import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";

import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";

const fromWei = (amt) => {
  const num = new BigNumber(amt.toString()).dividedBy(10 ** 18);
  return parseFloat(num.toFixed(2));
};

const idToName = {
  all: "all",
  "0x1": "eth",
  "0x2": "matic",
  "0x0": "btc",
  "0x3": "bnb",
  "0x14": "jpy",
};

const ids = ["0x0", "0x1", "0x2", "0x3", "0x14"];

function ClassicUserStatistics({ address }) {
  const [id, setId] = useState("all");

  const [dateFrom, setDateFrom] = useState(
    new Date(moment.utc().subtract({ days: 7 }))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [marketStats, setMarketStats] = useState([]);

  const loadNetworkStats = async (network) => {
    const graph =
      network === "polygon"
        ? "https://api.studio.thegraph.com/query/76875/polynew/version/latest"
        : "https://api.studio.thegraph.com/query/76875/bnbnew/version/latest";

    const queryAllTime = `query ( $sender: String)  { 
            userTotals(first: 1000, where: {sender: $sender, day: 0}) {
                id
                countBet
                countWon
                countBear
                countBull
                totalWon
                totalBet
                totalSamePrice
                totalWonClaimed
                totalClaimed
                totalEarnedUSD
                houseProfit
              }
        }`;

    let reqAllTime = await fetch(graph, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        query: queryAllTime,
        variables: { id, sender: address },
      }),
    });

    const resultAllTime = await reqAllTime.json();

    const userTotals = resultAllTime.data.userTotals;

    const marketData = [];
    userTotals.forEach((u) => {
      let data = { ...u };
      data.totalWon = fromWei(data.totalWon);
      data.totalBet = fromWei(data.totalBet);
      data.totalSamePrice = fromWei(data.totalSamePrice);
      data.totalWonClaimed = fromWei(data.totalWonClaimed);
      data.totalClaimed = fromWei(data.totalClaimed);
      data.houseProfit = fromWei(data.houseProfit);
      let id = network + " all";
      ids.forEach((i) => {
        if (u.id.includes("_" + i)) {
          id = network + " " + idToName[i];
        }
      });
      data.id = id;
      marketData.push(data);
    });

    console.log("cem", marketData);
    return marketData;
  };

  useEffect(() => {
    const loadData = async () => {
      const [bscData, polygonData] = await Promise.all([
        loadNetworkStats("bsc"),
        loadNetworkStats("polygon"),
      ]);
      setMarketStats([...bscData, ...polygonData]);
    };
    loadData();
  }, [address]);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Grid container spacing={3}>
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="bg-white border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        ></th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Bet
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Won
                        </th>{" "}
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Win Rate %
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Bull
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Bear
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total Bet
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total Won
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total SamePrice
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          House Profit
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total Claimed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {marketStats.map((m, i) => (
                        <tr
                          key={m.id}
                          className={
                            i % 2 == 1
                              ? "bg-white"
                              : "bg-gray-100" + " border-b"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {m.id}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countBet}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countWon}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {parseInt(100 * (m.countWon / m.countBet))}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countBull}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countBear}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalBet}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalWon}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalSamePrice}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.houseProfit}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalClaimed}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Stack>
    </>
  );
}

export default ClassicUserStatistics;
