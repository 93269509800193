import * as React from "react";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import FormControl from "@mui/material/FormControl";

const networks = ["bsc", "polygon"];

export default function AlertDialog({
  open,
  handleClose,
  handleConfirm,
  title,
  item,
}) {
  const [rates, setRates] = React.useState();
  React.useEffect(() => {
    const _rates = [];
    networks.map((n) => {
      const rateItem = item.rates.find((i) => i.network === n);
      const rate = rateItem ? rateItem.rate : 0;
      _rates[n] = rate;
      setRates(_rates);
    });
  }, []);
  const saveRates = () => {
    const newRates = networks.map((n) => ({ network: n, rate: rates[n] }));
    handleConfirm(item.name, newRates);
  };
  return (
    <div>
      {rates && (
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "black",
              color: "black",
            },
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Partner: {item.name}
            </DialogContentText>
            <MDBox pt={3}>
              <FormControl>
                {networks.map((n) => (
                  <Grid key={n} item xs={12} mt={3}>
                    <MDInput
                      fullWidth
                      label={n + " rate"}
                      onChange={(e) =>
                        setRates((prev) => ({ ...prev, [n]: e.target.value }))
                      }
                      value={rates[n]}
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </FormControl>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => saveRates()} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
