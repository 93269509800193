/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const Item = ({ title, value }) => (
  <MDBox mb={1} lineHeight={0}>
    <MDTypography variant="caption" color="text">
      {title}:&nbsp;&nbsp;&nbsp;
      <MDTypography variant="caption" fontWeight="medium">
        {value}
      </MDTypography>
    </MDTypography>
  </MDBox>
);

function NetworkInformation({ balances }) {
  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Network Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {balances.map((balance) => (
            <MDBox
              key={balance.network + balance.token}
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              bgColor="transparent"
              borderRadius="lg"
              mt={2}
            >
              <MDBox width="100%" display="flex" flexDirection="column">
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {balance.network + " : " + balance.token}
                  </MDTypography>
                </MDBox>
                <Item
                  title="Total Deposit"
                  value={balance.totalDeposit.toFixed(3)}
                />
                <Item
                  title="Total Withdraw"
                  value={balance.totalWithdraw.toFixed(3)}
                />
                <Item title="Total Bet" value={balance.totalBet.toFixed(3)} />
                <Item
                  title="House Profit"
                  value={balance.houseProfit.toFixed(3)}
                />
                <Item title="Num Bets" value={balance.numBets} />
                <Item title="Num Won" value={balance.numWon} />
                <Item title="Num Lost" value={balance.numLost} />
                <Item
                  title="DP/WB"
                  value={
                    ((balance.totalDeposit - balance.houseProfit) /
                    (balance.totalWithdraw + balance.amount)).toFixed(3)
                  }
                />
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default NetworkInformation;
