// @mui material components
import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import PieChart from "examples/Charts/PieChart";
import ClassicBetsTable from "layouts/classic/ClassicBetsTable";

import ContractBalances from "./components/ContractBalances";
import OperatorBalances from "./components/OperatorBalances";
import ClassicStatistics from "./components/ClassicStatistics";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { useEffect, useState } from "react";
import axios from "axios";

import { shortened } from "helpers";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import classicABI from "../../constants/classicABI";
import classicNewABI from "../../constants/classicNewABI";

const web3Bsc = new Web3("https://bsc-dataseed3.defibit.io/");
const web3Matic = new Web3("https://polygon-rpc.com/");

const predictionsData = [
  {
    name: "BSC New",
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    interval: 300,
    network: 0,
    web3: web3Bsc,
    abi: classicNewABI,
    multiple: true,
    id: "0",
  },
  {
    name: "Poly New",
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    interval: 300,
    network: 1,
    web3: web3Matic,
    abi: classicNewABI,
    multiple: true,
    id: "0",
  },
  // {
  //   name: "PolyETH",
  //   address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
  //   interval: 300,
  //   network: 1,
  //   web3: web3Matic,
  //   abi: classicNewABI,
  //   multiple: true,
  //   id: "1",
  // },
  // {
  //   name: "PolyMATIC",
  //   address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
  //   interval: 300,
  //   network: 1,
  //   web3: web3Matic,
  //   abi: classicNewABI,
  //   multiple: true,
  //   id: "2",
  // },
];

const predictionContracts = [];
for (let i = 0; i < predictionsData.length; i++) {
  const web3ToUse = predictionsData[i].web3;

  predictionContracts.push(
    new web3ToUse.eth.Contract(
      predictionsData[i].abi,
      predictionsData[i].address
    )
  );
}

const getNativeBalance = async (web3, address) => {
  const balanceWei = await web3.eth.getBalance(address);
  const balance = BigNumber(balanceWei.toString())
    .dividedBy(10 ** 18)
    .toFixed(2);
  return balance;
};

function Dashboard() {
  //const [network, setNetwork] = useState("BSC");
  const [predictions, setPredictions] = useState([]);
  const [bets, setBets] = useState([]);

  const loadPolygonBets = async () => {
    const graph =
      "https://api.studio.thegraph.com/query/76875/polynew/version/latest";

    const query = `query { 
    predictionsBets(first: 1000, orderBy: timestamp, orderDirection: desc) {
      id
      predictionId
      currentEpoch
      amount
      position
      roundResult
      result
      sender
      timestamp
    }
}`;

    let results = await fetch(graph, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        query,
      }),
    });

    const result = await results.json();
    let _bets = result.data.predictionsBets;
    _bets = _bets.map((b) => {
      return { ...b, network: "polygon" };
    });

    return _bets;
  };

  const loadBscBets = async () => {
    const graph =
      "https://api.studio.thegraph.com/query/76875/bnbnew/version/latest";

    const query = `query { 
    predictionsBets(first: 1000, orderBy: timestamp, orderDirection: desc) {
      id
      predictionId
      currentEpoch
      amount
      position
      roundResult
      result
      sender
      timestamp
    }
}`;

    let results = await fetch(graph, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        query,
      }),
    });

    const result = await results.json();
    let _bets = result.data.predictionsBets;
    _bets = _bets.map((b) => {
      return { ...b, network: "bsc" };
    });
    return _bets;
  };

  useEffect(() => {
    const loadData = async () => {
      const betsPolygon = await loadPolygonBets();
      const betsBsc = await loadBscBets();
      let allBets = [...betsBsc, ...betsPolygon].sort(
        (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp)
      );
      console.log("allBets", allBets);
      setBets(allBets);
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const blockNumber = await web3Bsc.eth.getBlockNumber();
        const block = await web3Bsc.eth.getBlock(blockNumber);
        const timestamp = block.timestamp;

        const predictions = [];

        const callsPre = [];
        const callsPost = [];

        for (let i = 0; i < predictionContracts.length; i++) {
          const predictionContract = predictionContracts[i];
          callsPre.push(predictionContract.methods.paused().call());
          callsPre.push(predictionContract.methods.currentEpoch().call());
        }

        const resultPre = await Promise.all(callsPre);

        for (let i = 0; i < predictionContracts.length; i++) {
          const predictionContract = predictionContracts[i];
          const roundNo = resultPre[i * 2 + 1];
          if (predictionsData[i].multiple)
            callsPost.push(
              predictionContract.methods
                .rounds(predictionsData[i].id, roundNo)
                .call()
            );
          else
            callsPost.push(predictionContract.methods.rounds(roundNo).call());
          callsPost.push(predictionContract.methods.timestamps(roundNo).call());
        }

        const resultPost = await Promise.all(callsPost);

        for (let i = 0; i < predictionContracts.length; i++) {
          const roundData = resultPost[i * 2];
          const timestamps = resultPost[i * 2 + 1];
          const paused = resultPre[i * 2];
          const currentRound = resultPre[i * 2 + 1];

          const balance = await getNativeBalance(
            predictionsData[i].web3,
            predictionsData[i].address
          );

          const timeOfLock = timestamps.lockTimestamp;
          const timeToLock = timeOfLock - timestamp;

          let text = paused ? "Paused" : timeToLock + " seconds";
          const slider =
            100 - (100 * parseInt(timeToLock)) / predictionsData[i].interval;
          const betUp = parseFloat(
            new BigNumber(roundData.bullAmount.toString())
              .dividedBy(10 ** 18)
              .toString()
          ).toFixed(3);
          const betDown = parseFloat(
            new BigNumber(roundData.bearAmount.toString())
              .dividedBy(10 ** 18)
              .toString()
          ).toFixed(3);

          predictions.push({
            name: predictionsData[i].name,
            currentRound,
            text,
            slider,
            betUp,
            betDown,
            balance,
          });
        }
        setPredictions(predictions);
      } catch (err) {
        console.log(err);
      }
    };

    loadData();
    const timer = setInterval(() => loadData(), 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={2}>
            <Stack direction="column" spacing={2}>
              <Grid item xs={12} mt={1}>
                <ContractBalances />
              </Grid>
              <Grid item xs={12} mt={1}>
                <OperatorBalances />
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={6} md={8} marginLeft={15}>
            <Grid container spacing={3}>
              {predictions.map((prediction) => (
                <Grid item xs={12} md={6} lg={6} key={prediction.name}>
                  <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title={`${prediction.name} : #${prediction.currentRound} : ${prediction.balance}`}
                      count={prediction.text}
                      percentage={{
                        color: "success",
                        amount: "%" + parseInt(prediction.slider),
                        label: "", //`Up: ${prediction.betUp} , Down: ${prediction.betDown}`,
                      }}
                    />
                  </MDBox>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid item xs={12}>
          <ClassicStatistics />
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid item xs={12}>
          <ClassicBetsTable data={bets} />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
