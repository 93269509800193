import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import TimelineItem from "examples/Timeline/TimelineItem";
import MDTypography from "components/MDTypography";

import { useBalance } from "wagmi";

import { contracts, tokenToAddressMap } from "helpers";

function Balances() {
  const balancePolygon = useBalance({
    address: contracts.polygon,
    chainId: 137,
  });

  const balancePolygonUSDT = useBalance({
    address: contracts.polygon,
    token: tokenToAddressMap.polygon.usdt,
    chainId: 137,
  });

  const balanceBsc = useBalance({
    address: contracts.bsc,
    chainId: 56,
  });

  const balanceBscUSDT = useBalance({
    address: contracts.bsc,
    token: tokenToAddressMap.bsc.usdt,
    chainId: 56,
  });

  const balanceEth = useBalance({
    address: contracts.eth,
    chainId: 1,
  });

  const balanceEthUSDT = useBalance({
    address: contracts.eth,
    token: tokenToAddressMap.eth.usdt,
    chainId: 1,
  });

  const balanceArbEth = useBalance({
    address: contracts.arb,
    chainId: 42161,
  });

  const balanceArbUSDT = useBalance({
    address: contracts.arb,
    token: tokenToAddressMap.arb.usdt,
    chainId: 42161,
  });

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Contract Balances
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <TimelineItem
            color="info"
            icon="local_parking"
            title={
              parseFloat(balancePolygon.data?.formatted).toFixed(2) + " MATIC"
            }
            dateTime=""
          />
          <TimelineItem
            color="success"
            icon="local_parking"
            title={
              parseFloat(balancePolygonUSDT.data?.formatted).toFixed(2) +
              " USDT"
            }
            dateTime=""
          />
          <TimelineItem
            color="warning"
            icon="format_bold"
            title={parseFloat(balanceBsc.data?.formatted).toFixed(2) + " BNB"}
            dateTime=""
          />
          <TimelineItem
            color="success"
            icon="format_bold"
            title={
              parseFloat(balanceBscUSDT.data?.formatted).toFixed(2) + " USDT"
            }
            dateTime=""
          />
          <TimelineItem
            color="warning"
            icon="explicit"
            title={parseFloat(balanceEth.data?.formatted).toFixed(4) + " ETH"}
            dateTime=""
          />
          <TimelineItem
            color="success"
            icon="explicit"
            title={
              parseFloat(balanceEthUSDT.data?.formatted).toFixed(2) + " USDT"
            }
            dateTime=""
          />{" "}
          <TimelineItem
            color="warning"
            icon="text_rotation_none"
            title={
              parseFloat(balanceArbEth.data?.formatted).toFixed(4) + " ETH"
            }
            dateTime=""
          />
          <TimelineItem
            color="success"
            icon="text_rotation_none"
            title={
              parseFloat(balanceArbUSDT.data?.formatted).toFixed(2) + " USDT"
            }
            dateTime=""
            lastItem
          />
        </MDBox>
      </Card>
    </>
  );
}

export default Balances;
