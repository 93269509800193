export const contracts = {
  bsc: "0x49eFb44831aD88A9cFFB183d48C0c60bF4028da8",
  polygon: "0x0b9c8c0a04354f41b985c10daf7db30bc66998f5",
  eth: "0x792b18ec0d39093f10f8b34676e2f8669a495e9b",
  arb: "0xe2ca0a434effea151d5b2c649b754acd3c8a20f0",
};

export const operator1Address = "0x4cbB5d1c808F0FEe11575E77025b40f602793286";
export const operator2Address = "0x2BcF5dc0b642063bCdC7ddeE995C658B0248B140";

export const getTokenDecimals = (token, network) => {
  let decimals = 18;
  if (
    (network === "polygon" || network === "arb") &&
    (token === "usdt" || token === "usdc")
  )
    decimals = 6;
  return decimals;
};

export const getMinutesDiff = (timestamp) => {
  const seconds = Math.abs(Math.floor(Date.now()) - timestamp);
  const minutes = Math.floor(seconds / 60000);
  return minutes;
};

export const getSecondsDiff = (timestamp) => {
  const sec = Math.abs(Math.floor(Date.now()) - timestamp);
  const seconds = Math.floor(sec / 1000);
  return seconds;
};

export const getMinutesDiffText = (timestamp) => {
  let diff = getMinutesDiff(timestamp);
  let timeDetail = "min";
  if (diff === 0) {
    diff = getSecondsDiff(timestamp);
    timeDetail = "sec";
  }
  if (timestamp > Math.floor(Date.now()))
    return "in " + diff + " " + timeDetail;
  return diff + " " + timeDetail + " ago";
};

export const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

export const tokenToAddressMap = {
  bsc: {
    bnb: "0x0000000000000000000000000000000000000000",
    usdt: "0x55d398326f99059fF775485246999027B3197955",
  },
  polygon: {
    matic: "0x0000000000000000000000000000000000000000",
    usdt: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  },
  eth: {
    eth: "0x0000000000000000000000000000000000000000",
    usdt: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  },
  arb: {
    eth: "0x0000000000000000000000000000000000000000",
    usdt: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
  },
};

export const scanTxLink = (network, hash) => {
  const url =
    network === "polygon"
      ? "https://polygonscan.com/tx/"
      : "https://bscscan.com/tx/";

  return url + hash;
};
