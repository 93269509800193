// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CircularProgress from "@mui/material/CircularProgress";

// Data
import { useEffect, useState } from "react";
import axios from "axios";
import AutoPlaysTable from "./autoPlaysTable";

function Tables() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/autoPlays",
        {
          withCredentials: true,
        }
      );
      console.log(result.data);
      setData(result.data);
      setIsLoading(false);
    };
    load();
    const timer = setInterval(() => load(), 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <CircularProgress color="success" />}
      {data && <AutoPlaysTable data={data} />}
    </DashboardLayout>
  );
}

export default Tables;
