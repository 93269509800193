/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Withdraws({ data }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium">
          Withdraws
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {data.map((withdraw) => (
            <MDBox
              key={withdraw._id}
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={1}
              pr={1}
            >
              <MDBox lineHeight={2.5}>
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  {withdraw.network + " : " + withdraw.token}
                </MDTypography>
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  color="text"
                >
                  {withdraw.createdAt}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {withdraw.amount}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {withdraw.transactionHash} / {withdraw.rejectionReason}
                </MDTypography>
              </MDBox>
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Withdraws;
