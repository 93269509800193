// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";
import BigNumber from "bignumber.js";

// Data
import { useEffect, useState } from "react";
import axios from "axios";

const getMinutesDiff = (timestamp) => {
  const seconds = Math.abs(Math.floor(Date.now()) - timestamp);
  const minutes = Math.floor(seconds / 60000);
  return minutes;
};

const getSecondsDiff = (timestamp) => {
  const sec = Math.abs(Math.floor(Date.now()) - timestamp);
  const seconds = Math.floor(sec / 1000);
  return seconds;
};

const getMinutesDiffText = (timestamp) => {
  let diff = getMinutesDiff(timestamp);
  let timeDetail = "min";
  if (diff === 0) {
    diff = getSecondsDiff(timestamp);
    timeDetail = "sec";
  }
  if (timestamp > Math.floor(Date.now()))
    return "in " + diff + " " + timeDetail;
  return diff + " " + timeDetail + " ago";
};

const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

const Item = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    {description && (
      <MDTypography variant="caption">{description}</MDTypography>
    )}
  </MDBox>
);

const Badge = ({ title, color }) => {
  <MDBox ml={-1}>
    <MDBadge badgeContent={title} color={color} variant="gradient" size="sm" />
  </MDBox>;
};

function BetsTable({
  data,
  columnsIgnored = {},
  setCanUpdate,
  showSource = false,
}) {
  const [bets, setBets] = useState();

  useEffect(() => {
    const rows = data.map((b) => {
      return {
        currentEpoch: <Item title={b.currentEpoch} />,
        network: <Item title={b.network} />,
        user: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography component="a" href={"/user/" + b.sender} color="text">
              {shortened(b.sender)}
            </MDTypography>
          </MDBox>
        ),
        target:
          b.predictionId === "0"
            ? "BTC"
            : b.predictionId === "1"
            ? "ETH"
            : b.predictionId === "2"
            ? "MATIC"
            : b.predictionId === "3"
            ? "BNB"
            : b.predictionId === "20"
            ? "JPY"
            : b.predictionId,
        amount: BigNumber(b.amount.toString())
          .dividedBy(10 ** 18)
          .toString(),
        position: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={b.position === 0 ? "up" : "down"}
              color={b.position === 0 ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        result: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={b.result === 1 ? "won" : "lost"}
              color={b.result === 1 ? "success" : "warning"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        time: (
          <Item
            title={getMinutesDiffText(
              new Date(parseInt(b.timestamp) * 1000).getTime()
            )}
          />
        ),
      };
    });

    const columns = [];
    columns.push({ Header: "#", accessor: "currentEpoch", align: "left" });
    columns.push({ Header: "network", accessor: "network", align: "left" });
    columns.push({ Header: "user", accessor: "user", align: "left" });
    columns.push({ Header: "target", accessor: "target", align: "left" });
    columns.push({ Header: "amount", accessor: "amount", align: "left" });
    columns.push({ Header: "position", accessor: "position", align: "left" });
    columns.push({ Header: "result", accessor: "result", align: "center" });
    columns.push({ Header: "Bet time", accessor: "time", align: "left" });

    const items = {
      columns,
      rows,
    };

    setBets(items);
  }, [data]);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Latest Bets
              </MDTypography>
            </MDBox>
            {bets && (
              <MDBox pt={3}>
                <DataTable
                  setCanUpdate={setCanUpdate}
                  table={bets}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default BetsTable;
