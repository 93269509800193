import React, { useState, useEffect, useMemo } from "react";
import { configureChains, WagmiConfig, createClient, useAccount } from "wagmi";
import { getDefaultProvider } from "ethers";
import axios from "axios";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import SignIn from "layouts/authentication/sign-in";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

import { mainnet, polygon, bsc, arbitrum } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { publicProvider } from "wagmi/providers/public";

// Material Dashboard 2 React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

const { chains, provider } = configureChains(
  [mainnet, bsc, polygon, arbitrum],
  [publicProvider()]
);

const client = createClient({
  autoConnect: true,
  connectors: [new InjectedConnector({ chains })],
  provider,
});
export const AuthContext = React.createContext({
  isAuthenticated: false,
  userRoles: localStorage.getItem("roles") || [],
  setUserRoles: () => {},
});

axios.defaults.withCredentials = true;

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRoles, setUserRoles] = useState(
    localStorage.getItem("roles") || []
  );
  const value = useMemo(
    () => ({ isAuthenticated, setIsAuthenticated, userRoles }),
    [isAuthenticated, userRoles]
  );

  const { address, isConnected } = useAccount();
  useEffect(() => {
    const load = async () => {
      if (!isConnected) return setIsAuthenticated(false);
      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/auth/authenticate",
        {
          withCredentials: true,
        }
      );

      if (
        result.status === 200 &&
        result.data.address.toLowerCase() === address.toLowerCase()
      ) {
        setIsAuthenticated(true);
        localStorage.setItem("roles", result.data.roles);
        setUserRoles(result.data.roles);
      }
    };

    load();
  }, [isAuthenticated]);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (
        route.route &&
        (!route.role || (userRoles && userRoles.includes(route.role)))
      ) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <AuthContext.Provider value={value}>
      <WagmiConfig client={client}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {!isAuthenticated && <SignIn />}
          {isAuthenticated && (
            <>
              {layout === "dashboard" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    brandName="PRDT Pro Dashboard"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                </>
              )}
              <Routes>
                {getRoutes(routes)}
                {userRoles && userRoles.includes("dev") && (
                  <Route path="*" element={<Navigate to="/dashboard" />} />
                )}
                {userRoles && userRoles.includes("support") && (
                  <Route path="*" element={<Navigate to="/user" />} />
                )}
                {userRoles && userRoles.includes("partner") && (
                  <Route
                    path="*"
                    element={
                      <Navigate
                        to={
                          "/partners/" +
                          userRoles[userRoles.indexOf("partner") + 1].substring(
                            8,
                            userRoles[userRoles.indexOf("partner") + 1].length
                          )
                        }
                      />
                    }
                  />
                )}
              </Routes>
            </>
          )}
        </ThemeProvider>
      </WagmiConfig>
    </AuthContext.Provider>
  );
}
