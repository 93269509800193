// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import axios from "axios";

const getMinutesDiff = (timestamp) => {
  const seconds = Math.abs(Math.floor(Date.now()) - timestamp);
  const minutes = Math.floor(seconds / 60000);
  return minutes;
};

const getSecondsDiff = (timestamp) => {
  const sec = Math.abs(Math.floor(Date.now()) - timestamp);
  const seconds = Math.floor(sec / 1000);
  return seconds;
};

const getMinutesDiffText = (timestamp) => {
  let diff = getMinutesDiff(timestamp);
  let timeDetail = "min";
  if (diff === 0) {
    diff = getSecondsDiff(timestamp);
    timeDetail = "sec";
  }
  if (timestamp > Math.floor(Date.now()))
    return "in " + diff + " " + timeDetail;
  return diff + " " + timeDetail + " ago";
};

const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

const Item = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    {description && (
      <MDTypography variant="caption">{description}</MDTypography>
    )}
  </MDBox>
);

const Badge = ({ title, color }) => {
  <MDBox ml={-1}>
    <MDBadge badgeContent={title} color={color} variant="gradient" size="sm" />
  </MDBox>;
};

function JournalTable({ data, columnsIgnored = {}, setCanUpdate }) {
  const [bets, setBets] = useState();
  useEffect(() => {
    const rows = data.map((b) => {
      return {
        time: (
          <Item title={getMinutesDiffText(new Date(b.createdAt).getTime())} />
        ),
        user: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography component="a" href={"/user/" + b.sender} color="text">
              {(b.user && b.user.nickname) || shortened(b.sender)}
            </MDTypography>
          </MDBox>
        ),
        action: b.action,
        operation: b.operation,
        description: b.description,
        network: (
          <Item
            title={b.network}
            description={b.amount?.toFixed(2) + " " + b.token}
          />
        ),
      };
    });

    const columns = [];
    columns.push({ Header: "time", accessor: "time", align: "left" });
    if (!columnsIgnored.user) {
      columns.push({
        Header: "user",
        accessor: "user",
        align: "left",
      });
    }
    columns.push({ Header: "action", accessor: "action", align: "left" });
    columns.push({ Header: "operation", accessor: "operation", align: "left" });
    columns.push({ Header: "network", accessor: "network", align: "left" });
    columns.push({
      Header: "description",
      accessor: "description",
      align: "left",
    });

    const items = {
      columns,
      rows,
    };

    setBets(items);
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Journal
              </MDTypography>
            </MDBox>
            {bets && (
              <MDBox pt={3}>
                <DataTable
                  setCanUpdate={setCanUpdate}
                  table={bets}
                  isSorted={true}
                  canSearch={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default JournalTable;
