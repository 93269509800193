// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
// Data
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

function PredictionStats() {
  const [dateTo, setDateTo] = useState(new Date(moment.utc()));
  const [dateFrom, setDateFrom] = useState(new Date(moment.utc()));
  const [data, setData] = useState();
  const [canUpdate, setCanUpdate] = useState(true);
  const [bets, setBets] = useState();
  useEffect(() => {
    if (!data) return;
    const rows = data.map((b) => {
      return {
        prediction: b._id.bettingToken,
        amountUSD: b.amountUSD,
        amountWonUSD: b.amountWonUSD,
        numBets: b.numBets,
        won: b.won,
      };
    });

    const columns = [];
    columns.push({
      Header: "prediction",
      accessor: "prediction",
      align: "left",
    });
    columns.push({ Header: "amountUSD", accessor: "amountUSD", align: "left" });
    columns.push({
      Header: "amountWonUSD",
      accessor: "amountWonUSD",
      align: "left",
    });
    columns.push({
      Header: "numBets",
      accessor: "numBets",
      align: "left",
    });
    columns.push({
      Header: "won",
      accessor: "won",
      align: "left",
    });

    const items = {
      columns,
      rows,
    };
    setBets(items);
  }, [data]);

  useEffect(() => {
    const load = async () => {
      if (!canUpdate) return;
      const result = await axios.post(
        process.env.REACT_APP_API_URI + `/api/v1/dashboard/stats/predictions`,
        {
          withCredentials: true,
          dateFrom,
          dateTo,
        }
      );
      console.log(result.data);
      setData(result.data);
    };

    load();
  }, [canUpdate, dateTo, dateFrom]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {data && (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="black">
                    Pro Prediction Stats
                    From the beginning of:{" "}
                    <DatePicker
                      selected={dateFrom}
                      onChange={(d) => setDateFrom(d)}
                    />
                    To the end of:{" "}
                    <DatePicker
                      selected={dateTo}
                      onChange={(d) => setDateTo(d)}
                    />
                    <br />
                  </MDTypography>
                </MDBox>
                {bets && (
                  <MDBox pt={3}>
                    <DataTable
                      setCanUpdate={setCanUpdate}
                      table={bets}
                      isSorted={true}
                      canSearch={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default PredictionStats;
