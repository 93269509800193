// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import axios from "axios";
import { scanTxLink, shortened, getMinutesDiffText } from "helpers";

const Item = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    {description && (
      <MDTypography variant="caption">{description}</MDTypography>
    )}
  </MDBox>
);

function Withdraws() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    const load = async () => {
      if (!canUpdate) return;
      setIsLoading(true);
      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/withdraws",
        {
          withCredentials: true,
        }
      );

      const rows = result.data.map((b) => {
        return {
          user: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography
                component="a"
                href={"/user/" + b.sender}
                color="text"
              >
                {b.user.nickname || shortened(b.sender)}
              </MDTypography>
            </MDBox>
          ),
          token: b.amount + " " + b.token,
          network: b.network,
          time: getMinutesDiffText(new Date(b.createdAt).getTime()),
          timeApprove: getMinutesDiffText(new Date(b.updatedAt).getTime()),
          state: b.state,
          transactionHash: (
            <Link
              href={scanTxLink(b.network, b.transactionHash)}
              target="_blank"
              underline="always"
            >
              {b.transactionHash ? shortened(b.transactionHash) : ""}
            </Link>
          ),
          details: b.rejectionReason,
        };
      });

      const items = {
        columns: [
          {
            Header: "user",
            accessor: "user",
            align: "left",
          },
          { Header: "network", accessor: "network", align: "left" },
          { Header: "token", accessor: "token", align: "left" },
          { Header: "timeRequest", accessor: "time", align: "left" },
          { Header: "timeApprove", accessor: "timeApprove", align: "left" },
          { Header: "state", accessor: "state", align: "left" },
          {
            Header: "transactionHash",
            accessor: "transactionHash",
            align: "left",
          },
          { Header: "details", accessor: "details", align: "left" },
        ],

        rows,
      };

      setData(items);
      setIsLoading(false);
    };

    load();
    const timer = setInterval(() => load(), 10000);
    return () => {
      clearInterval(timer);
    };
  }, [canUpdate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <CircularProgress color="success" />}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Last Withdraws
                </MDTypography>
              </MDBox>
              {data && (
                <MDBox pt={3}>
                  <DataTable
                    setCanUpdate={setCanUpdate}
                    table={data}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Withdraws;
