// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import axios from "axios";

const getMinutesDiff = (timestamp) => {
  const seconds = Math.abs(Math.floor(Date.now()) - timestamp);
  const minutes = Math.floor(seconds / 60000);
  return minutes;
};

const getSecondsDiff = (timestamp) => {
  const sec = Math.abs(Math.floor(Date.now()) - timestamp);
  const seconds = Math.floor(sec / 1000);
  return seconds;
};

const getMinutesDiffText = (timestamp) => {
  let diff = getMinutesDiff(timestamp);
  let timeDetail = "min";
  if (diff === 0) {
    diff = getSecondsDiff(timestamp);
    timeDetail = "sec";
  }
  if (timestamp > Math.floor(Date.now()))
    return "in " + diff + " " + timeDetail;
  return diff + " " + timeDetail + " ago";
};

const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

const Item = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    {description && (
      <MDTypography variant="caption">{description}</MDTypography>
    )}
  </MDBox>
);

const Badge = ({ title, color }) => {
  <MDBox ml={-1}>
    <MDBadge badgeContent={title} color={color} variant="gradient" size="sm" />
  </MDBox>;
};

function BetsTable({
  data,
  columnsIgnored = {},
  setCanUpdate,
  showSource = false,
}) {
  const [bets, setBets] = useState();

  useEffect(() => {
    const rows = data.map((b) => {
      return {
        currentEpoch: <Item title={b.currentEpoch} />,
        user: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography component="a" href={"/user/" + b.sender} color="text">
              {b.user.nickname || shortened(b.sender)}
            </MDTypography>
          </MDBox>
        ),
        source: b.source,
        partner: b.user?.reference?.partner || "",
        jackpot: (
          <Item
            title={b.jackpotRandom}
            description={b.jackpotChance}
          />
        ),
        token: (
          <Item
            title={b.targetToken}
            description={b.amount + " " + b.bettingToken}
          />
        ),
        prices: (
          <Item
            title={"Close: " + b.closePrice}
            description={"Start: " + b.startPrice}
          />
        ),
        position: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={b.position === 0 ? "up" : "down"}
              color={b.position === 0 ? "success" : "primary"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        result: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={
                b.calculated
                  ? b.canceled
                    ? "canceled"
                    : b.won
                    ? "won"
                    : b.samePrice
                    ? "samePrice"
                    : "lost"
                  : "ongoing"
              }
              color={
                b.calculated
                  ? b.canceled
                    ? "error"
                    : b.won
                    ? "success"
                    : b.samePrice
                    ? "secondary"
                    : "warning"
                  : "info"
              }
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        time: (
          <Item
            title={
              "Close: " +
              getMinutesDiffText(new Date(b.closeTimestamp).getTime())
            }
            description={
              "Start: " +
              getMinutesDiffText(new Date(b.startTimestamp).getTime())
            }
          />
        ),
      };
    });

    const columns = [];
    columns.push({ Header: "#", accessor: "currentEpoch", align: "left" });
    if (showSource) {
      columns.push({
        Header: "source",
        accessor: "source",
        align: "center",
      });
    }
    if (!columnsIgnored.user) {
      columns.push({
        Header: "user",
        accessor: "user",
        align: "left",
      });
      columns.push({
        Header: "partner",
        accessor: "partner",
        align: "left",
      });
    }
    columns.push({ Header: "jackpot", accessor: "jackpot", align: "left" });
    columns.push({ Header: "token", accessor: "token", align: "left" });
    columns.push({ Header: "prices", accessor: "prices", align: "left" });
    columns.push({ Header: "position", accessor: "position", align: "center" });
    columns.push({ Header: "result", accessor: "result", align: "center" });
    columns.push({ Header: "time", accessor: "time", align: "left" });

    const items = {
      columns,
      rows,
    };

    setBets(items);
  }, [data]);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Latest Bets
              </MDTypography>
            </MDBox>
            {bets && (
              <MDBox pt={3}>
                <DataTable
                  setCanUpdate={setCanUpdate}
                  table={bets}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default BetsTable;
