// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import BetsTable from "layouts/bets/BetsTable";
import JournalTable from "layouts/journal/JournalTable";

import UserCard from "./components/UserCard";
import Deposits from "layouts/user/components/Deposits";
import Withdraws from "layouts/user/components/Withdraws";
import NetworkInformation from "layouts/user/components/NetworkInformation";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import axios from "axios";

function PartnerUser() {
  const { address } = useParams();
  const [user, setUser] = useState();
  const [enteredAddress, setEnteredAddress] = useState("");
  const [postStats, setPostStats] = useState();
  const [journalData, setJournalData] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const load = async () => {
      const addressCheckSum = ethers.utils.getAddress(address);
      const [result, journal, postStatistics] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URI}/api/v1/dashboard/partners/users/${addressCheckSum}`,
          {
            withCredentials: true,
          }
        ),
      ]);

      setUser(result.data);
      setJournalData(journal.data);

      let statsResult = { numBets: 0, numWon: 0, numLost: 0, houseProfit: 0 };
      for (let i = 0; i < postStatistics.data.length; i++) {
        const s = postStatistics.data[i];
        if (s._id) statsResult.numWon += s.numBets;
        else statsResult.numLost += s.numBets;
        statsResult.numBets += s.numBets;
        statsResult.houseProfit =
          statsResult.houseProfit + s.amountUSD - s.amountWonUSD;
      }
      console.log(statsResult);

      setPostStats(statsResult);
    };
    if (address) load();
    else {
      setUser(null);
    }
  }, [address]);

  const loadAddress = () => {
    navigate("/user/" + enteredAddress);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!address && (
        <MDBox mt={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDInput
                fullWidth
                label="Enter user address"
                value={enteredAddress}
                variant="standard"
                onChange={(e) => setEnteredAddress(e.target.value)}
              />
              <MDButton
                fullWidth
                variant="gradient"
                color="info"
                size="small"
                onClick={loadAddress}
              >
                Load
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {user && (
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <UserCard user={user} isPartner={true} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {user.balances.map((b) => (
                    <Grid key={b.network + b.token} item xs={12} md={6} xl={2}>
                      <DefaultInfoCard
                        icon="account_balance"
                        title={b.network + " " + b.token}
                        description="balance"
                        value={b.amount.toFixed(2)}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="warning"
                      title="Total Bonus"
                      description="USD"
                      value={user.totalBonus.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="success"
                      title={"Won:" + user.numWon}
                      description={"Num Bets:" + user.numBets}
                      value={
                        parseInt(
                          (user.numWon / (user.numWon + user.numLost)) * 100
                        ) + "%"
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="Total Bet"
                      description="USD"
                      value={user.totalBet.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="House Profit"
                      description="USD"
                      value={(user.houseProfit - user.totalBonus).toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="Total Deposit"
                      description="USD"
                      value={user.totalDeposit?.toFixed(2)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={2}>
                    <DefaultInfoCard
                      icon="account_balance"
                      color="primary"
                      title="Total Withdraw"
                      description="USD"
                      value={user.totalWithdraw?.toFixed(2)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <NetworkInformation balances={user.balances} />
              </Grid>

              <Grid item xs={12} md={10}>
                <BetsTable data={user.bets} columnsIgnored={{ user: true }} />
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Deposits data={user.deposits} />
              </Grid>

              <Grid item xs={6}>
                <Withdraws data={user.withdraws} />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default PartnerUser;
