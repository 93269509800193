import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import TimelineItem from "examples/Timeline/TimelineItem";
import MDTypography from "components/MDTypography";

import { useBalance } from "wagmi";

import { contracts, tokenToAddressMap } from "helpers";

function Balances() {
  const balancePolygon = useBalance({
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    chainId: 137,
  });

  const balanceBsc = useBalance({
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    chainId: 56,
  });

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Contract Balances
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <TimelineItem
            color="info"
            icon="local_parking"
            title={
              parseFloat(balancePolygon.data?.formatted).toFixed(2) + " MATIC"
            }
            dateTime=""
          />
          <TimelineItem
            color="warning"
            icon="format_bold"
            title={parseFloat(balanceBsc.data?.formatted).toFixed(2) + " BNB"}
            dateTime=""
            lastItem
          />
        </MDBox>
      </Card>
    </>
  );
}

export default Balances;
