import Bets from "layouts/bets";
import BetsWithSource from "layouts/bets/withSource";
import NewBet from "layouts/bets/newBet";
import AutoPlays from "layouts/autoPlays";
import Partners from "layouts/partners";
import Journal from "layouts/journal";
import Withdraws from "layouts/withdraws";
import LiveUsers from "layouts/liveUsers";
import DailyWinners from "layouts/stats/dailyWinners";
import PredictionStats from "layouts/stats/predictionStats";
// import WithdrawsWaitingForApproval from "layouts/withdraws/waitingForApproval";
// import WithdrawsNotSent from "layouts/withdraws/notSent";
// import WithdrawsManuallySent from "layouts/withdraws/manuallySent";
import WithdrawsSentNoTx from "layouts/withdraws/sentNoTx";
import Deposits from "layouts/deposits";
import User from "layouts/user";
import PartnerUser from "layouts/partnerUser";
import Classic from "layouts/classic";
import SuggestedLiquidity from "layouts/classic/suggestedLiquidity";
import ClassicUserData from "layouts/classic/userData";
import ClassicTopWinners from "layouts/classic/topWinners";
import ClassicTopWinnersToday from "layouts/classic/topWinnersToday";
import ClassicTopWinnersYesterday from "layouts/classic/topWinnersYesterday";
import ClassicTopWinnersDateSelect from "layouts/classic/topWinnersDateSelect";
import PriceChart from "layouts/priceChart";

import ProfitWallets from "layouts/profitWallets";

import Dashboard from "layouts/dashboard";
import Status from "layouts/status";
import PartnerDashboard from "layouts/dashboard/partner.dashboard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "Status",
    key: "status",
    icon: <Icon fontSize="small">status</Icon>,
    route: "/status",
    component: <Status />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "DailyWinners",
    key: "stats.dailyWinners",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/stats/dailyWinners",
    component: <DailyWinners />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "PredictionStats",
    key: "stats.predictions",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/stats/predictions",
    component: <PredictionStats />,
    role: "dev",
  },
  {
    type: "divider",
    key: "divider3",
    role: "dev",
  },
  {
    type: "collapse",
    name: "Classic",
    key: "classic",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/classic",
    component: <Classic />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "SuggestedLiquidity",
    key: "suggestedLiquidity",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/suggestedLiquidity",
    component: <SuggestedLiquidity />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "Classic TopWinners",
    key: "classicTopWinner",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/classic/topwinners",
    component: <ClassicTopWinners />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "TopWinners Today",
    key: "classicTopWinnerToday",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/classic/topwinners/today",
    component: <ClassicTopWinnersToday />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "TopWinners Yesterday",
    key: "classicTopWinnerYesterday",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/classic/topwinners/yesterday",
    component: <ClassicTopWinnersYesterday />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "TopWinners Date",
    key: "classicTopWinnerDate",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/classic/topwinners/date",
    component: <ClassicTopWinnersDateSelect />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "Classic User",
    key: "classicUser",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/classic/user",
    component: <ClassicUserData />,
    role: "dev",
  },
  {
    type: "divider",
    key: "dividerClassic",
    route: "/classic/user/:address",
    component: <ClassicUserData />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "ProfitWallets",
    key: "profitWallets",
    icon: <PanToolAltIcon />,
    route: "/ProfitWallets",
    component: <ProfitWallets />,
    role: "dev",
  },
  {
    type: "divider",
    key: "dividerProfitWallet",
    role: "dev",
  },
  {
    type: "collapse",
    name: "Bets",
    key: "bets",
    icon: <PanToolAltIcon />,
    route: "/bets",
    component: <Bets />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "Bets With Source",
    key: "betsWithSource",
    icon: <PanToolAltIcon />,
    route: "/betsWithSource",
    component: <BetsWithSource />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "NewBet",
    key: "NewBet",
    icon: <PanToolAltIcon />,
    route: "/newBet",
    component: <NewBet />,
    role: "dev",
  },
  {
    type: "divider",
    key: "divider4",
    role: "dev",
  },
  {
    type: "collapse",
    name: "PriceChart",
    key: "priceChart",
    icon: <PanToolAltIcon />,
    route: "/priceChart",
    component: <PriceChart />,
    role: "dev",
  },
  {
    type: "divider",
    key: "dividerPriceChart",
    role: "dev",
  },
  {
    type: "collapse",
    name: "LiveUsers",
    key: "LiveUsers",
    icon: <PanToolAltIcon />,
    route: "/liveUsers",
    component: <LiveUsers />,
    role: "dev",
  },
  {
    type: "divider",
    key: "dividerLiveUsers",
    role: "dev",
  },
  {
    type: "collapse",
    name: "Auto Plays",
    key: "autoPlays",
    icon: <MotionPhotosAutoIcon />,
    route: "/autoPlays",
    component: <AutoPlays />,
    role: "dev",
  },
  {
    type: "divider",
    key: "divider6",
    role: "dev",
  },
  {
    type: "collapse",
    name: "Journal",
    key: "journal",
    icon: <MenuBookIcon />,
    route: "/journal",
    component: <Journal />,
    role: "dev",
  },
  {
    type: "divider",
    key: "divider1",
    role: "dev",
  },
  {
    type: "collapse",
    name: "Withdraws",
    key: "withdraws",
    icon: <LocalAtmIcon />,
    route: "/withdraws",
    component: <Withdraws />,
    role: "dev",
  },
  // {
  //   type: "divider",
  //   key: "divider5",
  // },
  // {
  //   type: "collapse",
  //   name: "Approve Withdraws",
  //   key: "withdrawsWaitingApproval",
  //   icon: <LocalAtmIcon />,
  //   route: "/withdraws/approval",
  //   component: <WithdrawsWaitingForApproval />,
  // },
  // {
  //   type: "collapse",
  //   name: "Withdraws not sent",
  //   key: "withdrawsNotSent",
  //   icon: <LocalAtmIcon />,
  //   route: "/withdraws/notsent",
  //   component: <WithdrawsNotSent />,
  // },
  {
    type: "collapse",
    name: "Withdraws sent no tx",
    key: "withdrawsSentNoTx",
    icon: <LocalAtmIcon />,
    route: "/withdraws/sentNoTx",
    component: <WithdrawsSentNoTx />,
    role: "dev",
  },
  // {
  //   type: "collapse",
  //   name: "Withdraws manually sent",
  //   key: "withdrawsManuallyent",
  //   icon: <LocalAtmIcon />,
  //   route: "/withdraws/sent",
  //   component: <WithdrawsManuallySent />,
  // },
  {
    type: "divider",
    key: "divider2",
    role: "dev",
  },
  {
    type: "collapse",
    name: "Deposits",
    key: "deposits",
    icon: <LocalAtmIcon />,
    route: "/deposits",
    component: <Deposits />,
    role: "dev",
  },
  {
    type: "divider",
    key: "dividerDeposit",
    role: "dev",
  },
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <PersonIcon />,
    route: "/user/",
    component: <User />,
    role: "dev",
  },
  {
    type: "divider",
    key: "userAddr",
    route: "/user/:address",
    component: <User />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "User",
    key: "partneruser",
    icon: <PersonIcon />,
    route: "/user/",
    component: <PartnerUser />,
    role: "partner",
  },
  {
    type: "divider",
    key: "userAddrPartner",
    route: "/user/:address",
    component: <PartnerUser />,
  },
  {
    type: "collapse",
    name: "Partners",
    key: "partners",
    icon: <PanToolAltIcon />,
    route: "/partners",
    component: <Partners />,
    role: "dev",
  },
  {
    type: "collapse",
    name: "Venus",
    key: "partnervenus",
    icon: <PanToolAltIcon />,
    route: "/partners/venus",
    component: <PartnerDashboard />,
    role: "partner.venus",
  },
  {
    type: "collapse",
    name: "Metavault",
    key: "partnerMetavault",
    icon: <PanToolAltIcon />,
    route: "/partners/metavault",
    component: <PartnerDashboard />,
    role: "partner.metavault",
  },
  {
    type: "collapse",
    name: "Biswap",
    key: "partnerBiswap",
    icon: <PanToolAltIcon />,
    route: "/partners/biswap",
    component: <PartnerDashboard />,
    role: "partner.biswap",
  },
  {
    type: "collapse",
    name: "Chance",
    key: "partnerChance",
    icon: <PanToolAltIcon />,
    route: "/partners/chance",
    component: <PartnerDashboard />,
    role: "partner.chance",
  },
  {
    type: "collapse",
    name: "CemTest",
    key: "partnerCemTest",
    icon: <PanToolAltIcon />,
    route: "/partners/cemtest",
    component: <PartnerDashboard />,
    role: "partner.cemtest",
  },
  {
    type: "collapse",
    name: "Jumper",
    key: "partnerJumper",
    icon: <PanToolAltIcon />,
    route: "/partners/jumper",
    component: <PartnerDashboard />,
    role: "partner.jumper",
  },
  {
    type: "collapse",
    name: "Bee",
    key: "partnerBee",
    icon: <PanToolAltIcon />,
    route: "/partners/bee",
    component: <PartnerDashboard />,
    role: "partner.bee",
  },
  {
    type: "collapse",
    name: "Kayu",
    key: "partnerKayu",
    icon: <PanToolAltIcon />,
    route: "/partners/kayu",
    component: <PartnerDashboard />,
    role: "partner.kayu",
  },
];

export default routes;
