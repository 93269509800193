// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AlertDialog from "./components/Dialog";

// Data
import { useEffect, useState } from "react";
import axios from "axios";

import {
  contracts,
  getTokenDecimals,
  getMinutesDiffText,
  shortened,
  tokenToAddressMap,
} from "helpers";

function Withdraws() {
  const [data, setData] = useState();
  const [openRevert, setOpenRevert] = useState(false);
  const [openResend, setOpenResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentWithdrawItem, setCurrentWithdrawItem] = useState();

  useEffect(() => {
    const load = async () => {
      getWithdrawRows();
    };

    load();
  }, []);

  const getWithdrawRows = async () => {
    const result = await axios.get(
      process.env.REACT_APP_API_URI +
        "/api/v1/dashboard/withdraws/approval/sentNoTx",
      {
        withCredentials: true,
      }
    );

    const rows = result.data.map((b) => {
      return {
        user: b.user.nickname || shortened(b.sender),
        token: b.amount + " " + b.token,
        network: b.network,
        time: getMinutesDiffText(new Date(b.createdAt).getTime()),
        timeApprove: getMinutesDiffText(new Date(b.updatedAt).getTime()),
        details: b.approveManual ? "approved by dev" : "auto sent",
        action: <ActionCell withdraw={b} />,
      };
    });

    const items = {
      columns: [
        {
          Header: "user",
          accessor: "user",
          align: "left",
        },
        { Header: "network", accessor: "network", align: "left" },
        { Header: "token", accessor: "token", align: "left" },
        { Header: "timeRequest", accessor: "time", align: "left" },
        { Header: "timeApprove", accessor: "timeApprove", align: "left" },
        { Header: "details", accessor: "details", align: "left" },
        { Header: "action", accessor: "action", align: "left" },
      ],

      rows,
    };

    setData(items);
  };

  const handleRevert = async (withdraw) => {
    setIsLoading(true);
    const id = withdraw._id;
    console.log("reject ", id);
    const resultRevert = await axios.post(
      process.env.REACT_APP_API_URI +
        "/api/v1/dashboard/withdraws/approval/revert",
      {
        withCredentials: true,
        withdrawId: id,
      }
    );

    console.log(resultRevert);
    await getWithdrawRows();

    handleClose();
  };

  const handleResend = async (withdraw) => {
    setIsLoading(true);
    const id = withdraw._id;
    console.log("reject ", id);
    const resultResend = await axios.post(
      process.env.REACT_APP_API_URI +
        "/api/v1/dashboard/withdraws/approval/resend",
      {
        withCredentials: true,
        withdrawId: id,
      }
    );

    console.log(resultResend);
    await getWithdrawRows();

    handleClose();
  };

  const handleClose = () => {
    setOpenRevert(false);
    setOpenResend(false);
    setIsLoading(false);
  };

  const openRevertModal = ({ withdraw }) => {
    setCurrentWithdrawItem(withdraw);
    setOpenRevert(true);
  };

  const openResendModal = ({ withdraw }) => {
    setCurrentWithdrawItem(withdraw);
    setOpenResend(true);
  };

  const ActionCell = ({ withdraw }) => (
    <MDBox>
      <MDButton
        variant="gradient"
        color={withdraw.approveManual ? "success" : "info"}
        size="small"
        onClick={() =>
          withdraw.approveManual
            ? openRevertModal({ withdraw })
            : openResendModal({ withdraw })
        }
      >
        {withdraw.approveManual ? "Revert" : "Resend"}
      </MDButton>
    </MDBox>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentWithdrawItem && (
        <>
          <AlertDialog
            handleClose={handleClose}
            handleConfirm={handleRevert}
            item={currentWithdrawItem}
            open={openRevert}
            isLoading={isLoading}
            title={"Revert this withdraw request?"}
            body={
              "The withdraw item will be shown on waiting manual approval table"
            }
          />
          <AlertDialog
            handleClose={handleClose}
            handleConfirm={handleResend}
            item={currentWithdrawItem}
            open={openResend}
            isLoading={isLoading}
            title={"Resend this withdraw amount?"}
            body={"The withdraw amount will be resent by the backend script"}
          />
        </>
      )}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Withdraws Sent but doesnt have TX Hash, needs double check
                </MDTypography>
              </MDBox>
              {data && (
                <MDBox pt={3}>
                  <DataTable
                    table={data}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Withdraws;
