import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

export default function AlertDialog({
  open,
  isLoading,
  handleClose,
  handleConfirm,
  title,
  body,
  item,
}) {
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "black",
            color: "black",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            User: {item.sender} <br />
            {item.network}, {item.amount} {item.token} <br /> <br />
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={isLoading} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => handleConfirm(item)}
            autoFocus
          >
            Confirm
          </Button>
          {isLoading && <CircularProgress />}
        </DialogActions>
      </Dialog>
    </div>
  );
}
