import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import TimelineItem from "examples/Timeline/TimelineItem";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

import { useBalance } from "wagmi";

const operator1Address = "0x4cbb5d1c808f0fee11575e77025b40f602793286";

function Balances() {
  const balanceOperator1Bsc = useBalance({
    address: operator1Address,
    chainId: 56,
  });

  const balanceOperator1Polygon = useBalance({
    address: operator1Address,
    chainId: 137,
  });

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Operator Balances
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <TimelineItem
            color="info"
            icon="local_parking"
            title={
              parseFloat(balanceOperator1Polygon.data?.formatted).toFixed(2) +
              " MATIC"
            }
          />
          <TimelineItem
            color="warning"
            icon="format_bold"
            title={
              parseFloat(balanceOperator1Bsc.data?.formatted).toFixed(2) +
              " BNB"
            }
            lastItem
          />
        </MDBox>
      </Card>
    </>
  );
}

export default Balances;
