import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import TimelineItem from "examples/Timeline/TimelineItem";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import TooltipItem from "components/TooltipItem";

import { useBalance } from "wagmi";

import { operator1Address, operator2Address } from "helpers";
function Balances() {
  const balanceOperator1Bsc = useBalance({
    address: operator1Address,
    chainId: 56,
  });

  const balanceOperator2Bsc = useBalance({
    address: operator2Address,
    chainId: 56,
  });

  const balanceOperator1Polygon = useBalance({
    address: operator1Address,
    chainId: 137,
  });

  const balanceOperator2Polygon = useBalance({
    address: operator2Address,
    chainId: 137,
  });

  const balanceOperator1Eth = useBalance({
    address: operator1Address,
    chainId: 1,
  });

  const balanceOperator2Eth = useBalance({
    address: operator2Address,
    chainId: 1,
  });

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium">
            Operator Balances
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <TooltipItem tooltip={operator1Address}>
            <TimelineItem
              color="info"
              icon="local_parking"
              title={
                parseFloat(balanceOperator1Polygon.data?.formatted).toFixed(2) +
                " MATIC"
              }
              dateTime="Operator1"
            />
          </TooltipItem>
          <TooltipItem tooltip={operator1Address}>
            <TimelineItem
              color="warning"
              icon="format_bold"
              title={
                parseFloat(balanceOperator1Bsc.data?.formatted).toFixed(2) +
                " BNB"
              }
              dateTime="Operator1"
            />
          </TooltipItem>
          <TooltipItem tooltip={operator2Address}>
            <TimelineItem
              color="info"
              icon="local_parking"
              title={
                parseFloat(balanceOperator2Polygon.data?.formatted).toFixed(2) +
                " MATIC"
              }
              dateTime="Operator2"
            />
          </TooltipItem>
          <TooltipItem tooltip={operator2Address}>
            <TimelineItem
              color="warning"
              icon="format_bold"
              title={
                parseFloat(balanceOperator2Bsc.data?.formatted).toFixed(2) +
                " BNB"
              }
              dateTime="Operator2"
            />
          </TooltipItem>

          <TooltipItem tooltip={operator1Address}>
            <TimelineItem
              color="info"
              icon="explicit"
              title={
                parseFloat(balanceOperator1Eth.data?.formatted).toFixed(2) +
                " ETH"
              }
              dateTime="Operator1"
            />
          </TooltipItem>

          <TooltipItem tooltip={operator2Address}>
            <TimelineItem
              color="warning"
              icon="explicit"
              title={
                parseFloat(balanceOperator2Eth.data?.formatted).toFixed(2) +
                " ETH"
              }
              dateTime="Operator2"
              lastItem
            />
          </TooltipItem>
        </MDBox>
      </Card>
    </>
  );
}

export default Balances;
