import MDSnackbar from "components/MDSnackbar";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Images
import pattern from "assets/images/illustrations/pattern-tree.svg";
import { useState } from "react";
import axios from "axios";
import MDInput from "components/MDInput";

function UserCard({ color, user }) {
  const [list, setList] = useState(user.listing || "none");
  const [label, setLabel] = useState(user.label || "none");
  const [nickname, setNickname] = useState(user.nickname || "");
  const [successSB, setSuccessSB] = useState(false);
  const [alertHighBet, setAlertHighBet] = useState(user.disableAlerts?.highBet);
  const [alertApproveWithdraw, setAlertApproveWithdraw] = useState(
    user.disableAlerts?.approveWithdraw
  );
  const [alertWinRate, setAlertWinRate] = useState(user.disableAlerts?.winRate);
  const [alertProfit, setAlertProfit] = useState(user.disableAlerts?.profit);

  const saveUserList = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${user.address}/list`,
      {
        withCredentials: true,
        list: list,
      }
    );

    setSuccessSB(result.status === 200);
  };

  const saveUserLabel = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${user.address}/label`,
      {
        withCredentials: true,
        label: label,
      }
    );
    setSuccessSB(result.status === 200);
  };

  const saveUserAlerts = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${user.address}/alerts`,
      {
        withCredentials: true,
        highBet: alertHighBet,
        approveWithdraw: alertApproveWithdraw,
        winRate: alertWinRate,
        profit: alertProfit,
      }
    );

    setSuccessSB(result.status === 200);
  };

  const saveUserNickname = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${user.address}/nickname`,
      {
        withCredentials: true,
        nickname: nickname,
      }
    );

    setSuccessSB(result.status === 200);
  };

  return (
    <>
      <Card
        sx={({
          palette: { gradients },
          functions: { linearGradient },
          boxShadows: { xl },
        }) => ({
          background: gradients[color]
            ? linearGradient(gradients[color].main, gradients[color].state)
            : linearGradient(gradients.dark.main, gradients.dark.state),
          boxShadow: xl,
          position: "relative",
        })}
      >
        <MDBox
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          opacity={0.2}
          sx={{
            backgroundImage: `url(${pattern})`,
            backgroundSize: "cover",
          }}
        />
        <MDBox position="relative" zIndex={2} p={2}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <MDTypography
                variant="h5"
                color="white"
                fontWeight="medium"
                sx={{ mt: 3, mb: 1, pb: 1 }}
              >
                {user.nickname && user.nickname + " : "} {user.address}{" "}
                {user.listing != "none" && user.listing + "listed"}
              </MDTypography>
              <MDTypography
                variant="h5"
                color="white"
                fontWeight="medium"
                sx={{ mt: 1, mb: 1, pb: 1 }}
              >
                Referrer: {user.reference.referrer1}
              </MDTypography>
              <MDTypography
                variant="h5"
                color="white"
                fontWeight="medium"
                sx={{ mt: 1, pb: 1 }}
              >
                ReferredBy Level1: {user.reference.referredBy1.length}
              </MDTypography>{" "}
              <MDTypography
                variant="h5"
                color="white"
                fontWeight="medium"
                sx={{ mt: 1, pb: 1 }}
              >
                ReferredBy Level2: {user.reference.referredBy2.length}
              </MDTypography>{" "}
              <MDTypography
                variant="h5"
                color="white"
                fontWeight="medium"
                sx={{ mt: 1, pb: 1 }}
              >
                ReferredBy Level3: {user.reference.referredBy3.length}
              </MDTypography>
            </Grid>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <InputLabel id="userList">UserList</InputLabel>
                <Select
                  fullWidth
                  labelId="userList"
                  id="userList"
                  value={list}
                  label="UserList"
                  variant="outlined"
                  style={{ height: "3em" }}
                  onChange={(e) => setList(e.target.value)}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="white">White</MenuItem>
                  <MenuItem value="green">Green</MenuItem>
                  <MenuItem value="gray">Gray</MenuItem>
                  <MenuItem value="black">Black</MenuItem>
                </Select>
                <MDButton
                  fullWidth
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={saveUserList}
                >
                  Save User List
                </MDButton>
              </Grid>
              <Grid item xs={12} mt={3}>
                <MDInput
                  fullWidth
                  label="Enter user nickname"
                  value={nickname}
                  variant="outlined"
                  onChange={(e) => setNickname(e.target.value)}
                />
                <MDButton
                  fullWidth
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={saveUserNickname}
                >
                  Save User Nickname
                </MDButton>
              </Grid>
              <Grid item xs={12} mt={3}>
                <InputLabel id="userLabel">UserLabel</InputLabel>
                <Select
                  fullWidth
                  labelId="userLabel"
                  id="userLabel"
                  value={label}
                  label="UserLabel"
                  variant="outlined"
                  style={{ height: "3em" }}
                  onChange={(e) => setLabel(e.target.value)}
                >
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="checked">Checked</MenuItem>
                  <MenuItem value="follow">Follow</MenuItem>
                </Select>
                <MDButton
                  fullWidth
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={saveUserLabel}
                >
                  Save Label
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="check"
        title="Save Complete"
        content="User saved successfully"
        open={successSB}
        onClose={() => setSuccessSB(false)}
        close={() => setSuccessSB(false)}
        bgWhite
      />
    </>
  );
}

export default UserCard;
