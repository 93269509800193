// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AlertDialog from "./components/Dialog";

import MDInput from "components/MDInput";
import FormControl from "@mui/material/FormControl";

// Data
import { useEffect, useState } from "react";
import axios from "axios";
import { scanTxLink, shortened, getMinutesDiffText } from "helpers";

const Item = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    {description && (
      <MDTypography variant="caption">{description}</MDTypography>
    )}
  </MDBox>
);

function Partners() {
  const [data, setData] = useState();
  const [currentPartner, setCurrentPartner] = useState();
  const [newPartnerName, setNewPartnerName] = useState("");
  const [newPartnerAddress, setNewPartnerAddress] = useState("");
  const [open, setOpen] = useState(false);

  const openSetRateModal = ({ partner }) => {
    setCurrentPartner(partner);
    setOpen(true);
  };

  const handleSetRate = async (name, rates) => {
    const result = await axios.put(
      process.env.REACT_APP_API_URI + `/api/v1/dashboard/partners/${name}`,
      {
        withCredentials: true,
        rates,
      }
    );

    console.log(result);
    loadData();
    setOpen(false);
  };

  const handleAddNewPartner = async () => {
    if (newPartnerName.length < 3 || newPartnerAddress.length < 30) return;

    const result = await axios.post(
      process.env.REACT_APP_API_URI + `/api/v1/dashboard/partners`,
      {
        withCredentials: true,
        name: newPartnerName,
        address: newPartnerAddress,
      }
    );

    console.log(result);
    loadData();
  };

  const ActionCell = ({ partner }) => (
    <MDBox>
      <MDButton
        variant="gradient"
        color="success"
        size="small"
        onClick={() => openSetRateModal({ partner })}
      >
        Set Rates
      </MDButton>
    </MDBox>
  );

  const loadData = async () => {
    const result = await axios.get(
      process.env.REACT_APP_API_URI + "/api/v1/dashboard/partners",
      {
        withCredentials: true,
      }
    );

    console.log(result.data)

    const rows = result.data.map((b) => {
      return {
        name: b.name,
        address: b.address,
        numUsers: b.user.reference.referredBy1.length,
        rates: (
          <ul>
            {b.rates.map((r) => (
              <li key={r.network}>{r.network + " : " + r.rate + "%"}</li>
            ))}
          </ul>
        ),
        set: <ActionCell partner={b} />,
      };
    });

    const items = {
      columns: [
        {
          Header: "name",
          accessor: "name",
          align: "left",
        },
        {
          Header: "address",
          accessor: "address",
          align: "left",
        },
        {
          Header: "num Users",
          accessor: "numUsers",
          align: "center",
        },
        {
          Header: "rates",
          accessor: "rates",
          align: "left",
        },
        {
          Header: "set",
          accessor: "set",
          align: "left",
        },
      ],

      rows,
    };

    setData(items);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentPartner && (
        <AlertDialog
          handleClose={() => setOpen(false)}
          handleConfirm={handleSetRate}
          item={currentPartner}
          open={open}
          title={"Save partner rates"}
        />
      )}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={8}></Grid>

              <Grid item xs={4}>
                <Grid item xs={12} mt={1}>
                  <MDInput
                    fullWidth
                    label="New Partner Name"
                    onChange={(e) => setNewPartnerName(e.target.value)}
                    value={newPartnerName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <MDInput
                    fullWidth
                    label="New Partner Address"
                    onChange={(e) => setNewPartnerAddress(e.target.value)}
                    value={newPartnerAddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <MDButton
                    fullWidth
                    variant="gradient"
                    color="success"
                    onClick={() => handleAddNewPartner()}
                  >
                    Add New Partner
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Partners
                </MDTypography>
              </MDBox>
              {data && (
                <MDBox pt={3}>
                  <DataTable
                    table={data}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Partners;
