import { useContext } from "react";
import {
  useSignMessage,
  useDisconnect,
  useAccount,
  useNetwork,
  useConnect,
} from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { AuthContext } from "App";
import { polygon, bsc } from "wagmi/chains";

function Basic() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { signMessageAsync } = useSignMessage({});
  const { connectAsync } = useConnect({
    connector: new InjectedConnector({
      chains: [polygon, bsc],
    }),
  });

  const authenticate = async () => {
    console.log("authenticating", isConnected);
    let userData;
    if (isConnected) {
      userData = {
        address,
        chain: chain.id,
        network: "evm",
      };
    } else {
      const connection = await connectAsync();
      userData = {
        address: connection.account,
        chain: connection.chain?.id,
        network: "evm",
      };
    }

    axios
      .post(`${process.env.REACT_APP_API_URI}/auth/request-message`, userData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
        },
      })
      .then((res) => {
        const message = res.data.message;
        const nonce = res.data.nonce;
        signMessageAsync({ message: message })
          .then((res) => {
            verify(message, nonce, res);
          })
          .catch((error) => {
            console.log(error.message);
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  function verify(message, nonce, signature) {
    //console.log(message, signature);
    var body = {
      message: message,
      nonce,
      address,
      signature: signature,
    };

    console.log("verify body", body);
    axios
      .post(`${process.env.REACT_APP_API_URI}/auth/verify`, body, {
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setIsAuthenticated(true);
      })
      .catch((error) => {});
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={1} px={3}>
          <MDBox component="form" role="form">
            <MDBox mt={1} mb={1}>
              <MDButton
                variant="gradient"
                color="success"
                fullWidth
                onClick={() => authenticate()}
              >
                connect
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
