// @mui material components
import Grid from "@mui/material/Grid";
import { ethers } from "ethers";

import Stack from "@mui/material/Stack";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { shortened } from "helpers";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import classicABI from "../../constants/classicABI";
import classicNewABI from "../../constants/classicNewABI";
import { TopWinnersTable } from "./components/TopWinnersTable";

const web3Bsc = new Web3("https://bsc-dataseed3.defibit.io/");
const web3Matic = new Web3("https://polygon-rpc.com/");

const fromWei = (amt) => {
  const num = new BigNumber(amt.toString()).dividedBy(10 ** 18);
  return parseFloat(num.toFixed(2));
};

const predictionsData = [
  {
    name: "BSC New",
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    interval: 300,
    network: 0,
    web3: web3Bsc,
    abi: classicNewABI,
    multiple: true,
    id: "0",
  },
  {
    name: "Poly New",
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    interval: 300,
    network: 1,
    web3: web3Matic,
    abi: classicNewABI,
    multiple: true,
    id: "0",
  },
];

const predictionContracts = [];
for (let i = 0; i < predictionsData.length; i++) {
  const web3ToUse = predictionsData[i].web3;

  predictionContracts.push(
    new web3ToUse.eth.Contract(
      predictionsData[i].abi,
      predictionsData[i].address
    )
  );
}

const getNativeBalance = async (web3, address) => {
  const balanceWei = await web3.eth.getBalance(address);
  const balance = BigNumber(balanceWei.toString())
    .dividedBy(10 ** 18)
    .toFixed(2);
  return balance;
};

function SuggestedLiquidity() {
  const [users, setUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [threeMonthsUsers, setThreeMonthsUsers] = useState([]);
  const [polygonWinners, setPolygonWinners] = useState([]);
  const [hideBelow, setHideBelow] = useState(10);
  const [nicknames, setNicknames] = useState({});

  const saveUserLabel = async (address, label) => {
    console.log("saving user label");
    const result = await axios.post(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/users/${address}/label`,
      {
        withCredentials: true,
        label: label,
      }
    );
  };

  const loadNicknames = async () => {
    console.log("loading nicknames");
    const result = await axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/nicknames`,
      {
        withCredentials: true,
      }
    );
    let nicks = {};
    for (let i = 0; i < result.data.length; i++) {
      let nickname = result.data[i].nickname;
      if (nickname?.length === 0) nickname = null;
      let label = result.data[i].label;
      if (label?.length === 0) label = null;
      nicks[result.data[i].address.toLowerCase()] = { nickname, label };
    }
    setNicknames(nicks);
  };

  useEffect(() => {
    loadNicknames();
  }, []);

  const handleSetUser = async (address) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const _contract = new ethers.Contract(
      predictionsData[0].address,
      classicNewABI,
      signer
    );

    await _contract.setExtraLiquidityUsers([address], true);
  };

  const handleUnsetUser = async (address) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const _contract = new ethers.Contract(
      predictionsData[0].address,
      classicNewABI,
      signer
    );

    await _contract.setExtraLiquidityUsers([address], false);
  };

  const loadUsers = async (network) => {
    const data = await axios.get(
      "https://api.prdt.finance/api/v1/dashboard/users/suggestedLiquidity",
      {
        withCredentials: true,
      }
    );
    let result = data.data;

    const calls = [];

    let userAddresses = result[network].usersInProfit.map((u) => u.sender);

    for (let i = 0; i < userAddresses.length; i++) {
      calls.push(
        predictionContracts[0].methods
          .extraLiquidityUsers(userAddresses[i])
          .call()
      );
    }
    let alreadySet = await Promise.all(calls);

    for (let i = 0; i < result.length; i++) {
      result[network].usersInProfit.alreadySet = alreadySet[i];
    }

    userAddresses = result[network].newUsers.map((u) => u.sender);

    for (let i = 0; i < userAddresses.length; i++) {
      calls.push(
        predictionContracts[0].methods
          .extraLiquidityUsers(userAddresses[i])
          .call()
      );
    }
    alreadySet = await Promise.all(calls);

    for (let i = 0; i < result.length; i++) {
      result[network].newUsers.alreadySet = alreadySet[i];
    }
    return result[network];
  };

  useEffect(() => {
    const loadData = async () => {
      //const winnersPolygon = await loadWinners("polygon");
      const usersBsc = await loadUsers("bsc");
      console.log(usersBsc);
      setUsers(usersBsc.usersInProfit);
      setNewUsers(usersBsc.newUsers);
      setThreeMonthsUsers(usersBsc.threeMonthsUsers);
      //setPolygonWinners(winnersPolygon);
    };
    loadData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                BSC UsersInProfit
                <table className="min-w-full">
                  <thead className="bg-white border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        User
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        AlreadySet?
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Label
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Total Bet
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Count Bet
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        HouseProfit
                      </th>{" "}
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, i) => (
                      <tr
                        key={user.sender}
                        className={
                          i % 2 == 1 ? "bg-white" : "bg-gray-100" + " border-b"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <a href={"/classic/user/" + user.sender}>
                            {nicknames[user.sender]?.nickname
                              ? nicknames[user.sender].nickname
                              : user.sender}
                          </a>
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.alreadySet ? "set" : ""}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {nicknames[user.sender]?.label || ""}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.totalBet}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.countBet}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.houseProfit}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {!user.alreadySet ? (
                            <button onClick={() => handleSetUser(user.address)}>
                              Set User
                            </button>
                          ) : (
                            <button
                              onClick={() => handleUnsetUser(user.address)}
                            >
                              Unset User
                            </button>
                          )}
                          {/* <Grid item xs={12} mt={3}>
                            <Select
                              fullWidth
                              labelId="userLabel"
                              id="userLabel"
                              value={nicknames[user.sender]?.label || "none"}
                              label="UserLabel"
                              variant="outlined"
                              style={{ height: "3em" }}
                              onChange={(e) =>
                                saveUserLabel(user.sender, e.target.value)
                              }
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="checked">Checked</MenuItem>
                              <MenuItem value="follow">Follow</MenuItem>
                            </Select>
                          </Grid> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                BSC New Users
                <table className="min-w-full">
                  <thead className="bg-white border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        User
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        AlreadySet?
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Label
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Total Bet
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Count Bet
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        HouseProfit
                      </th>{" "}
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {newUsers.map((user, i) => (
                      <tr
                        key={user.sender}
                        className={
                          i % 2 == 1 ? "bg-white" : "bg-gray-100" + " border-b"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <a href={"/classic/user/" + user.sender}>
                            {nicknames[user.sender]?.nickname
                              ? nicknames[user.sender].nickname
                              : user.sender}
                          </a>
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.alreadySet ? "set" : ""}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {nicknames[user.sender]?.label || ""}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.totalBet}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.countBet}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.houseProfit}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {!user.alreadySet ? (
                            <button onClick={() => handleSetUser(user.sender)}>
                              Set User
                            </button>
                          ) : (
                            <button
                              onClick={() => handleUnsetUser(user.sender)}
                            >
                              Unset User
                            </button>
                          )}
                          {/* <Grid item xs={12} mt={3}>
                            <Select
                              fullWidth
                              labelId="userLabel"
                              id="userLabel"
                              value={nicknames[user.sender]?.label || "none"}
                              label="UserLabel"
                              variant="outlined"
                              style={{ height: "3em" }}
                              onChange={(e) =>
                                saveUserLabel(user.sender, e.target.value)
                              }
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="checked">Checked</MenuItem>
                              <MenuItem value="follow">Follow</MenuItem>
                            </Select>
                          </Grid> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                BSC Last 90 Days
                <table className="min-w-full">
                  <thead className="bg-white border-b">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        User
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        AlreadySet?
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Label
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Total Bet
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Count Bet
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        HouseProfit
                      </th>{" "}
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {threeMonthsUsers.map((user, i) => (
                      <tr
                        key={user.sender}
                        className={
                          i % 2 == 1 ? "bg-white" : "bg-gray-100" + " border-b"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <a href={"/classic/user/" + user.sender}>
                            {nicknames[user.sender]?.nickname
                              ? nicknames[user.sender].nickname
                              : user.sender}
                          </a>
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.alreadySet ? "set" : ""}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {nicknames[user.sender]?.label || ""}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.totalBet}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.countBet}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {user.houseProfit}
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {!user.alreadySet ? (
                            <button onClick={() => handleSetUser(user.sender)}>
                              Set User
                            </button>
                          ) : (
                            <button
                              onClick={() => handleUnsetUser(user.sender)}
                            >
                              Unset User
                            </button>
                          )}
                          {/* <Grid item xs={12} mt={3}>
                            <Select
                              fullWidth
                              labelId="userLabel"
                              id="userLabel"
                              value={nicknames[user.sender]?.label || "none"}
                              label="UserLabel"
                              variant="outlined"
                              style={{ height: "3em" }}
                              onChange={(e) =>
                                saveUserLabel(user.sender, e.target.value)
                              }
                            >
                              <MenuItem value="none">None</MenuItem>
                              <MenuItem value="checked">Checked</MenuItem>
                              <MenuItem value="follow">Follow</MenuItem>
                            </Select>
                          </Grid> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </DashboardLayout>
  );
}

export default SuggestedLiquidity;
