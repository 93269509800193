// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useEffect, useState } from "react";
import axios from "axios";
import JournalTable from "./JournalTable";

function Tables() {
  const [data, setData] = useState();
  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    const load = async () => {
      if (!canUpdate) return;
      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/journals",
        {
          withCredentials: true,
        }
      );
      setData(result.data);
    };

    load();
  }, [canUpdate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {data && <JournalTable data={data} setCanUpdate={setCanUpdate} />}
    </DashboardLayout>
  );
}

export default Tables;
