module.exports = [
    {
        "time": 1689719670000,
        "value": 138.805,
        "_internal_originalTime": 1689719670000
    },
    {
        "time": 1689719688000,
        "value": 138.802,
        "_internal_originalTime": 1689719688000
    },
    {
        "time": 1689719703000,
        "value": 138.802,
        "_internal_originalTime": 1689719703000
    },
    {
        "time": 1689719718000,
        "value": 138.802,
        "_internal_originalTime": 1689719718000
    },
    {
        "time": 1689719733000,
        "value": 138.801,
        "_internal_originalTime": 1689719733000
    },
    {
        "time": 1689719749000,
        "value": 138.805,
        "_internal_originalTime": 1689719749000
    },
    {
        "time": 1689719762000,
        "value": 138.797,
        "_internal_originalTime": 1689719762000
    },
    {
        "time": 1689719776000,
        "value": 138.801,
        "_internal_originalTime": 1689719776000
    },
    {
        "time": 1689719785000,
        "value": 138.8,
        "_internal_originalTime": 1689719785000
    },
    {
        "time": 1689719806000,
        "value": 138.802,
        "_internal_originalTime": 1689719806000
    },
    {
        "time": 1689719821000,
        "value": 138.801,
        "_internal_originalTime": 1689719821000
    },
    {
        "time": 1689719836000,
        "value": 138.806,
        "_internal_originalTime": 1689719836000
    },
    {
        "time": 1689719847000,
        "value": 138.804,
        "_internal_originalTime": 1689719847000
    },
    {
        "time": 1689719866000,
        "value": 138.807,
        "_internal_originalTime": 1689719866000
    },
    {
        "time": 1689719882000,
        "value": 138.807,
        "_internal_originalTime": 1689719882000
    },
    {
        "time": 1689719896000,
        "value": 138.806,
        "_internal_originalTime": 1689719896000
    },
    {
        "time": 1689719912000,
        "value": 138.799,
        "_internal_originalTime": 1689719912000
    },
    {
        "time": 1689719926000,
        "value": 138.807,
        "_internal_originalTime": 1689719926000
    },
    {
        "time": 1689719937000,
        "value": 138.804,
        "_internal_originalTime": 1689719937000
    },
    {
        "time": 1689719940000,
        "value": 138.796,
        "_internal_originalTime": 1689719940000
    },
    {
        "time": 1689719941000,
        "value": 138.797,
        "_internal_originalTime": 1689719941000
    },
    {
        "time": 1689719943000,
        "value": 138.803,
        "_internal_originalTime": 1689719943000
    },
    {
        "time": 1689719958000,
        "value": 138.803,
        "_internal_originalTime": 1689719958000
    },
    {
        "time": 1689719971000,
        "value": 138.805,
        "_internal_originalTime": 1689719971000
    },
    {
        "time": 1689719987000,
        "value": 138.806,
        "_internal_originalTime": 1689719987000
    },
    {
        "time": 1689720004000,
        "value": 138.804,
        "_internal_originalTime": 1689720004000
    },
    {
        "time": 1689720018000,
        "value": 138.8,
        "_internal_originalTime": 1689720018000
    },
    {
        "time": 1689720034000,
        "value": 138.792,
        "_internal_originalTime": 1689720034000
    },
    {
        "time": 1689720048000,
        "value": 138.791,
        "_internal_originalTime": 1689720048000
    },
    {
        "time": 1689720064000,
        "value": 138.795,
        "_internal_originalTime": 1689720064000
    },
    {
        "time": 1689720078000,
        "value": 138.802,
        "_internal_originalTime": 1689720078000
    },
    {
        "time": 1689720094000,
        "value": 138.802,
        "_internal_originalTime": 1689720094000
    },
    {
        "time": 1689720109000,
        "value": 138.803,
        "_internal_originalTime": 1689720109000
    },
    {
        "time": 1689720124000,
        "value": 138.803,
        "_internal_originalTime": 1689720124000
    },
    {
        "time": 1689720138000,
        "value": 138.8,
        "_internal_originalTime": 1689720138000
    },
    {
        "time": 1689720149000,
        "value": 138.807,
        "_internal_originalTime": 1689720149000
    },
    {
        "time": 1689720168000,
        "value": 138.801,
        "_internal_originalTime": 1689720168000
    },
    {
        "time": 1689720185000,
        "value": 138.804,
        "_internal_originalTime": 1689720185000
    },
    {
        "time": 1689720199000,
        "value": 138.807,
        "_internal_originalTime": 1689720199000
    },
    {
        "time": 1689720212000,
        "value": 138.808,
        "_internal_originalTime": 1689720212000
    },
    {
        "time": 1689720228000,
        "value": 138.8,
        "_internal_originalTime": 1689720228000
    },
    {
        "time": 1689720238000,
        "value": 138.806,
        "_internal_originalTime": 1689720238000
    },
    {
        "time": 1689720240000,
        "value": 138.806,
        "_internal_originalTime": 1689720240000
    },
    {
        "time": 1689720241000,
        "value": 138.81,
        "_internal_originalTime": 1689720241000
    },
    {
        "time": 1689720243000,
        "value": 138.804,
        "_internal_originalTime": 1689720243000
    },
    {
        "time": 1689720258000,
        "value": 138.805,
        "_internal_originalTime": 1689720258000
    },
    {
        "time": 1689720274000,
        "value": 138.803,
        "_internal_originalTime": 1689720274000
    },
    {
        "time": 1689720289000,
        "value": 138.781,
        "_internal_originalTime": 1689720289000
    },
    {
        "time": 1689720299000,
        "value": 138.791,
        "_internal_originalTime": 1689720299000
    },
    {
        "time": 1689720307000,
        "value": 138.779,
        "_internal_originalTime": 1689720307000
    },
    {
        "time": 1689720316000,
        "value": 138.78,
        "_internal_originalTime": 1689720316000
    },
    {
        "time": 1689720326000,
        "value": 138.794,
        "_internal_originalTime": 1689720326000
    },
    {
        "time": 1689720338000,
        "value": 138.791,
        "_internal_originalTime": 1689720338000
    },
    {
        "time": 1689720351000,
        "value": 138.793,
        "_internal_originalTime": 1689720351000
    },
    {
        "time": 1689720359000,
        "value": 138.802,
        "_internal_originalTime": 1689720359000
    },
    {
        "time": 1689720374000,
        "value": 138.803,
        "_internal_originalTime": 1689720374000
    },
    {
        "time": 1689720389000,
        "value": 138.805,
        "_internal_originalTime": 1689720389000
    },
    {
        "time": 1689720401000,
        "value": 138.801,
        "_internal_originalTime": 1689720401000
    },
    {
        "time": 1689720414000,
        "value": 138.808,
        "_internal_originalTime": 1689720414000
    },
    {
        "time": 1689720428000,
        "value": 138.807,
        "_internal_originalTime": 1689720428000
    },
    {
        "time": 1689720441000,
        "value": 138.811,
        "_internal_originalTime": 1689720441000
    },
    {
        "time": 1689720451000,
        "value": 138.814,
        "_internal_originalTime": 1689720451000
    },
    {
        "time": 1689720466000,
        "value": 138.817,
        "_internal_originalTime": 1689720466000
    },
    {
        "time": 1689720482000,
        "value": 138.84,
        "_internal_originalTime": 1689720482000
    },
    {
        "time": 1689720496000,
        "value": 138.846,
        "_internal_originalTime": 1689720496000
    },
    {
        "time": 1689720507000,
        "value": 138.851,
        "_internal_originalTime": 1689720507000
    },
    {
        "time": 1689720519000,
        "value": 138.848,
        "_internal_originalTime": 1689720519000
    },
    {
        "time": 1689720535000,
        "value": 138.851,
        "_internal_originalTime": 1689720535000
    },
    {
        "time": 1689720550000,
        "value": 138.842,
        "_internal_originalTime": 1689720550000
    },
    {
        "time": 1689720563000,
        "value": 138.86,
        "_internal_originalTime": 1689720563000
    },
    {
        "time": 1689720566000,
        "value": 138.861,
        "_internal_originalTime": 1689720566000
    },
    {
        "time": 1689720584000,
        "value": 138.855,
        "_internal_originalTime": 1689720584000
    },
    {
        "time": 1689720599000,
        "value": 138.838,
        "_internal_originalTime": 1689720599000
    },
    {
        "time": 1689720614000,
        "value": 138.848,
        "_internal_originalTime": 1689720614000
    },
    {
        "time": 1689720622000,
        "value": 138.839,
        "_internal_originalTime": 1689720622000
    },
    {
        "time": 1689720640000,
        "value": 138.852,
        "_internal_originalTime": 1689720640000
    },
    {
        "time": 1689720654000,
        "value": 138.854,
        "_internal_originalTime": 1689720654000
    },
    {
        "time": 1689720671000,
        "value": 138.85,
        "_internal_originalTime": 1689720671000
    },
    {
        "time": 1689720686000,
        "value": 138.849,
        "_internal_originalTime": 1689720686000
    },
    {
        "time": 1689720701000,
        "value": 138.847,
        "_internal_originalTime": 1689720701000
    },
    {
        "time": 1689720717000,
        "value": 138.853,
        "_internal_originalTime": 1689720717000
    },
    {
        "time": 1689720727000,
        "value": 138.856,
        "_internal_originalTime": 1689720727000
    },
    {
        "time": 1689720747000,
        "value": 138.848,
        "_internal_originalTime": 1689720747000
    },
    {
        "time": 1689720761000,
        "value": 138.852,
        "_internal_originalTime": 1689720761000
    },
    {
        "time": 1689720776000,
        "value": 138.853,
        "_internal_originalTime": 1689720776000
    },
    {
        "time": 1689720792000,
        "value": 138.852,
        "_internal_originalTime": 1689720792000
    },
    {
        "time": 1689720806000,
        "value": 138.855,
        "_internal_originalTime": 1689720806000
    },
    {
        "time": 1689720820000,
        "value": 138.849,
        "_internal_originalTime": 1689720820000
    },
    {
        "time": 1689720836000,
        "value": 138.854,
        "_internal_originalTime": 1689720836000
    },
    {
        "time": 1689720851000,
        "value": 138.849,
        "_internal_originalTime": 1689720851000
    },
    {
        "time": 1689720866000,
        "value": 138.855,
        "_internal_originalTime": 1689720866000
    },
    {
        "time": 1689720869000,
        "value": 138.835,
        "_internal_originalTime": 1689720869000
    },
    {
        "time": 1689720871000,
        "value": 138.833,
        "_internal_originalTime": 1689720871000
    },
    {
        "time": 1689720886000,
        "value": 138.853,
        "_internal_originalTime": 1689720886000
    },
    {
        "time": 1689720901000,
        "value": 138.851,
        "_internal_originalTime": 1689720901000
    },
    {
        "time": 1689720916000,
        "value": 138.864,
        "_internal_originalTime": 1689720916000
    },
    {
        "time": 1689720928000,
        "value": 138.857,
        "_internal_originalTime": 1689720928000
    },
    {
        "time": 1689720943000,
        "value": 138.861,
        "_internal_originalTime": 1689720943000
    },
    {
        "time": 1689720960000,
        "value": 138.869,
        "_internal_originalTime": 1689720960000
    },
    {
        "time": 1689720974000,
        "value": 138.881,
        "_internal_originalTime": 1689720974000
    },
    {
        "time": 1689720984000,
        "value": 138.884,
        "_internal_originalTime": 1689720984000
    },
    {
        "time": 1689721000000,
        "value": 138.882,
        "_internal_originalTime": 1689721000000
    },
    {
        "time": 1689721013000,
        "value": 138.883,
        "_internal_originalTime": 1689721013000
    },
    {
        "time": 1689721027000,
        "value": 138.868,
        "_internal_originalTime": 1689721027000
    },
    {
        "time": 1689721048000,
        "value": 138.917,
        "_internal_originalTime": 1689721048000
    },
    {
        "time": 1689721063000,
        "value": 138.926,
        "_internal_originalTime": 1689721063000
    },
    {
        "time": 1689721073000,
        "value": 138.931,
        "_internal_originalTime": 1689721073000
    },
    {
        "time": 1689721092000,
        "value": 138.93,
        "_internal_originalTime": 1689721092000
    },
    {
        "time": 1689721109000,
        "value": 138.937,
        "_internal_originalTime": 1689721109000
    },
    {
        "time": 1689721118000,
        "value": 138.928,
        "_internal_originalTime": 1689721118000
    },
    {
        "time": 1689721139000,
        "value": 138.928,
        "_internal_originalTime": 1689721139000
    },
    {
        "time": 1689721140000,
        "value": 138.926,
        "_internal_originalTime": 1689721140000
    },
    {
        "time": 1689721144000,
        "value": 138.927,
        "_internal_originalTime": 1689721144000
    },
    {
        "time": 1689721159000,
        "value": 138.922,
        "_internal_originalTime": 1689721159000
    },
    {
        "time": 1689721173000,
        "value": 138.926,
        "_internal_originalTime": 1689721173000
    },
    {
        "time": 1689721190000,
        "value": 138.915,
        "_internal_originalTime": 1689721190000
    },
    {
        "time": 1689721200000,
        "value": 138.908,
        "_internal_originalTime": 1689721200000
    },
    {
        "time": 1689721211000,
        "value": 138.903,
        "_internal_originalTime": 1689721211000
    },
    {
        "time": 1689721222000,
        "value": 138.9,
        "_internal_originalTime": 1689721222000
    },
    {
        "time": 1689721235000,
        "value": 138.9,
        "_internal_originalTime": 1689721235000
    },
    {
        "time": 1689721245000,
        "value": 138.9,
        "_internal_originalTime": 1689721245000
    },
    {
        "time": 1689721256000,
        "value": 138.9,
        "_internal_originalTime": 1689721256000
    },
    {
        "time": 1689721269000,
        "value": 138.897,
        "_internal_originalTime": 1689721269000
    },
    {
        "time": 1689721280000,
        "value": 138.904,
        "_internal_originalTime": 1689721280000
    },
    {
        "time": 1689721291000,
        "value": 138.901,
        "_internal_originalTime": 1689721291000
    },
    {
        "time": 1689721304000,
        "value": 138.899,
        "_internal_originalTime": 1689721304000
    },
    {
        "time": 1689721318000,
        "value": 138.902,
        "_internal_originalTime": 1689721318000
    },
    {
        "time": 1689721324000,
        "value": 138.9,
        "_internal_originalTime": 1689721324000
    },
    {
        "time": 1689721339000,
        "value": 138.895,
        "_internal_originalTime": 1689721339000
    },
    {
        "time": 1689721359000,
        "value": 138.898,
        "_internal_originalTime": 1689721359000
    },
    {
        "time": 1689721372000,
        "value": 138.902,
        "_internal_originalTime": 1689721372000
    },
    {
        "time": 1689721385000,
        "value": 138.89,
        "_internal_originalTime": 1689721385000
    },
    {
        "time": 1689721397000,
        "value": 138.902,
        "_internal_originalTime": 1689721397000
    },
    {
        "time": 1689721403000,
        "value": 138.902,
        "_internal_originalTime": 1689721403000
    },
    {
        "time": 1689721414000,
        "value": 138.9,
        "_internal_originalTime": 1689721414000
    },
    {
        "time": 1689721428000,
        "value": 138.902,
        "_internal_originalTime": 1689721428000
    },
    {
        "time": 1689721441000,
        "value": 138.902,
        "_internal_originalTime": 1689721441000
    },
    {
        "time": 1689721456000,
        "value": 138.899,
        "_internal_originalTime": 1689721456000
    },
    {
        "time": 1689721465000,
        "value": 138.905,
        "_internal_originalTime": 1689721465000
    },
    {
        "time": 1689721467000,
        "value": 138.904,
        "_internal_originalTime": 1689721467000
    },
    {
        "time": 1689721477000,
        "value": 138.899,
        "_internal_originalTime": 1689721477000
    },
    {
        "time": 1689721490000,
        "value": 138.903,
        "_internal_originalTime": 1689721490000
    },
    {
        "time": 1689721505000,
        "value": 138.902,
        "_internal_originalTime": 1689721505000
    },
    {
        "time": 1689721518000,
        "value": 138.907,
        "_internal_originalTime": 1689721518000
    },
    {
        "time": 1689721525000,
        "value": 138.916,
        "_internal_originalTime": 1689721525000
    },
    {
        "time": 1689721538000,
        "value": 138.915,
        "_internal_originalTime": 1689721538000
    },
    {
        "time": 1689721549000,
        "value": 138.912,
        "_internal_originalTime": 1689721549000
    },
    {
        "time": 1689721562000,
        "value": 138.909,
        "_internal_originalTime": 1689721562000
    },
    {
        "time": 1689721575000,
        "value": 138.911,
        "_internal_originalTime": 1689721575000
    },
    {
        "time": 1689721586000,
        "value": 138.912,
        "_internal_originalTime": 1689721586000
    },
    {
        "time": 1689721595000,
        "value": 138.91,
        "_internal_originalTime": 1689721595000
    },
    {
        "time": 1689721608000,
        "value": 138.913,
        "_internal_originalTime": 1689721608000
    },
    {
        "time": 1689721620000,
        "value": 138.91,
        "_internal_originalTime": 1689721620000
    },
    {
        "time": 1689721637000,
        "value": 138.912,
        "_internal_originalTime": 1689721637000
    },
    {
        "time": 1689721650000,
        "value": 138.911,
        "_internal_originalTime": 1689721650000
    },
    {
        "time": 1689721656000,
        "value": 138.908,
        "_internal_originalTime": 1689721656000
    },
    {
        "time": 1689721677000,
        "value": 138.91,
        "_internal_originalTime": 1689721677000
    },
    {
        "time": 1689721690000,
        "value": 138.893,
        "_internal_originalTime": 1689721690000
    },
    {
        "time": 1689721703000,
        "value": 138.89,
        "_internal_originalTime": 1689721703000
    },
    {
        "time": 1689721709000,
        "value": 138.887,
        "_internal_originalTime": 1689721709000
    },
    {
        "time": 1689721724000,
        "value": 138.891,
        "_internal_originalTime": 1689721724000
    },
    {
        "time": 1689721738000,
        "value": 138.895,
        "_internal_originalTime": 1689721738000
    },
    {
        "time": 1689721756000,
        "value": 138.896,
        "_internal_originalTime": 1689721756000
    },
    {
        "time": 1689721769000,
        "value": 138.895,
        "_internal_originalTime": 1689721769000
    },
    {
        "time": 1689721785000,
        "value": 138.898,
        "_internal_originalTime": 1689721785000
    },
    {
        "time": 1689721796000,
        "value": 138.902,
        "_internal_originalTime": 1689721796000
    },
    {
        "time": 1689721804000,
        "value": 138.896,
        "_internal_originalTime": 1689721804000
    },
    {
        "time": 1689721821000,
        "value": 138.895,
        "_internal_originalTime": 1689721821000
    },
    {
        "time": 1689721833000,
        "value": 138.893,
        "_internal_originalTime": 1689721833000
    },
    {
        "time": 1689721851000,
        "value": 138.899,
        "_internal_originalTime": 1689721851000
    },
    {
        "time": 1689721858000,
        "value": 138.902,
        "_internal_originalTime": 1689721858000
    },
    {
        "time": 1689721872000,
        "value": 138.903,
        "_internal_originalTime": 1689721872000
    },
    {
        "time": 1689721887000,
        "value": 138.907,
        "_internal_originalTime": 1689721887000
    },
    {
        "time": 1689721903000,
        "value": 138.904,
        "_internal_originalTime": 1689721903000
    },
    {
        "time": 1689721917000,
        "value": 138.904,
        "_internal_originalTime": 1689721917000
    },
    {
        "time": 1689721924000,
        "value": 138.905,
        "_internal_originalTime": 1689721924000
    },
    {
        "time": 1689721938000,
        "value": 138.904,
        "_internal_originalTime": 1689721938000
    },
    {
        "time": 1689721946000,
        "value": 138.901,
        "_internal_originalTime": 1689721946000
    },
    {
        "time": 1689721968000,
        "value": 138.901,
        "_internal_originalTime": 1689721968000
    },
    {
        "time": 1689721977000,
        "value": 138.91,
        "_internal_originalTime": 1689721977000
    },
    {
        "time": 1689721989000,
        "value": 138.912,
        "_internal_originalTime": 1689721989000
    },
    {
        "time": 1689722002000,
        "value": 138.914,
        "_internal_originalTime": 1689722002000
    },
    {
        "time": 1689722017000,
        "value": 138.91,
        "_internal_originalTime": 1689722017000
    },
    {
        "time": 1689722030000,
        "value": 138.914,
        "_internal_originalTime": 1689722030000
    },
    {
        "time": 1689722039000,
        "value": 138.918,
        "_internal_originalTime": 1689722039000
    },
    {
        "time": 1689722041000,
        "value": 138.918,
        "_internal_originalTime": 1689722041000
    },
    {
        "time": 1689722042000,
        "value": 138.925,
        "_internal_originalTime": 1689722042000
    },
    {
        "time": 1689722053000,
        "value": 138.93,
        "_internal_originalTime": 1689722053000
    },
    {
        "time": 1689722082000,
        "value": 138.937,
        "_internal_originalTime": 1689722082000
    },
    {
        "time": 1689722097000,
        "value": 138.944,
        "_internal_originalTime": 1689722097000
    },
    {
        "time": 1689722101000,
        "value": 138.946,
        "_internal_originalTime": 1689722101000
    },
    {
        "time": 1689722111000,
        "value": 138.946,
        "_internal_originalTime": 1689722111000
    },
    {
        "time": 1689722120000,
        "value": 138.949,
        "_internal_originalTime": 1689722120000
    },
    {
        "time": 1689722130000,
        "value": 138.942,
        "_internal_originalTime": 1689722130000
    },
    {
        "time": 1689722141000,
        "value": 138.944,
        "_internal_originalTime": 1689722141000
    },
    {
        "time": 1689722150000,
        "value": 138.946,
        "_internal_originalTime": 1689722150000
    },
    {
        "time": 1689722158000,
        "value": 138.947,
        "_internal_originalTime": 1689722158000
    },
    {
        "time": 1689722162000,
        "value": 138.946,
        "_internal_originalTime": 1689722162000
    },
    {
        "time": 1689722176000,
        "value": 138.942,
        "_internal_originalTime": 1689722176000
    },
    {
        "time": 1689722184000,
        "value": 138.946,
        "_internal_originalTime": 1689722184000
    },
    {
        "time": 1689722198000,
        "value": 138.945,
        "_internal_originalTime": 1689722198000
    },
    {
        "time": 1689722204000,
        "value": 138.946,
        "_internal_originalTime": 1689722204000
    },
    {
        "time": 1689722214000,
        "value": 138.948,
        "_internal_originalTime": 1689722214000
    },
    {
        "time": 1689722221000,
        "value": 138.93,
        "_internal_originalTime": 1689722221000
    },
    {
        "time": 1689722236000,
        "value": 138.948,
        "_internal_originalTime": 1689722236000
    },
    {
        "time": 1689722249000,
        "value": 138.946,
        "_internal_originalTime": 1689722249000
    },
    {
        "time": 1689722259000,
        "value": 138.951,
        "_internal_originalTime": 1689722259000
    },
    {
        "time": 1689722269000,
        "value": 138.96,
        "_internal_originalTime": 1689722269000
    },
    {
        "time": 1689722278000,
        "value": 138.958,
        "_internal_originalTime": 1689722278000
    },
    {
        "time": 1689722283000,
        "value": 138.946,
        "_internal_originalTime": 1689722283000
    },
    {
        "time": 1689722291000,
        "value": 138.96,
        "_internal_originalTime": 1689722291000
    },
    {
        "time": 1689722304000,
        "value": 138.97,
        "_internal_originalTime": 1689722304000
    },
    {
        "time": 1689722313000,
        "value": 138.973,
        "_internal_originalTime": 1689722313000
    },
    {
        "time": 1689722326000,
        "value": 138.971,
        "_internal_originalTime": 1689722326000
    },
    {
        "time": 1689722330000,
        "value": 138.971,
        "_internal_originalTime": 1689722330000
    },
    {
        "time": 1689722344000,
        "value": 138.967,
        "_internal_originalTime": 1689722344000
    },
    {
        "time": 1689722348000,
        "value": 138.956,
        "_internal_originalTime": 1689722348000
    },
    {
        "time": 1689722357000,
        "value": 138.961,
        "_internal_originalTime": 1689722357000
    },
    {
        "time": 1689722369000,
        "value": 138.964,
        "_internal_originalTime": 1689722369000
    },
    {
        "time": 1689722379000,
        "value": 138.967,
        "_internal_originalTime": 1689722379000
    },
    {
        "time": 1689722388000,
        "value": 138.96,
        "_internal_originalTime": 1689722388000
    },
    {
        "time": 1689722398000,
        "value": 138.95,
        "_internal_originalTime": 1689722398000
    },
    {
        "time": 1689722403000,
        "value": 138.955,
        "_internal_originalTime": 1689722403000
    },
    {
        "time": 1689722410000,
        "value": 138.955,
        "_internal_originalTime": 1689722410000
    },
    {
        "time": 1689722423000,
        "value": 138.961,
        "_internal_originalTime": 1689722423000
    },
    {
        "time": 1689722432000,
        "value": 138.963,
        "_internal_originalTime": 1689722432000
    },
    {
        "time": 1689722445000,
        "value": 138.962,
        "_internal_originalTime": 1689722445000
    },
    {
        "time": 1689722458000,
        "value": 138.962,
        "_internal_originalTime": 1689722458000
    },
    {
        "time": 1689722462000,
        "value": 138.963,
        "_internal_originalTime": 1689722462000
    },
    {
        "time": 1689722474000,
        "value": 138.966,
        "_internal_originalTime": 1689722474000
    },
    {
        "time": 1689722483000,
        "value": 138.962,
        "_internal_originalTime": 1689722483000
    },
    {
        "time": 1689722492000,
        "value": 138.953,
        "_internal_originalTime": 1689722492000
    },
    {
        "time": 1689722505000,
        "value": 138.949,
        "_internal_originalTime": 1689722505000
    },
    {
        "time": 1689722515000,
        "value": 138.947,
        "_internal_originalTime": 1689722515000
    },
    {
        "time": 1689722521000,
        "value": 138.949,
        "_internal_originalTime": 1689722521000
    },
    {
        "time": 1689722533000,
        "value": 138.95,
        "_internal_originalTime": 1689722533000
    },
    {
        "time": 1689722540000,
        "value": 138.948,
        "_internal_originalTime": 1689722540000
    },
    {
        "time": 1689722551000,
        "value": 138.946,
        "_internal_originalTime": 1689722551000
    },
    {
        "time": 1689722564000,
        "value": 138.948,
        "_internal_originalTime": 1689722564000
    },
    {
        "time": 1689722576000,
        "value": 138.946,
        "_internal_originalTime": 1689722576000
    },
    {
        "time": 1689722581000,
        "value": 138.948,
        "_internal_originalTime": 1689722581000
    },
    {
        "time": 1689722594000,
        "value": 138.95,
        "_internal_originalTime": 1689722594000
    },
    {
        "time": 1689722602000,
        "value": 138.948,
        "_internal_originalTime": 1689722602000
    },
    {
        "time": 1689722616000,
        "value": 138.947,
        "_internal_originalTime": 1689722616000
    },
    {
        "time": 1689722628000,
        "value": 138.95,
        "_internal_originalTime": 1689722628000
    },
    {
        "time": 1689722637000,
        "value": 138.948,
        "_internal_originalTime": 1689722637000
    },
    {
        "time": 1689722643000,
        "value": 138.949,
        "_internal_originalTime": 1689722643000
    },
    {
        "time": 1689722668000,
        "value": 138.948,
        "_internal_originalTime": 1689722668000
    },
    {
        "time": 1689722680000,
        "value": 138.964,
        "_internal_originalTime": 1689722680000
    },
    {
        "time": 1689722690000,
        "value": 138.97,
        "_internal_originalTime": 1689722690000
    },
    {
        "time": 1689722707000,
        "value": 138.964,
        "_internal_originalTime": 1689722707000
    },
    {
        "time": 1689722714000,
        "value": 138.968,
        "_internal_originalTime": 1689722714000
    },
    {
        "time": 1689722730000,
        "value": 138.97,
        "_internal_originalTime": 1689722730000
    },
    {
        "time": 1689722739000,
        "value": 138.972,
        "_internal_originalTime": 1689722739000
    },
    {
        "time": 1689722750000,
        "value": 138.971,
        "_internal_originalTime": 1689722750000
    },
    {
        "time": 1689722756000,
        "value": 138.972,
        "_internal_originalTime": 1689722756000
    },
    {
        "time": 1689722771000,
        "value": 138.97,
        "_internal_originalTime": 1689722771000
    },
    {
        "time": 1689722783000,
        "value": 138.969,
        "_internal_originalTime": 1689722783000
    },
    {
        "time": 1689722792000,
        "value": 138.967,
        "_internal_originalTime": 1689722792000
    },
    {
        "time": 1689722804000,
        "value": 138.973,
        "_internal_originalTime": 1689722804000
    },
    {
        "time": 1689722811000,
        "value": 138.967,
        "_internal_originalTime": 1689722811000
    },
    {
        "time": 1689722823000,
        "value": 138.968,
        "_internal_originalTime": 1689722823000
    },
    {
        "time": 1689722832000,
        "value": 138.97,
        "_internal_originalTime": 1689722832000
    },
    {
        "time": 1689722847000,
        "value": 138.974,
        "_internal_originalTime": 1689722847000
    },
    {
        "time": 1689722859000,
        "value": 138.971,
        "_internal_originalTime": 1689722859000
    },
    {
        "time": 1689722869000,
        "value": 138.98,
        "_internal_originalTime": 1689722869000
    },
    {
        "time": 1689722879000,
        "value": 138.978,
        "_internal_originalTime": 1689722879000
    },
    {
        "time": 1689722884000,
        "value": 138.98,
        "_internal_originalTime": 1689722884000
    },
    {
        "time": 1689722892000,
        "value": 138.98,
        "_internal_originalTime": 1689722892000
    },
    {
        "time": 1689722906000,
        "value": 138.985,
        "_internal_originalTime": 1689722906000
    },
    {
        "time": 1689722917000,
        "value": 138.992,
        "_internal_originalTime": 1689722917000
    },
    {
        "time": 1689722929000,
        "value": 138.989,
        "_internal_originalTime": 1689722929000
    },
    {
        "time": 1689722937000,
        "value": 138.996,
        "_internal_originalTime": 1689722937000
    },
    {
        "time": 1689722939000,
        "value": 138.994,
        "_internal_originalTime": 1689722939000
    },
    {
        "time": 1689722940000,
        "value": 138.992,
        "_internal_originalTime": 1689722940000
    },
    {
        "time": 1689722942000,
        "value": 138.993,
        "_internal_originalTime": 1689722942000
    },
    {
        "time": 1689722944000,
        "value": 138.993,
        "_internal_originalTime": 1689722944000
    },
    {
        "time": 1689722954000,
        "value": 138.995,
        "_internal_originalTime": 1689722954000
    },
    {
        "time": 1689722962000,
        "value": 139.005,
        "_internal_originalTime": 1689722962000
    },
    {
        "time": 1689722973000,
        "value": 139.032,
        "_internal_originalTime": 1689722973000
    },
    {
        "time": 1689722984000,
        "value": 139.026,
        "_internal_originalTime": 1689722984000
    },
    {
        "time": 1689722993000,
        "value": 139.018,
        "_internal_originalTime": 1689722993000
    },
    {
        "time": 1689722999000,
        "value": 139.01,
        "_internal_originalTime": 1689722999000
    },
    {
        "time": 1689723007000,
        "value": 139.009,
        "_internal_originalTime": 1689723007000
    },
    {
        "time": 1689723018000,
        "value": 139.008,
        "_internal_originalTime": 1689723018000
    },
    {
        "time": 1689723027000,
        "value": 139.025,
        "_internal_originalTime": 1689723027000
    },
    {
        "time": 1689723037000,
        "value": 139.015,
        "_internal_originalTime": 1689723037000
    },
    {
        "time": 1689723048000,
        "value": 139.01,
        "_internal_originalTime": 1689723048000
    },
    {
        "time": 1689723059000,
        "value": 139.006,
        "_internal_originalTime": 1689723059000
    },
    {
        "time": 1689723064000,
        "value": 139.011,
        "_internal_originalTime": 1689723064000
    },
    {
        "time": 1689723075000,
        "value": 139.011,
        "_internal_originalTime": 1689723075000
    },
    {
        "time": 1689723084000,
        "value": 139.032,
        "_internal_originalTime": 1689723084000
    },
    {
        "time": 1689723094000,
        "value": 139.038,
        "_internal_originalTime": 1689723094000
    },
    {
        "time": 1689723105000,
        "value": 139.038,
        "_internal_originalTime": 1689723105000
    },
    {
        "time": 1689723114000,
        "value": 139.043,
        "_internal_originalTime": 1689723114000
    },
    {
        "time": 1689723118000,
        "value": 139.04,
        "_internal_originalTime": 1689723118000
    },
    {
        "time": 1689723130000,
        "value": 139.038,
        "_internal_originalTime": 1689723130000
    },
    {
        "time": 1689723140000,
        "value": 139.04,
        "_internal_originalTime": 1689723140000
    },
    {
        "time": 1689723151000,
        "value": 139.04,
        "_internal_originalTime": 1689723151000
    },
    {
        "time": 1689723164000,
        "value": 139.039,
        "_internal_originalTime": 1689723164000
    },
    {
        "time": 1689723174000,
        "value": 139.048,
        "_internal_originalTime": 1689723174000
    },
    {
        "time": 1689723180000,
        "value": 139.042,
        "_internal_originalTime": 1689723180000
    },
    {
        "time": 1689723188000,
        "value": 139.036,
        "_internal_originalTime": 1689723188000
    },
    {
        "time": 1689723201000,
        "value": 139.026,
        "_internal_originalTime": 1689723201000
    },
    {
        "time": 1689723212000,
        "value": 139.021,
        "_internal_originalTime": 1689723212000
    },
    {
        "time": 1689723225000,
        "value": 139.03,
        "_internal_originalTime": 1689723225000
    },
    {
        "time": 1689723235000,
        "value": 139.027,
        "_internal_originalTime": 1689723235000
    },
    {
        "time": 1689723240000,
        "value": 139.01,
        "_internal_originalTime": 1689723240000
    },
    {
        "time": 1689723250000,
        "value": 139.018,
        "_internal_originalTime": 1689723250000
    },
    {
        "time": 1689723258000,
        "value": 139.029,
        "_internal_originalTime": 1689723258000
    },
    {
        "time": 1689723266000,
        "value": 139.028,
        "_internal_originalTime": 1689723266000
    },
    {
        "time": 1689723279000,
        "value": 139.036,
        "_internal_originalTime": 1689723279000
    },
    {
        "time": 1689723291000,
        "value": 139.03,
        "_internal_originalTime": 1689723291000
    },
    {
        "time": 1689723298000,
        "value": 139.036,
        "_internal_originalTime": 1689723298000
    },
    {
        "time": 1689723305000,
        "value": 139.044,
        "_internal_originalTime": 1689723305000
    },
    {
        "time": 1689723317000,
        "value": 139.041,
        "_internal_originalTime": 1689723317000
    },
    {
        "time": 1689723328000,
        "value": 139.038,
        "_internal_originalTime": 1689723328000
    },
    {
        "time": 1689723336000,
        "value": 139.045,
        "_internal_originalTime": 1689723336000
    },
    {
        "time": 1689723350000,
        "value": 139.044,
        "_internal_originalTime": 1689723350000
    },
    {
        "time": 1689723359000,
        "value": 139.052,
        "_internal_originalTime": 1689723359000
    },
    {
        "time": 1689723363000,
        "value": 139.06,
        "_internal_originalTime": 1689723363000
    },
    {
        "time": 1689723373000,
        "value": 139.059,
        "_internal_originalTime": 1689723373000
    },
    {
        "time": 1689723384000,
        "value": 139.056,
        "_internal_originalTime": 1689723384000
    },
    {
        "time": 1689723396000,
        "value": 139.058,
        "_internal_originalTime": 1689723396000
    },
    {
        "time": 1689723406000,
        "value": 139.061,
        "_internal_originalTime": 1689723406000
    },
    {
        "time": 1689723418000,
        "value": 139.058,
        "_internal_originalTime": 1689723418000
    },
    {
        "time": 1689723423000,
        "value": 139.058,
        "_internal_originalTime": 1689723423000
    },
    {
        "time": 1689723435000,
        "value": 139.06,
        "_internal_originalTime": 1689723435000
    },
    {
        "time": 1689723449000,
        "value": 139.059,
        "_internal_originalTime": 1689723449000
    },
    {
        "time": 1689723459000,
        "value": 139.066,
        "_internal_originalTime": 1689723459000
    },
    {
        "time": 1689723468000,
        "value": 139.081,
        "_internal_originalTime": 1689723468000
    },
    {
        "time": 1689723479000,
        "value": 139.081,
        "_internal_originalTime": 1689723479000
    },
    {
        "time": 1689723483000,
        "value": 139.082,
        "_internal_originalTime": 1689723483000
    },
    {
        "time": 1689723494000,
        "value": 139.08,
        "_internal_originalTime": 1689723494000
    },
    {
        "time": 1689723504000,
        "value": 139.086,
        "_internal_originalTime": 1689723504000
    },
    {
        "time": 1689723513000,
        "value": 139.108,
        "_internal_originalTime": 1689723513000
    },
    {
        "time": 1689723524000,
        "value": 139.118,
        "_internal_originalTime": 1689723524000
    },
    {
        "time": 1689723533000,
        "value": 139.133,
        "_internal_originalTime": 1689723533000
    },
    {
        "time": 1689723539000,
        "value": 139.147,
        "_internal_originalTime": 1689723539000
    },
    {
        "time": 1689723540000,
        "value": 139.142,
        "_internal_originalTime": 1689723540000
    },
    {
        "time": 1689723541000,
        "value": 139.153,
        "_internal_originalTime": 1689723541000
    },
    {
        "time": 1689723546000,
        "value": 139.155,
        "_internal_originalTime": 1689723546000
    },
    {
        "time": 1689723555000,
        "value": 139.171,
        "_internal_originalTime": 1689723555000
    },
    {
        "time": 1689723562000,
        "value": 139.185,
        "_internal_originalTime": 1689723562000
    },
    {
        "time": 1689723572000,
        "value": 139.183,
        "_internal_originalTime": 1689723572000
    },
    {
        "time": 1689723583000,
        "value": 139.161,
        "_internal_originalTime": 1689723583000
    },
    {
        "time": 1689723593000,
        "value": 139.16,
        "_internal_originalTime": 1689723593000
    },
    {
        "time": 1689723599000,
        "value": 139.163,
        "_internal_originalTime": 1689723599000
    },
    {
        "time": 1689723607000,
        "value": 139.16,
        "_internal_originalTime": 1689723607000
    },
    {
        "time": 1689723617000,
        "value": 139.163,
        "_internal_originalTime": 1689723617000
    },
    {
        "time": 1689723629000,
        "value": 139.162,
        "_internal_originalTime": 1689723629000
    },
    {
        "time": 1689723639000,
        "value": 139.161,
        "_internal_originalTime": 1689723639000
    },
    {
        "time": 1689723651000,
        "value": 139.154,
        "_internal_originalTime": 1689723651000
    },
    {
        "time": 1689723659000,
        "value": 139.134,
        "_internal_originalTime": 1689723659000
    },
    {
        "time": 1689723664000,
        "value": 139.14,
        "_internal_originalTime": 1689723664000
    },
    {
        "time": 1689723674000,
        "value": 139.14,
        "_internal_originalTime": 1689723674000
    },
    {
        "time": 1689723684000,
        "value": 139.129,
        "_internal_originalTime": 1689723684000
    },
    {
        "time": 1689723692000,
        "value": 139.133,
        "_internal_originalTime": 1689723692000
    },
    {
        "time": 1689723704000,
        "value": 139.131,
        "_internal_originalTime": 1689723704000
    },
    {
        "time": 1689723714000,
        "value": 139.11,
        "_internal_originalTime": 1689723714000
    },
    {
        "time": 1689723719000,
        "value": 139.111,
        "_internal_originalTime": 1689723719000
    },
    {
        "time": 1689723725000,
        "value": 139.112,
        "_internal_originalTime": 1689723725000
    },
    {
        "time": 1689723735000,
        "value": 139.11,
        "_internal_originalTime": 1689723735000
    },
    {
        "time": 1689723746000,
        "value": 139.103,
        "_internal_originalTime": 1689723746000
    },
    {
        "time": 1689723756000,
        "value": 139.095,
        "_internal_originalTime": 1689723756000
    },
    {
        "time": 1689723768000,
        "value": 139.092,
        "_internal_originalTime": 1689723768000
    },
    {
        "time": 1689723779000,
        "value": 139.102,
        "_internal_originalTime": 1689723779000
    },
    {
        "time": 1689723783000,
        "value": 139.1,
        "_internal_originalTime": 1689723783000
    },
    {
        "time": 1689723795000,
        "value": 139.095,
        "_internal_originalTime": 1689723795000
    },
    {
        "time": 1689723804000,
        "value": 139.08,
        "_internal_originalTime": 1689723804000
    },
    {
        "time": 1689723814000,
        "value": 139.082,
        "_internal_originalTime": 1689723814000
    },
    {
        "time": 1689723825000,
        "value": 139.089,
        "_internal_originalTime": 1689723825000
    },
    {
        "time": 1689723834000,
        "value": 139.097,
        "_internal_originalTime": 1689723834000
    },
    {
        "time": 1689723838000,
        "value": 139.096,
        "_internal_originalTime": 1689723838000
    },
    {
        "time": 1689723841000,
        "value": 139.097,
        "_internal_originalTime": 1689723841000
    },
    {
        "time": 1689723849000,
        "value": 139.099,
        "_internal_originalTime": 1689723849000
    },
    {
        "time": 1689723861000,
        "value": 139.102,
        "_internal_originalTime": 1689723861000
    },
    {
        "time": 1689723872000,
        "value": 139.107,
        "_internal_originalTime": 1689723872000
    },
    {
        "time": 1689723881000,
        "value": 139.101,
        "_internal_originalTime": 1689723881000
    },
    {
        "time": 1689723892000,
        "value": 139.102,
        "_internal_originalTime": 1689723892000
    },
    {
        "time": 1689723897000,
        "value": 139.106,
        "_internal_originalTime": 1689723897000
    },
    {
        "time": 1689723903000,
        "value": 139.098,
        "_internal_originalTime": 1689723903000
    },
    {
        "time": 1689723905000,
        "value": 139.097,
        "_internal_originalTime": 1689723905000
    },
    {
        "time": 1689723910000,
        "value": 139.1,
        "_internal_originalTime": 1689723910000
    },
    {
        "time": 1689723914000,
        "value": 139.097,
        "_internal_originalTime": 1689723914000
    },
    {
        "time": 1689723917000,
        "value": 139.099,
        "_internal_originalTime": 1689723917000
    },
    {
        "time": 1689723921000,
        "value": 139.106,
        "_internal_originalTime": 1689723921000
    },
    {
        "time": 1689723924000,
        "value": 139.1,
        "_internal_originalTime": 1689723924000
    },
    {
        "time": 1689723930000,
        "value": 139.105,
        "_internal_originalTime": 1689723930000
    },
    {
        "time": 1689723933000,
        "value": 139.103,
        "_internal_originalTime": 1689723933000
    },
    {
        "time": 1689723938000,
        "value": 139.098,
        "_internal_originalTime": 1689723938000
    },
    {
        "time": 1689723942000,
        "value": 139.101,
        "_internal_originalTime": 1689723942000
    },
    {
        "time": 1689723946000,
        "value": 139.094,
        "_internal_originalTime": 1689723946000
    },
    {
        "time": 1689723951000,
        "value": 139.091,
        "_internal_originalTime": 1689723951000
    },
    {
        "time": 1689723957000,
        "value": 139.093,
        "_internal_originalTime": 1689723957000
    },
    {
        "time": 1689723959000,
        "value": 139.09,
        "_internal_originalTime": 1689723959000
    },
    {
        "time": 1689723961000,
        "value": 139.087,
        "_internal_originalTime": 1689723961000
    },
    {
        "time": 1689723967000,
        "value": 139.081,
        "_internal_originalTime": 1689723967000
    },
    {
        "time": 1689723972000,
        "value": 139.081,
        "_internal_originalTime": 1689723972000
    },
    {
        "time": 1689723976000,
        "value": 139.082,
        "_internal_originalTime": 1689723976000
    },
    {
        "time": 1689723980000,
        "value": 139.083,
        "_internal_originalTime": 1689723980000
    },
    {
        "time": 1689723985000,
        "value": 139.083,
        "_internal_originalTime": 1689723985000
    },
    {
        "time": 1689723988000,
        "value": 139.083,
        "_internal_originalTime": 1689723988000
    },
    {
        "time": 1689723992000,
        "value": 139.082,
        "_internal_originalTime": 1689723992000
    },
    {
        "time": 1689723999000,
        "value": 139.082,
        "_internal_originalTime": 1689723999000
    },
    {
        "time": 1689724001000,
        "value": 139.083,
        "_internal_originalTime": 1689724001000
    },
    {
        "time": 1689724007000,
        "value": 139.082,
        "_internal_originalTime": 1689724007000
    },
    {
        "time": 1689724011000,
        "value": 139.082,
        "_internal_originalTime": 1689724011000
    },
    {
        "time": 1689724015000,
        "value": 139.084,
        "_internal_originalTime": 1689724015000
    },
    {
        "time": 1689724017000,
        "value": 139.083,
        "_internal_originalTime": 1689724017000
    },
    {
        "time": 1689724022000,
        "value": 139.085,
        "_internal_originalTime": 1689724022000
    },
    {
        "time": 1689724027000,
        "value": 139.08,
        "_internal_originalTime": 1689724027000
    },
    {
        "time": 1689724032000,
        "value": 139.084,
        "_internal_originalTime": 1689724032000
    },
    {
        "time": 1689724038000,
        "value": 139.082,
        "_internal_originalTime": 1689724038000
    },
    {
        "time": 1689724043000,
        "value": 139.084,
        "_internal_originalTime": 1689724043000
    },
    {
        "time": 1689724047000,
        "value": 139.084,
        "_internal_originalTime": 1689724047000
    },
    {
        "time": 1689724052000,
        "value": 139.084,
        "_internal_originalTime": 1689724052000
    },
    {
        "time": 1689724056000,
        "value": 139.094,
        "_internal_originalTime": 1689724056000
    },
    {
        "time": 1689724061000,
        "value": 139.093,
        "_internal_originalTime": 1689724061000
    },
    {
        "time": 1689724064000,
        "value": 139.097,
        "_internal_originalTime": 1689724064000
    },
    {
        "time": 1689724065000,
        "value": 139.096,
        "_internal_originalTime": 1689724065000
    },
    {
        "time": 1689724070000,
        "value": 139.096,
        "_internal_originalTime": 1689724070000
    },
    {
        "time": 1689724076000,
        "value": 139.097,
        "_internal_originalTime": 1689724076000
    },
    {
        "time": 1689724079000,
        "value": 139.098,
        "_internal_originalTime": 1689724079000
    },
    {
        "time": 1689724082000,
        "value": 139.106,
        "_internal_originalTime": 1689724082000
    },
    {
        "time": 1689724084000,
        "value": 139.105,
        "_internal_originalTime": 1689724084000
    },
    {
        "time": 1689724091000,
        "value": 139.106,
        "_internal_originalTime": 1689724091000
    },
    {
        "time": 1689724094000,
        "value": 139.103,
        "_internal_originalTime": 1689724094000
    },
    {
        "time": 1689724100000,
        "value": 139.101,
        "_internal_originalTime": 1689724100000
    },
    {
        "time": 1689724106000,
        "value": 139.107,
        "_internal_originalTime": 1689724106000
    },
    {
        "time": 1689724110000,
        "value": 139.105,
        "_internal_originalTime": 1689724110000
    },
    {
        "time": 1689724115000,
        "value": 139.106,
        "_internal_originalTime": 1689724115000
    },
    {
        "time": 1689724121000,
        "value": 139.1,
        "_internal_originalTime": 1689724121000
    },
    {
        "time": 1689724122000,
        "value": 139.102,
        "_internal_originalTime": 1689724122000
    },
    {
        "time": 1689724128000,
        "value": 139.101,
        "_internal_originalTime": 1689724128000
    },
    {
        "time": 1689724131000,
        "value": 139.1,
        "_internal_originalTime": 1689724131000
    },
    {
        "time": 1689724139000,
        "value": 139.095,
        "_internal_originalTime": 1689724139000
    },
    {
        "time": 1689724140000,
        "value": 139.094,
        "_internal_originalTime": 1689724140000
    },
    {
        "time": 1689724143000,
        "value": 139.094,
        "_internal_originalTime": 1689724143000
    },
    {
        "time": 1689724147000,
        "value": 139.093,
        "_internal_originalTime": 1689724147000
    },
    {
        "time": 1689724150000,
        "value": 139.087,
        "_internal_originalTime": 1689724150000
    },
    {
        "time": 1689724155000,
        "value": 139.084,
        "_internal_originalTime": 1689724155000
    },
    {
        "time": 1689724157000,
        "value": 139.086,
        "_internal_originalTime": 1689724157000
    },
    {
        "time": 1689724164000,
        "value": 139.084,
        "_internal_originalTime": 1689724164000
    },
    {
        "time": 1689724168000,
        "value": 139.092,
        "_internal_originalTime": 1689724168000
    },
    {
        "time": 1689724171000,
        "value": 139.086,
        "_internal_originalTime": 1689724171000
    },
    {
        "time": 1689724176000,
        "value": 139.092,
        "_internal_originalTime": 1689724176000
    },
    {
        "time": 1689724180000,
        "value": 139.092,
        "_internal_originalTime": 1689724180000
    },
    {
        "time": 1689724184000,
        "value": 139.091,
        "_internal_originalTime": 1689724184000
    },
    {
        "time": 1689724188000,
        "value": 139.087,
        "_internal_originalTime": 1689724188000
    },
    {
        "time": 1689724190000,
        "value": 139.084,
        "_internal_originalTime": 1689724190000
    },
    {
        "time": 1689724196000,
        "value": 139.086,
        "_internal_originalTime": 1689724196000
    },
    {
        "time": 1689724199000,
        "value": 139.092,
        "_internal_originalTime": 1689724199000
    },
    {
        "time": 1689724201000,
        "value": 139.088,
        "_internal_originalTime": 1689724201000
    },
    {
        "time": 1689724206000,
        "value": 139.091,
        "_internal_originalTime": 1689724206000
    },
    {
        "time": 1689724210000,
        "value": 139.08,
        "_internal_originalTime": 1689724210000
    },
    {
        "time": 1689724214000,
        "value": 139.078,
        "_internal_originalTime": 1689724214000
    },
    {
        "time": 1689724220000,
        "value": 139.082,
        "_internal_originalTime": 1689724220000
    },
    {
        "time": 1689724225000,
        "value": 139.077,
        "_internal_originalTime": 1689724225000
    },
    {
        "time": 1689724230000,
        "value": 139.072,
        "_internal_originalTime": 1689724230000
    },
    {
        "time": 1689724233000,
        "value": 139.061,
        "_internal_originalTime": 1689724233000
    },
    {
        "time": 1689724238000,
        "value": 139.065,
        "_internal_originalTime": 1689724238000
    },
    {
        "time": 1689724243000,
        "value": 139.067,
        "_internal_originalTime": 1689724243000
    },
    {
        "time": 1689724247000,
        "value": 139.065,
        "_internal_originalTime": 1689724247000
    },
    {
        "time": 1689724252000,
        "value": 139.066,
        "_internal_originalTime": 1689724252000
    },
    {
        "time": 1689724257000,
        "value": 139.065,
        "_internal_originalTime": 1689724257000
    },
    {
        "time": 1689724259000,
        "value": 139.063,
        "_internal_originalTime": 1689724259000
    },
    {
        "time": 1689724262000,
        "value": 139.063,
        "_internal_originalTime": 1689724262000
    },
    {
        "time": 1689724263000,
        "value": 139.062,
        "_internal_originalTime": 1689724263000
    },
    {
        "time": 1689724270000,
        "value": 139.061,
        "_internal_originalTime": 1689724270000
    },
    {
        "time": 1689724276000,
        "value": 139.062,
        "_internal_originalTime": 1689724276000
    },
    {
        "time": 1689724283000,
        "value": 139.061,
        "_internal_originalTime": 1689724283000
    },
    {
        "time": 1689724287000,
        "value": 139.064,
        "_internal_originalTime": 1689724287000
    },
    {
        "time": 1689724291000,
        "value": 139.063,
        "_internal_originalTime": 1689724291000
    },
    {
        "time": 1689724296000,
        "value": 139.062,
        "_internal_originalTime": 1689724296000
    },
    {
        "time": 1689724304000,
        "value": 139.064,
        "_internal_originalTime": 1689724304000
    },
    {
        "time": 1689724308000,
        "value": 139.062,
        "_internal_originalTime": 1689724308000
    },
    {
        "time": 1689724313000,
        "value": 139.063,
        "_internal_originalTime": 1689724313000
    },
    {
        "time": 1689724316000,
        "value": 139.063,
        "_internal_originalTime": 1689724316000
    },
    {
        "time": 1689724320000,
        "value": 139.081,
        "_internal_originalTime": 1689724320000
    },
    {
        "time": 1689724322000,
        "value": 139.084,
        "_internal_originalTime": 1689724322000
    },
    {
        "time": 1689724327000,
        "value": 139.076,
        "_internal_originalTime": 1689724327000
    },
    {
        "time": 1689724330000,
        "value": 139.075,
        "_internal_originalTime": 1689724330000
    },
    {
        "time": 1689724335000,
        "value": 139.064,
        "_internal_originalTime": 1689724335000
    },
    {
        "time": 1689724340000,
        "value": 139.055,
        "_internal_originalTime": 1689724340000
    },
    {
        "time": 1689724345000,
        "value": 139.059,
        "_internal_originalTime": 1689724345000
    },
    {
        "time": 1689724349000,
        "value": 139.058,
        "_internal_originalTime": 1689724349000
    },
    {
        "time": 1689724354000,
        "value": 139.078,
        "_internal_originalTime": 1689724354000
    },
    {
        "time": 1689724356000,
        "value": 139.072,
        "_internal_originalTime": 1689724356000
    },
    {
        "time": 1689724361000,
        "value": 139.082,
        "_internal_originalTime": 1689724361000
    },
    {
        "time": 1689724365000,
        "value": 139.085,
        "_internal_originalTime": 1689724365000
    },
    {
        "time": 1689724370000,
        "value": 139.084,
        "_internal_originalTime": 1689724370000
    },
    {
        "time": 1689724375000,
        "value": 139.084,
        "_internal_originalTime": 1689724375000
    },
    {
        "time": 1689724378000,
        "value": 139.083,
        "_internal_originalTime": 1689724378000
    },
    {
        "time": 1689724381000,
        "value": 139.06,
        "_internal_originalTime": 1689724381000
    },
    {
        "time": 1689724385000,
        "value": 139.084,
        "_internal_originalTime": 1689724385000
    },
    {
        "time": 1689724394000,
        "value": 139.083,
        "_internal_originalTime": 1689724394000
    },
    {
        "time": 1689724399000,
        "value": 139.082,
        "_internal_originalTime": 1689724399000
    },
    {
        "time": 1689724404000,
        "value": 139.083,
        "_internal_originalTime": 1689724404000
    },
    {
        "time": 1689724410000,
        "value": 139.084,
        "_internal_originalTime": 1689724410000
    },
    {
        "time": 1689724413000,
        "value": 139.081,
        "_internal_originalTime": 1689724413000
    },
    {
        "time": 1689724418000,
        "value": 139.082,
        "_internal_originalTime": 1689724418000
    },
    {
        "time": 1689724422000,
        "value": 139.079,
        "_internal_originalTime": 1689724422000
    },
    {
        "time": 1689724428000,
        "value": 139.081,
        "_internal_originalTime": 1689724428000
    },
    {
        "time": 1689724433000,
        "value": 139.051,
        "_internal_originalTime": 1689724433000
    },
    {
        "time": 1689724435000,
        "value": 139.054,
        "_internal_originalTime": 1689724435000
    },
    {
        "time": 1689724440000,
        "value": 139.035,
        "_internal_originalTime": 1689724440000
    },
    {
        "time": 1689724444000,
        "value": 139.034,
        "_internal_originalTime": 1689724444000
    },
    {
        "time": 1689724448000,
        "value": 139.035,
        "_internal_originalTime": 1689724448000
    },
    {
        "time": 1689724452000,
        "value": 139.028,
        "_internal_originalTime": 1689724452000
    },
    {
        "time": 1689724455000,
        "value": 139.027,
        "_internal_originalTime": 1689724455000
    },
    {
        "time": 1689724460000,
        "value": 139.034,
        "_internal_originalTime": 1689724460000
    },
    {
        "time": 1689724466000,
        "value": 139.037,
        "_internal_originalTime": 1689724466000
    },
    {
        "time": 1689724469000,
        "value": 139.05,
        "_internal_originalTime": 1689724469000
    },
    {
        "time": 1689724470000,
        "value": 139.048,
        "_internal_originalTime": 1689724470000
    },
    {
        "time": 1689724471000,
        "value": 139.052,
        "_internal_originalTime": 1689724471000
    },
    {
        "time": 1689724474000,
        "value": 139.052,
        "_internal_originalTime": 1689724474000
    },
    {
        "time": 1689724479000,
        "value": 139.054,
        "_internal_originalTime": 1689724479000
    },
    {
        "time": 1689724483000,
        "value": 139.053,
        "_internal_originalTime": 1689724483000
    },
    {
        "time": 1689724488000,
        "value": 139.052,
        "_internal_originalTime": 1689724488000
    },
    {
        "time": 1689724494000,
        "value": 139.051,
        "_internal_originalTime": 1689724494000
    },
    {
        "time": 1689724499000,
        "value": 139.054,
        "_internal_originalTime": 1689724499000
    },
    {
        "time": 1689724501000,
        "value": 139.048,
        "_internal_originalTime": 1689724501000
    },
    {
        "time": 1689724502000,
        "value": 139.047,
        "_internal_originalTime": 1689724502000
    },
    {
        "time": 1689724511000,
        "value": 139.034,
        "_internal_originalTime": 1689724511000
    },
    {
        "time": 1689724515000,
        "value": 139.035,
        "_internal_originalTime": 1689724515000
    },
    {
        "time": 1689724519000,
        "value": 139.036,
        "_internal_originalTime": 1689724519000
    },
    {
        "time": 1689724524000,
        "value": 139.034,
        "_internal_originalTime": 1689724524000
    },
    {
        "time": 1689724526000,
        "value": 139.021,
        "_internal_originalTime": 1689724526000
    },
    {
        "time": 1689724529000,
        "value": 139.02,
        "_internal_originalTime": 1689724529000
    },
    {
        "time": 1689724533000,
        "value": 139.027,
        "_internal_originalTime": 1689724533000
    },
    {
        "time": 1689724538000,
        "value": 139.029,
        "_internal_originalTime": 1689724538000
    },
    {
        "time": 1689724543000,
        "value": 139.027,
        "_internal_originalTime": 1689724543000
    },
    {
        "time": 1689724548000,
        "value": 139.027,
        "_internal_originalTime": 1689724548000
    },
    {
        "time": 1689724553000,
        "value": 139.027,
        "_internal_originalTime": 1689724553000
    },
    {
        "time": 1689724558000,
        "value": 139.022,
        "_internal_originalTime": 1689724558000
    },
    {
        "time": 1689724560000,
        "value": 139.01,
        "_internal_originalTime": 1689724560000
    },
    {
        "time": 1689724565000,
        "value": 139.023,
        "_internal_originalTime": 1689724565000
    },
    {
        "time": 1689724573000,
        "value": 139.019,
        "_internal_originalTime": 1689724573000
    },
    {
        "time": 1689724577000,
        "value": 139.019,
        "_internal_originalTime": 1689724577000
    },
    {
        "time": 1689724582000,
        "value": 139.02,
        "_internal_originalTime": 1689724582000
    },
    {
        "time": 1689724585000,
        "value": 139.017,
        "_internal_originalTime": 1689724585000
    },
    {
        "time": 1689724589000,
        "value": 139.017,
        "_internal_originalTime": 1689724589000
    },
    {
        "time": 1689724592000,
        "value": 139.017,
        "_internal_originalTime": 1689724592000
    },
    {
        "time": 1689724596000,
        "value": 139.018,
        "_internal_originalTime": 1689724596000
    },
    {
        "time": 1689724602000,
        "value": 139.024,
        "_internal_originalTime": 1689724602000
    },
    {
        "time": 1689724606000,
        "value": 139.026,
        "_internal_originalTime": 1689724606000
    },
    {
        "time": 1689724611000,
        "value": 139.026,
        "_internal_originalTime": 1689724611000
    },
    {
        "time": 1689724613000,
        "value": 139.024,
        "_internal_originalTime": 1689724613000
    },
    {
        "time": 1689724617000,
        "value": 139.026,
        "_internal_originalTime": 1689724617000
    },
    {
        "time": 1689724623000,
        "value": 139.023,
        "_internal_originalTime": 1689724623000
    },
    {
        "time": 1689724627000,
        "value": 139.023,
        "_internal_originalTime": 1689724627000
    },
    {
        "time": 1689724637000,
        "value": 139.027,
        "_internal_originalTime": 1689724637000
    },
    {
        "time": 1689724640000,
        "value": 139.019,
        "_internal_originalTime": 1689724640000
    },
    {
        "time": 1689724643000,
        "value": 139.023,
        "_internal_originalTime": 1689724643000
    },
    {
        "time": 1689724651000,
        "value": 139.02,
        "_internal_originalTime": 1689724651000
    },
    {
        "time": 1689724652000,
        "value": 139.018,
        "_internal_originalTime": 1689724652000
    },
    {
        "time": 1689724657000,
        "value": 139.018,
        "_internal_originalTime": 1689724657000
    },
    {
        "time": 1689724670000,
        "value": 139.017,
        "_internal_originalTime": 1689724670000
    },
    {
        "time": 1689724674000,
        "value": 139.023,
        "_internal_originalTime": 1689724674000
    },
    {
        "time": 1689724679000,
        "value": 139.023,
        "_internal_originalTime": 1689724679000
    },
    {
        "time": 1689724685000,
        "value": 139.019,
        "_internal_originalTime": 1689724685000
    },
    {
        "time": 1689724690000,
        "value": 139.029,
        "_internal_originalTime": 1689724690000
    },
    {
        "time": 1689724695000,
        "value": 139.03,
        "_internal_originalTime": 1689724695000
    },
    {
        "time": 1689724701000,
        "value": 139.029,
        "_internal_originalTime": 1689724701000
    },
    {
        "time": 1689724704000,
        "value": 139.03,
        "_internal_originalTime": 1689724704000
    },
    {
        "time": 1689724710000,
        "value": 139.028,
        "_internal_originalTime": 1689724710000
    },
    {
        "time": 1689724713000,
        "value": 139.025,
        "_internal_originalTime": 1689724713000
    },
    {
        "time": 1689724719000,
        "value": 139.025,
        "_internal_originalTime": 1689724719000
    },
    {
        "time": 1689724724000,
        "value": 139.026,
        "_internal_originalTime": 1689724724000
    },
    {
        "time": 1689724725000,
        "value": 139.027,
        "_internal_originalTime": 1689724725000
    },
    {
        "time": 1689724731000,
        "value": 139.029,
        "_internal_originalTime": 1689724731000
    },
    {
        "time": 1689724737000,
        "value": 139.03,
        "_internal_originalTime": 1689724737000
    },
    {
        "time": 1689724738000,
        "value": 139.029,
        "_internal_originalTime": 1689724738000
    },
    {
        "time": 1689724740000,
        "value": 139.032,
        "_internal_originalTime": 1689724740000
    },
    {
        "time": 1689724741000,
        "value": 139.032,
        "_internal_originalTime": 1689724741000
    },
    {
        "time": 1689724742000,
        "value": 139.029,
        "_internal_originalTime": 1689724742000
    },
    {
        "time": 1689724745000,
        "value": 139.027,
        "_internal_originalTime": 1689724745000
    },
    {
        "time": 1689724749000,
        "value": 139.029,
        "_internal_originalTime": 1689724749000
    },
    {
        "time": 1689724755000,
        "value": 139.031,
        "_internal_originalTime": 1689724755000
    },
    {
        "time": 1689724760000,
        "value": 139.031,
        "_internal_originalTime": 1689724760000
    },
    {
        "time": 1689724765000,
        "value": 139.031,
        "_internal_originalTime": 1689724765000
    },
    {
        "time": 1689724768000,
        "value": 139.028,
        "_internal_originalTime": 1689724768000
    },
    {
        "time": 1689724773000,
        "value": 139.03,
        "_internal_originalTime": 1689724773000
    },
    {
        "time": 1689724777000,
        "value": 139.031,
        "_internal_originalTime": 1689724777000
    },
    {
        "time": 1689724780000,
        "value": 139.031,
        "_internal_originalTime": 1689724780000
    },
    {
        "time": 1689724786000,
        "value": 139.018,
        "_internal_originalTime": 1689724786000
    },
    {
        "time": 1689724790000,
        "value": 139.017,
        "_internal_originalTime": 1689724790000
    },
    {
        "time": 1689724793000,
        "value": 139.018,
        "_internal_originalTime": 1689724793000
    },
    {
        "time": 1689724797000,
        "value": 139.019,
        "_internal_originalTime": 1689724797000
    },
    {
        "time": 1689724801000,
        "value": 139,
        "_internal_originalTime": 1689724801000
    },
    {
        "time": 1689724804000,
        "value": 138.999,
        "_internal_originalTime": 1689724804000
    },
    {
        "time": 1689724807000,
        "value": 139.022,
        "_internal_originalTime": 1689724807000
    },
    {
        "time": 1689724809000,
        "value": 139.027,
        "_internal_originalTime": 1689724809000
    },
    {
        "time": 1689724812000,
        "value": 139.023,
        "_internal_originalTime": 1689724812000
    },
    {
        "time": 1689724815000,
        "value": 139.019,
        "_internal_originalTime": 1689724815000
    },
    {
        "time": 1689724817000,
        "value": 139.008,
        "_internal_originalTime": 1689724817000
    },
    {
        "time": 1689724821000,
        "value": 138.999,
        "_internal_originalTime": 1689724821000
    },
    {
        "time": 1689724824000,
        "value": 138.974,
        "_internal_originalTime": 1689724824000
    },
    {
        "time": 1689724827000,
        "value": 138.981,
        "_internal_originalTime": 1689724827000
    },
    {
        "time": 1689724830000,
        "value": 138.991,
        "_internal_originalTime": 1689724830000
    },
    {
        "time": 1689724832000,
        "value": 139.002,
        "_internal_originalTime": 1689724832000
    },
    {
        "time": 1689724836000,
        "value": 138.999,
        "_internal_originalTime": 1689724836000
    },
    {
        "time": 1689724838000,
        "value": 139.011,
        "_internal_originalTime": 1689724838000
    },
    {
        "time": 1689724841000,
        "value": 139.01,
        "_internal_originalTime": 1689724841000
    },
    {
        "time": 1689724845000,
        "value": 139.007,
        "_internal_originalTime": 1689724845000
    },
    {
        "time": 1689724846000,
        "value": 138.999,
        "_internal_originalTime": 1689724846000
    },
    {
        "time": 1689724850000,
        "value": 139.006,
        "_internal_originalTime": 1689724850000
    },
    {
        "time": 1689724853000,
        "value": 139.011,
        "_internal_originalTime": 1689724853000
    },
    {
        "time": 1689724856000,
        "value": 139.01,
        "_internal_originalTime": 1689724856000
    },
    {
        "time": 1689724858000,
        "value": 139.011,
        "_internal_originalTime": 1689724858000
    },
    {
        "time": 1689724861000,
        "value": 139.01,
        "_internal_originalTime": 1689724861000
    },
    {
        "time": 1689724864000,
        "value": 139.054,
        "_internal_originalTime": 1689724864000
    },
    {
        "time": 1689724867000,
        "value": 139.048,
        "_internal_originalTime": 1689724867000
    },
    {
        "time": 1689724871000,
        "value": 139.049,
        "_internal_originalTime": 1689724871000
    },
    {
        "time": 1689724874000,
        "value": 139.048,
        "_internal_originalTime": 1689724874000
    },
    {
        "time": 1689724877000,
        "value": 139.057,
        "_internal_originalTime": 1689724877000
    },
    {
        "time": 1689724880000,
        "value": 139.066,
        "_internal_originalTime": 1689724880000
    },
    {
        "time": 1689724884000,
        "value": 139.055,
        "_internal_originalTime": 1689724884000
    },
    {
        "time": 1689724887000,
        "value": 139.059,
        "_internal_originalTime": 1689724887000
    },
    {
        "time": 1689724889000,
        "value": 139.059,
        "_internal_originalTime": 1689724889000
    },
    {
        "time": 1689724893000,
        "value": 139.056,
        "_internal_originalTime": 1689724893000
    },
    {
        "time": 1689724897000,
        "value": 139.036,
        "_internal_originalTime": 1689724897000
    },
    {
        "time": 1689724900000,
        "value": 139.038,
        "_internal_originalTime": 1689724900000
    },
    {
        "time": 1689724903000,
        "value": 139.039,
        "_internal_originalTime": 1689724903000
    },
    {
        "time": 1689724907000,
        "value": 139.03,
        "_internal_originalTime": 1689724907000
    },
    {
        "time": 1689724910000,
        "value": 139.044,
        "_internal_originalTime": 1689724910000
    },
    {
        "time": 1689724913000,
        "value": 139.036,
        "_internal_originalTime": 1689724913000
    },
    {
        "time": 1689724917000,
        "value": 139.036,
        "_internal_originalTime": 1689724917000
    },
    {
        "time": 1689724919000,
        "value": 139.042,
        "_internal_originalTime": 1689724919000
    },
    {
        "time": 1689724922000,
        "value": 139.057,
        "_internal_originalTime": 1689724922000
    },
    {
        "time": 1689724924000,
        "value": 139.068,
        "_internal_originalTime": 1689724924000
    },
    {
        "time": 1689724927000,
        "value": 139.058,
        "_internal_originalTime": 1689724927000
    },
    {
        "time": 1689724931000,
        "value": 139.061,
        "_internal_originalTime": 1689724931000
    },
    {
        "time": 1689724934000,
        "value": 139.05,
        "_internal_originalTime": 1689724934000
    },
    {
        "time": 1689724937000,
        "value": 139.047,
        "_internal_originalTime": 1689724937000
    },
    {
        "time": 1689724940000,
        "value": 139.042,
        "_internal_originalTime": 1689724940000
    },
    {
        "time": 1689724944000,
        "value": 139.036,
        "_internal_originalTime": 1689724944000
    },
    {
        "time": 1689724949000,
        "value": 139.039,
        "_internal_originalTime": 1689724949000
    },
    {
        "time": 1689724952000,
        "value": 139.037,
        "_internal_originalTime": 1689724952000
    },
    {
        "time": 1689724955000,
        "value": 139.036,
        "_internal_originalTime": 1689724955000
    },
    {
        "time": 1689724959000,
        "value": 139.038,
        "_internal_originalTime": 1689724959000
    },
    {
        "time": 1689724963000,
        "value": 139.038,
        "_internal_originalTime": 1689724963000
    },
    {
        "time": 1689724967000,
        "value": 139.036,
        "_internal_originalTime": 1689724967000
    },
    {
        "time": 1689724970000,
        "value": 139.032,
        "_internal_originalTime": 1689724970000
    },
    {
        "time": 1689724973000,
        "value": 139.038,
        "_internal_originalTime": 1689724973000
    },
    {
        "time": 1689724978000,
        "value": 139.032,
        "_internal_originalTime": 1689724978000
    },
    {
        "time": 1689724979000,
        "value": 139.031,
        "_internal_originalTime": 1689724979000
    },
    {
        "time": 1689724981000,
        "value": 139.032,
        "_internal_originalTime": 1689724981000
    },
    {
        "time": 1689724984000,
        "value": 139.039,
        "_internal_originalTime": 1689724984000
    },
    {
        "time": 1689724987000,
        "value": 139.036,
        "_internal_originalTime": 1689724987000
    },
    {
        "time": 1689724990000,
        "value": 139.031,
        "_internal_originalTime": 1689724990000
    },
    {
        "time": 1689724994000,
        "value": 139.031,
        "_internal_originalTime": 1689724994000
    },
    {
        "time": 1689724997000,
        "value": 139.04,
        "_internal_originalTime": 1689724997000
    },
    {
        "time": 1689725002000,
        "value": 139.04,
        "_internal_originalTime": 1689725002000
    },
    {
        "time": 1689725005000,
        "value": 139.038,
        "_internal_originalTime": 1689725005000
    },
    {
        "time": 1689725009000,
        "value": 139.029,
        "_internal_originalTime": 1689725009000
    },
    {
        "time": 1689725012000,
        "value": 139.032,
        "_internal_originalTime": 1689725012000
    },
    {
        "time": 1689725016000,
        "value": 139.031,
        "_internal_originalTime": 1689725016000
    },
    {
        "time": 1689725019000,
        "value": 139.013,
        "_internal_originalTime": 1689725019000
    },
    {
        "time": 1689725023000,
        "value": 139.002,
        "_internal_originalTime": 1689725023000
    },
    {
        "time": 1689725025000,
        "value": 139.011,
        "_internal_originalTime": 1689725025000
    },
    {
        "time": 1689725032000,
        "value": 138.99,
        "_internal_originalTime": 1689725032000
    },
    {
        "time": 1689725036000,
        "value": 138.968,
        "_internal_originalTime": 1689725036000
    },
    {
        "time": 1689725038000,
        "value": 138.948,
        "_internal_originalTime": 1689725038000
    },
    {
        "time": 1689725039000,
        "value": 138.951,
        "_internal_originalTime": 1689725039000
    },
    {
        "time": 1689725041000,
        "value": 138.949,
        "_internal_originalTime": 1689725041000
    },
    {
        "time": 1689725042000,
        "value": 138.954,
        "_internal_originalTime": 1689725042000
    },
    {
        "time": 1689725044000,
        "value": 138.96,
        "_internal_originalTime": 1689725044000
    },
    {
        "time": 1689725046000,
        "value": 138.959,
        "_internal_originalTime": 1689725046000
    },
    {
        "time": 1689725049000,
        "value": 138.963,
        "_internal_originalTime": 1689725049000
    },
    {
        "time": 1689725053000,
        "value": 138.972,
        "_internal_originalTime": 1689725053000
    },
    {
        "time": 1689725056000,
        "value": 138.972,
        "_internal_originalTime": 1689725056000
    },
    {
        "time": 1689725060000,
        "value": 138.973,
        "_internal_originalTime": 1689725060000
    },
    {
        "time": 1689725062000,
        "value": 138.972,
        "_internal_originalTime": 1689725062000
    },
    {
        "time": 1689725067000,
        "value": 138.975,
        "_internal_originalTime": 1689725067000
    },
    {
        "time": 1689725069000,
        "value": 138.982,
        "_internal_originalTime": 1689725069000
    },
    {
        "time": 1689725073000,
        "value": 138.987,
        "_internal_originalTime": 1689725073000
    },
    {
        "time": 1689725076000,
        "value": 138.992,
        "_internal_originalTime": 1689725076000
    },
    {
        "time": 1689725079000,
        "value": 138.99,
        "_internal_originalTime": 1689725079000
    },
    {
        "time": 1689725084000,
        "value": 138.976,
        "_internal_originalTime": 1689725084000
    },
    {
        "time": 1689725087000,
        "value": 138.978,
        "_internal_originalTime": 1689725087000
    },
    {
        "time": 1689725090000,
        "value": 138.992,
        "_internal_originalTime": 1689725090000
    },
    {
        "time": 1689725093000,
        "value": 138.993,
        "_internal_originalTime": 1689725093000
    },
    {
        "time": 1689725098000,
        "value": 138.993,
        "_internal_originalTime": 1689725098000
    },
    {
        "time": 1689725099000,
        "value": 139.001,
        "_internal_originalTime": 1689725099000
    },
    {
        "time": 1689725102000,
        "value": 139,
        "_internal_originalTime": 1689725102000
    },
    {
        "time": 1689725104000,
        "value": 139.003,
        "_internal_originalTime": 1689725104000
    },
    {
        "time": 1689725108000,
        "value": 139,
        "_internal_originalTime": 1689725108000
    },
    {
        "time": 1689725111000,
        "value": 139.001,
        "_internal_originalTime": 1689725111000
    },
    {
        "time": 1689725114000,
        "value": 139.002,
        "_internal_originalTime": 1689725114000
    },
    {
        "time": 1689725117000,
        "value": 138.998,
        "_internal_originalTime": 1689725117000
    },
    {
        "time": 1689725120000,
        "value": 139.002,
        "_internal_originalTime": 1689725120000
    },
    {
        "time": 1689725124000,
        "value": 139.009,
        "_internal_originalTime": 1689725124000
    },
    {
        "time": 1689725128000,
        "value": 138.997,
        "_internal_originalTime": 1689725128000
    },
    {
        "time": 1689725130000,
        "value": 138.991,
        "_internal_originalTime": 1689725130000
    },
    {
        "time": 1689725134000,
        "value": 138.988,
        "_internal_originalTime": 1689725134000
    },
    {
        "time": 1689725137000,
        "value": 138.98,
        "_internal_originalTime": 1689725137000
    },
    {
        "time": 1689725141000,
        "value": 138.981,
        "_internal_originalTime": 1689725141000
    },
    {
        "time": 1689725144000,
        "value": 138.981,
        "_internal_originalTime": 1689725144000
    },
    {
        "time": 1689725147000,
        "value": 138.966,
        "_internal_originalTime": 1689725147000
    },
    {
        "time": 1689725150000,
        "value": 138.963,
        "_internal_originalTime": 1689725150000
    },
    {
        "time": 1689725153000,
        "value": 138.972,
        "_internal_originalTime": 1689725153000
    },
    {
        "time": 1689725157000,
        "value": 138.972,
        "_internal_originalTime": 1689725157000
    },
    {
        "time": 1689725159000,
        "value": 138.972,
        "_internal_originalTime": 1689725159000
    },
    {
        "time": 1689725160000,
        "value": 138.95,
        "_internal_originalTime": 1689725160000
    },
    {
        "time": 1689725165000,
        "value": 138.973,
        "_internal_originalTime": 1689725165000
    },
    {
        "time": 1689725168000,
        "value": 138.971,
        "_internal_originalTime": 1689725168000
    },
    {
        "time": 1689725172000,
        "value": 138.982,
        "_internal_originalTime": 1689725172000
    },
    {
        "time": 1689725175000,
        "value": 138.982,
        "_internal_originalTime": 1689725175000
    },
    {
        "time": 1689725178000,
        "value": 138.982,
        "_internal_originalTime": 1689725178000
    },
    {
        "time": 1689725181000,
        "value": 138.972,
        "_internal_originalTime": 1689725181000
    },
    {
        "time": 1689725185000,
        "value": 138.972,
        "_internal_originalTime": 1689725185000
    },
    {
        "time": 1689725188000,
        "value": 138.985,
        "_internal_originalTime": 1689725188000
    },
    {
        "time": 1689725190000,
        "value": 138.983,
        "_internal_originalTime": 1689725190000
    },
    {
        "time": 1689725193000,
        "value": 138.986,
        "_internal_originalTime": 1689725193000
    },
    {
        "time": 1689725197000,
        "value": 138.986,
        "_internal_originalTime": 1689725197000
    },
    {
        "time": 1689725200000,
        "value": 138.984,
        "_internal_originalTime": 1689725200000
    },
    {
        "time": 1689725202000,
        "value": 138.984,
        "_internal_originalTime": 1689725202000
    },
    {
        "time": 1689725208000,
        "value": 138.984,
        "_internal_originalTime": 1689725208000
    },
    {
        "time": 1689725211000,
        "value": 138.984,
        "_internal_originalTime": 1689725211000
    },
    {
        "time": 1689725215000,
        "value": 138.99,
        "_internal_originalTime": 1689725215000
    },
    {
        "time": 1689725218000,
        "value": 138.983,
        "_internal_originalTime": 1689725218000
    },
    {
        "time": 1689725222000,
        "value": 138.982,
        "_internal_originalTime": 1689725222000
    },
    {
        "time": 1689725225000,
        "value": 138.984,
        "_internal_originalTime": 1689725225000
    },
    {
        "time": 1689725229000,
        "value": 138.988,
        "_internal_originalTime": 1689725229000
    },
    {
        "time": 1689725233000,
        "value": 138.991,
        "_internal_originalTime": 1689725233000
    },
    {
        "time": 1689725235000,
        "value": 138.99,
        "_internal_originalTime": 1689725235000
    },
    {
        "time": 1689725238000,
        "value": 138.994,
        "_internal_originalTime": 1689725238000
    },
    {
        "time": 1689725241000,
        "value": 138.992,
        "_internal_originalTime": 1689725241000
    },
    {
        "time": 1689725244000,
        "value": 138.998,
        "_internal_originalTime": 1689725244000
    },
    {
        "time": 1689725248000,
        "value": 138.996,
        "_internal_originalTime": 1689725248000
    },
    {
        "time": 1689725250000,
        "value": 138.993,
        "_internal_originalTime": 1689725250000
    },
    {
        "time": 1689725254000,
        "value": 139,
        "_internal_originalTime": 1689725254000
    },
    {
        "time": 1689725257000,
        "value": 139.003,
        "_internal_originalTime": 1689725257000
    },
    {
        "time": 1689725260000,
        "value": 139.008,
        "_internal_originalTime": 1689725260000
    },
    {
        "time": 1689725263000,
        "value": 138.999,
        "_internal_originalTime": 1689725263000
    },
    {
        "time": 1689725266000,
        "value": 139,
        "_internal_originalTime": 1689725266000
    },
    {
        "time": 1689725273000,
        "value": 138.998,
        "_internal_originalTime": 1689725273000
    },
    {
        "time": 1689725277000,
        "value": 138.996,
        "_internal_originalTime": 1689725277000
    },
    {
        "time": 1689725279000,
        "value": 138.998,
        "_internal_originalTime": 1689725279000
    },
    {
        "time": 1689725282000,
        "value": 138.996,
        "_internal_originalTime": 1689725282000
    },
    {
        "time": 1689725284000,
        "value": 138.995,
        "_internal_originalTime": 1689725284000
    },
    {
        "time": 1689725286000,
        "value": 138.995,
        "_internal_originalTime": 1689725286000
    },
    {
        "time": 1689725292000,
        "value": 139.002,
        "_internal_originalTime": 1689725292000
    },
    {
        "time": 1689725295000,
        "value": 139.003,
        "_internal_originalTime": 1689725295000
    },
    {
        "time": 1689725298000,
        "value": 139.001,
        "_internal_originalTime": 1689725298000
    },
    {
        "time": 1689725302000,
        "value": 139.006,
        "_internal_originalTime": 1689725302000
    },
    {
        "time": 1689725305000,
        "value": 138.998,
        "_internal_originalTime": 1689725305000
    },
    {
        "time": 1689725309000,
        "value": 138.989,
        "_internal_originalTime": 1689725309000
    },
    {
        "time": 1689725312000,
        "value": 138.969,
        "_internal_originalTime": 1689725312000
    },
    {
        "time": 1689725315000,
        "value": 138.963,
        "_internal_originalTime": 1689725315000
    },
    {
        "time": 1689725319000,
        "value": 138.963,
        "_internal_originalTime": 1689725319000
    },
    {
        "time": 1689725324000,
        "value": 138.961,
        "_internal_originalTime": 1689725324000
    },
    {
        "time": 1689725326000,
        "value": 138.961,
        "_internal_originalTime": 1689725326000
    },
    {
        "time": 1689725330000,
        "value": 138.968,
        "_internal_originalTime": 1689725330000
    },
    {
        "time": 1689725333000,
        "value": 138.973,
        "_internal_originalTime": 1689725333000
    },
    {
        "time": 1689725336000,
        "value": 138.973,
        "_internal_originalTime": 1689725336000
    },
    {
        "time": 1689725337000,
        "value": 138.973,
        "_internal_originalTime": 1689725337000
    },
    {
        "time": 1689725340000,
        "value": 138.976,
        "_internal_originalTime": 1689725340000
    },
    {
        "time": 1689725342000,
        "value": 138.986,
        "_internal_originalTime": 1689725342000
    },
    {
        "time": 1689725344000,
        "value": 138.992,
        "_internal_originalTime": 1689725344000
    },
    {
        "time": 1689725347000,
        "value": 138.99,
        "_internal_originalTime": 1689725347000
    },
    {
        "time": 1689725350000,
        "value": 138.976,
        "_internal_originalTime": 1689725350000
    },
    {
        "time": 1689725354000,
        "value": 138.98,
        "_internal_originalTime": 1689725354000
    },
    {
        "time": 1689725356000,
        "value": 138.98,
        "_internal_originalTime": 1689725356000
    },
    {
        "time": 1689725359000,
        "value": 138.98,
        "_internal_originalTime": 1689725359000
    },
    {
        "time": 1689725364000,
        "value": 138.981,
        "_internal_originalTime": 1689725364000
    },
    {
        "time": 1689725367000,
        "value": 138.98,
        "_internal_originalTime": 1689725367000
    },
    {
        "time": 1689725370000,
        "value": 138.982,
        "_internal_originalTime": 1689725370000
    },
    {
        "time": 1689725374000,
        "value": 138.991,
        "_internal_originalTime": 1689725374000
    },
    {
        "time": 1689725378000,
        "value": 138.988,
        "_internal_originalTime": 1689725378000
    },
    {
        "time": 1689725381000,
        "value": 138.99,
        "_internal_originalTime": 1689725381000
    },
    {
        "time": 1689725385000,
        "value": 138.982,
        "_internal_originalTime": 1689725385000
    },
    {
        "time": 1689725389000,
        "value": 138.978,
        "_internal_originalTime": 1689725389000
    },
    {
        "time": 1689725393000,
        "value": 138.98,
        "_internal_originalTime": 1689725393000
    },
    {
        "time": 1689725396000,
        "value": 138.978,
        "_internal_originalTime": 1689725396000
    },
    {
        "time": 1689725399000,
        "value": 138.964,
        "_internal_originalTime": 1689725399000
    },
    {
        "time": 1689725400000,
        "value": 138.959,
        "_internal_originalTime": 1689725400000
    },
    {
        "time": 1689725402000,
        "value": 138.961,
        "_internal_originalTime": 1689725402000
    },
    {
        "time": 1689725405000,
        "value": 138.94,
        "_internal_originalTime": 1689725405000
    },
    {
        "time": 1689725408000,
        "value": 138.943,
        "_internal_originalTime": 1689725408000
    },
    {
        "time": 1689725411000,
        "value": 138.943,
        "_internal_originalTime": 1689725411000
    },
    {
        "time": 1689725415000,
        "value": 138.951,
        "_internal_originalTime": 1689725415000
    },
    {
        "time": 1689725417000,
        "value": 138.952,
        "_internal_originalTime": 1689725417000
    },
    {
        "time": 1689725420000,
        "value": 138.955,
        "_internal_originalTime": 1689725420000
    },
    {
        "time": 1689725424000,
        "value": 138.954,
        "_internal_originalTime": 1689725424000
    },
    {
        "time": 1689725426000,
        "value": 138.955,
        "_internal_originalTime": 1689725426000
    },
    {
        "time": 1689725430000,
        "value": 138.96,
        "_internal_originalTime": 1689725430000
    },
    {
        "time": 1689725432000,
        "value": 138.962,
        "_internal_originalTime": 1689725432000
    },
    {
        "time": 1689725436000,
        "value": 138.963,
        "_internal_originalTime": 1689725436000
    },
    {
        "time": 1689725440000,
        "value": 138.971,
        "_internal_originalTime": 1689725440000
    },
    {
        "time": 1689725443000,
        "value": 138.967,
        "_internal_originalTime": 1689725443000
    },
    {
        "time": 1689725445000,
        "value": 138.968,
        "_internal_originalTime": 1689725445000
    },
    {
        "time": 1689725450000,
        "value": 138.97,
        "_internal_originalTime": 1689725450000
    },
    {
        "time": 1689725454000,
        "value": 138.966,
        "_internal_originalTime": 1689725454000
    },
    {
        "time": 1689725458000,
        "value": 138.977,
        "_internal_originalTime": 1689725458000
    },
    {
        "time": 1689725461000,
        "value": 138.983,
        "_internal_originalTime": 1689725461000
    },
    {
        "time": 1689725463000,
        "value": 138.978,
        "_internal_originalTime": 1689725463000
    },
    {
        "time": 1689725465000,
        "value": 138.979,
        "_internal_originalTime": 1689725465000
    },
    {
        "time": 1689725470000,
        "value": 138.983,
        "_internal_originalTime": 1689725470000
    },
    {
        "time": 1689725474000,
        "value": 138.982,
        "_internal_originalTime": 1689725474000
    },
    {
        "time": 1689725477000,
        "value": 138.989,
        "_internal_originalTime": 1689725477000
    },
    {
        "time": 1689725480000,
        "value": 138.988,
        "_internal_originalTime": 1689725480000
    },
    {
        "time": 1689725483000,
        "value": 138.988,
        "_internal_originalTime": 1689725483000
    },
    {
        "time": 1689725486000,
        "value": 138.981,
        "_internal_originalTime": 1689725486000
    },
    {
        "time": 1689725488000,
        "value": 138.978,
        "_internal_originalTime": 1689725488000
    },
    {
        "time": 1689725491000,
        "value": 138.978,
        "_internal_originalTime": 1689725491000
    },
    {
        "time": 1689725495000,
        "value": 138.98,
        "_internal_originalTime": 1689725495000
    },
    {
        "time": 1689725498000,
        "value": 138.979,
        "_internal_originalTime": 1689725498000
    },
    {
        "time": 1689725501000,
        "value": 138.971,
        "_internal_originalTime": 1689725501000
    },
    {
        "time": 1689725505000,
        "value": 138.977,
        "_internal_originalTime": 1689725505000
    },
    {
        "time": 1689725508000,
        "value": 138.984,
        "_internal_originalTime": 1689725508000
    },
    {
        "time": 1689725512000,
        "value": 138.982,
        "_internal_originalTime": 1689725512000
    },
    {
        "time": 1689725513000,
        "value": 138.981,
        "_internal_originalTime": 1689725513000
    },
    {
        "time": 1689725519000,
        "value": 138.982,
        "_internal_originalTime": 1689725519000
    },
    {
        "time": 1689725522000,
        "value": 138.983,
        "_internal_originalTime": 1689725522000
    },
    {
        "time": 1689725525000,
        "value": 138.984,
        "_internal_originalTime": 1689725525000
    },
    {
        "time": 1689725529000,
        "value": 138.984,
        "_internal_originalTime": 1689725529000
    },
    {
        "time": 1689725534000,
        "value": 138.994,
        "_internal_originalTime": 1689725534000
    },
    {
        "time": 1689725535000,
        "value": 138.991,
        "_internal_originalTime": 1689725535000
    },
    {
        "time": 1689725540000,
        "value": 138.99,
        "_internal_originalTime": 1689725540000
    },
    {
        "time": 1689725543000,
        "value": 138.98,
        "_internal_originalTime": 1689725543000
    },
    {
        "time": 1689725547000,
        "value": 138.982,
        "_internal_originalTime": 1689725547000
    },
    {
        "time": 1689725549000,
        "value": 139.004,
        "_internal_originalTime": 1689725549000
    },
    {
        "time": 1689725551000,
        "value": 139.002,
        "_internal_originalTime": 1689725551000
    },
    {
        "time": 1689725555000,
        "value": 139.02,
        "_internal_originalTime": 1689725555000
    },
    {
        "time": 1689725558000,
        "value": 139.025,
        "_internal_originalTime": 1689725558000
    },
    {
        "time": 1689725562000,
        "value": 139.038,
        "_internal_originalTime": 1689725562000
    },
    {
        "time": 1689725565000,
        "value": 139.034,
        "_internal_originalTime": 1689725565000
    },
    {
        "time": 1689725568000,
        "value": 139.046,
        "_internal_originalTime": 1689725568000
    },
    {
        "time": 1689725571000,
        "value": 139.039,
        "_internal_originalTime": 1689725571000
    },
    {
        "time": 1689725575000,
        "value": 139.029,
        "_internal_originalTime": 1689725575000
    },
    {
        "time": 1689725579000,
        "value": 139.034,
        "_internal_originalTime": 1689725579000
    },
    {
        "time": 1689725581000,
        "value": 139.03,
        "_internal_originalTime": 1689725581000
    },
    {
        "time": 1689725585000,
        "value": 139.055,
        "_internal_originalTime": 1689725585000
    },
    {
        "time": 1689725588000,
        "value": 139.052,
        "_internal_originalTime": 1689725588000
    },
    {
        "time": 1689725591000,
        "value": 139.067,
        "_internal_originalTime": 1689725591000
    },
    {
        "time": 1689725594000,
        "value": 139.08,
        "_internal_originalTime": 1689725594000
    },
    {
        "time": 1689725596000,
        "value": 139.093,
        "_internal_originalTime": 1689725596000
    },
    {
        "time": 1689725599000,
        "value": 139.089,
        "_internal_originalTime": 1689725599000
    },
    {
        "time": 1689725602000,
        "value": 139.083,
        "_internal_originalTime": 1689725602000
    },
    {
        "time": 1689725606000,
        "value": 139.102,
        "_internal_originalTime": 1689725606000
    },
    {
        "time": 1689725607000,
        "value": 139.097,
        "_internal_originalTime": 1689725607000
    },
    {
        "time": 1689725610000,
        "value": 139.102,
        "_internal_originalTime": 1689725610000
    },
    {
        "time": 1689725614000,
        "value": 139.102,
        "_internal_originalTime": 1689725614000
    },
    {
        "time": 1689725617000,
        "value": 139.092,
        "_internal_originalTime": 1689725617000
    },
    {
        "time": 1689725621000,
        "value": 139.091,
        "_internal_originalTime": 1689725621000
    },
    {
        "time": 1689725624000,
        "value": 139.08,
        "_internal_originalTime": 1689725624000
    },
    {
        "time": 1689725627000,
        "value": 139.075,
        "_internal_originalTime": 1689725627000
    },
    {
        "time": 1689725630000,
        "value": 139.062,
        "_internal_originalTime": 1689725630000
    },
    {
        "time": 1689725633000,
        "value": 139.056,
        "_internal_originalTime": 1689725633000
    },
    {
        "time": 1689725637000,
        "value": 139.067,
        "_internal_originalTime": 1689725637000
    },
    {
        "time": 1689725640000,
        "value": 139.067,
        "_internal_originalTime": 1689725640000
    },
    {
        "time": 1689725643000,
        "value": 139.069,
        "_internal_originalTime": 1689725643000
    },
    {
        "time": 1689725645000,
        "value": 139.069,
        "_internal_originalTime": 1689725645000
    },
    {
        "time": 1689725649000,
        "value": 139.069,
        "_internal_originalTime": 1689725649000
    },
    {
        "time": 1689725653000,
        "value": 139.067,
        "_internal_originalTime": 1689725653000
    },
    {
        "time": 1689725656000,
        "value": 139.068,
        "_internal_originalTime": 1689725656000
    },
    {
        "time": 1689725660000,
        "value": 139.067,
        "_internal_originalTime": 1689725660000
    },
    {
        "time": 1689725663000,
        "value": 139.077,
        "_internal_originalTime": 1689725663000
    },
    {
        "time": 1689725666000,
        "value": 139.068,
        "_internal_originalTime": 1689725666000
    },
    {
        "time": 1689725667000,
        "value": 139.063,
        "_internal_originalTime": 1689725667000
    },
    {
        "time": 1689725668000,
        "value": 139.062,
        "_internal_originalTime": 1689725668000
    },
    {
        "time": 1689725670000,
        "value": 139.061,
        "_internal_originalTime": 1689725670000
    },
    {
        "time": 1689725671000,
        "value": 139.053,
        "_internal_originalTime": 1689725671000
    },
    {
        "time": 1689725676000,
        "value": 139.054,
        "_internal_originalTime": 1689725676000
    },
    {
        "time": 1689725680000,
        "value": 139.057,
        "_internal_originalTime": 1689725680000
    },
    {
        "time": 1689725683000,
        "value": 139.06,
        "_internal_originalTime": 1689725683000
    },
    {
        "time": 1689725687000,
        "value": 139.067,
        "_internal_originalTime": 1689725687000
    },
    {
        "time": 1689725689000,
        "value": 139.061,
        "_internal_originalTime": 1689725689000
    },
    {
        "time": 1689725693000,
        "value": 139.058,
        "_internal_originalTime": 1689725693000
    },
    {
        "time": 1689725697000,
        "value": 139.057,
        "_internal_originalTime": 1689725697000
    },
    {
        "time": 1689725699000,
        "value": 139.054,
        "_internal_originalTime": 1689725699000
    },
    {
        "time": 1689725702000,
        "value": 139.056,
        "_internal_originalTime": 1689725702000
    },
    {
        "time": 1689725704000,
        "value": 139.051,
        "_internal_originalTime": 1689725704000
    },
    {
        "time": 1689725705000,
        "value": 139.048,
        "_internal_originalTime": 1689725705000
    },
    {
        "time": 1689725706000,
        "value": 139.048,
        "_internal_originalTime": 1689725706000
    },
    {
        "time": 1689725710000,
        "value": 139.05,
        "_internal_originalTime": 1689725710000
    },
    {
        "time": 1689725712000,
        "value": 139.049,
        "_internal_originalTime": 1689725712000
    },
    {
        "time": 1689725713000,
        "value": 139.048,
        "_internal_originalTime": 1689725713000
    },
    {
        "time": 1689725716000,
        "value": 139.049,
        "_internal_originalTime": 1689725716000
    },
    {
        "time": 1689725718000,
        "value": 139.048,
        "_internal_originalTime": 1689725718000
    },
    {
        "time": 1689725720000,
        "value": 139.051,
        "_internal_originalTime": 1689725720000
    },
    {
        "time": 1689725723000,
        "value": 139.054,
        "_internal_originalTime": 1689725723000
    },
    {
        "time": 1689725727000,
        "value": 139.05,
        "_internal_originalTime": 1689725727000
    },
    {
        "time": 1689725729000,
        "value": 139.057,
        "_internal_originalTime": 1689725729000
    },
    {
        "time": 1689725730000,
        "value": 139.055,
        "_internal_originalTime": 1689725730000
    },
    {
        "time": 1689725731000,
        "value": 139.056,
        "_internal_originalTime": 1689725731000
    },
    {
        "time": 1689725735000,
        "value": 139.046,
        "_internal_originalTime": 1689725735000
    },
    {
        "time": 1689725737000,
        "value": 139.032,
        "_internal_originalTime": 1689725737000
    },
    {
        "time": 1689725739000,
        "value": 139.03,
        "_internal_originalTime": 1689725739000
    },
    {
        "time": 1689725742000,
        "value": 139.028,
        "_internal_originalTime": 1689725742000
    },
    {
        "time": 1689725746000,
        "value": 139.031,
        "_internal_originalTime": 1689725746000
    },
    {
        "time": 1689725748000,
        "value": 139.034,
        "_internal_originalTime": 1689725748000
    },
    {
        "time": 1689725750000,
        "value": 139.034,
        "_internal_originalTime": 1689725750000
    },
    {
        "time": 1689725753000,
        "value": 139.035,
        "_internal_originalTime": 1689725753000
    },
    {
        "time": 1689725755000,
        "value": 139.034,
        "_internal_originalTime": 1689725755000
    },
    {
        "time": 1689725758000,
        "value": 139.039,
        "_internal_originalTime": 1689725758000
    },
    {
        "time": 1689725760000,
        "value": 139.033,
        "_internal_originalTime": 1689725760000
    },
    {
        "time": 1689725762000,
        "value": 139.027,
        "_internal_originalTime": 1689725762000
    },
    {
        "time": 1689725764000,
        "value": 139.018,
        "_internal_originalTime": 1689725764000
    },
    {
        "time": 1689725766000,
        "value": 139.027,
        "_internal_originalTime": 1689725766000
    },
    {
        "time": 1689725769000,
        "value": 139.03,
        "_internal_originalTime": 1689725769000
    },
    {
        "time": 1689725771000,
        "value": 139.03,
        "_internal_originalTime": 1689725771000
    },
    {
        "time": 1689725774000,
        "value": 139.027,
        "_internal_originalTime": 1689725774000
    },
    {
        "time": 1689725775000,
        "value": 139.027,
        "_internal_originalTime": 1689725775000
    },
    {
        "time": 1689725778000,
        "value": 139.027,
        "_internal_originalTime": 1689725778000
    },
    {
        "time": 1689725780000,
        "value": 139.03,
        "_internal_originalTime": 1689725780000
    },
    {
        "time": 1689725782000,
        "value": 139.03,
        "_internal_originalTime": 1689725782000
    },
    {
        "time": 1689725785000,
        "value": 139.03,
        "_internal_originalTime": 1689725785000
    },
    {
        "time": 1689725786000,
        "value": 139.029,
        "_internal_originalTime": 1689725786000
    },
    {
        "time": 1689725789000,
        "value": 139.018,
        "_internal_originalTime": 1689725789000
    },
    {
        "time": 1689725790000,
        "value": 139.016,
        "_internal_originalTime": 1689725790000
    },
    {
        "time": 1689725792000,
        "value": 139.018,
        "_internal_originalTime": 1689725792000
    },
    {
        "time": 1689725795000,
        "value": 139.018,
        "_internal_originalTime": 1689725795000
    },
    {
        "time": 1689725796000,
        "value": 139.018,
        "_internal_originalTime": 1689725796000
    },
    {
        "time": 1689725800000,
        "value": 139.008,
        "_internal_originalTime": 1689725800000
    },
    {
        "time": 1689725802000,
        "value": 139.011,
        "_internal_originalTime": 1689725802000
    },
    {
        "time": 1689725805000,
        "value": 139,
        "_internal_originalTime": 1689725805000
    },
    {
        "time": 1689725806000,
        "value": 139,
        "_internal_originalTime": 1689725806000
    },
    {
        "time": 1689725808000,
        "value": 139.011,
        "_internal_originalTime": 1689725808000
    },
    {
        "time": 1689725811000,
        "value": 139.008,
        "_internal_originalTime": 1689725811000
    },
    {
        "time": 1689725814000,
        "value": 139.007,
        "_internal_originalTime": 1689725814000
    },
    {
        "time": 1689725816000,
        "value": 139.008,
        "_internal_originalTime": 1689725816000
    },
    {
        "time": 1689725819000,
        "value": 139.013,
        "_internal_originalTime": 1689725819000
    },
    {
        "time": 1689725820000,
        "value": 139.008,
        "_internal_originalTime": 1689725820000
    },
    {
        "time": 1689725823000,
        "value": 139.007,
        "_internal_originalTime": 1689725823000
    },
    {
        "time": 1689725825000,
        "value": 139.008,
        "_internal_originalTime": 1689725825000
    },
    {
        "time": 1689725827000,
        "value": 139.008,
        "_internal_originalTime": 1689725827000
    },
    {
        "time": 1689725830000,
        "value": 139.007,
        "_internal_originalTime": 1689725830000
    },
    {
        "time": 1689725833000,
        "value": 139.007,
        "_internal_originalTime": 1689725833000
    },
    {
        "time": 1689725837000,
        "value": 139.007,
        "_internal_originalTime": 1689725837000
    },
    {
        "time": 1689725839000,
        "value": 139.007,
        "_internal_originalTime": 1689725839000
    },
    {
        "time": 1689725842000,
        "value": 139.008,
        "_internal_originalTime": 1689725842000
    },
    {
        "time": 1689725844000,
        "value": 139.006,
        "_internal_originalTime": 1689725844000
    },
    {
        "time": 1689725847000,
        "value": 139.005,
        "_internal_originalTime": 1689725847000
    },
    {
        "time": 1689725849000,
        "value": 139.007,
        "_internal_originalTime": 1689725849000
    },
    {
        "time": 1689725851000,
        "value": 139.008,
        "_internal_originalTime": 1689725851000
    },
    {
        "time": 1689725853000,
        "value": 139.006,
        "_internal_originalTime": 1689725853000
    },
    {
        "time": 1689725854000,
        "value": 139.006,
        "_internal_originalTime": 1689725854000
    },
    {
        "time": 1689725858000,
        "value": 139.006,
        "_internal_originalTime": 1689725858000
    },
    {
        "time": 1689725861000,
        "value": 138.998,
        "_internal_originalTime": 1689725861000
    },
    {
        "time": 1689725864000,
        "value": 138.998,
        "_internal_originalTime": 1689725864000
    },
    {
        "time": 1689725865000,
        "value": 138.988,
        "_internal_originalTime": 1689725865000
    },
    {
        "time": 1689725866000,
        "value": 138.988,
        "_internal_originalTime": 1689725866000
    },
    {
        "time": 1689725871000,
        "value": 138.992,
        "_internal_originalTime": 1689725871000
    },
    {
        "time": 1689725873000,
        "value": 138.992,
        "_internal_originalTime": 1689725873000
    },
    {
        "time": 1689725875000,
        "value": 138.99,
        "_internal_originalTime": 1689725875000
    },
    {
        "time": 1689725878000,
        "value": 138.991,
        "_internal_originalTime": 1689725878000
    },
    {
        "time": 1689725879000,
        "value": 138.99,
        "_internal_originalTime": 1689725879000
    },
    {
        "time": 1689725881000,
        "value": 138.992,
        "_internal_originalTime": 1689725881000
    },
    {
        "time": 1689725884000,
        "value": 138.988,
        "_internal_originalTime": 1689725884000
    },
    {
        "time": 1689725886000,
        "value": 138.99,
        "_internal_originalTime": 1689725886000
    },
    {
        "time": 1689725889000,
        "value": 138.988,
        "_internal_originalTime": 1689725889000
    },
    {
        "time": 1689725891000,
        "value": 138.98,
        "_internal_originalTime": 1689725891000
    },
    {
        "time": 1689725893000,
        "value": 138.979,
        "_internal_originalTime": 1689725893000
    },
    {
        "time": 1689725895000,
        "value": 138.981,
        "_internal_originalTime": 1689725895000
    },
    {
        "time": 1689725897000,
        "value": 138.983,
        "_internal_originalTime": 1689725897000
    },
    {
        "time": 1689725900000,
        "value": 138.981,
        "_internal_originalTime": 1689725900000
    },
    {
        "time": 1689725902000,
        "value": 138.978,
        "_internal_originalTime": 1689725902000
    },
    {
        "time": 1689725905000,
        "value": 138.954,
        "_internal_originalTime": 1689725905000
    },
    {
        "time": 1689725908000,
        "value": 138.956,
        "_internal_originalTime": 1689725908000
    },
    {
        "time": 1689725909000,
        "value": 138.955,
        "_internal_originalTime": 1689725909000
    },
    {
        "time": 1689725911000,
        "value": 138.962,
        "_internal_originalTime": 1689725911000
    },
    {
        "time": 1689725914000,
        "value": 138.96,
        "_internal_originalTime": 1689725914000
    },
    {
        "time": 1689725916000,
        "value": 138.963,
        "_internal_originalTime": 1689725916000
    },
    {
        "time": 1689725919000,
        "value": 138.96,
        "_internal_originalTime": 1689725919000
    },
    {
        "time": 1689725922000,
        "value": 138.972,
        "_internal_originalTime": 1689725922000
    },
    {
        "time": 1689725924000,
        "value": 138.972,
        "_internal_originalTime": 1689725924000
    },
    {
        "time": 1689725926000,
        "value": 138.969,
        "_internal_originalTime": 1689725926000
    },
    {
        "time": 1689725928000,
        "value": 138.969,
        "_internal_originalTime": 1689725928000
    },
    {
        "time": 1689725931000,
        "value": 138.972,
        "_internal_originalTime": 1689725931000
    },
    {
        "time": 1689725934000,
        "value": 138.981,
        "_internal_originalTime": 1689725934000
    },
    {
        "time": 1689725936000,
        "value": 138.974,
        "_internal_originalTime": 1689725936000
    },
    {
        "time": 1689725938000,
        "value": 138.971,
        "_internal_originalTime": 1689725938000
    },
    {
        "time": 1689725940000,
        "value": 138.973,
        "_internal_originalTime": 1689725940000
    },
    {
        "time": 1689725941000,
        "value": 138.95,
        "_internal_originalTime": 1689725941000
    },
    {
        "time": 1689725945000,
        "value": 138.962,
        "_internal_originalTime": 1689725945000
    },
    {
        "time": 1689725947000,
        "value": 138.962,
        "_internal_originalTime": 1689725947000
    },
    {
        "time": 1689725949000,
        "value": 138.964,
        "_internal_originalTime": 1689725949000
    },
    {
        "time": 1689725952000,
        "value": 138.96,
        "_internal_originalTime": 1689725952000
    },
    {
        "time": 1689725955000,
        "value": 138.958,
        "_internal_originalTime": 1689725955000
    },
    {
        "time": 1689725957000,
        "value": 138.959,
        "_internal_originalTime": 1689725957000
    },
    {
        "time": 1689725959000,
        "value": 138.963,
        "_internal_originalTime": 1689725959000
    },
    {
        "time": 1689725962000,
        "value": 138.961,
        "_internal_originalTime": 1689725962000
    },
    {
        "time": 1689725964000,
        "value": 138.962,
        "_internal_originalTime": 1689725964000
    },
    {
        "time": 1689725967000,
        "value": 138.958,
        "_internal_originalTime": 1689725967000
    },
    {
        "time": 1689725969000,
        "value": 138.954,
        "_internal_originalTime": 1689725969000
    },
    {
        "time": 1689725971000,
        "value": 138.953,
        "_internal_originalTime": 1689725971000
    },
    {
        "time": 1689725973000,
        "value": 138.95,
        "_internal_originalTime": 1689725973000
    },
    {
        "time": 1689725976000,
        "value": 138.952,
        "_internal_originalTime": 1689725976000
    },
    {
        "time": 1689725979000,
        "value": 138.954,
        "_internal_originalTime": 1689725979000
    },
    {
        "time": 1689725980000,
        "value": 138.953,
        "_internal_originalTime": 1689725980000
    },
    {
        "time": 1689725984000,
        "value": 138.959,
        "_internal_originalTime": 1689725984000
    },
    {
        "time": 1689725986000,
        "value": 138.96,
        "_internal_originalTime": 1689725986000
    },
    {
        "time": 1689725988000,
        "value": 138.96,
        "_internal_originalTime": 1689725988000
    },
    {
        "time": 1689725990000,
        "value": 138.962,
        "_internal_originalTime": 1689725990000
    },
    {
        "time": 1689725993000,
        "value": 138.959,
        "_internal_originalTime": 1689725993000
    },
    {
        "time": 1689725996000,
        "value": 138.959,
        "_internal_originalTime": 1689725996000
    },
    {
        "time": 1689725999000,
        "value": 138.955,
        "_internal_originalTime": 1689725999000
    },
    {
        "time": 1689726001000,
        "value": 138.944,
        "_internal_originalTime": 1689726001000
    },
    {
        "time": 1689726003000,
        "value": 138.944,
        "_internal_originalTime": 1689726003000
    },
    {
        "time": 1689726004000,
        "value": 138.943,
        "_internal_originalTime": 1689726004000
    },
    {
        "time": 1689726008000,
        "value": 138.949,
        "_internal_originalTime": 1689726008000
    },
    {
        "time": 1689726010000,
        "value": 138.953,
        "_internal_originalTime": 1689726010000
    },
    {
        "time": 1689726013000,
        "value": 138.944,
        "_internal_originalTime": 1689726013000
    },
    {
        "time": 1689726015000,
        "value": 138.945,
        "_internal_originalTime": 1689726015000
    },
    {
        "time": 1689726017000,
        "value": 138.956,
        "_internal_originalTime": 1689726017000
    },
    {
        "time": 1689726019000,
        "value": 138.956,
        "_internal_originalTime": 1689726019000
    },
    {
        "time": 1689726021000,
        "value": 138.955,
        "_internal_originalTime": 1689726021000
    },
    {
        "time": 1689726024000,
        "value": 138.959,
        "_internal_originalTime": 1689726024000
    },
    {
        "time": 1689726027000,
        "value": 138.958,
        "_internal_originalTime": 1689726027000
    },
    {
        "time": 1689726028000,
        "value": 138.959,
        "_internal_originalTime": 1689726028000
    },
    {
        "time": 1689726031000,
        "value": 138.964,
        "_internal_originalTime": 1689726031000
    },
    {
        "time": 1689726032000,
        "value": 138.963,
        "_internal_originalTime": 1689726032000
    },
    {
        "time": 1689726033000,
        "value": 138.974,
        "_internal_originalTime": 1689726033000
    },
    {
        "time": 1689726034000,
        "value": 138.974,
        "_internal_originalTime": 1689726034000
    },
    {
        "time": 1689726035000,
        "value": 138.974,
        "_internal_originalTime": 1689726035000
    },
    {
        "time": 1689726036000,
        "value": 138.971,
        "_internal_originalTime": 1689726036000
    },
    {
        "time": 1689726038000,
        "value": 138.969,
        "_internal_originalTime": 1689726038000
    },
    {
        "time": 1689726040000,
        "value": 138.972,
        "_internal_originalTime": 1689726040000
    },
    {
        "time": 1689726041000,
        "value": 138.98,
        "_internal_originalTime": 1689726041000
    },
    {
        "time": 1689726042000,
        "value": 138.978,
        "_internal_originalTime": 1689726042000
    },
    {
        "time": 1689726043000,
        "value": 138.974,
        "_internal_originalTime": 1689726043000
    },
    {
        "time": 1689726044000,
        "value": 138.977,
        "_internal_originalTime": 1689726044000
    },
    {
        "time": 1689726045000,
        "value": 138.976,
        "_internal_originalTime": 1689726045000
    },
    {
        "time": 1689726046000,
        "value": 138.977,
        "_internal_originalTime": 1689726046000
    },
    {
        "time": 1689726047000,
        "value": 138.978,
        "_internal_originalTime": 1689726047000
    },
    {
        "time": 1689726048000,
        "value": 138.981,
        "_internal_originalTime": 1689726048000
    },
    {
        "time": 1689726051000,
        "value": 138.984,
        "_internal_originalTime": 1689726051000
    },
    {
        "time": 1689726055000,
        "value": 138.991,
        "_internal_originalTime": 1689726055000
    },
    {
        "time": 1689726057000,
        "value": 138.989,
        "_internal_originalTime": 1689726057000
    },
    {
        "time": 1689726060000,
        "value": 138.975,
        "_internal_originalTime": 1689726060000
    },
    {
        "time": 1689726061000,
        "value": 138.971,
        "_internal_originalTime": 1689726061000
    },
    {
        "time": 1689726062000,
        "value": 138.97,
        "_internal_originalTime": 1689726062000
    },
    {
        "time": 1689726064000,
        "value": 138.97,
        "_internal_originalTime": 1689726064000
    },
    {
        "time": 1689726066000,
        "value": 138.969,
        "_internal_originalTime": 1689726066000
    },
    {
        "time": 1689726067000,
        "value": 138.969,
        "_internal_originalTime": 1689726067000
    },
    {
        "time": 1689726069000,
        "value": 138.963,
        "_internal_originalTime": 1689726069000
    },
    {
        "time": 1689726070000,
        "value": 138.964,
        "_internal_originalTime": 1689726070000
    },
    {
        "time": 1689726071000,
        "value": 138.97,
        "_internal_originalTime": 1689726071000
    },
    {
        "time": 1689726072000,
        "value": 138.964,
        "_internal_originalTime": 1689726072000
    },
    {
        "time": 1689726073000,
        "value": 138.969,
        "_internal_originalTime": 1689726073000
    },
    {
        "time": 1689726074000,
        "value": 138.97,
        "_internal_originalTime": 1689726074000
    },
    {
        "time": 1689726075000,
        "value": 138.971,
        "_internal_originalTime": 1689726075000
    },
    {
        "time": 1689726076000,
        "value": 138.97,
        "_internal_originalTime": 1689726076000
    },
    {
        "time": 1689726078000,
        "value": 138.97,
        "_internal_originalTime": 1689726078000
    },
    {
        "time": 1689726080000,
        "value": 138.97,
        "_internal_originalTime": 1689726080000
    },
    {
        "time": 1689726081000,
        "value": 138.97,
        "_internal_originalTime": 1689726081000
    },
    {
        "time": 1689726085000,
        "value": 138.968,
        "_internal_originalTime": 1689726085000
    },
    {
        "time": 1689726087000,
        "value": 138.981,
        "_internal_originalTime": 1689726087000
    },
    {
        "time": 1689726088000,
        "value": 138.979,
        "_internal_originalTime": 1689726088000
    },
    {
        "time": 1689726089000,
        "value": 138.982,
        "_internal_originalTime": 1689726089000
    },
    {
        "time": 1689726091000,
        "value": 138.98,
        "_internal_originalTime": 1689726091000
    },
    {
        "time": 1689726092000,
        "value": 138.976,
        "_internal_originalTime": 1689726092000
    },
    {
        "time": 1689726093000,
        "value": 138.974,
        "_internal_originalTime": 1689726093000
    },
    {
        "time": 1689726095000,
        "value": 138.974,
        "_internal_originalTime": 1689726095000
    },
    {
        "time": 1689726098000,
        "value": 138.973,
        "_internal_originalTime": 1689726098000
    },
    {
        "time": 1689726101000,
        "value": 138.979,
        "_internal_originalTime": 1689726101000
    },
    {
        "time": 1689726103000,
        "value": 138.976,
        "_internal_originalTime": 1689726103000
    },
    {
        "time": 1689726105000,
        "value": 138.977,
        "_internal_originalTime": 1689726105000
    },
    {
        "time": 1689726108000,
        "value": 138.995,
        "_internal_originalTime": 1689726108000
    },
    {
        "time": 1689726110000,
        "value": 138.99,
        "_internal_originalTime": 1689726110000
    },
    {
        "time": 1689726112000,
        "value": 138.989,
        "_internal_originalTime": 1689726112000
    },
    {
        "time": 1689726115000,
        "value": 138.993,
        "_internal_originalTime": 1689726115000
    },
    {
        "time": 1689726117000,
        "value": 138.993,
        "_internal_originalTime": 1689726117000
    },
    {
        "time": 1689726119000,
        "value": 138.996,
        "_internal_originalTime": 1689726119000
    },
    {
        "time": 1689726120000,
        "value": 139,
        "_internal_originalTime": 1689726120000
    },
    {
        "time": 1689726122000,
        "value": 138.996,
        "_internal_originalTime": 1689726122000
    },
    {
        "time": 1689726125000,
        "value": 139.002,
        "_internal_originalTime": 1689726125000
    },
    {
        "time": 1689726127000,
        "value": 139.005,
        "_internal_originalTime": 1689726127000
    },
    {
        "time": 1689726130000,
        "value": 139.028,
        "_internal_originalTime": 1689726130000
    },
    {
        "time": 1689726132000,
        "value": 139.028,
        "_internal_originalTime": 1689726132000
    },
    {
        "time": 1689726135000,
        "value": 139.023,
        "_internal_originalTime": 1689726135000
    },
    {
        "time": 1689726139000,
        "value": 139.009,
        "_internal_originalTime": 1689726139000
    },
    {
        "time": 1689726141000,
        "value": 139.001,
        "_internal_originalTime": 1689726141000
    },
    {
        "time": 1689726142000,
        "value": 139.005,
        "_internal_originalTime": 1689726142000
    },
    {
        "time": 1689726146000,
        "value": 138.989,
        "_internal_originalTime": 1689726146000
    },
    {
        "time": 1689726149000,
        "value": 138.989,
        "_internal_originalTime": 1689726149000
    },
    {
        "time": 1689726150000,
        "value": 138.99,
        "_internal_originalTime": 1689726150000
    },
    {
        "time": 1689726153000,
        "value": 138.973,
        "_internal_originalTime": 1689726153000
    },
    {
        "time": 1689726156000,
        "value": 138.968,
        "_internal_originalTime": 1689726156000
    },
    {
        "time": 1689726158000,
        "value": 138.959,
        "_internal_originalTime": 1689726158000
    },
    {
        "time": 1689726161000,
        "value": 138.96,
        "_internal_originalTime": 1689726161000
    },
    {
        "time": 1689726163000,
        "value": 138.958,
        "_internal_originalTime": 1689726163000
    },
    {
        "time": 1689726165000,
        "value": 138.958,
        "_internal_originalTime": 1689726165000
    },
    {
        "time": 1689726168000,
        "value": 138.96,
        "_internal_originalTime": 1689726168000
    },
    {
        "time": 1689726170000,
        "value": 138.953,
        "_internal_originalTime": 1689726170000
    },
    {
        "time": 1689726172000,
        "value": 138.956,
        "_internal_originalTime": 1689726172000
    },
    {
        "time": 1689726174000,
        "value": 138.953,
        "_internal_originalTime": 1689726174000
    },
    {
        "time": 1689726177000,
        "value": 138.954,
        "_internal_originalTime": 1689726177000
    },
    {
        "time": 1689726179000,
        "value": 138.959,
        "_internal_originalTime": 1689726179000
    },
    {
        "time": 1689726180000,
        "value": 138.959,
        "_internal_originalTime": 1689726180000
    },
    {
        "time": 1689726182000,
        "value": 138.961,
        "_internal_originalTime": 1689726182000
    },
    {
        "time": 1689726184000,
        "value": 138.959,
        "_internal_originalTime": 1689726184000
    },
    {
        "time": 1689726187000,
        "value": 138.96,
        "_internal_originalTime": 1689726187000
    },
    {
        "time": 1689726190000,
        "value": 138.954,
        "_internal_originalTime": 1689726190000
    },
    {
        "time": 1689726195000,
        "value": 138.942,
        "_internal_originalTime": 1689726195000
    },
    {
        "time": 1689726196000,
        "value": 138.944,
        "_internal_originalTime": 1689726196000
    },
    {
        "time": 1689726199000,
        "value": 138.948,
        "_internal_originalTime": 1689726199000
    },
    {
        "time": 1689726201000,
        "value": 138.95,
        "_internal_originalTime": 1689726201000
    },
    {
        "time": 1689726204000,
        "value": 138.951,
        "_internal_originalTime": 1689726204000
    },
    {
        "time": 1689726206000,
        "value": 138.952,
        "_internal_originalTime": 1689726206000
    },
    {
        "time": 1689726208000,
        "value": 138.953,
        "_internal_originalTime": 1689726208000
    },
    {
        "time": 1689726211000,
        "value": 138.952,
        "_internal_originalTime": 1689726211000
    },
    {
        "time": 1689726213000,
        "value": 138.952,
        "_internal_originalTime": 1689726213000
    },
    {
        "time": 1689726216000,
        "value": 138.954,
        "_internal_originalTime": 1689726216000
    },
    {
        "time": 1689726218000,
        "value": 138.951,
        "_internal_originalTime": 1689726218000
    },
    {
        "time": 1689726221000,
        "value": 138.95,
        "_internal_originalTime": 1689726221000
    },
    {
        "time": 1689726224000,
        "value": 138.952,
        "_internal_originalTime": 1689726224000
    },
    {
        "time": 1689726226000,
        "value": 138.953,
        "_internal_originalTime": 1689726226000
    },
    {
        "time": 1689726228000,
        "value": 138.951,
        "_internal_originalTime": 1689726228000
    },
    {
        "time": 1689726231000,
        "value": 138.953,
        "_internal_originalTime": 1689726231000
    },
    {
        "time": 1689726235000,
        "value": 138.963,
        "_internal_originalTime": 1689726235000
    },
    {
        "time": 1689726237000,
        "value": 138.962,
        "_internal_originalTime": 1689726237000
    },
    {
        "time": 1689726239000,
        "value": 138.963,
        "_internal_originalTime": 1689726239000
    },
    {
        "time": 1689726241000,
        "value": 138.94,
        "_internal_originalTime": 1689726241000
    },
    {
        "time": 1689726242000,
        "value": 138.965,
        "_internal_originalTime": 1689726242000
    },
    {
        "time": 1689726243000,
        "value": 138.963,
        "_internal_originalTime": 1689726243000
    },
    {
        "time": 1689726245000,
        "value": 138.962,
        "_internal_originalTime": 1689726245000
    },
    {
        "time": 1689726248000,
        "value": 138.946,
        "_internal_originalTime": 1689726248000
    },
    {
        "time": 1689726250000,
        "value": 138.951,
        "_internal_originalTime": 1689726250000
    },
    {
        "time": 1689726252000,
        "value": 138.95,
        "_internal_originalTime": 1689726252000
    },
    {
        "time": 1689726255000,
        "value": 138.957,
        "_internal_originalTime": 1689726255000
    },
    {
        "time": 1689726257000,
        "value": 138.963,
        "_internal_originalTime": 1689726257000
    },
    {
        "time": 1689726260000,
        "value": 138.968,
        "_internal_originalTime": 1689726260000
    },
    {
        "time": 1689726262000,
        "value": 138.969,
        "_internal_originalTime": 1689726262000
    },
    {
        "time": 1689726265000,
        "value": 138.97,
        "_internal_originalTime": 1689726265000
    },
    {
        "time": 1689726268000,
        "value": 138.973,
        "_internal_originalTime": 1689726268000
    },
    {
        "time": 1689726270000,
        "value": 138.97,
        "_internal_originalTime": 1689726270000
    },
    {
        "time": 1689726272000,
        "value": 138.978,
        "_internal_originalTime": 1689726272000
    },
    {
        "time": 1689726274000,
        "value": 138.98,
        "_internal_originalTime": 1689726274000
    },
    {
        "time": 1689726277000,
        "value": 138.978,
        "_internal_originalTime": 1689726277000
    },
    {
        "time": 1689726280000,
        "value": 138.979,
        "_internal_originalTime": 1689726280000
    },
    {
        "time": 1689726283000,
        "value": 138.985,
        "_internal_originalTime": 1689726283000
    },
    {
        "time": 1689726285000,
        "value": 138.984,
        "_internal_originalTime": 1689726285000
    },
    {
        "time": 1689726288000,
        "value": 138.978,
        "_internal_originalTime": 1689726288000
    },
    {
        "time": 1689726290000,
        "value": 138.975,
        "_internal_originalTime": 1689726290000
    },
    {
        "time": 1689726292000,
        "value": 138.973,
        "_internal_originalTime": 1689726292000
    },
    {
        "time": 1689726294000,
        "value": 138.974,
        "_internal_originalTime": 1689726294000
    },
    {
        "time": 1689726298000,
        "value": 138.976,
        "_internal_originalTime": 1689726298000
    },
    {
        "time": 1689726300000,
        "value": 138.975,
        "_internal_originalTime": 1689726300000
    },
    {
        "time": 1689726302000,
        "value": 138.978,
        "_internal_originalTime": 1689726302000
    },
    {
        "time": 1689726304000,
        "value": 138.978,
        "_internal_originalTime": 1689726304000
    },
    {
        "time": 1689726305000,
        "value": 138.977,
        "_internal_originalTime": 1689726305000
    },
    {
        "time": 1689726307000,
        "value": 138.978,
        "_internal_originalTime": 1689726307000
    },
    {
        "time": 1689726311000,
        "value": 138.978,
        "_internal_originalTime": 1689726311000
    },
    {
        "time": 1689726313000,
        "value": 138.977,
        "_internal_originalTime": 1689726313000
    },
    {
        "time": 1689726315000,
        "value": 138.978,
        "_internal_originalTime": 1689726315000
    },
    {
        "time": 1689726317000,
        "value": 138.981,
        "_internal_originalTime": 1689726317000
    },
    {
        "time": 1689726320000,
        "value": 138.979,
        "_internal_originalTime": 1689726320000
    },
    {
        "time": 1689726323000,
        "value": 138.98,
        "_internal_originalTime": 1689726323000
    },
    {
        "time": 1689726326000,
        "value": 138.974,
        "_internal_originalTime": 1689726326000
    },
    {
        "time": 1689726328000,
        "value": 138.973,
        "_internal_originalTime": 1689726328000
    },
    {
        "time": 1689726336000,
        "value": 138.973,
        "_internal_originalTime": 1689726336000
    },
    {
        "time": 1689726339000,
        "value": 138.973,
        "_internal_originalTime": 1689726339000
    },
    {
        "time": 1689726341000,
        "value": 138.973,
        "_internal_originalTime": 1689726341000
    },
    {
        "time": 1689726344000,
        "value": 138.972,
        "_internal_originalTime": 1689726344000
    },
    {
        "time": 1689726349000,
        "value": 138.972,
        "_internal_originalTime": 1689726349000
    },
    {
        "time": 1689726351000,
        "value": 138.972,
        "_internal_originalTime": 1689726351000
    },
    {
        "time": 1689726354000,
        "value": 138.973,
        "_internal_originalTime": 1689726354000
    },
    {
        "time": 1689726357000,
        "value": 138.974,
        "_internal_originalTime": 1689726357000
    },
    {
        "time": 1689726358000,
        "value": 138.972,
        "_internal_originalTime": 1689726358000
    },
    {
        "time": 1689726360000,
        "value": 138.95,
        "_internal_originalTime": 1689726360000
    },
    {
        "time": 1689726365000,
        "value": 138.974,
        "_internal_originalTime": 1689726365000
    },
    {
        "time": 1689726368000,
        "value": 138.959,
        "_internal_originalTime": 1689726368000
    },
    {
        "time": 1689726370000,
        "value": 138.964,
        "_internal_originalTime": 1689726370000
    },
    {
        "time": 1689726373000,
        "value": 138.963,
        "_internal_originalTime": 1689726373000
    },
    {
        "time": 1689726375000,
        "value": 138.955,
        "_internal_originalTime": 1689726375000
    },
    {
        "time": 1689726378000,
        "value": 138.972,
        "_internal_originalTime": 1689726378000
    },
    {
        "time": 1689726380000,
        "value": 138.974,
        "_internal_originalTime": 1689726380000
    },
    {
        "time": 1689726382000,
        "value": 138.976,
        "_internal_originalTime": 1689726382000
    },
    {
        "time": 1689726385000,
        "value": 138.97,
        "_internal_originalTime": 1689726385000
    },
    {
        "time": 1689726388000,
        "value": 138.973,
        "_internal_originalTime": 1689726388000
    },
    {
        "time": 1689726392000,
        "value": 138.976,
        "_internal_originalTime": 1689726392000
    },
    {
        "time": 1689726394000,
        "value": 138.974,
        "_internal_originalTime": 1689726394000
    },
    {
        "time": 1689726397000,
        "value": 138.973,
        "_internal_originalTime": 1689726397000
    },
    {
        "time": 1689726401000,
        "value": 138.98,
        "_internal_originalTime": 1689726401000
    },
    {
        "time": 1689726404000,
        "value": 138.974,
        "_internal_originalTime": 1689726404000
    },
    {
        "time": 1689726405000,
        "value": 138.987,
        "_internal_originalTime": 1689726405000
    },
    {
        "time": 1689726407000,
        "value": 138.988,
        "_internal_originalTime": 1689726407000
    },
    {
        "time": 1689726410000,
        "value": 138.992,
        "_internal_originalTime": 1689726410000
    },
    {
        "time": 1689726412000,
        "value": 138.992,
        "_internal_originalTime": 1689726412000
    },
    {
        "time": 1689726418000,
        "value": 138.982,
        "_internal_originalTime": 1689726418000
    },
    {
        "time": 1689726419000,
        "value": 138.982,
        "_internal_originalTime": 1689726419000
    },
    {
        "time": 1689726421000,
        "value": 138.97,
        "_internal_originalTime": 1689726421000
    },
    {
        "time": 1689726423000,
        "value": 138.983,
        "_internal_originalTime": 1689726423000
    },
    {
        "time": 1689726424000,
        "value": 138.982,
        "_internal_originalTime": 1689726424000
    },
    {
        "time": 1689726428000,
        "value": 139,
        "_internal_originalTime": 1689726428000
    },
    {
        "time": 1689726431000,
        "value": 139.01,
        "_internal_originalTime": 1689726431000
    },
    {
        "time": 1689726433000,
        "value": 139.008,
        "_internal_originalTime": 1689726433000
    },
    {
        "time": 1689726436000,
        "value": 139.017,
        "_internal_originalTime": 1689726436000
    },
    {
        "time": 1689726438000,
        "value": 139.013,
        "_internal_originalTime": 1689726438000
    },
    {
        "time": 1689726440000,
        "value": 139.009,
        "_internal_originalTime": 1689726440000
    },
    {
        "time": 1689726443000,
        "value": 139.009,
        "_internal_originalTime": 1689726443000
    },
    {
        "time": 1689726445000,
        "value": 139.01,
        "_internal_originalTime": 1689726445000
    },
    {
        "time": 1689726447000,
        "value": 139.012,
        "_internal_originalTime": 1689726447000
    },
    {
        "time": 1689726455000,
        "value": 139.008,
        "_internal_originalTime": 1689726455000
    },
    {
        "time": 1689726457000,
        "value": 139.009,
        "_internal_originalTime": 1689726457000
    },
    {
        "time": 1689726461000,
        "value": 139.001,
        "_internal_originalTime": 1689726461000
    },
    {
        "time": 1689726464000,
        "value": 139.002,
        "_internal_originalTime": 1689726464000
    },
    {
        "time": 1689726466000,
        "value": 139.004,
        "_internal_originalTime": 1689726466000
    },
    {
        "time": 1689726467000,
        "value": 138.992,
        "_internal_originalTime": 1689726467000
    },
    {
        "time": 1689726471000,
        "value": 138.994,
        "_internal_originalTime": 1689726471000
    },
    {
        "time": 1689726473000,
        "value": 138.99,
        "_internal_originalTime": 1689726473000
    },
    {
        "time": 1689726475000,
        "value": 138.99,
        "_internal_originalTime": 1689726475000
    },
    {
        "time": 1689726478000,
        "value": 138.99,
        "_internal_originalTime": 1689726478000
    },
    {
        "time": 1689726479000,
        "value": 138.993,
        "_internal_originalTime": 1689726479000
    },
    {
        "time": 1689726481000,
        "value": 138.991,
        "_internal_originalTime": 1689726481000
    },
    {
        "time": 1689726484000,
        "value": 138.992,
        "_internal_originalTime": 1689726484000
    },
    {
        "time": 1689726486000,
        "value": 139.004,
        "_internal_originalTime": 1689726486000
    },
    {
        "time": 1689726489000,
        "value": 138.999,
        "_internal_originalTime": 1689726489000
    },
    {
        "time": 1689726492000,
        "value": 139.001,
        "_internal_originalTime": 1689726492000
    },
    {
        "time": 1689726494000,
        "value": 139.001,
        "_internal_originalTime": 1689726494000
    },
    {
        "time": 1689726495000,
        "value": 138.998,
        "_internal_originalTime": 1689726495000
    },
    {
        "time": 1689726499000,
        "value": 139,
        "_internal_originalTime": 1689726499000
    },
    {
        "time": 1689726501000,
        "value": 139.008,
        "_internal_originalTime": 1689726501000
    },
    {
        "time": 1689726504000,
        "value": 139.001,
        "_internal_originalTime": 1689726504000
    },
    {
        "time": 1689726506000,
        "value": 139.002,
        "_internal_originalTime": 1689726506000
    },
    {
        "time": 1689726509000,
        "value": 139.002,
        "_internal_originalTime": 1689726509000
    },
    {
        "time": 1689726519000,
        "value": 138.999,
        "_internal_originalTime": 1689726519000
    },
    {
        "time": 1689726522000,
        "value": 138.99,
        "_internal_originalTime": 1689726522000
    },
    {
        "time": 1689726524000,
        "value": 138.983,
        "_internal_originalTime": 1689726524000
    },
    {
        "time": 1689726526000,
        "value": 138.983,
        "_internal_originalTime": 1689726526000
    },
    {
        "time": 1689726529000,
        "value": 138.983,
        "_internal_originalTime": 1689726529000
    },
    {
        "time": 1689726531000,
        "value": 138.979,
        "_internal_originalTime": 1689726531000
    },
    {
        "time": 1689726533000,
        "value": 138.982,
        "_internal_originalTime": 1689726533000
    },
    {
        "time": 1689726534000,
        "value": 138.981,
        "_internal_originalTime": 1689726534000
    },
    {
        "time": 1689726537000,
        "value": 138.972,
        "_internal_originalTime": 1689726537000
    },
    {
        "time": 1689726539000,
        "value": 138.966,
        "_internal_originalTime": 1689726539000
    },
    {
        "time": 1689726540000,
        "value": 138.971,
        "_internal_originalTime": 1689726540000
    },
    {
        "time": 1689726542000,
        "value": 138.972,
        "_internal_originalTime": 1689726542000
    },
    {
        "time": 1689726543000,
        "value": 138.973,
        "_internal_originalTime": 1689726543000
    },
    {
        "time": 1689726546000,
        "value": 138.971,
        "_internal_originalTime": 1689726546000
    },
    {
        "time": 1689726548000,
        "value": 138.968,
        "_internal_originalTime": 1689726548000
    },
    {
        "time": 1689726550000,
        "value": 138.97,
        "_internal_originalTime": 1689726550000
    },
    {
        "time": 1689726553000,
        "value": 138.968,
        "_internal_originalTime": 1689726553000
    },
    {
        "time": 1689726555000,
        "value": 138.962,
        "_internal_originalTime": 1689726555000
    },
    {
        "time": 1689726556000,
        "value": 138.963,
        "_internal_originalTime": 1689726556000
    },
    {
        "time": 1689726560000,
        "value": 138.949,
        "_internal_originalTime": 1689726560000
    },
    {
        "time": 1689726563000,
        "value": 138.954,
        "_internal_originalTime": 1689726563000
    },
    {
        "time": 1689726565000,
        "value": 138.955,
        "_internal_originalTime": 1689726565000
    },
    {
        "time": 1689726569000,
        "value": 138.952,
        "_internal_originalTime": 1689726569000
    },
    {
        "time": 1689726570000,
        "value": 138.95,
        "_internal_originalTime": 1689726570000
    },
    {
        "time": 1689726573000,
        "value": 138.955,
        "_internal_originalTime": 1689726573000
    },
    {
        "time": 1689726575000,
        "value": 138.953,
        "_internal_originalTime": 1689726575000
    },
    {
        "time": 1689726579000,
        "value": 138.95,
        "_internal_originalTime": 1689726579000
    },
    {
        "time": 1689726580000,
        "value": 138.951,
        "_internal_originalTime": 1689726580000
    },
    {
        "time": 1689726586000,
        "value": 138.951,
        "_internal_originalTime": 1689726586000
    },
    {
        "time": 1689726588000,
        "value": 138.951,
        "_internal_originalTime": 1689726588000
    },
    {
        "time": 1689726590000,
        "value": 138.952,
        "_internal_originalTime": 1689726590000
    },
    {
        "time": 1689726591000,
        "value": 138.951,
        "_internal_originalTime": 1689726591000
    },
    {
        "time": 1689726594000,
        "value": 138.946,
        "_internal_originalTime": 1689726594000
    },
    {
        "time": 1689726597000,
        "value": 138.944,
        "_internal_originalTime": 1689726597000
    },
    {
        "time": 1689726599000,
        "value": 138.952,
        "_internal_originalTime": 1689726599000
    },
    {
        "time": 1689726600000,
        "value": 138.949,
        "_internal_originalTime": 1689726600000
    },
    {
        "time": 1689726601000,
        "value": 138.95,
        "_internal_originalTime": 1689726601000
    },
    {
        "time": 1689726602000,
        "value": 138.951,
        "_internal_originalTime": 1689726602000
    },
    {
        "time": 1689726604000,
        "value": 138.951,
        "_internal_originalTime": 1689726604000
    },
    {
        "time": 1689726605000,
        "value": 138.95,
        "_internal_originalTime": 1689726605000
    },
    {
        "time": 1689726608000,
        "value": 138.952,
        "_internal_originalTime": 1689726608000
    },
    {
        "time": 1689726611000,
        "value": 138.952,
        "_internal_originalTime": 1689726611000
    },
    {
        "time": 1689726614000,
        "value": 138.953,
        "_internal_originalTime": 1689726614000
    },
    {
        "time": 1689726616000,
        "value": 138.949,
        "_internal_originalTime": 1689726616000
    },
    {
        "time": 1689726617000,
        "value": 138.948,
        "_internal_originalTime": 1689726617000
    },
    {
        "time": 1689726620000,
        "value": 138.949,
        "_internal_originalTime": 1689726620000
    },
    {
        "time": 1689726622000,
        "value": 138.958,
        "_internal_originalTime": 1689726622000
    },
    {
        "time": 1689726623000,
        "value": 138.961,
        "_internal_originalTime": 1689726623000
    },
    {
        "time": 1689726626000,
        "value": 138.96,
        "_internal_originalTime": 1689726626000
    },
    {
        "time": 1689726627000,
        "value": 138.96,
        "_internal_originalTime": 1689726627000
    },
    {
        "time": 1689726629000,
        "value": 138.959,
        "_internal_originalTime": 1689726629000
    },
    {
        "time": 1689726630000,
        "value": 138.959,
        "_internal_originalTime": 1689726630000
    },
    {
        "time": 1689726632000,
        "value": 138.96,
        "_internal_originalTime": 1689726632000
    },
    {
        "time": 1689726633000,
        "value": 138.953,
        "_internal_originalTime": 1689726633000
    },
    {
        "time": 1689726635000,
        "value": 138.954,
        "_internal_originalTime": 1689726635000
    },
    {
        "time": 1689726638000,
        "value": 138.951,
        "_internal_originalTime": 1689726638000
    },
    {
        "time": 1689726640000,
        "value": 138.962,
        "_internal_originalTime": 1689726640000
    },
    {
        "time": 1689726643000,
        "value": 138.973,
        "_internal_originalTime": 1689726643000
    },
    {
        "time": 1689726645000,
        "value": 138.971,
        "_internal_originalTime": 1689726645000
    },
    {
        "time": 1689726646000,
        "value": 138.968,
        "_internal_originalTime": 1689726646000
    },
    {
        "time": 1689726648000,
        "value": 138.969,
        "_internal_originalTime": 1689726648000
    },
    {
        "time": 1689726651000,
        "value": 138.979,
        "_internal_originalTime": 1689726651000
    },
    {
        "time": 1689726652000,
        "value": 138.979,
        "_internal_originalTime": 1689726652000
    },
    {
        "time": 1689726654000,
        "value": 138.982,
        "_internal_originalTime": 1689726654000
    },
    {
        "time": 1689726655000,
        "value": 138.982,
        "_internal_originalTime": 1689726655000
    },
    {
        "time": 1689726658000,
        "value": 138.989,
        "_internal_originalTime": 1689726658000
    },
    {
        "time": 1689726660000,
        "value": 138.984,
        "_internal_originalTime": 1689726660000
    },
    {
        "time": 1689726662000,
        "value": 138.982,
        "_internal_originalTime": 1689726662000
    },
    {
        "time": 1689726663000,
        "value": 138.981,
        "_internal_originalTime": 1689726663000
    },
    {
        "time": 1689726666000,
        "value": 138.98,
        "_internal_originalTime": 1689726666000
    },
    {
        "time": 1689726667000,
        "value": 138.965,
        "_internal_originalTime": 1689726667000
    },
    {
        "time": 1689726669000,
        "value": 138.965,
        "_internal_originalTime": 1689726669000
    },
    {
        "time": 1689726671000,
        "value": 138.972,
        "_internal_originalTime": 1689726671000
    },
    {
        "time": 1689726673000,
        "value": 138.96,
        "_internal_originalTime": 1689726673000
    },
    {
        "time": 1689726674000,
        "value": 138.962,
        "_internal_originalTime": 1689726674000
    },
    {
        "time": 1689726675000,
        "value": 138.964,
        "_internal_originalTime": 1689726675000
    },
    {
        "time": 1689726677000,
        "value": 138.963,
        "_internal_originalTime": 1689726677000
    },
    {
        "time": 1689726680000,
        "value": 138.962,
        "_internal_originalTime": 1689726680000
    },
    {
        "time": 1689726681000,
        "value": 138.96,
        "_internal_originalTime": 1689726681000
    },
    {
        "time": 1689726684000,
        "value": 138.96,
        "_internal_originalTime": 1689726684000
    },
    {
        "time": 1689726686000,
        "value": 138.963,
        "_internal_originalTime": 1689726686000
    },
    {
        "time": 1689726688000,
        "value": 138.962,
        "_internal_originalTime": 1689726688000
    },
    {
        "time": 1689726689000,
        "value": 138.963,
        "_internal_originalTime": 1689726689000
    },
    {
        "time": 1689726692000,
        "value": 138.962,
        "_internal_originalTime": 1689726692000
    },
    {
        "time": 1689726694000,
        "value": 138.966,
        "_internal_originalTime": 1689726694000
    },
    {
        "time": 1689726695000,
        "value": 138.969,
        "_internal_originalTime": 1689726695000
    },
    {
        "time": 1689726697000,
        "value": 138.969,
        "_internal_originalTime": 1689726697000
    },
    {
        "time": 1689726702000,
        "value": 138.971,
        "_internal_originalTime": 1689726702000
    },
    {
        "time": 1689726704000,
        "value": 138.975,
        "_internal_originalTime": 1689726704000
    },
    {
        "time": 1689726705000,
        "value": 138.974,
        "_internal_originalTime": 1689726705000
    },
    {
        "time": 1689726707000,
        "value": 138.969,
        "_internal_originalTime": 1689726707000
    },
    {
        "time": 1689726708000,
        "value": 138.973,
        "_internal_originalTime": 1689726708000
    },
    {
        "time": 1689726710000,
        "value": 138.974,
        "_internal_originalTime": 1689726710000
    },
    {
        "time": 1689726711000,
        "value": 138.968,
        "_internal_originalTime": 1689726711000
    },
    {
        "time": 1689726713000,
        "value": 138.959,
        "_internal_originalTime": 1689726713000
    },
    {
        "time": 1689726715000,
        "value": 138.96,
        "_internal_originalTime": 1689726715000
    },
    {
        "time": 1689726716000,
        "value": 138.961,
        "_internal_originalTime": 1689726716000
    },
    {
        "time": 1689726718000,
        "value": 138.962,
        "_internal_originalTime": 1689726718000
    },
    {
        "time": 1689726719000,
        "value": 138.959,
        "_internal_originalTime": 1689726719000
    },
    {
        "time": 1689726721000,
        "value": 138.94,
        "_internal_originalTime": 1689726721000
    },
    {
        "time": 1689726727000,
        "value": 138.958,
        "_internal_originalTime": 1689726727000
    },
    {
        "time": 1689726728000,
        "value": 138.96,
        "_internal_originalTime": 1689726728000
    },
    {
        "time": 1689726730000,
        "value": 138.952,
        "_internal_originalTime": 1689726730000
    },
    {
        "time": 1689726731000,
        "value": 138.951,
        "_internal_originalTime": 1689726731000
    },
    {
        "time": 1689726734000,
        "value": 138.952,
        "_internal_originalTime": 1689726734000
    },
    {
        "time": 1689726736000,
        "value": 138.947,
        "_internal_originalTime": 1689726736000
    },
    {
        "time": 1689726738000,
        "value": 138.944,
        "_internal_originalTime": 1689726738000
    },
    {
        "time": 1689726740000,
        "value": 138.926,
        "_internal_originalTime": 1689726740000
    },
    {
        "time": 1689726742000,
        "value": 138.915,
        "_internal_originalTime": 1689726742000
    },
    {
        "time": 1689726744000,
        "value": 138.904,
        "_internal_originalTime": 1689726744000
    },
    {
        "time": 1689726747000,
        "value": 138.912,
        "_internal_originalTime": 1689726747000
    },
    {
        "time": 1689726748000,
        "value": 138.915,
        "_internal_originalTime": 1689726748000
    },
    {
        "time": 1689726749000,
        "value": 138.914,
        "_internal_originalTime": 1689726749000
    },
    {
        "time": 1689726751000,
        "value": 138.912,
        "_internal_originalTime": 1689726751000
    },
    {
        "time": 1689726754000,
        "value": 138.916,
        "_internal_originalTime": 1689726754000
    },
    {
        "time": 1689726755000,
        "value": 138.913,
        "_internal_originalTime": 1689726755000
    },
    {
        "time": 1689726757000,
        "value": 138.921,
        "_internal_originalTime": 1689726757000
    },
    {
        "time": 1689726760000,
        "value": 138.921,
        "_internal_originalTime": 1689726760000
    },
    {
        "time": 1689726762000,
        "value": 138.912,
        "_internal_originalTime": 1689726762000
    },
    {
        "time": 1689726764000,
        "value": 138.914,
        "_internal_originalTime": 1689726764000
    },
    {
        "time": 1689726765000,
        "value": 138.915,
        "_internal_originalTime": 1689726765000
    },
    {
        "time": 1689726767000,
        "value": 138.915,
        "_internal_originalTime": 1689726767000
    },
    {
        "time": 1689726769000,
        "value": 138.925,
        "_internal_originalTime": 1689726769000
    },
    {
        "time": 1689726770000,
        "value": 138.926,
        "_internal_originalTime": 1689726770000
    },
    {
        "time": 1689726772000,
        "value": 138.923,
        "_internal_originalTime": 1689726772000
    },
    {
        "time": 1689726773000,
        "value": 138.922,
        "_internal_originalTime": 1689726773000
    },
    {
        "time": 1689726775000,
        "value": 138.928,
        "_internal_originalTime": 1689726775000
    },
    {
        "time": 1689726776000,
        "value": 138.927,
        "_internal_originalTime": 1689726776000
    },
    {
        "time": 1689726779000,
        "value": 138.953,
        "_internal_originalTime": 1689726779000
    },
    {
        "time": 1689726781000,
        "value": 138.952,
        "_internal_originalTime": 1689726781000
    },
    {
        "time": 1689726783000,
        "value": 138.959,
        "_internal_originalTime": 1689726783000
    },
    {
        "time": 1689726785000,
        "value": 138.975,
        "_internal_originalTime": 1689726785000
    },
    {
        "time": 1689726786000,
        "value": 138.978,
        "_internal_originalTime": 1689726786000
    },
    {
        "time": 1689726788000,
        "value": 138.975,
        "_internal_originalTime": 1689726788000
    },
    {
        "time": 1689726790000,
        "value": 138.972,
        "_internal_originalTime": 1689726790000
    },
    {
        "time": 1689726792000,
        "value": 138.966,
        "_internal_originalTime": 1689726792000
    },
    {
        "time": 1689726794000,
        "value": 138.961,
        "_internal_originalTime": 1689726794000
    },
    {
        "time": 1689726796000,
        "value": 138.963,
        "_internal_originalTime": 1689726796000
    },
    {
        "time": 1689726797000,
        "value": 138.961,
        "_internal_originalTime": 1689726797000
    },
    {
        "time": 1689726801000,
        "value": 138.962,
        "_internal_originalTime": 1689726801000
    },
    {
        "time": 1689726803000,
        "value": 138.961,
        "_internal_originalTime": 1689726803000
    },
    {
        "time": 1689726805000,
        "value": 138.962,
        "_internal_originalTime": 1689726805000
    },
    {
        "time": 1689726807000,
        "value": 138.962,
        "_internal_originalTime": 1689726807000
    },
    {
        "time": 1689726810000,
        "value": 138.963,
        "_internal_originalTime": 1689726810000
    },
    {
        "time": 1689726820000,
        "value": 138.961,
        "_internal_originalTime": 1689726820000
    },
    {
        "time": 1689726823000,
        "value": 138.963,
        "_internal_originalTime": 1689726823000
    },
    {
        "time": 1689726825000,
        "value": 138.963,
        "_internal_originalTime": 1689726825000
    },
    {
        "time": 1689726826000,
        "value": 138.958,
        "_internal_originalTime": 1689726826000
    },
    {
        "time": 1689726828000,
        "value": 138.958,
        "_internal_originalTime": 1689726828000
    },
    {
        "time": 1689726830000,
        "value": 138.962,
        "_internal_originalTime": 1689726830000
    },
    {
        "time": 1689726832000,
        "value": 138.96,
        "_internal_originalTime": 1689726832000
    },
    {
        "time": 1689726834000,
        "value": 138.944,
        "_internal_originalTime": 1689726834000
    },
    {
        "time": 1689726836000,
        "value": 138.953,
        "_internal_originalTime": 1689726836000
    },
    {
        "time": 1689726838000,
        "value": 138.952,
        "_internal_originalTime": 1689726838000
    },
    {
        "time": 1689726839000,
        "value": 138.95,
        "_internal_originalTime": 1689726839000
    },
    {
        "time": 1689726840000,
        "value": 138.948,
        "_internal_originalTime": 1689726840000
    },
    {
        "time": 1689726841000,
        "value": 138.945,
        "_internal_originalTime": 1689726841000
    },
    {
        "time": 1689726843000,
        "value": 138.946,
        "_internal_originalTime": 1689726843000
    },
    {
        "time": 1689726844000,
        "value": 138.942,
        "_internal_originalTime": 1689726844000
    },
    {
        "time": 1689726847000,
        "value": 138.943,
        "_internal_originalTime": 1689726847000
    },
    {
        "time": 1689726848000,
        "value": 138.942,
        "_internal_originalTime": 1689726848000
    },
    {
        "time": 1689726850000,
        "value": 138.933,
        "_internal_originalTime": 1689726850000
    },
    {
        "time": 1689726851000,
        "value": 138.924,
        "_internal_originalTime": 1689726851000
    },
    {
        "time": 1689726853000,
        "value": 138.931,
        "_internal_originalTime": 1689726853000
    },
    {
        "time": 1689726854000,
        "value": 138.932,
        "_internal_originalTime": 1689726854000
    },
    {
        "time": 1689726856000,
        "value": 138.932,
        "_internal_originalTime": 1689726856000
    },
    {
        "time": 1689726857000,
        "value": 138.931,
        "_internal_originalTime": 1689726857000
    },
    {
        "time": 1689726859000,
        "value": 138.932,
        "_internal_originalTime": 1689726859000
    },
    {
        "time": 1689726861000,
        "value": 138.927,
        "_internal_originalTime": 1689726861000
    },
    {
        "time": 1689726863000,
        "value": 138.918,
        "_internal_originalTime": 1689726863000
    },
    {
        "time": 1689726865000,
        "value": 138.906,
        "_internal_originalTime": 1689726865000
    },
    {
        "time": 1689726867000,
        "value": 138.905,
        "_internal_originalTime": 1689726867000
    },
    {
        "time": 1689726869000,
        "value": 138.903,
        "_internal_originalTime": 1689726869000
    },
    {
        "time": 1689726870000,
        "value": 138.907,
        "_internal_originalTime": 1689726870000
    },
    {
        "time": 1689726871000,
        "value": 138.906,
        "_internal_originalTime": 1689726871000
    },
    {
        "time": 1689726872000,
        "value": 138.908,
        "_internal_originalTime": 1689726872000
    },
    {
        "time": 1689726874000,
        "value": 138.906,
        "_internal_originalTime": 1689726874000
    },
    {
        "time": 1689726875000,
        "value": 138.898,
        "_internal_originalTime": 1689726875000
    },
    {
        "time": 1689726877000,
        "value": 138.892,
        "_internal_originalTime": 1689726877000
    },
    {
        "time": 1689726879000,
        "value": 138.893,
        "_internal_originalTime": 1689726879000
    },
    {
        "time": 1689726882000,
        "value": 138.894,
        "_internal_originalTime": 1689726882000
    },
    {
        "time": 1689726884000,
        "value": 138.898,
        "_internal_originalTime": 1689726884000
    },
    {
        "time": 1689726885000,
        "value": 138.897,
        "_internal_originalTime": 1689726885000
    },
    {
        "time": 1689726887000,
        "value": 138.901,
        "_internal_originalTime": 1689726887000
    },
    {
        "time": 1689726888000,
        "value": 138.903,
        "_internal_originalTime": 1689726888000
    },
    {
        "time": 1689726890000,
        "value": 138.9,
        "_internal_originalTime": 1689726890000
    },
    {
        "time": 1689726891000,
        "value": 138.898,
        "_internal_originalTime": 1689726891000
    },
    {
        "time": 1689726892000,
        "value": 138.898,
        "_internal_originalTime": 1689726892000
    },
    {
        "time": 1689726895000,
        "value": 138.901,
        "_internal_originalTime": 1689726895000
    },
    {
        "time": 1689726896000,
        "value": 138.9,
        "_internal_originalTime": 1689726896000
    },
    {
        "time": 1689726898000,
        "value": 138.895,
        "_internal_originalTime": 1689726898000
    },
    {
        "time": 1689726900000,
        "value": 138.901,
        "_internal_originalTime": 1689726900000
    },
    {
        "time": 1689726901000,
        "value": 138.903,
        "_internal_originalTime": 1689726901000
    },
    {
        "time": 1689726903000,
        "value": 138.903,
        "_internal_originalTime": 1689726903000
    },
    {
        "time": 1689726905000,
        "value": 138.897,
        "_internal_originalTime": 1689726905000
    },
    {
        "time": 1689726906000,
        "value": 138.896,
        "_internal_originalTime": 1689726906000
    },
    {
        "time": 1689726908000,
        "value": 138.896,
        "_internal_originalTime": 1689726908000
    },
    {
        "time": 1689726910000,
        "value": 138.898,
        "_internal_originalTime": 1689726910000
    },
    {
        "time": 1689726912000,
        "value": 138.899,
        "_internal_originalTime": 1689726912000
    },
    {
        "time": 1689726914000,
        "value": 138.898,
        "_internal_originalTime": 1689726914000
    },
    {
        "time": 1689726915000,
        "value": 138.9,
        "_internal_originalTime": 1689726915000
    },
    {
        "time": 1689726917000,
        "value": 138.916,
        "_internal_originalTime": 1689726917000
    },
    {
        "time": 1689726919000,
        "value": 138.915,
        "_internal_originalTime": 1689726919000
    },
    {
        "time": 1689726921000,
        "value": 138.923,
        "_internal_originalTime": 1689726921000
    },
    {
        "time": 1689726923000,
        "value": 138.923,
        "_internal_originalTime": 1689726923000
    },
    {
        "time": 1689726925000,
        "value": 138.922,
        "_internal_originalTime": 1689726925000
    },
    {
        "time": 1689726927000,
        "value": 138.918,
        "_internal_originalTime": 1689726927000
    },
    {
        "time": 1689726928000,
        "value": 138.915,
        "_internal_originalTime": 1689726928000
    },
    {
        "time": 1689726929000,
        "value": 138.916,
        "_internal_originalTime": 1689726929000
    },
    {
        "time": 1689726933000,
        "value": 138.913,
        "_internal_originalTime": 1689726933000
    },
    {
        "time": 1689726934000,
        "value": 138.905,
        "_internal_originalTime": 1689726934000
    },
    {
        "time": 1689726936000,
        "value": 138.904,
        "_internal_originalTime": 1689726936000
    },
    {
        "time": 1689726938000,
        "value": 138.905,
        "_internal_originalTime": 1689726938000
    },
    {
        "time": 1689726940000,
        "value": 138.907,
        "_internal_originalTime": 1689726940000
    },
    {
        "time": 1689726943000,
        "value": 138.894,
        "_internal_originalTime": 1689726943000
    },
    {
        "time": 1689726945000,
        "value": 138.895,
        "_internal_originalTime": 1689726945000
    },
    {
        "time": 1689726946000,
        "value": 138.898,
        "_internal_originalTime": 1689726946000
    },
    {
        "time": 1689726948000,
        "value": 138.896,
        "_internal_originalTime": 1689726948000
    },
    {
        "time": 1689726950000,
        "value": 138.894,
        "_internal_originalTime": 1689726950000
    },
    {
        "time": 1689726952000,
        "value": 138.894,
        "_internal_originalTime": 1689726952000
    },
    {
        "time": 1689726953000,
        "value": 138.898,
        "_internal_originalTime": 1689726953000
    },
    {
        "time": 1689726955000,
        "value": 138.895,
        "_internal_originalTime": 1689726955000
    },
    {
        "time": 1689726957000,
        "value": 138.888,
        "_internal_originalTime": 1689726957000
    },
    {
        "time": 1689726959000,
        "value": 138.875,
        "_internal_originalTime": 1689726959000
    },
    {
        "time": 1689726960000,
        "value": 138.864,
        "_internal_originalTime": 1689726960000
    },
    {
        "time": 1689726961000,
        "value": 138.864,
        "_internal_originalTime": 1689726961000
    },
    {
        "time": 1689726963000,
        "value": 138.864,
        "_internal_originalTime": 1689726963000
    },
    {
        "time": 1689726965000,
        "value": 138.875,
        "_internal_originalTime": 1689726965000
    },
    {
        "time": 1689726967000,
        "value": 138.88,
        "_internal_originalTime": 1689726967000
    },
    {
        "time": 1689726969000,
        "value": 138.881,
        "_internal_originalTime": 1689726969000
    },
    {
        "time": 1689726970000,
        "value": 138.874,
        "_internal_originalTime": 1689726970000
    },
    {
        "time": 1689726972000,
        "value": 138.876,
        "_internal_originalTime": 1689726972000
    },
    {
        "time": 1689726974000,
        "value": 138.88,
        "_internal_originalTime": 1689726974000
    },
    {
        "time": 1689726976000,
        "value": 138.881,
        "_internal_originalTime": 1689726976000
    },
    {
        "time": 1689726977000,
        "value": 138.878,
        "_internal_originalTime": 1689726977000
    },
    {
        "time": 1689726980000,
        "value": 138.888,
        "_internal_originalTime": 1689726980000
    },
    {
        "time": 1689726981000,
        "value": 138.887,
        "_internal_originalTime": 1689726981000
    },
    {
        "time": 1689726984000,
        "value": 138.885,
        "_internal_originalTime": 1689726984000
    },
    {
        "time": 1689726985000,
        "value": 138.886,
        "_internal_originalTime": 1689726985000
    },
    {
        "time": 1689726987000,
        "value": 138.884,
        "_internal_originalTime": 1689726987000
    },
    {
        "time": 1689726989000,
        "value": 138.884,
        "_internal_originalTime": 1689726989000
    },
    {
        "time": 1689726990000,
        "value": 138.875,
        "_internal_originalTime": 1689726990000
    },
    {
        "time": 1689726991000,
        "value": 138.867,
        "_internal_originalTime": 1689726991000
    },
    {
        "time": 1689726993000,
        "value": 138.876,
        "_internal_originalTime": 1689726993000
    },
    {
        "time": 1689726994000,
        "value": 138.874,
        "_internal_originalTime": 1689726994000
    },
    {
        "time": 1689726997000,
        "value": 138.886,
        "_internal_originalTime": 1689726997000
    },
    {
        "time": 1689726998000,
        "value": 138.895,
        "_internal_originalTime": 1689726998000
    },
    {
        "time": 1689727000000,
        "value": 138.894,
        "_internal_originalTime": 1689727000000
    },
    {
        "time": 1689727002000,
        "value": 138.895,
        "_internal_originalTime": 1689727002000
    },
    {
        "time": 1689727004000,
        "value": 138.895,
        "_internal_originalTime": 1689727004000
    },
    {
        "time": 1689727006000,
        "value": 138.895,
        "_internal_originalTime": 1689727006000
    },
    {
        "time": 1689727007000,
        "value": 138.895,
        "_internal_originalTime": 1689727007000
    },
    {
        "time": 1689727009000,
        "value": 138.894,
        "_internal_originalTime": 1689727009000
    },
    {
        "time": 1689727011000,
        "value": 138.897,
        "_internal_originalTime": 1689727011000
    },
    {
        "time": 1689727013000,
        "value": 138.894,
        "_internal_originalTime": 1689727013000
    },
    {
        "time": 1689727014000,
        "value": 138.894,
        "_internal_originalTime": 1689727014000
    },
    {
        "time": 1689727016000,
        "value": 138.896,
        "_internal_originalTime": 1689727016000
    },
    {
        "time": 1689727020000,
        "value": 138.895,
        "_internal_originalTime": 1689727020000
    },
    {
        "time": 1689727022000,
        "value": 138.89,
        "_internal_originalTime": 1689727022000
    },
    {
        "time": 1689727023000,
        "value": 138.887,
        "_internal_originalTime": 1689727023000
    },
    {
        "time": 1689727025000,
        "value": 138.886,
        "_internal_originalTime": 1689727025000
    },
    {
        "time": 1689727027000,
        "value": 138.885,
        "_internal_originalTime": 1689727027000
    },
    {
        "time": 1689727029000,
        "value": 138.883,
        "_internal_originalTime": 1689727029000
    },
    {
        "time": 1689727033000,
        "value": 138.884,
        "_internal_originalTime": 1689727033000
    },
    {
        "time": 1689727034000,
        "value": 138.883,
        "_internal_originalTime": 1689727034000
    },
    {
        "time": 1689727036000,
        "value": 138.887,
        "_internal_originalTime": 1689727036000
    },
    {
        "time": 1689727038000,
        "value": 138.886,
        "_internal_originalTime": 1689727038000
    },
    {
        "time": 1689727039000,
        "value": 138.886,
        "_internal_originalTime": 1689727039000
    },
    {
        "time": 1689727041000,
        "value": 138.884,
        "_internal_originalTime": 1689727041000
    },
    {
        "time": 1689727043000,
        "value": 138.874,
        "_internal_originalTime": 1689727043000
    },
    {
        "time": 1689727046000,
        "value": 138.878,
        "_internal_originalTime": 1689727046000
    },
    {
        "time": 1689727047000,
        "value": 138.876,
        "_internal_originalTime": 1689727047000
    },
    {
        "time": 1689727048000,
        "value": 138.877,
        "_internal_originalTime": 1689727048000
    },
    {
        "time": 1689727049000,
        "value": 138.876,
        "_internal_originalTime": 1689727049000
    },
    {
        "time": 1689727051000,
        "value": 138.876,
        "_internal_originalTime": 1689727051000
    },
    {
        "time": 1689727053000,
        "value": 138.873,
        "_internal_originalTime": 1689727053000
    },
    {
        "time": 1689727055000,
        "value": 138.876,
        "_internal_originalTime": 1689727055000
    },
    {
        "time": 1689727057000,
        "value": 138.875,
        "_internal_originalTime": 1689727057000
    },
    {
        "time": 1689727059000,
        "value": 138.876,
        "_internal_originalTime": 1689727059000
    },
    {
        "time": 1689727061000,
        "value": 138.878,
        "_internal_originalTime": 1689727061000
    },
    {
        "time": 1689727064000,
        "value": 138.878,
        "_internal_originalTime": 1689727064000
    },
    {
        "time": 1689727065000,
        "value": 138.878,
        "_internal_originalTime": 1689727065000
    },
    {
        "time": 1689727067000,
        "value": 138.878,
        "_internal_originalTime": 1689727067000
    },
    {
        "time": 1689727068000,
        "value": 138.877,
        "_internal_originalTime": 1689727068000
    },
    {
        "time": 1689727070000,
        "value": 138.888,
        "_internal_originalTime": 1689727070000
    },
    {
        "time": 1689727072000,
        "value": 138.886,
        "_internal_originalTime": 1689727072000
    },
    {
        "time": 1689727074000,
        "value": 138.886,
        "_internal_originalTime": 1689727074000
    },
    {
        "time": 1689727076000,
        "value": 138.884,
        "_internal_originalTime": 1689727076000
    },
    {
        "time": 1689727078000,
        "value": 138.886,
        "_internal_originalTime": 1689727078000
    },
    {
        "time": 1689727079000,
        "value": 138.884,
        "_internal_originalTime": 1689727079000
    },
    {
        "time": 1689727081000,
        "value": 138.887,
        "_internal_originalTime": 1689727081000
    },
    {
        "time": 1689727082000,
        "value": 138.885,
        "_internal_originalTime": 1689727082000
    },
    {
        "time": 1689727084000,
        "value": 138.885,
        "_internal_originalTime": 1689727084000
    },
    {
        "time": 1689727087000,
        "value": 138.884,
        "_internal_originalTime": 1689727087000
    },
    {
        "time": 1689727088000,
        "value": 138.886,
        "_internal_originalTime": 1689727088000
    },
    {
        "time": 1689727090000,
        "value": 138.885,
        "_internal_originalTime": 1689727090000
    },
    {
        "time": 1689727091000,
        "value": 138.885,
        "_internal_originalTime": 1689727091000
    },
    {
        "time": 1689727094000,
        "value": 138.886,
        "_internal_originalTime": 1689727094000
    },
    {
        "time": 1689727096000,
        "value": 138.895,
        "_internal_originalTime": 1689727096000
    },
    {
        "time": 1689727097000,
        "value": 138.894,
        "_internal_originalTime": 1689727097000
    },
    {
        "time": 1689727099000,
        "value": 138.892,
        "_internal_originalTime": 1689727099000
    },
    {
        "time": 1689727102000,
        "value": 138.893,
        "_internal_originalTime": 1689727102000
    },
    {
        "time": 1689727103000,
        "value": 138.895,
        "_internal_originalTime": 1689727103000
    },
    {
        "time": 1689727105000,
        "value": 138.893,
        "_internal_originalTime": 1689727105000
    },
    {
        "time": 1689727108000,
        "value": 138.897,
        "_internal_originalTime": 1689727108000
    },
    {
        "time": 1689727109000,
        "value": 138.895,
        "_internal_originalTime": 1689727109000
    },
    {
        "time": 1689727111000,
        "value": 138.894,
        "_internal_originalTime": 1689727111000
    },
    {
        "time": 1689727113000,
        "value": 138.89,
        "_internal_originalTime": 1689727113000
    },
    {
        "time": 1689727114000,
        "value": 138.887,
        "_internal_originalTime": 1689727114000
    },
    {
        "time": 1689727115000,
        "value": 138.884,
        "_internal_originalTime": 1689727115000
    },
    {
        "time": 1689727118000,
        "value": 138.886,
        "_internal_originalTime": 1689727118000
    },
    {
        "time": 1689727119000,
        "value": 138.884,
        "_internal_originalTime": 1689727119000
    },
    {
        "time": 1689727122000,
        "value": 138.887,
        "_internal_originalTime": 1689727122000
    },
    {
        "time": 1689727124000,
        "value": 138.887,
        "_internal_originalTime": 1689727124000
    },
    {
        "time": 1689727125000,
        "value": 138.885,
        "_internal_originalTime": 1689727125000
    },
    {
        "time": 1689727126000,
        "value": 138.884,
        "_internal_originalTime": 1689727126000
    },
    {
        "time": 1689727129000,
        "value": 138.887,
        "_internal_originalTime": 1689727129000
    },
    {
        "time": 1689727131000,
        "value": 138.885,
        "_internal_originalTime": 1689727131000
    },
    {
        "time": 1689727133000,
        "value": 138.885,
        "_internal_originalTime": 1689727133000
    },
    {
        "time": 1689727134000,
        "value": 138.889,
        "_internal_originalTime": 1689727134000
    },
    {
        "time": 1689727136000,
        "value": 138.876,
        "_internal_originalTime": 1689727136000
    },
    {
        "time": 1689727138000,
        "value": 138.872,
        "_internal_originalTime": 1689727138000
    },
    {
        "time": 1689727141000,
        "value": 138.875,
        "_internal_originalTime": 1689727141000
    },
    {
        "time": 1689727142000,
        "value": 138.874,
        "_internal_originalTime": 1689727142000
    },
    {
        "time": 1689727144000,
        "value": 138.868,
        "_internal_originalTime": 1689727144000
    },
    {
        "time": 1689727145000,
        "value": 138.869,
        "_internal_originalTime": 1689727145000
    },
    {
        "time": 1689727148000,
        "value": 138.869,
        "_internal_originalTime": 1689727148000
    },
    {
        "time": 1689727150000,
        "value": 138.872,
        "_internal_originalTime": 1689727150000
    },
    {
        "time": 1689727152000,
        "value": 138.874,
        "_internal_originalTime": 1689727152000
    },
    {
        "time": 1689727153000,
        "value": 138.872,
        "_internal_originalTime": 1689727153000
    },
    {
        "time": 1689727155000,
        "value": 138.871,
        "_internal_originalTime": 1689727155000
    },
    {
        "time": 1689727157000,
        "value": 138.874,
        "_internal_originalTime": 1689727157000
    },
    {
        "time": 1689727158000,
        "value": 138.878,
        "_internal_originalTime": 1689727158000
    },
    {
        "time": 1689727160000,
        "value": 138.888,
        "_internal_originalTime": 1689727160000
    },
    {
        "time": 1689727162000,
        "value": 138.884,
        "_internal_originalTime": 1689727162000
    },
    {
        "time": 1689727163000,
        "value": 138.887,
        "_internal_originalTime": 1689727163000
    },
    {
        "time": 1689727165000,
        "value": 138.886,
        "_internal_originalTime": 1689727165000
    },
    {
        "time": 1689727166000,
        "value": 138.887,
        "_internal_originalTime": 1689727166000
    },
    {
        "time": 1689727167000,
        "value": 138.889,
        "_internal_originalTime": 1689727167000
    },
    {
        "time": 1689727169000,
        "value": 138.89,
        "_internal_originalTime": 1689727169000
    },
    {
        "time": 1689727170000,
        "value": 138.887,
        "_internal_originalTime": 1689727170000
    },
    {
        "time": 1689727171000,
        "value": 138.887,
        "_internal_originalTime": 1689727171000
    },
    {
        "time": 1689727174000,
        "value": 138.885,
        "_internal_originalTime": 1689727174000
    },
    {
        "time": 1689727175000,
        "value": 138.885,
        "_internal_originalTime": 1689727175000
    },
    {
        "time": 1689727176000,
        "value": 138.887,
        "_internal_originalTime": 1689727176000
    },
    {
        "time": 1689727179000,
        "value": 138.884,
        "_internal_originalTime": 1689727179000
    },
    {
        "time": 1689727180000,
        "value": 138.887,
        "_internal_originalTime": 1689727180000
    },
    {
        "time": 1689727183000,
        "value": 138.888,
        "_internal_originalTime": 1689727183000
    },
    {
        "time": 1689727184000,
        "value": 138.89,
        "_internal_originalTime": 1689727184000
    },
    {
        "time": 1689727186000,
        "value": 138.887,
        "_internal_originalTime": 1689727186000
    },
    {
        "time": 1689727188000,
        "value": 138.892,
        "_internal_originalTime": 1689727188000
    },
    {
        "time": 1689727190000,
        "value": 138.894,
        "_internal_originalTime": 1689727190000
    },
    {
        "time": 1689727191000,
        "value": 138.89,
        "_internal_originalTime": 1689727191000
    },
    {
        "time": 1689727193000,
        "value": 138.897,
        "_internal_originalTime": 1689727193000
    },
    {
        "time": 1689727195000,
        "value": 138.888,
        "_internal_originalTime": 1689727195000
    },
    {
        "time": 1689727197000,
        "value": 138.888,
        "_internal_originalTime": 1689727197000
    },
    {
        "time": 1689727199000,
        "value": 138.886,
        "_internal_originalTime": 1689727199000
    },
    {
        "time": 1689727200000,
        "value": 138.884,
        "_internal_originalTime": 1689727200000
    },
    {
        "time": 1689727202000,
        "value": 138.898,
        "_internal_originalTime": 1689727202000
    },
    {
        "time": 1689727204000,
        "value": 138.902,
        "_internal_originalTime": 1689727204000
    },
    {
        "time": 1689727206000,
        "value": 138.905,
        "_internal_originalTime": 1689727206000
    },
    {
        "time": 1689727208000,
        "value": 138.897,
        "_internal_originalTime": 1689727208000
    },
    {
        "time": 1689727209000,
        "value": 138.898,
        "_internal_originalTime": 1689727209000
    },
    {
        "time": 1689727211000,
        "value": 138.895,
        "_internal_originalTime": 1689727211000
    },
    {
        "time": 1689727213000,
        "value": 138.899,
        "_internal_originalTime": 1689727213000
    },
    {
        "time": 1689727216000,
        "value": 138.896,
        "_internal_originalTime": 1689727216000
    },
    {
        "time": 1689727218000,
        "value": 138.895,
        "_internal_originalTime": 1689727218000
    },
    {
        "time": 1689727220000,
        "value": 138.897,
        "_internal_originalTime": 1689727220000
    },
    {
        "time": 1689727221000,
        "value": 138.898,
        "_internal_originalTime": 1689727221000
    },
    {
        "time": 1689727223000,
        "value": 138.895,
        "_internal_originalTime": 1689727223000
    },
    {
        "time": 1689727226000,
        "value": 138.898,
        "_internal_originalTime": 1689727226000
    },
    {
        "time": 1689727228000,
        "value": 138.898,
        "_internal_originalTime": 1689727228000
    },
    {
        "time": 1689727230000,
        "value": 138.895,
        "_internal_originalTime": 1689727230000
    },
    {
        "time": 1689727231000,
        "value": 138.894,
        "_internal_originalTime": 1689727231000
    },
    {
        "time": 1689727232000,
        "value": 138.893,
        "_internal_originalTime": 1689727232000
    },
    {
        "time": 1689727235000,
        "value": 138.896,
        "_internal_originalTime": 1689727235000
    },
    {
        "time": 1689727237000,
        "value": 138.894,
        "_internal_originalTime": 1689727237000
    },
    {
        "time": 1689727239000,
        "value": 138.894,
        "_internal_originalTime": 1689727239000
    },
    {
        "time": 1689727241000,
        "value": 138.894,
        "_internal_originalTime": 1689727241000
    },
    {
        "time": 1689727244000,
        "value": 138.898,
        "_internal_originalTime": 1689727244000
    },
    {
        "time": 1689727245000,
        "value": 138.896,
        "_internal_originalTime": 1689727245000
    },
    {
        "time": 1689727247000,
        "value": 138.894,
        "_internal_originalTime": 1689727247000
    },
    {
        "time": 1689727249000,
        "value": 138.897,
        "_internal_originalTime": 1689727249000
    },
    {
        "time": 1689727251000,
        "value": 138.896,
        "_internal_originalTime": 1689727251000
    },
    {
        "time": 1689727252000,
        "value": 138.906,
        "_internal_originalTime": 1689727252000
    },
    {
        "time": 1689727254000,
        "value": 138.894,
        "_internal_originalTime": 1689727254000
    },
    {
        "time": 1689727255000,
        "value": 138.896,
        "_internal_originalTime": 1689727255000
    },
    {
        "time": 1689727257000,
        "value": 138.895,
        "_internal_originalTime": 1689727257000
    },
    {
        "time": 1689727261000,
        "value": 138.898,
        "_internal_originalTime": 1689727261000
    },
    {
        "time": 1689727262000,
        "value": 138.894,
        "_internal_originalTime": 1689727262000
    },
    {
        "time": 1689727264000,
        "value": 138.895,
        "_internal_originalTime": 1689727264000
    },
    {
        "time": 1689727265000,
        "value": 138.895,
        "_internal_originalTime": 1689727265000
    },
    {
        "time": 1689727268000,
        "value": 138.894,
        "_internal_originalTime": 1689727268000
    },
    {
        "time": 1689727270000,
        "value": 138.894,
        "_internal_originalTime": 1689727270000
    },
    {
        "time": 1689727271000,
        "value": 138.895,
        "_internal_originalTime": 1689727271000
    },
    {
        "time": 1689727274000,
        "value": 138.897,
        "_internal_originalTime": 1689727274000
    },
    {
        "time": 1689727275000,
        "value": 138.906,
        "_internal_originalTime": 1689727275000
    },
    {
        "time": 1689727276000,
        "value": 138.897,
        "_internal_originalTime": 1689727276000
    },
    {
        "time": 1689727278000,
        "value": 138.897,
        "_internal_originalTime": 1689727278000
    },
    {
        "time": 1689727280000,
        "value": 138.899,
        "_internal_originalTime": 1689727280000
    },
    {
        "time": 1689727282000,
        "value": 138.896,
        "_internal_originalTime": 1689727282000
    },
    {
        "time": 1689727284000,
        "value": 138.91,
        "_internal_originalTime": 1689727284000
    },
    {
        "time": 1689727286000,
        "value": 138.906,
        "_internal_originalTime": 1689727286000
    },
    {
        "time": 1689727288000,
        "value": 138.908,
        "_internal_originalTime": 1689727288000
    },
    {
        "time": 1689727289000,
        "value": 138.907,
        "_internal_originalTime": 1689727289000
    },
    {
        "time": 1689727290000,
        "value": 138.908,
        "_internal_originalTime": 1689727290000
    },
    {
        "time": 1689727291000,
        "value": 138.909,
        "_internal_originalTime": 1689727291000
    },
    {
        "time": 1689727294000,
        "value": 138.899,
        "_internal_originalTime": 1689727294000
    },
    {
        "time": 1689727296000,
        "value": 138.897,
        "_internal_originalTime": 1689727296000
    },
    {
        "time": 1689727299000,
        "value": 138.898,
        "_internal_originalTime": 1689727299000
    },
    {
        "time": 1689727302000,
        "value": 138.899,
        "_internal_originalTime": 1689727302000
    },
    {
        "time": 1689727304000,
        "value": 138.898,
        "_internal_originalTime": 1689727304000
    },
    {
        "time": 1689727307000,
        "value": 138.877,
        "_internal_originalTime": 1689727307000
    },
    {
        "time": 1689727308000,
        "value": 138.876,
        "_internal_originalTime": 1689727308000
    },
    {
        "time": 1689727310000,
        "value": 138.876,
        "_internal_originalTime": 1689727310000
    },
    {
        "time": 1689727312000,
        "value": 138.878,
        "_internal_originalTime": 1689727312000
    },
    {
        "time": 1689727314000,
        "value": 138.877,
        "_internal_originalTime": 1689727314000
    },
    {
        "time": 1689727315000,
        "value": 138.876,
        "_internal_originalTime": 1689727315000
    },
    {
        "time": 1689727318000,
        "value": 138.875,
        "_internal_originalTime": 1689727318000
    },
    {
        "time": 1689727319000,
        "value": 138.874,
        "_internal_originalTime": 1689727319000
    },
    {
        "time": 1689727320000,
        "value": 138.876,
        "_internal_originalTime": 1689727320000
    },
    {
        "time": 1689727322000,
        "value": 138.874,
        "_internal_originalTime": 1689727322000
    },
    {
        "time": 1689727324000,
        "value": 138.877,
        "_internal_originalTime": 1689727324000
    },
    {
        "time": 1689727326000,
        "value": 138.876,
        "_internal_originalTime": 1689727326000
    },
    {
        "time": 1689727328000,
        "value": 138.876,
        "_internal_originalTime": 1689727328000
    },
    {
        "time": 1689727330000,
        "value": 138.874,
        "_internal_originalTime": 1689727330000
    },
    {
        "time": 1689727332000,
        "value": 138.875,
        "_internal_originalTime": 1689727332000
    },
    {
        "time": 1689727335000,
        "value": 138.877,
        "_internal_originalTime": 1689727335000
    },
    {
        "time": 1689727338000,
        "value": 138.87,
        "_internal_originalTime": 1689727338000
    },
    {
        "time": 1689727339000,
        "value": 138.868,
        "_internal_originalTime": 1689727339000
    },
    {
        "time": 1689727341000,
        "value": 138.87,
        "_internal_originalTime": 1689727341000
    },
    {
        "time": 1689727343000,
        "value": 138.869,
        "_internal_originalTime": 1689727343000
    },
    {
        "time": 1689727345000,
        "value": 138.871,
        "_internal_originalTime": 1689727345000
    },
    {
        "time": 1689727347000,
        "value": 138.887,
        "_internal_originalTime": 1689727347000
    },
    {
        "time": 1689727349000,
        "value": 138.894,
        "_internal_originalTime": 1689727349000
    },
    {
        "time": 1689727350000,
        "value": 138.898,
        "_internal_originalTime": 1689727350000
    },
    {
        "time": 1689727351000,
        "value": 138.897,
        "_internal_originalTime": 1689727351000
    },
    {
        "time": 1689727354000,
        "value": 138.897,
        "_internal_originalTime": 1689727354000
    },
    {
        "time": 1689727356000,
        "value": 138.897,
        "_internal_originalTime": 1689727356000
    },
    {
        "time": 1689727358000,
        "value": 138.898,
        "_internal_originalTime": 1689727358000
    },
    {
        "time": 1689727360000,
        "value": 138.897,
        "_internal_originalTime": 1689727360000
    },
    {
        "time": 1689727361000,
        "value": 138.899,
        "_internal_originalTime": 1689727361000
    },
    {
        "time": 1689727363000,
        "value": 138.898,
        "_internal_originalTime": 1689727363000
    },
    {
        "time": 1689727365000,
        "value": 138.895,
        "_internal_originalTime": 1689727365000
    },
    {
        "time": 1689727367000,
        "value": 138.893,
        "_internal_originalTime": 1689727367000
    },
    {
        "time": 1689727370000,
        "value": 138.898,
        "_internal_originalTime": 1689727370000
    },
    {
        "time": 1689727371000,
        "value": 138.893,
        "_internal_originalTime": 1689727371000
    },
    {
        "time": 1689727377000,
        "value": 138.894,
        "_internal_originalTime": 1689727377000
    },
    {
        "time": 1689727379000,
        "value": 138.885,
        "_internal_originalTime": 1689727379000
    },
    {
        "time": 1689727381000,
        "value": 138.888,
        "_internal_originalTime": 1689727381000
    },
    {
        "time": 1689727383000,
        "value": 138.893,
        "_internal_originalTime": 1689727383000
    },
    {
        "time": 1689727385000,
        "value": 138.896,
        "_internal_originalTime": 1689727385000
    },
    {
        "time": 1689727387000,
        "value": 138.897,
        "_internal_originalTime": 1689727387000
    },
    {
        "time": 1689727392000,
        "value": 138.894,
        "_internal_originalTime": 1689727392000
    },
    {
        "time": 1689727394000,
        "value": 138.898,
        "_internal_originalTime": 1689727394000
    },
    {
        "time": 1689727395000,
        "value": 138.896,
        "_internal_originalTime": 1689727395000
    },
    {
        "time": 1689727396000,
        "value": 138.893,
        "_internal_originalTime": 1689727396000
    },
    {
        "time": 1689727398000,
        "value": 138.896,
        "_internal_originalTime": 1689727398000
    },
    {
        "time": 1689727400000,
        "value": 138.895,
        "_internal_originalTime": 1689727400000
    },
    {
        "time": 1689727402000,
        "value": 138.897,
        "_internal_originalTime": 1689727402000
    },
    {
        "time": 1689727405000,
        "value": 138.892,
        "_internal_originalTime": 1689727405000
    },
    {
        "time": 1689727406000,
        "value": 138.889,
        "_internal_originalTime": 1689727406000
    },
    {
        "time": 1689727409000,
        "value": 138.889,
        "_internal_originalTime": 1689727409000
    },
    {
        "time": 1689727410000,
        "value": 138.89,
        "_internal_originalTime": 1689727410000
    },
    {
        "time": 1689727412000,
        "value": 138.891,
        "_internal_originalTime": 1689727412000
    },
    {
        "time": 1689727414000,
        "value": 138.89,
        "_internal_originalTime": 1689727414000
    },
    {
        "time": 1689727415000,
        "value": 138.888,
        "_internal_originalTime": 1689727415000
    },
    {
        "time": 1689727417000,
        "value": 138.891,
        "_internal_originalTime": 1689727417000
    },
    {
        "time": 1689727419000,
        "value": 138.89,
        "_internal_originalTime": 1689727419000
    },
    {
        "time": 1689727422000,
        "value": 138.893,
        "_internal_originalTime": 1689727422000
    },
    {
        "time": 1689727423000,
        "value": 138.891,
        "_internal_originalTime": 1689727423000
    },
    {
        "time": 1689727424000,
        "value": 138.892,
        "_internal_originalTime": 1689727424000
    },
    {
        "time": 1689727426000,
        "value": 138.892,
        "_internal_originalTime": 1689727426000
    },
    {
        "time": 1689727430000,
        "value": 138.892,
        "_internal_originalTime": 1689727430000
    },
    {
        "time": 1689727432000,
        "value": 138.889,
        "_internal_originalTime": 1689727432000
    },
    {
        "time": 1689727433000,
        "value": 138.888,
        "_internal_originalTime": 1689727433000
    },
    {
        "time": 1689727436000,
        "value": 138.893,
        "_internal_originalTime": 1689727436000
    },
    {
        "time": 1689727437000,
        "value": 138.894,
        "_internal_originalTime": 1689727437000
    },
    {
        "time": 1689727440000,
        "value": 138.897,
        "_internal_originalTime": 1689727440000
    },
    {
        "time": 1689727441000,
        "value": 138.897,
        "_internal_originalTime": 1689727441000
    },
    {
        "time": 1689727444000,
        "value": 138.891,
        "_internal_originalTime": 1689727444000
    },
    {
        "time": 1689727445000,
        "value": 138.889,
        "_internal_originalTime": 1689727445000
    },
    {
        "time": 1689727448000,
        "value": 138.891,
        "_internal_originalTime": 1689727448000
    },
    {
        "time": 1689727449000,
        "value": 138.888,
        "_internal_originalTime": 1689727449000
    },
    {
        "time": 1689727450000,
        "value": 138.887,
        "_internal_originalTime": 1689727450000
    },
    {
        "time": 1689727454000,
        "value": 138.887,
        "_internal_originalTime": 1689727454000
    },
    {
        "time": 1689727457000,
        "value": 138.898,
        "_internal_originalTime": 1689727457000
    },
    {
        "time": 1689727458000,
        "value": 138.896,
        "_internal_originalTime": 1689727458000
    },
    {
        "time": 1689727461000,
        "value": 138.897,
        "_internal_originalTime": 1689727461000
    },
    {
        "time": 1689727464000,
        "value": 138.9,
        "_internal_originalTime": 1689727464000
    },
    {
        "time": 1689727466000,
        "value": 138.9,
        "_internal_originalTime": 1689727466000
    },
    {
        "time": 1689727467000,
        "value": 138.896,
        "_internal_originalTime": 1689727467000
    },
    {
        "time": 1689727468000,
        "value": 138.898,
        "_internal_originalTime": 1689727468000
    },
    {
        "time": 1689727474000,
        "value": 138.898,
        "_internal_originalTime": 1689727474000
    },
    {
        "time": 1689727476000,
        "value": 138.897,
        "_internal_originalTime": 1689727476000
    },
    {
        "time": 1689727478000,
        "value": 138.896,
        "_internal_originalTime": 1689727478000
    },
    {
        "time": 1689727479000,
        "value": 138.895,
        "_internal_originalTime": 1689727479000
    },
    {
        "time": 1689727484000,
        "value": 138.897,
        "_internal_originalTime": 1689727484000
    },
    {
        "time": 1689727485000,
        "value": 138.898,
        "_internal_originalTime": 1689727485000
    },
    {
        "time": 1689727487000,
        "value": 138.899,
        "_internal_originalTime": 1689727487000
    },
    {
        "time": 1689727488000,
        "value": 138.898,
        "_internal_originalTime": 1689727488000
    },
    {
        "time": 1689727490000,
        "value": 138.896,
        "_internal_originalTime": 1689727490000
    },
    {
        "time": 1689727491000,
        "value": 138.895,
        "_internal_originalTime": 1689727491000
    },
    {
        "time": 1689727495000,
        "value": 138.897,
        "_internal_originalTime": 1689727495000
    },
    {
        "time": 1689727497000,
        "value": 138.874,
        "_internal_originalTime": 1689727497000
    },
    {
        "time": 1689727499000,
        "value": 138.877,
        "_internal_originalTime": 1689727499000
    },
    {
        "time": 1689727500000,
        "value": 138.884,
        "_internal_originalTime": 1689727500000
    },
    {
        "time": 1689727502000,
        "value": 138.883,
        "_internal_originalTime": 1689727502000
    },
    {
        "time": 1689727503000,
        "value": 138.892,
        "_internal_originalTime": 1689727503000
    },
    {
        "time": 1689727505000,
        "value": 138.89,
        "_internal_originalTime": 1689727505000
    },
    {
        "time": 1689727507000,
        "value": 138.897,
        "_internal_originalTime": 1689727507000
    },
    {
        "time": 1689727509000,
        "value": 138.897,
        "_internal_originalTime": 1689727509000
    },
    {
        "time": 1689727511000,
        "value": 138.897,
        "_internal_originalTime": 1689727511000
    },
    {
        "time": 1689727512000,
        "value": 138.893,
        "_internal_originalTime": 1689727512000
    },
    {
        "time": 1689727514000,
        "value": 138.906,
        "_internal_originalTime": 1689727514000
    },
    {
        "time": 1689727516000,
        "value": 138.904,
        "_internal_originalTime": 1689727516000
    },
    {
        "time": 1689727518000,
        "value": 138.905,
        "_internal_originalTime": 1689727518000
    },
    {
        "time": 1689727519000,
        "value": 138.905,
        "_internal_originalTime": 1689727519000
    },
    {
        "time": 1689727521000,
        "value": 138.905,
        "_internal_originalTime": 1689727521000
    },
    {
        "time": 1689727523000,
        "value": 138.915,
        "_internal_originalTime": 1689727523000
    },
    {
        "time": 1689727525000,
        "value": 138.919,
        "_internal_originalTime": 1689727525000
    },
    {
        "time": 1689727527000,
        "value": 138.917,
        "_internal_originalTime": 1689727527000
    },
    {
        "time": 1689727528000,
        "value": 138.929,
        "_internal_originalTime": 1689727528000
    },
    {
        "time": 1689727529000,
        "value": 138.926,
        "_internal_originalTime": 1689727529000
    },
    {
        "time": 1689727531000,
        "value": 138.929,
        "_internal_originalTime": 1689727531000
    },
    {
        "time": 1689727533000,
        "value": 138.932,
        "_internal_originalTime": 1689727533000
    },
    {
        "time": 1689727535000,
        "value": 138.947,
        "_internal_originalTime": 1689727535000
    },
    {
        "time": 1689727537000,
        "value": 138.945,
        "_internal_originalTime": 1689727537000
    },
    {
        "time": 1689727539000,
        "value": 138.939,
        "_internal_originalTime": 1689727539000
    },
    {
        "time": 1689727540000,
        "value": 138.946,
        "_internal_originalTime": 1689727540000
    },
    {
        "time": 1689727542000,
        "value": 138.946,
        "_internal_originalTime": 1689727542000
    },
    {
        "time": 1689727544000,
        "value": 138.947,
        "_internal_originalTime": 1689727544000
    },
    {
        "time": 1689727545000,
        "value": 138.94,
        "_internal_originalTime": 1689727545000
    },
    {
        "time": 1689727547000,
        "value": 138.943,
        "_internal_originalTime": 1689727547000
    },
    {
        "time": 1689727549000,
        "value": 138.941,
        "_internal_originalTime": 1689727549000
    },
    {
        "time": 1689727551000,
        "value": 138.937,
        "_internal_originalTime": 1689727551000
    },
    {
        "time": 1689727552000,
        "value": 138.936,
        "_internal_originalTime": 1689727552000
    },
    {
        "time": 1689727555000,
        "value": 138.94,
        "_internal_originalTime": 1689727555000
    },
    {
        "time": 1689727557000,
        "value": 138.938,
        "_internal_originalTime": 1689727557000
    },
    {
        "time": 1689727558000,
        "value": 138.939,
        "_internal_originalTime": 1689727558000
    },
    {
        "time": 1689727560000,
        "value": 138.92,
        "_internal_originalTime": 1689727560000
    },
    {
        "time": 1689727562000,
        "value": 138.931,
        "_internal_originalTime": 1689727562000
    },
    {
        "time": 1689727563000,
        "value": 138.933,
        "_internal_originalTime": 1689727563000
    },
    {
        "time": 1689727566000,
        "value": 138.935,
        "_internal_originalTime": 1689727566000
    },
    {
        "time": 1689727567000,
        "value": 138.931,
        "_internal_originalTime": 1689727567000
    },
    {
        "time": 1689727570000,
        "value": 138.932,
        "_internal_originalTime": 1689727570000
    },
    {
        "time": 1689727572000,
        "value": 138.932,
        "_internal_originalTime": 1689727572000
    },
    {
        "time": 1689727573000,
        "value": 138.921,
        "_internal_originalTime": 1689727573000
    },
    {
        "time": 1689727574000,
        "value": 138.92,
        "_internal_originalTime": 1689727574000
    },
    {
        "time": 1689727576000,
        "value": 138.937,
        "_internal_originalTime": 1689727576000
    },
    {
        "time": 1689727578000,
        "value": 138.938,
        "_internal_originalTime": 1689727578000
    },
    {
        "time": 1689727580000,
        "value": 138.945,
        "_internal_originalTime": 1689727580000
    },
    {
        "time": 1689727582000,
        "value": 138.941,
        "_internal_originalTime": 1689727582000
    },
    {
        "time": 1689727584000,
        "value": 138.941,
        "_internal_originalTime": 1689727584000
    },
    {
        "time": 1689727587000,
        "value": 138.943,
        "_internal_originalTime": 1689727587000
    },
    {
        "time": 1689727589000,
        "value": 138.942,
        "_internal_originalTime": 1689727589000
    },
    {
        "time": 1689727591000,
        "value": 138.941,
        "_internal_originalTime": 1689727591000
    },
    {
        "time": 1689727592000,
        "value": 138.938,
        "_internal_originalTime": 1689727592000
    },
    {
        "time": 1689727594000,
        "value": 138.94,
        "_internal_originalTime": 1689727594000
    },
    {
        "time": 1689727596000,
        "value": 138.937,
        "_internal_originalTime": 1689727596000
    },
    {
        "time": 1689727598000,
        "value": 138.936,
        "_internal_originalTime": 1689727598000
    },
    {
        "time": 1689727600000,
        "value": 138.948,
        "_internal_originalTime": 1689727600000
    },
    {
        "time": 1689727601000,
        "value": 138.946,
        "_internal_originalTime": 1689727601000
    },
    {
        "time": 1689727603000,
        "value": 138.949,
        "_internal_originalTime": 1689727603000
    },
    {
        "time": 1689727606000,
        "value": 138.95,
        "_internal_originalTime": 1689727606000
    },
    {
        "time": 1689727608000,
        "value": 138.949,
        "_internal_originalTime": 1689727608000
    },
    {
        "time": 1689727609000,
        "value": 138.952,
        "_internal_originalTime": 1689727609000
    },
    {
        "time": 1689727610000,
        "value": 138.952,
        "_internal_originalTime": 1689727610000
    },
    {
        "time": 1689727612000,
        "value": 138.949,
        "_internal_originalTime": 1689727612000
    },
    {
        "time": 1689727613000,
        "value": 138.949,
        "_internal_originalTime": 1689727613000
    },
    {
        "time": 1689727615000,
        "value": 138.952,
        "_internal_originalTime": 1689727615000
    },
    {
        "time": 1689727618000,
        "value": 138.953,
        "_internal_originalTime": 1689727618000
    },
    {
        "time": 1689727620000,
        "value": 138.943,
        "_internal_originalTime": 1689727620000
    },
    {
        "time": 1689727622000,
        "value": 138.944,
        "_internal_originalTime": 1689727622000
    },
    {
        "time": 1689727624000,
        "value": 138.935,
        "_internal_originalTime": 1689727624000
    },
    {
        "time": 1689727625000,
        "value": 138.934,
        "_internal_originalTime": 1689727625000
    },
    {
        "time": 1689727627000,
        "value": 138.933,
        "_internal_originalTime": 1689727627000
    },
    {
        "time": 1689727628000,
        "value": 138.933,
        "_internal_originalTime": 1689727628000
    },
    {
        "time": 1689727630000,
        "value": 138.932,
        "_internal_originalTime": 1689727630000
    },
    {
        "time": 1689727632000,
        "value": 138.93,
        "_internal_originalTime": 1689727632000
    },
    {
        "time": 1689727633000,
        "value": 138.933,
        "_internal_originalTime": 1689727633000
    },
    {
        "time": 1689727635000,
        "value": 138.935,
        "_internal_originalTime": 1689727635000
    },
    {
        "time": 1689727637000,
        "value": 138.934,
        "_internal_originalTime": 1689727637000
    },
    {
        "time": 1689727639000,
        "value": 138.932,
        "_internal_originalTime": 1689727639000
    },
    {
        "time": 1689727641000,
        "value": 138.932,
        "_internal_originalTime": 1689727641000
    },
    {
        "time": 1689727646000,
        "value": 138.921,
        "_internal_originalTime": 1689727646000
    },
    {
        "time": 1689727648000,
        "value": 138.925,
        "_internal_originalTime": 1689727648000
    },
    {
        "time": 1689727649000,
        "value": 138.922,
        "_internal_originalTime": 1689727649000
    },
    {
        "time": 1689727650000,
        "value": 138.924,
        "_internal_originalTime": 1689727650000
    },
    {
        "time": 1689727652000,
        "value": 138.925,
        "_internal_originalTime": 1689727652000
    },
    {
        "time": 1689727654000,
        "value": 138.92,
        "_internal_originalTime": 1689727654000
    },
    {
        "time": 1689727656000,
        "value": 138.905,
        "_internal_originalTime": 1689727656000
    },
    {
        "time": 1689727658000,
        "value": 138.914,
        "_internal_originalTime": 1689727658000
    },
    {
        "time": 1689727660000,
        "value": 138.914,
        "_internal_originalTime": 1689727660000
    },
    {
        "time": 1689727662000,
        "value": 138.911,
        "_internal_originalTime": 1689727662000
    },
    {
        "time": 1689727664000,
        "value": 138.921,
        "_internal_originalTime": 1689727664000
    },
    {
        "time": 1689727666000,
        "value": 138.922,
        "_internal_originalTime": 1689727666000
    },
    {
        "time": 1689727667000,
        "value": 138.918,
        "_internal_originalTime": 1689727667000
    },
    {
        "time": 1689727668000,
        "value": 138.919,
        "_internal_originalTime": 1689727668000
    },
    {
        "time": 1689727672000,
        "value": 138.918,
        "_internal_originalTime": 1689727672000
    },
    {
        "time": 1689727677000,
        "value": 138.919,
        "_internal_originalTime": 1689727677000
    },
    {
        "time": 1689727679000,
        "value": 138.922,
        "_internal_originalTime": 1689727679000
    },
    {
        "time": 1689727680000,
        "value": 138.925,
        "_internal_originalTime": 1689727680000
    },
    {
        "time": 1689727683000,
        "value": 138.932,
        "_internal_originalTime": 1689727683000
    },
    {
        "time": 1689727686000,
        "value": 138.932,
        "_internal_originalTime": 1689727686000
    },
    {
        "time": 1689727689000,
        "value": 138.933,
        "_internal_originalTime": 1689727689000
    },
    {
        "time": 1689727690000,
        "value": 138.932,
        "_internal_originalTime": 1689727690000
    },
    {
        "time": 1689727691000,
        "value": 138.933,
        "_internal_originalTime": 1689727691000
    },
    {
        "time": 1689727694000,
        "value": 138.924,
        "_internal_originalTime": 1689727694000
    },
    {
        "time": 1689727696000,
        "value": 138.924,
        "_internal_originalTime": 1689727696000
    },
    {
        "time": 1689727698000,
        "value": 138.927,
        "_internal_originalTime": 1689727698000
    },
    {
        "time": 1689727702000,
        "value": 138.937,
        "_internal_originalTime": 1689727702000
    },
    {
        "time": 1689727703000,
        "value": 138.939,
        "_internal_originalTime": 1689727703000
    },
    {
        "time": 1689727706000,
        "value": 138.94,
        "_internal_originalTime": 1689727706000
    },
    {
        "time": 1689727707000,
        "value": 138.931,
        "_internal_originalTime": 1689727707000
    },
    {
        "time": 1689727708000,
        "value": 138.931,
        "_internal_originalTime": 1689727708000
    },
    {
        "time": 1689727710000,
        "value": 138.933,
        "_internal_originalTime": 1689727710000
    },
    {
        "time": 1689727711000,
        "value": 138.935,
        "_internal_originalTime": 1689727711000
    },
    {
        "time": 1689727713000,
        "value": 138.934,
        "_internal_originalTime": 1689727713000
    },
    {
        "time": 1689727714000,
        "value": 138.933,
        "_internal_originalTime": 1689727714000
    },
    {
        "time": 1689727717000,
        "value": 138.932,
        "_internal_originalTime": 1689727717000
    },
    {
        "time": 1689727721000,
        "value": 138.934,
        "_internal_originalTime": 1689727721000
    },
    {
        "time": 1689727722000,
        "value": 138.931,
        "_internal_originalTime": 1689727722000
    },
    {
        "time": 1689727725000,
        "value": 138.942,
        "_internal_originalTime": 1689727725000
    },
    {
        "time": 1689727726000,
        "value": 138.944,
        "_internal_originalTime": 1689727726000
    },
    {
        "time": 1689727727000,
        "value": 138.94,
        "_internal_originalTime": 1689727727000
    },
    {
        "time": 1689727729000,
        "value": 138.941,
        "_internal_originalTime": 1689727729000
    },
    {
        "time": 1689727731000,
        "value": 138.951,
        "_internal_originalTime": 1689727731000
    },
    {
        "time": 1689727732000,
        "value": 138.951,
        "_internal_originalTime": 1689727732000
    },
    {
        "time": 1689727735000,
        "value": 138.952,
        "_internal_originalTime": 1689727735000
    },
    {
        "time": 1689727736000,
        "value": 138.95,
        "_internal_originalTime": 1689727736000
    },
    {
        "time": 1689727737000,
        "value": 138.951,
        "_internal_originalTime": 1689727737000
    },
    {
        "time": 1689727739000,
        "value": 138.951,
        "_internal_originalTime": 1689727739000
    },
    {
        "time": 1689727742000,
        "value": 138.951,
        "_internal_originalTime": 1689727742000
    },
    {
        "time": 1689727743000,
        "value": 138.95,
        "_internal_originalTime": 1689727743000
    },
    {
        "time": 1689727745000,
        "value": 138.943,
        "_internal_originalTime": 1689727745000
    },
    {
        "time": 1689727747000,
        "value": 138.943,
        "_internal_originalTime": 1689727747000
    },
    {
        "time": 1689727751000,
        "value": 138.944,
        "_internal_originalTime": 1689727751000
    },
    {
        "time": 1689727752000,
        "value": 138.943,
        "_internal_originalTime": 1689727752000
    },
    {
        "time": 1689727754000,
        "value": 138.938,
        "_internal_originalTime": 1689727754000
    },
    {
        "time": 1689727755000,
        "value": 138.94,
        "_internal_originalTime": 1689727755000
    },
    {
        "time": 1689727757000,
        "value": 138.941,
        "_internal_originalTime": 1689727757000
    },
    {
        "time": 1689727759000,
        "value": 138.942,
        "_internal_originalTime": 1689727759000
    },
    {
        "time": 1689727761000,
        "value": 138.942,
        "_internal_originalTime": 1689727761000
    },
    {
        "time": 1689727764000,
        "value": 138.941,
        "_internal_originalTime": 1689727764000
    },
    {
        "time": 1689727766000,
        "value": 138.939,
        "_internal_originalTime": 1689727766000
    },
    {
        "time": 1689727767000,
        "value": 138.939,
        "_internal_originalTime": 1689727767000
    },
    {
        "time": 1689727769000,
        "value": 138.942,
        "_internal_originalTime": 1689727769000
    },
    {
        "time": 1689727771000,
        "value": 138.94,
        "_internal_originalTime": 1689727771000
    },
    {
        "time": 1689727773000,
        "value": 138.934,
        "_internal_originalTime": 1689727773000
    },
    {
        "time": 1689727774000,
        "value": 138.935,
        "_internal_originalTime": 1689727774000
    },
    {
        "time": 1689727776000,
        "value": 138.933,
        "_internal_originalTime": 1689727776000
    },
    {
        "time": 1689727779000,
        "value": 138.935,
        "_internal_originalTime": 1689727779000
    },
    {
        "time": 1689727781000,
        "value": 138.935,
        "_internal_originalTime": 1689727781000
    },
    {
        "time": 1689727789000,
        "value": 138.923,
        "_internal_originalTime": 1689727789000
    },
    {
        "time": 1689727791000,
        "value": 138.921,
        "_internal_originalTime": 1689727791000
    },
    {
        "time": 1689727792000,
        "value": 138.919,
        "_internal_originalTime": 1689727792000
    },
    {
        "time": 1689727793000,
        "value": 138.925,
        "_internal_originalTime": 1689727793000
    },
    {
        "time": 1689727796000,
        "value": 138.926,
        "_internal_originalTime": 1689727796000
    },
    {
        "time": 1689727797000,
        "value": 138.927,
        "_internal_originalTime": 1689727797000
    },
    {
        "time": 1689727802000,
        "value": 138.924,
        "_internal_originalTime": 1689727802000
    },
    {
        "time": 1689727803000,
        "value": 138.94,
        "_internal_originalTime": 1689727803000
    },
    {
        "time": 1689727806000,
        "value": 138.942,
        "_internal_originalTime": 1689727806000
    },
    {
        "time": 1689727807000,
        "value": 138.946,
        "_internal_originalTime": 1689727807000
    },
    {
        "time": 1689727809000,
        "value": 138.948,
        "_internal_originalTime": 1689727809000
    },
    {
        "time": 1689727810000,
        "value": 138.949,
        "_internal_originalTime": 1689727810000
    },
    {
        "time": 1689727812000,
        "value": 138.95,
        "_internal_originalTime": 1689727812000
    },
    {
        "time": 1689727814000,
        "value": 138.959,
        "_internal_originalTime": 1689727814000
    },
    {
        "time": 1689727816000,
        "value": 138.961,
        "_internal_originalTime": 1689727816000
    },
    {
        "time": 1689727818000,
        "value": 138.956,
        "_internal_originalTime": 1689727818000
    },
    {
        "time": 1689727819000,
        "value": 138.958,
        "_internal_originalTime": 1689727819000
    },
    {
        "time": 1689727821000,
        "value": 138.956,
        "_internal_originalTime": 1689727821000
    },
    {
        "time": 1689727823000,
        "value": 138.954,
        "_internal_originalTime": 1689727823000
    },
    {
        "time": 1689727824000,
        "value": 138.939,
        "_internal_originalTime": 1689727824000
    },
    {
        "time": 1689727826000,
        "value": 138.94,
        "_internal_originalTime": 1689727826000
    },
    {
        "time": 1689727827000,
        "value": 138.941,
        "_internal_originalTime": 1689727827000
    },
    {
        "time": 1689727828000,
        "value": 138.941,
        "_internal_originalTime": 1689727828000
    },
    {
        "time": 1689727831000,
        "value": 138.941,
        "_internal_originalTime": 1689727831000
    },
    {
        "time": 1689727833000,
        "value": 138.941,
        "_internal_originalTime": 1689727833000
    },
    {
        "time": 1689727835000,
        "value": 138.94,
        "_internal_originalTime": 1689727835000
    },
    {
        "time": 1689727838000,
        "value": 138.94,
        "_internal_originalTime": 1689727838000
    },
    {
        "time": 1689727841000,
        "value": 138.942,
        "_internal_originalTime": 1689727841000
    },
    {
        "time": 1689727843000,
        "value": 138.941,
        "_internal_originalTime": 1689727843000
    },
    {
        "time": 1689727844000,
        "value": 138.942,
        "_internal_originalTime": 1689727844000
    },
    {
        "time": 1689727846000,
        "value": 138.943,
        "_internal_originalTime": 1689727846000
    },
    {
        "time": 1689727847000,
        "value": 138.947,
        "_internal_originalTime": 1689727847000
    },
    {
        "time": 1689727850000,
        "value": 138.947,
        "_internal_originalTime": 1689727850000
    },
    {
        "time": 1689727851000,
        "value": 138.944,
        "_internal_originalTime": 1689727851000
    },
    {
        "time": 1689727853000,
        "value": 138.944,
        "_internal_originalTime": 1689727853000
    },
    {
        "time": 1689727855000,
        "value": 138.946,
        "_internal_originalTime": 1689727855000
    },
    {
        "time": 1689727856000,
        "value": 138.944,
        "_internal_originalTime": 1689727856000
    },
    {
        "time": 1689727857000,
        "value": 138.945,
        "_internal_originalTime": 1689727857000
    },
    {
        "time": 1689727860000,
        "value": 138.92,
        "_internal_originalTime": 1689727860000
    },
    {
        "time": 1689727861000,
        "value": 138.947,
        "_internal_originalTime": 1689727861000
    },
    {
        "time": 1689727864000,
        "value": 138.945,
        "_internal_originalTime": 1689727864000
    },
    {
        "time": 1689727866000,
        "value": 138.945,
        "_internal_originalTime": 1689727866000
    },
    {
        "time": 1689727868000,
        "value": 138.94,
        "_internal_originalTime": 1689727868000
    },
    {
        "time": 1689727869000,
        "value": 138.94,
        "_internal_originalTime": 1689727869000
    },
    {
        "time": 1689727871000,
        "value": 138.94,
        "_internal_originalTime": 1689727871000
    },
    {
        "time": 1689727873000,
        "value": 138.938,
        "_internal_originalTime": 1689727873000
    },
    {
        "time": 1689727874000,
        "value": 138.939,
        "_internal_originalTime": 1689727874000
    },
    {
        "time": 1689727876000,
        "value": 138.94,
        "_internal_originalTime": 1689727876000
    },
    {
        "time": 1689727878000,
        "value": 138.939,
        "_internal_originalTime": 1689727878000
    },
    {
        "time": 1689727880000,
        "value": 138.941,
        "_internal_originalTime": 1689727880000
    },
    {
        "time": 1689727882000,
        "value": 138.937,
        "_internal_originalTime": 1689727882000
    },
    {
        "time": 1689727883000,
        "value": 138.94,
        "_internal_originalTime": 1689727883000
    },
    {
        "time": 1689727886000,
        "value": 138.941,
        "_internal_originalTime": 1689727886000
    },
    {
        "time": 1689727888000,
        "value": 138.941,
        "_internal_originalTime": 1689727888000
    },
    {
        "time": 1689727889000,
        "value": 138.942,
        "_internal_originalTime": 1689727889000
    },
    {
        "time": 1689727892000,
        "value": 138.953,
        "_internal_originalTime": 1689727892000
    },
    {
        "time": 1689727894000,
        "value": 138.959,
        "_internal_originalTime": 1689727894000
    },
    {
        "time": 1689727895000,
        "value": 138.969,
        "_internal_originalTime": 1689727895000
    },
    {
        "time": 1689727897000,
        "value": 138.981,
        "_internal_originalTime": 1689727897000
    },
    {
        "time": 1689727899000,
        "value": 138.979,
        "_internal_originalTime": 1689727899000
    },
    {
        "time": 1689727900000,
        "value": 138.978,
        "_internal_originalTime": 1689727900000
    },
    {
        "time": 1689727902000,
        "value": 138.971,
        "_internal_originalTime": 1689727902000
    },
    {
        "time": 1689727904000,
        "value": 138.971,
        "_internal_originalTime": 1689727904000
    },
    {
        "time": 1689727906000,
        "value": 138.955,
        "_internal_originalTime": 1689727906000
    },
    {
        "time": 1689727907000,
        "value": 138.957,
        "_internal_originalTime": 1689727907000
    },
    {
        "time": 1689727909000,
        "value": 138.956,
        "_internal_originalTime": 1689727909000
    },
    {
        "time": 1689727910000,
        "value": 138.956,
        "_internal_originalTime": 1689727910000
    },
    {
        "time": 1689727912000,
        "value": 138.958,
        "_internal_originalTime": 1689727912000
    },
    {
        "time": 1689727914000,
        "value": 138.966,
        "_internal_originalTime": 1689727914000
    },
    {
        "time": 1689727915000,
        "value": 138.967,
        "_internal_originalTime": 1689727915000
    },
    {
        "time": 1689727919000,
        "value": 138.966,
        "_internal_originalTime": 1689727919000
    },
    {
        "time": 1689727920000,
        "value": 138.95,
        "_internal_originalTime": 1689727920000
    },
    {
        "time": 1689727924000,
        "value": 138.964,
        "_internal_originalTime": 1689727924000
    },
    {
        "time": 1689727925000,
        "value": 138.965,
        "_internal_originalTime": 1689727925000
    },
    {
        "time": 1689727926000,
        "value": 138.964,
        "_internal_originalTime": 1689727926000
    },
    {
        "time": 1689727929000,
        "value": 138.964,
        "_internal_originalTime": 1689727929000
    },
    {
        "time": 1689727931000,
        "value": 138.97,
        "_internal_originalTime": 1689727931000
    },
    {
        "time": 1689727932000,
        "value": 138.968,
        "_internal_originalTime": 1689727932000
    },
    {
        "time": 1689727934000,
        "value": 138.97,
        "_internal_originalTime": 1689727934000
    },
    {
        "time": 1689727936000,
        "value": 138.972,
        "_internal_originalTime": 1689727936000
    },
    {
        "time": 1689727937000,
        "value": 138.97,
        "_internal_originalTime": 1689727937000
    },
    {
        "time": 1689727940000,
        "value": 138.969,
        "_internal_originalTime": 1689727940000
    },
    {
        "time": 1689727942000,
        "value": 138.969,
        "_internal_originalTime": 1689727942000
    },
    {
        "time": 1689727944000,
        "value": 138.971,
        "_internal_originalTime": 1689727944000
    },
    {
        "time": 1689727946000,
        "value": 138.98,
        "_internal_originalTime": 1689727946000
    },
    {
        "time": 1689727947000,
        "value": 138.972,
        "_internal_originalTime": 1689727947000
    },
    {
        "time": 1689727949000,
        "value": 138.968,
        "_internal_originalTime": 1689727949000
    },
    {
        "time": 1689727950000,
        "value": 138.968,
        "_internal_originalTime": 1689727950000
    },
    {
        "time": 1689727951000,
        "value": 138.968,
        "_internal_originalTime": 1689727951000
    },
    {
        "time": 1689727955000,
        "value": 138.968,
        "_internal_originalTime": 1689727955000
    },
    {
        "time": 1689727956000,
        "value": 138.968,
        "_internal_originalTime": 1689727956000
    },
    {
        "time": 1689727959000,
        "value": 138.968,
        "_internal_originalTime": 1689727959000
    },
    {
        "time": 1689727961000,
        "value": 138.963,
        "_internal_originalTime": 1689727961000
    },
    {
        "time": 1689727963000,
        "value": 138.96,
        "_internal_originalTime": 1689727963000
    },
    {
        "time": 1689727964000,
        "value": 138.959,
        "_internal_originalTime": 1689727964000
    },
    {
        "time": 1689727966000,
        "value": 138.958,
        "_internal_originalTime": 1689727966000
    },
    {
        "time": 1689727970000,
        "value": 138.947,
        "_internal_originalTime": 1689727970000
    },
    {
        "time": 1689727973000,
        "value": 138.948,
        "_internal_originalTime": 1689727973000
    },
    {
        "time": 1689727974000,
        "value": 138.952,
        "_internal_originalTime": 1689727974000
    },
    {
        "time": 1689727976000,
        "value": 138.95,
        "_internal_originalTime": 1689727976000
    },
    {
        "time": 1689727977000,
        "value": 138.951,
        "_internal_originalTime": 1689727977000
    },
    {
        "time": 1689727980000,
        "value": 138.954,
        "_internal_originalTime": 1689727980000
    },
    {
        "time": 1689727982000,
        "value": 138.954,
        "_internal_originalTime": 1689727982000
    },
    {
        "time": 1689727984000,
        "value": 138.954,
        "_internal_originalTime": 1689727984000
    },
    {
        "time": 1689727986000,
        "value": 138.959,
        "_internal_originalTime": 1689727986000
    },
    {
        "time": 1689727988000,
        "value": 138.961,
        "_internal_originalTime": 1689727988000
    },
    {
        "time": 1689727989000,
        "value": 138.96,
        "_internal_originalTime": 1689727989000
    },
    {
        "time": 1689727991000,
        "value": 138.96,
        "_internal_originalTime": 1689727991000
    },
    {
        "time": 1689727992000,
        "value": 138.959,
        "_internal_originalTime": 1689727992000
    },
    {
        "time": 1689727994000,
        "value": 138.959,
        "_internal_originalTime": 1689727994000
    },
    {
        "time": 1689727995000,
        "value": 138.959,
        "_internal_originalTime": 1689727995000
    },
    {
        "time": 1689727997000,
        "value": 138.961,
        "_internal_originalTime": 1689727997000
    },
    {
        "time": 1689728001000,
        "value": 138.968,
        "_internal_originalTime": 1689728001000
    },
    {
        "time": 1689728003000,
        "value": 138.964,
        "_internal_originalTime": 1689728003000
    },
    {
        "time": 1689728005000,
        "value": 138.981,
        "_internal_originalTime": 1689728005000
    },
    {
        "time": 1689728006000,
        "value": 138.978,
        "_internal_originalTime": 1689728006000
    },
    {
        "time": 1689728011000,
        "value": 138.988,
        "_internal_originalTime": 1689728011000
    },
    {
        "time": 1689728012000,
        "value": 138.993,
        "_internal_originalTime": 1689728012000
    },
    {
        "time": 1689728014000,
        "value": 138.991,
        "_internal_originalTime": 1689728014000
    },
    {
        "time": 1689728015000,
        "value": 138.987,
        "_internal_originalTime": 1689728015000
    },
    {
        "time": 1689728018000,
        "value": 138.988,
        "_internal_originalTime": 1689728018000
    },
    {
        "time": 1689728019000,
        "value": 138.967,
        "_internal_originalTime": 1689728019000
    },
    {
        "time": 1689728021000,
        "value": 138.979,
        "_internal_originalTime": 1689728021000
    },
    {
        "time": 1689728023000,
        "value": 138.968,
        "_internal_originalTime": 1689728023000
    },
    {
        "time": 1689728025000,
        "value": 138.979,
        "_internal_originalTime": 1689728025000
    },
    {
        "time": 1689728027000,
        "value": 138.978,
        "_internal_originalTime": 1689728027000
    },
    {
        "time": 1689728028000,
        "value": 138.978,
        "_internal_originalTime": 1689728028000
    },
    {
        "time": 1689728030000,
        "value": 138.978,
        "_internal_originalTime": 1689728030000
    },
    {
        "time": 1689728032000,
        "value": 138.975,
        "_internal_originalTime": 1689728032000
    },
    {
        "time": 1689728033000,
        "value": 138.981,
        "_internal_originalTime": 1689728033000
    },
    {
        "time": 1689728035000,
        "value": 138.982,
        "_internal_originalTime": 1689728035000
    },
    {
        "time": 1689728036000,
        "value": 138.982,
        "_internal_originalTime": 1689728036000
    },
    {
        "time": 1689728038000,
        "value": 138.995,
        "_internal_originalTime": 1689728038000
    },
    {
        "time": 1689728039000,
        "value": 138.992,
        "_internal_originalTime": 1689728039000
    },
    {
        "time": 1689728040000,
        "value": 138.984,
        "_internal_originalTime": 1689728040000
    },
    {
        "time": 1689728041000,
        "value": 138.978,
        "_internal_originalTime": 1689728041000
    },
    {
        "time": 1689728042000,
        "value": 138.979,
        "_internal_originalTime": 1689728042000
    },
    {
        "time": 1689728044000,
        "value": 138.976,
        "_internal_originalTime": 1689728044000
    },
    {
        "time": 1689728046000,
        "value": 138.973,
        "_internal_originalTime": 1689728046000
    },
    {
        "time": 1689728047000,
        "value": 138.971,
        "_internal_originalTime": 1689728047000
    },
    {
        "time": 1689728049000,
        "value": 138.962,
        "_internal_originalTime": 1689728049000
    },
    {
        "time": 1689728050000,
        "value": 138.971,
        "_internal_originalTime": 1689728050000
    },
    {
        "time": 1689728052000,
        "value": 138.963,
        "_internal_originalTime": 1689728052000
    },
    {
        "time": 1689728054000,
        "value": 138.957,
        "_internal_originalTime": 1689728054000
    },
    {
        "time": 1689728055000,
        "value": 138.953,
        "_internal_originalTime": 1689728055000
    },
    {
        "time": 1689728056000,
        "value": 138.952,
        "_internal_originalTime": 1689728056000
    },
    {
        "time": 1689728059000,
        "value": 138.947,
        "_internal_originalTime": 1689728059000
    },
    {
        "time": 1689728061000,
        "value": 138.949,
        "_internal_originalTime": 1689728061000
    },
    {
        "time": 1689728063000,
        "value": 138.953,
        "_internal_originalTime": 1689728063000
    },
    {
        "time": 1689728065000,
        "value": 138.948,
        "_internal_originalTime": 1689728065000
    },
    {
        "time": 1689728067000,
        "value": 138.953,
        "_internal_originalTime": 1689728067000
    },
    {
        "time": 1689728068000,
        "value": 138.943,
        "_internal_originalTime": 1689728068000
    },
    {
        "time": 1689728070000,
        "value": 138.945,
        "_internal_originalTime": 1689728070000
    },
    {
        "time": 1689728071000,
        "value": 138.95,
        "_internal_originalTime": 1689728071000
    },
    {
        "time": 1689728072000,
        "value": 138.95,
        "_internal_originalTime": 1689728072000
    },
    {
        "time": 1689728075000,
        "value": 138.955,
        "_internal_originalTime": 1689728075000
    },
    {
        "time": 1689728076000,
        "value": 138.971,
        "_internal_originalTime": 1689728076000
    },
    {
        "time": 1689728078000,
        "value": 138.969,
        "_internal_originalTime": 1689728078000
    },
    {
        "time": 1689728080000,
        "value": 138.972,
        "_internal_originalTime": 1689728080000
    },
    {
        "time": 1689728082000,
        "value": 138.968,
        "_internal_originalTime": 1689728082000
    },
    {
        "time": 1689728084000,
        "value": 138.969,
        "_internal_originalTime": 1689728084000
    },
    {
        "time": 1689728086000,
        "value": 138.972,
        "_internal_originalTime": 1689728086000
    },
    {
        "time": 1689728087000,
        "value": 138.969,
        "_internal_originalTime": 1689728087000
    },
    {
        "time": 1689728089000,
        "value": 138.973,
        "_internal_originalTime": 1689728089000
    },
    {
        "time": 1689728090000,
        "value": 138.973,
        "_internal_originalTime": 1689728090000
    },
    {
        "time": 1689728092000,
        "value": 138.979,
        "_internal_originalTime": 1689728092000
    },
    {
        "time": 1689728093000,
        "value": 138.979,
        "_internal_originalTime": 1689728093000
    },
    {
        "time": 1689728094000,
        "value": 138.977,
        "_internal_originalTime": 1689728094000
    },
    {
        "time": 1689728096000,
        "value": 138.982,
        "_internal_originalTime": 1689728096000
    },
    {
        "time": 1689728098000,
        "value": 138.984,
        "_internal_originalTime": 1689728098000
    },
    {
        "time": 1689728100000,
        "value": 138.983,
        "_internal_originalTime": 1689728100000
    },
    {
        "time": 1689728102000,
        "value": 138.971,
        "_internal_originalTime": 1689728102000
    },
    {
        "time": 1689728104000,
        "value": 138.981,
        "_internal_originalTime": 1689728104000
    },
    {
        "time": 1689728106000,
        "value": 138.964,
        "_internal_originalTime": 1689728106000
    },
    {
        "time": 1689728107000,
        "value": 138.963,
        "_internal_originalTime": 1689728107000
    },
    {
        "time": 1689728109000,
        "value": 138.972,
        "_internal_originalTime": 1689728109000
    },
    {
        "time": 1689728111000,
        "value": 138.964,
        "_internal_originalTime": 1689728111000
    },
    {
        "time": 1689728112000,
        "value": 138.967,
        "_internal_originalTime": 1689728112000
    },
    {
        "time": 1689728113000,
        "value": 138.964,
        "_internal_originalTime": 1689728113000
    },
    {
        "time": 1689728115000,
        "value": 138.977,
        "_internal_originalTime": 1689728115000
    },
    {
        "time": 1689728116000,
        "value": 138.977,
        "_internal_originalTime": 1689728116000
    },
    {
        "time": 1689728119000,
        "value": 138.975,
        "_internal_originalTime": 1689728119000
    },
    {
        "time": 1689728122000,
        "value": 138.969,
        "_internal_originalTime": 1689728122000
    },
    {
        "time": 1689728123000,
        "value": 138.968,
        "_internal_originalTime": 1689728123000
    },
    {
        "time": 1689728126000,
        "value": 138.963,
        "_internal_originalTime": 1689728126000
    },
    {
        "time": 1689728127000,
        "value": 138.962,
        "_internal_originalTime": 1689728127000
    },
    {
        "time": 1689728129000,
        "value": 138.966,
        "_internal_originalTime": 1689728129000
    },
    {
        "time": 1689728130000,
        "value": 138.969,
        "_internal_originalTime": 1689728130000
    },
    {
        "time": 1689728132000,
        "value": 138.965,
        "_internal_originalTime": 1689728132000
    },
    {
        "time": 1689728133000,
        "value": 138.961,
        "_internal_originalTime": 1689728133000
    },
    {
        "time": 1689728135000,
        "value": 138.963,
        "_internal_originalTime": 1689728135000
    },
    {
        "time": 1689728136000,
        "value": 138.964,
        "_internal_originalTime": 1689728136000
    },
    {
        "time": 1689728138000,
        "value": 138.945,
        "_internal_originalTime": 1689728138000
    },
    {
        "time": 1689728140000,
        "value": 138.945,
        "_internal_originalTime": 1689728140000
    },
    {
        "time": 1689728143000,
        "value": 138.95,
        "_internal_originalTime": 1689728143000
    },
    {
        "time": 1689728144000,
        "value": 138.944,
        "_internal_originalTime": 1689728144000
    },
    {
        "time": 1689728146000,
        "value": 138.942,
        "_internal_originalTime": 1689728146000
    },
    {
        "time": 1689728147000,
        "value": 138.944,
        "_internal_originalTime": 1689728147000
    },
    {
        "time": 1689728149000,
        "value": 138.945,
        "_internal_originalTime": 1689728149000
    },
    {
        "time": 1689728150000,
        "value": 138.944,
        "_internal_originalTime": 1689728150000
    },
    {
        "time": 1689728152000,
        "value": 138.939,
        "_internal_originalTime": 1689728152000
    },
    {
        "time": 1689728153000,
        "value": 138.942,
        "_internal_originalTime": 1689728153000
    },
    {
        "time": 1689728155000,
        "value": 138.933,
        "_internal_originalTime": 1689728155000
    },
    {
        "time": 1689728157000,
        "value": 138.932,
        "_internal_originalTime": 1689728157000
    },
    {
        "time": 1689728159000,
        "value": 138.934,
        "_internal_originalTime": 1689728159000
    },
    {
        "time": 1689728160000,
        "value": 138.941,
        "_internal_originalTime": 1689728160000
    },
    {
        "time": 1689728161000,
        "value": 138.937,
        "_internal_originalTime": 1689728161000
    },
    {
        "time": 1689728163000,
        "value": 138.932,
        "_internal_originalTime": 1689728163000
    },
    {
        "time": 1689728165000,
        "value": 138.934,
        "_internal_originalTime": 1689728165000
    },
    {
        "time": 1689728167000,
        "value": 138.934,
        "_internal_originalTime": 1689728167000
    },
    {
        "time": 1689728168000,
        "value": 138.932,
        "_internal_originalTime": 1689728168000
    },
    {
        "time": 1689728171000,
        "value": 138.925,
        "_internal_originalTime": 1689728171000
    },
    {
        "time": 1689728173000,
        "value": 138.924,
        "_internal_originalTime": 1689728173000
    },
    {
        "time": 1689728174000,
        "value": 138.922,
        "_internal_originalTime": 1689728174000
    },
    {
        "time": 1689728176000,
        "value": 138.922,
        "_internal_originalTime": 1689728176000
    },
    {
        "time": 1689728178000,
        "value": 138.924,
        "_internal_originalTime": 1689728178000
    },
    {
        "time": 1689728180000,
        "value": 138.924,
        "_internal_originalTime": 1689728180000
    },
    {
        "time": 1689728181000,
        "value": 138.925,
        "_internal_originalTime": 1689728181000
    },
    {
        "time": 1689728183000,
        "value": 138.922,
        "_internal_originalTime": 1689728183000
    },
    {
        "time": 1689728184000,
        "value": 138.924,
        "_internal_originalTime": 1689728184000
    },
    {
        "time": 1689728187000,
        "value": 138.922,
        "_internal_originalTime": 1689728187000
    },
    {
        "time": 1689728189000,
        "value": 138.922,
        "_internal_originalTime": 1689728189000
    },
    {
        "time": 1689728190000,
        "value": 138.922,
        "_internal_originalTime": 1689728190000
    },
    {
        "time": 1689728191000,
        "value": 138.922,
        "_internal_originalTime": 1689728191000
    },
    {
        "time": 1689728194000,
        "value": 138.922,
        "_internal_originalTime": 1689728194000
    },
    {
        "time": 1689728195000,
        "value": 138.921,
        "_internal_originalTime": 1689728195000
    },
    {
        "time": 1689728198000,
        "value": 138.92,
        "_internal_originalTime": 1689728198000
    },
    {
        "time": 1689728200000,
        "value": 138.924,
        "_internal_originalTime": 1689728200000
    },
    {
        "time": 1689728202000,
        "value": 138.925,
        "_internal_originalTime": 1689728202000
    },
    {
        "time": 1689728204000,
        "value": 138.923,
        "_internal_originalTime": 1689728204000
    },
    {
        "time": 1689728206000,
        "value": 138.923,
        "_internal_originalTime": 1689728206000
    },
    {
        "time": 1689728207000,
        "value": 138.925,
        "_internal_originalTime": 1689728207000
    },
    {
        "time": 1689728209000,
        "value": 138.927,
        "_internal_originalTime": 1689728209000
    },
    {
        "time": 1689728211000,
        "value": 138.924,
        "_internal_originalTime": 1689728211000
    },
    {
        "time": 1689728212000,
        "value": 138.922,
        "_internal_originalTime": 1689728212000
    },
    {
        "time": 1689728214000,
        "value": 138.926,
        "_internal_originalTime": 1689728214000
    },
    {
        "time": 1689728216000,
        "value": 138.925,
        "_internal_originalTime": 1689728216000
    },
    {
        "time": 1689728218000,
        "value": 138.925,
        "_internal_originalTime": 1689728218000
    },
    {
        "time": 1689728219000,
        "value": 138.923,
        "_internal_originalTime": 1689728219000
    },
    {
        "time": 1689728220000,
        "value": 138.925,
        "_internal_originalTime": 1689728220000
    },
    {
        "time": 1689728222000,
        "value": 138.916,
        "_internal_originalTime": 1689728222000
    },
    {
        "time": 1689728223000,
        "value": 138.914,
        "_internal_originalTime": 1689728223000
    },
    {
        "time": 1689728226000,
        "value": 138.913,
        "_internal_originalTime": 1689728226000
    },
    {
        "time": 1689728227000,
        "value": 138.907,
        "_internal_originalTime": 1689728227000
    },
    {
        "time": 1689728230000,
        "value": 138.904,
        "_internal_originalTime": 1689728230000
    },
    {
        "time": 1689728231000,
        "value": 138.904,
        "_internal_originalTime": 1689728231000
    },
    {
        "time": 1689728233000,
        "value": 138.902,
        "_internal_originalTime": 1689728233000
    },
    {
        "time": 1689728235000,
        "value": 138.903,
        "_internal_originalTime": 1689728235000
    },
    {
        "time": 1689728236000,
        "value": 138.904,
        "_internal_originalTime": 1689728236000
    },
    {
        "time": 1689728237000,
        "value": 138.903,
        "_internal_originalTime": 1689728237000
    },
    {
        "time": 1689728240000,
        "value": 138.903,
        "_internal_originalTime": 1689728240000
    },
    {
        "time": 1689728242000,
        "value": 138.905,
        "_internal_originalTime": 1689728242000
    },
    {
        "time": 1689728244000,
        "value": 138.902,
        "_internal_originalTime": 1689728244000
    },
    {
        "time": 1689728246000,
        "value": 138.902,
        "_internal_originalTime": 1689728246000
    },
    {
        "time": 1689728248000,
        "value": 138.917,
        "_internal_originalTime": 1689728248000
    },
    {
        "time": 1689728249000,
        "value": 138.915,
        "_internal_originalTime": 1689728249000
    },
    {
        "time": 1689728250000,
        "value": 138.914,
        "_internal_originalTime": 1689728250000
    },
    {
        "time": 1689728255000,
        "value": 138.916,
        "_internal_originalTime": 1689728255000
    },
    {
        "time": 1689728257000,
        "value": 138.912,
        "_internal_originalTime": 1689728257000
    },
    {
        "time": 1689728260000,
        "value": 138.914,
        "_internal_originalTime": 1689728260000
    },
    {
        "time": 1689728263000,
        "value": 138.911,
        "_internal_originalTime": 1689728263000
    },
    {
        "time": 1689728264000,
        "value": 138.907,
        "_internal_originalTime": 1689728264000
    },
    {
        "time": 1689728266000,
        "value": 138.91,
        "_internal_originalTime": 1689728266000
    },
    {
        "time": 1689728268000,
        "value": 138.91,
        "_internal_originalTime": 1689728268000
    },
    {
        "time": 1689728270000,
        "value": 138.913,
        "_internal_originalTime": 1689728270000
    },
    {
        "time": 1689728271000,
        "value": 138.912,
        "_internal_originalTime": 1689728271000
    },
    {
        "time": 1689728273000,
        "value": 138.911,
        "_internal_originalTime": 1689728273000
    },
    {
        "time": 1689728275000,
        "value": 138.91,
        "_internal_originalTime": 1689728275000
    },
    {
        "time": 1689728277000,
        "value": 138.91,
        "_internal_originalTime": 1689728277000
    },
    {
        "time": 1689728278000,
        "value": 138.91,
        "_internal_originalTime": 1689728278000
    },
    {
        "time": 1689728281000,
        "value": 138.91,
        "_internal_originalTime": 1689728281000
    },
    {
        "time": 1689728282000,
        "value": 138.911,
        "_internal_originalTime": 1689728282000
    },
    {
        "time": 1689728284000,
        "value": 138.91,
        "_internal_originalTime": 1689728284000
    },
    {
        "time": 1689728287000,
        "value": 138.91,
        "_internal_originalTime": 1689728287000
    },
    {
        "time": 1689728291000,
        "value": 138.91,
        "_internal_originalTime": 1689728291000
    },
    {
        "time": 1689728293000,
        "value": 138.905,
        "_internal_originalTime": 1689728293000
    },
    {
        "time": 1689728294000,
        "value": 138.904,
        "_internal_originalTime": 1689728294000
    },
    {
        "time": 1689728298000,
        "value": 138.904,
        "_internal_originalTime": 1689728298000
    },
    {
        "time": 1689728300000,
        "value": 138.908,
        "_internal_originalTime": 1689728300000
    },
    {
        "time": 1689728302000,
        "value": 138.903,
        "_internal_originalTime": 1689728302000
    },
    {
        "time": 1689728303000,
        "value": 138.909,
        "_internal_originalTime": 1689728303000
    },
    {
        "time": 1689728304000,
        "value": 138.908,
        "_internal_originalTime": 1689728304000
    },
    {
        "time": 1689728307000,
        "value": 138.907,
        "_internal_originalTime": 1689728307000
    },
    {
        "time": 1689728308000,
        "value": 138.908,
        "_internal_originalTime": 1689728308000
    },
    {
        "time": 1689728312000,
        "value": 138.906,
        "_internal_originalTime": 1689728312000
    },
    {
        "time": 1689728314000,
        "value": 138.905,
        "_internal_originalTime": 1689728314000
    },
    {
        "time": 1689728315000,
        "value": 138.912,
        "_internal_originalTime": 1689728315000
    },
    {
        "time": 1689728318000,
        "value": 138.913,
        "_internal_originalTime": 1689728318000
    },
    {
        "time": 1689728322000,
        "value": 138.91,
        "_internal_originalTime": 1689728322000
    },
    {
        "time": 1689728324000,
        "value": 138.906,
        "_internal_originalTime": 1689728324000
    },
    {
        "time": 1689728325000,
        "value": 138.91,
        "_internal_originalTime": 1689728325000
    },
    {
        "time": 1689728327000,
        "value": 138.911,
        "_internal_originalTime": 1689728327000
    },
    {
        "time": 1689728328000,
        "value": 138.911,
        "_internal_originalTime": 1689728328000
    },
    {
        "time": 1689728331000,
        "value": 138.914,
        "_internal_originalTime": 1689728331000
    },
    {
        "time": 1689728333000,
        "value": 138.929,
        "_internal_originalTime": 1689728333000
    },
    {
        "time": 1689728334000,
        "value": 138.928,
        "_internal_originalTime": 1689728334000
    },
    {
        "time": 1689728336000,
        "value": 138.925,
        "_internal_originalTime": 1689728336000
    },
    {
        "time": 1689728338000,
        "value": 138.925,
        "_internal_originalTime": 1689728338000
    },
    {
        "time": 1689728340000,
        "value": 138.924,
        "_internal_originalTime": 1689728340000
    },
    {
        "time": 1689728341000,
        "value": 138.924,
        "_internal_originalTime": 1689728341000
    },
    {
        "time": 1689728343000,
        "value": 138.922,
        "_internal_originalTime": 1689728343000
    },
    {
        "time": 1689728344000,
        "value": 138.926,
        "_internal_originalTime": 1689728344000
    },
    {
        "time": 1689728346000,
        "value": 138.926,
        "_internal_originalTime": 1689728346000
    },
    {
        "time": 1689728349000,
        "value": 138.925,
        "_internal_originalTime": 1689728349000
    },
    {
        "time": 1689728351000,
        "value": 138.927,
        "_internal_originalTime": 1689728351000
    },
    {
        "time": 1689728353000,
        "value": 138.922,
        "_internal_originalTime": 1689728353000
    },
    {
        "time": 1689728355000,
        "value": 138.924,
        "_internal_originalTime": 1689728355000
    },
    {
        "time": 1689728356000,
        "value": 138.924,
        "_internal_originalTime": 1689728356000
    },
    {
        "time": 1689728358000,
        "value": 138.908,
        "_internal_originalTime": 1689728358000
    },
    {
        "time": 1689728359000,
        "value": 138.91,
        "_internal_originalTime": 1689728359000
    },
    {
        "time": 1689728362000,
        "value": 138.909,
        "_internal_originalTime": 1689728362000
    },
    {
        "time": 1689728364000,
        "value": 138.911,
        "_internal_originalTime": 1689728364000
    },
    {
        "time": 1689728366000,
        "value": 138.913,
        "_internal_originalTime": 1689728366000
    },
    {
        "time": 1689728367000,
        "value": 138.914,
        "_internal_originalTime": 1689728367000
    },
    {
        "time": 1689728369000,
        "value": 138.914,
        "_internal_originalTime": 1689728369000
    },
    {
        "time": 1689728370000,
        "value": 138.912,
        "_internal_originalTime": 1689728370000
    },
    {
        "time": 1689728372000,
        "value": 138.915,
        "_internal_originalTime": 1689728372000
    },
    {
        "time": 1689728374000,
        "value": 138.912,
        "_internal_originalTime": 1689728374000
    },
    {
        "time": 1689728375000,
        "value": 138.912,
        "_internal_originalTime": 1689728375000
    },
    {
        "time": 1689728377000,
        "value": 138.914,
        "_internal_originalTime": 1689728377000
    },
    {
        "time": 1689728379000,
        "value": 138.915,
        "_internal_originalTime": 1689728379000
    },
    {
        "time": 1689728382000,
        "value": 138.91,
        "_internal_originalTime": 1689728382000
    },
    {
        "time": 1689728384000,
        "value": 138.914,
        "_internal_originalTime": 1689728384000
    },
    {
        "time": 1689728385000,
        "value": 138.91,
        "_internal_originalTime": 1689728385000
    },
    {
        "time": 1689728387000,
        "value": 138.918,
        "_internal_originalTime": 1689728387000
    },
    {
        "time": 1689728389000,
        "value": 138.914,
        "_internal_originalTime": 1689728389000
    },
    {
        "time": 1689728390000,
        "value": 138.914,
        "_internal_originalTime": 1689728390000
    },
    {
        "time": 1689728392000,
        "value": 138.914,
        "_internal_originalTime": 1689728392000
    },
    {
        "time": 1689728394000,
        "value": 138.913,
        "_internal_originalTime": 1689728394000
    },
    {
        "time": 1689728396000,
        "value": 138.92,
        "_internal_originalTime": 1689728396000
    },
    {
        "time": 1689728399000,
        "value": 138.913,
        "_internal_originalTime": 1689728399000
    },
    {
        "time": 1689728400000,
        "value": 138.906,
        "_internal_originalTime": 1689728400000
    },
    {
        "time": 1689728401000,
        "value": 138.907,
        "_internal_originalTime": 1689728401000
    },
    {
        "time": 1689728403000,
        "value": 138.9,
        "_internal_originalTime": 1689728403000
    },
    {
        "time": 1689728404000,
        "value": 138.889,
        "_internal_originalTime": 1689728404000
    },
    {
        "time": 1689728406000,
        "value": 138.887,
        "_internal_originalTime": 1689728406000
    },
    {
        "time": 1689728408000,
        "value": 138.884,
        "_internal_originalTime": 1689728408000
    },
    {
        "time": 1689728410000,
        "value": 138.894,
        "_internal_originalTime": 1689728410000
    },
    {
        "time": 1689728412000,
        "value": 138.874,
        "_internal_originalTime": 1689728412000
    },
    {
        "time": 1689728414000,
        "value": 138.868,
        "_internal_originalTime": 1689728414000
    },
    {
        "time": 1689728415000,
        "value": 138.868,
        "_internal_originalTime": 1689728415000
    },
    {
        "time": 1689728417000,
        "value": 138.86,
        "_internal_originalTime": 1689728417000
    },
    {
        "time": 1689728418000,
        "value": 138.865,
        "_internal_originalTime": 1689728418000
    },
    {
        "time": 1689728420000,
        "value": 138.865,
        "_internal_originalTime": 1689728420000
    },
    {
        "time": 1689728422000,
        "value": 138.866,
        "_internal_originalTime": 1689728422000
    },
    {
        "time": 1689728424000,
        "value": 138.854,
        "_internal_originalTime": 1689728424000
    },
    {
        "time": 1689728426000,
        "value": 138.854,
        "_internal_originalTime": 1689728426000
    },
    {
        "time": 1689728429000,
        "value": 138.85,
        "_internal_originalTime": 1689728429000
    },
    {
        "time": 1689728431000,
        "value": 138.85,
        "_internal_originalTime": 1689728431000
    },
    {
        "time": 1689728432000,
        "value": 138.85,
        "_internal_originalTime": 1689728432000
    },
    {
        "time": 1689728434000,
        "value": 138.85,
        "_internal_originalTime": 1689728434000
    },
    {
        "time": 1689728436000,
        "value": 138.851,
        "_internal_originalTime": 1689728436000
    },
    {
        "time": 1689728438000,
        "value": 138.874,
        "_internal_originalTime": 1689728438000
    },
    {
        "time": 1689728440000,
        "value": 138.874,
        "_internal_originalTime": 1689728440000
    },
    {
        "time": 1689728442000,
        "value": 138.874,
        "_internal_originalTime": 1689728442000
    },
    {
        "time": 1689728444000,
        "value": 138.876,
        "_internal_originalTime": 1689728444000
    },
    {
        "time": 1689728445000,
        "value": 138.869,
        "_internal_originalTime": 1689728445000
    },
    {
        "time": 1689728447000,
        "value": 138.876,
        "_internal_originalTime": 1689728447000
    },
    {
        "time": 1689728448000,
        "value": 138.876,
        "_internal_originalTime": 1689728448000
    },
    {
        "time": 1689728450000,
        "value": 138.876,
        "_internal_originalTime": 1689728450000
    },
    {
        "time": 1689728452000,
        "value": 138.872,
        "_internal_originalTime": 1689728452000
    },
    {
        "time": 1689728453000,
        "value": 138.874,
        "_internal_originalTime": 1689728453000
    },
    {
        "time": 1689728456000,
        "value": 138.876,
        "_internal_originalTime": 1689728456000
    },
    {
        "time": 1689728457000,
        "value": 138.876,
        "_internal_originalTime": 1689728457000
    },
    {
        "time": 1689728459000,
        "value": 138.869,
        "_internal_originalTime": 1689728459000
    },
    {
        "time": 1689728461000,
        "value": 138.86,
        "_internal_originalTime": 1689728461000
    },
    {
        "time": 1689728462000,
        "value": 138.874,
        "_internal_originalTime": 1689728462000
    },
    {
        "time": 1689728464000,
        "value": 138.881,
        "_internal_originalTime": 1689728464000
    },
    {
        "time": 1689728466000,
        "value": 138.896,
        "_internal_originalTime": 1689728466000
    },
    {
        "time": 1689728467000,
        "value": 138.893,
        "_internal_originalTime": 1689728467000
    },
    {
        "time": 1689728469000,
        "value": 138.89,
        "_internal_originalTime": 1689728469000
    },
    {
        "time": 1689728471000,
        "value": 138.894,
        "_internal_originalTime": 1689728471000
    },
    {
        "time": 1689728473000,
        "value": 138.892,
        "_internal_originalTime": 1689728473000
    },
    {
        "time": 1689728474000,
        "value": 138.888,
        "_internal_originalTime": 1689728474000
    },
    {
        "time": 1689728475000,
        "value": 138.892,
        "_internal_originalTime": 1689728475000
    },
    {
        "time": 1689728478000,
        "value": 138.892,
        "_internal_originalTime": 1689728478000
    },
    {
        "time": 1689728479000,
        "value": 138.892,
        "_internal_originalTime": 1689728479000
    },
    {
        "time": 1689728481000,
        "value": 138.896,
        "_internal_originalTime": 1689728481000
    },
    {
        "time": 1689728483000,
        "value": 138.892,
        "_internal_originalTime": 1689728483000
    },
    {
        "time": 1689728485000,
        "value": 138.895,
        "_internal_originalTime": 1689728485000
    },
    {
        "time": 1689728487000,
        "value": 138.895,
        "_internal_originalTime": 1689728487000
    },
    {
        "time": 1689728488000,
        "value": 138.898,
        "_internal_originalTime": 1689728488000
    },
    {
        "time": 1689728489000,
        "value": 138.9,
        "_internal_originalTime": 1689728489000
    },
    {
        "time": 1689728490000,
        "value": 138.903,
        "_internal_originalTime": 1689728490000
    },
    {
        "time": 1689728492000,
        "value": 138.902,
        "_internal_originalTime": 1689728492000
    },
    {
        "time": 1689728494000,
        "value": 138.896,
        "_internal_originalTime": 1689728494000
    },
    {
        "time": 1689728496000,
        "value": 138.895,
        "_internal_originalTime": 1689728496000
    },
    {
        "time": 1689728500000,
        "value": 138.893,
        "_internal_originalTime": 1689728500000
    },
    {
        "time": 1689728502000,
        "value": 138.893,
        "_internal_originalTime": 1689728502000
    },
    {
        "time": 1689728504000,
        "value": 138.894,
        "_internal_originalTime": 1689728504000
    },
    {
        "time": 1689728505000,
        "value": 138.893,
        "_internal_originalTime": 1689728505000
    },
    {
        "time": 1689728506000,
        "value": 138.894,
        "_internal_originalTime": 1689728506000
    },
    {
        "time": 1689728509000,
        "value": 138.893,
        "_internal_originalTime": 1689728509000
    },
    {
        "time": 1689728510000,
        "value": 138.894,
        "_internal_originalTime": 1689728510000
    },
    {
        "time": 1689728512000,
        "value": 138.891,
        "_internal_originalTime": 1689728512000
    },
    {
        "time": 1689728513000,
        "value": 138.894,
        "_internal_originalTime": 1689728513000
    },
    {
        "time": 1689728516000,
        "value": 138.897,
        "_internal_originalTime": 1689728516000
    },
    {
        "time": 1689728518000,
        "value": 138.896,
        "_internal_originalTime": 1689728518000
    },
    {
        "time": 1689728519000,
        "value": 138.896,
        "_internal_originalTime": 1689728519000
    },
    {
        "time": 1689728521000,
        "value": 138.897,
        "_internal_originalTime": 1689728521000
    },
    {
        "time": 1689728523000,
        "value": 138.899,
        "_internal_originalTime": 1689728523000
    },
    {
        "time": 1689728525000,
        "value": 138.894,
        "_internal_originalTime": 1689728525000
    },
    {
        "time": 1689728527000,
        "value": 138.907,
        "_internal_originalTime": 1689728527000
    },
    {
        "time": 1689728528000,
        "value": 138.911,
        "_internal_originalTime": 1689728528000
    },
    {
        "time": 1689728530000,
        "value": 138.91,
        "_internal_originalTime": 1689728530000
    },
    {
        "time": 1689728532000,
        "value": 138.911,
        "_internal_originalTime": 1689728532000
    },
    {
        "time": 1689728534000,
        "value": 138.92,
        "_internal_originalTime": 1689728534000
    },
    {
        "time": 1689728535000,
        "value": 138.923,
        "_internal_originalTime": 1689728535000
    },
    {
        "time": 1689728536000,
        "value": 138.92,
        "_internal_originalTime": 1689728536000
    },
    {
        "time": 1689728538000,
        "value": 138.924,
        "_internal_originalTime": 1689728538000
    },
    {
        "time": 1689728540000,
        "value": 138.925,
        "_internal_originalTime": 1689728540000
    },
    {
        "time": 1689728542000,
        "value": 138.923,
        "_internal_originalTime": 1689728542000
    },
    {
        "time": 1689728544000,
        "value": 138.942,
        "_internal_originalTime": 1689728544000
    },
    {
        "time": 1689728545000,
        "value": 138.942,
        "_internal_originalTime": 1689728545000
    },
    {
        "time": 1689728547000,
        "value": 138.93,
        "_internal_originalTime": 1689728547000
    },
    {
        "time": 1689728548000,
        "value": 138.929,
        "_internal_originalTime": 1689728548000
    },
    {
        "time": 1689728550000,
        "value": 138.932,
        "_internal_originalTime": 1689728550000
    },
    {
        "time": 1689728551000,
        "value": 138.934,
        "_internal_originalTime": 1689728551000
    },
    {
        "time": 1689728553000,
        "value": 138.938,
        "_internal_originalTime": 1689728553000
    },
    {
        "time": 1689728554000,
        "value": 138.939,
        "_internal_originalTime": 1689728554000
    },
    {
        "time": 1689728557000,
        "value": 138.938,
        "_internal_originalTime": 1689728557000
    },
    {
        "time": 1689728558000,
        "value": 138.94,
        "_internal_originalTime": 1689728558000
    },
    {
        "time": 1689728560000,
        "value": 138.941,
        "_internal_originalTime": 1689728560000
    },
    {
        "time": 1689728562000,
        "value": 138.94,
        "_internal_originalTime": 1689728562000
    },
    {
        "time": 1689728564000,
        "value": 138.938,
        "_internal_originalTime": 1689728564000
    },
    {
        "time": 1689728565000,
        "value": 138.939,
        "_internal_originalTime": 1689728565000
    },
    {
        "time": 1689728567000,
        "value": 138.939,
        "_internal_originalTime": 1689728567000
    },
    {
        "time": 1689728569000,
        "value": 138.938,
        "_internal_originalTime": 1689728569000
    },
    {
        "time": 1689728571000,
        "value": 138.939,
        "_internal_originalTime": 1689728571000
    },
    {
        "time": 1689728572000,
        "value": 138.938,
        "_internal_originalTime": 1689728572000
    },
    {
        "time": 1689728575000,
        "value": 138.942,
        "_internal_originalTime": 1689728575000
    },
    {
        "time": 1689728576000,
        "value": 138.94,
        "_internal_originalTime": 1689728576000
    },
    {
        "time": 1689728579000,
        "value": 138.94,
        "_internal_originalTime": 1689728579000
    },
    {
        "time": 1689728582000,
        "value": 138.921,
        "_internal_originalTime": 1689728582000
    },
    {
        "time": 1689728584000,
        "value": 138.917,
        "_internal_originalTime": 1689728584000
    },
    {
        "time": 1689728585000,
        "value": 138.913,
        "_internal_originalTime": 1689728585000
    },
    {
        "time": 1689728587000,
        "value": 138.913,
        "_internal_originalTime": 1689728587000
    },
    {
        "time": 1689728589000,
        "value": 138.915,
        "_internal_originalTime": 1689728589000
    },
    {
        "time": 1689728591000,
        "value": 138.917,
        "_internal_originalTime": 1689728591000
    },
    {
        "time": 1689728592000,
        "value": 138.915,
        "_internal_originalTime": 1689728592000
    },
    {
        "time": 1689728595000,
        "value": 138.925,
        "_internal_originalTime": 1689728595000
    },
    {
        "time": 1689728596000,
        "value": 138.928,
        "_internal_originalTime": 1689728596000
    },
    {
        "time": 1689728600000,
        "value": 138.931,
        "_internal_originalTime": 1689728600000
    },
    {
        "time": 1689728602000,
        "value": 138.93,
        "_internal_originalTime": 1689728602000
    },
    {
        "time": 1689728603000,
        "value": 138.93,
        "_internal_originalTime": 1689728603000
    },
    {
        "time": 1689728605000,
        "value": 138.93,
        "_internal_originalTime": 1689728605000
    },
    {
        "time": 1689728606000,
        "value": 138.93,
        "_internal_originalTime": 1689728606000
    },
    {
        "time": 1689728607000,
        "value": 138.93,
        "_internal_originalTime": 1689728607000
    },
    {
        "time": 1689728609000,
        "value": 138.93,
        "_internal_originalTime": 1689728609000
    },
    {
        "time": 1689728611000,
        "value": 138.94,
        "_internal_originalTime": 1689728611000
    },
    {
        "time": 1689728612000,
        "value": 138.939,
        "_internal_originalTime": 1689728612000
    },
    {
        "time": 1689728613000,
        "value": 138.94,
        "_internal_originalTime": 1689728613000
    },
    {
        "time": 1689728617000,
        "value": 138.939,
        "_internal_originalTime": 1689728617000
    },
    {
        "time": 1689728622000,
        "value": 138.94,
        "_internal_originalTime": 1689728622000
    },
    {
        "time": 1689728624000,
        "value": 138.943,
        "_internal_originalTime": 1689728624000
    },
    {
        "time": 1689728625000,
        "value": 138.939,
        "_internal_originalTime": 1689728625000
    },
    {
        "time": 1689728628000,
        "value": 138.94,
        "_internal_originalTime": 1689728628000
    },
    {
        "time": 1689728631000,
        "value": 138.951,
        "_internal_originalTime": 1689728631000
    },
    {
        "time": 1689728632000,
        "value": 138.943,
        "_internal_originalTime": 1689728632000
    },
    {
        "time": 1689728634000,
        "value": 138.941,
        "_internal_originalTime": 1689728634000
    },
    {
        "time": 1689728636000,
        "value": 138.953,
        "_internal_originalTime": 1689728636000
    },
    {
        "time": 1689728637000,
        "value": 138.952,
        "_internal_originalTime": 1689728637000
    },
    {
        "time": 1689728638000,
        "value": 138.95,
        "_internal_originalTime": 1689728638000
    },
    {
        "time": 1689728641000,
        "value": 138.948,
        "_internal_originalTime": 1689728641000
    },
    {
        "time": 1689728642000,
        "value": 138.935,
        "_internal_originalTime": 1689728642000
    },
    {
        "time": 1689728644000,
        "value": 138.932,
        "_internal_originalTime": 1689728644000
    },
    {
        "time": 1689728646000,
        "value": 138.932,
        "_internal_originalTime": 1689728646000
    },
    {
        "time": 1689728648000,
        "value": 138.93,
        "_internal_originalTime": 1689728648000
    },
    {
        "time": 1689728649000,
        "value": 138.932,
        "_internal_originalTime": 1689728649000
    },
    {
        "time": 1689728652000,
        "value": 138.93,
        "_internal_originalTime": 1689728652000
    },
    {
        "time": 1689728655000,
        "value": 138.928,
        "_internal_originalTime": 1689728655000
    },
    {
        "time": 1689728657000,
        "value": 138.935,
        "_internal_originalTime": 1689728657000
    },
    {
        "time": 1689728658000,
        "value": 138.932,
        "_internal_originalTime": 1689728658000
    },
    {
        "time": 1689728661000,
        "value": 138.933,
        "_internal_originalTime": 1689728661000
    },
    {
        "time": 1689728663000,
        "value": 138.93,
        "_internal_originalTime": 1689728663000
    },
    {
        "time": 1689728664000,
        "value": 138.939,
        "_internal_originalTime": 1689728664000
    },
    {
        "time": 1689728667000,
        "value": 138.93,
        "_internal_originalTime": 1689728667000
    },
    {
        "time": 1689728668000,
        "value": 138.922,
        "_internal_originalTime": 1689728668000
    },
    {
        "time": 1689728669000,
        "value": 138.923,
        "_internal_originalTime": 1689728669000
    },
    {
        "time": 1689728670000,
        "value": 138.922,
        "_internal_originalTime": 1689728670000
    },
    {
        "time": 1689728671000,
        "value": 138.922,
        "_internal_originalTime": 1689728671000
    },
    {
        "time": 1689728673000,
        "value": 138.91,
        "_internal_originalTime": 1689728673000
    },
    {
        "time": 1689728675000,
        "value": 138.91,
        "_internal_originalTime": 1689728675000
    },
    {
        "time": 1689728676000,
        "value": 138.909,
        "_internal_originalTime": 1689728676000
    },
    {
        "time": 1689728679000,
        "value": 138.91,
        "_internal_originalTime": 1689728679000
    },
    {
        "time": 1689728680000,
        "value": 138.911,
        "_internal_originalTime": 1689728680000
    },
    {
        "time": 1689728683000,
        "value": 138.908,
        "_internal_originalTime": 1689728683000
    },
    {
        "time": 1689728685000,
        "value": 138.911,
        "_internal_originalTime": 1689728685000
    },
    {
        "time": 1689728686000,
        "value": 138.903,
        "_internal_originalTime": 1689728686000
    },
    {
        "time": 1689728688000,
        "value": 138.903,
        "_internal_originalTime": 1689728688000
    },
    {
        "time": 1689728690000,
        "value": 138.905,
        "_internal_originalTime": 1689728690000
    },
    {
        "time": 1689728692000,
        "value": 138.9,
        "_internal_originalTime": 1689728692000
    },
    {
        "time": 1689728694000,
        "value": 138.9,
        "_internal_originalTime": 1689728694000
    },
    {
        "time": 1689728696000,
        "value": 138.9,
        "_internal_originalTime": 1689728696000
    },
    {
        "time": 1689728699000,
        "value": 138.9,
        "_internal_originalTime": 1689728699000
    },
    {
        "time": 1689728700000,
        "value": 138.893,
        "_internal_originalTime": 1689728700000
    },
    {
        "time": 1689728701000,
        "value": 138.891,
        "_internal_originalTime": 1689728701000
    },
    {
        "time": 1689728702000,
        "value": 138.894,
        "_internal_originalTime": 1689728702000
    },
    {
        "time": 1689728705000,
        "value": 138.891,
        "_internal_originalTime": 1689728705000
    },
    {
        "time": 1689728707000,
        "value": 138.891,
        "_internal_originalTime": 1689728707000
    },
    {
        "time": 1689728710000,
        "value": 138.893,
        "_internal_originalTime": 1689728710000
    },
    {
        "time": 1689728713000,
        "value": 138.893,
        "_internal_originalTime": 1689728713000
    },
    {
        "time": 1689728718000,
        "value": 138.902,
        "_internal_originalTime": 1689728718000
    },
    {
        "time": 1689728720000,
        "value": 138.904,
        "_internal_originalTime": 1689728720000
    },
    {
        "time": 1689728721000,
        "value": 138.903,
        "_internal_originalTime": 1689728721000
    },
    {
        "time": 1689728723000,
        "value": 138.901,
        "_internal_originalTime": 1689728723000
    },
    {
        "time": 1689728725000,
        "value": 138.901,
        "_internal_originalTime": 1689728725000
    },
    {
        "time": 1689728726000,
        "value": 138.901,
        "_internal_originalTime": 1689728726000
    },
    {
        "time": 1689728728000,
        "value": 138.901,
        "_internal_originalTime": 1689728728000
    },
    {
        "time": 1689728729000,
        "value": 138.901,
        "_internal_originalTime": 1689728729000
    },
    {
        "time": 1689728732000,
        "value": 138.911,
        "_internal_originalTime": 1689728732000
    },
    {
        "time": 1689728733000,
        "value": 138.91,
        "_internal_originalTime": 1689728733000
    },
    {
        "time": 1689728735000,
        "value": 138.91,
        "_internal_originalTime": 1689728735000
    },
    {
        "time": 1689728736000,
        "value": 138.911,
        "_internal_originalTime": 1689728736000
    },
    {
        "time": 1689728738000,
        "value": 138.912,
        "_internal_originalTime": 1689728738000
    },
    {
        "time": 1689728741000,
        "value": 138.91,
        "_internal_originalTime": 1689728741000
    },
    {
        "time": 1689728743000,
        "value": 138.911,
        "_internal_originalTime": 1689728743000
    },
    {
        "time": 1689728744000,
        "value": 138.91,
        "_internal_originalTime": 1689728744000
    },
    {
        "time": 1689728746000,
        "value": 138.91,
        "_internal_originalTime": 1689728746000
    },
    {
        "time": 1689728747000,
        "value": 138.911,
        "_internal_originalTime": 1689728747000
    },
    {
        "time": 1689728750000,
        "value": 138.912,
        "_internal_originalTime": 1689728750000
    },
    {
        "time": 1689728753000,
        "value": 138.91,
        "_internal_originalTime": 1689728753000
    },
    {
        "time": 1689728756000,
        "value": 138.91,
        "_internal_originalTime": 1689728756000
    },
    {
        "time": 1689728760000,
        "value": 138.91,
        "_internal_originalTime": 1689728760000
    },
    {
        "time": 1689728761000,
        "value": 138.91,
        "_internal_originalTime": 1689728761000
    },
    {
        "time": 1689728763000,
        "value": 138.91,
        "_internal_originalTime": 1689728763000
    },
    {
        "time": 1689728766000,
        "value": 138.91,
        "_internal_originalTime": 1689728766000
    },
    {
        "time": 1689728777000,
        "value": 138.909,
        "_internal_originalTime": 1689728777000
    },
    {
        "time": 1689728778000,
        "value": 138.91,
        "_internal_originalTime": 1689728778000
    },
    {
        "time": 1689728781000,
        "value": 138.901,
        "_internal_originalTime": 1689728781000
    },
    {
        "time": 1689728783000,
        "value": 138.893,
        "_internal_originalTime": 1689728783000
    },
    {
        "time": 1689728785000,
        "value": 138.904,
        "_internal_originalTime": 1689728785000
    },
    {
        "time": 1689728787000,
        "value": 138.906,
        "_internal_originalTime": 1689728787000
    },
    {
        "time": 1689728790000,
        "value": 138.905,
        "_internal_originalTime": 1689728790000
    },
    {
        "time": 1689728791000,
        "value": 138.904,
        "_internal_originalTime": 1689728791000
    },
    {
        "time": 1689728793000,
        "value": 138.902,
        "_internal_originalTime": 1689728793000
    },
    {
        "time": 1689728794000,
        "value": 138.905,
        "_internal_originalTime": 1689728794000
    },
    {
        "time": 1689728796000,
        "value": 138.903,
        "_internal_originalTime": 1689728796000
    },
    {
        "time": 1689728798000,
        "value": 138.906,
        "_internal_originalTime": 1689728798000
    },
    {
        "time": 1689728799000,
        "value": 138.902,
        "_internal_originalTime": 1689728799000
    },
    {
        "time": 1689728804000,
        "value": 138.901,
        "_internal_originalTime": 1689728804000
    },
    {
        "time": 1689728806000,
        "value": 138.901,
        "_internal_originalTime": 1689728806000
    },
    {
        "time": 1689728807000,
        "value": 138.901,
        "_internal_originalTime": 1689728807000
    },
    {
        "time": 1689728810000,
        "value": 138.902,
        "_internal_originalTime": 1689728810000
    },
    {
        "time": 1689728811000,
        "value": 138.903,
        "_internal_originalTime": 1689728811000
    },
    {
        "time": 1689728812000,
        "value": 138.902,
        "_internal_originalTime": 1689728812000
    },
    {
        "time": 1689728814000,
        "value": 138.894,
        "_internal_originalTime": 1689728814000
    },
    {
        "time": 1689728815000,
        "value": 138.894,
        "_internal_originalTime": 1689728815000
    },
    {
        "time": 1689728818000,
        "value": 138.903,
        "_internal_originalTime": 1689728818000
    },
    {
        "time": 1689728819000,
        "value": 138.901,
        "_internal_originalTime": 1689728819000
    },
    {
        "time": 1689728821000,
        "value": 138.894,
        "_internal_originalTime": 1689728821000
    },
    {
        "time": 1689728822000,
        "value": 138.891,
        "_internal_originalTime": 1689728822000
    },
    {
        "time": 1689728823000,
        "value": 138.893,
        "_internal_originalTime": 1689728823000
    },
    {
        "time": 1689728826000,
        "value": 138.905,
        "_internal_originalTime": 1689728826000
    },
    {
        "time": 1689728828000,
        "value": 138.905,
        "_internal_originalTime": 1689728828000
    },
    {
        "time": 1689728829000,
        "value": 138.912,
        "_internal_originalTime": 1689728829000
    },
    {
        "time": 1689728831000,
        "value": 138.909,
        "_internal_originalTime": 1689728831000
    },
    {
        "time": 1689728833000,
        "value": 138.908,
        "_internal_originalTime": 1689728833000
    },
    {
        "time": 1689728835000,
        "value": 138.905,
        "_internal_originalTime": 1689728835000
    },
    {
        "time": 1689728836000,
        "value": 138.904,
        "_internal_originalTime": 1689728836000
    },
    {
        "time": 1689728838000,
        "value": 138.904,
        "_internal_originalTime": 1689728838000
    },
    {
        "time": 1689728840000,
        "value": 138.913,
        "_internal_originalTime": 1689728840000
    },
    {
        "time": 1689728842000,
        "value": 138.909,
        "_internal_originalTime": 1689728842000
    },
    {
        "time": 1689728844000,
        "value": 138.909,
        "_internal_originalTime": 1689728844000
    },
    {
        "time": 1689728846000,
        "value": 138.913,
        "_internal_originalTime": 1689728846000
    },
    {
        "time": 1689728848000,
        "value": 138.912,
        "_internal_originalTime": 1689728848000
    },
    {
        "time": 1689728849000,
        "value": 138.914,
        "_internal_originalTime": 1689728849000
    },
    {
        "time": 1689728852000,
        "value": 138.918,
        "_internal_originalTime": 1689728852000
    },
    {
        "time": 1689728854000,
        "value": 138.92,
        "_internal_originalTime": 1689728854000
    },
    {
        "time": 1689728855000,
        "value": 138.919,
        "_internal_originalTime": 1689728855000
    },
    {
        "time": 1689728857000,
        "value": 138.919,
        "_internal_originalTime": 1689728857000
    },
    {
        "time": 1689728860000,
        "value": 138.918,
        "_internal_originalTime": 1689728860000
    },
    {
        "time": 1689728861000,
        "value": 138.92,
        "_internal_originalTime": 1689728861000
    },
    {
        "time": 1689728863000,
        "value": 138.92,
        "_internal_originalTime": 1689728863000
    },
    {
        "time": 1689728869000,
        "value": 138.92,
        "_internal_originalTime": 1689728869000
    },
    {
        "time": 1689728870000,
        "value": 138.922,
        "_internal_originalTime": 1689728870000
    },
    {
        "time": 1689728872000,
        "value": 138.918,
        "_internal_originalTime": 1689728872000
    },
    {
        "time": 1689728874000,
        "value": 138.922,
        "_internal_originalTime": 1689728874000
    },
    {
        "time": 1689728876000,
        "value": 138.929,
        "_internal_originalTime": 1689728876000
    },
    {
        "time": 1689728878000,
        "value": 138.923,
        "_internal_originalTime": 1689728878000
    },
    {
        "time": 1689728879000,
        "value": 138.922,
        "_internal_originalTime": 1689728879000
    },
    {
        "time": 1689728880000,
        "value": 138.9,
        "_internal_originalTime": 1689728880000
    },
    {
        "time": 1689728882000,
        "value": 138.924,
        "_internal_originalTime": 1689728882000
    },
    {
        "time": 1689728884000,
        "value": 138.923,
        "_internal_originalTime": 1689728884000
    },
    {
        "time": 1689728885000,
        "value": 138.924,
        "_internal_originalTime": 1689728885000
    },
    {
        "time": 1689728888000,
        "value": 138.925,
        "_internal_originalTime": 1689728888000
    },
    {
        "time": 1689728889000,
        "value": 138.926,
        "_internal_originalTime": 1689728889000
    },
    {
        "time": 1689728891000,
        "value": 138.925,
        "_internal_originalTime": 1689728891000
    },
    {
        "time": 1689728893000,
        "value": 138.924,
        "_internal_originalTime": 1689728893000
    },
    {
        "time": 1689728896000,
        "value": 138.925,
        "_internal_originalTime": 1689728896000
    },
    {
        "time": 1689728898000,
        "value": 138.926,
        "_internal_originalTime": 1689728898000
    },
    {
        "time": 1689728900000,
        "value": 138.927,
        "_internal_originalTime": 1689728900000
    },
    {
        "time": 1689728902000,
        "value": 138.932,
        "_internal_originalTime": 1689728902000
    },
    {
        "time": 1689728904000,
        "value": 138.934,
        "_internal_originalTime": 1689728904000
    },
    {
        "time": 1689728906000,
        "value": 138.935,
        "_internal_originalTime": 1689728906000
    },
    {
        "time": 1689728909000,
        "value": 138.932,
        "_internal_originalTime": 1689728909000
    },
    {
        "time": 1689728911000,
        "value": 138.931,
        "_internal_originalTime": 1689728911000
    },
    {
        "time": 1689728912000,
        "value": 138.931,
        "_internal_originalTime": 1689728912000
    },
    {
        "time": 1689728914000,
        "value": 138.932,
        "_internal_originalTime": 1689728914000
    },
    {
        "time": 1689728916000,
        "value": 138.932,
        "_internal_originalTime": 1689728916000
    },
    {
        "time": 1689728920000,
        "value": 138.937,
        "_internal_originalTime": 1689728920000
    },
    {
        "time": 1689728922000,
        "value": 138.931,
        "_internal_originalTime": 1689728922000
    },
    {
        "time": 1689728924000,
        "value": 138.932,
        "_internal_originalTime": 1689728924000
    },
    {
        "time": 1689728927000,
        "value": 138.931,
        "_internal_originalTime": 1689728927000
    },
    {
        "time": 1689728929000,
        "value": 138.933,
        "_internal_originalTime": 1689728929000
    },
    {
        "time": 1689728931000,
        "value": 138.923,
        "_internal_originalTime": 1689728931000
    },
    {
        "time": 1689728932000,
        "value": 138.922,
        "_internal_originalTime": 1689728932000
    },
    {
        "time": 1689728937000,
        "value": 138.912,
        "_internal_originalTime": 1689728937000
    },
    {
        "time": 1689728938000,
        "value": 138.909,
        "_internal_originalTime": 1689728938000
    },
    {
        "time": 1689728939000,
        "value": 138.911,
        "_internal_originalTime": 1689728939000
    },
    {
        "time": 1689728940000,
        "value": 138.911,
        "_internal_originalTime": 1689728940000
    },
    {
        "time": 1689728941000,
        "value": 138.912,
        "_internal_originalTime": 1689728941000
    },
    {
        "time": 1689728942000,
        "value": 138.915,
        "_internal_originalTime": 1689728942000
    },
    {
        "time": 1689728943000,
        "value": 138.915,
        "_internal_originalTime": 1689728943000
    },
    {
        "time": 1689728944000,
        "value": 138.915,
        "_internal_originalTime": 1689728944000
    },
    {
        "time": 1689728948000,
        "value": 138.914,
        "_internal_originalTime": 1689728948000
    },
    {
        "time": 1689728950000,
        "value": 138.912,
        "_internal_originalTime": 1689728950000
    },
    {
        "time": 1689728952000,
        "value": 138.915,
        "_internal_originalTime": 1689728952000
    },
    {
        "time": 1689728957000,
        "value": 138.922,
        "_internal_originalTime": 1689728957000
    },
    {
        "time": 1689728959000,
        "value": 138.925,
        "_internal_originalTime": 1689728959000
    },
    {
        "time": 1689728961000,
        "value": 138.922,
        "_internal_originalTime": 1689728961000
    },
    {
        "time": 1689728963000,
        "value": 138.925,
        "_internal_originalTime": 1689728963000
    },
    {
        "time": 1689728964000,
        "value": 138.933,
        "_internal_originalTime": 1689728964000
    },
    {
        "time": 1689728968000,
        "value": 138.93,
        "_internal_originalTime": 1689728968000
    },
    {
        "time": 1689728970000,
        "value": 138.923,
        "_internal_originalTime": 1689728970000
    },
    {
        "time": 1689728971000,
        "value": 138.912,
        "_internal_originalTime": 1689728971000
    },
    {
        "time": 1689728973000,
        "value": 138.912,
        "_internal_originalTime": 1689728973000
    },
    {
        "time": 1689728974000,
        "value": 138.909,
        "_internal_originalTime": 1689728974000
    },
    {
        "time": 1689728976000,
        "value": 138.912,
        "_internal_originalTime": 1689728976000
    },
    {
        "time": 1689728978000,
        "value": 138.909,
        "_internal_originalTime": 1689728978000
    },
    {
        "time": 1689728980000,
        "value": 138.905,
        "_internal_originalTime": 1689728980000
    },
    {
        "time": 1689728982000,
        "value": 138.908,
        "_internal_originalTime": 1689728982000
    },
    {
        "time": 1689728983000,
        "value": 138.905,
        "_internal_originalTime": 1689728983000
    },
    {
        "time": 1689728986000,
        "value": 138.907,
        "_internal_originalTime": 1689728986000
    },
    {
        "time": 1689728990000,
        "value": 138.906,
        "_internal_originalTime": 1689728990000
    },
    {
        "time": 1689728991000,
        "value": 138.904,
        "_internal_originalTime": 1689728991000
    },
    {
        "time": 1689728992000,
        "value": 138.902,
        "_internal_originalTime": 1689728992000
    },
    {
        "time": 1689728997000,
        "value": 138.904,
        "_internal_originalTime": 1689728997000
    },
    {
        "time": 1689728998000,
        "value": 138.895,
        "_internal_originalTime": 1689728998000
    },
    {
        "time": 1689729002000,
        "value": 138.9,
        "_internal_originalTime": 1689729002000
    },
    {
        "time": 1689729004000,
        "value": 138.901,
        "_internal_originalTime": 1689729004000
    },
    {
        "time": 1689729006000,
        "value": 138.91,
        "_internal_originalTime": 1689729006000
    },
    {
        "time": 1689729008000,
        "value": 138.905,
        "_internal_originalTime": 1689729008000
    },
    {
        "time": 1689729010000,
        "value": 138.904,
        "_internal_originalTime": 1689729010000
    },
    {
        "time": 1689729012000,
        "value": 138.905,
        "_internal_originalTime": 1689729012000
    },
    {
        "time": 1689729013000,
        "value": 138.902,
        "_internal_originalTime": 1689729013000
    },
    {
        "time": 1689729015000,
        "value": 138.902,
        "_internal_originalTime": 1689729015000
    },
    {
        "time": 1689729016000,
        "value": 138.901,
        "_internal_originalTime": 1689729016000
    },
    {
        "time": 1689729019000,
        "value": 138.908,
        "_internal_originalTime": 1689729019000
    },
    {
        "time": 1689729020000,
        "value": 138.909,
        "_internal_originalTime": 1689729020000
    },
    {
        "time": 1689729022000,
        "value": 138.908,
        "_internal_originalTime": 1689729022000
    },
    {
        "time": 1689729024000,
        "value": 138.909,
        "_internal_originalTime": 1689729024000
    },
    {
        "time": 1689729026000,
        "value": 138.918,
        "_internal_originalTime": 1689729026000
    },
    {
        "time": 1689729028000,
        "value": 138.918,
        "_internal_originalTime": 1689729028000
    },
    {
        "time": 1689729030000,
        "value": 138.919,
        "_internal_originalTime": 1689729030000
    },
    {
        "time": 1689729031000,
        "value": 138.92,
        "_internal_originalTime": 1689729031000
    },
    {
        "time": 1689729032000,
        "value": 138.919,
        "_internal_originalTime": 1689729032000
    },
    {
        "time": 1689729035000,
        "value": 138.92,
        "_internal_originalTime": 1689729035000
    },
    {
        "time": 1689729037000,
        "value": 138.938,
        "_internal_originalTime": 1689729037000
    },
    {
        "time": 1689729038000,
        "value": 138.939,
        "_internal_originalTime": 1689729038000
    },
    {
        "time": 1689729040000,
        "value": 138.939,
        "_internal_originalTime": 1689729040000
    },
    {
        "time": 1689729042000,
        "value": 138.939,
        "_internal_originalTime": 1689729042000
    },
    {
        "time": 1689729045000,
        "value": 138.937,
        "_internal_originalTime": 1689729045000
    },
    {
        "time": 1689729046000,
        "value": 138.937,
        "_internal_originalTime": 1689729046000
    },
    {
        "time": 1689729048000,
        "value": 138.934,
        "_internal_originalTime": 1689729048000
    },
    {
        "time": 1689729049000,
        "value": 138.934,
        "_internal_originalTime": 1689729049000
    },
    {
        "time": 1689729051000,
        "value": 138.932,
        "_internal_originalTime": 1689729051000
    },
    {
        "time": 1689729052000,
        "value": 138.928,
        "_internal_originalTime": 1689729052000
    },
    {
        "time": 1689729054000,
        "value": 138.93,
        "_internal_originalTime": 1689729054000
    },
    {
        "time": 1689729056000,
        "value": 138.93,
        "_internal_originalTime": 1689729056000
    },
    {
        "time": 1689729058000,
        "value": 138.931,
        "_internal_originalTime": 1689729058000
    },
    {
        "time": 1689729059000,
        "value": 138.932,
        "_internal_originalTime": 1689729059000
    },
    {
        "time": 1689729060000,
        "value": 138.929,
        "_internal_originalTime": 1689729060000
    },
    {
        "time": 1689729062000,
        "value": 138.93,
        "_internal_originalTime": 1689729062000
    },
    {
        "time": 1689729064000,
        "value": 138.929,
        "_internal_originalTime": 1689729064000
    },
    {
        "time": 1689729066000,
        "value": 138.932,
        "_internal_originalTime": 1689729066000
    },
    {
        "time": 1689729068000,
        "value": 138.941,
        "_internal_originalTime": 1689729068000
    },
    {
        "time": 1689729069000,
        "value": 138.938,
        "_internal_originalTime": 1689729069000
    },
    {
        "time": 1689729071000,
        "value": 138.945,
        "_internal_originalTime": 1689729071000
    },
    {
        "time": 1689729072000,
        "value": 138.941,
        "_internal_originalTime": 1689729072000
    },
    {
        "time": 1689729075000,
        "value": 138.942,
        "_internal_originalTime": 1689729075000
    },
    {
        "time": 1689729076000,
        "value": 138.941,
        "_internal_originalTime": 1689729076000
    },
    {
        "time": 1689729078000,
        "value": 138.939,
        "_internal_originalTime": 1689729078000
    },
    {
        "time": 1689729079000,
        "value": 138.943,
        "_internal_originalTime": 1689729079000
    },
    {
        "time": 1689729081000,
        "value": 138.938,
        "_internal_originalTime": 1689729081000
    },
    {
        "time": 1689729083000,
        "value": 138.938,
        "_internal_originalTime": 1689729083000
    },
    {
        "time": 1689729085000,
        "value": 138.94,
        "_internal_originalTime": 1689729085000
    },
    {
        "time": 1689729091000,
        "value": 138.939,
        "_internal_originalTime": 1689729091000
    },
    {
        "time": 1689729093000,
        "value": 138.936,
        "_internal_originalTime": 1689729093000
    },
    {
        "time": 1689729095000,
        "value": 138.938,
        "_internal_originalTime": 1689729095000
    },
    {
        "time": 1689729097000,
        "value": 138.93,
        "_internal_originalTime": 1689729097000
    },
    {
        "time": 1689729099000,
        "value": 138.929,
        "_internal_originalTime": 1689729099000
    },
    {
        "time": 1689729100000,
        "value": 138.93,
        "_internal_originalTime": 1689729100000
    },
    {
        "time": 1689729104000,
        "value": 138.928,
        "_internal_originalTime": 1689729104000
    },
    {
        "time": 1689729108000,
        "value": 138.924,
        "_internal_originalTime": 1689729108000
    },
    {
        "time": 1689729110000,
        "value": 138.925,
        "_internal_originalTime": 1689729110000
    },
    {
        "time": 1689729113000,
        "value": 138.922,
        "_internal_originalTime": 1689729113000
    },
    {
        "time": 1689729117000,
        "value": 138.923,
        "_internal_originalTime": 1689729117000
    },
    {
        "time": 1689729119000,
        "value": 138.915,
        "_internal_originalTime": 1689729119000
    },
    {
        "time": 1689729120000,
        "value": 138.912,
        "_internal_originalTime": 1689729120000
    },
    {
        "time": 1689729121000,
        "value": 138.911,
        "_internal_originalTime": 1689729121000
    },
    {
        "time": 1689729123000,
        "value": 138.908,
        "_internal_originalTime": 1689729123000
    },
    {
        "time": 1689729125000,
        "value": 138.905,
        "_internal_originalTime": 1689729125000
    },
    {
        "time": 1689729126000,
        "value": 138.902,
        "_internal_originalTime": 1689729126000
    },
    {
        "time": 1689729128000,
        "value": 138.901,
        "_internal_originalTime": 1689729128000
    },
    {
        "time": 1689729130000,
        "value": 138.903,
        "_internal_originalTime": 1689729130000
    },
    {
        "time": 1689729132000,
        "value": 138.902,
        "_internal_originalTime": 1689729132000
    },
    {
        "time": 1689729134000,
        "value": 138.9,
        "_internal_originalTime": 1689729134000
    },
    {
        "time": 1689729137000,
        "value": 138.902,
        "_internal_originalTime": 1689729137000
    },
    {
        "time": 1689729138000,
        "value": 138.909,
        "_internal_originalTime": 1689729138000
    },
    {
        "time": 1689729141000,
        "value": 138.899,
        "_internal_originalTime": 1689729141000
    },
    {
        "time": 1689729142000,
        "value": 138.901,
        "_internal_originalTime": 1689729142000
    },
    {
        "time": 1689729144000,
        "value": 138.903,
        "_internal_originalTime": 1689729144000
    },
    {
        "time": 1689729146000,
        "value": 138.901,
        "_internal_originalTime": 1689729146000
    },
    {
        "time": 1689729148000,
        "value": 138.901,
        "_internal_originalTime": 1689729148000
    },
    {
        "time": 1689729149000,
        "value": 138.901,
        "_internal_originalTime": 1689729149000
    },
    {
        "time": 1689729151000,
        "value": 138.901,
        "_internal_originalTime": 1689729151000
    },
    {
        "time": 1689729152000,
        "value": 138.9,
        "_internal_originalTime": 1689729152000
    },
    {
        "time": 1689729154000,
        "value": 138.9,
        "_internal_originalTime": 1689729154000
    },
    {
        "time": 1689729156000,
        "value": 138.9,
        "_internal_originalTime": 1689729156000
    },
    {
        "time": 1689729158000,
        "value": 138.9,
        "_internal_originalTime": 1689729158000
    },
    {
        "time": 1689729159000,
        "value": 138.901,
        "_internal_originalTime": 1689729159000
    },
    {
        "time": 1689729164000,
        "value": 138.901,
        "_internal_originalTime": 1689729164000
    },
    {
        "time": 1689729166000,
        "value": 138.9,
        "_internal_originalTime": 1689729166000
    },
    {
        "time": 1689729168000,
        "value": 138.901,
        "_internal_originalTime": 1689729168000
    },
    {
        "time": 1689729169000,
        "value": 138.902,
        "_internal_originalTime": 1689729169000
    },
    {
        "time": 1689729170000,
        "value": 138.9,
        "_internal_originalTime": 1689729170000
    },
    {
        "time": 1689729172000,
        "value": 138.898,
        "_internal_originalTime": 1689729172000
    },
    {
        "time": 1689729174000,
        "value": 138.89,
        "_internal_originalTime": 1689729174000
    },
    {
        "time": 1689729176000,
        "value": 138.892,
        "_internal_originalTime": 1689729176000
    },
    {
        "time": 1689729178000,
        "value": 138.901,
        "_internal_originalTime": 1689729178000
    },
    {
        "time": 1689729179000,
        "value": 138.899,
        "_internal_originalTime": 1689729179000
    },
    {
        "time": 1689729180000,
        "value": 138.897,
        "_internal_originalTime": 1689729180000
    },
    {
        "time": 1689729182000,
        "value": 138.895,
        "_internal_originalTime": 1689729182000
    },
    {
        "time": 1689729184000,
        "value": 138.894,
        "_internal_originalTime": 1689729184000
    },
    {
        "time": 1689729186000,
        "value": 138.896,
        "_internal_originalTime": 1689729186000
    },
    {
        "time": 1689729187000,
        "value": 138.903,
        "_internal_originalTime": 1689729187000
    },
    {
        "time": 1689729189000,
        "value": 138.903,
        "_internal_originalTime": 1689729189000
    },
    {
        "time": 1689729191000,
        "value": 138.901,
        "_internal_originalTime": 1689729191000
    },
    {
        "time": 1689729192000,
        "value": 138.901,
        "_internal_originalTime": 1689729192000
    },
    {
        "time": 1689729194000,
        "value": 138.894,
        "_internal_originalTime": 1689729194000
    },
    {
        "time": 1689729195000,
        "value": 138.895,
        "_internal_originalTime": 1689729195000
    },
    {
        "time": 1689729197000,
        "value": 138.895,
        "_internal_originalTime": 1689729197000
    },
    {
        "time": 1689729199000,
        "value": 138.894,
        "_internal_originalTime": 1689729199000
    },
    {
        "time": 1689729201000,
        "value": 138.892,
        "_internal_originalTime": 1689729201000
    },
    {
        "time": 1689729203000,
        "value": 138.893,
        "_internal_originalTime": 1689729203000
    },
    {
        "time": 1689729207000,
        "value": 138.893,
        "_internal_originalTime": 1689729207000
    },
    {
        "time": 1689729209000,
        "value": 138.892,
        "_internal_originalTime": 1689729209000
    },
    {
        "time": 1689729210000,
        "value": 138.89,
        "_internal_originalTime": 1689729210000
    },
    {
        "time": 1689729212000,
        "value": 138.891,
        "_internal_originalTime": 1689729212000
    },
    {
        "time": 1689729213000,
        "value": 138.892,
        "_internal_originalTime": 1689729213000
    },
    {
        "time": 1689729215000,
        "value": 138.89,
        "_internal_originalTime": 1689729215000
    },
    {
        "time": 1689729216000,
        "value": 138.891,
        "_internal_originalTime": 1689729216000
    },
    {
        "time": 1689729219000,
        "value": 138.891,
        "_internal_originalTime": 1689729219000
    },
    {
        "time": 1689729221000,
        "value": 138.889,
        "_internal_originalTime": 1689729221000
    },
    {
        "time": 1689729223000,
        "value": 138.891,
        "_internal_originalTime": 1689729223000
    },
    {
        "time": 1689729225000,
        "value": 138.893,
        "_internal_originalTime": 1689729225000
    },
    {
        "time": 1689729226000,
        "value": 138.89,
        "_internal_originalTime": 1689729226000
    },
    {
        "time": 1689729227000,
        "value": 138.893,
        "_internal_originalTime": 1689729227000
    },
    {
        "time": 1689729230000,
        "value": 138.892,
        "_internal_originalTime": 1689729230000
    },
    {
        "time": 1689729231000,
        "value": 138.892,
        "_internal_originalTime": 1689729231000
    },
    {
        "time": 1689729234000,
        "value": 138.892,
        "_internal_originalTime": 1689729234000
    },
    {
        "time": 1689729235000,
        "value": 138.891,
        "_internal_originalTime": 1689729235000
    },
    {
        "time": 1689729237000,
        "value": 138.891,
        "_internal_originalTime": 1689729237000
    },
    {
        "time": 1689729238000,
        "value": 138.892,
        "_internal_originalTime": 1689729238000
    },
    {
        "time": 1689729239000,
        "value": 138.892,
        "_internal_originalTime": 1689729239000
    },
    {
        "time": 1689729240000,
        "value": 138.89,
        "_internal_originalTime": 1689729240000
    },
    {
        "time": 1689729241000,
        "value": 138.89,
        "_internal_originalTime": 1689729241000
    },
    {
        "time": 1689729242000,
        "value": 138.892,
        "_internal_originalTime": 1689729242000
    },
    {
        "time": 1689729244000,
        "value": 138.891,
        "_internal_originalTime": 1689729244000
    },
    {
        "time": 1689729246000,
        "value": 138.906,
        "_internal_originalTime": 1689729246000
    },
    {
        "time": 1689729247000,
        "value": 138.906,
        "_internal_originalTime": 1689729247000
    },
    {
        "time": 1689729249000,
        "value": 138.905,
        "_internal_originalTime": 1689729249000
    },
    {
        "time": 1689729252000,
        "value": 138.905,
        "_internal_originalTime": 1689729252000
    },
    {
        "time": 1689729254000,
        "value": 138.912,
        "_internal_originalTime": 1689729254000
    },
    {
        "time": 1689729255000,
        "value": 138.912,
        "_internal_originalTime": 1689729255000
    },
    {
        "time": 1689729257000,
        "value": 138.919,
        "_internal_originalTime": 1689729257000
    },
    {
        "time": 1689729258000,
        "value": 138.914,
        "_internal_originalTime": 1689729258000
    },
    {
        "time": 1689729260000,
        "value": 138.914,
        "_internal_originalTime": 1689729260000
    },
    {
        "time": 1689729262000,
        "value": 138.914,
        "_internal_originalTime": 1689729262000
    },
    {
        "time": 1689729264000,
        "value": 138.914,
        "_internal_originalTime": 1689729264000
    },
    {
        "time": 1689729267000,
        "value": 138.916,
        "_internal_originalTime": 1689729267000
    },
    {
        "time": 1689729268000,
        "value": 138.913,
        "_internal_originalTime": 1689729268000
    },
    {
        "time": 1689729271000,
        "value": 138.908,
        "_internal_originalTime": 1689729271000
    },
    {
        "time": 1689729273000,
        "value": 138.912,
        "_internal_originalTime": 1689729273000
    },
    {
        "time": 1689729274000,
        "value": 138.91,
        "_internal_originalTime": 1689729274000
    },
    {
        "time": 1689729276000,
        "value": 138.91,
        "_internal_originalTime": 1689729276000
    },
    {
        "time": 1689729278000,
        "value": 138.91,
        "_internal_originalTime": 1689729278000
    },
    {
        "time": 1689729280000,
        "value": 138.934,
        "_internal_originalTime": 1689729280000
    },
    {
        "time": 1689729281000,
        "value": 138.932,
        "_internal_originalTime": 1689729281000
    },
    {
        "time": 1689729284000,
        "value": 138.933,
        "_internal_originalTime": 1689729284000
    },
    {
        "time": 1689729285000,
        "value": 138.941,
        "_internal_originalTime": 1689729285000
    },
    {
        "time": 1689729286000,
        "value": 138.941,
        "_internal_originalTime": 1689729286000
    },
    {
        "time": 1689729288000,
        "value": 138.941,
        "_internal_originalTime": 1689729288000
    },
    {
        "time": 1689729290000,
        "value": 138.947,
        "_internal_originalTime": 1689729290000
    },
    {
        "time": 1689729292000,
        "value": 138.943,
        "_internal_originalTime": 1689729292000
    },
    {
        "time": 1689729293000,
        "value": 138.943,
        "_internal_originalTime": 1689729293000
    },
    {
        "time": 1689729295000,
        "value": 138.943,
        "_internal_originalTime": 1689729295000
    },
    {
        "time": 1689729297000,
        "value": 138.939,
        "_internal_originalTime": 1689729297000
    },
    {
        "time": 1689729299000,
        "value": 138.938,
        "_internal_originalTime": 1689729299000
    },
    {
        "time": 1689729300000,
        "value": 138.932,
        "_internal_originalTime": 1689729300000
    },
    {
        "time": 1689729301000,
        "value": 138.938,
        "_internal_originalTime": 1689729301000
    },
    {
        "time": 1689729303000,
        "value": 138.94,
        "_internal_originalTime": 1689729303000
    },
    {
        "time": 1689729306000,
        "value": 138.937,
        "_internal_originalTime": 1689729306000
    },
    {
        "time": 1689729307000,
        "value": 138.935,
        "_internal_originalTime": 1689729307000
    },
    {
        "time": 1689729310000,
        "value": 138.936,
        "_internal_originalTime": 1689729310000
    },
    {
        "time": 1689729311000,
        "value": 138.933,
        "_internal_originalTime": 1689729311000
    },
    {
        "time": 1689729312000,
        "value": 138.932,
        "_internal_originalTime": 1689729312000
    },
    {
        "time": 1689729315000,
        "value": 138.931,
        "_internal_originalTime": 1689729315000
    },
    {
        "time": 1689729318000,
        "value": 138.935,
        "_internal_originalTime": 1689729318000
    },
    {
        "time": 1689729320000,
        "value": 138.942,
        "_internal_originalTime": 1689729320000
    },
    {
        "time": 1689729322000,
        "value": 138.935,
        "_internal_originalTime": 1689729322000
    },
    {
        "time": 1689729324000,
        "value": 138.933,
        "_internal_originalTime": 1689729324000
    },
    {
        "time": 1689729326000,
        "value": 138.93,
        "_internal_originalTime": 1689729326000
    },
    {
        "time": 1689729327000,
        "value": 138.934,
        "_internal_originalTime": 1689729327000
    },
    {
        "time": 1689729329000,
        "value": 138.932,
        "_internal_originalTime": 1689729329000
    },
    {
        "time": 1689729332000,
        "value": 138.93,
        "_internal_originalTime": 1689729332000
    },
    {
        "time": 1689729334000,
        "value": 138.923,
        "_internal_originalTime": 1689729334000
    },
    {
        "time": 1689729336000,
        "value": 138.923,
        "_internal_originalTime": 1689729336000
    },
    {
        "time": 1689729337000,
        "value": 138.922,
        "_internal_originalTime": 1689729337000
    },
    {
        "time": 1689729340000,
        "value": 138.918,
        "_internal_originalTime": 1689729340000
    },
    {
        "time": 1689729342000,
        "value": 138.92,
        "_internal_originalTime": 1689729342000
    },
    {
        "time": 1689729344000,
        "value": 138.921,
        "_internal_originalTime": 1689729344000
    },
    {
        "time": 1689729345000,
        "value": 138.921,
        "_internal_originalTime": 1689729345000
    },
    {
        "time": 1689729347000,
        "value": 138.922,
        "_internal_originalTime": 1689729347000
    },
    {
        "time": 1689729349000,
        "value": 138.918,
        "_internal_originalTime": 1689729349000
    },
    {
        "time": 1689729351000,
        "value": 138.92,
        "_internal_originalTime": 1689729351000
    },
    {
        "time": 1689729352000,
        "value": 138.918,
        "_internal_originalTime": 1689729352000
    },
    {
        "time": 1689729355000,
        "value": 138.92,
        "_internal_originalTime": 1689729355000
    },
    {
        "time": 1689729356000,
        "value": 138.918,
        "_internal_originalTime": 1689729356000
    },
    {
        "time": 1689729358000,
        "value": 138.919,
        "_internal_originalTime": 1689729358000
    },
    {
        "time": 1689729359000,
        "value": 138.914,
        "_internal_originalTime": 1689729359000
    },
    {
        "time": 1689729361000,
        "value": 138.925,
        "_internal_originalTime": 1689729361000
    },
    {
        "time": 1689729362000,
        "value": 138.925,
        "_internal_originalTime": 1689729362000
    },
    {
        "time": 1689729365000,
        "value": 138.928,
        "_internal_originalTime": 1689729365000
    },
    {
        "time": 1689729367000,
        "value": 138.916,
        "_internal_originalTime": 1689729367000
    },
    {
        "time": 1689729368000,
        "value": 138.92,
        "_internal_originalTime": 1689729368000
    },
    {
        "time": 1689729371000,
        "value": 138.923,
        "_internal_originalTime": 1689729371000
    },
    {
        "time": 1689729372000,
        "value": 138.931,
        "_internal_originalTime": 1689729372000
    },
    {
        "time": 1689729374000,
        "value": 138.93,
        "_internal_originalTime": 1689729374000
    },
    {
        "time": 1689729375000,
        "value": 138.929,
        "_internal_originalTime": 1689729375000
    },
    {
        "time": 1689729377000,
        "value": 138.932,
        "_internal_originalTime": 1689729377000
    },
    {
        "time": 1689729379000,
        "value": 138.931,
        "_internal_originalTime": 1689729379000
    },
    {
        "time": 1689729381000,
        "value": 138.933,
        "_internal_originalTime": 1689729381000
    },
    {
        "time": 1689729383000,
        "value": 138.934,
        "_internal_originalTime": 1689729383000
    },
    {
        "time": 1689729385000,
        "value": 138.931,
        "_internal_originalTime": 1689729385000
    },
    {
        "time": 1689729387000,
        "value": 138.93,
        "_internal_originalTime": 1689729387000
    },
    {
        "time": 1689729389000,
        "value": 138.93,
        "_internal_originalTime": 1689729389000
    },
    {
        "time": 1689729390000,
        "value": 138.93,
        "_internal_originalTime": 1689729390000
    },
    {
        "time": 1689729392000,
        "value": 138.93,
        "_internal_originalTime": 1689729392000
    },
    {
        "time": 1689729395000,
        "value": 138.938,
        "_internal_originalTime": 1689729395000
    },
    {
        "time": 1689729397000,
        "value": 138.937,
        "_internal_originalTime": 1689729397000
    },
    {
        "time": 1689729398000,
        "value": 138.93,
        "_internal_originalTime": 1689729398000
    },
    {
        "time": 1689729401000,
        "value": 138.93,
        "_internal_originalTime": 1689729401000
    },
    {
        "time": 1689729403000,
        "value": 138.931,
        "_internal_originalTime": 1689729403000
    },
    {
        "time": 1689729404000,
        "value": 138.93,
        "_internal_originalTime": 1689729404000
    },
    {
        "time": 1689729406000,
        "value": 138.929,
        "_internal_originalTime": 1689729406000
    },
    {
        "time": 1689729409000,
        "value": 138.913,
        "_internal_originalTime": 1689729409000
    },
    {
        "time": 1689729411000,
        "value": 138.913,
        "_internal_originalTime": 1689729411000
    },
    {
        "time": 1689729412000,
        "value": 138.912,
        "_internal_originalTime": 1689729412000
    },
    {
        "time": 1689729414000,
        "value": 138.915,
        "_internal_originalTime": 1689729414000
    },
    {
        "time": 1689729421000,
        "value": 138.911,
        "_internal_originalTime": 1689729421000
    },
    {
        "time": 1689729423000,
        "value": 138.91,
        "_internal_originalTime": 1689729423000
    },
    {
        "time": 1689729424000,
        "value": 138.912,
        "_internal_originalTime": 1689729424000
    },
    {
        "time": 1689729427000,
        "value": 138.912,
        "_internal_originalTime": 1689729427000
    },
    {
        "time": 1689729429000,
        "value": 138.912,
        "_internal_originalTime": 1689729429000
    },
    {
        "time": 1689729431000,
        "value": 138.912,
        "_internal_originalTime": 1689729431000
    },
    {
        "time": 1689729433000,
        "value": 138.914,
        "_internal_originalTime": 1689729433000
    },
    {
        "time": 1689729434000,
        "value": 138.91,
        "_internal_originalTime": 1689729434000
    },
    {
        "time": 1689729437000,
        "value": 138.911,
        "_internal_originalTime": 1689729437000
    },
    {
        "time": 1689729439000,
        "value": 138.904,
        "_internal_originalTime": 1689729439000
    },
    {
        "time": 1689729441000,
        "value": 138.902,
        "_internal_originalTime": 1689729441000
    },
    {
        "time": 1689729442000,
        "value": 138.9,
        "_internal_originalTime": 1689729442000
    },
    {
        "time": 1689729445000,
        "value": 138.906,
        "_internal_originalTime": 1689729445000
    },
    {
        "time": 1689729447000,
        "value": 138.902,
        "_internal_originalTime": 1689729447000
    },
    {
        "time": 1689729448000,
        "value": 138.905,
        "_internal_originalTime": 1689729448000
    },
    {
        "time": 1689729450000,
        "value": 138.903,
        "_internal_originalTime": 1689729450000
    },
    {
        "time": 1689729452000,
        "value": 138.912,
        "_internal_originalTime": 1689729452000
    },
    {
        "time": 1689729454000,
        "value": 138.911,
        "_internal_originalTime": 1689729454000
    },
    {
        "time": 1689729456000,
        "value": 138.915,
        "_internal_originalTime": 1689729456000
    },
    {
        "time": 1689729459000,
        "value": 138.914,
        "_internal_originalTime": 1689729459000
    },
    {
        "time": 1689729461000,
        "value": 138.911,
        "_internal_originalTime": 1689729461000
    },
    {
        "time": 1689729463000,
        "value": 138.908,
        "_internal_originalTime": 1689729463000
    },
    {
        "time": 1689729467000,
        "value": 138.91,
        "_internal_originalTime": 1689729467000
    },
    {
        "time": 1689729469000,
        "value": 138.907,
        "_internal_originalTime": 1689729469000
    },
    {
        "time": 1689729470000,
        "value": 138.91,
        "_internal_originalTime": 1689729470000
    },
    {
        "time": 1689729472000,
        "value": 138.906,
        "_internal_originalTime": 1689729472000
    },
    {
        "time": 1689729475000,
        "value": 138.907,
        "_internal_originalTime": 1689729475000
    },
    {
        "time": 1689729477000,
        "value": 138.907,
        "_internal_originalTime": 1689729477000
    },
    {
        "time": 1689729479000,
        "value": 138.907,
        "_internal_originalTime": 1689729479000
    },
    {
        "time": 1689729481000,
        "value": 138.901,
        "_internal_originalTime": 1689729481000
    },
    {
        "time": 1689729483000,
        "value": 138.901,
        "_internal_originalTime": 1689729483000
    },
    {
        "time": 1689729486000,
        "value": 138.9,
        "_internal_originalTime": 1689729486000
    },
    {
        "time": 1689729488000,
        "value": 138.899,
        "_internal_originalTime": 1689729488000
    },
    {
        "time": 1689729489000,
        "value": 138.898,
        "_internal_originalTime": 1689729489000
    },
    {
        "time": 1689729490000,
        "value": 138.896,
        "_internal_originalTime": 1689729490000
    },
    {
        "time": 1689729493000,
        "value": 138.902,
        "_internal_originalTime": 1689729493000
    },
    {
        "time": 1689729495000,
        "value": 138.898,
        "_internal_originalTime": 1689729495000
    },
    {
        "time": 1689729497000,
        "value": 138.899,
        "_internal_originalTime": 1689729497000
    },
    {
        "time": 1689729499000,
        "value": 138.898,
        "_internal_originalTime": 1689729499000
    },
    {
        "time": 1689729501000,
        "value": 138.897,
        "_internal_originalTime": 1689729501000
    },
    {
        "time": 1689729504000,
        "value": 138.899,
        "_internal_originalTime": 1689729504000
    },
    {
        "time": 1689729506000,
        "value": 138.899,
        "_internal_originalTime": 1689729506000
    },
    {
        "time": 1689729508000,
        "value": 138.901,
        "_internal_originalTime": 1689729508000
    },
    {
        "time": 1689729509000,
        "value": 138.902,
        "_internal_originalTime": 1689729509000
    },
    {
        "time": 1689729511000,
        "value": 138.897,
        "_internal_originalTime": 1689729511000
    },
    {
        "time": 1689729513000,
        "value": 138.898,
        "_internal_originalTime": 1689729513000
    },
    {
        "time": 1689729514000,
        "value": 138.898,
        "_internal_originalTime": 1689729514000
    },
    {
        "time": 1689729515000,
        "value": 138.897,
        "_internal_originalTime": 1689729515000
    },
    {
        "time": 1689729517000,
        "value": 138.899,
        "_internal_originalTime": 1689729517000
    },
    {
        "time": 1689729522000,
        "value": 138.884,
        "_internal_originalTime": 1689729522000
    },
    {
        "time": 1689729524000,
        "value": 138.882,
        "_internal_originalTime": 1689729524000
    },
    {
        "time": 1689729525000,
        "value": 138.882,
        "_internal_originalTime": 1689729525000
    },
    {
        "time": 1689729527000,
        "value": 138.884,
        "_internal_originalTime": 1689729527000
    },
    {
        "time": 1689729529000,
        "value": 138.884,
        "_internal_originalTime": 1689729529000
    },
    {
        "time": 1689729531000,
        "value": 138.883,
        "_internal_originalTime": 1689729531000
    },
    {
        "time": 1689729532000,
        "value": 138.887,
        "_internal_originalTime": 1689729532000
    },
    {
        "time": 1689729534000,
        "value": 138.884,
        "_internal_originalTime": 1689729534000
    },
    {
        "time": 1689729535000,
        "value": 138.886,
        "_internal_originalTime": 1689729535000
    },
    {
        "time": 1689729538000,
        "value": 138.893,
        "_internal_originalTime": 1689729538000
    },
    {
        "time": 1689729539000,
        "value": 138.897,
        "_internal_originalTime": 1689729539000
    },
    {
        "time": 1689729540000,
        "value": 138.895,
        "_internal_originalTime": 1689729540000
    },
    {
        "time": 1689729541000,
        "value": 138.88,
        "_internal_originalTime": 1689729541000
    },
    {
        "time": 1689729542000,
        "value": 138.892,
        "_internal_originalTime": 1689729542000
    },
    {
        "time": 1689729543000,
        "value": 138.895,
        "_internal_originalTime": 1689729543000
    },
    {
        "time": 1689729546000,
        "value": 138.896,
        "_internal_originalTime": 1689729546000
    },
    {
        "time": 1689729547000,
        "value": 138.891,
        "_internal_originalTime": 1689729547000
    },
    {
        "time": 1689729550000,
        "value": 138.891,
        "_internal_originalTime": 1689729550000
    },
    {
        "time": 1689729551000,
        "value": 138.891,
        "_internal_originalTime": 1689729551000
    },
    {
        "time": 1689729554000,
        "value": 138.894,
        "_internal_originalTime": 1689729554000
    },
    {
        "time": 1689729555000,
        "value": 138.893,
        "_internal_originalTime": 1689729555000
    },
    {
        "time": 1689729557000,
        "value": 138.899,
        "_internal_originalTime": 1689729557000
    },
    {
        "time": 1689729559000,
        "value": 138.898,
        "_internal_originalTime": 1689729559000
    },
    {
        "time": 1689729561000,
        "value": 138.898,
        "_internal_originalTime": 1689729561000
    },
    {
        "time": 1689729563000,
        "value": 138.898,
        "_internal_originalTime": 1689729563000
    },
    {
        "time": 1689729567000,
        "value": 138.902,
        "_internal_originalTime": 1689729567000
    },
    {
        "time": 1689729569000,
        "value": 138.901,
        "_internal_originalTime": 1689729569000
    },
    {
        "time": 1689729571000,
        "value": 138.897,
        "_internal_originalTime": 1689729571000
    },
    {
        "time": 1689729572000,
        "value": 138.911,
        "_internal_originalTime": 1689729572000
    },
    {
        "time": 1689729574000,
        "value": 138.918,
        "_internal_originalTime": 1689729574000
    },
    {
        "time": 1689729576000,
        "value": 138.919,
        "_internal_originalTime": 1689729576000
    },
    {
        "time": 1689729578000,
        "value": 138.929,
        "_internal_originalTime": 1689729578000
    },
    {
        "time": 1689729580000,
        "value": 138.926,
        "_internal_originalTime": 1689729580000
    },
    {
        "time": 1689729582000,
        "value": 138.923,
        "_internal_originalTime": 1689729582000
    },
    {
        "time": 1689729584000,
        "value": 138.927,
        "_internal_originalTime": 1689729584000
    },
    {
        "time": 1689729586000,
        "value": 138.922,
        "_internal_originalTime": 1689729586000
    },
    {
        "time": 1689729588000,
        "value": 138.921,
        "_internal_originalTime": 1689729588000
    },
    {
        "time": 1689729589000,
        "value": 138.932,
        "_internal_originalTime": 1689729589000
    },
    {
        "time": 1689729590000,
        "value": 138.932,
        "_internal_originalTime": 1689729590000
    },
    {
        "time": 1689729593000,
        "value": 138.924,
        "_internal_originalTime": 1689729593000
    },
    {
        "time": 1689729595000,
        "value": 138.93,
        "_internal_originalTime": 1689729595000
    },
    {
        "time": 1689729596000,
        "value": 138.929,
        "_internal_originalTime": 1689729596000
    },
    {
        "time": 1689729598000,
        "value": 138.929,
        "_internal_originalTime": 1689729598000
    },
    {
        "time": 1689729601000,
        "value": 138.927,
        "_internal_originalTime": 1689729601000
    },
    {
        "time": 1689729602000,
        "value": 138.929,
        "_internal_originalTime": 1689729602000
    },
    {
        "time": 1689729605000,
        "value": 138.927,
        "_internal_originalTime": 1689729605000
    },
    {
        "time": 1689729607000,
        "value": 138.93,
        "_internal_originalTime": 1689729607000
    },
    {
        "time": 1689729610000,
        "value": 138.92,
        "_internal_originalTime": 1689729610000
    },
    {
        "time": 1689729612000,
        "value": 138.911,
        "_internal_originalTime": 1689729612000
    },
    {
        "time": 1689729613000,
        "value": 138.909,
        "_internal_originalTime": 1689729613000
    },
    {
        "time": 1689729615000,
        "value": 138.919,
        "_internal_originalTime": 1689729615000
    },
    {
        "time": 1689729617000,
        "value": 138.921,
        "_internal_originalTime": 1689729617000
    },
    {
        "time": 1689729618000,
        "value": 138.916,
        "_internal_originalTime": 1689729618000
    },
    {
        "time": 1689729620000,
        "value": 138.914,
        "_internal_originalTime": 1689729620000
    },
    {
        "time": 1689729623000,
        "value": 138.913,
        "_internal_originalTime": 1689729623000
    },
    {
        "time": 1689729624000,
        "value": 138.911,
        "_internal_originalTime": 1689729624000
    },
    {
        "time": 1689729627000,
        "value": 138.913,
        "_internal_originalTime": 1689729627000
    },
    {
        "time": 1689729629000,
        "value": 138.921,
        "_internal_originalTime": 1689729629000
    },
    {
        "time": 1689729632000,
        "value": 138.929,
        "_internal_originalTime": 1689729632000
    },
    {
        "time": 1689729633000,
        "value": 138.926,
        "_internal_originalTime": 1689729633000
    },
    {
        "time": 1689729634000,
        "value": 138.929,
        "_internal_originalTime": 1689729634000
    },
    {
        "time": 1689729636000,
        "value": 138.93,
        "_internal_originalTime": 1689729636000
    },
    {
        "time": 1689729638000,
        "value": 138.93,
        "_internal_originalTime": 1689729638000
    },
    {
        "time": 1689729641000,
        "value": 138.925,
        "_internal_originalTime": 1689729641000
    },
    {
        "time": 1689729643000,
        "value": 138.931,
        "_internal_originalTime": 1689729643000
    },
    {
        "time": 1689729645000,
        "value": 138.94,
        "_internal_originalTime": 1689729645000
    },
    {
        "time": 1689729647000,
        "value": 138.94,
        "_internal_originalTime": 1689729647000
    },
    {
        "time": 1689729648000,
        "value": 138.94,
        "_internal_originalTime": 1689729648000
    },
    {
        "time": 1689729650000,
        "value": 138.937,
        "_internal_originalTime": 1689729650000
    },
    {
        "time": 1689729652000,
        "value": 138.943,
        "_internal_originalTime": 1689729652000
    },
    {
        "time": 1689729653000,
        "value": 138.941,
        "_internal_originalTime": 1689729653000
    },
    {
        "time": 1689729655000,
        "value": 138.939,
        "_internal_originalTime": 1689729655000
    },
    {
        "time": 1689729657000,
        "value": 138.942,
        "_internal_originalTime": 1689729657000
    },
    {
        "time": 1689729658000,
        "value": 138.938,
        "_internal_originalTime": 1689729658000
    },
    {
        "time": 1689729660000,
        "value": 138.941,
        "_internal_originalTime": 1689729660000
    },
    {
        "time": 1689729662000,
        "value": 138.942,
        "_internal_originalTime": 1689729662000
    },
    {
        "time": 1689729664000,
        "value": 138.94,
        "_internal_originalTime": 1689729664000
    },
    {
        "time": 1689729666000,
        "value": 138.936,
        "_internal_originalTime": 1689729666000
    },
    {
        "time": 1689729668000,
        "value": 138.939,
        "_internal_originalTime": 1689729668000
    },
    {
        "time": 1689729669000,
        "value": 138.937,
        "_internal_originalTime": 1689729669000
    },
    {
        "time": 1689729670000,
        "value": 138.932,
        "_internal_originalTime": 1689729670000
    },
    {
        "time": 1689729672000,
        "value": 138.934,
        "_internal_originalTime": 1689729672000
    },
    {
        "time": 1689729674000,
        "value": 138.934,
        "_internal_originalTime": 1689729674000
    },
    {
        "time": 1689729678000,
        "value": 138.93,
        "_internal_originalTime": 1689729678000
    },
    {
        "time": 1689729680000,
        "value": 138.926,
        "_internal_originalTime": 1689729680000
    },
    {
        "time": 1689729682000,
        "value": 138.928,
        "_internal_originalTime": 1689729682000
    },
    {
        "time": 1689729685000,
        "value": 138.922,
        "_internal_originalTime": 1689729685000
    },
    {
        "time": 1689729686000,
        "value": 138.923,
        "_internal_originalTime": 1689729686000
    },
    {
        "time": 1689729688000,
        "value": 138.922,
        "_internal_originalTime": 1689729688000
    },
    {
        "time": 1689729689000,
        "value": 138.921,
        "_internal_originalTime": 1689729689000
    },
    {
        "time": 1689729691000,
        "value": 138.923,
        "_internal_originalTime": 1689729691000
    },
    {
        "time": 1689729693000,
        "value": 138.921,
        "_internal_originalTime": 1689729693000
    },
    {
        "time": 1689729694000,
        "value": 138.923,
        "_internal_originalTime": 1689729694000
    },
    {
        "time": 1689729696000,
        "value": 138.922,
        "_internal_originalTime": 1689729696000
    },
    {
        "time": 1689729699000,
        "value": 138.921,
        "_internal_originalTime": 1689729699000
    },
    {
        "time": 1689729703000,
        "value": 138.919,
        "_internal_originalTime": 1689729703000
    },
    {
        "time": 1689729705000,
        "value": 138.919,
        "_internal_originalTime": 1689729705000
    },
    {
        "time": 1689729708000,
        "value": 138.934,
        "_internal_originalTime": 1689729708000
    },
    {
        "time": 1689729709000,
        "value": 138.929,
        "_internal_originalTime": 1689729709000
    },
    {
        "time": 1689729711000,
        "value": 138.927,
        "_internal_originalTime": 1689729711000
    },
    {
        "time": 1689729712000,
        "value": 138.921,
        "_internal_originalTime": 1689729712000
    },
    {
        "time": 1689729715000,
        "value": 138.927,
        "_internal_originalTime": 1689729715000
    },
    {
        "time": 1689729716000,
        "value": 138.926,
        "_internal_originalTime": 1689729716000
    },
    {
        "time": 1689729718000,
        "value": 138.931,
        "_internal_originalTime": 1689729718000
    },
    {
        "time": 1689729719000,
        "value": 138.934,
        "_internal_originalTime": 1689729719000
    },
    {
        "time": 1689729720000,
        "value": 138.934,
        "_internal_originalTime": 1689729720000
    },
    {
        "time": 1689729722000,
        "value": 138.942,
        "_internal_originalTime": 1689729722000
    },
    {
        "time": 1689729724000,
        "value": 138.934,
        "_internal_originalTime": 1689729724000
    },
    {
        "time": 1689729725000,
        "value": 138.928,
        "_internal_originalTime": 1689729725000
    },
    {
        "time": 1689729728000,
        "value": 138.933,
        "_internal_originalTime": 1689729728000
    },
    {
        "time": 1689729730000,
        "value": 138.936,
        "_internal_originalTime": 1689729730000
    },
    {
        "time": 1689729732000,
        "value": 138.937,
        "_internal_originalTime": 1689729732000
    },
    {
        "time": 1689729734000,
        "value": 138.934,
        "_internal_originalTime": 1689729734000
    },
    {
        "time": 1689729736000,
        "value": 138.936,
        "_internal_originalTime": 1689729736000
    },
    {
        "time": 1689729738000,
        "value": 138.941,
        "_internal_originalTime": 1689729738000
    },
    {
        "time": 1689729739000,
        "value": 138.938,
        "_internal_originalTime": 1689729739000
    },
    {
        "time": 1689729741000,
        "value": 138.936,
        "_internal_originalTime": 1689729741000
    },
    {
        "time": 1689729743000,
        "value": 138.939,
        "_internal_originalTime": 1689729743000
    },
    {
        "time": 1689729745000,
        "value": 138.935,
        "_internal_originalTime": 1689729745000
    },
    {
        "time": 1689729749000,
        "value": 138.938,
        "_internal_originalTime": 1689729749000
    },
    {
        "time": 1689729753000,
        "value": 138.939,
        "_internal_originalTime": 1689729753000
    },
    {
        "time": 1689729755000,
        "value": 138.937,
        "_internal_originalTime": 1689729755000
    },
    {
        "time": 1689729756000,
        "value": 138.935,
        "_internal_originalTime": 1689729756000
    },
    {
        "time": 1689729760000,
        "value": 138.936,
        "_internal_originalTime": 1689729760000
    },
    {
        "time": 1689729763000,
        "value": 138.937,
        "_internal_originalTime": 1689729763000
    },
    {
        "time": 1689729765000,
        "value": 138.941,
        "_internal_originalTime": 1689729765000
    },
    {
        "time": 1689729767000,
        "value": 138.936,
        "_internal_originalTime": 1689729767000
    },
    {
        "time": 1689729768000,
        "value": 138.934,
        "_internal_originalTime": 1689729768000
    },
    {
        "time": 1689729770000,
        "value": 138.936,
        "_internal_originalTime": 1689729770000
    },
    {
        "time": 1689729771000,
        "value": 138.934,
        "_internal_originalTime": 1689729771000
    },
    {
        "time": 1689729774000,
        "value": 138.934,
        "_internal_originalTime": 1689729774000
    },
    {
        "time": 1689729775000,
        "value": 138.936,
        "_internal_originalTime": 1689729775000
    },
    {
        "time": 1689729777000,
        "value": 138.934,
        "_internal_originalTime": 1689729777000
    },
    {
        "time": 1689729779000,
        "value": 138.933,
        "_internal_originalTime": 1689729779000
    },
    {
        "time": 1689729780000,
        "value": 138.924,
        "_internal_originalTime": 1689729780000
    },
    {
        "time": 1689729782000,
        "value": 138.925,
        "_internal_originalTime": 1689729782000
    },
    {
        "time": 1689729783000,
        "value": 138.923,
        "_internal_originalTime": 1689729783000
    },
    {
        "time": 1689729785000,
        "value": 138.922,
        "_internal_originalTime": 1689729785000
    },
    {
        "time": 1689729787000,
        "value": 138.924,
        "_internal_originalTime": 1689729787000
    },
    {
        "time": 1689729789000,
        "value": 138.932,
        "_internal_originalTime": 1689729789000
    },
    {
        "time": 1689729793000,
        "value": 138.933,
        "_internal_originalTime": 1689729793000
    },
    {
        "time": 1689729795000,
        "value": 138.931,
        "_internal_originalTime": 1689729795000
    },
    {
        "time": 1689729796000,
        "value": 138.932,
        "_internal_originalTime": 1689729796000
    },
    {
        "time": 1689729799000,
        "value": 138.93,
        "_internal_originalTime": 1689729799000
    },
    {
        "time": 1689729800000,
        "value": 138.93,
        "_internal_originalTime": 1689729800000
    },
    {
        "time": 1689729802000,
        "value": 138.928,
        "_internal_originalTime": 1689729802000
    },
    {
        "time": 1689729804000,
        "value": 138.931,
        "_internal_originalTime": 1689729804000
    },
    {
        "time": 1689729806000,
        "value": 138.94,
        "_internal_originalTime": 1689729806000
    },
    {
        "time": 1689729808000,
        "value": 138.94,
        "_internal_originalTime": 1689729808000
    },
    {
        "time": 1689729812000,
        "value": 138.94,
        "_internal_originalTime": 1689729812000
    },
    {
        "time": 1689729815000,
        "value": 138.941,
        "_internal_originalTime": 1689729815000
    },
    {
        "time": 1689729816000,
        "value": 138.937,
        "_internal_originalTime": 1689729816000
    },
    {
        "time": 1689729820000,
        "value": 138.94,
        "_internal_originalTime": 1689729820000
    },
    {
        "time": 1689729822000,
        "value": 138.938,
        "_internal_originalTime": 1689729822000
    },
    {
        "time": 1689729824000,
        "value": 138.938,
        "_internal_originalTime": 1689729824000
    },
    {
        "time": 1689729825000,
        "value": 138.94,
        "_internal_originalTime": 1689729825000
    },
    {
        "time": 1689729828000,
        "value": 138.94,
        "_internal_originalTime": 1689729828000
    },
    {
        "time": 1689729830000,
        "value": 138.941,
        "_internal_originalTime": 1689729830000
    },
    {
        "time": 1689729834000,
        "value": 138.942,
        "_internal_originalTime": 1689729834000
    },
    {
        "time": 1689729838000,
        "value": 138.94,
        "_internal_originalTime": 1689729838000
    },
    {
        "time": 1689729840000,
        "value": 138.94,
        "_internal_originalTime": 1689729840000
    },
    {
        "time": 1689729842000,
        "value": 138.94,
        "_internal_originalTime": 1689729842000
    },
    {
        "time": 1689729845000,
        "value": 138.939,
        "_internal_originalTime": 1689729845000
    },
    {
        "time": 1689729847000,
        "value": 138.94,
        "_internal_originalTime": 1689729847000
    },
    {
        "time": 1689729848000,
        "value": 138.939,
        "_internal_originalTime": 1689729848000
    },
    {
        "time": 1689729850000,
        "value": 138.94,
        "_internal_originalTime": 1689729850000
    },
    {
        "time": 1689729853000,
        "value": 138.94,
        "_internal_originalTime": 1689729853000
    },
    {
        "time": 1689729855000,
        "value": 138.94,
        "_internal_originalTime": 1689729855000
    },
    {
        "time": 1689729856000,
        "value": 138.94,
        "_internal_originalTime": 1689729856000
    },
    {
        "time": 1689729859000,
        "value": 138.951,
        "_internal_originalTime": 1689729859000
    },
    {
        "time": 1689729860000,
        "value": 138.95,
        "_internal_originalTime": 1689729860000
    },
    {
        "time": 1689729862000,
        "value": 138.95,
        "_internal_originalTime": 1689729862000
    },
    {
        "time": 1689729864000,
        "value": 138.952,
        "_internal_originalTime": 1689729864000
    },
    {
        "time": 1689729866000,
        "value": 138.952,
        "_internal_originalTime": 1689729866000
    },
    {
        "time": 1689729868000,
        "value": 138.953,
        "_internal_originalTime": 1689729868000
    },
    {
        "time": 1689729872000,
        "value": 138.954,
        "_internal_originalTime": 1689729872000
    },
    {
        "time": 1689729874000,
        "value": 138.968,
        "_internal_originalTime": 1689729874000
    },
    {
        "time": 1689729876000,
        "value": 138.977,
        "_internal_originalTime": 1689729876000
    },
    {
        "time": 1689729878000,
        "value": 138.982,
        "_internal_originalTime": 1689729878000
    },
    {
        "time": 1689729880000,
        "value": 138.986,
        "_internal_originalTime": 1689729880000
    },
    {
        "time": 1689729882000,
        "value": 138.986,
        "_internal_originalTime": 1689729882000
    },
    {
        "time": 1689729884000,
        "value": 138.984,
        "_internal_originalTime": 1689729884000
    },
    {
        "time": 1689729885000,
        "value": 138.982,
        "_internal_originalTime": 1689729885000
    },
    {
        "time": 1689729887000,
        "value": 138.976,
        "_internal_originalTime": 1689729887000
    },
    {
        "time": 1689729889000,
        "value": 138.97,
        "_internal_originalTime": 1689729889000
    },
    {
        "time": 1689729891000,
        "value": 138.971,
        "_internal_originalTime": 1689729891000
    },
    {
        "time": 1689729893000,
        "value": 138.967,
        "_internal_originalTime": 1689729893000
    },
    {
        "time": 1689729894000,
        "value": 138.971,
        "_internal_originalTime": 1689729894000
    },
    {
        "time": 1689729896000,
        "value": 138.973,
        "_internal_originalTime": 1689729896000
    },
    {
        "time": 1689729898000,
        "value": 138.987,
        "_internal_originalTime": 1689729898000
    },
    {
        "time": 1689729900000,
        "value": 138.989,
        "_internal_originalTime": 1689729900000
    },
    {
        "time": 1689729901000,
        "value": 138.988,
        "_internal_originalTime": 1689729901000
    },
    {
        "time": 1689729903000,
        "value": 138.984,
        "_internal_originalTime": 1689729903000
    },
    {
        "time": 1689729904000,
        "value": 138.977,
        "_internal_originalTime": 1689729904000
    },
    {
        "time": 1689729906000,
        "value": 138.988,
        "_internal_originalTime": 1689729906000
    },
    {
        "time": 1689729908000,
        "value": 138.98,
        "_internal_originalTime": 1689729908000
    },
    {
        "time": 1689729910000,
        "value": 138.981,
        "_internal_originalTime": 1689729910000
    },
    {
        "time": 1689729912000,
        "value": 138.981,
        "_internal_originalTime": 1689729912000
    },
    {
        "time": 1689729914000,
        "value": 138.98,
        "_internal_originalTime": 1689729914000
    },
    {
        "time": 1689729915000,
        "value": 138.978,
        "_internal_originalTime": 1689729915000
    },
    {
        "time": 1689729917000,
        "value": 138.984,
        "_internal_originalTime": 1689729917000
    },
    {
        "time": 1689729919000,
        "value": 138.97,
        "_internal_originalTime": 1689729919000
    },
    {
        "time": 1689729921000,
        "value": 138.982,
        "_internal_originalTime": 1689729921000
    },
    {
        "time": 1689729923000,
        "value": 138.981,
        "_internal_originalTime": 1689729923000
    },
    {
        "time": 1689729924000,
        "value": 138.984,
        "_internal_originalTime": 1689729924000
    },
    {
        "time": 1689729926000,
        "value": 138.98,
        "_internal_originalTime": 1689729926000
    },
    {
        "time": 1689729932000,
        "value": 138.983,
        "_internal_originalTime": 1689729932000
    },
    {
        "time": 1689729934000,
        "value": 138.983,
        "_internal_originalTime": 1689729934000
    },
    {
        "time": 1689729935000,
        "value": 138.981,
        "_internal_originalTime": 1689729935000
    },
    {
        "time": 1689729936000,
        "value": 138.979,
        "_internal_originalTime": 1689729936000
    },
    {
        "time": 1689729942000,
        "value": 138.974,
        "_internal_originalTime": 1689729942000
    },
    {
        "time": 1689729944000,
        "value": 138.966,
        "_internal_originalTime": 1689729944000
    },
    {
        "time": 1689729945000,
        "value": 138.967,
        "_internal_originalTime": 1689729945000
    },
    {
        "time": 1689729946000,
        "value": 138.967,
        "_internal_originalTime": 1689729946000
    },
    {
        "time": 1689729948000,
        "value": 138.981,
        "_internal_originalTime": 1689729948000
    },
    {
        "time": 1689729950000,
        "value": 138.98,
        "_internal_originalTime": 1689729950000
    },
    {
        "time": 1689729952000,
        "value": 138.982,
        "_internal_originalTime": 1689729952000
    },
    {
        "time": 1689729953000,
        "value": 138.981,
        "_internal_originalTime": 1689729953000
    },
    {
        "time": 1689729955000,
        "value": 138.983,
        "_internal_originalTime": 1689729955000
    },
    {
        "time": 1689729958000,
        "value": 138.983,
        "_internal_originalTime": 1689729958000
    },
    {
        "time": 1689729959000,
        "value": 138.98,
        "_internal_originalTime": 1689729959000
    },
    {
        "time": 1689729964000,
        "value": 138.998,
        "_internal_originalTime": 1689729964000
    },
    {
        "time": 1689729966000,
        "value": 138.999,
        "_internal_originalTime": 1689729966000
    },
    {
        "time": 1689729968000,
        "value": 138.997,
        "_internal_originalTime": 1689729968000
    },
    {
        "time": 1689729970000,
        "value": 138.999,
        "_internal_originalTime": 1689729970000
    },
    {
        "time": 1689729971000,
        "value": 138.997,
        "_internal_originalTime": 1689729971000
    },
    {
        "time": 1689729973000,
        "value": 138.996,
        "_internal_originalTime": 1689729973000
    },
    {
        "time": 1689729974000,
        "value": 138.997,
        "_internal_originalTime": 1689729974000
    },
    {
        "time": 1689729975000,
        "value": 138.996,
        "_internal_originalTime": 1689729975000
    },
    {
        "time": 1689729978000,
        "value": 138.997,
        "_internal_originalTime": 1689729978000
    },
    {
        "time": 1689729980000,
        "value": 138.993,
        "_internal_originalTime": 1689729980000
    },
    {
        "time": 1689729982000,
        "value": 138.987,
        "_internal_originalTime": 1689729982000
    },
    {
        "time": 1689729984000,
        "value": 138.982,
        "_internal_originalTime": 1689729984000
    },
    {
        "time": 1689729986000,
        "value": 138.979,
        "_internal_originalTime": 1689729986000
    },
    {
        "time": 1689729988000,
        "value": 138.982,
        "_internal_originalTime": 1689729988000
    },
    {
        "time": 1689729990000,
        "value": 138.982,
        "_internal_originalTime": 1689729990000
    },
    {
        "time": 1689729991000,
        "value": 138.98,
        "_internal_originalTime": 1689729991000
    },
    {
        "time": 1689729992000,
        "value": 138.977,
        "_internal_originalTime": 1689729992000
    },
    {
        "time": 1689729995000,
        "value": 138.978,
        "_internal_originalTime": 1689729995000
    },
    {
        "time": 1689729996000,
        "value": 138.982,
        "_internal_originalTime": 1689729996000
    },
    {
        "time": 1689729998000,
        "value": 138.977,
        "_internal_originalTime": 1689729998000
    },
    {
        "time": 1689730001000,
        "value": 138.98,
        "_internal_originalTime": 1689730001000
    },
    {
        "time": 1689730003000,
        "value": 138.987,
        "_internal_originalTime": 1689730003000
    },
    {
        "time": 1689730004000,
        "value": 138.986,
        "_internal_originalTime": 1689730004000
    },
    {
        "time": 1689730006000,
        "value": 138.987,
        "_internal_originalTime": 1689730006000
    },
    {
        "time": 1689730007000,
        "value": 138.986,
        "_internal_originalTime": 1689730007000
    },
    {
        "time": 1689730010000,
        "value": 138.983,
        "_internal_originalTime": 1689730010000
    },
    {
        "time": 1689730011000,
        "value": 138.979,
        "_internal_originalTime": 1689730011000
    },
    {
        "time": 1689730014000,
        "value": 138.981,
        "_internal_originalTime": 1689730014000
    },
    {
        "time": 1689730015000,
        "value": 138.98,
        "_internal_originalTime": 1689730015000
    },
    {
        "time": 1689730017000,
        "value": 138.982,
        "_internal_originalTime": 1689730017000
    },
    {
        "time": 1689730019000,
        "value": 138.973,
        "_internal_originalTime": 1689730019000
    },
    {
        "time": 1689730020000,
        "value": 138.974,
        "_internal_originalTime": 1689730020000
    },
    {
        "time": 1689730022000,
        "value": 138.977,
        "_internal_originalTime": 1689730022000
    },
    {
        "time": 1689730023000,
        "value": 138.979,
        "_internal_originalTime": 1689730023000
    },
    {
        "time": 1689730027000,
        "value": 138.978,
        "_internal_originalTime": 1689730027000
    },
    {
        "time": 1689730028000,
        "value": 138.969,
        "_internal_originalTime": 1689730028000
    },
    {
        "time": 1689730030000,
        "value": 138.97,
        "_internal_originalTime": 1689730030000
    },
    {
        "time": 1689730032000,
        "value": 138.969,
        "_internal_originalTime": 1689730032000
    },
    {
        "time": 1689730033000,
        "value": 138.963,
        "_internal_originalTime": 1689730033000
    },
    {
        "time": 1689730035000,
        "value": 138.971,
        "_internal_originalTime": 1689730035000
    },
    {
        "time": 1689730037000,
        "value": 138.967,
        "_internal_originalTime": 1689730037000
    },
    {
        "time": 1689730039000,
        "value": 138.969,
        "_internal_originalTime": 1689730039000
    },
    {
        "time": 1689730041000,
        "value": 138.97,
        "_internal_originalTime": 1689730041000
    },
    {
        "time": 1689730043000,
        "value": 138.968,
        "_internal_originalTime": 1689730043000
    },
    {
        "time": 1689730045000,
        "value": 138.968,
        "_internal_originalTime": 1689730045000
    },
    {
        "time": 1689730046000,
        "value": 138.968,
        "_internal_originalTime": 1689730046000
    },
    {
        "time": 1689730049000,
        "value": 138.971,
        "_internal_originalTime": 1689730049000
    },
    {
        "time": 1689730050000,
        "value": 138.969,
        "_internal_originalTime": 1689730050000
    },
    {
        "time": 1689730053000,
        "value": 138.968,
        "_internal_originalTime": 1689730053000
    },
    {
        "time": 1689730055000,
        "value": 138.969,
        "_internal_originalTime": 1689730055000
    },
    {
        "time": 1689730056000,
        "value": 138.97,
        "_internal_originalTime": 1689730056000
    },
    {
        "time": 1689730059000,
        "value": 138.978,
        "_internal_originalTime": 1689730059000
    },
    {
        "time": 1689730062000,
        "value": 138.979,
        "_internal_originalTime": 1689730062000
    },
    {
        "time": 1689730063000,
        "value": 138.98,
        "_internal_originalTime": 1689730063000
    },
    {
        "time": 1689730067000,
        "value": 138.987,
        "_internal_originalTime": 1689730067000
    },
    {
        "time": 1689730068000,
        "value": 138.982,
        "_internal_originalTime": 1689730068000
    },
    {
        "time": 1689730070000,
        "value": 138.987,
        "_internal_originalTime": 1689730070000
    },
    {
        "time": 1689730071000,
        "value": 138.983,
        "_internal_originalTime": 1689730071000
    },
    {
        "time": 1689730073000,
        "value": 138.985,
        "_internal_originalTime": 1689730073000
    },
    {
        "time": 1689730075000,
        "value": 138.984,
        "_internal_originalTime": 1689730075000
    },
    {
        "time": 1689730077000,
        "value": 138.987,
        "_internal_originalTime": 1689730077000
    },
    {
        "time": 1689730079000,
        "value": 138.988,
        "_internal_originalTime": 1689730079000
    },
    {
        "time": 1689730081000,
        "value": 138.986,
        "_internal_originalTime": 1689730081000
    },
    {
        "time": 1689730085000,
        "value": 138.988,
        "_internal_originalTime": 1689730085000
    },
    {
        "time": 1689730089000,
        "value": 138.986,
        "_internal_originalTime": 1689730089000
    },
    {
        "time": 1689730090000,
        "value": 138.986,
        "_internal_originalTime": 1689730090000
    },
    {
        "time": 1689730091000,
        "value": 138.987,
        "_internal_originalTime": 1689730091000
    },
    {
        "time": 1689730093000,
        "value": 138.987,
        "_internal_originalTime": 1689730093000
    },
    {
        "time": 1689730095000,
        "value": 138.987,
        "_internal_originalTime": 1689730095000
    },
    {
        "time": 1689730097000,
        "value": 138.987,
        "_internal_originalTime": 1689730097000
    },
    {
        "time": 1689730100000,
        "value": 138.987,
        "_internal_originalTime": 1689730100000
    },
    {
        "time": 1689730101000,
        "value": 138.987,
        "_internal_originalTime": 1689730101000
    },
    {
        "time": 1689730104000,
        "value": 138.989,
        "_internal_originalTime": 1689730104000
    },
    {
        "time": 1689730106000,
        "value": 138.989,
        "_internal_originalTime": 1689730106000
    },
    {
        "time": 1689730107000,
        "value": 138.987,
        "_internal_originalTime": 1689730107000
    },
    {
        "time": 1689730110000,
        "value": 138.99,
        "_internal_originalTime": 1689730110000
    },
    {
        "time": 1689730111000,
        "value": 138.989,
        "_internal_originalTime": 1689730111000
    },
    {
        "time": 1689730113000,
        "value": 138.999,
        "_internal_originalTime": 1689730113000
    },
    {
        "time": 1689730114000,
        "value": 138.998,
        "_internal_originalTime": 1689730114000
    },
    {
        "time": 1689730116000,
        "value": 138.998,
        "_internal_originalTime": 1689730116000
    },
    {
        "time": 1689730117000,
        "value": 138.997,
        "_internal_originalTime": 1689730117000
    },
    {
        "time": 1689730118000,
        "value": 138.998,
        "_internal_originalTime": 1689730118000
    },
    {
        "time": 1689730120000,
        "value": 138.997,
        "_internal_originalTime": 1689730120000
    },
    {
        "time": 1689730121000,
        "value": 138.999,
        "_internal_originalTime": 1689730121000
    },
    {
        "time": 1689730122000,
        "value": 139,
        "_internal_originalTime": 1689730122000
    },
    {
        "time": 1689730123000,
        "value": 138.998,
        "_internal_originalTime": 1689730123000
    },
    {
        "time": 1689730125000,
        "value": 138.998,
        "_internal_originalTime": 1689730125000
    },
    {
        "time": 1689730126000,
        "value": 139.016,
        "_internal_originalTime": 1689730126000
    },
    {
        "time": 1689730127000,
        "value": 139.016,
        "_internal_originalTime": 1689730127000
    },
    {
        "time": 1689730130000,
        "value": 139.017,
        "_internal_originalTime": 1689730130000
    },
    {
        "time": 1689730132000,
        "value": 139.025,
        "_internal_originalTime": 1689730132000
    },
    {
        "time": 1689730134000,
        "value": 139.035,
        "_internal_originalTime": 1689730134000
    },
    {
        "time": 1689730136000,
        "value": 139.029,
        "_internal_originalTime": 1689730136000
    },
    {
        "time": 1689730138000,
        "value": 139.032,
        "_internal_originalTime": 1689730138000
    },
    {
        "time": 1689730139000,
        "value": 139.037,
        "_internal_originalTime": 1689730139000
    },
    {
        "time": 1689730140000,
        "value": 139.032,
        "_internal_originalTime": 1689730140000
    },
    {
        "time": 1689730141000,
        "value": 139.038,
        "_internal_originalTime": 1689730141000
    },
    {
        "time": 1689730142000,
        "value": 139.036,
        "_internal_originalTime": 1689730142000
    },
    {
        "time": 1689730143000,
        "value": 139.039,
        "_internal_originalTime": 1689730143000
    },
    {
        "time": 1689730144000,
        "value": 139.036,
        "_internal_originalTime": 1689730144000
    },
    {
        "time": 1689730145000,
        "value": 139.035,
        "_internal_originalTime": 1689730145000
    },
    {
        "time": 1689730149000,
        "value": 139.038,
        "_internal_originalTime": 1689730149000
    },
    {
        "time": 1689730150000,
        "value": 139.041,
        "_internal_originalTime": 1689730150000
    },
    {
        "time": 1689730152000,
        "value": 139.038,
        "_internal_originalTime": 1689730152000
    },
    {
        "time": 1689730162000,
        "value": 139.045,
        "_internal_originalTime": 1689730162000
    },
    {
        "time": 1689730163000,
        "value": 139.048,
        "_internal_originalTime": 1689730163000
    },
    {
        "time": 1689730165000,
        "value": 139.047,
        "_internal_originalTime": 1689730165000
    },
    {
        "time": 1689730166000,
        "value": 139.042,
        "_internal_originalTime": 1689730166000
    },
    {
        "time": 1689730169000,
        "value": 139.044,
        "_internal_originalTime": 1689730169000
    },
    {
        "time": 1689730170000,
        "value": 139.049,
        "_internal_originalTime": 1689730170000
    },
    {
        "time": 1689730171000,
        "value": 139.044,
        "_internal_originalTime": 1689730171000
    },
    {
        "time": 1689730173000,
        "value": 139.045,
        "_internal_originalTime": 1689730173000
    },
    {
        "time": 1689730175000,
        "value": 139.047,
        "_internal_originalTime": 1689730175000
    },
    {
        "time": 1689730177000,
        "value": 139.063,
        "_internal_originalTime": 1689730177000
    },
    {
        "time": 1689730179000,
        "value": 139.056,
        "_internal_originalTime": 1689730179000
    },
    {
        "time": 1689730181000,
        "value": 139.051,
        "_internal_originalTime": 1689730181000
    },
    {
        "time": 1689730183000,
        "value": 139.05,
        "_internal_originalTime": 1689730183000
    },
    {
        "time": 1689730185000,
        "value": 139.048,
        "_internal_originalTime": 1689730185000
    },
    {
        "time": 1689730186000,
        "value": 139.05,
        "_internal_originalTime": 1689730186000
    },
    {
        "time": 1689730188000,
        "value": 139.049,
        "_internal_originalTime": 1689730188000
    },
    {
        "time": 1689730190000,
        "value": 139.051,
        "_internal_originalTime": 1689730190000
    },
    {
        "time": 1689730193000,
        "value": 139.049,
        "_internal_originalTime": 1689730193000
    },
    {
        "time": 1689730195000,
        "value": 139.061,
        "_internal_originalTime": 1689730195000
    },
    {
        "time": 1689730196000,
        "value": 139.058,
        "_internal_originalTime": 1689730196000
    },
    {
        "time": 1689730198000,
        "value": 139.062,
        "_internal_originalTime": 1689730198000
    },
    {
        "time": 1689730199000,
        "value": 139.062,
        "_internal_originalTime": 1689730199000
    },
    {
        "time": 1689730200000,
        "value": 139.04,
        "_internal_originalTime": 1689730200000
    },
    {
        "time": 1689730202000,
        "value": 139.062,
        "_internal_originalTime": 1689730202000
    },
    {
        "time": 1689730204000,
        "value": 139.047,
        "_internal_originalTime": 1689730204000
    },
    {
        "time": 1689730205000,
        "value": 139.045,
        "_internal_originalTime": 1689730205000
    },
    {
        "time": 1689730207000,
        "value": 139.039,
        "_internal_originalTime": 1689730207000
    },
    {
        "time": 1689730209000,
        "value": 139.037,
        "_internal_originalTime": 1689730209000
    },
    {
        "time": 1689730211000,
        "value": 139.029,
        "_internal_originalTime": 1689730211000
    },
    {
        "time": 1689730213000,
        "value": 139.033,
        "_internal_originalTime": 1689730213000
    },
    {
        "time": 1689730214000,
        "value": 139.039,
        "_internal_originalTime": 1689730214000
    },
    {
        "time": 1689730216000,
        "value": 139.038,
        "_internal_originalTime": 1689730216000
    },
    {
        "time": 1689730218000,
        "value": 139.038,
        "_internal_originalTime": 1689730218000
    },
    {
        "time": 1689730220000,
        "value": 139.038,
        "_internal_originalTime": 1689730220000
    },
    {
        "time": 1689730221000,
        "value": 139.04,
        "_internal_originalTime": 1689730221000
    },
    {
        "time": 1689730223000,
        "value": 139.04,
        "_internal_originalTime": 1689730223000
    },
    {
        "time": 1689730225000,
        "value": 139.036,
        "_internal_originalTime": 1689730225000
    },
    {
        "time": 1689730227000,
        "value": 139.04,
        "_internal_originalTime": 1689730227000
    },
    {
        "time": 1689730229000,
        "value": 139.035,
        "_internal_originalTime": 1689730229000
    },
    {
        "time": 1689730230000,
        "value": 139.057,
        "_internal_originalTime": 1689730230000
    },
    {
        "time": 1689730232000,
        "value": 139.059,
        "_internal_originalTime": 1689730232000
    },
    {
        "time": 1689730234000,
        "value": 139.054,
        "_internal_originalTime": 1689730234000
    },
    {
        "time": 1689730235000,
        "value": 139.057,
        "_internal_originalTime": 1689730235000
    },
    {
        "time": 1689730238000,
        "value": 139.052,
        "_internal_originalTime": 1689730238000
    },
    {
        "time": 1689730240000,
        "value": 139.052,
        "_internal_originalTime": 1689730240000
    },
    {
        "time": 1689730242000,
        "value": 139.041,
        "_internal_originalTime": 1689730242000
    },
    {
        "time": 1689730244000,
        "value": 139.029,
        "_internal_originalTime": 1689730244000
    },
    {
        "time": 1689730245000,
        "value": 139.028,
        "_internal_originalTime": 1689730245000
    },
    {
        "time": 1689730248000,
        "value": 139.018,
        "_internal_originalTime": 1689730248000
    },
    {
        "time": 1689730249000,
        "value": 139.018,
        "_internal_originalTime": 1689730249000
    },
    {
        "time": 1689730251000,
        "value": 139.026,
        "_internal_originalTime": 1689730251000
    },
    {
        "time": 1689730253000,
        "value": 139.02,
        "_internal_originalTime": 1689730253000
    },
    {
        "time": 1689730254000,
        "value": 139.025,
        "_internal_originalTime": 1689730254000
    },
    {
        "time": 1689730256000,
        "value": 139.014,
        "_internal_originalTime": 1689730256000
    },
    {
        "time": 1689730258000,
        "value": 139.021,
        "_internal_originalTime": 1689730258000
    },
    {
        "time": 1689730259000,
        "value": 139.02,
        "_internal_originalTime": 1689730259000
    },
    {
        "time": 1689730260000,
        "value": 139,
        "_internal_originalTime": 1689730260000
    },
    {
        "time": 1689730261000,
        "value": 139.02,
        "_internal_originalTime": 1689730261000
    },
    {
        "time": 1689730263000,
        "value": 139.024,
        "_internal_originalTime": 1689730263000
    },
    {
        "time": 1689730265000,
        "value": 139.019,
        "_internal_originalTime": 1689730265000
    },
    {
        "time": 1689730267000,
        "value": 139.018,
        "_internal_originalTime": 1689730267000
    },
    {
        "time": 1689730271000,
        "value": 139.019,
        "_internal_originalTime": 1689730271000
    },
    {
        "time": 1689730272000,
        "value": 139.024,
        "_internal_originalTime": 1689730272000
    },
    {
        "time": 1689730274000,
        "value": 139.023,
        "_internal_originalTime": 1689730274000
    },
    {
        "time": 1689730276000,
        "value": 139.025,
        "_internal_originalTime": 1689730276000
    },
    {
        "time": 1689730277000,
        "value": 139.02,
        "_internal_originalTime": 1689730277000
    },
    {
        "time": 1689730280000,
        "value": 139.021,
        "_internal_originalTime": 1689730280000
    },
    {
        "time": 1689730283000,
        "value": 139.021,
        "_internal_originalTime": 1689730283000
    },
    {
        "time": 1689730285000,
        "value": 139.023,
        "_internal_originalTime": 1689730285000
    },
    {
        "time": 1689730287000,
        "value": 139.023,
        "_internal_originalTime": 1689730287000
    },
    {
        "time": 1689730289000,
        "value": 139.021,
        "_internal_originalTime": 1689730289000
    },
    {
        "time": 1689730290000,
        "value": 139.02,
        "_internal_originalTime": 1689730290000
    },
    {
        "time": 1689730291000,
        "value": 139.014,
        "_internal_originalTime": 1689730291000
    },
    {
        "time": 1689730294000,
        "value": 139.025,
        "_internal_originalTime": 1689730294000
    },
    {
        "time": 1689730295000,
        "value": 139.02,
        "_internal_originalTime": 1689730295000
    },
    {
        "time": 1689730297000,
        "value": 139.022,
        "_internal_originalTime": 1689730297000
    },
    {
        "time": 1689730299000,
        "value": 139.02,
        "_internal_originalTime": 1689730299000
    },
    {
        "time": 1689730301000,
        "value": 139.023,
        "_internal_originalTime": 1689730301000
    },
    {
        "time": 1689730303000,
        "value": 139.02,
        "_internal_originalTime": 1689730303000
    },
    {
        "time": 1689730305000,
        "value": 139.018,
        "_internal_originalTime": 1689730305000
    },
    {
        "time": 1689730306000,
        "value": 139.02,
        "_internal_originalTime": 1689730306000
    },
    {
        "time": 1689730307000,
        "value": 139.02,
        "_internal_originalTime": 1689730307000
    },
    {
        "time": 1689730310000,
        "value": 139.024,
        "_internal_originalTime": 1689730310000
    },
    {
        "time": 1689730311000,
        "value": 139.025,
        "_internal_originalTime": 1689730311000
    },
    {
        "time": 1689730313000,
        "value": 139.024,
        "_internal_originalTime": 1689730313000
    },
    {
        "time": 1689730315000,
        "value": 139.022,
        "_internal_originalTime": 1689730315000
    },
    {
        "time": 1689730316000,
        "value": 139.022,
        "_internal_originalTime": 1689730316000
    },
    {
        "time": 1689730318000,
        "value": 139.017,
        "_internal_originalTime": 1689730318000
    },
    {
        "time": 1689730320000,
        "value": 139.021,
        "_internal_originalTime": 1689730320000
    },
    {
        "time": 1689730322000,
        "value": 139.02,
        "_internal_originalTime": 1689730322000
    },
    {
        "time": 1689730324000,
        "value": 139.02,
        "_internal_originalTime": 1689730324000
    },
    {
        "time": 1689730326000,
        "value": 139.016,
        "_internal_originalTime": 1689730326000
    },
    {
        "time": 1689730328000,
        "value": 139.019,
        "_internal_originalTime": 1689730328000
    },
    {
        "time": 1689730330000,
        "value": 139.003,
        "_internal_originalTime": 1689730330000
    },
    {
        "time": 1689730331000,
        "value": 139.008,
        "_internal_originalTime": 1689730331000
    },
    {
        "time": 1689730333000,
        "value": 139.007,
        "_internal_originalTime": 1689730333000
    },
    {
        "time": 1689730334000,
        "value": 139.006,
        "_internal_originalTime": 1689730334000
    },
    {
        "time": 1689730336000,
        "value": 139.005,
        "_internal_originalTime": 1689730336000
    },
    {
        "time": 1689730338000,
        "value": 139.002,
        "_internal_originalTime": 1689730338000
    },
    {
        "time": 1689730340000,
        "value": 139.004,
        "_internal_originalTime": 1689730340000
    },
    {
        "time": 1689730342000,
        "value": 138.998,
        "_internal_originalTime": 1689730342000
    },
    {
        "time": 1689730343000,
        "value": 139,
        "_internal_originalTime": 1689730343000
    },
    {
        "time": 1689730346000,
        "value": 139.004,
        "_internal_originalTime": 1689730346000
    },
    {
        "time": 1689730348000,
        "value": 138.998,
        "_internal_originalTime": 1689730348000
    },
    {
        "time": 1689730349000,
        "value": 139.003,
        "_internal_originalTime": 1689730349000
    },
    {
        "time": 1689730352000,
        "value": 139.003,
        "_internal_originalTime": 1689730352000
    },
    {
        "time": 1689730353000,
        "value": 139.003,
        "_internal_originalTime": 1689730353000
    },
    {
        "time": 1689730356000,
        "value": 138.998,
        "_internal_originalTime": 1689730356000
    },
    {
        "time": 1689730359000,
        "value": 139.004,
        "_internal_originalTime": 1689730359000
    },
    {
        "time": 1689730361000,
        "value": 139.014,
        "_internal_originalTime": 1689730361000
    },
    {
        "time": 1689730363000,
        "value": 139.013,
        "_internal_originalTime": 1689730363000
    },
    {
        "time": 1689730365000,
        "value": 139.012,
        "_internal_originalTime": 1689730365000
    },
    {
        "time": 1689730366000,
        "value": 139.02,
        "_internal_originalTime": 1689730366000
    },
    {
        "time": 1689730368000,
        "value": 139.02,
        "_internal_originalTime": 1689730368000
    },
    {
        "time": 1689730370000,
        "value": 139.02,
        "_internal_originalTime": 1689730370000
    },
    {
        "time": 1689730371000,
        "value": 139.023,
        "_internal_originalTime": 1689730371000
    },
    {
        "time": 1689730373000,
        "value": 139.017,
        "_internal_originalTime": 1689730373000
    },
    {
        "time": 1689730375000,
        "value": 139.02,
        "_internal_originalTime": 1689730375000
    },
    {
        "time": 1689730377000,
        "value": 139.015,
        "_internal_originalTime": 1689730377000
    },
    {
        "time": 1689730378000,
        "value": 139.016,
        "_internal_originalTime": 1689730378000
    },
    {
        "time": 1689730379000,
        "value": 139.01,
        "_internal_originalTime": 1689730379000
    },
    {
        "time": 1689730380000,
        "value": 139.016,
        "_internal_originalTime": 1689730380000
    },
    {
        "time": 1689730383000,
        "value": 139.012,
        "_internal_originalTime": 1689730383000
    },
    {
        "time": 1689730385000,
        "value": 139.007,
        "_internal_originalTime": 1689730385000
    },
    {
        "time": 1689730386000,
        "value": 139,
        "_internal_originalTime": 1689730386000
    },
    {
        "time": 1689730388000,
        "value": 138.993,
        "_internal_originalTime": 1689730388000
    },
    {
        "time": 1689730389000,
        "value": 138.987,
        "_internal_originalTime": 1689730389000
    },
    {
        "time": 1689730390000,
        "value": 138.988,
        "_internal_originalTime": 1689730390000
    },
    {
        "time": 1689730393000,
        "value": 138.989,
        "_internal_originalTime": 1689730393000
    },
    {
        "time": 1689730395000,
        "value": 138.988,
        "_internal_originalTime": 1689730395000
    },
    {
        "time": 1689730398000,
        "value": 138.988,
        "_internal_originalTime": 1689730398000
    },
    {
        "time": 1689730400000,
        "value": 138.989,
        "_internal_originalTime": 1689730400000
    },
    {
        "time": 1689730401000,
        "value": 138.991,
        "_internal_originalTime": 1689730401000
    },
    {
        "time": 1689730403000,
        "value": 138.993,
        "_internal_originalTime": 1689730403000
    },
    {
        "time": 1689730405000,
        "value": 138.99,
        "_internal_originalTime": 1689730405000
    },
    {
        "time": 1689730407000,
        "value": 138.99,
        "_internal_originalTime": 1689730407000
    },
    {
        "time": 1689730409000,
        "value": 138.995,
        "_internal_originalTime": 1689730409000
    },
    {
        "time": 1689730410000,
        "value": 138.992,
        "_internal_originalTime": 1689730410000
    },
    {
        "time": 1689730411000,
        "value": 138.994,
        "_internal_originalTime": 1689730411000
    },
    {
        "time": 1689730413000,
        "value": 138.988,
        "_internal_originalTime": 1689730413000
    },
    {
        "time": 1689730414000,
        "value": 138.99,
        "_internal_originalTime": 1689730414000
    },
    {
        "time": 1689730416000,
        "value": 138.992,
        "_internal_originalTime": 1689730416000
    },
    {
        "time": 1689730417000,
        "value": 138.99,
        "_internal_originalTime": 1689730417000
    },
    {
        "time": 1689730422000,
        "value": 138.986,
        "_internal_originalTime": 1689730422000
    },
    {
        "time": 1689730423000,
        "value": 138.987,
        "_internal_originalTime": 1689730423000
    },
    {
        "time": 1689730427000,
        "value": 138.99,
        "_internal_originalTime": 1689730427000
    },
    {
        "time": 1689730429000,
        "value": 138.99,
        "_internal_originalTime": 1689730429000
    },
    {
        "time": 1689730430000,
        "value": 138.991,
        "_internal_originalTime": 1689730430000
    },
    {
        "time": 1689730432000,
        "value": 138.987,
        "_internal_originalTime": 1689730432000
    },
    {
        "time": 1689730434000,
        "value": 138.988,
        "_internal_originalTime": 1689730434000
    },
    {
        "time": 1689730435000,
        "value": 138.987,
        "_internal_originalTime": 1689730435000
    },
    {
        "time": 1689730438000,
        "value": 138.987,
        "_internal_originalTime": 1689730438000
    },
    {
        "time": 1689730439000,
        "value": 138.991,
        "_internal_originalTime": 1689730439000
    },
    {
        "time": 1689730441000,
        "value": 138.97,
        "_internal_originalTime": 1689730441000
    },
    {
        "time": 1689730443000,
        "value": 138.987,
        "_internal_originalTime": 1689730443000
    },
    {
        "time": 1689730444000,
        "value": 138.988,
        "_internal_originalTime": 1689730444000
    },
    {
        "time": 1689730446000,
        "value": 138.98,
        "_internal_originalTime": 1689730446000
    },
    {
        "time": 1689730447000,
        "value": 138.985,
        "_internal_originalTime": 1689730447000
    },
    {
        "time": 1689730449000,
        "value": 138.983,
        "_internal_originalTime": 1689730449000
    },
    {
        "time": 1689730451000,
        "value": 138.972,
        "_internal_originalTime": 1689730451000
    },
    {
        "time": 1689730452000,
        "value": 138.975,
        "_internal_originalTime": 1689730452000
    },
    {
        "time": 1689730454000,
        "value": 138.976,
        "_internal_originalTime": 1689730454000
    },
    {
        "time": 1689730455000,
        "value": 138.978,
        "_internal_originalTime": 1689730455000
    },
    {
        "time": 1689730457000,
        "value": 138.976,
        "_internal_originalTime": 1689730457000
    },
    {
        "time": 1689730459000,
        "value": 138.981,
        "_internal_originalTime": 1689730459000
    },
    {
        "time": 1689730461000,
        "value": 138.994,
        "_internal_originalTime": 1689730461000
    },
    {
        "time": 1689730462000,
        "value": 138.996,
        "_internal_originalTime": 1689730462000
    },
    {
        "time": 1689730464000,
        "value": 138.993,
        "_internal_originalTime": 1689730464000
    },
    {
        "time": 1689730466000,
        "value": 138.975,
        "_internal_originalTime": 1689730466000
    },
    {
        "time": 1689730468000,
        "value": 138.962,
        "_internal_originalTime": 1689730468000
    },
    {
        "time": 1689730469000,
        "value": 139.008,
        "_internal_originalTime": 1689730469000
    },
    {
        "time": 1689730471000,
        "value": 139.011,
        "_internal_originalTime": 1689730471000
    },
    {
        "time": 1689730472000,
        "value": 139.01,
        "_internal_originalTime": 1689730472000
    },
    {
        "time": 1689730473000,
        "value": 139.007,
        "_internal_originalTime": 1689730473000
    },
    {
        "time": 1689730475000,
        "value": 139.007,
        "_internal_originalTime": 1689730475000
    },
    {
        "time": 1689730476000,
        "value": 139.011,
        "_internal_originalTime": 1689730476000
    },
    {
        "time": 1689730478000,
        "value": 139.004,
        "_internal_originalTime": 1689730478000
    },
    {
        "time": 1689730480000,
        "value": 139.002,
        "_internal_originalTime": 1689730480000
    },
    {
        "time": 1689730482000,
        "value": 139.005,
        "_internal_originalTime": 1689730482000
    },
    {
        "time": 1689730484000,
        "value": 139.008,
        "_internal_originalTime": 1689730484000
    },
    {
        "time": 1689730485000,
        "value": 139.009,
        "_internal_originalTime": 1689730485000
    },
    {
        "time": 1689730486000,
        "value": 139.011,
        "_internal_originalTime": 1689730486000
    },
    {
        "time": 1689730489000,
        "value": 139.013,
        "_internal_originalTime": 1689730489000
    },
    {
        "time": 1689730491000,
        "value": 139.002,
        "_internal_originalTime": 1689730491000
    },
    {
        "time": 1689730493000,
        "value": 138.996,
        "_internal_originalTime": 1689730493000
    },
    {
        "time": 1689730494000,
        "value": 138.99,
        "_internal_originalTime": 1689730494000
    },
    {
        "time": 1689730496000,
        "value": 138.989,
        "_internal_originalTime": 1689730496000
    },
    {
        "time": 1689730498000,
        "value": 138.992,
        "_internal_originalTime": 1689730498000
    },
    {
        "time": 1689730499000,
        "value": 138.988,
        "_internal_originalTime": 1689730499000
    },
    {
        "time": 1689730500000,
        "value": 138.971,
        "_internal_originalTime": 1689730500000
    },
    {
        "time": 1689730502000,
        "value": 138.966,
        "_internal_originalTime": 1689730502000
    },
    {
        "time": 1689730504000,
        "value": 138.962,
        "_internal_originalTime": 1689730504000
    },
    {
        "time": 1689730506000,
        "value": 138.96,
        "_internal_originalTime": 1689730506000
    },
    {
        "time": 1689730508000,
        "value": 138.966,
        "_internal_originalTime": 1689730508000
    },
    {
        "time": 1689730510000,
        "value": 138.969,
        "_internal_originalTime": 1689730510000
    },
    {
        "time": 1689730511000,
        "value": 138.974,
        "_internal_originalTime": 1689730511000
    },
    {
        "time": 1689730513000,
        "value": 138.971,
        "_internal_originalTime": 1689730513000
    },
    {
        "time": 1689730515000,
        "value": 138.97,
        "_internal_originalTime": 1689730515000
    },
    {
        "time": 1689730516000,
        "value": 138.967,
        "_internal_originalTime": 1689730516000
    },
    {
        "time": 1689730518000,
        "value": 138.96,
        "_internal_originalTime": 1689730518000
    },
    {
        "time": 1689730520000,
        "value": 138.972,
        "_internal_originalTime": 1689730520000
    },
    {
        "time": 1689730522000,
        "value": 138.97,
        "_internal_originalTime": 1689730522000
    },
    {
        "time": 1689730524000,
        "value": 138.966,
        "_internal_originalTime": 1689730524000
    },
    {
        "time": 1689730525000,
        "value": 138.961,
        "_internal_originalTime": 1689730525000
    },
    {
        "time": 1689730526000,
        "value": 138.963,
        "_internal_originalTime": 1689730526000
    },
    {
        "time": 1689730529000,
        "value": 138.966,
        "_internal_originalTime": 1689730529000
    },
    {
        "time": 1689730530000,
        "value": 138.966,
        "_internal_originalTime": 1689730530000
    },
    {
        "time": 1689730532000,
        "value": 138.965,
        "_internal_originalTime": 1689730532000
    },
    {
        "time": 1689730533000,
        "value": 138.973,
        "_internal_originalTime": 1689730533000
    },
    {
        "time": 1689730534000,
        "value": 138.973,
        "_internal_originalTime": 1689730534000
    },
    {
        "time": 1689730537000,
        "value": 138.973,
        "_internal_originalTime": 1689730537000
    },
    {
        "time": 1689730539000,
        "value": 138.973,
        "_internal_originalTime": 1689730539000
    },
    {
        "time": 1689730541000,
        "value": 138.973,
        "_internal_originalTime": 1689730541000
    },
    {
        "time": 1689730543000,
        "value": 138.974,
        "_internal_originalTime": 1689730543000
    },
    {
        "time": 1689730544000,
        "value": 138.974,
        "_internal_originalTime": 1689730544000
    },
    {
        "time": 1689730546000,
        "value": 138.975,
        "_internal_originalTime": 1689730546000
    },
    {
        "time": 1689730547000,
        "value": 138.975,
        "_internal_originalTime": 1689730547000
    },
    {
        "time": 1689730549000,
        "value": 138.973,
        "_internal_originalTime": 1689730549000
    },
    {
        "time": 1689730551000,
        "value": 138.974,
        "_internal_originalTime": 1689730551000
    },
    {
        "time": 1689730552000,
        "value": 138.966,
        "_internal_originalTime": 1689730552000
    },
    {
        "time": 1689730554000,
        "value": 138.96,
        "_internal_originalTime": 1689730554000
    },
    {
        "time": 1689730556000,
        "value": 138.965,
        "_internal_originalTime": 1689730556000
    },
    {
        "time": 1689730558000,
        "value": 138.964,
        "_internal_originalTime": 1689730558000
    },
    {
        "time": 1689730559000,
        "value": 138.965,
        "_internal_originalTime": 1689730559000
    },
    {
        "time": 1689730560000,
        "value": 138.965,
        "_internal_originalTime": 1689730560000
    },
    {
        "time": 1689730562000,
        "value": 138.953,
        "_internal_originalTime": 1689730562000
    },
    {
        "time": 1689730563000,
        "value": 138.954,
        "_internal_originalTime": 1689730563000
    },
    {
        "time": 1689730565000,
        "value": 138.95,
        "_internal_originalTime": 1689730565000
    },
    {
        "time": 1689730566000,
        "value": 138.954,
        "_internal_originalTime": 1689730566000
    },
    {
        "time": 1689730569000,
        "value": 138.955,
        "_internal_originalTime": 1689730569000
    },
    {
        "time": 1689730571000,
        "value": 138.956,
        "_internal_originalTime": 1689730571000
    },
    {
        "time": 1689730573000,
        "value": 138.952,
        "_internal_originalTime": 1689730573000
    },
    {
        "time": 1689730574000,
        "value": 138.951,
        "_internal_originalTime": 1689730574000
    },
    {
        "time": 1689730578000,
        "value": 138.948,
        "_internal_originalTime": 1689730578000
    },
    {
        "time": 1689730580000,
        "value": 138.954,
        "_internal_originalTime": 1689730580000
    },
    {
        "time": 1689730581000,
        "value": 138.954,
        "_internal_originalTime": 1689730581000
    },
    {
        "time": 1689730583000,
        "value": 138.949,
        "_internal_originalTime": 1689730583000
    },
    {
        "time": 1689730586000,
        "value": 138.944,
        "_internal_originalTime": 1689730586000
    },
    {
        "time": 1689730587000,
        "value": 138.944,
        "_internal_originalTime": 1689730587000
    },
    {
        "time": 1689730589000,
        "value": 138.943,
        "_internal_originalTime": 1689730589000
    },
    {
        "time": 1689730590000,
        "value": 138.94,
        "_internal_originalTime": 1689730590000
    },
    {
        "time": 1689730592000,
        "value": 138.947,
        "_internal_originalTime": 1689730592000
    },
    {
        "time": 1689730593000,
        "value": 138.94,
        "_internal_originalTime": 1689730593000
    },
    {
        "time": 1689730595000,
        "value": 138.946,
        "_internal_originalTime": 1689730595000
    },
    {
        "time": 1689730597000,
        "value": 138.951,
        "_internal_originalTime": 1689730597000
    },
    {
        "time": 1689730598000,
        "value": 138.946,
        "_internal_originalTime": 1689730598000
    },
    {
        "time": 1689730600000,
        "value": 138.946,
        "_internal_originalTime": 1689730600000
    },
    {
        "time": 1689730602000,
        "value": 138.942,
        "_internal_originalTime": 1689730602000
    },
    {
        "time": 1689730604000,
        "value": 138.945,
        "_internal_originalTime": 1689730604000
    },
    {
        "time": 1689730606000,
        "value": 138.942,
        "_internal_originalTime": 1689730606000
    },
    {
        "time": 1689730609000,
        "value": 138.947,
        "_internal_originalTime": 1689730609000
    },
    {
        "time": 1689730611000,
        "value": 138.946,
        "_internal_originalTime": 1689730611000
    },
    {
        "time": 1689730613000,
        "value": 138.95,
        "_internal_originalTime": 1689730613000
    },
    {
        "time": 1689730614000,
        "value": 138.944,
        "_internal_originalTime": 1689730614000
    },
    {
        "time": 1689730616000,
        "value": 138.943,
        "_internal_originalTime": 1689730616000
    },
    {
        "time": 1689730619000,
        "value": 138.948,
        "_internal_originalTime": 1689730619000
    },
    {
        "time": 1689730620000,
        "value": 138.944,
        "_internal_originalTime": 1689730620000
    },
    {
        "time": 1689730622000,
        "value": 138.942,
        "_internal_originalTime": 1689730622000
    },
    {
        "time": 1689730624000,
        "value": 138.938,
        "_internal_originalTime": 1689730624000
    },
    {
        "time": 1689730626000,
        "value": 138.937,
        "_internal_originalTime": 1689730626000
    },
    {
        "time": 1689730627000,
        "value": 138.941,
        "_internal_originalTime": 1689730627000
    },
    {
        "time": 1689730629000,
        "value": 138.945,
        "_internal_originalTime": 1689730629000
    },
    {
        "time": 1689730630000,
        "value": 138.945,
        "_internal_originalTime": 1689730630000
    },
    {
        "time": 1689730632000,
        "value": 138.942,
        "_internal_originalTime": 1689730632000
    },
    {
        "time": 1689730634000,
        "value": 138.944,
        "_internal_originalTime": 1689730634000
    },
    {
        "time": 1689730636000,
        "value": 138.947,
        "_internal_originalTime": 1689730636000
    },
    {
        "time": 1689730637000,
        "value": 138.945,
        "_internal_originalTime": 1689730637000
    },
    {
        "time": 1689730639000,
        "value": 138.943,
        "_internal_originalTime": 1689730639000
    },
    {
        "time": 1689730642000,
        "value": 138.946,
        "_internal_originalTime": 1689730642000
    },
    {
        "time": 1689730644000,
        "value": 138.94,
        "_internal_originalTime": 1689730644000
    },
    {
        "time": 1689730645000,
        "value": 138.945,
        "_internal_originalTime": 1689730645000
    },
    {
        "time": 1689730648000,
        "value": 138.943,
        "_internal_originalTime": 1689730648000
    },
    {
        "time": 1689730649000,
        "value": 138.944,
        "_internal_originalTime": 1689730649000
    },
    {
        "time": 1689730650000,
        "value": 138.944,
        "_internal_originalTime": 1689730650000
    },
    {
        "time": 1689730652000,
        "value": 138.943,
        "_internal_originalTime": 1689730652000
    },
    {
        "time": 1689730656000,
        "value": 138.955,
        "_internal_originalTime": 1689730656000
    },
    {
        "time": 1689730657000,
        "value": 138.954,
        "_internal_originalTime": 1689730657000
    },
    {
        "time": 1689730659000,
        "value": 138.952,
        "_internal_originalTime": 1689730659000
    },
    {
        "time": 1689730661000,
        "value": 138.956,
        "_internal_originalTime": 1689730661000
    },
    {
        "time": 1689730663000,
        "value": 138.957,
        "_internal_originalTime": 1689730663000
    },
    {
        "time": 1689730667000,
        "value": 138.958,
        "_internal_originalTime": 1689730667000
    },
    {
        "time": 1689730669000,
        "value": 138.954,
        "_internal_originalTime": 1689730669000
    },
    {
        "time": 1689730671000,
        "value": 138.951,
        "_internal_originalTime": 1689730671000
    },
    {
        "time": 1689730672000,
        "value": 138.954,
        "_internal_originalTime": 1689730672000
    },
    {
        "time": 1689730674000,
        "value": 138.956,
        "_internal_originalTime": 1689730674000
    },
    {
        "time": 1689730675000,
        "value": 138.953,
        "_internal_originalTime": 1689730675000
    },
    {
        "time": 1689730678000,
        "value": 138.956,
        "_internal_originalTime": 1689730678000
    },
    {
        "time": 1689730679000,
        "value": 138.952,
        "_internal_originalTime": 1689730679000
    },
    {
        "time": 1689730680000,
        "value": 138.959,
        "_internal_originalTime": 1689730680000
    },
    {
        "time": 1689730682000,
        "value": 138.955,
        "_internal_originalTime": 1689730682000
    },
    {
        "time": 1689730684000,
        "value": 138.957,
        "_internal_originalTime": 1689730684000
    },
    {
        "time": 1689730686000,
        "value": 138.956,
        "_internal_originalTime": 1689730686000
    },
    {
        "time": 1689730687000,
        "value": 138.956,
        "_internal_originalTime": 1689730687000
    },
    {
        "time": 1689730689000,
        "value": 138.955,
        "_internal_originalTime": 1689730689000
    },
    {
        "time": 1689730691000,
        "value": 138.951,
        "_internal_originalTime": 1689730691000
    },
    {
        "time": 1689730692000,
        "value": 138.955,
        "_internal_originalTime": 1689730692000
    },
    {
        "time": 1689730695000,
        "value": 138.952,
        "_internal_originalTime": 1689730695000
    },
    {
        "time": 1689730696000,
        "value": 138.953,
        "_internal_originalTime": 1689730696000
    },
    {
        "time": 1689730698000,
        "value": 138.952,
        "_internal_originalTime": 1689730698000
    },
    {
        "time": 1689730700000,
        "value": 138.955,
        "_internal_originalTime": 1689730700000
    },
    {
        "time": 1689730702000,
        "value": 138.955,
        "_internal_originalTime": 1689730702000
    },
    {
        "time": 1689730703000,
        "value": 138.955,
        "_internal_originalTime": 1689730703000
    },
    {
        "time": 1689730707000,
        "value": 138.966,
        "_internal_originalTime": 1689730707000
    },
    {
        "time": 1689730708000,
        "value": 138.963,
        "_internal_originalTime": 1689730708000
    },
    {
        "time": 1689730710000,
        "value": 138.963,
        "_internal_originalTime": 1689730710000
    },
    {
        "time": 1689730711000,
        "value": 138.962,
        "_internal_originalTime": 1689730711000
    },
    {
        "time": 1689730713000,
        "value": 138.96,
        "_internal_originalTime": 1689730713000
    },
    {
        "time": 1689730714000,
        "value": 138.958,
        "_internal_originalTime": 1689730714000
    },
    {
        "time": 1689730716000,
        "value": 138.955,
        "_internal_originalTime": 1689730716000
    },
    {
        "time": 1689730718000,
        "value": 138.955,
        "_internal_originalTime": 1689730718000
    },
    {
        "time": 1689730720000,
        "value": 138.959,
        "_internal_originalTime": 1689730720000
    },
    {
        "time": 1689730722000,
        "value": 138.956,
        "_internal_originalTime": 1689730722000
    },
    {
        "time": 1689730724000,
        "value": 138.955,
        "_internal_originalTime": 1689730724000
    },
    {
        "time": 1689730725000,
        "value": 138.954,
        "_internal_originalTime": 1689730725000
    },
    {
        "time": 1689730727000,
        "value": 138.95,
        "_internal_originalTime": 1689730727000
    },
    {
        "time": 1689730729000,
        "value": 138.953,
        "_internal_originalTime": 1689730729000
    },
    {
        "time": 1689730730000,
        "value": 138.95,
        "_internal_originalTime": 1689730730000
    },
    {
        "time": 1689730732000,
        "value": 138.955,
        "_internal_originalTime": 1689730732000
    },
    {
        "time": 1689730734000,
        "value": 138.953,
        "_internal_originalTime": 1689730734000
    },
    {
        "time": 1689730736000,
        "value": 138.95,
        "_internal_originalTime": 1689730736000
    },
    {
        "time": 1689730739000,
        "value": 138.953,
        "_internal_originalTime": 1689730739000
    },
    {
        "time": 1689730740000,
        "value": 138.95,
        "_internal_originalTime": 1689730740000
    },
    {
        "time": 1689730742000,
        "value": 138.95,
        "_internal_originalTime": 1689730742000
    },
    {
        "time": 1689730744000,
        "value": 138.956,
        "_internal_originalTime": 1689730744000
    },
    {
        "time": 1689730745000,
        "value": 138.956,
        "_internal_originalTime": 1689730745000
    },
    {
        "time": 1689730748000,
        "value": 138.957,
        "_internal_originalTime": 1689730748000
    },
    {
        "time": 1689730750000,
        "value": 138.944,
        "_internal_originalTime": 1689730750000
    },
    {
        "time": 1689730751000,
        "value": 138.945,
        "_internal_originalTime": 1689730751000
    },
    {
        "time": 1689730752000,
        "value": 138.946,
        "_internal_originalTime": 1689730752000
    },
    {
        "time": 1689730755000,
        "value": 138.945,
        "_internal_originalTime": 1689730755000
    },
    {
        "time": 1689730758000,
        "value": 138.954,
        "_internal_originalTime": 1689730758000
    },
    {
        "time": 1689730760000,
        "value": 138.955,
        "_internal_originalTime": 1689730760000
    },
    {
        "time": 1689730761000,
        "value": 138.955,
        "_internal_originalTime": 1689730761000
    },
    {
        "time": 1689730763000,
        "value": 138.948,
        "_internal_originalTime": 1689730763000
    },
    {
        "time": 1689730765000,
        "value": 138.952,
        "_internal_originalTime": 1689730765000
    },
    {
        "time": 1689730767000,
        "value": 138.956,
        "_internal_originalTime": 1689730767000
    },
    {
        "time": 1689730769000,
        "value": 138.959,
        "_internal_originalTime": 1689730769000
    },
    {
        "time": 1689730770000,
        "value": 138.956,
        "_internal_originalTime": 1689730770000
    },
    {
        "time": 1689730772000,
        "value": 138.952,
        "_internal_originalTime": 1689730772000
    },
    {
        "time": 1689730775000,
        "value": 138.956,
        "_internal_originalTime": 1689730775000
    },
    {
        "time": 1689730777000,
        "value": 138.956,
        "_internal_originalTime": 1689730777000
    },
    {
        "time": 1689730779000,
        "value": 138.953,
        "_internal_originalTime": 1689730779000
    },
    {
        "time": 1689730781000,
        "value": 138.952,
        "_internal_originalTime": 1689730781000
    },
    {
        "time": 1689730783000,
        "value": 138.955,
        "_internal_originalTime": 1689730783000
    },
    {
        "time": 1689730784000,
        "value": 138.959,
        "_internal_originalTime": 1689730784000
    },
    {
        "time": 1689730785000,
        "value": 138.963,
        "_internal_originalTime": 1689730785000
    },
    {
        "time": 1689730788000,
        "value": 138.964,
        "_internal_originalTime": 1689730788000
    },
    {
        "time": 1689730790000,
        "value": 138.963,
        "_internal_originalTime": 1689730790000
    },
    {
        "time": 1689730791000,
        "value": 138.96,
        "_internal_originalTime": 1689730791000
    },
    {
        "time": 1689730793000,
        "value": 138.958,
        "_internal_originalTime": 1689730793000
    },
    {
        "time": 1689730795000,
        "value": 138.964,
        "_internal_originalTime": 1689730795000
    },
    {
        "time": 1689730797000,
        "value": 138.967,
        "_internal_originalTime": 1689730797000
    },
    {
        "time": 1689730798000,
        "value": 138.966,
        "_internal_originalTime": 1689730798000
    },
    {
        "time": 1689730799000,
        "value": 138.966,
        "_internal_originalTime": 1689730799000
    },
    {
        "time": 1689730801000,
        "value": 138.963,
        "_internal_originalTime": 1689730801000
    },
    {
        "time": 1689730802000,
        "value": 138.965,
        "_internal_originalTime": 1689730802000
    },
    {
        "time": 1689730803000,
        "value": 138.96,
        "_internal_originalTime": 1689730803000
    },
    {
        "time": 1689730804000,
        "value": 138.959,
        "_internal_originalTime": 1689730804000
    },
    {
        "time": 1689730806000,
        "value": 138.946,
        "_internal_originalTime": 1689730806000
    },
    {
        "time": 1689730808000,
        "value": 138.945,
        "_internal_originalTime": 1689730808000
    },
    {
        "time": 1689730810000,
        "value": 138.954,
        "_internal_originalTime": 1689730810000
    },
    {
        "time": 1689730811000,
        "value": 138.954,
        "_internal_originalTime": 1689730811000
    },
    {
        "time": 1689730813000,
        "value": 138.96,
        "_internal_originalTime": 1689730813000
    },
    {
        "time": 1689730815000,
        "value": 138.963,
        "_internal_originalTime": 1689730815000
    },
    {
        "time": 1689730817000,
        "value": 138.965,
        "_internal_originalTime": 1689730817000
    },
    {
        "time": 1689730818000,
        "value": 138.965,
        "_internal_originalTime": 1689730818000
    },
    {
        "time": 1689730820000,
        "value": 138.962,
        "_internal_originalTime": 1689730820000
    },
    {
        "time": 1689730823000,
        "value": 138.972,
        "_internal_originalTime": 1689730823000
    },
    {
        "time": 1689730824000,
        "value": 138.973,
        "_internal_originalTime": 1689730824000
    },
    {
        "time": 1689730826000,
        "value": 138.972,
        "_internal_originalTime": 1689730826000
    },
    {
        "time": 1689730828000,
        "value": 138.976,
        "_internal_originalTime": 1689730828000
    },
    {
        "time": 1689730829000,
        "value": 138.972,
        "_internal_originalTime": 1689730829000
    },
    {
        "time": 1689730831000,
        "value": 138.974,
        "_internal_originalTime": 1689730831000
    },
    {
        "time": 1689730833000,
        "value": 138.977,
        "_internal_originalTime": 1689730833000
    },
    {
        "time": 1689730835000,
        "value": 138.977,
        "_internal_originalTime": 1689730835000
    },
    {
        "time": 1689730836000,
        "value": 138.974,
        "_internal_originalTime": 1689730836000
    },
    {
        "time": 1689730838000,
        "value": 138.979,
        "_internal_originalTime": 1689730838000
    },
    {
        "time": 1689730840000,
        "value": 138.976,
        "_internal_originalTime": 1689730840000
    },
    {
        "time": 1689730842000,
        "value": 138.974,
        "_internal_originalTime": 1689730842000
    },
    {
        "time": 1689730845000,
        "value": 138.974,
        "_internal_originalTime": 1689730845000
    },
    {
        "time": 1689730846000,
        "value": 138.975,
        "_internal_originalTime": 1689730846000
    },
    {
        "time": 1689730848000,
        "value": 138.981,
        "_internal_originalTime": 1689730848000
    },
    {
        "time": 1689730850000,
        "value": 138.977,
        "_internal_originalTime": 1689730850000
    },
    {
        "time": 1689730851000,
        "value": 138.979,
        "_internal_originalTime": 1689730851000
    },
    {
        "time": 1689730852000,
        "value": 138.977,
        "_internal_originalTime": 1689730852000
    },
    {
        "time": 1689730855000,
        "value": 138.973,
        "_internal_originalTime": 1689730855000
    },
    {
        "time": 1689730856000,
        "value": 138.972,
        "_internal_originalTime": 1689730856000
    },
    {
        "time": 1689730859000,
        "value": 138.975,
        "_internal_originalTime": 1689730859000
    },
    {
        "time": 1689730860000,
        "value": 138.975,
        "_internal_originalTime": 1689730860000
    },
    {
        "time": 1689730862000,
        "value": 138.974,
        "_internal_originalTime": 1689730862000
    },
    {
        "time": 1689730864000,
        "value": 138.973,
        "_internal_originalTime": 1689730864000
    },
    {
        "time": 1689730866000,
        "value": 138.979,
        "_internal_originalTime": 1689730866000
    },
    {
        "time": 1689730867000,
        "value": 138.977,
        "_internal_originalTime": 1689730867000
    },
    {
        "time": 1689730870000,
        "value": 138.972,
        "_internal_originalTime": 1689730870000
    },
    {
        "time": 1689730873000,
        "value": 138.978,
        "_internal_originalTime": 1689730873000
    },
    {
        "time": 1689730874000,
        "value": 138.978,
        "_internal_originalTime": 1689730874000
    },
    {
        "time": 1689730875000,
        "value": 138.977,
        "_internal_originalTime": 1689730875000
    },
    {
        "time": 1689730877000,
        "value": 138.974,
        "_internal_originalTime": 1689730877000
    },
    {
        "time": 1689730879000,
        "value": 138.977,
        "_internal_originalTime": 1689730879000
    },
    {
        "time": 1689730881000,
        "value": 138.978,
        "_internal_originalTime": 1689730881000
    },
    {
        "time": 1689730882000,
        "value": 138.979,
        "_internal_originalTime": 1689730882000
    },
    {
        "time": 1689730884000,
        "value": 138.978,
        "_internal_originalTime": 1689730884000
    },
    {
        "time": 1689730887000,
        "value": 138.978,
        "_internal_originalTime": 1689730887000
    },
    {
        "time": 1689730888000,
        "value": 138.978,
        "_internal_originalTime": 1689730888000
    },
    {
        "time": 1689730890000,
        "value": 138.971,
        "_internal_originalTime": 1689730890000
    },
    {
        "time": 1689730891000,
        "value": 138.973,
        "_internal_originalTime": 1689730891000
    },
    {
        "time": 1689730893000,
        "value": 138.977,
        "_internal_originalTime": 1689730893000
    },
    {
        "time": 1689730895000,
        "value": 138.971,
        "_internal_originalTime": 1689730895000
    },
    {
        "time": 1689730896000,
        "value": 138.974,
        "_internal_originalTime": 1689730896000
    },
    {
        "time": 1689730898000,
        "value": 138.983,
        "_internal_originalTime": 1689730898000
    },
    {
        "time": 1689730900000,
        "value": 138.982,
        "_internal_originalTime": 1689730900000
    },
    {
        "time": 1689730903000,
        "value": 138.984,
        "_internal_originalTime": 1689730903000
    },
    {
        "time": 1689730904000,
        "value": 138.982,
        "_internal_originalTime": 1689730904000
    },
    {
        "time": 1689730905000,
        "value": 138.985,
        "_internal_originalTime": 1689730905000
    },
    {
        "time": 1689730907000,
        "value": 138.985,
        "_internal_originalTime": 1689730907000
    },
    {
        "time": 1689730909000,
        "value": 138.985,
        "_internal_originalTime": 1689730909000
    },
    {
        "time": 1689730911000,
        "value": 138.982,
        "_internal_originalTime": 1689730911000
    },
    {
        "time": 1689730912000,
        "value": 138.972,
        "_internal_originalTime": 1689730912000
    },
    {
        "time": 1689730914000,
        "value": 138.976,
        "_internal_originalTime": 1689730914000
    },
    {
        "time": 1689730916000,
        "value": 138.975,
        "_internal_originalTime": 1689730916000
    },
    {
        "time": 1689730918000,
        "value": 138.976,
        "_internal_originalTime": 1689730918000
    },
    {
        "time": 1689730920000,
        "value": 138.96,
        "_internal_originalTime": 1689730920000
    },
    {
        "time": 1689730926000,
        "value": 138.97,
        "_internal_originalTime": 1689730926000
    },
    {
        "time": 1689730927000,
        "value": 138.972,
        "_internal_originalTime": 1689730927000
    },
    {
        "time": 1689730929000,
        "value": 138.979,
        "_internal_originalTime": 1689730929000
    },
    {
        "time": 1689730931000,
        "value": 138.981,
        "_internal_originalTime": 1689730931000
    },
    {
        "time": 1689730933000,
        "value": 138.975,
        "_internal_originalTime": 1689730933000
    },
    {
        "time": 1689730934000,
        "value": 138.975,
        "_internal_originalTime": 1689730934000
    },
    {
        "time": 1689730936000,
        "value": 138.975,
        "_internal_originalTime": 1689730936000
    },
    {
        "time": 1689730940000,
        "value": 138.974,
        "_internal_originalTime": 1689730940000
    },
    {
        "time": 1689730942000,
        "value": 138.976,
        "_internal_originalTime": 1689730942000
    },
    {
        "time": 1689730943000,
        "value": 138.972,
        "_internal_originalTime": 1689730943000
    },
    {
        "time": 1689730944000,
        "value": 138.973,
        "_internal_originalTime": 1689730944000
    },
    {
        "time": 1689730947000,
        "value": 138.975,
        "_internal_originalTime": 1689730947000
    },
    {
        "time": 1689730949000,
        "value": 138.972,
        "_internal_originalTime": 1689730949000
    },
    {
        "time": 1689730950000,
        "value": 138.973,
        "_internal_originalTime": 1689730950000
    },
    {
        "time": 1689730952000,
        "value": 138.975,
        "_internal_originalTime": 1689730952000
    },
    {
        "time": 1689730954000,
        "value": 138.989,
        "_internal_originalTime": 1689730954000
    },
    {
        "time": 1689730956000,
        "value": 138.98,
        "_internal_originalTime": 1689730956000
    },
    {
        "time": 1689730957000,
        "value": 138.985,
        "_internal_originalTime": 1689730957000
    },
    {
        "time": 1689730959000,
        "value": 138.978,
        "_internal_originalTime": 1689730959000
    },
    {
        "time": 1689730961000,
        "value": 138.981,
        "_internal_originalTime": 1689730961000
    },
    {
        "time": 1689730962000,
        "value": 138.982,
        "_internal_originalTime": 1689730962000
    },
    {
        "time": 1689730967000,
        "value": 138.983,
        "_internal_originalTime": 1689730967000
    },
    {
        "time": 1689730968000,
        "value": 138.983,
        "_internal_originalTime": 1689730968000
    },
    {
        "time": 1689730970000,
        "value": 138.982,
        "_internal_originalTime": 1689730970000
    },
    {
        "time": 1689730972000,
        "value": 138.977,
        "_internal_originalTime": 1689730972000
    },
    {
        "time": 1689730973000,
        "value": 138.974,
        "_internal_originalTime": 1689730973000
    },
    {
        "time": 1689730976000,
        "value": 138.969,
        "_internal_originalTime": 1689730976000
    },
    {
        "time": 1689730977000,
        "value": 138.974,
        "_internal_originalTime": 1689730977000
    },
    {
        "time": 1689730979000,
        "value": 138.975,
        "_internal_originalTime": 1689730979000
    },
    {
        "time": 1689730981000,
        "value": 138.95,
        "_internal_originalTime": 1689730981000
    },
    {
        "time": 1689730985000,
        "value": 138.972,
        "_internal_originalTime": 1689730985000
    },
    {
        "time": 1689730986000,
        "value": 138.973,
        "_internal_originalTime": 1689730986000
    },
    {
        "time": 1689730989000,
        "value": 138.973,
        "_internal_originalTime": 1689730989000
    },
    {
        "time": 1689730991000,
        "value": 138.972,
        "_internal_originalTime": 1689730991000
    },
    {
        "time": 1689730993000,
        "value": 138.973,
        "_internal_originalTime": 1689730993000
    },
    {
        "time": 1689730995000,
        "value": 138.97,
        "_internal_originalTime": 1689730995000
    },
    {
        "time": 1689730997000,
        "value": 138.975,
        "_internal_originalTime": 1689730997000
    },
    {
        "time": 1689730999000,
        "value": 138.973,
        "_internal_originalTime": 1689730999000
    },
    {
        "time": 1689731001000,
        "value": 138.972,
        "_internal_originalTime": 1689731001000
    },
    {
        "time": 1689731002000,
        "value": 138.983,
        "_internal_originalTime": 1689731002000
    },
    {
        "time": 1689731004000,
        "value": 138.981,
        "_internal_originalTime": 1689731004000
    },
    {
        "time": 1689731007000,
        "value": 138.978,
        "_internal_originalTime": 1689731007000
    },
    {
        "time": 1689731008000,
        "value": 138.977,
        "_internal_originalTime": 1689731008000
    },
    {
        "time": 1689731009000,
        "value": 138.97,
        "_internal_originalTime": 1689731009000
    },
    {
        "time": 1689731011000,
        "value": 138.975,
        "_internal_originalTime": 1689731011000
    },
    {
        "time": 1689731013000,
        "value": 138.973,
        "_internal_originalTime": 1689731013000
    },
    {
        "time": 1689731015000,
        "value": 138.974,
        "_internal_originalTime": 1689731015000
    },
    {
        "time": 1689731017000,
        "value": 138.973,
        "_internal_originalTime": 1689731017000
    },
    {
        "time": 1689731020000,
        "value": 138.972,
        "_internal_originalTime": 1689731020000
    },
    {
        "time": 1689731022000,
        "value": 138.972,
        "_internal_originalTime": 1689731022000
    },
    {
        "time": 1689731024000,
        "value": 138.968,
        "_internal_originalTime": 1689731024000
    },
    {
        "time": 1689731025000,
        "value": 138.973,
        "_internal_originalTime": 1689731025000
    },
    {
        "time": 1689731028000,
        "value": 138.973,
        "_internal_originalTime": 1689731028000
    },
    {
        "time": 1689731030000,
        "value": 138.971,
        "_internal_originalTime": 1689731030000
    },
    {
        "time": 1689731032000,
        "value": 138.973,
        "_internal_originalTime": 1689731032000
    },
    {
        "time": 1689731033000,
        "value": 138.966,
        "_internal_originalTime": 1689731033000
    },
    {
        "time": 1689731035000,
        "value": 138.973,
        "_internal_originalTime": 1689731035000
    },
    {
        "time": 1689731039000,
        "value": 138.974,
        "_internal_originalTime": 1689731039000
    },
    {
        "time": 1689731040000,
        "value": 138.973,
        "_internal_originalTime": 1689731040000
    },
    {
        "time": 1689731042000,
        "value": 138.974,
        "_internal_originalTime": 1689731042000
    },
    {
        "time": 1689731043000,
        "value": 138.975,
        "_internal_originalTime": 1689731043000
    },
    {
        "time": 1689731046000,
        "value": 138.973,
        "_internal_originalTime": 1689731046000
    },
    {
        "time": 1689731047000,
        "value": 138.974,
        "_internal_originalTime": 1689731047000
    },
    {
        "time": 1689731049000,
        "value": 138.973,
        "_internal_originalTime": 1689731049000
    },
    {
        "time": 1689731051000,
        "value": 138.97,
        "_internal_originalTime": 1689731051000
    },
    {
        "time": 1689731052000,
        "value": 138.973,
        "_internal_originalTime": 1689731052000
    },
    {
        "time": 1689731054000,
        "value": 138.97,
        "_internal_originalTime": 1689731054000
    },
    {
        "time": 1689731055000,
        "value": 138.971,
        "_internal_originalTime": 1689731055000
    },
    {
        "time": 1689731056000,
        "value": 138.97,
        "_internal_originalTime": 1689731056000
    },
    {
        "time": 1689731060000,
        "value": 138.971,
        "_internal_originalTime": 1689731060000
    },
    {
        "time": 1689731062000,
        "value": 138.973,
        "_internal_originalTime": 1689731062000
    },
    {
        "time": 1689731063000,
        "value": 138.967,
        "_internal_originalTime": 1689731063000
    },
    {
        "time": 1689731066000,
        "value": 138.973,
        "_internal_originalTime": 1689731066000
    },
    {
        "time": 1689731068000,
        "value": 138.976,
        "_internal_originalTime": 1689731068000
    },
    {
        "time": 1689731069000,
        "value": 138.974,
        "_internal_originalTime": 1689731069000
    },
    {
        "time": 1689731071000,
        "value": 138.975,
        "_internal_originalTime": 1689731071000
    },
    {
        "time": 1689731072000,
        "value": 138.975,
        "_internal_originalTime": 1689731072000
    },
    {
        "time": 1689731076000,
        "value": 138.972,
        "_internal_originalTime": 1689731076000
    },
    {
        "time": 1689731078000,
        "value": 138.972,
        "_internal_originalTime": 1689731078000
    },
    {
        "time": 1689731081000,
        "value": 138.97,
        "_internal_originalTime": 1689731081000
    },
    {
        "time": 1689731082000,
        "value": 138.975,
        "_internal_originalTime": 1689731082000
    },
    {
        "time": 1689731084000,
        "value": 138.975,
        "_internal_originalTime": 1689731084000
    },
    {
        "time": 1689731090000,
        "value": 138.974,
        "_internal_originalTime": 1689731090000
    },
    {
        "time": 1689731091000,
        "value": 138.97,
        "_internal_originalTime": 1689731091000
    },
    {
        "time": 1689731093000,
        "value": 138.967,
        "_internal_originalTime": 1689731093000
    },
    {
        "time": 1689731095000,
        "value": 138.974,
        "_internal_originalTime": 1689731095000
    },
    {
        "time": 1689731097000,
        "value": 138.974,
        "_internal_originalTime": 1689731097000
    },
    {
        "time": 1689731099000,
        "value": 138.974,
        "_internal_originalTime": 1689731099000
    },
    {
        "time": 1689731100000,
        "value": 138.973,
        "_internal_originalTime": 1689731100000
    },
    {
        "time": 1689731101000,
        "value": 138.97,
        "_internal_originalTime": 1689731101000
    },
    {
        "time": 1689731102000,
        "value": 138.974,
        "_internal_originalTime": 1689731102000
    },
    {
        "time": 1689731103000,
        "value": 138.965,
        "_internal_originalTime": 1689731103000
    },
    {
        "time": 1689731104000,
        "value": 138.966,
        "_internal_originalTime": 1689731104000
    },
    {
        "time": 1689731105000,
        "value": 138.964,
        "_internal_originalTime": 1689731105000
    },
    {
        "time": 1689731106000,
        "value": 138.966,
        "_internal_originalTime": 1689731106000
    },
    {
        "time": 1689731107000,
        "value": 138.965,
        "_internal_originalTime": 1689731107000
    },
    {
        "time": 1689731108000,
        "value": 138.965,
        "_internal_originalTime": 1689731108000
    },
    {
        "time": 1689731110000,
        "value": 138.969,
        "_internal_originalTime": 1689731110000
    },
    {
        "time": 1689731111000,
        "value": 138.969,
        "_internal_originalTime": 1689731111000
    },
    {
        "time": 1689731112000,
        "value": 138.969,
        "_internal_originalTime": 1689731112000
    },
    {
        "time": 1689731113000,
        "value": 138.971,
        "_internal_originalTime": 1689731113000
    },
    {
        "time": 1689731114000,
        "value": 138.97,
        "_internal_originalTime": 1689731114000
    },
    {
        "time": 1689731115000,
        "value": 138.972,
        "_internal_originalTime": 1689731115000
    },
    {
        "time": 1689731117000,
        "value": 138.971,
        "_internal_originalTime": 1689731117000
    },
    {
        "time": 1689731119000,
        "value": 138.971,
        "_internal_originalTime": 1689731119000
    },
    {
        "time": 1689731120000,
        "value": 138.972,
        "_internal_originalTime": 1689731120000
    },
    {
        "time": 1689731123000,
        "value": 138.966,
        "_internal_originalTime": 1689731123000
    },
    {
        "time": 1689731124000,
        "value": 138.968,
        "_internal_originalTime": 1689731124000
    },
    {
        "time": 1689731125000,
        "value": 138.971,
        "_internal_originalTime": 1689731125000
    },
    {
        "time": 1689731127000,
        "value": 138.973,
        "_internal_originalTime": 1689731127000
    },
    {
        "time": 1689731128000,
        "value": 138.971,
        "_internal_originalTime": 1689731128000
    },
    {
        "time": 1689731130000,
        "value": 138.969,
        "_internal_originalTime": 1689731130000
    },
    {
        "time": 1689731132000,
        "value": 138.968,
        "_internal_originalTime": 1689731132000
    },
    {
        "time": 1689731133000,
        "value": 138.972,
        "_internal_originalTime": 1689731133000
    },
    {
        "time": 1689731134000,
        "value": 138.973,
        "_internal_originalTime": 1689731134000
    },
    {
        "time": 1689731135000,
        "value": 138.981,
        "_internal_originalTime": 1689731135000
    },
    {
        "time": 1689731136000,
        "value": 138.98,
        "_internal_originalTime": 1689731136000
    },
    {
        "time": 1689731137000,
        "value": 138.978,
        "_internal_originalTime": 1689731137000
    },
    {
        "time": 1689731138000,
        "value": 138.981,
        "_internal_originalTime": 1689731138000
    },
    {
        "time": 1689731140000,
        "value": 138.976,
        "_internal_originalTime": 1689731140000
    },
    {
        "time": 1689731141000,
        "value": 138.982,
        "_internal_originalTime": 1689731141000
    },
    {
        "time": 1689731143000,
        "value": 138.981,
        "_internal_originalTime": 1689731143000
    },
    {
        "time": 1689731144000,
        "value": 138.98,
        "_internal_originalTime": 1689731144000
    },
    {
        "time": 1689731146000,
        "value": 138.982,
        "_internal_originalTime": 1689731146000
    },
    {
        "time": 1689731148000,
        "value": 138.978,
        "_internal_originalTime": 1689731148000
    },
    {
        "time": 1689731151000,
        "value": 138.994,
        "_internal_originalTime": 1689731151000
    },
    {
        "time": 1689731152000,
        "value": 138.99,
        "_internal_originalTime": 1689731152000
    },
    {
        "time": 1689731153000,
        "value": 138.983,
        "_internal_originalTime": 1689731153000
    },
    {
        "time": 1689731155000,
        "value": 138.985,
        "_internal_originalTime": 1689731155000
    },
    {
        "time": 1689731156000,
        "value": 138.988,
        "_internal_originalTime": 1689731156000
    },
    {
        "time": 1689731157000,
        "value": 138.992,
        "_internal_originalTime": 1689731157000
    },
    {
        "time": 1689731158000,
        "value": 138.992,
        "_internal_originalTime": 1689731158000
    },
    {
        "time": 1689731159000,
        "value": 138.989,
        "_internal_originalTime": 1689731159000
    },
    {
        "time": 1689731160000,
        "value": 138.991,
        "_internal_originalTime": 1689731160000
    },
    {
        "time": 1689731161000,
        "value": 138.97,
        "_internal_originalTime": 1689731161000
    },
    {
        "time": 1689731163000,
        "value": 138.992,
        "_internal_originalTime": 1689731163000
    },
    {
        "time": 1689731166000,
        "value": 138.993,
        "_internal_originalTime": 1689731166000
    },
    {
        "time": 1689731167000,
        "value": 138.991,
        "_internal_originalTime": 1689731167000
    },
    {
        "time": 1689731168000,
        "value": 138.99,
        "_internal_originalTime": 1689731168000
    },
    {
        "time": 1689731169000,
        "value": 138.995,
        "_internal_originalTime": 1689731169000
    },
    {
        "time": 1689731172000,
        "value": 138.994,
        "_internal_originalTime": 1689731172000
    },
    {
        "time": 1689731173000,
        "value": 138.995,
        "_internal_originalTime": 1689731173000
    },
    {
        "time": 1689731174000,
        "value": 138.996,
        "_internal_originalTime": 1689731174000
    },
    {
        "time": 1689731175000,
        "value": 138.992,
        "_internal_originalTime": 1689731175000
    },
    {
        "time": 1689731176000,
        "value": 138.992,
        "_internal_originalTime": 1689731176000
    },
    {
        "time": 1689731177000,
        "value": 138.995,
        "_internal_originalTime": 1689731177000
    },
    {
        "time": 1689731178000,
        "value": 138.995,
        "_internal_originalTime": 1689731178000
    },
    {
        "time": 1689731179000,
        "value": 138.995,
        "_internal_originalTime": 1689731179000
    },
    {
        "time": 1689731181000,
        "value": 138.993,
        "_internal_originalTime": 1689731181000
    },
    {
        "time": 1689731182000,
        "value": 138.993,
        "_internal_originalTime": 1689731182000
    },
    {
        "time": 1689731183000,
        "value": 138.993,
        "_internal_originalTime": 1689731183000
    },
    {
        "time": 1689731184000,
        "value": 138.994,
        "_internal_originalTime": 1689731184000
    },
    {
        "time": 1689731187000,
        "value": 139.016,
        "_internal_originalTime": 1689731187000
    },
    {
        "time": 1689731188000,
        "value": 139.013,
        "_internal_originalTime": 1689731188000
    },
    {
        "time": 1689731189000,
        "value": 139.01,
        "_internal_originalTime": 1689731189000
    },
    {
        "time": 1689731190000,
        "value": 139.01,
        "_internal_originalTime": 1689731190000
    },
    {
        "time": 1689731191000,
        "value": 139.013,
        "_internal_originalTime": 1689731191000
    },
    {
        "time": 1689731192000,
        "value": 139.009,
        "_internal_originalTime": 1689731192000
    },
    {
        "time": 1689731193000,
        "value": 139.008,
        "_internal_originalTime": 1689731193000
    },
    {
        "time": 1689731194000,
        "value": 139.005,
        "_internal_originalTime": 1689731194000
    },
    {
        "time": 1689731195000,
        "value": 139.005,
        "_internal_originalTime": 1689731195000
    },
    {
        "time": 1689731196000,
        "value": 139.002,
        "_internal_originalTime": 1689731196000
    },
    {
        "time": 1689731197000,
        "value": 138.999,
        "_internal_originalTime": 1689731197000
    },
    {
        "time": 1689731199000,
        "value": 138.999,
        "_internal_originalTime": 1689731199000
    },
    {
        "time": 1689731200000,
        "value": 139.002,
        "_internal_originalTime": 1689731200000
    },
    {
        "time": 1689731201000,
        "value": 138.999,
        "_internal_originalTime": 1689731201000
    },
    {
        "time": 1689731202000,
        "value": 138.999,
        "_internal_originalTime": 1689731202000
    },
    {
        "time": 1689731203000,
        "value": 139,
        "_internal_originalTime": 1689731203000
    },
    {
        "time": 1689731204000,
        "value": 139,
        "_internal_originalTime": 1689731204000
    },
    {
        "time": 1689731206000,
        "value": 138.998,
        "_internal_originalTime": 1689731206000
    },
    {
        "time": 1689731207000,
        "value": 138.999,
        "_internal_originalTime": 1689731207000
    },
    {
        "time": 1689731210000,
        "value": 139.005,
        "_internal_originalTime": 1689731210000
    },
    {
        "time": 1689731211000,
        "value": 139.004,
        "_internal_originalTime": 1689731211000
    },
    {
        "time": 1689731212000,
        "value": 139,
        "_internal_originalTime": 1689731212000
    },
    {
        "time": 1689731214000,
        "value": 139.001,
        "_internal_originalTime": 1689731214000
    },
    {
        "time": 1689731215000,
        "value": 139.001,
        "_internal_originalTime": 1689731215000
    },
    {
        "time": 1689731217000,
        "value": 139.006,
        "_internal_originalTime": 1689731217000
    },
    {
        "time": 1689731219000,
        "value": 139.004,
        "_internal_originalTime": 1689731219000
    },
    {
        "time": 1689731220000,
        "value": 138.99,
        "_internal_originalTime": 1689731220000
    },
    {
        "time": 1689731221000,
        "value": 138.99,
        "_internal_originalTime": 1689731221000
    },
    {
        "time": 1689731223000,
        "value": 138.996,
        "_internal_originalTime": 1689731223000
    },
    {
        "time": 1689731224000,
        "value": 138.993,
        "_internal_originalTime": 1689731224000
    },
    {
        "time": 1689731225000,
        "value": 138.992,
        "_internal_originalTime": 1689731225000
    },
    {
        "time": 1689731227000,
        "value": 138.997,
        "_internal_originalTime": 1689731227000
    },
    {
        "time": 1689731228000,
        "value": 138.998,
        "_internal_originalTime": 1689731228000
    },
    {
        "time": 1689731229000,
        "value": 138.997,
        "_internal_originalTime": 1689731229000
    },
    {
        "time": 1689731230000,
        "value": 138.996,
        "_internal_originalTime": 1689731230000
    },
    {
        "time": 1689731231000,
        "value": 138.997,
        "_internal_originalTime": 1689731231000
    },
    {
        "time": 1689731233000,
        "value": 138.999,
        "_internal_originalTime": 1689731233000
    },
    {
        "time": 1689731234000,
        "value": 138.998,
        "_internal_originalTime": 1689731234000
    },
    {
        "time": 1689731236000,
        "value": 138.997,
        "_internal_originalTime": 1689731236000
    },
    {
        "time": 1689731237000,
        "value": 139.006,
        "_internal_originalTime": 1689731237000
    },
    {
        "time": 1689731238000,
        "value": 139.004,
        "_internal_originalTime": 1689731238000
    },
    {
        "time": 1689731239000,
        "value": 139.004,
        "_internal_originalTime": 1689731239000
    },
    {
        "time": 1689731240000,
        "value": 139.005,
        "_internal_originalTime": 1689731240000
    },
    {
        "time": 1689731241000,
        "value": 139.008,
        "_internal_originalTime": 1689731241000
    },
    {
        "time": 1689731242000,
        "value": 139.01,
        "_internal_originalTime": 1689731242000
    },
    {
        "time": 1689731243000,
        "value": 139.011,
        "_internal_originalTime": 1689731243000
    },
    {
        "time": 1689731245000,
        "value": 139.007,
        "_internal_originalTime": 1689731245000
    },
    {
        "time": 1689731246000,
        "value": 139.008,
        "_internal_originalTime": 1689731246000
    },
    {
        "time": 1689731247000,
        "value": 139.01,
        "_internal_originalTime": 1689731247000
    },
    {
        "time": 1689731248000,
        "value": 139.009,
        "_internal_originalTime": 1689731248000
    },
    {
        "time": 1689731249000,
        "value": 139.012,
        "_internal_originalTime": 1689731249000
    },
    {
        "time": 1689731250000,
        "value": 139.012,
        "_internal_originalTime": 1689731250000
    },
    {
        "time": 1689731251000,
        "value": 139.013,
        "_internal_originalTime": 1689731251000
    },
    {
        "time": 1689731252000,
        "value": 139.012,
        "_internal_originalTime": 1689731252000
    },
    {
        "time": 1689731256000,
        "value": 139.024,
        "_internal_originalTime": 1689731256000
    },
    {
        "time": 1689731257000,
        "value": 139.022,
        "_internal_originalTime": 1689731257000
    },
    {
        "time": 1689731258000,
        "value": 139.022,
        "_internal_originalTime": 1689731258000
    },
    {
        "time": 1689731260000,
        "value": 139.018,
        "_internal_originalTime": 1689731260000
    },
    {
        "time": 1689731261000,
        "value": 139.018,
        "_internal_originalTime": 1689731261000
    },
    {
        "time": 1689731262000,
        "value": 139.022,
        "_internal_originalTime": 1689731262000
    },
    {
        "time": 1689731263000,
        "value": 139.016,
        "_internal_originalTime": 1689731263000
    },
    {
        "time": 1689731264000,
        "value": 139.021,
        "_internal_originalTime": 1689731264000
    },
    {
        "time": 1689731266000,
        "value": 139.021,
        "_internal_originalTime": 1689731266000
    },
    {
        "time": 1689731267000,
        "value": 139.022,
        "_internal_originalTime": 1689731267000
    },
    {
        "time": 1689731269000,
        "value": 139.018,
        "_internal_originalTime": 1689731269000
    },
    {
        "time": 1689731270000,
        "value": 139.018,
        "_internal_originalTime": 1689731270000
    },
    {
        "time": 1689731271000,
        "value": 139.033,
        "_internal_originalTime": 1689731271000
    },
    {
        "time": 1689731272000,
        "value": 139.034,
        "_internal_originalTime": 1689731272000
    },
    {
        "time": 1689731273000,
        "value": 139.033,
        "_internal_originalTime": 1689731273000
    },
    {
        "time": 1689731274000,
        "value": 139.031,
        "_internal_originalTime": 1689731274000
    },
    {
        "time": 1689731275000,
        "value": 139.037,
        "_internal_originalTime": 1689731275000
    },
    {
        "time": 1689731277000,
        "value": 139.028,
        "_internal_originalTime": 1689731277000
    },
    {
        "time": 1689731279000,
        "value": 139.026,
        "_internal_originalTime": 1689731279000
    },
    {
        "time": 1689731281000,
        "value": 139.033,
        "_internal_originalTime": 1689731281000
    },
    {
        "time": 1689731282000,
        "value": 139.03,
        "_internal_originalTime": 1689731282000
    },
    {
        "time": 1689731283000,
        "value": 139.03,
        "_internal_originalTime": 1689731283000
    },
    {
        "time": 1689731284000,
        "value": 139.034,
        "_internal_originalTime": 1689731284000
    },
    {
        "time": 1689731285000,
        "value": 139.033,
        "_internal_originalTime": 1689731285000
    },
    {
        "time": 1689731287000,
        "value": 139.035,
        "_internal_originalTime": 1689731287000
    },
    {
        "time": 1689731288000,
        "value": 139.037,
        "_internal_originalTime": 1689731288000
    },
    {
        "time": 1689731290000,
        "value": 139.034,
        "_internal_originalTime": 1689731290000
    },
    {
        "time": 1689731291000,
        "value": 139.039,
        "_internal_originalTime": 1689731291000
    },
    {
        "time": 1689731292000,
        "value": 139.034,
        "_internal_originalTime": 1689731292000
    },
    {
        "time": 1689731293000,
        "value": 139.03,
        "_internal_originalTime": 1689731293000
    },
    {
        "time": 1689731294000,
        "value": 139.038,
        "_internal_originalTime": 1689731294000
    },
    {
        "time": 1689731296000,
        "value": 139.036,
        "_internal_originalTime": 1689731296000
    },
    {
        "time": 1689731297000,
        "value": 139.038,
        "_internal_originalTime": 1689731297000
    },
    {
        "time": 1689731299000,
        "value": 139.036,
        "_internal_originalTime": 1689731299000
    },
    {
        "time": 1689731300000,
        "value": 139.035,
        "_internal_originalTime": 1689731300000
    },
    {
        "time": 1689731301000,
        "value": 139.037,
        "_internal_originalTime": 1689731301000
    },
    {
        "time": 1689731302000,
        "value": 139.035,
        "_internal_originalTime": 1689731302000
    },
    {
        "time": 1689731303000,
        "value": 139.036,
        "_internal_originalTime": 1689731303000
    },
    {
        "time": 1689731305000,
        "value": 139.034,
        "_internal_originalTime": 1689731305000
    },
    {
        "time": 1689731306000,
        "value": 139.04,
        "_internal_originalTime": 1689731306000
    },
    {
        "time": 1689731307000,
        "value": 139.036,
        "_internal_originalTime": 1689731307000
    },
    {
        "time": 1689731311000,
        "value": 139.041,
        "_internal_originalTime": 1689731311000
    },
    {
        "time": 1689731313000,
        "value": 139.039,
        "_internal_originalTime": 1689731313000
    },
    {
        "time": 1689731314000,
        "value": 139.036,
        "_internal_originalTime": 1689731314000
    },
    {
        "time": 1689731316000,
        "value": 139.045,
        "_internal_originalTime": 1689731316000
    },
    {
        "time": 1689731320000,
        "value": 139.056,
        "_internal_originalTime": 1689731320000
    },
    {
        "time": 1689731321000,
        "value": 139.057,
        "_internal_originalTime": 1689731321000
    },
    {
        "time": 1689731323000,
        "value": 139.059,
        "_internal_originalTime": 1689731323000
    },
    {
        "time": 1689731324000,
        "value": 139.062,
        "_internal_originalTime": 1689731324000
    },
    {
        "time": 1689731325000,
        "value": 139.061,
        "_internal_originalTime": 1689731325000
    },
    {
        "time": 1689731326000,
        "value": 139.064,
        "_internal_originalTime": 1689731326000
    },
    {
        "time": 1689731327000,
        "value": 139.069,
        "_internal_originalTime": 1689731327000
    },
    {
        "time": 1689731329000,
        "value": 139.064,
        "_internal_originalTime": 1689731329000
    },
    {
        "time": 1689731330000,
        "value": 139.068,
        "_internal_originalTime": 1689731330000
    },
    {
        "time": 1689731331000,
        "value": 139.069,
        "_internal_originalTime": 1689731331000
    },
    {
        "time": 1689731332000,
        "value": 139.066,
        "_internal_originalTime": 1689731332000
    },
    {
        "time": 1689731333000,
        "value": 139.068,
        "_internal_originalTime": 1689731333000
    },
    {
        "time": 1689731334000,
        "value": 139.066,
        "_internal_originalTime": 1689731334000
    },
    {
        "time": 1689731335000,
        "value": 139.07,
        "_internal_originalTime": 1689731335000
    },
    {
        "time": 1689731336000,
        "value": 139.065,
        "_internal_originalTime": 1689731336000
    },
    {
        "time": 1689731338000,
        "value": 139.07,
        "_internal_originalTime": 1689731338000
    },
    {
        "time": 1689731339000,
        "value": 139.07,
        "_internal_originalTime": 1689731339000
    },
    {
        "time": 1689731340000,
        "value": 139.05,
        "_internal_originalTime": 1689731340000
    },
    {
        "time": 1689731342000,
        "value": 139.059,
        "_internal_originalTime": 1689731342000
    },
    {
        "time": 1689731343000,
        "value": 139.058,
        "_internal_originalTime": 1689731343000
    },
    {
        "time": 1689731344000,
        "value": 139.063,
        "_internal_originalTime": 1689731344000
    },
    {
        "time": 1689731345000,
        "value": 139.064,
        "_internal_originalTime": 1689731345000
    },
    {
        "time": 1689731347000,
        "value": 139.056,
        "_internal_originalTime": 1689731347000
    },
    {
        "time": 1689731348000,
        "value": 139.045,
        "_internal_originalTime": 1689731348000
    },
    {
        "time": 1689731350000,
        "value": 139.039,
        "_internal_originalTime": 1689731350000
    },
    {
        "time": 1689731352000,
        "value": 139.037,
        "_internal_originalTime": 1689731352000
    },
    {
        "time": 1689731353000,
        "value": 139.037,
        "_internal_originalTime": 1689731353000
    },
    {
        "time": 1689731354000,
        "value": 139.036,
        "_internal_originalTime": 1689731354000
    },
    {
        "time": 1689731355000,
        "value": 139.038,
        "_internal_originalTime": 1689731355000
    },
    {
        "time": 1689731358000,
        "value": 139.034,
        "_internal_originalTime": 1689731358000
    },
    {
        "time": 1689731359000,
        "value": 139.036,
        "_internal_originalTime": 1689731359000
    },
    {
        "time": 1689731360000,
        "value": 139.034,
        "_internal_originalTime": 1689731360000
    },
    {
        "time": 1689731361000,
        "value": 139.03,
        "_internal_originalTime": 1689731361000
    },
    {
        "time": 1689731363000,
        "value": 139.027,
        "_internal_originalTime": 1689731363000
    },
    {
        "time": 1689731364000,
        "value": 139.026,
        "_internal_originalTime": 1689731364000
    },
    {
        "time": 1689731366000,
        "value": 139.027,
        "_internal_originalTime": 1689731366000
    },
    {
        "time": 1689731367000,
        "value": 139.032,
        "_internal_originalTime": 1689731367000
    },
    {
        "time": 1689731368000,
        "value": 139.029,
        "_internal_originalTime": 1689731368000
    },
    {
        "time": 1689731369000,
        "value": 139.028,
        "_internal_originalTime": 1689731369000
    },
    {
        "time": 1689731370000,
        "value": 139.026,
        "_internal_originalTime": 1689731370000
    },
    {
        "time": 1689731371000,
        "value": 139.024,
        "_internal_originalTime": 1689731371000
    },
    {
        "time": 1689731372000,
        "value": 139.023,
        "_internal_originalTime": 1689731372000
    },
    {
        "time": 1689731373000,
        "value": 139.025,
        "_internal_originalTime": 1689731373000
    },
    {
        "time": 1689731374000,
        "value": 139.025,
        "_internal_originalTime": 1689731374000
    },
    {
        "time": 1689731376000,
        "value": 139.029,
        "_internal_originalTime": 1689731376000
    },
    {
        "time": 1689731377000,
        "value": 139.023,
        "_internal_originalTime": 1689731377000
    },
    {
        "time": 1689731378000,
        "value": 139.022,
        "_internal_originalTime": 1689731378000
    },
    {
        "time": 1689731379000,
        "value": 139.024,
        "_internal_originalTime": 1689731379000
    },
    {
        "time": 1689731380000,
        "value": 139.026,
        "_internal_originalTime": 1689731380000
    },
    {
        "time": 1689731382000,
        "value": 139.026,
        "_internal_originalTime": 1689731382000
    },
    {
        "time": 1689731383000,
        "value": 139.028,
        "_internal_originalTime": 1689731383000
    },
    {
        "time": 1689731387000,
        "value": 139.032,
        "_internal_originalTime": 1689731387000
    },
    {
        "time": 1689731389000,
        "value": 139.033,
        "_internal_originalTime": 1689731389000
    },
    {
        "time": 1689731390000,
        "value": 139.03,
        "_internal_originalTime": 1689731390000
    },
    {
        "time": 1689731392000,
        "value": 139.029,
        "_internal_originalTime": 1689731392000
    },
    {
        "time": 1689731393000,
        "value": 139.026,
        "_internal_originalTime": 1689731393000
    },
    {
        "time": 1689731395000,
        "value": 139.034,
        "_internal_originalTime": 1689731395000
    },
    {
        "time": 1689731396000,
        "value": 139.036,
        "_internal_originalTime": 1689731396000
    },
    {
        "time": 1689731397000,
        "value": 139.034,
        "_internal_originalTime": 1689731397000
    },
    {
        "time": 1689731398000,
        "value": 139.028,
        "_internal_originalTime": 1689731398000
    },
    {
        "time": 1689731399000,
        "value": 139.034,
        "_internal_originalTime": 1689731399000
    },
    {
        "time": 1689731401000,
        "value": 139.032,
        "_internal_originalTime": 1689731401000
    },
    {
        "time": 1689731402000,
        "value": 139.031,
        "_internal_originalTime": 1689731402000
    },
    {
        "time": 1689731403000,
        "value": 139.039,
        "_internal_originalTime": 1689731403000
    },
    {
        "time": 1689731404000,
        "value": 139.035,
        "_internal_originalTime": 1689731404000
    },
    {
        "time": 1689731405000,
        "value": 139.034,
        "_internal_originalTime": 1689731405000
    },
    {
        "time": 1689731408000,
        "value": 139.039,
        "_internal_originalTime": 1689731408000
    },
    {
        "time": 1689731409000,
        "value": 139.044,
        "_internal_originalTime": 1689731409000
    },
    {
        "time": 1689731410000,
        "value": 139.044,
        "_internal_originalTime": 1689731410000
    },
    {
        "time": 1689731412000,
        "value": 139.042,
        "_internal_originalTime": 1689731412000
    },
    {
        "time": 1689731414000,
        "value": 139.046,
        "_internal_originalTime": 1689731414000
    },
    {
        "time": 1689731415000,
        "value": 139.045,
        "_internal_originalTime": 1689731415000
    },
    {
        "time": 1689731419000,
        "value": 139.04,
        "_internal_originalTime": 1689731419000
    },
    {
        "time": 1689731420000,
        "value": 139.041,
        "_internal_originalTime": 1689731420000
    },
    {
        "time": 1689731421000,
        "value": 139.038,
        "_internal_originalTime": 1689731421000
    },
    {
        "time": 1689731422000,
        "value": 139.042,
        "_internal_originalTime": 1689731422000
    },
    {
        "time": 1689731424000,
        "value": 139.033,
        "_internal_originalTime": 1689731424000
    },
    {
        "time": 1689731425000,
        "value": 139.028,
        "_internal_originalTime": 1689731425000
    },
    {
        "time": 1689731427000,
        "value": 139.027,
        "_internal_originalTime": 1689731427000
    },
    {
        "time": 1689731430000,
        "value": 139.033,
        "_internal_originalTime": 1689731430000
    },
    {
        "time": 1689731431000,
        "value": 139.033,
        "_internal_originalTime": 1689731431000
    },
    {
        "time": 1689731434000,
        "value": 139.027,
        "_internal_originalTime": 1689731434000
    },
    {
        "time": 1689731435000,
        "value": 139.029,
        "_internal_originalTime": 1689731435000
    },
    {
        "time": 1689731437000,
        "value": 139.026,
        "_internal_originalTime": 1689731437000
    },
    {
        "time": 1689731438000,
        "value": 139.017,
        "_internal_originalTime": 1689731438000
    },
    {
        "time": 1689731439000,
        "value": 139.014,
        "_internal_originalTime": 1689731439000
    },
    {
        "time": 1689731440000,
        "value": 139.012,
        "_internal_originalTime": 1689731440000
    },
    {
        "time": 1689731442000,
        "value": 139.016,
        "_internal_originalTime": 1689731442000
    },
    {
        "time": 1689731443000,
        "value": 139.015,
        "_internal_originalTime": 1689731443000
    },
    {
        "time": 1689731444000,
        "value": 139.015,
        "_internal_originalTime": 1689731444000
    },
    {
        "time": 1689731445000,
        "value": 139.014,
        "_internal_originalTime": 1689731445000
    },
    {
        "time": 1689731447000,
        "value": 139.013,
        "_internal_originalTime": 1689731447000
    },
    {
        "time": 1689731450000,
        "value": 139.029,
        "_internal_originalTime": 1689731450000
    },
    {
        "time": 1689731451000,
        "value": 139.034,
        "_internal_originalTime": 1689731451000
    },
    {
        "time": 1689731452000,
        "value": 139.028,
        "_internal_originalTime": 1689731452000
    },
    {
        "time": 1689731453000,
        "value": 139.028,
        "_internal_originalTime": 1689731453000
    },
    {
        "time": 1689731454000,
        "value": 139.035,
        "_internal_originalTime": 1689731454000
    },
    {
        "time": 1689731456000,
        "value": 139.035,
        "_internal_originalTime": 1689731456000
    },
    {
        "time": 1689731458000,
        "value": 139.035,
        "_internal_originalTime": 1689731458000
    },
    {
        "time": 1689731459000,
        "value": 139.034,
        "_internal_originalTime": 1689731459000
    },
    {
        "time": 1689731461000,
        "value": 139.04,
        "_internal_originalTime": 1689731461000
    },
    {
        "time": 1689731462000,
        "value": 139.039,
        "_internal_originalTime": 1689731462000
    },
    {
        "time": 1689731463000,
        "value": 139.04,
        "_internal_originalTime": 1689731463000
    },
    {
        "time": 1689731464000,
        "value": 139.042,
        "_internal_originalTime": 1689731464000
    },
    {
        "time": 1689731465000,
        "value": 139.032,
        "_internal_originalTime": 1689731465000
    },
    {
        "time": 1689731466000,
        "value": 139.03,
        "_internal_originalTime": 1689731466000
    },
    {
        "time": 1689731467000,
        "value": 139.03,
        "_internal_originalTime": 1689731467000
    },
    {
        "time": 1689731468000,
        "value": 139.036,
        "_internal_originalTime": 1689731468000
    },
    {
        "time": 1689731469000,
        "value": 139.034,
        "_internal_originalTime": 1689731469000
    },
    {
        "time": 1689731470000,
        "value": 139.043,
        "_internal_originalTime": 1689731470000
    },
    {
        "time": 1689731471000,
        "value": 139.044,
        "_internal_originalTime": 1689731471000
    },
    {
        "time": 1689731472000,
        "value": 139.044,
        "_internal_originalTime": 1689731472000
    },
    {
        "time": 1689731473000,
        "value": 139.044,
        "_internal_originalTime": 1689731473000
    },
    {
        "time": 1689731475000,
        "value": 139.043,
        "_internal_originalTime": 1689731475000
    },
    {
        "time": 1689731476000,
        "value": 139.039,
        "_internal_originalTime": 1689731476000
    },
    {
        "time": 1689731477000,
        "value": 139.039,
        "_internal_originalTime": 1689731477000
    },
    {
        "time": 1689731478000,
        "value": 139.034,
        "_internal_originalTime": 1689731478000
    },
    {
        "time": 1689731479000,
        "value": 139.037,
        "_internal_originalTime": 1689731479000
    },
    {
        "time": 1689731480000,
        "value": 139.039,
        "_internal_originalTime": 1689731480000
    },
    {
        "time": 1689731482000,
        "value": 139.033,
        "_internal_originalTime": 1689731482000
    },
    {
        "time": 1689731483000,
        "value": 139.031,
        "_internal_originalTime": 1689731483000
    },
    {
        "time": 1689731484000,
        "value": 139.03,
        "_internal_originalTime": 1689731484000
    },
    {
        "time": 1689731485000,
        "value": 139.03,
        "_internal_originalTime": 1689731485000
    },
    {
        "time": 1689731488000,
        "value": 139.033,
        "_internal_originalTime": 1689731488000
    },
    {
        "time": 1689731489000,
        "value": 139.034,
        "_internal_originalTime": 1689731489000
    },
    {
        "time": 1689731490000,
        "value": 139.028,
        "_internal_originalTime": 1689731490000
    },
    {
        "time": 1689731491000,
        "value": 139.03,
        "_internal_originalTime": 1689731491000
    },
    {
        "time": 1689731492000,
        "value": 139.035,
        "_internal_originalTime": 1689731492000
    },
    {
        "time": 1689731494000,
        "value": 139.033,
        "_internal_originalTime": 1689731494000
    },
    {
        "time": 1689731495000,
        "value": 139.032,
        "_internal_originalTime": 1689731495000
    },
    {
        "time": 1689731496000,
        "value": 139.033,
        "_internal_originalTime": 1689731496000
    },
    {
        "time": 1689731497000,
        "value": 139.032,
        "_internal_originalTime": 1689731497000
    },
    {
        "time": 1689731499000,
        "value": 139.037,
        "_internal_originalTime": 1689731499000
    },
    {
        "time": 1689731500000,
        "value": 139.032,
        "_internal_originalTime": 1689731500000
    },
    {
        "time": 1689731502000,
        "value": 139.038,
        "_internal_originalTime": 1689731502000
    },
    {
        "time": 1689731503000,
        "value": 139.04,
        "_internal_originalTime": 1689731503000
    },
    {
        "time": 1689731504000,
        "value": 139.041,
        "_internal_originalTime": 1689731504000
    },
    {
        "time": 1689731505000,
        "value": 139.043,
        "_internal_originalTime": 1689731505000
    },
    {
        "time": 1689731506000,
        "value": 139.04,
        "_internal_originalTime": 1689731506000
    },
    {
        "time": 1689731507000,
        "value": 139.042,
        "_internal_originalTime": 1689731507000
    },
    {
        "time": 1689731508000,
        "value": 139.043,
        "_internal_originalTime": 1689731508000
    },
    {
        "time": 1689731509000,
        "value": 139.04,
        "_internal_originalTime": 1689731509000
    },
    {
        "time": 1689731511000,
        "value": 139.038,
        "_internal_originalTime": 1689731511000
    },
    {
        "time": 1689731512000,
        "value": 139.042,
        "_internal_originalTime": 1689731512000
    },
    {
        "time": 1689731513000,
        "value": 139.038,
        "_internal_originalTime": 1689731513000
    },
    {
        "time": 1689731515000,
        "value": 139.046,
        "_internal_originalTime": 1689731515000
    },
    {
        "time": 1689731516000,
        "value": 139.053,
        "_internal_originalTime": 1689731516000
    },
    {
        "time": 1689731517000,
        "value": 139.052,
        "_internal_originalTime": 1689731517000
    },
    {
        "time": 1689731518000,
        "value": 139.058,
        "_internal_originalTime": 1689731518000
    },
    {
        "time": 1689731520000,
        "value": 139.058,
        "_internal_originalTime": 1689731520000
    },
    {
        "time": 1689731521000,
        "value": 139.053,
        "_internal_originalTime": 1689731521000
    },
    {
        "time": 1689731522000,
        "value": 139.06,
        "_internal_originalTime": 1689731522000
    },
    {
        "time": 1689731523000,
        "value": 139.061,
        "_internal_originalTime": 1689731523000
    },
    {
        "time": 1689731524000,
        "value": 139.057,
        "_internal_originalTime": 1689731524000
    },
    {
        "time": 1689731525000,
        "value": 139.057,
        "_internal_originalTime": 1689731525000
    },
    {
        "time": 1689731527000,
        "value": 139.067,
        "_internal_originalTime": 1689731527000
    },
    {
        "time": 1689731528000,
        "value": 139.071,
        "_internal_originalTime": 1689731528000
    },
    {
        "time": 1689731529000,
        "value": 139.067,
        "_internal_originalTime": 1689731529000
    },
    {
        "time": 1689731530000,
        "value": 139.064,
        "_internal_originalTime": 1689731530000
    },
    {
        "time": 1689731531000,
        "value": 139.065,
        "_internal_originalTime": 1689731531000
    },
    {
        "time": 1689731533000,
        "value": 139.064,
        "_internal_originalTime": 1689731533000
    },
    {
        "time": 1689731534000,
        "value": 139.064,
        "_internal_originalTime": 1689731534000
    },
    {
        "time": 1689731535000,
        "value": 139.07,
        "_internal_originalTime": 1689731535000
    },
    {
        "time": 1689731536000,
        "value": 139.068,
        "_internal_originalTime": 1689731536000
    },
    {
        "time": 1689731537000,
        "value": 139.064,
        "_internal_originalTime": 1689731537000
    },
    {
        "time": 1689731539000,
        "value": 139.065,
        "_internal_originalTime": 1689731539000
    },
    {
        "time": 1689731540000,
        "value": 139.072,
        "_internal_originalTime": 1689731540000
    },
    {
        "time": 1689731543000,
        "value": 139.064,
        "_internal_originalTime": 1689731543000
    },
    {
        "time": 1689731544000,
        "value": 139.067,
        "_internal_originalTime": 1689731544000
    },
    {
        "time": 1689731546000,
        "value": 139.064,
        "_internal_originalTime": 1689731546000
    },
    {
        "time": 1689731550000,
        "value": 139.063,
        "_internal_originalTime": 1689731550000
    },
    {
        "time": 1689731551000,
        "value": 139.062,
        "_internal_originalTime": 1689731551000
    },
    {
        "time": 1689731552000,
        "value": 139.068,
        "_internal_originalTime": 1689731552000
    },
    {
        "time": 1689731555000,
        "value": 139.06,
        "_internal_originalTime": 1689731555000
    },
    {
        "time": 1689731556000,
        "value": 139.063,
        "_internal_originalTime": 1689731556000
    },
    {
        "time": 1689731557000,
        "value": 139.046,
        "_internal_originalTime": 1689731557000
    },
    {
        "time": 1689731558000,
        "value": 139.046,
        "_internal_originalTime": 1689731558000
    },
    {
        "time": 1689731559000,
        "value": 139.05,
        "_internal_originalTime": 1689731559000
    },
    {
        "time": 1689731561000,
        "value": 139.057,
        "_internal_originalTime": 1689731561000
    },
    {
        "time": 1689731562000,
        "value": 139.056,
        "_internal_originalTime": 1689731562000
    },
    {
        "time": 1689731563000,
        "value": 139.058,
        "_internal_originalTime": 1689731563000
    },
    {
        "time": 1689731566000,
        "value": 139.058,
        "_internal_originalTime": 1689731566000
    },
    {
        "time": 1689731568000,
        "value": 139.054,
        "_internal_originalTime": 1689731568000
    },
    {
        "time": 1689731569000,
        "value": 139.052,
        "_internal_originalTime": 1689731569000
    },
    {
        "time": 1689731570000,
        "value": 139.048,
        "_internal_originalTime": 1689731570000
    },
    {
        "time": 1689731571000,
        "value": 139.058,
        "_internal_originalTime": 1689731571000
    },
    {
        "time": 1689731572000,
        "value": 139.058,
        "_internal_originalTime": 1689731572000
    },
    {
        "time": 1689731573000,
        "value": 139.058,
        "_internal_originalTime": 1689731573000
    },
    {
        "time": 1689731575000,
        "value": 139.056,
        "_internal_originalTime": 1689731575000
    },
    {
        "time": 1689731576000,
        "value": 139.061,
        "_internal_originalTime": 1689731576000
    },
    {
        "time": 1689731578000,
        "value": 139.061,
        "_internal_originalTime": 1689731578000
    },
    {
        "time": 1689731579000,
        "value": 139.059,
        "_internal_originalTime": 1689731579000
    },
    {
        "time": 1689731580000,
        "value": 139.057,
        "_internal_originalTime": 1689731580000
    },
    {
        "time": 1689731581000,
        "value": 139.059,
        "_internal_originalTime": 1689731581000
    },
    {
        "time": 1689731582000,
        "value": 139.056,
        "_internal_originalTime": 1689731582000
    },
    {
        "time": 1689731584000,
        "value": 139.059,
        "_internal_originalTime": 1689731584000
    },
    {
        "time": 1689731586000,
        "value": 139.063,
        "_internal_originalTime": 1689731586000
    },
    {
        "time": 1689731588000,
        "value": 139.062,
        "_internal_originalTime": 1689731588000
    },
    {
        "time": 1689731589000,
        "value": 139.06,
        "_internal_originalTime": 1689731589000
    },
    {
        "time": 1689731590000,
        "value": 139.055,
        "_internal_originalTime": 1689731590000
    },
    {
        "time": 1689731591000,
        "value": 139.06,
        "_internal_originalTime": 1689731591000
    },
    {
        "time": 1689731593000,
        "value": 139.059,
        "_internal_originalTime": 1689731593000
    },
    {
        "time": 1689731594000,
        "value": 139.06,
        "_internal_originalTime": 1689731594000
    },
    {
        "time": 1689731597000,
        "value": 139.059,
        "_internal_originalTime": 1689731597000
    },
    {
        "time": 1689731598000,
        "value": 139.056,
        "_internal_originalTime": 1689731598000
    },
    {
        "time": 1689731599000,
        "value": 139.057,
        "_internal_originalTime": 1689731599000
    },
    {
        "time": 1689731600000,
        "value": 139.061,
        "_internal_originalTime": 1689731600000
    },
    {
        "time": 1689731602000,
        "value": 139.063,
        "_internal_originalTime": 1689731602000
    },
    {
        "time": 1689731604000,
        "value": 139.06,
        "_internal_originalTime": 1689731604000
    },
    {
        "time": 1689731605000,
        "value": 139.058,
        "_internal_originalTime": 1689731605000
    },
    {
        "time": 1689731608000,
        "value": 139.054,
        "_internal_originalTime": 1689731608000
    },
    {
        "time": 1689731609000,
        "value": 139.058,
        "_internal_originalTime": 1689731609000
    },
    {
        "time": 1689731610000,
        "value": 139.058,
        "_internal_originalTime": 1689731610000
    },
    {
        "time": 1689731611000,
        "value": 139.053,
        "_internal_originalTime": 1689731611000
    },
    {
        "time": 1689731613000,
        "value": 139.058,
        "_internal_originalTime": 1689731613000
    },
    {
        "time": 1689731615000,
        "value": 139.042,
        "_internal_originalTime": 1689731615000
    },
    {
        "time": 1689731616000,
        "value": 139.043,
        "_internal_originalTime": 1689731616000
    },
    {
        "time": 1689731617000,
        "value": 139.046,
        "_internal_originalTime": 1689731617000
    },
    {
        "time": 1689731618000,
        "value": 139.042,
        "_internal_originalTime": 1689731618000
    },
    {
        "time": 1689731620000,
        "value": 139.055,
        "_internal_originalTime": 1689731620000
    },
    {
        "time": 1689731621000,
        "value": 139.056,
        "_internal_originalTime": 1689731621000
    },
    {
        "time": 1689731623000,
        "value": 139.059,
        "_internal_originalTime": 1689731623000
    },
    {
        "time": 1689731625000,
        "value": 139.057,
        "_internal_originalTime": 1689731625000
    },
    {
        "time": 1689731627000,
        "value": 139.063,
        "_internal_originalTime": 1689731627000
    },
    {
        "time": 1689731628000,
        "value": 139.064,
        "_internal_originalTime": 1689731628000
    },
    {
        "time": 1689731629000,
        "value": 139.063,
        "_internal_originalTime": 1689731629000
    },
    {
        "time": 1689731630000,
        "value": 139.067,
        "_internal_originalTime": 1689731630000
    },
    {
        "time": 1689731631000,
        "value": 139.066,
        "_internal_originalTime": 1689731631000
    },
    {
        "time": 1689731632000,
        "value": 139.068,
        "_internal_originalTime": 1689731632000
    },
    {
        "time": 1689731633000,
        "value": 139.061,
        "_internal_originalTime": 1689731633000
    },
    {
        "time": 1689731635000,
        "value": 139.066,
        "_internal_originalTime": 1689731635000
    },
    {
        "time": 1689731636000,
        "value": 139.061,
        "_internal_originalTime": 1689731636000
    },
    {
        "time": 1689731637000,
        "value": 139.065,
        "_internal_originalTime": 1689731637000
    },
    {
        "time": 1689731639000,
        "value": 139.059,
        "_internal_originalTime": 1689731639000
    },
    {
        "time": 1689731640000,
        "value": 139.068,
        "_internal_originalTime": 1689731640000
    },
    {
        "time": 1689731641000,
        "value": 139.064,
        "_internal_originalTime": 1689731641000
    },
    {
        "time": 1689731642000,
        "value": 139.067,
        "_internal_originalTime": 1689731642000
    },
    {
        "time": 1689731643000,
        "value": 139.063,
        "_internal_originalTime": 1689731643000
    },
    {
        "time": 1689731645000,
        "value": 139.063,
        "_internal_originalTime": 1689731645000
    },
    {
        "time": 1689731646000,
        "value": 139.069,
        "_internal_originalTime": 1689731646000
    },
    {
        "time": 1689731648000,
        "value": 139.064,
        "_internal_originalTime": 1689731648000
    },
    {
        "time": 1689731650000,
        "value": 139.07,
        "_internal_originalTime": 1689731650000
    },
    {
        "time": 1689731651000,
        "value": 139.065,
        "_internal_originalTime": 1689731651000
    },
    {
        "time": 1689731652000,
        "value": 139.068,
        "_internal_originalTime": 1689731652000
    },
    {
        "time": 1689731655000,
        "value": 139.065,
        "_internal_originalTime": 1689731655000
    },
    {
        "time": 1689731656000,
        "value": 139.068,
        "_internal_originalTime": 1689731656000
    },
    {
        "time": 1689731658000,
        "value": 139.066,
        "_internal_originalTime": 1689731658000
    },
    {
        "time": 1689731659000,
        "value": 139.066,
        "_internal_originalTime": 1689731659000
    },
    {
        "time": 1689731660000,
        "value": 139.071,
        "_internal_originalTime": 1689731660000
    },
    {
        "time": 1689731663000,
        "value": 139.065,
        "_internal_originalTime": 1689731663000
    },
    {
        "time": 1689731664000,
        "value": 139.064,
        "_internal_originalTime": 1689731664000
    },
    {
        "time": 1689731665000,
        "value": 139.069,
        "_internal_originalTime": 1689731665000
    },
    {
        "time": 1689731666000,
        "value": 139.067,
        "_internal_originalTime": 1689731666000
    },
    {
        "time": 1689731667000,
        "value": 139.068,
        "_internal_originalTime": 1689731667000
    },
    {
        "time": 1689731670000,
        "value": 139.064,
        "_internal_originalTime": 1689731670000
    },
    {
        "time": 1689731671000,
        "value": 139.065,
        "_internal_originalTime": 1689731671000
    },
    {
        "time": 1689731672000,
        "value": 139.071,
        "_internal_originalTime": 1689731672000
    },
    {
        "time": 1689731673000,
        "value": 139.07,
        "_internal_originalTime": 1689731673000
    },
    {
        "time": 1689731674000,
        "value": 139.07,
        "_internal_originalTime": 1689731674000
    },
    {
        "time": 1689731675000,
        "value": 139.067,
        "_internal_originalTime": 1689731675000
    },
    {
        "time": 1689731677000,
        "value": 139.072,
        "_internal_originalTime": 1689731677000
    },
    {
        "time": 1689731679000,
        "value": 139.066,
        "_internal_originalTime": 1689731679000
    },
    {
        "time": 1689731680000,
        "value": 139.068,
        "_internal_originalTime": 1689731680000
    },
    {
        "time": 1689731683000,
        "value": 139.06,
        "_internal_originalTime": 1689731683000
    },
    {
        "time": 1689731684000,
        "value": 139.066,
        "_internal_originalTime": 1689731684000
    },
    {
        "time": 1689731685000,
        "value": 139.063,
        "_internal_originalTime": 1689731685000
    },
    {
        "time": 1689731687000,
        "value": 139.067,
        "_internal_originalTime": 1689731687000
    },
    {
        "time": 1689731688000,
        "value": 139.065,
        "_internal_originalTime": 1689731688000
    },
    {
        "time": 1689731689000,
        "value": 139.063,
        "_internal_originalTime": 1689731689000
    },
    {
        "time": 1689731690000,
        "value": 139.067,
        "_internal_originalTime": 1689731690000
    },
    {
        "time": 1689731692000,
        "value": 139.067,
        "_internal_originalTime": 1689731692000
    },
    {
        "time": 1689731694000,
        "value": 139.064,
        "_internal_originalTime": 1689731694000
    },
    {
        "time": 1689731695000,
        "value": 139.064,
        "_internal_originalTime": 1689731695000
    },
    {
        "time": 1689731696000,
        "value": 139.063,
        "_internal_originalTime": 1689731696000
    },
    {
        "time": 1689731699000,
        "value": 139.075,
        "_internal_originalTime": 1689731699000
    },
    {
        "time": 1689731700000,
        "value": 139.078,
        "_internal_originalTime": 1689731700000
    },
    {
        "time": 1689731701000,
        "value": 139.08,
        "_internal_originalTime": 1689731701000
    },
    {
        "time": 1689731702000,
        "value": 139.08,
        "_internal_originalTime": 1689731702000
    },
    {
        "time": 1689731705000,
        "value": 139.072,
        "_internal_originalTime": 1689731705000
    },
    {
        "time": 1689731706000,
        "value": 139.073,
        "_internal_originalTime": 1689731706000
    },
    {
        "time": 1689731707000,
        "value": 139.069,
        "_internal_originalTime": 1689731707000
    },
    {
        "time": 1689731708000,
        "value": 139.067,
        "_internal_originalTime": 1689731708000
    },
    {
        "time": 1689731709000,
        "value": 139.069,
        "_internal_originalTime": 1689731709000
    },
    {
        "time": 1689731710000,
        "value": 139.067,
        "_internal_originalTime": 1689731710000
    },
    {
        "time": 1689731711000,
        "value": 139.067,
        "_internal_originalTime": 1689731711000
    },
    {
        "time": 1689731713000,
        "value": 139.07,
        "_internal_originalTime": 1689731713000
    },
    {
        "time": 1689731714000,
        "value": 139.067,
        "_internal_originalTime": 1689731714000
    },
    {
        "time": 1689731716000,
        "value": 139.068,
        "_internal_originalTime": 1689731716000
    },
    {
        "time": 1689731718000,
        "value": 139.065,
        "_internal_originalTime": 1689731718000
    },
    {
        "time": 1689731719000,
        "value": 139.063,
        "_internal_originalTime": 1689731719000
    },
    {
        "time": 1689731721000,
        "value": 139.069,
        "_internal_originalTime": 1689731721000
    },
    {
        "time": 1689731722000,
        "value": 139.068,
        "_internal_originalTime": 1689731722000
    },
    {
        "time": 1689731723000,
        "value": 139.064,
        "_internal_originalTime": 1689731723000
    },
    {
        "time": 1689731725000,
        "value": 139.064,
        "_internal_originalTime": 1689731725000
    },
    {
        "time": 1689731728000,
        "value": 139.07,
        "_internal_originalTime": 1689731728000
    },
    {
        "time": 1689731729000,
        "value": 139.065,
        "_internal_originalTime": 1689731729000
    },
    {
        "time": 1689731732000,
        "value": 139.064,
        "_internal_originalTime": 1689731732000
    },
    {
        "time": 1689731733000,
        "value": 139.07,
        "_internal_originalTime": 1689731733000
    },
    {
        "time": 1689731734000,
        "value": 139.073,
        "_internal_originalTime": 1689731734000
    },
    {
        "time": 1689731735000,
        "value": 139.078,
        "_internal_originalTime": 1689731735000
    },
    {
        "time": 1689731736000,
        "value": 139.07,
        "_internal_originalTime": 1689731736000
    },
    {
        "time": 1689731737000,
        "value": 139.073,
        "_internal_originalTime": 1689731737000
    },
    {
        "time": 1689731740000,
        "value": 139.06,
        "_internal_originalTime": 1689731740000
    },
    {
        "time": 1689731741000,
        "value": 139.063,
        "_internal_originalTime": 1689731741000
    },
    {
        "time": 1689731742000,
        "value": 139.063,
        "_internal_originalTime": 1689731742000
    },
    {
        "time": 1689731744000,
        "value": 139.062,
        "_internal_originalTime": 1689731744000
    },
    {
        "time": 1689731746000,
        "value": 139.067,
        "_internal_originalTime": 1689731746000
    },
    {
        "time": 1689731747000,
        "value": 139.07,
        "_internal_originalTime": 1689731747000
    },
    {
        "time": 1689731749000,
        "value": 139.068,
        "_internal_originalTime": 1689731749000
    },
    {
        "time": 1689731750000,
        "value": 139.069,
        "_internal_originalTime": 1689731750000
    },
    {
        "time": 1689731751000,
        "value": 139.067,
        "_internal_originalTime": 1689731751000
    },
    {
        "time": 1689731752000,
        "value": 139.071,
        "_internal_originalTime": 1689731752000
    },
    {
        "time": 1689731753000,
        "value": 139.073,
        "_internal_originalTime": 1689731753000
    },
    {
        "time": 1689731754000,
        "value": 139.072,
        "_internal_originalTime": 1689731754000
    },
    {
        "time": 1689731755000,
        "value": 139.068,
        "_internal_originalTime": 1689731755000
    },
    {
        "time": 1689731756000,
        "value": 139.072,
        "_internal_originalTime": 1689731756000
    },
    {
        "time": 1689731757000,
        "value": 139.072,
        "_internal_originalTime": 1689731757000
    },
    {
        "time": 1689731759000,
        "value": 139.072,
        "_internal_originalTime": 1689731759000
    },
    {
        "time": 1689731761000,
        "value": 139.072,
        "_internal_originalTime": 1689731761000
    },
    {
        "time": 1689731762000,
        "value": 139.068,
        "_internal_originalTime": 1689731762000
    },
    {
        "time": 1689731765000,
        "value": 139.072,
        "_internal_originalTime": 1689731765000
    },
    {
        "time": 1689731766000,
        "value": 139.071,
        "_internal_originalTime": 1689731766000
    },
    {
        "time": 1689731767000,
        "value": 139.071,
        "_internal_originalTime": 1689731767000
    },
    {
        "time": 1689731768000,
        "value": 139.083,
        "_internal_originalTime": 1689731768000
    },
    {
        "time": 1689731769000,
        "value": 139.09,
        "_internal_originalTime": 1689731769000
    },
    {
        "time": 1689731770000,
        "value": 139.094,
        "_internal_originalTime": 1689731770000
    },
    {
        "time": 1689731771000,
        "value": 139.092,
        "_internal_originalTime": 1689731771000
    },
    {
        "time": 1689731772000,
        "value": 139.092,
        "_internal_originalTime": 1689731772000
    },
    {
        "time": 1689731773000,
        "value": 139.09,
        "_internal_originalTime": 1689731773000
    },
    {
        "time": 1689731774000,
        "value": 139.087,
        "_internal_originalTime": 1689731774000
    },
    {
        "time": 1689731775000,
        "value": 139.09,
        "_internal_originalTime": 1689731775000
    },
    {
        "time": 1689731776000,
        "value": 139.084,
        "_internal_originalTime": 1689731776000
    },
    {
        "time": 1689731777000,
        "value": 139.091,
        "_internal_originalTime": 1689731777000
    },
    {
        "time": 1689731779000,
        "value": 139.093,
        "_internal_originalTime": 1689731779000
    },
    {
        "time": 1689731781000,
        "value": 139.089,
        "_internal_originalTime": 1689731781000
    },
    {
        "time": 1689731784000,
        "value": 139.084,
        "_internal_originalTime": 1689731784000
    },
    {
        "time": 1689731785000,
        "value": 139.082,
        "_internal_originalTime": 1689731785000
    },
    {
        "time": 1689731786000,
        "value": 139.088,
        "_internal_originalTime": 1689731786000
    },
    {
        "time": 1689731787000,
        "value": 139.082,
        "_internal_originalTime": 1689731787000
    },
    {
        "time": 1689731788000,
        "value": 139.088,
        "_internal_originalTime": 1689731788000
    },
    {
        "time": 1689731789000,
        "value": 139.088,
        "_internal_originalTime": 1689731789000
    },
    {
        "time": 1689731790000,
        "value": 139.088,
        "_internal_originalTime": 1689731790000
    },
    {
        "time": 1689731791000,
        "value": 139.082,
        "_internal_originalTime": 1689731791000
    },
    {
        "time": 1689731792000,
        "value": 139.082,
        "_internal_originalTime": 1689731792000
    },
    {
        "time": 1689731795000,
        "value": 139.083,
        "_internal_originalTime": 1689731795000
    },
    {
        "time": 1689731796000,
        "value": 139.088,
        "_internal_originalTime": 1689731796000
    },
    {
        "time": 1689731797000,
        "value": 139.09,
        "_internal_originalTime": 1689731797000
    },
    {
        "time": 1689731798000,
        "value": 139.09,
        "_internal_originalTime": 1689731798000
    },
    {
        "time": 1689731799000,
        "value": 139.093,
        "_internal_originalTime": 1689731799000
    },
    {
        "time": 1689731800000,
        "value": 139.09,
        "_internal_originalTime": 1689731800000
    },
    {
        "time": 1689731802000,
        "value": 139.092,
        "_internal_originalTime": 1689731802000
    },
    {
        "time": 1689731803000,
        "value": 139.093,
        "_internal_originalTime": 1689731803000
    },
    {
        "time": 1689731804000,
        "value": 139.087,
        "_internal_originalTime": 1689731804000
    },
    {
        "time": 1689731806000,
        "value": 139.091,
        "_internal_originalTime": 1689731806000
    },
    {
        "time": 1689731807000,
        "value": 139.09,
        "_internal_originalTime": 1689731807000
    },
    {
        "time": 1689731808000,
        "value": 139.086,
        "_internal_originalTime": 1689731808000
    },
    {
        "time": 1689731809000,
        "value": 139.08,
        "_internal_originalTime": 1689731809000
    },
    {
        "time": 1689731810000,
        "value": 139.083,
        "_internal_originalTime": 1689731810000
    },
    {
        "time": 1689731811000,
        "value": 139.086,
        "_internal_originalTime": 1689731811000
    },
    {
        "time": 1689731812000,
        "value": 139.086,
        "_internal_originalTime": 1689731812000
    },
    {
        "time": 1689731813000,
        "value": 139.081,
        "_internal_originalTime": 1689731813000
    },
    {
        "time": 1689731815000,
        "value": 139.085,
        "_internal_originalTime": 1689731815000
    },
    {
        "time": 1689731816000,
        "value": 139.097,
        "_internal_originalTime": 1689731816000
    },
    {
        "time": 1689731818000,
        "value": 139.096,
        "_internal_originalTime": 1689731818000
    },
    {
        "time": 1689731819000,
        "value": 139.096,
        "_internal_originalTime": 1689731819000
    },
    {
        "time": 1689731820000,
        "value": 139.07,
        "_internal_originalTime": 1689731820000
    },
    {
        "time": 1689731821000,
        "value": 139.096,
        "_internal_originalTime": 1689731821000
    },
    {
        "time": 1689731822000,
        "value": 139.097,
        "_internal_originalTime": 1689731822000
    },
    {
        "time": 1689731823000,
        "value": 139.097,
        "_internal_originalTime": 1689731823000
    },
    {
        "time": 1689731825000,
        "value": 139.096,
        "_internal_originalTime": 1689731825000
    },
    {
        "time": 1689731826000,
        "value": 139.097,
        "_internal_originalTime": 1689731826000
    },
    {
        "time": 1689731833000,
        "value": 139.083,
        "_internal_originalTime": 1689731833000
    },
    {
        "time": 1689731834000,
        "value": 139.086,
        "_internal_originalTime": 1689731834000
    },
    {
        "time": 1689731835000,
        "value": 139.086,
        "_internal_originalTime": 1689731835000
    },
    {
        "time": 1689731836000,
        "value": 139.063,
        "_internal_originalTime": 1689731836000
    },
    {
        "time": 1689731837000,
        "value": 139.065,
        "_internal_originalTime": 1689731837000
    },
    {
        "time": 1689731839000,
        "value": 139.069,
        "_internal_originalTime": 1689731839000
    },
    {
        "time": 1689731840000,
        "value": 139.071,
        "_internal_originalTime": 1689731840000
    },
    {
        "time": 1689731841000,
        "value": 139.075,
        "_internal_originalTime": 1689731841000
    },
    {
        "time": 1689731842000,
        "value": 139.078,
        "_internal_originalTime": 1689731842000
    },
    {
        "time": 1689731844000,
        "value": 139.078,
        "_internal_originalTime": 1689731844000
    },
    {
        "time": 1689731845000,
        "value": 139.065,
        "_internal_originalTime": 1689731845000
    },
    {
        "time": 1689731846000,
        "value": 139.066,
        "_internal_originalTime": 1689731846000
    },
    {
        "time": 1689731847000,
        "value": 139.066,
        "_internal_originalTime": 1689731847000
    },
    {
        "time": 1689731848000,
        "value": 139.066,
        "_internal_originalTime": 1689731848000
    },
    {
        "time": 1689731849000,
        "value": 139.065,
        "_internal_originalTime": 1689731849000
    },
    {
        "time": 1689731850000,
        "value": 139.067,
        "_internal_originalTime": 1689731850000
    },
    {
        "time": 1689731852000,
        "value": 139.067,
        "_internal_originalTime": 1689731852000
    },
    {
        "time": 1689731853000,
        "value": 139.056,
        "_internal_originalTime": 1689731853000
    },
    {
        "time": 1689731854000,
        "value": 139.063,
        "_internal_originalTime": 1689731854000
    },
    {
        "time": 1689731855000,
        "value": 139.063,
        "_internal_originalTime": 1689731855000
    },
    {
        "time": 1689731857000,
        "value": 139.055,
        "_internal_originalTime": 1689731857000
    },
    {
        "time": 1689731858000,
        "value": 139.054,
        "_internal_originalTime": 1689731858000
    },
    {
        "time": 1689731859000,
        "value": 139.055,
        "_internal_originalTime": 1689731859000
    },
    {
        "time": 1689731860000,
        "value": 139.054,
        "_internal_originalTime": 1689731860000
    },
    {
        "time": 1689731862000,
        "value": 139.065,
        "_internal_originalTime": 1689731862000
    },
    {
        "time": 1689731863000,
        "value": 139.066,
        "_internal_originalTime": 1689731863000
    },
    {
        "time": 1689731864000,
        "value": 139.063,
        "_internal_originalTime": 1689731864000
    },
    {
        "time": 1689731866000,
        "value": 139.066,
        "_internal_originalTime": 1689731866000
    },
    {
        "time": 1689731867000,
        "value": 139.067,
        "_internal_originalTime": 1689731867000
    },
    {
        "time": 1689731868000,
        "value": 139.061,
        "_internal_originalTime": 1689731868000
    },
    {
        "time": 1689731870000,
        "value": 139.063,
        "_internal_originalTime": 1689731870000
    },
    {
        "time": 1689731871000,
        "value": 139.066,
        "_internal_originalTime": 1689731871000
    },
    {
        "time": 1689731872000,
        "value": 139.067,
        "_internal_originalTime": 1689731872000
    },
    {
        "time": 1689731873000,
        "value": 139.059,
        "_internal_originalTime": 1689731873000
    },
    {
        "time": 1689731874000,
        "value": 139.067,
        "_internal_originalTime": 1689731874000
    },
    {
        "time": 1689731875000,
        "value": 139.066,
        "_internal_originalTime": 1689731875000
    },
    {
        "time": 1689731877000,
        "value": 139.066,
        "_internal_originalTime": 1689731877000
    },
    {
        "time": 1689731878000,
        "value": 139.066,
        "_internal_originalTime": 1689731878000
    },
    {
        "time": 1689731879000,
        "value": 139.067,
        "_internal_originalTime": 1689731879000
    },
    {
        "time": 1689731880000,
        "value": 139.067,
        "_internal_originalTime": 1689731880000
    },
    {
        "time": 1689731881000,
        "value": 139.065,
        "_internal_originalTime": 1689731881000
    },
    {
        "time": 1689731883000,
        "value": 139.066,
        "_internal_originalTime": 1689731883000
    },
    {
        "time": 1689731884000,
        "value": 139.056,
        "_internal_originalTime": 1689731884000
    },
    {
        "time": 1689731885000,
        "value": 139.061,
        "_internal_originalTime": 1689731885000
    },
    {
        "time": 1689731886000,
        "value": 139.057,
        "_internal_originalTime": 1689731886000
    },
    {
        "time": 1689731887000,
        "value": 139.061,
        "_internal_originalTime": 1689731887000
    },
    {
        "time": 1689731888000,
        "value": 139.062,
        "_internal_originalTime": 1689731888000
    },
    {
        "time": 1689731891000,
        "value": 139.066,
        "_internal_originalTime": 1689731891000
    },
    {
        "time": 1689731892000,
        "value": 139.063,
        "_internal_originalTime": 1689731892000
    },
    {
        "time": 1689731893000,
        "value": 139.063,
        "_internal_originalTime": 1689731893000
    },
    {
        "time": 1689731894000,
        "value": 139.063,
        "_internal_originalTime": 1689731894000
    },
    {
        "time": 1689731895000,
        "value": 139.066,
        "_internal_originalTime": 1689731895000
    },
    {
        "time": 1689731896000,
        "value": 139.062,
        "_internal_originalTime": 1689731896000
    },
    {
        "time": 1689731898000,
        "value": 139.063,
        "_internal_originalTime": 1689731898000
    },
    {
        "time": 1689731899000,
        "value": 139.063,
        "_internal_originalTime": 1689731899000
    },
    {
        "time": 1689731900000,
        "value": 139.066,
        "_internal_originalTime": 1689731900000
    },
    {
        "time": 1689731902000,
        "value": 139.064,
        "_internal_originalTime": 1689731902000
    },
    {
        "time": 1689731903000,
        "value": 139.063,
        "_internal_originalTime": 1689731903000
    },
    {
        "time": 1689731904000,
        "value": 139.063,
        "_internal_originalTime": 1689731904000
    },
    {
        "time": 1689731906000,
        "value": 139.064,
        "_internal_originalTime": 1689731906000
    },
    {
        "time": 1689731907000,
        "value": 139.061,
        "_internal_originalTime": 1689731907000
    },
    {
        "time": 1689731909000,
        "value": 139.063,
        "_internal_originalTime": 1689731909000
    },
    {
        "time": 1689731910000,
        "value": 139.062,
        "_internal_originalTime": 1689731910000
    },
    {
        "time": 1689731912000,
        "value": 139.062,
        "_internal_originalTime": 1689731912000
    },
    {
        "time": 1689731913000,
        "value": 139.068,
        "_internal_originalTime": 1689731913000
    },
    {
        "time": 1689731914000,
        "value": 139.067,
        "_internal_originalTime": 1689731914000
    },
    {
        "time": 1689731916000,
        "value": 139.064,
        "_internal_originalTime": 1689731916000
    },
    {
        "time": 1689731919000,
        "value": 139.065,
        "_internal_originalTime": 1689731919000
    },
    {
        "time": 1689731922000,
        "value": 139.057,
        "_internal_originalTime": 1689731922000
    },
    {
        "time": 1689731923000,
        "value": 139.057,
        "_internal_originalTime": 1689731923000
    },
    {
        "time": 1689731924000,
        "value": 139.056,
        "_internal_originalTime": 1689731924000
    },
    {
        "time": 1689731925000,
        "value": 139.062,
        "_internal_originalTime": 1689731925000
    },
    {
        "time": 1689731928000,
        "value": 139.055,
        "_internal_originalTime": 1689731928000
    },
    {
        "time": 1689731929000,
        "value": 139.066,
        "_internal_originalTime": 1689731929000
    },
    {
        "time": 1689731930000,
        "value": 139.065,
        "_internal_originalTime": 1689731930000
    },
    {
        "time": 1689731931000,
        "value": 139.076,
        "_internal_originalTime": 1689731931000
    },
    {
        "time": 1689731932000,
        "value": 139.078,
        "_internal_originalTime": 1689731932000
    },
    {
        "time": 1689731933000,
        "value": 139.079,
        "_internal_originalTime": 1689731933000
    },
    {
        "time": 1689731935000,
        "value": 139.078,
        "_internal_originalTime": 1689731935000
    },
    {
        "time": 1689731936000,
        "value": 139.074,
        "_internal_originalTime": 1689731936000
    },
    {
        "time": 1689731937000,
        "value": 139.078,
        "_internal_originalTime": 1689731937000
    },
    {
        "time": 1689731939000,
        "value": 139.074,
        "_internal_originalTime": 1689731939000
    },
    {
        "time": 1689731940000,
        "value": 139.08,
        "_internal_originalTime": 1689731940000
    },
    {
        "time": 1689731941000,
        "value": 139.073,
        "_internal_originalTime": 1689731941000
    },
    {
        "time": 1689731942000,
        "value": 139.077,
        "_internal_originalTime": 1689731942000
    },
    {
        "time": 1689731943000,
        "value": 139.072,
        "_internal_originalTime": 1689731943000
    },
    {
        "time": 1689731944000,
        "value": 139.076,
        "_internal_originalTime": 1689731944000
    },
    {
        "time": 1689731946000,
        "value": 139.077,
        "_internal_originalTime": 1689731946000
    },
    {
        "time": 1689731947000,
        "value": 139.076,
        "_internal_originalTime": 1689731947000
    },
    {
        "time": 1689731948000,
        "value": 139.079,
        "_internal_originalTime": 1689731948000
    },
    {
        "time": 1689731949000,
        "value": 139.087,
        "_internal_originalTime": 1689731949000
    },
    {
        "time": 1689731950000,
        "value": 139.087,
        "_internal_originalTime": 1689731950000
    },
    {
        "time": 1689731952000,
        "value": 139.088,
        "_internal_originalTime": 1689731952000
    },
    {
        "time": 1689731953000,
        "value": 139.084,
        "_internal_originalTime": 1689731953000
    },
    {
        "time": 1689731955000,
        "value": 139.088,
        "_internal_originalTime": 1689731955000
    },
    {
        "time": 1689731958000,
        "value": 139.085,
        "_internal_originalTime": 1689731958000
    },
    {
        "time": 1689731959000,
        "value": 139.086,
        "_internal_originalTime": 1689731959000
    },
    {
        "time": 1689731961000,
        "value": 139.084,
        "_internal_originalTime": 1689731961000
    },
    {
        "time": 1689731962000,
        "value": 139.088,
        "_internal_originalTime": 1689731962000
    },
    {
        "time": 1689731963000,
        "value": 139.09,
        "_internal_originalTime": 1689731963000
    },
    {
        "time": 1689731964000,
        "value": 139.086,
        "_internal_originalTime": 1689731964000
    },
    {
        "time": 1689731965000,
        "value": 139.086,
        "_internal_originalTime": 1689731965000
    },
    {
        "time": 1689731967000,
        "value": 139.089,
        "_internal_originalTime": 1689731967000
    },
    {
        "time": 1689731969000,
        "value": 139.09,
        "_internal_originalTime": 1689731969000
    },
    {
        "time": 1689731970000,
        "value": 139.085,
        "_internal_originalTime": 1689731970000
    },
    {
        "time": 1689731972000,
        "value": 139.087,
        "_internal_originalTime": 1689731972000
    },
    {
        "time": 1689731974000,
        "value": 139.085,
        "_internal_originalTime": 1689731974000
    },
    {
        "time": 1689731980000,
        "value": 139.097,
        "_internal_originalTime": 1689731980000
    },
    {
        "time": 1689731981000,
        "value": 139.097,
        "_internal_originalTime": 1689731981000
    },
    {
        "time": 1689731983000,
        "value": 139.096,
        "_internal_originalTime": 1689731983000
    },
    {
        "time": 1689731985000,
        "value": 139.092,
        "_internal_originalTime": 1689731985000
    },
    {
        "time": 1689731986000,
        "value": 139.09,
        "_internal_originalTime": 1689731986000
    },
    {
        "time": 1689731987000,
        "value": 139.093,
        "_internal_originalTime": 1689731987000
    },
    {
        "time": 1689731989000,
        "value": 139.091,
        "_internal_originalTime": 1689731989000
    },
    {
        "time": 1689731990000,
        "value": 139.092,
        "_internal_originalTime": 1689731990000
    },
    {
        "time": 1689731991000,
        "value": 139.094,
        "_internal_originalTime": 1689731991000
    },
    {
        "time": 1689731992000,
        "value": 139.091,
        "_internal_originalTime": 1689731992000
    },
    {
        "time": 1689731993000,
        "value": 139.089,
        "_internal_originalTime": 1689731993000
    },
    {
        "time": 1689731997000,
        "value": 139.09,
        "_internal_originalTime": 1689731997000
    },
    {
        "time": 1689732001000,
        "value": 139.095,
        "_internal_originalTime": 1689732001000
    },
    {
        "time": 1689732002000,
        "value": 139.095,
        "_internal_originalTime": 1689732002000
    },
    {
        "time": 1689732004000,
        "value": 139.096,
        "_internal_originalTime": 1689732004000
    },
    {
        "time": 1689732006000,
        "value": 139.093,
        "_internal_originalTime": 1689732006000
    },
    {
        "time": 1689732007000,
        "value": 139.091,
        "_internal_originalTime": 1689732007000
    },
    {
        "time": 1689732008000,
        "value": 139.096,
        "_internal_originalTime": 1689732008000
    },
    {
        "time": 1689732009000,
        "value": 139.096,
        "_internal_originalTime": 1689732009000
    },
    {
        "time": 1689732013000,
        "value": 139.096,
        "_internal_originalTime": 1689732013000
    },
    {
        "time": 1689732014000,
        "value": 139.092,
        "_internal_originalTime": 1689732014000
    },
    {
        "time": 1689732017000,
        "value": 139.092,
        "_internal_originalTime": 1689732017000
    },
    {
        "time": 1689732018000,
        "value": 139.095,
        "_internal_originalTime": 1689732018000
    },
    {
        "time": 1689732019000,
        "value": 139.091,
        "_internal_originalTime": 1689732019000
    },
    {
        "time": 1689732020000,
        "value": 139.09,
        "_internal_originalTime": 1689732020000
    },
    {
        "time": 1689732021000,
        "value": 139.092,
        "_internal_originalTime": 1689732021000
    },
    {
        "time": 1689732023000,
        "value": 139.09,
        "_internal_originalTime": 1689732023000
    },
    {
        "time": 1689732026000,
        "value": 139.092,
        "_internal_originalTime": 1689732026000
    },
    {
        "time": 1689732028000,
        "value": 139.096,
        "_internal_originalTime": 1689732028000
    },
    {
        "time": 1689732031000,
        "value": 139.092,
        "_internal_originalTime": 1689732031000
    },
    {
        "time": 1689732032000,
        "value": 139.097,
        "_internal_originalTime": 1689732032000
    },
    {
        "time": 1689732034000,
        "value": 139.107,
        "_internal_originalTime": 1689732034000
    },
    {
        "time": 1689732036000,
        "value": 139.107,
        "_internal_originalTime": 1689732036000
    },
    {
        "time": 1689732037000,
        "value": 139.113,
        "_internal_originalTime": 1689732037000
    },
    {
        "time": 1689732038000,
        "value": 139.11,
        "_internal_originalTime": 1689732038000
    },
    {
        "time": 1689732040000,
        "value": 139.116,
        "_internal_originalTime": 1689732040000
    },
    {
        "time": 1689732041000,
        "value": 139.118,
        "_internal_originalTime": 1689732041000
    },
    {
        "time": 1689732042000,
        "value": 139.117,
        "_internal_originalTime": 1689732042000
    },
    {
        "time": 1689732044000,
        "value": 139.116,
        "_internal_originalTime": 1689732044000
    },
    {
        "time": 1689732045000,
        "value": 139.117,
        "_internal_originalTime": 1689732045000
    },
    {
        "time": 1689732046000,
        "value": 139.118,
        "_internal_originalTime": 1689732046000
    },
    {
        "time": 1689732047000,
        "value": 139.116,
        "_internal_originalTime": 1689732047000
    },
    {
        "time": 1689732048000,
        "value": 139.118,
        "_internal_originalTime": 1689732048000
    },
    {
        "time": 1689732049000,
        "value": 139.117,
        "_internal_originalTime": 1689732049000
    },
    {
        "time": 1689732050000,
        "value": 139.114,
        "_internal_originalTime": 1689732050000
    },
    {
        "time": 1689732051000,
        "value": 139.117,
        "_internal_originalTime": 1689732051000
    },
    {
        "time": 1689732052000,
        "value": 139.108,
        "_internal_originalTime": 1689732052000
    },
    {
        "time": 1689732053000,
        "value": 139.109,
        "_internal_originalTime": 1689732053000
    },
    {
        "time": 1689732054000,
        "value": 139.118,
        "_internal_originalTime": 1689732054000
    },
    {
        "time": 1689732056000,
        "value": 139.119,
        "_internal_originalTime": 1689732056000
    },
    {
        "time": 1689732059000,
        "value": 139.117,
        "_internal_originalTime": 1689732059000
    },
    {
        "time": 1689732060000,
        "value": 139.116,
        "_internal_originalTime": 1689732060000
    },
    {
        "time": 1689732062000,
        "value": 139.117,
        "_internal_originalTime": 1689732062000
    },
    {
        "time": 1689732063000,
        "value": 139.115,
        "_internal_originalTime": 1689732063000
    },
    {
        "time": 1689732064000,
        "value": 139.117,
        "_internal_originalTime": 1689732064000
    },
    {
        "time": 1689732066000,
        "value": 139.117,
        "_internal_originalTime": 1689732066000
    },
    {
        "time": 1689732069000,
        "value": 139.112,
        "_internal_originalTime": 1689732069000
    },
    {
        "time": 1689732071000,
        "value": 139.117,
        "_internal_originalTime": 1689732071000
    },
    {
        "time": 1689732072000,
        "value": 139.119,
        "_internal_originalTime": 1689732072000
    },
    {
        "time": 1689732073000,
        "value": 139.117,
        "_internal_originalTime": 1689732073000
    },
    {
        "time": 1689732074000,
        "value": 139.12,
        "_internal_originalTime": 1689732074000
    },
    {
        "time": 1689732075000,
        "value": 139.119,
        "_internal_originalTime": 1689732075000
    },
    {
        "time": 1689732076000,
        "value": 139.117,
        "_internal_originalTime": 1689732076000
    },
    {
        "time": 1689732078000,
        "value": 139.122,
        "_internal_originalTime": 1689732078000
    },
    {
        "time": 1689732080000,
        "value": 139.123,
        "_internal_originalTime": 1689732080000
    },
    {
        "time": 1689732081000,
        "value": 139.116,
        "_internal_originalTime": 1689732081000
    },
    {
        "time": 1689732082000,
        "value": 139.116,
        "_internal_originalTime": 1689732082000
    },
    {
        "time": 1689732083000,
        "value": 139.11,
        "_internal_originalTime": 1689732083000
    },
    {
        "time": 1689732085000,
        "value": 139.117,
        "_internal_originalTime": 1689732085000
    },
    {
        "time": 1689732086000,
        "value": 139.117,
        "_internal_originalTime": 1689732086000
    },
    {
        "time": 1689732087000,
        "value": 139.111,
        "_internal_originalTime": 1689732087000
    },
    {
        "time": 1689732089000,
        "value": 139.119,
        "_internal_originalTime": 1689732089000
    },
    {
        "time": 1689732090000,
        "value": 139.113,
        "_internal_originalTime": 1689732090000
    },
    {
        "time": 1689732092000,
        "value": 139.115,
        "_internal_originalTime": 1689732092000
    },
    {
        "time": 1689732094000,
        "value": 139.111,
        "_internal_originalTime": 1689732094000
    },
    {
        "time": 1689732095000,
        "value": 139.112,
        "_internal_originalTime": 1689732095000
    },
    {
        "time": 1689732096000,
        "value": 139.12,
        "_internal_originalTime": 1689732096000
    },
    {
        "time": 1689732097000,
        "value": 139.12,
        "_internal_originalTime": 1689732097000
    },
    {
        "time": 1689732098000,
        "value": 139.128,
        "_internal_originalTime": 1689732098000
    },
    {
        "time": 1689732100000,
        "value": 139.117,
        "_internal_originalTime": 1689732100000
    },
    {
        "time": 1689732101000,
        "value": 139.115,
        "_internal_originalTime": 1689732101000
    },
    {
        "time": 1689732102000,
        "value": 139.116,
        "_internal_originalTime": 1689732102000
    },
    {
        "time": 1689732103000,
        "value": 139.122,
        "_internal_originalTime": 1689732103000
    },
    {
        "time": 1689732104000,
        "value": 139.124,
        "_internal_originalTime": 1689732104000
    },
    {
        "time": 1689732105000,
        "value": 139.124,
        "_internal_originalTime": 1689732105000
    },
    {
        "time": 1689732107000,
        "value": 139.123,
        "_internal_originalTime": 1689732107000
    },
    {
        "time": 1689732108000,
        "value": 139.114,
        "_internal_originalTime": 1689732108000
    },
    {
        "time": 1689732109000,
        "value": 139.115,
        "_internal_originalTime": 1689732109000
    },
    {
        "time": 1689732110000,
        "value": 139.115,
        "_internal_originalTime": 1689732110000
    },
    {
        "time": 1689732111000,
        "value": 139.113,
        "_internal_originalTime": 1689732111000
    },
    {
        "time": 1689732112000,
        "value": 139.118,
        "_internal_originalTime": 1689732112000
    },
    {
        "time": 1689732113000,
        "value": 139.111,
        "_internal_originalTime": 1689732113000
    },
    {
        "time": 1689732115000,
        "value": 139.118,
        "_internal_originalTime": 1689732115000
    },
    {
        "time": 1689732116000,
        "value": 139.104,
        "_internal_originalTime": 1689732116000
    },
    {
        "time": 1689732117000,
        "value": 139.108,
        "_internal_originalTime": 1689732117000
    },
    {
        "time": 1689732118000,
        "value": 139.113,
        "_internal_originalTime": 1689732118000
    },
    {
        "time": 1689732119000,
        "value": 139.115,
        "_internal_originalTime": 1689732119000
    },
    {
        "time": 1689732120000,
        "value": 139.09,
        "_internal_originalTime": 1689732120000
    },
    {
        "time": 1689732121000,
        "value": 139.109,
        "_internal_originalTime": 1689732121000
    },
    {
        "time": 1689732123000,
        "value": 139.108,
        "_internal_originalTime": 1689732123000
    },
    {
        "time": 1689732124000,
        "value": 139.114,
        "_internal_originalTime": 1689732124000
    },
    {
        "time": 1689732125000,
        "value": 139.108,
        "_internal_originalTime": 1689732125000
    },
    {
        "time": 1689732126000,
        "value": 139.109,
        "_internal_originalTime": 1689732126000
    },
    {
        "time": 1689732128000,
        "value": 139.109,
        "_internal_originalTime": 1689732128000
    },
    {
        "time": 1689732130000,
        "value": 139.101,
        "_internal_originalTime": 1689732130000
    },
    {
        "time": 1689732131000,
        "value": 139.107,
        "_internal_originalTime": 1689732131000
    },
    {
        "time": 1689732132000,
        "value": 139.103,
        "_internal_originalTime": 1689732132000
    },
    {
        "time": 1689732134000,
        "value": 139.108,
        "_internal_originalTime": 1689732134000
    },
    {
        "time": 1689732135000,
        "value": 139.106,
        "_internal_originalTime": 1689732135000
    },
    {
        "time": 1689732136000,
        "value": 139.118,
        "_internal_originalTime": 1689732136000
    },
    {
        "time": 1689732138000,
        "value": 139.12,
        "_internal_originalTime": 1689732138000
    },
    {
        "time": 1689732139000,
        "value": 139.121,
        "_internal_originalTime": 1689732139000
    },
    {
        "time": 1689732140000,
        "value": 139.117,
        "_internal_originalTime": 1689732140000
    },
    {
        "time": 1689732141000,
        "value": 139.117,
        "_internal_originalTime": 1689732141000
    },
    {
        "time": 1689732143000,
        "value": 139.123,
        "_internal_originalTime": 1689732143000
    },
    {
        "time": 1689732144000,
        "value": 139.118,
        "_internal_originalTime": 1689732144000
    },
    {
        "time": 1689732145000,
        "value": 139.125,
        "_internal_originalTime": 1689732145000
    },
    {
        "time": 1689732147000,
        "value": 139.124,
        "_internal_originalTime": 1689732147000
    },
    {
        "time": 1689732148000,
        "value": 139.123,
        "_internal_originalTime": 1689732148000
    },
    {
        "time": 1689732150000,
        "value": 139.121,
        "_internal_originalTime": 1689732150000
    },
    {
        "time": 1689732151000,
        "value": 139.121,
        "_internal_originalTime": 1689732151000
    },
    {
        "time": 1689732153000,
        "value": 139.12,
        "_internal_originalTime": 1689732153000
    },
    {
        "time": 1689732155000,
        "value": 139.118,
        "_internal_originalTime": 1689732155000
    },
    {
        "time": 1689732157000,
        "value": 139.14,
        "_internal_originalTime": 1689732157000
    },
    {
        "time": 1689732158000,
        "value": 139.141,
        "_internal_originalTime": 1689732158000
    },
    {
        "time": 1689732159000,
        "value": 139.144,
        "_internal_originalTime": 1689732159000
    },
    {
        "time": 1689732161000,
        "value": 139.149,
        "_internal_originalTime": 1689732161000
    },
    {
        "time": 1689732162000,
        "value": 139.162,
        "_internal_originalTime": 1689732162000
    },
    {
        "time": 1689732163000,
        "value": 139.162,
        "_internal_originalTime": 1689732163000
    },
    {
        "time": 1689732165000,
        "value": 139.166,
        "_internal_originalTime": 1689732165000
    },
    {
        "time": 1689732166000,
        "value": 139.167,
        "_internal_originalTime": 1689732166000
    },
    {
        "time": 1689732167000,
        "value": 139.165,
        "_internal_originalTime": 1689732167000
    },
    {
        "time": 1689732169000,
        "value": 139.172,
        "_internal_originalTime": 1689732169000
    },
    {
        "time": 1689732170000,
        "value": 139.173,
        "_internal_originalTime": 1689732170000
    },
    {
        "time": 1689732171000,
        "value": 139.177,
        "_internal_originalTime": 1689732171000
    },
    {
        "time": 1689732172000,
        "value": 139.177,
        "_internal_originalTime": 1689732172000
    },
    {
        "time": 1689732173000,
        "value": 139.18,
        "_internal_originalTime": 1689732173000
    },
    {
        "time": 1689732175000,
        "value": 139.181,
        "_internal_originalTime": 1689732175000
    },
    {
        "time": 1689732176000,
        "value": 139.182,
        "_internal_originalTime": 1689732176000
    },
    {
        "time": 1689732177000,
        "value": 139.182,
        "_internal_originalTime": 1689732177000
    },
    {
        "time": 1689732178000,
        "value": 139.178,
        "_internal_originalTime": 1689732178000
    },
    {
        "time": 1689732179000,
        "value": 139.182,
        "_internal_originalTime": 1689732179000
    },
    {
        "time": 1689732181000,
        "value": 139.178,
        "_internal_originalTime": 1689732181000
    },
    {
        "time": 1689732182000,
        "value": 139.176,
        "_internal_originalTime": 1689732182000
    },
    {
        "time": 1689732186000,
        "value": 139.166,
        "_internal_originalTime": 1689732186000
    },
    {
        "time": 1689732187000,
        "value": 139.168,
        "_internal_originalTime": 1689732187000
    },
    {
        "time": 1689732188000,
        "value": 139.168,
        "_internal_originalTime": 1689732188000
    },
    {
        "time": 1689732189000,
        "value": 139.167,
        "_internal_originalTime": 1689732189000
    },
    {
        "time": 1689732190000,
        "value": 139.167,
        "_internal_originalTime": 1689732190000
    },
    {
        "time": 1689732192000,
        "value": 139.17,
        "_internal_originalTime": 1689732192000
    },
    {
        "time": 1689732193000,
        "value": 139.167,
        "_internal_originalTime": 1689732193000
    },
    {
        "time": 1689732194000,
        "value": 139.162,
        "_internal_originalTime": 1689732194000
    },
    {
        "time": 1689732197000,
        "value": 139.163,
        "_internal_originalTime": 1689732197000
    },
    {
        "time": 1689732199000,
        "value": 139.159,
        "_internal_originalTime": 1689732199000
    },
    {
        "time": 1689732200000,
        "value": 139.164,
        "_internal_originalTime": 1689732200000
    },
    {
        "time": 1689732201000,
        "value": 139.167,
        "_internal_originalTime": 1689732201000
    },
    {
        "time": 1689732202000,
        "value": 139.162,
        "_internal_originalTime": 1689732202000
    },
    {
        "time": 1689732204000,
        "value": 139.164,
        "_internal_originalTime": 1689732204000
    },
    {
        "time": 1689732206000,
        "value": 139.164,
        "_internal_originalTime": 1689732206000
    },
    {
        "time": 1689732207000,
        "value": 139.161,
        "_internal_originalTime": 1689732207000
    },
    {
        "time": 1689732209000,
        "value": 139.162,
        "_internal_originalTime": 1689732209000
    },
    {
        "time": 1689732210000,
        "value": 139.17,
        "_internal_originalTime": 1689732210000
    },
    {
        "time": 1689732212000,
        "value": 139.166,
        "_internal_originalTime": 1689732212000
    },
    {
        "time": 1689732213000,
        "value": 139.167,
        "_internal_originalTime": 1689732213000
    },
    {
        "time": 1689732214000,
        "value": 139.173,
        "_internal_originalTime": 1689732214000
    },
    {
        "time": 1689732215000,
        "value": 139.174,
        "_internal_originalTime": 1689732215000
    },
    {
        "time": 1689732216000,
        "value": 139.175,
        "_internal_originalTime": 1689732216000
    },
    {
        "time": 1689732217000,
        "value": 139.176,
        "_internal_originalTime": 1689732217000
    },
    {
        "time": 1689732219000,
        "value": 139.175,
        "_internal_originalTime": 1689732219000
    },
    {
        "time": 1689732220000,
        "value": 139.17,
        "_internal_originalTime": 1689732220000
    },
    {
        "time": 1689732221000,
        "value": 139.17,
        "_internal_originalTime": 1689732221000
    },
    {
        "time": 1689732223000,
        "value": 139.164,
        "_internal_originalTime": 1689732223000
    },
    {
        "time": 1689732224000,
        "value": 139.166,
        "_internal_originalTime": 1689732224000
    },
    {
        "time": 1689732225000,
        "value": 139.166,
        "_internal_originalTime": 1689732225000
    },
    {
        "time": 1689732226000,
        "value": 139.169,
        "_internal_originalTime": 1689732226000
    },
    {
        "time": 1689732227000,
        "value": 139.171,
        "_internal_originalTime": 1689732227000
    },
    {
        "time": 1689732229000,
        "value": 139.171,
        "_internal_originalTime": 1689732229000
    },
    {
        "time": 1689732230000,
        "value": 139.17,
        "_internal_originalTime": 1689732230000
    },
    {
        "time": 1689732231000,
        "value": 139.174,
        "_internal_originalTime": 1689732231000
    },
    {
        "time": 1689732232000,
        "value": 139.175,
        "_internal_originalTime": 1689732232000
    },
    {
        "time": 1689732234000,
        "value": 139.167,
        "_internal_originalTime": 1689732234000
    },
    {
        "time": 1689732235000,
        "value": 139.174,
        "_internal_originalTime": 1689732235000
    },
    {
        "time": 1689732236000,
        "value": 139.172,
        "_internal_originalTime": 1689732236000
    },
    {
        "time": 1689732238000,
        "value": 139.163,
        "_internal_originalTime": 1689732238000
    },
    {
        "time": 1689732239000,
        "value": 139.166,
        "_internal_originalTime": 1689732239000
    },
    {
        "time": 1689732240000,
        "value": 139.15,
        "_internal_originalTime": 1689732240000
    },
    {
        "time": 1689732241000,
        "value": 139.172,
        "_internal_originalTime": 1689732241000
    },
    {
        "time": 1689732244000,
        "value": 139.175,
        "_internal_originalTime": 1689732244000
    },
    {
        "time": 1689732245000,
        "value": 139.175,
        "_internal_originalTime": 1689732245000
    },
    {
        "time": 1689732246000,
        "value": 139.179,
        "_internal_originalTime": 1689732246000
    },
    {
        "time": 1689732248000,
        "value": 139.175,
        "_internal_originalTime": 1689732248000
    },
    {
        "time": 1689732249000,
        "value": 139.173,
        "_internal_originalTime": 1689732249000
    },
    {
        "time": 1689732251000,
        "value": 139.173,
        "_internal_originalTime": 1689732251000
    },
    {
        "time": 1689732253000,
        "value": 139.168,
        "_internal_originalTime": 1689732253000
    },
    {
        "time": 1689732254000,
        "value": 139.17,
        "_internal_originalTime": 1689732254000
    },
    {
        "time": 1689732255000,
        "value": 139.173,
        "_internal_originalTime": 1689732255000
    },
    {
        "time": 1689732256000,
        "value": 139.17,
        "_internal_originalTime": 1689732256000
    },
    {
        "time": 1689732257000,
        "value": 139.175,
        "_internal_originalTime": 1689732257000
    },
    {
        "time": 1689732259000,
        "value": 139.168,
        "_internal_originalTime": 1689732259000
    },
    {
        "time": 1689732260000,
        "value": 139.172,
        "_internal_originalTime": 1689732260000
    },
    {
        "time": 1689732262000,
        "value": 139.175,
        "_internal_originalTime": 1689732262000
    },
    {
        "time": 1689732264000,
        "value": 139.177,
        "_internal_originalTime": 1689732264000
    },
    {
        "time": 1689732265000,
        "value": 139.17,
        "_internal_originalTime": 1689732265000
    },
    {
        "time": 1689732267000,
        "value": 139.175,
        "_internal_originalTime": 1689732267000
    },
    {
        "time": 1689732268000,
        "value": 139.172,
        "_internal_originalTime": 1689732268000
    },
    {
        "time": 1689732269000,
        "value": 139.171,
        "_internal_originalTime": 1689732269000
    },
    {
        "time": 1689732270000,
        "value": 139.17,
        "_internal_originalTime": 1689732270000
    },
    {
        "time": 1689732271000,
        "value": 139.176,
        "_internal_originalTime": 1689732271000
    },
    {
        "time": 1689732272000,
        "value": 139.175,
        "_internal_originalTime": 1689732272000
    },
    {
        "time": 1689732273000,
        "value": 139.173,
        "_internal_originalTime": 1689732273000
    },
    {
        "time": 1689732276000,
        "value": 139.173,
        "_internal_originalTime": 1689732276000
    },
    {
        "time": 1689732278000,
        "value": 139.173,
        "_internal_originalTime": 1689732278000
    },
    {
        "time": 1689732282000,
        "value": 139.168,
        "_internal_originalTime": 1689732282000
    },
    {
        "time": 1689732283000,
        "value": 139.17,
        "_internal_originalTime": 1689732283000
    },
    {
        "time": 1689732284000,
        "value": 139.169,
        "_internal_originalTime": 1689732284000
    },
    {
        "time": 1689732286000,
        "value": 139.175,
        "_internal_originalTime": 1689732286000
    },
    {
        "time": 1689732287000,
        "value": 139.16,
        "_internal_originalTime": 1689732287000
    },
    {
        "time": 1689732288000,
        "value": 139.161,
        "_internal_originalTime": 1689732288000
    },
    {
        "time": 1689732289000,
        "value": 139.16,
        "_internal_originalTime": 1689732289000
    },
    {
        "time": 1689732291000,
        "value": 139.167,
        "_internal_originalTime": 1689732291000
    },
    {
        "time": 1689732292000,
        "value": 139.173,
        "_internal_originalTime": 1689732292000
    },
    {
        "time": 1689732294000,
        "value": 139.175,
        "_internal_originalTime": 1689732294000
    },
    {
        "time": 1689732299000,
        "value": 139.174,
        "_internal_originalTime": 1689732299000
    },
    {
        "time": 1689732300000,
        "value": 139.177,
        "_internal_originalTime": 1689732300000
    },
    {
        "time": 1689732301000,
        "value": 139.176,
        "_internal_originalTime": 1689732301000
    },
    {
        "time": 1689732302000,
        "value": 139.177,
        "_internal_originalTime": 1689732302000
    },
    {
        "time": 1689732303000,
        "value": 139.184,
        "_internal_originalTime": 1689732303000
    },
    {
        "time": 1689732304000,
        "value": 139.178,
        "_internal_originalTime": 1689732304000
    },
    {
        "time": 1689732305000,
        "value": 139.183,
        "_internal_originalTime": 1689732305000
    },
    {
        "time": 1689732307000,
        "value": 139.18,
        "_internal_originalTime": 1689732307000
    },
    {
        "time": 1689732308000,
        "value": 139.182,
        "_internal_originalTime": 1689732308000
    },
    {
        "time": 1689732309000,
        "value": 139.183,
        "_internal_originalTime": 1689732309000
    },
    {
        "time": 1689732311000,
        "value": 139.187,
        "_internal_originalTime": 1689732311000
    },
    {
        "time": 1689732313000,
        "value": 139.187,
        "_internal_originalTime": 1689732313000
    },
    {
        "time": 1689732315000,
        "value": 139.199,
        "_internal_originalTime": 1689732315000
    },
    {
        "time": 1689732316000,
        "value": 139.21,
        "_internal_originalTime": 1689732316000
    },
    {
        "time": 1689732317000,
        "value": 139.209,
        "_internal_originalTime": 1689732317000
    },
    {
        "time": 1689732318000,
        "value": 139.196,
        "_internal_originalTime": 1689732318000
    },
    {
        "time": 1689732319000,
        "value": 139.192,
        "_internal_originalTime": 1689732319000
    },
    {
        "time": 1689732320000,
        "value": 139.19,
        "_internal_originalTime": 1689732320000
    },
    {
        "time": 1689732322000,
        "value": 139.187,
        "_internal_originalTime": 1689732322000
    },
    {
        "time": 1689732323000,
        "value": 139.198,
        "_internal_originalTime": 1689732323000
    },
    {
        "time": 1689732325000,
        "value": 139.205,
        "_internal_originalTime": 1689732325000
    },
    {
        "time": 1689732327000,
        "value": 139.21,
        "_internal_originalTime": 1689732327000
    },
    {
        "time": 1689732328000,
        "value": 139.21,
        "_internal_originalTime": 1689732328000
    },
    {
        "time": 1689732329000,
        "value": 139.21,
        "_internal_originalTime": 1689732329000
    },
    {
        "time": 1689732330000,
        "value": 139.21,
        "_internal_originalTime": 1689732330000
    },
    {
        "time": 1689732332000,
        "value": 139.212,
        "_internal_originalTime": 1689732332000
    },
    {
        "time": 1689732334000,
        "value": 139.211,
        "_internal_originalTime": 1689732334000
    },
    {
        "time": 1689732335000,
        "value": 139.207,
        "_internal_originalTime": 1689732335000
    },
    {
        "time": 1689732336000,
        "value": 139.211,
        "_internal_originalTime": 1689732336000
    },
    {
        "time": 1689732337000,
        "value": 139.211,
        "_internal_originalTime": 1689732337000
    },
    {
        "time": 1689732339000,
        "value": 139.214,
        "_internal_originalTime": 1689732339000
    },
    {
        "time": 1689732340000,
        "value": 139.213,
        "_internal_originalTime": 1689732340000
    },
    {
        "time": 1689732341000,
        "value": 139.212,
        "_internal_originalTime": 1689732341000
    },
    {
        "time": 1689732342000,
        "value": 139.218,
        "_internal_originalTime": 1689732342000
    },
    {
        "time": 1689732343000,
        "value": 139.211,
        "_internal_originalTime": 1689732343000
    },
    {
        "time": 1689732344000,
        "value": 139.219,
        "_internal_originalTime": 1689732344000
    },
    {
        "time": 1689732345000,
        "value": 139.22,
        "_internal_originalTime": 1689732345000
    },
    {
        "time": 1689732346000,
        "value": 139.22,
        "_internal_originalTime": 1689732346000
    },
    {
        "time": 1689732348000,
        "value": 139.22,
        "_internal_originalTime": 1689732348000
    },
    {
        "time": 1689732349000,
        "value": 139.238,
        "_internal_originalTime": 1689732349000
    },
    {
        "time": 1689732350000,
        "value": 139.258,
        "_internal_originalTime": 1689732350000
    },
    {
        "time": 1689732351000,
        "value": 139.255,
        "_internal_originalTime": 1689732351000
    },
    {
        "time": 1689732353000,
        "value": 139.258,
        "_internal_originalTime": 1689732353000
    },
    {
        "time": 1689732354000,
        "value": 139.259,
        "_internal_originalTime": 1689732354000
    },
    {
        "time": 1689732355000,
        "value": 139.256,
        "_internal_originalTime": 1689732355000
    },
    {
        "time": 1689732356000,
        "value": 139.254,
        "_internal_originalTime": 1689732356000
    },
    {
        "time": 1689732357000,
        "value": 139.249,
        "_internal_originalTime": 1689732357000
    },
    {
        "time": 1689732359000,
        "value": 139.249,
        "_internal_originalTime": 1689732359000
    },
    {
        "time": 1689732360000,
        "value": 139.24,
        "_internal_originalTime": 1689732360000
    },
    {
        "time": 1689732361000,
        "value": 139.24,
        "_internal_originalTime": 1689732361000
    },
    {
        "time": 1689732362000,
        "value": 139.238,
        "_internal_originalTime": 1689732362000
    },
    {
        "time": 1689732363000,
        "value": 139.247,
        "_internal_originalTime": 1689732363000
    },
    {
        "time": 1689732364000,
        "value": 139.242,
        "_internal_originalTime": 1689732364000
    },
    {
        "time": 1689732365000,
        "value": 139.249,
        "_internal_originalTime": 1689732365000
    },
    {
        "time": 1689732367000,
        "value": 139.252,
        "_internal_originalTime": 1689732367000
    },
    {
        "time": 1689732368000,
        "value": 139.244,
        "_internal_originalTime": 1689732368000
    },
    {
        "time": 1689732369000,
        "value": 139.255,
        "_internal_originalTime": 1689732369000
    },
    {
        "time": 1689732370000,
        "value": 139.252,
        "_internal_originalTime": 1689732370000
    },
    {
        "time": 1689732372000,
        "value": 139.248,
        "_internal_originalTime": 1689732372000
    },
    {
        "time": 1689732373000,
        "value": 139.249,
        "_internal_originalTime": 1689732373000
    },
    {
        "time": 1689732374000,
        "value": 139.237,
        "_internal_originalTime": 1689732374000
    },
    {
        "time": 1689732375000,
        "value": 139.241,
        "_internal_originalTime": 1689732375000
    },
    {
        "time": 1689732378000,
        "value": 139.253,
        "_internal_originalTime": 1689732378000
    },
    {
        "time": 1689732379000,
        "value": 139.246,
        "_internal_originalTime": 1689732379000
    },
    {
        "time": 1689732380000,
        "value": 139.241,
        "_internal_originalTime": 1689732380000
    },
    {
        "time": 1689732382000,
        "value": 139.264,
        "_internal_originalTime": 1689732382000
    },
    {
        "time": 1689732383000,
        "value": 139.265,
        "_internal_originalTime": 1689732383000
    },
    {
        "time": 1689732384000,
        "value": 139.267,
        "_internal_originalTime": 1689732384000
    },
    {
        "time": 1689732385000,
        "value": 139.262,
        "_internal_originalTime": 1689732385000
    },
    {
        "time": 1689732386000,
        "value": 139.261,
        "_internal_originalTime": 1689732386000
    },
    {
        "time": 1689732387000,
        "value": 139.264,
        "_internal_originalTime": 1689732387000
    },
    {
        "time": 1689732388000,
        "value": 139.268,
        "_internal_originalTime": 1689732388000
    },
    {
        "time": 1689732389000,
        "value": 139.266,
        "_internal_originalTime": 1689732389000
    },
    {
        "time": 1689732391000,
        "value": 139.266,
        "_internal_originalTime": 1689732391000
    },
    {
        "time": 1689732392000,
        "value": 139.275,
        "_internal_originalTime": 1689732392000
    },
    {
        "time": 1689732394000,
        "value": 139.287,
        "_internal_originalTime": 1689732394000
    },
    {
        "time": 1689732396000,
        "value": 139.288,
        "_internal_originalTime": 1689732396000
    },
    {
        "time": 1689732397000,
        "value": 139.285,
        "_internal_originalTime": 1689732397000
    },
    {
        "time": 1689732398000,
        "value": 139.282,
        "_internal_originalTime": 1689732398000
    },
    {
        "time": 1689732399000,
        "value": 139.29,
        "_internal_originalTime": 1689732399000
    },
    {
        "time": 1689732400000,
        "value": 139.294,
        "_internal_originalTime": 1689732400000
    },
    {
        "time": 1689732401000,
        "value": 139.294,
        "_internal_originalTime": 1689732401000
    },
    {
        "time": 1689732403000,
        "value": 139.291,
        "_internal_originalTime": 1689732403000
    },
    {
        "time": 1689732405000,
        "value": 139.284,
        "_internal_originalTime": 1689732405000
    },
    {
        "time": 1689732406000,
        "value": 139.28,
        "_internal_originalTime": 1689732406000
    },
    {
        "time": 1689732407000,
        "value": 139.278,
        "_internal_originalTime": 1689732407000
    },
    {
        "time": 1689732409000,
        "value": 139.277,
        "_internal_originalTime": 1689732409000
    },
    {
        "time": 1689732410000,
        "value": 139.279,
        "_internal_originalTime": 1689732410000
    },
    {
        "time": 1689732412000,
        "value": 139.301,
        "_internal_originalTime": 1689732412000
    },
    {
        "time": 1689732413000,
        "value": 139.305,
        "_internal_originalTime": 1689732413000
    },
    {
        "time": 1689732414000,
        "value": 139.304,
        "_internal_originalTime": 1689732414000
    },
    {
        "time": 1689732415000,
        "value": 139.304,
        "_internal_originalTime": 1689732415000
    },
    {
        "time": 1689732416000,
        "value": 139.309,
        "_internal_originalTime": 1689732416000
    },
    {
        "time": 1689732418000,
        "value": 139.315,
        "_internal_originalTime": 1689732418000
    },
    {
        "time": 1689732419000,
        "value": 139.313,
        "_internal_originalTime": 1689732419000
    },
    {
        "time": 1689732420000,
        "value": 139.315,
        "_internal_originalTime": 1689732420000
    },
    {
        "time": 1689732421000,
        "value": 139.315,
        "_internal_originalTime": 1689732421000
    },
    {
        "time": 1689732422000,
        "value": 139.314,
        "_internal_originalTime": 1689732422000
    },
    {
        "time": 1689732423000,
        "value": 139.323,
        "_internal_originalTime": 1689732423000
    },
    {
        "time": 1689732424000,
        "value": 139.325,
        "_internal_originalTime": 1689732424000
    },
    {
        "time": 1689732426000,
        "value": 139.304,
        "_internal_originalTime": 1689732426000
    },
    {
        "time": 1689732427000,
        "value": 139.309,
        "_internal_originalTime": 1689732427000
    },
    {
        "time": 1689732428000,
        "value": 139.311,
        "_internal_originalTime": 1689732428000
    },
    {
        "time": 1689732430000,
        "value": 139.304,
        "_internal_originalTime": 1689732430000
    },
    {
        "time": 1689732431000,
        "value": 139.308,
        "_internal_originalTime": 1689732431000
    },
    {
        "time": 1689732432000,
        "value": 139.306,
        "_internal_originalTime": 1689732432000
    },
    {
        "time": 1689732433000,
        "value": 139.313,
        "_internal_originalTime": 1689732433000
    },
    {
        "time": 1689732434000,
        "value": 139.316,
        "_internal_originalTime": 1689732434000
    },
    {
        "time": 1689732435000,
        "value": 139.308,
        "_internal_originalTime": 1689732435000
    },
    {
        "time": 1689732436000,
        "value": 139.309,
        "_internal_originalTime": 1689732436000
    },
    {
        "time": 1689732437000,
        "value": 139.31,
        "_internal_originalTime": 1689732437000
    },
    {
        "time": 1689732439000,
        "value": 139.312,
        "_internal_originalTime": 1689732439000
    },
    {
        "time": 1689732440000,
        "value": 139.311,
        "_internal_originalTime": 1689732440000
    },
    {
        "time": 1689732441000,
        "value": 139.294,
        "_internal_originalTime": 1689732441000
    },
    {
        "time": 1689732442000,
        "value": 139.285,
        "_internal_originalTime": 1689732442000
    },
    {
        "time": 1689732443000,
        "value": 139.287,
        "_internal_originalTime": 1689732443000
    },
    {
        "time": 1689732444000,
        "value": 139.276,
        "_internal_originalTime": 1689732444000
    },
    {
        "time": 1689732445000,
        "value": 139.276,
        "_internal_originalTime": 1689732445000
    },
    {
        "time": 1689732446000,
        "value": 139.284,
        "_internal_originalTime": 1689732446000
    },
    {
        "time": 1689732448000,
        "value": 139.288,
        "_internal_originalTime": 1689732448000
    },
    {
        "time": 1689732449000,
        "value": 139.288,
        "_internal_originalTime": 1689732449000
    },
    {
        "time": 1689732450000,
        "value": 139.284,
        "_internal_originalTime": 1689732450000
    },
    {
        "time": 1689732451000,
        "value": 139.286,
        "_internal_originalTime": 1689732451000
    },
    {
        "time": 1689732452000,
        "value": 139.285,
        "_internal_originalTime": 1689732452000
    },
    {
        "time": 1689732453000,
        "value": 139.284,
        "_internal_originalTime": 1689732453000
    },
    {
        "time": 1689732455000,
        "value": 139.292,
        "_internal_originalTime": 1689732455000
    },
    {
        "time": 1689732456000,
        "value": 139.294,
        "_internal_originalTime": 1689732456000
    },
    {
        "time": 1689732457000,
        "value": 139.29,
        "_internal_originalTime": 1689732457000
    },
    {
        "time": 1689732458000,
        "value": 139.266,
        "_internal_originalTime": 1689732458000
    },
    {
        "time": 1689732459000,
        "value": 139.268,
        "_internal_originalTime": 1689732459000
    },
    {
        "time": 1689732461000,
        "value": 139.265,
        "_internal_originalTime": 1689732461000
    },
    {
        "time": 1689732463000,
        "value": 139.265,
        "_internal_originalTime": 1689732463000
    },
    {
        "time": 1689732464000,
        "value": 139.275,
        "_internal_originalTime": 1689732464000
    },
    {
        "time": 1689732465000,
        "value": 139.279,
        "_internal_originalTime": 1689732465000
    },
    {
        "time": 1689732466000,
        "value": 139.283,
        "_internal_originalTime": 1689732466000
    },
    {
        "time": 1689732467000,
        "value": 139.284,
        "_internal_originalTime": 1689732467000
    },
    {
        "time": 1689732469000,
        "value": 139.278,
        "_internal_originalTime": 1689732469000
    },
    {
        "time": 1689732470000,
        "value": 139.282,
        "_internal_originalTime": 1689732470000
    },
    {
        "time": 1689732471000,
        "value": 139.282,
        "_internal_originalTime": 1689732471000
    },
    {
        "time": 1689732472000,
        "value": 139.288,
        "_internal_originalTime": 1689732472000
    },
    {
        "time": 1689732473000,
        "value": 139.284,
        "_internal_originalTime": 1689732473000
    },
    {
        "time": 1689732475000,
        "value": 139.288,
        "_internal_originalTime": 1689732475000
    },
    {
        "time": 1689732476000,
        "value": 139.283,
        "_internal_originalTime": 1689732476000
    },
    {
        "time": 1689732477000,
        "value": 139.285,
        "_internal_originalTime": 1689732477000
    },
    {
        "time": 1689732479000,
        "value": 139.288,
        "_internal_originalTime": 1689732479000
    },
    {
        "time": 1689732480000,
        "value": 139.288,
        "_internal_originalTime": 1689732480000
    },
    {
        "time": 1689732481000,
        "value": 139.291,
        "_internal_originalTime": 1689732481000
    },
    {
        "time": 1689732482000,
        "value": 139.291,
        "_internal_originalTime": 1689732482000
    },
    {
        "time": 1689732484000,
        "value": 139.285,
        "_internal_originalTime": 1689732484000
    },
    {
        "time": 1689732485000,
        "value": 139.288,
        "_internal_originalTime": 1689732485000
    },
    {
        "time": 1689732487000,
        "value": 139.288,
        "_internal_originalTime": 1689732487000
    },
    {
        "time": 1689732488000,
        "value": 139.285,
        "_internal_originalTime": 1689732488000
    },
    {
        "time": 1689732490000,
        "value": 139.293,
        "_internal_originalTime": 1689732490000
    },
    {
        "time": 1689732491000,
        "value": 139.292,
        "_internal_originalTime": 1689732491000
    },
    {
        "time": 1689732492000,
        "value": 139.296,
        "_internal_originalTime": 1689732492000
    },
    {
        "time": 1689732493000,
        "value": 139.29,
        "_internal_originalTime": 1689732493000
    },
    {
        "time": 1689732494000,
        "value": 139.293,
        "_internal_originalTime": 1689732494000
    },
    {
        "time": 1689732496000,
        "value": 139.296,
        "_internal_originalTime": 1689732496000
    },
    {
        "time": 1689732498000,
        "value": 139.291,
        "_internal_originalTime": 1689732498000
    },
    {
        "time": 1689732499000,
        "value": 139.296,
        "_internal_originalTime": 1689732499000
    },
    {
        "time": 1689732500000,
        "value": 139.289,
        "_internal_originalTime": 1689732500000
    },
    {
        "time": 1689732501000,
        "value": 139.284,
        "_internal_originalTime": 1689732501000
    },
    {
        "time": 1689732502000,
        "value": 139.287,
        "_internal_originalTime": 1689732502000
    },
    {
        "time": 1689732503000,
        "value": 139.288,
        "_internal_originalTime": 1689732503000
    },
    {
        "time": 1689732504000,
        "value": 139.29,
        "_internal_originalTime": 1689732504000
    },
    {
        "time": 1689732505000,
        "value": 139.287,
        "_internal_originalTime": 1689732505000
    },
    {
        "time": 1689732506000,
        "value": 139.296,
        "_internal_originalTime": 1689732506000
    },
    {
        "time": 1689732508000,
        "value": 139.296,
        "_internal_originalTime": 1689732508000
    },
    {
        "time": 1689732509000,
        "value": 139.294,
        "_internal_originalTime": 1689732509000
    },
    {
        "time": 1689732510000,
        "value": 139.293,
        "_internal_originalTime": 1689732510000
    },
    {
        "time": 1689732511000,
        "value": 139.293,
        "_internal_originalTime": 1689732511000
    },
    {
        "time": 1689732512000,
        "value": 139.292,
        "_internal_originalTime": 1689732512000
    },
    {
        "time": 1689732513000,
        "value": 139.29,
        "_internal_originalTime": 1689732513000
    },
    {
        "time": 1689732516000,
        "value": 139.29,
        "_internal_originalTime": 1689732516000
    },
    {
        "time": 1689732517000,
        "value": 139.296,
        "_internal_originalTime": 1689732517000
    },
    {
        "time": 1689732518000,
        "value": 139.287,
        "_internal_originalTime": 1689732518000
    },
    {
        "time": 1689732520000,
        "value": 139.286,
        "_internal_originalTime": 1689732520000
    },
    {
        "time": 1689732521000,
        "value": 139.287,
        "_internal_originalTime": 1689732521000
    },
    {
        "time": 1689732522000,
        "value": 139.291,
        "_internal_originalTime": 1689732522000
    },
    {
        "time": 1689732523000,
        "value": 139.293,
        "_internal_originalTime": 1689732523000
    },
    {
        "time": 1689732524000,
        "value": 139.296,
        "_internal_originalTime": 1689732524000
    },
    {
        "time": 1689732525000,
        "value": 139.294,
        "_internal_originalTime": 1689732525000
    },
    {
        "time": 1689732526000,
        "value": 139.292,
        "_internal_originalTime": 1689732526000
    },
    {
        "time": 1689732528000,
        "value": 139.323,
        "_internal_originalTime": 1689732528000
    },
    {
        "time": 1689732529000,
        "value": 139.322,
        "_internal_originalTime": 1689732529000
    },
    {
        "time": 1689732530000,
        "value": 139.322,
        "_internal_originalTime": 1689732530000
    },
    {
        "time": 1689732531000,
        "value": 139.323,
        "_internal_originalTime": 1689732531000
    },
    {
        "time": 1689732532000,
        "value": 139.322,
        "_internal_originalTime": 1689732532000
    },
    {
        "time": 1689732534000,
        "value": 139.327,
        "_internal_originalTime": 1689732534000
    },
    {
        "time": 1689732535000,
        "value": 139.322,
        "_internal_originalTime": 1689732535000
    },
    {
        "time": 1689732536000,
        "value": 139.326,
        "_internal_originalTime": 1689732536000
    },
    {
        "time": 1689732537000,
        "value": 139.31,
        "_internal_originalTime": 1689732537000
    },
    {
        "time": 1689732539000,
        "value": 139.304,
        "_internal_originalTime": 1689732539000
    },
    {
        "time": 1689732540000,
        "value": 139.31,
        "_internal_originalTime": 1689732540000
    },
    {
        "time": 1689732541000,
        "value": 139.3,
        "_internal_originalTime": 1689732541000
    },
    {
        "time": 1689732542000,
        "value": 139.296,
        "_internal_originalTime": 1689732542000
    },
    {
        "time": 1689732544000,
        "value": 139.292,
        "_internal_originalTime": 1689732544000
    },
    {
        "time": 1689732545000,
        "value": 139.289,
        "_internal_originalTime": 1689732545000
    },
    {
        "time": 1689732547000,
        "value": 139.288,
        "_internal_originalTime": 1689732547000
    },
    {
        "time": 1689732548000,
        "value": 139.282,
        "_internal_originalTime": 1689732548000
    },
    {
        "time": 1689732549000,
        "value": 139.284,
        "_internal_originalTime": 1689732549000
    },
    {
        "time": 1689732551000,
        "value": 139.283,
        "_internal_originalTime": 1689732551000
    },
    {
        "time": 1689732552000,
        "value": 139.28,
        "_internal_originalTime": 1689732552000
    },
    {
        "time": 1689732553000,
        "value": 139.287,
        "_internal_originalTime": 1689732553000
    },
    {
        "time": 1689732555000,
        "value": 139.288,
        "_internal_originalTime": 1689732555000
    },
    {
        "time": 1689732557000,
        "value": 139.283,
        "_internal_originalTime": 1689732557000
    },
    {
        "time": 1689732559000,
        "value": 139.294,
        "_internal_originalTime": 1689732559000
    },
    {
        "time": 1689732560000,
        "value": 139.292,
        "_internal_originalTime": 1689732560000
    },
    {
        "time": 1689732561000,
        "value": 139.3,
        "_internal_originalTime": 1689732561000
    },
    {
        "time": 1689732562000,
        "value": 139.29,
        "_internal_originalTime": 1689732562000
    },
    {
        "time": 1689732564000,
        "value": 139.29,
        "_internal_originalTime": 1689732564000
    },
    {
        "time": 1689732565000,
        "value": 139.29,
        "_internal_originalTime": 1689732565000
    },
    {
        "time": 1689732566000,
        "value": 139.295,
        "_internal_originalTime": 1689732566000
    },
    {
        "time": 1689732567000,
        "value": 139.3,
        "_internal_originalTime": 1689732567000
    },
    {
        "time": 1689732568000,
        "value": 139.296,
        "_internal_originalTime": 1689732568000
    },
    {
        "time": 1689732570000,
        "value": 139.292,
        "_internal_originalTime": 1689732570000
    },
    {
        "time": 1689732571000,
        "value": 139.289,
        "_internal_originalTime": 1689732571000
    },
    {
        "time": 1689732573000,
        "value": 139.284,
        "_internal_originalTime": 1689732573000
    },
    {
        "time": 1689732574000,
        "value": 139.287,
        "_internal_originalTime": 1689732574000
    },
    {
        "time": 1689732576000,
        "value": 139.282,
        "_internal_originalTime": 1689732576000
    },
    {
        "time": 1689732577000,
        "value": 139.282,
        "_internal_originalTime": 1689732577000
    },
    {
        "time": 1689732579000,
        "value": 139.282,
        "_internal_originalTime": 1689732579000
    },
    {
        "time": 1689732580000,
        "value": 139.28,
        "_internal_originalTime": 1689732580000
    },
    {
        "time": 1689732581000,
        "value": 139.281,
        "_internal_originalTime": 1689732581000
    },
    {
        "time": 1689732582000,
        "value": 139.277,
        "_internal_originalTime": 1689732582000
    },
    {
        "time": 1689732584000,
        "value": 139.276,
        "_internal_originalTime": 1689732584000
    },
    {
        "time": 1689732589000,
        "value": 139.264,
        "_internal_originalTime": 1689732589000
    },
    {
        "time": 1689732591000,
        "value": 139.277,
        "_internal_originalTime": 1689732591000
    },
    {
        "time": 1689732592000,
        "value": 139.277,
        "_internal_originalTime": 1689732592000
    },
    {
        "time": 1689732594000,
        "value": 139.276,
        "_internal_originalTime": 1689732594000
    },
    {
        "time": 1689732595000,
        "value": 139.27,
        "_internal_originalTime": 1689732595000
    },
    {
        "time": 1689732596000,
        "value": 139.277,
        "_internal_originalTime": 1689732596000
    },
    {
        "time": 1689732597000,
        "value": 139.278,
        "_internal_originalTime": 1689732597000
    },
    {
        "time": 1689732599000,
        "value": 139.273,
        "_internal_originalTime": 1689732599000
    },
    {
        "time": 1689732600000,
        "value": 139.272,
        "_internal_originalTime": 1689732600000
    },
    {
        "time": 1689732602000,
        "value": 139.275,
        "_internal_originalTime": 1689732602000
    },
    {
        "time": 1689732603000,
        "value": 139.277,
        "_internal_originalTime": 1689732603000
    },
    {
        "time": 1689732606000,
        "value": 139.273,
        "_internal_originalTime": 1689732606000
    },
    {
        "time": 1689732608000,
        "value": 139.269,
        "_internal_originalTime": 1689732608000
    },
    {
        "time": 1689732610000,
        "value": 139.267,
        "_internal_originalTime": 1689732610000
    },
    {
        "time": 1689732611000,
        "value": 139.265,
        "_internal_originalTime": 1689732611000
    },
    {
        "time": 1689732612000,
        "value": 139.264,
        "_internal_originalTime": 1689732612000
    },
    {
        "time": 1689732614000,
        "value": 139.258,
        "_internal_originalTime": 1689732614000
    },
    {
        "time": 1689732615000,
        "value": 139.26,
        "_internal_originalTime": 1689732615000
    },
    {
        "time": 1689732616000,
        "value": 139.256,
        "_internal_originalTime": 1689732616000
    },
    {
        "time": 1689732617000,
        "value": 139.259,
        "_internal_originalTime": 1689732617000
    },
    {
        "time": 1689732618000,
        "value": 139.259,
        "_internal_originalTime": 1689732618000
    },
    {
        "time": 1689732619000,
        "value": 139.255,
        "_internal_originalTime": 1689732619000
    },
    {
        "time": 1689732620000,
        "value": 139.259,
        "_internal_originalTime": 1689732620000
    },
    {
        "time": 1689732621000,
        "value": 139.256,
        "_internal_originalTime": 1689732621000
    },
    {
        "time": 1689732623000,
        "value": 139.254,
        "_internal_originalTime": 1689732623000
    },
    {
        "time": 1689732624000,
        "value": 139.254,
        "_internal_originalTime": 1689732624000
    },
    {
        "time": 1689732625000,
        "value": 139.255,
        "_internal_originalTime": 1689732625000
    },
    {
        "time": 1689732627000,
        "value": 139.256,
        "_internal_originalTime": 1689732627000
    },
    {
        "time": 1689732628000,
        "value": 139.254,
        "_internal_originalTime": 1689732628000
    },
    {
        "time": 1689732629000,
        "value": 139.254,
        "_internal_originalTime": 1689732629000
    },
    {
        "time": 1689732631000,
        "value": 139.254,
        "_internal_originalTime": 1689732631000
    },
    {
        "time": 1689732632000,
        "value": 139.255,
        "_internal_originalTime": 1689732632000
    },
    {
        "time": 1689732633000,
        "value": 139.265,
        "_internal_originalTime": 1689732633000
    },
    {
        "time": 1689732634000,
        "value": 139.26,
        "_internal_originalTime": 1689732634000
    },
    {
        "time": 1689732636000,
        "value": 139.256,
        "_internal_originalTime": 1689732636000
    },
    {
        "time": 1689732637000,
        "value": 139.259,
        "_internal_originalTime": 1689732637000
    },
    {
        "time": 1689732638000,
        "value": 139.258,
        "_internal_originalTime": 1689732638000
    },
    {
        "time": 1689732641000,
        "value": 139.253,
        "_internal_originalTime": 1689732641000
    },
    {
        "time": 1689732642000,
        "value": 139.248,
        "_internal_originalTime": 1689732642000
    },
    {
        "time": 1689732643000,
        "value": 139.249,
        "_internal_originalTime": 1689732643000
    },
    {
        "time": 1689732644000,
        "value": 139.248,
        "_internal_originalTime": 1689732644000
    },
    {
        "time": 1689732645000,
        "value": 139.238,
        "_internal_originalTime": 1689732645000
    },
    {
        "time": 1689732646000,
        "value": 139.239,
        "_internal_originalTime": 1689732646000
    },
    {
        "time": 1689732648000,
        "value": 139.233,
        "_internal_originalTime": 1689732648000
    },
    {
        "time": 1689732649000,
        "value": 139.238,
        "_internal_originalTime": 1689732649000
    },
    {
        "time": 1689732650000,
        "value": 139.235,
        "_internal_originalTime": 1689732650000
    },
    {
        "time": 1689732651000,
        "value": 139.229,
        "_internal_originalTime": 1689732651000
    },
    {
        "time": 1689732653000,
        "value": 139.227,
        "_internal_originalTime": 1689732653000
    },
    {
        "time": 1689732654000,
        "value": 139.222,
        "_internal_originalTime": 1689732654000
    },
    {
        "time": 1689732655000,
        "value": 139.224,
        "_internal_originalTime": 1689732655000
    },
    {
        "time": 1689732656000,
        "value": 139.224,
        "_internal_originalTime": 1689732656000
    },
    {
        "time": 1689732657000,
        "value": 139.23,
        "_internal_originalTime": 1689732657000
    },
    {
        "time": 1689732659000,
        "value": 139.229,
        "_internal_originalTime": 1689732659000
    },
    {
        "time": 1689732660000,
        "value": 139.226,
        "_internal_originalTime": 1689732660000
    },
    {
        "time": 1689732661000,
        "value": 139.232,
        "_internal_originalTime": 1689732661000
    },
    {
        "time": 1689732662000,
        "value": 139.224,
        "_internal_originalTime": 1689732662000
    },
    {
        "time": 1689732663000,
        "value": 139.226,
        "_internal_originalTime": 1689732663000
    },
    {
        "time": 1689732664000,
        "value": 139.23,
        "_internal_originalTime": 1689732664000
    },
    {
        "time": 1689732666000,
        "value": 139.232,
        "_internal_originalTime": 1689732666000
    },
    {
        "time": 1689732667000,
        "value": 139.222,
        "_internal_originalTime": 1689732667000
    },
    {
        "time": 1689732668000,
        "value": 139.223,
        "_internal_originalTime": 1689732668000
    },
    {
        "time": 1689732669000,
        "value": 139.229,
        "_internal_originalTime": 1689732669000
    },
    {
        "time": 1689732672000,
        "value": 139.226,
        "_internal_originalTime": 1689732672000
    },
    {
        "time": 1689732675000,
        "value": 139.224,
        "_internal_originalTime": 1689732675000
    },
    {
        "time": 1689732676000,
        "value": 139.224,
        "_internal_originalTime": 1689732676000
    },
    {
        "time": 1689732678000,
        "value": 139.227,
        "_internal_originalTime": 1689732678000
    },
    {
        "time": 1689732679000,
        "value": 139.226,
        "_internal_originalTime": 1689732679000
    },
    {
        "time": 1689732680000,
        "value": 139.231,
        "_internal_originalTime": 1689732680000
    },
    {
        "time": 1689732682000,
        "value": 139.229,
        "_internal_originalTime": 1689732682000
    },
    {
        "time": 1689732684000,
        "value": 139.23,
        "_internal_originalTime": 1689732684000
    },
    {
        "time": 1689732686000,
        "value": 139.224,
        "_internal_originalTime": 1689732686000
    },
    {
        "time": 1689732687000,
        "value": 139.23,
        "_internal_originalTime": 1689732687000
    },
    {
        "time": 1689732689000,
        "value": 139.226,
        "_internal_originalTime": 1689732689000
    },
    {
        "time": 1689732691000,
        "value": 139.231,
        "_internal_originalTime": 1689732691000
    },
    {
        "time": 1689732692000,
        "value": 139.223,
        "_internal_originalTime": 1689732692000
    },
    {
        "time": 1689732694000,
        "value": 139.227,
        "_internal_originalTime": 1689732694000
    },
    {
        "time": 1689732695000,
        "value": 139.228,
        "_internal_originalTime": 1689732695000
    },
    {
        "time": 1689732696000,
        "value": 139.224,
        "_internal_originalTime": 1689732696000
    },
    {
        "time": 1689732697000,
        "value": 139.23,
        "_internal_originalTime": 1689732697000
    },
    {
        "time": 1689732698000,
        "value": 139.224,
        "_internal_originalTime": 1689732698000
    },
    {
        "time": 1689732699000,
        "value": 139.226,
        "_internal_originalTime": 1689732699000
    },
    {
        "time": 1689732700000,
        "value": 139.23,
        "_internal_originalTime": 1689732700000
    },
    {
        "time": 1689732702000,
        "value": 139.224,
        "_internal_originalTime": 1689732702000
    },
    {
        "time": 1689732703000,
        "value": 139.229,
        "_internal_originalTime": 1689732703000
    },
    {
        "time": 1689732704000,
        "value": 139.22,
        "_internal_originalTime": 1689732704000
    },
    {
        "time": 1689732705000,
        "value": 139.216,
        "_internal_originalTime": 1689732705000
    },
    {
        "time": 1689732710000,
        "value": 139.222,
        "_internal_originalTime": 1689732710000
    },
    {
        "time": 1689732711000,
        "value": 139.217,
        "_internal_originalTime": 1689732711000
    },
    {
        "time": 1689732712000,
        "value": 139.217,
        "_internal_originalTime": 1689732712000
    },
    {
        "time": 1689732714000,
        "value": 139.217,
        "_internal_originalTime": 1689732714000
    },
    {
        "time": 1689732715000,
        "value": 139.227,
        "_internal_originalTime": 1689732715000
    },
    {
        "time": 1689732717000,
        "value": 139.227,
        "_internal_originalTime": 1689732717000
    },
    {
        "time": 1689732719000,
        "value": 139.221,
        "_internal_originalTime": 1689732719000
    },
    {
        "time": 1689732720000,
        "value": 139.226,
        "_internal_originalTime": 1689732720000
    },
    {
        "time": 1689732721000,
        "value": 139.225,
        "_internal_originalTime": 1689732721000
    },
    {
        "time": 1689732722000,
        "value": 139.213,
        "_internal_originalTime": 1689732722000
    },
    {
        "time": 1689732723000,
        "value": 139.209,
        "_internal_originalTime": 1689732723000
    },
    {
        "time": 1689732725000,
        "value": 139.217,
        "_internal_originalTime": 1689732725000
    },
    {
        "time": 1689732727000,
        "value": 139.215,
        "_internal_originalTime": 1689732727000
    },
    {
        "time": 1689732728000,
        "value": 139.219,
        "_internal_originalTime": 1689732728000
    },
    {
        "time": 1689732729000,
        "value": 139.219,
        "_internal_originalTime": 1689732729000
    },
    {
        "time": 1689732730000,
        "value": 139.218,
        "_internal_originalTime": 1689732730000
    },
    {
        "time": 1689732732000,
        "value": 139.223,
        "_internal_originalTime": 1689732732000
    },
    {
        "time": 1689732733000,
        "value": 139.219,
        "_internal_originalTime": 1689732733000
    },
    {
        "time": 1689732734000,
        "value": 139.216,
        "_internal_originalTime": 1689732734000
    },
    {
        "time": 1689732735000,
        "value": 139.222,
        "_internal_originalTime": 1689732735000
    },
    {
        "time": 1689732737000,
        "value": 139.212,
        "_internal_originalTime": 1689732737000
    },
    {
        "time": 1689732738000,
        "value": 139.215,
        "_internal_originalTime": 1689732738000
    },
    {
        "time": 1689732740000,
        "value": 139.219,
        "_internal_originalTime": 1689732740000
    },
    {
        "time": 1689732741000,
        "value": 139.217,
        "_internal_originalTime": 1689732741000
    },
    {
        "time": 1689732742000,
        "value": 139.197,
        "_internal_originalTime": 1689732742000
    },
    {
        "time": 1689732743000,
        "value": 139.199,
        "_internal_originalTime": 1689732743000
    },
    {
        "time": 1689732744000,
        "value": 139.199,
        "_internal_originalTime": 1689732744000
    },
    {
        "time": 1689732745000,
        "value": 139.199,
        "_internal_originalTime": 1689732745000
    },
    {
        "time": 1689732747000,
        "value": 139.196,
        "_internal_originalTime": 1689732747000
    },
    {
        "time": 1689732748000,
        "value": 139.197,
        "_internal_originalTime": 1689732748000
    },
    {
        "time": 1689732749000,
        "value": 139.196,
        "_internal_originalTime": 1689732749000
    },
    {
        "time": 1689732750000,
        "value": 139.199,
        "_internal_originalTime": 1689732750000
    },
    {
        "time": 1689732751000,
        "value": 139.199,
        "_internal_originalTime": 1689732751000
    },
    {
        "time": 1689732753000,
        "value": 139.195,
        "_internal_originalTime": 1689732753000
    },
    {
        "time": 1689732754000,
        "value": 139.202,
        "_internal_originalTime": 1689732754000
    },
    {
        "time": 1689732755000,
        "value": 139.208,
        "_internal_originalTime": 1689732755000
    },
    {
        "time": 1689732757000,
        "value": 139.208,
        "_internal_originalTime": 1689732757000
    },
    {
        "time": 1689732758000,
        "value": 139.21,
        "_internal_originalTime": 1689732758000
    },
    {
        "time": 1689732759000,
        "value": 139.207,
        "_internal_originalTime": 1689732759000
    },
    {
        "time": 1689732761000,
        "value": 139.205,
        "_internal_originalTime": 1689732761000
    },
    {
        "time": 1689732763000,
        "value": 139.204,
        "_internal_originalTime": 1689732763000
    },
    {
        "time": 1689732764000,
        "value": 139.207,
        "_internal_originalTime": 1689732764000
    },
    {
        "time": 1689732765000,
        "value": 139.203,
        "_internal_originalTime": 1689732765000
    },
    {
        "time": 1689732767000,
        "value": 139.209,
        "_internal_originalTime": 1689732767000
    },
    {
        "time": 1689732768000,
        "value": 139.208,
        "_internal_originalTime": 1689732768000
    },
    {
        "time": 1689732769000,
        "value": 139.207,
        "_internal_originalTime": 1689732769000
    },
    {
        "time": 1689732771000,
        "value": 139.21,
        "_internal_originalTime": 1689732771000
    },
    {
        "time": 1689732772000,
        "value": 139.207,
        "_internal_originalTime": 1689732772000
    },
    {
        "time": 1689732774000,
        "value": 139.206,
        "_internal_originalTime": 1689732774000
    },
    {
        "time": 1689732775000,
        "value": 139.207,
        "_internal_originalTime": 1689732775000
    },
    {
        "time": 1689732778000,
        "value": 139.205,
        "_internal_originalTime": 1689732778000
    },
    {
        "time": 1689732780000,
        "value": 139.207,
        "_internal_originalTime": 1689732780000
    },
    {
        "time": 1689732781000,
        "value": 139.18,
        "_internal_originalTime": 1689732781000
    },
    {
        "time": 1689732783000,
        "value": 139.207,
        "_internal_originalTime": 1689732783000
    },
    {
        "time": 1689732785000,
        "value": 139.207,
        "_internal_originalTime": 1689732785000
    },
    {
        "time": 1689732786000,
        "value": 139.204,
        "_internal_originalTime": 1689732786000
    },
    {
        "time": 1689732787000,
        "value": 139.205,
        "_internal_originalTime": 1689732787000
    },
    {
        "time": 1689732788000,
        "value": 139.208,
        "_internal_originalTime": 1689732788000
    },
    {
        "time": 1689732790000,
        "value": 139.205,
        "_internal_originalTime": 1689732790000
    },
    {
        "time": 1689732791000,
        "value": 139.208,
        "_internal_originalTime": 1689732791000
    },
    {
        "time": 1689732792000,
        "value": 139.204,
        "_internal_originalTime": 1689732792000
    },
    {
        "time": 1689732794000,
        "value": 139.206,
        "_internal_originalTime": 1689732794000
    },
    {
        "time": 1689732796000,
        "value": 139.207,
        "_internal_originalTime": 1689732796000
    },
    {
        "time": 1689732797000,
        "value": 139.219,
        "_internal_originalTime": 1689732797000
    },
    {
        "time": 1689732799000,
        "value": 139.218,
        "_internal_originalTime": 1689732799000
    },
    {
        "time": 1689732800000,
        "value": 139.214,
        "_internal_originalTime": 1689732800000
    },
    {
        "time": 1689732801000,
        "value": 139.219,
        "_internal_originalTime": 1689732801000
    },
    {
        "time": 1689732802000,
        "value": 139.219,
        "_internal_originalTime": 1689732802000
    },
    {
        "time": 1689732803000,
        "value": 139.218,
        "_internal_originalTime": 1689732803000
    },
    {
        "time": 1689732804000,
        "value": 139.218,
        "_internal_originalTime": 1689732804000
    },
    {
        "time": 1689732805000,
        "value": 139.214,
        "_internal_originalTime": 1689732805000
    },
    {
        "time": 1689732806000,
        "value": 139.206,
        "_internal_originalTime": 1689732806000
    },
    {
        "time": 1689732807000,
        "value": 139.213,
        "_internal_originalTime": 1689732807000
    },
    {
        "time": 1689732808000,
        "value": 139.205,
        "_internal_originalTime": 1689732808000
    },
    {
        "time": 1689732809000,
        "value": 139.211,
        "_internal_originalTime": 1689732809000
    },
    {
        "time": 1689732811000,
        "value": 139.212,
        "_internal_originalTime": 1689732811000
    },
    {
        "time": 1689732812000,
        "value": 139.215,
        "_internal_originalTime": 1689732812000
    },
    {
        "time": 1689732813000,
        "value": 139.218,
        "_internal_originalTime": 1689732813000
    },
    {
        "time": 1689732814000,
        "value": 139.216,
        "_internal_originalTime": 1689732814000
    },
    {
        "time": 1689732816000,
        "value": 139.218,
        "_internal_originalTime": 1689732816000
    },
    {
        "time": 1689732817000,
        "value": 139.212,
        "_internal_originalTime": 1689732817000
    },
    {
        "time": 1689732818000,
        "value": 139.218,
        "_internal_originalTime": 1689732818000
    },
    {
        "time": 1689732819000,
        "value": 139.219,
        "_internal_originalTime": 1689732819000
    },
    {
        "time": 1689732820000,
        "value": 139.22,
        "_internal_originalTime": 1689732820000
    },
    {
        "time": 1689732822000,
        "value": 139.219,
        "_internal_originalTime": 1689732822000
    },
    {
        "time": 1689732823000,
        "value": 139.214,
        "_internal_originalTime": 1689732823000
    },
    {
        "time": 1689732825000,
        "value": 139.218,
        "_internal_originalTime": 1689732825000
    },
    {
        "time": 1689732826000,
        "value": 139.226,
        "_internal_originalTime": 1689732826000
    },
    {
        "time": 1689732827000,
        "value": 139.228,
        "_internal_originalTime": 1689732827000
    },
    {
        "time": 1689732828000,
        "value": 139.223,
        "_internal_originalTime": 1689732828000
    },
    {
        "time": 1689732829000,
        "value": 139.223,
        "_internal_originalTime": 1689732829000
    },
    {
        "time": 1689732831000,
        "value": 139.222,
        "_internal_originalTime": 1689732831000
    },
    {
        "time": 1689732832000,
        "value": 139.216,
        "_internal_originalTime": 1689732832000
    },
    {
        "time": 1689732833000,
        "value": 139.216,
        "_internal_originalTime": 1689732833000
    },
    {
        "time": 1689732835000,
        "value": 139.216,
        "_internal_originalTime": 1689732835000
    },
    {
        "time": 1689732836000,
        "value": 139.217,
        "_internal_originalTime": 1689732836000
    },
    {
        "time": 1689732838000,
        "value": 139.214,
        "_internal_originalTime": 1689732838000
    },
    {
        "time": 1689732840000,
        "value": 139.218,
        "_internal_originalTime": 1689732840000
    },
    {
        "time": 1689732842000,
        "value": 139.216,
        "_internal_originalTime": 1689732842000
    },
    {
        "time": 1689732844000,
        "value": 139.222,
        "_internal_originalTime": 1689732844000
    },
    {
        "time": 1689732845000,
        "value": 139.219,
        "_internal_originalTime": 1689732845000
    },
    {
        "time": 1689732846000,
        "value": 139.223,
        "_internal_originalTime": 1689732846000
    },
    {
        "time": 1689732847000,
        "value": 139.222,
        "_internal_originalTime": 1689732847000
    },
    {
        "time": 1689732848000,
        "value": 139.228,
        "_internal_originalTime": 1689732848000
    },
    {
        "time": 1689732849000,
        "value": 139.224,
        "_internal_originalTime": 1689732849000
    },
    {
        "time": 1689732850000,
        "value": 139.224,
        "_internal_originalTime": 1689732850000
    },
    {
        "time": 1689732852000,
        "value": 139.233,
        "_internal_originalTime": 1689732852000
    },
    {
        "time": 1689732853000,
        "value": 139.227,
        "_internal_originalTime": 1689732853000
    },
    {
        "time": 1689732855000,
        "value": 139.234,
        "_internal_originalTime": 1689732855000
    },
    {
        "time": 1689732856000,
        "value": 139.233,
        "_internal_originalTime": 1689732856000
    },
    {
        "time": 1689732857000,
        "value": 139.227,
        "_internal_originalTime": 1689732857000
    },
    {
        "time": 1689732858000,
        "value": 139.227,
        "_internal_originalTime": 1689732858000
    },
    {
        "time": 1689732860000,
        "value": 139.226,
        "_internal_originalTime": 1689732860000
    },
    {
        "time": 1689732861000,
        "value": 139.219,
        "_internal_originalTime": 1689732861000
    },
    {
        "time": 1689732862000,
        "value": 139.221,
        "_internal_originalTime": 1689732862000
    },
    {
        "time": 1689732864000,
        "value": 139.212,
        "_internal_originalTime": 1689732864000
    },
    {
        "time": 1689732865000,
        "value": 139.212,
        "_internal_originalTime": 1689732865000
    },
    {
        "time": 1689732866000,
        "value": 139.211,
        "_internal_originalTime": 1689732866000
    },
    {
        "time": 1689732867000,
        "value": 139.207,
        "_internal_originalTime": 1689732867000
    },
    {
        "time": 1689732868000,
        "value": 139.207,
        "_internal_originalTime": 1689732868000
    },
    {
        "time": 1689732869000,
        "value": 139.216,
        "_internal_originalTime": 1689732869000
    },
    {
        "time": 1689732870000,
        "value": 139.215,
        "_internal_originalTime": 1689732870000
    },
    {
        "time": 1689732872000,
        "value": 139.215,
        "_internal_originalTime": 1689732872000
    },
    {
        "time": 1689732873000,
        "value": 139.22,
        "_internal_originalTime": 1689732873000
    },
    {
        "time": 1689732874000,
        "value": 139.216,
        "_internal_originalTime": 1689732874000
    },
    {
        "time": 1689732876000,
        "value": 139.221,
        "_internal_originalTime": 1689732876000
    },
    {
        "time": 1689732877000,
        "value": 139.22,
        "_internal_originalTime": 1689732877000
    },
    {
        "time": 1689732879000,
        "value": 139.22,
        "_internal_originalTime": 1689732879000
    },
    {
        "time": 1689732880000,
        "value": 139.222,
        "_internal_originalTime": 1689732880000
    },
    {
        "time": 1689732881000,
        "value": 139.22,
        "_internal_originalTime": 1689732881000
    },
    {
        "time": 1689732883000,
        "value": 139.223,
        "_internal_originalTime": 1689732883000
    },
    {
        "time": 1689732884000,
        "value": 139.216,
        "_internal_originalTime": 1689732884000
    },
    {
        "time": 1689732885000,
        "value": 139.221,
        "_internal_originalTime": 1689732885000
    },
    {
        "time": 1689732887000,
        "value": 139.217,
        "_internal_originalTime": 1689732887000
    },
    {
        "time": 1689732889000,
        "value": 139.218,
        "_internal_originalTime": 1689732889000
    },
    {
        "time": 1689732890000,
        "value": 139.221,
        "_internal_originalTime": 1689732890000
    },
    {
        "time": 1689732892000,
        "value": 139.217,
        "_internal_originalTime": 1689732892000
    },
    {
        "time": 1689732894000,
        "value": 139.215,
        "_internal_originalTime": 1689732894000
    },
    {
        "time": 1689732895000,
        "value": 139.214,
        "_internal_originalTime": 1689732895000
    },
    {
        "time": 1689732896000,
        "value": 139.216,
        "_internal_originalTime": 1689732896000
    },
    {
        "time": 1689732898000,
        "value": 139.215,
        "_internal_originalTime": 1689732898000
    },
    {
        "time": 1689732899000,
        "value": 139.214,
        "_internal_originalTime": 1689732899000
    },
    {
        "time": 1689732900000,
        "value": 139.213,
        "_internal_originalTime": 1689732900000
    },
    {
        "time": 1689732901000,
        "value": 139.214,
        "_internal_originalTime": 1689732901000
    },
    {
        "time": 1689732902000,
        "value": 139.212,
        "_internal_originalTime": 1689732902000
    },
    {
        "time": 1689732903000,
        "value": 139.211,
        "_internal_originalTime": 1689732903000
    },
    {
        "time": 1689732904000,
        "value": 139.211,
        "_internal_originalTime": 1689732904000
    },
    {
        "time": 1689732906000,
        "value": 139.204,
        "_internal_originalTime": 1689732906000
    },
    {
        "time": 1689732907000,
        "value": 139.205,
        "_internal_originalTime": 1689732907000
    },
    {
        "time": 1689732909000,
        "value": 139.206,
        "_internal_originalTime": 1689732909000
    },
    {
        "time": 1689732910000,
        "value": 139.206,
        "_internal_originalTime": 1689732910000
    },
    {
        "time": 1689732911000,
        "value": 139.203,
        "_internal_originalTime": 1689732911000
    },
    {
        "time": 1689732913000,
        "value": 139.199,
        "_internal_originalTime": 1689732913000
    },
    {
        "time": 1689732914000,
        "value": 139.193,
        "_internal_originalTime": 1689732914000
    },
    {
        "time": 1689732915000,
        "value": 139.194,
        "_internal_originalTime": 1689732915000
    },
    {
        "time": 1689732916000,
        "value": 139.198,
        "_internal_originalTime": 1689732916000
    },
    {
        "time": 1689732917000,
        "value": 139.196,
        "_internal_originalTime": 1689732917000
    },
    {
        "time": 1689732918000,
        "value": 139.196,
        "_internal_originalTime": 1689732918000
    },
    {
        "time": 1689732919000,
        "value": 139.191,
        "_internal_originalTime": 1689732919000
    },
    {
        "time": 1689732920000,
        "value": 139.193,
        "_internal_originalTime": 1689732920000
    },
    {
        "time": 1689732922000,
        "value": 139.187,
        "_internal_originalTime": 1689732922000
    },
    {
        "time": 1689732923000,
        "value": 139.193,
        "_internal_originalTime": 1689732923000
    },
    {
        "time": 1689732924000,
        "value": 139.197,
        "_internal_originalTime": 1689732924000
    },
    {
        "time": 1689732925000,
        "value": 139.188,
        "_internal_originalTime": 1689732925000
    },
    {
        "time": 1689732927000,
        "value": 139.194,
        "_internal_originalTime": 1689732927000
    },
    {
        "time": 1689732928000,
        "value": 139.192,
        "_internal_originalTime": 1689732928000
    },
    {
        "time": 1689732929000,
        "value": 139.193,
        "_internal_originalTime": 1689732929000
    },
    {
        "time": 1689732930000,
        "value": 139.198,
        "_internal_originalTime": 1689732930000
    },
    {
        "time": 1689732931000,
        "value": 139.193,
        "_internal_originalTime": 1689732931000
    },
    {
        "time": 1689732932000,
        "value": 139.19,
        "_internal_originalTime": 1689732932000
    },
    {
        "time": 1689732934000,
        "value": 139.192,
        "_internal_originalTime": 1689732934000
    },
    {
        "time": 1689732935000,
        "value": 139.19,
        "_internal_originalTime": 1689732935000
    },
    {
        "time": 1689732936000,
        "value": 139.19,
        "_internal_originalTime": 1689732936000
    },
    {
        "time": 1689732937000,
        "value": 139.198,
        "_internal_originalTime": 1689732937000
    },
    {
        "time": 1689732938000,
        "value": 139.193,
        "_internal_originalTime": 1689732938000
    },
    {
        "time": 1689732939000,
        "value": 139.182,
        "_internal_originalTime": 1689732939000
    },
    {
        "time": 1689732940000,
        "value": 139.168,
        "_internal_originalTime": 1689732940000
    },
    {
        "time": 1689732941000,
        "value": 139.171,
        "_internal_originalTime": 1689732941000
    },
    {
        "time": 1689732943000,
        "value": 139.174,
        "_internal_originalTime": 1689732943000
    },
    {
        "time": 1689732945000,
        "value": 139.175,
        "_internal_originalTime": 1689732945000
    },
    {
        "time": 1689732947000,
        "value": 139.178,
        "_internal_originalTime": 1689732947000
    },
    {
        "time": 1689732948000,
        "value": 139.176,
        "_internal_originalTime": 1689732948000
    },
    {
        "time": 1689732949000,
        "value": 139.174,
        "_internal_originalTime": 1689732949000
    },
    {
        "time": 1689732950000,
        "value": 139.182,
        "_internal_originalTime": 1689732950000
    },
    {
        "time": 1689732952000,
        "value": 139.176,
        "_internal_originalTime": 1689732952000
    },
    {
        "time": 1689732954000,
        "value": 139.176,
        "_internal_originalTime": 1689732954000
    },
    {
        "time": 1689732956000,
        "value": 139.175,
        "_internal_originalTime": 1689732956000
    },
    {
        "time": 1689732957000,
        "value": 139.175,
        "_internal_originalTime": 1689732957000
    },
    {
        "time": 1689732958000,
        "value": 139.172,
        "_internal_originalTime": 1689732958000
    },
    {
        "time": 1689732960000,
        "value": 139.187,
        "_internal_originalTime": 1689732960000
    },
    {
        "time": 1689732961000,
        "value": 139.186,
        "_internal_originalTime": 1689732961000
    },
    {
        "time": 1689732962000,
        "value": 139.187,
        "_internal_originalTime": 1689732962000
    },
    {
        "time": 1689732963000,
        "value": 139.185,
        "_internal_originalTime": 1689732963000
    },
    {
        "time": 1689732966000,
        "value": 139.195,
        "_internal_originalTime": 1689732966000
    },
    {
        "time": 1689732967000,
        "value": 139.19,
        "_internal_originalTime": 1689732967000
    },
    {
        "time": 1689732968000,
        "value": 139.195,
        "_internal_originalTime": 1689732968000
    },
    {
        "time": 1689732969000,
        "value": 139.184,
        "_internal_originalTime": 1689732969000
    },
    {
        "time": 1689732971000,
        "value": 139.188,
        "_internal_originalTime": 1689732971000
    },
    {
        "time": 1689732972000,
        "value": 139.186,
        "_internal_originalTime": 1689732972000
    },
    {
        "time": 1689732976000,
        "value": 139.176,
        "_internal_originalTime": 1689732976000
    },
    {
        "time": 1689732977000,
        "value": 139.176,
        "_internal_originalTime": 1689732977000
    },
    {
        "time": 1689732980000,
        "value": 139.182,
        "_internal_originalTime": 1689732980000
    },
    {
        "time": 1689732981000,
        "value": 139.184,
        "_internal_originalTime": 1689732981000
    },
    {
        "time": 1689732982000,
        "value": 139.188,
        "_internal_originalTime": 1689732982000
    },
    {
        "time": 1689732983000,
        "value": 139.187,
        "_internal_originalTime": 1689732983000
    },
    {
        "time": 1689732984000,
        "value": 139.187,
        "_internal_originalTime": 1689732984000
    },
    {
        "time": 1689732986000,
        "value": 139.194,
        "_internal_originalTime": 1689732986000
    },
    {
        "time": 1689732987000,
        "value": 139.194,
        "_internal_originalTime": 1689732987000
    },
    {
        "time": 1689732988000,
        "value": 139.179,
        "_internal_originalTime": 1689732988000
    },
    {
        "time": 1689732990000,
        "value": 139.181,
        "_internal_originalTime": 1689732990000
    },
    {
        "time": 1689732991000,
        "value": 139.178,
        "_internal_originalTime": 1689732991000
    },
    {
        "time": 1689732994000,
        "value": 139.192,
        "_internal_originalTime": 1689732994000
    },
    {
        "time": 1689732995000,
        "value": 139.191,
        "_internal_originalTime": 1689732995000
    },
    {
        "time": 1689732996000,
        "value": 139.193,
        "_internal_originalTime": 1689732996000
    },
    {
        "time": 1689732997000,
        "value": 139.188,
        "_internal_originalTime": 1689732997000
    },
    {
        "time": 1689732998000,
        "value": 139.191,
        "_internal_originalTime": 1689732998000
    },
    {
        "time": 1689733000000,
        "value": 139.187,
        "_internal_originalTime": 1689733000000
    },
    {
        "time": 1689733003000,
        "value": 139.188,
        "_internal_originalTime": 1689733003000
    },
    {
        "time": 1689733005000,
        "value": 139.187,
        "_internal_originalTime": 1689733005000
    },
    {
        "time": 1689733006000,
        "value": 139.188,
        "_internal_originalTime": 1689733006000
    },
    {
        "time": 1689733007000,
        "value": 139.191,
        "_internal_originalTime": 1689733007000
    },
    {
        "time": 1689733009000,
        "value": 139.191,
        "_internal_originalTime": 1689733009000
    },
    {
        "time": 1689733010000,
        "value": 139.19,
        "_internal_originalTime": 1689733010000
    },
    {
        "time": 1689733011000,
        "value": 139.19,
        "_internal_originalTime": 1689733011000
    },
    {
        "time": 1689733013000,
        "value": 139.19,
        "_internal_originalTime": 1689733013000
    },
    {
        "time": 1689733015000,
        "value": 139.188,
        "_internal_originalTime": 1689733015000
    },
    {
        "time": 1689733016000,
        "value": 139.191,
        "_internal_originalTime": 1689733016000
    },
    {
        "time": 1689733017000,
        "value": 139.189,
        "_internal_originalTime": 1689733017000
    },
    {
        "time": 1689733019000,
        "value": 139.189,
        "_internal_originalTime": 1689733019000
    },
    {
        "time": 1689733020000,
        "value": 139.203,
        "_internal_originalTime": 1689733020000
    },
    {
        "time": 1689733021000,
        "value": 139.203,
        "_internal_originalTime": 1689733021000
    },
    {
        "time": 1689733023000,
        "value": 139.205,
        "_internal_originalTime": 1689733023000
    },
    {
        "time": 1689733024000,
        "value": 139.21,
        "_internal_originalTime": 1689733024000
    },
    {
        "time": 1689733025000,
        "value": 139.208,
        "_internal_originalTime": 1689733025000
    },
    {
        "time": 1689733026000,
        "value": 139.208,
        "_internal_originalTime": 1689733026000
    },
    {
        "time": 1689733028000,
        "value": 139.205,
        "_internal_originalTime": 1689733028000
    },
    {
        "time": 1689733029000,
        "value": 139.209,
        "_internal_originalTime": 1689733029000
    },
    {
        "time": 1689733030000,
        "value": 139.206,
        "_internal_originalTime": 1689733030000
    },
    {
        "time": 1689733032000,
        "value": 139.204,
        "_internal_originalTime": 1689733032000
    },
    {
        "time": 1689733034000,
        "value": 139.199,
        "_internal_originalTime": 1689733034000
    },
    {
        "time": 1689733036000,
        "value": 139.2,
        "_internal_originalTime": 1689733036000
    },
    {
        "time": 1689733038000,
        "value": 139.199,
        "_internal_originalTime": 1689733038000
    },
    {
        "time": 1689733039000,
        "value": 139.196,
        "_internal_originalTime": 1689733039000
    },
    {
        "time": 1689733040000,
        "value": 139.198,
        "_internal_originalTime": 1689733040000
    },
    {
        "time": 1689733042000,
        "value": 139.2,
        "_internal_originalTime": 1689733042000
    },
    {
        "time": 1689733044000,
        "value": 139.204,
        "_internal_originalTime": 1689733044000
    },
    {
        "time": 1689733045000,
        "value": 139.2,
        "_internal_originalTime": 1689733045000
    },
    {
        "time": 1689733047000,
        "value": 139.2,
        "_internal_originalTime": 1689733047000
    },
    {
        "time": 1689733048000,
        "value": 139.2,
        "_internal_originalTime": 1689733048000
    },
    {
        "time": 1689733049000,
        "value": 139.199,
        "_internal_originalTime": 1689733049000
    },
    {
        "time": 1689733050000,
        "value": 139.198,
        "_internal_originalTime": 1689733050000
    },
    {
        "time": 1689733053000,
        "value": 139.199,
        "_internal_originalTime": 1689733053000
    },
    {
        "time": 1689733054000,
        "value": 139.203,
        "_internal_originalTime": 1689733054000
    },
    {
        "time": 1689733055000,
        "value": 139.199,
        "_internal_originalTime": 1689733055000
    },
    {
        "time": 1689733057000,
        "value": 139.204,
        "_internal_originalTime": 1689733057000
    },
    {
        "time": 1689733059000,
        "value": 139.198,
        "_internal_originalTime": 1689733059000
    },
    {
        "time": 1689733060000,
        "value": 139.201,
        "_internal_originalTime": 1689733060000
    },
    {
        "time": 1689733061000,
        "value": 139.197,
        "_internal_originalTime": 1689733061000
    },
    {
        "time": 1689733062000,
        "value": 139.195,
        "_internal_originalTime": 1689733062000
    },
    {
        "time": 1689733064000,
        "value": 139.201,
        "_internal_originalTime": 1689733064000
    },
    {
        "time": 1689733065000,
        "value": 139.196,
        "_internal_originalTime": 1689733065000
    },
    {
        "time": 1689733068000,
        "value": 139.203,
        "_internal_originalTime": 1689733068000
    },
    {
        "time": 1689733069000,
        "value": 139.201,
        "_internal_originalTime": 1689733069000
    },
    {
        "time": 1689733070000,
        "value": 139.202,
        "_internal_originalTime": 1689733070000
    },
    {
        "time": 1689733072000,
        "value": 139.199,
        "_internal_originalTime": 1689733072000
    },
    {
        "time": 1689733073000,
        "value": 139.198,
        "_internal_originalTime": 1689733073000
    },
    {
        "time": 1689733074000,
        "value": 139.196,
        "_internal_originalTime": 1689733074000
    },
    {
        "time": 1689733075000,
        "value": 139.196,
        "_internal_originalTime": 1689733075000
    },
    {
        "time": 1689733077000,
        "value": 139.196,
        "_internal_originalTime": 1689733077000
    },
    {
        "time": 1689733079000,
        "value": 139.203,
        "_internal_originalTime": 1689733079000
    },
    {
        "time": 1689733080000,
        "value": 139.202,
        "_internal_originalTime": 1689733080000
    },
    {
        "time": 1689733081000,
        "value": 139.202,
        "_internal_originalTime": 1689733081000
    },
    {
        "time": 1689733082000,
        "value": 139.199,
        "_internal_originalTime": 1689733082000
    },
    {
        "time": 1689733083000,
        "value": 139.203,
        "_internal_originalTime": 1689733083000
    },
    {
        "time": 1689733084000,
        "value": 139.203,
        "_internal_originalTime": 1689733084000
    },
    {
        "time": 1689733085000,
        "value": 139.196,
        "_internal_originalTime": 1689733085000
    },
    {
        "time": 1689733086000,
        "value": 139.203,
        "_internal_originalTime": 1689733086000
    },
    {
        "time": 1689733088000,
        "value": 139.203,
        "_internal_originalTime": 1689733088000
    },
    {
        "time": 1689733090000,
        "value": 139.2,
        "_internal_originalTime": 1689733090000
    },
    {
        "time": 1689733092000,
        "value": 139.196,
        "_internal_originalTime": 1689733092000
    },
    {
        "time": 1689733093000,
        "value": 139.192,
        "_internal_originalTime": 1689733093000
    },
    {
        "time": 1689733094000,
        "value": 139.196,
        "_internal_originalTime": 1689733094000
    },
    {
        "time": 1689733097000,
        "value": 139.2,
        "_internal_originalTime": 1689733097000
    },
    {
        "time": 1689733098000,
        "value": 139.196,
        "_internal_originalTime": 1689733098000
    },
    {
        "time": 1689733100000,
        "value": 139.199,
        "_internal_originalTime": 1689733100000
    },
    {
        "time": 1689733101000,
        "value": 139.196,
        "_internal_originalTime": 1689733101000
    },
    {
        "time": 1689733103000,
        "value": 139.196,
        "_internal_originalTime": 1689733103000
    },
    {
        "time": 1689733105000,
        "value": 139.194,
        "_internal_originalTime": 1689733105000
    },
    {
        "time": 1689733107000,
        "value": 139.196,
        "_internal_originalTime": 1689733107000
    },
    {
        "time": 1689733108000,
        "value": 139.199,
        "_internal_originalTime": 1689733108000
    },
    {
        "time": 1689733110000,
        "value": 139.194,
        "_internal_originalTime": 1689733110000
    },
    {
        "time": 1689733111000,
        "value": 139.193,
        "_internal_originalTime": 1689733111000
    },
    {
        "time": 1689733112000,
        "value": 139.187,
        "_internal_originalTime": 1689733112000
    },
    {
        "time": 1689733113000,
        "value": 139.184,
        "_internal_originalTime": 1689733113000
    },
    {
        "time": 1689733114000,
        "value": 139.189,
        "_internal_originalTime": 1689733114000
    },
    {
        "time": 1689733116000,
        "value": 139.186,
        "_internal_originalTime": 1689733116000
    },
    {
        "time": 1689733117000,
        "value": 139.185,
        "_internal_originalTime": 1689733117000
    },
    {
        "time": 1689733119000,
        "value": 139.191,
        "_internal_originalTime": 1689733119000
    },
    {
        "time": 1689733121000,
        "value": 139.188,
        "_internal_originalTime": 1689733121000
    },
    {
        "time": 1689733122000,
        "value": 139.193,
        "_internal_originalTime": 1689733122000
    },
    {
        "time": 1689733125000,
        "value": 139.19,
        "_internal_originalTime": 1689733125000
    },
    {
        "time": 1689733126000,
        "value": 139.19,
        "_internal_originalTime": 1689733126000
    },
    {
        "time": 1689733127000,
        "value": 139.192,
        "_internal_originalTime": 1689733127000
    },
    {
        "time": 1689733128000,
        "value": 139.193,
        "_internal_originalTime": 1689733128000
    },
    {
        "time": 1689733130000,
        "value": 139.191,
        "_internal_originalTime": 1689733130000
    },
    {
        "time": 1689733134000,
        "value": 139.193,
        "_internal_originalTime": 1689733134000
    },
    {
        "time": 1689733136000,
        "value": 139.188,
        "_internal_originalTime": 1689733136000
    },
    {
        "time": 1689733137000,
        "value": 139.187,
        "_internal_originalTime": 1689733137000
    },
    {
        "time": 1689733138000,
        "value": 139.186,
        "_internal_originalTime": 1689733138000
    },
    {
        "time": 1689733140000,
        "value": 139.17,
        "_internal_originalTime": 1689733140000
    },
    {
        "time": 1689733141000,
        "value": 139.186,
        "_internal_originalTime": 1689733141000
    },
    {
        "time": 1689733142000,
        "value": 139.185,
        "_internal_originalTime": 1689733142000
    },
    {
        "time": 1689733143000,
        "value": 139.191,
        "_internal_originalTime": 1689733143000
    },
    {
        "time": 1689733145000,
        "value": 139.191,
        "_internal_originalTime": 1689733145000
    },
    {
        "time": 1689733146000,
        "value": 139.191,
        "_internal_originalTime": 1689733146000
    },
    {
        "time": 1689733148000,
        "value": 139.191,
        "_internal_originalTime": 1689733148000
    },
    {
        "time": 1689733149000,
        "value": 139.179,
        "_internal_originalTime": 1689733149000
    },
    {
        "time": 1689733150000,
        "value": 139.181,
        "_internal_originalTime": 1689733150000
    },
    {
        "time": 1689733152000,
        "value": 139.188,
        "_internal_originalTime": 1689733152000
    },
    {
        "time": 1689733153000,
        "value": 139.178,
        "_internal_originalTime": 1689733153000
    },
    {
        "time": 1689733154000,
        "value": 139.183,
        "_internal_originalTime": 1689733154000
    },
    {
        "time": 1689733155000,
        "value": 139.184,
        "_internal_originalTime": 1689733155000
    },
    {
        "time": 1689733156000,
        "value": 139.18,
        "_internal_originalTime": 1689733156000
    },
    {
        "time": 1689733158000,
        "value": 139.178,
        "_internal_originalTime": 1689733158000
    },
    {
        "time": 1689733159000,
        "value": 139.184,
        "_internal_originalTime": 1689733159000
    },
    {
        "time": 1689733160000,
        "value": 139.18,
        "_internal_originalTime": 1689733160000
    },
    {
        "time": 1689733162000,
        "value": 139.18,
        "_internal_originalTime": 1689733162000
    },
    {
        "time": 1689733163000,
        "value": 139.182,
        "_internal_originalTime": 1689733163000
    },
    {
        "time": 1689733165000,
        "value": 139.185,
        "_internal_originalTime": 1689733165000
    },
    {
        "time": 1689733166000,
        "value": 139.182,
        "_internal_originalTime": 1689733166000
    },
    {
        "time": 1689733167000,
        "value": 139.19,
        "_internal_originalTime": 1689733167000
    },
    {
        "time": 1689733169000,
        "value": 139.191,
        "_internal_originalTime": 1689733169000
    },
    {
        "time": 1689733170000,
        "value": 139.186,
        "_internal_originalTime": 1689733170000
    },
    {
        "time": 1689733171000,
        "value": 139.187,
        "_internal_originalTime": 1689733171000
    },
    {
        "time": 1689733172000,
        "value": 139.185,
        "_internal_originalTime": 1689733172000
    },
    {
        "time": 1689733174000,
        "value": 139.185,
        "_internal_originalTime": 1689733174000
    },
    {
        "time": 1689733176000,
        "value": 139.177,
        "_internal_originalTime": 1689733176000
    },
    {
        "time": 1689733177000,
        "value": 139.175,
        "_internal_originalTime": 1689733177000
    },
    {
        "time": 1689733178000,
        "value": 139.178,
        "_internal_originalTime": 1689733178000
    },
    {
        "time": 1689733179000,
        "value": 139.172,
        "_internal_originalTime": 1689733179000
    },
    {
        "time": 1689733181000,
        "value": 139.166,
        "_internal_originalTime": 1689733181000
    },
    {
        "time": 1689733182000,
        "value": 139.157,
        "_internal_originalTime": 1689733182000
    },
    {
        "time": 1689733183000,
        "value": 139.159,
        "_internal_originalTime": 1689733183000
    },
    {
        "time": 1689733184000,
        "value": 139.156,
        "_internal_originalTime": 1689733184000
    },
    {
        "time": 1689733185000,
        "value": 139.154,
        "_internal_originalTime": 1689733185000
    },
    {
        "time": 1689733186000,
        "value": 139.154,
        "_internal_originalTime": 1689733186000
    },
    {
        "time": 1689733188000,
        "value": 139.156,
        "_internal_originalTime": 1689733188000
    },
    {
        "time": 1689733189000,
        "value": 139.16,
        "_internal_originalTime": 1689733189000
    },
    {
        "time": 1689733190000,
        "value": 139.161,
        "_internal_originalTime": 1689733190000
    },
    {
        "time": 1689733191000,
        "value": 139.157,
        "_internal_originalTime": 1689733191000
    },
    {
        "time": 1689733193000,
        "value": 139.156,
        "_internal_originalTime": 1689733193000
    },
    {
        "time": 1689733194000,
        "value": 139.166,
        "_internal_originalTime": 1689733194000
    },
    {
        "time": 1689733196000,
        "value": 139.171,
        "_internal_originalTime": 1689733196000
    },
    {
        "time": 1689733197000,
        "value": 139.162,
        "_internal_originalTime": 1689733197000
    },
    {
        "time": 1689733198000,
        "value": 139.164,
        "_internal_originalTime": 1689733198000
    },
    {
        "time": 1689733199000,
        "value": 139.168,
        "_internal_originalTime": 1689733199000
    },
    {
        "time": 1689733200000,
        "value": 139.201,
        "_internal_originalTime": 1689733200000
    },
    {
        "time": 1689733201000,
        "value": 139.195,
        "_internal_originalTime": 1689733201000
    },
    {
        "time": 1689733202000,
        "value": 139.194,
        "_internal_originalTime": 1689733202000
    },
    {
        "time": 1689733204000,
        "value": 139.187,
        "_internal_originalTime": 1689733204000
    },
    {
        "time": 1689733205000,
        "value": 139.187,
        "_internal_originalTime": 1689733205000
    },
    {
        "time": 1689733206000,
        "value": 139.195,
        "_internal_originalTime": 1689733206000
    },
    {
        "time": 1689733207000,
        "value": 139.188,
        "_internal_originalTime": 1689733207000
    },
    {
        "time": 1689733208000,
        "value": 139.189,
        "_internal_originalTime": 1689733208000
    },
    {
        "time": 1689733209000,
        "value": 139.187,
        "_internal_originalTime": 1689733209000
    },
    {
        "time": 1689733210000,
        "value": 139.188,
        "_internal_originalTime": 1689733210000
    },
    {
        "time": 1689733211000,
        "value": 139.189,
        "_internal_originalTime": 1689733211000
    },
    {
        "time": 1689733213000,
        "value": 139.186,
        "_internal_originalTime": 1689733213000
    },
    {
        "time": 1689733214000,
        "value": 139.184,
        "_internal_originalTime": 1689733214000
    },
    {
        "time": 1689733217000,
        "value": 139.187,
        "_internal_originalTime": 1689733217000
    },
    {
        "time": 1689733219000,
        "value": 139.185,
        "_internal_originalTime": 1689733219000
    },
    {
        "time": 1689733220000,
        "value": 139.19,
        "_internal_originalTime": 1689733220000
    },
    {
        "time": 1689733222000,
        "value": 139.196,
        "_internal_originalTime": 1689733222000
    },
    {
        "time": 1689733224000,
        "value": 139.199,
        "_internal_originalTime": 1689733224000
    },
    {
        "time": 1689733225000,
        "value": 139.196,
        "_internal_originalTime": 1689733225000
    },
    {
        "time": 1689733226000,
        "value": 139.197,
        "_internal_originalTime": 1689733226000
    },
    {
        "time": 1689733228000,
        "value": 139.195,
        "_internal_originalTime": 1689733228000
    },
    {
        "time": 1689733230000,
        "value": 139.186,
        "_internal_originalTime": 1689733230000
    },
    {
        "time": 1689733231000,
        "value": 139.192,
        "_internal_originalTime": 1689733231000
    },
    {
        "time": 1689733232000,
        "value": 139.188,
        "_internal_originalTime": 1689733232000
    },
    {
        "time": 1689733234000,
        "value": 139.192,
        "_internal_originalTime": 1689733234000
    },
    {
        "time": 1689733236000,
        "value": 139.194,
        "_internal_originalTime": 1689733236000
    },
    {
        "time": 1689733238000,
        "value": 139.193,
        "_internal_originalTime": 1689733238000
    },
    {
        "time": 1689733239000,
        "value": 139.188,
        "_internal_originalTime": 1689733239000
    },
    {
        "time": 1689733245000,
        "value": 139.19,
        "_internal_originalTime": 1689733245000
    },
    {
        "time": 1689733246000,
        "value": 139.193,
        "_internal_originalTime": 1689733246000
    },
    {
        "time": 1689733250000,
        "value": 139.19,
        "_internal_originalTime": 1689733250000
    },
    {
        "time": 1689733251000,
        "value": 139.193,
        "_internal_originalTime": 1689733251000
    },
    {
        "time": 1689733252000,
        "value": 139.194,
        "_internal_originalTime": 1689733252000
    },
    {
        "time": 1689733254000,
        "value": 139.187,
        "_internal_originalTime": 1689733254000
    },
    {
        "time": 1689733255000,
        "value": 139.196,
        "_internal_originalTime": 1689733255000
    },
    {
        "time": 1689733256000,
        "value": 139.191,
        "_internal_originalTime": 1689733256000
    },
    {
        "time": 1689733257000,
        "value": 139.195,
        "_internal_originalTime": 1689733257000
    },
    {
        "time": 1689733259000,
        "value": 139.194,
        "_internal_originalTime": 1689733259000
    },
    {
        "time": 1689733260000,
        "value": 139.17,
        "_internal_originalTime": 1689733260000
    },
    {
        "time": 1689733262000,
        "value": 139.183,
        "_internal_originalTime": 1689733262000
    },
    {
        "time": 1689733265000,
        "value": 139.19,
        "_internal_originalTime": 1689733265000
    },
    {
        "time": 1689733267000,
        "value": 139.193,
        "_internal_originalTime": 1689733267000
    },
    {
        "time": 1689733269000,
        "value": 139.2,
        "_internal_originalTime": 1689733269000
    },
    {
        "time": 1689733270000,
        "value": 139.201,
        "_internal_originalTime": 1689733270000
    },
    {
        "time": 1689733275000,
        "value": 139.203,
        "_internal_originalTime": 1689733275000
    },
    {
        "time": 1689733276000,
        "value": 139.201,
        "_internal_originalTime": 1689733276000
    },
    {
        "time": 1689733280000,
        "value": 139.2,
        "_internal_originalTime": 1689733280000
    },
    {
        "time": 1689733282000,
        "value": 139.201,
        "_internal_originalTime": 1689733282000
    },
    {
        "time": 1689733283000,
        "value": 139.202,
        "_internal_originalTime": 1689733283000
    },
    {
        "time": 1689733285000,
        "value": 139.206,
        "_internal_originalTime": 1689733285000
    },
    {
        "time": 1689733286000,
        "value": 139.205,
        "_internal_originalTime": 1689733286000
    },
    {
        "time": 1689733287000,
        "value": 139.21,
        "_internal_originalTime": 1689733287000
    },
    {
        "time": 1689733288000,
        "value": 139.205,
        "_internal_originalTime": 1689733288000
    },
    {
        "time": 1689733289000,
        "value": 139.206,
        "_internal_originalTime": 1689733289000
    },
    {
        "time": 1689733290000,
        "value": 139.207,
        "_internal_originalTime": 1689733290000
    },
    {
        "time": 1689733292000,
        "value": 139.207,
        "_internal_originalTime": 1689733292000
    },
    {
        "time": 1689733293000,
        "value": 139.2,
        "_internal_originalTime": 1689733293000
    },
    {
        "time": 1689733295000,
        "value": 139.21,
        "_internal_originalTime": 1689733295000
    },
    {
        "time": 1689733296000,
        "value": 139.211,
        "_internal_originalTime": 1689733296000
    },
    {
        "time": 1689733297000,
        "value": 139.211,
        "_internal_originalTime": 1689733297000
    },
    {
        "time": 1689733298000,
        "value": 139.207,
        "_internal_originalTime": 1689733298000
    },
    {
        "time": 1689733299000,
        "value": 139.211,
        "_internal_originalTime": 1689733299000
    },
    {
        "time": 1689733300000,
        "value": 139.21,
        "_internal_originalTime": 1689733300000
    },
    {
        "time": 1689733301000,
        "value": 139.207,
        "_internal_originalTime": 1689733301000
    },
    {
        "time": 1689733303000,
        "value": 139.205,
        "_internal_originalTime": 1689733303000
    },
    {
        "time": 1689733304000,
        "value": 139.207,
        "_internal_originalTime": 1689733304000
    },
    {
        "time": 1689733306000,
        "value": 139.208,
        "_internal_originalTime": 1689733306000
    },
    {
        "time": 1689733308000,
        "value": 139.219,
        "_internal_originalTime": 1689733308000
    },
    {
        "time": 1689733309000,
        "value": 139.218,
        "_internal_originalTime": 1689733309000
    },
    {
        "time": 1689733311000,
        "value": 139.22,
        "_internal_originalTime": 1689733311000
    },
    {
        "time": 1689733312000,
        "value": 139.227,
        "_internal_originalTime": 1689733312000
    },
    {
        "time": 1689733313000,
        "value": 139.228,
        "_internal_originalTime": 1689733313000
    },
    {
        "time": 1689733314000,
        "value": 139.221,
        "_internal_originalTime": 1689733314000
    },
    {
        "time": 1689733316000,
        "value": 139.22,
        "_internal_originalTime": 1689733316000
    },
    {
        "time": 1689733317000,
        "value": 139.221,
        "_internal_originalTime": 1689733317000
    },
    {
        "time": 1689733319000,
        "value": 139.219,
        "_internal_originalTime": 1689733319000
    },
    {
        "time": 1689733320000,
        "value": 139.216,
        "_internal_originalTime": 1689733320000
    },
    {
        "time": 1689733321000,
        "value": 139.219,
        "_internal_originalTime": 1689733321000
    },
    {
        "time": 1689733322000,
        "value": 139.224,
        "_internal_originalTime": 1689733322000
    },
    {
        "time": 1689733323000,
        "value": 139.23,
        "_internal_originalTime": 1689733323000
    },
    {
        "time": 1689733324000,
        "value": 139.227,
        "_internal_originalTime": 1689733324000
    },
    {
        "time": 1689733325000,
        "value": 139.228,
        "_internal_originalTime": 1689733325000
    },
    {
        "time": 1689733326000,
        "value": 139.228,
        "_internal_originalTime": 1689733326000
    },
    {
        "time": 1689733328000,
        "value": 139.23,
        "_internal_originalTime": 1689733328000
    },
    {
        "time": 1689733329000,
        "value": 139.228,
        "_internal_originalTime": 1689733329000
    },
    {
        "time": 1689733331000,
        "value": 139.221,
        "_internal_originalTime": 1689733331000
    },
    {
        "time": 1689733333000,
        "value": 139.22,
        "_internal_originalTime": 1689733333000
    },
    {
        "time": 1689733334000,
        "value": 139.226,
        "_internal_originalTime": 1689733334000
    },
    {
        "time": 1689733335000,
        "value": 139.228,
        "_internal_originalTime": 1689733335000
    },
    {
        "time": 1689733336000,
        "value": 139.218,
        "_internal_originalTime": 1689733336000
    },
    {
        "time": 1689733337000,
        "value": 139.218,
        "_internal_originalTime": 1689733337000
    },
    {
        "time": 1689733338000,
        "value": 139.219,
        "_internal_originalTime": 1689733338000
    },
    {
        "time": 1689733340000,
        "value": 139.219,
        "_internal_originalTime": 1689733340000
    },
    {
        "time": 1689733343000,
        "value": 139.216,
        "_internal_originalTime": 1689733343000
    },
    {
        "time": 1689733344000,
        "value": 139.221,
        "_internal_originalTime": 1689733344000
    },
    {
        "time": 1689733345000,
        "value": 139.22,
        "_internal_originalTime": 1689733345000
    },
    {
        "time": 1689733347000,
        "value": 139.224,
        "_internal_originalTime": 1689733347000
    },
    {
        "time": 1689733348000,
        "value": 139.228,
        "_internal_originalTime": 1689733348000
    },
    {
        "time": 1689733349000,
        "value": 139.228,
        "_internal_originalTime": 1689733349000
    },
    {
        "time": 1689733350000,
        "value": 139.227,
        "_internal_originalTime": 1689733350000
    },
    {
        "time": 1689733351000,
        "value": 139.229,
        "_internal_originalTime": 1689733351000
    },
    {
        "time": 1689733353000,
        "value": 139.228,
        "_internal_originalTime": 1689733353000
    },
    {
        "time": 1689733356000,
        "value": 139.226,
        "_internal_originalTime": 1689733356000
    },
    {
        "time": 1689733357000,
        "value": 139.226,
        "_internal_originalTime": 1689733357000
    },
    {
        "time": 1689733358000,
        "value": 139.23,
        "_internal_originalTime": 1689733358000
    },
    {
        "time": 1689733359000,
        "value": 139.231,
        "_internal_originalTime": 1689733359000
    },
    {
        "time": 1689733360000,
        "value": 139.229,
        "_internal_originalTime": 1689733360000
    },
    {
        "time": 1689733362000,
        "value": 139.228,
        "_internal_originalTime": 1689733362000
    },
    {
        "time": 1689733363000,
        "value": 139.226,
        "_internal_originalTime": 1689733363000
    },
    {
        "time": 1689733364000,
        "value": 139.221,
        "_internal_originalTime": 1689733364000
    },
    {
        "time": 1689733365000,
        "value": 139.217,
        "_internal_originalTime": 1689733365000
    },
    {
        "time": 1689733367000,
        "value": 139.221,
        "_internal_originalTime": 1689733367000
    },
    {
        "time": 1689733369000,
        "value": 139.222,
        "_internal_originalTime": 1689733369000
    },
    {
        "time": 1689733371000,
        "value": 139.226,
        "_internal_originalTime": 1689733371000
    },
    {
        "time": 1689733372000,
        "value": 139.222,
        "_internal_originalTime": 1689733372000
    },
    {
        "time": 1689733373000,
        "value": 139.229,
        "_internal_originalTime": 1689733373000
    },
    {
        "time": 1689733375000,
        "value": 139.224,
        "_internal_originalTime": 1689733375000
    },
    {
        "time": 1689733376000,
        "value": 139.226,
        "_internal_originalTime": 1689733376000
    },
    {
        "time": 1689733377000,
        "value": 139.232,
        "_internal_originalTime": 1689733377000
    },
    {
        "time": 1689733378000,
        "value": 139.232,
        "_internal_originalTime": 1689733378000
    },
    {
        "time": 1689733380000,
        "value": 139.237,
        "_internal_originalTime": 1689733380000
    },
    {
        "time": 1689733382000,
        "value": 139.238,
        "_internal_originalTime": 1689733382000
    },
    {
        "time": 1689733383000,
        "value": 139.237,
        "_internal_originalTime": 1689733383000
    },
    {
        "time": 1689733384000,
        "value": 139.247,
        "_internal_originalTime": 1689733384000
    },
    {
        "time": 1689733385000,
        "value": 139.247,
        "_internal_originalTime": 1689733385000
    },
    {
        "time": 1689733386000,
        "value": 139.242,
        "_internal_originalTime": 1689733386000
    },
    {
        "time": 1689733388000,
        "value": 139.244,
        "_internal_originalTime": 1689733388000
    },
    {
        "time": 1689733390000,
        "value": 139.248,
        "_internal_originalTime": 1689733390000
    },
    {
        "time": 1689733392000,
        "value": 139.249,
        "_internal_originalTime": 1689733392000
    },
    {
        "time": 1689733393000,
        "value": 139.249,
        "_internal_originalTime": 1689733393000
    },
    {
        "time": 1689733395000,
        "value": 139.245,
        "_internal_originalTime": 1689733395000
    },
    {
        "time": 1689733396000,
        "value": 139.244,
        "_internal_originalTime": 1689733396000
    },
    {
        "time": 1689733397000,
        "value": 139.249,
        "_internal_originalTime": 1689733397000
    },
    {
        "time": 1689733398000,
        "value": 139.247,
        "_internal_originalTime": 1689733398000
    },
    {
        "time": 1689733399000,
        "value": 139.248,
        "_internal_originalTime": 1689733399000
    },
    {
        "time": 1689733401000,
        "value": 139.246,
        "_internal_originalTime": 1689733401000
    },
    {
        "time": 1689733402000,
        "value": 139.255,
        "_internal_originalTime": 1689733402000
    },
    {
        "time": 1689733403000,
        "value": 139.254,
        "_internal_originalTime": 1689733403000
    },
    {
        "time": 1689733404000,
        "value": 139.25,
        "_internal_originalTime": 1689733404000
    },
    {
        "time": 1689733405000,
        "value": 139.252,
        "_internal_originalTime": 1689733405000
    },
    {
        "time": 1689733407000,
        "value": 139.257,
        "_internal_originalTime": 1689733407000
    },
    {
        "time": 1689733408000,
        "value": 139.257,
        "_internal_originalTime": 1689733408000
    },
    {
        "time": 1689733409000,
        "value": 139.26,
        "_internal_originalTime": 1689733409000
    },
    {
        "time": 1689733410000,
        "value": 139.253,
        "_internal_originalTime": 1689733410000
    },
    {
        "time": 1689733412000,
        "value": 139.26,
        "_internal_originalTime": 1689733412000
    },
    {
        "time": 1689733414000,
        "value": 139.253,
        "_internal_originalTime": 1689733414000
    },
    {
        "time": 1689733415000,
        "value": 139.258,
        "_internal_originalTime": 1689733415000
    },
    {
        "time": 1689733416000,
        "value": 139.259,
        "_internal_originalTime": 1689733416000
    },
    {
        "time": 1689733418000,
        "value": 139.253,
        "_internal_originalTime": 1689733418000
    },
    {
        "time": 1689733419000,
        "value": 139.25,
        "_internal_originalTime": 1689733419000
    },
    {
        "time": 1689733420000,
        "value": 139.254,
        "_internal_originalTime": 1689733420000
    },
    {
        "time": 1689733421000,
        "value": 139.256,
        "_internal_originalTime": 1689733421000
    },
    {
        "time": 1689733423000,
        "value": 139.252,
        "_internal_originalTime": 1689733423000
    },
    {
        "time": 1689733424000,
        "value": 139.258,
        "_internal_originalTime": 1689733424000
    },
    {
        "time": 1689733425000,
        "value": 139.255,
        "_internal_originalTime": 1689733425000
    },
    {
        "time": 1689733426000,
        "value": 139.254,
        "_internal_originalTime": 1689733426000
    },
    {
        "time": 1689733427000,
        "value": 139.254,
        "_internal_originalTime": 1689733427000
    },
    {
        "time": 1689733429000,
        "value": 139.26,
        "_internal_originalTime": 1689733429000
    },
    {
        "time": 1689733430000,
        "value": 139.261,
        "_internal_originalTime": 1689733430000
    },
    {
        "time": 1689733431000,
        "value": 139.259,
        "_internal_originalTime": 1689733431000
    },
    {
        "time": 1689733432000,
        "value": 139.25,
        "_internal_originalTime": 1689733432000
    },
    {
        "time": 1689733434000,
        "value": 139.252,
        "_internal_originalTime": 1689733434000
    },
    {
        "time": 1689733435000,
        "value": 139.259,
        "_internal_originalTime": 1689733435000
    },
    {
        "time": 1689733436000,
        "value": 139.256,
        "_internal_originalTime": 1689733436000
    },
    {
        "time": 1689733438000,
        "value": 139.261,
        "_internal_originalTime": 1689733438000
    },
    {
        "time": 1689733439000,
        "value": 139.25,
        "_internal_originalTime": 1689733439000
    },
    {
        "time": 1689733440000,
        "value": 139.255,
        "_internal_originalTime": 1689733440000
    },
    {
        "time": 1689733441000,
        "value": 139.259,
        "_internal_originalTime": 1689733441000
    },
    {
        "time": 1689733442000,
        "value": 139.256,
        "_internal_originalTime": 1689733442000
    },
    {
        "time": 1689733443000,
        "value": 139.26,
        "_internal_originalTime": 1689733443000
    },
    {
        "time": 1689733444000,
        "value": 139.26,
        "_internal_originalTime": 1689733444000
    },
    {
        "time": 1689733445000,
        "value": 139.261,
        "_internal_originalTime": 1689733445000
    },
    {
        "time": 1689733447000,
        "value": 139.261,
        "_internal_originalTime": 1689733447000
    },
    {
        "time": 1689733449000,
        "value": 139.261,
        "_internal_originalTime": 1689733449000
    },
    {
        "time": 1689733451000,
        "value": 139.255,
        "_internal_originalTime": 1689733451000
    },
    {
        "time": 1689733453000,
        "value": 139.254,
        "_internal_originalTime": 1689733453000
    },
    {
        "time": 1689733454000,
        "value": 139.255,
        "_internal_originalTime": 1689733454000
    },
    {
        "time": 1689733455000,
        "value": 139.258,
        "_internal_originalTime": 1689733455000
    },
    {
        "time": 1689733456000,
        "value": 139.255,
        "_internal_originalTime": 1689733456000
    },
    {
        "time": 1689733457000,
        "value": 139.258,
        "_internal_originalTime": 1689733457000
    },
    {
        "time": 1689733459000,
        "value": 139.257,
        "_internal_originalTime": 1689733459000
    },
    {
        "time": 1689733460000,
        "value": 139.254,
        "_internal_originalTime": 1689733460000
    },
    {
        "time": 1689733461000,
        "value": 139.254,
        "_internal_originalTime": 1689733461000
    },
    {
        "time": 1689733462000,
        "value": 139.257,
        "_internal_originalTime": 1689733462000
    },
    {
        "time": 1689733463000,
        "value": 139.257,
        "_internal_originalTime": 1689733463000
    },
    {
        "time": 1689733465000,
        "value": 139.257,
        "_internal_originalTime": 1689733465000
    },
    {
        "time": 1689733466000,
        "value": 139.247,
        "_internal_originalTime": 1689733466000
    },
    {
        "time": 1689733467000,
        "value": 139.249,
        "_internal_originalTime": 1689733467000
    },
    {
        "time": 1689733468000,
        "value": 139.245,
        "_internal_originalTime": 1689733468000
    },
    {
        "time": 1689733472000,
        "value": 139.249,
        "_internal_originalTime": 1689733472000
    },
    {
        "time": 1689733476000,
        "value": 139.246,
        "_internal_originalTime": 1689733476000
    },
    {
        "time": 1689733477000,
        "value": 139.248,
        "_internal_originalTime": 1689733477000
    },
    {
        "time": 1689733478000,
        "value": 139.249,
        "_internal_originalTime": 1689733478000
    },
    {
        "time": 1689733479000,
        "value": 139.246,
        "_internal_originalTime": 1689733479000
    },
    {
        "time": 1689733481000,
        "value": 139.249,
        "_internal_originalTime": 1689733481000
    },
    {
        "time": 1689733483000,
        "value": 139.249,
        "_internal_originalTime": 1689733483000
    },
    {
        "time": 1689733484000,
        "value": 139.244,
        "_internal_originalTime": 1689733484000
    },
    {
        "time": 1689733485000,
        "value": 139.243,
        "_internal_originalTime": 1689733485000
    },
    {
        "time": 1689733486000,
        "value": 139.244,
        "_internal_originalTime": 1689733486000
    },
    {
        "time": 1689733487000,
        "value": 139.242,
        "_internal_originalTime": 1689733487000
    },
    {
        "time": 1689733489000,
        "value": 139.241,
        "_internal_originalTime": 1689733489000
    },
    {
        "time": 1689733490000,
        "value": 139.246,
        "_internal_originalTime": 1689733490000
    },
    {
        "time": 1689733491000,
        "value": 139.25,
        "_internal_originalTime": 1689733491000
    },
    {
        "time": 1689733492000,
        "value": 139.25,
        "_internal_originalTime": 1689733492000
    },
    {
        "time": 1689733494000,
        "value": 139.246,
        "_internal_originalTime": 1689733494000
    },
    {
        "time": 1689733495000,
        "value": 139.257,
        "_internal_originalTime": 1689733495000
    },
    {
        "time": 1689733496000,
        "value": 139.255,
        "_internal_originalTime": 1689733496000
    },
    {
        "time": 1689733498000,
        "value": 139.256,
        "_internal_originalTime": 1689733498000
    },
    {
        "time": 1689733499000,
        "value": 139.253,
        "_internal_originalTime": 1689733499000
    },
    {
        "time": 1689733500000,
        "value": 139.257,
        "_internal_originalTime": 1689733500000
    },
    {
        "time": 1689733501000,
        "value": 139.257,
        "_internal_originalTime": 1689733501000
    },
    {
        "time": 1689733503000,
        "value": 139.253,
        "_internal_originalTime": 1689733503000
    },
    {
        "time": 1689733504000,
        "value": 139.256,
        "_internal_originalTime": 1689733504000
    },
    {
        "time": 1689733505000,
        "value": 139.258,
        "_internal_originalTime": 1689733505000
    },
    {
        "time": 1689733506000,
        "value": 139.254,
        "_internal_originalTime": 1689733506000
    },
    {
        "time": 1689733508000,
        "value": 139.253,
        "_internal_originalTime": 1689733508000
    },
    {
        "time": 1689733510000,
        "value": 139.254,
        "_internal_originalTime": 1689733510000
    },
    {
        "time": 1689733511000,
        "value": 139.248,
        "_internal_originalTime": 1689733511000
    },
    {
        "time": 1689733513000,
        "value": 139.248,
        "_internal_originalTime": 1689733513000
    },
    {
        "time": 1689733514000,
        "value": 139.253,
        "_internal_originalTime": 1689733514000
    },
    {
        "time": 1689733515000,
        "value": 139.252,
        "_internal_originalTime": 1689733515000
    },
    {
        "time": 1689733516000,
        "value": 139.252,
        "_internal_originalTime": 1689733516000
    },
    {
        "time": 1689733517000,
        "value": 139.254,
        "_internal_originalTime": 1689733517000
    },
    {
        "time": 1689733518000,
        "value": 139.259,
        "_internal_originalTime": 1689733518000
    },
    {
        "time": 1689733519000,
        "value": 139.258,
        "_internal_originalTime": 1689733519000
    },
    {
        "time": 1689733521000,
        "value": 139.254,
        "_internal_originalTime": 1689733521000
    },
    {
        "time": 1689733523000,
        "value": 139.253,
        "_internal_originalTime": 1689733523000
    },
    {
        "time": 1689733524000,
        "value": 139.259,
        "_internal_originalTime": 1689733524000
    },
    {
        "time": 1689733525000,
        "value": 139.25,
        "_internal_originalTime": 1689733525000
    },
    {
        "time": 1689733526000,
        "value": 139.247,
        "_internal_originalTime": 1689733526000
    },
    {
        "time": 1689733527000,
        "value": 139.248,
        "_internal_originalTime": 1689733527000
    },
    {
        "time": 1689733528000,
        "value": 139.243,
        "_internal_originalTime": 1689733528000
    },
    {
        "time": 1689733529000,
        "value": 139.246,
        "_internal_originalTime": 1689733529000
    },
    {
        "time": 1689733530000,
        "value": 139.241,
        "_internal_originalTime": 1689733530000
    },
    {
        "time": 1689733531000,
        "value": 139.24,
        "_internal_originalTime": 1689733531000
    },
    {
        "time": 1689733532000,
        "value": 139.244,
        "_internal_originalTime": 1689733532000
    },
    {
        "time": 1689733534000,
        "value": 139.247,
        "_internal_originalTime": 1689733534000
    },
    {
        "time": 1689733537000,
        "value": 139.236,
        "_internal_originalTime": 1689733537000
    },
    {
        "time": 1689733539000,
        "value": 139.237,
        "_internal_originalTime": 1689733539000
    },
    {
        "time": 1689733540000,
        "value": 139.241,
        "_internal_originalTime": 1689733540000
    },
    {
        "time": 1689733541000,
        "value": 139.24,
        "_internal_originalTime": 1689733541000
    },
    {
        "time": 1689733543000,
        "value": 139.238,
        "_internal_originalTime": 1689733543000
    },
    {
        "time": 1689733544000,
        "value": 139.245,
        "_internal_originalTime": 1689733544000
    },
    {
        "time": 1689733546000,
        "value": 139.245,
        "_internal_originalTime": 1689733546000
    },
    {
        "time": 1689733547000,
        "value": 139.252,
        "_internal_originalTime": 1689733547000
    },
    {
        "time": 1689733549000,
        "value": 139.246,
        "_internal_originalTime": 1689733549000
    },
    {
        "time": 1689733551000,
        "value": 139.247,
        "_internal_originalTime": 1689733551000
    },
    {
        "time": 1689733552000,
        "value": 139.25,
        "_internal_originalTime": 1689733552000
    },
    {
        "time": 1689733554000,
        "value": 139.248,
        "_internal_originalTime": 1689733554000
    },
    {
        "time": 1689733555000,
        "value": 139.244,
        "_internal_originalTime": 1689733555000
    },
    {
        "time": 1689733556000,
        "value": 139.244,
        "_internal_originalTime": 1689733556000
    },
    {
        "time": 1689733558000,
        "value": 139.246,
        "_internal_originalTime": 1689733558000
    },
    {
        "time": 1689733560000,
        "value": 139.247,
        "_internal_originalTime": 1689733560000
    },
    {
        "time": 1689733561000,
        "value": 139.257,
        "_internal_originalTime": 1689733561000
    },
    {
        "time": 1689733562000,
        "value": 139.264,
        "_internal_originalTime": 1689733562000
    },
    {
        "time": 1689733565000,
        "value": 139.267,
        "_internal_originalTime": 1689733565000
    },
    {
        "time": 1689733566000,
        "value": 139.264,
        "_internal_originalTime": 1689733566000
    },
    {
        "time": 1689733568000,
        "value": 139.278,
        "_internal_originalTime": 1689733568000
    },
    {
        "time": 1689733569000,
        "value": 139.281,
        "_internal_originalTime": 1689733569000
    },
    {
        "time": 1689733571000,
        "value": 139.276,
        "_internal_originalTime": 1689733571000
    },
    {
        "time": 1689733572000,
        "value": 139.275,
        "_internal_originalTime": 1689733572000
    },
    {
        "time": 1689733573000,
        "value": 139.274,
        "_internal_originalTime": 1689733573000
    },
    {
        "time": 1689733575000,
        "value": 139.275,
        "_internal_originalTime": 1689733575000
    },
    {
        "time": 1689733576000,
        "value": 139.276,
        "_internal_originalTime": 1689733576000
    },
    {
        "time": 1689733578000,
        "value": 139.274,
        "_internal_originalTime": 1689733578000
    },
    {
        "time": 1689733579000,
        "value": 139.271,
        "_internal_originalTime": 1689733579000
    },
    {
        "time": 1689733580000,
        "value": 139.276,
        "_internal_originalTime": 1689733580000
    },
    {
        "time": 1689733581000,
        "value": 139.276,
        "_internal_originalTime": 1689733581000
    },
    {
        "time": 1689733582000,
        "value": 139.286,
        "_internal_originalTime": 1689733582000
    },
    {
        "time": 1689733583000,
        "value": 139.285,
        "_internal_originalTime": 1689733583000
    },
    {
        "time": 1689733584000,
        "value": 139.288,
        "_internal_originalTime": 1689733584000
    },
    {
        "time": 1689733586000,
        "value": 139.287,
        "_internal_originalTime": 1689733586000
    },
    {
        "time": 1689733587000,
        "value": 139.286,
        "_internal_originalTime": 1689733587000
    },
    {
        "time": 1689733588000,
        "value": 139.286,
        "_internal_originalTime": 1689733588000
    },
    {
        "time": 1689733589000,
        "value": 139.29,
        "_internal_originalTime": 1689733589000
    },
    {
        "time": 1689733590000,
        "value": 139.284,
        "_internal_originalTime": 1689733590000
    },
    {
        "time": 1689733592000,
        "value": 139.284,
        "_internal_originalTime": 1689733592000
    },
    {
        "time": 1689733593000,
        "value": 139.288,
        "_internal_originalTime": 1689733593000
    },
    {
        "time": 1689733598000,
        "value": 139.287,
        "_internal_originalTime": 1689733598000
    },
    {
        "time": 1689733610000,
        "value": 139.289,
        "_internal_originalTime": 1689733610000
    },
    {
        "time": 1689733612000,
        "value": 139.286,
        "_internal_originalTime": 1689733612000
    },
    {
        "time": 1689733613000,
        "value": 139.287,
        "_internal_originalTime": 1689733613000
    },
    {
        "time": 1689733614000,
        "value": 139.284,
        "_internal_originalTime": 1689733614000
    },
    {
        "time": 1689733616000,
        "value": 139.284,
        "_internal_originalTime": 1689733616000
    },
    {
        "time": 1689733620000,
        "value": 139.26,
        "_internal_originalTime": 1689733620000
    },
    {
        "time": 1689733622000,
        "value": 139.287,
        "_internal_originalTime": 1689733622000
    },
    {
        "time": 1689733623000,
        "value": 139.288,
        "_internal_originalTime": 1689733623000
    },
    {
        "time": 1689733625000,
        "value": 139.286,
        "_internal_originalTime": 1689733625000
    },
    {
        "time": 1689733626000,
        "value": 139.288,
        "_internal_originalTime": 1689733626000
    },
    {
        "time": 1689733627000,
        "value": 139.282,
        "_internal_originalTime": 1689733627000
    },
    {
        "time": 1689733629000,
        "value": 139.285,
        "_internal_originalTime": 1689733629000
    },
    {
        "time": 1689733630000,
        "value": 139.286,
        "_internal_originalTime": 1689733630000
    },
    {
        "time": 1689733631000,
        "value": 139.289,
        "_internal_originalTime": 1689733631000
    },
    {
        "time": 1689733633000,
        "value": 139.284,
        "_internal_originalTime": 1689733633000
    },
    {
        "time": 1689733634000,
        "value": 139.286,
        "_internal_originalTime": 1689733634000
    },
    {
        "time": 1689733635000,
        "value": 139.282,
        "_internal_originalTime": 1689733635000
    },
    {
        "time": 1689733636000,
        "value": 139.288,
        "_internal_originalTime": 1689733636000
    },
    {
        "time": 1689733638000,
        "value": 139.284,
        "_internal_originalTime": 1689733638000
    },
    {
        "time": 1689733639000,
        "value": 139.29,
        "_internal_originalTime": 1689733639000
    },
    {
        "time": 1689733640000,
        "value": 139.286,
        "_internal_originalTime": 1689733640000
    },
    {
        "time": 1689733641000,
        "value": 139.289,
        "_internal_originalTime": 1689733641000
    },
    {
        "time": 1689733642000,
        "value": 139.288,
        "_internal_originalTime": 1689733642000
    },
    {
        "time": 1689733644000,
        "value": 139.287,
        "_internal_originalTime": 1689733644000
    },
    {
        "time": 1689733645000,
        "value": 139.285,
        "_internal_originalTime": 1689733645000
    },
    {
        "time": 1689733646000,
        "value": 139.286,
        "_internal_originalTime": 1689733646000
    },
    {
        "time": 1689733648000,
        "value": 139.286,
        "_internal_originalTime": 1689733648000
    },
    {
        "time": 1689733649000,
        "value": 139.281,
        "_internal_originalTime": 1689733649000
    },
    {
        "time": 1689733650000,
        "value": 139.283,
        "_internal_originalTime": 1689733650000
    },
    {
        "time": 1689733651000,
        "value": 139.284,
        "_internal_originalTime": 1689733651000
    },
    {
        "time": 1689733652000,
        "value": 139.284,
        "_internal_originalTime": 1689733652000
    },
    {
        "time": 1689733654000,
        "value": 139.282,
        "_internal_originalTime": 1689733654000
    },
    {
        "time": 1689733657000,
        "value": 139.29,
        "_internal_originalTime": 1689733657000
    },
    {
        "time": 1689733663000,
        "value": 139.283,
        "_internal_originalTime": 1689733663000
    },
    {
        "time": 1689733664000,
        "value": 139.288,
        "_internal_originalTime": 1689733664000
    },
    {
        "time": 1689733666000,
        "value": 139.289,
        "_internal_originalTime": 1689733666000
    },
    {
        "time": 1689733669000,
        "value": 139.282,
        "_internal_originalTime": 1689733669000
    },
    {
        "time": 1689733671000,
        "value": 139.288,
        "_internal_originalTime": 1689733671000
    },
    {
        "time": 1689733672000,
        "value": 139.288,
        "_internal_originalTime": 1689733672000
    },
    {
        "time": 1689733673000,
        "value": 139.283,
        "_internal_originalTime": 1689733673000
    },
    {
        "time": 1689733674000,
        "value": 139.283,
        "_internal_originalTime": 1689733674000
    },
    {
        "time": 1689733675000,
        "value": 139.282,
        "_internal_originalTime": 1689733675000
    },
    {
        "time": 1689733678000,
        "value": 139.288,
        "_internal_originalTime": 1689733678000
    },
    {
        "time": 1689733680000,
        "value": 139.287,
        "_internal_originalTime": 1689733680000
    },
    {
        "time": 1689733681000,
        "value": 139.27,
        "_internal_originalTime": 1689733681000
    },
    {
        "time": 1689733685000,
        "value": 139.284,
        "_internal_originalTime": 1689733685000
    },
    {
        "time": 1689733692000,
        "value": 139.295,
        "_internal_originalTime": 1689733692000
    },
    {
        "time": 1689733693000,
        "value": 139.29,
        "_internal_originalTime": 1689733693000
    },
    {
        "time": 1689733695000,
        "value": 139.294,
        "_internal_originalTime": 1689733695000
    },
    {
        "time": 1689733696000,
        "value": 139.295,
        "_internal_originalTime": 1689733696000
    },
    {
        "time": 1689733698000,
        "value": 139.292,
        "_internal_originalTime": 1689733698000
    },
    {
        "time": 1689733699000,
        "value": 139.291,
        "_internal_originalTime": 1689733699000
    },
    {
        "time": 1689733700000,
        "value": 139.29,
        "_internal_originalTime": 1689733700000
    },
    {
        "time": 1689733701000,
        "value": 139.291,
        "_internal_originalTime": 1689733701000
    },
    {
        "time": 1689733704000,
        "value": 139.288,
        "_internal_originalTime": 1689733704000
    },
    {
        "time": 1689733708000,
        "value": 139.291,
        "_internal_originalTime": 1689733708000
    },
    {
        "time": 1689733709000,
        "value": 139.309,
        "_internal_originalTime": 1689733709000
    },
    {
        "time": 1689733710000,
        "value": 139.306,
        "_internal_originalTime": 1689733710000
    },
    {
        "time": 1689733711000,
        "value": 139.301,
        "_internal_originalTime": 1689733711000
    },
    {
        "time": 1689733712000,
        "value": 139.3,
        "_internal_originalTime": 1689733712000
    },
    {
        "time": 1689733713000,
        "value": 139.3,
        "_internal_originalTime": 1689733713000
    },
    {
        "time": 1689733715000,
        "value": 139.297,
        "_internal_originalTime": 1689733715000
    },
    {
        "time": 1689733716000,
        "value": 139.298,
        "_internal_originalTime": 1689733716000
    },
    {
        "time": 1689733717000,
        "value": 139.299,
        "_internal_originalTime": 1689733717000
    },
    {
        "time": 1689733721000,
        "value": 139.297,
        "_internal_originalTime": 1689733721000
    },
    {
        "time": 1689733722000,
        "value": 139.287,
        "_internal_originalTime": 1689733722000
    },
    {
        "time": 1689733724000,
        "value": 139.287,
        "_internal_originalTime": 1689733724000
    },
    {
        "time": 1689733725000,
        "value": 139.276,
        "_internal_originalTime": 1689733725000
    },
    {
        "time": 1689733726000,
        "value": 139.281,
        "_internal_originalTime": 1689733726000
    },
    {
        "time": 1689733727000,
        "value": 139.276,
        "_internal_originalTime": 1689733727000
    },
    {
        "time": 1689733730000,
        "value": 139.28,
        "_internal_originalTime": 1689733730000
    },
    {
        "time": 1689733731000,
        "value": 139.278,
        "_internal_originalTime": 1689733731000
    },
    {
        "time": 1689733732000,
        "value": 139.281,
        "_internal_originalTime": 1689733732000
    },
    {
        "time": 1689733733000,
        "value": 139.28,
        "_internal_originalTime": 1689733733000
    },
    {
        "time": 1689733735000,
        "value": 139.282,
        "_internal_originalTime": 1689733735000
    },
    {
        "time": 1689733736000,
        "value": 139.287,
        "_internal_originalTime": 1689733736000
    },
    {
        "time": 1689733738000,
        "value": 139.287,
        "_internal_originalTime": 1689733738000
    },
    {
        "time": 1689733739000,
        "value": 139.287,
        "_internal_originalTime": 1689733739000
    },
    {
        "time": 1689733740000,
        "value": 139.287,
        "_internal_originalTime": 1689733740000
    },
    {
        "time": 1689733741000,
        "value": 139.287,
        "_internal_originalTime": 1689733741000
    },
    {
        "time": 1689733743000,
        "value": 139.288,
        "_internal_originalTime": 1689733743000
    },
    {
        "time": 1689733744000,
        "value": 139.294,
        "_internal_originalTime": 1689733744000
    },
    {
        "time": 1689733746000,
        "value": 139.303,
        "_internal_originalTime": 1689733746000
    },
    {
        "time": 1689733747000,
        "value": 139.305,
        "_internal_originalTime": 1689733747000
    },
    {
        "time": 1689733748000,
        "value": 139.302,
        "_internal_originalTime": 1689733748000
    },
    {
        "time": 1689733750000,
        "value": 139.307,
        "_internal_originalTime": 1689733750000
    },
    {
        "time": 1689733751000,
        "value": 139.31,
        "_internal_originalTime": 1689733751000
    },
    {
        "time": 1689733752000,
        "value": 139.314,
        "_internal_originalTime": 1689733752000
    },
    {
        "time": 1689733753000,
        "value": 139.31,
        "_internal_originalTime": 1689733753000
    },
    {
        "time": 1689733754000,
        "value": 139.315,
        "_internal_originalTime": 1689733754000
    },
    {
        "time": 1689733756000,
        "value": 139.315,
        "_internal_originalTime": 1689733756000
    },
    {
        "time": 1689733758000,
        "value": 139.304,
        "_internal_originalTime": 1689733758000
    },
    {
        "time": 1689733759000,
        "value": 139.293,
        "_internal_originalTime": 1689733759000
    },
    {
        "time": 1689733760000,
        "value": 139.286,
        "_internal_originalTime": 1689733760000
    },
    {
        "time": 1689733761000,
        "value": 139.286,
        "_internal_originalTime": 1689733761000
    },
    {
        "time": 1689733762000,
        "value": 139.284,
        "_internal_originalTime": 1689733762000
    },
    {
        "time": 1689733763000,
        "value": 139.288,
        "_internal_originalTime": 1689733763000
    },
    {
        "time": 1689733764000,
        "value": 139.281,
        "_internal_originalTime": 1689733764000
    },
    {
        "time": 1689733766000,
        "value": 139.286,
        "_internal_originalTime": 1689733766000
    },
    {
        "time": 1689733768000,
        "value": 139.286,
        "_internal_originalTime": 1689733768000
    },
    {
        "time": 1689733769000,
        "value": 139.283,
        "_internal_originalTime": 1689733769000
    },
    {
        "time": 1689733770000,
        "value": 139.283,
        "_internal_originalTime": 1689733770000
    },
    {
        "time": 1689733771000,
        "value": 139.287,
        "_internal_originalTime": 1689733771000
    },
    {
        "time": 1689733773000,
        "value": 139.283,
        "_internal_originalTime": 1689733773000
    },
    {
        "time": 1689733774000,
        "value": 139.279,
        "_internal_originalTime": 1689733774000
    },
    {
        "time": 1689733775000,
        "value": 139.279,
        "_internal_originalTime": 1689733775000
    },
    {
        "time": 1689733777000,
        "value": 139.277,
        "_internal_originalTime": 1689733777000
    },
    {
        "time": 1689733778000,
        "value": 139.275,
        "_internal_originalTime": 1689733778000
    },
    {
        "time": 1689733779000,
        "value": 139.28,
        "_internal_originalTime": 1689733779000
    },
    {
        "time": 1689733780000,
        "value": 139.274,
        "_internal_originalTime": 1689733780000
    },
    {
        "time": 1689733781000,
        "value": 139.276,
        "_internal_originalTime": 1689733781000
    },
    {
        "time": 1689733783000,
        "value": 139.287,
        "_internal_originalTime": 1689733783000
    },
    {
        "time": 1689733784000,
        "value": 139.292,
        "_internal_originalTime": 1689733784000
    },
    {
        "time": 1689733786000,
        "value": 139.295,
        "_internal_originalTime": 1689733786000
    },
    {
        "time": 1689733788000,
        "value": 139.289,
        "_internal_originalTime": 1689733788000
    },
    {
        "time": 1689733789000,
        "value": 139.287,
        "_internal_originalTime": 1689733789000
    },
    {
        "time": 1689733790000,
        "value": 139.287,
        "_internal_originalTime": 1689733790000
    },
    {
        "time": 1689733792000,
        "value": 139.289,
        "_internal_originalTime": 1689733792000
    },
    {
        "time": 1689733794000,
        "value": 139.286,
        "_internal_originalTime": 1689733794000
    },
    {
        "time": 1689733796000,
        "value": 139.282,
        "_internal_originalTime": 1689733796000
    },
    {
        "time": 1689733798000,
        "value": 139.287,
        "_internal_originalTime": 1689733798000
    },
    {
        "time": 1689733799000,
        "value": 139.287,
        "_internal_originalTime": 1689733799000
    },
    {
        "time": 1689733800000,
        "value": 139.284,
        "_internal_originalTime": 1689733800000
    },
    {
        "time": 1689733801000,
        "value": 139.287,
        "_internal_originalTime": 1689733801000
    },
    {
        "time": 1689733803000,
        "value": 139.287,
        "_internal_originalTime": 1689733803000
    },
    {
        "time": 1689733804000,
        "value": 139.287,
        "_internal_originalTime": 1689733804000
    },
    {
        "time": 1689733805000,
        "value": 139.284,
        "_internal_originalTime": 1689733805000
    },
    {
        "time": 1689733807000,
        "value": 139.275,
        "_internal_originalTime": 1689733807000
    },
    {
        "time": 1689733808000,
        "value": 139.276,
        "_internal_originalTime": 1689733808000
    },
    {
        "time": 1689733809000,
        "value": 139.28,
        "_internal_originalTime": 1689733809000
    },
    {
        "time": 1689733811000,
        "value": 139.284,
        "_internal_originalTime": 1689733811000
    },
    {
        "time": 1689733812000,
        "value": 139.287,
        "_internal_originalTime": 1689733812000
    },
    {
        "time": 1689733815000,
        "value": 139.287,
        "_internal_originalTime": 1689733815000
    },
    {
        "time": 1689733817000,
        "value": 139.286,
        "_internal_originalTime": 1689733817000
    },
    {
        "time": 1689733818000,
        "value": 139.297,
        "_internal_originalTime": 1689733818000
    },
    {
        "time": 1689733819000,
        "value": 139.288,
        "_internal_originalTime": 1689733819000
    },
    {
        "time": 1689733820000,
        "value": 139.289,
        "_internal_originalTime": 1689733820000
    },
    {
        "time": 1689733821000,
        "value": 139.281,
        "_internal_originalTime": 1689733821000
    },
    {
        "time": 1689733822000,
        "value": 139.274,
        "_internal_originalTime": 1689733822000
    },
    {
        "time": 1689733823000,
        "value": 139.274,
        "_internal_originalTime": 1689733823000
    },
    {
        "time": 1689733825000,
        "value": 139.278,
        "_internal_originalTime": 1689733825000
    },
    {
        "time": 1689733826000,
        "value": 139.272,
        "_internal_originalTime": 1689733826000
    },
    {
        "time": 1689733829000,
        "value": 139.277,
        "_internal_originalTime": 1689733829000
    },
    {
        "time": 1689733830000,
        "value": 139.279,
        "_internal_originalTime": 1689733830000
    },
    {
        "time": 1689733831000,
        "value": 139.281,
        "_internal_originalTime": 1689733831000
    },
    {
        "time": 1689733832000,
        "value": 139.281,
        "_internal_originalTime": 1689733832000
    },
    {
        "time": 1689733834000,
        "value": 139.28,
        "_internal_originalTime": 1689733834000
    },
    {
        "time": 1689733837000,
        "value": 139.274,
        "_internal_originalTime": 1689733837000
    },
    {
        "time": 1689733838000,
        "value": 139.285,
        "_internal_originalTime": 1689733838000
    },
    {
        "time": 1689733839000,
        "value": 139.286,
        "_internal_originalTime": 1689733839000
    },
    {
        "time": 1689733840000,
        "value": 139.283,
        "_internal_originalTime": 1689733840000
    },
    {
        "time": 1689733843000,
        "value": 139.28,
        "_internal_originalTime": 1689733843000
    },
    {
        "time": 1689733845000,
        "value": 139.286,
        "_internal_originalTime": 1689733845000
    },
    {
        "time": 1689733847000,
        "value": 139.282,
        "_internal_originalTime": 1689733847000
    },
    {
        "time": 1689733848000,
        "value": 139.28,
        "_internal_originalTime": 1689733848000
    },
    {
        "time": 1689733849000,
        "value": 139.289,
        "_internal_originalTime": 1689733849000
    },
    {
        "time": 1689733850000,
        "value": 139.286,
        "_internal_originalTime": 1689733850000
    },
    {
        "time": 1689733852000,
        "value": 139.284,
        "_internal_originalTime": 1689733852000
    },
    {
        "time": 1689733854000,
        "value": 139.28,
        "_internal_originalTime": 1689733854000
    },
    {
        "time": 1689733856000,
        "value": 139.286,
        "_internal_originalTime": 1689733856000
    },
    {
        "time": 1689733858000,
        "value": 139.286,
        "_internal_originalTime": 1689733858000
    },
    {
        "time": 1689733860000,
        "value": 139.281,
        "_internal_originalTime": 1689733860000
    },
    {
        "time": 1689733861000,
        "value": 139.279,
        "_internal_originalTime": 1689733861000
    },
    {
        "time": 1689733862000,
        "value": 139.268,
        "_internal_originalTime": 1689733862000
    },
    {
        "time": 1689733863000,
        "value": 139.267,
        "_internal_originalTime": 1689733863000
    },
    {
        "time": 1689733864000,
        "value": 139.268,
        "_internal_originalTime": 1689733864000
    },
    {
        "time": 1689733865000,
        "value": 139.264,
        "_internal_originalTime": 1689733865000
    },
    {
        "time": 1689733866000,
        "value": 139.264,
        "_internal_originalTime": 1689733866000
    },
    {
        "time": 1689733868000,
        "value": 139.268,
        "_internal_originalTime": 1689733868000
    },
    {
        "time": 1689733869000,
        "value": 139.268,
        "_internal_originalTime": 1689733869000
    },
    {
        "time": 1689733871000,
        "value": 139.268,
        "_internal_originalTime": 1689733871000
    },
    {
        "time": 1689733872000,
        "value": 139.262,
        "_internal_originalTime": 1689733872000
    },
    {
        "time": 1689733873000,
        "value": 139.263,
        "_internal_originalTime": 1689733873000
    },
    {
        "time": 1689733874000,
        "value": 139.268,
        "_internal_originalTime": 1689733874000
    },
    {
        "time": 1689733876000,
        "value": 139.263,
        "_internal_originalTime": 1689733876000
    },
    {
        "time": 1689733877000,
        "value": 139.259,
        "_internal_originalTime": 1689733877000
    },
    {
        "time": 1689733878000,
        "value": 139.264,
        "_internal_originalTime": 1689733878000
    },
    {
        "time": 1689733879000,
        "value": 139.262,
        "_internal_originalTime": 1689733879000
    },
    {
        "time": 1689733880000,
        "value": 139.262,
        "_internal_originalTime": 1689733880000
    },
    {
        "time": 1689733881000,
        "value": 139.262,
        "_internal_originalTime": 1689733881000
    },
    {
        "time": 1689733882000,
        "value": 139.268,
        "_internal_originalTime": 1689733882000
    },
    {
        "time": 1689733883000,
        "value": 139.268,
        "_internal_originalTime": 1689733883000
    },
    {
        "time": 1689733885000,
        "value": 139.266,
        "_internal_originalTime": 1689733885000
    },
    {
        "time": 1689733886000,
        "value": 139.267,
        "_internal_originalTime": 1689733886000
    },
    {
        "time": 1689733887000,
        "value": 139.267,
        "_internal_originalTime": 1689733887000
    },
    {
        "time": 1689733889000,
        "value": 139.262,
        "_internal_originalTime": 1689733889000
    },
    {
        "time": 1689733890000,
        "value": 139.267,
        "_internal_originalTime": 1689733890000
    },
    {
        "time": 1689733892000,
        "value": 139.262,
        "_internal_originalTime": 1689733892000
    },
    {
        "time": 1689733893000,
        "value": 139.262,
        "_internal_originalTime": 1689733893000
    },
    {
        "time": 1689733894000,
        "value": 139.257,
        "_internal_originalTime": 1689733894000
    },
    {
        "time": 1689733896000,
        "value": 139.258,
        "_internal_originalTime": 1689733896000
    },
    {
        "time": 1689733897000,
        "value": 139.256,
        "_internal_originalTime": 1689733897000
    },
    {
        "time": 1689733898000,
        "value": 139.257,
        "_internal_originalTime": 1689733898000
    },
    {
        "time": 1689733900000,
        "value": 139.261,
        "_internal_originalTime": 1689733900000
    },
    {
        "time": 1689733902000,
        "value": 139.26,
        "_internal_originalTime": 1689733902000
    },
    {
        "time": 1689733903000,
        "value": 139.247,
        "_internal_originalTime": 1689733903000
    },
    {
        "time": 1689733904000,
        "value": 139.246,
        "_internal_originalTime": 1689733904000
    },
    {
        "time": 1689733905000,
        "value": 139.25,
        "_internal_originalTime": 1689733905000
    },
    {
        "time": 1689733906000,
        "value": 139.251,
        "_internal_originalTime": 1689733906000
    },
    {
        "time": 1689733907000,
        "value": 139.246,
        "_internal_originalTime": 1689733907000
    },
    {
        "time": 1689733909000,
        "value": 139.253,
        "_internal_originalTime": 1689733909000
    },
    {
        "time": 1689733910000,
        "value": 139.251,
        "_internal_originalTime": 1689733910000
    },
    {
        "time": 1689733911000,
        "value": 139.254,
        "_internal_originalTime": 1689733911000
    },
    {
        "time": 1689733913000,
        "value": 139.254,
        "_internal_originalTime": 1689733913000
    },
    {
        "time": 1689733914000,
        "value": 139.249,
        "_internal_originalTime": 1689733914000
    },
    {
        "time": 1689733915000,
        "value": 139.247,
        "_internal_originalTime": 1689733915000
    },
    {
        "time": 1689733916000,
        "value": 139.249,
        "_internal_originalTime": 1689733916000
    },
    {
        "time": 1689733918000,
        "value": 139.251,
        "_internal_originalTime": 1689733918000
    },
    {
        "time": 1689733919000,
        "value": 139.25,
        "_internal_originalTime": 1689733919000
    },
    {
        "time": 1689733921000,
        "value": 139.23,
        "_internal_originalTime": 1689733921000
    },
    {
        "time": 1689733922000,
        "value": 139.248,
        "_internal_originalTime": 1689733922000
    },
    {
        "time": 1689733924000,
        "value": 139.248,
        "_internal_originalTime": 1689733924000
    },
    {
        "time": 1689733925000,
        "value": 139.25,
        "_internal_originalTime": 1689733925000
    },
    {
        "time": 1689733928000,
        "value": 139.25,
        "_internal_originalTime": 1689733928000
    },
    {
        "time": 1689733929000,
        "value": 139.248,
        "_internal_originalTime": 1689733929000
    },
    {
        "time": 1689733930000,
        "value": 139.248,
        "_internal_originalTime": 1689733930000
    },
    {
        "time": 1689733931000,
        "value": 139.248,
        "_internal_originalTime": 1689733931000
    },
    {
        "time": 1689733932000,
        "value": 139.246,
        "_internal_originalTime": 1689733932000
    },
    {
        "time": 1689733933000,
        "value": 139.246,
        "_internal_originalTime": 1689733933000
    },
    {
        "time": 1689733935000,
        "value": 139.247,
        "_internal_originalTime": 1689733935000
    },
    {
        "time": 1689733938000,
        "value": 139.248,
        "_internal_originalTime": 1689733938000
    },
    {
        "time": 1689733939000,
        "value": 139.246,
        "_internal_originalTime": 1689733939000
    },
    {
        "time": 1689733940000,
        "value": 139.248,
        "_internal_originalTime": 1689733940000
    },
    {
        "time": 1689733942000,
        "value": 139.246,
        "_internal_originalTime": 1689733942000
    },
    {
        "time": 1689733943000,
        "value": 139.25,
        "_internal_originalTime": 1689733943000
    },
    {
        "time": 1689733944000,
        "value": 139.233,
        "_internal_originalTime": 1689733944000
    },
    {
        "time": 1689733945000,
        "value": 139.238,
        "_internal_originalTime": 1689733945000
    },
    {
        "time": 1689733948000,
        "value": 139.236,
        "_internal_originalTime": 1689733948000
    },
    {
        "time": 1689733949000,
        "value": 139.24,
        "_internal_originalTime": 1689733949000
    },
    {
        "time": 1689733950000,
        "value": 139.236,
        "_internal_originalTime": 1689733950000
    },
    {
        "time": 1689733952000,
        "value": 139.236,
        "_internal_originalTime": 1689733952000
    },
    {
        "time": 1689733953000,
        "value": 139.24,
        "_internal_originalTime": 1689733953000
    },
    {
        "time": 1689733956000,
        "value": 139.236,
        "_internal_originalTime": 1689733956000
    },
    {
        "time": 1689733958000,
        "value": 139.236,
        "_internal_originalTime": 1689733958000
    },
    {
        "time": 1689733959000,
        "value": 139.238,
        "_internal_originalTime": 1689733959000
    },
    {
        "time": 1689733961000,
        "value": 139.24,
        "_internal_originalTime": 1689733961000
    },
    {
        "time": 1689733962000,
        "value": 139.24,
        "_internal_originalTime": 1689733962000
    },
    {
        "time": 1689733965000,
        "value": 139.24,
        "_internal_originalTime": 1689733965000
    },
    {
        "time": 1689733966000,
        "value": 139.248,
        "_internal_originalTime": 1689733966000
    },
    {
        "time": 1689733967000,
        "value": 139.248,
        "_internal_originalTime": 1689733967000
    },
    {
        "time": 1689733968000,
        "value": 139.252,
        "_internal_originalTime": 1689733968000
    },
    {
        "time": 1689733969000,
        "value": 139.25,
        "_internal_originalTime": 1689733969000
    },
    {
        "time": 1689733971000,
        "value": 139.269,
        "_internal_originalTime": 1689733971000
    },
    {
        "time": 1689733972000,
        "value": 139.267,
        "_internal_originalTime": 1689733972000
    },
    {
        "time": 1689733973000,
        "value": 139.267,
        "_internal_originalTime": 1689733973000
    },
    {
        "time": 1689733974000,
        "value": 139.273,
        "_internal_originalTime": 1689733974000
    },
    {
        "time": 1689733976000,
        "value": 139.271,
        "_internal_originalTime": 1689733976000
    },
    {
        "time": 1689733977000,
        "value": 139.269,
        "_internal_originalTime": 1689733977000
    },
    {
        "time": 1689733978000,
        "value": 139.272,
        "_internal_originalTime": 1689733978000
    },
    {
        "time": 1689733979000,
        "value": 139.268,
        "_internal_originalTime": 1689733979000
    },
    {
        "time": 1689733981000,
        "value": 139.259,
        "_internal_originalTime": 1689733981000
    },
    {
        "time": 1689733982000,
        "value": 139.26,
        "_internal_originalTime": 1689733982000
    },
    {
        "time": 1689733983000,
        "value": 139.257,
        "_internal_originalTime": 1689733983000
    },
    {
        "time": 1689733984000,
        "value": 139.262,
        "_internal_originalTime": 1689733984000
    },
    {
        "time": 1689733986000,
        "value": 139.262,
        "_internal_originalTime": 1689733986000
    },
    {
        "time": 1689733988000,
        "value": 139.256,
        "_internal_originalTime": 1689733988000
    },
    {
        "time": 1689733990000,
        "value": 139.255,
        "_internal_originalTime": 1689733990000
    },
    {
        "time": 1689733991000,
        "value": 139.258,
        "_internal_originalTime": 1689733991000
    },
    {
        "time": 1689733992000,
        "value": 139.255,
        "_internal_originalTime": 1689733992000
    },
    {
        "time": 1689733993000,
        "value": 139.256,
        "_internal_originalTime": 1689733993000
    },
    {
        "time": 1689733996000,
        "value": 139.261,
        "_internal_originalTime": 1689733996000
    },
    {
        "time": 1689733997000,
        "value": 139.264,
        "_internal_originalTime": 1689733997000
    },
    {
        "time": 1689733999000,
        "value": 139.265,
        "_internal_originalTime": 1689733999000
    },
    {
        "time": 1689734000000,
        "value": 139.263,
        "_internal_originalTime": 1689734000000
    },
    {
        "time": 1689734002000,
        "value": 139.266,
        "_internal_originalTime": 1689734002000
    },
    {
        "time": 1689734004000,
        "value": 139.264,
        "_internal_originalTime": 1689734004000
    },
    {
        "time": 1689734005000,
        "value": 139.27,
        "_internal_originalTime": 1689734005000
    },
    {
        "time": 1689734006000,
        "value": 139.257,
        "_internal_originalTime": 1689734006000
    },
    {
        "time": 1689734008000,
        "value": 139.256,
        "_internal_originalTime": 1689734008000
    },
    {
        "time": 1689734009000,
        "value": 139.261,
        "_internal_originalTime": 1689734009000
    },
    {
        "time": 1689734010000,
        "value": 139.26,
        "_internal_originalTime": 1689734010000
    },
    {
        "time": 1689734011000,
        "value": 139.256,
        "_internal_originalTime": 1689734011000
    },
    {
        "time": 1689734013000,
        "value": 139.261,
        "_internal_originalTime": 1689734013000
    },
    {
        "time": 1689734014000,
        "value": 139.26,
        "_internal_originalTime": 1689734014000
    },
    {
        "time": 1689734016000,
        "value": 139.25,
        "_internal_originalTime": 1689734016000
    },
    {
        "time": 1689734017000,
        "value": 139.251,
        "_internal_originalTime": 1689734017000
    },
    {
        "time": 1689734019000,
        "value": 139.25,
        "_internal_originalTime": 1689734019000
    },
    {
        "time": 1689734021000,
        "value": 139.249,
        "_internal_originalTime": 1689734021000
    },
    {
        "time": 1689734022000,
        "value": 139.267,
        "_internal_originalTime": 1689734022000
    },
    {
        "time": 1689734023000,
        "value": 139.264,
        "_internal_originalTime": 1689734023000
    },
    {
        "time": 1689734025000,
        "value": 139.265,
        "_internal_originalTime": 1689734025000
    },
    {
        "time": 1689734026000,
        "value": 139.267,
        "_internal_originalTime": 1689734026000
    },
    {
        "time": 1689734028000,
        "value": 139.262,
        "_internal_originalTime": 1689734028000
    },
    {
        "time": 1689734029000,
        "value": 139.256,
        "_internal_originalTime": 1689734029000
    },
    {
        "time": 1689734030000,
        "value": 139.254,
        "_internal_originalTime": 1689734030000
    },
    {
        "time": 1689734031000,
        "value": 139.255,
        "_internal_originalTime": 1689734031000
    },
    {
        "time": 1689734033000,
        "value": 139.256,
        "_internal_originalTime": 1689734033000
    },
    {
        "time": 1689734034000,
        "value": 139.261,
        "_internal_originalTime": 1689734034000
    },
    {
        "time": 1689734035000,
        "value": 139.261,
        "_internal_originalTime": 1689734035000
    },
    {
        "time": 1689734038000,
        "value": 139.261,
        "_internal_originalTime": 1689734038000
    },
    {
        "time": 1689734039000,
        "value": 139.256,
        "_internal_originalTime": 1689734039000
    },
    {
        "time": 1689734040000,
        "value": 139.256,
        "_internal_originalTime": 1689734040000
    },
    {
        "time": 1689734041000,
        "value": 139.254,
        "_internal_originalTime": 1689734041000
    },
    {
        "time": 1689734043000,
        "value": 139.254,
        "_internal_originalTime": 1689734043000
    },
    {
        "time": 1689734044000,
        "value": 139.256,
        "_internal_originalTime": 1689734044000
    },
    {
        "time": 1689734046000,
        "value": 139.248,
        "_internal_originalTime": 1689734046000
    },
    {
        "time": 1689734048000,
        "value": 139.247,
        "_internal_originalTime": 1689734048000
    },
    {
        "time": 1689734049000,
        "value": 139.247,
        "_internal_originalTime": 1689734049000
    },
    {
        "time": 1689734051000,
        "value": 139.247,
        "_internal_originalTime": 1689734051000
    },
    {
        "time": 1689734052000,
        "value": 139.249,
        "_internal_originalTime": 1689734052000
    },
    {
        "time": 1689734053000,
        "value": 139.248,
        "_internal_originalTime": 1689734053000
    },
    {
        "time": 1689734055000,
        "value": 139.249,
        "_internal_originalTime": 1689734055000
    },
    {
        "time": 1689734058000,
        "value": 139.247,
        "_internal_originalTime": 1689734058000
    },
    {
        "time": 1689734059000,
        "value": 139.248,
        "_internal_originalTime": 1689734059000
    },
    {
        "time": 1689734060000,
        "value": 139.245,
        "_internal_originalTime": 1689734060000
    },
    {
        "time": 1689734061000,
        "value": 139.244,
        "_internal_originalTime": 1689734061000
    },
    {
        "time": 1689734062000,
        "value": 139.243,
        "_internal_originalTime": 1689734062000
    },
    {
        "time": 1689734063000,
        "value": 139.244,
        "_internal_originalTime": 1689734063000
    },
    {
        "time": 1689734065000,
        "value": 139.248,
        "_internal_originalTime": 1689734065000
    },
    {
        "time": 1689734066000,
        "value": 139.246,
        "_internal_originalTime": 1689734066000
    },
    {
        "time": 1689734067000,
        "value": 139.25,
        "_internal_originalTime": 1689734067000
    },
    {
        "time": 1689734069000,
        "value": 139.247,
        "_internal_originalTime": 1689734069000
    },
    {
        "time": 1689734070000,
        "value": 139.245,
        "_internal_originalTime": 1689734070000
    },
    {
        "time": 1689734071000,
        "value": 139.248,
        "_internal_originalTime": 1689734071000
    },
    {
        "time": 1689734073000,
        "value": 139.25,
        "_internal_originalTime": 1689734073000
    },
    {
        "time": 1689734076000,
        "value": 139.261,
        "_internal_originalTime": 1689734076000
    },
    {
        "time": 1689734077000,
        "value": 139.259,
        "_internal_originalTime": 1689734077000
    },
    {
        "time": 1689734078000,
        "value": 139.257,
        "_internal_originalTime": 1689734078000
    },
    {
        "time": 1689734079000,
        "value": 139.254,
        "_internal_originalTime": 1689734079000
    },
    {
        "time": 1689734080000,
        "value": 139.252,
        "_internal_originalTime": 1689734080000
    },
    {
        "time": 1689734083000,
        "value": 139.253,
        "_internal_originalTime": 1689734083000
    },
    {
        "time": 1689734084000,
        "value": 139.251,
        "_internal_originalTime": 1689734084000
    },
    {
        "time": 1689734085000,
        "value": 139.252,
        "_internal_originalTime": 1689734085000
    },
    {
        "time": 1689734089000,
        "value": 139.255,
        "_internal_originalTime": 1689734089000
    },
    {
        "time": 1689734090000,
        "value": 139.257,
        "_internal_originalTime": 1689734090000
    },
    {
        "time": 1689734091000,
        "value": 139.254,
        "_internal_originalTime": 1689734091000
    },
    {
        "time": 1689734095000,
        "value": 139.254,
        "_internal_originalTime": 1689734095000
    },
    {
        "time": 1689734096000,
        "value": 139.254,
        "_internal_originalTime": 1689734096000
    },
    {
        "time": 1689734097000,
        "value": 139.255,
        "_internal_originalTime": 1689734097000
    },
    {
        "time": 1689734098000,
        "value": 139.252,
        "_internal_originalTime": 1689734098000
    },
    {
        "time": 1689734099000,
        "value": 139.254,
        "_internal_originalTime": 1689734099000
    },
    {
        "time": 1689734102000,
        "value": 139.254,
        "_internal_originalTime": 1689734102000
    },
    {
        "time": 1689734105000,
        "value": 139.241,
        "_internal_originalTime": 1689734105000
    },
    {
        "time": 1689734106000,
        "value": 139.242,
        "_internal_originalTime": 1689734106000
    },
    {
        "time": 1689734107000,
        "value": 139.24,
        "_internal_originalTime": 1689734107000
    },
    {
        "time": 1689734109000,
        "value": 139.251,
        "_internal_originalTime": 1689734109000
    },
    {
        "time": 1689734110000,
        "value": 139.249,
        "_internal_originalTime": 1689734110000
    },
    {
        "time": 1689734112000,
        "value": 139.25,
        "_internal_originalTime": 1689734112000
    },
    {
        "time": 1689734113000,
        "value": 139.252,
        "_internal_originalTime": 1689734113000
    },
    {
        "time": 1689734115000,
        "value": 139.253,
        "_internal_originalTime": 1689734115000
    },
    {
        "time": 1689734116000,
        "value": 139.249,
        "_internal_originalTime": 1689734116000
    },
    {
        "time": 1689734117000,
        "value": 139.248,
        "_internal_originalTime": 1689734117000
    },
    {
        "time": 1689734119000,
        "value": 139.251,
        "_internal_originalTime": 1689734119000
    },
    {
        "time": 1689734120000,
        "value": 139.255,
        "_internal_originalTime": 1689734120000
    },
    {
        "time": 1689734121000,
        "value": 139.254,
        "_internal_originalTime": 1689734121000
    },
    {
        "time": 1689734122000,
        "value": 139.267,
        "_internal_originalTime": 1689734122000
    },
    {
        "time": 1689734123000,
        "value": 139.263,
        "_internal_originalTime": 1689734123000
    },
    {
        "time": 1689734124000,
        "value": 139.257,
        "_internal_originalTime": 1689734124000
    },
    {
        "time": 1689734125000,
        "value": 139.261,
        "_internal_originalTime": 1689734125000
    },
    {
        "time": 1689734127000,
        "value": 139.26,
        "_internal_originalTime": 1689734127000
    },
    {
        "time": 1689734128000,
        "value": 139.26,
        "_internal_originalTime": 1689734128000
    },
    {
        "time": 1689734130000,
        "value": 139.262,
        "_internal_originalTime": 1689734130000
    },
    {
        "time": 1689734132000,
        "value": 139.262,
        "_internal_originalTime": 1689734132000
    },
    {
        "time": 1689734133000,
        "value": 139.275,
        "_internal_originalTime": 1689734133000
    },
    {
        "time": 1689734134000,
        "value": 139.278,
        "_internal_originalTime": 1689734134000
    },
    {
        "time": 1689734136000,
        "value": 139.282,
        "_internal_originalTime": 1689734136000
    },
    {
        "time": 1689734137000,
        "value": 139.286,
        "_internal_originalTime": 1689734137000
    },
    {
        "time": 1689734139000,
        "value": 139.287,
        "_internal_originalTime": 1689734139000
    },
    {
        "time": 1689734140000,
        "value": 139.284,
        "_internal_originalTime": 1689734140000
    },
    {
        "time": 1689734141000,
        "value": 139.286,
        "_internal_originalTime": 1689734141000
    },
    {
        "time": 1689734142000,
        "value": 139.278,
        "_internal_originalTime": 1689734142000
    },
    {
        "time": 1689734143000,
        "value": 139.278,
        "_internal_originalTime": 1689734143000
    },
    {
        "time": 1689734144000,
        "value": 139.278,
        "_internal_originalTime": 1689734144000
    },
    {
        "time": 1689734146000,
        "value": 139.283,
        "_internal_originalTime": 1689734146000
    },
    {
        "time": 1689734148000,
        "value": 139.281,
        "_internal_originalTime": 1689734148000
    },
    {
        "time": 1689734151000,
        "value": 139.281,
        "_internal_originalTime": 1689734151000
    },
    {
        "time": 1689734152000,
        "value": 139.267,
        "_internal_originalTime": 1689734152000
    },
    {
        "time": 1689734153000,
        "value": 139.269,
        "_internal_originalTime": 1689734153000
    },
    {
        "time": 1689734154000,
        "value": 139.261,
        "_internal_originalTime": 1689734154000
    },
    {
        "time": 1689734156000,
        "value": 139.269,
        "_internal_originalTime": 1689734156000
    },
    {
        "time": 1689734157000,
        "value": 139.266,
        "_internal_originalTime": 1689734157000
    },
    {
        "time": 1689734160000,
        "value": 139.276,
        "_internal_originalTime": 1689734160000
    },
    {
        "time": 1689734161000,
        "value": 139.277,
        "_internal_originalTime": 1689734161000
    },
    {
        "time": 1689734162000,
        "value": 139.281,
        "_internal_originalTime": 1689734162000
    },
    {
        "time": 1689734163000,
        "value": 139.277,
        "_internal_originalTime": 1689734163000
    },
    {
        "time": 1689734164000,
        "value": 139.274,
        "_internal_originalTime": 1689734164000
    },
    {
        "time": 1689734166000,
        "value": 139.28,
        "_internal_originalTime": 1689734166000
    },
    {
        "time": 1689734167000,
        "value": 139.286,
        "_internal_originalTime": 1689734167000
    },
    {
        "time": 1689734168000,
        "value": 139.279,
        "_internal_originalTime": 1689734168000
    },
    {
        "time": 1689734170000,
        "value": 139.278,
        "_internal_originalTime": 1689734170000
    },
    {
        "time": 1689734171000,
        "value": 139.276,
        "_internal_originalTime": 1689734171000
    },
    {
        "time": 1689734173000,
        "value": 139.279,
        "_internal_originalTime": 1689734173000
    },
    {
        "time": 1689734174000,
        "value": 139.279,
        "_internal_originalTime": 1689734174000
    },
    {
        "time": 1689734177000,
        "value": 139.276,
        "_internal_originalTime": 1689734177000
    },
    {
        "time": 1689734178000,
        "value": 139.276,
        "_internal_originalTime": 1689734178000
    },
    {
        "time": 1689734179000,
        "value": 139.277,
        "_internal_originalTime": 1689734179000
    },
    {
        "time": 1689734180000,
        "value": 139.274,
        "_internal_originalTime": 1689734180000
    },
    {
        "time": 1689734182000,
        "value": 139.274,
        "_internal_originalTime": 1689734182000
    },
    {
        "time": 1689734184000,
        "value": 139.277,
        "_internal_originalTime": 1689734184000
    },
    {
        "time": 1689734185000,
        "value": 139.275,
        "_internal_originalTime": 1689734185000
    },
    {
        "time": 1689734186000,
        "value": 139.276,
        "_internal_originalTime": 1689734186000
    },
    {
        "time": 1689734188000,
        "value": 139.269,
        "_internal_originalTime": 1689734188000
    },
    {
        "time": 1689734189000,
        "value": 139.266,
        "_internal_originalTime": 1689734189000
    },
    {
        "time": 1689734190000,
        "value": 139.265,
        "_internal_originalTime": 1689734190000
    },
    {
        "time": 1689734193000,
        "value": 139.264,
        "_internal_originalTime": 1689734193000
    },
    {
        "time": 1689734196000,
        "value": 139.267,
        "_internal_originalTime": 1689734196000
    },
    {
        "time": 1689734198000,
        "value": 139.267,
        "_internal_originalTime": 1689734198000
    },
    {
        "time": 1689734200000,
        "value": 139.264,
        "_internal_originalTime": 1689734200000
    },
    {
        "time": 1689734201000,
        "value": 139.259,
        "_internal_originalTime": 1689734201000
    },
    {
        "time": 1689734202000,
        "value": 139.258,
        "_internal_originalTime": 1689734202000
    },
    {
        "time": 1689734204000,
        "value": 139.261,
        "_internal_originalTime": 1689734204000
    },
    {
        "time": 1689734206000,
        "value": 139.261,
        "_internal_originalTime": 1689734206000
    },
    {
        "time": 1689734207000,
        "value": 139.256,
        "_internal_originalTime": 1689734207000
    },
    {
        "time": 1689734208000,
        "value": 139.261,
        "_internal_originalTime": 1689734208000
    },
    {
        "time": 1689734209000,
        "value": 139.258,
        "_internal_originalTime": 1689734209000
    },
    {
        "time": 1689734210000,
        "value": 139.259,
        "_internal_originalTime": 1689734210000
    },
    {
        "time": 1689734212000,
        "value": 139.261,
        "_internal_originalTime": 1689734212000
    },
    {
        "time": 1689734213000,
        "value": 139.261,
        "_internal_originalTime": 1689734213000
    },
    {
        "time": 1689734214000,
        "value": 139.256,
        "_internal_originalTime": 1689734214000
    },
    {
        "time": 1689734215000,
        "value": 139.261,
        "_internal_originalTime": 1689734215000
    },
    {
        "time": 1689734218000,
        "value": 139.257,
        "_internal_originalTime": 1689734218000
    },
    {
        "time": 1689734219000,
        "value": 139.258,
        "_internal_originalTime": 1689734219000
    },
    {
        "time": 1689734220000,
        "value": 139.268,
        "_internal_originalTime": 1689734220000
    },
    {
        "time": 1689734221000,
        "value": 139.24,
        "_internal_originalTime": 1689734221000
    },
    {
        "time": 1689734222000,
        "value": 139.265,
        "_internal_originalTime": 1689734222000
    },
    {
        "time": 1689734223000,
        "value": 139.266,
        "_internal_originalTime": 1689734223000
    },
    {
        "time": 1689734224000,
        "value": 139.266,
        "_internal_originalTime": 1689734224000
    },
    {
        "time": 1689734226000,
        "value": 139.265,
        "_internal_originalTime": 1689734226000
    },
    {
        "time": 1689734227000,
        "value": 139.266,
        "_internal_originalTime": 1689734227000
    },
    {
        "time": 1689734228000,
        "value": 139.266,
        "_internal_originalTime": 1689734228000
    },
    {
        "time": 1689734230000,
        "value": 139.264,
        "_internal_originalTime": 1689734230000
    },
    {
        "time": 1689734231000,
        "value": 139.27,
        "_internal_originalTime": 1689734231000
    },
    {
        "time": 1689734233000,
        "value": 139.268,
        "_internal_originalTime": 1689734233000
    },
    {
        "time": 1689734235000,
        "value": 139.271,
        "_internal_originalTime": 1689734235000
    },
    {
        "time": 1689734237000,
        "value": 139.264,
        "_internal_originalTime": 1689734237000
    },
    {
        "time": 1689734239000,
        "value": 139.27,
        "_internal_originalTime": 1689734239000
    },
    {
        "time": 1689734240000,
        "value": 139.267,
        "_internal_originalTime": 1689734240000
    },
    {
        "time": 1689734243000,
        "value": 139.268,
        "_internal_originalTime": 1689734243000
    },
    {
        "time": 1689734244000,
        "value": 139.278,
        "_internal_originalTime": 1689734244000
    },
    {
        "time": 1689734245000,
        "value": 139.277,
        "_internal_originalTime": 1689734245000
    },
    {
        "time": 1689734246000,
        "value": 139.278,
        "_internal_originalTime": 1689734246000
    },
    {
        "time": 1689734247000,
        "value": 139.279,
        "_internal_originalTime": 1689734247000
    },
    {
        "time": 1689734249000,
        "value": 139.275,
        "_internal_originalTime": 1689734249000
    },
    {
        "time": 1689734250000,
        "value": 139.278,
        "_internal_originalTime": 1689734250000
    },
    {
        "time": 1689734251000,
        "value": 139.278,
        "_internal_originalTime": 1689734251000
    },
    {
        "time": 1689734252000,
        "value": 139.277,
        "_internal_originalTime": 1689734252000
    },
    {
        "time": 1689734254000,
        "value": 139.278,
        "_internal_originalTime": 1689734254000
    },
    {
        "time": 1689734255000,
        "value": 139.277,
        "_internal_originalTime": 1689734255000
    },
    {
        "time": 1689734256000,
        "value": 139.277,
        "_internal_originalTime": 1689734256000
    },
    {
        "time": 1689734259000,
        "value": 139.277,
        "_internal_originalTime": 1689734259000
    },
    {
        "time": 1689734264000,
        "value": 139.285,
        "_internal_originalTime": 1689734264000
    },
    {
        "time": 1689734265000,
        "value": 139.282,
        "_internal_originalTime": 1689734265000
    },
    {
        "time": 1689734266000,
        "value": 139.282,
        "_internal_originalTime": 1689734266000
    },
    {
        "time": 1689734269000,
        "value": 139.286,
        "_internal_originalTime": 1689734269000
    },
    {
        "time": 1689734271000,
        "value": 139.282,
        "_internal_originalTime": 1689734271000
    },
    {
        "time": 1689734272000,
        "value": 139.283,
        "_internal_originalTime": 1689734272000
    },
    {
        "time": 1689734274000,
        "value": 139.283,
        "_internal_originalTime": 1689734274000
    },
    {
        "time": 1689734275000,
        "value": 139.282,
        "_internal_originalTime": 1689734275000
    },
    {
        "time": 1689734281000,
        "value": 139.283,
        "_internal_originalTime": 1689734281000
    },
    {
        "time": 1689734282000,
        "value": 139.284,
        "_internal_originalTime": 1689734282000
    },
    {
        "time": 1689734283000,
        "value": 139.287,
        "_internal_originalTime": 1689734283000
    },
    {
        "time": 1689734284000,
        "value": 139.288,
        "_internal_originalTime": 1689734284000
    },
    {
        "time": 1689734285000,
        "value": 139.284,
        "_internal_originalTime": 1689734285000
    },
    {
        "time": 1689734287000,
        "value": 139.287,
        "_internal_originalTime": 1689734287000
    },
    {
        "time": 1689734288000,
        "value": 139.286,
        "_internal_originalTime": 1689734288000
    },
    {
        "time": 1689734289000,
        "value": 139.288,
        "_internal_originalTime": 1689734289000
    },
    {
        "time": 1689734290000,
        "value": 139.289,
        "_internal_originalTime": 1689734290000
    },
    {
        "time": 1689734292000,
        "value": 139.288,
        "_internal_originalTime": 1689734292000
    },
    {
        "time": 1689734293000,
        "value": 139.286,
        "_internal_originalTime": 1689734293000
    },
    {
        "time": 1689734296000,
        "value": 139.288,
        "_internal_originalTime": 1689734296000
    },
    {
        "time": 1689734298000,
        "value": 139.287,
        "_internal_originalTime": 1689734298000
    },
    {
        "time": 1689734299000,
        "value": 139.28,
        "_internal_originalTime": 1689734299000
    },
    {
        "time": 1689734300000,
        "value": 139.272,
        "_internal_originalTime": 1689734300000
    },
    {
        "time": 1689734301000,
        "value": 139.276,
        "_internal_originalTime": 1689734301000
    },
    {
        "time": 1689734302000,
        "value": 139.273,
        "_internal_originalTime": 1689734302000
    },
    {
        "time": 1689734303000,
        "value": 139.273,
        "_internal_originalTime": 1689734303000
    },
    {
        "time": 1689734305000,
        "value": 139.277,
        "_internal_originalTime": 1689734305000
    },
    {
        "time": 1689734306000,
        "value": 139.274,
        "_internal_originalTime": 1689734306000
    },
    {
        "time": 1689734307000,
        "value": 139.276,
        "_internal_originalTime": 1689734307000
    },
    {
        "time": 1689734309000,
        "value": 139.277,
        "_internal_originalTime": 1689734309000
    },
    {
        "time": 1689734310000,
        "value": 139.278,
        "_internal_originalTime": 1689734310000
    },
    {
        "time": 1689734311000,
        "value": 139.276,
        "_internal_originalTime": 1689734311000
    },
    {
        "time": 1689734312000,
        "value": 139.285,
        "_internal_originalTime": 1689734312000
    },
    {
        "time": 1689734313000,
        "value": 139.282,
        "_internal_originalTime": 1689734313000
    },
    {
        "time": 1689734314000,
        "value": 139.281,
        "_internal_originalTime": 1689734314000
    },
    {
        "time": 1689734316000,
        "value": 139.277,
        "_internal_originalTime": 1689734316000
    },
    {
        "time": 1689734317000,
        "value": 139.276,
        "_internal_originalTime": 1689734317000
    },
    {
        "time": 1689734318000,
        "value": 139.28,
        "_internal_originalTime": 1689734318000
    },
    {
        "time": 1689734320000,
        "value": 139.28,
        "_internal_originalTime": 1689734320000
    },
    {
        "time": 1689734321000,
        "value": 139.279,
        "_internal_originalTime": 1689734321000
    },
    {
        "time": 1689734323000,
        "value": 139.276,
        "_internal_originalTime": 1689734323000
    },
    {
        "time": 1689734324000,
        "value": 139.274,
        "_internal_originalTime": 1689734324000
    },
    {
        "time": 1689734325000,
        "value": 139.282,
        "_internal_originalTime": 1689734325000
    },
    {
        "time": 1689734326000,
        "value": 139.28,
        "_internal_originalTime": 1689734326000
    },
    {
        "time": 1689734327000,
        "value": 139.278,
        "_internal_originalTime": 1689734327000
    },
    {
        "time": 1689734328000,
        "value": 139.282,
        "_internal_originalTime": 1689734328000
    },
    {
        "time": 1689734329000,
        "value": 139.276,
        "_internal_originalTime": 1689734329000
    },
    {
        "time": 1689734330000,
        "value": 139.278,
        "_internal_originalTime": 1689734330000
    },
    {
        "time": 1689734332000,
        "value": 139.281,
        "_internal_originalTime": 1689734332000
    },
    {
        "time": 1689734333000,
        "value": 139.286,
        "_internal_originalTime": 1689734333000
    },
    {
        "time": 1689734334000,
        "value": 139.288,
        "_internal_originalTime": 1689734334000
    },
    {
        "time": 1689734336000,
        "value": 139.289,
        "_internal_originalTime": 1689734336000
    },
    {
        "time": 1689734337000,
        "value": 139.292,
        "_internal_originalTime": 1689734337000
    },
    {
        "time": 1689734338000,
        "value": 139.295,
        "_internal_originalTime": 1689734338000
    },
    {
        "time": 1689734339000,
        "value": 139.295,
        "_internal_originalTime": 1689734339000
    },
    {
        "time": 1689734340000,
        "value": 139.293,
        "_internal_originalTime": 1689734340000
    },
    {
        "time": 1689734342000,
        "value": 139.293,
        "_internal_originalTime": 1689734342000
    },
    {
        "time": 1689734343000,
        "value": 139.278,
        "_internal_originalTime": 1689734343000
    },
    {
        "time": 1689734344000,
        "value": 139.278,
        "_internal_originalTime": 1689734344000
    },
    {
        "time": 1689734346000,
        "value": 139.274,
        "_internal_originalTime": 1689734346000
    },
    {
        "time": 1689734347000,
        "value": 139.277,
        "_internal_originalTime": 1689734347000
    },
    {
        "time": 1689734350000,
        "value": 139.278,
        "_internal_originalTime": 1689734350000
    },
    {
        "time": 1689734352000,
        "value": 139.277,
        "_internal_originalTime": 1689734352000
    },
    {
        "time": 1689734353000,
        "value": 139.279,
        "_internal_originalTime": 1689734353000
    },
    {
        "time": 1689734355000,
        "value": 139.273,
        "_internal_originalTime": 1689734355000
    },
    {
        "time": 1689734356000,
        "value": 139.273,
        "_internal_originalTime": 1689734356000
    },
    {
        "time": 1689734359000,
        "value": 139.274,
        "_internal_originalTime": 1689734359000
    },
    {
        "time": 1689734360000,
        "value": 139.274,
        "_internal_originalTime": 1689734360000
    },
    {
        "time": 1689734362000,
        "value": 139.279,
        "_internal_originalTime": 1689734362000
    },
    {
        "time": 1689734364000,
        "value": 139.28,
        "_internal_originalTime": 1689734364000
    },
    {
        "time": 1689734366000,
        "value": 139.277,
        "_internal_originalTime": 1689734366000
    },
    {
        "time": 1689734368000,
        "value": 139.281,
        "_internal_originalTime": 1689734368000
    },
    {
        "time": 1689734370000,
        "value": 139.278,
        "_internal_originalTime": 1689734370000
    },
    {
        "time": 1689734371000,
        "value": 139.278,
        "_internal_originalTime": 1689734371000
    },
    {
        "time": 1689734374000,
        "value": 139.272,
        "_internal_originalTime": 1689734374000
    },
    {
        "time": 1689734376000,
        "value": 139.278,
        "_internal_originalTime": 1689734376000
    },
    {
        "time": 1689734377000,
        "value": 139.274,
        "_internal_originalTime": 1689734377000
    },
    {
        "time": 1689734378000,
        "value": 139.288,
        "_internal_originalTime": 1689734378000
    },
    {
        "time": 1689734379000,
        "value": 139.284,
        "_internal_originalTime": 1689734379000
    },
    {
        "time": 1689734380000,
        "value": 139.283,
        "_internal_originalTime": 1689734380000
    },
    {
        "time": 1689734382000,
        "value": 139.28,
        "_internal_originalTime": 1689734382000
    },
    {
        "time": 1689734383000,
        "value": 139.276,
        "_internal_originalTime": 1689734383000
    },
    {
        "time": 1689734384000,
        "value": 139.266,
        "_internal_originalTime": 1689734384000
    },
    {
        "time": 1689734385000,
        "value": 139.27,
        "_internal_originalTime": 1689734385000
    },
    {
        "time": 1689734386000,
        "value": 139.271,
        "_internal_originalTime": 1689734386000
    },
    {
        "time": 1689734387000,
        "value": 139.272,
        "_internal_originalTime": 1689734387000
    },
    {
        "time": 1689734389000,
        "value": 139.266,
        "_internal_originalTime": 1689734389000
    },
    {
        "time": 1689734390000,
        "value": 139.271,
        "_internal_originalTime": 1689734390000
    },
    {
        "time": 1689734392000,
        "value": 139.27,
        "_internal_originalTime": 1689734392000
    },
    {
        "time": 1689734394000,
        "value": 139.262,
        "_internal_originalTime": 1689734394000
    },
    {
        "time": 1689734395000,
        "value": 139.264,
        "_internal_originalTime": 1689734395000
    },
    {
        "time": 1689734396000,
        "value": 139.269,
        "_internal_originalTime": 1689734396000
    },
    {
        "time": 1689734400000,
        "value": 139.266,
        "_internal_originalTime": 1689734400000
    },
    {
        "time": 1689734401000,
        "value": 139.262,
        "_internal_originalTime": 1689734401000
    },
    {
        "time": 1689734402000,
        "value": 139.261,
        "_internal_originalTime": 1689734402000
    },
    {
        "time": 1689734404000,
        "value": 139.261,
        "_internal_originalTime": 1689734404000
    },
    {
        "time": 1689734405000,
        "value": 139.264,
        "_internal_originalTime": 1689734405000
    },
    {
        "time": 1689734406000,
        "value": 139.264,
        "_internal_originalTime": 1689734406000
    },
    {
        "time": 1689734407000,
        "value": 139.262,
        "_internal_originalTime": 1689734407000
    },
    {
        "time": 1689734409000,
        "value": 139.263,
        "_internal_originalTime": 1689734409000
    },
    {
        "time": 1689734410000,
        "value": 139.263,
        "_internal_originalTime": 1689734410000
    },
    {
        "time": 1689734413000,
        "value": 139.264,
        "_internal_originalTime": 1689734413000
    },
    {
        "time": 1689734414000,
        "value": 139.262,
        "_internal_originalTime": 1689734414000
    },
    {
        "time": 1689734415000,
        "value": 139.262,
        "_internal_originalTime": 1689734415000
    },
    {
        "time": 1689734416000,
        "value": 139.262,
        "_internal_originalTime": 1689734416000
    },
    {
        "time": 1689734417000,
        "value": 139.264,
        "_internal_originalTime": 1689734417000
    },
    {
        "time": 1689734419000,
        "value": 139.262,
        "_internal_originalTime": 1689734419000
    },
    {
        "time": 1689734421000,
        "value": 139.263,
        "_internal_originalTime": 1689734421000
    },
    {
        "time": 1689734423000,
        "value": 139.26,
        "_internal_originalTime": 1689734423000
    },
    {
        "time": 1689734424000,
        "value": 139.26,
        "_internal_originalTime": 1689734424000
    },
    {
        "time": 1689734427000,
        "value": 139.26,
        "_internal_originalTime": 1689734427000
    },
    {
        "time": 1689734428000,
        "value": 139.263,
        "_internal_originalTime": 1689734428000
    },
    {
        "time": 1689734429000,
        "value": 139.26,
        "_internal_originalTime": 1689734429000
    },
    {
        "time": 1689734432000,
        "value": 139.263,
        "_internal_originalTime": 1689734432000
    },
    {
        "time": 1689734434000,
        "value": 139.263,
        "_internal_originalTime": 1689734434000
    },
    {
        "time": 1689734436000,
        "value": 139.264,
        "_internal_originalTime": 1689734436000
    },
    {
        "time": 1689734437000,
        "value": 139.263,
        "_internal_originalTime": 1689734437000
    },
    {
        "time": 1689734438000,
        "value": 139.26,
        "_internal_originalTime": 1689734438000
    },
    {
        "time": 1689734440000,
        "value": 139.264,
        "_internal_originalTime": 1689734440000
    },
    {
        "time": 1689734441000,
        "value": 139.263,
        "_internal_originalTime": 1689734441000
    },
    {
        "time": 1689734443000,
        "value": 139.258,
        "_internal_originalTime": 1689734443000
    },
    {
        "time": 1689734444000,
        "value": 139.258,
        "_internal_originalTime": 1689734444000
    },
    {
        "time": 1689734446000,
        "value": 139.257,
        "_internal_originalTime": 1689734446000
    },
    {
        "time": 1689734447000,
        "value": 139.257,
        "_internal_originalTime": 1689734447000
    },
    {
        "time": 1689734450000,
        "value": 139.237,
        "_internal_originalTime": 1689734450000
    },
    {
        "time": 1689734451000,
        "value": 139.24,
        "_internal_originalTime": 1689734451000
    },
    {
        "time": 1689734452000,
        "value": 139.241,
        "_internal_originalTime": 1689734452000
    },
    {
        "time": 1689734454000,
        "value": 139.235,
        "_internal_originalTime": 1689734454000
    },
    {
        "time": 1689734456000,
        "value": 139.236,
        "_internal_originalTime": 1689734456000
    },
    {
        "time": 1689734457000,
        "value": 139.24,
        "_internal_originalTime": 1689734457000
    },
    {
        "time": 1689734458000,
        "value": 139.241,
        "_internal_originalTime": 1689734458000
    },
    {
        "time": 1689734459000,
        "value": 139.241,
        "_internal_originalTime": 1689734459000
    },
    {
        "time": 1689734460000,
        "value": 139.22,
        "_internal_originalTime": 1689734460000
    },
    {
        "time": 1689734461000,
        "value": 139.236,
        "_internal_originalTime": 1689734461000
    },
    {
        "time": 1689734464000,
        "value": 139.245,
        "_internal_originalTime": 1689734464000
    },
    {
        "time": 1689734466000,
        "value": 139.246,
        "_internal_originalTime": 1689734466000
    },
    {
        "time": 1689734467000,
        "value": 139.249,
        "_internal_originalTime": 1689734467000
    },
    {
        "time": 1689734468000,
        "value": 139.244,
        "_internal_originalTime": 1689734468000
    },
    {
        "time": 1689734470000,
        "value": 139.245,
        "_internal_originalTime": 1689734470000
    },
    {
        "time": 1689734471000,
        "value": 139.245,
        "_internal_originalTime": 1689734471000
    },
    {
        "time": 1689734472000,
        "value": 139.245,
        "_internal_originalTime": 1689734472000
    },
    {
        "time": 1689734474000,
        "value": 139.248,
        "_internal_originalTime": 1689734474000
    },
    {
        "time": 1689734475000,
        "value": 139.245,
        "_internal_originalTime": 1689734475000
    },
    {
        "time": 1689734476000,
        "value": 139.248,
        "_internal_originalTime": 1689734476000
    },
    {
        "time": 1689734478000,
        "value": 139.247,
        "_internal_originalTime": 1689734478000
    },
    {
        "time": 1689734479000,
        "value": 139.247,
        "_internal_originalTime": 1689734479000
    },
    {
        "time": 1689734480000,
        "value": 139.248,
        "_internal_originalTime": 1689734480000
    },
    {
        "time": 1689734481000,
        "value": 139.249,
        "_internal_originalTime": 1689734481000
    },
    {
        "time": 1689734482000,
        "value": 139.248,
        "_internal_originalTime": 1689734482000
    },
    {
        "time": 1689734484000,
        "value": 139.248,
        "_internal_originalTime": 1689734484000
    },
    {
        "time": 1689734485000,
        "value": 139.243,
        "_internal_originalTime": 1689734485000
    },
    {
        "time": 1689734487000,
        "value": 139.239,
        "_internal_originalTime": 1689734487000
    },
    {
        "time": 1689734488000,
        "value": 139.238,
        "_internal_originalTime": 1689734488000
    },
    {
        "time": 1689734489000,
        "value": 139.24,
        "_internal_originalTime": 1689734489000
    },
    {
        "time": 1689734493000,
        "value": 139.24,
        "_internal_originalTime": 1689734493000
    },
    {
        "time": 1689734494000,
        "value": 139.241,
        "_internal_originalTime": 1689734494000
    },
    {
        "time": 1689734495000,
        "value": 139.238,
        "_internal_originalTime": 1689734495000
    },
    {
        "time": 1689734497000,
        "value": 139.238,
        "_internal_originalTime": 1689734497000
    },
    {
        "time": 1689734498000,
        "value": 139.241,
        "_internal_originalTime": 1689734498000
    },
    {
        "time": 1689734499000,
        "value": 139.24,
        "_internal_originalTime": 1689734499000
    },
    {
        "time": 1689734500000,
        "value": 139.239,
        "_internal_originalTime": 1689734500000
    },
    {
        "time": 1689734502000,
        "value": 139.237,
        "_internal_originalTime": 1689734502000
    },
    {
        "time": 1689734503000,
        "value": 139.239,
        "_internal_originalTime": 1689734503000
    },
    {
        "time": 1689734504000,
        "value": 139.24,
        "_internal_originalTime": 1689734504000
    },
    {
        "time": 1689734506000,
        "value": 139.236,
        "_internal_originalTime": 1689734506000
    },
    {
        "time": 1689734508000,
        "value": 139.247,
        "_internal_originalTime": 1689734508000
    },
    {
        "time": 1689734509000,
        "value": 139.25,
        "_internal_originalTime": 1689734509000
    },
    {
        "time": 1689734510000,
        "value": 139.246,
        "_internal_originalTime": 1689734510000
    },
    {
        "time": 1689734512000,
        "value": 139.259,
        "_internal_originalTime": 1689734512000
    },
    {
        "time": 1689734513000,
        "value": 139.255,
        "_internal_originalTime": 1689734513000
    },
    {
        "time": 1689734515000,
        "value": 139.255,
        "_internal_originalTime": 1689734515000
    },
    {
        "time": 1689734516000,
        "value": 139.256,
        "_internal_originalTime": 1689734516000
    },
    {
        "time": 1689734518000,
        "value": 139.255,
        "_internal_originalTime": 1689734518000
    },
    {
        "time": 1689734519000,
        "value": 139.256,
        "_internal_originalTime": 1689734519000
    },
    {
        "time": 1689734521000,
        "value": 139.255,
        "_internal_originalTime": 1689734521000
    },
    {
        "time": 1689734523000,
        "value": 139.257,
        "_internal_originalTime": 1689734523000
    },
    {
        "time": 1689734524000,
        "value": 139.256,
        "_internal_originalTime": 1689734524000
    },
    {
        "time": 1689734525000,
        "value": 139.255,
        "_internal_originalTime": 1689734525000
    },
    {
        "time": 1689734526000,
        "value": 139.257,
        "_internal_originalTime": 1689734526000
    },
    {
        "time": 1689734527000,
        "value": 139.254,
        "_internal_originalTime": 1689734527000
    },
    {
        "time": 1689734529000,
        "value": 139.246,
        "_internal_originalTime": 1689734529000
    },
    {
        "time": 1689734530000,
        "value": 139.251,
        "_internal_originalTime": 1689734530000
    },
    {
        "time": 1689734531000,
        "value": 139.252,
        "_internal_originalTime": 1689734531000
    },
    {
        "time": 1689734532000,
        "value": 139.251,
        "_internal_originalTime": 1689734532000
    },
    {
        "time": 1689734535000,
        "value": 139.243,
        "_internal_originalTime": 1689734535000
    },
    {
        "time": 1689734536000,
        "value": 139.245,
        "_internal_originalTime": 1689734536000
    },
    {
        "time": 1689734537000,
        "value": 139.248,
        "_internal_originalTime": 1689734537000
    },
    {
        "time": 1689734539000,
        "value": 139.245,
        "_internal_originalTime": 1689734539000
    },
    {
        "time": 1689734540000,
        "value": 139.25,
        "_internal_originalTime": 1689734540000
    },
    {
        "time": 1689734542000,
        "value": 139.246,
        "_internal_originalTime": 1689734542000
    },
    {
        "time": 1689734544000,
        "value": 139.244,
        "_internal_originalTime": 1689734544000
    },
    {
        "time": 1689734545000,
        "value": 139.242,
        "_internal_originalTime": 1689734545000
    },
    {
        "time": 1689734546000,
        "value": 139.242,
        "_internal_originalTime": 1689734546000
    },
    {
        "time": 1689734548000,
        "value": 139.247,
        "_internal_originalTime": 1689734548000
    },
    {
        "time": 1689734550000,
        "value": 139.245,
        "_internal_originalTime": 1689734550000
    },
    {
        "time": 1689734551000,
        "value": 139.246,
        "_internal_originalTime": 1689734551000
    },
    {
        "time": 1689734552000,
        "value": 139.241,
        "_internal_originalTime": 1689734552000
    },
    {
        "time": 1689734555000,
        "value": 139.24,
        "_internal_originalTime": 1689734555000
    },
    {
        "time": 1689734556000,
        "value": 139.242,
        "_internal_originalTime": 1689734556000
    },
    {
        "time": 1689734557000,
        "value": 139.246,
        "_internal_originalTime": 1689734557000
    },
    {
        "time": 1689734559000,
        "value": 139.243,
        "_internal_originalTime": 1689734559000
    },
    {
        "time": 1689734560000,
        "value": 139.257,
        "_internal_originalTime": 1689734560000
    },
    {
        "time": 1689734561000,
        "value": 139.256,
        "_internal_originalTime": 1689734561000
    },
    {
        "time": 1689734563000,
        "value": 139.256,
        "_internal_originalTime": 1689734563000
    },
    {
        "time": 1689734564000,
        "value": 139.255,
        "_internal_originalTime": 1689734564000
    },
    {
        "time": 1689734565000,
        "value": 139.258,
        "_internal_originalTime": 1689734565000
    },
    {
        "time": 1689734566000,
        "value": 139.257,
        "_internal_originalTime": 1689734566000
    },
    {
        "time": 1689734569000,
        "value": 139.253,
        "_internal_originalTime": 1689734569000
    },
    {
        "time": 1689734571000,
        "value": 139.25,
        "_internal_originalTime": 1689734571000
    },
    {
        "time": 1689734572000,
        "value": 139.254,
        "_internal_originalTime": 1689734572000
    },
    {
        "time": 1689734574000,
        "value": 139.25,
        "_internal_originalTime": 1689734574000
    },
    {
        "time": 1689734576000,
        "value": 139.252,
        "_internal_originalTime": 1689734576000
    },
    {
        "time": 1689734578000,
        "value": 139.254,
        "_internal_originalTime": 1689734578000
    },
    {
        "time": 1689734579000,
        "value": 139.255,
        "_internal_originalTime": 1689734579000
    },
    {
        "time": 1689734580000,
        "value": 139.252,
        "_internal_originalTime": 1689734580000
    },
    {
        "time": 1689734581000,
        "value": 139.256,
        "_internal_originalTime": 1689734581000
    },
    {
        "time": 1689734582000,
        "value": 139.257,
        "_internal_originalTime": 1689734582000
    },
    {
        "time": 1689734584000,
        "value": 139.254,
        "_internal_originalTime": 1689734584000
    },
    {
        "time": 1689734585000,
        "value": 139.259,
        "_internal_originalTime": 1689734585000
    },
    {
        "time": 1689734587000,
        "value": 139.258,
        "_internal_originalTime": 1689734587000
    },
    {
        "time": 1689734588000,
        "value": 139.256,
        "_internal_originalTime": 1689734588000
    },
    {
        "time": 1689734590000,
        "value": 139.256,
        "_internal_originalTime": 1689734590000
    },
    {
        "time": 1689734592000,
        "value": 139.258,
        "_internal_originalTime": 1689734592000
    },
    {
        "time": 1689734593000,
        "value": 139.256,
        "_internal_originalTime": 1689734593000
    },
    {
        "time": 1689734594000,
        "value": 139.256,
        "_internal_originalTime": 1689734594000
    },
    {
        "time": 1689734596000,
        "value": 139.253,
        "_internal_originalTime": 1689734596000
    },
    {
        "time": 1689734599000,
        "value": 139.258,
        "_internal_originalTime": 1689734599000
    },
    {
        "time": 1689734601000,
        "value": 139.257,
        "_internal_originalTime": 1689734601000
    },
    {
        "time": 1689734602000,
        "value": 139.257,
        "_internal_originalTime": 1689734602000
    },
    {
        "time": 1689734603000,
        "value": 139.246,
        "_internal_originalTime": 1689734603000
    },
    {
        "time": 1689734604000,
        "value": 139.243,
        "_internal_originalTime": 1689734604000
    },
    {
        "time": 1689734605000,
        "value": 139.251,
        "_internal_originalTime": 1689734605000
    },
    {
        "time": 1689734607000,
        "value": 139.244,
        "_internal_originalTime": 1689734607000
    },
    {
        "time": 1689734608000,
        "value": 139.25,
        "_internal_originalTime": 1689734608000
    },
    {
        "time": 1689734610000,
        "value": 139.247,
        "_internal_originalTime": 1689734610000
    },
    {
        "time": 1689734611000,
        "value": 139.255,
        "_internal_originalTime": 1689734611000
    },
    {
        "time": 1689734614000,
        "value": 139.256,
        "_internal_originalTime": 1689734614000
    },
    {
        "time": 1689734616000,
        "value": 139.256,
        "_internal_originalTime": 1689734616000
    },
    {
        "time": 1689734617000,
        "value": 139.257,
        "_internal_originalTime": 1689734617000
    },
    {
        "time": 1689734619000,
        "value": 139.254,
        "_internal_originalTime": 1689734619000
    },
    {
        "time": 1689734620000,
        "value": 139.257,
        "_internal_originalTime": 1689734620000
    },
    {
        "time": 1689734621000,
        "value": 139.253,
        "_internal_originalTime": 1689734621000
    },
    {
        "time": 1689734622000,
        "value": 139.256,
        "_internal_originalTime": 1689734622000
    },
    {
        "time": 1689734623000,
        "value": 139.254,
        "_internal_originalTime": 1689734623000
    },
    {
        "time": 1689734624000,
        "value": 139.257,
        "_internal_originalTime": 1689734624000
    },
    {
        "time": 1689734625000,
        "value": 139.255,
        "_internal_originalTime": 1689734625000
    },
    {
        "time": 1689734627000,
        "value": 139.254,
        "_internal_originalTime": 1689734627000
    },
    {
        "time": 1689734629000,
        "value": 139.269,
        "_internal_originalTime": 1689734629000
    },
    {
        "time": 1689734630000,
        "value": 139.259,
        "_internal_originalTime": 1689734630000
    },
    {
        "time": 1689734631000,
        "value": 139.26,
        "_internal_originalTime": 1689734631000
    },
    {
        "time": 1689734632000,
        "value": 139.26,
        "_internal_originalTime": 1689734632000
    },
    {
        "time": 1689734634000,
        "value": 139.248,
        "_internal_originalTime": 1689734634000
    },
    {
        "time": 1689734635000,
        "value": 139.237,
        "_internal_originalTime": 1689734635000
    },
    {
        "time": 1689734636000,
        "value": 139.237,
        "_internal_originalTime": 1689734636000
    },
    {
        "time": 1689734638000,
        "value": 139.24,
        "_internal_originalTime": 1689734638000
    },
    {
        "time": 1689734639000,
        "value": 139.239,
        "_internal_originalTime": 1689734639000
    },
    {
        "time": 1689734640000,
        "value": 139.243,
        "_internal_originalTime": 1689734640000
    },
    {
        "time": 1689734642000,
        "value": 139.24,
        "_internal_originalTime": 1689734642000
    },
    {
        "time": 1689734643000,
        "value": 139.242,
        "_internal_originalTime": 1689734643000
    },
    {
        "time": 1689734645000,
        "value": 139.236,
        "_internal_originalTime": 1689734645000
    },
    {
        "time": 1689734646000,
        "value": 139.24,
        "_internal_originalTime": 1689734646000
    },
    {
        "time": 1689734648000,
        "value": 139.241,
        "_internal_originalTime": 1689734648000
    },
    {
        "time": 1689734649000,
        "value": 139.238,
        "_internal_originalTime": 1689734649000
    },
    {
        "time": 1689734650000,
        "value": 139.238,
        "_internal_originalTime": 1689734650000
    },
    {
        "time": 1689734652000,
        "value": 139.238,
        "_internal_originalTime": 1689734652000
    },
    {
        "time": 1689734653000,
        "value": 139.24,
        "_internal_originalTime": 1689734653000
    },
    {
        "time": 1689734656000,
        "value": 139.23,
        "_internal_originalTime": 1689734656000
    },
    {
        "time": 1689734657000,
        "value": 139.23,
        "_internal_originalTime": 1689734657000
    },
    {
        "time": 1689734658000,
        "value": 139.23,
        "_internal_originalTime": 1689734658000
    },
    {
        "time": 1689734659000,
        "value": 139.232,
        "_internal_originalTime": 1689734659000
    },
    {
        "time": 1689734660000,
        "value": 139.232,
        "_internal_originalTime": 1689734660000
    },
    {
        "time": 1689734661000,
        "value": 139.235,
        "_internal_originalTime": 1689734661000
    },
    {
        "time": 1689734662000,
        "value": 139.237,
        "_internal_originalTime": 1689734662000
    },
    {
        "time": 1689734664000,
        "value": 139.232,
        "_internal_originalTime": 1689734664000
    },
    {
        "time": 1689734665000,
        "value": 139.231,
        "_internal_originalTime": 1689734665000
    },
    {
        "time": 1689734666000,
        "value": 139.228,
        "_internal_originalTime": 1689734666000
    },
    {
        "time": 1689734669000,
        "value": 139.235,
        "_internal_originalTime": 1689734669000
    },
    {
        "time": 1689734670000,
        "value": 139.237,
        "_internal_originalTime": 1689734670000
    },
    {
        "time": 1689734671000,
        "value": 139.228,
        "_internal_originalTime": 1689734671000
    },
    {
        "time": 1689734673000,
        "value": 139.212,
        "_internal_originalTime": 1689734673000
    },
    {
        "time": 1689734674000,
        "value": 139.22,
        "_internal_originalTime": 1689734674000
    },
    {
        "time": 1689734675000,
        "value": 139.216,
        "_internal_originalTime": 1689734675000
    },
    {
        "time": 1689734677000,
        "value": 139.226,
        "_internal_originalTime": 1689734677000
    },
    {
        "time": 1689734678000,
        "value": 139.216,
        "_internal_originalTime": 1689734678000
    },
    {
        "time": 1689734679000,
        "value": 139.217,
        "_internal_originalTime": 1689734679000
    },
    {
        "time": 1689734680000,
        "value": 139.216,
        "_internal_originalTime": 1689734680000
    },
    {
        "time": 1689734681000,
        "value": 139.218,
        "_internal_originalTime": 1689734681000
    },
    {
        "time": 1689734683000,
        "value": 139.221,
        "_internal_originalTime": 1689734683000
    },
    {
        "time": 1689734684000,
        "value": 139.218,
        "_internal_originalTime": 1689734684000
    },
    {
        "time": 1689734685000,
        "value": 139.218,
        "_internal_originalTime": 1689734685000
    },
    {
        "time": 1689734686000,
        "value": 139.225,
        "_internal_originalTime": 1689734686000
    },
    {
        "time": 1689734687000,
        "value": 139.222,
        "_internal_originalTime": 1689734687000
    },
    {
        "time": 1689734689000,
        "value": 139.221,
        "_internal_originalTime": 1689734689000
    },
    {
        "time": 1689734690000,
        "value": 139.224,
        "_internal_originalTime": 1689734690000
    },
    {
        "time": 1689734691000,
        "value": 139.222,
        "_internal_originalTime": 1689734691000
    },
    {
        "time": 1689734692000,
        "value": 139.222,
        "_internal_originalTime": 1689734692000
    },
    {
        "time": 1689734694000,
        "value": 139.224,
        "_internal_originalTime": 1689734694000
    },
    {
        "time": 1689734695000,
        "value": 139.222,
        "_internal_originalTime": 1689734695000
    },
    {
        "time": 1689734696000,
        "value": 139.222,
        "_internal_originalTime": 1689734696000
    },
    {
        "time": 1689734697000,
        "value": 139.221,
        "_internal_originalTime": 1689734697000
    },
    {
        "time": 1689734699000,
        "value": 139.224,
        "_internal_originalTime": 1689734699000
    },
    {
        "time": 1689734700000,
        "value": 139.223,
        "_internal_originalTime": 1689734700000
    },
    {
        "time": 1689734701000,
        "value": 139.224,
        "_internal_originalTime": 1689734701000
    },
    {
        "time": 1689734703000,
        "value": 139.226,
        "_internal_originalTime": 1689734703000
    },
    {
        "time": 1689734704000,
        "value": 139.224,
        "_internal_originalTime": 1689734704000
    },
    {
        "time": 1689734706000,
        "value": 139.225,
        "_internal_originalTime": 1689734706000
    },
    {
        "time": 1689734707000,
        "value": 139.222,
        "_internal_originalTime": 1689734707000
    },
    {
        "time": 1689734708000,
        "value": 139.225,
        "_internal_originalTime": 1689734708000
    },
    {
        "time": 1689734709000,
        "value": 139.222,
        "_internal_originalTime": 1689734709000
    },
    {
        "time": 1689734710000,
        "value": 139.216,
        "_internal_originalTime": 1689734710000
    },
    {
        "time": 1689734711000,
        "value": 139.214,
        "_internal_originalTime": 1689734711000
    },
    {
        "time": 1689734713000,
        "value": 139.22,
        "_internal_originalTime": 1689734713000
    },
    {
        "time": 1689734714000,
        "value": 139.214,
        "_internal_originalTime": 1689734714000
    },
    {
        "time": 1689734715000,
        "value": 139.217,
        "_internal_originalTime": 1689734715000
    },
    {
        "time": 1689734716000,
        "value": 139.218,
        "_internal_originalTime": 1689734716000
    },
    {
        "time": 1689734717000,
        "value": 139.213,
        "_internal_originalTime": 1689734717000
    },
    {
        "time": 1689734719000,
        "value": 139.218,
        "_internal_originalTime": 1689734719000
    },
    {
        "time": 1689734720000,
        "value": 139.221,
        "_internal_originalTime": 1689734720000
    },
    {
        "time": 1689734721000,
        "value": 139.218,
        "_internal_originalTime": 1689734721000
    },
    {
        "time": 1689734722000,
        "value": 139.22,
        "_internal_originalTime": 1689734722000
    },
    {
        "time": 1689734724000,
        "value": 139.216,
        "_internal_originalTime": 1689734724000
    },
    {
        "time": 1689734725000,
        "value": 139.221,
        "_internal_originalTime": 1689734725000
    },
    {
        "time": 1689734726000,
        "value": 139.221,
        "_internal_originalTime": 1689734726000
    },
    {
        "time": 1689734728000,
        "value": 139.218,
        "_internal_originalTime": 1689734728000
    },
    {
        "time": 1689734729000,
        "value": 139.216,
        "_internal_originalTime": 1689734729000
    },
    {
        "time": 1689734730000,
        "value": 139.231,
        "_internal_originalTime": 1689734730000
    },
    {
        "time": 1689734731000,
        "value": 139.23,
        "_internal_originalTime": 1689734731000
    },
    {
        "time": 1689734734000,
        "value": 139.225,
        "_internal_originalTime": 1689734734000
    },
    {
        "time": 1689734735000,
        "value": 139.226,
        "_internal_originalTime": 1689734735000
    },
    {
        "time": 1689734737000,
        "value": 139.225,
        "_internal_originalTime": 1689734737000
    },
    {
        "time": 1689734738000,
        "value": 139.224,
        "_internal_originalTime": 1689734738000
    },
    {
        "time": 1689734740000,
        "value": 139.225,
        "_internal_originalTime": 1689734740000
    },
    {
        "time": 1689734741000,
        "value": 139.224,
        "_internal_originalTime": 1689734741000
    },
    {
        "time": 1689734742000,
        "value": 139.225,
        "_internal_originalTime": 1689734742000
    },
    {
        "time": 1689734745000,
        "value": 139.223,
        "_internal_originalTime": 1689734745000
    },
    {
        "time": 1689734746000,
        "value": 139.221,
        "_internal_originalTime": 1689734746000
    },
    {
        "time": 1689734747000,
        "value": 139.22,
        "_internal_originalTime": 1689734747000
    },
    {
        "time": 1689734749000,
        "value": 139.219,
        "_internal_originalTime": 1689734749000
    },
    {
        "time": 1689734750000,
        "value": 139.216,
        "_internal_originalTime": 1689734750000
    },
    {
        "time": 1689734753000,
        "value": 139.216,
        "_internal_originalTime": 1689734753000
    },
    {
        "time": 1689734754000,
        "value": 139.216,
        "_internal_originalTime": 1689734754000
    },
    {
        "time": 1689734755000,
        "value": 139.216,
        "_internal_originalTime": 1689734755000
    },
    {
        "time": 1689734756000,
        "value": 139.217,
        "_internal_originalTime": 1689734756000
    },
    {
        "time": 1689734758000,
        "value": 139.218,
        "_internal_originalTime": 1689734758000
    },
    {
        "time": 1689734759000,
        "value": 139.216,
        "_internal_originalTime": 1689734759000
    },
    {
        "time": 1689734761000,
        "value": 139.22,
        "_internal_originalTime": 1689734761000
    },
    {
        "time": 1689734762000,
        "value": 139.22,
        "_internal_originalTime": 1689734762000
    },
    {
        "time": 1689734764000,
        "value": 139.217,
        "_internal_originalTime": 1689734764000
    },
    {
        "time": 1689734765000,
        "value": 139.214,
        "_internal_originalTime": 1689734765000
    },
    {
        "time": 1689734766000,
        "value": 139.215,
        "_internal_originalTime": 1689734766000
    },
    {
        "time": 1689734767000,
        "value": 139.216,
        "_internal_originalTime": 1689734767000
    },
    {
        "time": 1689734769000,
        "value": 139.214,
        "_internal_originalTime": 1689734769000
    },
    {
        "time": 1689734770000,
        "value": 139.214,
        "_internal_originalTime": 1689734770000
    },
    {
        "time": 1689734771000,
        "value": 139.216,
        "_internal_originalTime": 1689734771000
    },
    {
        "time": 1689734773000,
        "value": 139.215,
        "_internal_originalTime": 1689734773000
    },
    {
        "time": 1689734774000,
        "value": 139.216,
        "_internal_originalTime": 1689734774000
    },
    {
        "time": 1689734775000,
        "value": 139.217,
        "_internal_originalTime": 1689734775000
    },
    {
        "time": 1689734776000,
        "value": 139.217,
        "_internal_originalTime": 1689734776000
    },
    {
        "time": 1689734777000,
        "value": 139.22,
        "_internal_originalTime": 1689734777000
    },
    {
        "time": 1689734778000,
        "value": 139.217,
        "_internal_originalTime": 1689734778000
    },
    {
        "time": 1689734780000,
        "value": 139.216,
        "_internal_originalTime": 1689734780000
    },
    {
        "time": 1689734781000,
        "value": 139.222,
        "_internal_originalTime": 1689734781000
    },
    {
        "time": 1689734782000,
        "value": 139.216,
        "_internal_originalTime": 1689734782000
    },
    {
        "time": 1689734784000,
        "value": 139.217,
        "_internal_originalTime": 1689734784000
    },
    {
        "time": 1689734785000,
        "value": 139.216,
        "_internal_originalTime": 1689734785000
    },
    {
        "time": 1689734787000,
        "value": 139.217,
        "_internal_originalTime": 1689734787000
    },
    {
        "time": 1689734788000,
        "value": 139.217,
        "_internal_originalTime": 1689734788000
    },
    {
        "time": 1689734789000,
        "value": 139.222,
        "_internal_originalTime": 1689734789000
    },
    {
        "time": 1689734791000,
        "value": 139.222,
        "_internal_originalTime": 1689734791000
    },
    {
        "time": 1689734794000,
        "value": 139.223,
        "_internal_originalTime": 1689734794000
    },
    {
        "time": 1689734796000,
        "value": 139.229,
        "_internal_originalTime": 1689734796000
    },
    {
        "time": 1689734797000,
        "value": 139.232,
        "_internal_originalTime": 1689734797000
    },
    {
        "time": 1689734798000,
        "value": 139.228,
        "_internal_originalTime": 1689734798000
    },
    {
        "time": 1689734799000,
        "value": 139.232,
        "_internal_originalTime": 1689734799000
    },
    {
        "time": 1689734801000,
        "value": 139.227,
        "_internal_originalTime": 1689734801000
    },
    {
        "time": 1689734802000,
        "value": 139.227,
        "_internal_originalTime": 1689734802000
    },
    {
        "time": 1689734803000,
        "value": 139.229,
        "_internal_originalTime": 1689734803000
    },
    {
        "time": 1689734804000,
        "value": 139.231,
        "_internal_originalTime": 1689734804000
    },
    {
        "time": 1689734806000,
        "value": 139.229,
        "_internal_originalTime": 1689734806000
    },
    {
        "time": 1689734809000,
        "value": 139.231,
        "_internal_originalTime": 1689734809000
    },
    {
        "time": 1689734811000,
        "value": 139.228,
        "_internal_originalTime": 1689734811000
    },
    {
        "time": 1689734812000,
        "value": 139.225,
        "_internal_originalTime": 1689734812000
    },
    {
        "time": 1689734814000,
        "value": 139.224,
        "_internal_originalTime": 1689734814000
    },
    {
        "time": 1689734815000,
        "value": 139.225,
        "_internal_originalTime": 1689734815000
    },
    {
        "time": 1689734816000,
        "value": 139.228,
        "_internal_originalTime": 1689734816000
    },
    {
        "time": 1689734818000,
        "value": 139.225,
        "_internal_originalTime": 1689734818000
    },
    {
        "time": 1689734819000,
        "value": 139.229,
        "_internal_originalTime": 1689734819000
    },
    {
        "time": 1689734820000,
        "value": 139.227,
        "_internal_originalTime": 1689734820000
    },
    {
        "time": 1689734821000,
        "value": 139.227,
        "_internal_originalTime": 1689734821000
    },
    {
        "time": 1689734822000,
        "value": 139.23,
        "_internal_originalTime": 1689734822000
    },
    {
        "time": 1689734825000,
        "value": 139.227,
        "_internal_originalTime": 1689734825000
    },
    {
        "time": 1689734826000,
        "value": 139.228,
        "_internal_originalTime": 1689734826000
    },
    {
        "time": 1689734827000,
        "value": 139.232,
        "_internal_originalTime": 1689734827000
    },
    {
        "time": 1689734828000,
        "value": 139.231,
        "_internal_originalTime": 1689734828000
    },
    {
        "time": 1689734830000,
        "value": 139.232,
        "_internal_originalTime": 1689734830000
    },
    {
        "time": 1689734831000,
        "value": 139.239,
        "_internal_originalTime": 1689734831000
    },
    {
        "time": 1689734833000,
        "value": 139.237,
        "_internal_originalTime": 1689734833000
    },
    {
        "time": 1689734834000,
        "value": 139.238,
        "_internal_originalTime": 1689734834000
    },
    {
        "time": 1689734835000,
        "value": 139.234,
        "_internal_originalTime": 1689734835000
    },
    {
        "time": 1689734836000,
        "value": 139.239,
        "_internal_originalTime": 1689734836000
    },
    {
        "time": 1689734837000,
        "value": 139.238,
        "_internal_originalTime": 1689734837000
    },
    {
        "time": 1689734840000,
        "value": 139.24,
        "_internal_originalTime": 1689734840000
    },
    {
        "time": 1689734841000,
        "value": 139.242,
        "_internal_originalTime": 1689734841000
    },
    {
        "time": 1689734842000,
        "value": 139.249,
        "_internal_originalTime": 1689734842000
    },
    {
        "time": 1689734843000,
        "value": 139.244,
        "_internal_originalTime": 1689734843000
    },
    {
        "time": 1689734845000,
        "value": 139.251,
        "_internal_originalTime": 1689734845000
    },
    {
        "time": 1689734848000,
        "value": 139.25,
        "_internal_originalTime": 1689734848000
    },
    {
        "time": 1689734849000,
        "value": 139.246,
        "_internal_originalTime": 1689734849000
    },
    {
        "time": 1689734850000,
        "value": 139.25,
        "_internal_originalTime": 1689734850000
    },
    {
        "time": 1689734851000,
        "value": 139.247,
        "_internal_originalTime": 1689734851000
    },
    {
        "time": 1689734853000,
        "value": 139.247,
        "_internal_originalTime": 1689734853000
    },
    {
        "time": 1689734855000,
        "value": 139.251,
        "_internal_originalTime": 1689734855000
    },
    {
        "time": 1689734857000,
        "value": 139.245,
        "_internal_originalTime": 1689734857000
    },
    {
        "time": 1689734859000,
        "value": 139.247,
        "_internal_originalTime": 1689734859000
    },
    {
        "time": 1689734860000,
        "value": 139.249,
        "_internal_originalTime": 1689734860000
    },
    {
        "time": 1689734862000,
        "value": 139.249,
        "_internal_originalTime": 1689734862000
    },
    {
        "time": 1689734863000,
        "value": 139.25,
        "_internal_originalTime": 1689734863000
    },
    {
        "time": 1689734864000,
        "value": 139.249,
        "_internal_originalTime": 1689734864000
    },
    {
        "time": 1689734865000,
        "value": 139.248,
        "_internal_originalTime": 1689734865000
    },
    {
        "time": 1689734866000,
        "value": 139.249,
        "_internal_originalTime": 1689734866000
    },
    {
        "time": 1689734867000,
        "value": 139.247,
        "_internal_originalTime": 1689734867000
    },
    {
        "time": 1689734869000,
        "value": 139.246,
        "_internal_originalTime": 1689734869000
    },
    {
        "time": 1689734870000,
        "value": 139.248,
        "_internal_originalTime": 1689734870000
    },
    {
        "time": 1689734871000,
        "value": 139.25,
        "_internal_originalTime": 1689734871000
    },
    {
        "time": 1689734872000,
        "value": 139.251,
        "_internal_originalTime": 1689734872000
    },
    {
        "time": 1689734875000,
        "value": 139.252,
        "_internal_originalTime": 1689734875000
    },
    {
        "time": 1689734877000,
        "value": 139.25,
        "_internal_originalTime": 1689734877000
    },
    {
        "time": 1689734880000,
        "value": 139.245,
        "_internal_originalTime": 1689734880000
    },
    {
        "time": 1689734881000,
        "value": 139.249,
        "_internal_originalTime": 1689734881000
    },
    {
        "time": 1689734883000,
        "value": 139.244,
        "_internal_originalTime": 1689734883000
    },
    {
        "time": 1689734884000,
        "value": 139.244,
        "_internal_originalTime": 1689734884000
    },
    {
        "time": 1689734885000,
        "value": 139.244,
        "_internal_originalTime": 1689734885000
    },
    {
        "time": 1689734887000,
        "value": 139.246,
        "_internal_originalTime": 1689734887000
    },
    {
        "time": 1689734888000,
        "value": 139.246,
        "_internal_originalTime": 1689734888000
    },
    {
        "time": 1689734889000,
        "value": 139.247,
        "_internal_originalTime": 1689734889000
    },
    {
        "time": 1689734890000,
        "value": 139.245,
        "_internal_originalTime": 1689734890000
    },
    {
        "time": 1689734892000,
        "value": 139.245,
        "_internal_originalTime": 1689734892000
    },
    {
        "time": 1689734893000,
        "value": 139.246,
        "_internal_originalTime": 1689734893000
    },
    {
        "time": 1689734894000,
        "value": 139.248,
        "_internal_originalTime": 1689734894000
    },
    {
        "time": 1689734896000,
        "value": 139.247,
        "_internal_originalTime": 1689734896000
    },
    {
        "time": 1689734897000,
        "value": 139.253,
        "_internal_originalTime": 1689734897000
    },
    {
        "time": 1689734898000,
        "value": 139.253,
        "_internal_originalTime": 1689734898000
    },
    {
        "time": 1689734899000,
        "value": 139.255,
        "_internal_originalTime": 1689734899000
    },
    {
        "time": 1689734901000,
        "value": 139.256,
        "_internal_originalTime": 1689734901000
    },
    {
        "time": 1689734902000,
        "value": 139.255,
        "_internal_originalTime": 1689734902000
    },
    {
        "time": 1689734903000,
        "value": 139.253,
        "_internal_originalTime": 1689734903000
    },
    {
        "time": 1689734905000,
        "value": 139.25,
        "_internal_originalTime": 1689734905000
    },
    {
        "time": 1689734906000,
        "value": 139.255,
        "_internal_originalTime": 1689734906000
    },
    {
        "time": 1689734908000,
        "value": 139.253,
        "_internal_originalTime": 1689734908000
    },
    {
        "time": 1689734909000,
        "value": 139.254,
        "_internal_originalTime": 1689734909000
    },
    {
        "time": 1689734911000,
        "value": 139.251,
        "_internal_originalTime": 1689734911000
    },
    {
        "time": 1689734912000,
        "value": 139.253,
        "_internal_originalTime": 1689734912000
    },
    {
        "time": 1689734914000,
        "value": 139.254,
        "_internal_originalTime": 1689734914000
    },
    {
        "time": 1689734917000,
        "value": 139.25,
        "_internal_originalTime": 1689734917000
    },
    {
        "time": 1689734920000,
        "value": 139.248,
        "_internal_originalTime": 1689734920000
    },
    {
        "time": 1689734921000,
        "value": 139.247,
        "_internal_originalTime": 1689734921000
    },
    {
        "time": 1689734922000,
        "value": 139.248,
        "_internal_originalTime": 1689734922000
    },
    {
        "time": 1689734924000,
        "value": 139.248,
        "_internal_originalTime": 1689734924000
    },
    {
        "time": 1689734925000,
        "value": 139.25,
        "_internal_originalTime": 1689734925000
    },
    {
        "time": 1689734926000,
        "value": 139.246,
        "_internal_originalTime": 1689734926000
    },
    {
        "time": 1689734928000,
        "value": 139.248,
        "_internal_originalTime": 1689734928000
    },
    {
        "time": 1689734929000,
        "value": 139.25,
        "_internal_originalTime": 1689734929000
    },
    {
        "time": 1689734930000,
        "value": 139.248,
        "_internal_originalTime": 1689734930000
    },
    {
        "time": 1689734932000,
        "value": 139.249,
        "_internal_originalTime": 1689734932000
    },
    {
        "time": 1689734933000,
        "value": 139.253,
        "_internal_originalTime": 1689734933000
    },
    {
        "time": 1689734935000,
        "value": 139.25,
        "_internal_originalTime": 1689734935000
    },
    {
        "time": 1689734936000,
        "value": 139.254,
        "_internal_originalTime": 1689734936000
    },
    {
        "time": 1689734937000,
        "value": 139.251,
        "_internal_originalTime": 1689734937000
    },
    {
        "time": 1689734938000,
        "value": 139.255,
        "_internal_originalTime": 1689734938000
    },
    {
        "time": 1689734939000,
        "value": 139.251,
        "_internal_originalTime": 1689734939000
    },
    {
        "time": 1689734941000,
        "value": 139.251,
        "_internal_originalTime": 1689734941000
    },
    {
        "time": 1689734942000,
        "value": 139.25,
        "_internal_originalTime": 1689734942000
    },
    {
        "time": 1689734943000,
        "value": 139.247,
        "_internal_originalTime": 1689734943000
    },
    {
        "time": 1689734944000,
        "value": 139.248,
        "_internal_originalTime": 1689734944000
    },
    {
        "time": 1689734945000,
        "value": 139.248,
        "_internal_originalTime": 1689734945000
    },
    {
        "time": 1689734947000,
        "value": 139.24,
        "_internal_originalTime": 1689734947000
    },
    {
        "time": 1689734948000,
        "value": 139.238,
        "_internal_originalTime": 1689734948000
    },
    {
        "time": 1689734949000,
        "value": 139.235,
        "_internal_originalTime": 1689734949000
    },
    {
        "time": 1689734950000,
        "value": 139.232,
        "_internal_originalTime": 1689734950000
    },
    {
        "time": 1689734952000,
        "value": 139.236,
        "_internal_originalTime": 1689734952000
    },
    {
        "time": 1689734953000,
        "value": 139.237,
        "_internal_originalTime": 1689734953000
    },
    {
        "time": 1689734955000,
        "value": 139.234,
        "_internal_originalTime": 1689734955000
    },
    {
        "time": 1689734956000,
        "value": 139.231,
        "_internal_originalTime": 1689734956000
    },
    {
        "time": 1689734957000,
        "value": 139.227,
        "_internal_originalTime": 1689734957000
    },
    {
        "time": 1689734958000,
        "value": 139.227,
        "_internal_originalTime": 1689734958000
    },
    {
        "time": 1689734959000,
        "value": 139.226,
        "_internal_originalTime": 1689734959000
    },
    {
        "time": 1689734960000,
        "value": 139.227,
        "_internal_originalTime": 1689734960000
    },
    {
        "time": 1689734962000,
        "value": 139.235,
        "_internal_originalTime": 1689734962000
    },
    {
        "time": 1689734964000,
        "value": 139.235,
        "_internal_originalTime": 1689734964000
    },
    {
        "time": 1689734965000,
        "value": 139.231,
        "_internal_originalTime": 1689734965000
    },
    {
        "time": 1689734966000,
        "value": 139.235,
        "_internal_originalTime": 1689734966000
    },
    {
        "time": 1689734967000,
        "value": 139.237,
        "_internal_originalTime": 1689734967000
    },
    {
        "time": 1689734969000,
        "value": 139.238,
        "_internal_originalTime": 1689734969000
    },
    {
        "time": 1689734970000,
        "value": 139.239,
        "_internal_originalTime": 1689734970000
    },
    {
        "time": 1689734972000,
        "value": 139.238,
        "_internal_originalTime": 1689734972000
    },
    {
        "time": 1689734978000,
        "value": 139.237,
        "_internal_originalTime": 1689734978000
    },
    {
        "time": 1689734980000,
        "value": 139.238,
        "_internal_originalTime": 1689734980000
    },
    {
        "time": 1689734981000,
        "value": 139.236,
        "_internal_originalTime": 1689734981000
    },
    {
        "time": 1689734982000,
        "value": 139.235,
        "_internal_originalTime": 1689734982000
    },
    {
        "time": 1689734984000,
        "value": 139.232,
        "_internal_originalTime": 1689734984000
    },
    {
        "time": 1689734985000,
        "value": 139.235,
        "_internal_originalTime": 1689734985000
    },
    {
        "time": 1689734986000,
        "value": 139.235,
        "_internal_originalTime": 1689734986000
    },
    {
        "time": 1689734987000,
        "value": 139.235,
        "_internal_originalTime": 1689734987000
    },
    {
        "time": 1689734990000,
        "value": 139.234,
        "_internal_originalTime": 1689734990000
    },
    {
        "time": 1689734992000,
        "value": 139.238,
        "_internal_originalTime": 1689734992000
    },
    {
        "time": 1689734993000,
        "value": 139.233,
        "_internal_originalTime": 1689734993000
    },
    {
        "time": 1689734995000,
        "value": 139.231,
        "_internal_originalTime": 1689734995000
    },
    {
        "time": 1689734997000,
        "value": 139.234,
        "_internal_originalTime": 1689734997000
    },
    {
        "time": 1689734998000,
        "value": 139.235,
        "_internal_originalTime": 1689734998000
    },
    {
        "time": 1689735000000,
        "value": 139.238,
        "_internal_originalTime": 1689735000000
    },
    {
        "time": 1689735001000,
        "value": 139.234,
        "_internal_originalTime": 1689735001000
    },
    {
        "time": 1689735003000,
        "value": 139.229,
        "_internal_originalTime": 1689735003000
    },
    {
        "time": 1689735005000,
        "value": 139.235,
        "_internal_originalTime": 1689735005000
    },
    {
        "time": 1689735008000,
        "value": 139.232,
        "_internal_originalTime": 1689735008000
    },
    {
        "time": 1689735009000,
        "value": 139.232,
        "_internal_originalTime": 1689735009000
    },
    {
        "time": 1689735010000,
        "value": 139.237,
        "_internal_originalTime": 1689735010000
    },
    {
        "time": 1689735011000,
        "value": 139.239,
        "_internal_originalTime": 1689735011000
    },
    {
        "time": 1689735013000,
        "value": 139.239,
        "_internal_originalTime": 1689735013000
    },
    {
        "time": 1689735014000,
        "value": 139.234,
        "_internal_originalTime": 1689735014000
    },
    {
        "time": 1689735015000,
        "value": 139.232,
        "_internal_originalTime": 1689735015000
    },
    {
        "time": 1689735016000,
        "value": 139.235,
        "_internal_originalTime": 1689735016000
    },
    {
        "time": 1689735018000,
        "value": 139.238,
        "_internal_originalTime": 1689735018000
    },
    {
        "time": 1689735020000,
        "value": 139.223,
        "_internal_originalTime": 1689735020000
    },
    {
        "time": 1689735022000,
        "value": 139.224,
        "_internal_originalTime": 1689735022000
    },
    {
        "time": 1689735023000,
        "value": 139.22,
        "_internal_originalTime": 1689735023000
    },
    {
        "time": 1689735024000,
        "value": 139.216,
        "_internal_originalTime": 1689735024000
    },
    {
        "time": 1689735025000,
        "value": 139.212,
        "_internal_originalTime": 1689735025000
    },
    {
        "time": 1689735027000,
        "value": 139.215,
        "_internal_originalTime": 1689735027000
    },
    {
        "time": 1689735028000,
        "value": 139.218,
        "_internal_originalTime": 1689735028000
    },
    {
        "time": 1689735030000,
        "value": 139.212,
        "_internal_originalTime": 1689735030000
    },
    {
        "time": 1689735032000,
        "value": 139.216,
        "_internal_originalTime": 1689735032000
    },
    {
        "time": 1689735034000,
        "value": 139.218,
        "_internal_originalTime": 1689735034000
    },
    {
        "time": 1689735035000,
        "value": 139.218,
        "_internal_originalTime": 1689735035000
    },
    {
        "time": 1689735036000,
        "value": 139.215,
        "_internal_originalTime": 1689735036000
    },
    {
        "time": 1689735037000,
        "value": 139.218,
        "_internal_originalTime": 1689735037000
    },
    {
        "time": 1689735039000,
        "value": 139.22,
        "_internal_originalTime": 1689735039000
    },
    {
        "time": 1689735040000,
        "value": 139.22,
        "_internal_originalTime": 1689735040000
    },
    {
        "time": 1689735041000,
        "value": 139.218,
        "_internal_originalTime": 1689735041000
    },
    {
        "time": 1689735043000,
        "value": 139.218,
        "_internal_originalTime": 1689735043000
    },
    {
        "time": 1689735045000,
        "value": 139.214,
        "_internal_originalTime": 1689735045000
    },
    {
        "time": 1689735046000,
        "value": 139.216,
        "_internal_originalTime": 1689735046000
    },
    {
        "time": 1689735047000,
        "value": 139.217,
        "_internal_originalTime": 1689735047000
    },
    {
        "time": 1689735048000,
        "value": 139.218,
        "_internal_originalTime": 1689735048000
    },
    {
        "time": 1689735050000,
        "value": 139.214,
        "_internal_originalTime": 1689735050000
    },
    {
        "time": 1689735052000,
        "value": 139.217,
        "_internal_originalTime": 1689735052000
    },
    {
        "time": 1689735054000,
        "value": 139.217,
        "_internal_originalTime": 1689735054000
    },
    {
        "time": 1689735055000,
        "value": 139.215,
        "_internal_originalTime": 1689735055000
    },
    {
        "time": 1689735057000,
        "value": 139.215,
        "_internal_originalTime": 1689735057000
    },
    {
        "time": 1689735058000,
        "value": 139.212,
        "_internal_originalTime": 1689735058000
    },
    {
        "time": 1689735059000,
        "value": 139.215,
        "_internal_originalTime": 1689735059000
    },
    {
        "time": 1689735060000,
        "value": 139.19,
        "_internal_originalTime": 1689735060000
    },
    {
        "time": 1689735061000,
        "value": 139.217,
        "_internal_originalTime": 1689735061000
    },
    {
        "time": 1689735062000,
        "value": 139.217,
        "_internal_originalTime": 1689735062000
    },
    {
        "time": 1689735064000,
        "value": 139.219,
        "_internal_originalTime": 1689735064000
    },
    {
        "time": 1689735065000,
        "value": 139.224,
        "_internal_originalTime": 1689735065000
    },
    {
        "time": 1689735066000,
        "value": 139.226,
        "_internal_originalTime": 1689735066000
    },
    {
        "time": 1689735067000,
        "value": 139.225,
        "_internal_originalTime": 1689735067000
    },
    {
        "time": 1689735068000,
        "value": 139.226,
        "_internal_originalTime": 1689735068000
    },
    {
        "time": 1689735070000,
        "value": 139.225,
        "_internal_originalTime": 1689735070000
    },
    {
        "time": 1689735075000,
        "value": 139.225,
        "_internal_originalTime": 1689735075000
    },
    {
        "time": 1689735077000,
        "value": 139.225,
        "_internal_originalTime": 1689735077000
    },
    {
        "time": 1689735079000,
        "value": 139.235,
        "_internal_originalTime": 1689735079000
    },
    {
        "time": 1689735080000,
        "value": 139.237,
        "_internal_originalTime": 1689735080000
    },
    {
        "time": 1689735081000,
        "value": 139.227,
        "_internal_originalTime": 1689735081000
    },
    {
        "time": 1689735082000,
        "value": 139.224,
        "_internal_originalTime": 1689735082000
    },
    {
        "time": 1689735083000,
        "value": 139.225,
        "_internal_originalTime": 1689735083000
    },
    {
        "time": 1689735085000,
        "value": 139.221,
        "_internal_originalTime": 1689735085000
    },
    {
        "time": 1689735086000,
        "value": 139.224,
        "_internal_originalTime": 1689735086000
    },
    {
        "time": 1689735088000,
        "value": 139.226,
        "_internal_originalTime": 1689735088000
    },
    {
        "time": 1689735089000,
        "value": 139.224,
        "_internal_originalTime": 1689735089000
    },
    {
        "time": 1689735090000,
        "value": 139.224,
        "_internal_originalTime": 1689735090000
    },
    {
        "time": 1689735091000,
        "value": 139.225,
        "_internal_originalTime": 1689735091000
    },
    {
        "time": 1689735093000,
        "value": 139.224,
        "_internal_originalTime": 1689735093000
    },
    {
        "time": 1689735095000,
        "value": 139.221,
        "_internal_originalTime": 1689735095000
    },
    {
        "time": 1689735096000,
        "value": 139.223,
        "_internal_originalTime": 1689735096000
    },
    {
        "time": 1689735097000,
        "value": 139.222,
        "_internal_originalTime": 1689735097000
    },
    {
        "time": 1689735099000,
        "value": 139.222,
        "_internal_originalTime": 1689735099000
    },
    {
        "time": 1689735101000,
        "value": 139.228,
        "_internal_originalTime": 1689735101000
    },
    {
        "time": 1689735102000,
        "value": 139.222,
        "_internal_originalTime": 1689735102000
    },
    {
        "time": 1689735104000,
        "value": 139.226,
        "_internal_originalTime": 1689735104000
    },
    {
        "time": 1689735105000,
        "value": 139.224,
        "_internal_originalTime": 1689735105000
    },
    {
        "time": 1689735106000,
        "value": 139.226,
        "_internal_originalTime": 1689735106000
    },
    {
        "time": 1689735107000,
        "value": 139.224,
        "_internal_originalTime": 1689735107000
    },
    {
        "time": 1689735109000,
        "value": 139.224,
        "_internal_originalTime": 1689735109000
    },
    {
        "time": 1689735110000,
        "value": 139.228,
        "_internal_originalTime": 1689735110000
    },
    {
        "time": 1689735113000,
        "value": 139.226,
        "_internal_originalTime": 1689735113000
    },
    {
        "time": 1689735115000,
        "value": 139.227,
        "_internal_originalTime": 1689735115000
    },
    {
        "time": 1689735116000,
        "value": 139.226,
        "_internal_originalTime": 1689735116000
    },
    {
        "time": 1689735117000,
        "value": 139.222,
        "_internal_originalTime": 1689735117000
    },
    {
        "time": 1689735118000,
        "value": 139.226,
        "_internal_originalTime": 1689735118000
    },
    {
        "time": 1689735119000,
        "value": 139.222,
        "_internal_originalTime": 1689735119000
    },
    {
        "time": 1689735120000,
        "value": 139.228,
        "_internal_originalTime": 1689735120000
    },
    {
        "time": 1689735121000,
        "value": 139.225,
        "_internal_originalTime": 1689735121000
    },
    {
        "time": 1689735124000,
        "value": 139.224,
        "_internal_originalTime": 1689735124000
    },
    {
        "time": 1689735125000,
        "value": 139.226,
        "_internal_originalTime": 1689735125000
    },
    {
        "time": 1689735127000,
        "value": 139.229,
        "_internal_originalTime": 1689735127000
    },
    {
        "time": 1689735130000,
        "value": 139.226,
        "_internal_originalTime": 1689735130000
    },
    {
        "time": 1689735134000,
        "value": 139.228,
        "_internal_originalTime": 1689735134000
    },
    {
        "time": 1689735135000,
        "value": 139.226,
        "_internal_originalTime": 1689735135000
    },
    {
        "time": 1689735136000,
        "value": 139.222,
        "_internal_originalTime": 1689735136000
    },
    {
        "time": 1689735137000,
        "value": 139.223,
        "_internal_originalTime": 1689735137000
    },
    {
        "time": 1689735139000,
        "value": 139.228,
        "_internal_originalTime": 1689735139000
    },
    {
        "time": 1689735140000,
        "value": 139.228,
        "_internal_originalTime": 1689735140000
    },
    {
        "time": 1689735142000,
        "value": 139.223,
        "_internal_originalTime": 1689735142000
    },
    {
        "time": 1689735144000,
        "value": 139.225,
        "_internal_originalTime": 1689735144000
    },
    {
        "time": 1689735145000,
        "value": 139.227,
        "_internal_originalTime": 1689735145000
    },
    {
        "time": 1689735146000,
        "value": 139.226,
        "_internal_originalTime": 1689735146000
    },
    {
        "time": 1689735148000,
        "value": 139.23,
        "_internal_originalTime": 1689735148000
    },
    {
        "time": 1689735149000,
        "value": 139.227,
        "_internal_originalTime": 1689735149000
    },
    {
        "time": 1689735150000,
        "value": 139.226,
        "_internal_originalTime": 1689735150000
    },
    {
        "time": 1689735151000,
        "value": 139.227,
        "_internal_originalTime": 1689735151000
    },
    {
        "time": 1689735153000,
        "value": 139.227,
        "_internal_originalTime": 1689735153000
    },
    {
        "time": 1689735154000,
        "value": 139.227,
        "_internal_originalTime": 1689735154000
    },
    {
        "time": 1689735155000,
        "value": 139.225,
        "_internal_originalTime": 1689735155000
    },
    {
        "time": 1689735156000,
        "value": 139.226,
        "_internal_originalTime": 1689735156000
    },
    {
        "time": 1689735157000,
        "value": 139.228,
        "_internal_originalTime": 1689735157000
    },
    {
        "time": 1689735160000,
        "value": 139.227,
        "_internal_originalTime": 1689735160000
    },
    {
        "time": 1689735162000,
        "value": 139.229,
        "_internal_originalTime": 1689735162000
    },
    {
        "time": 1689735166000,
        "value": 139.227,
        "_internal_originalTime": 1689735166000
    },
    {
        "time": 1689735168000,
        "value": 139.225,
        "_internal_originalTime": 1689735168000
    },
    {
        "time": 1689735169000,
        "value": 139.224,
        "_internal_originalTime": 1689735169000
    },
    {
        "time": 1689735171000,
        "value": 139.224,
        "_internal_originalTime": 1689735171000
    },
    {
        "time": 1689735172000,
        "value": 139.227,
        "_internal_originalTime": 1689735172000
    },
    {
        "time": 1689735174000,
        "value": 139.23,
        "_internal_originalTime": 1689735174000
    },
    {
        "time": 1689735175000,
        "value": 139.23,
        "_internal_originalTime": 1689735175000
    },
    {
        "time": 1689735176000,
        "value": 139.229,
        "_internal_originalTime": 1689735176000
    },
    {
        "time": 1689735178000,
        "value": 139.229,
        "_internal_originalTime": 1689735178000
    },
    {
        "time": 1689735179000,
        "value": 139.228,
        "_internal_originalTime": 1689735179000
    },
    {
        "time": 1689735181000,
        "value": 139.229,
        "_internal_originalTime": 1689735181000
    },
    {
        "time": 1689735183000,
        "value": 139.228,
        "_internal_originalTime": 1689735183000
    },
    {
        "time": 1689735184000,
        "value": 139.224,
        "_internal_originalTime": 1689735184000
    },
    {
        "time": 1689735185000,
        "value": 139.225,
        "_internal_originalTime": 1689735185000
    },
    {
        "time": 1689735187000,
        "value": 139.224,
        "_internal_originalTime": 1689735187000
    },
    {
        "time": 1689735188000,
        "value": 139.226,
        "_internal_originalTime": 1689735188000
    },
    {
        "time": 1689735190000,
        "value": 139.224,
        "_internal_originalTime": 1689735190000
    },
    {
        "time": 1689735192000,
        "value": 139.222,
        "_internal_originalTime": 1689735192000
    },
    {
        "time": 1689735193000,
        "value": 139.227,
        "_internal_originalTime": 1689735193000
    },
    {
        "time": 1689735194000,
        "value": 139.228,
        "_internal_originalTime": 1689735194000
    },
    {
        "time": 1689735196000,
        "value": 139.225,
        "_internal_originalTime": 1689735196000
    },
    {
        "time": 1689735198000,
        "value": 139.228,
        "_internal_originalTime": 1689735198000
    },
    {
        "time": 1689735200000,
        "value": 139.227,
        "_internal_originalTime": 1689735200000
    },
    {
        "time": 1689735202000,
        "value": 139.225,
        "_internal_originalTime": 1689735202000
    },
    {
        "time": 1689735203000,
        "value": 139.224,
        "_internal_originalTime": 1689735203000
    },
    {
        "time": 1689735205000,
        "value": 139.224,
        "_internal_originalTime": 1689735205000
    },
    {
        "time": 1689735206000,
        "value": 139.226,
        "_internal_originalTime": 1689735206000
    },
    {
        "time": 1689735208000,
        "value": 139.227,
        "_internal_originalTime": 1689735208000
    },
    {
        "time": 1689735209000,
        "value": 139.226,
        "_internal_originalTime": 1689735209000
    },
    {
        "time": 1689735210000,
        "value": 139.231,
        "_internal_originalTime": 1689735210000
    },
    {
        "time": 1689735212000,
        "value": 139.228,
        "_internal_originalTime": 1689735212000
    },
    {
        "time": 1689735214000,
        "value": 139.228,
        "_internal_originalTime": 1689735214000
    },
    {
        "time": 1689735215000,
        "value": 139.224,
        "_internal_originalTime": 1689735215000
    },
    {
        "time": 1689735217000,
        "value": 139.227,
        "_internal_originalTime": 1689735217000
    },
    {
        "time": 1689735218000,
        "value": 139.231,
        "_internal_originalTime": 1689735218000
    },
    {
        "time": 1689735219000,
        "value": 139.23,
        "_internal_originalTime": 1689735219000
    },
    {
        "time": 1689735220000,
        "value": 139.229,
        "_internal_originalTime": 1689735220000
    },
    {
        "time": 1689735222000,
        "value": 139.229,
        "_internal_originalTime": 1689735222000
    },
    {
        "time": 1689735223000,
        "value": 139.229,
        "_internal_originalTime": 1689735223000
    },
    {
        "time": 1689735226000,
        "value": 139.229,
        "_internal_originalTime": 1689735226000
    },
    {
        "time": 1689735227000,
        "value": 139.227,
        "_internal_originalTime": 1689735227000
    },
    {
        "time": 1689735228000,
        "value": 139.227,
        "_internal_originalTime": 1689735228000
    },
    {
        "time": 1689735229000,
        "value": 139.229,
        "_internal_originalTime": 1689735229000
    },
    {
        "time": 1689735231000,
        "value": 139.227,
        "_internal_originalTime": 1689735231000
    },
    {
        "time": 1689735232000,
        "value": 139.226,
        "_internal_originalTime": 1689735232000
    },
    {
        "time": 1689735234000,
        "value": 139.226,
        "_internal_originalTime": 1689735234000
    },
    {
        "time": 1689735235000,
        "value": 139.223,
        "_internal_originalTime": 1689735235000
    },
    {
        "time": 1689735236000,
        "value": 139.224,
        "_internal_originalTime": 1689735236000
    },
    {
        "time": 1689735238000,
        "value": 139.226,
        "_internal_originalTime": 1689735238000
    },
    {
        "time": 1689735240000,
        "value": 139.225,
        "_internal_originalTime": 1689735240000
    },
    {
        "time": 1689735241000,
        "value": 139.226,
        "_internal_originalTime": 1689735241000
    },
    {
        "time": 1689735242000,
        "value": 139.227,
        "_internal_originalTime": 1689735242000
    },
    {
        "time": 1689735243000,
        "value": 139.227,
        "_internal_originalTime": 1689735243000
    },
    {
        "time": 1689735247000,
        "value": 139.226,
        "_internal_originalTime": 1689735247000
    },
    {
        "time": 1689735249000,
        "value": 139.225,
        "_internal_originalTime": 1689735249000
    },
    {
        "time": 1689735250000,
        "value": 139.224,
        "_internal_originalTime": 1689735250000
    },
    {
        "time": 1689735253000,
        "value": 139.227,
        "_internal_originalTime": 1689735253000
    },
    {
        "time": 1689735254000,
        "value": 139.227,
        "_internal_originalTime": 1689735254000
    },
    {
        "time": 1689735255000,
        "value": 139.226,
        "_internal_originalTime": 1689735255000
    },
    {
        "time": 1689735256000,
        "value": 139.224,
        "_internal_originalTime": 1689735256000
    },
    {
        "time": 1689735257000,
        "value": 139.225,
        "_internal_originalTime": 1689735257000
    },
    {
        "time": 1689735259000,
        "value": 139.225,
        "_internal_originalTime": 1689735259000
    },
    {
        "time": 1689735260000,
        "value": 139.224,
        "_internal_originalTime": 1689735260000
    },
    {
        "time": 1689735262000,
        "value": 139.229,
        "_internal_originalTime": 1689735262000
    },
    {
        "time": 1689735264000,
        "value": 139.231,
        "_internal_originalTime": 1689735264000
    },
    {
        "time": 1689735265000,
        "value": 139.228,
        "_internal_originalTime": 1689735265000
    },
    {
        "time": 1689735266000,
        "value": 139.223,
        "_internal_originalTime": 1689735266000
    },
    {
        "time": 1689735268000,
        "value": 139.228,
        "_internal_originalTime": 1689735268000
    },
    {
        "time": 1689735269000,
        "value": 139.226,
        "_internal_originalTime": 1689735269000
    },
    {
        "time": 1689735271000,
        "value": 139.227,
        "_internal_originalTime": 1689735271000
    },
    {
        "time": 1689735272000,
        "value": 139.223,
        "_internal_originalTime": 1689735272000
    },
    {
        "time": 1689735274000,
        "value": 139.227,
        "_internal_originalTime": 1689735274000
    },
    {
        "time": 1689735276000,
        "value": 139.224,
        "_internal_originalTime": 1689735276000
    },
    {
        "time": 1689735279000,
        "value": 139.229,
        "_internal_originalTime": 1689735279000
    },
    {
        "time": 1689735281000,
        "value": 139.225,
        "_internal_originalTime": 1689735281000
    },
    {
        "time": 1689735283000,
        "value": 139.224,
        "_internal_originalTime": 1689735283000
    },
    {
        "time": 1689735284000,
        "value": 139.224,
        "_internal_originalTime": 1689735284000
    },
    {
        "time": 1689735286000,
        "value": 139.23,
        "_internal_originalTime": 1689735286000
    },
    {
        "time": 1689735287000,
        "value": 139.226,
        "_internal_originalTime": 1689735287000
    },
    {
        "time": 1689735289000,
        "value": 139.227,
        "_internal_originalTime": 1689735289000
    },
    {
        "time": 1689735290000,
        "value": 139.226,
        "_internal_originalTime": 1689735290000
    },
    {
        "time": 1689735292000,
        "value": 139.225,
        "_internal_originalTime": 1689735292000
    },
    {
        "time": 1689735293000,
        "value": 139.229,
        "_internal_originalTime": 1689735293000
    },
    {
        "time": 1689735294000,
        "value": 139.229,
        "_internal_originalTime": 1689735294000
    },
    {
        "time": 1689735296000,
        "value": 139.225,
        "_internal_originalTime": 1689735296000
    },
    {
        "time": 1689735297000,
        "value": 139.24,
        "_internal_originalTime": 1689735297000
    },
    {
        "time": 1689735298000,
        "value": 139.241,
        "_internal_originalTime": 1689735298000
    },
    {
        "time": 1689735300000,
        "value": 139.24,
        "_internal_originalTime": 1689735300000
    },
    {
        "time": 1689735301000,
        "value": 139.236,
        "_internal_originalTime": 1689735301000
    },
    {
        "time": 1689735302000,
        "value": 139.234,
        "_internal_originalTime": 1689735302000
    },
    {
        "time": 1689735303000,
        "value": 139.234,
        "_internal_originalTime": 1689735303000
    },
    {
        "time": 1689735304000,
        "value": 139.235,
        "_internal_originalTime": 1689735304000
    },
    {
        "time": 1689735305000,
        "value": 139.234,
        "_internal_originalTime": 1689735305000
    },
    {
        "time": 1689735307000,
        "value": 139.24,
        "_internal_originalTime": 1689735307000
    },
    {
        "time": 1689735309000,
        "value": 139.237,
        "_internal_originalTime": 1689735309000
    },
    {
        "time": 1689735310000,
        "value": 139.237,
        "_internal_originalTime": 1689735310000
    },
    {
        "time": 1689735311000,
        "value": 139.237,
        "_internal_originalTime": 1689735311000
    },
    {
        "time": 1689735312000,
        "value": 139.237,
        "_internal_originalTime": 1689735312000
    },
    {
        "time": 1689735314000,
        "value": 139.232,
        "_internal_originalTime": 1689735314000
    },
    {
        "time": 1689735316000,
        "value": 139.234,
        "_internal_originalTime": 1689735316000
    },
    {
        "time": 1689735318000,
        "value": 139.231,
        "_internal_originalTime": 1689735318000
    },
    {
        "time": 1689735319000,
        "value": 139.226,
        "_internal_originalTime": 1689735319000
    },
    {
        "time": 1689735320000,
        "value": 139.231,
        "_internal_originalTime": 1689735320000
    },
    {
        "time": 1689735322000,
        "value": 139.232,
        "_internal_originalTime": 1689735322000
    },
    {
        "time": 1689735324000,
        "value": 139.233,
        "_internal_originalTime": 1689735324000
    },
    {
        "time": 1689735325000,
        "value": 139.228,
        "_internal_originalTime": 1689735325000
    },
    {
        "time": 1689735326000,
        "value": 139.231,
        "_internal_originalTime": 1689735326000
    },
    {
        "time": 1689735328000,
        "value": 139.224,
        "_internal_originalTime": 1689735328000
    },
    {
        "time": 1689735329000,
        "value": 139.224,
        "_internal_originalTime": 1689735329000
    },
    {
        "time": 1689735330000,
        "value": 139.224,
        "_internal_originalTime": 1689735330000
    },
    {
        "time": 1689735331000,
        "value": 139.229,
        "_internal_originalTime": 1689735331000
    },
    {
        "time": 1689735332000,
        "value": 139.228,
        "_internal_originalTime": 1689735332000
    },
    {
        "time": 1689735334000,
        "value": 139.224,
        "_internal_originalTime": 1689735334000
    },
    {
        "time": 1689735335000,
        "value": 139.228,
        "_internal_originalTime": 1689735335000
    },
    {
        "time": 1689735337000,
        "value": 139.228,
        "_internal_originalTime": 1689735337000
    },
    {
        "time": 1689735339000,
        "value": 139.228,
        "_internal_originalTime": 1689735339000
    },
    {
        "time": 1689735340000,
        "value": 139.224,
        "_internal_originalTime": 1689735340000
    },
    {
        "time": 1689735342000,
        "value": 139.224,
        "_internal_originalTime": 1689735342000
    },
    {
        "time": 1689735345000,
        "value": 139.223,
        "_internal_originalTime": 1689735345000
    },
    {
        "time": 1689735346000,
        "value": 139.224,
        "_internal_originalTime": 1689735346000
    },
    {
        "time": 1689735347000,
        "value": 139.224,
        "_internal_originalTime": 1689735347000
    },
    {
        "time": 1689735349000,
        "value": 139.224,
        "_internal_originalTime": 1689735349000
    },
    {
        "time": 1689735351000,
        "value": 139.232,
        "_internal_originalTime": 1689735351000
    },
    {
        "time": 1689735352000,
        "value": 139.231,
        "_internal_originalTime": 1689735352000
    },
    {
        "time": 1689735354000,
        "value": 139.226,
        "_internal_originalTime": 1689735354000
    },
    {
        "time": 1689735355000,
        "value": 139.227,
        "_internal_originalTime": 1689735355000
    },
    {
        "time": 1689735356000,
        "value": 139.227,
        "_internal_originalTime": 1689735356000
    },
    {
        "time": 1689735358000,
        "value": 139.231,
        "_internal_originalTime": 1689735358000
    },
    {
        "time": 1689735359000,
        "value": 139.232,
        "_internal_originalTime": 1689735359000
    },
    {
        "time": 1689735360000,
        "value": 139.227,
        "_internal_originalTime": 1689735360000
    },
    {
        "time": 1689735361000,
        "value": 139.234,
        "_internal_originalTime": 1689735361000
    },
    {
        "time": 1689735362000,
        "value": 139.238,
        "_internal_originalTime": 1689735362000
    },
    {
        "time": 1689735363000,
        "value": 139.233,
        "_internal_originalTime": 1689735363000
    },
    {
        "time": 1689735364000,
        "value": 139.234,
        "_internal_originalTime": 1689735364000
    },
    {
        "time": 1689735366000,
        "value": 139.238,
        "_internal_originalTime": 1689735366000
    },
    {
        "time": 1689735367000,
        "value": 139.234,
        "_internal_originalTime": 1689735367000
    },
    {
        "time": 1689735370000,
        "value": 139.238,
        "_internal_originalTime": 1689735370000
    },
    {
        "time": 1689735371000,
        "value": 139.238,
        "_internal_originalTime": 1689735371000
    },
    {
        "time": 1689735373000,
        "value": 139.235,
        "_internal_originalTime": 1689735373000
    },
    {
        "time": 1689735375000,
        "value": 139.237,
        "_internal_originalTime": 1689735375000
    },
    {
        "time": 1689735377000,
        "value": 139.234,
        "_internal_originalTime": 1689735377000
    },
    {
        "time": 1689735379000,
        "value": 139.237,
        "_internal_originalTime": 1689735379000
    },
    {
        "time": 1689735380000,
        "value": 139.235,
        "_internal_originalTime": 1689735380000
    },
    {
        "time": 1689735382000,
        "value": 139.227,
        "_internal_originalTime": 1689735382000
    },
    {
        "time": 1689735384000,
        "value": 139.239,
        "_internal_originalTime": 1689735384000
    },
    {
        "time": 1689735385000,
        "value": 139.234,
        "_internal_originalTime": 1689735385000
    },
    {
        "time": 1689735386000,
        "value": 139.245,
        "_internal_originalTime": 1689735386000
    },
    {
        "time": 1689735387000,
        "value": 139.242,
        "_internal_originalTime": 1689735387000
    },
    {
        "time": 1689735388000,
        "value": 139.241,
        "_internal_originalTime": 1689735388000
    },
    {
        "time": 1689735390000,
        "value": 139.234,
        "_internal_originalTime": 1689735390000
    },
    {
        "time": 1689735391000,
        "value": 139.234,
        "_internal_originalTime": 1689735391000
    },
    {
        "time": 1689735392000,
        "value": 139.238,
        "_internal_originalTime": 1689735392000
    },
    {
        "time": 1689735394000,
        "value": 139.24,
        "_internal_originalTime": 1689735394000
    },
    {
        "time": 1689735395000,
        "value": 139.244,
        "_internal_originalTime": 1689735395000
    },
    {
        "time": 1689735396000,
        "value": 139.244,
        "_internal_originalTime": 1689735396000
    },
    {
        "time": 1689735397000,
        "value": 139.247,
        "_internal_originalTime": 1689735397000
    },
    {
        "time": 1689735399000,
        "value": 139.249,
        "_internal_originalTime": 1689735399000
    },
    {
        "time": 1689735400000,
        "value": 139.242,
        "_internal_originalTime": 1689735400000
    },
    {
        "time": 1689735401000,
        "value": 139.25,
        "_internal_originalTime": 1689735401000
    },
    {
        "time": 1689735403000,
        "value": 139.249,
        "_internal_originalTime": 1689735403000
    },
    {
        "time": 1689735404000,
        "value": 139.248,
        "_internal_originalTime": 1689735404000
    },
    {
        "time": 1689735406000,
        "value": 139.248,
        "_internal_originalTime": 1689735406000
    },
    {
        "time": 1689735408000,
        "value": 139.247,
        "_internal_originalTime": 1689735408000
    },
    {
        "time": 1689735409000,
        "value": 139.247,
        "_internal_originalTime": 1689735409000
    },
    {
        "time": 1689735411000,
        "value": 139.251,
        "_internal_originalTime": 1689735411000
    },
    {
        "time": 1689735412000,
        "value": 139.244,
        "_internal_originalTime": 1689735412000
    },
    {
        "time": 1689735413000,
        "value": 139.25,
        "_internal_originalTime": 1689735413000
    },
    {
        "time": 1689735414000,
        "value": 139.244,
        "_internal_originalTime": 1689735414000
    },
    {
        "time": 1689735416000,
        "value": 139.244,
        "_internal_originalTime": 1689735416000
    },
    {
        "time": 1689735417000,
        "value": 139.246,
        "_internal_originalTime": 1689735417000
    },
    {
        "time": 1689735419000,
        "value": 139.242,
        "_internal_originalTime": 1689735419000
    },
    {
        "time": 1689735420000,
        "value": 139.242,
        "_internal_originalTime": 1689735420000
    },
    {
        "time": 1689735421000,
        "value": 139.246,
        "_internal_originalTime": 1689735421000
    },
    {
        "time": 1689735422000,
        "value": 139.246,
        "_internal_originalTime": 1689735422000
    },
    {
        "time": 1689735423000,
        "value": 139.246,
        "_internal_originalTime": 1689735423000
    },
    {
        "time": 1689735424000,
        "value": 139.246,
        "_internal_originalTime": 1689735424000
    },
    {
        "time": 1689735425000,
        "value": 139.245,
        "_internal_originalTime": 1689735425000
    },
    {
        "time": 1689735426000,
        "value": 139.246,
        "_internal_originalTime": 1689735426000
    },
    {
        "time": 1689735428000,
        "value": 139.248,
        "_internal_originalTime": 1689735428000
    },
    {
        "time": 1689735430000,
        "value": 139.251,
        "_internal_originalTime": 1689735430000
    },
    {
        "time": 1689735432000,
        "value": 139.244,
        "_internal_originalTime": 1689735432000
    },
    {
        "time": 1689735433000,
        "value": 139.249,
        "_internal_originalTime": 1689735433000
    },
    {
        "time": 1689735434000,
        "value": 139.25,
        "_internal_originalTime": 1689735434000
    },
    {
        "time": 1689735436000,
        "value": 139.244,
        "_internal_originalTime": 1689735436000
    },
    {
        "time": 1689735437000,
        "value": 139.246,
        "_internal_originalTime": 1689735437000
    },
    {
        "time": 1689735438000,
        "value": 139.247,
        "_internal_originalTime": 1689735438000
    },
    {
        "time": 1689735439000,
        "value": 139.25,
        "_internal_originalTime": 1689735439000
    },
    {
        "time": 1689735440000,
        "value": 139.252,
        "_internal_originalTime": 1689735440000
    },
    {
        "time": 1689735442000,
        "value": 139.249,
        "_internal_originalTime": 1689735442000
    },
    {
        "time": 1689735443000,
        "value": 139.247,
        "_internal_originalTime": 1689735443000
    },
    {
        "time": 1689735444000,
        "value": 139.244,
        "_internal_originalTime": 1689735444000
    },
    {
        "time": 1689735445000,
        "value": 139.243,
        "_internal_originalTime": 1689735445000
    },
    {
        "time": 1689735446000,
        "value": 139.247,
        "_internal_originalTime": 1689735446000
    },
    {
        "time": 1689735448000,
        "value": 139.257,
        "_internal_originalTime": 1689735448000
    },
    {
        "time": 1689735449000,
        "value": 139.253,
        "_internal_originalTime": 1689735449000
    },
    {
        "time": 1689735450000,
        "value": 139.257,
        "_internal_originalTime": 1689735450000
    },
    {
        "time": 1689735451000,
        "value": 139.259,
        "_internal_originalTime": 1689735451000
    },
    {
        "time": 1689735452000,
        "value": 139.259,
        "_internal_originalTime": 1689735452000
    },
    {
        "time": 1689735454000,
        "value": 139.259,
        "_internal_originalTime": 1689735454000
    },
    {
        "time": 1689735455000,
        "value": 139.255,
        "_internal_originalTime": 1689735455000
    },
    {
        "time": 1689735456000,
        "value": 139.256,
        "_internal_originalTime": 1689735456000
    },
    {
        "time": 1689735457000,
        "value": 139.252,
        "_internal_originalTime": 1689735457000
    },
    {
        "time": 1689735458000,
        "value": 139.251,
        "_internal_originalTime": 1689735458000
    },
    {
        "time": 1689735461000,
        "value": 139.252,
        "_internal_originalTime": 1689735461000
    },
    {
        "time": 1689735462000,
        "value": 139.253,
        "_internal_originalTime": 1689735462000
    },
    {
        "time": 1689735463000,
        "value": 139.257,
        "_internal_originalTime": 1689735463000
    },
    {
        "time": 1689735464000,
        "value": 139.258,
        "_internal_originalTime": 1689735464000
    },
    {
        "time": 1689735466000,
        "value": 139.256,
        "_internal_originalTime": 1689735466000
    },
    {
        "time": 1689735468000,
        "value": 139.254,
        "_internal_originalTime": 1689735468000
    },
    {
        "time": 1689735470000,
        "value": 139.255,
        "_internal_originalTime": 1689735470000
    },
    {
        "time": 1689735471000,
        "value": 139.259,
        "_internal_originalTime": 1689735471000
    },
    {
        "time": 1689735473000,
        "value": 139.263,
        "_internal_originalTime": 1689735473000
    },
    {
        "time": 1689735474000,
        "value": 139.26,
        "_internal_originalTime": 1689735474000
    },
    {
        "time": 1689735475000,
        "value": 139.26,
        "_internal_originalTime": 1689735475000
    },
    {
        "time": 1689735476000,
        "value": 139.265,
        "_internal_originalTime": 1689735476000
    },
    {
        "time": 1689735478000,
        "value": 139.267,
        "_internal_originalTime": 1689735478000
    },
    {
        "time": 1689735479000,
        "value": 139.265,
        "_internal_originalTime": 1689735479000
    },
    {
        "time": 1689735480000,
        "value": 139.267,
        "_internal_originalTime": 1689735480000
    },
    {
        "time": 1689735481000,
        "value": 139.267,
        "_internal_originalTime": 1689735481000
    },
    {
        "time": 1689735482000,
        "value": 139.266,
        "_internal_originalTime": 1689735482000
    },
    {
        "time": 1689735483000,
        "value": 139.26,
        "_internal_originalTime": 1689735483000
    },
    {
        "time": 1689735484000,
        "value": 139.263,
        "_internal_originalTime": 1689735484000
    },
    {
        "time": 1689735486000,
        "value": 139.253,
        "_internal_originalTime": 1689735486000
    },
    {
        "time": 1689735487000,
        "value": 139.256,
        "_internal_originalTime": 1689735487000
    },
    {
        "time": 1689735488000,
        "value": 139.248,
        "_internal_originalTime": 1689735488000
    },
    {
        "time": 1689735490000,
        "value": 139.255,
        "_internal_originalTime": 1689735490000
    },
    {
        "time": 1689735491000,
        "value": 139.248,
        "_internal_originalTime": 1689735491000
    },
    {
        "time": 1689735493000,
        "value": 139.242,
        "_internal_originalTime": 1689735493000
    },
    {
        "time": 1689735494000,
        "value": 139.241,
        "_internal_originalTime": 1689735494000
    },
    {
        "time": 1689735496000,
        "value": 139.242,
        "_internal_originalTime": 1689735496000
    },
    {
        "time": 1689735497000,
        "value": 139.242,
        "_internal_originalTime": 1689735497000
    },
    {
        "time": 1689735498000,
        "value": 139.248,
        "_internal_originalTime": 1689735498000
    },
    {
        "time": 1689735499000,
        "value": 139.244,
        "_internal_originalTime": 1689735499000
    },
    {
        "time": 1689735500000,
        "value": 139.243,
        "_internal_originalTime": 1689735500000
    },
    {
        "time": 1689735501000,
        "value": 139.242,
        "_internal_originalTime": 1689735501000
    },
    {
        "time": 1689735502000,
        "value": 139.246,
        "_internal_originalTime": 1689735502000
    },
    {
        "time": 1689735503000,
        "value": 139.244,
        "_internal_originalTime": 1689735503000
    },
    {
        "time": 1689735504000,
        "value": 139.244,
        "_internal_originalTime": 1689735504000
    },
    {
        "time": 1689735505000,
        "value": 139.242,
        "_internal_originalTime": 1689735505000
    },
    {
        "time": 1689735507000,
        "value": 139.256,
        "_internal_originalTime": 1689735507000
    },
    {
        "time": 1689735508000,
        "value": 139.258,
        "_internal_originalTime": 1689735508000
    },
    {
        "time": 1689735509000,
        "value": 139.259,
        "_internal_originalTime": 1689735509000
    },
    {
        "time": 1689735511000,
        "value": 139.26,
        "_internal_originalTime": 1689735511000
    },
    {
        "time": 1689735512000,
        "value": 139.259,
        "_internal_originalTime": 1689735512000
    },
    {
        "time": 1689735513000,
        "value": 139.265,
        "_internal_originalTime": 1689735513000
    },
    {
        "time": 1689735515000,
        "value": 139.266,
        "_internal_originalTime": 1689735515000
    },
    {
        "time": 1689735516000,
        "value": 139.26,
        "_internal_originalTime": 1689735516000
    },
    {
        "time": 1689735517000,
        "value": 139.259,
        "_internal_originalTime": 1689735517000
    },
    {
        "time": 1689735519000,
        "value": 139.266,
        "_internal_originalTime": 1689735519000
    },
    {
        "time": 1689735520000,
        "value": 139.258,
        "_internal_originalTime": 1689735520000
    },
    {
        "time": 1689735521000,
        "value": 139.254,
        "_internal_originalTime": 1689735521000
    },
    {
        "time": 1689735523000,
        "value": 139.256,
        "_internal_originalTime": 1689735523000
    },
    {
        "time": 1689735526000,
        "value": 139.256,
        "_internal_originalTime": 1689735526000
    },
    {
        "time": 1689735529000,
        "value": 139.256,
        "_internal_originalTime": 1689735529000
    },
    {
        "time": 1689735530000,
        "value": 139.256,
        "_internal_originalTime": 1689735530000
    },
    {
        "time": 1689735531000,
        "value": 139.254,
        "_internal_originalTime": 1689735531000
    },
    {
        "time": 1689735533000,
        "value": 139.252,
        "_internal_originalTime": 1689735533000
    },
    {
        "time": 1689735535000,
        "value": 139.258,
        "_internal_originalTime": 1689735535000
    },
    {
        "time": 1689735537000,
        "value": 139.254,
        "_internal_originalTime": 1689735537000
    },
    {
        "time": 1689735538000,
        "value": 139.258,
        "_internal_originalTime": 1689735538000
    },
    {
        "time": 1689735539000,
        "value": 139.255,
        "_internal_originalTime": 1689735539000
    },
    {
        "time": 1689735540000,
        "value": 139.254,
        "_internal_originalTime": 1689735540000
    },
    {
        "time": 1689735541000,
        "value": 139.23,
        "_internal_originalTime": 1689735541000
    },
    {
        "time": 1689735542000,
        "value": 139.255,
        "_internal_originalTime": 1689735542000
    },
    {
        "time": 1689735543000,
        "value": 139.251,
        "_internal_originalTime": 1689735543000
    },
    {
        "time": 1689735544000,
        "value": 139.252,
        "_internal_originalTime": 1689735544000
    },
    {
        "time": 1689735545000,
        "value": 139.255,
        "_internal_originalTime": 1689735545000
    },
    {
        "time": 1689735547000,
        "value": 139.252,
        "_internal_originalTime": 1689735547000
    },
    {
        "time": 1689735548000,
        "value": 139.252,
        "_internal_originalTime": 1689735548000
    },
    {
        "time": 1689735550000,
        "value": 139.249,
        "_internal_originalTime": 1689735550000
    },
    {
        "time": 1689735551000,
        "value": 139.244,
        "_internal_originalTime": 1689735551000
    },
    {
        "time": 1689735552000,
        "value": 139.246,
        "_internal_originalTime": 1689735552000
    },
    {
        "time": 1689735553000,
        "value": 139.249,
        "_internal_originalTime": 1689735553000
    },
    {
        "time": 1689735555000,
        "value": 139.253,
        "_internal_originalTime": 1689735555000
    },
    {
        "time": 1689735556000,
        "value": 139.256,
        "_internal_originalTime": 1689735556000
    },
    {
        "time": 1689735557000,
        "value": 139.252,
        "_internal_originalTime": 1689735557000
    },
    {
        "time": 1689735559000,
        "value": 139.251,
        "_internal_originalTime": 1689735559000
    },
    {
        "time": 1689735560000,
        "value": 139.246,
        "_internal_originalTime": 1689735560000
    },
    {
        "time": 1689735561000,
        "value": 139.257,
        "_internal_originalTime": 1689735561000
    },
    {
        "time": 1689735562000,
        "value": 139.257,
        "_internal_originalTime": 1689735562000
    },
    {
        "time": 1689735565000,
        "value": 139.256,
        "_internal_originalTime": 1689735565000
    },
    {
        "time": 1689735567000,
        "value": 139.254,
        "_internal_originalTime": 1689735567000
    },
    {
        "time": 1689735568000,
        "value": 139.254,
        "_internal_originalTime": 1689735568000
    },
    {
        "time": 1689735573000,
        "value": 139.261,
        "_internal_originalTime": 1689735573000
    },
    {
        "time": 1689735574000,
        "value": 139.26,
        "_internal_originalTime": 1689735574000
    },
    {
        "time": 1689735575000,
        "value": 139.256,
        "_internal_originalTime": 1689735575000
    },
    {
        "time": 1689735576000,
        "value": 139.254,
        "_internal_originalTime": 1689735576000
    },
    {
        "time": 1689735578000,
        "value": 139.255,
        "_internal_originalTime": 1689735578000
    },
    {
        "time": 1689735579000,
        "value": 139.258,
        "_internal_originalTime": 1689735579000
    },
    {
        "time": 1689735580000,
        "value": 139.259,
        "_internal_originalTime": 1689735580000
    },
    {
        "time": 1689735582000,
        "value": 139.257,
        "_internal_originalTime": 1689735582000
    },
    {
        "time": 1689735585000,
        "value": 139.254,
        "_internal_originalTime": 1689735585000
    },
    {
        "time": 1689735586000,
        "value": 139.253,
        "_internal_originalTime": 1689735586000
    },
    {
        "time": 1689735587000,
        "value": 139.253,
        "_internal_originalTime": 1689735587000
    },
    {
        "time": 1689735588000,
        "value": 139.257,
        "_internal_originalTime": 1689735588000
    },
    {
        "time": 1689735590000,
        "value": 139.257,
        "_internal_originalTime": 1689735590000
    },
    {
        "time": 1689735591000,
        "value": 139.253,
        "_internal_originalTime": 1689735591000
    },
    {
        "time": 1689735593000,
        "value": 139.258,
        "_internal_originalTime": 1689735593000
    },
    {
        "time": 1689735594000,
        "value": 139.258,
        "_internal_originalTime": 1689735594000
    },
    {
        "time": 1689735596000,
        "value": 139.249,
        "_internal_originalTime": 1689735596000
    },
    {
        "time": 1689735597000,
        "value": 139.252,
        "_internal_originalTime": 1689735597000
    },
    {
        "time": 1689735599000,
        "value": 139.251,
        "_internal_originalTime": 1689735599000
    },
    {
        "time": 1689735600000,
        "value": 139.244,
        "_internal_originalTime": 1689735600000
    },
    {
        "time": 1689735601000,
        "value": 139.244,
        "_internal_originalTime": 1689735601000
    },
    {
        "time": 1689735603000,
        "value": 139.255,
        "_internal_originalTime": 1689735603000
    },
    {
        "time": 1689735604000,
        "value": 139.252,
        "_internal_originalTime": 1689735604000
    },
    {
        "time": 1689735606000,
        "value": 139.254,
        "_internal_originalTime": 1689735606000
    },
    {
        "time": 1689735608000,
        "value": 139.254,
        "_internal_originalTime": 1689735608000
    },
    {
        "time": 1689735609000,
        "value": 139.255,
        "_internal_originalTime": 1689735609000
    },
    {
        "time": 1689735610000,
        "value": 139.259,
        "_internal_originalTime": 1689735610000
    },
    {
        "time": 1689735612000,
        "value": 139.255,
        "_internal_originalTime": 1689735612000
    },
    {
        "time": 1689735613000,
        "value": 139.259,
        "_internal_originalTime": 1689735613000
    },
    {
        "time": 1689735614000,
        "value": 139.259,
        "_internal_originalTime": 1689735614000
    },
    {
        "time": 1689735616000,
        "value": 139.259,
        "_internal_originalTime": 1689735616000
    },
    {
        "time": 1689735618000,
        "value": 139.259,
        "_internal_originalTime": 1689735618000
    },
    {
        "time": 1689735620000,
        "value": 139.256,
        "_internal_originalTime": 1689735620000
    },
    {
        "time": 1689735623000,
        "value": 139.254,
        "_internal_originalTime": 1689735623000
    },
    {
        "time": 1689735625000,
        "value": 139.26,
        "_internal_originalTime": 1689735625000
    },
    {
        "time": 1689735626000,
        "value": 139.254,
        "_internal_originalTime": 1689735626000
    },
    {
        "time": 1689735628000,
        "value": 139.26,
        "_internal_originalTime": 1689735628000
    },
    {
        "time": 1689735629000,
        "value": 139.252,
        "_internal_originalTime": 1689735629000
    },
    {
        "time": 1689735630000,
        "value": 139.258,
        "_internal_originalTime": 1689735630000
    },
    {
        "time": 1689735632000,
        "value": 139.257,
        "_internal_originalTime": 1689735632000
    },
    {
        "time": 1689735634000,
        "value": 139.26,
        "_internal_originalTime": 1689735634000
    },
    {
        "time": 1689735636000,
        "value": 139.26,
        "_internal_originalTime": 1689735636000
    },
    {
        "time": 1689735638000,
        "value": 139.276,
        "_internal_originalTime": 1689735638000
    },
    {
        "time": 1689735639000,
        "value": 139.278,
        "_internal_originalTime": 1689735639000
    },
    {
        "time": 1689735640000,
        "value": 139.281,
        "_internal_originalTime": 1689735640000
    },
    {
        "time": 1689735641000,
        "value": 139.281,
        "_internal_originalTime": 1689735641000
    },
    {
        "time": 1689735643000,
        "value": 139.286,
        "_internal_originalTime": 1689735643000
    },
    {
        "time": 1689735644000,
        "value": 139.288,
        "_internal_originalTime": 1689735644000
    },
    {
        "time": 1689735645000,
        "value": 139.285,
        "_internal_originalTime": 1689735645000
    },
    {
        "time": 1689735646000,
        "value": 139.286,
        "_internal_originalTime": 1689735646000
    },
    {
        "time": 1689735647000,
        "value": 139.28,
        "_internal_originalTime": 1689735647000
    },
    {
        "time": 1689735649000,
        "value": 139.285,
        "_internal_originalTime": 1689735649000
    },
    {
        "time": 1689735650000,
        "value": 139.284,
        "_internal_originalTime": 1689735650000
    },
    {
        "time": 1689735651000,
        "value": 139.285,
        "_internal_originalTime": 1689735651000
    },
    {
        "time": 1689735652000,
        "value": 139.281,
        "_internal_originalTime": 1689735652000
    },
    {
        "time": 1689735654000,
        "value": 139.283,
        "_internal_originalTime": 1689735654000
    },
    {
        "time": 1689735655000,
        "value": 139.28,
        "_internal_originalTime": 1689735655000
    },
    {
        "time": 1689735656000,
        "value": 139.285,
        "_internal_originalTime": 1689735656000
    },
    {
        "time": 1689735658000,
        "value": 139.283,
        "_internal_originalTime": 1689735658000
    },
    {
        "time": 1689735659000,
        "value": 139.28,
        "_internal_originalTime": 1689735659000
    },
    {
        "time": 1689735660000,
        "value": 139.278,
        "_internal_originalTime": 1689735660000
    },
    {
        "time": 1689735661000,
        "value": 139.276,
        "_internal_originalTime": 1689735661000
    },
    {
        "time": 1689735662000,
        "value": 139.277,
        "_internal_originalTime": 1689735662000
    },
    {
        "time": 1689735664000,
        "value": 139.277,
        "_internal_originalTime": 1689735664000
    },
    {
        "time": 1689735665000,
        "value": 139.293,
        "_internal_originalTime": 1689735665000
    },
    {
        "time": 1689735666000,
        "value": 139.293,
        "_internal_originalTime": 1689735666000
    },
    {
        "time": 1689735668000,
        "value": 139.292,
        "_internal_originalTime": 1689735668000
    },
    {
        "time": 1689735669000,
        "value": 139.29,
        "_internal_originalTime": 1689735669000
    },
    {
        "time": 1689735671000,
        "value": 139.297,
        "_internal_originalTime": 1689735671000
    },
    {
        "time": 1689735673000,
        "value": 139.297,
        "_internal_originalTime": 1689735673000
    },
    {
        "time": 1689735674000,
        "value": 139.297,
        "_internal_originalTime": 1689735674000
    },
    {
        "time": 1689735676000,
        "value": 139.295,
        "_internal_originalTime": 1689735676000
    },
    {
        "time": 1689735678000,
        "value": 139.294,
        "_internal_originalTime": 1689735678000
    },
    {
        "time": 1689735679000,
        "value": 139.295,
        "_internal_originalTime": 1689735679000
    },
    {
        "time": 1689735680000,
        "value": 139.296,
        "_internal_originalTime": 1689735680000
    },
    {
        "time": 1689735682000,
        "value": 139.296,
        "_internal_originalTime": 1689735682000
    },
    {
        "time": 1689735683000,
        "value": 139.295,
        "_internal_originalTime": 1689735683000
    },
    {
        "time": 1689735684000,
        "value": 139.28,
        "_internal_originalTime": 1689735684000
    },
    {
        "time": 1689735685000,
        "value": 139.286,
        "_internal_originalTime": 1689735685000
    },
    {
        "time": 1689735686000,
        "value": 139.282,
        "_internal_originalTime": 1689735686000
    },
    {
        "time": 1689735688000,
        "value": 139.282,
        "_internal_originalTime": 1689735688000
    },
    {
        "time": 1689735689000,
        "value": 139.291,
        "_internal_originalTime": 1689735689000
    },
    {
        "time": 1689735690000,
        "value": 139.284,
        "_internal_originalTime": 1689735690000
    },
    {
        "time": 1689735691000,
        "value": 139.285,
        "_internal_originalTime": 1689735691000
    },
    {
        "time": 1689735692000,
        "value": 139.284,
        "_internal_originalTime": 1689735692000
    },
    {
        "time": 1689735694000,
        "value": 139.287,
        "_internal_originalTime": 1689735694000
    },
    {
        "time": 1689735695000,
        "value": 139.286,
        "_internal_originalTime": 1689735695000
    },
    {
        "time": 1689735696000,
        "value": 139.286,
        "_internal_originalTime": 1689735696000
    },
    {
        "time": 1689735699000,
        "value": 139.282,
        "_internal_originalTime": 1689735699000
    },
    {
        "time": 1689735701000,
        "value": 139.29,
        "_internal_originalTime": 1689735701000
    },
    {
        "time": 1689735703000,
        "value": 139.285,
        "_internal_originalTime": 1689735703000
    },
    {
        "time": 1689735704000,
        "value": 139.274,
        "_internal_originalTime": 1689735704000
    },
    {
        "time": 1689735706000,
        "value": 139.281,
        "_internal_originalTime": 1689735706000
    },
    {
        "time": 1689735707000,
        "value": 139.283,
        "_internal_originalTime": 1689735707000
    },
    {
        "time": 1689735709000,
        "value": 139.283,
        "_internal_originalTime": 1689735709000
    },
    {
        "time": 1689735711000,
        "value": 139.286,
        "_internal_originalTime": 1689735711000
    },
    {
        "time": 1689735712000,
        "value": 139.282,
        "_internal_originalTime": 1689735712000
    },
    {
        "time": 1689735713000,
        "value": 139.286,
        "_internal_originalTime": 1689735713000
    },
    {
        "time": 1689735715000,
        "value": 139.279,
        "_internal_originalTime": 1689735715000
    },
    {
        "time": 1689735716000,
        "value": 139.282,
        "_internal_originalTime": 1689735716000
    },
    {
        "time": 1689735717000,
        "value": 139.28,
        "_internal_originalTime": 1689735717000
    },
    {
        "time": 1689735719000,
        "value": 139.286,
        "_internal_originalTime": 1689735719000
    },
    {
        "time": 1689735720000,
        "value": 139.287,
        "_internal_originalTime": 1689735720000
    },
    {
        "time": 1689735721000,
        "value": 139.279,
        "_internal_originalTime": 1689735721000
    },
    {
        "time": 1689735722000,
        "value": 139.282,
        "_internal_originalTime": 1689735722000
    },
    {
        "time": 1689735723000,
        "value": 139.283,
        "_internal_originalTime": 1689735723000
    },
    {
        "time": 1689735724000,
        "value": 139.282,
        "_internal_originalTime": 1689735724000
    },
    {
        "time": 1689735725000,
        "value": 139.288,
        "_internal_originalTime": 1689735725000
    },
    {
        "time": 1689735727000,
        "value": 139.287,
        "_internal_originalTime": 1689735727000
    },
    {
        "time": 1689735728000,
        "value": 139.289,
        "_internal_originalTime": 1689735728000
    },
    {
        "time": 1689735729000,
        "value": 139.291,
        "_internal_originalTime": 1689735729000
    },
    {
        "time": 1689735731000,
        "value": 139.288,
        "_internal_originalTime": 1689735731000
    },
    {
        "time": 1689735732000,
        "value": 139.293,
        "_internal_originalTime": 1689735732000
    },
    {
        "time": 1689735733000,
        "value": 139.29,
        "_internal_originalTime": 1689735733000
    },
    {
        "time": 1689735735000,
        "value": 139.285,
        "_internal_originalTime": 1689735735000
    },
    {
        "time": 1689735736000,
        "value": 139.284,
        "_internal_originalTime": 1689735736000
    },
    {
        "time": 1689735738000,
        "value": 139.284,
        "_internal_originalTime": 1689735738000
    },
    {
        "time": 1689735739000,
        "value": 139.29,
        "_internal_originalTime": 1689735739000
    },
    {
        "time": 1689735740000,
        "value": 139.285,
        "_internal_originalTime": 1689735740000
    },
    {
        "time": 1689735741000,
        "value": 139.285,
        "_internal_originalTime": 1689735741000
    },
    {
        "time": 1689735742000,
        "value": 139.283,
        "_internal_originalTime": 1689735742000
    },
    {
        "time": 1689735744000,
        "value": 139.285,
        "_internal_originalTime": 1689735744000
    },
    {
        "time": 1689735745000,
        "value": 139.288,
        "_internal_originalTime": 1689735745000
    },
    {
        "time": 1689735746000,
        "value": 139.284,
        "_internal_originalTime": 1689735746000
    },
    {
        "time": 1689735747000,
        "value": 139.288,
        "_internal_originalTime": 1689735747000
    },
    {
        "time": 1689735749000,
        "value": 139.285,
        "_internal_originalTime": 1689735749000
    },
    {
        "time": 1689735750000,
        "value": 139.288,
        "_internal_originalTime": 1689735750000
    },
    {
        "time": 1689735751000,
        "value": 139.293,
        "_internal_originalTime": 1689735751000
    },
    {
        "time": 1689735752000,
        "value": 139.284,
        "_internal_originalTime": 1689735752000
    },
    {
        "time": 1689735754000,
        "value": 139.287,
        "_internal_originalTime": 1689735754000
    },
    {
        "time": 1689735756000,
        "value": 139.285,
        "_internal_originalTime": 1689735756000
    },
    {
        "time": 1689735757000,
        "value": 139.284,
        "_internal_originalTime": 1689735757000
    },
    {
        "time": 1689735759000,
        "value": 139.282,
        "_internal_originalTime": 1689735759000
    },
    {
        "time": 1689735760000,
        "value": 139.284,
        "_internal_originalTime": 1689735760000
    },
    {
        "time": 1689735761000,
        "value": 139.279,
        "_internal_originalTime": 1689735761000
    },
    {
        "time": 1689735763000,
        "value": 139.28,
        "_internal_originalTime": 1689735763000
    },
    {
        "time": 1689735764000,
        "value": 139.282,
        "_internal_originalTime": 1689735764000
    },
    {
        "time": 1689735765000,
        "value": 139.282,
        "_internal_originalTime": 1689735765000
    },
    {
        "time": 1689735767000,
        "value": 139.285,
        "_internal_originalTime": 1689735767000
    },
    {
        "time": 1689735768000,
        "value": 139.285,
        "_internal_originalTime": 1689735768000
    },
    {
        "time": 1689735770000,
        "value": 139.282,
        "_internal_originalTime": 1689735770000
    },
    {
        "time": 1689735772000,
        "value": 139.283,
        "_internal_originalTime": 1689735772000
    },
    {
        "time": 1689735774000,
        "value": 139.28,
        "_internal_originalTime": 1689735774000
    },
    {
        "time": 1689735776000,
        "value": 139.285,
        "_internal_originalTime": 1689735776000
    },
    {
        "time": 1689735777000,
        "value": 139.273,
        "_internal_originalTime": 1689735777000
    },
    {
        "time": 1689735778000,
        "value": 139.275,
        "_internal_originalTime": 1689735778000
    },
    {
        "time": 1689735779000,
        "value": 139.27,
        "_internal_originalTime": 1689735779000
    },
    {
        "time": 1689735780000,
        "value": 139.272,
        "_internal_originalTime": 1689735780000
    },
    {
        "time": 1689735781000,
        "value": 139.272,
        "_internal_originalTime": 1689735781000
    },
    {
        "time": 1689735782000,
        "value": 139.272,
        "_internal_originalTime": 1689735782000
    },
    {
        "time": 1689735783000,
        "value": 139.275,
        "_internal_originalTime": 1689735783000
    },
    {
        "time": 1689735784000,
        "value": 139.275,
        "_internal_originalTime": 1689735784000
    },
    {
        "time": 1689735785000,
        "value": 139.282,
        "_internal_originalTime": 1689735785000
    },
    {
        "time": 1689735787000,
        "value": 139.286,
        "_internal_originalTime": 1689735787000
    },
    {
        "time": 1689735788000,
        "value": 139.292,
        "_internal_originalTime": 1689735788000
    },
    {
        "time": 1689735789000,
        "value": 139.289,
        "_internal_originalTime": 1689735789000
    },
    {
        "time": 1689735791000,
        "value": 139.287,
        "_internal_originalTime": 1689735791000
    },
    {
        "time": 1689735792000,
        "value": 139.291,
        "_internal_originalTime": 1689735792000
    },
    {
        "time": 1689735793000,
        "value": 139.282,
        "_internal_originalTime": 1689735793000
    },
    {
        "time": 1689735794000,
        "value": 139.285,
        "_internal_originalTime": 1689735794000
    },
    {
        "time": 1689735796000,
        "value": 139.282,
        "_internal_originalTime": 1689735796000
    },
    {
        "time": 1689735797000,
        "value": 139.285,
        "_internal_originalTime": 1689735797000
    },
    {
        "time": 1689735798000,
        "value": 139.282,
        "_internal_originalTime": 1689735798000
    },
    {
        "time": 1689735799000,
        "value": 139.282,
        "_internal_originalTime": 1689735799000
    },
    {
        "time": 1689735801000,
        "value": 139.282,
        "_internal_originalTime": 1689735801000
    },
    {
        "time": 1689735802000,
        "value": 139.286,
        "_internal_originalTime": 1689735802000
    },
    {
        "time": 1689735804000,
        "value": 139.287,
        "_internal_originalTime": 1689735804000
    },
    {
        "time": 1689735805000,
        "value": 139.286,
        "_internal_originalTime": 1689735805000
    },
    {
        "time": 1689735807000,
        "value": 139.278,
        "_internal_originalTime": 1689735807000
    },
    {
        "time": 1689735808000,
        "value": 139.28,
        "_internal_originalTime": 1689735808000
    },
    {
        "time": 1689735810000,
        "value": 139.28,
        "_internal_originalTime": 1689735810000
    },
    {
        "time": 1689735811000,
        "value": 139.276,
        "_internal_originalTime": 1689735811000
    },
    {
        "time": 1689735812000,
        "value": 139.276,
        "_internal_originalTime": 1689735812000
    },
    {
        "time": 1689735814000,
        "value": 139.28,
        "_internal_originalTime": 1689735814000
    },
    {
        "time": 1689735816000,
        "value": 139.274,
        "_internal_originalTime": 1689735816000
    },
    {
        "time": 1689735817000,
        "value": 139.278,
        "_internal_originalTime": 1689735817000
    },
    {
        "time": 1689735819000,
        "value": 139.276,
        "_internal_originalTime": 1689735819000
    },
    {
        "time": 1689735820000,
        "value": 139.272,
        "_internal_originalTime": 1689735820000
    },
    {
        "time": 1689735821000,
        "value": 139.277,
        "_internal_originalTime": 1689735821000
    },
    {
        "time": 1689735823000,
        "value": 139.278,
        "_internal_originalTime": 1689735823000
    },
    {
        "time": 1689735824000,
        "value": 139.275,
        "_internal_originalTime": 1689735824000
    },
    {
        "time": 1689735825000,
        "value": 139.27,
        "_internal_originalTime": 1689735825000
    },
    {
        "time": 1689735826000,
        "value": 139.276,
        "_internal_originalTime": 1689735826000
    },
    {
        "time": 1689735827000,
        "value": 139.276,
        "_internal_originalTime": 1689735827000
    },
    {
        "time": 1689735829000,
        "value": 139.284,
        "_internal_originalTime": 1689735829000
    },
    {
        "time": 1689735830000,
        "value": 139.271,
        "_internal_originalTime": 1689735830000
    },
    {
        "time": 1689735831000,
        "value": 139.266,
        "_internal_originalTime": 1689735831000
    },
    {
        "time": 1689735833000,
        "value": 139.258,
        "_internal_originalTime": 1689735833000
    },
    {
        "time": 1689735835000,
        "value": 139.266,
        "_internal_originalTime": 1689735835000
    },
    {
        "time": 1689735836000,
        "value": 139.258,
        "_internal_originalTime": 1689735836000
    },
    {
        "time": 1689735838000,
        "value": 139.261,
        "_internal_originalTime": 1689735838000
    },
    {
        "time": 1689735839000,
        "value": 139.266,
        "_internal_originalTime": 1689735839000
    },
    {
        "time": 1689735840000,
        "value": 139.266,
        "_internal_originalTime": 1689735840000
    },
    {
        "time": 1689735842000,
        "value": 139.265,
        "_internal_originalTime": 1689735842000
    },
    {
        "time": 1689735844000,
        "value": 139.266,
        "_internal_originalTime": 1689735844000
    },
    {
        "time": 1689735845000,
        "value": 139.264,
        "_internal_originalTime": 1689735845000
    },
    {
        "time": 1689735846000,
        "value": 139.266,
        "_internal_originalTime": 1689735846000
    },
    {
        "time": 1689735848000,
        "value": 139.262,
        "_internal_originalTime": 1689735848000
    },
    {
        "time": 1689735850000,
        "value": 139.268,
        "_internal_originalTime": 1689735850000
    },
    {
        "time": 1689735851000,
        "value": 139.267,
        "_internal_originalTime": 1689735851000
    },
    {
        "time": 1689735854000,
        "value": 139.267,
        "_internal_originalTime": 1689735854000
    },
    {
        "time": 1689735856000,
        "value": 139.265,
        "_internal_originalTime": 1689735856000
    },
    {
        "time": 1689735857000,
        "value": 139.268,
        "_internal_originalTime": 1689735857000
    },
    {
        "time": 1689735859000,
        "value": 139.276,
        "_internal_originalTime": 1689735859000
    },
    {
        "time": 1689735860000,
        "value": 139.273,
        "_internal_originalTime": 1689735860000
    },
    {
        "time": 1689735862000,
        "value": 139.272,
        "_internal_originalTime": 1689735862000
    },
    {
        "time": 1689735863000,
        "value": 139.276,
        "_internal_originalTime": 1689735863000
    },
    {
        "time": 1689735865000,
        "value": 139.276,
        "_internal_originalTime": 1689735865000
    },
    {
        "time": 1689735867000,
        "value": 139.269,
        "_internal_originalTime": 1689735867000
    },
    {
        "time": 1689735868000,
        "value": 139.265,
        "_internal_originalTime": 1689735868000
    },
    {
        "time": 1689735869000,
        "value": 139.263,
        "_internal_originalTime": 1689735869000
    },
    {
        "time": 1689735871000,
        "value": 139.263,
        "_internal_originalTime": 1689735871000
    },
    {
        "time": 1689735872000,
        "value": 139.269,
        "_internal_originalTime": 1689735872000
    },
    {
        "time": 1689735873000,
        "value": 139.265,
        "_internal_originalTime": 1689735873000
    },
    {
        "time": 1689735875000,
        "value": 139.264,
        "_internal_originalTime": 1689735875000
    },
    {
        "time": 1689735876000,
        "value": 139.266,
        "_internal_originalTime": 1689735876000
    },
    {
        "time": 1689735877000,
        "value": 139.266,
        "_internal_originalTime": 1689735877000
    },
    {
        "time": 1689735879000,
        "value": 139.264,
        "_internal_originalTime": 1689735879000
    },
    {
        "time": 1689735881000,
        "value": 139.263,
        "_internal_originalTime": 1689735881000
    },
    {
        "time": 1689735882000,
        "value": 139.266,
        "_internal_originalTime": 1689735882000
    },
    {
        "time": 1689735883000,
        "value": 139.261,
        "_internal_originalTime": 1689735883000
    },
    {
        "time": 1689735884000,
        "value": 139.266,
        "_internal_originalTime": 1689735884000
    },
    {
        "time": 1689735885000,
        "value": 139.264,
        "_internal_originalTime": 1689735885000
    },
    {
        "time": 1689735886000,
        "value": 139.267,
        "_internal_originalTime": 1689735886000
    },
    {
        "time": 1689735888000,
        "value": 139.263,
        "_internal_originalTime": 1689735888000
    },
    {
        "time": 1689735889000,
        "value": 139.268,
        "_internal_originalTime": 1689735889000
    },
    {
        "time": 1689735891000,
        "value": 139.262,
        "_internal_originalTime": 1689735891000
    },
    {
        "time": 1689735892000,
        "value": 139.264,
        "_internal_originalTime": 1689735892000
    },
    {
        "time": 1689735893000,
        "value": 139.264,
        "_internal_originalTime": 1689735893000
    },
    {
        "time": 1689735895000,
        "value": 139.264,
        "_internal_originalTime": 1689735895000
    },
    {
        "time": 1689735896000,
        "value": 139.266,
        "_internal_originalTime": 1689735896000
    },
    {
        "time": 1689735897000,
        "value": 139.266,
        "_internal_originalTime": 1689735897000
    },
    {
        "time": 1689735899000,
        "value": 139.267,
        "_internal_originalTime": 1689735899000
    },
    {
        "time": 1689735900000,
        "value": 139.266,
        "_internal_originalTime": 1689735900000
    },
    {
        "time": 1689735901000,
        "value": 139.267,
        "_internal_originalTime": 1689735901000
    },
    {
        "time": 1689735903000,
        "value": 139.261,
        "_internal_originalTime": 1689735903000
    },
    {
        "time": 1689735904000,
        "value": 139.267,
        "_internal_originalTime": 1689735904000
    },
    {
        "time": 1689735905000,
        "value": 139.267,
        "_internal_originalTime": 1689735905000
    },
    {
        "time": 1689735906000,
        "value": 139.262,
        "_internal_originalTime": 1689735906000
    },
    {
        "time": 1689735907000,
        "value": 139.264,
        "_internal_originalTime": 1689735907000
    },
    {
        "time": 1689735909000,
        "value": 139.267,
        "_internal_originalTime": 1689735909000
    },
    {
        "time": 1689735910000,
        "value": 139.263,
        "_internal_originalTime": 1689735910000
    },
    {
        "time": 1689735911000,
        "value": 139.264,
        "_internal_originalTime": 1689735911000
    },
    {
        "time": 1689735913000,
        "value": 139.266,
        "_internal_originalTime": 1689735913000
    },
    {
        "time": 1689735915000,
        "value": 139.266,
        "_internal_originalTime": 1689735915000
    },
    {
        "time": 1689735916000,
        "value": 139.267,
        "_internal_originalTime": 1689735916000
    },
    {
        "time": 1689735918000,
        "value": 139.267,
        "_internal_originalTime": 1689735918000
    },
    {
        "time": 1689735920000,
        "value": 139.265,
        "_internal_originalTime": 1689735920000
    },
    {
        "time": 1689735923000,
        "value": 139.262,
        "_internal_originalTime": 1689735923000
    },
    {
        "time": 1689735925000,
        "value": 139.267,
        "_internal_originalTime": 1689735925000
    },
    {
        "time": 1689735927000,
        "value": 139.267,
        "_internal_originalTime": 1689735927000
    },
    {
        "time": 1689735928000,
        "value": 139.267,
        "_internal_originalTime": 1689735928000
    },
    {
        "time": 1689735929000,
        "value": 139.267,
        "_internal_originalTime": 1689735929000
    },
    {
        "time": 1689735931000,
        "value": 139.266,
        "_internal_originalTime": 1689735931000
    },
    {
        "time": 1689735932000,
        "value": 139.264,
        "_internal_originalTime": 1689735932000
    },
    {
        "time": 1689735933000,
        "value": 139.265,
        "_internal_originalTime": 1689735933000
    },
    {
        "time": 1689735936000,
        "value": 139.268,
        "_internal_originalTime": 1689735936000
    },
    {
        "time": 1689735937000,
        "value": 139.275,
        "_internal_originalTime": 1689735937000
    },
    {
        "time": 1689735939000,
        "value": 139.274,
        "_internal_originalTime": 1689735939000
    },
    {
        "time": 1689735940000,
        "value": 139.276,
        "_internal_originalTime": 1689735940000
    },
    {
        "time": 1689735941000,
        "value": 139.274,
        "_internal_originalTime": 1689735941000
    },
    {
        "time": 1689735942000,
        "value": 139.276,
        "_internal_originalTime": 1689735942000
    },
    {
        "time": 1689735943000,
        "value": 139.278,
        "_internal_originalTime": 1689735943000
    },
    {
        "time": 1689735945000,
        "value": 139.278,
        "_internal_originalTime": 1689735945000
    },
    {
        "time": 1689735946000,
        "value": 139.276,
        "_internal_originalTime": 1689735946000
    },
    {
        "time": 1689735947000,
        "value": 139.275,
        "_internal_originalTime": 1689735947000
    },
    {
        "time": 1689735948000,
        "value": 139.274,
        "_internal_originalTime": 1689735948000
    },
    {
        "time": 1689735950000,
        "value": 139.274,
        "_internal_originalTime": 1689735950000
    },
    {
        "time": 1689735951000,
        "value": 139.277,
        "_internal_originalTime": 1689735951000
    },
    {
        "time": 1689735952000,
        "value": 139.275,
        "_internal_originalTime": 1689735952000
    },
    {
        "time": 1689735954000,
        "value": 139.272,
        "_internal_originalTime": 1689735954000
    },
    {
        "time": 1689735955000,
        "value": 139.278,
        "_internal_originalTime": 1689735955000
    },
    {
        "time": 1689735957000,
        "value": 139.272,
        "_internal_originalTime": 1689735957000
    },
    {
        "time": 1689735958000,
        "value": 139.272,
        "_internal_originalTime": 1689735958000
    },
    {
        "time": 1689735959000,
        "value": 139.279,
        "_internal_originalTime": 1689735959000
    },
    {
        "time": 1689735960000,
        "value": 139.274,
        "_internal_originalTime": 1689735960000
    },
    {
        "time": 1689735961000,
        "value": 139.278,
        "_internal_originalTime": 1689735961000
    },
    {
        "time": 1689735963000,
        "value": 139.277,
        "_internal_originalTime": 1689735963000
    },
    {
        "time": 1689735964000,
        "value": 139.274,
        "_internal_originalTime": 1689735964000
    },
    {
        "time": 1689735965000,
        "value": 139.274,
        "_internal_originalTime": 1689735965000
    },
    {
        "time": 1689735967000,
        "value": 139.292,
        "_internal_originalTime": 1689735967000
    },
    {
        "time": 1689735969000,
        "value": 139.285,
        "_internal_originalTime": 1689735969000
    },
    {
        "time": 1689735975000,
        "value": 139.282,
        "_internal_originalTime": 1689735975000
    },
    {
        "time": 1689735978000,
        "value": 139.288,
        "_internal_originalTime": 1689735978000
    },
    {
        "time": 1689735979000,
        "value": 139.288,
        "_internal_originalTime": 1689735979000
    },
    {
        "time": 1689735980000,
        "value": 139.292,
        "_internal_originalTime": 1689735980000
    },
    {
        "time": 1689735982000,
        "value": 139.289,
        "_internal_originalTime": 1689735982000
    },
    {
        "time": 1689735984000,
        "value": 139.292,
        "_internal_originalTime": 1689735984000
    },
    {
        "time": 1689735986000,
        "value": 139.291,
        "_internal_originalTime": 1689735986000
    },
    {
        "time": 1689735987000,
        "value": 139.29,
        "_internal_originalTime": 1689735987000
    },
    {
        "time": 1689735988000,
        "value": 139.295,
        "_internal_originalTime": 1689735988000
    },
    {
        "time": 1689735990000,
        "value": 139.29,
        "_internal_originalTime": 1689735990000
    },
    {
        "time": 1689735992000,
        "value": 139.286,
        "_internal_originalTime": 1689735992000
    },
    {
        "time": 1689735994000,
        "value": 139.286,
        "_internal_originalTime": 1689735994000
    },
    {
        "time": 1689735996000,
        "value": 139.288,
        "_internal_originalTime": 1689735996000
    },
    {
        "time": 1689735997000,
        "value": 139.288,
        "_internal_originalTime": 1689735997000
    },
    {
        "time": 1689736000000,
        "value": 139.283,
        "_internal_originalTime": 1689736000000
    },
    {
        "time": 1689736001000,
        "value": 139.286,
        "_internal_originalTime": 1689736001000
    },
    {
        "time": 1689736003000,
        "value": 139.288,
        "_internal_originalTime": 1689736003000
    },
    {
        "time": 1689736004000,
        "value": 139.287,
        "_internal_originalTime": 1689736004000
    },
    {
        "time": 1689736005000,
        "value": 139.289,
        "_internal_originalTime": 1689736005000
    },
    {
        "time": 1689736009000,
        "value": 139.285,
        "_internal_originalTime": 1689736009000
    },
    {
        "time": 1689736010000,
        "value": 139.284,
        "_internal_originalTime": 1689736010000
    },
    {
        "time": 1689736011000,
        "value": 139.286,
        "_internal_originalTime": 1689736011000
    },
    {
        "time": 1689736014000,
        "value": 139.285,
        "_internal_originalTime": 1689736014000
    },
    {
        "time": 1689736016000,
        "value": 139.285,
        "_internal_originalTime": 1689736016000
    },
    {
        "time": 1689736017000,
        "value": 139.28,
        "_internal_originalTime": 1689736017000
    },
    {
        "time": 1689736019000,
        "value": 139.285,
        "_internal_originalTime": 1689736019000
    },
    {
        "time": 1689736021000,
        "value": 139.275,
        "_internal_originalTime": 1689736021000
    },
    {
        "time": 1689736023000,
        "value": 139.275,
        "_internal_originalTime": 1689736023000
    },
    {
        "time": 1689736024000,
        "value": 139.276,
        "_internal_originalTime": 1689736024000
    },
    {
        "time": 1689736026000,
        "value": 139.274,
        "_internal_originalTime": 1689736026000
    },
    {
        "time": 1689736028000,
        "value": 139.279,
        "_internal_originalTime": 1689736028000
    },
    {
        "time": 1689736030000,
        "value": 139.274,
        "_internal_originalTime": 1689736030000
    },
    {
        "time": 1689736034000,
        "value": 139.276,
        "_internal_originalTime": 1689736034000
    },
    {
        "time": 1689736035000,
        "value": 139.276,
        "_internal_originalTime": 1689736035000
    },
    {
        "time": 1689736036000,
        "value": 139.276,
        "_internal_originalTime": 1689736036000
    },
    {
        "time": 1689736037000,
        "value": 139.276,
        "_internal_originalTime": 1689736037000
    },
    {
        "time": 1689736039000,
        "value": 139.276,
        "_internal_originalTime": 1689736039000
    },
    {
        "time": 1689736040000,
        "value": 139.279,
        "_internal_originalTime": 1689736040000
    },
    {
        "time": 1689736041000,
        "value": 139.276,
        "_internal_originalTime": 1689736041000
    },
    {
        "time": 1689736044000,
        "value": 139.288,
        "_internal_originalTime": 1689736044000
    },
    {
        "time": 1689736045000,
        "value": 139.281,
        "_internal_originalTime": 1689736045000
    },
    {
        "time": 1689736046000,
        "value": 139.282,
        "_internal_originalTime": 1689736046000
    },
    {
        "time": 1689736048000,
        "value": 139.283,
        "_internal_originalTime": 1689736048000
    },
    {
        "time": 1689736049000,
        "value": 139.284,
        "_internal_originalTime": 1689736049000
    },
    {
        "time": 1689736051000,
        "value": 139.287,
        "_internal_originalTime": 1689736051000
    },
    {
        "time": 1689736053000,
        "value": 139.287,
        "_internal_originalTime": 1689736053000
    },
    {
        "time": 1689736054000,
        "value": 139.283,
        "_internal_originalTime": 1689736054000
    },
    {
        "time": 1689736056000,
        "value": 139.283,
        "_internal_originalTime": 1689736056000
    },
    {
        "time": 1689736057000,
        "value": 139.282,
        "_internal_originalTime": 1689736057000
    },
    {
        "time": 1689736058000,
        "value": 139.278,
        "_internal_originalTime": 1689736058000
    },
    {
        "time": 1689736059000,
        "value": 139.274,
        "_internal_originalTime": 1689736059000
    },
    {
        "time": 1689736061000,
        "value": 139.279,
        "_internal_originalTime": 1689736061000
    },
    {
        "time": 1689736062000,
        "value": 139.274,
        "_internal_originalTime": 1689736062000
    },
    {
        "time": 1689736063000,
        "value": 139.274,
        "_internal_originalTime": 1689736063000
    },
    {
        "time": 1689736064000,
        "value": 139.274,
        "_internal_originalTime": 1689736064000
    },
    {
        "time": 1689736065000,
        "value": 139.274,
        "_internal_originalTime": 1689736065000
    },
    {
        "time": 1689736070000,
        "value": 139.28,
        "_internal_originalTime": 1689736070000
    },
    {
        "time": 1689736072000,
        "value": 139.285,
        "_internal_originalTime": 1689736072000
    },
    {
        "time": 1689736073000,
        "value": 139.281,
        "_internal_originalTime": 1689736073000
    },
    {
        "time": 1689736075000,
        "value": 139.285,
        "_internal_originalTime": 1689736075000
    },
    {
        "time": 1689736077000,
        "value": 139.283,
        "_internal_originalTime": 1689736077000
    },
    {
        "time": 1689736078000,
        "value": 139.286,
        "_internal_originalTime": 1689736078000
    },
    {
        "time": 1689736079000,
        "value": 139.285,
        "_internal_originalTime": 1689736079000
    },
    {
        "time": 1689736080000,
        "value": 139.286,
        "_internal_originalTime": 1689736080000
    },
    {
        "time": 1689736081000,
        "value": 139.282,
        "_internal_originalTime": 1689736081000
    },
    {
        "time": 1689736082000,
        "value": 139.285,
        "_internal_originalTime": 1689736082000
    },
    {
        "time": 1689736084000,
        "value": 139.285,
        "_internal_originalTime": 1689736084000
    },
    {
        "time": 1689736085000,
        "value": 139.282,
        "_internal_originalTime": 1689736085000
    },
    {
        "time": 1689736086000,
        "value": 139.285,
        "_internal_originalTime": 1689736086000
    },
    {
        "time": 1689736088000,
        "value": 139.285,
        "_internal_originalTime": 1689736088000
    },
    {
        "time": 1689736089000,
        "value": 139.278,
        "_internal_originalTime": 1689736089000
    },
    {
        "time": 1689736092000,
        "value": 139.282,
        "_internal_originalTime": 1689736092000
    },
    {
        "time": 1689736093000,
        "value": 139.284,
        "_internal_originalTime": 1689736093000
    },
    {
        "time": 1689736095000,
        "value": 139.282,
        "_internal_originalTime": 1689736095000
    },
    {
        "time": 1689736096000,
        "value": 139.284,
        "_internal_originalTime": 1689736096000
    },
    {
        "time": 1689736098000,
        "value": 139.287,
        "_internal_originalTime": 1689736098000
    },
    {
        "time": 1689736099000,
        "value": 139.287,
        "_internal_originalTime": 1689736099000
    },
    {
        "time": 1689736100000,
        "value": 139.286,
        "_internal_originalTime": 1689736100000
    },
    {
        "time": 1689736102000,
        "value": 139.277,
        "_internal_originalTime": 1689736102000
    },
    {
        "time": 1689736103000,
        "value": 139.273,
        "_internal_originalTime": 1689736103000
    },
    {
        "time": 1689736104000,
        "value": 139.273,
        "_internal_originalTime": 1689736104000
    },
    {
        "time": 1689736105000,
        "value": 139.275,
        "_internal_originalTime": 1689736105000
    },
    {
        "time": 1689736107000,
        "value": 139.276,
        "_internal_originalTime": 1689736107000
    },
    {
        "time": 1689736109000,
        "value": 139.278,
        "_internal_originalTime": 1689736109000
    },
    {
        "time": 1689736110000,
        "value": 139.274,
        "_internal_originalTime": 1689736110000
    },
    {
        "time": 1689736111000,
        "value": 139.274,
        "_internal_originalTime": 1689736111000
    },
    {
        "time": 1689736112000,
        "value": 139.278,
        "_internal_originalTime": 1689736112000
    },
    {
        "time": 1689736114000,
        "value": 139.272,
        "_internal_originalTime": 1689736114000
    },
    {
        "time": 1689736115000,
        "value": 139.276,
        "_internal_originalTime": 1689736115000
    },
    {
        "time": 1689736116000,
        "value": 139.275,
        "_internal_originalTime": 1689736116000
    },
    {
        "time": 1689736117000,
        "value": 139.276,
        "_internal_originalTime": 1689736117000
    },
    {
        "time": 1689736119000,
        "value": 139.272,
        "_internal_originalTime": 1689736119000
    },
    {
        "time": 1689736120000,
        "value": 139.276,
        "_internal_originalTime": 1689736120000
    },
    {
        "time": 1689736121000,
        "value": 139.278,
        "_internal_originalTime": 1689736121000
    },
    {
        "time": 1689736124000,
        "value": 139.273,
        "_internal_originalTime": 1689736124000
    },
    {
        "time": 1689736125000,
        "value": 139.272,
        "_internal_originalTime": 1689736125000
    },
    {
        "time": 1689736126000,
        "value": 139.272,
        "_internal_originalTime": 1689736126000
    },
    {
        "time": 1689736128000,
        "value": 139.267,
        "_internal_originalTime": 1689736128000
    },
    {
        "time": 1689736129000,
        "value": 139.262,
        "_internal_originalTime": 1689736129000
    },
    {
        "time": 1689736130000,
        "value": 139.264,
        "_internal_originalTime": 1689736130000
    },
    {
        "time": 1689736132000,
        "value": 139.266,
        "_internal_originalTime": 1689736132000
    },
    {
        "time": 1689736134000,
        "value": 139.264,
        "_internal_originalTime": 1689736134000
    },
    {
        "time": 1689736136000,
        "value": 139.267,
        "_internal_originalTime": 1689736136000
    },
    {
        "time": 1689736138000,
        "value": 139.267,
        "_internal_originalTime": 1689736138000
    },
    {
        "time": 1689736139000,
        "value": 139.267,
        "_internal_originalTime": 1689736139000
    },
    {
        "time": 1689736140000,
        "value": 139.262,
        "_internal_originalTime": 1689736140000
    },
    {
        "time": 1689736143000,
        "value": 139.268,
        "_internal_originalTime": 1689736143000
    },
    {
        "time": 1689736144000,
        "value": 139.264,
        "_internal_originalTime": 1689736144000
    },
    {
        "time": 1689736146000,
        "value": 139.267,
        "_internal_originalTime": 1689736146000
    },
    {
        "time": 1689736147000,
        "value": 139.265,
        "_internal_originalTime": 1689736147000
    },
    {
        "time": 1689736148000,
        "value": 139.262,
        "_internal_originalTime": 1689736148000
    },
    {
        "time": 1689736149000,
        "value": 139.262,
        "_internal_originalTime": 1689736149000
    },
    {
        "time": 1689736151000,
        "value": 139.268,
        "_internal_originalTime": 1689736151000
    },
    {
        "time": 1689736152000,
        "value": 139.262,
        "_internal_originalTime": 1689736152000
    },
    {
        "time": 1689736154000,
        "value": 139.268,
        "_internal_originalTime": 1689736154000
    },
    {
        "time": 1689736155000,
        "value": 139.266,
        "_internal_originalTime": 1689736155000
    },
    {
        "time": 1689736158000,
        "value": 139.266,
        "_internal_originalTime": 1689736158000
    },
    {
        "time": 1689736160000,
        "value": 139.266,
        "_internal_originalTime": 1689736160000
    },
    {
        "time": 1689736161000,
        "value": 139.267,
        "_internal_originalTime": 1689736161000
    },
    {
        "time": 1689736162000,
        "value": 139.268,
        "_internal_originalTime": 1689736162000
    },
    {
        "time": 1689736164000,
        "value": 139.267,
        "_internal_originalTime": 1689736164000
    },
    {
        "time": 1689736165000,
        "value": 139.261,
        "_internal_originalTime": 1689736165000
    },
    {
        "time": 1689736166000,
        "value": 139.265,
        "_internal_originalTime": 1689736166000
    },
    {
        "time": 1689736168000,
        "value": 139.261,
        "_internal_originalTime": 1689736168000
    },
    {
        "time": 1689736169000,
        "value": 139.264,
        "_internal_originalTime": 1689736169000
    },
    {
        "time": 1689736170000,
        "value": 139.262,
        "_internal_originalTime": 1689736170000
    },
    {
        "time": 1689736171000,
        "value": 139.265,
        "_internal_originalTime": 1689736171000
    },
    {
        "time": 1689736172000,
        "value": 139.267,
        "_internal_originalTime": 1689736172000
    },
    {
        "time": 1689736175000,
        "value": 139.267,
        "_internal_originalTime": 1689736175000
    },
    {
        "time": 1689736176000,
        "value": 139.269,
        "_internal_originalTime": 1689736176000
    },
    {
        "time": 1689736177000,
        "value": 139.264,
        "_internal_originalTime": 1689736177000
    },
    {
        "time": 1689736179000,
        "value": 139.265,
        "_internal_originalTime": 1689736179000
    },
    {
        "time": 1689736180000,
        "value": 139.267,
        "_internal_originalTime": 1689736180000
    },
    {
        "time": 1689736182000,
        "value": 139.267,
        "_internal_originalTime": 1689736182000
    },
    {
        "time": 1689736183000,
        "value": 139.261,
        "_internal_originalTime": 1689736183000
    },
    {
        "time": 1689736184000,
        "value": 139.265,
        "_internal_originalTime": 1689736184000
    },
    {
        "time": 1689736185000,
        "value": 139.265,
        "_internal_originalTime": 1689736185000
    },
    {
        "time": 1689736186000,
        "value": 139.268,
        "_internal_originalTime": 1689736186000
    },
    {
        "time": 1689736188000,
        "value": 139.268,
        "_internal_originalTime": 1689736188000
    },
    {
        "time": 1689736189000,
        "value": 139.262,
        "_internal_originalTime": 1689736189000
    },
    {
        "time": 1689736191000,
        "value": 139.268,
        "_internal_originalTime": 1689736191000
    },
    {
        "time": 1689736192000,
        "value": 139.268,
        "_internal_originalTime": 1689736192000
    },
    {
        "time": 1689736194000,
        "value": 139.264,
        "_internal_originalTime": 1689736194000
    },
    {
        "time": 1689736195000,
        "value": 139.262,
        "_internal_originalTime": 1689736195000
    },
    {
        "time": 1689736198000,
        "value": 139.262,
        "_internal_originalTime": 1689736198000
    },
    {
        "time": 1689736200000,
        "value": 139.265,
        "_internal_originalTime": 1689736200000
    },
    {
        "time": 1689736202000,
        "value": 139.264,
        "_internal_originalTime": 1689736202000
    },
    {
        "time": 1689736203000,
        "value": 139.266,
        "_internal_originalTime": 1689736203000
    },
    {
        "time": 1689736204000,
        "value": 139.268,
        "_internal_originalTime": 1689736204000
    },
    {
        "time": 1689736206000,
        "value": 139.266,
        "_internal_originalTime": 1689736206000
    },
    {
        "time": 1689736207000,
        "value": 139.265,
        "_internal_originalTime": 1689736207000
    },
    {
        "time": 1689736209000,
        "value": 139.275,
        "_internal_originalTime": 1689736209000
    },
    {
        "time": 1689736210000,
        "value": 139.276,
        "_internal_originalTime": 1689736210000
    },
    {
        "time": 1689736211000,
        "value": 139.269,
        "_internal_originalTime": 1689736211000
    },
    {
        "time": 1689736213000,
        "value": 139.266,
        "_internal_originalTime": 1689736213000
    },
    {
        "time": 1689736214000,
        "value": 139.257,
        "_internal_originalTime": 1689736214000
    },
    {
        "time": 1689736215000,
        "value": 139.255,
        "_internal_originalTime": 1689736215000
    },
    {
        "time": 1689736216000,
        "value": 139.254,
        "_internal_originalTime": 1689736216000
    },
    {
        "time": 1689736217000,
        "value": 139.256,
        "_internal_originalTime": 1689736217000
    },
    {
        "time": 1689736219000,
        "value": 139.256,
        "_internal_originalTime": 1689736219000
    },
    {
        "time": 1689736220000,
        "value": 139.247,
        "_internal_originalTime": 1689736220000
    },
    {
        "time": 1689736221000,
        "value": 139.251,
        "_internal_originalTime": 1689736221000
    },
    {
        "time": 1689736222000,
        "value": 139.25,
        "_internal_originalTime": 1689736222000
    },
    {
        "time": 1689736224000,
        "value": 139.25,
        "_internal_originalTime": 1689736224000
    },
    {
        "time": 1689736226000,
        "value": 139.242,
        "_internal_originalTime": 1689736226000
    },
    {
        "time": 1689736227000,
        "value": 139.246,
        "_internal_originalTime": 1689736227000
    },
    {
        "time": 1689736228000,
        "value": 139.243,
        "_internal_originalTime": 1689736228000
    },
    {
        "time": 1689736230000,
        "value": 139.244,
        "_internal_originalTime": 1689736230000
    },
    {
        "time": 1689736232000,
        "value": 139.242,
        "_internal_originalTime": 1689736232000
    },
    {
        "time": 1689736233000,
        "value": 139.248,
        "_internal_originalTime": 1689736233000
    },
    {
        "time": 1689736235000,
        "value": 139.245,
        "_internal_originalTime": 1689736235000
    },
    {
        "time": 1689736236000,
        "value": 139.246,
        "_internal_originalTime": 1689736236000
    },
    {
        "time": 1689736237000,
        "value": 139.246,
        "_internal_originalTime": 1689736237000
    },
    {
        "time": 1689736239000,
        "value": 139.248,
        "_internal_originalTime": 1689736239000
    },
    {
        "time": 1689736240000,
        "value": 139.248,
        "_internal_originalTime": 1689736240000
    },
    {
        "time": 1689736241000,
        "value": 139.245,
        "_internal_originalTime": 1689736241000
    },
    {
        "time": 1689736243000,
        "value": 139.245,
        "_internal_originalTime": 1689736243000
    },
    {
        "time": 1689736244000,
        "value": 139.246,
        "_internal_originalTime": 1689736244000
    },
    {
        "time": 1689736245000,
        "value": 139.244,
        "_internal_originalTime": 1689736245000
    },
    {
        "time": 1689736246000,
        "value": 139.245,
        "_internal_originalTime": 1689736246000
    },
    {
        "time": 1689736248000,
        "value": 139.248,
        "_internal_originalTime": 1689736248000
    },
    {
        "time": 1689736250000,
        "value": 139.242,
        "_internal_originalTime": 1689736250000
    },
    {
        "time": 1689736251000,
        "value": 139.248,
        "_internal_originalTime": 1689736251000
    },
    {
        "time": 1689736253000,
        "value": 139.244,
        "_internal_originalTime": 1689736253000
    },
    {
        "time": 1689736255000,
        "value": 139.247,
        "_internal_originalTime": 1689736255000
    },
    {
        "time": 1689736257000,
        "value": 139.242,
        "_internal_originalTime": 1689736257000
    },
    {
        "time": 1689736258000,
        "value": 139.248,
        "_internal_originalTime": 1689736258000
    },
    {
        "time": 1689736261000,
        "value": 139.243,
        "_internal_originalTime": 1689736261000
    },
    {
        "time": 1689736263000,
        "value": 139.236,
        "_internal_originalTime": 1689736263000
    },
    {
        "time": 1689736264000,
        "value": 139.241,
        "_internal_originalTime": 1689736264000
    },
    {
        "time": 1689736265000,
        "value": 139.242,
        "_internal_originalTime": 1689736265000
    },
    {
        "time": 1689736266000,
        "value": 139.236,
        "_internal_originalTime": 1689736266000
    },
    {
        "time": 1689736267000,
        "value": 139.236,
        "_internal_originalTime": 1689736267000
    },
    {
        "time": 1689736269000,
        "value": 139.236,
        "_internal_originalTime": 1689736269000
    },
    {
        "time": 1689736270000,
        "value": 139.241,
        "_internal_originalTime": 1689736270000
    },
    {
        "time": 1689736271000,
        "value": 139.241,
        "_internal_originalTime": 1689736271000
    },
    {
        "time": 1689736273000,
        "value": 139.236,
        "_internal_originalTime": 1689736273000
    },
    {
        "time": 1689736274000,
        "value": 139.241,
        "_internal_originalTime": 1689736274000
    },
    {
        "time": 1689736276000,
        "value": 139.236,
        "_internal_originalTime": 1689736276000
    },
    {
        "time": 1689736278000,
        "value": 139.237,
        "_internal_originalTime": 1689736278000
    },
    {
        "time": 1689736279000,
        "value": 139.237,
        "_internal_originalTime": 1689736279000
    },
    {
        "time": 1689736280000,
        "value": 139.236,
        "_internal_originalTime": 1689736280000
    },
    {
        "time": 1689736281000,
        "value": 139.236,
        "_internal_originalTime": 1689736281000
    },
    {
        "time": 1689736282000,
        "value": 139.24,
        "_internal_originalTime": 1689736282000
    },
    {
        "time": 1689736284000,
        "value": 139.237,
        "_internal_originalTime": 1689736284000
    },
    {
        "time": 1689736285000,
        "value": 139.237,
        "_internal_originalTime": 1689736285000
    },
    {
        "time": 1689736287000,
        "value": 139.232,
        "_internal_originalTime": 1689736287000
    },
    {
        "time": 1689736288000,
        "value": 139.234,
        "_internal_originalTime": 1689736288000
    },
    {
        "time": 1689736289000,
        "value": 139.235,
        "_internal_originalTime": 1689736289000
    },
    {
        "time": 1689736292000,
        "value": 139.238,
        "_internal_originalTime": 1689736292000
    },
    {
        "time": 1689736294000,
        "value": 139.236,
        "_internal_originalTime": 1689736294000
    },
    {
        "time": 1689736296000,
        "value": 139.234,
        "_internal_originalTime": 1689736296000
    },
    {
        "time": 1689736298000,
        "value": 139.242,
        "_internal_originalTime": 1689736298000
    },
    {
        "time": 1689736299000,
        "value": 139.234,
        "_internal_originalTime": 1689736299000
    },
    {
        "time": 1689736300000,
        "value": 139.241,
        "_internal_originalTime": 1689736300000
    },
    {
        "time": 1689736302000,
        "value": 139.234,
        "_internal_originalTime": 1689736302000
    },
    {
        "time": 1689736303000,
        "value": 139.236,
        "_internal_originalTime": 1689736303000
    },
    {
        "time": 1689736304000,
        "value": 139.236,
        "_internal_originalTime": 1689736304000
    },
    {
        "time": 1689736305000,
        "value": 139.236,
        "_internal_originalTime": 1689736305000
    },
    {
        "time": 1689736306000,
        "value": 139.24,
        "_internal_originalTime": 1689736306000
    },
    {
        "time": 1689736308000,
        "value": 139.236,
        "_internal_originalTime": 1689736308000
    },
    {
        "time": 1689736309000,
        "value": 139.24,
        "_internal_originalTime": 1689736309000
    },
    {
        "time": 1689736312000,
        "value": 139.235,
        "_internal_originalTime": 1689736312000
    },
    {
        "time": 1689736313000,
        "value": 139.238,
        "_internal_originalTime": 1689736313000
    },
    {
        "time": 1689736315000,
        "value": 139.232,
        "_internal_originalTime": 1689736315000
    },
    {
        "time": 1689736316000,
        "value": 139.238,
        "_internal_originalTime": 1689736316000
    },
    {
        "time": 1689736318000,
        "value": 139.236,
        "_internal_originalTime": 1689736318000
    },
    {
        "time": 1689736320000,
        "value": 139.21,
        "_internal_originalTime": 1689736320000
    },
    {
        "time": 1689736321000,
        "value": 139.237,
        "_internal_originalTime": 1689736321000
    },
    {
        "time": 1689736322000,
        "value": 139.234,
        "_internal_originalTime": 1689736322000
    },
    {
        "time": 1689736323000,
        "value": 139.236,
        "_internal_originalTime": 1689736323000
    },
    {
        "time": 1689736324000,
        "value": 139.236,
        "_internal_originalTime": 1689736324000
    },
    {
        "time": 1689736326000,
        "value": 139.239,
        "_internal_originalTime": 1689736326000
    },
    {
        "time": 1689736327000,
        "value": 139.239,
        "_internal_originalTime": 1689736327000
    },
    {
        "time": 1689736328000,
        "value": 139.234,
        "_internal_originalTime": 1689736328000
    },
    {
        "time": 1689736330000,
        "value": 139.236,
        "_internal_originalTime": 1689736330000
    },
    {
        "time": 1689736332000,
        "value": 139.235,
        "_internal_originalTime": 1689736332000
    },
    {
        "time": 1689736333000,
        "value": 139.236,
        "_internal_originalTime": 1689736333000
    },
    {
        "time": 1689736334000,
        "value": 139.234,
        "_internal_originalTime": 1689736334000
    },
    {
        "time": 1689736335000,
        "value": 139.236,
        "_internal_originalTime": 1689736335000
    },
    {
        "time": 1689736336000,
        "value": 139.235,
        "_internal_originalTime": 1689736336000
    },
    {
        "time": 1689736338000,
        "value": 139.233,
        "_internal_originalTime": 1689736338000
    },
    {
        "time": 1689736339000,
        "value": 139.234,
        "_internal_originalTime": 1689736339000
    },
    {
        "time": 1689736340000,
        "value": 139.234,
        "_internal_originalTime": 1689736340000
    },
    {
        "time": 1689736341000,
        "value": 139.234,
        "_internal_originalTime": 1689736341000
    },
    {
        "time": 1689736343000,
        "value": 139.238,
        "_internal_originalTime": 1689736343000
    },
    {
        "time": 1689736344000,
        "value": 139.234,
        "_internal_originalTime": 1689736344000
    },
    {
        "time": 1689736345000,
        "value": 139.232,
        "_internal_originalTime": 1689736345000
    },
    {
        "time": 1689736347000,
        "value": 139.239,
        "_internal_originalTime": 1689736347000
    },
    {
        "time": 1689736349000,
        "value": 139.236,
        "_internal_originalTime": 1689736349000
    },
    {
        "time": 1689736351000,
        "value": 139.234,
        "_internal_originalTime": 1689736351000
    },
    {
        "time": 1689736352000,
        "value": 139.237,
        "_internal_originalTime": 1689736352000
    },
    {
        "time": 1689736353000,
        "value": 139.241,
        "_internal_originalTime": 1689736353000
    },
    {
        "time": 1689736357000,
        "value": 139.241,
        "_internal_originalTime": 1689736357000
    },
    {
        "time": 1689736359000,
        "value": 139.241,
        "_internal_originalTime": 1689736359000
    },
    {
        "time": 1689736360000,
        "value": 139.235,
        "_internal_originalTime": 1689736360000
    },
    {
        "time": 1689736361000,
        "value": 139.236,
        "_internal_originalTime": 1689736361000
    },
    {
        "time": 1689736362000,
        "value": 139.236,
        "_internal_originalTime": 1689736362000
    },
    {
        "time": 1689736364000,
        "value": 139.236,
        "_internal_originalTime": 1689736364000
    },
    {
        "time": 1689736365000,
        "value": 139.236,
        "_internal_originalTime": 1689736365000
    },
    {
        "time": 1689736366000,
        "value": 139.241,
        "_internal_originalTime": 1689736366000
    },
    {
        "time": 1689736367000,
        "value": 139.238,
        "_internal_originalTime": 1689736367000
    },
    {
        "time": 1689736369000,
        "value": 139.237,
        "_internal_originalTime": 1689736369000
    },
    {
        "time": 1689736370000,
        "value": 139.24,
        "_internal_originalTime": 1689736370000
    },
    {
        "time": 1689736371000,
        "value": 139.241,
        "_internal_originalTime": 1689736371000
    },
    {
        "time": 1689736372000,
        "value": 139.241,
        "_internal_originalTime": 1689736372000
    },
    {
        "time": 1689736373000,
        "value": 139.236,
        "_internal_originalTime": 1689736373000
    },
    {
        "time": 1689736375000,
        "value": 139.226,
        "_internal_originalTime": 1689736375000
    },
    {
        "time": 1689736376000,
        "value": 139.224,
        "_internal_originalTime": 1689736376000
    },
    {
        "time": 1689736378000,
        "value": 139.228,
        "_internal_originalTime": 1689736378000
    },
    {
        "time": 1689736379000,
        "value": 139.218,
        "_internal_originalTime": 1689736379000
    },
    {
        "time": 1689736380000,
        "value": 139.225,
        "_internal_originalTime": 1689736380000
    },
    {
        "time": 1689736381000,
        "value": 139.224,
        "_internal_originalTime": 1689736381000
    },
    {
        "time": 1689736383000,
        "value": 139.225,
        "_internal_originalTime": 1689736383000
    },
    {
        "time": 1689736384000,
        "value": 139.225,
        "_internal_originalTime": 1689736384000
    },
    {
        "time": 1689736385000,
        "value": 139.223,
        "_internal_originalTime": 1689736385000
    },
    {
        "time": 1689736386000,
        "value": 139.223,
        "_internal_originalTime": 1689736386000
    },
    {
        "time": 1689736387000,
        "value": 139.226,
        "_internal_originalTime": 1689736387000
    },
    {
        "time": 1689736389000,
        "value": 139.226,
        "_internal_originalTime": 1689736389000
    },
    {
        "time": 1689736390000,
        "value": 139.227,
        "_internal_originalTime": 1689736390000
    },
    {
        "time": 1689736392000,
        "value": 139.224,
        "_internal_originalTime": 1689736392000
    },
    {
        "time": 1689736394000,
        "value": 139.227,
        "_internal_originalTime": 1689736394000
    },
    {
        "time": 1689736395000,
        "value": 139.231,
        "_internal_originalTime": 1689736395000
    },
    {
        "time": 1689736396000,
        "value": 139.231,
        "_internal_originalTime": 1689736396000
    },
    {
        "time": 1689736397000,
        "value": 139.226,
        "_internal_originalTime": 1689736397000
    },
    {
        "time": 1689736399000,
        "value": 139.232,
        "_internal_originalTime": 1689736399000
    },
    {
        "time": 1689736400000,
        "value": 139.228,
        "_internal_originalTime": 1689736400000
    },
    {
        "time": 1689736401000,
        "value": 139.225,
        "_internal_originalTime": 1689736401000
    },
    {
        "time": 1689736402000,
        "value": 139.225,
        "_internal_originalTime": 1689736402000
    },
    {
        "time": 1689736404000,
        "value": 139.228,
        "_internal_originalTime": 1689736404000
    },
    {
        "time": 1689736405000,
        "value": 139.223,
        "_internal_originalTime": 1689736405000
    },
    {
        "time": 1689736407000,
        "value": 139.229,
        "_internal_originalTime": 1689736407000
    },
    {
        "time": 1689736408000,
        "value": 139.229,
        "_internal_originalTime": 1689736408000
    },
    {
        "time": 1689736410000,
        "value": 139.222,
        "_internal_originalTime": 1689736410000
    },
    {
        "time": 1689736411000,
        "value": 139.226,
        "_internal_originalTime": 1689736411000
    },
    {
        "time": 1689736412000,
        "value": 139.225,
        "_internal_originalTime": 1689736412000
    },
    {
        "time": 1689736413000,
        "value": 139.225,
        "_internal_originalTime": 1689736413000
    },
    {
        "time": 1689736414000,
        "value": 139.226,
        "_internal_originalTime": 1689736414000
    },
    {
        "time": 1689736416000,
        "value": 139.229,
        "_internal_originalTime": 1689736416000
    },
    {
        "time": 1689736417000,
        "value": 139.229,
        "_internal_originalTime": 1689736417000
    },
    {
        "time": 1689736418000,
        "value": 139.225,
        "_internal_originalTime": 1689736418000
    },
    {
        "time": 1689736420000,
        "value": 139.226,
        "_internal_originalTime": 1689736420000
    },
    {
        "time": 1689736421000,
        "value": 139.226,
        "_internal_originalTime": 1689736421000
    },
    {
        "time": 1689736422000,
        "value": 139.227,
        "_internal_originalTime": 1689736422000
    },
    {
        "time": 1689736423000,
        "value": 139.225,
        "_internal_originalTime": 1689736423000
    },
    {
        "time": 1689736424000,
        "value": 139.229,
        "_internal_originalTime": 1689736424000
    },
    {
        "time": 1689736425000,
        "value": 139.225,
        "_internal_originalTime": 1689736425000
    },
    {
        "time": 1689736426000,
        "value": 139.226,
        "_internal_originalTime": 1689736426000
    },
    {
        "time": 1689736428000,
        "value": 139.229,
        "_internal_originalTime": 1689736428000
    },
    {
        "time": 1689736429000,
        "value": 139.225,
        "_internal_originalTime": 1689736429000
    },
    {
        "time": 1689736431000,
        "value": 139.222,
        "_internal_originalTime": 1689736431000
    },
    {
        "time": 1689736432000,
        "value": 139.229,
        "_internal_originalTime": 1689736432000
    },
    {
        "time": 1689736434000,
        "value": 139.226,
        "_internal_originalTime": 1689736434000
    },
    {
        "time": 1689736435000,
        "value": 139.224,
        "_internal_originalTime": 1689736435000
    },
    {
        "time": 1689736436000,
        "value": 139.226,
        "_internal_originalTime": 1689736436000
    },
    {
        "time": 1689736438000,
        "value": 139.234,
        "_internal_originalTime": 1689736438000
    },
    {
        "time": 1689736440000,
        "value": 139.237,
        "_internal_originalTime": 1689736440000
    },
    {
        "time": 1689736441000,
        "value": 139.239,
        "_internal_originalTime": 1689736441000
    },
    {
        "time": 1689736442000,
        "value": 139.232,
        "_internal_originalTime": 1689736442000
    },
    {
        "time": 1689736443000,
        "value": 139.236,
        "_internal_originalTime": 1689736443000
    },
    {
        "time": 1689736444000,
        "value": 139.233,
        "_internal_originalTime": 1689736444000
    },
    {
        "time": 1689736446000,
        "value": 139.234,
        "_internal_originalTime": 1689736446000
    },
    {
        "time": 1689736447000,
        "value": 139.234,
        "_internal_originalTime": 1689736447000
    },
    {
        "time": 1689736448000,
        "value": 139.238,
        "_internal_originalTime": 1689736448000
    },
    {
        "time": 1689736449000,
        "value": 139.232,
        "_internal_originalTime": 1689736449000
    },
    {
        "time": 1689736451000,
        "value": 139.232,
        "_internal_originalTime": 1689736451000
    },
    {
        "time": 1689736452000,
        "value": 139.218,
        "_internal_originalTime": 1689736452000
    },
    {
        "time": 1689736453000,
        "value": 139.222,
        "_internal_originalTime": 1689736453000
    },
    {
        "time": 1689736454000,
        "value": 139.222,
        "_internal_originalTime": 1689736454000
    },
    {
        "time": 1689736456000,
        "value": 139.218,
        "_internal_originalTime": 1689736456000
    },
    {
        "time": 1689736457000,
        "value": 139.217,
        "_internal_originalTime": 1689736457000
    },
    {
        "time": 1689736458000,
        "value": 139.214,
        "_internal_originalTime": 1689736458000
    },
    {
        "time": 1689736459000,
        "value": 139.218,
        "_internal_originalTime": 1689736459000
    },
    {
        "time": 1689736460000,
        "value": 139.218,
        "_internal_originalTime": 1689736460000
    },
    {
        "time": 1689736462000,
        "value": 139.214,
        "_internal_originalTime": 1689736462000
    },
    {
        "time": 1689736463000,
        "value": 139.211,
        "_internal_originalTime": 1689736463000
    },
    {
        "time": 1689736464000,
        "value": 139.211,
        "_internal_originalTime": 1689736464000
    },
    {
        "time": 1689736466000,
        "value": 139.217,
        "_internal_originalTime": 1689736466000
    },
    {
        "time": 1689736467000,
        "value": 139.217,
        "_internal_originalTime": 1689736467000
    },
    {
        "time": 1689736469000,
        "value": 139.208,
        "_internal_originalTime": 1689736469000
    },
    {
        "time": 1689736472000,
        "value": 139.213,
        "_internal_originalTime": 1689736472000
    },
    {
        "time": 1689736476000,
        "value": 139.213,
        "_internal_originalTime": 1689736476000
    },
    {
        "time": 1689736477000,
        "value": 139.216,
        "_internal_originalTime": 1689736477000
    },
    {
        "time": 1689736478000,
        "value": 139.212,
        "_internal_originalTime": 1689736478000
    },
    {
        "time": 1689736479000,
        "value": 139.21,
        "_internal_originalTime": 1689736479000
    },
    {
        "time": 1689736481000,
        "value": 139.21,
        "_internal_originalTime": 1689736481000
    },
    {
        "time": 1689736482000,
        "value": 139.214,
        "_internal_originalTime": 1689736482000
    },
    {
        "time": 1689736485000,
        "value": 139.213,
        "_internal_originalTime": 1689736485000
    },
    {
        "time": 1689736490000,
        "value": 139.211,
        "_internal_originalTime": 1689736490000
    },
    {
        "time": 1689736492000,
        "value": 139.208,
        "_internal_originalTime": 1689736492000
    },
    {
        "time": 1689736494000,
        "value": 139.208,
        "_internal_originalTime": 1689736494000
    },
    {
        "time": 1689736495000,
        "value": 139.213,
        "_internal_originalTime": 1689736495000
    },
    {
        "time": 1689736496000,
        "value": 139.214,
        "_internal_originalTime": 1689736496000
    },
    {
        "time": 1689736498000,
        "value": 139.215,
        "_internal_originalTime": 1689736498000
    },
    {
        "time": 1689736500000,
        "value": 139.21,
        "_internal_originalTime": 1689736500000
    },
    {
        "time": 1689736501000,
        "value": 139.19,
        "_internal_originalTime": 1689736501000
    },
    {
        "time": 1689736502000,
        "value": 139.208,
        "_internal_originalTime": 1689736502000
    },
    {
        "time": 1689736504000,
        "value": 139.214,
        "_internal_originalTime": 1689736504000
    },
    {
        "time": 1689736506000,
        "value": 139.214,
        "_internal_originalTime": 1689736506000
    },
    {
        "time": 1689736508000,
        "value": 139.21,
        "_internal_originalTime": 1689736508000
    },
    {
        "time": 1689736509000,
        "value": 139.213,
        "_internal_originalTime": 1689736509000
    },
    {
        "time": 1689736512000,
        "value": 139.212,
        "_internal_originalTime": 1689736512000
    },
    {
        "time": 1689736514000,
        "value": 139.208,
        "_internal_originalTime": 1689736514000
    },
    {
        "time": 1689736517000,
        "value": 139.208,
        "_internal_originalTime": 1689736517000
    },
    {
        "time": 1689736519000,
        "value": 139.207,
        "_internal_originalTime": 1689736519000
    },
    {
        "time": 1689736520000,
        "value": 139.211,
        "_internal_originalTime": 1689736520000
    },
    {
        "time": 1689736521000,
        "value": 139.198,
        "_internal_originalTime": 1689736521000
    },
    {
        "time": 1689736522000,
        "value": 139.198,
        "_internal_originalTime": 1689736522000
    },
    {
        "time": 1689736524000,
        "value": 139.199,
        "_internal_originalTime": 1689736524000
    },
    {
        "time": 1689736525000,
        "value": 139.203,
        "_internal_originalTime": 1689736525000
    },
    {
        "time": 1689736527000,
        "value": 139.203,
        "_internal_originalTime": 1689736527000
    },
    {
        "time": 1689736528000,
        "value": 139.206,
        "_internal_originalTime": 1689736528000
    },
    {
        "time": 1689736529000,
        "value": 139.211,
        "_internal_originalTime": 1689736529000
    },
    {
        "time": 1689736530000,
        "value": 139.209,
        "_internal_originalTime": 1689736530000
    },
    {
        "time": 1689736531000,
        "value": 139.212,
        "_internal_originalTime": 1689736531000
    },
    {
        "time": 1689736533000,
        "value": 139.212,
        "_internal_originalTime": 1689736533000
    },
    {
        "time": 1689736534000,
        "value": 139.213,
        "_internal_originalTime": 1689736534000
    },
    {
        "time": 1689736535000,
        "value": 139.213,
        "_internal_originalTime": 1689736535000
    },
    {
        "time": 1689736536000,
        "value": 139.208,
        "_internal_originalTime": 1689736536000
    },
    {
        "time": 1689736537000,
        "value": 139.207,
        "_internal_originalTime": 1689736537000
    },
    {
        "time": 1689736538000,
        "value": 139.213,
        "_internal_originalTime": 1689736538000
    },
    {
        "time": 1689736540000,
        "value": 139.208,
        "_internal_originalTime": 1689736540000
    },
    {
        "time": 1689736541000,
        "value": 139.213,
        "_internal_originalTime": 1689736541000
    },
    {
        "time": 1689736544000,
        "value": 139.211,
        "_internal_originalTime": 1689736544000
    },
    {
        "time": 1689736545000,
        "value": 139.214,
        "_internal_originalTime": 1689736545000
    },
    {
        "time": 1689736546000,
        "value": 139.209,
        "_internal_originalTime": 1689736546000
    },
    {
        "time": 1689736548000,
        "value": 139.214,
        "_internal_originalTime": 1689736548000
    },
    {
        "time": 1689736550000,
        "value": 139.208,
        "_internal_originalTime": 1689736550000
    },
    {
        "time": 1689736552000,
        "value": 139.212,
        "_internal_originalTime": 1689736552000
    },
    {
        "time": 1689736554000,
        "value": 139.212,
        "_internal_originalTime": 1689736554000
    },
    {
        "time": 1689736555000,
        "value": 139.211,
        "_internal_originalTime": 1689736555000
    },
    {
        "time": 1689736557000,
        "value": 139.214,
        "_internal_originalTime": 1689736557000
    },
    {
        "time": 1689736558000,
        "value": 139.212,
        "_internal_originalTime": 1689736558000
    },
    {
        "time": 1689736559000,
        "value": 139.213,
        "_internal_originalTime": 1689736559000
    },
    {
        "time": 1689736560000,
        "value": 139.211,
        "_internal_originalTime": 1689736560000
    },
    {
        "time": 1689736562000,
        "value": 139.208,
        "_internal_originalTime": 1689736562000
    },
    {
        "time": 1689736563000,
        "value": 139.208,
        "_internal_originalTime": 1689736563000
    },
    {
        "time": 1689736565000,
        "value": 139.207,
        "_internal_originalTime": 1689736565000
    },
    {
        "time": 1689736566000,
        "value": 139.213,
        "_internal_originalTime": 1689736566000
    },
    {
        "time": 1689736568000,
        "value": 139.213,
        "_internal_originalTime": 1689736568000
    },
    {
        "time": 1689736569000,
        "value": 139.213,
        "_internal_originalTime": 1689736569000
    },
    {
        "time": 1689736570000,
        "value": 139.208,
        "_internal_originalTime": 1689736570000
    },
    {
        "time": 1689736572000,
        "value": 139.212,
        "_internal_originalTime": 1689736572000
    },
    {
        "time": 1689736573000,
        "value": 139.206,
        "_internal_originalTime": 1689736573000
    },
    {
        "time": 1689736574000,
        "value": 139.206,
        "_internal_originalTime": 1689736574000
    },
    {
        "time": 1689736576000,
        "value": 139.206,
        "_internal_originalTime": 1689736576000
    },
    {
        "time": 1689736578000,
        "value": 139.206,
        "_internal_originalTime": 1689736578000
    },
    {
        "time": 1689736579000,
        "value": 139.2,
        "_internal_originalTime": 1689736579000
    },
    {
        "time": 1689736580000,
        "value": 139.2,
        "_internal_originalTime": 1689736580000
    },
    {
        "time": 1689736583000,
        "value": 139.202,
        "_internal_originalTime": 1689736583000
    },
    {
        "time": 1689736585000,
        "value": 139.198,
        "_internal_originalTime": 1689736585000
    },
    {
        "time": 1689736586000,
        "value": 139.202,
        "_internal_originalTime": 1689736586000
    },
    {
        "time": 1689736588000,
        "value": 139.204,
        "_internal_originalTime": 1689736588000
    },
    {
        "time": 1689736589000,
        "value": 139.208,
        "_internal_originalTime": 1689736589000
    },
    {
        "time": 1689736590000,
        "value": 139.209,
        "_internal_originalTime": 1689736590000
    },
    {
        "time": 1689736596000,
        "value": 139.212,
        "_internal_originalTime": 1689736596000
    },
    {
        "time": 1689736597000,
        "value": 139.211,
        "_internal_originalTime": 1689736597000
    },
    {
        "time": 1689736598000,
        "value": 139.207,
        "_internal_originalTime": 1689736598000
    },
    {
        "time": 1689736599000,
        "value": 139.207,
        "_internal_originalTime": 1689736599000
    },
    {
        "time": 1689736600000,
        "value": 139.211,
        "_internal_originalTime": 1689736600000
    },
    {
        "time": 1689736602000,
        "value": 139.209,
        "_internal_originalTime": 1689736602000
    },
    {
        "time": 1689736603000,
        "value": 139.208,
        "_internal_originalTime": 1689736603000
    },
    {
        "time": 1689736604000,
        "value": 139.207,
        "_internal_originalTime": 1689736604000
    },
    {
        "time": 1689736605000,
        "value": 139.209,
        "_internal_originalTime": 1689736605000
    },
    {
        "time": 1689736606000,
        "value": 139.207,
        "_internal_originalTime": 1689736606000
    },
    {
        "time": 1689736607000,
        "value": 139.208,
        "_internal_originalTime": 1689736607000
    },
    {
        "time": 1689736608000,
        "value": 139.213,
        "_internal_originalTime": 1689736608000
    },
    {
        "time": 1689736610000,
        "value": 139.21,
        "_internal_originalTime": 1689736610000
    },
    {
        "time": 1689736612000,
        "value": 139.209,
        "_internal_originalTime": 1689736612000
    },
    {
        "time": 1689736613000,
        "value": 139.208,
        "_internal_originalTime": 1689736613000
    },
    {
        "time": 1689736614000,
        "value": 139.207,
        "_internal_originalTime": 1689736614000
    },
    {
        "time": 1689736615000,
        "value": 139.207,
        "_internal_originalTime": 1689736615000
    },
    {
        "time": 1689736617000,
        "value": 139.208,
        "_internal_originalTime": 1689736617000
    },
    {
        "time": 1689736619000,
        "value": 139.207,
        "_internal_originalTime": 1689736619000
    },
    {
        "time": 1689736625000,
        "value": 139.213,
        "_internal_originalTime": 1689736625000
    },
    {
        "time": 1689736626000,
        "value": 139.213,
        "_internal_originalTime": 1689736626000
    },
    {
        "time": 1689736629000,
        "value": 139.206,
        "_internal_originalTime": 1689736629000
    },
    {
        "time": 1689736630000,
        "value": 139.213,
        "_internal_originalTime": 1689736630000
    },
    {
        "time": 1689736632000,
        "value": 139.207,
        "_internal_originalTime": 1689736632000
    },
    {
        "time": 1689736633000,
        "value": 139.213,
        "_internal_originalTime": 1689736633000
    },
    {
        "time": 1689736636000,
        "value": 139.207,
        "_internal_originalTime": 1689736636000
    },
    {
        "time": 1689736637000,
        "value": 139.206,
        "_internal_originalTime": 1689736637000
    },
    {
        "time": 1689736639000,
        "value": 139.213,
        "_internal_originalTime": 1689736639000
    },
    {
        "time": 1689736640000,
        "value": 139.208,
        "_internal_originalTime": 1689736640000
    },
    {
        "time": 1689736641000,
        "value": 139.212,
        "_internal_originalTime": 1689736641000
    },
    {
        "time": 1689736643000,
        "value": 139.21,
        "_internal_originalTime": 1689736643000
    },
    {
        "time": 1689736646000,
        "value": 139.211,
        "_internal_originalTime": 1689736646000
    },
    {
        "time": 1689736647000,
        "value": 139.21,
        "_internal_originalTime": 1689736647000
    },
    {
        "time": 1689736648000,
        "value": 139.21,
        "_internal_originalTime": 1689736648000
    },
    {
        "time": 1689736651000,
        "value": 139.211,
        "_internal_originalTime": 1689736651000
    },
    {
        "time": 1689736652000,
        "value": 139.208,
        "_internal_originalTime": 1689736652000
    },
    {
        "time": 1689736658000,
        "value": 139.212,
        "_internal_originalTime": 1689736658000
    },
    {
        "time": 1689736660000,
        "value": 139.213,
        "_internal_originalTime": 1689736660000
    },
    {
        "time": 1689736661000,
        "value": 139.207,
        "_internal_originalTime": 1689736661000
    },
    {
        "time": 1689736662000,
        "value": 139.212,
        "_internal_originalTime": 1689736662000
    },
    {
        "time": 1689736663000,
        "value": 139.212,
        "_internal_originalTime": 1689736663000
    },
    {
        "time": 1689736664000,
        "value": 139.212,
        "_internal_originalTime": 1689736664000
    },
    {
        "time": 1689736666000,
        "value": 139.211,
        "_internal_originalTime": 1689736666000
    },
    {
        "time": 1689736668000,
        "value": 139.211,
        "_internal_originalTime": 1689736668000
    },
    {
        "time": 1689736669000,
        "value": 139.207,
        "_internal_originalTime": 1689736669000
    },
    {
        "time": 1689736670000,
        "value": 139.208,
        "_internal_originalTime": 1689736670000
    },
    {
        "time": 1689736671000,
        "value": 139.206,
        "_internal_originalTime": 1689736671000
    },
    {
        "time": 1689736673000,
        "value": 139.207,
        "_internal_originalTime": 1689736673000
    },
    {
        "time": 1689736674000,
        "value": 139.211,
        "_internal_originalTime": 1689736674000
    },
    {
        "time": 1689736676000,
        "value": 139.208,
        "_internal_originalTime": 1689736676000
    },
    {
        "time": 1689736677000,
        "value": 139.208,
        "_internal_originalTime": 1689736677000
    },
    {
        "time": 1689736678000,
        "value": 139.206,
        "_internal_originalTime": 1689736678000
    },
    {
        "time": 1689736680000,
        "value": 139.204,
        "_internal_originalTime": 1689736680000
    },
    {
        "time": 1689736681000,
        "value": 139.186,
        "_internal_originalTime": 1689736681000
    },
    {
        "time": 1689736682000,
        "value": 139.197,
        "_internal_originalTime": 1689736682000
    },
    {
        "time": 1689736684000,
        "value": 139.2,
        "_internal_originalTime": 1689736684000
    },
    {
        "time": 1689736686000,
        "value": 139.195,
        "_internal_originalTime": 1689736686000
    },
    {
        "time": 1689736687000,
        "value": 139.199,
        "_internal_originalTime": 1689736687000
    },
    {
        "time": 1689736689000,
        "value": 139.201,
        "_internal_originalTime": 1689736689000
    },
    {
        "time": 1689736691000,
        "value": 139.202,
        "_internal_originalTime": 1689736691000
    },
    {
        "time": 1689736693000,
        "value": 139.198,
        "_internal_originalTime": 1689736693000
    },
    {
        "time": 1689736695000,
        "value": 139.202,
        "_internal_originalTime": 1689736695000
    },
    {
        "time": 1689736696000,
        "value": 139.202,
        "_internal_originalTime": 1689736696000
    },
    {
        "time": 1689736697000,
        "value": 139.196,
        "_internal_originalTime": 1689736697000
    },
    {
        "time": 1689736699000,
        "value": 139.199,
        "_internal_originalTime": 1689736699000
    },
    {
        "time": 1689736700000,
        "value": 139.202,
        "_internal_originalTime": 1689736700000
    },
    {
        "time": 1689736702000,
        "value": 139.194,
        "_internal_originalTime": 1689736702000
    },
    {
        "time": 1689736703000,
        "value": 139.197,
        "_internal_originalTime": 1689736703000
    },
    {
        "time": 1689736706000,
        "value": 139.198,
        "_internal_originalTime": 1689736706000
    },
    {
        "time": 1689736707000,
        "value": 139.199,
        "_internal_originalTime": 1689736707000
    },
    {
        "time": 1689736709000,
        "value": 139.197,
        "_internal_originalTime": 1689736709000
    },
    {
        "time": 1689736710000,
        "value": 139.197,
        "_internal_originalTime": 1689736710000
    },
    {
        "time": 1689736711000,
        "value": 139.198,
        "_internal_originalTime": 1689736711000
    },
    {
        "time": 1689736712000,
        "value": 139.195,
        "_internal_originalTime": 1689736712000
    },
    {
        "time": 1689736714000,
        "value": 139.2,
        "_internal_originalTime": 1689736714000
    },
    {
        "time": 1689736716000,
        "value": 139.194,
        "_internal_originalTime": 1689736716000
    },
    {
        "time": 1689736717000,
        "value": 139.197,
        "_internal_originalTime": 1689736717000
    },
    {
        "time": 1689736721000,
        "value": 139.2,
        "_internal_originalTime": 1689736721000
    },
    {
        "time": 1689736722000,
        "value": 139.199,
        "_internal_originalTime": 1689736722000
    },
    {
        "time": 1689736723000,
        "value": 139.198,
        "_internal_originalTime": 1689736723000
    },
    {
        "time": 1689736725000,
        "value": 139.201,
        "_internal_originalTime": 1689736725000
    },
    {
        "time": 1689736726000,
        "value": 139.201,
        "_internal_originalTime": 1689736726000
    },
    {
        "time": 1689736728000,
        "value": 139.201,
        "_internal_originalTime": 1689736728000
    },
    {
        "time": 1689736730000,
        "value": 139.198,
        "_internal_originalTime": 1689736730000
    },
    {
        "time": 1689736733000,
        "value": 139.196,
        "_internal_originalTime": 1689736733000
    },
    {
        "time": 1689736734000,
        "value": 139.193,
        "_internal_originalTime": 1689736734000
    },
    {
        "time": 1689736735000,
        "value": 139.194,
        "_internal_originalTime": 1689736735000
    },
    {
        "time": 1689736737000,
        "value": 139.19,
        "_internal_originalTime": 1689736737000
    },
    {
        "time": 1689736738000,
        "value": 139.189,
        "_internal_originalTime": 1689736738000
    },
    {
        "time": 1689736739000,
        "value": 139.191,
        "_internal_originalTime": 1689736739000
    },
    {
        "time": 1689736740000,
        "value": 139.189,
        "_internal_originalTime": 1689736740000
    },
    {
        "time": 1689736741000,
        "value": 139.192,
        "_internal_originalTime": 1689736741000
    },
    {
        "time": 1689736742000,
        "value": 139.189,
        "_internal_originalTime": 1689736742000
    },
    {
        "time": 1689736743000,
        "value": 139.187,
        "_internal_originalTime": 1689736743000
    },
    {
        "time": 1689736744000,
        "value": 139.192,
        "_internal_originalTime": 1689736744000
    },
    {
        "time": 1689736745000,
        "value": 139.192,
        "_internal_originalTime": 1689736745000
    },
    {
        "time": 1689736749000,
        "value": 139.193,
        "_internal_originalTime": 1689736749000
    },
    {
        "time": 1689736750000,
        "value": 139.194,
        "_internal_originalTime": 1689736750000
    },
    {
        "time": 1689736751000,
        "value": 139.191,
        "_internal_originalTime": 1689736751000
    },
    {
        "time": 1689736752000,
        "value": 139.19,
        "_internal_originalTime": 1689736752000
    },
    {
        "time": 1689736754000,
        "value": 139.188,
        "_internal_originalTime": 1689736754000
    },
    {
        "time": 1689736755000,
        "value": 139.193,
        "_internal_originalTime": 1689736755000
    },
    {
        "time": 1689736756000,
        "value": 139.189,
        "_internal_originalTime": 1689736756000
    },
    {
        "time": 1689736757000,
        "value": 139.189,
        "_internal_originalTime": 1689736757000
    },
    {
        "time": 1689736758000,
        "value": 139.189,
        "_internal_originalTime": 1689736758000
    },
    {
        "time": 1689736759000,
        "value": 139.189,
        "_internal_originalTime": 1689736759000
    },
    {
        "time": 1689736761000,
        "value": 139.189,
        "_internal_originalTime": 1689736761000
    },
    {
        "time": 1689736762000,
        "value": 139.189,
        "_internal_originalTime": 1689736762000
    },
    {
        "time": 1689736763000,
        "value": 139.189,
        "_internal_originalTime": 1689736763000
    },
    {
        "time": 1689736764000,
        "value": 139.19,
        "_internal_originalTime": 1689736764000
    },
    {
        "time": 1689736765000,
        "value": 139.187,
        "_internal_originalTime": 1689736765000
    },
    {
        "time": 1689736766000,
        "value": 139.192,
        "_internal_originalTime": 1689736766000
    },
    {
        "time": 1689736767000,
        "value": 139.191,
        "_internal_originalTime": 1689736767000
    },
    {
        "time": 1689736768000,
        "value": 139.192,
        "_internal_originalTime": 1689736768000
    },
    {
        "time": 1689736769000,
        "value": 139.194,
        "_internal_originalTime": 1689736769000
    },
    {
        "time": 1689736771000,
        "value": 139.193,
        "_internal_originalTime": 1689736771000
    },
    {
        "time": 1689736773000,
        "value": 139.191,
        "_internal_originalTime": 1689736773000
    },
    {
        "time": 1689736774000,
        "value": 139.189,
        "_internal_originalTime": 1689736774000
    },
    {
        "time": 1689736775000,
        "value": 139.193,
        "_internal_originalTime": 1689736775000
    },
    {
        "time": 1689736777000,
        "value": 139.189,
        "_internal_originalTime": 1689736777000
    },
    {
        "time": 1689736779000,
        "value": 139.188,
        "_internal_originalTime": 1689736779000
    },
    {
        "time": 1689736782000,
        "value": 139.194,
        "_internal_originalTime": 1689736782000
    },
    {
        "time": 1689736783000,
        "value": 139.194,
        "_internal_originalTime": 1689736783000
    },
    {
        "time": 1689736784000,
        "value": 139.198,
        "_internal_originalTime": 1689736784000
    },
    {
        "time": 1689736785000,
        "value": 139.192,
        "_internal_originalTime": 1689736785000
    },
    {
        "time": 1689736786000,
        "value": 139.194,
        "_internal_originalTime": 1689736786000
    },
    {
        "time": 1689736788000,
        "value": 139.203,
        "_internal_originalTime": 1689736788000
    },
    {
        "time": 1689736789000,
        "value": 139.199,
        "_internal_originalTime": 1689736789000
    },
    {
        "time": 1689736790000,
        "value": 139.199,
        "_internal_originalTime": 1689736790000
    },
    {
        "time": 1689736792000,
        "value": 139.201,
        "_internal_originalTime": 1689736792000
    },
    {
        "time": 1689736793000,
        "value": 139.194,
        "_internal_originalTime": 1689736793000
    },
    {
        "time": 1689736795000,
        "value": 139.193,
        "_internal_originalTime": 1689736795000
    },
    {
        "time": 1689736796000,
        "value": 139.189,
        "_internal_originalTime": 1689736796000
    },
    {
        "time": 1689736797000,
        "value": 139.189,
        "_internal_originalTime": 1689736797000
    },
    {
        "time": 1689736798000,
        "value": 139.189,
        "_internal_originalTime": 1689736798000
    },
    {
        "time": 1689736799000,
        "value": 139.191,
        "_internal_originalTime": 1689736799000
    },
    {
        "time": 1689736800000,
        "value": 139.19,
        "_internal_originalTime": 1689736800000
    },
    {
        "time": 1689736803000,
        "value": 139.186,
        "_internal_originalTime": 1689736803000
    },
    {
        "time": 1689736806000,
        "value": 139.185,
        "_internal_originalTime": 1689736806000
    },
    {
        "time": 1689736807000,
        "value": 139.186,
        "_internal_originalTime": 1689736807000
    },
    {
        "time": 1689736812000,
        "value": 139.189,
        "_internal_originalTime": 1689736812000
    },
    {
        "time": 1689736813000,
        "value": 139.191,
        "_internal_originalTime": 1689736813000
    },
    {
        "time": 1689736814000,
        "value": 139.192,
        "_internal_originalTime": 1689736814000
    },
    {
        "time": 1689736816000,
        "value": 139.187,
        "_internal_originalTime": 1689736816000
    },
    {
        "time": 1689736819000,
        "value": 139.188,
        "_internal_originalTime": 1689736819000
    },
    {
        "time": 1689736820000,
        "value": 139.187,
        "_internal_originalTime": 1689736820000
    },
    {
        "time": 1689736824000,
        "value": 139.188,
        "_internal_originalTime": 1689736824000
    },
    {
        "time": 1689736825000,
        "value": 139.186,
        "_internal_originalTime": 1689736825000
    },
    {
        "time": 1689736828000,
        "value": 139.187,
        "_internal_originalTime": 1689736828000
    },
    {
        "time": 1689736830000,
        "value": 139.189,
        "_internal_originalTime": 1689736830000
    },
    {
        "time": 1689736831000,
        "value": 139.192,
        "_internal_originalTime": 1689736831000
    },
    {
        "time": 1689736832000,
        "value": 139.189,
        "_internal_originalTime": 1689736832000
    },
    {
        "time": 1689736833000,
        "value": 139.19,
        "_internal_originalTime": 1689736833000
    },
    {
        "time": 1689736834000,
        "value": 139.192,
        "_internal_originalTime": 1689736834000
    },
    {
        "time": 1689736836000,
        "value": 139.189,
        "_internal_originalTime": 1689736836000
    },
    {
        "time": 1689736837000,
        "value": 139.193,
        "_internal_originalTime": 1689736837000
    },
    {
        "time": 1689736839000,
        "value": 139.186,
        "_internal_originalTime": 1689736839000
    },
    {
        "time": 1689736840000,
        "value": 139.187,
        "_internal_originalTime": 1689736840000
    },
    {
        "time": 1689736841000,
        "value": 139.19,
        "_internal_originalTime": 1689736841000
    },
    {
        "time": 1689736843000,
        "value": 139.19,
        "_internal_originalTime": 1689736843000
    },
    {
        "time": 1689736844000,
        "value": 139.187,
        "_internal_originalTime": 1689736844000
    },
    {
        "time": 1689736845000,
        "value": 139.189,
        "_internal_originalTime": 1689736845000
    },
    {
        "time": 1689736846000,
        "value": 139.186,
        "_internal_originalTime": 1689736846000
    },
    {
        "time": 1689736847000,
        "value": 139.189,
        "_internal_originalTime": 1689736847000
    },
    {
        "time": 1689736851000,
        "value": 139.184,
        "_internal_originalTime": 1689736851000
    },
    {
        "time": 1689736852000,
        "value": 139.185,
        "_internal_originalTime": 1689736852000
    },
    {
        "time": 1689736855000,
        "value": 139.188,
        "_internal_originalTime": 1689736855000
    },
    {
        "time": 1689736856000,
        "value": 139.188,
        "_internal_originalTime": 1689736856000
    },
    {
        "time": 1689736858000,
        "value": 139.184,
        "_internal_originalTime": 1689736858000
    },
    {
        "time": 1689736859000,
        "value": 139.184,
        "_internal_originalTime": 1689736859000
    },
    {
        "time": 1689736860000,
        "value": 139.178,
        "_internal_originalTime": 1689736860000
    },
    {
        "time": 1689736862000,
        "value": 139.183,
        "_internal_originalTime": 1689736862000
    },
    {
        "time": 1689736865000,
        "value": 139.184,
        "_internal_originalTime": 1689736865000
    },
    {
        "time": 1689736866000,
        "value": 139.181,
        "_internal_originalTime": 1689736866000
    },
    {
        "time": 1689736867000,
        "value": 139.18,
        "_internal_originalTime": 1689736867000
    },
    {
        "time": 1689736868000,
        "value": 139.178,
        "_internal_originalTime": 1689736868000
    },
    {
        "time": 1689736869000,
        "value": 139.181,
        "_internal_originalTime": 1689736869000
    },
    {
        "time": 1689736871000,
        "value": 139.183,
        "_internal_originalTime": 1689736871000
    },
    {
        "time": 1689736872000,
        "value": 139.178,
        "_internal_originalTime": 1689736872000
    },
    {
        "time": 1689736874000,
        "value": 139.192,
        "_internal_originalTime": 1689736874000
    },
    {
        "time": 1689736875000,
        "value": 139.19,
        "_internal_originalTime": 1689736875000
    },
    {
        "time": 1689736876000,
        "value": 139.187,
        "_internal_originalTime": 1689736876000
    },
    {
        "time": 1689736877000,
        "value": 139.194,
        "_internal_originalTime": 1689736877000
    },
    {
        "time": 1689736878000,
        "value": 139.194,
        "_internal_originalTime": 1689736878000
    },
    {
        "time": 1689736880000,
        "value": 139.191,
        "_internal_originalTime": 1689736880000
    },
    {
        "time": 1689736884000,
        "value": 139.19,
        "_internal_originalTime": 1689736884000
    },
    {
        "time": 1689736885000,
        "value": 139.19,
        "_internal_originalTime": 1689736885000
    },
    {
        "time": 1689736890000,
        "value": 139.188,
        "_internal_originalTime": 1689736890000
    },
    {
        "time": 1689736892000,
        "value": 139.194,
        "_internal_originalTime": 1689736892000
    },
    {
        "time": 1689736894000,
        "value": 139.187,
        "_internal_originalTime": 1689736894000
    },
    {
        "time": 1689736896000,
        "value": 139.194,
        "_internal_originalTime": 1689736896000
    },
    {
        "time": 1689736899000,
        "value": 139.189,
        "_internal_originalTime": 1689736899000
    },
    {
        "time": 1689736900000,
        "value": 139.188,
        "_internal_originalTime": 1689736900000
    },
    {
        "time": 1689736903000,
        "value": 139.188,
        "_internal_originalTime": 1689736903000
    },
    {
        "time": 1689736904000,
        "value": 139.188,
        "_internal_originalTime": 1689736904000
    },
    {
        "time": 1689736906000,
        "value": 139.19,
        "_internal_originalTime": 1689736906000
    },
    {
        "time": 1689736908000,
        "value": 139.194,
        "_internal_originalTime": 1689736908000
    },
    {
        "time": 1689736909000,
        "value": 139.193,
        "_internal_originalTime": 1689736909000
    },
    {
        "time": 1689736911000,
        "value": 139.194,
        "_internal_originalTime": 1689736911000
    },
    {
        "time": 1689736913000,
        "value": 139.188,
        "_internal_originalTime": 1689736913000
    },
    {
        "time": 1689736915000,
        "value": 139.187,
        "_internal_originalTime": 1689736915000
    },
    {
        "time": 1689736918000,
        "value": 139.194,
        "_internal_originalTime": 1689736918000
    },
    {
        "time": 1689736919000,
        "value": 139.189,
        "_internal_originalTime": 1689736919000
    },
    {
        "time": 1689736921000,
        "value": 139.17,
        "_internal_originalTime": 1689736921000
    },
    {
        "time": 1689736927000,
        "value": 139.19,
        "_internal_originalTime": 1689736927000
    },
    {
        "time": 1689736930000,
        "value": 139.187,
        "_internal_originalTime": 1689736930000
    },
    {
        "time": 1689736932000,
        "value": 139.191,
        "_internal_originalTime": 1689736932000
    },
    {
        "time": 1689736933000,
        "value": 139.189,
        "_internal_originalTime": 1689736933000
    },
    {
        "time": 1689736934000,
        "value": 139.187,
        "_internal_originalTime": 1689736934000
    },
    {
        "time": 1689736937000,
        "value": 139.189,
        "_internal_originalTime": 1689736937000
    },
    {
        "time": 1689736938000,
        "value": 139.188,
        "_internal_originalTime": 1689736938000
    },
    {
        "time": 1689736940000,
        "value": 139.19,
        "_internal_originalTime": 1689736940000
    },
    {
        "time": 1689736945000,
        "value": 139.187,
        "_internal_originalTime": 1689736945000
    },
    {
        "time": 1689736948000,
        "value": 139.192,
        "_internal_originalTime": 1689736948000
    },
    {
        "time": 1689736949000,
        "value": 139.189,
        "_internal_originalTime": 1689736949000
    },
    {
        "time": 1689736951000,
        "value": 139.194,
        "_internal_originalTime": 1689736951000
    },
    {
        "time": 1689736953000,
        "value": 139.194,
        "_internal_originalTime": 1689736953000
    },
    {
        "time": 1689736955000,
        "value": 139.191,
        "_internal_originalTime": 1689736955000
    },
    {
        "time": 1689736957000,
        "value": 139.194,
        "_internal_originalTime": 1689736957000
    },
    {
        "time": 1689736959000,
        "value": 139.194,
        "_internal_originalTime": 1689736959000
    },
    {
        "time": 1689736961000,
        "value": 139.188,
        "_internal_originalTime": 1689736961000
    },
    {
        "time": 1689736962000,
        "value": 139.188,
        "_internal_originalTime": 1689736962000
    },
    {
        "time": 1689736963000,
        "value": 139.189,
        "_internal_originalTime": 1689736963000
    },
    {
        "time": 1689736964000,
        "value": 139.193,
        "_internal_originalTime": 1689736964000
    },
    {
        "time": 1689736965000,
        "value": 139.193,
        "_internal_originalTime": 1689736965000
    },
    {
        "time": 1689736966000,
        "value": 139.193,
        "_internal_originalTime": 1689736966000
    },
    {
        "time": 1689736968000,
        "value": 139.186,
        "_internal_originalTime": 1689736968000
    },
    {
        "time": 1689736970000,
        "value": 139.18,
        "_internal_originalTime": 1689736970000
    },
    {
        "time": 1689736972000,
        "value": 139.178,
        "_internal_originalTime": 1689736972000
    },
    {
        "time": 1689736974000,
        "value": 139.179,
        "_internal_originalTime": 1689736974000
    },
    {
        "time": 1689736975000,
        "value": 139.178,
        "_internal_originalTime": 1689736975000
    },
    {
        "time": 1689736976000,
        "value": 139.179,
        "_internal_originalTime": 1689736976000
    },
    {
        "time": 1689736977000,
        "value": 139.18,
        "_internal_originalTime": 1689736977000
    },
    {
        "time": 1689736978000,
        "value": 139.184,
        "_internal_originalTime": 1689736978000
    },
    {
        "time": 1689736979000,
        "value": 139.184,
        "_internal_originalTime": 1689736979000
    },
    {
        "time": 1689736980000,
        "value": 139.18,
        "_internal_originalTime": 1689736980000
    },
    {
        "time": 1689736982000,
        "value": 139.179,
        "_internal_originalTime": 1689736982000
    },
    {
        "time": 1689736983000,
        "value": 139.182,
        "_internal_originalTime": 1689736983000
    },
    {
        "time": 1689736984000,
        "value": 139.171,
        "_internal_originalTime": 1689736984000
    },
    {
        "time": 1689736985000,
        "value": 139.172,
        "_internal_originalTime": 1689736985000
    },
    {
        "time": 1689736987000,
        "value": 139.17,
        "_internal_originalTime": 1689736987000
    },
    {
        "time": 1689736989000,
        "value": 139.171,
        "_internal_originalTime": 1689736989000
    },
    {
        "time": 1689736990000,
        "value": 139.174,
        "_internal_originalTime": 1689736990000
    },
    {
        "time": 1689736992000,
        "value": 139.169,
        "_internal_originalTime": 1689736992000
    },
    {
        "time": 1689736994000,
        "value": 139.177,
        "_internal_originalTime": 1689736994000
    },
    {
        "time": 1689736995000,
        "value": 139.181,
        "_internal_originalTime": 1689736995000
    },
    {
        "time": 1689736996000,
        "value": 139.176,
        "_internal_originalTime": 1689736996000
    },
    {
        "time": 1689736998000,
        "value": 139.178,
        "_internal_originalTime": 1689736998000
    },
    {
        "time": 1689736999000,
        "value": 139.178,
        "_internal_originalTime": 1689736999000
    },
    {
        "time": 1689737000000,
        "value": 139.179,
        "_internal_originalTime": 1689737000000
    },
    {
        "time": 1689737001000,
        "value": 139.18,
        "_internal_originalTime": 1689737001000
    },
    {
        "time": 1689737003000,
        "value": 139.182,
        "_internal_originalTime": 1689737003000
    },
    {
        "time": 1689737004000,
        "value": 139.182,
        "_internal_originalTime": 1689737004000
    },
    {
        "time": 1689737005000,
        "value": 139.175,
        "_internal_originalTime": 1689737005000
    },
    {
        "time": 1689737007000,
        "value": 139.179,
        "_internal_originalTime": 1689737007000
    },
    {
        "time": 1689737008000,
        "value": 139.182,
        "_internal_originalTime": 1689737008000
    },
    {
        "time": 1689737009000,
        "value": 139.182,
        "_internal_originalTime": 1689737009000
    },
    {
        "time": 1689737011000,
        "value": 139.18,
        "_internal_originalTime": 1689737011000
    },
    {
        "time": 1689737012000,
        "value": 139.179,
        "_internal_originalTime": 1689737012000
    },
    {
        "time": 1689737013000,
        "value": 139.18,
        "_internal_originalTime": 1689737013000
    },
    {
        "time": 1689737014000,
        "value": 139.182,
        "_internal_originalTime": 1689737014000
    },
    {
        "time": 1689737017000,
        "value": 139.18,
        "_internal_originalTime": 1689737017000
    },
    {
        "time": 1689737019000,
        "value": 139.178,
        "_internal_originalTime": 1689737019000
    },
    {
        "time": 1689737020000,
        "value": 139.176,
        "_internal_originalTime": 1689737020000
    },
    {
        "time": 1689737021000,
        "value": 139.18,
        "_internal_originalTime": 1689737021000
    },
    {
        "time": 1689737023000,
        "value": 139.178,
        "_internal_originalTime": 1689737023000
    },
    {
        "time": 1689737024000,
        "value": 139.175,
        "_internal_originalTime": 1689737024000
    },
    {
        "time": 1689737025000,
        "value": 139.171,
        "_internal_originalTime": 1689737025000
    },
    {
        "time": 1689737026000,
        "value": 139.178,
        "_internal_originalTime": 1689737026000
    },
    {
        "time": 1689737027000,
        "value": 139.174,
        "_internal_originalTime": 1689737027000
    },
    {
        "time": 1689737029000,
        "value": 139.18,
        "_internal_originalTime": 1689737029000
    },
    {
        "time": 1689737030000,
        "value": 139.184,
        "_internal_originalTime": 1689737030000
    },
    {
        "time": 1689737031000,
        "value": 139.184,
        "_internal_originalTime": 1689737031000
    },
    {
        "time": 1689737033000,
        "value": 139.178,
        "_internal_originalTime": 1689737033000
    },
    {
        "time": 1689737034000,
        "value": 139.17,
        "_internal_originalTime": 1689737034000
    },
    {
        "time": 1689737035000,
        "value": 139.169,
        "_internal_originalTime": 1689737035000
    },
    {
        "time": 1689737037000,
        "value": 139.172,
        "_internal_originalTime": 1689737037000
    },
    {
        "time": 1689737038000,
        "value": 139.172,
        "_internal_originalTime": 1689737038000
    },
    {
        "time": 1689737040000,
        "value": 139.177,
        "_internal_originalTime": 1689737040000
    },
    {
        "time": 1689737041000,
        "value": 139.178,
        "_internal_originalTime": 1689737041000
    },
    {
        "time": 1689737042000,
        "value": 139.18,
        "_internal_originalTime": 1689737042000
    },
    {
        "time": 1689737044000,
        "value": 139.179,
        "_internal_originalTime": 1689737044000
    },
    {
        "time": 1689737046000,
        "value": 139.176,
        "_internal_originalTime": 1689737046000
    },
    {
        "time": 1689737047000,
        "value": 139.176,
        "_internal_originalTime": 1689737047000
    },
    {
        "time": 1689737048000,
        "value": 139.178,
        "_internal_originalTime": 1689737048000
    },
    {
        "time": 1689737050000,
        "value": 139.179,
        "_internal_originalTime": 1689737050000
    },
    {
        "time": 1689737054000,
        "value": 139.176,
        "_internal_originalTime": 1689737054000
    },
    {
        "time": 1689737055000,
        "value": 139.175,
        "_internal_originalTime": 1689737055000
    },
    {
        "time": 1689737056000,
        "value": 139.18,
        "_internal_originalTime": 1689737056000
    },
    {
        "time": 1689737057000,
        "value": 139.18,
        "_internal_originalTime": 1689737057000
    },
    {
        "time": 1689737059000,
        "value": 139.177,
        "_internal_originalTime": 1689737059000
    },
    {
        "time": 1689737060000,
        "value": 139.18,
        "_internal_originalTime": 1689737060000
    },
    {
        "time": 1689737061000,
        "value": 139.174,
        "_internal_originalTime": 1689737061000
    },
    {
        "time": 1689737062000,
        "value": 139.171,
        "_internal_originalTime": 1689737062000
    },
    {
        "time": 1689737066000,
        "value": 139.175,
        "_internal_originalTime": 1689737066000
    },
    {
        "time": 1689737070000,
        "value": 139.17,
        "_internal_originalTime": 1689737070000
    },
    {
        "time": 1689737072000,
        "value": 139.171,
        "_internal_originalTime": 1689737072000
    },
    {
        "time": 1689737073000,
        "value": 139.184,
        "_internal_originalTime": 1689737073000
    },
    {
        "time": 1689737074000,
        "value": 139.178,
        "_internal_originalTime": 1689737074000
    },
    {
        "time": 1689737075000,
        "value": 139.183,
        "_internal_originalTime": 1689737075000
    },
    {
        "time": 1689737077000,
        "value": 139.18,
        "_internal_originalTime": 1689737077000
    },
    {
        "time": 1689737078000,
        "value": 139.178,
        "_internal_originalTime": 1689737078000
    },
    {
        "time": 1689737080000,
        "value": 139.183,
        "_internal_originalTime": 1689737080000
    },
    {
        "time": 1689737081000,
        "value": 139.176,
        "_internal_originalTime": 1689737081000
    },
    {
        "time": 1689737083000,
        "value": 139.178,
        "_internal_originalTime": 1689737083000
    },
    {
        "time": 1689737085000,
        "value": 139.184,
        "_internal_originalTime": 1689737085000
    },
    {
        "time": 1689737087000,
        "value": 139.181,
        "_internal_originalTime": 1689737087000
    },
    {
        "time": 1689737089000,
        "value": 139.178,
        "_internal_originalTime": 1689737089000
    },
    {
        "time": 1689737090000,
        "value": 139.18,
        "_internal_originalTime": 1689737090000
    },
    {
        "time": 1689737091000,
        "value": 139.179,
        "_internal_originalTime": 1689737091000
    },
    {
        "time": 1689737092000,
        "value": 139.178,
        "_internal_originalTime": 1689737092000
    },
    {
        "time": 1689737094000,
        "value": 139.18,
        "_internal_originalTime": 1689737094000
    },
    {
        "time": 1689737095000,
        "value": 139.176,
        "_internal_originalTime": 1689737095000
    },
    {
        "time": 1689737097000,
        "value": 139.181,
        "_internal_originalTime": 1689737097000
    },
    {
        "time": 1689737098000,
        "value": 139.179,
        "_internal_originalTime": 1689737098000
    },
    {
        "time": 1689737099000,
        "value": 139.183,
        "_internal_originalTime": 1689737099000
    },
    {
        "time": 1689737101000,
        "value": 139.186,
        "_internal_originalTime": 1689737101000
    },
    {
        "time": 1689737102000,
        "value": 139.188,
        "_internal_originalTime": 1689737102000
    },
    {
        "time": 1689737105000,
        "value": 139.19,
        "_internal_originalTime": 1689737105000
    },
    {
        "time": 1689737107000,
        "value": 139.19,
        "_internal_originalTime": 1689737107000
    },
    {
        "time": 1689737109000,
        "value": 139.195,
        "_internal_originalTime": 1689737109000
    },
    {
        "time": 1689737110000,
        "value": 139.187,
        "_internal_originalTime": 1689737110000
    },
    {
        "time": 1689737111000,
        "value": 139.184,
        "_internal_originalTime": 1689737111000
    },
    {
        "time": 1689737114000,
        "value": 139.191,
        "_internal_originalTime": 1689737114000
    },
    {
        "time": 1689737116000,
        "value": 139.188,
        "_internal_originalTime": 1689737116000
    },
    {
        "time": 1689737119000,
        "value": 139.189,
        "_internal_originalTime": 1689737119000
    },
    {
        "time": 1689737121000,
        "value": 139.191,
        "_internal_originalTime": 1689737121000
    },
    {
        "time": 1689737122000,
        "value": 139.192,
        "_internal_originalTime": 1689737122000
    },
    {
        "time": 1689737124000,
        "value": 139.192,
        "_internal_originalTime": 1689737124000
    },
    {
        "time": 1689737125000,
        "value": 139.188,
        "_internal_originalTime": 1689737125000
    },
    {
        "time": 1689737126000,
        "value": 139.189,
        "_internal_originalTime": 1689737126000
    },
    {
        "time": 1689737127000,
        "value": 139.19,
        "_internal_originalTime": 1689737127000
    },
    {
        "time": 1689737128000,
        "value": 139.19,
        "_internal_originalTime": 1689737128000
    },
    {
        "time": 1689737130000,
        "value": 139.185,
        "_internal_originalTime": 1689737130000
    },
    {
        "time": 1689737131000,
        "value": 139.173,
        "_internal_originalTime": 1689737131000
    },
    {
        "time": 1689737132000,
        "value": 139.171,
        "_internal_originalTime": 1689737132000
    },
    {
        "time": 1689737133000,
        "value": 139.168,
        "_internal_originalTime": 1689737133000
    },
    {
        "time": 1689737134000,
        "value": 139.174,
        "_internal_originalTime": 1689737134000
    },
    {
        "time": 1689737137000,
        "value": 139.169,
        "_internal_originalTime": 1689737137000
    },
    {
        "time": 1689737139000,
        "value": 139.172,
        "_internal_originalTime": 1689737139000
    },
    {
        "time": 1689737144000,
        "value": 139.169,
        "_internal_originalTime": 1689737144000
    },
    {
        "time": 1689737145000,
        "value": 139.172,
        "_internal_originalTime": 1689737145000
    },
    {
        "time": 1689737147000,
        "value": 139.169,
        "_internal_originalTime": 1689737147000
    },
    {
        "time": 1689737148000,
        "value": 139.168,
        "_internal_originalTime": 1689737148000
    },
    {
        "time": 1689737151000,
        "value": 139.179,
        "_internal_originalTime": 1689737151000
    },
    {
        "time": 1689737152000,
        "value": 139.176,
        "_internal_originalTime": 1689737152000
    },
    {
        "time": 1689737153000,
        "value": 139.176,
        "_internal_originalTime": 1689737153000
    },
    {
        "time": 1689737155000,
        "value": 139.178,
        "_internal_originalTime": 1689737155000
    },
    {
        "time": 1689737159000,
        "value": 139.176,
        "_internal_originalTime": 1689737159000
    },
    {
        "time": 1689737160000,
        "value": 139.179,
        "_internal_originalTime": 1689737160000
    },
    {
        "time": 1689737163000,
        "value": 139.176,
        "_internal_originalTime": 1689737163000
    },
    {
        "time": 1689737164000,
        "value": 139.177,
        "_internal_originalTime": 1689737164000
    },
    {
        "time": 1689737165000,
        "value": 139.178,
        "_internal_originalTime": 1689737165000
    },
    {
        "time": 1689737166000,
        "value": 139.176,
        "_internal_originalTime": 1689737166000
    },
    {
        "time": 1689737167000,
        "value": 139.179,
        "_internal_originalTime": 1689737167000
    },
    {
        "time": 1689737169000,
        "value": 139.178,
        "_internal_originalTime": 1689737169000
    },
    {
        "time": 1689737170000,
        "value": 139.174,
        "_internal_originalTime": 1689737170000
    },
    {
        "time": 1689737172000,
        "value": 139.169,
        "_internal_originalTime": 1689737172000
    },
    {
        "time": 1689737173000,
        "value": 139.165,
        "_internal_originalTime": 1689737173000
    },
    {
        "time": 1689737174000,
        "value": 139.166,
        "_internal_originalTime": 1689737174000
    },
    {
        "time": 1689737175000,
        "value": 139.162,
        "_internal_originalTime": 1689737175000
    },
    {
        "time": 1689737176000,
        "value": 139.16,
        "_internal_originalTime": 1689737176000
    },
    {
        "time": 1689737177000,
        "value": 139.16,
        "_internal_originalTime": 1689737177000
    },
    {
        "time": 1689737179000,
        "value": 139.17,
        "_internal_originalTime": 1689737179000
    },
    {
        "time": 1689737181000,
        "value": 139.177,
        "_internal_originalTime": 1689737181000
    },
    {
        "time": 1689737182000,
        "value": 139.175,
        "_internal_originalTime": 1689737182000
    },
    {
        "time": 1689737183000,
        "value": 139.175,
        "_internal_originalTime": 1689737183000
    },
    {
        "time": 1689737184000,
        "value": 139.177,
        "_internal_originalTime": 1689737184000
    },
    {
        "time": 1689737186000,
        "value": 139.171,
        "_internal_originalTime": 1689737186000
    },
    {
        "time": 1689737187000,
        "value": 139.178,
        "_internal_originalTime": 1689737187000
    },
    {
        "time": 1689737188000,
        "value": 139.177,
        "_internal_originalTime": 1689737188000
    },
    {
        "time": 1689737190000,
        "value": 139.177,
        "_internal_originalTime": 1689737190000
    },
    {
        "time": 1689737191000,
        "value": 139.179,
        "_internal_originalTime": 1689737191000
    },
    {
        "time": 1689737192000,
        "value": 139.18,
        "_internal_originalTime": 1689737192000
    },
    {
        "time": 1689737193000,
        "value": 139.18,
        "_internal_originalTime": 1689737193000
    },
    {
        "time": 1689737197000,
        "value": 139.176,
        "_internal_originalTime": 1689737197000
    },
    {
        "time": 1689737198000,
        "value": 139.178,
        "_internal_originalTime": 1689737198000
    },
    {
        "time": 1689737199000,
        "value": 139.173,
        "_internal_originalTime": 1689737199000
    },
    {
        "time": 1689737200000,
        "value": 139.168,
        "_internal_originalTime": 1689737200000
    },
    {
        "time": 1689737201000,
        "value": 139.172,
        "_internal_originalTime": 1689737201000
    },
    {
        "time": 1689737203000,
        "value": 139.168,
        "_internal_originalTime": 1689737203000
    },
    {
        "time": 1689737204000,
        "value": 139.17,
        "_internal_originalTime": 1689737204000
    },
    {
        "time": 1689737205000,
        "value": 139.172,
        "_internal_originalTime": 1689737205000
    },
    {
        "time": 1689737209000,
        "value": 139.171,
        "_internal_originalTime": 1689737209000
    },
    {
        "time": 1689737210000,
        "value": 139.171,
        "_internal_originalTime": 1689737210000
    },
    {
        "time": 1689737212000,
        "value": 139.168,
        "_internal_originalTime": 1689737212000
    },
    {
        "time": 1689737214000,
        "value": 139.17,
        "_internal_originalTime": 1689737214000
    },
    {
        "time": 1689737216000,
        "value": 139.163,
        "_internal_originalTime": 1689737216000
    },
    {
        "time": 1689737217000,
        "value": 139.171,
        "_internal_originalTime": 1689737217000
    },
    {
        "time": 1689737218000,
        "value": 139.167,
        "_internal_originalTime": 1689737218000
    },
    {
        "time": 1689737221000,
        "value": 139.15,
        "_internal_originalTime": 1689737221000
    },
    {
        "time": 1689737222000,
        "value": 139.171,
        "_internal_originalTime": 1689737222000
    },
    {
        "time": 1689737223000,
        "value": 139.173,
        "_internal_originalTime": 1689737223000
    },
    {
        "time": 1689737224000,
        "value": 139.169,
        "_internal_originalTime": 1689737224000
    },
    {
        "time": 1689737225000,
        "value": 139.168,
        "_internal_originalTime": 1689737225000
    },
    {
        "time": 1689737227000,
        "value": 139.172,
        "_internal_originalTime": 1689737227000
    },
    {
        "time": 1689737228000,
        "value": 139.169,
        "_internal_originalTime": 1689737228000
    },
    {
        "time": 1689737230000,
        "value": 139.172,
        "_internal_originalTime": 1689737230000
    },
    {
        "time": 1689737231000,
        "value": 139.173,
        "_internal_originalTime": 1689737231000
    },
    {
        "time": 1689737233000,
        "value": 139.178,
        "_internal_originalTime": 1689737233000
    },
    {
        "time": 1689737234000,
        "value": 139.176,
        "_internal_originalTime": 1689737234000
    },
    {
        "time": 1689737235000,
        "value": 139.176,
        "_internal_originalTime": 1689737235000
    },
    {
        "time": 1689737236000,
        "value": 139.176,
        "_internal_originalTime": 1689737236000
    },
    {
        "time": 1689737237000,
        "value": 139.176,
        "_internal_originalTime": 1689737237000
    },
    {
        "time": 1689737239000,
        "value": 139.181,
        "_internal_originalTime": 1689737239000
    },
    {
        "time": 1689737240000,
        "value": 139.181,
        "_internal_originalTime": 1689737240000
    },
    {
        "time": 1689737241000,
        "value": 139.18,
        "_internal_originalTime": 1689737241000
    },
    {
        "time": 1689737242000,
        "value": 139.18,
        "_internal_originalTime": 1689737242000
    },
    {
        "time": 1689737243000,
        "value": 139.181,
        "_internal_originalTime": 1689737243000
    },
    {
        "time": 1689737245000,
        "value": 139.183,
        "_internal_originalTime": 1689737245000
    },
    {
        "time": 1689737246000,
        "value": 139.192,
        "_internal_originalTime": 1689737246000
    },
    {
        "time": 1689737247000,
        "value": 139.19,
        "_internal_originalTime": 1689737247000
    },
    {
        "time": 1689737248000,
        "value": 139.191,
        "_internal_originalTime": 1689737248000
    },
    {
        "time": 1689737250000,
        "value": 139.189,
        "_internal_originalTime": 1689737250000
    },
    {
        "time": 1689737251000,
        "value": 139.19,
        "_internal_originalTime": 1689737251000
    },
    {
        "time": 1689737252000,
        "value": 139.191,
        "_internal_originalTime": 1689737252000
    },
    {
        "time": 1689737253000,
        "value": 139.191,
        "_internal_originalTime": 1689737253000
    },
    {
        "time": 1689737255000,
        "value": 139.192,
        "_internal_originalTime": 1689737255000
    },
    {
        "time": 1689737256000,
        "value": 139.192,
        "_internal_originalTime": 1689737256000
    },
    {
        "time": 1689737257000,
        "value": 139.19,
        "_internal_originalTime": 1689737257000
    },
    {
        "time": 1689737261000,
        "value": 139.188,
        "_internal_originalTime": 1689737261000
    },
    {
        "time": 1689737263000,
        "value": 139.189,
        "_internal_originalTime": 1689737263000
    },
    {
        "time": 1689737265000,
        "value": 139.186,
        "_internal_originalTime": 1689737265000
    },
    {
        "time": 1689737266000,
        "value": 139.187,
        "_internal_originalTime": 1689737266000
    },
    {
        "time": 1689737267000,
        "value": 139.183,
        "_internal_originalTime": 1689737267000
    },
    {
        "time": 1689737268000,
        "value": 139.18,
        "_internal_originalTime": 1689737268000
    },
    {
        "time": 1689737270000,
        "value": 139.18,
        "_internal_originalTime": 1689737270000
    },
    {
        "time": 1689737271000,
        "value": 139.185,
        "_internal_originalTime": 1689737271000
    },
    {
        "time": 1689737272000,
        "value": 139.18,
        "_internal_originalTime": 1689737272000
    },
    {
        "time": 1689737274000,
        "value": 139.189,
        "_internal_originalTime": 1689737274000
    },
    {
        "time": 1689737276000,
        "value": 139.189,
        "_internal_originalTime": 1689737276000
    },
    {
        "time": 1689737278000,
        "value": 139.188,
        "_internal_originalTime": 1689737278000
    },
    {
        "time": 1689737279000,
        "value": 139.19,
        "_internal_originalTime": 1689737279000
    },
    {
        "time": 1689737280000,
        "value": 139.17,
        "_internal_originalTime": 1689737280000
    },
    {
        "time": 1689737282000,
        "value": 139.188,
        "_internal_originalTime": 1689737282000
    },
    {
        "time": 1689737283000,
        "value": 139.192,
        "_internal_originalTime": 1689737283000
    },
    {
        "time": 1689737284000,
        "value": 139.188,
        "_internal_originalTime": 1689737284000
    },
    {
        "time": 1689737286000,
        "value": 139.192,
        "_internal_originalTime": 1689737286000
    },
    {
        "time": 1689737287000,
        "value": 139.189,
        "_internal_originalTime": 1689737287000
    },
    {
        "time": 1689737288000,
        "value": 139.189,
        "_internal_originalTime": 1689737288000
    },
    {
        "time": 1689737289000,
        "value": 139.186,
        "_internal_originalTime": 1689737289000
    },
    {
        "time": 1689737290000,
        "value": 139.19,
        "_internal_originalTime": 1689737290000
    },
    {
        "time": 1689737291000,
        "value": 139.188,
        "_internal_originalTime": 1689737291000
    },
    {
        "time": 1689737295000,
        "value": 139.193,
        "_internal_originalTime": 1689737295000
    },
    {
        "time": 1689737296000,
        "value": 139.189,
        "_internal_originalTime": 1689737296000
    },
    {
        "time": 1689737297000,
        "value": 139.19,
        "_internal_originalTime": 1689737297000
    },
    {
        "time": 1689737299000,
        "value": 139.19,
        "_internal_originalTime": 1689737299000
    },
    {
        "time": 1689737301000,
        "value": 139.19,
        "_internal_originalTime": 1689737301000
    },
    {
        "time": 1689737302000,
        "value": 139.191,
        "_internal_originalTime": 1689737302000
    },
    {
        "time": 1689737303000,
        "value": 139.19,
        "_internal_originalTime": 1689737303000
    },
    {
        "time": 1689737305000,
        "value": 139.19,
        "_internal_originalTime": 1689737305000
    },
    {
        "time": 1689737306000,
        "value": 139.19,
        "_internal_originalTime": 1689737306000
    },
    {
        "time": 1689737310000,
        "value": 139.193,
        "_internal_originalTime": 1689737310000
    },
    {
        "time": 1689737311000,
        "value": 139.188,
        "_internal_originalTime": 1689737311000
    },
    {
        "time": 1689737312000,
        "value": 139.188,
        "_internal_originalTime": 1689737312000
    },
    {
        "time": 1689737313000,
        "value": 139.187,
        "_internal_originalTime": 1689737313000
    },
    {
        "time": 1689737314000,
        "value": 139.188,
        "_internal_originalTime": 1689737314000
    },
    {
        "time": 1689737316000,
        "value": 139.189,
        "_internal_originalTime": 1689737316000
    },
    {
        "time": 1689737318000,
        "value": 139.186,
        "_internal_originalTime": 1689737318000
    },
    {
        "time": 1689737319000,
        "value": 139.188,
        "_internal_originalTime": 1689737319000
    },
    {
        "time": 1689737320000,
        "value": 139.19,
        "_internal_originalTime": 1689737320000
    },
    {
        "time": 1689737323000,
        "value": 139.189,
        "_internal_originalTime": 1689737323000
    },
    {
        "time": 1689737324000,
        "value": 139.186,
        "_internal_originalTime": 1689737324000
    },
    {
        "time": 1689737325000,
        "value": 139.178,
        "_internal_originalTime": 1689737325000
    },
    {
        "time": 1689737326000,
        "value": 139.178,
        "_internal_originalTime": 1689737326000
    },
    {
        "time": 1689737327000,
        "value": 139.175,
        "_internal_originalTime": 1689737327000
    },
    {
        "time": 1689737329000,
        "value": 139.174,
        "_internal_originalTime": 1689737329000
    },
    {
        "time": 1689737330000,
        "value": 139.173,
        "_internal_originalTime": 1689737330000
    },
    {
        "time": 1689737332000,
        "value": 139.175,
        "_internal_originalTime": 1689737332000
    },
    {
        "time": 1689737333000,
        "value": 139.175,
        "_internal_originalTime": 1689737333000
    },
    {
        "time": 1689737335000,
        "value": 139.175,
        "_internal_originalTime": 1689737335000
    },
    {
        "time": 1689737336000,
        "value": 139.175,
        "_internal_originalTime": 1689737336000
    },
    {
        "time": 1689737338000,
        "value": 139.182,
        "_internal_originalTime": 1689737338000
    },
    {
        "time": 1689737339000,
        "value": 139.178,
        "_internal_originalTime": 1689737339000
    },
    {
        "time": 1689737342000,
        "value": 139.181,
        "_internal_originalTime": 1689737342000
    },
    {
        "time": 1689737343000,
        "value": 139.182,
        "_internal_originalTime": 1689737343000
    },
    {
        "time": 1689737344000,
        "value": 139.18,
        "_internal_originalTime": 1689737344000
    },
    {
        "time": 1689737346000,
        "value": 139.181,
        "_internal_originalTime": 1689737346000
    },
    {
        "time": 1689737347000,
        "value": 139.184,
        "_internal_originalTime": 1689737347000
    },
    {
        "time": 1689737350000,
        "value": 139.185,
        "_internal_originalTime": 1689737350000
    },
    {
        "time": 1689737352000,
        "value": 139.185,
        "_internal_originalTime": 1689737352000
    },
    {
        "time": 1689737354000,
        "value": 139.182,
        "_internal_originalTime": 1689737354000
    },
    {
        "time": 1689737355000,
        "value": 139.182,
        "_internal_originalTime": 1689737355000
    },
    {
        "time": 1689737357000,
        "value": 139.181,
        "_internal_originalTime": 1689737357000
    },
    {
        "time": 1689737359000,
        "value": 139.178,
        "_internal_originalTime": 1689737359000
    },
    {
        "time": 1689737360000,
        "value": 139.181,
        "_internal_originalTime": 1689737360000
    },
    {
        "time": 1689737361000,
        "value": 139.179,
        "_internal_originalTime": 1689737361000
    },
    {
        "time": 1689737362000,
        "value": 139.182,
        "_internal_originalTime": 1689737362000
    },
    {
        "time": 1689737364000,
        "value": 139.178,
        "_internal_originalTime": 1689737364000
    },
    {
        "time": 1689737366000,
        "value": 139.182,
        "_internal_originalTime": 1689737366000
    },
    {
        "time": 1689737368000,
        "value": 139.181,
        "_internal_originalTime": 1689737368000
    },
    {
        "time": 1689737369000,
        "value": 139.18,
        "_internal_originalTime": 1689737369000
    },
    {
        "time": 1689737372000,
        "value": 139.185,
        "_internal_originalTime": 1689737372000
    },
    {
        "time": 1689737374000,
        "value": 139.18,
        "_internal_originalTime": 1689737374000
    },
    {
        "time": 1689737375000,
        "value": 139.181,
        "_internal_originalTime": 1689737375000
    },
    {
        "time": 1689737376000,
        "value": 139.188,
        "_internal_originalTime": 1689737376000
    },
    {
        "time": 1689737378000,
        "value": 139.184,
        "_internal_originalTime": 1689737378000
    },
    {
        "time": 1689737379000,
        "value": 139.184,
        "_internal_originalTime": 1689737379000
    },
    {
        "time": 1689737380000,
        "value": 139.182,
        "_internal_originalTime": 1689737380000
    },
    {
        "time": 1689737381000,
        "value": 139.182,
        "_internal_originalTime": 1689737381000
    },
    {
        "time": 1689737382000,
        "value": 139.182,
        "_internal_originalTime": 1689737382000
    },
    {
        "time": 1689737384000,
        "value": 139.182,
        "_internal_originalTime": 1689737384000
    },
    {
        "time": 1689737385000,
        "value": 139.182,
        "_internal_originalTime": 1689737385000
    },
    {
        "time": 1689737386000,
        "value": 139.182,
        "_internal_originalTime": 1689737386000
    },
    {
        "time": 1689737388000,
        "value": 139.184,
        "_internal_originalTime": 1689737388000
    },
    {
        "time": 1689737389000,
        "value": 139.182,
        "_internal_originalTime": 1689737389000
    },
    {
        "time": 1689737390000,
        "value": 139.182,
        "_internal_originalTime": 1689737390000
    },
    {
        "time": 1689737392000,
        "value": 139.187,
        "_internal_originalTime": 1689737392000
    },
    {
        "time": 1689737393000,
        "value": 139.189,
        "_internal_originalTime": 1689737393000
    },
    {
        "time": 1689737395000,
        "value": 139.185,
        "_internal_originalTime": 1689737395000
    },
    {
        "time": 1689737396000,
        "value": 139.189,
        "_internal_originalTime": 1689737396000
    },
    {
        "time": 1689737397000,
        "value": 139.193,
        "_internal_originalTime": 1689737397000
    },
    {
        "time": 1689737400000,
        "value": 139.17,
        "_internal_originalTime": 1689737400000
    },
    {
        "time": 1689737401000,
        "value": 139.192,
        "_internal_originalTime": 1689737401000
    },
    {
        "time": 1689737402000,
        "value": 139.191,
        "_internal_originalTime": 1689737402000
    },
    {
        "time": 1689737403000,
        "value": 139.191,
        "_internal_originalTime": 1689737403000
    },
    {
        "time": 1689737404000,
        "value": 139.187,
        "_internal_originalTime": 1689737404000
    },
    {
        "time": 1689737406000,
        "value": 139.193,
        "_internal_originalTime": 1689737406000
    },
    {
        "time": 1689737407000,
        "value": 139.192,
        "_internal_originalTime": 1689737407000
    },
    {
        "time": 1689737408000,
        "value": 139.193,
        "_internal_originalTime": 1689737408000
    },
    {
        "time": 1689737410000,
        "value": 139.19,
        "_internal_originalTime": 1689737410000
    },
    {
        "time": 1689737411000,
        "value": 139.191,
        "_internal_originalTime": 1689737411000
    },
    {
        "time": 1689737412000,
        "value": 139.19,
        "_internal_originalTime": 1689737412000
    },
    {
        "time": 1689737413000,
        "value": 139.191,
        "_internal_originalTime": 1689737413000
    },
    {
        "time": 1689737416000,
        "value": 139.188,
        "_internal_originalTime": 1689737416000
    },
    {
        "time": 1689737417000,
        "value": 139.189,
        "_internal_originalTime": 1689737417000
    },
    {
        "time": 1689737418000,
        "value": 139.188,
        "_internal_originalTime": 1689737418000
    },
    {
        "time": 1689737419000,
        "value": 139.189,
        "_internal_originalTime": 1689737419000
    },
    {
        "time": 1689737421000,
        "value": 139.188,
        "_internal_originalTime": 1689737421000
    },
    {
        "time": 1689737422000,
        "value": 139.189,
        "_internal_originalTime": 1689737422000
    },
    {
        "time": 1689737423000,
        "value": 139.188,
        "_internal_originalTime": 1689737423000
    },
    {
        "time": 1689737424000,
        "value": 139.189,
        "_internal_originalTime": 1689737424000
    },
    {
        "time": 1689737426000,
        "value": 139.187,
        "_internal_originalTime": 1689737426000
    },
    {
        "time": 1689737427000,
        "value": 139.188,
        "_internal_originalTime": 1689737427000
    },
    {
        "time": 1689737428000,
        "value": 139.19,
        "_internal_originalTime": 1689737428000
    },
    {
        "time": 1689737433000,
        "value": 139.19,
        "_internal_originalTime": 1689737433000
    },
    {
        "time": 1689737434000,
        "value": 139.178,
        "_internal_originalTime": 1689737434000
    },
    {
        "time": 1689737437000,
        "value": 139.182,
        "_internal_originalTime": 1689737437000
    },
    {
        "time": 1689737438000,
        "value": 139.179,
        "_internal_originalTime": 1689737438000
    },
    {
        "time": 1689737439000,
        "value": 139.182,
        "_internal_originalTime": 1689737439000
    },
    {
        "time": 1689737440000,
        "value": 139.18,
        "_internal_originalTime": 1689737440000
    },
    {
        "time": 1689737441000,
        "value": 139.183,
        "_internal_originalTime": 1689737441000
    },
    {
        "time": 1689737443000,
        "value": 139.178,
        "_internal_originalTime": 1689737443000
    },
    {
        "time": 1689737445000,
        "value": 139.19,
        "_internal_originalTime": 1689737445000
    },
    {
        "time": 1689737446000,
        "value": 139.192,
        "_internal_originalTime": 1689737446000
    },
    {
        "time": 1689737447000,
        "value": 139.195,
        "_internal_originalTime": 1689737447000
    },
    {
        "time": 1689737448000,
        "value": 139.19,
        "_internal_originalTime": 1689737448000
    },
    {
        "time": 1689737450000,
        "value": 139.189,
        "_internal_originalTime": 1689737450000
    },
    {
        "time": 1689737452000,
        "value": 139.19,
        "_internal_originalTime": 1689737452000
    },
    {
        "time": 1689737453000,
        "value": 139.19,
        "_internal_originalTime": 1689737453000
    },
    {
        "time": 1689737454000,
        "value": 139.189,
        "_internal_originalTime": 1689737454000
    },
    {
        "time": 1689737455000,
        "value": 139.19,
        "_internal_originalTime": 1689737455000
    },
    {
        "time": 1689737457000,
        "value": 139.19,
        "_internal_originalTime": 1689737457000
    },
    {
        "time": 1689737458000,
        "value": 139.188,
        "_internal_originalTime": 1689737458000
    },
    {
        "time": 1689737459000,
        "value": 139.19,
        "_internal_originalTime": 1689737459000
    },
    {
        "time": 1689737461000,
        "value": 139.192,
        "_internal_originalTime": 1689737461000
    },
    {
        "time": 1689737463000,
        "value": 139.194,
        "_internal_originalTime": 1689737463000
    },
    {
        "time": 1689737464000,
        "value": 139.193,
        "_internal_originalTime": 1689737464000
    },
    {
        "time": 1689737465000,
        "value": 139.201,
        "_internal_originalTime": 1689737465000
    },
    {
        "time": 1689737467000,
        "value": 139.198,
        "_internal_originalTime": 1689737467000
    },
    {
        "time": 1689737468000,
        "value": 139.205,
        "_internal_originalTime": 1689737468000
    },
    {
        "time": 1689737469000,
        "value": 139.2,
        "_internal_originalTime": 1689737469000
    },
    {
        "time": 1689737471000,
        "value": 139.196,
        "_internal_originalTime": 1689737471000
    },
    {
        "time": 1689737472000,
        "value": 139.198,
        "_internal_originalTime": 1689737472000
    },
    {
        "time": 1689737473000,
        "value": 139.198,
        "_internal_originalTime": 1689737473000
    },
    {
        "time": 1689737474000,
        "value": 139.194,
        "_internal_originalTime": 1689737474000
    },
    {
        "time": 1689737475000,
        "value": 139.189,
        "_internal_originalTime": 1689737475000
    },
    {
        "time": 1689737477000,
        "value": 139.189,
        "_internal_originalTime": 1689737477000
    },
    {
        "time": 1689737478000,
        "value": 139.188,
        "_internal_originalTime": 1689737478000
    },
    {
        "time": 1689737479000,
        "value": 139.188,
        "_internal_originalTime": 1689737479000
    },
    {
        "time": 1689737480000,
        "value": 139.19,
        "_internal_originalTime": 1689737480000
    },
    {
        "time": 1689737481000,
        "value": 139.188,
        "_internal_originalTime": 1689737481000
    },
    {
        "time": 1689737483000,
        "value": 139.186,
        "_internal_originalTime": 1689737483000
    },
    {
        "time": 1689737484000,
        "value": 139.187,
        "_internal_originalTime": 1689737484000
    },
    {
        "time": 1689737485000,
        "value": 139.188,
        "_internal_originalTime": 1689737485000
    },
    {
        "time": 1689737487000,
        "value": 139.188,
        "_internal_originalTime": 1689737487000
    },
    {
        "time": 1689737488000,
        "value": 139.19,
        "_internal_originalTime": 1689737488000
    },
    {
        "time": 1689737489000,
        "value": 139.187,
        "_internal_originalTime": 1689737489000
    },
    {
        "time": 1689737490000,
        "value": 139.189,
        "_internal_originalTime": 1689737490000
    },
    {
        "time": 1689737491000,
        "value": 139.191,
        "_internal_originalTime": 1689737491000
    },
    {
        "time": 1689737493000,
        "value": 139.187,
        "_internal_originalTime": 1689737493000
    },
    {
        "time": 1689737494000,
        "value": 139.189,
        "_internal_originalTime": 1689737494000
    },
    {
        "time": 1689737495000,
        "value": 139.192,
        "_internal_originalTime": 1689737495000
    },
    {
        "time": 1689737497000,
        "value": 139.191,
        "_internal_originalTime": 1689737497000
    },
    {
        "time": 1689737498000,
        "value": 139.189,
        "_internal_originalTime": 1689737498000
    },
    {
        "time": 1689737500000,
        "value": 139.199,
        "_internal_originalTime": 1689737500000
    },
    {
        "time": 1689737506000,
        "value": 139.2,
        "_internal_originalTime": 1689737506000
    },
    {
        "time": 1689737508000,
        "value": 139.196,
        "_internal_originalTime": 1689737508000
    },
    {
        "time": 1689737509000,
        "value": 139.197,
        "_internal_originalTime": 1689737509000
    },
    {
        "time": 1689737511000,
        "value": 139.199,
        "_internal_originalTime": 1689737511000
    },
    {
        "time": 1689737512000,
        "value": 139.201,
        "_internal_originalTime": 1689737512000
    },
    {
        "time": 1689737514000,
        "value": 139.201,
        "_internal_originalTime": 1689737514000
    },
    {
        "time": 1689737515000,
        "value": 139.202,
        "_internal_originalTime": 1689737515000
    },
    {
        "time": 1689737517000,
        "value": 139.203,
        "_internal_originalTime": 1689737517000
    },
    {
        "time": 1689737518000,
        "value": 139.203,
        "_internal_originalTime": 1689737518000
    },
    {
        "time": 1689737519000,
        "value": 139.201,
        "_internal_originalTime": 1689737519000
    },
    {
        "time": 1689737520000,
        "value": 139.201,
        "_internal_originalTime": 1689737520000
    },
    {
        "time": 1689737522000,
        "value": 139.201,
        "_internal_originalTime": 1689737522000
    },
    {
        "time": 1689737523000,
        "value": 139.201,
        "_internal_originalTime": 1689737523000
    },
    {
        "time": 1689737524000,
        "value": 139.2,
        "_internal_originalTime": 1689737524000
    },
    {
        "time": 1689737525000,
        "value": 139.199,
        "_internal_originalTime": 1689737525000
    },
    {
        "time": 1689737527000,
        "value": 139.2,
        "_internal_originalTime": 1689737527000
    },
    {
        "time": 1689737528000,
        "value": 139.196,
        "_internal_originalTime": 1689737528000
    },
    {
        "time": 1689737529000,
        "value": 139.2,
        "_internal_originalTime": 1689737529000
    },
    {
        "time": 1689737534000,
        "value": 139.198,
        "_internal_originalTime": 1689737534000
    },
    {
        "time": 1689737536000,
        "value": 139.198,
        "_internal_originalTime": 1689737536000
    },
    {
        "time": 1689737537000,
        "value": 139.198,
        "_internal_originalTime": 1689737537000
    },
    {
        "time": 1689737539000,
        "value": 139.198,
        "_internal_originalTime": 1689737539000
    },
    {
        "time": 1689737540000,
        "value": 139.199,
        "_internal_originalTime": 1689737540000
    },
    {
        "time": 1689737542000,
        "value": 139.199,
        "_internal_originalTime": 1689737542000
    },
    {
        "time": 1689737543000,
        "value": 139.199,
        "_internal_originalTime": 1689737543000
    },
    {
        "time": 1689737545000,
        "value": 139.197,
        "_internal_originalTime": 1689737545000
    },
    {
        "time": 1689737547000,
        "value": 139.197,
        "_internal_originalTime": 1689737547000
    },
    {
        "time": 1689737548000,
        "value": 139.199,
        "_internal_originalTime": 1689737548000
    },
    {
        "time": 1689737549000,
        "value": 139.197,
        "_internal_originalTime": 1689737549000
    },
    {
        "time": 1689737550000,
        "value": 139.199,
        "_internal_originalTime": 1689737550000
    },
    {
        "time": 1689737552000,
        "value": 139.196,
        "_internal_originalTime": 1689737552000
    },
    {
        "time": 1689737555000,
        "value": 139.193,
        "_internal_originalTime": 1689737555000
    },
    {
        "time": 1689737556000,
        "value": 139.188,
        "_internal_originalTime": 1689737556000
    },
    {
        "time": 1689737557000,
        "value": 139.193,
        "_internal_originalTime": 1689737557000
    },
    {
        "time": 1689737559000,
        "value": 139.192,
        "_internal_originalTime": 1689737559000
    },
    {
        "time": 1689737560000,
        "value": 139.19,
        "_internal_originalTime": 1689737560000
    },
    {
        "time": 1689737561000,
        "value": 139.191,
        "_internal_originalTime": 1689737561000
    },
    {
        "time": 1689737563000,
        "value": 139.187,
        "_internal_originalTime": 1689737563000
    },
    {
        "time": 1689737564000,
        "value": 139.188,
        "_internal_originalTime": 1689737564000
    },
    {
        "time": 1689737566000,
        "value": 139.191,
        "_internal_originalTime": 1689737566000
    },
    {
        "time": 1689737567000,
        "value": 139.19,
        "_internal_originalTime": 1689737567000
    },
    {
        "time": 1689737569000,
        "value": 139.188,
        "_internal_originalTime": 1689737569000
    },
    {
        "time": 1689737570000,
        "value": 139.192,
        "_internal_originalTime": 1689737570000
    },
    {
        "time": 1689737572000,
        "value": 139.188,
        "_internal_originalTime": 1689737572000
    },
    {
        "time": 1689737573000,
        "value": 139.19,
        "_internal_originalTime": 1689737573000
    },
    {
        "time": 1689737574000,
        "value": 139.19,
        "_internal_originalTime": 1689737574000
    },
    {
        "time": 1689737576000,
        "value": 139.185,
        "_internal_originalTime": 1689737576000
    },
    {
        "time": 1689737577000,
        "value": 139.189,
        "_internal_originalTime": 1689737577000
    },
    {
        "time": 1689737579000,
        "value": 139.19,
        "_internal_originalTime": 1689737579000
    },
    {
        "time": 1689737580000,
        "value": 139.19,
        "_internal_originalTime": 1689737580000
    },
    {
        "time": 1689737581000,
        "value": 139.192,
        "_internal_originalTime": 1689737581000
    },
    {
        "time": 1689737582000,
        "value": 139.189,
        "_internal_originalTime": 1689737582000
    },
    {
        "time": 1689737584000,
        "value": 139.195,
        "_internal_originalTime": 1689737584000
    },
    {
        "time": 1689737585000,
        "value": 139.196,
        "_internal_originalTime": 1689737585000
    },
    {
        "time": 1689737587000,
        "value": 139.198,
        "_internal_originalTime": 1689737587000
    },
    {
        "time": 1689737589000,
        "value": 139.189,
        "_internal_originalTime": 1689737589000
    },
    {
        "time": 1689737590000,
        "value": 139.19,
        "_internal_originalTime": 1689737590000
    },
    {
        "time": 1689737591000,
        "value": 139.186,
        "_internal_originalTime": 1689737591000
    },
    {
        "time": 1689737592000,
        "value": 139.187,
        "_internal_originalTime": 1689737592000
    },
    {
        "time": 1689737594000,
        "value": 139.187,
        "_internal_originalTime": 1689737594000
    },
    {
        "time": 1689737595000,
        "value": 139.197,
        "_internal_originalTime": 1689737595000
    },
    {
        "time": 1689737596000,
        "value": 139.203,
        "_internal_originalTime": 1689737596000
    },
    {
        "time": 1689737597000,
        "value": 139.203,
        "_internal_originalTime": 1689737597000
    },
    {
        "time": 1689737598000,
        "value": 139.202,
        "_internal_originalTime": 1689737598000
    },
    {
        "time": 1689737600000,
        "value": 139.204,
        "_internal_originalTime": 1689737600000
    },
    {
        "time": 1689737601000,
        "value": 139.2,
        "_internal_originalTime": 1689737601000
    },
    {
        "time": 1689737603000,
        "value": 139.204,
        "_internal_originalTime": 1689737603000
    },
    {
        "time": 1689737604000,
        "value": 139.203,
        "_internal_originalTime": 1689737604000
    },
    {
        "time": 1689737605000,
        "value": 139.203,
        "_internal_originalTime": 1689737605000
    },
    {
        "time": 1689737606000,
        "value": 139.203,
        "_internal_originalTime": 1689737606000
    },
    {
        "time": 1689737609000,
        "value": 139.203,
        "_internal_originalTime": 1689737609000
    },
    {
        "time": 1689737610000,
        "value": 139.202,
        "_internal_originalTime": 1689737610000
    },
    {
        "time": 1689737611000,
        "value": 139.2,
        "_internal_originalTime": 1689737611000
    },
    {
        "time": 1689737613000,
        "value": 139.2,
        "_internal_originalTime": 1689737613000
    },
    {
        "time": 1689737614000,
        "value": 139.2,
        "_internal_originalTime": 1689737614000
    },
    {
        "time": 1689737616000,
        "value": 139.2,
        "_internal_originalTime": 1689737616000
    },
    {
        "time": 1689737617000,
        "value": 139.201,
        "_internal_originalTime": 1689737617000
    },
    {
        "time": 1689737618000,
        "value": 139.201,
        "_internal_originalTime": 1689737618000
    },
    {
        "time": 1689737620000,
        "value": 139.198,
        "_internal_originalTime": 1689737620000
    },
    {
        "time": 1689737621000,
        "value": 139.198,
        "_internal_originalTime": 1689737621000
    },
    {
        "time": 1689737622000,
        "value": 139.203,
        "_internal_originalTime": 1689737622000
    },
    {
        "time": 1689737624000,
        "value": 139.202,
        "_internal_originalTime": 1689737624000
    },
    {
        "time": 1689737628000,
        "value": 139.201,
        "_internal_originalTime": 1689737628000
    },
    {
        "time": 1689737629000,
        "value": 139.199,
        "_internal_originalTime": 1689737629000
    },
    {
        "time": 1689737631000,
        "value": 139.199,
        "_internal_originalTime": 1689737631000
    },
    {
        "time": 1689737632000,
        "value": 139.202,
        "_internal_originalTime": 1689737632000
    },
    {
        "time": 1689737633000,
        "value": 139.198,
        "_internal_originalTime": 1689737633000
    },
    {
        "time": 1689737635000,
        "value": 139.198,
        "_internal_originalTime": 1689737635000
    },
    {
        "time": 1689737637000,
        "value": 139.198,
        "_internal_originalTime": 1689737637000
    },
    {
        "time": 1689737640000,
        "value": 139.18,
        "_internal_originalTime": 1689737640000
    },
    {
        "time": 1689737641000,
        "value": 139.203,
        "_internal_originalTime": 1689737641000
    },
    {
        "time": 1689737643000,
        "value": 139.201,
        "_internal_originalTime": 1689737643000
    },
    {
        "time": 1689737644000,
        "value": 139.199,
        "_internal_originalTime": 1689737644000
    },
    {
        "time": 1689737646000,
        "value": 139.199,
        "_internal_originalTime": 1689737646000
    },
    {
        "time": 1689737647000,
        "value": 139.202,
        "_internal_originalTime": 1689737647000
    },
    {
        "time": 1689737649000,
        "value": 139.202,
        "_internal_originalTime": 1689737649000
    },
    {
        "time": 1689737651000,
        "value": 139.2,
        "_internal_originalTime": 1689737651000
    },
    {
        "time": 1689737653000,
        "value": 139.2,
        "_internal_originalTime": 1689737653000
    },
    {
        "time": 1689737654000,
        "value": 139.201,
        "_internal_originalTime": 1689737654000
    },
    {
        "time": 1689737655000,
        "value": 139.201,
        "_internal_originalTime": 1689737655000
    },
    {
        "time": 1689737656000,
        "value": 139.201,
        "_internal_originalTime": 1689737656000
    },
    {
        "time": 1689737657000,
        "value": 139.201,
        "_internal_originalTime": 1689737657000
    },
    {
        "time": 1689737659000,
        "value": 139.203,
        "_internal_originalTime": 1689737659000
    },
    {
        "time": 1689737660000,
        "value": 139.202,
        "_internal_originalTime": 1689737660000
    },
    {
        "time": 1689737661000,
        "value": 139.211,
        "_internal_originalTime": 1689737661000
    },
    {
        "time": 1689737662000,
        "value": 139.211,
        "_internal_originalTime": 1689737662000
    },
    {
        "time": 1689737664000,
        "value": 139.212,
        "_internal_originalTime": 1689737664000
    },
    {
        "time": 1689737666000,
        "value": 139.206,
        "_internal_originalTime": 1689737666000
    },
    {
        "time": 1689737668000,
        "value": 139.209,
        "_internal_originalTime": 1689737668000
    },
    {
        "time": 1689737671000,
        "value": 139.215,
        "_internal_originalTime": 1689737671000
    },
    {
        "time": 1689737672000,
        "value": 139.215,
        "_internal_originalTime": 1689737672000
    },
    {
        "time": 1689737674000,
        "value": 139.209,
        "_internal_originalTime": 1689737674000
    },
    {
        "time": 1689737675000,
        "value": 139.221,
        "_internal_originalTime": 1689737675000
    },
    {
        "time": 1689737676000,
        "value": 139.218,
        "_internal_originalTime": 1689737676000
    },
    {
        "time": 1689737677000,
        "value": 139.222,
        "_internal_originalTime": 1689737677000
    },
    {
        "time": 1689737679000,
        "value": 139.219,
        "_internal_originalTime": 1689737679000
    },
    {
        "time": 1689737680000,
        "value": 139.221,
        "_internal_originalTime": 1689737680000
    },
    {
        "time": 1689737681000,
        "value": 139.219,
        "_internal_originalTime": 1689737681000
    },
    {
        "time": 1689737683000,
        "value": 139.22,
        "_internal_originalTime": 1689737683000
    },
    {
        "time": 1689737684000,
        "value": 139.221,
        "_internal_originalTime": 1689737684000
    },
    {
        "time": 1689737686000,
        "value": 139.222,
        "_internal_originalTime": 1689737686000
    },
    {
        "time": 1689737687000,
        "value": 139.22,
        "_internal_originalTime": 1689737687000
    },
    {
        "time": 1689737688000,
        "value": 139.218,
        "_internal_originalTime": 1689737688000
    },
    {
        "time": 1689737690000,
        "value": 139.218,
        "_internal_originalTime": 1689737690000
    },
    {
        "time": 1689737691000,
        "value": 139.219,
        "_internal_originalTime": 1689737691000
    },
    {
        "time": 1689737693000,
        "value": 139.216,
        "_internal_originalTime": 1689737693000
    },
    {
        "time": 1689737694000,
        "value": 139.217,
        "_internal_originalTime": 1689737694000
    },
    {
        "time": 1689737695000,
        "value": 139.219,
        "_internal_originalTime": 1689737695000
    },
    {
        "time": 1689737697000,
        "value": 139.219,
        "_internal_originalTime": 1689737697000
    },
    {
        "time": 1689737699000,
        "value": 139.22,
        "_internal_originalTime": 1689737699000
    },
    {
        "time": 1689737701000,
        "value": 139.218,
        "_internal_originalTime": 1689737701000
    },
    {
        "time": 1689737702000,
        "value": 139.221,
        "_internal_originalTime": 1689737702000
    },
    {
        "time": 1689737703000,
        "value": 139.227,
        "_internal_originalTime": 1689737703000
    },
    {
        "time": 1689737704000,
        "value": 139.23,
        "_internal_originalTime": 1689737704000
    },
    {
        "time": 1689737705000,
        "value": 139.231,
        "_internal_originalTime": 1689737705000
    },
    {
        "time": 1689737707000,
        "value": 139.219,
        "_internal_originalTime": 1689737707000
    },
    {
        "time": 1689737708000,
        "value": 139.22,
        "_internal_originalTime": 1689737708000
    },
    {
        "time": 1689737709000,
        "value": 139.219,
        "_internal_originalTime": 1689737709000
    },
    {
        "time": 1689737710000,
        "value": 139.22,
        "_internal_originalTime": 1689737710000
    },
    {
        "time": 1689737711000,
        "value": 139.221,
        "_internal_originalTime": 1689737711000
    },
    {
        "time": 1689737713000,
        "value": 139.22,
        "_internal_originalTime": 1689737713000
    },
    {
        "time": 1689737715000,
        "value": 139.22,
        "_internal_originalTime": 1689737715000
    },
    {
        "time": 1689737717000,
        "value": 139.219,
        "_internal_originalTime": 1689737717000
    },
    {
        "time": 1689737718000,
        "value": 139.218,
        "_internal_originalTime": 1689737718000
    },
    {
        "time": 1689737719000,
        "value": 139.219,
        "_internal_originalTime": 1689737719000
    },
    {
        "time": 1689737720000,
        "value": 139.22,
        "_internal_originalTime": 1689737720000
    },
    {
        "time": 1689737721000,
        "value": 139.216,
        "_internal_originalTime": 1689737721000
    },
    {
        "time": 1689737722000,
        "value": 139.22,
        "_internal_originalTime": 1689737722000
    },
    {
        "time": 1689737726000,
        "value": 139.214,
        "_internal_originalTime": 1689737726000
    },
    {
        "time": 1689737728000,
        "value": 139.227,
        "_internal_originalTime": 1689737728000
    },
    {
        "time": 1689737729000,
        "value": 139.224,
        "_internal_originalTime": 1689737729000
    },
    {
        "time": 1689737730000,
        "value": 139.227,
        "_internal_originalTime": 1689737730000
    },
    {
        "time": 1689737731000,
        "value": 139.228,
        "_internal_originalTime": 1689737731000
    },
    {
        "time": 1689737732000,
        "value": 139.228,
        "_internal_originalTime": 1689737732000
    },
    {
        "time": 1689737736000,
        "value": 139.224,
        "_internal_originalTime": 1689737736000
    },
    {
        "time": 1689737739000,
        "value": 139.226,
        "_internal_originalTime": 1689737739000
    },
    {
        "time": 1689737740000,
        "value": 139.224,
        "_internal_originalTime": 1689737740000
    },
    {
        "time": 1689737741000,
        "value": 139.224,
        "_internal_originalTime": 1689737741000
    },
    {
        "time": 1689737742000,
        "value": 139.229,
        "_internal_originalTime": 1689737742000
    },
    {
        "time": 1689737743000,
        "value": 139.228,
        "_internal_originalTime": 1689737743000
    },
    {
        "time": 1689737744000,
        "value": 139.227,
        "_internal_originalTime": 1689737744000
    },
    {
        "time": 1689737746000,
        "value": 139.223,
        "_internal_originalTime": 1689737746000
    },
    {
        "time": 1689737747000,
        "value": 139.233,
        "_internal_originalTime": 1689737747000
    },
    {
        "time": 1689737748000,
        "value": 139.24,
        "_internal_originalTime": 1689737748000
    },
    {
        "time": 1689737749000,
        "value": 139.236,
        "_internal_originalTime": 1689737749000
    },
    {
        "time": 1689737751000,
        "value": 139.241,
        "_internal_originalTime": 1689737751000
    },
    {
        "time": 1689737753000,
        "value": 139.241,
        "_internal_originalTime": 1689737753000
    },
    {
        "time": 1689737755000,
        "value": 139.236,
        "_internal_originalTime": 1689737755000
    },
    {
        "time": 1689737756000,
        "value": 139.235,
        "_internal_originalTime": 1689737756000
    },
    {
        "time": 1689737758000,
        "value": 139.236,
        "_internal_originalTime": 1689737758000
    },
    {
        "time": 1689737759000,
        "value": 139.24,
        "_internal_originalTime": 1689737759000
    },
    {
        "time": 1689737760000,
        "value": 139.242,
        "_internal_originalTime": 1689737760000
    },
    {
        "time": 1689737761000,
        "value": 139.236,
        "_internal_originalTime": 1689737761000
    },
    {
        "time": 1689737763000,
        "value": 139.238,
        "_internal_originalTime": 1689737763000
    },
    {
        "time": 1689737765000,
        "value": 139.24,
        "_internal_originalTime": 1689737765000
    },
    {
        "time": 1689737767000,
        "value": 139.235,
        "_internal_originalTime": 1689737767000
    },
    {
        "time": 1689737769000,
        "value": 139.238,
        "_internal_originalTime": 1689737769000
    },
    {
        "time": 1689737772000,
        "value": 139.238,
        "_internal_originalTime": 1689737772000
    },
    {
        "time": 1689737773000,
        "value": 139.241,
        "_internal_originalTime": 1689737773000
    },
    {
        "time": 1689737775000,
        "value": 139.238,
        "_internal_originalTime": 1689737775000
    },
    {
        "time": 1689737776000,
        "value": 139.242,
        "_internal_originalTime": 1689737776000
    },
    {
        "time": 1689737777000,
        "value": 139.236,
        "_internal_originalTime": 1689737777000
    },
    {
        "time": 1689737778000,
        "value": 139.237,
        "_internal_originalTime": 1689737778000
    },
    {
        "time": 1689737779000,
        "value": 139.237,
        "_internal_originalTime": 1689737779000
    },
    {
        "time": 1689737781000,
        "value": 139.24,
        "_internal_originalTime": 1689737781000
    },
    {
        "time": 1689737782000,
        "value": 139.237,
        "_internal_originalTime": 1689737782000
    },
    {
        "time": 1689737784000,
        "value": 139.236,
        "_internal_originalTime": 1689737784000
    },
    {
        "time": 1689737786000,
        "value": 139.236,
        "_internal_originalTime": 1689737786000
    },
    {
        "time": 1689737788000,
        "value": 139.239,
        "_internal_originalTime": 1689737788000
    },
    {
        "time": 1689737790000,
        "value": 139.236,
        "_internal_originalTime": 1689737790000
    },
    {
        "time": 1689737791000,
        "value": 139.238,
        "_internal_originalTime": 1689737791000
    },
    {
        "time": 1689737796000,
        "value": 139.238,
        "_internal_originalTime": 1689737796000
    },
    {
        "time": 1689737798000,
        "value": 139.235,
        "_internal_originalTime": 1689737798000
    },
    {
        "time": 1689737800000,
        "value": 139.238,
        "_internal_originalTime": 1689737800000
    },
    {
        "time": 1689737802000,
        "value": 139.239,
        "_internal_originalTime": 1689737802000
    },
    {
        "time": 1689737803000,
        "value": 139.238,
        "_internal_originalTime": 1689737803000
    },
    {
        "time": 1689737804000,
        "value": 139.238,
        "_internal_originalTime": 1689737804000
    },
    {
        "time": 1689737806000,
        "value": 139.238,
        "_internal_originalTime": 1689737806000
    },
    {
        "time": 1689737808000,
        "value": 139.238,
        "_internal_originalTime": 1689737808000
    },
    {
        "time": 1689737809000,
        "value": 139.236,
        "_internal_originalTime": 1689737809000
    },
    {
        "time": 1689737811000,
        "value": 139.241,
        "_internal_originalTime": 1689737811000
    },
    {
        "time": 1689737813000,
        "value": 139.235,
        "_internal_originalTime": 1689737813000
    },
    {
        "time": 1689737814000,
        "value": 139.236,
        "_internal_originalTime": 1689737814000
    },
    {
        "time": 1689737816000,
        "value": 139.238,
        "_internal_originalTime": 1689737816000
    },
    {
        "time": 1689737818000,
        "value": 139.238,
        "_internal_originalTime": 1689737818000
    },
    {
        "time": 1689737819000,
        "value": 139.239,
        "_internal_originalTime": 1689737819000
    },
    {
        "time": 1689737820000,
        "value": 139.243,
        "_internal_originalTime": 1689737820000
    },
    {
        "time": 1689737822000,
        "value": 139.243,
        "_internal_originalTime": 1689737822000
    },
    {
        "time": 1689737823000,
        "value": 139.242,
        "_internal_originalTime": 1689737823000
    },
    {
        "time": 1689737824000,
        "value": 139.239,
        "_internal_originalTime": 1689737824000
    },
    {
        "time": 1689737825000,
        "value": 139.236,
        "_internal_originalTime": 1689737825000
    },
    {
        "time": 1689737826000,
        "value": 139.236,
        "_internal_originalTime": 1689737826000
    },
    {
        "time": 1689737827000,
        "value": 139.236,
        "_internal_originalTime": 1689737827000
    },
    {
        "time": 1689737829000,
        "value": 139.236,
        "_internal_originalTime": 1689737829000
    },
    {
        "time": 1689737834000,
        "value": 139.236,
        "_internal_originalTime": 1689737834000
    },
    {
        "time": 1689737836000,
        "value": 139.238,
        "_internal_originalTime": 1689737836000
    },
    {
        "time": 1689737839000,
        "value": 139.238,
        "_internal_originalTime": 1689737839000
    },
    {
        "time": 1689737840000,
        "value": 139.241,
        "_internal_originalTime": 1689737840000
    },
    {
        "time": 1689737844000,
        "value": 139.24,
        "_internal_originalTime": 1689737844000
    },
    {
        "time": 1689737846000,
        "value": 139.234,
        "_internal_originalTime": 1689737846000
    },
    {
        "time": 1689737852000,
        "value": 139.241,
        "_internal_originalTime": 1689737852000
    },
    {
        "time": 1689737853000,
        "value": 139.236,
        "_internal_originalTime": 1689737853000
    },
    {
        "time": 1689737854000,
        "value": 139.239,
        "_internal_originalTime": 1689737854000
    },
    {
        "time": 1689737856000,
        "value": 139.235,
        "_internal_originalTime": 1689737856000
    },
    {
        "time": 1689737857000,
        "value": 139.238,
        "_internal_originalTime": 1689737857000
    },
    {
        "time": 1689737858000,
        "value": 139.24,
        "_internal_originalTime": 1689737858000
    },
    {
        "time": 1689737860000,
        "value": 139.235,
        "_internal_originalTime": 1689737860000
    },
    {
        "time": 1689737862000,
        "value": 139.24,
        "_internal_originalTime": 1689737862000
    },
    {
        "time": 1689737866000,
        "value": 139.236,
        "_internal_originalTime": 1689737866000
    },
    {
        "time": 1689737868000,
        "value": 139.237,
        "_internal_originalTime": 1689737868000
    },
    {
        "time": 1689737870000,
        "value": 139.237,
        "_internal_originalTime": 1689737870000
    },
    {
        "time": 1689737873000,
        "value": 139.239,
        "_internal_originalTime": 1689737873000
    },
    {
        "time": 1689737877000,
        "value": 139.237,
        "_internal_originalTime": 1689737877000
    },
    {
        "time": 1689737878000,
        "value": 139.236,
        "_internal_originalTime": 1689737878000
    },
    {
        "time": 1689737880000,
        "value": 139.24,
        "_internal_originalTime": 1689737880000
    },
    {
        "time": 1689737882000,
        "value": 139.241,
        "_internal_originalTime": 1689737882000
    },
    {
        "time": 1689737883000,
        "value": 139.237,
        "_internal_originalTime": 1689737883000
    },
    {
        "time": 1689737884000,
        "value": 139.236,
        "_internal_originalTime": 1689737884000
    },
    {
        "time": 1689737885000,
        "value": 139.24,
        "_internal_originalTime": 1689737885000
    },
    {
        "time": 1689737887000,
        "value": 139.24,
        "_internal_originalTime": 1689737887000
    },
    {
        "time": 1689737888000,
        "value": 139.239,
        "_internal_originalTime": 1689737888000
    },
    {
        "time": 1689737890000,
        "value": 139.228,
        "_internal_originalTime": 1689737890000
    },
    {
        "time": 1689737891000,
        "value": 139.229,
        "_internal_originalTime": 1689737891000
    },
    {
        "time": 1689737892000,
        "value": 139.228,
        "_internal_originalTime": 1689737892000
    },
    {
        "time": 1689737894000,
        "value": 139.228,
        "_internal_originalTime": 1689737894000
    },
    {
        "time": 1689737895000,
        "value": 139.229,
        "_internal_originalTime": 1689737895000
    },
    {
        "time": 1689737897000,
        "value": 139.231,
        "_internal_originalTime": 1689737897000
    },
    {
        "time": 1689737898000,
        "value": 139.227,
        "_internal_originalTime": 1689737898000
    },
    {
        "time": 1689737899000,
        "value": 139.225,
        "_internal_originalTime": 1689737899000
    },
    {
        "time": 1689737901000,
        "value": 139.225,
        "_internal_originalTime": 1689737901000
    },
    {
        "time": 1689737902000,
        "value": 139.225,
        "_internal_originalTime": 1689737902000
    },
    {
        "time": 1689737903000,
        "value": 139.227,
        "_internal_originalTime": 1689737903000
    },
    {
        "time": 1689737904000,
        "value": 139.224,
        "_internal_originalTime": 1689737904000
    },
    {
        "time": 1689737906000,
        "value": 139.221,
        "_internal_originalTime": 1689737906000
    },
    {
        "time": 1689737911000,
        "value": 139.226,
        "_internal_originalTime": 1689737911000
    },
    {
        "time": 1689737912000,
        "value": 139.228,
        "_internal_originalTime": 1689737912000
    },
    {
        "time": 1689737913000,
        "value": 139.226,
        "_internal_originalTime": 1689737913000
    },
    {
        "time": 1689737914000,
        "value": 139.227,
        "_internal_originalTime": 1689737914000
    },
    {
        "time": 1689737915000,
        "value": 139.226,
        "_internal_originalTime": 1689737915000
    },
    {
        "time": 1689737916000,
        "value": 139.229,
        "_internal_originalTime": 1689737916000
    },
    {
        "time": 1689737917000,
        "value": 139.227,
        "_internal_originalTime": 1689737917000
    },
    {
        "time": 1689737918000,
        "value": 139.226,
        "_internal_originalTime": 1689737918000
    },
    {
        "time": 1689737919000,
        "value": 139.228,
        "_internal_originalTime": 1689737919000
    },
    {
        "time": 1689737922000,
        "value": 139.226,
        "_internal_originalTime": 1689737922000
    },
    {
        "time": 1689737923000,
        "value": 139.228,
        "_internal_originalTime": 1689737923000
    },
    {
        "time": 1689737925000,
        "value": 139.226,
        "_internal_originalTime": 1689737925000
    },
    {
        "time": 1689737926000,
        "value": 139.226,
        "_internal_originalTime": 1689737926000
    },
    {
        "time": 1689737927000,
        "value": 139.227,
        "_internal_originalTime": 1689737927000
    },
    {
        "time": 1689737929000,
        "value": 139.226,
        "_internal_originalTime": 1689737929000
    },
    {
        "time": 1689737930000,
        "value": 139.226,
        "_internal_originalTime": 1689737930000
    },
    {
        "time": 1689737932000,
        "value": 139.228,
        "_internal_originalTime": 1689737932000
    },
    {
        "time": 1689737934000,
        "value": 139.225,
        "_internal_originalTime": 1689737934000
    },
    {
        "time": 1689737935000,
        "value": 139.225,
        "_internal_originalTime": 1689737935000
    },
    {
        "time": 1689737936000,
        "value": 139.222,
        "_internal_originalTime": 1689737936000
    },
    {
        "time": 1689737937000,
        "value": 139.224,
        "_internal_originalTime": 1689737937000
    },
    {
        "time": 1689737939000,
        "value": 139.225,
        "_internal_originalTime": 1689737939000
    },
    {
        "time": 1689737941000,
        "value": 139.226,
        "_internal_originalTime": 1689737941000
    },
    {
        "time": 1689737942000,
        "value": 139.225,
        "_internal_originalTime": 1689737942000
    },
    {
        "time": 1689737944000,
        "value": 139.225,
        "_internal_originalTime": 1689737944000
    },
    {
        "time": 1689737945000,
        "value": 139.225,
        "_internal_originalTime": 1689737945000
    },
    {
        "time": 1689737947000,
        "value": 139.226,
        "_internal_originalTime": 1689737947000
    },
    {
        "time": 1689737949000,
        "value": 139.229,
        "_internal_originalTime": 1689737949000
    },
    {
        "time": 1689737953000,
        "value": 139.223,
        "_internal_originalTime": 1689737953000
    },
    {
        "time": 1689737954000,
        "value": 139.229,
        "_internal_originalTime": 1689737954000
    },
    {
        "time": 1689737955000,
        "value": 139.227,
        "_internal_originalTime": 1689737955000
    },
    {
        "time": 1689737956000,
        "value": 139.228,
        "_internal_originalTime": 1689737956000
    },
    {
        "time": 1689737960000,
        "value": 139.228,
        "_internal_originalTime": 1689737960000
    },
    {
        "time": 1689737965000,
        "value": 139.229,
        "_internal_originalTime": 1689737965000
    },
    {
        "time": 1689737966000,
        "value": 139.222,
        "_internal_originalTime": 1689737966000
    },
    {
        "time": 1689737969000,
        "value": 139.229,
        "_internal_originalTime": 1689737969000
    },
    {
        "time": 1689737973000,
        "value": 139.225,
        "_internal_originalTime": 1689737973000
    },
    {
        "time": 1689737974000,
        "value": 139.225,
        "_internal_originalTime": 1689737974000
    },
    {
        "time": 1689737975000,
        "value": 139.226,
        "_internal_originalTime": 1689737975000
    },
    {
        "time": 1689737976000,
        "value": 139.225,
        "_internal_originalTime": 1689737976000
    },
    {
        "time": 1689737978000,
        "value": 139.226,
        "_internal_originalTime": 1689737978000
    },
    {
        "time": 1689737980000,
        "value": 139.229,
        "_internal_originalTime": 1689737980000
    },
    {
        "time": 1689737981000,
        "value": 139.225,
        "_internal_originalTime": 1689737981000
    },
    {
        "time": 1689737984000,
        "value": 139.225,
        "_internal_originalTime": 1689737984000
    },
    {
        "time": 1689737986000,
        "value": 139.216,
        "_internal_originalTime": 1689737986000
    },
    {
        "time": 1689737987000,
        "value": 139.218,
        "_internal_originalTime": 1689737987000
    },
    {
        "time": 1689737988000,
        "value": 139.219,
        "_internal_originalTime": 1689737988000
    },
    {
        "time": 1689737989000,
        "value": 139.218,
        "_internal_originalTime": 1689737989000
    },
    {
        "time": 1689737990000,
        "value": 139.222,
        "_internal_originalTime": 1689737990000
    },
    {
        "time": 1689737992000,
        "value": 139.221,
        "_internal_originalTime": 1689737992000
    },
    {
        "time": 1689737993000,
        "value": 139.221,
        "_internal_originalTime": 1689737993000
    },
    {
        "time": 1689737994000,
        "value": 139.216,
        "_internal_originalTime": 1689737994000
    },
    {
        "time": 1689737997000,
        "value": 139.218,
        "_internal_originalTime": 1689737997000
    },
    {
        "time": 1689738000000,
        "value": 139.222,
        "_internal_originalTime": 1689738000000
    },
    {
        "time": 1689738002000,
        "value": 139.222,
        "_internal_originalTime": 1689738002000
    },
    {
        "time": 1689738003000,
        "value": 139.222,
        "_internal_originalTime": 1689738003000
    },
    {
        "time": 1689738004000,
        "value": 139.223,
        "_internal_originalTime": 1689738004000
    },
    {
        "time": 1689738006000,
        "value": 139.224,
        "_internal_originalTime": 1689738006000
    },
    {
        "time": 1689738007000,
        "value": 139.228,
        "_internal_originalTime": 1689738007000
    },
    {
        "time": 1689738009000,
        "value": 139.224,
        "_internal_originalTime": 1689738009000
    },
    {
        "time": 1689738010000,
        "value": 139.225,
        "_internal_originalTime": 1689738010000
    },
    {
        "time": 1689738012000,
        "value": 139.226,
        "_internal_originalTime": 1689738012000
    },
    {
        "time": 1689738013000,
        "value": 139.23,
        "_internal_originalTime": 1689738013000
    },
    {
        "time": 1689738015000,
        "value": 139.225,
        "_internal_originalTime": 1689738015000
    },
    {
        "time": 1689738016000,
        "value": 139.23,
        "_internal_originalTime": 1689738016000
    },
    {
        "time": 1689738017000,
        "value": 139.224,
        "_internal_originalTime": 1689738017000
    },
    {
        "time": 1689738020000,
        "value": 139.225,
        "_internal_originalTime": 1689738020000
    },
    {
        "time": 1689738022000,
        "value": 139.23,
        "_internal_originalTime": 1689738022000
    },
    {
        "time": 1689738024000,
        "value": 139.224,
        "_internal_originalTime": 1689738024000
    },
    {
        "time": 1689738025000,
        "value": 139.224,
        "_internal_originalTime": 1689738025000
    },
    {
        "time": 1689738026000,
        "value": 139.23,
        "_internal_originalTime": 1689738026000
    },
    {
        "time": 1689738027000,
        "value": 139.23,
        "_internal_originalTime": 1689738027000
    },
    {
        "time": 1689738029000,
        "value": 139.226,
        "_internal_originalTime": 1689738029000
    },
    {
        "time": 1689738031000,
        "value": 139.223,
        "_internal_originalTime": 1689738031000
    },
    {
        "time": 1689738032000,
        "value": 139.223,
        "_internal_originalTime": 1689738032000
    },
    {
        "time": 1689738034000,
        "value": 139.22,
        "_internal_originalTime": 1689738034000
    },
    {
        "time": 1689738035000,
        "value": 139.205,
        "_internal_originalTime": 1689738035000
    },
    {
        "time": 1689738037000,
        "value": 139.196,
        "_internal_originalTime": 1689738037000
    },
    {
        "time": 1689738041000,
        "value": 139.195,
        "_internal_originalTime": 1689738041000
    },
    {
        "time": 1689738042000,
        "value": 139.202,
        "_internal_originalTime": 1689738042000
    },
    {
        "time": 1689738046000,
        "value": 139.197,
        "_internal_originalTime": 1689738046000
    },
    {
        "time": 1689738048000,
        "value": 139.2,
        "_internal_originalTime": 1689738048000
    },
    {
        "time": 1689738051000,
        "value": 139.198,
        "_internal_originalTime": 1689738051000
    },
    {
        "time": 1689738052000,
        "value": 139.198,
        "_internal_originalTime": 1689738052000
    },
    {
        "time": 1689738056000,
        "value": 139.196,
        "_internal_originalTime": 1689738056000
    },
    {
        "time": 1689738058000,
        "value": 139.198,
        "_internal_originalTime": 1689738058000
    },
    {
        "time": 1689738060000,
        "value": 139.18,
        "_internal_originalTime": 1689738060000
    },
    {
        "time": 1689738061000,
        "value": 139.203,
        "_internal_originalTime": 1689738061000
    },
    {
        "time": 1689738063000,
        "value": 139.202,
        "_internal_originalTime": 1689738063000
    },
    {
        "time": 1689738064000,
        "value": 139.202,
        "_internal_originalTime": 1689738064000
    },
    {
        "time": 1689738066000,
        "value": 139.203,
        "_internal_originalTime": 1689738066000
    },
    {
        "time": 1689738070000,
        "value": 139.203,
        "_internal_originalTime": 1689738070000
    },
    {
        "time": 1689738071000,
        "value": 139.198,
        "_internal_originalTime": 1689738071000
    },
    {
        "time": 1689738073000,
        "value": 139.197,
        "_internal_originalTime": 1689738073000
    },
    {
        "time": 1689738074000,
        "value": 139.189,
        "_internal_originalTime": 1689738074000
    },
    {
        "time": 1689738075000,
        "value": 139.192,
        "_internal_originalTime": 1689738075000
    },
    {
        "time": 1689738077000,
        "value": 139.185,
        "_internal_originalTime": 1689738077000
    },
    {
        "time": 1689738078000,
        "value": 139.176,
        "_internal_originalTime": 1689738078000
    },
    {
        "time": 1689738080000,
        "value": 139.177,
        "_internal_originalTime": 1689738080000
    },
    {
        "time": 1689738081000,
        "value": 139.177,
        "_internal_originalTime": 1689738081000
    },
    {
        "time": 1689738082000,
        "value": 139.177,
        "_internal_originalTime": 1689738082000
    },
    {
        "time": 1689738084000,
        "value": 139.178,
        "_internal_originalTime": 1689738084000
    },
    {
        "time": 1689738085000,
        "value": 139.182,
        "_internal_originalTime": 1689738085000
    },
    {
        "time": 1689738086000,
        "value": 139.18,
        "_internal_originalTime": 1689738086000
    },
    {
        "time": 1689738087000,
        "value": 139.178,
        "_internal_originalTime": 1689738087000
    },
    {
        "time": 1689738088000,
        "value": 139.181,
        "_internal_originalTime": 1689738088000
    },
    {
        "time": 1689738090000,
        "value": 139.182,
        "_internal_originalTime": 1689738090000
    },
    {
        "time": 1689738093000,
        "value": 139.181,
        "_internal_originalTime": 1689738093000
    },
    {
        "time": 1689738094000,
        "value": 139.182,
        "_internal_originalTime": 1689738094000
    },
    {
        "time": 1689738095000,
        "value": 139.18,
        "_internal_originalTime": 1689738095000
    },
    {
        "time": 1689738097000,
        "value": 139.183,
        "_internal_originalTime": 1689738097000
    },
    {
        "time": 1689738098000,
        "value": 139.182,
        "_internal_originalTime": 1689738098000
    },
    {
        "time": 1689738100000,
        "value": 139.18,
        "_internal_originalTime": 1689738100000
    },
    {
        "time": 1689738102000,
        "value": 139.181,
        "_internal_originalTime": 1689738102000
    },
    {
        "time": 1689738103000,
        "value": 139.182,
        "_internal_originalTime": 1689738103000
    },
    {
        "time": 1689738104000,
        "value": 139.181,
        "_internal_originalTime": 1689738104000
    },
    {
        "time": 1689738105000,
        "value": 139.182,
        "_internal_originalTime": 1689738105000
    },
    {
        "time": 1689738107000,
        "value": 139.182,
        "_internal_originalTime": 1689738107000
    },
    {
        "time": 1689738108000,
        "value": 139.176,
        "_internal_originalTime": 1689738108000
    },
    {
        "time": 1689738109000,
        "value": 139.179,
        "_internal_originalTime": 1689738109000
    },
    {
        "time": 1689738111000,
        "value": 139.179,
        "_internal_originalTime": 1689738111000
    },
    {
        "time": 1689738112000,
        "value": 139.178,
        "_internal_originalTime": 1689738112000
    },
    {
        "time": 1689738114000,
        "value": 139.176,
        "_internal_originalTime": 1689738114000
    },
    {
        "time": 1689738115000,
        "value": 139.17,
        "_internal_originalTime": 1689738115000
    },
    {
        "time": 1689738117000,
        "value": 139.172,
        "_internal_originalTime": 1689738117000
    },
    {
        "time": 1689738118000,
        "value": 139.172,
        "_internal_originalTime": 1689738118000
    },
    {
        "time": 1689738120000,
        "value": 139.173,
        "_internal_originalTime": 1689738120000
    },
    {
        "time": 1689738121000,
        "value": 139.15,
        "_internal_originalTime": 1689738121000
    },
    {
        "time": 1689738122000,
        "value": 139.168,
        "_internal_originalTime": 1689738122000
    },
    {
        "time": 1689738123000,
        "value": 139.167,
        "_internal_originalTime": 1689738123000
    },
    {
        "time": 1689738124000,
        "value": 139.17,
        "_internal_originalTime": 1689738124000
    },
    {
        "time": 1689738125000,
        "value": 139.167,
        "_internal_originalTime": 1689738125000
    },
    {
        "time": 1689738127000,
        "value": 139.168,
        "_internal_originalTime": 1689738127000
    },
    {
        "time": 1689738128000,
        "value": 139.169,
        "_internal_originalTime": 1689738128000
    },
    {
        "time": 1689738130000,
        "value": 139.171,
        "_internal_originalTime": 1689738130000
    },
    {
        "time": 1689738132000,
        "value": 139.171,
        "_internal_originalTime": 1689738132000
    },
    {
        "time": 1689738133000,
        "value": 139.172,
        "_internal_originalTime": 1689738133000
    },
    {
        "time": 1689738136000,
        "value": 139.181,
        "_internal_originalTime": 1689738136000
    },
    {
        "time": 1689738137000,
        "value": 139.18,
        "_internal_originalTime": 1689738137000
    },
    {
        "time": 1689738139000,
        "value": 139.176,
        "_internal_originalTime": 1689738139000
    },
    {
        "time": 1689738140000,
        "value": 139.179,
        "_internal_originalTime": 1689738140000
    },
    {
        "time": 1689738141000,
        "value": 139.18,
        "_internal_originalTime": 1689738141000
    },
    {
        "time": 1689738143000,
        "value": 139.178,
        "_internal_originalTime": 1689738143000
    },
    {
        "time": 1689738144000,
        "value": 139.181,
        "_internal_originalTime": 1689738144000
    },
    {
        "time": 1689738147000,
        "value": 139.182,
        "_internal_originalTime": 1689738147000
    },
    {
        "time": 1689738148000,
        "value": 139.18,
        "_internal_originalTime": 1689738148000
    },
    {
        "time": 1689738149000,
        "value": 139.182,
        "_internal_originalTime": 1689738149000
    },
    {
        "time": 1689738150000,
        "value": 139.176,
        "_internal_originalTime": 1689738150000
    },
    {
        "time": 1689738152000,
        "value": 139.178,
        "_internal_originalTime": 1689738152000
    },
    {
        "time": 1689738153000,
        "value": 139.174,
        "_internal_originalTime": 1689738153000
    },
    {
        "time": 1689738154000,
        "value": 139.169,
        "_internal_originalTime": 1689738154000
    },
    {
        "time": 1689738156000,
        "value": 139.172,
        "_internal_originalTime": 1689738156000
    },
    {
        "time": 1689738157000,
        "value": 139.168,
        "_internal_originalTime": 1689738157000
    },
    {
        "time": 1689738158000,
        "value": 139.167,
        "_internal_originalTime": 1689738158000
    },
    {
        "time": 1689738160000,
        "value": 139.172,
        "_internal_originalTime": 1689738160000
    },
    {
        "time": 1689738162000,
        "value": 139.167,
        "_internal_originalTime": 1689738162000
    },
    {
        "time": 1689738164000,
        "value": 139.156,
        "_internal_originalTime": 1689738164000
    },
    {
        "time": 1689738165000,
        "value": 139.159,
        "_internal_originalTime": 1689738165000
    },
    {
        "time": 1689738166000,
        "value": 139.162,
        "_internal_originalTime": 1689738166000
    },
    {
        "time": 1689738167000,
        "value": 139.161,
        "_internal_originalTime": 1689738167000
    },
    {
        "time": 1689738168000,
        "value": 139.159,
        "_internal_originalTime": 1689738168000
    },
    {
        "time": 1689738170000,
        "value": 139.159,
        "_internal_originalTime": 1689738170000
    },
    {
        "time": 1689738172000,
        "value": 139.162,
        "_internal_originalTime": 1689738172000
    },
    {
        "time": 1689738174000,
        "value": 139.162,
        "_internal_originalTime": 1689738174000
    },
    {
        "time": 1689738175000,
        "value": 139.164,
        "_internal_originalTime": 1689738175000
    },
    {
        "time": 1689738176000,
        "value": 139.158,
        "_internal_originalTime": 1689738176000
    },
    {
        "time": 1689738180000,
        "value": 139.14,
        "_internal_originalTime": 1689738180000
    },
    {
        "time": 1689738186000,
        "value": 139.164,
        "_internal_originalTime": 1689738186000
    },
    {
        "time": 1689738188000,
        "value": 139.166,
        "_internal_originalTime": 1689738188000
    },
    {
        "time": 1689738189000,
        "value": 139.17,
        "_internal_originalTime": 1689738189000
    },
    {
        "time": 1689738190000,
        "value": 139.164,
        "_internal_originalTime": 1689738190000
    },
    {
        "time": 1689738192000,
        "value": 139.17,
        "_internal_originalTime": 1689738192000
    },
    {
        "time": 1689738193000,
        "value": 139.17,
        "_internal_originalTime": 1689738193000
    },
    {
        "time": 1689738194000,
        "value": 139.171,
        "_internal_originalTime": 1689738194000
    },
    {
        "time": 1689738195000,
        "value": 139.167,
        "_internal_originalTime": 1689738195000
    },
    {
        "time": 1689738197000,
        "value": 139.17,
        "_internal_originalTime": 1689738197000
    },
    {
        "time": 1689738198000,
        "value": 139.17,
        "_internal_originalTime": 1689738198000
    },
    {
        "time": 1689738201000,
        "value": 139.17,
        "_internal_originalTime": 1689738201000
    },
    {
        "time": 1689738202000,
        "value": 139.168,
        "_internal_originalTime": 1689738202000
    },
    {
        "time": 1689738204000,
        "value": 139.17,
        "_internal_originalTime": 1689738204000
    },
    {
        "time": 1689738206000,
        "value": 139.172,
        "_internal_originalTime": 1689738206000
    },
    {
        "time": 1689738207000,
        "value": 139.171,
        "_internal_originalTime": 1689738207000
    },
    {
        "time": 1689738208000,
        "value": 139.171,
        "_internal_originalTime": 1689738208000
    },
    {
        "time": 1689738213000,
        "value": 139.173,
        "_internal_originalTime": 1689738213000
    },
    {
        "time": 1689738215000,
        "value": 139.169,
        "_internal_originalTime": 1689738215000
    },
    {
        "time": 1689738217000,
        "value": 139.169,
        "_internal_originalTime": 1689738217000
    },
    {
        "time": 1689738222000,
        "value": 139.166,
        "_internal_originalTime": 1689738222000
    },
    {
        "time": 1689738223000,
        "value": 139.164,
        "_internal_originalTime": 1689738223000
    },
    {
        "time": 1689738224000,
        "value": 139.169,
        "_internal_originalTime": 1689738224000
    },
    {
        "time": 1689738225000,
        "value": 139.17,
        "_internal_originalTime": 1689738225000
    },
    {
        "time": 1689738226000,
        "value": 139.17,
        "_internal_originalTime": 1689738226000
    },
    {
        "time": 1689738228000,
        "value": 139.175,
        "_internal_originalTime": 1689738228000
    },
    {
        "time": 1689738229000,
        "value": 139.172,
        "_internal_originalTime": 1689738229000
    },
    {
        "time": 1689738231000,
        "value": 139.174,
        "_internal_originalTime": 1689738231000
    },
    {
        "time": 1689738232000,
        "value": 139.176,
        "_internal_originalTime": 1689738232000
    },
    {
        "time": 1689738234000,
        "value": 139.173,
        "_internal_originalTime": 1689738234000
    },
    {
        "time": 1689738236000,
        "value": 139.166,
        "_internal_originalTime": 1689738236000
    },
    {
        "time": 1689738238000,
        "value": 139.169,
        "_internal_originalTime": 1689738238000
    },
    {
        "time": 1689738239000,
        "value": 139.172,
        "_internal_originalTime": 1689738239000
    },
    {
        "time": 1689738240000,
        "value": 139.17,
        "_internal_originalTime": 1689738240000
    },
    {
        "time": 1689738241000,
        "value": 139.169,
        "_internal_originalTime": 1689738241000
    },
    {
        "time": 1689738243000,
        "value": 139.17,
        "_internal_originalTime": 1689738243000
    },
    {
        "time": 1689738247000,
        "value": 139.172,
        "_internal_originalTime": 1689738247000
    },
    {
        "time": 1689738248000,
        "value": 139.167,
        "_internal_originalTime": 1689738248000
    },
    {
        "time": 1689738250000,
        "value": 139.171,
        "_internal_originalTime": 1689738250000
    },
    {
        "time": 1689738252000,
        "value": 139.165,
        "_internal_originalTime": 1689738252000
    },
    {
        "time": 1689738253000,
        "value": 139.165,
        "_internal_originalTime": 1689738253000
    },
    {
        "time": 1689738254000,
        "value": 139.17,
        "_internal_originalTime": 1689738254000
    },
    {
        "time": 1689738256000,
        "value": 139.163,
        "_internal_originalTime": 1689738256000
    },
    {
        "time": 1689738257000,
        "value": 139.164,
        "_internal_originalTime": 1689738257000
    },
    {
        "time": 1689738259000,
        "value": 139.158,
        "_internal_originalTime": 1689738259000
    },
    {
        "time": 1689738260000,
        "value": 139.163,
        "_internal_originalTime": 1689738260000
    },
    {
        "time": 1689738262000,
        "value": 139.156,
        "_internal_originalTime": 1689738262000
    },
    {
        "time": 1689738263000,
        "value": 139.16,
        "_internal_originalTime": 1689738263000
    },
    {
        "time": 1689738264000,
        "value": 139.16,
        "_internal_originalTime": 1689738264000
    },
    {
        "time": 1689738266000,
        "value": 139.162,
        "_internal_originalTime": 1689738266000
    },
    {
        "time": 1689738267000,
        "value": 139.161,
        "_internal_originalTime": 1689738267000
    },
    {
        "time": 1689738268000,
        "value": 139.162,
        "_internal_originalTime": 1689738268000
    },
    {
        "time": 1689738270000,
        "value": 139.158,
        "_internal_originalTime": 1689738270000
    },
    {
        "time": 1689738273000,
        "value": 139.16,
        "_internal_originalTime": 1689738273000
    },
    {
        "time": 1689738274000,
        "value": 139.16,
        "_internal_originalTime": 1689738274000
    },
    {
        "time": 1689738276000,
        "value": 139.159,
        "_internal_originalTime": 1689738276000
    },
    {
        "time": 1689738278000,
        "value": 139.16,
        "_internal_originalTime": 1689738278000
    },
    {
        "time": 1689738279000,
        "value": 139.16,
        "_internal_originalTime": 1689738279000
    },
    {
        "time": 1689738281000,
        "value": 139.164,
        "_internal_originalTime": 1689738281000
    },
    {
        "time": 1689738282000,
        "value": 139.162,
        "_internal_originalTime": 1689738282000
    },
    {
        "time": 1689738284000,
        "value": 139.159,
        "_internal_originalTime": 1689738284000
    },
    {
        "time": 1689738286000,
        "value": 139.159,
        "_internal_originalTime": 1689738286000
    },
    {
        "time": 1689738287000,
        "value": 139.163,
        "_internal_originalTime": 1689738287000
    },
    {
        "time": 1689738289000,
        "value": 139.16,
        "_internal_originalTime": 1689738289000
    },
    {
        "time": 1689738290000,
        "value": 139.163,
        "_internal_originalTime": 1689738290000
    },
    {
        "time": 1689738294000,
        "value": 139.163,
        "_internal_originalTime": 1689738294000
    },
    {
        "time": 1689738296000,
        "value": 139.157,
        "_internal_originalTime": 1689738296000
    },
    {
        "time": 1689738298000,
        "value": 139.163,
        "_internal_originalTime": 1689738298000
    },
    {
        "time": 1689738300000,
        "value": 139.159,
        "_internal_originalTime": 1689738300000
    },
    {
        "time": 1689738301000,
        "value": 139.14,
        "_internal_originalTime": 1689738301000
    },
    {
        "time": 1689738302000,
        "value": 139.159,
        "_internal_originalTime": 1689738302000
    },
    {
        "time": 1689738303000,
        "value": 139.162,
        "_internal_originalTime": 1689738303000
    },
    {
        "time": 1689738305000,
        "value": 139.165,
        "_internal_originalTime": 1689738305000
    },
    {
        "time": 1689738306000,
        "value": 139.165,
        "_internal_originalTime": 1689738306000
    },
    {
        "time": 1689738308000,
        "value": 139.169,
        "_internal_originalTime": 1689738308000
    },
    {
        "time": 1689738309000,
        "value": 139.169,
        "_internal_originalTime": 1689738309000
    },
    {
        "time": 1689738311000,
        "value": 139.169,
        "_internal_originalTime": 1689738311000
    },
    {
        "time": 1689738313000,
        "value": 139.167,
        "_internal_originalTime": 1689738313000
    },
    {
        "time": 1689738314000,
        "value": 139.171,
        "_internal_originalTime": 1689738314000
    },
    {
        "time": 1689738315000,
        "value": 139.168,
        "_internal_originalTime": 1689738315000
    },
    {
        "time": 1689738317000,
        "value": 139.16,
        "_internal_originalTime": 1689738317000
    },
    {
        "time": 1689738319000,
        "value": 139.159,
        "_internal_originalTime": 1689738319000
    },
    {
        "time": 1689738320000,
        "value": 139.162,
        "_internal_originalTime": 1689738320000
    },
    {
        "time": 1689738322000,
        "value": 139.161,
        "_internal_originalTime": 1689738322000
    },
    {
        "time": 1689738325000,
        "value": 139.163,
        "_internal_originalTime": 1689738325000
    },
    {
        "time": 1689738326000,
        "value": 139.162,
        "_internal_originalTime": 1689738326000
    },
    {
        "time": 1689738329000,
        "value": 139.161,
        "_internal_originalTime": 1689738329000
    },
    {
        "time": 1689738330000,
        "value": 139.158,
        "_internal_originalTime": 1689738330000
    },
    {
        "time": 1689738331000,
        "value": 139.157,
        "_internal_originalTime": 1689738331000
    },
    {
        "time": 1689738332000,
        "value": 139.158,
        "_internal_originalTime": 1689738332000
    },
    {
        "time": 1689738334000,
        "value": 139.158,
        "_internal_originalTime": 1689738334000
    },
    {
        "time": 1689738335000,
        "value": 139.158,
        "_internal_originalTime": 1689738335000
    },
    {
        "time": 1689738336000,
        "value": 139.158,
        "_internal_originalTime": 1689738336000
    },
    {
        "time": 1689738340000,
        "value": 139.165,
        "_internal_originalTime": 1689738340000
    },
    {
        "time": 1689738342000,
        "value": 139.163,
        "_internal_originalTime": 1689738342000
    },
    {
        "time": 1689738344000,
        "value": 139.164,
        "_internal_originalTime": 1689738344000
    },
    {
        "time": 1689738345000,
        "value": 139.162,
        "_internal_originalTime": 1689738345000
    },
    {
        "time": 1689738346000,
        "value": 139.162,
        "_internal_originalTime": 1689738346000
    },
    {
        "time": 1689738348000,
        "value": 139.163,
        "_internal_originalTime": 1689738348000
    },
    {
        "time": 1689738350000,
        "value": 139.165,
        "_internal_originalTime": 1689738350000
    },
    {
        "time": 1689738351000,
        "value": 139.169,
        "_internal_originalTime": 1689738351000
    },
    {
        "time": 1689738352000,
        "value": 139.169,
        "_internal_originalTime": 1689738352000
    },
    {
        "time": 1689738356000,
        "value": 139.166,
        "_internal_originalTime": 1689738356000
    },
    {
        "time": 1689738358000,
        "value": 139.169,
        "_internal_originalTime": 1689738358000
    },
    {
        "time": 1689738359000,
        "value": 139.172,
        "_internal_originalTime": 1689738359000
    },
    {
        "time": 1689738360000,
        "value": 139.173,
        "_internal_originalTime": 1689738360000
    },
    {
        "time": 1689738361000,
        "value": 139.172,
        "_internal_originalTime": 1689738361000
    },
    {
        "time": 1689738364000,
        "value": 139.173,
        "_internal_originalTime": 1689738364000
    },
    {
        "time": 1689738366000,
        "value": 139.172,
        "_internal_originalTime": 1689738366000
    },
    {
        "time": 1689738368000,
        "value": 139.172,
        "_internal_originalTime": 1689738368000
    },
    {
        "time": 1689738369000,
        "value": 139.171,
        "_internal_originalTime": 1689738369000
    },
    {
        "time": 1689738370000,
        "value": 139.169,
        "_internal_originalTime": 1689738370000
    },
    {
        "time": 1689738372000,
        "value": 139.179,
        "_internal_originalTime": 1689738372000
    },
    {
        "time": 1689738374000,
        "value": 139.18,
        "_internal_originalTime": 1689738374000
    },
    {
        "time": 1689738375000,
        "value": 139.182,
        "_internal_originalTime": 1689738375000
    },
    {
        "time": 1689738376000,
        "value": 139.183,
        "_internal_originalTime": 1689738376000
    },
    {
        "time": 1689738377000,
        "value": 139.182,
        "_internal_originalTime": 1689738377000
    },
    {
        "time": 1689738380000,
        "value": 139.183,
        "_internal_originalTime": 1689738380000
    },
    {
        "time": 1689738381000,
        "value": 139.178,
        "_internal_originalTime": 1689738381000
    },
    {
        "time": 1689738384000,
        "value": 139.18,
        "_internal_originalTime": 1689738384000
    },
    {
        "time": 1689738385000,
        "value": 139.181,
        "_internal_originalTime": 1689738385000
    },
    {
        "time": 1689738387000,
        "value": 139.169,
        "_internal_originalTime": 1689738387000
    },
    {
        "time": 1689738390000,
        "value": 139.173,
        "_internal_originalTime": 1689738390000
    },
    {
        "time": 1689738391000,
        "value": 139.173,
        "_internal_originalTime": 1689738391000
    },
    {
        "time": 1689738392000,
        "value": 139.174,
        "_internal_originalTime": 1689738392000
    },
    {
        "time": 1689738395000,
        "value": 139.173,
        "_internal_originalTime": 1689738395000
    },
    {
        "time": 1689738400000,
        "value": 139.172,
        "_internal_originalTime": 1689738400000
    },
    {
        "time": 1689738403000,
        "value": 139.173,
        "_internal_originalTime": 1689738403000
    },
    {
        "time": 1689738405000,
        "value": 139.173,
        "_internal_originalTime": 1689738405000
    },
    {
        "time": 1689738407000,
        "value": 139.173,
        "_internal_originalTime": 1689738407000
    },
    {
        "time": 1689738408000,
        "value": 139.167,
        "_internal_originalTime": 1689738408000
    },
    {
        "time": 1689738410000,
        "value": 139.171,
        "_internal_originalTime": 1689738410000
    },
    {
        "time": 1689738412000,
        "value": 139.174,
        "_internal_originalTime": 1689738412000
    },
    {
        "time": 1689738414000,
        "value": 139.171,
        "_internal_originalTime": 1689738414000
    },
    {
        "time": 1689738415000,
        "value": 139.186,
        "_internal_originalTime": 1689738415000
    },
    {
        "time": 1689738418000,
        "value": 139.183,
        "_internal_originalTime": 1689738418000
    },
    {
        "time": 1689738419000,
        "value": 139.184,
        "_internal_originalTime": 1689738419000
    },
    {
        "time": 1689738421000,
        "value": 139.187,
        "_internal_originalTime": 1689738421000
    },
    {
        "time": 1689738422000,
        "value": 139.189,
        "_internal_originalTime": 1689738422000
    },
    {
        "time": 1689738423000,
        "value": 139.19,
        "_internal_originalTime": 1689738423000
    },
    {
        "time": 1689738425000,
        "value": 139.191,
        "_internal_originalTime": 1689738425000
    },
    {
        "time": 1689738426000,
        "value": 139.19,
        "_internal_originalTime": 1689738426000
    },
    {
        "time": 1689738427000,
        "value": 139.188,
        "_internal_originalTime": 1689738427000
    },
    {
        "time": 1689738429000,
        "value": 139.187,
        "_internal_originalTime": 1689738429000
    },
    {
        "time": 1689738431000,
        "value": 139.191,
        "_internal_originalTime": 1689738431000
    },
    {
        "time": 1689738433000,
        "value": 139.178,
        "_internal_originalTime": 1689738433000
    },
    {
        "time": 1689738434000,
        "value": 139.181,
        "_internal_originalTime": 1689738434000
    },
    {
        "time": 1689738436000,
        "value": 139.18,
        "_internal_originalTime": 1689738436000
    },
    {
        "time": 1689738437000,
        "value": 139.177,
        "_internal_originalTime": 1689738437000
    },
    {
        "time": 1689738440000,
        "value": 139.181,
        "_internal_originalTime": 1689738440000
    },
    {
        "time": 1689738441000,
        "value": 139.176,
        "_internal_originalTime": 1689738441000
    },
    {
        "time": 1689738442000,
        "value": 139.183,
        "_internal_originalTime": 1689738442000
    },
    {
        "time": 1689738444000,
        "value": 139.181,
        "_internal_originalTime": 1689738444000
    },
    {
        "time": 1689738446000,
        "value": 139.175,
        "_internal_originalTime": 1689738446000
    },
    {
        "time": 1689738447000,
        "value": 139.18,
        "_internal_originalTime": 1689738447000
    },
    {
        "time": 1689738449000,
        "value": 139.18,
        "_internal_originalTime": 1689738449000
    },
    {
        "time": 1689738450000,
        "value": 139.18,
        "_internal_originalTime": 1689738450000
    },
    {
        "time": 1689738452000,
        "value": 139.178,
        "_internal_originalTime": 1689738452000
    },
    {
        "time": 1689738453000,
        "value": 139.178,
        "_internal_originalTime": 1689738453000
    },
    {
        "time": 1689738455000,
        "value": 139.178,
        "_internal_originalTime": 1689738455000
    },
    {
        "time": 1689738458000,
        "value": 139.184,
        "_internal_originalTime": 1689738458000
    },
    {
        "time": 1689738460000,
        "value": 139.185,
        "_internal_originalTime": 1689738460000
    },
    {
        "time": 1689738461000,
        "value": 139.181,
        "_internal_originalTime": 1689738461000
    },
    {
        "time": 1689738466000,
        "value": 139.18,
        "_internal_originalTime": 1689738466000
    },
    {
        "time": 1689738467000,
        "value": 139.185,
        "_internal_originalTime": 1689738467000
    },
    {
        "time": 1689738469000,
        "value": 139.189,
        "_internal_originalTime": 1689738469000
    },
    {
        "time": 1689738471000,
        "value": 139.187,
        "_internal_originalTime": 1689738471000
    },
    {
        "time": 1689738472000,
        "value": 139.19,
        "_internal_originalTime": 1689738472000
    },
    {
        "time": 1689738474000,
        "value": 139.186,
        "_internal_originalTime": 1689738474000
    },
    {
        "time": 1689738475000,
        "value": 139.189,
        "_internal_originalTime": 1689738475000
    },
    {
        "time": 1689738477000,
        "value": 139.189,
        "_internal_originalTime": 1689738477000
    },
    {
        "time": 1689738478000,
        "value": 139.183,
        "_internal_originalTime": 1689738478000
    },
    {
        "time": 1689738479000,
        "value": 139.176,
        "_internal_originalTime": 1689738479000
    },
    {
        "time": 1689738480000,
        "value": 139.178,
        "_internal_originalTime": 1689738480000
    },
    {
        "time": 1689738482000,
        "value": 139.18,
        "_internal_originalTime": 1689738482000
    },
    {
        "time": 1689738484000,
        "value": 139.181,
        "_internal_originalTime": 1689738484000
    },
    {
        "time": 1689738486000,
        "value": 139.193,
        "_internal_originalTime": 1689738486000
    },
    {
        "time": 1689738488000,
        "value": 139.196,
        "_internal_originalTime": 1689738488000
    },
    {
        "time": 1689738490000,
        "value": 139.201,
        "_internal_originalTime": 1689738490000
    },
    {
        "time": 1689738491000,
        "value": 139.202,
        "_internal_originalTime": 1689738491000
    },
    {
        "time": 1689738493000,
        "value": 139.196,
        "_internal_originalTime": 1689738493000
    },
    {
        "time": 1689738494000,
        "value": 139.2,
        "_internal_originalTime": 1689738494000
    },
    {
        "time": 1689738496000,
        "value": 139.201,
        "_internal_originalTime": 1689738496000
    },
    {
        "time": 1689738498000,
        "value": 139.201,
        "_internal_originalTime": 1689738498000
    },
    {
        "time": 1689738500000,
        "value": 139.2,
        "_internal_originalTime": 1689738500000
    },
    {
        "time": 1689738501000,
        "value": 139.201,
        "_internal_originalTime": 1689738501000
    },
    {
        "time": 1689738504000,
        "value": 139.2,
        "_internal_originalTime": 1689738504000
    },
    {
        "time": 1689738506000,
        "value": 139.195,
        "_internal_originalTime": 1689738506000
    },
    {
        "time": 1689738508000,
        "value": 139.196,
        "_internal_originalTime": 1689738508000
    },
    {
        "time": 1689738509000,
        "value": 139.199,
        "_internal_originalTime": 1689738509000
    },
    {
        "time": 1689738511000,
        "value": 139.194,
        "_internal_originalTime": 1689738511000
    },
    {
        "time": 1689738512000,
        "value": 139.201,
        "_internal_originalTime": 1689738512000
    },
    {
        "time": 1689738514000,
        "value": 139.196,
        "_internal_originalTime": 1689738514000
    },
    {
        "time": 1689738515000,
        "value": 139.198,
        "_internal_originalTime": 1689738515000
    },
    {
        "time": 1689738516000,
        "value": 139.199,
        "_internal_originalTime": 1689738516000
    },
    {
        "time": 1689738518000,
        "value": 139.195,
        "_internal_originalTime": 1689738518000
    },
    {
        "time": 1689738520000,
        "value": 139.195,
        "_internal_originalTime": 1689738520000
    },
    {
        "time": 1689738522000,
        "value": 139.198,
        "_internal_originalTime": 1689738522000
    },
    {
        "time": 1689738524000,
        "value": 139.202,
        "_internal_originalTime": 1689738524000
    },
    {
        "time": 1689738528000,
        "value": 139.201,
        "_internal_originalTime": 1689738528000
    },
    {
        "time": 1689738530000,
        "value": 139.196,
        "_internal_originalTime": 1689738530000
    },
    {
        "time": 1689738531000,
        "value": 139.197,
        "_internal_originalTime": 1689738531000
    },
    {
        "time": 1689738532000,
        "value": 139.202,
        "_internal_originalTime": 1689738532000
    },
    {
        "time": 1689738536000,
        "value": 139.218,
        "_internal_originalTime": 1689738536000
    },
    {
        "time": 1689738537000,
        "value": 139.22,
        "_internal_originalTime": 1689738537000
    },
    {
        "time": 1689738540000,
        "value": 139.216,
        "_internal_originalTime": 1689738540000
    },
    {
        "time": 1689738541000,
        "value": 139.22,
        "_internal_originalTime": 1689738541000
    },
    {
        "time": 1689738542000,
        "value": 139.216,
        "_internal_originalTime": 1689738542000
    },
    {
        "time": 1689738543000,
        "value": 139.22,
        "_internal_originalTime": 1689738543000
    },
    {
        "time": 1689738544000,
        "value": 139.213,
        "_internal_originalTime": 1689738544000
    },
    {
        "time": 1689738545000,
        "value": 139.214,
        "_internal_originalTime": 1689738545000
    },
    {
        "time": 1689738547000,
        "value": 139.207,
        "_internal_originalTime": 1689738547000
    },
    {
        "time": 1689738548000,
        "value": 139.211,
        "_internal_originalTime": 1689738548000
    },
    {
        "time": 1689738550000,
        "value": 139.21,
        "_internal_originalTime": 1689738550000
    },
    {
        "time": 1689738552000,
        "value": 139.21,
        "_internal_originalTime": 1689738552000
    },
    {
        "time": 1689738554000,
        "value": 139.198,
        "_internal_originalTime": 1689738554000
    },
    {
        "time": 1689738556000,
        "value": 139.194,
        "_internal_originalTime": 1689738556000
    },
    {
        "time": 1689738557000,
        "value": 139.199,
        "_internal_originalTime": 1689738557000
    },
    {
        "time": 1689738559000,
        "value": 139.194,
        "_internal_originalTime": 1689738559000
    },
    {
        "time": 1689738560000,
        "value": 139.197,
        "_internal_originalTime": 1689738560000
    },
    {
        "time": 1689738561000,
        "value": 139.192,
        "_internal_originalTime": 1689738561000
    },
    {
        "time": 1689738565000,
        "value": 139.196,
        "_internal_originalTime": 1689738565000
    },
    {
        "time": 1689738566000,
        "value": 139.192,
        "_internal_originalTime": 1689738566000
    },
    {
        "time": 1689738570000,
        "value": 139.199,
        "_internal_originalTime": 1689738570000
    },
    {
        "time": 1689738571000,
        "value": 139.194,
        "_internal_originalTime": 1689738571000
    },
    {
        "time": 1689738574000,
        "value": 139.204,
        "_internal_originalTime": 1689738574000
    },
    {
        "time": 1689738575000,
        "value": 139.21,
        "_internal_originalTime": 1689738575000
    },
    {
        "time": 1689738576000,
        "value": 139.206,
        "_internal_originalTime": 1689738576000
    },
    {
        "time": 1689738578000,
        "value": 139.204,
        "_internal_originalTime": 1689738578000
    },
    {
        "time": 1689738580000,
        "value": 139.203,
        "_internal_originalTime": 1689738580000
    },
    {
        "time": 1689738581000,
        "value": 139.203,
        "_internal_originalTime": 1689738581000
    },
    {
        "time": 1689738583000,
        "value": 139.197,
        "_internal_originalTime": 1689738583000
    },
    {
        "time": 1689738584000,
        "value": 139.197,
        "_internal_originalTime": 1689738584000
    },
    {
        "time": 1689738586000,
        "value": 139.197,
        "_internal_originalTime": 1689738586000
    },
    {
        "time": 1689738587000,
        "value": 139.2,
        "_internal_originalTime": 1689738587000
    },
    {
        "time": 1689738589000,
        "value": 139.2,
        "_internal_originalTime": 1689738589000
    },
    {
        "time": 1689738591000,
        "value": 139.194,
        "_internal_originalTime": 1689738591000
    },
    {
        "time": 1689738593000,
        "value": 139.197,
        "_internal_originalTime": 1689738593000
    },
    {
        "time": 1689738594000,
        "value": 139.197,
        "_internal_originalTime": 1689738594000
    },
    {
        "time": 1689738596000,
        "value": 139.195,
        "_internal_originalTime": 1689738596000
    },
    {
        "time": 1689738597000,
        "value": 139.196,
        "_internal_originalTime": 1689738597000
    },
    {
        "time": 1689738600000,
        "value": 139.198,
        "_internal_originalTime": 1689738600000
    },
    {
        "time": 1689738602000,
        "value": 139.197,
        "_internal_originalTime": 1689738602000
    },
    {
        "time": 1689738603000,
        "value": 139.199,
        "_internal_originalTime": 1689738603000
    },
    {
        "time": 1689738604000,
        "value": 139.198,
        "_internal_originalTime": 1689738604000
    },
    {
        "time": 1689738605000,
        "value": 139.2,
        "_internal_originalTime": 1689738605000
    },
    {
        "time": 1689738607000,
        "value": 139.198,
        "_internal_originalTime": 1689738607000
    },
    {
        "time": 1689738610000,
        "value": 139.205,
        "_internal_originalTime": 1689738610000
    },
    {
        "time": 1689738612000,
        "value": 139.208,
        "_internal_originalTime": 1689738612000
    },
    {
        "time": 1689738614000,
        "value": 139.203,
        "_internal_originalTime": 1689738614000
    },
    {
        "time": 1689738616000,
        "value": 139.214,
        "_internal_originalTime": 1689738616000
    },
    {
        "time": 1689738618000,
        "value": 139.22,
        "_internal_originalTime": 1689738618000
    },
    {
        "time": 1689738620000,
        "value": 139.217,
        "_internal_originalTime": 1689738620000
    },
    {
        "time": 1689738621000,
        "value": 139.22,
        "_internal_originalTime": 1689738621000
    },
    {
        "time": 1689738622000,
        "value": 139.216,
        "_internal_originalTime": 1689738622000
    },
    {
        "time": 1689738626000,
        "value": 139.216,
        "_internal_originalTime": 1689738626000
    },
    {
        "time": 1689738627000,
        "value": 139.22,
        "_internal_originalTime": 1689738627000
    },
    {
        "time": 1689738629000,
        "value": 139.22,
        "_internal_originalTime": 1689738629000
    },
    {
        "time": 1689738632000,
        "value": 139.22,
        "_internal_originalTime": 1689738632000
    },
    {
        "time": 1689738634000,
        "value": 139.221,
        "_internal_originalTime": 1689738634000
    },
    {
        "time": 1689738635000,
        "value": 139.221,
        "_internal_originalTime": 1689738635000
    },
    {
        "time": 1689738636000,
        "value": 139.216,
        "_internal_originalTime": 1689738636000
    },
    {
        "time": 1689738637000,
        "value": 139.212,
        "_internal_originalTime": 1689738637000
    },
    {
        "time": 1689738639000,
        "value": 139.209,
        "_internal_originalTime": 1689738639000
    },
    {
        "time": 1689738640000,
        "value": 139.21,
        "_internal_originalTime": 1689738640000
    },
    {
        "time": 1689738641000,
        "value": 139.209,
        "_internal_originalTime": 1689738641000
    },
    {
        "time": 1689738643000,
        "value": 139.215,
        "_internal_originalTime": 1689738643000
    },
    {
        "time": 1689738644000,
        "value": 139.216,
        "_internal_originalTime": 1689738644000
    },
    {
        "time": 1689738646000,
        "value": 139.214,
        "_internal_originalTime": 1689738646000
    },
    {
        "time": 1689738647000,
        "value": 139.214,
        "_internal_originalTime": 1689738647000
    },
    {
        "time": 1689738648000,
        "value": 139.214,
        "_internal_originalTime": 1689738648000
    },
    {
        "time": 1689738650000,
        "value": 139.215,
        "_internal_originalTime": 1689738650000
    },
    {
        "time": 1689738651000,
        "value": 139.214,
        "_internal_originalTime": 1689738651000
    },
    {
        "time": 1689738652000,
        "value": 139.214,
        "_internal_originalTime": 1689738652000
    },
    {
        "time": 1689738653000,
        "value": 139.217,
        "_internal_originalTime": 1689738653000
    },
    {
        "time": 1689738654000,
        "value": 139.219,
        "_internal_originalTime": 1689738654000
    },
    {
        "time": 1689738656000,
        "value": 139.217,
        "_internal_originalTime": 1689738656000
    },
    {
        "time": 1689738658000,
        "value": 139.218,
        "_internal_originalTime": 1689738658000
    },
    {
        "time": 1689738659000,
        "value": 139.214,
        "_internal_originalTime": 1689738659000
    },
    {
        "time": 1689738661000,
        "value": 139.19,
        "_internal_originalTime": 1689738661000
    },
    {
        "time": 1689738662000,
        "value": 139.219,
        "_internal_originalTime": 1689738662000
    },
    {
        "time": 1689738663000,
        "value": 139.217,
        "_internal_originalTime": 1689738663000
    },
    {
        "time": 1689738665000,
        "value": 139.215,
        "_internal_originalTime": 1689738665000
    },
    {
        "time": 1689738666000,
        "value": 139.214,
        "_internal_originalTime": 1689738666000
    },
    {
        "time": 1689738671000,
        "value": 139.212,
        "_internal_originalTime": 1689738671000
    },
    {
        "time": 1689738673000,
        "value": 139.21,
        "_internal_originalTime": 1689738673000
    },
    {
        "time": 1689738676000,
        "value": 139.209,
        "_internal_originalTime": 1689738676000
    },
    {
        "time": 1689738678000,
        "value": 139.205,
        "_internal_originalTime": 1689738678000
    },
    {
        "time": 1689738679000,
        "value": 139.202,
        "_internal_originalTime": 1689738679000
    },
    {
        "time": 1689738681000,
        "value": 139.197,
        "_internal_originalTime": 1689738681000
    },
    {
        "time": 1689738682000,
        "value": 139.2,
        "_internal_originalTime": 1689738682000
    },
    {
        "time": 1689738684000,
        "value": 139.207,
        "_internal_originalTime": 1689738684000
    },
    {
        "time": 1689738685000,
        "value": 139.205,
        "_internal_originalTime": 1689738685000
    },
    {
        "time": 1689738687000,
        "value": 139.206,
        "_internal_originalTime": 1689738687000
    },
    {
        "time": 1689738688000,
        "value": 139.206,
        "_internal_originalTime": 1689738688000
    },
    {
        "time": 1689738689000,
        "value": 139.206,
        "_internal_originalTime": 1689738689000
    },
    {
        "time": 1689738690000,
        "value": 139.21,
        "_internal_originalTime": 1689738690000
    },
    {
        "time": 1689738691000,
        "value": 139.211,
        "_internal_originalTime": 1689738691000
    },
    {
        "time": 1689738692000,
        "value": 139.207,
        "_internal_originalTime": 1689738692000
    },
    {
        "time": 1689738695000,
        "value": 139.206,
        "_internal_originalTime": 1689738695000
    },
    {
        "time": 1689738697000,
        "value": 139.206,
        "_internal_originalTime": 1689738697000
    },
    {
        "time": 1689738698000,
        "value": 139.211,
        "_internal_originalTime": 1689738698000
    },
    {
        "time": 1689738700000,
        "value": 139.207,
        "_internal_originalTime": 1689738700000
    },
    {
        "time": 1689738701000,
        "value": 139.209,
        "_internal_originalTime": 1689738701000
    },
    {
        "time": 1689738703000,
        "value": 139.206,
        "_internal_originalTime": 1689738703000
    },
    {
        "time": 1689738705000,
        "value": 139.21,
        "_internal_originalTime": 1689738705000
    },
    {
        "time": 1689738706000,
        "value": 139.206,
        "_internal_originalTime": 1689738706000
    },
    {
        "time": 1689738708000,
        "value": 139.206,
        "_internal_originalTime": 1689738708000
    },
    {
        "time": 1689738710000,
        "value": 139.209,
        "_internal_originalTime": 1689738710000
    },
    {
        "time": 1689738711000,
        "value": 139.204,
        "_internal_originalTime": 1689738711000
    },
    {
        "time": 1689738716000,
        "value": 139.203,
        "_internal_originalTime": 1689738716000
    },
    {
        "time": 1689738717000,
        "value": 139.209,
        "_internal_originalTime": 1689738717000
    },
    {
        "time": 1689738719000,
        "value": 139.207,
        "_internal_originalTime": 1689738719000
    },
    {
        "time": 1689738720000,
        "value": 139.209,
        "_internal_originalTime": 1689738720000
    },
    {
        "time": 1689738723000,
        "value": 139.209,
        "_internal_originalTime": 1689738723000
    },
    {
        "time": 1689738726000,
        "value": 139.21,
        "_internal_originalTime": 1689738726000
    },
    {
        "time": 1689738728000,
        "value": 139.206,
        "_internal_originalTime": 1689738728000
    },
    {
        "time": 1689738730000,
        "value": 139.206,
        "_internal_originalTime": 1689738730000
    },
    {
        "time": 1689738732000,
        "value": 139.205,
        "_internal_originalTime": 1689738732000
    },
    {
        "time": 1689738734000,
        "value": 139.209,
        "_internal_originalTime": 1689738734000
    },
    {
        "time": 1689738736000,
        "value": 139.212,
        "_internal_originalTime": 1689738736000
    },
    {
        "time": 1689738737000,
        "value": 139.211,
        "_internal_originalTime": 1689738737000
    },
    {
        "time": 1689738739000,
        "value": 139.205,
        "_internal_originalTime": 1689738739000
    },
    {
        "time": 1689738741000,
        "value": 139.204,
        "_internal_originalTime": 1689738741000
    },
    {
        "time": 1689738744000,
        "value": 139.203,
        "_internal_originalTime": 1689738744000
    },
    {
        "time": 1689738745000,
        "value": 139.208,
        "_internal_originalTime": 1689738745000
    },
    {
        "time": 1689738746000,
        "value": 139.202,
        "_internal_originalTime": 1689738746000
    },
    {
        "time": 1689738750000,
        "value": 139.208,
        "_internal_originalTime": 1689738750000
    },
    {
        "time": 1689738751000,
        "value": 139.208,
        "_internal_originalTime": 1689738751000
    },
    {
        "time": 1689738753000,
        "value": 139.205,
        "_internal_originalTime": 1689738753000
    },
    {
        "time": 1689738754000,
        "value": 139.205,
        "_internal_originalTime": 1689738754000
    },
    {
        "time": 1689738757000,
        "value": 139.204,
        "_internal_originalTime": 1689738757000
    },
    {
        "time": 1689738759000,
        "value": 139.204,
        "_internal_originalTime": 1689738759000
    },
    {
        "time": 1689738760000,
        "value": 139.205,
        "_internal_originalTime": 1689738760000
    },
    {
        "time": 1689738762000,
        "value": 139.204,
        "_internal_originalTime": 1689738762000
    },
    {
        "time": 1689738765000,
        "value": 139.204,
        "_internal_originalTime": 1689738765000
    },
    {
        "time": 1689738766000,
        "value": 139.208,
        "_internal_originalTime": 1689738766000
    },
    {
        "time": 1689738767000,
        "value": 139.204,
        "_internal_originalTime": 1689738767000
    },
    {
        "time": 1689738768000,
        "value": 139.21,
        "_internal_originalTime": 1689738768000
    },
    {
        "time": 1689738769000,
        "value": 139.204,
        "_internal_originalTime": 1689738769000
    },
    {
        "time": 1689738770000,
        "value": 139.205,
        "_internal_originalTime": 1689738770000
    },
    {
        "time": 1689738772000,
        "value": 139.207,
        "_internal_originalTime": 1689738772000
    },
    {
        "time": 1689738774000,
        "value": 139.195,
        "_internal_originalTime": 1689738774000
    },
    {
        "time": 1689738775000,
        "value": 139.201,
        "_internal_originalTime": 1689738775000
    },
    {
        "time": 1689738777000,
        "value": 139.201,
        "_internal_originalTime": 1689738777000
    },
    {
        "time": 1689738779000,
        "value": 139.196,
        "_internal_originalTime": 1689738779000
    },
    {
        "time": 1689738780000,
        "value": 139.201,
        "_internal_originalTime": 1689738780000
    },
    {
        "time": 1689738781000,
        "value": 139.201,
        "_internal_originalTime": 1689738781000
    },
    {
        "time": 1689738782000,
        "value": 139.196,
        "_internal_originalTime": 1689738782000
    },
    {
        "time": 1689738784000,
        "value": 139.197,
        "_internal_originalTime": 1689738784000
    },
    {
        "time": 1689738786000,
        "value": 139.2,
        "_internal_originalTime": 1689738786000
    },
    {
        "time": 1689738787000,
        "value": 139.2,
        "_internal_originalTime": 1689738787000
    },
    {
        "time": 1689738790000,
        "value": 139.196,
        "_internal_originalTime": 1689738790000
    },
    {
        "time": 1689738791000,
        "value": 139.194,
        "_internal_originalTime": 1689738791000
    },
    {
        "time": 1689738793000,
        "value": 139.195,
        "_internal_originalTime": 1689738793000
    },
    {
        "time": 1689738794000,
        "value": 139.201,
        "_internal_originalTime": 1689738794000
    },
    {
        "time": 1689738796000,
        "value": 139.201,
        "_internal_originalTime": 1689738796000
    },
    {
        "time": 1689738798000,
        "value": 139.201,
        "_internal_originalTime": 1689738798000
    },
    {
        "time": 1689738799000,
        "value": 139.188,
        "_internal_originalTime": 1689738799000
    },
    {
        "time": 1689738801000,
        "value": 139.192,
        "_internal_originalTime": 1689738801000
    },
    {
        "time": 1689738802000,
        "value": 139.188,
        "_internal_originalTime": 1689738802000
    },
    {
        "time": 1689738804000,
        "value": 139.188,
        "_internal_originalTime": 1689738804000
    },
    {
        "time": 1689738806000,
        "value": 139.192,
        "_internal_originalTime": 1689738806000
    },
    {
        "time": 1689738808000,
        "value": 139.18,
        "_internal_originalTime": 1689738808000
    },
    {
        "time": 1689738809000,
        "value": 139.174,
        "_internal_originalTime": 1689738809000
    },
    {
        "time": 1689738810000,
        "value": 139.177,
        "_internal_originalTime": 1689738810000
    },
    {
        "time": 1689738811000,
        "value": 139.183,
        "_internal_originalTime": 1689738811000
    },
    {
        "time": 1689738812000,
        "value": 139.182,
        "_internal_originalTime": 1689738812000
    },
    {
        "time": 1689738817000,
        "value": 139.179,
        "_internal_originalTime": 1689738817000
    },
    {
        "time": 1689738818000,
        "value": 139.176,
        "_internal_originalTime": 1689738818000
    },
    {
        "time": 1689738820000,
        "value": 139.178,
        "_internal_originalTime": 1689738820000
    },
    {
        "time": 1689738821000,
        "value": 139.181,
        "_internal_originalTime": 1689738821000
    },
    {
        "time": 1689738823000,
        "value": 139.184,
        "_internal_originalTime": 1689738823000
    },
    {
        "time": 1689738824000,
        "value": 139.182,
        "_internal_originalTime": 1689738824000
    },
    {
        "time": 1689738825000,
        "value": 139.182,
        "_internal_originalTime": 1689738825000
    },
    {
        "time": 1689738827000,
        "value": 139.178,
        "_internal_originalTime": 1689738827000
    },
    {
        "time": 1689738828000,
        "value": 139.182,
        "_internal_originalTime": 1689738828000
    },
    {
        "time": 1689738831000,
        "value": 139.182,
        "_internal_originalTime": 1689738831000
    },
    {
        "time": 1689738833000,
        "value": 139.18,
        "_internal_originalTime": 1689738833000
    },
    {
        "time": 1689738837000,
        "value": 139.18,
        "_internal_originalTime": 1689738837000
    },
    {
        "time": 1689738839000,
        "value": 139.188,
        "_internal_originalTime": 1689738839000
    },
    {
        "time": 1689738840000,
        "value": 139.188,
        "_internal_originalTime": 1689738840000
    },
    {
        "time": 1689738841000,
        "value": 139.188,
        "_internal_originalTime": 1689738841000
    },
    {
        "time": 1689738842000,
        "value": 139.185,
        "_internal_originalTime": 1689738842000
    },
    {
        "time": 1689738844000,
        "value": 139.181,
        "_internal_originalTime": 1689738844000
    },
    {
        "time": 1689738846000,
        "value": 139.184,
        "_internal_originalTime": 1689738846000
    },
    {
        "time": 1689738848000,
        "value": 139.18,
        "_internal_originalTime": 1689738848000
    },
    {
        "time": 1689738851000,
        "value": 139.183,
        "_internal_originalTime": 1689738851000
    },
    {
        "time": 1689738853000,
        "value": 139.185,
        "_internal_originalTime": 1689738853000
    },
    {
        "time": 1689738854000,
        "value": 139.185,
        "_internal_originalTime": 1689738854000
    },
    {
        "time": 1689738856000,
        "value": 139.192,
        "_internal_originalTime": 1689738856000
    },
    {
        "time": 1689738857000,
        "value": 139.191,
        "_internal_originalTime": 1689738857000
    },
    {
        "time": 1689738859000,
        "value": 139.188,
        "_internal_originalTime": 1689738859000
    },
    {
        "time": 1689738860000,
        "value": 139.187,
        "_internal_originalTime": 1689738860000
    },
    {
        "time": 1689738861000,
        "value": 139.191,
        "_internal_originalTime": 1689738861000
    },
    {
        "time": 1689738863000,
        "value": 139.191,
        "_internal_originalTime": 1689738863000
    },
    {
        "time": 1689738865000,
        "value": 139.186,
        "_internal_originalTime": 1689738865000
    },
    {
        "time": 1689738867000,
        "value": 139.189,
        "_internal_originalTime": 1689738867000
    },
    {
        "time": 1689738868000,
        "value": 139.184,
        "_internal_originalTime": 1689738868000
    },
    {
        "time": 1689738869000,
        "value": 139.191,
        "_internal_originalTime": 1689738869000
    },
    {
        "time": 1689738872000,
        "value": 139.186,
        "_internal_originalTime": 1689738872000
    },
    {
        "time": 1689738876000,
        "value": 139.191,
        "_internal_originalTime": 1689738876000
    },
    {
        "time": 1689738878000,
        "value": 139.191,
        "_internal_originalTime": 1689738878000
    },
    {
        "time": 1689738880000,
        "value": 139.182,
        "_internal_originalTime": 1689738880000
    },
    {
        "time": 1689738882000,
        "value": 139.186,
        "_internal_originalTime": 1689738882000
    },
    {
        "time": 1689738883000,
        "value": 139.189,
        "_internal_originalTime": 1689738883000
    },
    {
        "time": 1689738884000,
        "value": 139.186,
        "_internal_originalTime": 1689738884000
    },
    {
        "time": 1689738886000,
        "value": 139.188,
        "_internal_originalTime": 1689738886000
    },
    {
        "time": 1689738887000,
        "value": 139.184,
        "_internal_originalTime": 1689738887000
    },
    {
        "time": 1689738890000,
        "value": 139.181,
        "_internal_originalTime": 1689738890000
    },
    {
        "time": 1689738891000,
        "value": 139.18,
        "_internal_originalTime": 1689738891000
    },
    {
        "time": 1689738893000,
        "value": 139.181,
        "_internal_originalTime": 1689738893000
    },
    {
        "time": 1689738895000,
        "value": 139.18,
        "_internal_originalTime": 1689738895000
    },
    {
        "time": 1689738896000,
        "value": 139.176,
        "_internal_originalTime": 1689738896000
    },
    {
        "time": 1689738900000,
        "value": 139.185,
        "_internal_originalTime": 1689738900000
    },
    {
        "time": 1689738902000,
        "value": 139.18,
        "_internal_originalTime": 1689738902000
    },
    {
        "time": 1689738904000,
        "value": 139.182,
        "_internal_originalTime": 1689738904000
    },
    {
        "time": 1689738906000,
        "value": 139.181,
        "_internal_originalTime": 1689738906000
    },
    {
        "time": 1689738908000,
        "value": 139.184,
        "_internal_originalTime": 1689738908000
    },
    {
        "time": 1689738909000,
        "value": 139.18,
        "_internal_originalTime": 1689738909000
    },
    {
        "time": 1689738911000,
        "value": 139.176,
        "_internal_originalTime": 1689738911000
    },
    {
        "time": 1689738912000,
        "value": 139.181,
        "_internal_originalTime": 1689738912000
    },
    {
        "time": 1689738914000,
        "value": 139.175,
        "_internal_originalTime": 1689738914000
    },
    {
        "time": 1689738915000,
        "value": 139.178,
        "_internal_originalTime": 1689738915000
    },
    {
        "time": 1689738916000,
        "value": 139.18,
        "_internal_originalTime": 1689738916000
    },
    {
        "time": 1689738921000,
        "value": 139.18,
        "_internal_originalTime": 1689738921000
    },
    {
        "time": 1689738922000,
        "value": 139.176,
        "_internal_originalTime": 1689738922000
    },
    {
        "time": 1689738923000,
        "value": 139.175,
        "_internal_originalTime": 1689738923000
    },
    {
        "time": 1689738925000,
        "value": 139.176,
        "_internal_originalTime": 1689738925000
    },
    {
        "time": 1689738926000,
        "value": 139.176,
        "_internal_originalTime": 1689738926000
    },
    {
        "time": 1689738931000,
        "value": 139.179,
        "_internal_originalTime": 1689738931000
    },
    {
        "time": 1689738933000,
        "value": 139.178,
        "_internal_originalTime": 1689738933000
    },
    {
        "time": 1689738934000,
        "value": 139.179,
        "_internal_originalTime": 1689738934000
    },
    {
        "time": 1689738936000,
        "value": 139.176,
        "_internal_originalTime": 1689738936000
    },
    {
        "time": 1689738939000,
        "value": 139.182,
        "_internal_originalTime": 1689738939000
    },
    {
        "time": 1689738940000,
        "value": 139.182,
        "_internal_originalTime": 1689738940000
    },
    {
        "time": 1689738942000,
        "value": 139.181,
        "_internal_originalTime": 1689738942000
    },
    {
        "time": 1689738944000,
        "value": 139.178,
        "_internal_originalTime": 1689738944000
    },
    {
        "time": 1689738945000,
        "value": 139.176,
        "_internal_originalTime": 1689738945000
    },
    {
        "time": 1689738947000,
        "value": 139.177,
        "_internal_originalTime": 1689738947000
    },
    {
        "time": 1689738948000,
        "value": 139.179,
        "_internal_originalTime": 1689738948000
    },
    {
        "time": 1689738950000,
        "value": 139.178,
        "_internal_originalTime": 1689738950000
    },
    {
        "time": 1689738952000,
        "value": 139.18,
        "_internal_originalTime": 1689738952000
    },
    {
        "time": 1689738956000,
        "value": 139.174,
        "_internal_originalTime": 1689738956000
    },
    {
        "time": 1689738958000,
        "value": 139.178,
        "_internal_originalTime": 1689738958000
    },
    {
        "time": 1689738959000,
        "value": 139.166,
        "_internal_originalTime": 1689738959000
    },
    {
        "time": 1689738961000,
        "value": 139.17,
        "_internal_originalTime": 1689738961000
    },
    {
        "time": 1689738962000,
        "value": 139.171,
        "_internal_originalTime": 1689738962000
    },
    {
        "time": 1689738964000,
        "value": 139.169,
        "_internal_originalTime": 1689738964000
    },
    {
        "time": 1689738965000,
        "value": 139.176,
        "_internal_originalTime": 1689738965000
    },
    {
        "time": 1689738967000,
        "value": 139.178,
        "_internal_originalTime": 1689738967000
    },
    {
        "time": 1689738968000,
        "value": 139.179,
        "_internal_originalTime": 1689738968000
    },
    {
        "time": 1689738970000,
        "value": 139.176,
        "_internal_originalTime": 1689738970000
    },
    {
        "time": 1689738971000,
        "value": 139.178,
        "_internal_originalTime": 1689738971000
    },
    {
        "time": 1689738973000,
        "value": 139.177,
        "_internal_originalTime": 1689738973000
    },
    {
        "time": 1689738974000,
        "value": 139.176,
        "_internal_originalTime": 1689738974000
    },
    {
        "time": 1689738976000,
        "value": 139.181,
        "_internal_originalTime": 1689738976000
    },
    {
        "time": 1689738977000,
        "value": 139.181,
        "_internal_originalTime": 1689738977000
    },
    {
        "time": 1689738979000,
        "value": 139.178,
        "_internal_originalTime": 1689738979000
    },
    {
        "time": 1689738981000,
        "value": 139.179,
        "_internal_originalTime": 1689738981000
    },
    {
        "time": 1689738982000,
        "value": 139.176,
        "_internal_originalTime": 1689738982000
    },
    {
        "time": 1689738984000,
        "value": 139.176,
        "_internal_originalTime": 1689738984000
    },
    {
        "time": 1689738985000,
        "value": 139.177,
        "_internal_originalTime": 1689738985000
    },
    {
        "time": 1689738986000,
        "value": 139.176,
        "_internal_originalTime": 1689738986000
    },
    {
        "time": 1689738988000,
        "value": 139.176,
        "_internal_originalTime": 1689738988000
    },
    {
        "time": 1689738990000,
        "value": 139.176,
        "_internal_originalTime": 1689738990000
    },
    {
        "time": 1689738992000,
        "value": 139.178,
        "_internal_originalTime": 1689738992000
    },
    {
        "time": 1689738993000,
        "value": 139.175,
        "_internal_originalTime": 1689738993000
    },
    {
        "time": 1689738995000,
        "value": 139.176,
        "_internal_originalTime": 1689738995000
    },
    {
        "time": 1689738996000,
        "value": 139.175,
        "_internal_originalTime": 1689738996000
    },
    {
        "time": 1689738998000,
        "value": 139.18,
        "_internal_originalTime": 1689738998000
    },
    {
        "time": 1689739000000,
        "value": 139.179,
        "_internal_originalTime": 1689739000000
    },
    {
        "time": 1689739001000,
        "value": 139.179,
        "_internal_originalTime": 1689739001000
    },
    {
        "time": 1689739003000,
        "value": 139.18,
        "_internal_originalTime": 1689739003000
    },
    {
        "time": 1689739004000,
        "value": 139.179,
        "_internal_originalTime": 1689739004000
    },
    {
        "time": 1689739006000,
        "value": 139.175,
        "_internal_originalTime": 1689739006000
    },
    {
        "time": 1689739007000,
        "value": 139.174,
        "_internal_originalTime": 1689739007000
    },
    {
        "time": 1689739008000,
        "value": 139.175,
        "_internal_originalTime": 1689739008000
    },
    {
        "time": 1689739010000,
        "value": 139.176,
        "_internal_originalTime": 1689739010000
    },
    {
        "time": 1689739016000,
        "value": 139.173,
        "_internal_originalTime": 1689739016000
    },
    {
        "time": 1689739018000,
        "value": 139.174,
        "_internal_originalTime": 1689739018000
    },
    {
        "time": 1689739019000,
        "value": 139.175,
        "_internal_originalTime": 1689739019000
    },
    {
        "time": 1689739021000,
        "value": 139.175,
        "_internal_originalTime": 1689739021000
    },
    {
        "time": 1689739022000,
        "value": 139.174,
        "_internal_originalTime": 1689739022000
    },
    {
        "time": 1689739024000,
        "value": 139.174,
        "_internal_originalTime": 1689739024000
    },
    {
        "time": 1689739026000,
        "value": 139.175,
        "_internal_originalTime": 1689739026000
    },
    {
        "time": 1689739028000,
        "value": 139.184,
        "_internal_originalTime": 1689739028000
    },
    {
        "time": 1689739030000,
        "value": 139.205,
        "_internal_originalTime": 1689739030000
    },
    {
        "time": 1689739031000,
        "value": 139.216,
        "_internal_originalTime": 1689739031000
    },
    {
        "time": 1689739033000,
        "value": 139.202,
        "_internal_originalTime": 1689739033000
    },
    {
        "time": 1689739034000,
        "value": 139.202,
        "_internal_originalTime": 1689739034000
    },
    {
        "time": 1689739036000,
        "value": 139.202,
        "_internal_originalTime": 1689739036000
    },
    {
        "time": 1689739037000,
        "value": 139.212,
        "_internal_originalTime": 1689739037000
    },
    {
        "time": 1689739039000,
        "value": 139.21,
        "_internal_originalTime": 1689739039000
    },
    {
        "time": 1689739040000,
        "value": 139.206,
        "_internal_originalTime": 1689739040000
    },
    {
        "time": 1689739042000,
        "value": 139.211,
        "_internal_originalTime": 1689739042000
    },
    {
        "time": 1689739043000,
        "value": 139.216,
        "_internal_originalTime": 1689739043000
    },
    {
        "time": 1689739045000,
        "value": 139.216,
        "_internal_originalTime": 1689739045000
    },
    {
        "time": 1689739046000,
        "value": 139.211,
        "_internal_originalTime": 1689739046000
    },
    {
        "time": 1689739048000,
        "value": 139.211,
        "_internal_originalTime": 1689739048000
    },
    {
        "time": 1689739049000,
        "value": 139.229,
        "_internal_originalTime": 1689739049000
    },
    {
        "time": 1689739050000,
        "value": 139.23,
        "_internal_originalTime": 1689739050000
    },
    {
        "time": 1689739052000,
        "value": 139.221,
        "_internal_originalTime": 1689739052000
    },
    {
        "time": 1689739053000,
        "value": 139.225,
        "_internal_originalTime": 1689739053000
    },
    {
        "time": 1689739055000,
        "value": 139.222,
        "_internal_originalTime": 1689739055000
    },
    {
        "time": 1689739057000,
        "value": 139.211,
        "_internal_originalTime": 1689739057000
    },
    {
        "time": 1689739058000,
        "value": 139.244,
        "_internal_originalTime": 1689739058000
    },
    {
        "time": 1689739060000,
        "value": 139.243,
        "_internal_originalTime": 1689739060000
    },
    {
        "time": 1689739061000,
        "value": 139.24,
        "_internal_originalTime": 1689739061000
    },
    {
        "time": 1689739063000,
        "value": 139.241,
        "_internal_originalTime": 1689739063000
    },
    {
        "time": 1689739064000,
        "value": 139.239,
        "_internal_originalTime": 1689739064000
    },
    {
        "time": 1689739066000,
        "value": 139.24,
        "_internal_originalTime": 1689739066000
    },
    {
        "time": 1689739068000,
        "value": 139.239,
        "_internal_originalTime": 1689739068000
    },
    {
        "time": 1689739069000,
        "value": 139.236,
        "_internal_originalTime": 1689739069000
    },
    {
        "time": 1689739071000,
        "value": 139.238,
        "_internal_originalTime": 1689739071000
    },
    {
        "time": 1689739073000,
        "value": 139.246,
        "_internal_originalTime": 1689739073000
    },
    {
        "time": 1689739076000,
        "value": 139.239,
        "_internal_originalTime": 1689739076000
    },
    {
        "time": 1689739079000,
        "value": 139.251,
        "_internal_originalTime": 1689739079000
    },
    {
        "time": 1689739080000,
        "value": 139.25,
        "_internal_originalTime": 1689739080000
    },
    {
        "time": 1689739081000,
        "value": 139.245,
        "_internal_originalTime": 1689739081000
    },
    {
        "time": 1689739083000,
        "value": 139.242,
        "_internal_originalTime": 1689739083000
    },
    {
        "time": 1689739085000,
        "value": 139.246,
        "_internal_originalTime": 1689739085000
    },
    {
        "time": 1689739086000,
        "value": 139.25,
        "_internal_originalTime": 1689739086000
    },
    {
        "time": 1689739088000,
        "value": 139.249,
        "_internal_originalTime": 1689739088000
    },
    {
        "time": 1689739090000,
        "value": 139.249,
        "_internal_originalTime": 1689739090000
    },
    {
        "time": 1689739091000,
        "value": 139.244,
        "_internal_originalTime": 1689739091000
    },
    {
        "time": 1689739093000,
        "value": 139.247,
        "_internal_originalTime": 1689739093000
    },
    {
        "time": 1689739095000,
        "value": 139.246,
        "_internal_originalTime": 1689739095000
    },
    {
        "time": 1689739097000,
        "value": 139.246,
        "_internal_originalTime": 1689739097000
    },
    {
        "time": 1689739098000,
        "value": 139.246,
        "_internal_originalTime": 1689739098000
    },
    {
        "time": 1689739100000,
        "value": 139.241,
        "_internal_originalTime": 1689739100000
    },
    {
        "time": 1689739102000,
        "value": 139.251,
        "_internal_originalTime": 1689739102000
    },
    {
        "time": 1689739103000,
        "value": 139.249,
        "_internal_originalTime": 1689739103000
    },
    {
        "time": 1689739105000,
        "value": 139.251,
        "_internal_originalTime": 1689739105000
    },
    {
        "time": 1689739106000,
        "value": 139.236,
        "_internal_originalTime": 1689739106000
    },
    {
        "time": 1689739108000,
        "value": 139.246,
        "_internal_originalTime": 1689739108000
    },
    {
        "time": 1689739110000,
        "value": 139.24,
        "_internal_originalTime": 1689739110000
    },
    {
        "time": 1689739111000,
        "value": 139.24,
        "_internal_originalTime": 1689739111000
    },
    {
        "time": 1689739112000,
        "value": 139.242,
        "_internal_originalTime": 1689739112000
    },
    {
        "time": 1689739114000,
        "value": 139.242,
        "_internal_originalTime": 1689739114000
    },
    {
        "time": 1689739116000,
        "value": 139.241,
        "_internal_originalTime": 1689739116000
    },
    {
        "time": 1689739117000,
        "value": 139.242,
        "_internal_originalTime": 1689739117000
    },
    {
        "time": 1689739118000,
        "value": 139.241,
        "_internal_originalTime": 1689739118000
    },
    {
        "time": 1689739121000,
        "value": 139.242,
        "_internal_originalTime": 1689739121000
    },
    {
        "time": 1689739122000,
        "value": 139.242,
        "_internal_originalTime": 1689739122000
    },
    {
        "time": 1689739124000,
        "value": 139.247,
        "_internal_originalTime": 1689739124000
    },
    {
        "time": 1689739126000,
        "value": 139.242,
        "_internal_originalTime": 1689739126000
    },
    {
        "time": 1689739127000,
        "value": 139.243,
        "_internal_originalTime": 1689739127000
    },
    {
        "time": 1689739129000,
        "value": 139.242,
        "_internal_originalTime": 1689739129000
    },
    {
        "time": 1689739131000,
        "value": 139.246,
        "_internal_originalTime": 1689739131000
    },
    {
        "time": 1689739133000,
        "value": 139.242,
        "_internal_originalTime": 1689739133000
    },
    {
        "time": 1689739136000,
        "value": 139.243,
        "_internal_originalTime": 1689739136000
    },
    {
        "time": 1689739138000,
        "value": 139.247,
        "_internal_originalTime": 1689739138000
    },
    {
        "time": 1689739139000,
        "value": 139.243,
        "_internal_originalTime": 1689739139000
    },
    {
        "time": 1689739140000,
        "value": 139.248,
        "_internal_originalTime": 1689739140000
    },
    {
        "time": 1689739141000,
        "value": 139.256,
        "_internal_originalTime": 1689739141000
    },
    {
        "time": 1689739142000,
        "value": 139.259,
        "_internal_originalTime": 1689739142000
    },
    {
        "time": 1689739143000,
        "value": 139.254,
        "_internal_originalTime": 1689739143000
    },
    {
        "time": 1689739146000,
        "value": 139.26,
        "_internal_originalTime": 1689739146000
    },
    {
        "time": 1689739147000,
        "value": 139.263,
        "_internal_originalTime": 1689739147000
    },
    {
        "time": 1689739149000,
        "value": 139.263,
        "_internal_originalTime": 1689739149000
    },
    {
        "time": 1689739151000,
        "value": 139.273,
        "_internal_originalTime": 1689739151000
    },
    {
        "time": 1689739152000,
        "value": 139.279,
        "_internal_originalTime": 1689739152000
    },
    {
        "time": 1689739154000,
        "value": 139.28,
        "_internal_originalTime": 1689739154000
    },
    {
        "time": 1689739155000,
        "value": 139.28,
        "_internal_originalTime": 1689739155000
    },
    {
        "time": 1689739157000,
        "value": 139.272,
        "_internal_originalTime": 1689739157000
    },
    {
        "time": 1689739158000,
        "value": 139.274,
        "_internal_originalTime": 1689739158000
    },
    {
        "time": 1689739160000,
        "value": 139.276,
        "_internal_originalTime": 1689739160000
    },
    {
        "time": 1689739161000,
        "value": 139.28,
        "_internal_originalTime": 1689739161000
    },
    {
        "time": 1689739163000,
        "value": 139.274,
        "_internal_originalTime": 1689739163000
    },
    {
        "time": 1689739165000,
        "value": 139.272,
        "_internal_originalTime": 1689739165000
    },
    {
        "time": 1689739166000,
        "value": 139.274,
        "_internal_originalTime": 1689739166000
    },
    {
        "time": 1689739168000,
        "value": 139.274,
        "_internal_originalTime": 1689739168000
    },
    {
        "time": 1689739169000,
        "value": 139.276,
        "_internal_originalTime": 1689739169000
    },
    {
        "time": 1689739171000,
        "value": 139.274,
        "_internal_originalTime": 1689739171000
    },
    {
        "time": 1689739173000,
        "value": 139.277,
        "_internal_originalTime": 1689739173000
    },
    {
        "time": 1689739174000,
        "value": 139.276,
        "_internal_originalTime": 1689739174000
    },
    {
        "time": 1689739176000,
        "value": 139.27,
        "_internal_originalTime": 1689739176000
    },
    {
        "time": 1689739177000,
        "value": 139.275,
        "_internal_originalTime": 1689739177000
    },
    {
        "time": 1689739180000,
        "value": 139.275,
        "_internal_originalTime": 1689739180000
    },
    {
        "time": 1689739181000,
        "value": 139.275,
        "_internal_originalTime": 1689739181000
    },
    {
        "time": 1689739183000,
        "value": 139.27,
        "_internal_originalTime": 1689739183000
    },
    {
        "time": 1689739184000,
        "value": 139.277,
        "_internal_originalTime": 1689739184000
    },
    {
        "time": 1689739186000,
        "value": 139.276,
        "_internal_originalTime": 1689739186000
    },
    {
        "time": 1689739187000,
        "value": 139.277,
        "_internal_originalTime": 1689739187000
    },
    {
        "time": 1689739188000,
        "value": 139.273,
        "_internal_originalTime": 1689739188000
    },
    {
        "time": 1689739191000,
        "value": 139.277,
        "_internal_originalTime": 1689739191000
    },
    {
        "time": 1689739192000,
        "value": 139.278,
        "_internal_originalTime": 1689739192000
    },
    {
        "time": 1689739194000,
        "value": 139.278,
        "_internal_originalTime": 1689739194000
    },
    {
        "time": 1689739195000,
        "value": 139.279,
        "_internal_originalTime": 1689739195000
    },
    {
        "time": 1689739197000,
        "value": 139.278,
        "_internal_originalTime": 1689739197000
    },
    {
        "time": 1689739199000,
        "value": 139.284,
        "_internal_originalTime": 1689739199000
    },
    {
        "time": 1689739200000,
        "value": 139.285,
        "_internal_originalTime": 1689739200000
    },
    {
        "time": 1689739202000,
        "value": 139.285,
        "_internal_originalTime": 1689739202000
    },
    {
        "time": 1689739203000,
        "value": 139.279,
        "_internal_originalTime": 1689739203000
    },
    {
        "time": 1689739205000,
        "value": 139.282,
        "_internal_originalTime": 1689739205000
    },
    {
        "time": 1689739206000,
        "value": 139.285,
        "_internal_originalTime": 1689739206000
    },
    {
        "time": 1689739207000,
        "value": 139.284,
        "_internal_originalTime": 1689739207000
    },
    {
        "time": 1689739208000,
        "value": 139.284,
        "_internal_originalTime": 1689739208000
    },
    {
        "time": 1689739210000,
        "value": 139.284,
        "_internal_originalTime": 1689739210000
    },
    {
        "time": 1689739212000,
        "value": 139.28,
        "_internal_originalTime": 1689739212000
    },
    {
        "time": 1689739214000,
        "value": 139.279,
        "_internal_originalTime": 1689739214000
    },
    {
        "time": 1689739215000,
        "value": 139.272,
        "_internal_originalTime": 1689739215000
    },
    {
        "time": 1689739216000,
        "value": 139.271,
        "_internal_originalTime": 1689739216000
    },
    {
        "time": 1689739218000,
        "value": 139.272,
        "_internal_originalTime": 1689739218000
    },
    {
        "time": 1689739220000,
        "value": 139.273,
        "_internal_originalTime": 1689739220000
    },
    {
        "time": 1689739221000,
        "value": 139.271,
        "_internal_originalTime": 1689739221000
    },
    {
        "time": 1689739223000,
        "value": 139.265,
        "_internal_originalTime": 1689739223000
    },
    {
        "time": 1689739224000,
        "value": 139.264,
        "_internal_originalTime": 1689739224000
    },
    {
        "time": 1689739227000,
        "value": 139.274,
        "_internal_originalTime": 1689739227000
    },
    {
        "time": 1689739228000,
        "value": 139.268,
        "_internal_originalTime": 1689739228000
    },
    {
        "time": 1689739231000,
        "value": 139.272,
        "_internal_originalTime": 1689739231000
    },
    {
        "time": 1689739233000,
        "value": 139.274,
        "_internal_originalTime": 1689739233000
    },
    {
        "time": 1689739235000,
        "value": 139.275,
        "_internal_originalTime": 1689739235000
    },
    {
        "time": 1689739236000,
        "value": 139.275,
        "_internal_originalTime": 1689739236000
    },
    {
        "time": 1689739238000,
        "value": 139.275,
        "_internal_originalTime": 1689739238000
    },
    {
        "time": 1689739240000,
        "value": 139.271,
        "_internal_originalTime": 1689739240000
    },
    {
        "time": 1689739241000,
        "value": 139.269,
        "_internal_originalTime": 1689739241000
    },
    {
        "time": 1689739242000,
        "value": 139.274,
        "_internal_originalTime": 1689739242000
    },
    {
        "time": 1689739245000,
        "value": 139.275,
        "_internal_originalTime": 1689739245000
    },
    {
        "time": 1689739247000,
        "value": 139.274,
        "_internal_originalTime": 1689739247000
    },
    {
        "time": 1689739249000,
        "value": 139.269,
        "_internal_originalTime": 1689739249000
    },
    {
        "time": 1689739250000,
        "value": 139.271,
        "_internal_originalTime": 1689739250000
    },
    {
        "time": 1689739251000,
        "value": 139.268,
        "_internal_originalTime": 1689739251000
    },
    {
        "time": 1689739253000,
        "value": 139.268,
        "_internal_originalTime": 1689739253000
    },
    {
        "time": 1689739255000,
        "value": 139.274,
        "_internal_originalTime": 1689739255000
    },
    {
        "time": 1689739256000,
        "value": 139.27,
        "_internal_originalTime": 1689739256000
    },
    {
        "time": 1689739257000,
        "value": 139.274,
        "_internal_originalTime": 1689739257000
    },
    {
        "time": 1689739259000,
        "value": 139.274,
        "_internal_originalTime": 1689739259000
    },
    {
        "time": 1689739261000,
        "value": 139.272,
        "_internal_originalTime": 1689739261000
    },
    {
        "time": 1689739262000,
        "value": 139.272,
        "_internal_originalTime": 1689739262000
    },
    {
        "time": 1689739263000,
        "value": 139.271,
        "_internal_originalTime": 1689739263000
    },
    {
        "time": 1689739264000,
        "value": 139.275,
        "_internal_originalTime": 1689739264000
    },
    {
        "time": 1689739267000,
        "value": 139.276,
        "_internal_originalTime": 1689739267000
    },
    {
        "time": 1689739269000,
        "value": 139.277,
        "_internal_originalTime": 1689739269000
    },
    {
        "time": 1689739270000,
        "value": 139.27,
        "_internal_originalTime": 1689739270000
    },
    {
        "time": 1689739272000,
        "value": 139.268,
        "_internal_originalTime": 1689739272000
    },
    {
        "time": 1689739273000,
        "value": 139.266,
        "_internal_originalTime": 1689739273000
    },
    {
        "time": 1689739275000,
        "value": 139.262,
        "_internal_originalTime": 1689739275000
    },
    {
        "time": 1689739276000,
        "value": 139.263,
        "_internal_originalTime": 1689739276000
    },
    {
        "time": 1689739278000,
        "value": 139.266,
        "_internal_originalTime": 1689739278000
    },
    {
        "time": 1689739279000,
        "value": 139.266,
        "_internal_originalTime": 1689739279000
    },
    {
        "time": 1689739281000,
        "value": 139.266,
        "_internal_originalTime": 1689739281000
    },
    {
        "time": 1689739282000,
        "value": 139.262,
        "_internal_originalTime": 1689739282000
    },
    {
        "time": 1689739283000,
        "value": 139.26,
        "_internal_originalTime": 1689739283000
    },
    {
        "time": 1689739285000,
        "value": 139.266,
        "_internal_originalTime": 1689739285000
    },
    {
        "time": 1689739286000,
        "value": 139.266,
        "_internal_originalTime": 1689739286000
    },
    {
        "time": 1689739290000,
        "value": 139.262,
        "_internal_originalTime": 1689739290000
    },
    {
        "time": 1689739295000,
        "value": 139.262,
        "_internal_originalTime": 1689739295000
    },
    {
        "time": 1689739296000,
        "value": 139.266,
        "_internal_originalTime": 1689739296000
    },
    {
        "time": 1689739298000,
        "value": 139.26,
        "_internal_originalTime": 1689739298000
    },
    {
        "time": 1689739299000,
        "value": 139.258,
        "_internal_originalTime": 1689739299000
    },
    {
        "time": 1689739300000,
        "value": 139.266,
        "_internal_originalTime": 1689739300000
    },
    {
        "time": 1689739302000,
        "value": 139.258,
        "_internal_originalTime": 1689739302000
    },
    {
        "time": 1689739304000,
        "value": 139.266,
        "_internal_originalTime": 1689739304000
    },
    {
        "time": 1689739308000,
        "value": 139.265,
        "_internal_originalTime": 1689739308000
    },
    {
        "time": 1689739310000,
        "value": 139.264,
        "_internal_originalTime": 1689739310000
    },
    {
        "time": 1689739312000,
        "value": 139.246,
        "_internal_originalTime": 1689739312000
    },
    {
        "time": 1689739313000,
        "value": 139.257,
        "_internal_originalTime": 1689739313000
    },
    {
        "time": 1689739315000,
        "value": 139.257,
        "_internal_originalTime": 1689739315000
    },
    {
        "time": 1689739317000,
        "value": 139.252,
        "_internal_originalTime": 1689739317000
    },
    {
        "time": 1689739319000,
        "value": 139.252,
        "_internal_originalTime": 1689739319000
    },
    {
        "time": 1689739320000,
        "value": 139.256,
        "_internal_originalTime": 1689739320000
    },
    {
        "time": 1689739321000,
        "value": 139.256,
        "_internal_originalTime": 1689739321000
    },
    {
        "time": 1689739323000,
        "value": 139.252,
        "_internal_originalTime": 1689739323000
    },
    {
        "time": 1689739325000,
        "value": 139.251,
        "_internal_originalTime": 1689739325000
    },
    {
        "time": 1689739326000,
        "value": 139.262,
        "_internal_originalTime": 1689739326000
    },
    {
        "time": 1689739331000,
        "value": 139.263,
        "_internal_originalTime": 1689739331000
    },
    {
        "time": 1689739333000,
        "value": 139.266,
        "_internal_originalTime": 1689739333000
    },
    {
        "time": 1689739335000,
        "value": 139.26,
        "_internal_originalTime": 1689739335000
    },
    {
        "time": 1689739336000,
        "value": 139.26,
        "_internal_originalTime": 1689739336000
    },
    {
        "time": 1689739337000,
        "value": 139.266,
        "_internal_originalTime": 1689739337000
    },
    {
        "time": 1689739340000,
        "value": 139.264,
        "_internal_originalTime": 1689739340000
    },
    {
        "time": 1689739341000,
        "value": 139.262,
        "_internal_originalTime": 1689739341000
    },
    {
        "time": 1689739343000,
        "value": 139.26,
        "_internal_originalTime": 1689739343000
    },
    {
        "time": 1689739344000,
        "value": 139.271,
        "_internal_originalTime": 1689739344000
    },
    {
        "time": 1689739347000,
        "value": 139.275,
        "_internal_originalTime": 1689739347000
    },
    {
        "time": 1689739350000,
        "value": 139.27,
        "_internal_originalTime": 1689739350000
    },
    {
        "time": 1689739352000,
        "value": 139.273,
        "_internal_originalTime": 1689739352000
    },
    {
        "time": 1689739353000,
        "value": 139.274,
        "_internal_originalTime": 1689739353000
    },
    {
        "time": 1689739355000,
        "value": 139.276,
        "_internal_originalTime": 1689739355000
    },
    {
        "time": 1689739356000,
        "value": 139.277,
        "_internal_originalTime": 1689739356000
    },
    {
        "time": 1689739358000,
        "value": 139.278,
        "_internal_originalTime": 1689739358000
    },
    {
        "time": 1689739360000,
        "value": 139.283,
        "_internal_originalTime": 1689739360000
    },
    {
        "time": 1689739361000,
        "value": 139.278,
        "_internal_originalTime": 1689739361000
    },
    {
        "time": 1689739364000,
        "value": 139.282,
        "_internal_originalTime": 1689739364000
    },
    {
        "time": 1689739365000,
        "value": 139.281,
        "_internal_originalTime": 1689739365000
    },
    {
        "time": 1689739366000,
        "value": 139.29,
        "_internal_originalTime": 1689739366000
    },
    {
        "time": 1689739368000,
        "value": 139.295,
        "_internal_originalTime": 1689739368000
    },
    {
        "time": 1689739370000,
        "value": 139.291,
        "_internal_originalTime": 1689739370000
    },
    {
        "time": 1689739372000,
        "value": 139.293,
        "_internal_originalTime": 1689739372000
    },
    {
        "time": 1689739374000,
        "value": 139.295,
        "_internal_originalTime": 1689739374000
    },
    {
        "time": 1689739375000,
        "value": 139.293,
        "_internal_originalTime": 1689739375000
    },
    {
        "time": 1689739377000,
        "value": 139.304,
        "_internal_originalTime": 1689739377000
    },
    {
        "time": 1689739379000,
        "value": 139.305,
        "_internal_originalTime": 1689739379000
    },
    {
        "time": 1689739381000,
        "value": 139.305,
        "_internal_originalTime": 1689739381000
    },
    {
        "time": 1689739382000,
        "value": 139.314,
        "_internal_originalTime": 1689739382000
    },
    {
        "time": 1689739384000,
        "value": 139.311,
        "_internal_originalTime": 1689739384000
    },
    {
        "time": 1689739385000,
        "value": 139.314,
        "_internal_originalTime": 1689739385000
    },
    {
        "time": 1689739387000,
        "value": 139.313,
        "_internal_originalTime": 1689739387000
    },
    {
        "time": 1689739390000,
        "value": 139.314,
        "_internal_originalTime": 1689739390000
    },
    {
        "time": 1689739391000,
        "value": 139.312,
        "_internal_originalTime": 1689739391000
    },
    {
        "time": 1689739393000,
        "value": 139.314,
        "_internal_originalTime": 1689739393000
    },
    {
        "time": 1689739395000,
        "value": 139.31,
        "_internal_originalTime": 1689739395000
    },
    {
        "time": 1689739397000,
        "value": 139.313,
        "_internal_originalTime": 1689739397000
    },
    {
        "time": 1689739398000,
        "value": 139.313,
        "_internal_originalTime": 1689739398000
    },
    {
        "time": 1689739399000,
        "value": 139.313,
        "_internal_originalTime": 1689739399000
    },
    {
        "time": 1689739400000,
        "value": 139.314,
        "_internal_originalTime": 1689739400000
    },
    {
        "time": 1689739402000,
        "value": 139.314,
        "_internal_originalTime": 1689739402000
    },
    {
        "time": 1689739404000,
        "value": 139.315,
        "_internal_originalTime": 1689739404000
    },
    {
        "time": 1689739406000,
        "value": 139.324,
        "_internal_originalTime": 1689739406000
    },
    {
        "time": 1689739407000,
        "value": 139.329,
        "_internal_originalTime": 1689739407000
    },
    {
        "time": 1689739408000,
        "value": 139.329,
        "_internal_originalTime": 1689739408000
    },
    {
        "time": 1689739410000,
        "value": 139.331,
        "_internal_originalTime": 1689739410000
    },
    {
        "time": 1689739411000,
        "value": 139.331,
        "_internal_originalTime": 1689739411000
    },
    {
        "time": 1689739412000,
        "value": 139.329,
        "_internal_originalTime": 1689739412000
    },
    {
        "time": 1689739414000,
        "value": 139.334,
        "_internal_originalTime": 1689739414000
    },
    {
        "time": 1689739416000,
        "value": 139.335,
        "_internal_originalTime": 1689739416000
    },
    {
        "time": 1689739417000,
        "value": 139.335,
        "_internal_originalTime": 1689739417000
    },
    {
        "time": 1689739419000,
        "value": 139.348,
        "_internal_originalTime": 1689739419000
    },
    {
        "time": 1689739420000,
        "value": 139.35,
        "_internal_originalTime": 1689739420000
    },
    {
        "time": 1689739422000,
        "value": 139.345,
        "_internal_originalTime": 1689739422000
    },
    {
        "time": 1689739423000,
        "value": 139.347,
        "_internal_originalTime": 1689739423000
    },
    {
        "time": 1689739424000,
        "value": 139.34,
        "_internal_originalTime": 1689739424000
    },
    {
        "time": 1689739425000,
        "value": 139.347,
        "_internal_originalTime": 1689739425000
    },
    {
        "time": 1689739426000,
        "value": 139.346,
        "_internal_originalTime": 1689739426000
    },
    {
        "time": 1689739428000,
        "value": 139.343,
        "_internal_originalTime": 1689739428000
    },
    {
        "time": 1689739431000,
        "value": 139.331,
        "_internal_originalTime": 1689739431000
    },
    {
        "time": 1689739432000,
        "value": 139.335,
        "_internal_originalTime": 1689739432000
    },
    {
        "time": 1689739434000,
        "value": 139.326,
        "_internal_originalTime": 1689739434000
    },
    {
        "time": 1689739436000,
        "value": 139.32,
        "_internal_originalTime": 1689739436000
    },
    {
        "time": 1689739437000,
        "value": 139.313,
        "_internal_originalTime": 1689739437000
    },
    {
        "time": 1689739439000,
        "value": 139.314,
        "_internal_originalTime": 1689739439000
    },
    {
        "time": 1689739440000,
        "value": 139.31,
        "_internal_originalTime": 1689739440000
    },
    {
        "time": 1689739441000,
        "value": 139.314,
        "_internal_originalTime": 1689739441000
    },
    {
        "time": 1689739443000,
        "value": 139.309,
        "_internal_originalTime": 1689739443000
    },
    {
        "time": 1689739444000,
        "value": 139.302,
        "_internal_originalTime": 1689739444000
    },
    {
        "time": 1689739445000,
        "value": 139.311,
        "_internal_originalTime": 1689739445000
    },
    {
        "time": 1689739447000,
        "value": 139.31,
        "_internal_originalTime": 1689739447000
    },
    {
        "time": 1689739449000,
        "value": 139.303,
        "_internal_originalTime": 1689739449000
    },
    {
        "time": 1689739451000,
        "value": 139.297,
        "_internal_originalTime": 1689739451000
    },
    {
        "time": 1689739453000,
        "value": 139.297,
        "_internal_originalTime": 1689739453000
    },
    {
        "time": 1689739454000,
        "value": 139.29,
        "_internal_originalTime": 1689739454000
    },
    {
        "time": 1689739455000,
        "value": 139.302,
        "_internal_originalTime": 1689739455000
    },
    {
        "time": 1689739457000,
        "value": 139.297,
        "_internal_originalTime": 1689739457000
    },
    {
        "time": 1689739458000,
        "value": 139.29,
        "_internal_originalTime": 1689739458000
    },
    {
        "time": 1689739459000,
        "value": 139.287,
        "_internal_originalTime": 1689739459000
    },
    {
        "time": 1689739461000,
        "value": 139.291,
        "_internal_originalTime": 1689739461000
    },
    {
        "time": 1689739463000,
        "value": 139.287,
        "_internal_originalTime": 1689739463000
    },
    {
        "time": 1689739464000,
        "value": 139.286,
        "_internal_originalTime": 1689739464000
    },
    {
        "time": 1689739466000,
        "value": 139.292,
        "_internal_originalTime": 1689739466000
    },
    {
        "time": 1689739468000,
        "value": 139.297,
        "_internal_originalTime": 1689739468000
    },
    {
        "time": 1689739469000,
        "value": 139.29,
        "_internal_originalTime": 1689739469000
    },
    {
        "time": 1689739470000,
        "value": 139.29,
        "_internal_originalTime": 1689739470000
    },
    {
        "time": 1689739471000,
        "value": 139.288,
        "_internal_originalTime": 1689739471000
    },
    {
        "time": 1689739473000,
        "value": 139.29,
        "_internal_originalTime": 1689739473000
    },
    {
        "time": 1689739475000,
        "value": 139.293,
        "_internal_originalTime": 1689739475000
    },
    {
        "time": 1689739477000,
        "value": 139.301,
        "_internal_originalTime": 1689739477000
    },
    {
        "time": 1689739479000,
        "value": 139.308,
        "_internal_originalTime": 1689739479000
    },
    {
        "time": 1689739481000,
        "value": 139.308,
        "_internal_originalTime": 1689739481000
    },
    {
        "time": 1689739482000,
        "value": 139.306,
        "_internal_originalTime": 1689739482000
    },
    {
        "time": 1689739485000,
        "value": 139.307,
        "_internal_originalTime": 1689739485000
    },
    {
        "time": 1689739487000,
        "value": 139.31,
        "_internal_originalTime": 1689739487000
    },
    {
        "time": 1689739488000,
        "value": 139.31,
        "_internal_originalTime": 1689739488000
    },
    {
        "time": 1689739491000,
        "value": 139.31,
        "_internal_originalTime": 1689739491000
    },
    {
        "time": 1689739494000,
        "value": 139.31,
        "_internal_originalTime": 1689739494000
    },
    {
        "time": 1689739495000,
        "value": 139.31,
        "_internal_originalTime": 1689739495000
    },
    {
        "time": 1689739497000,
        "value": 139.317,
        "_internal_originalTime": 1689739497000
    },
    {
        "time": 1689739498000,
        "value": 139.315,
        "_internal_originalTime": 1689739498000
    },
    {
        "time": 1689739500000,
        "value": 139.318,
        "_internal_originalTime": 1689739500000
    },
    {
        "time": 1689739501000,
        "value": 139.318,
        "_internal_originalTime": 1689739501000
    },
    {
        "time": 1689739502000,
        "value": 139.312,
        "_internal_originalTime": 1689739502000
    },
    {
        "time": 1689739503000,
        "value": 139.317,
        "_internal_originalTime": 1689739503000
    },
    {
        "time": 1689739507000,
        "value": 139.314,
        "_internal_originalTime": 1689739507000
    },
    {
        "time": 1689739509000,
        "value": 139.313,
        "_internal_originalTime": 1689739509000
    },
    {
        "time": 1689739510000,
        "value": 139.315,
        "_internal_originalTime": 1689739510000
    },
    {
        "time": 1689739512000,
        "value": 139.314,
        "_internal_originalTime": 1689739512000
    },
    {
        "time": 1689739513000,
        "value": 139.315,
        "_internal_originalTime": 1689739513000
    },
    {
        "time": 1689739515000,
        "value": 139.319,
        "_internal_originalTime": 1689739515000
    },
    {
        "time": 1689739516000,
        "value": 139.319,
        "_internal_originalTime": 1689739516000
    },
    {
        "time": 1689739517000,
        "value": 139.319,
        "_internal_originalTime": 1689739517000
    },
    {
        "time": 1689739518000,
        "value": 139.319,
        "_internal_originalTime": 1689739518000
    },
    {
        "time": 1689739520000,
        "value": 139.318,
        "_internal_originalTime": 1689739520000
    },
    {
        "time": 1689739522000,
        "value": 139.326,
        "_internal_originalTime": 1689739522000
    },
    {
        "time": 1689739524000,
        "value": 139.331,
        "_internal_originalTime": 1689739524000
    },
    {
        "time": 1689739525000,
        "value": 139.329,
        "_internal_originalTime": 1689739525000
    },
    {
        "time": 1689739527000,
        "value": 139.329,
        "_internal_originalTime": 1689739527000
    },
    {
        "time": 1689739528000,
        "value": 139.325,
        "_internal_originalTime": 1689739528000
    },
    {
        "time": 1689739530000,
        "value": 139.325,
        "_internal_originalTime": 1689739530000
    },
    {
        "time": 1689739531000,
        "value": 139.334,
        "_internal_originalTime": 1689739531000
    },
    {
        "time": 1689739532000,
        "value": 139.33,
        "_internal_originalTime": 1689739532000
    },
    {
        "time": 1689739534000,
        "value": 139.333,
        "_internal_originalTime": 1689739534000
    },
    {
        "time": 1689739536000,
        "value": 139.321,
        "_internal_originalTime": 1689739536000
    },
    {
        "time": 1689739537000,
        "value": 139.325,
        "_internal_originalTime": 1689739537000
    },
    {
        "time": 1689739538000,
        "value": 139.325,
        "_internal_originalTime": 1689739538000
    },
    {
        "time": 1689739540000,
        "value": 139.327,
        "_internal_originalTime": 1689739540000
    },
    {
        "time": 1689739542000,
        "value": 139.323,
        "_internal_originalTime": 1689739542000
    },
    {
        "time": 1689739544000,
        "value": 139.324,
        "_internal_originalTime": 1689739544000
    },
    {
        "time": 1689739545000,
        "value": 139.323,
        "_internal_originalTime": 1689739545000
    },
    {
        "time": 1689739549000,
        "value": 139.324,
        "_internal_originalTime": 1689739549000
    },
    {
        "time": 1689739551000,
        "value": 139.323,
        "_internal_originalTime": 1689739551000
    },
    {
        "time": 1689739552000,
        "value": 139.323,
        "_internal_originalTime": 1689739552000
    },
    {
        "time": 1689739554000,
        "value": 139.324,
        "_internal_originalTime": 1689739554000
    },
    {
        "time": 1689739557000,
        "value": 139.324,
        "_internal_originalTime": 1689739557000
    },
    {
        "time": 1689739558000,
        "value": 139.323,
        "_internal_originalTime": 1689739558000
    },
    {
        "time": 1689739559000,
        "value": 139.324,
        "_internal_originalTime": 1689739559000
    },
    {
        "time": 1689739560000,
        "value": 139.325,
        "_internal_originalTime": 1689739560000
    },
    {
        "time": 1689739562000,
        "value": 139.311,
        "_internal_originalTime": 1689739562000
    },
    {
        "time": 1689739563000,
        "value": 139.307,
        "_internal_originalTime": 1689739563000
    },
    {
        "time": 1689739564000,
        "value": 139.307,
        "_internal_originalTime": 1689739564000
    },
    {
        "time": 1689739566000,
        "value": 139.307,
        "_internal_originalTime": 1689739566000
    },
    {
        "time": 1689739567000,
        "value": 139.304,
        "_internal_originalTime": 1689739567000
    },
    {
        "time": 1689739569000,
        "value": 139.303,
        "_internal_originalTime": 1689739569000
    },
    {
        "time": 1689739571000,
        "value": 139.308,
        "_internal_originalTime": 1689739571000
    },
    {
        "time": 1689739572000,
        "value": 139.302,
        "_internal_originalTime": 1689739572000
    },
    {
        "time": 1689739574000,
        "value": 139.306,
        "_internal_originalTime": 1689739574000
    },
    {
        "time": 1689739575000,
        "value": 139.304,
        "_internal_originalTime": 1689739575000
    },
    {
        "time": 1689739577000,
        "value": 139.302,
        "_internal_originalTime": 1689739577000
    },
    {
        "time": 1689739578000,
        "value": 139.308,
        "_internal_originalTime": 1689739578000
    },
    {
        "time": 1689739580000,
        "value": 139.304,
        "_internal_originalTime": 1689739580000
    },
    {
        "time": 1689739581000,
        "value": 139.307,
        "_internal_originalTime": 1689739581000
    },
    {
        "time": 1689739583000,
        "value": 139.305,
        "_internal_originalTime": 1689739583000
    },
    {
        "time": 1689739586000,
        "value": 139.303,
        "_internal_originalTime": 1689739586000
    },
    {
        "time": 1689739587000,
        "value": 139.305,
        "_internal_originalTime": 1689739587000
    },
    {
        "time": 1689739589000,
        "value": 139.306,
        "_internal_originalTime": 1689739589000
    },
    {
        "time": 1689739591000,
        "value": 139.304,
        "_internal_originalTime": 1689739591000
    },
    {
        "time": 1689739596000,
        "value": 139.303,
        "_internal_originalTime": 1689739596000
    },
    {
        "time": 1689739603000,
        "value": 139.307,
        "_internal_originalTime": 1689739603000
    },
    {
        "time": 1689739608000,
        "value": 139.315,
        "_internal_originalTime": 1689739608000
    },
    {
        "time": 1689739610000,
        "value": 139.313,
        "_internal_originalTime": 1689739610000
    },
    {
        "time": 1689739611000,
        "value": 139.313,
        "_internal_originalTime": 1689739611000
    },
    {
        "time": 1689739613000,
        "value": 139.312,
        "_internal_originalTime": 1689739613000
    },
    {
        "time": 1689739615000,
        "value": 139.31,
        "_internal_originalTime": 1689739615000
    },
    {
        "time": 1689739616000,
        "value": 139.311,
        "_internal_originalTime": 1689739616000
    },
    {
        "time": 1689739618000,
        "value": 139.312,
        "_internal_originalTime": 1689739618000
    },
    {
        "time": 1689739619000,
        "value": 139.31,
        "_internal_originalTime": 1689739619000
    },
    {
        "time": 1689739621000,
        "value": 139.311,
        "_internal_originalTime": 1689739621000
    },
    {
        "time": 1689739623000,
        "value": 139.313,
        "_internal_originalTime": 1689739623000
    },
    {
        "time": 1689739624000,
        "value": 139.311,
        "_internal_originalTime": 1689739624000
    },
    {
        "time": 1689739626000,
        "value": 139.311,
        "_internal_originalTime": 1689739626000
    },
    {
        "time": 1689739627000,
        "value": 139.31,
        "_internal_originalTime": 1689739627000
    },
    {
        "time": 1689739629000,
        "value": 139.309,
        "_internal_originalTime": 1689739629000
    },
    {
        "time": 1689739631000,
        "value": 139.313,
        "_internal_originalTime": 1689739631000
    },
    {
        "time": 1689739633000,
        "value": 139.313,
        "_internal_originalTime": 1689739633000
    },
    {
        "time": 1689739635000,
        "value": 139.314,
        "_internal_originalTime": 1689739635000
    },
    {
        "time": 1689739638000,
        "value": 139.312,
        "_internal_originalTime": 1689739638000
    },
    {
        "time": 1689739639000,
        "value": 139.31,
        "_internal_originalTime": 1689739639000
    },
    {
        "time": 1689739640000,
        "value": 139.31,
        "_internal_originalTime": 1689739640000
    },
    {
        "time": 1689739642000,
        "value": 139.31,
        "_internal_originalTime": 1689739642000
    },
    {
        "time": 1689739644000,
        "value": 139.31,
        "_internal_originalTime": 1689739644000
    },
    {
        "time": 1689739645000,
        "value": 139.315,
        "_internal_originalTime": 1689739645000
    },
    {
        "time": 1689739646000,
        "value": 139.308,
        "_internal_originalTime": 1689739646000
    },
    {
        "time": 1689739649000,
        "value": 139.315,
        "_internal_originalTime": 1689739649000
    },
    {
        "time": 1689739651000,
        "value": 139.313,
        "_internal_originalTime": 1689739651000
    },
    {
        "time": 1689739652000,
        "value": 139.313,
        "_internal_originalTime": 1689739652000
    },
    {
        "time": 1689739655000,
        "value": 139.323,
        "_internal_originalTime": 1689739655000
    },
    {
        "time": 1689739656000,
        "value": 139.321,
        "_internal_originalTime": 1689739656000
    },
    {
        "time": 1689739658000,
        "value": 139.323,
        "_internal_originalTime": 1689739658000
    },
    {
        "time": 1689739661000,
        "value": 139.318,
        "_internal_originalTime": 1689739661000
    },
    {
        "time": 1689739662000,
        "value": 139.32,
        "_internal_originalTime": 1689739662000
    },
    {
        "time": 1689739665000,
        "value": 139.323,
        "_internal_originalTime": 1689739665000
    },
    {
        "time": 1689739668000,
        "value": 139.323,
        "_internal_originalTime": 1689739668000
    },
    {
        "time": 1689739671000,
        "value": 139.323,
        "_internal_originalTime": 1689739671000
    },
    {
        "time": 1689739673000,
        "value": 139.32,
        "_internal_originalTime": 1689739673000
    },
    {
        "time": 1689739675000,
        "value": 139.323,
        "_internal_originalTime": 1689739675000
    },
    {
        "time": 1689739676000,
        "value": 139.318,
        "_internal_originalTime": 1689739676000
    },
    {
        "time": 1689739680000,
        "value": 139.323,
        "_internal_originalTime": 1689739680000
    },
    {
        "time": 1689739681000,
        "value": 139.3,
        "_internal_originalTime": 1689739681000
    },
    {
        "time": 1689739683000,
        "value": 139.32,
        "_internal_originalTime": 1689739683000
    },
    {
        "time": 1689739684000,
        "value": 139.325,
        "_internal_originalTime": 1689739684000
    },
    {
        "time": 1689739685000,
        "value": 139.321,
        "_internal_originalTime": 1689739685000
    },
    {
        "time": 1689739687000,
        "value": 139.322,
        "_internal_originalTime": 1689739687000
    },
    {
        "time": 1689739689000,
        "value": 139.323,
        "_internal_originalTime": 1689739689000
    },
    {
        "time": 1689739690000,
        "value": 139.323,
        "_internal_originalTime": 1689739690000
    },
    {
        "time": 1689739693000,
        "value": 139.324,
        "_internal_originalTime": 1689739693000
    },
    {
        "time": 1689739695000,
        "value": 139.32,
        "_internal_originalTime": 1689739695000
    },
    {
        "time": 1689739696000,
        "value": 139.32,
        "_internal_originalTime": 1689739696000
    },
    {
        "time": 1689739697000,
        "value": 139.323,
        "_internal_originalTime": 1689739697000
    },
    {
        "time": 1689739700000,
        "value": 139.33,
        "_internal_originalTime": 1689739700000
    },
    {
        "time": 1689739701000,
        "value": 139.333,
        "_internal_originalTime": 1689739701000
    },
    {
        "time": 1689739704000,
        "value": 139.332,
        "_internal_originalTime": 1689739704000
    },
    {
        "time": 1689739706000,
        "value": 139.33,
        "_internal_originalTime": 1689739706000
    },
    {
        "time": 1689739707000,
        "value": 139.332,
        "_internal_originalTime": 1689739707000
    },
    {
        "time": 1689739709000,
        "value": 139.331,
        "_internal_originalTime": 1689739709000
    },
    {
        "time": 1689739710000,
        "value": 139.33,
        "_internal_originalTime": 1689739710000
    },
    {
        "time": 1689739712000,
        "value": 139.333,
        "_internal_originalTime": 1689739712000
    },
    {
        "time": 1689739713000,
        "value": 139.333,
        "_internal_originalTime": 1689739713000
    },
    {
        "time": 1689739717000,
        "value": 139.332,
        "_internal_originalTime": 1689739717000
    },
    {
        "time": 1689739719000,
        "value": 139.334,
        "_internal_originalTime": 1689739719000
    },
    {
        "time": 1689739720000,
        "value": 139.333,
        "_internal_originalTime": 1689739720000
    },
    {
        "time": 1689739722000,
        "value": 139.33,
        "_internal_originalTime": 1689739722000
    },
    {
        "time": 1689739724000,
        "value": 139.334,
        "_internal_originalTime": 1689739724000
    },
    {
        "time": 1689739725000,
        "value": 139.333,
        "_internal_originalTime": 1689739725000
    },
    {
        "time": 1689739728000,
        "value": 139.332,
        "_internal_originalTime": 1689739728000
    },
    {
        "time": 1689739731000,
        "value": 139.331,
        "_internal_originalTime": 1689739731000
    },
    {
        "time": 1689739732000,
        "value": 139.331,
        "_internal_originalTime": 1689739732000
    },
    {
        "time": 1689739734000,
        "value": 139.328,
        "_internal_originalTime": 1689739734000
    },
    {
        "time": 1689739735000,
        "value": 139.334,
        "_internal_originalTime": 1689739735000
    },
    {
        "time": 1689739738000,
        "value": 139.334,
        "_internal_originalTime": 1689739738000
    },
    {
        "time": 1689739740000,
        "value": 139.332,
        "_internal_originalTime": 1689739740000
    },
    {
        "time": 1689739741000,
        "value": 139.331,
        "_internal_originalTime": 1689739741000
    },
    {
        "time": 1689739742000,
        "value": 139.333,
        "_internal_originalTime": 1689739742000
    },
    {
        "time": 1689739745000,
        "value": 139.336,
        "_internal_originalTime": 1689739745000
    },
    {
        "time": 1689739747000,
        "value": 139.33,
        "_internal_originalTime": 1689739747000
    },
    {
        "time": 1689739748000,
        "value": 139.331,
        "_internal_originalTime": 1689739748000
    },
    {
        "time": 1689739750000,
        "value": 139.331,
        "_internal_originalTime": 1689739750000
    },
    {
        "time": 1689739751000,
        "value": 139.329,
        "_internal_originalTime": 1689739751000
    },
    {
        "time": 1689739754000,
        "value": 139.332,
        "_internal_originalTime": 1689739754000
    },
    {
        "time": 1689739757000,
        "value": 139.332,
        "_internal_originalTime": 1689739757000
    },
    {
        "time": 1689739759000,
        "value": 139.334,
        "_internal_originalTime": 1689739759000
    },
    {
        "time": 1689739760000,
        "value": 139.33,
        "_internal_originalTime": 1689739760000
    },
    {
        "time": 1689739763000,
        "value": 139.322,
        "_internal_originalTime": 1689739763000
    },
    {
        "time": 1689739764000,
        "value": 139.32,
        "_internal_originalTime": 1689739764000
    },
    {
        "time": 1689739766000,
        "value": 139.318,
        "_internal_originalTime": 1689739766000
    },
    {
        "time": 1689739767000,
        "value": 139.331,
        "_internal_originalTime": 1689739767000
    },
    {
        "time": 1689739769000,
        "value": 139.328,
        "_internal_originalTime": 1689739769000
    },
    {
        "time": 1689739771000,
        "value": 139.332,
        "_internal_originalTime": 1689739771000
    },
    {
        "time": 1689739773000,
        "value": 139.335,
        "_internal_originalTime": 1689739773000
    },
    {
        "time": 1689739774000,
        "value": 139.332,
        "_internal_originalTime": 1689739774000
    },
    {
        "time": 1689739776000,
        "value": 139.342,
        "_internal_originalTime": 1689739776000
    },
    {
        "time": 1689739778000,
        "value": 139.341,
        "_internal_originalTime": 1689739778000
    },
    {
        "time": 1689739780000,
        "value": 139.345,
        "_internal_originalTime": 1689739780000
    },
    {
        "time": 1689739782000,
        "value": 139.34,
        "_internal_originalTime": 1689739782000
    },
    {
        "time": 1689739785000,
        "value": 139.345,
        "_internal_originalTime": 1689739785000
    },
    {
        "time": 1689739786000,
        "value": 139.343,
        "_internal_originalTime": 1689739786000
    },
    {
        "time": 1689739788000,
        "value": 139.346,
        "_internal_originalTime": 1689739788000
    },
    {
        "time": 1689739790000,
        "value": 139.351,
        "_internal_originalTime": 1689739790000
    },
    {
        "time": 1689739791000,
        "value": 139.35,
        "_internal_originalTime": 1689739791000
    },
    {
        "time": 1689739793000,
        "value": 139.359,
        "_internal_originalTime": 1689739793000
    },
    {
        "time": 1689739794000,
        "value": 139.358,
        "_internal_originalTime": 1689739794000
    },
    {
        "time": 1689739796000,
        "value": 139.361,
        "_internal_originalTime": 1689739796000
    },
    {
        "time": 1689739798000,
        "value": 139.353,
        "_internal_originalTime": 1689739798000
    },
    {
        "time": 1689739799000,
        "value": 139.354,
        "_internal_originalTime": 1689739799000
    },
    {
        "time": 1689739800000,
        "value": 139.33,
        "_internal_originalTime": 1689739800000
    },
    {
        "time": 1689739804000,
        "value": 139.361,
        "_internal_originalTime": 1689739804000
    },
    {
        "time": 1689739806000,
        "value": 139.364,
        "_internal_originalTime": 1689739806000
    },
    {
        "time": 1689739807000,
        "value": 139.364,
        "_internal_originalTime": 1689739807000
    },
    {
        "time": 1689739809000,
        "value": 139.364,
        "_internal_originalTime": 1689739809000
    },
    {
        "time": 1689739810000,
        "value": 139.36,
        "_internal_originalTime": 1689739810000
    },
    {
        "time": 1689739812000,
        "value": 139.36,
        "_internal_originalTime": 1689739812000
    },
    {
        "time": 1689739814000,
        "value": 139.363,
        "_internal_originalTime": 1689739814000
    },
    {
        "time": 1689739815000,
        "value": 139.363,
        "_internal_originalTime": 1689739815000
    },
    {
        "time": 1689739816000,
        "value": 139.365,
        "_internal_originalTime": 1689739816000
    },
    {
        "time": 1689739818000,
        "value": 139.364,
        "_internal_originalTime": 1689739818000
    },
    {
        "time": 1689739820000,
        "value": 139.367,
        "_internal_originalTime": 1689739820000
    },
    {
        "time": 1689739821000,
        "value": 139.373,
        "_internal_originalTime": 1689739821000
    },
    {
        "time": 1689739823000,
        "value": 139.374,
        "_internal_originalTime": 1689739823000
    },
    {
        "time": 1689739825000,
        "value": 139.369,
        "_internal_originalTime": 1689739825000
    },
    {
        "time": 1689739827000,
        "value": 139.368,
        "_internal_originalTime": 1689739827000
    },
    {
        "time": 1689739829000,
        "value": 139.369,
        "_internal_originalTime": 1689739829000
    },
    {
        "time": 1689739830000,
        "value": 139.371,
        "_internal_originalTime": 1689739830000
    },
    {
        "time": 1689739831000,
        "value": 139.371,
        "_internal_originalTime": 1689739831000
    },
    {
        "time": 1689739832000,
        "value": 139.369,
        "_internal_originalTime": 1689739832000
    },
    {
        "time": 1689739835000,
        "value": 139.37,
        "_internal_originalTime": 1689739835000
    },
    {
        "time": 1689739838000,
        "value": 139.36,
        "_internal_originalTime": 1689739838000
    },
    {
        "time": 1689739839000,
        "value": 139.364,
        "_internal_originalTime": 1689739839000
    },
    {
        "time": 1689739842000,
        "value": 139.363,
        "_internal_originalTime": 1689739842000
    },
    {
        "time": 1689739844000,
        "value": 139.363,
        "_internal_originalTime": 1689739844000
    },
    {
        "time": 1689739845000,
        "value": 139.361,
        "_internal_originalTime": 1689739845000
    },
    {
        "time": 1689739846000,
        "value": 139.364,
        "_internal_originalTime": 1689739846000
    },
    {
        "time": 1689739848000,
        "value": 139.36,
        "_internal_originalTime": 1689739848000
    },
    {
        "time": 1689739852000,
        "value": 139.35,
        "_internal_originalTime": 1689739852000
    },
    {
        "time": 1689739853000,
        "value": 139.352,
        "_internal_originalTime": 1689739853000
    },
    {
        "time": 1689739855000,
        "value": 139.35,
        "_internal_originalTime": 1689739855000
    },
    {
        "time": 1689739857000,
        "value": 139.346,
        "_internal_originalTime": 1689739857000
    },
    {
        "time": 1689739859000,
        "value": 139.349,
        "_internal_originalTime": 1689739859000
    },
    {
        "time": 1689739860000,
        "value": 139.35,
        "_internal_originalTime": 1689739860000
    },
    {
        "time": 1689739863000,
        "value": 139.348,
        "_internal_originalTime": 1689739863000
    },
    {
        "time": 1689739865000,
        "value": 139.352,
        "_internal_originalTime": 1689739865000
    },
    {
        "time": 1689739867000,
        "value": 139.349,
        "_internal_originalTime": 1689739867000
    },
    {
        "time": 1689739869000,
        "value": 139.348,
        "_internal_originalTime": 1689739869000
    },
    {
        "time": 1689739873000,
        "value": 139.352,
        "_internal_originalTime": 1689739873000
    },
    {
        "time": 1689739875000,
        "value": 139.347,
        "_internal_originalTime": 1689739875000
    },
    {
        "time": 1689739879000,
        "value": 139.351,
        "_internal_originalTime": 1689739879000
    },
    {
        "time": 1689739881000,
        "value": 139.352,
        "_internal_originalTime": 1689739881000
    },
    {
        "time": 1689739884000,
        "value": 139.34,
        "_internal_originalTime": 1689739884000
    },
    {
        "time": 1689739887000,
        "value": 139.341,
        "_internal_originalTime": 1689739887000
    },
    {
        "time": 1689739889000,
        "value": 139.33,
        "_internal_originalTime": 1689739889000
    },
    {
        "time": 1689739890000,
        "value": 139.331,
        "_internal_originalTime": 1689739890000
    },
    {
        "time": 1689739891000,
        "value": 139.342,
        "_internal_originalTime": 1689739891000
    },
    {
        "time": 1689739892000,
        "value": 139.338,
        "_internal_originalTime": 1689739892000
    },
    {
        "time": 1689739894000,
        "value": 139.342,
        "_internal_originalTime": 1689739894000
    },
    {
        "time": 1689739896000,
        "value": 139.342,
        "_internal_originalTime": 1689739896000
    },
    {
        "time": 1689739898000,
        "value": 139.338,
        "_internal_originalTime": 1689739898000
    },
    {
        "time": 1689739899000,
        "value": 139.342,
        "_internal_originalTime": 1689739899000
    },
    {
        "time": 1689739901000,
        "value": 139.341,
        "_internal_originalTime": 1689739901000
    },
    {
        "time": 1689739902000,
        "value": 139.338,
        "_internal_originalTime": 1689739902000
    },
    {
        "time": 1689739904000,
        "value": 139.343,
        "_internal_originalTime": 1689739904000
    },
    {
        "time": 1689739905000,
        "value": 139.341,
        "_internal_originalTime": 1689739905000
    },
    {
        "time": 1689739907000,
        "value": 139.344,
        "_internal_originalTime": 1689739907000
    },
    {
        "time": 1689739908000,
        "value": 139.338,
        "_internal_originalTime": 1689739908000
    },
    {
        "time": 1689739910000,
        "value": 139.344,
        "_internal_originalTime": 1689739910000
    },
    {
        "time": 1689739912000,
        "value": 139.338,
        "_internal_originalTime": 1689739912000
    },
    {
        "time": 1689739913000,
        "value": 139.344,
        "_internal_originalTime": 1689739913000
    },
    {
        "time": 1689739915000,
        "value": 139.342,
        "_internal_originalTime": 1689739915000
    },
    {
        "time": 1689739917000,
        "value": 139.344,
        "_internal_originalTime": 1689739917000
    },
    {
        "time": 1689739918000,
        "value": 139.342,
        "_internal_originalTime": 1689739918000
    },
    {
        "time": 1689739919000,
        "value": 139.339,
        "_internal_originalTime": 1689739919000
    },
    {
        "time": 1689739920000,
        "value": 139.342,
        "_internal_originalTime": 1689739920000
    },
    {
        "time": 1689739921000,
        "value": 139.338,
        "_internal_originalTime": 1689739921000
    },
    {
        "time": 1689739923000,
        "value": 139.342,
        "_internal_originalTime": 1689739923000
    },
    {
        "time": 1689739924000,
        "value": 139.339,
        "_internal_originalTime": 1689739924000
    },
    {
        "time": 1689739926000,
        "value": 139.341,
        "_internal_originalTime": 1689739926000
    },
    {
        "time": 1689739927000,
        "value": 139.342,
        "_internal_originalTime": 1689739927000
    },
    {
        "time": 1689739929000,
        "value": 139.345,
        "_internal_originalTime": 1689739929000
    },
    {
        "time": 1689739930000,
        "value": 139.34,
        "_internal_originalTime": 1689739930000
    },
    {
        "time": 1689739933000,
        "value": 139.343,
        "_internal_originalTime": 1689739933000
    },
    {
        "time": 1689739934000,
        "value": 139.344,
        "_internal_originalTime": 1689739934000
    },
    {
        "time": 1689739939000,
        "value": 139.34,
        "_internal_originalTime": 1689739939000
    },
    {
        "time": 1689739940000,
        "value": 139.346,
        "_internal_originalTime": 1689739940000
    },
    {
        "time": 1689739942000,
        "value": 139.345,
        "_internal_originalTime": 1689739942000
    },
    {
        "time": 1689739944000,
        "value": 139.34,
        "_internal_originalTime": 1689739944000
    },
    {
        "time": 1689739945000,
        "value": 139.34,
        "_internal_originalTime": 1689739945000
    },
    {
        "time": 1689739947000,
        "value": 139.34,
        "_internal_originalTime": 1689739947000
    },
    {
        "time": 1689739949000,
        "value": 139.344,
        "_internal_originalTime": 1689739949000
    },
    {
        "time": 1689739950000,
        "value": 139.34,
        "_internal_originalTime": 1689739950000
    },
    {
        "time": 1689739952000,
        "value": 139.34,
        "_internal_originalTime": 1689739952000
    },
    {
        "time": 1689739953000,
        "value": 139.34,
        "_internal_originalTime": 1689739953000
    },
    {
        "time": 1689739954000,
        "value": 139.344,
        "_internal_originalTime": 1689739954000
    },
    {
        "time": 1689739956000,
        "value": 139.338,
        "_internal_originalTime": 1689739956000
    },
    {
        "time": 1689739958000,
        "value": 139.336,
        "_internal_originalTime": 1689739958000
    },
    {
        "time": 1689739960000,
        "value": 139.332,
        "_internal_originalTime": 1689739960000
    },
    {
        "time": 1689739963000,
        "value": 139.332,
        "_internal_originalTime": 1689739963000
    },
    {
        "time": 1689739964000,
        "value": 139.339,
        "_internal_originalTime": 1689739964000
    },
    {
        "time": 1689739966000,
        "value": 139.339,
        "_internal_originalTime": 1689739966000
    },
    {
        "time": 1689739967000,
        "value": 139.339,
        "_internal_originalTime": 1689739967000
    },
    {
        "time": 1689739969000,
        "value": 139.338,
        "_internal_originalTime": 1689739969000
    },
    {
        "time": 1689739971000,
        "value": 139.338,
        "_internal_originalTime": 1689739971000
    },
    {
        "time": 1689739972000,
        "value": 139.342,
        "_internal_originalTime": 1689739972000
    },
    {
        "time": 1689739974000,
        "value": 139.342,
        "_internal_originalTime": 1689739974000
    },
    {
        "time": 1689739975000,
        "value": 139.338,
        "_internal_originalTime": 1689739975000
    },
    {
        "time": 1689739979000,
        "value": 139.341,
        "_internal_originalTime": 1689739979000
    },
    {
        "time": 1689739980000,
        "value": 139.34,
        "_internal_originalTime": 1689739980000
    },
    {
        "time": 1689739981000,
        "value": 139.342,
        "_internal_originalTime": 1689739981000
    },
    {
        "time": 1689739982000,
        "value": 139.34,
        "_internal_originalTime": 1689739982000
    },
    {
        "time": 1689739984000,
        "value": 139.341,
        "_internal_originalTime": 1689739984000
    },
    {
        "time": 1689739985000,
        "value": 139.346,
        "_internal_originalTime": 1689739985000
    },
    {
        "time": 1689739987000,
        "value": 139.343,
        "_internal_originalTime": 1689739987000
    },
    {
        "time": 1689739991000,
        "value": 139.342,
        "_internal_originalTime": 1689739991000
    },
    {
        "time": 1689739992000,
        "value": 139.345,
        "_internal_originalTime": 1689739992000
    },
    {
        "time": 1689739994000,
        "value": 139.345,
        "_internal_originalTime": 1689739994000
    },
    {
        "time": 1689739995000,
        "value": 139.339,
        "_internal_originalTime": 1689739995000
    },
    {
        "time": 1689739997000,
        "value": 139.344,
        "_internal_originalTime": 1689739997000
    },
    {
        "time": 1689739999000,
        "value": 139.347,
        "_internal_originalTime": 1689739999000
    },
    {
        "time": 1689740001000,
        "value": 139.339,
        "_internal_originalTime": 1689740001000
    },
    {
        "time": 1689740003000,
        "value": 139.341,
        "_internal_originalTime": 1689740003000
    },
    {
        "time": 1689740005000,
        "value": 139.343,
        "_internal_originalTime": 1689740005000
    },
    {
        "time": 1689740007000,
        "value": 139.338,
        "_internal_originalTime": 1689740007000
    },
    {
        "time": 1689740009000,
        "value": 139.344,
        "_internal_originalTime": 1689740009000
    },
    {
        "time": 1689740011000,
        "value": 139.344,
        "_internal_originalTime": 1689740011000
    },
    {
        "time": 1689740013000,
        "value": 139.338,
        "_internal_originalTime": 1689740013000
    },
    {
        "time": 1689740014000,
        "value": 139.336,
        "_internal_originalTime": 1689740014000
    },
    {
        "time": 1689740016000,
        "value": 139.338,
        "_internal_originalTime": 1689740016000
    },
    {
        "time": 1689740018000,
        "value": 139.333,
        "_internal_originalTime": 1689740018000
    },
    {
        "time": 1689740019000,
        "value": 139.332,
        "_internal_originalTime": 1689740019000
    },
    {
        "time": 1689740021000,
        "value": 139.325,
        "_internal_originalTime": 1689740021000
    },
    {
        "time": 1689740022000,
        "value": 139.321,
        "_internal_originalTime": 1689740022000
    },
    {
        "time": 1689740023000,
        "value": 139.324,
        "_internal_originalTime": 1689740023000
    },
    {
        "time": 1689740026000,
        "value": 139.326,
        "_internal_originalTime": 1689740026000
    },
    {
        "time": 1689740028000,
        "value": 139.322,
        "_internal_originalTime": 1689740028000
    },
    {
        "time": 1689740030000,
        "value": 139.323,
        "_internal_originalTime": 1689740030000
    },
    {
        "time": 1689740031000,
        "value": 139.322,
        "_internal_originalTime": 1689740031000
    },
    {
        "time": 1689740032000,
        "value": 139.326,
        "_internal_originalTime": 1689740032000
    },
    {
        "time": 1689740034000,
        "value": 139.325,
        "_internal_originalTime": 1689740034000
    },
    {
        "time": 1689740036000,
        "value": 139.321,
        "_internal_originalTime": 1689740036000
    },
    {
        "time": 1689740039000,
        "value": 139.327,
        "_internal_originalTime": 1689740039000
    },
    {
        "time": 1689740041000,
        "value": 139.331,
        "_internal_originalTime": 1689740041000
    },
    {
        "time": 1689740042000,
        "value": 139.335,
        "_internal_originalTime": 1689740042000
    },
    {
        "time": 1689740043000,
        "value": 139.324,
        "_internal_originalTime": 1689740043000
    },
    {
        "time": 1689740044000,
        "value": 139.319,
        "_internal_originalTime": 1689740044000
    },
    {
        "time": 1689740046000,
        "value": 139.319,
        "_internal_originalTime": 1689740046000
    },
    {
        "time": 1689740047000,
        "value": 139.32,
        "_internal_originalTime": 1689740047000
    },
    {
        "time": 1689740049000,
        "value": 139.318,
        "_internal_originalTime": 1689740049000
    },
    {
        "time": 1689740050000,
        "value": 139.318,
        "_internal_originalTime": 1689740050000
    },
    {
        "time": 1689740053000,
        "value": 139.315,
        "_internal_originalTime": 1689740053000
    },
    {
        "time": 1689740054000,
        "value": 139.317,
        "_internal_originalTime": 1689740054000
    },
    {
        "time": 1689740056000,
        "value": 139.318,
        "_internal_originalTime": 1689740056000
    },
    {
        "time": 1689740059000,
        "value": 139.317,
        "_internal_originalTime": 1689740059000
    },
    {
        "time": 1689740060000,
        "value": 139.319,
        "_internal_originalTime": 1689740060000
    },
    {
        "time": 1689740062000,
        "value": 139.319,
        "_internal_originalTime": 1689740062000
    },
    {
        "time": 1689740064000,
        "value": 139.311,
        "_internal_originalTime": 1689740064000
    },
    {
        "time": 1689740065000,
        "value": 139.317,
        "_internal_originalTime": 1689740065000
    },
    {
        "time": 1689740067000,
        "value": 139.32,
        "_internal_originalTime": 1689740067000
    },
    {
        "time": 1689740068000,
        "value": 139.316,
        "_internal_originalTime": 1689740068000
    },
    {
        "time": 1689740071000,
        "value": 139.319,
        "_internal_originalTime": 1689740071000
    },
    {
        "time": 1689740072000,
        "value": 139.319,
        "_internal_originalTime": 1689740072000
    },
    {
        "time": 1689740074000,
        "value": 139.323,
        "_internal_originalTime": 1689740074000
    },
    {
        "time": 1689740076000,
        "value": 139.324,
        "_internal_originalTime": 1689740076000
    },
    {
        "time": 1689740078000,
        "value": 139.318,
        "_internal_originalTime": 1689740078000
    },
    {
        "time": 1689740079000,
        "value": 139.322,
        "_internal_originalTime": 1689740079000
    },
    {
        "time": 1689740081000,
        "value": 139.322,
        "_internal_originalTime": 1689740081000
    },
    {
        "time": 1689740082000,
        "value": 139.321,
        "_internal_originalTime": 1689740082000
    },
    {
        "time": 1689740084000,
        "value": 139.325,
        "_internal_originalTime": 1689740084000
    },
    {
        "time": 1689740085000,
        "value": 139.335,
        "_internal_originalTime": 1689740085000
    },
    {
        "time": 1689740087000,
        "value": 139.324,
        "_internal_originalTime": 1689740087000
    },
    {
        "time": 1689740088000,
        "value": 139.323,
        "_internal_originalTime": 1689740088000
    },
    {
        "time": 1689740090000,
        "value": 139.322,
        "_internal_originalTime": 1689740090000
    },
    {
        "time": 1689740092000,
        "value": 139.328,
        "_internal_originalTime": 1689740092000
    },
    {
        "time": 1689740094000,
        "value": 139.323,
        "_internal_originalTime": 1689740094000
    },
    {
        "time": 1689740095000,
        "value": 139.326,
        "_internal_originalTime": 1689740095000
    },
    {
        "time": 1689740097000,
        "value": 139.333,
        "_internal_originalTime": 1689740097000
    },
    {
        "time": 1689740098000,
        "value": 139.331,
        "_internal_originalTime": 1689740098000
    },
    {
        "time": 1689740101000,
        "value": 139.334,
        "_internal_originalTime": 1689740101000
    },
    {
        "time": 1689740103000,
        "value": 139.335,
        "_internal_originalTime": 1689740103000
    },
    {
        "time": 1689740105000,
        "value": 139.341,
        "_internal_originalTime": 1689740105000
    },
    {
        "time": 1689740106000,
        "value": 139.341,
        "_internal_originalTime": 1689740106000
    },
    {
        "time": 1689740108000,
        "value": 139.343,
        "_internal_originalTime": 1689740108000
    },
    {
        "time": 1689740110000,
        "value": 139.344,
        "_internal_originalTime": 1689740110000
    },
    {
        "time": 1689740111000,
        "value": 139.346,
        "_internal_originalTime": 1689740111000
    },
    {
        "time": 1689740113000,
        "value": 139.345,
        "_internal_originalTime": 1689740113000
    },
    {
        "time": 1689740114000,
        "value": 139.346,
        "_internal_originalTime": 1689740114000
    },
    {
        "time": 1689740116000,
        "value": 139.342,
        "_internal_originalTime": 1689740116000
    },
    {
        "time": 1689740118000,
        "value": 139.347,
        "_internal_originalTime": 1689740118000
    },
    {
        "time": 1689740120000,
        "value": 139.346,
        "_internal_originalTime": 1689740120000
    },
    {
        "time": 1689740121000,
        "value": 139.34,
        "_internal_originalTime": 1689740121000
    },
    {
        "time": 1689740123000,
        "value": 139.341,
        "_internal_originalTime": 1689740123000
    },
    {
        "time": 1689740124000,
        "value": 139.34,
        "_internal_originalTime": 1689740124000
    },
    {
        "time": 1689740126000,
        "value": 139.347,
        "_internal_originalTime": 1689740126000
    },
    {
        "time": 1689740128000,
        "value": 139.347,
        "_internal_originalTime": 1689740128000
    },
    {
        "time": 1689740130000,
        "value": 139.354,
        "_internal_originalTime": 1689740130000
    },
    {
        "time": 1689740132000,
        "value": 139.354,
        "_internal_originalTime": 1689740132000
    },
    {
        "time": 1689740134000,
        "value": 139.353,
        "_internal_originalTime": 1689740134000
    },
    {
        "time": 1689740135000,
        "value": 139.35,
        "_internal_originalTime": 1689740135000
    },
    {
        "time": 1689740137000,
        "value": 139.35,
        "_internal_originalTime": 1689740137000
    },
    {
        "time": 1689740138000,
        "value": 139.353,
        "_internal_originalTime": 1689740138000
    },
    {
        "time": 1689740141000,
        "value": 139.35,
        "_internal_originalTime": 1689740141000
    },
    {
        "time": 1689740142000,
        "value": 139.351,
        "_internal_originalTime": 1689740142000
    },
    {
        "time": 1689740145000,
        "value": 139.353,
        "_internal_originalTime": 1689740145000
    },
    {
        "time": 1689740147000,
        "value": 139.349,
        "_internal_originalTime": 1689740147000
    },
    {
        "time": 1689740148000,
        "value": 139.351,
        "_internal_originalTime": 1689740148000
    },
    {
        "time": 1689740149000,
        "value": 139.349,
        "_internal_originalTime": 1689740149000
    },
    {
        "time": 1689740152000,
        "value": 139.346,
        "_internal_originalTime": 1689740152000
    },
    {
        "time": 1689740153000,
        "value": 139.351,
        "_internal_originalTime": 1689740153000
    },
    {
        "time": 1689740155000,
        "value": 139.349,
        "_internal_originalTime": 1689740155000
    },
    {
        "time": 1689740157000,
        "value": 139.353,
        "_internal_originalTime": 1689740157000
    },
    {
        "time": 1689740159000,
        "value": 139.351,
        "_internal_originalTime": 1689740159000
    },
    {
        "time": 1689740161000,
        "value": 139.353,
        "_internal_originalTime": 1689740161000
    },
    {
        "time": 1689740162000,
        "value": 139.349,
        "_internal_originalTime": 1689740162000
    },
    {
        "time": 1689740164000,
        "value": 139.349,
        "_internal_originalTime": 1689740164000
    },
    {
        "time": 1689740165000,
        "value": 139.353,
        "_internal_originalTime": 1689740165000
    },
    {
        "time": 1689740167000,
        "value": 139.347,
        "_internal_originalTime": 1689740167000
    },
    {
        "time": 1689740168000,
        "value": 139.352,
        "_internal_originalTime": 1689740168000
    },
    {
        "time": 1689740170000,
        "value": 139.353,
        "_internal_originalTime": 1689740170000
    },
    {
        "time": 1689740171000,
        "value": 139.352,
        "_internal_originalTime": 1689740171000
    },
    {
        "time": 1689740174000,
        "value": 139.354,
        "_internal_originalTime": 1689740174000
    },
    {
        "time": 1689740175000,
        "value": 139.354,
        "_internal_originalTime": 1689740175000
    },
    {
        "time": 1689740176000,
        "value": 139.35,
        "_internal_originalTime": 1689740176000
    },
    {
        "time": 1689740180000,
        "value": 139.353,
        "_internal_originalTime": 1689740180000
    },
    {
        "time": 1689740182000,
        "value": 139.353,
        "_internal_originalTime": 1689740182000
    },
    {
        "time": 1689740183000,
        "value": 139.35,
        "_internal_originalTime": 1689740183000
    },
    {
        "time": 1689740185000,
        "value": 139.347,
        "_internal_originalTime": 1689740185000
    },
    {
        "time": 1689740187000,
        "value": 139.359,
        "_internal_originalTime": 1689740187000
    },
    {
        "time": 1689740188000,
        "value": 139.362,
        "_internal_originalTime": 1689740188000
    },
    {
        "time": 1689740189000,
        "value": 139.363,
        "_internal_originalTime": 1689740189000
    },
    {
        "time": 1689740190000,
        "value": 139.358,
        "_internal_originalTime": 1689740190000
    },
    {
        "time": 1689740193000,
        "value": 139.364,
        "_internal_originalTime": 1689740193000
    },
    {
        "time": 1689740194000,
        "value": 139.364,
        "_internal_originalTime": 1689740194000
    },
    {
        "time": 1689740196000,
        "value": 139.361,
        "_internal_originalTime": 1689740196000
    },
    {
        "time": 1689740197000,
        "value": 139.364,
        "_internal_originalTime": 1689740197000
    },
    {
        "time": 1689740199000,
        "value": 139.36,
        "_internal_originalTime": 1689740199000
    },
    {
        "time": 1689740201000,
        "value": 139.364,
        "_internal_originalTime": 1689740201000
    },
    {
        "time": 1689740202000,
        "value": 139.361,
        "_internal_originalTime": 1689740202000
    },
    {
        "time": 1689740204000,
        "value": 139.364,
        "_internal_originalTime": 1689740204000
    },
    {
        "time": 1689740205000,
        "value": 139.365,
        "_internal_originalTime": 1689740205000
    },
    {
        "time": 1689740208000,
        "value": 139.35,
        "_internal_originalTime": 1689740208000
    },
    {
        "time": 1689740210000,
        "value": 139.348,
        "_internal_originalTime": 1689740210000
    },
    {
        "time": 1689740212000,
        "value": 139.348,
        "_internal_originalTime": 1689740212000
    },
    {
        "time": 1689740213000,
        "value": 139.351,
        "_internal_originalTime": 1689740213000
    },
    {
        "time": 1689740215000,
        "value": 139.346,
        "_internal_originalTime": 1689740215000
    },
    {
        "time": 1689740217000,
        "value": 139.344,
        "_internal_originalTime": 1689740217000
    },
    {
        "time": 1689740219000,
        "value": 139.344,
        "_internal_originalTime": 1689740219000
    },
    {
        "time": 1689740220000,
        "value": 139.341,
        "_internal_originalTime": 1689740220000
    },
    {
        "time": 1689740221000,
        "value": 139.345,
        "_internal_originalTime": 1689740221000
    },
    {
        "time": 1689740223000,
        "value": 139.35,
        "_internal_originalTime": 1689740223000
    },
    {
        "time": 1689740224000,
        "value": 139.351,
        "_internal_originalTime": 1689740224000
    },
    {
        "time": 1689740225000,
        "value": 139.353,
        "_internal_originalTime": 1689740225000
    },
    {
        "time": 1689740227000,
        "value": 139.35,
        "_internal_originalTime": 1689740227000
    },
    {
        "time": 1689740229000,
        "value": 139.351,
        "_internal_originalTime": 1689740229000
    },
    {
        "time": 1689740231000,
        "value": 139.349,
        "_internal_originalTime": 1689740231000
    },
    {
        "time": 1689740235000,
        "value": 139.351,
        "_internal_originalTime": 1689740235000
    },
    {
        "time": 1689740236000,
        "value": 139.359,
        "_internal_originalTime": 1689740236000
    },
    {
        "time": 1689740237000,
        "value": 139.362,
        "_internal_originalTime": 1689740237000
    },
    {
        "time": 1689740239000,
        "value": 139.357,
        "_internal_originalTime": 1689740239000
    },
    {
        "time": 1689740241000,
        "value": 139.362,
        "_internal_originalTime": 1689740241000
    },
    {
        "time": 1689740244000,
        "value": 139.351,
        "_internal_originalTime": 1689740244000
    },
    {
        "time": 1689740245000,
        "value": 139.355,
        "_internal_originalTime": 1689740245000
    },
    {
        "time": 1689740247000,
        "value": 139.353,
        "_internal_originalTime": 1689740247000
    },
    {
        "time": 1689740248000,
        "value": 139.348,
        "_internal_originalTime": 1689740248000
    },
    {
        "time": 1689740249000,
        "value": 139.354,
        "_internal_originalTime": 1689740249000
    },
    {
        "time": 1689740251000,
        "value": 139.353,
        "_internal_originalTime": 1689740251000
    },
    {
        "time": 1689740257000,
        "value": 139.353,
        "_internal_originalTime": 1689740257000
    },
    {
        "time": 1689740260000,
        "value": 139.348,
        "_internal_originalTime": 1689740260000
    },
    {
        "time": 1689740266000,
        "value": 139.354,
        "_internal_originalTime": 1689740266000
    },
    {
        "time": 1689740271000,
        "value": 139.356,
        "_internal_originalTime": 1689740271000
    },
    {
        "time": 1689740273000,
        "value": 139.352,
        "_internal_originalTime": 1689740273000
    },
    {
        "time": 1689740274000,
        "value": 139.355,
        "_internal_originalTime": 1689740274000
    },
    {
        "time": 1689740276000,
        "value": 139.355,
        "_internal_originalTime": 1689740276000
    },
    {
        "time": 1689740278000,
        "value": 139.357,
        "_internal_originalTime": 1689740278000
    },
    {
        "time": 1689740280000,
        "value": 139.348,
        "_internal_originalTime": 1689740280000
    },
    {
        "time": 1689740281000,
        "value": 139.342,
        "_internal_originalTime": 1689740281000
    },
    {
        "time": 1689740282000,
        "value": 139.34,
        "_internal_originalTime": 1689740282000
    },
    {
        "time": 1689740284000,
        "value": 139.332,
        "_internal_originalTime": 1689740284000
    },
    {
        "time": 1689740285000,
        "value": 139.352,
        "_internal_originalTime": 1689740285000
    },
    {
        "time": 1689740287000,
        "value": 139.356,
        "_internal_originalTime": 1689740287000
    },
    {
        "time": 1689740288000,
        "value": 139.356,
        "_internal_originalTime": 1689740288000
    },
    {
        "time": 1689740290000,
        "value": 139.353,
        "_internal_originalTime": 1689740290000
    },
    {
        "time": 1689740292000,
        "value": 139.352,
        "_internal_originalTime": 1689740292000
    },
    {
        "time": 1689740293000,
        "value": 139.352,
        "_internal_originalTime": 1689740293000
    },
    {
        "time": 1689740295000,
        "value": 139.351,
        "_internal_originalTime": 1689740295000
    },
    {
        "time": 1689740296000,
        "value": 139.35,
        "_internal_originalTime": 1689740296000
    },
    {
        "time": 1689740297000,
        "value": 139.355,
        "_internal_originalTime": 1689740297000
    },
    {
        "time": 1689740301000,
        "value": 139.351,
        "_internal_originalTime": 1689740301000
    },
    {
        "time": 1689740302000,
        "value": 139.353,
        "_internal_originalTime": 1689740302000
    },
    {
        "time": 1689740304000,
        "value": 139.35,
        "_internal_originalTime": 1689740304000
    },
    {
        "time": 1689740305000,
        "value": 139.353,
        "_internal_originalTime": 1689740305000
    },
    {
        "time": 1689740307000,
        "value": 139.349,
        "_internal_originalTime": 1689740307000
    },
    {
        "time": 1689740309000,
        "value": 139.348,
        "_internal_originalTime": 1689740309000
    },
    {
        "time": 1689740311000,
        "value": 139.35,
        "_internal_originalTime": 1689740311000
    },
    {
        "time": 1689740312000,
        "value": 139.347,
        "_internal_originalTime": 1689740312000
    },
    {
        "time": 1689740314000,
        "value": 139.353,
        "_internal_originalTime": 1689740314000
    },
    {
        "time": 1689740315000,
        "value": 139.353,
        "_internal_originalTime": 1689740315000
    },
    {
        "time": 1689740318000,
        "value": 139.351,
        "_internal_originalTime": 1689740318000
    },
    {
        "time": 1689740319000,
        "value": 139.353,
        "_internal_originalTime": 1689740319000
    },
    {
        "time": 1689740321000,
        "value": 139.35,
        "_internal_originalTime": 1689740321000
    },
    {
        "time": 1689740322000,
        "value": 139.353,
        "_internal_originalTime": 1689740322000
    },
    {
        "time": 1689740323000,
        "value": 139.353,
        "_internal_originalTime": 1689740323000
    },
    {
        "time": 1689740325000,
        "value": 139.349,
        "_internal_originalTime": 1689740325000
    },
    {
        "time": 1689740326000,
        "value": 139.349,
        "_internal_originalTime": 1689740326000
    },
    {
        "time": 1689740328000,
        "value": 139.353,
        "_internal_originalTime": 1689740328000
    },
    {
        "time": 1689740330000,
        "value": 139.353,
        "_internal_originalTime": 1689740330000
    },
    {
        "time": 1689740332000,
        "value": 139.357,
        "_internal_originalTime": 1689740332000
    },
    {
        "time": 1689740333000,
        "value": 139.359,
        "_internal_originalTime": 1689740333000
    },
    {
        "time": 1689740335000,
        "value": 139.37,
        "_internal_originalTime": 1689740335000
    },
    {
        "time": 1689740337000,
        "value": 139.364,
        "_internal_originalTime": 1689740337000
    },
    {
        "time": 1689740339000,
        "value": 139.367,
        "_internal_originalTime": 1689740339000
    },
    {
        "time": 1689740341000,
        "value": 139.371,
        "_internal_originalTime": 1689740341000
    },
    {
        "time": 1689740343000,
        "value": 139.371,
        "_internal_originalTime": 1689740343000
    },
    {
        "time": 1689740345000,
        "value": 139.371,
        "_internal_originalTime": 1689740345000
    },
    {
        "time": 1689740348000,
        "value": 139.372,
        "_internal_originalTime": 1689740348000
    },
    {
        "time": 1689740350000,
        "value": 139.369,
        "_internal_originalTime": 1689740350000
    },
    {
        "time": 1689740352000,
        "value": 139.372,
        "_internal_originalTime": 1689740352000
    },
    {
        "time": 1689740353000,
        "value": 139.371,
        "_internal_originalTime": 1689740353000
    },
    {
        "time": 1689740355000,
        "value": 139.365,
        "_internal_originalTime": 1689740355000
    },
    {
        "time": 1689740356000,
        "value": 139.366,
        "_internal_originalTime": 1689740356000
    },
    {
        "time": 1689740358000,
        "value": 139.36,
        "_internal_originalTime": 1689740358000
    },
    {
        "time": 1689740360000,
        "value": 139.362,
        "_internal_originalTime": 1689740360000
    },
    {
        "time": 1689740362000,
        "value": 139.362,
        "_internal_originalTime": 1689740362000
    },
    {
        "time": 1689740363000,
        "value": 139.36,
        "_internal_originalTime": 1689740363000
    },
    {
        "time": 1689740364000,
        "value": 139.363,
        "_internal_originalTime": 1689740364000
    },
    {
        "time": 1689740366000,
        "value": 139.359,
        "_internal_originalTime": 1689740366000
    },
    {
        "time": 1689740368000,
        "value": 139.356,
        "_internal_originalTime": 1689740368000
    },
    {
        "time": 1689740369000,
        "value": 139.363,
        "_internal_originalTime": 1689740369000
    },
    {
        "time": 1689740371000,
        "value": 139.36,
        "_internal_originalTime": 1689740371000
    },
    {
        "time": 1689740373000,
        "value": 139.36,
        "_internal_originalTime": 1689740373000
    },
    {
        "time": 1689740376000,
        "value": 139.363,
        "_internal_originalTime": 1689740376000
    },
    {
        "time": 1689740377000,
        "value": 139.363,
        "_internal_originalTime": 1689740377000
    },
    {
        "time": 1689740379000,
        "value": 139.361,
        "_internal_originalTime": 1689740379000
    },
    {
        "time": 1689740383000,
        "value": 139.363,
        "_internal_originalTime": 1689740383000
    },
    {
        "time": 1689740385000,
        "value": 139.361,
        "_internal_originalTime": 1689740385000
    },
    {
        "time": 1689740387000,
        "value": 139.361,
        "_internal_originalTime": 1689740387000
    },
    {
        "time": 1689740388000,
        "value": 139.36,
        "_internal_originalTime": 1689740388000
    },
    {
        "time": 1689740391000,
        "value": 139.361,
        "_internal_originalTime": 1689740391000
    },
    {
        "time": 1689740393000,
        "value": 139.358,
        "_internal_originalTime": 1689740393000
    },
    {
        "time": 1689740395000,
        "value": 139.358,
        "_internal_originalTime": 1689740395000
    },
    {
        "time": 1689740397000,
        "value": 139.358,
        "_internal_originalTime": 1689740397000
    },
    {
        "time": 1689740398000,
        "value": 139.363,
        "_internal_originalTime": 1689740398000
    },
    {
        "time": 1689740401000,
        "value": 139.361,
        "_internal_originalTime": 1689740401000
    },
    {
        "time": 1689740402000,
        "value": 139.359,
        "_internal_originalTime": 1689740402000
    },
    {
        "time": 1689740403000,
        "value": 139.368,
        "_internal_originalTime": 1689740403000
    },
    {
        "time": 1689740406000,
        "value": 139.379,
        "_internal_originalTime": 1689740406000
    },
    {
        "time": 1689740407000,
        "value": 139.379,
        "_internal_originalTime": 1689740407000
    },
    {
        "time": 1689740409000,
        "value": 139.387,
        "_internal_originalTime": 1689740409000
    },
    {
        "time": 1689740410000,
        "value": 139.391,
        "_internal_originalTime": 1689740410000
    },
    {
        "time": 1689740415000,
        "value": 139.392,
        "_internal_originalTime": 1689740415000
    },
    {
        "time": 1689740416000,
        "value": 139.395,
        "_internal_originalTime": 1689740416000
    },
    {
        "time": 1689740417000,
        "value": 139.395,
        "_internal_originalTime": 1689740417000
    },
    {
        "time": 1689740419000,
        "value": 139.396,
        "_internal_originalTime": 1689740419000
    },
    {
        "time": 1689740421000,
        "value": 139.391,
        "_internal_originalTime": 1689740421000
    },
    {
        "time": 1689740423000,
        "value": 139.393,
        "_internal_originalTime": 1689740423000
    },
    {
        "time": 1689740424000,
        "value": 139.396,
        "_internal_originalTime": 1689740424000
    },
    {
        "time": 1689740426000,
        "value": 139.394,
        "_internal_originalTime": 1689740426000
    },
    {
        "time": 1689740427000,
        "value": 139.389,
        "_internal_originalTime": 1689740427000
    },
    {
        "time": 1689740429000,
        "value": 139.394,
        "_internal_originalTime": 1689740429000
    },
    {
        "time": 1689740430000,
        "value": 139.396,
        "_internal_originalTime": 1689740430000
    },
    {
        "time": 1689740432000,
        "value": 139.396,
        "_internal_originalTime": 1689740432000
    },
    {
        "time": 1689740433000,
        "value": 139.396,
        "_internal_originalTime": 1689740433000
    },
    {
        "time": 1689740435000,
        "value": 139.391,
        "_internal_originalTime": 1689740435000
    },
    {
        "time": 1689740436000,
        "value": 139.386,
        "_internal_originalTime": 1689740436000
    },
    {
        "time": 1689740438000,
        "value": 139.393,
        "_internal_originalTime": 1689740438000
    },
    {
        "time": 1689740439000,
        "value": 139.39,
        "_internal_originalTime": 1689740439000
    },
    {
        "time": 1689740441000,
        "value": 139.393,
        "_internal_originalTime": 1689740441000
    },
    {
        "time": 1689740443000,
        "value": 139.394,
        "_internal_originalTime": 1689740443000
    },
    {
        "time": 1689740444000,
        "value": 139.394,
        "_internal_originalTime": 1689740444000
    },
    {
        "time": 1689740445000,
        "value": 139.394,
        "_internal_originalTime": 1689740445000
    },
    {
        "time": 1689740449000,
        "value": 139.394,
        "_internal_originalTime": 1689740449000
    },
    {
        "time": 1689740450000,
        "value": 139.394,
        "_internal_originalTime": 1689740450000
    },
    {
        "time": 1689740454000,
        "value": 139.391,
        "_internal_originalTime": 1689740454000
    },
    {
        "time": 1689740457000,
        "value": 139.387,
        "_internal_originalTime": 1689740457000
    },
    {
        "time": 1689740458000,
        "value": 139.389,
        "_internal_originalTime": 1689740458000
    },
    {
        "time": 1689740459000,
        "value": 139.394,
        "_internal_originalTime": 1689740459000
    },
    {
        "time": 1689740460000,
        "value": 139.391,
        "_internal_originalTime": 1689740460000
    },
    {
        "time": 1689740461000,
        "value": 139.39,
        "_internal_originalTime": 1689740461000
    },
    {
        "time": 1689740463000,
        "value": 139.393,
        "_internal_originalTime": 1689740463000
    },
    {
        "time": 1689740465000,
        "value": 139.389,
        "_internal_originalTime": 1689740465000
    },
    {
        "time": 1689740467000,
        "value": 139.392,
        "_internal_originalTime": 1689740467000
    },
    {
        "time": 1689740468000,
        "value": 139.393,
        "_internal_originalTime": 1689740468000
    },
    {
        "time": 1689740471000,
        "value": 139.39,
        "_internal_originalTime": 1689740471000
    },
    {
        "time": 1689740472000,
        "value": 139.389,
        "_internal_originalTime": 1689740472000
    },
    {
        "time": 1689740473000,
        "value": 139.393,
        "_internal_originalTime": 1689740473000
    },
    {
        "time": 1689740478000,
        "value": 139.394,
        "_internal_originalTime": 1689740478000
    },
    {
        "time": 1689740480000,
        "value": 139.39,
        "_internal_originalTime": 1689740480000
    },
    {
        "time": 1689740482000,
        "value": 139.391,
        "_internal_originalTime": 1689740482000
    },
    {
        "time": 1689740484000,
        "value": 139.394,
        "_internal_originalTime": 1689740484000
    },
    {
        "time": 1689740486000,
        "value": 139.392,
        "_internal_originalTime": 1689740486000
    },
    {
        "time": 1689740488000,
        "value": 139.388,
        "_internal_originalTime": 1689740488000
    },
    {
        "time": 1689740490000,
        "value": 139.393,
        "_internal_originalTime": 1689740490000
    },
    {
        "time": 1689740491000,
        "value": 139.393,
        "_internal_originalTime": 1689740491000
    },
    {
        "time": 1689740492000,
        "value": 139.392,
        "_internal_originalTime": 1689740492000
    },
    {
        "time": 1689740494000,
        "value": 139.386,
        "_internal_originalTime": 1689740494000
    },
    {
        "time": 1689740495000,
        "value": 139.391,
        "_internal_originalTime": 1689740495000
    },
    {
        "time": 1689740497000,
        "value": 139.388,
        "_internal_originalTime": 1689740497000
    },
    {
        "time": 1689740499000,
        "value": 139.383,
        "_internal_originalTime": 1689740499000
    },
    {
        "time": 1689740500000,
        "value": 139.38,
        "_internal_originalTime": 1689740500000
    },
    {
        "time": 1689740504000,
        "value": 139.383,
        "_internal_originalTime": 1689740504000
    },
    {
        "time": 1689740505000,
        "value": 139.378,
        "_internal_originalTime": 1689740505000
    },
    {
        "time": 1689740506000,
        "value": 139.384,
        "_internal_originalTime": 1689740506000
    },
    {
        "time": 1689740508000,
        "value": 139.378,
        "_internal_originalTime": 1689740508000
    },
    {
        "time": 1689740510000,
        "value": 139.381,
        "_internal_originalTime": 1689740510000
    },
    {
        "time": 1689740511000,
        "value": 139.381,
        "_internal_originalTime": 1689740511000
    },
    {
        "time": 1689740515000,
        "value": 139.388,
        "_internal_originalTime": 1689740515000
    },
    {
        "time": 1689740516000,
        "value": 139.389,
        "_internal_originalTime": 1689740516000
    },
    {
        "time": 1689740518000,
        "value": 139.393,
        "_internal_originalTime": 1689740518000
    },
    {
        "time": 1689740519000,
        "value": 139.393,
        "_internal_originalTime": 1689740519000
    },
    {
        "time": 1689740521000,
        "value": 139.386,
        "_internal_originalTime": 1689740521000
    },
    {
        "time": 1689740522000,
        "value": 139.389,
        "_internal_originalTime": 1689740522000
    },
    {
        "time": 1689740524000,
        "value": 139.39,
        "_internal_originalTime": 1689740524000
    },
    {
        "time": 1689740526000,
        "value": 139.383,
        "_internal_originalTime": 1689740526000
    },
    {
        "time": 1689740527000,
        "value": 139.387,
        "_internal_originalTime": 1689740527000
    },
    {
        "time": 1689740529000,
        "value": 139.386,
        "_internal_originalTime": 1689740529000
    },
    {
        "time": 1689740530000,
        "value": 139.385,
        "_internal_originalTime": 1689740530000
    },
    {
        "time": 1689740532000,
        "value": 139.374,
        "_internal_originalTime": 1689740532000
    },
    {
        "time": 1689740535000,
        "value": 139.37,
        "_internal_originalTime": 1689740535000
    },
    {
        "time": 1689740536000,
        "value": 139.37,
        "_internal_originalTime": 1689740536000
    },
    {
        "time": 1689740537000,
        "value": 139.376,
        "_internal_originalTime": 1689740537000
    },
    {
        "time": 1689740539000,
        "value": 139.357,
        "_internal_originalTime": 1689740539000
    },
    {
        "time": 1689740541000,
        "value": 139.354,
        "_internal_originalTime": 1689740541000
    },
    {
        "time": 1689740542000,
        "value": 139.362,
        "_internal_originalTime": 1689740542000
    },
    {
        "time": 1689740543000,
        "value": 139.363,
        "_internal_originalTime": 1689740543000
    },
    {
        "time": 1689740544000,
        "value": 139.356,
        "_internal_originalTime": 1689740544000
    },
    {
        "time": 1689740547000,
        "value": 139.358,
        "_internal_originalTime": 1689740547000
    },
    {
        "time": 1689740549000,
        "value": 139.358,
        "_internal_originalTime": 1689740549000
    },
    {
        "time": 1689740550000,
        "value": 139.356,
        "_internal_originalTime": 1689740550000
    },
    {
        "time": 1689740551000,
        "value": 139.363,
        "_internal_originalTime": 1689740551000
    },
    {
        "time": 1689740554000,
        "value": 139.358,
        "_internal_originalTime": 1689740554000
    },
    {
        "time": 1689740555000,
        "value": 139.356,
        "_internal_originalTime": 1689740555000
    },
    {
        "time": 1689740557000,
        "value": 139.364,
        "_internal_originalTime": 1689740557000
    },
    {
        "time": 1689740559000,
        "value": 139.371,
        "_internal_originalTime": 1689740559000
    },
    {
        "time": 1689740560000,
        "value": 139.375,
        "_internal_originalTime": 1689740560000
    },
    {
        "time": 1689740562000,
        "value": 139.375,
        "_internal_originalTime": 1689740562000
    },
    {
        "time": 1689740564000,
        "value": 139.379,
        "_internal_originalTime": 1689740564000
    },
    {
        "time": 1689740565000,
        "value": 139.364,
        "_internal_originalTime": 1689740565000
    },
    {
        "time": 1689740566000,
        "value": 139.37,
        "_internal_originalTime": 1689740566000
    },
    {
        "time": 1689740568000,
        "value": 139.357,
        "_internal_originalTime": 1689740568000
    },
    {
        "time": 1689740569000,
        "value": 139.361,
        "_internal_originalTime": 1689740569000
    },
    {
        "time": 1689740571000,
        "value": 139.356,
        "_internal_originalTime": 1689740571000
    },
    {
        "time": 1689740573000,
        "value": 139.362,
        "_internal_originalTime": 1689740573000
    },
    {
        "time": 1689740574000,
        "value": 139.362,
        "_internal_originalTime": 1689740574000
    },
    {
        "time": 1689740576000,
        "value": 139.362,
        "_internal_originalTime": 1689740576000
    },
    {
        "time": 1689740577000,
        "value": 139.358,
        "_internal_originalTime": 1689740577000
    },
    {
        "time": 1689740579000,
        "value": 139.362,
        "_internal_originalTime": 1689740579000
    },
    {
        "time": 1689740581000,
        "value": 139.357,
        "_internal_originalTime": 1689740581000
    },
    {
        "time": 1689740582000,
        "value": 139.362,
        "_internal_originalTime": 1689740582000
    },
    {
        "time": 1689740583000,
        "value": 139.362,
        "_internal_originalTime": 1689740583000
    },
    {
        "time": 1689740584000,
        "value": 139.362,
        "_internal_originalTime": 1689740584000
    },
    {
        "time": 1689740586000,
        "value": 139.357,
        "_internal_originalTime": 1689740586000
    },
    {
        "time": 1689740588000,
        "value": 139.362,
        "_internal_originalTime": 1689740588000
    },
    {
        "time": 1689740590000,
        "value": 139.358,
        "_internal_originalTime": 1689740590000
    },
    {
        "time": 1689740591000,
        "value": 139.357,
        "_internal_originalTime": 1689740591000
    },
    {
        "time": 1689740593000,
        "value": 139.36,
        "_internal_originalTime": 1689740593000
    },
    {
        "time": 1689740595000,
        "value": 139.359,
        "_internal_originalTime": 1689740595000
    },
    {
        "time": 1689740596000,
        "value": 139.356,
        "_internal_originalTime": 1689740596000
    },
    {
        "time": 1689740598000,
        "value": 139.348,
        "_internal_originalTime": 1689740598000
    },
    {
        "time": 1689740600000,
        "value": 139.351,
        "_internal_originalTime": 1689740600000
    },
    {
        "time": 1689740602000,
        "value": 139.349,
        "_internal_originalTime": 1689740602000
    },
    {
        "time": 1689740604000,
        "value": 139.35,
        "_internal_originalTime": 1689740604000
    },
    {
        "time": 1689740605000,
        "value": 139.349,
        "_internal_originalTime": 1689740605000
    },
    {
        "time": 1689740607000,
        "value": 139.344,
        "_internal_originalTime": 1689740607000
    },
    {
        "time": 1689740609000,
        "value": 139.345,
        "_internal_originalTime": 1689740609000
    },
    {
        "time": 1689740611000,
        "value": 139.347,
        "_internal_originalTime": 1689740611000
    },
    {
        "time": 1689740612000,
        "value": 139.352,
        "_internal_originalTime": 1689740612000
    },
    {
        "time": 1689740613000,
        "value": 139.352,
        "_internal_originalTime": 1689740613000
    },
    {
        "time": 1689740616000,
        "value": 139.349,
        "_internal_originalTime": 1689740616000
    },
    {
        "time": 1689740618000,
        "value": 139.348,
        "_internal_originalTime": 1689740618000
    },
    {
        "time": 1689740619000,
        "value": 139.348,
        "_internal_originalTime": 1689740619000
    },
    {
        "time": 1689740621000,
        "value": 139.348,
        "_internal_originalTime": 1689740621000
    },
    {
        "time": 1689740622000,
        "value": 139.353,
        "_internal_originalTime": 1689740622000
    },
    {
        "time": 1689740624000,
        "value": 139.36,
        "_internal_originalTime": 1689740624000
    },
    {
        "time": 1689740625000,
        "value": 139.355,
        "_internal_originalTime": 1689740625000
    },
    {
        "time": 1689740628000,
        "value": 139.36,
        "_internal_originalTime": 1689740628000
    },
    {
        "time": 1689740630000,
        "value": 139.357,
        "_internal_originalTime": 1689740630000
    },
    {
        "time": 1689740631000,
        "value": 139.357,
        "_internal_originalTime": 1689740631000
    },
    {
        "time": 1689740633000,
        "value": 139.353,
        "_internal_originalTime": 1689740633000
    },
    {
        "time": 1689740635000,
        "value": 139.352,
        "_internal_originalTime": 1689740635000
    },
    {
        "time": 1689740637000,
        "value": 139.349,
        "_internal_originalTime": 1689740637000
    },
    {
        "time": 1689740638000,
        "value": 139.353,
        "_internal_originalTime": 1689740638000
    },
    {
        "time": 1689740639000,
        "value": 139.353,
        "_internal_originalTime": 1689740639000
    },
    {
        "time": 1689740641000,
        "value": 139.348,
        "_internal_originalTime": 1689740641000
    },
    {
        "time": 1689740642000,
        "value": 139.349,
        "_internal_originalTime": 1689740642000
    },
    {
        "time": 1689740643000,
        "value": 139.348,
        "_internal_originalTime": 1689740643000
    },
    {
        "time": 1689740644000,
        "value": 139.353,
        "_internal_originalTime": 1689740644000
    },
    {
        "time": 1689740646000,
        "value": 139.353,
        "_internal_originalTime": 1689740646000
    },
    {
        "time": 1689740648000,
        "value": 139.35,
        "_internal_originalTime": 1689740648000
    },
    {
        "time": 1689740651000,
        "value": 139.348,
        "_internal_originalTime": 1689740651000
    },
    {
        "time": 1689740654000,
        "value": 139.346,
        "_internal_originalTime": 1689740654000
    },
    {
        "time": 1689740658000,
        "value": 139.351,
        "_internal_originalTime": 1689740658000
    },
    {
        "time": 1689740659000,
        "value": 139.346,
        "_internal_originalTime": 1689740659000
    },
    {
        "time": 1689740662000,
        "value": 139.352,
        "_internal_originalTime": 1689740662000
    },
    {
        "time": 1689740663000,
        "value": 139.352,
        "_internal_originalTime": 1689740663000
    },
    {
        "time": 1689740665000,
        "value": 139.346,
        "_internal_originalTime": 1689740665000
    },
    {
        "time": 1689740668000,
        "value": 139.352,
        "_internal_originalTime": 1689740668000
    },
    {
        "time": 1689740669000,
        "value": 139.352,
        "_internal_originalTime": 1689740669000
    },
    {
        "time": 1689740671000,
        "value": 139.352,
        "_internal_originalTime": 1689740671000
    },
    {
        "time": 1689740672000,
        "value": 139.355,
        "_internal_originalTime": 1689740672000
    },
    {
        "time": 1689740674000,
        "value": 139.35,
        "_internal_originalTime": 1689740674000
    },
    {
        "time": 1689740675000,
        "value": 139.346,
        "_internal_originalTime": 1689740675000
    },
    {
        "time": 1689740677000,
        "value": 139.351,
        "_internal_originalTime": 1689740677000
    },
    {
        "time": 1689740679000,
        "value": 139.352,
        "_internal_originalTime": 1689740679000
    },
    {
        "time": 1689740682000,
        "value": 139.351,
        "_internal_originalTime": 1689740682000
    },
    {
        "time": 1689740685000,
        "value": 139.347,
        "_internal_originalTime": 1689740685000
    },
    {
        "time": 1689740686000,
        "value": 139.347,
        "_internal_originalTime": 1689740686000
    },
    {
        "time": 1689740688000,
        "value": 139.352,
        "_internal_originalTime": 1689740688000
    },
    {
        "time": 1689740690000,
        "value": 139.35,
        "_internal_originalTime": 1689740690000
    },
    {
        "time": 1689740692000,
        "value": 139.351,
        "_internal_originalTime": 1689740692000
    },
    {
        "time": 1689740693000,
        "value": 139.353,
        "_internal_originalTime": 1689740693000
    },
    {
        "time": 1689740694000,
        "value": 139.353,
        "_internal_originalTime": 1689740694000
    },
    {
        "time": 1689740696000,
        "value": 139.354,
        "_internal_originalTime": 1689740696000
    },
    {
        "time": 1689740699000,
        "value": 139.352,
        "_internal_originalTime": 1689740699000
    },
    {
        "time": 1689740701000,
        "value": 139.342,
        "_internal_originalTime": 1689740701000
    },
    {
        "time": 1689740703000,
        "value": 139.345,
        "_internal_originalTime": 1689740703000
    },
    {
        "time": 1689740704000,
        "value": 139.346,
        "_internal_originalTime": 1689740704000
    },
    {
        "time": 1689740706000,
        "value": 139.361,
        "_internal_originalTime": 1689740706000
    },
    {
        "time": 1689740707000,
        "value": 139.362,
        "_internal_originalTime": 1689740707000
    },
    {
        "time": 1689740708000,
        "value": 139.358,
        "_internal_originalTime": 1689740708000
    },
    {
        "time": 1689740711000,
        "value": 139.36,
        "_internal_originalTime": 1689740711000
    },
    {
        "time": 1689740713000,
        "value": 139.36,
        "_internal_originalTime": 1689740713000
    },
    {
        "time": 1689740714000,
        "value": 139.361,
        "_internal_originalTime": 1689740714000
    },
    {
        "time": 1689740716000,
        "value": 139.362,
        "_internal_originalTime": 1689740716000
    },
    {
        "time": 1689740717000,
        "value": 139.362,
        "_internal_originalTime": 1689740717000
    },
    {
        "time": 1689740720000,
        "value": 139.361,
        "_internal_originalTime": 1689740720000
    },
    {
        "time": 1689740722000,
        "value": 139.362,
        "_internal_originalTime": 1689740722000
    },
    {
        "time": 1689740723000,
        "value": 139.363,
        "_internal_originalTime": 1689740723000
    },
    {
        "time": 1689740725000,
        "value": 139.363,
        "_internal_originalTime": 1689740725000
    },
    {
        "time": 1689740727000,
        "value": 139.355,
        "_internal_originalTime": 1689740727000
    },
    {
        "time": 1689740728000,
        "value": 139.356,
        "_internal_originalTime": 1689740728000
    },
    {
        "time": 1689740730000,
        "value": 139.36,
        "_internal_originalTime": 1689740730000
    },
    {
        "time": 1689740731000,
        "value": 139.362,
        "_internal_originalTime": 1689740731000
    },
    {
        "time": 1689740735000,
        "value": 139.356,
        "_internal_originalTime": 1689740735000
    },
    {
        "time": 1689740736000,
        "value": 139.352,
        "_internal_originalTime": 1689740736000
    },
    {
        "time": 1689740738000,
        "value": 139.355,
        "_internal_originalTime": 1689740738000
    },
    {
        "time": 1689740739000,
        "value": 139.348,
        "_internal_originalTime": 1689740739000
    },
    {
        "time": 1689740741000,
        "value": 139.352,
        "_internal_originalTime": 1689740741000
    },
    {
        "time": 1689740742000,
        "value": 139.352,
        "_internal_originalTime": 1689740742000
    },
    {
        "time": 1689740745000,
        "value": 139.353,
        "_internal_originalTime": 1689740745000
    },
    {
        "time": 1689740747000,
        "value": 139.354,
        "_internal_originalTime": 1689740747000
    },
    {
        "time": 1689740749000,
        "value": 139.354,
        "_internal_originalTime": 1689740749000
    },
    {
        "time": 1689740752000,
        "value": 139.348,
        "_internal_originalTime": 1689740752000
    },
    {
        "time": 1689740755000,
        "value": 139.35,
        "_internal_originalTime": 1689740755000
    },
    {
        "time": 1689740757000,
        "value": 139.352,
        "_internal_originalTime": 1689740757000
    },
    {
        "time": 1689740759000,
        "value": 139.348,
        "_internal_originalTime": 1689740759000
    },
    {
        "time": 1689740760000,
        "value": 139.347,
        "_internal_originalTime": 1689740760000
    },
    {
        "time": 1689740762000,
        "value": 139.352,
        "_internal_originalTime": 1689740762000
    },
    {
        "time": 1689740764000,
        "value": 139.349,
        "_internal_originalTime": 1689740764000
    },
    {
        "time": 1689740766000,
        "value": 139.358,
        "_internal_originalTime": 1689740766000
    },
    {
        "time": 1689740767000,
        "value": 139.361,
        "_internal_originalTime": 1689740767000
    },
    {
        "time": 1689740768000,
        "value": 139.357,
        "_internal_originalTime": 1689740768000
    },
    {
        "time": 1689740771000,
        "value": 139.356,
        "_internal_originalTime": 1689740771000
    },
    {
        "time": 1689740773000,
        "value": 139.351,
        "_internal_originalTime": 1689740773000
    },
    {
        "time": 1689740774000,
        "value": 139.352,
        "_internal_originalTime": 1689740774000
    },
    {
        "time": 1689740775000,
        "value": 139.352,
        "_internal_originalTime": 1689740775000
    },
    {
        "time": 1689740779000,
        "value": 139.348,
        "_internal_originalTime": 1689740779000
    },
    {
        "time": 1689740780000,
        "value": 139.347,
        "_internal_originalTime": 1689740780000
    },
    {
        "time": 1689740781000,
        "value": 139.352,
        "_internal_originalTime": 1689740781000
    },
    {
        "time": 1689740784000,
        "value": 139.346,
        "_internal_originalTime": 1689740784000
    },
    {
        "time": 1689740786000,
        "value": 139.35,
        "_internal_originalTime": 1689740786000
    },
    {
        "time": 1689740787000,
        "value": 139.353,
        "_internal_originalTime": 1689740787000
    },
    {
        "time": 1689740789000,
        "value": 139.353,
        "_internal_originalTime": 1689740789000
    },
    {
        "time": 1689740792000,
        "value": 139.347,
        "_internal_originalTime": 1689740792000
    },
    {
        "time": 1689740794000,
        "value": 139.352,
        "_internal_originalTime": 1689740794000
    },
    {
        "time": 1689740795000,
        "value": 139.353,
        "_internal_originalTime": 1689740795000
    },
    {
        "time": 1689740798000,
        "value": 139.352,
        "_internal_originalTime": 1689740798000
    },
    {
        "time": 1689740799000,
        "value": 139.352,
        "_internal_originalTime": 1689740799000
    },
    {
        "time": 1689740800000,
        "value": 139.352,
        "_internal_originalTime": 1689740800000
    },
    {
        "time": 1689740802000,
        "value": 139.352,
        "_internal_originalTime": 1689740802000
    },
    {
        "time": 1689740803000,
        "value": 139.354,
        "_internal_originalTime": 1689740803000
    },
    {
        "time": 1689740805000,
        "value": 139.352,
        "_internal_originalTime": 1689740805000
    },
    {
        "time": 1689740807000,
        "value": 139.356,
        "_internal_originalTime": 1689740807000
    },
    {
        "time": 1689740808000,
        "value": 139.354,
        "_internal_originalTime": 1689740808000
    },
    {
        "time": 1689740809000,
        "value": 139.353,
        "_internal_originalTime": 1689740809000
    },
    {
        "time": 1689740811000,
        "value": 139.357,
        "_internal_originalTime": 1689740811000
    },
    {
        "time": 1689740816000,
        "value": 139.356,
        "_internal_originalTime": 1689740816000
    },
    {
        "time": 1689740818000,
        "value": 139.359,
        "_internal_originalTime": 1689740818000
    },
    {
        "time": 1689740819000,
        "value": 139.365,
        "_internal_originalTime": 1689740819000
    },
    {
        "time": 1689740822000,
        "value": 139.363,
        "_internal_originalTime": 1689740822000
    },
    {
        "time": 1689740824000,
        "value": 139.358,
        "_internal_originalTime": 1689740824000
    },
    {
        "time": 1689740826000,
        "value": 139.358,
        "_internal_originalTime": 1689740826000
    },
    {
        "time": 1689740827000,
        "value": 139.362,
        "_internal_originalTime": 1689740827000
    },
    {
        "time": 1689740829000,
        "value": 139.357,
        "_internal_originalTime": 1689740829000
    },
    {
        "time": 1689740830000,
        "value": 139.363,
        "_internal_originalTime": 1689740830000
    },
    {
        "time": 1689740832000,
        "value": 139.362,
        "_internal_originalTime": 1689740832000
    },
    {
        "time": 1689740834000,
        "value": 139.361,
        "_internal_originalTime": 1689740834000
    },
    {
        "time": 1689740836000,
        "value": 139.356,
        "_internal_originalTime": 1689740836000
    },
    {
        "time": 1689740839000,
        "value": 139.359,
        "_internal_originalTime": 1689740839000
    },
    {
        "time": 1689740841000,
        "value": 139.362,
        "_internal_originalTime": 1689740841000
    },
    {
        "time": 1689740842000,
        "value": 139.361,
        "_internal_originalTime": 1689740842000
    },
    {
        "time": 1689740844000,
        "value": 139.362,
        "_internal_originalTime": 1689740844000
    },
    {
        "time": 1689740846000,
        "value": 139.362,
        "_internal_originalTime": 1689740846000
    },
    {
        "time": 1689740847000,
        "value": 139.356,
        "_internal_originalTime": 1689740847000
    },
    {
        "time": 1689740849000,
        "value": 139.356,
        "_internal_originalTime": 1689740849000
    },
    {
        "time": 1689740850000,
        "value": 139.362,
        "_internal_originalTime": 1689740850000
    },
    {
        "time": 1689740851000,
        "value": 139.362,
        "_internal_originalTime": 1689740851000
    },
    {
        "time": 1689740853000,
        "value": 139.362,
        "_internal_originalTime": 1689740853000
    },
    {
        "time": 1689740855000,
        "value": 139.362,
        "_internal_originalTime": 1689740855000
    },
    {
        "time": 1689740857000,
        "value": 139.362,
        "_internal_originalTime": 1689740857000
    },
    {
        "time": 1689740859000,
        "value": 139.356,
        "_internal_originalTime": 1689740859000
    },
    {
        "time": 1689740860000,
        "value": 139.361,
        "_internal_originalTime": 1689740860000
    },
    {
        "time": 1689740865000,
        "value": 139.361,
        "_internal_originalTime": 1689740865000
    },
    {
        "time": 1689740867000,
        "value": 139.362,
        "_internal_originalTime": 1689740867000
    },
    {
        "time": 1689740868000,
        "value": 139.357,
        "_internal_originalTime": 1689740868000
    },
    {
        "time": 1689740869000,
        "value": 139.361,
        "_internal_originalTime": 1689740869000
    },
    {
        "time": 1689740871000,
        "value": 139.356,
        "_internal_originalTime": 1689740871000
    },
    {
        "time": 1689740872000,
        "value": 139.362,
        "_internal_originalTime": 1689740872000
    },
    {
        "time": 1689740874000,
        "value": 139.357,
        "_internal_originalTime": 1689740874000
    },
    {
        "time": 1689740877000,
        "value": 139.356,
        "_internal_originalTime": 1689740877000
    },
    {
        "time": 1689740878000,
        "value": 139.357,
        "_internal_originalTime": 1689740878000
    },
    {
        "time": 1689740879000,
        "value": 139.356,
        "_internal_originalTime": 1689740879000
    },
    {
        "time": 1689740881000,
        "value": 139.34,
        "_internal_originalTime": 1689740881000
    },
    {
        "time": 1689740882000,
        "value": 139.359,
        "_internal_originalTime": 1689740882000
    },
    {
        "time": 1689740884000,
        "value": 139.364,
        "_internal_originalTime": 1689740884000
    },
    {
        "time": 1689740885000,
        "value": 139.363,
        "_internal_originalTime": 1689740885000
    },
    {
        "time": 1689740886000,
        "value": 139.358,
        "_internal_originalTime": 1689740886000
    },
    {
        "time": 1689740889000,
        "value": 139.36,
        "_internal_originalTime": 1689740889000
    },
    {
        "time": 1689740890000,
        "value": 139.362,
        "_internal_originalTime": 1689740890000
    },
    {
        "time": 1689740894000,
        "value": 139.359,
        "_internal_originalTime": 1689740894000
    },
    {
        "time": 1689740895000,
        "value": 139.362,
        "_internal_originalTime": 1689740895000
    },
    {
        "time": 1689740897000,
        "value": 139.358,
        "_internal_originalTime": 1689740897000
    },
    {
        "time": 1689740899000,
        "value": 139.362,
        "_internal_originalTime": 1689740899000
    },
    {
        "time": 1689740900000,
        "value": 139.366,
        "_internal_originalTime": 1689740900000
    },
    {
        "time": 1689740902000,
        "value": 139.364,
        "_internal_originalTime": 1689740902000
    },
    {
        "time": 1689740903000,
        "value": 139.357,
        "_internal_originalTime": 1689740903000
    },
    {
        "time": 1689740905000,
        "value": 139.355,
        "_internal_originalTime": 1689740905000
    },
    {
        "time": 1689740907000,
        "value": 139.349,
        "_internal_originalTime": 1689740907000
    },
    {
        "time": 1689740908000,
        "value": 139.348,
        "_internal_originalTime": 1689740908000
    },
    {
        "time": 1689740910000,
        "value": 139.348,
        "_internal_originalTime": 1689740910000
    },
    {
        "time": 1689740911000,
        "value": 139.348,
        "_internal_originalTime": 1689740911000
    },
    {
        "time": 1689740915000,
        "value": 139.355,
        "_internal_originalTime": 1689740915000
    },
    {
        "time": 1689740916000,
        "value": 139.349,
        "_internal_originalTime": 1689740916000
    },
    {
        "time": 1689740918000,
        "value": 139.353,
        "_internal_originalTime": 1689740918000
    },
    {
        "time": 1689740919000,
        "value": 139.346,
        "_internal_originalTime": 1689740919000
    },
    {
        "time": 1689740921000,
        "value": 139.344,
        "_internal_originalTime": 1689740921000
    },
    {
        "time": 1689740923000,
        "value": 139.348,
        "_internal_originalTime": 1689740923000
    },
    {
        "time": 1689740925000,
        "value": 139.344,
        "_internal_originalTime": 1689740925000
    },
    {
        "time": 1689740927000,
        "value": 139.341,
        "_internal_originalTime": 1689740927000
    },
    {
        "time": 1689740929000,
        "value": 139.351,
        "_internal_originalTime": 1689740929000
    },
    {
        "time": 1689740930000,
        "value": 139.346,
        "_internal_originalTime": 1689740930000
    },
    {
        "time": 1689740934000,
        "value": 139.346,
        "_internal_originalTime": 1689740934000
    },
    {
        "time": 1689740935000,
        "value": 139.347,
        "_internal_originalTime": 1689740935000
    },
    {
        "time": 1689740938000,
        "value": 139.352,
        "_internal_originalTime": 1689740938000
    },
    {
        "time": 1689740940000,
        "value": 139.347,
        "_internal_originalTime": 1689740940000
    },
    {
        "time": 1689740941000,
        "value": 139.344,
        "_internal_originalTime": 1689740941000
    },
    {
        "time": 1689740942000,
        "value": 139.351,
        "_internal_originalTime": 1689740942000
    },
    {
        "time": 1689740944000,
        "value": 139.346,
        "_internal_originalTime": 1689740944000
    },
    {
        "time": 1689740946000,
        "value": 139.346,
        "_internal_originalTime": 1689740946000
    },
    {
        "time": 1689740948000,
        "value": 139.347,
        "_internal_originalTime": 1689740948000
    },
    {
        "time": 1689740949000,
        "value": 139.344,
        "_internal_originalTime": 1689740949000
    },
    {
        "time": 1689740953000,
        "value": 139.347,
        "_internal_originalTime": 1689740953000
    },
    {
        "time": 1689740954000,
        "value": 139.347,
        "_internal_originalTime": 1689740954000
    },
    {
        "time": 1689740955000,
        "value": 139.344,
        "_internal_originalTime": 1689740955000
    },
    {
        "time": 1689740958000,
        "value": 139.351,
        "_internal_originalTime": 1689740958000
    },
    {
        "time": 1689740959000,
        "value": 139.348,
        "_internal_originalTime": 1689740959000
    },
    {
        "time": 1689740961000,
        "value": 139.347,
        "_internal_originalTime": 1689740961000
    },
    {
        "time": 1689740962000,
        "value": 139.348,
        "_internal_originalTime": 1689740962000
    },
    {
        "time": 1689740964000,
        "value": 139.346,
        "_internal_originalTime": 1689740964000
    },
    {
        "time": 1689740966000,
        "value": 139.342,
        "_internal_originalTime": 1689740966000
    },
    {
        "time": 1689740968000,
        "value": 139.339,
        "_internal_originalTime": 1689740968000
    },
    {
        "time": 1689740969000,
        "value": 139.336,
        "_internal_originalTime": 1689740969000
    },
    {
        "time": 1689740970000,
        "value": 139.343,
        "_internal_originalTime": 1689740970000
    },
    {
        "time": 1689740974000,
        "value": 139.339,
        "_internal_originalTime": 1689740974000
    },
    {
        "time": 1689740976000,
        "value": 139.345,
        "_internal_originalTime": 1689740976000
    },
    {
        "time": 1689740978000,
        "value": 139.343,
        "_internal_originalTime": 1689740978000
    },
    {
        "time": 1689740979000,
        "value": 139.339,
        "_internal_originalTime": 1689740979000
    },
    {
        "time": 1689740982000,
        "value": 139.344,
        "_internal_originalTime": 1689740982000
    },
    {
        "time": 1689740985000,
        "value": 139.345,
        "_internal_originalTime": 1689740985000
    },
    {
        "time": 1689740987000,
        "value": 139.343,
        "_internal_originalTime": 1689740987000
    },
    {
        "time": 1689740988000,
        "value": 139.346,
        "_internal_originalTime": 1689740988000
    },
    {
        "time": 1689740990000,
        "value": 139.345,
        "_internal_originalTime": 1689740990000
    },
    {
        "time": 1689740993000,
        "value": 139.35,
        "_internal_originalTime": 1689740993000
    },
    {
        "time": 1689740994000,
        "value": 139.346,
        "_internal_originalTime": 1689740994000
    },
    {
        "time": 1689740996000,
        "value": 139.345,
        "_internal_originalTime": 1689740996000
    },
    {
        "time": 1689740998000,
        "value": 139.346,
        "_internal_originalTime": 1689740998000
    },
    {
        "time": 1689740999000,
        "value": 139.345,
        "_internal_originalTime": 1689740999000
    },
    {
        "time": 1689741000000,
        "value": 139.338,
        "_internal_originalTime": 1689741000000
    },
    {
        "time": 1689741001000,
        "value": 139.345,
        "_internal_originalTime": 1689741001000
    },
    {
        "time": 1689741002000,
        "value": 139.341,
        "_internal_originalTime": 1689741002000
    },
    {
        "time": 1689741003000,
        "value": 139.345,
        "_internal_originalTime": 1689741003000
    },
    {
        "time": 1689741004000,
        "value": 139.338,
        "_internal_originalTime": 1689741004000
    },
    {
        "time": 1689741009000,
        "value": 139.337,
        "_internal_originalTime": 1689741009000
    },
    {
        "time": 1689741010000,
        "value": 139.344,
        "_internal_originalTime": 1689741010000
    },
    {
        "time": 1689741013000,
        "value": 139.346,
        "_internal_originalTime": 1689741013000
    },
    {
        "time": 1689741015000,
        "value": 139.339,
        "_internal_originalTime": 1689741015000
    },
    {
        "time": 1689741016000,
        "value": 139.339,
        "_internal_originalTime": 1689741016000
    },
    {
        "time": 1689741017000,
        "value": 139.346,
        "_internal_originalTime": 1689741017000
    },
    {
        "time": 1689741019000,
        "value": 139.34,
        "_internal_originalTime": 1689741019000
    },
    {
        "time": 1689741020000,
        "value": 139.346,
        "_internal_originalTime": 1689741020000
    },
    {
        "time": 1689741022000,
        "value": 139.339,
        "_internal_originalTime": 1689741022000
    },
    {
        "time": 1689741024000,
        "value": 139.338,
        "_internal_originalTime": 1689741024000
    },
    {
        "time": 1689741025000,
        "value": 139.339,
        "_internal_originalTime": 1689741025000
    },
    {
        "time": 1689741027000,
        "value": 139.345,
        "_internal_originalTime": 1689741027000
    },
    {
        "time": 1689741029000,
        "value": 139.341,
        "_internal_originalTime": 1689741029000
    },
    {
        "time": 1689741030000,
        "value": 139.345,
        "_internal_originalTime": 1689741030000
    },
    {
        "time": 1689741035000,
        "value": 139.345,
        "_internal_originalTime": 1689741035000
    },
    {
        "time": 1689741037000,
        "value": 139.338,
        "_internal_originalTime": 1689741037000
    },
    {
        "time": 1689741038000,
        "value": 139.342,
        "_internal_originalTime": 1689741038000
    },
    {
        "time": 1689741041000,
        "value": 139.347,
        "_internal_originalTime": 1689741041000
    },
    {
        "time": 1689741042000,
        "value": 139.348,
        "_internal_originalTime": 1689741042000
    },
    {
        "time": 1689741044000,
        "value": 139.347,
        "_internal_originalTime": 1689741044000
    },
    {
        "time": 1689741045000,
        "value": 139.35,
        "_internal_originalTime": 1689741045000
    },
    {
        "time": 1689741047000,
        "value": 139.349,
        "_internal_originalTime": 1689741047000
    },
    {
        "time": 1689741048000,
        "value": 139.339,
        "_internal_originalTime": 1689741048000
    },
    {
        "time": 1689741049000,
        "value": 139.338,
        "_internal_originalTime": 1689741049000
    },
    {
        "time": 1689741050000,
        "value": 139.343,
        "_internal_originalTime": 1689741050000
    },
    {
        "time": 1689741054000,
        "value": 139.339,
        "_internal_originalTime": 1689741054000
    },
    {
        "time": 1689741055000,
        "value": 139.344,
        "_internal_originalTime": 1689741055000
    },
    {
        "time": 1689741057000,
        "value": 139.336,
        "_internal_originalTime": 1689741057000
    },
    {
        "time": 1689741058000,
        "value": 139.339,
        "_internal_originalTime": 1689741058000
    },
    {
        "time": 1689741059000,
        "value": 139.338,
        "_internal_originalTime": 1689741059000
    },
    {
        "time": 1689741061000,
        "value": 139.338,
        "_internal_originalTime": 1689741061000
    },
    {
        "time": 1689741062000,
        "value": 139.342,
        "_internal_originalTime": 1689741062000
    },
    {
        "time": 1689741064000,
        "value": 139.337,
        "_internal_originalTime": 1689741064000
    },
    {
        "time": 1689741065000,
        "value": 139.344,
        "_internal_originalTime": 1689741065000
    },
    {
        "time": 1689741067000,
        "value": 139.344,
        "_internal_originalTime": 1689741067000
    },
    {
        "time": 1689741068000,
        "value": 139.343,
        "_internal_originalTime": 1689741068000
    },
    {
        "time": 1689741070000,
        "value": 139.343,
        "_internal_originalTime": 1689741070000
    },
    {
        "time": 1689741072000,
        "value": 139.347,
        "_internal_originalTime": 1689741072000
    },
    {
        "time": 1689741074000,
        "value": 139.34,
        "_internal_originalTime": 1689741074000
    },
    {
        "time": 1689741075000,
        "value": 139.347,
        "_internal_originalTime": 1689741075000
    },
    {
        "time": 1689741078000,
        "value": 139.351,
        "_internal_originalTime": 1689741078000
    },
    {
        "time": 1689741079000,
        "value": 139.357,
        "_internal_originalTime": 1689741079000
    },
    {
        "time": 1689741081000,
        "value": 139.355,
        "_internal_originalTime": 1689741081000
    },
    {
        "time": 1689741084000,
        "value": 139.355,
        "_internal_originalTime": 1689741084000
    },
    {
        "time": 1689741087000,
        "value": 139.348,
        "_internal_originalTime": 1689741087000
    },
    {
        "time": 1689741089000,
        "value": 139.345,
        "_internal_originalTime": 1689741089000
    },
    {
        "time": 1689741090000,
        "value": 139.351,
        "_internal_originalTime": 1689741090000
    },
    {
        "time": 1689741092000,
        "value": 139.346,
        "_internal_originalTime": 1689741092000
    },
    {
        "time": 1689741093000,
        "value": 139.344,
        "_internal_originalTime": 1689741093000
    },
    {
        "time": 1689741097000,
        "value": 139.351,
        "_internal_originalTime": 1689741097000
    },
    {
        "time": 1689741098000,
        "value": 139.344,
        "_internal_originalTime": 1689741098000
    },
    {
        "time": 1689741101000,
        "value": 139.352,
        "_internal_originalTime": 1689741101000
    },
    {
        "time": 1689741102000,
        "value": 139.353,
        "_internal_originalTime": 1689741102000
    },
    {
        "time": 1689741104000,
        "value": 139.347,
        "_internal_originalTime": 1689741104000
    },
    {
        "time": 1689741107000,
        "value": 139.349,
        "_internal_originalTime": 1689741107000
    },
    {
        "time": 1689741109000,
        "value": 139.35,
        "_internal_originalTime": 1689741109000
    },
    {
        "time": 1689741116000,
        "value": 139.34,
        "_internal_originalTime": 1689741116000
    },
    {
        "time": 1689741117000,
        "value": 139.335,
        "_internal_originalTime": 1689741117000
    },
    {
        "time": 1689741118000,
        "value": 139.337,
        "_internal_originalTime": 1689741118000
    },
    {
        "time": 1689741120000,
        "value": 139.336,
        "_internal_originalTime": 1689741120000
    },
    {
        "time": 1689741121000,
        "value": 139.342,
        "_internal_originalTime": 1689741121000
    },
    {
        "time": 1689741123000,
        "value": 139.34,
        "_internal_originalTime": 1689741123000
    },
    {
        "time": 1689741125000,
        "value": 139.345,
        "_internal_originalTime": 1689741125000
    },
    {
        "time": 1689741126000,
        "value": 139.344,
        "_internal_originalTime": 1689741126000
    },
    {
        "time": 1689741128000,
        "value": 139.341,
        "_internal_originalTime": 1689741128000
    },
    {
        "time": 1689741133000,
        "value": 139.345,
        "_internal_originalTime": 1689741133000
    },
    {
        "time": 1689741136000,
        "value": 139.342,
        "_internal_originalTime": 1689741136000
    },
    {
        "time": 1689741137000,
        "value": 139.336,
        "_internal_originalTime": 1689741137000
    },
    {
        "time": 1689741141000,
        "value": 139.343,
        "_internal_originalTime": 1689741141000
    },
    {
        "time": 1689741142000,
        "value": 139.338,
        "_internal_originalTime": 1689741142000
    },
    {
        "time": 1689741144000,
        "value": 139.338,
        "_internal_originalTime": 1689741144000
    },
    {
        "time": 1689741146000,
        "value": 139.335,
        "_internal_originalTime": 1689741146000
    },
    {
        "time": 1689741147000,
        "value": 139.336,
        "_internal_originalTime": 1689741147000
    },
    {
        "time": 1689741149000,
        "value": 139.337,
        "_internal_originalTime": 1689741149000
    },
    {
        "time": 1689741150000,
        "value": 139.339,
        "_internal_originalTime": 1689741150000
    },
    {
        "time": 1689741152000,
        "value": 139.336,
        "_internal_originalTime": 1689741152000
    },
    {
        "time": 1689741154000,
        "value": 139.343,
        "_internal_originalTime": 1689741154000
    },
    {
        "time": 1689741156000,
        "value": 139.337,
        "_internal_originalTime": 1689741156000
    },
    {
        "time": 1689741157000,
        "value": 139.334,
        "_internal_originalTime": 1689741157000
    },
    {
        "time": 1689741159000,
        "value": 139.332,
        "_internal_originalTime": 1689741159000
    },
    {
        "time": 1689741161000,
        "value": 139.331,
        "_internal_originalTime": 1689741161000
    },
    {
        "time": 1689741163000,
        "value": 139.331,
        "_internal_originalTime": 1689741163000
    },
    {
        "time": 1689741164000,
        "value": 139.328,
        "_internal_originalTime": 1689741164000
    },
    {
        "time": 1689741165000,
        "value": 139.335,
        "_internal_originalTime": 1689741165000
    },
    {
        "time": 1689741167000,
        "value": 139.33,
        "_internal_originalTime": 1689741167000
    },
    {
        "time": 1689741170000,
        "value": 139.334,
        "_internal_originalTime": 1689741170000
    },
    {
        "time": 1689741175000,
        "value": 139.329,
        "_internal_originalTime": 1689741175000
    },
    {
        "time": 1689741177000,
        "value": 139.326,
        "_internal_originalTime": 1689741177000
    },
    {
        "time": 1689741178000,
        "value": 139.326,
        "_internal_originalTime": 1689741178000
    },
    {
        "time": 1689741179000,
        "value": 139.327,
        "_internal_originalTime": 1689741179000
    },
    {
        "time": 1689741181000,
        "value": 139.328,
        "_internal_originalTime": 1689741181000
    },
    {
        "time": 1689741182000,
        "value": 139.332,
        "_internal_originalTime": 1689741182000
    },
    {
        "time": 1689741183000,
        "value": 139.337,
        "_internal_originalTime": 1689741183000
    },
    {
        "time": 1689741184000,
        "value": 139.336,
        "_internal_originalTime": 1689741184000
    },
    {
        "time": 1689741186000,
        "value": 139.331,
        "_internal_originalTime": 1689741186000
    },
    {
        "time": 1689741188000,
        "value": 139.331,
        "_internal_originalTime": 1689741188000
    },
    {
        "time": 1689741190000,
        "value": 139.33,
        "_internal_originalTime": 1689741190000
    },
    {
        "time": 1689741192000,
        "value": 139.332,
        "_internal_originalTime": 1689741192000
    },
    {
        "time": 1689741194000,
        "value": 139.328,
        "_internal_originalTime": 1689741194000
    },
    {
        "time": 1689741195000,
        "value": 139.329,
        "_internal_originalTime": 1689741195000
    },
    {
        "time": 1689741197000,
        "value": 139.328,
        "_internal_originalTime": 1689741197000
    },
    {
        "time": 1689741198000,
        "value": 139.328,
        "_internal_originalTime": 1689741198000
    },
    {
        "time": 1689741201000,
        "value": 139.327,
        "_internal_originalTime": 1689741201000
    },
    {
        "time": 1689741202000,
        "value": 139.328,
        "_internal_originalTime": 1689741202000
    },
    {
        "time": 1689741204000,
        "value": 139.326,
        "_internal_originalTime": 1689741204000
    },
    {
        "time": 1689741205000,
        "value": 139.326,
        "_internal_originalTime": 1689741205000
    },
    {
        "time": 1689741207000,
        "value": 139.327,
        "_internal_originalTime": 1689741207000
    },
    {
        "time": 1689741209000,
        "value": 139.325,
        "_internal_originalTime": 1689741209000
    },
    {
        "time": 1689741210000,
        "value": 139.326,
        "_internal_originalTime": 1689741210000
    },
    {
        "time": 1689741211000,
        "value": 139.325,
        "_internal_originalTime": 1689741211000
    },
    {
        "time": 1689741214000,
        "value": 139.332,
        "_internal_originalTime": 1689741214000
    },
    {
        "time": 1689741216000,
        "value": 139.318,
        "_internal_originalTime": 1689741216000
    },
    {
        "time": 1689741217000,
        "value": 139.322,
        "_internal_originalTime": 1689741217000
    },
    {
        "time": 1689741219000,
        "value": 139.321,
        "_internal_originalTime": 1689741219000
    },
    {
        "time": 1689741220000,
        "value": 139.316,
        "_internal_originalTime": 1689741220000
    },
    {
        "time": 1689741222000,
        "value": 139.318,
        "_internal_originalTime": 1689741222000
    },
    {
        "time": 1689741223000,
        "value": 139.319,
        "_internal_originalTime": 1689741223000
    },
    {
        "time": 1689741229000,
        "value": 139.323,
        "_internal_originalTime": 1689741229000
    },
    {
        "time": 1689741230000,
        "value": 139.327,
        "_internal_originalTime": 1689741230000
    },
    {
        "time": 1689741232000,
        "value": 139.317,
        "_internal_originalTime": 1689741232000
    },
    {
        "time": 1689741234000,
        "value": 139.319,
        "_internal_originalTime": 1689741234000
    },
    {
        "time": 1689741236000,
        "value": 139.32,
        "_internal_originalTime": 1689741236000
    },
    {
        "time": 1689741237000,
        "value": 139.324,
        "_internal_originalTime": 1689741237000
    },
    {
        "time": 1689741239000,
        "value": 139.321,
        "_internal_originalTime": 1689741239000
    },
    {
        "time": 1689741240000,
        "value": 139.321,
        "_internal_originalTime": 1689741240000
    },
    {
        "time": 1689741241000,
        "value": 139.318,
        "_internal_originalTime": 1689741241000
    },
    {
        "time": 1689741243000,
        "value": 139.327,
        "_internal_originalTime": 1689741243000
    },
    {
        "time": 1689741244000,
        "value": 139.319,
        "_internal_originalTime": 1689741244000
    },
    {
        "time": 1689741246000,
        "value": 139.322,
        "_internal_originalTime": 1689741246000
    },
    {
        "time": 1689741248000,
        "value": 139.317,
        "_internal_originalTime": 1689741248000
    },
    {
        "time": 1689741250000,
        "value": 139.321,
        "_internal_originalTime": 1689741250000
    },
    {
        "time": 1689741251000,
        "value": 139.322,
        "_internal_originalTime": 1689741251000
    },
    {
        "time": 1689741253000,
        "value": 139.319,
        "_internal_originalTime": 1689741253000
    },
    {
        "time": 1689741254000,
        "value": 139.319,
        "_internal_originalTime": 1689741254000
    },
    {
        "time": 1689741256000,
        "value": 139.322,
        "_internal_originalTime": 1689741256000
    },
    {
        "time": 1689741259000,
        "value": 139.32,
        "_internal_originalTime": 1689741259000
    },
    {
        "time": 1689741261000,
        "value": 139.322,
        "_internal_originalTime": 1689741261000
    },
    {
        "time": 1689741263000,
        "value": 139.323,
        "_internal_originalTime": 1689741263000
    },
    {
        "time": 1689741264000,
        "value": 139.323,
        "_internal_originalTime": 1689741264000
    },
    {
        "time": 1689741266000,
        "value": 139.317,
        "_internal_originalTime": 1689741266000
    },
    {
        "time": 1689741268000,
        "value": 139.319,
        "_internal_originalTime": 1689741268000
    },
    {
        "time": 1689741269000,
        "value": 139.32,
        "_internal_originalTime": 1689741269000
    },
    {
        "time": 1689741270000,
        "value": 139.323,
        "_internal_originalTime": 1689741270000
    },
    {
        "time": 1689741271000,
        "value": 139.317,
        "_internal_originalTime": 1689741271000
    },
    {
        "time": 1689741274000,
        "value": 139.322,
        "_internal_originalTime": 1689741274000
    },
    {
        "time": 1689741276000,
        "value": 139.323,
        "_internal_originalTime": 1689741276000
    },
    {
        "time": 1689741278000,
        "value": 139.319,
        "_internal_originalTime": 1689741278000
    },
    {
        "time": 1689741281000,
        "value": 139.319,
        "_internal_originalTime": 1689741281000
    },
    {
        "time": 1689741282000,
        "value": 139.319,
        "_internal_originalTime": 1689741282000
    },
    {
        "time": 1689741284000,
        "value": 139.318,
        "_internal_originalTime": 1689741284000
    },
    {
        "time": 1689741286000,
        "value": 139.319,
        "_internal_originalTime": 1689741286000
    },
    {
        "time": 1689741288000,
        "value": 139.318,
        "_internal_originalTime": 1689741288000
    },
    {
        "time": 1689741291000,
        "value": 139.326,
        "_internal_originalTime": 1689741291000
    },
    {
        "time": 1689741293000,
        "value": 139.318,
        "_internal_originalTime": 1689741293000
    },
    {
        "time": 1689741294000,
        "value": 139.319,
        "_internal_originalTime": 1689741294000
    },
    {
        "time": 1689741298000,
        "value": 139.316,
        "_internal_originalTime": 1689741298000
    },
    {
        "time": 1689741301000,
        "value": 139.322,
        "_internal_originalTime": 1689741301000
    },
    {
        "time": 1689741304000,
        "value": 139.318,
        "_internal_originalTime": 1689741304000
    },
    {
        "time": 1689741306000,
        "value": 139.323,
        "_internal_originalTime": 1689741306000
    },
    {
        "time": 1689741307000,
        "value": 139.318,
        "_internal_originalTime": 1689741307000
    },
    {
        "time": 1689741309000,
        "value": 139.317,
        "_internal_originalTime": 1689741309000
    },
    {
        "time": 1689741310000,
        "value": 139.323,
        "_internal_originalTime": 1689741310000
    },
    {
        "time": 1689741313000,
        "value": 139.318,
        "_internal_originalTime": 1689741313000
    },
    {
        "time": 1689741314000,
        "value": 139.318,
        "_internal_originalTime": 1689741314000
    },
    {
        "time": 1689741316000,
        "value": 139.322,
        "_internal_originalTime": 1689741316000
    },
    {
        "time": 1689741317000,
        "value": 139.322,
        "_internal_originalTime": 1689741317000
    },
    {
        "time": 1689741318000,
        "value": 139.316,
        "_internal_originalTime": 1689741318000
    },
    {
        "time": 1689741320000,
        "value": 139.316,
        "_internal_originalTime": 1689741320000
    },
    {
        "time": 1689741322000,
        "value": 139.318,
        "_internal_originalTime": 1689741322000
    },
    {
        "time": 1689741323000,
        "value": 139.323,
        "_internal_originalTime": 1689741323000
    },
    {
        "time": 1689741326000,
        "value": 139.319,
        "_internal_originalTime": 1689741326000
    },
    {
        "time": 1689741327000,
        "value": 139.318,
        "_internal_originalTime": 1689741327000
    },
    {
        "time": 1689741329000,
        "value": 139.316,
        "_internal_originalTime": 1689741329000
    },
    {
        "time": 1689741331000,
        "value": 139.316,
        "_internal_originalTime": 1689741331000
    },
    {
        "time": 1689741332000,
        "value": 139.323,
        "_internal_originalTime": 1689741332000
    },
    {
        "time": 1689741338000,
        "value": 139.318,
        "_internal_originalTime": 1689741338000
    },
    {
        "time": 1689741339000,
        "value": 139.317,
        "_internal_originalTime": 1689741339000
    },
    {
        "time": 1689741343000,
        "value": 139.323,
        "_internal_originalTime": 1689741343000
    },
    {
        "time": 1689741345000,
        "value": 139.329,
        "_internal_originalTime": 1689741345000
    },
    {
        "time": 1689741346000,
        "value": 139.321,
        "_internal_originalTime": 1689741346000
    },
    {
        "time": 1689741348000,
        "value": 139.321,
        "_internal_originalTime": 1689741348000
    },
    {
        "time": 1689741349000,
        "value": 139.324,
        "_internal_originalTime": 1689741349000
    },
    {
        "time": 1689741351000,
        "value": 139.318,
        "_internal_originalTime": 1689741351000
    },
    {
        "time": 1689741352000,
        "value": 139.318,
        "_internal_originalTime": 1689741352000
    },
    {
        "time": 1689741355000,
        "value": 139.322,
        "_internal_originalTime": 1689741355000
    },
    {
        "time": 1689741356000,
        "value": 139.318,
        "_internal_originalTime": 1689741356000
    },
    {
        "time": 1689741359000,
        "value": 139.318,
        "_internal_originalTime": 1689741359000
    },
    {
        "time": 1689741360000,
        "value": 139.322,
        "_internal_originalTime": 1689741360000
    },
    {
        "time": 1689741361000,
        "value": 139.334,
        "_internal_originalTime": 1689741361000
    },
    {
        "time": 1689741363000,
        "value": 139.333,
        "_internal_originalTime": 1689741363000
    },
    {
        "time": 1689741366000,
        "value": 139.335,
        "_internal_originalTime": 1689741366000
    },
    {
        "time": 1689741368000,
        "value": 139.331,
        "_internal_originalTime": 1689741368000
    },
    {
        "time": 1689741370000,
        "value": 139.33,
        "_internal_originalTime": 1689741370000
    },
    {
        "time": 1689741372000,
        "value": 139.33,
        "_internal_originalTime": 1689741372000
    },
    {
        "time": 1689741373000,
        "value": 139.332,
        "_internal_originalTime": 1689741373000
    },
    {
        "time": 1689741375000,
        "value": 139.329,
        "_internal_originalTime": 1689741375000
    },
    {
        "time": 1689741377000,
        "value": 139.331,
        "_internal_originalTime": 1689741377000
    },
    {
        "time": 1689741378000,
        "value": 139.33,
        "_internal_originalTime": 1689741378000
    },
    {
        "time": 1689741380000,
        "value": 139.338,
        "_internal_originalTime": 1689741380000
    },
    {
        "time": 1689741382000,
        "value": 139.336,
        "_internal_originalTime": 1689741382000
    },
    {
        "time": 1689741383000,
        "value": 139.335,
        "_internal_originalTime": 1689741383000
    },
    {
        "time": 1689741385000,
        "value": 139.338,
        "_internal_originalTime": 1689741385000
    },
    {
        "time": 1689741387000,
        "value": 139.343,
        "_internal_originalTime": 1689741387000
    },
    {
        "time": 1689741390000,
        "value": 139.336,
        "_internal_originalTime": 1689741390000
    },
    {
        "time": 1689741392000,
        "value": 139.354,
        "_internal_originalTime": 1689741392000
    },
    {
        "time": 1689741393000,
        "value": 139.353,
        "_internal_originalTime": 1689741393000
    },
    {
        "time": 1689741396000,
        "value": 139.351,
        "_internal_originalTime": 1689741396000
    },
    {
        "time": 1689741398000,
        "value": 139.346,
        "_internal_originalTime": 1689741398000
    },
    {
        "time": 1689741400000,
        "value": 139.343,
        "_internal_originalTime": 1689741400000
    },
    {
        "time": 1689741401000,
        "value": 139.342,
        "_internal_originalTime": 1689741401000
    },
    {
        "time": 1689741403000,
        "value": 139.353,
        "_internal_originalTime": 1689741403000
    },
    {
        "time": 1689741404000,
        "value": 139.352,
        "_internal_originalTime": 1689741404000
    },
    {
        "time": 1689741406000,
        "value": 139.348,
        "_internal_originalTime": 1689741406000
    },
    {
        "time": 1689741407000,
        "value": 139.351,
        "_internal_originalTime": 1689741407000
    },
    {
        "time": 1689741409000,
        "value": 139.348,
        "_internal_originalTime": 1689741409000
    },
    {
        "time": 1689741411000,
        "value": 139.349,
        "_internal_originalTime": 1689741411000
    },
    {
        "time": 1689741412000,
        "value": 139.351,
        "_internal_originalTime": 1689741412000
    },
    {
        "time": 1689741413000,
        "value": 139.354,
        "_internal_originalTime": 1689741413000
    },
    {
        "time": 1689741418000,
        "value": 139.351,
        "_internal_originalTime": 1689741418000
    },
    {
        "time": 1689741419000,
        "value": 139.355,
        "_internal_originalTime": 1689741419000
    },
    {
        "time": 1689741420000,
        "value": 139.358,
        "_internal_originalTime": 1689741420000
    },
    {
        "time": 1689741421000,
        "value": 139.352,
        "_internal_originalTime": 1689741421000
    },
    {
        "time": 1689741422000,
        "value": 139.355,
        "_internal_originalTime": 1689741422000
    },
    {
        "time": 1689741424000,
        "value": 139.352,
        "_internal_originalTime": 1689741424000
    },
    {
        "time": 1689741425000,
        "value": 139.355,
        "_internal_originalTime": 1689741425000
    },
    {
        "time": 1689741428000,
        "value": 139.351,
        "_internal_originalTime": 1689741428000
    },
    {
        "time": 1689741430000,
        "value": 139.351,
        "_internal_originalTime": 1689741430000
    },
    {
        "time": 1689741432000,
        "value": 139.356,
        "_internal_originalTime": 1689741432000
    },
    {
        "time": 1689741433000,
        "value": 139.356,
        "_internal_originalTime": 1689741433000
    },
    {
        "time": 1689741435000,
        "value": 139.352,
        "_internal_originalTime": 1689741435000
    },
    {
        "time": 1689741438000,
        "value": 139.348,
        "_internal_originalTime": 1689741438000
    },
    {
        "time": 1689741440000,
        "value": 139.352,
        "_internal_originalTime": 1689741440000
    },
    {
        "time": 1689741442000,
        "value": 139.35,
        "_internal_originalTime": 1689741442000
    },
    {
        "time": 1689741443000,
        "value": 139.348,
        "_internal_originalTime": 1689741443000
    },
    {
        "time": 1689741445000,
        "value": 139.352,
        "_internal_originalTime": 1689741445000
    },
    {
        "time": 1689741447000,
        "value": 139.349,
        "_internal_originalTime": 1689741447000
    },
    {
        "time": 1689741449000,
        "value": 139.349,
        "_internal_originalTime": 1689741449000
    },
    {
        "time": 1689741450000,
        "value": 139.348,
        "_internal_originalTime": 1689741450000
    },
    {
        "time": 1689741452000,
        "value": 139.349,
        "_internal_originalTime": 1689741452000
    },
    {
        "time": 1689741453000,
        "value": 139.348,
        "_internal_originalTime": 1689741453000
    },
    {
        "time": 1689741454000,
        "value": 139.35,
        "_internal_originalTime": 1689741454000
    },
    {
        "time": 1689741456000,
        "value": 139.351,
        "_internal_originalTime": 1689741456000
    },
    {
        "time": 1689741459000,
        "value": 139.351,
        "_internal_originalTime": 1689741459000
    },
    {
        "time": 1689741460000,
        "value": 139.353,
        "_internal_originalTime": 1689741460000
    },
    {
        "time": 1689741462000,
        "value": 139.349,
        "_internal_originalTime": 1689741462000
    },
    {
        "time": 1689741463000,
        "value": 139.348,
        "_internal_originalTime": 1689741463000
    },
    {
        "time": 1689741464000,
        "value": 139.349,
        "_internal_originalTime": 1689741464000
    },
    {
        "time": 1689741466000,
        "value": 139.353,
        "_internal_originalTime": 1689741466000
    },
    {
        "time": 1689741468000,
        "value": 139.347,
        "_internal_originalTime": 1689741468000
    },
    {
        "time": 1689741469000,
        "value": 139.346,
        "_internal_originalTime": 1689741469000
    },
    {
        "time": 1689741472000,
        "value": 139.353,
        "_internal_originalTime": 1689741472000
    },
    {
        "time": 1689741473000,
        "value": 139.355,
        "_internal_originalTime": 1689741473000
    },
    {
        "time": 1689741475000,
        "value": 139.348,
        "_internal_originalTime": 1689741475000
    },
    {
        "time": 1689741477000,
        "value": 139.349,
        "_internal_originalTime": 1689741477000
    },
    {
        "time": 1689741479000,
        "value": 139.35,
        "_internal_originalTime": 1689741479000
    },
    {
        "time": 1689741481000,
        "value": 139.349,
        "_internal_originalTime": 1689741481000
    },
    {
        "time": 1689741482000,
        "value": 139.347,
        "_internal_originalTime": 1689741482000
    },
    {
        "time": 1689741483000,
        "value": 139.348,
        "_internal_originalTime": 1689741483000
    },
    {
        "time": 1689741484000,
        "value": 139.347,
        "_internal_originalTime": 1689741484000
    },
    {
        "time": 1689741486000,
        "value": 139.346,
        "_internal_originalTime": 1689741486000
    },
    {
        "time": 1689741488000,
        "value": 139.348,
        "_internal_originalTime": 1689741488000
    },
    {
        "time": 1689741489000,
        "value": 139.352,
        "_internal_originalTime": 1689741489000
    },
    {
        "time": 1689741493000,
        "value": 139.353,
        "_internal_originalTime": 1689741493000
    },
    {
        "time": 1689741494000,
        "value": 139.348,
        "_internal_originalTime": 1689741494000
    },
    {
        "time": 1689741496000,
        "value": 139.344,
        "_internal_originalTime": 1689741496000
    },
    {
        "time": 1689741497000,
        "value": 139.342,
        "_internal_originalTime": 1689741497000
    },
    {
        "time": 1689741499000,
        "value": 139.345,
        "_internal_originalTime": 1689741499000
    },
    {
        "time": 1689741501000,
        "value": 139.338,
        "_internal_originalTime": 1689741501000
    },
    {
        "time": 1689741503000,
        "value": 139.338,
        "_internal_originalTime": 1689741503000
    },
    {
        "time": 1689741504000,
        "value": 139.338,
        "_internal_originalTime": 1689741504000
    },
    {
        "time": 1689741507000,
        "value": 139.336,
        "_internal_originalTime": 1689741507000
    },
    {
        "time": 1689741509000,
        "value": 139.341,
        "_internal_originalTime": 1689741509000
    },
    {
        "time": 1689741510000,
        "value": 139.342,
        "_internal_originalTime": 1689741510000
    },
    {
        "time": 1689741512000,
        "value": 139.346,
        "_internal_originalTime": 1689741512000
    },
    {
        "time": 1689741514000,
        "value": 139.34,
        "_internal_originalTime": 1689741514000
    },
    {
        "time": 1689741515000,
        "value": 139.346,
        "_internal_originalTime": 1689741515000
    },
    {
        "time": 1689741518000,
        "value": 139.341,
        "_internal_originalTime": 1689741518000
    },
    {
        "time": 1689741523000,
        "value": 139.346,
        "_internal_originalTime": 1689741523000
    },
    {
        "time": 1689741525000,
        "value": 139.346,
        "_internal_originalTime": 1689741525000
    },
    {
        "time": 1689741527000,
        "value": 139.338,
        "_internal_originalTime": 1689741527000
    },
    {
        "time": 1689741529000,
        "value": 139.342,
        "_internal_originalTime": 1689741529000
    },
    {
        "time": 1689741531000,
        "value": 139.338,
        "_internal_originalTime": 1689741531000
    },
    {
        "time": 1689741532000,
        "value": 139.34,
        "_internal_originalTime": 1689741532000
    },
    {
        "time": 1689741535000,
        "value": 139.346,
        "_internal_originalTime": 1689741535000
    },
    {
        "time": 1689741537000,
        "value": 139.338,
        "_internal_originalTime": 1689741537000
    },
    {
        "time": 1689741538000,
        "value": 139.338,
        "_internal_originalTime": 1689741538000
    },
    {
        "time": 1689741540000,
        "value": 139.34,
        "_internal_originalTime": 1689741540000
    },
    {
        "time": 1689741541000,
        "value": 139.341,
        "_internal_originalTime": 1689741541000
    },
    {
        "time": 1689741543000,
        "value": 139.341,
        "_internal_originalTime": 1689741543000
    },
    {
        "time": 1689741545000,
        "value": 139.34,
        "_internal_originalTime": 1689741545000
    },
    {
        "time": 1689741546000,
        "value": 139.338,
        "_internal_originalTime": 1689741546000
    },
    {
        "time": 1689741548000,
        "value": 139.341,
        "_internal_originalTime": 1689741548000
    },
    {
        "time": 1689741549000,
        "value": 139.344,
        "_internal_originalTime": 1689741549000
    },
    {
        "time": 1689741554000,
        "value": 139.34,
        "_internal_originalTime": 1689741554000
    },
    {
        "time": 1689741556000,
        "value": 139.341,
        "_internal_originalTime": 1689741556000
    },
    {
        "time": 1689741557000,
        "value": 139.341,
        "_internal_originalTime": 1689741557000
    },
    {
        "time": 1689741558000,
        "value": 139.341,
        "_internal_originalTime": 1689741558000
    },
    {
        "time": 1689741561000,
        "value": 139.338,
        "_internal_originalTime": 1689741561000
    },
    {
        "time": 1689741564000,
        "value": 139.338,
        "_internal_originalTime": 1689741564000
    },
    {
        "time": 1689741565000,
        "value": 139.339,
        "_internal_originalTime": 1689741565000
    },
    {
        "time": 1689741568000,
        "value": 139.341,
        "_internal_originalTime": 1689741568000
    },
    {
        "time": 1689741569000,
        "value": 139.334,
        "_internal_originalTime": 1689741569000
    },
    {
        "time": 1689741570000,
        "value": 139.341,
        "_internal_originalTime": 1689741570000
    },
    {
        "time": 1689741571000,
        "value": 139.338,
        "_internal_originalTime": 1689741571000
    },
    {
        "time": 1689741573000,
        "value": 139.341,
        "_internal_originalTime": 1689741573000
    },
    {
        "time": 1689741574000,
        "value": 139.339,
        "_internal_originalTime": 1689741574000
    },
    {
        "time": 1689741576000,
        "value": 139.34,
        "_internal_originalTime": 1689741576000
    },
    {
        "time": 1689741579000,
        "value": 139.34,
        "_internal_originalTime": 1689741579000
    },
    {
        "time": 1689741580000,
        "value": 139.337,
        "_internal_originalTime": 1689741580000
    },
    {
        "time": 1689741581000,
        "value": 139.335,
        "_internal_originalTime": 1689741581000
    },
    {
        "time": 1689741583000,
        "value": 139.335,
        "_internal_originalTime": 1689741583000
    },
    {
        "time": 1689741585000,
        "value": 139.331,
        "_internal_originalTime": 1689741585000
    },
    {
        "time": 1689741586000,
        "value": 139.33,
        "_internal_originalTime": 1689741586000
    },
    {
        "time": 1689741587000,
        "value": 139.33,
        "_internal_originalTime": 1689741587000
    },
    {
        "time": 1689741590000,
        "value": 139.335,
        "_internal_originalTime": 1689741590000
    },
    {
        "time": 1689741591000,
        "value": 139.329,
        "_internal_originalTime": 1689741591000
    },
    {
        "time": 1689741593000,
        "value": 139.333,
        "_internal_originalTime": 1689741593000
    },
    {
        "time": 1689741595000,
        "value": 139.334,
        "_internal_originalTime": 1689741595000
    },
    {
        "time": 1689741597000,
        "value": 139.33,
        "_internal_originalTime": 1689741597000
    },
    {
        "time": 1689741598000,
        "value": 139.333,
        "_internal_originalTime": 1689741598000
    },
    {
        "time": 1689741600000,
        "value": 139.329,
        "_internal_originalTime": 1689741600000
    },
    {
        "time": 1689741601000,
        "value": 139.33,
        "_internal_originalTime": 1689741601000
    },
    {
        "time": 1689741602000,
        "value": 139.329,
        "_internal_originalTime": 1689741602000
    },
    {
        "time": 1689741604000,
        "value": 139.331,
        "_internal_originalTime": 1689741604000
    },
    {
        "time": 1689741605000,
        "value": 139.327,
        "_internal_originalTime": 1689741605000
    },
    {
        "time": 1689741607000,
        "value": 139.34,
        "_internal_originalTime": 1689741607000
    },
    {
        "time": 1689741609000,
        "value": 139.358,
        "_internal_originalTime": 1689741609000
    },
    {
        "time": 1689741611000,
        "value": 139.361,
        "_internal_originalTime": 1689741611000
    },
    {
        "time": 1689741612000,
        "value": 139.359,
        "_internal_originalTime": 1689741612000
    },
    {
        "time": 1689741614000,
        "value": 139.356,
        "_internal_originalTime": 1689741614000
    },
    {
        "time": 1689741615000,
        "value": 139.363,
        "_internal_originalTime": 1689741615000
    },
    {
        "time": 1689741617000,
        "value": 139.366,
        "_internal_originalTime": 1689741617000
    },
    {
        "time": 1689741619000,
        "value": 139.36,
        "_internal_originalTime": 1689741619000
    },
    {
        "time": 1689741620000,
        "value": 139.364,
        "_internal_originalTime": 1689741620000
    },
    {
        "time": 1689741622000,
        "value": 139.364,
        "_internal_originalTime": 1689741622000
    },
    {
        "time": 1689741624000,
        "value": 139.359,
        "_internal_originalTime": 1689741624000
    },
    {
        "time": 1689741625000,
        "value": 139.355,
        "_internal_originalTime": 1689741625000
    },
    {
        "time": 1689741627000,
        "value": 139.346,
        "_internal_originalTime": 1689741627000
    },
    {
        "time": 1689741631000,
        "value": 139.345,
        "_internal_originalTime": 1689741631000
    },
    {
        "time": 1689741632000,
        "value": 139.348,
        "_internal_originalTime": 1689741632000
    },
    {
        "time": 1689741634000,
        "value": 139.349,
        "_internal_originalTime": 1689741634000
    },
    {
        "time": 1689741635000,
        "value": 139.348,
        "_internal_originalTime": 1689741635000
    },
    {
        "time": 1689741637000,
        "value": 139.346,
        "_internal_originalTime": 1689741637000
    },
    {
        "time": 1689741639000,
        "value": 139.344,
        "_internal_originalTime": 1689741639000
    },
    {
        "time": 1689741642000,
        "value": 139.348,
        "_internal_originalTime": 1689741642000
    },
    {
        "time": 1689741643000,
        "value": 139.344,
        "_internal_originalTime": 1689741643000
    },
    {
        "time": 1689741644000,
        "value": 139.345,
        "_internal_originalTime": 1689741644000
    },
    {
        "time": 1689741646000,
        "value": 139.35,
        "_internal_originalTime": 1689741646000
    },
    {
        "time": 1689741648000,
        "value": 139.35,
        "_internal_originalTime": 1689741648000
    },
    {
        "time": 1689741651000,
        "value": 139.351,
        "_internal_originalTime": 1689741651000
    },
    {
        "time": 1689741653000,
        "value": 139.351,
        "_internal_originalTime": 1689741653000
    },
    {
        "time": 1689741655000,
        "value": 139.348,
        "_internal_originalTime": 1689741655000
    },
    {
        "time": 1689741657000,
        "value": 139.343,
        "_internal_originalTime": 1689741657000
    },
    {
        "time": 1689741659000,
        "value": 139.351,
        "_internal_originalTime": 1689741659000
    },
    {
        "time": 1689741662000,
        "value": 139.343,
        "_internal_originalTime": 1689741662000
    },
    {
        "time": 1689741663000,
        "value": 139.338,
        "_internal_originalTime": 1689741663000
    },
    {
        "time": 1689741664000,
        "value": 139.341,
        "_internal_originalTime": 1689741664000
    },
    {
        "time": 1689741666000,
        "value": 139.338,
        "_internal_originalTime": 1689741666000
    },
    {
        "time": 1689741668000,
        "value": 139.339,
        "_internal_originalTime": 1689741668000
    },
    {
        "time": 1689741669000,
        "value": 139.333,
        "_internal_originalTime": 1689741669000
    },
    {
        "time": 1689741671000,
        "value": 139.331,
        "_internal_originalTime": 1689741671000
    },
    {
        "time": 1689741673000,
        "value": 139.334,
        "_internal_originalTime": 1689741673000
    },
    {
        "time": 1689741675000,
        "value": 139.338,
        "_internal_originalTime": 1689741675000
    },
    {
        "time": 1689741676000,
        "value": 139.339,
        "_internal_originalTime": 1689741676000
    },
    {
        "time": 1689741677000,
        "value": 139.343,
        "_internal_originalTime": 1689741677000
    },
    {
        "time": 1689741678000,
        "value": 139.338,
        "_internal_originalTime": 1689741678000
    },
    {
        "time": 1689741681000,
        "value": 139.338,
        "_internal_originalTime": 1689741681000
    },
    {
        "time": 1689741682000,
        "value": 139.327,
        "_internal_originalTime": 1689741682000
    },
    {
        "time": 1689741683000,
        "value": 139.332,
        "_internal_originalTime": 1689741683000
    },
    {
        "time": 1689741684000,
        "value": 139.328,
        "_internal_originalTime": 1689741684000
    },
    {
        "time": 1689741687000,
        "value": 139.32,
        "_internal_originalTime": 1689741687000
    },
    {
        "time": 1689741688000,
        "value": 139.318,
        "_internal_originalTime": 1689741688000
    },
    {
        "time": 1689741690000,
        "value": 139.329,
        "_internal_originalTime": 1689741690000
    },
    {
        "time": 1689741691000,
        "value": 139.324,
        "_internal_originalTime": 1689741691000
    },
    {
        "time": 1689741692000,
        "value": 139.328,
        "_internal_originalTime": 1689741692000
    },
    {
        "time": 1689741694000,
        "value": 139.327,
        "_internal_originalTime": 1689741694000
    },
    {
        "time": 1689741695000,
        "value": 139.33,
        "_internal_originalTime": 1689741695000
    },
    {
        "time": 1689741696000,
        "value": 139.33,
        "_internal_originalTime": 1689741696000
    },
    {
        "time": 1689741699000,
        "value": 139.33,
        "_internal_originalTime": 1689741699000
    },
    {
        "time": 1689741700000,
        "value": 139.327,
        "_internal_originalTime": 1689741700000
    },
    {
        "time": 1689741702000,
        "value": 139.328,
        "_internal_originalTime": 1689741702000
    },
    {
        "time": 1689741703000,
        "value": 139.327,
        "_internal_originalTime": 1689741703000
    },
    {
        "time": 1689741705000,
        "value": 139.329,
        "_internal_originalTime": 1689741705000
    },
    {
        "time": 1689741706000,
        "value": 139.333,
        "_internal_originalTime": 1689741706000
    },
    {
        "time": 1689741708000,
        "value": 139.33,
        "_internal_originalTime": 1689741708000
    },
    {
        "time": 1689741710000,
        "value": 139.329,
        "_internal_originalTime": 1689741710000
    },
    {
        "time": 1689741712000,
        "value": 139.332,
        "_internal_originalTime": 1689741712000
    },
    {
        "time": 1689741714000,
        "value": 139.345,
        "_internal_originalTime": 1689741714000
    },
    {
        "time": 1689741716000,
        "value": 139.348,
        "_internal_originalTime": 1689741716000
    },
    {
        "time": 1689741717000,
        "value": 139.346,
        "_internal_originalTime": 1689741717000
    },
    {
        "time": 1689741719000,
        "value": 139.352,
        "_internal_originalTime": 1689741719000
    },
    {
        "time": 1689741720000,
        "value": 139.32,
        "_internal_originalTime": 1689741720000
    },
    {
        "time": 1689741721000,
        "value": 139.353,
        "_internal_originalTime": 1689741721000
    },
    {
        "time": 1689741723000,
        "value": 139.351,
        "_internal_originalTime": 1689741723000
    },
    {
        "time": 1689741724000,
        "value": 139.347,
        "_internal_originalTime": 1689741724000
    },
    {
        "time": 1689741726000,
        "value": 139.354,
        "_internal_originalTime": 1689741726000
    },
    {
        "time": 1689741728000,
        "value": 139.348,
        "_internal_originalTime": 1689741728000
    },
    {
        "time": 1689741729000,
        "value": 139.352,
        "_internal_originalTime": 1689741729000
    },
    {
        "time": 1689741731000,
        "value": 139.347,
        "_internal_originalTime": 1689741731000
    },
    {
        "time": 1689741732000,
        "value": 139.348,
        "_internal_originalTime": 1689741732000
    },
    {
        "time": 1689741734000,
        "value": 139.348,
        "_internal_originalTime": 1689741734000
    },
    {
        "time": 1689741735000,
        "value": 139.341,
        "_internal_originalTime": 1689741735000
    },
    {
        "time": 1689741736000,
        "value": 139.342,
        "_internal_originalTime": 1689741736000
    },
    {
        "time": 1689741739000,
        "value": 139.341,
        "_internal_originalTime": 1689741739000
    },
    {
        "time": 1689741740000,
        "value": 139.34,
        "_internal_originalTime": 1689741740000
    },
    {
        "time": 1689741741000,
        "value": 139.342,
        "_internal_originalTime": 1689741741000
    },
    {
        "time": 1689741744000,
        "value": 139.345,
        "_internal_originalTime": 1689741744000
    },
    {
        "time": 1689741746000,
        "value": 139.348,
        "_internal_originalTime": 1689741746000
    },
    {
        "time": 1689741747000,
        "value": 139.338,
        "_internal_originalTime": 1689741747000
    },
    {
        "time": 1689741748000,
        "value": 139.338,
        "_internal_originalTime": 1689741748000
    },
    {
        "time": 1689741749000,
        "value": 139.338,
        "_internal_originalTime": 1689741749000
    },
    {
        "time": 1689741751000,
        "value": 139.338,
        "_internal_originalTime": 1689741751000
    },
    {
        "time": 1689741752000,
        "value": 139.338,
        "_internal_originalTime": 1689741752000
    },
    {
        "time": 1689741754000,
        "value": 139.341,
        "_internal_originalTime": 1689741754000
    },
    {
        "time": 1689741756000,
        "value": 139.342,
        "_internal_originalTime": 1689741756000
    },
    {
        "time": 1689741757000,
        "value": 139.344,
        "_internal_originalTime": 1689741757000
    },
    {
        "time": 1689741758000,
        "value": 139.34,
        "_internal_originalTime": 1689741758000
    },
    {
        "time": 1689741761000,
        "value": 139.342,
        "_internal_originalTime": 1689741761000
    },
    {
        "time": 1689741762000,
        "value": 139.34,
        "_internal_originalTime": 1689741762000
    },
    {
        "time": 1689741764000,
        "value": 139.338,
        "_internal_originalTime": 1689741764000
    },
    {
        "time": 1689741766000,
        "value": 139.344,
        "_internal_originalTime": 1689741766000
    },
    {
        "time": 1689741768000,
        "value": 139.343,
        "_internal_originalTime": 1689741768000
    },
    {
        "time": 1689741771000,
        "value": 139.338,
        "_internal_originalTime": 1689741771000
    },
    {
        "time": 1689741773000,
        "value": 139.344,
        "_internal_originalTime": 1689741773000
    },
    {
        "time": 1689741775000,
        "value": 139.341,
        "_internal_originalTime": 1689741775000
    },
    {
        "time": 1689741777000,
        "value": 139.338,
        "_internal_originalTime": 1689741777000
    },
    {
        "time": 1689741779000,
        "value": 139.34,
        "_internal_originalTime": 1689741779000
    },
    {
        "time": 1689741781000,
        "value": 139.344,
        "_internal_originalTime": 1689741781000
    },
    {
        "time": 1689741782000,
        "value": 139.341,
        "_internal_originalTime": 1689741782000
    },
    {
        "time": 1689741784000,
        "value": 139.341,
        "_internal_originalTime": 1689741784000
    },
    {
        "time": 1689741786000,
        "value": 139.341,
        "_internal_originalTime": 1689741786000
    },
    {
        "time": 1689741788000,
        "value": 139.332,
        "_internal_originalTime": 1689741788000
    },
    {
        "time": 1689741790000,
        "value": 139.326,
        "_internal_originalTime": 1689741790000
    },
    {
        "time": 1689741791000,
        "value": 139.33,
        "_internal_originalTime": 1689741791000
    },
    {
        "time": 1689741793000,
        "value": 139.328,
        "_internal_originalTime": 1689741793000
    },
    {
        "time": 1689741794000,
        "value": 139.328,
        "_internal_originalTime": 1689741794000
    },
    {
        "time": 1689741796000,
        "value": 139.329,
        "_internal_originalTime": 1689741796000
    },
    {
        "time": 1689741797000,
        "value": 139.331,
        "_internal_originalTime": 1689741797000
    },
    {
        "time": 1689741799000,
        "value": 139.327,
        "_internal_originalTime": 1689741799000
    },
    {
        "time": 1689741801000,
        "value": 139.331,
        "_internal_originalTime": 1689741801000
    },
    {
        "time": 1689741802000,
        "value": 139.33,
        "_internal_originalTime": 1689741802000
    },
    {
        "time": 1689741803000,
        "value": 139.33,
        "_internal_originalTime": 1689741803000
    },
    {
        "time": 1689741806000,
        "value": 139.333,
        "_internal_originalTime": 1689741806000
    },
    {
        "time": 1689741807000,
        "value": 139.328,
        "_internal_originalTime": 1689741807000
    },
    {
        "time": 1689741809000,
        "value": 139.333,
        "_internal_originalTime": 1689741809000
    },
    {
        "time": 1689741811000,
        "value": 139.329,
        "_internal_originalTime": 1689741811000
    },
    {
        "time": 1689741813000,
        "value": 139.33,
        "_internal_originalTime": 1689741813000
    },
    {
        "time": 1689741814000,
        "value": 139.333,
        "_internal_originalTime": 1689741814000
    },
    {
        "time": 1689741816000,
        "value": 139.331,
        "_internal_originalTime": 1689741816000
    },
    {
        "time": 1689741818000,
        "value": 139.338,
        "_internal_originalTime": 1689741818000
    },
    {
        "time": 1689741819000,
        "value": 139.338,
        "_internal_originalTime": 1689741819000
    },
    {
        "time": 1689741820000,
        "value": 139.338,
        "_internal_originalTime": 1689741820000
    },
    {
        "time": 1689741822000,
        "value": 139.34,
        "_internal_originalTime": 1689741822000
    },
    {
        "time": 1689741824000,
        "value": 139.341,
        "_internal_originalTime": 1689741824000
    },
    {
        "time": 1689741827000,
        "value": 139.338,
        "_internal_originalTime": 1689741827000
    },
    {
        "time": 1689741828000,
        "value": 139.342,
        "_internal_originalTime": 1689741828000
    },
    {
        "time": 1689741830000,
        "value": 139.336,
        "_internal_originalTime": 1689741830000
    },
    {
        "time": 1689741831000,
        "value": 139.336,
        "_internal_originalTime": 1689741831000
    },
    {
        "time": 1689741833000,
        "value": 139.343,
        "_internal_originalTime": 1689741833000
    },
    {
        "time": 1689741836000,
        "value": 139.343,
        "_internal_originalTime": 1689741836000
    },
    {
        "time": 1689741838000,
        "value": 139.339,
        "_internal_originalTime": 1689741838000
    },
    {
        "time": 1689741839000,
        "value": 139.342,
        "_internal_originalTime": 1689741839000
    },
    {
        "time": 1689741841000,
        "value": 139.337,
        "_internal_originalTime": 1689741841000
    },
    {
        "time": 1689741842000,
        "value": 139.334,
        "_internal_originalTime": 1689741842000
    },
    {
        "time": 1689741845000,
        "value": 139.34,
        "_internal_originalTime": 1689741845000
    },
    {
        "time": 1689741847000,
        "value": 139.336,
        "_internal_originalTime": 1689741847000
    },
    {
        "time": 1689741849000,
        "value": 139.337,
        "_internal_originalTime": 1689741849000
    },
    {
        "time": 1689741851000,
        "value": 139.336,
        "_internal_originalTime": 1689741851000
    },
    {
        "time": 1689741853000,
        "value": 139.336,
        "_internal_originalTime": 1689741853000
    },
    {
        "time": 1689741854000,
        "value": 139.334,
        "_internal_originalTime": 1689741854000
    },
    {
        "time": 1689741855000,
        "value": 139.328,
        "_internal_originalTime": 1689741855000
    },
    {
        "time": 1689741857000,
        "value": 139.338,
        "_internal_originalTime": 1689741857000
    },
    {
        "time": 1689741858000,
        "value": 139.333,
        "_internal_originalTime": 1689741858000
    },
    {
        "time": 1689741860000,
        "value": 139.333,
        "_internal_originalTime": 1689741860000
    },
    {
        "time": 1689741862000,
        "value": 139.332,
        "_internal_originalTime": 1689741862000
    },
    {
        "time": 1689741863000,
        "value": 139.337,
        "_internal_originalTime": 1689741863000
    },
    {
        "time": 1689741864000,
        "value": 139.336,
        "_internal_originalTime": 1689741864000
    },
    {
        "time": 1689741866000,
        "value": 139.331,
        "_internal_originalTime": 1689741866000
    },
    {
        "time": 1689741868000,
        "value": 139.337,
        "_internal_originalTime": 1689741868000
    },
    {
        "time": 1689741869000,
        "value": 139.336,
        "_internal_originalTime": 1689741869000
    },
    {
        "time": 1689741870000,
        "value": 139.333,
        "_internal_originalTime": 1689741870000
    },
    {
        "time": 1689741871000,
        "value": 139.328,
        "_internal_originalTime": 1689741871000
    },
    {
        "time": 1689741873000,
        "value": 139.333,
        "_internal_originalTime": 1689741873000
    },
    {
        "time": 1689741875000,
        "value": 139.333,
        "_internal_originalTime": 1689741875000
    },
    {
        "time": 1689741876000,
        "value": 139.332,
        "_internal_originalTime": 1689741876000
    },
    {
        "time": 1689741879000,
        "value": 139.332,
        "_internal_originalTime": 1689741879000
    },
    {
        "time": 1689741880000,
        "value": 139.333,
        "_internal_originalTime": 1689741880000
    },
    {
        "time": 1689741882000,
        "value": 139.334,
        "_internal_originalTime": 1689741882000
    },
    {
        "time": 1689741884000,
        "value": 139.333,
        "_internal_originalTime": 1689741884000
    },
    {
        "time": 1689741890000,
        "value": 139.329,
        "_internal_originalTime": 1689741890000
    },
    {
        "time": 1689741891000,
        "value": 139.33,
        "_internal_originalTime": 1689741891000
    },
    {
        "time": 1689741894000,
        "value": 139.335,
        "_internal_originalTime": 1689741894000
    },
    {
        "time": 1689741896000,
        "value": 139.334,
        "_internal_originalTime": 1689741896000
    },
    {
        "time": 1689741897000,
        "value": 139.328,
        "_internal_originalTime": 1689741897000
    },
    {
        "time": 1689741899000,
        "value": 139.333,
        "_internal_originalTime": 1689741899000
    },
    {
        "time": 1689741900000,
        "value": 139.335,
        "_internal_originalTime": 1689741900000
    },
    {
        "time": 1689741902000,
        "value": 139.33,
        "_internal_originalTime": 1689741902000
    },
    {
        "time": 1689741905000,
        "value": 139.333,
        "_internal_originalTime": 1689741905000
    },
    {
        "time": 1689741907000,
        "value": 139.328,
        "_internal_originalTime": 1689741907000
    },
    {
        "time": 1689741908000,
        "value": 139.323,
        "_internal_originalTime": 1689741908000
    },
    {
        "time": 1689741910000,
        "value": 139.323,
        "_internal_originalTime": 1689741910000
    },
    {
        "time": 1689741913000,
        "value": 139.318,
        "_internal_originalTime": 1689741913000
    },
    {
        "time": 1689741914000,
        "value": 139.328,
        "_internal_originalTime": 1689741914000
    },
    {
        "time": 1689741915000,
        "value": 139.327,
        "_internal_originalTime": 1689741915000
    },
    {
        "time": 1689741917000,
        "value": 139.327,
        "_internal_originalTime": 1689741917000
    },
    {
        "time": 1689741918000,
        "value": 139.332,
        "_internal_originalTime": 1689741918000
    },
    {
        "time": 1689741920000,
        "value": 139.332,
        "_internal_originalTime": 1689741920000
    },
    {
        "time": 1689741921000,
        "value": 139.332,
        "_internal_originalTime": 1689741921000
    },
    {
        "time": 1689741924000,
        "value": 139.329,
        "_internal_originalTime": 1689741924000
    },
    {
        "time": 1689741925000,
        "value": 139.327,
        "_internal_originalTime": 1689741925000
    },
    {
        "time": 1689741926000,
        "value": 139.328,
        "_internal_originalTime": 1689741926000
    },
    {
        "time": 1689741927000,
        "value": 139.326,
        "_internal_originalTime": 1689741927000
    },
    {
        "time": 1689741929000,
        "value": 139.332,
        "_internal_originalTime": 1689741929000
    },
    {
        "time": 1689741930000,
        "value": 139.332,
        "_internal_originalTime": 1689741930000
    },
    {
        "time": 1689741932000,
        "value": 139.328,
        "_internal_originalTime": 1689741932000
    },
    {
        "time": 1689741933000,
        "value": 139.328,
        "_internal_originalTime": 1689741933000
    },
    {
        "time": 1689741935000,
        "value": 139.328,
        "_internal_originalTime": 1689741935000
    },
    {
        "time": 1689741937000,
        "value": 139.332,
        "_internal_originalTime": 1689741937000
    },
    {
        "time": 1689741939000,
        "value": 139.329,
        "_internal_originalTime": 1689741939000
    },
    {
        "time": 1689741940000,
        "value": 139.326,
        "_internal_originalTime": 1689741940000
    },
    {
        "time": 1689741942000,
        "value": 139.33,
        "_internal_originalTime": 1689741942000
    },
    {
        "time": 1689741944000,
        "value": 139.328,
        "_internal_originalTime": 1689741944000
    },
    {
        "time": 1689741945000,
        "value": 139.331,
        "_internal_originalTime": 1689741945000
    },
    {
        "time": 1689741947000,
        "value": 139.332,
        "_internal_originalTime": 1689741947000
    },
    {
        "time": 1689741948000,
        "value": 139.328,
        "_internal_originalTime": 1689741948000
    },
    {
        "time": 1689741951000,
        "value": 139.33,
        "_internal_originalTime": 1689741951000
    },
    {
        "time": 1689741953000,
        "value": 139.328,
        "_internal_originalTime": 1689741953000
    },
    {
        "time": 1689741956000,
        "value": 139.33,
        "_internal_originalTime": 1689741956000
    },
    {
        "time": 1689741958000,
        "value": 139.328,
        "_internal_originalTime": 1689741958000
    },
    {
        "time": 1689741960000,
        "value": 139.328,
        "_internal_originalTime": 1689741960000
    },
    {
        "time": 1689741961000,
        "value": 139.33,
        "_internal_originalTime": 1689741961000
    },
    {
        "time": 1689741963000,
        "value": 139.327,
        "_internal_originalTime": 1689741963000
    },
    {
        "time": 1689741964000,
        "value": 139.329,
        "_internal_originalTime": 1689741964000
    },
    {
        "time": 1689741965000,
        "value": 139.328,
        "_internal_originalTime": 1689741965000
    },
    {
        "time": 1689741968000,
        "value": 139.333,
        "_internal_originalTime": 1689741968000
    },
    {
        "time": 1689741970000,
        "value": 139.329,
        "_internal_originalTime": 1689741970000
    },
    {
        "time": 1689741971000,
        "value": 139.333,
        "_internal_originalTime": 1689741971000
    },
    {
        "time": 1689741974000,
        "value": 139.336,
        "_internal_originalTime": 1689741974000
    },
    {
        "time": 1689741975000,
        "value": 139.33,
        "_internal_originalTime": 1689741975000
    },
    {
        "time": 1689741976000,
        "value": 139.335,
        "_internal_originalTime": 1689741976000
    },
    {
        "time": 1689741979000,
        "value": 139.35,
        "_internal_originalTime": 1689741979000
    },
    {
        "time": 1689741980000,
        "value": 139.349,
        "_internal_originalTime": 1689741980000
    },
    {
        "time": 1689741985000,
        "value": 139.352,
        "_internal_originalTime": 1689741985000
    },
    {
        "time": 1689741986000,
        "value": 139.352,
        "_internal_originalTime": 1689741986000
    },
    {
        "time": 1689741987000,
        "value": 139.35,
        "_internal_originalTime": 1689741987000
    },
    {
        "time": 1689741991000,
        "value": 139.345,
        "_internal_originalTime": 1689741991000
    },
    {
        "time": 1689741993000,
        "value": 139.346,
        "_internal_originalTime": 1689741993000
    },
    {
        "time": 1689741994000,
        "value": 139.346,
        "_internal_originalTime": 1689741994000
    },
    {
        "time": 1689741996000,
        "value": 139.352,
        "_internal_originalTime": 1689741996000
    },
    {
        "time": 1689741998000,
        "value": 139.348,
        "_internal_originalTime": 1689741998000
    },
    {
        "time": 1689741999000,
        "value": 139.353,
        "_internal_originalTime": 1689741999000
    },
    {
        "time": 1689742001000,
        "value": 139.354,
        "_internal_originalTime": 1689742001000
    },
    {
        "time": 1689742002000,
        "value": 139.355,
        "_internal_originalTime": 1689742002000
    },
    {
        "time": 1689742003000,
        "value": 139.354,
        "_internal_originalTime": 1689742003000
    },
    {
        "time": 1689742005000,
        "value": 139.35,
        "_internal_originalTime": 1689742005000
    },
    {
        "time": 1689742008000,
        "value": 139.357,
        "_internal_originalTime": 1689742008000
    },
    {
        "time": 1689742011000,
        "value": 139.357,
        "_internal_originalTime": 1689742011000
    },
    {
        "time": 1689742012000,
        "value": 139.358,
        "_internal_originalTime": 1689742012000
    },
    {
        "time": 1689742013000,
        "value": 139.359,
        "_internal_originalTime": 1689742013000
    },
    {
        "time": 1689742016000,
        "value": 139.359,
        "_internal_originalTime": 1689742016000
    },
    {
        "time": 1689742017000,
        "value": 139.354,
        "_internal_originalTime": 1689742017000
    },
    {
        "time": 1689742019000,
        "value": 139.361,
        "_internal_originalTime": 1689742019000
    },
    {
        "time": 1689742020000,
        "value": 139.359,
        "_internal_originalTime": 1689742020000
    },
    {
        "time": 1689742021000,
        "value": 139.34,
        "_internal_originalTime": 1689742021000
    },
    {
        "time": 1689742023000,
        "value": 139.355,
        "_internal_originalTime": 1689742023000
    },
    {
        "time": 1689742025000,
        "value": 139.359,
        "_internal_originalTime": 1689742025000
    },
    {
        "time": 1689742026000,
        "value": 139.354,
        "_internal_originalTime": 1689742026000
    },
    {
        "time": 1689742028000,
        "value": 139.354,
        "_internal_originalTime": 1689742028000
    },
    {
        "time": 1689742032000,
        "value": 139.358,
        "_internal_originalTime": 1689742032000
    },
    {
        "time": 1689742034000,
        "value": 139.358,
        "_internal_originalTime": 1689742034000
    },
    {
        "time": 1689742035000,
        "value": 139.357,
        "_internal_originalTime": 1689742035000
    },
    {
        "time": 1689742037000,
        "value": 139.357,
        "_internal_originalTime": 1689742037000
    },
    {
        "time": 1689742038000,
        "value": 139.358,
        "_internal_originalTime": 1689742038000
    },
    {
        "time": 1689742040000,
        "value": 139.356,
        "_internal_originalTime": 1689742040000
    },
    {
        "time": 1689742041000,
        "value": 139.357,
        "_internal_originalTime": 1689742041000
    },
    {
        "time": 1689742044000,
        "value": 139.355,
        "_internal_originalTime": 1689742044000
    },
    {
        "time": 1689742046000,
        "value": 139.357,
        "_internal_originalTime": 1689742046000
    },
    {
        "time": 1689742047000,
        "value": 139.354,
        "_internal_originalTime": 1689742047000
    },
    {
        "time": 1689742051000,
        "value": 139.357,
        "_internal_originalTime": 1689742051000
    },
    {
        "time": 1689742053000,
        "value": 139.358,
        "_internal_originalTime": 1689742053000
    },
    {
        "time": 1689742054000,
        "value": 139.357,
        "_internal_originalTime": 1689742054000
    },
    {
        "time": 1689742058000,
        "value": 139.357,
        "_internal_originalTime": 1689742058000
    },
    {
        "time": 1689742060000,
        "value": 139.352,
        "_internal_originalTime": 1689742060000
    },
    {
        "time": 1689742062000,
        "value": 139.352,
        "_internal_originalTime": 1689742062000
    },
    {
        "time": 1689742065000,
        "value": 139.35,
        "_internal_originalTime": 1689742065000
    },
    {
        "time": 1689742066000,
        "value": 139.353,
        "_internal_originalTime": 1689742066000
    },
    {
        "time": 1689742068000,
        "value": 139.36,
        "_internal_originalTime": 1689742068000
    },
    {
        "time": 1689742070000,
        "value": 139.356,
        "_internal_originalTime": 1689742070000
    },
    {
        "time": 1689742074000,
        "value": 139.356,
        "_internal_originalTime": 1689742074000
    },
    {
        "time": 1689742075000,
        "value": 139.358,
        "_internal_originalTime": 1689742075000
    },
    {
        "time": 1689742076000,
        "value": 139.356,
        "_internal_originalTime": 1689742076000
    },
    {
        "time": 1689742079000,
        "value": 139.36,
        "_internal_originalTime": 1689742079000
    },
    {
        "time": 1689742080000,
        "value": 139.358,
        "_internal_originalTime": 1689742080000
    },
    {
        "time": 1689742081000,
        "value": 139.368,
        "_internal_originalTime": 1689742081000
    },
    {
        "time": 1689742082000,
        "value": 139.37,
        "_internal_originalTime": 1689742082000
    },
    {
        "time": 1689742084000,
        "value": 139.37,
        "_internal_originalTime": 1689742084000
    },
    {
        "time": 1689742086000,
        "value": 139.364,
        "_internal_originalTime": 1689742086000
    },
    {
        "time": 1689742088000,
        "value": 139.367,
        "_internal_originalTime": 1689742088000
    },
    {
        "time": 1689742091000,
        "value": 139.364,
        "_internal_originalTime": 1689742091000
    },
    {
        "time": 1689742093000,
        "value": 139.366,
        "_internal_originalTime": 1689742093000
    },
    {
        "time": 1689742095000,
        "value": 139.373,
        "_internal_originalTime": 1689742095000
    },
    {
        "time": 1689742097000,
        "value": 139.37,
        "_internal_originalTime": 1689742097000
    },
    {
        "time": 1689742098000,
        "value": 139.368,
        "_internal_originalTime": 1689742098000
    },
    {
        "time": 1689742099000,
        "value": 139.369,
        "_internal_originalTime": 1689742099000
    },
    {
        "time": 1689742101000,
        "value": 139.368,
        "_internal_originalTime": 1689742101000
    },
    {
        "time": 1689742103000,
        "value": 139.369,
        "_internal_originalTime": 1689742103000
    },
    {
        "time": 1689742104000,
        "value": 139.368,
        "_internal_originalTime": 1689742104000
    },
    {
        "time": 1689742105000,
        "value": 139.369,
        "_internal_originalTime": 1689742105000
    },
    {
        "time": 1689742106000,
        "value": 139.368,
        "_internal_originalTime": 1689742106000
    },
    {
        "time": 1689742107000,
        "value": 139.365,
        "_internal_originalTime": 1689742107000
    },
    {
        "time": 1689742110000,
        "value": 139.372,
        "_internal_originalTime": 1689742110000
    },
    {
        "time": 1689742112000,
        "value": 139.371,
        "_internal_originalTime": 1689742112000
    },
    {
        "time": 1689742113000,
        "value": 139.367,
        "_internal_originalTime": 1689742113000
    },
    {
        "time": 1689742115000,
        "value": 139.37,
        "_internal_originalTime": 1689742115000
    },
    {
        "time": 1689742117000,
        "value": 139.369,
        "_internal_originalTime": 1689742117000
    },
    {
        "time": 1689742118000,
        "value": 139.367,
        "_internal_originalTime": 1689742118000
    },
    {
        "time": 1689742120000,
        "value": 139.37,
        "_internal_originalTime": 1689742120000
    },
    {
        "time": 1689742122000,
        "value": 139.37,
        "_internal_originalTime": 1689742122000
    },
    {
        "time": 1689742124000,
        "value": 139.369,
        "_internal_originalTime": 1689742124000
    },
    {
        "time": 1689742125000,
        "value": 139.374,
        "_internal_originalTime": 1689742125000
    },
    {
        "time": 1689742126000,
        "value": 139.38,
        "_internal_originalTime": 1689742126000
    },
    {
        "time": 1689742128000,
        "value": 139.389,
        "_internal_originalTime": 1689742128000
    },
    {
        "time": 1689742130000,
        "value": 139.389,
        "_internal_originalTime": 1689742130000
    },
    {
        "time": 1689742132000,
        "value": 139.391,
        "_internal_originalTime": 1689742132000
    },
    {
        "time": 1689742133000,
        "value": 139.39,
        "_internal_originalTime": 1689742133000
    },
    {
        "time": 1689742135000,
        "value": 139.405,
        "_internal_originalTime": 1689742135000
    },
    {
        "time": 1689742137000,
        "value": 139.412,
        "_internal_originalTime": 1689742137000
    },
    {
        "time": 1689742139000,
        "value": 139.409,
        "_internal_originalTime": 1689742139000
    },
    {
        "time": 1689742140000,
        "value": 139.4,
        "_internal_originalTime": 1689742140000
    },
    {
        "time": 1689742141000,
        "value": 139.4,
        "_internal_originalTime": 1689742141000
    },
    {
        "time": 1689742142000,
        "value": 139.4,
        "_internal_originalTime": 1689742142000
    },
    {
        "time": 1689742144000,
        "value": 139.398,
        "_internal_originalTime": 1689742144000
    },
    {
        "time": 1689742146000,
        "value": 139.397,
        "_internal_originalTime": 1689742146000
    },
    {
        "time": 1689742147000,
        "value": 139.4,
        "_internal_originalTime": 1689742147000
    },
    {
        "time": 1689742148000,
        "value": 139.4,
        "_internal_originalTime": 1689742148000
    },
    {
        "time": 1689742151000,
        "value": 139.396,
        "_internal_originalTime": 1689742151000
    },
    {
        "time": 1689742153000,
        "value": 139.396,
        "_internal_originalTime": 1689742153000
    },
    {
        "time": 1689742156000,
        "value": 139.401,
        "_internal_originalTime": 1689742156000
    },
    {
        "time": 1689742159000,
        "value": 139.401,
        "_internal_originalTime": 1689742159000
    },
    {
        "time": 1689742162000,
        "value": 139.398,
        "_internal_originalTime": 1689742162000
    },
    {
        "time": 1689742164000,
        "value": 139.399,
        "_internal_originalTime": 1689742164000
    },
    {
        "time": 1689742166000,
        "value": 139.399,
        "_internal_originalTime": 1689742166000
    },
    {
        "time": 1689742167000,
        "value": 139.395,
        "_internal_originalTime": 1689742167000
    },
    {
        "time": 1689742169000,
        "value": 139.4,
        "_internal_originalTime": 1689742169000
    },
    {
        "time": 1689742171000,
        "value": 139.395,
        "_internal_originalTime": 1689742171000
    },
    {
        "time": 1689742173000,
        "value": 139.398,
        "_internal_originalTime": 1689742173000
    },
    {
        "time": 1689742174000,
        "value": 139.398,
        "_internal_originalTime": 1689742174000
    },
    {
        "time": 1689742176000,
        "value": 139.402,
        "_internal_originalTime": 1689742176000
    },
    {
        "time": 1689742178000,
        "value": 139.4,
        "_internal_originalTime": 1689742178000
    },
    {
        "time": 1689742180000,
        "value": 139.409,
        "_internal_originalTime": 1689742180000
    },
    {
        "time": 1689742181000,
        "value": 139.409,
        "_internal_originalTime": 1689742181000
    },
    {
        "time": 1689742182000,
        "value": 139.405,
        "_internal_originalTime": 1689742182000
    },
    {
        "time": 1689742184000,
        "value": 139.408,
        "_internal_originalTime": 1689742184000
    },
    {
        "time": 1689742185000,
        "value": 139.404,
        "_internal_originalTime": 1689742185000
    },
    {
        "time": 1689742187000,
        "value": 139.407,
        "_internal_originalTime": 1689742187000
    },
    {
        "time": 1689742189000,
        "value": 139.405,
        "_internal_originalTime": 1689742189000
    },
    {
        "time": 1689742191000,
        "value": 139.404,
        "_internal_originalTime": 1689742191000
    },
    {
        "time": 1689742193000,
        "value": 139.4,
        "_internal_originalTime": 1689742193000
    },
    {
        "time": 1689742194000,
        "value": 139.401,
        "_internal_originalTime": 1689742194000
    },
    {
        "time": 1689742197000,
        "value": 139.399,
        "_internal_originalTime": 1689742197000
    },
    {
        "time": 1689742198000,
        "value": 139.404,
        "_internal_originalTime": 1689742198000
    },
    {
        "time": 1689742199000,
        "value": 139.404,
        "_internal_originalTime": 1689742199000
    },
    {
        "time": 1689742200000,
        "value": 139.41,
        "_internal_originalTime": 1689742200000
    },
    {
        "time": 1689742202000,
        "value": 139.41,
        "_internal_originalTime": 1689742202000
    },
    {
        "time": 1689742203000,
        "value": 139.408,
        "_internal_originalTime": 1689742203000
    },
    {
        "time": 1689742205000,
        "value": 139.42,
        "_internal_originalTime": 1689742205000
    },
    {
        "time": 1689742206000,
        "value": 139.416,
        "_internal_originalTime": 1689742206000
    },
    {
        "time": 1689742208000,
        "value": 139.418,
        "_internal_originalTime": 1689742208000
    },
    {
        "time": 1689742210000,
        "value": 139.417,
        "_internal_originalTime": 1689742210000
    },
    {
        "time": 1689742213000,
        "value": 139.419,
        "_internal_originalTime": 1689742213000
    },
    {
        "time": 1689742214000,
        "value": 139.42,
        "_internal_originalTime": 1689742214000
    },
    {
        "time": 1689742216000,
        "value": 139.419,
        "_internal_originalTime": 1689742216000
    },
    {
        "time": 1689742217000,
        "value": 139.412,
        "_internal_originalTime": 1689742217000
    },
    {
        "time": 1689742219000,
        "value": 139.415,
        "_internal_originalTime": 1689742219000
    },
    {
        "time": 1689742220000,
        "value": 139.408,
        "_internal_originalTime": 1689742220000
    },
    {
        "time": 1689742222000,
        "value": 139.394,
        "_internal_originalTime": 1689742222000
    },
    {
        "time": 1689742224000,
        "value": 139.396,
        "_internal_originalTime": 1689742224000
    },
    {
        "time": 1689742225000,
        "value": 139.399,
        "_internal_originalTime": 1689742225000
    },
    {
        "time": 1689742227000,
        "value": 139.397,
        "_internal_originalTime": 1689742227000
    },
    {
        "time": 1689742228000,
        "value": 139.393,
        "_internal_originalTime": 1689742228000
    },
    {
        "time": 1689742230000,
        "value": 139.392,
        "_internal_originalTime": 1689742230000
    },
    {
        "time": 1689742232000,
        "value": 139.396,
        "_internal_originalTime": 1689742232000
    },
    {
        "time": 1689742234000,
        "value": 139.394,
        "_internal_originalTime": 1689742234000
    },
    {
        "time": 1689742235000,
        "value": 139.393,
        "_internal_originalTime": 1689742235000
    },
    {
        "time": 1689742237000,
        "value": 139.399,
        "_internal_originalTime": 1689742237000
    },
    {
        "time": 1689742238000,
        "value": 139.395,
        "_internal_originalTime": 1689742238000
    },
    {
        "time": 1689742240000,
        "value": 139.399,
        "_internal_originalTime": 1689742240000
    },
    {
        "time": 1689742242000,
        "value": 139.399,
        "_internal_originalTime": 1689742242000
    },
    {
        "time": 1689742243000,
        "value": 139.398,
        "_internal_originalTime": 1689742243000
    },
    {
        "time": 1689742245000,
        "value": 139.395,
        "_internal_originalTime": 1689742245000
    },
    {
        "time": 1689742247000,
        "value": 139.399,
        "_internal_originalTime": 1689742247000
    },
    {
        "time": 1689742248000,
        "value": 139.399,
        "_internal_originalTime": 1689742248000
    },
    {
        "time": 1689742251000,
        "value": 139.399,
        "_internal_originalTime": 1689742251000
    },
    {
        "time": 1689742252000,
        "value": 139.39,
        "_internal_originalTime": 1689742252000
    },
    {
        "time": 1689742254000,
        "value": 139.388,
        "_internal_originalTime": 1689742254000
    },
    {
        "time": 1689742256000,
        "value": 139.38,
        "_internal_originalTime": 1689742256000
    },
    {
        "time": 1689742257000,
        "value": 139.381,
        "_internal_originalTime": 1689742257000
    },
    {
        "time": 1689742259000,
        "value": 139.375,
        "_internal_originalTime": 1689742259000
    },
    {
        "time": 1689742260000,
        "value": 139.377,
        "_internal_originalTime": 1689742260000
    },
    {
        "time": 1689742262000,
        "value": 139.38,
        "_internal_originalTime": 1689742262000
    },
    {
        "time": 1689742263000,
        "value": 139.383,
        "_internal_originalTime": 1689742263000
    },
    {
        "time": 1689742265000,
        "value": 139.387,
        "_internal_originalTime": 1689742265000
    },
    {
        "time": 1689742267000,
        "value": 139.386,
        "_internal_originalTime": 1689742267000
    },
    {
        "time": 1689742268000,
        "value": 139.389,
        "_internal_originalTime": 1689742268000
    },
    {
        "time": 1689742270000,
        "value": 139.389,
        "_internal_originalTime": 1689742270000
    },
    {
        "time": 1689742271000,
        "value": 139.39,
        "_internal_originalTime": 1689742271000
    },
    {
        "time": 1689742273000,
        "value": 139.391,
        "_internal_originalTime": 1689742273000
    },
    {
        "time": 1689742275000,
        "value": 139.39,
        "_internal_originalTime": 1689742275000
    },
    {
        "time": 1689742277000,
        "value": 139.389,
        "_internal_originalTime": 1689742277000
    },
    {
        "time": 1689742278000,
        "value": 139.381,
        "_internal_originalTime": 1689742278000
    },
    {
        "time": 1689742280000,
        "value": 139.386,
        "_internal_originalTime": 1689742280000
    },
    {
        "time": 1689742283000,
        "value": 139.379,
        "_internal_originalTime": 1689742283000
    },
    {
        "time": 1689742284000,
        "value": 139.38,
        "_internal_originalTime": 1689742284000
    },
    {
        "time": 1689742285000,
        "value": 139.374,
        "_internal_originalTime": 1689742285000
    },
    {
        "time": 1689742288000,
        "value": 139.368,
        "_internal_originalTime": 1689742288000
    },
    {
        "time": 1689742289000,
        "value": 139.366,
        "_internal_originalTime": 1689742289000
    },
    {
        "time": 1689742290000,
        "value": 139.373,
        "_internal_originalTime": 1689742290000
    },
    {
        "time": 1689742291000,
        "value": 139.368,
        "_internal_originalTime": 1689742291000
    },
    {
        "time": 1689742293000,
        "value": 139.367,
        "_internal_originalTime": 1689742293000
    },
    {
        "time": 1689742296000,
        "value": 139.36,
        "_internal_originalTime": 1689742296000
    },
    {
        "time": 1689742299000,
        "value": 139.363,
        "_internal_originalTime": 1689742299000
    },
    {
        "time": 1689742302000,
        "value": 139.362,
        "_internal_originalTime": 1689742302000
    },
    {
        "time": 1689742304000,
        "value": 139.362,
        "_internal_originalTime": 1689742304000
    },
    {
        "time": 1689742305000,
        "value": 139.36,
        "_internal_originalTime": 1689742305000
    },
    {
        "time": 1689742306000,
        "value": 139.362,
        "_internal_originalTime": 1689742306000
    },
    {
        "time": 1689742309000,
        "value": 139.361,
        "_internal_originalTime": 1689742309000
    },
    {
        "time": 1689742310000,
        "value": 139.36,
        "_internal_originalTime": 1689742310000
    },
    {
        "time": 1689742311000,
        "value": 139.361,
        "_internal_originalTime": 1689742311000
    },
    {
        "time": 1689742316000,
        "value": 139.362,
        "_internal_originalTime": 1689742316000
    },
    {
        "time": 1689742318000,
        "value": 139.361,
        "_internal_originalTime": 1689742318000
    },
    {
        "time": 1689742319000,
        "value": 139.362,
        "_internal_originalTime": 1689742319000
    },
    {
        "time": 1689742320000,
        "value": 139.367,
        "_internal_originalTime": 1689742320000
    },
    {
        "time": 1689742321000,
        "value": 139.364,
        "_internal_originalTime": 1689742321000
    },
    {
        "time": 1689742322000,
        "value": 139.363,
        "_internal_originalTime": 1689742322000
    },
    {
        "time": 1689742325000,
        "value": 139.363,
        "_internal_originalTime": 1689742325000
    },
    {
        "time": 1689742327000,
        "value": 139.362,
        "_internal_originalTime": 1689742327000
    },
    {
        "time": 1689742328000,
        "value": 139.363,
        "_internal_originalTime": 1689742328000
    },
    {
        "time": 1689742331000,
        "value": 139.364,
        "_internal_originalTime": 1689742331000
    },
    {
        "time": 1689742332000,
        "value": 139.369,
        "_internal_originalTime": 1689742332000
    },
    {
        "time": 1689742333000,
        "value": 139.37,
        "_internal_originalTime": 1689742333000
    },
    {
        "time": 1689742334000,
        "value": 139.371,
        "_internal_originalTime": 1689742334000
    },
    {
        "time": 1689742338000,
        "value": 139.371,
        "_internal_originalTime": 1689742338000
    },
    {
        "time": 1689742340000,
        "value": 139.369,
        "_internal_originalTime": 1689742340000
    },
    {
        "time": 1689742342000,
        "value": 139.371,
        "_internal_originalTime": 1689742342000
    },
    {
        "time": 1689742343000,
        "value": 139.367,
        "_internal_originalTime": 1689742343000
    },
    {
        "time": 1689742345000,
        "value": 139.372,
        "_internal_originalTime": 1689742345000
    },
    {
        "time": 1689742346000,
        "value": 139.368,
        "_internal_originalTime": 1689742346000
    },
    {
        "time": 1689742348000,
        "value": 139.366,
        "_internal_originalTime": 1689742348000
    },
    {
        "time": 1689742349000,
        "value": 139.372,
        "_internal_originalTime": 1689742349000
    },
    {
        "time": 1689742351000,
        "value": 139.374,
        "_internal_originalTime": 1689742351000
    },
    {
        "time": 1689742352000,
        "value": 139.376,
        "_internal_originalTime": 1689742352000
    },
    {
        "time": 1689742354000,
        "value": 139.378,
        "_internal_originalTime": 1689742354000
    },
    {
        "time": 1689742356000,
        "value": 139.385,
        "_internal_originalTime": 1689742356000
    },
    {
        "time": 1689742357000,
        "value": 139.384,
        "_internal_originalTime": 1689742357000
    },
    {
        "time": 1689742359000,
        "value": 139.383,
        "_internal_originalTime": 1689742359000
    },
    {
        "time": 1689742361000,
        "value": 139.379,
        "_internal_originalTime": 1689742361000
    },
    {
        "time": 1689742362000,
        "value": 139.38,
        "_internal_originalTime": 1689742362000
    },
    {
        "time": 1689742364000,
        "value": 139.379,
        "_internal_originalTime": 1689742364000
    },
    {
        "time": 1689742365000,
        "value": 139.378,
        "_internal_originalTime": 1689742365000
    },
    {
        "time": 1689742367000,
        "value": 139.386,
        "_internal_originalTime": 1689742367000
    },
    {
        "time": 1689742371000,
        "value": 139.382,
        "_internal_originalTime": 1689742371000
    },
    {
        "time": 1689742372000,
        "value": 139.386,
        "_internal_originalTime": 1689742372000
    },
    {
        "time": 1689742374000,
        "value": 139.386,
        "_internal_originalTime": 1689742374000
    },
    {
        "time": 1689742376000,
        "value": 139.38,
        "_internal_originalTime": 1689742376000
    },
    {
        "time": 1689742378000,
        "value": 139.378,
        "_internal_originalTime": 1689742378000
    },
    {
        "time": 1689742379000,
        "value": 139.38,
        "_internal_originalTime": 1689742379000
    },
    {
        "time": 1689742380000,
        "value": 139.36,
        "_internal_originalTime": 1689742380000
    },
    {
        "time": 1689742381000,
        "value": 139.382,
        "_internal_originalTime": 1689742381000
    },
    {
        "time": 1689742383000,
        "value": 139.384,
        "_internal_originalTime": 1689742383000
    },
    {
        "time": 1689742384000,
        "value": 139.379,
        "_internal_originalTime": 1689742384000
    },
    {
        "time": 1689742386000,
        "value": 139.378,
        "_internal_originalTime": 1689742386000
    },
    {
        "time": 1689742388000,
        "value": 139.38,
        "_internal_originalTime": 1689742388000
    },
    {
        "time": 1689742390000,
        "value": 139.379,
        "_internal_originalTime": 1689742390000
    },
    {
        "time": 1689742392000,
        "value": 139.384,
        "_internal_originalTime": 1689742392000
    },
    {
        "time": 1689742393000,
        "value": 139.38,
        "_internal_originalTime": 1689742393000
    },
    {
        "time": 1689742396000,
        "value": 139.384,
        "_internal_originalTime": 1689742396000
    },
    {
        "time": 1689742397000,
        "value": 139.381,
        "_internal_originalTime": 1689742397000
    },
    {
        "time": 1689742398000,
        "value": 139.389,
        "_internal_originalTime": 1689742398000
    },
    {
        "time": 1689742399000,
        "value": 139.389,
        "_internal_originalTime": 1689742399000
    },
    {
        "time": 1689742402000,
        "value": 139.389,
        "_internal_originalTime": 1689742402000
    },
    {
        "time": 1689742404000,
        "value": 139.389,
        "_internal_originalTime": 1689742404000
    },
    {
        "time": 1689742405000,
        "value": 139.39,
        "_internal_originalTime": 1689742405000
    },
    {
        "time": 1689742407000,
        "value": 139.388,
        "_internal_originalTime": 1689742407000
    },
    {
        "time": 1689742408000,
        "value": 139.383,
        "_internal_originalTime": 1689742408000
    },
    {
        "time": 1689742410000,
        "value": 139.388,
        "_internal_originalTime": 1689742410000
    },
    {
        "time": 1689742412000,
        "value": 139.388,
        "_internal_originalTime": 1689742412000
    },
    {
        "time": 1689742414000,
        "value": 139.39,
        "_internal_originalTime": 1689742414000
    },
    {
        "time": 1689742416000,
        "value": 139.39,
        "_internal_originalTime": 1689742416000
    },
    {
        "time": 1689742418000,
        "value": 139.39,
        "_internal_originalTime": 1689742418000
    },
    {
        "time": 1689742419000,
        "value": 139.39,
        "_internal_originalTime": 1689742419000
    },
    {
        "time": 1689742421000,
        "value": 139.386,
        "_internal_originalTime": 1689742421000
    },
    {
        "time": 1689742422000,
        "value": 139.39,
        "_internal_originalTime": 1689742422000
    },
    {
        "time": 1689742427000,
        "value": 139.391,
        "_internal_originalTime": 1689742427000
    },
    {
        "time": 1689742428000,
        "value": 139.389,
        "_internal_originalTime": 1689742428000
    },
    {
        "time": 1689742430000,
        "value": 139.39,
        "_internal_originalTime": 1689742430000
    },
    {
        "time": 1689742434000,
        "value": 139.387,
        "_internal_originalTime": 1689742434000
    },
    {
        "time": 1689742435000,
        "value": 139.39,
        "_internal_originalTime": 1689742435000
    },
    {
        "time": 1689742438000,
        "value": 139.385,
        "_internal_originalTime": 1689742438000
    },
    {
        "time": 1689742441000,
        "value": 139.37,
        "_internal_originalTime": 1689742441000
    },
    {
        "time": 1689742442000,
        "value": 139.394,
        "_internal_originalTime": 1689742442000
    },
    {
        "time": 1689742444000,
        "value": 139.393,
        "_internal_originalTime": 1689742444000
    },
    {
        "time": 1689742445000,
        "value": 139.391,
        "_internal_originalTime": 1689742445000
    },
    {
        "time": 1689742446000,
        "value": 139.392,
        "_internal_originalTime": 1689742446000
    },
    {
        "time": 1689742448000,
        "value": 139.39,
        "_internal_originalTime": 1689742448000
    },
    {
        "time": 1689742453000,
        "value": 139.39,
        "_internal_originalTime": 1689742453000
    },
    {
        "time": 1689742454000,
        "value": 139.391,
        "_internal_originalTime": 1689742454000
    },
    {
        "time": 1689742456000,
        "value": 139.391,
        "_internal_originalTime": 1689742456000
    },
    {
        "time": 1689742459000,
        "value": 139.387,
        "_internal_originalTime": 1689742459000
    },
    {
        "time": 1689742461000,
        "value": 139.391,
        "_internal_originalTime": 1689742461000
    },
    {
        "time": 1689742462000,
        "value": 139.392,
        "_internal_originalTime": 1689742462000
    },
    {
        "time": 1689742463000,
        "value": 139.392,
        "_internal_originalTime": 1689742463000
    },
    {
        "time": 1689742467000,
        "value": 139.387,
        "_internal_originalTime": 1689742467000
    },
    {
        "time": 1689742468000,
        "value": 139.385,
        "_internal_originalTime": 1689742468000
    },
    {
        "time": 1689742471000,
        "value": 139.389,
        "_internal_originalTime": 1689742471000
    },
    {
        "time": 1689742474000,
        "value": 139.39,
        "_internal_originalTime": 1689742474000
    },
    {
        "time": 1689742477000,
        "value": 139.39,
        "_internal_originalTime": 1689742477000
    },
    {
        "time": 1689742478000,
        "value": 139.389,
        "_internal_originalTime": 1689742478000
    },
    {
        "time": 1689742480000,
        "value": 139.384,
        "_internal_originalTime": 1689742480000
    },
    {
        "time": 1689742483000,
        "value": 139.38,
        "_internal_originalTime": 1689742483000
    },
    {
        "time": 1689742484000,
        "value": 139.381,
        "_internal_originalTime": 1689742484000
    },
    {
        "time": 1689742485000,
        "value": 139.381,
        "_internal_originalTime": 1689742485000
    },
    {
        "time": 1689742487000,
        "value": 139.381,
        "_internal_originalTime": 1689742487000
    },
    {
        "time": 1689742488000,
        "value": 139.376,
        "_internal_originalTime": 1689742488000
    },
    {
        "time": 1689742491000,
        "value": 139.382,
        "_internal_originalTime": 1689742491000
    },
    {
        "time": 1689742492000,
        "value": 139.383,
        "_internal_originalTime": 1689742492000
    },
    {
        "time": 1689742494000,
        "value": 139.381,
        "_internal_originalTime": 1689742494000
    },
    {
        "time": 1689742496000,
        "value": 139.382,
        "_internal_originalTime": 1689742496000
    },
    {
        "time": 1689742498000,
        "value": 139.377,
        "_internal_originalTime": 1689742498000
    },
    {
        "time": 1689742499000,
        "value": 139.391,
        "_internal_originalTime": 1689742499000
    },
    {
        "time": 1689742501000,
        "value": 139.402,
        "_internal_originalTime": 1689742501000
    },
    {
        "time": 1689742503000,
        "value": 139.409,
        "_internal_originalTime": 1689742503000
    },
    {
        "time": 1689742505000,
        "value": 139.413,
        "_internal_originalTime": 1689742505000
    },
    {
        "time": 1689742507000,
        "value": 139.414,
        "_internal_originalTime": 1689742507000
    },
    {
        "time": 1689742508000,
        "value": 139.417,
        "_internal_originalTime": 1689742508000
    },
    {
        "time": 1689742510000,
        "value": 139.414,
        "_internal_originalTime": 1689742510000
    },
    {
        "time": 1689742512000,
        "value": 139.409,
        "_internal_originalTime": 1689742512000
    },
    {
        "time": 1689742515000,
        "value": 139.405,
        "_internal_originalTime": 1689742515000
    },
    {
        "time": 1689742517000,
        "value": 139.41,
        "_internal_originalTime": 1689742517000
    },
    {
        "time": 1689742519000,
        "value": 139.41,
        "_internal_originalTime": 1689742519000
    },
    {
        "time": 1689742521000,
        "value": 139.41,
        "_internal_originalTime": 1689742521000
    },
    {
        "time": 1689742522000,
        "value": 139.418,
        "_internal_originalTime": 1689742522000
    },
    {
        "time": 1689742523000,
        "value": 139.418,
        "_internal_originalTime": 1689742523000
    },
    {
        "time": 1689742525000,
        "value": 139.415,
        "_internal_originalTime": 1689742525000
    },
    {
        "time": 1689742526000,
        "value": 139.414,
        "_internal_originalTime": 1689742526000
    },
    {
        "time": 1689742528000,
        "value": 139.408,
        "_internal_originalTime": 1689742528000
    },
    {
        "time": 1689742529000,
        "value": 139.408,
        "_internal_originalTime": 1689742529000
    },
    {
        "time": 1689742530000,
        "value": 139.405,
        "_internal_originalTime": 1689742530000
    },
    {
        "time": 1689742532000,
        "value": 139.406,
        "_internal_originalTime": 1689742532000
    },
    {
        "time": 1689742534000,
        "value": 139.407,
        "_internal_originalTime": 1689742534000
    },
    {
        "time": 1689742536000,
        "value": 139.411,
        "_internal_originalTime": 1689742536000
    },
    {
        "time": 1689742537000,
        "value": 139.408,
        "_internal_originalTime": 1689742537000
    },
    {
        "time": 1689742539000,
        "value": 139.405,
        "_internal_originalTime": 1689742539000
    },
    {
        "time": 1689742541000,
        "value": 139.407,
        "_internal_originalTime": 1689742541000
    },
    {
        "time": 1689742542000,
        "value": 139.411,
        "_internal_originalTime": 1689742542000
    },
    {
        "time": 1689742544000,
        "value": 139.405,
        "_internal_originalTime": 1689742544000
    },
    {
        "time": 1689742545000,
        "value": 139.404,
        "_internal_originalTime": 1689742545000
    },
    {
        "time": 1689742546000,
        "value": 139.405,
        "_internal_originalTime": 1689742546000
    },
    {
        "time": 1689742550000,
        "value": 139.4,
        "_internal_originalTime": 1689742550000
    },
    {
        "time": 1689742551000,
        "value": 139.404,
        "_internal_originalTime": 1689742551000
    },
    {
        "time": 1689742554000,
        "value": 139.404,
        "_internal_originalTime": 1689742554000
    },
    {
        "time": 1689742556000,
        "value": 139.404,
        "_internal_originalTime": 1689742556000
    },
    {
        "time": 1689742557000,
        "value": 139.4,
        "_internal_originalTime": 1689742557000
    },
    {
        "time": 1689742558000,
        "value": 139.404,
        "_internal_originalTime": 1689742558000
    },
    {
        "time": 1689742560000,
        "value": 139.402,
        "_internal_originalTime": 1689742560000
    },
    {
        "time": 1689742561000,
        "value": 139.401,
        "_internal_originalTime": 1689742561000
    },
    {
        "time": 1689742562000,
        "value": 139.403,
        "_internal_originalTime": 1689742562000
    },
    {
        "time": 1689742568000,
        "value": 139.398,
        "_internal_originalTime": 1689742568000
    },
    {
        "time": 1689742570000,
        "value": 139.403,
        "_internal_originalTime": 1689742570000
    },
    {
        "time": 1689742572000,
        "value": 139.394,
        "_internal_originalTime": 1689742572000
    },
    {
        "time": 1689742574000,
        "value": 139.401,
        "_internal_originalTime": 1689742574000
    },
    {
        "time": 1689742575000,
        "value": 139.401,
        "_internal_originalTime": 1689742575000
    },
    {
        "time": 1689742577000,
        "value": 139.4,
        "_internal_originalTime": 1689742577000
    },
    {
        "time": 1689742578000,
        "value": 139.399,
        "_internal_originalTime": 1689742578000
    },
    {
        "time": 1689742580000,
        "value": 139.399,
        "_internal_originalTime": 1689742580000
    },
    {
        "time": 1689742581000,
        "value": 139.398,
        "_internal_originalTime": 1689742581000
    },
    {
        "time": 1689742585000,
        "value": 139.396,
        "_internal_originalTime": 1689742585000
    },
    {
        "time": 1689742587000,
        "value": 139.397,
        "_internal_originalTime": 1689742587000
    },
    {
        "time": 1689742588000,
        "value": 139.4,
        "_internal_originalTime": 1689742588000
    },
    {
        "time": 1689742592000,
        "value": 139.396,
        "_internal_originalTime": 1689742592000
    },
    {
        "time": 1689742593000,
        "value": 139.396,
        "_internal_originalTime": 1689742593000
    },
    {
        "time": 1689742595000,
        "value": 139.4,
        "_internal_originalTime": 1689742595000
    },
    {
        "time": 1689742596000,
        "value": 139.4,
        "_internal_originalTime": 1689742596000
    },
    {
        "time": 1689742598000,
        "value": 139.398,
        "_internal_originalTime": 1689742598000
    },
    {
        "time": 1689742600000,
        "value": 139.398,
        "_internal_originalTime": 1689742600000
    },
    {
        "time": 1689742603000,
        "value": 139.398,
        "_internal_originalTime": 1689742603000
    },
    {
        "time": 1689742604000,
        "value": 139.397,
        "_internal_originalTime": 1689742604000
    },
    {
        "time": 1689742607000,
        "value": 139.398,
        "_internal_originalTime": 1689742607000
    },
    {
        "time": 1689742610000,
        "value": 139.398,
        "_internal_originalTime": 1689742610000
    },
    {
        "time": 1689742612000,
        "value": 139.396,
        "_internal_originalTime": 1689742612000
    },
    {
        "time": 1689742615000,
        "value": 139.397,
        "_internal_originalTime": 1689742615000
    },
    {
        "time": 1689742618000,
        "value": 139.395,
        "_internal_originalTime": 1689742618000
    },
    {
        "time": 1689742621000,
        "value": 139.396,
        "_internal_originalTime": 1689742621000
    },
    {
        "time": 1689742622000,
        "value": 139.397,
        "_internal_originalTime": 1689742622000
    },
    {
        "time": 1689742624000,
        "value": 139.399,
        "_internal_originalTime": 1689742624000
    },
    {
        "time": 1689742626000,
        "value": 139.395,
        "_internal_originalTime": 1689742626000
    },
    {
        "time": 1689742628000,
        "value": 139.389,
        "_internal_originalTime": 1689742628000
    },
    {
        "time": 1689742630000,
        "value": 139.374,
        "_internal_originalTime": 1689742630000
    },
    {
        "time": 1689742631000,
        "value": 139.373,
        "_internal_originalTime": 1689742631000
    },
    {
        "time": 1689742634000,
        "value": 139.375,
        "_internal_originalTime": 1689742634000
    },
    {
        "time": 1689742635000,
        "value": 139.37,
        "_internal_originalTime": 1689742635000
    },
    {
        "time": 1689742637000,
        "value": 139.368,
        "_internal_originalTime": 1689742637000
    },
    {
        "time": 1689742638000,
        "value": 139.375,
        "_internal_originalTime": 1689742638000
    },
    {
        "time": 1689742643000,
        "value": 139.375,
        "_internal_originalTime": 1689742643000
    },
    {
        "time": 1689742646000,
        "value": 139.369,
        "_internal_originalTime": 1689742646000
    },
    {
        "time": 1689742648000,
        "value": 139.368,
        "_internal_originalTime": 1689742648000
    },
    {
        "time": 1689742653000,
        "value": 139.372,
        "_internal_originalTime": 1689742653000
    },
    {
        "time": 1689742656000,
        "value": 139.368,
        "_internal_originalTime": 1689742656000
    },
    {
        "time": 1689742657000,
        "value": 139.369,
        "_internal_originalTime": 1689742657000
    },
    {
        "time": 1689742659000,
        "value": 139.366,
        "_internal_originalTime": 1689742659000
    },
    {
        "time": 1689742660000,
        "value": 139.371,
        "_internal_originalTime": 1689742660000
    },
    {
        "time": 1689742662000,
        "value": 139.371,
        "_internal_originalTime": 1689742662000
    },
    {
        "time": 1689742665000,
        "value": 139.368,
        "_internal_originalTime": 1689742665000
    },
    {
        "time": 1689742667000,
        "value": 139.366,
        "_internal_originalTime": 1689742667000
    },
    {
        "time": 1689742668000,
        "value": 139.366,
        "_internal_originalTime": 1689742668000
    },
    {
        "time": 1689742670000,
        "value": 139.371,
        "_internal_originalTime": 1689742670000
    },
    {
        "time": 1689742673000,
        "value": 139.367,
        "_internal_originalTime": 1689742673000
    },
    {
        "time": 1689742674000,
        "value": 139.368,
        "_internal_originalTime": 1689742674000
    },
    {
        "time": 1689742676000,
        "value": 139.367,
        "_internal_originalTime": 1689742676000
    },
    {
        "time": 1689742678000,
        "value": 139.367,
        "_internal_originalTime": 1689742678000
    },
    {
        "time": 1689742679000,
        "value": 139.371,
        "_internal_originalTime": 1689742679000
    },
    {
        "time": 1689742680000,
        "value": 139.371,
        "_internal_originalTime": 1689742680000
    },
    {
        "time": 1689742682000,
        "value": 139.364,
        "_internal_originalTime": 1689742682000
    },
    {
        "time": 1689742683000,
        "value": 139.37,
        "_internal_originalTime": 1689742683000
    },
    {
        "time": 1689742685000,
        "value": 139.367,
        "_internal_originalTime": 1689742685000
    },
    {
        "time": 1689742686000,
        "value": 139.369,
        "_internal_originalTime": 1689742686000
    },
    {
        "time": 1689742688000,
        "value": 139.365,
        "_internal_originalTime": 1689742688000
    },
    {
        "time": 1689742690000,
        "value": 139.366,
        "_internal_originalTime": 1689742690000
    },
    {
        "time": 1689742691000,
        "value": 139.369,
        "_internal_originalTime": 1689742691000
    },
    {
        "time": 1689742693000,
        "value": 139.358,
        "_internal_originalTime": 1689742693000
    },
    {
        "time": 1689742695000,
        "value": 139.363,
        "_internal_originalTime": 1689742695000
    },
    {
        "time": 1689742696000,
        "value": 139.362,
        "_internal_originalTime": 1689742696000
    },
    {
        "time": 1689742697000,
        "value": 139.366,
        "_internal_originalTime": 1689742697000
    },
    {
        "time": 1689742701000,
        "value": 139.361,
        "_internal_originalTime": 1689742701000
    },
    {
        "time": 1689742703000,
        "value": 139.362,
        "_internal_originalTime": 1689742703000
    },
    {
        "time": 1689742705000,
        "value": 139.35,
        "_internal_originalTime": 1689742705000
    },
    {
        "time": 1689742707000,
        "value": 139.354,
        "_internal_originalTime": 1689742707000
    },
    {
        "time": 1689742709000,
        "value": 139.347,
        "_internal_originalTime": 1689742709000
    },
    {
        "time": 1689742710000,
        "value": 139.352,
        "_internal_originalTime": 1689742710000
    },
    {
        "time": 1689742711000,
        "value": 139.361,
        "_internal_originalTime": 1689742711000
    },
    {
        "time": 1689742713000,
        "value": 139.356,
        "_internal_originalTime": 1689742713000
    },
    {
        "time": 1689742715000,
        "value": 139.356,
        "_internal_originalTime": 1689742715000
    },
    {
        "time": 1689742717000,
        "value": 139.366,
        "_internal_originalTime": 1689742717000
    },
    {
        "time": 1689742719000,
        "value": 139.368,
        "_internal_originalTime": 1689742719000
    },
    {
        "time": 1689742721000,
        "value": 139.367,
        "_internal_originalTime": 1689742721000
    },
    {
        "time": 1689742723000,
        "value": 139.363,
        "_internal_originalTime": 1689742723000
    },
    {
        "time": 1689742724000,
        "value": 139.362,
        "_internal_originalTime": 1689742724000
    },
    {
        "time": 1689742725000,
        "value": 139.358,
        "_internal_originalTime": 1689742725000
    },
    {
        "time": 1689742727000,
        "value": 139.362,
        "_internal_originalTime": 1689742727000
    },
    {
        "time": 1689742729000,
        "value": 139.356,
        "_internal_originalTime": 1689742729000
    },
    {
        "time": 1689742730000,
        "value": 139.362,
        "_internal_originalTime": 1689742730000
    },
    {
        "time": 1689742732000,
        "value": 139.361,
        "_internal_originalTime": 1689742732000
    },
    {
        "time": 1689742734000,
        "value": 139.359,
        "_internal_originalTime": 1689742734000
    },
    {
        "time": 1689742737000,
        "value": 139.36,
        "_internal_originalTime": 1689742737000
    },
    {
        "time": 1689742738000,
        "value": 139.36,
        "_internal_originalTime": 1689742738000
    },
    {
        "time": 1689742740000,
        "value": 139.362,
        "_internal_originalTime": 1689742740000
    },
    {
        "time": 1689742741000,
        "value": 139.34,
        "_internal_originalTime": 1689742741000
    },
    {
        "time": 1689742742000,
        "value": 139.364,
        "_internal_originalTime": 1689742742000
    },
    {
        "time": 1689742743000,
        "value": 139.362,
        "_internal_originalTime": 1689742743000
    },
    {
        "time": 1689742746000,
        "value": 139.359,
        "_internal_originalTime": 1689742746000
    },
    {
        "time": 1689742747000,
        "value": 139.359,
        "_internal_originalTime": 1689742747000
    },
    {
        "time": 1689742749000,
        "value": 139.361,
        "_internal_originalTime": 1689742749000
    },
    {
        "time": 1689742750000,
        "value": 139.361,
        "_internal_originalTime": 1689742750000
    },
    {
        "time": 1689742752000,
        "value": 139.372,
        "_internal_originalTime": 1689742752000
    },
    {
        "time": 1689742754000,
        "value": 139.367,
        "_internal_originalTime": 1689742754000
    },
    {
        "time": 1689742755000,
        "value": 139.365,
        "_internal_originalTime": 1689742755000
    },
    {
        "time": 1689742756000,
        "value": 139.369,
        "_internal_originalTime": 1689742756000
    },
    {
        "time": 1689742758000,
        "value": 139.366,
        "_internal_originalTime": 1689742758000
    },
    {
        "time": 1689742759000,
        "value": 139.372,
        "_internal_originalTime": 1689742759000
    },
    {
        "time": 1689742761000,
        "value": 139.367,
        "_internal_originalTime": 1689742761000
    },
    {
        "time": 1689742763000,
        "value": 139.37,
        "_internal_originalTime": 1689742763000
    },
    {
        "time": 1689742765000,
        "value": 139.366,
        "_internal_originalTime": 1689742765000
    },
    {
        "time": 1689742767000,
        "value": 139.369,
        "_internal_originalTime": 1689742767000
    },
    {
        "time": 1689742769000,
        "value": 139.364,
        "_internal_originalTime": 1689742769000
    },
    {
        "time": 1689742770000,
        "value": 139.366,
        "_internal_originalTime": 1689742770000
    },
    {
        "time": 1689742773000,
        "value": 139.362,
        "_internal_originalTime": 1689742773000
    },
    {
        "time": 1689742774000,
        "value": 139.366,
        "_internal_originalTime": 1689742774000
    },
    {
        "time": 1689742776000,
        "value": 139.363,
        "_internal_originalTime": 1689742776000
    },
    {
        "time": 1689742778000,
        "value": 139.365,
        "_internal_originalTime": 1689742778000
    },
    {
        "time": 1689742780000,
        "value": 139.368,
        "_internal_originalTime": 1689742780000
    },
    {
        "time": 1689742784000,
        "value": 139.366,
        "_internal_originalTime": 1689742784000
    },
    {
        "time": 1689742787000,
        "value": 139.37,
        "_internal_originalTime": 1689742787000
    },
    {
        "time": 1689742789000,
        "value": 139.372,
        "_internal_originalTime": 1689742789000
    },
    {
        "time": 1689742799000,
        "value": 139.367,
        "_internal_originalTime": 1689742799000
    },
    {
        "time": 1689742800000,
        "value": 139.367,
        "_internal_originalTime": 1689742800000
    },
    {
        "time": 1689742801000,
        "value": 139.366,
        "_internal_originalTime": 1689742801000
    },
    {
        "time": 1689742804000,
        "value": 139.367,
        "_internal_originalTime": 1689742804000
    },
    {
        "time": 1689742806000,
        "value": 139.369,
        "_internal_originalTime": 1689742806000
    },
    {
        "time": 1689742808000,
        "value": 139.37,
        "_internal_originalTime": 1689742808000
    },
    {
        "time": 1689742814000,
        "value": 139.373,
        "_internal_originalTime": 1689742814000
    },
    {
        "time": 1689742815000,
        "value": 139.373,
        "_internal_originalTime": 1689742815000
    },
    {
        "time": 1689742816000,
        "value": 139.379,
        "_internal_originalTime": 1689742816000
    },
    {
        "time": 1689742818000,
        "value": 139.379,
        "_internal_originalTime": 1689742818000
    },
    {
        "time": 1689742819000,
        "value": 139.38,
        "_internal_originalTime": 1689742819000
    },
    {
        "time": 1689742820000,
        "value": 139.379,
        "_internal_originalTime": 1689742820000
    },
    {
        "time": 1689742824000,
        "value": 139.379,
        "_internal_originalTime": 1689742824000
    },
    {
        "time": 1689742825000,
        "value": 139.374,
        "_internal_originalTime": 1689742825000
    },
    {
        "time": 1689742826000,
        "value": 139.379,
        "_internal_originalTime": 1689742826000
    },
    {
        "time": 1689742828000,
        "value": 139.374,
        "_internal_originalTime": 1689742828000
    },
    {
        "time": 1689742830000,
        "value": 139.379,
        "_internal_originalTime": 1689742830000
    },
    {
        "time": 1689742831000,
        "value": 139.376,
        "_internal_originalTime": 1689742831000
    },
    {
        "time": 1689742832000,
        "value": 139.388,
        "_internal_originalTime": 1689742832000
    },
    {
        "time": 1689742833000,
        "value": 139.387,
        "_internal_originalTime": 1689742833000
    },
    {
        "time": 1689742835000,
        "value": 139.39,
        "_internal_originalTime": 1689742835000
    },
    {
        "time": 1689742836000,
        "value": 139.39,
        "_internal_originalTime": 1689742836000
    },
    {
        "time": 1689742837000,
        "value": 139.386,
        "_internal_originalTime": 1689742837000
    },
    {
        "time": 1689742838000,
        "value": 139.389,
        "_internal_originalTime": 1689742838000
    },
    {
        "time": 1689742839000,
        "value": 139.389,
        "_internal_originalTime": 1689742839000
    },
    {
        "time": 1689742840000,
        "value": 139.39,
        "_internal_originalTime": 1689742840000
    },
    {
        "time": 1689742842000,
        "value": 139.389,
        "_internal_originalTime": 1689742842000
    },
    {
        "time": 1689742843000,
        "value": 139.388,
        "_internal_originalTime": 1689742843000
    },
    {
        "time": 1689742844000,
        "value": 139.386,
        "_internal_originalTime": 1689742844000
    },
    {
        "time": 1689742845000,
        "value": 139.389,
        "_internal_originalTime": 1689742845000
    },
    {
        "time": 1689742846000,
        "value": 139.388,
        "_internal_originalTime": 1689742846000
    },
    {
        "time": 1689742847000,
        "value": 139.388,
        "_internal_originalTime": 1689742847000
    },
    {
        "time": 1689742850000,
        "value": 139.387,
        "_internal_originalTime": 1689742850000
    },
    {
        "time": 1689742851000,
        "value": 139.387,
        "_internal_originalTime": 1689742851000
    },
    {
        "time": 1689742852000,
        "value": 139.383,
        "_internal_originalTime": 1689742852000
    },
    {
        "time": 1689742853000,
        "value": 139.387,
        "_internal_originalTime": 1689742853000
    },
    {
        "time": 1689742855000,
        "value": 139.388,
        "_internal_originalTime": 1689742855000
    },
    {
        "time": 1689742856000,
        "value": 139.388,
        "_internal_originalTime": 1689742856000
    },
    {
        "time": 1689742858000,
        "value": 139.39,
        "_internal_originalTime": 1689742858000
    },
    {
        "time": 1689742859000,
        "value": 139.388,
        "_internal_originalTime": 1689742859000
    },
    {
        "time": 1689742860000,
        "value": 139.389,
        "_internal_originalTime": 1689742860000
    },
    {
        "time": 1689742862000,
        "value": 139.388,
        "_internal_originalTime": 1689742862000
    },
    {
        "time": 1689742863000,
        "value": 139.387,
        "_internal_originalTime": 1689742863000
    },
    {
        "time": 1689742864000,
        "value": 139.39,
        "_internal_originalTime": 1689742864000
    },
    {
        "time": 1689742866000,
        "value": 139.385,
        "_internal_originalTime": 1689742866000
    },
    {
        "time": 1689742868000,
        "value": 139.386,
        "_internal_originalTime": 1689742868000
    },
    {
        "time": 1689742871000,
        "value": 139.389,
        "_internal_originalTime": 1689742871000
    },
    {
        "time": 1689742873000,
        "value": 139.388,
        "_internal_originalTime": 1689742873000
    },
    {
        "time": 1689742876000,
        "value": 139.384,
        "_internal_originalTime": 1689742876000
    },
    {
        "time": 1689742878000,
        "value": 139.383,
        "_internal_originalTime": 1689742878000
    },
    {
        "time": 1689742880000,
        "value": 139.378,
        "_internal_originalTime": 1689742880000
    },
    {
        "time": 1689742881000,
        "value": 139.379,
        "_internal_originalTime": 1689742881000
    },
    {
        "time": 1689742882000,
        "value": 139.382,
        "_internal_originalTime": 1689742882000
    },
    {
        "time": 1689742883000,
        "value": 139.379,
        "_internal_originalTime": 1689742883000
    },
    {
        "time": 1689742885000,
        "value": 139.379,
        "_internal_originalTime": 1689742885000
    },
    {
        "time": 1689742887000,
        "value": 139.381,
        "_internal_originalTime": 1689742887000
    },
    {
        "time": 1689742888000,
        "value": 139.382,
        "_internal_originalTime": 1689742888000
    },
    {
        "time": 1689742890000,
        "value": 139.383,
        "_internal_originalTime": 1689742890000
    },
    {
        "time": 1689742891000,
        "value": 139.373,
        "_internal_originalTime": 1689742891000
    },
    {
        "time": 1689742893000,
        "value": 139.378,
        "_internal_originalTime": 1689742893000
    },
    {
        "time": 1689742895000,
        "value": 139.379,
        "_internal_originalTime": 1689742895000
    },
    {
        "time": 1689742897000,
        "value": 139.38,
        "_internal_originalTime": 1689742897000
    },
    {
        "time": 1689742898000,
        "value": 139.38,
        "_internal_originalTime": 1689742898000
    },
    {
        "time": 1689742900000,
        "value": 139.38,
        "_internal_originalTime": 1689742900000
    },
    {
        "time": 1689742902000,
        "value": 139.377,
        "_internal_originalTime": 1689742902000
    },
    {
        "time": 1689742903000,
        "value": 139.377,
        "_internal_originalTime": 1689742903000
    },
    {
        "time": 1689742905000,
        "value": 139.375,
        "_internal_originalTime": 1689742905000
    },
    {
        "time": 1689742906000,
        "value": 139.38,
        "_internal_originalTime": 1689742906000
    },
    {
        "time": 1689742908000,
        "value": 139.379,
        "_internal_originalTime": 1689742908000
    },
    {
        "time": 1689742910000,
        "value": 139.377,
        "_internal_originalTime": 1689742910000
    },
    {
        "time": 1689742911000,
        "value": 139.379,
        "_internal_originalTime": 1689742911000
    },
    {
        "time": 1689742912000,
        "value": 139.377,
        "_internal_originalTime": 1689742912000
    },
    {
        "time": 1689742915000,
        "value": 139.375,
        "_internal_originalTime": 1689742915000
    },
    {
        "time": 1689742916000,
        "value": 139.375,
        "_internal_originalTime": 1689742916000
    },
    {
        "time": 1689742918000,
        "value": 139.374,
        "_internal_originalTime": 1689742918000
    },
    {
        "time": 1689742920000,
        "value": 139.38,
        "_internal_originalTime": 1689742920000
    },
    {
        "time": 1689742923000,
        "value": 139.377,
        "_internal_originalTime": 1689742923000
    },
    {
        "time": 1689742925000,
        "value": 139.38,
        "_internal_originalTime": 1689742925000
    },
    {
        "time": 1689742926000,
        "value": 139.377,
        "_internal_originalTime": 1689742926000
    },
    {
        "time": 1689742928000,
        "value": 139.378,
        "_internal_originalTime": 1689742928000
    },
    {
        "time": 1689742929000,
        "value": 139.381,
        "_internal_originalTime": 1689742929000
    },
    {
        "time": 1689742931000,
        "value": 139.376,
        "_internal_originalTime": 1689742931000
    },
    {
        "time": 1689742936000,
        "value": 139.378,
        "_internal_originalTime": 1689742936000
    },
    {
        "time": 1689742937000,
        "value": 139.377,
        "_internal_originalTime": 1689742937000
    },
    {
        "time": 1689742941000,
        "value": 139.378,
        "_internal_originalTime": 1689742941000
    },
    {
        "time": 1689742942000,
        "value": 139.381,
        "_internal_originalTime": 1689742942000
    },
    {
        "time": 1689742944000,
        "value": 139.377,
        "_internal_originalTime": 1689742944000
    },
    {
        "time": 1689742945000,
        "value": 139.381,
        "_internal_originalTime": 1689742945000
    },
    {
        "time": 1689742947000,
        "value": 139.38,
        "_internal_originalTime": 1689742947000
    },
    {
        "time": 1689742948000,
        "value": 139.38,
        "_internal_originalTime": 1689742948000
    },
    {
        "time": 1689742950000,
        "value": 139.38,
        "_internal_originalTime": 1689742950000
    },
    {
        "time": 1689742954000,
        "value": 139.378,
        "_internal_originalTime": 1689742954000
    },
    {
        "time": 1689742955000,
        "value": 139.382,
        "_internal_originalTime": 1689742955000
    },
    {
        "time": 1689742957000,
        "value": 139.38,
        "_internal_originalTime": 1689742957000
    },
    {
        "time": 1689742958000,
        "value": 139.376,
        "_internal_originalTime": 1689742958000
    },
    {
        "time": 1689742960000,
        "value": 139.38,
        "_internal_originalTime": 1689742960000
    },
    {
        "time": 1689742962000,
        "value": 139.377,
        "_internal_originalTime": 1689742962000
    },
    {
        "time": 1689742964000,
        "value": 139.378,
        "_internal_originalTime": 1689742964000
    },
    {
        "time": 1689742965000,
        "value": 139.377,
        "_internal_originalTime": 1689742965000
    },
    {
        "time": 1689742971000,
        "value": 139.377,
        "_internal_originalTime": 1689742971000
    },
    {
        "time": 1689742972000,
        "value": 139.378,
        "_internal_originalTime": 1689742972000
    },
    {
        "time": 1689742973000,
        "value": 139.367,
        "_internal_originalTime": 1689742973000
    },
    {
        "time": 1689742975000,
        "value": 139.366,
        "_internal_originalTime": 1689742975000
    },
    {
        "time": 1689742977000,
        "value": 139.37,
        "_internal_originalTime": 1689742977000
    },
    {
        "time": 1689742978000,
        "value": 139.371,
        "_internal_originalTime": 1689742978000
    },
    {
        "time": 1689742980000,
        "value": 139.376,
        "_internal_originalTime": 1689742980000
    },
    {
        "time": 1689742981000,
        "value": 139.367,
        "_internal_originalTime": 1689742981000
    },
    {
        "time": 1689742983000,
        "value": 139.367,
        "_internal_originalTime": 1689742983000
    },
    {
        "time": 1689742985000,
        "value": 139.367,
        "_internal_originalTime": 1689742985000
    },
    {
        "time": 1689742986000,
        "value": 139.371,
        "_internal_originalTime": 1689742986000
    },
    {
        "time": 1689742988000,
        "value": 139.369,
        "_internal_originalTime": 1689742988000
    },
    {
        "time": 1689742990000,
        "value": 139.367,
        "_internal_originalTime": 1689742990000
    },
    {
        "time": 1689742991000,
        "value": 139.369,
        "_internal_originalTime": 1689742991000
    },
    {
        "time": 1689742993000,
        "value": 139.366,
        "_internal_originalTime": 1689742993000
    },
    {
        "time": 1689742994000,
        "value": 139.37,
        "_internal_originalTime": 1689742994000
    },
    {
        "time": 1689742996000,
        "value": 139.367,
        "_internal_originalTime": 1689742996000
    },
    {
        "time": 1689742998000,
        "value": 139.37,
        "_internal_originalTime": 1689742998000
    },
    {
        "time": 1689742999000,
        "value": 139.371,
        "_internal_originalTime": 1689742999000
    },
    {
        "time": 1689743001000,
        "value": 139.37,
        "_internal_originalTime": 1689743001000
    },
    {
        "time": 1689743002000,
        "value": 139.369,
        "_internal_originalTime": 1689743002000
    },
    {
        "time": 1689743005000,
        "value": 139.376,
        "_internal_originalTime": 1689743005000
    },
    {
        "time": 1689743006000,
        "value": 139.37,
        "_internal_originalTime": 1689743006000
    },
    {
        "time": 1689743008000,
        "value": 139.364,
        "_internal_originalTime": 1689743008000
    },
    {
        "time": 1689743009000,
        "value": 139.369,
        "_internal_originalTime": 1689743009000
    },
    {
        "time": 1689743011000,
        "value": 139.369,
        "_internal_originalTime": 1689743011000
    },
    {
        "time": 1689743012000,
        "value": 139.381,
        "_internal_originalTime": 1689743012000
    },
    {
        "time": 1689743014000,
        "value": 139.38,
        "_internal_originalTime": 1689743014000
    },
    {
        "time": 1689743015000,
        "value": 139.378,
        "_internal_originalTime": 1689743015000
    },
    {
        "time": 1689743018000,
        "value": 139.378,
        "_internal_originalTime": 1689743018000
    },
    {
        "time": 1689743019000,
        "value": 139.378,
        "_internal_originalTime": 1689743019000
    },
    {
        "time": 1689743024000,
        "value": 139.381,
        "_internal_originalTime": 1689743024000
    },
    {
        "time": 1689743025000,
        "value": 139.381,
        "_internal_originalTime": 1689743025000
    },
    {
        "time": 1689743027000,
        "value": 139.377,
        "_internal_originalTime": 1689743027000
    },
    {
        "time": 1689743030000,
        "value": 139.379,
        "_internal_originalTime": 1689743030000
    },
    {
        "time": 1689743031000,
        "value": 139.381,
        "_internal_originalTime": 1689743031000
    },
    {
        "time": 1689743034000,
        "value": 139.379,
        "_internal_originalTime": 1689743034000
    },
    {
        "time": 1689743036000,
        "value": 139.376,
        "_internal_originalTime": 1689743036000
    },
    {
        "time": 1689743038000,
        "value": 139.378,
        "_internal_originalTime": 1689743038000
    },
    {
        "time": 1689743040000,
        "value": 139.386,
        "_internal_originalTime": 1689743040000
    },
    {
        "time": 1689743041000,
        "value": 139.392,
        "_internal_originalTime": 1689743041000
    },
    {
        "time": 1689743042000,
        "value": 139.384,
        "_internal_originalTime": 1689743042000
    },
    {
        "time": 1689743043000,
        "value": 139.39,
        "_internal_originalTime": 1689743043000
    },
    {
        "time": 1689743045000,
        "value": 139.386,
        "_internal_originalTime": 1689743045000
    },
    {
        "time": 1689743047000,
        "value": 139.382,
        "_internal_originalTime": 1689743047000
    },
    {
        "time": 1689743049000,
        "value": 139.385,
        "_internal_originalTime": 1689743049000
    },
    {
        "time": 1689743050000,
        "value": 139.391,
        "_internal_originalTime": 1689743050000
    },
    {
        "time": 1689743052000,
        "value": 139.39,
        "_internal_originalTime": 1689743052000
    },
    {
        "time": 1689743054000,
        "value": 139.39,
        "_internal_originalTime": 1689743054000
    },
    {
        "time": 1689743055000,
        "value": 139.388,
        "_internal_originalTime": 1689743055000
    },
    {
        "time": 1689743057000,
        "value": 139.389,
        "_internal_originalTime": 1689743057000
    },
    {
        "time": 1689743058000,
        "value": 139.384,
        "_internal_originalTime": 1689743058000
    },
    {
        "time": 1689743060000,
        "value": 139.391,
        "_internal_originalTime": 1689743060000
    },
    {
        "time": 1689743061000,
        "value": 139.39,
        "_internal_originalTime": 1689743061000
    },
    {
        "time": 1689743063000,
        "value": 139.388,
        "_internal_originalTime": 1689743063000
    },
    {
        "time": 1689743066000,
        "value": 139.386,
        "_internal_originalTime": 1689743066000
    },
    {
        "time": 1689743067000,
        "value": 139.384,
        "_internal_originalTime": 1689743067000
    },
    {
        "time": 1689743068000,
        "value": 139.385,
        "_internal_originalTime": 1689743068000
    },
    {
        "time": 1689743070000,
        "value": 139.389,
        "_internal_originalTime": 1689743070000
    },
    {
        "time": 1689743071000,
        "value": 139.389,
        "_internal_originalTime": 1689743071000
    },
    {
        "time": 1689743073000,
        "value": 139.387,
        "_internal_originalTime": 1689743073000
    },
    {
        "time": 1689743075000,
        "value": 139.382,
        "_internal_originalTime": 1689743075000
    },
    {
        "time": 1689743077000,
        "value": 139.389,
        "_internal_originalTime": 1689743077000
    },
    {
        "time": 1689743078000,
        "value": 139.387,
        "_internal_originalTime": 1689743078000
    },
    {
        "time": 1689743080000,
        "value": 139.39,
        "_internal_originalTime": 1689743080000
    },
    {
        "time": 1689743081000,
        "value": 139.388,
        "_internal_originalTime": 1689743081000
    },
    {
        "time": 1689743083000,
        "value": 139.399,
        "_internal_originalTime": 1689743083000
    },
    {
        "time": 1689743085000,
        "value": 139.395,
        "_internal_originalTime": 1689743085000
    },
    {
        "time": 1689743088000,
        "value": 139.394,
        "_internal_originalTime": 1689743088000
    },
    {
        "time": 1689743090000,
        "value": 139.397,
        "_internal_originalTime": 1689743090000
    },
    {
        "time": 1689743092000,
        "value": 139.396,
        "_internal_originalTime": 1689743092000
    },
    {
        "time": 1689743093000,
        "value": 139.396,
        "_internal_originalTime": 1689743093000
    },
    {
        "time": 1689743095000,
        "value": 139.395,
        "_internal_originalTime": 1689743095000
    },
    {
        "time": 1689743097000,
        "value": 139.392,
        "_internal_originalTime": 1689743097000
    },
    {
        "time": 1689743098000,
        "value": 139.391,
        "_internal_originalTime": 1689743098000
    },
    {
        "time": 1689743100000,
        "value": 139.393,
        "_internal_originalTime": 1689743100000
    },
    {
        "time": 1689743102000,
        "value": 139.395,
        "_internal_originalTime": 1689743102000
    },
    {
        "time": 1689743104000,
        "value": 139.393,
        "_internal_originalTime": 1689743104000
    },
    {
        "time": 1689743105000,
        "value": 139.393,
        "_internal_originalTime": 1689743105000
    },
    {
        "time": 1689743107000,
        "value": 139.397,
        "_internal_originalTime": 1689743107000
    },
    {
        "time": 1689743108000,
        "value": 139.399,
        "_internal_originalTime": 1689743108000
    },
    {
        "time": 1689743110000,
        "value": 139.401,
        "_internal_originalTime": 1689743110000
    },
    {
        "time": 1689743112000,
        "value": 139.401,
        "_internal_originalTime": 1689743112000
    },
    {
        "time": 1689743115000,
        "value": 139.402,
        "_internal_originalTime": 1689743115000
    },
    {
        "time": 1689743116000,
        "value": 139.401,
        "_internal_originalTime": 1689743116000
    },
    {
        "time": 1689743119000,
        "value": 139.4,
        "_internal_originalTime": 1689743119000
    },
    {
        "time": 1689743120000,
        "value": 139.401,
        "_internal_originalTime": 1689743120000
    },
    {
        "time": 1689743122000,
        "value": 139.396,
        "_internal_originalTime": 1689743122000
    },
    {
        "time": 1689743124000,
        "value": 139.399,
        "_internal_originalTime": 1689743124000
    },
    {
        "time": 1689743125000,
        "value": 139.404,
        "_internal_originalTime": 1689743125000
    },
    {
        "time": 1689743128000,
        "value": 139.393,
        "_internal_originalTime": 1689743128000
    },
    {
        "time": 1689743129000,
        "value": 139.398,
        "_internal_originalTime": 1689743129000
    },
    {
        "time": 1689743131000,
        "value": 139.4,
        "_internal_originalTime": 1689743131000
    },
    {
        "time": 1689743132000,
        "value": 139.395,
        "_internal_originalTime": 1689743132000
    },
    {
        "time": 1689743134000,
        "value": 139.396,
        "_internal_originalTime": 1689743134000
    },
    {
        "time": 1689743136000,
        "value": 139.4,
        "_internal_originalTime": 1689743136000
    },
    {
        "time": 1689743138000,
        "value": 139.402,
        "_internal_originalTime": 1689743138000
    },
    {
        "time": 1689743139000,
        "value": 139.399,
        "_internal_originalTime": 1689743139000
    },
    {
        "time": 1689743141000,
        "value": 139.402,
        "_internal_originalTime": 1689743141000
    },
    {
        "time": 1689743142000,
        "value": 139.401,
        "_internal_originalTime": 1689743142000
    },
    {
        "time": 1689743143000,
        "value": 139.403,
        "_internal_originalTime": 1689743143000
    },
    {
        "time": 1689743146000,
        "value": 139.401,
        "_internal_originalTime": 1689743146000
    },
    {
        "time": 1689743147000,
        "value": 139.398,
        "_internal_originalTime": 1689743147000
    },
    {
        "time": 1689743148000,
        "value": 139.398,
        "_internal_originalTime": 1689743148000
    },
    {
        "time": 1689743150000,
        "value": 139.402,
        "_internal_originalTime": 1689743150000
    },
    {
        "time": 1689743152000,
        "value": 139.399,
        "_internal_originalTime": 1689743152000
    },
    {
        "time": 1689743154000,
        "value": 139.393,
        "_internal_originalTime": 1689743154000
    },
    {
        "time": 1689743156000,
        "value": 139.388,
        "_internal_originalTime": 1689743156000
    },
    {
        "time": 1689743157000,
        "value": 139.391,
        "_internal_originalTime": 1689743157000
    },
    {
        "time": 1689743158000,
        "value": 139.391,
        "_internal_originalTime": 1689743158000
    },
    {
        "time": 1689743160000,
        "value": 139.39,
        "_internal_originalTime": 1689743160000
    },
    {
        "time": 1689743161000,
        "value": 139.382,
        "_internal_originalTime": 1689743161000
    },
    {
        "time": 1689743162000,
        "value": 139.38,
        "_internal_originalTime": 1689743162000
    },
    {
        "time": 1689743164000,
        "value": 139.379,
        "_internal_originalTime": 1689743164000
    },
    {
        "time": 1689743165000,
        "value": 139.382,
        "_internal_originalTime": 1689743165000
    },
    {
        "time": 1689743167000,
        "value": 139.379,
        "_internal_originalTime": 1689743167000
    },
    {
        "time": 1689743168000,
        "value": 139.379,
        "_internal_originalTime": 1689743168000
    },
    {
        "time": 1689743171000,
        "value": 139.382,
        "_internal_originalTime": 1689743171000
    },
    {
        "time": 1689743174000,
        "value": 139.381,
        "_internal_originalTime": 1689743174000
    },
    {
        "time": 1689743176000,
        "value": 139.383,
        "_internal_originalTime": 1689743176000
    },
    {
        "time": 1689743182000,
        "value": 139.373,
        "_internal_originalTime": 1689743182000
    },
    {
        "time": 1689743183000,
        "value": 139.37,
        "_internal_originalTime": 1689743183000
    },
    {
        "time": 1689743185000,
        "value": 139.37,
        "_internal_originalTime": 1689743185000
    },
    {
        "time": 1689743186000,
        "value": 139.37,
        "_internal_originalTime": 1689743186000
    },
    {
        "time": 1689743187000,
        "value": 139.367,
        "_internal_originalTime": 1689743187000
    },
    {
        "time": 1689743188000,
        "value": 139.371,
        "_internal_originalTime": 1689743188000
    },
    {
        "time": 1689743192000,
        "value": 139.373,
        "_internal_originalTime": 1689743192000
    },
    {
        "time": 1689743194000,
        "value": 139.369,
        "_internal_originalTime": 1689743194000
    },
    {
        "time": 1689743197000,
        "value": 139.373,
        "_internal_originalTime": 1689743197000
    },
    {
        "time": 1689743200000,
        "value": 139.373,
        "_internal_originalTime": 1689743200000
    },
    {
        "time": 1689743201000,
        "value": 139.39,
        "_internal_originalTime": 1689743201000
    },
    {
        "time": 1689743202000,
        "value": 139.385,
        "_internal_originalTime": 1689743202000
    },
    {
        "time": 1689743204000,
        "value": 139.386,
        "_internal_originalTime": 1689743204000
    },
    {
        "time": 1689743205000,
        "value": 139.388,
        "_internal_originalTime": 1689743205000
    },
    {
        "time": 1689743207000,
        "value": 139.383,
        "_internal_originalTime": 1689743207000
    },
    {
        "time": 1689743208000,
        "value": 139.376,
        "_internal_originalTime": 1689743208000
    },
    {
        "time": 1689743210000,
        "value": 139.389,
        "_internal_originalTime": 1689743210000
    },
    {
        "time": 1689743212000,
        "value": 139.392,
        "_internal_originalTime": 1689743212000
    },
    {
        "time": 1689743214000,
        "value": 139.388,
        "_internal_originalTime": 1689743214000
    },
    {
        "time": 1689743216000,
        "value": 139.39,
        "_internal_originalTime": 1689743216000
    },
    {
        "time": 1689743217000,
        "value": 139.389,
        "_internal_originalTime": 1689743217000
    },
    {
        "time": 1689743219000,
        "value": 139.397,
        "_internal_originalTime": 1689743219000
    },
    {
        "time": 1689743220000,
        "value": 139.397,
        "_internal_originalTime": 1689743220000
    },
    {
        "time": 1689743221000,
        "value": 139.397,
        "_internal_originalTime": 1689743221000
    },
    {
        "time": 1689743223000,
        "value": 139.393,
        "_internal_originalTime": 1689743223000
    },
    {
        "time": 1689743224000,
        "value": 139.399,
        "_internal_originalTime": 1689743224000
    },
    {
        "time": 1689743226000,
        "value": 139.396,
        "_internal_originalTime": 1689743226000
    },
    {
        "time": 1689743228000,
        "value": 139.39,
        "_internal_originalTime": 1689743228000
    },
    {
        "time": 1689743231000,
        "value": 139.394,
        "_internal_originalTime": 1689743231000
    },
    {
        "time": 1689743232000,
        "value": 139.39,
        "_internal_originalTime": 1689743232000
    },
    {
        "time": 1689743234000,
        "value": 139.388,
        "_internal_originalTime": 1689743234000
    },
    {
        "time": 1689743237000,
        "value": 139.386,
        "_internal_originalTime": 1689743237000
    },
    {
        "time": 1689743240000,
        "value": 139.38,
        "_internal_originalTime": 1689743240000
    },
    {
        "time": 1689743243000,
        "value": 139.384,
        "_internal_originalTime": 1689743243000
    },
    {
        "time": 1689743245000,
        "value": 139.38,
        "_internal_originalTime": 1689743245000
    },
    {
        "time": 1689743246000,
        "value": 139.38,
        "_internal_originalTime": 1689743246000
    },
    {
        "time": 1689743248000,
        "value": 139.377,
        "_internal_originalTime": 1689743248000
    },
    {
        "time": 1689743249000,
        "value": 139.385,
        "_internal_originalTime": 1689743249000
    },
    {
        "time": 1689743250000,
        "value": 139.381,
        "_internal_originalTime": 1689743250000
    },
    {
        "time": 1689743252000,
        "value": 139.385,
        "_internal_originalTime": 1689743252000
    },
    {
        "time": 1689743253000,
        "value": 139.385,
        "_internal_originalTime": 1689743253000
    },
    {
        "time": 1689743258000,
        "value": 139.384,
        "_internal_originalTime": 1689743258000
    },
    {
        "time": 1689743259000,
        "value": 139.382,
        "_internal_originalTime": 1689743259000
    },
    {
        "time": 1689743261000,
        "value": 139.379,
        "_internal_originalTime": 1689743261000
    },
    {
        "time": 1689743263000,
        "value": 139.383,
        "_internal_originalTime": 1689743263000
    },
    {
        "time": 1689743264000,
        "value": 139.383,
        "_internal_originalTime": 1689743264000
    },
    {
        "time": 1689743265000,
        "value": 139.381,
        "_internal_originalTime": 1689743265000
    },
    {
        "time": 1689743266000,
        "value": 139.379,
        "_internal_originalTime": 1689743266000
    },
    {
        "time": 1689743269000,
        "value": 139.381,
        "_internal_originalTime": 1689743269000
    },
    {
        "time": 1689743271000,
        "value": 139.383,
        "_internal_originalTime": 1689743271000
    },
    {
        "time": 1689743273000,
        "value": 139.381,
        "_internal_originalTime": 1689743273000
    },
    {
        "time": 1689743274000,
        "value": 139.379,
        "_internal_originalTime": 1689743274000
    },
    {
        "time": 1689743276000,
        "value": 139.379,
        "_internal_originalTime": 1689743276000
    },
    {
        "time": 1689743278000,
        "value": 139.377,
        "_internal_originalTime": 1689743278000
    },
    {
        "time": 1689743280000,
        "value": 139.369,
        "_internal_originalTime": 1689743280000
    },
    {
        "time": 1689743281000,
        "value": 139.365,
        "_internal_originalTime": 1689743281000
    },
    {
        "time": 1689743283000,
        "value": 139.365,
        "_internal_originalTime": 1689743283000
    },
    {
        "time": 1689743284000,
        "value": 139.365,
        "_internal_originalTime": 1689743284000
    },
    {
        "time": 1689743288000,
        "value": 139.362,
        "_internal_originalTime": 1689743288000
    },
    {
        "time": 1689743291000,
        "value": 139.361,
        "_internal_originalTime": 1689743291000
    },
    {
        "time": 1689743293000,
        "value": 139.366,
        "_internal_originalTime": 1689743293000
    },
    {
        "time": 1689743294000,
        "value": 139.362,
        "_internal_originalTime": 1689743294000
    },
    {
        "time": 1689743297000,
        "value": 139.353,
        "_internal_originalTime": 1689743297000
    },
    {
        "time": 1689743298000,
        "value": 139.354,
        "_internal_originalTime": 1689743298000
    },
    {
        "time": 1689743300000,
        "value": 139.356,
        "_internal_originalTime": 1689743300000
    },
    {
        "time": 1689743301000,
        "value": 139.357,
        "_internal_originalTime": 1689743301000
    },
    {
        "time": 1689743303000,
        "value": 139.357,
        "_internal_originalTime": 1689743303000
    },
    {
        "time": 1689743305000,
        "value": 139.357,
        "_internal_originalTime": 1689743305000
    },
    {
        "time": 1689743306000,
        "value": 139.362,
        "_internal_originalTime": 1689743306000
    },
    {
        "time": 1689743308000,
        "value": 139.356,
        "_internal_originalTime": 1689743308000
    },
    {
        "time": 1689743309000,
        "value": 139.36,
        "_internal_originalTime": 1689743309000
    },
    {
        "time": 1689743310000,
        "value": 139.36,
        "_internal_originalTime": 1689743310000
    },
    {
        "time": 1689743312000,
        "value": 139.363,
        "_internal_originalTime": 1689743312000
    },
    {
        "time": 1689743315000,
        "value": 139.359,
        "_internal_originalTime": 1689743315000
    },
    {
        "time": 1689743319000,
        "value": 139.357,
        "_internal_originalTime": 1689743319000
    },
    {
        "time": 1689743321000,
        "value": 139.361,
        "_internal_originalTime": 1689743321000
    },
    {
        "time": 1689743322000,
        "value": 139.356,
        "_internal_originalTime": 1689743322000
    },
    {
        "time": 1689743324000,
        "value": 139.355,
        "_internal_originalTime": 1689743324000
    },
    {
        "time": 1689743325000,
        "value": 139.351,
        "_internal_originalTime": 1689743325000
    },
    {
        "time": 1689743328000,
        "value": 139.352,
        "_internal_originalTime": 1689743328000
    },
    {
        "time": 1689743330000,
        "value": 139.349,
        "_internal_originalTime": 1689743330000
    },
    {
        "time": 1689743331000,
        "value": 139.353,
        "_internal_originalTime": 1689743331000
    },
    {
        "time": 1689743333000,
        "value": 139.349,
        "_internal_originalTime": 1689743333000
    },
    {
        "time": 1689743335000,
        "value": 139.349,
        "_internal_originalTime": 1689743335000
    },
    {
        "time": 1689743336000,
        "value": 139.351,
        "_internal_originalTime": 1689743336000
    },
    {
        "time": 1689743339000,
        "value": 139.351,
        "_internal_originalTime": 1689743339000
    },
    {
        "time": 1689743340000,
        "value": 139.349,
        "_internal_originalTime": 1689743340000
    },
    {
        "time": 1689743343000,
        "value": 139.344,
        "_internal_originalTime": 1689743343000
    },
    {
        "time": 1689743344000,
        "value": 139.342,
        "_internal_originalTime": 1689743344000
    },
    {
        "time": 1689743347000,
        "value": 139.346,
        "_internal_originalTime": 1689743347000
    },
    {
        "time": 1689743348000,
        "value": 139.341,
        "_internal_originalTime": 1689743348000
    },
    {
        "time": 1689743350000,
        "value": 139.345,
        "_internal_originalTime": 1689743350000
    },
    {
        "time": 1689743352000,
        "value": 139.342,
        "_internal_originalTime": 1689743352000
    },
    {
        "time": 1689743354000,
        "value": 139.34,
        "_internal_originalTime": 1689743354000
    },
    {
        "time": 1689743355000,
        "value": 139.348,
        "_internal_originalTime": 1689743355000
    },
    {
        "time": 1689743357000,
        "value": 139.349,
        "_internal_originalTime": 1689743357000
    },
    {
        "time": 1689743359000,
        "value": 139.344,
        "_internal_originalTime": 1689743359000
    },
    {
        "time": 1689743360000,
        "value": 139.347,
        "_internal_originalTime": 1689743360000
    },
    {
        "time": 1689743362000,
        "value": 139.34,
        "_internal_originalTime": 1689743362000
    },
    {
        "time": 1689743365000,
        "value": 139.344,
        "_internal_originalTime": 1689743365000
    },
    {
        "time": 1689743366000,
        "value": 139.344,
        "_internal_originalTime": 1689743366000
    },
    {
        "time": 1689743368000,
        "value": 139.345,
        "_internal_originalTime": 1689743368000
    },
    {
        "time": 1689743370000,
        "value": 139.344,
        "_internal_originalTime": 1689743370000
    },
    {
        "time": 1689743373000,
        "value": 139.34,
        "_internal_originalTime": 1689743373000
    },
    {
        "time": 1689743375000,
        "value": 139.339,
        "_internal_originalTime": 1689743375000
    },
    {
        "time": 1689743376000,
        "value": 139.341,
        "_internal_originalTime": 1689743376000
    },
    {
        "time": 1689743380000,
        "value": 139.34,
        "_internal_originalTime": 1689743380000
    },
    {
        "time": 1689743381000,
        "value": 139.342,
        "_internal_originalTime": 1689743381000
    },
    {
        "time": 1689743384000,
        "value": 139.344,
        "_internal_originalTime": 1689743384000
    },
    {
        "time": 1689743386000,
        "value": 139.341,
        "_internal_originalTime": 1689743386000
    },
    {
        "time": 1689743388000,
        "value": 139.34,
        "_internal_originalTime": 1689743388000
    },
    {
        "time": 1689743389000,
        "value": 139.341,
        "_internal_originalTime": 1689743389000
    },
    {
        "time": 1689743392000,
        "value": 139.344,
        "_internal_originalTime": 1689743392000
    },
    {
        "time": 1689743395000,
        "value": 139.34,
        "_internal_originalTime": 1689743395000
    },
    {
        "time": 1689743398000,
        "value": 139.34,
        "_internal_originalTime": 1689743398000
    },
    {
        "time": 1689743399000,
        "value": 139.342,
        "_internal_originalTime": 1689743399000
    },
    {
        "time": 1689743400000,
        "value": 139.344,
        "_internal_originalTime": 1689743400000
    },
    {
        "time": 1689743402000,
        "value": 139.343,
        "_internal_originalTime": 1689743402000
    },
    {
        "time": 1689743405000,
        "value": 139.341,
        "_internal_originalTime": 1689743405000
    },
    {
        "time": 1689743406000,
        "value": 139.338,
        "_internal_originalTime": 1689743406000
    },
    {
        "time": 1689743408000,
        "value": 139.339,
        "_internal_originalTime": 1689743408000
    },
    {
        "time": 1689743410000,
        "value": 139.329,
        "_internal_originalTime": 1689743410000
    },
    {
        "time": 1689743411000,
        "value": 139.332,
        "_internal_originalTime": 1689743411000
    },
    {
        "time": 1689743414000,
        "value": 139.328,
        "_internal_originalTime": 1689743414000
    },
    {
        "time": 1689743415000,
        "value": 139.321,
        "_internal_originalTime": 1689743415000
    },
    {
        "time": 1689743416000,
        "value": 139.32,
        "_internal_originalTime": 1689743416000
    },
    {
        "time": 1689743418000,
        "value": 139.322,
        "_internal_originalTime": 1689743418000
    },
    {
        "time": 1689743419000,
        "value": 139.323,
        "_internal_originalTime": 1689743419000
    },
    {
        "time": 1689743422000,
        "value": 139.326,
        "_internal_originalTime": 1689743422000
    },
    {
        "time": 1689743424000,
        "value": 139.324,
        "_internal_originalTime": 1689743424000
    },
    {
        "time": 1689743425000,
        "value": 139.322,
        "_internal_originalTime": 1689743425000
    },
    {
        "time": 1689743429000,
        "value": 139.324,
        "_internal_originalTime": 1689743429000
    },
    {
        "time": 1689743430000,
        "value": 139.32,
        "_internal_originalTime": 1689743430000
    },
    {
        "time": 1689743431000,
        "value": 139.323,
        "_internal_originalTime": 1689743431000
    },
    {
        "time": 1689743433000,
        "value": 139.322,
        "_internal_originalTime": 1689743433000
    },
    {
        "time": 1689743435000,
        "value": 139.324,
        "_internal_originalTime": 1689743435000
    },
    {
        "time": 1689743436000,
        "value": 139.323,
        "_internal_originalTime": 1689743436000
    },
    {
        "time": 1689743438000,
        "value": 139.323,
        "_internal_originalTime": 1689743438000
    },
    {
        "time": 1689743440000,
        "value": 139.323,
        "_internal_originalTime": 1689743440000
    },
    {
        "time": 1689743445000,
        "value": 139.32,
        "_internal_originalTime": 1689743445000
    },
    {
        "time": 1689743448000,
        "value": 139.322,
        "_internal_originalTime": 1689743448000
    },
    {
        "time": 1689743450000,
        "value": 139.319,
        "_internal_originalTime": 1689743450000
    },
    {
        "time": 1689743451000,
        "value": 139.322,
        "_internal_originalTime": 1689743451000
    },
    {
        "time": 1689743455000,
        "value": 139.322,
        "_internal_originalTime": 1689743455000
    },
    {
        "time": 1689743456000,
        "value": 139.322,
        "_internal_originalTime": 1689743456000
    },
    {
        "time": 1689743458000,
        "value": 139.322,
        "_internal_originalTime": 1689743458000
    },
    {
        "time": 1689743459000,
        "value": 139.322,
        "_internal_originalTime": 1689743459000
    },
    {
        "time": 1689743460000,
        "value": 139.319,
        "_internal_originalTime": 1689743460000
    },
    {
        "time": 1689743462000,
        "value": 139.319,
        "_internal_originalTime": 1689743462000
    },
    {
        "time": 1689743464000,
        "value": 139.322,
        "_internal_originalTime": 1689743464000
    },
    {
        "time": 1689743465000,
        "value": 139.323,
        "_internal_originalTime": 1689743465000
    },
    {
        "time": 1689743468000,
        "value": 139.327,
        "_internal_originalTime": 1689743468000
    },
    {
        "time": 1689743469000,
        "value": 139.327,
        "_internal_originalTime": 1689743469000
    },
    {
        "time": 1689743471000,
        "value": 139.332,
        "_internal_originalTime": 1689743471000
    },
    {
        "time": 1689743473000,
        "value": 139.331,
        "_internal_originalTime": 1689743473000
    },
    {
        "time": 1689743475000,
        "value": 139.332,
        "_internal_originalTime": 1689743475000
    },
    {
        "time": 1689743477000,
        "value": 139.331,
        "_internal_originalTime": 1689743477000
    },
    {
        "time": 1689743479000,
        "value": 139.333,
        "_internal_originalTime": 1689743479000
    },
    {
        "time": 1689743481000,
        "value": 139.334,
        "_internal_originalTime": 1689743481000
    },
    {
        "time": 1689743482000,
        "value": 139.334,
        "_internal_originalTime": 1689743482000
    },
    {
        "time": 1689743484000,
        "value": 139.332,
        "_internal_originalTime": 1689743484000
    },
    {
        "time": 1689743486000,
        "value": 139.334,
        "_internal_originalTime": 1689743486000
    },
    {
        "time": 1689743488000,
        "value": 139.328,
        "_internal_originalTime": 1689743488000
    },
    {
        "time": 1689743490000,
        "value": 139.332,
        "_internal_originalTime": 1689743490000
    },
    {
        "time": 1689743491000,
        "value": 139.328,
        "_internal_originalTime": 1689743491000
    },
    {
        "time": 1689743493000,
        "value": 139.333,
        "_internal_originalTime": 1689743493000
    },
    {
        "time": 1689743494000,
        "value": 139.329,
        "_internal_originalTime": 1689743494000
    },
    {
        "time": 1689743497000,
        "value": 139.333,
        "_internal_originalTime": 1689743497000
    },
    {
        "time": 1689743499000,
        "value": 139.332,
        "_internal_originalTime": 1689743499000
    },
    {
        "time": 1689743500000,
        "value": 139.332,
        "_internal_originalTime": 1689743500000
    },
    {
        "time": 1689743502000,
        "value": 139.332,
        "_internal_originalTime": 1689743502000
    },
    {
        "time": 1689743504000,
        "value": 139.334,
        "_internal_originalTime": 1689743504000
    },
    {
        "time": 1689743506000,
        "value": 139.328,
        "_internal_originalTime": 1689743506000
    },
    {
        "time": 1689743508000,
        "value": 139.331,
        "_internal_originalTime": 1689743508000
    },
    {
        "time": 1689743511000,
        "value": 139.332,
        "_internal_originalTime": 1689743511000
    },
    {
        "time": 1689743512000,
        "value": 139.331,
        "_internal_originalTime": 1689743512000
    },
    {
        "time": 1689743515000,
        "value": 139.334,
        "_internal_originalTime": 1689743515000
    },
    {
        "time": 1689743516000,
        "value": 139.33,
        "_internal_originalTime": 1689743516000
    },
    {
        "time": 1689743518000,
        "value": 139.332,
        "_internal_originalTime": 1689743518000
    },
    {
        "time": 1689743519000,
        "value": 139.334,
        "_internal_originalTime": 1689743519000
    },
    {
        "time": 1689743521000,
        "value": 139.333,
        "_internal_originalTime": 1689743521000
    },
    {
        "time": 1689743522000,
        "value": 139.332,
        "_internal_originalTime": 1689743522000
    },
    {
        "time": 1689743523000,
        "value": 139.333,
        "_internal_originalTime": 1689743523000
    },
    {
        "time": 1689743525000,
        "value": 139.331,
        "_internal_originalTime": 1689743525000
    },
    {
        "time": 1689743526000,
        "value": 139.333,
        "_internal_originalTime": 1689743526000
    },
    {
        "time": 1689743527000,
        "value": 139.332,
        "_internal_originalTime": 1689743527000
    },
    {
        "time": 1689743528000,
        "value": 139.332,
        "_internal_originalTime": 1689743528000
    },
    {
        "time": 1689743531000,
        "value": 139.329,
        "_internal_originalTime": 1689743531000
    },
    {
        "time": 1689743533000,
        "value": 139.332,
        "_internal_originalTime": 1689743533000
    },
    {
        "time": 1689743534000,
        "value": 139.333,
        "_internal_originalTime": 1689743534000
    },
    {
        "time": 1689743536000,
        "value": 139.333,
        "_internal_originalTime": 1689743536000
    },
    {
        "time": 1689743537000,
        "value": 139.331,
        "_internal_originalTime": 1689743537000
    },
    {
        "time": 1689743541000,
        "value": 139.335,
        "_internal_originalTime": 1689743541000
    },
    {
        "time": 1689743542000,
        "value": 139.333,
        "_internal_originalTime": 1689743542000
    },
    {
        "time": 1689743543000,
        "value": 139.335,
        "_internal_originalTime": 1689743543000
    },
    {
        "time": 1689743546000,
        "value": 139.335,
        "_internal_originalTime": 1689743546000
    },
    {
        "time": 1689743548000,
        "value": 139.33,
        "_internal_originalTime": 1689743548000
    },
    {
        "time": 1689743550000,
        "value": 139.331,
        "_internal_originalTime": 1689743550000
    },
    {
        "time": 1689743551000,
        "value": 139.333,
        "_internal_originalTime": 1689743551000
    },
    {
        "time": 1689743553000,
        "value": 139.331,
        "_internal_originalTime": 1689743553000
    },
    {
        "time": 1689743555000,
        "value": 139.332,
        "_internal_originalTime": 1689743555000
    },
    {
        "time": 1689743557000,
        "value": 139.334,
        "_internal_originalTime": 1689743557000
    },
    {
        "time": 1689743558000,
        "value": 139.335,
        "_internal_originalTime": 1689743558000
    },
    {
        "time": 1689743564000,
        "value": 139.333,
        "_internal_originalTime": 1689743564000
    },
    {
        "time": 1689743565000,
        "value": 139.332,
        "_internal_originalTime": 1689743565000
    },
    {
        "time": 1689743566000,
        "value": 139.332,
        "_internal_originalTime": 1689743566000
    },
    {
        "time": 1689743569000,
        "value": 139.334,
        "_internal_originalTime": 1689743569000
    },
    {
        "time": 1689743572000,
        "value": 139.333,
        "_internal_originalTime": 1689743572000
    },
    {
        "time": 1689743573000,
        "value": 139.333,
        "_internal_originalTime": 1689743573000
    },
    {
        "time": 1689743576000,
        "value": 139.335,
        "_internal_originalTime": 1689743576000
    },
    {
        "time": 1689743578000,
        "value": 139.331,
        "_internal_originalTime": 1689743578000
    },
    {
        "time": 1689743579000,
        "value": 139.332,
        "_internal_originalTime": 1689743579000
    },
    {
        "time": 1689743580000,
        "value": 139.333,
        "_internal_originalTime": 1689743580000
    },
    {
        "time": 1689743582000,
        "value": 139.35,
        "_internal_originalTime": 1689743582000
    },
    {
        "time": 1689743584000,
        "value": 139.354,
        "_internal_originalTime": 1689743584000
    },
    {
        "time": 1689743586000,
        "value": 139.365,
        "_internal_originalTime": 1689743586000
    },
    {
        "time": 1689743588000,
        "value": 139.362,
        "_internal_originalTime": 1689743588000
    },
    {
        "time": 1689743589000,
        "value": 139.363,
        "_internal_originalTime": 1689743589000
    },
    {
        "time": 1689743591000,
        "value": 139.363,
        "_internal_originalTime": 1689743591000
    },
    {
        "time": 1689743593000,
        "value": 139.363,
        "_internal_originalTime": 1689743593000
    },
    {
        "time": 1689743595000,
        "value": 139.35,
        "_internal_originalTime": 1689743595000
    },
    {
        "time": 1689743597000,
        "value": 139.359,
        "_internal_originalTime": 1689743597000
    },
    {
        "time": 1689743598000,
        "value": 139.357,
        "_internal_originalTime": 1689743598000
    },
    {
        "time": 1689743600000,
        "value": 139.352,
        "_internal_originalTime": 1689743600000
    },
    {
        "time": 1689743602000,
        "value": 139.357,
        "_internal_originalTime": 1689743602000
    },
    {
        "time": 1689743603000,
        "value": 139.355,
        "_internal_originalTime": 1689743603000
    },
    {
        "time": 1689743604000,
        "value": 139.354,
        "_internal_originalTime": 1689743604000
    },
    {
        "time": 1689743606000,
        "value": 139.356,
        "_internal_originalTime": 1689743606000
    },
    {
        "time": 1689743608000,
        "value": 139.348,
        "_internal_originalTime": 1689743608000
    },
    {
        "time": 1689743609000,
        "value": 139.354,
        "_internal_originalTime": 1689743609000
    },
    {
        "time": 1689743612000,
        "value": 139.35,
        "_internal_originalTime": 1689743612000
    },
    {
        "time": 1689743614000,
        "value": 139.351,
        "_internal_originalTime": 1689743614000
    },
    {
        "time": 1689743615000,
        "value": 139.35,
        "_internal_originalTime": 1689743615000
    },
    {
        "time": 1689743618000,
        "value": 139.352,
        "_internal_originalTime": 1689743618000
    },
    {
        "time": 1689743621000,
        "value": 139.36,
        "_internal_originalTime": 1689743621000
    },
    {
        "time": 1689743623000,
        "value": 139.359,
        "_internal_originalTime": 1689743623000
    },
    {
        "time": 1689743624000,
        "value": 139.356,
        "_internal_originalTime": 1689743624000
    },
    {
        "time": 1689743626000,
        "value": 139.362,
        "_internal_originalTime": 1689743626000
    },
    {
        "time": 1689743628000,
        "value": 139.364,
        "_internal_originalTime": 1689743628000
    },
    {
        "time": 1689743629000,
        "value": 139.365,
        "_internal_originalTime": 1689743629000
    },
    {
        "time": 1689743633000,
        "value": 139.365,
        "_internal_originalTime": 1689743633000
    },
    {
        "time": 1689743636000,
        "value": 139.37,
        "_internal_originalTime": 1689743636000
    },
    {
        "time": 1689743639000,
        "value": 139.37,
        "_internal_originalTime": 1689743639000
    },
    {
        "time": 1689743640000,
        "value": 139.37,
        "_internal_originalTime": 1689743640000
    },
    {
        "time": 1689743641000,
        "value": 139.369,
        "_internal_originalTime": 1689743641000
    },
    {
        "time": 1689743642000,
        "value": 139.37,
        "_internal_originalTime": 1689743642000
    },
    {
        "time": 1689743646000,
        "value": 139.37,
        "_internal_originalTime": 1689743646000
    },
    {
        "time": 1689743648000,
        "value": 139.369,
        "_internal_originalTime": 1689743648000
    },
    {
        "time": 1689743650000,
        "value": 139.362,
        "_internal_originalTime": 1689743650000
    },
    {
        "time": 1689743651000,
        "value": 139.363,
        "_internal_originalTime": 1689743651000
    },
    {
        "time": 1689743654000,
        "value": 139.361,
        "_internal_originalTime": 1689743654000
    },
    {
        "time": 1689743655000,
        "value": 139.361,
        "_internal_originalTime": 1689743655000
    },
    {
        "time": 1689743657000,
        "value": 139.36,
        "_internal_originalTime": 1689743657000
    },
    {
        "time": 1689743658000,
        "value": 139.362,
        "_internal_originalTime": 1689743658000
    },
    {
        "time": 1689743662000,
        "value": 139.36,
        "_internal_originalTime": 1689743662000
    },
    {
        "time": 1689743664000,
        "value": 139.358,
        "_internal_originalTime": 1689743664000
    },
    {
        "time": 1689743665000,
        "value": 139.36,
        "_internal_originalTime": 1689743665000
    },
    {
        "time": 1689743667000,
        "value": 139.36,
        "_internal_originalTime": 1689743667000
    },
    {
        "time": 1689743668000,
        "value": 139.355,
        "_internal_originalTime": 1689743668000
    },
    {
        "time": 1689743670000,
        "value": 139.353,
        "_internal_originalTime": 1689743670000
    },
    {
        "time": 1689743671000,
        "value": 139.356,
        "_internal_originalTime": 1689743671000
    },
    {
        "time": 1689743674000,
        "value": 139.357,
        "_internal_originalTime": 1689743674000
    },
    {
        "time": 1689743677000,
        "value": 139.355,
        "_internal_originalTime": 1689743677000
    },
    {
        "time": 1689743679000,
        "value": 139.359,
        "_internal_originalTime": 1689743679000
    },
    {
        "time": 1689743681000,
        "value": 139.36,
        "_internal_originalTime": 1689743681000
    },
    {
        "time": 1689743683000,
        "value": 139.358,
        "_internal_originalTime": 1689743683000
    },
    {
        "time": 1689743684000,
        "value": 139.362,
        "_internal_originalTime": 1689743684000
    },
    {
        "time": 1689743686000,
        "value": 139.361,
        "_internal_originalTime": 1689743686000
    },
    {
        "time": 1689743688000,
        "value": 139.361,
        "_internal_originalTime": 1689743688000
    },
    {
        "time": 1689743690000,
        "value": 139.361,
        "_internal_originalTime": 1689743690000
    },
    {
        "time": 1689743691000,
        "value": 139.362,
        "_internal_originalTime": 1689743691000
    },
    {
        "time": 1689743693000,
        "value": 139.358,
        "_internal_originalTime": 1689743693000
    },
    {
        "time": 1689743698000,
        "value": 139.357,
        "_internal_originalTime": 1689743698000
    },
    {
        "time": 1689743700000,
        "value": 139.363,
        "_internal_originalTime": 1689743700000
    },
    {
        "time": 1689743701000,
        "value": 139.34,
        "_internal_originalTime": 1689743701000
    },
    {
        "time": 1689743702000,
        "value": 139.358,
        "_internal_originalTime": 1689743702000
    },
    {
        "time": 1689743703000,
        "value": 139.36,
        "_internal_originalTime": 1689743703000
    },
    {
        "time": 1689743705000,
        "value": 139.359,
        "_internal_originalTime": 1689743705000
    },
    {
        "time": 1689743709000,
        "value": 139.356,
        "_internal_originalTime": 1689743709000
    },
    {
        "time": 1689743710000,
        "value": 139.361,
        "_internal_originalTime": 1689743710000
    },
    {
        "time": 1689743711000,
        "value": 139.365,
        "_internal_originalTime": 1689743711000
    },
    {
        "time": 1689743712000,
        "value": 139.36,
        "_internal_originalTime": 1689743712000
    },
    {
        "time": 1689743713000,
        "value": 139.361,
        "_internal_originalTime": 1689743713000
    },
    {
        "time": 1689743716000,
        "value": 139.361,
        "_internal_originalTime": 1689743716000
    },
    {
        "time": 1689743719000,
        "value": 139.359,
        "_internal_originalTime": 1689743719000
    },
    {
        "time": 1689743720000,
        "value": 139.36,
        "_internal_originalTime": 1689743720000
    },
    {
        "time": 1689743721000,
        "value": 139.365,
        "_internal_originalTime": 1689743721000
    },
    {
        "time": 1689743722000,
        "value": 139.362,
        "_internal_originalTime": 1689743722000
    },
    {
        "time": 1689743724000,
        "value": 139.363,
        "_internal_originalTime": 1689743724000
    },
    {
        "time": 1689743725000,
        "value": 139.362,
        "_internal_originalTime": 1689743725000
    },
    {
        "time": 1689743728000,
        "value": 139.366,
        "_internal_originalTime": 1689743728000
    },
    {
        "time": 1689743730000,
        "value": 139.37,
        "_internal_originalTime": 1689743730000
    },
    {
        "time": 1689743731000,
        "value": 139.372,
        "_internal_originalTime": 1689743731000
    },
    {
        "time": 1689743732000,
        "value": 139.373,
        "_internal_originalTime": 1689743732000
    },
    {
        "time": 1689743733000,
        "value": 139.37,
        "_internal_originalTime": 1689743733000
    },
    {
        "time": 1689743734000,
        "value": 139.372,
        "_internal_originalTime": 1689743734000
    },
    {
        "time": 1689743735000,
        "value": 139.369,
        "_internal_originalTime": 1689743735000
    },
    {
        "time": 1689743736000,
        "value": 139.37,
        "_internal_originalTime": 1689743736000
    },
    {
        "time": 1689743738000,
        "value": 139.368,
        "_internal_originalTime": 1689743738000
    },
    {
        "time": 1689743739000,
        "value": 139.368,
        "_internal_originalTime": 1689743739000
    },
    {
        "time": 1689743740000,
        "value": 139.358,
        "_internal_originalTime": 1689743740000
    },
    {
        "time": 1689743742000,
        "value": 139.36,
        "_internal_originalTime": 1689743742000
    },
    {
        "time": 1689743743000,
        "value": 139.36,
        "_internal_originalTime": 1689743743000
    },
    {
        "time": 1689743745000,
        "value": 139.363,
        "_internal_originalTime": 1689743745000
    },
    {
        "time": 1689743748000,
        "value": 139.363,
        "_internal_originalTime": 1689743748000
    },
    {
        "time": 1689743749000,
        "value": 139.366,
        "_internal_originalTime": 1689743749000
    },
    {
        "time": 1689743750000,
        "value": 139.362,
        "_internal_originalTime": 1689743750000
    },
    {
        "time": 1689743751000,
        "value": 139.364,
        "_internal_originalTime": 1689743751000
    },
    {
        "time": 1689743753000,
        "value": 139.362,
        "_internal_originalTime": 1689743753000
    },
    {
        "time": 1689743757000,
        "value": 139.359,
        "_internal_originalTime": 1689743757000
    },
    {
        "time": 1689743758000,
        "value": 139.353,
        "_internal_originalTime": 1689743758000
    },
    {
        "time": 1689743761000,
        "value": 139.359,
        "_internal_originalTime": 1689743761000
    },
    {
        "time": 1689743763000,
        "value": 139.361,
        "_internal_originalTime": 1689743763000
    },
    {
        "time": 1689743764000,
        "value": 139.358,
        "_internal_originalTime": 1689743764000
    },
    {
        "time": 1689743766000,
        "value": 139.361,
        "_internal_originalTime": 1689743766000
    },
    {
        "time": 1689743767000,
        "value": 139.365,
        "_internal_originalTime": 1689743767000
    },
    {
        "time": 1689743768000,
        "value": 139.362,
        "_internal_originalTime": 1689743768000
    },
    {
        "time": 1689743769000,
        "value": 139.361,
        "_internal_originalTime": 1689743769000
    },
    {
        "time": 1689743770000,
        "value": 139.362,
        "_internal_originalTime": 1689743770000
    },
    {
        "time": 1689743771000,
        "value": 139.358,
        "_internal_originalTime": 1689743771000
    },
    {
        "time": 1689743772000,
        "value": 139.358,
        "_internal_originalTime": 1689743772000
    },
    {
        "time": 1689743773000,
        "value": 139.36,
        "_internal_originalTime": 1689743773000
    },
    {
        "time": 1689743774000,
        "value": 139.362,
        "_internal_originalTime": 1689743774000
    },
    {
        "time": 1689743775000,
        "value": 139.362,
        "_internal_originalTime": 1689743775000
    },
    {
        "time": 1689743776000,
        "value": 139.36,
        "_internal_originalTime": 1689743776000
    },
    {
        "time": 1689743777000,
        "value": 139.363,
        "_internal_originalTime": 1689743777000
    },
    {
        "time": 1689743779000,
        "value": 139.363,
        "_internal_originalTime": 1689743779000
    },
    {
        "time": 1689743780000,
        "value": 139.362,
        "_internal_originalTime": 1689743780000
    },
    {
        "time": 1689743782000,
        "value": 139.361,
        "_internal_originalTime": 1689743782000
    },
    {
        "time": 1689743784000,
        "value": 139.36,
        "_internal_originalTime": 1689743784000
    },
    {
        "time": 1689743786000,
        "value": 139.358,
        "_internal_originalTime": 1689743786000
    },
    {
        "time": 1689743787000,
        "value": 139.358,
        "_internal_originalTime": 1689743787000
    },
    {
        "time": 1689743788000,
        "value": 139.358,
        "_internal_originalTime": 1689743788000
    },
    {
        "time": 1689743789000,
        "value": 139.359,
        "_internal_originalTime": 1689743789000
    },
    {
        "time": 1689743791000,
        "value": 139.363,
        "_internal_originalTime": 1689743791000
    },
    {
        "time": 1689743793000,
        "value": 139.362,
        "_internal_originalTime": 1689743793000
    },
    {
        "time": 1689743794000,
        "value": 139.361,
        "_internal_originalTime": 1689743794000
    },
    {
        "time": 1689743795000,
        "value": 139.365,
        "_internal_originalTime": 1689743795000
    },
    {
        "time": 1689743797000,
        "value": 139.36,
        "_internal_originalTime": 1689743797000
    },
    {
        "time": 1689743798000,
        "value": 139.363,
        "_internal_originalTime": 1689743798000
    },
    {
        "time": 1689743800000,
        "value": 139.361,
        "_internal_originalTime": 1689743800000
    },
    {
        "time": 1689743802000,
        "value": 139.361,
        "_internal_originalTime": 1689743802000
    },
    {
        "time": 1689743803000,
        "value": 139.36,
        "_internal_originalTime": 1689743803000
    },
    {
        "time": 1689743805000,
        "value": 139.36,
        "_internal_originalTime": 1689743805000
    },
    {
        "time": 1689743806000,
        "value": 139.36,
        "_internal_originalTime": 1689743806000
    },
    {
        "time": 1689743807000,
        "value": 139.36,
        "_internal_originalTime": 1689743807000
    },
    {
        "time": 1689743809000,
        "value": 139.363,
        "_internal_originalTime": 1689743809000
    },
    {
        "time": 1689743811000,
        "value": 139.362,
        "_internal_originalTime": 1689743811000
    },
    {
        "time": 1689743812000,
        "value": 139.363,
        "_internal_originalTime": 1689743812000
    },
    {
        "time": 1689743814000,
        "value": 139.359,
        "_internal_originalTime": 1689743814000
    },
    {
        "time": 1689743816000,
        "value": 139.358,
        "_internal_originalTime": 1689743816000
    },
    {
        "time": 1689743818000,
        "value": 139.358,
        "_internal_originalTime": 1689743818000
    },
    {
        "time": 1689743819000,
        "value": 139.359,
        "_internal_originalTime": 1689743819000
    },
    {
        "time": 1689743820000,
        "value": 139.359,
        "_internal_originalTime": 1689743820000
    },
    {
        "time": 1689743821000,
        "value": 139.358,
        "_internal_originalTime": 1689743821000
    },
    {
        "time": 1689743822000,
        "value": 139.359,
        "_internal_originalTime": 1689743822000
    },
    {
        "time": 1689743824000,
        "value": 139.348,
        "_internal_originalTime": 1689743824000
    },
    {
        "time": 1689743826000,
        "value": 139.343,
        "_internal_originalTime": 1689743826000
    },
    {
        "time": 1689743828000,
        "value": 139.35,
        "_internal_originalTime": 1689743828000
    },
    {
        "time": 1689743829000,
        "value": 139.353,
        "_internal_originalTime": 1689743829000
    },
    {
        "time": 1689743830000,
        "value": 139.354,
        "_internal_originalTime": 1689743830000
    },
    {
        "time": 1689743832000,
        "value": 139.351,
        "_internal_originalTime": 1689743832000
    },
    {
        "time": 1689743833000,
        "value": 139.351,
        "_internal_originalTime": 1689743833000
    },
    {
        "time": 1689743834000,
        "value": 139.352,
        "_internal_originalTime": 1689743834000
    },
    {
        "time": 1689743835000,
        "value": 139.351,
        "_internal_originalTime": 1689743835000
    },
    {
        "time": 1689743836000,
        "value": 139.352,
        "_internal_originalTime": 1689743836000
    },
    {
        "time": 1689743838000,
        "value": 139.346,
        "_internal_originalTime": 1689743838000
    },
    {
        "time": 1689743839000,
        "value": 139.341,
        "_internal_originalTime": 1689743839000
    },
    {
        "time": 1689743840000,
        "value": 139.342,
        "_internal_originalTime": 1689743840000
    },
    {
        "time": 1689743842000,
        "value": 139.34,
        "_internal_originalTime": 1689743842000
    },
    {
        "time": 1689743843000,
        "value": 139.341,
        "_internal_originalTime": 1689743843000
    },
    {
        "time": 1689743846000,
        "value": 139.342,
        "_internal_originalTime": 1689743846000
    },
    {
        "time": 1689743848000,
        "value": 139.338,
        "_internal_originalTime": 1689743848000
    },
    {
        "time": 1689743849000,
        "value": 139.343,
        "_internal_originalTime": 1689743849000
    },
    {
        "time": 1689743850000,
        "value": 139.343,
        "_internal_originalTime": 1689743850000
    },
    {
        "time": 1689743851000,
        "value": 139.343,
        "_internal_originalTime": 1689743851000
    },
    {
        "time": 1689743853000,
        "value": 139.343,
        "_internal_originalTime": 1689743853000
    },
    {
        "time": 1689743854000,
        "value": 139.343,
        "_internal_originalTime": 1689743854000
    },
    {
        "time": 1689743856000,
        "value": 139.343,
        "_internal_originalTime": 1689743856000
    },
    {
        "time": 1689743857000,
        "value": 139.335,
        "_internal_originalTime": 1689743857000
    },
    {
        "time": 1689743858000,
        "value": 139.334,
        "_internal_originalTime": 1689743858000
    },
    {
        "time": 1689743859000,
        "value": 139.336,
        "_internal_originalTime": 1689743859000
    },
    {
        "time": 1689743863000,
        "value": 139.331,
        "_internal_originalTime": 1689743863000
    },
    {
        "time": 1689743864000,
        "value": 139.332,
        "_internal_originalTime": 1689743864000
    },
    {
        "time": 1689743866000,
        "value": 139.334,
        "_internal_originalTime": 1689743866000
    },
    {
        "time": 1689743868000,
        "value": 139.33,
        "_internal_originalTime": 1689743868000
    },
    {
        "time": 1689743873000,
        "value": 139.331,
        "_internal_originalTime": 1689743873000
    },
    {
        "time": 1689743874000,
        "value": 139.333,
        "_internal_originalTime": 1689743874000
    },
    {
        "time": 1689743875000,
        "value": 139.33,
        "_internal_originalTime": 1689743875000
    },
    {
        "time": 1689743877000,
        "value": 139.332,
        "_internal_originalTime": 1689743877000
    },
    {
        "time": 1689743879000,
        "value": 139.333,
        "_internal_originalTime": 1689743879000
    },
    {
        "time": 1689743880000,
        "value": 139.332,
        "_internal_originalTime": 1689743880000
    },
    {
        "time": 1689743882000,
        "value": 139.332,
        "_internal_originalTime": 1689743882000
    },
    {
        "time": 1689743883000,
        "value": 139.332,
        "_internal_originalTime": 1689743883000
    },
    {
        "time": 1689743885000,
        "value": 139.332,
        "_internal_originalTime": 1689743885000
    },
    {
        "time": 1689743886000,
        "value": 139.331,
        "_internal_originalTime": 1689743886000
    },
    {
        "time": 1689743887000,
        "value": 139.332,
        "_internal_originalTime": 1689743887000
    },
    {
        "time": 1689743888000,
        "value": 139.332,
        "_internal_originalTime": 1689743888000
    },
    {
        "time": 1689743890000,
        "value": 139.323,
        "_internal_originalTime": 1689743890000
    },
    {
        "time": 1689743891000,
        "value": 139.33,
        "_internal_originalTime": 1689743891000
    },
    {
        "time": 1689743892000,
        "value": 139.329,
        "_internal_originalTime": 1689743892000
    },
    {
        "time": 1689743893000,
        "value": 139.331,
        "_internal_originalTime": 1689743893000
    },
    {
        "time": 1689743896000,
        "value": 139.332,
        "_internal_originalTime": 1689743896000
    },
    {
        "time": 1689743898000,
        "value": 139.328,
        "_internal_originalTime": 1689743898000
    },
    {
        "time": 1689743899000,
        "value": 139.331,
        "_internal_originalTime": 1689743899000
    },
    {
        "time": 1689743900000,
        "value": 139.336,
        "_internal_originalTime": 1689743900000
    },
    {
        "time": 1689743902000,
        "value": 139.334,
        "_internal_originalTime": 1689743902000
    },
    {
        "time": 1689743906000,
        "value": 139.33,
        "_internal_originalTime": 1689743906000
    },
    {
        "time": 1689743907000,
        "value": 139.334,
        "_internal_originalTime": 1689743907000
    },
    {
        "time": 1689743908000,
        "value": 139.329,
        "_internal_originalTime": 1689743908000
    },
    {
        "time": 1689743914000,
        "value": 139.331,
        "_internal_originalTime": 1689743914000
    },
    {
        "time": 1689743916000,
        "value": 139.329,
        "_internal_originalTime": 1689743916000
    },
    {
        "time": 1689743917000,
        "value": 139.327,
        "_internal_originalTime": 1689743917000
    },
    {
        "time": 1689743919000,
        "value": 139.329,
        "_internal_originalTime": 1689743919000
    },
    {
        "time": 1689743920000,
        "value": 139.328,
        "_internal_originalTime": 1689743920000
    },
    {
        "time": 1689743922000,
        "value": 139.329,
        "_internal_originalTime": 1689743922000
    },
    {
        "time": 1689743923000,
        "value": 139.332,
        "_internal_originalTime": 1689743923000
    },
    {
        "time": 1689743924000,
        "value": 139.331,
        "_internal_originalTime": 1689743924000
    },
    {
        "time": 1689743928000,
        "value": 139.33,
        "_internal_originalTime": 1689743928000
    },
    {
        "time": 1689743929000,
        "value": 139.333,
        "_internal_originalTime": 1689743929000
    },
    {
        "time": 1689743931000,
        "value": 139.334,
        "_internal_originalTime": 1689743931000
    },
    {
        "time": 1689743932000,
        "value": 139.334,
        "_internal_originalTime": 1689743932000
    },
    {
        "time": 1689743938000,
        "value": 139.329,
        "_internal_originalTime": 1689743938000
    },
    {
        "time": 1689743940000,
        "value": 139.33,
        "_internal_originalTime": 1689743940000
    },
    {
        "time": 1689743941000,
        "value": 139.334,
        "_internal_originalTime": 1689743941000
    },
    {
        "time": 1689743942000,
        "value": 139.334,
        "_internal_originalTime": 1689743942000
    },
    {
        "time": 1689743947000,
        "value": 139.335,
        "_internal_originalTime": 1689743947000
    },
    {
        "time": 1689743948000,
        "value": 139.332,
        "_internal_originalTime": 1689743948000
    },
    {
        "time": 1689743955000,
        "value": 139.334,
        "_internal_originalTime": 1689743955000
    },
    {
        "time": 1689743957000,
        "value": 139.338,
        "_internal_originalTime": 1689743957000
    },
    {
        "time": 1689743958000,
        "value": 139.336,
        "_internal_originalTime": 1689743958000
    },
    {
        "time": 1689743960000,
        "value": 139.336,
        "_internal_originalTime": 1689743960000
    },
    {
        "time": 1689743961000,
        "value": 139.334,
        "_internal_originalTime": 1689743961000
    },
    {
        "time": 1689743963000,
        "value": 139.341,
        "_internal_originalTime": 1689743963000
    },
    {
        "time": 1689743964000,
        "value": 139.336,
        "_internal_originalTime": 1689743964000
    },
    {
        "time": 1689743965000,
        "value": 139.334,
        "_internal_originalTime": 1689743965000
    },
    {
        "time": 1689743966000,
        "value": 139.331,
        "_internal_originalTime": 1689743966000
    },
    {
        "time": 1689743968000,
        "value": 139.33,
        "_internal_originalTime": 1689743968000
    },
    {
        "time": 1689743970000,
        "value": 139.332,
        "_internal_originalTime": 1689743970000
    },
    {
        "time": 1689743971000,
        "value": 139.331,
        "_internal_originalTime": 1689743971000
    },
    {
        "time": 1689743972000,
        "value": 139.328,
        "_internal_originalTime": 1689743972000
    },
    {
        "time": 1689743973000,
        "value": 139.329,
        "_internal_originalTime": 1689743973000
    },
    {
        "time": 1689743974000,
        "value": 139.332,
        "_internal_originalTime": 1689743974000
    },
    {
        "time": 1689743975000,
        "value": 139.331,
        "_internal_originalTime": 1689743975000
    },
    {
        "time": 1689743977000,
        "value": 139.334,
        "_internal_originalTime": 1689743977000
    },
    {
        "time": 1689743978000,
        "value": 139.333,
        "_internal_originalTime": 1689743978000
    },
    {
        "time": 1689743980000,
        "value": 139.334,
        "_internal_originalTime": 1689743980000
    },
    {
        "time": 1689743981000,
        "value": 139.334,
        "_internal_originalTime": 1689743981000
    },
    {
        "time": 1689743985000,
        "value": 139.333,
        "_internal_originalTime": 1689743985000
    },
    {
        "time": 1689743986000,
        "value": 139.333,
        "_internal_originalTime": 1689743986000
    },
    {
        "time": 1689743989000,
        "value": 139.33,
        "_internal_originalTime": 1689743989000
    },
    {
        "time": 1689743990000,
        "value": 139.329,
        "_internal_originalTime": 1689743990000
    },
    {
        "time": 1689743992000,
        "value": 139.332,
        "_internal_originalTime": 1689743992000
    },
    {
        "time": 1689743994000,
        "value": 139.332,
        "_internal_originalTime": 1689743994000
    },
    {
        "time": 1689743997000,
        "value": 139.333,
        "_internal_originalTime": 1689743997000
    },
    {
        "time": 1689743998000,
        "value": 139.328,
        "_internal_originalTime": 1689743998000
    },
    {
        "time": 1689743999000,
        "value": 139.329,
        "_internal_originalTime": 1689743999000
    },
    {
        "time": 1689744001000,
        "value": 139.334,
        "_internal_originalTime": 1689744001000
    },
    {
        "time": 1689744002000,
        "value": 139.333,
        "_internal_originalTime": 1689744002000
    },
    {
        "time": 1689744003000,
        "value": 139.332,
        "_internal_originalTime": 1689744003000
    },
    {
        "time": 1689744004000,
        "value": 139.333,
        "_internal_originalTime": 1689744004000
    },
    {
        "time": 1689744005000,
        "value": 139.332,
        "_internal_originalTime": 1689744005000
    },
    {
        "time": 1689744007000,
        "value": 139.334,
        "_internal_originalTime": 1689744007000
    },
    {
        "time": 1689744008000,
        "value": 139.333,
        "_internal_originalTime": 1689744008000
    },
    {
        "time": 1689744011000,
        "value": 139.334,
        "_internal_originalTime": 1689744011000
    },
    {
        "time": 1689744013000,
        "value": 139.334,
        "_internal_originalTime": 1689744013000
    },
    {
        "time": 1689744014000,
        "value": 139.333,
        "_internal_originalTime": 1689744014000
    },
    {
        "time": 1689744015000,
        "value": 139.33,
        "_internal_originalTime": 1689744015000
    },
    {
        "time": 1689744019000,
        "value": 139.334,
        "_internal_originalTime": 1689744019000
    },
    {
        "time": 1689744020000,
        "value": 139.332,
        "_internal_originalTime": 1689744020000
    },
    {
        "time": 1689744022000,
        "value": 139.331,
        "_internal_originalTime": 1689744022000
    },
    {
        "time": 1689744024000,
        "value": 139.341,
        "_internal_originalTime": 1689744024000
    },
    {
        "time": 1689744026000,
        "value": 139.342,
        "_internal_originalTime": 1689744026000
    },
    {
        "time": 1689744028000,
        "value": 139.339,
        "_internal_originalTime": 1689744028000
    },
    {
        "time": 1689744029000,
        "value": 139.342,
        "_internal_originalTime": 1689744029000
    },
    {
        "time": 1689744030000,
        "value": 139.342,
        "_internal_originalTime": 1689744030000
    },
    {
        "time": 1689744031000,
        "value": 139.341,
        "_internal_originalTime": 1689744031000
    },
    {
        "time": 1689744032000,
        "value": 139.342,
        "_internal_originalTime": 1689744032000
    },
    {
        "time": 1689744034000,
        "value": 139.342,
        "_internal_originalTime": 1689744034000
    },
    {
        "time": 1689744036000,
        "value": 139.34,
        "_internal_originalTime": 1689744036000
    },
    {
        "time": 1689744038000,
        "value": 139.34,
        "_internal_originalTime": 1689744038000
    },
    {
        "time": 1689744039000,
        "value": 139.338,
        "_internal_originalTime": 1689744039000
    },
    {
        "time": 1689744041000,
        "value": 139.344,
        "_internal_originalTime": 1689744041000
    },
    {
        "time": 1689744042000,
        "value": 139.344,
        "_internal_originalTime": 1689744042000
    },
    {
        "time": 1689744043000,
        "value": 139.344,
        "_internal_originalTime": 1689744043000
    },
    {
        "time": 1689744044000,
        "value": 139.339,
        "_internal_originalTime": 1689744044000
    },
    {
        "time": 1689744045000,
        "value": 139.344,
        "_internal_originalTime": 1689744045000
    },
    {
        "time": 1689744048000,
        "value": 139.345,
        "_internal_originalTime": 1689744048000
    },
    {
        "time": 1689744049000,
        "value": 139.35,
        "_internal_originalTime": 1689744049000
    },
    {
        "time": 1689744050000,
        "value": 139.353,
        "_internal_originalTime": 1689744050000
    },
    {
        "time": 1689744052000,
        "value": 139.349,
        "_internal_originalTime": 1689744052000
    },
    {
        "time": 1689744053000,
        "value": 139.352,
        "_internal_originalTime": 1689744053000
    },
    {
        "time": 1689744055000,
        "value": 139.352,
        "_internal_originalTime": 1689744055000
    },
    {
        "time": 1689744056000,
        "value": 139.349,
        "_internal_originalTime": 1689744056000
    },
    {
        "time": 1689744057000,
        "value": 139.354,
        "_internal_originalTime": 1689744057000
    },
    {
        "time": 1689744058000,
        "value": 139.349,
        "_internal_originalTime": 1689744058000
    },
    {
        "time": 1689744059000,
        "value": 139.35,
        "_internal_originalTime": 1689744059000
    },
    {
        "time": 1689744060000,
        "value": 139.33,
        "_internal_originalTime": 1689744060000
    },
    {
        "time": 1689744061000,
        "value": 139.35,
        "_internal_originalTime": 1689744061000
    },
    {
        "time": 1689744063000,
        "value": 139.35,
        "_internal_originalTime": 1689744063000
    },
    {
        "time": 1689744064000,
        "value": 139.35,
        "_internal_originalTime": 1689744064000
    },
    {
        "time": 1689744065000,
        "value": 139.35,
        "_internal_originalTime": 1689744065000
    },
    {
        "time": 1689744067000,
        "value": 139.351,
        "_internal_originalTime": 1689744067000
    },
    {
        "time": 1689744068000,
        "value": 139.351,
        "_internal_originalTime": 1689744068000
    },
    {
        "time": 1689744069000,
        "value": 139.353,
        "_internal_originalTime": 1689744069000
    },
    {
        "time": 1689744070000,
        "value": 139.353,
        "_internal_originalTime": 1689744070000
    },
    {
        "time": 1689744071000,
        "value": 139.352,
        "_internal_originalTime": 1689744071000
    },
    {
        "time": 1689744073000,
        "value": 139.351,
        "_internal_originalTime": 1689744073000
    },
    {
        "time": 1689744075000,
        "value": 139.352,
        "_internal_originalTime": 1689744075000
    },
    {
        "time": 1689744077000,
        "value": 139.352,
        "_internal_originalTime": 1689744077000
    },
    {
        "time": 1689744079000,
        "value": 139.35,
        "_internal_originalTime": 1689744079000
    },
    {
        "time": 1689744081000,
        "value": 139.351,
        "_internal_originalTime": 1689744081000
    },
    {
        "time": 1689744084000,
        "value": 139.348,
        "_internal_originalTime": 1689744084000
    },
    {
        "time": 1689744088000,
        "value": 139.346,
        "_internal_originalTime": 1689744088000
    },
    {
        "time": 1689744090000,
        "value": 139.351,
        "_internal_originalTime": 1689744090000
    },
    {
        "time": 1689744091000,
        "value": 139.351,
        "_internal_originalTime": 1689744091000
    },
    {
        "time": 1689744092000,
        "value": 139.334,
        "_internal_originalTime": 1689744092000
    },
    {
        "time": 1689744094000,
        "value": 139.33,
        "_internal_originalTime": 1689744094000
    },
    {
        "time": 1689744096000,
        "value": 139.33,
        "_internal_originalTime": 1689744096000
    },
    {
        "time": 1689744097000,
        "value": 139.33,
        "_internal_originalTime": 1689744097000
    },
    {
        "time": 1689744099000,
        "value": 139.332,
        "_internal_originalTime": 1689744099000
    },
    {
        "time": 1689744100000,
        "value": 139.331,
        "_internal_originalTime": 1689744100000
    },
    {
        "time": 1689744101000,
        "value": 139.33,
        "_internal_originalTime": 1689744101000
    },
    {
        "time": 1689744103000,
        "value": 139.328,
        "_internal_originalTime": 1689744103000
    },
    {
        "time": 1689744105000,
        "value": 139.334,
        "_internal_originalTime": 1689744105000
    },
    {
        "time": 1689744106000,
        "value": 139.33,
        "_internal_originalTime": 1689744106000
    },
    {
        "time": 1689744107000,
        "value": 139.332,
        "_internal_originalTime": 1689744107000
    },
    {
        "time": 1689744108000,
        "value": 139.33,
        "_internal_originalTime": 1689744108000
    },
    {
        "time": 1689744109000,
        "value": 139.331,
        "_internal_originalTime": 1689744109000
    },
    {
        "time": 1689744111000,
        "value": 139.336,
        "_internal_originalTime": 1689744111000
    },
    {
        "time": 1689744112000,
        "value": 139.331,
        "_internal_originalTime": 1689744112000
    },
    {
        "time": 1689744113000,
        "value": 139.333,
        "_internal_originalTime": 1689744113000
    },
    {
        "time": 1689744115000,
        "value": 139.33,
        "_internal_originalTime": 1689744115000
    },
    {
        "time": 1689744116000,
        "value": 139.331,
        "_internal_originalTime": 1689744116000
    },
    {
        "time": 1689744118000,
        "value": 139.33,
        "_internal_originalTime": 1689744118000
    },
    {
        "time": 1689744120000,
        "value": 139.31,
        "_internal_originalTime": 1689744120000
    },
    {
        "time": 1689744122000,
        "value": 139.335,
        "_internal_originalTime": 1689744122000
    },
    {
        "time": 1689744123000,
        "value": 139.33,
        "_internal_originalTime": 1689744123000
    },
    {
        "time": 1689744125000,
        "value": 139.328,
        "_internal_originalTime": 1689744125000
    },
    {
        "time": 1689744127000,
        "value": 139.331,
        "_internal_originalTime": 1689744127000
    },
    {
        "time": 1689744128000,
        "value": 139.336,
        "_internal_originalTime": 1689744128000
    },
    {
        "time": 1689744129000,
        "value": 139.333,
        "_internal_originalTime": 1689744129000
    },
    {
        "time": 1689744130000,
        "value": 139.333,
        "_internal_originalTime": 1689744130000
    },
    {
        "time": 1689744133000,
        "value": 139.333,
        "_internal_originalTime": 1689744133000
    },
    {
        "time": 1689744135000,
        "value": 139.331,
        "_internal_originalTime": 1689744135000
    },
    {
        "time": 1689744136000,
        "value": 139.332,
        "_internal_originalTime": 1689744136000
    },
    {
        "time": 1689744138000,
        "value": 139.333,
        "_internal_originalTime": 1689744138000
    },
    {
        "time": 1689744142000,
        "value": 139.33,
        "_internal_originalTime": 1689744142000
    },
    {
        "time": 1689744143000,
        "value": 139.331,
        "_internal_originalTime": 1689744143000
    },
    {
        "time": 1689744147000,
        "value": 139.331,
        "_internal_originalTime": 1689744147000
    },
    {
        "time": 1689744148000,
        "value": 139.333,
        "_internal_originalTime": 1689744148000
    },
    {
        "time": 1689744149000,
        "value": 139.334,
        "_internal_originalTime": 1689744149000
    },
    {
        "time": 1689744150000,
        "value": 139.335,
        "_internal_originalTime": 1689744150000
    },
    {
        "time": 1689744153000,
        "value": 139.333,
        "_internal_originalTime": 1689744153000
    },
    {
        "time": 1689744155000,
        "value": 139.33,
        "_internal_originalTime": 1689744155000
    },
    {
        "time": 1689744158000,
        "value": 139.336,
        "_internal_originalTime": 1689744158000
    },
    {
        "time": 1689744160000,
        "value": 139.341,
        "_internal_originalTime": 1689744160000
    },
    {
        "time": 1689744162000,
        "value": 139.337,
        "_internal_originalTime": 1689744162000
    },
    {
        "time": 1689744163000,
        "value": 139.334,
        "_internal_originalTime": 1689744163000
    },
    {
        "time": 1689744164000,
        "value": 139.339,
        "_internal_originalTime": 1689744164000
    },
    {
        "time": 1689744165000,
        "value": 139.336,
        "_internal_originalTime": 1689744165000
    },
    {
        "time": 1689744166000,
        "value": 139.338,
        "_internal_originalTime": 1689744166000
    },
    {
        "time": 1689744167000,
        "value": 139.336,
        "_internal_originalTime": 1689744167000
    },
    {
        "time": 1689744169000,
        "value": 139.337,
        "_internal_originalTime": 1689744169000
    },
    {
        "time": 1689744170000,
        "value": 139.34,
        "_internal_originalTime": 1689744170000
    },
    {
        "time": 1689744172000,
        "value": 139.334,
        "_internal_originalTime": 1689744172000
    },
    {
        "time": 1689744174000,
        "value": 139.337,
        "_internal_originalTime": 1689744174000
    },
    {
        "time": 1689744175000,
        "value": 139.338,
        "_internal_originalTime": 1689744175000
    },
    {
        "time": 1689744178000,
        "value": 139.335,
        "_internal_originalTime": 1689744178000
    },
    {
        "time": 1689744179000,
        "value": 139.333,
        "_internal_originalTime": 1689744179000
    },
    {
        "time": 1689744181000,
        "value": 139.332,
        "_internal_originalTime": 1689744181000
    },
    {
        "time": 1689744183000,
        "value": 139.332,
        "_internal_originalTime": 1689744183000
    },
    {
        "time": 1689744185000,
        "value": 139.335,
        "_internal_originalTime": 1689744185000
    },
    {
        "time": 1689744186000,
        "value": 139.332,
        "_internal_originalTime": 1689744186000
    },
    {
        "time": 1689744188000,
        "value": 139.333,
        "_internal_originalTime": 1689744188000
    },
    {
        "time": 1689744189000,
        "value": 139.334,
        "_internal_originalTime": 1689744189000
    },
    {
        "time": 1689744192000,
        "value": 139.333,
        "_internal_originalTime": 1689744192000
    },
    {
        "time": 1689744193000,
        "value": 139.334,
        "_internal_originalTime": 1689744193000
    },
    {
        "time": 1689744195000,
        "value": 139.334,
        "_internal_originalTime": 1689744195000
    },
    {
        "time": 1689744198000,
        "value": 139.333,
        "_internal_originalTime": 1689744198000
    },
    {
        "time": 1689744202000,
        "value": 139.336,
        "_internal_originalTime": 1689744202000
    },
    {
        "time": 1689744206000,
        "value": 139.332,
        "_internal_originalTime": 1689744206000
    },
    {
        "time": 1689744208000,
        "value": 139.336,
        "_internal_originalTime": 1689744208000
    },
    {
        "time": 1689744211000,
        "value": 139.333,
        "_internal_originalTime": 1689744211000
    },
    {
        "time": 1689744212000,
        "value": 139.333,
        "_internal_originalTime": 1689744212000
    },
    {
        "time": 1689744215000,
        "value": 139.333,
        "_internal_originalTime": 1689744215000
    },
    {
        "time": 1689744217000,
        "value": 139.332,
        "_internal_originalTime": 1689744217000
    },
    {
        "time": 1689744219000,
        "value": 139.337,
        "_internal_originalTime": 1689744219000
    },
    {
        "time": 1689744231000,
        "value": 139.341,
        "_internal_originalTime": 1689744231000
    },
    {
        "time": 1689744232000,
        "value": 139.343,
        "_internal_originalTime": 1689744232000
    },
    {
        "time": 1689744233000,
        "value": 139.349,
        "_internal_originalTime": 1689744233000
    },
    {
        "time": 1689744235000,
        "value": 139.348,
        "_internal_originalTime": 1689744235000
    },
    {
        "time": 1689744236000,
        "value": 139.352,
        "_internal_originalTime": 1689744236000
    },
    {
        "time": 1689744238000,
        "value": 139.342,
        "_internal_originalTime": 1689744238000
    },
    {
        "time": 1689744239000,
        "value": 139.347,
        "_internal_originalTime": 1689744239000
    },
    {
        "time": 1689744241000,
        "value": 139.348,
        "_internal_originalTime": 1689744241000
    },
    {
        "time": 1689744242000,
        "value": 139.347,
        "_internal_originalTime": 1689744242000
    },
    {
        "time": 1689744244000,
        "value": 139.347,
        "_internal_originalTime": 1689744244000
    },
    {
        "time": 1689744246000,
        "value": 139.347,
        "_internal_originalTime": 1689744246000
    },
    {
        "time": 1689744249000,
        "value": 139.347,
        "_internal_originalTime": 1689744249000
    },
    {
        "time": 1689744250000,
        "value": 139.347,
        "_internal_originalTime": 1689744250000
    },
    {
        "time": 1689744252000,
        "value": 139.348,
        "_internal_originalTime": 1689744252000
    },
    {
        "time": 1689744253000,
        "value": 139.343,
        "_internal_originalTime": 1689744253000
    },
    {
        "time": 1689744254000,
        "value": 139.346,
        "_internal_originalTime": 1689744254000
    },
    {
        "time": 1689744256000,
        "value": 139.343,
        "_internal_originalTime": 1689744256000
    },
    {
        "time": 1689744257000,
        "value": 139.343,
        "_internal_originalTime": 1689744257000
    },
    {
        "time": 1689744258000,
        "value": 139.343,
        "_internal_originalTime": 1689744258000
    },
    {
        "time": 1689744259000,
        "value": 139.342,
        "_internal_originalTime": 1689744259000
    },
    {
        "time": 1689744262000,
        "value": 139.346,
        "_internal_originalTime": 1689744262000
    },
    {
        "time": 1689744264000,
        "value": 139.346,
        "_internal_originalTime": 1689744264000
    },
    {
        "time": 1689744268000,
        "value": 139.347,
        "_internal_originalTime": 1689744268000
    },
    {
        "time": 1689744273000,
        "value": 139.332,
        "_internal_originalTime": 1689744273000
    },
    {
        "time": 1689744274000,
        "value": 139.324,
        "_internal_originalTime": 1689744274000
    },
    {
        "time": 1689744275000,
        "value": 139.326,
        "_internal_originalTime": 1689744275000
    },
    {
        "time": 1689744277000,
        "value": 139.321,
        "_internal_originalTime": 1689744277000
    },
    {
        "time": 1689744278000,
        "value": 139.321,
        "_internal_originalTime": 1689744278000
    },
    {
        "time": 1689744279000,
        "value": 139.319,
        "_internal_originalTime": 1689744279000
    },
    {
        "time": 1689744280000,
        "value": 139.322,
        "_internal_originalTime": 1689744280000
    },
    {
        "time": 1689744282000,
        "value": 139.323,
        "_internal_originalTime": 1689744282000
    },
    {
        "time": 1689744283000,
        "value": 139.326,
        "_internal_originalTime": 1689744283000
    },
    {
        "time": 1689744284000,
        "value": 139.322,
        "_internal_originalTime": 1689744284000
    },
    {
        "time": 1689744285000,
        "value": 139.325,
        "_internal_originalTime": 1689744285000
    },
    {
        "time": 1689744286000,
        "value": 139.324,
        "_internal_originalTime": 1689744286000
    },
    {
        "time": 1689744287000,
        "value": 139.324,
        "_internal_originalTime": 1689744287000
    },
    {
        "time": 1689744288000,
        "value": 139.32,
        "_internal_originalTime": 1689744288000
    },
    {
        "time": 1689744291000,
        "value": 139.324,
        "_internal_originalTime": 1689744291000
    },
    {
        "time": 1689744292000,
        "value": 139.326,
        "_internal_originalTime": 1689744292000
    },
    {
        "time": 1689744294000,
        "value": 139.32,
        "_internal_originalTime": 1689744294000
    },
    {
        "time": 1689744295000,
        "value": 139.322,
        "_internal_originalTime": 1689744295000
    },
    {
        "time": 1689744297000,
        "value": 139.322,
        "_internal_originalTime": 1689744297000
    },
    {
        "time": 1689744298000,
        "value": 139.319,
        "_internal_originalTime": 1689744298000
    },
    {
        "time": 1689744299000,
        "value": 139.321,
        "_internal_originalTime": 1689744299000
    },
    {
        "time": 1689744300000,
        "value": 139.322,
        "_internal_originalTime": 1689744300000
    },
    {
        "time": 1689744301000,
        "value": 139.326,
        "_internal_originalTime": 1689744301000
    },
    {
        "time": 1689744303000,
        "value": 139.324,
        "_internal_originalTime": 1689744303000
    },
    {
        "time": 1689744304000,
        "value": 139.325,
        "_internal_originalTime": 1689744304000
    },
    {
        "time": 1689744305000,
        "value": 139.323,
        "_internal_originalTime": 1689744305000
    },
    {
        "time": 1689744307000,
        "value": 139.322,
        "_internal_originalTime": 1689744307000
    },
    {
        "time": 1689744308000,
        "value": 139.322,
        "_internal_originalTime": 1689744308000
    },
    {
        "time": 1689744310000,
        "value": 139.319,
        "_internal_originalTime": 1689744310000
    },
    {
        "time": 1689744311000,
        "value": 139.324,
        "_internal_originalTime": 1689744311000
    },
    {
        "time": 1689744314000,
        "value": 139.32,
        "_internal_originalTime": 1689744314000
    },
    {
        "time": 1689744315000,
        "value": 139.32,
        "_internal_originalTime": 1689744315000
    },
    {
        "time": 1689744316000,
        "value": 139.324,
        "_internal_originalTime": 1689744316000
    },
    {
        "time": 1689744318000,
        "value": 139.324,
        "_internal_originalTime": 1689744318000
    },
    {
        "time": 1689744320000,
        "value": 139.311,
        "_internal_originalTime": 1689744320000
    },
    {
        "time": 1689744322000,
        "value": 139.304,
        "_internal_originalTime": 1689744322000
    },
    {
        "time": 1689744323000,
        "value": 139.306,
        "_internal_originalTime": 1689744323000
    },
    {
        "time": 1689744324000,
        "value": 139.305,
        "_internal_originalTime": 1689744324000
    },
    {
        "time": 1689744325000,
        "value": 139.308,
        "_internal_originalTime": 1689744325000
    },
    {
        "time": 1689744326000,
        "value": 139.303,
        "_internal_originalTime": 1689744326000
    },
    {
        "time": 1689744328000,
        "value": 139.3,
        "_internal_originalTime": 1689744328000
    },
    {
        "time": 1689744330000,
        "value": 139.304,
        "_internal_originalTime": 1689744330000
    },
    {
        "time": 1689744332000,
        "value": 139.303,
        "_internal_originalTime": 1689744332000
    },
    {
        "time": 1689744333000,
        "value": 139.309,
        "_internal_originalTime": 1689744333000
    },
    {
        "time": 1689744334000,
        "value": 139.305,
        "_internal_originalTime": 1689744334000
    },
    {
        "time": 1689744335000,
        "value": 139.304,
        "_internal_originalTime": 1689744335000
    },
    {
        "time": 1689744338000,
        "value": 139.305,
        "_internal_originalTime": 1689744338000
    },
    {
        "time": 1689744340000,
        "value": 139.304,
        "_internal_originalTime": 1689744340000
    },
    {
        "time": 1689744342000,
        "value": 139.304,
        "_internal_originalTime": 1689744342000
    },
    {
        "time": 1689744345000,
        "value": 139.307,
        "_internal_originalTime": 1689744345000
    },
    {
        "time": 1689744346000,
        "value": 139.309,
        "_internal_originalTime": 1689744346000
    },
    {
        "time": 1689744347000,
        "value": 139.301,
        "_internal_originalTime": 1689744347000
    },
    {
        "time": 1689744349000,
        "value": 139.305,
        "_internal_originalTime": 1689744349000
    },
    {
        "time": 1689744350000,
        "value": 139.3,
        "_internal_originalTime": 1689744350000
    },
    {
        "time": 1689744351000,
        "value": 139.304,
        "_internal_originalTime": 1689744351000
    },
    {
        "time": 1689744353000,
        "value": 139.294,
        "_internal_originalTime": 1689744353000
    },
    {
        "time": 1689744354000,
        "value": 139.294,
        "_internal_originalTime": 1689744354000
    },
    {
        "time": 1689744355000,
        "value": 139.291,
        "_internal_originalTime": 1689744355000
    },
    {
        "time": 1689744356000,
        "value": 139.295,
        "_internal_originalTime": 1689744356000
    },
    {
        "time": 1689744358000,
        "value": 139.293,
        "_internal_originalTime": 1689744358000
    },
    {
        "time": 1689744359000,
        "value": 139.294,
        "_internal_originalTime": 1689744359000
    },
    {
        "time": 1689744361000,
        "value": 139.292,
        "_internal_originalTime": 1689744361000
    },
    {
        "time": 1689744362000,
        "value": 139.292,
        "_internal_originalTime": 1689744362000
    },
    {
        "time": 1689744363000,
        "value": 139.292,
        "_internal_originalTime": 1689744363000
    },
    {
        "time": 1689744364000,
        "value": 139.297,
        "_internal_originalTime": 1689744364000
    },
    {
        "time": 1689744366000,
        "value": 139.296,
        "_internal_originalTime": 1689744366000
    },
    {
        "time": 1689744367000,
        "value": 139.295,
        "_internal_originalTime": 1689744367000
    },
    {
        "time": 1689744368000,
        "value": 139.293,
        "_internal_originalTime": 1689744368000
    },
    {
        "time": 1689744370000,
        "value": 139.294,
        "_internal_originalTime": 1689744370000
    },
    {
        "time": 1689744371000,
        "value": 139.296,
        "_internal_originalTime": 1689744371000
    },
    {
        "time": 1689744372000,
        "value": 139.292,
        "_internal_originalTime": 1689744372000
    },
    {
        "time": 1689744373000,
        "value": 139.296,
        "_internal_originalTime": 1689744373000
    },
    {
        "time": 1689744374000,
        "value": 139.294,
        "_internal_originalTime": 1689744374000
    },
    {
        "time": 1689744376000,
        "value": 139.293,
        "_internal_originalTime": 1689744376000
    },
    {
        "time": 1689744377000,
        "value": 139.292,
        "_internal_originalTime": 1689744377000
    },
    {
        "time": 1689744378000,
        "value": 139.296,
        "_internal_originalTime": 1689744378000
    },
    {
        "time": 1689744379000,
        "value": 139.295,
        "_internal_originalTime": 1689744379000
    },
    {
        "time": 1689744382000,
        "value": 139.3,
        "_internal_originalTime": 1689744382000
    },
    {
        "time": 1689744384000,
        "value": 139.302,
        "_internal_originalTime": 1689744384000
    },
    {
        "time": 1689744386000,
        "value": 139.302,
        "_internal_originalTime": 1689744386000
    },
    {
        "time": 1689744387000,
        "value": 139.304,
        "_internal_originalTime": 1689744387000
    },
    {
        "time": 1689744388000,
        "value": 139.301,
        "_internal_originalTime": 1689744388000
    },
    {
        "time": 1689744389000,
        "value": 139.302,
        "_internal_originalTime": 1689744389000
    },
    {
        "time": 1689744390000,
        "value": 139.305,
        "_internal_originalTime": 1689744390000
    },
    {
        "time": 1689744391000,
        "value": 139.298,
        "_internal_originalTime": 1689744391000
    },
    {
        "time": 1689744393000,
        "value": 139.298,
        "_internal_originalTime": 1689744393000
    },
    {
        "time": 1689744394000,
        "value": 139.29,
        "_internal_originalTime": 1689744394000
    },
    {
        "time": 1689744395000,
        "value": 139.29,
        "_internal_originalTime": 1689744395000
    },
    {
        "time": 1689744397000,
        "value": 139.294,
        "_internal_originalTime": 1689744397000
    },
    {
        "time": 1689744398000,
        "value": 139.293,
        "_internal_originalTime": 1689744398000
    },
    {
        "time": 1689744400000,
        "value": 139.294,
        "_internal_originalTime": 1689744400000
    },
    {
        "time": 1689744402000,
        "value": 139.282,
        "_internal_originalTime": 1689744402000
    },
    {
        "time": 1689744403000,
        "value": 139.284,
        "_internal_originalTime": 1689744403000
    },
    {
        "time": 1689744404000,
        "value": 139.284,
        "_internal_originalTime": 1689744404000
    },
    {
        "time": 1689744406000,
        "value": 139.278,
        "_internal_originalTime": 1689744406000
    },
    {
        "time": 1689744407000,
        "value": 139.284,
        "_internal_originalTime": 1689744407000
    },
    {
        "time": 1689744408000,
        "value": 139.282,
        "_internal_originalTime": 1689744408000
    },
    {
        "time": 1689744409000,
        "value": 139.281,
        "_internal_originalTime": 1689744409000
    },
    {
        "time": 1689744411000,
        "value": 139.291,
        "_internal_originalTime": 1689744411000
    },
    {
        "time": 1689744412000,
        "value": 139.29,
        "_internal_originalTime": 1689744412000
    },
    {
        "time": 1689744413000,
        "value": 139.288,
        "_internal_originalTime": 1689744413000
    },
    {
        "time": 1689744414000,
        "value": 139.293,
        "_internal_originalTime": 1689744414000
    },
    {
        "time": 1689744415000,
        "value": 139.29,
        "_internal_originalTime": 1689744415000
    },
    {
        "time": 1689744417000,
        "value": 139.288,
        "_internal_originalTime": 1689744417000
    },
    {
        "time": 1689744418000,
        "value": 139.293,
        "_internal_originalTime": 1689744418000
    },
    {
        "time": 1689744419000,
        "value": 139.293,
        "_internal_originalTime": 1689744419000
    },
    {
        "time": 1689744420000,
        "value": 139.292,
        "_internal_originalTime": 1689744420000
    },
    {
        "time": 1689744421000,
        "value": 139.293,
        "_internal_originalTime": 1689744421000
    },
    {
        "time": 1689744422000,
        "value": 139.294,
        "_internal_originalTime": 1689744422000
    },
    {
        "time": 1689744423000,
        "value": 139.292,
        "_internal_originalTime": 1689744423000
    },
    {
        "time": 1689744424000,
        "value": 139.293,
        "_internal_originalTime": 1689744424000
    },
    {
        "time": 1689744426000,
        "value": 139.292,
        "_internal_originalTime": 1689744426000
    },
    {
        "time": 1689744431000,
        "value": 139.302,
        "_internal_originalTime": 1689744431000
    },
    {
        "time": 1689744433000,
        "value": 139.301,
        "_internal_originalTime": 1689744433000
    },
    {
        "time": 1689744434000,
        "value": 139.301,
        "_internal_originalTime": 1689744434000
    },
    {
        "time": 1689744436000,
        "value": 139.301,
        "_internal_originalTime": 1689744436000
    },
    {
        "time": 1689744438000,
        "value": 139.298,
        "_internal_originalTime": 1689744438000
    },
    {
        "time": 1689744439000,
        "value": 139.302,
        "_internal_originalTime": 1689744439000
    },
    {
        "time": 1689744440000,
        "value": 139.308,
        "_internal_originalTime": 1689744440000
    },
    {
        "time": 1689744442000,
        "value": 139.302,
        "_internal_originalTime": 1689744442000
    },
    {
        "time": 1689744444000,
        "value": 139.302,
        "_internal_originalTime": 1689744444000
    },
    {
        "time": 1689744446000,
        "value": 139.305,
        "_internal_originalTime": 1689744446000
    },
    {
        "time": 1689744448000,
        "value": 139.3,
        "_internal_originalTime": 1689744448000
    },
    {
        "time": 1689744450000,
        "value": 139.305,
        "_internal_originalTime": 1689744450000
    },
    {
        "time": 1689744451000,
        "value": 139.304,
        "_internal_originalTime": 1689744451000
    },
    {
        "time": 1689744452000,
        "value": 139.304,
        "_internal_originalTime": 1689744452000
    },
    {
        "time": 1689744455000,
        "value": 139.303,
        "_internal_originalTime": 1689744455000
    },
    {
        "time": 1689744457000,
        "value": 139.3,
        "_internal_originalTime": 1689744457000
    },
    {
        "time": 1689744459000,
        "value": 139.303,
        "_internal_originalTime": 1689744459000
    },
    {
        "time": 1689744462000,
        "value": 139.298,
        "_internal_originalTime": 1689744462000
    },
    {
        "time": 1689744463000,
        "value": 139.298,
        "_internal_originalTime": 1689744463000
    },
    {
        "time": 1689744465000,
        "value": 139.301,
        "_internal_originalTime": 1689744465000
    },
    {
        "time": 1689744466000,
        "value": 139.299,
        "_internal_originalTime": 1689744466000
    },
    {
        "time": 1689744468000,
        "value": 139.301,
        "_internal_originalTime": 1689744468000
    },
    {
        "time": 1689744470000,
        "value": 139.306,
        "_internal_originalTime": 1689744470000
    },
    {
        "time": 1689744471000,
        "value": 139.305,
        "_internal_originalTime": 1689744471000
    },
    {
        "time": 1689744474000,
        "value": 139.305,
        "_internal_originalTime": 1689744474000
    },
    {
        "time": 1689744476000,
        "value": 139.303,
        "_internal_originalTime": 1689744476000
    },
    {
        "time": 1689744477000,
        "value": 139.304,
        "_internal_originalTime": 1689744477000
    },
    {
        "time": 1689744479000,
        "value": 139.307,
        "_internal_originalTime": 1689744479000
    },
    {
        "time": 1689744480000,
        "value": 139.304,
        "_internal_originalTime": 1689744480000
    },
    {
        "time": 1689744481000,
        "value": 139.307,
        "_internal_originalTime": 1689744481000
    },
    {
        "time": 1689744482000,
        "value": 139.306,
        "_internal_originalTime": 1689744482000
    },
    {
        "time": 1689744484000,
        "value": 139.307,
        "_internal_originalTime": 1689744484000
    },
    {
        "time": 1689744486000,
        "value": 139.306,
        "_internal_originalTime": 1689744486000
    },
    {
        "time": 1689744487000,
        "value": 139.304,
        "_internal_originalTime": 1689744487000
    },
    {
        "time": 1689744488000,
        "value": 139.304,
        "_internal_originalTime": 1689744488000
    },
    {
        "time": 1689744490000,
        "value": 139.305,
        "_internal_originalTime": 1689744490000
    },
    {
        "time": 1689744491000,
        "value": 139.308,
        "_internal_originalTime": 1689744491000
    },
    {
        "time": 1689744492000,
        "value": 139.303,
        "_internal_originalTime": 1689744492000
    },
    {
        "time": 1689744494000,
        "value": 139.304,
        "_internal_originalTime": 1689744494000
    },
    {
        "time": 1689744496000,
        "value": 139.303,
        "_internal_originalTime": 1689744496000
    },
    {
        "time": 1689744497000,
        "value": 139.309,
        "_internal_originalTime": 1689744497000
    },
    {
        "time": 1689744499000,
        "value": 139.307,
        "_internal_originalTime": 1689744499000
    },
    {
        "time": 1689744500000,
        "value": 139.305,
        "_internal_originalTime": 1689744500000
    },
    {
        "time": 1689744501000,
        "value": 139.306,
        "_internal_originalTime": 1689744501000
    },
    {
        "time": 1689744502000,
        "value": 139.304,
        "_internal_originalTime": 1689744502000
    },
    {
        "time": 1689744504000,
        "value": 139.304,
        "_internal_originalTime": 1689744504000
    },
    {
        "time": 1689744506000,
        "value": 139.302,
        "_internal_originalTime": 1689744506000
    },
    {
        "time": 1689744508000,
        "value": 139.301,
        "_internal_originalTime": 1689744508000
    },
    {
        "time": 1689744509000,
        "value": 139.304,
        "_internal_originalTime": 1689744509000
    },
    {
        "time": 1689744511000,
        "value": 139.303,
        "_internal_originalTime": 1689744511000
    },
    {
        "time": 1689744512000,
        "value": 139.303,
        "_internal_originalTime": 1689744512000
    },
    {
        "time": 1689744513000,
        "value": 139.301,
        "_internal_originalTime": 1689744513000
    },
    {
        "time": 1689744514000,
        "value": 139.303,
        "_internal_originalTime": 1689744514000
    },
    {
        "time": 1689744516000,
        "value": 139.302,
        "_internal_originalTime": 1689744516000
    },
    {
        "time": 1689744518000,
        "value": 139.301,
        "_internal_originalTime": 1689744518000
    },
    {
        "time": 1689744519000,
        "value": 139.304,
        "_internal_originalTime": 1689744519000
    },
    {
        "time": 1689744520000,
        "value": 139.305,
        "_internal_originalTime": 1689744520000
    },
    {
        "time": 1689744521000,
        "value": 139.305,
        "_internal_originalTime": 1689744521000
    },
    {
        "time": 1689744523000,
        "value": 139.311,
        "_internal_originalTime": 1689744523000
    },
    {
        "time": 1689744524000,
        "value": 139.313,
        "_internal_originalTime": 1689744524000
    },
    {
        "time": 1689744526000,
        "value": 139.314,
        "_internal_originalTime": 1689744526000
    },
    {
        "time": 1689744527000,
        "value": 139.316,
        "_internal_originalTime": 1689744527000
    },
    {
        "time": 1689744528000,
        "value": 139.317,
        "_internal_originalTime": 1689744528000
    },
    {
        "time": 1689744530000,
        "value": 139.319,
        "_internal_originalTime": 1689744530000
    },
    {
        "time": 1689744531000,
        "value": 139.324,
        "_internal_originalTime": 1689744531000
    },
    {
        "time": 1689744532000,
        "value": 139.324,
        "_internal_originalTime": 1689744532000
    },
    {
        "time": 1689744534000,
        "value": 139.324,
        "_internal_originalTime": 1689744534000
    },
    {
        "time": 1689744535000,
        "value": 139.323,
        "_internal_originalTime": 1689744535000
    },
    {
        "time": 1689744537000,
        "value": 139.323,
        "_internal_originalTime": 1689744537000
    },
    {
        "time": 1689744538000,
        "value": 139.323,
        "_internal_originalTime": 1689744538000
    },
    {
        "time": 1689744539000,
        "value": 139.321,
        "_internal_originalTime": 1689744539000
    },
    {
        "time": 1689744540000,
        "value": 139.322,
        "_internal_originalTime": 1689744540000
    },
    {
        "time": 1689744542000,
        "value": 139.319,
        "_internal_originalTime": 1689744542000
    },
    {
        "time": 1689744543000,
        "value": 139.323,
        "_internal_originalTime": 1689744543000
    },
    {
        "time": 1689744544000,
        "value": 139.323,
        "_internal_originalTime": 1689744544000
    },
    {
        "time": 1689744545000,
        "value": 139.323,
        "_internal_originalTime": 1689744545000
    },
    {
        "time": 1689744546000,
        "value": 139.323,
        "_internal_originalTime": 1689744546000
    },
    {
        "time": 1689744548000,
        "value": 139.323,
        "_internal_originalTime": 1689744548000
    },
    {
        "time": 1689744549000,
        "value": 139.331,
        "_internal_originalTime": 1689744549000
    },
    {
        "time": 1689744550000,
        "value": 139.326,
        "_internal_originalTime": 1689744550000
    },
    {
        "time": 1689744551000,
        "value": 139.323,
        "_internal_originalTime": 1689744551000
    },
    {
        "time": 1689744552000,
        "value": 139.326,
        "_internal_originalTime": 1689744552000
    },
    {
        "time": 1689744554000,
        "value": 139.325,
        "_internal_originalTime": 1689744554000
    },
    {
        "time": 1689744555000,
        "value": 139.323,
        "_internal_originalTime": 1689744555000
    },
    {
        "time": 1689744556000,
        "value": 139.322,
        "_internal_originalTime": 1689744556000
    },
    {
        "time": 1689744558000,
        "value": 139.323,
        "_internal_originalTime": 1689744558000
    },
    {
        "time": 1689744559000,
        "value": 139.322,
        "_internal_originalTime": 1689744559000
    },
    {
        "time": 1689744560000,
        "value": 139.323,
        "_internal_originalTime": 1689744560000
    },
    {
        "time": 1689744561000,
        "value": 139.322,
        "_internal_originalTime": 1689744561000
    },
    {
        "time": 1689744562000,
        "value": 139.323,
        "_internal_originalTime": 1689744562000
    },
    {
        "time": 1689744567000,
        "value": 139.323,
        "_internal_originalTime": 1689744567000
    },
    {
        "time": 1689744568000,
        "value": 139.326,
        "_internal_originalTime": 1689744568000
    },
    {
        "time": 1689744569000,
        "value": 139.326,
        "_internal_originalTime": 1689744569000
    },
    {
        "time": 1689744570000,
        "value": 139.325,
        "_internal_originalTime": 1689744570000
    },
    {
        "time": 1689744573000,
        "value": 139.314,
        "_internal_originalTime": 1689744573000
    },
    {
        "time": 1689744574000,
        "value": 139.317,
        "_internal_originalTime": 1689744574000
    },
    {
        "time": 1689744575000,
        "value": 139.313,
        "_internal_originalTime": 1689744575000
    },
    {
        "time": 1689744576000,
        "value": 139.313,
        "_internal_originalTime": 1689744576000
    },
    {
        "time": 1689744577000,
        "value": 139.31,
        "_internal_originalTime": 1689744577000
    },
    {
        "time": 1689744579000,
        "value": 139.314,
        "_internal_originalTime": 1689744579000
    },
    {
        "time": 1689744580000,
        "value": 139.315,
        "_internal_originalTime": 1689744580000
    },
    {
        "time": 1689744581000,
        "value": 139.312,
        "_internal_originalTime": 1689744581000
    },
    {
        "time": 1689744582000,
        "value": 139.317,
        "_internal_originalTime": 1689744582000
    },
    {
        "time": 1689744589000,
        "value": 139.315,
        "_internal_originalTime": 1689744589000
    },
    {
        "time": 1689744590000,
        "value": 139.312,
        "_internal_originalTime": 1689744590000
    },
    {
        "time": 1689744592000,
        "value": 139.313,
        "_internal_originalTime": 1689744592000
    },
    {
        "time": 1689744594000,
        "value": 139.317,
        "_internal_originalTime": 1689744594000
    },
    {
        "time": 1689744595000,
        "value": 139.314,
        "_internal_originalTime": 1689744595000
    },
    {
        "time": 1689744597000,
        "value": 139.317,
        "_internal_originalTime": 1689744597000
    },
    {
        "time": 1689744599000,
        "value": 139.312,
        "_internal_originalTime": 1689744599000
    },
    {
        "time": 1689744600000,
        "value": 139.318,
        "_internal_originalTime": 1689744600000
    },
    {
        "time": 1689744602000,
        "value": 139.316,
        "_internal_originalTime": 1689744602000
    },
    {
        "time": 1689744603000,
        "value": 139.315,
        "_internal_originalTime": 1689744603000
    },
    {
        "time": 1689744604000,
        "value": 139.31,
        "_internal_originalTime": 1689744604000
    },
    {
        "time": 1689744605000,
        "value": 139.317,
        "_internal_originalTime": 1689744605000
    },
    {
        "time": 1689744606000,
        "value": 139.32,
        "_internal_originalTime": 1689744606000
    },
    {
        "time": 1689744607000,
        "value": 139.32,
        "_internal_originalTime": 1689744607000
    },
    {
        "time": 1689744608000,
        "value": 139.319,
        "_internal_originalTime": 1689744608000
    },
    {
        "time": 1689744611000,
        "value": 139.32,
        "_internal_originalTime": 1689744611000
    },
    {
        "time": 1689744612000,
        "value": 139.323,
        "_internal_originalTime": 1689744612000
    },
    {
        "time": 1689744614000,
        "value": 139.324,
        "_internal_originalTime": 1689744614000
    },
    {
        "time": 1689744615000,
        "value": 139.324,
        "_internal_originalTime": 1689744615000
    },
    {
        "time": 1689744616000,
        "value": 139.323,
        "_internal_originalTime": 1689744616000
    },
    {
        "time": 1689744617000,
        "value": 139.32,
        "_internal_originalTime": 1689744617000
    },
    {
        "time": 1689744618000,
        "value": 139.323,
        "_internal_originalTime": 1689744618000
    },
    {
        "time": 1689744620000,
        "value": 139.322,
        "_internal_originalTime": 1689744620000
    },
    {
        "time": 1689744621000,
        "value": 139.322,
        "_internal_originalTime": 1689744621000
    },
    {
        "time": 1689744622000,
        "value": 139.32,
        "_internal_originalTime": 1689744622000
    },
    {
        "time": 1689744623000,
        "value": 139.332,
        "_internal_originalTime": 1689744623000
    },
    {
        "time": 1689744625000,
        "value": 139.329,
        "_internal_originalTime": 1689744625000
    },
    {
        "time": 1689744626000,
        "value": 139.328,
        "_internal_originalTime": 1689744626000
    },
    {
        "time": 1689744628000,
        "value": 139.328,
        "_internal_originalTime": 1689744628000
    },
    {
        "time": 1689744629000,
        "value": 139.326,
        "_internal_originalTime": 1689744629000
    },
    {
        "time": 1689744630000,
        "value": 139.328,
        "_internal_originalTime": 1689744630000
    },
    {
        "time": 1689744631000,
        "value": 139.336,
        "_internal_originalTime": 1689744631000
    },
    {
        "time": 1689744632000,
        "value": 139.333,
        "_internal_originalTime": 1689744632000
    },
    {
        "time": 1689744634000,
        "value": 139.338,
        "_internal_originalTime": 1689744634000
    },
    {
        "time": 1689744635000,
        "value": 139.336,
        "_internal_originalTime": 1689744635000
    },
    {
        "time": 1689744637000,
        "value": 139.335,
        "_internal_originalTime": 1689744637000
    },
    {
        "time": 1689744638000,
        "value": 139.335,
        "_internal_originalTime": 1689744638000
    },
    {
        "time": 1689744640000,
        "value": 139.336,
        "_internal_originalTime": 1689744640000
    },
    {
        "time": 1689744642000,
        "value": 139.336,
        "_internal_originalTime": 1689744642000
    },
    {
        "time": 1689744650000,
        "value": 139.325,
        "_internal_originalTime": 1689744650000
    },
    {
        "time": 1689744652000,
        "value": 139.317,
        "_internal_originalTime": 1689744652000
    },
    {
        "time": 1689744653000,
        "value": 139.32,
        "_internal_originalTime": 1689744653000
    },
    {
        "time": 1689744654000,
        "value": 139.319,
        "_internal_originalTime": 1689744654000
    },
    {
        "time": 1689744655000,
        "value": 139.317,
        "_internal_originalTime": 1689744655000
    },
    {
        "time": 1689744657000,
        "value": 139.314,
        "_internal_originalTime": 1689744657000
    },
    {
        "time": 1689744658000,
        "value": 139.316,
        "_internal_originalTime": 1689744658000
    },
    {
        "time": 1689744660000,
        "value": 139.316,
        "_internal_originalTime": 1689744660000
    },
    {
        "time": 1689744661000,
        "value": 139.314,
        "_internal_originalTime": 1689744661000
    },
    {
        "time": 1689744662000,
        "value": 139.313,
        "_internal_originalTime": 1689744662000
    },
    {
        "time": 1689744664000,
        "value": 139.312,
        "_internal_originalTime": 1689744664000
    },
    {
        "time": 1689744666000,
        "value": 139.317,
        "_internal_originalTime": 1689744666000
    },
    {
        "time": 1689744667000,
        "value": 139.314,
        "_internal_originalTime": 1689744667000
    },
    {
        "time": 1689744668000,
        "value": 139.314,
        "_internal_originalTime": 1689744668000
    },
    {
        "time": 1689744669000,
        "value": 139.317,
        "_internal_originalTime": 1689744669000
    },
    {
        "time": 1689744670000,
        "value": 139.317,
        "_internal_originalTime": 1689744670000
    },
    {
        "time": 1689744671000,
        "value": 139.318,
        "_internal_originalTime": 1689744671000
    },
    {
        "time": 1689744673000,
        "value": 139.318,
        "_internal_originalTime": 1689744673000
    },
    {
        "time": 1689744675000,
        "value": 139.314,
        "_internal_originalTime": 1689744675000
    },
    {
        "time": 1689744676000,
        "value": 139.313,
        "_internal_originalTime": 1689744676000
    },
    {
        "time": 1689744679000,
        "value": 139.312,
        "_internal_originalTime": 1689744679000
    },
    {
        "time": 1689744680000,
        "value": 139.316,
        "_internal_originalTime": 1689744680000
    },
    {
        "time": 1689744682000,
        "value": 139.303,
        "_internal_originalTime": 1689744682000
    },
    {
        "time": 1689744683000,
        "value": 139.307,
        "_internal_originalTime": 1689744683000
    },
    {
        "time": 1689744684000,
        "value": 139.305,
        "_internal_originalTime": 1689744684000
    },
    {
        "time": 1689744685000,
        "value": 139.304,
        "_internal_originalTime": 1689744685000
    },
    {
        "time": 1689744687000,
        "value": 139.304,
        "_internal_originalTime": 1689744687000
    },
    {
        "time": 1689744688000,
        "value": 139.306,
        "_internal_originalTime": 1689744688000
    },
    {
        "time": 1689744689000,
        "value": 139.303,
        "_internal_originalTime": 1689744689000
    },
    {
        "time": 1689744690000,
        "value": 139.306,
        "_internal_originalTime": 1689744690000
    },
    {
        "time": 1689744691000,
        "value": 139.308,
        "_internal_originalTime": 1689744691000
    },
    {
        "time": 1689744694000,
        "value": 139.301,
        "_internal_originalTime": 1689744694000
    },
    {
        "time": 1689744695000,
        "value": 139.301,
        "_internal_originalTime": 1689744695000
    },
    {
        "time": 1689744696000,
        "value": 139.306,
        "_internal_originalTime": 1689744696000
    },
    {
        "time": 1689744699000,
        "value": 139.301,
        "_internal_originalTime": 1689744699000
    },
    {
        "time": 1689744700000,
        "value": 139.303,
        "_internal_originalTime": 1689744700000
    },
    {
        "time": 1689744701000,
        "value": 139.3,
        "_internal_originalTime": 1689744701000
    },
    {
        "time": 1689744702000,
        "value": 139.301,
        "_internal_originalTime": 1689744702000
    },
    {
        "time": 1689744704000,
        "value": 139.315,
        "_internal_originalTime": 1689744704000
    },
    {
        "time": 1689744705000,
        "value": 139.315,
        "_internal_originalTime": 1689744705000
    },
    {
        "time": 1689744707000,
        "value": 139.323,
        "_internal_originalTime": 1689744707000
    },
    {
        "time": 1689744708000,
        "value": 139.32,
        "_internal_originalTime": 1689744708000
    },
    {
        "time": 1689744709000,
        "value": 139.317,
        "_internal_originalTime": 1689744709000
    },
    {
        "time": 1689744711000,
        "value": 139.318,
        "_internal_originalTime": 1689744711000
    },
    {
        "time": 1689744712000,
        "value": 139.32,
        "_internal_originalTime": 1689744712000
    },
    {
        "time": 1689744714000,
        "value": 139.325,
        "_internal_originalTime": 1689744714000
    },
    {
        "time": 1689744716000,
        "value": 139.324,
        "_internal_originalTime": 1689744716000
    },
    {
        "time": 1689744717000,
        "value": 139.325,
        "_internal_originalTime": 1689744717000
    },
    {
        "time": 1689744718000,
        "value": 139.32,
        "_internal_originalTime": 1689744718000
    },
    {
        "time": 1689744719000,
        "value": 139.32,
        "_internal_originalTime": 1689744719000
    },
    {
        "time": 1689744721000,
        "value": 139.321,
        "_internal_originalTime": 1689744721000
    },
    {
        "time": 1689744722000,
        "value": 139.325,
        "_internal_originalTime": 1689744722000
    },
    {
        "time": 1689744723000,
        "value": 139.323,
        "_internal_originalTime": 1689744723000
    },
    {
        "time": 1689744724000,
        "value": 139.328,
        "_internal_originalTime": 1689744724000
    },
    {
        "time": 1689744725000,
        "value": 139.326,
        "_internal_originalTime": 1689744725000
    },
    {
        "time": 1689744726000,
        "value": 139.33,
        "_internal_originalTime": 1689744726000
    },
    {
        "time": 1689744728000,
        "value": 139.327,
        "_internal_originalTime": 1689744728000
    },
    {
        "time": 1689744729000,
        "value": 139.329,
        "_internal_originalTime": 1689744729000
    },
    {
        "time": 1689744730000,
        "value": 139.326,
        "_internal_originalTime": 1689744730000
    },
    {
        "time": 1689744731000,
        "value": 139.331,
        "_internal_originalTime": 1689744731000
    },
    {
        "time": 1689744733000,
        "value": 139.328,
        "_internal_originalTime": 1689744733000
    },
    {
        "time": 1689744734000,
        "value": 139.329,
        "_internal_originalTime": 1689744734000
    },
    {
        "time": 1689744735000,
        "value": 139.331,
        "_internal_originalTime": 1689744735000
    },
    {
        "time": 1689744736000,
        "value": 139.331,
        "_internal_originalTime": 1689744736000
    },
    {
        "time": 1689744740000,
        "value": 139.327,
        "_internal_originalTime": 1689744740000
    },
    {
        "time": 1689744742000,
        "value": 139.334,
        "_internal_originalTime": 1689744742000
    },
    {
        "time": 1689744744000,
        "value": 139.331,
        "_internal_originalTime": 1689744744000
    },
    {
        "time": 1689744745000,
        "value": 139.329,
        "_internal_originalTime": 1689744745000
    },
    {
        "time": 1689744746000,
        "value": 139.331,
        "_internal_originalTime": 1689744746000
    },
    {
        "time": 1689744748000,
        "value": 139.331,
        "_internal_originalTime": 1689744748000
    },
    {
        "time": 1689744751000,
        "value": 139.335,
        "_internal_originalTime": 1689744751000
    },
    {
        "time": 1689744753000,
        "value": 139.335,
        "_internal_originalTime": 1689744753000
    },
    {
        "time": 1689744754000,
        "value": 139.336,
        "_internal_originalTime": 1689744754000
    },
    {
        "time": 1689744756000,
        "value": 139.337,
        "_internal_originalTime": 1689744756000
    },
    {
        "time": 1689744758000,
        "value": 139.336,
        "_internal_originalTime": 1689744758000
    },
    {
        "time": 1689744762000,
        "value": 139.333,
        "_internal_originalTime": 1689744762000
    },
    {
        "time": 1689744764000,
        "value": 139.332,
        "_internal_originalTime": 1689744764000
    },
    {
        "time": 1689744766000,
        "value": 139.333,
        "_internal_originalTime": 1689744766000
    },
    {
        "time": 1689744778000,
        "value": 139.338,
        "_internal_originalTime": 1689744778000
    },
    {
        "time": 1689744779000,
        "value": 139.333,
        "_internal_originalTime": 1689744779000
    },
    {
        "time": 1689744781000,
        "value": 139.336,
        "_internal_originalTime": 1689744781000
    },
    {
        "time": 1689744788000,
        "value": 139.335,
        "_internal_originalTime": 1689744788000
    },
    {
        "time": 1689744792000,
        "value": 139.337,
        "_internal_originalTime": 1689744792000
    },
    {
        "time": 1689744794000,
        "value": 139.335,
        "_internal_originalTime": 1689744794000
    },
    {
        "time": 1689744796000,
        "value": 139.333,
        "_internal_originalTime": 1689744796000
    },
    {
        "time": 1689744797000,
        "value": 139.335,
        "_internal_originalTime": 1689744797000
    },
    {
        "time": 1689744798000,
        "value": 139.334,
        "_internal_originalTime": 1689744798000
    },
    {
        "time": 1689744800000,
        "value": 139.335,
        "_internal_originalTime": 1689744800000
    },
    {
        "time": 1689744801000,
        "value": 139.335,
        "_internal_originalTime": 1689744801000
    },
    {
        "time": 1689744802000,
        "value": 139.336,
        "_internal_originalTime": 1689744802000
    },
    {
        "time": 1689744803000,
        "value": 139.335,
        "_internal_originalTime": 1689744803000
    },
    {
        "time": 1689744806000,
        "value": 139.336,
        "_internal_originalTime": 1689744806000
    },
    {
        "time": 1689744807000,
        "value": 139.335,
        "_internal_originalTime": 1689744807000
    },
    {
        "time": 1689744808000,
        "value": 139.331,
        "_internal_originalTime": 1689744808000
    },
    {
        "time": 1689744810000,
        "value": 139.336,
        "_internal_originalTime": 1689744810000
    },
    {
        "time": 1689744812000,
        "value": 139.333,
        "_internal_originalTime": 1689744812000
    },
    {
        "time": 1689744813000,
        "value": 139.337,
        "_internal_originalTime": 1689744813000
    },
    {
        "time": 1689744817000,
        "value": 139.333,
        "_internal_originalTime": 1689744817000
    },
    {
        "time": 1689744818000,
        "value": 139.334,
        "_internal_originalTime": 1689744818000
    },
    {
        "time": 1689744823000,
        "value": 139.336,
        "_internal_originalTime": 1689744823000
    },
    {
        "time": 1689744828000,
        "value": 139.335,
        "_internal_originalTime": 1689744828000
    },
    {
        "time": 1689744829000,
        "value": 139.335,
        "_internal_originalTime": 1689744829000
    },
    {
        "time": 1689744830000,
        "value": 139.336,
        "_internal_originalTime": 1689744830000
    },
    {
        "time": 1689744831000,
        "value": 139.335,
        "_internal_originalTime": 1689744831000
    },
    {
        "time": 1689744833000,
        "value": 139.32,
        "_internal_originalTime": 1689744833000
    },
    {
        "time": 1689744834000,
        "value": 139.318,
        "_internal_originalTime": 1689744834000
    },
    {
        "time": 1689744835000,
        "value": 139.313,
        "_internal_originalTime": 1689744835000
    },
    {
        "time": 1689744837000,
        "value": 139.313,
        "_internal_originalTime": 1689744837000
    },
    {
        "time": 1689744838000,
        "value": 139.308,
        "_internal_originalTime": 1689744838000
    },
    {
        "time": 1689744839000,
        "value": 139.311,
        "_internal_originalTime": 1689744839000
    },
    {
        "time": 1689744840000,
        "value": 139.312,
        "_internal_originalTime": 1689744840000
    },
    {
        "time": 1689744841000,
        "value": 139.313,
        "_internal_originalTime": 1689744841000
    },
    {
        "time": 1689744842000,
        "value": 139.315,
        "_internal_originalTime": 1689744842000
    },
    {
        "time": 1689744843000,
        "value": 139.31,
        "_internal_originalTime": 1689744843000
    },
    {
        "time": 1689744844000,
        "value": 139.311,
        "_internal_originalTime": 1689744844000
    },
    {
        "time": 1689744845000,
        "value": 139.316,
        "_internal_originalTime": 1689744845000
    },
    {
        "time": 1689744846000,
        "value": 139.31,
        "_internal_originalTime": 1689744846000
    },
    {
        "time": 1689744848000,
        "value": 139.314,
        "_internal_originalTime": 1689744848000
    },
    {
        "time": 1689744851000,
        "value": 139.313,
        "_internal_originalTime": 1689744851000
    },
    {
        "time": 1689744853000,
        "value": 139.312,
        "_internal_originalTime": 1689744853000
    },
    {
        "time": 1689744854000,
        "value": 139.314,
        "_internal_originalTime": 1689744854000
    },
    {
        "time": 1689744855000,
        "value": 139.317,
        "_internal_originalTime": 1689744855000
    },
    {
        "time": 1689744858000,
        "value": 139.314,
        "_internal_originalTime": 1689744858000
    },
    {
        "time": 1689744859000,
        "value": 139.313,
        "_internal_originalTime": 1689744859000
    },
    {
        "time": 1689744860000,
        "value": 139.315,
        "_internal_originalTime": 1689744860000
    },
    {
        "time": 1689744862000,
        "value": 139.311,
        "_internal_originalTime": 1689744862000
    },
    {
        "time": 1689744863000,
        "value": 139.314,
        "_internal_originalTime": 1689744863000
    },
    {
        "time": 1689744865000,
        "value": 139.315,
        "_internal_originalTime": 1689744865000
    },
    {
        "time": 1689744867000,
        "value": 139.315,
        "_internal_originalTime": 1689744867000
    },
    {
        "time": 1689744868000,
        "value": 139.314,
        "_internal_originalTime": 1689744868000
    },
    {
        "time": 1689744869000,
        "value": 139.315,
        "_internal_originalTime": 1689744869000
    },
    {
        "time": 1689744870000,
        "value": 139.316,
        "_internal_originalTime": 1689744870000
    },
    {
        "time": 1689744871000,
        "value": 139.317,
        "_internal_originalTime": 1689744871000
    },
    {
        "time": 1689744876000,
        "value": 139.322,
        "_internal_originalTime": 1689744876000
    },
    {
        "time": 1689744878000,
        "value": 139.312,
        "_internal_originalTime": 1689744878000
    },
    {
        "time": 1689744879000,
        "value": 139.318,
        "_internal_originalTime": 1689744879000
    },
    {
        "time": 1689744882000,
        "value": 139.312,
        "_internal_originalTime": 1689744882000
    },
    {
        "time": 1689744883000,
        "value": 139.314,
        "_internal_originalTime": 1689744883000
    },
    {
        "time": 1689744884000,
        "value": 139.314,
        "_internal_originalTime": 1689744884000
    },
    {
        "time": 1689744885000,
        "value": 139.313,
        "_internal_originalTime": 1689744885000
    },
    {
        "time": 1689744886000,
        "value": 139.312,
        "_internal_originalTime": 1689744886000
    },
    {
        "time": 1689744887000,
        "value": 139.311,
        "_internal_originalTime": 1689744887000
    },
    {
        "time": 1689744890000,
        "value": 139.315,
        "_internal_originalTime": 1689744890000
    },
    {
        "time": 1689744891000,
        "value": 139.31,
        "_internal_originalTime": 1689744891000
    },
    {
        "time": 1689744892000,
        "value": 139.31,
        "_internal_originalTime": 1689744892000
    },
    {
        "time": 1689744894000,
        "value": 139.31,
        "_internal_originalTime": 1689744894000
    },
    {
        "time": 1689744895000,
        "value": 139.312,
        "_internal_originalTime": 1689744895000
    },
    {
        "time": 1689744897000,
        "value": 139.312,
        "_internal_originalTime": 1689744897000
    },
    {
        "time": 1689744898000,
        "value": 139.319,
        "_internal_originalTime": 1689744898000
    },
    {
        "time": 1689744899000,
        "value": 139.323,
        "_internal_originalTime": 1689744899000
    },
    {
        "time": 1689744900000,
        "value": 139.324,
        "_internal_originalTime": 1689744900000
    },
    {
        "time": 1689744901000,
        "value": 139.324,
        "_internal_originalTime": 1689744901000
    },
    {
        "time": 1689744902000,
        "value": 139.324,
        "_internal_originalTime": 1689744902000
    },
    {
        "time": 1689744903000,
        "value": 139.333,
        "_internal_originalTime": 1689744903000
    },
    {
        "time": 1689744904000,
        "value": 139.336,
        "_internal_originalTime": 1689744904000
    },
    {
        "time": 1689744905000,
        "value": 139.335,
        "_internal_originalTime": 1689744905000
    },
    {
        "time": 1689744906000,
        "value": 139.335,
        "_internal_originalTime": 1689744906000
    },
    {
        "time": 1689744908000,
        "value": 139.342,
        "_internal_originalTime": 1689744908000
    },
    {
        "time": 1689744909000,
        "value": 139.344,
        "_internal_originalTime": 1689744909000
    },
    {
        "time": 1689744910000,
        "value": 139.341,
        "_internal_originalTime": 1689744910000
    },
    {
        "time": 1689744911000,
        "value": 139.342,
        "_internal_originalTime": 1689744911000
    },
    {
        "time": 1689744913000,
        "value": 139.34,
        "_internal_originalTime": 1689744913000
    },
    {
        "time": 1689744914000,
        "value": 139.341,
        "_internal_originalTime": 1689744914000
    },
    {
        "time": 1689744917000,
        "value": 139.343,
        "_internal_originalTime": 1689744917000
    },
    {
        "time": 1689744918000,
        "value": 139.342,
        "_internal_originalTime": 1689744918000
    },
    {
        "time": 1689744920000,
        "value": 139.334,
        "_internal_originalTime": 1689744920000
    },
    {
        "time": 1689744921000,
        "value": 139.331,
        "_internal_originalTime": 1689744921000
    },
    {
        "time": 1689744922000,
        "value": 139.329,
        "_internal_originalTime": 1689744922000
    },
    {
        "time": 1689744924000,
        "value": 139.322,
        "_internal_originalTime": 1689744924000
    },
    {
        "time": 1689744925000,
        "value": 139.322,
        "_internal_originalTime": 1689744925000
    },
    {
        "time": 1689744926000,
        "value": 139.322,
        "_internal_originalTime": 1689744926000
    },
    {
        "time": 1689744929000,
        "value": 139.324,
        "_internal_originalTime": 1689744929000
    },
    {
        "time": 1689744930000,
        "value": 139.321,
        "_internal_originalTime": 1689744930000
    },
    {
        "time": 1689744931000,
        "value": 139.32,
        "_internal_originalTime": 1689744931000
    },
    {
        "time": 1689744932000,
        "value": 139.324,
        "_internal_originalTime": 1689744932000
    },
    {
        "time": 1689744935000,
        "value": 139.326,
        "_internal_originalTime": 1689744935000
    },
    {
        "time": 1689744937000,
        "value": 139.319,
        "_internal_originalTime": 1689744937000
    },
    {
        "time": 1689744939000,
        "value": 139.321,
        "_internal_originalTime": 1689744939000
    },
    {
        "time": 1689744940000,
        "value": 139.321,
        "_internal_originalTime": 1689744940000
    },
    {
        "time": 1689744942000,
        "value": 139.321,
        "_internal_originalTime": 1689744942000
    },
    {
        "time": 1689744944000,
        "value": 139.32,
        "_internal_originalTime": 1689744944000
    },
    {
        "time": 1689744947000,
        "value": 139.329,
        "_internal_originalTime": 1689744947000
    },
    {
        "time": 1689744948000,
        "value": 139.333,
        "_internal_originalTime": 1689744948000
    },
    {
        "time": 1689744951000,
        "value": 139.331,
        "_internal_originalTime": 1689744951000
    },
    {
        "time": 1689744952000,
        "value": 139.332,
        "_internal_originalTime": 1689744952000
    },
    {
        "time": 1689744953000,
        "value": 139.328,
        "_internal_originalTime": 1689744953000
    },
    {
        "time": 1689744957000,
        "value": 139.333,
        "_internal_originalTime": 1689744957000
    },
    {
        "time": 1689744959000,
        "value": 139.331,
        "_internal_originalTime": 1689744959000
    },
    {
        "time": 1689744960000,
        "value": 139.332,
        "_internal_originalTime": 1689744960000
    },
    {
        "time": 1689744961000,
        "value": 139.331,
        "_internal_originalTime": 1689744961000
    },
    {
        "time": 1689744962000,
        "value": 139.331,
        "_internal_originalTime": 1689744962000
    },
    {
        "time": 1689744964000,
        "value": 139.329,
        "_internal_originalTime": 1689744964000
    },
    {
        "time": 1689744965000,
        "value": 139.333,
        "_internal_originalTime": 1689744965000
    },
    {
        "time": 1689744966000,
        "value": 139.332,
        "_internal_originalTime": 1689744966000
    },
    {
        "time": 1689744968000,
        "value": 139.333,
        "_internal_originalTime": 1689744968000
    },
    {
        "time": 1689744970000,
        "value": 139.335,
        "_internal_originalTime": 1689744970000
    },
    {
        "time": 1689744972000,
        "value": 139.33,
        "_internal_originalTime": 1689744972000
    },
    {
        "time": 1689744973000,
        "value": 139.331,
        "_internal_originalTime": 1689744973000
    },
    {
        "time": 1689744974000,
        "value": 139.33,
        "_internal_originalTime": 1689744974000
    },
    {
        "time": 1689744976000,
        "value": 139.333,
        "_internal_originalTime": 1689744976000
    },
    {
        "time": 1689744977000,
        "value": 139.331,
        "_internal_originalTime": 1689744977000
    },
    {
        "time": 1689744978000,
        "value": 139.335,
        "_internal_originalTime": 1689744978000
    },
    {
        "time": 1689744979000,
        "value": 139.331,
        "_internal_originalTime": 1689744979000
    },
    {
        "time": 1689744981000,
        "value": 139.333,
        "_internal_originalTime": 1689744981000
    },
    {
        "time": 1689744985000,
        "value": 139.33,
        "_internal_originalTime": 1689744985000
    },
    {
        "time": 1689744986000,
        "value": 139.334,
        "_internal_originalTime": 1689744986000
    },
    {
        "time": 1689744988000,
        "value": 139.331,
        "_internal_originalTime": 1689744988000
    },
    {
        "time": 1689744989000,
        "value": 139.329,
        "_internal_originalTime": 1689744989000
    },
    {
        "time": 1689744990000,
        "value": 139.334,
        "_internal_originalTime": 1689744990000
    },
    {
        "time": 1689744991000,
        "value": 139.333,
        "_internal_originalTime": 1689744991000
    },
    {
        "time": 1689744994000,
        "value": 139.331,
        "_internal_originalTime": 1689744994000
    },
    {
        "time": 1689744996000,
        "value": 139.334,
        "_internal_originalTime": 1689744996000
    },
    {
        "time": 1689744997000,
        "value": 139.331,
        "_internal_originalTime": 1689744997000
    },
    {
        "time": 1689744999000,
        "value": 139.333,
        "_internal_originalTime": 1689744999000
    },
    {
        "time": 1689745000000,
        "value": 139.333,
        "_internal_originalTime": 1689745000000
    },
    {
        "time": 1689745001000,
        "value": 139.331,
        "_internal_originalTime": 1689745001000
    },
    {
        "time": 1689745003000,
        "value": 139.334,
        "_internal_originalTime": 1689745003000
    },
    {
        "time": 1689745005000,
        "value": 139.334,
        "_internal_originalTime": 1689745005000
    },
    {
        "time": 1689745006000,
        "value": 139.334,
        "_internal_originalTime": 1689745006000
    },
    {
        "time": 1689745007000,
        "value": 139.335,
        "_internal_originalTime": 1689745007000
    },
    {
        "time": 1689745008000,
        "value": 139.333,
        "_internal_originalTime": 1689745008000
    },
    {
        "time": 1689745011000,
        "value": 139.331,
        "_internal_originalTime": 1689745011000
    },
    {
        "time": 1689745012000,
        "value": 139.328,
        "_internal_originalTime": 1689745012000
    },
    {
        "time": 1689745017000,
        "value": 139.331,
        "_internal_originalTime": 1689745017000
    },
    {
        "time": 1689745019000,
        "value": 139.327,
        "_internal_originalTime": 1689745019000
    },
    {
        "time": 1689745020000,
        "value": 139.331,
        "_internal_originalTime": 1689745020000
    },
    {
        "time": 1689745022000,
        "value": 139.331,
        "_internal_originalTime": 1689745022000
    },
    {
        "time": 1689745023000,
        "value": 139.334,
        "_internal_originalTime": 1689745023000
    },
    {
        "time": 1689745025000,
        "value": 139.329,
        "_internal_originalTime": 1689745025000
    },
    {
        "time": 1689745027000,
        "value": 139.336,
        "_internal_originalTime": 1689745027000
    },
    {
        "time": 1689745028000,
        "value": 139.334,
        "_internal_originalTime": 1689745028000
    },
    {
        "time": 1689745030000,
        "value": 139.334,
        "_internal_originalTime": 1689745030000
    },
    {
        "time": 1689745032000,
        "value": 139.334,
        "_internal_originalTime": 1689745032000
    },
    {
        "time": 1689745033000,
        "value": 139.334,
        "_internal_originalTime": 1689745033000
    },
    {
        "time": 1689745034000,
        "value": 139.334,
        "_internal_originalTime": 1689745034000
    },
    {
        "time": 1689745035000,
        "value": 139.335,
        "_internal_originalTime": 1689745035000
    },
    {
        "time": 1689745036000,
        "value": 139.337,
        "_internal_originalTime": 1689745036000
    },
    {
        "time": 1689745037000,
        "value": 139.335,
        "_internal_originalTime": 1689745037000
    },
    {
        "time": 1689745040000,
        "value": 139.336,
        "_internal_originalTime": 1689745040000
    },
    {
        "time": 1689745042000,
        "value": 139.331,
        "_internal_originalTime": 1689745042000
    },
    {
        "time": 1689745043000,
        "value": 139.332,
        "_internal_originalTime": 1689745043000
    },
    {
        "time": 1689745044000,
        "value": 139.331,
        "_internal_originalTime": 1689745044000
    },
    {
        "time": 1689745045000,
        "value": 139.331,
        "_internal_originalTime": 1689745045000
    },
    {
        "time": 1689745047000,
        "value": 139.329,
        "_internal_originalTime": 1689745047000
    },
    {
        "time": 1689745048000,
        "value": 139.329,
        "_internal_originalTime": 1689745048000
    },
    {
        "time": 1689745049000,
        "value": 139.332,
        "_internal_originalTime": 1689745049000
    },
    {
        "time": 1689745050000,
        "value": 139.327,
        "_internal_originalTime": 1689745050000
    },
    {
        "time": 1689745051000,
        "value": 139.326,
        "_internal_originalTime": 1689745051000
    },
    {
        "time": 1689745053000,
        "value": 139.325,
        "_internal_originalTime": 1689745053000
    },
    {
        "time": 1689745054000,
        "value": 139.323,
        "_internal_originalTime": 1689745054000
    },
    {
        "time": 1689745055000,
        "value": 139.323,
        "_internal_originalTime": 1689745055000
    },
    {
        "time": 1689745057000,
        "value": 139.322,
        "_internal_originalTime": 1689745057000
    },
    {
        "time": 1689745058000,
        "value": 139.323,
        "_internal_originalTime": 1689745058000
    },
    {
        "time": 1689745060000,
        "value": 139.315,
        "_internal_originalTime": 1689745060000
    },
    {
        "time": 1689745061000,
        "value": 139.315,
        "_internal_originalTime": 1689745061000
    },
    {
        "time": 1689745063000,
        "value": 139.315,
        "_internal_originalTime": 1689745063000
    },
    {
        "time": 1689745064000,
        "value": 139.315,
        "_internal_originalTime": 1689745064000
    },
    {
        "time": 1689745065000,
        "value": 139.316,
        "_internal_originalTime": 1689745065000
    },
    {
        "time": 1689745066000,
        "value": 139.314,
        "_internal_originalTime": 1689745066000
    },
    {
        "time": 1689745068000,
        "value": 139.317,
        "_internal_originalTime": 1689745068000
    },
    {
        "time": 1689745069000,
        "value": 139.314,
        "_internal_originalTime": 1689745069000
    },
    {
        "time": 1689745070000,
        "value": 139.315,
        "_internal_originalTime": 1689745070000
    },
    {
        "time": 1689745071000,
        "value": 139.314,
        "_internal_originalTime": 1689745071000
    },
    {
        "time": 1689745073000,
        "value": 139.312,
        "_internal_originalTime": 1689745073000
    },
    {
        "time": 1689745075000,
        "value": 139.311,
        "_internal_originalTime": 1689745075000
    },
    {
        "time": 1689745076000,
        "value": 139.295,
        "_internal_originalTime": 1689745076000
    },
    {
        "time": 1689745077000,
        "value": 139.288,
        "_internal_originalTime": 1689745077000
    },
    {
        "time": 1689745079000,
        "value": 139.289,
        "_internal_originalTime": 1689745079000
    },
    {
        "time": 1689745080000,
        "value": 139.27,
        "_internal_originalTime": 1689745080000
    },
    {
        "time": 1689745081000,
        "value": 139.29,
        "_internal_originalTime": 1689745081000
    },
    {
        "time": 1689745082000,
        "value": 139.287,
        "_internal_originalTime": 1689745082000
    },
    {
        "time": 1689745086000,
        "value": 139.284,
        "_internal_originalTime": 1689745086000
    },
    {
        "time": 1689745088000,
        "value": 139.299,
        "_internal_originalTime": 1689745088000
    },
    {
        "time": 1689745089000,
        "value": 139.293,
        "_internal_originalTime": 1689745089000
    },
    {
        "time": 1689745090000,
        "value": 139.299,
        "_internal_originalTime": 1689745090000
    },
    {
        "time": 1689745092000,
        "value": 139.295,
        "_internal_originalTime": 1689745092000
    },
    {
        "time": 1689745093000,
        "value": 139.295,
        "_internal_originalTime": 1689745093000
    },
    {
        "time": 1689745094000,
        "value": 139.291,
        "_internal_originalTime": 1689745094000
    },
    {
        "time": 1689745095000,
        "value": 139.293,
        "_internal_originalTime": 1689745095000
    },
    {
        "time": 1689745096000,
        "value": 139.294,
        "_internal_originalTime": 1689745096000
    },
    {
        "time": 1689745098000,
        "value": 139.295,
        "_internal_originalTime": 1689745098000
    },
    {
        "time": 1689745099000,
        "value": 139.293,
        "_internal_originalTime": 1689745099000
    },
    {
        "time": 1689745100000,
        "value": 139.295,
        "_internal_originalTime": 1689745100000
    },
    {
        "time": 1689745102000,
        "value": 139.298,
        "_internal_originalTime": 1689745102000
    },
    {
        "time": 1689745103000,
        "value": 139.299,
        "_internal_originalTime": 1689745103000
    },
    {
        "time": 1689745105000,
        "value": 139.297,
        "_internal_originalTime": 1689745105000
    },
    {
        "time": 1689745107000,
        "value": 139.297,
        "_internal_originalTime": 1689745107000
    },
    {
        "time": 1689745108000,
        "value": 139.295,
        "_internal_originalTime": 1689745108000
    },
    {
        "time": 1689745109000,
        "value": 139.297,
        "_internal_originalTime": 1689745109000
    },
    {
        "time": 1689745110000,
        "value": 139.299,
        "_internal_originalTime": 1689745110000
    },
    {
        "time": 1689745111000,
        "value": 139.296,
        "_internal_originalTime": 1689745111000
    },
    {
        "time": 1689745113000,
        "value": 139.297,
        "_internal_originalTime": 1689745113000
    },
    {
        "time": 1689745115000,
        "value": 139.295,
        "_internal_originalTime": 1689745115000
    },
    {
        "time": 1689745117000,
        "value": 139.296,
        "_internal_originalTime": 1689745117000
    },
    {
        "time": 1689745118000,
        "value": 139.294,
        "_internal_originalTime": 1689745118000
    },
    {
        "time": 1689745119000,
        "value": 139.297,
        "_internal_originalTime": 1689745119000
    },
    {
        "time": 1689745120000,
        "value": 139.296,
        "_internal_originalTime": 1689745120000
    },
    {
        "time": 1689745121000,
        "value": 139.294,
        "_internal_originalTime": 1689745121000
    },
    {
        "time": 1689745122000,
        "value": 139.297,
        "_internal_originalTime": 1689745122000
    },
    {
        "time": 1689745124000,
        "value": 139.296,
        "_internal_originalTime": 1689745124000
    },
    {
        "time": 1689745126000,
        "value": 139.297,
        "_internal_originalTime": 1689745126000
    },
    {
        "time": 1689745127000,
        "value": 139.295,
        "_internal_originalTime": 1689745127000
    },
    {
        "time": 1689745128000,
        "value": 139.298,
        "_internal_originalTime": 1689745128000
    },
    {
        "time": 1689745131000,
        "value": 139.296,
        "_internal_originalTime": 1689745131000
    },
    {
        "time": 1689745132000,
        "value": 139.294,
        "_internal_originalTime": 1689745132000
    },
    {
        "time": 1689745133000,
        "value": 139.295,
        "_internal_originalTime": 1689745133000
    },
    {
        "time": 1689745134000,
        "value": 139.296,
        "_internal_originalTime": 1689745134000
    },
    {
        "time": 1689745135000,
        "value": 139.293,
        "_internal_originalTime": 1689745135000
    },
    {
        "time": 1689745137000,
        "value": 139.294,
        "_internal_originalTime": 1689745137000
    },
    {
        "time": 1689745138000,
        "value": 139.296,
        "_internal_originalTime": 1689745138000
    },
    {
        "time": 1689745140000,
        "value": 139.295,
        "_internal_originalTime": 1689745140000
    },
    {
        "time": 1689745141000,
        "value": 139.287,
        "_internal_originalTime": 1689745141000
    },
    {
        "time": 1689745142000,
        "value": 139.284,
        "_internal_originalTime": 1689745142000
    },
    {
        "time": 1689745144000,
        "value": 139.292,
        "_internal_originalTime": 1689745144000
    },
    {
        "time": 1689745146000,
        "value": 139.292,
        "_internal_originalTime": 1689745146000
    },
    {
        "time": 1689745147000,
        "value": 139.293,
        "_internal_originalTime": 1689745147000
    },
    {
        "time": 1689745148000,
        "value": 139.296,
        "_internal_originalTime": 1689745148000
    },
    {
        "time": 1689745149000,
        "value": 139.292,
        "_internal_originalTime": 1689745149000
    },
    {
        "time": 1689745150000,
        "value": 139.296,
        "_internal_originalTime": 1689745150000
    },
    {
        "time": 1689745151000,
        "value": 139.293,
        "_internal_originalTime": 1689745151000
    },
    {
        "time": 1689745153000,
        "value": 139.297,
        "_internal_originalTime": 1689745153000
    },
    {
        "time": 1689745154000,
        "value": 139.301,
        "_internal_originalTime": 1689745154000
    },
    {
        "time": 1689745156000,
        "value": 139.297,
        "_internal_originalTime": 1689745156000
    },
    {
        "time": 1689745158000,
        "value": 139.298,
        "_internal_originalTime": 1689745158000
    },
    {
        "time": 1689745159000,
        "value": 139.286,
        "_internal_originalTime": 1689745159000
    },
    {
        "time": 1689745160000,
        "value": 139.287,
        "_internal_originalTime": 1689745160000
    },
    {
        "time": 1689745161000,
        "value": 139.288,
        "_internal_originalTime": 1689745161000
    },
    {
        "time": 1689745162000,
        "value": 139.293,
        "_internal_originalTime": 1689745162000
    },
    {
        "time": 1689745163000,
        "value": 139.287,
        "_internal_originalTime": 1689745163000
    },
    {
        "time": 1689745164000,
        "value": 139.288,
        "_internal_originalTime": 1689745164000
    },
    {
        "time": 1689745166000,
        "value": 139.292,
        "_internal_originalTime": 1689745166000
    },
    {
        "time": 1689745167000,
        "value": 139.295,
        "_internal_originalTime": 1689745167000
    },
    {
        "time": 1689745168000,
        "value": 139.296,
        "_internal_originalTime": 1689745168000
    },
    {
        "time": 1689745169000,
        "value": 139.305,
        "_internal_originalTime": 1689745169000
    },
    {
        "time": 1689745170000,
        "value": 139.298,
        "_internal_originalTime": 1689745170000
    },
    {
        "time": 1689745171000,
        "value": 139.298,
        "_internal_originalTime": 1689745171000
    },
    {
        "time": 1689745173000,
        "value": 139.3,
        "_internal_originalTime": 1689745173000
    },
    {
        "time": 1689745174000,
        "value": 139.295,
        "_internal_originalTime": 1689745174000
    },
    {
        "time": 1689745175000,
        "value": 139.291,
        "_internal_originalTime": 1689745175000
    },
    {
        "time": 1689745177000,
        "value": 139.294,
        "_internal_originalTime": 1689745177000
    },
    {
        "time": 1689745178000,
        "value": 139.292,
        "_internal_originalTime": 1689745178000
    },
    {
        "time": 1689745179000,
        "value": 139.297,
        "_internal_originalTime": 1689745179000
    },
    {
        "time": 1689745180000,
        "value": 139.297,
        "_internal_originalTime": 1689745180000
    },
    {
        "time": 1689745182000,
        "value": 139.294,
        "_internal_originalTime": 1689745182000
    },
    {
        "time": 1689745183000,
        "value": 139.299,
        "_internal_originalTime": 1689745183000
    },
    {
        "time": 1689745184000,
        "value": 139.299,
        "_internal_originalTime": 1689745184000
    },
    {
        "time": 1689745186000,
        "value": 139.299,
        "_internal_originalTime": 1689745186000
    },
    {
        "time": 1689745187000,
        "value": 139.3,
        "_internal_originalTime": 1689745187000
    },
    {
        "time": 1689745190000,
        "value": 139.298,
        "_internal_originalTime": 1689745190000
    },
    {
        "time": 1689745192000,
        "value": 139.3,
        "_internal_originalTime": 1689745192000
    },
    {
        "time": 1689745194000,
        "value": 139.295,
        "_internal_originalTime": 1689745194000
    },
    {
        "time": 1689745195000,
        "value": 139.297,
        "_internal_originalTime": 1689745195000
    },
    {
        "time": 1689745196000,
        "value": 139.293,
        "_internal_originalTime": 1689745196000
    },
    {
        "time": 1689745199000,
        "value": 139.294,
        "_internal_originalTime": 1689745199000
    },
    {
        "time": 1689745200000,
        "value": 139.306,
        "_internal_originalTime": 1689745200000
    },
    {
        "time": 1689745201000,
        "value": 139.299,
        "_internal_originalTime": 1689745201000
    },
    {
        "time": 1689745202000,
        "value": 139.302,
        "_internal_originalTime": 1689745202000
    },
    {
        "time": 1689745204000,
        "value": 139.295,
        "_internal_originalTime": 1689745204000
    },
    {
        "time": 1689745205000,
        "value": 139.298,
        "_internal_originalTime": 1689745205000
    },
    {
        "time": 1689745206000,
        "value": 139.296,
        "_internal_originalTime": 1689745206000
    },
    {
        "time": 1689745207000,
        "value": 139.298,
        "_internal_originalTime": 1689745207000
    },
    {
        "time": 1689745208000,
        "value": 139.296,
        "_internal_originalTime": 1689745208000
    },
    {
        "time": 1689745209000,
        "value": 139.296,
        "_internal_originalTime": 1689745209000
    },
    {
        "time": 1689745211000,
        "value": 139.301,
        "_internal_originalTime": 1689745211000
    },
    {
        "time": 1689745213000,
        "value": 139.297,
        "_internal_originalTime": 1689745213000
    },
    {
        "time": 1689745216000,
        "value": 139.294,
        "_internal_originalTime": 1689745216000
    },
    {
        "time": 1689745217000,
        "value": 139.291,
        "_internal_originalTime": 1689745217000
    },
    {
        "time": 1689745218000,
        "value": 139.295,
        "_internal_originalTime": 1689745218000
    },
    {
        "time": 1689745220000,
        "value": 139.292,
        "_internal_originalTime": 1689745220000
    },
    {
        "time": 1689745221000,
        "value": 139.293,
        "_internal_originalTime": 1689745221000
    },
    {
        "time": 1689745222000,
        "value": 139.291,
        "_internal_originalTime": 1689745222000
    },
    {
        "time": 1689745223000,
        "value": 139.292,
        "_internal_originalTime": 1689745223000
    },
    {
        "time": 1689745225000,
        "value": 139.295,
        "_internal_originalTime": 1689745225000
    },
    {
        "time": 1689745227000,
        "value": 139.298,
        "_internal_originalTime": 1689745227000
    },
    {
        "time": 1689745228000,
        "value": 139.301,
        "_internal_originalTime": 1689745228000
    },
    {
        "time": 1689745229000,
        "value": 139.288,
        "_internal_originalTime": 1689745229000
    },
    {
        "time": 1689745230000,
        "value": 139.283,
        "_internal_originalTime": 1689745230000
    },
    {
        "time": 1689745231000,
        "value": 139.281,
        "_internal_originalTime": 1689745231000
    },
    {
        "time": 1689745233000,
        "value": 139.274,
        "_internal_originalTime": 1689745233000
    },
    {
        "time": 1689745235000,
        "value": 139.278,
        "_internal_originalTime": 1689745235000
    },
    {
        "time": 1689745236000,
        "value": 139.272,
        "_internal_originalTime": 1689745236000
    },
    {
        "time": 1689745237000,
        "value": 139.276,
        "_internal_originalTime": 1689745237000
    },
    {
        "time": 1689745238000,
        "value": 139.28,
        "_internal_originalTime": 1689745238000
    },
    {
        "time": 1689745240000,
        "value": 139.28,
        "_internal_originalTime": 1689745240000
    },
    {
        "time": 1689745241000,
        "value": 139.275,
        "_internal_originalTime": 1689745241000
    },
    {
        "time": 1689745242000,
        "value": 139.275,
        "_internal_originalTime": 1689745242000
    },
    {
        "time": 1689745243000,
        "value": 139.276,
        "_internal_originalTime": 1689745243000
    },
    {
        "time": 1689745245000,
        "value": 139.277,
        "_internal_originalTime": 1689745245000
    },
    {
        "time": 1689745246000,
        "value": 139.281,
        "_internal_originalTime": 1689745246000
    },
    {
        "time": 1689745248000,
        "value": 139.281,
        "_internal_originalTime": 1689745248000
    },
    {
        "time": 1689745249000,
        "value": 139.282,
        "_internal_originalTime": 1689745249000
    },
    {
        "time": 1689745250000,
        "value": 139.288,
        "_internal_originalTime": 1689745250000
    },
    {
        "time": 1689745252000,
        "value": 139.286,
        "_internal_originalTime": 1689745252000
    },
    {
        "time": 1689745253000,
        "value": 139.293,
        "_internal_originalTime": 1689745253000
    },
    {
        "time": 1689745254000,
        "value": 139.295,
        "_internal_originalTime": 1689745254000
    },
    {
        "time": 1689745255000,
        "value": 139.297,
        "_internal_originalTime": 1689745255000
    },
    {
        "time": 1689745256000,
        "value": 139.296,
        "_internal_originalTime": 1689745256000
    },
    {
        "time": 1689745258000,
        "value": 139.294,
        "_internal_originalTime": 1689745258000
    },
    {
        "time": 1689745259000,
        "value": 139.292,
        "_internal_originalTime": 1689745259000
    },
    {
        "time": 1689745261000,
        "value": 139.27,
        "_internal_originalTime": 1689745261000
    },
    {
        "time": 1689745262000,
        "value": 139.295,
        "_internal_originalTime": 1689745262000
    },
    {
        "time": 1689745267000,
        "value": 139.292,
        "_internal_originalTime": 1689745267000
    },
    {
        "time": 1689745269000,
        "value": 139.298,
        "_internal_originalTime": 1689745269000
    },
    {
        "time": 1689745272000,
        "value": 139.298,
        "_internal_originalTime": 1689745272000
    },
    {
        "time": 1689745275000,
        "value": 139.297,
        "_internal_originalTime": 1689745275000
    },
    {
        "time": 1689745277000,
        "value": 139.294,
        "_internal_originalTime": 1689745277000
    },
    {
        "time": 1689745278000,
        "value": 139.294,
        "_internal_originalTime": 1689745278000
    },
    {
        "time": 1689745279000,
        "value": 139.296,
        "_internal_originalTime": 1689745279000
    },
    {
        "time": 1689745280000,
        "value": 139.293,
        "_internal_originalTime": 1689745280000
    },
    {
        "time": 1689745281000,
        "value": 139.294,
        "_internal_originalTime": 1689745281000
    },
    {
        "time": 1689745283000,
        "value": 139.297,
        "_internal_originalTime": 1689745283000
    },
    {
        "time": 1689745284000,
        "value": 139.296,
        "_internal_originalTime": 1689745284000
    },
    {
        "time": 1689745285000,
        "value": 139.297,
        "_internal_originalTime": 1689745285000
    },
    {
        "time": 1689745288000,
        "value": 139.298,
        "_internal_originalTime": 1689745288000
    },
    {
        "time": 1689745289000,
        "value": 139.292,
        "_internal_originalTime": 1689745289000
    },
    {
        "time": 1689745290000,
        "value": 139.295,
        "_internal_originalTime": 1689745290000
    },
    {
        "time": 1689745291000,
        "value": 139.3,
        "_internal_originalTime": 1689745291000
    },
    {
        "time": 1689745292000,
        "value": 139.282,
        "_internal_originalTime": 1689745292000
    },
    {
        "time": 1689745294000,
        "value": 139.275,
        "_internal_originalTime": 1689745294000
    },
    {
        "time": 1689745296000,
        "value": 139.27,
        "_internal_originalTime": 1689745296000
    },
    {
        "time": 1689745297000,
        "value": 139.271,
        "_internal_originalTime": 1689745297000
    },
    {
        "time": 1689745298000,
        "value": 139.275,
        "_internal_originalTime": 1689745298000
    },
    {
        "time": 1689745299000,
        "value": 139.266,
        "_internal_originalTime": 1689745299000
    },
    {
        "time": 1689745301000,
        "value": 139.269,
        "_internal_originalTime": 1689745301000
    },
    {
        "time": 1689745302000,
        "value": 139.263,
        "_internal_originalTime": 1689745302000
    },
    {
        "time": 1689745303000,
        "value": 139.269,
        "_internal_originalTime": 1689745303000
    },
    {
        "time": 1689745305000,
        "value": 139.276,
        "_internal_originalTime": 1689745305000
    },
    {
        "time": 1689745306000,
        "value": 139.273,
        "_internal_originalTime": 1689745306000
    },
    {
        "time": 1689745307000,
        "value": 139.269,
        "_internal_originalTime": 1689745307000
    },
    {
        "time": 1689745309000,
        "value": 139.268,
        "_internal_originalTime": 1689745309000
    },
    {
        "time": 1689745311000,
        "value": 139.276,
        "_internal_originalTime": 1689745311000
    },
    {
        "time": 1689745312000,
        "value": 139.277,
        "_internal_originalTime": 1689745312000
    },
    {
        "time": 1689745313000,
        "value": 139.275,
        "_internal_originalTime": 1689745313000
    },
    {
        "time": 1689745314000,
        "value": 139.272,
        "_internal_originalTime": 1689745314000
    },
    {
        "time": 1689745315000,
        "value": 139.277,
        "_internal_originalTime": 1689745315000
    },
    {
        "time": 1689745317000,
        "value": 139.276,
        "_internal_originalTime": 1689745317000
    },
    {
        "time": 1689745318000,
        "value": 139.274,
        "_internal_originalTime": 1689745318000
    },
    {
        "time": 1689745320000,
        "value": 139.275,
        "_internal_originalTime": 1689745320000
    },
    {
        "time": 1689745321000,
        "value": 139.268,
        "_internal_originalTime": 1689745321000
    },
    {
        "time": 1689745323000,
        "value": 139.269,
        "_internal_originalTime": 1689745323000
    },
    {
        "time": 1689745324000,
        "value": 139.266,
        "_internal_originalTime": 1689745324000
    },
    {
        "time": 1689745326000,
        "value": 139.269,
        "_internal_originalTime": 1689745326000
    },
    {
        "time": 1689745327000,
        "value": 139.268,
        "_internal_originalTime": 1689745327000
    },
    {
        "time": 1689745328000,
        "value": 139.268,
        "_internal_originalTime": 1689745328000
    },
    {
        "time": 1689745332000,
        "value": 139.267,
        "_internal_originalTime": 1689745332000
    },
    {
        "time": 1689745333000,
        "value": 139.268,
        "_internal_originalTime": 1689745333000
    },
    {
        "time": 1689745334000,
        "value": 139.27,
        "_internal_originalTime": 1689745334000
    },
    {
        "time": 1689745335000,
        "value": 139.265,
        "_internal_originalTime": 1689745335000
    },
    {
        "time": 1689745336000,
        "value": 139.264,
        "_internal_originalTime": 1689745336000
    },
    {
        "time": 1689745338000,
        "value": 139.268,
        "_internal_originalTime": 1689745338000
    },
    {
        "time": 1689745342000,
        "value": 139.266,
        "_internal_originalTime": 1689745342000
    },
    {
        "time": 1689745343000,
        "value": 139.269,
        "_internal_originalTime": 1689745343000
    },
    {
        "time": 1689745345000,
        "value": 139.268,
        "_internal_originalTime": 1689745345000
    },
    {
        "time": 1689745346000,
        "value": 139.264,
        "_internal_originalTime": 1689745346000
    },
    {
        "time": 1689745347000,
        "value": 139.266,
        "_internal_originalTime": 1689745347000
    },
    {
        "time": 1689745348000,
        "value": 139.264,
        "_internal_originalTime": 1689745348000
    },
    {
        "time": 1689745349000,
        "value": 139.264,
        "_internal_originalTime": 1689745349000
    },
    {
        "time": 1689745351000,
        "value": 139.268,
        "_internal_originalTime": 1689745351000
    },
    {
        "time": 1689745355000,
        "value": 139.268,
        "_internal_originalTime": 1689745355000
    },
    {
        "time": 1689745358000,
        "value": 139.267,
        "_internal_originalTime": 1689745358000
    },
    {
        "time": 1689745360000,
        "value": 139.27,
        "_internal_originalTime": 1689745360000
    },
    {
        "time": 1689745364000,
        "value": 139.272,
        "_internal_originalTime": 1689745364000
    },
    {
        "time": 1689745365000,
        "value": 139.268,
        "_internal_originalTime": 1689745365000
    },
    {
        "time": 1689745366000,
        "value": 139.271,
        "_internal_originalTime": 1689745366000
    },
    {
        "time": 1689745367000,
        "value": 139.27,
        "_internal_originalTime": 1689745367000
    },
    {
        "time": 1689745369000,
        "value": 139.275,
        "_internal_originalTime": 1689745369000
    },
    {
        "time": 1689745370000,
        "value": 139.273,
        "_internal_originalTime": 1689745370000
    },
    {
        "time": 1689745371000,
        "value": 139.276,
        "_internal_originalTime": 1689745371000
    },
    {
        "time": 1689745372000,
        "value": 139.275,
        "_internal_originalTime": 1689745372000
    },
    {
        "time": 1689745374000,
        "value": 139.276,
        "_internal_originalTime": 1689745374000
    },
    {
        "time": 1689745375000,
        "value": 139.277,
        "_internal_originalTime": 1689745375000
    },
    {
        "time": 1689745376000,
        "value": 139.278,
        "_internal_originalTime": 1689745376000
    },
    {
        "time": 1689745379000,
        "value": 139.273,
        "_internal_originalTime": 1689745379000
    },
    {
        "time": 1689745380000,
        "value": 139.28,
        "_internal_originalTime": 1689745380000
    },
    {
        "time": 1689745381000,
        "value": 139.28,
        "_internal_originalTime": 1689745381000
    },
    {
        "time": 1689745382000,
        "value": 139.281,
        "_internal_originalTime": 1689745382000
    },
    {
        "time": 1689745384000,
        "value": 139.278,
        "_internal_originalTime": 1689745384000
    },
    {
        "time": 1689745386000,
        "value": 139.278,
        "_internal_originalTime": 1689745386000
    },
    {
        "time": 1689745388000,
        "value": 139.278,
        "_internal_originalTime": 1689745388000
    },
    {
        "time": 1689745391000,
        "value": 139.281,
        "_internal_originalTime": 1689745391000
    },
    {
        "time": 1689745392000,
        "value": 139.276,
        "_internal_originalTime": 1689745392000
    },
    {
        "time": 1689745393000,
        "value": 139.28,
        "_internal_originalTime": 1689745393000
    },
    {
        "time": 1689745395000,
        "value": 139.289,
        "_internal_originalTime": 1689745395000
    },
    {
        "time": 1689745396000,
        "value": 139.287,
        "_internal_originalTime": 1689745396000
    },
    {
        "time": 1689745397000,
        "value": 139.287,
        "_internal_originalTime": 1689745397000
    },
    {
        "time": 1689745399000,
        "value": 139.288,
        "_internal_originalTime": 1689745399000
    },
    {
        "time": 1689745401000,
        "value": 139.284,
        "_internal_originalTime": 1689745401000
    },
    {
        "time": 1689745402000,
        "value": 139.284,
        "_internal_originalTime": 1689745402000
    },
    {
        "time": 1689745403000,
        "value": 139.284,
        "_internal_originalTime": 1689745403000
    },
    {
        "time": 1689745404000,
        "value": 139.289,
        "_internal_originalTime": 1689745404000
    },
    {
        "time": 1689745406000,
        "value": 139.287,
        "_internal_originalTime": 1689745406000
    },
    {
        "time": 1689745408000,
        "value": 139.284,
        "_internal_originalTime": 1689745408000
    },
    {
        "time": 1689745409000,
        "value": 139.294,
        "_internal_originalTime": 1689745409000
    },
    {
        "time": 1689745411000,
        "value": 139.297,
        "_internal_originalTime": 1689745411000
    },
    {
        "time": 1689745412000,
        "value": 139.298,
        "_internal_originalTime": 1689745412000
    },
    {
        "time": 1689745414000,
        "value": 139.296,
        "_internal_originalTime": 1689745414000
    },
    {
        "time": 1689745415000,
        "value": 139.299,
        "_internal_originalTime": 1689745415000
    },
    {
        "time": 1689745416000,
        "value": 139.298,
        "_internal_originalTime": 1689745416000
    },
    {
        "time": 1689745418000,
        "value": 139.296,
        "_internal_originalTime": 1689745418000
    },
    {
        "time": 1689745422000,
        "value": 139.298,
        "_internal_originalTime": 1689745422000
    },
    {
        "time": 1689745425000,
        "value": 139.299,
        "_internal_originalTime": 1689745425000
    },
    {
        "time": 1689745426000,
        "value": 139.298,
        "_internal_originalTime": 1689745426000
    },
    {
        "time": 1689745432000,
        "value": 139.299,
        "_internal_originalTime": 1689745432000
    },
    {
        "time": 1689745434000,
        "value": 139.298,
        "_internal_originalTime": 1689745434000
    },
    {
        "time": 1689745435000,
        "value": 139.299,
        "_internal_originalTime": 1689745435000
    },
    {
        "time": 1689745438000,
        "value": 139.298,
        "_internal_originalTime": 1689745438000
    },
    {
        "time": 1689745439000,
        "value": 139.305,
        "_internal_originalTime": 1689745439000
    },
    {
        "time": 1689745440000,
        "value": 139.298,
        "_internal_originalTime": 1689745440000
    },
    {
        "time": 1689745442000,
        "value": 139.308,
        "_internal_originalTime": 1689745442000
    },
    {
        "time": 1689745444000,
        "value": 139.308,
        "_internal_originalTime": 1689745444000
    },
    {
        "time": 1689745445000,
        "value": 139.308,
        "_internal_originalTime": 1689745445000
    },
    {
        "time": 1689745448000,
        "value": 139.305,
        "_internal_originalTime": 1689745448000
    },
    {
        "time": 1689745450000,
        "value": 139.308,
        "_internal_originalTime": 1689745450000
    },
    {
        "time": 1689745453000,
        "value": 139.308,
        "_internal_originalTime": 1689745453000
    },
    {
        "time": 1689745455000,
        "value": 139.313,
        "_internal_originalTime": 1689745455000
    },
    {
        "time": 1689745456000,
        "value": 139.312,
        "_internal_originalTime": 1689745456000
    },
    {
        "time": 1689745457000,
        "value": 139.315,
        "_internal_originalTime": 1689745457000
    },
    {
        "time": 1689745458000,
        "value": 139.314,
        "_internal_originalTime": 1689745458000
    },
    {
        "time": 1689745461000,
        "value": 139.317,
        "_internal_originalTime": 1689745461000
    },
    {
        "time": 1689745466000,
        "value": 139.315,
        "_internal_originalTime": 1689745466000
    },
    {
        "time": 1689745468000,
        "value": 139.318,
        "_internal_originalTime": 1689745468000
    },
    {
        "time": 1689745469000,
        "value": 139.317,
        "_internal_originalTime": 1689745469000
    },
    {
        "time": 1689745471000,
        "value": 139.314,
        "_internal_originalTime": 1689745471000
    },
    {
        "time": 1689745472000,
        "value": 139.317,
        "_internal_originalTime": 1689745472000
    },
    {
        "time": 1689745473000,
        "value": 139.317,
        "_internal_originalTime": 1689745473000
    },
    {
        "time": 1689745474000,
        "value": 139.318,
        "_internal_originalTime": 1689745474000
    },
    {
        "time": 1689745477000,
        "value": 139.317,
        "_internal_originalTime": 1689745477000
    },
    {
        "time": 1689745478000,
        "value": 139.318,
        "_internal_originalTime": 1689745478000
    },
    {
        "time": 1689745479000,
        "value": 139.318,
        "_internal_originalTime": 1689745479000
    },
    {
        "time": 1689745481000,
        "value": 139.318,
        "_internal_originalTime": 1689745481000
    },
    {
        "time": 1689745482000,
        "value": 139.317,
        "_internal_originalTime": 1689745482000
    },
    {
        "time": 1689745484000,
        "value": 139.318,
        "_internal_originalTime": 1689745484000
    },
    {
        "time": 1689745485000,
        "value": 139.317,
        "_internal_originalTime": 1689745485000
    },
    {
        "time": 1689745489000,
        "value": 139.318,
        "_internal_originalTime": 1689745489000
    },
    {
        "time": 1689745490000,
        "value": 139.318,
        "_internal_originalTime": 1689745490000
    },
    {
        "time": 1689745491000,
        "value": 139.318,
        "_internal_originalTime": 1689745491000
    },
    {
        "time": 1689745495000,
        "value": 139.318,
        "_internal_originalTime": 1689745495000
    },
    {
        "time": 1689745496000,
        "value": 139.319,
        "_internal_originalTime": 1689745496000
    },
    {
        "time": 1689745497000,
        "value": 139.317,
        "_internal_originalTime": 1689745497000
    },
    {
        "time": 1689745499000,
        "value": 139.311,
        "_internal_originalTime": 1689745499000
    },
    {
        "time": 1689745500000,
        "value": 139.318,
        "_internal_originalTime": 1689745500000
    },
    {
        "time": 1689745501000,
        "value": 139.316,
        "_internal_originalTime": 1689745501000
    },
    {
        "time": 1689745502000,
        "value": 139.312,
        "_internal_originalTime": 1689745502000
    },
    {
        "time": 1689745504000,
        "value": 139.312,
        "_internal_originalTime": 1689745504000
    },
    {
        "time": 1689745505000,
        "value": 139.317,
        "_internal_originalTime": 1689745505000
    },
    {
        "time": 1689745508000,
        "value": 139.314,
        "_internal_originalTime": 1689745508000
    },
    {
        "time": 1689745509000,
        "value": 139.315,
        "_internal_originalTime": 1689745509000
    },
    {
        "time": 1689745512000,
        "value": 139.308,
        "_internal_originalTime": 1689745512000
    },
    {
        "time": 1689745513000,
        "value": 139.307,
        "_internal_originalTime": 1689745513000
    },
    {
        "time": 1689745514000,
        "value": 139.308,
        "_internal_originalTime": 1689745514000
    },
    {
        "time": 1689745515000,
        "value": 139.311,
        "_internal_originalTime": 1689745515000
    },
    {
        "time": 1689745516000,
        "value": 139.306,
        "_internal_originalTime": 1689745516000
    },
    {
        "time": 1689745518000,
        "value": 139.306,
        "_internal_originalTime": 1689745518000
    },
    {
        "time": 1689745519000,
        "value": 139.308,
        "_internal_originalTime": 1689745519000
    },
    {
        "time": 1689745520000,
        "value": 139.304,
        "_internal_originalTime": 1689745520000
    },
    {
        "time": 1689745521000,
        "value": 139.306,
        "_internal_originalTime": 1689745521000
    },
    {
        "time": 1689745523000,
        "value": 139.307,
        "_internal_originalTime": 1689745523000
    },
    {
        "time": 1689745525000,
        "value": 139.303,
        "_internal_originalTime": 1689745525000
    },
    {
        "time": 1689745526000,
        "value": 139.304,
        "_internal_originalTime": 1689745526000
    },
    {
        "time": 1689745527000,
        "value": 139.303,
        "_internal_originalTime": 1689745527000
    },
    {
        "time": 1689745528000,
        "value": 139.303,
        "_internal_originalTime": 1689745528000
    },
    {
        "time": 1689745529000,
        "value": 139.304,
        "_internal_originalTime": 1689745529000
    },
    {
        "time": 1689745531000,
        "value": 139.306,
        "_internal_originalTime": 1689745531000
    },
    {
        "time": 1689745533000,
        "value": 139.305,
        "_internal_originalTime": 1689745533000
    },
    {
        "time": 1689745534000,
        "value": 139.3,
        "_internal_originalTime": 1689745534000
    },
    {
        "time": 1689745536000,
        "value": 139.306,
        "_internal_originalTime": 1689745536000
    },
    {
        "time": 1689745537000,
        "value": 139.301,
        "_internal_originalTime": 1689745537000
    },
    {
        "time": 1689745538000,
        "value": 139.303,
        "_internal_originalTime": 1689745538000
    },
    {
        "time": 1689745540000,
        "value": 139.299,
        "_internal_originalTime": 1689745540000
    },
    {
        "time": 1689745541000,
        "value": 139.298,
        "_internal_originalTime": 1689745541000
    },
    {
        "time": 1689745543000,
        "value": 139.3,
        "_internal_originalTime": 1689745543000
    },
    {
        "time": 1689745544000,
        "value": 139.304,
        "_internal_originalTime": 1689745544000
    },
    {
        "time": 1689745545000,
        "value": 139.301,
        "_internal_originalTime": 1689745545000
    },
    {
        "time": 1689745546000,
        "value": 139.3,
        "_internal_originalTime": 1689745546000
    },
    {
        "time": 1689745547000,
        "value": 139.301,
        "_internal_originalTime": 1689745547000
    },
    {
        "time": 1689745548000,
        "value": 139.301,
        "_internal_originalTime": 1689745548000
    },
    {
        "time": 1689745549000,
        "value": 139.3,
        "_internal_originalTime": 1689745549000
    },
    {
        "time": 1689745551000,
        "value": 139.303,
        "_internal_originalTime": 1689745551000
    },
    {
        "time": 1689745552000,
        "value": 139.301,
        "_internal_originalTime": 1689745552000
    },
    {
        "time": 1689745553000,
        "value": 139.305,
        "_internal_originalTime": 1689745553000
    },
    {
        "time": 1689745555000,
        "value": 139.309,
        "_internal_originalTime": 1689745555000
    },
    {
        "time": 1689745556000,
        "value": 139.313,
        "_internal_originalTime": 1689745556000
    },
    {
        "time": 1689745557000,
        "value": 139.314,
        "_internal_originalTime": 1689745557000
    },
    {
        "time": 1689745559000,
        "value": 139.31,
        "_internal_originalTime": 1689745559000
    },
    {
        "time": 1689745560000,
        "value": 139.313,
        "_internal_originalTime": 1689745560000
    },
    {
        "time": 1689745561000,
        "value": 139.314,
        "_internal_originalTime": 1689745561000
    },
    {
        "time": 1689745562000,
        "value": 139.31,
        "_internal_originalTime": 1689745562000
    },
    {
        "time": 1689745564000,
        "value": 139.312,
        "_internal_originalTime": 1689745564000
    },
    {
        "time": 1689745566000,
        "value": 139.305,
        "_internal_originalTime": 1689745566000
    },
    {
        "time": 1689745567000,
        "value": 139.309,
        "_internal_originalTime": 1689745567000
    },
    {
        "time": 1689745568000,
        "value": 139.305,
        "_internal_originalTime": 1689745568000
    },
    {
        "time": 1689745569000,
        "value": 139.31,
        "_internal_originalTime": 1689745569000
    },
    {
        "time": 1689745570000,
        "value": 139.302,
        "_internal_originalTime": 1689745570000
    },
    {
        "time": 1689745572000,
        "value": 139.303,
        "_internal_originalTime": 1689745572000
    },
    {
        "time": 1689745573000,
        "value": 139.302,
        "_internal_originalTime": 1689745573000
    },
    {
        "time": 1689745574000,
        "value": 139.306,
        "_internal_originalTime": 1689745574000
    },
    {
        "time": 1689745575000,
        "value": 139.308,
        "_internal_originalTime": 1689745575000
    },
    {
        "time": 1689745576000,
        "value": 139.302,
        "_internal_originalTime": 1689745576000
    },
    {
        "time": 1689745577000,
        "value": 139.307,
        "_internal_originalTime": 1689745577000
    },
    {
        "time": 1689745579000,
        "value": 139.303,
        "_internal_originalTime": 1689745579000
    },
    {
        "time": 1689745580000,
        "value": 139.303,
        "_internal_originalTime": 1689745580000
    },
    {
        "time": 1689745581000,
        "value": 139.304,
        "_internal_originalTime": 1689745581000
    },
    {
        "time": 1689745582000,
        "value": 139.304,
        "_internal_originalTime": 1689745582000
    },
    {
        "time": 1689745584000,
        "value": 139.308,
        "_internal_originalTime": 1689745584000
    },
    {
        "time": 1689745586000,
        "value": 139.306,
        "_internal_originalTime": 1689745586000
    },
    {
        "time": 1689745587000,
        "value": 139.303,
        "_internal_originalTime": 1689745587000
    },
    {
        "time": 1689745590000,
        "value": 139.301,
        "_internal_originalTime": 1689745590000
    },
    {
        "time": 1689745591000,
        "value": 139.3,
        "_internal_originalTime": 1689745591000
    },
    {
        "time": 1689745593000,
        "value": 139.304,
        "_internal_originalTime": 1689745593000
    },
    {
        "time": 1689745594000,
        "value": 139.301,
        "_internal_originalTime": 1689745594000
    },
    {
        "time": 1689745595000,
        "value": 139.308,
        "_internal_originalTime": 1689745595000
    },
    {
        "time": 1689745597000,
        "value": 139.308,
        "_internal_originalTime": 1689745597000
    },
    {
        "time": 1689745599000,
        "value": 139.306,
        "_internal_originalTime": 1689745599000
    },
    {
        "time": 1689745600000,
        "value": 139.304,
        "_internal_originalTime": 1689745600000
    },
    {
        "time": 1689745602000,
        "value": 139.307,
        "_internal_originalTime": 1689745602000
    },
    {
        "time": 1689745604000,
        "value": 139.319,
        "_internal_originalTime": 1689745604000
    },
    {
        "time": 1689745606000,
        "value": 139.317,
        "_internal_originalTime": 1689745606000
    },
    {
        "time": 1689745607000,
        "value": 139.314,
        "_internal_originalTime": 1689745607000
    },
    {
        "time": 1689745609000,
        "value": 139.313,
        "_internal_originalTime": 1689745609000
    },
    {
        "time": 1689745611000,
        "value": 139.316,
        "_internal_originalTime": 1689745611000
    },
    {
        "time": 1689745612000,
        "value": 139.312,
        "_internal_originalTime": 1689745612000
    },
    {
        "time": 1689745613000,
        "value": 139.314,
        "_internal_originalTime": 1689745613000
    },
    {
        "time": 1689745614000,
        "value": 139.314,
        "_internal_originalTime": 1689745614000
    },
    {
        "time": 1689745616000,
        "value": 139.316,
        "_internal_originalTime": 1689745616000
    },
    {
        "time": 1689745618000,
        "value": 139.327,
        "_internal_originalTime": 1689745618000
    },
    {
        "time": 1689745619000,
        "value": 139.32,
        "_internal_originalTime": 1689745619000
    },
    {
        "time": 1689745620000,
        "value": 139.327,
        "_internal_originalTime": 1689745620000
    },
    {
        "time": 1689745621000,
        "value": 139.323,
        "_internal_originalTime": 1689745621000
    },
    {
        "time": 1689745622000,
        "value": 139.325,
        "_internal_originalTime": 1689745622000
    },
    {
        "time": 1689745624000,
        "value": 139.32,
        "_internal_originalTime": 1689745624000
    },
    {
        "time": 1689745626000,
        "value": 139.323,
        "_internal_originalTime": 1689745626000
    },
    {
        "time": 1689745627000,
        "value": 139.323,
        "_internal_originalTime": 1689745627000
    },
    {
        "time": 1689745628000,
        "value": 139.322,
        "_internal_originalTime": 1689745628000
    },
    {
        "time": 1689745629000,
        "value": 139.319,
        "_internal_originalTime": 1689745629000
    },
    {
        "time": 1689745630000,
        "value": 139.319,
        "_internal_originalTime": 1689745630000
    },
    {
        "time": 1689745631000,
        "value": 139.317,
        "_internal_originalTime": 1689745631000
    },
    {
        "time": 1689745632000,
        "value": 139.32,
        "_internal_originalTime": 1689745632000
    },
    {
        "time": 1689745634000,
        "value": 139.32,
        "_internal_originalTime": 1689745634000
    },
    {
        "time": 1689745640000,
        "value": 139.331,
        "_internal_originalTime": 1689745640000
    },
    {
        "time": 1689745641000,
        "value": 139.333,
        "_internal_originalTime": 1689745641000
    },
    {
        "time": 1689745642000,
        "value": 139.331,
        "_internal_originalTime": 1689745642000
    },
    {
        "time": 1689745643000,
        "value": 139.336,
        "_internal_originalTime": 1689745643000
    },
    {
        "time": 1689745644000,
        "value": 139.336,
        "_internal_originalTime": 1689745644000
    },
    {
        "time": 1689745645000,
        "value": 139.343,
        "_internal_originalTime": 1689745645000
    },
    {
        "time": 1689745647000,
        "value": 139.341,
        "_internal_originalTime": 1689745647000
    },
    {
        "time": 1689745648000,
        "value": 139.345,
        "_internal_originalTime": 1689745648000
    },
    {
        "time": 1689745649000,
        "value": 139.345,
        "_internal_originalTime": 1689745649000
    },
    {
        "time": 1689745650000,
        "value": 139.341,
        "_internal_originalTime": 1689745650000
    },
    {
        "time": 1689745651000,
        "value": 139.342,
        "_internal_originalTime": 1689745651000
    },
    {
        "time": 1689745652000,
        "value": 139.34,
        "_internal_originalTime": 1689745652000
    },
    {
        "time": 1689745654000,
        "value": 139.354,
        "_internal_originalTime": 1689745654000
    },
    {
        "time": 1689745655000,
        "value": 139.349,
        "_internal_originalTime": 1689745655000
    },
    {
        "time": 1689745656000,
        "value": 139.35,
        "_internal_originalTime": 1689745656000
    },
    {
        "time": 1689745657000,
        "value": 139.347,
        "_internal_originalTime": 1689745657000
    },
    {
        "time": 1689745658000,
        "value": 139.348,
        "_internal_originalTime": 1689745658000
    },
    {
        "time": 1689745660000,
        "value": 139.345,
        "_internal_originalTime": 1689745660000
    },
    {
        "time": 1689745661000,
        "value": 139.347,
        "_internal_originalTime": 1689745661000
    },
    {
        "time": 1689745662000,
        "value": 139.35,
        "_internal_originalTime": 1689745662000
    },
    {
        "time": 1689745663000,
        "value": 139.354,
        "_internal_originalTime": 1689745663000
    },
    {
        "time": 1689745664000,
        "value": 139.351,
        "_internal_originalTime": 1689745664000
    },
    {
        "time": 1689745665000,
        "value": 139.348,
        "_internal_originalTime": 1689745665000
    },
    {
        "time": 1689745666000,
        "value": 139.348,
        "_internal_originalTime": 1689745666000
    },
    {
        "time": 1689745668000,
        "value": 139.349,
        "_internal_originalTime": 1689745668000
    },
    {
        "time": 1689745669000,
        "value": 139.348,
        "_internal_originalTime": 1689745669000
    },
    {
        "time": 1689745671000,
        "value": 139.35,
        "_internal_originalTime": 1689745671000
    },
    {
        "time": 1689745672000,
        "value": 139.347,
        "_internal_originalTime": 1689745672000
    },
    {
        "time": 1689745674000,
        "value": 139.354,
        "_internal_originalTime": 1689745674000
    },
    {
        "time": 1689745675000,
        "value": 139.346,
        "_internal_originalTime": 1689745675000
    },
    {
        "time": 1689745677000,
        "value": 139.346,
        "_internal_originalTime": 1689745677000
    },
    {
        "time": 1689745678000,
        "value": 139.351,
        "_internal_originalTime": 1689745678000
    },
    {
        "time": 1689745679000,
        "value": 139.348,
        "_internal_originalTime": 1689745679000
    },
    {
        "time": 1689745680000,
        "value": 139.347,
        "_internal_originalTime": 1689745680000
    },
    {
        "time": 1689745681000,
        "value": 139.345,
        "_internal_originalTime": 1689745681000
    },
    {
        "time": 1689745682000,
        "value": 139.341,
        "_internal_originalTime": 1689745682000
    },
    {
        "time": 1689745683000,
        "value": 139.346,
        "_internal_originalTime": 1689745683000
    },
    {
        "time": 1689745684000,
        "value": 139.34,
        "_internal_originalTime": 1689745684000
    },
    {
        "time": 1689745685000,
        "value": 139.342,
        "_internal_originalTime": 1689745685000
    },
    {
        "time": 1689745687000,
        "value": 139.345,
        "_internal_originalTime": 1689745687000
    },
    {
        "time": 1689745688000,
        "value": 139.342,
        "_internal_originalTime": 1689745688000
    },
    {
        "time": 1689745690000,
        "value": 139.344,
        "_internal_originalTime": 1689745690000
    },
    {
        "time": 1689745691000,
        "value": 139.345,
        "_internal_originalTime": 1689745691000
    },
    {
        "time": 1689745692000,
        "value": 139.344,
        "_internal_originalTime": 1689745692000
    },
    {
        "time": 1689745694000,
        "value": 139.338,
        "_internal_originalTime": 1689745694000
    },
    {
        "time": 1689745695000,
        "value": 139.344,
        "_internal_originalTime": 1689745695000
    },
    {
        "time": 1689745696000,
        "value": 139.347,
        "_internal_originalTime": 1689745696000
    },
    {
        "time": 1689745697000,
        "value": 139.348,
        "_internal_originalTime": 1689745697000
    },
    {
        "time": 1689745698000,
        "value": 139.346,
        "_internal_originalTime": 1689745698000
    },
    {
        "time": 1689745700000,
        "value": 139.34,
        "_internal_originalTime": 1689745700000
    },
    {
        "time": 1689745702000,
        "value": 139.342,
        "_internal_originalTime": 1689745702000
    },
    {
        "time": 1689745703000,
        "value": 139.342,
        "_internal_originalTime": 1689745703000
    },
    {
        "time": 1689745705000,
        "value": 139.345,
        "_internal_originalTime": 1689745705000
    },
    {
        "time": 1689745706000,
        "value": 139.339,
        "_internal_originalTime": 1689745706000
    },
    {
        "time": 1689745708000,
        "value": 139.34,
        "_internal_originalTime": 1689745708000
    },
    {
        "time": 1689745709000,
        "value": 139.337,
        "_internal_originalTime": 1689745709000
    },
    {
        "time": 1689745710000,
        "value": 139.343,
        "_internal_originalTime": 1689745710000
    },
    {
        "time": 1689745712000,
        "value": 139.338,
        "_internal_originalTime": 1689745712000
    },
    {
        "time": 1689745714000,
        "value": 139.343,
        "_internal_originalTime": 1689745714000
    },
    {
        "time": 1689745715000,
        "value": 139.346,
        "_internal_originalTime": 1689745715000
    },
    {
        "time": 1689745716000,
        "value": 139.342,
        "_internal_originalTime": 1689745716000
    },
    {
        "time": 1689745717000,
        "value": 139.347,
        "_internal_originalTime": 1689745717000
    },
    {
        "time": 1689745718000,
        "value": 139.346,
        "_internal_originalTime": 1689745718000
    },
    {
        "time": 1689745720000,
        "value": 139.341,
        "_internal_originalTime": 1689745720000
    },
    {
        "time": 1689745721000,
        "value": 139.344,
        "_internal_originalTime": 1689745721000
    },
    {
        "time": 1689745722000,
        "value": 139.338,
        "_internal_originalTime": 1689745722000
    },
    {
        "time": 1689745723000,
        "value": 139.342,
        "_internal_originalTime": 1689745723000
    },
    {
        "time": 1689745725000,
        "value": 139.338,
        "_internal_originalTime": 1689745725000
    },
    {
        "time": 1689745726000,
        "value": 139.339,
        "_internal_originalTime": 1689745726000
    },
    {
        "time": 1689745728000,
        "value": 139.343,
        "_internal_originalTime": 1689745728000
    },
    {
        "time": 1689745729000,
        "value": 139.34,
        "_internal_originalTime": 1689745729000
    },
    {
        "time": 1689745730000,
        "value": 139.342,
        "_internal_originalTime": 1689745730000
    },
    {
        "time": 1689745731000,
        "value": 139.341,
        "_internal_originalTime": 1689745731000
    },
    {
        "time": 1689745732000,
        "value": 139.337,
        "_internal_originalTime": 1689745732000
    },
    {
        "time": 1689745734000,
        "value": 139.34,
        "_internal_originalTime": 1689745734000
    },
    {
        "time": 1689745735000,
        "value": 139.342,
        "_internal_originalTime": 1689745735000
    },
    {
        "time": 1689745736000,
        "value": 139.339,
        "_internal_originalTime": 1689745736000
    },
    {
        "time": 1689745739000,
        "value": 139.336,
        "_internal_originalTime": 1689745739000
    },
    {
        "time": 1689745740000,
        "value": 139.342,
        "_internal_originalTime": 1689745740000
    },
    {
        "time": 1689745741000,
        "value": 139.32,
        "_internal_originalTime": 1689745741000
    },
    {
        "time": 1689745742000,
        "value": 139.349,
        "_internal_originalTime": 1689745742000
    },
    {
        "time": 1689745744000,
        "value": 139.354,
        "_internal_originalTime": 1689745744000
    },
    {
        "time": 1689745745000,
        "value": 139.346,
        "_internal_originalTime": 1689745745000
    },
    {
        "time": 1689745746000,
        "value": 139.347,
        "_internal_originalTime": 1689745746000
    },
    {
        "time": 1689745748000,
        "value": 139.349,
        "_internal_originalTime": 1689745748000
    },
    {
        "time": 1689745749000,
        "value": 139.353,
        "_internal_originalTime": 1689745749000
    },
    {
        "time": 1689745750000,
        "value": 139.352,
        "_internal_originalTime": 1689745750000
    },
    {
        "time": 1689745753000,
        "value": 139.352,
        "_internal_originalTime": 1689745753000
    },
    {
        "time": 1689745755000,
        "value": 139.355,
        "_internal_originalTime": 1689745755000
    },
    {
        "time": 1689745756000,
        "value": 139.351,
        "_internal_originalTime": 1689745756000
    },
    {
        "time": 1689745758000,
        "value": 139.354,
        "_internal_originalTime": 1689745758000
    },
    {
        "time": 1689745759000,
        "value": 139.355,
        "_internal_originalTime": 1689745759000
    },
    {
        "time": 1689745762000,
        "value": 139.351,
        "_internal_originalTime": 1689745762000
    },
    {
        "time": 1689745764000,
        "value": 139.355,
        "_internal_originalTime": 1689745764000
    },
    {
        "time": 1689745765000,
        "value": 139.354,
        "_internal_originalTime": 1689745765000
    },
    {
        "time": 1689745766000,
        "value": 139.361,
        "_internal_originalTime": 1689745766000
    },
    {
        "time": 1689745767000,
        "value": 139.36,
        "_internal_originalTime": 1689745767000
    },
    {
        "time": 1689745768000,
        "value": 139.361,
        "_internal_originalTime": 1689745768000
    },
    {
        "time": 1689745769000,
        "value": 139.359,
        "_internal_originalTime": 1689745769000
    },
    {
        "time": 1689745771000,
        "value": 139.372,
        "_internal_originalTime": 1689745771000
    },
    {
        "time": 1689745773000,
        "value": 139.374,
        "_internal_originalTime": 1689745773000
    },
    {
        "time": 1689745774000,
        "value": 139.381,
        "_internal_originalTime": 1689745774000
    },
    {
        "time": 1689745775000,
        "value": 139.38,
        "_internal_originalTime": 1689745775000
    },
    {
        "time": 1689745777000,
        "value": 139.384,
        "_internal_originalTime": 1689745777000
    },
    {
        "time": 1689745779000,
        "value": 139.389,
        "_internal_originalTime": 1689745779000
    },
    {
        "time": 1689745780000,
        "value": 139.387,
        "_internal_originalTime": 1689745780000
    },
    {
        "time": 1689745781000,
        "value": 139.389,
        "_internal_originalTime": 1689745781000
    },
    {
        "time": 1689745783000,
        "value": 139.395,
        "_internal_originalTime": 1689745783000
    },
    {
        "time": 1689745784000,
        "value": 139.392,
        "_internal_originalTime": 1689745784000
    },
    {
        "time": 1689745785000,
        "value": 139.394,
        "_internal_originalTime": 1689745785000
    },
    {
        "time": 1689745787000,
        "value": 139.389,
        "_internal_originalTime": 1689745787000
    },
    {
        "time": 1689745789000,
        "value": 139.388,
        "_internal_originalTime": 1689745789000
    },
    {
        "time": 1689745790000,
        "value": 139.387,
        "_internal_originalTime": 1689745790000
    },
    {
        "time": 1689745792000,
        "value": 139.378,
        "_internal_originalTime": 1689745792000
    },
    {
        "time": 1689745793000,
        "value": 139.381,
        "_internal_originalTime": 1689745793000
    },
    {
        "time": 1689745794000,
        "value": 139.378,
        "_internal_originalTime": 1689745794000
    },
    {
        "time": 1689745795000,
        "value": 139.386,
        "_internal_originalTime": 1689745795000
    },
    {
        "time": 1689745797000,
        "value": 139.384,
        "_internal_originalTime": 1689745797000
    },
    {
        "time": 1689745798000,
        "value": 139.384,
        "_internal_originalTime": 1689745798000
    },
    {
        "time": 1689745799000,
        "value": 139.384,
        "_internal_originalTime": 1689745799000
    },
    {
        "time": 1689745801000,
        "value": 139.388,
        "_internal_originalTime": 1689745801000
    },
    {
        "time": 1689745803000,
        "value": 139.385,
        "_internal_originalTime": 1689745803000
    },
    {
        "time": 1689745804000,
        "value": 139.385,
        "_internal_originalTime": 1689745804000
    },
    {
        "time": 1689745805000,
        "value": 139.385,
        "_internal_originalTime": 1689745805000
    },
    {
        "time": 1689745808000,
        "value": 139.384,
        "_internal_originalTime": 1689745808000
    },
    {
        "time": 1689745810000,
        "value": 139.385,
        "_internal_originalTime": 1689745810000
    },
    {
        "time": 1689745811000,
        "value": 139.396,
        "_internal_originalTime": 1689745811000
    },
    {
        "time": 1689745813000,
        "value": 139.398,
        "_internal_originalTime": 1689745813000
    },
    {
        "time": 1689745814000,
        "value": 139.388,
        "_internal_originalTime": 1689745814000
    },
    {
        "time": 1689745815000,
        "value": 139.395,
        "_internal_originalTime": 1689745815000
    },
    {
        "time": 1689745816000,
        "value": 139.4,
        "_internal_originalTime": 1689745816000
    },
    {
        "time": 1689745817000,
        "value": 139.398,
        "_internal_originalTime": 1689745817000
    },
    {
        "time": 1689745819000,
        "value": 139.403,
        "_internal_originalTime": 1689745819000
    },
    {
        "time": 1689745820000,
        "value": 139.403,
        "_internal_originalTime": 1689745820000
    },
    {
        "time": 1689745824000,
        "value": 139.402,
        "_internal_originalTime": 1689745824000
    },
    {
        "time": 1689745825000,
        "value": 139.41,
        "_internal_originalTime": 1689745825000
    },
    {
        "time": 1689745826000,
        "value": 139.429,
        "_internal_originalTime": 1689745826000
    },
    {
        "time": 1689745827000,
        "value": 139.412,
        "_internal_originalTime": 1689745827000
    },
    {
        "time": 1689745828000,
        "value": 139.426,
        "_internal_originalTime": 1689745828000
    },
    {
        "time": 1689745829000,
        "value": 139.419,
        "_internal_originalTime": 1689745829000
    },
    {
        "time": 1689745830000,
        "value": 139.42,
        "_internal_originalTime": 1689745830000
    },
    {
        "time": 1689745831000,
        "value": 139.431,
        "_internal_originalTime": 1689745831000
    },
    {
        "time": 1689745832000,
        "value": 139.426,
        "_internal_originalTime": 1689745832000
    },
    {
        "time": 1689745833000,
        "value": 139.427,
        "_internal_originalTime": 1689745833000
    },
    {
        "time": 1689745835000,
        "value": 139.428,
        "_internal_originalTime": 1689745835000
    },
    {
        "time": 1689745836000,
        "value": 139.426,
        "_internal_originalTime": 1689745836000
    },
    {
        "time": 1689745837000,
        "value": 139.426,
        "_internal_originalTime": 1689745837000
    },
    {
        "time": 1689745838000,
        "value": 139.427,
        "_internal_originalTime": 1689745838000
    },
    {
        "time": 1689745840000,
        "value": 139.429,
        "_internal_originalTime": 1689745840000
    },
    {
        "time": 1689745841000,
        "value": 139.425,
        "_internal_originalTime": 1689745841000
    },
    {
        "time": 1689745843000,
        "value": 139.441,
        "_internal_originalTime": 1689745843000
    },
    {
        "time": 1689745844000,
        "value": 139.436,
        "_internal_originalTime": 1689745844000
    },
    {
        "time": 1689745846000,
        "value": 139.442,
        "_internal_originalTime": 1689745846000
    },
    {
        "time": 1689745848000,
        "value": 139.436,
        "_internal_originalTime": 1689745848000
    },
    {
        "time": 1689745850000,
        "value": 139.438,
        "_internal_originalTime": 1689745850000
    },
    {
        "time": 1689745851000,
        "value": 139.434,
        "_internal_originalTime": 1689745851000
    },
    {
        "time": 1689745852000,
        "value": 139.439,
        "_internal_originalTime": 1689745852000
    },
    {
        "time": 1689745853000,
        "value": 139.428,
        "_internal_originalTime": 1689745853000
    },
    {
        "time": 1689745855000,
        "value": 139.443,
        "_internal_originalTime": 1689745855000
    },
    {
        "time": 1689745856000,
        "value": 139.443,
        "_internal_originalTime": 1689745856000
    },
    {
        "time": 1689745857000,
        "value": 139.446,
        "_internal_originalTime": 1689745857000
    },
    {
        "time": 1689745858000,
        "value": 139.44,
        "_internal_originalTime": 1689745858000
    },
    {
        "time": 1689745859000,
        "value": 139.436,
        "_internal_originalTime": 1689745859000
    },
    {
        "time": 1689745860000,
        "value": 139.437,
        "_internal_originalTime": 1689745860000
    },
    {
        "time": 1689745861000,
        "value": 139.42,
        "_internal_originalTime": 1689745861000
    },
    {
        "time": 1689745862000,
        "value": 139.438,
        "_internal_originalTime": 1689745862000
    },
    {
        "time": 1689745863000,
        "value": 139.437,
        "_internal_originalTime": 1689745863000
    },
    {
        "time": 1689745864000,
        "value": 139.441,
        "_internal_originalTime": 1689745864000
    },
    {
        "time": 1689745865000,
        "value": 139.437,
        "_internal_originalTime": 1689745865000
    },
    {
        "time": 1689745866000,
        "value": 139.435,
        "_internal_originalTime": 1689745866000
    },
    {
        "time": 1689745868000,
        "value": 139.437,
        "_internal_originalTime": 1689745868000
    },
    {
        "time": 1689745869000,
        "value": 139.432,
        "_internal_originalTime": 1689745869000
    },
    {
        "time": 1689745870000,
        "value": 139.43,
        "_internal_originalTime": 1689745870000
    },
    {
        "time": 1689745871000,
        "value": 139.437,
        "_internal_originalTime": 1689745871000
    },
    {
        "time": 1689745872000,
        "value": 139.442,
        "_internal_originalTime": 1689745872000
    },
    {
        "time": 1689745873000,
        "value": 139.442,
        "_internal_originalTime": 1689745873000
    },
    {
        "time": 1689745874000,
        "value": 139.444,
        "_internal_originalTime": 1689745874000
    },
    {
        "time": 1689745875000,
        "value": 139.439,
        "_internal_originalTime": 1689745875000
    },
    {
        "time": 1689745877000,
        "value": 139.44,
        "_internal_originalTime": 1689745877000
    },
    {
        "time": 1689745878000,
        "value": 139.438,
        "_internal_originalTime": 1689745878000
    },
    {
        "time": 1689745879000,
        "value": 139.439,
        "_internal_originalTime": 1689745879000
    },
    {
        "time": 1689745880000,
        "value": 139.435,
        "_internal_originalTime": 1689745880000
    },
    {
        "time": 1689745881000,
        "value": 139.434,
        "_internal_originalTime": 1689745881000
    },
    {
        "time": 1689745882000,
        "value": 139.438,
        "_internal_originalTime": 1689745882000
    },
    {
        "time": 1689745884000,
        "value": 139.437,
        "_internal_originalTime": 1689745884000
    },
    {
        "time": 1689745885000,
        "value": 139.436,
        "_internal_originalTime": 1689745885000
    },
    {
        "time": 1689745887000,
        "value": 139.436,
        "_internal_originalTime": 1689745887000
    },
    {
        "time": 1689745888000,
        "value": 139.44,
        "_internal_originalTime": 1689745888000
    },
    {
        "time": 1689745889000,
        "value": 139.437,
        "_internal_originalTime": 1689745889000
    },
    {
        "time": 1689745891000,
        "value": 139.437,
        "_internal_originalTime": 1689745891000
    },
    {
        "time": 1689745892000,
        "value": 139.432,
        "_internal_originalTime": 1689745892000
    },
    {
        "time": 1689745894000,
        "value": 139.44,
        "_internal_originalTime": 1689745894000
    },
    {
        "time": 1689745895000,
        "value": 139.44,
        "_internal_originalTime": 1689745895000
    },
    {
        "time": 1689745896000,
        "value": 139.439,
        "_internal_originalTime": 1689745896000
    },
    {
        "time": 1689745897000,
        "value": 139.434,
        "_internal_originalTime": 1689745897000
    },
    {
        "time": 1689745899000,
        "value": 139.434,
        "_internal_originalTime": 1689745899000
    },
    {
        "time": 1689745901000,
        "value": 139.434,
        "_internal_originalTime": 1689745901000
    },
    {
        "time": 1689745902000,
        "value": 139.435,
        "_internal_originalTime": 1689745902000
    },
    {
        "time": 1689745903000,
        "value": 139.421,
        "_internal_originalTime": 1689745903000
    },
    {
        "time": 1689745904000,
        "value": 139.422,
        "_internal_originalTime": 1689745904000
    },
    {
        "time": 1689745905000,
        "value": 139.414,
        "_internal_originalTime": 1689745905000
    },
    {
        "time": 1689745906000,
        "value": 139.42,
        "_internal_originalTime": 1689745906000
    },
    {
        "time": 1689745908000,
        "value": 139.418,
        "_internal_originalTime": 1689745908000
    },
    {
        "time": 1689745909000,
        "value": 139.416,
        "_internal_originalTime": 1689745909000
    },
    {
        "time": 1689745911000,
        "value": 139.42,
        "_internal_originalTime": 1689745911000
    },
    {
        "time": 1689745912000,
        "value": 139.409,
        "_internal_originalTime": 1689745912000
    },
    {
        "time": 1689745913000,
        "value": 139.407,
        "_internal_originalTime": 1689745913000
    },
    {
        "time": 1689745914000,
        "value": 139.413,
        "_internal_originalTime": 1689745914000
    },
    {
        "time": 1689745915000,
        "value": 139.408,
        "_internal_originalTime": 1689745915000
    },
    {
        "time": 1689745918000,
        "value": 139.412,
        "_internal_originalTime": 1689745918000
    },
    {
        "time": 1689745919000,
        "value": 139.41,
        "_internal_originalTime": 1689745919000
    },
    {
        "time": 1689745920000,
        "value": 139.407,
        "_internal_originalTime": 1689745920000
    },
    {
        "time": 1689745921000,
        "value": 139.407,
        "_internal_originalTime": 1689745921000
    },
    {
        "time": 1689745923000,
        "value": 139.407,
        "_internal_originalTime": 1689745923000
    },
    {
        "time": 1689745924000,
        "value": 139.407,
        "_internal_originalTime": 1689745924000
    },
    {
        "time": 1689745926000,
        "value": 139.41,
        "_internal_originalTime": 1689745926000
    },
    {
        "time": 1689745928000,
        "value": 139.409,
        "_internal_originalTime": 1689745928000
    },
    {
        "time": 1689745929000,
        "value": 139.426,
        "_internal_originalTime": 1689745929000
    },
    {
        "time": 1689745931000,
        "value": 139.436,
        "_internal_originalTime": 1689745931000
    },
    {
        "time": 1689745933000,
        "value": 139.435,
        "_internal_originalTime": 1689745933000
    },
    {
        "time": 1689745934000,
        "value": 139.433,
        "_internal_originalTime": 1689745934000
    },
    {
        "time": 1689745935000,
        "value": 139.428,
        "_internal_originalTime": 1689745935000
    },
    {
        "time": 1689745936000,
        "value": 139.429,
        "_internal_originalTime": 1689745936000
    },
    {
        "time": 1689745937000,
        "value": 139.427,
        "_internal_originalTime": 1689745937000
    },
    {
        "time": 1689745938000,
        "value": 139.43,
        "_internal_originalTime": 1689745938000
    },
    {
        "time": 1689745939000,
        "value": 139.426,
        "_internal_originalTime": 1689745939000
    },
    {
        "time": 1689745941000,
        "value": 139.426,
        "_internal_originalTime": 1689745941000
    },
    {
        "time": 1689745942000,
        "value": 139.425,
        "_internal_originalTime": 1689745942000
    },
    {
        "time": 1689745943000,
        "value": 139.423,
        "_internal_originalTime": 1689745943000
    },
    {
        "time": 1689745946000,
        "value": 139.416,
        "_internal_originalTime": 1689745946000
    },
    {
        "time": 1689745947000,
        "value": 139.422,
        "_internal_originalTime": 1689745947000
    },
    {
        "time": 1689745948000,
        "value": 139.422,
        "_internal_originalTime": 1689745948000
    },
    {
        "time": 1689745949000,
        "value": 139.425,
        "_internal_originalTime": 1689745949000
    },
    {
        "time": 1689745950000,
        "value": 139.423,
        "_internal_originalTime": 1689745950000
    },
    {
        "time": 1689745951000,
        "value": 139.416,
        "_internal_originalTime": 1689745951000
    },
    {
        "time": 1689745952000,
        "value": 139.42,
        "_internal_originalTime": 1689745952000
    },
    {
        "time": 1689745953000,
        "value": 139.42,
        "_internal_originalTime": 1689745953000
    },
    {
        "time": 1689745954000,
        "value": 139.421,
        "_internal_originalTime": 1689745954000
    },
    {
        "time": 1689745955000,
        "value": 139.422,
        "_internal_originalTime": 1689745955000
    },
    {
        "time": 1689745958000,
        "value": 139.42,
        "_internal_originalTime": 1689745958000
    },
    {
        "time": 1689745959000,
        "value": 139.419,
        "_internal_originalTime": 1689745959000
    },
    {
        "time": 1689745960000,
        "value": 139.42,
        "_internal_originalTime": 1689745960000
    },
    {
        "time": 1689745961000,
        "value": 139.42,
        "_internal_originalTime": 1689745961000
    },
    {
        "time": 1689745963000,
        "value": 139.416,
        "_internal_originalTime": 1689745963000
    },
    {
        "time": 1689745964000,
        "value": 139.423,
        "_internal_originalTime": 1689745964000
    },
    {
        "time": 1689745966000,
        "value": 139.419,
        "_internal_originalTime": 1689745966000
    },
    {
        "time": 1689745967000,
        "value": 139.42,
        "_internal_originalTime": 1689745967000
    },
    {
        "time": 1689745968000,
        "value": 139.418,
        "_internal_originalTime": 1689745968000
    },
    {
        "time": 1689745969000,
        "value": 139.424,
        "_internal_originalTime": 1689745969000
    },
    {
        "time": 1689745971000,
        "value": 139.419,
        "_internal_originalTime": 1689745971000
    },
    {
        "time": 1689745972000,
        "value": 139.421,
        "_internal_originalTime": 1689745972000
    },
    {
        "time": 1689745973000,
        "value": 139.419,
        "_internal_originalTime": 1689745973000
    },
    {
        "time": 1689745974000,
        "value": 139.42,
        "_internal_originalTime": 1689745974000
    },
    {
        "time": 1689745975000,
        "value": 139.426,
        "_internal_originalTime": 1689745975000
    },
    {
        "time": 1689745977000,
        "value": 139.42,
        "_internal_originalTime": 1689745977000
    },
    {
        "time": 1689745978000,
        "value": 139.416,
        "_internal_originalTime": 1689745978000
    },
    {
        "time": 1689745979000,
        "value": 139.416,
        "_internal_originalTime": 1689745979000
    },
    {
        "time": 1689745981000,
        "value": 139.418,
        "_internal_originalTime": 1689745981000
    },
    {
        "time": 1689745982000,
        "value": 139.422,
        "_internal_originalTime": 1689745982000
    },
    {
        "time": 1689745983000,
        "value": 139.422,
        "_internal_originalTime": 1689745983000
    },
    {
        "time": 1689745984000,
        "value": 139.42,
        "_internal_originalTime": 1689745984000
    },
    {
        "time": 1689745985000,
        "value": 139.42,
        "_internal_originalTime": 1689745985000
    },
    {
        "time": 1689745987000,
        "value": 139.427,
        "_internal_originalTime": 1689745987000
    },
    {
        "time": 1689745988000,
        "value": 139.426,
        "_internal_originalTime": 1689745988000
    },
    {
        "time": 1689745989000,
        "value": 139.42,
        "_internal_originalTime": 1689745989000
    },
    {
        "time": 1689745991000,
        "value": 139.419,
        "_internal_originalTime": 1689745991000
    },
    {
        "time": 1689745993000,
        "value": 139.422,
        "_internal_originalTime": 1689745993000
    },
    {
        "time": 1689745994000,
        "value": 139.42,
        "_internal_originalTime": 1689745994000
    },
    {
        "time": 1689745995000,
        "value": 139.419,
        "_internal_originalTime": 1689745995000
    },
    {
        "time": 1689745996000,
        "value": 139.42,
        "_internal_originalTime": 1689745996000
    },
    {
        "time": 1689745997000,
        "value": 139.427,
        "_internal_originalTime": 1689745997000
    },
    {
        "time": 1689745999000,
        "value": 139.423,
        "_internal_originalTime": 1689745999000
    },
    {
        "time": 1689746000000,
        "value": 139.422,
        "_internal_originalTime": 1689746000000
    },
    {
        "time": 1689746002000,
        "value": 139.428,
        "_internal_originalTime": 1689746002000
    },
    {
        "time": 1689746004000,
        "value": 139.43,
        "_internal_originalTime": 1689746004000
    },
    {
        "time": 1689746005000,
        "value": 139.425,
        "_internal_originalTime": 1689746005000
    },
    {
        "time": 1689746006000,
        "value": 139.429,
        "_internal_originalTime": 1689746006000
    },
    {
        "time": 1689746007000,
        "value": 139.425,
        "_internal_originalTime": 1689746007000
    },
    {
        "time": 1689746008000,
        "value": 139.427,
        "_internal_originalTime": 1689746008000
    },
    {
        "time": 1689746009000,
        "value": 139.426,
        "_internal_originalTime": 1689746009000
    },
    {
        "time": 1689746010000,
        "value": 139.426,
        "_internal_originalTime": 1689746010000
    },
    {
        "time": 1689746011000,
        "value": 139.431,
        "_internal_originalTime": 1689746011000
    },
    {
        "time": 1689746013000,
        "value": 139.423,
        "_internal_originalTime": 1689746013000
    },
    {
        "time": 1689746014000,
        "value": 139.423,
        "_internal_originalTime": 1689746014000
    },
    {
        "time": 1689746015000,
        "value": 139.418,
        "_internal_originalTime": 1689746015000
    },
    {
        "time": 1689746017000,
        "value": 139.414,
        "_internal_originalTime": 1689746017000
    },
    {
        "time": 1689746018000,
        "value": 139.416,
        "_internal_originalTime": 1689746018000
    },
    {
        "time": 1689746019000,
        "value": 139.435,
        "_internal_originalTime": 1689746019000
    },
    {
        "time": 1689746020000,
        "value": 139.428,
        "_internal_originalTime": 1689746020000
    },
    {
        "time": 1689746021000,
        "value": 139.429,
        "_internal_originalTime": 1689746021000
    },
    {
        "time": 1689746022000,
        "value": 139.424,
        "_internal_originalTime": 1689746022000
    },
    {
        "time": 1689746024000,
        "value": 139.433,
        "_internal_originalTime": 1689746024000
    },
    {
        "time": 1689746025000,
        "value": 139.428,
        "_internal_originalTime": 1689746025000
    },
    {
        "time": 1689746026000,
        "value": 139.429,
        "_internal_originalTime": 1689746026000
    },
    {
        "time": 1689746028000,
        "value": 139.428,
        "_internal_originalTime": 1689746028000
    },
    {
        "time": 1689746029000,
        "value": 139.432,
        "_internal_originalTime": 1689746029000
    },
    {
        "time": 1689746031000,
        "value": 139.425,
        "_internal_originalTime": 1689746031000
    },
    {
        "time": 1689746032000,
        "value": 139.424,
        "_internal_originalTime": 1689746032000
    },
    {
        "time": 1689746033000,
        "value": 139.417,
        "_internal_originalTime": 1689746033000
    },
    {
        "time": 1689746034000,
        "value": 139.42,
        "_internal_originalTime": 1689746034000
    },
    {
        "time": 1689746036000,
        "value": 139.419,
        "_internal_originalTime": 1689746036000
    },
    {
        "time": 1689746037000,
        "value": 139.421,
        "_internal_originalTime": 1689746037000
    },
    {
        "time": 1689746038000,
        "value": 139.421,
        "_internal_originalTime": 1689746038000
    },
    {
        "time": 1689746039000,
        "value": 139.42,
        "_internal_originalTime": 1689746039000
    },
    {
        "time": 1689746042000,
        "value": 139.414,
        "_internal_originalTime": 1689746042000
    },
    {
        "time": 1689746043000,
        "value": 139.413,
        "_internal_originalTime": 1689746043000
    },
    {
        "time": 1689746044000,
        "value": 139.416,
        "_internal_originalTime": 1689746044000
    },
    {
        "time": 1689746046000,
        "value": 139.411,
        "_internal_originalTime": 1689746046000
    },
    {
        "time": 1689746047000,
        "value": 139.408,
        "_internal_originalTime": 1689746047000
    },
    {
        "time": 1689746048000,
        "value": 139.413,
        "_internal_originalTime": 1689746048000
    },
    {
        "time": 1689746049000,
        "value": 139.413,
        "_internal_originalTime": 1689746049000
    },
    {
        "time": 1689746050000,
        "value": 139.414,
        "_internal_originalTime": 1689746050000
    },
    {
        "time": 1689746052000,
        "value": 139.414,
        "_internal_originalTime": 1689746052000
    },
    {
        "time": 1689746053000,
        "value": 139.408,
        "_internal_originalTime": 1689746053000
    },
    {
        "time": 1689746054000,
        "value": 139.411,
        "_internal_originalTime": 1689746054000
    },
    {
        "time": 1689746055000,
        "value": 139.412,
        "_internal_originalTime": 1689746055000
    },
    {
        "time": 1689746058000,
        "value": 139.409,
        "_internal_originalTime": 1689746058000
    },
    {
        "time": 1689746060000,
        "value": 139.412,
        "_internal_originalTime": 1689746060000
    },
    {
        "time": 1689746061000,
        "value": 139.407,
        "_internal_originalTime": 1689746061000
    },
    {
        "time": 1689746062000,
        "value": 139.412,
        "_internal_originalTime": 1689746062000
    },
    {
        "time": 1689746064000,
        "value": 139.404,
        "_internal_originalTime": 1689746064000
    },
    {
        "time": 1689746066000,
        "value": 139.412,
        "_internal_originalTime": 1689746066000
    },
    {
        "time": 1689746067000,
        "value": 139.412,
        "_internal_originalTime": 1689746067000
    },
    {
        "time": 1689746068000,
        "value": 139.409,
        "_internal_originalTime": 1689746068000
    },
    {
        "time": 1689746069000,
        "value": 139.407,
        "_internal_originalTime": 1689746069000
    },
    {
        "time": 1689746071000,
        "value": 139.407,
        "_internal_originalTime": 1689746071000
    },
    {
        "time": 1689746073000,
        "value": 139.401,
        "_internal_originalTime": 1689746073000
    },
    {
        "time": 1689746074000,
        "value": 139.403,
        "_internal_originalTime": 1689746074000
    },
    {
        "time": 1689746075000,
        "value": 139.399,
        "_internal_originalTime": 1689746075000
    },
    {
        "time": 1689746077000,
        "value": 139.414,
        "_internal_originalTime": 1689746077000
    },
    {
        "time": 1689746078000,
        "value": 139.409,
        "_internal_originalTime": 1689746078000
    },
    {
        "time": 1689746079000,
        "value": 139.41,
        "_internal_originalTime": 1689746079000
    },
    {
        "time": 1689746080000,
        "value": 139.412,
        "_internal_originalTime": 1689746080000
    },
    {
        "time": 1689746082000,
        "value": 139.406,
        "_internal_originalTime": 1689746082000
    },
    {
        "time": 1689746084000,
        "value": 139.407,
        "_internal_originalTime": 1689746084000
    },
    {
        "time": 1689746086000,
        "value": 139.404,
        "_internal_originalTime": 1689746086000
    },
    {
        "time": 1689746087000,
        "value": 139.406,
        "_internal_originalTime": 1689746087000
    },
    {
        "time": 1689746088000,
        "value": 139.407,
        "_internal_originalTime": 1689746088000
    },
    {
        "time": 1689746089000,
        "value": 139.404,
        "_internal_originalTime": 1689746089000
    },
    {
        "time": 1689746091000,
        "value": 139.406,
        "_internal_originalTime": 1689746091000
    },
    {
        "time": 1689746092000,
        "value": 139.41,
        "_internal_originalTime": 1689746092000
    },
    {
        "time": 1689746093000,
        "value": 139.41,
        "_internal_originalTime": 1689746093000
    },
    {
        "time": 1689746095000,
        "value": 139.407,
        "_internal_originalTime": 1689746095000
    },
    {
        "time": 1689746097000,
        "value": 139.407,
        "_internal_originalTime": 1689746097000
    },
    {
        "time": 1689746099000,
        "value": 139.408,
        "_internal_originalTime": 1689746099000
    },
    {
        "time": 1689746100000,
        "value": 139.405,
        "_internal_originalTime": 1689746100000
    },
    {
        "time": 1689746101000,
        "value": 139.411,
        "_internal_originalTime": 1689746101000
    },
    {
        "time": 1689746102000,
        "value": 139.41,
        "_internal_originalTime": 1689746102000
    },
    {
        "time": 1689746103000,
        "value": 139.402,
        "_internal_originalTime": 1689746103000
    },
    {
        "time": 1689746104000,
        "value": 139.403,
        "_internal_originalTime": 1689746104000
    },
    {
        "time": 1689746105000,
        "value": 139.407,
        "_internal_originalTime": 1689746105000
    },
    {
        "time": 1689746106000,
        "value": 139.406,
        "_internal_originalTime": 1689746106000
    },
    {
        "time": 1689746107000,
        "value": 139.407,
        "_internal_originalTime": 1689746107000
    },
    {
        "time": 1689746108000,
        "value": 139.406,
        "_internal_originalTime": 1689746108000
    },
    {
        "time": 1689746110000,
        "value": 139.407,
        "_internal_originalTime": 1689746110000
    },
    {
        "time": 1689746111000,
        "value": 139.41,
        "_internal_originalTime": 1689746111000
    },
    {
        "time": 1689746113000,
        "value": 139.408,
        "_internal_originalTime": 1689746113000
    },
    {
        "time": 1689746115000,
        "value": 139.408,
        "_internal_originalTime": 1689746115000
    },
    {
        "time": 1689746116000,
        "value": 139.412,
        "_internal_originalTime": 1689746116000
    },
    {
        "time": 1689746117000,
        "value": 139.41,
        "_internal_originalTime": 1689746117000
    },
    {
        "time": 1689746119000,
        "value": 139.402,
        "_internal_originalTime": 1689746119000
    },
    {
        "time": 1689746120000,
        "value": 139.408,
        "_internal_originalTime": 1689746120000
    },
    {
        "time": 1689746121000,
        "value": 139.408,
        "_internal_originalTime": 1689746121000
    },
    {
        "time": 1689746122000,
        "value": 139.401,
        "_internal_originalTime": 1689746122000
    },
    {
        "time": 1689746123000,
        "value": 139.398,
        "_internal_originalTime": 1689746123000
    },
    {
        "time": 1689746124000,
        "value": 139.404,
        "_internal_originalTime": 1689746124000
    },
    {
        "time": 1689746125000,
        "value": 139.399,
        "_internal_originalTime": 1689746125000
    },
    {
        "time": 1689746127000,
        "value": 139.403,
        "_internal_originalTime": 1689746127000
    },
    {
        "time": 1689746128000,
        "value": 139.398,
        "_internal_originalTime": 1689746128000
    },
    {
        "time": 1689746129000,
        "value": 139.397,
        "_internal_originalTime": 1689746129000
    },
    {
        "time": 1689746130000,
        "value": 139.399,
        "_internal_originalTime": 1689746130000
    },
    {
        "time": 1689746131000,
        "value": 139.396,
        "_internal_originalTime": 1689746131000
    },
    {
        "time": 1689746132000,
        "value": 139.395,
        "_internal_originalTime": 1689746132000
    },
    {
        "time": 1689746133000,
        "value": 139.39,
        "_internal_originalTime": 1689746133000
    },
    {
        "time": 1689746135000,
        "value": 139.389,
        "_internal_originalTime": 1689746135000
    },
    {
        "time": 1689746136000,
        "value": 139.389,
        "_internal_originalTime": 1689746136000
    },
    {
        "time": 1689746137000,
        "value": 139.39,
        "_internal_originalTime": 1689746137000
    },
    {
        "time": 1689746138000,
        "value": 139.399,
        "_internal_originalTime": 1689746138000
    },
    {
        "time": 1689746139000,
        "value": 139.394,
        "_internal_originalTime": 1689746139000
    },
    {
        "time": 1689746140000,
        "value": 139.393,
        "_internal_originalTime": 1689746140000
    },
    {
        "time": 1689746141000,
        "value": 139.391,
        "_internal_originalTime": 1689746141000
    },
    {
        "time": 1689746143000,
        "value": 139.391,
        "_internal_originalTime": 1689746143000
    },
    {
        "time": 1689746145000,
        "value": 139.392,
        "_internal_originalTime": 1689746145000
    },
    {
        "time": 1689746147000,
        "value": 139.397,
        "_internal_originalTime": 1689746147000
    },
    {
        "time": 1689746148000,
        "value": 139.39,
        "_internal_originalTime": 1689746148000
    },
    {
        "time": 1689746149000,
        "value": 139.389,
        "_internal_originalTime": 1689746149000
    },
    {
        "time": 1689746151000,
        "value": 139.392,
        "_internal_originalTime": 1689746151000
    },
    {
        "time": 1689746152000,
        "value": 139.397,
        "_internal_originalTime": 1689746152000
    },
    {
        "time": 1689746153000,
        "value": 139.396,
        "_internal_originalTime": 1689746153000
    },
    {
        "time": 1689746155000,
        "value": 139.393,
        "_internal_originalTime": 1689746155000
    },
    {
        "time": 1689746156000,
        "value": 139.389,
        "_internal_originalTime": 1689746156000
    },
    {
        "time": 1689746157000,
        "value": 139.389,
        "_internal_originalTime": 1689746157000
    },
    {
        "time": 1689746159000,
        "value": 139.396,
        "_internal_originalTime": 1689746159000
    },
    {
        "time": 1689746160000,
        "value": 139.398,
        "_internal_originalTime": 1689746160000
    },
    {
        "time": 1689746162000,
        "value": 139.401,
        "_internal_originalTime": 1689746162000
    },
    {
        "time": 1689746163000,
        "value": 139.399,
        "_internal_originalTime": 1689746163000
    },
    {
        "time": 1689746164000,
        "value": 139.398,
        "_internal_originalTime": 1689746164000
    },
    {
        "time": 1689746165000,
        "value": 139.401,
        "_internal_originalTime": 1689746165000
    },
    {
        "time": 1689746166000,
        "value": 139.397,
        "_internal_originalTime": 1689746166000
    },
    {
        "time": 1689746167000,
        "value": 139.403,
        "_internal_originalTime": 1689746167000
    },
    {
        "time": 1689746169000,
        "value": 139.394,
        "_internal_originalTime": 1689746169000
    },
    {
        "time": 1689746170000,
        "value": 139.395,
        "_internal_originalTime": 1689746170000
    },
    {
        "time": 1689746171000,
        "value": 139.395,
        "_internal_originalTime": 1689746171000
    },
    {
        "time": 1689746173000,
        "value": 139.397,
        "_internal_originalTime": 1689746173000
    },
    {
        "time": 1689746174000,
        "value": 139.398,
        "_internal_originalTime": 1689746174000
    },
    {
        "time": 1689746176000,
        "value": 139.403,
        "_internal_originalTime": 1689746176000
    },
    {
        "time": 1689746177000,
        "value": 139.396,
        "_internal_originalTime": 1689746177000
    },
    {
        "time": 1689746178000,
        "value": 139.407,
        "_internal_originalTime": 1689746178000
    },
    {
        "time": 1689746179000,
        "value": 139.401,
        "_internal_originalTime": 1689746179000
    },
    {
        "time": 1689746180000,
        "value": 139.405,
        "_internal_originalTime": 1689746180000
    },
    {
        "time": 1689746181000,
        "value": 139.4,
        "_internal_originalTime": 1689746181000
    },
    {
        "time": 1689746183000,
        "value": 139.406,
        "_internal_originalTime": 1689746183000
    },
    {
        "time": 1689746184000,
        "value": 139.407,
        "_internal_originalTime": 1689746184000
    },
    {
        "time": 1689746185000,
        "value": 139.406,
        "_internal_originalTime": 1689746185000
    },
    {
        "time": 1689746186000,
        "value": 139.398,
        "_internal_originalTime": 1689746186000
    },
    {
        "time": 1689746188000,
        "value": 139.401,
        "_internal_originalTime": 1689746188000
    },
    {
        "time": 1689746189000,
        "value": 139.41,
        "_internal_originalTime": 1689746189000
    },
    {
        "time": 1689746191000,
        "value": 139.407,
        "_internal_originalTime": 1689746191000
    },
    {
        "time": 1689746192000,
        "value": 139.408,
        "_internal_originalTime": 1689746192000
    },
    {
        "time": 1689746193000,
        "value": 139.404,
        "_internal_originalTime": 1689746193000
    },
    {
        "time": 1689746194000,
        "value": 139.402,
        "_internal_originalTime": 1689746194000
    },
    {
        "time": 1689746195000,
        "value": 139.406,
        "_internal_originalTime": 1689746195000
    },
    {
        "time": 1689746197000,
        "value": 139.406,
        "_internal_originalTime": 1689746197000
    },
    {
        "time": 1689746198000,
        "value": 139.405,
        "_internal_originalTime": 1689746198000
    },
    {
        "time": 1689746199000,
        "value": 139.402,
        "_internal_originalTime": 1689746199000
    },
    {
        "time": 1689746200000,
        "value": 139.401,
        "_internal_originalTime": 1689746200000
    },
    {
        "time": 1689746202000,
        "value": 139.399,
        "_internal_originalTime": 1689746202000
    },
    {
        "time": 1689746203000,
        "value": 139.396,
        "_internal_originalTime": 1689746203000
    },
    {
        "time": 1689746205000,
        "value": 139.397,
        "_internal_originalTime": 1689746205000
    },
    {
        "time": 1689746206000,
        "value": 139.397,
        "_internal_originalTime": 1689746206000
    },
    {
        "time": 1689746208000,
        "value": 139.399,
        "_internal_originalTime": 1689746208000
    },
    {
        "time": 1689746209000,
        "value": 139.399,
        "_internal_originalTime": 1689746209000
    },
    {
        "time": 1689746210000,
        "value": 139.402,
        "_internal_originalTime": 1689746210000
    },
    {
        "time": 1689746211000,
        "value": 139.402,
        "_internal_originalTime": 1689746211000
    },
    {
        "time": 1689746212000,
        "value": 139.396,
        "_internal_originalTime": 1689746212000
    },
    {
        "time": 1689746214000,
        "value": 139.399,
        "_internal_originalTime": 1689746214000
    },
    {
        "time": 1689746215000,
        "value": 139.394,
        "_internal_originalTime": 1689746215000
    },
    {
        "time": 1689746216000,
        "value": 139.396,
        "_internal_originalTime": 1689746216000
    },
    {
        "time": 1689746217000,
        "value": 139.396,
        "_internal_originalTime": 1689746217000
    },
    {
        "time": 1689746219000,
        "value": 139.398,
        "_internal_originalTime": 1689746219000
    },
    {
        "time": 1689746220000,
        "value": 139.403,
        "_internal_originalTime": 1689746220000
    },
    {
        "time": 1689746221000,
        "value": 139.418,
        "_internal_originalTime": 1689746221000
    },
    {
        "time": 1689746222000,
        "value": 139.412,
        "_internal_originalTime": 1689746222000
    },
    {
        "time": 1689746223000,
        "value": 139.409,
        "_internal_originalTime": 1689746223000
    },
    {
        "time": 1689746224000,
        "value": 139.412,
        "_internal_originalTime": 1689746224000
    },
    {
        "time": 1689746225000,
        "value": 139.408,
        "_internal_originalTime": 1689746225000
    },
    {
        "time": 1689746226000,
        "value": 139.408,
        "_internal_originalTime": 1689746226000
    },
    {
        "time": 1689746227000,
        "value": 139.407,
        "_internal_originalTime": 1689746227000
    },
    {
        "time": 1689746228000,
        "value": 139.408,
        "_internal_originalTime": 1689746228000
    },
    {
        "time": 1689746230000,
        "value": 139.408,
        "_internal_originalTime": 1689746230000
    },
    {
        "time": 1689746231000,
        "value": 139.412,
        "_internal_originalTime": 1689746231000
    },
    {
        "time": 1689746232000,
        "value": 139.409,
        "_internal_originalTime": 1689746232000
    },
    {
        "time": 1689746234000,
        "value": 139.406,
        "_internal_originalTime": 1689746234000
    },
    {
        "time": 1689746235000,
        "value": 139.408,
        "_internal_originalTime": 1689746235000
    },
    {
        "time": 1689746236000,
        "value": 139.406,
        "_internal_originalTime": 1689746236000
    },
    {
        "time": 1689746237000,
        "value": 139.409,
        "_internal_originalTime": 1689746237000
    },
    {
        "time": 1689746238000,
        "value": 139.41,
        "_internal_originalTime": 1689746238000
    },
    {
        "time": 1689746239000,
        "value": 139.413,
        "_internal_originalTime": 1689746239000
    },
    {
        "time": 1689746240000,
        "value": 139.41,
        "_internal_originalTime": 1689746240000
    },
    {
        "time": 1689746242000,
        "value": 139.411,
        "_internal_originalTime": 1689746242000
    },
    {
        "time": 1689746243000,
        "value": 139.412,
        "_internal_originalTime": 1689746243000
    },
    {
        "time": 1689746244000,
        "value": 139.412,
        "_internal_originalTime": 1689746244000
    },
    {
        "time": 1689746245000,
        "value": 139.408,
        "_internal_originalTime": 1689746245000
    },
    {
        "time": 1689746246000,
        "value": 139.411,
        "_internal_originalTime": 1689746246000
    },
    {
        "time": 1689746248000,
        "value": 139.409,
        "_internal_originalTime": 1689746248000
    },
    {
        "time": 1689746249000,
        "value": 139.41,
        "_internal_originalTime": 1689746249000
    },
    {
        "time": 1689746250000,
        "value": 139.408,
        "_internal_originalTime": 1689746250000
    },
    {
        "time": 1689746251000,
        "value": 139.404,
        "_internal_originalTime": 1689746251000
    },
    {
        "time": 1689746252000,
        "value": 139.406,
        "_internal_originalTime": 1689746252000
    },
    {
        "time": 1689746253000,
        "value": 139.405,
        "_internal_originalTime": 1689746253000
    },
    {
        "time": 1689746255000,
        "value": 139.4,
        "_internal_originalTime": 1689746255000
    },
    {
        "time": 1689746256000,
        "value": 139.401,
        "_internal_originalTime": 1689746256000
    },
    {
        "time": 1689746258000,
        "value": 139.401,
        "_internal_originalTime": 1689746258000
    },
    {
        "time": 1689746263000,
        "value": 139.402,
        "_internal_originalTime": 1689746263000
    },
    {
        "time": 1689746264000,
        "value": 139.406,
        "_internal_originalTime": 1689746264000
    },
    {
        "time": 1689746265000,
        "value": 139.398,
        "_internal_originalTime": 1689746265000
    },
    {
        "time": 1689746268000,
        "value": 139.398,
        "_internal_originalTime": 1689746268000
    },
    {
        "time": 1689746269000,
        "value": 139.402,
        "_internal_originalTime": 1689746269000
    },
    {
        "time": 1689746272000,
        "value": 139.404,
        "_internal_originalTime": 1689746272000
    },
    {
        "time": 1689746273000,
        "value": 139.396,
        "_internal_originalTime": 1689746273000
    },
    {
        "time": 1689746274000,
        "value": 139.4,
        "_internal_originalTime": 1689746274000
    },
    {
        "time": 1689746275000,
        "value": 139.401,
        "_internal_originalTime": 1689746275000
    },
    {
        "time": 1689746277000,
        "value": 139.4,
        "_internal_originalTime": 1689746277000
    },
    {
        "time": 1689746278000,
        "value": 139.396,
        "_internal_originalTime": 1689746278000
    },
    {
        "time": 1689746279000,
        "value": 139.392,
        "_internal_originalTime": 1689746279000
    },
    {
        "time": 1689746280000,
        "value": 139.394,
        "_internal_originalTime": 1689746280000
    },
    {
        "time": 1689746281000,
        "value": 139.392,
        "_internal_originalTime": 1689746281000
    },
    {
        "time": 1689746283000,
        "value": 139.394,
        "_internal_originalTime": 1689746283000
    },
    {
        "time": 1689746284000,
        "value": 139.392,
        "_internal_originalTime": 1689746284000
    },
    {
        "time": 1689746286000,
        "value": 139.393,
        "_internal_originalTime": 1689746286000
    },
    {
        "time": 1689746287000,
        "value": 139.391,
        "_internal_originalTime": 1689746287000
    },
    {
        "time": 1689746288000,
        "value": 139.387,
        "_internal_originalTime": 1689746288000
    },
    {
        "time": 1689746289000,
        "value": 139.39,
        "_internal_originalTime": 1689746289000
    },
    {
        "time": 1689746290000,
        "value": 139.386,
        "_internal_originalTime": 1689746290000
    },
    {
        "time": 1689746292000,
        "value": 139.389,
        "_internal_originalTime": 1689746292000
    },
    {
        "time": 1689746293000,
        "value": 139.383,
        "_internal_originalTime": 1689746293000
    },
    {
        "time": 1689746294000,
        "value": 139.38,
        "_internal_originalTime": 1689746294000
    },
    {
        "time": 1689746295000,
        "value": 139.381,
        "_internal_originalTime": 1689746295000
    },
    {
        "time": 1689746296000,
        "value": 139.381,
        "_internal_originalTime": 1689746296000
    },
    {
        "time": 1689746297000,
        "value": 139.381,
        "_internal_originalTime": 1689746297000
    },
    {
        "time": 1689746298000,
        "value": 139.374,
        "_internal_originalTime": 1689746298000
    },
    {
        "time": 1689746299000,
        "value": 139.371,
        "_internal_originalTime": 1689746299000
    },
    {
        "time": 1689746300000,
        "value": 139.369,
        "_internal_originalTime": 1689746300000
    },
    {
        "time": 1689746302000,
        "value": 139.371,
        "_internal_originalTime": 1689746302000
    },
    {
        "time": 1689746303000,
        "value": 139.368,
        "_internal_originalTime": 1689746303000
    },
    {
        "time": 1689746304000,
        "value": 139.369,
        "_internal_originalTime": 1689746304000
    },
    {
        "time": 1689746305000,
        "value": 139.366,
        "_internal_originalTime": 1689746305000
    },
    {
        "time": 1689746306000,
        "value": 139.365,
        "_internal_originalTime": 1689746306000
    },
    {
        "time": 1689746308000,
        "value": 139.362,
        "_internal_originalTime": 1689746308000
    },
    {
        "time": 1689746310000,
        "value": 139.358,
        "_internal_originalTime": 1689746310000
    },
    {
        "time": 1689746311000,
        "value": 139.361,
        "_internal_originalTime": 1689746311000
    },
    {
        "time": 1689746312000,
        "value": 139.365,
        "_internal_originalTime": 1689746312000
    },
    {
        "time": 1689746314000,
        "value": 139.367,
        "_internal_originalTime": 1689746314000
    },
    {
        "time": 1689746315000,
        "value": 139.368,
        "_internal_originalTime": 1689746315000
    },
    {
        "time": 1689746317000,
        "value": 139.361,
        "_internal_originalTime": 1689746317000
    },
    {
        "time": 1689746320000,
        "value": 139.361,
        "_internal_originalTime": 1689746320000
    },
    {
        "time": 1689746321000,
        "value": 139.359,
        "_internal_originalTime": 1689746321000
    },
    {
        "time": 1689746322000,
        "value": 139.359,
        "_internal_originalTime": 1689746322000
    },
    {
        "time": 1689746323000,
        "value": 139.361,
        "_internal_originalTime": 1689746323000
    },
    {
        "time": 1689746324000,
        "value": 139.358,
        "_internal_originalTime": 1689746324000
    },
    {
        "time": 1689746325000,
        "value": 139.359,
        "_internal_originalTime": 1689746325000
    },
    {
        "time": 1689746326000,
        "value": 139.357,
        "_internal_originalTime": 1689746326000
    },
    {
        "time": 1689746328000,
        "value": 139.357,
        "_internal_originalTime": 1689746328000
    },
    {
        "time": 1689746329000,
        "value": 139.359,
        "_internal_originalTime": 1689746329000
    },
    {
        "time": 1689746330000,
        "value": 139.357,
        "_internal_originalTime": 1689746330000
    },
    {
        "time": 1689746331000,
        "value": 139.357,
        "_internal_originalTime": 1689746331000
    },
    {
        "time": 1689746332000,
        "value": 139.349,
        "_internal_originalTime": 1689746332000
    },
    {
        "time": 1689746334000,
        "value": 139.353,
        "_internal_originalTime": 1689746334000
    },
    {
        "time": 1689746336000,
        "value": 139.357,
        "_internal_originalTime": 1689746336000
    },
    {
        "time": 1689746338000,
        "value": 139.36,
        "_internal_originalTime": 1689746338000
    },
    {
        "time": 1689746339000,
        "value": 139.355,
        "_internal_originalTime": 1689746339000
    },
    {
        "time": 1689746340000,
        "value": 139.358,
        "_internal_originalTime": 1689746340000
    },
    {
        "time": 1689746341000,
        "value": 139.361,
        "_internal_originalTime": 1689746341000
    },
    {
        "time": 1689746342000,
        "value": 139.36,
        "_internal_originalTime": 1689746342000
    },
    {
        "time": 1689746343000,
        "value": 139.354,
        "_internal_originalTime": 1689746343000
    },
    {
        "time": 1689746345000,
        "value": 139.359,
        "_internal_originalTime": 1689746345000
    },
    {
        "time": 1689746347000,
        "value": 139.359,
        "_internal_originalTime": 1689746347000
    },
    {
        "time": 1689746348000,
        "value": 139.356,
        "_internal_originalTime": 1689746348000
    },
    {
        "time": 1689746349000,
        "value": 139.355,
        "_internal_originalTime": 1689746349000
    },
    {
        "time": 1689746350000,
        "value": 139.359,
        "_internal_originalTime": 1689746350000
    },
    {
        "time": 1689746351000,
        "value": 139.355,
        "_internal_originalTime": 1689746351000
    },
    {
        "time": 1689746353000,
        "value": 139.355,
        "_internal_originalTime": 1689746353000
    },
    {
        "time": 1689746354000,
        "value": 139.36,
        "_internal_originalTime": 1689746354000
    },
    {
        "time": 1689746356000,
        "value": 139.354,
        "_internal_originalTime": 1689746356000
    },
    {
        "time": 1689746357000,
        "value": 139.353,
        "_internal_originalTime": 1689746357000
    },
    {
        "time": 1689746358000,
        "value": 139.357,
        "_internal_originalTime": 1689746358000
    },
    {
        "time": 1689746360000,
        "value": 139.351,
        "_internal_originalTime": 1689746360000
    },
    {
        "time": 1689746361000,
        "value": 139.351,
        "_internal_originalTime": 1689746361000
    },
    {
        "time": 1689746363000,
        "value": 139.349,
        "_internal_originalTime": 1689746363000
    },
    {
        "time": 1689746364000,
        "value": 139.347,
        "_internal_originalTime": 1689746364000
    },
    {
        "time": 1689746365000,
        "value": 139.35,
        "_internal_originalTime": 1689746365000
    },
    {
        "time": 1689746366000,
        "value": 139.353,
        "_internal_originalTime": 1689746366000
    },
    {
        "time": 1689746367000,
        "value": 139.355,
        "_internal_originalTime": 1689746367000
    },
    {
        "time": 1689746369000,
        "value": 139.355,
        "_internal_originalTime": 1689746369000
    },
    {
        "time": 1689746370000,
        "value": 139.353,
        "_internal_originalTime": 1689746370000
    },
    {
        "time": 1689746371000,
        "value": 139.355,
        "_internal_originalTime": 1689746371000
    },
    {
        "time": 1689746372000,
        "value": 139.354,
        "_internal_originalTime": 1689746372000
    },
    {
        "time": 1689746373000,
        "value": 139.349,
        "_internal_originalTime": 1689746373000
    },
    {
        "time": 1689746374000,
        "value": 139.352,
        "_internal_originalTime": 1689746374000
    },
    {
        "time": 1689746375000,
        "value": 139.349,
        "_internal_originalTime": 1689746375000
    },
    {
        "time": 1689746378000,
        "value": 139.349,
        "_internal_originalTime": 1689746378000
    },
    {
        "time": 1689746379000,
        "value": 139.349,
        "_internal_originalTime": 1689746379000
    },
    {
        "time": 1689746380000,
        "value": 139.354,
        "_internal_originalTime": 1689746380000
    },
    {
        "time": 1689746381000,
        "value": 139.349,
        "_internal_originalTime": 1689746381000
    },
    {
        "time": 1689746382000,
        "value": 139.35,
        "_internal_originalTime": 1689746382000
    },
    {
        "time": 1689746383000,
        "value": 139.347,
        "_internal_originalTime": 1689746383000
    },
    {
        "time": 1689746384000,
        "value": 139.345,
        "_internal_originalTime": 1689746384000
    },
    {
        "time": 1689746387000,
        "value": 139.349,
        "_internal_originalTime": 1689746387000
    },
    {
        "time": 1689746388000,
        "value": 139.348,
        "_internal_originalTime": 1689746388000
    },
    {
        "time": 1689746389000,
        "value": 139.347,
        "_internal_originalTime": 1689746389000
    },
    {
        "time": 1689746390000,
        "value": 139.351,
        "_internal_originalTime": 1689746390000
    },
    {
        "time": 1689746391000,
        "value": 139.353,
        "_internal_originalTime": 1689746391000
    },
    {
        "time": 1689746392000,
        "value": 139.356,
        "_internal_originalTime": 1689746392000
    },
    {
        "time": 1689746393000,
        "value": 139.356,
        "_internal_originalTime": 1689746393000
    },
    {
        "time": 1689746394000,
        "value": 139.354,
        "_internal_originalTime": 1689746394000
    },
    {
        "time": 1689746395000,
        "value": 139.355,
        "_internal_originalTime": 1689746395000
    },
    {
        "time": 1689746396000,
        "value": 139.358,
        "_internal_originalTime": 1689746396000
    },
    {
        "time": 1689746398000,
        "value": 139.358,
        "_internal_originalTime": 1689746398000
    },
    {
        "time": 1689746399000,
        "value": 139.359,
        "_internal_originalTime": 1689746399000
    },
    {
        "time": 1689746400000,
        "value": 139.347,
        "_internal_originalTime": 1689746400000
    },
    {
        "time": 1689746401000,
        "value": 139.35,
        "_internal_originalTime": 1689746401000
    },
    {
        "time": 1689746403000,
        "value": 139.336,
        "_internal_originalTime": 1689746403000
    },
    {
        "time": 1689746404000,
        "value": 139.318,
        "_internal_originalTime": 1689746404000
    },
    {
        "time": 1689746405000,
        "value": 139.319,
        "_internal_originalTime": 1689746405000
    },
    {
        "time": 1689746407000,
        "value": 139.29,
        "_internal_originalTime": 1689746407000
    },
    {
        "time": 1689746408000,
        "value": 139.28,
        "_internal_originalTime": 1689746408000
    },
    {
        "time": 1689746409000,
        "value": 139.247,
        "_internal_originalTime": 1689746409000
    },
    {
        "time": 1689746410000,
        "value": 139.259,
        "_internal_originalTime": 1689746410000
    },
    {
        "time": 1689746411000,
        "value": 139.23,
        "_internal_originalTime": 1689746411000
    },
    {
        "time": 1689746412000,
        "value": 139.259,
        "_internal_originalTime": 1689746412000
    },
    {
        "time": 1689746413000,
        "value": 139.27,
        "_internal_originalTime": 1689746413000
    },
    {
        "time": 1689746414000,
        "value": 139.277,
        "_internal_originalTime": 1689746414000
    },
    {
        "time": 1689746415000,
        "value": 139.272,
        "_internal_originalTime": 1689746415000
    },
    {
        "time": 1689746416000,
        "value": 139.285,
        "_internal_originalTime": 1689746416000
    },
    {
        "time": 1689746417000,
        "value": 139.274,
        "_internal_originalTime": 1689746417000
    },
    {
        "time": 1689746418000,
        "value": 139.256,
        "_internal_originalTime": 1689746418000
    },
    {
        "time": 1689746419000,
        "value": 139.285,
        "_internal_originalTime": 1689746419000
    },
    {
        "time": 1689746420000,
        "value": 139.297,
        "_internal_originalTime": 1689746420000
    },
    {
        "time": 1689746421000,
        "value": 139.287,
        "_internal_originalTime": 1689746421000
    },
    {
        "time": 1689746423000,
        "value": 139.282,
        "_internal_originalTime": 1689746423000
    },
    {
        "time": 1689746424000,
        "value": 139.281,
        "_internal_originalTime": 1689746424000
    },
    {
        "time": 1689746425000,
        "value": 139.28,
        "_internal_originalTime": 1689746425000
    },
    {
        "time": 1689746426000,
        "value": 139.276,
        "_internal_originalTime": 1689746426000
    },
    {
        "time": 1689746427000,
        "value": 139.281,
        "_internal_originalTime": 1689746427000
    },
    {
        "time": 1689746428000,
        "value": 139.285,
        "_internal_originalTime": 1689746428000
    },
    {
        "time": 1689746430000,
        "value": 139.272,
        "_internal_originalTime": 1689746430000
    },
    {
        "time": 1689746431000,
        "value": 139.281,
        "_internal_originalTime": 1689746431000
    },
    {
        "time": 1689746432000,
        "value": 139.287,
        "_internal_originalTime": 1689746432000
    },
    {
        "time": 1689746433000,
        "value": 139.279,
        "_internal_originalTime": 1689746433000
    },
    {
        "time": 1689746435000,
        "value": 139.28,
        "_internal_originalTime": 1689746435000
    },
    {
        "time": 1689746436000,
        "value": 139.298,
        "_internal_originalTime": 1689746436000
    },
    {
        "time": 1689746437000,
        "value": 139.301,
        "_internal_originalTime": 1689746437000
    },
    {
        "time": 1689746438000,
        "value": 139.283,
        "_internal_originalTime": 1689746438000
    },
    {
        "time": 1689746440000,
        "value": 139.284,
        "_internal_originalTime": 1689746440000
    },
    {
        "time": 1689746441000,
        "value": 139.292,
        "_internal_originalTime": 1689746441000
    },
    {
        "time": 1689746442000,
        "value": 139.293,
        "_internal_originalTime": 1689746442000
    },
    {
        "time": 1689746443000,
        "value": 139.288,
        "_internal_originalTime": 1689746443000
    },
    {
        "time": 1689746444000,
        "value": 139.285,
        "_internal_originalTime": 1689746444000
    },
    {
        "time": 1689746445000,
        "value": 139.289,
        "_internal_originalTime": 1689746445000
    },
    {
        "time": 1689746447000,
        "value": 139.306,
        "_internal_originalTime": 1689746447000
    },
    {
        "time": 1689746448000,
        "value": 139.307,
        "_internal_originalTime": 1689746448000
    },
    {
        "time": 1689746449000,
        "value": 139.314,
        "_internal_originalTime": 1689746449000
    },
    {
        "time": 1689746450000,
        "value": 139.323,
        "_internal_originalTime": 1689746450000
    },
    {
        "time": 1689746451000,
        "value": 139.326,
        "_internal_originalTime": 1689746451000
    },
    {
        "time": 1689746452000,
        "value": 139.331,
        "_internal_originalTime": 1689746452000
    },
    {
        "time": 1689746453000,
        "value": 139.325,
        "_internal_originalTime": 1689746453000
    },
    {
        "time": 1689746454000,
        "value": 139.315,
        "_internal_originalTime": 1689746454000
    },
    {
        "time": 1689746456000,
        "value": 139.309,
        "_internal_originalTime": 1689746456000
    },
    {
        "time": 1689746457000,
        "value": 139.296,
        "_internal_originalTime": 1689746457000
    },
    {
        "time": 1689746458000,
        "value": 139.298,
        "_internal_originalTime": 1689746458000
    },
    {
        "time": 1689746459000,
        "value": 139.28,
        "_internal_originalTime": 1689746459000
    },
    {
        "time": 1689746460000,
        "value": 139.277,
        "_internal_originalTime": 1689746460000
    },
    {
        "time": 1689746461000,
        "value": 139.28,
        "_internal_originalTime": 1689746461000
    },
    {
        "time": 1689746462000,
        "value": 139.274,
        "_internal_originalTime": 1689746462000
    },
    {
        "time": 1689746463000,
        "value": 139.27,
        "_internal_originalTime": 1689746463000
    },
    {
        "time": 1689746465000,
        "value": 139.277,
        "_internal_originalTime": 1689746465000
    },
    {
        "time": 1689746466000,
        "value": 139.278,
        "_internal_originalTime": 1689746466000
    },
    {
        "time": 1689746467000,
        "value": 139.278,
        "_internal_originalTime": 1689746467000
    },
    {
        "time": 1689746468000,
        "value": 139.277,
        "_internal_originalTime": 1689746468000
    },
    {
        "time": 1689746469000,
        "value": 139.277,
        "_internal_originalTime": 1689746469000
    },
    {
        "time": 1689746470000,
        "value": 139.279,
        "_internal_originalTime": 1689746470000
    },
    {
        "time": 1689746471000,
        "value": 139.279,
        "_internal_originalTime": 1689746471000
    },
    {
        "time": 1689746472000,
        "value": 139.294,
        "_internal_originalTime": 1689746472000
    },
    {
        "time": 1689746474000,
        "value": 139.289,
        "_internal_originalTime": 1689746474000
    },
    {
        "time": 1689746475000,
        "value": 139.287,
        "_internal_originalTime": 1689746475000
    },
    {
        "time": 1689746476000,
        "value": 139.306,
        "_internal_originalTime": 1689746476000
    },
    {
        "time": 1689746478000,
        "value": 139.314,
        "_internal_originalTime": 1689746478000
    },
    {
        "time": 1689746480000,
        "value": 139.313,
        "_internal_originalTime": 1689746480000
    },
    {
        "time": 1689746481000,
        "value": 139.308,
        "_internal_originalTime": 1689746481000
    },
    {
        "time": 1689746482000,
        "value": 139.311,
        "_internal_originalTime": 1689746482000
    },
    {
        "time": 1689746483000,
        "value": 139.324,
        "_internal_originalTime": 1689746483000
    },
    {
        "time": 1689746484000,
        "value": 139.315,
        "_internal_originalTime": 1689746484000
    },
    {
        "time": 1689746485000,
        "value": 139.316,
        "_internal_originalTime": 1689746485000
    },
    {
        "time": 1689746486000,
        "value": 139.314,
        "_internal_originalTime": 1689746486000
    },
    {
        "time": 1689746487000,
        "value": 139.321,
        "_internal_originalTime": 1689746487000
    },
    {
        "time": 1689746489000,
        "value": 139.319,
        "_internal_originalTime": 1689746489000
    },
    {
        "time": 1689746490000,
        "value": 139.315,
        "_internal_originalTime": 1689746490000
    },
    {
        "time": 1689746491000,
        "value": 139.309,
        "_internal_originalTime": 1689746491000
    },
    {
        "time": 1689746492000,
        "value": 139.313,
        "_internal_originalTime": 1689746492000
    },
    {
        "time": 1689746493000,
        "value": 139.312,
        "_internal_originalTime": 1689746493000
    },
    {
        "time": 1689746495000,
        "value": 139.315,
        "_internal_originalTime": 1689746495000
    },
    {
        "time": 1689746496000,
        "value": 139.314,
        "_internal_originalTime": 1689746496000
    },
    {
        "time": 1689746497000,
        "value": 139.312,
        "_internal_originalTime": 1689746497000
    },
    {
        "time": 1689746498000,
        "value": 139.313,
        "_internal_originalTime": 1689746498000
    },
    {
        "time": 1689746500000,
        "value": 139.305,
        "_internal_originalTime": 1689746500000
    },
    {
        "time": 1689746502000,
        "value": 139.3,
        "_internal_originalTime": 1689746502000
    },
    {
        "time": 1689746503000,
        "value": 139.294,
        "_internal_originalTime": 1689746503000
    },
    {
        "time": 1689746505000,
        "value": 139.294,
        "_internal_originalTime": 1689746505000
    },
    {
        "time": 1689746506000,
        "value": 139.297,
        "_internal_originalTime": 1689746506000
    },
    {
        "time": 1689746508000,
        "value": 139.314,
        "_internal_originalTime": 1689746508000
    },
    {
        "time": 1689746509000,
        "value": 139.312,
        "_internal_originalTime": 1689746509000
    },
    {
        "time": 1689746510000,
        "value": 139.305,
        "_internal_originalTime": 1689746510000
    },
    {
        "time": 1689746512000,
        "value": 139.301,
        "_internal_originalTime": 1689746512000
    },
    {
        "time": 1689746513000,
        "value": 139.3,
        "_internal_originalTime": 1689746513000
    },
    {
        "time": 1689746514000,
        "value": 139.298,
        "_internal_originalTime": 1689746514000
    },
    {
        "time": 1689746515000,
        "value": 139.302,
        "_internal_originalTime": 1689746515000
    },
    {
        "time": 1689746516000,
        "value": 139.305,
        "_internal_originalTime": 1689746516000
    },
    {
        "time": 1689746518000,
        "value": 139.31,
        "_internal_originalTime": 1689746518000
    },
    {
        "time": 1689746519000,
        "value": 139.309,
        "_internal_originalTime": 1689746519000
    },
    {
        "time": 1689746520000,
        "value": 139.307,
        "_internal_originalTime": 1689746520000
    },
    {
        "time": 1689746521000,
        "value": 139.307,
        "_internal_originalTime": 1689746521000
    },
    {
        "time": 1689746522000,
        "value": 139.301,
        "_internal_originalTime": 1689746522000
    },
    {
        "time": 1689746523000,
        "value": 139.324,
        "_internal_originalTime": 1689746523000
    },
    {
        "time": 1689746525000,
        "value": 139.335,
        "_internal_originalTime": 1689746525000
    },
    {
        "time": 1689746526000,
        "value": 139.333,
        "_internal_originalTime": 1689746526000
    },
    {
        "time": 1689746527000,
        "value": 139.332,
        "_internal_originalTime": 1689746527000
    },
    {
        "time": 1689746529000,
        "value": 139.334,
        "_internal_originalTime": 1689746529000
    },
    {
        "time": 1689746530000,
        "value": 139.335,
        "_internal_originalTime": 1689746530000
    },
    {
        "time": 1689746531000,
        "value": 139.335,
        "_internal_originalTime": 1689746531000
    },
    {
        "time": 1689746533000,
        "value": 139.334,
        "_internal_originalTime": 1689746533000
    },
    {
        "time": 1689746535000,
        "value": 139.337,
        "_internal_originalTime": 1689746535000
    },
    {
        "time": 1689746537000,
        "value": 139.34,
        "_internal_originalTime": 1689746537000
    },
    {
        "time": 1689746538000,
        "value": 139.336,
        "_internal_originalTime": 1689746538000
    },
    {
        "time": 1689746540000,
        "value": 139.333,
        "_internal_originalTime": 1689746540000
    },
    {
        "time": 1689746541000,
        "value": 139.335,
        "_internal_originalTime": 1689746541000
    },
    {
        "time": 1689746542000,
        "value": 139.334,
        "_internal_originalTime": 1689746542000
    },
    {
        "time": 1689746544000,
        "value": 139.335,
        "_internal_originalTime": 1689746544000
    },
    {
        "time": 1689746545000,
        "value": 139.338,
        "_internal_originalTime": 1689746545000
    },
    {
        "time": 1689746546000,
        "value": 139.337,
        "_internal_originalTime": 1689746546000
    },
    {
        "time": 1689746547000,
        "value": 139.343,
        "_internal_originalTime": 1689746547000
    },
    {
        "time": 1689746548000,
        "value": 139.343,
        "_internal_originalTime": 1689746548000
    },
    {
        "time": 1689746549000,
        "value": 139.338,
        "_internal_originalTime": 1689746549000
    },
    {
        "time": 1689746551000,
        "value": 139.342,
        "_internal_originalTime": 1689746551000
    },
    {
        "time": 1689746552000,
        "value": 139.344,
        "_internal_originalTime": 1689746552000
    },
    {
        "time": 1689746554000,
        "value": 139.342,
        "_internal_originalTime": 1689746554000
    },
    {
        "time": 1689746555000,
        "value": 139.346,
        "_internal_originalTime": 1689746555000
    },
    {
        "time": 1689746556000,
        "value": 139.342,
        "_internal_originalTime": 1689746556000
    },
    {
        "time": 1689746557000,
        "value": 139.343,
        "_internal_originalTime": 1689746557000
    },
    {
        "time": 1689746558000,
        "value": 139.343,
        "_internal_originalTime": 1689746558000
    },
    {
        "time": 1689746560000,
        "value": 139.359,
        "_internal_originalTime": 1689746560000
    },
    {
        "time": 1689746561000,
        "value": 139.352,
        "_internal_originalTime": 1689746561000
    },
    {
        "time": 1689746563000,
        "value": 139.351,
        "_internal_originalTime": 1689746563000
    },
    {
        "time": 1689746564000,
        "value": 139.355,
        "_internal_originalTime": 1689746564000
    },
    {
        "time": 1689746565000,
        "value": 139.352,
        "_internal_originalTime": 1689746565000
    },
    {
        "time": 1689746566000,
        "value": 139.352,
        "_internal_originalTime": 1689746566000
    },
    {
        "time": 1689746567000,
        "value": 139.351,
        "_internal_originalTime": 1689746567000
    },
    {
        "time": 1689746568000,
        "value": 139.35,
        "_internal_originalTime": 1689746568000
    },
    {
        "time": 1689746569000,
        "value": 139.342,
        "_internal_originalTime": 1689746569000
    },
    {
        "time": 1689746571000,
        "value": 139.347,
        "_internal_originalTime": 1689746571000
    },
    {
        "time": 1689746572000,
        "value": 139.343,
        "_internal_originalTime": 1689746572000
    },
    {
        "time": 1689746573000,
        "value": 139.34,
        "_internal_originalTime": 1689746573000
    },
    {
        "time": 1689746575000,
        "value": 139.344,
        "_internal_originalTime": 1689746575000
    },
    {
        "time": 1689746577000,
        "value": 139.341,
        "_internal_originalTime": 1689746577000
    },
    {
        "time": 1689746578000,
        "value": 139.361,
        "_internal_originalTime": 1689746578000
    },
    {
        "time": 1689746579000,
        "value": 139.363,
        "_internal_originalTime": 1689746579000
    },
    {
        "time": 1689746580000,
        "value": 139.364,
        "_internal_originalTime": 1689746580000
    },
    {
        "time": 1689746581000,
        "value": 139.362,
        "_internal_originalTime": 1689746581000
    },
    {
        "time": 1689746582000,
        "value": 139.36,
        "_internal_originalTime": 1689746582000
    },
    {
        "time": 1689746584000,
        "value": 139.36,
        "_internal_originalTime": 1689746584000
    },
    {
        "time": 1689746585000,
        "value": 139.363,
        "_internal_originalTime": 1689746585000
    },
    {
        "time": 1689746586000,
        "value": 139.363,
        "_internal_originalTime": 1689746586000
    },
    {
        "time": 1689746587000,
        "value": 139.364,
        "_internal_originalTime": 1689746587000
    },
    {
        "time": 1689746588000,
        "value": 139.369,
        "_internal_originalTime": 1689746588000
    },
    {
        "time": 1689746589000,
        "value": 139.368,
        "_internal_originalTime": 1689746589000
    },
    {
        "time": 1689746590000,
        "value": 139.372,
        "_internal_originalTime": 1689746590000
    },
    {
        "time": 1689746591000,
        "value": 139.371,
        "_internal_originalTime": 1689746591000
    },
    {
        "time": 1689746593000,
        "value": 139.372,
        "_internal_originalTime": 1689746593000
    },
    {
        "time": 1689746594000,
        "value": 139.372,
        "_internal_originalTime": 1689746594000
    },
    {
        "time": 1689746595000,
        "value": 139.373,
        "_internal_originalTime": 1689746595000
    },
    {
        "time": 1689746596000,
        "value": 139.38,
        "_internal_originalTime": 1689746596000
    },
    {
        "time": 1689746597000,
        "value": 139.369,
        "_internal_originalTime": 1689746597000
    },
    {
        "time": 1689746598000,
        "value": 139.362,
        "_internal_originalTime": 1689746598000
    },
    {
        "time": 1689746599000,
        "value": 139.364,
        "_internal_originalTime": 1689746599000
    },
    {
        "time": 1689746600000,
        "value": 139.363,
        "_internal_originalTime": 1689746600000
    },
    {
        "time": 1689746601000,
        "value": 139.372,
        "_internal_originalTime": 1689746601000
    },
    {
        "time": 1689746602000,
        "value": 139.364,
        "_internal_originalTime": 1689746602000
    },
    {
        "time": 1689746603000,
        "value": 139.365,
        "_internal_originalTime": 1689746603000
    },
    {
        "time": 1689746605000,
        "value": 139.382,
        "_internal_originalTime": 1689746605000
    },
    {
        "time": 1689746607000,
        "value": 139.385,
        "_internal_originalTime": 1689746607000
    },
    {
        "time": 1689746608000,
        "value": 139.38,
        "_internal_originalTime": 1689746608000
    },
    {
        "time": 1689746609000,
        "value": 139.379,
        "_internal_originalTime": 1689746609000
    },
    {
        "time": 1689746610000,
        "value": 139.39,
        "_internal_originalTime": 1689746610000
    },
    {
        "time": 1689746611000,
        "value": 139.391,
        "_internal_originalTime": 1689746611000
    },
    {
        "time": 1689746612000,
        "value": 139.392,
        "_internal_originalTime": 1689746612000
    },
    {
        "time": 1689746613000,
        "value": 139.414,
        "_internal_originalTime": 1689746613000
    },
    {
        "time": 1689746614000,
        "value": 139.421,
        "_internal_originalTime": 1689746614000
    },
    {
        "time": 1689746616000,
        "value": 139.415,
        "_internal_originalTime": 1689746616000
    },
    {
        "time": 1689746617000,
        "value": 139.42,
        "_internal_originalTime": 1689746617000
    },
    {
        "time": 1689746618000,
        "value": 139.417,
        "_internal_originalTime": 1689746618000
    },
    {
        "time": 1689746619000,
        "value": 139.413,
        "_internal_originalTime": 1689746619000
    },
    {
        "time": 1689746620000,
        "value": 139.4,
        "_internal_originalTime": 1689746620000
    },
    {
        "time": 1689746621000,
        "value": 139.4,
        "_internal_originalTime": 1689746621000
    },
    {
        "time": 1689746622000,
        "value": 139.396,
        "_internal_originalTime": 1689746622000
    },
    {
        "time": 1689746623000,
        "value": 139.394,
        "_internal_originalTime": 1689746623000
    },
    {
        "time": 1689746624000,
        "value": 139.393,
        "_internal_originalTime": 1689746624000
    },
    {
        "time": 1689746625000,
        "value": 139.389,
        "_internal_originalTime": 1689746625000
    },
    {
        "time": 1689746626000,
        "value": 139.39,
        "_internal_originalTime": 1689746626000
    },
    {
        "time": 1689746627000,
        "value": 139.391,
        "_internal_originalTime": 1689746627000
    },
    {
        "time": 1689746628000,
        "value": 139.392,
        "_internal_originalTime": 1689746628000
    },
    {
        "time": 1689746629000,
        "value": 139.394,
        "_internal_originalTime": 1689746629000
    },
    {
        "time": 1689746630000,
        "value": 139.393,
        "_internal_originalTime": 1689746630000
    },
    {
        "time": 1689746631000,
        "value": 139.394,
        "_internal_originalTime": 1689746631000
    },
    {
        "time": 1689746632000,
        "value": 139.401,
        "_internal_originalTime": 1689746632000
    },
    {
        "time": 1689746634000,
        "value": 139.396,
        "_internal_originalTime": 1689746634000
    },
    {
        "time": 1689746635000,
        "value": 139.395,
        "_internal_originalTime": 1689746635000
    },
    {
        "time": 1689746636000,
        "value": 139.394,
        "_internal_originalTime": 1689746636000
    },
    {
        "time": 1689746638000,
        "value": 139.394,
        "_internal_originalTime": 1689746638000
    },
    {
        "time": 1689746639000,
        "value": 139.395,
        "_internal_originalTime": 1689746639000
    },
    {
        "time": 1689746640000,
        "value": 139.394,
        "_internal_originalTime": 1689746640000
    },
    {
        "time": 1689746641000,
        "value": 139.397,
        "_internal_originalTime": 1689746641000
    },
    {
        "time": 1689746643000,
        "value": 139.4,
        "_internal_originalTime": 1689746643000
    },
    {
        "time": 1689746644000,
        "value": 139.402,
        "_internal_originalTime": 1689746644000
    },
    {
        "time": 1689746645000,
        "value": 139.404,
        "_internal_originalTime": 1689746645000
    },
    {
        "time": 1689746646000,
        "value": 139.404,
        "_internal_originalTime": 1689746646000
    },
    {
        "time": 1689746647000,
        "value": 139.397,
        "_internal_originalTime": 1689746647000
    },
    {
        "time": 1689746648000,
        "value": 139.4,
        "_internal_originalTime": 1689746648000
    },
    {
        "time": 1689746649000,
        "value": 139.397,
        "_internal_originalTime": 1689746649000
    },
    {
        "time": 1689746651000,
        "value": 139.395,
        "_internal_originalTime": 1689746651000
    },
    {
        "time": 1689746652000,
        "value": 139.396,
        "_internal_originalTime": 1689746652000
    },
    {
        "time": 1689746653000,
        "value": 139.399,
        "_internal_originalTime": 1689746653000
    },
    {
        "time": 1689746655000,
        "value": 139.4,
        "_internal_originalTime": 1689746655000
    },
    {
        "time": 1689746656000,
        "value": 139.4,
        "_internal_originalTime": 1689746656000
    },
    {
        "time": 1689746657000,
        "value": 139.39,
        "_internal_originalTime": 1689746657000
    },
    {
        "time": 1689746658000,
        "value": 139.391,
        "_internal_originalTime": 1689746658000
    },
    {
        "time": 1689746660000,
        "value": 139.395,
        "_internal_originalTime": 1689746660000
    },
    {
        "time": 1689746661000,
        "value": 139.395,
        "_internal_originalTime": 1689746661000
    },
    {
        "time": 1689746662000,
        "value": 139.39,
        "_internal_originalTime": 1689746662000
    },
    {
        "time": 1689746663000,
        "value": 139.397,
        "_internal_originalTime": 1689746663000
    },
    {
        "time": 1689746665000,
        "value": 139.397,
        "_internal_originalTime": 1689746665000
    },
    {
        "time": 1689746667000,
        "value": 139.415,
        "_internal_originalTime": 1689746667000
    },
    {
        "time": 1689746668000,
        "value": 139.409,
        "_internal_originalTime": 1689746668000
    },
    {
        "time": 1689746669000,
        "value": 139.404,
        "_internal_originalTime": 1689746669000
    },
    {
        "time": 1689746670000,
        "value": 139.411,
        "_internal_originalTime": 1689746670000
    },
    {
        "time": 1689746671000,
        "value": 139.41,
        "_internal_originalTime": 1689746671000
    },
    {
        "time": 1689746672000,
        "value": 139.408,
        "_internal_originalTime": 1689746672000
    },
    {
        "time": 1689746674000,
        "value": 139.413,
        "_internal_originalTime": 1689746674000
    },
    {
        "time": 1689746676000,
        "value": 139.41,
        "_internal_originalTime": 1689746676000
    },
    {
        "time": 1689746678000,
        "value": 139.408,
        "_internal_originalTime": 1689746678000
    },
    {
        "time": 1689746680000,
        "value": 139.41,
        "_internal_originalTime": 1689746680000
    },
    {
        "time": 1689746681000,
        "value": 139.417,
        "_internal_originalTime": 1689746681000
    },
    {
        "time": 1689746683000,
        "value": 139.422,
        "_internal_originalTime": 1689746683000
    },
    {
        "time": 1689746684000,
        "value": 139.414,
        "_internal_originalTime": 1689746684000
    },
    {
        "time": 1689746685000,
        "value": 139.423,
        "_internal_originalTime": 1689746685000
    },
    {
        "time": 1689746687000,
        "value": 139.432,
        "_internal_originalTime": 1689746687000
    },
    {
        "time": 1689746688000,
        "value": 139.424,
        "_internal_originalTime": 1689746688000
    },
    {
        "time": 1689746689000,
        "value": 139.421,
        "_internal_originalTime": 1689746689000
    },
    {
        "time": 1689746691000,
        "value": 139.415,
        "_internal_originalTime": 1689746691000
    },
    {
        "time": 1689746693000,
        "value": 139.42,
        "_internal_originalTime": 1689746693000
    },
    {
        "time": 1689746694000,
        "value": 139.417,
        "_internal_originalTime": 1689746694000
    },
    {
        "time": 1689746695000,
        "value": 139.416,
        "_internal_originalTime": 1689746695000
    },
    {
        "time": 1689746697000,
        "value": 139.402,
        "_internal_originalTime": 1689746697000
    },
    {
        "time": 1689746698000,
        "value": 139.406,
        "_internal_originalTime": 1689746698000
    },
    {
        "time": 1689746700000,
        "value": 139.408,
        "_internal_originalTime": 1689746700000
    },
    {
        "time": 1689746701000,
        "value": 139.408,
        "_internal_originalTime": 1689746701000
    },
    {
        "time": 1689746702000,
        "value": 139.407,
        "_internal_originalTime": 1689746702000
    },
    {
        "time": 1689746704000,
        "value": 139.414,
        "_internal_originalTime": 1689746704000
    },
    {
        "time": 1689746706000,
        "value": 139.417,
        "_internal_originalTime": 1689746706000
    },
    {
        "time": 1689746707000,
        "value": 139.417,
        "_internal_originalTime": 1689746707000
    },
    {
        "time": 1689746708000,
        "value": 139.415,
        "_internal_originalTime": 1689746708000
    },
    {
        "time": 1689746709000,
        "value": 139.416,
        "_internal_originalTime": 1689746709000
    },
    {
        "time": 1689746710000,
        "value": 139.421,
        "_internal_originalTime": 1689746710000
    },
    {
        "time": 1689746712000,
        "value": 139.411,
        "_internal_originalTime": 1689746712000
    },
    {
        "time": 1689746713000,
        "value": 139.414,
        "_internal_originalTime": 1689746713000
    },
    {
        "time": 1689746714000,
        "value": 139.418,
        "_internal_originalTime": 1689746714000
    },
    {
        "time": 1689746715000,
        "value": 139.423,
        "_internal_originalTime": 1689746715000
    },
    {
        "time": 1689746716000,
        "value": 139.418,
        "_internal_originalTime": 1689746716000
    },
    {
        "time": 1689746717000,
        "value": 139.416,
        "_internal_originalTime": 1689746717000
    },
    {
        "time": 1689746718000,
        "value": 139.417,
        "_internal_originalTime": 1689746718000
    },
    {
        "time": 1689746719000,
        "value": 139.417,
        "_internal_originalTime": 1689746719000
    },
    {
        "time": 1689746721000,
        "value": 139.418,
        "_internal_originalTime": 1689746721000
    },
    {
        "time": 1689746723000,
        "value": 139.428,
        "_internal_originalTime": 1689746723000
    },
    {
        "time": 1689746724000,
        "value": 139.426,
        "_internal_originalTime": 1689746724000
    },
    {
        "time": 1689746725000,
        "value": 139.43,
        "_internal_originalTime": 1689746725000
    },
    {
        "time": 1689746726000,
        "value": 139.431,
        "_internal_originalTime": 1689746726000
    },
    {
        "time": 1689746728000,
        "value": 139.431,
        "_internal_originalTime": 1689746728000
    },
    {
        "time": 1689746730000,
        "value": 139.43,
        "_internal_originalTime": 1689746730000
    },
    {
        "time": 1689746731000,
        "value": 139.428,
        "_internal_originalTime": 1689746731000
    },
    {
        "time": 1689746732000,
        "value": 139.428,
        "_internal_originalTime": 1689746732000
    },
    {
        "time": 1689746733000,
        "value": 139.431,
        "_internal_originalTime": 1689746733000
    },
    {
        "time": 1689746734000,
        "value": 139.435,
        "_internal_originalTime": 1689746734000
    },
    {
        "time": 1689746735000,
        "value": 139.44,
        "_internal_originalTime": 1689746735000
    },
    {
        "time": 1689746737000,
        "value": 139.44,
        "_internal_originalTime": 1689746737000
    },
    {
        "time": 1689746738000,
        "value": 139.436,
        "_internal_originalTime": 1689746738000
    },
    {
        "time": 1689746739000,
        "value": 139.432,
        "_internal_originalTime": 1689746739000
    },
    {
        "time": 1689746740000,
        "value": 139.43,
        "_internal_originalTime": 1689746740000
    },
    {
        "time": 1689746742000,
        "value": 139.432,
        "_internal_originalTime": 1689746742000
    },
    {
        "time": 1689746743000,
        "value": 139.429,
        "_internal_originalTime": 1689746743000
    },
    {
        "time": 1689746745000,
        "value": 139.419,
        "_internal_originalTime": 1689746745000
    },
    {
        "time": 1689746747000,
        "value": 139.412,
        "_internal_originalTime": 1689746747000
    },
    {
        "time": 1689746748000,
        "value": 139.411,
        "_internal_originalTime": 1689746748000
    },
    {
        "time": 1689746749000,
        "value": 139.407,
        "_internal_originalTime": 1689746749000
    },
    {
        "time": 1689746750000,
        "value": 139.406,
        "_internal_originalTime": 1689746750000
    },
    {
        "time": 1689746752000,
        "value": 139.405,
        "_internal_originalTime": 1689746752000
    },
    {
        "time": 1689746753000,
        "value": 139.405,
        "_internal_originalTime": 1689746753000
    },
    {
        "time": 1689746754000,
        "value": 139.404,
        "_internal_originalTime": 1689746754000
    },
    {
        "time": 1689746755000,
        "value": 139.399,
        "_internal_originalTime": 1689746755000
    },
    {
        "time": 1689746756000,
        "value": 139.4,
        "_internal_originalTime": 1689746756000
    },
    {
        "time": 1689746757000,
        "value": 139.405,
        "_internal_originalTime": 1689746757000
    },
    {
        "time": 1689746759000,
        "value": 139.399,
        "_internal_originalTime": 1689746759000
    },
    {
        "time": 1689746760000,
        "value": 139.4,
        "_internal_originalTime": 1689746760000
    },
    {
        "time": 1689746761000,
        "value": 139.401,
        "_internal_originalTime": 1689746761000
    },
    {
        "time": 1689746762000,
        "value": 139.399,
        "_internal_originalTime": 1689746762000
    },
    {
        "time": 1689746763000,
        "value": 139.405,
        "_internal_originalTime": 1689746763000
    },
    {
        "time": 1689746764000,
        "value": 139.4,
        "_internal_originalTime": 1689746764000
    },
    {
        "time": 1689746765000,
        "value": 139.39,
        "_internal_originalTime": 1689746765000
    },
    {
        "time": 1689746767000,
        "value": 139.401,
        "_internal_originalTime": 1689746767000
    },
    {
        "time": 1689746768000,
        "value": 139.402,
        "_internal_originalTime": 1689746768000
    },
    {
        "time": 1689746770000,
        "value": 139.398,
        "_internal_originalTime": 1689746770000
    },
    {
        "time": 1689746771000,
        "value": 139.404,
        "_internal_originalTime": 1689746771000
    },
    {
        "time": 1689746772000,
        "value": 139.404,
        "_internal_originalTime": 1689746772000
    },
    {
        "time": 1689746773000,
        "value": 139.4,
        "_internal_originalTime": 1689746773000
    },
    {
        "time": 1689746775000,
        "value": 139.411,
        "_internal_originalTime": 1689746775000
    },
    {
        "time": 1689746776000,
        "value": 139.425,
        "_internal_originalTime": 1689746776000
    },
    {
        "time": 1689746778000,
        "value": 139.42,
        "_internal_originalTime": 1689746778000
    },
    {
        "time": 1689746779000,
        "value": 139.419,
        "_internal_originalTime": 1689746779000
    },
    {
        "time": 1689746780000,
        "value": 139.416,
        "_internal_originalTime": 1689746780000
    },
    {
        "time": 1689746781000,
        "value": 139.418,
        "_internal_originalTime": 1689746781000
    },
    {
        "time": 1689746782000,
        "value": 139.418,
        "_internal_originalTime": 1689746782000
    },
    {
        "time": 1689746784000,
        "value": 139.425,
        "_internal_originalTime": 1689746784000
    },
    {
        "time": 1689746786000,
        "value": 139.413,
        "_internal_originalTime": 1689746786000
    },
    {
        "time": 1689746787000,
        "value": 139.409,
        "_internal_originalTime": 1689746787000
    },
    {
        "time": 1689746788000,
        "value": 139.408,
        "_internal_originalTime": 1689746788000
    },
    {
        "time": 1689746789000,
        "value": 139.4,
        "_internal_originalTime": 1689746789000
    },
    {
        "time": 1689746790000,
        "value": 139.401,
        "_internal_originalTime": 1689746790000
    },
    {
        "time": 1689746791000,
        "value": 139.4,
        "_internal_originalTime": 1689746791000
    },
    {
        "time": 1689746792000,
        "value": 139.403,
        "_internal_originalTime": 1689746792000
    },
    {
        "time": 1689746794000,
        "value": 139.402,
        "_internal_originalTime": 1689746794000
    },
    {
        "time": 1689746796000,
        "value": 139.404,
        "_internal_originalTime": 1689746796000
    },
    {
        "time": 1689746797000,
        "value": 139.406,
        "_internal_originalTime": 1689746797000
    },
    {
        "time": 1689746798000,
        "value": 139.406,
        "_internal_originalTime": 1689746798000
    },
    {
        "time": 1689746799000,
        "value": 139.427,
        "_internal_originalTime": 1689746799000
    },
    {
        "time": 1689746800000,
        "value": 139.424,
        "_internal_originalTime": 1689746800000
    },
    {
        "time": 1689746801000,
        "value": 139.43,
        "_internal_originalTime": 1689746801000
    },
    {
        "time": 1689746802000,
        "value": 139.431,
        "_internal_originalTime": 1689746802000
    },
    {
        "time": 1689746804000,
        "value": 139.423,
        "_internal_originalTime": 1689746804000
    },
    {
        "time": 1689746805000,
        "value": 139.426,
        "_internal_originalTime": 1689746805000
    },
    {
        "time": 1689746806000,
        "value": 139.416,
        "_internal_originalTime": 1689746806000
    },
    {
        "time": 1689746807000,
        "value": 139.421,
        "_internal_originalTime": 1689746807000
    },
    {
        "time": 1689746808000,
        "value": 139.411,
        "_internal_originalTime": 1689746808000
    },
    {
        "time": 1689746810000,
        "value": 139.417,
        "_internal_originalTime": 1689746810000
    },
    {
        "time": 1689746811000,
        "value": 139.437,
        "_internal_originalTime": 1689746811000
    },
    {
        "time": 1689746812000,
        "value": 139.441,
        "_internal_originalTime": 1689746812000
    },
    {
        "time": 1689746814000,
        "value": 139.437,
        "_internal_originalTime": 1689746814000
    },
    {
        "time": 1689746815000,
        "value": 139.449,
        "_internal_originalTime": 1689746815000
    },
    {
        "time": 1689746816000,
        "value": 139.456,
        "_internal_originalTime": 1689746816000
    },
    {
        "time": 1689746817000,
        "value": 139.47,
        "_internal_originalTime": 1689746817000
    },
    {
        "time": 1689746818000,
        "value": 139.456,
        "_internal_originalTime": 1689746818000
    },
    {
        "time": 1689746819000,
        "value": 139.459,
        "_internal_originalTime": 1689746819000
    },
    {
        "time": 1689746821000,
        "value": 139.454,
        "_internal_originalTime": 1689746821000
    },
    {
        "time": 1689746822000,
        "value": 139.454,
        "_internal_originalTime": 1689746822000
    },
    {
        "time": 1689746823000,
        "value": 139.455,
        "_internal_originalTime": 1689746823000
    },
    {
        "time": 1689746824000,
        "value": 139.449,
        "_internal_originalTime": 1689746824000
    },
    {
        "time": 1689746825000,
        "value": 139.441,
        "_internal_originalTime": 1689746825000
    },
    {
        "time": 1689746826000,
        "value": 139.434,
        "_internal_originalTime": 1689746826000
    },
    {
        "time": 1689746827000,
        "value": 139.43,
        "_internal_originalTime": 1689746827000
    },
    {
        "time": 1689746828000,
        "value": 139.432,
        "_internal_originalTime": 1689746828000
    },
    {
        "time": 1689746830000,
        "value": 139.429,
        "_internal_originalTime": 1689746830000
    },
    {
        "time": 1689746831000,
        "value": 139.431,
        "_internal_originalTime": 1689746831000
    },
    {
        "time": 1689746832000,
        "value": 139.413,
        "_internal_originalTime": 1689746832000
    },
    {
        "time": 1689746833000,
        "value": 139.412,
        "_internal_originalTime": 1689746833000
    },
    {
        "time": 1689746835000,
        "value": 139.422,
        "_internal_originalTime": 1689746835000
    },
    {
        "time": 1689746836000,
        "value": 139.426,
        "_internal_originalTime": 1689746836000
    },
    {
        "time": 1689746837000,
        "value": 139.412,
        "_internal_originalTime": 1689746837000
    },
    {
        "time": 1689746838000,
        "value": 139.41,
        "_internal_originalTime": 1689746838000
    },
    {
        "time": 1689746839000,
        "value": 139.408,
        "_internal_originalTime": 1689746839000
    },
    {
        "time": 1689746840000,
        "value": 139.412,
        "_internal_originalTime": 1689746840000
    },
    {
        "time": 1689746842000,
        "value": 139.407,
        "_internal_originalTime": 1689746842000
    },
    {
        "time": 1689746843000,
        "value": 139.41,
        "_internal_originalTime": 1689746843000
    },
    {
        "time": 1689746844000,
        "value": 139.414,
        "_internal_originalTime": 1689746844000
    },
    {
        "time": 1689746846000,
        "value": 139.419,
        "_internal_originalTime": 1689746846000
    },
    {
        "time": 1689746848000,
        "value": 139.413,
        "_internal_originalTime": 1689746848000
    },
    {
        "time": 1689746849000,
        "value": 139.413,
        "_internal_originalTime": 1689746849000
    },
    {
        "time": 1689746850000,
        "value": 139.416,
        "_internal_originalTime": 1689746850000
    },
    {
        "time": 1689746851000,
        "value": 139.399,
        "_internal_originalTime": 1689746851000
    },
    {
        "time": 1689746852000,
        "value": 139.398,
        "_internal_originalTime": 1689746852000
    },
    {
        "time": 1689746853000,
        "value": 139.394,
        "_internal_originalTime": 1689746853000
    },
    {
        "time": 1689746854000,
        "value": 139.395,
        "_internal_originalTime": 1689746854000
    },
    {
        "time": 1689746855000,
        "value": 139.4,
        "_internal_originalTime": 1689746855000
    },
    {
        "time": 1689746857000,
        "value": 139.403,
        "_internal_originalTime": 1689746857000
    },
    {
        "time": 1689746858000,
        "value": 139.409,
        "_internal_originalTime": 1689746858000
    },
    {
        "time": 1689746859000,
        "value": 139.393,
        "_internal_originalTime": 1689746859000
    },
    {
        "time": 1689746860000,
        "value": 139.393,
        "_internal_originalTime": 1689746860000
    },
    {
        "time": 1689746862000,
        "value": 139.389,
        "_internal_originalTime": 1689746862000
    },
    {
        "time": 1689746863000,
        "value": 139.392,
        "_internal_originalTime": 1689746863000
    },
    {
        "time": 1689746865000,
        "value": 139.393,
        "_internal_originalTime": 1689746865000
    },
    {
        "time": 1689746866000,
        "value": 139.393,
        "_internal_originalTime": 1689746866000
    },
    {
        "time": 1689746868000,
        "value": 139.399,
        "_internal_originalTime": 1689746868000
    },
    {
        "time": 1689746869000,
        "value": 139.389,
        "_internal_originalTime": 1689746869000
    },
    {
        "time": 1689746870000,
        "value": 139.398,
        "_internal_originalTime": 1689746870000
    },
    {
        "time": 1689746871000,
        "value": 139.395,
        "_internal_originalTime": 1689746871000
    },
    {
        "time": 1689746873000,
        "value": 139.394,
        "_internal_originalTime": 1689746873000
    },
    {
        "time": 1689746874000,
        "value": 139.4,
        "_internal_originalTime": 1689746874000
    },
    {
        "time": 1689746875000,
        "value": 139.392,
        "_internal_originalTime": 1689746875000
    },
    {
        "time": 1689746877000,
        "value": 139.39,
        "_internal_originalTime": 1689746877000
    },
    {
        "time": 1689746878000,
        "value": 139.39,
        "_internal_originalTime": 1689746878000
    },
    {
        "time": 1689746879000,
        "value": 139.394,
        "_internal_originalTime": 1689746879000
    },
    {
        "time": 1689746880000,
        "value": 139.39,
        "_internal_originalTime": 1689746880000
    },
    {
        "time": 1689746881000,
        "value": 139.388,
        "_internal_originalTime": 1689746881000
    },
    {
        "time": 1689746882000,
        "value": 139.391,
        "_internal_originalTime": 1689746882000
    },
    {
        "time": 1689746883000,
        "value": 139.386,
        "_internal_originalTime": 1689746883000
    },
    {
        "time": 1689746884000,
        "value": 139.389,
        "_internal_originalTime": 1689746884000
    },
    {
        "time": 1689746886000,
        "value": 139.39,
        "_internal_originalTime": 1689746886000
    },
    {
        "time": 1689746888000,
        "value": 139.385,
        "_internal_originalTime": 1689746888000
    },
    {
        "time": 1689746889000,
        "value": 139.38,
        "_internal_originalTime": 1689746889000
    },
    {
        "time": 1689746890000,
        "value": 139.385,
        "_internal_originalTime": 1689746890000
    },
    {
        "time": 1689746891000,
        "value": 139.388,
        "_internal_originalTime": 1689746891000
    },
    {
        "time": 1689746893000,
        "value": 139.394,
        "_internal_originalTime": 1689746893000
    },
    {
        "time": 1689746894000,
        "value": 139.394,
        "_internal_originalTime": 1689746894000
    },
    {
        "time": 1689746895000,
        "value": 139.389,
        "_internal_originalTime": 1689746895000
    },
    {
        "time": 1689746896000,
        "value": 139.391,
        "_internal_originalTime": 1689746896000
    },
    {
        "time": 1689746897000,
        "value": 139.392,
        "_internal_originalTime": 1689746897000
    },
    {
        "time": 1689746898000,
        "value": 139.389,
        "_internal_originalTime": 1689746898000
    },
    {
        "time": 1689746899000,
        "value": 139.392,
        "_internal_originalTime": 1689746899000
    },
    {
        "time": 1689746901000,
        "value": 139.383,
        "_internal_originalTime": 1689746901000
    },
    {
        "time": 1689746902000,
        "value": 139.381,
        "_internal_originalTime": 1689746902000
    },
    {
        "time": 1689746903000,
        "value": 139.388,
        "_internal_originalTime": 1689746903000
    },
    {
        "time": 1689746904000,
        "value": 139.388,
        "_internal_originalTime": 1689746904000
    },
    {
        "time": 1689746905000,
        "value": 139.395,
        "_internal_originalTime": 1689746905000
    },
    {
        "time": 1689746906000,
        "value": 139.398,
        "_internal_originalTime": 1689746906000
    },
    {
        "time": 1689746907000,
        "value": 139.401,
        "_internal_originalTime": 1689746907000
    },
    {
        "time": 1689746908000,
        "value": 139.4,
        "_internal_originalTime": 1689746908000
    },
    {
        "time": 1689746909000,
        "value": 139.404,
        "_internal_originalTime": 1689746909000
    },
    {
        "time": 1689746911000,
        "value": 139.403,
        "_internal_originalTime": 1689746911000
    },
    {
        "time": 1689746912000,
        "value": 139.411,
        "_internal_originalTime": 1689746912000
    },
    {
        "time": 1689746913000,
        "value": 139.412,
        "_internal_originalTime": 1689746913000
    },
    {
        "time": 1689746914000,
        "value": 139.413,
        "_internal_originalTime": 1689746914000
    },
    {
        "time": 1689746915000,
        "value": 139.41,
        "_internal_originalTime": 1689746915000
    },
    {
        "time": 1689746917000,
        "value": 139.41,
        "_internal_originalTime": 1689746917000
    },
    {
        "time": 1689746918000,
        "value": 139.402,
        "_internal_originalTime": 1689746918000
    },
    {
        "time": 1689746920000,
        "value": 139.402,
        "_internal_originalTime": 1689746920000
    },
    {
        "time": 1689746921000,
        "value": 139.398,
        "_internal_originalTime": 1689746921000
    },
    {
        "time": 1689746922000,
        "value": 139.403,
        "_internal_originalTime": 1689746922000
    },
    {
        "time": 1689746924000,
        "value": 139.402,
        "_internal_originalTime": 1689746924000
    },
    {
        "time": 1689746925000,
        "value": 139.393,
        "_internal_originalTime": 1689746925000
    },
    {
        "time": 1689746926000,
        "value": 139.39,
        "_internal_originalTime": 1689746926000
    },
    {
        "time": 1689746927000,
        "value": 139.394,
        "_internal_originalTime": 1689746927000
    },
    {
        "time": 1689746929000,
        "value": 139.391,
        "_internal_originalTime": 1689746929000
    },
    {
        "time": 1689746930000,
        "value": 139.39,
        "_internal_originalTime": 1689746930000
    },
    {
        "time": 1689746931000,
        "value": 139.379,
        "_internal_originalTime": 1689746931000
    },
    {
        "time": 1689746932000,
        "value": 139.386,
        "_internal_originalTime": 1689746932000
    },
    {
        "time": 1689746933000,
        "value": 139.381,
        "_internal_originalTime": 1689746933000
    },
    {
        "time": 1689746934000,
        "value": 139.387,
        "_internal_originalTime": 1689746934000
    },
    {
        "time": 1689746936000,
        "value": 139.389,
        "_internal_originalTime": 1689746936000
    },
    {
        "time": 1689746938000,
        "value": 139.384,
        "_internal_originalTime": 1689746938000
    },
    {
        "time": 1689746939000,
        "value": 139.387,
        "_internal_originalTime": 1689746939000
    },
    {
        "time": 1689746940000,
        "value": 139.383,
        "_internal_originalTime": 1689746940000
    },
    {
        "time": 1689746942000,
        "value": 139.384,
        "_internal_originalTime": 1689746942000
    },
    {
        "time": 1689746944000,
        "value": 139.391,
        "_internal_originalTime": 1689746944000
    },
    {
        "time": 1689746945000,
        "value": 139.388,
        "_internal_originalTime": 1689746945000
    },
    {
        "time": 1689746946000,
        "value": 139.392,
        "_internal_originalTime": 1689746946000
    },
    {
        "time": 1689746948000,
        "value": 139.391,
        "_internal_originalTime": 1689746948000
    },
    {
        "time": 1689746949000,
        "value": 139.392,
        "_internal_originalTime": 1689746949000
    },
    {
        "time": 1689746950000,
        "value": 139.378,
        "_internal_originalTime": 1689746950000
    },
    {
        "time": 1689746951000,
        "value": 139.382,
        "_internal_originalTime": 1689746951000
    },
    {
        "time": 1689746952000,
        "value": 139.376,
        "_internal_originalTime": 1689746952000
    },
    {
        "time": 1689746954000,
        "value": 139.378,
        "_internal_originalTime": 1689746954000
    },
    {
        "time": 1689746956000,
        "value": 139.381,
        "_internal_originalTime": 1689746956000
    },
    {
        "time": 1689746957000,
        "value": 139.381,
        "_internal_originalTime": 1689746957000
    },
    {
        "time": 1689746958000,
        "value": 139.376,
        "_internal_originalTime": 1689746958000
    },
    {
        "time": 1689746959000,
        "value": 139.375,
        "_internal_originalTime": 1689746959000
    },
    {
        "time": 1689746960000,
        "value": 139.374,
        "_internal_originalTime": 1689746960000
    },
    {
        "time": 1689746961000,
        "value": 139.375,
        "_internal_originalTime": 1689746961000
    },
    {
        "time": 1689746962000,
        "value": 139.373,
        "_internal_originalTime": 1689746962000
    },
    {
        "time": 1689746964000,
        "value": 139.374,
        "_internal_originalTime": 1689746964000
    },
    {
        "time": 1689746965000,
        "value": 139.374,
        "_internal_originalTime": 1689746965000
    },
    {
        "time": 1689746967000,
        "value": 139.375,
        "_internal_originalTime": 1689746967000
    },
    {
        "time": 1689746968000,
        "value": 139.379,
        "_internal_originalTime": 1689746968000
    },
    {
        "time": 1689746969000,
        "value": 139.378,
        "_internal_originalTime": 1689746969000
    },
    {
        "time": 1689746970000,
        "value": 139.382,
        "_internal_originalTime": 1689746970000
    },
    {
        "time": 1689746971000,
        "value": 139.381,
        "_internal_originalTime": 1689746971000
    },
    {
        "time": 1689746972000,
        "value": 139.382,
        "_internal_originalTime": 1689746972000
    },
    {
        "time": 1689746973000,
        "value": 139.382,
        "_internal_originalTime": 1689746973000
    },
    {
        "time": 1689746974000,
        "value": 139.379,
        "_internal_originalTime": 1689746974000
    },
    {
        "time": 1689746976000,
        "value": 139.378,
        "_internal_originalTime": 1689746976000
    },
    {
        "time": 1689746977000,
        "value": 139.378,
        "_internal_originalTime": 1689746977000
    },
    {
        "time": 1689746978000,
        "value": 139.374,
        "_internal_originalTime": 1689746978000
    },
    {
        "time": 1689746979000,
        "value": 139.381,
        "_internal_originalTime": 1689746979000
    },
    {
        "time": 1689746980000,
        "value": 139.392,
        "_internal_originalTime": 1689746980000
    },
    {
        "time": 1689746981000,
        "value": 139.401,
        "_internal_originalTime": 1689746981000
    },
    {
        "time": 1689746982000,
        "value": 139.391,
        "_internal_originalTime": 1689746982000
    },
    {
        "time": 1689746983000,
        "value": 139.391,
        "_internal_originalTime": 1689746983000
    },
    {
        "time": 1689746984000,
        "value": 139.393,
        "_internal_originalTime": 1689746984000
    },
    {
        "time": 1689746986000,
        "value": 139.391,
        "_internal_originalTime": 1689746986000
    },
    {
        "time": 1689746988000,
        "value": 139.391,
        "_internal_originalTime": 1689746988000
    },
    {
        "time": 1689746989000,
        "value": 139.396,
        "_internal_originalTime": 1689746989000
    },
    {
        "time": 1689746991000,
        "value": 139.386,
        "_internal_originalTime": 1689746991000
    },
    {
        "time": 1689746992000,
        "value": 139.388,
        "_internal_originalTime": 1689746992000
    },
    {
        "time": 1689746993000,
        "value": 139.378,
        "_internal_originalTime": 1689746993000
    },
    {
        "time": 1689746994000,
        "value": 139.375,
        "_internal_originalTime": 1689746994000
    },
    {
        "time": 1689746995000,
        "value": 139.368,
        "_internal_originalTime": 1689746995000
    },
    {
        "time": 1689746997000,
        "value": 139.373,
        "_internal_originalTime": 1689746997000
    },
    {
        "time": 1689746998000,
        "value": 139.368,
        "_internal_originalTime": 1689746998000
    },
    {
        "time": 1689746999000,
        "value": 139.383,
        "_internal_originalTime": 1689746999000
    },
    {
        "time": 1689747000000,
        "value": 139.381,
        "_internal_originalTime": 1689747000000
    },
    {
        "time": 1689747001000,
        "value": 139.386,
        "_internal_originalTime": 1689747001000
    },
    {
        "time": 1689747002000,
        "value": 139.383,
        "_internal_originalTime": 1689747002000
    },
    {
        "time": 1689747003000,
        "value": 139.384,
        "_internal_originalTime": 1689747003000
    },
    {
        "time": 1689747004000,
        "value": 139.388,
        "_internal_originalTime": 1689747004000
    },
    {
        "time": 1689747005000,
        "value": 139.384,
        "_internal_originalTime": 1689747005000
    },
    {
        "time": 1689747006000,
        "value": 139.387,
        "_internal_originalTime": 1689747006000
    },
    {
        "time": 1689747007000,
        "value": 139.389,
        "_internal_originalTime": 1689747007000
    },
    {
        "time": 1689747008000,
        "value": 139.39,
        "_internal_originalTime": 1689747008000
    },
    {
        "time": 1689747009000,
        "value": 139.385,
        "_internal_originalTime": 1689747009000
    },
    {
        "time": 1689747010000,
        "value": 139.386,
        "_internal_originalTime": 1689747010000
    },
    {
        "time": 1689747012000,
        "value": 139.38,
        "_internal_originalTime": 1689747012000
    },
    {
        "time": 1689747013000,
        "value": 139.374,
        "_internal_originalTime": 1689747013000
    },
    {
        "time": 1689747014000,
        "value": 139.371,
        "_internal_originalTime": 1689747014000
    },
    {
        "time": 1689747015000,
        "value": 139.378,
        "_internal_originalTime": 1689747015000
    },
    {
        "time": 1689747016000,
        "value": 139.379,
        "_internal_originalTime": 1689747016000
    },
    {
        "time": 1689747017000,
        "value": 139.378,
        "_internal_originalTime": 1689747017000
    },
    {
        "time": 1689747018000,
        "value": 139.384,
        "_internal_originalTime": 1689747018000
    },
    {
        "time": 1689747019000,
        "value": 139.382,
        "_internal_originalTime": 1689747019000
    },
    {
        "time": 1689747020000,
        "value": 139.378,
        "_internal_originalTime": 1689747020000
    },
    {
        "time": 1689747022000,
        "value": 139.385,
        "_internal_originalTime": 1689747022000
    },
    {
        "time": 1689747024000,
        "value": 139.39,
        "_internal_originalTime": 1689747024000
    },
    {
        "time": 1689747025000,
        "value": 139.39,
        "_internal_originalTime": 1689747025000
    },
    {
        "time": 1689747026000,
        "value": 139.395,
        "_internal_originalTime": 1689747026000
    },
    {
        "time": 1689747027000,
        "value": 139.394,
        "_internal_originalTime": 1689747027000
    },
    {
        "time": 1689747028000,
        "value": 139.396,
        "_internal_originalTime": 1689747028000
    },
    {
        "time": 1689747029000,
        "value": 139.389,
        "_internal_originalTime": 1689747029000
    },
    {
        "time": 1689747030000,
        "value": 139.394,
        "_internal_originalTime": 1689747030000
    },
    {
        "time": 1689747031000,
        "value": 139.394,
        "_internal_originalTime": 1689747031000
    },
    {
        "time": 1689747032000,
        "value": 139.384,
        "_internal_originalTime": 1689747032000
    },
    {
        "time": 1689747034000,
        "value": 139.376,
        "_internal_originalTime": 1689747034000
    },
    {
        "time": 1689747036000,
        "value": 139.379,
        "_internal_originalTime": 1689747036000
    },
    {
        "time": 1689747037000,
        "value": 139.381,
        "_internal_originalTime": 1689747037000
    },
    {
        "time": 1689747038000,
        "value": 139.379,
        "_internal_originalTime": 1689747038000
    },
    {
        "time": 1689747040000,
        "value": 139.384,
        "_internal_originalTime": 1689747040000
    },
    {
        "time": 1689747042000,
        "value": 139.371,
        "_internal_originalTime": 1689747042000
    },
    {
        "time": 1689747044000,
        "value": 139.366,
        "_internal_originalTime": 1689747044000
    },
    {
        "time": 1689747046000,
        "value": 139.366,
        "_internal_originalTime": 1689747046000
    },
    {
        "time": 1689747047000,
        "value": 139.371,
        "_internal_originalTime": 1689747047000
    },
    {
        "time": 1689747048000,
        "value": 139.369,
        "_internal_originalTime": 1689747048000
    },
    {
        "time": 1689747049000,
        "value": 139.369,
        "_internal_originalTime": 1689747049000
    },
    {
        "time": 1689747050000,
        "value": 139.38,
        "_internal_originalTime": 1689747050000
    },
    {
        "time": 1689747051000,
        "value": 139.379,
        "_internal_originalTime": 1689747051000
    },
    {
        "time": 1689747052000,
        "value": 139.387,
        "_internal_originalTime": 1689747052000
    },
    {
        "time": 1689747053000,
        "value": 139.377,
        "_internal_originalTime": 1689747053000
    },
    {
        "time": 1689747054000,
        "value": 139.374,
        "_internal_originalTime": 1689747054000
    },
    {
        "time": 1689747055000,
        "value": 139.374,
        "_internal_originalTime": 1689747055000
    },
    {
        "time": 1689747057000,
        "value": 139.378,
        "_internal_originalTime": 1689747057000
    },
    {
        "time": 1689747058000,
        "value": 139.366,
        "_internal_originalTime": 1689747058000
    },
    {
        "time": 1689747059000,
        "value": 139.356,
        "_internal_originalTime": 1689747059000
    },
    {
        "time": 1689747060000,
        "value": 139.362,
        "_internal_originalTime": 1689747060000
    },
    {
        "time": 1689747061000,
        "value": 139.361,
        "_internal_originalTime": 1689747061000
    },
    {
        "time": 1689747062000,
        "value": 139.361,
        "_internal_originalTime": 1689747062000
    },
    {
        "time": 1689747063000,
        "value": 139.361,
        "_internal_originalTime": 1689747063000
    },
    {
        "time": 1689747064000,
        "value": 139.35,
        "_internal_originalTime": 1689747064000
    },
    {
        "time": 1689747065000,
        "value": 139.354,
        "_internal_originalTime": 1689747065000
    },
    {
        "time": 1689747066000,
        "value": 139.331,
        "_internal_originalTime": 1689747066000
    },
    {
        "time": 1689747067000,
        "value": 139.334,
        "_internal_originalTime": 1689747067000
    },
    {
        "time": 1689747068000,
        "value": 139.326,
        "_internal_originalTime": 1689747068000
    },
    {
        "time": 1689747070000,
        "value": 139.334,
        "_internal_originalTime": 1689747070000
    },
    {
        "time": 1689747071000,
        "value": 139.328,
        "_internal_originalTime": 1689747071000
    },
    {
        "time": 1689747072000,
        "value": 139.332,
        "_internal_originalTime": 1689747072000
    },
    {
        "time": 1689747073000,
        "value": 139.325,
        "_internal_originalTime": 1689747073000
    },
    {
        "time": 1689747074000,
        "value": 139.333,
        "_internal_originalTime": 1689747074000
    },
    {
        "time": 1689747076000,
        "value": 139.346,
        "_internal_originalTime": 1689747076000
    },
    {
        "time": 1689747077000,
        "value": 139.341,
        "_internal_originalTime": 1689747077000
    },
    {
        "time": 1689747078000,
        "value": 139.344,
        "_internal_originalTime": 1689747078000
    },
    {
        "time": 1689747080000,
        "value": 139.344,
        "_internal_originalTime": 1689747080000
    },
    {
        "time": 1689747081000,
        "value": 139.337,
        "_internal_originalTime": 1689747081000
    },
    {
        "time": 1689747082000,
        "value": 139.336,
        "_internal_originalTime": 1689747082000
    },
    {
        "time": 1689747083000,
        "value": 139.342,
        "_internal_originalTime": 1689747083000
    },
    {
        "time": 1689747084000,
        "value": 139.342,
        "_internal_originalTime": 1689747084000
    },
    {
        "time": 1689747086000,
        "value": 139.342,
        "_internal_originalTime": 1689747086000
    },
    {
        "time": 1689747088000,
        "value": 139.347,
        "_internal_originalTime": 1689747088000
    },
    {
        "time": 1689747089000,
        "value": 139.342,
        "_internal_originalTime": 1689747089000
    },
    {
        "time": 1689747090000,
        "value": 139.34,
        "_internal_originalTime": 1689747090000
    },
    {
        "time": 1689747091000,
        "value": 139.337,
        "_internal_originalTime": 1689747091000
    },
    {
        "time": 1689747092000,
        "value": 139.342,
        "_internal_originalTime": 1689747092000
    },
    {
        "time": 1689747093000,
        "value": 139.351,
        "_internal_originalTime": 1689747093000
    },
    {
        "time": 1689747094000,
        "value": 139.352,
        "_internal_originalTime": 1689747094000
    },
    {
        "time": 1689747095000,
        "value": 139.353,
        "_internal_originalTime": 1689747095000
    },
    {
        "time": 1689747096000,
        "value": 139.353,
        "_internal_originalTime": 1689747096000
    },
    {
        "time": 1689747098000,
        "value": 139.353,
        "_internal_originalTime": 1689747098000
    },
    {
        "time": 1689747099000,
        "value": 139.354,
        "_internal_originalTime": 1689747099000
    },
    {
        "time": 1689747100000,
        "value": 139.36,
        "_internal_originalTime": 1689747100000
    },
    {
        "time": 1689747102000,
        "value": 139.354,
        "_internal_originalTime": 1689747102000
    },
    {
        "time": 1689747103000,
        "value": 139.338,
        "_internal_originalTime": 1689747103000
    },
    {
        "time": 1689747105000,
        "value": 139.332,
        "_internal_originalTime": 1689747105000
    },
    {
        "time": 1689747106000,
        "value": 139.338,
        "_internal_originalTime": 1689747106000
    },
    {
        "time": 1689747107000,
        "value": 139.34,
        "_internal_originalTime": 1689747107000
    },
    {
        "time": 1689747109000,
        "value": 139.339,
        "_internal_originalTime": 1689747109000
    },
    {
        "time": 1689747110000,
        "value": 139.349,
        "_internal_originalTime": 1689747110000
    },
    {
        "time": 1689747112000,
        "value": 139.356,
        "_internal_originalTime": 1689747112000
    },
    {
        "time": 1689747114000,
        "value": 139.349,
        "_internal_originalTime": 1689747114000
    },
    {
        "time": 1689747115000,
        "value": 139.346,
        "_internal_originalTime": 1689747115000
    },
    {
        "time": 1689747116000,
        "value": 139.356,
        "_internal_originalTime": 1689747116000
    },
    {
        "time": 1689747117000,
        "value": 139.353,
        "_internal_originalTime": 1689747117000
    },
    {
        "time": 1689747118000,
        "value": 139.351,
        "_internal_originalTime": 1689747118000
    },
    {
        "time": 1689747119000,
        "value": 139.353,
        "_internal_originalTime": 1689747119000
    },
    {
        "time": 1689747120000,
        "value": 139.356,
        "_internal_originalTime": 1689747120000
    },
    {
        "time": 1689747121000,
        "value": 139.351,
        "_internal_originalTime": 1689747121000
    },
    {
        "time": 1689747122000,
        "value": 139.356,
        "_internal_originalTime": 1689747122000
    },
    {
        "time": 1689747123000,
        "value": 139.355,
        "_internal_originalTime": 1689747123000
    },
    {
        "time": 1689747124000,
        "value": 139.361,
        "_internal_originalTime": 1689747124000
    },
    {
        "time": 1689747125000,
        "value": 139.364,
        "_internal_originalTime": 1689747125000
    },
    {
        "time": 1689747126000,
        "value": 139.363,
        "_internal_originalTime": 1689747126000
    },
    {
        "time": 1689747127000,
        "value": 139.368,
        "_internal_originalTime": 1689747127000
    },
    {
        "time": 1689747129000,
        "value": 139.365,
        "_internal_originalTime": 1689747129000
    },
    {
        "time": 1689747130000,
        "value": 139.355,
        "_internal_originalTime": 1689747130000
    },
    {
        "time": 1689747131000,
        "value": 139.35,
        "_internal_originalTime": 1689747131000
    },
    {
        "time": 1689747132000,
        "value": 139.357,
        "_internal_originalTime": 1689747132000
    },
    {
        "time": 1689747133000,
        "value": 139.363,
        "_internal_originalTime": 1689747133000
    },
    {
        "time": 1689747134000,
        "value": 139.361,
        "_internal_originalTime": 1689747134000
    },
    {
        "time": 1689747135000,
        "value": 139.366,
        "_internal_originalTime": 1689747135000
    },
    {
        "time": 1689747136000,
        "value": 139.364,
        "_internal_originalTime": 1689747136000
    },
    {
        "time": 1689747137000,
        "value": 139.36,
        "_internal_originalTime": 1689747137000
    },
    {
        "time": 1689747139000,
        "value": 139.354,
        "_internal_originalTime": 1689747139000
    },
    {
        "time": 1689747140000,
        "value": 139.338,
        "_internal_originalTime": 1689747140000
    },
    {
        "time": 1689747141000,
        "value": 139.339,
        "_internal_originalTime": 1689747141000
    },
    {
        "time": 1689747143000,
        "value": 139.323,
        "_internal_originalTime": 1689747143000
    },
    {
        "time": 1689747144000,
        "value": 139.328,
        "_internal_originalTime": 1689747144000
    },
    {
        "time": 1689747145000,
        "value": 139.332,
        "_internal_originalTime": 1689747145000
    },
    {
        "time": 1689747146000,
        "value": 139.336,
        "_internal_originalTime": 1689747146000
    },
    {
        "time": 1689747148000,
        "value": 139.338,
        "_internal_originalTime": 1689747148000
    },
    {
        "time": 1689747150000,
        "value": 139.333,
        "_internal_originalTime": 1689747150000
    },
    {
        "time": 1689747151000,
        "value": 139.328,
        "_internal_originalTime": 1689747151000
    },
    {
        "time": 1689747152000,
        "value": 139.323,
        "_internal_originalTime": 1689747152000
    },
    {
        "time": 1689747153000,
        "value": 139.322,
        "_internal_originalTime": 1689747153000
    },
    {
        "time": 1689747155000,
        "value": 139.333,
        "_internal_originalTime": 1689747155000
    },
    {
        "time": 1689747156000,
        "value": 139.343,
        "_internal_originalTime": 1689747156000
    },
    {
        "time": 1689747157000,
        "value": 139.345,
        "_internal_originalTime": 1689747157000
    },
    {
        "time": 1689747158000,
        "value": 139.343,
        "_internal_originalTime": 1689747158000
    },
    {
        "time": 1689747160000,
        "value": 139.332,
        "_internal_originalTime": 1689747160000
    },
    {
        "time": 1689747161000,
        "value": 139.335,
        "_internal_originalTime": 1689747161000
    },
    {
        "time": 1689747162000,
        "value": 139.328,
        "_internal_originalTime": 1689747162000
    },
    {
        "time": 1689747164000,
        "value": 139.329,
        "_internal_originalTime": 1689747164000
    },
    {
        "time": 1689747165000,
        "value": 139.331,
        "_internal_originalTime": 1689747165000
    },
    {
        "time": 1689747166000,
        "value": 139.328,
        "_internal_originalTime": 1689747166000
    },
    {
        "time": 1689747168000,
        "value": 139.321,
        "_internal_originalTime": 1689747168000
    },
    {
        "time": 1689747169000,
        "value": 139.321,
        "_internal_originalTime": 1689747169000
    },
    {
        "time": 1689747171000,
        "value": 139.328,
        "_internal_originalTime": 1689747171000
    },
    {
        "time": 1689747172000,
        "value": 139.328,
        "_internal_originalTime": 1689747172000
    },
    {
        "time": 1689747173000,
        "value": 139.333,
        "_internal_originalTime": 1689747173000
    },
    {
        "time": 1689747174000,
        "value": 139.332,
        "_internal_originalTime": 1689747174000
    },
    {
        "time": 1689747175000,
        "value": 139.326,
        "_internal_originalTime": 1689747175000
    },
    {
        "time": 1689747177000,
        "value": 139.33,
        "_internal_originalTime": 1689747177000
    },
    {
        "time": 1689747179000,
        "value": 139.321,
        "_internal_originalTime": 1689747179000
    },
    {
        "time": 1689747180000,
        "value": 139.329,
        "_internal_originalTime": 1689747180000
    },
    {
        "time": 1689747181000,
        "value": 139.332,
        "_internal_originalTime": 1689747181000
    },
    {
        "time": 1689747182000,
        "value": 139.328,
        "_internal_originalTime": 1689747182000
    },
    {
        "time": 1689747183000,
        "value": 139.334,
        "_internal_originalTime": 1689747183000
    },
    {
        "time": 1689747184000,
        "value": 139.332,
        "_internal_originalTime": 1689747184000
    },
    {
        "time": 1689747186000,
        "value": 139.334,
        "_internal_originalTime": 1689747186000
    },
    {
        "time": 1689747187000,
        "value": 139.33,
        "_internal_originalTime": 1689747187000
    },
    {
        "time": 1689747188000,
        "value": 139.335,
        "_internal_originalTime": 1689747188000
    },
    {
        "time": 1689747189000,
        "value": 139.331,
        "_internal_originalTime": 1689747189000
    },
    {
        "time": 1689747190000,
        "value": 139.324,
        "_internal_originalTime": 1689747190000
    },
    {
        "time": 1689747191000,
        "value": 139.331,
        "_internal_originalTime": 1689747191000
    },
    {
        "time": 1689747192000,
        "value": 139.328,
        "_internal_originalTime": 1689747192000
    },
    {
        "time": 1689747194000,
        "value": 139.336,
        "_internal_originalTime": 1689747194000
    },
    {
        "time": 1689747195000,
        "value": 139.332,
        "_internal_originalTime": 1689747195000
    },
    {
        "time": 1689747196000,
        "value": 139.349,
        "_internal_originalTime": 1689747196000
    },
    {
        "time": 1689747198000,
        "value": 139.349,
        "_internal_originalTime": 1689747198000
    },
    {
        "time": 1689747199000,
        "value": 139.349,
        "_internal_originalTime": 1689747199000
    },
    {
        "time": 1689747200000,
        "value": 139.349,
        "_internal_originalTime": 1689747200000
    },
    {
        "time": 1689747201000,
        "value": 139.351,
        "_internal_originalTime": 1689747201000
    },
    {
        "time": 1689747202000,
        "value": 139.348,
        "_internal_originalTime": 1689747202000
    },
    {
        "time": 1689747203000,
        "value": 139.341,
        "_internal_originalTime": 1689747203000
    },
    {
        "time": 1689747204000,
        "value": 139.343,
        "_internal_originalTime": 1689747204000
    },
    {
        "time": 1689747205000,
        "value": 139.34,
        "_internal_originalTime": 1689747205000
    },
    {
        "time": 1689747206000,
        "value": 139.345,
        "_internal_originalTime": 1689747206000
    },
    {
        "time": 1689747207000,
        "value": 139.333,
        "_internal_originalTime": 1689747207000
    },
    {
        "time": 1689747208000,
        "value": 139.335,
        "_internal_originalTime": 1689747208000
    },
    {
        "time": 1689747209000,
        "value": 139.326,
        "_internal_originalTime": 1689747209000
    },
    {
        "time": 1689747210000,
        "value": 139.33,
        "_internal_originalTime": 1689747210000
    },
    {
        "time": 1689747211000,
        "value": 139.328,
        "_internal_originalTime": 1689747211000
    },
    {
        "time": 1689747212000,
        "value": 139.331,
        "_internal_originalTime": 1689747212000
    },
    {
        "time": 1689747214000,
        "value": 139.329,
        "_internal_originalTime": 1689747214000
    },
    {
        "time": 1689747215000,
        "value": 139.328,
        "_internal_originalTime": 1689747215000
    },
    {
        "time": 1689747216000,
        "value": 139.334,
        "_internal_originalTime": 1689747216000
    },
    {
        "time": 1689747217000,
        "value": 139.334,
        "_internal_originalTime": 1689747217000
    },
    {
        "time": 1689747218000,
        "value": 139.34,
        "_internal_originalTime": 1689747218000
    },
    {
        "time": 1689747219000,
        "value": 139.339,
        "_internal_originalTime": 1689747219000
    },
    {
        "time": 1689747220000,
        "value": 139.34,
        "_internal_originalTime": 1689747220000
    },
    {
        "time": 1689747221000,
        "value": 139.341,
        "_internal_originalTime": 1689747221000
    },
    {
        "time": 1689747222000,
        "value": 139.34,
        "_internal_originalTime": 1689747222000
    },
    {
        "time": 1689747224000,
        "value": 139.347,
        "_internal_originalTime": 1689747224000
    },
    {
        "time": 1689747225000,
        "value": 139.343,
        "_internal_originalTime": 1689747225000
    },
    {
        "time": 1689747226000,
        "value": 139.351,
        "_internal_originalTime": 1689747226000
    },
    {
        "time": 1689747227000,
        "value": 139.353,
        "_internal_originalTime": 1689747227000
    },
    {
        "time": 1689747228000,
        "value": 139.352,
        "_internal_originalTime": 1689747228000
    },
    {
        "time": 1689747229000,
        "value": 139.348,
        "_internal_originalTime": 1689747229000
    },
    {
        "time": 1689747230000,
        "value": 139.354,
        "_internal_originalTime": 1689747230000
    },
    {
        "time": 1689747231000,
        "value": 139.351,
        "_internal_originalTime": 1689747231000
    },
    {
        "time": 1689747232000,
        "value": 139.35,
        "_internal_originalTime": 1689747232000
    },
    {
        "time": 1689747234000,
        "value": 139.352,
        "_internal_originalTime": 1689747234000
    },
    {
        "time": 1689747235000,
        "value": 139.355,
        "_internal_originalTime": 1689747235000
    },
    {
        "time": 1689747237000,
        "value": 139.348,
        "_internal_originalTime": 1689747237000
    },
    {
        "time": 1689747238000,
        "value": 139.35,
        "_internal_originalTime": 1689747238000
    },
    {
        "time": 1689747240000,
        "value": 139.353,
        "_internal_originalTime": 1689747240000
    },
    {
        "time": 1689747241000,
        "value": 139.354,
        "_internal_originalTime": 1689747241000
    },
    {
        "time": 1689747242000,
        "value": 139.354,
        "_internal_originalTime": 1689747242000
    },
    {
        "time": 1689747243000,
        "value": 139.355,
        "_internal_originalTime": 1689747243000
    },
    {
        "time": 1689747244000,
        "value": 139.352,
        "_internal_originalTime": 1689747244000
    },
    {
        "time": 1689747246000,
        "value": 139.354,
        "_internal_originalTime": 1689747246000
    },
    {
        "time": 1689747247000,
        "value": 139.353,
        "_internal_originalTime": 1689747247000
    },
    {
        "time": 1689747248000,
        "value": 139.352,
        "_internal_originalTime": 1689747248000
    },
    {
        "time": 1689747249000,
        "value": 139.351,
        "_internal_originalTime": 1689747249000
    },
    {
        "time": 1689747250000,
        "value": 139.353,
        "_internal_originalTime": 1689747250000
    },
    {
        "time": 1689747252000,
        "value": 139.353,
        "_internal_originalTime": 1689747252000
    },
    {
        "time": 1689747253000,
        "value": 139.349,
        "_internal_originalTime": 1689747253000
    },
    {
        "time": 1689747254000,
        "value": 139.354,
        "_internal_originalTime": 1689747254000
    },
    {
        "time": 1689747256000,
        "value": 139.349,
        "_internal_originalTime": 1689747256000
    },
    {
        "time": 1689747257000,
        "value": 139.354,
        "_internal_originalTime": 1689747257000
    },
    {
        "time": 1689747258000,
        "value": 139.354,
        "_internal_originalTime": 1689747258000
    },
    {
        "time": 1689747259000,
        "value": 139.348,
        "_internal_originalTime": 1689747259000
    },
    {
        "time": 1689747260000,
        "value": 139.352,
        "_internal_originalTime": 1689747260000
    },
    {
        "time": 1689747261000,
        "value": 139.356,
        "_internal_originalTime": 1689747261000
    },
    {
        "time": 1689747262000,
        "value": 139.356,
        "_internal_originalTime": 1689747262000
    },
    {
        "time": 1689747263000,
        "value": 139.354,
        "_internal_originalTime": 1689747263000
    },
    {
        "time": 1689747265000,
        "value": 139.356,
        "_internal_originalTime": 1689747265000
    },
    {
        "time": 1689747266000,
        "value": 139.356,
        "_internal_originalTime": 1689747266000
    },
    {
        "time": 1689747267000,
        "value": 139.371,
        "_internal_originalTime": 1689747267000
    },
    {
        "time": 1689747268000,
        "value": 139.369,
        "_internal_originalTime": 1689747268000
    },
    {
        "time": 1689747269000,
        "value": 139.37,
        "_internal_originalTime": 1689747269000
    },
    {
        "time": 1689747271000,
        "value": 139.368,
        "_internal_originalTime": 1689747271000
    },
    {
        "time": 1689747274000,
        "value": 139.382,
        "_internal_originalTime": 1689747274000
    },
    {
        "time": 1689747276000,
        "value": 139.389,
        "_internal_originalTime": 1689747276000
    },
    {
        "time": 1689747277000,
        "value": 139.391,
        "_internal_originalTime": 1689747277000
    },
    {
        "time": 1689747278000,
        "value": 139.392,
        "_internal_originalTime": 1689747278000
    },
    {
        "time": 1689747280000,
        "value": 139.391,
        "_internal_originalTime": 1689747280000
    },
    {
        "time": 1689747282000,
        "value": 139.399,
        "_internal_originalTime": 1689747282000
    },
    {
        "time": 1689747283000,
        "value": 139.4,
        "_internal_originalTime": 1689747283000
    },
    {
        "time": 1689747285000,
        "value": 139.407,
        "_internal_originalTime": 1689747285000
    },
    {
        "time": 1689747286000,
        "value": 139.409,
        "_internal_originalTime": 1689747286000
    },
    {
        "time": 1689747287000,
        "value": 139.399,
        "_internal_originalTime": 1689747287000
    },
    {
        "time": 1689747288000,
        "value": 139.394,
        "_internal_originalTime": 1689747288000
    },
    {
        "time": 1689747289000,
        "value": 139.399,
        "_internal_originalTime": 1689747289000
    },
    {
        "time": 1689747290000,
        "value": 139.394,
        "_internal_originalTime": 1689747290000
    },
    {
        "time": 1689747292000,
        "value": 139.396,
        "_internal_originalTime": 1689747292000
    },
    {
        "time": 1689747295000,
        "value": 139.376,
        "_internal_originalTime": 1689747295000
    },
    {
        "time": 1689747296000,
        "value": 139.377,
        "_internal_originalTime": 1689747296000
    },
    {
        "time": 1689747297000,
        "value": 139.382,
        "_internal_originalTime": 1689747297000
    },
    {
        "time": 1689747298000,
        "value": 139.382,
        "_internal_originalTime": 1689747298000
    },
    {
        "time": 1689747300000,
        "value": 139.383,
        "_internal_originalTime": 1689747300000
    },
    {
        "time": 1689747301000,
        "value": 139.39,
        "_internal_originalTime": 1689747301000
    },
    {
        "time": 1689747302000,
        "value": 139.39,
        "_internal_originalTime": 1689747302000
    },
    {
        "time": 1689747303000,
        "value": 139.387,
        "_internal_originalTime": 1689747303000
    },
    {
        "time": 1689747304000,
        "value": 139.39,
        "_internal_originalTime": 1689747304000
    },
    {
        "time": 1689747305000,
        "value": 139.392,
        "_internal_originalTime": 1689747305000
    },
    {
        "time": 1689747306000,
        "value": 139.395,
        "_internal_originalTime": 1689747306000
    },
    {
        "time": 1689747307000,
        "value": 139.402,
        "_internal_originalTime": 1689747307000
    },
    {
        "time": 1689747308000,
        "value": 139.41,
        "_internal_originalTime": 1689747308000
    },
    {
        "time": 1689747310000,
        "value": 139.412,
        "_internal_originalTime": 1689747310000
    },
    {
        "time": 1689747311000,
        "value": 139.406,
        "_internal_originalTime": 1689747311000
    },
    {
        "time": 1689747312000,
        "value": 139.406,
        "_internal_originalTime": 1689747312000
    },
    {
        "time": 1689747313000,
        "value": 139.406,
        "_internal_originalTime": 1689747313000
    },
    {
        "time": 1689747314000,
        "value": 139.408,
        "_internal_originalTime": 1689747314000
    },
    {
        "time": 1689747315000,
        "value": 139.414,
        "_internal_originalTime": 1689747315000
    },
    {
        "time": 1689747316000,
        "value": 139.409,
        "_internal_originalTime": 1689747316000
    },
    {
        "time": 1689747317000,
        "value": 139.413,
        "_internal_originalTime": 1689747317000
    },
    {
        "time": 1689747318000,
        "value": 139.411,
        "_internal_originalTime": 1689747318000
    },
    {
        "time": 1689747319000,
        "value": 139.418,
        "_internal_originalTime": 1689747319000
    },
    {
        "time": 1689747320000,
        "value": 139.41,
        "_internal_originalTime": 1689747320000
    },
    {
        "time": 1689747321000,
        "value": 139.407,
        "_internal_originalTime": 1689747321000
    },
    {
        "time": 1689747322000,
        "value": 139.41,
        "_internal_originalTime": 1689747322000
    },
    {
        "time": 1689747323000,
        "value": 139.406,
        "_internal_originalTime": 1689747323000
    },
    {
        "time": 1689747325000,
        "value": 139.394,
        "_internal_originalTime": 1689747325000
    },
    {
        "time": 1689747326000,
        "value": 139.401,
        "_internal_originalTime": 1689747326000
    },
    {
        "time": 1689747327000,
        "value": 139.398,
        "_internal_originalTime": 1689747327000
    },
    {
        "time": 1689747328000,
        "value": 139.4,
        "_internal_originalTime": 1689747328000
    },
    {
        "time": 1689747329000,
        "value": 139.401,
        "_internal_originalTime": 1689747329000
    },
    {
        "time": 1689747331000,
        "value": 139.401,
        "_internal_originalTime": 1689747331000
    },
    {
        "time": 1689747332000,
        "value": 139.394,
        "_internal_originalTime": 1689747332000
    },
    {
        "time": 1689747333000,
        "value": 139.401,
        "_internal_originalTime": 1689747333000
    },
    {
        "time": 1689747334000,
        "value": 139.401,
        "_internal_originalTime": 1689747334000
    },
    {
        "time": 1689747335000,
        "value": 139.401,
        "_internal_originalTime": 1689747335000
    },
    {
        "time": 1689747336000,
        "value": 139.408,
        "_internal_originalTime": 1689747336000
    },
    {
        "time": 1689747337000,
        "value": 139.407,
        "_internal_originalTime": 1689747337000
    },
    {
        "time": 1689747339000,
        "value": 139.404,
        "_internal_originalTime": 1689747339000
    },
    {
        "time": 1689747341000,
        "value": 139.404,
        "_internal_originalTime": 1689747341000
    },
    {
        "time": 1689747342000,
        "value": 139.406,
        "_internal_originalTime": 1689747342000
    },
    {
        "time": 1689747344000,
        "value": 139.406,
        "_internal_originalTime": 1689747344000
    },
    {
        "time": 1689747345000,
        "value": 139.404,
        "_internal_originalTime": 1689747345000
    },
    {
        "time": 1689747347000,
        "value": 139.406,
        "_internal_originalTime": 1689747347000
    },
    {
        "time": 1689747348000,
        "value": 139.41,
        "_internal_originalTime": 1689747348000
    },
    {
        "time": 1689747350000,
        "value": 139.4,
        "_internal_originalTime": 1689747350000
    },
    {
        "time": 1689747351000,
        "value": 139.41,
        "_internal_originalTime": 1689747351000
    },
    {
        "time": 1689747352000,
        "value": 139.419,
        "_internal_originalTime": 1689747352000
    },
    {
        "time": 1689747353000,
        "value": 139.412,
        "_internal_originalTime": 1689747353000
    },
    {
        "time": 1689747354000,
        "value": 139.404,
        "_internal_originalTime": 1689747354000
    },
    {
        "time": 1689747355000,
        "value": 139.409,
        "_internal_originalTime": 1689747355000
    },
    {
        "time": 1689747356000,
        "value": 139.409,
        "_internal_originalTime": 1689747356000
    },
    {
        "time": 1689747358000,
        "value": 139.41,
        "_internal_originalTime": 1689747358000
    },
    {
        "time": 1689747360000,
        "value": 139.411,
        "_internal_originalTime": 1689747360000
    },
    {
        "time": 1689747361000,
        "value": 139.42,
        "_internal_originalTime": 1689747361000
    },
    {
        "time": 1689747362000,
        "value": 139.415,
        "_internal_originalTime": 1689747362000
    },
    {
        "time": 1689747365000,
        "value": 139.428,
        "_internal_originalTime": 1689747365000
    },
    {
        "time": 1689747367000,
        "value": 139.428,
        "_internal_originalTime": 1689747367000
    },
    {
        "time": 1689747368000,
        "value": 139.419,
        "_internal_originalTime": 1689747368000
    },
    {
        "time": 1689747370000,
        "value": 139.412,
        "_internal_originalTime": 1689747370000
    },
    {
        "time": 1689747371000,
        "value": 139.406,
        "_internal_originalTime": 1689747371000
    },
    {
        "time": 1689747372000,
        "value": 139.397,
        "_internal_originalTime": 1689747372000
    },
    {
        "time": 1689747373000,
        "value": 139.39,
        "_internal_originalTime": 1689747373000
    },
    {
        "time": 1689747374000,
        "value": 139.395,
        "_internal_originalTime": 1689747374000
    },
    {
        "time": 1689747375000,
        "value": 139.391,
        "_internal_originalTime": 1689747375000
    },
    {
        "time": 1689747376000,
        "value": 139.398,
        "_internal_originalTime": 1689747376000
    },
    {
        "time": 1689747377000,
        "value": 139.39,
        "_internal_originalTime": 1689747377000
    },
    {
        "time": 1689747378000,
        "value": 139.382,
        "_internal_originalTime": 1689747378000
    },
    {
        "time": 1689747379000,
        "value": 139.382,
        "_internal_originalTime": 1689747379000
    },
    {
        "time": 1689747380000,
        "value": 139.39,
        "_internal_originalTime": 1689747380000
    },
    {
        "time": 1689747382000,
        "value": 139.39,
        "_internal_originalTime": 1689747382000
    },
    {
        "time": 1689747384000,
        "value": 139.38,
        "_internal_originalTime": 1689747384000
    },
    {
        "time": 1689747385000,
        "value": 139.389,
        "_internal_originalTime": 1689747385000
    },
    {
        "time": 1689747386000,
        "value": 139.383,
        "_internal_originalTime": 1689747386000
    },
    {
        "time": 1689747387000,
        "value": 139.374,
        "_internal_originalTime": 1689747387000
    },
    {
        "time": 1689747388000,
        "value": 139.38,
        "_internal_originalTime": 1689747388000
    },
    {
        "time": 1689747389000,
        "value": 139.375,
        "_internal_originalTime": 1689747389000
    },
    {
        "time": 1689747390000,
        "value": 139.384,
        "_internal_originalTime": 1689747390000
    },
    {
        "time": 1689747391000,
        "value": 139.39,
        "_internal_originalTime": 1689747391000
    },
    {
        "time": 1689747393000,
        "value": 139.386,
        "_internal_originalTime": 1689747393000
    },
    {
        "time": 1689747394000,
        "value": 139.39,
        "_internal_originalTime": 1689747394000
    },
    {
        "time": 1689747395000,
        "value": 139.39,
        "_internal_originalTime": 1689747395000
    },
    {
        "time": 1689747396000,
        "value": 139.385,
        "_internal_originalTime": 1689747396000
    },
    {
        "time": 1689747397000,
        "value": 139.386,
        "_internal_originalTime": 1689747397000
    },
    {
        "time": 1689747399000,
        "value": 139.385,
        "_internal_originalTime": 1689747399000
    },
    {
        "time": 1689747400000,
        "value": 139.386,
        "_internal_originalTime": 1689747400000
    },
    {
        "time": 1689747401000,
        "value": 139.386,
        "_internal_originalTime": 1689747401000
    },
    {
        "time": 1689747402000,
        "value": 139.406,
        "_internal_originalTime": 1689747402000
    },
    {
        "time": 1689747403000,
        "value": 139.409,
        "_internal_originalTime": 1689747403000
    },
    {
        "time": 1689747404000,
        "value": 139.405,
        "_internal_originalTime": 1689747404000
    },
    {
        "time": 1689747405000,
        "value": 139.404,
        "_internal_originalTime": 1689747405000
    },
    {
        "time": 1689747406000,
        "value": 139.404,
        "_internal_originalTime": 1689747406000
    },
    {
        "time": 1689747407000,
        "value": 139.404,
        "_internal_originalTime": 1689747407000
    },
    {
        "time": 1689747408000,
        "value": 139.401,
        "_internal_originalTime": 1689747408000
    },
    {
        "time": 1689747409000,
        "value": 139.398,
        "_internal_originalTime": 1689747409000
    },
    {
        "time": 1689747410000,
        "value": 139.397,
        "_internal_originalTime": 1689747410000
    },
    {
        "time": 1689747411000,
        "value": 139.399,
        "_internal_originalTime": 1689747411000
    },
    {
        "time": 1689747412000,
        "value": 139.385,
        "_internal_originalTime": 1689747412000
    },
    {
        "time": 1689747413000,
        "value": 139.39,
        "_internal_originalTime": 1689747413000
    },
    {
        "time": 1689747415000,
        "value": 139.384,
        "_internal_originalTime": 1689747415000
    },
    {
        "time": 1689747416000,
        "value": 139.384,
        "_internal_originalTime": 1689747416000
    },
    {
        "time": 1689747417000,
        "value": 139.392,
        "_internal_originalTime": 1689747417000
    },
    {
        "time": 1689747418000,
        "value": 139.394,
        "_internal_originalTime": 1689747418000
    },
    {
        "time": 1689747420000,
        "value": 139.39,
        "_internal_originalTime": 1689747420000
    },
    {
        "time": 1689747421000,
        "value": 139.402,
        "_internal_originalTime": 1689747421000
    },
    {
        "time": 1689747422000,
        "value": 139.407,
        "_internal_originalTime": 1689747422000
    },
    {
        "time": 1689747424000,
        "value": 139.409,
        "_internal_originalTime": 1689747424000
    },
    {
        "time": 1689747425000,
        "value": 139.401,
        "_internal_originalTime": 1689747425000
    },
    {
        "time": 1689747427000,
        "value": 139.402,
        "_internal_originalTime": 1689747427000
    },
    {
        "time": 1689747428000,
        "value": 139.405,
        "_internal_originalTime": 1689747428000
    },
    {
        "time": 1689747430000,
        "value": 139.406,
        "_internal_originalTime": 1689747430000
    },
    {
        "time": 1689747431000,
        "value": 139.408,
        "_internal_originalTime": 1689747431000
    },
    {
        "time": 1689747432000,
        "value": 139.401,
        "_internal_originalTime": 1689747432000
    },
    {
        "time": 1689747433000,
        "value": 139.397,
        "_internal_originalTime": 1689747433000
    },
    {
        "time": 1689747434000,
        "value": 139.399,
        "_internal_originalTime": 1689747434000
    },
    {
        "time": 1689747435000,
        "value": 139.398,
        "_internal_originalTime": 1689747435000
    },
    {
        "time": 1689747437000,
        "value": 139.395,
        "_internal_originalTime": 1689747437000
    },
    {
        "time": 1689747438000,
        "value": 139.392,
        "_internal_originalTime": 1689747438000
    },
    {
        "time": 1689747439000,
        "value": 139.399,
        "_internal_originalTime": 1689747439000
    },
    {
        "time": 1689747440000,
        "value": 139.397,
        "_internal_originalTime": 1689747440000
    },
    {
        "time": 1689747441000,
        "value": 139.408,
        "_internal_originalTime": 1689747441000
    },
    {
        "time": 1689747442000,
        "value": 139.407,
        "_internal_originalTime": 1689747442000
    },
    {
        "time": 1689747445000,
        "value": 139.416,
        "_internal_originalTime": 1689747445000
    },
    {
        "time": 1689747446000,
        "value": 139.421,
        "_internal_originalTime": 1689747446000
    },
    {
        "time": 1689747447000,
        "value": 139.414,
        "_internal_originalTime": 1689747447000
    },
    {
        "time": 1689747449000,
        "value": 139.399,
        "_internal_originalTime": 1689747449000
    },
    {
        "time": 1689747450000,
        "value": 139.391,
        "_internal_originalTime": 1689747450000
    },
    {
        "time": 1689747451000,
        "value": 139.395,
        "_internal_originalTime": 1689747451000
    },
    {
        "time": 1689747452000,
        "value": 139.399,
        "_internal_originalTime": 1689747452000
    },
    {
        "time": 1689747455000,
        "value": 139.398,
        "_internal_originalTime": 1689747455000
    },
    {
        "time": 1689747456000,
        "value": 139.384,
        "_internal_originalTime": 1689747456000
    },
    {
        "time": 1689747457000,
        "value": 139.384,
        "_internal_originalTime": 1689747457000
    },
    {
        "time": 1689747458000,
        "value": 139.386,
        "_internal_originalTime": 1689747458000
    },
    {
        "time": 1689747460000,
        "value": 139.389,
        "_internal_originalTime": 1689747460000
    },
    {
        "time": 1689747461000,
        "value": 139.39,
        "_internal_originalTime": 1689747461000
    },
    {
        "time": 1689747462000,
        "value": 139.382,
        "_internal_originalTime": 1689747462000
    },
    {
        "time": 1689747463000,
        "value": 139.389,
        "_internal_originalTime": 1689747463000
    },
    {
        "time": 1689747464000,
        "value": 139.379,
        "_internal_originalTime": 1689747464000
    },
    {
        "time": 1689747465000,
        "value": 139.384,
        "_internal_originalTime": 1689747465000
    },
    {
        "time": 1689747466000,
        "value": 139.384,
        "_internal_originalTime": 1689747466000
    },
    {
        "time": 1689747467000,
        "value": 139.379,
        "_internal_originalTime": 1689747467000
    },
    {
        "time": 1689747468000,
        "value": 139.384,
        "_internal_originalTime": 1689747468000
    },
    {
        "time": 1689747469000,
        "value": 139.387,
        "_internal_originalTime": 1689747469000
    },
    {
        "time": 1689747470000,
        "value": 139.381,
        "_internal_originalTime": 1689747470000
    },
    {
        "time": 1689747472000,
        "value": 139.38,
        "_internal_originalTime": 1689747472000
    },
    {
        "time": 1689747473000,
        "value": 139.383,
        "_internal_originalTime": 1689747473000
    },
    {
        "time": 1689747474000,
        "value": 139.378,
        "_internal_originalTime": 1689747474000
    },
    {
        "time": 1689747475000,
        "value": 139.383,
        "_internal_originalTime": 1689747475000
    },
    {
        "time": 1689747476000,
        "value": 139.383,
        "_internal_originalTime": 1689747476000
    },
    {
        "time": 1689747479000,
        "value": 139.384,
        "_internal_originalTime": 1689747479000
    },
    {
        "time": 1689747480000,
        "value": 139.38,
        "_internal_originalTime": 1689747480000
    },
    {
        "time": 1689747481000,
        "value": 139.388,
        "_internal_originalTime": 1689747481000
    },
    {
        "time": 1689747482000,
        "value": 139.393,
        "_internal_originalTime": 1689747482000
    },
    {
        "time": 1689747483000,
        "value": 139.399,
        "_internal_originalTime": 1689747483000
    },
    {
        "time": 1689747485000,
        "value": 139.401,
        "_internal_originalTime": 1689747485000
    },
    {
        "time": 1689747486000,
        "value": 139.405,
        "_internal_originalTime": 1689747486000
    },
    {
        "time": 1689747487000,
        "value": 139.412,
        "_internal_originalTime": 1689747487000
    },
    {
        "time": 1689747488000,
        "value": 139.406,
        "_internal_originalTime": 1689747488000
    },
    {
        "time": 1689747489000,
        "value": 139.412,
        "_internal_originalTime": 1689747489000
    },
    {
        "time": 1689747490000,
        "value": 139.413,
        "_internal_originalTime": 1689747490000
    },
    {
        "time": 1689747491000,
        "value": 139.406,
        "_internal_originalTime": 1689747491000
    },
    {
        "time": 1689747492000,
        "value": 139.405,
        "_internal_originalTime": 1689747492000
    },
    {
        "time": 1689747493000,
        "value": 139.412,
        "_internal_originalTime": 1689747493000
    },
    {
        "time": 1689747495000,
        "value": 139.41,
        "_internal_originalTime": 1689747495000
    },
    {
        "time": 1689747496000,
        "value": 139.411,
        "_internal_originalTime": 1689747496000
    },
    {
        "time": 1689747497000,
        "value": 139.407,
        "_internal_originalTime": 1689747497000
    },
    {
        "time": 1689747498000,
        "value": 139.414,
        "_internal_originalTime": 1689747498000
    },
    {
        "time": 1689747499000,
        "value": 139.414,
        "_internal_originalTime": 1689747499000
    },
    {
        "time": 1689747500000,
        "value": 139.415,
        "_internal_originalTime": 1689747500000
    },
    {
        "time": 1689747502000,
        "value": 139.411,
        "_internal_originalTime": 1689747502000
    },
    {
        "time": 1689747503000,
        "value": 139.412,
        "_internal_originalTime": 1689747503000
    },
    {
        "time": 1689747504000,
        "value": 139.424,
        "_internal_originalTime": 1689747504000
    },
    {
        "time": 1689747505000,
        "value": 139.427,
        "_internal_originalTime": 1689747505000
    },
    {
        "time": 1689747506000,
        "value": 139.42,
        "_internal_originalTime": 1689747506000
    },
    {
        "time": 1689747508000,
        "value": 139.426,
        "_internal_originalTime": 1689747508000
    },
    {
        "time": 1689747509000,
        "value": 139.427,
        "_internal_originalTime": 1689747509000
    },
    {
        "time": 1689747510000,
        "value": 139.436,
        "_internal_originalTime": 1689747510000
    },
    {
        "time": 1689747512000,
        "value": 139.43,
        "_internal_originalTime": 1689747512000
    },
    {
        "time": 1689747513000,
        "value": 139.428,
        "_internal_originalTime": 1689747513000
    },
    {
        "time": 1689747515000,
        "value": 139.438,
        "_internal_originalTime": 1689747515000
    },
    {
        "time": 1689747516000,
        "value": 139.438,
        "_internal_originalTime": 1689747516000
    },
    {
        "time": 1689747517000,
        "value": 139.438,
        "_internal_originalTime": 1689747517000
    },
    {
        "time": 1689747518000,
        "value": 139.435,
        "_internal_originalTime": 1689747518000
    },
    {
        "time": 1689747520000,
        "value": 139.445,
        "_internal_originalTime": 1689747520000
    },
    {
        "time": 1689747522000,
        "value": 139.434,
        "_internal_originalTime": 1689747522000
    },
    {
        "time": 1689747523000,
        "value": 139.446,
        "_internal_originalTime": 1689747523000
    },
    {
        "time": 1689747524000,
        "value": 139.44,
        "_internal_originalTime": 1689747524000
    },
    {
        "time": 1689747525000,
        "value": 139.447,
        "_internal_originalTime": 1689747525000
    },
    {
        "time": 1689747526000,
        "value": 139.44,
        "_internal_originalTime": 1689747526000
    },
    {
        "time": 1689747528000,
        "value": 139.445,
        "_internal_originalTime": 1689747528000
    },
    {
        "time": 1689747529000,
        "value": 139.449,
        "_internal_originalTime": 1689747529000
    },
    {
        "time": 1689747531000,
        "value": 139.444,
        "_internal_originalTime": 1689747531000
    },
    {
        "time": 1689747533000,
        "value": 139.444,
        "_internal_originalTime": 1689747533000
    },
    {
        "time": 1689747534000,
        "value": 139.447,
        "_internal_originalTime": 1689747534000
    },
    {
        "time": 1689747535000,
        "value": 139.444,
        "_internal_originalTime": 1689747535000
    },
    {
        "time": 1689747536000,
        "value": 139.448,
        "_internal_originalTime": 1689747536000
    },
    {
        "time": 1689747537000,
        "value": 139.448,
        "_internal_originalTime": 1689747537000
    },
    {
        "time": 1689747538000,
        "value": 139.455,
        "_internal_originalTime": 1689747538000
    },
    {
        "time": 1689747540000,
        "value": 139.451,
        "_internal_originalTime": 1689747540000
    },
    {
        "time": 1689747541000,
        "value": 139.448,
        "_internal_originalTime": 1689747541000
    },
    {
        "time": 1689747542000,
        "value": 139.445,
        "_internal_originalTime": 1689747542000
    },
    {
        "time": 1689747543000,
        "value": 139.448,
        "_internal_originalTime": 1689747543000
    },
    {
        "time": 1689747544000,
        "value": 139.449,
        "_internal_originalTime": 1689747544000
    },
    {
        "time": 1689747545000,
        "value": 139.448,
        "_internal_originalTime": 1689747545000
    },
    {
        "time": 1689747546000,
        "value": 139.446,
        "_internal_originalTime": 1689747546000
    },
    {
        "time": 1689747547000,
        "value": 139.448,
        "_internal_originalTime": 1689747547000
    },
    {
        "time": 1689747548000,
        "value": 139.445,
        "_internal_originalTime": 1689747548000
    },
    {
        "time": 1689747549000,
        "value": 139.445,
        "_internal_originalTime": 1689747549000
    },
    {
        "time": 1689747550000,
        "value": 139.439,
        "_internal_originalTime": 1689747550000
    },
    {
        "time": 1689747551000,
        "value": 139.44,
        "_internal_originalTime": 1689747551000
    },
    {
        "time": 1689747552000,
        "value": 139.443,
        "_internal_originalTime": 1689747552000
    },
    {
        "time": 1689747554000,
        "value": 139.445,
        "_internal_originalTime": 1689747554000
    },
    {
        "time": 1689747555000,
        "value": 139.444,
        "_internal_originalTime": 1689747555000
    },
    {
        "time": 1689747556000,
        "value": 139.45,
        "_internal_originalTime": 1689747556000
    },
    {
        "time": 1689747557000,
        "value": 139.439,
        "_internal_originalTime": 1689747557000
    },
    {
        "time": 1689747558000,
        "value": 139.447,
        "_internal_originalTime": 1689747558000
    },
    {
        "time": 1689747560000,
        "value": 139.442,
        "_internal_originalTime": 1689747560000
    },
    {
        "time": 1689747563000,
        "value": 139.447,
        "_internal_originalTime": 1689747563000
    },
    {
        "time": 1689747564000,
        "value": 139.444,
        "_internal_originalTime": 1689747564000
    },
    {
        "time": 1689747565000,
        "value": 139.446,
        "_internal_originalTime": 1689747565000
    },
    {
        "time": 1689747566000,
        "value": 139.44,
        "_internal_originalTime": 1689747566000
    },
    {
        "time": 1689747567000,
        "value": 139.446,
        "_internal_originalTime": 1689747567000
    },
    {
        "time": 1689747568000,
        "value": 139.447,
        "_internal_originalTime": 1689747568000
    },
    {
        "time": 1689747569000,
        "value": 139.434,
        "_internal_originalTime": 1689747569000
    },
    {
        "time": 1689747570000,
        "value": 139.44,
        "_internal_originalTime": 1689747570000
    },
    {
        "time": 1689747571000,
        "value": 139.44,
        "_internal_originalTime": 1689747571000
    },
    {
        "time": 1689747572000,
        "value": 139.443,
        "_internal_originalTime": 1689747572000
    },
    {
        "time": 1689747573000,
        "value": 139.437,
        "_internal_originalTime": 1689747573000
    },
    {
        "time": 1689747574000,
        "value": 139.43,
        "_internal_originalTime": 1689747574000
    },
    {
        "time": 1689747575000,
        "value": 139.437,
        "_internal_originalTime": 1689747575000
    },
    {
        "time": 1689747577000,
        "value": 139.433,
        "_internal_originalTime": 1689747577000
    },
    {
        "time": 1689747578000,
        "value": 139.433,
        "_internal_originalTime": 1689747578000
    },
    {
        "time": 1689747580000,
        "value": 139.435,
        "_internal_originalTime": 1689747580000
    },
    {
        "time": 1689747581000,
        "value": 139.432,
        "_internal_originalTime": 1689747581000
    },
    {
        "time": 1689747583000,
        "value": 139.437,
        "_internal_originalTime": 1689747583000
    },
    {
        "time": 1689747585000,
        "value": 139.444,
        "_internal_originalTime": 1689747585000
    },
    {
        "time": 1689747587000,
        "value": 139.442,
        "_internal_originalTime": 1689747587000
    },
    {
        "time": 1689747588000,
        "value": 139.436,
        "_internal_originalTime": 1689747588000
    },
    {
        "time": 1689747589000,
        "value": 139.438,
        "_internal_originalTime": 1689747589000
    },
    {
        "time": 1689747590000,
        "value": 139.439,
        "_internal_originalTime": 1689747590000
    },
    {
        "time": 1689747591000,
        "value": 139.436,
        "_internal_originalTime": 1689747591000
    },
    {
        "time": 1689747592000,
        "value": 139.432,
        "_internal_originalTime": 1689747592000
    },
    {
        "time": 1689747593000,
        "value": 139.431,
        "_internal_originalTime": 1689747593000
    },
    {
        "time": 1689747595000,
        "value": 139.435,
        "_internal_originalTime": 1689747595000
    },
    {
        "time": 1689747597000,
        "value": 139.437,
        "_internal_originalTime": 1689747597000
    },
    {
        "time": 1689747598000,
        "value": 139.445,
        "_internal_originalTime": 1689747598000
    },
    {
        "time": 1689747599000,
        "value": 139.436,
        "_internal_originalTime": 1689747599000
    },
    {
        "time": 1689747600000,
        "value": 139.44,
        "_internal_originalTime": 1689747600000
    },
    {
        "time": 1689747601000,
        "value": 139.442,
        "_internal_originalTime": 1689747601000
    },
    {
        "time": 1689747602000,
        "value": 139.445,
        "_internal_originalTime": 1689747602000
    },
    {
        "time": 1689747603000,
        "value": 139.451,
        "_internal_originalTime": 1689747603000
    },
    {
        "time": 1689747605000,
        "value": 139.442,
        "_internal_originalTime": 1689747605000
    },
    {
        "time": 1689747606000,
        "value": 139.443,
        "_internal_originalTime": 1689747606000
    },
    {
        "time": 1689747607000,
        "value": 139.444,
        "_internal_originalTime": 1689747607000
    },
    {
        "time": 1689747608000,
        "value": 139.449,
        "_internal_originalTime": 1689747608000
    },
    {
        "time": 1689747609000,
        "value": 139.444,
        "_internal_originalTime": 1689747609000
    },
    {
        "time": 1689747611000,
        "value": 139.451,
        "_internal_originalTime": 1689747611000
    },
    {
        "time": 1689747612000,
        "value": 139.455,
        "_internal_originalTime": 1689747612000
    },
    {
        "time": 1689747613000,
        "value": 139.453,
        "_internal_originalTime": 1689747613000
    },
    {
        "time": 1689747615000,
        "value": 139.452,
        "_internal_originalTime": 1689747615000
    },
    {
        "time": 1689747616000,
        "value": 139.455,
        "_internal_originalTime": 1689747616000
    },
    {
        "time": 1689747617000,
        "value": 139.454,
        "_internal_originalTime": 1689747617000
    },
    {
        "time": 1689747619000,
        "value": 139.443,
        "_internal_originalTime": 1689747619000
    },
    {
        "time": 1689747620000,
        "value": 139.446,
        "_internal_originalTime": 1689747620000
    },
    {
        "time": 1689747621000,
        "value": 139.441,
        "_internal_originalTime": 1689747621000
    },
    {
        "time": 1689747622000,
        "value": 139.445,
        "_internal_originalTime": 1689747622000
    },
    {
        "time": 1689747623000,
        "value": 139.446,
        "_internal_originalTime": 1689747623000
    },
    {
        "time": 1689747624000,
        "value": 139.444,
        "_internal_originalTime": 1689747624000
    },
    {
        "time": 1689747625000,
        "value": 139.44,
        "_internal_originalTime": 1689747625000
    },
    {
        "time": 1689747626000,
        "value": 139.443,
        "_internal_originalTime": 1689747626000
    },
    {
        "time": 1689747628000,
        "value": 139.441,
        "_internal_originalTime": 1689747628000
    },
    {
        "time": 1689747630000,
        "value": 139.442,
        "_internal_originalTime": 1689747630000
    },
    {
        "time": 1689747631000,
        "value": 139.449,
        "_internal_originalTime": 1689747631000
    },
    {
        "time": 1689747632000,
        "value": 139.448,
        "_internal_originalTime": 1689747632000
    },
    {
        "time": 1689747633000,
        "value": 139.446,
        "_internal_originalTime": 1689747633000
    },
    {
        "time": 1689747634000,
        "value": 139.439,
        "_internal_originalTime": 1689747634000
    },
    {
        "time": 1689747635000,
        "value": 139.439,
        "_internal_originalTime": 1689747635000
    },
    {
        "time": 1689747636000,
        "value": 139.434,
        "_internal_originalTime": 1689747636000
    },
    {
        "time": 1689747637000,
        "value": 139.443,
        "_internal_originalTime": 1689747637000
    },
    {
        "time": 1689747638000,
        "value": 139.441,
        "_internal_originalTime": 1689747638000
    },
    {
        "time": 1689747640000,
        "value": 139.441,
        "_internal_originalTime": 1689747640000
    },
    {
        "time": 1689747641000,
        "value": 139.445,
        "_internal_originalTime": 1689747641000
    },
    {
        "time": 1689747643000,
        "value": 139.434,
        "_internal_originalTime": 1689747643000
    },
    {
        "time": 1689747644000,
        "value": 139.433,
        "_internal_originalTime": 1689747644000
    },
    {
        "time": 1689747645000,
        "value": 139.438,
        "_internal_originalTime": 1689747645000
    },
    {
        "time": 1689747646000,
        "value": 139.434,
        "_internal_originalTime": 1689747646000
    },
    {
        "time": 1689747678000,
        "value": 139.452,
        "_internal_originalTime": 1689747678000
    },
    {
        "time": 1689747679000,
        "value": 139.45,
        "_internal_originalTime": 1689747679000
    },
    {
        "time": 1689747681000,
        "value": 139.455,
        "_internal_originalTime": 1689747681000
    },
    {
        "time": 1689747683000,
        "value": 139.448,
        "_internal_originalTime": 1689747683000
    },
    {
        "time": 1689747685000,
        "value": 139.445,
        "_internal_originalTime": 1689747685000
    },
    {
        "time": 1689747686000,
        "value": 139.445,
        "_internal_originalTime": 1689747686000
    },
    {
        "time": 1689747687000,
        "value": 139.447,
        "_internal_originalTime": 1689747687000
    },
    {
        "time": 1689747688000,
        "value": 139.447,
        "_internal_originalTime": 1689747688000
    },
    {
        "time": 1689747689000,
        "value": 139.444,
        "_internal_originalTime": 1689747689000
    },
    {
        "time": 1689747690000,
        "value": 139.446,
        "_internal_originalTime": 1689747690000
    },
    {
        "time": 1689747691000,
        "value": 139.443,
        "_internal_originalTime": 1689747691000
    },
    {
        "time": 1689747692000,
        "value": 139.448,
        "_internal_originalTime": 1689747692000
    },
    {
        "time": 1689747693000,
        "value": 139.453,
        "_internal_originalTime": 1689747693000
    },
    {
        "time": 1689747695000,
        "value": 139.444,
        "_internal_originalTime": 1689747695000
    },
    {
        "time": 1689747697000,
        "value": 139.446,
        "_internal_originalTime": 1689747697000
    },
    {
        "time": 1689747698000,
        "value": 139.448,
        "_internal_originalTime": 1689747698000
    },
    {
        "time": 1689747699000,
        "value": 139.447,
        "_internal_originalTime": 1689747699000
    },
    {
        "time": 1689747700000,
        "value": 139.446,
        "_internal_originalTime": 1689747700000
    },
    {
        "time": 1689747702000,
        "value": 139.442,
        "_internal_originalTime": 1689747702000
    },
    {
        "time": 1689747704000,
        "value": 139.443,
        "_internal_originalTime": 1689747704000
    },
    {
        "time": 1689747705000,
        "value": 139.441,
        "_internal_originalTime": 1689747705000
    },
    {
        "time": 1689747706000,
        "value": 139.446,
        "_internal_originalTime": 1689747706000
    },
    {
        "time": 1689747707000,
        "value": 139.446,
        "_internal_originalTime": 1689747707000
    },
    {
        "time": 1689747708000,
        "value": 139.446,
        "_internal_originalTime": 1689747708000
    },
    {
        "time": 1689747709000,
        "value": 139.448,
        "_internal_originalTime": 1689747709000
    },
    {
        "time": 1689747710000,
        "value": 139.446,
        "_internal_originalTime": 1689747710000
    },
    {
        "time": 1689747712000,
        "value": 139.452,
        "_internal_originalTime": 1689747712000
    },
    {
        "time": 1689747713000,
        "value": 139.455,
        "_internal_originalTime": 1689747713000
    },
    {
        "time": 1689747714000,
        "value": 139.459,
        "_internal_originalTime": 1689747714000
    },
    {
        "time": 1689747715000,
        "value": 139.458,
        "_internal_originalTime": 1689747715000
    },
    {
        "time": 1689747716000,
        "value": 139.458,
        "_internal_originalTime": 1689747716000
    },
    {
        "time": 1689747717000,
        "value": 139.456,
        "_internal_originalTime": 1689747717000
    },
    {
        "time": 1689747718000,
        "value": 139.457,
        "_internal_originalTime": 1689747718000
    },
    {
        "time": 1689747719000,
        "value": 139.457,
        "_internal_originalTime": 1689747719000
    },
    {
        "time": 1689747720000,
        "value": 139.456,
        "_internal_originalTime": 1689747720000
    },
    {
        "time": 1689747721000,
        "value": 139.454,
        "_internal_originalTime": 1689747721000
    },
    {
        "time": 1689747722000,
        "value": 139.46,
        "_internal_originalTime": 1689747722000
    },
    {
        "time": 1689747723000,
        "value": 139.46,
        "_internal_originalTime": 1689747723000
    },
    {
        "time": 1689747725000,
        "value": 139.455,
        "_internal_originalTime": 1689747725000
    },
    {
        "time": 1689747726000,
        "value": 139.46,
        "_internal_originalTime": 1689747726000
    },
    {
        "time": 1689747728000,
        "value": 139.461,
        "_internal_originalTime": 1689747728000
    },
    {
        "time": 1689747729000,
        "value": 139.46,
        "_internal_originalTime": 1689747729000
    },
    {
        "time": 1689747730000,
        "value": 139.456,
        "_internal_originalTime": 1689747730000
    },
    {
        "time": 1689747731000,
        "value": 139.457,
        "_internal_originalTime": 1689747731000
    },
    {
        "time": 1689747732000,
        "value": 139.457,
        "_internal_originalTime": 1689747732000
    },
    {
        "time": 1689747734000,
        "value": 139.454,
        "_internal_originalTime": 1689747734000
    },
    {
        "time": 1689747735000,
        "value": 139.453,
        "_internal_originalTime": 1689747735000
    },
    {
        "time": 1689747736000,
        "value": 139.447,
        "_internal_originalTime": 1689747736000
    },
    {
        "time": 1689747737000,
        "value": 139.445,
        "_internal_originalTime": 1689747737000
    },
    {
        "time": 1689747738000,
        "value": 139.455,
        "_internal_originalTime": 1689747738000
    },
    {
        "time": 1689747739000,
        "value": 139.452,
        "_internal_originalTime": 1689747739000
    },
    {
        "time": 1689747741000,
        "value": 139.456,
        "_internal_originalTime": 1689747741000
    },
    {
        "time": 1689747742000,
        "value": 139.457,
        "_internal_originalTime": 1689747742000
    },
    {
        "time": 1689747743000,
        "value": 139.454,
        "_internal_originalTime": 1689747743000
    },
    {
        "time": 1689747745000,
        "value": 139.463,
        "_internal_originalTime": 1689747745000
    },
    {
        "time": 1689747747000,
        "value": 139.466,
        "_internal_originalTime": 1689747747000
    },
    {
        "time": 1689747748000,
        "value": 139.466,
        "_internal_originalTime": 1689747748000
    },
    {
        "time": 1689747749000,
        "value": 139.462,
        "_internal_originalTime": 1689747749000
    },
    {
        "time": 1689747750000,
        "value": 139.46,
        "_internal_originalTime": 1689747750000
    },
    {
        "time": 1689747751000,
        "value": 139.464,
        "_internal_originalTime": 1689747751000
    },
    {
        "time": 1689747752000,
        "value": 139.464,
        "_internal_originalTime": 1689747752000
    },
    {
        "time": 1689747754000,
        "value": 139.46,
        "_internal_originalTime": 1689747754000
    },
    {
        "time": 1689747755000,
        "value": 139.46,
        "_internal_originalTime": 1689747755000
    },
    {
        "time": 1689747756000,
        "value": 139.456,
        "_internal_originalTime": 1689747756000
    },
    {
        "time": 1689747757000,
        "value": 139.454,
        "_internal_originalTime": 1689747757000
    },
    {
        "time": 1689747758000,
        "value": 139.457,
        "_internal_originalTime": 1689747758000
    },
    {
        "time": 1689747760000,
        "value": 139.452,
        "_internal_originalTime": 1689747760000
    },
    {
        "time": 1689747761000,
        "value": 139.455,
        "_internal_originalTime": 1689747761000
    },
    {
        "time": 1689747762000,
        "value": 139.446,
        "_internal_originalTime": 1689747762000
    },
    {
        "time": 1689747763000,
        "value": 139.451,
        "_internal_originalTime": 1689747763000
    },
    {
        "time": 1689747764000,
        "value": 139.456,
        "_internal_originalTime": 1689747764000
    },
    {
        "time": 1689747765000,
        "value": 139.452,
        "_internal_originalTime": 1689747765000
    },
    {
        "time": 1689747766000,
        "value": 139.452,
        "_internal_originalTime": 1689747766000
    },
    {
        "time": 1689747768000,
        "value": 139.456,
        "_internal_originalTime": 1689747768000
    },
    {
        "time": 1689747769000,
        "value": 139.454,
        "_internal_originalTime": 1689747769000
    },
    {
        "time": 1689747770000,
        "value": 139.456,
        "_internal_originalTime": 1689747770000
    },
    {
        "time": 1689747772000,
        "value": 139.44,
        "_internal_originalTime": 1689747772000
    },
    {
        "time": 1689747773000,
        "value": 139.442,
        "_internal_originalTime": 1689747773000
    },
    {
        "time": 1689747774000,
        "value": 139.442,
        "_internal_originalTime": 1689747774000
    },
    {
        "time": 1689747775000,
        "value": 139.438,
        "_internal_originalTime": 1689747775000
    },
    {
        "time": 1689747777000,
        "value": 139.449,
        "_internal_originalTime": 1689747777000
    },
    {
        "time": 1689747778000,
        "value": 139.452,
        "_internal_originalTime": 1689747778000
    },
    {
        "time": 1689747779000,
        "value": 139.448,
        "_internal_originalTime": 1689747779000
    },
    {
        "time": 1689747781000,
        "value": 139.445,
        "_internal_originalTime": 1689747781000
    },
    {
        "time": 1689747783000,
        "value": 139.442,
        "_internal_originalTime": 1689747783000
    },
    {
        "time": 1689747784000,
        "value": 139.442,
        "_internal_originalTime": 1689747784000
    },
    {
        "time": 1689747786000,
        "value": 139.455,
        "_internal_originalTime": 1689747786000
    },
    {
        "time": 1689747787000,
        "value": 139.454,
        "_internal_originalTime": 1689747787000
    },
    {
        "time": 1689747788000,
        "value": 139.456,
        "_internal_originalTime": 1689747788000
    },
    {
        "time": 1689747789000,
        "value": 139.452,
        "_internal_originalTime": 1689747789000
    },
    {
        "time": 1689747790000,
        "value": 139.453,
        "_internal_originalTime": 1689747790000
    },
    {
        "time": 1689747791000,
        "value": 139.454,
        "_internal_originalTime": 1689747791000
    },
    {
        "time": 1689747792000,
        "value": 139.454,
        "_internal_originalTime": 1689747792000
    },
    {
        "time": 1689747793000,
        "value": 139.457,
        "_internal_originalTime": 1689747793000
    },
    {
        "time": 1689747794000,
        "value": 139.442,
        "_internal_originalTime": 1689747794000
    },
    {
        "time": 1689747795000,
        "value": 139.44,
        "_internal_originalTime": 1689747795000
    },
    {
        "time": 1689747796000,
        "value": 139.445,
        "_internal_originalTime": 1689747796000
    },
    {
        "time": 1689747797000,
        "value": 139.446,
        "_internal_originalTime": 1689747797000
    },
    {
        "time": 1689747798000,
        "value": 139.446,
        "_internal_originalTime": 1689747798000
    },
    {
        "time": 1689747800000,
        "value": 139.447,
        "_internal_originalTime": 1689747800000
    },
    {
        "time": 1689747801000,
        "value": 139.446,
        "_internal_originalTime": 1689747801000
    },
    {
        "time": 1689747802000,
        "value": 139.444,
        "_internal_originalTime": 1689747802000
    },
    {
        "time": 1689747803000,
        "value": 139.443,
        "_internal_originalTime": 1689747803000
    },
    {
        "time": 1689747804000,
        "value": 139.434,
        "_internal_originalTime": 1689747804000
    },
    {
        "time": 1689747805000,
        "value": 139.438,
        "_internal_originalTime": 1689747805000
    },
    {
        "time": 1689747806000,
        "value": 139.435,
        "_internal_originalTime": 1689747806000
    },
    {
        "time": 1689747807000,
        "value": 139.432,
        "_internal_originalTime": 1689747807000
    },
    {
        "time": 1689747808000,
        "value": 139.436,
        "_internal_originalTime": 1689747808000
    },
    {
        "time": 1689747809000,
        "value": 139.436,
        "_internal_originalTime": 1689747809000
    },
    {
        "time": 1689747810000,
        "value": 139.437,
        "_internal_originalTime": 1689747810000
    },
    {
        "time": 1689747811000,
        "value": 139.435,
        "_internal_originalTime": 1689747811000
    },
    {
        "time": 1689747812000,
        "value": 139.431,
        "_internal_originalTime": 1689747812000
    },
    {
        "time": 1689747813000,
        "value": 139.436,
        "_internal_originalTime": 1689747813000
    },
    {
        "time": 1689747814000,
        "value": 139.435,
        "_internal_originalTime": 1689747814000
    },
    {
        "time": 1689747815000,
        "value": 139.432,
        "_internal_originalTime": 1689747815000
    },
    {
        "time": 1689747816000,
        "value": 139.436,
        "_internal_originalTime": 1689747816000
    },
    {
        "time": 1689747817000,
        "value": 139.437,
        "_internal_originalTime": 1689747817000
    },
    {
        "time": 1689747818000,
        "value": 139.437,
        "_internal_originalTime": 1689747818000
    },
    {
        "time": 1689747820000,
        "value": 139.439,
        "_internal_originalTime": 1689747820000
    },
    {
        "time": 1689747821000,
        "value": 139.434,
        "_internal_originalTime": 1689747821000
    },
    {
        "time": 1689747822000,
        "value": 139.438,
        "_internal_originalTime": 1689747822000
    },
    {
        "time": 1689747823000,
        "value": 139.437,
        "_internal_originalTime": 1689747823000
    },
    {
        "time": 1689747824000,
        "value": 139.442,
        "_internal_originalTime": 1689747824000
    },
    {
        "time": 1689747825000,
        "value": 139.445,
        "_internal_originalTime": 1689747825000
    },
    {
        "time": 1689747826000,
        "value": 139.438,
        "_internal_originalTime": 1689747826000
    },
    {
        "time": 1689747827000,
        "value": 139.439,
        "_internal_originalTime": 1689747827000
    },
    {
        "time": 1689747828000,
        "value": 139.438,
        "_internal_originalTime": 1689747828000
    },
    {
        "time": 1689747829000,
        "value": 139.439,
        "_internal_originalTime": 1689747829000
    },
    {
        "time": 1689747830000,
        "value": 139.438,
        "_internal_originalTime": 1689747830000
    },
    {
        "time": 1689747831000,
        "value": 139.436,
        "_internal_originalTime": 1689747831000
    },
    {
        "time": 1689747833000,
        "value": 139.444,
        "_internal_originalTime": 1689747833000
    },
    {
        "time": 1689747834000,
        "value": 139.444,
        "_internal_originalTime": 1689747834000
    },
    {
        "time": 1689747836000,
        "value": 139.44,
        "_internal_originalTime": 1689747836000
    },
    {
        "time": 1689747837000,
        "value": 139.44,
        "_internal_originalTime": 1689747837000
    },
    {
        "time": 1689747838000,
        "value": 139.444,
        "_internal_originalTime": 1689747838000
    },
    {
        "time": 1689747839000,
        "value": 139.44,
        "_internal_originalTime": 1689747839000
    },
    {
        "time": 1689747840000,
        "value": 139.441,
        "_internal_originalTime": 1689747840000
    },
    {
        "time": 1689747841000,
        "value": 139.445,
        "_internal_originalTime": 1689747841000
    },
    {
        "time": 1689747842000,
        "value": 139.442,
        "_internal_originalTime": 1689747842000
    },
    {
        "time": 1689747843000,
        "value": 139.44,
        "_internal_originalTime": 1689747843000
    },
    {
        "time": 1689747844000,
        "value": 139.444,
        "_internal_originalTime": 1689747844000
    },
    {
        "time": 1689747845000,
        "value": 139.445,
        "_internal_originalTime": 1689747845000
    },
    {
        "time": 1689747847000,
        "value": 139.44,
        "_internal_originalTime": 1689747847000
    },
    {
        "time": 1689747848000,
        "value": 139.441,
        "_internal_originalTime": 1689747848000
    },
    {
        "time": 1689747849000,
        "value": 139.433,
        "_internal_originalTime": 1689747849000
    },
    {
        "time": 1689747850000,
        "value": 139.428,
        "_internal_originalTime": 1689747850000
    },
    {
        "time": 1689747851000,
        "value": 139.432,
        "_internal_originalTime": 1689747851000
    },
    {
        "time": 1689747852000,
        "value": 139.433,
        "_internal_originalTime": 1689747852000
    },
    {
        "time": 1689747853000,
        "value": 139.436,
        "_internal_originalTime": 1689747853000
    },
    {
        "time": 1689747855000,
        "value": 139.435,
        "_internal_originalTime": 1689747855000
    },
    {
        "time": 1689747856000,
        "value": 139.435,
        "_internal_originalTime": 1689747856000
    },
    {
        "time": 1689747858000,
        "value": 139.435,
        "_internal_originalTime": 1689747858000
    },
    {
        "time": 1689747859000,
        "value": 139.435,
        "_internal_originalTime": 1689747859000
    },
    {
        "time": 1689747861000,
        "value": 139.432,
        "_internal_originalTime": 1689747861000
    },
    {
        "time": 1689747862000,
        "value": 139.429,
        "_internal_originalTime": 1689747862000
    },
    {
        "time": 1689747863000,
        "value": 139.429,
        "_internal_originalTime": 1689747863000
    },
    {
        "time": 1689747864000,
        "value": 139.422,
        "_internal_originalTime": 1689747864000
    },
    {
        "time": 1689747865000,
        "value": 139.427,
        "_internal_originalTime": 1689747865000
    },
    {
        "time": 1689747866000,
        "value": 139.424,
        "_internal_originalTime": 1689747866000
    },
    {
        "time": 1689747867000,
        "value": 139.424,
        "_internal_originalTime": 1689747867000
    },
    {
        "time": 1689747868000,
        "value": 139.425,
        "_internal_originalTime": 1689747868000
    },
    {
        "time": 1689747869000,
        "value": 139.424,
        "_internal_originalTime": 1689747869000
    },
    {
        "time": 1689747870000,
        "value": 139.427,
        "_internal_originalTime": 1689747870000
    },
    {
        "time": 1689747871000,
        "value": 139.429,
        "_internal_originalTime": 1689747871000
    },
    {
        "time": 1689747872000,
        "value": 139.43,
        "_internal_originalTime": 1689747872000
    },
    {
        "time": 1689747873000,
        "value": 139.435,
        "_internal_originalTime": 1689747873000
    },
    {
        "time": 1689747874000,
        "value": 139.437,
        "_internal_originalTime": 1689747874000
    },
    {
        "time": 1689747875000,
        "value": 139.434,
        "_internal_originalTime": 1689747875000
    },
    {
        "time": 1689747877000,
        "value": 139.435,
        "_internal_originalTime": 1689747877000
    },
    {
        "time": 1689747878000,
        "value": 139.436,
        "_internal_originalTime": 1689747878000
    },
    {
        "time": 1689747880000,
        "value": 139.436,
        "_internal_originalTime": 1689747880000
    },
    {
        "time": 1689747881000,
        "value": 139.43,
        "_internal_originalTime": 1689747881000
    },
    {
        "time": 1689747882000,
        "value": 139.437,
        "_internal_originalTime": 1689747882000
    },
    {
        "time": 1689747884000,
        "value": 139.434,
        "_internal_originalTime": 1689747884000
    },
    {
        "time": 1689747885000,
        "value": 139.435,
        "_internal_originalTime": 1689747885000
    },
    {
        "time": 1689747886000,
        "value": 139.436,
        "_internal_originalTime": 1689747886000
    },
    {
        "time": 1689747887000,
        "value": 139.436,
        "_internal_originalTime": 1689747887000
    },
    {
        "time": 1689747889000,
        "value": 139.422,
        "_internal_originalTime": 1689747889000
    },
    {
        "time": 1689747891000,
        "value": 139.418,
        "_internal_originalTime": 1689747891000
    },
    {
        "time": 1689747893000,
        "value": 139.417,
        "_internal_originalTime": 1689747893000
    },
    {
        "time": 1689747894000,
        "value": 139.414,
        "_internal_originalTime": 1689747894000
    },
    {
        "time": 1689747895000,
        "value": 139.41,
        "_internal_originalTime": 1689747895000
    },
    {
        "time": 1689747896000,
        "value": 139.412,
        "_internal_originalTime": 1689747896000
    },
    {
        "time": 1689747898000,
        "value": 139.406,
        "_internal_originalTime": 1689747898000
    },
    {
        "time": 1689747900000,
        "value": 139.402,
        "_internal_originalTime": 1689747900000
    },
    {
        "time": 1689747901000,
        "value": 139.389,
        "_internal_originalTime": 1689747901000
    },
    {
        "time": 1689747902000,
        "value": 139.391,
        "_internal_originalTime": 1689747902000
    },
    {
        "time": 1689747903000,
        "value": 139.389,
        "_internal_originalTime": 1689747903000
    },
    {
        "time": 1689747904000,
        "value": 139.387,
        "_internal_originalTime": 1689747904000
    },
    {
        "time": 1689747905000,
        "value": 139.388,
        "_internal_originalTime": 1689747905000
    },
    {
        "time": 1689747907000,
        "value": 139.387,
        "_internal_originalTime": 1689747907000
    },
    {
        "time": 1689747908000,
        "value": 139.385,
        "_internal_originalTime": 1689747908000
    },
    {
        "time": 1689747909000,
        "value": 139.387,
        "_internal_originalTime": 1689747909000
    },
    {
        "time": 1689747910000,
        "value": 139.388,
        "_internal_originalTime": 1689747910000
    },
    {
        "time": 1689747911000,
        "value": 139.391,
        "_internal_originalTime": 1689747911000
    },
    {
        "time": 1689747912000,
        "value": 139.39,
        "_internal_originalTime": 1689747912000
    },
    {
        "time": 1689747915000,
        "value": 139.391,
        "_internal_originalTime": 1689747915000
    },
    {
        "time": 1689747916000,
        "value": 139.39,
        "_internal_originalTime": 1689747916000
    },
    {
        "time": 1689747918000,
        "value": 139.39,
        "_internal_originalTime": 1689747918000
    },
    {
        "time": 1689747920000,
        "value": 139.389,
        "_internal_originalTime": 1689747920000
    },
    {
        "time": 1689747921000,
        "value": 139.389,
        "_internal_originalTime": 1689747921000
    },
    {
        "time": 1689747922000,
        "value": 139.389,
        "_internal_originalTime": 1689747922000
    },
    {
        "time": 1689747923000,
        "value": 139.391,
        "_internal_originalTime": 1689747923000
    },
    {
        "time": 1689747924000,
        "value": 139.388,
        "_internal_originalTime": 1689747924000
    },
    {
        "time": 1689747925000,
        "value": 139.388,
        "_internal_originalTime": 1689747925000
    },
    {
        "time": 1689747926000,
        "value": 139.384,
        "_internal_originalTime": 1689747926000
    },
    {
        "time": 1689747927000,
        "value": 139.382,
        "_internal_originalTime": 1689747927000
    },
    {
        "time": 1689747928000,
        "value": 139.386,
        "_internal_originalTime": 1689747928000
    },
    {
        "time": 1689747930000,
        "value": 139.384,
        "_internal_originalTime": 1689747930000
    },
    {
        "time": 1689747931000,
        "value": 139.388,
        "_internal_originalTime": 1689747931000
    },
    {
        "time": 1689747932000,
        "value": 139.383,
        "_internal_originalTime": 1689747932000
    },
    {
        "time": 1689747933000,
        "value": 139.38,
        "_internal_originalTime": 1689747933000
    },
    {
        "time": 1689747934000,
        "value": 139.384,
        "_internal_originalTime": 1689747934000
    },
    {
        "time": 1689747935000,
        "value": 139.382,
        "_internal_originalTime": 1689747935000
    },
    {
        "time": 1689747938000,
        "value": 139.374,
        "_internal_originalTime": 1689747938000
    },
    {
        "time": 1689747940000,
        "value": 139.37,
        "_internal_originalTime": 1689747940000
    },
    {
        "time": 1689747942000,
        "value": 139.37,
        "_internal_originalTime": 1689747942000
    },
    {
        "time": 1689747943000,
        "value": 139.372,
        "_internal_originalTime": 1689747943000
    },
    {
        "time": 1689747944000,
        "value": 139.362,
        "_internal_originalTime": 1689747944000
    },
    {
        "time": 1689747945000,
        "value": 139.361,
        "_internal_originalTime": 1689747945000
    },
    {
        "time": 1689747946000,
        "value": 139.362,
        "_internal_originalTime": 1689747946000
    },
    {
        "time": 1689747947000,
        "value": 139.366,
        "_internal_originalTime": 1689747947000
    },
    {
        "time": 1689747948000,
        "value": 139.361,
        "_internal_originalTime": 1689747948000
    },
    {
        "time": 1689747949000,
        "value": 139.365,
        "_internal_originalTime": 1689747949000
    },
    {
        "time": 1689747951000,
        "value": 139.363,
        "_internal_originalTime": 1689747951000
    },
    {
        "time": 1689747952000,
        "value": 139.366,
        "_internal_originalTime": 1689747952000
    },
    {
        "time": 1689747953000,
        "value": 139.37,
        "_internal_originalTime": 1689747953000
    },
    {
        "time": 1689747954000,
        "value": 139.367,
        "_internal_originalTime": 1689747954000
    },
    {
        "time": 1689747955000,
        "value": 139.376,
        "_internal_originalTime": 1689747955000
    },
    {
        "time": 1689747957000,
        "value": 139.382,
        "_internal_originalTime": 1689747957000
    },
    {
        "time": 1689747958000,
        "value": 139.375,
        "_internal_originalTime": 1689747958000
    },
    {
        "time": 1689747959000,
        "value": 139.373,
        "_internal_originalTime": 1689747959000
    },
    {
        "time": 1689747960000,
        "value": 139.375,
        "_internal_originalTime": 1689747960000
    },
    {
        "time": 1689747961000,
        "value": 139.375,
        "_internal_originalTime": 1689747961000
    },
    {
        "time": 1689747962000,
        "value": 139.374,
        "_internal_originalTime": 1689747962000
    },
    {
        "time": 1689747963000,
        "value": 139.376,
        "_internal_originalTime": 1689747963000
    },
    {
        "time": 1689747964000,
        "value": 139.375,
        "_internal_originalTime": 1689747964000
    },
    {
        "time": 1689747965000,
        "value": 139.375,
        "_internal_originalTime": 1689747965000
    },
    {
        "time": 1689747967000,
        "value": 139.38,
        "_internal_originalTime": 1689747967000
    },
    {
        "time": 1689747968000,
        "value": 139.381,
        "_internal_originalTime": 1689747968000
    },
    {
        "time": 1689747969000,
        "value": 139.389,
        "_internal_originalTime": 1689747969000
    },
    {
        "time": 1689747970000,
        "value": 139.388,
        "_internal_originalTime": 1689747970000
    },
    {
        "time": 1689747971000,
        "value": 139.392,
        "_internal_originalTime": 1689747971000
    },
    {
        "time": 1689747973000,
        "value": 139.408,
        "_internal_originalTime": 1689747973000
    },
    {
        "time": 1689747974000,
        "value": 139.415,
        "_internal_originalTime": 1689747974000
    },
    {
        "time": 1689747975000,
        "value": 139.415,
        "_internal_originalTime": 1689747975000
    },
    {
        "time": 1689747976000,
        "value": 139.404,
        "_internal_originalTime": 1689747976000
    },
    {
        "time": 1689747978000,
        "value": 139.407,
        "_internal_originalTime": 1689747978000
    },
    {
        "time": 1689747979000,
        "value": 139.404,
        "_internal_originalTime": 1689747979000
    },
    {
        "time": 1689747980000,
        "value": 139.407,
        "_internal_originalTime": 1689747980000
    },
    {
        "time": 1689747981000,
        "value": 139.399,
        "_internal_originalTime": 1689747981000
    },
    {
        "time": 1689747982000,
        "value": 139.412,
        "_internal_originalTime": 1689747982000
    },
    {
        "time": 1689747983000,
        "value": 139.427,
        "_internal_originalTime": 1689747983000
    },
    {
        "time": 1689747984000,
        "value": 139.436,
        "_internal_originalTime": 1689747984000
    },
    {
        "time": 1689747985000,
        "value": 139.436,
        "_internal_originalTime": 1689747985000
    },
    {
        "time": 1689747986000,
        "value": 139.428,
        "_internal_originalTime": 1689747986000
    },
    {
        "time": 1689747988000,
        "value": 139.429,
        "_internal_originalTime": 1689747988000
    },
    {
        "time": 1689747989000,
        "value": 139.42,
        "_internal_originalTime": 1689747989000
    },
    {
        "time": 1689747990000,
        "value": 139.417,
        "_internal_originalTime": 1689747990000
    },
    {
        "time": 1689747991000,
        "value": 139.414,
        "_internal_originalTime": 1689747991000
    },
    {
        "time": 1689747992000,
        "value": 139.416,
        "_internal_originalTime": 1689747992000
    },
    {
        "time": 1689747993000,
        "value": 139.412,
        "_internal_originalTime": 1689747993000
    },
    {
        "time": 1689747994000,
        "value": 139.412,
        "_internal_originalTime": 1689747994000
    },
    {
        "time": 1689747995000,
        "value": 139.411,
        "_internal_originalTime": 1689747995000
    },
    {
        "time": 1689747998000,
        "value": 139.411,
        "_internal_originalTime": 1689747998000
    },
    {
        "time": 1689747999000,
        "value": 139.4,
        "_internal_originalTime": 1689747999000
    },
    {
        "time": 1689748000000,
        "value": 139.397,
        "_internal_originalTime": 1689748000000
    },
    {
        "time": 1689748001000,
        "value": 139.393,
        "_internal_originalTime": 1689748001000
    },
    {
        "time": 1689748002000,
        "value": 139.398,
        "_internal_originalTime": 1689748002000
    },
    {
        "time": 1689748003000,
        "value": 139.402,
        "_internal_originalTime": 1689748003000
    },
    {
        "time": 1689748004000,
        "value": 139.406,
        "_internal_originalTime": 1689748004000
    },
    {
        "time": 1689748005000,
        "value": 139.408,
        "_internal_originalTime": 1689748005000
    },
    {
        "time": 1689748006000,
        "value": 139.414,
        "_internal_originalTime": 1689748006000
    },
    {
        "time": 1689748007000,
        "value": 139.416,
        "_internal_originalTime": 1689748007000
    },
    {
        "time": 1689748008000,
        "value": 139.414,
        "_internal_originalTime": 1689748008000
    },
    {
        "time": 1689748009000,
        "value": 139.415,
        "_internal_originalTime": 1689748009000
    },
    {
        "time": 1689748010000,
        "value": 139.412,
        "_internal_originalTime": 1689748010000
    },
    {
        "time": 1689748012000,
        "value": 139.417,
        "_internal_originalTime": 1689748012000
    },
    {
        "time": 1689748015000,
        "value": 139.408,
        "_internal_originalTime": 1689748015000
    },
    {
        "time": 1689748016000,
        "value": 139.408,
        "_internal_originalTime": 1689748016000
    },
    {
        "time": 1689748017000,
        "value": 139.406,
        "_internal_originalTime": 1689748017000
    },
    {
        "time": 1689748019000,
        "value": 139.409,
        "_internal_originalTime": 1689748019000
    },
    {
        "time": 1689748020000,
        "value": 139.408,
        "_internal_originalTime": 1689748020000
    },
    {
        "time": 1689748021000,
        "value": 139.38,
        "_internal_originalTime": 1689748021000
    },
    {
        "time": 1689748022000,
        "value": 139.41,
        "_internal_originalTime": 1689748022000
    },
    {
        "time": 1689748023000,
        "value": 139.409,
        "_internal_originalTime": 1689748023000
    },
    {
        "time": 1689748024000,
        "value": 139.41,
        "_internal_originalTime": 1689748024000
    },
    {
        "time": 1689748025000,
        "value": 139.405,
        "_internal_originalTime": 1689748025000
    },
    {
        "time": 1689748026000,
        "value": 139.412,
        "_internal_originalTime": 1689748026000
    },
    {
        "time": 1689748027000,
        "value": 139.411,
        "_internal_originalTime": 1689748027000
    },
    {
        "time": 1689748028000,
        "value": 139.415,
        "_internal_originalTime": 1689748028000
    },
    {
        "time": 1689748029000,
        "value": 139.415,
        "_internal_originalTime": 1689748029000
    },
    {
        "time": 1689748030000,
        "value": 139.407,
        "_internal_originalTime": 1689748030000
    },
    {
        "time": 1689748032000,
        "value": 139.403,
        "_internal_originalTime": 1689748032000
    },
    {
        "time": 1689748033000,
        "value": 139.398,
        "_internal_originalTime": 1689748033000
    },
    {
        "time": 1689748035000,
        "value": 139.397,
        "_internal_originalTime": 1689748035000
    },
    {
        "time": 1689748036000,
        "value": 139.399,
        "_internal_originalTime": 1689748036000
    },
    {
        "time": 1689748037000,
        "value": 139.396,
        "_internal_originalTime": 1689748037000
    },
    {
        "time": 1689748039000,
        "value": 139.388,
        "_internal_originalTime": 1689748039000
    },
    {
        "time": 1689748040000,
        "value": 139.39,
        "_internal_originalTime": 1689748040000
    },
    {
        "time": 1689748042000,
        "value": 139.39,
        "_internal_originalTime": 1689748042000
    },
    {
        "time": 1689748043000,
        "value": 139.388,
        "_internal_originalTime": 1689748043000
    },
    {
        "time": 1689748044000,
        "value": 139.392,
        "_internal_originalTime": 1689748044000
    },
    {
        "time": 1689748045000,
        "value": 139.392,
        "_internal_originalTime": 1689748045000
    },
    {
        "time": 1689748046000,
        "value": 139.39,
        "_internal_originalTime": 1689748046000
    },
    {
        "time": 1689748047000,
        "value": 139.399,
        "_internal_originalTime": 1689748047000
    },
    {
        "time": 1689748048000,
        "value": 139.401,
        "_internal_originalTime": 1689748048000
    },
    {
        "time": 1689748049000,
        "value": 139.397,
        "_internal_originalTime": 1689748049000
    },
    {
        "time": 1689748050000,
        "value": 139.402,
        "_internal_originalTime": 1689748050000
    },
    {
        "time": 1689748053000,
        "value": 139.403,
        "_internal_originalTime": 1689748053000
    },
    {
        "time": 1689748054000,
        "value": 139.399,
        "_internal_originalTime": 1689748054000
    },
    {
        "time": 1689748055000,
        "value": 139.401,
        "_internal_originalTime": 1689748055000
    },
    {
        "time": 1689748057000,
        "value": 139.397,
        "_internal_originalTime": 1689748057000
    },
    {
        "time": 1689748059000,
        "value": 139.401,
        "_internal_originalTime": 1689748059000
    },
    {
        "time": 1689748060000,
        "value": 139.399,
        "_internal_originalTime": 1689748060000
    },
    {
        "time": 1689748061000,
        "value": 139.399,
        "_internal_originalTime": 1689748061000
    },
    {
        "time": 1689748062000,
        "value": 139.397,
        "_internal_originalTime": 1689748062000
    },
    {
        "time": 1689748064000,
        "value": 139.398,
        "_internal_originalTime": 1689748064000
    },
    {
        "time": 1689748065000,
        "value": 139.401,
        "_internal_originalTime": 1689748065000
    },
    {
        "time": 1689748066000,
        "value": 139.396,
        "_internal_originalTime": 1689748066000
    },
    {
        "time": 1689748068000,
        "value": 139.4,
        "_internal_originalTime": 1689748068000
    },
    {
        "time": 1689748069000,
        "value": 139.394,
        "_internal_originalTime": 1689748069000
    },
    {
        "time": 1689748070000,
        "value": 139.398,
        "_internal_originalTime": 1689748070000
    },
    {
        "time": 1689748071000,
        "value": 139.399,
        "_internal_originalTime": 1689748071000
    },
    {
        "time": 1689748072000,
        "value": 139.396,
        "_internal_originalTime": 1689748072000
    },
    {
        "time": 1689748074000,
        "value": 139.401,
        "_internal_originalTime": 1689748074000
    },
    {
        "time": 1689748075000,
        "value": 139.407,
        "_internal_originalTime": 1689748075000
    },
    {
        "time": 1689748076000,
        "value": 139.402,
        "_internal_originalTime": 1689748076000
    },
    {
        "time": 1689748078000,
        "value": 139.415,
        "_internal_originalTime": 1689748078000
    },
    {
        "time": 1689748079000,
        "value": 139.414,
        "_internal_originalTime": 1689748079000
    },
    {
        "time": 1689748080000,
        "value": 139.413,
        "_internal_originalTime": 1689748080000
    },
    {
        "time": 1689748082000,
        "value": 139.412,
        "_internal_originalTime": 1689748082000
    },
    {
        "time": 1689748083000,
        "value": 139.411,
        "_internal_originalTime": 1689748083000
    },
    {
        "time": 1689748085000,
        "value": 139.408,
        "_internal_originalTime": 1689748085000
    },
    {
        "time": 1689748086000,
        "value": 139.406,
        "_internal_originalTime": 1689748086000
    },
    {
        "time": 1689748087000,
        "value": 139.398,
        "_internal_originalTime": 1689748087000
    },
    {
        "time": 1689748088000,
        "value": 139.398,
        "_internal_originalTime": 1689748088000
    },
    {
        "time": 1689748089000,
        "value": 139.401,
        "_internal_originalTime": 1689748089000
    },
    {
        "time": 1689748090000,
        "value": 139.4,
        "_internal_originalTime": 1689748090000
    },
    {
        "time": 1689748091000,
        "value": 139.398,
        "_internal_originalTime": 1689748091000
    },
    {
        "time": 1689748093000,
        "value": 139.396,
        "_internal_originalTime": 1689748093000
    },
    {
        "time": 1689748094000,
        "value": 139.394,
        "_internal_originalTime": 1689748094000
    },
    {
        "time": 1689748095000,
        "value": 139.399,
        "_internal_originalTime": 1689748095000
    },
    {
        "time": 1689748096000,
        "value": 139.399,
        "_internal_originalTime": 1689748096000
    },
    {
        "time": 1689748097000,
        "value": 139.399,
        "_internal_originalTime": 1689748097000
    },
    {
        "time": 1689748098000,
        "value": 139.401,
        "_internal_originalTime": 1689748098000
    },
    {
        "time": 1689748100000,
        "value": 139.396,
        "_internal_originalTime": 1689748100000
    },
    {
        "time": 1689748101000,
        "value": 139.4,
        "_internal_originalTime": 1689748101000
    },
    {
        "time": 1689748102000,
        "value": 139.398,
        "_internal_originalTime": 1689748102000
    },
    {
        "time": 1689748104000,
        "value": 139.394,
        "_internal_originalTime": 1689748104000
    },
    {
        "time": 1689748105000,
        "value": 139.386,
        "_internal_originalTime": 1689748105000
    },
    {
        "time": 1689748107000,
        "value": 139.393,
        "_internal_originalTime": 1689748107000
    },
    {
        "time": 1689748108000,
        "value": 139.39,
        "_internal_originalTime": 1689748108000
    },
    {
        "time": 1689748109000,
        "value": 139.398,
        "_internal_originalTime": 1689748109000
    },
    {
        "time": 1689748110000,
        "value": 139.387,
        "_internal_originalTime": 1689748110000
    },
    {
        "time": 1689748111000,
        "value": 139.387,
        "_internal_originalTime": 1689748111000
    },
    {
        "time": 1689748113000,
        "value": 139.388,
        "_internal_originalTime": 1689748113000
    },
    {
        "time": 1689748114000,
        "value": 139.387,
        "_internal_originalTime": 1689748114000
    },
    {
        "time": 1689748116000,
        "value": 139.392,
        "_internal_originalTime": 1689748116000
    },
    {
        "time": 1689748117000,
        "value": 139.394,
        "_internal_originalTime": 1689748117000
    },
    {
        "time": 1689748118000,
        "value": 139.392,
        "_internal_originalTime": 1689748118000
    },
    {
        "time": 1689748119000,
        "value": 139.395,
        "_internal_originalTime": 1689748119000
    },
    {
        "time": 1689748120000,
        "value": 139.394,
        "_internal_originalTime": 1689748120000
    },
    {
        "time": 1689748122000,
        "value": 139.395,
        "_internal_originalTime": 1689748122000
    },
    {
        "time": 1689748123000,
        "value": 139.39,
        "_internal_originalTime": 1689748123000
    },
    {
        "time": 1689748125000,
        "value": 139.409,
        "_internal_originalTime": 1689748125000
    },
    {
        "time": 1689748126000,
        "value": 139.419,
        "_internal_originalTime": 1689748126000
    },
    {
        "time": 1689748127000,
        "value": 139.415,
        "_internal_originalTime": 1689748127000
    },
    {
        "time": 1689748129000,
        "value": 139.417,
        "_internal_originalTime": 1689748129000
    },
    {
        "time": 1689748130000,
        "value": 139.415,
        "_internal_originalTime": 1689748130000
    },
    {
        "time": 1689748131000,
        "value": 139.418,
        "_internal_originalTime": 1689748131000
    },
    {
        "time": 1689748132000,
        "value": 139.416,
        "_internal_originalTime": 1689748132000
    },
    {
        "time": 1689748134000,
        "value": 139.415,
        "_internal_originalTime": 1689748134000
    },
    {
        "time": 1689748135000,
        "value": 139.419,
        "_internal_originalTime": 1689748135000
    },
    {
        "time": 1689748137000,
        "value": 139.421,
        "_internal_originalTime": 1689748137000
    },
    {
        "time": 1689748139000,
        "value": 139.419,
        "_internal_originalTime": 1689748139000
    },
    {
        "time": 1689748140000,
        "value": 139.42,
        "_internal_originalTime": 1689748140000
    },
    {
        "time": 1689748141000,
        "value": 139.418,
        "_internal_originalTime": 1689748141000
    },
    {
        "time": 1689748142000,
        "value": 139.424,
        "_internal_originalTime": 1689748142000
    },
    {
        "time": 1689748143000,
        "value": 139.422,
        "_internal_originalTime": 1689748143000
    },
    {
        "time": 1689748145000,
        "value": 139.419,
        "_internal_originalTime": 1689748145000
    },
    {
        "time": 1689748146000,
        "value": 139.416,
        "_internal_originalTime": 1689748146000
    },
    {
        "time": 1689748147000,
        "value": 139.417,
        "_internal_originalTime": 1689748147000
    },
    {
        "time": 1689748148000,
        "value": 139.417,
        "_internal_originalTime": 1689748148000
    },
    {
        "time": 1689748149000,
        "value": 139.418,
        "_internal_originalTime": 1689748149000
    },
    {
        "time": 1689748151000,
        "value": 139.418,
        "_internal_originalTime": 1689748151000
    },
    {
        "time": 1689748153000,
        "value": 139.415,
        "_internal_originalTime": 1689748153000
    },
    {
        "time": 1689748155000,
        "value": 139.414,
        "_internal_originalTime": 1689748155000
    },
    {
        "time": 1689748156000,
        "value": 139.414,
        "_internal_originalTime": 1689748156000
    },
    {
        "time": 1689748157000,
        "value": 139.414,
        "_internal_originalTime": 1689748157000
    },
    {
        "time": 1689748158000,
        "value": 139.414,
        "_internal_originalTime": 1689748158000
    },
    {
        "time": 1689748159000,
        "value": 139.409,
        "_internal_originalTime": 1689748159000
    },
    {
        "time": 1689748161000,
        "value": 139.41,
        "_internal_originalTime": 1689748161000
    },
    {
        "time": 1689748162000,
        "value": 139.405,
        "_internal_originalTime": 1689748162000
    },
    {
        "time": 1689748163000,
        "value": 139.405,
        "_internal_originalTime": 1689748163000
    },
    {
        "time": 1689748164000,
        "value": 139.402,
        "_internal_originalTime": 1689748164000
    },
    {
        "time": 1689748165000,
        "value": 139.405,
        "_internal_originalTime": 1689748165000
    },
    {
        "time": 1689748166000,
        "value": 139.403,
        "_internal_originalTime": 1689748166000
    },
    {
        "time": 1689748168000,
        "value": 139.398,
        "_internal_originalTime": 1689748168000
    },
    {
        "time": 1689748169000,
        "value": 139.4,
        "_internal_originalTime": 1689748169000
    },
    {
        "time": 1689748171000,
        "value": 139.401,
        "_internal_originalTime": 1689748171000
    },
    {
        "time": 1689748172000,
        "value": 139.4,
        "_internal_originalTime": 1689748172000
    },
    {
        "time": 1689748174000,
        "value": 139.402,
        "_internal_originalTime": 1689748174000
    },
    {
        "time": 1689748176000,
        "value": 139.402,
        "_internal_originalTime": 1689748176000
    },
    {
        "time": 1689748177000,
        "value": 139.403,
        "_internal_originalTime": 1689748177000
    },
    {
        "time": 1689748178000,
        "value": 139.4,
        "_internal_originalTime": 1689748178000
    },
    {
        "time": 1689748180000,
        "value": 139.396,
        "_internal_originalTime": 1689748180000
    },
    {
        "time": 1689748181000,
        "value": 139.393,
        "_internal_originalTime": 1689748181000
    },
    {
        "time": 1689748182000,
        "value": 139.389,
        "_internal_originalTime": 1689748182000
    },
    {
        "time": 1689748183000,
        "value": 139.399,
        "_internal_originalTime": 1689748183000
    },
    {
        "time": 1689748185000,
        "value": 139.397,
        "_internal_originalTime": 1689748185000
    },
    {
        "time": 1689748186000,
        "value": 139.398,
        "_internal_originalTime": 1689748186000
    },
    {
        "time": 1689748187000,
        "value": 139.399,
        "_internal_originalTime": 1689748187000
    },
    {
        "time": 1689748188000,
        "value": 139.402,
        "_internal_originalTime": 1689748188000
    },
    {
        "time": 1689748189000,
        "value": 139.401,
        "_internal_originalTime": 1689748189000
    },
    {
        "time": 1689748190000,
        "value": 139.398,
        "_internal_originalTime": 1689748190000
    },
    {
        "time": 1689748192000,
        "value": 139.4,
        "_internal_originalTime": 1689748192000
    },
    {
        "time": 1689748193000,
        "value": 139.398,
        "_internal_originalTime": 1689748193000
    },
    {
        "time": 1689748194000,
        "value": 139.398,
        "_internal_originalTime": 1689748194000
    },
    {
        "time": 1689748195000,
        "value": 139.39,
        "_internal_originalTime": 1689748195000
    },
    {
        "time": 1689748196000,
        "value": 139.394,
        "_internal_originalTime": 1689748196000
    },
    {
        "time": 1689748198000,
        "value": 139.393,
        "_internal_originalTime": 1689748198000
    },
    {
        "time": 1689748199000,
        "value": 139.389,
        "_internal_originalTime": 1689748199000
    },
    {
        "time": 1689748200000,
        "value": 139.398,
        "_internal_originalTime": 1689748200000
    },
    {
        "time": 1689748201000,
        "value": 139.381,
        "_internal_originalTime": 1689748201000
    },
    {
        "time": 1689748202000,
        "value": 139.37,
        "_internal_originalTime": 1689748202000
    },
    {
        "time": 1689748203000,
        "value": 139.387,
        "_internal_originalTime": 1689748203000
    },
    {
        "time": 1689748204000,
        "value": 139.388,
        "_internal_originalTime": 1689748204000
    },
    {
        "time": 1689748205000,
        "value": 139.389,
        "_internal_originalTime": 1689748205000
    },
    {
        "time": 1689748206000,
        "value": 139.392,
        "_internal_originalTime": 1689748206000
    },
    {
        "time": 1689748207000,
        "value": 139.395,
        "_internal_originalTime": 1689748207000
    },
    {
        "time": 1689748208000,
        "value": 139.397,
        "_internal_originalTime": 1689748208000
    },
    {
        "time": 1689748210000,
        "value": 139.399,
        "_internal_originalTime": 1689748210000
    },
    {
        "time": 1689748211000,
        "value": 139.401,
        "_internal_originalTime": 1689748211000
    },
    {
        "time": 1689748212000,
        "value": 139.399,
        "_internal_originalTime": 1689748212000
    },
    {
        "time": 1689748213000,
        "value": 139.399,
        "_internal_originalTime": 1689748213000
    },
    {
        "time": 1689748214000,
        "value": 139.402,
        "_internal_originalTime": 1689748214000
    },
    {
        "time": 1689748215000,
        "value": 139.409,
        "_internal_originalTime": 1689748215000
    },
    {
        "time": 1689748216000,
        "value": 139.407,
        "_internal_originalTime": 1689748216000
    },
    {
        "time": 1689748217000,
        "value": 139.407,
        "_internal_originalTime": 1689748217000
    },
    {
        "time": 1689748219000,
        "value": 139.411,
        "_internal_originalTime": 1689748219000
    },
    {
        "time": 1689748220000,
        "value": 139.409,
        "_internal_originalTime": 1689748220000
    },
    {
        "time": 1689748221000,
        "value": 139.407,
        "_internal_originalTime": 1689748221000
    },
    {
        "time": 1689748222000,
        "value": 139.403,
        "_internal_originalTime": 1689748222000
    },
    {
        "time": 1689748223000,
        "value": 139.394,
        "_internal_originalTime": 1689748223000
    },
    {
        "time": 1689748224000,
        "value": 139.385,
        "_internal_originalTime": 1689748224000
    },
    {
        "time": 1689748225000,
        "value": 139.388,
        "_internal_originalTime": 1689748225000
    },
    {
        "time": 1689748226000,
        "value": 139.385,
        "_internal_originalTime": 1689748226000
    },
    {
        "time": 1689748228000,
        "value": 139.385,
        "_internal_originalTime": 1689748228000
    },
    {
        "time": 1689748229000,
        "value": 139.384,
        "_internal_originalTime": 1689748229000
    },
    {
        "time": 1689748230000,
        "value": 139.382,
        "_internal_originalTime": 1689748230000
    },
    {
        "time": 1689748231000,
        "value": 139.386,
        "_internal_originalTime": 1689748231000
    },
    {
        "time": 1689748232000,
        "value": 139.388,
        "_internal_originalTime": 1689748232000
    },
    {
        "time": 1689748233000,
        "value": 139.389,
        "_internal_originalTime": 1689748233000
    },
    {
        "time": 1689748234000,
        "value": 139.389,
        "_internal_originalTime": 1689748234000
    },
    {
        "time": 1689748236000,
        "value": 139.384,
        "_internal_originalTime": 1689748236000
    },
    {
        "time": 1689748237000,
        "value": 139.382,
        "_internal_originalTime": 1689748237000
    },
    {
        "time": 1689748238000,
        "value": 139.381,
        "_internal_originalTime": 1689748238000
    },
    {
        "time": 1689748239000,
        "value": 139.381,
        "_internal_originalTime": 1689748239000
    },
    {
        "time": 1689748240000,
        "value": 139.382,
        "_internal_originalTime": 1689748240000
    },
    {
        "time": 1689748241000,
        "value": 139.379,
        "_internal_originalTime": 1689748241000
    },
    {
        "time": 1689748243000,
        "value": 139.379,
        "_internal_originalTime": 1689748243000
    },
    {
        "time": 1689748244000,
        "value": 139.382,
        "_internal_originalTime": 1689748244000
    },
    {
        "time": 1689748245000,
        "value": 139.38,
        "_internal_originalTime": 1689748245000
    },
    {
        "time": 1689748246000,
        "value": 139.381,
        "_internal_originalTime": 1689748246000
    },
    {
        "time": 1689748248000,
        "value": 139.378,
        "_internal_originalTime": 1689748248000
    },
    {
        "time": 1689748249000,
        "value": 139.379,
        "_internal_originalTime": 1689748249000
    },
    {
        "time": 1689748250000,
        "value": 139.378,
        "_internal_originalTime": 1689748250000
    },
    {
        "time": 1689748252000,
        "value": 139.371,
        "_internal_originalTime": 1689748252000
    },
    {
        "time": 1689748254000,
        "value": 139.368,
        "_internal_originalTime": 1689748254000
    },
    {
        "time": 1689748255000,
        "value": 139.366,
        "_internal_originalTime": 1689748255000
    },
    {
        "time": 1689748256000,
        "value": 139.363,
        "_internal_originalTime": 1689748256000
    },
    {
        "time": 1689748257000,
        "value": 139.364,
        "_internal_originalTime": 1689748257000
    },
    {
        "time": 1689748259000,
        "value": 139.366,
        "_internal_originalTime": 1689748259000
    },
    {
        "time": 1689748260000,
        "value": 139.368,
        "_internal_originalTime": 1689748260000
    },
    {
        "time": 1689748261000,
        "value": 139.35,
        "_internal_originalTime": 1689748261000
    },
    {
        "time": 1689748262000,
        "value": 139.371,
        "_internal_originalTime": 1689748262000
    },
    {
        "time": 1689748263000,
        "value": 139.371,
        "_internal_originalTime": 1689748263000
    },
    {
        "time": 1689748264000,
        "value": 139.37,
        "_internal_originalTime": 1689748264000
    },
    {
        "time": 1689748266000,
        "value": 139.376,
        "_internal_originalTime": 1689748266000
    },
    {
        "time": 1689748267000,
        "value": 139.374,
        "_internal_originalTime": 1689748267000
    },
    {
        "time": 1689748268000,
        "value": 139.375,
        "_internal_originalTime": 1689748268000
    },
    {
        "time": 1689748269000,
        "value": 139.375,
        "_internal_originalTime": 1689748269000
    },
    {
        "time": 1689748271000,
        "value": 139.388,
        "_internal_originalTime": 1689748271000
    },
    {
        "time": 1689748272000,
        "value": 139.389,
        "_internal_originalTime": 1689748272000
    },
    {
        "time": 1689748273000,
        "value": 139.398,
        "_internal_originalTime": 1689748273000
    },
    {
        "time": 1689748274000,
        "value": 139.408,
        "_internal_originalTime": 1689748274000
    },
    {
        "time": 1689748275000,
        "value": 139.407,
        "_internal_originalTime": 1689748275000
    },
    {
        "time": 1689748276000,
        "value": 139.411,
        "_internal_originalTime": 1689748276000
    },
    {
        "time": 1689748277000,
        "value": 139.415,
        "_internal_originalTime": 1689748277000
    },
    {
        "time": 1689748278000,
        "value": 139.412,
        "_internal_originalTime": 1689748278000
    },
    {
        "time": 1689748279000,
        "value": 139.411,
        "_internal_originalTime": 1689748279000
    },
    {
        "time": 1689748280000,
        "value": 139.41,
        "_internal_originalTime": 1689748280000
    },
    {
        "time": 1689748282000,
        "value": 139.409,
        "_internal_originalTime": 1689748282000
    },
    {
        "time": 1689748284000,
        "value": 139.416,
        "_internal_originalTime": 1689748284000
    },
    {
        "time": 1689748285000,
        "value": 139.413,
        "_internal_originalTime": 1689748285000
    },
    {
        "time": 1689748286000,
        "value": 139.41,
        "_internal_originalTime": 1689748286000
    },
    {
        "time": 1689748288000,
        "value": 139.42,
        "_internal_originalTime": 1689748288000
    },
    {
        "time": 1689748290000,
        "value": 139.417,
        "_internal_originalTime": 1689748290000
    },
    {
        "time": 1689748291000,
        "value": 139.415,
        "_internal_originalTime": 1689748291000
    },
    {
        "time": 1689748293000,
        "value": 139.415,
        "_internal_originalTime": 1689748293000
    },
    {
        "time": 1689748294000,
        "value": 139.418,
        "_internal_originalTime": 1689748294000
    },
    {
        "time": 1689748296000,
        "value": 139.416,
        "_internal_originalTime": 1689748296000
    },
    {
        "time": 1689748297000,
        "value": 139.414,
        "_internal_originalTime": 1689748297000
    },
    {
        "time": 1689748298000,
        "value": 139.41,
        "_internal_originalTime": 1689748298000
    },
    {
        "time": 1689748300000,
        "value": 139.4,
        "_internal_originalTime": 1689748300000
    },
    {
        "time": 1689748301000,
        "value": 139.402,
        "_internal_originalTime": 1689748301000
    },
    {
        "time": 1689748302000,
        "value": 139.407,
        "_internal_originalTime": 1689748302000
    },
    {
        "time": 1689748303000,
        "value": 139.408,
        "_internal_originalTime": 1689748303000
    },
    {
        "time": 1689748304000,
        "value": 139.405,
        "_internal_originalTime": 1689748304000
    },
    {
        "time": 1689748306000,
        "value": 139.411,
        "_internal_originalTime": 1689748306000
    },
    {
        "time": 1689748308000,
        "value": 139.409,
        "_internal_originalTime": 1689748308000
    },
    {
        "time": 1689748309000,
        "value": 139.409,
        "_internal_originalTime": 1689748309000
    },
    {
        "time": 1689748310000,
        "value": 139.413,
        "_internal_originalTime": 1689748310000
    },
    {
        "time": 1689748312000,
        "value": 139.41,
        "_internal_originalTime": 1689748312000
    },
    {
        "time": 1689748313000,
        "value": 139.409,
        "_internal_originalTime": 1689748313000
    },
    {
        "time": 1689748314000,
        "value": 139.412,
        "_internal_originalTime": 1689748314000
    },
    {
        "time": 1689748315000,
        "value": 139.41,
        "_internal_originalTime": 1689748315000
    },
    {
        "time": 1689748317000,
        "value": 139.409,
        "_internal_originalTime": 1689748317000
    },
    {
        "time": 1689748318000,
        "value": 139.418,
        "_internal_originalTime": 1689748318000
    },
    {
        "time": 1689748319000,
        "value": 139.422,
        "_internal_originalTime": 1689748319000
    },
    {
        "time": 1689748320000,
        "value": 139.421,
        "_internal_originalTime": 1689748320000
    },
    {
        "time": 1689748321000,
        "value": 139.416,
        "_internal_originalTime": 1689748321000
    },
    {
        "time": 1689748322000,
        "value": 139.417,
        "_internal_originalTime": 1689748322000
    },
    {
        "time": 1689748323000,
        "value": 139.423,
        "_internal_originalTime": 1689748323000
    },
    {
        "time": 1689748324000,
        "value": 139.428,
        "_internal_originalTime": 1689748324000
    },
    {
        "time": 1689748325000,
        "value": 139.428,
        "_internal_originalTime": 1689748325000
    },
    {
        "time": 1689748327000,
        "value": 139.432,
        "_internal_originalTime": 1689748327000
    },
    {
        "time": 1689748328000,
        "value": 139.436,
        "_internal_originalTime": 1689748328000
    },
    {
        "time": 1689748329000,
        "value": 139.436,
        "_internal_originalTime": 1689748329000
    },
    {
        "time": 1689748330000,
        "value": 139.435,
        "_internal_originalTime": 1689748330000
    },
    {
        "time": 1689748332000,
        "value": 139.435,
        "_internal_originalTime": 1689748332000
    },
    {
        "time": 1689748333000,
        "value": 139.434,
        "_internal_originalTime": 1689748333000
    },
    {
        "time": 1689748335000,
        "value": 139.437,
        "_internal_originalTime": 1689748335000
    },
    {
        "time": 1689748336000,
        "value": 139.435,
        "_internal_originalTime": 1689748336000
    },
    {
        "time": 1689748337000,
        "value": 139.435,
        "_internal_originalTime": 1689748337000
    },
    {
        "time": 1689748338000,
        "value": 139.435,
        "_internal_originalTime": 1689748338000
    },
    {
        "time": 1689748339000,
        "value": 139.434,
        "_internal_originalTime": 1689748339000
    },
    {
        "time": 1689748341000,
        "value": 139.433,
        "_internal_originalTime": 1689748341000
    },
    {
        "time": 1689748342000,
        "value": 139.435,
        "_internal_originalTime": 1689748342000
    },
    {
        "time": 1689748343000,
        "value": 139.435,
        "_internal_originalTime": 1689748343000
    },
    {
        "time": 1689748344000,
        "value": 139.439,
        "_internal_originalTime": 1689748344000
    },
    {
        "time": 1689748345000,
        "value": 139.439,
        "_internal_originalTime": 1689748345000
    },
    {
        "time": 1689748346000,
        "value": 139.44,
        "_internal_originalTime": 1689748346000
    },
    {
        "time": 1689748348000,
        "value": 139.44,
        "_internal_originalTime": 1689748348000
    },
    {
        "time": 1689748349000,
        "value": 139.445,
        "_internal_originalTime": 1689748349000
    },
    {
        "time": 1689748350000,
        "value": 139.434,
        "_internal_originalTime": 1689748350000
    },
    {
        "time": 1689748351000,
        "value": 139.44,
        "_internal_originalTime": 1689748351000
    },
    {
        "time": 1689748352000,
        "value": 139.445,
        "_internal_originalTime": 1689748352000
    },
    {
        "time": 1689748353000,
        "value": 139.44,
        "_internal_originalTime": 1689748353000
    },
    {
        "time": 1689748354000,
        "value": 139.44,
        "_internal_originalTime": 1689748354000
    },
    {
        "time": 1689748355000,
        "value": 139.438,
        "_internal_originalTime": 1689748355000
    },
    {
        "time": 1689748357000,
        "value": 139.438,
        "_internal_originalTime": 1689748357000
    },
    {
        "time": 1689748358000,
        "value": 139.44,
        "_internal_originalTime": 1689748358000
    },
    {
        "time": 1689748359000,
        "value": 139.437,
        "_internal_originalTime": 1689748359000
    },
    {
        "time": 1689748360000,
        "value": 139.437,
        "_internal_originalTime": 1689748360000
    },
    {
        "time": 1689748361000,
        "value": 139.443,
        "_internal_originalTime": 1689748361000
    },
    {
        "time": 1689748362000,
        "value": 139.441,
        "_internal_originalTime": 1689748362000
    },
    {
        "time": 1689748363000,
        "value": 139.441,
        "_internal_originalTime": 1689748363000
    },
    {
        "time": 1689748364000,
        "value": 139.44,
        "_internal_originalTime": 1689748364000
    },
    {
        "time": 1689748366000,
        "value": 139.438,
        "_internal_originalTime": 1689748366000
    },
    {
        "time": 1689748367000,
        "value": 139.44,
        "_internal_originalTime": 1689748367000
    },
    {
        "time": 1689748368000,
        "value": 139.437,
        "_internal_originalTime": 1689748368000
    },
    {
        "time": 1689748369000,
        "value": 139.437,
        "_internal_originalTime": 1689748369000
    },
    {
        "time": 1689748370000,
        "value": 139.435,
        "_internal_originalTime": 1689748370000
    },
    {
        "time": 1689748371000,
        "value": 139.439,
        "_internal_originalTime": 1689748371000
    },
    {
        "time": 1689748372000,
        "value": 139.439,
        "_internal_originalTime": 1689748372000
    },
    {
        "time": 1689748374000,
        "value": 139.441,
        "_internal_originalTime": 1689748374000
    },
    {
        "time": 1689748375000,
        "value": 139.44,
        "_internal_originalTime": 1689748375000
    },
    {
        "time": 1689748377000,
        "value": 139.441,
        "_internal_originalTime": 1689748377000
    },
    {
        "time": 1689748378000,
        "value": 139.437,
        "_internal_originalTime": 1689748378000
    },
    {
        "time": 1689748379000,
        "value": 139.443,
        "_internal_originalTime": 1689748379000
    },
    {
        "time": 1689748380000,
        "value": 139.444,
        "_internal_originalTime": 1689748380000
    },
    {
        "time": 1689748381000,
        "value": 139.444,
        "_internal_originalTime": 1689748381000
    },
    {
        "time": 1689748382000,
        "value": 139.446,
        "_internal_originalTime": 1689748382000
    },
    {
        "time": 1689748384000,
        "value": 139.44,
        "_internal_originalTime": 1689748384000
    },
    {
        "time": 1689748386000,
        "value": 139.444,
        "_internal_originalTime": 1689748386000
    },
    {
        "time": 1689748387000,
        "value": 139.443,
        "_internal_originalTime": 1689748387000
    },
    {
        "time": 1689748388000,
        "value": 139.444,
        "_internal_originalTime": 1689748388000
    },
    {
        "time": 1689748390000,
        "value": 139.441,
        "_internal_originalTime": 1689748390000
    },
    {
        "time": 1689748391000,
        "value": 139.44,
        "_internal_originalTime": 1689748391000
    },
    {
        "time": 1689748392000,
        "value": 139.44,
        "_internal_originalTime": 1689748392000
    },
    {
        "time": 1689748394000,
        "value": 139.442,
        "_internal_originalTime": 1689748394000
    },
    {
        "time": 1689748395000,
        "value": 139.435,
        "_internal_originalTime": 1689748395000
    },
    {
        "time": 1689748396000,
        "value": 139.445,
        "_internal_originalTime": 1689748396000
    },
    {
        "time": 1689748397000,
        "value": 139.445,
        "_internal_originalTime": 1689748397000
    },
    {
        "time": 1689748399000,
        "value": 139.44,
        "_internal_originalTime": 1689748399000
    },
    {
        "time": 1689748400000,
        "value": 139.44,
        "_internal_originalTime": 1689748400000
    },
    {
        "time": 1689748401000,
        "value": 139.444,
        "_internal_originalTime": 1689748401000
    },
    {
        "time": 1689748402000,
        "value": 139.443,
        "_internal_originalTime": 1689748402000
    },
    {
        "time": 1689748403000,
        "value": 139.437,
        "_internal_originalTime": 1689748403000
    },
    {
        "time": 1689748404000,
        "value": 139.438,
        "_internal_originalTime": 1689748404000
    },
    {
        "time": 1689748405000,
        "value": 139.443,
        "_internal_originalTime": 1689748405000
    },
    {
        "time": 1689748406000,
        "value": 139.445,
        "_internal_originalTime": 1689748406000
    },
    {
        "time": 1689748407000,
        "value": 139.445,
        "_internal_originalTime": 1689748407000
    },
    {
        "time": 1689748408000,
        "value": 139.443,
        "_internal_originalTime": 1689748408000
    },
    {
        "time": 1689748409000,
        "value": 139.442,
        "_internal_originalTime": 1689748409000
    },
    {
        "time": 1689748410000,
        "value": 139.445,
        "_internal_originalTime": 1689748410000
    },
    {
        "time": 1689748411000,
        "value": 139.446,
        "_internal_originalTime": 1689748411000
    },
    {
        "time": 1689748412000,
        "value": 139.44,
        "_internal_originalTime": 1689748412000
    },
    {
        "time": 1689748414000,
        "value": 139.44,
        "_internal_originalTime": 1689748414000
    },
    {
        "time": 1689748415000,
        "value": 139.442,
        "_internal_originalTime": 1689748415000
    },
    {
        "time": 1689748417000,
        "value": 139.44,
        "_internal_originalTime": 1689748417000
    },
    {
        "time": 1689748419000,
        "value": 139.436,
        "_internal_originalTime": 1689748419000
    },
    {
        "time": 1689748420000,
        "value": 139.437,
        "_internal_originalTime": 1689748420000
    },
    {
        "time": 1689748422000,
        "value": 139.436,
        "_internal_originalTime": 1689748422000
    },
    {
        "time": 1689748423000,
        "value": 139.431,
        "_internal_originalTime": 1689748423000
    },
    {
        "time": 1689748424000,
        "value": 139.44,
        "_internal_originalTime": 1689748424000
    },
    {
        "time": 1689748425000,
        "value": 139.437,
        "_internal_originalTime": 1689748425000
    },
    {
        "time": 1689748426000,
        "value": 139.434,
        "_internal_originalTime": 1689748426000
    },
    {
        "time": 1689748428000,
        "value": 139.434,
        "_internal_originalTime": 1689748428000
    },
    {
        "time": 1689748429000,
        "value": 139.436,
        "_internal_originalTime": 1689748429000
    },
    {
        "time": 1689748430000,
        "value": 139.438,
        "_internal_originalTime": 1689748430000
    },
    {
        "time": 1689748431000,
        "value": 139.438,
        "_internal_originalTime": 1689748431000
    },
    {
        "time": 1689748433000,
        "value": 139.435,
        "_internal_originalTime": 1689748433000
    },
    {
        "time": 1689748434000,
        "value": 139.429,
        "_internal_originalTime": 1689748434000
    },
    {
        "time": 1689748435000,
        "value": 139.428,
        "_internal_originalTime": 1689748435000
    },
    {
        "time": 1689748436000,
        "value": 139.432,
        "_internal_originalTime": 1689748436000
    },
    {
        "time": 1689748437000,
        "value": 139.435,
        "_internal_originalTime": 1689748437000
    },
    {
        "time": 1689748438000,
        "value": 139.433,
        "_internal_originalTime": 1689748438000
    },
    {
        "time": 1689748440000,
        "value": 139.436,
        "_internal_originalTime": 1689748440000
    },
    {
        "time": 1689748441000,
        "value": 139.439,
        "_internal_originalTime": 1689748441000
    },
    {
        "time": 1689748442000,
        "value": 139.439,
        "_internal_originalTime": 1689748442000
    },
    {
        "time": 1689748444000,
        "value": 139.437,
        "_internal_originalTime": 1689748444000
    },
    {
        "time": 1689748445000,
        "value": 139.438,
        "_internal_originalTime": 1689748445000
    },
    {
        "time": 1689748446000,
        "value": 139.445,
        "_internal_originalTime": 1689748446000
    },
    {
        "time": 1689748448000,
        "value": 139.441,
        "_internal_originalTime": 1689748448000
    },
    {
        "time": 1689748449000,
        "value": 139.438,
        "_internal_originalTime": 1689748449000
    },
    {
        "time": 1689748451000,
        "value": 139.443,
        "_internal_originalTime": 1689748451000
    },
    {
        "time": 1689748453000,
        "value": 139.446,
        "_internal_originalTime": 1689748453000
    },
    {
        "time": 1689748455000,
        "value": 139.448,
        "_internal_originalTime": 1689748455000
    },
    {
        "time": 1689748456000,
        "value": 139.442,
        "_internal_originalTime": 1689748456000
    },
    {
        "time": 1689748457000,
        "value": 139.442,
        "_internal_originalTime": 1689748457000
    },
    {
        "time": 1689748459000,
        "value": 139.448,
        "_internal_originalTime": 1689748459000
    },
    {
        "time": 1689748460000,
        "value": 139.446,
        "_internal_originalTime": 1689748460000
    },
    {
        "time": 1689748462000,
        "value": 139.448,
        "_internal_originalTime": 1689748462000
    },
    {
        "time": 1689748463000,
        "value": 139.454,
        "_internal_originalTime": 1689748463000
    },
    {
        "time": 1689748464000,
        "value": 139.451,
        "_internal_originalTime": 1689748464000
    },
    {
        "time": 1689748465000,
        "value": 139.452,
        "_internal_originalTime": 1689748465000
    },
    {
        "time": 1689748466000,
        "value": 139.447,
        "_internal_originalTime": 1689748466000
    },
    {
        "time": 1689748467000,
        "value": 139.449,
        "_internal_originalTime": 1689748467000
    },
    {
        "time": 1689748468000,
        "value": 139.446,
        "_internal_originalTime": 1689748468000
    },
    {
        "time": 1689748470000,
        "value": 139.456,
        "_internal_originalTime": 1689748470000
    },
    {
        "time": 1689748471000,
        "value": 139.461,
        "_internal_originalTime": 1689748471000
    },
    {
        "time": 1689748472000,
        "value": 139.459,
        "_internal_originalTime": 1689748472000
    },
    {
        "time": 1689748474000,
        "value": 139.461,
        "_internal_originalTime": 1689748474000
    },
    {
        "time": 1689748475000,
        "value": 139.457,
        "_internal_originalTime": 1689748475000
    },
    {
        "time": 1689748476000,
        "value": 139.464,
        "_internal_originalTime": 1689748476000
    },
    {
        "time": 1689748477000,
        "value": 139.466,
        "_internal_originalTime": 1689748477000
    },
    {
        "time": 1689748478000,
        "value": 139.468,
        "_internal_originalTime": 1689748478000
    },
    {
        "time": 1689748480000,
        "value": 139.468,
        "_internal_originalTime": 1689748480000
    },
    {
        "time": 1689748482000,
        "value": 139.472,
        "_internal_originalTime": 1689748482000
    },
    {
        "time": 1689748483000,
        "value": 139.474,
        "_internal_originalTime": 1689748483000
    },
    {
        "time": 1689748485000,
        "value": 139.472,
        "_internal_originalTime": 1689748485000
    },
    {
        "time": 1689748486000,
        "value": 139.47,
        "_internal_originalTime": 1689748486000
    },
    {
        "time": 1689748488000,
        "value": 139.472,
        "_internal_originalTime": 1689748488000
    },
    {
        "time": 1689748489000,
        "value": 139.471,
        "_internal_originalTime": 1689748489000
    },
    {
        "time": 1689748490000,
        "value": 139.469,
        "_internal_originalTime": 1689748490000
    },
    {
        "time": 1689748492000,
        "value": 139.469,
        "_internal_originalTime": 1689748492000
    },
    {
        "time": 1689748493000,
        "value": 139.465,
        "_internal_originalTime": 1689748493000
    },
    {
        "time": 1689748494000,
        "value": 139.46,
        "_internal_originalTime": 1689748494000
    },
    {
        "time": 1689748495000,
        "value": 139.463,
        "_internal_originalTime": 1689748495000
    },
    {
        "time": 1689748497000,
        "value": 139.464,
        "_internal_originalTime": 1689748497000
    },
    {
        "time": 1689748498000,
        "value": 139.46,
        "_internal_originalTime": 1689748498000
    },
    {
        "time": 1689748499000,
        "value": 139.467,
        "_internal_originalTime": 1689748499000
    },
    {
        "time": 1689748500000,
        "value": 139.467,
        "_internal_originalTime": 1689748500000
    },
    {
        "time": 1689748501000,
        "value": 139.466,
        "_internal_originalTime": 1689748501000
    },
    {
        "time": 1689748502000,
        "value": 139.465,
        "_internal_originalTime": 1689748502000
    },
    {
        "time": 1689748503000,
        "value": 139.465,
        "_internal_originalTime": 1689748503000
    },
    {
        "time": 1689748504000,
        "value": 139.458,
        "_internal_originalTime": 1689748504000
    },
    {
        "time": 1689748505000,
        "value": 139.458,
        "_internal_originalTime": 1689748505000
    },
    {
        "time": 1689748506000,
        "value": 139.456,
        "_internal_originalTime": 1689748506000
    },
    {
        "time": 1689748507000,
        "value": 139.454,
        "_internal_originalTime": 1689748507000
    },
    {
        "time": 1689748508000,
        "value": 139.454,
        "_internal_originalTime": 1689748508000
    },
    {
        "time": 1689748510000,
        "value": 139.455,
        "_internal_originalTime": 1689748510000
    },
    {
        "time": 1689748511000,
        "value": 139.455,
        "_internal_originalTime": 1689748511000
    },
    {
        "time": 1689748512000,
        "value": 139.447,
        "_internal_originalTime": 1689748512000
    },
    {
        "time": 1689748513000,
        "value": 139.452,
        "_internal_originalTime": 1689748513000
    },
    {
        "time": 1689748514000,
        "value": 139.455,
        "_internal_originalTime": 1689748514000
    },
    {
        "time": 1689748515000,
        "value": 139.452,
        "_internal_originalTime": 1689748515000
    },
    {
        "time": 1689748516000,
        "value": 139.451,
        "_internal_originalTime": 1689748516000
    },
    {
        "time": 1689748517000,
        "value": 139.445,
        "_internal_originalTime": 1689748517000
    },
    {
        "time": 1689748519000,
        "value": 139.444,
        "_internal_originalTime": 1689748519000
    },
    {
        "time": 1689748520000,
        "value": 139.443,
        "_internal_originalTime": 1689748520000
    },
    {
        "time": 1689748522000,
        "value": 139.449,
        "_internal_originalTime": 1689748522000
    },
    {
        "time": 1689748523000,
        "value": 139.446,
        "_internal_originalTime": 1689748523000
    },
    {
        "time": 1689748524000,
        "value": 139.438,
        "_internal_originalTime": 1689748524000
    },
    {
        "time": 1689748525000,
        "value": 139.442,
        "_internal_originalTime": 1689748525000
    },
    {
        "time": 1689748526000,
        "value": 139.437,
        "_internal_originalTime": 1689748526000
    },
    {
        "time": 1689748527000,
        "value": 139.434,
        "_internal_originalTime": 1689748527000
    },
    {
        "time": 1689748528000,
        "value": 139.439,
        "_internal_originalTime": 1689748528000
    },
    {
        "time": 1689748529000,
        "value": 139.436,
        "_internal_originalTime": 1689748529000
    },
    {
        "time": 1689748530000,
        "value": 139.433,
        "_internal_originalTime": 1689748530000
    },
    {
        "time": 1689748531000,
        "value": 139.446,
        "_internal_originalTime": 1689748531000
    },
    {
        "time": 1689748532000,
        "value": 139.446,
        "_internal_originalTime": 1689748532000
    },
    {
        "time": 1689748534000,
        "value": 139.432,
        "_internal_originalTime": 1689748534000
    },
    {
        "time": 1689748537000,
        "value": 139.432,
        "_internal_originalTime": 1689748537000
    },
    {
        "time": 1689748539000,
        "value": 139.437,
        "_internal_originalTime": 1689748539000
    },
    {
        "time": 1689748540000,
        "value": 139.428,
        "_internal_originalTime": 1689748540000
    },
    {
        "time": 1689748542000,
        "value": 139.433,
        "_internal_originalTime": 1689748542000
    },
    {
        "time": 1689748543000,
        "value": 139.435,
        "_internal_originalTime": 1689748543000
    },
    {
        "time": 1689748544000,
        "value": 139.435,
        "_internal_originalTime": 1689748544000
    },
    {
        "time": 1689748545000,
        "value": 139.436,
        "_internal_originalTime": 1689748545000
    },
    {
        "time": 1689748546000,
        "value": 139.429,
        "_internal_originalTime": 1689748546000
    },
    {
        "time": 1689748547000,
        "value": 139.426,
        "_internal_originalTime": 1689748547000
    },
    {
        "time": 1689748550000,
        "value": 139.425,
        "_internal_originalTime": 1689748550000
    },
    {
        "time": 1689748551000,
        "value": 139.426,
        "_internal_originalTime": 1689748551000
    },
    {
        "time": 1689748552000,
        "value": 139.422,
        "_internal_originalTime": 1689748552000
    },
    {
        "time": 1689748553000,
        "value": 139.429,
        "_internal_originalTime": 1689748553000
    },
    {
        "time": 1689748554000,
        "value": 139.425,
        "_internal_originalTime": 1689748554000
    },
    {
        "time": 1689748555000,
        "value": 139.426,
        "_internal_originalTime": 1689748555000
    },
    {
        "time": 1689748557000,
        "value": 139.427,
        "_internal_originalTime": 1689748557000
    },
    {
        "time": 1689748558000,
        "value": 139.422,
        "_internal_originalTime": 1689748558000
    },
    {
        "time": 1689748559000,
        "value": 139.425,
        "_internal_originalTime": 1689748559000
    },
    {
        "time": 1689748560000,
        "value": 139.423,
        "_internal_originalTime": 1689748560000
    },
    {
        "time": 1689748561000,
        "value": 139.426,
        "_internal_originalTime": 1689748561000
    },
    {
        "time": 1689748562000,
        "value": 139.429,
        "_internal_originalTime": 1689748562000
    },
    {
        "time": 1689748563000,
        "value": 139.43,
        "_internal_originalTime": 1689748563000
    },
    {
        "time": 1689748564000,
        "value": 139.435,
        "_internal_originalTime": 1689748564000
    },
    {
        "time": 1689748565000,
        "value": 139.439,
        "_internal_originalTime": 1689748565000
    },
    {
        "time": 1689748566000,
        "value": 139.438,
        "_internal_originalTime": 1689748566000
    },
    {
        "time": 1689748567000,
        "value": 139.434,
        "_internal_originalTime": 1689748567000
    },
    {
        "time": 1689748569000,
        "value": 139.433,
        "_internal_originalTime": 1689748569000
    },
    {
        "time": 1689748570000,
        "value": 139.43,
        "_internal_originalTime": 1689748570000
    },
    {
        "time": 1689748571000,
        "value": 139.424,
        "_internal_originalTime": 1689748571000
    },
    {
        "time": 1689748572000,
        "value": 139.43,
        "_internal_originalTime": 1689748572000
    },
    {
        "time": 1689748574000,
        "value": 139.429,
        "_internal_originalTime": 1689748574000
    },
    {
        "time": 1689748575000,
        "value": 139.422,
        "_internal_originalTime": 1689748575000
    },
    {
        "time": 1689748576000,
        "value": 139.423,
        "_internal_originalTime": 1689748576000
    },
    {
        "time": 1689748578000,
        "value": 139.428,
        "_internal_originalTime": 1689748578000
    },
    {
        "time": 1689748579000,
        "value": 139.427,
        "_internal_originalTime": 1689748579000
    },
    {
        "time": 1689748580000,
        "value": 139.43,
        "_internal_originalTime": 1689748580000
    },
    {
        "time": 1689748582000,
        "value": 139.428,
        "_internal_originalTime": 1689748582000
    },
    {
        "time": 1689748583000,
        "value": 139.43,
        "_internal_originalTime": 1689748583000
    },
    {
        "time": 1689748585000,
        "value": 139.427,
        "_internal_originalTime": 1689748585000
    },
    {
        "time": 1689748586000,
        "value": 139.425,
        "_internal_originalTime": 1689748586000
    },
    {
        "time": 1689748587000,
        "value": 139.427,
        "_internal_originalTime": 1689748587000
    },
    {
        "time": 1689748589000,
        "value": 139.426,
        "_internal_originalTime": 1689748589000
    },
    {
        "time": 1689748590000,
        "value": 139.432,
        "_internal_originalTime": 1689748590000
    },
    {
        "time": 1689748591000,
        "value": 139.428,
        "_internal_originalTime": 1689748591000
    },
    {
        "time": 1689748592000,
        "value": 139.417,
        "_internal_originalTime": 1689748592000
    },
    {
        "time": 1689748594000,
        "value": 139.422,
        "_internal_originalTime": 1689748594000
    },
    {
        "time": 1689748595000,
        "value": 139.423,
        "_internal_originalTime": 1689748595000
    },
    {
        "time": 1689748597000,
        "value": 139.424,
        "_internal_originalTime": 1689748597000
    },
    {
        "time": 1689748598000,
        "value": 139.426,
        "_internal_originalTime": 1689748598000
    },
    {
        "time": 1689748600000,
        "value": 139.429,
        "_internal_originalTime": 1689748600000
    },
    {
        "time": 1689748602000,
        "value": 139.44,
        "_internal_originalTime": 1689748602000
    },
    {
        "time": 1689748603000,
        "value": 139.439,
        "_internal_originalTime": 1689748603000
    },
    {
        "time": 1689748604000,
        "value": 139.431,
        "_internal_originalTime": 1689748604000
    },
    {
        "time": 1689748605000,
        "value": 139.43,
        "_internal_originalTime": 1689748605000
    },
    {
        "time": 1689748606000,
        "value": 139.429,
        "_internal_originalTime": 1689748606000
    },
    {
        "time": 1689748607000,
        "value": 139.434,
        "_internal_originalTime": 1689748607000
    },
    {
        "time": 1689748608000,
        "value": 139.437,
        "_internal_originalTime": 1689748608000
    },
    {
        "time": 1689748609000,
        "value": 139.44,
        "_internal_originalTime": 1689748609000
    },
    {
        "time": 1689748611000,
        "value": 139.437,
        "_internal_originalTime": 1689748611000
    },
    {
        "time": 1689748612000,
        "value": 139.442,
        "_internal_originalTime": 1689748612000
    },
    {
        "time": 1689748613000,
        "value": 139.44,
        "_internal_originalTime": 1689748613000
    },
    {
        "time": 1689748614000,
        "value": 139.447,
        "_internal_originalTime": 1689748614000
    },
    {
        "time": 1689748616000,
        "value": 139.447,
        "_internal_originalTime": 1689748616000
    },
    {
        "time": 1689748617000,
        "value": 139.456,
        "_internal_originalTime": 1689748617000
    },
    {
        "time": 1689748618000,
        "value": 139.454,
        "_internal_originalTime": 1689748618000
    },
    {
        "time": 1689748619000,
        "value": 139.454,
        "_internal_originalTime": 1689748619000
    },
    {
        "time": 1689748620000,
        "value": 139.449,
        "_internal_originalTime": 1689748620000
    },
    {
        "time": 1689748621000,
        "value": 139.454,
        "_internal_originalTime": 1689748621000
    },
    {
        "time": 1689748622000,
        "value": 139.453,
        "_internal_originalTime": 1689748622000
    },
    {
        "time": 1689748623000,
        "value": 139.45,
        "_internal_originalTime": 1689748623000
    },
    {
        "time": 1689748624000,
        "value": 139.45,
        "_internal_originalTime": 1689748624000
    },
    {
        "time": 1689748625000,
        "value": 139.456,
        "_internal_originalTime": 1689748625000
    },
    {
        "time": 1689748628000,
        "value": 139.462,
        "_internal_originalTime": 1689748628000
    },
    {
        "time": 1689748629000,
        "value": 139.456,
        "_internal_originalTime": 1689748629000
    },
    {
        "time": 1689748630000,
        "value": 139.457,
        "_internal_originalTime": 1689748630000
    },
    {
        "time": 1689748631000,
        "value": 139.453,
        "_internal_originalTime": 1689748631000
    },
    {
        "time": 1689748632000,
        "value": 139.456,
        "_internal_originalTime": 1689748632000
    },
    {
        "time": 1689748633000,
        "value": 139.456,
        "_internal_originalTime": 1689748633000
    },
    {
        "time": 1689748636000,
        "value": 139.454,
        "_internal_originalTime": 1689748636000
    },
    {
        "time": 1689748637000,
        "value": 139.456,
        "_internal_originalTime": 1689748637000
    },
    {
        "time": 1689748638000,
        "value": 139.457,
        "_internal_originalTime": 1689748638000
    },
    {
        "time": 1689748639000,
        "value": 139.453,
        "_internal_originalTime": 1689748639000
    },
    {
        "time": 1689748641000,
        "value": 139.456,
        "_internal_originalTime": 1689748641000
    },
    {
        "time": 1689748642000,
        "value": 139.451,
        "_internal_originalTime": 1689748642000
    },
    {
        "time": 1689748644000,
        "value": 139.453,
        "_internal_originalTime": 1689748644000
    },
    {
        "time": 1689748645000,
        "value": 139.452,
        "_internal_originalTime": 1689748645000
    },
    {
        "time": 1689748646000,
        "value": 139.46,
        "_internal_originalTime": 1689748646000
    },
    {
        "time": 1689748647000,
        "value": 139.46,
        "_internal_originalTime": 1689748647000
    },
    {
        "time": 1689748648000,
        "value": 139.455,
        "_internal_originalTime": 1689748648000
    },
    {
        "time": 1689748649000,
        "value": 139.456,
        "_internal_originalTime": 1689748649000
    },
    {
        "time": 1689748650000,
        "value": 139.456,
        "_internal_originalTime": 1689748650000
    },
    {
        "time": 1689748651000,
        "value": 139.459,
        "_internal_originalTime": 1689748651000
    },
    {
        "time": 1689748652000,
        "value": 139.459,
        "_internal_originalTime": 1689748652000
    },
    {
        "time": 1689748654000,
        "value": 139.455,
        "_internal_originalTime": 1689748654000
    },
    {
        "time": 1689748656000,
        "value": 139.461,
        "_internal_originalTime": 1689748656000
    },
    {
        "time": 1689748657000,
        "value": 139.465,
        "_internal_originalTime": 1689748657000
    },
    {
        "time": 1689748658000,
        "value": 139.466,
        "_internal_originalTime": 1689748658000
    },
    {
        "time": 1689748659000,
        "value": 139.466,
        "_internal_originalTime": 1689748659000
    },
    {
        "time": 1689748661000,
        "value": 139.466,
        "_internal_originalTime": 1689748661000
    },
    {
        "time": 1689748662000,
        "value": 139.463,
        "_internal_originalTime": 1689748662000
    },
    {
        "time": 1689748663000,
        "value": 139.465,
        "_internal_originalTime": 1689748663000
    },
    {
        "time": 1689748664000,
        "value": 139.469,
        "_internal_originalTime": 1689748664000
    },
    {
        "time": 1689748665000,
        "value": 139.469,
        "_internal_originalTime": 1689748665000
    },
    {
        "time": 1689748666000,
        "value": 139.462,
        "_internal_originalTime": 1689748666000
    },
    {
        "time": 1689748667000,
        "value": 139.466,
        "_internal_originalTime": 1689748667000
    },
    {
        "time": 1689748668000,
        "value": 139.466,
        "_internal_originalTime": 1689748668000
    },
    {
        "time": 1689748669000,
        "value": 139.464,
        "_internal_originalTime": 1689748669000
    },
    {
        "time": 1689748671000,
        "value": 139.467,
        "_internal_originalTime": 1689748671000
    },
    {
        "time": 1689748672000,
        "value": 139.466,
        "_internal_originalTime": 1689748672000
    },
    {
        "time": 1689748673000,
        "value": 139.465,
        "_internal_originalTime": 1689748673000
    },
    {
        "time": 1689748674000,
        "value": 139.466,
        "_internal_originalTime": 1689748674000
    },
    {
        "time": 1689748676000,
        "value": 139.469,
        "_internal_originalTime": 1689748676000
    },
    {
        "time": 1689748677000,
        "value": 139.466,
        "_internal_originalTime": 1689748677000
    },
    {
        "time": 1689748678000,
        "value": 139.473,
        "_internal_originalTime": 1689748678000
    },
    {
        "time": 1689748679000,
        "value": 139.473,
        "_internal_originalTime": 1689748679000
    },
    {
        "time": 1689748681000,
        "value": 139.481,
        "_internal_originalTime": 1689748681000
    },
    {
        "time": 1689748682000,
        "value": 139.474,
        "_internal_originalTime": 1689748682000
    },
    {
        "time": 1689748684000,
        "value": 139.474,
        "_internal_originalTime": 1689748684000
    },
    {
        "time": 1689748685000,
        "value": 139.471,
        "_internal_originalTime": 1689748685000
    },
    {
        "time": 1689748686000,
        "value": 139.477,
        "_internal_originalTime": 1689748686000
    },
    {
        "time": 1689748687000,
        "value": 139.476,
        "_internal_originalTime": 1689748687000
    },
    {
        "time": 1689748688000,
        "value": 139.475,
        "_internal_originalTime": 1689748688000
    },
    {
        "time": 1689748690000,
        "value": 139.478,
        "_internal_originalTime": 1689748690000
    },
    {
        "time": 1689748692000,
        "value": 139.475,
        "_internal_originalTime": 1689748692000
    },
    {
        "time": 1689748693000,
        "value": 139.478,
        "_internal_originalTime": 1689748693000
    },
    {
        "time": 1689748694000,
        "value": 139.473,
        "_internal_originalTime": 1689748694000
    },
    {
        "time": 1689748695000,
        "value": 139.475,
        "_internal_originalTime": 1689748695000
    },
    {
        "time": 1689748696000,
        "value": 139.477,
        "_internal_originalTime": 1689748696000
    },
    {
        "time": 1689748698000,
        "value": 139.484,
        "_internal_originalTime": 1689748698000
    },
    {
        "time": 1689748699000,
        "value": 139.481,
        "_internal_originalTime": 1689748699000
    },
    {
        "time": 1689748700000,
        "value": 139.477,
        "_internal_originalTime": 1689748700000
    },
    {
        "time": 1689748702000,
        "value": 139.482,
        "_internal_originalTime": 1689748702000
    },
    {
        "time": 1689748703000,
        "value": 139.482,
        "_internal_originalTime": 1689748703000
    },
    {
        "time": 1689748705000,
        "value": 139.482,
        "_internal_originalTime": 1689748705000
    },
    {
        "time": 1689748707000,
        "value": 139.48,
        "_internal_originalTime": 1689748707000
    },
    {
        "time": 1689748709000,
        "value": 139.481,
        "_internal_originalTime": 1689748709000
    },
    {
        "time": 1689748711000,
        "value": 139.484,
        "_internal_originalTime": 1689748711000
    },
    {
        "time": 1689748712000,
        "value": 139.484,
        "_internal_originalTime": 1689748712000
    },
    {
        "time": 1689748713000,
        "value": 139.481,
        "_internal_originalTime": 1689748713000
    },
    {
        "time": 1689748714000,
        "value": 139.481,
        "_internal_originalTime": 1689748714000
    },
    {
        "time": 1689748715000,
        "value": 139.484,
        "_internal_originalTime": 1689748715000
    },
    {
        "time": 1689748716000,
        "value": 139.482,
        "_internal_originalTime": 1689748716000
    },
    {
        "time": 1689748718000,
        "value": 139.485,
        "_internal_originalTime": 1689748718000
    },
    {
        "time": 1689748719000,
        "value": 139.482,
        "_internal_originalTime": 1689748719000
    },
    {
        "time": 1689748720000,
        "value": 139.484,
        "_internal_originalTime": 1689748720000
    },
    {
        "time": 1689748721000,
        "value": 139.484,
        "_internal_originalTime": 1689748721000
    },
    {
        "time": 1689748723000,
        "value": 139.484,
        "_internal_originalTime": 1689748723000
    },
    {
        "time": 1689748725000,
        "value": 139.482,
        "_internal_originalTime": 1689748725000
    },
    {
        "time": 1689748726000,
        "value": 139.483,
        "_internal_originalTime": 1689748726000
    },
    {
        "time": 1689748728000,
        "value": 139.476,
        "_internal_originalTime": 1689748728000
    },
    {
        "time": 1689748729000,
        "value": 139.473,
        "_internal_originalTime": 1689748729000
    },
    {
        "time": 1689748730000,
        "value": 139.475,
        "_internal_originalTime": 1689748730000
    },
    {
        "time": 1689748731000,
        "value": 139.464,
        "_internal_originalTime": 1689748731000
    },
    {
        "time": 1689748732000,
        "value": 139.468,
        "_internal_originalTime": 1689748732000
    },
    {
        "time": 1689748733000,
        "value": 139.468,
        "_internal_originalTime": 1689748733000
    },
    {
        "time": 1689748735000,
        "value": 139.468,
        "_internal_originalTime": 1689748735000
    },
    {
        "time": 1689748736000,
        "value": 139.467,
        "_internal_originalTime": 1689748736000
    },
    {
        "time": 1689748737000,
        "value": 139.469,
        "_internal_originalTime": 1689748737000
    },
    {
        "time": 1689748738000,
        "value": 139.47,
        "_internal_originalTime": 1689748738000
    },
    {
        "time": 1689748740000,
        "value": 139.475,
        "_internal_originalTime": 1689748740000
    },
    {
        "time": 1689748741000,
        "value": 139.466,
        "_internal_originalTime": 1689748741000
    },
    {
        "time": 1689748742000,
        "value": 139.469,
        "_internal_originalTime": 1689748742000
    },
    {
        "time": 1689748744000,
        "value": 139.467,
        "_internal_originalTime": 1689748744000
    },
    {
        "time": 1689748745000,
        "value": 139.466,
        "_internal_originalTime": 1689748745000
    },
    {
        "time": 1689748747000,
        "value": 139.468,
        "_internal_originalTime": 1689748747000
    },
    {
        "time": 1689748748000,
        "value": 139.466,
        "_internal_originalTime": 1689748748000
    },
    {
        "time": 1689748749000,
        "value": 139.465,
        "_internal_originalTime": 1689748749000
    },
    {
        "time": 1689748750000,
        "value": 139.463,
        "_internal_originalTime": 1689748750000
    },
    {
        "time": 1689748751000,
        "value": 139.462,
        "_internal_originalTime": 1689748751000
    },
    {
        "time": 1689748752000,
        "value": 139.465,
        "_internal_originalTime": 1689748752000
    },
    {
        "time": 1689748754000,
        "value": 139.464,
        "_internal_originalTime": 1689748754000
    },
    {
        "time": 1689748756000,
        "value": 139.466,
        "_internal_originalTime": 1689748756000
    },
    {
        "time": 1689748757000,
        "value": 139.466,
        "_internal_originalTime": 1689748757000
    },
    {
        "time": 1689748758000,
        "value": 139.467,
        "_internal_originalTime": 1689748758000
    },
    {
        "time": 1689748759000,
        "value": 139.464,
        "_internal_originalTime": 1689748759000
    },
    {
        "time": 1689748761000,
        "value": 139.468,
        "_internal_originalTime": 1689748761000
    },
    {
        "time": 1689748762000,
        "value": 139.467,
        "_internal_originalTime": 1689748762000
    },
    {
        "time": 1689748763000,
        "value": 139.467,
        "_internal_originalTime": 1689748763000
    },
    {
        "time": 1689748764000,
        "value": 139.472,
        "_internal_originalTime": 1689748764000
    },
    {
        "time": 1689748765000,
        "value": 139.473,
        "_internal_originalTime": 1689748765000
    },
    {
        "time": 1689748766000,
        "value": 139.475,
        "_internal_originalTime": 1689748766000
    },
    {
        "time": 1689748767000,
        "value": 139.473,
        "_internal_originalTime": 1689748767000
    },
    {
        "time": 1689748769000,
        "value": 139.484,
        "_internal_originalTime": 1689748769000
    },
    {
        "time": 1689748770000,
        "value": 139.483,
        "_internal_originalTime": 1689748770000
    },
    {
        "time": 1689748771000,
        "value": 139.477,
        "_internal_originalTime": 1689748771000
    },
    {
        "time": 1689748772000,
        "value": 139.477,
        "_internal_originalTime": 1689748772000
    },
    {
        "time": 1689748773000,
        "value": 139.477,
        "_internal_originalTime": 1689748773000
    },
    {
        "time": 1689748774000,
        "value": 139.479,
        "_internal_originalTime": 1689748774000
    },
    {
        "time": 1689748775000,
        "value": 139.48,
        "_internal_originalTime": 1689748775000
    },
    {
        "time": 1689748777000,
        "value": 139.478,
        "_internal_originalTime": 1689748777000
    },
    {
        "time": 1689748779000,
        "value": 139.481,
        "_internal_originalTime": 1689748779000
    },
    {
        "time": 1689748780000,
        "value": 139.481,
        "_internal_originalTime": 1689748780000
    },
    {
        "time": 1689748781000,
        "value": 139.48,
        "_internal_originalTime": 1689748781000
    },
    {
        "time": 1689748782000,
        "value": 139.48,
        "_internal_originalTime": 1689748782000
    },
    {
        "time": 1689748783000,
        "value": 139.481,
        "_internal_originalTime": 1689748783000
    },
    {
        "time": 1689748784000,
        "value": 139.483,
        "_internal_originalTime": 1689748784000
    },
    {
        "time": 1689748785000,
        "value": 139.484,
        "_internal_originalTime": 1689748785000
    },
    {
        "time": 1689748786000,
        "value": 139.486,
        "_internal_originalTime": 1689748786000
    },
    {
        "time": 1689748788000,
        "value": 139.485,
        "_internal_originalTime": 1689748788000
    },
    {
        "time": 1689748789000,
        "value": 139.483,
        "_internal_originalTime": 1689748789000
    },
    {
        "time": 1689748790000,
        "value": 139.48,
        "_internal_originalTime": 1689748790000
    },
    {
        "time": 1689748791000,
        "value": 139.484,
        "_internal_originalTime": 1689748791000
    },
    {
        "time": 1689748792000,
        "value": 139.484,
        "_internal_originalTime": 1689748792000
    },
    {
        "time": 1689748794000,
        "value": 139.482,
        "_internal_originalTime": 1689748794000
    },
    {
        "time": 1689748795000,
        "value": 139.485,
        "_internal_originalTime": 1689748795000
    },
    {
        "time": 1689748796000,
        "value": 139.484,
        "_internal_originalTime": 1689748796000
    },
    {
        "time": 1689748797000,
        "value": 139.484,
        "_internal_originalTime": 1689748797000
    },
    {
        "time": 1689748799000,
        "value": 139.483,
        "_internal_originalTime": 1689748799000
    },
    {
        "time": 1689748800000,
        "value": 139.485,
        "_internal_originalTime": 1689748800000
    },
    {
        "time": 1689748801000,
        "value": 139.49,
        "_internal_originalTime": 1689748801000
    },
    {
        "time": 1689748802000,
        "value": 139.489,
        "_internal_originalTime": 1689748802000
    },
    {
        "time": 1689748806000,
        "value": 139.484,
        "_internal_originalTime": 1689748806000
    },
    {
        "time": 1689748807000,
        "value": 139.487,
        "_internal_originalTime": 1689748807000
    },
    {
        "time": 1689748808000,
        "value": 139.488,
        "_internal_originalTime": 1689748808000
    },
    {
        "time": 1689748809000,
        "value": 139.488,
        "_internal_originalTime": 1689748809000
    },
    {
        "time": 1689748811000,
        "value": 139.487,
        "_internal_originalTime": 1689748811000
    },
    {
        "time": 1689748812000,
        "value": 139.489,
        "_internal_originalTime": 1689748812000
    },
    {
        "time": 1689748813000,
        "value": 139.489,
        "_internal_originalTime": 1689748813000
    },
    {
        "time": 1689748814000,
        "value": 139.488,
        "_internal_originalTime": 1689748814000
    },
    {
        "time": 1689748815000,
        "value": 139.487,
        "_internal_originalTime": 1689748815000
    },
    {
        "time": 1689748816000,
        "value": 139.489,
        "_internal_originalTime": 1689748816000
    },
    {
        "time": 1689748817000,
        "value": 139.492,
        "_internal_originalTime": 1689748817000
    },
    {
        "time": 1689748818000,
        "value": 139.488,
        "_internal_originalTime": 1689748818000
    },
    {
        "time": 1689748819000,
        "value": 139.491,
        "_internal_originalTime": 1689748819000
    },
    {
        "time": 1689748820000,
        "value": 139.484,
        "_internal_originalTime": 1689748820000
    },
    {
        "time": 1689748822000,
        "value": 139.489,
        "_internal_originalTime": 1689748822000
    },
    {
        "time": 1689748824000,
        "value": 139.487,
        "_internal_originalTime": 1689748824000
    },
    {
        "time": 1689748825000,
        "value": 139.492,
        "_internal_originalTime": 1689748825000
    },
    {
        "time": 1689748826000,
        "value": 139.493,
        "_internal_originalTime": 1689748826000
    },
    {
        "time": 1689748828000,
        "value": 139.486,
        "_internal_originalTime": 1689748828000
    },
    {
        "time": 1689748830000,
        "value": 139.488,
        "_internal_originalTime": 1689748830000
    },
    {
        "time": 1689748831000,
        "value": 139.485,
        "_internal_originalTime": 1689748831000
    },
    {
        "time": 1689748833000,
        "value": 139.488,
        "_internal_originalTime": 1689748833000
    },
    {
        "time": 1689748834000,
        "value": 139.485,
        "_internal_originalTime": 1689748834000
    },
    {
        "time": 1689748835000,
        "value": 139.49,
        "_internal_originalTime": 1689748835000
    },
    {
        "time": 1689748837000,
        "value": 139.49,
        "_internal_originalTime": 1689748837000
    },
    {
        "time": 1689748839000,
        "value": 139.49,
        "_internal_originalTime": 1689748839000
    },
    {
        "time": 1689748840000,
        "value": 139.493,
        "_internal_originalTime": 1689748840000
    },
    {
        "time": 1689748841000,
        "value": 139.493,
        "_internal_originalTime": 1689748841000
    },
    {
        "time": 1689748842000,
        "value": 139.493,
        "_internal_originalTime": 1689748842000
    },
    {
        "time": 1689748843000,
        "value": 139.495,
        "_internal_originalTime": 1689748843000
    },
    {
        "time": 1689748845000,
        "value": 139.496,
        "_internal_originalTime": 1689748845000
    },
    {
        "time": 1689748846000,
        "value": 139.493,
        "_internal_originalTime": 1689748846000
    },
    {
        "time": 1689748847000,
        "value": 139.489,
        "_internal_originalTime": 1689748847000
    },
    {
        "time": 1689748849000,
        "value": 139.491,
        "_internal_originalTime": 1689748849000
    },
    {
        "time": 1689748850000,
        "value": 139.491,
        "_internal_originalTime": 1689748850000
    },
    {
        "time": 1689748851000,
        "value": 139.491,
        "_internal_originalTime": 1689748851000
    },
    {
        "time": 1689748852000,
        "value": 139.492,
        "_internal_originalTime": 1689748852000
    },
    {
        "time": 1689748854000,
        "value": 139.493,
        "_internal_originalTime": 1689748854000
    },
    {
        "time": 1689748855000,
        "value": 139.518,
        "_internal_originalTime": 1689748855000
    },
    {
        "time": 1689748856000,
        "value": 139.528,
        "_internal_originalTime": 1689748856000
    },
    {
        "time": 1689748858000,
        "value": 139.536,
        "_internal_originalTime": 1689748858000
    },
    {
        "time": 1689748859000,
        "value": 139.53,
        "_internal_originalTime": 1689748859000
    },
    {
        "time": 1689748860000,
        "value": 139.529,
        "_internal_originalTime": 1689748860000
    },
    {
        "time": 1689748861000,
        "value": 139.543,
        "_internal_originalTime": 1689748861000
    },
    {
        "time": 1689748862000,
        "value": 139.542,
        "_internal_originalTime": 1689748862000
    },
    {
        "time": 1689748865000,
        "value": 139.542,
        "_internal_originalTime": 1689748865000
    },
    {
        "time": 1689748866000,
        "value": 139.548,
        "_internal_originalTime": 1689748866000
    },
    {
        "time": 1689748867000,
        "value": 139.545,
        "_internal_originalTime": 1689748867000
    },
    {
        "time": 1689748868000,
        "value": 139.547,
        "_internal_originalTime": 1689748868000
    },
    {
        "time": 1689748869000,
        "value": 139.55,
        "_internal_originalTime": 1689748869000
    },
    {
        "time": 1689748870000,
        "value": 139.554,
        "_internal_originalTime": 1689748870000
    },
    {
        "time": 1689748872000,
        "value": 139.542,
        "_internal_originalTime": 1689748872000
    },
    {
        "time": 1689748873000,
        "value": 139.554,
        "_internal_originalTime": 1689748873000
    },
    {
        "time": 1689748874000,
        "value": 139.556,
        "_internal_originalTime": 1689748874000
    },
    {
        "time": 1689748875000,
        "value": 139.555,
        "_internal_originalTime": 1689748875000
    },
    {
        "time": 1689748876000,
        "value": 139.558,
        "_internal_originalTime": 1689748876000
    },
    {
        "time": 1689748878000,
        "value": 139.555,
        "_internal_originalTime": 1689748878000
    },
    {
        "time": 1689748880000,
        "value": 139.554,
        "_internal_originalTime": 1689748880000
    },
    {
        "time": 1689748881000,
        "value": 139.556,
        "_internal_originalTime": 1689748881000
    },
    {
        "time": 1689748882000,
        "value": 139.562,
        "_internal_originalTime": 1689748882000
    },
    {
        "time": 1689748884000,
        "value": 139.566,
        "_internal_originalTime": 1689748884000
    },
    {
        "time": 1689748885000,
        "value": 139.563,
        "_internal_originalTime": 1689748885000
    },
    {
        "time": 1689748886000,
        "value": 139.559,
        "_internal_originalTime": 1689748886000
    },
    {
        "time": 1689748888000,
        "value": 139.56,
        "_internal_originalTime": 1689748888000
    },
    {
        "time": 1689748889000,
        "value": 139.573,
        "_internal_originalTime": 1689748889000
    },
    {
        "time": 1689748890000,
        "value": 139.572,
        "_internal_originalTime": 1689748890000
    },
    {
        "time": 1689748891000,
        "value": 139.568,
        "_internal_originalTime": 1689748891000
    },
    {
        "time": 1689748893000,
        "value": 139.574,
        "_internal_originalTime": 1689748893000
    },
    {
        "time": 1689748894000,
        "value": 139.573,
        "_internal_originalTime": 1689748894000
    },
    {
        "time": 1689748895000,
        "value": 139.57,
        "_internal_originalTime": 1689748895000
    },
    {
        "time": 1689748897000,
        "value": 139.57,
        "_internal_originalTime": 1689748897000
    },
    {
        "time": 1689748898000,
        "value": 139.564,
        "_internal_originalTime": 1689748898000
    },
    {
        "time": 1689748899000,
        "value": 139.569,
        "_internal_originalTime": 1689748899000
    },
    {
        "time": 1689748900000,
        "value": 139.559,
        "_internal_originalTime": 1689748900000
    },
    {
        "time": 1689748901000,
        "value": 139.565,
        "_internal_originalTime": 1689748901000
    },
    {
        "time": 1689748902000,
        "value": 139.565,
        "_internal_originalTime": 1689748902000
    },
    {
        "time": 1689748903000,
        "value": 139.564,
        "_internal_originalTime": 1689748903000
    },
    {
        "time": 1689748905000,
        "value": 139.56,
        "_internal_originalTime": 1689748905000
    },
    {
        "time": 1689748906000,
        "value": 139.559,
        "_internal_originalTime": 1689748906000
    },
    {
        "time": 1689748908000,
        "value": 139.56,
        "_internal_originalTime": 1689748908000
    },
    {
        "time": 1689748909000,
        "value": 139.559,
        "_internal_originalTime": 1689748909000
    },
    {
        "time": 1689748910000,
        "value": 139.566,
        "_internal_originalTime": 1689748910000
    },
    {
        "time": 1689748911000,
        "value": 139.56,
        "_internal_originalTime": 1689748911000
    },
    {
        "time": 1689748912000,
        "value": 139.565,
        "_internal_originalTime": 1689748912000
    },
    {
        "time": 1689748914000,
        "value": 139.564,
        "_internal_originalTime": 1689748914000
    },
    {
        "time": 1689748916000,
        "value": 139.559,
        "_internal_originalTime": 1689748916000
    },
    {
        "time": 1689748917000,
        "value": 139.559,
        "_internal_originalTime": 1689748917000
    },
    {
        "time": 1689748919000,
        "value": 139.561,
        "_internal_originalTime": 1689748919000
    },
    {
        "time": 1689748920000,
        "value": 139.561,
        "_internal_originalTime": 1689748920000
    },
    {
        "time": 1689748921000,
        "value": 139.54,
        "_internal_originalTime": 1689748921000
    },
    {
        "time": 1689748923000,
        "value": 139.559,
        "_internal_originalTime": 1689748923000
    },
    {
        "time": 1689748924000,
        "value": 139.565,
        "_internal_originalTime": 1689748924000
    },
    {
        "time": 1689748925000,
        "value": 139.564,
        "_internal_originalTime": 1689748925000
    },
    {
        "time": 1689748926000,
        "value": 139.566,
        "_internal_originalTime": 1689748926000
    },
    {
        "time": 1689748927000,
        "value": 139.563,
        "_internal_originalTime": 1689748927000
    },
    {
        "time": 1689748928000,
        "value": 139.567,
        "_internal_originalTime": 1689748928000
    },
    {
        "time": 1689748929000,
        "value": 139.57,
        "_internal_originalTime": 1689748929000
    },
    {
        "time": 1689748931000,
        "value": 139.571,
        "_internal_originalTime": 1689748931000
    },
    {
        "time": 1689748932000,
        "value": 139.572,
        "_internal_originalTime": 1689748932000
    },
    {
        "time": 1689748933000,
        "value": 139.569,
        "_internal_originalTime": 1689748933000
    },
    {
        "time": 1689748935000,
        "value": 139.571,
        "_internal_originalTime": 1689748935000
    },
    {
        "time": 1689748936000,
        "value": 139.565,
        "_internal_originalTime": 1689748936000
    },
    {
        "time": 1689748937000,
        "value": 139.571,
        "_internal_originalTime": 1689748937000
    },
    {
        "time": 1689748938000,
        "value": 139.575,
        "_internal_originalTime": 1689748938000
    },
    {
        "time": 1689748939000,
        "value": 139.574,
        "_internal_originalTime": 1689748939000
    },
    {
        "time": 1689748940000,
        "value": 139.572,
        "_internal_originalTime": 1689748940000
    },
    {
        "time": 1689748942000,
        "value": 139.573,
        "_internal_originalTime": 1689748942000
    },
    {
        "time": 1689748943000,
        "value": 139.575,
        "_internal_originalTime": 1689748943000
    },
    {
        "time": 1689748945000,
        "value": 139.573,
        "_internal_originalTime": 1689748945000
    },
    {
        "time": 1689748946000,
        "value": 139.573,
        "_internal_originalTime": 1689748946000
    },
    {
        "time": 1689748948000,
        "value": 139.578,
        "_internal_originalTime": 1689748948000
    },
    {
        "time": 1689748949000,
        "value": 139.582,
        "_internal_originalTime": 1689748949000
    },
    {
        "time": 1689748950000,
        "value": 139.582,
        "_internal_originalTime": 1689748950000
    },
    {
        "time": 1689748951000,
        "value": 139.585,
        "_internal_originalTime": 1689748951000
    },
    {
        "time": 1689748953000,
        "value": 139.592,
        "_internal_originalTime": 1689748953000
    },
    {
        "time": 1689748954000,
        "value": 139.599,
        "_internal_originalTime": 1689748954000
    },
    {
        "time": 1689748955000,
        "value": 139.593,
        "_internal_originalTime": 1689748955000
    },
    {
        "time": 1689748956000,
        "value": 139.596,
        "_internal_originalTime": 1689748956000
    },
    {
        "time": 1689748957000,
        "value": 139.59,
        "_internal_originalTime": 1689748957000
    },
    {
        "time": 1689748958000,
        "value": 139.592,
        "_internal_originalTime": 1689748958000
    },
    {
        "time": 1689748960000,
        "value": 139.583,
        "_internal_originalTime": 1689748960000
    },
    {
        "time": 1689748962000,
        "value": 139.576,
        "_internal_originalTime": 1689748962000
    },
    {
        "time": 1689748963000,
        "value": 139.573,
        "_internal_originalTime": 1689748963000
    },
    {
        "time": 1689748965000,
        "value": 139.572,
        "_internal_originalTime": 1689748965000
    },
    {
        "time": 1689748967000,
        "value": 139.572,
        "_internal_originalTime": 1689748967000
    },
    {
        "time": 1689748968000,
        "value": 139.571,
        "_internal_originalTime": 1689748968000
    },
    {
        "time": 1689748969000,
        "value": 139.568,
        "_internal_originalTime": 1689748969000
    },
    {
        "time": 1689748970000,
        "value": 139.561,
        "_internal_originalTime": 1689748970000
    },
    {
        "time": 1689748972000,
        "value": 139.553,
        "_internal_originalTime": 1689748972000
    },
    {
        "time": 1689748974000,
        "value": 139.55,
        "_internal_originalTime": 1689748974000
    },
    {
        "time": 1689748975000,
        "value": 139.545,
        "_internal_originalTime": 1689748975000
    },
    {
        "time": 1689748976000,
        "value": 139.544,
        "_internal_originalTime": 1689748976000
    },
    {
        "time": 1689748978000,
        "value": 139.539,
        "_internal_originalTime": 1689748978000
    },
    {
        "time": 1689748979000,
        "value": 139.547,
        "_internal_originalTime": 1689748979000
    },
    {
        "time": 1689748981000,
        "value": 139.53,
        "_internal_originalTime": 1689748981000
    },
    {
        "time": 1689748982000,
        "value": 139.57,
        "_internal_originalTime": 1689748982000
    },
    {
        "time": 1689748983000,
        "value": 139.572,
        "_internal_originalTime": 1689748983000
    },
    {
        "time": 1689748984000,
        "value": 139.574,
        "_internal_originalTime": 1689748984000
    },
    {
        "time": 1689748985000,
        "value": 139.571,
        "_internal_originalTime": 1689748985000
    },
    {
        "time": 1689748986000,
        "value": 139.567,
        "_internal_originalTime": 1689748986000
    },
    {
        "time": 1689748987000,
        "value": 139.567,
        "_internal_originalTime": 1689748987000
    },
    {
        "time": 1689748989000,
        "value": 139.565,
        "_internal_originalTime": 1689748989000
    },
    {
        "time": 1689748990000,
        "value": 139.565,
        "_internal_originalTime": 1689748990000
    },
    {
        "time": 1689748991000,
        "value": 139.568,
        "_internal_originalTime": 1689748991000
    },
    {
        "time": 1689748992000,
        "value": 139.574,
        "_internal_originalTime": 1689748992000
    },
    {
        "time": 1689748993000,
        "value": 139.564,
        "_internal_originalTime": 1689748993000
    },
    {
        "time": 1689748994000,
        "value": 139.566,
        "_internal_originalTime": 1689748994000
    },
    {
        "time": 1689748995000,
        "value": 139.566,
        "_internal_originalTime": 1689748995000
    },
    {
        "time": 1689748996000,
        "value": 139.566,
        "_internal_originalTime": 1689748996000
    },
    {
        "time": 1689748997000,
        "value": 139.567,
        "_internal_originalTime": 1689748997000
    },
    {
        "time": 1689748999000,
        "value": 139.563,
        "_internal_originalTime": 1689748999000
    },
    {
        "time": 1689749000000,
        "value": 139.565,
        "_internal_originalTime": 1689749000000
    },
    {
        "time": 1689749001000,
        "value": 139.552,
        "_internal_originalTime": 1689749001000
    },
    {
        "time": 1689749002000,
        "value": 139.552,
        "_internal_originalTime": 1689749002000
    },
    {
        "time": 1689749003000,
        "value": 139.549,
        "_internal_originalTime": 1689749003000
    },
    {
        "time": 1689749004000,
        "value": 139.553,
        "_internal_originalTime": 1689749004000
    },
    {
        "time": 1689749005000,
        "value": 139.549,
        "_internal_originalTime": 1689749005000
    },
    {
        "time": 1689749006000,
        "value": 139.549,
        "_internal_originalTime": 1689749006000
    },
    {
        "time": 1689749008000,
        "value": 139.544,
        "_internal_originalTime": 1689749008000
    },
    {
        "time": 1689749009000,
        "value": 139.545,
        "_internal_originalTime": 1689749009000
    },
    {
        "time": 1689749010000,
        "value": 139.552,
        "_internal_originalTime": 1689749010000
    },
    {
        "time": 1689749011000,
        "value": 139.551,
        "_internal_originalTime": 1689749011000
    },
    {
        "time": 1689749013000,
        "value": 139.561,
        "_internal_originalTime": 1689749013000
    },
    {
        "time": 1689749015000,
        "value": 139.561,
        "_internal_originalTime": 1689749015000
    },
    {
        "time": 1689749016000,
        "value": 139.563,
        "_internal_originalTime": 1689749016000
    },
    {
        "time": 1689749017000,
        "value": 139.564,
        "_internal_originalTime": 1689749017000
    },
    {
        "time": 1689749018000,
        "value": 139.572,
        "_internal_originalTime": 1689749018000
    },
    {
        "time": 1689749020000,
        "value": 139.57,
        "_internal_originalTime": 1689749020000
    },
    {
        "time": 1689749022000,
        "value": 139.571,
        "_internal_originalTime": 1689749022000
    },
    {
        "time": 1689749023000,
        "value": 139.569,
        "_internal_originalTime": 1689749023000
    },
    {
        "time": 1689749024000,
        "value": 139.571,
        "_internal_originalTime": 1689749024000
    },
    {
        "time": 1689749025000,
        "value": 139.579,
        "_internal_originalTime": 1689749025000
    },
    {
        "time": 1689749026000,
        "value": 139.574,
        "_internal_originalTime": 1689749026000
    },
    {
        "time": 1689749027000,
        "value": 139.576,
        "_internal_originalTime": 1689749027000
    },
    {
        "time": 1689749028000,
        "value": 139.577,
        "_internal_originalTime": 1689749028000
    },
    {
        "time": 1689749029000,
        "value": 139.574,
        "_internal_originalTime": 1689749029000
    },
    {
        "time": 1689749031000,
        "value": 139.579,
        "_internal_originalTime": 1689749031000
    },
    {
        "time": 1689749032000,
        "value": 139.573,
        "_internal_originalTime": 1689749032000
    },
    {
        "time": 1689749034000,
        "value": 139.574,
        "_internal_originalTime": 1689749034000
    },
    {
        "time": 1689749035000,
        "value": 139.58,
        "_internal_originalTime": 1689749035000
    },
    {
        "time": 1689749036000,
        "value": 139.58,
        "_internal_originalTime": 1689749036000
    },
    {
        "time": 1689749037000,
        "value": 139.586,
        "_internal_originalTime": 1689749037000
    },
    {
        "time": 1689749038000,
        "value": 139.594,
        "_internal_originalTime": 1689749038000
    },
    {
        "time": 1689749040000,
        "value": 139.593,
        "_internal_originalTime": 1689749040000
    },
    {
        "time": 1689749041000,
        "value": 139.591,
        "_internal_originalTime": 1689749041000
    },
    {
        "time": 1689749043000,
        "value": 139.594,
        "_internal_originalTime": 1689749043000
    },
    {
        "time": 1689749044000,
        "value": 139.592,
        "_internal_originalTime": 1689749044000
    },
    {
        "time": 1689749046000,
        "value": 139.593,
        "_internal_originalTime": 1689749046000
    },
    {
        "time": 1689749047000,
        "value": 139.594,
        "_internal_originalTime": 1689749047000
    },
    {
        "time": 1689749048000,
        "value": 139.592,
        "_internal_originalTime": 1689749048000
    },
    {
        "time": 1689749049000,
        "value": 139.598,
        "_internal_originalTime": 1689749049000
    },
    {
        "time": 1689749050000,
        "value": 139.601,
        "_internal_originalTime": 1689749050000
    },
    {
        "time": 1689749051000,
        "value": 139.594,
        "_internal_originalTime": 1689749051000
    },
    {
        "time": 1689749053000,
        "value": 139.596,
        "_internal_originalTime": 1689749053000
    },
    {
        "time": 1689749054000,
        "value": 139.588,
        "_internal_originalTime": 1689749054000
    },
    {
        "time": 1689749055000,
        "value": 139.591,
        "_internal_originalTime": 1689749055000
    },
    {
        "time": 1689749056000,
        "value": 139.597,
        "_internal_originalTime": 1689749056000
    },
    {
        "time": 1689749058000,
        "value": 139.597,
        "_internal_originalTime": 1689749058000
    },
    {
        "time": 1689749059000,
        "value": 139.594,
        "_internal_originalTime": 1689749059000
    },
    {
        "time": 1689749060000,
        "value": 139.594,
        "_internal_originalTime": 1689749060000
    },
    {
        "time": 1689749061000,
        "value": 139.591,
        "_internal_originalTime": 1689749061000
    },
    {
        "time": 1689749062000,
        "value": 139.592,
        "_internal_originalTime": 1689749062000
    },
    {
        "time": 1689749063000,
        "value": 139.594,
        "_internal_originalTime": 1689749063000
    },
    {
        "time": 1689749065000,
        "value": 139.597,
        "_internal_originalTime": 1689749065000
    },
    {
        "time": 1689749066000,
        "value": 139.606,
        "_internal_originalTime": 1689749066000
    },
    {
        "time": 1689749068000,
        "value": 139.609,
        "_internal_originalTime": 1689749068000
    },
    {
        "time": 1689749069000,
        "value": 139.609,
        "_internal_originalTime": 1689749069000
    },
    {
        "time": 1689749070000,
        "value": 139.607,
        "_internal_originalTime": 1689749070000
    },
    {
        "time": 1689749071000,
        "value": 139.605,
        "_internal_originalTime": 1689749071000
    },
    {
        "time": 1689749072000,
        "value": 139.616,
        "_internal_originalTime": 1689749072000
    },
    {
        "time": 1689749074000,
        "value": 139.611,
        "_internal_originalTime": 1689749074000
    },
    {
        "time": 1689749075000,
        "value": 139.612,
        "_internal_originalTime": 1689749075000
    },
    {
        "time": 1689749077000,
        "value": 139.614,
        "_internal_originalTime": 1689749077000
    },
    {
        "time": 1689749078000,
        "value": 139.613,
        "_internal_originalTime": 1689749078000
    },
    {
        "time": 1689749079000,
        "value": 139.6,
        "_internal_originalTime": 1689749079000
    },
    {
        "time": 1689749080000,
        "value": 139.605,
        "_internal_originalTime": 1689749080000
    },
    {
        "time": 1689749081000,
        "value": 139.605,
        "_internal_originalTime": 1689749081000
    },
    {
        "time": 1689749083000,
        "value": 139.601,
        "_internal_originalTime": 1689749083000
    },
    {
        "time": 1689749084000,
        "value": 139.608,
        "_internal_originalTime": 1689749084000
    },
    {
        "time": 1689749086000,
        "value": 139.606,
        "_internal_originalTime": 1689749086000
    },
    {
        "time": 1689749088000,
        "value": 139.605,
        "_internal_originalTime": 1689749088000
    },
    {
        "time": 1689749089000,
        "value": 139.612,
        "_internal_originalTime": 1689749089000
    },
    {
        "time": 1689749090000,
        "value": 139.607,
        "_internal_originalTime": 1689749090000
    },
    {
        "time": 1689749091000,
        "value": 139.61,
        "_internal_originalTime": 1689749091000
    },
    {
        "time": 1689749092000,
        "value": 139.611,
        "_internal_originalTime": 1689749092000
    },
    {
        "time": 1689749094000,
        "value": 139.608,
        "_internal_originalTime": 1689749094000
    },
    {
        "time": 1689749095000,
        "value": 139.608,
        "_internal_originalTime": 1689749095000
    },
    {
        "time": 1689749096000,
        "value": 139.608,
        "_internal_originalTime": 1689749096000
    },
    {
        "time": 1689749097000,
        "value": 139.61,
        "_internal_originalTime": 1689749097000
    },
    {
        "time": 1689749099000,
        "value": 139.607,
        "_internal_originalTime": 1689749099000
    },
    {
        "time": 1689749100000,
        "value": 139.613,
        "_internal_originalTime": 1689749100000
    },
    {
        "time": 1689749101000,
        "value": 139.59,
        "_internal_originalTime": 1689749101000
    },
    {
        "time": 1689749102000,
        "value": 139.614,
        "_internal_originalTime": 1689749102000
    },
    {
        "time": 1689749104000,
        "value": 139.616,
        "_internal_originalTime": 1689749104000
    },
    {
        "time": 1689749105000,
        "value": 139.617,
        "_internal_originalTime": 1689749105000
    },
    {
        "time": 1689749106000,
        "value": 139.604,
        "_internal_originalTime": 1689749106000
    },
    {
        "time": 1689749107000,
        "value": 139.602,
        "_internal_originalTime": 1689749107000
    },
    {
        "time": 1689749108000,
        "value": 139.607,
        "_internal_originalTime": 1689749108000
    },
    {
        "time": 1689749109000,
        "value": 139.609,
        "_internal_originalTime": 1689749109000
    },
    {
        "time": 1689749110000,
        "value": 139.608,
        "_internal_originalTime": 1689749110000
    },
    {
        "time": 1689749111000,
        "value": 139.609,
        "_internal_originalTime": 1689749111000
    },
    {
        "time": 1689749113000,
        "value": 139.605,
        "_internal_originalTime": 1689749113000
    },
    {
        "time": 1689749114000,
        "value": 139.604,
        "_internal_originalTime": 1689749114000
    },
    {
        "time": 1689749115000,
        "value": 139.611,
        "_internal_originalTime": 1689749115000
    },
    {
        "time": 1689749117000,
        "value": 139.609,
        "_internal_originalTime": 1689749117000
    },
    {
        "time": 1689749118000,
        "value": 139.61,
        "_internal_originalTime": 1689749118000
    },
    {
        "time": 1689749119000,
        "value": 139.61,
        "_internal_originalTime": 1689749119000
    },
    {
        "time": 1689749120000,
        "value": 139.611,
        "_internal_originalTime": 1689749120000
    },
    {
        "time": 1689749121000,
        "value": 139.61,
        "_internal_originalTime": 1689749121000
    },
    {
        "time": 1689749122000,
        "value": 139.61,
        "_internal_originalTime": 1689749122000
    },
    {
        "time": 1689749123000,
        "value": 139.609,
        "_internal_originalTime": 1689749123000
    },
    {
        "time": 1689749124000,
        "value": 139.611,
        "_internal_originalTime": 1689749124000
    },
    {
        "time": 1689749125000,
        "value": 139.618,
        "_internal_originalTime": 1689749125000
    },
    {
        "time": 1689749126000,
        "value": 139.619,
        "_internal_originalTime": 1689749126000
    },
    {
        "time": 1689749128000,
        "value": 139.62,
        "_internal_originalTime": 1689749128000
    },
    {
        "time": 1689749129000,
        "value": 139.627,
        "_internal_originalTime": 1689749129000
    },
    {
        "time": 1689749130000,
        "value": 139.627,
        "_internal_originalTime": 1689749130000
    },
    {
        "time": 1689749131000,
        "value": 139.628,
        "_internal_originalTime": 1689749131000
    },
    {
        "time": 1689749132000,
        "value": 139.627,
        "_internal_originalTime": 1689749132000
    },
    {
        "time": 1689749133000,
        "value": 139.625,
        "_internal_originalTime": 1689749133000
    },
    {
        "time": 1689749134000,
        "value": 139.625,
        "_internal_originalTime": 1689749134000
    },
    {
        "time": 1689749135000,
        "value": 139.628,
        "_internal_originalTime": 1689749135000
    },
    {
        "time": 1689749136000,
        "value": 139.618,
        "_internal_originalTime": 1689749136000
    },
    {
        "time": 1689749137000,
        "value": 139.618,
        "_internal_originalTime": 1689749137000
    },
    {
        "time": 1689749139000,
        "value": 139.614,
        "_internal_originalTime": 1689749139000
    },
    {
        "time": 1689749140000,
        "value": 139.616,
        "_internal_originalTime": 1689749140000
    },
    {
        "time": 1689749142000,
        "value": 139.616,
        "_internal_originalTime": 1689749142000
    },
    {
        "time": 1689749143000,
        "value": 139.617,
        "_internal_originalTime": 1689749143000
    },
    {
        "time": 1689749144000,
        "value": 139.618,
        "_internal_originalTime": 1689749144000
    },
    {
        "time": 1689749146000,
        "value": 139.613,
        "_internal_originalTime": 1689749146000
    },
    {
        "time": 1689749147000,
        "value": 139.614,
        "_internal_originalTime": 1689749147000
    },
    {
        "time": 1689749149000,
        "value": 139.616,
        "_internal_originalTime": 1689749149000
    },
    {
        "time": 1689749151000,
        "value": 139.613,
        "_internal_originalTime": 1689749151000
    },
    {
        "time": 1689749152000,
        "value": 139.611,
        "_internal_originalTime": 1689749152000
    },
    {
        "time": 1689749153000,
        "value": 139.612,
        "_internal_originalTime": 1689749153000
    },
    {
        "time": 1689749155000,
        "value": 139.609,
        "_internal_originalTime": 1689749155000
    },
    {
        "time": 1689749156000,
        "value": 139.608,
        "_internal_originalTime": 1689749156000
    },
    {
        "time": 1689749157000,
        "value": 139.61,
        "_internal_originalTime": 1689749157000
    },
    {
        "time": 1689749159000,
        "value": 139.609,
        "_internal_originalTime": 1689749159000
    },
    {
        "time": 1689749160000,
        "value": 139.604,
        "_internal_originalTime": 1689749160000
    },
    {
        "time": 1689749162000,
        "value": 139.608,
        "_internal_originalTime": 1689749162000
    },
    {
        "time": 1689749163000,
        "value": 139.6,
        "_internal_originalTime": 1689749163000
    },
    {
        "time": 1689749164000,
        "value": 139.601,
        "_internal_originalTime": 1689749164000
    },
    {
        "time": 1689749165000,
        "value": 139.594,
        "_internal_originalTime": 1689749165000
    },
    {
        "time": 1689749167000,
        "value": 139.595,
        "_internal_originalTime": 1689749167000
    },
    {
        "time": 1689749168000,
        "value": 139.592,
        "_internal_originalTime": 1689749168000
    },
    {
        "time": 1689749170000,
        "value": 139.593,
        "_internal_originalTime": 1689749170000
    },
    {
        "time": 1689749172000,
        "value": 139.599,
        "_internal_originalTime": 1689749172000
    },
    {
        "time": 1689749173000,
        "value": 139.6,
        "_internal_originalTime": 1689749173000
    },
    {
        "time": 1689749174000,
        "value": 139.605,
        "_internal_originalTime": 1689749174000
    },
    {
        "time": 1689749175000,
        "value": 139.604,
        "_internal_originalTime": 1689749175000
    },
    {
        "time": 1689749176000,
        "value": 139.604,
        "_internal_originalTime": 1689749176000
    },
    {
        "time": 1689749177000,
        "value": 139.609,
        "_internal_originalTime": 1689749177000
    },
    {
        "time": 1689749178000,
        "value": 139.608,
        "_internal_originalTime": 1689749178000
    },
    {
        "time": 1689749179000,
        "value": 139.607,
        "_internal_originalTime": 1689749179000
    },
    {
        "time": 1689749180000,
        "value": 139.608,
        "_internal_originalTime": 1689749180000
    },
    {
        "time": 1689749182000,
        "value": 139.609,
        "_internal_originalTime": 1689749182000
    },
    {
        "time": 1689749183000,
        "value": 139.61,
        "_internal_originalTime": 1689749183000
    },
    {
        "time": 1689749184000,
        "value": 139.62,
        "_internal_originalTime": 1689749184000
    },
    {
        "time": 1689749186000,
        "value": 139.62,
        "_internal_originalTime": 1689749186000
    },
    {
        "time": 1689749187000,
        "value": 139.616,
        "_internal_originalTime": 1689749187000
    },
    {
        "time": 1689749188000,
        "value": 139.623,
        "_internal_originalTime": 1689749188000
    },
    {
        "time": 1689749190000,
        "value": 139.628,
        "_internal_originalTime": 1689749190000
    },
    {
        "time": 1689749191000,
        "value": 139.63,
        "_internal_originalTime": 1689749191000
    },
    {
        "time": 1689749192000,
        "value": 139.629,
        "_internal_originalTime": 1689749192000
    },
    {
        "time": 1689749194000,
        "value": 139.636,
        "_internal_originalTime": 1689749194000
    },
    {
        "time": 1689749196000,
        "value": 139.633,
        "_internal_originalTime": 1689749196000
    },
    {
        "time": 1689749197000,
        "value": 139.628,
        "_internal_originalTime": 1689749197000
    },
    {
        "time": 1689749199000,
        "value": 139.631,
        "_internal_originalTime": 1689749199000
    },
    {
        "time": 1689749200000,
        "value": 139.632,
        "_internal_originalTime": 1689749200000
    },
    {
        "time": 1689749202000,
        "value": 139.631,
        "_internal_originalTime": 1689749202000
    },
    {
        "time": 1689749204000,
        "value": 139.632,
        "_internal_originalTime": 1689749204000
    },
    {
        "time": 1689749206000,
        "value": 139.634,
        "_internal_originalTime": 1689749206000
    },
    {
        "time": 1689749208000,
        "value": 139.629,
        "_internal_originalTime": 1689749208000
    },
    {
        "time": 1689749210000,
        "value": 139.631,
        "_internal_originalTime": 1689749210000
    },
    {
        "time": 1689749212000,
        "value": 139.631,
        "_internal_originalTime": 1689749212000
    },
    {
        "time": 1689749213000,
        "value": 139.632,
        "_internal_originalTime": 1689749213000
    },
    {
        "time": 1689749215000,
        "value": 139.643,
        "_internal_originalTime": 1689749215000
    },
    {
        "time": 1689749217000,
        "value": 139.636,
        "_internal_originalTime": 1689749217000
    },
    {
        "time": 1689749219000,
        "value": 139.643,
        "_internal_originalTime": 1689749219000
    },
    {
        "time": 1689749220000,
        "value": 139.643,
        "_internal_originalTime": 1689749220000
    },
    {
        "time": 1689749221000,
        "value": 139.643,
        "_internal_originalTime": 1689749221000
    },
    {
        "time": 1689749222000,
        "value": 139.648,
        "_internal_originalTime": 1689749222000
    },
    {
        "time": 1689749223000,
        "value": 139.642,
        "_internal_originalTime": 1689749223000
    },
    {
        "time": 1689749224000,
        "value": 139.641,
        "_internal_originalTime": 1689749224000
    },
    {
        "time": 1689749226000,
        "value": 139.634,
        "_internal_originalTime": 1689749226000
    },
    {
        "time": 1689749228000,
        "value": 139.636,
        "_internal_originalTime": 1689749228000
    },
    {
        "time": 1689749229000,
        "value": 139.634,
        "_internal_originalTime": 1689749229000
    },
    {
        "time": 1689749231000,
        "value": 139.633,
        "_internal_originalTime": 1689749231000
    },
    {
        "time": 1689749233000,
        "value": 139.637,
        "_internal_originalTime": 1689749233000
    },
    {
        "time": 1689749234000,
        "value": 139.642,
        "_internal_originalTime": 1689749234000
    },
    {
        "time": 1689749235000,
        "value": 139.636,
        "_internal_originalTime": 1689749235000
    },
    {
        "time": 1689749237000,
        "value": 139.638,
        "_internal_originalTime": 1689749237000
    },
    {
        "time": 1689749238000,
        "value": 139.637,
        "_internal_originalTime": 1689749238000
    },
    {
        "time": 1689749239000,
        "value": 139.654,
        "_internal_originalTime": 1689749239000
    },
    {
        "time": 1689749240000,
        "value": 139.648,
        "_internal_originalTime": 1689749240000
    },
    {
        "time": 1689749242000,
        "value": 139.65,
        "_internal_originalTime": 1689749242000
    },
    {
        "time": 1689749244000,
        "value": 139.661,
        "_internal_originalTime": 1689749244000
    },
    {
        "time": 1689749245000,
        "value": 139.657,
        "_internal_originalTime": 1689749245000
    },
    {
        "time": 1689749246000,
        "value": 139.657,
        "_internal_originalTime": 1689749246000
    },
    {
        "time": 1689749247000,
        "value": 139.659,
        "_internal_originalTime": 1689749247000
    },
    {
        "time": 1689749248000,
        "value": 139.658,
        "_internal_originalTime": 1689749248000
    },
    {
        "time": 1689749249000,
        "value": 139.654,
        "_internal_originalTime": 1689749249000
    },
    {
        "time": 1689749250000,
        "value": 139.658,
        "_internal_originalTime": 1689749250000
    },
    {
        "time": 1689749251000,
        "value": 139.646,
        "_internal_originalTime": 1689749251000
    },
    {
        "time": 1689749252000,
        "value": 139.647,
        "_internal_originalTime": 1689749252000
    },
    {
        "time": 1689749254000,
        "value": 139.647,
        "_internal_originalTime": 1689749254000
    },
    {
        "time": 1689749256000,
        "value": 139.648,
        "_internal_originalTime": 1689749256000
    },
    {
        "time": 1689749257000,
        "value": 139.651,
        "_internal_originalTime": 1689749257000
    },
    {
        "time": 1689749258000,
        "value": 139.641,
        "_internal_originalTime": 1689749258000
    },
    {
        "time": 1689749260000,
        "value": 139.636,
        "_internal_originalTime": 1689749260000
    },
    {
        "time": 1689749262000,
        "value": 139.649,
        "_internal_originalTime": 1689749262000
    },
    {
        "time": 1689749263000,
        "value": 139.636,
        "_internal_originalTime": 1689749263000
    },
    {
        "time": 1689749264000,
        "value": 139.647,
        "_internal_originalTime": 1689749264000
    },
    {
        "time": 1689749265000,
        "value": 139.651,
        "_internal_originalTime": 1689749265000
    },
    {
        "time": 1689749266000,
        "value": 139.648,
        "_internal_originalTime": 1689749266000
    },
    {
        "time": 1689749267000,
        "value": 139.651,
        "_internal_originalTime": 1689749267000
    },
    {
        "time": 1689749269000,
        "value": 139.65,
        "_internal_originalTime": 1689749269000
    },
    {
        "time": 1689749271000,
        "value": 139.652,
        "_internal_originalTime": 1689749271000
    },
    {
        "time": 1689749272000,
        "value": 139.654,
        "_internal_originalTime": 1689749272000
    },
    {
        "time": 1689749273000,
        "value": 139.648,
        "_internal_originalTime": 1689749273000
    },
    {
        "time": 1689749274000,
        "value": 139.654,
        "_internal_originalTime": 1689749274000
    },
    {
        "time": 1689749275000,
        "value": 139.654,
        "_internal_originalTime": 1689749275000
    },
    {
        "time": 1689749277000,
        "value": 139.657,
        "_internal_originalTime": 1689749277000
    },
    {
        "time": 1689749278000,
        "value": 139.658,
        "_internal_originalTime": 1689749278000
    },
    {
        "time": 1689749279000,
        "value": 139.65,
        "_internal_originalTime": 1689749279000
    },
    {
        "time": 1689749281000,
        "value": 139.63,
        "_internal_originalTime": 1689749281000
    },
    {
        "time": 1689749282000,
        "value": 139.653,
        "_internal_originalTime": 1689749282000
    },
    {
        "time": 1689749284000,
        "value": 139.65,
        "_internal_originalTime": 1689749284000
    },
    {
        "time": 1689749285000,
        "value": 139.657,
        "_internal_originalTime": 1689749285000
    },
    {
        "time": 1689749286000,
        "value": 139.656,
        "_internal_originalTime": 1689749286000
    },
    {
        "time": 1689749287000,
        "value": 139.657,
        "_internal_originalTime": 1689749287000
    },
    {
        "time": 1689749288000,
        "value": 139.654,
        "_internal_originalTime": 1689749288000
    },
    {
        "time": 1689749290000,
        "value": 139.65,
        "_internal_originalTime": 1689749290000
    },
    {
        "time": 1689749291000,
        "value": 139.657,
        "_internal_originalTime": 1689749291000
    },
    {
        "time": 1689749292000,
        "value": 139.662,
        "_internal_originalTime": 1689749292000
    },
    {
        "time": 1689749293000,
        "value": 139.665,
        "_internal_originalTime": 1689749293000
    },
    {
        "time": 1689749294000,
        "value": 139.668,
        "_internal_originalTime": 1689749294000
    },
    {
        "time": 1689749295000,
        "value": 139.665,
        "_internal_originalTime": 1689749295000
    },
    {
        "time": 1689749296000,
        "value": 139.673,
        "_internal_originalTime": 1689749296000
    },
    {
        "time": 1689749297000,
        "value": 139.669,
        "_internal_originalTime": 1689749297000
    },
    {
        "time": 1689749298000,
        "value": 139.673,
        "_internal_originalTime": 1689749298000
    },
    {
        "time": 1689749299000,
        "value": 139.677,
        "_internal_originalTime": 1689749299000
    },
    {
        "time": 1689749301000,
        "value": 139.674,
        "_internal_originalTime": 1689749301000
    },
    {
        "time": 1689749302000,
        "value": 139.668,
        "_internal_originalTime": 1689749302000
    },
    {
        "time": 1689749303000,
        "value": 139.671,
        "_internal_originalTime": 1689749303000
    },
    {
        "time": 1689749306000,
        "value": 139.672,
        "_internal_originalTime": 1689749306000
    },
    {
        "time": 1689749308000,
        "value": 139.672,
        "_internal_originalTime": 1689749308000
    },
    {
        "time": 1689749309000,
        "value": 139.67,
        "_internal_originalTime": 1689749309000
    },
    {
        "time": 1689749311000,
        "value": 139.673,
        "_internal_originalTime": 1689749311000
    },
    {
        "time": 1689749312000,
        "value": 139.674,
        "_internal_originalTime": 1689749312000
    },
    {
        "time": 1689749313000,
        "value": 139.673,
        "_internal_originalTime": 1689749313000
    },
    {
        "time": 1689749314000,
        "value": 139.673,
        "_internal_originalTime": 1689749314000
    },
    {
        "time": 1689749315000,
        "value": 139.666,
        "_internal_originalTime": 1689749315000
    },
    {
        "time": 1689749318000,
        "value": 139.667,
        "_internal_originalTime": 1689749318000
    },
    {
        "time": 1689749319000,
        "value": 139.655,
        "_internal_originalTime": 1689749319000
    },
    {
        "time": 1689749320000,
        "value": 139.646,
        "_internal_originalTime": 1689749320000
    },
    {
        "time": 1689749321000,
        "value": 139.644,
        "_internal_originalTime": 1689749321000
    },
    {
        "time": 1689749322000,
        "value": 139.645,
        "_internal_originalTime": 1689749322000
    },
    {
        "time": 1689749323000,
        "value": 139.648,
        "_internal_originalTime": 1689749323000
    },
    {
        "time": 1689749325000,
        "value": 139.648,
        "_internal_originalTime": 1689749325000
    },
    {
        "time": 1689749327000,
        "value": 139.656,
        "_internal_originalTime": 1689749327000
    },
    {
        "time": 1689749329000,
        "value": 139.66,
        "_internal_originalTime": 1689749329000
    },
    {
        "time": 1689749330000,
        "value": 139.659,
        "_internal_originalTime": 1689749330000
    },
    {
        "time": 1689749331000,
        "value": 139.654,
        "_internal_originalTime": 1689749331000
    },
    {
        "time": 1689749332000,
        "value": 139.656,
        "_internal_originalTime": 1689749332000
    },
    {
        "time": 1689749334000,
        "value": 139.645,
        "_internal_originalTime": 1689749334000
    },
    {
        "time": 1689749336000,
        "value": 139.639,
        "_internal_originalTime": 1689749336000
    },
    {
        "time": 1689749337000,
        "value": 139.647,
        "_internal_originalTime": 1689749337000
    },
    {
        "time": 1689749338000,
        "value": 139.647,
        "_internal_originalTime": 1689749338000
    },
    {
        "time": 1689749339000,
        "value": 139.648,
        "_internal_originalTime": 1689749339000
    },
    {
        "time": 1689749341000,
        "value": 139.638,
        "_internal_originalTime": 1689749341000
    },
    {
        "time": 1689749342000,
        "value": 139.644,
        "_internal_originalTime": 1689749342000
    },
    {
        "time": 1689749343000,
        "value": 139.643,
        "_internal_originalTime": 1689749343000
    },
    {
        "time": 1689749344000,
        "value": 139.638,
        "_internal_originalTime": 1689749344000
    },
    {
        "time": 1689749345000,
        "value": 139.634,
        "_internal_originalTime": 1689749345000
    },
    {
        "time": 1689749346000,
        "value": 139.642,
        "_internal_originalTime": 1689749346000
    },
    {
        "time": 1689749347000,
        "value": 139.641,
        "_internal_originalTime": 1689749347000
    },
    {
        "time": 1689749348000,
        "value": 139.636,
        "_internal_originalTime": 1689749348000
    },
    {
        "time": 1689749349000,
        "value": 139.64,
        "_internal_originalTime": 1689749349000
    },
    {
        "time": 1689749351000,
        "value": 139.637,
        "_internal_originalTime": 1689749351000
    },
    {
        "time": 1689749352000,
        "value": 139.634,
        "_internal_originalTime": 1689749352000
    },
    {
        "time": 1689749353000,
        "value": 139.636,
        "_internal_originalTime": 1689749353000
    },
    {
        "time": 1689749354000,
        "value": 139.64,
        "_internal_originalTime": 1689749354000
    },
    {
        "time": 1689749355000,
        "value": 139.638,
        "_internal_originalTime": 1689749355000
    },
    {
        "time": 1689749356000,
        "value": 139.636,
        "_internal_originalTime": 1689749356000
    },
    {
        "time": 1689749357000,
        "value": 139.635,
        "_internal_originalTime": 1689749357000
    },
    {
        "time": 1689749358000,
        "value": 139.629,
        "_internal_originalTime": 1689749358000
    },
    {
        "time": 1689749359000,
        "value": 139.628,
        "_internal_originalTime": 1689749359000
    },
    {
        "time": 1689749361000,
        "value": 139.628,
        "_internal_originalTime": 1689749361000
    },
    {
        "time": 1689749362000,
        "value": 139.635,
        "_internal_originalTime": 1689749362000
    },
    {
        "time": 1689749363000,
        "value": 139.629,
        "_internal_originalTime": 1689749363000
    },
    {
        "time": 1689749364000,
        "value": 139.628,
        "_internal_originalTime": 1689749364000
    },
    {
        "time": 1689749365000,
        "value": 139.619,
        "_internal_originalTime": 1689749365000
    },
    {
        "time": 1689749366000,
        "value": 139.616,
        "_internal_originalTime": 1689749366000
    },
    {
        "time": 1689749368000,
        "value": 139.611,
        "_internal_originalTime": 1689749368000
    },
    {
        "time": 1689749369000,
        "value": 139.613,
        "_internal_originalTime": 1689749369000
    },
    {
        "time": 1689749370000,
        "value": 139.612,
        "_internal_originalTime": 1689749370000
    },
    {
        "time": 1689749371000,
        "value": 139.615,
        "_internal_originalTime": 1689749371000
    },
    {
        "time": 1689749372000,
        "value": 139.615,
        "_internal_originalTime": 1689749372000
    },
    {
        "time": 1689749374000,
        "value": 139.615,
        "_internal_originalTime": 1689749374000
    },
    {
        "time": 1689749376000,
        "value": 139.619,
        "_internal_originalTime": 1689749376000
    },
    {
        "time": 1689749377000,
        "value": 139.619,
        "_internal_originalTime": 1689749377000
    },
    {
        "time": 1689749378000,
        "value": 139.619,
        "_internal_originalTime": 1689749378000
    },
    {
        "time": 1689749381000,
        "value": 139.625,
        "_internal_originalTime": 1689749381000
    },
    {
        "time": 1689749382000,
        "value": 139.621,
        "_internal_originalTime": 1689749382000
    },
    {
        "time": 1689749383000,
        "value": 139.619,
        "_internal_originalTime": 1689749383000
    },
    {
        "time": 1689749384000,
        "value": 139.615,
        "_internal_originalTime": 1689749384000
    },
    {
        "time": 1689749385000,
        "value": 139.616,
        "_internal_originalTime": 1689749385000
    },
    {
        "time": 1689749386000,
        "value": 139.618,
        "_internal_originalTime": 1689749386000
    },
    {
        "time": 1689749387000,
        "value": 139.612,
        "_internal_originalTime": 1689749387000
    },
    {
        "time": 1689749388000,
        "value": 139.611,
        "_internal_originalTime": 1689749388000
    },
    {
        "time": 1689749389000,
        "value": 139.608,
        "_internal_originalTime": 1689749389000
    },
    {
        "time": 1689749391000,
        "value": 139.604,
        "_internal_originalTime": 1689749391000
    },
    {
        "time": 1689749392000,
        "value": 139.599,
        "_internal_originalTime": 1689749392000
    },
    {
        "time": 1689749393000,
        "value": 139.597,
        "_internal_originalTime": 1689749393000
    },
    {
        "time": 1689749394000,
        "value": 139.595,
        "_internal_originalTime": 1689749394000
    },
    {
        "time": 1689749395000,
        "value": 139.592,
        "_internal_originalTime": 1689749395000
    },
    {
        "time": 1689749397000,
        "value": 139.593,
        "_internal_originalTime": 1689749397000
    },
    {
        "time": 1689749398000,
        "value": 139.591,
        "_internal_originalTime": 1689749398000
    },
    {
        "time": 1689749400000,
        "value": 139.57,
        "_internal_originalTime": 1689749400000
    },
    {
        "time": 1689749401000,
        "value": 139.595,
        "_internal_originalTime": 1689749401000
    },
    {
        "time": 1689749402000,
        "value": 139.6,
        "_internal_originalTime": 1689749402000
    },
    {
        "time": 1689749403000,
        "value": 139.591,
        "_internal_originalTime": 1689749403000
    },
    {
        "time": 1689749404000,
        "value": 139.59,
        "_internal_originalTime": 1689749404000
    },
    {
        "time": 1689749405000,
        "value": 139.593,
        "_internal_originalTime": 1689749405000
    },
    {
        "time": 1689749406000,
        "value": 139.585,
        "_internal_originalTime": 1689749406000
    },
    {
        "time": 1689749407000,
        "value": 139.586,
        "_internal_originalTime": 1689749407000
    },
    {
        "time": 1689749409000,
        "value": 139.589,
        "_internal_originalTime": 1689749409000
    },
    {
        "time": 1689749410000,
        "value": 139.586,
        "_internal_originalTime": 1689749410000
    },
    {
        "time": 1689749411000,
        "value": 139.593,
        "_internal_originalTime": 1689749411000
    },
    {
        "time": 1689749413000,
        "value": 139.59,
        "_internal_originalTime": 1689749413000
    },
    {
        "time": 1689749414000,
        "value": 139.586,
        "_internal_originalTime": 1689749414000
    },
    {
        "time": 1689749415000,
        "value": 139.583,
        "_internal_originalTime": 1689749415000
    },
    {
        "time": 1689749416000,
        "value": 139.586,
        "_internal_originalTime": 1689749416000
    },
    {
        "time": 1689749417000,
        "value": 139.585,
        "_internal_originalTime": 1689749417000
    },
    {
        "time": 1689749418000,
        "value": 139.589,
        "_internal_originalTime": 1689749418000
    },
    {
        "time": 1689749420000,
        "value": 139.589,
        "_internal_originalTime": 1689749420000
    },
    {
        "time": 1689749421000,
        "value": 139.587,
        "_internal_originalTime": 1689749421000
    },
    {
        "time": 1689749422000,
        "value": 139.587,
        "_internal_originalTime": 1689749422000
    },
    {
        "time": 1689749424000,
        "value": 139.584,
        "_internal_originalTime": 1689749424000
    },
    {
        "time": 1689749425000,
        "value": 139.583,
        "_internal_originalTime": 1689749425000
    },
    {
        "time": 1689749426000,
        "value": 139.574,
        "_internal_originalTime": 1689749426000
    },
    {
        "time": 1689749429000,
        "value": 139.568,
        "_internal_originalTime": 1689749429000
    },
    {
        "time": 1689749430000,
        "value": 139.571,
        "_internal_originalTime": 1689749430000
    },
    {
        "time": 1689749432000,
        "value": 139.569,
        "_internal_originalTime": 1689749432000
    },
    {
        "time": 1689749433000,
        "value": 139.569,
        "_internal_originalTime": 1689749433000
    },
    {
        "time": 1689749434000,
        "value": 139.565,
        "_internal_originalTime": 1689749434000
    },
    {
        "time": 1689749435000,
        "value": 139.556,
        "_internal_originalTime": 1689749435000
    },
    {
        "time": 1689749437000,
        "value": 139.557,
        "_internal_originalTime": 1689749437000
    },
    {
        "time": 1689749438000,
        "value": 139.556,
        "_internal_originalTime": 1689749438000
    },
    {
        "time": 1689749439000,
        "value": 139.558,
        "_internal_originalTime": 1689749439000
    },
    {
        "time": 1689749440000,
        "value": 139.561,
        "_internal_originalTime": 1689749440000
    },
    {
        "time": 1689749441000,
        "value": 139.555,
        "_internal_originalTime": 1689749441000
    },
    {
        "time": 1689749443000,
        "value": 139.559,
        "_internal_originalTime": 1689749443000
    },
    {
        "time": 1689749445000,
        "value": 139.577,
        "_internal_originalTime": 1689749445000
    },
    {
        "time": 1689749446000,
        "value": 139.568,
        "_internal_originalTime": 1689749446000
    },
    {
        "time": 1689749447000,
        "value": 139.572,
        "_internal_originalTime": 1689749447000
    },
    {
        "time": 1689749448000,
        "value": 139.569,
        "_internal_originalTime": 1689749448000
    },
    {
        "time": 1689749449000,
        "value": 139.568,
        "_internal_originalTime": 1689749449000
    },
    {
        "time": 1689749451000,
        "value": 139.558,
        "_internal_originalTime": 1689749451000
    },
    {
        "time": 1689749452000,
        "value": 139.564,
        "_internal_originalTime": 1689749452000
    },
    {
        "time": 1689749453000,
        "value": 139.57,
        "_internal_originalTime": 1689749453000
    },
    {
        "time": 1689749454000,
        "value": 139.573,
        "_internal_originalTime": 1689749454000
    },
    {
        "time": 1689749455000,
        "value": 139.568,
        "_internal_originalTime": 1689749455000
    },
    {
        "time": 1689749457000,
        "value": 139.562,
        "_internal_originalTime": 1689749457000
    },
    {
        "time": 1689749458000,
        "value": 139.56,
        "_internal_originalTime": 1689749458000
    },
    {
        "time": 1689749459000,
        "value": 139.566,
        "_internal_originalTime": 1689749459000
    },
    {
        "time": 1689749460000,
        "value": 139.559,
        "_internal_originalTime": 1689749460000
    },
    {
        "time": 1689749461000,
        "value": 139.566,
        "_internal_originalTime": 1689749461000
    },
    {
        "time": 1689749462000,
        "value": 139.564,
        "_internal_originalTime": 1689749462000
    },
    {
        "time": 1689749464000,
        "value": 139.558,
        "_internal_originalTime": 1689749464000
    },
    {
        "time": 1689749465000,
        "value": 139.563,
        "_internal_originalTime": 1689749465000
    },
    {
        "time": 1689749467000,
        "value": 139.559,
        "_internal_originalTime": 1689749467000
    },
    {
        "time": 1689749469000,
        "value": 139.566,
        "_internal_originalTime": 1689749469000
    },
    {
        "time": 1689749472000,
        "value": 139.568,
        "_internal_originalTime": 1689749472000
    },
    {
        "time": 1689749474000,
        "value": 139.564,
        "_internal_originalTime": 1689749474000
    },
    {
        "time": 1689749477000,
        "value": 139.548,
        "_internal_originalTime": 1689749477000
    },
    {
        "time": 1689749479000,
        "value": 139.553,
        "_internal_originalTime": 1689749479000
    },
    {
        "time": 1689749481000,
        "value": 139.555,
        "_internal_originalTime": 1689749481000
    },
    {
        "time": 1689749482000,
        "value": 139.561,
        "_internal_originalTime": 1689749482000
    },
    {
        "time": 1689749483000,
        "value": 139.565,
        "_internal_originalTime": 1689749483000
    },
    {
        "time": 1689749485000,
        "value": 139.568,
        "_internal_originalTime": 1689749485000
    },
    {
        "time": 1689749486000,
        "value": 139.566,
        "_internal_originalTime": 1689749486000
    },
    {
        "time": 1689749487000,
        "value": 139.573,
        "_internal_originalTime": 1689749487000
    },
    {
        "time": 1689749488000,
        "value": 139.571,
        "_internal_originalTime": 1689749488000
    },
    {
        "time": 1689749490000,
        "value": 139.565,
        "_internal_originalTime": 1689749490000
    },
    {
        "time": 1689749491000,
        "value": 139.564,
        "_internal_originalTime": 1689749491000
    },
    {
        "time": 1689749493000,
        "value": 139.564,
        "_internal_originalTime": 1689749493000
    },
    {
        "time": 1689749494000,
        "value": 139.568,
        "_internal_originalTime": 1689749494000
    },
    {
        "time": 1689749495000,
        "value": 139.563,
        "_internal_originalTime": 1689749495000
    },
    {
        "time": 1689749496000,
        "value": 139.562,
        "_internal_originalTime": 1689749496000
    },
    {
        "time": 1689749497000,
        "value": 139.56,
        "_internal_originalTime": 1689749497000
    },
    {
        "time": 1689749499000,
        "value": 139.564,
        "_internal_originalTime": 1689749499000
    },
    {
        "time": 1689749500000,
        "value": 139.565,
        "_internal_originalTime": 1689749500000
    },
    {
        "time": 1689749501000,
        "value": 139.56,
        "_internal_originalTime": 1689749501000
    },
    {
        "time": 1689749503000,
        "value": 139.56,
        "_internal_originalTime": 1689749503000
    },
    {
        "time": 1689749504000,
        "value": 139.564,
        "_internal_originalTime": 1689749504000
    },
    {
        "time": 1689749506000,
        "value": 139.562,
        "_internal_originalTime": 1689749506000
    },
    {
        "time": 1689749508000,
        "value": 139.563,
        "_internal_originalTime": 1689749508000
    },
    {
        "time": 1689749509000,
        "value": 139.563,
        "_internal_originalTime": 1689749509000
    },
    {
        "time": 1689749510000,
        "value": 139.561,
        "_internal_originalTime": 1689749510000
    },
    {
        "time": 1689749511000,
        "value": 139.563,
        "_internal_originalTime": 1689749511000
    },
    {
        "time": 1689749512000,
        "value": 139.561,
        "_internal_originalTime": 1689749512000
    },
    {
        "time": 1689749514000,
        "value": 139.562,
        "_internal_originalTime": 1689749514000
    },
    {
        "time": 1689749515000,
        "value": 139.562,
        "_internal_originalTime": 1689749515000
    },
    {
        "time": 1689749516000,
        "value": 139.56,
        "_internal_originalTime": 1689749516000
    },
    {
        "time": 1689749517000,
        "value": 139.561,
        "_internal_originalTime": 1689749517000
    },
    {
        "time": 1689749519000,
        "value": 139.563,
        "_internal_originalTime": 1689749519000
    },
    {
        "time": 1689749520000,
        "value": 139.558,
        "_internal_originalTime": 1689749520000
    },
    {
        "time": 1689749521000,
        "value": 139.562,
        "_internal_originalTime": 1689749521000
    },
    {
        "time": 1689749522000,
        "value": 139.549,
        "_internal_originalTime": 1689749522000
    },
    {
        "time": 1689749524000,
        "value": 139.552,
        "_internal_originalTime": 1689749524000
    },
    {
        "time": 1689749525000,
        "value": 139.551,
        "_internal_originalTime": 1689749525000
    },
    {
        "time": 1689749526000,
        "value": 139.551,
        "_internal_originalTime": 1689749526000
    },
    {
        "time": 1689749527000,
        "value": 139.552,
        "_internal_originalTime": 1689749527000
    },
    {
        "time": 1689749528000,
        "value": 139.551,
        "_internal_originalTime": 1689749528000
    },
    {
        "time": 1689749530000,
        "value": 139.556,
        "_internal_originalTime": 1689749530000
    },
    {
        "time": 1689749531000,
        "value": 139.556,
        "_internal_originalTime": 1689749531000
    },
    {
        "time": 1689749532000,
        "value": 139.552,
        "_internal_originalTime": 1689749532000
    },
    {
        "time": 1689749533000,
        "value": 139.554,
        "_internal_originalTime": 1689749533000
    },
    {
        "time": 1689749536000,
        "value": 139.552,
        "_internal_originalTime": 1689749536000
    },
    {
        "time": 1689749537000,
        "value": 139.556,
        "_internal_originalTime": 1689749537000
    },
    {
        "time": 1689749538000,
        "value": 139.557,
        "_internal_originalTime": 1689749538000
    },
    {
        "time": 1689749539000,
        "value": 139.556,
        "_internal_originalTime": 1689749539000
    },
    {
        "time": 1689749540000,
        "value": 139.563,
        "_internal_originalTime": 1689749540000
    },
    {
        "time": 1689749541000,
        "value": 139.564,
        "_internal_originalTime": 1689749541000
    },
    {
        "time": 1689749542000,
        "value": 139.568,
        "_internal_originalTime": 1689749542000
    },
    {
        "time": 1689749543000,
        "value": 139.567,
        "_internal_originalTime": 1689749543000
    },
    {
        "time": 1689749545000,
        "value": 139.566,
        "_internal_originalTime": 1689749545000
    },
    {
        "time": 1689749547000,
        "value": 139.557,
        "_internal_originalTime": 1689749547000
    },
    {
        "time": 1689749548000,
        "value": 139.558,
        "_internal_originalTime": 1689749548000
    },
    {
        "time": 1689749549000,
        "value": 139.556,
        "_internal_originalTime": 1689749549000
    },
    {
        "time": 1689749550000,
        "value": 139.56,
        "_internal_originalTime": 1689749550000
    },
    {
        "time": 1689749551000,
        "value": 139.557,
        "_internal_originalTime": 1689749551000
    },
    {
        "time": 1689749552000,
        "value": 139.558,
        "_internal_originalTime": 1689749552000
    },
    {
        "time": 1689749554000,
        "value": 139.556,
        "_internal_originalTime": 1689749554000
    },
    {
        "time": 1689749556000,
        "value": 139.552,
        "_internal_originalTime": 1689749556000
    },
    {
        "time": 1689749557000,
        "value": 139.557,
        "_internal_originalTime": 1689749557000
    },
    {
        "time": 1689749559000,
        "value": 139.555,
        "_internal_originalTime": 1689749559000
    },
    {
        "time": 1689749560000,
        "value": 139.553,
        "_internal_originalTime": 1689749560000
    },
    {
        "time": 1689749561000,
        "value": 139.551,
        "_internal_originalTime": 1689749561000
    },
    {
        "time": 1689749562000,
        "value": 139.55,
        "_internal_originalTime": 1689749562000
    },
    {
        "time": 1689749565000,
        "value": 139.554,
        "_internal_originalTime": 1689749565000
    },
    {
        "time": 1689749566000,
        "value": 139.552,
        "_internal_originalTime": 1689749566000
    },
    {
        "time": 1689749567000,
        "value": 139.557,
        "_internal_originalTime": 1689749567000
    },
    {
        "time": 1689749568000,
        "value": 139.554,
        "_internal_originalTime": 1689749568000
    },
    {
        "time": 1689749571000,
        "value": 139.553,
        "_internal_originalTime": 1689749571000
    },
    {
        "time": 1689749572000,
        "value": 139.552,
        "_internal_originalTime": 1689749572000
    },
    {
        "time": 1689749573000,
        "value": 139.553,
        "_internal_originalTime": 1689749573000
    },
    {
        "time": 1689749575000,
        "value": 139.554,
        "_internal_originalTime": 1689749575000
    },
    {
        "time": 1689749577000,
        "value": 139.554,
        "_internal_originalTime": 1689749577000
    },
    {
        "time": 1689749579000,
        "value": 139.554,
        "_internal_originalTime": 1689749579000
    },
    {
        "time": 1689749580000,
        "value": 139.549,
        "_internal_originalTime": 1689749580000
    },
    {
        "time": 1689749581000,
        "value": 139.555,
        "_internal_originalTime": 1689749581000
    },
    {
        "time": 1689749583000,
        "value": 139.553,
        "_internal_originalTime": 1689749583000
    },
    {
        "time": 1689749584000,
        "value": 139.565,
        "_internal_originalTime": 1689749584000
    },
    {
        "time": 1689749585000,
        "value": 139.564,
        "_internal_originalTime": 1689749585000
    },
    {
        "time": 1689749586000,
        "value": 139.563,
        "_internal_originalTime": 1689749586000
    },
    {
        "time": 1689749587000,
        "value": 139.564,
        "_internal_originalTime": 1689749587000
    },
    {
        "time": 1689749588000,
        "value": 139.558,
        "_internal_originalTime": 1689749588000
    },
    {
        "time": 1689749589000,
        "value": 139.563,
        "_internal_originalTime": 1689749589000
    },
    {
        "time": 1689749590000,
        "value": 139.561,
        "_internal_originalTime": 1689749590000
    },
    {
        "time": 1689749591000,
        "value": 139.559,
        "_internal_originalTime": 1689749591000
    },
    {
        "time": 1689749593000,
        "value": 139.556,
        "_internal_originalTime": 1689749593000
    },
    {
        "time": 1689749594000,
        "value": 139.56,
        "_internal_originalTime": 1689749594000
    },
    {
        "time": 1689749595000,
        "value": 139.562,
        "_internal_originalTime": 1689749595000
    },
    {
        "time": 1689749596000,
        "value": 139.557,
        "_internal_originalTime": 1689749596000
    },
    {
        "time": 1689749597000,
        "value": 139.555,
        "_internal_originalTime": 1689749597000
    },
    {
        "time": 1689749599000,
        "value": 139.549,
        "_internal_originalTime": 1689749599000
    },
    {
        "time": 1689749600000,
        "value": 139.548,
        "_internal_originalTime": 1689749600000
    },
    {
        "time": 1689749602000,
        "value": 139.545,
        "_internal_originalTime": 1689749602000
    },
    {
        "time": 1689749603000,
        "value": 139.54,
        "_internal_originalTime": 1689749603000
    },
    {
        "time": 1689749604000,
        "value": 139.542,
        "_internal_originalTime": 1689749604000
    },
    {
        "time": 1689749605000,
        "value": 139.536,
        "_internal_originalTime": 1689749605000
    },
    {
        "time": 1689749606000,
        "value": 139.537,
        "_internal_originalTime": 1689749606000
    },
    {
        "time": 1689749607000,
        "value": 139.539,
        "_internal_originalTime": 1689749607000
    },
    {
        "time": 1689749609000,
        "value": 139.537,
        "_internal_originalTime": 1689749609000
    },
    {
        "time": 1689749610000,
        "value": 139.537,
        "_internal_originalTime": 1689749610000
    },
    {
        "time": 1689749611000,
        "value": 139.539,
        "_internal_originalTime": 1689749611000
    },
    {
        "time": 1689749612000,
        "value": 139.54,
        "_internal_originalTime": 1689749612000
    },
    {
        "time": 1689749614000,
        "value": 139.541,
        "_internal_originalTime": 1689749614000
    },
    {
        "time": 1689749615000,
        "value": 139.542,
        "_internal_originalTime": 1689749615000
    },
    {
        "time": 1689749616000,
        "value": 139.546,
        "_internal_originalTime": 1689749616000
    },
    {
        "time": 1689749618000,
        "value": 139.541,
        "_internal_originalTime": 1689749618000
    },
    {
        "time": 1689749619000,
        "value": 139.54,
        "_internal_originalTime": 1689749619000
    },
    {
        "time": 1689749620000,
        "value": 139.541,
        "_internal_originalTime": 1689749620000
    },
    {
        "time": 1689749622000,
        "value": 139.551,
        "_internal_originalTime": 1689749622000
    },
    {
        "time": 1689749623000,
        "value": 139.546,
        "_internal_originalTime": 1689749623000
    },
    {
        "time": 1689749625000,
        "value": 139.547,
        "_internal_originalTime": 1689749625000
    },
    {
        "time": 1689749626000,
        "value": 139.546,
        "_internal_originalTime": 1689749626000
    },
    {
        "time": 1689749627000,
        "value": 139.556,
        "_internal_originalTime": 1689749627000
    },
    {
        "time": 1689749628000,
        "value": 139.559,
        "_internal_originalTime": 1689749628000
    },
    {
        "time": 1689749629000,
        "value": 139.556,
        "_internal_originalTime": 1689749629000
    },
    {
        "time": 1689749631000,
        "value": 139.557,
        "_internal_originalTime": 1689749631000
    },
    {
        "time": 1689749632000,
        "value": 139.565,
        "_internal_originalTime": 1689749632000
    },
    {
        "time": 1689749633000,
        "value": 139.57,
        "_internal_originalTime": 1689749633000
    },
    {
        "time": 1689749635000,
        "value": 139.57,
        "_internal_originalTime": 1689749635000
    },
    {
        "time": 1689749636000,
        "value": 139.57,
        "_internal_originalTime": 1689749636000
    },
    {
        "time": 1689749637000,
        "value": 139.572,
        "_internal_originalTime": 1689749637000
    },
    {
        "time": 1689749638000,
        "value": 139.573,
        "_internal_originalTime": 1689749638000
    },
    {
        "time": 1689749639000,
        "value": 139.573,
        "_internal_originalTime": 1689749639000
    },
    {
        "time": 1689749640000,
        "value": 139.577,
        "_internal_originalTime": 1689749640000
    },
    {
        "time": 1689749641000,
        "value": 139.572,
        "_internal_originalTime": 1689749641000
    },
    {
        "time": 1689749642000,
        "value": 139.572,
        "_internal_originalTime": 1689749642000
    },
    {
        "time": 1689749644000,
        "value": 139.57,
        "_internal_originalTime": 1689749644000
    },
    {
        "time": 1689749646000,
        "value": 139.575,
        "_internal_originalTime": 1689749646000
    },
    {
        "time": 1689749647000,
        "value": 139.575,
        "_internal_originalTime": 1689749647000
    },
    {
        "time": 1689749648000,
        "value": 139.572,
        "_internal_originalTime": 1689749648000
    },
    {
        "time": 1689749650000,
        "value": 139.574,
        "_internal_originalTime": 1689749650000
    },
    {
        "time": 1689749651000,
        "value": 139.574,
        "_internal_originalTime": 1689749651000
    },
    {
        "time": 1689749655000,
        "value": 139.569,
        "_internal_originalTime": 1689749655000
    },
    {
        "time": 1689749656000,
        "value": 139.568,
        "_internal_originalTime": 1689749656000
    },
    {
        "time": 1689749657000,
        "value": 139.561,
        "_internal_originalTime": 1689749657000
    },
    {
        "time": 1689749658000,
        "value": 139.558,
        "_internal_originalTime": 1689749658000
    },
    {
        "time": 1689749660000,
        "value": 139.551,
        "_internal_originalTime": 1689749660000
    },
    {
        "time": 1689749663000,
        "value": 139.547,
        "_internal_originalTime": 1689749663000
    },
    {
        "time": 1689749664000,
        "value": 139.543,
        "_internal_originalTime": 1689749664000
    },
    {
        "time": 1689749665000,
        "value": 139.549,
        "_internal_originalTime": 1689749665000
    },
    {
        "time": 1689749666000,
        "value": 139.536,
        "_internal_originalTime": 1689749666000
    },
    {
        "time": 1689749668000,
        "value": 139.538,
        "_internal_originalTime": 1689749668000
    },
    {
        "time": 1689749670000,
        "value": 139.534,
        "_internal_originalTime": 1689749670000
    },
    {
        "time": 1689749671000,
        "value": 139.541,
        "_internal_originalTime": 1689749671000
    },
    {
        "time": 1689749672000,
        "value": 139.541,
        "_internal_originalTime": 1689749672000
    },
    {
        "time": 1689749673000,
        "value": 139.543,
        "_internal_originalTime": 1689749673000
    },
    {
        "time": 1689749674000,
        "value": 139.542,
        "_internal_originalTime": 1689749674000
    },
    {
        "time": 1689749676000,
        "value": 139.541,
        "_internal_originalTime": 1689749676000
    },
    {
        "time": 1689749677000,
        "value": 139.539,
        "_internal_originalTime": 1689749677000
    },
    {
        "time": 1689749678000,
        "value": 139.541,
        "_internal_originalTime": 1689749678000
    },
    {
        "time": 1689749679000,
        "value": 139.548,
        "_internal_originalTime": 1689749679000
    },
    {
        "time": 1689749680000,
        "value": 139.552,
        "_internal_originalTime": 1689749680000
    },
    {
        "time": 1689749683000,
        "value": 139.551,
        "_internal_originalTime": 1689749683000
    },
    {
        "time": 1689749684000,
        "value": 139.544,
        "_internal_originalTime": 1689749684000
    },
    {
        "time": 1689749686000,
        "value": 139.537,
        "_internal_originalTime": 1689749686000
    },
    {
        "time": 1689749687000,
        "value": 139.532,
        "_internal_originalTime": 1689749687000
    },
    {
        "time": 1689749688000,
        "value": 139.53,
        "_internal_originalTime": 1689749688000
    },
    {
        "time": 1689749689000,
        "value": 139.534,
        "_internal_originalTime": 1689749689000
    },
    {
        "time": 1689749690000,
        "value": 139.53,
        "_internal_originalTime": 1689749690000
    },
    {
        "time": 1689749691000,
        "value": 139.531,
        "_internal_originalTime": 1689749691000
    },
    {
        "time": 1689749693000,
        "value": 139.528,
        "_internal_originalTime": 1689749693000
    },
    {
        "time": 1689749694000,
        "value": 139.531,
        "_internal_originalTime": 1689749694000
    },
    {
        "time": 1689749695000,
        "value": 139.531,
        "_internal_originalTime": 1689749695000
    },
    {
        "time": 1689749697000,
        "value": 139.531,
        "_internal_originalTime": 1689749697000
    },
    {
        "time": 1689749698000,
        "value": 139.536,
        "_internal_originalTime": 1689749698000
    },
    {
        "time": 1689749699000,
        "value": 139.541,
        "_internal_originalTime": 1689749699000
    },
    {
        "time": 1689749701000,
        "value": 139.542,
        "_internal_originalTime": 1689749701000
    },
    {
        "time": 1689749702000,
        "value": 139.543,
        "_internal_originalTime": 1689749702000
    },
    {
        "time": 1689749703000,
        "value": 139.545,
        "_internal_originalTime": 1689749703000
    },
    {
        "time": 1689749705000,
        "value": 139.545,
        "_internal_originalTime": 1689749705000
    },
    {
        "time": 1689749706000,
        "value": 139.544,
        "_internal_originalTime": 1689749706000
    },
    {
        "time": 1689749708000,
        "value": 139.544,
        "_internal_originalTime": 1689749708000
    },
    {
        "time": 1689749709000,
        "value": 139.541,
        "_internal_originalTime": 1689749709000
    },
    {
        "time": 1689749710000,
        "value": 139.542,
        "_internal_originalTime": 1689749710000
    },
    {
        "time": 1689749711000,
        "value": 139.538,
        "_internal_originalTime": 1689749711000
    },
    {
        "time": 1689749712000,
        "value": 139.541,
        "_internal_originalTime": 1689749712000
    },
    {
        "time": 1689749713000,
        "value": 139.536,
        "_internal_originalTime": 1689749713000
    },
    {
        "time": 1689749715000,
        "value": 139.535,
        "_internal_originalTime": 1689749715000
    },
    {
        "time": 1689749716000,
        "value": 139.526,
        "_internal_originalTime": 1689749716000
    },
    {
        "time": 1689749717000,
        "value": 139.525,
        "_internal_originalTime": 1689749717000
    },
    {
        "time": 1689749718000,
        "value": 139.525,
        "_internal_originalTime": 1689749718000
    },
    {
        "time": 1689749721000,
        "value": 139.529,
        "_internal_originalTime": 1689749721000
    },
    {
        "time": 1689749722000,
        "value": 139.53,
        "_internal_originalTime": 1689749722000
    },
    {
        "time": 1689749725000,
        "value": 139.527,
        "_internal_originalTime": 1689749725000
    },
    {
        "time": 1689749726000,
        "value": 139.524,
        "_internal_originalTime": 1689749726000
    },
    {
        "time": 1689749728000,
        "value": 139.527,
        "_internal_originalTime": 1689749728000
    },
    {
        "time": 1689749729000,
        "value": 139.526,
        "_internal_originalTime": 1689749729000
    },
    {
        "time": 1689749731000,
        "value": 139.522,
        "_internal_originalTime": 1689749731000
    },
    {
        "time": 1689749732000,
        "value": 139.524,
        "_internal_originalTime": 1689749732000
    },
    {
        "time": 1689749733000,
        "value": 139.52,
        "_internal_originalTime": 1689749733000
    },
    {
        "time": 1689749734000,
        "value": 139.521,
        "_internal_originalTime": 1689749734000
    },
    {
        "time": 1689749735000,
        "value": 139.521,
        "_internal_originalTime": 1689749735000
    },
    {
        "time": 1689749737000,
        "value": 139.513,
        "_internal_originalTime": 1689749737000
    },
    {
        "time": 1689749738000,
        "value": 139.509,
        "_internal_originalTime": 1689749738000
    },
    {
        "time": 1689749739000,
        "value": 139.514,
        "_internal_originalTime": 1689749739000
    },
    {
        "time": 1689749740000,
        "value": 139.506,
        "_internal_originalTime": 1689749740000
    },
    {
        "time": 1689749741000,
        "value": 139.513,
        "_internal_originalTime": 1689749741000
    },
    {
        "time": 1689749742000,
        "value": 139.509,
        "_internal_originalTime": 1689749742000
    },
    {
        "time": 1689749744000,
        "value": 139.516,
        "_internal_originalTime": 1689749744000
    },
    {
        "time": 1689749746000,
        "value": 139.517,
        "_internal_originalTime": 1689749746000
    },
    {
        "time": 1689749747000,
        "value": 139.512,
        "_internal_originalTime": 1689749747000
    },
    {
        "time": 1689749748000,
        "value": 139.517,
        "_internal_originalTime": 1689749748000
    },
    {
        "time": 1689749749000,
        "value": 139.513,
        "_internal_originalTime": 1689749749000
    },
    {
        "time": 1689749751000,
        "value": 139.529,
        "_internal_originalTime": 1689749751000
    },
    {
        "time": 1689749752000,
        "value": 139.521,
        "_internal_originalTime": 1689749752000
    },
    {
        "time": 1689749753000,
        "value": 139.52,
        "_internal_originalTime": 1689749753000
    },
    {
        "time": 1689749755000,
        "value": 139.523,
        "_internal_originalTime": 1689749755000
    },
    {
        "time": 1689749757000,
        "value": 139.527,
        "_internal_originalTime": 1689749757000
    },
    {
        "time": 1689749758000,
        "value": 139.528,
        "_internal_originalTime": 1689749758000
    },
    {
        "time": 1689749761000,
        "value": 139.528,
        "_internal_originalTime": 1689749761000
    },
    {
        "time": 1689749762000,
        "value": 139.524,
        "_internal_originalTime": 1689749762000
    },
    {
        "time": 1689749764000,
        "value": 139.524,
        "_internal_originalTime": 1689749764000
    },
    {
        "time": 1689749765000,
        "value": 139.523,
        "_internal_originalTime": 1689749765000
    },
    {
        "time": 1689749766000,
        "value": 139.52,
        "_internal_originalTime": 1689749766000
    },
    {
        "time": 1689749768000,
        "value": 139.524,
        "_internal_originalTime": 1689749768000
    },
    {
        "time": 1689749769000,
        "value": 139.516,
        "_internal_originalTime": 1689749769000
    },
    {
        "time": 1689749770000,
        "value": 139.521,
        "_internal_originalTime": 1689749770000
    },
    {
        "time": 1689749771000,
        "value": 139.519,
        "_internal_originalTime": 1689749771000
    },
    {
        "time": 1689749773000,
        "value": 139.514,
        "_internal_originalTime": 1689749773000
    },
    {
        "time": 1689749775000,
        "value": 139.514,
        "_internal_originalTime": 1689749775000
    },
    {
        "time": 1689749777000,
        "value": 139.517,
        "_internal_originalTime": 1689749777000
    },
    {
        "time": 1689749779000,
        "value": 139.514,
        "_internal_originalTime": 1689749779000
    },
    {
        "time": 1689749781000,
        "value": 139.513,
        "_internal_originalTime": 1689749781000
    },
    {
        "time": 1689749782000,
        "value": 139.515,
        "_internal_originalTime": 1689749782000
    },
    {
        "time": 1689749783000,
        "value": 139.509,
        "_internal_originalTime": 1689749783000
    },
    {
        "time": 1689749784000,
        "value": 139.515,
        "_internal_originalTime": 1689749784000
    },
    {
        "time": 1689749785000,
        "value": 139.514,
        "_internal_originalTime": 1689749785000
    },
    {
        "time": 1689749787000,
        "value": 139.516,
        "_internal_originalTime": 1689749787000
    },
    {
        "time": 1689749788000,
        "value": 139.516,
        "_internal_originalTime": 1689749788000
    },
    {
        "time": 1689749790000,
        "value": 139.514,
        "_internal_originalTime": 1689749790000
    },
    {
        "time": 1689749791000,
        "value": 139.507,
        "_internal_originalTime": 1689749791000
    },
    {
        "time": 1689749793000,
        "value": 139.509,
        "_internal_originalTime": 1689749793000
    },
    {
        "time": 1689749794000,
        "value": 139.511,
        "_internal_originalTime": 1689749794000
    },
    {
        "time": 1689749795000,
        "value": 139.504,
        "_internal_originalTime": 1689749795000
    },
    {
        "time": 1689749796000,
        "value": 139.501,
        "_internal_originalTime": 1689749796000
    },
    {
        "time": 1689749797000,
        "value": 139.503,
        "_internal_originalTime": 1689749797000
    },
    {
        "time": 1689749798000,
        "value": 139.498,
        "_internal_originalTime": 1689749798000
    },
    {
        "time": 1689749801000,
        "value": 139.504,
        "_internal_originalTime": 1689749801000
    },
    {
        "time": 1689749802000,
        "value": 139.506,
        "_internal_originalTime": 1689749802000
    },
    {
        "time": 1689749803000,
        "value": 139.505,
        "_internal_originalTime": 1689749803000
    },
    {
        "time": 1689749804000,
        "value": 139.507,
        "_internal_originalTime": 1689749804000
    },
    {
        "time": 1689749805000,
        "value": 139.506,
        "_internal_originalTime": 1689749805000
    },
    {
        "time": 1689749806000,
        "value": 139.502,
        "_internal_originalTime": 1689749806000
    },
    {
        "time": 1689749808000,
        "value": 139.503,
        "_internal_originalTime": 1689749808000
    },
    {
        "time": 1689749809000,
        "value": 139.504,
        "_internal_originalTime": 1689749809000
    },
    {
        "time": 1689749810000,
        "value": 139.504,
        "_internal_originalTime": 1689749810000
    },
    {
        "time": 1689749811000,
        "value": 139.499,
        "_internal_originalTime": 1689749811000
    },
    {
        "time": 1689749812000,
        "value": 139.5,
        "_internal_originalTime": 1689749812000
    },
    {
        "time": 1689749814000,
        "value": 139.501,
        "_internal_originalTime": 1689749814000
    },
    {
        "time": 1689749815000,
        "value": 139.503,
        "_internal_originalTime": 1689749815000
    },
    {
        "time": 1689749817000,
        "value": 139.498,
        "_internal_originalTime": 1689749817000
    },
    {
        "time": 1689749820000,
        "value": 139.49,
        "_internal_originalTime": 1689749820000
    },
    {
        "time": 1689749821000,
        "value": 139.489,
        "_internal_originalTime": 1689749821000
    },
    {
        "time": 1689749822000,
        "value": 139.485,
        "_internal_originalTime": 1689749822000
    },
    {
        "time": 1689749823000,
        "value": 139.483,
        "_internal_originalTime": 1689749823000
    },
    {
        "time": 1689749824000,
        "value": 139.488,
        "_internal_originalTime": 1689749824000
    },
    {
        "time": 1689749825000,
        "value": 139.487,
        "_internal_originalTime": 1689749825000
    },
    {
        "time": 1689749826000,
        "value": 139.484,
        "_internal_originalTime": 1689749826000
    },
    {
        "time": 1689749827000,
        "value": 139.48,
        "_internal_originalTime": 1689749827000
    },
    {
        "time": 1689749828000,
        "value": 139.476,
        "_internal_originalTime": 1689749828000
    },
    {
        "time": 1689749829000,
        "value": 139.482,
        "_internal_originalTime": 1689749829000
    },
    {
        "time": 1689749830000,
        "value": 139.486,
        "_internal_originalTime": 1689749830000
    },
    {
        "time": 1689749831000,
        "value": 139.484,
        "_internal_originalTime": 1689749831000
    },
    {
        "time": 1689749833000,
        "value": 139.48,
        "_internal_originalTime": 1689749833000
    },
    {
        "time": 1689749835000,
        "value": 139.484,
        "_internal_originalTime": 1689749835000
    },
    {
        "time": 1689749836000,
        "value": 139.483,
        "_internal_originalTime": 1689749836000
    },
    {
        "time": 1689749837000,
        "value": 139.484,
        "_internal_originalTime": 1689749837000
    },
    {
        "time": 1689749839000,
        "value": 139.479,
        "_internal_originalTime": 1689749839000
    },
    {
        "time": 1689749840000,
        "value": 139.477,
        "_internal_originalTime": 1689749840000
    },
    {
        "time": 1689749841000,
        "value": 139.481,
        "_internal_originalTime": 1689749841000
    },
    {
        "time": 1689749842000,
        "value": 139.481,
        "_internal_originalTime": 1689749842000
    },
    {
        "time": 1689749844000,
        "value": 139.48,
        "_internal_originalTime": 1689749844000
    },
    {
        "time": 1689749846000,
        "value": 139.484,
        "_internal_originalTime": 1689749846000
    },
    {
        "time": 1689749847000,
        "value": 139.49,
        "_internal_originalTime": 1689749847000
    },
    {
        "time": 1689749848000,
        "value": 139.488,
        "_internal_originalTime": 1689749848000
    },
    {
        "time": 1689749850000,
        "value": 139.479,
        "_internal_originalTime": 1689749850000
    },
    {
        "time": 1689749852000,
        "value": 139.476,
        "_internal_originalTime": 1689749852000
    },
    {
        "time": 1689749853000,
        "value": 139.478,
        "_internal_originalTime": 1689749853000
    },
    {
        "time": 1689749854000,
        "value": 139.479,
        "_internal_originalTime": 1689749854000
    },
    {
        "time": 1689749855000,
        "value": 139.479,
        "_internal_originalTime": 1689749855000
    },
    {
        "time": 1689749857000,
        "value": 139.478,
        "_internal_originalTime": 1689749857000
    },
    {
        "time": 1689749859000,
        "value": 139.485,
        "_internal_originalTime": 1689749859000
    },
    {
        "time": 1689749860000,
        "value": 139.485,
        "_internal_originalTime": 1689749860000
    },
    {
        "time": 1689749861000,
        "value": 139.485,
        "_internal_originalTime": 1689749861000
    },
    {
        "time": 1689749864000,
        "value": 139.479,
        "_internal_originalTime": 1689749864000
    },
    {
        "time": 1689749867000,
        "value": 139.47,
        "_internal_originalTime": 1689749867000
    },
    {
        "time": 1689749868000,
        "value": 139.474,
        "_internal_originalTime": 1689749868000
    },
    {
        "time": 1689749869000,
        "value": 139.476,
        "_internal_originalTime": 1689749869000
    },
    {
        "time": 1689749870000,
        "value": 139.477,
        "_internal_originalTime": 1689749870000
    },
    {
        "time": 1689749871000,
        "value": 139.473,
        "_internal_originalTime": 1689749871000
    },
    {
        "time": 1689749872000,
        "value": 139.472,
        "_internal_originalTime": 1689749872000
    },
    {
        "time": 1689749874000,
        "value": 139.471,
        "_internal_originalTime": 1689749874000
    },
    {
        "time": 1689749876000,
        "value": 139.473,
        "_internal_originalTime": 1689749876000
    },
    {
        "time": 1689749877000,
        "value": 139.468,
        "_internal_originalTime": 1689749877000
    },
    {
        "time": 1689749878000,
        "value": 139.469,
        "_internal_originalTime": 1689749878000
    },
    {
        "time": 1689749879000,
        "value": 139.473,
        "_internal_originalTime": 1689749879000
    },
    {
        "time": 1689749881000,
        "value": 139.47,
        "_internal_originalTime": 1689749881000
    },
    {
        "time": 1689749882000,
        "value": 139.475,
        "_internal_originalTime": 1689749882000
    },
    {
        "time": 1689749884000,
        "value": 139.469,
        "_internal_originalTime": 1689749884000
    },
    {
        "time": 1689749886000,
        "value": 139.469,
        "_internal_originalTime": 1689749886000
    },
    {
        "time": 1689749887000,
        "value": 139.468,
        "_internal_originalTime": 1689749887000
    },
    {
        "time": 1689749888000,
        "value": 139.469,
        "_internal_originalTime": 1689749888000
    },
    {
        "time": 1689749889000,
        "value": 139.468,
        "_internal_originalTime": 1689749889000
    },
    {
        "time": 1689749890000,
        "value": 139.474,
        "_internal_originalTime": 1689749890000
    },
    {
        "time": 1689749891000,
        "value": 139.474,
        "_internal_originalTime": 1689749891000
    },
    {
        "time": 1689749893000,
        "value": 139.474,
        "_internal_originalTime": 1689749893000
    },
    {
        "time": 1689749895000,
        "value": 139.473,
        "_internal_originalTime": 1689749895000
    },
    {
        "time": 1689749897000,
        "value": 139.471,
        "_internal_originalTime": 1689749897000
    },
    {
        "time": 1689749898000,
        "value": 139.473,
        "_internal_originalTime": 1689749898000
    },
    {
        "time": 1689749899000,
        "value": 139.471,
        "_internal_originalTime": 1689749899000
    },
    {
        "time": 1689749900000,
        "value": 139.464,
        "_internal_originalTime": 1689749900000
    },
    {
        "time": 1689749901000,
        "value": 139.466,
        "_internal_originalTime": 1689749901000
    },
    {
        "time": 1689749902000,
        "value": 139.468,
        "_internal_originalTime": 1689749902000
    },
    {
        "time": 1689749904000,
        "value": 139.468,
        "_internal_originalTime": 1689749904000
    },
    {
        "time": 1689749905000,
        "value": 139.467,
        "_internal_originalTime": 1689749905000
    },
    {
        "time": 1689749907000,
        "value": 139.474,
        "_internal_originalTime": 1689749907000
    },
    {
        "time": 1689749908000,
        "value": 139.464,
        "_internal_originalTime": 1689749908000
    },
    {
        "time": 1689749909000,
        "value": 139.47,
        "_internal_originalTime": 1689749909000
    },
    {
        "time": 1689749910000,
        "value": 139.468,
        "_internal_originalTime": 1689749910000
    },
    {
        "time": 1689749911000,
        "value": 139.464,
        "_internal_originalTime": 1689749911000
    },
    {
        "time": 1689749912000,
        "value": 139.462,
        "_internal_originalTime": 1689749912000
    },
    {
        "time": 1689749914000,
        "value": 139.472,
        "_internal_originalTime": 1689749914000
    },
    {
        "time": 1689749915000,
        "value": 139.468,
        "_internal_originalTime": 1689749915000
    },
    {
        "time": 1689749916000,
        "value": 139.46,
        "_internal_originalTime": 1689749916000
    },
    {
        "time": 1689749917000,
        "value": 139.46,
        "_internal_originalTime": 1689749917000
    },
    {
        "time": 1689749918000,
        "value": 139.463,
        "_internal_originalTime": 1689749918000
    },
    {
        "time": 1689749919000,
        "value": 139.464,
        "_internal_originalTime": 1689749919000
    },
    {
        "time": 1689749921000,
        "value": 139.467,
        "_internal_originalTime": 1689749921000
    },
    {
        "time": 1689749923000,
        "value": 139.45,
        "_internal_originalTime": 1689749923000
    },
    {
        "time": 1689749925000,
        "value": 139.459,
        "_internal_originalTime": 1689749925000
    },
    {
        "time": 1689749926000,
        "value": 139.465,
        "_internal_originalTime": 1689749926000
    },
    {
        "time": 1689749927000,
        "value": 139.466,
        "_internal_originalTime": 1689749927000
    },
    {
        "time": 1689749930000,
        "value": 139.466,
        "_internal_originalTime": 1689749930000
    },
    {
        "time": 1689749931000,
        "value": 139.469,
        "_internal_originalTime": 1689749931000
    },
    {
        "time": 1689749932000,
        "value": 139.468,
        "_internal_originalTime": 1689749932000
    },
    {
        "time": 1689749934000,
        "value": 139.467,
        "_internal_originalTime": 1689749934000
    },
    {
        "time": 1689749936000,
        "value": 139.462,
        "_internal_originalTime": 1689749936000
    },
    {
        "time": 1689749938000,
        "value": 139.449,
        "_internal_originalTime": 1689749938000
    },
    {
        "time": 1689749940000,
        "value": 139.456,
        "_internal_originalTime": 1689749940000
    },
    {
        "time": 1689749941000,
        "value": 139.453,
        "_internal_originalTime": 1689749941000
    },
    {
        "time": 1689749942000,
        "value": 139.456,
        "_internal_originalTime": 1689749942000
    },
    {
        "time": 1689749943000,
        "value": 139.453,
        "_internal_originalTime": 1689749943000
    },
    {
        "time": 1689749944000,
        "value": 139.458,
        "_internal_originalTime": 1689749944000
    },
    {
        "time": 1689749946000,
        "value": 139.455,
        "_internal_originalTime": 1689749946000
    },
    {
        "time": 1689749947000,
        "value": 139.456,
        "_internal_originalTime": 1689749947000
    },
    {
        "time": 1689749948000,
        "value": 139.447,
        "_internal_originalTime": 1689749948000
    },
    {
        "time": 1689749950000,
        "value": 139.443,
        "_internal_originalTime": 1689749950000
    },
    {
        "time": 1689749951000,
        "value": 139.445,
        "_internal_originalTime": 1689749951000
    },
    {
        "time": 1689749952000,
        "value": 139.449,
        "_internal_originalTime": 1689749952000
    },
    {
        "time": 1689749953000,
        "value": 139.449,
        "_internal_originalTime": 1689749953000
    },
    {
        "time": 1689749954000,
        "value": 139.442,
        "_internal_originalTime": 1689749954000
    },
    {
        "time": 1689749955000,
        "value": 139.447,
        "_internal_originalTime": 1689749955000
    },
    {
        "time": 1689749956000,
        "value": 139.45,
        "_internal_originalTime": 1689749956000
    },
    {
        "time": 1689749957000,
        "value": 139.448,
        "_internal_originalTime": 1689749957000
    },
    {
        "time": 1689749959000,
        "value": 139.45,
        "_internal_originalTime": 1689749959000
    },
    {
        "time": 1689749960000,
        "value": 139.452,
        "_internal_originalTime": 1689749960000
    },
    {
        "time": 1689749961000,
        "value": 139.45,
        "_internal_originalTime": 1689749961000
    },
    {
        "time": 1689749962000,
        "value": 139.452,
        "_internal_originalTime": 1689749962000
    },
    {
        "time": 1689749963000,
        "value": 139.454,
        "_internal_originalTime": 1689749963000
    },
    {
        "time": 1689749964000,
        "value": 139.453,
        "_internal_originalTime": 1689749964000
    },
    {
        "time": 1689749965000,
        "value": 139.454,
        "_internal_originalTime": 1689749965000
    },
    {
        "time": 1689749966000,
        "value": 139.456,
        "_internal_originalTime": 1689749966000
    },
    {
        "time": 1689749968000,
        "value": 139.448,
        "_internal_originalTime": 1689749968000
    },
    {
        "time": 1689749969000,
        "value": 139.453,
        "_internal_originalTime": 1689749969000
    },
    {
        "time": 1689749971000,
        "value": 139.45,
        "_internal_originalTime": 1689749971000
    },
    {
        "time": 1689749973000,
        "value": 139.449,
        "_internal_originalTime": 1689749973000
    },
    {
        "time": 1689749974000,
        "value": 139.451,
        "_internal_originalTime": 1689749974000
    },
    {
        "time": 1689749975000,
        "value": 139.452,
        "_internal_originalTime": 1689749975000
    },
    {
        "time": 1689749976000,
        "value": 139.45,
        "_internal_originalTime": 1689749976000
    },
    {
        "time": 1689749977000,
        "value": 139.445,
        "_internal_originalTime": 1689749977000
    },
    {
        "time": 1689749978000,
        "value": 139.449,
        "_internal_originalTime": 1689749978000
    },
    {
        "time": 1689749980000,
        "value": 139.444,
        "_internal_originalTime": 1689749980000
    },
    {
        "time": 1689749982000,
        "value": 139.449,
        "_internal_originalTime": 1689749982000
    },
    {
        "time": 1689749983000,
        "value": 139.449,
        "_internal_originalTime": 1689749983000
    },
    {
        "time": 1689749984000,
        "value": 139.448,
        "_internal_originalTime": 1689749984000
    },
    {
        "time": 1689749985000,
        "value": 139.449,
        "_internal_originalTime": 1689749985000
    },
    {
        "time": 1689749986000,
        "value": 139.444,
        "_internal_originalTime": 1689749986000
    },
    {
        "time": 1689749987000,
        "value": 139.448,
        "_internal_originalTime": 1689749987000
    },
    {
        "time": 1689749989000,
        "value": 139.451,
        "_internal_originalTime": 1689749989000
    },
    {
        "time": 1689749991000,
        "value": 139.452,
        "_internal_originalTime": 1689749991000
    },
    {
        "time": 1689749992000,
        "value": 139.452,
        "_internal_originalTime": 1689749992000
    },
    {
        "time": 1689749993000,
        "value": 139.449,
        "_internal_originalTime": 1689749993000
    },
    {
        "time": 1689749994000,
        "value": 139.451,
        "_internal_originalTime": 1689749994000
    },
    {
        "time": 1689749995000,
        "value": 139.453,
        "_internal_originalTime": 1689749995000
    },
    {
        "time": 1689749997000,
        "value": 139.45,
        "_internal_originalTime": 1689749997000
    },
    {
        "time": 1689749998000,
        "value": 139.447,
        "_internal_originalTime": 1689749998000
    },
    {
        "time": 1689749999000,
        "value": 139.447,
        "_internal_originalTime": 1689749999000
    },
    {
        "time": 1689750000000,
        "value": 139.447,
        "_internal_originalTime": 1689750000000
    },
    {
        "time": 1689750002000,
        "value": 139.436,
        "_internal_originalTime": 1689750002000
    },
    {
        "time": 1689750004000,
        "value": 139.438,
        "_internal_originalTime": 1689750004000
    },
    {
        "time": 1689750005000,
        "value": 139.446,
        "_internal_originalTime": 1689750005000
    },
    {
        "time": 1689750006000,
        "value": 139.441,
        "_internal_originalTime": 1689750006000
    },
    {
        "time": 1689750007000,
        "value": 139.442,
        "_internal_originalTime": 1689750007000
    },
    {
        "time": 1689750008000,
        "value": 139.448,
        "_internal_originalTime": 1689750008000
    },
    {
        "time": 1689750009000,
        "value": 139.446,
        "_internal_originalTime": 1689750009000
    },
    {
        "time": 1689750010000,
        "value": 139.44,
        "_internal_originalTime": 1689750010000
    },
    {
        "time": 1689750011000,
        "value": 139.438,
        "_internal_originalTime": 1689750011000
    },
    {
        "time": 1689750013000,
        "value": 139.436,
        "_internal_originalTime": 1689750013000
    },
    {
        "time": 1689750014000,
        "value": 139.437,
        "_internal_originalTime": 1689750014000
    },
    {
        "time": 1689750015000,
        "value": 139.434,
        "_internal_originalTime": 1689750015000
    },
    {
        "time": 1689750016000,
        "value": 139.439,
        "_internal_originalTime": 1689750016000
    },
    {
        "time": 1689750018000,
        "value": 139.424,
        "_internal_originalTime": 1689750018000
    },
    {
        "time": 1689750019000,
        "value": 139.426,
        "_internal_originalTime": 1689750019000
    },
    {
        "time": 1689750020000,
        "value": 139.422,
        "_internal_originalTime": 1689750020000
    },
    {
        "time": 1689750021000,
        "value": 139.425,
        "_internal_originalTime": 1689750021000
    },
    {
        "time": 1689750022000,
        "value": 139.412,
        "_internal_originalTime": 1689750022000
    },
    {
        "time": 1689750023000,
        "value": 139.416,
        "_internal_originalTime": 1689750023000
    },
    {
        "time": 1689750025000,
        "value": 139.413,
        "_internal_originalTime": 1689750025000
    },
    {
        "time": 1689750026000,
        "value": 139.408,
        "_internal_originalTime": 1689750026000
    },
    {
        "time": 1689750028000,
        "value": 139.403,
        "_internal_originalTime": 1689750028000
    },
    {
        "time": 1689750030000,
        "value": 139.404,
        "_internal_originalTime": 1689750030000
    },
    {
        "time": 1689750031000,
        "value": 139.407,
        "_internal_originalTime": 1689750031000
    },
    {
        "time": 1689750032000,
        "value": 139.396,
        "_internal_originalTime": 1689750032000
    },
    {
        "time": 1689750033000,
        "value": 139.397,
        "_internal_originalTime": 1689750033000
    },
    {
        "time": 1689750034000,
        "value": 139.395,
        "_internal_originalTime": 1689750034000
    },
    {
        "time": 1689750035000,
        "value": 139.397,
        "_internal_originalTime": 1689750035000
    },
    {
        "time": 1689750036000,
        "value": 139.395,
        "_internal_originalTime": 1689750036000
    },
    {
        "time": 1689750038000,
        "value": 139.4,
        "_internal_originalTime": 1689750038000
    },
    {
        "time": 1689750039000,
        "value": 139.404,
        "_internal_originalTime": 1689750039000
    },
    {
        "time": 1689750041000,
        "value": 139.408,
        "_internal_originalTime": 1689750041000
    },
    {
        "time": 1689750043000,
        "value": 139.414,
        "_internal_originalTime": 1689750043000
    },
    {
        "time": 1689750044000,
        "value": 139.41,
        "_internal_originalTime": 1689750044000
    },
    {
        "time": 1689750045000,
        "value": 139.416,
        "_internal_originalTime": 1689750045000
    },
    {
        "time": 1689750046000,
        "value": 139.419,
        "_internal_originalTime": 1689750046000
    },
    {
        "time": 1689750048000,
        "value": 139.422,
        "_internal_originalTime": 1689750048000
    },
    {
        "time": 1689750049000,
        "value": 139.412,
        "_internal_originalTime": 1689750049000
    },
    {
        "time": 1689750050000,
        "value": 139.414,
        "_internal_originalTime": 1689750050000
    },
    {
        "time": 1689750051000,
        "value": 139.408,
        "_internal_originalTime": 1689750051000
    },
    {
        "time": 1689750052000,
        "value": 139.404,
        "_internal_originalTime": 1689750052000
    },
    {
        "time": 1689750053000,
        "value": 139.402,
        "_internal_originalTime": 1689750053000
    },
    {
        "time": 1689750054000,
        "value": 139.403,
        "_internal_originalTime": 1689750054000
    },
    {
        "time": 1689750055000,
        "value": 139.409,
        "_internal_originalTime": 1689750055000
    },
    {
        "time": 1689750056000,
        "value": 139.411,
        "_internal_originalTime": 1689750056000
    },
    {
        "time": 1689750057000,
        "value": 139.408,
        "_internal_originalTime": 1689750057000
    },
    {
        "time": 1689750059000,
        "value": 139.402,
        "_internal_originalTime": 1689750059000
    },
    {
        "time": 1689750060000,
        "value": 139.402,
        "_internal_originalTime": 1689750060000
    },
    {
        "time": 1689750061000,
        "value": 139.405,
        "_internal_originalTime": 1689750061000
    },
    {
        "time": 1689750062000,
        "value": 139.407,
        "_internal_originalTime": 1689750062000
    },
    {
        "time": 1689750063000,
        "value": 139.399,
        "_internal_originalTime": 1689750063000
    },
    {
        "time": 1689750064000,
        "value": 139.395,
        "_internal_originalTime": 1689750064000
    },
    {
        "time": 1689750065000,
        "value": 139.398,
        "_internal_originalTime": 1689750065000
    },
    {
        "time": 1689750066000,
        "value": 139.397,
        "_internal_originalTime": 1689750066000
    },
    {
        "time": 1689750067000,
        "value": 139.403,
        "_internal_originalTime": 1689750067000
    },
    {
        "time": 1689750068000,
        "value": 139.397,
        "_internal_originalTime": 1689750068000
    },
    {
        "time": 1689750069000,
        "value": 139.394,
        "_internal_originalTime": 1689750069000
    },
    {
        "time": 1689750070000,
        "value": 139.393,
        "_internal_originalTime": 1689750070000
    },
    {
        "time": 1689750071000,
        "value": 139.395,
        "_internal_originalTime": 1689750071000
    },
    {
        "time": 1689750072000,
        "value": 139.396,
        "_internal_originalTime": 1689750072000
    },
    {
        "time": 1689750073000,
        "value": 139.395,
        "_internal_originalTime": 1689750073000
    },
    {
        "time": 1689750074000,
        "value": 139.394,
        "_internal_originalTime": 1689750074000
    },
    {
        "time": 1689750075000,
        "value": 139.409,
        "_internal_originalTime": 1689750075000
    },
    {
        "time": 1689750077000,
        "value": 139.403,
        "_internal_originalTime": 1689750077000
    },
    {
        "time": 1689750078000,
        "value": 139.4,
        "_internal_originalTime": 1689750078000
    },
    {
        "time": 1689750079000,
        "value": 139.401,
        "_internal_originalTime": 1689750079000
    },
    {
        "time": 1689750080000,
        "value": 139.396,
        "_internal_originalTime": 1689750080000
    },
    {
        "time": 1689750081000,
        "value": 139.389,
        "_internal_originalTime": 1689750081000
    },
    {
        "time": 1689750082000,
        "value": 139.388,
        "_internal_originalTime": 1689750082000
    },
    {
        "time": 1689750083000,
        "value": 139.389,
        "_internal_originalTime": 1689750083000
    },
    {
        "time": 1689750084000,
        "value": 139.388,
        "_internal_originalTime": 1689750084000
    },
    {
        "time": 1689750085000,
        "value": 139.383,
        "_internal_originalTime": 1689750085000
    },
    {
        "time": 1689750086000,
        "value": 139.381,
        "_internal_originalTime": 1689750086000
    },
    {
        "time": 1689750087000,
        "value": 139.382,
        "_internal_originalTime": 1689750087000
    },
    {
        "time": 1689750088000,
        "value": 139.378,
        "_internal_originalTime": 1689750088000
    },
    {
        "time": 1689750089000,
        "value": 139.379,
        "_internal_originalTime": 1689750089000
    },
    {
        "time": 1689750090000,
        "value": 139.381,
        "_internal_originalTime": 1689750090000
    },
    {
        "time": 1689750092000,
        "value": 139.38,
        "_internal_originalTime": 1689750092000
    },
    {
        "time": 1689750093000,
        "value": 139.378,
        "_internal_originalTime": 1689750093000
    },
    {
        "time": 1689750094000,
        "value": 139.372,
        "_internal_originalTime": 1689750094000
    },
    {
        "time": 1689750095000,
        "value": 139.377,
        "_internal_originalTime": 1689750095000
    },
    {
        "time": 1689750097000,
        "value": 139.378,
        "_internal_originalTime": 1689750097000
    },
    {
        "time": 1689750098000,
        "value": 139.379,
        "_internal_originalTime": 1689750098000
    },
    {
        "time": 1689750100000,
        "value": 139.364,
        "_internal_originalTime": 1689750100000
    },
    {
        "time": 1689750101000,
        "value": 139.356,
        "_internal_originalTime": 1689750101000
    },
    {
        "time": 1689750103000,
        "value": 139.356,
        "_internal_originalTime": 1689750103000
    },
    {
        "time": 1689750105000,
        "value": 139.371,
        "_internal_originalTime": 1689750105000
    },
    {
        "time": 1689750106000,
        "value": 139.376,
        "_internal_originalTime": 1689750106000
    },
    {
        "time": 1689750107000,
        "value": 139.376,
        "_internal_originalTime": 1689750107000
    },
    {
        "time": 1689750108000,
        "value": 139.378,
        "_internal_originalTime": 1689750108000
    },
    {
        "time": 1689750110000,
        "value": 139.378,
        "_internal_originalTime": 1689750110000
    },
    {
        "time": 1689750111000,
        "value": 139.376,
        "_internal_originalTime": 1689750111000
    },
    {
        "time": 1689750112000,
        "value": 139.376,
        "_internal_originalTime": 1689750112000
    },
    {
        "time": 1689750113000,
        "value": 139.379,
        "_internal_originalTime": 1689750113000
    },
    {
        "time": 1689750114000,
        "value": 139.375,
        "_internal_originalTime": 1689750114000
    },
    {
        "time": 1689750115000,
        "value": 139.383,
        "_internal_originalTime": 1689750115000
    },
    {
        "time": 1689750117000,
        "value": 139.388,
        "_internal_originalTime": 1689750117000
    },
    {
        "time": 1689750118000,
        "value": 139.389,
        "_internal_originalTime": 1689750118000
    },
    {
        "time": 1689750119000,
        "value": 139.387,
        "_internal_originalTime": 1689750119000
    },
    {
        "time": 1689750121000,
        "value": 139.387,
        "_internal_originalTime": 1689750121000
    },
    {
        "time": 1689750122000,
        "value": 139.391,
        "_internal_originalTime": 1689750122000
    },
    {
        "time": 1689750123000,
        "value": 139.385,
        "_internal_originalTime": 1689750123000
    },
    {
        "time": 1689750124000,
        "value": 139.385,
        "_internal_originalTime": 1689750124000
    },
    {
        "time": 1689750125000,
        "value": 139.382,
        "_internal_originalTime": 1689750125000
    },
    {
        "time": 1689750126000,
        "value": 139.377,
        "_internal_originalTime": 1689750126000
    },
    {
        "time": 1689750127000,
        "value": 139.379,
        "_internal_originalTime": 1689750127000
    },
    {
        "time": 1689750128000,
        "value": 139.378,
        "_internal_originalTime": 1689750128000
    },
    {
        "time": 1689750129000,
        "value": 139.369,
        "_internal_originalTime": 1689750129000
    },
    {
        "time": 1689750130000,
        "value": 139.372,
        "_internal_originalTime": 1689750130000
    },
    {
        "time": 1689750131000,
        "value": 139.362,
        "_internal_originalTime": 1689750131000
    },
    {
        "time": 1689750132000,
        "value": 139.361,
        "_internal_originalTime": 1689750132000
    },
    {
        "time": 1689750133000,
        "value": 139.36,
        "_internal_originalTime": 1689750133000
    },
    {
        "time": 1689750134000,
        "value": 139.356,
        "_internal_originalTime": 1689750134000
    },
    {
        "time": 1689750135000,
        "value": 139.363,
        "_internal_originalTime": 1689750135000
    },
    {
        "time": 1689750137000,
        "value": 139.356,
        "_internal_originalTime": 1689750137000
    },
    {
        "time": 1689750138000,
        "value": 139.366,
        "_internal_originalTime": 1689750138000
    },
    {
        "time": 1689750139000,
        "value": 139.36,
        "_internal_originalTime": 1689750139000
    },
    {
        "time": 1689750140000,
        "value": 139.363,
        "_internal_originalTime": 1689750140000
    },
    {
        "time": 1689750141000,
        "value": 139.358,
        "_internal_originalTime": 1689750141000
    },
    {
        "time": 1689750142000,
        "value": 139.364,
        "_internal_originalTime": 1689750142000
    },
    {
        "time": 1689750143000,
        "value": 139.367,
        "_internal_originalTime": 1689750143000
    },
    {
        "time": 1689750145000,
        "value": 139.37,
        "_internal_originalTime": 1689750145000
    },
    {
        "time": 1689750150000,
        "value": 139.365,
        "_internal_originalTime": 1689750150000
    },
    {
        "time": 1689750151000,
        "value": 139.376,
        "_internal_originalTime": 1689750151000
    },
    {
        "time": 1689750152000,
        "value": 139.381,
        "_internal_originalTime": 1689750152000
    },
    {
        "time": 1689750154000,
        "value": 139.378,
        "_internal_originalTime": 1689750154000
    },
    {
        "time": 1689750155000,
        "value": 139.384,
        "_internal_originalTime": 1689750155000
    },
    {
        "time": 1689750156000,
        "value": 139.388,
        "_internal_originalTime": 1689750156000
    },
    {
        "time": 1689750157000,
        "value": 139.391,
        "_internal_originalTime": 1689750157000
    },
    {
        "time": 1689750159000,
        "value": 139.405,
        "_internal_originalTime": 1689750159000
    },
    {
        "time": 1689750160000,
        "value": 139.411,
        "_internal_originalTime": 1689750160000
    },
    {
        "time": 1689750162000,
        "value": 139.408,
        "_internal_originalTime": 1689750162000
    },
    {
        "time": 1689750163000,
        "value": 139.409,
        "_internal_originalTime": 1689750163000
    },
    {
        "time": 1689750164000,
        "value": 139.409,
        "_internal_originalTime": 1689750164000
    },
    {
        "time": 1689750165000,
        "value": 139.406,
        "_internal_originalTime": 1689750165000
    },
    {
        "time": 1689750167000,
        "value": 139.4,
        "_internal_originalTime": 1689750167000
    },
    {
        "time": 1689750168000,
        "value": 139.4,
        "_internal_originalTime": 1689750168000
    },
    {
        "time": 1689750169000,
        "value": 139.4,
        "_internal_originalTime": 1689750169000
    },
    {
        "time": 1689750170000,
        "value": 139.394,
        "_internal_originalTime": 1689750170000
    },
    {
        "time": 1689750171000,
        "value": 139.389,
        "_internal_originalTime": 1689750171000
    },
    {
        "time": 1689750172000,
        "value": 139.389,
        "_internal_originalTime": 1689750172000
    },
    {
        "time": 1689750174000,
        "value": 139.388,
        "_internal_originalTime": 1689750174000
    },
    {
        "time": 1689750175000,
        "value": 139.391,
        "_internal_originalTime": 1689750175000
    },
    {
        "time": 1689750176000,
        "value": 139.389,
        "_internal_originalTime": 1689750176000
    },
    {
        "time": 1689750178000,
        "value": 139.389,
        "_internal_originalTime": 1689750178000
    },
    {
        "time": 1689750180000,
        "value": 139.389,
        "_internal_originalTime": 1689750180000
    },
    {
        "time": 1689750181000,
        "value": 139.383,
        "_internal_originalTime": 1689750181000
    },
    {
        "time": 1689750182000,
        "value": 139.388,
        "_internal_originalTime": 1689750182000
    },
    {
        "time": 1689750183000,
        "value": 139.389,
        "_internal_originalTime": 1689750183000
    },
    {
        "time": 1689750184000,
        "value": 139.388,
        "_internal_originalTime": 1689750184000
    },
    {
        "time": 1689750186000,
        "value": 139.389,
        "_internal_originalTime": 1689750186000
    },
    {
        "time": 1689750187000,
        "value": 139.394,
        "_internal_originalTime": 1689750187000
    },
    {
        "time": 1689750188000,
        "value": 139.389,
        "_internal_originalTime": 1689750188000
    },
    {
        "time": 1689750189000,
        "value": 139.383,
        "_internal_originalTime": 1689750189000
    },
    {
        "time": 1689750190000,
        "value": 139.375,
        "_internal_originalTime": 1689750190000
    },
    {
        "time": 1689750191000,
        "value": 139.373,
        "_internal_originalTime": 1689750191000
    },
    {
        "time": 1689750192000,
        "value": 139.373,
        "_internal_originalTime": 1689750192000
    },
    {
        "time": 1689750193000,
        "value": 139.373,
        "_internal_originalTime": 1689750193000
    },
    {
        "time": 1689750194000,
        "value": 139.37,
        "_internal_originalTime": 1689750194000
    },
    {
        "time": 1689750196000,
        "value": 139.375,
        "_internal_originalTime": 1689750196000
    },
    {
        "time": 1689750197000,
        "value": 139.382,
        "_internal_originalTime": 1689750197000
    },
    {
        "time": 1689750199000,
        "value": 139.384,
        "_internal_originalTime": 1689750199000
    },
    {
        "time": 1689750201000,
        "value": 139.384,
        "_internal_originalTime": 1689750201000
    },
    {
        "time": 1689750202000,
        "value": 139.38,
        "_internal_originalTime": 1689750202000
    },
    {
        "time": 1689750203000,
        "value": 139.383,
        "_internal_originalTime": 1689750203000
    },
    {
        "time": 1689750205000,
        "value": 139.384,
        "_internal_originalTime": 1689750205000
    },
    {
        "time": 1689750206000,
        "value": 139.382,
        "_internal_originalTime": 1689750206000
    },
    {
        "time": 1689750207000,
        "value": 139.374,
        "_internal_originalTime": 1689750207000
    },
    {
        "time": 1689750209000,
        "value": 139.368,
        "_internal_originalTime": 1689750209000
    },
    {
        "time": 1689750210000,
        "value": 139.367,
        "_internal_originalTime": 1689750210000
    },
    {
        "time": 1689750211000,
        "value": 139.366,
        "_internal_originalTime": 1689750211000
    },
    {
        "time": 1689750212000,
        "value": 139.363,
        "_internal_originalTime": 1689750212000
    },
    {
        "time": 1689750213000,
        "value": 139.359,
        "_internal_originalTime": 1689750213000
    },
    {
        "time": 1689750215000,
        "value": 139.357,
        "_internal_originalTime": 1689750215000
    },
    {
        "time": 1689750217000,
        "value": 139.361,
        "_internal_originalTime": 1689750217000
    },
    {
        "time": 1689750218000,
        "value": 139.36,
        "_internal_originalTime": 1689750218000
    },
    {
        "time": 1689750219000,
        "value": 139.354,
        "_internal_originalTime": 1689750219000
    },
    {
        "time": 1689750221000,
        "value": 139.36,
        "_internal_originalTime": 1689750221000
    },
    {
        "time": 1689750222000,
        "value": 139.368,
        "_internal_originalTime": 1689750222000
    },
    {
        "time": 1689750224000,
        "value": 139.363,
        "_internal_originalTime": 1689750224000
    },
    {
        "time": 1689750225000,
        "value": 139.358,
        "_internal_originalTime": 1689750225000
    },
    {
        "time": 1689750226000,
        "value": 139.359,
        "_internal_originalTime": 1689750226000
    },
    {
        "time": 1689750227000,
        "value": 139.36,
        "_internal_originalTime": 1689750227000
    },
    {
        "time": 1689750228000,
        "value": 139.357,
        "_internal_originalTime": 1689750228000
    },
    {
        "time": 1689750229000,
        "value": 139.356,
        "_internal_originalTime": 1689750229000
    },
    {
        "time": 1689750231000,
        "value": 139.359,
        "_internal_originalTime": 1689750231000
    },
    {
        "time": 1689750232000,
        "value": 139.358,
        "_internal_originalTime": 1689750232000
    },
    {
        "time": 1689750233000,
        "value": 139.363,
        "_internal_originalTime": 1689750233000
    },
    {
        "time": 1689750234000,
        "value": 139.363,
        "_internal_originalTime": 1689750234000
    },
    {
        "time": 1689750235000,
        "value": 139.364,
        "_internal_originalTime": 1689750235000
    },
    {
        "time": 1689750236000,
        "value": 139.369,
        "_internal_originalTime": 1689750236000
    },
    {
        "time": 1689750238000,
        "value": 139.369,
        "_internal_originalTime": 1689750238000
    },
    {
        "time": 1689750239000,
        "value": 139.367,
        "_internal_originalTime": 1689750239000
    },
    {
        "time": 1689750241000,
        "value": 139.367,
        "_internal_originalTime": 1689750241000
    },
    {
        "time": 1689750243000,
        "value": 139.369,
        "_internal_originalTime": 1689750243000
    },
    {
        "time": 1689750245000,
        "value": 139.366,
        "_internal_originalTime": 1689750245000
    },
    {
        "time": 1689750246000,
        "value": 139.369,
        "_internal_originalTime": 1689750246000
    },
    {
        "time": 1689750248000,
        "value": 139.369,
        "_internal_originalTime": 1689750248000
    },
    {
        "time": 1689750249000,
        "value": 139.381,
        "_internal_originalTime": 1689750249000
    },
    {
        "time": 1689750250000,
        "value": 139.379,
        "_internal_originalTime": 1689750250000
    },
    {
        "time": 1689750251000,
        "value": 139.376,
        "_internal_originalTime": 1689750251000
    },
    {
        "time": 1689750253000,
        "value": 139.378,
        "_internal_originalTime": 1689750253000
    },
    {
        "time": 1689750254000,
        "value": 139.385,
        "_internal_originalTime": 1689750254000
    },
    {
        "time": 1689750256000,
        "value": 139.384,
        "_internal_originalTime": 1689750256000
    },
    {
        "time": 1689750257000,
        "value": 139.38,
        "_internal_originalTime": 1689750257000
    },
    {
        "time": 1689750258000,
        "value": 139.381,
        "_internal_originalTime": 1689750258000
    },
    {
        "time": 1689750259000,
        "value": 139.379,
        "_internal_originalTime": 1689750259000
    },
    {
        "time": 1689750260000,
        "value": 139.38,
        "_internal_originalTime": 1689750260000
    },
    {
        "time": 1689750261000,
        "value": 139.379,
        "_internal_originalTime": 1689750261000
    },
    {
        "time": 1689750262000,
        "value": 139.38,
        "_internal_originalTime": 1689750262000
    },
    {
        "time": 1689750264000,
        "value": 139.366,
        "_internal_originalTime": 1689750264000
    },
    {
        "time": 1689750265000,
        "value": 139.369,
        "_internal_originalTime": 1689750265000
    },
    {
        "time": 1689750267000,
        "value": 139.368,
        "_internal_originalTime": 1689750267000
    },
    {
        "time": 1689750268000,
        "value": 139.37,
        "_internal_originalTime": 1689750268000
    },
    {
        "time": 1689750269000,
        "value": 139.366,
        "_internal_originalTime": 1689750269000
    },
    {
        "time": 1689750270000,
        "value": 139.368,
        "_internal_originalTime": 1689750270000
    },
    {
        "time": 1689750271000,
        "value": 139.357,
        "_internal_originalTime": 1689750271000
    },
    {
        "time": 1689750272000,
        "value": 139.363,
        "_internal_originalTime": 1689750272000
    },
    {
        "time": 1689750273000,
        "value": 139.362,
        "_internal_originalTime": 1689750273000
    },
    {
        "time": 1689750274000,
        "value": 139.364,
        "_internal_originalTime": 1689750274000
    },
    {
        "time": 1689750275000,
        "value": 139.366,
        "_internal_originalTime": 1689750275000
    },
    {
        "time": 1689750276000,
        "value": 139.369,
        "_internal_originalTime": 1689750276000
    },
    {
        "time": 1689750277000,
        "value": 139.373,
        "_internal_originalTime": 1689750277000
    },
    {
        "time": 1689750278000,
        "value": 139.372,
        "_internal_originalTime": 1689750278000
    },
    {
        "time": 1689750280000,
        "value": 139.379,
        "_internal_originalTime": 1689750280000
    },
    {
        "time": 1689750281000,
        "value": 139.38,
        "_internal_originalTime": 1689750281000
    },
    {
        "time": 1689750282000,
        "value": 139.374,
        "_internal_originalTime": 1689750282000
    },
    {
        "time": 1689750283000,
        "value": 139.38,
        "_internal_originalTime": 1689750283000
    },
    {
        "time": 1689750284000,
        "value": 139.377,
        "_internal_originalTime": 1689750284000
    },
    {
        "time": 1689750286000,
        "value": 139.377,
        "_internal_originalTime": 1689750286000
    },
    {
        "time": 1689750287000,
        "value": 139.377,
        "_internal_originalTime": 1689750287000
    },
    {
        "time": 1689750289000,
        "value": 139.377,
        "_internal_originalTime": 1689750289000
    },
    {
        "time": 1689750291000,
        "value": 139.374,
        "_internal_originalTime": 1689750291000
    },
    {
        "time": 1689750292000,
        "value": 139.371,
        "_internal_originalTime": 1689750292000
    },
    {
        "time": 1689750294000,
        "value": 139.368,
        "_internal_originalTime": 1689750294000
    },
    {
        "time": 1689750295000,
        "value": 139.367,
        "_internal_originalTime": 1689750295000
    },
    {
        "time": 1689750296000,
        "value": 139.37,
        "_internal_originalTime": 1689750296000
    },
    {
        "time": 1689750298000,
        "value": 139.371,
        "_internal_originalTime": 1689750298000
    },
    {
        "time": 1689750299000,
        "value": 139.371,
        "_internal_originalTime": 1689750299000
    },
    {
        "time": 1689750300000,
        "value": 139.369,
        "_internal_originalTime": 1689750300000
    },
    {
        "time": 1689750301000,
        "value": 139.369,
        "_internal_originalTime": 1689750301000
    },
    {
        "time": 1689750302000,
        "value": 139.376,
        "_internal_originalTime": 1689750302000
    },
    {
        "time": 1689750303000,
        "value": 139.381,
        "_internal_originalTime": 1689750303000
    },
    {
        "time": 1689750304000,
        "value": 139.388,
        "_internal_originalTime": 1689750304000
    },
    {
        "time": 1689750305000,
        "value": 139.388,
        "_internal_originalTime": 1689750305000
    },
    {
        "time": 1689750306000,
        "value": 139.388,
        "_internal_originalTime": 1689750306000
    },
    {
        "time": 1689750307000,
        "value": 139.388,
        "_internal_originalTime": 1689750307000
    },
    {
        "time": 1689750308000,
        "value": 139.386,
        "_internal_originalTime": 1689750308000
    },
    {
        "time": 1689750310000,
        "value": 139.369,
        "_internal_originalTime": 1689750310000
    },
    {
        "time": 1689750311000,
        "value": 139.369,
        "_internal_originalTime": 1689750311000
    },
    {
        "time": 1689750313000,
        "value": 139.372,
        "_internal_originalTime": 1689750313000
    },
    {
        "time": 1689750314000,
        "value": 139.38,
        "_internal_originalTime": 1689750314000
    },
    {
        "time": 1689750315000,
        "value": 139.381,
        "_internal_originalTime": 1689750315000
    },
    {
        "time": 1689750316000,
        "value": 139.377,
        "_internal_originalTime": 1689750316000
    },
    {
        "time": 1689750317000,
        "value": 139.377,
        "_internal_originalTime": 1689750317000
    },
    {
        "time": 1689750318000,
        "value": 139.376,
        "_internal_originalTime": 1689750318000
    },
    {
        "time": 1689750320000,
        "value": 139.377,
        "_internal_originalTime": 1689750320000
    },
    {
        "time": 1689750323000,
        "value": 139.368,
        "_internal_originalTime": 1689750323000
    },
    {
        "time": 1689750325000,
        "value": 139.362,
        "_internal_originalTime": 1689750325000
    },
    {
        "time": 1689750327000,
        "value": 139.371,
        "_internal_originalTime": 1689750327000
    },
    {
        "time": 1689750328000,
        "value": 139.364,
        "_internal_originalTime": 1689750328000
    },
    {
        "time": 1689750329000,
        "value": 139.369,
        "_internal_originalTime": 1689750329000
    },
    {
        "time": 1689750330000,
        "value": 139.373,
        "_internal_originalTime": 1689750330000
    },
    {
        "time": 1689750331000,
        "value": 139.371,
        "_internal_originalTime": 1689750331000
    },
    {
        "time": 1689750332000,
        "value": 139.364,
        "_internal_originalTime": 1689750332000
    },
    {
        "time": 1689750334000,
        "value": 139.362,
        "_internal_originalTime": 1689750334000
    },
    {
        "time": 1689750335000,
        "value": 139.369,
        "_internal_originalTime": 1689750335000
    },
    {
        "time": 1689750337000,
        "value": 139.354,
        "_internal_originalTime": 1689750337000
    },
    {
        "time": 1689750338000,
        "value": 139.36,
        "_internal_originalTime": 1689750338000
    },
    {
        "time": 1689750339000,
        "value": 139.362,
        "_internal_originalTime": 1689750339000
    },
    {
        "time": 1689750340000,
        "value": 139.365,
        "_internal_originalTime": 1689750340000
    },
    {
        "time": 1689750342000,
        "value": 139.368,
        "_internal_originalTime": 1689750342000
    },
    {
        "time": 1689750343000,
        "value": 139.378,
        "_internal_originalTime": 1689750343000
    },
    {
        "time": 1689750345000,
        "value": 139.38,
        "_internal_originalTime": 1689750345000
    },
    {
        "time": 1689750346000,
        "value": 139.377,
        "_internal_originalTime": 1689750346000
    },
    {
        "time": 1689750347000,
        "value": 139.376,
        "_internal_originalTime": 1689750347000
    },
    {
        "time": 1689750348000,
        "value": 139.382,
        "_internal_originalTime": 1689750348000
    },
    {
        "time": 1689750349000,
        "value": 139.382,
        "_internal_originalTime": 1689750349000
    },
    {
        "time": 1689750351000,
        "value": 139.383,
        "_internal_originalTime": 1689750351000
    },
    {
        "time": 1689750352000,
        "value": 139.382,
        "_internal_originalTime": 1689750352000
    },
    {
        "time": 1689750354000,
        "value": 139.385,
        "_internal_originalTime": 1689750354000
    },
    {
        "time": 1689750355000,
        "value": 139.379,
        "_internal_originalTime": 1689750355000
    },
    {
        "time": 1689750356000,
        "value": 139.382,
        "_internal_originalTime": 1689750356000
    },
    {
        "time": 1689750359000,
        "value": 139.367,
        "_internal_originalTime": 1689750359000
    },
    {
        "time": 1689750360000,
        "value": 139.367,
        "_internal_originalTime": 1689750360000
    },
    {
        "time": 1689750362000,
        "value": 139.367,
        "_internal_originalTime": 1689750362000
    },
    {
        "time": 1689750363000,
        "value": 139.369,
        "_internal_originalTime": 1689750363000
    },
    {
        "time": 1689750364000,
        "value": 139.365,
        "_internal_originalTime": 1689750364000
    },
    {
        "time": 1689750365000,
        "value": 139.367,
        "_internal_originalTime": 1689750365000
    },
    {
        "time": 1689750367000,
        "value": 139.367,
        "_internal_originalTime": 1689750367000
    },
    {
        "time": 1689750368000,
        "value": 139.367,
        "_internal_originalTime": 1689750368000
    },
    {
        "time": 1689750370000,
        "value": 139.367,
        "_internal_originalTime": 1689750370000
    },
    {
        "time": 1689750371000,
        "value": 139.368,
        "_internal_originalTime": 1689750371000
    },
    {
        "time": 1689750372000,
        "value": 139.356,
        "_internal_originalTime": 1689750372000
    },
    {
        "time": 1689750373000,
        "value": 139.361,
        "_internal_originalTime": 1689750373000
    },
    {
        "time": 1689750374000,
        "value": 139.358,
        "_internal_originalTime": 1689750374000
    },
    {
        "time": 1689750375000,
        "value": 139.355,
        "_internal_originalTime": 1689750375000
    },
    {
        "time": 1689750376000,
        "value": 139.344,
        "_internal_originalTime": 1689750376000
    },
    {
        "time": 1689750377000,
        "value": 139.34,
        "_internal_originalTime": 1689750377000
    },
    {
        "time": 1689750379000,
        "value": 139.344,
        "_internal_originalTime": 1689750379000
    },
    {
        "time": 1689750381000,
        "value": 139.35,
        "_internal_originalTime": 1689750381000
    },
    {
        "time": 1689750382000,
        "value": 139.351,
        "_internal_originalTime": 1689750382000
    },
    {
        "time": 1689750383000,
        "value": 139.352,
        "_internal_originalTime": 1689750383000
    },
    {
        "time": 1689750385000,
        "value": 139.346,
        "_internal_originalTime": 1689750385000
    },
    {
        "time": 1689750386000,
        "value": 139.348,
        "_internal_originalTime": 1689750386000
    },
    {
        "time": 1689750388000,
        "value": 139.357,
        "_internal_originalTime": 1689750388000
    },
    {
        "time": 1689750389000,
        "value": 139.358,
        "_internal_originalTime": 1689750389000
    },
    {
        "time": 1689750390000,
        "value": 139.347,
        "_internal_originalTime": 1689750390000
    },
    {
        "time": 1689750391000,
        "value": 139.347,
        "_internal_originalTime": 1689750391000
    },
    {
        "time": 1689750392000,
        "value": 139.35,
        "_internal_originalTime": 1689750392000
    },
    {
        "time": 1689750393000,
        "value": 139.35,
        "_internal_originalTime": 1689750393000
    },
    {
        "time": 1689750394000,
        "value": 139.342,
        "_internal_originalTime": 1689750394000
    },
    {
        "time": 1689750395000,
        "value": 139.344,
        "_internal_originalTime": 1689750395000
    },
    {
        "time": 1689750397000,
        "value": 139.34,
        "_internal_originalTime": 1689750397000
    },
    {
        "time": 1689750398000,
        "value": 139.34,
        "_internal_originalTime": 1689750398000
    },
    {
        "time": 1689750399000,
        "value": 139.341,
        "_internal_originalTime": 1689750399000
    },
    {
        "time": 1689750400000,
        "value": 139.34,
        "_internal_originalTime": 1689750400000
    },
    {
        "time": 1689750402000,
        "value": 139.337,
        "_internal_originalTime": 1689750402000
    },
    {
        "time": 1689750403000,
        "value": 139.336,
        "_internal_originalTime": 1689750403000
    },
    {
        "time": 1689750404000,
        "value": 139.332,
        "_internal_originalTime": 1689750404000
    },
    {
        "time": 1689750406000,
        "value": 139.329,
        "_internal_originalTime": 1689750406000
    },
    {
        "time": 1689750407000,
        "value": 139.332,
        "_internal_originalTime": 1689750407000
    },
    {
        "time": 1689750409000,
        "value": 139.332,
        "_internal_originalTime": 1689750409000
    },
    {
        "time": 1689750410000,
        "value": 139.328,
        "_internal_originalTime": 1689750410000
    },
    {
        "time": 1689750411000,
        "value": 139.331,
        "_internal_originalTime": 1689750411000
    },
    {
        "time": 1689750412000,
        "value": 139.335,
        "_internal_originalTime": 1689750412000
    },
    {
        "time": 1689750413000,
        "value": 139.34,
        "_internal_originalTime": 1689750413000
    },
    {
        "time": 1689750415000,
        "value": 139.333,
        "_internal_originalTime": 1689750415000
    },
    {
        "time": 1689750416000,
        "value": 139.335,
        "_internal_originalTime": 1689750416000
    },
    {
        "time": 1689750417000,
        "value": 139.333,
        "_internal_originalTime": 1689750417000
    },
    {
        "time": 1689750419000,
        "value": 139.332,
        "_internal_originalTime": 1689750419000
    },
    {
        "time": 1689750421000,
        "value": 139.331,
        "_internal_originalTime": 1689750421000
    },
    {
        "time": 1689750422000,
        "value": 139.326,
        "_internal_originalTime": 1689750422000
    },
    {
        "time": 1689750424000,
        "value": 139.327,
        "_internal_originalTime": 1689750424000
    },
    {
        "time": 1689750425000,
        "value": 139.33,
        "_internal_originalTime": 1689750425000
    },
    {
        "time": 1689750426000,
        "value": 139.326,
        "_internal_originalTime": 1689750426000
    },
    {
        "time": 1689750427000,
        "value": 139.324,
        "_internal_originalTime": 1689750427000
    },
    {
        "time": 1689750429000,
        "value": 139.326,
        "_internal_originalTime": 1689750429000
    },
    {
        "time": 1689750430000,
        "value": 139.328,
        "_internal_originalTime": 1689750430000
    },
    {
        "time": 1689750431000,
        "value": 139.328,
        "_internal_originalTime": 1689750431000
    },
    {
        "time": 1689750432000,
        "value": 139.325,
        "_internal_originalTime": 1689750432000
    },
    {
        "time": 1689750433000,
        "value": 139.325,
        "_internal_originalTime": 1689750433000
    },
    {
        "time": 1689750434000,
        "value": 139.328,
        "_internal_originalTime": 1689750434000
    },
    {
        "time": 1689750437000,
        "value": 139.336,
        "_internal_originalTime": 1689750437000
    },
    {
        "time": 1689750438000,
        "value": 139.334,
        "_internal_originalTime": 1689750438000
    },
    {
        "time": 1689750439000,
        "value": 139.336,
        "_internal_originalTime": 1689750439000
    },
    {
        "time": 1689750440000,
        "value": 139.337,
        "_internal_originalTime": 1689750440000
    },
    {
        "time": 1689750441000,
        "value": 139.339,
        "_internal_originalTime": 1689750441000
    },
    {
        "time": 1689750442000,
        "value": 139.33,
        "_internal_originalTime": 1689750442000
    },
    {
        "time": 1689750443000,
        "value": 139.33,
        "_internal_originalTime": 1689750443000
    },
    {
        "time": 1689750444000,
        "value": 139.327,
        "_internal_originalTime": 1689750444000
    },
    {
        "time": 1689750445000,
        "value": 139.328,
        "_internal_originalTime": 1689750445000
    },
    {
        "time": 1689750446000,
        "value": 139.328,
        "_internal_originalTime": 1689750446000
    },
    {
        "time": 1689750448000,
        "value": 139.327,
        "_internal_originalTime": 1689750448000
    },
    {
        "time": 1689750450000,
        "value": 139.322,
        "_internal_originalTime": 1689750450000
    },
    {
        "time": 1689750451000,
        "value": 139.326,
        "_internal_originalTime": 1689750451000
    },
    {
        "time": 1689750452000,
        "value": 139.319,
        "_internal_originalTime": 1689750452000
    },
    {
        "time": 1689750454000,
        "value": 139.318,
        "_internal_originalTime": 1689750454000
    },
    {
        "time": 1689750456000,
        "value": 139.32,
        "_internal_originalTime": 1689750456000
    },
    {
        "time": 1689750457000,
        "value": 139.319,
        "_internal_originalTime": 1689750457000
    },
    {
        "time": 1689750458000,
        "value": 139.319,
        "_internal_originalTime": 1689750458000
    },
    {
        "time": 1689750459000,
        "value": 139.311,
        "_internal_originalTime": 1689750459000
    },
    {
        "time": 1689750460000,
        "value": 139.311,
        "_internal_originalTime": 1689750460000
    },
    {
        "time": 1689750461000,
        "value": 139.307,
        "_internal_originalTime": 1689750461000
    },
    {
        "time": 1689750462000,
        "value": 139.309,
        "_internal_originalTime": 1689750462000
    },
    {
        "time": 1689750463000,
        "value": 139.311,
        "_internal_originalTime": 1689750463000
    },
    {
        "time": 1689750465000,
        "value": 139.313,
        "_internal_originalTime": 1689750465000
    },
    {
        "time": 1689750466000,
        "value": 139.311,
        "_internal_originalTime": 1689750466000
    },
    {
        "time": 1689750467000,
        "value": 139.302,
        "_internal_originalTime": 1689750467000
    },
    {
        "time": 1689750468000,
        "value": 139.304,
        "_internal_originalTime": 1689750468000
    },
    {
        "time": 1689750469000,
        "value": 139.295,
        "_internal_originalTime": 1689750469000
    },
    {
        "time": 1689750470000,
        "value": 139.298,
        "_internal_originalTime": 1689750470000
    },
    {
        "time": 1689750471000,
        "value": 139.298,
        "_internal_originalTime": 1689750471000
    },
    {
        "time": 1689750472000,
        "value": 139.299,
        "_internal_originalTime": 1689750472000
    },
    {
        "time": 1689750473000,
        "value": 139.301,
        "_internal_originalTime": 1689750473000
    },
    {
        "time": 1689750475000,
        "value": 139.295,
        "_internal_originalTime": 1689750475000
    },
    {
        "time": 1689750476000,
        "value": 139.299,
        "_internal_originalTime": 1689750476000
    },
    {
        "time": 1689750477000,
        "value": 139.294,
        "_internal_originalTime": 1689750477000
    },
    {
        "time": 1689750478000,
        "value": 139.305,
        "_internal_originalTime": 1689750478000
    },
    {
        "time": 1689750479000,
        "value": 139.305,
        "_internal_originalTime": 1689750479000
    },
    {
        "time": 1689750480000,
        "value": 139.305,
        "_internal_originalTime": 1689750480000
    },
    {
        "time": 1689750481000,
        "value": 139.303,
        "_internal_originalTime": 1689750481000
    },
    {
        "time": 1689750482000,
        "value": 139.303,
        "_internal_originalTime": 1689750482000
    },
    {
        "time": 1689750483000,
        "value": 139.309,
        "_internal_originalTime": 1689750483000
    },
    {
        "time": 1689750485000,
        "value": 139.31,
        "_internal_originalTime": 1689750485000
    },
    {
        "time": 1689750486000,
        "value": 139.314,
        "_internal_originalTime": 1689750486000
    },
    {
        "time": 1689750487000,
        "value": 139.312,
        "_internal_originalTime": 1689750487000
    },
    {
        "time": 1689750488000,
        "value": 139.304,
        "_internal_originalTime": 1689750488000
    },
    {
        "time": 1689750489000,
        "value": 139.308,
        "_internal_originalTime": 1689750489000
    },
    {
        "time": 1689750490000,
        "value": 139.309,
        "_internal_originalTime": 1689750490000
    },
    {
        "time": 1689750491000,
        "value": 139.31,
        "_internal_originalTime": 1689750491000
    },
    {
        "time": 1689750493000,
        "value": 139.31,
        "_internal_originalTime": 1689750493000
    },
    {
        "time": 1689750494000,
        "value": 139.313,
        "_internal_originalTime": 1689750494000
    },
    {
        "time": 1689750496000,
        "value": 139.31,
        "_internal_originalTime": 1689750496000
    },
    {
        "time": 1689750497000,
        "value": 139.308,
        "_internal_originalTime": 1689750497000
    },
    {
        "time": 1689750498000,
        "value": 139.31,
        "_internal_originalTime": 1689750498000
    },
    {
        "time": 1689750499000,
        "value": 139.308,
        "_internal_originalTime": 1689750499000
    },
    {
        "time": 1689750500000,
        "value": 139.31,
        "_internal_originalTime": 1689750500000
    },
    {
        "time": 1689750501000,
        "value": 139.308,
        "_internal_originalTime": 1689750501000
    },
    {
        "time": 1689750504000,
        "value": 139.311,
        "_internal_originalTime": 1689750504000
    },
    {
        "time": 1689750505000,
        "value": 139.308,
        "_internal_originalTime": 1689750505000
    },
    {
        "time": 1689750506000,
        "value": 139.31,
        "_internal_originalTime": 1689750506000
    },
    {
        "time": 1689750508000,
        "value": 139.305,
        "_internal_originalTime": 1689750508000
    },
    {
        "time": 1689750509000,
        "value": 139.308,
        "_internal_originalTime": 1689750509000
    },
    {
        "time": 1689750510000,
        "value": 139.307,
        "_internal_originalTime": 1689750510000
    },
    {
        "time": 1689750511000,
        "value": 139.304,
        "_internal_originalTime": 1689750511000
    },
    {
        "time": 1689750512000,
        "value": 139.291,
        "_internal_originalTime": 1689750512000
    },
    {
        "time": 1689750514000,
        "value": 139.286,
        "_internal_originalTime": 1689750514000
    },
    {
        "time": 1689750516000,
        "value": 139.285,
        "_internal_originalTime": 1689750516000
    },
    {
        "time": 1689750517000,
        "value": 139.288,
        "_internal_originalTime": 1689750517000
    },
    {
        "time": 1689750518000,
        "value": 139.287,
        "_internal_originalTime": 1689750518000
    },
    {
        "time": 1689750519000,
        "value": 139.283,
        "_internal_originalTime": 1689750519000
    },
    {
        "time": 1689750521000,
        "value": 139.273,
        "_internal_originalTime": 1689750521000
    },
    {
        "time": 1689750522000,
        "value": 139.266,
        "_internal_originalTime": 1689750522000
    },
    {
        "time": 1689750523000,
        "value": 139.267,
        "_internal_originalTime": 1689750523000
    },
    {
        "time": 1689750525000,
        "value": 139.274,
        "_internal_originalTime": 1689750525000
    },
    {
        "time": 1689750526000,
        "value": 139.274,
        "_internal_originalTime": 1689750526000
    },
    {
        "time": 1689750527000,
        "value": 139.275,
        "_internal_originalTime": 1689750527000
    },
    {
        "time": 1689750528000,
        "value": 139.28,
        "_internal_originalTime": 1689750528000
    },
    {
        "time": 1689750529000,
        "value": 139.281,
        "_internal_originalTime": 1689750529000
    },
    {
        "time": 1689750531000,
        "value": 139.281,
        "_internal_originalTime": 1689750531000
    },
    {
        "time": 1689750532000,
        "value": 139.281,
        "_internal_originalTime": 1689750532000
    },
    {
        "time": 1689750533000,
        "value": 139.281,
        "_internal_originalTime": 1689750533000
    },
    {
        "time": 1689750534000,
        "value": 139.281,
        "_internal_originalTime": 1689750534000
    },
    {
        "time": 1689750535000,
        "value": 139.29,
        "_internal_originalTime": 1689750535000
    },
    {
        "time": 1689750536000,
        "value": 139.284,
        "_internal_originalTime": 1689750536000
    },
    {
        "time": 1689750538000,
        "value": 139.286,
        "_internal_originalTime": 1689750538000
    },
    {
        "time": 1689750539000,
        "value": 139.284,
        "_internal_originalTime": 1689750539000
    },
    {
        "time": 1689750540000,
        "value": 139.283,
        "_internal_originalTime": 1689750540000
    },
    {
        "time": 1689750541000,
        "value": 139.282,
        "_internal_originalTime": 1689750541000
    },
    {
        "time": 1689750542000,
        "value": 139.285,
        "_internal_originalTime": 1689750542000
    },
    {
        "time": 1689750543000,
        "value": 139.286,
        "_internal_originalTime": 1689750543000
    },
    {
        "time": 1689750545000,
        "value": 139.293,
        "_internal_originalTime": 1689750545000
    },
    {
        "time": 1689750546000,
        "value": 139.288,
        "_internal_originalTime": 1689750546000
    },
    {
        "time": 1689750547000,
        "value": 139.293,
        "_internal_originalTime": 1689750547000
    },
    {
        "time": 1689750549000,
        "value": 139.283,
        "_internal_originalTime": 1689750549000
    },
    {
        "time": 1689750550000,
        "value": 139.283,
        "_internal_originalTime": 1689750550000
    },
    {
        "time": 1689750551000,
        "value": 139.284,
        "_internal_originalTime": 1689750551000
    },
    {
        "time": 1689750552000,
        "value": 139.281,
        "_internal_originalTime": 1689750552000
    },
    {
        "time": 1689750553000,
        "value": 139.282,
        "_internal_originalTime": 1689750553000
    },
    {
        "time": 1689750554000,
        "value": 139.281,
        "_internal_originalTime": 1689750554000
    },
    {
        "time": 1689750555000,
        "value": 139.283,
        "_internal_originalTime": 1689750555000
    },
    {
        "time": 1689750556000,
        "value": 139.283,
        "_internal_originalTime": 1689750556000
    },
    {
        "time": 1689750557000,
        "value": 139.284,
        "_internal_originalTime": 1689750557000
    },
    {
        "time": 1689750558000,
        "value": 139.282,
        "_internal_originalTime": 1689750558000
    },
    {
        "time": 1689750559000,
        "value": 139.285,
        "_internal_originalTime": 1689750559000
    },
    {
        "time": 1689750560000,
        "value": 139.287,
        "_internal_originalTime": 1689750560000
    },
    {
        "time": 1689750561000,
        "value": 139.283,
        "_internal_originalTime": 1689750561000
    },
    {
        "time": 1689750562000,
        "value": 139.284,
        "_internal_originalTime": 1689750562000
    },
    {
        "time": 1689750563000,
        "value": 139.284,
        "_internal_originalTime": 1689750563000
    },
    {
        "time": 1689750565000,
        "value": 139.274,
        "_internal_originalTime": 1689750565000
    },
    {
        "time": 1689750567000,
        "value": 139.274,
        "_internal_originalTime": 1689750567000
    },
    {
        "time": 1689750568000,
        "value": 139.274,
        "_internal_originalTime": 1689750568000
    },
    {
        "time": 1689750569000,
        "value": 139.275,
        "_internal_originalTime": 1689750569000
    },
    {
        "time": 1689750571000,
        "value": 139.269,
        "_internal_originalTime": 1689750571000
    },
    {
        "time": 1689750572000,
        "value": 139.272,
        "_internal_originalTime": 1689750572000
    },
    {
        "time": 1689750573000,
        "value": 139.274,
        "_internal_originalTime": 1689750573000
    },
    {
        "time": 1689750574000,
        "value": 139.273,
        "_internal_originalTime": 1689750574000
    },
    {
        "time": 1689750575000,
        "value": 139.274,
        "_internal_originalTime": 1689750575000
    },
    {
        "time": 1689750576000,
        "value": 139.273,
        "_internal_originalTime": 1689750576000
    },
    {
        "time": 1689750577000,
        "value": 139.27,
        "_internal_originalTime": 1689750577000
    },
    {
        "time": 1689750578000,
        "value": 139.274,
        "_internal_originalTime": 1689750578000
    },
    {
        "time": 1689750580000,
        "value": 139.262,
        "_internal_originalTime": 1689750580000
    },
    {
        "time": 1689750581000,
        "value": 139.261,
        "_internal_originalTime": 1689750581000
    },
    {
        "time": 1689750582000,
        "value": 139.263,
        "_internal_originalTime": 1689750582000
    },
    {
        "time": 1689750583000,
        "value": 139.265,
        "_internal_originalTime": 1689750583000
    },
    {
        "time": 1689750584000,
        "value": 139.274,
        "_internal_originalTime": 1689750584000
    },
    {
        "time": 1689750585000,
        "value": 139.275,
        "_internal_originalTime": 1689750585000
    },
    {
        "time": 1689750587000,
        "value": 139.276,
        "_internal_originalTime": 1689750587000
    },
    {
        "time": 1689750588000,
        "value": 139.276,
        "_internal_originalTime": 1689750588000
    },
    {
        "time": 1689750589000,
        "value": 139.275,
        "_internal_originalTime": 1689750589000
    },
    {
        "time": 1689750591000,
        "value": 139.276,
        "_internal_originalTime": 1689750591000
    },
    {
        "time": 1689750592000,
        "value": 139.274,
        "_internal_originalTime": 1689750592000
    },
    {
        "time": 1689750593000,
        "value": 139.275,
        "_internal_originalTime": 1689750593000
    },
    {
        "time": 1689750594000,
        "value": 139.272,
        "_internal_originalTime": 1689750594000
    },
    {
        "time": 1689750595000,
        "value": 139.272,
        "_internal_originalTime": 1689750595000
    },
    {
        "time": 1689750597000,
        "value": 139.275,
        "_internal_originalTime": 1689750597000
    },
    {
        "time": 1689750598000,
        "value": 139.276,
        "_internal_originalTime": 1689750598000
    },
    {
        "time": 1689750600000,
        "value": 139.271,
        "_internal_originalTime": 1689750600000
    },
    {
        "time": 1689750601000,
        "value": 139.27,
        "_internal_originalTime": 1689750601000
    },
    {
        "time": 1689750602000,
        "value": 139.284,
        "_internal_originalTime": 1689750602000
    },
    {
        "time": 1689750603000,
        "value": 139.286,
        "_internal_originalTime": 1689750603000
    },
    {
        "time": 1689750604000,
        "value": 139.29,
        "_internal_originalTime": 1689750604000
    },
    {
        "time": 1689750605000,
        "value": 139.29,
        "_internal_originalTime": 1689750605000
    },
    {
        "time": 1689750606000,
        "value": 139.289,
        "_internal_originalTime": 1689750606000
    },
    {
        "time": 1689750607000,
        "value": 139.288,
        "_internal_originalTime": 1689750607000
    },
    {
        "time": 1689750608000,
        "value": 139.278,
        "_internal_originalTime": 1689750608000
    },
    {
        "time": 1689750609000,
        "value": 139.279,
        "_internal_originalTime": 1689750609000
    },
    {
        "time": 1689750611000,
        "value": 139.281,
        "_internal_originalTime": 1689750611000
    },
    {
        "time": 1689750613000,
        "value": 139.262,
        "_internal_originalTime": 1689750613000
    },
    {
        "time": 1689750614000,
        "value": 139.262,
        "_internal_originalTime": 1689750614000
    },
    {
        "time": 1689750615000,
        "value": 139.258,
        "_internal_originalTime": 1689750615000
    },
    {
        "time": 1689750616000,
        "value": 139.259,
        "_internal_originalTime": 1689750616000
    },
    {
        "time": 1689750617000,
        "value": 139.261,
        "_internal_originalTime": 1689750617000
    },
    {
        "time": 1689750619000,
        "value": 139.255,
        "_internal_originalTime": 1689750619000
    },
    {
        "time": 1689750620000,
        "value": 139.25,
        "_internal_originalTime": 1689750620000
    },
    {
        "time": 1689750621000,
        "value": 139.256,
        "_internal_originalTime": 1689750621000
    },
    {
        "time": 1689750622000,
        "value": 139.247,
        "_internal_originalTime": 1689750622000
    },
    {
        "time": 1689750623000,
        "value": 139.245,
        "_internal_originalTime": 1689750623000
    },
    {
        "time": 1689750624000,
        "value": 139.244,
        "_internal_originalTime": 1689750624000
    },
    {
        "time": 1689750625000,
        "value": 139.243,
        "_internal_originalTime": 1689750625000
    },
    {
        "time": 1689750627000,
        "value": 139.241,
        "_internal_originalTime": 1689750627000
    },
    {
        "time": 1689750628000,
        "value": 139.246,
        "_internal_originalTime": 1689750628000
    },
    {
        "time": 1689750629000,
        "value": 139.244,
        "_internal_originalTime": 1689750629000
    },
    {
        "time": 1689750630000,
        "value": 139.24,
        "_internal_originalTime": 1689750630000
    },
    {
        "time": 1689750631000,
        "value": 139.239,
        "_internal_originalTime": 1689750631000
    },
    {
        "time": 1689750632000,
        "value": 139.241,
        "_internal_originalTime": 1689750632000
    },
    {
        "time": 1689750633000,
        "value": 139.242,
        "_internal_originalTime": 1689750633000
    },
    {
        "time": 1689750635000,
        "value": 139.237,
        "_internal_originalTime": 1689750635000
    },
    {
        "time": 1689750637000,
        "value": 139.244,
        "_internal_originalTime": 1689750637000
    },
    {
        "time": 1689750638000,
        "value": 139.246,
        "_internal_originalTime": 1689750638000
    },
    {
        "time": 1689750640000,
        "value": 139.255,
        "_internal_originalTime": 1689750640000
    },
    {
        "time": 1689750641000,
        "value": 139.253,
        "_internal_originalTime": 1689750641000
    },
    {
        "time": 1689750642000,
        "value": 139.256,
        "_internal_originalTime": 1689750642000
    },
    {
        "time": 1689750643000,
        "value": 139.259,
        "_internal_originalTime": 1689750643000
    },
    {
        "time": 1689750644000,
        "value": 139.257,
        "_internal_originalTime": 1689750644000
    },
    {
        "time": 1689750645000,
        "value": 139.262,
        "_internal_originalTime": 1689750645000
    },
    {
        "time": 1689750646000,
        "value": 139.265,
        "_internal_originalTime": 1689750646000
    },
    {
        "time": 1689750648000,
        "value": 139.271,
        "_internal_originalTime": 1689750648000
    },
    {
        "time": 1689750649000,
        "value": 139.271,
        "_internal_originalTime": 1689750649000
    },
    {
        "time": 1689750650000,
        "value": 139.273,
        "_internal_originalTime": 1689750650000
    },
    {
        "time": 1689750651000,
        "value": 139.268,
        "_internal_originalTime": 1689750651000
    },
    {
        "time": 1689750652000,
        "value": 139.272,
        "_internal_originalTime": 1689750652000
    },
    {
        "time": 1689750653000,
        "value": 139.27,
        "_internal_originalTime": 1689750653000
    },
    {
        "time": 1689750654000,
        "value": 139.27,
        "_internal_originalTime": 1689750654000
    },
    {
        "time": 1689750655000,
        "value": 139.266,
        "_internal_originalTime": 1689750655000
    },
    {
        "time": 1689750657000,
        "value": 139.265,
        "_internal_originalTime": 1689750657000
    },
    {
        "time": 1689750658000,
        "value": 139.265,
        "_internal_originalTime": 1689750658000
    },
    {
        "time": 1689750659000,
        "value": 139.268,
        "_internal_originalTime": 1689750659000
    },
    {
        "time": 1689750660000,
        "value": 139.267,
        "_internal_originalTime": 1689750660000
    },
    {
        "time": 1689750661000,
        "value": 139.268,
        "_internal_originalTime": 1689750661000
    },
    {
        "time": 1689750662000,
        "value": 139.27,
        "_internal_originalTime": 1689750662000
    },
    {
        "time": 1689750663000,
        "value": 139.264,
        "_internal_originalTime": 1689750663000
    },
    {
        "time": 1689750664000,
        "value": 139.267,
        "_internal_originalTime": 1689750664000
    },
    {
        "time": 1689750666000,
        "value": 139.267,
        "_internal_originalTime": 1689750666000
    },
    {
        "time": 1689750667000,
        "value": 139.271,
        "_internal_originalTime": 1689750667000
    },
    {
        "time": 1689750668000,
        "value": 139.273,
        "_internal_originalTime": 1689750668000
    },
    {
        "time": 1689750670000,
        "value": 139.268,
        "_internal_originalTime": 1689750670000
    },
    {
        "time": 1689750671000,
        "value": 139.267,
        "_internal_originalTime": 1689750671000
    },
    {
        "time": 1689750673000,
        "value": 139.269,
        "_internal_originalTime": 1689750673000
    },
    {
        "time": 1689750674000,
        "value": 139.267,
        "_internal_originalTime": 1689750674000
    },
    {
        "time": 1689750676000,
        "value": 139.262,
        "_internal_originalTime": 1689750676000
    },
    {
        "time": 1689750677000,
        "value": 139.252,
        "_internal_originalTime": 1689750677000
    },
    {
        "time": 1689750679000,
        "value": 139.25,
        "_internal_originalTime": 1689750679000
    },
    {
        "time": 1689750681000,
        "value": 139.245,
        "_internal_originalTime": 1689750681000
    },
    {
        "time": 1689750682000,
        "value": 139.245,
        "_internal_originalTime": 1689750682000
    },
    {
        "time": 1689750683000,
        "value": 139.239,
        "_internal_originalTime": 1689750683000
    },
    {
        "time": 1689750684000,
        "value": 139.236,
        "_internal_originalTime": 1689750684000
    },
    {
        "time": 1689750685000,
        "value": 139.245,
        "_internal_originalTime": 1689750685000
    },
    {
        "time": 1689750686000,
        "value": 139.248,
        "_internal_originalTime": 1689750686000
    },
    {
        "time": 1689750687000,
        "value": 139.243,
        "_internal_originalTime": 1689750687000
    },
    {
        "time": 1689750688000,
        "value": 139.248,
        "_internal_originalTime": 1689750688000
    },
    {
        "time": 1689750689000,
        "value": 139.244,
        "_internal_originalTime": 1689750689000
    },
    {
        "time": 1689750690000,
        "value": 139.248,
        "_internal_originalTime": 1689750690000
    },
    {
        "time": 1689750691000,
        "value": 139.25,
        "_internal_originalTime": 1689750691000
    },
    {
        "time": 1689750692000,
        "value": 139.252,
        "_internal_originalTime": 1689750692000
    },
    {
        "time": 1689750693000,
        "value": 139.256,
        "_internal_originalTime": 1689750693000
    },
    {
        "time": 1689750694000,
        "value": 139.259,
        "_internal_originalTime": 1689750694000
    },
    {
        "time": 1689750695000,
        "value": 139.26,
        "_internal_originalTime": 1689750695000
    },
    {
        "time": 1689750697000,
        "value": 139.26,
        "_internal_originalTime": 1689750697000
    },
    {
        "time": 1689750698000,
        "value": 139.264,
        "_internal_originalTime": 1689750698000
    },
    {
        "time": 1689750700000,
        "value": 139.263,
        "_internal_originalTime": 1689750700000
    },
    {
        "time": 1689750701000,
        "value": 139.26,
        "_internal_originalTime": 1689750701000
    },
    {
        "time": 1689750702000,
        "value": 139.265,
        "_internal_originalTime": 1689750702000
    },
    {
        "time": 1689750703000,
        "value": 139.264,
        "_internal_originalTime": 1689750703000
    },
    {
        "time": 1689750704000,
        "value": 139.266,
        "_internal_originalTime": 1689750704000
    },
    {
        "time": 1689750705000,
        "value": 139.264,
        "_internal_originalTime": 1689750705000
    },
    {
        "time": 1689750706000,
        "value": 139.263,
        "_internal_originalTime": 1689750706000
    },
    {
        "time": 1689750708000,
        "value": 139.262,
        "_internal_originalTime": 1689750708000
    },
    {
        "time": 1689750709000,
        "value": 139.262,
        "_internal_originalTime": 1689750709000
    },
    {
        "time": 1689750710000,
        "value": 139.265,
        "_internal_originalTime": 1689750710000
    },
    {
        "time": 1689750711000,
        "value": 139.261,
        "_internal_originalTime": 1689750711000
    },
    {
        "time": 1689750712000,
        "value": 139.264,
        "_internal_originalTime": 1689750712000
    },
    {
        "time": 1689750714000,
        "value": 139.261,
        "_internal_originalTime": 1689750714000
    },
    {
        "time": 1689750715000,
        "value": 139.261,
        "_internal_originalTime": 1689750715000
    },
    {
        "time": 1689750716000,
        "value": 139.264,
        "_internal_originalTime": 1689750716000
    },
    {
        "time": 1689750717000,
        "value": 139.262,
        "_internal_originalTime": 1689750717000
    },
    {
        "time": 1689750718000,
        "value": 139.259,
        "_internal_originalTime": 1689750718000
    },
    {
        "time": 1689750719000,
        "value": 139.264,
        "_internal_originalTime": 1689750719000
    },
    {
        "time": 1689750720000,
        "value": 139.261,
        "_internal_originalTime": 1689750720000
    },
    {
        "time": 1689750721000,
        "value": 139.267,
        "_internal_originalTime": 1689750721000
    },
    {
        "time": 1689750722000,
        "value": 139.271,
        "_internal_originalTime": 1689750722000
    },
    {
        "time": 1689750723000,
        "value": 139.27,
        "_internal_originalTime": 1689750723000
    },
    {
        "time": 1689750724000,
        "value": 139.274,
        "_internal_originalTime": 1689750724000
    },
    {
        "time": 1689750725000,
        "value": 139.27,
        "_internal_originalTime": 1689750725000
    },
    {
        "time": 1689750727000,
        "value": 139.252,
        "_internal_originalTime": 1689750727000
    },
    {
        "time": 1689750728000,
        "value": 139.251,
        "_internal_originalTime": 1689750728000
    },
    {
        "time": 1689750730000,
        "value": 139.255,
        "_internal_originalTime": 1689750730000
    },
    {
        "time": 1689750731000,
        "value": 139.255,
        "_internal_originalTime": 1689750731000
    },
    {
        "time": 1689750732000,
        "value": 139.256,
        "_internal_originalTime": 1689750732000
    },
    {
        "time": 1689750734000,
        "value": 139.259,
        "_internal_originalTime": 1689750734000
    },
    {
        "time": 1689750735000,
        "value": 139.264,
        "_internal_originalTime": 1689750735000
    },
    {
        "time": 1689750737000,
        "value": 139.265,
        "_internal_originalTime": 1689750737000
    },
    {
        "time": 1689750738000,
        "value": 139.264,
        "_internal_originalTime": 1689750738000
    },
    {
        "time": 1689750739000,
        "value": 139.265,
        "_internal_originalTime": 1689750739000
    },
    {
        "time": 1689750740000,
        "value": 139.274,
        "_internal_originalTime": 1689750740000
    },
    {
        "time": 1689750742000,
        "value": 139.271,
        "_internal_originalTime": 1689750742000
    },
    {
        "time": 1689750743000,
        "value": 139.266,
        "_internal_originalTime": 1689750743000
    },
    {
        "time": 1689750744000,
        "value": 139.267,
        "_internal_originalTime": 1689750744000
    },
    {
        "time": 1689750745000,
        "value": 139.265,
        "_internal_originalTime": 1689750745000
    },
    {
        "time": 1689750747000,
        "value": 139.265,
        "_internal_originalTime": 1689750747000
    },
    {
        "time": 1689750748000,
        "value": 139.267,
        "_internal_originalTime": 1689750748000
    },
    {
        "time": 1689750749000,
        "value": 139.266,
        "_internal_originalTime": 1689750749000
    },
    {
        "time": 1689750750000,
        "value": 139.261,
        "_internal_originalTime": 1689750750000
    },
    {
        "time": 1689750751000,
        "value": 139.263,
        "_internal_originalTime": 1689750751000
    },
    {
        "time": 1689750753000,
        "value": 139.262,
        "_internal_originalTime": 1689750753000
    },
    {
        "time": 1689750754000,
        "value": 139.265,
        "_internal_originalTime": 1689750754000
    },
    {
        "time": 1689750756000,
        "value": 139.264,
        "_internal_originalTime": 1689750756000
    },
    {
        "time": 1689750757000,
        "value": 139.264,
        "_internal_originalTime": 1689750757000
    },
    {
        "time": 1689750758000,
        "value": 139.253,
        "_internal_originalTime": 1689750758000
    },
    {
        "time": 1689750759000,
        "value": 139.247,
        "_internal_originalTime": 1689750759000
    },
    {
        "time": 1689750761000,
        "value": 139.244,
        "_internal_originalTime": 1689750761000
    },
    {
        "time": 1689750762000,
        "value": 139.245,
        "_internal_originalTime": 1689750762000
    },
    {
        "time": 1689750763000,
        "value": 139.241,
        "_internal_originalTime": 1689750763000
    },
    {
        "time": 1689750765000,
        "value": 139.246,
        "_internal_originalTime": 1689750765000
    },
    {
        "time": 1689750766000,
        "value": 139.259,
        "_internal_originalTime": 1689750766000
    },
    {
        "time": 1689750768000,
        "value": 139.256,
        "_internal_originalTime": 1689750768000
    },
    {
        "time": 1689750769000,
        "value": 139.255,
        "_internal_originalTime": 1689750769000
    },
    {
        "time": 1689750770000,
        "value": 139.256,
        "_internal_originalTime": 1689750770000
    },
    {
        "time": 1689750772000,
        "value": 139.24,
        "_internal_originalTime": 1689750772000
    },
    {
        "time": 1689750773000,
        "value": 139.225,
        "_internal_originalTime": 1689750773000
    },
    {
        "time": 1689750774000,
        "value": 139.23,
        "_internal_originalTime": 1689750774000
    },
    {
        "time": 1689750775000,
        "value": 139.226,
        "_internal_originalTime": 1689750775000
    },
    {
        "time": 1689750777000,
        "value": 139.228,
        "_internal_originalTime": 1689750777000
    },
    {
        "time": 1689750778000,
        "value": 139.222,
        "_internal_originalTime": 1689750778000
    },
    {
        "time": 1689750780000,
        "value": 139.218,
        "_internal_originalTime": 1689750780000
    },
    {
        "time": 1689750781000,
        "value": 139.2,
        "_internal_originalTime": 1689750781000
    },
    {
        "time": 1689750783000,
        "value": 139.213,
        "_internal_originalTime": 1689750783000
    },
    {
        "time": 1689750784000,
        "value": 139.207,
        "_internal_originalTime": 1689750784000
    },
    {
        "time": 1689750785000,
        "value": 139.208,
        "_internal_originalTime": 1689750785000
    },
    {
        "time": 1689750786000,
        "value": 139.206,
        "_internal_originalTime": 1689750786000
    },
    {
        "time": 1689750787000,
        "value": 139.205,
        "_internal_originalTime": 1689750787000
    },
    {
        "time": 1689750789000,
        "value": 139.205,
        "_internal_originalTime": 1689750789000
    },
    {
        "time": 1689750790000,
        "value": 139.197,
        "_internal_originalTime": 1689750790000
    },
    {
        "time": 1689750791000,
        "value": 139.2,
        "_internal_originalTime": 1689750791000
    },
    {
        "time": 1689750792000,
        "value": 139.198,
        "_internal_originalTime": 1689750792000
    },
    {
        "time": 1689750793000,
        "value": 139.2,
        "_internal_originalTime": 1689750793000
    },
    {
        "time": 1689750795000,
        "value": 139.199,
        "_internal_originalTime": 1689750795000
    },
    {
        "time": 1689750796000,
        "value": 139.208,
        "_internal_originalTime": 1689750796000
    },
    {
        "time": 1689750798000,
        "value": 139.2,
        "_internal_originalTime": 1689750798000
    },
    {
        "time": 1689750799000,
        "value": 139.201,
        "_internal_originalTime": 1689750799000
    },
    {
        "time": 1689750801000,
        "value": 139.21,
        "_internal_originalTime": 1689750801000
    },
    {
        "time": 1689750802000,
        "value": 139.21,
        "_internal_originalTime": 1689750802000
    },
    {
        "time": 1689750803000,
        "value": 139.212,
        "_internal_originalTime": 1689750803000
    },
    {
        "time": 1689750804000,
        "value": 139.207,
        "_internal_originalTime": 1689750804000
    },
    {
        "time": 1689750805000,
        "value": 139.208,
        "_internal_originalTime": 1689750805000
    },
    {
        "time": 1689750807000,
        "value": 139.214,
        "_internal_originalTime": 1689750807000
    },
    {
        "time": 1689750808000,
        "value": 139.214,
        "_internal_originalTime": 1689750808000
    },
    {
        "time": 1689750809000,
        "value": 139.211,
        "_internal_originalTime": 1689750809000
    },
    {
        "time": 1689750810000,
        "value": 139.211,
        "_internal_originalTime": 1689750810000
    },
    {
        "time": 1689750811000,
        "value": 139.203,
        "_internal_originalTime": 1689750811000
    },
    {
        "time": 1689750813000,
        "value": 139.206,
        "_internal_originalTime": 1689750813000
    },
    {
        "time": 1689750814000,
        "value": 139.21,
        "_internal_originalTime": 1689750814000
    },
    {
        "time": 1689750815000,
        "value": 139.21,
        "_internal_originalTime": 1689750815000
    },
    {
        "time": 1689750816000,
        "value": 139.211,
        "_internal_originalTime": 1689750816000
    },
    {
        "time": 1689750818000,
        "value": 139.207,
        "_internal_originalTime": 1689750818000
    },
    {
        "time": 1689750819000,
        "value": 139.208,
        "_internal_originalTime": 1689750819000
    },
    {
        "time": 1689750820000,
        "value": 139.216,
        "_internal_originalTime": 1689750820000
    },
    {
        "time": 1689750822000,
        "value": 139.216,
        "_internal_originalTime": 1689750822000
    },
    {
        "time": 1689750823000,
        "value": 139.223,
        "_internal_originalTime": 1689750823000
    },
    {
        "time": 1689750824000,
        "value": 139.234,
        "_internal_originalTime": 1689750824000
    },
    {
        "time": 1689750825000,
        "value": 139.236,
        "_internal_originalTime": 1689750825000
    },
    {
        "time": 1689750826000,
        "value": 139.234,
        "_internal_originalTime": 1689750826000
    },
    {
        "time": 1689750828000,
        "value": 139.226,
        "_internal_originalTime": 1689750828000
    },
    {
        "time": 1689750829000,
        "value": 139.228,
        "_internal_originalTime": 1689750829000
    },
    {
        "time": 1689750830000,
        "value": 139.231,
        "_internal_originalTime": 1689750830000
    },
    {
        "time": 1689750831000,
        "value": 139.234,
        "_internal_originalTime": 1689750831000
    },
    {
        "time": 1689750832000,
        "value": 139.226,
        "_internal_originalTime": 1689750832000
    },
    {
        "time": 1689750833000,
        "value": 139.229,
        "_internal_originalTime": 1689750833000
    },
    {
        "time": 1689750834000,
        "value": 139.228,
        "_internal_originalTime": 1689750834000
    },
    {
        "time": 1689750836000,
        "value": 139.233,
        "_internal_originalTime": 1689750836000
    },
    {
        "time": 1689750837000,
        "value": 139.229,
        "_internal_originalTime": 1689750837000
    },
    {
        "time": 1689750839000,
        "value": 139.233,
        "_internal_originalTime": 1689750839000
    },
    {
        "time": 1689750840000,
        "value": 139.23,
        "_internal_originalTime": 1689750840000
    },
    {
        "time": 1689750841000,
        "value": 139.23,
        "_internal_originalTime": 1689750841000
    },
    {
        "time": 1689750842000,
        "value": 139.233,
        "_internal_originalTime": 1689750842000
    },
    {
        "time": 1689750843000,
        "value": 139.236,
        "_internal_originalTime": 1689750843000
    },
    {
        "time": 1689750845000,
        "value": 139.238,
        "_internal_originalTime": 1689750845000
    },
    {
        "time": 1689750846000,
        "value": 139.243,
        "_internal_originalTime": 1689750846000
    },
    {
        "time": 1689750847000,
        "value": 139.244,
        "_internal_originalTime": 1689750847000
    },
    {
        "time": 1689750849000,
        "value": 139.246,
        "_internal_originalTime": 1689750849000
    },
    {
        "time": 1689750851000,
        "value": 139.245,
        "_internal_originalTime": 1689750851000
    },
    {
        "time": 1689750853000,
        "value": 139.245,
        "_internal_originalTime": 1689750853000
    },
    {
        "time": 1689750855000,
        "value": 139.249,
        "_internal_originalTime": 1689750855000
    },
    {
        "time": 1689750856000,
        "value": 139.257,
        "_internal_originalTime": 1689750856000
    },
    {
        "time": 1689750857000,
        "value": 139.251,
        "_internal_originalTime": 1689750857000
    },
    {
        "time": 1689750858000,
        "value": 139.253,
        "_internal_originalTime": 1689750858000
    },
    {
        "time": 1689750860000,
        "value": 139.252,
        "_internal_originalTime": 1689750860000
    },
    {
        "time": 1689750861000,
        "value": 139.254,
        "_internal_originalTime": 1689750861000
    },
    {
        "time": 1689750862000,
        "value": 139.253,
        "_internal_originalTime": 1689750862000
    },
    {
        "time": 1689750863000,
        "value": 139.256,
        "_internal_originalTime": 1689750863000
    },
    {
        "time": 1689750865000,
        "value": 139.256,
        "_internal_originalTime": 1689750865000
    },
    {
        "time": 1689750866000,
        "value": 139.258,
        "_internal_originalTime": 1689750866000
    },
    {
        "time": 1689750867000,
        "value": 139.265,
        "_internal_originalTime": 1689750867000
    },
    {
        "time": 1689750868000,
        "value": 139.268,
        "_internal_originalTime": 1689750868000
    },
    {
        "time": 1689750869000,
        "value": 139.273,
        "_internal_originalTime": 1689750869000
    },
    {
        "time": 1689750870000,
        "value": 139.271,
        "_internal_originalTime": 1689750870000
    },
    {
        "time": 1689750871000,
        "value": 139.268,
        "_internal_originalTime": 1689750871000
    },
    {
        "time": 1689750872000,
        "value": 139.266,
        "_internal_originalTime": 1689750872000
    },
    {
        "time": 1689750874000,
        "value": 139.264,
        "_internal_originalTime": 1689750874000
    },
    {
        "time": 1689750875000,
        "value": 139.265,
        "_internal_originalTime": 1689750875000
    },
    {
        "time": 1689750876000,
        "value": 139.264,
        "_internal_originalTime": 1689750876000
    },
    {
        "time": 1689750878000,
        "value": 139.258,
        "_internal_originalTime": 1689750878000
    },
    {
        "time": 1689750879000,
        "value": 139.26,
        "_internal_originalTime": 1689750879000
    },
    {
        "time": 1689750881000,
        "value": 139.262,
        "_internal_originalTime": 1689750881000
    },
    {
        "time": 1689750882000,
        "value": 139.252,
        "_internal_originalTime": 1689750882000
    },
    {
        "time": 1689750884000,
        "value": 139.251,
        "_internal_originalTime": 1689750884000
    },
    {
        "time": 1689750885000,
        "value": 139.25,
        "_internal_originalTime": 1689750885000
    },
    {
        "time": 1689750887000,
        "value": 139.248,
        "_internal_originalTime": 1689750887000
    },
    {
        "time": 1689750888000,
        "value": 139.244,
        "_internal_originalTime": 1689750888000
    },
    {
        "time": 1689750889000,
        "value": 139.251,
        "_internal_originalTime": 1689750889000
    },
    {
        "time": 1689750891000,
        "value": 139.253,
        "_internal_originalTime": 1689750891000
    },
    {
        "time": 1689750892000,
        "value": 139.253,
        "_internal_originalTime": 1689750892000
    },
    {
        "time": 1689750893000,
        "value": 139.252,
        "_internal_originalTime": 1689750893000
    },
    {
        "time": 1689750894000,
        "value": 139.249,
        "_internal_originalTime": 1689750894000
    },
    {
        "time": 1689750896000,
        "value": 139.252,
        "_internal_originalTime": 1689750896000
    },
    {
        "time": 1689750897000,
        "value": 139.259,
        "_internal_originalTime": 1689750897000
    },
    {
        "time": 1689750899000,
        "value": 139.263,
        "_internal_originalTime": 1689750899000
    },
    {
        "time": 1689750900000,
        "value": 139.257,
        "_internal_originalTime": 1689750900000
    },
    {
        "time": 1689750901000,
        "value": 139.261,
        "_internal_originalTime": 1689750901000
    },
    {
        "time": 1689750902000,
        "value": 139.263,
        "_internal_originalTime": 1689750902000
    },
    {
        "time": 1689750903000,
        "value": 139.268,
        "_internal_originalTime": 1689750903000
    },
    {
        "time": 1689750904000,
        "value": 139.267,
        "_internal_originalTime": 1689750904000
    },
    {
        "time": 1689750905000,
        "value": 139.264,
        "_internal_originalTime": 1689750905000
    },
    {
        "time": 1689750906000,
        "value": 139.267,
        "_internal_originalTime": 1689750906000
    },
    {
        "time": 1689750907000,
        "value": 139.264,
        "_internal_originalTime": 1689750907000
    },
    {
        "time": 1689750908000,
        "value": 139.265,
        "_internal_originalTime": 1689750908000
    },
    {
        "time": 1689750909000,
        "value": 139.265,
        "_internal_originalTime": 1689750909000
    },
    {
        "time": 1689750910000,
        "value": 139.273,
        "_internal_originalTime": 1689750910000
    },
    {
        "time": 1689750911000,
        "value": 139.271,
        "_internal_originalTime": 1689750911000
    },
    {
        "time": 1689750913000,
        "value": 139.268,
        "_internal_originalTime": 1689750913000
    },
    {
        "time": 1689750915000,
        "value": 139.274,
        "_internal_originalTime": 1689750915000
    },
    {
        "time": 1689750916000,
        "value": 139.276,
        "_internal_originalTime": 1689750916000
    },
    {
        "time": 1689750917000,
        "value": 139.27,
        "_internal_originalTime": 1689750917000
    },
    {
        "time": 1689750919000,
        "value": 139.274,
        "_internal_originalTime": 1689750919000
    },
    {
        "time": 1689750920000,
        "value": 139.278,
        "_internal_originalTime": 1689750920000
    },
    {
        "time": 1689750921000,
        "value": 139.282,
        "_internal_originalTime": 1689750921000
    },
    {
        "time": 1689750923000,
        "value": 139.284,
        "_internal_originalTime": 1689750923000
    },
    {
        "time": 1689750924000,
        "value": 139.284,
        "_internal_originalTime": 1689750924000
    },
    {
        "time": 1689750926000,
        "value": 139.285,
        "_internal_originalTime": 1689750926000
    },
    {
        "time": 1689750927000,
        "value": 139.291,
        "_internal_originalTime": 1689750927000
    },
    {
        "time": 1689750928000,
        "value": 139.292,
        "_internal_originalTime": 1689750928000
    },
    {
        "time": 1689750930000,
        "value": 139.294,
        "_internal_originalTime": 1689750930000
    },
    {
        "time": 1689750931000,
        "value": 139.29,
        "_internal_originalTime": 1689750931000
    },
    {
        "time": 1689750933000,
        "value": 139.29,
        "_internal_originalTime": 1689750933000
    },
    {
        "time": 1689750935000,
        "value": 139.291,
        "_internal_originalTime": 1689750935000
    },
    {
        "time": 1689750937000,
        "value": 139.294,
        "_internal_originalTime": 1689750937000
    },
    {
        "time": 1689750939000,
        "value": 139.294,
        "_internal_originalTime": 1689750939000
    },
    {
        "time": 1689750940000,
        "value": 139.293,
        "_internal_originalTime": 1689750940000
    },
    {
        "time": 1689750941000,
        "value": 139.293,
        "_internal_originalTime": 1689750941000
    },
    {
        "time": 1689750942000,
        "value": 139.293,
        "_internal_originalTime": 1689750942000
    },
    {
        "time": 1689750943000,
        "value": 139.292,
        "_internal_originalTime": 1689750943000
    },
    {
        "time": 1689750944000,
        "value": 139.29,
        "_internal_originalTime": 1689750944000
    },
    {
        "time": 1689750945000,
        "value": 139.296,
        "_internal_originalTime": 1689750945000
    },
    {
        "time": 1689750946000,
        "value": 139.296,
        "_internal_originalTime": 1689750946000
    },
    {
        "time": 1689750947000,
        "value": 139.303,
        "_internal_originalTime": 1689750947000
    },
    {
        "time": 1689750948000,
        "value": 139.305,
        "_internal_originalTime": 1689750948000
    },
    {
        "time": 1689750949000,
        "value": 139.314,
        "_internal_originalTime": 1689750949000
    },
    {
        "time": 1689750951000,
        "value": 139.32,
        "_internal_originalTime": 1689750951000
    },
    {
        "time": 1689750952000,
        "value": 139.321,
        "_internal_originalTime": 1689750952000
    },
    {
        "time": 1689750953000,
        "value": 139.312,
        "_internal_originalTime": 1689750953000
    },
    {
        "time": 1689750955000,
        "value": 139.309,
        "_internal_originalTime": 1689750955000
    },
    {
        "time": 1689750957000,
        "value": 139.307,
        "_internal_originalTime": 1689750957000
    },
    {
        "time": 1689750959000,
        "value": 139.31,
        "_internal_originalTime": 1689750959000
    },
    {
        "time": 1689750960000,
        "value": 139.304,
        "_internal_originalTime": 1689750960000
    },
    {
        "time": 1689750961000,
        "value": 139.302,
        "_internal_originalTime": 1689750961000
    },
    {
        "time": 1689750962000,
        "value": 139.305,
        "_internal_originalTime": 1689750962000
    },
    {
        "time": 1689750963000,
        "value": 139.303,
        "_internal_originalTime": 1689750963000
    },
    {
        "time": 1689750965000,
        "value": 139.306,
        "_internal_originalTime": 1689750965000
    },
    {
        "time": 1689750966000,
        "value": 139.304,
        "_internal_originalTime": 1689750966000
    },
    {
        "time": 1689750967000,
        "value": 139.302,
        "_internal_originalTime": 1689750967000
    },
    {
        "time": 1689750968000,
        "value": 139.306,
        "_internal_originalTime": 1689750968000
    },
    {
        "time": 1689750969000,
        "value": 139.31,
        "_internal_originalTime": 1689750969000
    },
    {
        "time": 1689750970000,
        "value": 139.303,
        "_internal_originalTime": 1689750970000
    },
    {
        "time": 1689750971000,
        "value": 139.302,
        "_internal_originalTime": 1689750971000
    },
    {
        "time": 1689750972000,
        "value": 139.306,
        "_internal_originalTime": 1689750972000
    },
    {
        "time": 1689750973000,
        "value": 139.31,
        "_internal_originalTime": 1689750973000
    },
    {
        "time": 1689750974000,
        "value": 139.309,
        "_internal_originalTime": 1689750974000
    },
    {
        "time": 1689750976000,
        "value": 139.307,
        "_internal_originalTime": 1689750976000
    },
    {
        "time": 1689750977000,
        "value": 139.31,
        "_internal_originalTime": 1689750977000
    },
    {
        "time": 1689750978000,
        "value": 139.309,
        "_internal_originalTime": 1689750978000
    },
    {
        "time": 1689750979000,
        "value": 139.305,
        "_internal_originalTime": 1689750979000
    },
    {
        "time": 1689750981000,
        "value": 139.305,
        "_internal_originalTime": 1689750981000
    },
    {
        "time": 1689750982000,
        "value": 139.309,
        "_internal_originalTime": 1689750982000
    },
    {
        "time": 1689750983000,
        "value": 139.307,
        "_internal_originalTime": 1689750983000
    },
    {
        "time": 1689750984000,
        "value": 139.312,
        "_internal_originalTime": 1689750984000
    },
    {
        "time": 1689750986000,
        "value": 139.313,
        "_internal_originalTime": 1689750986000
    },
    {
        "time": 1689750987000,
        "value": 139.313,
        "_internal_originalTime": 1689750987000
    },
    {
        "time": 1689750988000,
        "value": 139.313,
        "_internal_originalTime": 1689750988000
    },
    {
        "time": 1689750990000,
        "value": 139.312,
        "_internal_originalTime": 1689750990000
    },
    {
        "time": 1689750991000,
        "value": 139.309,
        "_internal_originalTime": 1689750991000
    },
    {
        "time": 1689750992000,
        "value": 139.315,
        "_internal_originalTime": 1689750992000
    },
    {
        "time": 1689750993000,
        "value": 139.31,
        "_internal_originalTime": 1689750993000
    },
    {
        "time": 1689750994000,
        "value": 139.313,
        "_internal_originalTime": 1689750994000
    },
    {
        "time": 1689750995000,
        "value": 139.311,
        "_internal_originalTime": 1689750995000
    },
    {
        "time": 1689750997000,
        "value": 139.313,
        "_internal_originalTime": 1689750997000
    },
    {
        "time": 1689750998000,
        "value": 139.313,
        "_internal_originalTime": 1689750998000
    },
    {
        "time": 1689750999000,
        "value": 139.312,
        "_internal_originalTime": 1689750999000
    },
    {
        "time": 1689751001000,
        "value": 139.305,
        "_internal_originalTime": 1689751001000
    },
    {
        "time": 1689751002000,
        "value": 139.302,
        "_internal_originalTime": 1689751002000
    },
    {
        "time": 1689751003000,
        "value": 139.306,
        "_internal_originalTime": 1689751003000
    },
    {
        "time": 1689751004000,
        "value": 139.304,
        "_internal_originalTime": 1689751004000
    },
    {
        "time": 1689751005000,
        "value": 139.307,
        "_internal_originalTime": 1689751005000
    },
    {
        "time": 1689751006000,
        "value": 139.311,
        "_internal_originalTime": 1689751006000
    },
    {
        "time": 1689751007000,
        "value": 139.309,
        "_internal_originalTime": 1689751007000
    },
    {
        "time": 1689751008000,
        "value": 139.306,
        "_internal_originalTime": 1689751008000
    },
    {
        "time": 1689751010000,
        "value": 139.31,
        "_internal_originalTime": 1689751010000
    },
    {
        "time": 1689751011000,
        "value": 139.311,
        "_internal_originalTime": 1689751011000
    },
    {
        "time": 1689751012000,
        "value": 139.312,
        "_internal_originalTime": 1689751012000
    },
    {
        "time": 1689751014000,
        "value": 139.311,
        "_internal_originalTime": 1689751014000
    },
    {
        "time": 1689751015000,
        "value": 139.31,
        "_internal_originalTime": 1689751015000
    },
    {
        "time": 1689751017000,
        "value": 139.312,
        "_internal_originalTime": 1689751017000
    },
    {
        "time": 1689751018000,
        "value": 139.321,
        "_internal_originalTime": 1689751018000
    },
    {
        "time": 1689751019000,
        "value": 139.314,
        "_internal_originalTime": 1689751019000
    },
    {
        "time": 1689751021000,
        "value": 139.308,
        "_internal_originalTime": 1689751021000
    },
    {
        "time": 1689751022000,
        "value": 139.312,
        "_internal_originalTime": 1689751022000
    },
    {
        "time": 1689751023000,
        "value": 139.312,
        "_internal_originalTime": 1689751023000
    },
    {
        "time": 1689751025000,
        "value": 139.306,
        "_internal_originalTime": 1689751025000
    },
    {
        "time": 1689751026000,
        "value": 139.302,
        "_internal_originalTime": 1689751026000
    },
    {
        "time": 1689751028000,
        "value": 139.305,
        "_internal_originalTime": 1689751028000
    },
    {
        "time": 1689751029000,
        "value": 139.311,
        "_internal_originalTime": 1689751029000
    },
    {
        "time": 1689751030000,
        "value": 139.308,
        "_internal_originalTime": 1689751030000
    },
    {
        "time": 1689751032000,
        "value": 139.312,
        "_internal_originalTime": 1689751032000
    },
    {
        "time": 1689751033000,
        "value": 139.314,
        "_internal_originalTime": 1689751033000
    },
    {
        "time": 1689751034000,
        "value": 139.321,
        "_internal_originalTime": 1689751034000
    },
    {
        "time": 1689751035000,
        "value": 139.323,
        "_internal_originalTime": 1689751035000
    },
    {
        "time": 1689751036000,
        "value": 139.332,
        "_internal_originalTime": 1689751036000
    },
    {
        "time": 1689751037000,
        "value": 139.332,
        "_internal_originalTime": 1689751037000
    },
    {
        "time": 1689751038000,
        "value": 139.327,
        "_internal_originalTime": 1689751038000
    },
    {
        "time": 1689751039000,
        "value": 139.328,
        "_internal_originalTime": 1689751039000
    },
    {
        "time": 1689751040000,
        "value": 139.328,
        "_internal_originalTime": 1689751040000
    },
    {
        "time": 1689751041000,
        "value": 139.328,
        "_internal_originalTime": 1689751041000
    },
    {
        "time": 1689751042000,
        "value": 139.327,
        "_internal_originalTime": 1689751042000
    },
    {
        "time": 1689751043000,
        "value": 139.329,
        "_internal_originalTime": 1689751043000
    },
    {
        "time": 1689751044000,
        "value": 139.329,
        "_internal_originalTime": 1689751044000
    },
    {
        "time": 1689751045000,
        "value": 139.33,
        "_internal_originalTime": 1689751045000
    },
    {
        "time": 1689751046000,
        "value": 139.328,
        "_internal_originalTime": 1689751046000
    },
    {
        "time": 1689751047000,
        "value": 139.339,
        "_internal_originalTime": 1689751047000
    },
    {
        "time": 1689751049000,
        "value": 139.339,
        "_internal_originalTime": 1689751049000
    },
    {
        "time": 1689751050000,
        "value": 139.34,
        "_internal_originalTime": 1689751050000
    },
    {
        "time": 1689751052000,
        "value": 139.34,
        "_internal_originalTime": 1689751052000
    },
    {
        "time": 1689751053000,
        "value": 139.336,
        "_internal_originalTime": 1689751053000
    },
    {
        "time": 1689751054000,
        "value": 139.338,
        "_internal_originalTime": 1689751054000
    },
    {
        "time": 1689751055000,
        "value": 139.351,
        "_internal_originalTime": 1689751055000
    },
    {
        "time": 1689751056000,
        "value": 139.353,
        "_internal_originalTime": 1689751056000
    },
    {
        "time": 1689751057000,
        "value": 139.354,
        "_internal_originalTime": 1689751057000
    },
    {
        "time": 1689751058000,
        "value": 139.353,
        "_internal_originalTime": 1689751058000
    },
    {
        "time": 1689751059000,
        "value": 139.352,
        "_internal_originalTime": 1689751059000
    },
    {
        "time": 1689751060000,
        "value": 139.354,
        "_internal_originalTime": 1689751060000
    },
    {
        "time": 1689751061000,
        "value": 139.351,
        "_internal_originalTime": 1689751061000
    },
    {
        "time": 1689751063000,
        "value": 139.35,
        "_internal_originalTime": 1689751063000
    },
    {
        "time": 1689751064000,
        "value": 139.354,
        "_internal_originalTime": 1689751064000
    },
    {
        "time": 1689751065000,
        "value": 139.357,
        "_internal_originalTime": 1689751065000
    },
    {
        "time": 1689751066000,
        "value": 139.348,
        "_internal_originalTime": 1689751066000
    },
    {
        "time": 1689751067000,
        "value": 139.351,
        "_internal_originalTime": 1689751067000
    },
    {
        "time": 1689751068000,
        "value": 139.338,
        "_internal_originalTime": 1689751068000
    },
    {
        "time": 1689751069000,
        "value": 139.336,
        "_internal_originalTime": 1689751069000
    },
    {
        "time": 1689751070000,
        "value": 139.341,
        "_internal_originalTime": 1689751070000
    },
    {
        "time": 1689751072000,
        "value": 139.337,
        "_internal_originalTime": 1689751072000
    },
    {
        "time": 1689751073000,
        "value": 139.336,
        "_internal_originalTime": 1689751073000
    },
    {
        "time": 1689751074000,
        "value": 139.338,
        "_internal_originalTime": 1689751074000
    },
    {
        "time": 1689751075000,
        "value": 139.338,
        "_internal_originalTime": 1689751075000
    },
    {
        "time": 1689751076000,
        "value": 139.335,
        "_internal_originalTime": 1689751076000
    },
    {
        "time": 1689751077000,
        "value": 139.332,
        "_internal_originalTime": 1689751077000
    },
    {
        "time": 1689751078000,
        "value": 139.336,
        "_internal_originalTime": 1689751078000
    },
    {
        "time": 1689751079000,
        "value": 139.339,
        "_internal_originalTime": 1689751079000
    },
    {
        "time": 1689751080000,
        "value": 139.338,
        "_internal_originalTime": 1689751080000
    },
    {
        "time": 1689751081000,
        "value": 139.33,
        "_internal_originalTime": 1689751081000
    },
    {
        "time": 1689751082000,
        "value": 139.336,
        "_internal_originalTime": 1689751082000
    },
    {
        "time": 1689751083000,
        "value": 139.34,
        "_internal_originalTime": 1689751083000
    },
    {
        "time": 1689751084000,
        "value": 139.336,
        "_internal_originalTime": 1689751084000
    },
    {
        "time": 1689751085000,
        "value": 139.336,
        "_internal_originalTime": 1689751085000
    },
    {
        "time": 1689751086000,
        "value": 139.339,
        "_internal_originalTime": 1689751086000
    },
    {
        "time": 1689751087000,
        "value": 139.337,
        "_internal_originalTime": 1689751087000
    },
    {
        "time": 1689751089000,
        "value": 139.346,
        "_internal_originalTime": 1689751089000
    },
    {
        "time": 1689751090000,
        "value": 139.343,
        "_internal_originalTime": 1689751090000
    },
    {
        "time": 1689751091000,
        "value": 139.342,
        "_internal_originalTime": 1689751091000
    },
    {
        "time": 1689751092000,
        "value": 139.34,
        "_internal_originalTime": 1689751092000
    },
    {
        "time": 1689751093000,
        "value": 139.341,
        "_internal_originalTime": 1689751093000
    },
    {
        "time": 1689751094000,
        "value": 139.349,
        "_internal_originalTime": 1689751094000
    },
    {
        "time": 1689751095000,
        "value": 139.351,
        "_internal_originalTime": 1689751095000
    },
    {
        "time": 1689751096000,
        "value": 139.351,
        "_internal_originalTime": 1689751096000
    },
    {
        "time": 1689751097000,
        "value": 139.35,
        "_internal_originalTime": 1689751097000
    },
    {
        "time": 1689751099000,
        "value": 139.347,
        "_internal_originalTime": 1689751099000
    },
    {
        "time": 1689751100000,
        "value": 139.345,
        "_internal_originalTime": 1689751100000
    },
    {
        "time": 1689751101000,
        "value": 139.346,
        "_internal_originalTime": 1689751101000
    },
    {
        "time": 1689751102000,
        "value": 139.349,
        "_internal_originalTime": 1689751102000
    },
    {
        "time": 1689751103000,
        "value": 139.349,
        "_internal_originalTime": 1689751103000
    },
    {
        "time": 1689751104000,
        "value": 139.348,
        "_internal_originalTime": 1689751104000
    },
    {
        "time": 1689751105000,
        "value": 139.349,
        "_internal_originalTime": 1689751105000
    },
    {
        "time": 1689751106000,
        "value": 139.349,
        "_internal_originalTime": 1689751106000
    },
    {
        "time": 1689751107000,
        "value": 139.349,
        "_internal_originalTime": 1689751107000
    },
    {
        "time": 1689751108000,
        "value": 139.346,
        "_internal_originalTime": 1689751108000
    },
    {
        "time": 1689751110000,
        "value": 139.348,
        "_internal_originalTime": 1689751110000
    },
    {
        "time": 1689751112000,
        "value": 139.346,
        "_internal_originalTime": 1689751112000
    },
    {
        "time": 1689751113000,
        "value": 139.341,
        "_internal_originalTime": 1689751113000
    },
    {
        "time": 1689751114000,
        "value": 139.345,
        "_internal_originalTime": 1689751114000
    },
    {
        "time": 1689751116000,
        "value": 139.336,
        "_internal_originalTime": 1689751116000
    },
    {
        "time": 1689751117000,
        "value": 139.338,
        "_internal_originalTime": 1689751117000
    },
    {
        "time": 1689751118000,
        "value": 139.342,
        "_internal_originalTime": 1689751118000
    },
    {
        "time": 1689751119000,
        "value": 139.336,
        "_internal_originalTime": 1689751119000
    },
    {
        "time": 1689751120000,
        "value": 139.339,
        "_internal_originalTime": 1689751120000
    },
    {
        "time": 1689751121000,
        "value": 139.336,
        "_internal_originalTime": 1689751121000
    },
    {
        "time": 1689751123000,
        "value": 139.345,
        "_internal_originalTime": 1689751123000
    },
    {
        "time": 1689751124000,
        "value": 139.345,
        "_internal_originalTime": 1689751124000
    },
    {
        "time": 1689751125000,
        "value": 139.34,
        "_internal_originalTime": 1689751125000
    },
    {
        "time": 1689751126000,
        "value": 139.345,
        "_internal_originalTime": 1689751126000
    },
    {
        "time": 1689751129000,
        "value": 139.346,
        "_internal_originalTime": 1689751129000
    },
    {
        "time": 1689751130000,
        "value": 139.34,
        "_internal_originalTime": 1689751130000
    },
    {
        "time": 1689751131000,
        "value": 139.341,
        "_internal_originalTime": 1689751131000
    },
    {
        "time": 1689751132000,
        "value": 139.345,
        "_internal_originalTime": 1689751132000
    },
    {
        "time": 1689751133000,
        "value": 139.345,
        "_internal_originalTime": 1689751133000
    },
    {
        "time": 1689751134000,
        "value": 139.34,
        "_internal_originalTime": 1689751134000
    },
    {
        "time": 1689751135000,
        "value": 139.341,
        "_internal_originalTime": 1689751135000
    },
    {
        "time": 1689751136000,
        "value": 139.344,
        "_internal_originalTime": 1689751136000
    },
    {
        "time": 1689751138000,
        "value": 139.351,
        "_internal_originalTime": 1689751138000
    },
    {
        "time": 1689751140000,
        "value": 139.348,
        "_internal_originalTime": 1689751140000
    },
    {
        "time": 1689751141000,
        "value": 139.344,
        "_internal_originalTime": 1689751141000
    },
    {
        "time": 1689751142000,
        "value": 139.352,
        "_internal_originalTime": 1689751142000
    },
    {
        "time": 1689751143000,
        "value": 139.353,
        "_internal_originalTime": 1689751143000
    },
    {
        "time": 1689751144000,
        "value": 139.351,
        "_internal_originalTime": 1689751144000
    },
    {
        "time": 1689751146000,
        "value": 139.352,
        "_internal_originalTime": 1689751146000
    },
    {
        "time": 1689751147000,
        "value": 139.353,
        "_internal_originalTime": 1689751147000
    },
    {
        "time": 1689751148000,
        "value": 139.352,
        "_internal_originalTime": 1689751148000
    },
    {
        "time": 1689751149000,
        "value": 139.353,
        "_internal_originalTime": 1689751149000
    },
    {
        "time": 1689751152000,
        "value": 139.343,
        "_internal_originalTime": 1689751152000
    },
    {
        "time": 1689751153000,
        "value": 139.342,
        "_internal_originalTime": 1689751153000
    },
    {
        "time": 1689751154000,
        "value": 139.344,
        "_internal_originalTime": 1689751154000
    },
    {
        "time": 1689751155000,
        "value": 139.352,
        "_internal_originalTime": 1689751155000
    },
    {
        "time": 1689751157000,
        "value": 139.352,
        "_internal_originalTime": 1689751157000
    },
    {
        "time": 1689751158000,
        "value": 139.353,
        "_internal_originalTime": 1689751158000
    },
    {
        "time": 1689751160000,
        "value": 139.353,
        "_internal_originalTime": 1689751160000
    },
    {
        "time": 1689751161000,
        "value": 139.357,
        "_internal_originalTime": 1689751161000
    },
    {
        "time": 1689751162000,
        "value": 139.36,
        "_internal_originalTime": 1689751162000
    },
    {
        "time": 1689751163000,
        "value": 139.362,
        "_internal_originalTime": 1689751163000
    },
    {
        "time": 1689751164000,
        "value": 139.361,
        "_internal_originalTime": 1689751164000
    },
    {
        "time": 1689751165000,
        "value": 139.372,
        "_internal_originalTime": 1689751165000
    },
    {
        "time": 1689751166000,
        "value": 139.369,
        "_internal_originalTime": 1689751166000
    },
    {
        "time": 1689751167000,
        "value": 139.372,
        "_internal_originalTime": 1689751167000
    },
    {
        "time": 1689751169000,
        "value": 139.374,
        "_internal_originalTime": 1689751169000
    },
    {
        "time": 1689751170000,
        "value": 139.38,
        "_internal_originalTime": 1689751170000
    },
    {
        "time": 1689751172000,
        "value": 139.382,
        "_internal_originalTime": 1689751172000
    },
    {
        "time": 1689751173000,
        "value": 139.369,
        "_internal_originalTime": 1689751173000
    },
    {
        "time": 1689751174000,
        "value": 139.373,
        "_internal_originalTime": 1689751174000
    },
    {
        "time": 1689751175000,
        "value": 139.372,
        "_internal_originalTime": 1689751175000
    },
    {
        "time": 1689751176000,
        "value": 139.372,
        "_internal_originalTime": 1689751176000
    },
    {
        "time": 1689751177000,
        "value": 139.373,
        "_internal_originalTime": 1689751177000
    },
    {
        "time": 1689751178000,
        "value": 139.372,
        "_internal_originalTime": 1689751178000
    },
    {
        "time": 1689751179000,
        "value": 139.372,
        "_internal_originalTime": 1689751179000
    },
    {
        "time": 1689751180000,
        "value": 139.375,
        "_internal_originalTime": 1689751180000
    },
    {
        "time": 1689751181000,
        "value": 139.376,
        "_internal_originalTime": 1689751181000
    },
    {
        "time": 1689751182000,
        "value": 139.361,
        "_internal_originalTime": 1689751182000
    },
    {
        "time": 1689751183000,
        "value": 139.362,
        "_internal_originalTime": 1689751183000
    },
    {
        "time": 1689751185000,
        "value": 139.361,
        "_internal_originalTime": 1689751185000
    },
    {
        "time": 1689751186000,
        "value": 139.369,
        "_internal_originalTime": 1689751186000
    },
    {
        "time": 1689751187000,
        "value": 139.372,
        "_internal_originalTime": 1689751187000
    },
    {
        "time": 1689751188000,
        "value": 139.368,
        "_internal_originalTime": 1689751188000
    },
    {
        "time": 1689751189000,
        "value": 139.374,
        "_internal_originalTime": 1689751189000
    },
    {
        "time": 1689751190000,
        "value": 139.373,
        "_internal_originalTime": 1689751190000
    },
    {
        "time": 1689751191000,
        "value": 139.369,
        "_internal_originalTime": 1689751191000
    },
    {
        "time": 1689751192000,
        "value": 139.367,
        "_internal_originalTime": 1689751192000
    },
    {
        "time": 1689751193000,
        "value": 139.372,
        "_internal_originalTime": 1689751193000
    },
    {
        "time": 1689751194000,
        "value": 139.372,
        "_internal_originalTime": 1689751194000
    },
    {
        "time": 1689751195000,
        "value": 139.366,
        "_internal_originalTime": 1689751195000
    },
    {
        "time": 1689751196000,
        "value": 139.369,
        "_internal_originalTime": 1689751196000
    },
    {
        "time": 1689751197000,
        "value": 139.369,
        "_internal_originalTime": 1689751197000
    },
    {
        "time": 1689751199000,
        "value": 139.36,
        "_internal_originalTime": 1689751199000
    },
    {
        "time": 1689751200000,
        "value": 139.359,
        "_internal_originalTime": 1689751200000
    },
    {
        "time": 1689751201000,
        "value": 139.359,
        "_internal_originalTime": 1689751201000
    },
    {
        "time": 1689751202000,
        "value": 139.355,
        "_internal_originalTime": 1689751202000
    },
    {
        "time": 1689751203000,
        "value": 139.359,
        "_internal_originalTime": 1689751203000
    },
    {
        "time": 1689751204000,
        "value": 139.362,
        "_internal_originalTime": 1689751204000
    },
    {
        "time": 1689751205000,
        "value": 139.361,
        "_internal_originalTime": 1689751205000
    },
    {
        "time": 1689751206000,
        "value": 139.36,
        "_internal_originalTime": 1689751206000
    },
    {
        "time": 1689751207000,
        "value": 139.36,
        "_internal_originalTime": 1689751207000
    },
    {
        "time": 1689751209000,
        "value": 139.352,
        "_internal_originalTime": 1689751209000
    },
    {
        "time": 1689751210000,
        "value": 139.349,
        "_internal_originalTime": 1689751210000
    },
    {
        "time": 1689751211000,
        "value": 139.353,
        "_internal_originalTime": 1689751211000
    },
    {
        "time": 1689751212000,
        "value": 139.351,
        "_internal_originalTime": 1689751212000
    },
    {
        "time": 1689751213000,
        "value": 139.347,
        "_internal_originalTime": 1689751213000
    },
    {
        "time": 1689751215000,
        "value": 139.349,
        "_internal_originalTime": 1689751215000
    },
    {
        "time": 1689751216000,
        "value": 139.349,
        "_internal_originalTime": 1689751216000
    },
    {
        "time": 1689751218000,
        "value": 139.34,
        "_internal_originalTime": 1689751218000
    },
    {
        "time": 1689751219000,
        "value": 139.337,
        "_internal_originalTime": 1689751219000
    },
    {
        "time": 1689751220000,
        "value": 139.34,
        "_internal_originalTime": 1689751220000
    },
    {
        "time": 1689751222000,
        "value": 139.34,
        "_internal_originalTime": 1689751222000
    },
    {
        "time": 1689751223000,
        "value": 139.336,
        "_internal_originalTime": 1689751223000
    },
    {
        "time": 1689751225000,
        "value": 139.34,
        "_internal_originalTime": 1689751225000
    },
    {
        "time": 1689751227000,
        "value": 139.351,
        "_internal_originalTime": 1689751227000
    },
    {
        "time": 1689751228000,
        "value": 139.346,
        "_internal_originalTime": 1689751228000
    },
    {
        "time": 1689751230000,
        "value": 139.35,
        "_internal_originalTime": 1689751230000
    },
    {
        "time": 1689751231000,
        "value": 139.356,
        "_internal_originalTime": 1689751231000
    },
    {
        "time": 1689751233000,
        "value": 139.352,
        "_internal_originalTime": 1689751233000
    },
    {
        "time": 1689751235000,
        "value": 139.348,
        "_internal_originalTime": 1689751235000
    },
    {
        "time": 1689751236000,
        "value": 139.347,
        "_internal_originalTime": 1689751236000
    },
    {
        "time": 1689751237000,
        "value": 139.351,
        "_internal_originalTime": 1689751237000
    },
    {
        "time": 1689751238000,
        "value": 139.35,
        "_internal_originalTime": 1689751238000
    },
    {
        "time": 1689751239000,
        "value": 139.345,
        "_internal_originalTime": 1689751239000
    },
    {
        "time": 1689751240000,
        "value": 139.339,
        "_internal_originalTime": 1689751240000
    },
    {
        "time": 1689751241000,
        "value": 139.344,
        "_internal_originalTime": 1689751241000
    },
    {
        "time": 1689751242000,
        "value": 139.343,
        "_internal_originalTime": 1689751242000
    },
    {
        "time": 1689751243000,
        "value": 139.342,
        "_internal_originalTime": 1689751243000
    },
    {
        "time": 1689751245000,
        "value": 139.343,
        "_internal_originalTime": 1689751245000
    },
    {
        "time": 1689751247000,
        "value": 139.337,
        "_internal_originalTime": 1689751247000
    },
    {
        "time": 1689751249000,
        "value": 139.341,
        "_internal_originalTime": 1689751249000
    },
    {
        "time": 1689751251000,
        "value": 139.341,
        "_internal_originalTime": 1689751251000
    },
    {
        "time": 1689751252000,
        "value": 139.342,
        "_internal_originalTime": 1689751252000
    },
    {
        "time": 1689751253000,
        "value": 139.344,
        "_internal_originalTime": 1689751253000
    },
    {
        "time": 1689751255000,
        "value": 139.34,
        "_internal_originalTime": 1689751255000
    },
    {
        "time": 1689751258000,
        "value": 139.34,
        "_internal_originalTime": 1689751258000
    },
    {
        "time": 1689751260000,
        "value": 139.342,
        "_internal_originalTime": 1689751260000
    },
    {
        "time": 1689751261000,
        "value": 139.338,
        "_internal_originalTime": 1689751261000
    },
    {
        "time": 1689751262000,
        "value": 139.342,
        "_internal_originalTime": 1689751262000
    },
    {
        "time": 1689751263000,
        "value": 139.344,
        "_internal_originalTime": 1689751263000
    },
    {
        "time": 1689751265000,
        "value": 139.35,
        "_internal_originalTime": 1689751265000
    },
    {
        "time": 1689751266000,
        "value": 139.353,
        "_internal_originalTime": 1689751266000
    },
    {
        "time": 1689751267000,
        "value": 139.356,
        "_internal_originalTime": 1689751267000
    },
    {
        "time": 1689751268000,
        "value": 139.36,
        "_internal_originalTime": 1689751268000
    },
    {
        "time": 1689751269000,
        "value": 139.361,
        "_internal_originalTime": 1689751269000
    },
    {
        "time": 1689751270000,
        "value": 139.359,
        "_internal_originalTime": 1689751270000
    },
    {
        "time": 1689751271000,
        "value": 139.361,
        "_internal_originalTime": 1689751271000
    },
    {
        "time": 1689751272000,
        "value": 139.362,
        "_internal_originalTime": 1689751272000
    },
    {
        "time": 1689751273000,
        "value": 139.36,
        "_internal_originalTime": 1689751273000
    },
    {
        "time": 1689751275000,
        "value": 139.357,
        "_internal_originalTime": 1689751275000
    },
    {
        "time": 1689751276000,
        "value": 139.358,
        "_internal_originalTime": 1689751276000
    },
    {
        "time": 1689751277000,
        "value": 139.358,
        "_internal_originalTime": 1689751277000
    },
    {
        "time": 1689751278000,
        "value": 139.356,
        "_internal_originalTime": 1689751278000
    },
    {
        "time": 1689751279000,
        "value": 139.36,
        "_internal_originalTime": 1689751279000
    },
    {
        "time": 1689751282000,
        "value": 139.355,
        "_internal_originalTime": 1689751282000
    },
    {
        "time": 1689751283000,
        "value": 139.355,
        "_internal_originalTime": 1689751283000
    },
    {
        "time": 1689751284000,
        "value": 139.355,
        "_internal_originalTime": 1689751284000
    },
    {
        "time": 1689751285000,
        "value": 139.358,
        "_internal_originalTime": 1689751285000
    },
    {
        "time": 1689751286000,
        "value": 139.356,
        "_internal_originalTime": 1689751286000
    },
    {
        "time": 1689751288000,
        "value": 139.358,
        "_internal_originalTime": 1689751288000
    },
    {
        "time": 1689751290000,
        "value": 139.356,
        "_internal_originalTime": 1689751290000
    },
    {
        "time": 1689751292000,
        "value": 139.36,
        "_internal_originalTime": 1689751292000
    },
    {
        "time": 1689751293000,
        "value": 139.361,
        "_internal_originalTime": 1689751293000
    },
    {
        "time": 1689751294000,
        "value": 139.357,
        "_internal_originalTime": 1689751294000
    },
    {
        "time": 1689751295000,
        "value": 139.356,
        "_internal_originalTime": 1689751295000
    },
    {
        "time": 1689751296000,
        "value": 139.36,
        "_internal_originalTime": 1689751296000
    },
    {
        "time": 1689751297000,
        "value": 139.36,
        "_internal_originalTime": 1689751297000
    },
    {
        "time": 1689751298000,
        "value": 139.359,
        "_internal_originalTime": 1689751298000
    },
    {
        "time": 1689751299000,
        "value": 139.358,
        "_internal_originalTime": 1689751299000
    },
    {
        "time": 1689751300000,
        "value": 139.357,
        "_internal_originalTime": 1689751300000
    },
    {
        "time": 1689751301000,
        "value": 139.358,
        "_internal_originalTime": 1689751301000
    },
    {
        "time": 1689751302000,
        "value": 139.36,
        "_internal_originalTime": 1689751302000
    },
    {
        "time": 1689751303000,
        "value": 139.36,
        "_internal_originalTime": 1689751303000
    },
    {
        "time": 1689751304000,
        "value": 139.358,
        "_internal_originalTime": 1689751304000
    },
    {
        "time": 1689751306000,
        "value": 139.358,
        "_internal_originalTime": 1689751306000
    },
    {
        "time": 1689751307000,
        "value": 139.36,
        "_internal_originalTime": 1689751307000
    },
    {
        "time": 1689751308000,
        "value": 139.357,
        "_internal_originalTime": 1689751308000
    },
    {
        "time": 1689751309000,
        "value": 139.356,
        "_internal_originalTime": 1689751309000
    },
    {
        "time": 1689751311000,
        "value": 139.355,
        "_internal_originalTime": 1689751311000
    },
    {
        "time": 1689751313000,
        "value": 139.355,
        "_internal_originalTime": 1689751313000
    },
    {
        "time": 1689751314000,
        "value": 139.355,
        "_internal_originalTime": 1689751314000
    },
    {
        "time": 1689751315000,
        "value": 139.357,
        "_internal_originalTime": 1689751315000
    },
    {
        "time": 1689751316000,
        "value": 139.354,
        "_internal_originalTime": 1689751316000
    },
    {
        "time": 1689751317000,
        "value": 139.358,
        "_internal_originalTime": 1689751317000
    },
    {
        "time": 1689751318000,
        "value": 139.352,
        "_internal_originalTime": 1689751318000
    },
    {
        "time": 1689751320000,
        "value": 139.354,
        "_internal_originalTime": 1689751320000
    },
    {
        "time": 1689751321000,
        "value": 139.351,
        "_internal_originalTime": 1689751321000
    },
    {
        "time": 1689751322000,
        "value": 139.357,
        "_internal_originalTime": 1689751322000
    },
    {
        "time": 1689751323000,
        "value": 139.356,
        "_internal_originalTime": 1689751323000
    },
    {
        "time": 1689751324000,
        "value": 139.357,
        "_internal_originalTime": 1689751324000
    },
    {
        "time": 1689751325000,
        "value": 139.353,
        "_internal_originalTime": 1689751325000
    },
    {
        "time": 1689751326000,
        "value": 139.356,
        "_internal_originalTime": 1689751326000
    },
    {
        "time": 1689751327000,
        "value": 139.354,
        "_internal_originalTime": 1689751327000
    },
    {
        "time": 1689751328000,
        "value": 139.351,
        "_internal_originalTime": 1689751328000
    },
    {
        "time": 1689751329000,
        "value": 139.353,
        "_internal_originalTime": 1689751329000
    },
    {
        "time": 1689751330000,
        "value": 139.351,
        "_internal_originalTime": 1689751330000
    },
    {
        "time": 1689751331000,
        "value": 139.348,
        "_internal_originalTime": 1689751331000
    },
    {
        "time": 1689751332000,
        "value": 139.346,
        "_internal_originalTime": 1689751332000
    },
    {
        "time": 1689751333000,
        "value": 139.347,
        "_internal_originalTime": 1689751333000
    },
    {
        "time": 1689751335000,
        "value": 139.346,
        "_internal_originalTime": 1689751335000
    },
    {
        "time": 1689751337000,
        "value": 139.343,
        "_internal_originalTime": 1689751337000
    },
    {
        "time": 1689751338000,
        "value": 139.343,
        "_internal_originalTime": 1689751338000
    },
    {
        "time": 1689751339000,
        "value": 139.342,
        "_internal_originalTime": 1689751339000
    },
    {
        "time": 1689751340000,
        "value": 139.339,
        "_internal_originalTime": 1689751340000
    },
    {
        "time": 1689751341000,
        "value": 139.335,
        "_internal_originalTime": 1689751341000
    },
    {
        "time": 1689751342000,
        "value": 139.331,
        "_internal_originalTime": 1689751342000
    },
    {
        "time": 1689751344000,
        "value": 139.326,
        "_internal_originalTime": 1689751344000
    },
    {
        "time": 1689751345000,
        "value": 139.325,
        "_internal_originalTime": 1689751345000
    },
    {
        "time": 1689751346000,
        "value": 139.325,
        "_internal_originalTime": 1689751346000
    },
    {
        "time": 1689751347000,
        "value": 139.313,
        "_internal_originalTime": 1689751347000
    },
    {
        "time": 1689751348000,
        "value": 139.316,
        "_internal_originalTime": 1689751348000
    },
    {
        "time": 1689751350000,
        "value": 139.315,
        "_internal_originalTime": 1689751350000
    },
    {
        "time": 1689751351000,
        "value": 139.318,
        "_internal_originalTime": 1689751351000
    },
    {
        "time": 1689751352000,
        "value": 139.323,
        "_internal_originalTime": 1689751352000
    },
    {
        "time": 1689751353000,
        "value": 139.315,
        "_internal_originalTime": 1689751353000
    },
    {
        "time": 1689751354000,
        "value": 139.312,
        "_internal_originalTime": 1689751354000
    },
    {
        "time": 1689751356000,
        "value": 139.314,
        "_internal_originalTime": 1689751356000
    },
    {
        "time": 1689751357000,
        "value": 139.32,
        "_internal_originalTime": 1689751357000
    },
    {
        "time": 1689751358000,
        "value": 139.309,
        "_internal_originalTime": 1689751358000
    },
    {
        "time": 1689751359000,
        "value": 139.315,
        "_internal_originalTime": 1689751359000
    },
    {
        "time": 1689751361000,
        "value": 139.291,
        "_internal_originalTime": 1689751361000
    },
    {
        "time": 1689751362000,
        "value": 139.292,
        "_internal_originalTime": 1689751362000
    },
    {
        "time": 1689751363000,
        "value": 139.281,
        "_internal_originalTime": 1689751363000
    },
    {
        "time": 1689751365000,
        "value": 139.281,
        "_internal_originalTime": 1689751365000
    },
    {
        "time": 1689751366000,
        "value": 139.28,
        "_internal_originalTime": 1689751366000
    },
    {
        "time": 1689751367000,
        "value": 139.286,
        "_internal_originalTime": 1689751367000
    },
    {
        "time": 1689751368000,
        "value": 139.285,
        "_internal_originalTime": 1689751368000
    },
    {
        "time": 1689751370000,
        "value": 139.278,
        "_internal_originalTime": 1689751370000
    },
    {
        "time": 1689751372000,
        "value": 139.284,
        "_internal_originalTime": 1689751372000
    },
    {
        "time": 1689751373000,
        "value": 139.285,
        "_internal_originalTime": 1689751373000
    },
    {
        "time": 1689751374000,
        "value": 139.285,
        "_internal_originalTime": 1689751374000
    },
    {
        "time": 1689751375000,
        "value": 139.274,
        "_internal_originalTime": 1689751375000
    },
    {
        "time": 1689751376000,
        "value": 139.272,
        "_internal_originalTime": 1689751376000
    },
    {
        "time": 1689751378000,
        "value": 139.273,
        "_internal_originalTime": 1689751378000
    },
    {
        "time": 1689751379000,
        "value": 139.261,
        "_internal_originalTime": 1689751379000
    },
    {
        "time": 1689751380000,
        "value": 139.268,
        "_internal_originalTime": 1689751380000
    },
    {
        "time": 1689751381000,
        "value": 139.269,
        "_internal_originalTime": 1689751381000
    },
    {
        "time": 1689751382000,
        "value": 139.274,
        "_internal_originalTime": 1689751382000
    },
    {
        "time": 1689751383000,
        "value": 139.273,
        "_internal_originalTime": 1689751383000
    },
    {
        "time": 1689751385000,
        "value": 139.272,
        "_internal_originalTime": 1689751385000
    },
    {
        "time": 1689751386000,
        "value": 139.272,
        "_internal_originalTime": 1689751386000
    },
    {
        "time": 1689751387000,
        "value": 139.28,
        "_internal_originalTime": 1689751387000
    },
    {
        "time": 1689751388000,
        "value": 139.277,
        "_internal_originalTime": 1689751388000
    },
    {
        "time": 1689751389000,
        "value": 139.278,
        "_internal_originalTime": 1689751389000
    },
    {
        "time": 1689751391000,
        "value": 139.277,
        "_internal_originalTime": 1689751391000
    },
    {
        "time": 1689751392000,
        "value": 139.276,
        "_internal_originalTime": 1689751392000
    },
    {
        "time": 1689751393000,
        "value": 139.272,
        "_internal_originalTime": 1689751393000
    },
    {
        "time": 1689751395000,
        "value": 139.27,
        "_internal_originalTime": 1689751395000
    },
    {
        "time": 1689751396000,
        "value": 139.271,
        "_internal_originalTime": 1689751396000
    },
    {
        "time": 1689751397000,
        "value": 139.267,
        "_internal_originalTime": 1689751397000
    },
    {
        "time": 1689751399000,
        "value": 139.266,
        "_internal_originalTime": 1689751399000
    },
    {
        "time": 1689751401000,
        "value": 139.273,
        "_internal_originalTime": 1689751401000
    },
    {
        "time": 1689751402000,
        "value": 139.27,
        "_internal_originalTime": 1689751402000
    },
    {
        "time": 1689751403000,
        "value": 139.271,
        "_internal_originalTime": 1689751403000
    },
    {
        "time": 1689751404000,
        "value": 139.268,
        "_internal_originalTime": 1689751404000
    },
    {
        "time": 1689751406000,
        "value": 139.273,
        "_internal_originalTime": 1689751406000
    },
    {
        "time": 1689751407000,
        "value": 139.271,
        "_internal_originalTime": 1689751407000
    },
    {
        "time": 1689751408000,
        "value": 139.3,
        "_internal_originalTime": 1689751408000
    },
    {
        "time": 1689751409000,
        "value": 139.275,
        "_internal_originalTime": 1689751409000
    },
    {
        "time": 1689751410000,
        "value": 139.273,
        "_internal_originalTime": 1689751410000
    },
    {
        "time": 1689751411000,
        "value": 139.267,
        "_internal_originalTime": 1689751411000
    },
    {
        "time": 1689751413000,
        "value": 139.26,
        "_internal_originalTime": 1689751413000
    },
    {
        "time": 1689751414000,
        "value": 139.262,
        "_internal_originalTime": 1689751414000
    },
    {
        "time": 1689751415000,
        "value": 139.257,
        "_internal_originalTime": 1689751415000
    },
    {
        "time": 1689751416000,
        "value": 139.254,
        "_internal_originalTime": 1689751416000
    },
    {
        "time": 1689751417000,
        "value": 139.25,
        "_internal_originalTime": 1689751417000
    },
    {
        "time": 1689751418000,
        "value": 139.254,
        "_internal_originalTime": 1689751418000
    },
    {
        "time": 1689751419000,
        "value": 139.254,
        "_internal_originalTime": 1689751419000
    },
    {
        "time": 1689751420000,
        "value": 139.252,
        "_internal_originalTime": 1689751420000
    },
    {
        "time": 1689751421000,
        "value": 139.257,
        "_internal_originalTime": 1689751421000
    },
    {
        "time": 1689751422000,
        "value": 139.253,
        "_internal_originalTime": 1689751422000
    },
    {
        "time": 1689751423000,
        "value": 139.254,
        "_internal_originalTime": 1689751423000
    },
    {
        "time": 1689751424000,
        "value": 139.255,
        "_internal_originalTime": 1689751424000
    },
    {
        "time": 1689751425000,
        "value": 139.258,
        "_internal_originalTime": 1689751425000
    },
    {
        "time": 1689751426000,
        "value": 139.253,
        "_internal_originalTime": 1689751426000
    },
    {
        "time": 1689751427000,
        "value": 139.25,
        "_internal_originalTime": 1689751427000
    },
    {
        "time": 1689751428000,
        "value": 139.261,
        "_internal_originalTime": 1689751428000
    },
    {
        "time": 1689751429000,
        "value": 139.255,
        "_internal_originalTime": 1689751429000
    },
    {
        "time": 1689751430000,
        "value": 139.253,
        "_internal_originalTime": 1689751430000
    },
    {
        "time": 1689751432000,
        "value": 139.25,
        "_internal_originalTime": 1689751432000
    },
    {
        "time": 1689751433000,
        "value": 139.255,
        "_internal_originalTime": 1689751433000
    },
    {
        "time": 1689751434000,
        "value": 139.253,
        "_internal_originalTime": 1689751434000
    },
    {
        "time": 1689751435000,
        "value": 139.251,
        "_internal_originalTime": 1689751435000
    },
    {
        "time": 1689751436000,
        "value": 139.255,
        "_internal_originalTime": 1689751436000
    },
    {
        "time": 1689751438000,
        "value": 139.256,
        "_internal_originalTime": 1689751438000
    },
    {
        "time": 1689751440000,
        "value": 139.254,
        "_internal_originalTime": 1689751440000
    },
    {
        "time": 1689751442000,
        "value": 139.259,
        "_internal_originalTime": 1689751442000
    },
    {
        "time": 1689751443000,
        "value": 139.261,
        "_internal_originalTime": 1689751443000
    },
    {
        "time": 1689751444000,
        "value": 139.258,
        "_internal_originalTime": 1689751444000
    },
    {
        "time": 1689751445000,
        "value": 139.259,
        "_internal_originalTime": 1689751445000
    },
    {
        "time": 1689751448000,
        "value": 139.256,
        "_internal_originalTime": 1689751448000
    },
    {
        "time": 1689751449000,
        "value": 139.251,
        "_internal_originalTime": 1689751449000
    },
    {
        "time": 1689751450000,
        "value": 139.253,
        "_internal_originalTime": 1689751450000
    },
    {
        "time": 1689751451000,
        "value": 139.251,
        "_internal_originalTime": 1689751451000
    },
    {
        "time": 1689751452000,
        "value": 139.251,
        "_internal_originalTime": 1689751452000
    },
    {
        "time": 1689751454000,
        "value": 139.252,
        "_internal_originalTime": 1689751454000
    },
    {
        "time": 1689751455000,
        "value": 139.249,
        "_internal_originalTime": 1689751455000
    },
    {
        "time": 1689751456000,
        "value": 139.25,
        "_internal_originalTime": 1689751456000
    },
    {
        "time": 1689751457000,
        "value": 139.25,
        "_internal_originalTime": 1689751457000
    },
    {
        "time": 1689751458000,
        "value": 139.262,
        "_internal_originalTime": 1689751458000
    },
    {
        "time": 1689751459000,
        "value": 139.263,
        "_internal_originalTime": 1689751459000
    },
    {
        "time": 1689751460000,
        "value": 139.262,
        "_internal_originalTime": 1689751460000
    },
    {
        "time": 1689751462000,
        "value": 139.265,
        "_internal_originalTime": 1689751462000
    },
    {
        "time": 1689751463000,
        "value": 139.264,
        "_internal_originalTime": 1689751463000
    },
    {
        "time": 1689751464000,
        "value": 139.265,
        "_internal_originalTime": 1689751464000
    },
    {
        "time": 1689751465000,
        "value": 139.269,
        "_internal_originalTime": 1689751465000
    },
    {
        "time": 1689751466000,
        "value": 139.262,
        "_internal_originalTime": 1689751466000
    },
    {
        "time": 1689751468000,
        "value": 139.258,
        "_internal_originalTime": 1689751468000
    },
    {
        "time": 1689751469000,
        "value": 139.265,
        "_internal_originalTime": 1689751469000
    },
    {
        "time": 1689751470000,
        "value": 139.261,
        "_internal_originalTime": 1689751470000
    },
    {
        "time": 1689751471000,
        "value": 139.26,
        "_internal_originalTime": 1689751471000
    },
    {
        "time": 1689751474000,
        "value": 139.265,
        "_internal_originalTime": 1689751474000
    },
    {
        "time": 1689751475000,
        "value": 139.262,
        "_internal_originalTime": 1689751475000
    },
    {
        "time": 1689751477000,
        "value": 139.273,
        "_internal_originalTime": 1689751477000
    },
    {
        "time": 1689751478000,
        "value": 139.272,
        "_internal_originalTime": 1689751478000
    },
    {
        "time": 1689751479000,
        "value": 139.273,
        "_internal_originalTime": 1689751479000
    },
    {
        "time": 1689751481000,
        "value": 139.273,
        "_internal_originalTime": 1689751481000
    },
    {
        "time": 1689751482000,
        "value": 139.271,
        "_internal_originalTime": 1689751482000
    },
    {
        "time": 1689751485000,
        "value": 139.27,
        "_internal_originalTime": 1689751485000
    },
    {
        "time": 1689751486000,
        "value": 139.264,
        "_internal_originalTime": 1689751486000
    },
    {
        "time": 1689751487000,
        "value": 139.262,
        "_internal_originalTime": 1689751487000
    },
    {
        "time": 1689751488000,
        "value": 139.264,
        "_internal_originalTime": 1689751488000
    },
    {
        "time": 1689751489000,
        "value": 139.265,
        "_internal_originalTime": 1689751489000
    },
    {
        "time": 1689751490000,
        "value": 139.267,
        "_internal_originalTime": 1689751490000
    },
    {
        "time": 1689751492000,
        "value": 139.273,
        "_internal_originalTime": 1689751492000
    },
    {
        "time": 1689751494000,
        "value": 139.273,
        "_internal_originalTime": 1689751494000
    },
    {
        "time": 1689751495000,
        "value": 139.27,
        "_internal_originalTime": 1689751495000
    },
    {
        "time": 1689751496000,
        "value": 139.275,
        "_internal_originalTime": 1689751496000
    },
    {
        "time": 1689751497000,
        "value": 139.274,
        "_internal_originalTime": 1689751497000
    },
    {
        "time": 1689751498000,
        "value": 139.27,
        "_internal_originalTime": 1689751498000
    },
    {
        "time": 1689751500000,
        "value": 139.272,
        "_internal_originalTime": 1689751500000
    },
    {
        "time": 1689751501000,
        "value": 139.273,
        "_internal_originalTime": 1689751501000
    },
    {
        "time": 1689751502000,
        "value": 139.272,
        "_internal_originalTime": 1689751502000
    },
    {
        "time": 1689751503000,
        "value": 139.271,
        "_internal_originalTime": 1689751503000
    },
    {
        "time": 1689751505000,
        "value": 139.268,
        "_internal_originalTime": 1689751505000
    },
    {
        "time": 1689751506000,
        "value": 139.271,
        "_internal_originalTime": 1689751506000
    },
    {
        "time": 1689751507000,
        "value": 139.272,
        "_internal_originalTime": 1689751507000
    },
    {
        "time": 1689751509000,
        "value": 139.27,
        "_internal_originalTime": 1689751509000
    },
    {
        "time": 1689751510000,
        "value": 139.268,
        "_internal_originalTime": 1689751510000
    },
    {
        "time": 1689751511000,
        "value": 139.27,
        "_internal_originalTime": 1689751511000
    },
    {
        "time": 1689751512000,
        "value": 139.27,
        "_internal_originalTime": 1689751512000
    },
    {
        "time": 1689751513000,
        "value": 139.276,
        "_internal_originalTime": 1689751513000
    },
    {
        "time": 1689751514000,
        "value": 139.274,
        "_internal_originalTime": 1689751514000
    },
    {
        "time": 1689751515000,
        "value": 139.273,
        "_internal_originalTime": 1689751515000
    },
    {
        "time": 1689751516000,
        "value": 139.276,
        "_internal_originalTime": 1689751516000
    },
    {
        "time": 1689751517000,
        "value": 139.273,
        "_internal_originalTime": 1689751517000
    },
    {
        "time": 1689751518000,
        "value": 139.273,
        "_internal_originalTime": 1689751518000
    },
    {
        "time": 1689751519000,
        "value": 139.27,
        "_internal_originalTime": 1689751519000
    },
    {
        "time": 1689751520000,
        "value": 139.273,
        "_internal_originalTime": 1689751520000
    },
    {
        "time": 1689751521000,
        "value": 139.273,
        "_internal_originalTime": 1689751521000
    },
    {
        "time": 1689751522000,
        "value": 139.272,
        "_internal_originalTime": 1689751522000
    },
    {
        "time": 1689751523000,
        "value": 139.272,
        "_internal_originalTime": 1689751523000
    },
    {
        "time": 1689751524000,
        "value": 139.282,
        "_internal_originalTime": 1689751524000
    },
    {
        "time": 1689751525000,
        "value": 139.287,
        "_internal_originalTime": 1689751525000
    },
    {
        "time": 1689751526000,
        "value": 139.286,
        "_internal_originalTime": 1689751526000
    },
    {
        "time": 1689751527000,
        "value": 139.284,
        "_internal_originalTime": 1689751527000
    },
    {
        "time": 1689751528000,
        "value": 139.283,
        "_internal_originalTime": 1689751528000
    },
    {
        "time": 1689751530000,
        "value": 139.281,
        "_internal_originalTime": 1689751530000
    },
    {
        "time": 1689751531000,
        "value": 139.281,
        "_internal_originalTime": 1689751531000
    },
    {
        "time": 1689751532000,
        "value": 139.284,
        "_internal_originalTime": 1689751532000
    },
    {
        "time": 1689751534000,
        "value": 139.286,
        "_internal_originalTime": 1689751534000
    },
    {
        "time": 1689751535000,
        "value": 139.301,
        "_internal_originalTime": 1689751535000
    },
    {
        "time": 1689751536000,
        "value": 139.294,
        "_internal_originalTime": 1689751536000
    },
    {
        "time": 1689751537000,
        "value": 139.294,
        "_internal_originalTime": 1689751537000
    },
    {
        "time": 1689751539000,
        "value": 139.3,
        "_internal_originalTime": 1689751539000
    },
    {
        "time": 1689751540000,
        "value": 139.301,
        "_internal_originalTime": 1689751540000
    },
    {
        "time": 1689751541000,
        "value": 139.299,
        "_internal_originalTime": 1689751541000
    },
    {
        "time": 1689751542000,
        "value": 139.296,
        "_internal_originalTime": 1689751542000
    },
    {
        "time": 1689751543000,
        "value": 139.297,
        "_internal_originalTime": 1689751543000
    },
    {
        "time": 1689751544000,
        "value": 139.297,
        "_internal_originalTime": 1689751544000
    },
    {
        "time": 1689751546000,
        "value": 139.287,
        "_internal_originalTime": 1689751546000
    },
    {
        "time": 1689751547000,
        "value": 139.291,
        "_internal_originalTime": 1689751547000
    },
    {
        "time": 1689751548000,
        "value": 139.289,
        "_internal_originalTime": 1689751548000
    },
    {
        "time": 1689751550000,
        "value": 139.288,
        "_internal_originalTime": 1689751550000
    },
    {
        "time": 1689751551000,
        "value": 139.292,
        "_internal_originalTime": 1689751551000
    },
    {
        "time": 1689751553000,
        "value": 139.291,
        "_internal_originalTime": 1689751553000
    },
    {
        "time": 1689751554000,
        "value": 139.289,
        "_internal_originalTime": 1689751554000
    },
    {
        "time": 1689751556000,
        "value": 139.291,
        "_internal_originalTime": 1689751556000
    },
    {
        "time": 1689751560000,
        "value": 139.291,
        "_internal_originalTime": 1689751560000
    },
    {
        "time": 1689751562000,
        "value": 139.288,
        "_internal_originalTime": 1689751562000
    },
    {
        "time": 1689751563000,
        "value": 139.283,
        "_internal_originalTime": 1689751563000
    },
    {
        "time": 1689751564000,
        "value": 139.289,
        "_internal_originalTime": 1689751564000
    },
    {
        "time": 1689751565000,
        "value": 139.288,
        "_internal_originalTime": 1689751565000
    },
    {
        "time": 1689751566000,
        "value": 139.291,
        "_internal_originalTime": 1689751566000
    },
    {
        "time": 1689751567000,
        "value": 139.293,
        "_internal_originalTime": 1689751567000
    },
    {
        "time": 1689751568000,
        "value": 139.292,
        "_internal_originalTime": 1689751568000
    },
    {
        "time": 1689751570000,
        "value": 139.293,
        "_internal_originalTime": 1689751570000
    },
    {
        "time": 1689751572000,
        "value": 139.292,
        "_internal_originalTime": 1689751572000
    },
    {
        "time": 1689751573000,
        "value": 139.291,
        "_internal_originalTime": 1689751573000
    },
    {
        "time": 1689751574000,
        "value": 139.292,
        "_internal_originalTime": 1689751574000
    },
    {
        "time": 1689751575000,
        "value": 139.301,
        "_internal_originalTime": 1689751575000
    },
    {
        "time": 1689751576000,
        "value": 139.302,
        "_internal_originalTime": 1689751576000
    },
    {
        "time": 1689751577000,
        "value": 139.307,
        "_internal_originalTime": 1689751577000
    },
    {
        "time": 1689751578000,
        "value": 139.31,
        "_internal_originalTime": 1689751578000
    },
    {
        "time": 1689751579000,
        "value": 139.304,
        "_internal_originalTime": 1689751579000
    },
    {
        "time": 1689751580000,
        "value": 139.304,
        "_internal_originalTime": 1689751580000
    },
    {
        "time": 1689751581000,
        "value": 139.303,
        "_internal_originalTime": 1689751581000
    },
    {
        "time": 1689751582000,
        "value": 139.303,
        "_internal_originalTime": 1689751582000
    },
    {
        "time": 1689751583000,
        "value": 139.301,
        "_internal_originalTime": 1689751583000
    },
    {
        "time": 1689751584000,
        "value": 139.299,
        "_internal_originalTime": 1689751584000
    },
    {
        "time": 1689751585000,
        "value": 139.305,
        "_internal_originalTime": 1689751585000
    },
    {
        "time": 1689751586000,
        "value": 139.306,
        "_internal_originalTime": 1689751586000
    },
    {
        "time": 1689751588000,
        "value": 139.305,
        "_internal_originalTime": 1689751588000
    },
    {
        "time": 1689751590000,
        "value": 139.302,
        "_internal_originalTime": 1689751590000
    },
    {
        "time": 1689751591000,
        "value": 139.298,
        "_internal_originalTime": 1689751591000
    },
    {
        "time": 1689751593000,
        "value": 139.298,
        "_internal_originalTime": 1689751593000
    },
    {
        "time": 1689751594000,
        "value": 139.301,
        "_internal_originalTime": 1689751594000
    },
    {
        "time": 1689751595000,
        "value": 139.303,
        "_internal_originalTime": 1689751595000
    },
    {
        "time": 1689751596000,
        "value": 139.302,
        "_internal_originalTime": 1689751596000
    },
    {
        "time": 1689751597000,
        "value": 139.3,
        "_internal_originalTime": 1689751597000
    },
    {
        "time": 1689751599000,
        "value": 139.312,
        "_internal_originalTime": 1689751599000
    },
    {
        "time": 1689751600000,
        "value": 139.312,
        "_internal_originalTime": 1689751600000
    },
    {
        "time": 1689751601000,
        "value": 139.31,
        "_internal_originalTime": 1689751601000
    },
    {
        "time": 1689751602000,
        "value": 139.311,
        "_internal_originalTime": 1689751602000
    },
    {
        "time": 1689751603000,
        "value": 139.312,
        "_internal_originalTime": 1689751603000
    },
    {
        "time": 1689751604000,
        "value": 139.311,
        "_internal_originalTime": 1689751604000
    },
    {
        "time": 1689751605000,
        "value": 139.314,
        "_internal_originalTime": 1689751605000
    },
    {
        "time": 1689751606000,
        "value": 139.314,
        "_internal_originalTime": 1689751606000
    },
    {
        "time": 1689751608000,
        "value": 139.326,
        "_internal_originalTime": 1689751608000
    },
    {
        "time": 1689751609000,
        "value": 139.327,
        "_internal_originalTime": 1689751609000
    },
    {
        "time": 1689751610000,
        "value": 139.331,
        "_internal_originalTime": 1689751610000
    },
    {
        "time": 1689751611000,
        "value": 139.338,
        "_internal_originalTime": 1689751611000
    },
    {
        "time": 1689751612000,
        "value": 139.335,
        "_internal_originalTime": 1689751612000
    },
    {
        "time": 1689751614000,
        "value": 139.333,
        "_internal_originalTime": 1689751614000
    },
    {
        "time": 1689751616000,
        "value": 139.328,
        "_internal_originalTime": 1689751616000
    },
    {
        "time": 1689751618000,
        "value": 139.332,
        "_internal_originalTime": 1689751618000
    },
    {
        "time": 1689751619000,
        "value": 139.328,
        "_internal_originalTime": 1689751619000
    },
    {
        "time": 1689751620000,
        "value": 139.329,
        "_internal_originalTime": 1689751620000
    },
    {
        "time": 1689751621000,
        "value": 139.321,
        "_internal_originalTime": 1689751621000
    },
    {
        "time": 1689751622000,
        "value": 139.321,
        "_internal_originalTime": 1689751622000
    },
    {
        "time": 1689751623000,
        "value": 139.321,
        "_internal_originalTime": 1689751623000
    },
    {
        "time": 1689751624000,
        "value": 139.32,
        "_internal_originalTime": 1689751624000
    },
    {
        "time": 1689751625000,
        "value": 139.32,
        "_internal_originalTime": 1689751625000
    },
    {
        "time": 1689751626000,
        "value": 139.319,
        "_internal_originalTime": 1689751626000
    },
    {
        "time": 1689751628000,
        "value": 139.311,
        "_internal_originalTime": 1689751628000
    },
    {
        "time": 1689751629000,
        "value": 139.312,
        "_internal_originalTime": 1689751629000
    },
    {
        "time": 1689751631000,
        "value": 139.317,
        "_internal_originalTime": 1689751631000
    },
    {
        "time": 1689751632000,
        "value": 139.316,
        "_internal_originalTime": 1689751632000
    },
    {
        "time": 1689751633000,
        "value": 139.314,
        "_internal_originalTime": 1689751633000
    },
    {
        "time": 1689751634000,
        "value": 139.316,
        "_internal_originalTime": 1689751634000
    },
    {
        "time": 1689751635000,
        "value": 139.316,
        "_internal_originalTime": 1689751635000
    },
    {
        "time": 1689751636000,
        "value": 139.309,
        "_internal_originalTime": 1689751636000
    },
    {
        "time": 1689751637000,
        "value": 139.316,
        "_internal_originalTime": 1689751637000
    },
    {
        "time": 1689751638000,
        "value": 139.315,
        "_internal_originalTime": 1689751638000
    },
    {
        "time": 1689751639000,
        "value": 139.314,
        "_internal_originalTime": 1689751639000
    },
    {
        "time": 1689751640000,
        "value": 139.308,
        "_internal_originalTime": 1689751640000
    },
    {
        "time": 1689751641000,
        "value": 139.313,
        "_internal_originalTime": 1689751641000
    },
    {
        "time": 1689751642000,
        "value": 139.313,
        "_internal_originalTime": 1689751642000
    },
    {
        "time": 1689751644000,
        "value": 139.307,
        "_internal_originalTime": 1689751644000
    },
    {
        "time": 1689751645000,
        "value": 139.309,
        "_internal_originalTime": 1689751645000
    },
    {
        "time": 1689751646000,
        "value": 139.309,
        "_internal_originalTime": 1689751646000
    },
    {
        "time": 1689751648000,
        "value": 139.308,
        "_internal_originalTime": 1689751648000
    },
    {
        "time": 1689751650000,
        "value": 139.314,
        "_internal_originalTime": 1689751650000
    },
    {
        "time": 1689751652000,
        "value": 139.316,
        "_internal_originalTime": 1689751652000
    },
    {
        "time": 1689751653000,
        "value": 139.316,
        "_internal_originalTime": 1689751653000
    },
    {
        "time": 1689751654000,
        "value": 139.319,
        "_internal_originalTime": 1689751654000
    },
    {
        "time": 1689751657000,
        "value": 139.316,
        "_internal_originalTime": 1689751657000
    },
    {
        "time": 1689751658000,
        "value": 139.326,
        "_internal_originalTime": 1689751658000
    },
    {
        "time": 1689751659000,
        "value": 139.326,
        "_internal_originalTime": 1689751659000
    },
    {
        "time": 1689751660000,
        "value": 139.327,
        "_internal_originalTime": 1689751660000
    },
    {
        "time": 1689751661000,
        "value": 139.331,
        "_internal_originalTime": 1689751661000
    },
    {
        "time": 1689751662000,
        "value": 139.329,
        "_internal_originalTime": 1689751662000
    },
    {
        "time": 1689751663000,
        "value": 139.33,
        "_internal_originalTime": 1689751663000
    },
    {
        "time": 1689751665000,
        "value": 139.333,
        "_internal_originalTime": 1689751665000
    },
    {
        "time": 1689751667000,
        "value": 139.33,
        "_internal_originalTime": 1689751667000
    },
    {
        "time": 1689751668000,
        "value": 139.327,
        "_internal_originalTime": 1689751668000
    },
    {
        "time": 1689751669000,
        "value": 139.329,
        "_internal_originalTime": 1689751669000
    },
    {
        "time": 1689751670000,
        "value": 139.329,
        "_internal_originalTime": 1689751670000
    },
    {
        "time": 1689751671000,
        "value": 139.328,
        "_internal_originalTime": 1689751671000
    },
    {
        "time": 1689751672000,
        "value": 139.332,
        "_internal_originalTime": 1689751672000
    },
    {
        "time": 1689751673000,
        "value": 139.33,
        "_internal_originalTime": 1689751673000
    },
    {
        "time": 1689751674000,
        "value": 139.326,
        "_internal_originalTime": 1689751674000
    },
    {
        "time": 1689751675000,
        "value": 139.329,
        "_internal_originalTime": 1689751675000
    },
    {
        "time": 1689751676000,
        "value": 139.331,
        "_internal_originalTime": 1689751676000
    },
    {
        "time": 1689751677000,
        "value": 139.324,
        "_internal_originalTime": 1689751677000
    },
    {
        "time": 1689751681000,
        "value": 139.324,
        "_internal_originalTime": 1689751681000
    },
    {
        "time": 1689751682000,
        "value": 139.331,
        "_internal_originalTime": 1689751682000
    },
    {
        "time": 1689751683000,
        "value": 139.335,
        "_internal_originalTime": 1689751683000
    },
    {
        "time": 1689751684000,
        "value": 139.338,
        "_internal_originalTime": 1689751684000
    },
    {
        "time": 1689751685000,
        "value": 139.333,
        "_internal_originalTime": 1689751685000
    },
    {
        "time": 1689751686000,
        "value": 139.334,
        "_internal_originalTime": 1689751686000
    },
    {
        "time": 1689751687000,
        "value": 139.333,
        "_internal_originalTime": 1689751687000
    },
    {
        "time": 1689751688000,
        "value": 139.34,
        "_internal_originalTime": 1689751688000
    },
    {
        "time": 1689751689000,
        "value": 139.34,
        "_internal_originalTime": 1689751689000
    },
    {
        "time": 1689751690000,
        "value": 139.343,
        "_internal_originalTime": 1689751690000
    },
    {
        "time": 1689751691000,
        "value": 139.339,
        "_internal_originalTime": 1689751691000
    },
    {
        "time": 1689751692000,
        "value": 139.342,
        "_internal_originalTime": 1689751692000
    },
    {
        "time": 1689751693000,
        "value": 139.339,
        "_internal_originalTime": 1689751693000
    },
    {
        "time": 1689751694000,
        "value": 139.342,
        "_internal_originalTime": 1689751694000
    },
    {
        "time": 1689751695000,
        "value": 139.345,
        "_internal_originalTime": 1689751695000
    },
    {
        "time": 1689751696000,
        "value": 139.34,
        "_internal_originalTime": 1689751696000
    },
    {
        "time": 1689751697000,
        "value": 139.346,
        "_internal_originalTime": 1689751697000
    },
    {
        "time": 1689751699000,
        "value": 139.341,
        "_internal_originalTime": 1689751699000
    },
    {
        "time": 1689751701000,
        "value": 139.344,
        "_internal_originalTime": 1689751701000
    },
    {
        "time": 1689751704000,
        "value": 139.35,
        "_internal_originalTime": 1689751704000
    },
    {
        "time": 1689751705000,
        "value": 139.342,
        "_internal_originalTime": 1689751705000
    },
    {
        "time": 1689751706000,
        "value": 139.34,
        "_internal_originalTime": 1689751706000
    },
    {
        "time": 1689751707000,
        "value": 139.346,
        "_internal_originalTime": 1689751707000
    },
    {
        "time": 1689751710000,
        "value": 139.344,
        "_internal_originalTime": 1689751710000
    },
    {
        "time": 1689751711000,
        "value": 139.346,
        "_internal_originalTime": 1689751711000
    },
    {
        "time": 1689751712000,
        "value": 139.343,
        "_internal_originalTime": 1689751712000
    },
    {
        "time": 1689751713000,
        "value": 139.342,
        "_internal_originalTime": 1689751713000
    },
    {
        "time": 1689751714000,
        "value": 139.34,
        "_internal_originalTime": 1689751714000
    },
    {
        "time": 1689751715000,
        "value": 139.343,
        "_internal_originalTime": 1689751715000
    },
    {
        "time": 1689751717000,
        "value": 139.336,
        "_internal_originalTime": 1689751717000
    },
    {
        "time": 1689751718000,
        "value": 139.338,
        "_internal_originalTime": 1689751718000
    },
    {
        "time": 1689751720000,
        "value": 139.335,
        "_internal_originalTime": 1689751720000
    },
    {
        "time": 1689751721000,
        "value": 139.33,
        "_internal_originalTime": 1689751721000
    },
    {
        "time": 1689751722000,
        "value": 139.332,
        "_internal_originalTime": 1689751722000
    },
    {
        "time": 1689751723000,
        "value": 139.33,
        "_internal_originalTime": 1689751723000
    },
    {
        "time": 1689751725000,
        "value": 139.333,
        "_internal_originalTime": 1689751725000
    },
    {
        "time": 1689751726000,
        "value": 139.33,
        "_internal_originalTime": 1689751726000
    },
    {
        "time": 1689751727000,
        "value": 139.333,
        "_internal_originalTime": 1689751727000
    },
    {
        "time": 1689751728000,
        "value": 139.336,
        "_internal_originalTime": 1689751728000
    },
    {
        "time": 1689751730000,
        "value": 139.333,
        "_internal_originalTime": 1689751730000
    },
    {
        "time": 1689751731000,
        "value": 139.332,
        "_internal_originalTime": 1689751731000
    },
    {
        "time": 1689751732000,
        "value": 139.333,
        "_internal_originalTime": 1689751732000
    },
    {
        "time": 1689751734000,
        "value": 139.333,
        "_internal_originalTime": 1689751734000
    },
    {
        "time": 1689751735000,
        "value": 139.336,
        "_internal_originalTime": 1689751735000
    },
    {
        "time": 1689751737000,
        "value": 139.331,
        "_internal_originalTime": 1689751737000
    },
    {
        "time": 1689751738000,
        "value": 139.327,
        "_internal_originalTime": 1689751738000
    },
    {
        "time": 1689751739000,
        "value": 139.333,
        "_internal_originalTime": 1689751739000
    },
    {
        "time": 1689751741000,
        "value": 139.327,
        "_internal_originalTime": 1689751741000
    },
    {
        "time": 1689751742000,
        "value": 139.327,
        "_internal_originalTime": 1689751742000
    },
    {
        "time": 1689751743000,
        "value": 139.332,
        "_internal_originalTime": 1689751743000
    },
    {
        "time": 1689751744000,
        "value": 139.33,
        "_internal_originalTime": 1689751744000
    },
    {
        "time": 1689751745000,
        "value": 139.333,
        "_internal_originalTime": 1689751745000
    },
    {
        "time": 1689751746000,
        "value": 139.334,
        "_internal_originalTime": 1689751746000
    },
    {
        "time": 1689751748000,
        "value": 139.333,
        "_internal_originalTime": 1689751748000
    },
    {
        "time": 1689751749000,
        "value": 139.329,
        "_internal_originalTime": 1689751749000
    },
    {
        "time": 1689751750000,
        "value": 139.329,
        "_internal_originalTime": 1689751750000
    },
    {
        "time": 1689751752000,
        "value": 139.332,
        "_internal_originalTime": 1689751752000
    },
    {
        "time": 1689751753000,
        "value": 139.328,
        "_internal_originalTime": 1689751753000
    },
    {
        "time": 1689751754000,
        "value": 139.33,
        "_internal_originalTime": 1689751754000
    },
    {
        "time": 1689751755000,
        "value": 139.33,
        "_internal_originalTime": 1689751755000
    },
    {
        "time": 1689751756000,
        "value": 139.331,
        "_internal_originalTime": 1689751756000
    },
    {
        "time": 1689751757000,
        "value": 139.332,
        "_internal_originalTime": 1689751757000
    },
    {
        "time": 1689751758000,
        "value": 139.329,
        "_internal_originalTime": 1689751758000
    },
    {
        "time": 1689751759000,
        "value": 139.333,
        "_internal_originalTime": 1689751759000
    },
    {
        "time": 1689751760000,
        "value": 139.338,
        "_internal_originalTime": 1689751760000
    },
    {
        "time": 1689751761000,
        "value": 139.333,
        "_internal_originalTime": 1689751761000
    },
    {
        "time": 1689751762000,
        "value": 139.337,
        "_internal_originalTime": 1689751762000
    },
    {
        "time": 1689751763000,
        "value": 139.333,
        "_internal_originalTime": 1689751763000
    },
    {
        "time": 1689751764000,
        "value": 139.332,
        "_internal_originalTime": 1689751764000
    },
    {
        "time": 1689751767000,
        "value": 139.339,
        "_internal_originalTime": 1689751767000
    },
    {
        "time": 1689751769000,
        "value": 139.335,
        "_internal_originalTime": 1689751769000
    },
    {
        "time": 1689751770000,
        "value": 139.338,
        "_internal_originalTime": 1689751770000
    },
    {
        "time": 1689751771000,
        "value": 139.336,
        "_internal_originalTime": 1689751771000
    },
    {
        "time": 1689751772000,
        "value": 139.337,
        "_internal_originalTime": 1689751772000
    },
    {
        "time": 1689751773000,
        "value": 139.334,
        "_internal_originalTime": 1689751773000
    },
    {
        "time": 1689751774000,
        "value": 139.335,
        "_internal_originalTime": 1689751774000
    },
    {
        "time": 1689751775000,
        "value": 139.34,
        "_internal_originalTime": 1689751775000
    },
    {
        "time": 1689751776000,
        "value": 139.338,
        "_internal_originalTime": 1689751776000
    },
    {
        "time": 1689751777000,
        "value": 139.349,
        "_internal_originalTime": 1689751777000
    },
    {
        "time": 1689751778000,
        "value": 139.345,
        "_internal_originalTime": 1689751778000
    },
    {
        "time": 1689751779000,
        "value": 139.348,
        "_internal_originalTime": 1689751779000
    },
    {
        "time": 1689751780000,
        "value": 139.349,
        "_internal_originalTime": 1689751780000
    },
    {
        "time": 1689751782000,
        "value": 139.351,
        "_internal_originalTime": 1689751782000
    },
    {
        "time": 1689751783000,
        "value": 139.346,
        "_internal_originalTime": 1689751783000
    },
    {
        "time": 1689751785000,
        "value": 139.348,
        "_internal_originalTime": 1689751785000
    },
    {
        "time": 1689751786000,
        "value": 139.348,
        "_internal_originalTime": 1689751786000
    },
    {
        "time": 1689751787000,
        "value": 139.351,
        "_internal_originalTime": 1689751787000
    },
    {
        "time": 1689751788000,
        "value": 139.347,
        "_internal_originalTime": 1689751788000
    },
    {
        "time": 1689751790000,
        "value": 139.338,
        "_internal_originalTime": 1689751790000
    },
    {
        "time": 1689751792000,
        "value": 139.34,
        "_internal_originalTime": 1689751792000
    },
    {
        "time": 1689751793000,
        "value": 139.34,
        "_internal_originalTime": 1689751793000
    },
    {
        "time": 1689751798000,
        "value": 139.359,
        "_internal_originalTime": 1689751798000
    },
    {
        "time": 1689751800000,
        "value": 139.36,
        "_internal_originalTime": 1689751800000
    },
    {
        "time": 1689751802000,
        "value": 139.362,
        "_internal_originalTime": 1689751802000
    },
    {
        "time": 1689751803000,
        "value": 139.36,
        "_internal_originalTime": 1689751803000
    },
    {
        "time": 1689751805000,
        "value": 139.348,
        "_internal_originalTime": 1689751805000
    },
    {
        "time": 1689751806000,
        "value": 139.35,
        "_internal_originalTime": 1689751806000
    },
    {
        "time": 1689751807000,
        "value": 139.348,
        "_internal_originalTime": 1689751807000
    },
    {
        "time": 1689751809000,
        "value": 139.35,
        "_internal_originalTime": 1689751809000
    },
    {
        "time": 1689751810000,
        "value": 139.35,
        "_internal_originalTime": 1689751810000
    },
    {
        "time": 1689751811000,
        "value": 139.35,
        "_internal_originalTime": 1689751811000
    },
    {
        "time": 1689751813000,
        "value": 139.347,
        "_internal_originalTime": 1689751813000
    },
    {
        "time": 1689751815000,
        "value": 139.346,
        "_internal_originalTime": 1689751815000
    },
    {
        "time": 1689751817000,
        "value": 139.347,
        "_internal_originalTime": 1689751817000
    },
    {
        "time": 1689751818000,
        "value": 139.347,
        "_internal_originalTime": 1689751818000
    },
    {
        "time": 1689751819000,
        "value": 139.338,
        "_internal_originalTime": 1689751819000
    },
    {
        "time": 1689751820000,
        "value": 139.34,
        "_internal_originalTime": 1689751820000
    },
    {
        "time": 1689751821000,
        "value": 139.335,
        "_internal_originalTime": 1689751821000
    },
    {
        "time": 1689751823000,
        "value": 139.34,
        "_internal_originalTime": 1689751823000
    },
    {
        "time": 1689751825000,
        "value": 139.334,
        "_internal_originalTime": 1689751825000
    },
    {
        "time": 1689751827000,
        "value": 139.347,
        "_internal_originalTime": 1689751827000
    },
    {
        "time": 1689751828000,
        "value": 139.343,
        "_internal_originalTime": 1689751828000
    },
    {
        "time": 1689751829000,
        "value": 139.353,
        "_internal_originalTime": 1689751829000
    },
    {
        "time": 1689751830000,
        "value": 139.346,
        "_internal_originalTime": 1689751830000
    },
    {
        "time": 1689751832000,
        "value": 139.345,
        "_internal_originalTime": 1689751832000
    },
    {
        "time": 1689751833000,
        "value": 139.341,
        "_internal_originalTime": 1689751833000
    },
    {
        "time": 1689751834000,
        "value": 139.338,
        "_internal_originalTime": 1689751834000
    },
    {
        "time": 1689751835000,
        "value": 139.34,
        "_internal_originalTime": 1689751835000
    },
    {
        "time": 1689751836000,
        "value": 139.336,
        "_internal_originalTime": 1689751836000
    },
    {
        "time": 1689751837000,
        "value": 139.34,
        "_internal_originalTime": 1689751837000
    },
    {
        "time": 1689751838000,
        "value": 139.345,
        "_internal_originalTime": 1689751838000
    },
    {
        "time": 1689751839000,
        "value": 139.35,
        "_internal_originalTime": 1689751839000
    },
    {
        "time": 1689751840000,
        "value": 139.352,
        "_internal_originalTime": 1689751840000
    },
    {
        "time": 1689751841000,
        "value": 139.354,
        "_internal_originalTime": 1689751841000
    },
    {
        "time": 1689751842000,
        "value": 139.358,
        "_internal_originalTime": 1689751842000
    },
    {
        "time": 1689751843000,
        "value": 139.358,
        "_internal_originalTime": 1689751843000
    },
    {
        "time": 1689751844000,
        "value": 139.349,
        "_internal_originalTime": 1689751844000
    },
    {
        "time": 1689751845000,
        "value": 139.351,
        "_internal_originalTime": 1689751845000
    },
    {
        "time": 1689751847000,
        "value": 139.352,
        "_internal_originalTime": 1689751847000
    },
    {
        "time": 1689751848000,
        "value": 139.355,
        "_internal_originalTime": 1689751848000
    },
    {
        "time": 1689751849000,
        "value": 139.355,
        "_internal_originalTime": 1689751849000
    },
    {
        "time": 1689751850000,
        "value": 139.353,
        "_internal_originalTime": 1689751850000
    },
    {
        "time": 1689751851000,
        "value": 139.354,
        "_internal_originalTime": 1689751851000
    },
    {
        "time": 1689751852000,
        "value": 139.351,
        "_internal_originalTime": 1689751852000
    },
    {
        "time": 1689751853000,
        "value": 139.345,
        "_internal_originalTime": 1689751853000
    },
    {
        "time": 1689751854000,
        "value": 139.342,
        "_internal_originalTime": 1689751854000
    },
    {
        "time": 1689751855000,
        "value": 139.342,
        "_internal_originalTime": 1689751855000
    },
    {
        "time": 1689751856000,
        "value": 139.34,
        "_internal_originalTime": 1689751856000
    },
    {
        "time": 1689751857000,
        "value": 139.34,
        "_internal_originalTime": 1689751857000
    },
    {
        "time": 1689751859000,
        "value": 139.342,
        "_internal_originalTime": 1689751859000
    },
    {
        "time": 1689751860000,
        "value": 139.339,
        "_internal_originalTime": 1689751860000
    },
    {
        "time": 1689751861000,
        "value": 139.341,
        "_internal_originalTime": 1689751861000
    },
    {
        "time": 1689751862000,
        "value": 139.336,
        "_internal_originalTime": 1689751862000
    },
    {
        "time": 1689751863000,
        "value": 139.336,
        "_internal_originalTime": 1689751863000
    },
    {
        "time": 1689751864000,
        "value": 139.336,
        "_internal_originalTime": 1689751864000
    },
    {
        "time": 1689751865000,
        "value": 139.339,
        "_internal_originalTime": 1689751865000
    },
    {
        "time": 1689751867000,
        "value": 139.333,
        "_internal_originalTime": 1689751867000
    },
    {
        "time": 1689751868000,
        "value": 139.33,
        "_internal_originalTime": 1689751868000
    },
    {
        "time": 1689751869000,
        "value": 139.33,
        "_internal_originalTime": 1689751869000
    },
    {
        "time": 1689751871000,
        "value": 139.334,
        "_internal_originalTime": 1689751871000
    },
    {
        "time": 1689751872000,
        "value": 139.338,
        "_internal_originalTime": 1689751872000
    },
    {
        "time": 1689751873000,
        "value": 139.342,
        "_internal_originalTime": 1689751873000
    },
    {
        "time": 1689751875000,
        "value": 139.339,
        "_internal_originalTime": 1689751875000
    },
    {
        "time": 1689751876000,
        "value": 139.341,
        "_internal_originalTime": 1689751876000
    },
    {
        "time": 1689751878000,
        "value": 139.342,
        "_internal_originalTime": 1689751878000
    },
    {
        "time": 1689751879000,
        "value": 139.341,
        "_internal_originalTime": 1689751879000
    },
    {
        "time": 1689751880000,
        "value": 139.342,
        "_internal_originalTime": 1689751880000
    },
    {
        "time": 1689751881000,
        "value": 139.34,
        "_internal_originalTime": 1689751881000
    },
    {
        "time": 1689751882000,
        "value": 139.342,
        "_internal_originalTime": 1689751882000
    },
    {
        "time": 1689751883000,
        "value": 139.342,
        "_internal_originalTime": 1689751883000
    },
    {
        "time": 1689751884000,
        "value": 139.345,
        "_internal_originalTime": 1689751884000
    },
    {
        "time": 1689751885000,
        "value": 139.34,
        "_internal_originalTime": 1689751885000
    },
    {
        "time": 1689751886000,
        "value": 139.357,
        "_internal_originalTime": 1689751886000
    },
    {
        "time": 1689751887000,
        "value": 139.352,
        "_internal_originalTime": 1689751887000
    },
    {
        "time": 1689751888000,
        "value": 139.358,
        "_internal_originalTime": 1689751888000
    },
    {
        "time": 1689751890000,
        "value": 139.357,
        "_internal_originalTime": 1689751890000
    },
    {
        "time": 1689751891000,
        "value": 139.355,
        "_internal_originalTime": 1689751891000
    },
    {
        "time": 1689751893000,
        "value": 139.362,
        "_internal_originalTime": 1689751893000
    },
    {
        "time": 1689751894000,
        "value": 139.368,
        "_internal_originalTime": 1689751894000
    },
    {
        "time": 1689751895000,
        "value": 139.365,
        "_internal_originalTime": 1689751895000
    },
    {
        "time": 1689751896000,
        "value": 139.367,
        "_internal_originalTime": 1689751896000
    },
    {
        "time": 1689751897000,
        "value": 139.369,
        "_internal_originalTime": 1689751897000
    },
    {
        "time": 1689751898000,
        "value": 139.372,
        "_internal_originalTime": 1689751898000
    },
    {
        "time": 1689751899000,
        "value": 139.371,
        "_internal_originalTime": 1689751899000
    },
    {
        "time": 1689751900000,
        "value": 139.371,
        "_internal_originalTime": 1689751900000
    },
    {
        "time": 1689751902000,
        "value": 139.37,
        "_internal_originalTime": 1689751902000
    },
    {
        "time": 1689751903000,
        "value": 139.369,
        "_internal_originalTime": 1689751903000
    },
    {
        "time": 1689751905000,
        "value": 139.371,
        "_internal_originalTime": 1689751905000
    },
    {
        "time": 1689751907000,
        "value": 139.373,
        "_internal_originalTime": 1689751907000
    },
    {
        "time": 1689751908000,
        "value": 139.368,
        "_internal_originalTime": 1689751908000
    },
    {
        "time": 1689751909000,
        "value": 139.371,
        "_internal_originalTime": 1689751909000
    },
    {
        "time": 1689751910000,
        "value": 139.373,
        "_internal_originalTime": 1689751910000
    },
    {
        "time": 1689751912000,
        "value": 139.375,
        "_internal_originalTime": 1689751912000
    },
    {
        "time": 1689751913000,
        "value": 139.376,
        "_internal_originalTime": 1689751913000
    },
    {
        "time": 1689751914000,
        "value": 139.375,
        "_internal_originalTime": 1689751914000
    },
    {
        "time": 1689751915000,
        "value": 139.386,
        "_internal_originalTime": 1689751915000
    },
    {
        "time": 1689751916000,
        "value": 139.384,
        "_internal_originalTime": 1689751916000
    },
    {
        "time": 1689751917000,
        "value": 139.385,
        "_internal_originalTime": 1689751917000
    },
    {
        "time": 1689751919000,
        "value": 139.382,
        "_internal_originalTime": 1689751919000
    },
    {
        "time": 1689751920000,
        "value": 139.383,
        "_internal_originalTime": 1689751920000
    },
    {
        "time": 1689751922000,
        "value": 139.381,
        "_internal_originalTime": 1689751922000
    },
    {
        "time": 1689751923000,
        "value": 139.382,
        "_internal_originalTime": 1689751923000
    },
    {
        "time": 1689751924000,
        "value": 139.379,
        "_internal_originalTime": 1689751924000
    },
    {
        "time": 1689751925000,
        "value": 139.382,
        "_internal_originalTime": 1689751925000
    },
    {
        "time": 1689751926000,
        "value": 139.384,
        "_internal_originalTime": 1689751926000
    },
    {
        "time": 1689751927000,
        "value": 139.384,
        "_internal_originalTime": 1689751927000
    },
    {
        "time": 1689751928000,
        "value": 139.382,
        "_internal_originalTime": 1689751928000
    },
    {
        "time": 1689751929000,
        "value": 139.385,
        "_internal_originalTime": 1689751929000
    },
    {
        "time": 1689751930000,
        "value": 139.385,
        "_internal_originalTime": 1689751930000
    },
    {
        "time": 1689751931000,
        "value": 139.381,
        "_internal_originalTime": 1689751931000
    },
    {
        "time": 1689751933000,
        "value": 139.377,
        "_internal_originalTime": 1689751933000
    },
    {
        "time": 1689751934000,
        "value": 139.376,
        "_internal_originalTime": 1689751934000
    },
    {
        "time": 1689751935000,
        "value": 139.375,
        "_internal_originalTime": 1689751935000
    },
    {
        "time": 1689751936000,
        "value": 139.372,
        "_internal_originalTime": 1689751936000
    },
    {
        "time": 1689751938000,
        "value": 139.375,
        "_internal_originalTime": 1689751938000
    },
    {
        "time": 1689751940000,
        "value": 139.372,
        "_internal_originalTime": 1689751940000
    },
    {
        "time": 1689751941000,
        "value": 139.381,
        "_internal_originalTime": 1689751941000
    },
    {
        "time": 1689751942000,
        "value": 139.379,
        "_internal_originalTime": 1689751942000
    },
    {
        "time": 1689751944000,
        "value": 139.39,
        "_internal_originalTime": 1689751944000
    },
    {
        "time": 1689751945000,
        "value": 139.379,
        "_internal_originalTime": 1689751945000
    },
    {
        "time": 1689751946000,
        "value": 139.377,
        "_internal_originalTime": 1689751946000
    },
    {
        "time": 1689751947000,
        "value": 139.377,
        "_internal_originalTime": 1689751947000
    },
    {
        "time": 1689751948000,
        "value": 139.376,
        "_internal_originalTime": 1689751948000
    },
    {
        "time": 1689751949000,
        "value": 139.375,
        "_internal_originalTime": 1689751949000
    },
    {
        "time": 1689751950000,
        "value": 139.368,
        "_internal_originalTime": 1689751950000
    },
    {
        "time": 1689751952000,
        "value": 139.375,
        "_internal_originalTime": 1689751952000
    },
    {
        "time": 1689751953000,
        "value": 139.373,
        "_internal_originalTime": 1689751953000
    },
    {
        "time": 1689751955000,
        "value": 139.372,
        "_internal_originalTime": 1689751955000
    },
    {
        "time": 1689751956000,
        "value": 139.379,
        "_internal_originalTime": 1689751956000
    },
    {
        "time": 1689751957000,
        "value": 139.379,
        "_internal_originalTime": 1689751957000
    },
    {
        "time": 1689751958000,
        "value": 139.377,
        "_internal_originalTime": 1689751958000
    },
    {
        "time": 1689751959000,
        "value": 139.38,
        "_internal_originalTime": 1689751959000
    },
    {
        "time": 1689751960000,
        "value": 139.38,
        "_internal_originalTime": 1689751960000
    },
    {
        "time": 1689751961000,
        "value": 139.384,
        "_internal_originalTime": 1689751961000
    },
    {
        "time": 1689751962000,
        "value": 139.383,
        "_internal_originalTime": 1689751962000
    },
    {
        "time": 1689751963000,
        "value": 139.382,
        "_internal_originalTime": 1689751963000
    },
    {
        "time": 1689751964000,
        "value": 139.385,
        "_internal_originalTime": 1689751964000
    },
    {
        "time": 1689751965000,
        "value": 139.379,
        "_internal_originalTime": 1689751965000
    },
    {
        "time": 1689751967000,
        "value": 139.371,
        "_internal_originalTime": 1689751967000
    },
    {
        "time": 1689751968000,
        "value": 139.368,
        "_internal_originalTime": 1689751968000
    },
    {
        "time": 1689751969000,
        "value": 139.373,
        "_internal_originalTime": 1689751969000
    },
    {
        "time": 1689751970000,
        "value": 139.369,
        "_internal_originalTime": 1689751970000
    },
    {
        "time": 1689751972000,
        "value": 139.371,
        "_internal_originalTime": 1689751972000
    },
    {
        "time": 1689751973000,
        "value": 139.371,
        "_internal_originalTime": 1689751973000
    },
    {
        "time": 1689751974000,
        "value": 139.369,
        "_internal_originalTime": 1689751974000
    },
    {
        "time": 1689751975000,
        "value": 139.371,
        "_internal_originalTime": 1689751975000
    },
    {
        "time": 1689751977000,
        "value": 139.37,
        "_internal_originalTime": 1689751977000
    },
    {
        "time": 1689751979000,
        "value": 139.37,
        "_internal_originalTime": 1689751979000
    },
    {
        "time": 1689751980000,
        "value": 139.37,
        "_internal_originalTime": 1689751980000
    },
    {
        "time": 1689751981000,
        "value": 139.371,
        "_internal_originalTime": 1689751981000
    },
    {
        "time": 1689751983000,
        "value": 139.368,
        "_internal_originalTime": 1689751983000
    },
    {
        "time": 1689751984000,
        "value": 139.369,
        "_internal_originalTime": 1689751984000
    },
    {
        "time": 1689751985000,
        "value": 139.372,
        "_internal_originalTime": 1689751985000
    },
    {
        "time": 1689751986000,
        "value": 139.368,
        "_internal_originalTime": 1689751986000
    },
    {
        "time": 1689751987000,
        "value": 139.369,
        "_internal_originalTime": 1689751987000
    },
    {
        "time": 1689751988000,
        "value": 139.369,
        "_internal_originalTime": 1689751988000
    },
    {
        "time": 1689751989000,
        "value": 139.371,
        "_internal_originalTime": 1689751989000
    },
    {
        "time": 1689751991000,
        "value": 139.368,
        "_internal_originalTime": 1689751991000
    },
    {
        "time": 1689751992000,
        "value": 139.368,
        "_internal_originalTime": 1689751992000
    },
    {
        "time": 1689751994000,
        "value": 139.368,
        "_internal_originalTime": 1689751994000
    },
    {
        "time": 1689751995000,
        "value": 139.366,
        "_internal_originalTime": 1689751995000
    },
    {
        "time": 1689751999000,
        "value": 139.37,
        "_internal_originalTime": 1689751999000
    },
    {
        "time": 1689752000000,
        "value": 139.364,
        "_internal_originalTime": 1689752000000
    },
    {
        "time": 1689752002000,
        "value": 139.364,
        "_internal_originalTime": 1689752002000
    },
    {
        "time": 1689752004000,
        "value": 139.372,
        "_internal_originalTime": 1689752004000
    },
    {
        "time": 1689752005000,
        "value": 139.368,
        "_internal_originalTime": 1689752005000
    },
    {
        "time": 1689752006000,
        "value": 139.374,
        "_internal_originalTime": 1689752006000
    },
    {
        "time": 1689752007000,
        "value": 139.374,
        "_internal_originalTime": 1689752007000
    },
    {
        "time": 1689752008000,
        "value": 139.372,
        "_internal_originalTime": 1689752008000
    },
    {
        "time": 1689752009000,
        "value": 139.37,
        "_internal_originalTime": 1689752009000
    },
    {
        "time": 1689752010000,
        "value": 139.37,
        "_internal_originalTime": 1689752010000
    },
    {
        "time": 1689752011000,
        "value": 139.373,
        "_internal_originalTime": 1689752011000
    },
    {
        "time": 1689752012000,
        "value": 139.382,
        "_internal_originalTime": 1689752012000
    },
    {
        "time": 1689752013000,
        "value": 139.377,
        "_internal_originalTime": 1689752013000
    },
    {
        "time": 1689752014000,
        "value": 139.377,
        "_internal_originalTime": 1689752014000
    },
    {
        "time": 1689752015000,
        "value": 139.378,
        "_internal_originalTime": 1689752015000
    },
    {
        "time": 1689752016000,
        "value": 139.38,
        "_internal_originalTime": 1689752016000
    },
    {
        "time": 1689752017000,
        "value": 139.377,
        "_internal_originalTime": 1689752017000
    },
    {
        "time": 1689752018000,
        "value": 139.378,
        "_internal_originalTime": 1689752018000
    },
    {
        "time": 1689752019000,
        "value": 139.377,
        "_internal_originalTime": 1689752019000
    },
    {
        "time": 1689752020000,
        "value": 139.375,
        "_internal_originalTime": 1689752020000
    },
    {
        "time": 1689752021000,
        "value": 139.374,
        "_internal_originalTime": 1689752021000
    },
    {
        "time": 1689752022000,
        "value": 139.377,
        "_internal_originalTime": 1689752022000
    },
    {
        "time": 1689752023000,
        "value": 139.376,
        "_internal_originalTime": 1689752023000
    },
    {
        "time": 1689752024000,
        "value": 139.377,
        "_internal_originalTime": 1689752024000
    },
    {
        "time": 1689752025000,
        "value": 139.377,
        "_internal_originalTime": 1689752025000
    },
    {
        "time": 1689752026000,
        "value": 139.381,
        "_internal_originalTime": 1689752026000
    },
    {
        "time": 1689752027000,
        "value": 139.38,
        "_internal_originalTime": 1689752027000
    },
    {
        "time": 1689752029000,
        "value": 139.38,
        "_internal_originalTime": 1689752029000
    },
    {
        "time": 1689752030000,
        "value": 139.385,
        "_internal_originalTime": 1689752030000
    },
    {
        "time": 1689752031000,
        "value": 139.384,
        "_internal_originalTime": 1689752031000
    },
    {
        "time": 1689752032000,
        "value": 139.384,
        "_internal_originalTime": 1689752032000
    },
    {
        "time": 1689752033000,
        "value": 139.384,
        "_internal_originalTime": 1689752033000
    },
    {
        "time": 1689752035000,
        "value": 139.384,
        "_internal_originalTime": 1689752035000
    },
    {
        "time": 1689752036000,
        "value": 139.389,
        "_internal_originalTime": 1689752036000
    },
    {
        "time": 1689752037000,
        "value": 139.391,
        "_internal_originalTime": 1689752037000
    },
    {
        "time": 1689752038000,
        "value": 139.388,
        "_internal_originalTime": 1689752038000
    },
    {
        "time": 1689752040000,
        "value": 139.388,
        "_internal_originalTime": 1689752040000
    },
    {
        "time": 1689752041000,
        "value": 139.383,
        "_internal_originalTime": 1689752041000
    },
    {
        "time": 1689752042000,
        "value": 139.382,
        "_internal_originalTime": 1689752042000
    },
    {
        "time": 1689752043000,
        "value": 139.39,
        "_internal_originalTime": 1689752043000
    },
    {
        "time": 1689752044000,
        "value": 139.387,
        "_internal_originalTime": 1689752044000
    },
    {
        "time": 1689752045000,
        "value": 139.389,
        "_internal_originalTime": 1689752045000
    },
    {
        "time": 1689752046000,
        "value": 139.39,
        "_internal_originalTime": 1689752046000
    },
    {
        "time": 1689752047000,
        "value": 139.387,
        "_internal_originalTime": 1689752047000
    },
    {
        "time": 1689752048000,
        "value": 139.4,
        "_internal_originalTime": 1689752048000
    },
    {
        "time": 1689752049000,
        "value": 139.402,
        "_internal_originalTime": 1689752049000
    },
    {
        "time": 1689752051000,
        "value": 139.403,
        "_internal_originalTime": 1689752051000
    },
    {
        "time": 1689752052000,
        "value": 139.399,
        "_internal_originalTime": 1689752052000
    },
    {
        "time": 1689752053000,
        "value": 139.396,
        "_internal_originalTime": 1689752053000
    },
    {
        "time": 1689752055000,
        "value": 139.397,
        "_internal_originalTime": 1689752055000
    },
    {
        "time": 1689752056000,
        "value": 139.396,
        "_internal_originalTime": 1689752056000
    },
    {
        "time": 1689752057000,
        "value": 139.395,
        "_internal_originalTime": 1689752057000
    },
    {
        "time": 1689752058000,
        "value": 139.397,
        "_internal_originalTime": 1689752058000
    },
    {
        "time": 1689752062000,
        "value": 139.411,
        "_internal_originalTime": 1689752062000
    },
    {
        "time": 1689752064000,
        "value": 139.412,
        "_internal_originalTime": 1689752064000
    },
    {
        "time": 1689752065000,
        "value": 139.412,
        "_internal_originalTime": 1689752065000
    },
    {
        "time": 1689752066000,
        "value": 139.408,
        "_internal_originalTime": 1689752066000
    },
    {
        "time": 1689752067000,
        "value": 139.409,
        "_internal_originalTime": 1689752067000
    },
    {
        "time": 1689752068000,
        "value": 139.412,
        "_internal_originalTime": 1689752068000
    },
    {
        "time": 1689752069000,
        "value": 139.417,
        "_internal_originalTime": 1689752069000
    },
    {
        "time": 1689752070000,
        "value": 139.414,
        "_internal_originalTime": 1689752070000
    },
    {
        "time": 1689752071000,
        "value": 139.416,
        "_internal_originalTime": 1689752071000
    },
    {
        "time": 1689752073000,
        "value": 139.419,
        "_internal_originalTime": 1689752073000
    },
    {
        "time": 1689752075000,
        "value": 139.427,
        "_internal_originalTime": 1689752075000
    },
    {
        "time": 1689752076000,
        "value": 139.424,
        "_internal_originalTime": 1689752076000
    },
    {
        "time": 1689752077000,
        "value": 139.429,
        "_internal_originalTime": 1689752077000
    },
    {
        "time": 1689752078000,
        "value": 139.427,
        "_internal_originalTime": 1689752078000
    },
    {
        "time": 1689752079000,
        "value": 139.429,
        "_internal_originalTime": 1689752079000
    },
    {
        "time": 1689752080000,
        "value": 139.429,
        "_internal_originalTime": 1689752080000
    },
    {
        "time": 1689752082000,
        "value": 139.429,
        "_internal_originalTime": 1689752082000
    },
    {
        "time": 1689752083000,
        "value": 139.427,
        "_internal_originalTime": 1689752083000
    },
    {
        "time": 1689752084000,
        "value": 139.425,
        "_internal_originalTime": 1689752084000
    },
    {
        "time": 1689752085000,
        "value": 139.429,
        "_internal_originalTime": 1689752085000
    },
    {
        "time": 1689752087000,
        "value": 139.429,
        "_internal_originalTime": 1689752087000
    },
    {
        "time": 1689752088000,
        "value": 139.427,
        "_internal_originalTime": 1689752088000
    },
    {
        "time": 1689752091000,
        "value": 139.411,
        "_internal_originalTime": 1689752091000
    },
    {
        "time": 1689752093000,
        "value": 139.411,
        "_internal_originalTime": 1689752093000
    },
    {
        "time": 1689752094000,
        "value": 139.412,
        "_internal_originalTime": 1689752094000
    },
    {
        "time": 1689752095000,
        "value": 139.407,
        "_internal_originalTime": 1689752095000
    },
    {
        "time": 1689752096000,
        "value": 139.404,
        "_internal_originalTime": 1689752096000
    },
    {
        "time": 1689752097000,
        "value": 139.41,
        "_internal_originalTime": 1689752097000
    },
    {
        "time": 1689752099000,
        "value": 139.406,
        "_internal_originalTime": 1689752099000
    },
    {
        "time": 1689752100000,
        "value": 139.407,
        "_internal_originalTime": 1689752100000
    },
    {
        "time": 1689752101000,
        "value": 139.403,
        "_internal_originalTime": 1689752101000
    },
    {
        "time": 1689752102000,
        "value": 139.409,
        "_internal_originalTime": 1689752102000
    },
    {
        "time": 1689752103000,
        "value": 139.406,
        "_internal_originalTime": 1689752103000
    },
    {
        "time": 1689752104000,
        "value": 139.409,
        "_internal_originalTime": 1689752104000
    },
    {
        "time": 1689752105000,
        "value": 139.406,
        "_internal_originalTime": 1689752105000
    },
    {
        "time": 1689752106000,
        "value": 139.409,
        "_internal_originalTime": 1689752106000
    },
    {
        "time": 1689752107000,
        "value": 139.407,
        "_internal_originalTime": 1689752107000
    },
    {
        "time": 1689752108000,
        "value": 139.407,
        "_internal_originalTime": 1689752108000
    },
    {
        "time": 1689752110000,
        "value": 139.401,
        "_internal_originalTime": 1689752110000
    },
    {
        "time": 1689752111000,
        "value": 139.392,
        "_internal_originalTime": 1689752111000
    },
    {
        "time": 1689752113000,
        "value": 139.395,
        "_internal_originalTime": 1689752113000
    },
    {
        "time": 1689752115000,
        "value": 139.386,
        "_internal_originalTime": 1689752115000
    },
    {
        "time": 1689752116000,
        "value": 139.39,
        "_internal_originalTime": 1689752116000
    },
    {
        "time": 1689752118000,
        "value": 139.39,
        "_internal_originalTime": 1689752118000
    },
    {
        "time": 1689752119000,
        "value": 139.383,
        "_internal_originalTime": 1689752119000
    },
    {
        "time": 1689752120000,
        "value": 139.382,
        "_internal_originalTime": 1689752120000
    },
    {
        "time": 1689752122000,
        "value": 139.386,
        "_internal_originalTime": 1689752122000
    },
    {
        "time": 1689752124000,
        "value": 139.379,
        "_internal_originalTime": 1689752124000
    },
    {
        "time": 1689752125000,
        "value": 139.374,
        "_internal_originalTime": 1689752125000
    },
    {
        "time": 1689752126000,
        "value": 139.374,
        "_internal_originalTime": 1689752126000
    },
    {
        "time": 1689752127000,
        "value": 139.364,
        "_internal_originalTime": 1689752127000
    },
    {
        "time": 1689752128000,
        "value": 139.366,
        "_internal_originalTime": 1689752128000
    },
    {
        "time": 1689752129000,
        "value": 139.359,
        "_internal_originalTime": 1689752129000
    },
    {
        "time": 1689752130000,
        "value": 139.359,
        "_internal_originalTime": 1689752130000
    },
    {
        "time": 1689752131000,
        "value": 139.359,
        "_internal_originalTime": 1689752131000
    },
    {
        "time": 1689752132000,
        "value": 139.36,
        "_internal_originalTime": 1689752132000
    },
    {
        "time": 1689752134000,
        "value": 139.35,
        "_internal_originalTime": 1689752134000
    },
    {
        "time": 1689752135000,
        "value": 139.348,
        "_internal_originalTime": 1689752135000
    },
    {
        "time": 1689752136000,
        "value": 139.354,
        "_internal_originalTime": 1689752136000
    },
    {
        "time": 1689752138000,
        "value": 139.359,
        "_internal_originalTime": 1689752138000
    },
    {
        "time": 1689752139000,
        "value": 139.369,
        "_internal_originalTime": 1689752139000
    },
    {
        "time": 1689752141000,
        "value": 139.372,
        "_internal_originalTime": 1689752141000
    },
    {
        "time": 1689752143000,
        "value": 139.367,
        "_internal_originalTime": 1689752143000
    },
    {
        "time": 1689752145000,
        "value": 139.367,
        "_internal_originalTime": 1689752145000
    },
    {
        "time": 1689752146000,
        "value": 139.363,
        "_internal_originalTime": 1689752146000
    },
    {
        "time": 1689752147000,
        "value": 139.361,
        "_internal_originalTime": 1689752147000
    },
    {
        "time": 1689752148000,
        "value": 139.359,
        "_internal_originalTime": 1689752148000
    },
    {
        "time": 1689752150000,
        "value": 139.356,
        "_internal_originalTime": 1689752150000
    },
    {
        "time": 1689752151000,
        "value": 139.358,
        "_internal_originalTime": 1689752151000
    },
    {
        "time": 1689752152000,
        "value": 139.36,
        "_internal_originalTime": 1689752152000
    },
    {
        "time": 1689752154000,
        "value": 139.353,
        "_internal_originalTime": 1689752154000
    },
    {
        "time": 1689752156000,
        "value": 139.353,
        "_internal_originalTime": 1689752156000
    },
    {
        "time": 1689752158000,
        "value": 139.351,
        "_internal_originalTime": 1689752158000
    },
    {
        "time": 1689752159000,
        "value": 139.354,
        "_internal_originalTime": 1689752159000
    },
    {
        "time": 1689752160000,
        "value": 139.356,
        "_internal_originalTime": 1689752160000
    },
    {
        "time": 1689752161000,
        "value": 139.358,
        "_internal_originalTime": 1689752161000
    },
    {
        "time": 1689752162000,
        "value": 139.358,
        "_internal_originalTime": 1689752162000
    },
    {
        "time": 1689752163000,
        "value": 139.357,
        "_internal_originalTime": 1689752163000
    },
    {
        "time": 1689752164000,
        "value": 139.359,
        "_internal_originalTime": 1689752164000
    },
    {
        "time": 1689752165000,
        "value": 139.358,
        "_internal_originalTime": 1689752165000
    },
    {
        "time": 1689752166000,
        "value": 139.358,
        "_internal_originalTime": 1689752166000
    },
    {
        "time": 1689752167000,
        "value": 139.349,
        "_internal_originalTime": 1689752167000
    },
    {
        "time": 1689752168000,
        "value": 139.35,
        "_internal_originalTime": 1689752168000
    },
    {
        "time": 1689752169000,
        "value": 139.351,
        "_internal_originalTime": 1689752169000
    },
    {
        "time": 1689752170000,
        "value": 139.351,
        "_internal_originalTime": 1689752170000
    },
    {
        "time": 1689752171000,
        "value": 139.347,
        "_internal_originalTime": 1689752171000
    },
    {
        "time": 1689752172000,
        "value": 139.351,
        "_internal_originalTime": 1689752172000
    },
    {
        "time": 1689752173000,
        "value": 139.351,
        "_internal_originalTime": 1689752173000
    },
    {
        "time": 1689752174000,
        "value": 139.348,
        "_internal_originalTime": 1689752174000
    },
    {
        "time": 1689752177000,
        "value": 139.371,
        "_internal_originalTime": 1689752177000
    },
    {
        "time": 1689752178000,
        "value": 139.369,
        "_internal_originalTime": 1689752178000
    },
    {
        "time": 1689752179000,
        "value": 139.372,
        "_internal_originalTime": 1689752179000
    },
    {
        "time": 1689752180000,
        "value": 139.371,
        "_internal_originalTime": 1689752180000
    },
    {
        "time": 1689752181000,
        "value": 139.372,
        "_internal_originalTime": 1689752181000
    },
    {
        "time": 1689752182000,
        "value": 139.372,
        "_internal_originalTime": 1689752182000
    },
    {
        "time": 1689752183000,
        "value": 139.367,
        "_internal_originalTime": 1689752183000
    },
    {
        "time": 1689752184000,
        "value": 139.372,
        "_internal_originalTime": 1689752184000
    },
    {
        "time": 1689752185000,
        "value": 139.378,
        "_internal_originalTime": 1689752185000
    },
    {
        "time": 1689752186000,
        "value": 139.377,
        "_internal_originalTime": 1689752186000
    },
    {
        "time": 1689752187000,
        "value": 139.378,
        "_internal_originalTime": 1689752187000
    },
    {
        "time": 1689752188000,
        "value": 139.383,
        "_internal_originalTime": 1689752188000
    },
    {
        "time": 1689752190000,
        "value": 139.375,
        "_internal_originalTime": 1689752190000
    },
    {
        "time": 1689752191000,
        "value": 139.372,
        "_internal_originalTime": 1689752191000
    },
    {
        "time": 1689752193000,
        "value": 139.372,
        "_internal_originalTime": 1689752193000
    },
    {
        "time": 1689752195000,
        "value": 139.373,
        "_internal_originalTime": 1689752195000
    },
    {
        "time": 1689752196000,
        "value": 139.368,
        "_internal_originalTime": 1689752196000
    },
    {
        "time": 1689752198000,
        "value": 139.37,
        "_internal_originalTime": 1689752198000
    },
    {
        "time": 1689752199000,
        "value": 139.37,
        "_internal_originalTime": 1689752199000
    },
    {
        "time": 1689752200000,
        "value": 139.368,
        "_internal_originalTime": 1689752200000
    },
    {
        "time": 1689752202000,
        "value": 139.366,
        "_internal_originalTime": 1689752202000
    },
    {
        "time": 1689752204000,
        "value": 139.368,
        "_internal_originalTime": 1689752204000
    },
    {
        "time": 1689752205000,
        "value": 139.363,
        "_internal_originalTime": 1689752205000
    },
    {
        "time": 1689752206000,
        "value": 139.356,
        "_internal_originalTime": 1689752206000
    },
    {
        "time": 1689752208000,
        "value": 139.359,
        "_internal_originalTime": 1689752208000
    },
    {
        "time": 1689752209000,
        "value": 139.354,
        "_internal_originalTime": 1689752209000
    },
    {
        "time": 1689752210000,
        "value": 139.356,
        "_internal_originalTime": 1689752210000
    },
    {
        "time": 1689752211000,
        "value": 139.355,
        "_internal_originalTime": 1689752211000
    },
    {
        "time": 1689752212000,
        "value": 139.353,
        "_internal_originalTime": 1689752212000
    },
    {
        "time": 1689752213000,
        "value": 139.353,
        "_internal_originalTime": 1689752213000
    },
    {
        "time": 1689752214000,
        "value": 139.356,
        "_internal_originalTime": 1689752214000
    },
    {
        "time": 1689752215000,
        "value": 139.356,
        "_internal_originalTime": 1689752215000
    },
    {
        "time": 1689752216000,
        "value": 139.354,
        "_internal_originalTime": 1689752216000
    },
    {
        "time": 1689752217000,
        "value": 139.358,
        "_internal_originalTime": 1689752217000
    },
    {
        "time": 1689752218000,
        "value": 139.357,
        "_internal_originalTime": 1689752218000
    },
    {
        "time": 1689752219000,
        "value": 139.359,
        "_internal_originalTime": 1689752219000
    },
    {
        "time": 1689752221000,
        "value": 139.359,
        "_internal_originalTime": 1689752221000
    },
    {
        "time": 1689752222000,
        "value": 139.354,
        "_internal_originalTime": 1689752222000
    },
    {
        "time": 1689752223000,
        "value": 139.354,
        "_internal_originalTime": 1689752223000
    },
    {
        "time": 1689752225000,
        "value": 139.354,
        "_internal_originalTime": 1689752225000
    },
    {
        "time": 1689752226000,
        "value": 139.356,
        "_internal_originalTime": 1689752226000
    },
    {
        "time": 1689752227000,
        "value": 139.349,
        "_internal_originalTime": 1689752227000
    },
    {
        "time": 1689752228000,
        "value": 139.348,
        "_internal_originalTime": 1689752228000
    },
    {
        "time": 1689752229000,
        "value": 139.355,
        "_internal_originalTime": 1689752229000
    },
    {
        "time": 1689752230000,
        "value": 139.353,
        "_internal_originalTime": 1689752230000
    },
    {
        "time": 1689752231000,
        "value": 139.352,
        "_internal_originalTime": 1689752231000
    },
    {
        "time": 1689752232000,
        "value": 139.359,
        "_internal_originalTime": 1689752232000
    },
    {
        "time": 1689752233000,
        "value": 139.36,
        "_internal_originalTime": 1689752233000
    },
    {
        "time": 1689752235000,
        "value": 139.359,
        "_internal_originalTime": 1689752235000
    },
    {
        "time": 1689752236000,
        "value": 139.358,
        "_internal_originalTime": 1689752236000
    },
    {
        "time": 1689752238000,
        "value": 139.36,
        "_internal_originalTime": 1689752238000
    },
    {
        "time": 1689752239000,
        "value": 139.354,
        "_internal_originalTime": 1689752239000
    },
    {
        "time": 1689752240000,
        "value": 139.358,
        "_internal_originalTime": 1689752240000
    },
    {
        "time": 1689752241000,
        "value": 139.361,
        "_internal_originalTime": 1689752241000
    },
    {
        "time": 1689752242000,
        "value": 139.359,
        "_internal_originalTime": 1689752242000
    },
    {
        "time": 1689752243000,
        "value": 139.357,
        "_internal_originalTime": 1689752243000
    },
    {
        "time": 1689752244000,
        "value": 139.359,
        "_internal_originalTime": 1689752244000
    },
    {
        "time": 1689752246000,
        "value": 139.359,
        "_internal_originalTime": 1689752246000
    },
    {
        "time": 1689752247000,
        "value": 139.36,
        "_internal_originalTime": 1689752247000
    },
    {
        "time": 1689752248000,
        "value": 139.361,
        "_internal_originalTime": 1689752248000
    },
    {
        "time": 1689752250000,
        "value": 139.355,
        "_internal_originalTime": 1689752250000
    },
    {
        "time": 1689752251000,
        "value": 139.357,
        "_internal_originalTime": 1689752251000
    },
    {
        "time": 1689752252000,
        "value": 139.358,
        "_internal_originalTime": 1689752252000
    },
    {
        "time": 1689752253000,
        "value": 139.359,
        "_internal_originalTime": 1689752253000
    },
    {
        "time": 1689752254000,
        "value": 139.358,
        "_internal_originalTime": 1689752254000
    },
    {
        "time": 1689752255000,
        "value": 139.359,
        "_internal_originalTime": 1689752255000
    },
    {
        "time": 1689752256000,
        "value": 139.359,
        "_internal_originalTime": 1689752256000
    },
    {
        "time": 1689752257000,
        "value": 139.36,
        "_internal_originalTime": 1689752257000
    },
    {
        "time": 1689752258000,
        "value": 139.362,
        "_internal_originalTime": 1689752258000
    },
    {
        "time": 1689752259000,
        "value": 139.371,
        "_internal_originalTime": 1689752259000
    },
    {
        "time": 1689752260000,
        "value": 139.372,
        "_internal_originalTime": 1689752260000
    },
    {
        "time": 1689752262000,
        "value": 139.372,
        "_internal_originalTime": 1689752262000
    },
    {
        "time": 1689752263000,
        "value": 139.368,
        "_internal_originalTime": 1689752263000
    },
    {
        "time": 1689752265000,
        "value": 139.373,
        "_internal_originalTime": 1689752265000
    },
    {
        "time": 1689752266000,
        "value": 139.372,
        "_internal_originalTime": 1689752266000
    },
    {
        "time": 1689752267000,
        "value": 139.373,
        "_internal_originalTime": 1689752267000
    },
    {
        "time": 1689752268000,
        "value": 139.373,
        "_internal_originalTime": 1689752268000
    },
    {
        "time": 1689752269000,
        "value": 139.376,
        "_internal_originalTime": 1689752269000
    },
    {
        "time": 1689752270000,
        "value": 139.378,
        "_internal_originalTime": 1689752270000
    },
    {
        "time": 1689752272000,
        "value": 139.377,
        "_internal_originalTime": 1689752272000
    },
    {
        "time": 1689752273000,
        "value": 139.38,
        "_internal_originalTime": 1689752273000
    },
    {
        "time": 1689752274000,
        "value": 139.379,
        "_internal_originalTime": 1689752274000
    },
    {
        "time": 1689752276000,
        "value": 139.377,
        "_internal_originalTime": 1689752276000
    },
    {
        "time": 1689752277000,
        "value": 139.375,
        "_internal_originalTime": 1689752277000
    },
    {
        "time": 1689752279000,
        "value": 139.37,
        "_internal_originalTime": 1689752279000
    },
    {
        "time": 1689752280000,
        "value": 139.368,
        "_internal_originalTime": 1689752280000
    },
    {
        "time": 1689752282000,
        "value": 139.368,
        "_internal_originalTime": 1689752282000
    },
    {
        "time": 1689752283000,
        "value": 139.372,
        "_internal_originalTime": 1689752283000
    },
    {
        "time": 1689752284000,
        "value": 139.373,
        "_internal_originalTime": 1689752284000
    },
    {
        "time": 1689752286000,
        "value": 139.37,
        "_internal_originalTime": 1689752286000
    },
    {
        "time": 1689752288000,
        "value": 139.374,
        "_internal_originalTime": 1689752288000
    },
    {
        "time": 1689752289000,
        "value": 139.373,
        "_internal_originalTime": 1689752289000
    },
    {
        "time": 1689752290000,
        "value": 139.372,
        "_internal_originalTime": 1689752290000
    },
    {
        "time": 1689752291000,
        "value": 139.368,
        "_internal_originalTime": 1689752291000
    },
    {
        "time": 1689752293000,
        "value": 139.362,
        "_internal_originalTime": 1689752293000
    },
    {
        "time": 1689752295000,
        "value": 139.362,
        "_internal_originalTime": 1689752295000
    },
    {
        "time": 1689752296000,
        "value": 139.373,
        "_internal_originalTime": 1689752296000
    },
    {
        "time": 1689752297000,
        "value": 139.371,
        "_internal_originalTime": 1689752297000
    },
    {
        "time": 1689752298000,
        "value": 139.368,
        "_internal_originalTime": 1689752298000
    },
    {
        "time": 1689752299000,
        "value": 139.368,
        "_internal_originalTime": 1689752299000
    },
    {
        "time": 1689752300000,
        "value": 139.372,
        "_internal_originalTime": 1689752300000
    },
    {
        "time": 1689752301000,
        "value": 139.371,
        "_internal_originalTime": 1689752301000
    },
    {
        "time": 1689752302000,
        "value": 139.37,
        "_internal_originalTime": 1689752302000
    },
    {
        "time": 1689752303000,
        "value": 139.366,
        "_internal_originalTime": 1689752303000
    },
    {
        "time": 1689752304000,
        "value": 139.362,
        "_internal_originalTime": 1689752304000
    },
    {
        "time": 1689752305000,
        "value": 139.368,
        "_internal_originalTime": 1689752305000
    },
    {
        "time": 1689752307000,
        "value": 139.366,
        "_internal_originalTime": 1689752307000
    },
    {
        "time": 1689752308000,
        "value": 139.372,
        "_internal_originalTime": 1689752308000
    },
    {
        "time": 1689752309000,
        "value": 139.375,
        "_internal_originalTime": 1689752309000
    },
    {
        "time": 1689752311000,
        "value": 139.372,
        "_internal_originalTime": 1689752311000
    },
    {
        "time": 1689752312000,
        "value": 139.377,
        "_internal_originalTime": 1689752312000
    },
    {
        "time": 1689752314000,
        "value": 139.38,
        "_internal_originalTime": 1689752314000
    },
    {
        "time": 1689752316000,
        "value": 139.378,
        "_internal_originalTime": 1689752316000
    },
    {
        "time": 1689752318000,
        "value": 139.377,
        "_internal_originalTime": 1689752318000
    },
    {
        "time": 1689752319000,
        "value": 139.373,
        "_internal_originalTime": 1689752319000
    },
    {
        "time": 1689752320000,
        "value": 139.371,
        "_internal_originalTime": 1689752320000
    },
    {
        "time": 1689752321000,
        "value": 139.369,
        "_internal_originalTime": 1689752321000
    },
    {
        "time": 1689752322000,
        "value": 139.365,
        "_internal_originalTime": 1689752322000
    },
    {
        "time": 1689752323000,
        "value": 139.364,
        "_internal_originalTime": 1689752323000
    },
    {
        "time": 1689752325000,
        "value": 139.368,
        "_internal_originalTime": 1689752325000
    },
    {
        "time": 1689752326000,
        "value": 139.37,
        "_internal_originalTime": 1689752326000
    },
    {
        "time": 1689752327000,
        "value": 139.371,
        "_internal_originalTime": 1689752327000
    },
    {
        "time": 1689752329000,
        "value": 139.371,
        "_internal_originalTime": 1689752329000
    },
    {
        "time": 1689752330000,
        "value": 139.368,
        "_internal_originalTime": 1689752330000
    },
    {
        "time": 1689752332000,
        "value": 139.365,
        "_internal_originalTime": 1689752332000
    },
    {
        "time": 1689752333000,
        "value": 139.363,
        "_internal_originalTime": 1689752333000
    },
    {
        "time": 1689752334000,
        "value": 139.368,
        "_internal_originalTime": 1689752334000
    },
    {
        "time": 1689752336000,
        "value": 139.369,
        "_internal_originalTime": 1689752336000
    },
    {
        "time": 1689752338000,
        "value": 139.37,
        "_internal_originalTime": 1689752338000
    },
    {
        "time": 1689752339000,
        "value": 139.371,
        "_internal_originalTime": 1689752339000
    },
    {
        "time": 1689752340000,
        "value": 139.369,
        "_internal_originalTime": 1689752340000
    },
    {
        "time": 1689752341000,
        "value": 139.369,
        "_internal_originalTime": 1689752341000
    },
    {
        "time": 1689752342000,
        "value": 139.372,
        "_internal_originalTime": 1689752342000
    },
    {
        "time": 1689752343000,
        "value": 139.373,
        "_internal_originalTime": 1689752343000
    },
    {
        "time": 1689752345000,
        "value": 139.367,
        "_internal_originalTime": 1689752345000
    },
    {
        "time": 1689752347000,
        "value": 139.372,
        "_internal_originalTime": 1689752347000
    },
    {
        "time": 1689752348000,
        "value": 139.372,
        "_internal_originalTime": 1689752348000
    },
    {
        "time": 1689752350000,
        "value": 139.367,
        "_internal_originalTime": 1689752350000
    },
    {
        "time": 1689752351000,
        "value": 139.373,
        "_internal_originalTime": 1689752351000
    },
    {
        "time": 1689752352000,
        "value": 139.371,
        "_internal_originalTime": 1689752352000
    },
    {
        "time": 1689752354000,
        "value": 139.372,
        "_internal_originalTime": 1689752354000
    },
    {
        "time": 1689752355000,
        "value": 139.371,
        "_internal_originalTime": 1689752355000
    },
    {
        "time": 1689752356000,
        "value": 139.371,
        "_internal_originalTime": 1689752356000
    },
    {
        "time": 1689752357000,
        "value": 139.371,
        "_internal_originalTime": 1689752357000
    },
    {
        "time": 1689752358000,
        "value": 139.37,
        "_internal_originalTime": 1689752358000
    },
    {
        "time": 1689752359000,
        "value": 139.373,
        "_internal_originalTime": 1689752359000
    },
    {
        "time": 1689752360000,
        "value": 139.367,
        "_internal_originalTime": 1689752360000
    },
    {
        "time": 1689752361000,
        "value": 139.362,
        "_internal_originalTime": 1689752361000
    },
    {
        "time": 1689752362000,
        "value": 139.363,
        "_internal_originalTime": 1689752362000
    },
    {
        "time": 1689752363000,
        "value": 139.368,
        "_internal_originalTime": 1689752363000
    },
    {
        "time": 1689752364000,
        "value": 139.368,
        "_internal_originalTime": 1689752364000
    },
    {
        "time": 1689752366000,
        "value": 139.369,
        "_internal_originalTime": 1689752366000
    },
    {
        "time": 1689752367000,
        "value": 139.375,
        "_internal_originalTime": 1689752367000
    },
    {
        "time": 1689752368000,
        "value": 139.369,
        "_internal_originalTime": 1689752368000
    },
    {
        "time": 1689752369000,
        "value": 139.372,
        "_internal_originalTime": 1689752369000
    },
    {
        "time": 1689752371000,
        "value": 139.377,
        "_internal_originalTime": 1689752371000
    },
    {
        "time": 1689752372000,
        "value": 139.377,
        "_internal_originalTime": 1689752372000
    },
    {
        "time": 1689752373000,
        "value": 139.377,
        "_internal_originalTime": 1689752373000
    },
    {
        "time": 1689752375000,
        "value": 139.378,
        "_internal_originalTime": 1689752375000
    },
    {
        "time": 1689752376000,
        "value": 139.376,
        "_internal_originalTime": 1689752376000
    },
    {
        "time": 1689752377000,
        "value": 139.378,
        "_internal_originalTime": 1689752377000
    },
    {
        "time": 1689752378000,
        "value": 139.378,
        "_internal_originalTime": 1689752378000
    },
    {
        "time": 1689752379000,
        "value": 139.379,
        "_internal_originalTime": 1689752379000
    },
    {
        "time": 1689752380000,
        "value": 139.381,
        "_internal_originalTime": 1689752380000
    },
    {
        "time": 1689752381000,
        "value": 139.38,
        "_internal_originalTime": 1689752381000
    },
    {
        "time": 1689752382000,
        "value": 139.387,
        "_internal_originalTime": 1689752382000
    },
    {
        "time": 1689752383000,
        "value": 139.395,
        "_internal_originalTime": 1689752383000
    },
    {
        "time": 1689752384000,
        "value": 139.4,
        "_internal_originalTime": 1689752384000
    },
    {
        "time": 1689752386000,
        "value": 139.395,
        "_internal_originalTime": 1689752386000
    },
    {
        "time": 1689752387000,
        "value": 139.393,
        "_internal_originalTime": 1689752387000
    },
    {
        "time": 1689752389000,
        "value": 139.393,
        "_internal_originalTime": 1689752389000
    },
    {
        "time": 1689752390000,
        "value": 139.394,
        "_internal_originalTime": 1689752390000
    },
    {
        "time": 1689752391000,
        "value": 139.398,
        "_internal_originalTime": 1689752391000
    },
    {
        "time": 1689752392000,
        "value": 139.397,
        "_internal_originalTime": 1689752392000
    },
    {
        "time": 1689752393000,
        "value": 139.394,
        "_internal_originalTime": 1689752393000
    },
    {
        "time": 1689752394000,
        "value": 139.402,
        "_internal_originalTime": 1689752394000
    },
    {
        "time": 1689752395000,
        "value": 139.403,
        "_internal_originalTime": 1689752395000
    },
    {
        "time": 1689752396000,
        "value": 139.402,
        "_internal_originalTime": 1689752396000
    },
    {
        "time": 1689752397000,
        "value": 139.397,
        "_internal_originalTime": 1689752397000
    },
    {
        "time": 1689752398000,
        "value": 139.402,
        "_internal_originalTime": 1689752398000
    },
    {
        "time": 1689752399000,
        "value": 139.394,
        "_internal_originalTime": 1689752399000
    },
    {
        "time": 1689752400000,
        "value": 139.397,
        "_internal_originalTime": 1689752400000
    },
    {
        "time": 1689752401000,
        "value": 139.401,
        "_internal_originalTime": 1689752401000
    },
    {
        "time": 1689752402000,
        "value": 139.396,
        "_internal_originalTime": 1689752402000
    },
    {
        "time": 1689752403000,
        "value": 139.402,
        "_internal_originalTime": 1689752403000
    },
    {
        "time": 1689752404000,
        "value": 139.398,
        "_internal_originalTime": 1689752404000
    },
    {
        "time": 1689752405000,
        "value": 139.394,
        "_internal_originalTime": 1689752405000
    },
    {
        "time": 1689752406000,
        "value": 139.393,
        "_internal_originalTime": 1689752406000
    },
    {
        "time": 1689752407000,
        "value": 139.393,
        "_internal_originalTime": 1689752407000
    },
    {
        "time": 1689752408000,
        "value": 139.397,
        "_internal_originalTime": 1689752408000
    },
    {
        "time": 1689752409000,
        "value": 139.395,
        "_internal_originalTime": 1689752409000
    },
    {
        "time": 1689752410000,
        "value": 139.397,
        "_internal_originalTime": 1689752410000
    },
    {
        "time": 1689752412000,
        "value": 139.395,
        "_internal_originalTime": 1689752412000
    },
    {
        "time": 1689752414000,
        "value": 139.401,
        "_internal_originalTime": 1689752414000
    },
    {
        "time": 1689752415000,
        "value": 139.396,
        "_internal_originalTime": 1689752415000
    },
    {
        "time": 1689752416000,
        "value": 139.4,
        "_internal_originalTime": 1689752416000
    },
    {
        "time": 1689752417000,
        "value": 139.394,
        "_internal_originalTime": 1689752417000
    },
    {
        "time": 1689752418000,
        "value": 139.395,
        "_internal_originalTime": 1689752418000
    },
    {
        "time": 1689752419000,
        "value": 139.399,
        "_internal_originalTime": 1689752419000
    },
    {
        "time": 1689752420000,
        "value": 139.397,
        "_internal_originalTime": 1689752420000
    },
    {
        "time": 1689752421000,
        "value": 139.395,
        "_internal_originalTime": 1689752421000
    },
    {
        "time": 1689752423000,
        "value": 139.391,
        "_internal_originalTime": 1689752423000
    },
    {
        "time": 1689752425000,
        "value": 139.391,
        "_internal_originalTime": 1689752425000
    },
    {
        "time": 1689752426000,
        "value": 139.386,
        "_internal_originalTime": 1689752426000
    },
    {
        "time": 1689752427000,
        "value": 139.387,
        "_internal_originalTime": 1689752427000
    },
    {
        "time": 1689752428000,
        "value": 139.39,
        "_internal_originalTime": 1689752428000
    },
    {
        "time": 1689752429000,
        "value": 139.386,
        "_internal_originalTime": 1689752429000
    },
    {
        "time": 1689752430000,
        "value": 139.383,
        "_internal_originalTime": 1689752430000
    },
    {
        "time": 1689752431000,
        "value": 139.388,
        "_internal_originalTime": 1689752431000
    },
    {
        "time": 1689752432000,
        "value": 139.387,
        "_internal_originalTime": 1689752432000
    },
    {
        "time": 1689752433000,
        "value": 139.388,
        "_internal_originalTime": 1689752433000
    },
    {
        "time": 1689752434000,
        "value": 139.386,
        "_internal_originalTime": 1689752434000
    },
    {
        "time": 1689752435000,
        "value": 139.386,
        "_internal_originalTime": 1689752435000
    },
    {
        "time": 1689752436000,
        "value": 139.387,
        "_internal_originalTime": 1689752436000
    },
    {
        "time": 1689752437000,
        "value": 139.383,
        "_internal_originalTime": 1689752437000
    },
    {
        "time": 1689752438000,
        "value": 139.378,
        "_internal_originalTime": 1689752438000
    },
    {
        "time": 1689752439000,
        "value": 139.38,
        "_internal_originalTime": 1689752439000
    },
    {
        "time": 1689752440000,
        "value": 139.378,
        "_internal_originalTime": 1689752440000
    },
    {
        "time": 1689752441000,
        "value": 139.38,
        "_internal_originalTime": 1689752441000
    },
    {
        "time": 1689752442000,
        "value": 139.389,
        "_internal_originalTime": 1689752442000
    },
    {
        "time": 1689752444000,
        "value": 139.393,
        "_internal_originalTime": 1689752444000
    },
    {
        "time": 1689752445000,
        "value": 139.391,
        "_internal_originalTime": 1689752445000
    },
    {
        "time": 1689752446000,
        "value": 139.391,
        "_internal_originalTime": 1689752446000
    },
    {
        "time": 1689752448000,
        "value": 139.388,
        "_internal_originalTime": 1689752448000
    },
    {
        "time": 1689752449000,
        "value": 139.392,
        "_internal_originalTime": 1689752449000
    },
    {
        "time": 1689752450000,
        "value": 139.393,
        "_internal_originalTime": 1689752450000
    },
    {
        "time": 1689752452000,
        "value": 139.393,
        "_internal_originalTime": 1689752452000
    },
    {
        "time": 1689752453000,
        "value": 139.396,
        "_internal_originalTime": 1689752453000
    },
    {
        "time": 1689752455000,
        "value": 139.399,
        "_internal_originalTime": 1689752455000
    },
    {
        "time": 1689752456000,
        "value": 139.403,
        "_internal_originalTime": 1689752456000
    },
    {
        "time": 1689752458000,
        "value": 139.402,
        "_internal_originalTime": 1689752458000
    },
    {
        "time": 1689752459000,
        "value": 139.402,
        "_internal_originalTime": 1689752459000
    },
    {
        "time": 1689752460000,
        "value": 139.406,
        "_internal_originalTime": 1689752460000
    },
    {
        "time": 1689752462000,
        "value": 139.399,
        "_internal_originalTime": 1689752462000
    },
    {
        "time": 1689752464000,
        "value": 139.401,
        "_internal_originalTime": 1689752464000
    },
    {
        "time": 1689752465000,
        "value": 139.4,
        "_internal_originalTime": 1689752465000
    },
    {
        "time": 1689752466000,
        "value": 139.402,
        "_internal_originalTime": 1689752466000
    },
    {
        "time": 1689752467000,
        "value": 139.4,
        "_internal_originalTime": 1689752467000
    },
    {
        "time": 1689752469000,
        "value": 139.402,
        "_internal_originalTime": 1689752469000
    },
    {
        "time": 1689752470000,
        "value": 139.399,
        "_internal_originalTime": 1689752470000
    },
    {
        "time": 1689752471000,
        "value": 139.402,
        "_internal_originalTime": 1689752471000
    },
    {
        "time": 1689752473000,
        "value": 139.403,
        "_internal_originalTime": 1689752473000
    },
    {
        "time": 1689752474000,
        "value": 139.414,
        "_internal_originalTime": 1689752474000
    },
    {
        "time": 1689752475000,
        "value": 139.41,
        "_internal_originalTime": 1689752475000
    },
    {
        "time": 1689752476000,
        "value": 139.411,
        "_internal_originalTime": 1689752476000
    },
    {
        "time": 1689752477000,
        "value": 139.415,
        "_internal_originalTime": 1689752477000
    },
    {
        "time": 1689752478000,
        "value": 139.423,
        "_internal_originalTime": 1689752478000
    },
    {
        "time": 1689752479000,
        "value": 139.42,
        "_internal_originalTime": 1689752479000
    },
    {
        "time": 1689752480000,
        "value": 139.42,
        "_internal_originalTime": 1689752480000
    },
    {
        "time": 1689752482000,
        "value": 139.422,
        "_internal_originalTime": 1689752482000
    },
    {
        "time": 1689752483000,
        "value": 139.418,
        "_internal_originalTime": 1689752483000
    },
    {
        "time": 1689752485000,
        "value": 139.413,
        "_internal_originalTime": 1689752485000
    },
    {
        "time": 1689752487000,
        "value": 139.406,
        "_internal_originalTime": 1689752487000
    },
    {
        "time": 1689752488000,
        "value": 139.402,
        "_internal_originalTime": 1689752488000
    },
    {
        "time": 1689752489000,
        "value": 139.396,
        "_internal_originalTime": 1689752489000
    },
    {
        "time": 1689752490000,
        "value": 139.397,
        "_internal_originalTime": 1689752490000
    },
    {
        "time": 1689752491000,
        "value": 139.389,
        "_internal_originalTime": 1689752491000
    },
    {
        "time": 1689752492000,
        "value": 139.389,
        "_internal_originalTime": 1689752492000
    },
    {
        "time": 1689752493000,
        "value": 139.387,
        "_internal_originalTime": 1689752493000
    },
    {
        "time": 1689752494000,
        "value": 139.386,
        "_internal_originalTime": 1689752494000
    },
    {
        "time": 1689752495000,
        "value": 139.384,
        "_internal_originalTime": 1689752495000
    },
    {
        "time": 1689752496000,
        "value": 139.382,
        "_internal_originalTime": 1689752496000
    },
    {
        "time": 1689752498000,
        "value": 139.382,
        "_internal_originalTime": 1689752498000
    },
    {
        "time": 1689752499000,
        "value": 139.385,
        "_internal_originalTime": 1689752499000
    },
    {
        "time": 1689752500000,
        "value": 139.381,
        "_internal_originalTime": 1689752500000
    },
    {
        "time": 1689752502000,
        "value": 139.379,
        "_internal_originalTime": 1689752502000
    },
    {
        "time": 1689752503000,
        "value": 139.378,
        "_internal_originalTime": 1689752503000
    },
    {
        "time": 1689752504000,
        "value": 139.379,
        "_internal_originalTime": 1689752504000
    },
    {
        "time": 1689752505000,
        "value": 139.379,
        "_internal_originalTime": 1689752505000
    },
    {
        "time": 1689752506000,
        "value": 139.378,
        "_internal_originalTime": 1689752506000
    },
    {
        "time": 1689752507000,
        "value": 139.377,
        "_internal_originalTime": 1689752507000
    },
    {
        "time": 1689752509000,
        "value": 139.376,
        "_internal_originalTime": 1689752509000
    },
    {
        "time": 1689752511000,
        "value": 139.373,
        "_internal_originalTime": 1689752511000
    },
    {
        "time": 1689752513000,
        "value": 139.38,
        "_internal_originalTime": 1689752513000
    },
    {
        "time": 1689752514000,
        "value": 139.373,
        "_internal_originalTime": 1689752514000
    },
    {
        "time": 1689752515000,
        "value": 139.372,
        "_internal_originalTime": 1689752515000
    },
    {
        "time": 1689752516000,
        "value": 139.371,
        "_internal_originalTime": 1689752516000
    },
    {
        "time": 1689752517000,
        "value": 139.371,
        "_internal_originalTime": 1689752517000
    },
    {
        "time": 1689752518000,
        "value": 139.371,
        "_internal_originalTime": 1689752518000
    },
    {
        "time": 1689752520000,
        "value": 139.368,
        "_internal_originalTime": 1689752520000
    },
    {
        "time": 1689752521000,
        "value": 139.366,
        "_internal_originalTime": 1689752521000
    },
    {
        "time": 1689752522000,
        "value": 139.37,
        "_internal_originalTime": 1689752522000
    },
    {
        "time": 1689752524000,
        "value": 139.372,
        "_internal_originalTime": 1689752524000
    },
    {
        "time": 1689752526000,
        "value": 139.367,
        "_internal_originalTime": 1689752526000
    },
    {
        "time": 1689752528000,
        "value": 139.367,
        "_internal_originalTime": 1689752528000
    },
    {
        "time": 1689752529000,
        "value": 139.369,
        "_internal_originalTime": 1689752529000
    },
    {
        "time": 1689752530000,
        "value": 139.357,
        "_internal_originalTime": 1689752530000
    },
    {
        "time": 1689752531000,
        "value": 139.361,
        "_internal_originalTime": 1689752531000
    },
    {
        "time": 1689752532000,
        "value": 139.361,
        "_internal_originalTime": 1689752532000
    },
    {
        "time": 1689752533000,
        "value": 139.358,
        "_internal_originalTime": 1689752533000
    },
    {
        "time": 1689752534000,
        "value": 139.347,
        "_internal_originalTime": 1689752534000
    },
    {
        "time": 1689752535000,
        "value": 139.347,
        "_internal_originalTime": 1689752535000
    },
    {
        "time": 1689752536000,
        "value": 139.346,
        "_internal_originalTime": 1689752536000
    },
    {
        "time": 1689752538000,
        "value": 139.341,
        "_internal_originalTime": 1689752538000
    },
    {
        "time": 1689752539000,
        "value": 139.339,
        "_internal_originalTime": 1689752539000
    },
    {
        "time": 1689752540000,
        "value": 139.341,
        "_internal_originalTime": 1689752540000
    },
    {
        "time": 1689752541000,
        "value": 139.338,
        "_internal_originalTime": 1689752541000
    },
    {
        "time": 1689752542000,
        "value": 139.35,
        "_internal_originalTime": 1689752542000
    },
    {
        "time": 1689752544000,
        "value": 139.347,
        "_internal_originalTime": 1689752544000
    },
    {
        "time": 1689752545000,
        "value": 139.349,
        "_internal_originalTime": 1689752545000
    },
    {
        "time": 1689752546000,
        "value": 139.344,
        "_internal_originalTime": 1689752546000
    },
    {
        "time": 1689752547000,
        "value": 139.347,
        "_internal_originalTime": 1689752547000
    },
    {
        "time": 1689752548000,
        "value": 139.349,
        "_internal_originalTime": 1689752548000
    },
    {
        "time": 1689752550000,
        "value": 139.345,
        "_internal_originalTime": 1689752550000
    },
    {
        "time": 1689752551000,
        "value": 139.346,
        "_internal_originalTime": 1689752551000
    },
    {
        "time": 1689752552000,
        "value": 139.348,
        "_internal_originalTime": 1689752552000
    },
    {
        "time": 1689752553000,
        "value": 139.349,
        "_internal_originalTime": 1689752553000
    },
    {
        "time": 1689752554000,
        "value": 139.353,
        "_internal_originalTime": 1689752554000
    },
    {
        "time": 1689752556000,
        "value": 139.353,
        "_internal_originalTime": 1689752556000
    },
    {
        "time": 1689752557000,
        "value": 139.352,
        "_internal_originalTime": 1689752557000
    },
    {
        "time": 1689752558000,
        "value": 139.357,
        "_internal_originalTime": 1689752558000
    },
    {
        "time": 1689752559000,
        "value": 139.357,
        "_internal_originalTime": 1689752559000
    },
    {
        "time": 1689752560000,
        "value": 139.354,
        "_internal_originalTime": 1689752560000
    },
    {
        "time": 1689752565000,
        "value": 139.358,
        "_internal_originalTime": 1689752565000
    },
    {
        "time": 1689752566000,
        "value": 139.354,
        "_internal_originalTime": 1689752566000
    },
    {
        "time": 1689752567000,
        "value": 139.353,
        "_internal_originalTime": 1689752567000
    },
    {
        "time": 1689752568000,
        "value": 139.357,
        "_internal_originalTime": 1689752568000
    },
    {
        "time": 1689752570000,
        "value": 139.357,
        "_internal_originalTime": 1689752570000
    },
    {
        "time": 1689752571000,
        "value": 139.354,
        "_internal_originalTime": 1689752571000
    },
    {
        "time": 1689752572000,
        "value": 139.355,
        "_internal_originalTime": 1689752572000
    },
    {
        "time": 1689752574000,
        "value": 139.358,
        "_internal_originalTime": 1689752574000
    },
    {
        "time": 1689752575000,
        "value": 139.358,
        "_internal_originalTime": 1689752575000
    },
    {
        "time": 1689752576000,
        "value": 139.362,
        "_internal_originalTime": 1689752576000
    },
    {
        "time": 1689752577000,
        "value": 139.362,
        "_internal_originalTime": 1689752577000
    },
    {
        "time": 1689752578000,
        "value": 139.361,
        "_internal_originalTime": 1689752578000
    },
    {
        "time": 1689752580000,
        "value": 139.361,
        "_internal_originalTime": 1689752580000
    },
    {
        "time": 1689752581000,
        "value": 139.361,
        "_internal_originalTime": 1689752581000
    },
    {
        "time": 1689752582000,
        "value": 139.356,
        "_internal_originalTime": 1689752582000
    },
    {
        "time": 1689752583000,
        "value": 139.358,
        "_internal_originalTime": 1689752583000
    },
    {
        "time": 1689752584000,
        "value": 139.362,
        "_internal_originalTime": 1689752584000
    },
    {
        "time": 1689752585000,
        "value": 139.361,
        "_internal_originalTime": 1689752585000
    },
    {
        "time": 1689752586000,
        "value": 139.357,
        "_internal_originalTime": 1689752586000
    },
    {
        "time": 1689752587000,
        "value": 139.357,
        "_internal_originalTime": 1689752587000
    },
    {
        "time": 1689752588000,
        "value": 139.361,
        "_internal_originalTime": 1689752588000
    },
    {
        "time": 1689752589000,
        "value": 139.364,
        "_internal_originalTime": 1689752589000
    },
    {
        "time": 1689752590000,
        "value": 139.349,
        "_internal_originalTime": 1689752590000
    },
    {
        "time": 1689752592000,
        "value": 139.338,
        "_internal_originalTime": 1689752592000
    },
    {
        "time": 1689752593000,
        "value": 139.348,
        "_internal_originalTime": 1689752593000
    },
    {
        "time": 1689752595000,
        "value": 139.348,
        "_internal_originalTime": 1689752595000
    },
    {
        "time": 1689752596000,
        "value": 139.352,
        "_internal_originalTime": 1689752596000
    },
    {
        "time": 1689752597000,
        "value": 139.352,
        "_internal_originalTime": 1689752597000
    },
    {
        "time": 1689752598000,
        "value": 139.358,
        "_internal_originalTime": 1689752598000
    },
    {
        "time": 1689752599000,
        "value": 139.348,
        "_internal_originalTime": 1689752599000
    },
    {
        "time": 1689752600000,
        "value": 139.348,
        "_internal_originalTime": 1689752600000
    },
    {
        "time": 1689752601000,
        "value": 139.351,
        "_internal_originalTime": 1689752601000
    },
    {
        "time": 1689752602000,
        "value": 139.346,
        "_internal_originalTime": 1689752602000
    },
    {
        "time": 1689752603000,
        "value": 139.349,
        "_internal_originalTime": 1689752603000
    },
    {
        "time": 1689752604000,
        "value": 139.351,
        "_internal_originalTime": 1689752604000
    },
    {
        "time": 1689752605000,
        "value": 139.342,
        "_internal_originalTime": 1689752605000
    },
    {
        "time": 1689752606000,
        "value": 139.343,
        "_internal_originalTime": 1689752606000
    },
    {
        "time": 1689752607000,
        "value": 139.345,
        "_internal_originalTime": 1689752607000
    },
    {
        "time": 1689752608000,
        "value": 139.344,
        "_internal_originalTime": 1689752608000
    },
    {
        "time": 1689752609000,
        "value": 139.345,
        "_internal_originalTime": 1689752609000
    },
    {
        "time": 1689752610000,
        "value": 139.344,
        "_internal_originalTime": 1689752610000
    },
    {
        "time": 1689752611000,
        "value": 139.346,
        "_internal_originalTime": 1689752611000
    },
    {
        "time": 1689752612000,
        "value": 139.344,
        "_internal_originalTime": 1689752612000
    },
    {
        "time": 1689752614000,
        "value": 139.346,
        "_internal_originalTime": 1689752614000
    },
    {
        "time": 1689752615000,
        "value": 139.347,
        "_internal_originalTime": 1689752615000
    },
    {
        "time": 1689752617000,
        "value": 139.342,
        "_internal_originalTime": 1689752617000
    },
    {
        "time": 1689752618000,
        "value": 139.342,
        "_internal_originalTime": 1689752618000
    },
    {
        "time": 1689752619000,
        "value": 139.346,
        "_internal_originalTime": 1689752619000
    },
    {
        "time": 1689752620000,
        "value": 139.348,
        "_internal_originalTime": 1689752620000
    },
    {
        "time": 1689752621000,
        "value": 139.345,
        "_internal_originalTime": 1689752621000
    },
    {
        "time": 1689752622000,
        "value": 139.347,
        "_internal_originalTime": 1689752622000
    },
    {
        "time": 1689752623000,
        "value": 139.354,
        "_internal_originalTime": 1689752623000
    },
    {
        "time": 1689752625000,
        "value": 139.354,
        "_internal_originalTime": 1689752625000
    },
    {
        "time": 1689752626000,
        "value": 139.352,
        "_internal_originalTime": 1689752626000
    },
    {
        "time": 1689752628000,
        "value": 139.349,
        "_internal_originalTime": 1689752628000
    },
    {
        "time": 1689752629000,
        "value": 139.361,
        "_internal_originalTime": 1689752629000
    },
    {
        "time": 1689752630000,
        "value": 139.359,
        "_internal_originalTime": 1689752630000
    },
    {
        "time": 1689752631000,
        "value": 139.358,
        "_internal_originalTime": 1689752631000
    },
    {
        "time": 1689752632000,
        "value": 139.359,
        "_internal_originalTime": 1689752632000
    },
    {
        "time": 1689752634000,
        "value": 139.357,
        "_internal_originalTime": 1689752634000
    },
    {
        "time": 1689752635000,
        "value": 139.358,
        "_internal_originalTime": 1689752635000
    },
    {
        "time": 1689752637000,
        "value": 139.358,
        "_internal_originalTime": 1689752637000
    },
    {
        "time": 1689752639000,
        "value": 139.369,
        "_internal_originalTime": 1689752639000
    },
    {
        "time": 1689752640000,
        "value": 139.379,
        "_internal_originalTime": 1689752640000
    },
    {
        "time": 1689752641000,
        "value": 139.381,
        "_internal_originalTime": 1689752641000
    },
    {
        "time": 1689752642000,
        "value": 139.386,
        "_internal_originalTime": 1689752642000
    },
    {
        "time": 1689752643000,
        "value": 139.385,
        "_internal_originalTime": 1689752643000
    },
    {
        "time": 1689752644000,
        "value": 139.39,
        "_internal_originalTime": 1689752644000
    },
    {
        "time": 1689752645000,
        "value": 139.378,
        "_internal_originalTime": 1689752645000
    },
    {
        "time": 1689752646000,
        "value": 139.376,
        "_internal_originalTime": 1689752646000
    },
    {
        "time": 1689752647000,
        "value": 139.379,
        "_internal_originalTime": 1689752647000
    },
    {
        "time": 1689752648000,
        "value": 139.38,
        "_internal_originalTime": 1689752648000
    },
    {
        "time": 1689752649000,
        "value": 139.38,
        "_internal_originalTime": 1689752649000
    },
    {
        "time": 1689752650000,
        "value": 139.371,
        "_internal_originalTime": 1689752650000
    },
    {
        "time": 1689752651000,
        "value": 139.368,
        "_internal_originalTime": 1689752651000
    },
    {
        "time": 1689752653000,
        "value": 139.368,
        "_internal_originalTime": 1689752653000
    },
    {
        "time": 1689752654000,
        "value": 139.367,
        "_internal_originalTime": 1689752654000
    },
    {
        "time": 1689752656000,
        "value": 139.373,
        "_internal_originalTime": 1689752656000
    },
    {
        "time": 1689752657000,
        "value": 139.368,
        "_internal_originalTime": 1689752657000
    },
    {
        "time": 1689752659000,
        "value": 139.375,
        "_internal_originalTime": 1689752659000
    },
    {
        "time": 1689752660000,
        "value": 139.378,
        "_internal_originalTime": 1689752660000
    },
    {
        "time": 1689752661000,
        "value": 139.372,
        "_internal_originalTime": 1689752661000
    },
    {
        "time": 1689752662000,
        "value": 139.375,
        "_internal_originalTime": 1689752662000
    },
    {
        "time": 1689752663000,
        "value": 139.373,
        "_internal_originalTime": 1689752663000
    },
    {
        "time": 1689752664000,
        "value": 139.375,
        "_internal_originalTime": 1689752664000
    },
    {
        "time": 1689752665000,
        "value": 139.377,
        "_internal_originalTime": 1689752665000
    },
    {
        "time": 1689752666000,
        "value": 139.376,
        "_internal_originalTime": 1689752666000
    },
    {
        "time": 1689752667000,
        "value": 139.378,
        "_internal_originalTime": 1689752667000
    },
    {
        "time": 1689752668000,
        "value": 139.372,
        "_internal_originalTime": 1689752668000
    },
    {
        "time": 1689752670000,
        "value": 139.374,
        "_internal_originalTime": 1689752670000
    },
    {
        "time": 1689752671000,
        "value": 139.379,
        "_internal_originalTime": 1689752671000
    },
    {
        "time": 1689752672000,
        "value": 139.38,
        "_internal_originalTime": 1689752672000
    },
    {
        "time": 1689752673000,
        "value": 139.376,
        "_internal_originalTime": 1689752673000
    },
    {
        "time": 1689752674000,
        "value": 139.378,
        "_internal_originalTime": 1689752674000
    },
    {
        "time": 1689752675000,
        "value": 139.379,
        "_internal_originalTime": 1689752675000
    },
    {
        "time": 1689752676000,
        "value": 139.376,
        "_internal_originalTime": 1689752676000
    },
    {
        "time": 1689752677000,
        "value": 139.376,
        "_internal_originalTime": 1689752677000
    },
    {
        "time": 1689752678000,
        "value": 139.377,
        "_internal_originalTime": 1689752678000
    },
    {
        "time": 1689752679000,
        "value": 139.378,
        "_internal_originalTime": 1689752679000
    },
    {
        "time": 1689752680000,
        "value": 139.376,
        "_internal_originalTime": 1689752680000
    },
    {
        "time": 1689752682000,
        "value": 139.377,
        "_internal_originalTime": 1689752682000
    },
    {
        "time": 1689752683000,
        "value": 139.377,
        "_internal_originalTime": 1689752683000
    },
    {
        "time": 1689752684000,
        "value": 139.375,
        "_internal_originalTime": 1689752684000
    },
    {
        "time": 1689752685000,
        "value": 139.374,
        "_internal_originalTime": 1689752685000
    },
    {
        "time": 1689752686000,
        "value": 139.374,
        "_internal_originalTime": 1689752686000
    },
    {
        "time": 1689752687000,
        "value": 139.374,
        "_internal_originalTime": 1689752687000
    },
    {
        "time": 1689752688000,
        "value": 139.375,
        "_internal_originalTime": 1689752688000
    },
    {
        "time": 1689752689000,
        "value": 139.369,
        "_internal_originalTime": 1689752689000
    },
    {
        "time": 1689752691000,
        "value": 139.372,
        "_internal_originalTime": 1689752691000
    },
    {
        "time": 1689752692000,
        "value": 139.366,
        "_internal_originalTime": 1689752692000
    },
    {
        "time": 1689752693000,
        "value": 139.37,
        "_internal_originalTime": 1689752693000
    },
    {
        "time": 1689752694000,
        "value": 139.375,
        "_internal_originalTime": 1689752694000
    },
    {
        "time": 1689752695000,
        "value": 139.374,
        "_internal_originalTime": 1689752695000
    },
    {
        "time": 1689752696000,
        "value": 139.375,
        "_internal_originalTime": 1689752696000
    },
    {
        "time": 1689752697000,
        "value": 139.376,
        "_internal_originalTime": 1689752697000
    },
    {
        "time": 1689752698000,
        "value": 139.374,
        "_internal_originalTime": 1689752698000
    },
    {
        "time": 1689752700000,
        "value": 139.367,
        "_internal_originalTime": 1689752700000
    },
    {
        "time": 1689752701000,
        "value": 139.37,
        "_internal_originalTime": 1689752701000
    },
    {
        "time": 1689752702000,
        "value": 139.367,
        "_internal_originalTime": 1689752702000
    },
    {
        "time": 1689752703000,
        "value": 139.368,
        "_internal_originalTime": 1689752703000
    },
    {
        "time": 1689752704000,
        "value": 139.364,
        "_internal_originalTime": 1689752704000
    },
    {
        "time": 1689752706000,
        "value": 139.366,
        "_internal_originalTime": 1689752706000
    },
    {
        "time": 1689752707000,
        "value": 139.356,
        "_internal_originalTime": 1689752707000
    },
    {
        "time": 1689752708000,
        "value": 139.357,
        "_internal_originalTime": 1689752708000
    },
    {
        "time": 1689752709000,
        "value": 139.361,
        "_internal_originalTime": 1689752709000
    },
    {
        "time": 1689752710000,
        "value": 139.363,
        "_internal_originalTime": 1689752710000
    },
    {
        "time": 1689752711000,
        "value": 139.367,
        "_internal_originalTime": 1689752711000
    },
    {
        "time": 1689752712000,
        "value": 139.367,
        "_internal_originalTime": 1689752712000
    },
    {
        "time": 1689752713000,
        "value": 139.367,
        "_internal_originalTime": 1689752713000
    },
    {
        "time": 1689752715000,
        "value": 139.368,
        "_internal_originalTime": 1689752715000
    },
    {
        "time": 1689752716000,
        "value": 139.368,
        "_internal_originalTime": 1689752716000
    },
    {
        "time": 1689752717000,
        "value": 139.362,
        "_internal_originalTime": 1689752717000
    },
    {
        "time": 1689752718000,
        "value": 139.379,
        "_internal_originalTime": 1689752718000
    },
    {
        "time": 1689752719000,
        "value": 139.376,
        "_internal_originalTime": 1689752719000
    },
    {
        "time": 1689752720000,
        "value": 139.376,
        "_internal_originalTime": 1689752720000
    },
    {
        "time": 1689752721000,
        "value": 139.379,
        "_internal_originalTime": 1689752721000
    },
    {
        "time": 1689752723000,
        "value": 139.378,
        "_internal_originalTime": 1689752723000
    },
    {
        "time": 1689752724000,
        "value": 139.379,
        "_internal_originalTime": 1689752724000
    },
    {
        "time": 1689752726000,
        "value": 139.377,
        "_internal_originalTime": 1689752726000
    },
    {
        "time": 1689752727000,
        "value": 139.381,
        "_internal_originalTime": 1689752727000
    },
    {
        "time": 1689752728000,
        "value": 139.381,
        "_internal_originalTime": 1689752728000
    },
    {
        "time": 1689752729000,
        "value": 139.378,
        "_internal_originalTime": 1689752729000
    },
    {
        "time": 1689752730000,
        "value": 139.377,
        "_internal_originalTime": 1689752730000
    },
    {
        "time": 1689752731000,
        "value": 139.377,
        "_internal_originalTime": 1689752731000
    },
    {
        "time": 1689752732000,
        "value": 139.382,
        "_internal_originalTime": 1689752732000
    },
    {
        "time": 1689752733000,
        "value": 139.374,
        "_internal_originalTime": 1689752733000
    },
    {
        "time": 1689752734000,
        "value": 139.38,
        "_internal_originalTime": 1689752734000
    },
    {
        "time": 1689752735000,
        "value": 139.376,
        "_internal_originalTime": 1689752735000
    },
    {
        "time": 1689752736000,
        "value": 139.372,
        "_internal_originalTime": 1689752736000
    },
    {
        "time": 1689752738000,
        "value": 139.367,
        "_internal_originalTime": 1689752738000
    },
    {
        "time": 1689752739000,
        "value": 139.361,
        "_internal_originalTime": 1689752739000
    },
    {
        "time": 1689752740000,
        "value": 139.364,
        "_internal_originalTime": 1689752740000
    },
    {
        "time": 1689752741000,
        "value": 139.364,
        "_internal_originalTime": 1689752741000
    },
    {
        "time": 1689752742000,
        "value": 139.359,
        "_internal_originalTime": 1689752742000
    },
    {
        "time": 1689752743000,
        "value": 139.357,
        "_internal_originalTime": 1689752743000
    },
    {
        "time": 1689752745000,
        "value": 139.357,
        "_internal_originalTime": 1689752745000
    },
    {
        "time": 1689752746000,
        "value": 139.36,
        "_internal_originalTime": 1689752746000
    },
    {
        "time": 1689752747000,
        "value": 139.365,
        "_internal_originalTime": 1689752747000
    },
    {
        "time": 1689752749000,
        "value": 139.364,
        "_internal_originalTime": 1689752749000
    },
    {
        "time": 1689752750000,
        "value": 139.364,
        "_internal_originalTime": 1689752750000
    },
    {
        "time": 1689752751000,
        "value": 139.365,
        "_internal_originalTime": 1689752751000
    },
    {
        "time": 1689752752000,
        "value": 139.362,
        "_internal_originalTime": 1689752752000
    },
    {
        "time": 1689752754000,
        "value": 139.361,
        "_internal_originalTime": 1689752754000
    },
    {
        "time": 1689752755000,
        "value": 139.354,
        "_internal_originalTime": 1689752755000
    },
    {
        "time": 1689752756000,
        "value": 139.358,
        "_internal_originalTime": 1689752756000
    },
    {
        "time": 1689752757000,
        "value": 139.352,
        "_internal_originalTime": 1689752757000
    },
    {
        "time": 1689752759000,
        "value": 139.352,
        "_internal_originalTime": 1689752759000
    },
    {
        "time": 1689752760000,
        "value": 139.356,
        "_internal_originalTime": 1689752760000
    },
    {
        "time": 1689752761000,
        "value": 139.358,
        "_internal_originalTime": 1689752761000
    },
    {
        "time": 1689752762000,
        "value": 139.367,
        "_internal_originalTime": 1689752762000
    },
    {
        "time": 1689752763000,
        "value": 139.365,
        "_internal_originalTime": 1689752763000
    },
    {
        "time": 1689752764000,
        "value": 139.362,
        "_internal_originalTime": 1689752764000
    },
    {
        "time": 1689752765000,
        "value": 139.362,
        "_internal_originalTime": 1689752765000
    },
    {
        "time": 1689752766000,
        "value": 139.365,
        "_internal_originalTime": 1689752766000
    },
    {
        "time": 1689752767000,
        "value": 139.36,
        "_internal_originalTime": 1689752767000
    },
    {
        "time": 1689752768000,
        "value": 139.367,
        "_internal_originalTime": 1689752768000
    },
    {
        "time": 1689752770000,
        "value": 139.365,
        "_internal_originalTime": 1689752770000
    },
    {
        "time": 1689752771000,
        "value": 139.363,
        "_internal_originalTime": 1689752771000
    },
    {
        "time": 1689752772000,
        "value": 139.362,
        "_internal_originalTime": 1689752772000
    },
    {
        "time": 1689752773000,
        "value": 139.366,
        "_internal_originalTime": 1689752773000
    },
    {
        "time": 1689752775000,
        "value": 139.375,
        "_internal_originalTime": 1689752775000
    },
    {
        "time": 1689752777000,
        "value": 139.377,
        "_internal_originalTime": 1689752777000
    },
    {
        "time": 1689752778000,
        "value": 139.378,
        "_internal_originalTime": 1689752778000
    },
    {
        "time": 1689752780000,
        "value": 139.376,
        "_internal_originalTime": 1689752780000
    },
    {
        "time": 1689752781000,
        "value": 139.378,
        "_internal_originalTime": 1689752781000
    },
    {
        "time": 1689752783000,
        "value": 139.376,
        "_internal_originalTime": 1689752783000
    },
    {
        "time": 1689752784000,
        "value": 139.379,
        "_internal_originalTime": 1689752784000
    },
    {
        "time": 1689752785000,
        "value": 139.382,
        "_internal_originalTime": 1689752785000
    },
    {
        "time": 1689752787000,
        "value": 139.383,
        "_internal_originalTime": 1689752787000
    },
    {
        "time": 1689752788000,
        "value": 139.382,
        "_internal_originalTime": 1689752788000
    },
    {
        "time": 1689752789000,
        "value": 139.382,
        "_internal_originalTime": 1689752789000
    },
    {
        "time": 1689752790000,
        "value": 139.377,
        "_internal_originalTime": 1689752790000
    },
    {
        "time": 1689752791000,
        "value": 139.377,
        "_internal_originalTime": 1689752791000
    },
    {
        "time": 1689752792000,
        "value": 139.376,
        "_internal_originalTime": 1689752792000
    },
    {
        "time": 1689752793000,
        "value": 139.379,
        "_internal_originalTime": 1689752793000
    },
    {
        "time": 1689752794000,
        "value": 139.38,
        "_internal_originalTime": 1689752794000
    },
    {
        "time": 1689752796000,
        "value": 139.382,
        "_internal_originalTime": 1689752796000
    },
    {
        "time": 1689752797000,
        "value": 139.381,
        "_internal_originalTime": 1689752797000
    },
    {
        "time": 1689752798000,
        "value": 139.37,
        "_internal_originalTime": 1689752798000
    },
    {
        "time": 1689752799000,
        "value": 139.371,
        "_internal_originalTime": 1689752799000
    },
    {
        "time": 1689752800000,
        "value": 139.374,
        "_internal_originalTime": 1689752800000
    },
    {
        "time": 1689752801000,
        "value": 139.378,
        "_internal_originalTime": 1689752801000
    },
    {
        "time": 1689752802000,
        "value": 139.374,
        "_internal_originalTime": 1689752802000
    },
    {
        "time": 1689752804000,
        "value": 139.379,
        "_internal_originalTime": 1689752804000
    },
    {
        "time": 1689752805000,
        "value": 139.377,
        "_internal_originalTime": 1689752805000
    },
    {
        "time": 1689752806000,
        "value": 139.375,
        "_internal_originalTime": 1689752806000
    },
    {
        "time": 1689752807000,
        "value": 139.377,
        "_internal_originalTime": 1689752807000
    },
    {
        "time": 1689752808000,
        "value": 139.375,
        "_internal_originalTime": 1689752808000
    },
    {
        "time": 1689752809000,
        "value": 139.375,
        "_internal_originalTime": 1689752809000
    },
    {
        "time": 1689752810000,
        "value": 139.375,
        "_internal_originalTime": 1689752810000
    },
    {
        "time": 1689752812000,
        "value": 139.377,
        "_internal_originalTime": 1689752812000
    },
    {
        "time": 1689752813000,
        "value": 139.381,
        "_internal_originalTime": 1689752813000
    },
    {
        "time": 1689752815000,
        "value": 139.38,
        "_internal_originalTime": 1689752815000
    },
    {
        "time": 1689752816000,
        "value": 139.379,
        "_internal_originalTime": 1689752816000
    },
    {
        "time": 1689752818000,
        "value": 139.38,
        "_internal_originalTime": 1689752818000
    },
    {
        "time": 1689752819000,
        "value": 139.38,
        "_internal_originalTime": 1689752819000
    },
    {
        "time": 1689752820000,
        "value": 139.382,
        "_internal_originalTime": 1689752820000
    },
    {
        "time": 1689752821000,
        "value": 139.384,
        "_internal_originalTime": 1689752821000
    },
    {
        "time": 1689752822000,
        "value": 139.384,
        "_internal_originalTime": 1689752822000
    },
    {
        "time": 1689752823000,
        "value": 139.385,
        "_internal_originalTime": 1689752823000
    },
    {
        "time": 1689752825000,
        "value": 139.386,
        "_internal_originalTime": 1689752825000
    },
    {
        "time": 1689752826000,
        "value": 139.384,
        "_internal_originalTime": 1689752826000
    },
    {
        "time": 1689752828000,
        "value": 139.386,
        "_internal_originalTime": 1689752828000
    },
    {
        "time": 1689752829000,
        "value": 139.395,
        "_internal_originalTime": 1689752829000
    },
    {
        "time": 1689752830000,
        "value": 139.393,
        "_internal_originalTime": 1689752830000
    },
    {
        "time": 1689752831000,
        "value": 139.396,
        "_internal_originalTime": 1689752831000
    },
    {
        "time": 1689752833000,
        "value": 139.394,
        "_internal_originalTime": 1689752833000
    },
    {
        "time": 1689752836000,
        "value": 139.399,
        "_internal_originalTime": 1689752836000
    },
    {
        "time": 1689752837000,
        "value": 139.405,
        "_internal_originalTime": 1689752837000
    },
    {
        "time": 1689752839000,
        "value": 139.405,
        "_internal_originalTime": 1689752839000
    },
    {
        "time": 1689752840000,
        "value": 139.406,
        "_internal_originalTime": 1689752840000
    },
    {
        "time": 1689752841000,
        "value": 139.406,
        "_internal_originalTime": 1689752841000
    },
    {
        "time": 1689752842000,
        "value": 139.406,
        "_internal_originalTime": 1689752842000
    },
    {
        "time": 1689752843000,
        "value": 139.403,
        "_internal_originalTime": 1689752843000
    },
    {
        "time": 1689752845000,
        "value": 139.406,
        "_internal_originalTime": 1689752845000
    },
    {
        "time": 1689752846000,
        "value": 139.418,
        "_internal_originalTime": 1689752846000
    },
    {
        "time": 1689752847000,
        "value": 139.415,
        "_internal_originalTime": 1689752847000
    },
    {
        "time": 1689752848000,
        "value": 139.414,
        "_internal_originalTime": 1689752848000
    },
    {
        "time": 1689752850000,
        "value": 139.419,
        "_internal_originalTime": 1689752850000
    },
    {
        "time": 1689752851000,
        "value": 139.422,
        "_internal_originalTime": 1689752851000
    },
    {
        "time": 1689752853000,
        "value": 139.422,
        "_internal_originalTime": 1689752853000
    },
    {
        "time": 1689752854000,
        "value": 139.435,
        "_internal_originalTime": 1689752854000
    },
    {
        "time": 1689752855000,
        "value": 139.434,
        "_internal_originalTime": 1689752855000
    },
    {
        "time": 1689752856000,
        "value": 139.422,
        "_internal_originalTime": 1689752856000
    },
    {
        "time": 1689752858000,
        "value": 139.424,
        "_internal_originalTime": 1689752858000
    },
    {
        "time": 1689752859000,
        "value": 139.424,
        "_internal_originalTime": 1689752859000
    },
    {
        "time": 1689752860000,
        "value": 139.415,
        "_internal_originalTime": 1689752860000
    },
    {
        "time": 1689752861000,
        "value": 139.413,
        "_internal_originalTime": 1689752861000
    },
    {
        "time": 1689752862000,
        "value": 139.417,
        "_internal_originalTime": 1689752862000
    },
    {
        "time": 1689752863000,
        "value": 139.42,
        "_internal_originalTime": 1689752863000
    },
    {
        "time": 1689752865000,
        "value": 139.42,
        "_internal_originalTime": 1689752865000
    },
    {
        "time": 1689752866000,
        "value": 139.419,
        "_internal_originalTime": 1689752866000
    },
    {
        "time": 1689752867000,
        "value": 139.416,
        "_internal_originalTime": 1689752867000
    },
    {
        "time": 1689752868000,
        "value": 139.414,
        "_internal_originalTime": 1689752868000
    },
    {
        "time": 1689752870000,
        "value": 139.412,
        "_internal_originalTime": 1689752870000
    },
    {
        "time": 1689752871000,
        "value": 139.413,
        "_internal_originalTime": 1689752871000
    },
    {
        "time": 1689752872000,
        "value": 139.412,
        "_internal_originalTime": 1689752872000
    },
    {
        "time": 1689752874000,
        "value": 139.416,
        "_internal_originalTime": 1689752874000
    },
    {
        "time": 1689752876000,
        "value": 139.41,
        "_internal_originalTime": 1689752876000
    },
    {
        "time": 1689752877000,
        "value": 139.41,
        "_internal_originalTime": 1689752877000
    },
    {
        "time": 1689752878000,
        "value": 139.414,
        "_internal_originalTime": 1689752878000
    },
    {
        "time": 1689752880000,
        "value": 139.412,
        "_internal_originalTime": 1689752880000
    },
    {
        "time": 1689752882000,
        "value": 139.41,
        "_internal_originalTime": 1689752882000
    },
    {
        "time": 1689752883000,
        "value": 139.414,
        "_internal_originalTime": 1689752883000
    },
    {
        "time": 1689752884000,
        "value": 139.414,
        "_internal_originalTime": 1689752884000
    },
    {
        "time": 1689752885000,
        "value": 139.415,
        "_internal_originalTime": 1689752885000
    },
    {
        "time": 1689752887000,
        "value": 139.414,
        "_internal_originalTime": 1689752887000
    },
    {
        "time": 1689752888000,
        "value": 139.415,
        "_internal_originalTime": 1689752888000
    },
    {
        "time": 1689752889000,
        "value": 139.406,
        "_internal_originalTime": 1689752889000
    },
    {
        "time": 1689752890000,
        "value": 139.411,
        "_internal_originalTime": 1689752890000
    },
    {
        "time": 1689752892000,
        "value": 139.411,
        "_internal_originalTime": 1689752892000
    },
    {
        "time": 1689752893000,
        "value": 139.399,
        "_internal_originalTime": 1689752893000
    },
    {
        "time": 1689752894000,
        "value": 139.401,
        "_internal_originalTime": 1689752894000
    },
    {
        "time": 1689752895000,
        "value": 139.399,
        "_internal_originalTime": 1689752895000
    },
    {
        "time": 1689752896000,
        "value": 139.402,
        "_internal_originalTime": 1689752896000
    },
    {
        "time": 1689752897000,
        "value": 139.402,
        "_internal_originalTime": 1689752897000
    },
    {
        "time": 1689752898000,
        "value": 139.4,
        "_internal_originalTime": 1689752898000
    },
    {
        "time": 1689752899000,
        "value": 139.396,
        "_internal_originalTime": 1689752899000
    },
    {
        "time": 1689752900000,
        "value": 139.392,
        "_internal_originalTime": 1689752900000
    },
    {
        "time": 1689752901000,
        "value": 139.393,
        "_internal_originalTime": 1689752901000
    },
    {
        "time": 1689752902000,
        "value": 139.399,
        "_internal_originalTime": 1689752902000
    },
    {
        "time": 1689752903000,
        "value": 139.395,
        "_internal_originalTime": 1689752903000
    },
    {
        "time": 1689752904000,
        "value": 139.398,
        "_internal_originalTime": 1689752904000
    },
    {
        "time": 1689752906000,
        "value": 139.397,
        "_internal_originalTime": 1689752906000
    },
    {
        "time": 1689752907000,
        "value": 139.4,
        "_internal_originalTime": 1689752907000
    },
    {
        "time": 1689752908000,
        "value": 139.4,
        "_internal_originalTime": 1689752908000
    },
    {
        "time": 1689752909000,
        "value": 139.4,
        "_internal_originalTime": 1689752909000
    },
    {
        "time": 1689752910000,
        "value": 139.4,
        "_internal_originalTime": 1689752910000
    },
    {
        "time": 1689752911000,
        "value": 139.399,
        "_internal_originalTime": 1689752911000
    },
    {
        "time": 1689752912000,
        "value": 139.401,
        "_internal_originalTime": 1689752912000
    },
    {
        "time": 1689752913000,
        "value": 139.4,
        "_internal_originalTime": 1689752913000
    },
    {
        "time": 1689752915000,
        "value": 139.398,
        "_internal_originalTime": 1689752915000
    },
    {
        "time": 1689752916000,
        "value": 139.408,
        "_internal_originalTime": 1689752916000
    },
    {
        "time": 1689752917000,
        "value": 139.409,
        "_internal_originalTime": 1689752917000
    },
    {
        "time": 1689752919000,
        "value": 139.406,
        "_internal_originalTime": 1689752919000
    },
    {
        "time": 1689752921000,
        "value": 139.403,
        "_internal_originalTime": 1689752921000
    },
    {
        "time": 1689752922000,
        "value": 139.398,
        "_internal_originalTime": 1689752922000
    },
    {
        "time": 1689752923000,
        "value": 139.403,
        "_internal_originalTime": 1689752923000
    },
    {
        "time": 1689752924000,
        "value": 139.397,
        "_internal_originalTime": 1689752924000
    },
    {
        "time": 1689752925000,
        "value": 139.399,
        "_internal_originalTime": 1689752925000
    },
    {
        "time": 1689752927000,
        "value": 139.398,
        "_internal_originalTime": 1689752927000
    },
    {
        "time": 1689752929000,
        "value": 139.398,
        "_internal_originalTime": 1689752929000
    },
    {
        "time": 1689752930000,
        "value": 139.397,
        "_internal_originalTime": 1689752930000
    },
    {
        "time": 1689752931000,
        "value": 139.4,
        "_internal_originalTime": 1689752931000
    },
    {
        "time": 1689752932000,
        "value": 139.395,
        "_internal_originalTime": 1689752932000
    },
    {
        "time": 1689752934000,
        "value": 139.394,
        "_internal_originalTime": 1689752934000
    },
    {
        "time": 1689752935000,
        "value": 139.396,
        "_internal_originalTime": 1689752935000
    },
    {
        "time": 1689752936000,
        "value": 139.399,
        "_internal_originalTime": 1689752936000
    },
    {
        "time": 1689752937000,
        "value": 139.4,
        "_internal_originalTime": 1689752937000
    },
    {
        "time": 1689752938000,
        "value": 139.4,
        "_internal_originalTime": 1689752938000
    },
    {
        "time": 1689752939000,
        "value": 139.404,
        "_internal_originalTime": 1689752939000
    },
    {
        "time": 1689752940000,
        "value": 139.405,
        "_internal_originalTime": 1689752940000
    },
    {
        "time": 1689752941000,
        "value": 139.406,
        "_internal_originalTime": 1689752941000
    },
    {
        "time": 1689752942000,
        "value": 139.405,
        "_internal_originalTime": 1689752942000
    },
    {
        "time": 1689752943000,
        "value": 139.411,
        "_internal_originalTime": 1689752943000
    },
    {
        "time": 1689752944000,
        "value": 139.414,
        "_internal_originalTime": 1689752944000
    },
    {
        "time": 1689752945000,
        "value": 139.415,
        "_internal_originalTime": 1689752945000
    },
    {
        "time": 1689752946000,
        "value": 139.418,
        "_internal_originalTime": 1689752946000
    },
    {
        "time": 1689752948000,
        "value": 139.413,
        "_internal_originalTime": 1689752948000
    },
    {
        "time": 1689752949000,
        "value": 139.408,
        "_internal_originalTime": 1689752949000
    },
    {
        "time": 1689752950000,
        "value": 139.411,
        "_internal_originalTime": 1689752950000
    },
    {
        "time": 1689752951000,
        "value": 139.409,
        "_internal_originalTime": 1689752951000
    },
    {
        "time": 1689752952000,
        "value": 139.406,
        "_internal_originalTime": 1689752952000
    },
    {
        "time": 1689752953000,
        "value": 139.407,
        "_internal_originalTime": 1689752953000
    },
    {
        "time": 1689752954000,
        "value": 139.41,
        "_internal_originalTime": 1689752954000
    },
    {
        "time": 1689752956000,
        "value": 139.41,
        "_internal_originalTime": 1689752956000
    },
    {
        "time": 1689752957000,
        "value": 139.414,
        "_internal_originalTime": 1689752957000
    },
    {
        "time": 1689752959000,
        "value": 139.415,
        "_internal_originalTime": 1689752959000
    },
    {
        "time": 1689752961000,
        "value": 139.416,
        "_internal_originalTime": 1689752961000
    },
    {
        "time": 1689752962000,
        "value": 139.411,
        "_internal_originalTime": 1689752962000
    },
    {
        "time": 1689752963000,
        "value": 139.416,
        "_internal_originalTime": 1689752963000
    },
    {
        "time": 1689752964000,
        "value": 139.418,
        "_internal_originalTime": 1689752964000
    },
    {
        "time": 1689752965000,
        "value": 139.418,
        "_internal_originalTime": 1689752965000
    },
    {
        "time": 1689752966000,
        "value": 139.424,
        "_internal_originalTime": 1689752966000
    },
    {
        "time": 1689752967000,
        "value": 139.427,
        "_internal_originalTime": 1689752967000
    },
    {
        "time": 1689752968000,
        "value": 139.424,
        "_internal_originalTime": 1689752968000
    },
    {
        "time": 1689752970000,
        "value": 139.424,
        "_internal_originalTime": 1689752970000
    },
    {
        "time": 1689752971000,
        "value": 139.428,
        "_internal_originalTime": 1689752971000
    },
    {
        "time": 1689752973000,
        "value": 139.425,
        "_internal_originalTime": 1689752973000
    },
    {
        "time": 1689752974000,
        "value": 139.43,
        "_internal_originalTime": 1689752974000
    },
    {
        "time": 1689752976000,
        "value": 139.434,
        "_internal_originalTime": 1689752976000
    },
    {
        "time": 1689752977000,
        "value": 139.426,
        "_internal_originalTime": 1689752977000
    },
    {
        "time": 1689752978000,
        "value": 139.427,
        "_internal_originalTime": 1689752978000
    },
    {
        "time": 1689752980000,
        "value": 139.427,
        "_internal_originalTime": 1689752980000
    },
    {
        "time": 1689752981000,
        "value": 139.427,
        "_internal_originalTime": 1689752981000
    },
    {
        "time": 1689752982000,
        "value": 139.427,
        "_internal_originalTime": 1689752982000
    },
    {
        "time": 1689752983000,
        "value": 139.423,
        "_internal_originalTime": 1689752983000
    },
    {
        "time": 1689752985000,
        "value": 139.427,
        "_internal_originalTime": 1689752985000
    },
    {
        "time": 1689752986000,
        "value": 139.428,
        "_internal_originalTime": 1689752986000
    },
    {
        "time": 1689752987000,
        "value": 139.428,
        "_internal_originalTime": 1689752987000
    },
    {
        "time": 1689752989000,
        "value": 139.429,
        "_internal_originalTime": 1689752989000
    },
    {
        "time": 1689752990000,
        "value": 139.434,
        "_internal_originalTime": 1689752990000
    },
    {
        "time": 1689752991000,
        "value": 139.427,
        "_internal_originalTime": 1689752991000
    },
    {
        "time": 1689752992000,
        "value": 139.423,
        "_internal_originalTime": 1689752992000
    },
    {
        "time": 1689752993000,
        "value": 139.425,
        "_internal_originalTime": 1689752993000
    },
    {
        "time": 1689752994000,
        "value": 139.425,
        "_internal_originalTime": 1689752994000
    },
    {
        "time": 1689752995000,
        "value": 139.427,
        "_internal_originalTime": 1689752995000
    },
    {
        "time": 1689752997000,
        "value": 139.426,
        "_internal_originalTime": 1689752997000
    },
    {
        "time": 1689752998000,
        "value": 139.431,
        "_internal_originalTime": 1689752998000
    },
    {
        "time": 1689752999000,
        "value": 139.432,
        "_internal_originalTime": 1689752999000
    },
    {
        "time": 1689753000000,
        "value": 139.433,
        "_internal_originalTime": 1689753000000
    },
    {
        "time": 1689753001000,
        "value": 139.434,
        "_internal_originalTime": 1689753001000
    },
    {
        "time": 1689753002000,
        "value": 139.437,
        "_internal_originalTime": 1689753002000
    },
    {
        "time": 1689753003000,
        "value": 139.425,
        "_internal_originalTime": 1689753003000
    },
    {
        "time": 1689753004000,
        "value": 139.421,
        "_internal_originalTime": 1689753004000
    },
    {
        "time": 1689753005000,
        "value": 139.425,
        "_internal_originalTime": 1689753005000
    },
    {
        "time": 1689753007000,
        "value": 139.423,
        "_internal_originalTime": 1689753007000
    },
    {
        "time": 1689753009000,
        "value": 139.417,
        "_internal_originalTime": 1689753009000
    },
    {
        "time": 1689753010000,
        "value": 139.421,
        "_internal_originalTime": 1689753010000
    },
    {
        "time": 1689753011000,
        "value": 139.423,
        "_internal_originalTime": 1689753011000
    },
    {
        "time": 1689753012000,
        "value": 139.42,
        "_internal_originalTime": 1689753012000
    },
    {
        "time": 1689753013000,
        "value": 139.425,
        "_internal_originalTime": 1689753013000
    },
    {
        "time": 1689753014000,
        "value": 139.425,
        "_internal_originalTime": 1689753014000
    },
    {
        "time": 1689753015000,
        "value": 139.425,
        "_internal_originalTime": 1689753015000
    },
    {
        "time": 1689753017000,
        "value": 139.423,
        "_internal_originalTime": 1689753017000
    },
    {
        "time": 1689753018000,
        "value": 139.424,
        "_internal_originalTime": 1689753018000
    },
    {
        "time": 1689753020000,
        "value": 139.422,
        "_internal_originalTime": 1689753020000
    },
    {
        "time": 1689753021000,
        "value": 139.423,
        "_internal_originalTime": 1689753021000
    },
    {
        "time": 1689753022000,
        "value": 139.42,
        "_internal_originalTime": 1689753022000
    },
    {
        "time": 1689753023000,
        "value": 139.426,
        "_internal_originalTime": 1689753023000
    },
    {
        "time": 1689753024000,
        "value": 139.422,
        "_internal_originalTime": 1689753024000
    },
    {
        "time": 1689753025000,
        "value": 139.426,
        "_internal_originalTime": 1689753025000
    },
    {
        "time": 1689753026000,
        "value": 139.426,
        "_internal_originalTime": 1689753026000
    },
    {
        "time": 1689753027000,
        "value": 139.427,
        "_internal_originalTime": 1689753027000
    },
    {
        "time": 1689753029000,
        "value": 139.425,
        "_internal_originalTime": 1689753029000
    },
    {
        "time": 1689753030000,
        "value": 139.422,
        "_internal_originalTime": 1689753030000
    },
    {
        "time": 1689753031000,
        "value": 139.427,
        "_internal_originalTime": 1689753031000
    },
    {
        "time": 1689753032000,
        "value": 139.432,
        "_internal_originalTime": 1689753032000
    },
    {
        "time": 1689753034000,
        "value": 139.435,
        "_internal_originalTime": 1689753034000
    },
    {
        "time": 1689753035000,
        "value": 139.434,
        "_internal_originalTime": 1689753035000
    },
    {
        "time": 1689753036000,
        "value": 139.435,
        "_internal_originalTime": 1689753036000
    },
    {
        "time": 1689753038000,
        "value": 139.439,
        "_internal_originalTime": 1689753038000
    },
    {
        "time": 1689753039000,
        "value": 139.434,
        "_internal_originalTime": 1689753039000
    },
    {
        "time": 1689753040000,
        "value": 139.434,
        "_internal_originalTime": 1689753040000
    },
    {
        "time": 1689753041000,
        "value": 139.439,
        "_internal_originalTime": 1689753041000
    },
    {
        "time": 1689753043000,
        "value": 139.435,
        "_internal_originalTime": 1689753043000
    },
    {
        "time": 1689753044000,
        "value": 139.435,
        "_internal_originalTime": 1689753044000
    },
    {
        "time": 1689753045000,
        "value": 139.433,
        "_internal_originalTime": 1689753045000
    },
    {
        "time": 1689753047000,
        "value": 139.425,
        "_internal_originalTime": 1689753047000
    },
    {
        "time": 1689753048000,
        "value": 139.424,
        "_internal_originalTime": 1689753048000
    },
    {
        "time": 1689753050000,
        "value": 139.428,
        "_internal_originalTime": 1689753050000
    },
    {
        "time": 1689753051000,
        "value": 139.427,
        "_internal_originalTime": 1689753051000
    },
    {
        "time": 1689753052000,
        "value": 139.425,
        "_internal_originalTime": 1689753052000
    },
    {
        "time": 1689753053000,
        "value": 139.423,
        "_internal_originalTime": 1689753053000
    },
    {
        "time": 1689753054000,
        "value": 139.424,
        "_internal_originalTime": 1689753054000
    },
    {
        "time": 1689753055000,
        "value": 139.424,
        "_internal_originalTime": 1689753055000
    },
    {
        "time": 1689753056000,
        "value": 139.42,
        "_internal_originalTime": 1689753056000
    },
    {
        "time": 1689753057000,
        "value": 139.424,
        "_internal_originalTime": 1689753057000
    },
    {
        "time": 1689753058000,
        "value": 139.423,
        "_internal_originalTime": 1689753058000
    },
    {
        "time": 1689753059000,
        "value": 139.431,
        "_internal_originalTime": 1689753059000
    },
    {
        "time": 1689753060000,
        "value": 139.43,
        "_internal_originalTime": 1689753060000
    },
    {
        "time": 1689753061000,
        "value": 139.425,
        "_internal_originalTime": 1689753061000
    },
    {
        "time": 1689753062000,
        "value": 139.426,
        "_internal_originalTime": 1689753062000
    },
    {
        "time": 1689753063000,
        "value": 139.423,
        "_internal_originalTime": 1689753063000
    },
    {
        "time": 1689753064000,
        "value": 139.425,
        "_internal_originalTime": 1689753064000
    },
    {
        "time": 1689753066000,
        "value": 139.423,
        "_internal_originalTime": 1689753066000
    },
    {
        "time": 1689753067000,
        "value": 139.422,
        "_internal_originalTime": 1689753067000
    },
    {
        "time": 1689753068000,
        "value": 139.431,
        "_internal_originalTime": 1689753068000
    },
    {
        "time": 1689753069000,
        "value": 139.425,
        "_internal_originalTime": 1689753069000
    },
    {
        "time": 1689753070000,
        "value": 139.426,
        "_internal_originalTime": 1689753070000
    },
    {
        "time": 1689753072000,
        "value": 139.424,
        "_internal_originalTime": 1689753072000
    },
    {
        "time": 1689753073000,
        "value": 139.429,
        "_internal_originalTime": 1689753073000
    },
    {
        "time": 1689753075000,
        "value": 139.412,
        "_internal_originalTime": 1689753075000
    },
    {
        "time": 1689753076000,
        "value": 139.414,
        "_internal_originalTime": 1689753076000
    },
    {
        "time": 1689753077000,
        "value": 139.414,
        "_internal_originalTime": 1689753077000
    },
    {
        "time": 1689753078000,
        "value": 139.417,
        "_internal_originalTime": 1689753078000
    },
    {
        "time": 1689753080000,
        "value": 139.414,
        "_internal_originalTime": 1689753080000
    },
    {
        "time": 1689753081000,
        "value": 139.411,
        "_internal_originalTime": 1689753081000
    },
    {
        "time": 1689753082000,
        "value": 139.414,
        "_internal_originalTime": 1689753082000
    },
    {
        "time": 1689753084000,
        "value": 139.429,
        "_internal_originalTime": 1689753084000
    },
    {
        "time": 1689753085000,
        "value": 139.426,
        "_internal_originalTime": 1689753085000
    },
    {
        "time": 1689753087000,
        "value": 139.429,
        "_internal_originalTime": 1689753087000
    },
    {
        "time": 1689753088000,
        "value": 139.427,
        "_internal_originalTime": 1689753088000
    },
    {
        "time": 1689753090000,
        "value": 139.428,
        "_internal_originalTime": 1689753090000
    },
    {
        "time": 1689753091000,
        "value": 139.426,
        "_internal_originalTime": 1689753091000
    },
    {
        "time": 1689753092000,
        "value": 139.43,
        "_internal_originalTime": 1689753092000
    },
    {
        "time": 1689753093000,
        "value": 139.435,
        "_internal_originalTime": 1689753093000
    },
    {
        "time": 1689753096000,
        "value": 139.436,
        "_internal_originalTime": 1689753096000
    },
    {
        "time": 1689753097000,
        "value": 139.435,
        "_internal_originalTime": 1689753097000
    },
    {
        "time": 1689753098000,
        "value": 139.436,
        "_internal_originalTime": 1689753098000
    },
    {
        "time": 1689753100000,
        "value": 139.438,
        "_internal_originalTime": 1689753100000
    },
    {
        "time": 1689753101000,
        "value": 139.436,
        "_internal_originalTime": 1689753101000
    },
    {
        "time": 1689753102000,
        "value": 139.438,
        "_internal_originalTime": 1689753102000
    },
    {
        "time": 1689753103000,
        "value": 139.436,
        "_internal_originalTime": 1689753103000
    },
    {
        "time": 1689753105000,
        "value": 139.436,
        "_internal_originalTime": 1689753105000
    },
    {
        "time": 1689753106000,
        "value": 139.443,
        "_internal_originalTime": 1689753106000
    },
    {
        "time": 1689753107000,
        "value": 139.442,
        "_internal_originalTime": 1689753107000
    },
    {
        "time": 1689753108000,
        "value": 139.442,
        "_internal_originalTime": 1689753108000
    },
    {
        "time": 1689753109000,
        "value": 139.439,
        "_internal_originalTime": 1689753109000
    },
    {
        "time": 1689753111000,
        "value": 139.445,
        "_internal_originalTime": 1689753111000
    },
    {
        "time": 1689753112000,
        "value": 139.442,
        "_internal_originalTime": 1689753112000
    },
    {
        "time": 1689753114000,
        "value": 139.444,
        "_internal_originalTime": 1689753114000
    },
    {
        "time": 1689753116000,
        "value": 139.44,
        "_internal_originalTime": 1689753116000
    },
    {
        "time": 1689753117000,
        "value": 139.44,
        "_internal_originalTime": 1689753117000
    },
    {
        "time": 1689753119000,
        "value": 139.446,
        "_internal_originalTime": 1689753119000
    },
    {
        "time": 1689753120000,
        "value": 139.444,
        "_internal_originalTime": 1689753120000
    },
    {
        "time": 1689753122000,
        "value": 139.44,
        "_internal_originalTime": 1689753122000
    },
    {
        "time": 1689753123000,
        "value": 139.444,
        "_internal_originalTime": 1689753123000
    },
    {
        "time": 1689753124000,
        "value": 139.442,
        "_internal_originalTime": 1689753124000
    },
    {
        "time": 1689753126000,
        "value": 139.444,
        "_internal_originalTime": 1689753126000
    },
    {
        "time": 1689753127000,
        "value": 139.447,
        "_internal_originalTime": 1689753127000
    },
    {
        "time": 1689753128000,
        "value": 139.454,
        "_internal_originalTime": 1689753128000
    },
    {
        "time": 1689753130000,
        "value": 139.45,
        "_internal_originalTime": 1689753130000
    },
    {
        "time": 1689753131000,
        "value": 139.449,
        "_internal_originalTime": 1689753131000
    },
    {
        "time": 1689753133000,
        "value": 139.455,
        "_internal_originalTime": 1689753133000
    },
    {
        "time": 1689753135000,
        "value": 139.454,
        "_internal_originalTime": 1689753135000
    },
    {
        "time": 1689753136000,
        "value": 139.453,
        "_internal_originalTime": 1689753136000
    },
    {
        "time": 1689753137000,
        "value": 139.454,
        "_internal_originalTime": 1689753137000
    },
    {
        "time": 1689753138000,
        "value": 139.455,
        "_internal_originalTime": 1689753138000
    },
    {
        "time": 1689753140000,
        "value": 139.457,
        "_internal_originalTime": 1689753140000
    },
    {
        "time": 1689753142000,
        "value": 139.454,
        "_internal_originalTime": 1689753142000
    },
    {
        "time": 1689753144000,
        "value": 139.453,
        "_internal_originalTime": 1689753144000
    },
    {
        "time": 1689753145000,
        "value": 139.455,
        "_internal_originalTime": 1689753145000
    },
    {
        "time": 1689753146000,
        "value": 139.455,
        "_internal_originalTime": 1689753146000
    },
    {
        "time": 1689753147000,
        "value": 139.456,
        "_internal_originalTime": 1689753147000
    },
    {
        "time": 1689753148000,
        "value": 139.447,
        "_internal_originalTime": 1689753148000
    },
    {
        "time": 1689753150000,
        "value": 139.451,
        "_internal_originalTime": 1689753150000
    },
    {
        "time": 1689753151000,
        "value": 139.452,
        "_internal_originalTime": 1689753151000
    },
    {
        "time": 1689753152000,
        "value": 139.45,
        "_internal_originalTime": 1689753152000
    },
    {
        "time": 1689753154000,
        "value": 139.456,
        "_internal_originalTime": 1689753154000
    },
    {
        "time": 1689753156000,
        "value": 139.452,
        "_internal_originalTime": 1689753156000
    },
    {
        "time": 1689753157000,
        "value": 139.465,
        "_internal_originalTime": 1689753157000
    },
    {
        "time": 1689753158000,
        "value": 139.461,
        "_internal_originalTime": 1689753158000
    },
    {
        "time": 1689753159000,
        "value": 139.466,
        "_internal_originalTime": 1689753159000
    },
    {
        "time": 1689753160000,
        "value": 139.464,
        "_internal_originalTime": 1689753160000
    },
    {
        "time": 1689753161000,
        "value": 139.461,
        "_internal_originalTime": 1689753161000
    },
    {
        "time": 1689753164000,
        "value": 139.46,
        "_internal_originalTime": 1689753164000
    },
    {
        "time": 1689753165000,
        "value": 139.464,
        "_internal_originalTime": 1689753165000
    },
    {
        "time": 1689753167000,
        "value": 139.464,
        "_internal_originalTime": 1689753167000
    },
    {
        "time": 1689753168000,
        "value": 139.465,
        "_internal_originalTime": 1689753168000
    },
    {
        "time": 1689753169000,
        "value": 139.465,
        "_internal_originalTime": 1689753169000
    },
    {
        "time": 1689753170000,
        "value": 139.465,
        "_internal_originalTime": 1689753170000
    },
    {
        "time": 1689753171000,
        "value": 139.464,
        "_internal_originalTime": 1689753171000
    },
    {
        "time": 1689753173000,
        "value": 139.46,
        "_internal_originalTime": 1689753173000
    },
    {
        "time": 1689753174000,
        "value": 139.464,
        "_internal_originalTime": 1689753174000
    },
    {
        "time": 1689753176000,
        "value": 139.464,
        "_internal_originalTime": 1689753176000
    },
    {
        "time": 1689753177000,
        "value": 139.462,
        "_internal_originalTime": 1689753177000
    },
    {
        "time": 1689753179000,
        "value": 139.464,
        "_internal_originalTime": 1689753179000
    },
    {
        "time": 1689753180000,
        "value": 139.463,
        "_internal_originalTime": 1689753180000
    },
    {
        "time": 1689753181000,
        "value": 139.467,
        "_internal_originalTime": 1689753181000
    },
    {
        "time": 1689753183000,
        "value": 139.464,
        "_internal_originalTime": 1689753183000
    },
    {
        "time": 1689753186000,
        "value": 139.459,
        "_internal_originalTime": 1689753186000
    },
    {
        "time": 1689753188000,
        "value": 139.453,
        "_internal_originalTime": 1689753188000
    },
    {
        "time": 1689753190000,
        "value": 139.457,
        "_internal_originalTime": 1689753190000
    },
    {
        "time": 1689753191000,
        "value": 139.458,
        "_internal_originalTime": 1689753191000
    },
    {
        "time": 1689753192000,
        "value": 139.461,
        "_internal_originalTime": 1689753192000
    },
    {
        "time": 1689753195000,
        "value": 139.463,
        "_internal_originalTime": 1689753195000
    },
    {
        "time": 1689753196000,
        "value": 139.467,
        "_internal_originalTime": 1689753196000
    },
    {
        "time": 1689753197000,
        "value": 139.473,
        "_internal_originalTime": 1689753197000
    },
    {
        "time": 1689753198000,
        "value": 139.472,
        "_internal_originalTime": 1689753198000
    },
    {
        "time": 1689753199000,
        "value": 139.472,
        "_internal_originalTime": 1689753199000
    },
    {
        "time": 1689753200000,
        "value": 139.47,
        "_internal_originalTime": 1689753200000
    },
    {
        "time": 1689753201000,
        "value": 139.478,
        "_internal_originalTime": 1689753201000
    },
    {
        "time": 1689753203000,
        "value": 139.474,
        "_internal_originalTime": 1689753203000
    },
    {
        "time": 1689753205000,
        "value": 139.476,
        "_internal_originalTime": 1689753205000
    },
    {
        "time": 1689753206000,
        "value": 139.474,
        "_internal_originalTime": 1689753206000
    },
    {
        "time": 1689753208000,
        "value": 139.475,
        "_internal_originalTime": 1689753208000
    },
    {
        "time": 1689753210000,
        "value": 139.471,
        "_internal_originalTime": 1689753210000
    },
    {
        "time": 1689753211000,
        "value": 139.473,
        "_internal_originalTime": 1689753211000
    },
    {
        "time": 1689753212000,
        "value": 139.476,
        "_internal_originalTime": 1689753212000
    },
    {
        "time": 1689753213000,
        "value": 139.473,
        "_internal_originalTime": 1689753213000
    },
    {
        "time": 1689753215000,
        "value": 139.471,
        "_internal_originalTime": 1689753215000
    },
    {
        "time": 1689753216000,
        "value": 139.474,
        "_internal_originalTime": 1689753216000
    },
    {
        "time": 1689753217000,
        "value": 139.471,
        "_internal_originalTime": 1689753217000
    },
    {
        "time": 1689753218000,
        "value": 139.472,
        "_internal_originalTime": 1689753218000
    },
    {
        "time": 1689753219000,
        "value": 139.47,
        "_internal_originalTime": 1689753219000
    },
    {
        "time": 1689753220000,
        "value": 139.476,
        "_internal_originalTime": 1689753220000
    },
    {
        "time": 1689753222000,
        "value": 139.474,
        "_internal_originalTime": 1689753222000
    },
    {
        "time": 1689753223000,
        "value": 139.477,
        "_internal_originalTime": 1689753223000
    },
    {
        "time": 1689753225000,
        "value": 139.482,
        "_internal_originalTime": 1689753225000
    },
    {
        "time": 1689753227000,
        "value": 139.485,
        "_internal_originalTime": 1689753227000
    },
    {
        "time": 1689753229000,
        "value": 139.484,
        "_internal_originalTime": 1689753229000
    },
    {
        "time": 1689753230000,
        "value": 139.478,
        "_internal_originalTime": 1689753230000
    },
    {
        "time": 1689753231000,
        "value": 139.48,
        "_internal_originalTime": 1689753231000
    },
    {
        "time": 1689753232000,
        "value": 139.48,
        "_internal_originalTime": 1689753232000
    },
    {
        "time": 1689753234000,
        "value": 139.484,
        "_internal_originalTime": 1689753234000
    },
    {
        "time": 1689753235000,
        "value": 139.484,
        "_internal_originalTime": 1689753235000
    },
    {
        "time": 1689753236000,
        "value": 139.484,
        "_internal_originalTime": 1689753236000
    },
    {
        "time": 1689753237000,
        "value": 139.487,
        "_internal_originalTime": 1689753237000
    },
    {
        "time": 1689753238000,
        "value": 139.483,
        "_internal_originalTime": 1689753238000
    },
    {
        "time": 1689753239000,
        "value": 139.48,
        "_internal_originalTime": 1689753239000
    },
    {
        "time": 1689753241000,
        "value": 139.479,
        "_internal_originalTime": 1689753241000
    },
    {
        "time": 1689753242000,
        "value": 139.479,
        "_internal_originalTime": 1689753242000
    },
    {
        "time": 1689753243000,
        "value": 139.478,
        "_internal_originalTime": 1689753243000
    },
    {
        "time": 1689753244000,
        "value": 139.485,
        "_internal_originalTime": 1689753244000
    },
    {
        "time": 1689753245000,
        "value": 139.483,
        "_internal_originalTime": 1689753245000
    },
    {
        "time": 1689753246000,
        "value": 139.486,
        "_internal_originalTime": 1689753246000
    },
    {
        "time": 1689753247000,
        "value": 139.482,
        "_internal_originalTime": 1689753247000
    },
    {
        "time": 1689753248000,
        "value": 139.489,
        "_internal_originalTime": 1689753248000
    },
    {
        "time": 1689753249000,
        "value": 139.493,
        "_internal_originalTime": 1689753249000
    },
    {
        "time": 1689753250000,
        "value": 139.493,
        "_internal_originalTime": 1689753250000
    },
    {
        "time": 1689753251000,
        "value": 139.491,
        "_internal_originalTime": 1689753251000
    },
    {
        "time": 1689753253000,
        "value": 139.491,
        "_internal_originalTime": 1689753253000
    },
    {
        "time": 1689753254000,
        "value": 139.485,
        "_internal_originalTime": 1689753254000
    },
    {
        "time": 1689753255000,
        "value": 139.487,
        "_internal_originalTime": 1689753255000
    },
    {
        "time": 1689753256000,
        "value": 139.482,
        "_internal_originalTime": 1689753256000
    },
    {
        "time": 1689753257000,
        "value": 139.483,
        "_internal_originalTime": 1689753257000
    },
    {
        "time": 1689753258000,
        "value": 139.485,
        "_internal_originalTime": 1689753258000
    },
    {
        "time": 1689753259000,
        "value": 139.479,
        "_internal_originalTime": 1689753259000
    },
    {
        "time": 1689753260000,
        "value": 139.486,
        "_internal_originalTime": 1689753260000
    },
    {
        "time": 1689753261000,
        "value": 139.485,
        "_internal_originalTime": 1689753261000
    },
    {
        "time": 1689753263000,
        "value": 139.485,
        "_internal_originalTime": 1689753263000
    },
    {
        "time": 1689753264000,
        "value": 139.485,
        "_internal_originalTime": 1689753264000
    },
    {
        "time": 1689753265000,
        "value": 139.484,
        "_internal_originalTime": 1689753265000
    },
    {
        "time": 1689753266000,
        "value": 139.485,
        "_internal_originalTime": 1689753266000
    },
    {
        "time": 1689753267000,
        "value": 139.469,
        "_internal_originalTime": 1689753267000
    },
    {
        "time": 1689753268000,
        "value": 139.469,
        "_internal_originalTime": 1689753268000
    },
    {
        "time": 1689753269000,
        "value": 139.469,
        "_internal_originalTime": 1689753269000
    },
    {
        "time": 1689753271000,
        "value": 139.47,
        "_internal_originalTime": 1689753271000
    },
    {
        "time": 1689753272000,
        "value": 139.47,
        "_internal_originalTime": 1689753272000
    },
    {
        "time": 1689753273000,
        "value": 139.472,
        "_internal_originalTime": 1689753273000
    },
    {
        "time": 1689753274000,
        "value": 139.476,
        "_internal_originalTime": 1689753274000
    },
    {
        "time": 1689753275000,
        "value": 139.47,
        "_internal_originalTime": 1689753275000
    },
    {
        "time": 1689753276000,
        "value": 139.474,
        "_internal_originalTime": 1689753276000
    },
    {
        "time": 1689753278000,
        "value": 139.472,
        "_internal_originalTime": 1689753278000
    },
    {
        "time": 1689753279000,
        "value": 139.47,
        "_internal_originalTime": 1689753279000
    },
    {
        "time": 1689753280000,
        "value": 139.472,
        "_internal_originalTime": 1689753280000
    },
    {
        "time": 1689753282000,
        "value": 139.465,
        "_internal_originalTime": 1689753282000
    },
    {
        "time": 1689753283000,
        "value": 139.464,
        "_internal_originalTime": 1689753283000
    },
    {
        "time": 1689753284000,
        "value": 139.463,
        "_internal_originalTime": 1689753284000
    },
    {
        "time": 1689753285000,
        "value": 139.469,
        "_internal_originalTime": 1689753285000
    },
    {
        "time": 1689753286000,
        "value": 139.474,
        "_internal_originalTime": 1689753286000
    },
    {
        "time": 1689753288000,
        "value": 139.47,
        "_internal_originalTime": 1689753288000
    },
    {
        "time": 1689753289000,
        "value": 139.471,
        "_internal_originalTime": 1689753289000
    },
    {
        "time": 1689753291000,
        "value": 139.473,
        "_internal_originalTime": 1689753291000
    },
    {
        "time": 1689753292000,
        "value": 139.472,
        "_internal_originalTime": 1689753292000
    },
    {
        "time": 1689753293000,
        "value": 139.473,
        "_internal_originalTime": 1689753293000
    },
    {
        "time": 1689753294000,
        "value": 139.468,
        "_internal_originalTime": 1689753294000
    },
    {
        "time": 1689753295000,
        "value": 139.472,
        "_internal_originalTime": 1689753295000
    },
    {
        "time": 1689753296000,
        "value": 139.469,
        "_internal_originalTime": 1689753296000
    },
    {
        "time": 1689753297000,
        "value": 139.464,
        "_internal_originalTime": 1689753297000
    },
    {
        "time": 1689753298000,
        "value": 139.464,
        "_internal_originalTime": 1689753298000
    },
    {
        "time": 1689753300000,
        "value": 139.462,
        "_internal_originalTime": 1689753300000
    },
    {
        "time": 1689753302000,
        "value": 139.467,
        "_internal_originalTime": 1689753302000
    },
    {
        "time": 1689753303000,
        "value": 139.465,
        "_internal_originalTime": 1689753303000
    },
    {
        "time": 1689753305000,
        "value": 139.466,
        "_internal_originalTime": 1689753305000
    },
    {
        "time": 1689753306000,
        "value": 139.464,
        "_internal_originalTime": 1689753306000
    },
    {
        "time": 1689753308000,
        "value": 139.463,
        "_internal_originalTime": 1689753308000
    },
    {
        "time": 1689753310000,
        "value": 139.465,
        "_internal_originalTime": 1689753310000
    },
    {
        "time": 1689753311000,
        "value": 139.465,
        "_internal_originalTime": 1689753311000
    },
    {
        "time": 1689753312000,
        "value": 139.465,
        "_internal_originalTime": 1689753312000
    },
    {
        "time": 1689753313000,
        "value": 139.467,
        "_internal_originalTime": 1689753313000
    },
    {
        "time": 1689753315000,
        "value": 139.473,
        "_internal_originalTime": 1689753315000
    },
    {
        "time": 1689753316000,
        "value": 139.475,
        "_internal_originalTime": 1689753316000
    },
    {
        "time": 1689753317000,
        "value": 139.474,
        "_internal_originalTime": 1689753317000
    },
    {
        "time": 1689753318000,
        "value": 139.47,
        "_internal_originalTime": 1689753318000
    },
    {
        "time": 1689753319000,
        "value": 139.47,
        "_internal_originalTime": 1689753319000
    },
    {
        "time": 1689753320000,
        "value": 139.468,
        "_internal_originalTime": 1689753320000
    },
    {
        "time": 1689753321000,
        "value": 139.467,
        "_internal_originalTime": 1689753321000
    },
    {
        "time": 1689753322000,
        "value": 139.472,
        "_internal_originalTime": 1689753322000
    },
    {
        "time": 1689753323000,
        "value": 139.474,
        "_internal_originalTime": 1689753323000
    },
    {
        "time": 1689753324000,
        "value": 139.476,
        "_internal_originalTime": 1689753324000
    },
    {
        "time": 1689753325000,
        "value": 139.473,
        "_internal_originalTime": 1689753325000
    },
    {
        "time": 1689753326000,
        "value": 139.475,
        "_internal_originalTime": 1689753326000
    },
    {
        "time": 1689753327000,
        "value": 139.473,
        "_internal_originalTime": 1689753327000
    },
    {
        "time": 1689753328000,
        "value": 139.473,
        "_internal_originalTime": 1689753328000
    },
    {
        "time": 1689753330000,
        "value": 139.472,
        "_internal_originalTime": 1689753330000
    },
    {
        "time": 1689753331000,
        "value": 139.474,
        "_internal_originalTime": 1689753331000
    },
    {
        "time": 1689753332000,
        "value": 139.473,
        "_internal_originalTime": 1689753332000
    },
    {
        "time": 1689753333000,
        "value": 139.473,
        "_internal_originalTime": 1689753333000
    },
    {
        "time": 1689753334000,
        "value": 139.472,
        "_internal_originalTime": 1689753334000
    },
    {
        "time": 1689753338000,
        "value": 139.472,
        "_internal_originalTime": 1689753338000
    },
    {
        "time": 1689753340000,
        "value": 139.473,
        "_internal_originalTime": 1689753340000
    },
    {
        "time": 1689753341000,
        "value": 139.474,
        "_internal_originalTime": 1689753341000
    },
    {
        "time": 1689753343000,
        "value": 139.473,
        "_internal_originalTime": 1689753343000
    },
    {
        "time": 1689753344000,
        "value": 139.472,
        "_internal_originalTime": 1689753344000
    },
    {
        "time": 1689753346000,
        "value": 139.476,
        "_internal_originalTime": 1689753346000
    },
    {
        "time": 1689753348000,
        "value": 139.476,
        "_internal_originalTime": 1689753348000
    },
    {
        "time": 1689753349000,
        "value": 139.479,
        "_internal_originalTime": 1689753349000
    },
    {
        "time": 1689753350000,
        "value": 139.479,
        "_internal_originalTime": 1689753350000
    },
    {
        "time": 1689753351000,
        "value": 139.481,
        "_internal_originalTime": 1689753351000
    },
    {
        "time": 1689753352000,
        "value": 139.478,
        "_internal_originalTime": 1689753352000
    },
    {
        "time": 1689753353000,
        "value": 139.485,
        "_internal_originalTime": 1689753353000
    },
    {
        "time": 1689753354000,
        "value": 139.48,
        "_internal_originalTime": 1689753354000
    },
    {
        "time": 1689753355000,
        "value": 139.483,
        "_internal_originalTime": 1689753355000
    },
    {
        "time": 1689753358000,
        "value": 139.481,
        "_internal_originalTime": 1689753358000
    },
    {
        "time": 1689753359000,
        "value": 139.483,
        "_internal_originalTime": 1689753359000
    },
    {
        "time": 1689753360000,
        "value": 139.479,
        "_internal_originalTime": 1689753360000
    },
    {
        "time": 1689753361000,
        "value": 139.478,
        "_internal_originalTime": 1689753361000
    },
    {
        "time": 1689753363000,
        "value": 139.482,
        "_internal_originalTime": 1689753363000
    },
    {
        "time": 1689753364000,
        "value": 139.482,
        "_internal_originalTime": 1689753364000
    },
    {
        "time": 1689753366000,
        "value": 139.478,
        "_internal_originalTime": 1689753366000
    },
    {
        "time": 1689753367000,
        "value": 139.475,
        "_internal_originalTime": 1689753367000
    },
    {
        "time": 1689753368000,
        "value": 139.478,
        "_internal_originalTime": 1689753368000
    },
    {
        "time": 1689753369000,
        "value": 139.475,
        "_internal_originalTime": 1689753369000
    },
    {
        "time": 1689753370000,
        "value": 139.475,
        "_internal_originalTime": 1689753370000
    },
    {
        "time": 1689753371000,
        "value": 139.475,
        "_internal_originalTime": 1689753371000
    },
    {
        "time": 1689753372000,
        "value": 139.473,
        "_internal_originalTime": 1689753372000
    },
    {
        "time": 1689753374000,
        "value": 139.468,
        "_internal_originalTime": 1689753374000
    },
    {
        "time": 1689753375000,
        "value": 139.471,
        "_internal_originalTime": 1689753375000
    },
    {
        "time": 1689753376000,
        "value": 139.476,
        "_internal_originalTime": 1689753376000
    },
    {
        "time": 1689753377000,
        "value": 139.478,
        "_internal_originalTime": 1689753377000
    },
    {
        "time": 1689753378000,
        "value": 139.482,
        "_internal_originalTime": 1689753378000
    },
    {
        "time": 1689753379000,
        "value": 139.483,
        "_internal_originalTime": 1689753379000
    },
    {
        "time": 1689753380000,
        "value": 139.483,
        "_internal_originalTime": 1689753380000
    },
    {
        "time": 1689753382000,
        "value": 139.483,
        "_internal_originalTime": 1689753382000
    },
    {
        "time": 1689753383000,
        "value": 139.483,
        "_internal_originalTime": 1689753383000
    },
    {
        "time": 1689753384000,
        "value": 139.483,
        "_internal_originalTime": 1689753384000
    },
    {
        "time": 1689753385000,
        "value": 139.484,
        "_internal_originalTime": 1689753385000
    },
    {
        "time": 1689753386000,
        "value": 139.48,
        "_internal_originalTime": 1689753386000
    },
    {
        "time": 1689753388000,
        "value": 139.49,
        "_internal_originalTime": 1689753388000
    },
    {
        "time": 1689753389000,
        "value": 139.497,
        "_internal_originalTime": 1689753389000
    },
    {
        "time": 1689753390000,
        "value": 139.496,
        "_internal_originalTime": 1689753390000
    },
    {
        "time": 1689753391000,
        "value": 139.497,
        "_internal_originalTime": 1689753391000
    },
    {
        "time": 1689753392000,
        "value": 139.48,
        "_internal_originalTime": 1689753392000
    },
    {
        "time": 1689753393000,
        "value": 139.483,
        "_internal_originalTime": 1689753393000
    },
    {
        "time": 1689753394000,
        "value": 139.483,
        "_internal_originalTime": 1689753394000
    },
    {
        "time": 1689753395000,
        "value": 139.485,
        "_internal_originalTime": 1689753395000
    },
    {
        "time": 1689753397000,
        "value": 139.483,
        "_internal_originalTime": 1689753397000
    },
    {
        "time": 1689753398000,
        "value": 139.472,
        "_internal_originalTime": 1689753398000
    },
    {
        "time": 1689753400000,
        "value": 139.474,
        "_internal_originalTime": 1689753400000
    },
    {
        "time": 1689753401000,
        "value": 139.477,
        "_internal_originalTime": 1689753401000
    },
    {
        "time": 1689753402000,
        "value": 139.471,
        "_internal_originalTime": 1689753402000
    },
    {
        "time": 1689753405000,
        "value": 139.463,
        "_internal_originalTime": 1689753405000
    },
    {
        "time": 1689753406000,
        "value": 139.469,
        "_internal_originalTime": 1689753406000
    },
    {
        "time": 1689753407000,
        "value": 139.473,
        "_internal_originalTime": 1689753407000
    },
    {
        "time": 1689753408000,
        "value": 139.473,
        "_internal_originalTime": 1689753408000
    },
    {
        "time": 1689753409000,
        "value": 139.47,
        "_internal_originalTime": 1689753409000
    },
    {
        "time": 1689753410000,
        "value": 139.465,
        "_internal_originalTime": 1689753410000
    },
    {
        "time": 1689753411000,
        "value": 139.463,
        "_internal_originalTime": 1689753411000
    },
    {
        "time": 1689753412000,
        "value": 139.464,
        "_internal_originalTime": 1689753412000
    },
    {
        "time": 1689753413000,
        "value": 139.464,
        "_internal_originalTime": 1689753413000
    },
    {
        "time": 1689753414000,
        "value": 139.461,
        "_internal_originalTime": 1689753414000
    },
    {
        "time": 1689753415000,
        "value": 139.454,
        "_internal_originalTime": 1689753415000
    },
    {
        "time": 1689753416000,
        "value": 139.454,
        "_internal_originalTime": 1689753416000
    },
    {
        "time": 1689753417000,
        "value": 139.456,
        "_internal_originalTime": 1689753417000
    },
    {
        "time": 1689753419000,
        "value": 139.456,
        "_internal_originalTime": 1689753419000
    },
    {
        "time": 1689753420000,
        "value": 139.43,
        "_internal_originalTime": 1689753420000
    },
    {
        "time": 1689753422000,
        "value": 139.45,
        "_internal_originalTime": 1689753422000
    },
    {
        "time": 1689753423000,
        "value": 139.451,
        "_internal_originalTime": 1689753423000
    },
    {
        "time": 1689753424000,
        "value": 139.452,
        "_internal_originalTime": 1689753424000
    },
    {
        "time": 1689753425000,
        "value": 139.452,
        "_internal_originalTime": 1689753425000
    },
    {
        "time": 1689753426000,
        "value": 139.452,
        "_internal_originalTime": 1689753426000
    },
    {
        "time": 1689753428000,
        "value": 139.453,
        "_internal_originalTime": 1689753428000
    },
    {
        "time": 1689753429000,
        "value": 139.455,
        "_internal_originalTime": 1689753429000
    },
    {
        "time": 1689753430000,
        "value": 139.454,
        "_internal_originalTime": 1689753430000
    },
    {
        "time": 1689753431000,
        "value": 139.456,
        "_internal_originalTime": 1689753431000
    },
    {
        "time": 1689753432000,
        "value": 139.456,
        "_internal_originalTime": 1689753432000
    },
    {
        "time": 1689753433000,
        "value": 139.454,
        "_internal_originalTime": 1689753433000
    },
    {
        "time": 1689753435000,
        "value": 139.451,
        "_internal_originalTime": 1689753435000
    },
    {
        "time": 1689753436000,
        "value": 139.45,
        "_internal_originalTime": 1689753436000
    },
    {
        "time": 1689753437000,
        "value": 139.452,
        "_internal_originalTime": 1689753437000
    },
    {
        "time": 1689753438000,
        "value": 139.454,
        "_internal_originalTime": 1689753438000
    },
    {
        "time": 1689753440000,
        "value": 139.455,
        "_internal_originalTime": 1689753440000
    },
    {
        "time": 1689753441000,
        "value": 139.454,
        "_internal_originalTime": 1689753441000
    },
    {
        "time": 1689753443000,
        "value": 139.458,
        "_internal_originalTime": 1689753443000
    },
    {
        "time": 1689753444000,
        "value": 139.458,
        "_internal_originalTime": 1689753444000
    },
    {
        "time": 1689753445000,
        "value": 139.46,
        "_internal_originalTime": 1689753445000
    },
    {
        "time": 1689753446000,
        "value": 139.461,
        "_internal_originalTime": 1689753446000
    },
    {
        "time": 1689753448000,
        "value": 139.454,
        "_internal_originalTime": 1689753448000
    },
    {
        "time": 1689753450000,
        "value": 139.46,
        "_internal_originalTime": 1689753450000
    },
    {
        "time": 1689753451000,
        "value": 139.463,
        "_internal_originalTime": 1689753451000
    },
    {
        "time": 1689753452000,
        "value": 139.463,
        "_internal_originalTime": 1689753452000
    },
    {
        "time": 1689753453000,
        "value": 139.468,
        "_internal_originalTime": 1689753453000
    },
    {
        "time": 1689753454000,
        "value": 139.482,
        "_internal_originalTime": 1689753454000
    },
    {
        "time": 1689753455000,
        "value": 139.482,
        "_internal_originalTime": 1689753455000
    },
    {
        "time": 1689753456000,
        "value": 139.482,
        "_internal_originalTime": 1689753456000
    },
    {
        "time": 1689753457000,
        "value": 139.49,
        "_internal_originalTime": 1689753457000
    },
    {
        "time": 1689753458000,
        "value": 139.481,
        "_internal_originalTime": 1689753458000
    },
    {
        "time": 1689753459000,
        "value": 139.48,
        "_internal_originalTime": 1689753459000
    },
    {
        "time": 1689753460000,
        "value": 139.472,
        "_internal_originalTime": 1689753460000
    },
    {
        "time": 1689753461000,
        "value": 139.472,
        "_internal_originalTime": 1689753461000
    },
    {
        "time": 1689753462000,
        "value": 139.471,
        "_internal_originalTime": 1689753462000
    },
    {
        "time": 1689753464000,
        "value": 139.463,
        "_internal_originalTime": 1689753464000
    },
    {
        "time": 1689753465000,
        "value": 139.465,
        "_internal_originalTime": 1689753465000
    },
    {
        "time": 1689753466000,
        "value": 139.46,
        "_internal_originalTime": 1689753466000
    },
    {
        "time": 1689753467000,
        "value": 139.459,
        "_internal_originalTime": 1689753467000
    },
    {
        "time": 1689753468000,
        "value": 139.464,
        "_internal_originalTime": 1689753468000
    },
    {
        "time": 1689753470000,
        "value": 139.463,
        "_internal_originalTime": 1689753470000
    },
    {
        "time": 1689753472000,
        "value": 139.455,
        "_internal_originalTime": 1689753472000
    },
    {
        "time": 1689753473000,
        "value": 139.452,
        "_internal_originalTime": 1689753473000
    },
    {
        "time": 1689753474000,
        "value": 139.457,
        "_internal_originalTime": 1689753474000
    },
    {
        "time": 1689753475000,
        "value": 139.453,
        "_internal_originalTime": 1689753475000
    },
    {
        "time": 1689753476000,
        "value": 139.459,
        "_internal_originalTime": 1689753476000
    },
    {
        "time": 1689753478000,
        "value": 139.46,
        "_internal_originalTime": 1689753478000
    },
    {
        "time": 1689753480000,
        "value": 139.461,
        "_internal_originalTime": 1689753480000
    },
    {
        "time": 1689753481000,
        "value": 139.462,
        "_internal_originalTime": 1689753481000
    },
    {
        "time": 1689753482000,
        "value": 139.464,
        "_internal_originalTime": 1689753482000
    },
    {
        "time": 1689753483000,
        "value": 139.466,
        "_internal_originalTime": 1689753483000
    },
    {
        "time": 1689753484000,
        "value": 139.463,
        "_internal_originalTime": 1689753484000
    },
    {
        "time": 1689753486000,
        "value": 139.464,
        "_internal_originalTime": 1689753486000
    },
    {
        "time": 1689753487000,
        "value": 139.47,
        "_internal_originalTime": 1689753487000
    },
    {
        "time": 1689753489000,
        "value": 139.467,
        "_internal_originalTime": 1689753489000
    },
    {
        "time": 1689753490000,
        "value": 139.469,
        "_internal_originalTime": 1689753490000
    },
    {
        "time": 1689753491000,
        "value": 139.473,
        "_internal_originalTime": 1689753491000
    },
    {
        "time": 1689753492000,
        "value": 139.471,
        "_internal_originalTime": 1689753492000
    },
    {
        "time": 1689753493000,
        "value": 139.47,
        "_internal_originalTime": 1689753493000
    },
    {
        "time": 1689753494000,
        "value": 139.474,
        "_internal_originalTime": 1689753494000
    },
    {
        "time": 1689753495000,
        "value": 139.468,
        "_internal_originalTime": 1689753495000
    },
    {
        "time": 1689753497000,
        "value": 139.473,
        "_internal_originalTime": 1689753497000
    },
    {
        "time": 1689753498000,
        "value": 139.469,
        "_internal_originalTime": 1689753498000
    },
    {
        "time": 1689753499000,
        "value": 139.471,
        "_internal_originalTime": 1689753499000
    },
    {
        "time": 1689753501000,
        "value": 139.468,
        "_internal_originalTime": 1689753501000
    },
    {
        "time": 1689753502000,
        "value": 139.47,
        "_internal_originalTime": 1689753502000
    },
    {
        "time": 1689753503000,
        "value": 139.469,
        "_internal_originalTime": 1689753503000
    },
    {
        "time": 1689753504000,
        "value": 139.471,
        "_internal_originalTime": 1689753504000
    },
    {
        "time": 1689753505000,
        "value": 139.472,
        "_internal_originalTime": 1689753505000
    },
    {
        "time": 1689753506000,
        "value": 139.474,
        "_internal_originalTime": 1689753506000
    },
    {
        "time": 1689753509000,
        "value": 139.475,
        "_internal_originalTime": 1689753509000
    },
    {
        "time": 1689753510000,
        "value": 139.472,
        "_internal_originalTime": 1689753510000
    },
    {
        "time": 1689753511000,
        "value": 139.473,
        "_internal_originalTime": 1689753511000
    },
    {
        "time": 1689753512000,
        "value": 139.49,
        "_internal_originalTime": 1689753512000
    },
    {
        "time": 1689753514000,
        "value": 139.484,
        "_internal_originalTime": 1689753514000
    },
    {
        "time": 1689753515000,
        "value": 139.487,
        "_internal_originalTime": 1689753515000
    },
    {
        "time": 1689753516000,
        "value": 139.486,
        "_internal_originalTime": 1689753516000
    },
    {
        "time": 1689753518000,
        "value": 139.485,
        "_internal_originalTime": 1689753518000
    },
    {
        "time": 1689753520000,
        "value": 139.488,
        "_internal_originalTime": 1689753520000
    },
    {
        "time": 1689753521000,
        "value": 139.489,
        "_internal_originalTime": 1689753521000
    },
    {
        "time": 1689753522000,
        "value": 139.489,
        "_internal_originalTime": 1689753522000
    },
    {
        "time": 1689753524000,
        "value": 139.49,
        "_internal_originalTime": 1689753524000
    },
    {
        "time": 1689753525000,
        "value": 139.493,
        "_internal_originalTime": 1689753525000
    },
    {
        "time": 1689753526000,
        "value": 139.493,
        "_internal_originalTime": 1689753526000
    },
    {
        "time": 1689753527000,
        "value": 139.497,
        "_internal_originalTime": 1689753527000
    },
    {
        "time": 1689753529000,
        "value": 139.491,
        "_internal_originalTime": 1689753529000
    },
    {
        "time": 1689753530000,
        "value": 139.482,
        "_internal_originalTime": 1689753530000
    },
    {
        "time": 1689753531000,
        "value": 139.478,
        "_internal_originalTime": 1689753531000
    },
    {
        "time": 1689753533000,
        "value": 139.481,
        "_internal_originalTime": 1689753533000
    },
    {
        "time": 1689753534000,
        "value": 139.483,
        "_internal_originalTime": 1689753534000
    },
    {
        "time": 1689753535000,
        "value": 139.482,
        "_internal_originalTime": 1689753535000
    },
    {
        "time": 1689753536000,
        "value": 139.482,
        "_internal_originalTime": 1689753536000
    },
    {
        "time": 1689753537000,
        "value": 139.478,
        "_internal_originalTime": 1689753537000
    },
    {
        "time": 1689753538000,
        "value": 139.481,
        "_internal_originalTime": 1689753538000
    },
    {
        "time": 1689753540000,
        "value": 139.478,
        "_internal_originalTime": 1689753540000
    },
    {
        "time": 1689753541000,
        "value": 139.46,
        "_internal_originalTime": 1689753541000
    },
    {
        "time": 1689753542000,
        "value": 139.476,
        "_internal_originalTime": 1689753542000
    },
    {
        "time": 1689753544000,
        "value": 139.475,
        "_internal_originalTime": 1689753544000
    },
    {
        "time": 1689753545000,
        "value": 139.473,
        "_internal_originalTime": 1689753545000
    },
    {
        "time": 1689753546000,
        "value": 139.473,
        "_internal_originalTime": 1689753546000
    },
    {
        "time": 1689753547000,
        "value": 139.508,
        "_internal_originalTime": 1689753547000
    },
    {
        "time": 1689753548000,
        "value": 139.5,
        "_internal_originalTime": 1689753548000
    },
    {
        "time": 1689753549000,
        "value": 139.494,
        "_internal_originalTime": 1689753549000
    },
    {
        "time": 1689753550000,
        "value": 139.499,
        "_internal_originalTime": 1689753550000
    },
    {
        "time": 1689753551000,
        "value": 139.496,
        "_internal_originalTime": 1689753551000
    },
    {
        "time": 1689753553000,
        "value": 139.496,
        "_internal_originalTime": 1689753553000
    },
    {
        "time": 1689753555000,
        "value": 139.489,
        "_internal_originalTime": 1689753555000
    },
    {
        "time": 1689753556000,
        "value": 139.488,
        "_internal_originalTime": 1689753556000
    },
    {
        "time": 1689753557000,
        "value": 139.487,
        "_internal_originalTime": 1689753557000
    },
    {
        "time": 1689753560000,
        "value": 139.489,
        "_internal_originalTime": 1689753560000
    },
    {
        "time": 1689753562000,
        "value": 139.487,
        "_internal_originalTime": 1689753562000
    },
    {
        "time": 1689753563000,
        "value": 139.487,
        "_internal_originalTime": 1689753563000
    },
    {
        "time": 1689753565000,
        "value": 139.486,
        "_internal_originalTime": 1689753565000
    },
    {
        "time": 1689753566000,
        "value": 139.487,
        "_internal_originalTime": 1689753566000
    },
    {
        "time": 1689753569000,
        "value": 139.492,
        "_internal_originalTime": 1689753569000
    },
    {
        "time": 1689753570000,
        "value": 139.492,
        "_internal_originalTime": 1689753570000
    },
    {
        "time": 1689753571000,
        "value": 139.493,
        "_internal_originalTime": 1689753571000
    },
    {
        "time": 1689753572000,
        "value": 139.494,
        "_internal_originalTime": 1689753572000
    },
    {
        "time": 1689753574000,
        "value": 139.487,
        "_internal_originalTime": 1689753574000
    },
    {
        "time": 1689753575000,
        "value": 139.493,
        "_internal_originalTime": 1689753575000
    },
    {
        "time": 1689753576000,
        "value": 139.489,
        "_internal_originalTime": 1689753576000
    },
    {
        "time": 1689753577000,
        "value": 139.495,
        "_internal_originalTime": 1689753577000
    },
    {
        "time": 1689753578000,
        "value": 139.495,
        "_internal_originalTime": 1689753578000
    },
    {
        "time": 1689753580000,
        "value": 139.492,
        "_internal_originalTime": 1689753580000
    },
    {
        "time": 1689753581000,
        "value": 139.495,
        "_internal_originalTime": 1689753581000
    },
    {
        "time": 1689753583000,
        "value": 139.502,
        "_internal_originalTime": 1689753583000
    },
    {
        "time": 1689753585000,
        "value": 139.5,
        "_internal_originalTime": 1689753585000
    },
    {
        "time": 1689753587000,
        "value": 139.496,
        "_internal_originalTime": 1689753587000
    },
    {
        "time": 1689753588000,
        "value": 139.495,
        "_internal_originalTime": 1689753588000
    },
    {
        "time": 1689753589000,
        "value": 139.498,
        "_internal_originalTime": 1689753589000
    },
    {
        "time": 1689753591000,
        "value": 139.494,
        "_internal_originalTime": 1689753591000
    },
    {
        "time": 1689753592000,
        "value": 139.491,
        "_internal_originalTime": 1689753592000
    },
    {
        "time": 1689753593000,
        "value": 139.49,
        "_internal_originalTime": 1689753593000
    },
    {
        "time": 1689753594000,
        "value": 139.494,
        "_internal_originalTime": 1689753594000
    },
    {
        "time": 1689753595000,
        "value": 139.495,
        "_internal_originalTime": 1689753595000
    },
    {
        "time": 1689753596000,
        "value": 139.498,
        "_internal_originalTime": 1689753596000
    },
    {
        "time": 1689753597000,
        "value": 139.496,
        "_internal_originalTime": 1689753597000
    },
    {
        "time": 1689753598000,
        "value": 139.496,
        "_internal_originalTime": 1689753598000
    },
    {
        "time": 1689753599000,
        "value": 139.496,
        "_internal_originalTime": 1689753599000
    },
    {
        "time": 1689753601000,
        "value": 139.51,
        "_internal_originalTime": 1689753601000
    },
    {
        "time": 1689753602000,
        "value": 139.504,
        "_internal_originalTime": 1689753602000
    },
    {
        "time": 1689753604000,
        "value": 139.499,
        "_internal_originalTime": 1689753604000
    },
    {
        "time": 1689753606000,
        "value": 139.499,
        "_internal_originalTime": 1689753606000
    },
    {
        "time": 1689753608000,
        "value": 139.502,
        "_internal_originalTime": 1689753608000
    },
    {
        "time": 1689753609000,
        "value": 139.5,
        "_internal_originalTime": 1689753609000
    },
    {
        "time": 1689753610000,
        "value": 139.496,
        "_internal_originalTime": 1689753610000
    },
    {
        "time": 1689753611000,
        "value": 139.498,
        "_internal_originalTime": 1689753611000
    },
    {
        "time": 1689753612000,
        "value": 139.495,
        "_internal_originalTime": 1689753612000
    },
    {
        "time": 1689753613000,
        "value": 139.492,
        "_internal_originalTime": 1689753613000
    },
    {
        "time": 1689753615000,
        "value": 139.496,
        "_internal_originalTime": 1689753615000
    },
    {
        "time": 1689753616000,
        "value": 139.502,
        "_internal_originalTime": 1689753616000
    },
    {
        "time": 1689753617000,
        "value": 139.508,
        "_internal_originalTime": 1689753617000
    },
    {
        "time": 1689753618000,
        "value": 139.504,
        "_internal_originalTime": 1689753618000
    },
    {
        "time": 1689753619000,
        "value": 139.498,
        "_internal_originalTime": 1689753619000
    },
    {
        "time": 1689753620000,
        "value": 139.499,
        "_internal_originalTime": 1689753620000
    },
    {
        "time": 1689753621000,
        "value": 139.501,
        "_internal_originalTime": 1689753621000
    },
    {
        "time": 1689753622000,
        "value": 139.504,
        "_internal_originalTime": 1689753622000
    },
    {
        "time": 1689753623000,
        "value": 139.503,
        "_internal_originalTime": 1689753623000
    },
    {
        "time": 1689753625000,
        "value": 139.508,
        "_internal_originalTime": 1689753625000
    },
    {
        "time": 1689753627000,
        "value": 139.509,
        "_internal_originalTime": 1689753627000
    },
    {
        "time": 1689753628000,
        "value": 139.51,
        "_internal_originalTime": 1689753628000
    },
    {
        "time": 1689753629000,
        "value": 139.51,
        "_internal_originalTime": 1689753629000
    },
    {
        "time": 1689753630000,
        "value": 139.508,
        "_internal_originalTime": 1689753630000
    },
    {
        "time": 1689753631000,
        "value": 139.5,
        "_internal_originalTime": 1689753631000
    },
    {
        "time": 1689753632000,
        "value": 139.497,
        "_internal_originalTime": 1689753632000
    },
    {
        "time": 1689753633000,
        "value": 139.502,
        "_internal_originalTime": 1689753633000
    },
    {
        "time": 1689753634000,
        "value": 139.5,
        "_internal_originalTime": 1689753634000
    },
    {
        "time": 1689753635000,
        "value": 139.501,
        "_internal_originalTime": 1689753635000
    },
    {
        "time": 1689753636000,
        "value": 139.494,
        "_internal_originalTime": 1689753636000
    },
    {
        "time": 1689753637000,
        "value": 139.499,
        "_internal_originalTime": 1689753637000
    },
    {
        "time": 1689753638000,
        "value": 139.497,
        "_internal_originalTime": 1689753638000
    },
    {
        "time": 1689753640000,
        "value": 139.5,
        "_internal_originalTime": 1689753640000
    },
    {
        "time": 1689753642000,
        "value": 139.509,
        "_internal_originalTime": 1689753642000
    },
    {
        "time": 1689753643000,
        "value": 139.511,
        "_internal_originalTime": 1689753643000
    },
    {
        "time": 1689753645000,
        "value": 139.509,
        "_internal_originalTime": 1689753645000
    },
    {
        "time": 1689753647000,
        "value": 139.505,
        "_internal_originalTime": 1689753647000
    },
    {
        "time": 1689753648000,
        "value": 139.504,
        "_internal_originalTime": 1689753648000
    },
    {
        "time": 1689753649000,
        "value": 139.504,
        "_internal_originalTime": 1689753649000
    },
    {
        "time": 1689753651000,
        "value": 139.501,
        "_internal_originalTime": 1689753651000
    },
    {
        "time": 1689753652000,
        "value": 139.5,
        "_internal_originalTime": 1689753652000
    },
    {
        "time": 1689753653000,
        "value": 139.502,
        "_internal_originalTime": 1689753653000
    },
    {
        "time": 1689753655000,
        "value": 139.501,
        "_internal_originalTime": 1689753655000
    },
    {
        "time": 1689753657000,
        "value": 139.501,
        "_internal_originalTime": 1689753657000
    },
    {
        "time": 1689753659000,
        "value": 139.497,
        "_internal_originalTime": 1689753659000
    },
    {
        "time": 1689753660000,
        "value": 139.499,
        "_internal_originalTime": 1689753660000
    },
    {
        "time": 1689753661000,
        "value": 139.49,
        "_internal_originalTime": 1689753661000
    },
    {
        "time": 1689753662000,
        "value": 139.489,
        "_internal_originalTime": 1689753662000
    },
    {
        "time": 1689753663000,
        "value": 139.491,
        "_internal_originalTime": 1689753663000
    },
    {
        "time": 1689753665000,
        "value": 139.492,
        "_internal_originalTime": 1689753665000
    },
    {
        "time": 1689753666000,
        "value": 139.491,
        "_internal_originalTime": 1689753666000
    },
    {
        "time": 1689753667000,
        "value": 139.48,
        "_internal_originalTime": 1689753667000
    },
    {
        "time": 1689753668000,
        "value": 139.482,
        "_internal_originalTime": 1689753668000
    },
    {
        "time": 1689753669000,
        "value": 139.484,
        "_internal_originalTime": 1689753669000
    },
    {
        "time": 1689753670000,
        "value": 139.485,
        "_internal_originalTime": 1689753670000
    },
    {
        "time": 1689753672000,
        "value": 139.478,
        "_internal_originalTime": 1689753672000
    },
    {
        "time": 1689753674000,
        "value": 139.47,
        "_internal_originalTime": 1689753674000
    },
    {
        "time": 1689753675000,
        "value": 139.47,
        "_internal_originalTime": 1689753675000
    },
    {
        "time": 1689753676000,
        "value": 139.468,
        "_internal_originalTime": 1689753676000
    },
    {
        "time": 1689753677000,
        "value": 139.466,
        "_internal_originalTime": 1689753677000
    },
    {
        "time": 1689753679000,
        "value": 139.466,
        "_internal_originalTime": 1689753679000
    },
    {
        "time": 1689753680000,
        "value": 139.465,
        "_internal_originalTime": 1689753680000
    },
    {
        "time": 1689753681000,
        "value": 139.465,
        "_internal_originalTime": 1689753681000
    },
    {
        "time": 1689753682000,
        "value": 139.464,
        "_internal_originalTime": 1689753682000
    },
    {
        "time": 1689753683000,
        "value": 139.463,
        "_internal_originalTime": 1689753683000
    },
    {
        "time": 1689753684000,
        "value": 139.462,
        "_internal_originalTime": 1689753684000
    },
    {
        "time": 1689753685000,
        "value": 139.463,
        "_internal_originalTime": 1689753685000
    },
    {
        "time": 1689753686000,
        "value": 139.463,
        "_internal_originalTime": 1689753686000
    },
    {
        "time": 1689753688000,
        "value": 139.463,
        "_internal_originalTime": 1689753688000
    },
    {
        "time": 1689753690000,
        "value": 139.462,
        "_internal_originalTime": 1689753690000
    },
    {
        "time": 1689753691000,
        "value": 139.464,
        "_internal_originalTime": 1689753691000
    },
    {
        "time": 1689753692000,
        "value": 139.464,
        "_internal_originalTime": 1689753692000
    },
    {
        "time": 1689753693000,
        "value": 139.471,
        "_internal_originalTime": 1689753693000
    },
    {
        "time": 1689753694000,
        "value": 139.471,
        "_internal_originalTime": 1689753694000
    },
    {
        "time": 1689753695000,
        "value": 139.467,
        "_internal_originalTime": 1689753695000
    },
    {
        "time": 1689753697000,
        "value": 139.466,
        "_internal_originalTime": 1689753697000
    },
    {
        "time": 1689753698000,
        "value": 139.469,
        "_internal_originalTime": 1689753698000
    },
    {
        "time": 1689753699000,
        "value": 139.467,
        "_internal_originalTime": 1689753699000
    },
    {
        "time": 1689753700000,
        "value": 139.467,
        "_internal_originalTime": 1689753700000
    },
    {
        "time": 1689753701000,
        "value": 139.472,
        "_internal_originalTime": 1689753701000
    },
    {
        "time": 1689753702000,
        "value": 139.467,
        "_internal_originalTime": 1689753702000
    },
    {
        "time": 1689753704000,
        "value": 139.465,
        "_internal_originalTime": 1689753704000
    },
    {
        "time": 1689753705000,
        "value": 139.462,
        "_internal_originalTime": 1689753705000
    },
    {
        "time": 1689753706000,
        "value": 139.466,
        "_internal_originalTime": 1689753706000
    },
    {
        "time": 1689753707000,
        "value": 139.471,
        "_internal_originalTime": 1689753707000
    },
    {
        "time": 1689753708000,
        "value": 139.472,
        "_internal_originalTime": 1689753708000
    },
    {
        "time": 1689753710000,
        "value": 139.467,
        "_internal_originalTime": 1689753710000
    },
    {
        "time": 1689753711000,
        "value": 139.465,
        "_internal_originalTime": 1689753711000
    },
    {
        "time": 1689753712000,
        "value": 139.465,
        "_internal_originalTime": 1689753712000
    },
    {
        "time": 1689753713000,
        "value": 139.462,
        "_internal_originalTime": 1689753713000
    },
    {
        "time": 1689753714000,
        "value": 139.469,
        "_internal_originalTime": 1689753714000
    },
    {
        "time": 1689753715000,
        "value": 139.471,
        "_internal_originalTime": 1689753715000
    },
    {
        "time": 1689753716000,
        "value": 139.481,
        "_internal_originalTime": 1689753716000
    },
    {
        "time": 1689753717000,
        "value": 139.481,
        "_internal_originalTime": 1689753717000
    },
    {
        "time": 1689753718000,
        "value": 139.481,
        "_internal_originalTime": 1689753718000
    },
    {
        "time": 1689753720000,
        "value": 139.481,
        "_internal_originalTime": 1689753720000
    },
    {
        "time": 1689753721000,
        "value": 139.478,
        "_internal_originalTime": 1689753721000
    },
    {
        "time": 1689753722000,
        "value": 139.482,
        "_internal_originalTime": 1689753722000
    },
    {
        "time": 1689753724000,
        "value": 139.478,
        "_internal_originalTime": 1689753724000
    },
    {
        "time": 1689753725000,
        "value": 139.476,
        "_internal_originalTime": 1689753725000
    },
    {
        "time": 1689753727000,
        "value": 139.475,
        "_internal_originalTime": 1689753727000
    },
    {
        "time": 1689753728000,
        "value": 139.479,
        "_internal_originalTime": 1689753728000
    },
    {
        "time": 1689753729000,
        "value": 139.482,
        "_internal_originalTime": 1689753729000
    },
    {
        "time": 1689753730000,
        "value": 139.482,
        "_internal_originalTime": 1689753730000
    },
    {
        "time": 1689753732000,
        "value": 139.483,
        "_internal_originalTime": 1689753732000
    },
    {
        "time": 1689753733000,
        "value": 139.483,
        "_internal_originalTime": 1689753733000
    },
    {
        "time": 1689753735000,
        "value": 139.482,
        "_internal_originalTime": 1689753735000
    },
    {
        "time": 1689753736000,
        "value": 139.48,
        "_internal_originalTime": 1689753736000
    },
    {
        "time": 1689753738000,
        "value": 139.483,
        "_internal_originalTime": 1689753738000
    },
    {
        "time": 1689753741000,
        "value": 139.476,
        "_internal_originalTime": 1689753741000
    },
    {
        "time": 1689753742000,
        "value": 139.476,
        "_internal_originalTime": 1689753742000
    },
    {
        "time": 1689753743000,
        "value": 139.47,
        "_internal_originalTime": 1689753743000
    },
    {
        "time": 1689753744000,
        "value": 139.475,
        "_internal_originalTime": 1689753744000
    },
    {
        "time": 1689753746000,
        "value": 139.472,
        "_internal_originalTime": 1689753746000
    },
    {
        "time": 1689753747000,
        "value": 139.467,
        "_internal_originalTime": 1689753747000
    },
    {
        "time": 1689753748000,
        "value": 139.47,
        "_internal_originalTime": 1689753748000
    },
    {
        "time": 1689753749000,
        "value": 139.469,
        "_internal_originalTime": 1689753749000
    },
    {
        "time": 1689753750000,
        "value": 139.469,
        "_internal_originalTime": 1689753750000
    },
    {
        "time": 1689753751000,
        "value": 139.462,
        "_internal_originalTime": 1689753751000
    },
    {
        "time": 1689753752000,
        "value": 139.457,
        "_internal_originalTime": 1689753752000
    },
    {
        "time": 1689753753000,
        "value": 139.462,
        "_internal_originalTime": 1689753753000
    },
    {
        "time": 1689753754000,
        "value": 139.461,
        "_internal_originalTime": 1689753754000
    },
    {
        "time": 1689753756000,
        "value": 139.461,
        "_internal_originalTime": 1689753756000
    },
    {
        "time": 1689753758000,
        "value": 139.459,
        "_internal_originalTime": 1689753758000
    },
    {
        "time": 1689753759000,
        "value": 139.462,
        "_internal_originalTime": 1689753759000
    },
    {
        "time": 1689753760000,
        "value": 139.461,
        "_internal_originalTime": 1689753760000
    },
    {
        "time": 1689753761000,
        "value": 139.46,
        "_internal_originalTime": 1689753761000
    },
    {
        "time": 1689753763000,
        "value": 139.458,
        "_internal_originalTime": 1689753763000
    },
    {
        "time": 1689753765000,
        "value": 139.461,
        "_internal_originalTime": 1689753765000
    },
    {
        "time": 1689753766000,
        "value": 139.462,
        "_internal_originalTime": 1689753766000
    },
    {
        "time": 1689753768000,
        "value": 139.462,
        "_internal_originalTime": 1689753768000
    },
    {
        "time": 1689753769000,
        "value": 139.464,
        "_internal_originalTime": 1689753769000
    },
    {
        "time": 1689753770000,
        "value": 139.46,
        "_internal_originalTime": 1689753770000
    },
    {
        "time": 1689753771000,
        "value": 139.461,
        "_internal_originalTime": 1689753771000
    },
    {
        "time": 1689753772000,
        "value": 139.462,
        "_internal_originalTime": 1689753772000
    },
    {
        "time": 1689753773000,
        "value": 139.463,
        "_internal_originalTime": 1689753773000
    },
    {
        "time": 1689753774000,
        "value": 139.462,
        "_internal_originalTime": 1689753774000
    },
    {
        "time": 1689753775000,
        "value": 139.461,
        "_internal_originalTime": 1689753775000
    },
    {
        "time": 1689753776000,
        "value": 139.463,
        "_internal_originalTime": 1689753776000
    },
    {
        "time": 1689753777000,
        "value": 139.465,
        "_internal_originalTime": 1689753777000
    },
    {
        "time": 1689753779000,
        "value": 139.461,
        "_internal_originalTime": 1689753779000
    },
    {
        "time": 1689753780000,
        "value": 139.463,
        "_internal_originalTime": 1689753780000
    },
    {
        "time": 1689753782000,
        "value": 139.464,
        "_internal_originalTime": 1689753782000
    },
    {
        "time": 1689753783000,
        "value": 139.465,
        "_internal_originalTime": 1689753783000
    },
    {
        "time": 1689753784000,
        "value": 139.466,
        "_internal_originalTime": 1689753784000
    },
    {
        "time": 1689753785000,
        "value": 139.46,
        "_internal_originalTime": 1689753785000
    },
    {
        "time": 1689753786000,
        "value": 139.463,
        "_internal_originalTime": 1689753786000
    },
    {
        "time": 1689753788000,
        "value": 139.46,
        "_internal_originalTime": 1689753788000
    },
    {
        "time": 1689753789000,
        "value": 139.46,
        "_internal_originalTime": 1689753789000
    },
    {
        "time": 1689753790000,
        "value": 139.456,
        "_internal_originalTime": 1689753790000
    },
    {
        "time": 1689753792000,
        "value": 139.457,
        "_internal_originalTime": 1689753792000
    },
    {
        "time": 1689753793000,
        "value": 139.457,
        "_internal_originalTime": 1689753793000
    },
    {
        "time": 1689753794000,
        "value": 139.46,
        "_internal_originalTime": 1689753794000
    },
    {
        "time": 1689753795000,
        "value": 139.465,
        "_internal_originalTime": 1689753795000
    },
    {
        "time": 1689753797000,
        "value": 139.457,
        "_internal_originalTime": 1689753797000
    },
    {
        "time": 1689753798000,
        "value": 139.463,
        "_internal_originalTime": 1689753798000
    },
    {
        "time": 1689753800000,
        "value": 139.463,
        "_internal_originalTime": 1689753800000
    },
    {
        "time": 1689753801000,
        "value": 139.46,
        "_internal_originalTime": 1689753801000
    },
    {
        "time": 1689753802000,
        "value": 139.466,
        "_internal_originalTime": 1689753802000
    },
    {
        "time": 1689753804000,
        "value": 139.466,
        "_internal_originalTime": 1689753804000
    },
    {
        "time": 1689753805000,
        "value": 139.465,
        "_internal_originalTime": 1689753805000
    },
    {
        "time": 1689753806000,
        "value": 139.465,
        "_internal_originalTime": 1689753806000
    },
    {
        "time": 1689753807000,
        "value": 139.467,
        "_internal_originalTime": 1689753807000
    },
    {
        "time": 1689753808000,
        "value": 139.471,
        "_internal_originalTime": 1689753808000
    },
    {
        "time": 1689753809000,
        "value": 139.467,
        "_internal_originalTime": 1689753809000
    },
    {
        "time": 1689753810000,
        "value": 139.471,
        "_internal_originalTime": 1689753810000
    },
    {
        "time": 1689753812000,
        "value": 139.468,
        "_internal_originalTime": 1689753812000
    },
    {
        "time": 1689753813000,
        "value": 139.465,
        "_internal_originalTime": 1689753813000
    },
    {
        "time": 1689753815000,
        "value": 139.461,
        "_internal_originalTime": 1689753815000
    },
    {
        "time": 1689753816000,
        "value": 139.457,
        "_internal_originalTime": 1689753816000
    },
    {
        "time": 1689753817000,
        "value": 139.457,
        "_internal_originalTime": 1689753817000
    },
    {
        "time": 1689753818000,
        "value": 139.458,
        "_internal_originalTime": 1689753818000
    },
    {
        "time": 1689753820000,
        "value": 139.459,
        "_internal_originalTime": 1689753820000
    },
    {
        "time": 1689753821000,
        "value": 139.456,
        "_internal_originalTime": 1689753821000
    },
    {
        "time": 1689753822000,
        "value": 139.459,
        "_internal_originalTime": 1689753822000
    },
    {
        "time": 1689753823000,
        "value": 139.464,
        "_internal_originalTime": 1689753823000
    },
    {
        "time": 1689753824000,
        "value": 139.466,
        "_internal_originalTime": 1689753824000
    },
    {
        "time": 1689753825000,
        "value": 139.467,
        "_internal_originalTime": 1689753825000
    },
    {
        "time": 1689753827000,
        "value": 139.467,
        "_internal_originalTime": 1689753827000
    },
    {
        "time": 1689753828000,
        "value": 139.466,
        "_internal_originalTime": 1689753828000
    },
    {
        "time": 1689753829000,
        "value": 139.467,
        "_internal_originalTime": 1689753829000
    },
    {
        "time": 1689753830000,
        "value": 139.468,
        "_internal_originalTime": 1689753830000
    },
    {
        "time": 1689753831000,
        "value": 139.467,
        "_internal_originalTime": 1689753831000
    },
    {
        "time": 1689753832000,
        "value": 139.461,
        "_internal_originalTime": 1689753832000
    },
    {
        "time": 1689753833000,
        "value": 139.46,
        "_internal_originalTime": 1689753833000
    },
    {
        "time": 1689753834000,
        "value": 139.463,
        "_internal_originalTime": 1689753834000
    },
    {
        "time": 1689753835000,
        "value": 139.46,
        "_internal_originalTime": 1689753835000
    },
    {
        "time": 1689753838000,
        "value": 139.464,
        "_internal_originalTime": 1689753838000
    },
    {
        "time": 1689753839000,
        "value": 139.462,
        "_internal_originalTime": 1689753839000
    },
    {
        "time": 1689753840000,
        "value": 139.462,
        "_internal_originalTime": 1689753840000
    },
    {
        "time": 1689753841000,
        "value": 139.464,
        "_internal_originalTime": 1689753841000
    },
    {
        "time": 1689753842000,
        "value": 139.46,
        "_internal_originalTime": 1689753842000
    },
    {
        "time": 1689753843000,
        "value": 139.463,
        "_internal_originalTime": 1689753843000
    },
    {
        "time": 1689753844000,
        "value": 139.464,
        "_internal_originalTime": 1689753844000
    },
    {
        "time": 1689753845000,
        "value": 139.464,
        "_internal_originalTime": 1689753845000
    },
    {
        "time": 1689753846000,
        "value": 139.461,
        "_internal_originalTime": 1689753846000
    },
    {
        "time": 1689753847000,
        "value": 139.456,
        "_internal_originalTime": 1689753847000
    },
    {
        "time": 1689753848000,
        "value": 139.457,
        "_internal_originalTime": 1689753848000
    },
    {
        "time": 1689753850000,
        "value": 139.456,
        "_internal_originalTime": 1689753850000
    },
    {
        "time": 1689753851000,
        "value": 139.452,
        "_internal_originalTime": 1689753851000
    },
    {
        "time": 1689753852000,
        "value": 139.457,
        "_internal_originalTime": 1689753852000
    },
    {
        "time": 1689753853000,
        "value": 139.453,
        "_internal_originalTime": 1689753853000
    },
    {
        "time": 1689753855000,
        "value": 139.451,
        "_internal_originalTime": 1689753855000
    },
    {
        "time": 1689753856000,
        "value": 139.453,
        "_internal_originalTime": 1689753856000
    },
    {
        "time": 1689753857000,
        "value": 139.456,
        "_internal_originalTime": 1689753857000
    },
    {
        "time": 1689753858000,
        "value": 139.458,
        "_internal_originalTime": 1689753858000
    },
    {
        "time": 1689753859000,
        "value": 139.458,
        "_internal_originalTime": 1689753859000
    },
    {
        "time": 1689753860000,
        "value": 139.454,
        "_internal_originalTime": 1689753860000
    },
    {
        "time": 1689753861000,
        "value": 139.456,
        "_internal_originalTime": 1689753861000
    },
    {
        "time": 1689753862000,
        "value": 139.456,
        "_internal_originalTime": 1689753862000
    },
    {
        "time": 1689753863000,
        "value": 139.457,
        "_internal_originalTime": 1689753863000
    },
    {
        "time": 1689753864000,
        "value": 139.455,
        "_internal_originalTime": 1689753864000
    },
    {
        "time": 1689753865000,
        "value": 139.456,
        "_internal_originalTime": 1689753865000
    },
    {
        "time": 1689753867000,
        "value": 139.455,
        "_internal_originalTime": 1689753867000
    },
    {
        "time": 1689753868000,
        "value": 139.451,
        "_internal_originalTime": 1689753868000
    },
    {
        "time": 1689753869000,
        "value": 139.452,
        "_internal_originalTime": 1689753869000
    },
    {
        "time": 1689753870000,
        "value": 139.451,
        "_internal_originalTime": 1689753870000
    },
    {
        "time": 1689753872000,
        "value": 139.452,
        "_internal_originalTime": 1689753872000
    },
    {
        "time": 1689753873000,
        "value": 139.452,
        "_internal_originalTime": 1689753873000
    },
    {
        "time": 1689753874000,
        "value": 139.452,
        "_internal_originalTime": 1689753874000
    },
    {
        "time": 1689753875000,
        "value": 139.451,
        "_internal_originalTime": 1689753875000
    },
    {
        "time": 1689753876000,
        "value": 139.451,
        "_internal_originalTime": 1689753876000
    },
    {
        "time": 1689753878000,
        "value": 139.443,
        "_internal_originalTime": 1689753878000
    },
    {
        "time": 1689753880000,
        "value": 139.453,
        "_internal_originalTime": 1689753880000
    },
    {
        "time": 1689753881000,
        "value": 139.444,
        "_internal_originalTime": 1689753881000
    },
    {
        "time": 1689753882000,
        "value": 139.441,
        "_internal_originalTime": 1689753882000
    },
    {
        "time": 1689753883000,
        "value": 139.441,
        "_internal_originalTime": 1689753883000
    },
    {
        "time": 1689753884000,
        "value": 139.436,
        "_internal_originalTime": 1689753884000
    },
    {
        "time": 1689753886000,
        "value": 139.433,
        "_internal_originalTime": 1689753886000
    },
    {
        "time": 1689753887000,
        "value": 139.435,
        "_internal_originalTime": 1689753887000
    },
    {
        "time": 1689753888000,
        "value": 139.435,
        "_internal_originalTime": 1689753888000
    },
    {
        "time": 1689753889000,
        "value": 139.431,
        "_internal_originalTime": 1689753889000
    },
    {
        "time": 1689753890000,
        "value": 139.431,
        "_internal_originalTime": 1689753890000
    },
    {
        "time": 1689753891000,
        "value": 139.433,
        "_internal_originalTime": 1689753891000
    },
    {
        "time": 1689753892000,
        "value": 139.428,
        "_internal_originalTime": 1689753892000
    },
    {
        "time": 1689753893000,
        "value": 139.428,
        "_internal_originalTime": 1689753893000
    },
    {
        "time": 1689753894000,
        "value": 139.43,
        "_internal_originalTime": 1689753894000
    },
    {
        "time": 1689753895000,
        "value": 139.426,
        "_internal_originalTime": 1689753895000
    },
    {
        "time": 1689753896000,
        "value": 139.427,
        "_internal_originalTime": 1689753896000
    },
    {
        "time": 1689753897000,
        "value": 139.424,
        "_internal_originalTime": 1689753897000
    },
    {
        "time": 1689753899000,
        "value": 139.423,
        "_internal_originalTime": 1689753899000
    },
    {
        "time": 1689753900000,
        "value": 139.425,
        "_internal_originalTime": 1689753900000
    },
    {
        "time": 1689753901000,
        "value": 139.43,
        "_internal_originalTime": 1689753901000
    },
    {
        "time": 1689753902000,
        "value": 139.425,
        "_internal_originalTime": 1689753902000
    },
    {
        "time": 1689753903000,
        "value": 139.423,
        "_internal_originalTime": 1689753903000
    },
    {
        "time": 1689753904000,
        "value": 139.426,
        "_internal_originalTime": 1689753904000
    },
    {
        "time": 1689753905000,
        "value": 139.425,
        "_internal_originalTime": 1689753905000
    },
    {
        "time": 1689753906000,
        "value": 139.424,
        "_internal_originalTime": 1689753906000
    },
    {
        "time": 1689753907000,
        "value": 139.423,
        "_internal_originalTime": 1689753907000
    },
    {
        "time": 1689753908000,
        "value": 139.424,
        "_internal_originalTime": 1689753908000
    },
    {
        "time": 1689753909000,
        "value": 139.427,
        "_internal_originalTime": 1689753909000
    },
    {
        "time": 1689753910000,
        "value": 139.428,
        "_internal_originalTime": 1689753910000
    },
    {
        "time": 1689753912000,
        "value": 139.427,
        "_internal_originalTime": 1689753912000
    },
    {
        "time": 1689753913000,
        "value": 139.426,
        "_internal_originalTime": 1689753913000
    },
    {
        "time": 1689753915000,
        "value": 139.424,
        "_internal_originalTime": 1689753915000
    },
    {
        "time": 1689753916000,
        "value": 139.424,
        "_internal_originalTime": 1689753916000
    },
    {
        "time": 1689753917000,
        "value": 139.422,
        "_internal_originalTime": 1689753917000
    },
    {
        "time": 1689753918000,
        "value": 139.426,
        "_internal_originalTime": 1689753918000
    },
    {
        "time": 1689753919000,
        "value": 139.427,
        "_internal_originalTime": 1689753919000
    },
    {
        "time": 1689753921000,
        "value": 139.423,
        "_internal_originalTime": 1689753921000
    },
    {
        "time": 1689753923000,
        "value": 139.426,
        "_internal_originalTime": 1689753923000
    },
    {
        "time": 1689753924000,
        "value": 139.425,
        "_internal_originalTime": 1689753924000
    },
    {
        "time": 1689753926000,
        "value": 139.417,
        "_internal_originalTime": 1689753926000
    },
    {
        "time": 1689753928000,
        "value": 139.424,
        "_internal_originalTime": 1689753928000
    },
    {
        "time": 1689753929000,
        "value": 139.431,
        "_internal_originalTime": 1689753929000
    },
    {
        "time": 1689753930000,
        "value": 139.432,
        "_internal_originalTime": 1689753930000
    },
    {
        "time": 1689753931000,
        "value": 139.428,
        "_internal_originalTime": 1689753931000
    },
    {
        "time": 1689753932000,
        "value": 139.428,
        "_internal_originalTime": 1689753932000
    },
    {
        "time": 1689753933000,
        "value": 139.431,
        "_internal_originalTime": 1689753933000
    },
    {
        "time": 1689753934000,
        "value": 139.431,
        "_internal_originalTime": 1689753934000
    },
    {
        "time": 1689753936000,
        "value": 139.433,
        "_internal_originalTime": 1689753936000
    },
    {
        "time": 1689753938000,
        "value": 139.432,
        "_internal_originalTime": 1689753938000
    },
    {
        "time": 1689753939000,
        "value": 139.432,
        "_internal_originalTime": 1689753939000
    },
    {
        "time": 1689753940000,
        "value": 139.424,
        "_internal_originalTime": 1689753940000
    },
    {
        "time": 1689753941000,
        "value": 139.424,
        "_internal_originalTime": 1689753941000
    },
    {
        "time": 1689753943000,
        "value": 139.427,
        "_internal_originalTime": 1689753943000
    },
    {
        "time": 1689753944000,
        "value": 139.432,
        "_internal_originalTime": 1689753944000
    },
    {
        "time": 1689753945000,
        "value": 139.437,
        "_internal_originalTime": 1689753945000
    },
    {
        "time": 1689753946000,
        "value": 139.432,
        "_internal_originalTime": 1689753946000
    },
    {
        "time": 1689753947000,
        "value": 139.435,
        "_internal_originalTime": 1689753947000
    },
    {
        "time": 1689753950000,
        "value": 139.435,
        "_internal_originalTime": 1689753950000
    },
    {
        "time": 1689753951000,
        "value": 139.437,
        "_internal_originalTime": 1689753951000
    },
    {
        "time": 1689753952000,
        "value": 139.437,
        "_internal_originalTime": 1689753952000
    },
    {
        "time": 1689753953000,
        "value": 139.443,
        "_internal_originalTime": 1689753953000
    },
    {
        "time": 1689753954000,
        "value": 139.442,
        "_internal_originalTime": 1689753954000
    },
    {
        "time": 1689753956000,
        "value": 139.443,
        "_internal_originalTime": 1689753956000
    },
    {
        "time": 1689753957000,
        "value": 139.444,
        "_internal_originalTime": 1689753957000
    },
    {
        "time": 1689753958000,
        "value": 139.442,
        "_internal_originalTime": 1689753958000
    },
    {
        "time": 1689753959000,
        "value": 139.449,
        "_internal_originalTime": 1689753959000
    },
    {
        "time": 1689753961000,
        "value": 139.449,
        "_internal_originalTime": 1689753961000
    },
    {
        "time": 1689753962000,
        "value": 139.44,
        "_internal_originalTime": 1689753962000
    },
    {
        "time": 1689753964000,
        "value": 139.446,
        "_internal_originalTime": 1689753964000
    },
    {
        "time": 1689753965000,
        "value": 139.447,
        "_internal_originalTime": 1689753965000
    },
    {
        "time": 1689753968000,
        "value": 139.445,
        "_internal_originalTime": 1689753968000
    },
    {
        "time": 1689753969000,
        "value": 139.442,
        "_internal_originalTime": 1689753969000
    },
    {
        "time": 1689753970000,
        "value": 139.442,
        "_internal_originalTime": 1689753970000
    },
    {
        "time": 1689753972000,
        "value": 139.447,
        "_internal_originalTime": 1689753972000
    },
    {
        "time": 1689753973000,
        "value": 139.448,
        "_internal_originalTime": 1689753973000
    },
    {
        "time": 1689753974000,
        "value": 139.447,
        "_internal_originalTime": 1689753974000
    },
    {
        "time": 1689753975000,
        "value": 139.444,
        "_internal_originalTime": 1689753975000
    },
    {
        "time": 1689753976000,
        "value": 139.448,
        "_internal_originalTime": 1689753976000
    },
    {
        "time": 1689753977000,
        "value": 139.444,
        "_internal_originalTime": 1689753977000
    },
    {
        "time": 1689753978000,
        "value": 139.446,
        "_internal_originalTime": 1689753978000
    },
    {
        "time": 1689753979000,
        "value": 139.446,
        "_internal_originalTime": 1689753979000
    },
    {
        "time": 1689753980000,
        "value": 139.448,
        "_internal_originalTime": 1689753980000
    },
    {
        "time": 1689753981000,
        "value": 139.445,
        "_internal_originalTime": 1689753981000
    },
    {
        "time": 1689753982000,
        "value": 139.451,
        "_internal_originalTime": 1689753982000
    },
    {
        "time": 1689753984000,
        "value": 139.451,
        "_internal_originalTime": 1689753984000
    },
    {
        "time": 1689753986000,
        "value": 139.452,
        "_internal_originalTime": 1689753986000
    },
    {
        "time": 1689753987000,
        "value": 139.457,
        "_internal_originalTime": 1689753987000
    },
    {
        "time": 1689753988000,
        "value": 139.457,
        "_internal_originalTime": 1689753988000
    },
    {
        "time": 1689753989000,
        "value": 139.459,
        "_internal_originalTime": 1689753989000
    },
    {
        "time": 1689753990000,
        "value": 139.46,
        "_internal_originalTime": 1689753990000
    },
    {
        "time": 1689753991000,
        "value": 139.458,
        "_internal_originalTime": 1689753991000
    },
    {
        "time": 1689753993000,
        "value": 139.454,
        "_internal_originalTime": 1689753993000
    },
    {
        "time": 1689753994000,
        "value": 139.455,
        "_internal_originalTime": 1689753994000
    },
    {
        "time": 1689753996000,
        "value": 139.45,
        "_internal_originalTime": 1689753996000
    },
    {
        "time": 1689753998000,
        "value": 139.453,
        "_internal_originalTime": 1689753998000
    },
    {
        "time": 1689753999000,
        "value": 139.453,
        "_internal_originalTime": 1689753999000
    },
    {
        "time": 1689754000000,
        "value": 139.452,
        "_internal_originalTime": 1689754000000
    },
    {
        "time": 1689754001000,
        "value": 139.452,
        "_internal_originalTime": 1689754001000
    },
    {
        "time": 1689754002000,
        "value": 139.448,
        "_internal_originalTime": 1689754002000
    },
    {
        "time": 1689754004000,
        "value": 139.456,
        "_internal_originalTime": 1689754004000
    },
    {
        "time": 1689754005000,
        "value": 139.457,
        "_internal_originalTime": 1689754005000
    },
    {
        "time": 1689754006000,
        "value": 139.456,
        "_internal_originalTime": 1689754006000
    },
    {
        "time": 1689754008000,
        "value": 139.451,
        "_internal_originalTime": 1689754008000
    },
    {
        "time": 1689754009000,
        "value": 139.451,
        "_internal_originalTime": 1689754009000
    },
    {
        "time": 1689754010000,
        "value": 139.444,
        "_internal_originalTime": 1689754010000
    },
    {
        "time": 1689754012000,
        "value": 139.443,
        "_internal_originalTime": 1689754012000
    },
    {
        "time": 1689754014000,
        "value": 139.443,
        "_internal_originalTime": 1689754014000
    },
    {
        "time": 1689754015000,
        "value": 139.443,
        "_internal_originalTime": 1689754015000
    },
    {
        "time": 1689754016000,
        "value": 139.448,
        "_internal_originalTime": 1689754016000
    },
    {
        "time": 1689754019000,
        "value": 139.451,
        "_internal_originalTime": 1689754019000
    },
    {
        "time": 1689754022000,
        "value": 139.454,
        "_internal_originalTime": 1689754022000
    },
    {
        "time": 1689754023000,
        "value": 139.457,
        "_internal_originalTime": 1689754023000
    },
    {
        "time": 1689754024000,
        "value": 139.451,
        "_internal_originalTime": 1689754024000
    },
    {
        "time": 1689754025000,
        "value": 139.455,
        "_internal_originalTime": 1689754025000
    },
    {
        "time": 1689754026000,
        "value": 139.453,
        "_internal_originalTime": 1689754026000
    },
    {
        "time": 1689754028000,
        "value": 139.454,
        "_internal_originalTime": 1689754028000
    },
    {
        "time": 1689754029000,
        "value": 139.455,
        "_internal_originalTime": 1689754029000
    },
    {
        "time": 1689754030000,
        "value": 139.452,
        "_internal_originalTime": 1689754030000
    },
    {
        "time": 1689754031000,
        "value": 139.455,
        "_internal_originalTime": 1689754031000
    },
    {
        "time": 1689754032000,
        "value": 139.453,
        "_internal_originalTime": 1689754032000
    },
    {
        "time": 1689754034000,
        "value": 139.445,
        "_internal_originalTime": 1689754034000
    },
    {
        "time": 1689754035000,
        "value": 139.449,
        "_internal_originalTime": 1689754035000
    },
    {
        "time": 1689754036000,
        "value": 139.448,
        "_internal_originalTime": 1689754036000
    },
    {
        "time": 1689754037000,
        "value": 139.451,
        "_internal_originalTime": 1689754037000
    },
    {
        "time": 1689754038000,
        "value": 139.45,
        "_internal_originalTime": 1689754038000
    },
    {
        "time": 1689754039000,
        "value": 139.45,
        "_internal_originalTime": 1689754039000
    },
    {
        "time": 1689754040000,
        "value": 139.445,
        "_internal_originalTime": 1689754040000
    },
    {
        "time": 1689754042000,
        "value": 139.448,
        "_internal_originalTime": 1689754042000
    },
    {
        "time": 1689754044000,
        "value": 139.444,
        "_internal_originalTime": 1689754044000
    },
    {
        "time": 1689754045000,
        "value": 139.446,
        "_internal_originalTime": 1689754045000
    },
    {
        "time": 1689754046000,
        "value": 139.448,
        "_internal_originalTime": 1689754046000
    },
    {
        "time": 1689754048000,
        "value": 139.447,
        "_internal_originalTime": 1689754048000
    },
    {
        "time": 1689754049000,
        "value": 139.454,
        "_internal_originalTime": 1689754049000
    },
    {
        "time": 1689754050000,
        "value": 139.456,
        "_internal_originalTime": 1689754050000
    },
    {
        "time": 1689754051000,
        "value": 139.453,
        "_internal_originalTime": 1689754051000
    },
    {
        "time": 1689754052000,
        "value": 139.456,
        "_internal_originalTime": 1689754052000
    },
    {
        "time": 1689754053000,
        "value": 139.455,
        "_internal_originalTime": 1689754053000
    },
    {
        "time": 1689754055000,
        "value": 139.456,
        "_internal_originalTime": 1689754055000
    },
    {
        "time": 1689754056000,
        "value": 139.456,
        "_internal_originalTime": 1689754056000
    },
    {
        "time": 1689754058000,
        "value": 139.456,
        "_internal_originalTime": 1689754058000
    },
    {
        "time": 1689754059000,
        "value": 139.457,
        "_internal_originalTime": 1689754059000
    },
    {
        "time": 1689754060000,
        "value": 139.457,
        "_internal_originalTime": 1689754060000
    },
    {
        "time": 1689754061000,
        "value": 139.453,
        "_internal_originalTime": 1689754061000
    },
    {
        "time": 1689754062000,
        "value": 139.456,
        "_internal_originalTime": 1689754062000
    },
    {
        "time": 1689754063000,
        "value": 139.456,
        "_internal_originalTime": 1689754063000
    },
    {
        "time": 1689754064000,
        "value": 139.457,
        "_internal_originalTime": 1689754064000
    },
    {
        "time": 1689754065000,
        "value": 139.458,
        "_internal_originalTime": 1689754065000
    },
    {
        "time": 1689754066000,
        "value": 139.453,
        "_internal_originalTime": 1689754066000
    },
    {
        "time": 1689754067000,
        "value": 139.455,
        "_internal_originalTime": 1689754067000
    },
    {
        "time": 1689754069000,
        "value": 139.456,
        "_internal_originalTime": 1689754069000
    },
    {
        "time": 1689754071000,
        "value": 139.456,
        "_internal_originalTime": 1689754071000
    },
    {
        "time": 1689754072000,
        "value": 139.467,
        "_internal_originalTime": 1689754072000
    },
    {
        "time": 1689754073000,
        "value": 139.474,
        "_internal_originalTime": 1689754073000
    },
    {
        "time": 1689754074000,
        "value": 139.477,
        "_internal_originalTime": 1689754074000
    },
    {
        "time": 1689754075000,
        "value": 139.479,
        "_internal_originalTime": 1689754075000
    },
    {
        "time": 1689754076000,
        "value": 139.481,
        "_internal_originalTime": 1689754076000
    },
    {
        "time": 1689754077000,
        "value": 139.48,
        "_internal_originalTime": 1689754077000
    },
    {
        "time": 1689754079000,
        "value": 139.475,
        "_internal_originalTime": 1689754079000
    },
    {
        "time": 1689754081000,
        "value": 139.462,
        "_internal_originalTime": 1689754081000
    },
    {
        "time": 1689754082000,
        "value": 139.461,
        "_internal_originalTime": 1689754082000
    },
    {
        "time": 1689754083000,
        "value": 139.462,
        "_internal_originalTime": 1689754083000
    },
    {
        "time": 1689754084000,
        "value": 139.46,
        "_internal_originalTime": 1689754084000
    },
    {
        "time": 1689754085000,
        "value": 139.461,
        "_internal_originalTime": 1689754085000
    },
    {
        "time": 1689754086000,
        "value": 139.456,
        "_internal_originalTime": 1689754086000
    },
    {
        "time": 1689754087000,
        "value": 139.458,
        "_internal_originalTime": 1689754087000
    },
    {
        "time": 1689754089000,
        "value": 139.451,
        "_internal_originalTime": 1689754089000
    },
    {
        "time": 1689754090000,
        "value": 139.447,
        "_internal_originalTime": 1689754090000
    },
    {
        "time": 1689754092000,
        "value": 139.441,
        "_internal_originalTime": 1689754092000
    },
    {
        "time": 1689754093000,
        "value": 139.451,
        "_internal_originalTime": 1689754093000
    },
    {
        "time": 1689754094000,
        "value": 139.449,
        "_internal_originalTime": 1689754094000
    },
    {
        "time": 1689754095000,
        "value": 139.452,
        "_internal_originalTime": 1689754095000
    },
    {
        "time": 1689754096000,
        "value": 139.453,
        "_internal_originalTime": 1689754096000
    },
    {
        "time": 1689754097000,
        "value": 139.45,
        "_internal_originalTime": 1689754097000
    },
    {
        "time": 1689754099000,
        "value": 139.455,
        "_internal_originalTime": 1689754099000
    },
    {
        "time": 1689754100000,
        "value": 139.456,
        "_internal_originalTime": 1689754100000
    },
    {
        "time": 1689754101000,
        "value": 139.453,
        "_internal_originalTime": 1689754101000
    },
    {
        "time": 1689754102000,
        "value": 139.45,
        "_internal_originalTime": 1689754102000
    },
    {
        "time": 1689754104000,
        "value": 139.435,
        "_internal_originalTime": 1689754104000
    },
    {
        "time": 1689754105000,
        "value": 139.437,
        "_internal_originalTime": 1689754105000
    },
    {
        "time": 1689754107000,
        "value": 139.435,
        "_internal_originalTime": 1689754107000
    },
    {
        "time": 1689754108000,
        "value": 139.437,
        "_internal_originalTime": 1689754108000
    },
    {
        "time": 1689754110000,
        "value": 139.436,
        "_internal_originalTime": 1689754110000
    },
    {
        "time": 1689754111000,
        "value": 139.437,
        "_internal_originalTime": 1689754111000
    },
    {
        "time": 1689754113000,
        "value": 139.435,
        "_internal_originalTime": 1689754113000
    },
    {
        "time": 1689754114000,
        "value": 139.432,
        "_internal_originalTime": 1689754114000
    },
    {
        "time": 1689754115000,
        "value": 139.435,
        "_internal_originalTime": 1689754115000
    },
    {
        "time": 1689754116000,
        "value": 139.435,
        "_internal_originalTime": 1689754116000
    },
    {
        "time": 1689754117000,
        "value": 139.437,
        "_internal_originalTime": 1689754117000
    },
    {
        "time": 1689754118000,
        "value": 139.434,
        "_internal_originalTime": 1689754118000
    },
    {
        "time": 1689754119000,
        "value": 139.425,
        "_internal_originalTime": 1689754119000
    },
    {
        "time": 1689754120000,
        "value": 139.427,
        "_internal_originalTime": 1689754120000
    },
    {
        "time": 1689754122000,
        "value": 139.425,
        "_internal_originalTime": 1689754122000
    },
    {
        "time": 1689754123000,
        "value": 139.425,
        "_internal_originalTime": 1689754123000
    },
    {
        "time": 1689754124000,
        "value": 139.416,
        "_internal_originalTime": 1689754124000
    },
    {
        "time": 1689754125000,
        "value": 139.416,
        "_internal_originalTime": 1689754125000
    },
    {
        "time": 1689754126000,
        "value": 139.412,
        "_internal_originalTime": 1689754126000
    },
    {
        "time": 1689754127000,
        "value": 139.415,
        "_internal_originalTime": 1689754127000
    },
    {
        "time": 1689754128000,
        "value": 139.41,
        "_internal_originalTime": 1689754128000
    },
    {
        "time": 1689754129000,
        "value": 139.409,
        "_internal_originalTime": 1689754129000
    },
    {
        "time": 1689754131000,
        "value": 139.41,
        "_internal_originalTime": 1689754131000
    },
    {
        "time": 1689754132000,
        "value": 139.416,
        "_internal_originalTime": 1689754132000
    },
    {
        "time": 1689754133000,
        "value": 139.412,
        "_internal_originalTime": 1689754133000
    },
    {
        "time": 1689754134000,
        "value": 139.416,
        "_internal_originalTime": 1689754134000
    },
    {
        "time": 1689754135000,
        "value": 139.412,
        "_internal_originalTime": 1689754135000
    },
    {
        "time": 1689754136000,
        "value": 139.416,
        "_internal_originalTime": 1689754136000
    },
    {
        "time": 1689754139000,
        "value": 139.416,
        "_internal_originalTime": 1689754139000
    },
    {
        "time": 1689754140000,
        "value": 139.416,
        "_internal_originalTime": 1689754140000
    },
    {
        "time": 1689754142000,
        "value": 139.417,
        "_internal_originalTime": 1689754142000
    },
    {
        "time": 1689754143000,
        "value": 139.411,
        "_internal_originalTime": 1689754143000
    },
    {
        "time": 1689754145000,
        "value": 139.411,
        "_internal_originalTime": 1689754145000
    },
    {
        "time": 1689754146000,
        "value": 139.412,
        "_internal_originalTime": 1689754146000
    },
    {
        "time": 1689754147000,
        "value": 139.416,
        "_internal_originalTime": 1689754147000
    },
    {
        "time": 1689754148000,
        "value": 139.412,
        "_internal_originalTime": 1689754148000
    },
    {
        "time": 1689754149000,
        "value": 139.419,
        "_internal_originalTime": 1689754149000
    },
    {
        "time": 1689754150000,
        "value": 139.423,
        "_internal_originalTime": 1689754150000
    },
    {
        "time": 1689754151000,
        "value": 139.425,
        "_internal_originalTime": 1689754151000
    },
    {
        "time": 1689754152000,
        "value": 139.425,
        "_internal_originalTime": 1689754152000
    },
    {
        "time": 1689754154000,
        "value": 139.431,
        "_internal_originalTime": 1689754154000
    },
    {
        "time": 1689754156000,
        "value": 139.432,
        "_internal_originalTime": 1689754156000
    },
    {
        "time": 1689754157000,
        "value": 139.434,
        "_internal_originalTime": 1689754157000
    },
    {
        "time": 1689754158000,
        "value": 139.44,
        "_internal_originalTime": 1689754158000
    },
    {
        "time": 1689754159000,
        "value": 139.44,
        "_internal_originalTime": 1689754159000
    },
    {
        "time": 1689754161000,
        "value": 139.443,
        "_internal_originalTime": 1689754161000
    },
    {
        "time": 1689754163000,
        "value": 139.456,
        "_internal_originalTime": 1689754163000
    },
    {
        "time": 1689754164000,
        "value": 139.451,
        "_internal_originalTime": 1689754164000
    },
    {
        "time": 1689754165000,
        "value": 139.452,
        "_internal_originalTime": 1689754165000
    },
    {
        "time": 1689754166000,
        "value": 139.45,
        "_internal_originalTime": 1689754166000
    },
    {
        "time": 1689754167000,
        "value": 139.456,
        "_internal_originalTime": 1689754167000
    },
    {
        "time": 1689754168000,
        "value": 139.456,
        "_internal_originalTime": 1689754168000
    },
    {
        "time": 1689754169000,
        "value": 139.455,
        "_internal_originalTime": 1689754169000
    },
    {
        "time": 1689754170000,
        "value": 139.452,
        "_internal_originalTime": 1689754170000
    },
    {
        "time": 1689754171000,
        "value": 139.455,
        "_internal_originalTime": 1689754171000
    },
    {
        "time": 1689754172000,
        "value": 139.458,
        "_internal_originalTime": 1689754172000
    },
    {
        "time": 1689754173000,
        "value": 139.446,
        "_internal_originalTime": 1689754173000
    },
    {
        "time": 1689754174000,
        "value": 139.45,
        "_internal_originalTime": 1689754174000
    },
    {
        "time": 1689754175000,
        "value": 139.45,
        "_internal_originalTime": 1689754175000
    },
    {
        "time": 1689754176000,
        "value": 139.452,
        "_internal_originalTime": 1689754176000
    },
    {
        "time": 1689754177000,
        "value": 139.454,
        "_internal_originalTime": 1689754177000
    },
    {
        "time": 1689754179000,
        "value": 139.452,
        "_internal_originalTime": 1689754179000
    },
    {
        "time": 1689754180000,
        "value": 139.455,
        "_internal_originalTime": 1689754180000
    },
    {
        "time": 1689754181000,
        "value": 139.452,
        "_internal_originalTime": 1689754181000
    },
    {
        "time": 1689754182000,
        "value": 139.453,
        "_internal_originalTime": 1689754182000
    },
    {
        "time": 1689754183000,
        "value": 139.452,
        "_internal_originalTime": 1689754183000
    },
    {
        "time": 1689754185000,
        "value": 139.457,
        "_internal_originalTime": 1689754185000
    },
    {
        "time": 1689754186000,
        "value": 139.452,
        "_internal_originalTime": 1689754186000
    },
    {
        "time": 1689754187000,
        "value": 139.455,
        "_internal_originalTime": 1689754187000
    },
    {
        "time": 1689754188000,
        "value": 139.454,
        "_internal_originalTime": 1689754188000
    },
    {
        "time": 1689754189000,
        "value": 139.449,
        "_internal_originalTime": 1689754189000
    },
    {
        "time": 1689754190000,
        "value": 139.447,
        "_internal_originalTime": 1689754190000
    },
    {
        "time": 1689754191000,
        "value": 139.442,
        "_internal_originalTime": 1689754191000
    },
    {
        "time": 1689754192000,
        "value": 139.446,
        "_internal_originalTime": 1689754192000
    },
    {
        "time": 1689754193000,
        "value": 139.443,
        "_internal_originalTime": 1689754193000
    },
    {
        "time": 1689754194000,
        "value": 139.444,
        "_internal_originalTime": 1689754194000
    },
    {
        "time": 1689754195000,
        "value": 139.446,
        "_internal_originalTime": 1689754195000
    },
    {
        "time": 1689754196000,
        "value": 139.444,
        "_internal_originalTime": 1689754196000
    },
    {
        "time": 1689754197000,
        "value": 139.444,
        "_internal_originalTime": 1689754197000
    },
    {
        "time": 1689754199000,
        "value": 139.448,
        "_internal_originalTime": 1689754199000
    },
    {
        "time": 1689754200000,
        "value": 139.443,
        "_internal_originalTime": 1689754200000
    },
    {
        "time": 1689754201000,
        "value": 139.444,
        "_internal_originalTime": 1689754201000
    },
    {
        "time": 1689754202000,
        "value": 139.444,
        "_internal_originalTime": 1689754202000
    },
    {
        "time": 1689754203000,
        "value": 139.441,
        "_internal_originalTime": 1689754203000
    },
    {
        "time": 1689754205000,
        "value": 139.443,
        "_internal_originalTime": 1689754205000
    },
    {
        "time": 1689754206000,
        "value": 139.444,
        "_internal_originalTime": 1689754206000
    },
    {
        "time": 1689754207000,
        "value": 139.448,
        "_internal_originalTime": 1689754207000
    },
    {
        "time": 1689754208000,
        "value": 139.447,
        "_internal_originalTime": 1689754208000
    },
    {
        "time": 1689754209000,
        "value": 139.444,
        "_internal_originalTime": 1689754209000
    },
    {
        "time": 1689754210000,
        "value": 139.447,
        "_internal_originalTime": 1689754210000
    },
    {
        "time": 1689754213000,
        "value": 139.447,
        "_internal_originalTime": 1689754213000
    },
    {
        "time": 1689754214000,
        "value": 139.446,
        "_internal_originalTime": 1689754214000
    },
    {
        "time": 1689754215000,
        "value": 139.446,
        "_internal_originalTime": 1689754215000
    },
    {
        "time": 1689754216000,
        "value": 139.447,
        "_internal_originalTime": 1689754216000
    },
    {
        "time": 1689754217000,
        "value": 139.45,
        "_internal_originalTime": 1689754217000
    },
    {
        "time": 1689754219000,
        "value": 139.449,
        "_internal_originalTime": 1689754219000
    },
    {
        "time": 1689754221000,
        "value": 139.447,
        "_internal_originalTime": 1689754221000
    },
    {
        "time": 1689754222000,
        "value": 139.446,
        "_internal_originalTime": 1689754222000
    },
    {
        "time": 1689754223000,
        "value": 139.446,
        "_internal_originalTime": 1689754223000
    },
    {
        "time": 1689754226000,
        "value": 139.445,
        "_internal_originalTime": 1689754226000
    },
    {
        "time": 1689754227000,
        "value": 139.447,
        "_internal_originalTime": 1689754227000
    },
    {
        "time": 1689754228000,
        "value": 139.449,
        "_internal_originalTime": 1689754228000
    },
    {
        "time": 1689754230000,
        "value": 139.451,
        "_internal_originalTime": 1689754230000
    },
    {
        "time": 1689754232000,
        "value": 139.449,
        "_internal_originalTime": 1689754232000
    },
    {
        "time": 1689754233000,
        "value": 139.448,
        "_internal_originalTime": 1689754233000
    },
    {
        "time": 1689754234000,
        "value": 139.452,
        "_internal_originalTime": 1689754234000
    },
    {
        "time": 1689754235000,
        "value": 139.451,
        "_internal_originalTime": 1689754235000
    },
    {
        "time": 1689754236000,
        "value": 139.459,
        "_internal_originalTime": 1689754236000
    },
    {
        "time": 1689754237000,
        "value": 139.455,
        "_internal_originalTime": 1689754237000
    },
    {
        "time": 1689754238000,
        "value": 139.45,
        "_internal_originalTime": 1689754238000
    },
    {
        "time": 1689754239000,
        "value": 139.452,
        "_internal_originalTime": 1689754239000
    },
    {
        "time": 1689754240000,
        "value": 139.454,
        "_internal_originalTime": 1689754240000
    },
    {
        "time": 1689754242000,
        "value": 139.452,
        "_internal_originalTime": 1689754242000
    },
    {
        "time": 1689754245000,
        "value": 139.451,
        "_internal_originalTime": 1689754245000
    },
    {
        "time": 1689754246000,
        "value": 139.454,
        "_internal_originalTime": 1689754246000
    },
    {
        "time": 1689754248000,
        "value": 139.454,
        "_internal_originalTime": 1689754248000
    },
    {
        "time": 1689754250000,
        "value": 139.459,
        "_internal_originalTime": 1689754250000
    },
    {
        "time": 1689754252000,
        "value": 139.466,
        "_internal_originalTime": 1689754252000
    },
    {
        "time": 1689754253000,
        "value": 139.466,
        "_internal_originalTime": 1689754253000
    },
    {
        "time": 1689754254000,
        "value": 139.47,
        "_internal_originalTime": 1689754254000
    },
    {
        "time": 1689754255000,
        "value": 139.472,
        "_internal_originalTime": 1689754255000
    },
    {
        "time": 1689754256000,
        "value": 139.472,
        "_internal_originalTime": 1689754256000
    },
    {
        "time": 1689754257000,
        "value": 139.471,
        "_internal_originalTime": 1689754257000
    },
    {
        "time": 1689754258000,
        "value": 139.471,
        "_internal_originalTime": 1689754258000
    },
    {
        "time": 1689754260000,
        "value": 139.472,
        "_internal_originalTime": 1689754260000
    },
    {
        "time": 1689754261000,
        "value": 139.469,
        "_internal_originalTime": 1689754261000
    },
    {
        "time": 1689754262000,
        "value": 139.466,
        "_internal_originalTime": 1689754262000
    },
    {
        "time": 1689754263000,
        "value": 139.471,
        "_internal_originalTime": 1689754263000
    },
    {
        "time": 1689754265000,
        "value": 139.47,
        "_internal_originalTime": 1689754265000
    },
    {
        "time": 1689754266000,
        "value": 139.461,
        "_internal_originalTime": 1689754266000
    },
    {
        "time": 1689754268000,
        "value": 139.461,
        "_internal_originalTime": 1689754268000
    },
    {
        "time": 1689754269000,
        "value": 139.458,
        "_internal_originalTime": 1689754269000
    },
    {
        "time": 1689754270000,
        "value": 139.454,
        "_internal_originalTime": 1689754270000
    },
    {
        "time": 1689754271000,
        "value": 139.454,
        "_internal_originalTime": 1689754271000
    },
    {
        "time": 1689754272000,
        "value": 139.46,
        "_internal_originalTime": 1689754272000
    },
    {
        "time": 1689754273000,
        "value": 139.453,
        "_internal_originalTime": 1689754273000
    },
    {
        "time": 1689754274000,
        "value": 139.45,
        "_internal_originalTime": 1689754274000
    },
    {
        "time": 1689754276000,
        "value": 139.454,
        "_internal_originalTime": 1689754276000
    },
    {
        "time": 1689754277000,
        "value": 139.458,
        "_internal_originalTime": 1689754277000
    },
    {
        "time": 1689754279000,
        "value": 139.473,
        "_internal_originalTime": 1689754279000
    },
    {
        "time": 1689754280000,
        "value": 139.471,
        "_internal_originalTime": 1689754280000
    },
    {
        "time": 1689754281000,
        "value": 139.47,
        "_internal_originalTime": 1689754281000
    },
    {
        "time": 1689754282000,
        "value": 139.471,
        "_internal_originalTime": 1689754282000
    },
    {
        "time": 1689754283000,
        "value": 139.47,
        "_internal_originalTime": 1689754283000
    },
    {
        "time": 1689754284000,
        "value": 139.476,
        "_internal_originalTime": 1689754284000
    },
    {
        "time": 1689754285000,
        "value": 139.471,
        "_internal_originalTime": 1689754285000
    },
    {
        "time": 1689754286000,
        "value": 139.476,
        "_internal_originalTime": 1689754286000
    },
    {
        "time": 1689754288000,
        "value": 139.471,
        "_internal_originalTime": 1689754288000
    },
    {
        "time": 1689754289000,
        "value": 139.472,
        "_internal_originalTime": 1689754289000
    },
    {
        "time": 1689754290000,
        "value": 139.472,
        "_internal_originalTime": 1689754290000
    },
    {
        "time": 1689754291000,
        "value": 139.474,
        "_internal_originalTime": 1689754291000
    },
    {
        "time": 1689754293000,
        "value": 139.472,
        "_internal_originalTime": 1689754293000
    },
    {
        "time": 1689754294000,
        "value": 139.475,
        "_internal_originalTime": 1689754294000
    },
    {
        "time": 1689754295000,
        "value": 139.473,
        "_internal_originalTime": 1689754295000
    },
    {
        "time": 1689754297000,
        "value": 139.472,
        "_internal_originalTime": 1689754297000
    },
    {
        "time": 1689754298000,
        "value": 139.483,
        "_internal_originalTime": 1689754298000
    },
    {
        "time": 1689754300000,
        "value": 139.482,
        "_internal_originalTime": 1689754300000
    },
    {
        "time": 1689754302000,
        "value": 139.486,
        "_internal_originalTime": 1689754302000
    },
    {
        "time": 1689754303000,
        "value": 139.485,
        "_internal_originalTime": 1689754303000
    },
    {
        "time": 1689754304000,
        "value": 139.483,
        "_internal_originalTime": 1689754304000
    },
    {
        "time": 1689754305000,
        "value": 139.481,
        "_internal_originalTime": 1689754305000
    },
    {
        "time": 1689754306000,
        "value": 139.482,
        "_internal_originalTime": 1689754306000
    },
    {
        "time": 1689754307000,
        "value": 139.48,
        "_internal_originalTime": 1689754307000
    },
    {
        "time": 1689754308000,
        "value": 139.482,
        "_internal_originalTime": 1689754308000
    },
    {
        "time": 1689754309000,
        "value": 139.479,
        "_internal_originalTime": 1689754309000
    },
    {
        "time": 1689754311000,
        "value": 139.476,
        "_internal_originalTime": 1689754311000
    },
    {
        "time": 1689754313000,
        "value": 139.471,
        "_internal_originalTime": 1689754313000
    },
    {
        "time": 1689754314000,
        "value": 139.469,
        "_internal_originalTime": 1689754314000
    },
    {
        "time": 1689754315000,
        "value": 139.474,
        "_internal_originalTime": 1689754315000
    },
    {
        "time": 1689754317000,
        "value": 139.476,
        "_internal_originalTime": 1689754317000
    },
    {
        "time": 1689754318000,
        "value": 139.47,
        "_internal_originalTime": 1689754318000
    },
    {
        "time": 1689754320000,
        "value": 139.47,
        "_internal_originalTime": 1689754320000
    },
    {
        "time": 1689754321000,
        "value": 139.475,
        "_internal_originalTime": 1689754321000
    },
    {
        "time": 1689754322000,
        "value": 139.474,
        "_internal_originalTime": 1689754322000
    },
    {
        "time": 1689754323000,
        "value": 139.477,
        "_internal_originalTime": 1689754323000
    },
    {
        "time": 1689754324000,
        "value": 139.474,
        "_internal_originalTime": 1689754324000
    },
    {
        "time": 1689754325000,
        "value": 139.479,
        "_internal_originalTime": 1689754325000
    },
    {
        "time": 1689754326000,
        "value": 139.471,
        "_internal_originalTime": 1689754326000
    },
    {
        "time": 1689754327000,
        "value": 139.467,
        "_internal_originalTime": 1689754327000
    },
    {
        "time": 1689754328000,
        "value": 139.466,
        "_internal_originalTime": 1689754328000
    },
    {
        "time": 1689754330000,
        "value": 139.46,
        "_internal_originalTime": 1689754330000
    },
    {
        "time": 1689754331000,
        "value": 139.464,
        "_internal_originalTime": 1689754331000
    },
    {
        "time": 1689754332000,
        "value": 139.464,
        "_internal_originalTime": 1689754332000
    },
    {
        "time": 1689754333000,
        "value": 139.469,
        "_internal_originalTime": 1689754333000
    },
    {
        "time": 1689754335000,
        "value": 139.466,
        "_internal_originalTime": 1689754335000
    },
    {
        "time": 1689754337000,
        "value": 139.468,
        "_internal_originalTime": 1689754337000
    },
    {
        "time": 1689754339000,
        "value": 139.462,
        "_internal_originalTime": 1689754339000
    },
    {
        "time": 1689754341000,
        "value": 139.465,
        "_internal_originalTime": 1689754341000
    },
    {
        "time": 1689754342000,
        "value": 139.463,
        "_internal_originalTime": 1689754342000
    },
    {
        "time": 1689754343000,
        "value": 139.465,
        "_internal_originalTime": 1689754343000
    },
    {
        "time": 1689754344000,
        "value": 139.464,
        "_internal_originalTime": 1689754344000
    },
    {
        "time": 1689754345000,
        "value": 139.463,
        "_internal_originalTime": 1689754345000
    },
    {
        "time": 1689754346000,
        "value": 139.462,
        "_internal_originalTime": 1689754346000
    },
    {
        "time": 1689754348000,
        "value": 139.47,
        "_internal_originalTime": 1689754348000
    },
    {
        "time": 1689754349000,
        "value": 139.468,
        "_internal_originalTime": 1689754349000
    },
    {
        "time": 1689754351000,
        "value": 139.471,
        "_internal_originalTime": 1689754351000
    },
    {
        "time": 1689754352000,
        "value": 139.472,
        "_internal_originalTime": 1689754352000
    },
    {
        "time": 1689754354000,
        "value": 139.467,
        "_internal_originalTime": 1689754354000
    },
    {
        "time": 1689754355000,
        "value": 139.462,
        "_internal_originalTime": 1689754355000
    },
    {
        "time": 1689754356000,
        "value": 139.462,
        "_internal_originalTime": 1689754356000
    },
    {
        "time": 1689754357000,
        "value": 139.461,
        "_internal_originalTime": 1689754357000
    },
    {
        "time": 1689754358000,
        "value": 139.462,
        "_internal_originalTime": 1689754358000
    },
    {
        "time": 1689754359000,
        "value": 139.463,
        "_internal_originalTime": 1689754359000
    },
    {
        "time": 1689754361000,
        "value": 139.463,
        "_internal_originalTime": 1689754361000
    },
    {
        "time": 1689754362000,
        "value": 139.461,
        "_internal_originalTime": 1689754362000
    },
    {
        "time": 1689754363000,
        "value": 139.46,
        "_internal_originalTime": 1689754363000
    },
    {
        "time": 1689754365000,
        "value": 139.464,
        "_internal_originalTime": 1689754365000
    },
    {
        "time": 1689754366000,
        "value": 139.467,
        "_internal_originalTime": 1689754366000
    },
    {
        "time": 1689754367000,
        "value": 139.464,
        "_internal_originalTime": 1689754367000
    },
    {
        "time": 1689754368000,
        "value": 139.468,
        "_internal_originalTime": 1689754368000
    },
    {
        "time": 1689754370000,
        "value": 139.472,
        "_internal_originalTime": 1689754370000
    },
    {
        "time": 1689754372000,
        "value": 139.468,
        "_internal_originalTime": 1689754372000
    },
    {
        "time": 1689754373000,
        "value": 139.473,
        "_internal_originalTime": 1689754373000
    },
    {
        "time": 1689754374000,
        "value": 139.474,
        "_internal_originalTime": 1689754374000
    },
    {
        "time": 1689754376000,
        "value": 139.465,
        "_internal_originalTime": 1689754376000
    },
    {
        "time": 1689754377000,
        "value": 139.463,
        "_internal_originalTime": 1689754377000
    },
    {
        "time": 1689754379000,
        "value": 139.464,
        "_internal_originalTime": 1689754379000
    },
    {
        "time": 1689754380000,
        "value": 139.462,
        "_internal_originalTime": 1689754380000
    },
    {
        "time": 1689754381000,
        "value": 139.467,
        "_internal_originalTime": 1689754381000
    },
    {
        "time": 1689754383000,
        "value": 139.464,
        "_internal_originalTime": 1689754383000
    },
    {
        "time": 1689754384000,
        "value": 139.462,
        "_internal_originalTime": 1689754384000
    },
    {
        "time": 1689754386000,
        "value": 139.47,
        "_internal_originalTime": 1689754386000
    },
    {
        "time": 1689754387000,
        "value": 139.472,
        "_internal_originalTime": 1689754387000
    },
    {
        "time": 1689754388000,
        "value": 139.467,
        "_internal_originalTime": 1689754388000
    },
    {
        "time": 1689754390000,
        "value": 139.471,
        "_internal_originalTime": 1689754390000
    },
    {
        "time": 1689754391000,
        "value": 139.471,
        "_internal_originalTime": 1689754391000
    },
    {
        "time": 1689754392000,
        "value": 139.47,
        "_internal_originalTime": 1689754392000
    },
    {
        "time": 1689754394000,
        "value": 139.472,
        "_internal_originalTime": 1689754394000
    },
    {
        "time": 1689754396000,
        "value": 139.47,
        "_internal_originalTime": 1689754396000
    },
    {
        "time": 1689754398000,
        "value": 139.468,
        "_internal_originalTime": 1689754398000
    },
    {
        "time": 1689754399000,
        "value": 139.472,
        "_internal_originalTime": 1689754399000
    },
    {
        "time": 1689754400000,
        "value": 139.473,
        "_internal_originalTime": 1689754400000
    },
    {
        "time": 1689754401000,
        "value": 139.473,
        "_internal_originalTime": 1689754401000
    },
    {
        "time": 1689754402000,
        "value": 139.472,
        "_internal_originalTime": 1689754402000
    },
    {
        "time": 1689754403000,
        "value": 139.471,
        "_internal_originalTime": 1689754403000
    },
    {
        "time": 1689754404000,
        "value": 139.473,
        "_internal_originalTime": 1689754404000
    },
    {
        "time": 1689754407000,
        "value": 139.472,
        "_internal_originalTime": 1689754407000
    },
    {
        "time": 1689754408000,
        "value": 139.47,
        "_internal_originalTime": 1689754408000
    },
    {
        "time": 1689754409000,
        "value": 139.485,
        "_internal_originalTime": 1689754409000
    },
    {
        "time": 1689754410000,
        "value": 139.482,
        "_internal_originalTime": 1689754410000
    },
    {
        "time": 1689754411000,
        "value": 139.487,
        "_internal_originalTime": 1689754411000
    },
    {
        "time": 1689754413000,
        "value": 139.488,
        "_internal_originalTime": 1689754413000
    },
    {
        "time": 1689754414000,
        "value": 139.474,
        "_internal_originalTime": 1689754414000
    },
    {
        "time": 1689754416000,
        "value": 139.48,
        "_internal_originalTime": 1689754416000
    },
    {
        "time": 1689754417000,
        "value": 139.479,
        "_internal_originalTime": 1689754417000
    },
    {
        "time": 1689754418000,
        "value": 139.483,
        "_internal_originalTime": 1689754418000
    },
    {
        "time": 1689754419000,
        "value": 139.485,
        "_internal_originalTime": 1689754419000
    },
    {
        "time": 1689754420000,
        "value": 139.484,
        "_internal_originalTime": 1689754420000
    },
    {
        "time": 1689754421000,
        "value": 139.48,
        "_internal_originalTime": 1689754421000
    },
    {
        "time": 1689754422000,
        "value": 139.486,
        "_internal_originalTime": 1689754422000
    },
    {
        "time": 1689754424000,
        "value": 139.483,
        "_internal_originalTime": 1689754424000
    },
    {
        "time": 1689754425000,
        "value": 139.486,
        "_internal_originalTime": 1689754425000
    },
    {
        "time": 1689754426000,
        "value": 139.482,
        "_internal_originalTime": 1689754426000
    },
    {
        "time": 1689754427000,
        "value": 139.483,
        "_internal_originalTime": 1689754427000
    },
    {
        "time": 1689754428000,
        "value": 139.48,
        "_internal_originalTime": 1689754428000
    },
    {
        "time": 1689754429000,
        "value": 139.483,
        "_internal_originalTime": 1689754429000
    },
    {
        "time": 1689754431000,
        "value": 139.478,
        "_internal_originalTime": 1689754431000
    },
    {
        "time": 1689754432000,
        "value": 139.479,
        "_internal_originalTime": 1689754432000
    },
    {
        "time": 1689754433000,
        "value": 139.482,
        "_internal_originalTime": 1689754433000
    },
    {
        "time": 1689754434000,
        "value": 139.479,
        "_internal_originalTime": 1689754434000
    },
    {
        "time": 1689754435000,
        "value": 139.48,
        "_internal_originalTime": 1689754435000
    },
    {
        "time": 1689754436000,
        "value": 139.483,
        "_internal_originalTime": 1689754436000
    },
    {
        "time": 1689754437000,
        "value": 139.485,
        "_internal_originalTime": 1689754437000
    },
    {
        "time": 1689754439000,
        "value": 139.484,
        "_internal_originalTime": 1689754439000
    },
    {
        "time": 1689754440000,
        "value": 139.485,
        "_internal_originalTime": 1689754440000
    },
    {
        "time": 1689754441000,
        "value": 139.481,
        "_internal_originalTime": 1689754441000
    },
    {
        "time": 1689754442000,
        "value": 139.481,
        "_internal_originalTime": 1689754442000
    },
    {
        "time": 1689754443000,
        "value": 139.48,
        "_internal_originalTime": 1689754443000
    },
    {
        "time": 1689754445000,
        "value": 139.48,
        "_internal_originalTime": 1689754445000
    },
    {
        "time": 1689754446000,
        "value": 139.479,
        "_internal_originalTime": 1689754446000
    },
    {
        "time": 1689754447000,
        "value": 139.481,
        "_internal_originalTime": 1689754447000
    },
    {
        "time": 1689754448000,
        "value": 139.475,
        "_internal_originalTime": 1689754448000
    },
    {
        "time": 1689754449000,
        "value": 139.479,
        "_internal_originalTime": 1689754449000
    },
    {
        "time": 1689754450000,
        "value": 139.479,
        "_internal_originalTime": 1689754450000
    },
    {
        "time": 1689754451000,
        "value": 139.483,
        "_internal_originalTime": 1689754451000
    },
    {
        "time": 1689754453000,
        "value": 139.481,
        "_internal_originalTime": 1689754453000
    },
    {
        "time": 1689754455000,
        "value": 139.482,
        "_internal_originalTime": 1689754455000
    },
    {
        "time": 1689754457000,
        "value": 139.478,
        "_internal_originalTime": 1689754457000
    },
    {
        "time": 1689754458000,
        "value": 139.481,
        "_internal_originalTime": 1689754458000
    },
    {
        "time": 1689754459000,
        "value": 139.478,
        "_internal_originalTime": 1689754459000
    },
    {
        "time": 1689754461000,
        "value": 139.488,
        "_internal_originalTime": 1689754461000
    },
    {
        "time": 1689754462000,
        "value": 139.485,
        "_internal_originalTime": 1689754462000
    },
    {
        "time": 1689754463000,
        "value": 139.485,
        "_internal_originalTime": 1689754463000
    },
    {
        "time": 1689754464000,
        "value": 139.49,
        "_internal_originalTime": 1689754464000
    },
    {
        "time": 1689754465000,
        "value": 139.491,
        "_internal_originalTime": 1689754465000
    },
    {
        "time": 1689754466000,
        "value": 139.488,
        "_internal_originalTime": 1689754466000
    },
    {
        "time": 1689754467000,
        "value": 139.486,
        "_internal_originalTime": 1689754467000
    },
    {
        "time": 1689754468000,
        "value": 139.491,
        "_internal_originalTime": 1689754468000
    },
    {
        "time": 1689754469000,
        "value": 139.492,
        "_internal_originalTime": 1689754469000
    },
    {
        "time": 1689754471000,
        "value": 139.491,
        "_internal_originalTime": 1689754471000
    },
    {
        "time": 1689754472000,
        "value": 139.487,
        "_internal_originalTime": 1689754472000
    },
    {
        "time": 1689754473000,
        "value": 139.485,
        "_internal_originalTime": 1689754473000
    },
    {
        "time": 1689754474000,
        "value": 139.485,
        "_internal_originalTime": 1689754474000
    },
    {
        "time": 1689754475000,
        "value": 139.482,
        "_internal_originalTime": 1689754475000
    },
    {
        "time": 1689754477000,
        "value": 139.492,
        "_internal_originalTime": 1689754477000
    },
    {
        "time": 1689754478000,
        "value": 139.488,
        "_internal_originalTime": 1689754478000
    },
    {
        "time": 1689754479000,
        "value": 139.488,
        "_internal_originalTime": 1689754479000
    },
    {
        "time": 1689754480000,
        "value": 139.495,
        "_internal_originalTime": 1689754480000
    },
    {
        "time": 1689754481000,
        "value": 139.493,
        "_internal_originalTime": 1689754481000
    },
    {
        "time": 1689754483000,
        "value": 139.499,
        "_internal_originalTime": 1689754483000
    },
    {
        "time": 1689754484000,
        "value": 139.504,
        "_internal_originalTime": 1689754484000
    },
    {
        "time": 1689754485000,
        "value": 139.498,
        "_internal_originalTime": 1689754485000
    },
    {
        "time": 1689754486000,
        "value": 139.501,
        "_internal_originalTime": 1689754486000
    },
    {
        "time": 1689754488000,
        "value": 139.498,
        "_internal_originalTime": 1689754488000
    },
    {
        "time": 1689754489000,
        "value": 139.497,
        "_internal_originalTime": 1689754489000
    },
    {
        "time": 1689754490000,
        "value": 139.496,
        "_internal_originalTime": 1689754490000
    },
    {
        "time": 1689754491000,
        "value": 139.491,
        "_internal_originalTime": 1689754491000
    },
    {
        "time": 1689754492000,
        "value": 139.496,
        "_internal_originalTime": 1689754492000
    },
    {
        "time": 1689754493000,
        "value": 139.494,
        "_internal_originalTime": 1689754493000
    },
    {
        "time": 1689754495000,
        "value": 139.491,
        "_internal_originalTime": 1689754495000
    },
    {
        "time": 1689754496000,
        "value": 139.497,
        "_internal_originalTime": 1689754496000
    },
    {
        "time": 1689754497000,
        "value": 139.495,
        "_internal_originalTime": 1689754497000
    },
    {
        "time": 1689754498000,
        "value": 139.49,
        "_internal_originalTime": 1689754498000
    },
    {
        "time": 1689754500000,
        "value": 139.492,
        "_internal_originalTime": 1689754500000
    },
    {
        "time": 1689754501000,
        "value": 139.47,
        "_internal_originalTime": 1689754501000
    },
    {
        "time": 1689754502000,
        "value": 139.493,
        "_internal_originalTime": 1689754502000
    },
    {
        "time": 1689754503000,
        "value": 139.501,
        "_internal_originalTime": 1689754503000
    },
    {
        "time": 1689754504000,
        "value": 139.497,
        "_internal_originalTime": 1689754504000
    },
    {
        "time": 1689754505000,
        "value": 139.5,
        "_internal_originalTime": 1689754505000
    },
    {
        "time": 1689754506000,
        "value": 139.497,
        "_internal_originalTime": 1689754506000
    },
    {
        "time": 1689754508000,
        "value": 139.498,
        "_internal_originalTime": 1689754508000
    },
    {
        "time": 1689754509000,
        "value": 139.507,
        "_internal_originalTime": 1689754509000
    },
    {
        "time": 1689754510000,
        "value": 139.506,
        "_internal_originalTime": 1689754510000
    },
    {
        "time": 1689754511000,
        "value": 139.506,
        "_internal_originalTime": 1689754511000
    },
    {
        "time": 1689754513000,
        "value": 139.502,
        "_internal_originalTime": 1689754513000
    },
    {
        "time": 1689754514000,
        "value": 139.506,
        "_internal_originalTime": 1689754514000
    },
    {
        "time": 1689754515000,
        "value": 139.505,
        "_internal_originalTime": 1689754515000
    },
    {
        "time": 1689754516000,
        "value": 139.508,
        "_internal_originalTime": 1689754516000
    },
    {
        "time": 1689754517000,
        "value": 139.51,
        "_internal_originalTime": 1689754517000
    },
    {
        "time": 1689754518000,
        "value": 139.507,
        "_internal_originalTime": 1689754518000
    },
    {
        "time": 1689754519000,
        "value": 139.51,
        "_internal_originalTime": 1689754519000
    },
    {
        "time": 1689754521000,
        "value": 139.506,
        "_internal_originalTime": 1689754521000
    },
    {
        "time": 1689754522000,
        "value": 139.501,
        "_internal_originalTime": 1689754522000
    },
    {
        "time": 1689754523000,
        "value": 139.493,
        "_internal_originalTime": 1689754523000
    },
    {
        "time": 1689754524000,
        "value": 139.503,
        "_internal_originalTime": 1689754524000
    },
    {
        "time": 1689754525000,
        "value": 139.5,
        "_internal_originalTime": 1689754525000
    },
    {
        "time": 1689754526000,
        "value": 139.5,
        "_internal_originalTime": 1689754526000
    },
    {
        "time": 1689754528000,
        "value": 139.504,
        "_internal_originalTime": 1689754528000
    },
    {
        "time": 1689754529000,
        "value": 139.503,
        "_internal_originalTime": 1689754529000
    },
    {
        "time": 1689754530000,
        "value": 139.504,
        "_internal_originalTime": 1689754530000
    },
    {
        "time": 1689754532000,
        "value": 139.498,
        "_internal_originalTime": 1689754532000
    },
    {
        "time": 1689754533000,
        "value": 139.495,
        "_internal_originalTime": 1689754533000
    },
    {
        "time": 1689754534000,
        "value": 139.5,
        "_internal_originalTime": 1689754534000
    },
    {
        "time": 1689754535000,
        "value": 139.503,
        "_internal_originalTime": 1689754535000
    },
    {
        "time": 1689754536000,
        "value": 139.501,
        "_internal_originalTime": 1689754536000
    },
    {
        "time": 1689754538000,
        "value": 139.498,
        "_internal_originalTime": 1689754538000
    },
    {
        "time": 1689754539000,
        "value": 139.502,
        "_internal_originalTime": 1689754539000
    },
    {
        "time": 1689754540000,
        "value": 139.502,
        "_internal_originalTime": 1689754540000
    },
    {
        "time": 1689754541000,
        "value": 139.501,
        "_internal_originalTime": 1689754541000
    },
    {
        "time": 1689754543000,
        "value": 139.505,
        "_internal_originalTime": 1689754543000
    },
    {
        "time": 1689754544000,
        "value": 139.503,
        "_internal_originalTime": 1689754544000
    },
    {
        "time": 1689754545000,
        "value": 139.505,
        "_internal_originalTime": 1689754545000
    },
    {
        "time": 1689754547000,
        "value": 139.498,
        "_internal_originalTime": 1689754547000
    },
    {
        "time": 1689754548000,
        "value": 139.502,
        "_internal_originalTime": 1689754548000
    },
    {
        "time": 1689754549000,
        "value": 139.501,
        "_internal_originalTime": 1689754549000
    },
    {
        "time": 1689754550000,
        "value": 139.498,
        "_internal_originalTime": 1689754550000
    },
    {
        "time": 1689754552000,
        "value": 139.501,
        "_internal_originalTime": 1689754552000
    },
    {
        "time": 1689754553000,
        "value": 139.497,
        "_internal_originalTime": 1689754553000
    },
    {
        "time": 1689754555000,
        "value": 139.501,
        "_internal_originalTime": 1689754555000
    },
    {
        "time": 1689754557000,
        "value": 139.496,
        "_internal_originalTime": 1689754557000
    },
    {
        "time": 1689754559000,
        "value": 139.508,
        "_internal_originalTime": 1689754559000
    },
    {
        "time": 1689754561000,
        "value": 139.503,
        "_internal_originalTime": 1689754561000
    },
    {
        "time": 1689754562000,
        "value": 139.503,
        "_internal_originalTime": 1689754562000
    },
    {
        "time": 1689754563000,
        "value": 139.507,
        "_internal_originalTime": 1689754563000
    },
    {
        "time": 1689754565000,
        "value": 139.51,
        "_internal_originalTime": 1689754565000
    },
    {
        "time": 1689754566000,
        "value": 139.51,
        "_internal_originalTime": 1689754566000
    },
    {
        "time": 1689754568000,
        "value": 139.509,
        "_internal_originalTime": 1689754568000
    },
    {
        "time": 1689754569000,
        "value": 139.51,
        "_internal_originalTime": 1689754569000
    },
    {
        "time": 1689754570000,
        "value": 139.503,
        "_internal_originalTime": 1689754570000
    },
    {
        "time": 1689754571000,
        "value": 139.499,
        "_internal_originalTime": 1689754571000
    },
    {
        "time": 1689754572000,
        "value": 139.504,
        "_internal_originalTime": 1689754572000
    },
    {
        "time": 1689754575000,
        "value": 139.495,
        "_internal_originalTime": 1689754575000
    },
    {
        "time": 1689754576000,
        "value": 139.496,
        "_internal_originalTime": 1689754576000
    },
    {
        "time": 1689754578000,
        "value": 139.492,
        "_internal_originalTime": 1689754578000
    },
    {
        "time": 1689754579000,
        "value": 139.496,
        "_internal_originalTime": 1689754579000
    },
    {
        "time": 1689754580000,
        "value": 139.5,
        "_internal_originalTime": 1689754580000
    },
    {
        "time": 1689754581000,
        "value": 139.499,
        "_internal_originalTime": 1689754581000
    },
    {
        "time": 1689754582000,
        "value": 139.497,
        "_internal_originalTime": 1689754582000
    },
    {
        "time": 1689754583000,
        "value": 139.495,
        "_internal_originalTime": 1689754583000
    },
    {
        "time": 1689754585000,
        "value": 139.495,
        "_internal_originalTime": 1689754585000
    },
    {
        "time": 1689754586000,
        "value": 139.503,
        "_internal_originalTime": 1689754586000
    },
    {
        "time": 1689754587000,
        "value": 139.503,
        "_internal_originalTime": 1689754587000
    },
    {
        "time": 1689754590000,
        "value": 139.503,
        "_internal_originalTime": 1689754590000
    },
    {
        "time": 1689754591000,
        "value": 139.497,
        "_internal_originalTime": 1689754591000
    },
    {
        "time": 1689754592000,
        "value": 139.494,
        "_internal_originalTime": 1689754592000
    },
    {
        "time": 1689754594000,
        "value": 139.501,
        "_internal_originalTime": 1689754594000
    },
    {
        "time": 1689754595000,
        "value": 139.503,
        "_internal_originalTime": 1689754595000
    },
    {
        "time": 1689754596000,
        "value": 139.499,
        "_internal_originalTime": 1689754596000
    },
    {
        "time": 1689754597000,
        "value": 139.504,
        "_internal_originalTime": 1689754597000
    },
    {
        "time": 1689754598000,
        "value": 139.5,
        "_internal_originalTime": 1689754598000
    },
    {
        "time": 1689754600000,
        "value": 139.504,
        "_internal_originalTime": 1689754600000
    },
    {
        "time": 1689754601000,
        "value": 139.504,
        "_internal_originalTime": 1689754601000
    },
    {
        "time": 1689754602000,
        "value": 139.502,
        "_internal_originalTime": 1689754602000
    },
    {
        "time": 1689754604000,
        "value": 139.503,
        "_internal_originalTime": 1689754604000
    },
    {
        "time": 1689754605000,
        "value": 139.498,
        "_internal_originalTime": 1689754605000
    },
    {
        "time": 1689754606000,
        "value": 139.499,
        "_internal_originalTime": 1689754606000
    },
    {
        "time": 1689754608000,
        "value": 139.501,
        "_internal_originalTime": 1689754608000
    },
    {
        "time": 1689754609000,
        "value": 139.499,
        "_internal_originalTime": 1689754609000
    },
    {
        "time": 1689754610000,
        "value": 139.5,
        "_internal_originalTime": 1689754610000
    },
    {
        "time": 1689754611000,
        "value": 139.503,
        "_internal_originalTime": 1689754611000
    },
    {
        "time": 1689754612000,
        "value": 139.51,
        "_internal_originalTime": 1689754612000
    },
    {
        "time": 1689754613000,
        "value": 139.509,
        "_internal_originalTime": 1689754613000
    },
    {
        "time": 1689754614000,
        "value": 139.507,
        "_internal_originalTime": 1689754614000
    },
    {
        "time": 1689754615000,
        "value": 139.511,
        "_internal_originalTime": 1689754615000
    },
    {
        "time": 1689754617000,
        "value": 139.513,
        "_internal_originalTime": 1689754617000
    },
    {
        "time": 1689754618000,
        "value": 139.511,
        "_internal_originalTime": 1689754618000
    },
    {
        "time": 1689754620000,
        "value": 139.513,
        "_internal_originalTime": 1689754620000
    },
    {
        "time": 1689754621000,
        "value": 139.49,
        "_internal_originalTime": 1689754621000
    },
    {
        "time": 1689754622000,
        "value": 139.51,
        "_internal_originalTime": 1689754622000
    },
    {
        "time": 1689754623000,
        "value": 139.51,
        "_internal_originalTime": 1689754623000
    },
    {
        "time": 1689754625000,
        "value": 139.509,
        "_internal_originalTime": 1689754625000
    },
    {
        "time": 1689754626000,
        "value": 139.507,
        "_internal_originalTime": 1689754626000
    },
    {
        "time": 1689754627000,
        "value": 139.511,
        "_internal_originalTime": 1689754627000
    },
    {
        "time": 1689754629000,
        "value": 139.509,
        "_internal_originalTime": 1689754629000
    },
    {
        "time": 1689754630000,
        "value": 139.51,
        "_internal_originalTime": 1689754630000
    },
    {
        "time": 1689754631000,
        "value": 139.511,
        "_internal_originalTime": 1689754631000
    },
    {
        "time": 1689754632000,
        "value": 139.51,
        "_internal_originalTime": 1689754632000
    },
    {
        "time": 1689754634000,
        "value": 139.513,
        "_internal_originalTime": 1689754634000
    },
    {
        "time": 1689754635000,
        "value": 139.515,
        "_internal_originalTime": 1689754635000
    },
    {
        "time": 1689754636000,
        "value": 139.508,
        "_internal_originalTime": 1689754636000
    },
    {
        "time": 1689754637000,
        "value": 139.509,
        "_internal_originalTime": 1689754637000
    },
    {
        "time": 1689754639000,
        "value": 139.504,
        "_internal_originalTime": 1689754639000
    },
    {
        "time": 1689754641000,
        "value": 139.508,
        "_internal_originalTime": 1689754641000
    },
    {
        "time": 1689754642000,
        "value": 139.507,
        "_internal_originalTime": 1689754642000
    },
    {
        "time": 1689754643000,
        "value": 139.509,
        "_internal_originalTime": 1689754643000
    },
    {
        "time": 1689754645000,
        "value": 139.505,
        "_internal_originalTime": 1689754645000
    },
    {
        "time": 1689754646000,
        "value": 139.509,
        "_internal_originalTime": 1689754646000
    },
    {
        "time": 1689754647000,
        "value": 139.51,
        "_internal_originalTime": 1689754647000
    },
    {
        "time": 1689754648000,
        "value": 139.508,
        "_internal_originalTime": 1689754648000
    },
    {
        "time": 1689754650000,
        "value": 139.509,
        "_internal_originalTime": 1689754650000
    },
    {
        "time": 1689754651000,
        "value": 139.508,
        "_internal_originalTime": 1689754651000
    },
    {
        "time": 1689754652000,
        "value": 139.506,
        "_internal_originalTime": 1689754652000
    },
    {
        "time": 1689754654000,
        "value": 139.506,
        "_internal_originalTime": 1689754654000
    },
    {
        "time": 1689754656000,
        "value": 139.508,
        "_internal_originalTime": 1689754656000
    },
    {
        "time": 1689754658000,
        "value": 139.508,
        "_internal_originalTime": 1689754658000
    },
    {
        "time": 1689754659000,
        "value": 139.507,
        "_internal_originalTime": 1689754659000
    },
    {
        "time": 1689754660000,
        "value": 139.506,
        "_internal_originalTime": 1689754660000
    },
    {
        "time": 1689754661000,
        "value": 139.504,
        "_internal_originalTime": 1689754661000
    },
    {
        "time": 1689754662000,
        "value": 139.51,
        "_internal_originalTime": 1689754662000
    },
    {
        "time": 1689754664000,
        "value": 139.506,
        "_internal_originalTime": 1689754664000
    },
    {
        "time": 1689754665000,
        "value": 139.505,
        "_internal_originalTime": 1689754665000
    },
    {
        "time": 1689754666000,
        "value": 139.504,
        "_internal_originalTime": 1689754666000
    },
    {
        "time": 1689754668000,
        "value": 139.5,
        "_internal_originalTime": 1689754668000
    },
    {
        "time": 1689754669000,
        "value": 139.498,
        "_internal_originalTime": 1689754669000
    },
    {
        "time": 1689754671000,
        "value": 139.504,
        "_internal_originalTime": 1689754671000
    },
    {
        "time": 1689754672000,
        "value": 139.498,
        "_internal_originalTime": 1689754672000
    },
    {
        "time": 1689754673000,
        "value": 139.498,
        "_internal_originalTime": 1689754673000
    },
    {
        "time": 1689754676000,
        "value": 139.499,
        "_internal_originalTime": 1689754676000
    },
    {
        "time": 1689754677000,
        "value": 139.496,
        "_internal_originalTime": 1689754677000
    },
    {
        "time": 1689754678000,
        "value": 139.494,
        "_internal_originalTime": 1689754678000
    },
    {
        "time": 1689754680000,
        "value": 139.489,
        "_internal_originalTime": 1689754680000
    },
    {
        "time": 1689754681000,
        "value": 139.492,
        "_internal_originalTime": 1689754681000
    },
    {
        "time": 1689754682000,
        "value": 139.489,
        "_internal_originalTime": 1689754682000
    },
    {
        "time": 1689754683000,
        "value": 139.492,
        "_internal_originalTime": 1689754683000
    },
    {
        "time": 1689754685000,
        "value": 139.496,
        "_internal_originalTime": 1689754685000
    },
    {
        "time": 1689754686000,
        "value": 139.499,
        "_internal_originalTime": 1689754686000
    },
    {
        "time": 1689754687000,
        "value": 139.499,
        "_internal_originalTime": 1689754687000
    },
    {
        "time": 1689754688000,
        "value": 139.501,
        "_internal_originalTime": 1689754688000
    },
    {
        "time": 1689754689000,
        "value": 139.501,
        "_internal_originalTime": 1689754689000
    },
    {
        "time": 1689754691000,
        "value": 139.503,
        "_internal_originalTime": 1689754691000
    },
    {
        "time": 1689754692000,
        "value": 139.5,
        "_internal_originalTime": 1689754692000
    },
    {
        "time": 1689754693000,
        "value": 139.501,
        "_internal_originalTime": 1689754693000
    },
    {
        "time": 1689754695000,
        "value": 139.502,
        "_internal_originalTime": 1689754695000
    },
    {
        "time": 1689754696000,
        "value": 139.501,
        "_internal_originalTime": 1689754696000
    },
    {
        "time": 1689754697000,
        "value": 139.497,
        "_internal_originalTime": 1689754697000
    },
    {
        "time": 1689754698000,
        "value": 139.497,
        "_internal_originalTime": 1689754698000
    },
    {
        "time": 1689754701000,
        "value": 139.498,
        "_internal_originalTime": 1689754701000
    },
    {
        "time": 1689754703000,
        "value": 139.502,
        "_internal_originalTime": 1689754703000
    },
    {
        "time": 1689754704000,
        "value": 139.5,
        "_internal_originalTime": 1689754704000
    },
    {
        "time": 1689754705000,
        "value": 139.511,
        "_internal_originalTime": 1689754705000
    },
    {
        "time": 1689754707000,
        "value": 139.51,
        "_internal_originalTime": 1689754707000
    },
    {
        "time": 1689754708000,
        "value": 139.513,
        "_internal_originalTime": 1689754708000
    },
    {
        "time": 1689754710000,
        "value": 139.508,
        "_internal_originalTime": 1689754710000
    },
    {
        "time": 1689754711000,
        "value": 139.509,
        "_internal_originalTime": 1689754711000
    },
    {
        "time": 1689754713000,
        "value": 139.504,
        "_internal_originalTime": 1689754713000
    },
    {
        "time": 1689754714000,
        "value": 139.506,
        "_internal_originalTime": 1689754714000
    },
    {
        "time": 1689754715000,
        "value": 139.508,
        "_internal_originalTime": 1689754715000
    },
    {
        "time": 1689754716000,
        "value": 139.502,
        "_internal_originalTime": 1689754716000
    },
    {
        "time": 1689754717000,
        "value": 139.5,
        "_internal_originalTime": 1689754717000
    },
    {
        "time": 1689754718000,
        "value": 139.505,
        "_internal_originalTime": 1689754718000
    },
    {
        "time": 1689754720000,
        "value": 139.505,
        "_internal_originalTime": 1689754720000
    },
    {
        "time": 1689754721000,
        "value": 139.501,
        "_internal_originalTime": 1689754721000
    },
    {
        "time": 1689754723000,
        "value": 139.5,
        "_internal_originalTime": 1689754723000
    },
    {
        "time": 1689754724000,
        "value": 139.498,
        "_internal_originalTime": 1689754724000
    },
    {
        "time": 1689754725000,
        "value": 139.502,
        "_internal_originalTime": 1689754725000
    },
    {
        "time": 1689754727000,
        "value": 139.503,
        "_internal_originalTime": 1689754727000
    },
    {
        "time": 1689754728000,
        "value": 139.501,
        "_internal_originalTime": 1689754728000
    },
    {
        "time": 1689754729000,
        "value": 139.5,
        "_internal_originalTime": 1689754729000
    },
    {
        "time": 1689754730000,
        "value": 139.501,
        "_internal_originalTime": 1689754730000
    },
    {
        "time": 1689754732000,
        "value": 139.499,
        "_internal_originalTime": 1689754732000
    },
    {
        "time": 1689754734000,
        "value": 139.485,
        "_internal_originalTime": 1689754734000
    },
    {
        "time": 1689754735000,
        "value": 139.482,
        "_internal_originalTime": 1689754735000
    },
    {
        "time": 1689754738000,
        "value": 139.489,
        "_internal_originalTime": 1689754738000
    },
    {
        "time": 1689754739000,
        "value": 139.492,
        "_internal_originalTime": 1689754739000
    },
    {
        "time": 1689754740000,
        "value": 139.492,
        "_internal_originalTime": 1689754740000
    },
    {
        "time": 1689754741000,
        "value": 139.46,
        "_internal_originalTime": 1689754741000
    },
    {
        "time": 1689754742000,
        "value": 139.492,
        "_internal_originalTime": 1689754742000
    },
    {
        "time": 1689754743000,
        "value": 139.492,
        "_internal_originalTime": 1689754743000
    },
    {
        "time": 1689754744000,
        "value": 139.492,
        "_internal_originalTime": 1689754744000
    },
    {
        "time": 1689754746000,
        "value": 139.488,
        "_internal_originalTime": 1689754746000
    },
    {
        "time": 1689754747000,
        "value": 139.493,
        "_internal_originalTime": 1689754747000
    },
    {
        "time": 1689754748000,
        "value": 139.489,
        "_internal_originalTime": 1689754748000
    },
    {
        "time": 1689754749000,
        "value": 139.489,
        "_internal_originalTime": 1689754749000
    },
    {
        "time": 1689754750000,
        "value": 139.482,
        "_internal_originalTime": 1689754750000
    },
    {
        "time": 1689754751000,
        "value": 139.483,
        "_internal_originalTime": 1689754751000
    },
    {
        "time": 1689754753000,
        "value": 139.485,
        "_internal_originalTime": 1689754753000
    },
    {
        "time": 1689754754000,
        "value": 139.486,
        "_internal_originalTime": 1689754754000
    },
    {
        "time": 1689754755000,
        "value": 139.488,
        "_internal_originalTime": 1689754755000
    },
    {
        "time": 1689754756000,
        "value": 139.492,
        "_internal_originalTime": 1689754756000
    },
    {
        "time": 1689754757000,
        "value": 139.489,
        "_internal_originalTime": 1689754757000
    },
    {
        "time": 1689754758000,
        "value": 139.488,
        "_internal_originalTime": 1689754758000
    },
    {
        "time": 1689754759000,
        "value": 139.487,
        "_internal_originalTime": 1689754759000
    },
    {
        "time": 1689754761000,
        "value": 139.495,
        "_internal_originalTime": 1689754761000
    },
    {
        "time": 1689754762000,
        "value": 139.488,
        "_internal_originalTime": 1689754762000
    },
    {
        "time": 1689754764000,
        "value": 139.491,
        "_internal_originalTime": 1689754764000
    },
    {
        "time": 1689754765000,
        "value": 139.489,
        "_internal_originalTime": 1689754765000
    },
    {
        "time": 1689754767000,
        "value": 139.486,
        "_internal_originalTime": 1689754767000
    },
    {
        "time": 1689754768000,
        "value": 139.487,
        "_internal_originalTime": 1689754768000
    },
    {
        "time": 1689754770000,
        "value": 139.488,
        "_internal_originalTime": 1689754770000
    },
    {
        "time": 1689754771000,
        "value": 139.49,
        "_internal_originalTime": 1689754771000
    },
    {
        "time": 1689754772000,
        "value": 139.486,
        "_internal_originalTime": 1689754772000
    },
    {
        "time": 1689754773000,
        "value": 139.484,
        "_internal_originalTime": 1689754773000
    },
    {
        "time": 1689754774000,
        "value": 139.479,
        "_internal_originalTime": 1689754774000
    },
    {
        "time": 1689754775000,
        "value": 139.486,
        "_internal_originalTime": 1689754775000
    },
    {
        "time": 1689754776000,
        "value": 139.482,
        "_internal_originalTime": 1689754776000
    },
    {
        "time": 1689754778000,
        "value": 139.491,
        "_internal_originalTime": 1689754778000
    },
    {
        "time": 1689754779000,
        "value": 139.499,
        "_internal_originalTime": 1689754779000
    },
    {
        "time": 1689754780000,
        "value": 139.495,
        "_internal_originalTime": 1689754780000
    },
    {
        "time": 1689754781000,
        "value": 139.498,
        "_internal_originalTime": 1689754781000
    },
    {
        "time": 1689754782000,
        "value": 139.496,
        "_internal_originalTime": 1689754782000
    },
    {
        "time": 1689754784000,
        "value": 139.507,
        "_internal_originalTime": 1689754784000
    },
    {
        "time": 1689754785000,
        "value": 139.504,
        "_internal_originalTime": 1689754785000
    },
    {
        "time": 1689754786000,
        "value": 139.502,
        "_internal_originalTime": 1689754786000
    },
    {
        "time": 1689754787000,
        "value": 139.505,
        "_internal_originalTime": 1689754787000
    },
    {
        "time": 1689754788000,
        "value": 139.504,
        "_internal_originalTime": 1689754788000
    },
    {
        "time": 1689754789000,
        "value": 139.51,
        "_internal_originalTime": 1689754789000
    },
    {
        "time": 1689754790000,
        "value": 139.51,
        "_internal_originalTime": 1689754790000
    },
    {
        "time": 1689754791000,
        "value": 139.509,
        "_internal_originalTime": 1689754791000
    },
    {
        "time": 1689754793000,
        "value": 139.51,
        "_internal_originalTime": 1689754793000
    },
    {
        "time": 1689754794000,
        "value": 139.508,
        "_internal_originalTime": 1689754794000
    },
    {
        "time": 1689754796000,
        "value": 139.511,
        "_internal_originalTime": 1689754796000
    },
    {
        "time": 1689754797000,
        "value": 139.504,
        "_internal_originalTime": 1689754797000
    },
    {
        "time": 1689754798000,
        "value": 139.502,
        "_internal_originalTime": 1689754798000
    },
    {
        "time": 1689754800000,
        "value": 139.511,
        "_internal_originalTime": 1689754800000
    },
    {
        "time": 1689754801000,
        "value": 139.512,
        "_internal_originalTime": 1689754801000
    },
    {
        "time": 1689754802000,
        "value": 139.51,
        "_internal_originalTime": 1689754802000
    },
    {
        "time": 1689754803000,
        "value": 139.51,
        "_internal_originalTime": 1689754803000
    },
    {
        "time": 1689754804000,
        "value": 139.508,
        "_internal_originalTime": 1689754804000
    },
    {
        "time": 1689754805000,
        "value": 139.509,
        "_internal_originalTime": 1689754805000
    },
    {
        "time": 1689754806000,
        "value": 139.511,
        "_internal_originalTime": 1689754806000
    },
    {
        "time": 1689754808000,
        "value": 139.512,
        "_internal_originalTime": 1689754808000
    },
    {
        "time": 1689754809000,
        "value": 139.509,
        "_internal_originalTime": 1689754809000
    },
    {
        "time": 1689754811000,
        "value": 139.51,
        "_internal_originalTime": 1689754811000
    },
    {
        "time": 1689754813000,
        "value": 139.514,
        "_internal_originalTime": 1689754813000
    },
    {
        "time": 1689754814000,
        "value": 139.512,
        "_internal_originalTime": 1689754814000
    },
    {
        "time": 1689754815000,
        "value": 139.509,
        "_internal_originalTime": 1689754815000
    },
    {
        "time": 1689754816000,
        "value": 139.51,
        "_internal_originalTime": 1689754816000
    },
    {
        "time": 1689754817000,
        "value": 139.509,
        "_internal_originalTime": 1689754817000
    },
    {
        "time": 1689754819000,
        "value": 139.507,
        "_internal_originalTime": 1689754819000
    },
    {
        "time": 1689754820000,
        "value": 139.509,
        "_internal_originalTime": 1689754820000
    },
    {
        "time": 1689754821000,
        "value": 139.513,
        "_internal_originalTime": 1689754821000
    },
    {
        "time": 1689754822000,
        "value": 139.512,
        "_internal_originalTime": 1689754822000
    },
    {
        "time": 1689754823000,
        "value": 139.513,
        "_internal_originalTime": 1689754823000
    },
    {
        "time": 1689754825000,
        "value": 139.506,
        "_internal_originalTime": 1689754825000
    },
    {
        "time": 1689754826000,
        "value": 139.509,
        "_internal_originalTime": 1689754826000
    },
    {
        "time": 1689754827000,
        "value": 139.51,
        "_internal_originalTime": 1689754827000
    },
    {
        "time": 1689754828000,
        "value": 139.51,
        "_internal_originalTime": 1689754828000
    },
    {
        "time": 1689754829000,
        "value": 139.514,
        "_internal_originalTime": 1689754829000
    },
    {
        "time": 1689754831000,
        "value": 139.517,
        "_internal_originalTime": 1689754831000
    },
    {
        "time": 1689754833000,
        "value": 139.512,
        "_internal_originalTime": 1689754833000
    },
    {
        "time": 1689754834000,
        "value": 139.502,
        "_internal_originalTime": 1689754834000
    },
    {
        "time": 1689754835000,
        "value": 139.503,
        "_internal_originalTime": 1689754835000
    },
    {
        "time": 1689754837000,
        "value": 139.506,
        "_internal_originalTime": 1689754837000
    },
    {
        "time": 1689754838000,
        "value": 139.509,
        "_internal_originalTime": 1689754838000
    },
    {
        "time": 1689754839000,
        "value": 139.506,
        "_internal_originalTime": 1689754839000
    },
    {
        "time": 1689754840000,
        "value": 139.511,
        "_internal_originalTime": 1689754840000
    },
    {
        "time": 1689754842000,
        "value": 139.511,
        "_internal_originalTime": 1689754842000
    },
    {
        "time": 1689754843000,
        "value": 139.511,
        "_internal_originalTime": 1689754843000
    },
    {
        "time": 1689754844000,
        "value": 139.512,
        "_internal_originalTime": 1689754844000
    },
    {
        "time": 1689754845000,
        "value": 139.513,
        "_internal_originalTime": 1689754845000
    },
    {
        "time": 1689754847000,
        "value": 139.508,
        "_internal_originalTime": 1689754847000
    },
    {
        "time": 1689754849000,
        "value": 139.511,
        "_internal_originalTime": 1689754849000
    },
    {
        "time": 1689754850000,
        "value": 139.51,
        "_internal_originalTime": 1689754850000
    },
    {
        "time": 1689754851000,
        "value": 139.509,
        "_internal_originalTime": 1689754851000
    },
    {
        "time": 1689754853000,
        "value": 139.507,
        "_internal_originalTime": 1689754853000
    },
    {
        "time": 1689754854000,
        "value": 139.507,
        "_internal_originalTime": 1689754854000
    },
    {
        "time": 1689754855000,
        "value": 139.511,
        "_internal_originalTime": 1689754855000
    },
    {
        "time": 1689754857000,
        "value": 139.508,
        "_internal_originalTime": 1689754857000
    },
    {
        "time": 1689754859000,
        "value": 139.513,
        "_internal_originalTime": 1689754859000
    },
    {
        "time": 1689754860000,
        "value": 139.508,
        "_internal_originalTime": 1689754860000
    },
    {
        "time": 1689754861000,
        "value": 139.509,
        "_internal_originalTime": 1689754861000
    },
    {
        "time": 1689754862000,
        "value": 139.509,
        "_internal_originalTime": 1689754862000
    },
    {
        "time": 1689754863000,
        "value": 139.51,
        "_internal_originalTime": 1689754863000
    },
    {
        "time": 1689754864000,
        "value": 139.51,
        "_internal_originalTime": 1689754864000
    },
    {
        "time": 1689754865000,
        "value": 139.51,
        "_internal_originalTime": 1689754865000
    },
    {
        "time": 1689754866000,
        "value": 139.509,
        "_internal_originalTime": 1689754866000
    },
    {
        "time": 1689754868000,
        "value": 139.51,
        "_internal_originalTime": 1689754868000
    },
    {
        "time": 1689754869000,
        "value": 139.511,
        "_internal_originalTime": 1689754869000
    },
    {
        "time": 1689754870000,
        "value": 139.511,
        "_internal_originalTime": 1689754870000
    },
    {
        "time": 1689754871000,
        "value": 139.515,
        "_internal_originalTime": 1689754871000
    },
    {
        "time": 1689754873000,
        "value": 139.513,
        "_internal_originalTime": 1689754873000
    },
    {
        "time": 1689754874000,
        "value": 139.516,
        "_internal_originalTime": 1689754874000
    },
    {
        "time": 1689754875000,
        "value": 139.513,
        "_internal_originalTime": 1689754875000
    },
    {
        "time": 1689754876000,
        "value": 139.512,
        "_internal_originalTime": 1689754876000
    },
    {
        "time": 1689754877000,
        "value": 139.516,
        "_internal_originalTime": 1689754877000
    },
    {
        "time": 1689754879000,
        "value": 139.517,
        "_internal_originalTime": 1689754879000
    },
    {
        "time": 1689754880000,
        "value": 139.512,
        "_internal_originalTime": 1689754880000
    },
    {
        "time": 1689754881000,
        "value": 139.516,
        "_internal_originalTime": 1689754881000
    },
    {
        "time": 1689754882000,
        "value": 139.517,
        "_internal_originalTime": 1689754882000
    },
    {
        "time": 1689754883000,
        "value": 139.519,
        "_internal_originalTime": 1689754883000
    },
    {
        "time": 1689754886000,
        "value": 139.519,
        "_internal_originalTime": 1689754886000
    },
    {
        "time": 1689754887000,
        "value": 139.518,
        "_internal_originalTime": 1689754887000
    },
    {
        "time": 1689754889000,
        "value": 139.52,
        "_internal_originalTime": 1689754889000
    },
    {
        "time": 1689754890000,
        "value": 139.521,
        "_internal_originalTime": 1689754890000
    },
    {
        "time": 1689754891000,
        "value": 139.521,
        "_internal_originalTime": 1689754891000
    },
    {
        "time": 1689754893000,
        "value": 139.52,
        "_internal_originalTime": 1689754893000
    },
    {
        "time": 1689754894000,
        "value": 139.522,
        "_internal_originalTime": 1689754894000
    },
    {
        "time": 1689754895000,
        "value": 139.52,
        "_internal_originalTime": 1689754895000
    },
    {
        "time": 1689754896000,
        "value": 139.518,
        "_internal_originalTime": 1689754896000
    },
    {
        "time": 1689754897000,
        "value": 139.516,
        "_internal_originalTime": 1689754897000
    },
    {
        "time": 1689754900000,
        "value": 139.515,
        "_internal_originalTime": 1689754900000
    },
    {
        "time": 1689754901000,
        "value": 139.518,
        "_internal_originalTime": 1689754901000
    },
    {
        "time": 1689754902000,
        "value": 139.521,
        "_internal_originalTime": 1689754902000
    },
    {
        "time": 1689754905000,
        "value": 139.524,
        "_internal_originalTime": 1689754905000
    },
    {
        "time": 1689754906000,
        "value": 139.529,
        "_internal_originalTime": 1689754906000
    },
    {
        "time": 1689754907000,
        "value": 139.524,
        "_internal_originalTime": 1689754907000
    },
    {
        "time": 1689754908000,
        "value": 139.523,
        "_internal_originalTime": 1689754908000
    },
    {
        "time": 1689754909000,
        "value": 139.522,
        "_internal_originalTime": 1689754909000
    },
    {
        "time": 1689754910000,
        "value": 139.523,
        "_internal_originalTime": 1689754910000
    },
    {
        "time": 1689754911000,
        "value": 139.521,
        "_internal_originalTime": 1689754911000
    },
    {
        "time": 1689754913000,
        "value": 139.524,
        "_internal_originalTime": 1689754913000
    },
    {
        "time": 1689754914000,
        "value": 139.522,
        "_internal_originalTime": 1689754914000
    },
    {
        "time": 1689754916000,
        "value": 139.521,
        "_internal_originalTime": 1689754916000
    },
    {
        "time": 1689754917000,
        "value": 139.521,
        "_internal_originalTime": 1689754917000
    },
    {
        "time": 1689754918000,
        "value": 139.519,
        "_internal_originalTime": 1689754918000
    },
    {
        "time": 1689754919000,
        "value": 139.524,
        "_internal_originalTime": 1689754919000
    },
    {
        "time": 1689754920000,
        "value": 139.529,
        "_internal_originalTime": 1689754920000
    },
    {
        "time": 1689754921000,
        "value": 139.52,
        "_internal_originalTime": 1689754921000
    },
    {
        "time": 1689754923000,
        "value": 139.522,
        "_internal_originalTime": 1689754923000
    },
    {
        "time": 1689754924000,
        "value": 139.521,
        "_internal_originalTime": 1689754924000
    },
    {
        "time": 1689754926000,
        "value": 139.518,
        "_internal_originalTime": 1689754926000
    },
    {
        "time": 1689754927000,
        "value": 139.519,
        "_internal_originalTime": 1689754927000
    },
    {
        "time": 1689754928000,
        "value": 139.519,
        "_internal_originalTime": 1689754928000
    },
    {
        "time": 1689754929000,
        "value": 139.52,
        "_internal_originalTime": 1689754929000
    },
    {
        "time": 1689754931000,
        "value": 139.516,
        "_internal_originalTime": 1689754931000
    },
    {
        "time": 1689754932000,
        "value": 139.517,
        "_internal_originalTime": 1689754932000
    },
    {
        "time": 1689754933000,
        "value": 139.52,
        "_internal_originalTime": 1689754933000
    },
    {
        "time": 1689754934000,
        "value": 139.518,
        "_internal_originalTime": 1689754934000
    },
    {
        "time": 1689754935000,
        "value": 139.521,
        "_internal_originalTime": 1689754935000
    },
    {
        "time": 1689754936000,
        "value": 139.521,
        "_internal_originalTime": 1689754936000
    },
    {
        "time": 1689754937000,
        "value": 139.526,
        "_internal_originalTime": 1689754937000
    },
    {
        "time": 1689754938000,
        "value": 139.524,
        "_internal_originalTime": 1689754938000
    },
    {
        "time": 1689754939000,
        "value": 139.523,
        "_internal_originalTime": 1689754939000
    },
    {
        "time": 1689754941000,
        "value": 139.525,
        "_internal_originalTime": 1689754941000
    },
    {
        "time": 1689754942000,
        "value": 139.521,
        "_internal_originalTime": 1689754942000
    },
    {
        "time": 1689754944000,
        "value": 139.519,
        "_internal_originalTime": 1689754944000
    },
    {
        "time": 1689754945000,
        "value": 139.518,
        "_internal_originalTime": 1689754945000
    },
    {
        "time": 1689754947000,
        "value": 139.52,
        "_internal_originalTime": 1689754947000
    },
    {
        "time": 1689754948000,
        "value": 139.525,
        "_internal_originalTime": 1689754948000
    },
    {
        "time": 1689754949000,
        "value": 139.524,
        "_internal_originalTime": 1689754949000
    },
    {
        "time": 1689754950000,
        "value": 139.521,
        "_internal_originalTime": 1689754950000
    },
    {
        "time": 1689754952000,
        "value": 139.523,
        "_internal_originalTime": 1689754952000
    },
    {
        "time": 1689754953000,
        "value": 139.522,
        "_internal_originalTime": 1689754953000
    },
    {
        "time": 1689754955000,
        "value": 139.52,
        "_internal_originalTime": 1689754955000
    },
    {
        "time": 1689754956000,
        "value": 139.52,
        "_internal_originalTime": 1689754956000
    },
    {
        "time": 1689754958000,
        "value": 139.52,
        "_internal_originalTime": 1689754958000
    },
    {
        "time": 1689754960000,
        "value": 139.521,
        "_internal_originalTime": 1689754960000
    },
    {
        "time": 1689754961000,
        "value": 139.524,
        "_internal_originalTime": 1689754961000
    },
    {
        "time": 1689754962000,
        "value": 139.523,
        "_internal_originalTime": 1689754962000
    },
    {
        "time": 1689754963000,
        "value": 139.518,
        "_internal_originalTime": 1689754963000
    },
    {
        "time": 1689754965000,
        "value": 139.523,
        "_internal_originalTime": 1689754965000
    },
    {
        "time": 1689754967000,
        "value": 139.52,
        "_internal_originalTime": 1689754967000
    },
    {
        "time": 1689754968000,
        "value": 139.523,
        "_internal_originalTime": 1689754968000
    },
    {
        "time": 1689754969000,
        "value": 139.52,
        "_internal_originalTime": 1689754969000
    },
    {
        "time": 1689754970000,
        "value": 139.519,
        "_internal_originalTime": 1689754970000
    },
    {
        "time": 1689754971000,
        "value": 139.519,
        "_internal_originalTime": 1689754971000
    },
    {
        "time": 1689754973000,
        "value": 139.523,
        "_internal_originalTime": 1689754973000
    },
    {
        "time": 1689754974000,
        "value": 139.525,
        "_internal_originalTime": 1689754974000
    },
    {
        "time": 1689754975000,
        "value": 139.526,
        "_internal_originalTime": 1689754975000
    },
    {
        "time": 1689754976000,
        "value": 139.524,
        "_internal_originalTime": 1689754976000
    },
    {
        "time": 1689754978000,
        "value": 139.522,
        "_internal_originalTime": 1689754978000
    },
    {
        "time": 1689754980000,
        "value": 139.523,
        "_internal_originalTime": 1689754980000
    },
    {
        "time": 1689754981000,
        "value": 139.521,
        "_internal_originalTime": 1689754981000
    },
    {
        "time": 1689754982000,
        "value": 139.52,
        "_internal_originalTime": 1689754982000
    },
    {
        "time": 1689754983000,
        "value": 139.524,
        "_internal_originalTime": 1689754983000
    },
    {
        "time": 1689754984000,
        "value": 139.528,
        "_internal_originalTime": 1689754984000
    },
    {
        "time": 1689754985000,
        "value": 139.522,
        "_internal_originalTime": 1689754985000
    },
    {
        "time": 1689754988000,
        "value": 139.526,
        "_internal_originalTime": 1689754988000
    },
    {
        "time": 1689754989000,
        "value": 139.527,
        "_internal_originalTime": 1689754989000
    },
    {
        "time": 1689754990000,
        "value": 139.522,
        "_internal_originalTime": 1689754990000
    },
    {
        "time": 1689754992000,
        "value": 139.523,
        "_internal_originalTime": 1689754992000
    },
    {
        "time": 1689754993000,
        "value": 139.524,
        "_internal_originalTime": 1689754993000
    },
    {
        "time": 1689754994000,
        "value": 139.525,
        "_internal_originalTime": 1689754994000
    },
    {
        "time": 1689754996000,
        "value": 139.522,
        "_internal_originalTime": 1689754996000
    },
    {
        "time": 1689754998000,
        "value": 139.525,
        "_internal_originalTime": 1689754998000
    },
    {
        "time": 1689755000000,
        "value": 139.521,
        "_internal_originalTime": 1689755000000
    },
    {
        "time": 1689755001000,
        "value": 139.52,
        "_internal_originalTime": 1689755001000
    },
    {
        "time": 1689755002000,
        "value": 139.525,
        "_internal_originalTime": 1689755002000
    },
    {
        "time": 1689755003000,
        "value": 139.523,
        "_internal_originalTime": 1689755003000
    },
    {
        "time": 1689755005000,
        "value": 139.524,
        "_internal_originalTime": 1689755005000
    },
    {
        "time": 1689755006000,
        "value": 139.528,
        "_internal_originalTime": 1689755006000
    },
    {
        "time": 1689755008000,
        "value": 139.529,
        "_internal_originalTime": 1689755008000
    },
    {
        "time": 1689755009000,
        "value": 139.528,
        "_internal_originalTime": 1689755009000
    },
    {
        "time": 1689755011000,
        "value": 139.53,
        "_internal_originalTime": 1689755011000
    },
    {
        "time": 1689755013000,
        "value": 139.524,
        "_internal_originalTime": 1689755013000
    },
    {
        "time": 1689755014000,
        "value": 139.531,
        "_internal_originalTime": 1689755014000
    },
    {
        "time": 1689755015000,
        "value": 139.527,
        "_internal_originalTime": 1689755015000
    },
    {
        "time": 1689755018000,
        "value": 139.529,
        "_internal_originalTime": 1689755018000
    },
    {
        "time": 1689755019000,
        "value": 139.525,
        "_internal_originalTime": 1689755019000
    },
    {
        "time": 1689755020000,
        "value": 139.525,
        "_internal_originalTime": 1689755020000
    },
    {
        "time": 1689755021000,
        "value": 139.532,
        "_internal_originalTime": 1689755021000
    },
    {
        "time": 1689755022000,
        "value": 139.536,
        "_internal_originalTime": 1689755022000
    },
    {
        "time": 1689755023000,
        "value": 139.539,
        "_internal_originalTime": 1689755023000
    },
    {
        "time": 1689755026000,
        "value": 139.542,
        "_internal_originalTime": 1689755026000
    },
    {
        "time": 1689755027000,
        "value": 139.541,
        "_internal_originalTime": 1689755027000
    },
    {
        "time": 1689755028000,
        "value": 139.543,
        "_internal_originalTime": 1689755028000
    },
    {
        "time": 1689755030000,
        "value": 139.543,
        "_internal_originalTime": 1689755030000
    },
    {
        "time": 1689755032000,
        "value": 139.54,
        "_internal_originalTime": 1689755032000
    },
    {
        "time": 1689755034000,
        "value": 139.541,
        "_internal_originalTime": 1689755034000
    },
    {
        "time": 1689755035000,
        "value": 139.549,
        "_internal_originalTime": 1689755035000
    },
    {
        "time": 1689755037000,
        "value": 139.55,
        "_internal_originalTime": 1689755037000
    },
    {
        "time": 1689755038000,
        "value": 139.544,
        "_internal_originalTime": 1689755038000
    },
    {
        "time": 1689755040000,
        "value": 139.551,
        "_internal_originalTime": 1689755040000
    },
    {
        "time": 1689755041000,
        "value": 139.53,
        "_internal_originalTime": 1689755041000
    },
    {
        "time": 1689755042000,
        "value": 139.552,
        "_internal_originalTime": 1689755042000
    },
    {
        "time": 1689755043000,
        "value": 139.545,
        "_internal_originalTime": 1689755043000
    },
    {
        "time": 1689755044000,
        "value": 139.547,
        "_internal_originalTime": 1689755044000
    },
    {
        "time": 1689755045000,
        "value": 139.549,
        "_internal_originalTime": 1689755045000
    },
    {
        "time": 1689755046000,
        "value": 139.547,
        "_internal_originalTime": 1689755046000
    },
    {
        "time": 1689755047000,
        "value": 139.551,
        "_internal_originalTime": 1689755047000
    },
    {
        "time": 1689755048000,
        "value": 139.549,
        "_internal_originalTime": 1689755048000
    },
    {
        "time": 1689755050000,
        "value": 139.552,
        "_internal_originalTime": 1689755050000
    },
    {
        "time": 1689755051000,
        "value": 139.55,
        "_internal_originalTime": 1689755051000
    },
    {
        "time": 1689755052000,
        "value": 139.551,
        "_internal_originalTime": 1689755052000
    },
    {
        "time": 1689755053000,
        "value": 139.548,
        "_internal_originalTime": 1689755053000
    },
    {
        "time": 1689755054000,
        "value": 139.547,
        "_internal_originalTime": 1689755054000
    },
    {
        "time": 1689755055000,
        "value": 139.549,
        "_internal_originalTime": 1689755055000
    },
    {
        "time": 1689755056000,
        "value": 139.547,
        "_internal_originalTime": 1689755056000
    },
    {
        "time": 1689755057000,
        "value": 139.548,
        "_internal_originalTime": 1689755057000
    },
    {
        "time": 1689755058000,
        "value": 139.551,
        "_internal_originalTime": 1689755058000
    },
    {
        "time": 1689755059000,
        "value": 139.548,
        "_internal_originalTime": 1689755059000
    },
    {
        "time": 1689755061000,
        "value": 139.548,
        "_internal_originalTime": 1689755061000
    },
    {
        "time": 1689755062000,
        "value": 139.553,
        "_internal_originalTime": 1689755062000
    },
    {
        "time": 1689755063000,
        "value": 139.553,
        "_internal_originalTime": 1689755063000
    },
    {
        "time": 1689755065000,
        "value": 139.554,
        "_internal_originalTime": 1689755065000
    },
    {
        "time": 1689755067000,
        "value": 139.555,
        "_internal_originalTime": 1689755067000
    },
    {
        "time": 1689755068000,
        "value": 139.555,
        "_internal_originalTime": 1689755068000
    },
    {
        "time": 1689755069000,
        "value": 139.553,
        "_internal_originalTime": 1689755069000
    },
    {
        "time": 1689755070000,
        "value": 139.555,
        "_internal_originalTime": 1689755070000
    },
    {
        "time": 1689755071000,
        "value": 139.551,
        "_internal_originalTime": 1689755071000
    },
    {
        "time": 1689755072000,
        "value": 139.554,
        "_internal_originalTime": 1689755072000
    },
    {
        "time": 1689755073000,
        "value": 139.547,
        "_internal_originalTime": 1689755073000
    },
    {
        "time": 1689755075000,
        "value": 139.557,
        "_internal_originalTime": 1689755075000
    },
    {
        "time": 1689755076000,
        "value": 139.558,
        "_internal_originalTime": 1689755076000
    },
    {
        "time": 1689755077000,
        "value": 139.557,
        "_internal_originalTime": 1689755077000
    },
    {
        "time": 1689755078000,
        "value": 139.56,
        "_internal_originalTime": 1689755078000
    },
    {
        "time": 1689755080000,
        "value": 139.561,
        "_internal_originalTime": 1689755080000
    },
    {
        "time": 1689755081000,
        "value": 139.557,
        "_internal_originalTime": 1689755081000
    },
    {
        "time": 1689755083000,
        "value": 139.557,
        "_internal_originalTime": 1689755083000
    },
    {
        "time": 1689755085000,
        "value": 139.558,
        "_internal_originalTime": 1689755085000
    },
    {
        "time": 1689755086000,
        "value": 139.563,
        "_internal_originalTime": 1689755086000
    },
    {
        "time": 1689755088000,
        "value": 139.561,
        "_internal_originalTime": 1689755088000
    },
    {
        "time": 1689755089000,
        "value": 139.56,
        "_internal_originalTime": 1689755089000
    },
    {
        "time": 1689755090000,
        "value": 139.559,
        "_internal_originalTime": 1689755090000
    },
    {
        "time": 1689755091000,
        "value": 139.558,
        "_internal_originalTime": 1689755091000
    },
    {
        "time": 1689755092000,
        "value": 139.558,
        "_internal_originalTime": 1689755092000
    },
    {
        "time": 1689755093000,
        "value": 139.559,
        "_internal_originalTime": 1689755093000
    },
    {
        "time": 1689755094000,
        "value": 139.561,
        "_internal_originalTime": 1689755094000
    },
    {
        "time": 1689755095000,
        "value": 139.559,
        "_internal_originalTime": 1689755095000
    },
    {
        "time": 1689755096000,
        "value": 139.557,
        "_internal_originalTime": 1689755096000
    },
    {
        "time": 1689755097000,
        "value": 139.557,
        "_internal_originalTime": 1689755097000
    },
    {
        "time": 1689755099000,
        "value": 139.557,
        "_internal_originalTime": 1689755099000
    },
    {
        "time": 1689755100000,
        "value": 139.552,
        "_internal_originalTime": 1689755100000
    },
    {
        "time": 1689755101000,
        "value": 139.556,
        "_internal_originalTime": 1689755101000
    },
    {
        "time": 1689755102000,
        "value": 139.559,
        "_internal_originalTime": 1689755102000
    },
    {
        "time": 1689755104000,
        "value": 139.557,
        "_internal_originalTime": 1689755104000
    },
    {
        "time": 1689755105000,
        "value": 139.543,
        "_internal_originalTime": 1689755105000
    },
    {
        "time": 1689755106000,
        "value": 139.543,
        "_internal_originalTime": 1689755106000
    },
    {
        "time": 1689755108000,
        "value": 139.54,
        "_internal_originalTime": 1689755108000
    },
    {
        "time": 1689755109000,
        "value": 139.54,
        "_internal_originalTime": 1689755109000
    },
    {
        "time": 1689755110000,
        "value": 139.541,
        "_internal_originalTime": 1689755110000
    },
    {
        "time": 1689755111000,
        "value": 139.541,
        "_internal_originalTime": 1689755111000
    },
    {
        "time": 1689755112000,
        "value": 139.538,
        "_internal_originalTime": 1689755112000
    },
    {
        "time": 1689755113000,
        "value": 139.541,
        "_internal_originalTime": 1689755113000
    },
    {
        "time": 1689755114000,
        "value": 139.541,
        "_internal_originalTime": 1689755114000
    },
    {
        "time": 1689755115000,
        "value": 139.542,
        "_internal_originalTime": 1689755115000
    },
    {
        "time": 1689755116000,
        "value": 139.538,
        "_internal_originalTime": 1689755116000
    },
    {
        "time": 1689755117000,
        "value": 139.541,
        "_internal_originalTime": 1689755117000
    },
    {
        "time": 1689755118000,
        "value": 139.54,
        "_internal_originalTime": 1689755118000
    },
    {
        "time": 1689755119000,
        "value": 139.541,
        "_internal_originalTime": 1689755119000
    },
    {
        "time": 1689755120000,
        "value": 139.539,
        "_internal_originalTime": 1689755120000
    },
    {
        "time": 1689755121000,
        "value": 139.537,
        "_internal_originalTime": 1689755121000
    },
    {
        "time": 1689755123000,
        "value": 139.538,
        "_internal_originalTime": 1689755123000
    },
    {
        "time": 1689755124000,
        "value": 139.536,
        "_internal_originalTime": 1689755124000
    },
    {
        "time": 1689755125000,
        "value": 139.536,
        "_internal_originalTime": 1689755125000
    },
    {
        "time": 1689755127000,
        "value": 139.537,
        "_internal_originalTime": 1689755127000
    },
    {
        "time": 1689755128000,
        "value": 139.538,
        "_internal_originalTime": 1689755128000
    },
    {
        "time": 1689755130000,
        "value": 139.534,
        "_internal_originalTime": 1689755130000
    },
    {
        "time": 1689755131000,
        "value": 139.529,
        "_internal_originalTime": 1689755131000
    },
    {
        "time": 1689755132000,
        "value": 139.534,
        "_internal_originalTime": 1689755132000
    },
    {
        "time": 1689755134000,
        "value": 139.529,
        "_internal_originalTime": 1689755134000
    },
    {
        "time": 1689755135000,
        "value": 139.529,
        "_internal_originalTime": 1689755135000
    },
    {
        "time": 1689755136000,
        "value": 139.531,
        "_internal_originalTime": 1689755136000
    },
    {
        "time": 1689755137000,
        "value": 139.528,
        "_internal_originalTime": 1689755137000
    },
    {
        "time": 1689755138000,
        "value": 139.526,
        "_internal_originalTime": 1689755138000
    },
    {
        "time": 1689755139000,
        "value": 139.526,
        "_internal_originalTime": 1689755139000
    },
    {
        "time": 1689755140000,
        "value": 139.526,
        "_internal_originalTime": 1689755140000
    },
    {
        "time": 1689755141000,
        "value": 139.528,
        "_internal_originalTime": 1689755141000
    },
    {
        "time": 1689755142000,
        "value": 139.53,
        "_internal_originalTime": 1689755142000
    },
    {
        "time": 1689755143000,
        "value": 139.526,
        "_internal_originalTime": 1689755143000
    },
    {
        "time": 1689755144000,
        "value": 139.528,
        "_internal_originalTime": 1689755144000
    },
    {
        "time": 1689755145000,
        "value": 139.524,
        "_internal_originalTime": 1689755145000
    },
    {
        "time": 1689755146000,
        "value": 139.523,
        "_internal_originalTime": 1689755146000
    },
    {
        "time": 1689755148000,
        "value": 139.525,
        "_internal_originalTime": 1689755148000
    },
    {
        "time": 1689755149000,
        "value": 139.52,
        "_internal_originalTime": 1689755149000
    },
    {
        "time": 1689755150000,
        "value": 139.528,
        "_internal_originalTime": 1689755150000
    },
    {
        "time": 1689755151000,
        "value": 139.526,
        "_internal_originalTime": 1689755151000
    },
    {
        "time": 1689755153000,
        "value": 139.527,
        "_internal_originalTime": 1689755153000
    },
    {
        "time": 1689755154000,
        "value": 139.532,
        "_internal_originalTime": 1689755154000
    },
    {
        "time": 1689755156000,
        "value": 139.532,
        "_internal_originalTime": 1689755156000
    },
    {
        "time": 1689755157000,
        "value": 139.531,
        "_internal_originalTime": 1689755157000
    },
    {
        "time": 1689755158000,
        "value": 139.531,
        "_internal_originalTime": 1689755158000
    },
    {
        "time": 1689755160000,
        "value": 139.53,
        "_internal_originalTime": 1689755160000
    },
    {
        "time": 1689755161000,
        "value": 139.531,
        "_internal_originalTime": 1689755161000
    },
    {
        "time": 1689755162000,
        "value": 139.526,
        "_internal_originalTime": 1689755162000
    },
    {
        "time": 1689755164000,
        "value": 139.528,
        "_internal_originalTime": 1689755164000
    },
    {
        "time": 1689755165000,
        "value": 139.529,
        "_internal_originalTime": 1689755165000
    },
    {
        "time": 1689755167000,
        "value": 139.527,
        "_internal_originalTime": 1689755167000
    },
    {
        "time": 1689755168000,
        "value": 139.524,
        "_internal_originalTime": 1689755168000
    },
    {
        "time": 1689755169000,
        "value": 139.529,
        "_internal_originalTime": 1689755169000
    },
    {
        "time": 1689755170000,
        "value": 139.531,
        "_internal_originalTime": 1689755170000
    },
    {
        "time": 1689755172000,
        "value": 139.533,
        "_internal_originalTime": 1689755172000
    },
    {
        "time": 1689755173000,
        "value": 139.538,
        "_internal_originalTime": 1689755173000
    },
    {
        "time": 1689755175000,
        "value": 139.531,
        "_internal_originalTime": 1689755175000
    },
    {
        "time": 1689755177000,
        "value": 139.532,
        "_internal_originalTime": 1689755177000
    },
    {
        "time": 1689755178000,
        "value": 139.534,
        "_internal_originalTime": 1689755178000
    },
    {
        "time": 1689755179000,
        "value": 139.529,
        "_internal_originalTime": 1689755179000
    },
    {
        "time": 1689755180000,
        "value": 139.528,
        "_internal_originalTime": 1689755180000
    },
    {
        "time": 1689755181000,
        "value": 139.531,
        "_internal_originalTime": 1689755181000
    },
    {
        "time": 1689755183000,
        "value": 139.528,
        "_internal_originalTime": 1689755183000
    },
    {
        "time": 1689755184000,
        "value": 139.533,
        "_internal_originalTime": 1689755184000
    },
    {
        "time": 1689755185000,
        "value": 139.528,
        "_internal_originalTime": 1689755185000
    },
    {
        "time": 1689755186000,
        "value": 139.529,
        "_internal_originalTime": 1689755186000
    },
    {
        "time": 1689755188000,
        "value": 139.527,
        "_internal_originalTime": 1689755188000
    },
    {
        "time": 1689755190000,
        "value": 139.529,
        "_internal_originalTime": 1689755190000
    },
    {
        "time": 1689755192000,
        "value": 139.526,
        "_internal_originalTime": 1689755192000
    },
    {
        "time": 1689755193000,
        "value": 139.517,
        "_internal_originalTime": 1689755193000
    },
    {
        "time": 1689755194000,
        "value": 139.516,
        "_internal_originalTime": 1689755194000
    },
    {
        "time": 1689755195000,
        "value": 139.512,
        "_internal_originalTime": 1689755195000
    },
    {
        "time": 1689755196000,
        "value": 139.508,
        "_internal_originalTime": 1689755196000
    },
    {
        "time": 1689755197000,
        "value": 139.514,
        "_internal_originalTime": 1689755197000
    },
    {
        "time": 1689755199000,
        "value": 139.514,
        "_internal_originalTime": 1689755199000
    },
    {
        "time": 1689755200000,
        "value": 139.509,
        "_internal_originalTime": 1689755200000
    },
    {
        "time": 1689755201000,
        "value": 139.513,
        "_internal_originalTime": 1689755201000
    },
    {
        "time": 1689755205000,
        "value": 139.51,
        "_internal_originalTime": 1689755205000
    },
    {
        "time": 1689755207000,
        "value": 139.514,
        "_internal_originalTime": 1689755207000
    },
    {
        "time": 1689755208000,
        "value": 139.512,
        "_internal_originalTime": 1689755208000
    },
    {
        "time": 1689755211000,
        "value": 139.513,
        "_internal_originalTime": 1689755211000
    },
    {
        "time": 1689755212000,
        "value": 139.513,
        "_internal_originalTime": 1689755212000
    },
    {
        "time": 1689755213000,
        "value": 139.514,
        "_internal_originalTime": 1689755213000
    },
    {
        "time": 1689755215000,
        "value": 139.513,
        "_internal_originalTime": 1689755215000
    },
    {
        "time": 1689755216000,
        "value": 139.516,
        "_internal_originalTime": 1689755216000
    },
    {
        "time": 1689755218000,
        "value": 139.512,
        "_internal_originalTime": 1689755218000
    },
    {
        "time": 1689755220000,
        "value": 139.509,
        "_internal_originalTime": 1689755220000
    },
    {
        "time": 1689755221000,
        "value": 139.507,
        "_internal_originalTime": 1689755221000
    },
    {
        "time": 1689755222000,
        "value": 139.503,
        "_internal_originalTime": 1689755222000
    },
    {
        "time": 1689755223000,
        "value": 139.51,
        "_internal_originalTime": 1689755223000
    },
    {
        "time": 1689755225000,
        "value": 139.507,
        "_internal_originalTime": 1689755225000
    },
    {
        "time": 1689755226000,
        "value": 139.51,
        "_internal_originalTime": 1689755226000
    },
    {
        "time": 1689755228000,
        "value": 139.507,
        "_internal_originalTime": 1689755228000
    },
    {
        "time": 1689755229000,
        "value": 139.514,
        "_internal_originalTime": 1689755229000
    },
    {
        "time": 1689755230000,
        "value": 139.51,
        "_internal_originalTime": 1689755230000
    },
    {
        "time": 1689755231000,
        "value": 139.509,
        "_internal_originalTime": 1689755231000
    },
    {
        "time": 1689755233000,
        "value": 139.514,
        "_internal_originalTime": 1689755233000
    },
    {
        "time": 1689755234000,
        "value": 139.523,
        "_internal_originalTime": 1689755234000
    },
    {
        "time": 1689755235000,
        "value": 139.536,
        "_internal_originalTime": 1689755235000
    },
    {
        "time": 1689755236000,
        "value": 139.542,
        "_internal_originalTime": 1689755236000
    },
    {
        "time": 1689755237000,
        "value": 139.538,
        "_internal_originalTime": 1689755237000
    },
    {
        "time": 1689755238000,
        "value": 139.542,
        "_internal_originalTime": 1689755238000
    },
    {
        "time": 1689755239000,
        "value": 139.538,
        "_internal_originalTime": 1689755239000
    },
    {
        "time": 1689755240000,
        "value": 139.543,
        "_internal_originalTime": 1689755240000
    },
    {
        "time": 1689755241000,
        "value": 139.538,
        "_internal_originalTime": 1689755241000
    },
    {
        "time": 1689755242000,
        "value": 139.536,
        "_internal_originalTime": 1689755242000
    },
    {
        "time": 1689755243000,
        "value": 139.538,
        "_internal_originalTime": 1689755243000
    },
    {
        "time": 1689755244000,
        "value": 139.547,
        "_internal_originalTime": 1689755244000
    },
    {
        "time": 1689755245000,
        "value": 139.544,
        "_internal_originalTime": 1689755245000
    },
    {
        "time": 1689755246000,
        "value": 139.546,
        "_internal_originalTime": 1689755246000
    },
    {
        "time": 1689755247000,
        "value": 139.546,
        "_internal_originalTime": 1689755247000
    },
    {
        "time": 1689755248000,
        "value": 139.555,
        "_internal_originalTime": 1689755248000
    },
    {
        "time": 1689755250000,
        "value": 139.548,
        "_internal_originalTime": 1689755250000
    },
    {
        "time": 1689755251000,
        "value": 139.548,
        "_internal_originalTime": 1689755251000
    },
    {
        "time": 1689755252000,
        "value": 139.548,
        "_internal_originalTime": 1689755252000
    },
    {
        "time": 1689755254000,
        "value": 139.549,
        "_internal_originalTime": 1689755254000
    },
    {
        "time": 1689755255000,
        "value": 139.55,
        "_internal_originalTime": 1689755255000
    },
    {
        "time": 1689755258000,
        "value": 139.549,
        "_internal_originalTime": 1689755258000
    },
    {
        "time": 1689755259000,
        "value": 139.553,
        "_internal_originalTime": 1689755259000
    },
    {
        "time": 1689755261000,
        "value": 139.557,
        "_internal_originalTime": 1689755261000
    },
    {
        "time": 1689755262000,
        "value": 139.554,
        "_internal_originalTime": 1689755262000
    },
    {
        "time": 1689755263000,
        "value": 139.556,
        "_internal_originalTime": 1689755263000
    },
    {
        "time": 1689755265000,
        "value": 139.553,
        "_internal_originalTime": 1689755265000
    },
    {
        "time": 1689755266000,
        "value": 139.552,
        "_internal_originalTime": 1689755266000
    },
    {
        "time": 1689755267000,
        "value": 139.555,
        "_internal_originalTime": 1689755267000
    },
    {
        "time": 1689755268000,
        "value": 139.555,
        "_internal_originalTime": 1689755268000
    },
    {
        "time": 1689755270000,
        "value": 139.555,
        "_internal_originalTime": 1689755270000
    },
    {
        "time": 1689755271000,
        "value": 139.557,
        "_internal_originalTime": 1689755271000
    },
    {
        "time": 1689755272000,
        "value": 139.555,
        "_internal_originalTime": 1689755272000
    },
    {
        "time": 1689755273000,
        "value": 139.56,
        "_internal_originalTime": 1689755273000
    },
    {
        "time": 1689755274000,
        "value": 139.561,
        "_internal_originalTime": 1689755274000
    },
    {
        "time": 1689755277000,
        "value": 139.558,
        "_internal_originalTime": 1689755277000
    },
    {
        "time": 1689755278000,
        "value": 139.558,
        "_internal_originalTime": 1689755278000
    },
    {
        "time": 1689755280000,
        "value": 139.558,
        "_internal_originalTime": 1689755280000
    },
    {
        "time": 1689755282000,
        "value": 139.551,
        "_internal_originalTime": 1689755282000
    },
    {
        "time": 1689755283000,
        "value": 139.546,
        "_internal_originalTime": 1689755283000
    },
    {
        "time": 1689755284000,
        "value": 139.552,
        "_internal_originalTime": 1689755284000
    },
    {
        "time": 1689755285000,
        "value": 139.557,
        "_internal_originalTime": 1689755285000
    },
    {
        "time": 1689755286000,
        "value": 139.557,
        "_internal_originalTime": 1689755286000
    },
    {
        "time": 1689755288000,
        "value": 139.556,
        "_internal_originalTime": 1689755288000
    },
    {
        "time": 1689755289000,
        "value": 139.554,
        "_internal_originalTime": 1689755289000
    },
    {
        "time": 1689755291000,
        "value": 139.56,
        "_internal_originalTime": 1689755291000
    },
    {
        "time": 1689755292000,
        "value": 139.562,
        "_internal_originalTime": 1689755292000
    },
    {
        "time": 1689755293000,
        "value": 139.559,
        "_internal_originalTime": 1689755293000
    },
    {
        "time": 1689755295000,
        "value": 139.56,
        "_internal_originalTime": 1689755295000
    },
    {
        "time": 1689755296000,
        "value": 139.561,
        "_internal_originalTime": 1689755296000
    },
    {
        "time": 1689755297000,
        "value": 139.556,
        "_internal_originalTime": 1689755297000
    },
    {
        "time": 1689755298000,
        "value": 139.554,
        "_internal_originalTime": 1689755298000
    },
    {
        "time": 1689755300000,
        "value": 139.554,
        "_internal_originalTime": 1689755300000
    },
    {
        "time": 1689755301000,
        "value": 139.553,
        "_internal_originalTime": 1689755301000
    },
    {
        "time": 1689755302000,
        "value": 139.55,
        "_internal_originalTime": 1689755302000
    },
    {
        "time": 1689755303000,
        "value": 139.55,
        "_internal_originalTime": 1689755303000
    },
    {
        "time": 1689755304000,
        "value": 139.55,
        "_internal_originalTime": 1689755304000
    },
    {
        "time": 1689755305000,
        "value": 139.548,
        "_internal_originalTime": 1689755305000
    },
    {
        "time": 1689755307000,
        "value": 139.548,
        "_internal_originalTime": 1689755307000
    },
    {
        "time": 1689755308000,
        "value": 139.543,
        "_internal_originalTime": 1689755308000
    },
    {
        "time": 1689755310000,
        "value": 139.543,
        "_internal_originalTime": 1689755310000
    },
    {
        "time": 1689755311000,
        "value": 139.543,
        "_internal_originalTime": 1689755311000
    },
    {
        "time": 1689755312000,
        "value": 139.547,
        "_internal_originalTime": 1689755312000
    },
    {
        "time": 1689755313000,
        "value": 139.547,
        "_internal_originalTime": 1689755313000
    },
    {
        "time": 1689755315000,
        "value": 139.546,
        "_internal_originalTime": 1689755315000
    },
    {
        "time": 1689755317000,
        "value": 139.547,
        "_internal_originalTime": 1689755317000
    },
    {
        "time": 1689755318000,
        "value": 139.544,
        "_internal_originalTime": 1689755318000
    },
    {
        "time": 1689755319000,
        "value": 139.55,
        "_internal_originalTime": 1689755319000
    },
    {
        "time": 1689755320000,
        "value": 139.552,
        "_internal_originalTime": 1689755320000
    },
    {
        "time": 1689755321000,
        "value": 139.549,
        "_internal_originalTime": 1689755321000
    },
    {
        "time": 1689755323000,
        "value": 139.547,
        "_internal_originalTime": 1689755323000
    },
    {
        "time": 1689755324000,
        "value": 139.551,
        "_internal_originalTime": 1689755324000
    },
    {
        "time": 1689755325000,
        "value": 139.551,
        "_internal_originalTime": 1689755325000
    },
    {
        "time": 1689755326000,
        "value": 139.553,
        "_internal_originalTime": 1689755326000
    },
    {
        "time": 1689755327000,
        "value": 139.553,
        "_internal_originalTime": 1689755327000
    },
    {
        "time": 1689755328000,
        "value": 139.55,
        "_internal_originalTime": 1689755328000
    },
    {
        "time": 1689755329000,
        "value": 139.552,
        "_internal_originalTime": 1689755329000
    },
    {
        "time": 1689755331000,
        "value": 139.548,
        "_internal_originalTime": 1689755331000
    },
    {
        "time": 1689755332000,
        "value": 139.544,
        "_internal_originalTime": 1689755332000
    },
    {
        "time": 1689755333000,
        "value": 139.548,
        "_internal_originalTime": 1689755333000
    },
    {
        "time": 1689755334000,
        "value": 139.547,
        "_internal_originalTime": 1689755334000
    },
    {
        "time": 1689755336000,
        "value": 139.55,
        "_internal_originalTime": 1689755336000
    },
    {
        "time": 1689755338000,
        "value": 139.548,
        "_internal_originalTime": 1689755338000
    },
    {
        "time": 1689755339000,
        "value": 139.549,
        "_internal_originalTime": 1689755339000
    },
    {
        "time": 1689755340000,
        "value": 139.544,
        "_internal_originalTime": 1689755340000
    },
    {
        "time": 1689755341000,
        "value": 139.544,
        "_internal_originalTime": 1689755341000
    },
    {
        "time": 1689755342000,
        "value": 139.553,
        "_internal_originalTime": 1689755342000
    },
    {
        "time": 1689755343000,
        "value": 139.553,
        "_internal_originalTime": 1689755343000
    },
    {
        "time": 1689755344000,
        "value": 139.556,
        "_internal_originalTime": 1689755344000
    },
    {
        "time": 1689755346000,
        "value": 139.552,
        "_internal_originalTime": 1689755346000
    },
    {
        "time": 1689755348000,
        "value": 139.554,
        "_internal_originalTime": 1689755348000
    },
    {
        "time": 1689755349000,
        "value": 139.553,
        "_internal_originalTime": 1689755349000
    },
    {
        "time": 1689755350000,
        "value": 139.552,
        "_internal_originalTime": 1689755350000
    },
    {
        "time": 1689755352000,
        "value": 139.549,
        "_internal_originalTime": 1689755352000
    },
    {
        "time": 1689755353000,
        "value": 139.552,
        "_internal_originalTime": 1689755353000
    },
    {
        "time": 1689755355000,
        "value": 139.555,
        "_internal_originalTime": 1689755355000
    },
    {
        "time": 1689755356000,
        "value": 139.556,
        "_internal_originalTime": 1689755356000
    },
    {
        "time": 1689755357000,
        "value": 139.555,
        "_internal_originalTime": 1689755357000
    },
    {
        "time": 1689755358000,
        "value": 139.555,
        "_internal_originalTime": 1689755358000
    },
    {
        "time": 1689755359000,
        "value": 139.555,
        "_internal_originalTime": 1689755359000
    },
    {
        "time": 1689755360000,
        "value": 139.554,
        "_internal_originalTime": 1689755360000
    },
    {
        "time": 1689755362000,
        "value": 139.556,
        "_internal_originalTime": 1689755362000
    },
    {
        "time": 1689755363000,
        "value": 139.557,
        "_internal_originalTime": 1689755363000
    },
    {
        "time": 1689755364000,
        "value": 139.552,
        "_internal_originalTime": 1689755364000
    },
    {
        "time": 1689755367000,
        "value": 139.551,
        "_internal_originalTime": 1689755367000
    },
    {
        "time": 1689755368000,
        "value": 139.553,
        "_internal_originalTime": 1689755368000
    },
    {
        "time": 1689755370000,
        "value": 139.549,
        "_internal_originalTime": 1689755370000
    },
    {
        "time": 1689755371000,
        "value": 139.549,
        "_internal_originalTime": 1689755371000
    },
    {
        "time": 1689755372000,
        "value": 139.549,
        "_internal_originalTime": 1689755372000
    },
    {
        "time": 1689755373000,
        "value": 139.549,
        "_internal_originalTime": 1689755373000
    },
    {
        "time": 1689755374000,
        "value": 139.551,
        "_internal_originalTime": 1689755374000
    },
    {
        "time": 1689755375000,
        "value": 139.55,
        "_internal_originalTime": 1689755375000
    },
    {
        "time": 1689755376000,
        "value": 139.552,
        "_internal_originalTime": 1689755376000
    },
    {
        "time": 1689755378000,
        "value": 139.553,
        "_internal_originalTime": 1689755378000
    },
    {
        "time": 1689755380000,
        "value": 139.553,
        "_internal_originalTime": 1689755380000
    },
    {
        "time": 1689755381000,
        "value": 139.549,
        "_internal_originalTime": 1689755381000
    },
    {
        "time": 1689755382000,
        "value": 139.551,
        "_internal_originalTime": 1689755382000
    },
    {
        "time": 1689755384000,
        "value": 139.553,
        "_internal_originalTime": 1689755384000
    },
    {
        "time": 1689755385000,
        "value": 139.554,
        "_internal_originalTime": 1689755385000
    },
    {
        "time": 1689755386000,
        "value": 139.558,
        "_internal_originalTime": 1689755386000
    },
    {
        "time": 1689755388000,
        "value": 139.556,
        "_internal_originalTime": 1689755388000
    },
    {
        "time": 1689755389000,
        "value": 139.549,
        "_internal_originalTime": 1689755389000
    },
    {
        "time": 1689755390000,
        "value": 139.548,
        "_internal_originalTime": 1689755390000
    },
    {
        "time": 1689755391000,
        "value": 139.547,
        "_internal_originalTime": 1689755391000
    },
    {
        "time": 1689755392000,
        "value": 139.547,
        "_internal_originalTime": 1689755392000
    },
    {
        "time": 1689755393000,
        "value": 139.549,
        "_internal_originalTime": 1689755393000
    },
    {
        "time": 1689755394000,
        "value": 139.548,
        "_internal_originalTime": 1689755394000
    },
    {
        "time": 1689755395000,
        "value": 139.548,
        "_internal_originalTime": 1689755395000
    },
    {
        "time": 1689755397000,
        "value": 139.545,
        "_internal_originalTime": 1689755397000
    },
    {
        "time": 1689755398000,
        "value": 139.545,
        "_internal_originalTime": 1689755398000
    },
    {
        "time": 1689755400000,
        "value": 139.547,
        "_internal_originalTime": 1689755400000
    },
    {
        "time": 1689755401000,
        "value": 139.542,
        "_internal_originalTime": 1689755401000
    },
    {
        "time": 1689755402000,
        "value": 139.541,
        "_internal_originalTime": 1689755402000
    },
    {
        "time": 1689755404000,
        "value": 139.54,
        "_internal_originalTime": 1689755404000
    },
    {
        "time": 1689755405000,
        "value": 139.537,
        "_internal_originalTime": 1689755405000
    },
    {
        "time": 1689755406000,
        "value": 139.535,
        "_internal_originalTime": 1689755406000
    },
    {
        "time": 1689755407000,
        "value": 139.54,
        "_internal_originalTime": 1689755407000
    },
    {
        "time": 1689755408000,
        "value": 139.547,
        "_internal_originalTime": 1689755408000
    },
    {
        "time": 1689755409000,
        "value": 139.542,
        "_internal_originalTime": 1689755409000
    },
    {
        "time": 1689755410000,
        "value": 139.54,
        "_internal_originalTime": 1689755410000
    },
    {
        "time": 1689755412000,
        "value": 139.541,
        "_internal_originalTime": 1689755412000
    },
    {
        "time": 1689755413000,
        "value": 139.543,
        "_internal_originalTime": 1689755413000
    },
    {
        "time": 1689755414000,
        "value": 139.54,
        "_internal_originalTime": 1689755414000
    },
    {
        "time": 1689755415000,
        "value": 139.541,
        "_internal_originalTime": 1689755415000
    },
    {
        "time": 1689755416000,
        "value": 139.541,
        "_internal_originalTime": 1689755416000
    },
    {
        "time": 1689755417000,
        "value": 139.536,
        "_internal_originalTime": 1689755417000
    },
    {
        "time": 1689755418000,
        "value": 139.537,
        "_internal_originalTime": 1689755418000
    },
    {
        "time": 1689755419000,
        "value": 139.535,
        "_internal_originalTime": 1689755419000
    },
    {
        "time": 1689755421000,
        "value": 139.535,
        "_internal_originalTime": 1689755421000
    },
    {
        "time": 1689755422000,
        "value": 139.537,
        "_internal_originalTime": 1689755422000
    },
    {
        "time": 1689755423000,
        "value": 139.535,
        "_internal_originalTime": 1689755423000
    },
    {
        "time": 1689755424000,
        "value": 139.532,
        "_internal_originalTime": 1689755424000
    },
    {
        "time": 1689755425000,
        "value": 139.536,
        "_internal_originalTime": 1689755425000
    },
    {
        "time": 1689755427000,
        "value": 139.539,
        "_internal_originalTime": 1689755427000
    },
    {
        "time": 1689755428000,
        "value": 139.535,
        "_internal_originalTime": 1689755428000
    },
    {
        "time": 1689755430000,
        "value": 139.548,
        "_internal_originalTime": 1689755430000
    },
    {
        "time": 1689755431000,
        "value": 139.556,
        "_internal_originalTime": 1689755431000
    },
    {
        "time": 1689755433000,
        "value": 139.557,
        "_internal_originalTime": 1689755433000
    },
    {
        "time": 1689755434000,
        "value": 139.559,
        "_internal_originalTime": 1689755434000
    },
    {
        "time": 1689755435000,
        "value": 139.557,
        "_internal_originalTime": 1689755435000
    },
    {
        "time": 1689755436000,
        "value": 139.558,
        "_internal_originalTime": 1689755436000
    },
    {
        "time": 1689755437000,
        "value": 139.56,
        "_internal_originalTime": 1689755437000
    },
    {
        "time": 1689755438000,
        "value": 139.56,
        "_internal_originalTime": 1689755438000
    },
    {
        "time": 1689755439000,
        "value": 139.558,
        "_internal_originalTime": 1689755439000
    },
    {
        "time": 1689755441000,
        "value": 139.562,
        "_internal_originalTime": 1689755441000
    },
    {
        "time": 1689755442000,
        "value": 139.558,
        "_internal_originalTime": 1689755442000
    },
    {
        "time": 1689755443000,
        "value": 139.559,
        "_internal_originalTime": 1689755443000
    },
    {
        "time": 1689755445000,
        "value": 139.561,
        "_internal_originalTime": 1689755445000
    },
    {
        "time": 1689755448000,
        "value": 139.556,
        "_internal_originalTime": 1689755448000
    },
    {
        "time": 1689755450000,
        "value": 139.564,
        "_internal_originalTime": 1689755450000
    },
    {
        "time": 1689755451000,
        "value": 139.556,
        "_internal_originalTime": 1689755451000
    },
    {
        "time": 1689755452000,
        "value": 139.56,
        "_internal_originalTime": 1689755452000
    },
    {
        "time": 1689755453000,
        "value": 139.558,
        "_internal_originalTime": 1689755453000
    },
    {
        "time": 1689755454000,
        "value": 139.563,
        "_internal_originalTime": 1689755454000
    },
    {
        "time": 1689755456000,
        "value": 139.56,
        "_internal_originalTime": 1689755456000
    },
    {
        "time": 1689755457000,
        "value": 139.559,
        "_internal_originalTime": 1689755457000
    },
    {
        "time": 1689755458000,
        "value": 139.566,
        "_internal_originalTime": 1689755458000
    },
    {
        "time": 1689755459000,
        "value": 139.567,
        "_internal_originalTime": 1689755459000
    },
    {
        "time": 1689755461000,
        "value": 139.571,
        "_internal_originalTime": 1689755461000
    },
    {
        "time": 1689755462000,
        "value": 139.57,
        "_internal_originalTime": 1689755462000
    },
    {
        "time": 1689755464000,
        "value": 139.57,
        "_internal_originalTime": 1689755464000
    },
    {
        "time": 1689755465000,
        "value": 139.562,
        "_internal_originalTime": 1689755465000
    },
    {
        "time": 1689755466000,
        "value": 139.566,
        "_internal_originalTime": 1689755466000
    },
    {
        "time": 1689755467000,
        "value": 139.568,
        "_internal_originalTime": 1689755467000
    },
    {
        "time": 1689755468000,
        "value": 139.564,
        "_internal_originalTime": 1689755468000
    },
    {
        "time": 1689755469000,
        "value": 139.564,
        "_internal_originalTime": 1689755469000
    },
    {
        "time": 1689755471000,
        "value": 139.564,
        "_internal_originalTime": 1689755471000
    },
    {
        "time": 1689755472000,
        "value": 139.565,
        "_internal_originalTime": 1689755472000
    },
    {
        "time": 1689755473000,
        "value": 139.574,
        "_internal_originalTime": 1689755473000
    },
    {
        "time": 1689755475000,
        "value": 139.575,
        "_internal_originalTime": 1689755475000
    },
    {
        "time": 1689755477000,
        "value": 139.575,
        "_internal_originalTime": 1689755477000
    },
    {
        "time": 1689755478000,
        "value": 139.577,
        "_internal_originalTime": 1689755478000
    },
    {
        "time": 1689755479000,
        "value": 139.576,
        "_internal_originalTime": 1689755479000
    },
    {
        "time": 1689755480000,
        "value": 139.578,
        "_internal_originalTime": 1689755480000
    },
    {
        "time": 1689755482000,
        "value": 139.575,
        "_internal_originalTime": 1689755482000
    },
    {
        "time": 1689755483000,
        "value": 139.577,
        "_internal_originalTime": 1689755483000
    },
    {
        "time": 1689755484000,
        "value": 139.574,
        "_internal_originalTime": 1689755484000
    },
    {
        "time": 1689755485000,
        "value": 139.573,
        "_internal_originalTime": 1689755485000
    },
    {
        "time": 1689755487000,
        "value": 139.576,
        "_internal_originalTime": 1689755487000
    },
    {
        "time": 1689755488000,
        "value": 139.576,
        "_internal_originalTime": 1689755488000
    },
    {
        "time": 1689755489000,
        "value": 139.573,
        "_internal_originalTime": 1689755489000
    },
    {
        "time": 1689755490000,
        "value": 139.575,
        "_internal_originalTime": 1689755490000
    },
    {
        "time": 1689755491000,
        "value": 139.575,
        "_internal_originalTime": 1689755491000
    },
    {
        "time": 1689755493000,
        "value": 139.568,
        "_internal_originalTime": 1689755493000
    },
    {
        "time": 1689755494000,
        "value": 139.575,
        "_internal_originalTime": 1689755494000
    },
    {
        "time": 1689755495000,
        "value": 139.574,
        "_internal_originalTime": 1689755495000
    },
    {
        "time": 1689755496000,
        "value": 139.577,
        "_internal_originalTime": 1689755496000
    },
    {
        "time": 1689755497000,
        "value": 139.573,
        "_internal_originalTime": 1689755497000
    },
    {
        "time": 1689755498000,
        "value": 139.574,
        "_internal_originalTime": 1689755498000
    },
    {
        "time": 1689755499000,
        "value": 139.574,
        "_internal_originalTime": 1689755499000
    },
    {
        "time": 1689755501000,
        "value": 139.573,
        "_internal_originalTime": 1689755501000
    },
    {
        "time": 1689755503000,
        "value": 139.574,
        "_internal_originalTime": 1689755503000
    },
    {
        "time": 1689755505000,
        "value": 139.571,
        "_internal_originalTime": 1689755505000
    },
    {
        "time": 1689755507000,
        "value": 139.57,
        "_internal_originalTime": 1689755507000
    },
    {
        "time": 1689755508000,
        "value": 139.564,
        "_internal_originalTime": 1689755508000
    },
    {
        "time": 1689755509000,
        "value": 139.567,
        "_internal_originalTime": 1689755509000
    },
    {
        "time": 1689755510000,
        "value": 139.564,
        "_internal_originalTime": 1689755510000
    },
    {
        "time": 1689755512000,
        "value": 139.568,
        "_internal_originalTime": 1689755512000
    },
    {
        "time": 1689755513000,
        "value": 139.568,
        "_internal_originalTime": 1689755513000
    },
    {
        "time": 1689755515000,
        "value": 139.567,
        "_internal_originalTime": 1689755515000
    },
    {
        "time": 1689755516000,
        "value": 139.563,
        "_internal_originalTime": 1689755516000
    },
    {
        "time": 1689755517000,
        "value": 139.562,
        "_internal_originalTime": 1689755517000
    },
    {
        "time": 1689755518000,
        "value": 139.562,
        "_internal_originalTime": 1689755518000
    },
    {
        "time": 1689755519000,
        "value": 139.563,
        "_internal_originalTime": 1689755519000
    },
    {
        "time": 1689755520000,
        "value": 139.554,
        "_internal_originalTime": 1689755520000
    },
    {
        "time": 1689755521000,
        "value": 139.557,
        "_internal_originalTime": 1689755521000
    },
    {
        "time": 1689755522000,
        "value": 139.554,
        "_internal_originalTime": 1689755522000
    },
    {
        "time": 1689755523000,
        "value": 139.544,
        "_internal_originalTime": 1689755523000
    },
    {
        "time": 1689755524000,
        "value": 139.547,
        "_internal_originalTime": 1689755524000
    },
    {
        "time": 1689755526000,
        "value": 139.547,
        "_internal_originalTime": 1689755526000
    },
    {
        "time": 1689755527000,
        "value": 139.548,
        "_internal_originalTime": 1689755527000
    },
    {
        "time": 1689755528000,
        "value": 139.548,
        "_internal_originalTime": 1689755528000
    },
    {
        "time": 1689755529000,
        "value": 139.546,
        "_internal_originalTime": 1689755529000
    },
    {
        "time": 1689755531000,
        "value": 139.548,
        "_internal_originalTime": 1689755531000
    },
    {
        "time": 1689755533000,
        "value": 139.55,
        "_internal_originalTime": 1689755533000
    },
    {
        "time": 1689755534000,
        "value": 139.551,
        "_internal_originalTime": 1689755534000
    },
    {
        "time": 1689755535000,
        "value": 139.546,
        "_internal_originalTime": 1689755535000
    },
    {
        "time": 1689755536000,
        "value": 139.549,
        "_internal_originalTime": 1689755536000
    },
    {
        "time": 1689755538000,
        "value": 139.547,
        "_internal_originalTime": 1689755538000
    },
    {
        "time": 1689755539000,
        "value": 139.546,
        "_internal_originalTime": 1689755539000
    },
    {
        "time": 1689755540000,
        "value": 139.56,
        "_internal_originalTime": 1689755540000
    },
    {
        "time": 1689755541000,
        "value": 139.55,
        "_internal_originalTime": 1689755541000
    },
    {
        "time": 1689755542000,
        "value": 139.556,
        "_internal_originalTime": 1689755542000
    },
    {
        "time": 1689755543000,
        "value": 139.558,
        "_internal_originalTime": 1689755543000
    },
    {
        "time": 1689755544000,
        "value": 139.556,
        "_internal_originalTime": 1689755544000
    },
    {
        "time": 1689755545000,
        "value": 139.554,
        "_internal_originalTime": 1689755545000
    },
    {
        "time": 1689755547000,
        "value": 139.559,
        "_internal_originalTime": 1689755547000
    },
    {
        "time": 1689755548000,
        "value": 139.56,
        "_internal_originalTime": 1689755548000
    },
    {
        "time": 1689755550000,
        "value": 139.561,
        "_internal_originalTime": 1689755550000
    },
    {
        "time": 1689755551000,
        "value": 139.561,
        "_internal_originalTime": 1689755551000
    },
    {
        "time": 1689755552000,
        "value": 139.556,
        "_internal_originalTime": 1689755552000
    },
    {
        "time": 1689755554000,
        "value": 139.558,
        "_internal_originalTime": 1689755554000
    },
    {
        "time": 1689755556000,
        "value": 139.553,
        "_internal_originalTime": 1689755556000
    },
    {
        "time": 1689755558000,
        "value": 139.548,
        "_internal_originalTime": 1689755558000
    },
    {
        "time": 1689755559000,
        "value": 139.549,
        "_internal_originalTime": 1689755559000
    },
    {
        "time": 1689755560000,
        "value": 139.545,
        "_internal_originalTime": 1689755560000
    },
    {
        "time": 1689755561000,
        "value": 139.548,
        "_internal_originalTime": 1689755561000
    },
    {
        "time": 1689755562000,
        "value": 139.548,
        "_internal_originalTime": 1689755562000
    },
    {
        "time": 1689755563000,
        "value": 139.549,
        "_internal_originalTime": 1689755563000
    },
    {
        "time": 1689755565000,
        "value": 139.548,
        "_internal_originalTime": 1689755565000
    },
    {
        "time": 1689755566000,
        "value": 139.544,
        "_internal_originalTime": 1689755566000
    },
    {
        "time": 1689755567000,
        "value": 139.549,
        "_internal_originalTime": 1689755567000
    },
    {
        "time": 1689755568000,
        "value": 139.545,
        "_internal_originalTime": 1689755568000
    },
    {
        "time": 1689755569000,
        "value": 139.555,
        "_internal_originalTime": 1689755569000
    },
    {
        "time": 1689755571000,
        "value": 139.552,
        "_internal_originalTime": 1689755571000
    },
    {
        "time": 1689755572000,
        "value": 139.55,
        "_internal_originalTime": 1689755572000
    },
    {
        "time": 1689755573000,
        "value": 139.552,
        "_internal_originalTime": 1689755573000
    },
    {
        "time": 1689755574000,
        "value": 139.549,
        "_internal_originalTime": 1689755574000
    },
    {
        "time": 1689755575000,
        "value": 139.554,
        "_internal_originalTime": 1689755575000
    },
    {
        "time": 1689755576000,
        "value": 139.552,
        "_internal_originalTime": 1689755576000
    },
    {
        "time": 1689755578000,
        "value": 139.547,
        "_internal_originalTime": 1689755578000
    },
    {
        "time": 1689755580000,
        "value": 139.548,
        "_internal_originalTime": 1689755580000
    },
    {
        "time": 1689755581000,
        "value": 139.553,
        "_internal_originalTime": 1689755581000
    },
    {
        "time": 1689755582000,
        "value": 139.548,
        "_internal_originalTime": 1689755582000
    },
    {
        "time": 1689755583000,
        "value": 139.549,
        "_internal_originalTime": 1689755583000
    },
    {
        "time": 1689755585000,
        "value": 139.55,
        "_internal_originalTime": 1689755585000
    },
    {
        "time": 1689755586000,
        "value": 139.55,
        "_internal_originalTime": 1689755586000
    },
    {
        "time": 1689755588000,
        "value": 139.549,
        "_internal_originalTime": 1689755588000
    },
    {
        "time": 1689755589000,
        "value": 139.548,
        "_internal_originalTime": 1689755589000
    },
    {
        "time": 1689755590000,
        "value": 139.547,
        "_internal_originalTime": 1689755590000
    },
    {
        "time": 1689755591000,
        "value": 139.55,
        "_internal_originalTime": 1689755591000
    },
    {
        "time": 1689755593000,
        "value": 139.556,
        "_internal_originalTime": 1689755593000
    },
    {
        "time": 1689755594000,
        "value": 139.561,
        "_internal_originalTime": 1689755594000
    },
    {
        "time": 1689755595000,
        "value": 139.555,
        "_internal_originalTime": 1689755595000
    },
    {
        "time": 1689755597000,
        "value": 139.55,
        "_internal_originalTime": 1689755597000
    },
    {
        "time": 1689755599000,
        "value": 139.549,
        "_internal_originalTime": 1689755599000
    },
    {
        "time": 1689755600000,
        "value": 139.556,
        "_internal_originalTime": 1689755600000
    },
    {
        "time": 1689755601000,
        "value": 139.558,
        "_internal_originalTime": 1689755601000
    },
    {
        "time": 1689755602000,
        "value": 139.559,
        "_internal_originalTime": 1689755602000
    },
    {
        "time": 1689755603000,
        "value": 139.561,
        "_internal_originalTime": 1689755603000
    },
    {
        "time": 1689755604000,
        "value": 139.559,
        "_internal_originalTime": 1689755604000
    },
    {
        "time": 1689755606000,
        "value": 139.56,
        "_internal_originalTime": 1689755606000
    },
    {
        "time": 1689755607000,
        "value": 139.561,
        "_internal_originalTime": 1689755607000
    },
    {
        "time": 1689755608000,
        "value": 139.557,
        "_internal_originalTime": 1689755608000
    },
    {
        "time": 1689755609000,
        "value": 139.557,
        "_internal_originalTime": 1689755609000
    },
    {
        "time": 1689755610000,
        "value": 139.558,
        "_internal_originalTime": 1689755610000
    },
    {
        "time": 1689755611000,
        "value": 139.56,
        "_internal_originalTime": 1689755611000
    },
    {
        "time": 1689755613000,
        "value": 139.566,
        "_internal_originalTime": 1689755613000
    },
    {
        "time": 1689755614000,
        "value": 139.576,
        "_internal_originalTime": 1689755614000
    },
    {
        "time": 1689755616000,
        "value": 139.57,
        "_internal_originalTime": 1689755616000
    },
    {
        "time": 1689755617000,
        "value": 139.577,
        "_internal_originalTime": 1689755617000
    },
    {
        "time": 1689755618000,
        "value": 139.573,
        "_internal_originalTime": 1689755618000
    },
    {
        "time": 1689755619000,
        "value": 139.567,
        "_internal_originalTime": 1689755619000
    },
    {
        "time": 1689755620000,
        "value": 139.571,
        "_internal_originalTime": 1689755620000
    },
    {
        "time": 1689755624000,
        "value": 139.568,
        "_internal_originalTime": 1689755624000
    },
    {
        "time": 1689755625000,
        "value": 139.566,
        "_internal_originalTime": 1689755625000
    },
    {
        "time": 1689755626000,
        "value": 139.562,
        "_internal_originalTime": 1689755626000
    },
    {
        "time": 1689755628000,
        "value": 139.565,
        "_internal_originalTime": 1689755628000
    },
    {
        "time": 1689755629000,
        "value": 139.561,
        "_internal_originalTime": 1689755629000
    },
    {
        "time": 1689755631000,
        "value": 139.563,
        "_internal_originalTime": 1689755631000
    },
    {
        "time": 1689755632000,
        "value": 139.562,
        "_internal_originalTime": 1689755632000
    },
    {
        "time": 1689755633000,
        "value": 139.556,
        "_internal_originalTime": 1689755633000
    },
    {
        "time": 1689755634000,
        "value": 139.559,
        "_internal_originalTime": 1689755634000
    },
    {
        "time": 1689755636000,
        "value": 139.558,
        "_internal_originalTime": 1689755636000
    },
    {
        "time": 1689755638000,
        "value": 139.557,
        "_internal_originalTime": 1689755638000
    },
    {
        "time": 1689755639000,
        "value": 139.554,
        "_internal_originalTime": 1689755639000
    },
    {
        "time": 1689755640000,
        "value": 139.561,
        "_internal_originalTime": 1689755640000
    },
    {
        "time": 1689755642000,
        "value": 139.56,
        "_internal_originalTime": 1689755642000
    },
    {
        "time": 1689755643000,
        "value": 139.565,
        "_internal_originalTime": 1689755643000
    },
    {
        "time": 1689755644000,
        "value": 139.576,
        "_internal_originalTime": 1689755644000
    },
    {
        "time": 1689755645000,
        "value": 139.573,
        "_internal_originalTime": 1689755645000
    },
    {
        "time": 1689755646000,
        "value": 139.574,
        "_internal_originalTime": 1689755646000
    },
    {
        "time": 1689755647000,
        "value": 139.577,
        "_internal_originalTime": 1689755647000
    },
    {
        "time": 1689755649000,
        "value": 139.597,
        "_internal_originalTime": 1689755649000
    },
    {
        "time": 1689755650000,
        "value": 139.593,
        "_internal_originalTime": 1689755650000
    },
    {
        "time": 1689755652000,
        "value": 139.583,
        "_internal_originalTime": 1689755652000
    },
    {
        "time": 1689755653000,
        "value": 139.576,
        "_internal_originalTime": 1689755653000
    },
    {
        "time": 1689755654000,
        "value": 139.574,
        "_internal_originalTime": 1689755654000
    },
    {
        "time": 1689755655000,
        "value": 139.58,
        "_internal_originalTime": 1689755655000
    },
    {
        "time": 1689755656000,
        "value": 139.572,
        "_internal_originalTime": 1689755656000
    },
    {
        "time": 1689755658000,
        "value": 139.572,
        "_internal_originalTime": 1689755658000
    },
    {
        "time": 1689755659000,
        "value": 139.574,
        "_internal_originalTime": 1689755659000
    },
    {
        "time": 1689755660000,
        "value": 139.574,
        "_internal_originalTime": 1689755660000
    },
    {
        "time": 1689755661000,
        "value": 139.578,
        "_internal_originalTime": 1689755661000
    },
    {
        "time": 1689755662000,
        "value": 139.576,
        "_internal_originalTime": 1689755662000
    },
    {
        "time": 1689755663000,
        "value": 139.576,
        "_internal_originalTime": 1689755663000
    },
    {
        "time": 1689755664000,
        "value": 139.578,
        "_internal_originalTime": 1689755664000
    },
    {
        "time": 1689755666000,
        "value": 139.574,
        "_internal_originalTime": 1689755666000
    },
    {
        "time": 1689755668000,
        "value": 139.579,
        "_internal_originalTime": 1689755668000
    },
    {
        "time": 1689755669000,
        "value": 139.578,
        "_internal_originalTime": 1689755669000
    },
    {
        "time": 1689755670000,
        "value": 139.581,
        "_internal_originalTime": 1689755670000
    },
    {
        "time": 1689755672000,
        "value": 139.589,
        "_internal_originalTime": 1689755672000
    },
    {
        "time": 1689755674000,
        "value": 139.595,
        "_internal_originalTime": 1689755674000
    },
    {
        "time": 1689755675000,
        "value": 139.59,
        "_internal_originalTime": 1689755675000
    },
    {
        "time": 1689755677000,
        "value": 139.591,
        "_internal_originalTime": 1689755677000
    },
    {
        "time": 1689755678000,
        "value": 139.592,
        "_internal_originalTime": 1689755678000
    },
    {
        "time": 1689755680000,
        "value": 139.588,
        "_internal_originalTime": 1689755680000
    },
    {
        "time": 1689755681000,
        "value": 139.59,
        "_internal_originalTime": 1689755681000
    },
    {
        "time": 1689755682000,
        "value": 139.592,
        "_internal_originalTime": 1689755682000
    },
    {
        "time": 1689755683000,
        "value": 139.596,
        "_internal_originalTime": 1689755683000
    },
    {
        "time": 1689755685000,
        "value": 139.597,
        "_internal_originalTime": 1689755685000
    },
    {
        "time": 1689755686000,
        "value": 139.592,
        "_internal_originalTime": 1689755686000
    },
    {
        "time": 1689755687000,
        "value": 139.598,
        "_internal_originalTime": 1689755687000
    },
    {
        "time": 1689755688000,
        "value": 139.597,
        "_internal_originalTime": 1689755688000
    },
    {
        "time": 1689755689000,
        "value": 139.599,
        "_internal_originalTime": 1689755689000
    },
    {
        "time": 1689755690000,
        "value": 139.597,
        "_internal_originalTime": 1689755690000
    },
    {
        "time": 1689755691000,
        "value": 139.595,
        "_internal_originalTime": 1689755691000
    },
    {
        "time": 1689755693000,
        "value": 139.6,
        "_internal_originalTime": 1689755693000
    },
    {
        "time": 1689755694000,
        "value": 139.597,
        "_internal_originalTime": 1689755694000
    },
    {
        "time": 1689755695000,
        "value": 139.59,
        "_internal_originalTime": 1689755695000
    },
    {
        "time": 1689755696000,
        "value": 139.589,
        "_internal_originalTime": 1689755696000
    },
    {
        "time": 1689755697000,
        "value": 139.583,
        "_internal_originalTime": 1689755697000
    },
    {
        "time": 1689755699000,
        "value": 139.584,
        "_internal_originalTime": 1689755699000
    },
    {
        "time": 1689755700000,
        "value": 139.596,
        "_internal_originalTime": 1689755700000
    },
    {
        "time": 1689755701000,
        "value": 139.598,
        "_internal_originalTime": 1689755701000
    },
    {
        "time": 1689755702000,
        "value": 139.597,
        "_internal_originalTime": 1689755702000
    },
    {
        "time": 1689755703000,
        "value": 139.593,
        "_internal_originalTime": 1689755703000
    },
    {
        "time": 1689755704000,
        "value": 139.593,
        "_internal_originalTime": 1689755704000
    },
    {
        "time": 1689755705000,
        "value": 139.589,
        "_internal_originalTime": 1689755705000
    },
    {
        "time": 1689755706000,
        "value": 139.591,
        "_internal_originalTime": 1689755706000
    },
    {
        "time": 1689755707000,
        "value": 139.593,
        "_internal_originalTime": 1689755707000
    },
    {
        "time": 1689755708000,
        "value": 139.594,
        "_internal_originalTime": 1689755708000
    },
    {
        "time": 1689755710000,
        "value": 139.598,
        "_internal_originalTime": 1689755710000
    },
    {
        "time": 1689755711000,
        "value": 139.598,
        "_internal_originalTime": 1689755711000
    },
    {
        "time": 1689755712000,
        "value": 139.594,
        "_internal_originalTime": 1689755712000
    },
    {
        "time": 1689755714000,
        "value": 139.588,
        "_internal_originalTime": 1689755714000
    },
    {
        "time": 1689755715000,
        "value": 139.592,
        "_internal_originalTime": 1689755715000
    },
    {
        "time": 1689755716000,
        "value": 139.588,
        "_internal_originalTime": 1689755716000
    },
    {
        "time": 1689755718000,
        "value": 139.593,
        "_internal_originalTime": 1689755718000
    },
    {
        "time": 1689755720000,
        "value": 139.59,
        "_internal_originalTime": 1689755720000
    },
    {
        "time": 1689755721000,
        "value": 139.586,
        "_internal_originalTime": 1689755721000
    },
    {
        "time": 1689755722000,
        "value": 139.585,
        "_internal_originalTime": 1689755722000
    },
    {
        "time": 1689755723000,
        "value": 139.589,
        "_internal_originalTime": 1689755723000
    },
    {
        "time": 1689755724000,
        "value": 139.59,
        "_internal_originalTime": 1689755724000
    },
    {
        "time": 1689755725000,
        "value": 139.587,
        "_internal_originalTime": 1689755725000
    },
    {
        "time": 1689755727000,
        "value": 139.585,
        "_internal_originalTime": 1689755727000
    },
    {
        "time": 1689755728000,
        "value": 139.586,
        "_internal_originalTime": 1689755728000
    },
    {
        "time": 1689755729000,
        "value": 139.577,
        "_internal_originalTime": 1689755729000
    },
    {
        "time": 1689755730000,
        "value": 139.576,
        "_internal_originalTime": 1689755730000
    },
    {
        "time": 1689755731000,
        "value": 139.575,
        "_internal_originalTime": 1689755731000
    },
    {
        "time": 1689755732000,
        "value": 139.573,
        "_internal_originalTime": 1689755732000
    },
    {
        "time": 1689755734000,
        "value": 139.588,
        "_internal_originalTime": 1689755734000
    },
    {
        "time": 1689755736000,
        "value": 139.587,
        "_internal_originalTime": 1689755736000
    },
    {
        "time": 1689755737000,
        "value": 139.587,
        "_internal_originalTime": 1689755737000
    },
    {
        "time": 1689755738000,
        "value": 139.583,
        "_internal_originalTime": 1689755738000
    },
    {
        "time": 1689755739000,
        "value": 139.587,
        "_internal_originalTime": 1689755739000
    },
    {
        "time": 1689755742000,
        "value": 139.583,
        "_internal_originalTime": 1689755742000
    },
    {
        "time": 1689755743000,
        "value": 139.583,
        "_internal_originalTime": 1689755743000
    },
    {
        "time": 1689755745000,
        "value": 139.589,
        "_internal_originalTime": 1689755745000
    },
    {
        "time": 1689755746000,
        "value": 139.587,
        "_internal_originalTime": 1689755746000
    },
    {
        "time": 1689755747000,
        "value": 139.582,
        "_internal_originalTime": 1689755747000
    },
    {
        "time": 1689755748000,
        "value": 139.585,
        "_internal_originalTime": 1689755748000
    },
    {
        "time": 1689755749000,
        "value": 139.588,
        "_internal_originalTime": 1689755749000
    },
    {
        "time": 1689755751000,
        "value": 139.583,
        "_internal_originalTime": 1689755751000
    },
    {
        "time": 1689755753000,
        "value": 139.579,
        "_internal_originalTime": 1689755753000
    },
    {
        "time": 1689755754000,
        "value": 139.577,
        "_internal_originalTime": 1689755754000
    },
    {
        "time": 1689755755000,
        "value": 139.572,
        "_internal_originalTime": 1689755755000
    },
    {
        "time": 1689755756000,
        "value": 139.579,
        "_internal_originalTime": 1689755756000
    },
    {
        "time": 1689755757000,
        "value": 139.574,
        "_internal_originalTime": 1689755757000
    },
    {
        "time": 1689755759000,
        "value": 139.573,
        "_internal_originalTime": 1689755759000
    },
    {
        "time": 1689755760000,
        "value": 139.572,
        "_internal_originalTime": 1689755760000
    },
    {
        "time": 1689755762000,
        "value": 139.574,
        "_internal_originalTime": 1689755762000
    },
    {
        "time": 1689755764000,
        "value": 139.574,
        "_internal_originalTime": 1689755764000
    },
    {
        "time": 1689755765000,
        "value": 139.579,
        "_internal_originalTime": 1689755765000
    },
    {
        "time": 1689755766000,
        "value": 139.578,
        "_internal_originalTime": 1689755766000
    },
    {
        "time": 1689755767000,
        "value": 139.587,
        "_internal_originalTime": 1689755767000
    },
    {
        "time": 1689755768000,
        "value": 139.59,
        "_internal_originalTime": 1689755768000
    },
    {
        "time": 1689755770000,
        "value": 139.596,
        "_internal_originalTime": 1689755770000
    },
    {
        "time": 1689755771000,
        "value": 139.599,
        "_internal_originalTime": 1689755771000
    },
    {
        "time": 1689755773000,
        "value": 139.598,
        "_internal_originalTime": 1689755773000
    },
    {
        "time": 1689755774000,
        "value": 139.592,
        "_internal_originalTime": 1689755774000
    },
    {
        "time": 1689755775000,
        "value": 139.594,
        "_internal_originalTime": 1689755775000
    },
    {
        "time": 1689755777000,
        "value": 139.587,
        "_internal_originalTime": 1689755777000
    },
    {
        "time": 1689755778000,
        "value": 139.575,
        "_internal_originalTime": 1689755778000
    },
    {
        "time": 1689755779000,
        "value": 139.577,
        "_internal_originalTime": 1689755779000
    },
    {
        "time": 1689755780000,
        "value": 139.58,
        "_internal_originalTime": 1689755780000
    },
    {
        "time": 1689755782000,
        "value": 139.575,
        "_internal_originalTime": 1689755782000
    },
    {
        "time": 1689755783000,
        "value": 139.579,
        "_internal_originalTime": 1689755783000
    },
    {
        "time": 1689755784000,
        "value": 139.573,
        "_internal_originalTime": 1689755784000
    },
    {
        "time": 1689755785000,
        "value": 139.57,
        "_internal_originalTime": 1689755785000
    },
    {
        "time": 1689755786000,
        "value": 139.576,
        "_internal_originalTime": 1689755786000
    },
    {
        "time": 1689755788000,
        "value": 139.574,
        "_internal_originalTime": 1689755788000
    },
    {
        "time": 1689755789000,
        "value": 139.574,
        "_internal_originalTime": 1689755789000
    },
    {
        "time": 1689755790000,
        "value": 139.576,
        "_internal_originalTime": 1689755790000
    },
    {
        "time": 1689755792000,
        "value": 139.577,
        "_internal_originalTime": 1689755792000
    },
    {
        "time": 1689755794000,
        "value": 139.586,
        "_internal_originalTime": 1689755794000
    },
    {
        "time": 1689755795000,
        "value": 139.578,
        "_internal_originalTime": 1689755795000
    },
    {
        "time": 1689755796000,
        "value": 139.578,
        "_internal_originalTime": 1689755796000
    },
    {
        "time": 1689755798000,
        "value": 139.581,
        "_internal_originalTime": 1689755798000
    },
    {
        "time": 1689755800000,
        "value": 139.574,
        "_internal_originalTime": 1689755800000
    },
    {
        "time": 1689755802000,
        "value": 139.582,
        "_internal_originalTime": 1689755802000
    },
    {
        "time": 1689755803000,
        "value": 139.58,
        "_internal_originalTime": 1689755803000
    },
    {
        "time": 1689755804000,
        "value": 139.579,
        "_internal_originalTime": 1689755804000
    },
    {
        "time": 1689755805000,
        "value": 139.579,
        "_internal_originalTime": 1689755805000
    },
    {
        "time": 1689755806000,
        "value": 139.577,
        "_internal_originalTime": 1689755806000
    },
    {
        "time": 1689755808000,
        "value": 139.572,
        "_internal_originalTime": 1689755808000
    },
    {
        "time": 1689755809000,
        "value": 139.575,
        "_internal_originalTime": 1689755809000
    },
    {
        "time": 1689755810000,
        "value": 139.574,
        "_internal_originalTime": 1689755810000
    },
    {
        "time": 1689755811000,
        "value": 139.577,
        "_internal_originalTime": 1689755811000
    },
    {
        "time": 1689755812000,
        "value": 139.576,
        "_internal_originalTime": 1689755812000
    },
    {
        "time": 1689755814000,
        "value": 139.573,
        "_internal_originalTime": 1689755814000
    },
    {
        "time": 1689755816000,
        "value": 139.572,
        "_internal_originalTime": 1689755816000
    },
    {
        "time": 1689755818000,
        "value": 139.57,
        "_internal_originalTime": 1689755818000
    },
    {
        "time": 1689755819000,
        "value": 139.567,
        "_internal_originalTime": 1689755819000
    },
    {
        "time": 1689755821000,
        "value": 139.57,
        "_internal_originalTime": 1689755821000
    },
    {
        "time": 1689755822000,
        "value": 139.57,
        "_internal_originalTime": 1689755822000
    },
    {
        "time": 1689755823000,
        "value": 139.564,
        "_internal_originalTime": 1689755823000
    },
    {
        "time": 1689755825000,
        "value": 139.567,
        "_internal_originalTime": 1689755825000
    },
    {
        "time": 1689755827000,
        "value": 139.564,
        "_internal_originalTime": 1689755827000
    },
    {
        "time": 1689755828000,
        "value": 139.57,
        "_internal_originalTime": 1689755828000
    },
    {
        "time": 1689755830000,
        "value": 139.567,
        "_internal_originalTime": 1689755830000
    },
    {
        "time": 1689755831000,
        "value": 139.569,
        "_internal_originalTime": 1689755831000
    },
    {
        "time": 1689755833000,
        "value": 139.568,
        "_internal_originalTime": 1689755833000
    },
    {
        "time": 1689755834000,
        "value": 139.57,
        "_internal_originalTime": 1689755834000
    },
    {
        "time": 1689755835000,
        "value": 139.571,
        "_internal_originalTime": 1689755835000
    },
    {
        "time": 1689755836000,
        "value": 139.572,
        "_internal_originalTime": 1689755836000
    },
    {
        "time": 1689755838000,
        "value": 139.572,
        "_internal_originalTime": 1689755838000
    },
    {
        "time": 1689755839000,
        "value": 139.565,
        "_internal_originalTime": 1689755839000
    },
    {
        "time": 1689755840000,
        "value": 139.567,
        "_internal_originalTime": 1689755840000
    },
    {
        "time": 1689755841000,
        "value": 139.569,
        "_internal_originalTime": 1689755841000
    },
    {
        "time": 1689755842000,
        "value": 139.57,
        "_internal_originalTime": 1689755842000
    },
    {
        "time": 1689755843000,
        "value": 139.57,
        "_internal_originalTime": 1689755843000
    },
    {
        "time": 1689755844000,
        "value": 139.57,
        "_internal_originalTime": 1689755844000
    },
    {
        "time": 1689755845000,
        "value": 139.57,
        "_internal_originalTime": 1689755845000
    },
    {
        "time": 1689755846000,
        "value": 139.569,
        "_internal_originalTime": 1689755846000
    },
    {
        "time": 1689755847000,
        "value": 139.568,
        "_internal_originalTime": 1689755847000
    },
    {
        "time": 1689755849000,
        "value": 139.568,
        "_internal_originalTime": 1689755849000
    },
    {
        "time": 1689755850000,
        "value": 139.568,
        "_internal_originalTime": 1689755850000
    },
    {
        "time": 1689755851000,
        "value": 139.567,
        "_internal_originalTime": 1689755851000
    },
    {
        "time": 1689755852000,
        "value": 139.568,
        "_internal_originalTime": 1689755852000
    },
    {
        "time": 1689755853000,
        "value": 139.564,
        "_internal_originalTime": 1689755853000
    },
    {
        "time": 1689755855000,
        "value": 139.567,
        "_internal_originalTime": 1689755855000
    },
    {
        "time": 1689755856000,
        "value": 139.563,
        "_internal_originalTime": 1689755856000
    },
    {
        "time": 1689755857000,
        "value": 139.565,
        "_internal_originalTime": 1689755857000
    },
    {
        "time": 1689755858000,
        "value": 139.567,
        "_internal_originalTime": 1689755858000
    },
    {
        "time": 1689755859000,
        "value": 139.566,
        "_internal_originalTime": 1689755859000
    },
    {
        "time": 1689755861000,
        "value": 139.563,
        "_internal_originalTime": 1689755861000
    },
    {
        "time": 1689755862000,
        "value": 139.565,
        "_internal_originalTime": 1689755862000
    },
    {
        "time": 1689755865000,
        "value": 139.568,
        "_internal_originalTime": 1689755865000
    },
    {
        "time": 1689755866000,
        "value": 139.561,
        "_internal_originalTime": 1689755866000
    },
    {
        "time": 1689755867000,
        "value": 139.562,
        "_internal_originalTime": 1689755867000
    },
    {
        "time": 1689755868000,
        "value": 139.562,
        "_internal_originalTime": 1689755868000
    },
    {
        "time": 1689755869000,
        "value": 139.558,
        "_internal_originalTime": 1689755869000
    },
    {
        "time": 1689755871000,
        "value": 139.562,
        "_internal_originalTime": 1689755871000
    },
    {
        "time": 1689755874000,
        "value": 139.562,
        "_internal_originalTime": 1689755874000
    },
    {
        "time": 1689755875000,
        "value": 139.56,
        "_internal_originalTime": 1689755875000
    },
    {
        "time": 1689755877000,
        "value": 139.553,
        "_internal_originalTime": 1689755877000
    },
    {
        "time": 1689755878000,
        "value": 139.556,
        "_internal_originalTime": 1689755878000
    },
    {
        "time": 1689755880000,
        "value": 139.551,
        "_internal_originalTime": 1689755880000
    },
    {
        "time": 1689755881000,
        "value": 139.546,
        "_internal_originalTime": 1689755881000
    },
    {
        "time": 1689755882000,
        "value": 139.543,
        "_internal_originalTime": 1689755882000
    },
    {
        "time": 1689755883000,
        "value": 139.539,
        "_internal_originalTime": 1689755883000
    },
    {
        "time": 1689755885000,
        "value": 139.536,
        "_internal_originalTime": 1689755885000
    },
    {
        "time": 1689755886000,
        "value": 139.536,
        "_internal_originalTime": 1689755886000
    },
    {
        "time": 1689755887000,
        "value": 139.539,
        "_internal_originalTime": 1689755887000
    },
    {
        "time": 1689755888000,
        "value": 139.536,
        "_internal_originalTime": 1689755888000
    },
    {
        "time": 1689755889000,
        "value": 139.547,
        "_internal_originalTime": 1689755889000
    },
    {
        "time": 1689755890000,
        "value": 139.546,
        "_internal_originalTime": 1689755890000
    },
    {
        "time": 1689755892000,
        "value": 139.547,
        "_internal_originalTime": 1689755892000
    },
    {
        "time": 1689755893000,
        "value": 139.535,
        "_internal_originalTime": 1689755893000
    },
    {
        "time": 1689755894000,
        "value": 139.536,
        "_internal_originalTime": 1689755894000
    },
    {
        "time": 1689755895000,
        "value": 139.537,
        "_internal_originalTime": 1689755895000
    },
    {
        "time": 1689755896000,
        "value": 139.537,
        "_internal_originalTime": 1689755896000
    },
    {
        "time": 1689755897000,
        "value": 139.535,
        "_internal_originalTime": 1689755897000
    },
    {
        "time": 1689755899000,
        "value": 139.537,
        "_internal_originalTime": 1689755899000
    },
    {
        "time": 1689755900000,
        "value": 139.529,
        "_internal_originalTime": 1689755900000
    },
    {
        "time": 1689755901000,
        "value": 139.528,
        "_internal_originalTime": 1689755901000
    },
    {
        "time": 1689755902000,
        "value": 139.524,
        "_internal_originalTime": 1689755902000
    },
    {
        "time": 1689755904000,
        "value": 139.531,
        "_internal_originalTime": 1689755904000
    },
    {
        "time": 1689755905000,
        "value": 139.53,
        "_internal_originalTime": 1689755905000
    },
    {
        "time": 1689755907000,
        "value": 139.531,
        "_internal_originalTime": 1689755907000
    },
    {
        "time": 1689755908000,
        "value": 139.53,
        "_internal_originalTime": 1689755908000
    },
    {
        "time": 1689755909000,
        "value": 139.529,
        "_internal_originalTime": 1689755909000
    },
    {
        "time": 1689755910000,
        "value": 139.529,
        "_internal_originalTime": 1689755910000
    },
    {
        "time": 1689755912000,
        "value": 139.532,
        "_internal_originalTime": 1689755912000
    },
    {
        "time": 1689755913000,
        "value": 139.532,
        "_internal_originalTime": 1689755913000
    },
    {
        "time": 1689755914000,
        "value": 139.543,
        "_internal_originalTime": 1689755914000
    },
    {
        "time": 1689755915000,
        "value": 139.546,
        "_internal_originalTime": 1689755915000
    },
    {
        "time": 1689755916000,
        "value": 139.545,
        "_internal_originalTime": 1689755916000
    },
    {
        "time": 1689755917000,
        "value": 139.549,
        "_internal_originalTime": 1689755917000
    },
    {
        "time": 1689755918000,
        "value": 139.547,
        "_internal_originalTime": 1689755918000
    },
    {
        "time": 1689755919000,
        "value": 139.543,
        "_internal_originalTime": 1689755919000
    },
    {
        "time": 1689755921000,
        "value": 139.548,
        "_internal_originalTime": 1689755921000
    },
    {
        "time": 1689755922000,
        "value": 139.54,
        "_internal_originalTime": 1689755922000
    },
    {
        "time": 1689755923000,
        "value": 139.535,
        "_internal_originalTime": 1689755923000
    },
    {
        "time": 1689755924000,
        "value": 139.537,
        "_internal_originalTime": 1689755924000
    },
    {
        "time": 1689755925000,
        "value": 139.536,
        "_internal_originalTime": 1689755925000
    },
    {
        "time": 1689755926000,
        "value": 139.526,
        "_internal_originalTime": 1689755926000
    },
    {
        "time": 1689755927000,
        "value": 139.53,
        "_internal_originalTime": 1689755927000
    },
    {
        "time": 1689755928000,
        "value": 139.527,
        "_internal_originalTime": 1689755928000
    },
    {
        "time": 1689755931000,
        "value": 139.528,
        "_internal_originalTime": 1689755931000
    },
    {
        "time": 1689755934000,
        "value": 139.526,
        "_internal_originalTime": 1689755934000
    },
    {
        "time": 1689755935000,
        "value": 139.529,
        "_internal_originalTime": 1689755935000
    },
    {
        "time": 1689755936000,
        "value": 139.528,
        "_internal_originalTime": 1689755936000
    },
    {
        "time": 1689755937000,
        "value": 139.527,
        "_internal_originalTime": 1689755937000
    },
    {
        "time": 1689755939000,
        "value": 139.526,
        "_internal_originalTime": 1689755939000
    },
    {
        "time": 1689755941000,
        "value": 139.524,
        "_internal_originalTime": 1689755941000
    },
    {
        "time": 1689755943000,
        "value": 139.513,
        "_internal_originalTime": 1689755943000
    },
    {
        "time": 1689755945000,
        "value": 139.522,
        "_internal_originalTime": 1689755945000
    },
    {
        "time": 1689755946000,
        "value": 139.516,
        "_internal_originalTime": 1689755946000
    },
    {
        "time": 1689755947000,
        "value": 139.516,
        "_internal_originalTime": 1689755947000
    },
    {
        "time": 1689755948000,
        "value": 139.517,
        "_internal_originalTime": 1689755948000
    },
    {
        "time": 1689755950000,
        "value": 139.516,
        "_internal_originalTime": 1689755950000
    },
    {
        "time": 1689755951000,
        "value": 139.514,
        "_internal_originalTime": 1689755951000
    },
    {
        "time": 1689755952000,
        "value": 139.514,
        "_internal_originalTime": 1689755952000
    },
    {
        "time": 1689755953000,
        "value": 139.51,
        "_internal_originalTime": 1689755953000
    },
    {
        "time": 1689755955000,
        "value": 139.513,
        "_internal_originalTime": 1689755955000
    },
    {
        "time": 1689755956000,
        "value": 139.511,
        "_internal_originalTime": 1689755956000
    },
    {
        "time": 1689755957000,
        "value": 139.505,
        "_internal_originalTime": 1689755957000
    },
    {
        "time": 1689755958000,
        "value": 139.502,
        "_internal_originalTime": 1689755958000
    },
    {
        "time": 1689755960000,
        "value": 139.51,
        "_internal_originalTime": 1689755960000
    },
    {
        "time": 1689755962000,
        "value": 139.516,
        "_internal_originalTime": 1689755962000
    },
    {
        "time": 1689755963000,
        "value": 139.511,
        "_internal_originalTime": 1689755963000
    },
    {
        "time": 1689755964000,
        "value": 139.511,
        "_internal_originalTime": 1689755964000
    },
    {
        "time": 1689755965000,
        "value": 139.502,
        "_internal_originalTime": 1689755965000
    },
    {
        "time": 1689755966000,
        "value": 139.508,
        "_internal_originalTime": 1689755966000
    },
    {
        "time": 1689755968000,
        "value": 139.509,
        "_internal_originalTime": 1689755968000
    },
    {
        "time": 1689755970000,
        "value": 139.507,
        "_internal_originalTime": 1689755970000
    },
    {
        "time": 1689755971000,
        "value": 139.511,
        "_internal_originalTime": 1689755971000
    },
    {
        "time": 1689755973000,
        "value": 139.506,
        "_internal_originalTime": 1689755973000
    },
    {
        "time": 1689755974000,
        "value": 139.508,
        "_internal_originalTime": 1689755974000
    },
    {
        "time": 1689755975000,
        "value": 139.511,
        "_internal_originalTime": 1689755975000
    },
    {
        "time": 1689755976000,
        "value": 139.507,
        "_internal_originalTime": 1689755976000
    },
    {
        "time": 1689755978000,
        "value": 139.507,
        "_internal_originalTime": 1689755978000
    },
    {
        "time": 1689755979000,
        "value": 139.508,
        "_internal_originalTime": 1689755979000
    },
    {
        "time": 1689755980000,
        "value": 139.51,
        "_internal_originalTime": 1689755980000
    },
    {
        "time": 1689755981000,
        "value": 139.507,
        "_internal_originalTime": 1689755981000
    },
    {
        "time": 1689755985000,
        "value": 139.506,
        "_internal_originalTime": 1689755985000
    },
    {
        "time": 1689755986000,
        "value": 139.507,
        "_internal_originalTime": 1689755986000
    },
    {
        "time": 1689755987000,
        "value": 139.508,
        "_internal_originalTime": 1689755987000
    },
    {
        "time": 1689755988000,
        "value": 139.506,
        "_internal_originalTime": 1689755988000
    },
    {
        "time": 1689755990000,
        "value": 139.509,
        "_internal_originalTime": 1689755990000
    },
    {
        "time": 1689755992000,
        "value": 139.509,
        "_internal_originalTime": 1689755992000
    },
    {
        "time": 1689755993000,
        "value": 139.508,
        "_internal_originalTime": 1689755993000
    },
    {
        "time": 1689755994000,
        "value": 139.509,
        "_internal_originalTime": 1689755994000
    },
    {
        "time": 1689755995000,
        "value": 139.509,
        "_internal_originalTime": 1689755995000
    },
    {
        "time": 1689755996000,
        "value": 139.507,
        "_internal_originalTime": 1689755996000
    },
    {
        "time": 1689755997000,
        "value": 139.508,
        "_internal_originalTime": 1689755997000
    },
    {
        "time": 1689755999000,
        "value": 139.511,
        "_internal_originalTime": 1689755999000
    },
    {
        "time": 1689756000000,
        "value": 139.51,
        "_internal_originalTime": 1689756000000
    },
    {
        "time": 1689756001000,
        "value": 139.509,
        "_internal_originalTime": 1689756001000
    },
    {
        "time": 1689756002000,
        "value": 139.519,
        "_internal_originalTime": 1689756002000
    },
    {
        "time": 1689756003000,
        "value": 139.517,
        "_internal_originalTime": 1689756003000
    },
    {
        "time": 1689756004000,
        "value": 139.519,
        "_internal_originalTime": 1689756004000
    },
    {
        "time": 1689756006000,
        "value": 139.516,
        "_internal_originalTime": 1689756006000
    },
    {
        "time": 1689756007000,
        "value": 139.517,
        "_internal_originalTime": 1689756007000
    },
    {
        "time": 1689756008000,
        "value": 139.518,
        "_internal_originalTime": 1689756008000
    },
    {
        "time": 1689756009000,
        "value": 139.514,
        "_internal_originalTime": 1689756009000
    },
    {
        "time": 1689756010000,
        "value": 139.522,
        "_internal_originalTime": 1689756010000
    },
    {
        "time": 1689756012000,
        "value": 139.521,
        "_internal_originalTime": 1689756012000
    },
    {
        "time": 1689756013000,
        "value": 139.515,
        "_internal_originalTime": 1689756013000
    },
    {
        "time": 1689756015000,
        "value": 139.52,
        "_internal_originalTime": 1689756015000
    },
    {
        "time": 1689756016000,
        "value": 139.52,
        "_internal_originalTime": 1689756016000
    },
    {
        "time": 1689756017000,
        "value": 139.521,
        "_internal_originalTime": 1689756017000
    },
    {
        "time": 1689756019000,
        "value": 139.518,
        "_internal_originalTime": 1689756019000
    },
    {
        "time": 1689756020000,
        "value": 139.517,
        "_internal_originalTime": 1689756020000
    },
    {
        "time": 1689756021000,
        "value": 139.519,
        "_internal_originalTime": 1689756021000
    },
    {
        "time": 1689756022000,
        "value": 139.514,
        "_internal_originalTime": 1689756022000
    },
    {
        "time": 1689756023000,
        "value": 139.515,
        "_internal_originalTime": 1689756023000
    },
    {
        "time": 1689756024000,
        "value": 139.519,
        "_internal_originalTime": 1689756024000
    },
    {
        "time": 1689756026000,
        "value": 139.515,
        "_internal_originalTime": 1689756026000
    },
    {
        "time": 1689756027000,
        "value": 139.519,
        "_internal_originalTime": 1689756027000
    },
    {
        "time": 1689756028000,
        "value": 139.518,
        "_internal_originalTime": 1689756028000
    },
    {
        "time": 1689756030000,
        "value": 139.528,
        "_internal_originalTime": 1689756030000
    },
    {
        "time": 1689756031000,
        "value": 139.529,
        "_internal_originalTime": 1689756031000
    },
    {
        "time": 1689756032000,
        "value": 139.529,
        "_internal_originalTime": 1689756032000
    },
    {
        "time": 1689756033000,
        "value": 139.529,
        "_internal_originalTime": 1689756033000
    },
    {
        "time": 1689756034000,
        "value": 139.531,
        "_internal_originalTime": 1689756034000
    },
    {
        "time": 1689756036000,
        "value": 139.527,
        "_internal_originalTime": 1689756036000
    },
    {
        "time": 1689756038000,
        "value": 139.527,
        "_internal_originalTime": 1689756038000
    },
    {
        "time": 1689756040000,
        "value": 139.53,
        "_internal_originalTime": 1689756040000
    },
    {
        "time": 1689756041000,
        "value": 139.527,
        "_internal_originalTime": 1689756041000
    },
    {
        "time": 1689756042000,
        "value": 139.524,
        "_internal_originalTime": 1689756042000
    },
    {
        "time": 1689756043000,
        "value": 139.525,
        "_internal_originalTime": 1689756043000
    },
    {
        "time": 1689756044000,
        "value": 139.531,
        "_internal_originalTime": 1689756044000
    },
    {
        "time": 1689756045000,
        "value": 139.533,
        "_internal_originalTime": 1689756045000
    },
    {
        "time": 1689756046000,
        "value": 139.53,
        "_internal_originalTime": 1689756046000
    },
    {
        "time": 1689756047000,
        "value": 139.531,
        "_internal_originalTime": 1689756047000
    },
    {
        "time": 1689756048000,
        "value": 139.531,
        "_internal_originalTime": 1689756048000
    },
    {
        "time": 1689756050000,
        "value": 139.534,
        "_internal_originalTime": 1689756050000
    },
    {
        "time": 1689756051000,
        "value": 139.53,
        "_internal_originalTime": 1689756051000
    },
    {
        "time": 1689756052000,
        "value": 139.537,
        "_internal_originalTime": 1689756052000
    },
    {
        "time": 1689756053000,
        "value": 139.537,
        "_internal_originalTime": 1689756053000
    },
    {
        "time": 1689756054000,
        "value": 139.536,
        "_internal_originalTime": 1689756054000
    },
    {
        "time": 1689756055000,
        "value": 139.535,
        "_internal_originalTime": 1689756055000
    },
    {
        "time": 1689756057000,
        "value": 139.534,
        "_internal_originalTime": 1689756057000
    },
    {
        "time": 1689756058000,
        "value": 139.528,
        "_internal_originalTime": 1689756058000
    },
    {
        "time": 1689756060000,
        "value": 139.534,
        "_internal_originalTime": 1689756060000
    },
    {
        "time": 1689756061000,
        "value": 139.54,
        "_internal_originalTime": 1689756061000
    },
    {
        "time": 1689756062000,
        "value": 139.535,
        "_internal_originalTime": 1689756062000
    },
    {
        "time": 1689756063000,
        "value": 139.537,
        "_internal_originalTime": 1689756063000
    },
    {
        "time": 1689756064000,
        "value": 139.537,
        "_internal_originalTime": 1689756064000
    },
    {
        "time": 1689756066000,
        "value": 139.539,
        "_internal_originalTime": 1689756066000
    },
    {
        "time": 1689756067000,
        "value": 139.536,
        "_internal_originalTime": 1689756067000
    },
    {
        "time": 1689756068000,
        "value": 139.539,
        "_internal_originalTime": 1689756068000
    },
    {
        "time": 1689756070000,
        "value": 139.534,
        "_internal_originalTime": 1689756070000
    },
    {
        "time": 1689756071000,
        "value": 139.538,
        "_internal_originalTime": 1689756071000
    },
    {
        "time": 1689756072000,
        "value": 139.534,
        "_internal_originalTime": 1689756072000
    },
    {
        "time": 1689756073000,
        "value": 139.539,
        "_internal_originalTime": 1689756073000
    },
    {
        "time": 1689756075000,
        "value": 139.538,
        "_internal_originalTime": 1689756075000
    },
    {
        "time": 1689756076000,
        "value": 139.537,
        "_internal_originalTime": 1689756076000
    },
    {
        "time": 1689756077000,
        "value": 139.536,
        "_internal_originalTime": 1689756077000
    },
    {
        "time": 1689756078000,
        "value": 139.538,
        "_internal_originalTime": 1689756078000
    },
    {
        "time": 1689756079000,
        "value": 139.538,
        "_internal_originalTime": 1689756079000
    },
    {
        "time": 1689756080000,
        "value": 139.536,
        "_internal_originalTime": 1689756080000
    },
    {
        "time": 1689756081000,
        "value": 139.534,
        "_internal_originalTime": 1689756081000
    },
    {
        "time": 1689756083000,
        "value": 139.537,
        "_internal_originalTime": 1689756083000
    },
    {
        "time": 1689756085000,
        "value": 139.538,
        "_internal_originalTime": 1689756085000
    },
    {
        "time": 1689756086000,
        "value": 139.539,
        "_internal_originalTime": 1689756086000
    },
    {
        "time": 1689756088000,
        "value": 139.535,
        "_internal_originalTime": 1689756088000
    },
    {
        "time": 1689756089000,
        "value": 139.541,
        "_internal_originalTime": 1689756089000
    },
    {
        "time": 1689756091000,
        "value": 139.541,
        "_internal_originalTime": 1689756091000
    },
    {
        "time": 1689756092000,
        "value": 139.539,
        "_internal_originalTime": 1689756092000
    },
    {
        "time": 1689756093000,
        "value": 139.535,
        "_internal_originalTime": 1689756093000
    },
    {
        "time": 1689756094000,
        "value": 139.529,
        "_internal_originalTime": 1689756094000
    },
    {
        "time": 1689756095000,
        "value": 139.531,
        "_internal_originalTime": 1689756095000
    },
    {
        "time": 1689756096000,
        "value": 139.532,
        "_internal_originalTime": 1689756096000
    },
    {
        "time": 1689756098000,
        "value": 139.532,
        "_internal_originalTime": 1689756098000
    },
    {
        "time": 1689756099000,
        "value": 139.532,
        "_internal_originalTime": 1689756099000
    },
    {
        "time": 1689756100000,
        "value": 139.535,
        "_internal_originalTime": 1689756100000
    },
    {
        "time": 1689756101000,
        "value": 139.537,
        "_internal_originalTime": 1689756101000
    },
    {
        "time": 1689756102000,
        "value": 139.538,
        "_internal_originalTime": 1689756102000
    },
    {
        "time": 1689756104000,
        "value": 139.538,
        "_internal_originalTime": 1689756104000
    },
    {
        "time": 1689756105000,
        "value": 139.537,
        "_internal_originalTime": 1689756105000
    },
    {
        "time": 1689756107000,
        "value": 139.538,
        "_internal_originalTime": 1689756107000
    },
    {
        "time": 1689756108000,
        "value": 139.536,
        "_internal_originalTime": 1689756108000
    },
    {
        "time": 1689756109000,
        "value": 139.54,
        "_internal_originalTime": 1689756109000
    },
    {
        "time": 1689756111000,
        "value": 139.542,
        "_internal_originalTime": 1689756111000
    },
    {
        "time": 1689756112000,
        "value": 139.549,
        "_internal_originalTime": 1689756112000
    },
    {
        "time": 1689756114000,
        "value": 139.547,
        "_internal_originalTime": 1689756114000
    },
    {
        "time": 1689756115000,
        "value": 139.548,
        "_internal_originalTime": 1689756115000
    },
    {
        "time": 1689756116000,
        "value": 139.549,
        "_internal_originalTime": 1689756116000
    },
    {
        "time": 1689756121000,
        "value": 139.549,
        "_internal_originalTime": 1689756121000
    },
    {
        "time": 1689756122000,
        "value": 139.548,
        "_internal_originalTime": 1689756122000
    },
    {
        "time": 1689756123000,
        "value": 139.548,
        "_internal_originalTime": 1689756123000
    },
    {
        "time": 1689756124000,
        "value": 139.55,
        "_internal_originalTime": 1689756124000
    },
    {
        "time": 1689756125000,
        "value": 139.54,
        "_internal_originalTime": 1689756125000
    },
    {
        "time": 1689756126000,
        "value": 139.545,
        "_internal_originalTime": 1689756126000
    },
    {
        "time": 1689756127000,
        "value": 139.547,
        "_internal_originalTime": 1689756127000
    },
    {
        "time": 1689756128000,
        "value": 139.548,
        "_internal_originalTime": 1689756128000
    },
    {
        "time": 1689756130000,
        "value": 139.546,
        "_internal_originalTime": 1689756130000
    },
    {
        "time": 1689756131000,
        "value": 139.55,
        "_internal_originalTime": 1689756131000
    },
    {
        "time": 1689756132000,
        "value": 139.548,
        "_internal_originalTime": 1689756132000
    },
    {
        "time": 1689756135000,
        "value": 139.557,
        "_internal_originalTime": 1689756135000
    },
    {
        "time": 1689756139000,
        "value": 139.558,
        "_internal_originalTime": 1689756139000
    },
    {
        "time": 1689756140000,
        "value": 139.557,
        "_internal_originalTime": 1689756140000
    },
    {
        "time": 1689756141000,
        "value": 139.559,
        "_internal_originalTime": 1689756141000
    },
    {
        "time": 1689756142000,
        "value": 139.559,
        "_internal_originalTime": 1689756142000
    },
    {
        "time": 1689756143000,
        "value": 139.557,
        "_internal_originalTime": 1689756143000
    },
    {
        "time": 1689756145000,
        "value": 139.558,
        "_internal_originalTime": 1689756145000
    },
    {
        "time": 1689756146000,
        "value": 139.549,
        "_internal_originalTime": 1689756146000
    },
    {
        "time": 1689756147000,
        "value": 139.552,
        "_internal_originalTime": 1689756147000
    },
    {
        "time": 1689756148000,
        "value": 139.554,
        "_internal_originalTime": 1689756148000
    },
    {
        "time": 1689756150000,
        "value": 139.558,
        "_internal_originalTime": 1689756150000
    },
    {
        "time": 1689756151000,
        "value": 139.556,
        "_internal_originalTime": 1689756151000
    },
    {
        "time": 1689756152000,
        "value": 139.557,
        "_internal_originalTime": 1689756152000
    },
    {
        "time": 1689756154000,
        "value": 139.557,
        "_internal_originalTime": 1689756154000
    },
    {
        "time": 1689756155000,
        "value": 139.548,
        "_internal_originalTime": 1689756155000
    },
    {
        "time": 1689756156000,
        "value": 139.548,
        "_internal_originalTime": 1689756156000
    },
    {
        "time": 1689756157000,
        "value": 139.548,
        "_internal_originalTime": 1689756157000
    },
    {
        "time": 1689756158000,
        "value": 139.552,
        "_internal_originalTime": 1689756158000
    },
    {
        "time": 1689756159000,
        "value": 139.551,
        "_internal_originalTime": 1689756159000
    },
    {
        "time": 1689756160000,
        "value": 139.548,
        "_internal_originalTime": 1689756160000
    },
    {
        "time": 1689756162000,
        "value": 139.548,
        "_internal_originalTime": 1689756162000
    },
    {
        "time": 1689756163000,
        "value": 139.548,
        "_internal_originalTime": 1689756163000
    },
    {
        "time": 1689756165000,
        "value": 139.548,
        "_internal_originalTime": 1689756165000
    },
    {
        "time": 1689756166000,
        "value": 139.553,
        "_internal_originalTime": 1689756166000
    },
    {
        "time": 1689756167000,
        "value": 139.556,
        "_internal_originalTime": 1689756167000
    },
    {
        "time": 1689756168000,
        "value": 139.557,
        "_internal_originalTime": 1689756168000
    },
    {
        "time": 1689756170000,
        "value": 139.568,
        "_internal_originalTime": 1689756170000
    },
    {
        "time": 1689756171000,
        "value": 139.567,
        "_internal_originalTime": 1689756171000
    },
    {
        "time": 1689756172000,
        "value": 139.561,
        "_internal_originalTime": 1689756172000
    },
    {
        "time": 1689756173000,
        "value": 139.561,
        "_internal_originalTime": 1689756173000
    },
    {
        "time": 1689756174000,
        "value": 139.558,
        "_internal_originalTime": 1689756174000
    },
    {
        "time": 1689756175000,
        "value": 139.557,
        "_internal_originalTime": 1689756175000
    },
    {
        "time": 1689756176000,
        "value": 139.554,
        "_internal_originalTime": 1689756176000
    },
    {
        "time": 1689756178000,
        "value": 139.559,
        "_internal_originalTime": 1689756178000
    },
    {
        "time": 1689756179000,
        "value": 139.56,
        "_internal_originalTime": 1689756179000
    },
    {
        "time": 1689756180000,
        "value": 139.56,
        "_internal_originalTime": 1689756180000
    },
    {
        "time": 1689756181000,
        "value": 139.56,
        "_internal_originalTime": 1689756181000
    },
    {
        "time": 1689756182000,
        "value": 139.561,
        "_internal_originalTime": 1689756182000
    },
    {
        "time": 1689756183000,
        "value": 139.56,
        "_internal_originalTime": 1689756183000
    },
    {
        "time": 1689756184000,
        "value": 139.566,
        "_internal_originalTime": 1689756184000
    },
    {
        "time": 1689756186000,
        "value": 139.566,
        "_internal_originalTime": 1689756186000
    },
    {
        "time": 1689756187000,
        "value": 139.565,
        "_internal_originalTime": 1689756187000
    },
    {
        "time": 1689756188000,
        "value": 139.567,
        "_internal_originalTime": 1689756188000
    },
    {
        "time": 1689756189000,
        "value": 139.567,
        "_internal_originalTime": 1689756189000
    },
    {
        "time": 1689756191000,
        "value": 139.567,
        "_internal_originalTime": 1689756191000
    },
    {
        "time": 1689756192000,
        "value": 139.565,
        "_internal_originalTime": 1689756192000
    },
    {
        "time": 1689756193000,
        "value": 139.564,
        "_internal_originalTime": 1689756193000
    },
    {
        "time": 1689756195000,
        "value": 139.57,
        "_internal_originalTime": 1689756195000
    },
    {
        "time": 1689756196000,
        "value": 139.567,
        "_internal_originalTime": 1689756196000
    },
    {
        "time": 1689756197000,
        "value": 139.569,
        "_internal_originalTime": 1689756197000
    },
    {
        "time": 1689756198000,
        "value": 139.567,
        "_internal_originalTime": 1689756198000
    },
    {
        "time": 1689756199000,
        "value": 139.569,
        "_internal_originalTime": 1689756199000
    },
    {
        "time": 1689756200000,
        "value": 139.566,
        "_internal_originalTime": 1689756200000
    },
    {
        "time": 1689756202000,
        "value": 139.57,
        "_internal_originalTime": 1689756202000
    },
    {
        "time": 1689756203000,
        "value": 139.563,
        "_internal_originalTime": 1689756203000
    },
    {
        "time": 1689756205000,
        "value": 139.561,
        "_internal_originalTime": 1689756205000
    },
    {
        "time": 1689756206000,
        "value": 139.566,
        "_internal_originalTime": 1689756206000
    },
    {
        "time": 1689756207000,
        "value": 139.562,
        "_internal_originalTime": 1689756207000
    },
    {
        "time": 1689756210000,
        "value": 139.558,
        "_internal_originalTime": 1689756210000
    },
    {
        "time": 1689756211000,
        "value": 139.566,
        "_internal_originalTime": 1689756211000
    },
    {
        "time": 1689756213000,
        "value": 139.566,
        "_internal_originalTime": 1689756213000
    },
    {
        "time": 1689756214000,
        "value": 139.567,
        "_internal_originalTime": 1689756214000
    },
    {
        "time": 1689756215000,
        "value": 139.567,
        "_internal_originalTime": 1689756215000
    },
    {
        "time": 1689756216000,
        "value": 139.561,
        "_internal_originalTime": 1689756216000
    },
    {
        "time": 1689756218000,
        "value": 139.56,
        "_internal_originalTime": 1689756218000
    },
    {
        "time": 1689756219000,
        "value": 139.559,
        "_internal_originalTime": 1689756219000
    },
    {
        "time": 1689756220000,
        "value": 139.558,
        "_internal_originalTime": 1689756220000
    },
    {
        "time": 1689756222000,
        "value": 139.555,
        "_internal_originalTime": 1689756222000
    },
    {
        "time": 1689756224000,
        "value": 139.56,
        "_internal_originalTime": 1689756224000
    },
    {
        "time": 1689756225000,
        "value": 139.559,
        "_internal_originalTime": 1689756225000
    },
    {
        "time": 1689756226000,
        "value": 139.558,
        "_internal_originalTime": 1689756226000
    },
    {
        "time": 1689756228000,
        "value": 139.555,
        "_internal_originalTime": 1689756228000
    },
    {
        "time": 1689756229000,
        "value": 139.553,
        "_internal_originalTime": 1689756229000
    },
    {
        "time": 1689756230000,
        "value": 139.553,
        "_internal_originalTime": 1689756230000
    },
    {
        "time": 1689756231000,
        "value": 139.552,
        "_internal_originalTime": 1689756231000
    },
    {
        "time": 1689756232000,
        "value": 139.552,
        "_internal_originalTime": 1689756232000
    },
    {
        "time": 1689756234000,
        "value": 139.552,
        "_internal_originalTime": 1689756234000
    },
    {
        "time": 1689756236000,
        "value": 139.554,
        "_internal_originalTime": 1689756236000
    },
    {
        "time": 1689756237000,
        "value": 139.548,
        "_internal_originalTime": 1689756237000
    },
    {
        "time": 1689756240000,
        "value": 139.55,
        "_internal_originalTime": 1689756240000
    },
    {
        "time": 1689756242000,
        "value": 139.551,
        "_internal_originalTime": 1689756242000
    },
    {
        "time": 1689756243000,
        "value": 139.554,
        "_internal_originalTime": 1689756243000
    },
    {
        "time": 1689756245000,
        "value": 139.551,
        "_internal_originalTime": 1689756245000
    },
    {
        "time": 1689756246000,
        "value": 139.553,
        "_internal_originalTime": 1689756246000
    },
    {
        "time": 1689756248000,
        "value": 139.547,
        "_internal_originalTime": 1689756248000
    },
    {
        "time": 1689756249000,
        "value": 139.548,
        "_internal_originalTime": 1689756249000
    },
    {
        "time": 1689756250000,
        "value": 139.55,
        "_internal_originalTime": 1689756250000
    },
    {
        "time": 1689756252000,
        "value": 139.547,
        "_internal_originalTime": 1689756252000
    },
    {
        "time": 1689756253000,
        "value": 139.548,
        "_internal_originalTime": 1689756253000
    },
    {
        "time": 1689756255000,
        "value": 139.552,
        "_internal_originalTime": 1689756255000
    },
    {
        "time": 1689756257000,
        "value": 139.549,
        "_internal_originalTime": 1689756257000
    },
    {
        "time": 1689756258000,
        "value": 139.551,
        "_internal_originalTime": 1689756258000
    },
    {
        "time": 1689756260000,
        "value": 139.547,
        "_internal_originalTime": 1689756260000
    },
    {
        "time": 1689756261000,
        "value": 139.549,
        "_internal_originalTime": 1689756261000
    },
    {
        "time": 1689756262000,
        "value": 139.551,
        "_internal_originalTime": 1689756262000
    },
    {
        "time": 1689756263000,
        "value": 139.55,
        "_internal_originalTime": 1689756263000
    },
    {
        "time": 1689756264000,
        "value": 139.55,
        "_internal_originalTime": 1689756264000
    },
    {
        "time": 1689756265000,
        "value": 139.549,
        "_internal_originalTime": 1689756265000
    },
    {
        "time": 1689756267000,
        "value": 139.551,
        "_internal_originalTime": 1689756267000
    },
    {
        "time": 1689756268000,
        "value": 139.554,
        "_internal_originalTime": 1689756268000
    },
    {
        "time": 1689756269000,
        "value": 139.557,
        "_internal_originalTime": 1689756269000
    },
    {
        "time": 1689756271000,
        "value": 139.556,
        "_internal_originalTime": 1689756271000
    },
    {
        "time": 1689756272000,
        "value": 139.554,
        "_internal_originalTime": 1689756272000
    },
    {
        "time": 1689756273000,
        "value": 139.553,
        "_internal_originalTime": 1689756273000
    },
    {
        "time": 1689756275000,
        "value": 139.55,
        "_internal_originalTime": 1689756275000
    },
    {
        "time": 1689756276000,
        "value": 139.555,
        "_internal_originalTime": 1689756276000
    },
    {
        "time": 1689756277000,
        "value": 139.552,
        "_internal_originalTime": 1689756277000
    },
    {
        "time": 1689756278000,
        "value": 139.551,
        "_internal_originalTime": 1689756278000
    },
    {
        "time": 1689756279000,
        "value": 139.552,
        "_internal_originalTime": 1689756279000
    },
    {
        "time": 1689756280000,
        "value": 139.553,
        "_internal_originalTime": 1689756280000
    },
    {
        "time": 1689756281000,
        "value": 139.552,
        "_internal_originalTime": 1689756281000
    },
    {
        "time": 1689756282000,
        "value": 139.553,
        "_internal_originalTime": 1689756282000
    },
    {
        "time": 1689756283000,
        "value": 139.553,
        "_internal_originalTime": 1689756283000
    },
    {
        "time": 1689756287000,
        "value": 139.566,
        "_internal_originalTime": 1689756287000
    },
    {
        "time": 1689756288000,
        "value": 139.566,
        "_internal_originalTime": 1689756288000
    },
    {
        "time": 1689756289000,
        "value": 139.567,
        "_internal_originalTime": 1689756289000
    },
    {
        "time": 1689756291000,
        "value": 139.564,
        "_internal_originalTime": 1689756291000
    },
    {
        "time": 1689756293000,
        "value": 139.566,
        "_internal_originalTime": 1689756293000
    },
    {
        "time": 1689756296000,
        "value": 139.569,
        "_internal_originalTime": 1689756296000
    },
    {
        "time": 1689756297000,
        "value": 139.575,
        "_internal_originalTime": 1689756297000
    },
    {
        "time": 1689756298000,
        "value": 139.572,
        "_internal_originalTime": 1689756298000
    },
    {
        "time": 1689756299000,
        "value": 139.575,
        "_internal_originalTime": 1689756299000
    },
    {
        "time": 1689756300000,
        "value": 139.574,
        "_internal_originalTime": 1689756300000
    },
    {
        "time": 1689756301000,
        "value": 139.576,
        "_internal_originalTime": 1689756301000
    },
    {
        "time": 1689756302000,
        "value": 139.572,
        "_internal_originalTime": 1689756302000
    },
    {
        "time": 1689756304000,
        "value": 139.576,
        "_internal_originalTime": 1689756304000
    },
    {
        "time": 1689756305000,
        "value": 139.574,
        "_internal_originalTime": 1689756305000
    },
    {
        "time": 1689756306000,
        "value": 139.576,
        "_internal_originalTime": 1689756306000
    },
    {
        "time": 1689756307000,
        "value": 139.574,
        "_internal_originalTime": 1689756307000
    },
    {
        "time": 1689756309000,
        "value": 139.582,
        "_internal_originalTime": 1689756309000
    },
    {
        "time": 1689756310000,
        "value": 139.581,
        "_internal_originalTime": 1689756310000
    },
    {
        "time": 1689756311000,
        "value": 139.587,
        "_internal_originalTime": 1689756311000
    },
    {
        "time": 1689756313000,
        "value": 139.586,
        "_internal_originalTime": 1689756313000
    },
    {
        "time": 1689756314000,
        "value": 139.589,
        "_internal_originalTime": 1689756314000
    },
    {
        "time": 1689756315000,
        "value": 139.588,
        "_internal_originalTime": 1689756315000
    },
    {
        "time": 1689756317000,
        "value": 139.588,
        "_internal_originalTime": 1689756317000
    },
    {
        "time": 1689756318000,
        "value": 139.59,
        "_internal_originalTime": 1689756318000
    },
    {
        "time": 1689756319000,
        "value": 139.586,
        "_internal_originalTime": 1689756319000
    },
    {
        "time": 1689756321000,
        "value": 139.586,
        "_internal_originalTime": 1689756321000
    },
    {
        "time": 1689756322000,
        "value": 139.587,
        "_internal_originalTime": 1689756322000
    },
    {
        "time": 1689756324000,
        "value": 139.586,
        "_internal_originalTime": 1689756324000
    },
    {
        "time": 1689756325000,
        "value": 139.588,
        "_internal_originalTime": 1689756325000
    },
    {
        "time": 1689756326000,
        "value": 139.587,
        "_internal_originalTime": 1689756326000
    },
    {
        "time": 1689756329000,
        "value": 139.588,
        "_internal_originalTime": 1689756329000
    },
    {
        "time": 1689756330000,
        "value": 139.587,
        "_internal_originalTime": 1689756330000
    },
    {
        "time": 1689756332000,
        "value": 139.589,
        "_internal_originalTime": 1689756332000
    },
    {
        "time": 1689756333000,
        "value": 139.595,
        "_internal_originalTime": 1689756333000
    },
    {
        "time": 1689756334000,
        "value": 139.596,
        "_internal_originalTime": 1689756334000
    },
    {
        "time": 1689756336000,
        "value": 139.596,
        "_internal_originalTime": 1689756336000
    },
    {
        "time": 1689756337000,
        "value": 139.596,
        "_internal_originalTime": 1689756337000
    },
    {
        "time": 1689756338000,
        "value": 139.595,
        "_internal_originalTime": 1689756338000
    },
    {
        "time": 1689756339000,
        "value": 139.601,
        "_internal_originalTime": 1689756339000
    },
    {
        "time": 1689756341000,
        "value": 139.598,
        "_internal_originalTime": 1689756341000
    },
    {
        "time": 1689756342000,
        "value": 139.598,
        "_internal_originalTime": 1689756342000
    },
    {
        "time": 1689756343000,
        "value": 139.6,
        "_internal_originalTime": 1689756343000
    },
    {
        "time": 1689756344000,
        "value": 139.597,
        "_internal_originalTime": 1689756344000
    },
    {
        "time": 1689756345000,
        "value": 139.597,
        "_internal_originalTime": 1689756345000
    },
    {
        "time": 1689756347000,
        "value": 139.6,
        "_internal_originalTime": 1689756347000
    },
    {
        "time": 1689756348000,
        "value": 139.595,
        "_internal_originalTime": 1689756348000
    },
    {
        "time": 1689756349000,
        "value": 139.598,
        "_internal_originalTime": 1689756349000
    },
    {
        "time": 1689756351000,
        "value": 139.597,
        "_internal_originalTime": 1689756351000
    },
    {
        "time": 1689756353000,
        "value": 139.595,
        "_internal_originalTime": 1689756353000
    },
    {
        "time": 1689756355000,
        "value": 139.595,
        "_internal_originalTime": 1689756355000
    },
    {
        "time": 1689756356000,
        "value": 139.595,
        "_internal_originalTime": 1689756356000
    },
    {
        "time": 1689756357000,
        "value": 139.596,
        "_internal_originalTime": 1689756357000
    },
    {
        "time": 1689756358000,
        "value": 139.596,
        "_internal_originalTime": 1689756358000
    },
    {
        "time": 1689756360000,
        "value": 139.597,
        "_internal_originalTime": 1689756360000
    },
    {
        "time": 1689756361000,
        "value": 139.596,
        "_internal_originalTime": 1689756361000
    },
    {
        "time": 1689756362000,
        "value": 139.597,
        "_internal_originalTime": 1689756362000
    },
    {
        "time": 1689756363000,
        "value": 139.592,
        "_internal_originalTime": 1689756363000
    },
    {
        "time": 1689756365000,
        "value": 139.598,
        "_internal_originalTime": 1689756365000
    },
    {
        "time": 1689756366000,
        "value": 139.597,
        "_internal_originalTime": 1689756366000
    },
    {
        "time": 1689756367000,
        "value": 139.594,
        "_internal_originalTime": 1689756367000
    },
    {
        "time": 1689756368000,
        "value": 139.597,
        "_internal_originalTime": 1689756368000
    },
    {
        "time": 1689756369000,
        "value": 139.596,
        "_internal_originalTime": 1689756369000
    },
    {
        "time": 1689756371000,
        "value": 139.596,
        "_internal_originalTime": 1689756371000
    },
    {
        "time": 1689756372000,
        "value": 139.596,
        "_internal_originalTime": 1689756372000
    },
    {
        "time": 1689756374000,
        "value": 139.608,
        "_internal_originalTime": 1689756374000
    },
    {
        "time": 1689756375000,
        "value": 139.607,
        "_internal_originalTime": 1689756375000
    },
    {
        "time": 1689756376000,
        "value": 139.607,
        "_internal_originalTime": 1689756376000
    },
    {
        "time": 1689756377000,
        "value": 139.608,
        "_internal_originalTime": 1689756377000
    },
    {
        "time": 1689756378000,
        "value": 139.61,
        "_internal_originalTime": 1689756378000
    },
    {
        "time": 1689756379000,
        "value": 139.615,
        "_internal_originalTime": 1689756379000
    },
    {
        "time": 1689756380000,
        "value": 139.61,
        "_internal_originalTime": 1689756380000
    },
    {
        "time": 1689756383000,
        "value": 139.613,
        "_internal_originalTime": 1689756383000
    },
    {
        "time": 1689756384000,
        "value": 139.611,
        "_internal_originalTime": 1689756384000
    },
    {
        "time": 1689756385000,
        "value": 139.61,
        "_internal_originalTime": 1689756385000
    },
    {
        "time": 1689756386000,
        "value": 139.612,
        "_internal_originalTime": 1689756386000
    },
    {
        "time": 1689756388000,
        "value": 139.61,
        "_internal_originalTime": 1689756388000
    },
    {
        "time": 1689756389000,
        "value": 139.612,
        "_internal_originalTime": 1689756389000
    },
    {
        "time": 1689756391000,
        "value": 139.615,
        "_internal_originalTime": 1689756391000
    },
    {
        "time": 1689756392000,
        "value": 139.615,
        "_internal_originalTime": 1689756392000
    },
    {
        "time": 1689756393000,
        "value": 139.609,
        "_internal_originalTime": 1689756393000
    },
    {
        "time": 1689756394000,
        "value": 139.611,
        "_internal_originalTime": 1689756394000
    },
    {
        "time": 1689756397000,
        "value": 139.625,
        "_internal_originalTime": 1689756397000
    },
    {
        "time": 1689756398000,
        "value": 139.627,
        "_internal_originalTime": 1689756398000
    },
    {
        "time": 1689756399000,
        "value": 139.623,
        "_internal_originalTime": 1689756399000
    },
    {
        "time": 1689756400000,
        "value": 139.628,
        "_internal_originalTime": 1689756400000
    },
    {
        "time": 1689756401000,
        "value": 139.628,
        "_internal_originalTime": 1689756401000
    },
    {
        "time": 1689756402000,
        "value": 139.628,
        "_internal_originalTime": 1689756402000
    },
    {
        "time": 1689756403000,
        "value": 139.628,
        "_internal_originalTime": 1689756403000
    },
    {
        "time": 1689756404000,
        "value": 139.625,
        "_internal_originalTime": 1689756404000
    },
    {
        "time": 1689756406000,
        "value": 139.624,
        "_internal_originalTime": 1689756406000
    },
    {
        "time": 1689756407000,
        "value": 139.623,
        "_internal_originalTime": 1689756407000
    },
    {
        "time": 1689756409000,
        "value": 139.625,
        "_internal_originalTime": 1689756409000
    },
    {
        "time": 1689756410000,
        "value": 139.624,
        "_internal_originalTime": 1689756410000
    },
    {
        "time": 1689756411000,
        "value": 139.62,
        "_internal_originalTime": 1689756411000
    },
    {
        "time": 1689756413000,
        "value": 139.615,
        "_internal_originalTime": 1689756413000
    },
    {
        "time": 1689756414000,
        "value": 139.619,
        "_internal_originalTime": 1689756414000
    },
    {
        "time": 1689756415000,
        "value": 139.618,
        "_internal_originalTime": 1689756415000
    },
    {
        "time": 1689756416000,
        "value": 139.615,
        "_internal_originalTime": 1689756416000
    },
    {
        "time": 1689756417000,
        "value": 139.617,
        "_internal_originalTime": 1689756417000
    },
    {
        "time": 1689756419000,
        "value": 139.614,
        "_internal_originalTime": 1689756419000
    },
    {
        "time": 1689756420000,
        "value": 139.614,
        "_internal_originalTime": 1689756420000
    },
    {
        "time": 1689756421000,
        "value": 139.59,
        "_internal_originalTime": 1689756421000
    },
    {
        "time": 1689756423000,
        "value": 139.613,
        "_internal_originalTime": 1689756423000
    },
    {
        "time": 1689756424000,
        "value": 139.621,
        "_internal_originalTime": 1689756424000
    },
    {
        "time": 1689756425000,
        "value": 139.622,
        "_internal_originalTime": 1689756425000
    },
    {
        "time": 1689756426000,
        "value": 139.628,
        "_internal_originalTime": 1689756426000
    },
    {
        "time": 1689756427000,
        "value": 139.63,
        "_internal_originalTime": 1689756427000
    },
    {
        "time": 1689756429000,
        "value": 139.626,
        "_internal_originalTime": 1689756429000
    },
    {
        "time": 1689756430000,
        "value": 139.627,
        "_internal_originalTime": 1689756430000
    },
    {
        "time": 1689756431000,
        "value": 139.63,
        "_internal_originalTime": 1689756431000
    },
    {
        "time": 1689756432000,
        "value": 139.628,
        "_internal_originalTime": 1689756432000
    },
    {
        "time": 1689756434000,
        "value": 139.628,
        "_internal_originalTime": 1689756434000
    },
    {
        "time": 1689756436000,
        "value": 139.626,
        "_internal_originalTime": 1689756436000
    },
    {
        "time": 1689756437000,
        "value": 139.629,
        "_internal_originalTime": 1689756437000
    },
    {
        "time": 1689756438000,
        "value": 139.628,
        "_internal_originalTime": 1689756438000
    },
    {
        "time": 1689756439000,
        "value": 139.629,
        "_internal_originalTime": 1689756439000
    },
    {
        "time": 1689756440000,
        "value": 139.627,
        "_internal_originalTime": 1689756440000
    },
    {
        "time": 1689756441000,
        "value": 139.629,
        "_internal_originalTime": 1689756441000
    },
    {
        "time": 1689756444000,
        "value": 139.63,
        "_internal_originalTime": 1689756444000
    },
    {
        "time": 1689756445000,
        "value": 139.632,
        "_internal_originalTime": 1689756445000
    },
    {
        "time": 1689756447000,
        "value": 139.629,
        "_internal_originalTime": 1689756447000
    },
    {
        "time": 1689756448000,
        "value": 139.624,
        "_internal_originalTime": 1689756448000
    },
    {
        "time": 1689756449000,
        "value": 139.62,
        "_internal_originalTime": 1689756449000
    },
    {
        "time": 1689756450000,
        "value": 139.623,
        "_internal_originalTime": 1689756450000
    },
    {
        "time": 1689756452000,
        "value": 139.633,
        "_internal_originalTime": 1689756452000
    },
    {
        "time": 1689756453000,
        "value": 139.631,
        "_internal_originalTime": 1689756453000
    },
    {
        "time": 1689756455000,
        "value": 139.631,
        "_internal_originalTime": 1689756455000
    },
    {
        "time": 1689756456000,
        "value": 139.632,
        "_internal_originalTime": 1689756456000
    },
    {
        "time": 1689756457000,
        "value": 139.637,
        "_internal_originalTime": 1689756457000
    },
    {
        "time": 1689756458000,
        "value": 139.634,
        "_internal_originalTime": 1689756458000
    },
    {
        "time": 1689756459000,
        "value": 139.635,
        "_internal_originalTime": 1689756459000
    },
    {
        "time": 1689756461000,
        "value": 139.634,
        "_internal_originalTime": 1689756461000
    },
    {
        "time": 1689756462000,
        "value": 139.632,
        "_internal_originalTime": 1689756462000
    },
    {
        "time": 1689756463000,
        "value": 139.633,
        "_internal_originalTime": 1689756463000
    },
    {
        "time": 1689756464000,
        "value": 139.632,
        "_internal_originalTime": 1689756464000
    },
    {
        "time": 1689756467000,
        "value": 139.637,
        "_internal_originalTime": 1689756467000
    },
    {
        "time": 1689756468000,
        "value": 139.632,
        "_internal_originalTime": 1689756468000
    },
    {
        "time": 1689756469000,
        "value": 139.63,
        "_internal_originalTime": 1689756469000
    },
    {
        "time": 1689756471000,
        "value": 139.634,
        "_internal_originalTime": 1689756471000
    },
    {
        "time": 1689756472000,
        "value": 139.635,
        "_internal_originalTime": 1689756472000
    },
    {
        "time": 1689756474000,
        "value": 139.63,
        "_internal_originalTime": 1689756474000
    },
    {
        "time": 1689756475000,
        "value": 139.632,
        "_internal_originalTime": 1689756475000
    },
    {
        "time": 1689756476000,
        "value": 139.623,
        "_internal_originalTime": 1689756476000
    },
    {
        "time": 1689756478000,
        "value": 139.624,
        "_internal_originalTime": 1689756478000
    },
    {
        "time": 1689756480000,
        "value": 139.622,
        "_internal_originalTime": 1689756480000
    },
    {
        "time": 1689756481000,
        "value": 139.62,
        "_internal_originalTime": 1689756481000
    },
    {
        "time": 1689756482000,
        "value": 139.631,
        "_internal_originalTime": 1689756482000
    },
    {
        "time": 1689756483000,
        "value": 139.63,
        "_internal_originalTime": 1689756483000
    },
    {
        "time": 1689756484000,
        "value": 139.632,
        "_internal_originalTime": 1689756484000
    },
    {
        "time": 1689756485000,
        "value": 139.631,
        "_internal_originalTime": 1689756485000
    },
    {
        "time": 1689756486000,
        "value": 139.631,
        "_internal_originalTime": 1689756486000
    },
    {
        "time": 1689756488000,
        "value": 139.626,
        "_internal_originalTime": 1689756488000
    },
    {
        "time": 1689756489000,
        "value": 139.627,
        "_internal_originalTime": 1689756489000
    },
    {
        "time": 1689756490000,
        "value": 139.622,
        "_internal_originalTime": 1689756490000
    },
    {
        "time": 1689756491000,
        "value": 139.625,
        "_internal_originalTime": 1689756491000
    },
    {
        "time": 1689756493000,
        "value": 139.626,
        "_internal_originalTime": 1689756493000
    },
    {
        "time": 1689756494000,
        "value": 139.626,
        "_internal_originalTime": 1689756494000
    },
    {
        "time": 1689756495000,
        "value": 139.632,
        "_internal_originalTime": 1689756495000
    },
    {
        "time": 1689756497000,
        "value": 139.632,
        "_internal_originalTime": 1689756497000
    },
    {
        "time": 1689756498000,
        "value": 139.622,
        "_internal_originalTime": 1689756498000
    },
    {
        "time": 1689756499000,
        "value": 139.627,
        "_internal_originalTime": 1689756499000
    },
    {
        "time": 1689756500000,
        "value": 139.626,
        "_internal_originalTime": 1689756500000
    },
    {
        "time": 1689756501000,
        "value": 139.628,
        "_internal_originalTime": 1689756501000
    },
    {
        "time": 1689756502000,
        "value": 139.624,
        "_internal_originalTime": 1689756502000
    },
    {
        "time": 1689756503000,
        "value": 139.628,
        "_internal_originalTime": 1689756503000
    },
    {
        "time": 1689756505000,
        "value": 139.627,
        "_internal_originalTime": 1689756505000
    },
    {
        "time": 1689756506000,
        "value": 139.625,
        "_internal_originalTime": 1689756506000
    },
    {
        "time": 1689756507000,
        "value": 139.627,
        "_internal_originalTime": 1689756507000
    },
    {
        "time": 1689756508000,
        "value": 139.625,
        "_internal_originalTime": 1689756508000
    },
    {
        "time": 1689756510000,
        "value": 139.626,
        "_internal_originalTime": 1689756510000
    },
    {
        "time": 1689756511000,
        "value": 139.625,
        "_internal_originalTime": 1689756511000
    },
    {
        "time": 1689756512000,
        "value": 139.624,
        "_internal_originalTime": 1689756512000
    },
    {
        "time": 1689756514000,
        "value": 139.629,
        "_internal_originalTime": 1689756514000
    },
    {
        "time": 1689756515000,
        "value": 139.626,
        "_internal_originalTime": 1689756515000
    },
    {
        "time": 1689756516000,
        "value": 139.627,
        "_internal_originalTime": 1689756516000
    },
    {
        "time": 1689756517000,
        "value": 139.625,
        "_internal_originalTime": 1689756517000
    },
    {
        "time": 1689756518000,
        "value": 139.626,
        "_internal_originalTime": 1689756518000
    },
    {
        "time": 1689756520000,
        "value": 139.628,
        "_internal_originalTime": 1689756520000
    },
    {
        "time": 1689756521000,
        "value": 139.627,
        "_internal_originalTime": 1689756521000
    },
    {
        "time": 1689756522000,
        "value": 139.624,
        "_internal_originalTime": 1689756522000
    },
    {
        "time": 1689756524000,
        "value": 139.625,
        "_internal_originalTime": 1689756524000
    },
    {
        "time": 1689756525000,
        "value": 139.627,
        "_internal_originalTime": 1689756525000
    },
    {
        "time": 1689756526000,
        "value": 139.624,
        "_internal_originalTime": 1689756526000
    },
    {
        "time": 1689756527000,
        "value": 139.627,
        "_internal_originalTime": 1689756527000
    },
    {
        "time": 1689756529000,
        "value": 139.626,
        "_internal_originalTime": 1689756529000
    },
    {
        "time": 1689756530000,
        "value": 139.619,
        "_internal_originalTime": 1689756530000
    },
    {
        "time": 1689756531000,
        "value": 139.623,
        "_internal_originalTime": 1689756531000
    },
    {
        "time": 1689756532000,
        "value": 139.623,
        "_internal_originalTime": 1689756532000
    },
    {
        "time": 1689756533000,
        "value": 139.618,
        "_internal_originalTime": 1689756533000
    },
    {
        "time": 1689756534000,
        "value": 139.622,
        "_internal_originalTime": 1689756534000
    },
    {
        "time": 1689756536000,
        "value": 139.622,
        "_internal_originalTime": 1689756536000
    },
    {
        "time": 1689756537000,
        "value": 139.622,
        "_internal_originalTime": 1689756537000
    },
    {
        "time": 1689756538000,
        "value": 139.618,
        "_internal_originalTime": 1689756538000
    },
    {
        "time": 1689756540000,
        "value": 139.625,
        "_internal_originalTime": 1689756540000
    },
    {
        "time": 1689756541000,
        "value": 139.62,
        "_internal_originalTime": 1689756541000
    },
    {
        "time": 1689756542000,
        "value": 139.624,
        "_internal_originalTime": 1689756542000
    },
    {
        "time": 1689756544000,
        "value": 139.624,
        "_internal_originalTime": 1689756544000
    },
    {
        "time": 1689756545000,
        "value": 139.623,
        "_internal_originalTime": 1689756545000
    },
    {
        "time": 1689756546000,
        "value": 139.623,
        "_internal_originalTime": 1689756546000
    },
    {
        "time": 1689756547000,
        "value": 139.618,
        "_internal_originalTime": 1689756547000
    },
    {
        "time": 1689756548000,
        "value": 139.623,
        "_internal_originalTime": 1689756548000
    },
    {
        "time": 1689756549000,
        "value": 139.623,
        "_internal_originalTime": 1689756549000
    },
    {
        "time": 1689756551000,
        "value": 139.62,
        "_internal_originalTime": 1689756551000
    },
    {
        "time": 1689756552000,
        "value": 139.622,
        "_internal_originalTime": 1689756552000
    },
    {
        "time": 1689756555000,
        "value": 139.627,
        "_internal_originalTime": 1689756555000
    },
    {
        "time": 1689756556000,
        "value": 139.629,
        "_internal_originalTime": 1689756556000
    },
    {
        "time": 1689756557000,
        "value": 139.627,
        "_internal_originalTime": 1689756557000
    },
    {
        "time": 1689756559000,
        "value": 139.629,
        "_internal_originalTime": 1689756559000
    },
    {
        "time": 1689756560000,
        "value": 139.627,
        "_internal_originalTime": 1689756560000
    },
    {
        "time": 1689756561000,
        "value": 139.625,
        "_internal_originalTime": 1689756561000
    },
    {
        "time": 1689756562000,
        "value": 139.624,
        "_internal_originalTime": 1689756562000
    },
    {
        "time": 1689756564000,
        "value": 139.627,
        "_internal_originalTime": 1689756564000
    },
    {
        "time": 1689756566000,
        "value": 139.63,
        "_internal_originalTime": 1689756566000
    },
    {
        "time": 1689756567000,
        "value": 139.629,
        "_internal_originalTime": 1689756567000
    },
    {
        "time": 1689756568000,
        "value": 139.63,
        "_internal_originalTime": 1689756568000
    },
    {
        "time": 1689756570000,
        "value": 139.629,
        "_internal_originalTime": 1689756570000
    },
    {
        "time": 1689756571000,
        "value": 139.625,
        "_internal_originalTime": 1689756571000
    },
    {
        "time": 1689756572000,
        "value": 139.628,
        "_internal_originalTime": 1689756572000
    },
    {
        "time": 1689756573000,
        "value": 139.629,
        "_internal_originalTime": 1689756573000
    },
    {
        "time": 1689756574000,
        "value": 139.628,
        "_internal_originalTime": 1689756574000
    },
    {
        "time": 1689756575000,
        "value": 139.629,
        "_internal_originalTime": 1689756575000
    },
    {
        "time": 1689756577000,
        "value": 139.628,
        "_internal_originalTime": 1689756577000
    },
    {
        "time": 1689756578000,
        "value": 139.628,
        "_internal_originalTime": 1689756578000
    },
    {
        "time": 1689756579000,
        "value": 139.629,
        "_internal_originalTime": 1689756579000
    },
    {
        "time": 1689756580000,
        "value": 139.625,
        "_internal_originalTime": 1689756580000
    },
    {
        "time": 1689756582000,
        "value": 139.629,
        "_internal_originalTime": 1689756582000
    },
    {
        "time": 1689756583000,
        "value": 139.641,
        "_internal_originalTime": 1689756583000
    },
    {
        "time": 1689756584000,
        "value": 139.641,
        "_internal_originalTime": 1689756584000
    },
    {
        "time": 1689756585000,
        "value": 139.642,
        "_internal_originalTime": 1689756585000
    },
    {
        "time": 1689756586000,
        "value": 139.64,
        "_internal_originalTime": 1689756586000
    },
    {
        "time": 1689756588000,
        "value": 139.639,
        "_internal_originalTime": 1689756588000
    },
    {
        "time": 1689756589000,
        "value": 139.642,
        "_internal_originalTime": 1689756589000
    },
    {
        "time": 1689756590000,
        "value": 139.641,
        "_internal_originalTime": 1689756590000
    },
    {
        "time": 1689756591000,
        "value": 139.641,
        "_internal_originalTime": 1689756591000
    },
    {
        "time": 1689756592000,
        "value": 139.638,
        "_internal_originalTime": 1689756592000
    },
    {
        "time": 1689756594000,
        "value": 139.639,
        "_internal_originalTime": 1689756594000
    },
    {
        "time": 1689756595000,
        "value": 139.64,
        "_internal_originalTime": 1689756595000
    },
    {
        "time": 1689756596000,
        "value": 139.638,
        "_internal_originalTime": 1689756596000
    },
    {
        "time": 1689756598000,
        "value": 139.641,
        "_internal_originalTime": 1689756598000
    },
    {
        "time": 1689756600000,
        "value": 139.639,
        "_internal_originalTime": 1689756600000
    },
    {
        "time": 1689756602000,
        "value": 139.642,
        "_internal_originalTime": 1689756602000
    },
    {
        "time": 1689756603000,
        "value": 139.639,
        "_internal_originalTime": 1689756603000
    },
    {
        "time": 1689756604000,
        "value": 139.641,
        "_internal_originalTime": 1689756604000
    },
    {
        "time": 1689756605000,
        "value": 139.638,
        "_internal_originalTime": 1689756605000
    },
    {
        "time": 1689756607000,
        "value": 139.644,
        "_internal_originalTime": 1689756607000
    },
    {
        "time": 1689756608000,
        "value": 139.642,
        "_internal_originalTime": 1689756608000
    },
    {
        "time": 1689756609000,
        "value": 139.646,
        "_internal_originalTime": 1689756609000
    },
    {
        "time": 1689756610000,
        "value": 139.644,
        "_internal_originalTime": 1689756610000
    },
    {
        "time": 1689756611000,
        "value": 139.646,
        "_internal_originalTime": 1689756611000
    },
    {
        "time": 1689756613000,
        "value": 139.65,
        "_internal_originalTime": 1689756613000
    },
    {
        "time": 1689756615000,
        "value": 139.653,
        "_internal_originalTime": 1689756615000
    },
    {
        "time": 1689756616000,
        "value": 139.656,
        "_internal_originalTime": 1689756616000
    },
    {
        "time": 1689756617000,
        "value": 139.655,
        "_internal_originalTime": 1689756617000
    },
    {
        "time": 1689756618000,
        "value": 139.655,
        "_internal_originalTime": 1689756618000
    },
    {
        "time": 1689756619000,
        "value": 139.656,
        "_internal_originalTime": 1689756619000
    },
    {
        "time": 1689756620000,
        "value": 139.656,
        "_internal_originalTime": 1689756620000
    },
    {
        "time": 1689756621000,
        "value": 139.652,
        "_internal_originalTime": 1689756621000
    },
    {
        "time": 1689756622000,
        "value": 139.653,
        "_internal_originalTime": 1689756622000
    },
    {
        "time": 1689756624000,
        "value": 139.652,
        "_internal_originalTime": 1689756624000
    },
    {
        "time": 1689756625000,
        "value": 139.654,
        "_internal_originalTime": 1689756625000
    },
    {
        "time": 1689756626000,
        "value": 139.654,
        "_internal_originalTime": 1689756626000
    },
    {
        "time": 1689756627000,
        "value": 139.655,
        "_internal_originalTime": 1689756627000
    },
    {
        "time": 1689756628000,
        "value": 139.656,
        "_internal_originalTime": 1689756628000
    },
    {
        "time": 1689756630000,
        "value": 139.662,
        "_internal_originalTime": 1689756630000
    },
    {
        "time": 1689756631000,
        "value": 139.667,
        "_internal_originalTime": 1689756631000
    },
    {
        "time": 1689756632000,
        "value": 139.666,
        "_internal_originalTime": 1689756632000
    },
    {
        "time": 1689756633000,
        "value": 139.669,
        "_internal_originalTime": 1689756633000
    },
    {
        "time": 1689756635000,
        "value": 139.664,
        "_internal_originalTime": 1689756635000
    },
    {
        "time": 1689756636000,
        "value": 139.668,
        "_internal_originalTime": 1689756636000
    },
    {
        "time": 1689756637000,
        "value": 139.67,
        "_internal_originalTime": 1689756637000
    },
    {
        "time": 1689756638000,
        "value": 139.67,
        "_internal_originalTime": 1689756638000
    },
    {
        "time": 1689756640000,
        "value": 139.673,
        "_internal_originalTime": 1689756640000
    },
    {
        "time": 1689756641000,
        "value": 139.668,
        "_internal_originalTime": 1689756641000
    },
    {
        "time": 1689756642000,
        "value": 139.668,
        "_internal_originalTime": 1689756642000
    },
    {
        "time": 1689756643000,
        "value": 139.668,
        "_internal_originalTime": 1689756643000
    },
    {
        "time": 1689756644000,
        "value": 139.664,
        "_internal_originalTime": 1689756644000
    },
    {
        "time": 1689756646000,
        "value": 139.661,
        "_internal_originalTime": 1689756646000
    },
    {
        "time": 1689756647000,
        "value": 139.654,
        "_internal_originalTime": 1689756647000
    },
    {
        "time": 1689756648000,
        "value": 139.656,
        "_internal_originalTime": 1689756648000
    },
    {
        "time": 1689756649000,
        "value": 139.658,
        "_internal_originalTime": 1689756649000
    },
    {
        "time": 1689756650000,
        "value": 139.657,
        "_internal_originalTime": 1689756650000
    },
    {
        "time": 1689756651000,
        "value": 139.646,
        "_internal_originalTime": 1689756651000
    },
    {
        "time": 1689756653000,
        "value": 139.645,
        "_internal_originalTime": 1689756653000
    },
    {
        "time": 1689756654000,
        "value": 139.648,
        "_internal_originalTime": 1689756654000
    },
    {
        "time": 1689756655000,
        "value": 139.655,
        "_internal_originalTime": 1689756655000
    },
    {
        "time": 1689756656000,
        "value": 139.655,
        "_internal_originalTime": 1689756656000
    },
    {
        "time": 1689756657000,
        "value": 139.655,
        "_internal_originalTime": 1689756657000
    },
    {
        "time": 1689756659000,
        "value": 139.654,
        "_internal_originalTime": 1689756659000
    },
    {
        "time": 1689756660000,
        "value": 139.654,
        "_internal_originalTime": 1689756660000
    },
    {
        "time": 1689756661000,
        "value": 139.654,
        "_internal_originalTime": 1689756661000
    },
    {
        "time": 1689756662000,
        "value": 139.654,
        "_internal_originalTime": 1689756662000
    },
    {
        "time": 1689756663000,
        "value": 139.649,
        "_internal_originalTime": 1689756663000
    },
    {
        "time": 1689756665000,
        "value": 139.654,
        "_internal_originalTime": 1689756665000
    },
    {
        "time": 1689756667000,
        "value": 139.653,
        "_internal_originalTime": 1689756667000
    },
    {
        "time": 1689756668000,
        "value": 139.651,
        "_internal_originalTime": 1689756668000
    },
    {
        "time": 1689756669000,
        "value": 139.654,
        "_internal_originalTime": 1689756669000
    },
    {
        "time": 1689756670000,
        "value": 139.654,
        "_internal_originalTime": 1689756670000
    },
    {
        "time": 1689756672000,
        "value": 139.654,
        "_internal_originalTime": 1689756672000
    },
    {
        "time": 1689756674000,
        "value": 139.65,
        "_internal_originalTime": 1689756674000
    },
    {
        "time": 1689756675000,
        "value": 139.654,
        "_internal_originalTime": 1689756675000
    },
    {
        "time": 1689756676000,
        "value": 139.653,
        "_internal_originalTime": 1689756676000
    },
    {
        "time": 1689756677000,
        "value": 139.657,
        "_internal_originalTime": 1689756677000
    },
    {
        "time": 1689756678000,
        "value": 139.658,
        "_internal_originalTime": 1689756678000
    },
    {
        "time": 1689756679000,
        "value": 139.659,
        "_internal_originalTime": 1689756679000
    },
    {
        "time": 1689756681000,
        "value": 139.649,
        "_internal_originalTime": 1689756681000
    },
    {
        "time": 1689756683000,
        "value": 139.649,
        "_internal_originalTime": 1689756683000
    },
    {
        "time": 1689756684000,
        "value": 139.647,
        "_internal_originalTime": 1689756684000
    },
    {
        "time": 1689756685000,
        "value": 139.645,
        "_internal_originalTime": 1689756685000
    },
    {
        "time": 1689756686000,
        "value": 139.644,
        "_internal_originalTime": 1689756686000
    },
    {
        "time": 1689756688000,
        "value": 139.645,
        "_internal_originalTime": 1689756688000
    },
    {
        "time": 1689756691000,
        "value": 139.646,
        "_internal_originalTime": 1689756691000
    },
    {
        "time": 1689756692000,
        "value": 139.654,
        "_internal_originalTime": 1689756692000
    },
    {
        "time": 1689756693000,
        "value": 139.659,
        "_internal_originalTime": 1689756693000
    },
    {
        "time": 1689756694000,
        "value": 139.658,
        "_internal_originalTime": 1689756694000
    },
    {
        "time": 1689756695000,
        "value": 139.663,
        "_internal_originalTime": 1689756695000
    },
    {
        "time": 1689756697000,
        "value": 139.665,
        "_internal_originalTime": 1689756697000
    },
    {
        "time": 1689756698000,
        "value": 139.662,
        "_internal_originalTime": 1689756698000
    },
    {
        "time": 1689756699000,
        "value": 139.662,
        "_internal_originalTime": 1689756699000
    },
    {
        "time": 1689756700000,
        "value": 139.671,
        "_internal_originalTime": 1689756700000
    },
    {
        "time": 1689756702000,
        "value": 139.666,
        "_internal_originalTime": 1689756702000
    },
    {
        "time": 1689756703000,
        "value": 139.664,
        "_internal_originalTime": 1689756703000
    },
    {
        "time": 1689756704000,
        "value": 139.67,
        "_internal_originalTime": 1689756704000
    },
    {
        "time": 1689756705000,
        "value": 139.67,
        "_internal_originalTime": 1689756705000
    },
    {
        "time": 1689756707000,
        "value": 139.671,
        "_internal_originalTime": 1689756707000
    },
    {
        "time": 1689756708000,
        "value": 139.671,
        "_internal_originalTime": 1689756708000
    },
    {
        "time": 1689756709000,
        "value": 139.668,
        "_internal_originalTime": 1689756709000
    },
    {
        "time": 1689756710000,
        "value": 139.67,
        "_internal_originalTime": 1689756710000
    },
    {
        "time": 1689756711000,
        "value": 139.673,
        "_internal_originalTime": 1689756711000
    },
    {
        "time": 1689756712000,
        "value": 139.674,
        "_internal_originalTime": 1689756712000
    },
    {
        "time": 1689756713000,
        "value": 139.685,
        "_internal_originalTime": 1689756713000
    },
    {
        "time": 1689756715000,
        "value": 139.694,
        "_internal_originalTime": 1689756715000
    },
    {
        "time": 1689756716000,
        "value": 139.696,
        "_internal_originalTime": 1689756716000
    },
    {
        "time": 1689756717000,
        "value": 139.7,
        "_internal_originalTime": 1689756717000
    },
    {
        "time": 1689756718000,
        "value": 139.699,
        "_internal_originalTime": 1689756718000
    },
    {
        "time": 1689756719000,
        "value": 139.702,
        "_internal_originalTime": 1689756719000
    },
    {
        "time": 1689756720000,
        "value": 139.698,
        "_internal_originalTime": 1689756720000
    },
    {
        "time": 1689756721000,
        "value": 139.67,
        "_internal_originalTime": 1689756721000
    },
    {
        "time": 1689756722000,
        "value": 139.699,
        "_internal_originalTime": 1689756722000
    },
    {
        "time": 1689756723000,
        "value": 139.698,
        "_internal_originalTime": 1689756723000
    },
    {
        "time": 1689756724000,
        "value": 139.7,
        "_internal_originalTime": 1689756724000
    },
    {
        "time": 1689756725000,
        "value": 139.7,
        "_internal_originalTime": 1689756725000
    },
    {
        "time": 1689756726000,
        "value": 139.698,
        "_internal_originalTime": 1689756726000
    },
    {
        "time": 1689756728000,
        "value": 139.696,
        "_internal_originalTime": 1689756728000
    },
    {
        "time": 1689756729000,
        "value": 139.7,
        "_internal_originalTime": 1689756729000
    },
    {
        "time": 1689756730000,
        "value": 139.696,
        "_internal_originalTime": 1689756730000
    },
    {
        "time": 1689756731000,
        "value": 139.697,
        "_internal_originalTime": 1689756731000
    },
    {
        "time": 1689756733000,
        "value": 139.7,
        "_internal_originalTime": 1689756733000
    },
    {
        "time": 1689756735000,
        "value": 139.698,
        "_internal_originalTime": 1689756735000
    },
    {
        "time": 1689756736000,
        "value": 139.7,
        "_internal_originalTime": 1689756736000
    },
    {
        "time": 1689756737000,
        "value": 139.699,
        "_internal_originalTime": 1689756737000
    },
    {
        "time": 1689756738000,
        "value": 139.7,
        "_internal_originalTime": 1689756738000
    },
    {
        "time": 1689756739000,
        "value": 139.703,
        "_internal_originalTime": 1689756739000
    },
    {
        "time": 1689756740000,
        "value": 139.701,
        "_internal_originalTime": 1689756740000
    },
    {
        "time": 1689756741000,
        "value": 139.694,
        "_internal_originalTime": 1689756741000
    },
    {
        "time": 1689756743000,
        "value": 139.693,
        "_internal_originalTime": 1689756743000
    },
    {
        "time": 1689756744000,
        "value": 139.695,
        "_internal_originalTime": 1689756744000
    },
    {
        "time": 1689756747000,
        "value": 139.693,
        "_internal_originalTime": 1689756747000
    },
    {
        "time": 1689756748000,
        "value": 139.698,
        "_internal_originalTime": 1689756748000
    },
    {
        "time": 1689756749000,
        "value": 139.693,
        "_internal_originalTime": 1689756749000
    },
    {
        "time": 1689756750000,
        "value": 139.684,
        "_internal_originalTime": 1689756750000
    },
    {
        "time": 1689756751000,
        "value": 139.681,
        "_internal_originalTime": 1689756751000
    },
    {
        "time": 1689756753000,
        "value": 139.683,
        "_internal_originalTime": 1689756753000
    },
    {
        "time": 1689756754000,
        "value": 139.684,
        "_internal_originalTime": 1689756754000
    },
    {
        "time": 1689756756000,
        "value": 139.681,
        "_internal_originalTime": 1689756756000
    },
    {
        "time": 1689756757000,
        "value": 139.691,
        "_internal_originalTime": 1689756757000
    },
    {
        "time": 1689756758000,
        "value": 139.695,
        "_internal_originalTime": 1689756758000
    },
    {
        "time": 1689756759000,
        "value": 139.694,
        "_internal_originalTime": 1689756759000
    },
    {
        "time": 1689756760000,
        "value": 139.69,
        "_internal_originalTime": 1689756760000
    },
    {
        "time": 1689756761000,
        "value": 139.69,
        "_internal_originalTime": 1689756761000
    },
    {
        "time": 1689756763000,
        "value": 139.693,
        "_internal_originalTime": 1689756763000
    },
    {
        "time": 1689756764000,
        "value": 139.687,
        "_internal_originalTime": 1689756764000
    },
    {
        "time": 1689756765000,
        "value": 139.687,
        "_internal_originalTime": 1689756765000
    },
    {
        "time": 1689756766000,
        "value": 139.683,
        "_internal_originalTime": 1689756766000
    },
    {
        "time": 1689756768000,
        "value": 139.683,
        "_internal_originalTime": 1689756768000
    },
    {
        "time": 1689756770000,
        "value": 139.681,
        "_internal_originalTime": 1689756770000
    },
    {
        "time": 1689756771000,
        "value": 139.687,
        "_internal_originalTime": 1689756771000
    },
    {
        "time": 1689756773000,
        "value": 139.688,
        "_internal_originalTime": 1689756773000
    },
    {
        "time": 1689756774000,
        "value": 139.687,
        "_internal_originalTime": 1689756774000
    },
    {
        "time": 1689756776000,
        "value": 139.683,
        "_internal_originalTime": 1689756776000
    },
    {
        "time": 1689756777000,
        "value": 139.682,
        "_internal_originalTime": 1689756777000
    },
    {
        "time": 1689756778000,
        "value": 139.687,
        "_internal_originalTime": 1689756778000
    },
    {
        "time": 1689756779000,
        "value": 139.687,
        "_internal_originalTime": 1689756779000
    },
    {
        "time": 1689756780000,
        "value": 139.682,
        "_internal_originalTime": 1689756780000
    },
    {
        "time": 1689756781000,
        "value": 139.682,
        "_internal_originalTime": 1689756781000
    },
    {
        "time": 1689756782000,
        "value": 139.681,
        "_internal_originalTime": 1689756782000
    },
    {
        "time": 1689756783000,
        "value": 139.68,
        "_internal_originalTime": 1689756783000
    },
    {
        "time": 1689756784000,
        "value": 139.678,
        "_internal_originalTime": 1689756784000
    },
    {
        "time": 1689756785000,
        "value": 139.674,
        "_internal_originalTime": 1689756785000
    },
    {
        "time": 1689756786000,
        "value": 139.672,
        "_internal_originalTime": 1689756786000
    },
    {
        "time": 1689756788000,
        "value": 139.674,
        "_internal_originalTime": 1689756788000
    },
    {
        "time": 1689756789000,
        "value": 139.677,
        "_internal_originalTime": 1689756789000
    },
    {
        "time": 1689756790000,
        "value": 139.677,
        "_internal_originalTime": 1689756790000
    },
    {
        "time": 1689756791000,
        "value": 139.677,
        "_internal_originalTime": 1689756791000
    },
    {
        "time": 1689756792000,
        "value": 139.673,
        "_internal_originalTime": 1689756792000
    },
    {
        "time": 1689756794000,
        "value": 139.671,
        "_internal_originalTime": 1689756794000
    },
    {
        "time": 1689756795000,
        "value": 139.674,
        "_internal_originalTime": 1689756795000
    },
    {
        "time": 1689756797000,
        "value": 139.672,
        "_internal_originalTime": 1689756797000
    },
    {
        "time": 1689756799000,
        "value": 139.671,
        "_internal_originalTime": 1689756799000
    },
    {
        "time": 1689756800000,
        "value": 139.684,
        "_internal_originalTime": 1689756800000
    },
    {
        "time": 1689756801000,
        "value": 139.686,
        "_internal_originalTime": 1689756801000
    },
    {
        "time": 1689756802000,
        "value": 139.685,
        "_internal_originalTime": 1689756802000
    },
    {
        "time": 1689756804000,
        "value": 139.685,
        "_internal_originalTime": 1689756804000
    },
    {
        "time": 1689756805000,
        "value": 139.682,
        "_internal_originalTime": 1689756805000
    },
    {
        "time": 1689756806000,
        "value": 139.682,
        "_internal_originalTime": 1689756806000
    },
    {
        "time": 1689756807000,
        "value": 139.681,
        "_internal_originalTime": 1689756807000
    },
    {
        "time": 1689756808000,
        "value": 139.678,
        "_internal_originalTime": 1689756808000
    },
    {
        "time": 1689756810000,
        "value": 139.682,
        "_internal_originalTime": 1689756810000
    },
    {
        "time": 1689756811000,
        "value": 139.68,
        "_internal_originalTime": 1689756811000
    },
    {
        "time": 1689756812000,
        "value": 139.682,
        "_internal_originalTime": 1689756812000
    },
    {
        "time": 1689756814000,
        "value": 139.683,
        "_internal_originalTime": 1689756814000
    },
    {
        "time": 1689756815000,
        "value": 139.682,
        "_internal_originalTime": 1689756815000
    },
    {
        "time": 1689756816000,
        "value": 139.68,
        "_internal_originalTime": 1689756816000
    },
    {
        "time": 1689756817000,
        "value": 139.683,
        "_internal_originalTime": 1689756817000
    },
    {
        "time": 1689756818000,
        "value": 139.681,
        "_internal_originalTime": 1689756818000
    },
    {
        "time": 1689756820000,
        "value": 139.69,
        "_internal_originalTime": 1689756820000
    },
    {
        "time": 1689756821000,
        "value": 139.692,
        "_internal_originalTime": 1689756821000
    },
    {
        "time": 1689756822000,
        "value": 139.69,
        "_internal_originalTime": 1689756822000
    },
    {
        "time": 1689756823000,
        "value": 139.693,
        "_internal_originalTime": 1689756823000
    },
    {
        "time": 1689756825000,
        "value": 139.694,
        "_internal_originalTime": 1689756825000
    },
    {
        "time": 1689756827000,
        "value": 139.692,
        "_internal_originalTime": 1689756827000
    },
    {
        "time": 1689756828000,
        "value": 139.681,
        "_internal_originalTime": 1689756828000
    },
    {
        "time": 1689756830000,
        "value": 139.685,
        "_internal_originalTime": 1689756830000
    },
    {
        "time": 1689756831000,
        "value": 139.684,
        "_internal_originalTime": 1689756831000
    },
    {
        "time": 1689756832000,
        "value": 139.682,
        "_internal_originalTime": 1689756832000
    },
    {
        "time": 1689756833000,
        "value": 139.676,
        "_internal_originalTime": 1689756833000
    },
    {
        "time": 1689756834000,
        "value": 139.683,
        "_internal_originalTime": 1689756834000
    },
    {
        "time": 1689756835000,
        "value": 139.682,
        "_internal_originalTime": 1689756835000
    },
    {
        "time": 1689756837000,
        "value": 139.683,
        "_internal_originalTime": 1689756837000
    },
    {
        "time": 1689756838000,
        "value": 139.682,
        "_internal_originalTime": 1689756838000
    },
    {
        "time": 1689756839000,
        "value": 139.681,
        "_internal_originalTime": 1689756839000
    },
    {
        "time": 1689756840000,
        "value": 139.678,
        "_internal_originalTime": 1689756840000
    },
    {
        "time": 1689756842000,
        "value": 139.672,
        "_internal_originalTime": 1689756842000
    },
    {
        "time": 1689756843000,
        "value": 139.67,
        "_internal_originalTime": 1689756843000
    },
    {
        "time": 1689756844000,
        "value": 139.676,
        "_internal_originalTime": 1689756844000
    },
    {
        "time": 1689756847000,
        "value": 139.674,
        "_internal_originalTime": 1689756847000
    },
    {
        "time": 1689756848000,
        "value": 139.675,
        "_internal_originalTime": 1689756848000
    },
    {
        "time": 1689756850000,
        "value": 139.671,
        "_internal_originalTime": 1689756850000
    },
    {
        "time": 1689756851000,
        "value": 139.667,
        "_internal_originalTime": 1689756851000
    },
    {
        "time": 1689756852000,
        "value": 139.663,
        "_internal_originalTime": 1689756852000
    },
    {
        "time": 1689756854000,
        "value": 139.661,
        "_internal_originalTime": 1689756854000
    },
    {
        "time": 1689756855000,
        "value": 139.664,
        "_internal_originalTime": 1689756855000
    },
    {
        "time": 1689756856000,
        "value": 139.662,
        "_internal_originalTime": 1689756856000
    },
    {
        "time": 1689756858000,
        "value": 139.666,
        "_internal_originalTime": 1689756858000
    },
    {
        "time": 1689756859000,
        "value": 139.666,
        "_internal_originalTime": 1689756859000
    },
    {
        "time": 1689756860000,
        "value": 139.667,
        "_internal_originalTime": 1689756860000
    },
    {
        "time": 1689756862000,
        "value": 139.673,
        "_internal_originalTime": 1689756862000
    },
    {
        "time": 1689756863000,
        "value": 139.678,
        "_internal_originalTime": 1689756863000
    },
    {
        "time": 1689756864000,
        "value": 139.673,
        "_internal_originalTime": 1689756864000
    },
    {
        "time": 1689756865000,
        "value": 139.671,
        "_internal_originalTime": 1689756865000
    },
    {
        "time": 1689756866000,
        "value": 139.671,
        "_internal_originalTime": 1689756866000
    },
    {
        "time": 1689756867000,
        "value": 139.665,
        "_internal_originalTime": 1689756867000
    },
    {
        "time": 1689756868000,
        "value": 139.665,
        "_internal_originalTime": 1689756868000
    },
    {
        "time": 1689756869000,
        "value": 139.665,
        "_internal_originalTime": 1689756869000
    },
    {
        "time": 1689756870000,
        "value": 139.666,
        "_internal_originalTime": 1689756870000
    },
    {
        "time": 1689756871000,
        "value": 139.664,
        "_internal_originalTime": 1689756871000
    },
    {
        "time": 1689756872000,
        "value": 139.665,
        "_internal_originalTime": 1689756872000
    },
    {
        "time": 1689756873000,
        "value": 139.662,
        "_internal_originalTime": 1689756873000
    },
    {
        "time": 1689756874000,
        "value": 139.665,
        "_internal_originalTime": 1689756874000
    },
    {
        "time": 1689756875000,
        "value": 139.668,
        "_internal_originalTime": 1689756875000
    },
    {
        "time": 1689756876000,
        "value": 139.669,
        "_internal_originalTime": 1689756876000
    },
    {
        "time": 1689756877000,
        "value": 139.67,
        "_internal_originalTime": 1689756877000
    },
    {
        "time": 1689756878000,
        "value": 139.671,
        "_internal_originalTime": 1689756878000
    },
    {
        "time": 1689756880000,
        "value": 139.674,
        "_internal_originalTime": 1689756880000
    },
    {
        "time": 1689756881000,
        "value": 139.671,
        "_internal_originalTime": 1689756881000
    },
    {
        "time": 1689756882000,
        "value": 139.675,
        "_internal_originalTime": 1689756882000
    },
    {
        "time": 1689756883000,
        "value": 139.676,
        "_internal_originalTime": 1689756883000
    },
    {
        "time": 1689756885000,
        "value": 139.678,
        "_internal_originalTime": 1689756885000
    },
    {
        "time": 1689756886000,
        "value": 139.674,
        "_internal_originalTime": 1689756886000
    },
    {
        "time": 1689756887000,
        "value": 139.674,
        "_internal_originalTime": 1689756887000
    },
    {
        "time": 1689756888000,
        "value": 139.672,
        "_internal_originalTime": 1689756888000
    },
    {
        "time": 1689756889000,
        "value": 139.673,
        "_internal_originalTime": 1689756889000
    },
    {
        "time": 1689756891000,
        "value": 139.675,
        "_internal_originalTime": 1689756891000
    },
    {
        "time": 1689756892000,
        "value": 139.674,
        "_internal_originalTime": 1689756892000
    },
    {
        "time": 1689756894000,
        "value": 139.662,
        "_internal_originalTime": 1689756894000
    },
    {
        "time": 1689756895000,
        "value": 139.662,
        "_internal_originalTime": 1689756895000
    },
    {
        "time": 1689756896000,
        "value": 139.658,
        "_internal_originalTime": 1689756896000
    },
    {
        "time": 1689756897000,
        "value": 139.652,
        "_internal_originalTime": 1689756897000
    },
    {
        "time": 1689756898000,
        "value": 139.655,
        "_internal_originalTime": 1689756898000
    },
    {
        "time": 1689756899000,
        "value": 139.652,
        "_internal_originalTime": 1689756899000
    },
    {
        "time": 1689756900000,
        "value": 139.655,
        "_internal_originalTime": 1689756900000
    },
    {
        "time": 1689756901000,
        "value": 139.656,
        "_internal_originalTime": 1689756901000
    },
    {
        "time": 1689756902000,
        "value": 139.653,
        "_internal_originalTime": 1689756902000
    },
    {
        "time": 1689756903000,
        "value": 139.658,
        "_internal_originalTime": 1689756903000
    },
    {
        "time": 1689756904000,
        "value": 139.664,
        "_internal_originalTime": 1689756904000
    },
    {
        "time": 1689756905000,
        "value": 139.663,
        "_internal_originalTime": 1689756905000
    },
    {
        "time": 1689756906000,
        "value": 139.665,
        "_internal_originalTime": 1689756906000
    },
    {
        "time": 1689756907000,
        "value": 139.661,
        "_internal_originalTime": 1689756907000
    },
    {
        "time": 1689756909000,
        "value": 139.667,
        "_internal_originalTime": 1689756909000
    },
    {
        "time": 1689756910000,
        "value": 139.665,
        "_internal_originalTime": 1689756910000
    },
    {
        "time": 1689756911000,
        "value": 139.665,
        "_internal_originalTime": 1689756911000
    },
    {
        "time": 1689756913000,
        "value": 139.665,
        "_internal_originalTime": 1689756913000
    },
    {
        "time": 1689756915000,
        "value": 139.67,
        "_internal_originalTime": 1689756915000
    },
    {
        "time": 1689756916000,
        "value": 139.674,
        "_internal_originalTime": 1689756916000
    },
    {
        "time": 1689756917000,
        "value": 139.675,
        "_internal_originalTime": 1689756917000
    },
    {
        "time": 1689756918000,
        "value": 139.675,
        "_internal_originalTime": 1689756918000
    },
    {
        "time": 1689756920000,
        "value": 139.675,
        "_internal_originalTime": 1689756920000
    },
    {
        "time": 1689756921000,
        "value": 139.676,
        "_internal_originalTime": 1689756921000
    },
    {
        "time": 1689756923000,
        "value": 139.678,
        "_internal_originalTime": 1689756923000
    },
    {
        "time": 1689756924000,
        "value": 139.674,
        "_internal_originalTime": 1689756924000
    },
    {
        "time": 1689756925000,
        "value": 139.675,
        "_internal_originalTime": 1689756925000
    },
    {
        "time": 1689756927000,
        "value": 139.675,
        "_internal_originalTime": 1689756927000
    },
    {
        "time": 1689756929000,
        "value": 139.675,
        "_internal_originalTime": 1689756929000
    },
    {
        "time": 1689756931000,
        "value": 139.674,
        "_internal_originalTime": 1689756931000
    },
    {
        "time": 1689756932000,
        "value": 139.667,
        "_internal_originalTime": 1689756932000
    },
    {
        "time": 1689756933000,
        "value": 139.663,
        "_internal_originalTime": 1689756933000
    },
    {
        "time": 1689756935000,
        "value": 139.662,
        "_internal_originalTime": 1689756935000
    },
    {
        "time": 1689756936000,
        "value": 139.663,
        "_internal_originalTime": 1689756936000
    },
    {
        "time": 1689756937000,
        "value": 139.663,
        "_internal_originalTime": 1689756937000
    },
    {
        "time": 1689756938000,
        "value": 139.663,
        "_internal_originalTime": 1689756938000
    },
    {
        "time": 1689756940000,
        "value": 139.663,
        "_internal_originalTime": 1689756940000
    },
    {
        "time": 1689756942000,
        "value": 139.665,
        "_internal_originalTime": 1689756942000
    },
    {
        "time": 1689756943000,
        "value": 139.663,
        "_internal_originalTime": 1689756943000
    },
    {
        "time": 1689756944000,
        "value": 139.662,
        "_internal_originalTime": 1689756944000
    },
    {
        "time": 1689756945000,
        "value": 139.67,
        "_internal_originalTime": 1689756945000
    },
    {
        "time": 1689756946000,
        "value": 139.671,
        "_internal_originalTime": 1689756946000
    },
    {
        "time": 1689756947000,
        "value": 139.677,
        "_internal_originalTime": 1689756947000
    },
    {
        "time": 1689756948000,
        "value": 139.673,
        "_internal_originalTime": 1689756948000
    },
    {
        "time": 1689756950000,
        "value": 139.672,
        "_internal_originalTime": 1689756950000
    },
    {
        "time": 1689756951000,
        "value": 139.673,
        "_internal_originalTime": 1689756951000
    },
    {
        "time": 1689756953000,
        "value": 139.675,
        "_internal_originalTime": 1689756953000
    },
    {
        "time": 1689756954000,
        "value": 139.674,
        "_internal_originalTime": 1689756954000
    },
    {
        "time": 1689756956000,
        "value": 139.675,
        "_internal_originalTime": 1689756956000
    },
    {
        "time": 1689756957000,
        "value": 139.673,
        "_internal_originalTime": 1689756957000
    },
    {
        "time": 1689756959000,
        "value": 139.676,
        "_internal_originalTime": 1689756959000
    },
    {
        "time": 1689756961000,
        "value": 139.672,
        "_internal_originalTime": 1689756961000
    },
    {
        "time": 1689756962000,
        "value": 139.674,
        "_internal_originalTime": 1689756962000
    },
    {
        "time": 1689756963000,
        "value": 139.676,
        "_internal_originalTime": 1689756963000
    },
    {
        "time": 1689756964000,
        "value": 139.672,
        "_internal_originalTime": 1689756964000
    },
    {
        "time": 1689756965000,
        "value": 139.675,
        "_internal_originalTime": 1689756965000
    },
    {
        "time": 1689756966000,
        "value": 139.67,
        "_internal_originalTime": 1689756966000
    },
    {
        "time": 1689756968000,
        "value": 139.675,
        "_internal_originalTime": 1689756968000
    },
    {
        "time": 1689756969000,
        "value": 139.683,
        "_internal_originalTime": 1689756969000
    },
    {
        "time": 1689756970000,
        "value": 139.684,
        "_internal_originalTime": 1689756970000
    },
    {
        "time": 1689756971000,
        "value": 139.69,
        "_internal_originalTime": 1689756971000
    },
    {
        "time": 1689756972000,
        "value": 139.691,
        "_internal_originalTime": 1689756972000
    },
    {
        "time": 1689756973000,
        "value": 139.692,
        "_internal_originalTime": 1689756973000
    },
    {
        "time": 1689756975000,
        "value": 139.691,
        "_internal_originalTime": 1689756975000
    },
    {
        "time": 1689756976000,
        "value": 139.693,
        "_internal_originalTime": 1689756976000
    },
    {
        "time": 1689756978000,
        "value": 139.692,
        "_internal_originalTime": 1689756978000
    },
    {
        "time": 1689756980000,
        "value": 139.695,
        "_internal_originalTime": 1689756980000
    },
    {
        "time": 1689756981000,
        "value": 139.692,
        "_internal_originalTime": 1689756981000
    },
    {
        "time": 1689756982000,
        "value": 139.697,
        "_internal_originalTime": 1689756982000
    },
    {
        "time": 1689756985000,
        "value": 139.696,
        "_internal_originalTime": 1689756985000
    },
    {
        "time": 1689756986000,
        "value": 139.694,
        "_internal_originalTime": 1689756986000
    },
    {
        "time": 1689756987000,
        "value": 139.69,
        "_internal_originalTime": 1689756987000
    },
    {
        "time": 1689756988000,
        "value": 139.694,
        "_internal_originalTime": 1689756988000
    },
    {
        "time": 1689756989000,
        "value": 139.69,
        "_internal_originalTime": 1689756989000
    },
    {
        "time": 1689756990000,
        "value": 139.692,
        "_internal_originalTime": 1689756990000
    },
    {
        "time": 1689756991000,
        "value": 139.698,
        "_internal_originalTime": 1689756991000
    },
    {
        "time": 1689756993000,
        "value": 139.694,
        "_internal_originalTime": 1689756993000
    },
    {
        "time": 1689756994000,
        "value": 139.693,
        "_internal_originalTime": 1689756994000
    },
    {
        "time": 1689756996000,
        "value": 139.696,
        "_internal_originalTime": 1689756996000
    },
    {
        "time": 1689756997000,
        "value": 139.69,
        "_internal_originalTime": 1689756997000
    },
    {
        "time": 1689756998000,
        "value": 139.687,
        "_internal_originalTime": 1689756998000
    },
    {
        "time": 1689757000000,
        "value": 139.679,
        "_internal_originalTime": 1689757000000
    },
    {
        "time": 1689757002000,
        "value": 139.672,
        "_internal_originalTime": 1689757002000
    },
    {
        "time": 1689757003000,
        "value": 139.677,
        "_internal_originalTime": 1689757003000
    },
    {
        "time": 1689757005000,
        "value": 139.678,
        "_internal_originalTime": 1689757005000
    },
    {
        "time": 1689757006000,
        "value": 139.673,
        "_internal_originalTime": 1689757006000
    },
    {
        "time": 1689757007000,
        "value": 139.676,
        "_internal_originalTime": 1689757007000
    },
    {
        "time": 1689757009000,
        "value": 139.678,
        "_internal_originalTime": 1689757009000
    },
    {
        "time": 1689757010000,
        "value": 139.684,
        "_internal_originalTime": 1689757010000
    },
    {
        "time": 1689757011000,
        "value": 139.685,
        "_internal_originalTime": 1689757011000
    },
    {
        "time": 1689757012000,
        "value": 139.685,
        "_internal_originalTime": 1689757012000
    },
    {
        "time": 1689757013000,
        "value": 139.684,
        "_internal_originalTime": 1689757013000
    },
    {
        "time": 1689757014000,
        "value": 139.686,
        "_internal_originalTime": 1689757014000
    },
    {
        "time": 1689757015000,
        "value": 139.687,
        "_internal_originalTime": 1689757015000
    },
    {
        "time": 1689757017000,
        "value": 139.686,
        "_internal_originalTime": 1689757017000
    },
    {
        "time": 1689757018000,
        "value": 139.687,
        "_internal_originalTime": 1689757018000
    },
    {
        "time": 1689757020000,
        "value": 139.687,
        "_internal_originalTime": 1689757020000
    },
    {
        "time": 1689757024000,
        "value": 139.691,
        "_internal_originalTime": 1689757024000
    },
    {
        "time": 1689757025000,
        "value": 139.692,
        "_internal_originalTime": 1689757025000
    },
    {
        "time": 1689757027000,
        "value": 139.7,
        "_internal_originalTime": 1689757027000
    },
    {
        "time": 1689757028000,
        "value": 139.701,
        "_internal_originalTime": 1689757028000
    },
    {
        "time": 1689757029000,
        "value": 139.702,
        "_internal_originalTime": 1689757029000
    },
    {
        "time": 1689757030000,
        "value": 139.698,
        "_internal_originalTime": 1689757030000
    },
    {
        "time": 1689757032000,
        "value": 139.702,
        "_internal_originalTime": 1689757032000
    },
    {
        "time": 1689757033000,
        "value": 139.7,
        "_internal_originalTime": 1689757033000
    },
    {
        "time": 1689757035000,
        "value": 139.698,
        "_internal_originalTime": 1689757035000
    },
    {
        "time": 1689757036000,
        "value": 139.698,
        "_internal_originalTime": 1689757036000
    },
    {
        "time": 1689757037000,
        "value": 139.701,
        "_internal_originalTime": 1689757037000
    },
    {
        "time": 1689757038000,
        "value": 139.703,
        "_internal_originalTime": 1689757038000
    },
    {
        "time": 1689757039000,
        "value": 139.702,
        "_internal_originalTime": 1689757039000
    },
    {
        "time": 1689757040000,
        "value": 139.706,
        "_internal_originalTime": 1689757040000
    },
    {
        "time": 1689757041000,
        "value": 139.703,
        "_internal_originalTime": 1689757041000
    },
    {
        "time": 1689757042000,
        "value": 139.703,
        "_internal_originalTime": 1689757042000
    },
    {
        "time": 1689757044000,
        "value": 139.7,
        "_internal_originalTime": 1689757044000
    },
    {
        "time": 1689757045000,
        "value": 139.703,
        "_internal_originalTime": 1689757045000
    },
    {
        "time": 1689757047000,
        "value": 139.705,
        "_internal_originalTime": 1689757047000
    },
    {
        "time": 1689757050000,
        "value": 139.705,
        "_internal_originalTime": 1689757050000
    },
    {
        "time": 1689757051000,
        "value": 139.703,
        "_internal_originalTime": 1689757051000
    },
    {
        "time": 1689757053000,
        "value": 139.714,
        "_internal_originalTime": 1689757053000
    },
    {
        "time": 1689757054000,
        "value": 139.715,
        "_internal_originalTime": 1689757054000
    },
    {
        "time": 1689757055000,
        "value": 139.721,
        "_internal_originalTime": 1689757055000
    },
    {
        "time": 1689757056000,
        "value": 139.721,
        "_internal_originalTime": 1689757056000
    },
    {
        "time": 1689757058000,
        "value": 139.721,
        "_internal_originalTime": 1689757058000
    },
    {
        "time": 1689757059000,
        "value": 139.737,
        "_internal_originalTime": 1689757059000
    },
    {
        "time": 1689757061000,
        "value": 139.732,
        "_internal_originalTime": 1689757061000
    },
    {
        "time": 1689757062000,
        "value": 139.723,
        "_internal_originalTime": 1689757062000
    },
    {
        "time": 1689757065000,
        "value": 139.723,
        "_internal_originalTime": 1689757065000
    },
    {
        "time": 1689757066000,
        "value": 139.723,
        "_internal_originalTime": 1689757066000
    },
    {
        "time": 1689757068000,
        "value": 139.723,
        "_internal_originalTime": 1689757068000
    },
    {
        "time": 1689757069000,
        "value": 139.724,
        "_internal_originalTime": 1689757069000
    },
    {
        "time": 1689757071000,
        "value": 139.724,
        "_internal_originalTime": 1689757071000
    },
    {
        "time": 1689757072000,
        "value": 139.716,
        "_internal_originalTime": 1689757072000
    },
    {
        "time": 1689757073000,
        "value": 139.716,
        "_internal_originalTime": 1689757073000
    },
    {
        "time": 1689757074000,
        "value": 139.722,
        "_internal_originalTime": 1689757074000
    },
    {
        "time": 1689757076000,
        "value": 139.71,
        "_internal_originalTime": 1689757076000
    },
    {
        "time": 1689757078000,
        "value": 139.711,
        "_internal_originalTime": 1689757078000
    },
    {
        "time": 1689757080000,
        "value": 139.709,
        "_internal_originalTime": 1689757080000
    },
    {
        "time": 1689757081000,
        "value": 139.707,
        "_internal_originalTime": 1689757081000
    },
    {
        "time": 1689757082000,
        "value": 139.707,
        "_internal_originalTime": 1689757082000
    },
    {
        "time": 1689757083000,
        "value": 139.71,
        "_internal_originalTime": 1689757083000
    },
    {
        "time": 1689757084000,
        "value": 139.712,
        "_internal_originalTime": 1689757084000
    },
    {
        "time": 1689757085000,
        "value": 139.712,
        "_internal_originalTime": 1689757085000
    },
    {
        "time": 1689757086000,
        "value": 139.708,
        "_internal_originalTime": 1689757086000
    },
    {
        "time": 1689757088000,
        "value": 139.708,
        "_internal_originalTime": 1689757088000
    },
    {
        "time": 1689757089000,
        "value": 139.711,
        "_internal_originalTime": 1689757089000
    },
    {
        "time": 1689757090000,
        "value": 139.71,
        "_internal_originalTime": 1689757090000
    },
    {
        "time": 1689757092000,
        "value": 139.709,
        "_internal_originalTime": 1689757092000
    },
    {
        "time": 1689757093000,
        "value": 139.707,
        "_internal_originalTime": 1689757093000
    },
    {
        "time": 1689757095000,
        "value": 139.708,
        "_internal_originalTime": 1689757095000
    },
    {
        "time": 1689757096000,
        "value": 139.721,
        "_internal_originalTime": 1689757096000
    },
    {
        "time": 1689757097000,
        "value": 139.726,
        "_internal_originalTime": 1689757097000
    },
    {
        "time": 1689757098000,
        "value": 139.733,
        "_internal_originalTime": 1689757098000
    },
    {
        "time": 1689757099000,
        "value": 139.733,
        "_internal_originalTime": 1689757099000
    },
    {
        "time": 1689757100000,
        "value": 139.73,
        "_internal_originalTime": 1689757100000
    },
    {
        "time": 1689757101000,
        "value": 139.73,
        "_internal_originalTime": 1689757101000
    },
    {
        "time": 1689757103000,
        "value": 139.731,
        "_internal_originalTime": 1689757103000
    },
    {
        "time": 1689757104000,
        "value": 139.73,
        "_internal_originalTime": 1689757104000
    },
    {
        "time": 1689757105000,
        "value": 139.745,
        "_internal_originalTime": 1689757105000
    },
    {
        "time": 1689757106000,
        "value": 139.745,
        "_internal_originalTime": 1689757106000
    },
    {
        "time": 1689757108000,
        "value": 139.744,
        "_internal_originalTime": 1689757108000
    },
    {
        "time": 1689757109000,
        "value": 139.746,
        "_internal_originalTime": 1689757109000
    },
    {
        "time": 1689757110000,
        "value": 139.744,
        "_internal_originalTime": 1689757110000
    },
    {
        "time": 1689757111000,
        "value": 139.745,
        "_internal_originalTime": 1689757111000
    },
    {
        "time": 1689757112000,
        "value": 139.743,
        "_internal_originalTime": 1689757112000
    },
    {
        "time": 1689757113000,
        "value": 139.74,
        "_internal_originalTime": 1689757113000
    },
    {
        "time": 1689757115000,
        "value": 139.745,
        "_internal_originalTime": 1689757115000
    },
    {
        "time": 1689757116000,
        "value": 139.745,
        "_internal_originalTime": 1689757116000
    },
    {
        "time": 1689757117000,
        "value": 139.745,
        "_internal_originalTime": 1689757117000
    },
    {
        "time": 1689757119000,
        "value": 139.742,
        "_internal_originalTime": 1689757119000
    },
    {
        "time": 1689757120000,
        "value": 139.741,
        "_internal_originalTime": 1689757120000
    },
    {
        "time": 1689757122000,
        "value": 139.743,
        "_internal_originalTime": 1689757122000
    },
    {
        "time": 1689757123000,
        "value": 139.742,
        "_internal_originalTime": 1689757123000
    },
    {
        "time": 1689757125000,
        "value": 139.744,
        "_internal_originalTime": 1689757125000
    },
    {
        "time": 1689757126000,
        "value": 139.738,
        "_internal_originalTime": 1689757126000
    },
    {
        "time": 1689757127000,
        "value": 139.74,
        "_internal_originalTime": 1689757127000
    },
    {
        "time": 1689757128000,
        "value": 139.741,
        "_internal_originalTime": 1689757128000
    },
    {
        "time": 1689757129000,
        "value": 139.742,
        "_internal_originalTime": 1689757129000
    },
    {
        "time": 1689757130000,
        "value": 139.74,
        "_internal_originalTime": 1689757130000
    },
    {
        "time": 1689757132000,
        "value": 139.74,
        "_internal_originalTime": 1689757132000
    },
    {
        "time": 1689757133000,
        "value": 139.745,
        "_internal_originalTime": 1689757133000
    },
    {
        "time": 1689757134000,
        "value": 139.745,
        "_internal_originalTime": 1689757134000
    },
    {
        "time": 1689757135000,
        "value": 139.741,
        "_internal_originalTime": 1689757135000
    },
    {
        "time": 1689757136000,
        "value": 139.743,
        "_internal_originalTime": 1689757136000
    },
    {
        "time": 1689757138000,
        "value": 139.745,
        "_internal_originalTime": 1689757138000
    },
    {
        "time": 1689757140000,
        "value": 139.74,
        "_internal_originalTime": 1689757140000
    },
    {
        "time": 1689757142000,
        "value": 139.743,
        "_internal_originalTime": 1689757142000
    },
    {
        "time": 1689757143000,
        "value": 139.738,
        "_internal_originalTime": 1689757143000
    },
    {
        "time": 1689757144000,
        "value": 139.744,
        "_internal_originalTime": 1689757144000
    },
    {
        "time": 1689757147000,
        "value": 139.738,
        "_internal_originalTime": 1689757147000
    },
    {
        "time": 1689757148000,
        "value": 139.743,
        "_internal_originalTime": 1689757148000
    },
    {
        "time": 1689757151000,
        "value": 139.741,
        "_internal_originalTime": 1689757151000
    },
    {
        "time": 1689757154000,
        "value": 139.739,
        "_internal_originalTime": 1689757154000
    },
    {
        "time": 1689757155000,
        "value": 139.74,
        "_internal_originalTime": 1689757155000
    },
    {
        "time": 1689757156000,
        "value": 139.737,
        "_internal_originalTime": 1689757156000
    },
    {
        "time": 1689757157000,
        "value": 139.736,
        "_internal_originalTime": 1689757157000
    },
    {
        "time": 1689757158000,
        "value": 139.738,
        "_internal_originalTime": 1689757158000
    },
    {
        "time": 1689757160000,
        "value": 139.741,
        "_internal_originalTime": 1689757160000
    },
    {
        "time": 1689757161000,
        "value": 139.731,
        "_internal_originalTime": 1689757161000
    },
    {
        "time": 1689757162000,
        "value": 139.729,
        "_internal_originalTime": 1689757162000
    },
    {
        "time": 1689757163000,
        "value": 139.727,
        "_internal_originalTime": 1689757163000
    },
    {
        "time": 1689757164000,
        "value": 139.73,
        "_internal_originalTime": 1689757164000
    },
    {
        "time": 1689757165000,
        "value": 139.732,
        "_internal_originalTime": 1689757165000
    },
    {
        "time": 1689757167000,
        "value": 139.733,
        "_internal_originalTime": 1689757167000
    },
    {
        "time": 1689757168000,
        "value": 139.73,
        "_internal_originalTime": 1689757168000
    },
    {
        "time": 1689757170000,
        "value": 139.728,
        "_internal_originalTime": 1689757170000
    },
    {
        "time": 1689757171000,
        "value": 139.725,
        "_internal_originalTime": 1689757171000
    },
    {
        "time": 1689757172000,
        "value": 139.724,
        "_internal_originalTime": 1689757172000
    },
    {
        "time": 1689757174000,
        "value": 139.724,
        "_internal_originalTime": 1689757174000
    },
    {
        "time": 1689757175000,
        "value": 139.731,
        "_internal_originalTime": 1689757175000
    },
    {
        "time": 1689757176000,
        "value": 139.735,
        "_internal_originalTime": 1689757176000
    },
    {
        "time": 1689757178000,
        "value": 139.734,
        "_internal_originalTime": 1689757178000
    },
    {
        "time": 1689757179000,
        "value": 139.731,
        "_internal_originalTime": 1689757179000
    },
    {
        "time": 1689757180000,
        "value": 139.728,
        "_internal_originalTime": 1689757180000
    },
    {
        "time": 1689757181000,
        "value": 139.729,
        "_internal_originalTime": 1689757181000
    },
    {
        "time": 1689757182000,
        "value": 139.734,
        "_internal_originalTime": 1689757182000
    },
    {
        "time": 1689757184000,
        "value": 139.73,
        "_internal_originalTime": 1689757184000
    },
    {
        "time": 1689757185000,
        "value": 139.735,
        "_internal_originalTime": 1689757185000
    },
    {
        "time": 1689757187000,
        "value": 139.733,
        "_internal_originalTime": 1689757187000
    },
    {
        "time": 1689757188000,
        "value": 139.728,
        "_internal_originalTime": 1689757188000
    },
    {
        "time": 1689757189000,
        "value": 139.73,
        "_internal_originalTime": 1689757189000
    },
    {
        "time": 1689757190000,
        "value": 139.733,
        "_internal_originalTime": 1689757190000
    },
    {
        "time": 1689757191000,
        "value": 139.733,
        "_internal_originalTime": 1689757191000
    },
    {
        "time": 1689757192000,
        "value": 139.727,
        "_internal_originalTime": 1689757192000
    },
    {
        "time": 1689757194000,
        "value": 139.73,
        "_internal_originalTime": 1689757194000
    },
    {
        "time": 1689757195000,
        "value": 139.723,
        "_internal_originalTime": 1689757195000
    },
    {
        "time": 1689757196000,
        "value": 139.722,
        "_internal_originalTime": 1689757196000
    },
    {
        "time": 1689757197000,
        "value": 139.732,
        "_internal_originalTime": 1689757197000
    },
    {
        "time": 1689757199000,
        "value": 139.729,
        "_internal_originalTime": 1689757199000
    },
    {
        "time": 1689757200000,
        "value": 139.732,
        "_internal_originalTime": 1689757200000
    },
    {
        "time": 1689757201000,
        "value": 139.73,
        "_internal_originalTime": 1689757201000
    },
    {
        "time": 1689757202000,
        "value": 139.73,
        "_internal_originalTime": 1689757202000
    },
    {
        "time": 1689757203000,
        "value": 139.733,
        "_internal_originalTime": 1689757203000
    },
    {
        "time": 1689757204000,
        "value": 139.732,
        "_internal_originalTime": 1689757204000
    },
    {
        "time": 1689757205000,
        "value": 139.731,
        "_internal_originalTime": 1689757205000
    },
    {
        "time": 1689757206000,
        "value": 139.735,
        "_internal_originalTime": 1689757206000
    },
    {
        "time": 1689757208000,
        "value": 139.733,
        "_internal_originalTime": 1689757208000
    },
    {
        "time": 1689757210000,
        "value": 139.731,
        "_internal_originalTime": 1689757210000
    },
    {
        "time": 1689757211000,
        "value": 139.732,
        "_internal_originalTime": 1689757211000
    },
    {
        "time": 1689757212000,
        "value": 139.732,
        "_internal_originalTime": 1689757212000
    },
    {
        "time": 1689757213000,
        "value": 139.728,
        "_internal_originalTime": 1689757213000
    },
    {
        "time": 1689757215000,
        "value": 139.731,
        "_internal_originalTime": 1689757215000
    },
    {
        "time": 1689757216000,
        "value": 139.732,
        "_internal_originalTime": 1689757216000
    },
    {
        "time": 1689757217000,
        "value": 139.733,
        "_internal_originalTime": 1689757217000
    },
    {
        "time": 1689757218000,
        "value": 139.733,
        "_internal_originalTime": 1689757218000
    },
    {
        "time": 1689757220000,
        "value": 139.73,
        "_internal_originalTime": 1689757220000
    },
    {
        "time": 1689757222000,
        "value": 139.732,
        "_internal_originalTime": 1689757222000
    },
    {
        "time": 1689757223000,
        "value": 139.731,
        "_internal_originalTime": 1689757223000
    },
    {
        "time": 1689757224000,
        "value": 139.733,
        "_internal_originalTime": 1689757224000
    },
    {
        "time": 1689757226000,
        "value": 139.732,
        "_internal_originalTime": 1689757226000
    },
    {
        "time": 1689757227000,
        "value": 139.729,
        "_internal_originalTime": 1689757227000
    },
    {
        "time": 1689757228000,
        "value": 139.728,
        "_internal_originalTime": 1689757228000
    },
    {
        "time": 1689757230000,
        "value": 139.722,
        "_internal_originalTime": 1689757230000
    },
    {
        "time": 1689757231000,
        "value": 139.724,
        "_internal_originalTime": 1689757231000
    },
    {
        "time": 1689757232000,
        "value": 139.723,
        "_internal_originalTime": 1689757232000
    },
    {
        "time": 1689757233000,
        "value": 139.724,
        "_internal_originalTime": 1689757233000
    },
    {
        "time": 1689757234000,
        "value": 139.721,
        "_internal_originalTime": 1689757234000
    },
    {
        "time": 1689757236000,
        "value": 139.72,
        "_internal_originalTime": 1689757236000
    },
    {
        "time": 1689757237000,
        "value": 139.722,
        "_internal_originalTime": 1689757237000
    },
    {
        "time": 1689757238000,
        "value": 139.724,
        "_internal_originalTime": 1689757238000
    },
    {
        "time": 1689757241000,
        "value": 139.722,
        "_internal_originalTime": 1689757241000
    },
    {
        "time": 1689757242000,
        "value": 139.724,
        "_internal_originalTime": 1689757242000
    },
    {
        "time": 1689757243000,
        "value": 139.724,
        "_internal_originalTime": 1689757243000
    },
    {
        "time": 1689757245000,
        "value": 139.722,
        "_internal_originalTime": 1689757245000
    },
    {
        "time": 1689757246000,
        "value": 139.719,
        "_internal_originalTime": 1689757246000
    },
    {
        "time": 1689757247000,
        "value": 139.718,
        "_internal_originalTime": 1689757247000
    },
    {
        "time": 1689757248000,
        "value": 139.723,
        "_internal_originalTime": 1689757248000
    },
    {
        "time": 1689757250000,
        "value": 139.725,
        "_internal_originalTime": 1689757250000
    },
    {
        "time": 1689757251000,
        "value": 139.725,
        "_internal_originalTime": 1689757251000
    },
    {
        "time": 1689757253000,
        "value": 139.72,
        "_internal_originalTime": 1689757253000
    },
    {
        "time": 1689757254000,
        "value": 139.72,
        "_internal_originalTime": 1689757254000
    },
    {
        "time": 1689757255000,
        "value": 139.722,
        "_internal_originalTime": 1689757255000
    },
    {
        "time": 1689757256000,
        "value": 139.725,
        "_internal_originalTime": 1689757256000
    },
    {
        "time": 1689757257000,
        "value": 139.711,
        "_internal_originalTime": 1689757257000
    },
    {
        "time": 1689757261000,
        "value": 139.698,
        "_internal_originalTime": 1689757261000
    },
    {
        "time": 1689757263000,
        "value": 139.707,
        "_internal_originalTime": 1689757263000
    },
    {
        "time": 1689757264000,
        "value": 139.706,
        "_internal_originalTime": 1689757264000
    },
    {
        "time": 1689757265000,
        "value": 139.701,
        "_internal_originalTime": 1689757265000
    },
    {
        "time": 1689757266000,
        "value": 139.706,
        "_internal_originalTime": 1689757266000
    },
    {
        "time": 1689757267000,
        "value": 139.698,
        "_internal_originalTime": 1689757267000
    },
    {
        "time": 1689757268000,
        "value": 139.696,
        "_internal_originalTime": 1689757268000
    },
    {
        "time": 1689757269000,
        "value": 139.694,
        "_internal_originalTime": 1689757269000
    },
    {
        "time": 1689757270000,
        "value": 139.701,
        "_internal_originalTime": 1689757270000
    },
    {
        "time": 1689757272000,
        "value": 139.691,
        "_internal_originalTime": 1689757272000
    },
    {
        "time": 1689757273000,
        "value": 139.693,
        "_internal_originalTime": 1689757273000
    },
    {
        "time": 1689757274000,
        "value": 139.695,
        "_internal_originalTime": 1689757274000
    },
    {
        "time": 1689757275000,
        "value": 139.699,
        "_internal_originalTime": 1689757275000
    },
    {
        "time": 1689757276000,
        "value": 139.691,
        "_internal_originalTime": 1689757276000
    },
    {
        "time": 1689757277000,
        "value": 139.693,
        "_internal_originalTime": 1689757277000
    },
    {
        "time": 1689757279000,
        "value": 139.698,
        "_internal_originalTime": 1689757279000
    },
    {
        "time": 1689757280000,
        "value": 139.69,
        "_internal_originalTime": 1689757280000
    },
    {
        "time": 1689757281000,
        "value": 139.693,
        "_internal_originalTime": 1689757281000
    },
    {
        "time": 1689757282000,
        "value": 139.696,
        "_internal_originalTime": 1689757282000
    },
    {
        "time": 1689757283000,
        "value": 139.693,
        "_internal_originalTime": 1689757283000
    },
    {
        "time": 1689757285000,
        "value": 139.689,
        "_internal_originalTime": 1689757285000
    },
    {
        "time": 1689757286000,
        "value": 139.692,
        "_internal_originalTime": 1689757286000
    },
    {
        "time": 1689757288000,
        "value": 139.692,
        "_internal_originalTime": 1689757288000
    },
    {
        "time": 1689757289000,
        "value": 139.694,
        "_internal_originalTime": 1689757289000
    },
    {
        "time": 1689757290000,
        "value": 139.69,
        "_internal_originalTime": 1689757290000
    },
    {
        "time": 1689757291000,
        "value": 139.693,
        "_internal_originalTime": 1689757291000
    },
    {
        "time": 1689757292000,
        "value": 139.69,
        "_internal_originalTime": 1689757292000
    },
    {
        "time": 1689757293000,
        "value": 139.687,
        "_internal_originalTime": 1689757293000
    },
    {
        "time": 1689757294000,
        "value": 139.68,
        "_internal_originalTime": 1689757294000
    },
    {
        "time": 1689757295000,
        "value": 139.68,
        "_internal_originalTime": 1689757295000
    },
    {
        "time": 1689757296000,
        "value": 139.679,
        "_internal_originalTime": 1689757296000
    },
    {
        "time": 1689757298000,
        "value": 139.685,
        "_internal_originalTime": 1689757298000
    },
    {
        "time": 1689757299000,
        "value": 139.677,
        "_internal_originalTime": 1689757299000
    },
    {
        "time": 1689757301000,
        "value": 139.674,
        "_internal_originalTime": 1689757301000
    },
    {
        "time": 1689757302000,
        "value": 139.675,
        "_internal_originalTime": 1689757302000
    },
    {
        "time": 1689757303000,
        "value": 139.67,
        "_internal_originalTime": 1689757303000
    },
    {
        "time": 1689757305000,
        "value": 139.671,
        "_internal_originalTime": 1689757305000
    },
    {
        "time": 1689757306000,
        "value": 139.674,
        "_internal_originalTime": 1689757306000
    },
    {
        "time": 1689757307000,
        "value": 139.673,
        "_internal_originalTime": 1689757307000
    },
    {
        "time": 1689757309000,
        "value": 139.674,
        "_internal_originalTime": 1689757309000
    },
    {
        "time": 1689757310000,
        "value": 139.674,
        "_internal_originalTime": 1689757310000
    },
    {
        "time": 1689757311000,
        "value": 139.673,
        "_internal_originalTime": 1689757311000
    },
    {
        "time": 1689757313000,
        "value": 139.672,
        "_internal_originalTime": 1689757313000
    },
    {
        "time": 1689757314000,
        "value": 139.677,
        "_internal_originalTime": 1689757314000
    },
    {
        "time": 1689757315000,
        "value": 139.677,
        "_internal_originalTime": 1689757315000
    },
    {
        "time": 1689757317000,
        "value": 139.676,
        "_internal_originalTime": 1689757317000
    },
    {
        "time": 1689757318000,
        "value": 139.673,
        "_internal_originalTime": 1689757318000
    },
    {
        "time": 1689757320000,
        "value": 139.676,
        "_internal_originalTime": 1689757320000
    },
    {
        "time": 1689757321000,
        "value": 139.674,
        "_internal_originalTime": 1689757321000
    },
    {
        "time": 1689757322000,
        "value": 139.68,
        "_internal_originalTime": 1689757322000
    },
    {
        "time": 1689757323000,
        "value": 139.676,
        "_internal_originalTime": 1689757323000
    },
    {
        "time": 1689757324000,
        "value": 139.681,
        "_internal_originalTime": 1689757324000
    },
    {
        "time": 1689757326000,
        "value": 139.686,
        "_internal_originalTime": 1689757326000
    },
    {
        "time": 1689757327000,
        "value": 139.694,
        "_internal_originalTime": 1689757327000
    },
    {
        "time": 1689757328000,
        "value": 139.694,
        "_internal_originalTime": 1689757328000
    },
    {
        "time": 1689757329000,
        "value": 139.699,
        "_internal_originalTime": 1689757329000
    },
    {
        "time": 1689757330000,
        "value": 139.697,
        "_internal_originalTime": 1689757330000
    },
    {
        "time": 1689757332000,
        "value": 139.695,
        "_internal_originalTime": 1689757332000
    },
    {
        "time": 1689757333000,
        "value": 139.702,
        "_internal_originalTime": 1689757333000
    },
    {
        "time": 1689757335000,
        "value": 139.702,
        "_internal_originalTime": 1689757335000
    },
    {
        "time": 1689757336000,
        "value": 139.71,
        "_internal_originalTime": 1689757336000
    },
    {
        "time": 1689757337000,
        "value": 139.712,
        "_internal_originalTime": 1689757337000
    },
    {
        "time": 1689757338000,
        "value": 139.712,
        "_internal_originalTime": 1689757338000
    },
    {
        "time": 1689757340000,
        "value": 139.715,
        "_internal_originalTime": 1689757340000
    },
    {
        "time": 1689757341000,
        "value": 139.711,
        "_internal_originalTime": 1689757341000
    },
    {
        "time": 1689757342000,
        "value": 139.71,
        "_internal_originalTime": 1689757342000
    },
    {
        "time": 1689757344000,
        "value": 139.713,
        "_internal_originalTime": 1689757344000
    },
    {
        "time": 1689757345000,
        "value": 139.713,
        "_internal_originalTime": 1689757345000
    },
    {
        "time": 1689757346000,
        "value": 139.717,
        "_internal_originalTime": 1689757346000
    },
    {
        "time": 1689757348000,
        "value": 139.725,
        "_internal_originalTime": 1689757348000
    },
    {
        "time": 1689757350000,
        "value": 139.719,
        "_internal_originalTime": 1689757350000
    },
    {
        "time": 1689757351000,
        "value": 139.718,
        "_internal_originalTime": 1689757351000
    },
    {
        "time": 1689757352000,
        "value": 139.711,
        "_internal_originalTime": 1689757352000
    },
    {
        "time": 1689757354000,
        "value": 139.711,
        "_internal_originalTime": 1689757354000
    },
    {
        "time": 1689757356000,
        "value": 139.71,
        "_internal_originalTime": 1689757356000
    },
    {
        "time": 1689757357000,
        "value": 139.711,
        "_internal_originalTime": 1689757357000
    },
    {
        "time": 1689757359000,
        "value": 139.711,
        "_internal_originalTime": 1689757359000
    },
    {
        "time": 1689757360000,
        "value": 139.713,
        "_internal_originalTime": 1689757360000
    },
    {
        "time": 1689757362000,
        "value": 139.71,
        "_internal_originalTime": 1689757362000
    },
    {
        "time": 1689757363000,
        "value": 139.71,
        "_internal_originalTime": 1689757363000
    },
    {
        "time": 1689757365000,
        "value": 139.712,
        "_internal_originalTime": 1689757365000
    },
    {
        "time": 1689757366000,
        "value": 139.711,
        "_internal_originalTime": 1689757366000
    },
    {
        "time": 1689757367000,
        "value": 139.711,
        "_internal_originalTime": 1689757367000
    },
    {
        "time": 1689757368000,
        "value": 139.713,
        "_internal_originalTime": 1689757368000
    },
    {
        "time": 1689757369000,
        "value": 139.713,
        "_internal_originalTime": 1689757369000
    },
    {
        "time": 1689757371000,
        "value": 139.722,
        "_internal_originalTime": 1689757371000
    },
    {
        "time": 1689757373000,
        "value": 139.722,
        "_internal_originalTime": 1689757373000
    },
    {
        "time": 1689757374000,
        "value": 139.724,
        "_internal_originalTime": 1689757374000
    },
    {
        "time": 1689757375000,
        "value": 139.716,
        "_internal_originalTime": 1689757375000
    },
    {
        "time": 1689757376000,
        "value": 139.724,
        "_internal_originalTime": 1689757376000
    },
    {
        "time": 1689757378000,
        "value": 139.722,
        "_internal_originalTime": 1689757378000
    },
    {
        "time": 1689757379000,
        "value": 139.712,
        "_internal_originalTime": 1689757379000
    },
    {
        "time": 1689757381000,
        "value": 139.722,
        "_internal_originalTime": 1689757381000
    },
    {
        "time": 1689757382000,
        "value": 139.711,
        "_internal_originalTime": 1689757382000
    },
    {
        "time": 1689757384000,
        "value": 139.712,
        "_internal_originalTime": 1689757384000
    },
    {
        "time": 1689757385000,
        "value": 139.704,
        "_internal_originalTime": 1689757385000
    },
    {
        "time": 1689757386000,
        "value": 139.713,
        "_internal_originalTime": 1689757386000
    },
    {
        "time": 1689757387000,
        "value": 139.708,
        "_internal_originalTime": 1689757387000
    },
    {
        "time": 1689757388000,
        "value": 139.713,
        "_internal_originalTime": 1689757388000
    },
    {
        "time": 1689757390000,
        "value": 139.708,
        "_internal_originalTime": 1689757390000
    },
    {
        "time": 1689757391000,
        "value": 139.71,
        "_internal_originalTime": 1689757391000
    },
    {
        "time": 1689757392000,
        "value": 139.708,
        "_internal_originalTime": 1689757392000
    },
    {
        "time": 1689757393000,
        "value": 139.707,
        "_internal_originalTime": 1689757393000
    },
    {
        "time": 1689757395000,
        "value": 139.715,
        "_internal_originalTime": 1689757395000
    },
    {
        "time": 1689757396000,
        "value": 139.712,
        "_internal_originalTime": 1689757396000
    },
    {
        "time": 1689757397000,
        "value": 139.708,
        "_internal_originalTime": 1689757397000
    },
    {
        "time": 1689757399000,
        "value": 139.711,
        "_internal_originalTime": 1689757399000
    },
    {
        "time": 1689757400000,
        "value": 139.713,
        "_internal_originalTime": 1689757400000
    },
    {
        "time": 1689757402000,
        "value": 139.713,
        "_internal_originalTime": 1689757402000
    },
    {
        "time": 1689757403000,
        "value": 139.729,
        "_internal_originalTime": 1689757403000
    },
    {
        "time": 1689757404000,
        "value": 139.727,
        "_internal_originalTime": 1689757404000
    },
    {
        "time": 1689757405000,
        "value": 139.722,
        "_internal_originalTime": 1689757405000
    },
    {
        "time": 1689757407000,
        "value": 139.733,
        "_internal_originalTime": 1689757407000
    },
    {
        "time": 1689757408000,
        "value": 139.733,
        "_internal_originalTime": 1689757408000
    },
    {
        "time": 1689757409000,
        "value": 139.74,
        "_internal_originalTime": 1689757409000
    },
    {
        "time": 1689757411000,
        "value": 139.745,
        "_internal_originalTime": 1689757411000
    },
    {
        "time": 1689757412000,
        "value": 139.738,
        "_internal_originalTime": 1689757412000
    },
    {
        "time": 1689757413000,
        "value": 139.747,
        "_internal_originalTime": 1689757413000
    },
    {
        "time": 1689757414000,
        "value": 139.742,
        "_internal_originalTime": 1689757414000
    },
    {
        "time": 1689757416000,
        "value": 139.745,
        "_internal_originalTime": 1689757416000
    },
    {
        "time": 1689757418000,
        "value": 139.746,
        "_internal_originalTime": 1689757418000
    },
    {
        "time": 1689757420000,
        "value": 139.745,
        "_internal_originalTime": 1689757420000
    },
    {
        "time": 1689757422000,
        "value": 139.741,
        "_internal_originalTime": 1689757422000
    },
    {
        "time": 1689757423000,
        "value": 139.74,
        "_internal_originalTime": 1689757423000
    },
    {
        "time": 1689757424000,
        "value": 139.745,
        "_internal_originalTime": 1689757424000
    },
    {
        "time": 1689757426000,
        "value": 139.744,
        "_internal_originalTime": 1689757426000
    },
    {
        "time": 1689757429000,
        "value": 139.743,
        "_internal_originalTime": 1689757429000
    },
    {
        "time": 1689757433000,
        "value": 139.74,
        "_internal_originalTime": 1689757433000
    },
    {
        "time": 1689757434000,
        "value": 139.745,
        "_internal_originalTime": 1689757434000
    },
    {
        "time": 1689757436000,
        "value": 139.74,
        "_internal_originalTime": 1689757436000
    },
    {
        "time": 1689757438000,
        "value": 139.744,
        "_internal_originalTime": 1689757438000
    },
    {
        "time": 1689757439000,
        "value": 139.74,
        "_internal_originalTime": 1689757439000
    },
    {
        "time": 1689757440000,
        "value": 139.743,
        "_internal_originalTime": 1689757440000
    },
    {
        "time": 1689757442000,
        "value": 139.745,
        "_internal_originalTime": 1689757442000
    },
    {
        "time": 1689757443000,
        "value": 139.747,
        "_internal_originalTime": 1689757443000
    },
    {
        "time": 1689757445000,
        "value": 139.744,
        "_internal_originalTime": 1689757445000
    },
    {
        "time": 1689757446000,
        "value": 139.741,
        "_internal_originalTime": 1689757446000
    },
    {
        "time": 1689757447000,
        "value": 139.741,
        "_internal_originalTime": 1689757447000
    },
    {
        "time": 1689757448000,
        "value": 139.74,
        "_internal_originalTime": 1689757448000
    },
    {
        "time": 1689757450000,
        "value": 139.746,
        "_internal_originalTime": 1689757450000
    },
    {
        "time": 1689757451000,
        "value": 139.746,
        "_internal_originalTime": 1689757451000
    },
    {
        "time": 1689757453000,
        "value": 139.744,
        "_internal_originalTime": 1689757453000
    },
    {
        "time": 1689757454000,
        "value": 139.746,
        "_internal_originalTime": 1689757454000
    },
    {
        "time": 1689757455000,
        "value": 139.745,
        "_internal_originalTime": 1689757455000
    },
    {
        "time": 1689757457000,
        "value": 139.744,
        "_internal_originalTime": 1689757457000
    },
    {
        "time": 1689757458000,
        "value": 139.749,
        "_internal_originalTime": 1689757458000
    },
    {
        "time": 1689757459000,
        "value": 139.75,
        "_internal_originalTime": 1689757459000
    },
    {
        "time": 1689757461000,
        "value": 139.758,
        "_internal_originalTime": 1689757461000
    },
    {
        "time": 1689757462000,
        "value": 139.756,
        "_internal_originalTime": 1689757462000
    },
    {
        "time": 1689757464000,
        "value": 139.764,
        "_internal_originalTime": 1689757464000
    },
    {
        "time": 1689757466000,
        "value": 139.76,
        "_internal_originalTime": 1689757466000
    },
    {
        "time": 1689757467000,
        "value": 139.77,
        "_internal_originalTime": 1689757467000
    },
    {
        "time": 1689757468000,
        "value": 139.766,
        "_internal_originalTime": 1689757468000
    },
    {
        "time": 1689757469000,
        "value": 139.769,
        "_internal_originalTime": 1689757469000
    },
    {
        "time": 1689757470000,
        "value": 139.77,
        "_internal_originalTime": 1689757470000
    },
    {
        "time": 1689757471000,
        "value": 139.77,
        "_internal_originalTime": 1689757471000
    },
    {
        "time": 1689757472000,
        "value": 139.762,
        "_internal_originalTime": 1689757472000
    },
    {
        "time": 1689757473000,
        "value": 139.756,
        "_internal_originalTime": 1689757473000
    },
    {
        "time": 1689757474000,
        "value": 139.758,
        "_internal_originalTime": 1689757474000
    },
    {
        "time": 1689757475000,
        "value": 139.761,
        "_internal_originalTime": 1689757475000
    },
    {
        "time": 1689757477000,
        "value": 139.76,
        "_internal_originalTime": 1689757477000
    },
    {
        "time": 1689757478000,
        "value": 139.763,
        "_internal_originalTime": 1689757478000
    },
    {
        "time": 1689757479000,
        "value": 139.763,
        "_internal_originalTime": 1689757479000
    },
    {
        "time": 1689757480000,
        "value": 139.769,
        "_internal_originalTime": 1689757480000
    },
    {
        "time": 1689757482000,
        "value": 139.763,
        "_internal_originalTime": 1689757482000
    },
    {
        "time": 1689757483000,
        "value": 139.764,
        "_internal_originalTime": 1689757483000
    },
    {
        "time": 1689757485000,
        "value": 139.771,
        "_internal_originalTime": 1689757485000
    },
    {
        "time": 1689757486000,
        "value": 139.771,
        "_internal_originalTime": 1689757486000
    },
    {
        "time": 1689757487000,
        "value": 139.766,
        "_internal_originalTime": 1689757487000
    },
    {
        "time": 1689757488000,
        "value": 139.766,
        "_internal_originalTime": 1689757488000
    },
    {
        "time": 1689757489000,
        "value": 139.769,
        "_internal_originalTime": 1689757489000
    },
    {
        "time": 1689757491000,
        "value": 139.766,
        "_internal_originalTime": 1689757491000
    },
    {
        "time": 1689757492000,
        "value": 139.766,
        "_internal_originalTime": 1689757492000
    },
    {
        "time": 1689757493000,
        "value": 139.769,
        "_internal_originalTime": 1689757493000
    },
    {
        "time": 1689757494000,
        "value": 139.764,
        "_internal_originalTime": 1689757494000
    },
    {
        "time": 1689757496000,
        "value": 139.762,
        "_internal_originalTime": 1689757496000
    },
    {
        "time": 1689757497000,
        "value": 139.76,
        "_internal_originalTime": 1689757497000
    },
    {
        "time": 1689757498000,
        "value": 139.763,
        "_internal_originalTime": 1689757498000
    },
    {
        "time": 1689757500000,
        "value": 139.766,
        "_internal_originalTime": 1689757500000
    },
    {
        "time": 1689757501000,
        "value": 139.767,
        "_internal_originalTime": 1689757501000
    },
    {
        "time": 1689757502000,
        "value": 139.765,
        "_internal_originalTime": 1689757502000
    },
    {
        "time": 1689757503000,
        "value": 139.771,
        "_internal_originalTime": 1689757503000
    },
    {
        "time": 1689757504000,
        "value": 139.771,
        "_internal_originalTime": 1689757504000
    },
    {
        "time": 1689757505000,
        "value": 139.772,
        "_internal_originalTime": 1689757505000
    },
    {
        "time": 1689757507000,
        "value": 139.771,
        "_internal_originalTime": 1689757507000
    },
    {
        "time": 1689757508000,
        "value": 139.775,
        "_internal_originalTime": 1689757508000
    },
    {
        "time": 1689757509000,
        "value": 139.78,
        "_internal_originalTime": 1689757509000
    },
    {
        "time": 1689757510000,
        "value": 139.779,
        "_internal_originalTime": 1689757510000
    },
    {
        "time": 1689757511000,
        "value": 139.779,
        "_internal_originalTime": 1689757511000
    },
    {
        "time": 1689757512000,
        "value": 139.782,
        "_internal_originalTime": 1689757512000
    },
    {
        "time": 1689757514000,
        "value": 139.78,
        "_internal_originalTime": 1689757514000
    },
    {
        "time": 1689757515000,
        "value": 139.782,
        "_internal_originalTime": 1689757515000
    },
    {
        "time": 1689757516000,
        "value": 139.783,
        "_internal_originalTime": 1689757516000
    },
    {
        "time": 1689757517000,
        "value": 139.785,
        "_internal_originalTime": 1689757517000
    },
    {
        "time": 1689757519000,
        "value": 139.781,
        "_internal_originalTime": 1689757519000
    },
    {
        "time": 1689757521000,
        "value": 139.783,
        "_internal_originalTime": 1689757521000
    },
    {
        "time": 1689757523000,
        "value": 139.781,
        "_internal_originalTime": 1689757523000
    },
    {
        "time": 1689757524000,
        "value": 139.78,
        "_internal_originalTime": 1689757524000
    },
    {
        "time": 1689757526000,
        "value": 139.781,
        "_internal_originalTime": 1689757526000
    },
    {
        "time": 1689757527000,
        "value": 139.78,
        "_internal_originalTime": 1689757527000
    },
    {
        "time": 1689757528000,
        "value": 139.782,
        "_internal_originalTime": 1689757528000
    },
    {
        "time": 1689757530000,
        "value": 139.782,
        "_internal_originalTime": 1689757530000
    },
    {
        "time": 1689757531000,
        "value": 139.783,
        "_internal_originalTime": 1689757531000
    },
    {
        "time": 1689757532000,
        "value": 139.78,
        "_internal_originalTime": 1689757532000
    },
    {
        "time": 1689757533000,
        "value": 139.782,
        "_internal_originalTime": 1689757533000
    },
    {
        "time": 1689757534000,
        "value": 139.781,
        "_internal_originalTime": 1689757534000
    },
    {
        "time": 1689757535000,
        "value": 139.78,
        "_internal_originalTime": 1689757535000
    },
    {
        "time": 1689757536000,
        "value": 139.782,
        "_internal_originalTime": 1689757536000
    },
    {
        "time": 1689757538000,
        "value": 139.781,
        "_internal_originalTime": 1689757538000
    },
    {
        "time": 1689757539000,
        "value": 139.785,
        "_internal_originalTime": 1689757539000
    },
    {
        "time": 1689757540000,
        "value": 139.79,
        "_internal_originalTime": 1689757540000
    },
    {
        "time": 1689757542000,
        "value": 139.792,
        "_internal_originalTime": 1689757542000
    },
    {
        "time": 1689757543000,
        "value": 139.787,
        "_internal_originalTime": 1689757543000
    },
    {
        "time": 1689757545000,
        "value": 139.788,
        "_internal_originalTime": 1689757545000
    },
    {
        "time": 1689757547000,
        "value": 139.782,
        "_internal_originalTime": 1689757547000
    },
    {
        "time": 1689757548000,
        "value": 139.778,
        "_internal_originalTime": 1689757548000
    },
    {
        "time": 1689757549000,
        "value": 139.78,
        "_internal_originalTime": 1689757549000
    },
    {
        "time": 1689757550000,
        "value": 139.778,
        "_internal_originalTime": 1689757550000
    },
    {
        "time": 1689757551000,
        "value": 139.779,
        "_internal_originalTime": 1689757551000
    },
    {
        "time": 1689757552000,
        "value": 139.785,
        "_internal_originalTime": 1689757552000
    },
    {
        "time": 1689757553000,
        "value": 139.786,
        "_internal_originalTime": 1689757553000
    },
    {
        "time": 1689757555000,
        "value": 139.78,
        "_internal_originalTime": 1689757555000
    },
    {
        "time": 1689757556000,
        "value": 139.784,
        "_internal_originalTime": 1689757556000
    },
    {
        "time": 1689757558000,
        "value": 139.783,
        "_internal_originalTime": 1689757558000
    },
    {
        "time": 1689757559000,
        "value": 139.787,
        "_internal_originalTime": 1689757559000
    },
    {
        "time": 1689757560000,
        "value": 139.784,
        "_internal_originalTime": 1689757560000
    },
    {
        "time": 1689757561000,
        "value": 139.783,
        "_internal_originalTime": 1689757561000
    },
    {
        "time": 1689757562000,
        "value": 139.783,
        "_internal_originalTime": 1689757562000
    },
    {
        "time": 1689757564000,
        "value": 139.777,
        "_internal_originalTime": 1689757564000
    },
    {
        "time": 1689757565000,
        "value": 139.783,
        "_internal_originalTime": 1689757565000
    },
    {
        "time": 1689757566000,
        "value": 139.78,
        "_internal_originalTime": 1689757566000
    },
    {
        "time": 1689757567000,
        "value": 139.78,
        "_internal_originalTime": 1689757567000
    },
    {
        "time": 1689757568000,
        "value": 139.78,
        "_internal_originalTime": 1689757568000
    },
    {
        "time": 1689757570000,
        "value": 139.784,
        "_internal_originalTime": 1689757570000
    },
    {
        "time": 1689757571000,
        "value": 139.783,
        "_internal_originalTime": 1689757571000
    },
    {
        "time": 1689757572000,
        "value": 139.782,
        "_internal_originalTime": 1689757572000
    },
    {
        "time": 1689757573000,
        "value": 139.781,
        "_internal_originalTime": 1689757573000
    },
    {
        "time": 1689757575000,
        "value": 139.783,
        "_internal_originalTime": 1689757575000
    },
    {
        "time": 1689757576000,
        "value": 139.781,
        "_internal_originalTime": 1689757576000
    },
    {
        "time": 1689757577000,
        "value": 139.778,
        "_internal_originalTime": 1689757577000
    },
    {
        "time": 1689757579000,
        "value": 139.781,
        "_internal_originalTime": 1689757579000
    },
    {
        "time": 1689757580000,
        "value": 139.781,
        "_internal_originalTime": 1689757580000
    },
    {
        "time": 1689757581000,
        "value": 139.782,
        "_internal_originalTime": 1689757581000
    },
    {
        "time": 1689757582000,
        "value": 139.782,
        "_internal_originalTime": 1689757582000
    },
    {
        "time": 1689757584000,
        "value": 139.782,
        "_internal_originalTime": 1689757584000
    },
    {
        "time": 1689757585000,
        "value": 139.779,
        "_internal_originalTime": 1689757585000
    },
    {
        "time": 1689757586000,
        "value": 139.765,
        "_internal_originalTime": 1689757586000
    },
    {
        "time": 1689757588000,
        "value": 139.762,
        "_internal_originalTime": 1689757588000
    },
    {
        "time": 1689757590000,
        "value": 139.767,
        "_internal_originalTime": 1689757590000
    },
    {
        "time": 1689757591000,
        "value": 139.77,
        "_internal_originalTime": 1689757591000
    },
    {
        "time": 1689757594000,
        "value": 139.773,
        "_internal_originalTime": 1689757594000
    },
    {
        "time": 1689757595000,
        "value": 139.774,
        "_internal_originalTime": 1689757595000
    },
    {
        "time": 1689757596000,
        "value": 139.774,
        "_internal_originalTime": 1689757596000
    },
    {
        "time": 1689757597000,
        "value": 139.77,
        "_internal_originalTime": 1689757597000
    },
    {
        "time": 1689757599000,
        "value": 139.774,
        "_internal_originalTime": 1689757599000
    },
    {
        "time": 1689757600000,
        "value": 139.772,
        "_internal_originalTime": 1689757600000
    },
    {
        "time": 1689757601000,
        "value": 139.761,
        "_internal_originalTime": 1689757601000
    },
    {
        "time": 1689757603000,
        "value": 139.765,
        "_internal_originalTime": 1689757603000
    },
    {
        "time": 1689757604000,
        "value": 139.764,
        "_internal_originalTime": 1689757604000
    },
    {
        "time": 1689757606000,
        "value": 139.763,
        "_internal_originalTime": 1689757606000
    },
    {
        "time": 1689757607000,
        "value": 139.761,
        "_internal_originalTime": 1689757607000
    },
    {
        "time": 1689757608000,
        "value": 139.762,
        "_internal_originalTime": 1689757608000
    },
    {
        "time": 1689757609000,
        "value": 139.761,
        "_internal_originalTime": 1689757609000
    },
    {
        "time": 1689757610000,
        "value": 139.765,
        "_internal_originalTime": 1689757610000
    },
    {
        "time": 1689757612000,
        "value": 139.77,
        "_internal_originalTime": 1689757612000
    },
    {
        "time": 1689757613000,
        "value": 139.771,
        "_internal_originalTime": 1689757613000
    },
    {
        "time": 1689757614000,
        "value": 139.772,
        "_internal_originalTime": 1689757614000
    },
    {
        "time": 1689757616000,
        "value": 139.768,
        "_internal_originalTime": 1689757616000
    },
    {
        "time": 1689757617000,
        "value": 139.773,
        "_internal_originalTime": 1689757617000
    },
    {
        "time": 1689757619000,
        "value": 139.772,
        "_internal_originalTime": 1689757619000
    },
    {
        "time": 1689757620000,
        "value": 139.774,
        "_internal_originalTime": 1689757620000
    },
    {
        "time": 1689757621000,
        "value": 139.75,
        "_internal_originalTime": 1689757621000
    },
    {
        "time": 1689757622000,
        "value": 139.769,
        "_internal_originalTime": 1689757622000
    },
    {
        "time": 1689757623000,
        "value": 139.759,
        "_internal_originalTime": 1689757623000
    },
    {
        "time": 1689757624000,
        "value": 139.76,
        "_internal_originalTime": 1689757624000
    },
    {
        "time": 1689757625000,
        "value": 139.762,
        "_internal_originalTime": 1689757625000
    },
    {
        "time": 1689757627000,
        "value": 139.761,
        "_internal_originalTime": 1689757627000
    },
    {
        "time": 1689757628000,
        "value": 139.762,
        "_internal_originalTime": 1689757628000
    },
    {
        "time": 1689757629000,
        "value": 139.76,
        "_internal_originalTime": 1689757629000
    },
    {
        "time": 1689757631000,
        "value": 139.763,
        "_internal_originalTime": 1689757631000
    },
    {
        "time": 1689757632000,
        "value": 139.762,
        "_internal_originalTime": 1689757632000
    },
    {
        "time": 1689757633000,
        "value": 139.762,
        "_internal_originalTime": 1689757633000
    },
    {
        "time": 1689757634000,
        "value": 139.76,
        "_internal_originalTime": 1689757634000
    },
    {
        "time": 1689757635000,
        "value": 139.76,
        "_internal_originalTime": 1689757635000
    },
    {
        "time": 1689757636000,
        "value": 139.763,
        "_internal_originalTime": 1689757636000
    },
    {
        "time": 1689757638000,
        "value": 139.753,
        "_internal_originalTime": 1689757638000
    },
    {
        "time": 1689757639000,
        "value": 139.75,
        "_internal_originalTime": 1689757639000
    },
    {
        "time": 1689757640000,
        "value": 139.751,
        "_internal_originalTime": 1689757640000
    },
    {
        "time": 1689757641000,
        "value": 139.75,
        "_internal_originalTime": 1689757641000
    },
    {
        "time": 1689757642000,
        "value": 139.74,
        "_internal_originalTime": 1689757642000
    },
    {
        "time": 1689757643000,
        "value": 139.733,
        "_internal_originalTime": 1689757643000
    },
    {
        "time": 1689757644000,
        "value": 139.735,
        "_internal_originalTime": 1689757644000
    },
    {
        "time": 1689757645000,
        "value": 139.732,
        "_internal_originalTime": 1689757645000
    },
    {
        "time": 1689757647000,
        "value": 139.733,
        "_internal_originalTime": 1689757647000
    },
    {
        "time": 1689757648000,
        "value": 139.736,
        "_internal_originalTime": 1689757648000
    },
    {
        "time": 1689757649000,
        "value": 139.732,
        "_internal_originalTime": 1689757649000
    },
    {
        "time": 1689757651000,
        "value": 139.732,
        "_internal_originalTime": 1689757651000
    },
    {
        "time": 1689757652000,
        "value": 139.732,
        "_internal_originalTime": 1689757652000
    },
    {
        "time": 1689757653000,
        "value": 139.736,
        "_internal_originalTime": 1689757653000
    },
    {
        "time": 1689757655000,
        "value": 139.736,
        "_internal_originalTime": 1689757655000
    },
    {
        "time": 1689757656000,
        "value": 139.743,
        "_internal_originalTime": 1689757656000
    },
    {
        "time": 1689757657000,
        "value": 139.741,
        "_internal_originalTime": 1689757657000
    },
    {
        "time": 1689757658000,
        "value": 139.741,
        "_internal_originalTime": 1689757658000
    },
    {
        "time": 1689757660000,
        "value": 139.74,
        "_internal_originalTime": 1689757660000
    },
    {
        "time": 1689757661000,
        "value": 139.741,
        "_internal_originalTime": 1689757661000
    },
    {
        "time": 1689757662000,
        "value": 139.738,
        "_internal_originalTime": 1689757662000
    },
    {
        "time": 1689757663000,
        "value": 139.744,
        "_internal_originalTime": 1689757663000
    },
    {
        "time": 1689757665000,
        "value": 139.742,
        "_internal_originalTime": 1689757665000
    },
    {
        "time": 1689757666000,
        "value": 139.742,
        "_internal_originalTime": 1689757666000
    },
    {
        "time": 1689757667000,
        "value": 139.749,
        "_internal_originalTime": 1689757667000
    },
    {
        "time": 1689757668000,
        "value": 139.739,
        "_internal_originalTime": 1689757668000
    },
    {
        "time": 1689757670000,
        "value": 139.741,
        "_internal_originalTime": 1689757670000
    },
    {
        "time": 1689757671000,
        "value": 139.741,
        "_internal_originalTime": 1689757671000
    },
    {
        "time": 1689757672000,
        "value": 139.741,
        "_internal_originalTime": 1689757672000
    },
    {
        "time": 1689757673000,
        "value": 139.744,
        "_internal_originalTime": 1689757673000
    },
    {
        "time": 1689757675000,
        "value": 139.742,
        "_internal_originalTime": 1689757675000
    },
    {
        "time": 1689757676000,
        "value": 139.744,
        "_internal_originalTime": 1689757676000
    },
    {
        "time": 1689757677000,
        "value": 139.744,
        "_internal_originalTime": 1689757677000
    },
    {
        "time": 1689757679000,
        "value": 139.747,
        "_internal_originalTime": 1689757679000
    },
    {
        "time": 1689757680000,
        "value": 139.751,
        "_internal_originalTime": 1689757680000
    },
    {
        "time": 1689757681000,
        "value": 139.751,
        "_internal_originalTime": 1689757681000
    },
    {
        "time": 1689757682000,
        "value": 139.751,
        "_internal_originalTime": 1689757682000
    },
    {
        "time": 1689757683000,
        "value": 139.748,
        "_internal_originalTime": 1689757683000
    },
    {
        "time": 1689757685000,
        "value": 139.751,
        "_internal_originalTime": 1689757685000
    },
    {
        "time": 1689757686000,
        "value": 139.751,
        "_internal_originalTime": 1689757686000
    },
    {
        "time": 1689757688000,
        "value": 139.752,
        "_internal_originalTime": 1689757688000
    },
    {
        "time": 1689757689000,
        "value": 139.751,
        "_internal_originalTime": 1689757689000
    },
    {
        "time": 1689757690000,
        "value": 139.752,
        "_internal_originalTime": 1689757690000
    },
    {
        "time": 1689757692000,
        "value": 139.752,
        "_internal_originalTime": 1689757692000
    },
    {
        "time": 1689757693000,
        "value": 139.74,
        "_internal_originalTime": 1689757693000
    },
    {
        "time": 1689757695000,
        "value": 139.743,
        "_internal_originalTime": 1689757695000
    },
    {
        "time": 1689757696000,
        "value": 139.742,
        "_internal_originalTime": 1689757696000
    },
    {
        "time": 1689757697000,
        "value": 139.743,
        "_internal_originalTime": 1689757697000
    },
    {
        "time": 1689757698000,
        "value": 139.742,
        "_internal_originalTime": 1689757698000
    },
    {
        "time": 1689757700000,
        "value": 139.744,
        "_internal_originalTime": 1689757700000
    },
    {
        "time": 1689757701000,
        "value": 139.744,
        "_internal_originalTime": 1689757701000
    },
    {
        "time": 1689757702000,
        "value": 139.738,
        "_internal_originalTime": 1689757702000
    },
    {
        "time": 1689757703000,
        "value": 139.732,
        "_internal_originalTime": 1689757703000
    },
    {
        "time": 1689757705000,
        "value": 139.735,
        "_internal_originalTime": 1689757705000
    },
    {
        "time": 1689757706000,
        "value": 139.735,
        "_internal_originalTime": 1689757706000
    },
    {
        "time": 1689757708000,
        "value": 139.733,
        "_internal_originalTime": 1689757708000
    },
    {
        "time": 1689757709000,
        "value": 139.734,
        "_internal_originalTime": 1689757709000
    },
    {
        "time": 1689757710000,
        "value": 139.742,
        "_internal_originalTime": 1689757710000
    },
    {
        "time": 1689757712000,
        "value": 139.753,
        "_internal_originalTime": 1689757712000
    },
    {
        "time": 1689757714000,
        "value": 139.752,
        "_internal_originalTime": 1689757714000
    },
    {
        "time": 1689757715000,
        "value": 139.746,
        "_internal_originalTime": 1689757715000
    },
    {
        "time": 1689757716000,
        "value": 139.745,
        "_internal_originalTime": 1689757716000
    },
    {
        "time": 1689757717000,
        "value": 139.741,
        "_internal_originalTime": 1689757717000
    },
    {
        "time": 1689757718000,
        "value": 139.738,
        "_internal_originalTime": 1689757718000
    },
    {
        "time": 1689757720000,
        "value": 139.743,
        "_internal_originalTime": 1689757720000
    },
    {
        "time": 1689757721000,
        "value": 139.753,
        "_internal_originalTime": 1689757721000
    },
    {
        "time": 1689757722000,
        "value": 139.75,
        "_internal_originalTime": 1689757722000
    },
    {
        "time": 1689757724000,
        "value": 139.753,
        "_internal_originalTime": 1689757724000
    },
    {
        "time": 1689757725000,
        "value": 139.752,
        "_internal_originalTime": 1689757725000
    },
    {
        "time": 1689757726000,
        "value": 139.75,
        "_internal_originalTime": 1689757726000
    },
    {
        "time": 1689757728000,
        "value": 139.749,
        "_internal_originalTime": 1689757728000
    },
    {
        "time": 1689757730000,
        "value": 139.75,
        "_internal_originalTime": 1689757730000
    },
    {
        "time": 1689757731000,
        "value": 139.751,
        "_internal_originalTime": 1689757731000
    },
    {
        "time": 1689757732000,
        "value": 139.751,
        "_internal_originalTime": 1689757732000
    },
    {
        "time": 1689757733000,
        "value": 139.749,
        "_internal_originalTime": 1689757733000
    },
    {
        "time": 1689757735000,
        "value": 139.75,
        "_internal_originalTime": 1689757735000
    },
    {
        "time": 1689757737000,
        "value": 139.75,
        "_internal_originalTime": 1689757737000
    },
    {
        "time": 1689757738000,
        "value": 139.748,
        "_internal_originalTime": 1689757738000
    },
    {
        "time": 1689757739000,
        "value": 139.75,
        "_internal_originalTime": 1689757739000
    },
    {
        "time": 1689757740000,
        "value": 139.753,
        "_internal_originalTime": 1689757740000
    },
    {
        "time": 1689757741000,
        "value": 139.75,
        "_internal_originalTime": 1689757741000
    },
    {
        "time": 1689757742000,
        "value": 139.75,
        "_internal_originalTime": 1689757742000
    },
    {
        "time": 1689757744000,
        "value": 139.748,
        "_internal_originalTime": 1689757744000
    },
    {
        "time": 1689757747000,
        "value": 139.743,
        "_internal_originalTime": 1689757747000
    },
    {
        "time": 1689757748000,
        "value": 139.745,
        "_internal_originalTime": 1689757748000
    },
    {
        "time": 1689757749000,
        "value": 139.748,
        "_internal_originalTime": 1689757749000
    },
    {
        "time": 1689757752000,
        "value": 139.744,
        "_internal_originalTime": 1689757752000
    },
    {
        "time": 1689757753000,
        "value": 139.744,
        "_internal_originalTime": 1689757753000
    },
    {
        "time": 1689757755000,
        "value": 139.743,
        "_internal_originalTime": 1689757755000
    },
    {
        "time": 1689757756000,
        "value": 139.744,
        "_internal_originalTime": 1689757756000
    },
    {
        "time": 1689757757000,
        "value": 139.743,
        "_internal_originalTime": 1689757757000
    },
    {
        "time": 1689757758000,
        "value": 139.743,
        "_internal_originalTime": 1689757758000
    },
    {
        "time": 1689757759000,
        "value": 139.741,
        "_internal_originalTime": 1689757759000
    },
    {
        "time": 1689757761000,
        "value": 139.741,
        "_internal_originalTime": 1689757761000
    },
    {
        "time": 1689757762000,
        "value": 139.732,
        "_internal_originalTime": 1689757762000
    },
    {
        "time": 1689757763000,
        "value": 139.728,
        "_internal_originalTime": 1689757763000
    },
    {
        "time": 1689757765000,
        "value": 139.733,
        "_internal_originalTime": 1689757765000
    },
    {
        "time": 1689757766000,
        "value": 139.722,
        "_internal_originalTime": 1689757766000
    },
    {
        "time": 1689757767000,
        "value": 139.718,
        "_internal_originalTime": 1689757767000
    },
    {
        "time": 1689757768000,
        "value": 139.724,
        "_internal_originalTime": 1689757768000
    },
    {
        "time": 1689757770000,
        "value": 139.723,
        "_internal_originalTime": 1689757770000
    },
    {
        "time": 1689757771000,
        "value": 139.722,
        "_internal_originalTime": 1689757771000
    },
    {
        "time": 1689757773000,
        "value": 139.725,
        "_internal_originalTime": 1689757773000
    },
    {
        "time": 1689757775000,
        "value": 139.72,
        "_internal_originalTime": 1689757775000
    },
    {
        "time": 1689757776000,
        "value": 139.734,
        "_internal_originalTime": 1689757776000
    },
    {
        "time": 1689757777000,
        "value": 139.732,
        "_internal_originalTime": 1689757777000
    },
    {
        "time": 1689757779000,
        "value": 139.736,
        "_internal_originalTime": 1689757779000
    },
    {
        "time": 1689757780000,
        "value": 139.736,
        "_internal_originalTime": 1689757780000
    },
    {
        "time": 1689757781000,
        "value": 139.734,
        "_internal_originalTime": 1689757781000
    },
    {
        "time": 1689757783000,
        "value": 139.731,
        "_internal_originalTime": 1689757783000
    },
    {
        "time": 1689757784000,
        "value": 139.732,
        "_internal_originalTime": 1689757784000
    },
    {
        "time": 1689757785000,
        "value": 139.74,
        "_internal_originalTime": 1689757785000
    },
    {
        "time": 1689757786000,
        "value": 139.744,
        "_internal_originalTime": 1689757786000
    },
    {
        "time": 1689757787000,
        "value": 139.744,
        "_internal_originalTime": 1689757787000
    },
    {
        "time": 1689757788000,
        "value": 139.744,
        "_internal_originalTime": 1689757788000
    },
    {
        "time": 1689757790000,
        "value": 139.741,
        "_internal_originalTime": 1689757790000
    },
    {
        "time": 1689757791000,
        "value": 139.744,
        "_internal_originalTime": 1689757791000
    },
    {
        "time": 1689757793000,
        "value": 139.741,
        "_internal_originalTime": 1689757793000
    },
    {
        "time": 1689757794000,
        "value": 139.746,
        "_internal_originalTime": 1689757794000
    },
    {
        "time": 1689757796000,
        "value": 139.74,
        "_internal_originalTime": 1689757796000
    },
    {
        "time": 1689757798000,
        "value": 139.745,
        "_internal_originalTime": 1689757798000
    },
    {
        "time": 1689757800000,
        "value": 139.743,
        "_internal_originalTime": 1689757800000
    },
    {
        "time": 1689757801000,
        "value": 139.741,
        "_internal_originalTime": 1689757801000
    },
    {
        "time": 1689757802000,
        "value": 139.743,
        "_internal_originalTime": 1689757802000
    },
    {
        "time": 1689757803000,
        "value": 139.746,
        "_internal_originalTime": 1689757803000
    },
    {
        "time": 1689757805000,
        "value": 139.74,
        "_internal_originalTime": 1689757805000
    },
    {
        "time": 1689757807000,
        "value": 139.745,
        "_internal_originalTime": 1689757807000
    },
    {
        "time": 1689757808000,
        "value": 139.743,
        "_internal_originalTime": 1689757808000
    },
    {
        "time": 1689757809000,
        "value": 139.742,
        "_internal_originalTime": 1689757809000
    },
    {
        "time": 1689757811000,
        "value": 139.747,
        "_internal_originalTime": 1689757811000
    },
    {
        "time": 1689757812000,
        "value": 139.74,
        "_internal_originalTime": 1689757812000
    },
    {
        "time": 1689757814000,
        "value": 139.745,
        "_internal_originalTime": 1689757814000
    },
    {
        "time": 1689757815000,
        "value": 139.743,
        "_internal_originalTime": 1689757815000
    },
    {
        "time": 1689757817000,
        "value": 139.744,
        "_internal_originalTime": 1689757817000
    },
    {
        "time": 1689757818000,
        "value": 139.744,
        "_internal_originalTime": 1689757818000
    },
    {
        "time": 1689757819000,
        "value": 139.749,
        "_internal_originalTime": 1689757819000
    },
    {
        "time": 1689757820000,
        "value": 139.741,
        "_internal_originalTime": 1689757820000
    },
    {
        "time": 1689757821000,
        "value": 139.742,
        "_internal_originalTime": 1689757821000
    },
    {
        "time": 1689757822000,
        "value": 139.746,
        "_internal_originalTime": 1689757822000
    },
    {
        "time": 1689757824000,
        "value": 139.74,
        "_internal_originalTime": 1689757824000
    },
    {
        "time": 1689757825000,
        "value": 139.743,
        "_internal_originalTime": 1689757825000
    },
    {
        "time": 1689757827000,
        "value": 139.742,
        "_internal_originalTime": 1689757827000
    },
    {
        "time": 1689757828000,
        "value": 139.741,
        "_internal_originalTime": 1689757828000
    },
    {
        "time": 1689757829000,
        "value": 139.741,
        "_internal_originalTime": 1689757829000
    },
    {
        "time": 1689757830000,
        "value": 139.744,
        "_internal_originalTime": 1689757830000
    },
    {
        "time": 1689757831000,
        "value": 139.743,
        "_internal_originalTime": 1689757831000
    },
    {
        "time": 1689757833000,
        "value": 139.741,
        "_internal_originalTime": 1689757833000
    },
    {
        "time": 1689757834000,
        "value": 139.734,
        "_internal_originalTime": 1689757834000
    },
    {
        "time": 1689757835000,
        "value": 139.732,
        "_internal_originalTime": 1689757835000
    },
    {
        "time": 1689757836000,
        "value": 139.732,
        "_internal_originalTime": 1689757836000
    },
    {
        "time": 1689757837000,
        "value": 139.735,
        "_internal_originalTime": 1689757837000
    },
    {
        "time": 1689757839000,
        "value": 139.737,
        "_internal_originalTime": 1689757839000
    },
    {
        "time": 1689757840000,
        "value": 139.731,
        "_internal_originalTime": 1689757840000
    },
    {
        "time": 1689757841000,
        "value": 139.733,
        "_internal_originalTime": 1689757841000
    },
    {
        "time": 1689757843000,
        "value": 139.73,
        "_internal_originalTime": 1689757843000
    },
    {
        "time": 1689757844000,
        "value": 139.73,
        "_internal_originalTime": 1689757844000
    },
    {
        "time": 1689757845000,
        "value": 139.731,
        "_internal_originalTime": 1689757845000
    },
    {
        "time": 1689757847000,
        "value": 139.726,
        "_internal_originalTime": 1689757847000
    },
    {
        "time": 1689757848000,
        "value": 139.723,
        "_internal_originalTime": 1689757848000
    },
    {
        "time": 1689757849000,
        "value": 139.724,
        "_internal_originalTime": 1689757849000
    },
    {
        "time": 1689757850000,
        "value": 139.727,
        "_internal_originalTime": 1689757850000
    },
    {
        "time": 1689757852000,
        "value": 139.726,
        "_internal_originalTime": 1689757852000
    },
    {
        "time": 1689757853000,
        "value": 139.732,
        "_internal_originalTime": 1689757853000
    },
    {
        "time": 1689757855000,
        "value": 139.733,
        "_internal_originalTime": 1689757855000
    },
    {
        "time": 1689757856000,
        "value": 139.734,
        "_internal_originalTime": 1689757856000
    },
    {
        "time": 1689757857000,
        "value": 139.731,
        "_internal_originalTime": 1689757857000
    },
    {
        "time": 1689757859000,
        "value": 139.735,
        "_internal_originalTime": 1689757859000
    },
    {
        "time": 1689757860000,
        "value": 139.733,
        "_internal_originalTime": 1689757860000
    },
    {
        "time": 1689757861000,
        "value": 139.731,
        "_internal_originalTime": 1689757861000
    },
    {
        "time": 1689757862000,
        "value": 139.738,
        "_internal_originalTime": 1689757862000
    },
    {
        "time": 1689757863000,
        "value": 139.745,
        "_internal_originalTime": 1689757863000
    },
    {
        "time": 1689757864000,
        "value": 139.742,
        "_internal_originalTime": 1689757864000
    },
    {
        "time": 1689757866000,
        "value": 139.738,
        "_internal_originalTime": 1689757866000
    },
    {
        "time": 1689757867000,
        "value": 139.743,
        "_internal_originalTime": 1689757867000
    },
    {
        "time": 1689757868000,
        "value": 139.745,
        "_internal_originalTime": 1689757868000
    },
    {
        "time": 1689757869000,
        "value": 139.743,
        "_internal_originalTime": 1689757869000
    },
    {
        "time": 1689757871000,
        "value": 139.734,
        "_internal_originalTime": 1689757871000
    },
    {
        "time": 1689757872000,
        "value": 139.732,
        "_internal_originalTime": 1689757872000
    },
    {
        "time": 1689757873000,
        "value": 139.734,
        "_internal_originalTime": 1689757873000
    },
    {
        "time": 1689757874000,
        "value": 139.734,
        "_internal_originalTime": 1689757874000
    },
    {
        "time": 1689757876000,
        "value": 139.73,
        "_internal_originalTime": 1689757876000
    },
    {
        "time": 1689757877000,
        "value": 139.732,
        "_internal_originalTime": 1689757877000
    },
    {
        "time": 1689757878000,
        "value": 139.732,
        "_internal_originalTime": 1689757878000
    },
    {
        "time": 1689757879000,
        "value": 139.745,
        "_internal_originalTime": 1689757879000
    },
    {
        "time": 1689757880000,
        "value": 139.749,
        "_internal_originalTime": 1689757880000
    },
    {
        "time": 1689757881000,
        "value": 139.752,
        "_internal_originalTime": 1689757881000
    },
    {
        "time": 1689757883000,
        "value": 139.753,
        "_internal_originalTime": 1689757883000
    },
    {
        "time": 1689757884000,
        "value": 139.755,
        "_internal_originalTime": 1689757884000
    },
    {
        "time": 1689757885000,
        "value": 139.755,
        "_internal_originalTime": 1689757885000
    },
    {
        "time": 1689757887000,
        "value": 139.751,
        "_internal_originalTime": 1689757887000
    },
    {
        "time": 1689757888000,
        "value": 139.751,
        "_internal_originalTime": 1689757888000
    },
    {
        "time": 1689757889000,
        "value": 139.751,
        "_internal_originalTime": 1689757889000
    },
    {
        "time": 1689757890000,
        "value": 139.752,
        "_internal_originalTime": 1689757890000
    },
    {
        "time": 1689757891000,
        "value": 139.751,
        "_internal_originalTime": 1689757891000
    },
    {
        "time": 1689757892000,
        "value": 139.753,
        "_internal_originalTime": 1689757892000
    },
    {
        "time": 1689757894000,
        "value": 139.753,
        "_internal_originalTime": 1689757894000
    },
    {
        "time": 1689757895000,
        "value": 139.762,
        "_internal_originalTime": 1689757895000
    },
    {
        "time": 1689757896000,
        "value": 139.762,
        "_internal_originalTime": 1689757896000
    },
    {
        "time": 1689757897000,
        "value": 139.763,
        "_internal_originalTime": 1689757897000
    },
    {
        "time": 1689757898000,
        "value": 139.76,
        "_internal_originalTime": 1689757898000
    },
    {
        "time": 1689757900000,
        "value": 139.763,
        "_internal_originalTime": 1689757900000
    },
    {
        "time": 1689757901000,
        "value": 139.768,
        "_internal_originalTime": 1689757901000
    },
    {
        "time": 1689757902000,
        "value": 139.765,
        "_internal_originalTime": 1689757902000
    },
    {
        "time": 1689757903000,
        "value": 139.769,
        "_internal_originalTime": 1689757903000
    },
    {
        "time": 1689757904000,
        "value": 139.768,
        "_internal_originalTime": 1689757904000
    },
    {
        "time": 1689757908000,
        "value": 139.766,
        "_internal_originalTime": 1689757908000
    },
    {
        "time": 1689757910000,
        "value": 139.766,
        "_internal_originalTime": 1689757910000
    },
    {
        "time": 1689757912000,
        "value": 139.767,
        "_internal_originalTime": 1689757912000
    },
    {
        "time": 1689757913000,
        "value": 139.764,
        "_internal_originalTime": 1689757913000
    },
    {
        "time": 1689757914000,
        "value": 139.768,
        "_internal_originalTime": 1689757914000
    },
    {
        "time": 1689757916000,
        "value": 139.774,
        "_internal_originalTime": 1689757916000
    },
    {
        "time": 1689757917000,
        "value": 139.78,
        "_internal_originalTime": 1689757917000
    },
    {
        "time": 1689757918000,
        "value": 139.779,
        "_internal_originalTime": 1689757918000
    },
    {
        "time": 1689757920000,
        "value": 139.77,
        "_internal_originalTime": 1689757920000
    },
    {
        "time": 1689757921000,
        "value": 139.74,
        "_internal_originalTime": 1689757921000
    },
    {
        "time": 1689757922000,
        "value": 139.773,
        "_internal_originalTime": 1689757922000
    },
    {
        "time": 1689757923000,
        "value": 139.769,
        "_internal_originalTime": 1689757923000
    },
    {
        "time": 1689757924000,
        "value": 139.766,
        "_internal_originalTime": 1689757924000
    },
    {
        "time": 1689757926000,
        "value": 139.769,
        "_internal_originalTime": 1689757926000
    },
    {
        "time": 1689757927000,
        "value": 139.77,
        "_internal_originalTime": 1689757927000
    },
    {
        "time": 1689757928000,
        "value": 139.76,
        "_internal_originalTime": 1689757928000
    },
    {
        "time": 1689757929000,
        "value": 139.762,
        "_internal_originalTime": 1689757929000
    },
    {
        "time": 1689757931000,
        "value": 139.759,
        "_internal_originalTime": 1689757931000
    },
    {
        "time": 1689757932000,
        "value": 139.751,
        "_internal_originalTime": 1689757932000
    },
    {
        "time": 1689757933000,
        "value": 139.75,
        "_internal_originalTime": 1689757933000
    },
    {
        "time": 1689757935000,
        "value": 139.747,
        "_internal_originalTime": 1689757935000
    },
    {
        "time": 1689757936000,
        "value": 139.751,
        "_internal_originalTime": 1689757936000
    },
    {
        "time": 1689757937000,
        "value": 139.752,
        "_internal_originalTime": 1689757937000
    },
    {
        "time": 1689757938000,
        "value": 139.752,
        "_internal_originalTime": 1689757938000
    },
    {
        "time": 1689757940000,
        "value": 139.747,
        "_internal_originalTime": 1689757940000
    },
    {
        "time": 1689757942000,
        "value": 139.739,
        "_internal_originalTime": 1689757942000
    },
    {
        "time": 1689757943000,
        "value": 139.739,
        "_internal_originalTime": 1689757943000
    },
    {
        "time": 1689757944000,
        "value": 139.741,
        "_internal_originalTime": 1689757944000
    },
    {
        "time": 1689757946000,
        "value": 139.736,
        "_internal_originalTime": 1689757946000
    },
    {
        "time": 1689757947000,
        "value": 139.732,
        "_internal_originalTime": 1689757947000
    },
    {
        "time": 1689757948000,
        "value": 139.735,
        "_internal_originalTime": 1689757948000
    },
    {
        "time": 1689757949000,
        "value": 139.734,
        "_internal_originalTime": 1689757949000
    },
    {
        "time": 1689757950000,
        "value": 139.735,
        "_internal_originalTime": 1689757950000
    },
    {
        "time": 1689757951000,
        "value": 139.732,
        "_internal_originalTime": 1689757951000
    },
    {
        "time": 1689757952000,
        "value": 139.731,
        "_internal_originalTime": 1689757952000
    },
    {
        "time": 1689757953000,
        "value": 139.732,
        "_internal_originalTime": 1689757953000
    },
    {
        "time": 1689757955000,
        "value": 139.735,
        "_internal_originalTime": 1689757955000
    },
    {
        "time": 1689757956000,
        "value": 139.73,
        "_internal_originalTime": 1689757956000
    },
    {
        "time": 1689757957000,
        "value": 139.731,
        "_internal_originalTime": 1689757957000
    },
    {
        "time": 1689757958000,
        "value": 139.731,
        "_internal_originalTime": 1689757958000
    },
    {
        "time": 1689757959000,
        "value": 139.727,
        "_internal_originalTime": 1689757959000
    },
    {
        "time": 1689757961000,
        "value": 139.732,
        "_internal_originalTime": 1689757961000
    },
    {
        "time": 1689757962000,
        "value": 139.728,
        "_internal_originalTime": 1689757962000
    },
    {
        "time": 1689757963000,
        "value": 139.722,
        "_internal_originalTime": 1689757963000
    },
    {
        "time": 1689757965000,
        "value": 139.729,
        "_internal_originalTime": 1689757965000
    },
    {
        "time": 1689757967000,
        "value": 139.737,
        "_internal_originalTime": 1689757967000
    },
    {
        "time": 1689757969000,
        "value": 139.738,
        "_internal_originalTime": 1689757969000
    },
    {
        "time": 1689757970000,
        "value": 139.742,
        "_internal_originalTime": 1689757970000
    },
    {
        "time": 1689757971000,
        "value": 139.73,
        "_internal_originalTime": 1689757971000
    },
    {
        "time": 1689757973000,
        "value": 139.729,
        "_internal_originalTime": 1689757973000
    },
    {
        "time": 1689757974000,
        "value": 139.734,
        "_internal_originalTime": 1689757974000
    },
    {
        "time": 1689757975000,
        "value": 139.735,
        "_internal_originalTime": 1689757975000
    },
    {
        "time": 1689757976000,
        "value": 139.733,
        "_internal_originalTime": 1689757976000
    },
    {
        "time": 1689757978000,
        "value": 139.735,
        "_internal_originalTime": 1689757978000
    },
    {
        "time": 1689757980000,
        "value": 139.732,
        "_internal_originalTime": 1689757980000
    },
    {
        "time": 1689757981000,
        "value": 139.73,
        "_internal_originalTime": 1689757981000
    },
    {
        "time": 1689757982000,
        "value": 139.731,
        "_internal_originalTime": 1689757982000
    },
    {
        "time": 1689757984000,
        "value": 139.732,
        "_internal_originalTime": 1689757984000
    },
    {
        "time": 1689757985000,
        "value": 139.73,
        "_internal_originalTime": 1689757985000
    },
    {
        "time": 1689757986000,
        "value": 139.733,
        "_internal_originalTime": 1689757986000
    },
    {
        "time": 1689757987000,
        "value": 139.725,
        "_internal_originalTime": 1689757987000
    },
    {
        "time": 1689757988000,
        "value": 139.727,
        "_internal_originalTime": 1689757988000
    },
    {
        "time": 1689757989000,
        "value": 139.726,
        "_internal_originalTime": 1689757989000
    },
    {
        "time": 1689757990000,
        "value": 139.72,
        "_internal_originalTime": 1689757990000
    },
    {
        "time": 1689757991000,
        "value": 139.716,
        "_internal_originalTime": 1689757991000
    },
    {
        "time": 1689757993000,
        "value": 139.717,
        "_internal_originalTime": 1689757993000
    },
    {
        "time": 1689757995000,
        "value": 139.717,
        "_internal_originalTime": 1689757995000
    },
    {
        "time": 1689757996000,
        "value": 139.717,
        "_internal_originalTime": 1689757996000
    },
    {
        "time": 1689757997000,
        "value": 139.717,
        "_internal_originalTime": 1689757997000
    },
    {
        "time": 1689757998000,
        "value": 139.714,
        "_internal_originalTime": 1689757998000
    },
    {
        "time": 1689757999000,
        "value": 139.716,
        "_internal_originalTime": 1689757999000
    },
    {
        "time": 1689758001000,
        "value": 139.713,
        "_internal_originalTime": 1689758001000
    },
    {
        "time": 1689758002000,
        "value": 139.711,
        "_internal_originalTime": 1689758002000
    },
    {
        "time": 1689758003000,
        "value": 139.714,
        "_internal_originalTime": 1689758003000
    },
    {
        "time": 1689758004000,
        "value": 139.714,
        "_internal_originalTime": 1689758004000
    },
    {
        "time": 1689758005000,
        "value": 139.714,
        "_internal_originalTime": 1689758005000
    },
    {
        "time": 1689758006000,
        "value": 139.711,
        "_internal_originalTime": 1689758006000
    },
    {
        "time": 1689758008000,
        "value": 139.717,
        "_internal_originalTime": 1689758008000
    },
    {
        "time": 1689758009000,
        "value": 139.714,
        "_internal_originalTime": 1689758009000
    },
    {
        "time": 1689758010000,
        "value": 139.714,
        "_internal_originalTime": 1689758010000
    },
    {
        "time": 1689758012000,
        "value": 139.713,
        "_internal_originalTime": 1689758012000
    },
    {
        "time": 1689758014000,
        "value": 139.71,
        "_internal_originalTime": 1689758014000
    },
    {
        "time": 1689758015000,
        "value": 139.717,
        "_internal_originalTime": 1689758015000
    },
    {
        "time": 1689758017000,
        "value": 139.721,
        "_internal_originalTime": 1689758017000
    },
    {
        "time": 1689758018000,
        "value": 139.721,
        "_internal_originalTime": 1689758018000
    },
    {
        "time": 1689758019000,
        "value": 139.724,
        "_internal_originalTime": 1689758019000
    },
    {
        "time": 1689758020000,
        "value": 139.71,
        "_internal_originalTime": 1689758020000
    },
    {
        "time": 1689758022000,
        "value": 139.714,
        "_internal_originalTime": 1689758022000
    },
    {
        "time": 1689758023000,
        "value": 139.713,
        "_internal_originalTime": 1689758023000
    },
    {
        "time": 1689758025000,
        "value": 139.708,
        "_internal_originalTime": 1689758025000
    },
    {
        "time": 1689758027000,
        "value": 139.714,
        "_internal_originalTime": 1689758027000
    },
    {
        "time": 1689758028000,
        "value": 139.714,
        "_internal_originalTime": 1689758028000
    },
    {
        "time": 1689758030000,
        "value": 139.714,
        "_internal_originalTime": 1689758030000
    },
    {
        "time": 1689758031000,
        "value": 139.722,
        "_internal_originalTime": 1689758031000
    },
    {
        "time": 1689758032000,
        "value": 139.714,
        "_internal_originalTime": 1689758032000
    },
    {
        "time": 1689758033000,
        "value": 139.712,
        "_internal_originalTime": 1689758033000
    },
    {
        "time": 1689758034000,
        "value": 139.715,
        "_internal_originalTime": 1689758034000
    },
    {
        "time": 1689758035000,
        "value": 139.716,
        "_internal_originalTime": 1689758035000
    },
    {
        "time": 1689758036000,
        "value": 139.712,
        "_internal_originalTime": 1689758036000
    },
    {
        "time": 1689758039000,
        "value": 139.72,
        "_internal_originalTime": 1689758039000
    },
    {
        "time": 1689758040000,
        "value": 139.718,
        "_internal_originalTime": 1689758040000
    },
    {
        "time": 1689758041000,
        "value": 139.717,
        "_internal_originalTime": 1689758041000
    },
    {
        "time": 1689758042000,
        "value": 139.714,
        "_internal_originalTime": 1689758042000
    },
    {
        "time": 1689758043000,
        "value": 139.717,
        "_internal_originalTime": 1689758043000
    },
    {
        "time": 1689758044000,
        "value": 139.716,
        "_internal_originalTime": 1689758044000
    },
    {
        "time": 1689758046000,
        "value": 139.717,
        "_internal_originalTime": 1689758046000
    },
    {
        "time": 1689758048000,
        "value": 139.718,
        "_internal_originalTime": 1689758048000
    },
    {
        "time": 1689758049000,
        "value": 139.721,
        "_internal_originalTime": 1689758049000
    },
    {
        "time": 1689758051000,
        "value": 139.734,
        "_internal_originalTime": 1689758051000
    },
    {
        "time": 1689758052000,
        "value": 139.733,
        "_internal_originalTime": 1689758052000
    },
    {
        "time": 1689758053000,
        "value": 139.734,
        "_internal_originalTime": 1689758053000
    },
    {
        "time": 1689758054000,
        "value": 139.736,
        "_internal_originalTime": 1689758054000
    },
    {
        "time": 1689758055000,
        "value": 139.732,
        "_internal_originalTime": 1689758055000
    },
    {
        "time": 1689758056000,
        "value": 139.741,
        "_internal_originalTime": 1689758056000
    },
    {
        "time": 1689758057000,
        "value": 139.75,
        "_internal_originalTime": 1689758057000
    },
    {
        "time": 1689758058000,
        "value": 139.751,
        "_internal_originalTime": 1689758058000
    },
    {
        "time": 1689758059000,
        "value": 139.76,
        "_internal_originalTime": 1689758059000
    },
    {
        "time": 1689758060000,
        "value": 139.762,
        "_internal_originalTime": 1689758060000
    },
    {
        "time": 1689758061000,
        "value": 139.761,
        "_internal_originalTime": 1689758061000
    },
    {
        "time": 1689758063000,
        "value": 139.758,
        "_internal_originalTime": 1689758063000
    },
    {
        "time": 1689758064000,
        "value": 139.754,
        "_internal_originalTime": 1689758064000
    },
    {
        "time": 1689758065000,
        "value": 139.755,
        "_internal_originalTime": 1689758065000
    },
    {
        "time": 1689758066000,
        "value": 139.756,
        "_internal_originalTime": 1689758066000
    },
    {
        "time": 1689758068000,
        "value": 139.762,
        "_internal_originalTime": 1689758068000
    },
    {
        "time": 1689758070000,
        "value": 139.771,
        "_internal_originalTime": 1689758070000
    },
    {
        "time": 1689758071000,
        "value": 139.769,
        "_internal_originalTime": 1689758071000
    },
    {
        "time": 1689758072000,
        "value": 139.77,
        "_internal_originalTime": 1689758072000
    },
    {
        "time": 1689758073000,
        "value": 139.769,
        "_internal_originalTime": 1689758073000
    },
    {
        "time": 1689758074000,
        "value": 139.77,
        "_internal_originalTime": 1689758074000
    },
    {
        "time": 1689758076000,
        "value": 139.771,
        "_internal_originalTime": 1689758076000
    },
    {
        "time": 1689758077000,
        "value": 139.762,
        "_internal_originalTime": 1689758077000
    },
    {
        "time": 1689758078000,
        "value": 139.771,
        "_internal_originalTime": 1689758078000
    },
    {
        "time": 1689758079000,
        "value": 139.773,
        "_internal_originalTime": 1689758079000
    },
    {
        "time": 1689758080000,
        "value": 139.773,
        "_internal_originalTime": 1689758080000
    },
    {
        "time": 1689758081000,
        "value": 139.771,
        "_internal_originalTime": 1689758081000
    },
    {
        "time": 1689758083000,
        "value": 139.77,
        "_internal_originalTime": 1689758083000
    },
    {
        "time": 1689758084000,
        "value": 139.767,
        "_internal_originalTime": 1689758084000
    },
    {
        "time": 1689758085000,
        "value": 139.768,
        "_internal_originalTime": 1689758085000
    },
    {
        "time": 1689758086000,
        "value": 139.771,
        "_internal_originalTime": 1689758086000
    },
    {
        "time": 1689758087000,
        "value": 139.771,
        "_internal_originalTime": 1689758087000
    },
    {
        "time": 1689758089000,
        "value": 139.772,
        "_internal_originalTime": 1689758089000
    },
    {
        "time": 1689758091000,
        "value": 139.776,
        "_internal_originalTime": 1689758091000
    },
    {
        "time": 1689758092000,
        "value": 139.779,
        "_internal_originalTime": 1689758092000
    },
    {
        "time": 1689758093000,
        "value": 139.782,
        "_internal_originalTime": 1689758093000
    },
    {
        "time": 1689758095000,
        "value": 139.774,
        "_internal_originalTime": 1689758095000
    },
    {
        "time": 1689758096000,
        "value": 139.775,
        "_internal_originalTime": 1689758096000
    },
    {
        "time": 1689758097000,
        "value": 139.775,
        "_internal_originalTime": 1689758097000
    },
    {
        "time": 1689758099000,
        "value": 139.776,
        "_internal_originalTime": 1689758099000
    },
    {
        "time": 1689758100000,
        "value": 139.779,
        "_internal_originalTime": 1689758100000
    },
    {
        "time": 1689758101000,
        "value": 139.78,
        "_internal_originalTime": 1689758101000
    },
    {
        "time": 1689758102000,
        "value": 139.782,
        "_internal_originalTime": 1689758102000
    },
    {
        "time": 1689758104000,
        "value": 139.78,
        "_internal_originalTime": 1689758104000
    },
    {
        "time": 1689758105000,
        "value": 139.777,
        "_internal_originalTime": 1689758105000
    },
    {
        "time": 1689758107000,
        "value": 139.773,
        "_internal_originalTime": 1689758107000
    },
    {
        "time": 1689758108000,
        "value": 139.768,
        "_internal_originalTime": 1689758108000
    },
    {
        "time": 1689758109000,
        "value": 139.769,
        "_internal_originalTime": 1689758109000
    },
    {
        "time": 1689758110000,
        "value": 139.77,
        "_internal_originalTime": 1689758110000
    },
    {
        "time": 1689758112000,
        "value": 139.766,
        "_internal_originalTime": 1689758112000
    },
    {
        "time": 1689758113000,
        "value": 139.759,
        "_internal_originalTime": 1689758113000
    },
    {
        "time": 1689758114000,
        "value": 139.752,
        "_internal_originalTime": 1689758114000
    },
    {
        "time": 1689758115000,
        "value": 139.759,
        "_internal_originalTime": 1689758115000
    },
    {
        "time": 1689758117000,
        "value": 139.747,
        "_internal_originalTime": 1689758117000
    },
    {
        "time": 1689758118000,
        "value": 139.751,
        "_internal_originalTime": 1689758118000
    },
    {
        "time": 1689758119000,
        "value": 139.746,
        "_internal_originalTime": 1689758119000
    },
    {
        "time": 1689758120000,
        "value": 139.749,
        "_internal_originalTime": 1689758120000
    },
    {
        "time": 1689758121000,
        "value": 139.749,
        "_internal_originalTime": 1689758121000
    },
    {
        "time": 1689758123000,
        "value": 139.752,
        "_internal_originalTime": 1689758123000
    },
    {
        "time": 1689758124000,
        "value": 139.759,
        "_internal_originalTime": 1689758124000
    },
    {
        "time": 1689758125000,
        "value": 139.766,
        "_internal_originalTime": 1689758125000
    },
    {
        "time": 1689758126000,
        "value": 139.766,
        "_internal_originalTime": 1689758126000
    },
    {
        "time": 1689758128000,
        "value": 139.769,
        "_internal_originalTime": 1689758128000
    },
    {
        "time": 1689758129000,
        "value": 139.77,
        "_internal_originalTime": 1689758129000
    },
    {
        "time": 1689758130000,
        "value": 139.768,
        "_internal_originalTime": 1689758130000
    },
    {
        "time": 1689758131000,
        "value": 139.768,
        "_internal_originalTime": 1689758131000
    },
    {
        "time": 1689758133000,
        "value": 139.768,
        "_internal_originalTime": 1689758133000
    },
    {
        "time": 1689758134000,
        "value": 139.766,
        "_internal_originalTime": 1689758134000
    },
    {
        "time": 1689758135000,
        "value": 139.767,
        "_internal_originalTime": 1689758135000
    },
    {
        "time": 1689758136000,
        "value": 139.766,
        "_internal_originalTime": 1689758136000
    },
    {
        "time": 1689758137000,
        "value": 139.763,
        "_internal_originalTime": 1689758137000
    },
    {
        "time": 1689758138000,
        "value": 139.762,
        "_internal_originalTime": 1689758138000
    },
    {
        "time": 1689758140000,
        "value": 139.765,
        "_internal_originalTime": 1689758140000
    },
    {
        "time": 1689758141000,
        "value": 139.765,
        "_internal_originalTime": 1689758141000
    },
    {
        "time": 1689758142000,
        "value": 139.766,
        "_internal_originalTime": 1689758142000
    },
    {
        "time": 1689758143000,
        "value": 139.766,
        "_internal_originalTime": 1689758143000
    },
    {
        "time": 1689758145000,
        "value": 139.763,
        "_internal_originalTime": 1689758145000
    },
    {
        "time": 1689758146000,
        "value": 139.765,
        "_internal_originalTime": 1689758146000
    },
    {
        "time": 1689758147000,
        "value": 139.759,
        "_internal_originalTime": 1689758147000
    },
    {
        "time": 1689758148000,
        "value": 139.762,
        "_internal_originalTime": 1689758148000
    },
    {
        "time": 1689758149000,
        "value": 139.756,
        "_internal_originalTime": 1689758149000
    },
    {
        "time": 1689758151000,
        "value": 139.763,
        "_internal_originalTime": 1689758151000
    },
    {
        "time": 1689758152000,
        "value": 139.762,
        "_internal_originalTime": 1689758152000
    },
    {
        "time": 1689758153000,
        "value": 139.76,
        "_internal_originalTime": 1689758153000
    },
    {
        "time": 1689758155000,
        "value": 139.767,
        "_internal_originalTime": 1689758155000
    },
    {
        "time": 1689758156000,
        "value": 139.767,
        "_internal_originalTime": 1689758156000
    },
    {
        "time": 1689758157000,
        "value": 139.765,
        "_internal_originalTime": 1689758157000
    },
    {
        "time": 1689758159000,
        "value": 139.779,
        "_internal_originalTime": 1689758159000
    },
    {
        "time": 1689758160000,
        "value": 139.776,
        "_internal_originalTime": 1689758160000
    },
    {
        "time": 1689758161000,
        "value": 139.778,
        "_internal_originalTime": 1689758161000
    },
    {
        "time": 1689758163000,
        "value": 139.779,
        "_internal_originalTime": 1689758163000
    },
    {
        "time": 1689758165000,
        "value": 139.776,
        "_internal_originalTime": 1689758165000
    },
    {
        "time": 1689758167000,
        "value": 139.776,
        "_internal_originalTime": 1689758167000
    },
    {
        "time": 1689758168000,
        "value": 139.78,
        "_internal_originalTime": 1689758168000
    },
    {
        "time": 1689758169000,
        "value": 139.781,
        "_internal_originalTime": 1689758169000
    },
    {
        "time": 1689758171000,
        "value": 139.774,
        "_internal_originalTime": 1689758171000
    },
    {
        "time": 1689758172000,
        "value": 139.772,
        "_internal_originalTime": 1689758172000
    },
    {
        "time": 1689758173000,
        "value": 139.773,
        "_internal_originalTime": 1689758173000
    },
    {
        "time": 1689758175000,
        "value": 139.774,
        "_internal_originalTime": 1689758175000
    },
    {
        "time": 1689758176000,
        "value": 139.772,
        "_internal_originalTime": 1689758176000
    },
    {
        "time": 1689758177000,
        "value": 139.775,
        "_internal_originalTime": 1689758177000
    },
    {
        "time": 1689758178000,
        "value": 139.77,
        "_internal_originalTime": 1689758178000
    },
    {
        "time": 1689758179000,
        "value": 139.773,
        "_internal_originalTime": 1689758179000
    },
    {
        "time": 1689758181000,
        "value": 139.768,
        "_internal_originalTime": 1689758181000
    },
    {
        "time": 1689758182000,
        "value": 139.772,
        "_internal_originalTime": 1689758182000
    },
    {
        "time": 1689758183000,
        "value": 139.77,
        "_internal_originalTime": 1689758183000
    },
    {
        "time": 1689758184000,
        "value": 139.772,
        "_internal_originalTime": 1689758184000
    },
    {
        "time": 1689758186000,
        "value": 139.771,
        "_internal_originalTime": 1689758186000
    },
    {
        "time": 1689758187000,
        "value": 139.768,
        "_internal_originalTime": 1689758187000
    },
    {
        "time": 1689758188000,
        "value": 139.77,
        "_internal_originalTime": 1689758188000
    },
    {
        "time": 1689758190000,
        "value": 139.769,
        "_internal_originalTime": 1689758190000
    },
    {
        "time": 1689758191000,
        "value": 139.758,
        "_internal_originalTime": 1689758191000
    },
    {
        "time": 1689758192000,
        "value": 139.76,
        "_internal_originalTime": 1689758192000
    },
    {
        "time": 1689758193000,
        "value": 139.76,
        "_internal_originalTime": 1689758193000
    },
    {
        "time": 1689758195000,
        "value": 139.756,
        "_internal_originalTime": 1689758195000
    },
    {
        "time": 1689758196000,
        "value": 139.76,
        "_internal_originalTime": 1689758196000
    },
    {
        "time": 1689758197000,
        "value": 139.757,
        "_internal_originalTime": 1689758197000
    },
    {
        "time": 1689758198000,
        "value": 139.755,
        "_internal_originalTime": 1689758198000
    },
    {
        "time": 1689758200000,
        "value": 139.756,
        "_internal_originalTime": 1689758200000
    },
    {
        "time": 1689758201000,
        "value": 139.756,
        "_internal_originalTime": 1689758201000
    },
    {
        "time": 1689758202000,
        "value": 139.756,
        "_internal_originalTime": 1689758202000
    },
    {
        "time": 1689758206000,
        "value": 139.777,
        "_internal_originalTime": 1689758206000
    },
    {
        "time": 1689758208000,
        "value": 139.778,
        "_internal_originalTime": 1689758208000
    },
    {
        "time": 1689758209000,
        "value": 139.775,
        "_internal_originalTime": 1689758209000
    },
    {
        "time": 1689758210000,
        "value": 139.772,
        "_internal_originalTime": 1689758210000
    },
    {
        "time": 1689758211000,
        "value": 139.776,
        "_internal_originalTime": 1689758211000
    },
    {
        "time": 1689758214000,
        "value": 139.778,
        "_internal_originalTime": 1689758214000
    },
    {
        "time": 1689758215000,
        "value": 139.776,
        "_internal_originalTime": 1689758215000
    },
    {
        "time": 1689758217000,
        "value": 139.776,
        "_internal_originalTime": 1689758217000
    },
    {
        "time": 1689758218000,
        "value": 139.772,
        "_internal_originalTime": 1689758218000
    },
    {
        "time": 1689758219000,
        "value": 139.77,
        "_internal_originalTime": 1689758219000
    },
    {
        "time": 1689758221000,
        "value": 139.771,
        "_internal_originalTime": 1689758221000
    },
    {
        "time": 1689758222000,
        "value": 139.771,
        "_internal_originalTime": 1689758222000
    },
    {
        "time": 1689758223000,
        "value": 139.772,
        "_internal_originalTime": 1689758223000
    },
    {
        "time": 1689758224000,
        "value": 139.775,
        "_internal_originalTime": 1689758224000
    },
    {
        "time": 1689758225000,
        "value": 139.776,
        "_internal_originalTime": 1689758225000
    },
    {
        "time": 1689758226000,
        "value": 139.768,
        "_internal_originalTime": 1689758226000
    },
    {
        "time": 1689758228000,
        "value": 139.772,
        "_internal_originalTime": 1689758228000
    },
    {
        "time": 1689758229000,
        "value": 139.776,
        "_internal_originalTime": 1689758229000
    },
    {
        "time": 1689758230000,
        "value": 139.77,
        "_internal_originalTime": 1689758230000
    },
    {
        "time": 1689758232000,
        "value": 139.772,
        "_internal_originalTime": 1689758232000
    },
    {
        "time": 1689758233000,
        "value": 139.782,
        "_internal_originalTime": 1689758233000
    },
    {
        "time": 1689758235000,
        "value": 139.776,
        "_internal_originalTime": 1689758235000
    },
    {
        "time": 1689758236000,
        "value": 139.783,
        "_internal_originalTime": 1689758236000
    },
    {
        "time": 1689758237000,
        "value": 139.782,
        "_internal_originalTime": 1689758237000
    },
    {
        "time": 1689758239000,
        "value": 139.776,
        "_internal_originalTime": 1689758239000
    },
    {
        "time": 1689758242000,
        "value": 139.79,
        "_internal_originalTime": 1689758242000
    },
    {
        "time": 1689758243000,
        "value": 139.786,
        "_internal_originalTime": 1689758243000
    },
    {
        "time": 1689758246000,
        "value": 139.786,
        "_internal_originalTime": 1689758246000
    },
    {
        "time": 1689758248000,
        "value": 139.786,
        "_internal_originalTime": 1689758248000
    },
    {
        "time": 1689758250000,
        "value": 139.786,
        "_internal_originalTime": 1689758250000
    },
    {
        "time": 1689758251000,
        "value": 139.788,
        "_internal_originalTime": 1689758251000
    },
    {
        "time": 1689758252000,
        "value": 139.792,
        "_internal_originalTime": 1689758252000
    },
    {
        "time": 1689758255000,
        "value": 139.797,
        "_internal_originalTime": 1689758255000
    },
    {
        "time": 1689758256000,
        "value": 139.793,
        "_internal_originalTime": 1689758256000
    },
    {
        "time": 1689758258000,
        "value": 139.797,
        "_internal_originalTime": 1689758258000
    },
    {
        "time": 1689758260000,
        "value": 139.789,
        "_internal_originalTime": 1689758260000
    },
    {
        "time": 1689758261000,
        "value": 139.782,
        "_internal_originalTime": 1689758261000
    },
    {
        "time": 1689758263000,
        "value": 139.781,
        "_internal_originalTime": 1689758263000
    },
    {
        "time": 1689758265000,
        "value": 139.78,
        "_internal_originalTime": 1689758265000
    },
    {
        "time": 1689758267000,
        "value": 139.779,
        "_internal_originalTime": 1689758267000
    },
    {
        "time": 1689758268000,
        "value": 139.774,
        "_internal_originalTime": 1689758268000
    },
    {
        "time": 1689758269000,
        "value": 139.773,
        "_internal_originalTime": 1689758269000
    },
    {
        "time": 1689758270000,
        "value": 139.771,
        "_internal_originalTime": 1689758270000
    },
    {
        "time": 1689758272000,
        "value": 139.767,
        "_internal_originalTime": 1689758272000
    },
    {
        "time": 1689758273000,
        "value": 139.77,
        "_internal_originalTime": 1689758273000
    },
    {
        "time": 1689758274000,
        "value": 139.767,
        "_internal_originalTime": 1689758274000
    },
    {
        "time": 1689758276000,
        "value": 139.766,
        "_internal_originalTime": 1689758276000
    },
    {
        "time": 1689758277000,
        "value": 139.763,
        "_internal_originalTime": 1689758277000
    },
    {
        "time": 1689758278000,
        "value": 139.761,
        "_internal_originalTime": 1689758278000
    },
    {
        "time": 1689758280000,
        "value": 139.761,
        "_internal_originalTime": 1689758280000
    },
    {
        "time": 1689758281000,
        "value": 139.756,
        "_internal_originalTime": 1689758281000
    },
    {
        "time": 1689758282000,
        "value": 139.757,
        "_internal_originalTime": 1689758282000
    },
    {
        "time": 1689758284000,
        "value": 139.762,
        "_internal_originalTime": 1689758284000
    },
    {
        "time": 1689758286000,
        "value": 139.763,
        "_internal_originalTime": 1689758286000
    },
    {
        "time": 1689758288000,
        "value": 139.766,
        "_internal_originalTime": 1689758288000
    },
    {
        "time": 1689758289000,
        "value": 139.774,
        "_internal_originalTime": 1689758289000
    },
    {
        "time": 1689758290000,
        "value": 139.772,
        "_internal_originalTime": 1689758290000
    },
    {
        "time": 1689758291000,
        "value": 139.767,
        "_internal_originalTime": 1689758291000
    },
    {
        "time": 1689758292000,
        "value": 139.772,
        "_internal_originalTime": 1689758292000
    },
    {
        "time": 1689758293000,
        "value": 139.772,
        "_internal_originalTime": 1689758293000
    },
    {
        "time": 1689758295000,
        "value": 139.768,
        "_internal_originalTime": 1689758295000
    },
    {
        "time": 1689758296000,
        "value": 139.77,
        "_internal_originalTime": 1689758296000
    },
    {
        "time": 1689758299000,
        "value": 139.772,
        "_internal_originalTime": 1689758299000
    },
    {
        "time": 1689758300000,
        "value": 139.772,
        "_internal_originalTime": 1689758300000
    },
    {
        "time": 1689758301000,
        "value": 139.778,
        "_internal_originalTime": 1689758301000
    },
    {
        "time": 1689758302000,
        "value": 139.78,
        "_internal_originalTime": 1689758302000
    },
    {
        "time": 1689758304000,
        "value": 139.777,
        "_internal_originalTime": 1689758304000
    },
    {
        "time": 1689758305000,
        "value": 139.779,
        "_internal_originalTime": 1689758305000
    },
    {
        "time": 1689758306000,
        "value": 139.779,
        "_internal_originalTime": 1689758306000
    },
    {
        "time": 1689758308000,
        "value": 139.792,
        "_internal_originalTime": 1689758308000
    },
    {
        "time": 1689758310000,
        "value": 139.781,
        "_internal_originalTime": 1689758310000
    },
    {
        "time": 1689758312000,
        "value": 139.782,
        "_internal_originalTime": 1689758312000
    },
    {
        "time": 1689758313000,
        "value": 139.781,
        "_internal_originalTime": 1689758313000
    },
    {
        "time": 1689758314000,
        "value": 139.788,
        "_internal_originalTime": 1689758314000
    },
    {
        "time": 1689758315000,
        "value": 139.788,
        "_internal_originalTime": 1689758315000
    },
    {
        "time": 1689758316000,
        "value": 139.796,
        "_internal_originalTime": 1689758316000
    },
    {
        "time": 1689758317000,
        "value": 139.79,
        "_internal_originalTime": 1689758317000
    },
    {
        "time": 1689758319000,
        "value": 139.783,
        "_internal_originalTime": 1689758319000
    },
    {
        "time": 1689758320000,
        "value": 139.782,
        "_internal_originalTime": 1689758320000
    },
    {
        "time": 1689758321000,
        "value": 139.786,
        "_internal_originalTime": 1689758321000
    },
    {
        "time": 1689758322000,
        "value": 139.789,
        "_internal_originalTime": 1689758322000
    },
    {
        "time": 1689758323000,
        "value": 139.797,
        "_internal_originalTime": 1689758323000
    },
    {
        "time": 1689758324000,
        "value": 139.797,
        "_internal_originalTime": 1689758324000
    },
    {
        "time": 1689758325000,
        "value": 139.794,
        "_internal_originalTime": 1689758325000
    },
    {
        "time": 1689758327000,
        "value": 139.793,
        "_internal_originalTime": 1689758327000
    },
    {
        "time": 1689758329000,
        "value": 139.789,
        "_internal_originalTime": 1689758329000
    },
    {
        "time": 1689758330000,
        "value": 139.791,
        "_internal_originalTime": 1689758330000
    },
    {
        "time": 1689758331000,
        "value": 139.792,
        "_internal_originalTime": 1689758331000
    },
    {
        "time": 1689758332000,
        "value": 139.793,
        "_internal_originalTime": 1689758332000
    },
    {
        "time": 1689758334000,
        "value": 139.79,
        "_internal_originalTime": 1689758334000
    },
    {
        "time": 1689758335000,
        "value": 139.794,
        "_internal_originalTime": 1689758335000
    },
    {
        "time": 1689758336000,
        "value": 139.789,
        "_internal_originalTime": 1689758336000
    },
    {
        "time": 1689758339000,
        "value": 139.787,
        "_internal_originalTime": 1689758339000
    },
    {
        "time": 1689758340000,
        "value": 139.795,
        "_internal_originalTime": 1689758340000
    },
    {
        "time": 1689758341000,
        "value": 139.792,
        "_internal_originalTime": 1689758341000
    },
    {
        "time": 1689758342000,
        "value": 139.793,
        "_internal_originalTime": 1689758342000
    },
    {
        "time": 1689758343000,
        "value": 139.795,
        "_internal_originalTime": 1689758343000
    },
    {
        "time": 1689758344000,
        "value": 139.797,
        "_internal_originalTime": 1689758344000
    },
    {
        "time": 1689758345000,
        "value": 139.796,
        "_internal_originalTime": 1689758345000
    },
    {
        "time": 1689758346000,
        "value": 139.795,
        "_internal_originalTime": 1689758346000
    },
    {
        "time": 1689758348000,
        "value": 139.798,
        "_internal_originalTime": 1689758348000
    },
    {
        "time": 1689758349000,
        "value": 139.797,
        "_internal_originalTime": 1689758349000
    },
    {
        "time": 1689758350000,
        "value": 139.794,
        "_internal_originalTime": 1689758350000
    },
    {
        "time": 1689758352000,
        "value": 139.802,
        "_internal_originalTime": 1689758352000
    },
    {
        "time": 1689758353000,
        "value": 139.802,
        "_internal_originalTime": 1689758353000
    },
    {
        "time": 1689758354000,
        "value": 139.802,
        "_internal_originalTime": 1689758354000
    },
    {
        "time": 1689758355000,
        "value": 139.804,
        "_internal_originalTime": 1689758355000
    },
    {
        "time": 1689758356000,
        "value": 139.804,
        "_internal_originalTime": 1689758356000
    },
    {
        "time": 1689758357000,
        "value": 139.799,
        "_internal_originalTime": 1689758357000
    },
    {
        "time": 1689758358000,
        "value": 139.798,
        "_internal_originalTime": 1689758358000
    },
    {
        "time": 1689758360000,
        "value": 139.799,
        "_internal_originalTime": 1689758360000
    },
    {
        "time": 1689758362000,
        "value": 139.8,
        "_internal_originalTime": 1689758362000
    },
    {
        "time": 1689758363000,
        "value": 139.802,
        "_internal_originalTime": 1689758363000
    },
    {
        "time": 1689758364000,
        "value": 139.804,
        "_internal_originalTime": 1689758364000
    },
    {
        "time": 1689758366000,
        "value": 139.807,
        "_internal_originalTime": 1689758366000
    },
    {
        "time": 1689758367000,
        "value": 139.805,
        "_internal_originalTime": 1689758367000
    },
    {
        "time": 1689758368000,
        "value": 139.808,
        "_internal_originalTime": 1689758368000
    },
    {
        "time": 1689758369000,
        "value": 139.814,
        "_internal_originalTime": 1689758369000
    },
    {
        "time": 1689758370000,
        "value": 139.808,
        "_internal_originalTime": 1689758370000
    },
    {
        "time": 1689758372000,
        "value": 139.809,
        "_internal_originalTime": 1689758372000
    },
    {
        "time": 1689758373000,
        "value": 139.812,
        "_internal_originalTime": 1689758373000
    },
    {
        "time": 1689758374000,
        "value": 139.819,
        "_internal_originalTime": 1689758374000
    },
    {
        "time": 1689758375000,
        "value": 139.817,
        "_internal_originalTime": 1689758375000
    },
    {
        "time": 1689758377000,
        "value": 139.817,
        "_internal_originalTime": 1689758377000
    },
    {
        "time": 1689758378000,
        "value": 139.815,
        "_internal_originalTime": 1689758378000
    },
    {
        "time": 1689758379000,
        "value": 139.82,
        "_internal_originalTime": 1689758379000
    },
    {
        "time": 1689758381000,
        "value": 139.83,
        "_internal_originalTime": 1689758381000
    },
    {
        "time": 1689758382000,
        "value": 139.828,
        "_internal_originalTime": 1689758382000
    },
    {
        "time": 1689758383000,
        "value": 139.833,
        "_internal_originalTime": 1689758383000
    },
    {
        "time": 1689758384000,
        "value": 139.838,
        "_internal_originalTime": 1689758384000
    },
    {
        "time": 1689758388000,
        "value": 139.844,
        "_internal_originalTime": 1689758388000
    },
    {
        "time": 1689758389000,
        "value": 139.841,
        "_internal_originalTime": 1689758389000
    },
    {
        "time": 1689758390000,
        "value": 139.844,
        "_internal_originalTime": 1689758390000
    },
    {
        "time": 1689758392000,
        "value": 139.835,
        "_internal_originalTime": 1689758392000
    },
    {
        "time": 1689758393000,
        "value": 139.826,
        "_internal_originalTime": 1689758393000
    },
    {
        "time": 1689758394000,
        "value": 139.827,
        "_internal_originalTime": 1689758394000
    },
    {
        "time": 1689758395000,
        "value": 139.826,
        "_internal_originalTime": 1689758395000
    },
    {
        "time": 1689758396000,
        "value": 139.827,
        "_internal_originalTime": 1689758396000
    },
    {
        "time": 1689758398000,
        "value": 139.816,
        "_internal_originalTime": 1689758398000
    },
    {
        "time": 1689758399000,
        "value": 139.818,
        "_internal_originalTime": 1689758399000
    },
    {
        "time": 1689758400000,
        "value": 139.821,
        "_internal_originalTime": 1689758400000
    },
    {
        "time": 1689758401000,
        "value": 139.819,
        "_internal_originalTime": 1689758401000
    },
    {
        "time": 1689758402000,
        "value": 139.819,
        "_internal_originalTime": 1689758402000
    },
    {
        "time": 1689758403000,
        "value": 139.816,
        "_internal_originalTime": 1689758403000
    },
    {
        "time": 1689758405000,
        "value": 139.818,
        "_internal_originalTime": 1689758405000
    },
    {
        "time": 1689758407000,
        "value": 139.815,
        "_internal_originalTime": 1689758407000
    },
    {
        "time": 1689758408000,
        "value": 139.803,
        "_internal_originalTime": 1689758408000
    },
    {
        "time": 1689758410000,
        "value": 139.804,
        "_internal_originalTime": 1689758410000
    },
    {
        "time": 1689758411000,
        "value": 139.807,
        "_internal_originalTime": 1689758411000
    },
    {
        "time": 1689758412000,
        "value": 139.802,
        "_internal_originalTime": 1689758412000
    },
    {
        "time": 1689758413000,
        "value": 139.794,
        "_internal_originalTime": 1689758413000
    },
    {
        "time": 1689758414000,
        "value": 139.797,
        "_internal_originalTime": 1689758414000
    },
    {
        "time": 1689758415000,
        "value": 139.796,
        "_internal_originalTime": 1689758415000
    },
    {
        "time": 1689758416000,
        "value": 139.773,
        "_internal_originalTime": 1689758416000
    },
    {
        "time": 1689758417000,
        "value": 139.77,
        "_internal_originalTime": 1689758417000
    },
    {
        "time": 1689758418000,
        "value": 139.783,
        "_internal_originalTime": 1689758418000
    },
    {
        "time": 1689758419000,
        "value": 139.789,
        "_internal_originalTime": 1689758419000
    },
    {
        "time": 1689758420000,
        "value": 139.794,
        "_internal_originalTime": 1689758420000
    },
    {
        "time": 1689758422000,
        "value": 139.795,
        "_internal_originalTime": 1689758422000
    },
    {
        "time": 1689758423000,
        "value": 139.791,
        "_internal_originalTime": 1689758423000
    },
    {
        "time": 1689758424000,
        "value": 139.789,
        "_internal_originalTime": 1689758424000
    },
    {
        "time": 1689758425000,
        "value": 139.786,
        "_internal_originalTime": 1689758425000
    },
    {
        "time": 1689758426000,
        "value": 139.789,
        "_internal_originalTime": 1689758426000
    },
    {
        "time": 1689758427000,
        "value": 139.793,
        "_internal_originalTime": 1689758427000
    },
    {
        "time": 1689758429000,
        "value": 139.79,
        "_internal_originalTime": 1689758429000
    },
    {
        "time": 1689758431000,
        "value": 139.794,
        "_internal_originalTime": 1689758431000
    },
    {
        "time": 1689758432000,
        "value": 139.791,
        "_internal_originalTime": 1689758432000
    },
    {
        "time": 1689758433000,
        "value": 139.79,
        "_internal_originalTime": 1689758433000
    },
    {
        "time": 1689758434000,
        "value": 139.79,
        "_internal_originalTime": 1689758434000
    },
    {
        "time": 1689758436000,
        "value": 139.791,
        "_internal_originalTime": 1689758436000
    },
    {
        "time": 1689758437000,
        "value": 139.782,
        "_internal_originalTime": 1689758437000
    },
    {
        "time": 1689758438000,
        "value": 139.777,
        "_internal_originalTime": 1689758438000
    },
    {
        "time": 1689758441000,
        "value": 139.781,
        "_internal_originalTime": 1689758441000
    },
    {
        "time": 1689758442000,
        "value": 139.779,
        "_internal_originalTime": 1689758442000
    },
    {
        "time": 1689758443000,
        "value": 139.78,
        "_internal_originalTime": 1689758443000
    },
    {
        "time": 1689758444000,
        "value": 139.779,
        "_internal_originalTime": 1689758444000
    },
    {
        "time": 1689758445000,
        "value": 139.778,
        "_internal_originalTime": 1689758445000
    },
    {
        "time": 1689758447000,
        "value": 139.779,
        "_internal_originalTime": 1689758447000
    },
    {
        "time": 1689758448000,
        "value": 139.78,
        "_internal_originalTime": 1689758448000
    },
    {
        "time": 1689758450000,
        "value": 139.781,
        "_internal_originalTime": 1689758450000
    },
    {
        "time": 1689758451000,
        "value": 139.777,
        "_internal_originalTime": 1689758451000
    },
    {
        "time": 1689758452000,
        "value": 139.777,
        "_internal_originalTime": 1689758452000
    },
    {
        "time": 1689758453000,
        "value": 139.779,
        "_internal_originalTime": 1689758453000
    },
    {
        "time": 1689758454000,
        "value": 139.779,
        "_internal_originalTime": 1689758454000
    },
    {
        "time": 1689758456000,
        "value": 139.776,
        "_internal_originalTime": 1689758456000
    },
    {
        "time": 1689758457000,
        "value": 139.776,
        "_internal_originalTime": 1689758457000
    },
    {
        "time": 1689758459000,
        "value": 139.778,
        "_internal_originalTime": 1689758459000
    },
    {
        "time": 1689758460000,
        "value": 139.78,
        "_internal_originalTime": 1689758460000
    },
    {
        "time": 1689758461000,
        "value": 139.78,
        "_internal_originalTime": 1689758461000
    },
    {
        "time": 1689758463000,
        "value": 139.776,
        "_internal_originalTime": 1689758463000
    },
    {
        "time": 1689758464000,
        "value": 139.77,
        "_internal_originalTime": 1689758464000
    },
    {
        "time": 1689758465000,
        "value": 139.771,
        "_internal_originalTime": 1689758465000
    },
    {
        "time": 1689758466000,
        "value": 139.771,
        "_internal_originalTime": 1689758466000
    },
    {
        "time": 1689758468000,
        "value": 139.772,
        "_internal_originalTime": 1689758468000
    },
    {
        "time": 1689758470000,
        "value": 139.765,
        "_internal_originalTime": 1689758470000
    },
    {
        "time": 1689758471000,
        "value": 139.766,
        "_internal_originalTime": 1689758471000
    },
    {
        "time": 1689758473000,
        "value": 139.768,
        "_internal_originalTime": 1689758473000
    },
    {
        "time": 1689758474000,
        "value": 139.781,
        "_internal_originalTime": 1689758474000
    },
    {
        "time": 1689758475000,
        "value": 139.779,
        "_internal_originalTime": 1689758475000
    },
    {
        "time": 1689758476000,
        "value": 139.78,
        "_internal_originalTime": 1689758476000
    },
    {
        "time": 1689758478000,
        "value": 139.792,
        "_internal_originalTime": 1689758478000
    },
    {
        "time": 1689758479000,
        "value": 139.789,
        "_internal_originalTime": 1689758479000
    },
    {
        "time": 1689758480000,
        "value": 139.788,
        "_internal_originalTime": 1689758480000
    },
    {
        "time": 1689758481000,
        "value": 139.79,
        "_internal_originalTime": 1689758481000
    },
    {
        "time": 1689758482000,
        "value": 139.794,
        "_internal_originalTime": 1689758482000
    },
    {
        "time": 1689758484000,
        "value": 139.796,
        "_internal_originalTime": 1689758484000
    },
    {
        "time": 1689758485000,
        "value": 139.799,
        "_internal_originalTime": 1689758485000
    },
    {
        "time": 1689758486000,
        "value": 139.796,
        "_internal_originalTime": 1689758486000
    },
    {
        "time": 1689758487000,
        "value": 139.803,
        "_internal_originalTime": 1689758487000
    },
    {
        "time": 1689758488000,
        "value": 139.805,
        "_internal_originalTime": 1689758488000
    },
    {
        "time": 1689758490000,
        "value": 139.808,
        "_internal_originalTime": 1689758490000
    },
    {
        "time": 1689758491000,
        "value": 139.811,
        "_internal_originalTime": 1689758491000
    },
    {
        "time": 1689758492000,
        "value": 139.811,
        "_internal_originalTime": 1689758492000
    },
    {
        "time": 1689758493000,
        "value": 139.808,
        "_internal_originalTime": 1689758493000
    },
    {
        "time": 1689758495000,
        "value": 139.813,
        "_internal_originalTime": 1689758495000
    },
    {
        "time": 1689758496000,
        "value": 139.815,
        "_internal_originalTime": 1689758496000
    },
    {
        "time": 1689758497000,
        "value": 139.82,
        "_internal_originalTime": 1689758497000
    },
    {
        "time": 1689758498000,
        "value": 139.82,
        "_internal_originalTime": 1689758498000
    },
    {
        "time": 1689758499000,
        "value": 139.823,
        "_internal_originalTime": 1689758499000
    },
    {
        "time": 1689758500000,
        "value": 139.821,
        "_internal_originalTime": 1689758500000
    },
    {
        "time": 1689758502000,
        "value": 139.819,
        "_internal_originalTime": 1689758502000
    },
    {
        "time": 1689758503000,
        "value": 139.825,
        "_internal_originalTime": 1689758503000
    },
    {
        "time": 1689758505000,
        "value": 139.826,
        "_internal_originalTime": 1689758505000
    },
    {
        "time": 1689758507000,
        "value": 139.831,
        "_internal_originalTime": 1689758507000
    },
    {
        "time": 1689758509000,
        "value": 139.832,
        "_internal_originalTime": 1689758509000
    },
    {
        "time": 1689758510000,
        "value": 139.838,
        "_internal_originalTime": 1689758510000
    },
    {
        "time": 1689758511000,
        "value": 139.839,
        "_internal_originalTime": 1689758511000
    },
    {
        "time": 1689758512000,
        "value": 139.84,
        "_internal_originalTime": 1689758512000
    },
    {
        "time": 1689758513000,
        "value": 139.834,
        "_internal_originalTime": 1689758513000
    },
    {
        "time": 1689758515000,
        "value": 139.831,
        "_internal_originalTime": 1689758515000
    },
    {
        "time": 1689758516000,
        "value": 139.83,
        "_internal_originalTime": 1689758516000
    },
    {
        "time": 1689758518000,
        "value": 139.832,
        "_internal_originalTime": 1689758518000
    },
    {
        "time": 1689758519000,
        "value": 139.83,
        "_internal_originalTime": 1689758519000
    },
    {
        "time": 1689758520000,
        "value": 139.826,
        "_internal_originalTime": 1689758520000
    },
    {
        "time": 1689758521000,
        "value": 139.825,
        "_internal_originalTime": 1689758521000
    },
    {
        "time": 1689758522000,
        "value": 139.823,
        "_internal_originalTime": 1689758522000
    },
    {
        "time": 1689758524000,
        "value": 139.825,
        "_internal_originalTime": 1689758524000
    },
    {
        "time": 1689758525000,
        "value": 139.823,
        "_internal_originalTime": 1689758525000
    },
    {
        "time": 1689758526000,
        "value": 139.825,
        "_internal_originalTime": 1689758526000
    },
    {
        "time": 1689758527000,
        "value": 139.827,
        "_internal_originalTime": 1689758527000
    },
    {
        "time": 1689758529000,
        "value": 139.829,
        "_internal_originalTime": 1689758529000
    },
    {
        "time": 1689758530000,
        "value": 139.822,
        "_internal_originalTime": 1689758530000
    },
    {
        "time": 1689758531000,
        "value": 139.823,
        "_internal_originalTime": 1689758531000
    },
    {
        "time": 1689758533000,
        "value": 139.82,
        "_internal_originalTime": 1689758533000
    },
    {
        "time": 1689758535000,
        "value": 139.819,
        "_internal_originalTime": 1689758535000
    },
    {
        "time": 1689758536000,
        "value": 139.819,
        "_internal_originalTime": 1689758536000
    },
    {
        "time": 1689758537000,
        "value": 139.822,
        "_internal_originalTime": 1689758537000
    },
    {
        "time": 1689758538000,
        "value": 139.82,
        "_internal_originalTime": 1689758538000
    },
    {
        "time": 1689758540000,
        "value": 139.821,
        "_internal_originalTime": 1689758540000
    },
    {
        "time": 1689758541000,
        "value": 139.821,
        "_internal_originalTime": 1689758541000
    },
    {
        "time": 1689758542000,
        "value": 139.809,
        "_internal_originalTime": 1689758542000
    },
    {
        "time": 1689758543000,
        "value": 139.822,
        "_internal_originalTime": 1689758543000
    },
    {
        "time": 1689758544000,
        "value": 139.82,
        "_internal_originalTime": 1689758544000
    },
    {
        "time": 1689758546000,
        "value": 139.822,
        "_internal_originalTime": 1689758546000
    },
    {
        "time": 1689758547000,
        "value": 139.823,
        "_internal_originalTime": 1689758547000
    },
    {
        "time": 1689758548000,
        "value": 139.816,
        "_internal_originalTime": 1689758548000
    },
    {
        "time": 1689758550000,
        "value": 139.814,
        "_internal_originalTime": 1689758550000
    },
    {
        "time": 1689758551000,
        "value": 139.81,
        "_internal_originalTime": 1689758551000
    },
    {
        "time": 1689758552000,
        "value": 139.814,
        "_internal_originalTime": 1689758552000
    },
    {
        "time": 1689758553000,
        "value": 139.809,
        "_internal_originalTime": 1689758553000
    },
    {
        "time": 1689758555000,
        "value": 139.812,
        "_internal_originalTime": 1689758555000
    },
    {
        "time": 1689758556000,
        "value": 139.807,
        "_internal_originalTime": 1689758556000
    },
    {
        "time": 1689758557000,
        "value": 139.807,
        "_internal_originalTime": 1689758557000
    },
    {
        "time": 1689758558000,
        "value": 139.808,
        "_internal_originalTime": 1689758558000
    },
    {
        "time": 1689758561000,
        "value": 139.803,
        "_internal_originalTime": 1689758561000
    },
    {
        "time": 1689758562000,
        "value": 139.8,
        "_internal_originalTime": 1689758562000
    },
    {
        "time": 1689758563000,
        "value": 139.79,
        "_internal_originalTime": 1689758563000
    },
    {
        "time": 1689758565000,
        "value": 139.797,
        "_internal_originalTime": 1689758565000
    },
    {
        "time": 1689758566000,
        "value": 139.798,
        "_internal_originalTime": 1689758566000
    },
    {
        "time": 1689758567000,
        "value": 139.799,
        "_internal_originalTime": 1689758567000
    },
    {
        "time": 1689758568000,
        "value": 139.793,
        "_internal_originalTime": 1689758568000
    },
    {
        "time": 1689758570000,
        "value": 139.798,
        "_internal_originalTime": 1689758570000
    },
    {
        "time": 1689758572000,
        "value": 139.795,
        "_internal_originalTime": 1689758572000
    },
    {
        "time": 1689758574000,
        "value": 139.792,
        "_internal_originalTime": 1689758574000
    },
    {
        "time": 1689758576000,
        "value": 139.801,
        "_internal_originalTime": 1689758576000
    },
    {
        "time": 1689758577000,
        "value": 139.804,
        "_internal_originalTime": 1689758577000
    },
    {
        "time": 1689758579000,
        "value": 139.799,
        "_internal_originalTime": 1689758579000
    },
    {
        "time": 1689758580000,
        "value": 139.787,
        "_internal_originalTime": 1689758580000
    },
    {
        "time": 1689758581000,
        "value": 139.789,
        "_internal_originalTime": 1689758581000
    },
    {
        "time": 1689758582000,
        "value": 139.793,
        "_internal_originalTime": 1689758582000
    },
    {
        "time": 1689758583000,
        "value": 139.79,
        "_internal_originalTime": 1689758583000
    },
    {
        "time": 1689758584000,
        "value": 139.787,
        "_internal_originalTime": 1689758584000
    },
    {
        "time": 1689758586000,
        "value": 139.783,
        "_internal_originalTime": 1689758586000
    },
    {
        "time": 1689758587000,
        "value": 139.782,
        "_internal_originalTime": 1689758587000
    },
    {
        "time": 1689758588000,
        "value": 139.785,
        "_internal_originalTime": 1689758588000
    },
    {
        "time": 1689758589000,
        "value": 139.787,
        "_internal_originalTime": 1689758589000
    },
    {
        "time": 1689758591000,
        "value": 139.785,
        "_internal_originalTime": 1689758591000
    },
    {
        "time": 1689758595000,
        "value": 139.78,
        "_internal_originalTime": 1689758595000
    },
    {
        "time": 1689758596000,
        "value": 139.785,
        "_internal_originalTime": 1689758596000
    },
    {
        "time": 1689758597000,
        "value": 139.782,
        "_internal_originalTime": 1689758597000
    },
    {
        "time": 1689758598000,
        "value": 139.781,
        "_internal_originalTime": 1689758598000
    },
    {
        "time": 1689758600000,
        "value": 139.783,
        "_internal_originalTime": 1689758600000
    },
    {
        "time": 1689758601000,
        "value": 139.78,
        "_internal_originalTime": 1689758601000
    },
    {
        "time": 1689758602000,
        "value": 139.781,
        "_internal_originalTime": 1689758602000
    },
    {
        "time": 1689758603000,
        "value": 139.785,
        "_internal_originalTime": 1689758603000
    },
    {
        "time": 1689758604000,
        "value": 139.785,
        "_internal_originalTime": 1689758604000
    },
    {
        "time": 1689758606000,
        "value": 139.784,
        "_internal_originalTime": 1689758606000
    },
    {
        "time": 1689758607000,
        "value": 139.78,
        "_internal_originalTime": 1689758607000
    },
    {
        "time": 1689758610000,
        "value": 139.793,
        "_internal_originalTime": 1689758610000
    },
    {
        "time": 1689758611000,
        "value": 139.787,
        "_internal_originalTime": 1689758611000
    },
    {
        "time": 1689758613000,
        "value": 139.786,
        "_internal_originalTime": 1689758613000
    },
    {
        "time": 1689758614000,
        "value": 139.786,
        "_internal_originalTime": 1689758614000
    },
    {
        "time": 1689758615000,
        "value": 139.785,
        "_internal_originalTime": 1689758615000
    },
    {
        "time": 1689758616000,
        "value": 139.784,
        "_internal_originalTime": 1689758616000
    },
    {
        "time": 1689758617000,
        "value": 139.79,
        "_internal_originalTime": 1689758617000
    },
    {
        "time": 1689758618000,
        "value": 139.79,
        "_internal_originalTime": 1689758618000
    },
    {
        "time": 1689758619000,
        "value": 139.79,
        "_internal_originalTime": 1689758619000
    },
    {
        "time": 1689758621000,
        "value": 139.799,
        "_internal_originalTime": 1689758621000
    },
    {
        "time": 1689758622000,
        "value": 139.8,
        "_internal_originalTime": 1689758622000
    },
    {
        "time": 1689758623000,
        "value": 139.804,
        "_internal_originalTime": 1689758623000
    },
    {
        "time": 1689758625000,
        "value": 139.8,
        "_internal_originalTime": 1689758625000
    },
    {
        "time": 1689758626000,
        "value": 139.802,
        "_internal_originalTime": 1689758626000
    },
    {
        "time": 1689758627000,
        "value": 139.803,
        "_internal_originalTime": 1689758627000
    },
    {
        "time": 1689758628000,
        "value": 139.802,
        "_internal_originalTime": 1689758628000
    },
    {
        "time": 1689758630000,
        "value": 139.798,
        "_internal_originalTime": 1689758630000
    },
    {
        "time": 1689758631000,
        "value": 139.798,
        "_internal_originalTime": 1689758631000
    },
    {
        "time": 1689758633000,
        "value": 139.8,
        "_internal_originalTime": 1689758633000
    },
    {
        "time": 1689758634000,
        "value": 139.806,
        "_internal_originalTime": 1689758634000
    },
    {
        "time": 1689758635000,
        "value": 139.811,
        "_internal_originalTime": 1689758635000
    },
    {
        "time": 1689758636000,
        "value": 139.812,
        "_internal_originalTime": 1689758636000
    },
    {
        "time": 1689758638000,
        "value": 139.816,
        "_internal_originalTime": 1689758638000
    },
    {
        "time": 1689758639000,
        "value": 139.817,
        "_internal_originalTime": 1689758639000
    },
    {
        "time": 1689758640000,
        "value": 139.79,
        "_internal_originalTime": 1689758640000
    },
    {
        "time": 1689758641000,
        "value": 139.819,
        "_internal_originalTime": 1689758641000
    },
    {
        "time": 1689758642000,
        "value": 139.812,
        "_internal_originalTime": 1689758642000
    },
    {
        "time": 1689758643000,
        "value": 139.81,
        "_internal_originalTime": 1689758643000
    },
    {
        "time": 1689758644000,
        "value": 139.81,
        "_internal_originalTime": 1689758644000
    },
    {
        "time": 1689758645000,
        "value": 139.813,
        "_internal_originalTime": 1689758645000
    },
    {
        "time": 1689758646000,
        "value": 139.812,
        "_internal_originalTime": 1689758646000
    },
    {
        "time": 1689758648000,
        "value": 139.812,
        "_internal_originalTime": 1689758648000
    },
    {
        "time": 1689758649000,
        "value": 139.822,
        "_internal_originalTime": 1689758649000
    },
    {
        "time": 1689758650000,
        "value": 139.813,
        "_internal_originalTime": 1689758650000
    },
    {
        "time": 1689758651000,
        "value": 139.812,
        "_internal_originalTime": 1689758651000
    },
    {
        "time": 1689758652000,
        "value": 139.808,
        "_internal_originalTime": 1689758652000
    },
    {
        "time": 1689758654000,
        "value": 139.81,
        "_internal_originalTime": 1689758654000
    },
    {
        "time": 1689758655000,
        "value": 139.807,
        "_internal_originalTime": 1689758655000
    },
    {
        "time": 1689758657000,
        "value": 139.81,
        "_internal_originalTime": 1689758657000
    },
    {
        "time": 1689758658000,
        "value": 139.81,
        "_internal_originalTime": 1689758658000
    },
    {
        "time": 1689758659000,
        "value": 139.805,
        "_internal_originalTime": 1689758659000
    },
    {
        "time": 1689758660000,
        "value": 139.809,
        "_internal_originalTime": 1689758660000
    },
    {
        "time": 1689758661000,
        "value": 139.809,
        "_internal_originalTime": 1689758661000
    },
    {
        "time": 1689758663000,
        "value": 139.817,
        "_internal_originalTime": 1689758663000
    },
    {
        "time": 1689758664000,
        "value": 139.811,
        "_internal_originalTime": 1689758664000
    },
    {
        "time": 1689758665000,
        "value": 139.81,
        "_internal_originalTime": 1689758665000
    },
    {
        "time": 1689758666000,
        "value": 139.807,
        "_internal_originalTime": 1689758666000
    },
    {
        "time": 1689758668000,
        "value": 139.818,
        "_internal_originalTime": 1689758668000
    },
    {
        "time": 1689758670000,
        "value": 139.821,
        "_internal_originalTime": 1689758670000
    },
    {
        "time": 1689758671000,
        "value": 139.812,
        "_internal_originalTime": 1689758671000
    },
    {
        "time": 1689758673000,
        "value": 139.815,
        "_internal_originalTime": 1689758673000
    },
    {
        "time": 1689758674000,
        "value": 139.815,
        "_internal_originalTime": 1689758674000
    },
    {
        "time": 1689758675000,
        "value": 139.816,
        "_internal_originalTime": 1689758675000
    },
    {
        "time": 1689758676000,
        "value": 139.812,
        "_internal_originalTime": 1689758676000
    },
    {
        "time": 1689758677000,
        "value": 139.809,
        "_internal_originalTime": 1689758677000
    },
    {
        "time": 1689758678000,
        "value": 139.815,
        "_internal_originalTime": 1689758678000
    },
    {
        "time": 1689758680000,
        "value": 139.812,
        "_internal_originalTime": 1689758680000
    },
    {
        "time": 1689758681000,
        "value": 139.812,
        "_internal_originalTime": 1689758681000
    },
    {
        "time": 1689758682000,
        "value": 139.811,
        "_internal_originalTime": 1689758682000
    },
    {
        "time": 1689758683000,
        "value": 139.805,
        "_internal_originalTime": 1689758683000
    },
    {
        "time": 1689758685000,
        "value": 139.807,
        "_internal_originalTime": 1689758685000
    },
    {
        "time": 1689758686000,
        "value": 139.8,
        "_internal_originalTime": 1689758686000
    },
    {
        "time": 1689758687000,
        "value": 139.802,
        "_internal_originalTime": 1689758687000
    },
    {
        "time": 1689758688000,
        "value": 139.806,
        "_internal_originalTime": 1689758688000
    },
    {
        "time": 1689758690000,
        "value": 139.805,
        "_internal_originalTime": 1689758690000
    },
    {
        "time": 1689758691000,
        "value": 139.804,
        "_internal_originalTime": 1689758691000
    },
    {
        "time": 1689758692000,
        "value": 139.804,
        "_internal_originalTime": 1689758692000
    },
    {
        "time": 1689758693000,
        "value": 139.804,
        "_internal_originalTime": 1689758693000
    },
    {
        "time": 1689758694000,
        "value": 139.805,
        "_internal_originalTime": 1689758694000
    },
    {
        "time": 1689758696000,
        "value": 139.801,
        "_internal_originalTime": 1689758696000
    },
    {
        "time": 1689758697000,
        "value": 139.802,
        "_internal_originalTime": 1689758697000
    },
    {
        "time": 1689758698000,
        "value": 139.805,
        "_internal_originalTime": 1689758698000
    },
    {
        "time": 1689758699000,
        "value": 139.802,
        "_internal_originalTime": 1689758699000
    },
    {
        "time": 1689758700000,
        "value": 139.814,
        "_internal_originalTime": 1689758700000
    },
    {
        "time": 1689758701000,
        "value": 139.815,
        "_internal_originalTime": 1689758701000
    },
    {
        "time": 1689758702000,
        "value": 139.819,
        "_internal_originalTime": 1689758702000
    },
    {
        "time": 1689758703000,
        "value": 139.818,
        "_internal_originalTime": 1689758703000
    },
    {
        "time": 1689758704000,
        "value": 139.81,
        "_internal_originalTime": 1689758704000
    },
    {
        "time": 1689758705000,
        "value": 139.804,
        "_internal_originalTime": 1689758705000
    },
    {
        "time": 1689758706000,
        "value": 139.81,
        "_internal_originalTime": 1689758706000
    },
    {
        "time": 1689758708000,
        "value": 139.805,
        "_internal_originalTime": 1689758708000
    },
    {
        "time": 1689758709000,
        "value": 139.811,
        "_internal_originalTime": 1689758709000
    },
    {
        "time": 1689758710000,
        "value": 139.811,
        "_internal_originalTime": 1689758710000
    },
    {
        "time": 1689758711000,
        "value": 139.808,
        "_internal_originalTime": 1689758711000
    },
    {
        "time": 1689758712000,
        "value": 139.81,
        "_internal_originalTime": 1689758712000
    },
    {
        "time": 1689758714000,
        "value": 139.811,
        "_internal_originalTime": 1689758714000
    },
    {
        "time": 1689758717000,
        "value": 139.816,
        "_internal_originalTime": 1689758717000
    },
    {
        "time": 1689758718000,
        "value": 139.818,
        "_internal_originalTime": 1689758718000
    },
    {
        "time": 1689758719000,
        "value": 139.818,
        "_internal_originalTime": 1689758719000
    },
    {
        "time": 1689758720000,
        "value": 139.819,
        "_internal_originalTime": 1689758720000
    },
    {
        "time": 1689758722000,
        "value": 139.818,
        "_internal_originalTime": 1689758722000
    },
    {
        "time": 1689758724000,
        "value": 139.823,
        "_internal_originalTime": 1689758724000
    },
    {
        "time": 1689758725000,
        "value": 139.818,
        "_internal_originalTime": 1689758725000
    },
    {
        "time": 1689758727000,
        "value": 139.822,
        "_internal_originalTime": 1689758727000
    },
    {
        "time": 1689758728000,
        "value": 139.821,
        "_internal_originalTime": 1689758728000
    },
    {
        "time": 1689758730000,
        "value": 139.82,
        "_internal_originalTime": 1689758730000
    },
    {
        "time": 1689758731000,
        "value": 139.824,
        "_internal_originalTime": 1689758731000
    },
    {
        "time": 1689758732000,
        "value": 139.823,
        "_internal_originalTime": 1689758732000
    },
    {
        "time": 1689758733000,
        "value": 139.822,
        "_internal_originalTime": 1689758733000
    },
    {
        "time": 1689758734000,
        "value": 139.819,
        "_internal_originalTime": 1689758734000
    },
    {
        "time": 1689758736000,
        "value": 139.819,
        "_internal_originalTime": 1689758736000
    },
    {
        "time": 1689758737000,
        "value": 139.819,
        "_internal_originalTime": 1689758737000
    },
    {
        "time": 1689758738000,
        "value": 139.821,
        "_internal_originalTime": 1689758738000
    },
    {
        "time": 1689758740000,
        "value": 139.823,
        "_internal_originalTime": 1689758740000
    },
    {
        "time": 1689758742000,
        "value": 139.82,
        "_internal_originalTime": 1689758742000
    },
    {
        "time": 1689758743000,
        "value": 139.819,
        "_internal_originalTime": 1689758743000
    },
    {
        "time": 1689758745000,
        "value": 139.815,
        "_internal_originalTime": 1689758745000
    },
    {
        "time": 1689758746000,
        "value": 139.82,
        "_internal_originalTime": 1689758746000
    },
    {
        "time": 1689758748000,
        "value": 139.819,
        "_internal_originalTime": 1689758748000
    },
    {
        "time": 1689758750000,
        "value": 139.824,
        "_internal_originalTime": 1689758750000
    },
    {
        "time": 1689758751000,
        "value": 139.836,
        "_internal_originalTime": 1689758751000
    },
    {
        "time": 1689758752000,
        "value": 139.834,
        "_internal_originalTime": 1689758752000
    },
    {
        "time": 1689758753000,
        "value": 139.841,
        "_internal_originalTime": 1689758753000
    },
    {
        "time": 1689758754000,
        "value": 139.844,
        "_internal_originalTime": 1689758754000
    },
    {
        "time": 1689758756000,
        "value": 139.844,
        "_internal_originalTime": 1689758756000
    },
    {
        "time": 1689758757000,
        "value": 139.843,
        "_internal_originalTime": 1689758757000
    },
    {
        "time": 1689758758000,
        "value": 139.847,
        "_internal_originalTime": 1689758758000
    },
    {
        "time": 1689758759000,
        "value": 139.846,
        "_internal_originalTime": 1689758759000
    },
    {
        "time": 1689758760000,
        "value": 139.842,
        "_internal_originalTime": 1689758760000
    },
    {
        "time": 1689758761000,
        "value": 139.84,
        "_internal_originalTime": 1689758761000
    },
    {
        "time": 1689758762000,
        "value": 139.841,
        "_internal_originalTime": 1689758762000
    },
    {
        "time": 1689758764000,
        "value": 139.846,
        "_internal_originalTime": 1689758764000
    },
    {
        "time": 1689758765000,
        "value": 139.846,
        "_internal_originalTime": 1689758765000
    },
    {
        "time": 1689758766000,
        "value": 139.841,
        "_internal_originalTime": 1689758766000
    },
    {
        "time": 1689758767000,
        "value": 139.842,
        "_internal_originalTime": 1689758767000
    },
    {
        "time": 1689758768000,
        "value": 139.839,
        "_internal_originalTime": 1689758768000
    },
    {
        "time": 1689758769000,
        "value": 139.838,
        "_internal_originalTime": 1689758769000
    },
    {
        "time": 1689758771000,
        "value": 139.839,
        "_internal_originalTime": 1689758771000
    },
    {
        "time": 1689758772000,
        "value": 139.838,
        "_internal_originalTime": 1689758772000
    },
    {
        "time": 1689758773000,
        "value": 139.838,
        "_internal_originalTime": 1689758773000
    },
    {
        "time": 1689758775000,
        "value": 139.842,
        "_internal_originalTime": 1689758775000
    },
    {
        "time": 1689758778000,
        "value": 139.838,
        "_internal_originalTime": 1689758778000
    },
    {
        "time": 1689758780000,
        "value": 139.838,
        "_internal_originalTime": 1689758780000
    },
    {
        "time": 1689758781000,
        "value": 139.843,
        "_internal_originalTime": 1689758781000
    },
    {
        "time": 1689758782000,
        "value": 139.839,
        "_internal_originalTime": 1689758782000
    },
    {
        "time": 1689758783000,
        "value": 139.842,
        "_internal_originalTime": 1689758783000
    },
    {
        "time": 1689758784000,
        "value": 139.836,
        "_internal_originalTime": 1689758784000
    },
    {
        "time": 1689758786000,
        "value": 139.834,
        "_internal_originalTime": 1689758786000
    },
    {
        "time": 1689758788000,
        "value": 139.821,
        "_internal_originalTime": 1689758788000
    },
    {
        "time": 1689758789000,
        "value": 139.821,
        "_internal_originalTime": 1689758789000
    },
    {
        "time": 1689758790000,
        "value": 139.822,
        "_internal_originalTime": 1689758790000
    },
    {
        "time": 1689758791000,
        "value": 139.827,
        "_internal_originalTime": 1689758791000
    },
    {
        "time": 1689758792000,
        "value": 139.832,
        "_internal_originalTime": 1689758792000
    },
    {
        "time": 1689758793000,
        "value": 139.832,
        "_internal_originalTime": 1689758793000
    },
    {
        "time": 1689758795000,
        "value": 139.828,
        "_internal_originalTime": 1689758795000
    },
    {
        "time": 1689758796000,
        "value": 139.832,
        "_internal_originalTime": 1689758796000
    },
    {
        "time": 1689758797000,
        "value": 139.83,
        "_internal_originalTime": 1689758797000
    },
    {
        "time": 1689758798000,
        "value": 139.836,
        "_internal_originalTime": 1689758798000
    },
    {
        "time": 1689758800000,
        "value": 139.832,
        "_internal_originalTime": 1689758800000
    },
    {
        "time": 1689758801000,
        "value": 139.843,
        "_internal_originalTime": 1689758801000
    },
    {
        "time": 1689758804000,
        "value": 139.843,
        "_internal_originalTime": 1689758804000
    },
    {
        "time": 1689758806000,
        "value": 139.84,
        "_internal_originalTime": 1689758806000
    },
    {
        "time": 1689758807000,
        "value": 139.839,
        "_internal_originalTime": 1689758807000
    },
    {
        "time": 1689758808000,
        "value": 139.844,
        "_internal_originalTime": 1689758808000
    },
    {
        "time": 1689758809000,
        "value": 139.842,
        "_internal_originalTime": 1689758809000
    },
    {
        "time": 1689758810000,
        "value": 139.841,
        "_internal_originalTime": 1689758810000
    },
    {
        "time": 1689758811000,
        "value": 139.839,
        "_internal_originalTime": 1689758811000
    },
    {
        "time": 1689758812000,
        "value": 139.844,
        "_internal_originalTime": 1689758812000
    },
    {
        "time": 1689758814000,
        "value": 139.845,
        "_internal_originalTime": 1689758814000
    },
    {
        "time": 1689758815000,
        "value": 139.845,
        "_internal_originalTime": 1689758815000
    },
    {
        "time": 1689758816000,
        "value": 139.838,
        "_internal_originalTime": 1689758816000
    },
    {
        "time": 1689758817000,
        "value": 139.841,
        "_internal_originalTime": 1689758817000
    },
    {
        "time": 1689758819000,
        "value": 139.841,
        "_internal_originalTime": 1689758819000
    },
    {
        "time": 1689758820000,
        "value": 139.842,
        "_internal_originalTime": 1689758820000
    },
    {
        "time": 1689758822000,
        "value": 139.837,
        "_internal_originalTime": 1689758822000
    },
    {
        "time": 1689758824000,
        "value": 139.845,
        "_internal_originalTime": 1689758824000
    },
    {
        "time": 1689758826000,
        "value": 139.838,
        "_internal_originalTime": 1689758826000
    },
    {
        "time": 1689758827000,
        "value": 139.843,
        "_internal_originalTime": 1689758827000
    },
    {
        "time": 1689758828000,
        "value": 139.839,
        "_internal_originalTime": 1689758828000
    },
    {
        "time": 1689758830000,
        "value": 139.843,
        "_internal_originalTime": 1689758830000
    },
    {
        "time": 1689758831000,
        "value": 139.843,
        "_internal_originalTime": 1689758831000
    },
    {
        "time": 1689758832000,
        "value": 139.842,
        "_internal_originalTime": 1689758832000
    },
    {
        "time": 1689758834000,
        "value": 139.843,
        "_internal_originalTime": 1689758834000
    },
    {
        "time": 1689758837000,
        "value": 139.843,
        "_internal_originalTime": 1689758837000
    },
    {
        "time": 1689758838000,
        "value": 139.844,
        "_internal_originalTime": 1689758838000
    },
    {
        "time": 1689758839000,
        "value": 139.84,
        "_internal_originalTime": 1689758839000
    },
    {
        "time": 1689758840000,
        "value": 139.84,
        "_internal_originalTime": 1689758840000
    },
    {
        "time": 1689758841000,
        "value": 139.842,
        "_internal_originalTime": 1689758841000
    },
    {
        "time": 1689758843000,
        "value": 139.842,
        "_internal_originalTime": 1689758843000
    },
    {
        "time": 1689758845000,
        "value": 139.841,
        "_internal_originalTime": 1689758845000
    },
    {
        "time": 1689758847000,
        "value": 139.843,
        "_internal_originalTime": 1689758847000
    },
    {
        "time": 1689758848000,
        "value": 139.84,
        "_internal_originalTime": 1689758848000
    },
    {
        "time": 1689758849000,
        "value": 139.841,
        "_internal_originalTime": 1689758849000
    },
    {
        "time": 1689758850000,
        "value": 139.842,
        "_internal_originalTime": 1689758850000
    },
    {
        "time": 1689758851000,
        "value": 139.841,
        "_internal_originalTime": 1689758851000
    },
    {
        "time": 1689758852000,
        "value": 139.842,
        "_internal_originalTime": 1689758852000
    },
    {
        "time": 1689758853000,
        "value": 139.842,
        "_internal_originalTime": 1689758853000
    },
    {
        "time": 1689758854000,
        "value": 139.839,
        "_internal_originalTime": 1689758854000
    },
    {
        "time": 1689758856000,
        "value": 139.842,
        "_internal_originalTime": 1689758856000
    },
    {
        "time": 1689758857000,
        "value": 139.843,
        "_internal_originalTime": 1689758857000
    },
    {
        "time": 1689758859000,
        "value": 139.848,
        "_internal_originalTime": 1689758859000
    },
    {
        "time": 1689758860000,
        "value": 139.842,
        "_internal_originalTime": 1689758860000
    },
    {
        "time": 1689758861000,
        "value": 139.84,
        "_internal_originalTime": 1689758861000
    },
    {
        "time": 1689758862000,
        "value": 139.84,
        "_internal_originalTime": 1689758862000
    },
    {
        "time": 1689758864000,
        "value": 139.845,
        "_internal_originalTime": 1689758864000
    },
    {
        "time": 1689758865000,
        "value": 139.846,
        "_internal_originalTime": 1689758865000
    },
    {
        "time": 1689758867000,
        "value": 139.842,
        "_internal_originalTime": 1689758867000
    },
    {
        "time": 1689758868000,
        "value": 139.839,
        "_internal_originalTime": 1689758868000
    },
    {
        "time": 1689758869000,
        "value": 139.838,
        "_internal_originalTime": 1689758869000
    },
    {
        "time": 1689758870000,
        "value": 139.84,
        "_internal_originalTime": 1689758870000
    },
    {
        "time": 1689758871000,
        "value": 139.84,
        "_internal_originalTime": 1689758871000
    },
    {
        "time": 1689758872000,
        "value": 139.842,
        "_internal_originalTime": 1689758872000
    },
    {
        "time": 1689758874000,
        "value": 139.839,
        "_internal_originalTime": 1689758874000
    },
    {
        "time": 1689758875000,
        "value": 139.841,
        "_internal_originalTime": 1689758875000
    },
    {
        "time": 1689758876000,
        "value": 139.841,
        "_internal_originalTime": 1689758876000
    },
    {
        "time": 1689758879000,
        "value": 139.844,
        "_internal_originalTime": 1689758879000
    },
    {
        "time": 1689758880000,
        "value": 139.844,
        "_internal_originalTime": 1689758880000
    },
    {
        "time": 1689758882000,
        "value": 139.84,
        "_internal_originalTime": 1689758882000
    },
    {
        "time": 1689758883000,
        "value": 139.84,
        "_internal_originalTime": 1689758883000
    },
    {
        "time": 1689758884000,
        "value": 139.844,
        "_internal_originalTime": 1689758884000
    },
    {
        "time": 1689758885000,
        "value": 139.844,
        "_internal_originalTime": 1689758885000
    },
    {
        "time": 1689758886000,
        "value": 139.854,
        "_internal_originalTime": 1689758886000
    },
    {
        "time": 1689758887000,
        "value": 139.856,
        "_internal_originalTime": 1689758887000
    },
    {
        "time": 1689758888000,
        "value": 139.864,
        "_internal_originalTime": 1689758888000
    },
    {
        "time": 1689758889000,
        "value": 139.867,
        "_internal_originalTime": 1689758889000
    },
    {
        "time": 1689758890000,
        "value": 139.868,
        "_internal_originalTime": 1689758890000
    },
    {
        "time": 1689758892000,
        "value": 139.867,
        "_internal_originalTime": 1689758892000
    },
    {
        "time": 1689758893000,
        "value": 139.87,
        "_internal_originalTime": 1689758893000
    },
    {
        "time": 1689758895000,
        "value": 139.864,
        "_internal_originalTime": 1689758895000
    },
    {
        "time": 1689758896000,
        "value": 139.868,
        "_internal_originalTime": 1689758896000
    },
    {
        "time": 1689758897000,
        "value": 139.871,
        "_internal_originalTime": 1689758897000
    },
    {
        "time": 1689758898000,
        "value": 139.869,
        "_internal_originalTime": 1689758898000
    },
    {
        "time": 1689758900000,
        "value": 139.869,
        "_internal_originalTime": 1689758900000
    },
    {
        "time": 1689758901000,
        "value": 139.88,
        "_internal_originalTime": 1689758901000
    },
    {
        "time": 1689758902000,
        "value": 139.88,
        "_internal_originalTime": 1689758902000
    },
    {
        "time": 1689758903000,
        "value": 139.879,
        "_internal_originalTime": 1689758903000
    },
    {
        "time": 1689758904000,
        "value": 139.882,
        "_internal_originalTime": 1689758904000
    },
    {
        "time": 1689758906000,
        "value": 139.882,
        "_internal_originalTime": 1689758906000
    },
    {
        "time": 1689758907000,
        "value": 139.882,
        "_internal_originalTime": 1689758907000
    },
    {
        "time": 1689758908000,
        "value": 139.881,
        "_internal_originalTime": 1689758908000
    },
    {
        "time": 1689758909000,
        "value": 139.882,
        "_internal_originalTime": 1689758909000
    },
    {
        "time": 1689758910000,
        "value": 139.882,
        "_internal_originalTime": 1689758910000
    },
    {
        "time": 1689758911000,
        "value": 139.878,
        "_internal_originalTime": 1689758911000
    },
    {
        "time": 1689758912000,
        "value": 139.879,
        "_internal_originalTime": 1689758912000
    },
    {
        "time": 1689758913000,
        "value": 139.879,
        "_internal_originalTime": 1689758913000
    },
    {
        "time": 1689758914000,
        "value": 139.878,
        "_internal_originalTime": 1689758914000
    },
    {
        "time": 1689758916000,
        "value": 139.88,
        "_internal_originalTime": 1689758916000
    },
    {
        "time": 1689758917000,
        "value": 139.879,
        "_internal_originalTime": 1689758917000
    },
    {
        "time": 1689758918000,
        "value": 139.88,
        "_internal_originalTime": 1689758918000
    },
    {
        "time": 1689758919000,
        "value": 139.88,
        "_internal_originalTime": 1689758919000
    },
    {
        "time": 1689758921000,
        "value": 139.881,
        "_internal_originalTime": 1689758921000
    },
    {
        "time": 1689758922000,
        "value": 139.878,
        "_internal_originalTime": 1689758922000
    },
    {
        "time": 1689758923000,
        "value": 139.878,
        "_internal_originalTime": 1689758923000
    },
    {
        "time": 1689758925000,
        "value": 139.883,
        "_internal_originalTime": 1689758925000
    },
    {
        "time": 1689758926000,
        "value": 139.886,
        "_internal_originalTime": 1689758926000
    },
    {
        "time": 1689758927000,
        "value": 139.883,
        "_internal_originalTime": 1689758927000
    },
    {
        "time": 1689758928000,
        "value": 139.885,
        "_internal_originalTime": 1689758928000
    },
    {
        "time": 1689758929000,
        "value": 139.881,
        "_internal_originalTime": 1689758929000
    },
    {
        "time": 1689758930000,
        "value": 139.88,
        "_internal_originalTime": 1689758930000
    },
    {
        "time": 1689758931000,
        "value": 139.881,
        "_internal_originalTime": 1689758931000
    },
    {
        "time": 1689758932000,
        "value": 139.88,
        "_internal_originalTime": 1689758932000
    },
    {
        "time": 1689758933000,
        "value": 139.882,
        "_internal_originalTime": 1689758933000
    },
    {
        "time": 1689758935000,
        "value": 139.885,
        "_internal_originalTime": 1689758935000
    },
    {
        "time": 1689758936000,
        "value": 139.888,
        "_internal_originalTime": 1689758936000
    },
    {
        "time": 1689758937000,
        "value": 139.883,
        "_internal_originalTime": 1689758937000
    },
    {
        "time": 1689758939000,
        "value": 139.883,
        "_internal_originalTime": 1689758939000
    },
    {
        "time": 1689758940000,
        "value": 139.884,
        "_internal_originalTime": 1689758940000
    },
    {
        "time": 1689758942000,
        "value": 139.883,
        "_internal_originalTime": 1689758942000
    },
    {
        "time": 1689758943000,
        "value": 139.878,
        "_internal_originalTime": 1689758943000
    },
    {
        "time": 1689758944000,
        "value": 139.885,
        "_internal_originalTime": 1689758944000
    },
    {
        "time": 1689758945000,
        "value": 139.886,
        "_internal_originalTime": 1689758945000
    },
    {
        "time": 1689758946000,
        "value": 139.884,
        "_internal_originalTime": 1689758946000
    },
    {
        "time": 1689758948000,
        "value": 139.887,
        "_internal_originalTime": 1689758948000
    },
    {
        "time": 1689758949000,
        "value": 139.885,
        "_internal_originalTime": 1689758949000
    },
    {
        "time": 1689758950000,
        "value": 139.884,
        "_internal_originalTime": 1689758950000
    },
    {
        "time": 1689758951000,
        "value": 139.885,
        "_internal_originalTime": 1689758951000
    },
    {
        "time": 1689758952000,
        "value": 139.885,
        "_internal_originalTime": 1689758952000
    },
    {
        "time": 1689758954000,
        "value": 139.882,
        "_internal_originalTime": 1689758954000
    },
    {
        "time": 1689758955000,
        "value": 139.878,
        "_internal_originalTime": 1689758955000
    },
    {
        "time": 1689758956000,
        "value": 139.881,
        "_internal_originalTime": 1689758956000
    },
    {
        "time": 1689758958000,
        "value": 139.882,
        "_internal_originalTime": 1689758958000
    },
    {
        "time": 1689758959000,
        "value": 139.879,
        "_internal_originalTime": 1689758959000
    },
    {
        "time": 1689758960000,
        "value": 139.876,
        "_internal_originalTime": 1689758960000
    },
    {
        "time": 1689758961000,
        "value": 139.882,
        "_internal_originalTime": 1689758961000
    },
    {
        "time": 1689758962000,
        "value": 139.882,
        "_internal_originalTime": 1689758962000
    },
    {
        "time": 1689758963000,
        "value": 139.881,
        "_internal_originalTime": 1689758963000
    },
    {
        "time": 1689758965000,
        "value": 139.879,
        "_internal_originalTime": 1689758965000
    },
    {
        "time": 1689758966000,
        "value": 139.877,
        "_internal_originalTime": 1689758966000
    },
    {
        "time": 1689758967000,
        "value": 139.878,
        "_internal_originalTime": 1689758967000
    },
    {
        "time": 1689758968000,
        "value": 139.882,
        "_internal_originalTime": 1689758968000
    },
    {
        "time": 1689758969000,
        "value": 139.883,
        "_internal_originalTime": 1689758969000
    },
    {
        "time": 1689758970000,
        "value": 139.883,
        "_internal_originalTime": 1689758970000
    },
    {
        "time": 1689758971000,
        "value": 139.878,
        "_internal_originalTime": 1689758971000
    },
    {
        "time": 1689758972000,
        "value": 139.879,
        "_internal_originalTime": 1689758972000
    },
    {
        "time": 1689758974000,
        "value": 139.883,
        "_internal_originalTime": 1689758974000
    },
    {
        "time": 1689758975000,
        "value": 139.88,
        "_internal_originalTime": 1689758975000
    },
    {
        "time": 1689758976000,
        "value": 139.882,
        "_internal_originalTime": 1689758976000
    },
    {
        "time": 1689758978000,
        "value": 139.881,
        "_internal_originalTime": 1689758978000
    },
    {
        "time": 1689758980000,
        "value": 139.876,
        "_internal_originalTime": 1689758980000
    },
    {
        "time": 1689758981000,
        "value": 139.877,
        "_internal_originalTime": 1689758981000
    },
    {
        "time": 1689758982000,
        "value": 139.878,
        "_internal_originalTime": 1689758982000
    },
    {
        "time": 1689758984000,
        "value": 139.88,
        "_internal_originalTime": 1689758984000
    },
    {
        "time": 1689758985000,
        "value": 139.877,
        "_internal_originalTime": 1689758985000
    },
    {
        "time": 1689758986000,
        "value": 139.877,
        "_internal_originalTime": 1689758986000
    },
    {
        "time": 1689758987000,
        "value": 139.879,
        "_internal_originalTime": 1689758987000
    },
    {
        "time": 1689758989000,
        "value": 139.876,
        "_internal_originalTime": 1689758989000
    },
    {
        "time": 1689758990000,
        "value": 139.874,
        "_internal_originalTime": 1689758990000
    },
    {
        "time": 1689758991000,
        "value": 139.877,
        "_internal_originalTime": 1689758991000
    },
    {
        "time": 1689758992000,
        "value": 139.874,
        "_internal_originalTime": 1689758992000
    },
    {
        "time": 1689758993000,
        "value": 139.879,
        "_internal_originalTime": 1689758993000
    },
    {
        "time": 1689758995000,
        "value": 139.875,
        "_internal_originalTime": 1689758995000
    },
    {
        "time": 1689758996000,
        "value": 139.876,
        "_internal_originalTime": 1689758996000
    },
    {
        "time": 1689758997000,
        "value": 139.869,
        "_internal_originalTime": 1689758997000
    },
    {
        "time": 1689758999000,
        "value": 139.871,
        "_internal_originalTime": 1689758999000
    },
    {
        "time": 1689759000000,
        "value": 139.864,
        "_internal_originalTime": 1689759000000
    },
    {
        "time": 1689759001000,
        "value": 139.868,
        "_internal_originalTime": 1689759001000
    },
    {
        "time": 1689759002000,
        "value": 139.87,
        "_internal_originalTime": 1689759002000
    },
    {
        "time": 1689759003000,
        "value": 139.869,
        "_internal_originalTime": 1689759003000
    },
    {
        "time": 1689759005000,
        "value": 139.869,
        "_internal_originalTime": 1689759005000
    },
    {
        "time": 1689759006000,
        "value": 139.866,
        "_internal_originalTime": 1689759006000
    },
    {
        "time": 1689759008000,
        "value": 139.867,
        "_internal_originalTime": 1689759008000
    },
    {
        "time": 1689759009000,
        "value": 139.858,
        "_internal_originalTime": 1689759009000
    },
    {
        "time": 1689759011000,
        "value": 139.862,
        "_internal_originalTime": 1689759011000
    },
    {
        "time": 1689759012000,
        "value": 139.863,
        "_internal_originalTime": 1689759012000
    },
    {
        "time": 1689759013000,
        "value": 139.861,
        "_internal_originalTime": 1689759013000
    },
    {
        "time": 1689759015000,
        "value": 139.859,
        "_internal_originalTime": 1689759015000
    },
    {
        "time": 1689759017000,
        "value": 139.855,
        "_internal_originalTime": 1689759017000
    },
    {
        "time": 1689759018000,
        "value": 139.856,
        "_internal_originalTime": 1689759018000
    },
    {
        "time": 1689759019000,
        "value": 139.858,
        "_internal_originalTime": 1689759019000
    },
    {
        "time": 1689759020000,
        "value": 139.855,
        "_internal_originalTime": 1689759020000
    },
    {
        "time": 1689759021000,
        "value": 139.859,
        "_internal_originalTime": 1689759021000
    },
    {
        "time": 1689759023000,
        "value": 139.857,
        "_internal_originalTime": 1689759023000
    },
    {
        "time": 1689759024000,
        "value": 139.849,
        "_internal_originalTime": 1689759024000
    },
    {
        "time": 1689759025000,
        "value": 139.842,
        "_internal_originalTime": 1689759025000
    },
    {
        "time": 1689759026000,
        "value": 139.846,
        "_internal_originalTime": 1689759026000
    },
    {
        "time": 1689759028000,
        "value": 139.848,
        "_internal_originalTime": 1689759028000
    },
    {
        "time": 1689759029000,
        "value": 139.851,
        "_internal_originalTime": 1689759029000
    },
    {
        "time": 1689759030000,
        "value": 139.848,
        "_internal_originalTime": 1689759030000
    },
    {
        "time": 1689759032000,
        "value": 139.849,
        "_internal_originalTime": 1689759032000
    },
    {
        "time": 1689759033000,
        "value": 139.848,
        "_internal_originalTime": 1689759033000
    },
    {
        "time": 1689759034000,
        "value": 139.846,
        "_internal_originalTime": 1689759034000
    },
    {
        "time": 1689759035000,
        "value": 139.849,
        "_internal_originalTime": 1689759035000
    },
    {
        "time": 1689759036000,
        "value": 139.85,
        "_internal_originalTime": 1689759036000
    },
    {
        "time": 1689759039000,
        "value": 139.849,
        "_internal_originalTime": 1689759039000
    },
    {
        "time": 1689759040000,
        "value": 139.85,
        "_internal_originalTime": 1689759040000
    },
    {
        "time": 1689759042000,
        "value": 139.849,
        "_internal_originalTime": 1689759042000
    },
    {
        "time": 1689759043000,
        "value": 139.842,
        "_internal_originalTime": 1689759043000
    },
    {
        "time": 1689759044000,
        "value": 139.846,
        "_internal_originalTime": 1689759044000
    },
    {
        "time": 1689759045000,
        "value": 139.85,
        "_internal_originalTime": 1689759045000
    },
    {
        "time": 1689759046000,
        "value": 139.852,
        "_internal_originalTime": 1689759046000
    },
    {
        "time": 1689759047000,
        "value": 139.85,
        "_internal_originalTime": 1689759047000
    },
    {
        "time": 1689759048000,
        "value": 139.857,
        "_internal_originalTime": 1689759048000
    },
    {
        "time": 1689759049000,
        "value": 139.858,
        "_internal_originalTime": 1689759049000
    },
    {
        "time": 1689759050000,
        "value": 139.848,
        "_internal_originalTime": 1689759050000
    },
    {
        "time": 1689759051000,
        "value": 139.851,
        "_internal_originalTime": 1689759051000
    },
    {
        "time": 1689759053000,
        "value": 139.847,
        "_internal_originalTime": 1689759053000
    },
    {
        "time": 1689759055000,
        "value": 139.85,
        "_internal_originalTime": 1689759055000
    },
    {
        "time": 1689759056000,
        "value": 139.85,
        "_internal_originalTime": 1689759056000
    },
    {
        "time": 1689759058000,
        "value": 139.842,
        "_internal_originalTime": 1689759058000
    },
    {
        "time": 1689759059000,
        "value": 139.849,
        "_internal_originalTime": 1689759059000
    },
    {
        "time": 1689759061000,
        "value": 139.847,
        "_internal_originalTime": 1689759061000
    },
    {
        "time": 1689759062000,
        "value": 139.847,
        "_internal_originalTime": 1689759062000
    },
    {
        "time": 1689759063000,
        "value": 139.847,
        "_internal_originalTime": 1689759063000
    },
    {
        "time": 1689759064000,
        "value": 139.845,
        "_internal_originalTime": 1689759064000
    },
    {
        "time": 1689759065000,
        "value": 139.849,
        "_internal_originalTime": 1689759065000
    },
    {
        "time": 1689759066000,
        "value": 139.84,
        "_internal_originalTime": 1689759066000
    },
    {
        "time": 1689759067000,
        "value": 139.836,
        "_internal_originalTime": 1689759067000
    },
    {
        "time": 1689759069000,
        "value": 139.84,
        "_internal_originalTime": 1689759069000
    },
    {
        "time": 1689759070000,
        "value": 139.838,
        "_internal_originalTime": 1689759070000
    },
    {
        "time": 1689759071000,
        "value": 139.841,
        "_internal_originalTime": 1689759071000
    },
    {
        "time": 1689759072000,
        "value": 139.841,
        "_internal_originalTime": 1689759072000
    },
    {
        "time": 1689759074000,
        "value": 139.838,
        "_internal_originalTime": 1689759074000
    },
    {
        "time": 1689759075000,
        "value": 139.838,
        "_internal_originalTime": 1689759075000
    },
    {
        "time": 1689759077000,
        "value": 139.839,
        "_internal_originalTime": 1689759077000
    },
    {
        "time": 1689759078000,
        "value": 139.839,
        "_internal_originalTime": 1689759078000
    },
    {
        "time": 1689759079000,
        "value": 139.839,
        "_internal_originalTime": 1689759079000
    },
    {
        "time": 1689759081000,
        "value": 139.839,
        "_internal_originalTime": 1689759081000
    },
    {
        "time": 1689759082000,
        "value": 139.837,
        "_internal_originalTime": 1689759082000
    },
    {
        "time": 1689759083000,
        "value": 139.835,
        "_internal_originalTime": 1689759083000
    },
    {
        "time": 1689759084000,
        "value": 139.834,
        "_internal_originalTime": 1689759084000
    },
    {
        "time": 1689759085000,
        "value": 139.834,
        "_internal_originalTime": 1689759085000
    },
    {
        "time": 1689759087000,
        "value": 139.824,
        "_internal_originalTime": 1689759087000
    },
    {
        "time": 1689759088000,
        "value": 139.83,
        "_internal_originalTime": 1689759088000
    },
    {
        "time": 1689759090000,
        "value": 139.825,
        "_internal_originalTime": 1689759090000
    },
    {
        "time": 1689759091000,
        "value": 139.831,
        "_internal_originalTime": 1689759091000
    },
    {
        "time": 1689759092000,
        "value": 139.824,
        "_internal_originalTime": 1689759092000
    },
    {
        "time": 1689759093000,
        "value": 139.826,
        "_internal_originalTime": 1689759093000
    },
    {
        "time": 1689759094000,
        "value": 139.815,
        "_internal_originalTime": 1689759094000
    },
    {
        "time": 1689759096000,
        "value": 139.833,
        "_internal_originalTime": 1689759096000
    },
    {
        "time": 1689759097000,
        "value": 139.834,
        "_internal_originalTime": 1689759097000
    },
    {
        "time": 1689759098000,
        "value": 139.833,
        "_internal_originalTime": 1689759098000
    },
    {
        "time": 1689759099000,
        "value": 139.833,
        "_internal_originalTime": 1689759099000
    },
    {
        "time": 1689759101000,
        "value": 139.833,
        "_internal_originalTime": 1689759101000
    },
    {
        "time": 1689759102000,
        "value": 139.828,
        "_internal_originalTime": 1689759102000
    },
    {
        "time": 1689759103000,
        "value": 139.826,
        "_internal_originalTime": 1689759103000
    },
    {
        "time": 1689759105000,
        "value": 139.829,
        "_internal_originalTime": 1689759105000
    },
    {
        "time": 1689759106000,
        "value": 139.83,
        "_internal_originalTime": 1689759106000
    },
    {
        "time": 1689759107000,
        "value": 139.83,
        "_internal_originalTime": 1689759107000
    },
    {
        "time": 1689759108000,
        "value": 139.835,
        "_internal_originalTime": 1689759108000
    },
    {
        "time": 1689759109000,
        "value": 139.834,
        "_internal_originalTime": 1689759109000
    },
    {
        "time": 1689759110000,
        "value": 139.836,
        "_internal_originalTime": 1689759110000
    },
    {
        "time": 1689759112000,
        "value": 139.83,
        "_internal_originalTime": 1689759112000
    },
    {
        "time": 1689759114000,
        "value": 139.837,
        "_internal_originalTime": 1689759114000
    },
    {
        "time": 1689759117000,
        "value": 139.833,
        "_internal_originalTime": 1689759117000
    },
    {
        "time": 1689759119000,
        "value": 139.833,
        "_internal_originalTime": 1689759119000
    },
    {
        "time": 1689759120000,
        "value": 139.834,
        "_internal_originalTime": 1689759120000
    },
    {
        "time": 1689759122000,
        "value": 139.81,
        "_internal_originalTime": 1689759122000
    },
    {
        "time": 1689759124000,
        "value": 139.829,
        "_internal_originalTime": 1689759124000
    },
    {
        "time": 1689759125000,
        "value": 139.829,
        "_internal_originalTime": 1689759125000
    },
    {
        "time": 1689759126000,
        "value": 139.826,
        "_internal_originalTime": 1689759126000
    },
    {
        "time": 1689759127000,
        "value": 139.827,
        "_internal_originalTime": 1689759127000
    },
    {
        "time": 1689759128000,
        "value": 139.825,
        "_internal_originalTime": 1689759128000
    },
    {
        "time": 1689759131000,
        "value": 139.817,
        "_internal_originalTime": 1689759131000
    },
    {
        "time": 1689759132000,
        "value": 139.814,
        "_internal_originalTime": 1689759132000
    },
    {
        "time": 1689759134000,
        "value": 139.816,
        "_internal_originalTime": 1689759134000
    },
    {
        "time": 1689759135000,
        "value": 139.819,
        "_internal_originalTime": 1689759135000
    },
    {
        "time": 1689759136000,
        "value": 139.821,
        "_internal_originalTime": 1689759136000
    },
    {
        "time": 1689759138000,
        "value": 139.821,
        "_internal_originalTime": 1689759138000
    },
    {
        "time": 1689759139000,
        "value": 139.822,
        "_internal_originalTime": 1689759139000
    },
    {
        "time": 1689759140000,
        "value": 139.82,
        "_internal_originalTime": 1689759140000
    },
    {
        "time": 1689759141000,
        "value": 139.822,
        "_internal_originalTime": 1689759141000
    },
    {
        "time": 1689759142000,
        "value": 139.824,
        "_internal_originalTime": 1689759142000
    },
    {
        "time": 1689759143000,
        "value": 139.824,
        "_internal_originalTime": 1689759143000
    },
    {
        "time": 1689759144000,
        "value": 139.826,
        "_internal_originalTime": 1689759144000
    },
    {
        "time": 1689759146000,
        "value": 139.823,
        "_internal_originalTime": 1689759146000
    },
    {
        "time": 1689759147000,
        "value": 139.818,
        "_internal_originalTime": 1689759147000
    },
    {
        "time": 1689759149000,
        "value": 139.814,
        "_internal_originalTime": 1689759149000
    },
    {
        "time": 1689759150000,
        "value": 139.818,
        "_internal_originalTime": 1689759150000
    },
    {
        "time": 1689759151000,
        "value": 139.82,
        "_internal_originalTime": 1689759151000
    },
    {
        "time": 1689759152000,
        "value": 139.82,
        "_internal_originalTime": 1689759152000
    },
    {
        "time": 1689759153000,
        "value": 139.819,
        "_internal_originalTime": 1689759153000
    },
    {
        "time": 1689759154000,
        "value": 139.814,
        "_internal_originalTime": 1689759154000
    },
    {
        "time": 1689759156000,
        "value": 139.819,
        "_internal_originalTime": 1689759156000
    },
    {
        "time": 1689759157000,
        "value": 139.817,
        "_internal_originalTime": 1689759157000
    },
    {
        "time": 1689759158000,
        "value": 139.819,
        "_internal_originalTime": 1689759158000
    },
    {
        "time": 1689759159000,
        "value": 139.818,
        "_internal_originalTime": 1689759159000
    },
    {
        "time": 1689759160000,
        "value": 139.82,
        "_internal_originalTime": 1689759160000
    },
    {
        "time": 1689759161000,
        "value": 139.82,
        "_internal_originalTime": 1689759161000
    },
    {
        "time": 1689759162000,
        "value": 139.822,
        "_internal_originalTime": 1689759162000
    },
    {
        "time": 1689759164000,
        "value": 139.821,
        "_internal_originalTime": 1689759164000
    },
    {
        "time": 1689759165000,
        "value": 139.822,
        "_internal_originalTime": 1689759165000
    },
    {
        "time": 1689759166000,
        "value": 139.822,
        "_internal_originalTime": 1689759166000
    },
    {
        "time": 1689759168000,
        "value": 139.818,
        "_internal_originalTime": 1689759168000
    },
    {
        "time": 1689759170000,
        "value": 139.818,
        "_internal_originalTime": 1689759170000
    },
    {
        "time": 1689759173000,
        "value": 139.819,
        "_internal_originalTime": 1689759173000
    },
    {
        "time": 1689759175000,
        "value": 139.821,
        "_internal_originalTime": 1689759175000
    },
    {
        "time": 1689759177000,
        "value": 139.823,
        "_internal_originalTime": 1689759177000
    },
    {
        "time": 1689759178000,
        "value": 139.821,
        "_internal_originalTime": 1689759178000
    },
    {
        "time": 1689759179000,
        "value": 139.821,
        "_internal_originalTime": 1689759179000
    },
    {
        "time": 1689759180000,
        "value": 139.8,
        "_internal_originalTime": 1689759180000
    },
    {
        "time": 1689759181000,
        "value": 139.81,
        "_internal_originalTime": 1689759181000
    },
    {
        "time": 1689759182000,
        "value": 139.802,
        "_internal_originalTime": 1689759182000
    },
    {
        "time": 1689759183000,
        "value": 139.798,
        "_internal_originalTime": 1689759183000
    },
    {
        "time": 1689759184000,
        "value": 139.796,
        "_internal_originalTime": 1689759184000
    },
    {
        "time": 1689759186000,
        "value": 139.802,
        "_internal_originalTime": 1689759186000
    },
    {
        "time": 1689759187000,
        "value": 139.797,
        "_internal_originalTime": 1689759187000
    },
    {
        "time": 1689759188000,
        "value": 139.79,
        "_internal_originalTime": 1689759188000
    },
    {
        "time": 1689759192000,
        "value": 139.798,
        "_internal_originalTime": 1689759192000
    },
    {
        "time": 1689759193000,
        "value": 139.802,
        "_internal_originalTime": 1689759193000
    },
    {
        "time": 1689759195000,
        "value": 139.801,
        "_internal_originalTime": 1689759195000
    },
    {
        "time": 1689759196000,
        "value": 139.798,
        "_internal_originalTime": 1689759196000
    },
    {
        "time": 1689759197000,
        "value": 139.802,
        "_internal_originalTime": 1689759197000
    },
    {
        "time": 1689759198000,
        "value": 139.803,
        "_internal_originalTime": 1689759198000
    },
    {
        "time": 1689759199000,
        "value": 139.807,
        "_internal_originalTime": 1689759199000
    },
    {
        "time": 1689759201000,
        "value": 139.804,
        "_internal_originalTime": 1689759201000
    },
    {
        "time": 1689759202000,
        "value": 139.808,
        "_internal_originalTime": 1689759202000
    },
    {
        "time": 1689759203000,
        "value": 139.802,
        "_internal_originalTime": 1689759203000
    },
    {
        "time": 1689759204000,
        "value": 139.803,
        "_internal_originalTime": 1689759204000
    },
    {
        "time": 1689759205000,
        "value": 139.801,
        "_internal_originalTime": 1689759205000
    },
    {
        "time": 1689759207000,
        "value": 139.797,
        "_internal_originalTime": 1689759207000
    },
    {
        "time": 1689759208000,
        "value": 139.799,
        "_internal_originalTime": 1689759208000
    },
    {
        "time": 1689759209000,
        "value": 139.797,
        "_internal_originalTime": 1689759209000
    },
    {
        "time": 1689759210000,
        "value": 139.8,
        "_internal_originalTime": 1689759210000
    },
    {
        "time": 1689759212000,
        "value": 139.796,
        "_internal_originalTime": 1689759212000
    },
    {
        "time": 1689759213000,
        "value": 139.806,
        "_internal_originalTime": 1689759213000
    },
    {
        "time": 1689759214000,
        "value": 139.812,
        "_internal_originalTime": 1689759214000
    },
    {
        "time": 1689759216000,
        "value": 139.808,
        "_internal_originalTime": 1689759216000
    },
    {
        "time": 1689759217000,
        "value": 139.812,
        "_internal_originalTime": 1689759217000
    },
    {
        "time": 1689759218000,
        "value": 139.809,
        "_internal_originalTime": 1689759218000
    },
    {
        "time": 1689759219000,
        "value": 139.809,
        "_internal_originalTime": 1689759219000
    },
    {
        "time": 1689759221000,
        "value": 139.811,
        "_internal_originalTime": 1689759221000
    },
    {
        "time": 1689759222000,
        "value": 139.803,
        "_internal_originalTime": 1689759222000
    },
    {
        "time": 1689759223000,
        "value": 139.803,
        "_internal_originalTime": 1689759223000
    },
    {
        "time": 1689759225000,
        "value": 139.802,
        "_internal_originalTime": 1689759225000
    },
    {
        "time": 1689759226000,
        "value": 139.799,
        "_internal_originalTime": 1689759226000
    },
    {
        "time": 1689759227000,
        "value": 139.8,
        "_internal_originalTime": 1689759227000
    },
    {
        "time": 1689759228000,
        "value": 139.802,
        "_internal_originalTime": 1689759228000
    },
    {
        "time": 1689759231000,
        "value": 139.802,
        "_internal_originalTime": 1689759231000
    },
    {
        "time": 1689759232000,
        "value": 139.804,
        "_internal_originalTime": 1689759232000
    },
    {
        "time": 1689759233000,
        "value": 139.804,
        "_internal_originalTime": 1689759233000
    },
    {
        "time": 1689759234000,
        "value": 139.803,
        "_internal_originalTime": 1689759234000
    },
    {
        "time": 1689759238000,
        "value": 139.802,
        "_internal_originalTime": 1689759238000
    },
    {
        "time": 1689759240000,
        "value": 139.798,
        "_internal_originalTime": 1689759240000
    },
    {
        "time": 1689759241000,
        "value": 139.802,
        "_internal_originalTime": 1689759241000
    },
    {
        "time": 1689759242000,
        "value": 139.806,
        "_internal_originalTime": 1689759242000
    },
    {
        "time": 1689759243000,
        "value": 139.802,
        "_internal_originalTime": 1689759243000
    },
    {
        "time": 1689759244000,
        "value": 139.806,
        "_internal_originalTime": 1689759244000
    },
    {
        "time": 1689759245000,
        "value": 139.809,
        "_internal_originalTime": 1689759245000
    },
    {
        "time": 1689759247000,
        "value": 139.814,
        "_internal_originalTime": 1689759247000
    },
    {
        "time": 1689759248000,
        "value": 139.808,
        "_internal_originalTime": 1689759248000
    },
    {
        "time": 1689759250000,
        "value": 139.812,
        "_internal_originalTime": 1689759250000
    },
    {
        "time": 1689759251000,
        "value": 139.809,
        "_internal_originalTime": 1689759251000
    },
    {
        "time": 1689759252000,
        "value": 139.81,
        "_internal_originalTime": 1689759252000
    },
    {
        "time": 1689759254000,
        "value": 139.807,
        "_internal_originalTime": 1689759254000
    },
    {
        "time": 1689759255000,
        "value": 139.806,
        "_internal_originalTime": 1689759255000
    },
    {
        "time": 1689759257000,
        "value": 139.816,
        "_internal_originalTime": 1689759257000
    },
    {
        "time": 1689759258000,
        "value": 139.814,
        "_internal_originalTime": 1689759258000
    },
    {
        "time": 1689759259000,
        "value": 139.815,
        "_internal_originalTime": 1689759259000
    },
    {
        "time": 1689759260000,
        "value": 139.814,
        "_internal_originalTime": 1689759260000
    },
    {
        "time": 1689759262000,
        "value": 139.821,
        "_internal_originalTime": 1689759262000
    },
    {
        "time": 1689759264000,
        "value": 139.822,
        "_internal_originalTime": 1689759264000
    },
    {
        "time": 1689759265000,
        "value": 139.819,
        "_internal_originalTime": 1689759265000
    },
    {
        "time": 1689759266000,
        "value": 139.819,
        "_internal_originalTime": 1689759266000
    },
    {
        "time": 1689759268000,
        "value": 139.822,
        "_internal_originalTime": 1689759268000
    },
    {
        "time": 1689759269000,
        "value": 139.82,
        "_internal_originalTime": 1689759269000
    },
    {
        "time": 1689759270000,
        "value": 139.82,
        "_internal_originalTime": 1689759270000
    },
    {
        "time": 1689759272000,
        "value": 139.819,
        "_internal_originalTime": 1689759272000
    },
    {
        "time": 1689759273000,
        "value": 139.819,
        "_internal_originalTime": 1689759273000
    },
    {
        "time": 1689759274000,
        "value": 139.819,
        "_internal_originalTime": 1689759274000
    },
    {
        "time": 1689759275000,
        "value": 139.819,
        "_internal_originalTime": 1689759275000
    },
    {
        "time": 1689759277000,
        "value": 139.819,
        "_internal_originalTime": 1689759277000
    },
    {
        "time": 1689759278000,
        "value": 139.822,
        "_internal_originalTime": 1689759278000
    },
    {
        "time": 1689759281000,
        "value": 139.82,
        "_internal_originalTime": 1689759281000
    },
    {
        "time": 1689759282000,
        "value": 139.832,
        "_internal_originalTime": 1689759282000
    },
    {
        "time": 1689759283000,
        "value": 139.829,
        "_internal_originalTime": 1689759283000
    },
    {
        "time": 1689759284000,
        "value": 139.83,
        "_internal_originalTime": 1689759284000
    },
    {
        "time": 1689759287000,
        "value": 139.827,
        "_internal_originalTime": 1689759287000
    },
    {
        "time": 1689759288000,
        "value": 139.828,
        "_internal_originalTime": 1689759288000
    },
    {
        "time": 1689759290000,
        "value": 139.83,
        "_internal_originalTime": 1689759290000
    },
    {
        "time": 1689759291000,
        "value": 139.829,
        "_internal_originalTime": 1689759291000
    },
    {
        "time": 1689759293000,
        "value": 139.829,
        "_internal_originalTime": 1689759293000
    },
    {
        "time": 1689759295000,
        "value": 139.828,
        "_internal_originalTime": 1689759295000
    },
    {
        "time": 1689759296000,
        "value": 139.827,
        "_internal_originalTime": 1689759296000
    },
    {
        "time": 1689759297000,
        "value": 139.832,
        "_internal_originalTime": 1689759297000
    },
    {
        "time": 1689759299000,
        "value": 139.831,
        "_internal_originalTime": 1689759299000
    },
    {
        "time": 1689759300000,
        "value": 139.832,
        "_internal_originalTime": 1689759300000
    },
    {
        "time": 1689759302000,
        "value": 139.833,
        "_internal_originalTime": 1689759302000
    },
    {
        "time": 1689759304000,
        "value": 139.829,
        "_internal_originalTime": 1689759304000
    },
    {
        "time": 1689759305000,
        "value": 139.828,
        "_internal_originalTime": 1689759305000
    },
    {
        "time": 1689759306000,
        "value": 139.828,
        "_internal_originalTime": 1689759306000
    },
    {
        "time": 1689759307000,
        "value": 139.828,
        "_internal_originalTime": 1689759307000
    },
    {
        "time": 1689759308000,
        "value": 139.83,
        "_internal_originalTime": 1689759308000
    },
    {
        "time": 1689759310000,
        "value": 139.829,
        "_internal_originalTime": 1689759310000
    },
    {
        "time": 1689759311000,
        "value": 139.821,
        "_internal_originalTime": 1689759311000
    },
    {
        "time": 1689759314000,
        "value": 139.819,
        "_internal_originalTime": 1689759314000
    },
    {
        "time": 1689759315000,
        "value": 139.818,
        "_internal_originalTime": 1689759315000
    },
    {
        "time": 1689759318000,
        "value": 139.818,
        "_internal_originalTime": 1689759318000
    },
    {
        "time": 1689759319000,
        "value": 139.815,
        "_internal_originalTime": 1689759319000
    },
    {
        "time": 1689759320000,
        "value": 139.815,
        "_internal_originalTime": 1689759320000
    },
    {
        "time": 1689759322000,
        "value": 139.812,
        "_internal_originalTime": 1689759322000
    },
    {
        "time": 1689759323000,
        "value": 139.807,
        "_internal_originalTime": 1689759323000
    },
    {
        "time": 1689759324000,
        "value": 139.809,
        "_internal_originalTime": 1689759324000
    },
    {
        "time": 1689759325000,
        "value": 139.81,
        "_internal_originalTime": 1689759325000
    },
    {
        "time": 1689759326000,
        "value": 139.812,
        "_internal_originalTime": 1689759326000
    },
    {
        "time": 1689759328000,
        "value": 139.812,
        "_internal_originalTime": 1689759328000
    },
    {
        "time": 1689759330000,
        "value": 139.813,
        "_internal_originalTime": 1689759330000
    },
    {
        "time": 1689759332000,
        "value": 139.812,
        "_internal_originalTime": 1689759332000
    },
    {
        "time": 1689759333000,
        "value": 139.81,
        "_internal_originalTime": 1689759333000
    },
    {
        "time": 1689759335000,
        "value": 139.803,
        "_internal_originalTime": 1689759335000
    },
    {
        "time": 1689759337000,
        "value": 139.79,
        "_internal_originalTime": 1689759337000
    },
    {
        "time": 1689759338000,
        "value": 139.795,
        "_internal_originalTime": 1689759338000
    },
    {
        "time": 1689759339000,
        "value": 139.797,
        "_internal_originalTime": 1689759339000
    },
    {
        "time": 1689759341000,
        "value": 139.799,
        "_internal_originalTime": 1689759341000
    },
    {
        "time": 1689759342000,
        "value": 139.801,
        "_internal_originalTime": 1689759342000
    },
    {
        "time": 1689759343000,
        "value": 139.8,
        "_internal_originalTime": 1689759343000
    },
    {
        "time": 1689759345000,
        "value": 139.799,
        "_internal_originalTime": 1689759345000
    },
    {
        "time": 1689759346000,
        "value": 139.792,
        "_internal_originalTime": 1689759346000
    },
    {
        "time": 1689759348000,
        "value": 139.79,
        "_internal_originalTime": 1689759348000
    },
    {
        "time": 1689759349000,
        "value": 139.795,
        "_internal_originalTime": 1689759349000
    },
    {
        "time": 1689759350000,
        "value": 139.796,
        "_internal_originalTime": 1689759350000
    },
    {
        "time": 1689759351000,
        "value": 139.799,
        "_internal_originalTime": 1689759351000
    },
    {
        "time": 1689759353000,
        "value": 139.799,
        "_internal_originalTime": 1689759353000
    },
    {
        "time": 1689759355000,
        "value": 139.8,
        "_internal_originalTime": 1689759355000
    },
    {
        "time": 1689759356000,
        "value": 139.802,
        "_internal_originalTime": 1689759356000
    },
    {
        "time": 1689759357000,
        "value": 139.792,
        "_internal_originalTime": 1689759357000
    },
    {
        "time": 1689759358000,
        "value": 139.792,
        "_internal_originalTime": 1689759358000
    },
    {
        "time": 1689759360000,
        "value": 139.789,
        "_internal_originalTime": 1689759360000
    },
    {
        "time": 1689759361000,
        "value": 139.77,
        "_internal_originalTime": 1689759361000
    },
    {
        "time": 1689759362000,
        "value": 139.791,
        "_internal_originalTime": 1689759362000
    },
    {
        "time": 1689759363000,
        "value": 139.788,
        "_internal_originalTime": 1689759363000
    },
    {
        "time": 1689759365000,
        "value": 139.774,
        "_internal_originalTime": 1689759365000
    },
    {
        "time": 1689759366000,
        "value": 139.773,
        "_internal_originalTime": 1689759366000
    },
    {
        "time": 1689759368000,
        "value": 139.779,
        "_internal_originalTime": 1689759368000
    },
    {
        "time": 1689759369000,
        "value": 139.783,
        "_internal_originalTime": 1689759369000
    },
    {
        "time": 1689759370000,
        "value": 139.781,
        "_internal_originalTime": 1689759370000
    },
    {
        "time": 1689759372000,
        "value": 139.772,
        "_internal_originalTime": 1689759372000
    },
    {
        "time": 1689759374000,
        "value": 139.78,
        "_internal_originalTime": 1689759374000
    },
    {
        "time": 1689759375000,
        "value": 139.78,
        "_internal_originalTime": 1689759375000
    },
    {
        "time": 1689759377000,
        "value": 139.781,
        "_internal_originalTime": 1689759377000
    },
    {
        "time": 1689759378000,
        "value": 139.776,
        "_internal_originalTime": 1689759378000
    },
    {
        "time": 1689759379000,
        "value": 139.781,
        "_internal_originalTime": 1689759379000
    },
    {
        "time": 1689759380000,
        "value": 139.778,
        "_internal_originalTime": 1689759380000
    },
    {
        "time": 1689759381000,
        "value": 139.782,
        "_internal_originalTime": 1689759381000
    },
    {
        "time": 1689759382000,
        "value": 139.783,
        "_internal_originalTime": 1689759382000
    },
    {
        "time": 1689759383000,
        "value": 139.781,
        "_internal_originalTime": 1689759383000
    },
    {
        "time": 1689759385000,
        "value": 139.783,
        "_internal_originalTime": 1689759385000
    },
    {
        "time": 1689759386000,
        "value": 139.788,
        "_internal_originalTime": 1689759386000
    },
    {
        "time": 1689759388000,
        "value": 139.789,
        "_internal_originalTime": 1689759388000
    },
    {
        "time": 1689759389000,
        "value": 139.782,
        "_internal_originalTime": 1689759389000
    },
    {
        "time": 1689759390000,
        "value": 139.784,
        "_internal_originalTime": 1689759390000
    },
    {
        "time": 1689759391000,
        "value": 139.782,
        "_internal_originalTime": 1689759391000
    },
    {
        "time": 1689759392000,
        "value": 139.784,
        "_internal_originalTime": 1689759392000
    },
    {
        "time": 1689759394000,
        "value": 139.778,
        "_internal_originalTime": 1689759394000
    },
    {
        "time": 1689759395000,
        "value": 139.779,
        "_internal_originalTime": 1689759395000
    },
    {
        "time": 1689759396000,
        "value": 139.78,
        "_internal_originalTime": 1689759396000
    },
    {
        "time": 1689759398000,
        "value": 139.783,
        "_internal_originalTime": 1689759398000
    },
    {
        "time": 1689759399000,
        "value": 139.788,
        "_internal_originalTime": 1689759399000
    },
    {
        "time": 1689759400000,
        "value": 139.788,
        "_internal_originalTime": 1689759400000
    },
    {
        "time": 1689759401000,
        "value": 139.792,
        "_internal_originalTime": 1689759401000
    },
    {
        "time": 1689759403000,
        "value": 139.789,
        "_internal_originalTime": 1689759403000
    },
    {
        "time": 1689759404000,
        "value": 139.791,
        "_internal_originalTime": 1689759404000
    },
    {
        "time": 1689759405000,
        "value": 139.79,
        "_internal_originalTime": 1689759405000
    },
    {
        "time": 1689759406000,
        "value": 139.788,
        "_internal_originalTime": 1689759406000
    },
    {
        "time": 1689759407000,
        "value": 139.787,
        "_internal_originalTime": 1689759407000
    },
    {
        "time": 1689759409000,
        "value": 139.792,
        "_internal_originalTime": 1689759409000
    },
    {
        "time": 1689759410000,
        "value": 139.792,
        "_internal_originalTime": 1689759410000
    },
    {
        "time": 1689759411000,
        "value": 139.793,
        "_internal_originalTime": 1689759411000
    },
    {
        "time": 1689759412000,
        "value": 139.797,
        "_internal_originalTime": 1689759412000
    },
    {
        "time": 1689759413000,
        "value": 139.792,
        "_internal_originalTime": 1689759413000
    },
    {
        "time": 1689759415000,
        "value": 139.796,
        "_internal_originalTime": 1689759415000
    },
    {
        "time": 1689759416000,
        "value": 139.795,
        "_internal_originalTime": 1689759416000
    },
    {
        "time": 1689759417000,
        "value": 139.796,
        "_internal_originalTime": 1689759417000
    },
    {
        "time": 1689759418000,
        "value": 139.798,
        "_internal_originalTime": 1689759418000
    },
    {
        "time": 1689759420000,
        "value": 139.8,
        "_internal_originalTime": 1689759420000
    },
    {
        "time": 1689759422000,
        "value": 139.799,
        "_internal_originalTime": 1689759422000
    },
    {
        "time": 1689759423000,
        "value": 139.797,
        "_internal_originalTime": 1689759423000
    },
    {
        "time": 1689759424000,
        "value": 139.792,
        "_internal_originalTime": 1689759424000
    },
    {
        "time": 1689759425000,
        "value": 139.788,
        "_internal_originalTime": 1689759425000
    },
    {
        "time": 1689759426000,
        "value": 139.788,
        "_internal_originalTime": 1689759426000
    },
    {
        "time": 1689759427000,
        "value": 139.786,
        "_internal_originalTime": 1689759427000
    },
    {
        "time": 1689759429000,
        "value": 139.791,
        "_internal_originalTime": 1689759429000
    },
    {
        "time": 1689759430000,
        "value": 139.786,
        "_internal_originalTime": 1689759430000
    },
    {
        "time": 1689759431000,
        "value": 139.791,
        "_internal_originalTime": 1689759431000
    },
    {
        "time": 1689759433000,
        "value": 139.791,
        "_internal_originalTime": 1689759433000
    },
    {
        "time": 1689759434000,
        "value": 139.79,
        "_internal_originalTime": 1689759434000
    },
    {
        "time": 1689759435000,
        "value": 139.791,
        "_internal_originalTime": 1689759435000
    },
    {
        "time": 1689759436000,
        "value": 139.791,
        "_internal_originalTime": 1689759436000
    },
    {
        "time": 1689759438000,
        "value": 139.79,
        "_internal_originalTime": 1689759438000
    },
    {
        "time": 1689759439000,
        "value": 139.792,
        "_internal_originalTime": 1689759439000
    },
    {
        "time": 1689759441000,
        "value": 139.792,
        "_internal_originalTime": 1689759441000
    },
    {
        "time": 1689759442000,
        "value": 139.789,
        "_internal_originalTime": 1689759442000
    },
    {
        "time": 1689759443000,
        "value": 139.792,
        "_internal_originalTime": 1689759443000
    },
    {
        "time": 1689759444000,
        "value": 139.79,
        "_internal_originalTime": 1689759444000
    },
    {
        "time": 1689759446000,
        "value": 139.791,
        "_internal_originalTime": 1689759446000
    },
    {
        "time": 1689759447000,
        "value": 139.791,
        "_internal_originalTime": 1689759447000
    },
    {
        "time": 1689759448000,
        "value": 139.789,
        "_internal_originalTime": 1689759448000
    },
    {
        "time": 1689759449000,
        "value": 139.791,
        "_internal_originalTime": 1689759449000
    },
    {
        "time": 1689759450000,
        "value": 139.787,
        "_internal_originalTime": 1689759450000
    },
    {
        "time": 1689759452000,
        "value": 139.79,
        "_internal_originalTime": 1689759452000
    },
    {
        "time": 1689759453000,
        "value": 139.788,
        "_internal_originalTime": 1689759453000
    },
    {
        "time": 1689759454000,
        "value": 139.789,
        "_internal_originalTime": 1689759454000
    },
    {
        "time": 1689759455000,
        "value": 139.792,
        "_internal_originalTime": 1689759455000
    },
    {
        "time": 1689759457000,
        "value": 139.788,
        "_internal_originalTime": 1689759457000
    },
    {
        "time": 1689759458000,
        "value": 139.792,
        "_internal_originalTime": 1689759458000
    },
    {
        "time": 1689759459000,
        "value": 139.792,
        "_internal_originalTime": 1689759459000
    },
    {
        "time": 1689759461000,
        "value": 139.791,
        "_internal_originalTime": 1689759461000
    },
    {
        "time": 1689759462000,
        "value": 139.791,
        "_internal_originalTime": 1689759462000
    },
    {
        "time": 1689759463000,
        "value": 139.791,
        "_internal_originalTime": 1689759463000
    },
    {
        "time": 1689759465000,
        "value": 139.788,
        "_internal_originalTime": 1689759465000
    },
    {
        "time": 1689759466000,
        "value": 139.79,
        "_internal_originalTime": 1689759466000
    },
    {
        "time": 1689759467000,
        "value": 139.791,
        "_internal_originalTime": 1689759467000
    },
    {
        "time": 1689759469000,
        "value": 139.79,
        "_internal_originalTime": 1689759469000
    },
    {
        "time": 1689759470000,
        "value": 139.791,
        "_internal_originalTime": 1689759470000
    },
    {
        "time": 1689759472000,
        "value": 139.791,
        "_internal_originalTime": 1689759472000
    },
    {
        "time": 1689759474000,
        "value": 139.791,
        "_internal_originalTime": 1689759474000
    },
    {
        "time": 1689759475000,
        "value": 139.786,
        "_internal_originalTime": 1689759475000
    },
    {
        "time": 1689759476000,
        "value": 139.786,
        "_internal_originalTime": 1689759476000
    },
    {
        "time": 1689759477000,
        "value": 139.786,
        "_internal_originalTime": 1689759477000
    },
    {
        "time": 1689759478000,
        "value": 139.789,
        "_internal_originalTime": 1689759478000
    },
    {
        "time": 1689759480000,
        "value": 139.79,
        "_internal_originalTime": 1689759480000
    },
    {
        "time": 1689759482000,
        "value": 139.791,
        "_internal_originalTime": 1689759482000
    },
    {
        "time": 1689759483000,
        "value": 139.791,
        "_internal_originalTime": 1689759483000
    },
    {
        "time": 1689759484000,
        "value": 139.792,
        "_internal_originalTime": 1689759484000
    },
    {
        "time": 1689759485000,
        "value": 139.791,
        "_internal_originalTime": 1689759485000
    },
    {
        "time": 1689759486000,
        "value": 139.791,
        "_internal_originalTime": 1689759486000
    },
    {
        "time": 1689759488000,
        "value": 139.792,
        "_internal_originalTime": 1689759488000
    },
    {
        "time": 1689759489000,
        "value": 139.791,
        "_internal_originalTime": 1689759489000
    },
    {
        "time": 1689759490000,
        "value": 139.789,
        "_internal_originalTime": 1689759490000
    },
    {
        "time": 1689759491000,
        "value": 139.79,
        "_internal_originalTime": 1689759491000
    },
    {
        "time": 1689759493000,
        "value": 139.788,
        "_internal_originalTime": 1689759493000
    },
    {
        "time": 1689759495000,
        "value": 139.79,
        "_internal_originalTime": 1689759495000
    },
    {
        "time": 1689759496000,
        "value": 139.791,
        "_internal_originalTime": 1689759496000
    },
    {
        "time": 1689759497000,
        "value": 139.791,
        "_internal_originalTime": 1689759497000
    },
    {
        "time": 1689759498000,
        "value": 139.79,
        "_internal_originalTime": 1689759498000
    },
    {
        "time": 1689759500000,
        "value": 139.791,
        "_internal_originalTime": 1689759500000
    },
    {
        "time": 1689759502000,
        "value": 139.792,
        "_internal_originalTime": 1689759502000
    },
    {
        "time": 1689759503000,
        "value": 139.793,
        "_internal_originalTime": 1689759503000
    },
    {
        "time": 1689759505000,
        "value": 139.79,
        "_internal_originalTime": 1689759505000
    },
    {
        "time": 1689759506000,
        "value": 139.791,
        "_internal_originalTime": 1689759506000
    },
    {
        "time": 1689759509000,
        "value": 139.792,
        "_internal_originalTime": 1689759509000
    },
    {
        "time": 1689759511000,
        "value": 139.789,
        "_internal_originalTime": 1689759511000
    },
    {
        "time": 1689759513000,
        "value": 139.787,
        "_internal_originalTime": 1689759513000
    },
    {
        "time": 1689759514000,
        "value": 139.799,
        "_internal_originalTime": 1689759514000
    },
    {
        "time": 1689759515000,
        "value": 139.801,
        "_internal_originalTime": 1689759515000
    },
    {
        "time": 1689759516000,
        "value": 139.796,
        "_internal_originalTime": 1689759516000
    },
    {
        "time": 1689759517000,
        "value": 139.797,
        "_internal_originalTime": 1689759517000
    },
    {
        "time": 1689759518000,
        "value": 139.799,
        "_internal_originalTime": 1689759518000
    },
    {
        "time": 1689759520000,
        "value": 139.8,
        "_internal_originalTime": 1689759520000
    },
    {
        "time": 1689759521000,
        "value": 139.802,
        "_internal_originalTime": 1689759521000
    },
    {
        "time": 1689759523000,
        "value": 139.787,
        "_internal_originalTime": 1689759523000
    },
    {
        "time": 1689759524000,
        "value": 139.79,
        "_internal_originalTime": 1689759524000
    },
    {
        "time": 1689759526000,
        "value": 139.788,
        "_internal_originalTime": 1689759526000
    },
    {
        "time": 1689759527000,
        "value": 139.79,
        "_internal_originalTime": 1689759527000
    },
    {
        "time": 1689759529000,
        "value": 139.795,
        "_internal_originalTime": 1689759529000
    },
    {
        "time": 1689759530000,
        "value": 139.794,
        "_internal_originalTime": 1689759530000
    },
    {
        "time": 1689759531000,
        "value": 139.783,
        "_internal_originalTime": 1689759531000
    },
    {
        "time": 1689759532000,
        "value": 139.789,
        "_internal_originalTime": 1689759532000
    },
    {
        "time": 1689759533000,
        "value": 139.788,
        "_internal_originalTime": 1689759533000
    },
    {
        "time": 1689759535000,
        "value": 139.784,
        "_internal_originalTime": 1689759535000
    },
    {
        "time": 1689759536000,
        "value": 139.786,
        "_internal_originalTime": 1689759536000
    },
    {
        "time": 1689759538000,
        "value": 139.784,
        "_internal_originalTime": 1689759538000
    },
    {
        "time": 1689759540000,
        "value": 139.788,
        "_internal_originalTime": 1689759540000
    },
    {
        "time": 1689759541000,
        "value": 139.789,
        "_internal_originalTime": 1689759541000
    },
    {
        "time": 1689759544000,
        "value": 139.792,
        "_internal_originalTime": 1689759544000
    },
    {
        "time": 1689759545000,
        "value": 139.785,
        "_internal_originalTime": 1689759545000
    },
    {
        "time": 1689759547000,
        "value": 139.787,
        "_internal_originalTime": 1689759547000
    },
    {
        "time": 1689759548000,
        "value": 139.784,
        "_internal_originalTime": 1689759548000
    },
    {
        "time": 1689759549000,
        "value": 139.791,
        "_internal_originalTime": 1689759549000
    },
    {
        "time": 1689759550000,
        "value": 139.788,
        "_internal_originalTime": 1689759550000
    },
    {
        "time": 1689759552000,
        "value": 139.791,
        "_internal_originalTime": 1689759552000
    },
    {
        "time": 1689759553000,
        "value": 139.788,
        "_internal_originalTime": 1689759553000
    },
    {
        "time": 1689759554000,
        "value": 139.789,
        "_internal_originalTime": 1689759554000
    },
    {
        "time": 1689759555000,
        "value": 139.789,
        "_internal_originalTime": 1689759555000
    },
    {
        "time": 1689759556000,
        "value": 139.791,
        "_internal_originalTime": 1689759556000
    },
    {
        "time": 1689759558000,
        "value": 139.79,
        "_internal_originalTime": 1689759558000
    },
    {
        "time": 1689759559000,
        "value": 139.791,
        "_internal_originalTime": 1689759559000
    },
    {
        "time": 1689759560000,
        "value": 139.789,
        "_internal_originalTime": 1689759560000
    },
    {
        "time": 1689759561000,
        "value": 139.795,
        "_internal_originalTime": 1689759561000
    },
    {
        "time": 1689759562000,
        "value": 139.79,
        "_internal_originalTime": 1689759562000
    },
    {
        "time": 1689759563000,
        "value": 139.791,
        "_internal_originalTime": 1689759563000
    },
    {
        "time": 1689759564000,
        "value": 139.791,
        "_internal_originalTime": 1689759564000
    },
    {
        "time": 1689759566000,
        "value": 139.79,
        "_internal_originalTime": 1689759566000
    },
    {
        "time": 1689759568000,
        "value": 139.789,
        "_internal_originalTime": 1689759568000
    },
    {
        "time": 1689759569000,
        "value": 139.791,
        "_internal_originalTime": 1689759569000
    },
    {
        "time": 1689759570000,
        "value": 139.79,
        "_internal_originalTime": 1689759570000
    },
    {
        "time": 1689759573000,
        "value": 139.791,
        "_internal_originalTime": 1689759573000
    },
    {
        "time": 1689759575000,
        "value": 139.785,
        "_internal_originalTime": 1689759575000
    },
    {
        "time": 1689759576000,
        "value": 139.788,
        "_internal_originalTime": 1689759576000
    },
    {
        "time": 1689759577000,
        "value": 139.787,
        "_internal_originalTime": 1689759577000
    },
    {
        "time": 1689759579000,
        "value": 139.788,
        "_internal_originalTime": 1689759579000
    },
    {
        "time": 1689759580000,
        "value": 139.79,
        "_internal_originalTime": 1689759580000
    },
    {
        "time": 1689759581000,
        "value": 139.789,
        "_internal_originalTime": 1689759581000
    },
    {
        "time": 1689759583000,
        "value": 139.79,
        "_internal_originalTime": 1689759583000
    },
    {
        "time": 1689759585000,
        "value": 139.788,
        "_internal_originalTime": 1689759585000
    },
    {
        "time": 1689759586000,
        "value": 139.79,
        "_internal_originalTime": 1689759586000
    },
    {
        "time": 1689759587000,
        "value": 139.788,
        "_internal_originalTime": 1689759587000
    },
    {
        "time": 1689759588000,
        "value": 139.792,
        "_internal_originalTime": 1689759588000
    },
    {
        "time": 1689759589000,
        "value": 139.793,
        "_internal_originalTime": 1689759589000
    },
    {
        "time": 1689759590000,
        "value": 139.788,
        "_internal_originalTime": 1689759590000
    },
    {
        "time": 1689759592000,
        "value": 139.791,
        "_internal_originalTime": 1689759592000
    },
    {
        "time": 1689759594000,
        "value": 139.791,
        "_internal_originalTime": 1689759594000
    },
    {
        "time": 1689759595000,
        "value": 139.79,
        "_internal_originalTime": 1689759595000
    },
    {
        "time": 1689759597000,
        "value": 139.787,
        "_internal_originalTime": 1689759597000
    },
    {
        "time": 1689759598000,
        "value": 139.793,
        "_internal_originalTime": 1689759598000
    },
    {
        "time": 1689759599000,
        "value": 139.788,
        "_internal_originalTime": 1689759599000
    },
    {
        "time": 1689759601000,
        "value": 139.791,
        "_internal_originalTime": 1689759601000
    },
    {
        "time": 1689759603000,
        "value": 139.792,
        "_internal_originalTime": 1689759603000
    },
    {
        "time": 1689759605000,
        "value": 139.793,
        "_internal_originalTime": 1689759605000
    },
    {
        "time": 1689759606000,
        "value": 139.789,
        "_internal_originalTime": 1689759606000
    },
    {
        "time": 1689759607000,
        "value": 139.792,
        "_internal_originalTime": 1689759607000
    },
    {
        "time": 1689759609000,
        "value": 139.788,
        "_internal_originalTime": 1689759609000
    },
    {
        "time": 1689759610000,
        "value": 139.798,
        "_internal_originalTime": 1689759610000
    },
    {
        "time": 1689759611000,
        "value": 139.794,
        "_internal_originalTime": 1689759611000
    },
    {
        "time": 1689759613000,
        "value": 139.798,
        "_internal_originalTime": 1689759613000
    },
    {
        "time": 1689759614000,
        "value": 139.798,
        "_internal_originalTime": 1689759614000
    },
    {
        "time": 1689759615000,
        "value": 139.798,
        "_internal_originalTime": 1689759615000
    },
    {
        "time": 1689759616000,
        "value": 139.802,
        "_internal_originalTime": 1689759616000
    },
    {
        "time": 1689759617000,
        "value": 139.8,
        "_internal_originalTime": 1689759617000
    },
    {
        "time": 1689759619000,
        "value": 139.799,
        "_internal_originalTime": 1689759619000
    },
    {
        "time": 1689759620000,
        "value": 139.801,
        "_internal_originalTime": 1689759620000
    },
    {
        "time": 1689759621000,
        "value": 139.79,
        "_internal_originalTime": 1689759621000
    },
    {
        "time": 1689759623000,
        "value": 139.787,
        "_internal_originalTime": 1689759623000
    },
    {
        "time": 1689759625000,
        "value": 139.791,
        "_internal_originalTime": 1689759625000
    },
    {
        "time": 1689759626000,
        "value": 139.788,
        "_internal_originalTime": 1689759626000
    },
    {
        "time": 1689759627000,
        "value": 139.791,
        "_internal_originalTime": 1689759627000
    },
    {
        "time": 1689759628000,
        "value": 139.798,
        "_internal_originalTime": 1689759628000
    },
    {
        "time": 1689759630000,
        "value": 139.793,
        "_internal_originalTime": 1689759630000
    },
    {
        "time": 1689759631000,
        "value": 139.792,
        "_internal_originalTime": 1689759631000
    },
    {
        "time": 1689759632000,
        "value": 139.796,
        "_internal_originalTime": 1689759632000
    },
    {
        "time": 1689759633000,
        "value": 139.797,
        "_internal_originalTime": 1689759633000
    },
    {
        "time": 1689759634000,
        "value": 139.796,
        "_internal_originalTime": 1689759634000
    },
    {
        "time": 1689759635000,
        "value": 139.798,
        "_internal_originalTime": 1689759635000
    },
    {
        "time": 1689759637000,
        "value": 139.798,
        "_internal_originalTime": 1689759637000
    },
    {
        "time": 1689759638000,
        "value": 139.797,
        "_internal_originalTime": 1689759638000
    },
    {
        "time": 1689759639000,
        "value": 139.798,
        "_internal_originalTime": 1689759639000
    },
    {
        "time": 1689759640000,
        "value": 139.801,
        "_internal_originalTime": 1689759640000
    },
    {
        "time": 1689759642000,
        "value": 139.8,
        "_internal_originalTime": 1689759642000
    },
    {
        "time": 1689759643000,
        "value": 139.796,
        "_internal_originalTime": 1689759643000
    },
    {
        "time": 1689759645000,
        "value": 139.801,
        "_internal_originalTime": 1689759645000
    },
    {
        "time": 1689759647000,
        "value": 139.799,
        "_internal_originalTime": 1689759647000
    },
    {
        "time": 1689759649000,
        "value": 139.798,
        "_internal_originalTime": 1689759649000
    },
    {
        "time": 1689759650000,
        "value": 139.798,
        "_internal_originalTime": 1689759650000
    },
    {
        "time": 1689759651000,
        "value": 139.794,
        "_internal_originalTime": 1689759651000
    },
    {
        "time": 1689759652000,
        "value": 139.788,
        "_internal_originalTime": 1689759652000
    },
    {
        "time": 1689759653000,
        "value": 139.79,
        "_internal_originalTime": 1689759653000
    },
    {
        "time": 1689759654000,
        "value": 139.793,
        "_internal_originalTime": 1689759654000
    },
    {
        "time": 1689759655000,
        "value": 139.799,
        "_internal_originalTime": 1689759655000
    },
    {
        "time": 1689759657000,
        "value": 139.799,
        "_internal_originalTime": 1689759657000
    },
    {
        "time": 1689759659000,
        "value": 139.8,
        "_internal_originalTime": 1689759659000
    },
    {
        "time": 1689759660000,
        "value": 139.798,
        "_internal_originalTime": 1689759660000
    },
    {
        "time": 1689759661000,
        "value": 139.796,
        "_internal_originalTime": 1689759661000
    },
    {
        "time": 1689759662000,
        "value": 139.794,
        "_internal_originalTime": 1689759662000
    },
    {
        "time": 1689759663000,
        "value": 139.797,
        "_internal_originalTime": 1689759663000
    },
    {
        "time": 1689759664000,
        "value": 139.798,
        "_internal_originalTime": 1689759664000
    },
    {
        "time": 1689759666000,
        "value": 139.791,
        "_internal_originalTime": 1689759666000
    },
    {
        "time": 1689759667000,
        "value": 139.788,
        "_internal_originalTime": 1689759667000
    },
    {
        "time": 1689759668000,
        "value": 139.786,
        "_internal_originalTime": 1689759668000
    },
    {
        "time": 1689759669000,
        "value": 139.786,
        "_internal_originalTime": 1689759669000
    },
    {
        "time": 1689759671000,
        "value": 139.791,
        "_internal_originalTime": 1689759671000
    },
    {
        "time": 1689759672000,
        "value": 139.786,
        "_internal_originalTime": 1689759672000
    },
    {
        "time": 1689759673000,
        "value": 139.789,
        "_internal_originalTime": 1689759673000
    },
    {
        "time": 1689759674000,
        "value": 139.786,
        "_internal_originalTime": 1689759674000
    },
    {
        "time": 1689759675000,
        "value": 139.789,
        "_internal_originalTime": 1689759675000
    },
    {
        "time": 1689759676000,
        "value": 139.788,
        "_internal_originalTime": 1689759676000
    },
    {
        "time": 1689759679000,
        "value": 139.79,
        "_internal_originalTime": 1689759679000
    },
    {
        "time": 1689759680000,
        "value": 139.79,
        "_internal_originalTime": 1689759680000
    },
    {
        "time": 1689759681000,
        "value": 139.792,
        "_internal_originalTime": 1689759681000
    },
    {
        "time": 1689759682000,
        "value": 139.788,
        "_internal_originalTime": 1689759682000
    },
    {
        "time": 1689759684000,
        "value": 139.788,
        "_internal_originalTime": 1689759684000
    },
    {
        "time": 1689759685000,
        "value": 139.789,
        "_internal_originalTime": 1689759685000
    },
    {
        "time": 1689759686000,
        "value": 139.79,
        "_internal_originalTime": 1689759686000
    },
    {
        "time": 1689759687000,
        "value": 139.79,
        "_internal_originalTime": 1689759687000
    },
    {
        "time": 1689759688000,
        "value": 139.791,
        "_internal_originalTime": 1689759688000
    },
    {
        "time": 1689759690000,
        "value": 139.789,
        "_internal_originalTime": 1689759690000
    },
    {
        "time": 1689759691000,
        "value": 139.788,
        "_internal_originalTime": 1689759691000
    },
    {
        "time": 1689759692000,
        "value": 139.791,
        "_internal_originalTime": 1689759692000
    },
    {
        "time": 1689759693000,
        "value": 139.785,
        "_internal_originalTime": 1689759693000
    },
    {
        "time": 1689759694000,
        "value": 139.784,
        "_internal_originalTime": 1689759694000
    },
    {
        "time": 1689759695000,
        "value": 139.783,
        "_internal_originalTime": 1689759695000
    },
    {
        "time": 1689759697000,
        "value": 139.79,
        "_internal_originalTime": 1689759697000
    },
    {
        "time": 1689759698000,
        "value": 139.79,
        "_internal_originalTime": 1689759698000
    },
    {
        "time": 1689759699000,
        "value": 139.79,
        "_internal_originalTime": 1689759699000
    },
    {
        "time": 1689759701000,
        "value": 139.788,
        "_internal_originalTime": 1689759701000
    },
    {
        "time": 1689759703000,
        "value": 139.791,
        "_internal_originalTime": 1689759703000
    },
    {
        "time": 1689759705000,
        "value": 139.787,
        "_internal_originalTime": 1689759705000
    },
    {
        "time": 1689759706000,
        "value": 139.789,
        "_internal_originalTime": 1689759706000
    },
    {
        "time": 1689759707000,
        "value": 139.789,
        "_internal_originalTime": 1689759707000
    },
    {
        "time": 1689759708000,
        "value": 139.787,
        "_internal_originalTime": 1689759708000
    },
    {
        "time": 1689759710000,
        "value": 139.791,
        "_internal_originalTime": 1689759710000
    },
    {
        "time": 1689759711000,
        "value": 139.791,
        "_internal_originalTime": 1689759711000
    },
    {
        "time": 1689759712000,
        "value": 139.786,
        "_internal_originalTime": 1689759712000
    },
    {
        "time": 1689759714000,
        "value": 139.789,
        "_internal_originalTime": 1689759714000
    },
    {
        "time": 1689759716000,
        "value": 139.79,
        "_internal_originalTime": 1689759716000
    },
    {
        "time": 1689759717000,
        "value": 139.786,
        "_internal_originalTime": 1689759717000
    },
    {
        "time": 1689759720000,
        "value": 139.788,
        "_internal_originalTime": 1689759720000
    },
    {
        "time": 1689759722000,
        "value": 139.789,
        "_internal_originalTime": 1689759722000
    },
    {
        "time": 1689759723000,
        "value": 139.789,
        "_internal_originalTime": 1689759723000
    },
    {
        "time": 1689759725000,
        "value": 139.783,
        "_internal_originalTime": 1689759725000
    },
    {
        "time": 1689759727000,
        "value": 139.791,
        "_internal_originalTime": 1689759727000
    },
    {
        "time": 1689759728000,
        "value": 139.789,
        "_internal_originalTime": 1689759728000
    },
    {
        "time": 1689759729000,
        "value": 139.791,
        "_internal_originalTime": 1689759729000
    },
    {
        "time": 1689759731000,
        "value": 139.788,
        "_internal_originalTime": 1689759731000
    },
    {
        "time": 1689759733000,
        "value": 139.789,
        "_internal_originalTime": 1689759733000
    },
    {
        "time": 1689759738000,
        "value": 139.788,
        "_internal_originalTime": 1689759738000
    },
    {
        "time": 1689759739000,
        "value": 139.792,
        "_internal_originalTime": 1689759739000
    },
    {
        "time": 1689759740000,
        "value": 139.79,
        "_internal_originalTime": 1689759740000
    },
    {
        "time": 1689759741000,
        "value": 139.788,
        "_internal_originalTime": 1689759741000
    },
    {
        "time": 1689759742000,
        "value": 139.789,
        "_internal_originalTime": 1689759742000
    },
    {
        "time": 1689759744000,
        "value": 139.789,
        "_internal_originalTime": 1689759744000
    },
    {
        "time": 1689759746000,
        "value": 139.788,
        "_internal_originalTime": 1689759746000
    },
    {
        "time": 1689759747000,
        "value": 139.791,
        "_internal_originalTime": 1689759747000
    },
    {
        "time": 1689759749000,
        "value": 139.784,
        "_internal_originalTime": 1689759749000
    },
    {
        "time": 1689759750000,
        "value": 139.785,
        "_internal_originalTime": 1689759750000
    },
    {
        "time": 1689759751000,
        "value": 139.789,
        "_internal_originalTime": 1689759751000
    },
    {
        "time": 1689759752000,
        "value": 139.787,
        "_internal_originalTime": 1689759752000
    },
    {
        "time": 1689759753000,
        "value": 139.788,
        "_internal_originalTime": 1689759753000
    },
    {
        "time": 1689759755000,
        "value": 139.786,
        "_internal_originalTime": 1689759755000
    },
    {
        "time": 1689759756000,
        "value": 139.787,
        "_internal_originalTime": 1689759756000
    },
    {
        "time": 1689759757000,
        "value": 139.787,
        "_internal_originalTime": 1689759757000
    },
    {
        "time": 1689759758000,
        "value": 139.785,
        "_internal_originalTime": 1689759758000
    },
    {
        "time": 1689759760000,
        "value": 139.785,
        "_internal_originalTime": 1689759760000
    },
    {
        "time": 1689759761000,
        "value": 139.787,
        "_internal_originalTime": 1689759761000
    },
    {
        "time": 1689759762000,
        "value": 139.788,
        "_internal_originalTime": 1689759762000
    },
    {
        "time": 1689759765000,
        "value": 139.784,
        "_internal_originalTime": 1689759765000
    },
    {
        "time": 1689759766000,
        "value": 139.782,
        "_internal_originalTime": 1689759766000
    },
    {
        "time": 1689759768000,
        "value": 139.776,
        "_internal_originalTime": 1689759768000
    },
    {
        "time": 1689759770000,
        "value": 139.781,
        "_internal_originalTime": 1689759770000
    },
    {
        "time": 1689759771000,
        "value": 139.779,
        "_internal_originalTime": 1689759771000
    },
    {
        "time": 1689759773000,
        "value": 139.778,
        "_internal_originalTime": 1689759773000
    },
    {
        "time": 1689759775000,
        "value": 139.768,
        "_internal_originalTime": 1689759775000
    },
    {
        "time": 1689759776000,
        "value": 139.768,
        "_internal_originalTime": 1689759776000
    },
    {
        "time": 1689759779000,
        "value": 139.77,
        "_internal_originalTime": 1689759779000
    },
    {
        "time": 1689759781000,
        "value": 139.764,
        "_internal_originalTime": 1689759781000
    },
    {
        "time": 1689759782000,
        "value": 139.768,
        "_internal_originalTime": 1689759782000
    },
    {
        "time": 1689759784000,
        "value": 139.767,
        "_internal_originalTime": 1689759784000
    },
    {
        "time": 1689759785000,
        "value": 139.768,
        "_internal_originalTime": 1689759785000
    },
    {
        "time": 1689759786000,
        "value": 139.767,
        "_internal_originalTime": 1689759786000
    },
    {
        "time": 1689759787000,
        "value": 139.769,
        "_internal_originalTime": 1689759787000
    },
    {
        "time": 1689759789000,
        "value": 139.77,
        "_internal_originalTime": 1689759789000
    },
    {
        "time": 1689759790000,
        "value": 139.767,
        "_internal_originalTime": 1689759790000
    },
    {
        "time": 1689759792000,
        "value": 139.76,
        "_internal_originalTime": 1689759792000
    },
    {
        "time": 1689759793000,
        "value": 139.77,
        "_internal_originalTime": 1689759793000
    },
    {
        "time": 1689759794000,
        "value": 139.764,
        "_internal_originalTime": 1689759794000
    },
    {
        "time": 1689759796000,
        "value": 139.767,
        "_internal_originalTime": 1689759796000
    },
    {
        "time": 1689759798000,
        "value": 139.77,
        "_internal_originalTime": 1689759798000
    },
    {
        "time": 1689759799000,
        "value": 139.77,
        "_internal_originalTime": 1689759799000
    },
    {
        "time": 1689759801000,
        "value": 139.769,
        "_internal_originalTime": 1689759801000
    },
    {
        "time": 1689759802000,
        "value": 139.773,
        "_internal_originalTime": 1689759802000
    },
    {
        "time": 1689759803000,
        "value": 139.77,
        "_internal_originalTime": 1689759803000
    },
    {
        "time": 1689759804000,
        "value": 139.77,
        "_internal_originalTime": 1689759804000
    },
    {
        "time": 1689759805000,
        "value": 139.77,
        "_internal_originalTime": 1689759805000
    },
    {
        "time": 1689759807000,
        "value": 139.774,
        "_internal_originalTime": 1689759807000
    },
    {
        "time": 1689759808000,
        "value": 139.768,
        "_internal_originalTime": 1689759808000
    },
    {
        "time": 1689759811000,
        "value": 139.772,
        "_internal_originalTime": 1689759811000
    },
    {
        "time": 1689759812000,
        "value": 139.768,
        "_internal_originalTime": 1689759812000
    },
    {
        "time": 1689759813000,
        "value": 139.769,
        "_internal_originalTime": 1689759813000
    },
    {
        "time": 1689759814000,
        "value": 139.765,
        "_internal_originalTime": 1689759814000
    },
    {
        "time": 1689759816000,
        "value": 139.769,
        "_internal_originalTime": 1689759816000
    },
    {
        "time": 1689759817000,
        "value": 139.764,
        "_internal_originalTime": 1689759817000
    },
    {
        "time": 1689759818000,
        "value": 139.769,
        "_internal_originalTime": 1689759818000
    },
    {
        "time": 1689759820000,
        "value": 139.768,
        "_internal_originalTime": 1689759820000
    },
    {
        "time": 1689759821000,
        "value": 139.771,
        "_internal_originalTime": 1689759821000
    },
    {
        "time": 1689759822000,
        "value": 139.772,
        "_internal_originalTime": 1689759822000
    },
    {
        "time": 1689759823000,
        "value": 139.768,
        "_internal_originalTime": 1689759823000
    },
    {
        "time": 1689759825000,
        "value": 139.767,
        "_internal_originalTime": 1689759825000
    },
    {
        "time": 1689759826000,
        "value": 139.768,
        "_internal_originalTime": 1689759826000
    },
    {
        "time": 1689759827000,
        "value": 139.767,
        "_internal_originalTime": 1689759827000
    },
    {
        "time": 1689759828000,
        "value": 139.764,
        "_internal_originalTime": 1689759828000
    },
    {
        "time": 1689759830000,
        "value": 139.758,
        "_internal_originalTime": 1689759830000
    },
    {
        "time": 1689759831000,
        "value": 139.756,
        "_internal_originalTime": 1689759831000
    },
    {
        "time": 1689759832000,
        "value": 139.756,
        "_internal_originalTime": 1689759832000
    },
    {
        "time": 1689759833000,
        "value": 139.759,
        "_internal_originalTime": 1689759833000
    },
    {
        "time": 1689759834000,
        "value": 139.758,
        "_internal_originalTime": 1689759834000
    },
    {
        "time": 1689759836000,
        "value": 139.757,
        "_internal_originalTime": 1689759836000
    },
    {
        "time": 1689759837000,
        "value": 139.759,
        "_internal_originalTime": 1689759837000
    },
    {
        "time": 1689759839000,
        "value": 139.759,
        "_internal_originalTime": 1689759839000
    },
    {
        "time": 1689759841000,
        "value": 139.759,
        "_internal_originalTime": 1689759841000
    },
    {
        "time": 1689759843000,
        "value": 139.761,
        "_internal_originalTime": 1689759843000
    },
    {
        "time": 1689759845000,
        "value": 139.756,
        "_internal_originalTime": 1689759845000
    },
    {
        "time": 1689759847000,
        "value": 139.761,
        "_internal_originalTime": 1689759847000
    },
    {
        "time": 1689759848000,
        "value": 139.76,
        "_internal_originalTime": 1689759848000
    },
    {
        "time": 1689759849000,
        "value": 139.757,
        "_internal_originalTime": 1689759849000
    },
    {
        "time": 1689759851000,
        "value": 139.759,
        "_internal_originalTime": 1689759851000
    },
    {
        "time": 1689759852000,
        "value": 139.761,
        "_internal_originalTime": 1689759852000
    },
    {
        "time": 1689759853000,
        "value": 139.763,
        "_internal_originalTime": 1689759853000
    },
    {
        "time": 1689759855000,
        "value": 139.777,
        "_internal_originalTime": 1689759855000
    },
    {
        "time": 1689759857000,
        "value": 139.769,
        "_internal_originalTime": 1689759857000
    },
    {
        "time": 1689759858000,
        "value": 139.77,
        "_internal_originalTime": 1689759858000
    },
    {
        "time": 1689759859000,
        "value": 139.768,
        "_internal_originalTime": 1689759859000
    },
    {
        "time": 1689759860000,
        "value": 139.769,
        "_internal_originalTime": 1689759860000
    },
    {
        "time": 1689759861000,
        "value": 139.769,
        "_internal_originalTime": 1689759861000
    },
    {
        "time": 1689759863000,
        "value": 139.763,
        "_internal_originalTime": 1689759863000
    },
    {
        "time": 1689759864000,
        "value": 139.769,
        "_internal_originalTime": 1689759864000
    },
    {
        "time": 1689759865000,
        "value": 139.768,
        "_internal_originalTime": 1689759865000
    },
    {
        "time": 1689759866000,
        "value": 139.77,
        "_internal_originalTime": 1689759866000
    },
    {
        "time": 1689759868000,
        "value": 139.766,
        "_internal_originalTime": 1689759868000
    },
    {
        "time": 1689759869000,
        "value": 139.766,
        "_internal_originalTime": 1689759869000
    },
    {
        "time": 1689759871000,
        "value": 139.77,
        "_internal_originalTime": 1689759871000
    },
    {
        "time": 1689759873000,
        "value": 139.767,
        "_internal_originalTime": 1689759873000
    },
    {
        "time": 1689759874000,
        "value": 139.773,
        "_internal_originalTime": 1689759874000
    },
    {
        "time": 1689759875000,
        "value": 139.767,
        "_internal_originalTime": 1689759875000
    },
    {
        "time": 1689759876000,
        "value": 139.771,
        "_internal_originalTime": 1689759876000
    },
    {
        "time": 1689759877000,
        "value": 139.771,
        "_internal_originalTime": 1689759877000
    },
    {
        "time": 1689759880000,
        "value": 139.772,
        "_internal_originalTime": 1689759880000
    },
    {
        "time": 1689759881000,
        "value": 139.777,
        "_internal_originalTime": 1689759881000
    },
    {
        "time": 1689759882000,
        "value": 139.777,
        "_internal_originalTime": 1689759882000
    },
    {
        "time": 1689759883000,
        "value": 139.779,
        "_internal_originalTime": 1689759883000
    },
    {
        "time": 1689759884000,
        "value": 139.781,
        "_internal_originalTime": 1689759884000
    },
    {
        "time": 1689759885000,
        "value": 139.782,
        "_internal_originalTime": 1689759885000
    },
    {
        "time": 1689759886000,
        "value": 139.777,
        "_internal_originalTime": 1689759886000
    },
    {
        "time": 1689759887000,
        "value": 139.778,
        "_internal_originalTime": 1689759887000
    },
    {
        "time": 1689759888000,
        "value": 139.779,
        "_internal_originalTime": 1689759888000
    },
    {
        "time": 1689759890000,
        "value": 139.782,
        "_internal_originalTime": 1689759890000
    },
    {
        "time": 1689759892000,
        "value": 139.778,
        "_internal_originalTime": 1689759892000
    },
    {
        "time": 1689759894000,
        "value": 139.778,
        "_internal_originalTime": 1689759894000
    },
    {
        "time": 1689759895000,
        "value": 139.781,
        "_internal_originalTime": 1689759895000
    },
    {
        "time": 1689759897000,
        "value": 139.782,
        "_internal_originalTime": 1689759897000
    },
    {
        "time": 1689759898000,
        "value": 139.782,
        "_internal_originalTime": 1689759898000
    },
    {
        "time": 1689759900000,
        "value": 139.783,
        "_internal_originalTime": 1689759900000
    },
    {
        "time": 1689759901000,
        "value": 139.787,
        "_internal_originalTime": 1689759901000
    },
    {
        "time": 1689759902000,
        "value": 139.787,
        "_internal_originalTime": 1689759902000
    },
    {
        "time": 1689759903000,
        "value": 139.787,
        "_internal_originalTime": 1689759903000
    },
    {
        "time": 1689759905000,
        "value": 139.79,
        "_internal_originalTime": 1689759905000
    },
    {
        "time": 1689759906000,
        "value": 139.79,
        "_internal_originalTime": 1689759906000
    },
    {
        "time": 1689759907000,
        "value": 139.786,
        "_internal_originalTime": 1689759907000
    },
    {
        "time": 1689759908000,
        "value": 139.789,
        "_internal_originalTime": 1689759908000
    },
    {
        "time": 1689759909000,
        "value": 139.787,
        "_internal_originalTime": 1689759909000
    },
    {
        "time": 1689759911000,
        "value": 139.786,
        "_internal_originalTime": 1689759911000
    },
    {
        "time": 1689759912000,
        "value": 139.788,
        "_internal_originalTime": 1689759912000
    },
    {
        "time": 1689759913000,
        "value": 139.788,
        "_internal_originalTime": 1689759913000
    },
    {
        "time": 1689759915000,
        "value": 139.789,
        "_internal_originalTime": 1689759915000
    },
    {
        "time": 1689759917000,
        "value": 139.79,
        "_internal_originalTime": 1689759917000
    },
    {
        "time": 1689759918000,
        "value": 139.786,
        "_internal_originalTime": 1689759918000
    },
    {
        "time": 1689759919000,
        "value": 139.791,
        "_internal_originalTime": 1689759919000
    },
    {
        "time": 1689759921000,
        "value": 139.791,
        "_internal_originalTime": 1689759921000
    },
    {
        "time": 1689759923000,
        "value": 139.788,
        "_internal_originalTime": 1689759923000
    },
    {
        "time": 1689759924000,
        "value": 139.788,
        "_internal_originalTime": 1689759924000
    },
    {
        "time": 1689759925000,
        "value": 139.79,
        "_internal_originalTime": 1689759925000
    },
    {
        "time": 1689759927000,
        "value": 139.79,
        "_internal_originalTime": 1689759927000
    },
    {
        "time": 1689759928000,
        "value": 139.791,
        "_internal_originalTime": 1689759928000
    },
    {
        "time": 1689759930000,
        "value": 139.794,
        "_internal_originalTime": 1689759930000
    },
    {
        "time": 1689759931000,
        "value": 139.789,
        "_internal_originalTime": 1689759931000
    },
    {
        "time": 1689759932000,
        "value": 139.79,
        "_internal_originalTime": 1689759932000
    },
    {
        "time": 1689759935000,
        "value": 139.786,
        "_internal_originalTime": 1689759935000
    },
    {
        "time": 1689759936000,
        "value": 139.785,
        "_internal_originalTime": 1689759936000
    },
    {
        "time": 1689759938000,
        "value": 139.789,
        "_internal_originalTime": 1689759938000
    },
    {
        "time": 1689759939000,
        "value": 139.792,
        "_internal_originalTime": 1689759939000
    },
    {
        "time": 1689759940000,
        "value": 139.793,
        "_internal_originalTime": 1689759940000
    },
    {
        "time": 1689759941000,
        "value": 139.796,
        "_internal_originalTime": 1689759941000
    },
    {
        "time": 1689759942000,
        "value": 139.788,
        "_internal_originalTime": 1689759942000
    },
    {
        "time": 1689759943000,
        "value": 139.789,
        "_internal_originalTime": 1689759943000
    },
    {
        "time": 1689759945000,
        "value": 139.789,
        "_internal_originalTime": 1689759945000
    },
    {
        "time": 1689759946000,
        "value": 139.785,
        "_internal_originalTime": 1689759946000
    },
    {
        "time": 1689759947000,
        "value": 139.784,
        "_internal_originalTime": 1689759947000
    },
    {
        "time": 1689759949000,
        "value": 139.788,
        "_internal_originalTime": 1689759949000
    },
    {
        "time": 1689759951000,
        "value": 139.788,
        "_internal_originalTime": 1689759951000
    },
    {
        "time": 1689759952000,
        "value": 139.788,
        "_internal_originalTime": 1689759952000
    },
    {
        "time": 1689759954000,
        "value": 139.78,
        "_internal_originalTime": 1689759954000
    },
    {
        "time": 1689759955000,
        "value": 139.779,
        "_internal_originalTime": 1689759955000
    },
    {
        "time": 1689759956000,
        "value": 139.775,
        "_internal_originalTime": 1689759956000
    },
    {
        "time": 1689759957000,
        "value": 139.779,
        "_internal_originalTime": 1689759957000
    },
    {
        "time": 1689759959000,
        "value": 139.779,
        "_internal_originalTime": 1689759959000
    },
    {
        "time": 1689759960000,
        "value": 139.778,
        "_internal_originalTime": 1689759960000
    },
    {
        "time": 1689759961000,
        "value": 139.778,
        "_internal_originalTime": 1689759961000
    },
    {
        "time": 1689759962000,
        "value": 139.78,
        "_internal_originalTime": 1689759962000
    },
    {
        "time": 1689759963000,
        "value": 139.776,
        "_internal_originalTime": 1689759963000
    },
    {
        "time": 1689759965000,
        "value": 139.775,
        "_internal_originalTime": 1689759965000
    },
    {
        "time": 1689759966000,
        "value": 139.781,
        "_internal_originalTime": 1689759966000
    },
    {
        "time": 1689759968000,
        "value": 139.78,
        "_internal_originalTime": 1689759968000
    },
    {
        "time": 1689759971000,
        "value": 139.779,
        "_internal_originalTime": 1689759971000
    },
    {
        "time": 1689759972000,
        "value": 139.781,
        "_internal_originalTime": 1689759972000
    },
    {
        "time": 1689759973000,
        "value": 139.777,
        "_internal_originalTime": 1689759973000
    },
    {
        "time": 1689759975000,
        "value": 139.78,
        "_internal_originalTime": 1689759975000
    },
    {
        "time": 1689759976000,
        "value": 139.782,
        "_internal_originalTime": 1689759976000
    },
    {
        "time": 1689759977000,
        "value": 139.778,
        "_internal_originalTime": 1689759977000
    },
    {
        "time": 1689759979000,
        "value": 139.779,
        "_internal_originalTime": 1689759979000
    },
    {
        "time": 1689759980000,
        "value": 139.776,
        "_internal_originalTime": 1689759980000
    },
    {
        "time": 1689759982000,
        "value": 139.776,
        "_internal_originalTime": 1689759982000
    },
    {
        "time": 1689759983000,
        "value": 139.779,
        "_internal_originalTime": 1689759983000
    },
    {
        "time": 1689759984000,
        "value": 139.779,
        "_internal_originalTime": 1689759984000
    },
    {
        "time": 1689759986000,
        "value": 139.78,
        "_internal_originalTime": 1689759986000
    },
    {
        "time": 1689759987000,
        "value": 139.774,
        "_internal_originalTime": 1689759987000
    },
    {
        "time": 1689759988000,
        "value": 139.772,
        "_internal_originalTime": 1689759988000
    },
    {
        "time": 1689759990000,
        "value": 139.776,
        "_internal_originalTime": 1689759990000
    },
    {
        "time": 1689759991000,
        "value": 139.783,
        "_internal_originalTime": 1689759991000
    },
    {
        "time": 1689759992000,
        "value": 139.776,
        "_internal_originalTime": 1689759992000
    },
    {
        "time": 1689759993000,
        "value": 139.779,
        "_internal_originalTime": 1689759993000
    },
    {
        "time": 1689759994000,
        "value": 139.78,
        "_internal_originalTime": 1689759994000
    },
    {
        "time": 1689759996000,
        "value": 139.782,
        "_internal_originalTime": 1689759996000
    },
    {
        "time": 1689759997000,
        "value": 139.781,
        "_internal_originalTime": 1689759997000
    },
    {
        "time": 1689759998000,
        "value": 139.78,
        "_internal_originalTime": 1689759998000
    },
    {
        "time": 1689759999000,
        "value": 139.778,
        "_internal_originalTime": 1689759999000
    },
    {
        "time": 1689760001000,
        "value": 139.78,
        "_internal_originalTime": 1689760001000
    },
    {
        "time": 1689760002000,
        "value": 139.776,
        "_internal_originalTime": 1689760002000
    },
    {
        "time": 1689760003000,
        "value": 139.777,
        "_internal_originalTime": 1689760003000
    },
    {
        "time": 1689760005000,
        "value": 139.781,
        "_internal_originalTime": 1689760005000
    },
    {
        "time": 1689760006000,
        "value": 139.78,
        "_internal_originalTime": 1689760006000
    },
    {
        "time": 1689760008000,
        "value": 139.783,
        "_internal_originalTime": 1689760008000
    },
    {
        "time": 1689760009000,
        "value": 139.789,
        "_internal_originalTime": 1689760009000
    },
    {
        "time": 1689760010000,
        "value": 139.789,
        "_internal_originalTime": 1689760010000
    },
    {
        "time": 1689760013000,
        "value": 139.788,
        "_internal_originalTime": 1689760013000
    },
    {
        "time": 1689760014000,
        "value": 139.787,
        "_internal_originalTime": 1689760014000
    },
    {
        "time": 1689760015000,
        "value": 139.786,
        "_internal_originalTime": 1689760015000
    },
    {
        "time": 1689760017000,
        "value": 139.788,
        "_internal_originalTime": 1689760017000
    },
    {
        "time": 1689760018000,
        "value": 139.788,
        "_internal_originalTime": 1689760018000
    },
    {
        "time": 1689760019000,
        "value": 139.789,
        "_internal_originalTime": 1689760019000
    },
    {
        "time": 1689760020000,
        "value": 139.788,
        "_internal_originalTime": 1689760020000
    },
    {
        "time": 1689760021000,
        "value": 139.789,
        "_internal_originalTime": 1689760021000
    },
    {
        "time": 1689760022000,
        "value": 139.79,
        "_internal_originalTime": 1689760022000
    },
    {
        "time": 1689760024000,
        "value": 139.788,
        "_internal_originalTime": 1689760024000
    },
    {
        "time": 1689760025000,
        "value": 139.783,
        "_internal_originalTime": 1689760025000
    },
    {
        "time": 1689760026000,
        "value": 139.782,
        "_internal_originalTime": 1689760026000
    },
    {
        "time": 1689760027000,
        "value": 139.777,
        "_internal_originalTime": 1689760027000
    },
    {
        "time": 1689760028000,
        "value": 139.777,
        "_internal_originalTime": 1689760028000
    },
    {
        "time": 1689760030000,
        "value": 139.774,
        "_internal_originalTime": 1689760030000
    },
    {
        "time": 1689760031000,
        "value": 139.764,
        "_internal_originalTime": 1689760031000
    },
    {
        "time": 1689760032000,
        "value": 139.771,
        "_internal_originalTime": 1689760032000
    },
    {
        "time": 1689760034000,
        "value": 139.77,
        "_internal_originalTime": 1689760034000
    },
    {
        "time": 1689760035000,
        "value": 139.772,
        "_internal_originalTime": 1689760035000
    },
    {
        "time": 1689760037000,
        "value": 139.768,
        "_internal_originalTime": 1689760037000
    },
    {
        "time": 1689760038000,
        "value": 139.778,
        "_internal_originalTime": 1689760038000
    },
    {
        "time": 1689760040000,
        "value": 139.779,
        "_internal_originalTime": 1689760040000
    },
    {
        "time": 1689760041000,
        "value": 139.776,
        "_internal_originalTime": 1689760041000
    },
    {
        "time": 1689760042000,
        "value": 139.778,
        "_internal_originalTime": 1689760042000
    },
    {
        "time": 1689760044000,
        "value": 139.781,
        "_internal_originalTime": 1689760044000
    },
    {
        "time": 1689760045000,
        "value": 139.778,
        "_internal_originalTime": 1689760045000
    },
    {
        "time": 1689760046000,
        "value": 139.781,
        "_internal_originalTime": 1689760046000
    },
    {
        "time": 1689760048000,
        "value": 139.772,
        "_internal_originalTime": 1689760048000
    },
    {
        "time": 1689760049000,
        "value": 139.777,
        "_internal_originalTime": 1689760049000
    },
    {
        "time": 1689760051000,
        "value": 139.769,
        "_internal_originalTime": 1689760051000
    },
    {
        "time": 1689760052000,
        "value": 139.765,
        "_internal_originalTime": 1689760052000
    },
    {
        "time": 1689760053000,
        "value": 139.764,
        "_internal_originalTime": 1689760053000
    },
    {
        "time": 1689760054000,
        "value": 139.766,
        "_internal_originalTime": 1689760054000
    },
    {
        "time": 1689760055000,
        "value": 139.766,
        "_internal_originalTime": 1689760055000
    },
    {
        "time": 1689760058000,
        "value": 139.768,
        "_internal_originalTime": 1689760058000
    },
    {
        "time": 1689760061000,
        "value": 139.771,
        "_internal_originalTime": 1689760061000
    },
    {
        "time": 1689760062000,
        "value": 139.775,
        "_internal_originalTime": 1689760062000
    },
    {
        "time": 1689760063000,
        "value": 139.772,
        "_internal_originalTime": 1689760063000
    },
    {
        "time": 1689760065000,
        "value": 139.765,
        "_internal_originalTime": 1689760065000
    },
    {
        "time": 1689760066000,
        "value": 139.766,
        "_internal_originalTime": 1689760066000
    },
    {
        "time": 1689760068000,
        "value": 139.766,
        "_internal_originalTime": 1689760068000
    },
    {
        "time": 1689760071000,
        "value": 139.769,
        "_internal_originalTime": 1689760071000
    },
    {
        "time": 1689760072000,
        "value": 139.767,
        "_internal_originalTime": 1689760072000
    },
    {
        "time": 1689760074000,
        "value": 139.769,
        "_internal_originalTime": 1689760074000
    },
    {
        "time": 1689760075000,
        "value": 139.77,
        "_internal_originalTime": 1689760075000
    },
    {
        "time": 1689760076000,
        "value": 139.767,
        "_internal_originalTime": 1689760076000
    },
    {
        "time": 1689760077000,
        "value": 139.77,
        "_internal_originalTime": 1689760077000
    },
    {
        "time": 1689760079000,
        "value": 139.772,
        "_internal_originalTime": 1689760079000
    },
    {
        "time": 1689760081000,
        "value": 139.771,
        "_internal_originalTime": 1689760081000
    },
    {
        "time": 1689760082000,
        "value": 139.767,
        "_internal_originalTime": 1689760082000
    },
    {
        "time": 1689760083000,
        "value": 139.766,
        "_internal_originalTime": 1689760083000
    },
    {
        "time": 1689760085000,
        "value": 139.768,
        "_internal_originalTime": 1689760085000
    },
    {
        "time": 1689760088000,
        "value": 139.766,
        "_internal_originalTime": 1689760088000
    },
    {
        "time": 1689760089000,
        "value": 139.766,
        "_internal_originalTime": 1689760089000
    },
    {
        "time": 1689760091000,
        "value": 139.772,
        "_internal_originalTime": 1689760091000
    },
    {
        "time": 1689760095000,
        "value": 139.77,
        "_internal_originalTime": 1689760095000
    },
    {
        "time": 1689760098000,
        "value": 139.772,
        "_internal_originalTime": 1689760098000
    },
    {
        "time": 1689760099000,
        "value": 139.772,
        "_internal_originalTime": 1689760099000
    },
    {
        "time": 1689760100000,
        "value": 139.767,
        "_internal_originalTime": 1689760100000
    },
    {
        "time": 1689760102000,
        "value": 139.768,
        "_internal_originalTime": 1689760102000
    },
    {
        "time": 1689760105000,
        "value": 139.771,
        "_internal_originalTime": 1689760105000
    },
    {
        "time": 1689760106000,
        "value": 139.772,
        "_internal_originalTime": 1689760106000
    },
    {
        "time": 1689760108000,
        "value": 139.77,
        "_internal_originalTime": 1689760108000
    },
    {
        "time": 1689760109000,
        "value": 139.774,
        "_internal_originalTime": 1689760109000
    },
    {
        "time": 1689760110000,
        "value": 139.768,
        "_internal_originalTime": 1689760110000
    },
    {
        "time": 1689760111000,
        "value": 139.771,
        "_internal_originalTime": 1689760111000
    },
    {
        "time": 1689760113000,
        "value": 139.769,
        "_internal_originalTime": 1689760113000
    },
    {
        "time": 1689760114000,
        "value": 139.77,
        "_internal_originalTime": 1689760114000
    },
    {
        "time": 1689760115000,
        "value": 139.767,
        "_internal_originalTime": 1689760115000
    },
    {
        "time": 1689760116000,
        "value": 139.77,
        "_internal_originalTime": 1689760116000
    },
    {
        "time": 1689760117000,
        "value": 139.772,
        "_internal_originalTime": 1689760117000
    },
    {
        "time": 1689760118000,
        "value": 139.77,
        "_internal_originalTime": 1689760118000
    },
    {
        "time": 1689760120000,
        "value": 139.768,
        "_internal_originalTime": 1689760120000
    },
    {
        "time": 1689760121000,
        "value": 139.77,
        "_internal_originalTime": 1689760121000
    },
    {
        "time": 1689760123000,
        "value": 139.768,
        "_internal_originalTime": 1689760123000
    },
    {
        "time": 1689760124000,
        "value": 139.754,
        "_internal_originalTime": 1689760124000
    },
    {
        "time": 1689760125000,
        "value": 139.756,
        "_internal_originalTime": 1689760125000
    },
    {
        "time": 1689760126000,
        "value": 139.754,
        "_internal_originalTime": 1689760126000
    },
    {
        "time": 1689760128000,
        "value": 139.757,
        "_internal_originalTime": 1689760128000
    },
    {
        "time": 1689760129000,
        "value": 139.755,
        "_internal_originalTime": 1689760129000
    },
    {
        "time": 1689760132000,
        "value": 139.754,
        "_internal_originalTime": 1689760132000
    },
    {
        "time": 1689760133000,
        "value": 139.76,
        "_internal_originalTime": 1689760133000
    },
    {
        "time": 1689760134000,
        "value": 139.76,
        "_internal_originalTime": 1689760134000
    },
    {
        "time": 1689760136000,
        "value": 139.758,
        "_internal_originalTime": 1689760136000
    },
    {
        "time": 1689760138000,
        "value": 139.76,
        "_internal_originalTime": 1689760138000
    },
    {
        "time": 1689760139000,
        "value": 139.761,
        "_internal_originalTime": 1689760139000
    },
    {
        "time": 1689760140000,
        "value": 139.765,
        "_internal_originalTime": 1689760140000
    },
    {
        "time": 1689760142000,
        "value": 139.76,
        "_internal_originalTime": 1689760142000
    },
    {
        "time": 1689760143000,
        "value": 139.759,
        "_internal_originalTime": 1689760143000
    },
    {
        "time": 1689760145000,
        "value": 139.756,
        "_internal_originalTime": 1689760145000
    },
    {
        "time": 1689760146000,
        "value": 139.761,
        "_internal_originalTime": 1689760146000
    },
    {
        "time": 1689760148000,
        "value": 139.76,
        "_internal_originalTime": 1689760148000
    },
    {
        "time": 1689760149000,
        "value": 139.757,
        "_internal_originalTime": 1689760149000
    },
    {
        "time": 1689760150000,
        "value": 139.761,
        "_internal_originalTime": 1689760150000
    },
    {
        "time": 1689760151000,
        "value": 139.761,
        "_internal_originalTime": 1689760151000
    },
    {
        "time": 1689760152000,
        "value": 139.762,
        "_internal_originalTime": 1689760152000
    },
    {
        "time": 1689760155000,
        "value": 139.758,
        "_internal_originalTime": 1689760155000
    },
    {
        "time": 1689760156000,
        "value": 139.758,
        "_internal_originalTime": 1689760156000
    },
    {
        "time": 1689760158000,
        "value": 139.759,
        "_internal_originalTime": 1689760158000
    },
    {
        "time": 1689760159000,
        "value": 139.763,
        "_internal_originalTime": 1689760159000
    },
    {
        "time": 1689760160000,
        "value": 139.763,
        "_internal_originalTime": 1689760160000
    },
    {
        "time": 1689760162000,
        "value": 139.758,
        "_internal_originalTime": 1689760162000
    },
    {
        "time": 1689760163000,
        "value": 139.756,
        "_internal_originalTime": 1689760163000
    },
    {
        "time": 1689760165000,
        "value": 139.756,
        "_internal_originalTime": 1689760165000
    },
    {
        "time": 1689760166000,
        "value": 139.753,
        "_internal_originalTime": 1689760166000
    },
    {
        "time": 1689760167000,
        "value": 139.738,
        "_internal_originalTime": 1689760167000
    },
    {
        "time": 1689760168000,
        "value": 139.736,
        "_internal_originalTime": 1689760168000
    },
    {
        "time": 1689760170000,
        "value": 139.741,
        "_internal_originalTime": 1689760170000
    },
    {
        "time": 1689760171000,
        "value": 139.738,
        "_internal_originalTime": 1689760171000
    },
    {
        "time": 1689760172000,
        "value": 139.738,
        "_internal_originalTime": 1689760172000
    },
    {
        "time": 1689760174000,
        "value": 139.739,
        "_internal_originalTime": 1689760174000
    },
    {
        "time": 1689760176000,
        "value": 139.742,
        "_internal_originalTime": 1689760176000
    },
    {
        "time": 1689760177000,
        "value": 139.738,
        "_internal_originalTime": 1689760177000
    },
    {
        "time": 1689760178000,
        "value": 139.739,
        "_internal_originalTime": 1689760178000
    },
    {
        "time": 1689760180000,
        "value": 139.739,
        "_internal_originalTime": 1689760180000
    },
    {
        "time": 1689760181000,
        "value": 139.742,
        "_internal_originalTime": 1689760181000
    },
    {
        "time": 1689760182000,
        "value": 139.74,
        "_internal_originalTime": 1689760182000
    },
    {
        "time": 1689760184000,
        "value": 139.738,
        "_internal_originalTime": 1689760184000
    },
    {
        "time": 1689760185000,
        "value": 139.742,
        "_internal_originalTime": 1689760185000
    },
    {
        "time": 1689760186000,
        "value": 139.745,
        "_internal_originalTime": 1689760186000
    },
    {
        "time": 1689760188000,
        "value": 139.738,
        "_internal_originalTime": 1689760188000
    },
    {
        "time": 1689760190000,
        "value": 139.745,
        "_internal_originalTime": 1689760190000
    },
    {
        "time": 1689760193000,
        "value": 139.741,
        "_internal_originalTime": 1689760193000
    },
    {
        "time": 1689760194000,
        "value": 139.745,
        "_internal_originalTime": 1689760194000
    },
    {
        "time": 1689760195000,
        "value": 139.743,
        "_internal_originalTime": 1689760195000
    },
    {
        "time": 1689760196000,
        "value": 139.738,
        "_internal_originalTime": 1689760196000
    },
    {
        "time": 1689760198000,
        "value": 139.745,
        "_internal_originalTime": 1689760198000
    },
    {
        "time": 1689760199000,
        "value": 139.74,
        "_internal_originalTime": 1689760199000
    },
    {
        "time": 1689760201000,
        "value": 139.749,
        "_internal_originalTime": 1689760201000
    },
    {
        "time": 1689760202000,
        "value": 139.747,
        "_internal_originalTime": 1689760202000
    },
    {
        "time": 1689760203000,
        "value": 139.747,
        "_internal_originalTime": 1689760203000
    },
    {
        "time": 1689760204000,
        "value": 139.746,
        "_internal_originalTime": 1689760204000
    },
    {
        "time": 1689760205000,
        "value": 139.744,
        "_internal_originalTime": 1689760205000
    },
    {
        "time": 1689760207000,
        "value": 139.747,
        "_internal_originalTime": 1689760207000
    },
    {
        "time": 1689760208000,
        "value": 139.75,
        "_internal_originalTime": 1689760208000
    },
    {
        "time": 1689760209000,
        "value": 139.75,
        "_internal_originalTime": 1689760209000
    },
    {
        "time": 1689760211000,
        "value": 139.75,
        "_internal_originalTime": 1689760211000
    },
    {
        "time": 1689760212000,
        "value": 139.75,
        "_internal_originalTime": 1689760212000
    },
    {
        "time": 1689760214000,
        "value": 139.754,
        "_internal_originalTime": 1689760214000
    },
    {
        "time": 1689760215000,
        "value": 139.753,
        "_internal_originalTime": 1689760215000
    },
    {
        "time": 1689760216000,
        "value": 139.753,
        "_internal_originalTime": 1689760216000
    },
    {
        "time": 1689760218000,
        "value": 139.751,
        "_internal_originalTime": 1689760218000
    },
    {
        "time": 1689760220000,
        "value": 139.753,
        "_internal_originalTime": 1689760220000
    },
    {
        "time": 1689760221000,
        "value": 139.76,
        "_internal_originalTime": 1689760221000
    },
    {
        "time": 1689760222000,
        "value": 139.763,
        "_internal_originalTime": 1689760222000
    },
    {
        "time": 1689760224000,
        "value": 139.756,
        "_internal_originalTime": 1689760224000
    },
    {
        "time": 1689760225000,
        "value": 139.758,
        "_internal_originalTime": 1689760225000
    },
    {
        "time": 1689760226000,
        "value": 139.759,
        "_internal_originalTime": 1689760226000
    },
    {
        "time": 1689760228000,
        "value": 139.766,
        "_internal_originalTime": 1689760228000
    },
    {
        "time": 1689760229000,
        "value": 139.758,
        "_internal_originalTime": 1689760229000
    },
    {
        "time": 1689760231000,
        "value": 139.789,
        "_internal_originalTime": 1689760231000
    },
    {
        "time": 1689760232000,
        "value": 139.791,
        "_internal_originalTime": 1689760232000
    },
    {
        "time": 1689760233000,
        "value": 139.792,
        "_internal_originalTime": 1689760233000
    },
    {
        "time": 1689760234000,
        "value": 139.79,
        "_internal_originalTime": 1689760234000
    },
    {
        "time": 1689760235000,
        "value": 139.785,
        "_internal_originalTime": 1689760235000
    },
    {
        "time": 1689760236000,
        "value": 139.798,
        "_internal_originalTime": 1689760236000
    },
    {
        "time": 1689760237000,
        "value": 139.787,
        "_internal_originalTime": 1689760237000
    },
    {
        "time": 1689760239000,
        "value": 139.789,
        "_internal_originalTime": 1689760239000
    },
    {
        "time": 1689760240000,
        "value": 139.789,
        "_internal_originalTime": 1689760240000
    },
    {
        "time": 1689760242000,
        "value": 139.787,
        "_internal_originalTime": 1689760242000
    },
    {
        "time": 1689760243000,
        "value": 139.786,
        "_internal_originalTime": 1689760243000
    },
    {
        "time": 1689760244000,
        "value": 139.79,
        "_internal_originalTime": 1689760244000
    },
    {
        "time": 1689760245000,
        "value": 139.791,
        "_internal_originalTime": 1689760245000
    },
    {
        "time": 1689760247000,
        "value": 139.791,
        "_internal_originalTime": 1689760247000
    },
    {
        "time": 1689760248000,
        "value": 139.793,
        "_internal_originalTime": 1689760248000
    },
    {
        "time": 1689760249000,
        "value": 139.792,
        "_internal_originalTime": 1689760249000
    },
    {
        "time": 1689760250000,
        "value": 139.793,
        "_internal_originalTime": 1689760250000
    },
    {
        "time": 1689760251000,
        "value": 139.79,
        "_internal_originalTime": 1689760251000
    },
    {
        "time": 1689760252000,
        "value": 139.789,
        "_internal_originalTime": 1689760252000
    },
    {
        "time": 1689760254000,
        "value": 139.79,
        "_internal_originalTime": 1689760254000
    },
    {
        "time": 1689760255000,
        "value": 139.792,
        "_internal_originalTime": 1689760255000
    },
    {
        "time": 1689760256000,
        "value": 139.79,
        "_internal_originalTime": 1689760256000
    },
    {
        "time": 1689760257000,
        "value": 139.791,
        "_internal_originalTime": 1689760257000
    },
    {
        "time": 1689760259000,
        "value": 139.789,
        "_internal_originalTime": 1689760259000
    },
    {
        "time": 1689760260000,
        "value": 139.789,
        "_internal_originalTime": 1689760260000
    },
    {
        "time": 1689760262000,
        "value": 139.788,
        "_internal_originalTime": 1689760262000
    },
    {
        "time": 1689760264000,
        "value": 139.787,
        "_internal_originalTime": 1689760264000
    },
    {
        "time": 1689760265000,
        "value": 139.789,
        "_internal_originalTime": 1689760265000
    },
    {
        "time": 1689760266000,
        "value": 139.791,
        "_internal_originalTime": 1689760266000
    },
    {
        "time": 1689760267000,
        "value": 139.79,
        "_internal_originalTime": 1689760267000
    },
    {
        "time": 1689760268000,
        "value": 139.79,
        "_internal_originalTime": 1689760268000
    },
    {
        "time": 1689760269000,
        "value": 139.795,
        "_internal_originalTime": 1689760269000
    },
    {
        "time": 1689760270000,
        "value": 139.791,
        "_internal_originalTime": 1689760270000
    },
    {
        "time": 1689760272000,
        "value": 139.787,
        "_internal_originalTime": 1689760272000
    },
    {
        "time": 1689760273000,
        "value": 139.79,
        "_internal_originalTime": 1689760273000
    },
    {
        "time": 1689760274000,
        "value": 139.791,
        "_internal_originalTime": 1689760274000
    },
    {
        "time": 1689760275000,
        "value": 139.792,
        "_internal_originalTime": 1689760275000
    },
    {
        "time": 1689760278000,
        "value": 139.789,
        "_internal_originalTime": 1689760278000
    },
    {
        "time": 1689760279000,
        "value": 139.787,
        "_internal_originalTime": 1689760279000
    },
    {
        "time": 1689760280000,
        "value": 139.784,
        "_internal_originalTime": 1689760280000
    },
    {
        "time": 1689760281000,
        "value": 139.789,
        "_internal_originalTime": 1689760281000
    },
    {
        "time": 1689760282000,
        "value": 139.789,
        "_internal_originalTime": 1689760282000
    },
    {
        "time": 1689760283000,
        "value": 139.778,
        "_internal_originalTime": 1689760283000
    },
    {
        "time": 1689760284000,
        "value": 139.781,
        "_internal_originalTime": 1689760284000
    },
    {
        "time": 1689760286000,
        "value": 139.781,
        "_internal_originalTime": 1689760286000
    },
    {
        "time": 1689760288000,
        "value": 139.781,
        "_internal_originalTime": 1689760288000
    },
    {
        "time": 1689760290000,
        "value": 139.769,
        "_internal_originalTime": 1689760290000
    },
    {
        "time": 1689760291000,
        "value": 139.772,
        "_internal_originalTime": 1689760291000
    },
    {
        "time": 1689760292000,
        "value": 139.776,
        "_internal_originalTime": 1689760292000
    },
    {
        "time": 1689760294000,
        "value": 139.772,
        "_internal_originalTime": 1689760294000
    },
    {
        "time": 1689760295000,
        "value": 139.766,
        "_internal_originalTime": 1689760295000
    },
    {
        "time": 1689760297000,
        "value": 139.768,
        "_internal_originalTime": 1689760297000
    },
    {
        "time": 1689760298000,
        "value": 139.77,
        "_internal_originalTime": 1689760298000
    },
    {
        "time": 1689760299000,
        "value": 139.771,
        "_internal_originalTime": 1689760299000
    },
    {
        "time": 1689760301000,
        "value": 139.768,
        "_internal_originalTime": 1689760301000
    },
    {
        "time": 1689760305000,
        "value": 139.771,
        "_internal_originalTime": 1689760305000
    },
    {
        "time": 1689760306000,
        "value": 139.771,
        "_internal_originalTime": 1689760306000
    },
    {
        "time": 1689760307000,
        "value": 139.761,
        "_internal_originalTime": 1689760307000
    },
    {
        "time": 1689760308000,
        "value": 139.763,
        "_internal_originalTime": 1689760308000
    },
    {
        "time": 1689760309000,
        "value": 139.761,
        "_internal_originalTime": 1689760309000
    },
    {
        "time": 1689760311000,
        "value": 139.764,
        "_internal_originalTime": 1689760311000
    },
    {
        "time": 1689760312000,
        "value": 139.762,
        "_internal_originalTime": 1689760312000
    },
    {
        "time": 1689760315000,
        "value": 139.762,
        "_internal_originalTime": 1689760315000
    },
    {
        "time": 1689760317000,
        "value": 139.762,
        "_internal_originalTime": 1689760317000
    },
    {
        "time": 1689760318000,
        "value": 139.761,
        "_internal_originalTime": 1689760318000
    },
    {
        "time": 1689760320000,
        "value": 139.768,
        "_internal_originalTime": 1689760320000
    },
    {
        "time": 1689760322000,
        "value": 139.772,
        "_internal_originalTime": 1689760322000
    },
    {
        "time": 1689760323000,
        "value": 139.769,
        "_internal_originalTime": 1689760323000
    },
    {
        "time": 1689760324000,
        "value": 139.769,
        "_internal_originalTime": 1689760324000
    },
    {
        "time": 1689760325000,
        "value": 139.768,
        "_internal_originalTime": 1689760325000
    },
    {
        "time": 1689760326000,
        "value": 139.769,
        "_internal_originalTime": 1689760326000
    },
    {
        "time": 1689760328000,
        "value": 139.768,
        "_internal_originalTime": 1689760328000
    },
    {
        "time": 1689760330000,
        "value": 139.771,
        "_internal_originalTime": 1689760330000
    },
    {
        "time": 1689760331000,
        "value": 139.772,
        "_internal_originalTime": 1689760331000
    },
    {
        "time": 1689760333000,
        "value": 139.776,
        "_internal_originalTime": 1689760333000
    },
    {
        "time": 1689760334000,
        "value": 139.782,
        "_internal_originalTime": 1689760334000
    },
    {
        "time": 1689760335000,
        "value": 139.779,
        "_internal_originalTime": 1689760335000
    },
    {
        "time": 1689760337000,
        "value": 139.779,
        "_internal_originalTime": 1689760337000
    },
    {
        "time": 1689760338000,
        "value": 139.78,
        "_internal_originalTime": 1689760338000
    },
    {
        "time": 1689760339000,
        "value": 139.779,
        "_internal_originalTime": 1689760339000
    },
    {
        "time": 1689760341000,
        "value": 139.782,
        "_internal_originalTime": 1689760341000
    },
    {
        "time": 1689760342000,
        "value": 139.783,
        "_internal_originalTime": 1689760342000
    },
    {
        "time": 1689760344000,
        "value": 139.783,
        "_internal_originalTime": 1689760344000
    },
    {
        "time": 1689760346000,
        "value": 139.782,
        "_internal_originalTime": 1689760346000
    },
    {
        "time": 1689760348000,
        "value": 139.78,
        "_internal_originalTime": 1689760348000
    },
    {
        "time": 1689760350000,
        "value": 139.785,
        "_internal_originalTime": 1689760350000
    },
    {
        "time": 1689760351000,
        "value": 139.782,
        "_internal_originalTime": 1689760351000
    },
    {
        "time": 1689760353000,
        "value": 139.781,
        "_internal_originalTime": 1689760353000
    },
    {
        "time": 1689760354000,
        "value": 139.781,
        "_internal_originalTime": 1689760354000
    },
    {
        "time": 1689760356000,
        "value": 139.783,
        "_internal_originalTime": 1689760356000
    },
    {
        "time": 1689760357000,
        "value": 139.773,
        "_internal_originalTime": 1689760357000
    },
    {
        "time": 1689760358000,
        "value": 139.771,
        "_internal_originalTime": 1689760358000
    },
    {
        "time": 1689760359000,
        "value": 139.772,
        "_internal_originalTime": 1689760359000
    },
    {
        "time": 1689760360000,
        "value": 139.769,
        "_internal_originalTime": 1689760360000
    },
    {
        "time": 1689760361000,
        "value": 139.772,
        "_internal_originalTime": 1689760361000
    },
    {
        "time": 1689760363000,
        "value": 139.773,
        "_internal_originalTime": 1689760363000
    },
    {
        "time": 1689760364000,
        "value": 139.772,
        "_internal_originalTime": 1689760364000
    },
    {
        "time": 1689760365000,
        "value": 139.776,
        "_internal_originalTime": 1689760365000
    },
    {
        "time": 1689760366000,
        "value": 139.778,
        "_internal_originalTime": 1689760366000
    },
    {
        "time": 1689760367000,
        "value": 139.779,
        "_internal_originalTime": 1689760367000
    },
    {
        "time": 1689760368000,
        "value": 139.777,
        "_internal_originalTime": 1689760368000
    },
    {
        "time": 1689760370000,
        "value": 139.779,
        "_internal_originalTime": 1689760370000
    },
    {
        "time": 1689760371000,
        "value": 139.774,
        "_internal_originalTime": 1689760371000
    },
    {
        "time": 1689760372000,
        "value": 139.782,
        "_internal_originalTime": 1689760372000
    },
    {
        "time": 1689760373000,
        "value": 139.783,
        "_internal_originalTime": 1689760373000
    },
    {
        "time": 1689760374000,
        "value": 139.783,
        "_internal_originalTime": 1689760374000
    },
    {
        "time": 1689760376000,
        "value": 139.784,
        "_internal_originalTime": 1689760376000
    },
    {
        "time": 1689760378000,
        "value": 139.778,
        "_internal_originalTime": 1689760378000
    },
    {
        "time": 1689760379000,
        "value": 139.778,
        "_internal_originalTime": 1689760379000
    },
    {
        "time": 1689760380000,
        "value": 139.778,
        "_internal_originalTime": 1689760380000
    },
    {
        "time": 1689760381000,
        "value": 139.771,
        "_internal_originalTime": 1689760381000
    },
    {
        "time": 1689760382000,
        "value": 139.77,
        "_internal_originalTime": 1689760382000
    },
    {
        "time": 1689760383000,
        "value": 139.773,
        "_internal_originalTime": 1689760383000
    },
    {
        "time": 1689760384000,
        "value": 139.774,
        "_internal_originalTime": 1689760384000
    },
    {
        "time": 1689760385000,
        "value": 139.766,
        "_internal_originalTime": 1689760385000
    },
    {
        "time": 1689760386000,
        "value": 139.774,
        "_internal_originalTime": 1689760386000
    },
    {
        "time": 1689760387000,
        "value": 139.774,
        "_internal_originalTime": 1689760387000
    },
    {
        "time": 1689760388000,
        "value": 139.77,
        "_internal_originalTime": 1689760388000
    },
    {
        "time": 1689760390000,
        "value": 139.768,
        "_internal_originalTime": 1689760390000
    },
    {
        "time": 1689760391000,
        "value": 139.774,
        "_internal_originalTime": 1689760391000
    },
    {
        "time": 1689760392000,
        "value": 139.77,
        "_internal_originalTime": 1689760392000
    },
    {
        "time": 1689760394000,
        "value": 139.774,
        "_internal_originalTime": 1689760394000
    },
    {
        "time": 1689760395000,
        "value": 139.777,
        "_internal_originalTime": 1689760395000
    },
    {
        "time": 1689760396000,
        "value": 139.787,
        "_internal_originalTime": 1689760396000
    },
    {
        "time": 1689760397000,
        "value": 139.786,
        "_internal_originalTime": 1689760397000
    },
    {
        "time": 1689760398000,
        "value": 139.784,
        "_internal_originalTime": 1689760398000
    },
    {
        "time": 1689760399000,
        "value": 139.785,
        "_internal_originalTime": 1689760399000
    },
    {
        "time": 1689760401000,
        "value": 139.782,
        "_internal_originalTime": 1689760401000
    },
    {
        "time": 1689760402000,
        "value": 139.788,
        "_internal_originalTime": 1689760402000
    },
    {
        "time": 1689760403000,
        "value": 139.79,
        "_internal_originalTime": 1689760403000
    },
    {
        "time": 1689760404000,
        "value": 139.79,
        "_internal_originalTime": 1689760404000
    },
    {
        "time": 1689760406000,
        "value": 139.79,
        "_internal_originalTime": 1689760406000
    },
    {
        "time": 1689760407000,
        "value": 139.789,
        "_internal_originalTime": 1689760407000
    },
    {
        "time": 1689760408000,
        "value": 139.789,
        "_internal_originalTime": 1689760408000
    },
    {
        "time": 1689760410000,
        "value": 139.79,
        "_internal_originalTime": 1689760410000
    },
    {
        "time": 1689760411000,
        "value": 139.792,
        "_internal_originalTime": 1689760411000
    },
    {
        "time": 1689760413000,
        "value": 139.79,
        "_internal_originalTime": 1689760413000
    },
    {
        "time": 1689760414000,
        "value": 139.792,
        "_internal_originalTime": 1689760414000
    },
    {
        "time": 1689760415000,
        "value": 139.788,
        "_internal_originalTime": 1689760415000
    },
    {
        "time": 1689760416000,
        "value": 139.791,
        "_internal_originalTime": 1689760416000
    },
    {
        "time": 1689760418000,
        "value": 139.791,
        "_internal_originalTime": 1689760418000
    },
    {
        "time": 1689760419000,
        "value": 139.791,
        "_internal_originalTime": 1689760419000
    },
    {
        "time": 1689760421000,
        "value": 139.794,
        "_internal_originalTime": 1689760421000
    },
    {
        "time": 1689760422000,
        "value": 139.795,
        "_internal_originalTime": 1689760422000
    },
    {
        "time": 1689760423000,
        "value": 139.797,
        "_internal_originalTime": 1689760423000
    },
    {
        "time": 1689760424000,
        "value": 139.798,
        "_internal_originalTime": 1689760424000
    },
    {
        "time": 1689760426000,
        "value": 139.798,
        "_internal_originalTime": 1689760426000
    },
    {
        "time": 1689760427000,
        "value": 139.799,
        "_internal_originalTime": 1689760427000
    },
    {
        "time": 1689760428000,
        "value": 139.798,
        "_internal_originalTime": 1689760428000
    },
    {
        "time": 1689760430000,
        "value": 139.799,
        "_internal_originalTime": 1689760430000
    },
    {
        "time": 1689760432000,
        "value": 139.798,
        "_internal_originalTime": 1689760432000
    },
    {
        "time": 1689760433000,
        "value": 139.798,
        "_internal_originalTime": 1689760433000
    },
    {
        "time": 1689760434000,
        "value": 139.798,
        "_internal_originalTime": 1689760434000
    },
    {
        "time": 1689760435000,
        "value": 139.801,
        "_internal_originalTime": 1689760435000
    },
    {
        "time": 1689760436000,
        "value": 139.799,
        "_internal_originalTime": 1689760436000
    },
    {
        "time": 1689760438000,
        "value": 139.798,
        "_internal_originalTime": 1689760438000
    },
    {
        "time": 1689760440000,
        "value": 139.796,
        "_internal_originalTime": 1689760440000
    },
    {
        "time": 1689760441000,
        "value": 139.799,
        "_internal_originalTime": 1689760441000
    },
    {
        "time": 1689760443000,
        "value": 139.796,
        "_internal_originalTime": 1689760443000
    },
    {
        "time": 1689760444000,
        "value": 139.799,
        "_internal_originalTime": 1689760444000
    },
    {
        "time": 1689760445000,
        "value": 139.8,
        "_internal_originalTime": 1689760445000
    },
    {
        "time": 1689760446000,
        "value": 139.798,
        "_internal_originalTime": 1689760446000
    },
    {
        "time": 1689760448000,
        "value": 139.802,
        "_internal_originalTime": 1689760448000
    },
    {
        "time": 1689760449000,
        "value": 139.81,
        "_internal_originalTime": 1689760449000
    },
    {
        "time": 1689760450000,
        "value": 139.809,
        "_internal_originalTime": 1689760450000
    },
    {
        "time": 1689760451000,
        "value": 139.807,
        "_internal_originalTime": 1689760451000
    },
    {
        "time": 1689760452000,
        "value": 139.809,
        "_internal_originalTime": 1689760452000
    },
    {
        "time": 1689760454000,
        "value": 139.809,
        "_internal_originalTime": 1689760454000
    },
    {
        "time": 1689760455000,
        "value": 139.81,
        "_internal_originalTime": 1689760455000
    },
    {
        "time": 1689760457000,
        "value": 139.806,
        "_internal_originalTime": 1689760457000
    },
    {
        "time": 1689760458000,
        "value": 139.808,
        "_internal_originalTime": 1689760458000
    },
    {
        "time": 1689760459000,
        "value": 139.81,
        "_internal_originalTime": 1689760459000
    },
    {
        "time": 1689760460000,
        "value": 139.807,
        "_internal_originalTime": 1689760460000
    },
    {
        "time": 1689760461000,
        "value": 139.811,
        "_internal_originalTime": 1689760461000
    },
    {
        "time": 1689760462000,
        "value": 139.812,
        "_internal_originalTime": 1689760462000
    },
    {
        "time": 1689760464000,
        "value": 139.817,
        "_internal_originalTime": 1689760464000
    },
    {
        "time": 1689760465000,
        "value": 139.817,
        "_internal_originalTime": 1689760465000
    },
    {
        "time": 1689760466000,
        "value": 139.82,
        "_internal_originalTime": 1689760466000
    },
    {
        "time": 1689760467000,
        "value": 139.822,
        "_internal_originalTime": 1689760467000
    },
    {
        "time": 1689760469000,
        "value": 139.83,
        "_internal_originalTime": 1689760469000
    },
    {
        "time": 1689760470000,
        "value": 139.834,
        "_internal_originalTime": 1689760470000
    },
    {
        "time": 1689760471000,
        "value": 139.832,
        "_internal_originalTime": 1689760471000
    },
    {
        "time": 1689760472000,
        "value": 139.838,
        "_internal_originalTime": 1689760472000
    },
    {
        "time": 1689760473000,
        "value": 139.838,
        "_internal_originalTime": 1689760473000
    },
    {
        "time": 1689760474000,
        "value": 139.839,
        "_internal_originalTime": 1689760474000
    },
    {
        "time": 1689760476000,
        "value": 139.841,
        "_internal_originalTime": 1689760476000
    },
    {
        "time": 1689760477000,
        "value": 139.841,
        "_internal_originalTime": 1689760477000
    },
    {
        "time": 1689760478000,
        "value": 139.841,
        "_internal_originalTime": 1689760478000
    },
    {
        "time": 1689760479000,
        "value": 139.84,
        "_internal_originalTime": 1689760479000
    },
    {
        "time": 1689760480000,
        "value": 139.843,
        "_internal_originalTime": 1689760480000
    },
    {
        "time": 1689760482000,
        "value": 139.841,
        "_internal_originalTime": 1689760482000
    },
    {
        "time": 1689760483000,
        "value": 139.838,
        "_internal_originalTime": 1689760483000
    },
    {
        "time": 1689760485000,
        "value": 139.838,
        "_internal_originalTime": 1689760485000
    },
    {
        "time": 1689760486000,
        "value": 139.832,
        "_internal_originalTime": 1689760486000
    },
    {
        "time": 1689760487000,
        "value": 139.831,
        "_internal_originalTime": 1689760487000
    },
    {
        "time": 1689760488000,
        "value": 139.827,
        "_internal_originalTime": 1689760488000
    },
    {
        "time": 1689760489000,
        "value": 139.828,
        "_internal_originalTime": 1689760489000
    },
    {
        "time": 1689760492000,
        "value": 139.83,
        "_internal_originalTime": 1689760492000
    },
    {
        "time": 1689760493000,
        "value": 139.823,
        "_internal_originalTime": 1689760493000
    },
    {
        "time": 1689760494000,
        "value": 139.823,
        "_internal_originalTime": 1689760494000
    },
    {
        "time": 1689760495000,
        "value": 139.822,
        "_internal_originalTime": 1689760495000
    },
    {
        "time": 1689760496000,
        "value": 139.822,
        "_internal_originalTime": 1689760496000
    },
    {
        "time": 1689760497000,
        "value": 139.822,
        "_internal_originalTime": 1689760497000
    },
    {
        "time": 1689760499000,
        "value": 139.822,
        "_internal_originalTime": 1689760499000
    },
    {
        "time": 1689760500000,
        "value": 139.823,
        "_internal_originalTime": 1689760500000
    },
    {
        "time": 1689760501000,
        "value": 139.821,
        "_internal_originalTime": 1689760501000
    },
    {
        "time": 1689760502000,
        "value": 139.822,
        "_internal_originalTime": 1689760502000
    },
    {
        "time": 1689760503000,
        "value": 139.817,
        "_internal_originalTime": 1689760503000
    },
    {
        "time": 1689760505000,
        "value": 139.82,
        "_internal_originalTime": 1689760505000
    },
    {
        "time": 1689760506000,
        "value": 139.817,
        "_internal_originalTime": 1689760506000
    },
    {
        "time": 1689760507000,
        "value": 139.819,
        "_internal_originalTime": 1689760507000
    },
    {
        "time": 1689760508000,
        "value": 139.817,
        "_internal_originalTime": 1689760508000
    },
    {
        "time": 1689760510000,
        "value": 139.816,
        "_internal_originalTime": 1689760510000
    },
    {
        "time": 1689760511000,
        "value": 139.817,
        "_internal_originalTime": 1689760511000
    },
    {
        "time": 1689760512000,
        "value": 139.811,
        "_internal_originalTime": 1689760512000
    },
    {
        "time": 1689760514000,
        "value": 139.812,
        "_internal_originalTime": 1689760514000
    },
    {
        "time": 1689760515000,
        "value": 139.816,
        "_internal_originalTime": 1689760515000
    },
    {
        "time": 1689760516000,
        "value": 139.816,
        "_internal_originalTime": 1689760516000
    },
    {
        "time": 1689760518000,
        "value": 139.817,
        "_internal_originalTime": 1689760518000
    },
    {
        "time": 1689760519000,
        "value": 139.817,
        "_internal_originalTime": 1689760519000
    },
    {
        "time": 1689760520000,
        "value": 139.815,
        "_internal_originalTime": 1689760520000
    },
    {
        "time": 1689760523000,
        "value": 139.817,
        "_internal_originalTime": 1689760523000
    },
    {
        "time": 1689760524000,
        "value": 139.817,
        "_internal_originalTime": 1689760524000
    },
    {
        "time": 1689760525000,
        "value": 139.817,
        "_internal_originalTime": 1689760525000
    },
    {
        "time": 1689760529000,
        "value": 139.811,
        "_internal_originalTime": 1689760529000
    },
    {
        "time": 1689760530000,
        "value": 139.816,
        "_internal_originalTime": 1689760530000
    },
    {
        "time": 1689760531000,
        "value": 139.819,
        "_internal_originalTime": 1689760531000
    },
    {
        "time": 1689760533000,
        "value": 139.818,
        "_internal_originalTime": 1689760533000
    },
    {
        "time": 1689760534000,
        "value": 139.82,
        "_internal_originalTime": 1689760534000
    },
    {
        "time": 1689760535000,
        "value": 139.813,
        "_internal_originalTime": 1689760535000
    },
    {
        "time": 1689760536000,
        "value": 139.822,
        "_internal_originalTime": 1689760536000
    },
    {
        "time": 1689760538000,
        "value": 139.823,
        "_internal_originalTime": 1689760538000
    },
    {
        "time": 1689760539000,
        "value": 139.825,
        "_internal_originalTime": 1689760539000
    },
    {
        "time": 1689760541000,
        "value": 139.829,
        "_internal_originalTime": 1689760541000
    },
    {
        "time": 1689760542000,
        "value": 139.827,
        "_internal_originalTime": 1689760542000
    },
    {
        "time": 1689760543000,
        "value": 139.829,
        "_internal_originalTime": 1689760543000
    },
    {
        "time": 1689760544000,
        "value": 139.827,
        "_internal_originalTime": 1689760544000
    },
    {
        "time": 1689760545000,
        "value": 139.829,
        "_internal_originalTime": 1689760545000
    },
    {
        "time": 1689760546000,
        "value": 139.83,
        "_internal_originalTime": 1689760546000
    },
    {
        "time": 1689760548000,
        "value": 139.832,
        "_internal_originalTime": 1689760548000
    },
    {
        "time": 1689760549000,
        "value": 139.83,
        "_internal_originalTime": 1689760549000
    },
    {
        "time": 1689760550000,
        "value": 139.829,
        "_internal_originalTime": 1689760550000
    },
    {
        "time": 1689760552000,
        "value": 139.826,
        "_internal_originalTime": 1689760552000
    },
    {
        "time": 1689760553000,
        "value": 139.827,
        "_internal_originalTime": 1689760553000
    },
    {
        "time": 1689760555000,
        "value": 139.828,
        "_internal_originalTime": 1689760555000
    },
    {
        "time": 1689760557000,
        "value": 139.829,
        "_internal_originalTime": 1689760557000
    },
    {
        "time": 1689760558000,
        "value": 139.831,
        "_internal_originalTime": 1689760558000
    },
    {
        "time": 1689760559000,
        "value": 139.828,
        "_internal_originalTime": 1689760559000
    },
    {
        "time": 1689760560000,
        "value": 139.829,
        "_internal_originalTime": 1689760560000
    },
    {
        "time": 1689760562000,
        "value": 139.81,
        "_internal_originalTime": 1689760562000
    },
    {
        "time": 1689760563000,
        "value": 139.828,
        "_internal_originalTime": 1689760563000
    },
    {
        "time": 1689760564000,
        "value": 139.83,
        "_internal_originalTime": 1689760564000
    },
    {
        "time": 1689760565000,
        "value": 139.828,
        "_internal_originalTime": 1689760565000
    },
    {
        "time": 1689760567000,
        "value": 139.828,
        "_internal_originalTime": 1689760567000
    },
    {
        "time": 1689760568000,
        "value": 139.83,
        "_internal_originalTime": 1689760568000
    },
    {
        "time": 1689760570000,
        "value": 139.833,
        "_internal_originalTime": 1689760570000
    },
    {
        "time": 1689760572000,
        "value": 139.834,
        "_internal_originalTime": 1689760572000
    },
    {
        "time": 1689760573000,
        "value": 139.835,
        "_internal_originalTime": 1689760573000
    },
    {
        "time": 1689760574000,
        "value": 139.836,
        "_internal_originalTime": 1689760574000
    },
    {
        "time": 1689760575000,
        "value": 139.836,
        "_internal_originalTime": 1689760575000
    },
    {
        "time": 1689760577000,
        "value": 139.837,
        "_internal_originalTime": 1689760577000
    },
    {
        "time": 1689760578000,
        "value": 139.834,
        "_internal_originalTime": 1689760578000
    },
    {
        "time": 1689760579000,
        "value": 139.825,
        "_internal_originalTime": 1689760579000
    },
    {
        "time": 1689760580000,
        "value": 139.83,
        "_internal_originalTime": 1689760580000
    },
    {
        "time": 1689760582000,
        "value": 139.832,
        "_internal_originalTime": 1689760582000
    },
    {
        "time": 1689760584000,
        "value": 139.84,
        "_internal_originalTime": 1689760584000
    },
    {
        "time": 1689760586000,
        "value": 139.836,
        "_internal_originalTime": 1689760586000
    },
    {
        "time": 1689760588000,
        "value": 139.838,
        "_internal_originalTime": 1689760588000
    },
    {
        "time": 1689760589000,
        "value": 139.841,
        "_internal_originalTime": 1689760589000
    },
    {
        "time": 1689760590000,
        "value": 139.838,
        "_internal_originalTime": 1689760590000
    },
    {
        "time": 1689760591000,
        "value": 139.836,
        "_internal_originalTime": 1689760591000
    },
    {
        "time": 1689760592000,
        "value": 139.841,
        "_internal_originalTime": 1689760592000
    },
    {
        "time": 1689760593000,
        "value": 139.84,
        "_internal_originalTime": 1689760593000
    },
    {
        "time": 1689760595000,
        "value": 139.84,
        "_internal_originalTime": 1689760595000
    },
    {
        "time": 1689760598000,
        "value": 139.84,
        "_internal_originalTime": 1689760598000
    },
    {
        "time": 1689760599000,
        "value": 139.842,
        "_internal_originalTime": 1689760599000
    },
    {
        "time": 1689760600000,
        "value": 139.837,
        "_internal_originalTime": 1689760600000
    },
    {
        "time": 1689760601000,
        "value": 139.837,
        "_internal_originalTime": 1689760601000
    },
    {
        "time": 1689760602000,
        "value": 139.838,
        "_internal_originalTime": 1689760602000
    },
    {
        "time": 1689760603000,
        "value": 139.841,
        "_internal_originalTime": 1689760603000
    },
    {
        "time": 1689760604000,
        "value": 139.837,
        "_internal_originalTime": 1689760604000
    },
    {
        "time": 1689760605000,
        "value": 139.839,
        "_internal_originalTime": 1689760605000
    },
    {
        "time": 1689760606000,
        "value": 139.838,
        "_internal_originalTime": 1689760606000
    },
    {
        "time": 1689760607000,
        "value": 139.839,
        "_internal_originalTime": 1689760607000
    },
    {
        "time": 1689760608000,
        "value": 139.839,
        "_internal_originalTime": 1689760608000
    },
    {
        "time": 1689760610000,
        "value": 139.838,
        "_internal_originalTime": 1689760610000
    },
    {
        "time": 1689760611000,
        "value": 139.839,
        "_internal_originalTime": 1689760611000
    },
    {
        "time": 1689760613000,
        "value": 139.837,
        "_internal_originalTime": 1689760613000
    },
    {
        "time": 1689760614000,
        "value": 139.839,
        "_internal_originalTime": 1689760614000
    },
    {
        "time": 1689760615000,
        "value": 139.836,
        "_internal_originalTime": 1689760615000
    },
    {
        "time": 1689760616000,
        "value": 139.839,
        "_internal_originalTime": 1689760616000
    },
    {
        "time": 1689760617000,
        "value": 139.835,
        "_internal_originalTime": 1689760617000
    },
    {
        "time": 1689760618000,
        "value": 139.834,
        "_internal_originalTime": 1689760618000
    },
    {
        "time": 1689760620000,
        "value": 139.841,
        "_internal_originalTime": 1689760620000
    },
    {
        "time": 1689760621000,
        "value": 139.835,
        "_internal_originalTime": 1689760621000
    },
    {
        "time": 1689760622000,
        "value": 139.836,
        "_internal_originalTime": 1689760622000
    },
    {
        "time": 1689760623000,
        "value": 139.832,
        "_internal_originalTime": 1689760623000
    },
    {
        "time": 1689760624000,
        "value": 139.83,
        "_internal_originalTime": 1689760624000
    },
    {
        "time": 1689760625000,
        "value": 139.833,
        "_internal_originalTime": 1689760625000
    },
    {
        "time": 1689760627000,
        "value": 139.83,
        "_internal_originalTime": 1689760627000
    },
    {
        "time": 1689760628000,
        "value": 139.836,
        "_internal_originalTime": 1689760628000
    },
    {
        "time": 1689760630000,
        "value": 139.838,
        "_internal_originalTime": 1689760630000
    },
    {
        "time": 1689760631000,
        "value": 139.834,
        "_internal_originalTime": 1689760631000
    },
    {
        "time": 1689760633000,
        "value": 139.834,
        "_internal_originalTime": 1689760633000
    },
    {
        "time": 1689760634000,
        "value": 139.835,
        "_internal_originalTime": 1689760634000
    },
    {
        "time": 1689760638000,
        "value": 139.832,
        "_internal_originalTime": 1689760638000
    },
    {
        "time": 1689760639000,
        "value": 139.835,
        "_internal_originalTime": 1689760639000
    },
    {
        "time": 1689760640000,
        "value": 139.832,
        "_internal_originalTime": 1689760640000
    },
    {
        "time": 1689760641000,
        "value": 139.831,
        "_internal_originalTime": 1689760641000
    },
    {
        "time": 1689760643000,
        "value": 139.833,
        "_internal_originalTime": 1689760643000
    },
    {
        "time": 1689760645000,
        "value": 139.836,
        "_internal_originalTime": 1689760645000
    },
    {
        "time": 1689760646000,
        "value": 139.833,
        "_internal_originalTime": 1689760646000
    },
    {
        "time": 1689760647000,
        "value": 139.835,
        "_internal_originalTime": 1689760647000
    },
    {
        "time": 1689760649000,
        "value": 139.838,
        "_internal_originalTime": 1689760649000
    },
    {
        "time": 1689760650000,
        "value": 139.835,
        "_internal_originalTime": 1689760650000
    },
    {
        "time": 1689760651000,
        "value": 139.836,
        "_internal_originalTime": 1689760651000
    },
    {
        "time": 1689760654000,
        "value": 139.828,
        "_internal_originalTime": 1689760654000
    },
    {
        "time": 1689760656000,
        "value": 139.831,
        "_internal_originalTime": 1689760656000
    },
    {
        "time": 1689760658000,
        "value": 139.832,
        "_internal_originalTime": 1689760658000
    },
    {
        "time": 1689760661000,
        "value": 139.833,
        "_internal_originalTime": 1689760661000
    },
    {
        "time": 1689760662000,
        "value": 139.831,
        "_internal_originalTime": 1689760662000
    },
    {
        "time": 1689760663000,
        "value": 139.828,
        "_internal_originalTime": 1689760663000
    },
    {
        "time": 1689760664000,
        "value": 139.831,
        "_internal_originalTime": 1689760664000
    },
    {
        "time": 1689760665000,
        "value": 139.832,
        "_internal_originalTime": 1689760665000
    },
    {
        "time": 1689760667000,
        "value": 139.832,
        "_internal_originalTime": 1689760667000
    },
    {
        "time": 1689760669000,
        "value": 139.832,
        "_internal_originalTime": 1689760669000
    },
    {
        "time": 1689760670000,
        "value": 139.832,
        "_internal_originalTime": 1689760670000
    },
    {
        "time": 1689760671000,
        "value": 139.831,
        "_internal_originalTime": 1689760671000
    },
    {
        "time": 1689760675000,
        "value": 139.829,
        "_internal_originalTime": 1689760675000
    },
    {
        "time": 1689760676000,
        "value": 139.832,
        "_internal_originalTime": 1689760676000
    },
    {
        "time": 1689760677000,
        "value": 139.83,
        "_internal_originalTime": 1689760677000
    },
    {
        "time": 1689760678000,
        "value": 139.829,
        "_internal_originalTime": 1689760678000
    },
    {
        "time": 1689760679000,
        "value": 139.83,
        "_internal_originalTime": 1689760679000
    },
    {
        "time": 1689760681000,
        "value": 139.816,
        "_internal_originalTime": 1689760681000
    },
    {
        "time": 1689760683000,
        "value": 139.816,
        "_internal_originalTime": 1689760683000
    },
    {
        "time": 1689760685000,
        "value": 139.808,
        "_internal_originalTime": 1689760685000
    },
    {
        "time": 1689760687000,
        "value": 139.808,
        "_internal_originalTime": 1689760687000
    },
    {
        "time": 1689760688000,
        "value": 139.808,
        "_internal_originalTime": 1689760688000
    },
    {
        "time": 1689760690000,
        "value": 139.81,
        "_internal_originalTime": 1689760690000
    },
    {
        "time": 1689760691000,
        "value": 139.811,
        "_internal_originalTime": 1689760691000
    },
    {
        "time": 1689760693000,
        "value": 139.808,
        "_internal_originalTime": 1689760693000
    },
    {
        "time": 1689760695000,
        "value": 139.804,
        "_internal_originalTime": 1689760695000
    },
    {
        "time": 1689760696000,
        "value": 139.812,
        "_internal_originalTime": 1689760696000
    },
    {
        "time": 1689760697000,
        "value": 139.811,
        "_internal_originalTime": 1689760697000
    },
    {
        "time": 1689760698000,
        "value": 139.81,
        "_internal_originalTime": 1689760698000
    },
    {
        "time": 1689760700000,
        "value": 139.808,
        "_internal_originalTime": 1689760700000
    },
    {
        "time": 1689760701000,
        "value": 139.808,
        "_internal_originalTime": 1689760701000
    },
    {
        "time": 1689760702000,
        "value": 139.809,
        "_internal_originalTime": 1689760702000
    },
    {
        "time": 1689760703000,
        "value": 139.809,
        "_internal_originalTime": 1689760703000
    },
    {
        "time": 1689760705000,
        "value": 139.808,
        "_internal_originalTime": 1689760705000
    },
    {
        "time": 1689760706000,
        "value": 139.808,
        "_internal_originalTime": 1689760706000
    },
    {
        "time": 1689760707000,
        "value": 139.809,
        "_internal_originalTime": 1689760707000
    },
    {
        "time": 1689760708000,
        "value": 139.809,
        "_internal_originalTime": 1689760708000
    },
    {
        "time": 1689760710000,
        "value": 139.809,
        "_internal_originalTime": 1689760710000
    },
    {
        "time": 1689760712000,
        "value": 139.811,
        "_internal_originalTime": 1689760712000
    },
    {
        "time": 1689760713000,
        "value": 139.807,
        "_internal_originalTime": 1689760713000
    },
    {
        "time": 1689760715000,
        "value": 139.806,
        "_internal_originalTime": 1689760715000
    },
    {
        "time": 1689760716000,
        "value": 139.813,
        "_internal_originalTime": 1689760716000
    },
    {
        "time": 1689760717000,
        "value": 139.809,
        "_internal_originalTime": 1689760717000
    },
    {
        "time": 1689760718000,
        "value": 139.798,
        "_internal_originalTime": 1689760718000
    },
    {
        "time": 1689760719000,
        "value": 139.801,
        "_internal_originalTime": 1689760719000
    },
    {
        "time": 1689760721000,
        "value": 139.8,
        "_internal_originalTime": 1689760721000
    },
    {
        "time": 1689760722000,
        "value": 139.796,
        "_internal_originalTime": 1689760722000
    },
    {
        "time": 1689760723000,
        "value": 139.796,
        "_internal_originalTime": 1689760723000
    },
    {
        "time": 1689760729000,
        "value": 139.796,
        "_internal_originalTime": 1689760729000
    },
    {
        "time": 1689760730000,
        "value": 139.802,
        "_internal_originalTime": 1689760730000
    },
    {
        "time": 1689760731000,
        "value": 139.802,
        "_internal_originalTime": 1689760731000
    },
    {
        "time": 1689760735000,
        "value": 139.796,
        "_internal_originalTime": 1689760735000
    },
    {
        "time": 1689760740000,
        "value": 139.797,
        "_internal_originalTime": 1689760740000
    },
    {
        "time": 1689760742000,
        "value": 139.802,
        "_internal_originalTime": 1689760742000
    },
    {
        "time": 1689760743000,
        "value": 139.795,
        "_internal_originalTime": 1689760743000
    },
    {
        "time": 1689760746000,
        "value": 139.799,
        "_internal_originalTime": 1689760746000
    },
    {
        "time": 1689760748000,
        "value": 139.802,
        "_internal_originalTime": 1689760748000
    },
    {
        "time": 1689760749000,
        "value": 139.796,
        "_internal_originalTime": 1689760749000
    },
    {
        "time": 1689760750000,
        "value": 139.811,
        "_internal_originalTime": 1689760750000
    },
    {
        "time": 1689760751000,
        "value": 139.808,
        "_internal_originalTime": 1689760751000
    },
    {
        "time": 1689760753000,
        "value": 139.804,
        "_internal_originalTime": 1689760753000
    },
    {
        "time": 1689760754000,
        "value": 139.805,
        "_internal_originalTime": 1689760754000
    },
    {
        "time": 1689760756000,
        "value": 139.807,
        "_internal_originalTime": 1689760756000
    },
    {
        "time": 1689760757000,
        "value": 139.802,
        "_internal_originalTime": 1689760757000
    },
    {
        "time": 1689760758000,
        "value": 139.802,
        "_internal_originalTime": 1689760758000
    },
    {
        "time": 1689760760000,
        "value": 139.802,
        "_internal_originalTime": 1689760760000
    },
    {
        "time": 1689760761000,
        "value": 139.801,
        "_internal_originalTime": 1689760761000
    },
    {
        "time": 1689760762000,
        "value": 139.8,
        "_internal_originalTime": 1689760762000
    },
    {
        "time": 1689760763000,
        "value": 139.797,
        "_internal_originalTime": 1689760763000
    },
    {
        "time": 1689760765000,
        "value": 139.807,
        "_internal_originalTime": 1689760765000
    },
    {
        "time": 1689760766000,
        "value": 139.811,
        "_internal_originalTime": 1689760766000
    },
    {
        "time": 1689760767000,
        "value": 139.811,
        "_internal_originalTime": 1689760767000
    },
    {
        "time": 1689760768000,
        "value": 139.807,
        "_internal_originalTime": 1689760768000
    },
    {
        "time": 1689760770000,
        "value": 139.813,
        "_internal_originalTime": 1689760770000
    },
    {
        "time": 1689760771000,
        "value": 139.811,
        "_internal_originalTime": 1689760771000
    },
    {
        "time": 1689760772000,
        "value": 139.816,
        "_internal_originalTime": 1689760772000
    },
    {
        "time": 1689760773000,
        "value": 139.814,
        "_internal_originalTime": 1689760773000
    },
    {
        "time": 1689760774000,
        "value": 139.815,
        "_internal_originalTime": 1689760774000
    },
    {
        "time": 1689760775000,
        "value": 139.808,
        "_internal_originalTime": 1689760775000
    },
    {
        "time": 1689760777000,
        "value": 139.817,
        "_internal_originalTime": 1689760777000
    },
    {
        "time": 1689760778000,
        "value": 139.816,
        "_internal_originalTime": 1689760778000
    },
    {
        "time": 1689760780000,
        "value": 139.815,
        "_internal_originalTime": 1689760780000
    },
    {
        "time": 1689760781000,
        "value": 139.812,
        "_internal_originalTime": 1689760781000
    },
    {
        "time": 1689760782000,
        "value": 139.816,
        "_internal_originalTime": 1689760782000
    },
    {
        "time": 1689760783000,
        "value": 139.814,
        "_internal_originalTime": 1689760783000
    },
    {
        "time": 1689760784000,
        "value": 139.817,
        "_internal_originalTime": 1689760784000
    },
    {
        "time": 1689760785000,
        "value": 139.812,
        "_internal_originalTime": 1689760785000
    },
    {
        "time": 1689760787000,
        "value": 139.818,
        "_internal_originalTime": 1689760787000
    },
    {
        "time": 1689760788000,
        "value": 139.813,
        "_internal_originalTime": 1689760788000
    },
    {
        "time": 1689760791000,
        "value": 139.812,
        "_internal_originalTime": 1689760791000
    },
    {
        "time": 1689760792000,
        "value": 139.815,
        "_internal_originalTime": 1689760792000
    },
    {
        "time": 1689760793000,
        "value": 139.817,
        "_internal_originalTime": 1689760793000
    },
    {
        "time": 1689760794000,
        "value": 139.814,
        "_internal_originalTime": 1689760794000
    },
    {
        "time": 1689760795000,
        "value": 139.812,
        "_internal_originalTime": 1689760795000
    },
    {
        "time": 1689760796000,
        "value": 139.818,
        "_internal_originalTime": 1689760796000
    },
    {
        "time": 1689760798000,
        "value": 139.816,
        "_internal_originalTime": 1689760798000
    },
    {
        "time": 1689760800000,
        "value": 139.815,
        "_internal_originalTime": 1689760800000
    },
    {
        "time": 1689760801000,
        "value": 139.814,
        "_internal_originalTime": 1689760801000
    },
    {
        "time": 1689760802000,
        "value": 139.817,
        "_internal_originalTime": 1689760802000
    },
    {
        "time": 1689760803000,
        "value": 139.818,
        "_internal_originalTime": 1689760803000
    },
    {
        "time": 1689760804000,
        "value": 139.821,
        "_internal_originalTime": 1689760804000
    },
    {
        "time": 1689760806000,
        "value": 139.816,
        "_internal_originalTime": 1689760806000
    },
    {
        "time": 1689760808000,
        "value": 139.82,
        "_internal_originalTime": 1689760808000
    },
    {
        "time": 1689760809000,
        "value": 139.821,
        "_internal_originalTime": 1689760809000
    },
    {
        "time": 1689760811000,
        "value": 139.826,
        "_internal_originalTime": 1689760811000
    },
    {
        "time": 1689760812000,
        "value": 139.828,
        "_internal_originalTime": 1689760812000
    },
    {
        "time": 1689760813000,
        "value": 139.831,
        "_internal_originalTime": 1689760813000
    },
    {
        "time": 1689760815000,
        "value": 139.826,
        "_internal_originalTime": 1689760815000
    },
    {
        "time": 1689760816000,
        "value": 139.828,
        "_internal_originalTime": 1689760816000
    },
    {
        "time": 1689760817000,
        "value": 139.827,
        "_internal_originalTime": 1689760817000
    },
    {
        "time": 1689760819000,
        "value": 139.824,
        "_internal_originalTime": 1689760819000
    },
    {
        "time": 1689760820000,
        "value": 139.827,
        "_internal_originalTime": 1689760820000
    },
    {
        "time": 1689760821000,
        "value": 139.827,
        "_internal_originalTime": 1689760821000
    },
    {
        "time": 1689760822000,
        "value": 139.828,
        "_internal_originalTime": 1689760822000
    },
    {
        "time": 1689760823000,
        "value": 139.827,
        "_internal_originalTime": 1689760823000
    },
    {
        "time": 1689760824000,
        "value": 139.827,
        "_internal_originalTime": 1689760824000
    },
    {
        "time": 1689760825000,
        "value": 139.824,
        "_internal_originalTime": 1689760825000
    },
    {
        "time": 1689760826000,
        "value": 139.822,
        "_internal_originalTime": 1689760826000
    },
    {
        "time": 1689760828000,
        "value": 139.821,
        "_internal_originalTime": 1689760828000
    },
    {
        "time": 1689760830000,
        "value": 139.818,
        "_internal_originalTime": 1689760830000
    },
    {
        "time": 1689760831000,
        "value": 139.815,
        "_internal_originalTime": 1689760831000
    },
    {
        "time": 1689760833000,
        "value": 139.81,
        "_internal_originalTime": 1689760833000
    },
    {
        "time": 1689760834000,
        "value": 139.812,
        "_internal_originalTime": 1689760834000
    },
    {
        "time": 1689760835000,
        "value": 139.81,
        "_internal_originalTime": 1689760835000
    },
    {
        "time": 1689760837000,
        "value": 139.809,
        "_internal_originalTime": 1689760837000
    },
    {
        "time": 1689760838000,
        "value": 139.812,
        "_internal_originalTime": 1689760838000
    },
    {
        "time": 1689760839000,
        "value": 139.812,
        "_internal_originalTime": 1689760839000
    },
    {
        "time": 1689760841000,
        "value": 139.808,
        "_internal_originalTime": 1689760841000
    },
    {
        "time": 1689760842000,
        "value": 139.811,
        "_internal_originalTime": 1689760842000
    },
    {
        "time": 1689760843000,
        "value": 139.812,
        "_internal_originalTime": 1689760843000
    },
    {
        "time": 1689760844000,
        "value": 139.812,
        "_internal_originalTime": 1689760844000
    },
    {
        "time": 1689760845000,
        "value": 139.806,
        "_internal_originalTime": 1689760845000
    },
    {
        "time": 1689760846000,
        "value": 139.81,
        "_internal_originalTime": 1689760846000
    },
    {
        "time": 1689760848000,
        "value": 139.808,
        "_internal_originalTime": 1689760848000
    },
    {
        "time": 1689760849000,
        "value": 139.81,
        "_internal_originalTime": 1689760849000
    },
    {
        "time": 1689760850000,
        "value": 139.809,
        "_internal_originalTime": 1689760850000
    },
    {
        "time": 1689760853000,
        "value": 139.81,
        "_internal_originalTime": 1689760853000
    },
    {
        "time": 1689760854000,
        "value": 139.809,
        "_internal_originalTime": 1689760854000
    },
    {
        "time": 1689760856000,
        "value": 139.808,
        "_internal_originalTime": 1689760856000
    },
    {
        "time": 1689760859000,
        "value": 139.816,
        "_internal_originalTime": 1689760859000
    },
    {
        "time": 1689760860000,
        "value": 139.79,
        "_internal_originalTime": 1689760860000
    },
    {
        "time": 1689760863000,
        "value": 139.816,
        "_internal_originalTime": 1689760863000
    },
    {
        "time": 1689760864000,
        "value": 139.816,
        "_internal_originalTime": 1689760864000
    },
    {
        "time": 1689760865000,
        "value": 139.818,
        "_internal_originalTime": 1689760865000
    },
    {
        "time": 1689760867000,
        "value": 139.82,
        "_internal_originalTime": 1689760867000
    },
    {
        "time": 1689760868000,
        "value": 139.823,
        "_internal_originalTime": 1689760868000
    },
    {
        "time": 1689760869000,
        "value": 139.818,
        "_internal_originalTime": 1689760869000
    },
    {
        "time": 1689760870000,
        "value": 139.813,
        "_internal_originalTime": 1689760870000
    },
    {
        "time": 1689760872000,
        "value": 139.816,
        "_internal_originalTime": 1689760872000
    },
    {
        "time": 1689760874000,
        "value": 139.819,
        "_internal_originalTime": 1689760874000
    },
    {
        "time": 1689760875000,
        "value": 139.824,
        "_internal_originalTime": 1689760875000
    },
    {
        "time": 1689760876000,
        "value": 139.822,
        "_internal_originalTime": 1689760876000
    },
    {
        "time": 1689760877000,
        "value": 139.827,
        "_internal_originalTime": 1689760877000
    },
    {
        "time": 1689760879000,
        "value": 139.828,
        "_internal_originalTime": 1689760879000
    },
    {
        "time": 1689760881000,
        "value": 139.828,
        "_internal_originalTime": 1689760881000
    },
    {
        "time": 1689760882000,
        "value": 139.826,
        "_internal_originalTime": 1689760882000
    },
    {
        "time": 1689760883000,
        "value": 139.829,
        "_internal_originalTime": 1689760883000
    },
    {
        "time": 1689760885000,
        "value": 139.829,
        "_internal_originalTime": 1689760885000
    },
    {
        "time": 1689760886000,
        "value": 139.831,
        "_internal_originalTime": 1689760886000
    },
    {
        "time": 1689760887000,
        "value": 139.823,
        "_internal_originalTime": 1689760887000
    },
    {
        "time": 1689760888000,
        "value": 139.818,
        "_internal_originalTime": 1689760888000
    },
    {
        "time": 1689760890000,
        "value": 139.815,
        "_internal_originalTime": 1689760890000
    },
    {
        "time": 1689760891000,
        "value": 139.812,
        "_internal_originalTime": 1689760891000
    },
    {
        "time": 1689760893000,
        "value": 139.817,
        "_internal_originalTime": 1689760893000
    },
    {
        "time": 1689760894000,
        "value": 139.82,
        "_internal_originalTime": 1689760894000
    },
    {
        "time": 1689760895000,
        "value": 139.819,
        "_internal_originalTime": 1689760895000
    },
    {
        "time": 1689760896000,
        "value": 139.822,
        "_internal_originalTime": 1689760896000
    },
    {
        "time": 1689760898000,
        "value": 139.82,
        "_internal_originalTime": 1689760898000
    },
    {
        "time": 1689760899000,
        "value": 139.822,
        "_internal_originalTime": 1689760899000
    },
    {
        "time": 1689760902000,
        "value": 139.823,
        "_internal_originalTime": 1689760902000
    },
    {
        "time": 1689760904000,
        "value": 139.818,
        "_internal_originalTime": 1689760904000
    },
    {
        "time": 1689760905000,
        "value": 139.827,
        "_internal_originalTime": 1689760905000
    },
    {
        "time": 1689760907000,
        "value": 139.838,
        "_internal_originalTime": 1689760907000
    },
    {
        "time": 1689760908000,
        "value": 139.837,
        "_internal_originalTime": 1689760908000
    },
    {
        "time": 1689760909000,
        "value": 139.839,
        "_internal_originalTime": 1689760909000
    },
    {
        "time": 1689760910000,
        "value": 139.841,
        "_internal_originalTime": 1689760910000
    },
    {
        "time": 1689760913000,
        "value": 139.838,
        "_internal_originalTime": 1689760913000
    },
    {
        "time": 1689760914000,
        "value": 139.841,
        "_internal_originalTime": 1689760914000
    },
    {
        "time": 1689760915000,
        "value": 139.839,
        "_internal_originalTime": 1689760915000
    },
    {
        "time": 1689760916000,
        "value": 139.847,
        "_internal_originalTime": 1689760916000
    },
    {
        "time": 1689760917000,
        "value": 139.847,
        "_internal_originalTime": 1689760917000
    },
    {
        "time": 1689760919000,
        "value": 139.847,
        "_internal_originalTime": 1689760919000
    },
    {
        "time": 1689760921000,
        "value": 139.82,
        "_internal_originalTime": 1689760921000
    },
    {
        "time": 1689760922000,
        "value": 139.847,
        "_internal_originalTime": 1689760922000
    },
    {
        "time": 1689760923000,
        "value": 139.849,
        "_internal_originalTime": 1689760923000
    },
    {
        "time": 1689760924000,
        "value": 139.845,
        "_internal_originalTime": 1689760924000
    },
    {
        "time": 1689760926000,
        "value": 139.846,
        "_internal_originalTime": 1689760926000
    },
    {
        "time": 1689760927000,
        "value": 139.847,
        "_internal_originalTime": 1689760927000
    },
    {
        "time": 1689760928000,
        "value": 139.849,
        "_internal_originalTime": 1689760928000
    },
    {
        "time": 1689760930000,
        "value": 139.848,
        "_internal_originalTime": 1689760930000
    },
    {
        "time": 1689760932000,
        "value": 139.846,
        "_internal_originalTime": 1689760932000
    },
    {
        "time": 1689760934000,
        "value": 139.846,
        "_internal_originalTime": 1689760934000
    },
    {
        "time": 1689760935000,
        "value": 139.848,
        "_internal_originalTime": 1689760935000
    },
    {
        "time": 1689760936000,
        "value": 139.849,
        "_internal_originalTime": 1689760936000
    },
    {
        "time": 1689760937000,
        "value": 139.847,
        "_internal_originalTime": 1689760937000
    },
    {
        "time": 1689760939000,
        "value": 139.848,
        "_internal_originalTime": 1689760939000
    },
    {
        "time": 1689760940000,
        "value": 139.84,
        "_internal_originalTime": 1689760940000
    },
    {
        "time": 1689760941000,
        "value": 139.844,
        "_internal_originalTime": 1689760941000
    },
    {
        "time": 1689760942000,
        "value": 139.846,
        "_internal_originalTime": 1689760942000
    },
    {
        "time": 1689760943000,
        "value": 139.842,
        "_internal_originalTime": 1689760943000
    },
    {
        "time": 1689760945000,
        "value": 139.84,
        "_internal_originalTime": 1689760945000
    },
    {
        "time": 1689760946000,
        "value": 139.836,
        "_internal_originalTime": 1689760946000
    },
    {
        "time": 1689760948000,
        "value": 139.833,
        "_internal_originalTime": 1689760948000
    },
    {
        "time": 1689760950000,
        "value": 139.829,
        "_internal_originalTime": 1689760950000
    },
    {
        "time": 1689760952000,
        "value": 139.827,
        "_internal_originalTime": 1689760952000
    },
    {
        "time": 1689760953000,
        "value": 139.83,
        "_internal_originalTime": 1689760953000
    },
    {
        "time": 1689760955000,
        "value": 139.827,
        "_internal_originalTime": 1689760955000
    },
    {
        "time": 1689760956000,
        "value": 139.828,
        "_internal_originalTime": 1689760956000
    },
    {
        "time": 1689760957000,
        "value": 139.829,
        "_internal_originalTime": 1689760957000
    },
    {
        "time": 1689760958000,
        "value": 139.836,
        "_internal_originalTime": 1689760958000
    },
    {
        "time": 1689760959000,
        "value": 139.838,
        "_internal_originalTime": 1689760959000
    },
    {
        "time": 1689760961000,
        "value": 139.839,
        "_internal_originalTime": 1689760961000
    },
    {
        "time": 1689760962000,
        "value": 139.839,
        "_internal_originalTime": 1689760962000
    },
    {
        "time": 1689760963000,
        "value": 139.839,
        "_internal_originalTime": 1689760963000
    },
    {
        "time": 1689760965000,
        "value": 139.84,
        "_internal_originalTime": 1689760965000
    },
    {
        "time": 1689760966000,
        "value": 139.833,
        "_internal_originalTime": 1689760966000
    },
    {
        "time": 1689760968000,
        "value": 139.824,
        "_internal_originalTime": 1689760968000
    },
    {
        "time": 1689760969000,
        "value": 139.825,
        "_internal_originalTime": 1689760969000
    },
    {
        "time": 1689760970000,
        "value": 139.826,
        "_internal_originalTime": 1689760970000
    },
    {
        "time": 1689760973000,
        "value": 139.827,
        "_internal_originalTime": 1689760973000
    },
    {
        "time": 1689760975000,
        "value": 139.826,
        "_internal_originalTime": 1689760975000
    },
    {
        "time": 1689760976000,
        "value": 139.825,
        "_internal_originalTime": 1689760976000
    },
    {
        "time": 1689760978000,
        "value": 139.824,
        "_internal_originalTime": 1689760978000
    },
    {
        "time": 1689760980000,
        "value": 139.832,
        "_internal_originalTime": 1689760980000
    },
    {
        "time": 1689760982000,
        "value": 139.81,
        "_internal_originalTime": 1689760982000
    },
    {
        "time": 1689760983000,
        "value": 139.829,
        "_internal_originalTime": 1689760983000
    },
    {
        "time": 1689760984000,
        "value": 139.829,
        "_internal_originalTime": 1689760984000
    },
    {
        "time": 1689760986000,
        "value": 139.829,
        "_internal_originalTime": 1689760986000
    },
    {
        "time": 1689760987000,
        "value": 139.832,
        "_internal_originalTime": 1689760987000
    },
    {
        "time": 1689760988000,
        "value": 139.831,
        "_internal_originalTime": 1689760988000
    },
    {
        "time": 1689760990000,
        "value": 139.832,
        "_internal_originalTime": 1689760990000
    },
    {
        "time": 1689760991000,
        "value": 139.836,
        "_internal_originalTime": 1689760991000
    },
    {
        "time": 1689760994000,
        "value": 139.835,
        "_internal_originalTime": 1689760994000
    },
    {
        "time": 1689760995000,
        "value": 139.837,
        "_internal_originalTime": 1689760995000
    },
    {
        "time": 1689760997000,
        "value": 139.839,
        "_internal_originalTime": 1689760997000
    },
    {
        "time": 1689760998000,
        "value": 139.837,
        "_internal_originalTime": 1689760998000
    },
    {
        "time": 1689761000000,
        "value": 139.839,
        "_internal_originalTime": 1689761000000
    },
    {
        "time": 1689761002000,
        "value": 139.841,
        "_internal_originalTime": 1689761002000
    },
    {
        "time": 1689761003000,
        "value": 139.838,
        "_internal_originalTime": 1689761003000
    },
    {
        "time": 1689761005000,
        "value": 139.837,
        "_internal_originalTime": 1689761005000
    },
    {
        "time": 1689761007000,
        "value": 139.836,
        "_internal_originalTime": 1689761007000
    },
    {
        "time": 1689761008000,
        "value": 139.836,
        "_internal_originalTime": 1689761008000
    },
    {
        "time": 1689761009000,
        "value": 139.832,
        "_internal_originalTime": 1689761009000
    },
    {
        "time": 1689761010000,
        "value": 139.836,
        "_internal_originalTime": 1689761010000
    },
    {
        "time": 1689761011000,
        "value": 139.835,
        "_internal_originalTime": 1689761011000
    },
    {
        "time": 1689761012000,
        "value": 139.829,
        "_internal_originalTime": 1689761012000
    },
    {
        "time": 1689761013000,
        "value": 139.833,
        "_internal_originalTime": 1689761013000
    },
    {
        "time": 1689761015000,
        "value": 139.83,
        "_internal_originalTime": 1689761015000
    },
    {
        "time": 1689761016000,
        "value": 139.833,
        "_internal_originalTime": 1689761016000
    },
    {
        "time": 1689761018000,
        "value": 139.839,
        "_internal_originalTime": 1689761018000
    },
    {
        "time": 1689761019000,
        "value": 139.841,
        "_internal_originalTime": 1689761019000
    },
    {
        "time": 1689761020000,
        "value": 139.839,
        "_internal_originalTime": 1689761020000
    },
    {
        "time": 1689761021000,
        "value": 139.844,
        "_internal_originalTime": 1689761021000
    },
    {
        "time": 1689761023000,
        "value": 139.848,
        "_internal_originalTime": 1689761023000
    },
    {
        "time": 1689761024000,
        "value": 139.845,
        "_internal_originalTime": 1689761024000
    },
    {
        "time": 1689761025000,
        "value": 139.849,
        "_internal_originalTime": 1689761025000
    },
    {
        "time": 1689761027000,
        "value": 139.846,
        "_internal_originalTime": 1689761027000
    },
    {
        "time": 1689761028000,
        "value": 139.845,
        "_internal_originalTime": 1689761028000
    },
    {
        "time": 1689761029000,
        "value": 139.846,
        "_internal_originalTime": 1689761029000
    },
    {
        "time": 1689761030000,
        "value": 139.846,
        "_internal_originalTime": 1689761030000
    },
    {
        "time": 1689761031000,
        "value": 139.847,
        "_internal_originalTime": 1689761031000
    },
    {
        "time": 1689761033000,
        "value": 139.845,
        "_internal_originalTime": 1689761033000
    },
    {
        "time": 1689761034000,
        "value": 139.843,
        "_internal_originalTime": 1689761034000
    },
    {
        "time": 1689761035000,
        "value": 139.844,
        "_internal_originalTime": 1689761035000
    },
    {
        "time": 1689761037000,
        "value": 139.839,
        "_internal_originalTime": 1689761037000
    },
    {
        "time": 1689761038000,
        "value": 139.842,
        "_internal_originalTime": 1689761038000
    },
    {
        "time": 1689761039000,
        "value": 139.842,
        "_internal_originalTime": 1689761039000
    },
    {
        "time": 1689761040000,
        "value": 139.846,
        "_internal_originalTime": 1689761040000
    },
    {
        "time": 1689761041000,
        "value": 139.847,
        "_internal_originalTime": 1689761041000
    },
    {
        "time": 1689761042000,
        "value": 139.841,
        "_internal_originalTime": 1689761042000
    },
    {
        "time": 1689761043000,
        "value": 139.842,
        "_internal_originalTime": 1689761043000
    },
    {
        "time": 1689761045000,
        "value": 139.835,
        "_internal_originalTime": 1689761045000
    },
    {
        "time": 1689761049000,
        "value": 139.847,
        "_internal_originalTime": 1689761049000
    },
    {
        "time": 1689761052000,
        "value": 139.848,
        "_internal_originalTime": 1689761052000
    },
    {
        "time": 1689761053000,
        "value": 139.846,
        "_internal_originalTime": 1689761053000
    },
    {
        "time": 1689761054000,
        "value": 139.847,
        "_internal_originalTime": 1689761054000
    },
    {
        "time": 1689761055000,
        "value": 139.848,
        "_internal_originalTime": 1689761055000
    },
    {
        "time": 1689761057000,
        "value": 139.85,
        "_internal_originalTime": 1689761057000
    },
    {
        "time": 1689761058000,
        "value": 139.844,
        "_internal_originalTime": 1689761058000
    },
    {
        "time": 1689761059000,
        "value": 139.85,
        "_internal_originalTime": 1689761059000
    },
    {
        "time": 1689761061000,
        "value": 139.849,
        "_internal_originalTime": 1689761061000
    },
    {
        "time": 1689761062000,
        "value": 139.855,
        "_internal_originalTime": 1689761062000
    },
    {
        "time": 1689761063000,
        "value": 139.853,
        "_internal_originalTime": 1689761063000
    },
    {
        "time": 1689761064000,
        "value": 139.855,
        "_internal_originalTime": 1689761064000
    },
    {
        "time": 1689761065000,
        "value": 139.855,
        "_internal_originalTime": 1689761065000
    },
    {
        "time": 1689761067000,
        "value": 139.853,
        "_internal_originalTime": 1689761067000
    },
    {
        "time": 1689761068000,
        "value": 139.854,
        "_internal_originalTime": 1689761068000
    },
    {
        "time": 1689761070000,
        "value": 139.853,
        "_internal_originalTime": 1689761070000
    },
    {
        "time": 1689761071000,
        "value": 139.855,
        "_internal_originalTime": 1689761071000
    },
    {
        "time": 1689761072000,
        "value": 139.848,
        "_internal_originalTime": 1689761072000
    },
    {
        "time": 1689761073000,
        "value": 139.849,
        "_internal_originalTime": 1689761073000
    },
    {
        "time": 1689761074000,
        "value": 139.85,
        "_internal_originalTime": 1689761074000
    },
    {
        "time": 1689761075000,
        "value": 139.842,
        "_internal_originalTime": 1689761075000
    },
    {
        "time": 1689761077000,
        "value": 139.848,
        "_internal_originalTime": 1689761077000
    },
    {
        "time": 1689761078000,
        "value": 139.848,
        "_internal_originalTime": 1689761078000
    },
    {
        "time": 1689761079000,
        "value": 139.846,
        "_internal_originalTime": 1689761079000
    },
    {
        "time": 1689761083000,
        "value": 139.85,
        "_internal_originalTime": 1689761083000
    },
    {
        "time": 1689761084000,
        "value": 139.851,
        "_internal_originalTime": 1689761084000
    },
    {
        "time": 1689761085000,
        "value": 139.848,
        "_internal_originalTime": 1689761085000
    },
    {
        "time": 1689761086000,
        "value": 139.851,
        "_internal_originalTime": 1689761086000
    },
    {
        "time": 1689761087000,
        "value": 139.857,
        "_internal_originalTime": 1689761087000
    },
    {
        "time": 1689761088000,
        "value": 139.855,
        "_internal_originalTime": 1689761088000
    },
    {
        "time": 1689761090000,
        "value": 139.848,
        "_internal_originalTime": 1689761090000
    },
    {
        "time": 1689761091000,
        "value": 139.846,
        "_internal_originalTime": 1689761091000
    },
    {
        "time": 1689761092000,
        "value": 139.846,
        "_internal_originalTime": 1689761092000
    },
    {
        "time": 1689761093000,
        "value": 139.842,
        "_internal_originalTime": 1689761093000
    },
    {
        "time": 1689761094000,
        "value": 139.845,
        "_internal_originalTime": 1689761094000
    },
    {
        "time": 1689761096000,
        "value": 139.845,
        "_internal_originalTime": 1689761096000
    },
    {
        "time": 1689761098000,
        "value": 139.841,
        "_internal_originalTime": 1689761098000
    },
    {
        "time": 1689761099000,
        "value": 139.841,
        "_internal_originalTime": 1689761099000
    },
    {
        "time": 1689761100000,
        "value": 139.847,
        "_internal_originalTime": 1689761100000
    },
    {
        "time": 1689761101000,
        "value": 139.848,
        "_internal_originalTime": 1689761101000
    },
    {
        "time": 1689761102000,
        "value": 139.843,
        "_internal_originalTime": 1689761102000
    },
    {
        "time": 1689761104000,
        "value": 139.846,
        "_internal_originalTime": 1689761104000
    },
    {
        "time": 1689761105000,
        "value": 139.84,
        "_internal_originalTime": 1689761105000
    },
    {
        "time": 1689761106000,
        "value": 139.844,
        "_internal_originalTime": 1689761106000
    },
    {
        "time": 1689761108000,
        "value": 139.841,
        "_internal_originalTime": 1689761108000
    },
    {
        "time": 1689761110000,
        "value": 139.839,
        "_internal_originalTime": 1689761110000
    },
    {
        "time": 1689761111000,
        "value": 139.84,
        "_internal_originalTime": 1689761111000
    },
    {
        "time": 1689761113000,
        "value": 139.84,
        "_internal_originalTime": 1689761113000
    },
    {
        "time": 1689761114000,
        "value": 139.839,
        "_internal_originalTime": 1689761114000
    },
    {
        "time": 1689761115000,
        "value": 139.839,
        "_internal_originalTime": 1689761115000
    },
    {
        "time": 1689761117000,
        "value": 139.836,
        "_internal_originalTime": 1689761117000
    },
    {
        "time": 1689761118000,
        "value": 139.841,
        "_internal_originalTime": 1689761118000
    },
    {
        "time": 1689761120000,
        "value": 139.846,
        "_internal_originalTime": 1689761120000
    },
    {
        "time": 1689761121000,
        "value": 139.848,
        "_internal_originalTime": 1689761121000
    },
    {
        "time": 1689761122000,
        "value": 139.848,
        "_internal_originalTime": 1689761122000
    },
    {
        "time": 1689761123000,
        "value": 139.846,
        "_internal_originalTime": 1689761123000
    },
    {
        "time": 1689761124000,
        "value": 139.844,
        "_internal_originalTime": 1689761124000
    },
    {
        "time": 1689761126000,
        "value": 139.849,
        "_internal_originalTime": 1689761126000
    },
    {
        "time": 1689761128000,
        "value": 139.842,
        "_internal_originalTime": 1689761128000
    },
    {
        "time": 1689761129000,
        "value": 139.848,
        "_internal_originalTime": 1689761129000
    },
    {
        "time": 1689761131000,
        "value": 139.852,
        "_internal_originalTime": 1689761131000
    },
    {
        "time": 1689761132000,
        "value": 139.852,
        "_internal_originalTime": 1689761132000
    },
    {
        "time": 1689761134000,
        "value": 139.856,
        "_internal_originalTime": 1689761134000
    },
    {
        "time": 1689761135000,
        "value": 139.851,
        "_internal_originalTime": 1689761135000
    },
    {
        "time": 1689761137000,
        "value": 139.857,
        "_internal_originalTime": 1689761137000
    },
    {
        "time": 1689761138000,
        "value": 139.852,
        "_internal_originalTime": 1689761138000
    },
    {
        "time": 1689761139000,
        "value": 139.855,
        "_internal_originalTime": 1689761139000
    },
    {
        "time": 1689761140000,
        "value": 139.848,
        "_internal_originalTime": 1689761140000
    },
    {
        "time": 1689761141000,
        "value": 139.845,
        "_internal_originalTime": 1689761141000
    },
    {
        "time": 1689761143000,
        "value": 139.849,
        "_internal_originalTime": 1689761143000
    },
    {
        "time": 1689761144000,
        "value": 139.847,
        "_internal_originalTime": 1689761144000
    },
    {
        "time": 1689761145000,
        "value": 139.847,
        "_internal_originalTime": 1689761145000
    },
    {
        "time": 1689761147000,
        "value": 139.847,
        "_internal_originalTime": 1689761147000
    },
    {
        "time": 1689761148000,
        "value": 139.851,
        "_internal_originalTime": 1689761148000
    },
    {
        "time": 1689761149000,
        "value": 139.851,
        "_internal_originalTime": 1689761149000
    },
    {
        "time": 1689761150000,
        "value": 139.85,
        "_internal_originalTime": 1689761150000
    },
    {
        "time": 1689761151000,
        "value": 139.848,
        "_internal_originalTime": 1689761151000
    },
    {
        "time": 1689761153000,
        "value": 139.85,
        "_internal_originalTime": 1689761153000
    },
    {
        "time": 1689761154000,
        "value": 139.847,
        "_internal_originalTime": 1689761154000
    },
    {
        "time": 1689761155000,
        "value": 139.85,
        "_internal_originalTime": 1689761155000
    },
    {
        "time": 1689761156000,
        "value": 139.85,
        "_internal_originalTime": 1689761156000
    },
    {
        "time": 1689761158000,
        "value": 139.848,
        "_internal_originalTime": 1689761158000
    },
    {
        "time": 1689761159000,
        "value": 139.851,
        "_internal_originalTime": 1689761159000
    },
    {
        "time": 1689761160000,
        "value": 139.849,
        "_internal_originalTime": 1689761160000
    },
    {
        "time": 1689761161000,
        "value": 139.848,
        "_internal_originalTime": 1689761161000
    },
    {
        "time": 1689761162000,
        "value": 139.845,
        "_internal_originalTime": 1689761162000
    },
    {
        "time": 1689761164000,
        "value": 139.847,
        "_internal_originalTime": 1689761164000
    },
    {
        "time": 1689761165000,
        "value": 139.848,
        "_internal_originalTime": 1689761165000
    },
    {
        "time": 1689761167000,
        "value": 139.848,
        "_internal_originalTime": 1689761167000
    },
    {
        "time": 1689761168000,
        "value": 139.847,
        "_internal_originalTime": 1689761168000
    },
    {
        "time": 1689761169000,
        "value": 139.842,
        "_internal_originalTime": 1689761169000
    },
    {
        "time": 1689761171000,
        "value": 139.843,
        "_internal_originalTime": 1689761171000
    },
    {
        "time": 1689761172000,
        "value": 139.839,
        "_internal_originalTime": 1689761172000
    },
    {
        "time": 1689761173000,
        "value": 139.843,
        "_internal_originalTime": 1689761173000
    },
    {
        "time": 1689761174000,
        "value": 139.838,
        "_internal_originalTime": 1689761174000
    },
    {
        "time": 1689761176000,
        "value": 139.836,
        "_internal_originalTime": 1689761176000
    },
    {
        "time": 1689761177000,
        "value": 139.838,
        "_internal_originalTime": 1689761177000
    },
    {
        "time": 1689761178000,
        "value": 139.838,
        "_internal_originalTime": 1689761178000
    },
    {
        "time": 1689761183000,
        "value": 139.835,
        "_internal_originalTime": 1689761183000
    },
    {
        "time": 1689761184000,
        "value": 139.828,
        "_internal_originalTime": 1689761184000
    },
    {
        "time": 1689761185000,
        "value": 139.831,
        "_internal_originalTime": 1689761185000
    },
    {
        "time": 1689761187000,
        "value": 139.825,
        "_internal_originalTime": 1689761187000
    },
    {
        "time": 1689761189000,
        "value": 139.831,
        "_internal_originalTime": 1689761189000
    },
    {
        "time": 1689761190000,
        "value": 139.828,
        "_internal_originalTime": 1689761190000
    },
    {
        "time": 1689761191000,
        "value": 139.827,
        "_internal_originalTime": 1689761191000
    },
    {
        "time": 1689761192000,
        "value": 139.827,
        "_internal_originalTime": 1689761192000
    },
    {
        "time": 1689761194000,
        "value": 139.829,
        "_internal_originalTime": 1689761194000
    },
    {
        "time": 1689761195000,
        "value": 139.823,
        "_internal_originalTime": 1689761195000
    },
    {
        "time": 1689761198000,
        "value": 139.827,
        "_internal_originalTime": 1689761198000
    },
    {
        "time": 1689761199000,
        "value": 139.827,
        "_internal_originalTime": 1689761199000
    },
    {
        "time": 1689761201000,
        "value": 139.828,
        "_internal_originalTime": 1689761201000
    },
    {
        "time": 1689761203000,
        "value": 139.828,
        "_internal_originalTime": 1689761203000
    },
    {
        "time": 1689761208000,
        "value": 139.823,
        "_internal_originalTime": 1689761208000
    },
    {
        "time": 1689761209000,
        "value": 139.809,
        "_internal_originalTime": 1689761209000
    },
    {
        "time": 1689761210000,
        "value": 139.81,
        "_internal_originalTime": 1689761210000
    },
    {
        "time": 1689761211000,
        "value": 139.807,
        "_internal_originalTime": 1689761211000
    },
    {
        "time": 1689761213000,
        "value": 139.796,
        "_internal_originalTime": 1689761213000
    },
    {
        "time": 1689761215000,
        "value": 139.8,
        "_internal_originalTime": 1689761215000
    },
    {
        "time": 1689761216000,
        "value": 139.8,
        "_internal_originalTime": 1689761216000
    },
    {
        "time": 1689761217000,
        "value": 139.802,
        "_internal_originalTime": 1689761217000
    },
    {
        "time": 1689761219000,
        "value": 139.795,
        "_internal_originalTime": 1689761219000
    },
    {
        "time": 1689761220000,
        "value": 139.797,
        "_internal_originalTime": 1689761220000
    },
    {
        "time": 1689761221000,
        "value": 139.8,
        "_internal_originalTime": 1689761221000
    },
    {
        "time": 1689761222000,
        "value": 139.805,
        "_internal_originalTime": 1689761222000
    },
    {
        "time": 1689761224000,
        "value": 139.81,
        "_internal_originalTime": 1689761224000
    },
    {
        "time": 1689761225000,
        "value": 139.817,
        "_internal_originalTime": 1689761225000
    },
    {
        "time": 1689761226000,
        "value": 139.807,
        "_internal_originalTime": 1689761226000
    },
    {
        "time": 1689761227000,
        "value": 139.805,
        "_internal_originalTime": 1689761227000
    },
    {
        "time": 1689761229000,
        "value": 139.81,
        "_internal_originalTime": 1689761229000
    },
    {
        "time": 1689761230000,
        "value": 139.817,
        "_internal_originalTime": 1689761230000
    },
    {
        "time": 1689761231000,
        "value": 139.819,
        "_internal_originalTime": 1689761231000
    },
    {
        "time": 1689761232000,
        "value": 139.82,
        "_internal_originalTime": 1689761232000
    },
    {
        "time": 1689761233000,
        "value": 139.82,
        "_internal_originalTime": 1689761233000
    },
    {
        "time": 1689761235000,
        "value": 139.819,
        "_internal_originalTime": 1689761235000
    },
    {
        "time": 1689761236000,
        "value": 139.822,
        "_internal_originalTime": 1689761236000
    },
    {
        "time": 1689761238000,
        "value": 139.822,
        "_internal_originalTime": 1689761238000
    },
    {
        "time": 1689761239000,
        "value": 139.826,
        "_internal_originalTime": 1689761239000
    },
    {
        "time": 1689761240000,
        "value": 139.82,
        "_internal_originalTime": 1689761240000
    },
    {
        "time": 1689761241000,
        "value": 139.816,
        "_internal_originalTime": 1689761241000
    },
    {
        "time": 1689761243000,
        "value": 139.815,
        "_internal_originalTime": 1689761243000
    },
    {
        "time": 1689761244000,
        "value": 139.809,
        "_internal_originalTime": 1689761244000
    },
    {
        "time": 1689761245000,
        "value": 139.809,
        "_internal_originalTime": 1689761245000
    },
    {
        "time": 1689761247000,
        "value": 139.804,
        "_internal_originalTime": 1689761247000
    },
    {
        "time": 1689761248000,
        "value": 139.809,
        "_internal_originalTime": 1689761248000
    },
    {
        "time": 1689761249000,
        "value": 139.811,
        "_internal_originalTime": 1689761249000
    },
    {
        "time": 1689761250000,
        "value": 139.811,
        "_internal_originalTime": 1689761250000
    },
    {
        "time": 1689761251000,
        "value": 139.809,
        "_internal_originalTime": 1689761251000
    },
    {
        "time": 1689761252000,
        "value": 139.809,
        "_internal_originalTime": 1689761252000
    },
    {
        "time": 1689761253000,
        "value": 139.809,
        "_internal_originalTime": 1689761253000
    },
    {
        "time": 1689761255000,
        "value": 139.807,
        "_internal_originalTime": 1689761255000
    },
    {
        "time": 1689761256000,
        "value": 139.808,
        "_internal_originalTime": 1689761256000
    },
    {
        "time": 1689761258000,
        "value": 139.827,
        "_internal_originalTime": 1689761258000
    },
    {
        "time": 1689761259000,
        "value": 139.81,
        "_internal_originalTime": 1689761259000
    },
    {
        "time": 1689761260000,
        "value": 139.807,
        "_internal_originalTime": 1689761260000
    },
    {
        "time": 1689761262000,
        "value": 139.806,
        "_internal_originalTime": 1689761262000
    },
    {
        "time": 1689761263000,
        "value": 139.812,
        "_internal_originalTime": 1689761263000
    },
    {
        "time": 1689761264000,
        "value": 139.809,
        "_internal_originalTime": 1689761264000
    },
    {
        "time": 1689761266000,
        "value": 139.809,
        "_internal_originalTime": 1689761266000
    },
    {
        "time": 1689761267000,
        "value": 139.808,
        "_internal_originalTime": 1689761267000
    },
    {
        "time": 1689761268000,
        "value": 139.804,
        "_internal_originalTime": 1689761268000
    },
    {
        "time": 1689761269000,
        "value": 139.805,
        "_internal_originalTime": 1689761269000
    },
    {
        "time": 1689761271000,
        "value": 139.806,
        "_internal_originalTime": 1689761271000
    },
    {
        "time": 1689761274000,
        "value": 139.802,
        "_internal_originalTime": 1689761274000
    },
    {
        "time": 1689761275000,
        "value": 139.796,
        "_internal_originalTime": 1689761275000
    },
    {
        "time": 1689761276000,
        "value": 139.802,
        "_internal_originalTime": 1689761276000
    },
    {
        "time": 1689761277000,
        "value": 139.799,
        "_internal_originalTime": 1689761277000
    },
    {
        "time": 1689761279000,
        "value": 139.796,
        "_internal_originalTime": 1689761279000
    },
    {
        "time": 1689761280000,
        "value": 139.796,
        "_internal_originalTime": 1689761280000
    },
    {
        "time": 1689761281000,
        "value": 139.795,
        "_internal_originalTime": 1689761281000
    },
    {
        "time": 1689761283000,
        "value": 139.801,
        "_internal_originalTime": 1689761283000
    },
    {
        "time": 1689761284000,
        "value": 139.813,
        "_internal_originalTime": 1689761284000
    },
    {
        "time": 1689761285000,
        "value": 139.808,
        "_internal_originalTime": 1689761285000
    },
    {
        "time": 1689761286000,
        "value": 139.808,
        "_internal_originalTime": 1689761286000
    },
    {
        "time": 1689761287000,
        "value": 139.808,
        "_internal_originalTime": 1689761287000
    },
    {
        "time": 1689761288000,
        "value": 139.812,
        "_internal_originalTime": 1689761288000
    },
    {
        "time": 1689761289000,
        "value": 139.813,
        "_internal_originalTime": 1689761289000
    },
    {
        "time": 1689761291000,
        "value": 139.808,
        "_internal_originalTime": 1689761291000
    },
    {
        "time": 1689761292000,
        "value": 139.81,
        "_internal_originalTime": 1689761292000
    },
    {
        "time": 1689761294000,
        "value": 139.806,
        "_internal_originalTime": 1689761294000
    },
    {
        "time": 1689761296000,
        "value": 139.809,
        "_internal_originalTime": 1689761296000
    },
    {
        "time": 1689761297000,
        "value": 139.811,
        "_internal_originalTime": 1689761297000
    },
    {
        "time": 1689761298000,
        "value": 139.812,
        "_internal_originalTime": 1689761298000
    },
    {
        "time": 1689761300000,
        "value": 139.815,
        "_internal_originalTime": 1689761300000
    },
    {
        "time": 1689761302000,
        "value": 139.811,
        "_internal_originalTime": 1689761302000
    },
    {
        "time": 1689761304000,
        "value": 139.812,
        "_internal_originalTime": 1689761304000
    },
    {
        "time": 1689761305000,
        "value": 139.815,
        "_internal_originalTime": 1689761305000
    },
    {
        "time": 1689761306000,
        "value": 139.807,
        "_internal_originalTime": 1689761306000
    },
    {
        "time": 1689761307000,
        "value": 139.805,
        "_internal_originalTime": 1689761307000
    },
    {
        "time": 1689761310000,
        "value": 139.803,
        "_internal_originalTime": 1689761310000
    },
    {
        "time": 1689761311000,
        "value": 139.803,
        "_internal_originalTime": 1689761311000
    },
    {
        "time": 1689761312000,
        "value": 139.799,
        "_internal_originalTime": 1689761312000
    },
    {
        "time": 1689761313000,
        "value": 139.798,
        "_internal_originalTime": 1689761313000
    },
    {
        "time": 1689761315000,
        "value": 139.793,
        "_internal_originalTime": 1689761315000
    },
    {
        "time": 1689761317000,
        "value": 139.801,
        "_internal_originalTime": 1689761317000
    },
    {
        "time": 1689761318000,
        "value": 139.799,
        "_internal_originalTime": 1689761318000
    },
    {
        "time": 1689761320000,
        "value": 139.799,
        "_internal_originalTime": 1689761320000
    },
    {
        "time": 1689761323000,
        "value": 139.8,
        "_internal_originalTime": 1689761323000
    },
    {
        "time": 1689761325000,
        "value": 139.803,
        "_internal_originalTime": 1689761325000
    },
    {
        "time": 1689761327000,
        "value": 139.804,
        "_internal_originalTime": 1689761327000
    },
    {
        "time": 1689761329000,
        "value": 139.805,
        "_internal_originalTime": 1689761329000
    },
    {
        "time": 1689761330000,
        "value": 139.808,
        "_internal_originalTime": 1689761330000
    },
    {
        "time": 1689761332000,
        "value": 139.804,
        "_internal_originalTime": 1689761332000
    },
    {
        "time": 1689761334000,
        "value": 139.808,
        "_internal_originalTime": 1689761334000
    },
    {
        "time": 1689761335000,
        "value": 139.805,
        "_internal_originalTime": 1689761335000
    },
    {
        "time": 1689761336000,
        "value": 139.809,
        "_internal_originalTime": 1689761336000
    },
    {
        "time": 1689761337000,
        "value": 139.809,
        "_internal_originalTime": 1689761337000
    },
    {
        "time": 1689761339000,
        "value": 139.807,
        "_internal_originalTime": 1689761339000
    },
    {
        "time": 1689761340000,
        "value": 139.811,
        "_internal_originalTime": 1689761340000
    },
    {
        "time": 1689761341000,
        "value": 139.805,
        "_internal_originalTime": 1689761341000
    },
    {
        "time": 1689761342000,
        "value": 139.807,
        "_internal_originalTime": 1689761342000
    },
    {
        "time": 1689761343000,
        "value": 139.806,
        "_internal_originalTime": 1689761343000
    },
    {
        "time": 1689761345000,
        "value": 139.806,
        "_internal_originalTime": 1689761345000
    },
    {
        "time": 1689761346000,
        "value": 139.808,
        "_internal_originalTime": 1689761346000
    },
    {
        "time": 1689761347000,
        "value": 139.807,
        "_internal_originalTime": 1689761347000
    },
    {
        "time": 1689761349000,
        "value": 139.806,
        "_internal_originalTime": 1689761349000
    },
    {
        "time": 1689761350000,
        "value": 139.805,
        "_internal_originalTime": 1689761350000
    },
    {
        "time": 1689761351000,
        "value": 139.801,
        "_internal_originalTime": 1689761351000
    },
    {
        "time": 1689761353000,
        "value": 139.799,
        "_internal_originalTime": 1689761353000
    },
    {
        "time": 1689761355000,
        "value": 139.796,
        "_internal_originalTime": 1689761355000
    },
    {
        "time": 1689761357000,
        "value": 139.798,
        "_internal_originalTime": 1689761357000
    },
    {
        "time": 1689761358000,
        "value": 139.798,
        "_internal_originalTime": 1689761358000
    },
    {
        "time": 1689761359000,
        "value": 139.8,
        "_internal_originalTime": 1689761359000
    },
    {
        "time": 1689761360000,
        "value": 139.8,
        "_internal_originalTime": 1689761360000
    },
    {
        "time": 1689761362000,
        "value": 139.801,
        "_internal_originalTime": 1689761362000
    },
    {
        "time": 1689761363000,
        "value": 139.805,
        "_internal_originalTime": 1689761363000
    },
    {
        "time": 1689761364000,
        "value": 139.805,
        "_internal_originalTime": 1689761364000
    },
    {
        "time": 1689761366000,
        "value": 139.808,
        "_internal_originalTime": 1689761366000
    },
    {
        "time": 1689761367000,
        "value": 139.804,
        "_internal_originalTime": 1689761367000
    },
    {
        "time": 1689761369000,
        "value": 139.806,
        "_internal_originalTime": 1689761369000
    },
    {
        "time": 1689761370000,
        "value": 139.807,
        "_internal_originalTime": 1689761370000
    },
    {
        "time": 1689761371000,
        "value": 139.806,
        "_internal_originalTime": 1689761371000
    },
    {
        "time": 1689761372000,
        "value": 139.802,
        "_internal_originalTime": 1689761372000
    },
    {
        "time": 1689761374000,
        "value": 139.807,
        "_internal_originalTime": 1689761374000
    },
    {
        "time": 1689761376000,
        "value": 139.804,
        "_internal_originalTime": 1689761376000
    },
    {
        "time": 1689761378000,
        "value": 139.826,
        "_internal_originalTime": 1689761378000
    },
    {
        "time": 1689761384000,
        "value": 139.807,
        "_internal_originalTime": 1689761384000
    },
    {
        "time": 1689761386000,
        "value": 139.807,
        "_internal_originalTime": 1689761386000
    },
    {
        "time": 1689761387000,
        "value": 139.806,
        "_internal_originalTime": 1689761387000
    },
    {
        "time": 1689761389000,
        "value": 139.807,
        "_internal_originalTime": 1689761389000
    },
    {
        "time": 1689761390000,
        "value": 139.807,
        "_internal_originalTime": 1689761390000
    },
    {
        "time": 1689761391000,
        "value": 139.81,
        "_internal_originalTime": 1689761391000
    },
    {
        "time": 1689761392000,
        "value": 139.808,
        "_internal_originalTime": 1689761392000
    },
    {
        "time": 1689761394000,
        "value": 139.808,
        "_internal_originalTime": 1689761394000
    },
    {
        "time": 1689761395000,
        "value": 139.808,
        "_internal_originalTime": 1689761395000
    },
    {
        "time": 1689761397000,
        "value": 139.816,
        "_internal_originalTime": 1689761397000
    },
    {
        "time": 1689761398000,
        "value": 139.817,
        "_internal_originalTime": 1689761398000
    },
    {
        "time": 1689761400000,
        "value": 139.817,
        "_internal_originalTime": 1689761400000
    },
    {
        "time": 1689761402000,
        "value": 139.818,
        "_internal_originalTime": 1689761402000
    },
    {
        "time": 1689761403000,
        "value": 139.818,
        "_internal_originalTime": 1689761403000
    },
    {
        "time": 1689761404000,
        "value": 139.816,
        "_internal_originalTime": 1689761404000
    },
    {
        "time": 1689761406000,
        "value": 139.812,
        "_internal_originalTime": 1689761406000
    },
    {
        "time": 1689761408000,
        "value": 139.81,
        "_internal_originalTime": 1689761408000
    },
    {
        "time": 1689761409000,
        "value": 139.809,
        "_internal_originalTime": 1689761409000
    },
    {
        "time": 1689761410000,
        "value": 139.809,
        "_internal_originalTime": 1689761410000
    },
    {
        "time": 1689761411000,
        "value": 139.809,
        "_internal_originalTime": 1689761411000
    },
    {
        "time": 1689761413000,
        "value": 139.808,
        "_internal_originalTime": 1689761413000
    },
    {
        "time": 1689761414000,
        "value": 139.808,
        "_internal_originalTime": 1689761414000
    },
    {
        "time": 1689761415000,
        "value": 139.808,
        "_internal_originalTime": 1689761415000
    },
    {
        "time": 1689761417000,
        "value": 139.807,
        "_internal_originalTime": 1689761417000
    },
    {
        "time": 1689761418000,
        "value": 139.808,
        "_internal_originalTime": 1689761418000
    },
    {
        "time": 1689761419000,
        "value": 139.805,
        "_internal_originalTime": 1689761419000
    },
    {
        "time": 1689761421000,
        "value": 139.808,
        "_internal_originalTime": 1689761421000
    },
    {
        "time": 1689761422000,
        "value": 139.808,
        "_internal_originalTime": 1689761422000
    },
    {
        "time": 1689761424000,
        "value": 139.807,
        "_internal_originalTime": 1689761424000
    },
    {
        "time": 1689761425000,
        "value": 139.807,
        "_internal_originalTime": 1689761425000
    },
    {
        "time": 1689761428000,
        "value": 139.807,
        "_internal_originalTime": 1689761428000
    },
    {
        "time": 1689761429000,
        "value": 139.811,
        "_internal_originalTime": 1689761429000
    },
    {
        "time": 1689761430000,
        "value": 139.812,
        "_internal_originalTime": 1689761430000
    },
    {
        "time": 1689761432000,
        "value": 139.806,
        "_internal_originalTime": 1689761432000
    },
    {
        "time": 1689761433000,
        "value": 139.807,
        "_internal_originalTime": 1689761433000
    },
    {
        "time": 1689761435000,
        "value": 139.808,
        "_internal_originalTime": 1689761435000
    },
    {
        "time": 1689761436000,
        "value": 139.807,
        "_internal_originalTime": 1689761436000
    },
    {
        "time": 1689761437000,
        "value": 139.806,
        "_internal_originalTime": 1689761437000
    },
    {
        "time": 1689761438000,
        "value": 139.826,
        "_internal_originalTime": 1689761438000
    },
    {
        "time": 1689761440000,
        "value": 139.807,
        "_internal_originalTime": 1689761440000
    },
    {
        "time": 1689761441000,
        "value": 139.807,
        "_internal_originalTime": 1689761441000
    },
    {
        "time": 1689761442000,
        "value": 139.807,
        "_internal_originalTime": 1689761442000
    },
    {
        "time": 1689761443000,
        "value": 139.809,
        "_internal_originalTime": 1689761443000
    },
    {
        "time": 1689761444000,
        "value": 139.807,
        "_internal_originalTime": 1689761444000
    },
    {
        "time": 1689761445000,
        "value": 139.812,
        "_internal_originalTime": 1689761445000
    },
    {
        "time": 1689761446000,
        "value": 139.814,
        "_internal_originalTime": 1689761446000
    },
    {
        "time": 1689761447000,
        "value": 139.815,
        "_internal_originalTime": 1689761447000
    },
    {
        "time": 1689761448000,
        "value": 139.815,
        "_internal_originalTime": 1689761448000
    },
    {
        "time": 1689761449000,
        "value": 139.816,
        "_internal_originalTime": 1689761449000
    },
    {
        "time": 1689761452000,
        "value": 139.815,
        "_internal_originalTime": 1689761452000
    },
    {
        "time": 1689761453000,
        "value": 139.812,
        "_internal_originalTime": 1689761453000
    },
    {
        "time": 1689761455000,
        "value": 139.815,
        "_internal_originalTime": 1689761455000
    },
    {
        "time": 1689761456000,
        "value": 139.812,
        "_internal_originalTime": 1689761456000
    },
    {
        "time": 1689761457000,
        "value": 139.813,
        "_internal_originalTime": 1689761457000
    },
    {
        "time": 1689761459000,
        "value": 139.811,
        "_internal_originalTime": 1689761459000
    },
    {
        "time": 1689761460000,
        "value": 139.81,
        "_internal_originalTime": 1689761460000
    },
    {
        "time": 1689761462000,
        "value": 139.814,
        "_internal_originalTime": 1689761462000
    },
    {
        "time": 1689761464000,
        "value": 139.818,
        "_internal_originalTime": 1689761464000
    },
    {
        "time": 1689761465000,
        "value": 139.81,
        "_internal_originalTime": 1689761465000
    },
    {
        "time": 1689761466000,
        "value": 139.816,
        "_internal_originalTime": 1689761466000
    },
    {
        "time": 1689761468000,
        "value": 139.809,
        "_internal_originalTime": 1689761468000
    },
    {
        "time": 1689761469000,
        "value": 139.81,
        "_internal_originalTime": 1689761469000
    },
    {
        "time": 1689761470000,
        "value": 139.814,
        "_internal_originalTime": 1689761470000
    },
    {
        "time": 1689761471000,
        "value": 139.809,
        "_internal_originalTime": 1689761471000
    },
    {
        "time": 1689761473000,
        "value": 139.813,
        "_internal_originalTime": 1689761473000
    },
    {
        "time": 1689761475000,
        "value": 139.808,
        "_internal_originalTime": 1689761475000
    },
    {
        "time": 1689761477000,
        "value": 139.816,
        "_internal_originalTime": 1689761477000
    },
    {
        "time": 1689761478000,
        "value": 139.817,
        "_internal_originalTime": 1689761478000
    },
    {
        "time": 1689761479000,
        "value": 139.818,
        "_internal_originalTime": 1689761479000
    },
    {
        "time": 1689761481000,
        "value": 139.815,
        "_internal_originalTime": 1689761481000
    },
    {
        "time": 1689761482000,
        "value": 139.81,
        "_internal_originalTime": 1689761482000
    },
    {
        "time": 1689761483000,
        "value": 139.813,
        "_internal_originalTime": 1689761483000
    },
    {
        "time": 1689761484000,
        "value": 139.813,
        "_internal_originalTime": 1689761484000
    },
    {
        "time": 1689761485000,
        "value": 139.813,
        "_internal_originalTime": 1689761485000
    },
    {
        "time": 1689761486000,
        "value": 139.806,
        "_internal_originalTime": 1689761486000
    },
    {
        "time": 1689761487000,
        "value": 139.806,
        "_internal_originalTime": 1689761487000
    },
    {
        "time": 1689761490000,
        "value": 139.808,
        "_internal_originalTime": 1689761490000
    },
    {
        "time": 1689761492000,
        "value": 139.804,
        "_internal_originalTime": 1689761492000
    },
    {
        "time": 1689761495000,
        "value": 139.809,
        "_internal_originalTime": 1689761495000
    },
    {
        "time": 1689761496000,
        "value": 139.81,
        "_internal_originalTime": 1689761496000
    },
    {
        "time": 1689761498000,
        "value": 139.81,
        "_internal_originalTime": 1689761498000
    },
    {
        "time": 1689761499000,
        "value": 139.81,
        "_internal_originalTime": 1689761499000
    },
    {
        "time": 1689761500000,
        "value": 139.81,
        "_internal_originalTime": 1689761500000
    },
    {
        "time": 1689761503000,
        "value": 139.81,
        "_internal_originalTime": 1689761503000
    },
    {
        "time": 1689761504000,
        "value": 139.811,
        "_internal_originalTime": 1689761504000
    },
    {
        "time": 1689761506000,
        "value": 139.809,
        "_internal_originalTime": 1689761506000
    },
    {
        "time": 1689761508000,
        "value": 139.808,
        "_internal_originalTime": 1689761508000
    },
    {
        "time": 1689761509000,
        "value": 139.809,
        "_internal_originalTime": 1689761509000
    },
    {
        "time": 1689761511000,
        "value": 139.81,
        "_internal_originalTime": 1689761511000
    },
    {
        "time": 1689761512000,
        "value": 139.811,
        "_internal_originalTime": 1689761512000
    },
    {
        "time": 1689761514000,
        "value": 139.809,
        "_internal_originalTime": 1689761514000
    },
    {
        "time": 1689761515000,
        "value": 139.811,
        "_internal_originalTime": 1689761515000
    },
    {
        "time": 1689761516000,
        "value": 139.806,
        "_internal_originalTime": 1689761516000
    },
    {
        "time": 1689761517000,
        "value": 139.804,
        "_internal_originalTime": 1689761517000
    },
    {
        "time": 1689761518000,
        "value": 139.809,
        "_internal_originalTime": 1689761518000
    },
    {
        "time": 1689761519000,
        "value": 139.807,
        "_internal_originalTime": 1689761519000
    },
    {
        "time": 1689761520000,
        "value": 139.806,
        "_internal_originalTime": 1689761520000
    },
    {
        "time": 1689761522000,
        "value": 139.807,
        "_internal_originalTime": 1689761522000
    },
    {
        "time": 1689761523000,
        "value": 139.808,
        "_internal_originalTime": 1689761523000
    },
    {
        "time": 1689761524000,
        "value": 139.81,
        "_internal_originalTime": 1689761524000
    },
    {
        "time": 1689761526000,
        "value": 139.805,
        "_internal_originalTime": 1689761526000
    },
    {
        "time": 1689761527000,
        "value": 139.81,
        "_internal_originalTime": 1689761527000
    },
    {
        "time": 1689761528000,
        "value": 139.808,
        "_internal_originalTime": 1689761528000
    },
    {
        "time": 1689761530000,
        "value": 139.809,
        "_internal_originalTime": 1689761530000
    },
    {
        "time": 1689761532000,
        "value": 139.806,
        "_internal_originalTime": 1689761532000
    },
    {
        "time": 1689761533000,
        "value": 139.81,
        "_internal_originalTime": 1689761533000
    },
    {
        "time": 1689761535000,
        "value": 139.81,
        "_internal_originalTime": 1689761535000
    },
    {
        "time": 1689761537000,
        "value": 139.809,
        "_internal_originalTime": 1689761537000
    },
    {
        "time": 1689761538000,
        "value": 139.81,
        "_internal_originalTime": 1689761538000
    },
    {
        "time": 1689761539000,
        "value": 139.811,
        "_internal_originalTime": 1689761539000
    },
    {
        "time": 1689761541000,
        "value": 139.811,
        "_internal_originalTime": 1689761541000
    },
    {
        "time": 1689761542000,
        "value": 139.806,
        "_internal_originalTime": 1689761542000
    },
    {
        "time": 1689761543000,
        "value": 139.809,
        "_internal_originalTime": 1689761543000
    },
    {
        "time": 1689761545000,
        "value": 139.808,
        "_internal_originalTime": 1689761545000
    },
    {
        "time": 1689761546000,
        "value": 139.809,
        "_internal_originalTime": 1689761546000
    },
    {
        "time": 1689761547000,
        "value": 139.807,
        "_internal_originalTime": 1689761547000
    },
    {
        "time": 1689761548000,
        "value": 139.808,
        "_internal_originalTime": 1689761548000
    },
    {
        "time": 1689761550000,
        "value": 139.799,
        "_internal_originalTime": 1689761550000
    },
    {
        "time": 1689761551000,
        "value": 139.798,
        "_internal_originalTime": 1689761551000
    },
    {
        "time": 1689761552000,
        "value": 139.8,
        "_internal_originalTime": 1689761552000
    },
    {
        "time": 1689761553000,
        "value": 139.799,
        "_internal_originalTime": 1689761553000
    },
    {
        "time": 1689761555000,
        "value": 139.796,
        "_internal_originalTime": 1689761555000
    },
    {
        "time": 1689761556000,
        "value": 139.797,
        "_internal_originalTime": 1689761556000
    },
    {
        "time": 1689761557000,
        "value": 139.798,
        "_internal_originalTime": 1689761557000
    },
    {
        "time": 1689761559000,
        "value": 139.8,
        "_internal_originalTime": 1689761559000
    },
    {
        "time": 1689761562000,
        "value": 139.796,
        "_internal_originalTime": 1689761562000
    },
    {
        "time": 1689761563000,
        "value": 139.794,
        "_internal_originalTime": 1689761563000
    },
    {
        "time": 1689761564000,
        "value": 139.799,
        "_internal_originalTime": 1689761564000
    },
    {
        "time": 1689761566000,
        "value": 139.799,
        "_internal_originalTime": 1689761566000
    },
    {
        "time": 1689761567000,
        "value": 139.796,
        "_internal_originalTime": 1689761567000
    },
    {
        "time": 1689761568000,
        "value": 139.794,
        "_internal_originalTime": 1689761568000
    },
    {
        "time": 1689761572000,
        "value": 139.8,
        "_internal_originalTime": 1689761572000
    },
    {
        "time": 1689761574000,
        "value": 139.794,
        "_internal_originalTime": 1689761574000
    },
    {
        "time": 1689761575000,
        "value": 139.796,
        "_internal_originalTime": 1689761575000
    },
    {
        "time": 1689761577000,
        "value": 139.794,
        "_internal_originalTime": 1689761577000
    },
    {
        "time": 1689761578000,
        "value": 139.8,
        "_internal_originalTime": 1689761578000
    },
    {
        "time": 1689761580000,
        "value": 139.8,
        "_internal_originalTime": 1689761580000
    },
    {
        "time": 1689761581000,
        "value": 139.797,
        "_internal_originalTime": 1689761581000
    },
    {
        "time": 1689761582000,
        "value": 139.797,
        "_internal_originalTime": 1689761582000
    },
    {
        "time": 1689761583000,
        "value": 139.799,
        "_internal_originalTime": 1689761583000
    },
    {
        "time": 1689761584000,
        "value": 139.8,
        "_internal_originalTime": 1689761584000
    },
    {
        "time": 1689761586000,
        "value": 139.794,
        "_internal_originalTime": 1689761586000
    },
    {
        "time": 1689761587000,
        "value": 139.798,
        "_internal_originalTime": 1689761587000
    },
    {
        "time": 1689761588000,
        "value": 139.799,
        "_internal_originalTime": 1689761588000
    },
    {
        "time": 1689761589000,
        "value": 139.797,
        "_internal_originalTime": 1689761589000
    },
    {
        "time": 1689761591000,
        "value": 139.796,
        "_internal_originalTime": 1689761591000
    },
    {
        "time": 1689761592000,
        "value": 139.802,
        "_internal_originalTime": 1689761592000
    },
    {
        "time": 1689761593000,
        "value": 139.796,
        "_internal_originalTime": 1689761593000
    },
    {
        "time": 1689761595000,
        "value": 139.796,
        "_internal_originalTime": 1689761595000
    },
    {
        "time": 1689761597000,
        "value": 139.798,
        "_internal_originalTime": 1689761597000
    },
    {
        "time": 1689761598000,
        "value": 139.796,
        "_internal_originalTime": 1689761598000
    },
    {
        "time": 1689761599000,
        "value": 139.796,
        "_internal_originalTime": 1689761599000
    },
    {
        "time": 1689761600000,
        "value": 139.799,
        "_internal_originalTime": 1689761600000
    },
    {
        "time": 1689761602000,
        "value": 139.8,
        "_internal_originalTime": 1689761602000
    },
    {
        "time": 1689761603000,
        "value": 139.8,
        "_internal_originalTime": 1689761603000
    },
    {
        "time": 1689761604000,
        "value": 139.794,
        "_internal_originalTime": 1689761604000
    },
    {
        "time": 1689761606000,
        "value": 139.799,
        "_internal_originalTime": 1689761606000
    },
    {
        "time": 1689761607000,
        "value": 139.798,
        "_internal_originalTime": 1689761607000
    },
    {
        "time": 1689761608000,
        "value": 139.8,
        "_internal_originalTime": 1689761608000
    },
    {
        "time": 1689761610000,
        "value": 139.789,
        "_internal_originalTime": 1689761610000
    },
    {
        "time": 1689761611000,
        "value": 139.788,
        "_internal_originalTime": 1689761611000
    },
    {
        "time": 1689761612000,
        "value": 139.789,
        "_internal_originalTime": 1689761612000
    },
    {
        "time": 1689761614000,
        "value": 139.786,
        "_internal_originalTime": 1689761614000
    },
    {
        "time": 1689761615000,
        "value": 139.79,
        "_internal_originalTime": 1689761615000
    },
    {
        "time": 1689761616000,
        "value": 139.784,
        "_internal_originalTime": 1689761616000
    },
    {
        "time": 1689761618000,
        "value": 139.787,
        "_internal_originalTime": 1689761618000
    },
    {
        "time": 1689761619000,
        "value": 139.788,
        "_internal_originalTime": 1689761619000
    },
    {
        "time": 1689761620000,
        "value": 139.78,
        "_internal_originalTime": 1689761620000
    },
    {
        "time": 1689761622000,
        "value": 139.78,
        "_internal_originalTime": 1689761622000
    },
    {
        "time": 1689761623000,
        "value": 139.774,
        "_internal_originalTime": 1689761623000
    },
    {
        "time": 1689761624000,
        "value": 139.776,
        "_internal_originalTime": 1689761624000
    },
    {
        "time": 1689761627000,
        "value": 139.779,
        "_internal_originalTime": 1689761627000
    },
    {
        "time": 1689761628000,
        "value": 139.779,
        "_internal_originalTime": 1689761628000
    },
    {
        "time": 1689761629000,
        "value": 139.783,
        "_internal_originalTime": 1689761629000
    },
    {
        "time": 1689761630000,
        "value": 139.776,
        "_internal_originalTime": 1689761630000
    },
    {
        "time": 1689761632000,
        "value": 139.78,
        "_internal_originalTime": 1689761632000
    },
    {
        "time": 1689761633000,
        "value": 139.783,
        "_internal_originalTime": 1689761633000
    },
    {
        "time": 1689761635000,
        "value": 139.782,
        "_internal_originalTime": 1689761635000
    },
    {
        "time": 1689761636000,
        "value": 139.78,
        "_internal_originalTime": 1689761636000
    },
    {
        "time": 1689761638000,
        "value": 139.778,
        "_internal_originalTime": 1689761638000
    },
    {
        "time": 1689761639000,
        "value": 139.778,
        "_internal_originalTime": 1689761639000
    },
    {
        "time": 1689761640000,
        "value": 139.777,
        "_internal_originalTime": 1689761640000
    },
    {
        "time": 1689761641000,
        "value": 139.776,
        "_internal_originalTime": 1689761641000
    },
    {
        "time": 1689761643000,
        "value": 139.779,
        "_internal_originalTime": 1689761643000
    },
    {
        "time": 1689761645000,
        "value": 139.778,
        "_internal_originalTime": 1689761645000
    },
    {
        "time": 1689761646000,
        "value": 139.775,
        "_internal_originalTime": 1689761646000
    },
    {
        "time": 1689761648000,
        "value": 139.779,
        "_internal_originalTime": 1689761648000
    },
    {
        "time": 1689761649000,
        "value": 139.776,
        "_internal_originalTime": 1689761649000
    },
    {
        "time": 1689761652000,
        "value": 139.777,
        "_internal_originalTime": 1689761652000
    },
    {
        "time": 1689761654000,
        "value": 139.782,
        "_internal_originalTime": 1689761654000
    },
    {
        "time": 1689761655000,
        "value": 139.784,
        "_internal_originalTime": 1689761655000
    },
    {
        "time": 1689761656000,
        "value": 139.788,
        "_internal_originalTime": 1689761656000
    },
    {
        "time": 1689761658000,
        "value": 139.789,
        "_internal_originalTime": 1689761658000
    },
    {
        "time": 1689761659000,
        "value": 139.787,
        "_internal_originalTime": 1689761659000
    },
    {
        "time": 1689761660000,
        "value": 139.786,
        "_internal_originalTime": 1689761660000
    },
    {
        "time": 1689761661000,
        "value": 139.78,
        "_internal_originalTime": 1689761661000
    },
    {
        "time": 1689761662000,
        "value": 139.782,
        "_internal_originalTime": 1689761662000
    },
    {
        "time": 1689761664000,
        "value": 139.779,
        "_internal_originalTime": 1689761664000
    },
    {
        "time": 1689761665000,
        "value": 139.778,
        "_internal_originalTime": 1689761665000
    },
    {
        "time": 1689761666000,
        "value": 139.776,
        "_internal_originalTime": 1689761666000
    },
    {
        "time": 1689761668000,
        "value": 139.777,
        "_internal_originalTime": 1689761668000
    },
    {
        "time": 1689761669000,
        "value": 139.781,
        "_internal_originalTime": 1689761669000
    },
    {
        "time": 1689761670000,
        "value": 139.78,
        "_internal_originalTime": 1689761670000
    },
    {
        "time": 1689761671000,
        "value": 139.783,
        "_internal_originalTime": 1689761671000
    },
    {
        "time": 1689761673000,
        "value": 139.782,
        "_internal_originalTime": 1689761673000
    },
    {
        "time": 1689761676000,
        "value": 139.777,
        "_internal_originalTime": 1689761676000
    },
    {
        "time": 1689761678000,
        "value": 139.779,
        "_internal_originalTime": 1689761678000
    },
    {
        "time": 1689761679000,
        "value": 139.781,
        "_internal_originalTime": 1689761679000
    },
    {
        "time": 1689761681000,
        "value": 139.782,
        "_internal_originalTime": 1689761681000
    },
    {
        "time": 1689761683000,
        "value": 139.779,
        "_internal_originalTime": 1689761683000
    },
    {
        "time": 1689761685000,
        "value": 139.777,
        "_internal_originalTime": 1689761685000
    },
    {
        "time": 1689761687000,
        "value": 139.779,
        "_internal_originalTime": 1689761687000
    },
    {
        "time": 1689761688000,
        "value": 139.78,
        "_internal_originalTime": 1689761688000
    },
    {
        "time": 1689761690000,
        "value": 139.776,
        "_internal_originalTime": 1689761690000
    },
    {
        "time": 1689761692000,
        "value": 139.776,
        "_internal_originalTime": 1689761692000
    },
    {
        "time": 1689761694000,
        "value": 139.777,
        "_internal_originalTime": 1689761694000
    },
    {
        "time": 1689761696000,
        "value": 139.782,
        "_internal_originalTime": 1689761696000
    },
    {
        "time": 1689761697000,
        "value": 139.779,
        "_internal_originalTime": 1689761697000
    },
    {
        "time": 1689761698000,
        "value": 139.779,
        "_internal_originalTime": 1689761698000
    },
    {
        "time": 1689761700000,
        "value": 139.78,
        "_internal_originalTime": 1689761700000
    },
    {
        "time": 1689761701000,
        "value": 139.778,
        "_internal_originalTime": 1689761701000
    },
    {
        "time": 1689761704000,
        "value": 139.779,
        "_internal_originalTime": 1689761704000
    },
    {
        "time": 1689761705000,
        "value": 139.78,
        "_internal_originalTime": 1689761705000
    },
    {
        "time": 1689761706000,
        "value": 139.775,
        "_internal_originalTime": 1689761706000
    },
    {
        "time": 1689761708000,
        "value": 139.779,
        "_internal_originalTime": 1689761708000
    },
    {
        "time": 1689761710000,
        "value": 139.781,
        "_internal_originalTime": 1689761710000
    },
    {
        "time": 1689761713000,
        "value": 139.779,
        "_internal_originalTime": 1689761713000
    },
    {
        "time": 1689761714000,
        "value": 139.776,
        "_internal_originalTime": 1689761714000
    },
    {
        "time": 1689761715000,
        "value": 139.781,
        "_internal_originalTime": 1689761715000
    },
    {
        "time": 1689761717000,
        "value": 139.776,
        "_internal_originalTime": 1689761717000
    },
    {
        "time": 1689761719000,
        "value": 139.76,
        "_internal_originalTime": 1689761719000
    },
    {
        "time": 1689761720000,
        "value": 139.762,
        "_internal_originalTime": 1689761720000
    },
    {
        "time": 1689761721000,
        "value": 139.762,
        "_internal_originalTime": 1689761721000
    },
    {
        "time": 1689761723000,
        "value": 139.75,
        "_internal_originalTime": 1689761723000
    },
    {
        "time": 1689761724000,
        "value": 139.749,
        "_internal_originalTime": 1689761724000
    },
    {
        "time": 1689761725000,
        "value": 139.748,
        "_internal_originalTime": 1689761725000
    },
    {
        "time": 1689761726000,
        "value": 139.752,
        "_internal_originalTime": 1689761726000
    },
    {
        "time": 1689761727000,
        "value": 139.756,
        "_internal_originalTime": 1689761727000
    },
    {
        "time": 1689761728000,
        "value": 139.756,
        "_internal_originalTime": 1689761728000
    },
    {
        "time": 1689761729000,
        "value": 139.756,
        "_internal_originalTime": 1689761729000
    },
    {
        "time": 1689761730000,
        "value": 139.761,
        "_internal_originalTime": 1689761730000
    },
    {
        "time": 1689761731000,
        "value": 139.758,
        "_internal_originalTime": 1689761731000
    },
    {
        "time": 1689761732000,
        "value": 139.76,
        "_internal_originalTime": 1689761732000
    },
    {
        "time": 1689761734000,
        "value": 139.762,
        "_internal_originalTime": 1689761734000
    },
    {
        "time": 1689761736000,
        "value": 139.755,
        "_internal_originalTime": 1689761736000
    },
    {
        "time": 1689761738000,
        "value": 139.758,
        "_internal_originalTime": 1689761738000
    },
    {
        "time": 1689761739000,
        "value": 139.766,
        "_internal_originalTime": 1689761739000
    },
    {
        "time": 1689761740000,
        "value": 139.765,
        "_internal_originalTime": 1689761740000
    },
    {
        "time": 1689761743000,
        "value": 139.765,
        "_internal_originalTime": 1689761743000
    },
    {
        "time": 1689761746000,
        "value": 139.763,
        "_internal_originalTime": 1689761746000
    },
    {
        "time": 1689761748000,
        "value": 139.76,
        "_internal_originalTime": 1689761748000
    },
    {
        "time": 1689761750000,
        "value": 139.76,
        "_internal_originalTime": 1689761750000
    },
    {
        "time": 1689761751000,
        "value": 139.76,
        "_internal_originalTime": 1689761751000
    },
    {
        "time": 1689761753000,
        "value": 139.761,
        "_internal_originalTime": 1689761753000
    },
    {
        "time": 1689761754000,
        "value": 139.761,
        "_internal_originalTime": 1689761754000
    },
    {
        "time": 1689761755000,
        "value": 139.763,
        "_internal_originalTime": 1689761755000
    },
    {
        "time": 1689761756000,
        "value": 139.758,
        "_internal_originalTime": 1689761756000
    },
    {
        "time": 1689761758000,
        "value": 139.758,
        "_internal_originalTime": 1689761758000
    },
    {
        "time": 1689761759000,
        "value": 139.761,
        "_internal_originalTime": 1689761759000
    },
    {
        "time": 1689761760000,
        "value": 139.761,
        "_internal_originalTime": 1689761760000
    },
    {
        "time": 1689761762000,
        "value": 139.765,
        "_internal_originalTime": 1689761762000
    },
    {
        "time": 1689761763000,
        "value": 139.76,
        "_internal_originalTime": 1689761763000
    },
    {
        "time": 1689761765000,
        "value": 139.756,
        "_internal_originalTime": 1689761765000
    },
    {
        "time": 1689761767000,
        "value": 139.758,
        "_internal_originalTime": 1689761767000
    },
    {
        "time": 1689761768000,
        "value": 139.756,
        "_internal_originalTime": 1689761768000
    },
    {
        "time": 1689761769000,
        "value": 139.761,
        "_internal_originalTime": 1689761769000
    },
    {
        "time": 1689761774000,
        "value": 139.761,
        "_internal_originalTime": 1689761774000
    },
    {
        "time": 1689761775000,
        "value": 139.761,
        "_internal_originalTime": 1689761775000
    },
    {
        "time": 1689761776000,
        "value": 139.759,
        "_internal_originalTime": 1689761776000
    },
    {
        "time": 1689761777000,
        "value": 139.76,
        "_internal_originalTime": 1689761777000
    },
    {
        "time": 1689761779000,
        "value": 139.751,
        "_internal_originalTime": 1689761779000
    },
    {
        "time": 1689761780000,
        "value": 139.751,
        "_internal_originalTime": 1689761780000
    },
    {
        "time": 1689761781000,
        "value": 139.752,
        "_internal_originalTime": 1689761781000
    },
    {
        "time": 1689761783000,
        "value": 139.752,
        "_internal_originalTime": 1689761783000
    },
    {
        "time": 1689761795000,
        "value": 139.749,
        "_internal_originalTime": 1689761795000
    },
    {
        "time": 1689761796000,
        "value": 139.745,
        "_internal_originalTime": 1689761796000
    },
    {
        "time": 1689761797000,
        "value": 139.749,
        "_internal_originalTime": 1689761797000
    },
    {
        "time": 1689761799000,
        "value": 139.747,
        "_internal_originalTime": 1689761799000
    },
    {
        "time": 1689761800000,
        "value": 139.746,
        "_internal_originalTime": 1689761800000
    },
    {
        "time": 1689761802000,
        "value": 139.746,
        "_internal_originalTime": 1689761802000
    },
    {
        "time": 1689761803000,
        "value": 139.751,
        "_internal_originalTime": 1689761803000
    },
    {
        "time": 1689761804000,
        "value": 139.751,
        "_internal_originalTime": 1689761804000
    },
    {
        "time": 1689761805000,
        "value": 139.745,
        "_internal_originalTime": 1689761805000
    },
    {
        "time": 1689761807000,
        "value": 139.75,
        "_internal_originalTime": 1689761807000
    },
    {
        "time": 1689761811000,
        "value": 139.746,
        "_internal_originalTime": 1689761811000
    },
    {
        "time": 1689761815000,
        "value": 139.744,
        "_internal_originalTime": 1689761815000
    },
    {
        "time": 1689761819000,
        "value": 139.743,
        "_internal_originalTime": 1689761819000
    },
    {
        "time": 1689761821000,
        "value": 139.737,
        "_internal_originalTime": 1689761821000
    },
    {
        "time": 1689761822000,
        "value": 139.739,
        "_internal_originalTime": 1689761822000
    },
    {
        "time": 1689761823000,
        "value": 139.74,
        "_internal_originalTime": 1689761823000
    },
    {
        "time": 1689761824000,
        "value": 139.741,
        "_internal_originalTime": 1689761824000
    },
    {
        "time": 1689761826000,
        "value": 139.736,
        "_internal_originalTime": 1689761826000
    },
    {
        "time": 1689761829000,
        "value": 139.732,
        "_internal_originalTime": 1689761829000
    },
    {
        "time": 1689761830000,
        "value": 139.735,
        "_internal_originalTime": 1689761830000
    },
    {
        "time": 1689761832000,
        "value": 139.728,
        "_internal_originalTime": 1689761832000
    },
    {
        "time": 1689761833000,
        "value": 139.733,
        "_internal_originalTime": 1689761833000
    },
    {
        "time": 1689761834000,
        "value": 139.733,
        "_internal_originalTime": 1689761834000
    },
    {
        "time": 1689761839000,
        "value": 139.733,
        "_internal_originalTime": 1689761839000
    },
    {
        "time": 1689761840000,
        "value": 139.729,
        "_internal_originalTime": 1689761840000
    },
    {
        "time": 1689761842000,
        "value": 139.73,
        "_internal_originalTime": 1689761842000
    },
    {
        "time": 1689761843000,
        "value": 139.73,
        "_internal_originalTime": 1689761843000
    },
    {
        "time": 1689761844000,
        "value": 139.73,
        "_internal_originalTime": 1689761844000
    },
    {
        "time": 1689761849000,
        "value": 139.733,
        "_internal_originalTime": 1689761849000
    },
    {
        "time": 1689761853000,
        "value": 139.729,
        "_internal_originalTime": 1689761853000
    },
    {
        "time": 1689761855000,
        "value": 139.734,
        "_internal_originalTime": 1689761855000
    },
    {
        "time": 1689761856000,
        "value": 139.73,
        "_internal_originalTime": 1689761856000
    },
    {
        "time": 1689761858000,
        "value": 139.728,
        "_internal_originalTime": 1689761858000
    },
    {
        "time": 1689761862000,
        "value": 139.721,
        "_internal_originalTime": 1689761862000
    },
    {
        "time": 1689761863000,
        "value": 139.718,
        "_internal_originalTime": 1689761863000
    },
    {
        "time": 1689761865000,
        "value": 139.713,
        "_internal_originalTime": 1689761865000
    },
    {
        "time": 1689761866000,
        "value": 139.711,
        "_internal_originalTime": 1689761866000
    },
    {
        "time": 1689761867000,
        "value": 139.711,
        "_internal_originalTime": 1689761867000
    },
    {
        "time": 1689761868000,
        "value": 139.713,
        "_internal_originalTime": 1689761868000
    },
    {
        "time": 1689761870000,
        "value": 139.71,
        "_internal_originalTime": 1689761870000
    },
    {
        "time": 1689761871000,
        "value": 139.712,
        "_internal_originalTime": 1689761871000
    },
    {
        "time": 1689761872000,
        "value": 139.711,
        "_internal_originalTime": 1689761872000
    },
    {
        "time": 1689761874000,
        "value": 139.708,
        "_internal_originalTime": 1689761874000
    },
    {
        "time": 1689761876000,
        "value": 139.714,
        "_internal_originalTime": 1689761876000
    },
    {
        "time": 1689761877000,
        "value": 139.708,
        "_internal_originalTime": 1689761877000
    },
    {
        "time": 1689761878000,
        "value": 139.708,
        "_internal_originalTime": 1689761878000
    },
    {
        "time": 1689761880000,
        "value": 139.724,
        "_internal_originalTime": 1689761880000
    },
    {
        "time": 1689761881000,
        "value": 139.723,
        "_internal_originalTime": 1689761881000
    },
    {
        "time": 1689761882000,
        "value": 139.731,
        "_internal_originalTime": 1689761882000
    },
    {
        "time": 1689761883000,
        "value": 139.733,
        "_internal_originalTime": 1689761883000
    },
    {
        "time": 1689761885000,
        "value": 139.73,
        "_internal_originalTime": 1689761885000
    },
    {
        "time": 1689761886000,
        "value": 139.732,
        "_internal_originalTime": 1689761886000
    },
    {
        "time": 1689761888000,
        "value": 139.728,
        "_internal_originalTime": 1689761888000
    },
    {
        "time": 1689761889000,
        "value": 139.729,
        "_internal_originalTime": 1689761889000
    },
    {
        "time": 1689761890000,
        "value": 139.732,
        "_internal_originalTime": 1689761890000
    },
    {
        "time": 1689761892000,
        "value": 139.734,
        "_internal_originalTime": 1689761892000
    },
    {
        "time": 1689761893000,
        "value": 139.733,
        "_internal_originalTime": 1689761893000
    },
    {
        "time": 1689761898000,
        "value": 139.739,
        "_internal_originalTime": 1689761898000
    },
    {
        "time": 1689761900000,
        "value": 139.737,
        "_internal_originalTime": 1689761900000
    },
    {
        "time": 1689761902000,
        "value": 139.737,
        "_internal_originalTime": 1689761902000
    },
    {
        "time": 1689761904000,
        "value": 139.737,
        "_internal_originalTime": 1689761904000
    },
    {
        "time": 1689761905000,
        "value": 139.744,
        "_internal_originalTime": 1689761905000
    },
    {
        "time": 1689761907000,
        "value": 139.744,
        "_internal_originalTime": 1689761907000
    },
    {
        "time": 1689761908000,
        "value": 139.745,
        "_internal_originalTime": 1689761908000
    },
    {
        "time": 1689761909000,
        "value": 139.745,
        "_internal_originalTime": 1689761909000
    },
    {
        "time": 1689761910000,
        "value": 139.743,
        "_internal_originalTime": 1689761910000
    },
    {
        "time": 1689761911000,
        "value": 139.745,
        "_internal_originalTime": 1689761911000
    },
    {
        "time": 1689761912000,
        "value": 139.745,
        "_internal_originalTime": 1689761912000
    },
    {
        "time": 1689761914000,
        "value": 139.743,
        "_internal_originalTime": 1689761914000
    },
    {
        "time": 1689761916000,
        "value": 139.737,
        "_internal_originalTime": 1689761916000
    },
    {
        "time": 1689761917000,
        "value": 139.738,
        "_internal_originalTime": 1689761917000
    },
    {
        "time": 1689761919000,
        "value": 139.739,
        "_internal_originalTime": 1689761919000
    },
    {
        "time": 1689761920000,
        "value": 139.748,
        "_internal_originalTime": 1689761920000
    },
    {
        "time": 1689761921000,
        "value": 139.744,
        "_internal_originalTime": 1689761921000
    },
    {
        "time": 1689761923000,
        "value": 139.748,
        "_internal_originalTime": 1689761923000
    },
    {
        "time": 1689761925000,
        "value": 139.746,
        "_internal_originalTime": 1689761925000
    },
    {
        "time": 1689761927000,
        "value": 139.747,
        "_internal_originalTime": 1689761927000
    },
    {
        "time": 1689761928000,
        "value": 139.744,
        "_internal_originalTime": 1689761928000
    },
    {
        "time": 1689761930000,
        "value": 139.749,
        "_internal_originalTime": 1689761930000
    },
    {
        "time": 1689761931000,
        "value": 139.75,
        "_internal_originalTime": 1689761931000
    },
    {
        "time": 1689761932000,
        "value": 139.751,
        "_internal_originalTime": 1689761932000
    },
    {
        "time": 1689761933000,
        "value": 139.747,
        "_internal_originalTime": 1689761933000
    },
    {
        "time": 1689761936000,
        "value": 139.751,
        "_internal_originalTime": 1689761936000
    },
    {
        "time": 1689761939000,
        "value": 139.746,
        "_internal_originalTime": 1689761939000
    },
    {
        "time": 1689761940000,
        "value": 139.74,
        "_internal_originalTime": 1689761940000
    },
    {
        "time": 1689761941000,
        "value": 139.75,
        "_internal_originalTime": 1689761941000
    },
    {
        "time": 1689761942000,
        "value": 139.752,
        "_internal_originalTime": 1689761942000
    },
    {
        "time": 1689761943000,
        "value": 139.75,
        "_internal_originalTime": 1689761943000
    },
    {
        "time": 1689761945000,
        "value": 139.75,
        "_internal_originalTime": 1689761945000
    },
    {
        "time": 1689761947000,
        "value": 139.744,
        "_internal_originalTime": 1689761947000
    },
    {
        "time": 1689761948000,
        "value": 139.748,
        "_internal_originalTime": 1689761948000
    },
    {
        "time": 1689761950000,
        "value": 139.753,
        "_internal_originalTime": 1689761950000
    },
    {
        "time": 1689761951000,
        "value": 139.749,
        "_internal_originalTime": 1689761951000
    },
    {
        "time": 1689761952000,
        "value": 139.749,
        "_internal_originalTime": 1689761952000
    },
    {
        "time": 1689761955000,
        "value": 139.752,
        "_internal_originalTime": 1689761955000
    },
    {
        "time": 1689761956000,
        "value": 139.747,
        "_internal_originalTime": 1689761956000
    },
    {
        "time": 1689761957000,
        "value": 139.746,
        "_internal_originalTime": 1689761957000
    },
    {
        "time": 1689761959000,
        "value": 139.747,
        "_internal_originalTime": 1689761959000
    },
    {
        "time": 1689761960000,
        "value": 139.746,
        "_internal_originalTime": 1689761960000
    },
    {
        "time": 1689761962000,
        "value": 139.747,
        "_internal_originalTime": 1689761962000
    },
    {
        "time": 1689761963000,
        "value": 139.752,
        "_internal_originalTime": 1689761963000
    },
    {
        "time": 1689761965000,
        "value": 139.754,
        "_internal_originalTime": 1689761965000
    },
    {
        "time": 1689761966000,
        "value": 139.754,
        "_internal_originalTime": 1689761966000
    },
    {
        "time": 1689761968000,
        "value": 139.759,
        "_internal_originalTime": 1689761968000
    },
    {
        "time": 1689761969000,
        "value": 139.756,
        "_internal_originalTime": 1689761969000
    },
    {
        "time": 1689761971000,
        "value": 139.753,
        "_internal_originalTime": 1689761971000
    },
    {
        "time": 1689761972000,
        "value": 139.758,
        "_internal_originalTime": 1689761972000
    },
    {
        "time": 1689761973000,
        "value": 139.752,
        "_internal_originalTime": 1689761973000
    },
    {
        "time": 1689761976000,
        "value": 139.75,
        "_internal_originalTime": 1689761976000
    },
    {
        "time": 1689761977000,
        "value": 139.756,
        "_internal_originalTime": 1689761977000
    },
    {
        "time": 1689761978000,
        "value": 139.759,
        "_internal_originalTime": 1689761978000
    },
    {
        "time": 1689761980000,
        "value": 139.759,
        "_internal_originalTime": 1689761980000
    },
    {
        "time": 1689761982000,
        "value": 139.759,
        "_internal_originalTime": 1689761982000
    },
    {
        "time": 1689761985000,
        "value": 139.762,
        "_internal_originalTime": 1689761985000
    },
    {
        "time": 1689761988000,
        "value": 139.762,
        "_internal_originalTime": 1689761988000
    },
    {
        "time": 1689761989000,
        "value": 139.759,
        "_internal_originalTime": 1689761989000
    },
    {
        "time": 1689761993000,
        "value": 139.761,
        "_internal_originalTime": 1689761993000
    },
    {
        "time": 1689761995000,
        "value": 139.758,
        "_internal_originalTime": 1689761995000
    },
    {
        "time": 1689761996000,
        "value": 139.762,
        "_internal_originalTime": 1689761996000
    },
    {
        "time": 1689761998000,
        "value": 139.763,
        "_internal_originalTime": 1689761998000
    },
    {
        "time": 1689761999000,
        "value": 139.763,
        "_internal_originalTime": 1689761999000
    },
    {
        "time": 1689762000000,
        "value": 139.762,
        "_internal_originalTime": 1689762000000
    },
    {
        "time": 1689762002000,
        "value": 139.764,
        "_internal_originalTime": 1689762002000
    },
    {
        "time": 1689762003000,
        "value": 139.76,
        "_internal_originalTime": 1689762003000
    },
    {
        "time": 1689762004000,
        "value": 139.758,
        "_internal_originalTime": 1689762004000
    },
    {
        "time": 1689762006000,
        "value": 139.755,
        "_internal_originalTime": 1689762006000
    },
    {
        "time": 1689762009000,
        "value": 139.762,
        "_internal_originalTime": 1689762009000
    },
    {
        "time": 1689762010000,
        "value": 139.759,
        "_internal_originalTime": 1689762010000
    },
    {
        "time": 1689762013000,
        "value": 139.758,
        "_internal_originalTime": 1689762013000
    },
    {
        "time": 1689762014000,
        "value": 139.762,
        "_internal_originalTime": 1689762014000
    },
    {
        "time": 1689762016000,
        "value": 139.76,
        "_internal_originalTime": 1689762016000
    },
    {
        "time": 1689762017000,
        "value": 139.763,
        "_internal_originalTime": 1689762017000
    },
    {
        "time": 1689762018000,
        "value": 139.761,
        "_internal_originalTime": 1689762018000
    },
    {
        "time": 1689762020000,
        "value": 139.765,
        "_internal_originalTime": 1689762020000
    },
    {
        "time": 1689762021000,
        "value": 139.77,
        "_internal_originalTime": 1689762021000
    },
    {
        "time": 1689762022000,
        "value": 139.768,
        "_internal_originalTime": 1689762022000
    },
    {
        "time": 1689762026000,
        "value": 139.772,
        "_internal_originalTime": 1689762026000
    },
    {
        "time": 1689762027000,
        "value": 139.771,
        "_internal_originalTime": 1689762027000
    },
    {
        "time": 1689762030000,
        "value": 139.771,
        "_internal_originalTime": 1689762030000
    },
    {
        "time": 1689762031000,
        "value": 139.772,
        "_internal_originalTime": 1689762031000
    },
    {
        "time": 1689762033000,
        "value": 139.771,
        "_internal_originalTime": 1689762033000
    },
    {
        "time": 1689762034000,
        "value": 139.772,
        "_internal_originalTime": 1689762034000
    },
    {
        "time": 1689762035000,
        "value": 139.768,
        "_internal_originalTime": 1689762035000
    },
    {
        "time": 1689762037000,
        "value": 139.771,
        "_internal_originalTime": 1689762037000
    },
    {
        "time": 1689762038000,
        "value": 139.773,
        "_internal_originalTime": 1689762038000
    },
    {
        "time": 1689762039000,
        "value": 139.768,
        "_internal_originalTime": 1689762039000
    },
    {
        "time": 1689762040000,
        "value": 139.772,
        "_internal_originalTime": 1689762040000
    },
    {
        "time": 1689762042000,
        "value": 139.772,
        "_internal_originalTime": 1689762042000
    },
    {
        "time": 1689762045000,
        "value": 139.772,
        "_internal_originalTime": 1689762045000
    },
    {
        "time": 1689762047000,
        "value": 139.773,
        "_internal_originalTime": 1689762047000
    },
    {
        "time": 1689762049000,
        "value": 139.773,
        "_internal_originalTime": 1689762049000
    },
    {
        "time": 1689762052000,
        "value": 139.772,
        "_internal_originalTime": 1689762052000
    },
    {
        "time": 1689762053000,
        "value": 139.771,
        "_internal_originalTime": 1689762053000
    },
    {
        "time": 1689762055000,
        "value": 139.773,
        "_internal_originalTime": 1689762055000
    },
    {
        "time": 1689762056000,
        "value": 139.78,
        "_internal_originalTime": 1689762056000
    },
    {
        "time": 1689762058000,
        "value": 139.78,
        "_internal_originalTime": 1689762058000
    },
    {
        "time": 1689762060000,
        "value": 139.78,
        "_internal_originalTime": 1689762060000
    },
    {
        "time": 1689762061000,
        "value": 139.778,
        "_internal_originalTime": 1689762061000
    },
    {
        "time": 1689762062000,
        "value": 139.778,
        "_internal_originalTime": 1689762062000
    },
    {
        "time": 1689762064000,
        "value": 139.781,
        "_internal_originalTime": 1689762064000
    },
    {
        "time": 1689762065000,
        "value": 139.779,
        "_internal_originalTime": 1689762065000
    },
    {
        "time": 1689762066000,
        "value": 139.78,
        "_internal_originalTime": 1689762066000
    },
    {
        "time": 1689762068000,
        "value": 139.782,
        "_internal_originalTime": 1689762068000
    },
    {
        "time": 1689762069000,
        "value": 139.782,
        "_internal_originalTime": 1689762069000
    },
    {
        "time": 1689762070000,
        "value": 139.781,
        "_internal_originalTime": 1689762070000
    },
    {
        "time": 1689762071000,
        "value": 139.776,
        "_internal_originalTime": 1689762071000
    },
    {
        "time": 1689762072000,
        "value": 139.778,
        "_internal_originalTime": 1689762072000
    },
    {
        "time": 1689762073000,
        "value": 139.776,
        "_internal_originalTime": 1689762073000
    },
    {
        "time": 1689762075000,
        "value": 139.78,
        "_internal_originalTime": 1689762075000
    },
    {
        "time": 1689762076000,
        "value": 139.774,
        "_internal_originalTime": 1689762076000
    },
    {
        "time": 1689762077000,
        "value": 139.772,
        "_internal_originalTime": 1689762077000
    },
    {
        "time": 1689762078000,
        "value": 139.777,
        "_internal_originalTime": 1689762078000
    },
    {
        "time": 1689762080000,
        "value": 139.775,
        "_internal_originalTime": 1689762080000
    },
    {
        "time": 1689762081000,
        "value": 139.772,
        "_internal_originalTime": 1689762081000
    },
    {
        "time": 1689762083000,
        "value": 139.771,
        "_internal_originalTime": 1689762083000
    },
    {
        "time": 1689762084000,
        "value": 139.77,
        "_internal_originalTime": 1689762084000
    },
    {
        "time": 1689762085000,
        "value": 139.768,
        "_internal_originalTime": 1689762085000
    },
    {
        "time": 1689762087000,
        "value": 139.771,
        "_internal_originalTime": 1689762087000
    },
    {
        "time": 1689762088000,
        "value": 139.771,
        "_internal_originalTime": 1689762088000
    },
    {
        "time": 1689762090000,
        "value": 139.768,
        "_internal_originalTime": 1689762090000
    },
    {
        "time": 1689762091000,
        "value": 139.77,
        "_internal_originalTime": 1689762091000
    },
    {
        "time": 1689762093000,
        "value": 139.771,
        "_internal_originalTime": 1689762093000
    },
    {
        "time": 1689762096000,
        "value": 139.765,
        "_internal_originalTime": 1689762096000
    },
    {
        "time": 1689762097000,
        "value": 139.769,
        "_internal_originalTime": 1689762097000
    },
    {
        "time": 1689762098000,
        "value": 139.768,
        "_internal_originalTime": 1689762098000
    },
    {
        "time": 1689762100000,
        "value": 139.768,
        "_internal_originalTime": 1689762100000
    },
    {
        "time": 1689762102000,
        "value": 139.771,
        "_internal_originalTime": 1689762102000
    },
    {
        "time": 1689762104000,
        "value": 139.77,
        "_internal_originalTime": 1689762104000
    },
    {
        "time": 1689762106000,
        "value": 139.773,
        "_internal_originalTime": 1689762106000
    },
    {
        "time": 1689762107000,
        "value": 139.773,
        "_internal_originalTime": 1689762107000
    },
    {
        "time": 1689762108000,
        "value": 139.771,
        "_internal_originalTime": 1689762108000
    },
    {
        "time": 1689762109000,
        "value": 139.771,
        "_internal_originalTime": 1689762109000
    },
    {
        "time": 1689762111000,
        "value": 139.77,
        "_internal_originalTime": 1689762111000
    },
    {
        "time": 1689762112000,
        "value": 139.77,
        "_internal_originalTime": 1689762112000
    },
    {
        "time": 1689762113000,
        "value": 139.77,
        "_internal_originalTime": 1689762113000
    },
    {
        "time": 1689762115000,
        "value": 139.77,
        "_internal_originalTime": 1689762115000
    },
    {
        "time": 1689762118000,
        "value": 139.772,
        "_internal_originalTime": 1689762118000
    },
    {
        "time": 1689762119000,
        "value": 139.77,
        "_internal_originalTime": 1689762119000
    },
    {
        "time": 1689762122000,
        "value": 139.771,
        "_internal_originalTime": 1689762122000
    },
    {
        "time": 1689762124000,
        "value": 139.769,
        "_internal_originalTime": 1689762124000
    },
    {
        "time": 1689762125000,
        "value": 139.77,
        "_internal_originalTime": 1689762125000
    },
    {
        "time": 1689762126000,
        "value": 139.764,
        "_internal_originalTime": 1689762126000
    },
    {
        "time": 1689762127000,
        "value": 139.759,
        "_internal_originalTime": 1689762127000
    },
    {
        "time": 1689762128000,
        "value": 139.762,
        "_internal_originalTime": 1689762128000
    },
    {
        "time": 1689762130000,
        "value": 139.761,
        "_internal_originalTime": 1689762130000
    },
    {
        "time": 1689762131000,
        "value": 139.75,
        "_internal_originalTime": 1689762131000
    },
    {
        "time": 1689762133000,
        "value": 139.75,
        "_internal_originalTime": 1689762133000
    },
    {
        "time": 1689762134000,
        "value": 139.761,
        "_internal_originalTime": 1689762134000
    },
    {
        "time": 1689762135000,
        "value": 139.761,
        "_internal_originalTime": 1689762135000
    },
    {
        "time": 1689762137000,
        "value": 139.761,
        "_internal_originalTime": 1689762137000
    },
    {
        "time": 1689762138000,
        "value": 139.759,
        "_internal_originalTime": 1689762138000
    },
    {
        "time": 1689762140000,
        "value": 139.76,
        "_internal_originalTime": 1689762140000
    },
    {
        "time": 1689762141000,
        "value": 139.76,
        "_internal_originalTime": 1689762141000
    },
    {
        "time": 1689762142000,
        "value": 139.761,
        "_internal_originalTime": 1689762142000
    },
    {
        "time": 1689762144000,
        "value": 139.758,
        "_internal_originalTime": 1689762144000
    },
    {
        "time": 1689762145000,
        "value": 139.759,
        "_internal_originalTime": 1689762145000
    },
    {
        "time": 1689762146000,
        "value": 139.76,
        "_internal_originalTime": 1689762146000
    },
    {
        "time": 1689762148000,
        "value": 139.767,
        "_internal_originalTime": 1689762148000
    },
    {
        "time": 1689762149000,
        "value": 139.77,
        "_internal_originalTime": 1689762149000
    },
    {
        "time": 1689762150000,
        "value": 139.766,
        "_internal_originalTime": 1689762150000
    },
    {
        "time": 1689762151000,
        "value": 139.762,
        "_internal_originalTime": 1689762151000
    },
    {
        "time": 1689762152000,
        "value": 139.762,
        "_internal_originalTime": 1689762152000
    },
    {
        "time": 1689762155000,
        "value": 139.758,
        "_internal_originalTime": 1689762155000
    },
    {
        "time": 1689762156000,
        "value": 139.758,
        "_internal_originalTime": 1689762156000
    },
    {
        "time": 1689762158000,
        "value": 139.758,
        "_internal_originalTime": 1689762158000
    },
    {
        "time": 1689762160000,
        "value": 139.761,
        "_internal_originalTime": 1689762160000
    },
    {
        "time": 1689762161000,
        "value": 139.766,
        "_internal_originalTime": 1689762161000
    },
    {
        "time": 1689762162000,
        "value": 139.77,
        "_internal_originalTime": 1689762162000
    },
    {
        "time": 1689762164000,
        "value": 139.77,
        "_internal_originalTime": 1689762164000
    },
    {
        "time": 1689762165000,
        "value": 139.769,
        "_internal_originalTime": 1689762165000
    },
    {
        "time": 1689762166000,
        "value": 139.771,
        "_internal_originalTime": 1689762166000
    },
    {
        "time": 1689762167000,
        "value": 139.76,
        "_internal_originalTime": 1689762167000
    },
    {
        "time": 1689762169000,
        "value": 139.76,
        "_internal_originalTime": 1689762169000
    },
    {
        "time": 1689762170000,
        "value": 139.762,
        "_internal_originalTime": 1689762170000
    },
    {
        "time": 1689762171000,
        "value": 139.761,
        "_internal_originalTime": 1689762171000
    },
    {
        "time": 1689762173000,
        "value": 139.759,
        "_internal_originalTime": 1689762173000
    },
    {
        "time": 1689762174000,
        "value": 139.759,
        "_internal_originalTime": 1689762174000
    },
    {
        "time": 1689762176000,
        "value": 139.759,
        "_internal_originalTime": 1689762176000
    },
    {
        "time": 1689762178000,
        "value": 139.761,
        "_internal_originalTime": 1689762178000
    },
    {
        "time": 1689762179000,
        "value": 139.763,
        "_internal_originalTime": 1689762179000
    },
    {
        "time": 1689762180000,
        "value": 139.762,
        "_internal_originalTime": 1689762180000
    },
    {
        "time": 1689762182000,
        "value": 139.762,
        "_internal_originalTime": 1689762182000
    },
    {
        "time": 1689762183000,
        "value": 139.762,
        "_internal_originalTime": 1689762183000
    },
    {
        "time": 1689762185000,
        "value": 139.768,
        "_internal_originalTime": 1689762185000
    },
    {
        "time": 1689762186000,
        "value": 139.769,
        "_internal_originalTime": 1689762186000
    },
    {
        "time": 1689762188000,
        "value": 139.771,
        "_internal_originalTime": 1689762188000
    },
    {
        "time": 1689762189000,
        "value": 139.771,
        "_internal_originalTime": 1689762189000
    },
    {
        "time": 1689762190000,
        "value": 139.77,
        "_internal_originalTime": 1689762190000
    },
    {
        "time": 1689762192000,
        "value": 139.77,
        "_internal_originalTime": 1689762192000
    },
    {
        "time": 1689762193000,
        "value": 139.768,
        "_internal_originalTime": 1689762193000
    },
    {
        "time": 1689762194000,
        "value": 139.77,
        "_internal_originalTime": 1689762194000
    },
    {
        "time": 1689762195000,
        "value": 139.771,
        "_internal_originalTime": 1689762195000
    },
    {
        "time": 1689762197000,
        "value": 139.776,
        "_internal_originalTime": 1689762197000
    },
    {
        "time": 1689762198000,
        "value": 139.782,
        "_internal_originalTime": 1689762198000
    },
    {
        "time": 1689762199000,
        "value": 139.789,
        "_internal_originalTime": 1689762199000
    },
    {
        "time": 1689762201000,
        "value": 139.789,
        "_internal_originalTime": 1689762201000
    },
    {
        "time": 1689762203000,
        "value": 139.79,
        "_internal_originalTime": 1689762203000
    },
    {
        "time": 1689762205000,
        "value": 139.79,
        "_internal_originalTime": 1689762205000
    },
    {
        "time": 1689762206000,
        "value": 139.791,
        "_internal_originalTime": 1689762206000
    },
    {
        "time": 1689762207000,
        "value": 139.789,
        "_internal_originalTime": 1689762207000
    },
    {
        "time": 1689762209000,
        "value": 139.788,
        "_internal_originalTime": 1689762209000
    },
    {
        "time": 1689762211000,
        "value": 139.788,
        "_internal_originalTime": 1689762211000
    },
    {
        "time": 1689762212000,
        "value": 139.791,
        "_internal_originalTime": 1689762212000
    },
    {
        "time": 1689762214000,
        "value": 139.79,
        "_internal_originalTime": 1689762214000
    },
    {
        "time": 1689762215000,
        "value": 139.787,
        "_internal_originalTime": 1689762215000
    },
    {
        "time": 1689762216000,
        "value": 139.79,
        "_internal_originalTime": 1689762216000
    },
    {
        "time": 1689762218000,
        "value": 139.791,
        "_internal_originalTime": 1689762218000
    },
    {
        "time": 1689762219000,
        "value": 139.79,
        "_internal_originalTime": 1689762219000
    },
    {
        "time": 1689762220000,
        "value": 139.791,
        "_internal_originalTime": 1689762220000
    },
    {
        "time": 1689762221000,
        "value": 139.791,
        "_internal_originalTime": 1689762221000
    },
    {
        "time": 1689762225000,
        "value": 139.792,
        "_internal_originalTime": 1689762225000
    },
    {
        "time": 1689762226000,
        "value": 139.79,
        "_internal_originalTime": 1689762226000
    },
    {
        "time": 1689762228000,
        "value": 139.786,
        "_internal_originalTime": 1689762228000
    },
    {
        "time": 1689762229000,
        "value": 139.789,
        "_internal_originalTime": 1689762229000
    },
    {
        "time": 1689762230000,
        "value": 139.789,
        "_internal_originalTime": 1689762230000
    },
    {
        "time": 1689762233000,
        "value": 139.789,
        "_internal_originalTime": 1689762233000
    },
    {
        "time": 1689762235000,
        "value": 139.788,
        "_internal_originalTime": 1689762235000
    },
    {
        "time": 1689762236000,
        "value": 139.785,
        "_internal_originalTime": 1689762236000
    },
    {
        "time": 1689762238000,
        "value": 139.782,
        "_internal_originalTime": 1689762238000
    },
    {
        "time": 1689762240000,
        "value": 139.786,
        "_internal_originalTime": 1689762240000
    },
    {
        "time": 1689762241000,
        "value": 139.78,
        "_internal_originalTime": 1689762241000
    },
    {
        "time": 1689762245000,
        "value": 139.782,
        "_internal_originalTime": 1689762245000
    },
    {
        "time": 1689762247000,
        "value": 139.782,
        "_internal_originalTime": 1689762247000
    },
    {
        "time": 1689762248000,
        "value": 139.778,
        "_internal_originalTime": 1689762248000
    },
    {
        "time": 1689762249000,
        "value": 139.78,
        "_internal_originalTime": 1689762249000
    },
    {
        "time": 1689762250000,
        "value": 139.779,
        "_internal_originalTime": 1689762250000
    },
    {
        "time": 1689762252000,
        "value": 139.78,
        "_internal_originalTime": 1689762252000
    },
    {
        "time": 1689762257000,
        "value": 139.777,
        "_internal_originalTime": 1689762257000
    },
    {
        "time": 1689762259000,
        "value": 139.776,
        "_internal_originalTime": 1689762259000
    },
    {
        "time": 1689762260000,
        "value": 139.776,
        "_internal_originalTime": 1689762260000
    },
    {
        "time": 1689762261000,
        "value": 139.773,
        "_internal_originalTime": 1689762261000
    },
    {
        "time": 1689762263000,
        "value": 139.771,
        "_internal_originalTime": 1689762263000
    },
    {
        "time": 1689762264000,
        "value": 139.771,
        "_internal_originalTime": 1689762264000
    },
    {
        "time": 1689762266000,
        "value": 139.771,
        "_internal_originalTime": 1689762266000
    },
    {
        "time": 1689762267000,
        "value": 139.772,
        "_internal_originalTime": 1689762267000
    },
    {
        "time": 1689762269000,
        "value": 139.771,
        "_internal_originalTime": 1689762269000
    },
    {
        "time": 1689762270000,
        "value": 139.772,
        "_internal_originalTime": 1689762270000
    },
    {
        "time": 1689762272000,
        "value": 139.773,
        "_internal_originalTime": 1689762272000
    },
    {
        "time": 1689762273000,
        "value": 139.771,
        "_internal_originalTime": 1689762273000
    },
    {
        "time": 1689762274000,
        "value": 139.771,
        "_internal_originalTime": 1689762274000
    },
    {
        "time": 1689762276000,
        "value": 139.765,
        "_internal_originalTime": 1689762276000
    },
    {
        "time": 1689762277000,
        "value": 139.768,
        "_internal_originalTime": 1689762277000
    },
    {
        "time": 1689762279000,
        "value": 139.776,
        "_internal_originalTime": 1689762279000
    },
    {
        "time": 1689762280000,
        "value": 139.776,
        "_internal_originalTime": 1689762280000
    },
    {
        "time": 1689762281000,
        "value": 139.77,
        "_internal_originalTime": 1689762281000
    },
    {
        "time": 1689762284000,
        "value": 139.773,
        "_internal_originalTime": 1689762284000
    },
    {
        "time": 1689762286000,
        "value": 139.771,
        "_internal_originalTime": 1689762286000
    },
    {
        "time": 1689762287000,
        "value": 139.772,
        "_internal_originalTime": 1689762287000
    },
    {
        "time": 1689762288000,
        "value": 139.768,
        "_internal_originalTime": 1689762288000
    },
    {
        "time": 1689762290000,
        "value": 139.77,
        "_internal_originalTime": 1689762290000
    },
    {
        "time": 1689762291000,
        "value": 139.762,
        "_internal_originalTime": 1689762291000
    },
    {
        "time": 1689762293000,
        "value": 139.769,
        "_internal_originalTime": 1689762293000
    },
    {
        "time": 1689762295000,
        "value": 139.766,
        "_internal_originalTime": 1689762295000
    },
    {
        "time": 1689762297000,
        "value": 139.77,
        "_internal_originalTime": 1689762297000
    },
    {
        "time": 1689762298000,
        "value": 139.772,
        "_internal_originalTime": 1689762298000
    },
    {
        "time": 1689762300000,
        "value": 139.771,
        "_internal_originalTime": 1689762300000
    },
    {
        "time": 1689762302000,
        "value": 139.77,
        "_internal_originalTime": 1689762302000
    },
    {
        "time": 1689762303000,
        "value": 139.747,
        "_internal_originalTime": 1689762303000
    },
    {
        "time": 1689762305000,
        "value": 139.748,
        "_internal_originalTime": 1689762305000
    },
    {
        "time": 1689762306000,
        "value": 139.751,
        "_internal_originalTime": 1689762306000
    },
    {
        "time": 1689762307000,
        "value": 139.749,
        "_internal_originalTime": 1689762307000
    },
    {
        "time": 1689762309000,
        "value": 139.751,
        "_internal_originalTime": 1689762309000
    },
    {
        "time": 1689762310000,
        "value": 139.749,
        "_internal_originalTime": 1689762310000
    },
    {
        "time": 1689762312000,
        "value": 139.751,
        "_internal_originalTime": 1689762312000
    },
    {
        "time": 1689762315000,
        "value": 139.751,
        "_internal_originalTime": 1689762315000
    },
    {
        "time": 1689762316000,
        "value": 139.755,
        "_internal_originalTime": 1689762316000
    },
    {
        "time": 1689762317000,
        "value": 139.758,
        "_internal_originalTime": 1689762317000
    },
    {
        "time": 1689762318000,
        "value": 139.76,
        "_internal_originalTime": 1689762318000
    },
    {
        "time": 1689762320000,
        "value": 139.761,
        "_internal_originalTime": 1689762320000
    },
    {
        "time": 1689762321000,
        "value": 139.757,
        "_internal_originalTime": 1689762321000
    },
    {
        "time": 1689762322000,
        "value": 139.753,
        "_internal_originalTime": 1689762322000
    },
    {
        "time": 1689762324000,
        "value": 139.755,
        "_internal_originalTime": 1689762324000
    },
    {
        "time": 1689762325000,
        "value": 139.753,
        "_internal_originalTime": 1689762325000
    },
    {
        "time": 1689762326000,
        "value": 139.752,
        "_internal_originalTime": 1689762326000
    },
    {
        "time": 1689762328000,
        "value": 139.75,
        "_internal_originalTime": 1689762328000
    },
    {
        "time": 1689762329000,
        "value": 139.753,
        "_internal_originalTime": 1689762329000
    },
    {
        "time": 1689762331000,
        "value": 139.753,
        "_internal_originalTime": 1689762331000
    },
    {
        "time": 1689762334000,
        "value": 139.753,
        "_internal_originalTime": 1689762334000
    },
    {
        "time": 1689762335000,
        "value": 139.753,
        "_internal_originalTime": 1689762335000
    },
    {
        "time": 1689762336000,
        "value": 139.752,
        "_internal_originalTime": 1689762336000
    },
    {
        "time": 1689762338000,
        "value": 139.754,
        "_internal_originalTime": 1689762338000
    },
    {
        "time": 1689762339000,
        "value": 139.754,
        "_internal_originalTime": 1689762339000
    },
    {
        "time": 1689762342000,
        "value": 139.748,
        "_internal_originalTime": 1689762342000
    },
    {
        "time": 1689762344000,
        "value": 139.753,
        "_internal_originalTime": 1689762344000
    },
    {
        "time": 1689762346000,
        "value": 139.752,
        "_internal_originalTime": 1689762346000
    },
    {
        "time": 1689762349000,
        "value": 139.754,
        "_internal_originalTime": 1689762349000
    },
    {
        "time": 1689762350000,
        "value": 139.754,
        "_internal_originalTime": 1689762350000
    },
    {
        "time": 1689762354000,
        "value": 139.75,
        "_internal_originalTime": 1689762354000
    },
    {
        "time": 1689762355000,
        "value": 139.755,
        "_internal_originalTime": 1689762355000
    },
    {
        "time": 1689762357000,
        "value": 139.763,
        "_internal_originalTime": 1689762357000
    },
    {
        "time": 1689762358000,
        "value": 139.766,
        "_internal_originalTime": 1689762358000
    },
    {
        "time": 1689762360000,
        "value": 139.766,
        "_internal_originalTime": 1689762360000
    },
    {
        "time": 1689762362000,
        "value": 139.76,
        "_internal_originalTime": 1689762362000
    },
    {
        "time": 1689762363000,
        "value": 139.761,
        "_internal_originalTime": 1689762363000
    },
    {
        "time": 1689762365000,
        "value": 139.762,
        "_internal_originalTime": 1689762365000
    },
    {
        "time": 1689762366000,
        "value": 139.757,
        "_internal_originalTime": 1689762366000
    },
    {
        "time": 1689762368000,
        "value": 139.761,
        "_internal_originalTime": 1689762368000
    },
    {
        "time": 1689762369000,
        "value": 139.762,
        "_internal_originalTime": 1689762369000
    },
    {
        "time": 1689762371000,
        "value": 139.759,
        "_internal_originalTime": 1689762371000
    },
    {
        "time": 1689762373000,
        "value": 139.757,
        "_internal_originalTime": 1689762373000
    },
    {
        "time": 1689762375000,
        "value": 139.753,
        "_internal_originalTime": 1689762375000
    },
    {
        "time": 1689762376000,
        "value": 139.755,
        "_internal_originalTime": 1689762376000
    },
    {
        "time": 1689762378000,
        "value": 139.754,
        "_internal_originalTime": 1689762378000
    },
    {
        "time": 1689762379000,
        "value": 139.757,
        "_internal_originalTime": 1689762379000
    },
    {
        "time": 1689762380000,
        "value": 139.757,
        "_internal_originalTime": 1689762380000
    },
    {
        "time": 1689762381000,
        "value": 139.757,
        "_internal_originalTime": 1689762381000
    },
    {
        "time": 1689762382000,
        "value": 139.755,
        "_internal_originalTime": 1689762382000
    },
    {
        "time": 1689762387000,
        "value": 139.752,
        "_internal_originalTime": 1689762387000
    },
    {
        "time": 1689762389000,
        "value": 139.756,
        "_internal_originalTime": 1689762389000
    },
    {
        "time": 1689762391000,
        "value": 139.754,
        "_internal_originalTime": 1689762391000
    },
    {
        "time": 1689762392000,
        "value": 139.751,
        "_internal_originalTime": 1689762392000
    },
    {
        "time": 1689762394000,
        "value": 139.751,
        "_internal_originalTime": 1689762394000
    },
    {
        "time": 1689762396000,
        "value": 139.748,
        "_internal_originalTime": 1689762396000
    },
    {
        "time": 1689762397000,
        "value": 139.75,
        "_internal_originalTime": 1689762397000
    },
    {
        "time": 1689762398000,
        "value": 139.756,
        "_internal_originalTime": 1689762398000
    },
    {
        "time": 1689762400000,
        "value": 139.752,
        "_internal_originalTime": 1689762400000
    },
    {
        "time": 1689762401000,
        "value": 139.754,
        "_internal_originalTime": 1689762401000
    },
    {
        "time": 1689762403000,
        "value": 139.752,
        "_internal_originalTime": 1689762403000
    },
    {
        "time": 1689762405000,
        "value": 139.752,
        "_internal_originalTime": 1689762405000
    },
    {
        "time": 1689762406000,
        "value": 139.752,
        "_internal_originalTime": 1689762406000
    },
    {
        "time": 1689762407000,
        "value": 139.752,
        "_internal_originalTime": 1689762407000
    },
    {
        "time": 1689762409000,
        "value": 139.749,
        "_internal_originalTime": 1689762409000
    },
    {
        "time": 1689762410000,
        "value": 139.758,
        "_internal_originalTime": 1689762410000
    },
    {
        "time": 1689762411000,
        "value": 139.763,
        "_internal_originalTime": 1689762411000
    },
    {
        "time": 1689762413000,
        "value": 139.763,
        "_internal_originalTime": 1689762413000
    },
    {
        "time": 1689762414000,
        "value": 139.762,
        "_internal_originalTime": 1689762414000
    },
    {
        "time": 1689762415000,
        "value": 139.766,
        "_internal_originalTime": 1689762415000
    },
    {
        "time": 1689762419000,
        "value": 139.763,
        "_internal_originalTime": 1689762419000
    },
    {
        "time": 1689762421000,
        "value": 139.74,
        "_internal_originalTime": 1689762421000
    },
    {
        "time": 1689762426000,
        "value": 139.758,
        "_internal_originalTime": 1689762426000
    },
    {
        "time": 1689762428000,
        "value": 139.763,
        "_internal_originalTime": 1689762428000
    },
    {
        "time": 1689762429000,
        "value": 139.761,
        "_internal_originalTime": 1689762429000
    },
    {
        "time": 1689762430000,
        "value": 139.761,
        "_internal_originalTime": 1689762430000
    },
    {
        "time": 1689762432000,
        "value": 139.76,
        "_internal_originalTime": 1689762432000
    },
    {
        "time": 1689762433000,
        "value": 139.762,
        "_internal_originalTime": 1689762433000
    },
    {
        "time": 1689762434000,
        "value": 139.766,
        "_internal_originalTime": 1689762434000
    },
    {
        "time": 1689762435000,
        "value": 139.765,
        "_internal_originalTime": 1689762435000
    },
    {
        "time": 1689762438000,
        "value": 139.761,
        "_internal_originalTime": 1689762438000
    },
    {
        "time": 1689762439000,
        "value": 139.763,
        "_internal_originalTime": 1689762439000
    },
    {
        "time": 1689762440000,
        "value": 139.761,
        "_internal_originalTime": 1689762440000
    },
    {
        "time": 1689762441000,
        "value": 139.758,
        "_internal_originalTime": 1689762441000
    },
    {
        "time": 1689762444000,
        "value": 139.763,
        "_internal_originalTime": 1689762444000
    },
    {
        "time": 1689762446000,
        "value": 139.761,
        "_internal_originalTime": 1689762446000
    },
    {
        "time": 1689762448000,
        "value": 139.763,
        "_internal_originalTime": 1689762448000
    },
    {
        "time": 1689762449000,
        "value": 139.758,
        "_internal_originalTime": 1689762449000
    },
    {
        "time": 1689762451000,
        "value": 139.763,
        "_internal_originalTime": 1689762451000
    },
    {
        "time": 1689762452000,
        "value": 139.763,
        "_internal_originalTime": 1689762452000
    },
    {
        "time": 1689762454000,
        "value": 139.756,
        "_internal_originalTime": 1689762454000
    },
    {
        "time": 1689762455000,
        "value": 139.754,
        "_internal_originalTime": 1689762455000
    },
    {
        "time": 1689762456000,
        "value": 139.749,
        "_internal_originalTime": 1689762456000
    },
    {
        "time": 1689762458000,
        "value": 139.753,
        "_internal_originalTime": 1689762458000
    },
    {
        "time": 1689762460000,
        "value": 139.75,
        "_internal_originalTime": 1689762460000
    },
    {
        "time": 1689762463000,
        "value": 139.755,
        "_internal_originalTime": 1689762463000
    },
    {
        "time": 1689762464000,
        "value": 139.753,
        "_internal_originalTime": 1689762464000
    },
    {
        "time": 1689762468000,
        "value": 139.754,
        "_internal_originalTime": 1689762468000
    },
    {
        "time": 1689762469000,
        "value": 139.75,
        "_internal_originalTime": 1689762469000
    },
    {
        "time": 1689762471000,
        "value": 139.75,
        "_internal_originalTime": 1689762471000
    },
    {
        "time": 1689762472000,
        "value": 139.752,
        "_internal_originalTime": 1689762472000
    },
    {
        "time": 1689762474000,
        "value": 139.761,
        "_internal_originalTime": 1689762474000
    },
    {
        "time": 1689762475000,
        "value": 139.76,
        "_internal_originalTime": 1689762475000
    },
    {
        "time": 1689762476000,
        "value": 139.763,
        "_internal_originalTime": 1689762476000
    },
    {
        "time": 1689762477000,
        "value": 139.763,
        "_internal_originalTime": 1689762477000
    },
    {
        "time": 1689762479000,
        "value": 139.758,
        "_internal_originalTime": 1689762479000
    },
    {
        "time": 1689762480000,
        "value": 139.759,
        "_internal_originalTime": 1689762480000
    },
    {
        "time": 1689762481000,
        "value": 139.76,
        "_internal_originalTime": 1689762481000
    },
    {
        "time": 1689762482000,
        "value": 139.755,
        "_internal_originalTime": 1689762482000
    },
    {
        "time": 1689762484000,
        "value": 139.754,
        "_internal_originalTime": 1689762484000
    },
    {
        "time": 1689762485000,
        "value": 139.754,
        "_internal_originalTime": 1689762485000
    },
    {
        "time": 1689762486000,
        "value": 139.748,
        "_internal_originalTime": 1689762486000
    },
    {
        "time": 1689762487000,
        "value": 139.75,
        "_internal_originalTime": 1689762487000
    },
    {
        "time": 1689762489000,
        "value": 139.753,
        "_internal_originalTime": 1689762489000
    },
    {
        "time": 1689762490000,
        "value": 139.75,
        "_internal_originalTime": 1689762490000
    },
    {
        "time": 1689762491000,
        "value": 139.754,
        "_internal_originalTime": 1689762491000
    },
    {
        "time": 1689762493000,
        "value": 139.751,
        "_internal_originalTime": 1689762493000
    },
    {
        "time": 1689762495000,
        "value": 139.751,
        "_internal_originalTime": 1689762495000
    },
    {
        "time": 1689762496000,
        "value": 139.748,
        "_internal_originalTime": 1689762496000
    },
    {
        "time": 1689762498000,
        "value": 139.751,
        "_internal_originalTime": 1689762498000
    },
    {
        "time": 1689762500000,
        "value": 139.748,
        "_internal_originalTime": 1689762500000
    },
    {
        "time": 1689762501000,
        "value": 139.746,
        "_internal_originalTime": 1689762501000
    },
    {
        "time": 1689762502000,
        "value": 139.748,
        "_internal_originalTime": 1689762502000
    },
    {
        "time": 1689762504000,
        "value": 139.751,
        "_internal_originalTime": 1689762504000
    },
    {
        "time": 1689762505000,
        "value": 139.749,
        "_internal_originalTime": 1689762505000
    },
    {
        "time": 1689762507000,
        "value": 139.75,
        "_internal_originalTime": 1689762507000
    },
    {
        "time": 1689762510000,
        "value": 139.753,
        "_internal_originalTime": 1689762510000
    },
    {
        "time": 1689762511000,
        "value": 139.752,
        "_internal_originalTime": 1689762511000
    },
    {
        "time": 1689762512000,
        "value": 139.755,
        "_internal_originalTime": 1689762512000
    },
    {
        "time": 1689762513000,
        "value": 139.754,
        "_internal_originalTime": 1689762513000
    },
    {
        "time": 1689762515000,
        "value": 139.754,
        "_internal_originalTime": 1689762515000
    },
    {
        "time": 1689762516000,
        "value": 139.749,
        "_internal_originalTime": 1689762516000
    },
    {
        "time": 1689762517000,
        "value": 139.749,
        "_internal_originalTime": 1689762517000
    },
    {
        "time": 1689762519000,
        "value": 139.748,
        "_internal_originalTime": 1689762519000
    },
    {
        "time": 1689762520000,
        "value": 139.746,
        "_internal_originalTime": 1689762520000
    },
    {
        "time": 1689762522000,
        "value": 139.753,
        "_internal_originalTime": 1689762522000
    },
    {
        "time": 1689762523000,
        "value": 139.752,
        "_internal_originalTime": 1689762523000
    },
    {
        "time": 1689762525000,
        "value": 139.747,
        "_internal_originalTime": 1689762525000
    },
    {
        "time": 1689762528000,
        "value": 139.746,
        "_internal_originalTime": 1689762528000
    },
    {
        "time": 1689762529000,
        "value": 139.748,
        "_internal_originalTime": 1689762529000
    },
    {
        "time": 1689762531000,
        "value": 139.754,
        "_internal_originalTime": 1689762531000
    },
    {
        "time": 1689762532000,
        "value": 139.749,
        "_internal_originalTime": 1689762532000
    },
    {
        "time": 1689762533000,
        "value": 139.754,
        "_internal_originalTime": 1689762533000
    },
    {
        "time": 1689762535000,
        "value": 139.752,
        "_internal_originalTime": 1689762535000
    },
    {
        "time": 1689762537000,
        "value": 139.751,
        "_internal_originalTime": 1689762537000
    },
    {
        "time": 1689762538000,
        "value": 139.753,
        "_internal_originalTime": 1689762538000
    },
    {
        "time": 1689762539000,
        "value": 139.743,
        "_internal_originalTime": 1689762539000
    },
    {
        "time": 1689762540000,
        "value": 139.745,
        "_internal_originalTime": 1689762540000
    },
    {
        "time": 1689762541000,
        "value": 139.749,
        "_internal_originalTime": 1689762541000
    },
    {
        "time": 1689762542000,
        "value": 139.746,
        "_internal_originalTime": 1689762542000
    },
    {
        "time": 1689762543000,
        "value": 139.746,
        "_internal_originalTime": 1689762543000
    },
    {
        "time": 1689762544000,
        "value": 139.746,
        "_internal_originalTime": 1689762544000
    },
    {
        "time": 1689762545000,
        "value": 139.743,
        "_internal_originalTime": 1689762545000
    },
    {
        "time": 1689762546000,
        "value": 139.749,
        "_internal_originalTime": 1689762546000
    },
    {
        "time": 1689762548000,
        "value": 139.746,
        "_internal_originalTime": 1689762548000
    },
    {
        "time": 1689762549000,
        "value": 139.751,
        "_internal_originalTime": 1689762549000
    },
    {
        "time": 1689762551000,
        "value": 139.748,
        "_internal_originalTime": 1689762551000
    },
    {
        "time": 1689762554000,
        "value": 139.75,
        "_internal_originalTime": 1689762554000
    },
    {
        "time": 1689762555000,
        "value": 139.75,
        "_internal_originalTime": 1689762555000
    },
    {
        "time": 1689762556000,
        "value": 139.749,
        "_internal_originalTime": 1689762556000
    },
    {
        "time": 1689762557000,
        "value": 139.742,
        "_internal_originalTime": 1689762557000
    },
    {
        "time": 1689762558000,
        "value": 139.748,
        "_internal_originalTime": 1689762558000
    },
    {
        "time": 1689762560000,
        "value": 139.747,
        "_internal_originalTime": 1689762560000
    },
    {
        "time": 1689762561000,
        "value": 139.751,
        "_internal_originalTime": 1689762561000
    },
    {
        "time": 1689762562000,
        "value": 139.75,
        "_internal_originalTime": 1689762562000
    },
    {
        "time": 1689762563000,
        "value": 139.748,
        "_internal_originalTime": 1689762563000
    },
    {
        "time": 1689762565000,
        "value": 139.748,
        "_internal_originalTime": 1689762565000
    },
    {
        "time": 1689762567000,
        "value": 139.748,
        "_internal_originalTime": 1689762567000
    },
    {
        "time": 1689762569000,
        "value": 139.746,
        "_internal_originalTime": 1689762569000
    },
    {
        "time": 1689762571000,
        "value": 139.746,
        "_internal_originalTime": 1689762571000
    },
    {
        "time": 1689762572000,
        "value": 139.748,
        "_internal_originalTime": 1689762572000
    },
    {
        "time": 1689762573000,
        "value": 139.753,
        "_internal_originalTime": 1689762573000
    },
    {
        "time": 1689762574000,
        "value": 139.752,
        "_internal_originalTime": 1689762574000
    },
    {
        "time": 1689762576000,
        "value": 139.746,
        "_internal_originalTime": 1689762576000
    },
    {
        "time": 1689762578000,
        "value": 139.751,
        "_internal_originalTime": 1689762578000
    },
    {
        "time": 1689762580000,
        "value": 139.749,
        "_internal_originalTime": 1689762580000
    },
    {
        "time": 1689762582000,
        "value": 139.747,
        "_internal_originalTime": 1689762582000
    },
    {
        "time": 1689762583000,
        "value": 139.749,
        "_internal_originalTime": 1689762583000
    },
    {
        "time": 1689762586000,
        "value": 139.749,
        "_internal_originalTime": 1689762586000
    },
    {
        "time": 1689762590000,
        "value": 139.749,
        "_internal_originalTime": 1689762590000
    },
    {
        "time": 1689762591000,
        "value": 139.75,
        "_internal_originalTime": 1689762591000
    },
    {
        "time": 1689762593000,
        "value": 139.751,
        "_internal_originalTime": 1689762593000
    },
    {
        "time": 1689762594000,
        "value": 139.753,
        "_internal_originalTime": 1689762594000
    },
    {
        "time": 1689762595000,
        "value": 139.751,
        "_internal_originalTime": 1689762595000
    },
    {
        "time": 1689762596000,
        "value": 139.751,
        "_internal_originalTime": 1689762596000
    },
    {
        "time": 1689762598000,
        "value": 139.752,
        "_internal_originalTime": 1689762598000
    },
    {
        "time": 1689762600000,
        "value": 139.75,
        "_internal_originalTime": 1689762600000
    },
    {
        "time": 1689762601000,
        "value": 139.749,
        "_internal_originalTime": 1689762601000
    },
    {
        "time": 1689762602000,
        "value": 139.75,
        "_internal_originalTime": 1689762602000
    },
    {
        "time": 1689762604000,
        "value": 139.747,
        "_internal_originalTime": 1689762604000
    },
    {
        "time": 1689762605000,
        "value": 139.75,
        "_internal_originalTime": 1689762605000
    },
    {
        "time": 1689762606000,
        "value": 139.751,
        "_internal_originalTime": 1689762606000
    },
    {
        "time": 1689762609000,
        "value": 139.759,
        "_internal_originalTime": 1689762609000
    },
    {
        "time": 1689762610000,
        "value": 139.756,
        "_internal_originalTime": 1689762610000
    },
    {
        "time": 1689762611000,
        "value": 139.763,
        "_internal_originalTime": 1689762611000
    },
    {
        "time": 1689762614000,
        "value": 139.751,
        "_internal_originalTime": 1689762614000
    },
    {
        "time": 1689762615000,
        "value": 139.75,
        "_internal_originalTime": 1689762615000
    },
    {
        "time": 1689762616000,
        "value": 139.751,
        "_internal_originalTime": 1689762616000
    },
    {
        "time": 1689762619000,
        "value": 139.75,
        "_internal_originalTime": 1689762619000
    },
    {
        "time": 1689762620000,
        "value": 139.751,
        "_internal_originalTime": 1689762620000
    },
    {
        "time": 1689762621000,
        "value": 139.758,
        "_internal_originalTime": 1689762621000
    },
    {
        "time": 1689762623000,
        "value": 139.762,
        "_internal_originalTime": 1689762623000
    },
    {
        "time": 1689762624000,
        "value": 139.761,
        "_internal_originalTime": 1689762624000
    },
    {
        "time": 1689762625000,
        "value": 139.758,
        "_internal_originalTime": 1689762625000
    },
    {
        "time": 1689762627000,
        "value": 139.762,
        "_internal_originalTime": 1689762627000
    },
    {
        "time": 1689762628000,
        "value": 139.76,
        "_internal_originalTime": 1689762628000
    },
    {
        "time": 1689762630000,
        "value": 139.762,
        "_internal_originalTime": 1689762630000
    },
    {
        "time": 1689762631000,
        "value": 139.756,
        "_internal_originalTime": 1689762631000
    },
    {
        "time": 1689762632000,
        "value": 139.756,
        "_internal_originalTime": 1689762632000
    },
    {
        "time": 1689762633000,
        "value": 139.756,
        "_internal_originalTime": 1689762633000
    },
    {
        "time": 1689762635000,
        "value": 139.759,
        "_internal_originalTime": 1689762635000
    },
    {
        "time": 1689762637000,
        "value": 139.76,
        "_internal_originalTime": 1689762637000
    },
    {
        "time": 1689762638000,
        "value": 139.756,
        "_internal_originalTime": 1689762638000
    },
    {
        "time": 1689762640000,
        "value": 139.762,
        "_internal_originalTime": 1689762640000
    },
    {
        "time": 1689762641000,
        "value": 139.763,
        "_internal_originalTime": 1689762641000
    },
    {
        "time": 1689762643000,
        "value": 139.763,
        "_internal_originalTime": 1689762643000
    },
    {
        "time": 1689762644000,
        "value": 139.76,
        "_internal_originalTime": 1689762644000
    },
    {
        "time": 1689762645000,
        "value": 139.759,
        "_internal_originalTime": 1689762645000
    },
    {
        "time": 1689762646000,
        "value": 139.76,
        "_internal_originalTime": 1689762646000
    },
    {
        "time": 1689762647000,
        "value": 139.759,
        "_internal_originalTime": 1689762647000
    },
    {
        "time": 1689762648000,
        "value": 139.76,
        "_internal_originalTime": 1689762648000
    },
    {
        "time": 1689762649000,
        "value": 139.756,
        "_internal_originalTime": 1689762649000
    },
    {
        "time": 1689762652000,
        "value": 139.756,
        "_internal_originalTime": 1689762652000
    },
    {
        "time": 1689762654000,
        "value": 139.756,
        "_internal_originalTime": 1689762654000
    },
    {
        "time": 1689762655000,
        "value": 139.756,
        "_internal_originalTime": 1689762655000
    },
    {
        "time": 1689762656000,
        "value": 139.756,
        "_internal_originalTime": 1689762656000
    },
    {
        "time": 1689762659000,
        "value": 139.756,
        "_internal_originalTime": 1689762659000
    },
    {
        "time": 1689762660000,
        "value": 139.756,
        "_internal_originalTime": 1689762660000
    },
    {
        "time": 1689762661000,
        "value": 139.756,
        "_internal_originalTime": 1689762661000
    },
    {
        "time": 1689762664000,
        "value": 139.765,
        "_internal_originalTime": 1689762664000
    },
    {
        "time": 1689762665000,
        "value": 139.763,
        "_internal_originalTime": 1689762665000
    },
    {
        "time": 1689762666000,
        "value": 139.758,
        "_internal_originalTime": 1689762666000
    },
    {
        "time": 1689762668000,
        "value": 139.761,
        "_internal_originalTime": 1689762668000
    },
    {
        "time": 1689762669000,
        "value": 139.761,
        "_internal_originalTime": 1689762669000
    },
    {
        "time": 1689762671000,
        "value": 139.761,
        "_internal_originalTime": 1689762671000
    },
    {
        "time": 1689762672000,
        "value": 139.756,
        "_internal_originalTime": 1689762672000
    },
    {
        "time": 1689762673000,
        "value": 139.759,
        "_internal_originalTime": 1689762673000
    },
    {
        "time": 1689762674000,
        "value": 139.761,
        "_internal_originalTime": 1689762674000
    },
    {
        "time": 1689762675000,
        "value": 139.761,
        "_internal_originalTime": 1689762675000
    },
    {
        "time": 1689762676000,
        "value": 139.76,
        "_internal_originalTime": 1689762676000
    },
    {
        "time": 1689762677000,
        "value": 139.76,
        "_internal_originalTime": 1689762677000
    },
    {
        "time": 1689762680000,
        "value": 139.764,
        "_internal_originalTime": 1689762680000
    },
    {
        "time": 1689762682000,
        "value": 139.764,
        "_internal_originalTime": 1689762682000
    },
    {
        "time": 1689762684000,
        "value": 139.762,
        "_internal_originalTime": 1689762684000
    },
    {
        "time": 1689762685000,
        "value": 139.759,
        "_internal_originalTime": 1689762685000
    },
    {
        "time": 1689762686000,
        "value": 139.762,
        "_internal_originalTime": 1689762686000
    },
    {
        "time": 1689762688000,
        "value": 139.756,
        "_internal_originalTime": 1689762688000
    },
    {
        "time": 1689762691000,
        "value": 139.764,
        "_internal_originalTime": 1689762691000
    },
    {
        "time": 1689762692000,
        "value": 139.762,
        "_internal_originalTime": 1689762692000
    },
    {
        "time": 1689762694000,
        "value": 139.758,
        "_internal_originalTime": 1689762694000
    },
    {
        "time": 1689762696000,
        "value": 139.758,
        "_internal_originalTime": 1689762696000
    },
    {
        "time": 1689762697000,
        "value": 139.762,
        "_internal_originalTime": 1689762697000
    },
    {
        "time": 1689762698000,
        "value": 139.758,
        "_internal_originalTime": 1689762698000
    },
    {
        "time": 1689762699000,
        "value": 139.758,
        "_internal_originalTime": 1689762699000
    },
    {
        "time": 1689762701000,
        "value": 139.762,
        "_internal_originalTime": 1689762701000
    },
    {
        "time": 1689762702000,
        "value": 139.762,
        "_internal_originalTime": 1689762702000
    },
    {
        "time": 1689762704000,
        "value": 139.756,
        "_internal_originalTime": 1689762704000
    },
    {
        "time": 1689762705000,
        "value": 139.76,
        "_internal_originalTime": 1689762705000
    },
    {
        "time": 1689762706000,
        "value": 139.756,
        "_internal_originalTime": 1689762706000
    },
    {
        "time": 1689762708000,
        "value": 139.756,
        "_internal_originalTime": 1689762708000
    },
    {
        "time": 1689762711000,
        "value": 139.761,
        "_internal_originalTime": 1689762711000
    },
    {
        "time": 1689762712000,
        "value": 139.75,
        "_internal_originalTime": 1689762712000
    },
    {
        "time": 1689762713000,
        "value": 139.758,
        "_internal_originalTime": 1689762713000
    },
    {
        "time": 1689762715000,
        "value": 139.76,
        "_internal_originalTime": 1689762715000
    },
    {
        "time": 1689762717000,
        "value": 139.76,
        "_internal_originalTime": 1689762717000
    },
    {
        "time": 1689762718000,
        "value": 139.761,
        "_internal_originalTime": 1689762718000
    },
    {
        "time": 1689762719000,
        "value": 139.761,
        "_internal_originalTime": 1689762719000
    },
    {
        "time": 1689762720000,
        "value": 139.76,
        "_internal_originalTime": 1689762720000
    },
    {
        "time": 1689762721000,
        "value": 139.76,
        "_internal_originalTime": 1689762721000
    },
    {
        "time": 1689762723000,
        "value": 139.76,
        "_internal_originalTime": 1689762723000
    },
    {
        "time": 1689762724000,
        "value": 139.761,
        "_internal_originalTime": 1689762724000
    },
    {
        "time": 1689762726000,
        "value": 139.767,
        "_internal_originalTime": 1689762726000
    },
    {
        "time": 1689762728000,
        "value": 139.762,
        "_internal_originalTime": 1689762728000
    },
    {
        "time": 1689762730000,
        "value": 139.765,
        "_internal_originalTime": 1689762730000
    },
    {
        "time": 1689762734000,
        "value": 139.764,
        "_internal_originalTime": 1689762734000
    },
    {
        "time": 1689762735000,
        "value": 139.776,
        "_internal_originalTime": 1689762735000
    },
    {
        "time": 1689762736000,
        "value": 139.777,
        "_internal_originalTime": 1689762736000
    },
    {
        "time": 1689762737000,
        "value": 139.777,
        "_internal_originalTime": 1689762737000
    },
    {
        "time": 1689762739000,
        "value": 139.776,
        "_internal_originalTime": 1689762739000
    },
    {
        "time": 1689762740000,
        "value": 139.779,
        "_internal_originalTime": 1689762740000
    },
    {
        "time": 1689762741000,
        "value": 139.781,
        "_internal_originalTime": 1689762741000
    },
    {
        "time": 1689762743000,
        "value": 139.782,
        "_internal_originalTime": 1689762743000
    },
    {
        "time": 1689762744000,
        "value": 139.781,
        "_internal_originalTime": 1689762744000
    },
    {
        "time": 1689762746000,
        "value": 139.781,
        "_internal_originalTime": 1689762746000
    },
    {
        "time": 1689762747000,
        "value": 139.781,
        "_internal_originalTime": 1689762747000
    },
    {
        "time": 1689762750000,
        "value": 139.782,
        "_internal_originalTime": 1689762750000
    },
    {
        "time": 1689762751000,
        "value": 139.787,
        "_internal_originalTime": 1689762751000
    },
    {
        "time": 1689762752000,
        "value": 139.786,
        "_internal_originalTime": 1689762752000
    },
    {
        "time": 1689762753000,
        "value": 139.784,
        "_internal_originalTime": 1689762753000
    },
    {
        "time": 1689762755000,
        "value": 139.786,
        "_internal_originalTime": 1689762755000
    },
    {
        "time": 1689762756000,
        "value": 139.787,
        "_internal_originalTime": 1689762756000
    },
    {
        "time": 1689762758000,
        "value": 139.788,
        "_internal_originalTime": 1689762758000
    },
    {
        "time": 1689762759000,
        "value": 139.791,
        "_internal_originalTime": 1689762759000
    },
    {
        "time": 1689762760000,
        "value": 139.791,
        "_internal_originalTime": 1689762760000
    },
    {
        "time": 1689762762000,
        "value": 139.791,
        "_internal_originalTime": 1689762762000
    },
    {
        "time": 1689762763000,
        "value": 139.792,
        "_internal_originalTime": 1689762763000
    },
    {
        "time": 1689762765000,
        "value": 139.791,
        "_internal_originalTime": 1689762765000
    },
    {
        "time": 1689762767000,
        "value": 139.789,
        "_internal_originalTime": 1689762767000
    },
    {
        "time": 1689762768000,
        "value": 139.799,
        "_internal_originalTime": 1689762768000
    },
    {
        "time": 1689762770000,
        "value": 139.799,
        "_internal_originalTime": 1689762770000
    },
    {
        "time": 1689762771000,
        "value": 139.809,
        "_internal_originalTime": 1689762771000
    },
    {
        "time": 1689762772000,
        "value": 139.81,
        "_internal_originalTime": 1689762772000
    },
    {
        "time": 1689762774000,
        "value": 139.81,
        "_internal_originalTime": 1689762774000
    },
    {
        "time": 1689762776000,
        "value": 139.812,
        "_internal_originalTime": 1689762776000
    },
    {
        "time": 1689762777000,
        "value": 139.809,
        "_internal_originalTime": 1689762777000
    },
    {
        "time": 1689762779000,
        "value": 139.819,
        "_internal_originalTime": 1689762779000
    },
    {
        "time": 1689762780000,
        "value": 139.817,
        "_internal_originalTime": 1689762780000
    },
    {
        "time": 1689762781000,
        "value": 139.82,
        "_internal_originalTime": 1689762781000
    },
    {
        "time": 1689762782000,
        "value": 139.819,
        "_internal_originalTime": 1689762782000
    },
    {
        "time": 1689762783000,
        "value": 139.818,
        "_internal_originalTime": 1689762783000
    },
    {
        "time": 1689762784000,
        "value": 139.828,
        "_internal_originalTime": 1689762784000
    },
    {
        "time": 1689762785000,
        "value": 139.832,
        "_internal_originalTime": 1689762785000
    },
    {
        "time": 1689762786000,
        "value": 139.83,
        "_internal_originalTime": 1689762786000
    },
    {
        "time": 1689762788000,
        "value": 139.829,
        "_internal_originalTime": 1689762788000
    },
    {
        "time": 1689762789000,
        "value": 139.825,
        "_internal_originalTime": 1689762789000
    },
    {
        "time": 1689762791000,
        "value": 139.825,
        "_internal_originalTime": 1689762791000
    },
    {
        "time": 1689762792000,
        "value": 139.822,
        "_internal_originalTime": 1689762792000
    },
    {
        "time": 1689762794000,
        "value": 139.818,
        "_internal_originalTime": 1689762794000
    },
    {
        "time": 1689762795000,
        "value": 139.818,
        "_internal_originalTime": 1689762795000
    },
    {
        "time": 1689762797000,
        "value": 139.818,
        "_internal_originalTime": 1689762797000
    },
    {
        "time": 1689762798000,
        "value": 139.823,
        "_internal_originalTime": 1689762798000
    },
    {
        "time": 1689762799000,
        "value": 139.822,
        "_internal_originalTime": 1689762799000
    },
    {
        "time": 1689762801000,
        "value": 139.821,
        "_internal_originalTime": 1689762801000
    },
    {
        "time": 1689762803000,
        "value": 139.825,
        "_internal_originalTime": 1689762803000
    },
    {
        "time": 1689762804000,
        "value": 139.823,
        "_internal_originalTime": 1689762804000
    },
    {
        "time": 1689762805000,
        "value": 139.822,
        "_internal_originalTime": 1689762805000
    },
    {
        "time": 1689762807000,
        "value": 139.819,
        "_internal_originalTime": 1689762807000
    },
    {
        "time": 1689762808000,
        "value": 139.819,
        "_internal_originalTime": 1689762808000
    },
    {
        "time": 1689762810000,
        "value": 139.824,
        "_internal_originalTime": 1689762810000
    },
    {
        "time": 1689762812000,
        "value": 139.831,
        "_internal_originalTime": 1689762812000
    },
    {
        "time": 1689762813000,
        "value": 139.826,
        "_internal_originalTime": 1689762813000
    },
    {
        "time": 1689762815000,
        "value": 139.831,
        "_internal_originalTime": 1689762815000
    },
    {
        "time": 1689762817000,
        "value": 139.832,
        "_internal_originalTime": 1689762817000
    },
    {
        "time": 1689762818000,
        "value": 139.831,
        "_internal_originalTime": 1689762818000
    },
    {
        "time": 1689762819000,
        "value": 139.832,
        "_internal_originalTime": 1689762819000
    },
    {
        "time": 1689762821000,
        "value": 139.833,
        "_internal_originalTime": 1689762821000
    },
    {
        "time": 1689762822000,
        "value": 139.83,
        "_internal_originalTime": 1689762822000
    },
    {
        "time": 1689762823000,
        "value": 139.832,
        "_internal_originalTime": 1689762823000
    },
    {
        "time": 1689762824000,
        "value": 139.829,
        "_internal_originalTime": 1689762824000
    },
    {
        "time": 1689762825000,
        "value": 139.828,
        "_internal_originalTime": 1689762825000
    },
    {
        "time": 1689762827000,
        "value": 139.83,
        "_internal_originalTime": 1689762827000
    },
    {
        "time": 1689762829000,
        "value": 139.832,
        "_internal_originalTime": 1689762829000
    },
    {
        "time": 1689762831000,
        "value": 139.831,
        "_internal_originalTime": 1689762831000
    },
    {
        "time": 1689762833000,
        "value": 139.83,
        "_internal_originalTime": 1689762833000
    },
    {
        "time": 1689762835000,
        "value": 139.832,
        "_internal_originalTime": 1689762835000
    },
    {
        "time": 1689762836000,
        "value": 139.83,
        "_internal_originalTime": 1689762836000
    },
    {
        "time": 1689762837000,
        "value": 139.832,
        "_internal_originalTime": 1689762837000
    },
    {
        "time": 1689762839000,
        "value": 139.831,
        "_internal_originalTime": 1689762839000
    },
    {
        "time": 1689762840000,
        "value": 139.831,
        "_internal_originalTime": 1689762840000
    },
    {
        "time": 1689762842000,
        "value": 139.829,
        "_internal_originalTime": 1689762842000
    },
    {
        "time": 1689762843000,
        "value": 139.828,
        "_internal_originalTime": 1689762843000
    },
    {
        "time": 1689762844000,
        "value": 139.836,
        "_internal_originalTime": 1689762844000
    },
    {
        "time": 1689762846000,
        "value": 139.829,
        "_internal_originalTime": 1689762846000
    },
    {
        "time": 1689762847000,
        "value": 139.83,
        "_internal_originalTime": 1689762847000
    },
    {
        "time": 1689762848000,
        "value": 139.831,
        "_internal_originalTime": 1689762848000
    },
    {
        "time": 1689762849000,
        "value": 139.828,
        "_internal_originalTime": 1689762849000
    },
    {
        "time": 1689762851000,
        "value": 139.828,
        "_internal_originalTime": 1689762851000
    },
    {
        "time": 1689762853000,
        "value": 139.831,
        "_internal_originalTime": 1689762853000
    },
    {
        "time": 1689762854000,
        "value": 139.834,
        "_internal_originalTime": 1689762854000
    },
    {
        "time": 1689762856000,
        "value": 139.831,
        "_internal_originalTime": 1689762856000
    },
    {
        "time": 1689762858000,
        "value": 139.828,
        "_internal_originalTime": 1689762858000
    },
    {
        "time": 1689762859000,
        "value": 139.83,
        "_internal_originalTime": 1689762859000
    },
    {
        "time": 1689762860000,
        "value": 139.827,
        "_internal_originalTime": 1689762860000
    },
    {
        "time": 1689762862000,
        "value": 139.824,
        "_internal_originalTime": 1689762862000
    },
    {
        "time": 1689762863000,
        "value": 139.825,
        "_internal_originalTime": 1689762863000
    },
    {
        "time": 1689762864000,
        "value": 139.83,
        "_internal_originalTime": 1689762864000
    },
    {
        "time": 1689762866000,
        "value": 139.829,
        "_internal_originalTime": 1689762866000
    },
    {
        "time": 1689762867000,
        "value": 139.829,
        "_internal_originalTime": 1689762867000
    },
    {
        "time": 1689762868000,
        "value": 139.831,
        "_internal_originalTime": 1689762868000
    },
    {
        "time": 1689762870000,
        "value": 139.829,
        "_internal_originalTime": 1689762870000
    },
    {
        "time": 1689762871000,
        "value": 139.831,
        "_internal_originalTime": 1689762871000
    },
    {
        "time": 1689762872000,
        "value": 139.83,
        "_internal_originalTime": 1689762872000
    },
    {
        "time": 1689762873000,
        "value": 139.831,
        "_internal_originalTime": 1689762873000
    },
    {
        "time": 1689762874000,
        "value": 139.834,
        "_internal_originalTime": 1689762874000
    },
    {
        "time": 1689762876000,
        "value": 139.821,
        "_internal_originalTime": 1689762876000
    },
    {
        "time": 1689762877000,
        "value": 139.815,
        "_internal_originalTime": 1689762877000
    },
    {
        "time": 1689762878000,
        "value": 139.816,
        "_internal_originalTime": 1689762878000
    },
    {
        "time": 1689762880000,
        "value": 139.82,
        "_internal_originalTime": 1689762880000
    },
    {
        "time": 1689762881000,
        "value": 139.817,
        "_internal_originalTime": 1689762881000
    },
    {
        "time": 1689762883000,
        "value": 139.815,
        "_internal_originalTime": 1689762883000
    },
    {
        "time": 1689762885000,
        "value": 139.816,
        "_internal_originalTime": 1689762885000
    },
    {
        "time": 1689762886000,
        "value": 139.819,
        "_internal_originalTime": 1689762886000
    },
    {
        "time": 1689762888000,
        "value": 139.818,
        "_internal_originalTime": 1689762888000
    },
    {
        "time": 1689762889000,
        "value": 139.813,
        "_internal_originalTime": 1689762889000
    },
    {
        "time": 1689762890000,
        "value": 139.807,
        "_internal_originalTime": 1689762890000
    },
    {
        "time": 1689762892000,
        "value": 139.809,
        "_internal_originalTime": 1689762892000
    },
    {
        "time": 1689762893000,
        "value": 139.806,
        "_internal_originalTime": 1689762893000
    },
    {
        "time": 1689762894000,
        "value": 139.809,
        "_internal_originalTime": 1689762894000
    },
    {
        "time": 1689762896000,
        "value": 139.803,
        "_internal_originalTime": 1689762896000
    },
    {
        "time": 1689762897000,
        "value": 139.803,
        "_internal_originalTime": 1689762897000
    },
    {
        "time": 1689762898000,
        "value": 139.802,
        "_internal_originalTime": 1689762898000
    },
    {
        "time": 1689762900000,
        "value": 139.806,
        "_internal_originalTime": 1689762900000
    },
    {
        "time": 1689762901000,
        "value": 139.804,
        "_internal_originalTime": 1689762901000
    },
    {
        "time": 1689762902000,
        "value": 139.811,
        "_internal_originalTime": 1689762902000
    },
    {
        "time": 1689762903000,
        "value": 139.813,
        "_internal_originalTime": 1689762903000
    },
    {
        "time": 1689762904000,
        "value": 139.811,
        "_internal_originalTime": 1689762904000
    },
    {
        "time": 1689762906000,
        "value": 139.805,
        "_internal_originalTime": 1689762906000
    },
    {
        "time": 1689762907000,
        "value": 139.809,
        "_internal_originalTime": 1689762907000
    },
    {
        "time": 1689762908000,
        "value": 139.815,
        "_internal_originalTime": 1689762908000
    },
    {
        "time": 1689762909000,
        "value": 139.817,
        "_internal_originalTime": 1689762909000
    },
    {
        "time": 1689762911000,
        "value": 139.814,
        "_internal_originalTime": 1689762911000
    },
    {
        "time": 1689762912000,
        "value": 139.81,
        "_internal_originalTime": 1689762912000
    },
    {
        "time": 1689762913000,
        "value": 139.81,
        "_internal_originalTime": 1689762913000
    },
    {
        "time": 1689762915000,
        "value": 139.812,
        "_internal_originalTime": 1689762915000
    },
    {
        "time": 1689762916000,
        "value": 139.811,
        "_internal_originalTime": 1689762916000
    },
    {
        "time": 1689762917000,
        "value": 139.809,
        "_internal_originalTime": 1689762917000
    },
    {
        "time": 1689762918000,
        "value": 139.81,
        "_internal_originalTime": 1689762918000
    },
    {
        "time": 1689762919000,
        "value": 139.812,
        "_internal_originalTime": 1689762919000
    },
    {
        "time": 1689762921000,
        "value": 139.81,
        "_internal_originalTime": 1689762921000
    },
    {
        "time": 1689762923000,
        "value": 139.811,
        "_internal_originalTime": 1689762923000
    },
    {
        "time": 1689762924000,
        "value": 139.81,
        "_internal_originalTime": 1689762924000
    },
    {
        "time": 1689762926000,
        "value": 139.811,
        "_internal_originalTime": 1689762926000
    },
    {
        "time": 1689762927000,
        "value": 139.81,
        "_internal_originalTime": 1689762927000
    },
    {
        "time": 1689762928000,
        "value": 139.811,
        "_internal_originalTime": 1689762928000
    },
    {
        "time": 1689762930000,
        "value": 139.811,
        "_internal_originalTime": 1689762930000
    },
    {
        "time": 1689762931000,
        "value": 139.81,
        "_internal_originalTime": 1689762931000
    },
    {
        "time": 1689762932000,
        "value": 139.811,
        "_internal_originalTime": 1689762932000
    },
    {
        "time": 1689762934000,
        "value": 139.809,
        "_internal_originalTime": 1689762934000
    },
    {
        "time": 1689762936000,
        "value": 139.809,
        "_internal_originalTime": 1689762936000
    },
    {
        "time": 1689762937000,
        "value": 139.809,
        "_internal_originalTime": 1689762937000
    },
    {
        "time": 1689762939000,
        "value": 139.81,
        "_internal_originalTime": 1689762939000
    },
    {
        "time": 1689762941000,
        "value": 139.816,
        "_internal_originalTime": 1689762941000
    },
    {
        "time": 1689762942000,
        "value": 139.814,
        "_internal_originalTime": 1689762942000
    },
    {
        "time": 1689762944000,
        "value": 139.809,
        "_internal_originalTime": 1689762944000
    },
    {
        "time": 1689762945000,
        "value": 139.81,
        "_internal_originalTime": 1689762945000
    },
    {
        "time": 1689762947000,
        "value": 139.811,
        "_internal_originalTime": 1689762947000
    },
    {
        "time": 1689762948000,
        "value": 139.811,
        "_internal_originalTime": 1689762948000
    },
    {
        "time": 1689762949000,
        "value": 139.811,
        "_internal_originalTime": 1689762949000
    },
    {
        "time": 1689762951000,
        "value": 139.809,
        "_internal_originalTime": 1689762951000
    },
    {
        "time": 1689762952000,
        "value": 139.809,
        "_internal_originalTime": 1689762952000
    },
    {
        "time": 1689762953000,
        "value": 139.81,
        "_internal_originalTime": 1689762953000
    },
    {
        "time": 1689762955000,
        "value": 139.809,
        "_internal_originalTime": 1689762955000
    },
    {
        "time": 1689762956000,
        "value": 139.808,
        "_internal_originalTime": 1689762956000
    },
    {
        "time": 1689762957000,
        "value": 139.809,
        "_internal_originalTime": 1689762957000
    },
    {
        "time": 1689762958000,
        "value": 139.811,
        "_internal_originalTime": 1689762958000
    },
    {
        "time": 1689762960000,
        "value": 139.807,
        "_internal_originalTime": 1689762960000
    },
    {
        "time": 1689762961000,
        "value": 139.79,
        "_internal_originalTime": 1689762961000
    },
    {
        "time": 1689762962000,
        "value": 139.8,
        "_internal_originalTime": 1689762962000
    },
    {
        "time": 1689762963000,
        "value": 139.801,
        "_internal_originalTime": 1689762963000
    },
    {
        "time": 1689762964000,
        "value": 139.811,
        "_internal_originalTime": 1689762964000
    },
    {
        "time": 1689762965000,
        "value": 139.81,
        "_internal_originalTime": 1689762965000
    },
    {
        "time": 1689762967000,
        "value": 139.808,
        "_internal_originalTime": 1689762967000
    },
    {
        "time": 1689762968000,
        "value": 139.811,
        "_internal_originalTime": 1689762968000
    },
    {
        "time": 1689762970000,
        "value": 139.811,
        "_internal_originalTime": 1689762970000
    },
    {
        "time": 1689762971000,
        "value": 139.809,
        "_internal_originalTime": 1689762971000
    },
    {
        "time": 1689762972000,
        "value": 139.809,
        "_internal_originalTime": 1689762972000
    },
    {
        "time": 1689762974000,
        "value": 139.809,
        "_internal_originalTime": 1689762974000
    },
    {
        "time": 1689762975000,
        "value": 139.809,
        "_internal_originalTime": 1689762975000
    },
    {
        "time": 1689762976000,
        "value": 139.809,
        "_internal_originalTime": 1689762976000
    },
    {
        "time": 1689762977000,
        "value": 139.808,
        "_internal_originalTime": 1689762977000
    },
    {
        "time": 1689762979000,
        "value": 139.811,
        "_internal_originalTime": 1689762979000
    },
    {
        "time": 1689762981000,
        "value": 139.809,
        "_internal_originalTime": 1689762981000
    },
    {
        "time": 1689762983000,
        "value": 139.811,
        "_internal_originalTime": 1689762983000
    },
    {
        "time": 1689762984000,
        "value": 139.81,
        "_internal_originalTime": 1689762984000
    },
    {
        "time": 1689762986000,
        "value": 139.81,
        "_internal_originalTime": 1689762986000
    },
    {
        "time": 1689762987000,
        "value": 139.809,
        "_internal_originalTime": 1689762987000
    },
    {
        "time": 1689762989000,
        "value": 139.809,
        "_internal_originalTime": 1689762989000
    },
    {
        "time": 1689762990000,
        "value": 139.809,
        "_internal_originalTime": 1689762990000
    },
    {
        "time": 1689762991000,
        "value": 139.809,
        "_internal_originalTime": 1689762991000
    },
    {
        "time": 1689762992000,
        "value": 139.808,
        "_internal_originalTime": 1689762992000
    },
    {
        "time": 1689762993000,
        "value": 139.809,
        "_internal_originalTime": 1689762993000
    },
    {
        "time": 1689762994000,
        "value": 139.808,
        "_internal_originalTime": 1689762994000
    },
    {
        "time": 1689762996000,
        "value": 139.81,
        "_internal_originalTime": 1689762996000
    },
    {
        "time": 1689762997000,
        "value": 139.827,
        "_internal_originalTime": 1689762997000
    },
    {
        "time": 1689762999000,
        "value": 139.831,
        "_internal_originalTime": 1689762999000
    },
    {
        "time": 1689763000000,
        "value": 139.831,
        "_internal_originalTime": 1689763000000
    },
    {
        "time": 1689763001000,
        "value": 139.829,
        "_internal_originalTime": 1689763001000
    },
    {
        "time": 1689763003000,
        "value": 139.829,
        "_internal_originalTime": 1689763003000
    },
    {
        "time": 1689763004000,
        "value": 139.828,
        "_internal_originalTime": 1689763004000
    },
    {
        "time": 1689763005000,
        "value": 139.829,
        "_internal_originalTime": 1689763005000
    },
    {
        "time": 1689763006000,
        "value": 139.83,
        "_internal_originalTime": 1689763006000
    },
    {
        "time": 1689763007000,
        "value": 139.833,
        "_internal_originalTime": 1689763007000
    },
    {
        "time": 1689763008000,
        "value": 139.831,
        "_internal_originalTime": 1689763008000
    },
    {
        "time": 1689763009000,
        "value": 139.829,
        "_internal_originalTime": 1689763009000
    },
    {
        "time": 1689763011000,
        "value": 139.829,
        "_internal_originalTime": 1689763011000
    },
    {
        "time": 1689763012000,
        "value": 139.829,
        "_internal_originalTime": 1689763012000
    },
    {
        "time": 1689763014000,
        "value": 139.828,
        "_internal_originalTime": 1689763014000
    },
    {
        "time": 1689763015000,
        "value": 139.827,
        "_internal_originalTime": 1689763015000
    },
    {
        "time": 1689763016000,
        "value": 139.831,
        "_internal_originalTime": 1689763016000
    },
    {
        "time": 1689763018000,
        "value": 139.829,
        "_internal_originalTime": 1689763018000
    },
    {
        "time": 1689763019000,
        "value": 139.83,
        "_internal_originalTime": 1689763019000
    },
    {
        "time": 1689763020000,
        "value": 139.832,
        "_internal_originalTime": 1689763020000
    },
    {
        "time": 1689763021000,
        "value": 139.81,
        "_internal_originalTime": 1689763021000
    },
    {
        "time": 1689763022000,
        "value": 139.83,
        "_internal_originalTime": 1689763022000
    },
    {
        "time": 1689763023000,
        "value": 139.831,
        "_internal_originalTime": 1689763023000
    },
    {
        "time": 1689763025000,
        "value": 139.825,
        "_internal_originalTime": 1689763025000
    },
    {
        "time": 1689763027000,
        "value": 139.812,
        "_internal_originalTime": 1689763027000
    },
    {
        "time": 1689763028000,
        "value": 139.81,
        "_internal_originalTime": 1689763028000
    },
    {
        "time": 1689763030000,
        "value": 139.809,
        "_internal_originalTime": 1689763030000
    },
    {
        "time": 1689763031000,
        "value": 139.82,
        "_internal_originalTime": 1689763031000
    },
    {
        "time": 1689763033000,
        "value": 139.819,
        "_internal_originalTime": 1689763033000
    },
    {
        "time": 1689763034000,
        "value": 139.82,
        "_internal_originalTime": 1689763034000
    },
    {
        "time": 1689763035000,
        "value": 139.817,
        "_internal_originalTime": 1689763035000
    },
    {
        "time": 1689763036000,
        "value": 139.82,
        "_internal_originalTime": 1689763036000
    },
    {
        "time": 1689763041000,
        "value": 139.821,
        "_internal_originalTime": 1689763041000
    },
    {
        "time": 1689763043000,
        "value": 139.819,
        "_internal_originalTime": 1689763043000
    },
    {
        "time": 1689763044000,
        "value": 139.82,
        "_internal_originalTime": 1689763044000
    },
    {
        "time": 1689763046000,
        "value": 139.821,
        "_internal_originalTime": 1689763046000
    },
    {
        "time": 1689763047000,
        "value": 139.82,
        "_internal_originalTime": 1689763047000
    },
    {
        "time": 1689763048000,
        "value": 139.82,
        "_internal_originalTime": 1689763048000
    },
    {
        "time": 1689763052000,
        "value": 139.822,
        "_internal_originalTime": 1689763052000
    },
    {
        "time": 1689763053000,
        "value": 139.817,
        "_internal_originalTime": 1689763053000
    },
    {
        "time": 1689763055000,
        "value": 139.819,
        "_internal_originalTime": 1689763055000
    },
    {
        "time": 1689763056000,
        "value": 139.813,
        "_internal_originalTime": 1689763056000
    },
    {
        "time": 1689763057000,
        "value": 139.811,
        "_internal_originalTime": 1689763057000
    },
    {
        "time": 1689763059000,
        "value": 139.811,
        "_internal_originalTime": 1689763059000
    },
    {
        "time": 1689763060000,
        "value": 139.811,
        "_internal_originalTime": 1689763060000
    },
    {
        "time": 1689763062000,
        "value": 139.817,
        "_internal_originalTime": 1689763062000
    },
    {
        "time": 1689763063000,
        "value": 139.819,
        "_internal_originalTime": 1689763063000
    },
    {
        "time": 1689763064000,
        "value": 139.814,
        "_internal_originalTime": 1689763064000
    },
    {
        "time": 1689763065000,
        "value": 139.816,
        "_internal_originalTime": 1689763065000
    },
    {
        "time": 1689763067000,
        "value": 139.809,
        "_internal_originalTime": 1689763067000
    },
    {
        "time": 1689763068000,
        "value": 139.815,
        "_internal_originalTime": 1689763068000
    },
    {
        "time": 1689763070000,
        "value": 139.817,
        "_internal_originalTime": 1689763070000
    },
    {
        "time": 1689763072000,
        "value": 139.815,
        "_internal_originalTime": 1689763072000
    },
    {
        "time": 1689763074000,
        "value": 139.808,
        "_internal_originalTime": 1689763074000
    },
    {
        "time": 1689763075000,
        "value": 139.811,
        "_internal_originalTime": 1689763075000
    },
    {
        "time": 1689763076000,
        "value": 139.812,
        "_internal_originalTime": 1689763076000
    },
    {
        "time": 1689763078000,
        "value": 139.811,
        "_internal_originalTime": 1689763078000
    },
    {
        "time": 1689763079000,
        "value": 139.811,
        "_internal_originalTime": 1689763079000
    },
    {
        "time": 1689763080000,
        "value": 139.808,
        "_internal_originalTime": 1689763080000
    },
    {
        "time": 1689763082000,
        "value": 139.809,
        "_internal_originalTime": 1689763082000
    },
    {
        "time": 1689763083000,
        "value": 139.795,
        "_internal_originalTime": 1689763083000
    },
    {
        "time": 1689763084000,
        "value": 139.794,
        "_internal_originalTime": 1689763084000
    },
    {
        "time": 1689763085000,
        "value": 139.794,
        "_internal_originalTime": 1689763085000
    },
    {
        "time": 1689763087000,
        "value": 139.797,
        "_internal_originalTime": 1689763087000
    },
    {
        "time": 1689763088000,
        "value": 139.799,
        "_internal_originalTime": 1689763088000
    },
    {
        "time": 1689763089000,
        "value": 139.8,
        "_internal_originalTime": 1689763089000
    },
    {
        "time": 1689763091000,
        "value": 139.8,
        "_internal_originalTime": 1689763091000
    },
    {
        "time": 1689763093000,
        "value": 139.799,
        "_internal_originalTime": 1689763093000
    },
    {
        "time": 1689763094000,
        "value": 139.799,
        "_internal_originalTime": 1689763094000
    },
    {
        "time": 1689763095000,
        "value": 139.801,
        "_internal_originalTime": 1689763095000
    },
    {
        "time": 1689763096000,
        "value": 139.8,
        "_internal_originalTime": 1689763096000
    },
    {
        "time": 1689763097000,
        "value": 139.793,
        "_internal_originalTime": 1689763097000
    },
    {
        "time": 1689763099000,
        "value": 139.799,
        "_internal_originalTime": 1689763099000
    },
    {
        "time": 1689763100000,
        "value": 139.802,
        "_internal_originalTime": 1689763100000
    },
    {
        "time": 1689763102000,
        "value": 139.798,
        "_internal_originalTime": 1689763102000
    },
    {
        "time": 1689763104000,
        "value": 139.801,
        "_internal_originalTime": 1689763104000
    },
    {
        "time": 1689763106000,
        "value": 139.801,
        "_internal_originalTime": 1689763106000
    },
    {
        "time": 1689763108000,
        "value": 139.801,
        "_internal_originalTime": 1689763108000
    },
    {
        "time": 1689763110000,
        "value": 139.8,
        "_internal_originalTime": 1689763110000
    },
    {
        "time": 1689763111000,
        "value": 139.795,
        "_internal_originalTime": 1689763111000
    },
    {
        "time": 1689763112000,
        "value": 139.794,
        "_internal_originalTime": 1689763112000
    },
    {
        "time": 1689763113000,
        "value": 139.794,
        "_internal_originalTime": 1689763113000
    },
    {
        "time": 1689763114000,
        "value": 139.796,
        "_internal_originalTime": 1689763114000
    },
    {
        "time": 1689763116000,
        "value": 139.794,
        "_internal_originalTime": 1689763116000
    },
    {
        "time": 1689763117000,
        "value": 139.801,
        "_internal_originalTime": 1689763117000
    },
    {
        "time": 1689763119000,
        "value": 139.801,
        "_internal_originalTime": 1689763119000
    },
    {
        "time": 1689763120000,
        "value": 139.8,
        "_internal_originalTime": 1689763120000
    },
    {
        "time": 1689763121000,
        "value": 139.798,
        "_internal_originalTime": 1689763121000
    },
    {
        "time": 1689763123000,
        "value": 139.796,
        "_internal_originalTime": 1689763123000
    },
    {
        "time": 1689763124000,
        "value": 139.789,
        "_internal_originalTime": 1689763124000
    },
    {
        "time": 1689763125000,
        "value": 139.79,
        "_internal_originalTime": 1689763125000
    },
    {
        "time": 1689763126000,
        "value": 139.791,
        "_internal_originalTime": 1689763126000
    },
    {
        "time": 1689763127000,
        "value": 139.79,
        "_internal_originalTime": 1689763127000
    },
    {
        "time": 1689763130000,
        "value": 139.778,
        "_internal_originalTime": 1689763130000
    },
    {
        "time": 1689763131000,
        "value": 139.781,
        "_internal_originalTime": 1689763131000
    },
    {
        "time": 1689763132000,
        "value": 139.78,
        "_internal_originalTime": 1689763132000
    },
    {
        "time": 1689763133000,
        "value": 139.782,
        "_internal_originalTime": 1689763133000
    },
    {
        "time": 1689763136000,
        "value": 139.778,
        "_internal_originalTime": 1689763136000
    },
    {
        "time": 1689763138000,
        "value": 139.782,
        "_internal_originalTime": 1689763138000
    },
    {
        "time": 1689763139000,
        "value": 139.781,
        "_internal_originalTime": 1689763139000
    },
    {
        "time": 1689763140000,
        "value": 139.782,
        "_internal_originalTime": 1689763140000
    },
    {
        "time": 1689763141000,
        "value": 139.76,
        "_internal_originalTime": 1689763141000
    },
    {
        "time": 1689763142000,
        "value": 139.782,
        "_internal_originalTime": 1689763142000
    },
    {
        "time": 1689763143000,
        "value": 139.779,
        "_internal_originalTime": 1689763143000
    },
    {
        "time": 1689763145000,
        "value": 139.783,
        "_internal_originalTime": 1689763145000
    },
    {
        "time": 1689763146000,
        "value": 139.778,
        "_internal_originalTime": 1689763146000
    },
    {
        "time": 1689763148000,
        "value": 139.781,
        "_internal_originalTime": 1689763148000
    },
    {
        "time": 1689763150000,
        "value": 139.781,
        "_internal_originalTime": 1689763150000
    },
    {
        "time": 1689763152000,
        "value": 139.779,
        "_internal_originalTime": 1689763152000
    },
    {
        "time": 1689763153000,
        "value": 139.78,
        "_internal_originalTime": 1689763153000
    },
    {
        "time": 1689763156000,
        "value": 139.779,
        "_internal_originalTime": 1689763156000
    },
    {
        "time": 1689763158000,
        "value": 139.79,
        "_internal_originalTime": 1689763158000
    },
    {
        "time": 1689763159000,
        "value": 139.788,
        "_internal_originalTime": 1689763159000
    },
    {
        "time": 1689763160000,
        "value": 139.79,
        "_internal_originalTime": 1689763160000
    },
    {
        "time": 1689763161000,
        "value": 139.787,
        "_internal_originalTime": 1689763161000
    },
    {
        "time": 1689763163000,
        "value": 139.79,
        "_internal_originalTime": 1689763163000
    },
    {
        "time": 1689763165000,
        "value": 139.792,
        "_internal_originalTime": 1689763165000
    },
    {
        "time": 1689763166000,
        "value": 139.791,
        "_internal_originalTime": 1689763166000
    },
    {
        "time": 1689763169000,
        "value": 139.788,
        "_internal_originalTime": 1689763169000
    },
    {
        "time": 1689763171000,
        "value": 139.791,
        "_internal_originalTime": 1689763171000
    },
    {
        "time": 1689763172000,
        "value": 139.79,
        "_internal_originalTime": 1689763172000
    },
    {
        "time": 1689763173000,
        "value": 139.788,
        "_internal_originalTime": 1689763173000
    },
    {
        "time": 1689763175000,
        "value": 139.788,
        "_internal_originalTime": 1689763175000
    },
    {
        "time": 1689763176000,
        "value": 139.786,
        "_internal_originalTime": 1689763176000
    },
    {
        "time": 1689763178000,
        "value": 139.788,
        "_internal_originalTime": 1689763178000
    },
    {
        "time": 1689763179000,
        "value": 139.791,
        "_internal_originalTime": 1689763179000
    },
    {
        "time": 1689763182000,
        "value": 139.791,
        "_internal_originalTime": 1689763182000
    },
    {
        "time": 1689763184000,
        "value": 139.8,
        "_internal_originalTime": 1689763184000
    },
    {
        "time": 1689763185000,
        "value": 139.802,
        "_internal_originalTime": 1689763185000
    },
    {
        "time": 1689763186000,
        "value": 139.799,
        "_internal_originalTime": 1689763186000
    },
    {
        "time": 1689763187000,
        "value": 139.8,
        "_internal_originalTime": 1689763187000
    },
    {
        "time": 1689763188000,
        "value": 139.799,
        "_internal_originalTime": 1689763188000
    },
    {
        "time": 1689763190000,
        "value": 139.799,
        "_internal_originalTime": 1689763190000
    },
    {
        "time": 1689763191000,
        "value": 139.802,
        "_internal_originalTime": 1689763191000
    },
    {
        "time": 1689763192000,
        "value": 139.802,
        "_internal_originalTime": 1689763192000
    },
    {
        "time": 1689763194000,
        "value": 139.799,
        "_internal_originalTime": 1689763194000
    },
    {
        "time": 1689763195000,
        "value": 139.802,
        "_internal_originalTime": 1689763195000
    },
    {
        "time": 1689763196000,
        "value": 139.811,
        "_internal_originalTime": 1689763196000
    },
    {
        "time": 1689763198000,
        "value": 139.811,
        "_internal_originalTime": 1689763198000
    },
    {
        "time": 1689763200000,
        "value": 139.78,
        "_internal_originalTime": 1689763200000
    },
    {
        "time": 1689763201000,
        "value": 139.817,
        "_internal_originalTime": 1689763201000
    },
    {
        "time": 1689763203000,
        "value": 139.821,
        "_internal_originalTime": 1689763203000
    },
    {
        "time": 1689763204000,
        "value": 139.819,
        "_internal_originalTime": 1689763204000
    },
    {
        "time": 1689763205000,
        "value": 139.82,
        "_internal_originalTime": 1689763205000
    },
    {
        "time": 1689763207000,
        "value": 139.819,
        "_internal_originalTime": 1689763207000
    },
    {
        "time": 1689763208000,
        "value": 139.817,
        "_internal_originalTime": 1689763208000
    },
    {
        "time": 1689763209000,
        "value": 139.818,
        "_internal_originalTime": 1689763209000
    },
    {
        "time": 1689763210000,
        "value": 139.821,
        "_internal_originalTime": 1689763210000
    },
    {
        "time": 1689763212000,
        "value": 139.822,
        "_internal_originalTime": 1689763212000
    },
    {
        "time": 1689763213000,
        "value": 139.819,
        "_internal_originalTime": 1689763213000
    },
    {
        "time": 1689763215000,
        "value": 139.819,
        "_internal_originalTime": 1689763215000
    },
    {
        "time": 1689763217000,
        "value": 139.818,
        "_internal_originalTime": 1689763217000
    },
    {
        "time": 1689763218000,
        "value": 139.818,
        "_internal_originalTime": 1689763218000
    },
    {
        "time": 1689763220000,
        "value": 139.822,
        "_internal_originalTime": 1689763220000
    },
    {
        "time": 1689763221000,
        "value": 139.819,
        "_internal_originalTime": 1689763221000
    },
    {
        "time": 1689763222000,
        "value": 139.82,
        "_internal_originalTime": 1689763222000
    },
    {
        "time": 1689763224000,
        "value": 139.831,
        "_internal_originalTime": 1689763224000
    },
    {
        "time": 1689763225000,
        "value": 139.837,
        "_internal_originalTime": 1689763225000
    },
    {
        "time": 1689763226000,
        "value": 139.838,
        "_internal_originalTime": 1689763226000
    },
    {
        "time": 1689763228000,
        "value": 139.836,
        "_internal_originalTime": 1689763228000
    },
    {
        "time": 1689763229000,
        "value": 139.836,
        "_internal_originalTime": 1689763229000
    },
    {
        "time": 1689763230000,
        "value": 139.837,
        "_internal_originalTime": 1689763230000
    },
    {
        "time": 1689763231000,
        "value": 139.838,
        "_internal_originalTime": 1689763231000
    },
    {
        "time": 1689763232000,
        "value": 139.837,
        "_internal_originalTime": 1689763232000
    },
    {
        "time": 1689763234000,
        "value": 139.84,
        "_internal_originalTime": 1689763234000
    },
    {
        "time": 1689763235000,
        "value": 139.839,
        "_internal_originalTime": 1689763235000
    },
    {
        "time": 1689763236000,
        "value": 139.838,
        "_internal_originalTime": 1689763236000
    },
    {
        "time": 1689763238000,
        "value": 139.842,
        "_internal_originalTime": 1689763238000
    },
    {
        "time": 1689763239000,
        "value": 139.846,
        "_internal_originalTime": 1689763239000
    },
    {
        "time": 1689763241000,
        "value": 139.846,
        "_internal_originalTime": 1689763241000
    },
    {
        "time": 1689763242000,
        "value": 139.847,
        "_internal_originalTime": 1689763242000
    },
    {
        "time": 1689763245000,
        "value": 139.848,
        "_internal_originalTime": 1689763245000
    },
    {
        "time": 1689763246000,
        "value": 139.848,
        "_internal_originalTime": 1689763246000
    },
    {
        "time": 1689763247000,
        "value": 139.848,
        "_internal_originalTime": 1689763247000
    },
    {
        "time": 1689763249000,
        "value": 139.849,
        "_internal_originalTime": 1689763249000
    },
    {
        "time": 1689763251000,
        "value": 139.851,
        "_internal_originalTime": 1689763251000
    },
    {
        "time": 1689763252000,
        "value": 139.851,
        "_internal_originalTime": 1689763252000
    },
    {
        "time": 1689763253000,
        "value": 139.85,
        "_internal_originalTime": 1689763253000
    },
    {
        "time": 1689763255000,
        "value": 139.852,
        "_internal_originalTime": 1689763255000
    },
    {
        "time": 1689763257000,
        "value": 139.852,
        "_internal_originalTime": 1689763257000
    },
    {
        "time": 1689763260000,
        "value": 139.848,
        "_internal_originalTime": 1689763260000
    },
    {
        "time": 1689763262000,
        "value": 139.843,
        "_internal_originalTime": 1689763262000
    },
    {
        "time": 1689763263000,
        "value": 139.847,
        "_internal_originalTime": 1689763263000
    },
    {
        "time": 1689763265000,
        "value": 139.846,
        "_internal_originalTime": 1689763265000
    },
    {
        "time": 1689763266000,
        "value": 139.844,
        "_internal_originalTime": 1689763266000
    },
    {
        "time": 1689763267000,
        "value": 139.844,
        "_internal_originalTime": 1689763267000
    },
    {
        "time": 1689763269000,
        "value": 139.846,
        "_internal_originalTime": 1689763269000
    },
    {
        "time": 1689763270000,
        "value": 139.848,
        "_internal_originalTime": 1689763270000
    },
    {
        "time": 1689763272000,
        "value": 139.844,
        "_internal_originalTime": 1689763272000
    },
    {
        "time": 1689763273000,
        "value": 139.847,
        "_internal_originalTime": 1689763273000
    },
    {
        "time": 1689763274000,
        "value": 139.849,
        "_internal_originalTime": 1689763274000
    },
    {
        "time": 1689763275000,
        "value": 139.846,
        "_internal_originalTime": 1689763275000
    },
    {
        "time": 1689763276000,
        "value": 139.848,
        "_internal_originalTime": 1689763276000
    },
    {
        "time": 1689763278000,
        "value": 139.851,
        "_internal_originalTime": 1689763278000
    },
    {
        "time": 1689763280000,
        "value": 139.849,
        "_internal_originalTime": 1689763280000
    },
    {
        "time": 1689763281000,
        "value": 139.854,
        "_internal_originalTime": 1689763281000
    },
    {
        "time": 1689763282000,
        "value": 139.857,
        "_internal_originalTime": 1689763282000
    },
    {
        "time": 1689763284000,
        "value": 139.858,
        "_internal_originalTime": 1689763284000
    },
    {
        "time": 1689763285000,
        "value": 139.857,
        "_internal_originalTime": 1689763285000
    },
    {
        "time": 1689763287000,
        "value": 139.855,
        "_internal_originalTime": 1689763287000
    },
    {
        "time": 1689763288000,
        "value": 139.856,
        "_internal_originalTime": 1689763288000
    },
    {
        "time": 1689763289000,
        "value": 139.852,
        "_internal_originalTime": 1689763289000
    },
    {
        "time": 1689763290000,
        "value": 139.854,
        "_internal_originalTime": 1689763290000
    },
    {
        "time": 1689763291000,
        "value": 139.855,
        "_internal_originalTime": 1689763291000
    },
    {
        "time": 1689763292000,
        "value": 139.857,
        "_internal_originalTime": 1689763292000
    },
    {
        "time": 1689763294000,
        "value": 139.854,
        "_internal_originalTime": 1689763294000
    },
    {
        "time": 1689763296000,
        "value": 139.856,
        "_internal_originalTime": 1689763296000
    },
    {
        "time": 1689763297000,
        "value": 139.857,
        "_internal_originalTime": 1689763297000
    },
    {
        "time": 1689763298000,
        "value": 139.857,
        "_internal_originalTime": 1689763298000
    },
    {
        "time": 1689763300000,
        "value": 139.86,
        "_internal_originalTime": 1689763300000
    },
    {
        "time": 1689763302000,
        "value": 139.858,
        "_internal_originalTime": 1689763302000
    },
    {
        "time": 1689763305000,
        "value": 139.858,
        "_internal_originalTime": 1689763305000
    },
    {
        "time": 1689763306000,
        "value": 139.859,
        "_internal_originalTime": 1689763306000
    },
    {
        "time": 1689763307000,
        "value": 139.857,
        "_internal_originalTime": 1689763307000
    },
    {
        "time": 1689763308000,
        "value": 139.857,
        "_internal_originalTime": 1689763308000
    },
    {
        "time": 1689763310000,
        "value": 139.858,
        "_internal_originalTime": 1689763310000
    },
    {
        "time": 1689763315000,
        "value": 139.858,
        "_internal_originalTime": 1689763315000
    },
    {
        "time": 1689763316000,
        "value": 139.86,
        "_internal_originalTime": 1689763316000
    },
    {
        "time": 1689763317000,
        "value": 139.859,
        "_internal_originalTime": 1689763317000
    },
    {
        "time": 1689763318000,
        "value": 139.857,
        "_internal_originalTime": 1689763318000
    },
    {
        "time": 1689763321000,
        "value": 139.858,
        "_internal_originalTime": 1689763321000
    },
    {
        "time": 1689763322000,
        "value": 139.853,
        "_internal_originalTime": 1689763322000
    },
    {
        "time": 1689763323000,
        "value": 139.852,
        "_internal_originalTime": 1689763323000
    },
    {
        "time": 1689763325000,
        "value": 139.853,
        "_internal_originalTime": 1689763325000
    },
    {
        "time": 1689763326000,
        "value": 139.844,
        "_internal_originalTime": 1689763326000
    },
    {
        "time": 1689763327000,
        "value": 139.851,
        "_internal_originalTime": 1689763327000
    },
    {
        "time": 1689763329000,
        "value": 139.847,
        "_internal_originalTime": 1689763329000
    },
    {
        "time": 1689763331000,
        "value": 139.847,
        "_internal_originalTime": 1689763331000
    },
    {
        "time": 1689763332000,
        "value": 139.843,
        "_internal_originalTime": 1689763332000
    },
    {
        "time": 1689763333000,
        "value": 139.844,
        "_internal_originalTime": 1689763333000
    },
    {
        "time": 1689763334000,
        "value": 139.844,
        "_internal_originalTime": 1689763334000
    },
    {
        "time": 1689763335000,
        "value": 139.841,
        "_internal_originalTime": 1689763335000
    },
    {
        "time": 1689763337000,
        "value": 139.84,
        "_internal_originalTime": 1689763337000
    },
    {
        "time": 1689763338000,
        "value": 139.838,
        "_internal_originalTime": 1689763338000
    },
    {
        "time": 1689763339000,
        "value": 139.836,
        "_internal_originalTime": 1689763339000
    },
    {
        "time": 1689763340000,
        "value": 139.838,
        "_internal_originalTime": 1689763340000
    },
    {
        "time": 1689763342000,
        "value": 139.841,
        "_internal_originalTime": 1689763342000
    },
    {
        "time": 1689763345000,
        "value": 139.837,
        "_internal_originalTime": 1689763345000
    },
    {
        "time": 1689763347000,
        "value": 139.835,
        "_internal_originalTime": 1689763347000
    },
    {
        "time": 1689763348000,
        "value": 139.841,
        "_internal_originalTime": 1689763348000
    },
    {
        "time": 1689763350000,
        "value": 139.84,
        "_internal_originalTime": 1689763350000
    },
    {
        "time": 1689763351000,
        "value": 139.839,
        "_internal_originalTime": 1689763351000
    },
    {
        "time": 1689763352000,
        "value": 139.84,
        "_internal_originalTime": 1689763352000
    },
    {
        "time": 1689763354000,
        "value": 139.834,
        "_internal_originalTime": 1689763354000
    },
    {
        "time": 1689763355000,
        "value": 139.838,
        "_internal_originalTime": 1689763355000
    },
    {
        "time": 1689763357000,
        "value": 139.836,
        "_internal_originalTime": 1689763357000
    },
    {
        "time": 1689763358000,
        "value": 139.836,
        "_internal_originalTime": 1689763358000
    },
    {
        "time": 1689763359000,
        "value": 139.834,
        "_internal_originalTime": 1689763359000
    },
    {
        "time": 1689763361000,
        "value": 139.834,
        "_internal_originalTime": 1689763361000
    },
    {
        "time": 1689763362000,
        "value": 139.837,
        "_internal_originalTime": 1689763362000
    },
    {
        "time": 1689763365000,
        "value": 139.84,
        "_internal_originalTime": 1689763365000
    },
    {
        "time": 1689763367000,
        "value": 139.837,
        "_internal_originalTime": 1689763367000
    },
    {
        "time": 1689763369000,
        "value": 139.838,
        "_internal_originalTime": 1689763369000
    },
    {
        "time": 1689763370000,
        "value": 139.839,
        "_internal_originalTime": 1689763370000
    },
    {
        "time": 1689763372000,
        "value": 139.836,
        "_internal_originalTime": 1689763372000
    },
    {
        "time": 1689763373000,
        "value": 139.841,
        "_internal_originalTime": 1689763373000
    },
    {
        "time": 1689763375000,
        "value": 139.843,
        "_internal_originalTime": 1689763375000
    },
    {
        "time": 1689763376000,
        "value": 139.842,
        "_internal_originalTime": 1689763376000
    },
    {
        "time": 1689763378000,
        "value": 139.841,
        "_internal_originalTime": 1689763378000
    },
    {
        "time": 1689763379000,
        "value": 139.84,
        "_internal_originalTime": 1689763379000
    },
    {
        "time": 1689763380000,
        "value": 139.839,
        "_internal_originalTime": 1689763380000
    },
    {
        "time": 1689763382000,
        "value": 139.848,
        "_internal_originalTime": 1689763382000
    },
    {
        "time": 1689763383000,
        "value": 139.847,
        "_internal_originalTime": 1689763383000
    },
    {
        "time": 1689763384000,
        "value": 139.846,
        "_internal_originalTime": 1689763384000
    },
    {
        "time": 1689763387000,
        "value": 139.847,
        "_internal_originalTime": 1689763387000
    },
    {
        "time": 1689763388000,
        "value": 139.848,
        "_internal_originalTime": 1689763388000
    },
    {
        "time": 1689763390000,
        "value": 139.85,
        "_internal_originalTime": 1689763390000
    },
    {
        "time": 1689763392000,
        "value": 139.844,
        "_internal_originalTime": 1689763392000
    },
    {
        "time": 1689763393000,
        "value": 139.846,
        "_internal_originalTime": 1689763393000
    },
    {
        "time": 1689763395000,
        "value": 139.848,
        "_internal_originalTime": 1689763395000
    },
    {
        "time": 1689763396000,
        "value": 139.848,
        "_internal_originalTime": 1689763396000
    },
    {
        "time": 1689763398000,
        "value": 139.857,
        "_internal_originalTime": 1689763398000
    },
    {
        "time": 1689763399000,
        "value": 139.858,
        "_internal_originalTime": 1689763399000
    },
    {
        "time": 1689763400000,
        "value": 139.858,
        "_internal_originalTime": 1689763400000
    },
    {
        "time": 1689763402000,
        "value": 139.859,
        "_internal_originalTime": 1689763402000
    },
    {
        "time": 1689763404000,
        "value": 139.86,
        "_internal_originalTime": 1689763404000
    },
    {
        "time": 1689763405000,
        "value": 139.857,
        "_internal_originalTime": 1689763405000
    },
    {
        "time": 1689763407000,
        "value": 139.856,
        "_internal_originalTime": 1689763407000
    },
    {
        "time": 1689763408000,
        "value": 139.856,
        "_internal_originalTime": 1689763408000
    },
    {
        "time": 1689763409000,
        "value": 139.858,
        "_internal_originalTime": 1689763409000
    },
    {
        "time": 1689763410000,
        "value": 139.854,
        "_internal_originalTime": 1689763410000
    },
    {
        "time": 1689763412000,
        "value": 139.854,
        "_internal_originalTime": 1689763412000
    },
    {
        "time": 1689763414000,
        "value": 139.858,
        "_internal_originalTime": 1689763414000
    },
    {
        "time": 1689763415000,
        "value": 139.858,
        "_internal_originalTime": 1689763415000
    },
    {
        "time": 1689763416000,
        "value": 139.853,
        "_internal_originalTime": 1689763416000
    },
    {
        "time": 1689763418000,
        "value": 139.855,
        "_internal_originalTime": 1689763418000
    },
    {
        "time": 1689763420000,
        "value": 139.857,
        "_internal_originalTime": 1689763420000
    },
    {
        "time": 1689763421000,
        "value": 139.861,
        "_internal_originalTime": 1689763421000
    },
    {
        "time": 1689763423000,
        "value": 139.862,
        "_internal_originalTime": 1689763423000
    },
    {
        "time": 1689763424000,
        "value": 139.862,
        "_internal_originalTime": 1689763424000
    },
    {
        "time": 1689763425000,
        "value": 139.867,
        "_internal_originalTime": 1689763425000
    },
    {
        "time": 1689763427000,
        "value": 139.864,
        "_internal_originalTime": 1689763427000
    },
    {
        "time": 1689763428000,
        "value": 139.862,
        "_internal_originalTime": 1689763428000
    },
    {
        "time": 1689763429000,
        "value": 139.868,
        "_internal_originalTime": 1689763429000
    },
    {
        "time": 1689763431000,
        "value": 139.867,
        "_internal_originalTime": 1689763431000
    },
    {
        "time": 1689763432000,
        "value": 139.863,
        "_internal_originalTime": 1689763432000
    },
    {
        "time": 1689763433000,
        "value": 139.868,
        "_internal_originalTime": 1689763433000
    },
    {
        "time": 1689763434000,
        "value": 139.866,
        "_internal_originalTime": 1689763434000
    },
    {
        "time": 1689763436000,
        "value": 139.868,
        "_internal_originalTime": 1689763436000
    },
    {
        "time": 1689763437000,
        "value": 139.867,
        "_internal_originalTime": 1689763437000
    },
    {
        "time": 1689763439000,
        "value": 139.869,
        "_internal_originalTime": 1689763439000
    },
    {
        "time": 1689763440000,
        "value": 139.869,
        "_internal_originalTime": 1689763440000
    },
    {
        "time": 1689763441000,
        "value": 139.866,
        "_internal_originalTime": 1689763441000
    },
    {
        "time": 1689763443000,
        "value": 139.864,
        "_internal_originalTime": 1689763443000
    },
    {
        "time": 1689763445000,
        "value": 139.865,
        "_internal_originalTime": 1689763445000
    },
    {
        "time": 1689763446000,
        "value": 139.86,
        "_internal_originalTime": 1689763446000
    },
    {
        "time": 1689763448000,
        "value": 139.87,
        "_internal_originalTime": 1689763448000
    },
    {
        "time": 1689763449000,
        "value": 139.868,
        "_internal_originalTime": 1689763449000
    },
    {
        "time": 1689763452000,
        "value": 139.871,
        "_internal_originalTime": 1689763452000
    },
    {
        "time": 1689763454000,
        "value": 139.864,
        "_internal_originalTime": 1689763454000
    },
    {
        "time": 1689763455000,
        "value": 139.867,
        "_internal_originalTime": 1689763455000
    },
    {
        "time": 1689763456000,
        "value": 139.865,
        "_internal_originalTime": 1689763456000
    },
    {
        "time": 1689763458000,
        "value": 139.863,
        "_internal_originalTime": 1689763458000
    },
    {
        "time": 1689763460000,
        "value": 139.866,
        "_internal_originalTime": 1689763460000
    },
    {
        "time": 1689763461000,
        "value": 139.868,
        "_internal_originalTime": 1689763461000
    },
    {
        "time": 1689763462000,
        "value": 139.866,
        "_internal_originalTime": 1689763462000
    },
    {
        "time": 1689763464000,
        "value": 139.866,
        "_internal_originalTime": 1689763464000
    },
    {
        "time": 1689763465000,
        "value": 139.866,
        "_internal_originalTime": 1689763465000
    },
    {
        "time": 1689763467000,
        "value": 139.865,
        "_internal_originalTime": 1689763467000
    },
    {
        "time": 1689763470000,
        "value": 139.858,
        "_internal_originalTime": 1689763470000
    },
    {
        "time": 1689763471000,
        "value": 139.854,
        "_internal_originalTime": 1689763471000
    },
    {
        "time": 1689763473000,
        "value": 139.858,
        "_internal_originalTime": 1689763473000
    },
    {
        "time": 1689763474000,
        "value": 139.855,
        "_internal_originalTime": 1689763474000
    },
    {
        "time": 1689763475000,
        "value": 139.857,
        "_internal_originalTime": 1689763475000
    },
    {
        "time": 1689763476000,
        "value": 139.851,
        "_internal_originalTime": 1689763476000
    },
    {
        "time": 1689763479000,
        "value": 139.857,
        "_internal_originalTime": 1689763479000
    },
    {
        "time": 1689763481000,
        "value": 139.857,
        "_internal_originalTime": 1689763481000
    },
    {
        "time": 1689763484000,
        "value": 139.858,
        "_internal_originalTime": 1689763484000
    },
    {
        "time": 1689763486000,
        "value": 139.856,
        "_internal_originalTime": 1689763486000
    },
    {
        "time": 1689763488000,
        "value": 139.857,
        "_internal_originalTime": 1689763488000
    },
    {
        "time": 1689763489000,
        "value": 139.854,
        "_internal_originalTime": 1689763489000
    },
    {
        "time": 1689763490000,
        "value": 139.856,
        "_internal_originalTime": 1689763490000
    },
    {
        "time": 1689763492000,
        "value": 139.86,
        "_internal_originalTime": 1689763492000
    },
    {
        "time": 1689763494000,
        "value": 139.857,
        "_internal_originalTime": 1689763494000
    },
    {
        "time": 1689763495000,
        "value": 139.857,
        "_internal_originalTime": 1689763495000
    },
    {
        "time": 1689763496000,
        "value": 139.855,
        "_internal_originalTime": 1689763496000
    },
    {
        "time": 1689763499000,
        "value": 139.857,
        "_internal_originalTime": 1689763499000
    },
    {
        "time": 1689763500000,
        "value": 139.857,
        "_internal_originalTime": 1689763500000
    },
    {
        "time": 1689763502000,
        "value": 139.849,
        "_internal_originalTime": 1689763502000
    },
    {
        "time": 1689763504000,
        "value": 139.853,
        "_internal_originalTime": 1689763504000
    },
    {
        "time": 1689763505000,
        "value": 139.849,
        "_internal_originalTime": 1689763505000
    },
    {
        "time": 1689763506000,
        "value": 139.848,
        "_internal_originalTime": 1689763506000
    },
    {
        "time": 1689763507000,
        "value": 139.847,
        "_internal_originalTime": 1689763507000
    },
    {
        "time": 1689763509000,
        "value": 139.851,
        "_internal_originalTime": 1689763509000
    },
    {
        "time": 1689763510000,
        "value": 139.855,
        "_internal_originalTime": 1689763510000
    },
    {
        "time": 1689763511000,
        "value": 139.858,
        "_internal_originalTime": 1689763511000
    },
    {
        "time": 1689763513000,
        "value": 139.867,
        "_internal_originalTime": 1689763513000
    },
    {
        "time": 1689763514000,
        "value": 139.861,
        "_internal_originalTime": 1689763514000
    },
    {
        "time": 1689763515000,
        "value": 139.866,
        "_internal_originalTime": 1689763515000
    },
    {
        "time": 1689763516000,
        "value": 139.863,
        "_internal_originalTime": 1689763516000
    },
    {
        "time": 1689763517000,
        "value": 139.863,
        "_internal_originalTime": 1689763517000
    },
    {
        "time": 1689763518000,
        "value": 139.867,
        "_internal_originalTime": 1689763518000
    },
    {
        "time": 1689763519000,
        "value": 139.864,
        "_internal_originalTime": 1689763519000
    },
    {
        "time": 1689763521000,
        "value": 139.865,
        "_internal_originalTime": 1689763521000
    },
    {
        "time": 1689763524000,
        "value": 139.869,
        "_internal_originalTime": 1689763524000
    },
    {
        "time": 1689763528000,
        "value": 139.867,
        "_internal_originalTime": 1689763528000
    },
    {
        "time": 1689763530000,
        "value": 139.862,
        "_internal_originalTime": 1689763530000
    },
    {
        "time": 1689763531000,
        "value": 139.863,
        "_internal_originalTime": 1689763531000
    },
    {
        "time": 1689763532000,
        "value": 139.861,
        "_internal_originalTime": 1689763532000
    },
    {
        "time": 1689763533000,
        "value": 139.861,
        "_internal_originalTime": 1689763533000
    },
    {
        "time": 1689763534000,
        "value": 139.856,
        "_internal_originalTime": 1689763534000
    },
    {
        "time": 1689763536000,
        "value": 139.858,
        "_internal_originalTime": 1689763536000
    },
    {
        "time": 1689763537000,
        "value": 139.86,
        "_internal_originalTime": 1689763537000
    },
    {
        "time": 1689763539000,
        "value": 139.865,
        "_internal_originalTime": 1689763539000
    },
    {
        "time": 1689763540000,
        "value": 139.867,
        "_internal_originalTime": 1689763540000
    },
    {
        "time": 1689763542000,
        "value": 139.869,
        "_internal_originalTime": 1689763542000
    },
    {
        "time": 1689763543000,
        "value": 139.868,
        "_internal_originalTime": 1689763543000
    },
    {
        "time": 1689763545000,
        "value": 139.87,
        "_internal_originalTime": 1689763545000
    },
    {
        "time": 1689763546000,
        "value": 139.864,
        "_internal_originalTime": 1689763546000
    },
    {
        "time": 1689763547000,
        "value": 139.868,
        "_internal_originalTime": 1689763547000
    },
    {
        "time": 1689763548000,
        "value": 139.868,
        "_internal_originalTime": 1689763548000
    },
    {
        "time": 1689763550000,
        "value": 139.867,
        "_internal_originalTime": 1689763550000
    },
    {
        "time": 1689763552000,
        "value": 139.864,
        "_internal_originalTime": 1689763552000
    },
    {
        "time": 1689763554000,
        "value": 139.868,
        "_internal_originalTime": 1689763554000
    },
    {
        "time": 1689763556000,
        "value": 139.868,
        "_internal_originalTime": 1689763556000
    },
    {
        "time": 1689763558000,
        "value": 139.869,
        "_internal_originalTime": 1689763558000
    },
    {
        "time": 1689763559000,
        "value": 139.864,
        "_internal_originalTime": 1689763559000
    },
    {
        "time": 1689763560000,
        "value": 139.866,
        "_internal_originalTime": 1689763560000
    },
    {
        "time": 1689763562000,
        "value": 139.858,
        "_internal_originalTime": 1689763562000
    },
    {
        "time": 1689763563000,
        "value": 139.86,
        "_internal_originalTime": 1689763563000
    },
    {
        "time": 1689763564000,
        "value": 139.861,
        "_internal_originalTime": 1689763564000
    },
    {
        "time": 1689763565000,
        "value": 139.86,
        "_internal_originalTime": 1689763565000
    },
    {
        "time": 1689763567000,
        "value": 139.858,
        "_internal_originalTime": 1689763567000
    },
    {
        "time": 1689763568000,
        "value": 139.859,
        "_internal_originalTime": 1689763568000
    },
    {
        "time": 1689763569000,
        "value": 139.857,
        "_internal_originalTime": 1689763569000
    },
    {
        "time": 1689763571000,
        "value": 139.863,
        "_internal_originalTime": 1689763571000
    },
    {
        "time": 1689763572000,
        "value": 139.866,
        "_internal_originalTime": 1689763572000
    },
    {
        "time": 1689763573000,
        "value": 139.864,
        "_internal_originalTime": 1689763573000
    },
    {
        "time": 1689763575000,
        "value": 139.866,
        "_internal_originalTime": 1689763575000
    },
    {
        "time": 1689763577000,
        "value": 139.867,
        "_internal_originalTime": 1689763577000
    },
    {
        "time": 1689763578000,
        "value": 139.876,
        "_internal_originalTime": 1689763578000
    },
    {
        "time": 1689763579000,
        "value": 139.878,
        "_internal_originalTime": 1689763579000
    },
    {
        "time": 1689763581000,
        "value": 139.875,
        "_internal_originalTime": 1689763581000
    },
    {
        "time": 1689763582000,
        "value": 139.872,
        "_internal_originalTime": 1689763582000
    },
    {
        "time": 1689763583000,
        "value": 139.872,
        "_internal_originalTime": 1689763583000
    },
    {
        "time": 1689763585000,
        "value": 139.877,
        "_internal_originalTime": 1689763585000
    },
    {
        "time": 1689763588000,
        "value": 139.875,
        "_internal_originalTime": 1689763588000
    },
    {
        "time": 1689763590000,
        "value": 139.877,
        "_internal_originalTime": 1689763590000
    },
    {
        "time": 1689763591000,
        "value": 139.876,
        "_internal_originalTime": 1689763591000
    },
    {
        "time": 1689763593000,
        "value": 139.875,
        "_internal_originalTime": 1689763593000
    },
    {
        "time": 1689763594000,
        "value": 139.877,
        "_internal_originalTime": 1689763594000
    },
    {
        "time": 1689763595000,
        "value": 139.877,
        "_internal_originalTime": 1689763595000
    },
    {
        "time": 1689763597000,
        "value": 139.876,
        "_internal_originalTime": 1689763597000
    },
    {
        "time": 1689763598000,
        "value": 139.876,
        "_internal_originalTime": 1689763598000
    },
    {
        "time": 1689763600000,
        "value": 139.877,
        "_internal_originalTime": 1689763600000
    },
    {
        "time": 1689763601000,
        "value": 139.876,
        "_internal_originalTime": 1689763601000
    },
    {
        "time": 1689763602000,
        "value": 139.875,
        "_internal_originalTime": 1689763602000
    },
    {
        "time": 1689763605000,
        "value": 139.877,
        "_internal_originalTime": 1689763605000
    },
    {
        "time": 1689763606000,
        "value": 139.878,
        "_internal_originalTime": 1689763606000
    },
    {
        "time": 1689763607000,
        "value": 139.875,
        "_internal_originalTime": 1689763607000
    },
    {
        "time": 1689763609000,
        "value": 139.875,
        "_internal_originalTime": 1689763609000
    },
    {
        "time": 1689763610000,
        "value": 139.872,
        "_internal_originalTime": 1689763610000
    },
    {
        "time": 1689763611000,
        "value": 139.88,
        "_internal_originalTime": 1689763611000
    },
    {
        "time": 1689763613000,
        "value": 139.879,
        "_internal_originalTime": 1689763613000
    },
    {
        "time": 1689763614000,
        "value": 139.875,
        "_internal_originalTime": 1689763614000
    },
    {
        "time": 1689763615000,
        "value": 139.878,
        "_internal_originalTime": 1689763615000
    },
    {
        "time": 1689763617000,
        "value": 139.875,
        "_internal_originalTime": 1689763617000
    },
    {
        "time": 1689763618000,
        "value": 139.875,
        "_internal_originalTime": 1689763618000
    },
    {
        "time": 1689763620000,
        "value": 139.876,
        "_internal_originalTime": 1689763620000
    },
    {
        "time": 1689763622000,
        "value": 139.875,
        "_internal_originalTime": 1689763622000
    },
    {
        "time": 1689763624000,
        "value": 139.877,
        "_internal_originalTime": 1689763624000
    },
    {
        "time": 1689763626000,
        "value": 139.878,
        "_internal_originalTime": 1689763626000
    },
    {
        "time": 1689763628000,
        "value": 139.876,
        "_internal_originalTime": 1689763628000
    },
    {
        "time": 1689763629000,
        "value": 139.878,
        "_internal_originalTime": 1689763629000
    },
    {
        "time": 1689763631000,
        "value": 139.878,
        "_internal_originalTime": 1689763631000
    },
    {
        "time": 1689763633000,
        "value": 139.881,
        "_internal_originalTime": 1689763633000
    },
    {
        "time": 1689763634000,
        "value": 139.884,
        "_internal_originalTime": 1689763634000
    },
    {
        "time": 1689763636000,
        "value": 139.882,
        "_internal_originalTime": 1689763636000
    },
    {
        "time": 1689763642000,
        "value": 139.88,
        "_internal_originalTime": 1689763642000
    },
    {
        "time": 1689763644000,
        "value": 139.881,
        "_internal_originalTime": 1689763644000
    },
    {
        "time": 1689763646000,
        "value": 139.88,
        "_internal_originalTime": 1689763646000
    },
    {
        "time": 1689763649000,
        "value": 139.88,
        "_internal_originalTime": 1689763649000
    },
    {
        "time": 1689763651000,
        "value": 139.882,
        "_internal_originalTime": 1689763651000
    },
    {
        "time": 1689763652000,
        "value": 139.882,
        "_internal_originalTime": 1689763652000
    },
    {
        "time": 1689763653000,
        "value": 139.88,
        "_internal_originalTime": 1689763653000
    },
    {
        "time": 1689763656000,
        "value": 139.885,
        "_internal_originalTime": 1689763656000
    },
    {
        "time": 1689763657000,
        "value": 139.886,
        "_internal_originalTime": 1689763657000
    },
    {
        "time": 1689763658000,
        "value": 139.889,
        "_internal_originalTime": 1689763658000
    },
    {
        "time": 1689763659000,
        "value": 139.889,
        "_internal_originalTime": 1689763659000
    },
    {
        "time": 1689763661000,
        "value": 139.89,
        "_internal_originalTime": 1689763661000
    },
    {
        "time": 1689763662000,
        "value": 139.884,
        "_internal_originalTime": 1689763662000
    },
    {
        "time": 1689763663000,
        "value": 139.887,
        "_internal_originalTime": 1689763663000
    },
    {
        "time": 1689763664000,
        "value": 139.885,
        "_internal_originalTime": 1689763664000
    },
    {
        "time": 1689763666000,
        "value": 139.887,
        "_internal_originalTime": 1689763666000
    },
    {
        "time": 1689763667000,
        "value": 139.888,
        "_internal_originalTime": 1689763667000
    },
    {
        "time": 1689763669000,
        "value": 139.895,
        "_internal_originalTime": 1689763669000
    },
    {
        "time": 1689763670000,
        "value": 139.892,
        "_internal_originalTime": 1689763670000
    },
    {
        "time": 1689763671000,
        "value": 139.888,
        "_internal_originalTime": 1689763671000
    },
    {
        "time": 1689763673000,
        "value": 139.891,
        "_internal_originalTime": 1689763673000
    },
    {
        "time": 1689763674000,
        "value": 139.89,
        "_internal_originalTime": 1689763674000
    },
    {
        "time": 1689763676000,
        "value": 139.898,
        "_internal_originalTime": 1689763676000
    },
    {
        "time": 1689763677000,
        "value": 139.896,
        "_internal_originalTime": 1689763677000
    },
    {
        "time": 1689763678000,
        "value": 139.894,
        "_internal_originalTime": 1689763678000
    },
    {
        "time": 1689763681000,
        "value": 139.87,
        "_internal_originalTime": 1689763681000
    },
    {
        "time": 1689763683000,
        "value": 139.904,
        "_internal_originalTime": 1689763683000
    },
    {
        "time": 1689763684000,
        "value": 139.906,
        "_internal_originalTime": 1689763684000
    },
    {
        "time": 1689763686000,
        "value": 139.917,
        "_internal_originalTime": 1689763686000
    },
    {
        "time": 1689763687000,
        "value": 139.914,
        "_internal_originalTime": 1689763687000
    },
    {
        "time": 1689763688000,
        "value": 139.916,
        "_internal_originalTime": 1689763688000
    },
    {
        "time": 1689763689000,
        "value": 139.912,
        "_internal_originalTime": 1689763689000
    },
    {
        "time": 1689763691000,
        "value": 139.916,
        "_internal_originalTime": 1689763691000
    },
    {
        "time": 1689763693000,
        "value": 139.917,
        "_internal_originalTime": 1689763693000
    },
    {
        "time": 1689763695000,
        "value": 139.91,
        "_internal_originalTime": 1689763695000
    },
    {
        "time": 1689763696000,
        "value": 139.91,
        "_internal_originalTime": 1689763696000
    },
    {
        "time": 1689763697000,
        "value": 139.91,
        "_internal_originalTime": 1689763697000
    },
    {
        "time": 1689763698000,
        "value": 139.91,
        "_internal_originalTime": 1689763698000
    },
    {
        "time": 1689763700000,
        "value": 139.914,
        "_internal_originalTime": 1689763700000
    },
    {
        "time": 1689763701000,
        "value": 139.914,
        "_internal_originalTime": 1689763701000
    },
    {
        "time": 1689763703000,
        "value": 139.915,
        "_internal_originalTime": 1689763703000
    },
    {
        "time": 1689763707000,
        "value": 139.912,
        "_internal_originalTime": 1689763707000
    },
    {
        "time": 1689763708000,
        "value": 139.912,
        "_internal_originalTime": 1689763708000
    },
    {
        "time": 1689763710000,
        "value": 139.911,
        "_internal_originalTime": 1689763710000
    },
    {
        "time": 1689763711000,
        "value": 139.908,
        "_internal_originalTime": 1689763711000
    },
    {
        "time": 1689763713000,
        "value": 139.904,
        "_internal_originalTime": 1689763713000
    },
    {
        "time": 1689763714000,
        "value": 139.904,
        "_internal_originalTime": 1689763714000
    },
    {
        "time": 1689763715000,
        "value": 139.902,
        "_internal_originalTime": 1689763715000
    },
    {
        "time": 1689763716000,
        "value": 139.9,
        "_internal_originalTime": 1689763716000
    },
    {
        "time": 1689763718000,
        "value": 139.906,
        "_internal_originalTime": 1689763718000
    },
    {
        "time": 1689763719000,
        "value": 139.907,
        "_internal_originalTime": 1689763719000
    },
    {
        "time": 1689763721000,
        "value": 139.907,
        "_internal_originalTime": 1689763721000
    },
    {
        "time": 1689763723000,
        "value": 139.905,
        "_internal_originalTime": 1689763723000
    },
    {
        "time": 1689763724000,
        "value": 139.904,
        "_internal_originalTime": 1689763724000
    },
    {
        "time": 1689763725000,
        "value": 139.904,
        "_internal_originalTime": 1689763725000
    },
    {
        "time": 1689763726000,
        "value": 139.903,
        "_internal_originalTime": 1689763726000
    },
    {
        "time": 1689763728000,
        "value": 139.904,
        "_internal_originalTime": 1689763728000
    },
    {
        "time": 1689763729000,
        "value": 139.904,
        "_internal_originalTime": 1689763729000
    },
    {
        "time": 1689763731000,
        "value": 139.9,
        "_internal_originalTime": 1689763731000
    },
    {
        "time": 1689763732000,
        "value": 139.907,
        "_internal_originalTime": 1689763732000
    },
    {
        "time": 1689763733000,
        "value": 139.901,
        "_internal_originalTime": 1689763733000
    },
    {
        "time": 1689763735000,
        "value": 139.892,
        "_internal_originalTime": 1689763735000
    },
    {
        "time": 1689763740000,
        "value": 139.89,
        "_internal_originalTime": 1689763740000
    },
    {
        "time": 1689763741000,
        "value": 139.894,
        "_internal_originalTime": 1689763741000
    },
    {
        "time": 1689763742000,
        "value": 139.895,
        "_internal_originalTime": 1689763742000
    },
    {
        "time": 1689763746000,
        "value": 139.895,
        "_internal_originalTime": 1689763746000
    },
    {
        "time": 1689763747000,
        "value": 139.896,
        "_internal_originalTime": 1689763747000
    },
    {
        "time": 1689763748000,
        "value": 139.894,
        "_internal_originalTime": 1689763748000
    },
    {
        "time": 1689763750000,
        "value": 139.895,
        "_internal_originalTime": 1689763750000
    },
    {
        "time": 1689763751000,
        "value": 139.895,
        "_internal_originalTime": 1689763751000
    },
    {
        "time": 1689763753000,
        "value": 139.893,
        "_internal_originalTime": 1689763753000
    },
    {
        "time": 1689763754000,
        "value": 139.893,
        "_internal_originalTime": 1689763754000
    },
    {
        "time": 1689763756000,
        "value": 139.894,
        "_internal_originalTime": 1689763756000
    },
    {
        "time": 1689763757000,
        "value": 139.892,
        "_internal_originalTime": 1689763757000
    },
    {
        "time": 1689763759000,
        "value": 139.892,
        "_internal_originalTime": 1689763759000
    },
    {
        "time": 1689763760000,
        "value": 139.894,
        "_internal_originalTime": 1689763760000
    },
    {
        "time": 1689763761000,
        "value": 139.892,
        "_internal_originalTime": 1689763761000
    },
    {
        "time": 1689763762000,
        "value": 139.894,
        "_internal_originalTime": 1689763762000
    },
    {
        "time": 1689763764000,
        "value": 139.895,
        "_internal_originalTime": 1689763764000
    },
    {
        "time": 1689763767000,
        "value": 139.893,
        "_internal_originalTime": 1689763767000
    },
    {
        "time": 1689763768000,
        "value": 139.893,
        "_internal_originalTime": 1689763768000
    },
    {
        "time": 1689763770000,
        "value": 139.892,
        "_internal_originalTime": 1689763770000
    },
    {
        "time": 1689763775000,
        "value": 139.895,
        "_internal_originalTime": 1689763775000
    },
    {
        "time": 1689763776000,
        "value": 139.889,
        "_internal_originalTime": 1689763776000
    },
    {
        "time": 1689763778000,
        "value": 139.893,
        "_internal_originalTime": 1689763778000
    },
    {
        "time": 1689763780000,
        "value": 139.895,
        "_internal_originalTime": 1689763780000
    },
    {
        "time": 1689763781000,
        "value": 139.892,
        "_internal_originalTime": 1689763781000
    },
    {
        "time": 1689763783000,
        "value": 139.895,
        "_internal_originalTime": 1689763783000
    },
    {
        "time": 1689763784000,
        "value": 139.896,
        "_internal_originalTime": 1689763784000
    },
    {
        "time": 1689763785000,
        "value": 139.892,
        "_internal_originalTime": 1689763785000
    },
    {
        "time": 1689763787000,
        "value": 139.905,
        "_internal_originalTime": 1689763787000
    },
    {
        "time": 1689763789000,
        "value": 139.905,
        "_internal_originalTime": 1689763789000
    },
    {
        "time": 1689763790000,
        "value": 139.906,
        "_internal_originalTime": 1689763790000
    },
    {
        "time": 1689763791000,
        "value": 139.906,
        "_internal_originalTime": 1689763791000
    },
    {
        "time": 1689763792000,
        "value": 139.906,
        "_internal_originalTime": 1689763792000
    },
    {
        "time": 1689763794000,
        "value": 139.906,
        "_internal_originalTime": 1689763794000
    },
    {
        "time": 1689763796000,
        "value": 139.906,
        "_internal_originalTime": 1689763796000
    },
    {
        "time": 1689763797000,
        "value": 139.905,
        "_internal_originalTime": 1689763797000
    },
    {
        "time": 1689763800000,
        "value": 139.904,
        "_internal_originalTime": 1689763800000
    },
    {
        "time": 1689763802000,
        "value": 139.906,
        "_internal_originalTime": 1689763802000
    },
    {
        "time": 1689763803000,
        "value": 139.906,
        "_internal_originalTime": 1689763803000
    },
    {
        "time": 1689763805000,
        "value": 139.906,
        "_internal_originalTime": 1689763805000
    },
    {
        "time": 1689763806000,
        "value": 139.9,
        "_internal_originalTime": 1689763806000
    },
    {
        "time": 1689763808000,
        "value": 139.904,
        "_internal_originalTime": 1689763808000
    },
    {
        "time": 1689763809000,
        "value": 139.904,
        "_internal_originalTime": 1689763809000
    },
    {
        "time": 1689763810000,
        "value": 139.906,
        "_internal_originalTime": 1689763810000
    },
    {
        "time": 1689763812000,
        "value": 139.904,
        "_internal_originalTime": 1689763812000
    },
    {
        "time": 1689763814000,
        "value": 139.905,
        "_internal_originalTime": 1689763814000
    },
    {
        "time": 1689763815000,
        "value": 139.904,
        "_internal_originalTime": 1689763815000
    },
    {
        "time": 1689763816000,
        "value": 139.906,
        "_internal_originalTime": 1689763816000
    },
    {
        "time": 1689763817000,
        "value": 139.906,
        "_internal_originalTime": 1689763817000
    },
    {
        "time": 1689763819000,
        "value": 139.906,
        "_internal_originalTime": 1689763819000
    },
    {
        "time": 1689763820000,
        "value": 139.904,
        "_internal_originalTime": 1689763820000
    },
    {
        "time": 1689763822000,
        "value": 139.906,
        "_internal_originalTime": 1689763822000
    },
    {
        "time": 1689763824000,
        "value": 139.906,
        "_internal_originalTime": 1689763824000
    },
    {
        "time": 1689763826000,
        "value": 139.908,
        "_internal_originalTime": 1689763826000
    },
    {
        "time": 1689763827000,
        "value": 139.909,
        "_internal_originalTime": 1689763827000
    },
    {
        "time": 1689763828000,
        "value": 139.912,
        "_internal_originalTime": 1689763828000
    },
    {
        "time": 1689763830000,
        "value": 139.913,
        "_internal_originalTime": 1689763830000
    },
    {
        "time": 1689763831000,
        "value": 139.917,
        "_internal_originalTime": 1689763831000
    },
    {
        "time": 1689763832000,
        "value": 139.921,
        "_internal_originalTime": 1689763832000
    },
    {
        "time": 1689763834000,
        "value": 139.921,
        "_internal_originalTime": 1689763834000
    },
    {
        "time": 1689763835000,
        "value": 139.917,
        "_internal_originalTime": 1689763835000
    },
    {
        "time": 1689763836000,
        "value": 139.912,
        "_internal_originalTime": 1689763836000
    },
    {
        "time": 1689763838000,
        "value": 139.917,
        "_internal_originalTime": 1689763838000
    },
    {
        "time": 1689763840000,
        "value": 139.917,
        "_internal_originalTime": 1689763840000
    },
    {
        "time": 1689763842000,
        "value": 139.922,
        "_internal_originalTime": 1689763842000
    },
    {
        "time": 1689763843000,
        "value": 139.924,
        "_internal_originalTime": 1689763843000
    },
    {
        "time": 1689763844000,
        "value": 139.92,
        "_internal_originalTime": 1689763844000
    },
    {
        "time": 1689763846000,
        "value": 139.919,
        "_internal_originalTime": 1689763846000
    },
    {
        "time": 1689763847000,
        "value": 139.919,
        "_internal_originalTime": 1689763847000
    },
    {
        "time": 1689763849000,
        "value": 139.917,
        "_internal_originalTime": 1689763849000
    },
    {
        "time": 1689763850000,
        "value": 139.917,
        "_internal_originalTime": 1689763850000
    },
    {
        "time": 1689763851000,
        "value": 139.921,
        "_internal_originalTime": 1689763851000
    },
    {
        "time": 1689763853000,
        "value": 139.921,
        "_internal_originalTime": 1689763853000
    },
    {
        "time": 1689763854000,
        "value": 139.918,
        "_internal_originalTime": 1689763854000
    },
    {
        "time": 1689763855000,
        "value": 139.915,
        "_internal_originalTime": 1689763855000
    },
    {
        "time": 1689763857000,
        "value": 139.918,
        "_internal_originalTime": 1689763857000
    },
    {
        "time": 1689763860000,
        "value": 139.92,
        "_internal_originalTime": 1689763860000
    },
    {
        "time": 1689763861000,
        "value": 139.921,
        "_internal_originalTime": 1689763861000
    },
    {
        "time": 1689763862000,
        "value": 139.923,
        "_internal_originalTime": 1689763862000
    },
    {
        "time": 1689763863000,
        "value": 139.923,
        "_internal_originalTime": 1689763863000
    },
    {
        "time": 1689763864000,
        "value": 139.923,
        "_internal_originalTime": 1689763864000
    },
    {
        "time": 1689763866000,
        "value": 139.92,
        "_internal_originalTime": 1689763866000
    },
    {
        "time": 1689763867000,
        "value": 139.919,
        "_internal_originalTime": 1689763867000
    },
    {
        "time": 1689763869000,
        "value": 139.923,
        "_internal_originalTime": 1689763869000
    },
    {
        "time": 1689763870000,
        "value": 139.923,
        "_internal_originalTime": 1689763870000
    },
    {
        "time": 1689763872000,
        "value": 139.923,
        "_internal_originalTime": 1689763872000
    },
    {
        "time": 1689763873000,
        "value": 139.925,
        "_internal_originalTime": 1689763873000
    },
    {
        "time": 1689763874000,
        "value": 139.923,
        "_internal_originalTime": 1689763874000
    },
    {
        "time": 1689763875000,
        "value": 139.921,
        "_internal_originalTime": 1689763875000
    },
    {
        "time": 1689763877000,
        "value": 139.922,
        "_internal_originalTime": 1689763877000
    },
    {
        "time": 1689763879000,
        "value": 139.924,
        "_internal_originalTime": 1689763879000
    },
    {
        "time": 1689763881000,
        "value": 139.924,
        "_internal_originalTime": 1689763881000
    },
    {
        "time": 1689763882000,
        "value": 139.924,
        "_internal_originalTime": 1689763882000
    },
    {
        "time": 1689763883000,
        "value": 139.921,
        "_internal_originalTime": 1689763883000
    },
    {
        "time": 1689763885000,
        "value": 139.922,
        "_internal_originalTime": 1689763885000
    },
    {
        "time": 1689763886000,
        "value": 139.923,
        "_internal_originalTime": 1689763886000
    },
    {
        "time": 1689763887000,
        "value": 139.922,
        "_internal_originalTime": 1689763887000
    },
    {
        "time": 1689763888000,
        "value": 139.922,
        "_internal_originalTime": 1689763888000
    },
    {
        "time": 1689763890000,
        "value": 139.922,
        "_internal_originalTime": 1689763890000
    },
    {
        "time": 1689763891000,
        "value": 139.92,
        "_internal_originalTime": 1689763891000
    },
    {
        "time": 1689763893000,
        "value": 139.926,
        "_internal_originalTime": 1689763893000
    },
    {
        "time": 1689763895000,
        "value": 139.926,
        "_internal_originalTime": 1689763895000
    },
    {
        "time": 1689763896000,
        "value": 139.926,
        "_internal_originalTime": 1689763896000
    },
    {
        "time": 1689763898000,
        "value": 139.925,
        "_internal_originalTime": 1689763898000
    },
    {
        "time": 1689763899000,
        "value": 139.926,
        "_internal_originalTime": 1689763899000
    },
    {
        "time": 1689763900000,
        "value": 139.922,
        "_internal_originalTime": 1689763900000
    },
    {
        "time": 1689763901000,
        "value": 139.925,
        "_internal_originalTime": 1689763901000
    },
    {
        "time": 1689763903000,
        "value": 139.927,
        "_internal_originalTime": 1689763903000
    },
    {
        "time": 1689763904000,
        "value": 139.927,
        "_internal_originalTime": 1689763904000
    },
    {
        "time": 1689763905000,
        "value": 139.926,
        "_internal_originalTime": 1689763905000
    },
    {
        "time": 1689763907000,
        "value": 139.927,
        "_internal_originalTime": 1689763907000
    },
    {
        "time": 1689763908000,
        "value": 139.927,
        "_internal_originalTime": 1689763908000
    },
    {
        "time": 1689763910000,
        "value": 139.935,
        "_internal_originalTime": 1689763910000
    },
    {
        "time": 1689763911000,
        "value": 139.933,
        "_internal_originalTime": 1689763911000
    },
    {
        "time": 1689763913000,
        "value": 139.929,
        "_internal_originalTime": 1689763913000
    },
    {
        "time": 1689763914000,
        "value": 139.934,
        "_internal_originalTime": 1689763914000
    },
    {
        "time": 1689763915000,
        "value": 139.932,
        "_internal_originalTime": 1689763915000
    },
    {
        "time": 1689763917000,
        "value": 139.931,
        "_internal_originalTime": 1689763917000
    },
    {
        "time": 1689763920000,
        "value": 139.932,
        "_internal_originalTime": 1689763920000
    },
    {
        "time": 1689763922000,
        "value": 139.93,
        "_internal_originalTime": 1689763922000
    },
    {
        "time": 1689763923000,
        "value": 139.932,
        "_internal_originalTime": 1689763923000
    },
    {
        "time": 1689763924000,
        "value": 139.93,
        "_internal_originalTime": 1689763924000
    },
    {
        "time": 1689763925000,
        "value": 139.927,
        "_internal_originalTime": 1689763925000
    },
    {
        "time": 1689763926000,
        "value": 139.927,
        "_internal_originalTime": 1689763926000
    },
    {
        "time": 1689763928000,
        "value": 139.926,
        "_internal_originalTime": 1689763928000
    },
    {
        "time": 1689763929000,
        "value": 139.924,
        "_internal_originalTime": 1689763929000
    },
    {
        "time": 1689763931000,
        "value": 139.929,
        "_internal_originalTime": 1689763931000
    },
    {
        "time": 1689763932000,
        "value": 139.928,
        "_internal_originalTime": 1689763932000
    },
    {
        "time": 1689763933000,
        "value": 139.926,
        "_internal_originalTime": 1689763933000
    },
    {
        "time": 1689763935000,
        "value": 139.927,
        "_internal_originalTime": 1689763935000
    },
    {
        "time": 1689763936000,
        "value": 139.927,
        "_internal_originalTime": 1689763936000
    },
    {
        "time": 1689763938000,
        "value": 139.927,
        "_internal_originalTime": 1689763938000
    },
    {
        "time": 1689763940000,
        "value": 139.925,
        "_internal_originalTime": 1689763940000
    },
    {
        "time": 1689763942000,
        "value": 139.927,
        "_internal_originalTime": 1689763942000
    },
    {
        "time": 1689763944000,
        "value": 139.925,
        "_internal_originalTime": 1689763944000
    },
    {
        "time": 1689763945000,
        "value": 139.921,
        "_internal_originalTime": 1689763945000
    },
    {
        "time": 1689763947000,
        "value": 139.923,
        "_internal_originalTime": 1689763947000
    },
    {
        "time": 1689763949000,
        "value": 139.927,
        "_internal_originalTime": 1689763949000
    },
    {
        "time": 1689763950000,
        "value": 139.922,
        "_internal_originalTime": 1689763950000
    },
    {
        "time": 1689763951000,
        "value": 139.926,
        "_internal_originalTime": 1689763951000
    },
    {
        "time": 1689763953000,
        "value": 139.924,
        "_internal_originalTime": 1689763953000
    },
    {
        "time": 1689763954000,
        "value": 139.922,
        "_internal_originalTime": 1689763954000
    },
    {
        "time": 1689763956000,
        "value": 139.925,
        "_internal_originalTime": 1689763956000
    },
    {
        "time": 1689763957000,
        "value": 139.928,
        "_internal_originalTime": 1689763957000
    },
    {
        "time": 1689763958000,
        "value": 139.925,
        "_internal_originalTime": 1689763958000
    },
    {
        "time": 1689763961000,
        "value": 139.93,
        "_internal_originalTime": 1689763961000
    },
    {
        "time": 1689763963000,
        "value": 139.923,
        "_internal_originalTime": 1689763963000
    },
    {
        "time": 1689763965000,
        "value": 139.924,
        "_internal_originalTime": 1689763965000
    },
    {
        "time": 1689763966000,
        "value": 139.924,
        "_internal_originalTime": 1689763966000
    },
    {
        "time": 1689763969000,
        "value": 139.925,
        "_internal_originalTime": 1689763969000
    },
    {
        "time": 1689763970000,
        "value": 139.92,
        "_internal_originalTime": 1689763970000
    },
    {
        "time": 1689763972000,
        "value": 139.914,
        "_internal_originalTime": 1689763972000
    },
    {
        "time": 1689763973000,
        "value": 139.912,
        "_internal_originalTime": 1689763973000
    },
    {
        "time": 1689763977000,
        "value": 139.917,
        "_internal_originalTime": 1689763977000
    },
    {
        "time": 1689763980000,
        "value": 139.9,
        "_internal_originalTime": 1689763980000
    },
    {
        "time": 1689763982000,
        "value": 139.917,
        "_internal_originalTime": 1689763982000
    },
    {
        "time": 1689763983000,
        "value": 139.918,
        "_internal_originalTime": 1689763983000
    },
    {
        "time": 1689763985000,
        "value": 139.913,
        "_internal_originalTime": 1689763985000
    },
    {
        "time": 1689763987000,
        "value": 139.914,
        "_internal_originalTime": 1689763987000
    },
    {
        "time": 1689763988000,
        "value": 139.919,
        "_internal_originalTime": 1689763988000
    },
    {
        "time": 1689763992000,
        "value": 139.913,
        "_internal_originalTime": 1689763992000
    },
    {
        "time": 1689763995000,
        "value": 139.906,
        "_internal_originalTime": 1689763995000
    },
    {
        "time": 1689763996000,
        "value": 139.908,
        "_internal_originalTime": 1689763996000
    },
    {
        "time": 1689763997000,
        "value": 139.908,
        "_internal_originalTime": 1689763997000
    },
    {
        "time": 1689763998000,
        "value": 139.904,
        "_internal_originalTime": 1689763998000
    },
    {
        "time": 1689763999000,
        "value": 139.908,
        "_internal_originalTime": 1689763999000
    },
    {
        "time": 1689764001000,
        "value": 139.906,
        "_internal_originalTime": 1689764001000
    },
    {
        "time": 1689764002000,
        "value": 139.908,
        "_internal_originalTime": 1689764002000
    },
    {
        "time": 1689764003000,
        "value": 139.909,
        "_internal_originalTime": 1689764003000
    },
    {
        "time": 1689764005000,
        "value": 139.905,
        "_internal_originalTime": 1689764005000
    },
    {
        "time": 1689764007000,
        "value": 139.915,
        "_internal_originalTime": 1689764007000
    },
    {
        "time": 1689764008000,
        "value": 139.913,
        "_internal_originalTime": 1689764008000
    },
    {
        "time": 1689764010000,
        "value": 139.915,
        "_internal_originalTime": 1689764010000
    },
    {
        "time": 1689764012000,
        "value": 139.916,
        "_internal_originalTime": 1689764012000
    },
    {
        "time": 1689764014000,
        "value": 139.921,
        "_internal_originalTime": 1689764014000
    },
    {
        "time": 1689764015000,
        "value": 139.92,
        "_internal_originalTime": 1689764015000
    },
    {
        "time": 1689764017000,
        "value": 139.916,
        "_internal_originalTime": 1689764017000
    },
    {
        "time": 1689764018000,
        "value": 139.916,
        "_internal_originalTime": 1689764018000
    },
    {
        "time": 1689764020000,
        "value": 139.918,
        "_internal_originalTime": 1689764020000
    },
    {
        "time": 1689764021000,
        "value": 139.917,
        "_internal_originalTime": 1689764021000
    },
    {
        "time": 1689764023000,
        "value": 139.913,
        "_internal_originalTime": 1689764023000
    },
    {
        "time": 1689764025000,
        "value": 139.912,
        "_internal_originalTime": 1689764025000
    },
    {
        "time": 1689764026000,
        "value": 139.913,
        "_internal_originalTime": 1689764026000
    },
    {
        "time": 1689764027000,
        "value": 139.911,
        "_internal_originalTime": 1689764027000
    },
    {
        "time": 1689764029000,
        "value": 139.91,
        "_internal_originalTime": 1689764029000
    },
    {
        "time": 1689764030000,
        "value": 139.91,
        "_internal_originalTime": 1689764030000
    },
    {
        "time": 1689764031000,
        "value": 139.911,
        "_internal_originalTime": 1689764031000
    },
    {
        "time": 1689764033000,
        "value": 139.912,
        "_internal_originalTime": 1689764033000
    },
    {
        "time": 1689764034000,
        "value": 139.909,
        "_internal_originalTime": 1689764034000
    },
    {
        "time": 1689764036000,
        "value": 139.91,
        "_internal_originalTime": 1689764036000
    },
    {
        "time": 1689764040000,
        "value": 139.909,
        "_internal_originalTime": 1689764040000
    },
    {
        "time": 1689764042000,
        "value": 139.906,
        "_internal_originalTime": 1689764042000
    },
    {
        "time": 1689764044000,
        "value": 139.908,
        "_internal_originalTime": 1689764044000
    },
    {
        "time": 1689764045000,
        "value": 139.908,
        "_internal_originalTime": 1689764045000
    },
    {
        "time": 1689764047000,
        "value": 139.901,
        "_internal_originalTime": 1689764047000
    },
    {
        "time": 1689764048000,
        "value": 139.906,
        "_internal_originalTime": 1689764048000
    },
    {
        "time": 1689764049000,
        "value": 139.909,
        "_internal_originalTime": 1689764049000
    },
    {
        "time": 1689764050000,
        "value": 139.909,
        "_internal_originalTime": 1689764050000
    },
    {
        "time": 1689764052000,
        "value": 139.912,
        "_internal_originalTime": 1689764052000
    },
    {
        "time": 1689764053000,
        "value": 139.914,
        "_internal_originalTime": 1689764053000
    },
    {
        "time": 1689764054000,
        "value": 139.917,
        "_internal_originalTime": 1689764054000
    },
    {
        "time": 1689764056000,
        "value": 139.916,
        "_internal_originalTime": 1689764056000
    },
    {
        "time": 1689764057000,
        "value": 139.914,
        "_internal_originalTime": 1689764057000
    },
    {
        "time": 1689764058000,
        "value": 139.913,
        "_internal_originalTime": 1689764058000
    },
    {
        "time": 1689764060000,
        "value": 139.909,
        "_internal_originalTime": 1689764060000
    },
    {
        "time": 1689764061000,
        "value": 139.909,
        "_internal_originalTime": 1689764061000
    },
    {
        "time": 1689764063000,
        "value": 139.912,
        "_internal_originalTime": 1689764063000
    },
    {
        "time": 1689764064000,
        "value": 139.913,
        "_internal_originalTime": 1689764064000
    },
    {
        "time": 1689764065000,
        "value": 139.915,
        "_internal_originalTime": 1689764065000
    },
    {
        "time": 1689764067000,
        "value": 139.914,
        "_internal_originalTime": 1689764067000
    },
    {
        "time": 1689764068000,
        "value": 139.914,
        "_internal_originalTime": 1689764068000
    },
    {
        "time": 1689764069000,
        "value": 139.915,
        "_internal_originalTime": 1689764069000
    },
    {
        "time": 1689764070000,
        "value": 139.912,
        "_internal_originalTime": 1689764070000
    },
    {
        "time": 1689764071000,
        "value": 139.915,
        "_internal_originalTime": 1689764071000
    },
    {
        "time": 1689764073000,
        "value": 139.916,
        "_internal_originalTime": 1689764073000
    },
    {
        "time": 1689764074000,
        "value": 139.916,
        "_internal_originalTime": 1689764074000
    },
    {
        "time": 1689764075000,
        "value": 139.915,
        "_internal_originalTime": 1689764075000
    },
    {
        "time": 1689764077000,
        "value": 139.904,
        "_internal_originalTime": 1689764077000
    },
    {
        "time": 1689764078000,
        "value": 139.902,
        "_internal_originalTime": 1689764078000
    },
    {
        "time": 1689764080000,
        "value": 139.902,
        "_internal_originalTime": 1689764080000
    },
    {
        "time": 1689764081000,
        "value": 139.913,
        "_internal_originalTime": 1689764081000
    },
    {
        "time": 1689764082000,
        "value": 139.914,
        "_internal_originalTime": 1689764082000
    },
    {
        "time": 1689764084000,
        "value": 139.913,
        "_internal_originalTime": 1689764084000
    },
    {
        "time": 1689764085000,
        "value": 139.91,
        "_internal_originalTime": 1689764085000
    },
    {
        "time": 1689764086000,
        "value": 139.914,
        "_internal_originalTime": 1689764086000
    },
    {
        "time": 1689764087000,
        "value": 139.915,
        "_internal_originalTime": 1689764087000
    },
    {
        "time": 1689764089000,
        "value": 139.913,
        "_internal_originalTime": 1689764089000
    },
    {
        "time": 1689764091000,
        "value": 139.912,
        "_internal_originalTime": 1689764091000
    },
    {
        "time": 1689764093000,
        "value": 139.915,
        "_internal_originalTime": 1689764093000
    },
    {
        "time": 1689764094000,
        "value": 139.914,
        "_internal_originalTime": 1689764094000
    },
    {
        "time": 1689764096000,
        "value": 139.914,
        "_internal_originalTime": 1689764096000
    },
    {
        "time": 1689764097000,
        "value": 139.916,
        "_internal_originalTime": 1689764097000
    },
    {
        "time": 1689764099000,
        "value": 139.913,
        "_internal_originalTime": 1689764099000
    },
    {
        "time": 1689764102000,
        "value": 139.914,
        "_internal_originalTime": 1689764102000
    },
    {
        "time": 1689764103000,
        "value": 139.916,
        "_internal_originalTime": 1689764103000
    },
    {
        "time": 1689764104000,
        "value": 139.915,
        "_internal_originalTime": 1689764104000
    },
    {
        "time": 1689764106000,
        "value": 139.916,
        "_internal_originalTime": 1689764106000
    },
    {
        "time": 1689764107000,
        "value": 139.913,
        "_internal_originalTime": 1689764107000
    },
    {
        "time": 1689764108000,
        "value": 139.914,
        "_internal_originalTime": 1689764108000
    },
    {
        "time": 1689764110000,
        "value": 139.912,
        "_internal_originalTime": 1689764110000
    },
    {
        "time": 1689764112000,
        "value": 139.914,
        "_internal_originalTime": 1689764112000
    },
    {
        "time": 1689764114000,
        "value": 139.91,
        "_internal_originalTime": 1689764114000
    },
    {
        "time": 1689764115000,
        "value": 139.914,
        "_internal_originalTime": 1689764115000
    },
    {
        "time": 1689764116000,
        "value": 139.915,
        "_internal_originalTime": 1689764116000
    },
    {
        "time": 1689764117000,
        "value": 139.913,
        "_internal_originalTime": 1689764117000
    },
    {
        "time": 1689764118000,
        "value": 139.913,
        "_internal_originalTime": 1689764118000
    },
    {
        "time": 1689764120000,
        "value": 139.911,
        "_internal_originalTime": 1689764120000
    },
    {
        "time": 1689764121000,
        "value": 139.916,
        "_internal_originalTime": 1689764121000
    },
    {
        "time": 1689764122000,
        "value": 139.916,
        "_internal_originalTime": 1689764122000
    },
    {
        "time": 1689764124000,
        "value": 139.925,
        "_internal_originalTime": 1689764124000
    },
    {
        "time": 1689764126000,
        "value": 139.924,
        "_internal_originalTime": 1689764126000
    },
    {
        "time": 1689764129000,
        "value": 139.93,
        "_internal_originalTime": 1689764129000
    },
    {
        "time": 1689764130000,
        "value": 139.934,
        "_internal_originalTime": 1689764130000
    },
    {
        "time": 1689764133000,
        "value": 139.929,
        "_internal_originalTime": 1689764133000
    },
    {
        "time": 1689764134000,
        "value": 139.925,
        "_internal_originalTime": 1689764134000
    },
    {
        "time": 1689764135000,
        "value": 139.922,
        "_internal_originalTime": 1689764135000
    },
    {
        "time": 1689764137000,
        "value": 139.921,
        "_internal_originalTime": 1689764137000
    },
    {
        "time": 1689764138000,
        "value": 139.925,
        "_internal_originalTime": 1689764138000
    },
    {
        "time": 1689764139000,
        "value": 139.922,
        "_internal_originalTime": 1689764139000
    },
    {
        "time": 1689764141000,
        "value": 139.918,
        "_internal_originalTime": 1689764141000
    },
    {
        "time": 1689764142000,
        "value": 139.92,
        "_internal_originalTime": 1689764142000
    },
    {
        "time": 1689764144000,
        "value": 139.923,
        "_internal_originalTime": 1689764144000
    },
    {
        "time": 1689764146000,
        "value": 139.922,
        "_internal_originalTime": 1689764146000
    },
    {
        "time": 1689764147000,
        "value": 139.922,
        "_internal_originalTime": 1689764147000
    },
    {
        "time": 1689764148000,
        "value": 139.924,
        "_internal_originalTime": 1689764148000
    },
    {
        "time": 1689764150000,
        "value": 139.923,
        "_internal_originalTime": 1689764150000
    },
    {
        "time": 1689764151000,
        "value": 139.922,
        "_internal_originalTime": 1689764151000
    },
    {
        "time": 1689764153000,
        "value": 139.935,
        "_internal_originalTime": 1689764153000
    },
    {
        "time": 1689764154000,
        "value": 139.93,
        "_internal_originalTime": 1689764154000
    },
    {
        "time": 1689764156000,
        "value": 139.936,
        "_internal_originalTime": 1689764156000
    },
    {
        "time": 1689764157000,
        "value": 139.933,
        "_internal_originalTime": 1689764157000
    },
    {
        "time": 1689764158000,
        "value": 139.933,
        "_internal_originalTime": 1689764158000
    },
    {
        "time": 1689764159000,
        "value": 139.932,
        "_internal_originalTime": 1689764159000
    },
    {
        "time": 1689764161000,
        "value": 139.91,
        "_internal_originalTime": 1689764161000
    },
    {
        "time": 1689764162000,
        "value": 139.928,
        "_internal_originalTime": 1689764162000
    },
    {
        "time": 1689764163000,
        "value": 139.931,
        "_internal_originalTime": 1689764163000
    },
    {
        "time": 1689764165000,
        "value": 139.93,
        "_internal_originalTime": 1689764165000
    },
    {
        "time": 1689764166000,
        "value": 139.933,
        "_internal_originalTime": 1689764166000
    },
    {
        "time": 1689764167000,
        "value": 139.933,
        "_internal_originalTime": 1689764167000
    },
    {
        "time": 1689764169000,
        "value": 139.926,
        "_internal_originalTime": 1689764169000
    },
    {
        "time": 1689764170000,
        "value": 139.916,
        "_internal_originalTime": 1689764170000
    },
    {
        "time": 1689764172000,
        "value": 139.922,
        "_internal_originalTime": 1689764172000
    },
    {
        "time": 1689764173000,
        "value": 139.918,
        "_internal_originalTime": 1689764173000
    },
    {
        "time": 1689764175000,
        "value": 139.918,
        "_internal_originalTime": 1689764175000
    },
    {
        "time": 1689764176000,
        "value": 139.919,
        "_internal_originalTime": 1689764176000
    },
    {
        "time": 1689764177000,
        "value": 139.918,
        "_internal_originalTime": 1689764177000
    },
    {
        "time": 1689764178000,
        "value": 139.917,
        "_internal_originalTime": 1689764178000
    },
    {
        "time": 1689764180000,
        "value": 139.919,
        "_internal_originalTime": 1689764180000
    },
    {
        "time": 1689764182000,
        "value": 139.923,
        "_internal_originalTime": 1689764182000
    },
    {
        "time": 1689764184000,
        "value": 139.923,
        "_internal_originalTime": 1689764184000
    },
    {
        "time": 1689764185000,
        "value": 139.923,
        "_internal_originalTime": 1689764185000
    },
    {
        "time": 1689764187000,
        "value": 139.919,
        "_internal_originalTime": 1689764187000
    },
    {
        "time": 1689764188000,
        "value": 139.922,
        "_internal_originalTime": 1689764188000
    },
    {
        "time": 1689764190000,
        "value": 139.935,
        "_internal_originalTime": 1689764190000
    },
    {
        "time": 1689764191000,
        "value": 139.93,
        "_internal_originalTime": 1689764191000
    },
    {
        "time": 1689764192000,
        "value": 139.934,
        "_internal_originalTime": 1689764192000
    },
    {
        "time": 1689764194000,
        "value": 139.934,
        "_internal_originalTime": 1689764194000
    },
    {
        "time": 1689764195000,
        "value": 139.928,
        "_internal_originalTime": 1689764195000
    },
    {
        "time": 1689764196000,
        "value": 139.932,
        "_internal_originalTime": 1689764196000
    },
    {
        "time": 1689764198000,
        "value": 139.927,
        "_internal_originalTime": 1689764198000
    },
    {
        "time": 1689764199000,
        "value": 139.928,
        "_internal_originalTime": 1689764199000
    },
    {
        "time": 1689764200000,
        "value": 139.929,
        "_internal_originalTime": 1689764200000
    },
    {
        "time": 1689764202000,
        "value": 139.926,
        "_internal_originalTime": 1689764202000
    },
    {
        "time": 1689764203000,
        "value": 139.931,
        "_internal_originalTime": 1689764203000
    },
    {
        "time": 1689764205000,
        "value": 139.927,
        "_internal_originalTime": 1689764205000
    },
    {
        "time": 1689764206000,
        "value": 139.929,
        "_internal_originalTime": 1689764206000
    },
    {
        "time": 1689764207000,
        "value": 139.929,
        "_internal_originalTime": 1689764207000
    },
    {
        "time": 1689764209000,
        "value": 139.929,
        "_internal_originalTime": 1689764209000
    },
    {
        "time": 1689764211000,
        "value": 139.926,
        "_internal_originalTime": 1689764211000
    },
    {
        "time": 1689764212000,
        "value": 139.925,
        "_internal_originalTime": 1689764212000
    },
    {
        "time": 1689764214000,
        "value": 139.929,
        "_internal_originalTime": 1689764214000
    },
    {
        "time": 1689764215000,
        "value": 139.924,
        "_internal_originalTime": 1689764215000
    },
    {
        "time": 1689764216000,
        "value": 139.925,
        "_internal_originalTime": 1689764216000
    },
    {
        "time": 1689764218000,
        "value": 139.927,
        "_internal_originalTime": 1689764218000
    },
    {
        "time": 1689764219000,
        "value": 139.926,
        "_internal_originalTime": 1689764219000
    },
    {
        "time": 1689764220000,
        "value": 139.928,
        "_internal_originalTime": 1689764220000
    },
    {
        "time": 1689764226000,
        "value": 139.93,
        "_internal_originalTime": 1689764226000
    },
    {
        "time": 1689764227000,
        "value": 139.922,
        "_internal_originalTime": 1689764227000
    },
    {
        "time": 1689764228000,
        "value": 139.929,
        "_internal_originalTime": 1689764228000
    },
    {
        "time": 1689764230000,
        "value": 139.925,
        "_internal_originalTime": 1689764230000
    },
    {
        "time": 1689764231000,
        "value": 139.928,
        "_internal_originalTime": 1689764231000
    },
    {
        "time": 1689764233000,
        "value": 139.929,
        "_internal_originalTime": 1689764233000
    },
    {
        "time": 1689764234000,
        "value": 139.93,
        "_internal_originalTime": 1689764234000
    },
    {
        "time": 1689764236000,
        "value": 139.929,
        "_internal_originalTime": 1689764236000
    },
    {
        "time": 1689764237000,
        "value": 139.918,
        "_internal_originalTime": 1689764237000
    },
    {
        "time": 1689764238000,
        "value": 139.926,
        "_internal_originalTime": 1689764238000
    },
    {
        "time": 1689764239000,
        "value": 139.928,
        "_internal_originalTime": 1689764239000
    },
    {
        "time": 1689764241000,
        "value": 139.929,
        "_internal_originalTime": 1689764241000
    },
    {
        "time": 1689764244000,
        "value": 139.924,
        "_internal_originalTime": 1689764244000
    },
    {
        "time": 1689764245000,
        "value": 139.929,
        "_internal_originalTime": 1689764245000
    },
    {
        "time": 1689764248000,
        "value": 139.928,
        "_internal_originalTime": 1689764248000
    },
    {
        "time": 1689764250000,
        "value": 139.924,
        "_internal_originalTime": 1689764250000
    },
    {
        "time": 1689764252000,
        "value": 139.928,
        "_internal_originalTime": 1689764252000
    },
    {
        "time": 1689764253000,
        "value": 139.927,
        "_internal_originalTime": 1689764253000
    },
    {
        "time": 1689764255000,
        "value": 139.925,
        "_internal_originalTime": 1689764255000
    },
    {
        "time": 1689764257000,
        "value": 139.93,
        "_internal_originalTime": 1689764257000
    },
    {
        "time": 1689764258000,
        "value": 139.928,
        "_internal_originalTime": 1689764258000
    },
    {
        "time": 1689764259000,
        "value": 139.927,
        "_internal_originalTime": 1689764259000
    },
    {
        "time": 1689764260000,
        "value": 139.927,
        "_internal_originalTime": 1689764260000
    },
    {
        "time": 1689764262000,
        "value": 139.933,
        "_internal_originalTime": 1689764262000
    },
    {
        "time": 1689764264000,
        "value": 139.933,
        "_internal_originalTime": 1689764264000
    },
    {
        "time": 1689764265000,
        "value": 139.93,
        "_internal_originalTime": 1689764265000
    },
    {
        "time": 1689764267000,
        "value": 139.936,
        "_internal_originalTime": 1689764267000
    },
    {
        "time": 1689764268000,
        "value": 139.935,
        "_internal_originalTime": 1689764268000
    },
    {
        "time": 1689764270000,
        "value": 139.935,
        "_internal_originalTime": 1689764270000
    },
    {
        "time": 1689764271000,
        "value": 139.935,
        "_internal_originalTime": 1689764271000
    },
    {
        "time": 1689764272000,
        "value": 139.937,
        "_internal_originalTime": 1689764272000
    },
    {
        "time": 1689764274000,
        "value": 139.934,
        "_internal_originalTime": 1689764274000
    },
    {
        "time": 1689764275000,
        "value": 139.933,
        "_internal_originalTime": 1689764275000
    },
    {
        "time": 1689764277000,
        "value": 139.931,
        "_internal_originalTime": 1689764277000
    },
    {
        "time": 1689764279000,
        "value": 139.931,
        "_internal_originalTime": 1689764279000
    },
    {
        "time": 1689764280000,
        "value": 139.931,
        "_internal_originalTime": 1689764280000
    },
    {
        "time": 1689764281000,
        "value": 139.933,
        "_internal_originalTime": 1689764281000
    },
    {
        "time": 1689764282000,
        "value": 139.931,
        "_internal_originalTime": 1689764282000
    },
    {
        "time": 1689764283000,
        "value": 139.932,
        "_internal_originalTime": 1689764283000
    },
    {
        "time": 1689764285000,
        "value": 139.934,
        "_internal_originalTime": 1689764285000
    },
    {
        "time": 1689764286000,
        "value": 139.931,
        "_internal_originalTime": 1689764286000
    },
    {
        "time": 1689764287000,
        "value": 139.93,
        "_internal_originalTime": 1689764287000
    },
    {
        "time": 1689764288000,
        "value": 139.933,
        "_internal_originalTime": 1689764288000
    },
    {
        "time": 1689764290000,
        "value": 139.935,
        "_internal_originalTime": 1689764290000
    },
    {
        "time": 1689764294000,
        "value": 139.935,
        "_internal_originalTime": 1689764294000
    },
    {
        "time": 1689764295000,
        "value": 139.934,
        "_internal_originalTime": 1689764295000
    },
    {
        "time": 1689764296000,
        "value": 139.932,
        "_internal_originalTime": 1689764296000
    },
    {
        "time": 1689764298000,
        "value": 139.933,
        "_internal_originalTime": 1689764298000
    },
    {
        "time": 1689764299000,
        "value": 139.934,
        "_internal_originalTime": 1689764299000
    },
    {
        "time": 1689764301000,
        "value": 139.933,
        "_internal_originalTime": 1689764301000
    },
    {
        "time": 1689764302000,
        "value": 139.932,
        "_internal_originalTime": 1689764302000
    },
    {
        "time": 1689764303000,
        "value": 139.934,
        "_internal_originalTime": 1689764303000
    },
    {
        "time": 1689764304000,
        "value": 139.935,
        "_internal_originalTime": 1689764304000
    },
    {
        "time": 1689764307000,
        "value": 139.93,
        "_internal_originalTime": 1689764307000
    },
    {
        "time": 1689764308000,
        "value": 139.925,
        "_internal_originalTime": 1689764308000
    },
    {
        "time": 1689764309000,
        "value": 139.925,
        "_internal_originalTime": 1689764309000
    },
    {
        "time": 1689764310000,
        "value": 139.926,
        "_internal_originalTime": 1689764310000
    },
    {
        "time": 1689764314000,
        "value": 139.927,
        "_internal_originalTime": 1689764314000
    },
    {
        "time": 1689764315000,
        "value": 139.929,
        "_internal_originalTime": 1689764315000
    },
    {
        "time": 1689764317000,
        "value": 139.922,
        "_internal_originalTime": 1689764317000
    },
    {
        "time": 1689764318000,
        "value": 139.924,
        "_internal_originalTime": 1689764318000
    },
    {
        "time": 1689764320000,
        "value": 139.926,
        "_internal_originalTime": 1689764320000
    },
    {
        "time": 1689764321000,
        "value": 139.928,
        "_internal_originalTime": 1689764321000
    },
    {
        "time": 1689764322000,
        "value": 139.928,
        "_internal_originalTime": 1689764322000
    },
    {
        "time": 1689764324000,
        "value": 139.924,
        "_internal_originalTime": 1689764324000
    },
    {
        "time": 1689764325000,
        "value": 139.927,
        "_internal_originalTime": 1689764325000
    },
    {
        "time": 1689764327000,
        "value": 139.927,
        "_internal_originalTime": 1689764327000
    },
    {
        "time": 1689764329000,
        "value": 139.927,
        "_internal_originalTime": 1689764329000
    },
    {
        "time": 1689764330000,
        "value": 139.928,
        "_internal_originalTime": 1689764330000
    },
    {
        "time": 1689764332000,
        "value": 139.924,
        "_internal_originalTime": 1689764332000
    },
    {
        "time": 1689764333000,
        "value": 139.924,
        "_internal_originalTime": 1689764333000
    },
    {
        "time": 1689764334000,
        "value": 139.924,
        "_internal_originalTime": 1689764334000
    },
    {
        "time": 1689764336000,
        "value": 139.925,
        "_internal_originalTime": 1689764336000
    },
    {
        "time": 1689764338000,
        "value": 139.927,
        "_internal_originalTime": 1689764338000
    },
    {
        "time": 1689764339000,
        "value": 139.928,
        "_internal_originalTime": 1689764339000
    },
    {
        "time": 1689764340000,
        "value": 139.927,
        "_internal_originalTime": 1689764340000
    },
    {
        "time": 1689764341000,
        "value": 139.925,
        "_internal_originalTime": 1689764341000
    },
    {
        "time": 1689764342000,
        "value": 139.929,
        "_internal_originalTime": 1689764342000
    },
    {
        "time": 1689764343000,
        "value": 139.928,
        "_internal_originalTime": 1689764343000
    },
    {
        "time": 1689764344000,
        "value": 139.927,
        "_internal_originalTime": 1689764344000
    },
    {
        "time": 1689764345000,
        "value": 139.925,
        "_internal_originalTime": 1689764345000
    },
    {
        "time": 1689764346000,
        "value": 139.924,
        "_internal_originalTime": 1689764346000
    },
    {
        "time": 1689764347000,
        "value": 139.928,
        "_internal_originalTime": 1689764347000
    },
    {
        "time": 1689764349000,
        "value": 139.929,
        "_internal_originalTime": 1689764349000
    },
    {
        "time": 1689764351000,
        "value": 139.926,
        "_internal_originalTime": 1689764351000
    },
    {
        "time": 1689764352000,
        "value": 139.925,
        "_internal_originalTime": 1689764352000
    },
    {
        "time": 1689764354000,
        "value": 139.926,
        "_internal_originalTime": 1689764354000
    },
    {
        "time": 1689764355000,
        "value": 139.92,
        "_internal_originalTime": 1689764355000
    },
    {
        "time": 1689764356000,
        "value": 139.917,
        "_internal_originalTime": 1689764356000
    },
    {
        "time": 1689764357000,
        "value": 139.917,
        "_internal_originalTime": 1689764357000
    },
    {
        "time": 1689764359000,
        "value": 139.914,
        "_internal_originalTime": 1689764359000
    },
    {
        "time": 1689764360000,
        "value": 139.914,
        "_internal_originalTime": 1689764360000
    },
    {
        "time": 1689764362000,
        "value": 139.907,
        "_internal_originalTime": 1689764362000
    },
    {
        "time": 1689764363000,
        "value": 139.904,
        "_internal_originalTime": 1689764363000
    },
    {
        "time": 1689764364000,
        "value": 139.905,
        "_internal_originalTime": 1689764364000
    },
    {
        "time": 1689764366000,
        "value": 139.9,
        "_internal_originalTime": 1689764366000
    },
    {
        "time": 1689764367000,
        "value": 139.901,
        "_internal_originalTime": 1689764367000
    },
    {
        "time": 1689764369000,
        "value": 139.9,
        "_internal_originalTime": 1689764369000
    },
    {
        "time": 1689764370000,
        "value": 139.901,
        "_internal_originalTime": 1689764370000
    },
    {
        "time": 1689764372000,
        "value": 139.906,
        "_internal_originalTime": 1689764372000
    },
    {
        "time": 1689764373000,
        "value": 139.905,
        "_internal_originalTime": 1689764373000
    },
    {
        "time": 1689764375000,
        "value": 139.904,
        "_internal_originalTime": 1689764375000
    },
    {
        "time": 1689764376000,
        "value": 139.903,
        "_internal_originalTime": 1689764376000
    },
    {
        "time": 1689764377000,
        "value": 139.906,
        "_internal_originalTime": 1689764377000
    },
    {
        "time": 1689764378000,
        "value": 139.905,
        "_internal_originalTime": 1689764378000
    },
    {
        "time": 1689764381000,
        "value": 139.902,
        "_internal_originalTime": 1689764381000
    },
    {
        "time": 1689764385000,
        "value": 139.906,
        "_internal_originalTime": 1689764385000
    },
    {
        "time": 1689764387000,
        "value": 139.9,
        "_internal_originalTime": 1689764387000
    },
    {
        "time": 1689764388000,
        "value": 139.902,
        "_internal_originalTime": 1689764388000
    },
    {
        "time": 1689764390000,
        "value": 139.909,
        "_internal_originalTime": 1689764390000
    },
    {
        "time": 1689764391000,
        "value": 139.908,
        "_internal_originalTime": 1689764391000
    },
    {
        "time": 1689764394000,
        "value": 139.909,
        "_internal_originalTime": 1689764394000
    },
    {
        "time": 1689764396000,
        "value": 139.904,
        "_internal_originalTime": 1689764396000
    },
    {
        "time": 1689764397000,
        "value": 139.914,
        "_internal_originalTime": 1689764397000
    },
    {
        "time": 1689764398000,
        "value": 139.915,
        "_internal_originalTime": 1689764398000
    },
    {
        "time": 1689764400000,
        "value": 139.916,
        "_internal_originalTime": 1689764400000
    },
    {
        "time": 1689764401000,
        "value": 139.915,
        "_internal_originalTime": 1689764401000
    },
    {
        "time": 1689764402000,
        "value": 139.907,
        "_internal_originalTime": 1689764402000
    },
    {
        "time": 1689764403000,
        "value": 139.907,
        "_internal_originalTime": 1689764403000
    },
    {
        "time": 1689764404000,
        "value": 139.907,
        "_internal_originalTime": 1689764404000
    },
    {
        "time": 1689764406000,
        "value": 139.905,
        "_internal_originalTime": 1689764406000
    },
    {
        "time": 1689764407000,
        "value": 139.904,
        "_internal_originalTime": 1689764407000
    },
    {
        "time": 1689764409000,
        "value": 139.904,
        "_internal_originalTime": 1689764409000
    },
    {
        "time": 1689764410000,
        "value": 139.906,
        "_internal_originalTime": 1689764410000
    },
    {
        "time": 1689764413000,
        "value": 139.906,
        "_internal_originalTime": 1689764413000
    },
    {
        "time": 1689764414000,
        "value": 139.903,
        "_internal_originalTime": 1689764414000
    },
    {
        "time": 1689764416000,
        "value": 139.906,
        "_internal_originalTime": 1689764416000
    },
    {
        "time": 1689764418000,
        "value": 139.908,
        "_internal_originalTime": 1689764418000
    },
    {
        "time": 1689764420000,
        "value": 139.91,
        "_internal_originalTime": 1689764420000
    },
    {
        "time": 1689764421000,
        "value": 139.908,
        "_internal_originalTime": 1689764421000
    },
    {
        "time": 1689764422000,
        "value": 139.909,
        "_internal_originalTime": 1689764422000
    },
    {
        "time": 1689764424000,
        "value": 139.91,
        "_internal_originalTime": 1689764424000
    },
    {
        "time": 1689764425000,
        "value": 139.91,
        "_internal_originalTime": 1689764425000
    },
    {
        "time": 1689764427000,
        "value": 139.908,
        "_internal_originalTime": 1689764427000
    },
    {
        "time": 1689764429000,
        "value": 139.908,
        "_internal_originalTime": 1689764429000
    },
    {
        "time": 1689764430000,
        "value": 139.905,
        "_internal_originalTime": 1689764430000
    },
    {
        "time": 1689764432000,
        "value": 139.905,
        "_internal_originalTime": 1689764432000
    },
    {
        "time": 1689764433000,
        "value": 139.907,
        "_internal_originalTime": 1689764433000
    },
    {
        "time": 1689764435000,
        "value": 139.907,
        "_internal_originalTime": 1689764435000
    },
    {
        "time": 1689764436000,
        "value": 139.907,
        "_internal_originalTime": 1689764436000
    },
    {
        "time": 1689764437000,
        "value": 139.903,
        "_internal_originalTime": 1689764437000
    },
    {
        "time": 1689764439000,
        "value": 139.904,
        "_internal_originalTime": 1689764439000
    },
    {
        "time": 1689764440000,
        "value": 139.903,
        "_internal_originalTime": 1689764440000
    },
    {
        "time": 1689764441000,
        "value": 139.905,
        "_internal_originalTime": 1689764441000
    },
    {
        "time": 1689764443000,
        "value": 139.905,
        "_internal_originalTime": 1689764443000
    },
    {
        "time": 1689764445000,
        "value": 139.906,
        "_internal_originalTime": 1689764445000
    },
    {
        "time": 1689764446000,
        "value": 139.905,
        "_internal_originalTime": 1689764446000
    },
    {
        "time": 1689764447000,
        "value": 139.908,
        "_internal_originalTime": 1689764447000
    },
    {
        "time": 1689764449000,
        "value": 139.909,
        "_internal_originalTime": 1689764449000
    },
    {
        "time": 1689764450000,
        "value": 139.908,
        "_internal_originalTime": 1689764450000
    },
    {
        "time": 1689764451000,
        "value": 139.907,
        "_internal_originalTime": 1689764451000
    },
    {
        "time": 1689764453000,
        "value": 139.907,
        "_internal_originalTime": 1689764453000
    },
    {
        "time": 1689764456000,
        "value": 139.907,
        "_internal_originalTime": 1689764456000
    },
    {
        "time": 1689764458000,
        "value": 139.907,
        "_internal_originalTime": 1689764458000
    },
    {
        "time": 1689764459000,
        "value": 139.909,
        "_internal_originalTime": 1689764459000
    },
    {
        "time": 1689764460000,
        "value": 139.908,
        "_internal_originalTime": 1689764460000
    },
    {
        "time": 1689764461000,
        "value": 139.908,
        "_internal_originalTime": 1689764461000
    },
    {
        "time": 1689764463000,
        "value": 139.905,
        "_internal_originalTime": 1689764463000
    },
    {
        "time": 1689764465000,
        "value": 139.907,
        "_internal_originalTime": 1689764465000
    },
    {
        "time": 1689764466000,
        "value": 139.91,
        "_internal_originalTime": 1689764466000
    },
    {
        "time": 1689764467000,
        "value": 139.911,
        "_internal_originalTime": 1689764467000
    },
    {
        "time": 1689764468000,
        "value": 139.908,
        "_internal_originalTime": 1689764468000
    },
    {
        "time": 1689764470000,
        "value": 139.908,
        "_internal_originalTime": 1689764470000
    },
    {
        "time": 1689764471000,
        "value": 139.909,
        "_internal_originalTime": 1689764471000
    },
    {
        "time": 1689764473000,
        "value": 139.904,
        "_internal_originalTime": 1689764473000
    },
    {
        "time": 1689764474000,
        "value": 139.905,
        "_internal_originalTime": 1689764474000
    },
    {
        "time": 1689764475000,
        "value": 139.906,
        "_internal_originalTime": 1689764475000
    },
    {
        "time": 1689764476000,
        "value": 139.909,
        "_internal_originalTime": 1689764476000
    },
    {
        "time": 1689764479000,
        "value": 139.91,
        "_internal_originalTime": 1689764479000
    },
    {
        "time": 1689764480000,
        "value": 139.904,
        "_internal_originalTime": 1689764480000
    },
    {
        "time": 1689764481000,
        "value": 139.908,
        "_internal_originalTime": 1689764481000
    },
    {
        "time": 1689764484000,
        "value": 139.908,
        "_internal_originalTime": 1689764484000
    },
    {
        "time": 1689764486000,
        "value": 139.907,
        "_internal_originalTime": 1689764486000
    },
    {
        "time": 1689764487000,
        "value": 139.908,
        "_internal_originalTime": 1689764487000
    },
    {
        "time": 1689764489000,
        "value": 139.909,
        "_internal_originalTime": 1689764489000
    },
    {
        "time": 1689764490000,
        "value": 139.909,
        "_internal_originalTime": 1689764490000
    },
    {
        "time": 1689764492000,
        "value": 139.904,
        "_internal_originalTime": 1689764492000
    },
    {
        "time": 1689764493000,
        "value": 139.906,
        "_internal_originalTime": 1689764493000
    },
    {
        "time": 1689764494000,
        "value": 139.904,
        "_internal_originalTime": 1689764494000
    },
    {
        "time": 1689764496000,
        "value": 139.901,
        "_internal_originalTime": 1689764496000
    },
    {
        "time": 1689764498000,
        "value": 139.898,
        "_internal_originalTime": 1689764498000
    },
    {
        "time": 1689764500000,
        "value": 139.898,
        "_internal_originalTime": 1689764500000
    },
    {
        "time": 1689764502000,
        "value": 139.898,
        "_internal_originalTime": 1689764502000
    },
    {
        "time": 1689764503000,
        "value": 139.896,
        "_internal_originalTime": 1689764503000
    },
    {
        "time": 1689764504000,
        "value": 139.892,
        "_internal_originalTime": 1689764504000
    },
    {
        "time": 1689764505000,
        "value": 139.888,
        "_internal_originalTime": 1689764505000
    },
    {
        "time": 1689764506000,
        "value": 139.89,
        "_internal_originalTime": 1689764506000
    },
    {
        "time": 1689764508000,
        "value": 139.888,
        "_internal_originalTime": 1689764508000
    },
    {
        "time": 1689764509000,
        "value": 139.889,
        "_internal_originalTime": 1689764509000
    },
    {
        "time": 1689764510000,
        "value": 139.889,
        "_internal_originalTime": 1689764510000
    },
    {
        "time": 1689764512000,
        "value": 139.885,
        "_internal_originalTime": 1689764512000
    },
    {
        "time": 1689764513000,
        "value": 139.887,
        "_internal_originalTime": 1689764513000
    },
    {
        "time": 1689764515000,
        "value": 139.89,
        "_internal_originalTime": 1689764515000
    },
    {
        "time": 1689764517000,
        "value": 139.882,
        "_internal_originalTime": 1689764517000
    },
    {
        "time": 1689764518000,
        "value": 139.885,
        "_internal_originalTime": 1689764518000
    },
    {
        "time": 1689764519000,
        "value": 139.884,
        "_internal_originalTime": 1689764519000
    },
    {
        "time": 1689764520000,
        "value": 139.881,
        "_internal_originalTime": 1689764520000
    },
    {
        "time": 1689764521000,
        "value": 139.882,
        "_internal_originalTime": 1689764521000
    },
    {
        "time": 1689764522000,
        "value": 139.878,
        "_internal_originalTime": 1689764522000
    },
    {
        "time": 1689764523000,
        "value": 139.878,
        "_internal_originalTime": 1689764523000
    },
    {
        "time": 1689764525000,
        "value": 139.88,
        "_internal_originalTime": 1689764525000
    },
    {
        "time": 1689764526000,
        "value": 139.876,
        "_internal_originalTime": 1689764526000
    },
    {
        "time": 1689764527000,
        "value": 139.878,
        "_internal_originalTime": 1689764527000
    },
    {
        "time": 1689764529000,
        "value": 139.876,
        "_internal_originalTime": 1689764529000
    },
    {
        "time": 1689764530000,
        "value": 139.881,
        "_internal_originalTime": 1689764530000
    },
    {
        "time": 1689764531000,
        "value": 139.881,
        "_internal_originalTime": 1689764531000
    },
    {
        "time": 1689764533000,
        "value": 139.869,
        "_internal_originalTime": 1689764533000
    },
    {
        "time": 1689764534000,
        "value": 139.867,
        "_internal_originalTime": 1689764534000
    },
    {
        "time": 1689764535000,
        "value": 139.868,
        "_internal_originalTime": 1689764535000
    },
    {
        "time": 1689764536000,
        "value": 139.867,
        "_internal_originalTime": 1689764536000
    },
    {
        "time": 1689764537000,
        "value": 139.87,
        "_internal_originalTime": 1689764537000
    },
    {
        "time": 1689764538000,
        "value": 139.877,
        "_internal_originalTime": 1689764538000
    },
    {
        "time": 1689764540000,
        "value": 139.88,
        "_internal_originalTime": 1689764540000
    },
    {
        "time": 1689764541000,
        "value": 139.885,
        "_internal_originalTime": 1689764541000
    },
    {
        "time": 1689764542000,
        "value": 139.885,
        "_internal_originalTime": 1689764542000
    },
    {
        "time": 1689764543000,
        "value": 139.888,
        "_internal_originalTime": 1689764543000
    },
    {
        "time": 1689764545000,
        "value": 139.891,
        "_internal_originalTime": 1689764545000
    },
    {
        "time": 1689764546000,
        "value": 139.888,
        "_internal_originalTime": 1689764546000
    },
    {
        "time": 1689764548000,
        "value": 139.888,
        "_internal_originalTime": 1689764548000
    },
    {
        "time": 1689764549000,
        "value": 139.888,
        "_internal_originalTime": 1689764549000
    },
    {
        "time": 1689764550000,
        "value": 139.882,
        "_internal_originalTime": 1689764550000
    },
    {
        "time": 1689764551000,
        "value": 139.887,
        "_internal_originalTime": 1689764551000
    },
    {
        "time": 1689764552000,
        "value": 139.889,
        "_internal_originalTime": 1689764552000
    },
    {
        "time": 1689764553000,
        "value": 139.883,
        "_internal_originalTime": 1689764553000
    },
    {
        "time": 1689764555000,
        "value": 139.885,
        "_internal_originalTime": 1689764555000
    },
    {
        "time": 1689764556000,
        "value": 139.881,
        "_internal_originalTime": 1689764556000
    },
    {
        "time": 1689764557000,
        "value": 139.876,
        "_internal_originalTime": 1689764557000
    },
    {
        "time": 1689764559000,
        "value": 139.875,
        "_internal_originalTime": 1689764559000
    },
    {
        "time": 1689764561000,
        "value": 139.881,
        "_internal_originalTime": 1689764561000
    },
    {
        "time": 1689764562000,
        "value": 139.881,
        "_internal_originalTime": 1689764562000
    },
    {
        "time": 1689764563000,
        "value": 139.876,
        "_internal_originalTime": 1689764563000
    },
    {
        "time": 1689764564000,
        "value": 139.879,
        "_internal_originalTime": 1689764564000
    },
    {
        "time": 1689764565000,
        "value": 139.876,
        "_internal_originalTime": 1689764565000
    },
    {
        "time": 1689764566000,
        "value": 139.879,
        "_internal_originalTime": 1689764566000
    },
    {
        "time": 1689764568000,
        "value": 139.893,
        "_internal_originalTime": 1689764568000
    },
    {
        "time": 1689764569000,
        "value": 139.892,
        "_internal_originalTime": 1689764569000
    },
    {
        "time": 1689764570000,
        "value": 139.895,
        "_internal_originalTime": 1689764570000
    },
    {
        "time": 1689764571000,
        "value": 139.897,
        "_internal_originalTime": 1689764571000
    },
    {
        "time": 1689764573000,
        "value": 139.896,
        "_internal_originalTime": 1689764573000
    },
    {
        "time": 1689764574000,
        "value": 139.899,
        "_internal_originalTime": 1689764574000
    },
    {
        "time": 1689764576000,
        "value": 139.905,
        "_internal_originalTime": 1689764576000
    },
    {
        "time": 1689764577000,
        "value": 139.908,
        "_internal_originalTime": 1689764577000
    },
    {
        "time": 1689764578000,
        "value": 139.905,
        "_internal_originalTime": 1689764578000
    },
    {
        "time": 1689764581000,
        "value": 139.88,
        "_internal_originalTime": 1689764581000
    },
    {
        "time": 1689764582000,
        "value": 139.903,
        "_internal_originalTime": 1689764582000
    },
    {
        "time": 1689764585000,
        "value": 139.907,
        "_internal_originalTime": 1689764585000
    },
    {
        "time": 1689764587000,
        "value": 139.902,
        "_internal_originalTime": 1689764587000
    },
    {
        "time": 1689764588000,
        "value": 139.909,
        "_internal_originalTime": 1689764588000
    },
    {
        "time": 1689764589000,
        "value": 139.913,
        "_internal_originalTime": 1689764589000
    },
    {
        "time": 1689764591000,
        "value": 139.917,
        "_internal_originalTime": 1689764591000
    },
    {
        "time": 1689764593000,
        "value": 139.922,
        "_internal_originalTime": 1689764593000
    },
    {
        "time": 1689764594000,
        "value": 139.928,
        "_internal_originalTime": 1689764594000
    },
    {
        "time": 1689764595000,
        "value": 139.926,
        "_internal_originalTime": 1689764595000
    },
    {
        "time": 1689764597000,
        "value": 139.933,
        "_internal_originalTime": 1689764597000
    },
    {
        "time": 1689764598000,
        "value": 139.932,
        "_internal_originalTime": 1689764598000
    },
    {
        "time": 1689764599000,
        "value": 139.936,
        "_internal_originalTime": 1689764599000
    },
    {
        "time": 1689764601000,
        "value": 139.936,
        "_internal_originalTime": 1689764601000
    },
    {
        "time": 1689764602000,
        "value": 139.935,
        "_internal_originalTime": 1689764602000
    },
    {
        "time": 1689764604000,
        "value": 139.935,
        "_internal_originalTime": 1689764604000
    },
    {
        "time": 1689764605000,
        "value": 139.933,
        "_internal_originalTime": 1689764605000
    },
    {
        "time": 1689764606000,
        "value": 139.933,
        "_internal_originalTime": 1689764606000
    },
    {
        "time": 1689764608000,
        "value": 139.931,
        "_internal_originalTime": 1689764608000
    },
    {
        "time": 1689764610000,
        "value": 139.934,
        "_internal_originalTime": 1689764610000
    },
    {
        "time": 1689764612000,
        "value": 139.935,
        "_internal_originalTime": 1689764612000
    },
    {
        "time": 1689764613000,
        "value": 139.934,
        "_internal_originalTime": 1689764613000
    },
    {
        "time": 1689764614000,
        "value": 139.933,
        "_internal_originalTime": 1689764614000
    },
    {
        "time": 1689764616000,
        "value": 139.934,
        "_internal_originalTime": 1689764616000
    },
    {
        "time": 1689764617000,
        "value": 139.928,
        "_internal_originalTime": 1689764617000
    },
    {
        "time": 1689764618000,
        "value": 139.934,
        "_internal_originalTime": 1689764618000
    },
    {
        "time": 1689764619000,
        "value": 139.932,
        "_internal_originalTime": 1689764619000
    },
    {
        "time": 1689764621000,
        "value": 139.936,
        "_internal_originalTime": 1689764621000
    },
    {
        "time": 1689764623000,
        "value": 139.935,
        "_internal_originalTime": 1689764623000
    },
    {
        "time": 1689764625000,
        "value": 139.934,
        "_internal_originalTime": 1689764625000
    },
    {
        "time": 1689764629000,
        "value": 139.923,
        "_internal_originalTime": 1689764629000
    },
    {
        "time": 1689764630000,
        "value": 139.92,
        "_internal_originalTime": 1689764630000
    },
    {
        "time": 1689764633000,
        "value": 139.919,
        "_internal_originalTime": 1689764633000
    },
    {
        "time": 1689764634000,
        "value": 139.916,
        "_internal_originalTime": 1689764634000
    },
    {
        "time": 1689764635000,
        "value": 139.918,
        "_internal_originalTime": 1689764635000
    },
    {
        "time": 1689764637000,
        "value": 139.915,
        "_internal_originalTime": 1689764637000
    },
    {
        "time": 1689764638000,
        "value": 139.914,
        "_internal_originalTime": 1689764638000
    },
    {
        "time": 1689764639000,
        "value": 139.919,
        "_internal_originalTime": 1689764639000
    },
    {
        "time": 1689764641000,
        "value": 139.92,
        "_internal_originalTime": 1689764641000
    },
    {
        "time": 1689764643000,
        "value": 139.923,
        "_internal_originalTime": 1689764643000
    },
    {
        "time": 1689764644000,
        "value": 139.921,
        "_internal_originalTime": 1689764644000
    },
    {
        "time": 1689764645000,
        "value": 139.92,
        "_internal_originalTime": 1689764645000
    },
    {
        "time": 1689764646000,
        "value": 139.924,
        "_internal_originalTime": 1689764646000
    },
    {
        "time": 1689764648000,
        "value": 139.924,
        "_internal_originalTime": 1689764648000
    },
    {
        "time": 1689764649000,
        "value": 139.926,
        "_internal_originalTime": 1689764649000
    },
    {
        "time": 1689764651000,
        "value": 139.934,
        "_internal_originalTime": 1689764651000
    },
    {
        "time": 1689764652000,
        "value": 139.933,
        "_internal_originalTime": 1689764652000
    },
    {
        "time": 1689764653000,
        "value": 139.934,
        "_internal_originalTime": 1689764653000
    },
    {
        "time": 1689764654000,
        "value": 139.935,
        "_internal_originalTime": 1689764654000
    },
    {
        "time": 1689764655000,
        "value": 139.934,
        "_internal_originalTime": 1689764655000
    },
    {
        "time": 1689764656000,
        "value": 139.932,
        "_internal_originalTime": 1689764656000
    },
    {
        "time": 1689764657000,
        "value": 139.93,
        "_internal_originalTime": 1689764657000
    },
    {
        "time": 1689764659000,
        "value": 139.938,
        "_internal_originalTime": 1689764659000
    },
    {
        "time": 1689764660000,
        "value": 139.932,
        "_internal_originalTime": 1689764660000
    },
    {
        "time": 1689764661000,
        "value": 139.932,
        "_internal_originalTime": 1689764661000
    },
    {
        "time": 1689764662000,
        "value": 139.934,
        "_internal_originalTime": 1689764662000
    },
    {
        "time": 1689764664000,
        "value": 139.935,
        "_internal_originalTime": 1689764664000
    },
    {
        "time": 1689764665000,
        "value": 139.935,
        "_internal_originalTime": 1689764665000
    },
    {
        "time": 1689764667000,
        "value": 139.935,
        "_internal_originalTime": 1689764667000
    },
    {
        "time": 1689764670000,
        "value": 139.929,
        "_internal_originalTime": 1689764670000
    },
    {
        "time": 1689764671000,
        "value": 139.925,
        "_internal_originalTime": 1689764671000
    },
    {
        "time": 1689764677000,
        "value": 139.922,
        "_internal_originalTime": 1689764677000
    },
    {
        "time": 1689764679000,
        "value": 139.926,
        "_internal_originalTime": 1689764679000
    },
    {
        "time": 1689764682000,
        "value": 139.923,
        "_internal_originalTime": 1689764682000
    },
    {
        "time": 1689764683000,
        "value": 139.925,
        "_internal_originalTime": 1689764683000
    },
    {
        "time": 1689764684000,
        "value": 139.927,
        "_internal_originalTime": 1689764684000
    },
    {
        "time": 1689764687000,
        "value": 139.932,
        "_internal_originalTime": 1689764687000
    },
    {
        "time": 1689764688000,
        "value": 139.933,
        "_internal_originalTime": 1689764688000
    },
    {
        "time": 1689764690000,
        "value": 139.929,
        "_internal_originalTime": 1689764690000
    },
    {
        "time": 1689764691000,
        "value": 139.928,
        "_internal_originalTime": 1689764691000
    },
    {
        "time": 1689764692000,
        "value": 139.931,
        "_internal_originalTime": 1689764692000
    },
    {
        "time": 1689764694000,
        "value": 139.933,
        "_internal_originalTime": 1689764694000
    },
    {
        "time": 1689764695000,
        "value": 139.934,
        "_internal_originalTime": 1689764695000
    },
    {
        "time": 1689764697000,
        "value": 139.932,
        "_internal_originalTime": 1689764697000
    },
    {
        "time": 1689764699000,
        "value": 139.936,
        "_internal_originalTime": 1689764699000
    },
    {
        "time": 1689764700000,
        "value": 139.937,
        "_internal_originalTime": 1689764700000
    },
    {
        "time": 1689764701000,
        "value": 139.93,
        "_internal_originalTime": 1689764701000
    },
    {
        "time": 1689764702000,
        "value": 139.932,
        "_internal_originalTime": 1689764702000
    },
    {
        "time": 1689764704000,
        "value": 139.932,
        "_internal_originalTime": 1689764704000
    },
    {
        "time": 1689764705000,
        "value": 139.924,
        "_internal_originalTime": 1689764705000
    },
    {
        "time": 1689764706000,
        "value": 139.926,
        "_internal_originalTime": 1689764706000
    },
    {
        "time": 1689764707000,
        "value": 139.922,
        "_internal_originalTime": 1689764707000
    },
    {
        "time": 1689764709000,
        "value": 139.925,
        "_internal_originalTime": 1689764709000
    },
    {
        "time": 1689764710000,
        "value": 139.928,
        "_internal_originalTime": 1689764710000
    },
    {
        "time": 1689764711000,
        "value": 139.933,
        "_internal_originalTime": 1689764711000
    },
    {
        "time": 1689764713000,
        "value": 139.929,
        "_internal_originalTime": 1689764713000
    },
    {
        "time": 1689764714000,
        "value": 139.93,
        "_internal_originalTime": 1689764714000
    },
    {
        "time": 1689764716000,
        "value": 139.931,
        "_internal_originalTime": 1689764716000
    },
    {
        "time": 1689764717000,
        "value": 139.935,
        "_internal_originalTime": 1689764717000
    },
    {
        "time": 1689764718000,
        "value": 139.929,
        "_internal_originalTime": 1689764718000
    },
    {
        "time": 1689764719000,
        "value": 139.928,
        "_internal_originalTime": 1689764719000
    },
    {
        "time": 1689764721000,
        "value": 139.93,
        "_internal_originalTime": 1689764721000
    },
    {
        "time": 1689764723000,
        "value": 139.936,
        "_internal_originalTime": 1689764723000
    },
    {
        "time": 1689764725000,
        "value": 139.93,
        "_internal_originalTime": 1689764725000
    },
    {
        "time": 1689764726000,
        "value": 139.934,
        "_internal_originalTime": 1689764726000
    },
    {
        "time": 1689764727000,
        "value": 139.93,
        "_internal_originalTime": 1689764727000
    },
    {
        "time": 1689764728000,
        "value": 139.93,
        "_internal_originalTime": 1689764728000
    },
    {
        "time": 1689764731000,
        "value": 139.934,
        "_internal_originalTime": 1689764731000
    },
    {
        "time": 1689764732000,
        "value": 139.934,
        "_internal_originalTime": 1689764732000
    },
    {
        "time": 1689764734000,
        "value": 139.936,
        "_internal_originalTime": 1689764734000
    },
    {
        "time": 1689764735000,
        "value": 139.931,
        "_internal_originalTime": 1689764735000
    },
    {
        "time": 1689764737000,
        "value": 139.93,
        "_internal_originalTime": 1689764737000
    },
    {
        "time": 1689764738000,
        "value": 139.932,
        "_internal_originalTime": 1689764738000
    },
    {
        "time": 1689764739000,
        "value": 139.931,
        "_internal_originalTime": 1689764739000
    },
    {
        "time": 1689764740000,
        "value": 139.931,
        "_internal_originalTime": 1689764740000
    },
    {
        "time": 1689764742000,
        "value": 139.934,
        "_internal_originalTime": 1689764742000
    },
    {
        "time": 1689764744000,
        "value": 139.932,
        "_internal_originalTime": 1689764744000
    },
    {
        "time": 1689764745000,
        "value": 139.935,
        "_internal_originalTime": 1689764745000
    },
    {
        "time": 1689764746000,
        "value": 139.931,
        "_internal_originalTime": 1689764746000
    },
    {
        "time": 1689764747000,
        "value": 139.936,
        "_internal_originalTime": 1689764747000
    },
    {
        "time": 1689764748000,
        "value": 139.93,
        "_internal_originalTime": 1689764748000
    },
    {
        "time": 1689764750000,
        "value": 139.93,
        "_internal_originalTime": 1689764750000
    },
    {
        "time": 1689764751000,
        "value": 139.936,
        "_internal_originalTime": 1689764751000
    },
    {
        "time": 1689764752000,
        "value": 139.933,
        "_internal_originalTime": 1689764752000
    },
    {
        "time": 1689764754000,
        "value": 139.932,
        "_internal_originalTime": 1689764754000
    },
    {
        "time": 1689764755000,
        "value": 139.93,
        "_internal_originalTime": 1689764755000
    },
    {
        "time": 1689764757000,
        "value": 139.93,
        "_internal_originalTime": 1689764757000
    },
    {
        "time": 1689764759000,
        "value": 139.933,
        "_internal_originalTime": 1689764759000
    },
    {
        "time": 1689764760000,
        "value": 139.936,
        "_internal_originalTime": 1689764760000
    },
    {
        "time": 1689764761000,
        "value": 139.936,
        "_internal_originalTime": 1689764761000
    },
    {
        "time": 1689764762000,
        "value": 139.939,
        "_internal_originalTime": 1689764762000
    },
    {
        "time": 1689764763000,
        "value": 139.94,
        "_internal_originalTime": 1689764763000
    },
    {
        "time": 1689764765000,
        "value": 139.939,
        "_internal_originalTime": 1689764765000
    },
    {
        "time": 1689764766000,
        "value": 139.938,
        "_internal_originalTime": 1689764766000
    },
    {
        "time": 1689764767000,
        "value": 139.944,
        "_internal_originalTime": 1689764767000
    },
    {
        "time": 1689764768000,
        "value": 139.945,
        "_internal_originalTime": 1689764768000
    },
    {
        "time": 1689764769000,
        "value": 139.944,
        "_internal_originalTime": 1689764769000
    },
    {
        "time": 1689764771000,
        "value": 139.945,
        "_internal_originalTime": 1689764771000
    },
    {
        "time": 1689764772000,
        "value": 139.944,
        "_internal_originalTime": 1689764772000
    },
    {
        "time": 1689764774000,
        "value": 139.946,
        "_internal_originalTime": 1689764774000
    },
    {
        "time": 1689764775000,
        "value": 139.944,
        "_internal_originalTime": 1689764775000
    },
    {
        "time": 1689764777000,
        "value": 139.944,
        "_internal_originalTime": 1689764777000
    },
    {
        "time": 1689764778000,
        "value": 139.94,
        "_internal_originalTime": 1689764778000
    },
    {
        "time": 1689764779000,
        "value": 139.943,
        "_internal_originalTime": 1689764779000
    },
    {
        "time": 1689764781000,
        "value": 139.946,
        "_internal_originalTime": 1689764781000
    },
    {
        "time": 1689764782000,
        "value": 139.943,
        "_internal_originalTime": 1689764782000
    },
    {
        "time": 1689764783000,
        "value": 139.945,
        "_internal_originalTime": 1689764783000
    },
    {
        "time": 1689764785000,
        "value": 139.944,
        "_internal_originalTime": 1689764785000
    },
    {
        "time": 1689764786000,
        "value": 139.942,
        "_internal_originalTime": 1689764786000
    },
    {
        "time": 1689764788000,
        "value": 139.944,
        "_internal_originalTime": 1689764788000
    },
    {
        "time": 1689764790000,
        "value": 139.942,
        "_internal_originalTime": 1689764790000
    },
    {
        "time": 1689764791000,
        "value": 139.943,
        "_internal_originalTime": 1689764791000
    },
    {
        "time": 1689764792000,
        "value": 139.936,
        "_internal_originalTime": 1689764792000
    },
    {
        "time": 1689764794000,
        "value": 139.939,
        "_internal_originalTime": 1689764794000
    },
    {
        "time": 1689764795000,
        "value": 139.945,
        "_internal_originalTime": 1689764795000
    },
    {
        "time": 1689764796000,
        "value": 139.944,
        "_internal_originalTime": 1689764796000
    },
    {
        "time": 1689764798000,
        "value": 139.943,
        "_internal_originalTime": 1689764798000
    },
    {
        "time": 1689764800000,
        "value": 139.95,
        "_internal_originalTime": 1689764800000
    },
    {
        "time": 1689764802000,
        "value": 139.952,
        "_internal_originalTime": 1689764802000
    },
    {
        "time": 1689764803000,
        "value": 139.95,
        "_internal_originalTime": 1689764803000
    },
    {
        "time": 1689764805000,
        "value": 139.956,
        "_internal_originalTime": 1689764805000
    },
    {
        "time": 1689764806000,
        "value": 139.951,
        "_internal_originalTime": 1689764806000
    },
    {
        "time": 1689764808000,
        "value": 139.95,
        "_internal_originalTime": 1689764808000
    },
    {
        "time": 1689764809000,
        "value": 139.954,
        "_internal_originalTime": 1689764809000
    },
    {
        "time": 1689764810000,
        "value": 139.954,
        "_internal_originalTime": 1689764810000
    },
    {
        "time": 1689764812000,
        "value": 139.954,
        "_internal_originalTime": 1689764812000
    },
    {
        "time": 1689764813000,
        "value": 139.951,
        "_internal_originalTime": 1689764813000
    },
    {
        "time": 1689764815000,
        "value": 139.954,
        "_internal_originalTime": 1689764815000
    },
    {
        "time": 1689764817000,
        "value": 139.953,
        "_internal_originalTime": 1689764817000
    },
    {
        "time": 1689764818000,
        "value": 139.954,
        "_internal_originalTime": 1689764818000
    },
    {
        "time": 1689764819000,
        "value": 139.953,
        "_internal_originalTime": 1689764819000
    },
    {
        "time": 1689764820000,
        "value": 139.93,
        "_internal_originalTime": 1689764820000
    },
    {
        "time": 1689764821000,
        "value": 139.95,
        "_internal_originalTime": 1689764821000
    },
    {
        "time": 1689764825000,
        "value": 139.951,
        "_internal_originalTime": 1689764825000
    },
    {
        "time": 1689764826000,
        "value": 139.957,
        "_internal_originalTime": 1689764826000
    },
    {
        "time": 1689764828000,
        "value": 139.956,
        "_internal_originalTime": 1689764828000
    },
    {
        "time": 1689764829000,
        "value": 139.956,
        "_internal_originalTime": 1689764829000
    },
    {
        "time": 1689764831000,
        "value": 139.954,
        "_internal_originalTime": 1689764831000
    },
    {
        "time": 1689764833000,
        "value": 139.951,
        "_internal_originalTime": 1689764833000
    },
    {
        "time": 1689764834000,
        "value": 139.954,
        "_internal_originalTime": 1689764834000
    },
    {
        "time": 1689764836000,
        "value": 139.954,
        "_internal_originalTime": 1689764836000
    },
    {
        "time": 1689764837000,
        "value": 139.951,
        "_internal_originalTime": 1689764837000
    },
    {
        "time": 1689764839000,
        "value": 139.947,
        "_internal_originalTime": 1689764839000
    },
    {
        "time": 1689764840000,
        "value": 139.945,
        "_internal_originalTime": 1689764840000
    },
    {
        "time": 1689764842000,
        "value": 139.943,
        "_internal_originalTime": 1689764842000
    },
    {
        "time": 1689764843000,
        "value": 139.942,
        "_internal_originalTime": 1689764843000
    },
    {
        "time": 1689764844000,
        "value": 139.933,
        "_internal_originalTime": 1689764844000
    },
    {
        "time": 1689764846000,
        "value": 139.937,
        "_internal_originalTime": 1689764846000
    },
    {
        "time": 1689764847000,
        "value": 139.935,
        "_internal_originalTime": 1689764847000
    },
    {
        "time": 1689764848000,
        "value": 139.934,
        "_internal_originalTime": 1689764848000
    },
    {
        "time": 1689764849000,
        "value": 139.925,
        "_internal_originalTime": 1689764849000
    },
    {
        "time": 1689764850000,
        "value": 139.924,
        "_internal_originalTime": 1689764850000
    },
    {
        "time": 1689764851000,
        "value": 139.928,
        "_internal_originalTime": 1689764851000
    },
    {
        "time": 1689764852000,
        "value": 139.927,
        "_internal_originalTime": 1689764852000
    },
    {
        "time": 1689764853000,
        "value": 139.925,
        "_internal_originalTime": 1689764853000
    },
    {
        "time": 1689764855000,
        "value": 139.924,
        "_internal_originalTime": 1689764855000
    },
    {
        "time": 1689764856000,
        "value": 139.928,
        "_internal_originalTime": 1689764856000
    },
    {
        "time": 1689764857000,
        "value": 139.929,
        "_internal_originalTime": 1689764857000
    },
    {
        "time": 1689764858000,
        "value": 139.934,
        "_internal_originalTime": 1689764858000
    },
    {
        "time": 1689764860000,
        "value": 139.939,
        "_internal_originalTime": 1689764860000
    },
    {
        "time": 1689764862000,
        "value": 139.941,
        "_internal_originalTime": 1689764862000
    },
    {
        "time": 1689764863000,
        "value": 139.936,
        "_internal_originalTime": 1689764863000
    },
    {
        "time": 1689764865000,
        "value": 139.939,
        "_internal_originalTime": 1689764865000
    },
    {
        "time": 1689764868000,
        "value": 139.936,
        "_internal_originalTime": 1689764868000
    },
    {
        "time": 1689764869000,
        "value": 139.94,
        "_internal_originalTime": 1689764869000
    },
    {
        "time": 1689764870000,
        "value": 139.94,
        "_internal_originalTime": 1689764870000
    },
    {
        "time": 1689764871000,
        "value": 139.939,
        "_internal_originalTime": 1689764871000
    },
    {
        "time": 1689764872000,
        "value": 139.939,
        "_internal_originalTime": 1689764872000
    },
    {
        "time": 1689764874000,
        "value": 139.935,
        "_internal_originalTime": 1689764874000
    },
    {
        "time": 1689764875000,
        "value": 139.935,
        "_internal_originalTime": 1689764875000
    },
    {
        "time": 1689764877000,
        "value": 139.936,
        "_internal_originalTime": 1689764877000
    },
    {
        "time": 1689764878000,
        "value": 139.939,
        "_internal_originalTime": 1689764878000
    },
    {
        "time": 1689764879000,
        "value": 139.938,
        "_internal_originalTime": 1689764879000
    },
    {
        "time": 1689764880000,
        "value": 139.935,
        "_internal_originalTime": 1689764880000
    },
    {
        "time": 1689764881000,
        "value": 139.939,
        "_internal_originalTime": 1689764881000
    },
    {
        "time": 1689764882000,
        "value": 139.937,
        "_internal_originalTime": 1689764882000
    },
    {
        "time": 1689764883000,
        "value": 139.938,
        "_internal_originalTime": 1689764883000
    },
    {
        "time": 1689764884000,
        "value": 139.937,
        "_internal_originalTime": 1689764884000
    },
    {
        "time": 1689764885000,
        "value": 139.942,
        "_internal_originalTime": 1689764885000
    },
    {
        "time": 1689764887000,
        "value": 139.943,
        "_internal_originalTime": 1689764887000
    },
    {
        "time": 1689764888000,
        "value": 139.943,
        "_internal_originalTime": 1689764888000
    },
    {
        "time": 1689764889000,
        "value": 139.945,
        "_internal_originalTime": 1689764889000
    },
    {
        "time": 1689764890000,
        "value": 139.947,
        "_internal_originalTime": 1689764890000
    },
    {
        "time": 1689764892000,
        "value": 139.944,
        "_internal_originalTime": 1689764892000
    },
    {
        "time": 1689764894000,
        "value": 139.948,
        "_internal_originalTime": 1689764894000
    },
    {
        "time": 1689764897000,
        "value": 139.945,
        "_internal_originalTime": 1689764897000
    },
    {
        "time": 1689764898000,
        "value": 139.944,
        "_internal_originalTime": 1689764898000
    },
    {
        "time": 1689764899000,
        "value": 139.944,
        "_internal_originalTime": 1689764899000
    },
    {
        "time": 1689764900000,
        "value": 139.947,
        "_internal_originalTime": 1689764900000
    },
    {
        "time": 1689764902000,
        "value": 139.945,
        "_internal_originalTime": 1689764902000
    },
    {
        "time": 1689764904000,
        "value": 139.946,
        "_internal_originalTime": 1689764904000
    },
    {
        "time": 1689764905000,
        "value": 139.946,
        "_internal_originalTime": 1689764905000
    },
    {
        "time": 1689764907000,
        "value": 139.945,
        "_internal_originalTime": 1689764907000
    },
    {
        "time": 1689764908000,
        "value": 139.943,
        "_internal_originalTime": 1689764908000
    },
    {
        "time": 1689764910000,
        "value": 139.945,
        "_internal_originalTime": 1689764910000
    },
    {
        "time": 1689764911000,
        "value": 139.934,
        "_internal_originalTime": 1689764911000
    },
    {
        "time": 1689764912000,
        "value": 139.937,
        "_internal_originalTime": 1689764912000
    },
    {
        "time": 1689764914000,
        "value": 139.934,
        "_internal_originalTime": 1689764914000
    },
    {
        "time": 1689764915000,
        "value": 139.942,
        "_internal_originalTime": 1689764915000
    },
    {
        "time": 1689764917000,
        "value": 139.936,
        "_internal_originalTime": 1689764917000
    },
    {
        "time": 1689764918000,
        "value": 139.936,
        "_internal_originalTime": 1689764918000
    },
    {
        "time": 1689764919000,
        "value": 139.938,
        "_internal_originalTime": 1689764919000
    },
    {
        "time": 1689764921000,
        "value": 139.938,
        "_internal_originalTime": 1689764921000
    },
    {
        "time": 1689764924000,
        "value": 139.936,
        "_internal_originalTime": 1689764924000
    },
    {
        "time": 1689764925000,
        "value": 139.945,
        "_internal_originalTime": 1689764925000
    },
    {
        "time": 1689764927000,
        "value": 139.947,
        "_internal_originalTime": 1689764927000
    },
    {
        "time": 1689764928000,
        "value": 139.947,
        "_internal_originalTime": 1689764928000
    },
    {
        "time": 1689764930000,
        "value": 139.947,
        "_internal_originalTime": 1689764930000
    },
    {
        "time": 1689764931000,
        "value": 139.949,
        "_internal_originalTime": 1689764931000
    },
    {
        "time": 1689764932000,
        "value": 139.949,
        "_internal_originalTime": 1689764932000
    },
    {
        "time": 1689764934000,
        "value": 139.947,
        "_internal_originalTime": 1689764934000
    },
    {
        "time": 1689764936000,
        "value": 139.949,
        "_internal_originalTime": 1689764936000
    },
    {
        "time": 1689764937000,
        "value": 139.946,
        "_internal_originalTime": 1689764937000
    },
    {
        "time": 1689764938000,
        "value": 139.946,
        "_internal_originalTime": 1689764938000
    },
    {
        "time": 1689764939000,
        "value": 139.944,
        "_internal_originalTime": 1689764939000
    },
    {
        "time": 1689764940000,
        "value": 139.942,
        "_internal_originalTime": 1689764940000
    },
    {
        "time": 1689764941000,
        "value": 139.92,
        "_internal_originalTime": 1689764941000
    },
    {
        "time": 1689764942000,
        "value": 139.946,
        "_internal_originalTime": 1689764942000
    },
    {
        "time": 1689764943000,
        "value": 139.942,
        "_internal_originalTime": 1689764943000
    },
    {
        "time": 1689764944000,
        "value": 139.946,
        "_internal_originalTime": 1689764944000
    },
    {
        "time": 1689764945000,
        "value": 139.947,
        "_internal_originalTime": 1689764945000
    },
    {
        "time": 1689764947000,
        "value": 139.943,
        "_internal_originalTime": 1689764947000
    },
    {
        "time": 1689764948000,
        "value": 139.945,
        "_internal_originalTime": 1689764948000
    },
    {
        "time": 1689764949000,
        "value": 139.944,
        "_internal_originalTime": 1689764949000
    },
    {
        "time": 1689764950000,
        "value": 139.949,
        "_internal_originalTime": 1689764950000
    },
    {
        "time": 1689764951000,
        "value": 139.944,
        "_internal_originalTime": 1689764951000
    },
    {
        "time": 1689764953000,
        "value": 139.942,
        "_internal_originalTime": 1689764953000
    },
    {
        "time": 1689764956000,
        "value": 139.943,
        "_internal_originalTime": 1689764956000
    },
    {
        "time": 1689764957000,
        "value": 139.946,
        "_internal_originalTime": 1689764957000
    },
    {
        "time": 1689764958000,
        "value": 139.952,
        "_internal_originalTime": 1689764958000
    },
    {
        "time": 1689764959000,
        "value": 139.953,
        "_internal_originalTime": 1689764959000
    },
    {
        "time": 1689764960000,
        "value": 139.953,
        "_internal_originalTime": 1689764960000
    },
    {
        "time": 1689764962000,
        "value": 139.954,
        "_internal_originalTime": 1689764962000
    },
    {
        "time": 1689764963000,
        "value": 139.953,
        "_internal_originalTime": 1689764963000
    },
    {
        "time": 1689764964000,
        "value": 139.955,
        "_internal_originalTime": 1689764964000
    },
    {
        "time": 1689764966000,
        "value": 139.957,
        "_internal_originalTime": 1689764966000
    },
    {
        "time": 1689764967000,
        "value": 139.954,
        "_internal_originalTime": 1689764967000
    },
    {
        "time": 1689764968000,
        "value": 139.955,
        "_internal_originalTime": 1689764968000
    },
    {
        "time": 1689764969000,
        "value": 139.954,
        "_internal_originalTime": 1689764969000
    },
    {
        "time": 1689764970000,
        "value": 139.953,
        "_internal_originalTime": 1689764970000
    },
    {
        "time": 1689764971000,
        "value": 139.954,
        "_internal_originalTime": 1689764971000
    },
    {
        "time": 1689764972000,
        "value": 139.957,
        "_internal_originalTime": 1689764972000
    },
    {
        "time": 1689764974000,
        "value": 139.953,
        "_internal_originalTime": 1689764974000
    },
    {
        "time": 1689764975000,
        "value": 139.951,
        "_internal_originalTime": 1689764975000
    },
    {
        "time": 1689764977000,
        "value": 139.952,
        "_internal_originalTime": 1689764977000
    },
    {
        "time": 1689764978000,
        "value": 139.953,
        "_internal_originalTime": 1689764978000
    },
    {
        "time": 1689764979000,
        "value": 139.953,
        "_internal_originalTime": 1689764979000
    },
    {
        "time": 1689764980000,
        "value": 139.953,
        "_internal_originalTime": 1689764980000
    },
    {
        "time": 1689764982000,
        "value": 139.952,
        "_internal_originalTime": 1689764982000
    },
    {
        "time": 1689764983000,
        "value": 139.953,
        "_internal_originalTime": 1689764983000
    },
    {
        "time": 1689764984000,
        "value": 139.953,
        "_internal_originalTime": 1689764984000
    },
    {
        "time": 1689764986000,
        "value": 139.952,
        "_internal_originalTime": 1689764986000
    },
    {
        "time": 1689764987000,
        "value": 139.954,
        "_internal_originalTime": 1689764987000
    },
    {
        "time": 1689764989000,
        "value": 139.955,
        "_internal_originalTime": 1689764989000
    },
    {
        "time": 1689764990000,
        "value": 139.954,
        "_internal_originalTime": 1689764990000
    },
    {
        "time": 1689764991000,
        "value": 139.952,
        "_internal_originalTime": 1689764991000
    },
    {
        "time": 1689764992000,
        "value": 139.951,
        "_internal_originalTime": 1689764992000
    },
    {
        "time": 1689764994000,
        "value": 139.952,
        "_internal_originalTime": 1689764994000
    },
    {
        "time": 1689764995000,
        "value": 139.95,
        "_internal_originalTime": 1689764995000
    },
    {
        "time": 1689764997000,
        "value": 139.954,
        "_internal_originalTime": 1689764997000
    },
    {
        "time": 1689764998000,
        "value": 139.95,
        "_internal_originalTime": 1689764998000
    },
    {
        "time": 1689764999000,
        "value": 139.951,
        "_internal_originalTime": 1689764999000
    },
    {
        "time": 1689765001000,
        "value": 139.947,
        "_internal_originalTime": 1689765001000
    },
    {
        "time": 1689765002000,
        "value": 139.943,
        "_internal_originalTime": 1689765002000
    },
    {
        "time": 1689765004000,
        "value": 139.952,
        "_internal_originalTime": 1689765004000
    },
    {
        "time": 1689765005000,
        "value": 139.953,
        "_internal_originalTime": 1689765005000
    },
    {
        "time": 1689765007000,
        "value": 139.948,
        "_internal_originalTime": 1689765007000
    },
    {
        "time": 1689765008000,
        "value": 139.948,
        "_internal_originalTime": 1689765008000
    },
    {
        "time": 1689765009000,
        "value": 139.953,
        "_internal_originalTime": 1689765009000
    },
    {
        "time": 1689765010000,
        "value": 139.952,
        "_internal_originalTime": 1689765010000
    },
    {
        "time": 1689765011000,
        "value": 139.951,
        "_internal_originalTime": 1689765011000
    },
    {
        "time": 1689765013000,
        "value": 139.95,
        "_internal_originalTime": 1689765013000
    },
    {
        "time": 1689765014000,
        "value": 139.95,
        "_internal_originalTime": 1689765014000
    },
    {
        "time": 1689765015000,
        "value": 139.954,
        "_internal_originalTime": 1689765015000
    },
    {
        "time": 1689765016000,
        "value": 139.95,
        "_internal_originalTime": 1689765016000
    },
    {
        "time": 1689765017000,
        "value": 139.954,
        "_internal_originalTime": 1689765017000
    },
    {
        "time": 1689765018000,
        "value": 139.952,
        "_internal_originalTime": 1689765018000
    },
    {
        "time": 1689765020000,
        "value": 139.948,
        "_internal_originalTime": 1689765020000
    },
    {
        "time": 1689765022000,
        "value": 139.945,
        "_internal_originalTime": 1689765022000
    },
    {
        "time": 1689765026000,
        "value": 139.944,
        "_internal_originalTime": 1689765026000
    },
    {
        "time": 1689765028000,
        "value": 139.942,
        "_internal_originalTime": 1689765028000
    },
    {
        "time": 1689765029000,
        "value": 139.942,
        "_internal_originalTime": 1689765029000
    },
    {
        "time": 1689765030000,
        "value": 139.943,
        "_internal_originalTime": 1689765030000
    },
    {
        "time": 1689765031000,
        "value": 139.945,
        "_internal_originalTime": 1689765031000
    },
    {
        "time": 1689765032000,
        "value": 139.946,
        "_internal_originalTime": 1689765032000
    },
    {
        "time": 1689765033000,
        "value": 139.947,
        "_internal_originalTime": 1689765033000
    },
    {
        "time": 1689765035000,
        "value": 139.947,
        "_internal_originalTime": 1689765035000
    },
    {
        "time": 1689765036000,
        "value": 139.941,
        "_internal_originalTime": 1689765036000
    },
    {
        "time": 1689765037000,
        "value": 139.942,
        "_internal_originalTime": 1689765037000
    },
    {
        "time": 1689765039000,
        "value": 139.95,
        "_internal_originalTime": 1689765039000
    },
    {
        "time": 1689765040000,
        "value": 139.952,
        "_internal_originalTime": 1689765040000
    },
    {
        "time": 1689765042000,
        "value": 139.951,
        "_internal_originalTime": 1689765042000
    },
    {
        "time": 1689765043000,
        "value": 139.953,
        "_internal_originalTime": 1689765043000
    },
    {
        "time": 1689765044000,
        "value": 139.952,
        "_internal_originalTime": 1689765044000
    },
    {
        "time": 1689765045000,
        "value": 139.952,
        "_internal_originalTime": 1689765045000
    },
    {
        "time": 1689765046000,
        "value": 139.953,
        "_internal_originalTime": 1689765046000
    },
    {
        "time": 1689765047000,
        "value": 139.953,
        "_internal_originalTime": 1689765047000
    },
    {
        "time": 1689765048000,
        "value": 139.954,
        "_internal_originalTime": 1689765048000
    },
    {
        "time": 1689765050000,
        "value": 139.951,
        "_internal_originalTime": 1689765050000
    },
    {
        "time": 1689765051000,
        "value": 139.942,
        "_internal_originalTime": 1689765051000
    },
    {
        "time": 1689765052000,
        "value": 139.944,
        "_internal_originalTime": 1689765052000
    },
    {
        "time": 1689765053000,
        "value": 139.953,
        "_internal_originalTime": 1689765053000
    },
    {
        "time": 1689765055000,
        "value": 139.948,
        "_internal_originalTime": 1689765055000
    },
    {
        "time": 1689765056000,
        "value": 139.952,
        "_internal_originalTime": 1689765056000
    },
    {
        "time": 1689765057000,
        "value": 139.954,
        "_internal_originalTime": 1689765057000
    },
    {
        "time": 1689765058000,
        "value": 139.953,
        "_internal_originalTime": 1689765058000
    },
    {
        "time": 1689765059000,
        "value": 139.952,
        "_internal_originalTime": 1689765059000
    },
    {
        "time": 1689765061000,
        "value": 139.95,
        "_internal_originalTime": 1689765061000
    },
    {
        "time": 1689765066000,
        "value": 139.952,
        "_internal_originalTime": 1689765066000
    },
    {
        "time": 1689765067000,
        "value": 139.951,
        "_internal_originalTime": 1689765067000
    },
    {
        "time": 1689765068000,
        "value": 139.953,
        "_internal_originalTime": 1689765068000
    },
    {
        "time": 1689765071000,
        "value": 139.951,
        "_internal_originalTime": 1689765071000
    },
    {
        "time": 1689765074000,
        "value": 139.95,
        "_internal_originalTime": 1689765074000
    },
    {
        "time": 1689765076000,
        "value": 139.95,
        "_internal_originalTime": 1689765076000
    },
    {
        "time": 1689765078000,
        "value": 139.95,
        "_internal_originalTime": 1689765078000
    },
    {
        "time": 1689765079000,
        "value": 139.948,
        "_internal_originalTime": 1689765079000
    },
    {
        "time": 1689765081000,
        "value": 139.95,
        "_internal_originalTime": 1689765081000
    },
    {
        "time": 1689765082000,
        "value": 139.947,
        "_internal_originalTime": 1689765082000
    },
    {
        "time": 1689765083000,
        "value": 139.945,
        "_internal_originalTime": 1689765083000
    },
    {
        "time": 1689765084000,
        "value": 139.953,
        "_internal_originalTime": 1689765084000
    },
    {
        "time": 1689765085000,
        "value": 139.953,
        "_internal_originalTime": 1689765085000
    },
    {
        "time": 1689765087000,
        "value": 139.952,
        "_internal_originalTime": 1689765087000
    },
    {
        "time": 1689765088000,
        "value": 139.952,
        "_internal_originalTime": 1689765088000
    },
    {
        "time": 1689765091000,
        "value": 139.949,
        "_internal_originalTime": 1689765091000
    },
    {
        "time": 1689765092000,
        "value": 139.953,
        "_internal_originalTime": 1689765092000
    },
    {
        "time": 1689765097000,
        "value": 139.947,
        "_internal_originalTime": 1689765097000
    },
    {
        "time": 1689765098000,
        "value": 139.954,
        "_internal_originalTime": 1689765098000
    },
    {
        "time": 1689765100000,
        "value": 139.954,
        "_internal_originalTime": 1689765100000
    },
    {
        "time": 1689765101000,
        "value": 139.954,
        "_internal_originalTime": 1689765101000
    },
    {
        "time": 1689765102000,
        "value": 139.955,
        "_internal_originalTime": 1689765102000
    },
    {
        "time": 1689765104000,
        "value": 139.955,
        "_internal_originalTime": 1689765104000
    },
    {
        "time": 1689765105000,
        "value": 139.952,
        "_internal_originalTime": 1689765105000
    },
    {
        "time": 1689765106000,
        "value": 139.95,
        "_internal_originalTime": 1689765106000
    },
    {
        "time": 1689765108000,
        "value": 139.952,
        "_internal_originalTime": 1689765108000
    },
    {
        "time": 1689765109000,
        "value": 139.952,
        "_internal_originalTime": 1689765109000
    },
    {
        "time": 1689765110000,
        "value": 139.954,
        "_internal_originalTime": 1689765110000
    },
    {
        "time": 1689765111000,
        "value": 139.948,
        "_internal_originalTime": 1689765111000
    },
    {
        "time": 1689765114000,
        "value": 139.952,
        "_internal_originalTime": 1689765114000
    },
    {
        "time": 1689765115000,
        "value": 139.95,
        "_internal_originalTime": 1689765115000
    },
    {
        "time": 1689765117000,
        "value": 139.955,
        "_internal_originalTime": 1689765117000
    },
    {
        "time": 1689765119000,
        "value": 139.955,
        "_internal_originalTime": 1689765119000
    },
    {
        "time": 1689765120000,
        "value": 139.958,
        "_internal_originalTime": 1689765120000
    },
    {
        "time": 1689765121000,
        "value": 139.966,
        "_internal_originalTime": 1689765121000
    },
    {
        "time": 1689765122000,
        "value": 139.958,
        "_internal_originalTime": 1689765122000
    },
    {
        "time": 1689765123000,
        "value": 139.963,
        "_internal_originalTime": 1689765123000
    },
    {
        "time": 1689765125000,
        "value": 139.964,
        "_internal_originalTime": 1689765125000
    },
    {
        "time": 1689765126000,
        "value": 139.96,
        "_internal_originalTime": 1689765126000
    },
    {
        "time": 1689765127000,
        "value": 139.957,
        "_internal_originalTime": 1689765127000
    },
    {
        "time": 1689765129000,
        "value": 139.964,
        "_internal_originalTime": 1689765129000
    },
    {
        "time": 1689765130000,
        "value": 139.961,
        "_internal_originalTime": 1689765130000
    },
    {
        "time": 1689765131000,
        "value": 139.963,
        "_internal_originalTime": 1689765131000
    },
    {
        "time": 1689765133000,
        "value": 139.964,
        "_internal_originalTime": 1689765133000
    },
    {
        "time": 1689765134000,
        "value": 139.962,
        "_internal_originalTime": 1689765134000
    },
    {
        "time": 1689765135000,
        "value": 139.961,
        "_internal_originalTime": 1689765135000
    },
    {
        "time": 1689765136000,
        "value": 139.96,
        "_internal_originalTime": 1689765136000
    },
    {
        "time": 1689765138000,
        "value": 139.976,
        "_internal_originalTime": 1689765138000
    },
    {
        "time": 1689765139000,
        "value": 139.975,
        "_internal_originalTime": 1689765139000
    },
    {
        "time": 1689765140000,
        "value": 139.973,
        "_internal_originalTime": 1689765140000
    },
    {
        "time": 1689765142000,
        "value": 139.97,
        "_internal_originalTime": 1689765142000
    },
    {
        "time": 1689765143000,
        "value": 139.971,
        "_internal_originalTime": 1689765143000
    },
    {
        "time": 1689765144000,
        "value": 139.969,
        "_internal_originalTime": 1689765144000
    },
    {
        "time": 1689765146000,
        "value": 139.972,
        "_internal_originalTime": 1689765146000
    },
    {
        "time": 1689765148000,
        "value": 139.973,
        "_internal_originalTime": 1689765148000
    },
    {
        "time": 1689765149000,
        "value": 139.971,
        "_internal_originalTime": 1689765149000
    },
    {
        "time": 1689765150000,
        "value": 139.974,
        "_internal_originalTime": 1689765150000
    },
    {
        "time": 1689765151000,
        "value": 139.97,
        "_internal_originalTime": 1689765151000
    },
    {
        "time": 1689765152000,
        "value": 139.974,
        "_internal_originalTime": 1689765152000
    },
    {
        "time": 1689765153000,
        "value": 139.974,
        "_internal_originalTime": 1689765153000
    },
    {
        "time": 1689765155000,
        "value": 139.974,
        "_internal_originalTime": 1689765155000
    },
    {
        "time": 1689765157000,
        "value": 139.971,
        "_internal_originalTime": 1689765157000
    },
    {
        "time": 1689765159000,
        "value": 139.963,
        "_internal_originalTime": 1689765159000
    },
    {
        "time": 1689765161000,
        "value": 139.963,
        "_internal_originalTime": 1689765161000
    },
    {
        "time": 1689765162000,
        "value": 139.963,
        "_internal_originalTime": 1689765162000
    },
    {
        "time": 1689765163000,
        "value": 139.963,
        "_internal_originalTime": 1689765163000
    },
    {
        "time": 1689765165000,
        "value": 139.963,
        "_internal_originalTime": 1689765165000
    },
    {
        "time": 1689765166000,
        "value": 139.963,
        "_internal_originalTime": 1689765166000
    },
    {
        "time": 1689765168000,
        "value": 139.965,
        "_internal_originalTime": 1689765168000
    },
    {
        "time": 1689765169000,
        "value": 139.963,
        "_internal_originalTime": 1689765169000
    },
    {
        "time": 1689765170000,
        "value": 139.965,
        "_internal_originalTime": 1689765170000
    },
    {
        "time": 1689765171000,
        "value": 139.965,
        "_internal_originalTime": 1689765171000
    },
    {
        "time": 1689765174000,
        "value": 139.961,
        "_internal_originalTime": 1689765174000
    },
    {
        "time": 1689765175000,
        "value": 139.96,
        "_internal_originalTime": 1689765175000
    },
    {
        "time": 1689765177000,
        "value": 139.964,
        "_internal_originalTime": 1689765177000
    },
    {
        "time": 1689765178000,
        "value": 139.966,
        "_internal_originalTime": 1689765178000
    },
    {
        "time": 1689765179000,
        "value": 139.964,
        "_internal_originalTime": 1689765179000
    },
    {
        "time": 1689765180000,
        "value": 139.963,
        "_internal_originalTime": 1689765180000
    },
    {
        "time": 1689765181000,
        "value": 139.964,
        "_internal_originalTime": 1689765181000
    },
    {
        "time": 1689765182000,
        "value": 139.962,
        "_internal_originalTime": 1689765182000
    },
    {
        "time": 1689765183000,
        "value": 139.964,
        "_internal_originalTime": 1689765183000
    },
    {
        "time": 1689765185000,
        "value": 139.964,
        "_internal_originalTime": 1689765185000
    },
    {
        "time": 1689765186000,
        "value": 139.96,
        "_internal_originalTime": 1689765186000
    },
    {
        "time": 1689765187000,
        "value": 139.976,
        "_internal_originalTime": 1689765187000
    },
    {
        "time": 1689765188000,
        "value": 139.973,
        "_internal_originalTime": 1689765188000
    },
    {
        "time": 1689765189000,
        "value": 139.973,
        "_internal_originalTime": 1689765189000
    },
    {
        "time": 1689765191000,
        "value": 139.973,
        "_internal_originalTime": 1689765191000
    },
    {
        "time": 1689765192000,
        "value": 139.975,
        "_internal_originalTime": 1689765192000
    },
    {
        "time": 1689765193000,
        "value": 139.975,
        "_internal_originalTime": 1689765193000
    },
    {
        "time": 1689765195000,
        "value": 139.978,
        "_internal_originalTime": 1689765195000
    },
    {
        "time": 1689765197000,
        "value": 139.974,
        "_internal_originalTime": 1689765197000
    },
    {
        "time": 1689765199000,
        "value": 139.976,
        "_internal_originalTime": 1689765199000
    },
    {
        "time": 1689765200000,
        "value": 139.973,
        "_internal_originalTime": 1689765200000
    },
    {
        "time": 1689765201000,
        "value": 139.975,
        "_internal_originalTime": 1689765201000
    },
    {
        "time": 1689765203000,
        "value": 139.976,
        "_internal_originalTime": 1689765203000
    },
    {
        "time": 1689765204000,
        "value": 139.974,
        "_internal_originalTime": 1689765204000
    },
    {
        "time": 1689765205000,
        "value": 139.973,
        "_internal_originalTime": 1689765205000
    },
    {
        "time": 1689765207000,
        "value": 139.977,
        "_internal_originalTime": 1689765207000
    },
    {
        "time": 1689765208000,
        "value": 139.972,
        "_internal_originalTime": 1689765208000
    },
    {
        "time": 1689765209000,
        "value": 139.975,
        "_internal_originalTime": 1689765209000
    },
    {
        "time": 1689765210000,
        "value": 139.972,
        "_internal_originalTime": 1689765210000
    },
    {
        "time": 1689765211000,
        "value": 139.972,
        "_internal_originalTime": 1689765211000
    },
    {
        "time": 1689765212000,
        "value": 139.973,
        "_internal_originalTime": 1689765212000
    },
    {
        "time": 1689765213000,
        "value": 139.973,
        "_internal_originalTime": 1689765213000
    },
    {
        "time": 1689765214000,
        "value": 139.974,
        "_internal_originalTime": 1689765214000
    },
    {
        "time": 1689765215000,
        "value": 139.974,
        "_internal_originalTime": 1689765215000
    },
    {
        "time": 1689765217000,
        "value": 139.974,
        "_internal_originalTime": 1689765217000
    },
    {
        "time": 1689765218000,
        "value": 139.974,
        "_internal_originalTime": 1689765218000
    },
    {
        "time": 1689765219000,
        "value": 139.972,
        "_internal_originalTime": 1689765219000
    },
    {
        "time": 1689765221000,
        "value": 139.971,
        "_internal_originalTime": 1689765221000
    },
    {
        "time": 1689765222000,
        "value": 139.965,
        "_internal_originalTime": 1689765222000
    },
    {
        "time": 1689765223000,
        "value": 139.964,
        "_internal_originalTime": 1689765223000
    },
    {
        "time": 1689765225000,
        "value": 139.969,
        "_internal_originalTime": 1689765225000
    },
    {
        "time": 1689765227000,
        "value": 139.967,
        "_internal_originalTime": 1689765227000
    },
    {
        "time": 1689765229000,
        "value": 139.968,
        "_internal_originalTime": 1689765229000
    },
    {
        "time": 1689765231000,
        "value": 139.957,
        "_internal_originalTime": 1689765231000
    },
    {
        "time": 1689765233000,
        "value": 139.952,
        "_internal_originalTime": 1689765233000
    },
    {
        "time": 1689765235000,
        "value": 139.956,
        "_internal_originalTime": 1689765235000
    },
    {
        "time": 1689765236000,
        "value": 139.955,
        "_internal_originalTime": 1689765236000
    },
    {
        "time": 1689765238000,
        "value": 139.956,
        "_internal_originalTime": 1689765238000
    },
    {
        "time": 1689765239000,
        "value": 139.955,
        "_internal_originalTime": 1689765239000
    },
    {
        "time": 1689765240000,
        "value": 139.956,
        "_internal_originalTime": 1689765240000
    },
    {
        "time": 1689765241000,
        "value": 139.952,
        "_internal_originalTime": 1689765241000
    },
    {
        "time": 1689765242000,
        "value": 139.955,
        "_internal_originalTime": 1689765242000
    },
    {
        "time": 1689765243000,
        "value": 139.956,
        "_internal_originalTime": 1689765243000
    },
    {
        "time": 1689765244000,
        "value": 139.954,
        "_internal_originalTime": 1689765244000
    },
    {
        "time": 1689765246000,
        "value": 139.955,
        "_internal_originalTime": 1689765246000
    },
    {
        "time": 1689765247000,
        "value": 139.951,
        "_internal_originalTime": 1689765247000
    },
    {
        "time": 1689765248000,
        "value": 139.952,
        "_internal_originalTime": 1689765248000
    },
    {
        "time": 1689765250000,
        "value": 139.95,
        "_internal_originalTime": 1689765250000
    },
    {
        "time": 1689765251000,
        "value": 139.944,
        "_internal_originalTime": 1689765251000
    },
    {
        "time": 1689765252000,
        "value": 139.947,
        "_internal_originalTime": 1689765252000
    },
    {
        "time": 1689765253000,
        "value": 139.943,
        "_internal_originalTime": 1689765253000
    },
    {
        "time": 1689765255000,
        "value": 139.953,
        "_internal_originalTime": 1689765255000
    },
    {
        "time": 1689765257000,
        "value": 139.95,
        "_internal_originalTime": 1689765257000
    },
    {
        "time": 1689765258000,
        "value": 139.953,
        "_internal_originalTime": 1689765258000
    },
    {
        "time": 1689765259000,
        "value": 139.95,
        "_internal_originalTime": 1689765259000
    },
    {
        "time": 1689765260000,
        "value": 139.954,
        "_internal_originalTime": 1689765260000
    },
    {
        "time": 1689765261000,
        "value": 139.953,
        "_internal_originalTime": 1689765261000
    },
    {
        "time": 1689765262000,
        "value": 139.951,
        "_internal_originalTime": 1689765262000
    },
    {
        "time": 1689765264000,
        "value": 139.953,
        "_internal_originalTime": 1689765264000
    },
    {
        "time": 1689765266000,
        "value": 139.956,
        "_internal_originalTime": 1689765266000
    },
    {
        "time": 1689765267000,
        "value": 139.957,
        "_internal_originalTime": 1689765267000
    },
    {
        "time": 1689765268000,
        "value": 139.96,
        "_internal_originalTime": 1689765268000
    },
    {
        "time": 1689765269000,
        "value": 139.962,
        "_internal_originalTime": 1689765269000
    },
    {
        "time": 1689765270000,
        "value": 139.962,
        "_internal_originalTime": 1689765270000
    },
    {
        "time": 1689765271000,
        "value": 139.961,
        "_internal_originalTime": 1689765271000
    },
    {
        "time": 1689765272000,
        "value": 139.961,
        "_internal_originalTime": 1689765272000
    },
    {
        "time": 1689765277000,
        "value": 139.958,
        "_internal_originalTime": 1689765277000
    },
    {
        "time": 1689765278000,
        "value": 139.955,
        "_internal_originalTime": 1689765278000
    },
    {
        "time": 1689765279000,
        "value": 139.956,
        "_internal_originalTime": 1689765279000
    },
    {
        "time": 1689765280000,
        "value": 139.957,
        "_internal_originalTime": 1689765280000
    },
    {
        "time": 1689765282000,
        "value": 139.958,
        "_internal_originalTime": 1689765282000
    },
    {
        "time": 1689765285000,
        "value": 139.953,
        "_internal_originalTime": 1689765285000
    },
    {
        "time": 1689765286000,
        "value": 139.951,
        "_internal_originalTime": 1689765286000
    },
    {
        "time": 1689765288000,
        "value": 139.953,
        "_internal_originalTime": 1689765288000
    },
    {
        "time": 1689765289000,
        "value": 139.95,
        "_internal_originalTime": 1689765289000
    },
    {
        "time": 1689765291000,
        "value": 139.954,
        "_internal_originalTime": 1689765291000
    },
    {
        "time": 1689765292000,
        "value": 139.969,
        "_internal_originalTime": 1689765292000
    },
    {
        "time": 1689765293000,
        "value": 139.971,
        "_internal_originalTime": 1689765293000
    },
    {
        "time": 1689765295000,
        "value": 139.972,
        "_internal_originalTime": 1689765295000
    },
    {
        "time": 1689765296000,
        "value": 139.972,
        "_internal_originalTime": 1689765296000
    },
    {
        "time": 1689765297000,
        "value": 139.972,
        "_internal_originalTime": 1689765297000
    },
    {
        "time": 1689765299000,
        "value": 139.969,
        "_internal_originalTime": 1689765299000
    },
    {
        "time": 1689765300000,
        "value": 139.972,
        "_internal_originalTime": 1689765300000
    },
    {
        "time": 1689765301000,
        "value": 139.969,
        "_internal_originalTime": 1689765301000
    },
    {
        "time": 1689765302000,
        "value": 139.973,
        "_internal_originalTime": 1689765302000
    },
    {
        "time": 1689765303000,
        "value": 139.973,
        "_internal_originalTime": 1689765303000
    },
    {
        "time": 1689765305000,
        "value": 139.968,
        "_internal_originalTime": 1689765305000
    },
    {
        "time": 1689765306000,
        "value": 139.973,
        "_internal_originalTime": 1689765306000
    },
    {
        "time": 1689765307000,
        "value": 139.967,
        "_internal_originalTime": 1689765307000
    },
    {
        "time": 1689765308000,
        "value": 139.97,
        "_internal_originalTime": 1689765308000
    },
    {
        "time": 1689765310000,
        "value": 139.97,
        "_internal_originalTime": 1689765310000
    },
    {
        "time": 1689765311000,
        "value": 139.972,
        "_internal_originalTime": 1689765311000
    },
    {
        "time": 1689765313000,
        "value": 139.968,
        "_internal_originalTime": 1689765313000
    },
    {
        "time": 1689765314000,
        "value": 139.973,
        "_internal_originalTime": 1689765314000
    },
    {
        "time": 1689765315000,
        "value": 139.975,
        "_internal_originalTime": 1689765315000
    },
    {
        "time": 1689765316000,
        "value": 139.974,
        "_internal_originalTime": 1689765316000
    },
    {
        "time": 1689765317000,
        "value": 139.973,
        "_internal_originalTime": 1689765317000
    },
    {
        "time": 1689765318000,
        "value": 139.971,
        "_internal_originalTime": 1689765318000
    },
    {
        "time": 1689765319000,
        "value": 139.969,
        "_internal_originalTime": 1689765319000
    },
    {
        "time": 1689765320000,
        "value": 139.971,
        "_internal_originalTime": 1689765320000
    },
    {
        "time": 1689765322000,
        "value": 139.968,
        "_internal_originalTime": 1689765322000
    },
    {
        "time": 1689765323000,
        "value": 139.97,
        "_internal_originalTime": 1689765323000
    },
    {
        "time": 1689765325000,
        "value": 139.973,
        "_internal_originalTime": 1689765325000
    },
    {
        "time": 1689765326000,
        "value": 139.974,
        "_internal_originalTime": 1689765326000
    },
    {
        "time": 1689765327000,
        "value": 139.975,
        "_internal_originalTime": 1689765327000
    },
    {
        "time": 1689765330000,
        "value": 139.976,
        "_internal_originalTime": 1689765330000
    },
    {
        "time": 1689765331000,
        "value": 139.974,
        "_internal_originalTime": 1689765331000
    },
    {
        "time": 1689765332000,
        "value": 139.975,
        "_internal_originalTime": 1689765332000
    },
    {
        "time": 1689765333000,
        "value": 139.975,
        "_internal_originalTime": 1689765333000
    },
    {
        "time": 1689765334000,
        "value": 139.972,
        "_internal_originalTime": 1689765334000
    },
    {
        "time": 1689765336000,
        "value": 139.971,
        "_internal_originalTime": 1689765336000
    },
    {
        "time": 1689765337000,
        "value": 139.976,
        "_internal_originalTime": 1689765337000
    },
    {
        "time": 1689765339000,
        "value": 139.972,
        "_internal_originalTime": 1689765339000
    },
    {
        "time": 1689765340000,
        "value": 139.976,
        "_internal_originalTime": 1689765340000
    },
    {
        "time": 1689765342000,
        "value": 139.975,
        "_internal_originalTime": 1689765342000
    },
    {
        "time": 1689765343000,
        "value": 139.976,
        "_internal_originalTime": 1689765343000
    },
    {
        "time": 1689765346000,
        "value": 139.981,
        "_internal_originalTime": 1689765346000
    },
    {
        "time": 1689765347000,
        "value": 139.986,
        "_internal_originalTime": 1689765347000
    },
    {
        "time": 1689765348000,
        "value": 139.98,
        "_internal_originalTime": 1689765348000
    },
    {
        "time": 1689765350000,
        "value": 139.98,
        "_internal_originalTime": 1689765350000
    },
    {
        "time": 1689765352000,
        "value": 139.984,
        "_internal_originalTime": 1689765352000
    },
    {
        "time": 1689765354000,
        "value": 139.983,
        "_internal_originalTime": 1689765354000
    },
    {
        "time": 1689765355000,
        "value": 139.981,
        "_internal_originalTime": 1689765355000
    },
    {
        "time": 1689765356000,
        "value": 139.983,
        "_internal_originalTime": 1689765356000
    },
    {
        "time": 1689765357000,
        "value": 139.979,
        "_internal_originalTime": 1689765357000
    },
    {
        "time": 1689765358000,
        "value": 139.984,
        "_internal_originalTime": 1689765358000
    },
    {
        "time": 1689765359000,
        "value": 139.984,
        "_internal_originalTime": 1689765359000
    },
    {
        "time": 1689765361000,
        "value": 139.979,
        "_internal_originalTime": 1689765361000
    },
    {
        "time": 1689765362000,
        "value": 139.98,
        "_internal_originalTime": 1689765362000
    },
    {
        "time": 1689765363000,
        "value": 139.98,
        "_internal_originalTime": 1689765363000
    },
    {
        "time": 1689765365000,
        "value": 139.983,
        "_internal_originalTime": 1689765365000
    },
    {
        "time": 1689765366000,
        "value": 139.981,
        "_internal_originalTime": 1689765366000
    },
    {
        "time": 1689765368000,
        "value": 139.98,
        "_internal_originalTime": 1689765368000
    },
    {
        "time": 1689765369000,
        "value": 139.98,
        "_internal_originalTime": 1689765369000
    },
    {
        "time": 1689765370000,
        "value": 139.986,
        "_internal_originalTime": 1689765370000
    },
    {
        "time": 1689765372000,
        "value": 139.983,
        "_internal_originalTime": 1689765372000
    },
    {
        "time": 1689765373000,
        "value": 139.984,
        "_internal_originalTime": 1689765373000
    },
    {
        "time": 1689765374000,
        "value": 139.983,
        "_internal_originalTime": 1689765374000
    },
    {
        "time": 1689765375000,
        "value": 139.983,
        "_internal_originalTime": 1689765375000
    },
    {
        "time": 1689765377000,
        "value": 139.983,
        "_internal_originalTime": 1689765377000
    },
    {
        "time": 1689765378000,
        "value": 139.984,
        "_internal_originalTime": 1689765378000
    },
    {
        "time": 1689765379000,
        "value": 139.983,
        "_internal_originalTime": 1689765379000
    },
    {
        "time": 1689765381000,
        "value": 139.981,
        "_internal_originalTime": 1689765381000
    },
    {
        "time": 1689765383000,
        "value": 139.982,
        "_internal_originalTime": 1689765383000
    },
    {
        "time": 1689765384000,
        "value": 139.983,
        "_internal_originalTime": 1689765384000
    },
    {
        "time": 1689765386000,
        "value": 139.984,
        "_internal_originalTime": 1689765386000
    },
    {
        "time": 1689765387000,
        "value": 139.983,
        "_internal_originalTime": 1689765387000
    },
    {
        "time": 1689765389000,
        "value": 139.982,
        "_internal_originalTime": 1689765389000
    },
    {
        "time": 1689765390000,
        "value": 139.981,
        "_internal_originalTime": 1689765390000
    },
    {
        "time": 1689765391000,
        "value": 139.983,
        "_internal_originalTime": 1689765391000
    },
    {
        "time": 1689765393000,
        "value": 139.983,
        "_internal_originalTime": 1689765393000
    },
    {
        "time": 1689765394000,
        "value": 139.986,
        "_internal_originalTime": 1689765394000
    },
    {
        "time": 1689765395000,
        "value": 139.98,
        "_internal_originalTime": 1689765395000
    },
    {
        "time": 1689765398000,
        "value": 139.984,
        "_internal_originalTime": 1689765398000
    },
    {
        "time": 1689765399000,
        "value": 139.982,
        "_internal_originalTime": 1689765399000
    },
    {
        "time": 1689765401000,
        "value": 139.983,
        "_internal_originalTime": 1689765401000
    },
    {
        "time": 1689765402000,
        "value": 139.98,
        "_internal_originalTime": 1689765402000
    },
    {
        "time": 1689765403000,
        "value": 139.978,
        "_internal_originalTime": 1689765403000
    },
    {
        "time": 1689765405000,
        "value": 139.977,
        "_internal_originalTime": 1689765405000
    },
    {
        "time": 1689765406000,
        "value": 139.974,
        "_internal_originalTime": 1689765406000
    },
    {
        "time": 1689765407000,
        "value": 139.972,
        "_internal_originalTime": 1689765407000
    },
    {
        "time": 1689765409000,
        "value": 139.97,
        "_internal_originalTime": 1689765409000
    },
    {
        "time": 1689765411000,
        "value": 139.981,
        "_internal_originalTime": 1689765411000
    },
    {
        "time": 1689765413000,
        "value": 139.984,
        "_internal_originalTime": 1689765413000
    },
    {
        "time": 1689765414000,
        "value": 139.983,
        "_internal_originalTime": 1689765414000
    },
    {
        "time": 1689765417000,
        "value": 139.988,
        "_internal_originalTime": 1689765417000
    },
    {
        "time": 1689765418000,
        "value": 139.988,
        "_internal_originalTime": 1689765418000
    },
    {
        "time": 1689765419000,
        "value": 139.982,
        "_internal_originalTime": 1689765419000
    },
    {
        "time": 1689765420000,
        "value": 139.983,
        "_internal_originalTime": 1689765420000
    },
    {
        "time": 1689765421000,
        "value": 139.986,
        "_internal_originalTime": 1689765421000
    },
    {
        "time": 1689765422000,
        "value": 139.984,
        "_internal_originalTime": 1689765422000
    },
    {
        "time": 1689765423000,
        "value": 139.986,
        "_internal_originalTime": 1689765423000
    },
    {
        "time": 1689765424000,
        "value": 139.986,
        "_internal_originalTime": 1689765424000
    },
    {
        "time": 1689765426000,
        "value": 139.983,
        "_internal_originalTime": 1689765426000
    },
    {
        "time": 1689765427000,
        "value": 139.986,
        "_internal_originalTime": 1689765427000
    },
    {
        "time": 1689765428000,
        "value": 139.981,
        "_internal_originalTime": 1689765428000
    },
    {
        "time": 1689765429000,
        "value": 139.984,
        "_internal_originalTime": 1689765429000
    },
    {
        "time": 1689765431000,
        "value": 139.983,
        "_internal_originalTime": 1689765431000
    },
    {
        "time": 1689765433000,
        "value": 139.984,
        "_internal_originalTime": 1689765433000
    },
    {
        "time": 1689765434000,
        "value": 139.984,
        "_internal_originalTime": 1689765434000
    },
    {
        "time": 1689765435000,
        "value": 139.983,
        "_internal_originalTime": 1689765435000
    },
    {
        "time": 1689765436000,
        "value": 139.984,
        "_internal_originalTime": 1689765436000
    },
    {
        "time": 1689765437000,
        "value": 139.984,
        "_internal_originalTime": 1689765437000
    },
    {
        "time": 1689765438000,
        "value": 139.985,
        "_internal_originalTime": 1689765438000
    },
    {
        "time": 1689765439000,
        "value": 139.983,
        "_internal_originalTime": 1689765439000
    },
    {
        "time": 1689765441000,
        "value": 139.982,
        "_internal_originalTime": 1689765441000
    },
    {
        "time": 1689765442000,
        "value": 139.985,
        "_internal_originalTime": 1689765442000
    },
    {
        "time": 1689765443000,
        "value": 139.981,
        "_internal_originalTime": 1689765443000
    },
    {
        "time": 1689765444000,
        "value": 139.979,
        "_internal_originalTime": 1689765444000
    },
    {
        "time": 1689765446000,
        "value": 139.979,
        "_internal_originalTime": 1689765446000
    },
    {
        "time": 1689765447000,
        "value": 139.98,
        "_internal_originalTime": 1689765447000
    },
    {
        "time": 1689765448000,
        "value": 139.983,
        "_internal_originalTime": 1689765448000
    },
    {
        "time": 1689765449000,
        "value": 139.979,
        "_internal_originalTime": 1689765449000
    },
    {
        "time": 1689765450000,
        "value": 139.983,
        "_internal_originalTime": 1689765450000
    },
    {
        "time": 1689765451000,
        "value": 139.982,
        "_internal_originalTime": 1689765451000
    },
    {
        "time": 1689765452000,
        "value": 139.98,
        "_internal_originalTime": 1689765452000
    },
    {
        "time": 1689765453000,
        "value": 139.982,
        "_internal_originalTime": 1689765453000
    },
    {
        "time": 1689765456000,
        "value": 139.981,
        "_internal_originalTime": 1689765456000
    },
    {
        "time": 1689765457000,
        "value": 139.981,
        "_internal_originalTime": 1689765457000
    },
    {
        "time": 1689765459000,
        "value": 139.983,
        "_internal_originalTime": 1689765459000
    },
    {
        "time": 1689765460000,
        "value": 139.983,
        "_internal_originalTime": 1689765460000
    },
    {
        "time": 1689765462000,
        "value": 139.981,
        "_internal_originalTime": 1689765462000
    },
    {
        "time": 1689765463000,
        "value": 139.985,
        "_internal_originalTime": 1689765463000
    },
    {
        "time": 1689765464000,
        "value": 139.986,
        "_internal_originalTime": 1689765464000
    },
    {
        "time": 1689765465000,
        "value": 139.985,
        "_internal_originalTime": 1689765465000
    },
    {
        "time": 1689765466000,
        "value": 139.986,
        "_internal_originalTime": 1689765466000
    },
    {
        "time": 1689765467000,
        "value": 139.981,
        "_internal_originalTime": 1689765467000
    },
    {
        "time": 1689765468000,
        "value": 139.983,
        "_internal_originalTime": 1689765468000
    },
    {
        "time": 1689765470000,
        "value": 139.983,
        "_internal_originalTime": 1689765470000
    },
    {
        "time": 1689765471000,
        "value": 139.984,
        "_internal_originalTime": 1689765471000
    },
    {
        "time": 1689765472000,
        "value": 139.984,
        "_internal_originalTime": 1689765472000
    },
    {
        "time": 1689765475000,
        "value": 139.984,
        "_internal_originalTime": 1689765475000
    },
    {
        "time": 1689765476000,
        "value": 139.982,
        "_internal_originalTime": 1689765476000
    },
    {
        "time": 1689765478000,
        "value": 139.984,
        "_internal_originalTime": 1689765478000
    },
    {
        "time": 1689765480000,
        "value": 139.985,
        "_internal_originalTime": 1689765480000
    },
    {
        "time": 1689765481000,
        "value": 139.983,
        "_internal_originalTime": 1689765481000
    },
    {
        "time": 1689765482000,
        "value": 139.982,
        "_internal_originalTime": 1689765482000
    },
    {
        "time": 1689765484000,
        "value": 139.982,
        "_internal_originalTime": 1689765484000
    },
    {
        "time": 1689765485000,
        "value": 139.982,
        "_internal_originalTime": 1689765485000
    },
    {
        "time": 1689765486000,
        "value": 139.98,
        "_internal_originalTime": 1689765486000
    },
    {
        "time": 1689765487000,
        "value": 139.98,
        "_internal_originalTime": 1689765487000
    },
    {
        "time": 1689765489000,
        "value": 139.981,
        "_internal_originalTime": 1689765489000
    },
    {
        "time": 1689765490000,
        "value": 139.985,
        "_internal_originalTime": 1689765490000
    },
    {
        "time": 1689765491000,
        "value": 139.985,
        "_internal_originalTime": 1689765491000
    },
    {
        "time": 1689765493000,
        "value": 139.978,
        "_internal_originalTime": 1689765493000
    },
    {
        "time": 1689765494000,
        "value": 139.985,
        "_internal_originalTime": 1689765494000
    },
    {
        "time": 1689765495000,
        "value": 139.986,
        "_internal_originalTime": 1689765495000
    },
    {
        "time": 1689765498000,
        "value": 139.983,
        "_internal_originalTime": 1689765498000
    },
    {
        "time": 1689765499000,
        "value": 139.982,
        "_internal_originalTime": 1689765499000
    },
    {
        "time": 1689765500000,
        "value": 139.981,
        "_internal_originalTime": 1689765500000
    },
    {
        "time": 1689765502000,
        "value": 139.982,
        "_internal_originalTime": 1689765502000
    },
    {
        "time": 1689765503000,
        "value": 139.982,
        "_internal_originalTime": 1689765503000
    },
    {
        "time": 1689765505000,
        "value": 139.981,
        "_internal_originalTime": 1689765505000
    },
    {
        "time": 1689765506000,
        "value": 139.978,
        "_internal_originalTime": 1689765506000
    },
    {
        "time": 1689765508000,
        "value": 139.978,
        "_internal_originalTime": 1689765508000
    },
    {
        "time": 1689765510000,
        "value": 139.974,
        "_internal_originalTime": 1689765510000
    },
    {
        "time": 1689765511000,
        "value": 139.98,
        "_internal_originalTime": 1689765511000
    },
    {
        "time": 1689765512000,
        "value": 139.982,
        "_internal_originalTime": 1689765512000
    },
    {
        "time": 1689765515000,
        "value": 139.985,
        "_internal_originalTime": 1689765515000
    },
    {
        "time": 1689765517000,
        "value": 139.983,
        "_internal_originalTime": 1689765517000
    },
    {
        "time": 1689765519000,
        "value": 139.989,
        "_internal_originalTime": 1689765519000
    },
    {
        "time": 1689765520000,
        "value": 139.987,
        "_internal_originalTime": 1689765520000
    },
    {
        "time": 1689765521000,
        "value": 139.982,
        "_internal_originalTime": 1689765521000
    },
    {
        "time": 1689765525000,
        "value": 139.981,
        "_internal_originalTime": 1689765525000
    },
    {
        "time": 1689765526000,
        "value": 139.982,
        "_internal_originalTime": 1689765526000
    },
    {
        "time": 1689765527000,
        "value": 139.979,
        "_internal_originalTime": 1689765527000
    },
    {
        "time": 1689765528000,
        "value": 139.98,
        "_internal_originalTime": 1689765528000
    },
    {
        "time": 1689765530000,
        "value": 139.984,
        "_internal_originalTime": 1689765530000
    },
    {
        "time": 1689765531000,
        "value": 139.982,
        "_internal_originalTime": 1689765531000
    },
    {
        "time": 1689765532000,
        "value": 139.987,
        "_internal_originalTime": 1689765532000
    },
    {
        "time": 1689765535000,
        "value": 139.985,
        "_internal_originalTime": 1689765535000
    },
    {
        "time": 1689765536000,
        "value": 139.978,
        "_internal_originalTime": 1689765536000
    },
    {
        "time": 1689765537000,
        "value": 139.975,
        "_internal_originalTime": 1689765537000
    },
    {
        "time": 1689765540000,
        "value": 139.974,
        "_internal_originalTime": 1689765540000
    },
    {
        "time": 1689765541000,
        "value": 139.979,
        "_internal_originalTime": 1689765541000
    },
    {
        "time": 1689765542000,
        "value": 139.98,
        "_internal_originalTime": 1689765542000
    },
    {
        "time": 1689765544000,
        "value": 139.983,
        "_internal_originalTime": 1689765544000
    },
    {
        "time": 1689765545000,
        "value": 139.982,
        "_internal_originalTime": 1689765545000
    },
    {
        "time": 1689765546000,
        "value": 139.982,
        "_internal_originalTime": 1689765546000
    },
    {
        "time": 1689765547000,
        "value": 139.982,
        "_internal_originalTime": 1689765547000
    },
    {
        "time": 1689765548000,
        "value": 139.972,
        "_internal_originalTime": 1689765548000
    },
    {
        "time": 1689765550000,
        "value": 139.974,
        "_internal_originalTime": 1689765550000
    },
    {
        "time": 1689765551000,
        "value": 139.976,
        "_internal_originalTime": 1689765551000
    },
    {
        "time": 1689765553000,
        "value": 139.977,
        "_internal_originalTime": 1689765553000
    },
    {
        "time": 1689765555000,
        "value": 139.977,
        "_internal_originalTime": 1689765555000
    },
    {
        "time": 1689765556000,
        "value": 139.974,
        "_internal_originalTime": 1689765556000
    },
    {
        "time": 1689765557000,
        "value": 139.972,
        "_internal_originalTime": 1689765557000
    },
    {
        "time": 1689765558000,
        "value": 139.977,
        "_internal_originalTime": 1689765558000
    },
    {
        "time": 1689765561000,
        "value": 139.969,
        "_internal_originalTime": 1689765561000
    },
    {
        "time": 1689765562000,
        "value": 139.974,
        "_internal_originalTime": 1689765562000
    },
    {
        "time": 1689765568000,
        "value": 139.973,
        "_internal_originalTime": 1689765568000
    },
    {
        "time": 1689765569000,
        "value": 139.969,
        "_internal_originalTime": 1689765569000
    },
    {
        "time": 1689765570000,
        "value": 139.972,
        "_internal_originalTime": 1689765570000
    },
    {
        "time": 1689765571000,
        "value": 139.972,
        "_internal_originalTime": 1689765571000
    },
    {
        "time": 1689765572000,
        "value": 139.973,
        "_internal_originalTime": 1689765572000
    },
    {
        "time": 1689765574000,
        "value": 139.971,
        "_internal_originalTime": 1689765574000
    },
    {
        "time": 1689765577000,
        "value": 139.967,
        "_internal_originalTime": 1689765577000
    },
    {
        "time": 1689765578000,
        "value": 139.967,
        "_internal_originalTime": 1689765578000
    },
    {
        "time": 1689765580000,
        "value": 139.961,
        "_internal_originalTime": 1689765580000
    },
    {
        "time": 1689765581000,
        "value": 139.966,
        "_internal_originalTime": 1689765581000
    },
    {
        "time": 1689765582000,
        "value": 139.966,
        "_internal_originalTime": 1689765582000
    },
    {
        "time": 1689765583000,
        "value": 139.966,
        "_internal_originalTime": 1689765583000
    },
    {
        "time": 1689765584000,
        "value": 139.964,
        "_internal_originalTime": 1689765584000
    },
    {
        "time": 1689765585000,
        "value": 139.963,
        "_internal_originalTime": 1689765585000
    },
    {
        "time": 1689765586000,
        "value": 139.963,
        "_internal_originalTime": 1689765586000
    },
    {
        "time": 1689765587000,
        "value": 139.961,
        "_internal_originalTime": 1689765587000
    },
    {
        "time": 1689765589000,
        "value": 139.962,
        "_internal_originalTime": 1689765589000
    },
    {
        "time": 1689765590000,
        "value": 139.961,
        "_internal_originalTime": 1689765590000
    },
    {
        "time": 1689765591000,
        "value": 139.967,
        "_internal_originalTime": 1689765591000
    },
    {
        "time": 1689765592000,
        "value": 139.967,
        "_internal_originalTime": 1689765592000
    },
    {
        "time": 1689765594000,
        "value": 139.967,
        "_internal_originalTime": 1689765594000
    },
    {
        "time": 1689765595000,
        "value": 139.964,
        "_internal_originalTime": 1689765595000
    },
    {
        "time": 1689765596000,
        "value": 139.962,
        "_internal_originalTime": 1689765596000
    },
    {
        "time": 1689765597000,
        "value": 139.963,
        "_internal_originalTime": 1689765597000
    },
    {
        "time": 1689765599000,
        "value": 139.966,
        "_internal_originalTime": 1689765599000
    },
    {
        "time": 1689765600000,
        "value": 139.964,
        "_internal_originalTime": 1689765600000
    },
    {
        "time": 1689765601000,
        "value": 139.94,
        "_internal_originalTime": 1689765601000
    },
    {
        "time": 1689765602000,
        "value": 139.965,
        "_internal_originalTime": 1689765602000
    },
    {
        "time": 1689765606000,
        "value": 139.97,
        "_internal_originalTime": 1689765606000
    },
    {
        "time": 1689765607000,
        "value": 139.96,
        "_internal_originalTime": 1689765607000
    },
    {
        "time": 1689765608000,
        "value": 139.964,
        "_internal_originalTime": 1689765608000
    },
    {
        "time": 1689765610000,
        "value": 139.954,
        "_internal_originalTime": 1689765610000
    },
    {
        "time": 1689765611000,
        "value": 139.954,
        "_internal_originalTime": 1689765611000
    },
    {
        "time": 1689765612000,
        "value": 139.954,
        "_internal_originalTime": 1689765612000
    },
    {
        "time": 1689765614000,
        "value": 139.951,
        "_internal_originalTime": 1689765614000
    },
    {
        "time": 1689765615000,
        "value": 139.955,
        "_internal_originalTime": 1689765615000
    },
    {
        "time": 1689765616000,
        "value": 139.951,
        "_internal_originalTime": 1689765616000
    },
    {
        "time": 1689765618000,
        "value": 139.952,
        "_internal_originalTime": 1689765618000
    },
    {
        "time": 1689765619000,
        "value": 139.955,
        "_internal_originalTime": 1689765619000
    },
    {
        "time": 1689765620000,
        "value": 139.953,
        "_internal_originalTime": 1689765620000
    },
    {
        "time": 1689765622000,
        "value": 139.954,
        "_internal_originalTime": 1689765622000
    },
    {
        "time": 1689765623000,
        "value": 139.953,
        "_internal_originalTime": 1689765623000
    },
    {
        "time": 1689765624000,
        "value": 139.953,
        "_internal_originalTime": 1689765624000
    },
    {
        "time": 1689765626000,
        "value": 139.954,
        "_internal_originalTime": 1689765626000
    },
    {
        "time": 1689765627000,
        "value": 139.953,
        "_internal_originalTime": 1689765627000
    },
    {
        "time": 1689765628000,
        "value": 139.953,
        "_internal_originalTime": 1689765628000
    },
    {
        "time": 1689765629000,
        "value": 139.954,
        "_internal_originalTime": 1689765629000
    },
    {
        "time": 1689765630000,
        "value": 139.955,
        "_internal_originalTime": 1689765630000
    },
    {
        "time": 1689765631000,
        "value": 139.955,
        "_internal_originalTime": 1689765631000
    },
    {
        "time": 1689765632000,
        "value": 139.957,
        "_internal_originalTime": 1689765632000
    },
    {
        "time": 1689765633000,
        "value": 139.956,
        "_internal_originalTime": 1689765633000
    },
    {
        "time": 1689765634000,
        "value": 139.956,
        "_internal_originalTime": 1689765634000
    },
    {
        "time": 1689765636000,
        "value": 139.955,
        "_internal_originalTime": 1689765636000
    },
    {
        "time": 1689765637000,
        "value": 139.954,
        "_internal_originalTime": 1689765637000
    },
    {
        "time": 1689765638000,
        "value": 139.95,
        "_internal_originalTime": 1689765638000
    },
    {
        "time": 1689765642000,
        "value": 139.953,
        "_internal_originalTime": 1689765642000
    },
    {
        "time": 1689765643000,
        "value": 139.949,
        "_internal_originalTime": 1689765643000
    },
    {
        "time": 1689765644000,
        "value": 139.954,
        "_internal_originalTime": 1689765644000
    },
    {
        "time": 1689765646000,
        "value": 139.953,
        "_internal_originalTime": 1689765646000
    },
    {
        "time": 1689765647000,
        "value": 139.953,
        "_internal_originalTime": 1689765647000
    },
    {
        "time": 1689765649000,
        "value": 139.955,
        "_internal_originalTime": 1689765649000
    },
    {
        "time": 1689765650000,
        "value": 139.954,
        "_internal_originalTime": 1689765650000
    },
    {
        "time": 1689765651000,
        "value": 139.948,
        "_internal_originalTime": 1689765651000
    },
    {
        "time": 1689765652000,
        "value": 139.94,
        "_internal_originalTime": 1689765652000
    },
    {
        "time": 1689765654000,
        "value": 139.945,
        "_internal_originalTime": 1689765654000
    },
    {
        "time": 1689765655000,
        "value": 139.944,
        "_internal_originalTime": 1689765655000
    },
    {
        "time": 1689765656000,
        "value": 139.943,
        "_internal_originalTime": 1689765656000
    },
    {
        "time": 1689765659000,
        "value": 139.943,
        "_internal_originalTime": 1689765659000
    },
    {
        "time": 1689765660000,
        "value": 139.943,
        "_internal_originalTime": 1689765660000
    },
    {
        "time": 1689765662000,
        "value": 139.944,
        "_internal_originalTime": 1689765662000
    },
    {
        "time": 1689765663000,
        "value": 139.933,
        "_internal_originalTime": 1689765663000
    },
    {
        "time": 1689765664000,
        "value": 139.937,
        "_internal_originalTime": 1689765664000
    },
    {
        "time": 1689765665000,
        "value": 139.935,
        "_internal_originalTime": 1689765665000
    },
    {
        "time": 1689765666000,
        "value": 139.934,
        "_internal_originalTime": 1689765666000
    },
    {
        "time": 1689765667000,
        "value": 139.931,
        "_internal_originalTime": 1689765667000
    },
    {
        "time": 1689765668000,
        "value": 139.933,
        "_internal_originalTime": 1689765668000
    },
    {
        "time": 1689765670000,
        "value": 139.937,
        "_internal_originalTime": 1689765670000
    },
    {
        "time": 1689765671000,
        "value": 139.935,
        "_internal_originalTime": 1689765671000
    },
    {
        "time": 1689765672000,
        "value": 139.938,
        "_internal_originalTime": 1689765672000
    },
    {
        "time": 1689765673000,
        "value": 139.937,
        "_internal_originalTime": 1689765673000
    },
    {
        "time": 1689765674000,
        "value": 139.938,
        "_internal_originalTime": 1689765674000
    },
    {
        "time": 1689765675000,
        "value": 139.936,
        "_internal_originalTime": 1689765675000
    },
    {
        "time": 1689765676000,
        "value": 139.936,
        "_internal_originalTime": 1689765676000
    },
    {
        "time": 1689765677000,
        "value": 139.934,
        "_internal_originalTime": 1689765677000
    },
    {
        "time": 1689765678000,
        "value": 139.939,
        "_internal_originalTime": 1689765678000
    },
    {
        "time": 1689765679000,
        "value": 139.936,
        "_internal_originalTime": 1689765679000
    },
    {
        "time": 1689765681000,
        "value": 139.938,
        "_internal_originalTime": 1689765681000
    },
    {
        "time": 1689765682000,
        "value": 139.942,
        "_internal_originalTime": 1689765682000
    },
    {
        "time": 1689765683000,
        "value": 139.946,
        "_internal_originalTime": 1689765683000
    },
    {
        "time": 1689765685000,
        "value": 139.943,
        "_internal_originalTime": 1689765685000
    },
    {
        "time": 1689765686000,
        "value": 139.946,
        "_internal_originalTime": 1689765686000
    },
    {
        "time": 1689765690000,
        "value": 139.946,
        "_internal_originalTime": 1689765690000
    },
    {
        "time": 1689765692000,
        "value": 139.947,
        "_internal_originalTime": 1689765692000
    },
    {
        "time": 1689765693000,
        "value": 139.943,
        "_internal_originalTime": 1689765693000
    },
    {
        "time": 1689765696000,
        "value": 139.948,
        "_internal_originalTime": 1689765696000
    },
    {
        "time": 1689765697000,
        "value": 139.944,
        "_internal_originalTime": 1689765697000
    },
    {
        "time": 1689765699000,
        "value": 139.947,
        "_internal_originalTime": 1689765699000
    },
    {
        "time": 1689765701000,
        "value": 139.947,
        "_internal_originalTime": 1689765701000
    },
    {
        "time": 1689765703000,
        "value": 139.944,
        "_internal_originalTime": 1689765703000
    },
    {
        "time": 1689765705000,
        "value": 139.947,
        "_internal_originalTime": 1689765705000
    },
    {
        "time": 1689765706000,
        "value": 139.945,
        "_internal_originalTime": 1689765706000
    },
    {
        "time": 1689765708000,
        "value": 139.946,
        "_internal_originalTime": 1689765708000
    },
    {
        "time": 1689765709000,
        "value": 139.944,
        "_internal_originalTime": 1689765709000
    },
    {
        "time": 1689765710000,
        "value": 139.95,
        "_internal_originalTime": 1689765710000
    },
    {
        "time": 1689765712000,
        "value": 139.948,
        "_internal_originalTime": 1689765712000
    },
    {
        "time": 1689765713000,
        "value": 139.95,
        "_internal_originalTime": 1689765713000
    },
    {
        "time": 1689765714000,
        "value": 139.948,
        "_internal_originalTime": 1689765714000
    },
    {
        "time": 1689765715000,
        "value": 139.946,
        "_internal_originalTime": 1689765715000
    },
    {
        "time": 1689765718000,
        "value": 139.947,
        "_internal_originalTime": 1689765718000
    },
    {
        "time": 1689765719000,
        "value": 139.947,
        "_internal_originalTime": 1689765719000
    },
    {
        "time": 1689765720000,
        "value": 139.949,
        "_internal_originalTime": 1689765720000
    },
    {
        "time": 1689765721000,
        "value": 139.944,
        "_internal_originalTime": 1689765721000
    },
    {
        "time": 1689765722000,
        "value": 139.945,
        "_internal_originalTime": 1689765722000
    },
    {
        "time": 1689765723000,
        "value": 139.945,
        "_internal_originalTime": 1689765723000
    },
    {
        "time": 1689765725000,
        "value": 139.946,
        "_internal_originalTime": 1689765725000
    },
    {
        "time": 1689765726000,
        "value": 139.944,
        "_internal_originalTime": 1689765726000
    },
    {
        "time": 1689765727000,
        "value": 139.945,
        "_internal_originalTime": 1689765727000
    },
    {
        "time": 1689765728000,
        "value": 139.945,
        "_internal_originalTime": 1689765728000
    },
    {
        "time": 1689765731000,
        "value": 139.942,
        "_internal_originalTime": 1689765731000
    },
    {
        "time": 1689765733000,
        "value": 139.945,
        "_internal_originalTime": 1689765733000
    },
    {
        "time": 1689765734000,
        "value": 139.944,
        "_internal_originalTime": 1689765734000
    },
    {
        "time": 1689765735000,
        "value": 139.948,
        "_internal_originalTime": 1689765735000
    },
    {
        "time": 1689765737000,
        "value": 139.952,
        "_internal_originalTime": 1689765737000
    },
    {
        "time": 1689765738000,
        "value": 139.956,
        "_internal_originalTime": 1689765738000
    },
    {
        "time": 1689765740000,
        "value": 139.953,
        "_internal_originalTime": 1689765740000
    },
    {
        "time": 1689765741000,
        "value": 139.954,
        "_internal_originalTime": 1689765741000
    },
    {
        "time": 1689765742000,
        "value": 139.953,
        "_internal_originalTime": 1689765742000
    },
    {
        "time": 1689765743000,
        "value": 139.954,
        "_internal_originalTime": 1689765743000
    },
    {
        "time": 1689765744000,
        "value": 139.957,
        "_internal_originalTime": 1689765744000
    },
    {
        "time": 1689765750000,
        "value": 139.952,
        "_internal_originalTime": 1689765750000
    },
    {
        "time": 1689765751000,
        "value": 139.953,
        "_internal_originalTime": 1689765751000
    },
    {
        "time": 1689765752000,
        "value": 139.95,
        "_internal_originalTime": 1689765752000
    },
    {
        "time": 1689765753000,
        "value": 139.954,
        "_internal_originalTime": 1689765753000
    },
    {
        "time": 1689765754000,
        "value": 139.954,
        "_internal_originalTime": 1689765754000
    },
    {
        "time": 1689765756000,
        "value": 139.95,
        "_internal_originalTime": 1689765756000
    },
    {
        "time": 1689765758000,
        "value": 139.951,
        "_internal_originalTime": 1689765758000
    },
    {
        "time": 1689765760000,
        "value": 139.951,
        "_internal_originalTime": 1689765760000
    },
    {
        "time": 1689765761000,
        "value": 139.958,
        "_internal_originalTime": 1689765761000
    },
    {
        "time": 1689765762000,
        "value": 139.957,
        "_internal_originalTime": 1689765762000
    },
    {
        "time": 1689765763000,
        "value": 139.955,
        "_internal_originalTime": 1689765763000
    },
    {
        "time": 1689765766000,
        "value": 139.954,
        "_internal_originalTime": 1689765766000
    },
    {
        "time": 1689765767000,
        "value": 139.956,
        "_internal_originalTime": 1689765767000
    },
    {
        "time": 1689765768000,
        "value": 139.956,
        "_internal_originalTime": 1689765768000
    },
    {
        "time": 1689765770000,
        "value": 139.954,
        "_internal_originalTime": 1689765770000
    },
    {
        "time": 1689765771000,
        "value": 139.956,
        "_internal_originalTime": 1689765771000
    },
    {
        "time": 1689765773000,
        "value": 139.953,
        "_internal_originalTime": 1689765773000
    },
    {
        "time": 1689765775000,
        "value": 139.952,
        "_internal_originalTime": 1689765775000
    },
    {
        "time": 1689765777000,
        "value": 139.953,
        "_internal_originalTime": 1689765777000
    },
    {
        "time": 1689765778000,
        "value": 139.953,
        "_internal_originalTime": 1689765778000
    },
    {
        "time": 1689765779000,
        "value": 139.954,
        "_internal_originalTime": 1689765779000
    },
    {
        "time": 1689765780000,
        "value": 139.955,
        "_internal_originalTime": 1689765780000
    },
    {
        "time": 1689765781000,
        "value": 139.955,
        "_internal_originalTime": 1689765781000
    },
    {
        "time": 1689765783000,
        "value": 139.956,
        "_internal_originalTime": 1689765783000
    },
    {
        "time": 1689765784000,
        "value": 139.954,
        "_internal_originalTime": 1689765784000
    },
    {
        "time": 1689765786000,
        "value": 139.964,
        "_internal_originalTime": 1689765786000
    },
    {
        "time": 1689765787000,
        "value": 139.969,
        "_internal_originalTime": 1689765787000
    },
    {
        "time": 1689765788000,
        "value": 139.967,
        "_internal_originalTime": 1689765788000
    },
    {
        "time": 1689765790000,
        "value": 139.965,
        "_internal_originalTime": 1689765790000
    },
    {
        "time": 1689765791000,
        "value": 139.967,
        "_internal_originalTime": 1689765791000
    },
    {
        "time": 1689765792000,
        "value": 139.968,
        "_internal_originalTime": 1689765792000
    },
    {
        "time": 1689765793000,
        "value": 139.967,
        "_internal_originalTime": 1689765793000
    },
    {
        "time": 1689765795000,
        "value": 139.974,
        "_internal_originalTime": 1689765795000
    },
    {
        "time": 1689765797000,
        "value": 139.969,
        "_internal_originalTime": 1689765797000
    },
    {
        "time": 1689765798000,
        "value": 139.971,
        "_internal_originalTime": 1689765798000
    },
    {
        "time": 1689765799000,
        "value": 139.967,
        "_internal_originalTime": 1689765799000
    },
    {
        "time": 1689765801000,
        "value": 139.967,
        "_internal_originalTime": 1689765801000
    },
    {
        "time": 1689765802000,
        "value": 139.973,
        "_internal_originalTime": 1689765802000
    },
    {
        "time": 1689765804000,
        "value": 139.971,
        "_internal_originalTime": 1689765804000
    },
    {
        "time": 1689765805000,
        "value": 139.97,
        "_internal_originalTime": 1689765805000
    },
    {
        "time": 1689765807000,
        "value": 139.973,
        "_internal_originalTime": 1689765807000
    },
    {
        "time": 1689765808000,
        "value": 139.971,
        "_internal_originalTime": 1689765808000
    },
    {
        "time": 1689765810000,
        "value": 139.975,
        "_internal_originalTime": 1689765810000
    },
    {
        "time": 1689765811000,
        "value": 139.972,
        "_internal_originalTime": 1689765811000
    },
    {
        "time": 1689765812000,
        "value": 139.972,
        "_internal_originalTime": 1689765812000
    },
    {
        "time": 1689765814000,
        "value": 139.975,
        "_internal_originalTime": 1689765814000
    },
    {
        "time": 1689765817000,
        "value": 139.97,
        "_internal_originalTime": 1689765817000
    },
    {
        "time": 1689765818000,
        "value": 139.972,
        "_internal_originalTime": 1689765818000
    },
    {
        "time": 1689765819000,
        "value": 139.973,
        "_internal_originalTime": 1689765819000
    },
    {
        "time": 1689765820000,
        "value": 139.971,
        "_internal_originalTime": 1689765820000
    },
    {
        "time": 1689765822000,
        "value": 139.969,
        "_internal_originalTime": 1689765822000
    },
    {
        "time": 1689765823000,
        "value": 139.973,
        "_internal_originalTime": 1689765823000
    },
    {
        "time": 1689765825000,
        "value": 139.972,
        "_internal_originalTime": 1689765825000
    },
    {
        "time": 1689765827000,
        "value": 139.977,
        "_internal_originalTime": 1689765827000
    },
    {
        "time": 1689765828000,
        "value": 139.976,
        "_internal_originalTime": 1689765828000
    },
    {
        "time": 1689765830000,
        "value": 139.974,
        "_internal_originalTime": 1689765830000
    },
    {
        "time": 1689765831000,
        "value": 139.977,
        "_internal_originalTime": 1689765831000
    },
    {
        "time": 1689765833000,
        "value": 139.975,
        "_internal_originalTime": 1689765833000
    },
    {
        "time": 1689765834000,
        "value": 139.982,
        "_internal_originalTime": 1689765834000
    },
    {
        "time": 1689765836000,
        "value": 139.982,
        "_internal_originalTime": 1689765836000
    },
    {
        "time": 1689765837000,
        "value": 139.985,
        "_internal_originalTime": 1689765837000
    },
    {
        "time": 1689765838000,
        "value": 139.982,
        "_internal_originalTime": 1689765838000
    },
    {
        "time": 1689765839000,
        "value": 139.984,
        "_internal_originalTime": 1689765839000
    },
    {
        "time": 1689765840000,
        "value": 139.98,
        "_internal_originalTime": 1689765840000
    },
    {
        "time": 1689765842000,
        "value": 139.979,
        "_internal_originalTime": 1689765842000
    },
    {
        "time": 1689765843000,
        "value": 139.98,
        "_internal_originalTime": 1689765843000
    },
    {
        "time": 1689765844000,
        "value": 139.985,
        "_internal_originalTime": 1689765844000
    },
    {
        "time": 1689765845000,
        "value": 139.98,
        "_internal_originalTime": 1689765845000
    },
    {
        "time": 1689765846000,
        "value": 139.98,
        "_internal_originalTime": 1689765846000
    },
    {
        "time": 1689765848000,
        "value": 139.983,
        "_internal_originalTime": 1689765848000
    },
    {
        "time": 1689765849000,
        "value": 139.98,
        "_internal_originalTime": 1689765849000
    },
    {
        "time": 1689765851000,
        "value": 139.98,
        "_internal_originalTime": 1689765851000
    },
    {
        "time": 1689765852000,
        "value": 139.98,
        "_internal_originalTime": 1689765852000
    },
    {
        "time": 1689765853000,
        "value": 139.98,
        "_internal_originalTime": 1689765853000
    },
    {
        "time": 1689765854000,
        "value": 139.98,
        "_internal_originalTime": 1689765854000
    },
    {
        "time": 1689765857000,
        "value": 139.982,
        "_internal_originalTime": 1689765857000
    },
    {
        "time": 1689765858000,
        "value": 139.98,
        "_internal_originalTime": 1689765858000
    },
    {
        "time": 1689765859000,
        "value": 139.981,
        "_internal_originalTime": 1689765859000
    },
    {
        "time": 1689765862000,
        "value": 139.981,
        "_internal_originalTime": 1689765862000
    },
    {
        "time": 1689765863000,
        "value": 139.98,
        "_internal_originalTime": 1689765863000
    },
    {
        "time": 1689765864000,
        "value": 139.982,
        "_internal_originalTime": 1689765864000
    },
    {
        "time": 1689765866000,
        "value": 139.985,
        "_internal_originalTime": 1689765866000
    },
    {
        "time": 1689765867000,
        "value": 139.985,
        "_internal_originalTime": 1689765867000
    },
    {
        "time": 1689765869000,
        "value": 139.986,
        "_internal_originalTime": 1689765869000
    },
    {
        "time": 1689765870000,
        "value": 139.986,
        "_internal_originalTime": 1689765870000
    },
    {
        "time": 1689765871000,
        "value": 139.988,
        "_internal_originalTime": 1689765871000
    },
    {
        "time": 1689765872000,
        "value": 139.988,
        "_internal_originalTime": 1689765872000
    },
    {
        "time": 1689765873000,
        "value": 139.984,
        "_internal_originalTime": 1689765873000
    },
    {
        "time": 1689765874000,
        "value": 139.982,
        "_internal_originalTime": 1689765874000
    },
    {
        "time": 1689765876000,
        "value": 139.988,
        "_internal_originalTime": 1689765876000
    },
    {
        "time": 1689765877000,
        "value": 139.986,
        "_internal_originalTime": 1689765877000
    },
    {
        "time": 1689765879000,
        "value": 139.988,
        "_internal_originalTime": 1689765879000
    },
    {
        "time": 1689765880000,
        "value": 139.991,
        "_internal_originalTime": 1689765880000
    },
    {
        "time": 1689765882000,
        "value": 139.985,
        "_internal_originalTime": 1689765882000
    },
    {
        "time": 1689765883000,
        "value": 139.988,
        "_internal_originalTime": 1689765883000
    },
    {
        "time": 1689765885000,
        "value": 139.988,
        "_internal_originalTime": 1689765885000
    },
    {
        "time": 1689765886000,
        "value": 139.985,
        "_internal_originalTime": 1689765886000
    },
    {
        "time": 1689765887000,
        "value": 139.982,
        "_internal_originalTime": 1689765887000
    },
    {
        "time": 1689765891000,
        "value": 139.981,
        "_internal_originalTime": 1689765891000
    },
    {
        "time": 1689765893000,
        "value": 139.98,
        "_internal_originalTime": 1689765893000
    },
    {
        "time": 1689765894000,
        "value": 139.986,
        "_internal_originalTime": 1689765894000
    },
    {
        "time": 1689765896000,
        "value": 139.983,
        "_internal_originalTime": 1689765896000
    },
    {
        "time": 1689765897000,
        "value": 139.984,
        "_internal_originalTime": 1689765897000
    },
    {
        "time": 1689765901000,
        "value": 139.987,
        "_internal_originalTime": 1689765901000
    },
    {
        "time": 1689765902000,
        "value": 139.987,
        "_internal_originalTime": 1689765902000
    },
    {
        "time": 1689765903000,
        "value": 139.982,
        "_internal_originalTime": 1689765903000
    },
    {
        "time": 1689765904000,
        "value": 139.984,
        "_internal_originalTime": 1689765904000
    },
    {
        "time": 1689765908000,
        "value": 139.985,
        "_internal_originalTime": 1689765908000
    },
    {
        "time": 1689765909000,
        "value": 139.984,
        "_internal_originalTime": 1689765909000
    },
    {
        "time": 1689765911000,
        "value": 139.988,
        "_internal_originalTime": 1689765911000
    },
    {
        "time": 1689765912000,
        "value": 139.987,
        "_internal_originalTime": 1689765912000
    },
    {
        "time": 1689765913000,
        "value": 139.985,
        "_internal_originalTime": 1689765913000
    },
    {
        "time": 1689765915000,
        "value": 139.987,
        "_internal_originalTime": 1689765915000
    },
    {
        "time": 1689765916000,
        "value": 139.985,
        "_internal_originalTime": 1689765916000
    },
    {
        "time": 1689765918000,
        "value": 139.986,
        "_internal_originalTime": 1689765918000
    },
    {
        "time": 1689765919000,
        "value": 139.986,
        "_internal_originalTime": 1689765919000
    },
    {
        "time": 1689765920000,
        "value": 139.983,
        "_internal_originalTime": 1689765920000
    },
    {
        "time": 1689765921000,
        "value": 139.988,
        "_internal_originalTime": 1689765921000
    },
    {
        "time": 1689765922000,
        "value": 139.986,
        "_internal_originalTime": 1689765922000
    },
    {
        "time": 1689765924000,
        "value": 139.984,
        "_internal_originalTime": 1689765924000
    },
    {
        "time": 1689765927000,
        "value": 139.983,
        "_internal_originalTime": 1689765927000
    },
    {
        "time": 1689765928000,
        "value": 139.98,
        "_internal_originalTime": 1689765928000
    },
    {
        "time": 1689765929000,
        "value": 139.982,
        "_internal_originalTime": 1689765929000
    },
    {
        "time": 1689765930000,
        "value": 139.981,
        "_internal_originalTime": 1689765930000
    },
    {
        "time": 1689765931000,
        "value": 139.984,
        "_internal_originalTime": 1689765931000
    },
    {
        "time": 1689765932000,
        "value": 139.983,
        "_internal_originalTime": 1689765932000
    },
    {
        "time": 1689765933000,
        "value": 139.983,
        "_internal_originalTime": 1689765933000
    },
    {
        "time": 1689765935000,
        "value": 139.985,
        "_internal_originalTime": 1689765935000
    },
    {
        "time": 1689765937000,
        "value": 139.983,
        "_internal_originalTime": 1689765937000
    },
    {
        "time": 1689765938000,
        "value": 139.983,
        "_internal_originalTime": 1689765938000
    },
    {
        "time": 1689765940000,
        "value": 139.984,
        "_internal_originalTime": 1689765940000
    },
    {
        "time": 1689765941000,
        "value": 139.986,
        "_internal_originalTime": 1689765941000
    },
    {
        "time": 1689765943000,
        "value": 139.982,
        "_internal_originalTime": 1689765943000
    },
    {
        "time": 1689765944000,
        "value": 139.982,
        "_internal_originalTime": 1689765944000
    },
    {
        "time": 1689765945000,
        "value": 139.984,
        "_internal_originalTime": 1689765945000
    },
    {
        "time": 1689765946000,
        "value": 139.982,
        "_internal_originalTime": 1689765946000
    },
    {
        "time": 1689765948000,
        "value": 139.985,
        "_internal_originalTime": 1689765948000
    },
    {
        "time": 1689765949000,
        "value": 139.982,
        "_internal_originalTime": 1689765949000
    },
    {
        "time": 1689765951000,
        "value": 139.982,
        "_internal_originalTime": 1689765951000
    },
    {
        "time": 1689765953000,
        "value": 139.985,
        "_internal_originalTime": 1689765953000
    },
    {
        "time": 1689765954000,
        "value": 139.985,
        "_internal_originalTime": 1689765954000
    },
    {
        "time": 1689765955000,
        "value": 139.985,
        "_internal_originalTime": 1689765955000
    },
    {
        "time": 1689765957000,
        "value": 139.989,
        "_internal_originalTime": 1689765957000
    },
    {
        "time": 1689765958000,
        "value": 139.984,
        "_internal_originalTime": 1689765958000
    },
    {
        "time": 1689765959000,
        "value": 139.984,
        "_internal_originalTime": 1689765959000
    },
    {
        "time": 1689765961000,
        "value": 139.981,
        "_internal_originalTime": 1689765961000
    },
    {
        "time": 1689765962000,
        "value": 139.984,
        "_internal_originalTime": 1689765962000
    },
    {
        "time": 1689765963000,
        "value": 139.981,
        "_internal_originalTime": 1689765963000
    },
    {
        "time": 1689765966000,
        "value": 139.979,
        "_internal_originalTime": 1689765966000
    },
    {
        "time": 1689765967000,
        "value": 139.977,
        "_internal_originalTime": 1689765967000
    },
    {
        "time": 1689765969000,
        "value": 139.973,
        "_internal_originalTime": 1689765969000
    },
    {
        "time": 1689765970000,
        "value": 139.977,
        "_internal_originalTime": 1689765970000
    },
    {
        "time": 1689765971000,
        "value": 139.977,
        "_internal_originalTime": 1689765971000
    },
    {
        "time": 1689765972000,
        "value": 139.974,
        "_internal_originalTime": 1689765972000
    },
    {
        "time": 1689765974000,
        "value": 139.975,
        "_internal_originalTime": 1689765974000
    },
    {
        "time": 1689765975000,
        "value": 139.974,
        "_internal_originalTime": 1689765975000
    },
    {
        "time": 1689765977000,
        "value": 139.972,
        "_internal_originalTime": 1689765977000
    },
    {
        "time": 1689765978000,
        "value": 139.972,
        "_internal_originalTime": 1689765978000
    },
    {
        "time": 1689765979000,
        "value": 139.965,
        "_internal_originalTime": 1689765979000
    },
    {
        "time": 1689765980000,
        "value": 139.97,
        "_internal_originalTime": 1689765980000
    },
    {
        "time": 1689765981000,
        "value": 139.973,
        "_internal_originalTime": 1689765981000
    },
    {
        "time": 1689765982000,
        "value": 139.974,
        "_internal_originalTime": 1689765982000
    },
    {
        "time": 1689765984000,
        "value": 139.977,
        "_internal_originalTime": 1689765984000
    },
    {
        "time": 1689765985000,
        "value": 139.977,
        "_internal_originalTime": 1689765985000
    },
    {
        "time": 1689765986000,
        "value": 139.975,
        "_internal_originalTime": 1689765986000
    },
    {
        "time": 1689765988000,
        "value": 139.975,
        "_internal_originalTime": 1689765988000
    },
    {
        "time": 1689765989000,
        "value": 139.976,
        "_internal_originalTime": 1689765989000
    },
    {
        "time": 1689765990000,
        "value": 139.975,
        "_internal_originalTime": 1689765990000
    },
    {
        "time": 1689765991000,
        "value": 139.976,
        "_internal_originalTime": 1689765991000
    },
    {
        "time": 1689765992000,
        "value": 139.975,
        "_internal_originalTime": 1689765992000
    },
    {
        "time": 1689765993000,
        "value": 139.982,
        "_internal_originalTime": 1689765993000
    },
    {
        "time": 1689765995000,
        "value": 139.983,
        "_internal_originalTime": 1689765995000
    },
    {
        "time": 1689765997000,
        "value": 139.981,
        "_internal_originalTime": 1689765997000
    },
    {
        "time": 1689765999000,
        "value": 139.982,
        "_internal_originalTime": 1689765999000
    },
    {
        "time": 1689766001000,
        "value": 139.982,
        "_internal_originalTime": 1689766001000
    },
    {
        "time": 1689766003000,
        "value": 139.984,
        "_internal_originalTime": 1689766003000
    },
    {
        "time": 1689766004000,
        "value": 139.987,
        "_internal_originalTime": 1689766004000
    },
    {
        "time": 1689766006000,
        "value": 139.982,
        "_internal_originalTime": 1689766006000
    },
    {
        "time": 1689766007000,
        "value": 139.984,
        "_internal_originalTime": 1689766007000
    },
    {
        "time": 1689766009000,
        "value": 139.982,
        "_internal_originalTime": 1689766009000
    },
    {
        "time": 1689766010000,
        "value": 139.982,
        "_internal_originalTime": 1689766010000
    },
    {
        "time": 1689766011000,
        "value": 139.987,
        "_internal_originalTime": 1689766011000
    },
    {
        "time": 1689766013000,
        "value": 139.987,
        "_internal_originalTime": 1689766013000
    },
    {
        "time": 1689766015000,
        "value": 139.985,
        "_internal_originalTime": 1689766015000
    },
    {
        "time": 1689766016000,
        "value": 139.984,
        "_internal_originalTime": 1689766016000
    },
    {
        "time": 1689766017000,
        "value": 139.982,
        "_internal_originalTime": 1689766017000
    },
    {
        "time": 1689766019000,
        "value": 139.984,
        "_internal_originalTime": 1689766019000
    },
    {
        "time": 1689766021000,
        "value": 139.987,
        "_internal_originalTime": 1689766021000
    },
    {
        "time": 1689766022000,
        "value": 139.986,
        "_internal_originalTime": 1689766022000
    },
    {
        "time": 1689766023000,
        "value": 139.987,
        "_internal_originalTime": 1689766023000
    },
    {
        "time": 1689766025000,
        "value": 139.989,
        "_internal_originalTime": 1689766025000
    },
    {
        "time": 1689766026000,
        "value": 139.985,
        "_internal_originalTime": 1689766026000
    },
    {
        "time": 1689766027000,
        "value": 139.99,
        "_internal_originalTime": 1689766027000
    },
    {
        "time": 1689766028000,
        "value": 139.989,
        "_internal_originalTime": 1689766028000
    },
    {
        "time": 1689766029000,
        "value": 139.986,
        "_internal_originalTime": 1689766029000
    },
    {
        "time": 1689766030000,
        "value": 139.991,
        "_internal_originalTime": 1689766030000
    },
    {
        "time": 1689766032000,
        "value": 139.986,
        "_internal_originalTime": 1689766032000
    },
    {
        "time": 1689766033000,
        "value": 139.99,
        "_internal_originalTime": 1689766033000
    },
    {
        "time": 1689766034000,
        "value": 139.988,
        "_internal_originalTime": 1689766034000
    },
    {
        "time": 1689766039000,
        "value": 139.992,
        "_internal_originalTime": 1689766039000
    },
    {
        "time": 1689766040000,
        "value": 139.99,
        "_internal_originalTime": 1689766040000
    },
    {
        "time": 1689766041000,
        "value": 139.984,
        "_internal_originalTime": 1689766041000
    },
    {
        "time": 1689766043000,
        "value": 139.987,
        "_internal_originalTime": 1689766043000
    },
    {
        "time": 1689766044000,
        "value": 139.987,
        "_internal_originalTime": 1689766044000
    },
    {
        "time": 1689766045000,
        "value": 139.99,
        "_internal_originalTime": 1689766045000
    },
    {
        "time": 1689766046000,
        "value": 139.987,
        "_internal_originalTime": 1689766046000
    },
    {
        "time": 1689766047000,
        "value": 139.988,
        "_internal_originalTime": 1689766047000
    },
    {
        "time": 1689766049000,
        "value": 139.991,
        "_internal_originalTime": 1689766049000
    },
    {
        "time": 1689766050000,
        "value": 139.988,
        "_internal_originalTime": 1689766050000
    },
    {
        "time": 1689766051000,
        "value": 139.988,
        "_internal_originalTime": 1689766051000
    },
    {
        "time": 1689766052000,
        "value": 139.986,
        "_internal_originalTime": 1689766052000
    },
    {
        "time": 1689766054000,
        "value": 139.989,
        "_internal_originalTime": 1689766054000
    },
    {
        "time": 1689766057000,
        "value": 139.988,
        "_internal_originalTime": 1689766057000
    },
    {
        "time": 1689766058000,
        "value": 139.992,
        "_internal_originalTime": 1689766058000
    },
    {
        "time": 1689766059000,
        "value": 139.992,
        "_internal_originalTime": 1689766059000
    },
    {
        "time": 1689766062000,
        "value": 139.989,
        "_internal_originalTime": 1689766062000
    },
    {
        "time": 1689766064000,
        "value": 139.989,
        "_internal_originalTime": 1689766064000
    },
    {
        "time": 1689766065000,
        "value": 139.99,
        "_internal_originalTime": 1689766065000
    },
    {
        "time": 1689766067000,
        "value": 139.988,
        "_internal_originalTime": 1689766067000
    },
    {
        "time": 1689766068000,
        "value": 139.989,
        "_internal_originalTime": 1689766068000
    },
    {
        "time": 1689766071000,
        "value": 139.991,
        "_internal_originalTime": 1689766071000
    },
    {
        "time": 1689766072000,
        "value": 139.989,
        "_internal_originalTime": 1689766072000
    },
    {
        "time": 1689766074000,
        "value": 139.987,
        "_internal_originalTime": 1689766074000
    },
    {
        "time": 1689766075000,
        "value": 139.987,
        "_internal_originalTime": 1689766075000
    },
    {
        "time": 1689766080000,
        "value": 139.988,
        "_internal_originalTime": 1689766080000
    },
    {
        "time": 1689766081000,
        "value": 139.989,
        "_internal_originalTime": 1689766081000
    },
    {
        "time": 1689766082000,
        "value": 139.987,
        "_internal_originalTime": 1689766082000
    },
    {
        "time": 1689766083000,
        "value": 139.985,
        "_internal_originalTime": 1689766083000
    },
    {
        "time": 1689766085000,
        "value": 139.986,
        "_internal_originalTime": 1689766085000
    },
    {
        "time": 1689766086000,
        "value": 139.985,
        "_internal_originalTime": 1689766086000
    },
    {
        "time": 1689766087000,
        "value": 139.98,
        "_internal_originalTime": 1689766087000
    },
    {
        "time": 1689766088000,
        "value": 139.983,
        "_internal_originalTime": 1689766088000
    },
    {
        "time": 1689766091000,
        "value": 139.985,
        "_internal_originalTime": 1689766091000
    },
    {
        "time": 1689766093000,
        "value": 139.986,
        "_internal_originalTime": 1689766093000
    },
    {
        "time": 1689766095000,
        "value": 139.983,
        "_internal_originalTime": 1689766095000
    },
    {
        "time": 1689766096000,
        "value": 139.98,
        "_internal_originalTime": 1689766096000
    },
    {
        "time": 1689766097000,
        "value": 139.985,
        "_internal_originalTime": 1689766097000
    },
    {
        "time": 1689766099000,
        "value": 139.98,
        "_internal_originalTime": 1689766099000
    },
    {
        "time": 1689766100000,
        "value": 139.98,
        "_internal_originalTime": 1689766100000
    },
    {
        "time": 1689766101000,
        "value": 139.984,
        "_internal_originalTime": 1689766101000
    },
    {
        "time": 1689766102000,
        "value": 139.98,
        "_internal_originalTime": 1689766102000
    },
    {
        "time": 1689766104000,
        "value": 139.98,
        "_internal_originalTime": 1689766104000
    },
    {
        "time": 1689766105000,
        "value": 139.98,
        "_internal_originalTime": 1689766105000
    },
    {
        "time": 1689766108000,
        "value": 139.98,
        "_internal_originalTime": 1689766108000
    },
    {
        "time": 1689766109000,
        "value": 139.982,
        "_internal_originalTime": 1689766109000
    },
    {
        "time": 1689766110000,
        "value": 139.983,
        "_internal_originalTime": 1689766110000
    },
    {
        "time": 1689766111000,
        "value": 139.986,
        "_internal_originalTime": 1689766111000
    },
    {
        "time": 1689766112000,
        "value": 139.987,
        "_internal_originalTime": 1689766112000
    },
    {
        "time": 1689766114000,
        "value": 139.984,
        "_internal_originalTime": 1689766114000
    },
    {
        "time": 1689766115000,
        "value": 139.987,
        "_internal_originalTime": 1689766115000
    },
    {
        "time": 1689766116000,
        "value": 139.983,
        "_internal_originalTime": 1689766116000
    },
    {
        "time": 1689766118000,
        "value": 139.983,
        "_internal_originalTime": 1689766118000
    },
    {
        "time": 1689766119000,
        "value": 139.98,
        "_internal_originalTime": 1689766119000
    },
    {
        "time": 1689766121000,
        "value": 139.986,
        "_internal_originalTime": 1689766121000
    },
    {
        "time": 1689766123000,
        "value": 139.986,
        "_internal_originalTime": 1689766123000
    },
    {
        "time": 1689766124000,
        "value": 139.986,
        "_internal_originalTime": 1689766124000
    },
    {
        "time": 1689766126000,
        "value": 139.982,
        "_internal_originalTime": 1689766126000
    },
    {
        "time": 1689766127000,
        "value": 139.98,
        "_internal_originalTime": 1689766127000
    },
    {
        "time": 1689766128000,
        "value": 139.983,
        "_internal_originalTime": 1689766128000
    },
    {
        "time": 1689766130000,
        "value": 139.98,
        "_internal_originalTime": 1689766130000
    },
    {
        "time": 1689766131000,
        "value": 139.983,
        "_internal_originalTime": 1689766131000
    },
    {
        "time": 1689766132000,
        "value": 139.982,
        "_internal_originalTime": 1689766132000
    },
    {
        "time": 1689766134000,
        "value": 139.984,
        "_internal_originalTime": 1689766134000
    },
    {
        "time": 1689766136000,
        "value": 139.984,
        "_internal_originalTime": 1689766136000
    },
    {
        "time": 1689766138000,
        "value": 139.984,
        "_internal_originalTime": 1689766138000
    },
    {
        "time": 1689766140000,
        "value": 139.984,
        "_internal_originalTime": 1689766140000
    },
    {
        "time": 1689766141000,
        "value": 139.984,
        "_internal_originalTime": 1689766141000
    },
    {
        "time": 1689766142000,
        "value": 139.981,
        "_internal_originalTime": 1689766142000
    },
    {
        "time": 1689766143000,
        "value": 139.986,
        "_internal_originalTime": 1689766143000
    },
    {
        "time": 1689766144000,
        "value": 139.981,
        "_internal_originalTime": 1689766144000
    },
    {
        "time": 1689766145000,
        "value": 139.981,
        "_internal_originalTime": 1689766145000
    },
    {
        "time": 1689766147000,
        "value": 139.985,
        "_internal_originalTime": 1689766147000
    },
    {
        "time": 1689766149000,
        "value": 139.98,
        "_internal_originalTime": 1689766149000
    },
    {
        "time": 1689766150000,
        "value": 139.986,
        "_internal_originalTime": 1689766150000
    },
    {
        "time": 1689766151000,
        "value": 139.986,
        "_internal_originalTime": 1689766151000
    },
    {
        "time": 1689766152000,
        "value": 139.981,
        "_internal_originalTime": 1689766152000
    },
    {
        "time": 1689766153000,
        "value": 139.985,
        "_internal_originalTime": 1689766153000
    },
    {
        "time": 1689766155000,
        "value": 139.985,
        "_internal_originalTime": 1689766155000
    },
    {
        "time": 1689766157000,
        "value": 139.973,
        "_internal_originalTime": 1689766157000
    },
    {
        "time": 1689766158000,
        "value": 139.974,
        "_internal_originalTime": 1689766158000
    },
    {
        "time": 1689766159000,
        "value": 139.974,
        "_internal_originalTime": 1689766159000
    },
    {
        "time": 1689766160000,
        "value": 139.976,
        "_internal_originalTime": 1689766160000
    },
    {
        "time": 1689766161000,
        "value": 139.973,
        "_internal_originalTime": 1689766161000
    },
    {
        "time": 1689766163000,
        "value": 139.974,
        "_internal_originalTime": 1689766163000
    },
    {
        "time": 1689766164000,
        "value": 139.972,
        "_internal_originalTime": 1689766164000
    },
    {
        "time": 1689766169000,
        "value": 139.974,
        "_internal_originalTime": 1689766169000
    },
    {
        "time": 1689766170000,
        "value": 139.977,
        "_internal_originalTime": 1689766170000
    },
    {
        "time": 1689766172000,
        "value": 139.972,
        "_internal_originalTime": 1689766172000
    },
    {
        "time": 1689766173000,
        "value": 139.975,
        "_internal_originalTime": 1689766173000
    },
    {
        "time": 1689766174000,
        "value": 139.97,
        "_internal_originalTime": 1689766174000
    },
    {
        "time": 1689766176000,
        "value": 139.975,
        "_internal_originalTime": 1689766176000
    },
    {
        "time": 1689766177000,
        "value": 139.977,
        "_internal_originalTime": 1689766177000
    },
    {
        "time": 1689766178000,
        "value": 139.974,
        "_internal_originalTime": 1689766178000
    },
    {
        "time": 1689766180000,
        "value": 139.974,
        "_internal_originalTime": 1689766180000
    },
    {
        "time": 1689766182000,
        "value": 139.974,
        "_internal_originalTime": 1689766182000
    },
    {
        "time": 1689766184000,
        "value": 139.974,
        "_internal_originalTime": 1689766184000
    },
    {
        "time": 1689766185000,
        "value": 139.974,
        "_internal_originalTime": 1689766185000
    },
    {
        "time": 1689766186000,
        "value": 139.974,
        "_internal_originalTime": 1689766186000
    },
    {
        "time": 1689766187000,
        "value": 139.974,
        "_internal_originalTime": 1689766187000
    },
    {
        "time": 1689766189000,
        "value": 139.977,
        "_internal_originalTime": 1689766189000
    },
    {
        "time": 1689766190000,
        "value": 139.974,
        "_internal_originalTime": 1689766190000
    },
    {
        "time": 1689766192000,
        "value": 139.973,
        "_internal_originalTime": 1689766192000
    },
    {
        "time": 1689766199000,
        "value": 139.974,
        "_internal_originalTime": 1689766199000
    },
    {
        "time": 1689766200000,
        "value": 139.972,
        "_internal_originalTime": 1689766200000
    },
    {
        "time": 1689766201000,
        "value": 139.973,
        "_internal_originalTime": 1689766201000
    },
    {
        "time": 1689766203000,
        "value": 139.976,
        "_internal_originalTime": 1689766203000
    },
    {
        "time": 1689766204000,
        "value": 139.976,
        "_internal_originalTime": 1689766204000
    },
    {
        "time": 1689766205000,
        "value": 139.972,
        "_internal_originalTime": 1689766205000
    },
    {
        "time": 1689766206000,
        "value": 139.973,
        "_internal_originalTime": 1689766206000
    },
    {
        "time": 1689766208000,
        "value": 139.974,
        "_internal_originalTime": 1689766208000
    },
    {
        "time": 1689766209000,
        "value": 139.976,
        "_internal_originalTime": 1689766209000
    },
    {
        "time": 1689766211000,
        "value": 139.971,
        "_internal_originalTime": 1689766211000
    },
    {
        "time": 1689766213000,
        "value": 139.973,
        "_internal_originalTime": 1689766213000
    },
    {
        "time": 1689766214000,
        "value": 139.976,
        "_internal_originalTime": 1689766214000
    },
    {
        "time": 1689766216000,
        "value": 139.976,
        "_internal_originalTime": 1689766216000
    },
    {
        "time": 1689766218000,
        "value": 139.976,
        "_internal_originalTime": 1689766218000
    },
    {
        "time": 1689766220000,
        "value": 139.976,
        "_internal_originalTime": 1689766220000
    },
    {
        "time": 1689766221000,
        "value": 139.976,
        "_internal_originalTime": 1689766221000
    },
    {
        "time": 1689766222000,
        "value": 139.973,
        "_internal_originalTime": 1689766222000
    },
    {
        "time": 1689766223000,
        "value": 139.976,
        "_internal_originalTime": 1689766223000
    },
    {
        "time": 1689766224000,
        "value": 139.973,
        "_internal_originalTime": 1689766224000
    },
    {
        "time": 1689766225000,
        "value": 139.973,
        "_internal_originalTime": 1689766225000
    },
    {
        "time": 1689766228000,
        "value": 139.974,
        "_internal_originalTime": 1689766228000
    },
    {
        "time": 1689766229000,
        "value": 139.976,
        "_internal_originalTime": 1689766229000
    },
    {
        "time": 1689766230000,
        "value": 139.973,
        "_internal_originalTime": 1689766230000
    },
    {
        "time": 1689766231000,
        "value": 139.973,
        "_internal_originalTime": 1689766231000
    },
    {
        "time": 1689766233000,
        "value": 139.973,
        "_internal_originalTime": 1689766233000
    },
    {
        "time": 1689766236000,
        "value": 139.974,
        "_internal_originalTime": 1689766236000
    },
    {
        "time": 1689766237000,
        "value": 139.974,
        "_internal_originalTime": 1689766237000
    },
    {
        "time": 1689766238000,
        "value": 139.984,
        "_internal_originalTime": 1689766238000
    },
    {
        "time": 1689766240000,
        "value": 139.986,
        "_internal_originalTime": 1689766240000
    },
    {
        "time": 1689766241000,
        "value": 139.986,
        "_internal_originalTime": 1689766241000
    },
    {
        "time": 1689766243000,
        "value": 139.981,
        "_internal_originalTime": 1689766243000
    },
    {
        "time": 1689766244000,
        "value": 139.976,
        "_internal_originalTime": 1689766244000
    },
    {
        "time": 1689766246000,
        "value": 139.97,
        "_internal_originalTime": 1689766246000
    },
    {
        "time": 1689766247000,
        "value": 139.973,
        "_internal_originalTime": 1689766247000
    },
    {
        "time": 1689766248000,
        "value": 139.978,
        "_internal_originalTime": 1689766248000
    },
    {
        "time": 1689766250000,
        "value": 139.972,
        "_internal_originalTime": 1689766250000
    },
    {
        "time": 1689766251000,
        "value": 139.973,
        "_internal_originalTime": 1689766251000
    },
    {
        "time": 1689766252000,
        "value": 139.975,
        "_internal_originalTime": 1689766252000
    },
    {
        "time": 1689766255000,
        "value": 139.974,
        "_internal_originalTime": 1689766255000
    },
    {
        "time": 1689766256000,
        "value": 139.97,
        "_internal_originalTime": 1689766256000
    },
    {
        "time": 1689766258000,
        "value": 139.977,
        "_internal_originalTime": 1689766258000
    },
    {
        "time": 1689766259000,
        "value": 139.976,
        "_internal_originalTime": 1689766259000
    },
    {
        "time": 1689766261000,
        "value": 139.972,
        "_internal_originalTime": 1689766261000
    },
    {
        "time": 1689766262000,
        "value": 139.973,
        "_internal_originalTime": 1689766262000
    },
    {
        "time": 1689766263000,
        "value": 139.973,
        "_internal_originalTime": 1689766263000
    },
    {
        "time": 1689766264000,
        "value": 139.97,
        "_internal_originalTime": 1689766264000
    },
    {
        "time": 1689766265000,
        "value": 139.972,
        "_internal_originalTime": 1689766265000
    },
    {
        "time": 1689766268000,
        "value": 139.97,
        "_internal_originalTime": 1689766268000
    },
    {
        "time": 1689766269000,
        "value": 139.975,
        "_internal_originalTime": 1689766269000
    },
    {
        "time": 1689766270000,
        "value": 139.97,
        "_internal_originalTime": 1689766270000
    },
    {
        "time": 1689766272000,
        "value": 139.976,
        "_internal_originalTime": 1689766272000
    },
    {
        "time": 1689766273000,
        "value": 139.972,
        "_internal_originalTime": 1689766273000
    },
    {
        "time": 1689766274000,
        "value": 139.972,
        "_internal_originalTime": 1689766274000
    },
    {
        "time": 1689766275000,
        "value": 139.972,
        "_internal_originalTime": 1689766275000
    },
    {
        "time": 1689766277000,
        "value": 139.971,
        "_internal_originalTime": 1689766277000
    },
    {
        "time": 1689766280000,
        "value": 139.972,
        "_internal_originalTime": 1689766280000
    },
    {
        "time": 1689766282000,
        "value": 139.976,
        "_internal_originalTime": 1689766282000
    },
    {
        "time": 1689766283000,
        "value": 139.962,
        "_internal_originalTime": 1689766283000
    },
    {
        "time": 1689766284000,
        "value": 139.96,
        "_internal_originalTime": 1689766284000
    },
    {
        "time": 1689766286000,
        "value": 139.963,
        "_internal_originalTime": 1689766286000
    },
    {
        "time": 1689766287000,
        "value": 139.947,
        "_internal_originalTime": 1689766287000
    },
    {
        "time": 1689766288000,
        "value": 139.948,
        "_internal_originalTime": 1689766288000
    },
    {
        "time": 1689766290000,
        "value": 139.951,
        "_internal_originalTime": 1689766290000
    },
    {
        "time": 1689766292000,
        "value": 139.95,
        "_internal_originalTime": 1689766292000
    },
    {
        "time": 1689766293000,
        "value": 139.951,
        "_internal_originalTime": 1689766293000
    },
    {
        "time": 1689766294000,
        "value": 139.952,
        "_internal_originalTime": 1689766294000
    },
    {
        "time": 1689766295000,
        "value": 139.95,
        "_internal_originalTime": 1689766295000
    },
    {
        "time": 1689766297000,
        "value": 139.954,
        "_internal_originalTime": 1689766297000
    },
    {
        "time": 1689766299000,
        "value": 139.952,
        "_internal_originalTime": 1689766299000
    },
    {
        "time": 1689766300000,
        "value": 139.954,
        "_internal_originalTime": 1689766300000
    },
    {
        "time": 1689766301000,
        "value": 139.962,
        "_internal_originalTime": 1689766301000
    },
    {
        "time": 1689766303000,
        "value": 139.962,
        "_internal_originalTime": 1689766303000
    },
    {
        "time": 1689766304000,
        "value": 139.963,
        "_internal_originalTime": 1689766304000
    },
    {
        "time": 1689766305000,
        "value": 139.962,
        "_internal_originalTime": 1689766305000
    },
    {
        "time": 1689766306000,
        "value": 139.963,
        "_internal_originalTime": 1689766306000
    },
    {
        "time": 1689766307000,
        "value": 139.961,
        "_internal_originalTime": 1689766307000
    },
    {
        "time": 1689766308000,
        "value": 139.968,
        "_internal_originalTime": 1689766308000
    },
    {
        "time": 1689766313000,
        "value": 139.97,
        "_internal_originalTime": 1689766313000
    },
    {
        "time": 1689766314000,
        "value": 139.972,
        "_internal_originalTime": 1689766314000
    },
    {
        "time": 1689766315000,
        "value": 139.978,
        "_internal_originalTime": 1689766315000
    },
    {
        "time": 1689766316000,
        "value": 139.978,
        "_internal_originalTime": 1689766316000
    },
    {
        "time": 1689766319000,
        "value": 139.979,
        "_internal_originalTime": 1689766319000
    },
    {
        "time": 1689766320000,
        "value": 139.978,
        "_internal_originalTime": 1689766320000
    },
    {
        "time": 1689766322000,
        "value": 139.981,
        "_internal_originalTime": 1689766322000
    },
    {
        "time": 1689766323000,
        "value": 139.983,
        "_internal_originalTime": 1689766323000
    },
    {
        "time": 1689766324000,
        "value": 139.983,
        "_internal_originalTime": 1689766324000
    },
    {
        "time": 1689766325000,
        "value": 139.982,
        "_internal_originalTime": 1689766325000
    },
    {
        "time": 1689766326000,
        "value": 139.983,
        "_internal_originalTime": 1689766326000
    },
    {
        "time": 1689766327000,
        "value": 139.978,
        "_internal_originalTime": 1689766327000
    },
    {
        "time": 1689766328000,
        "value": 139.983,
        "_internal_originalTime": 1689766328000
    },
    {
        "time": 1689766330000,
        "value": 139.981,
        "_internal_originalTime": 1689766330000
    },
    {
        "time": 1689766331000,
        "value": 139.981,
        "_internal_originalTime": 1689766331000
    },
    {
        "time": 1689766333000,
        "value": 139.988,
        "_internal_originalTime": 1689766333000
    },
    {
        "time": 1689766334000,
        "value": 139.988,
        "_internal_originalTime": 1689766334000
    },
    {
        "time": 1689766335000,
        "value": 139.986,
        "_internal_originalTime": 1689766335000
    },
    {
        "time": 1689766338000,
        "value": 139.991,
        "_internal_originalTime": 1689766338000
    },
    {
        "time": 1689766341000,
        "value": 139.992,
        "_internal_originalTime": 1689766341000
    },
    {
        "time": 1689766343000,
        "value": 139.99,
        "_internal_originalTime": 1689766343000
    },
    {
        "time": 1689766345000,
        "value": 139.989,
        "_internal_originalTime": 1689766345000
    },
    {
        "time": 1689766347000,
        "value": 139.99,
        "_internal_originalTime": 1689766347000
    },
    {
        "time": 1689766348000,
        "value": 139.988,
        "_internal_originalTime": 1689766348000
    },
    {
        "time": 1689766350000,
        "value": 139.991,
        "_internal_originalTime": 1689766350000
    },
    {
        "time": 1689766351000,
        "value": 139.988,
        "_internal_originalTime": 1689766351000
    },
    {
        "time": 1689766353000,
        "value": 139.99,
        "_internal_originalTime": 1689766353000
    },
    {
        "time": 1689766354000,
        "value": 139.988,
        "_internal_originalTime": 1689766354000
    },
    {
        "time": 1689766356000,
        "value": 139.988,
        "_internal_originalTime": 1689766356000
    },
    {
        "time": 1689766358000,
        "value": 139.99,
        "_internal_originalTime": 1689766358000
    },
    {
        "time": 1689766359000,
        "value": 139.99,
        "_internal_originalTime": 1689766359000
    },
    {
        "time": 1689766361000,
        "value": 139.984,
        "_internal_originalTime": 1689766361000
    },
    {
        "time": 1689766363000,
        "value": 139.986,
        "_internal_originalTime": 1689766363000
    },
    {
        "time": 1689766364000,
        "value": 139.982,
        "_internal_originalTime": 1689766364000
    },
    {
        "time": 1689766365000,
        "value": 139.984,
        "_internal_originalTime": 1689766365000
    },
    {
        "time": 1689766366000,
        "value": 139.985,
        "_internal_originalTime": 1689766366000
    },
    {
        "time": 1689766367000,
        "value": 139.988,
        "_internal_originalTime": 1689766367000
    },
    {
        "time": 1689766369000,
        "value": 139.984,
        "_internal_originalTime": 1689766369000
    },
    {
        "time": 1689766371000,
        "value": 139.986,
        "_internal_originalTime": 1689766371000
    },
    {
        "time": 1689766373000,
        "value": 139.992,
        "_internal_originalTime": 1689766373000
    },
    {
        "time": 1689766374000,
        "value": 139.99,
        "_internal_originalTime": 1689766374000
    },
    {
        "time": 1689766376000,
        "value": 139.99,
        "_internal_originalTime": 1689766376000
    },
    {
        "time": 1689766377000,
        "value": 139.988,
        "_internal_originalTime": 1689766377000
    },
    {
        "time": 1689766378000,
        "value": 139.987,
        "_internal_originalTime": 1689766378000
    },
    {
        "time": 1689766379000,
        "value": 139.989,
        "_internal_originalTime": 1689766379000
    },
    {
        "time": 1689766381000,
        "value": 139.987,
        "_internal_originalTime": 1689766381000
    },
    {
        "time": 1689766382000,
        "value": 139.981,
        "_internal_originalTime": 1689766382000
    },
    {
        "time": 1689766383000,
        "value": 139.985,
        "_internal_originalTime": 1689766383000
    },
    {
        "time": 1689766384000,
        "value": 139.984,
        "_internal_originalTime": 1689766384000
    },
    {
        "time": 1689766385000,
        "value": 139.99,
        "_internal_originalTime": 1689766385000
    },
    {
        "time": 1689766387000,
        "value": 139.989,
        "_internal_originalTime": 1689766387000
    },
    {
        "time": 1689766388000,
        "value": 139.988,
        "_internal_originalTime": 1689766388000
    },
    {
        "time": 1689766389000,
        "value": 139.991,
        "_internal_originalTime": 1689766389000
    },
    {
        "time": 1689766391000,
        "value": 139.988,
        "_internal_originalTime": 1689766391000
    },
    {
        "time": 1689766392000,
        "value": 139.989,
        "_internal_originalTime": 1689766392000
    },
    {
        "time": 1689766393000,
        "value": 139.986,
        "_internal_originalTime": 1689766393000
    },
    {
        "time": 1689766394000,
        "value": 139.987,
        "_internal_originalTime": 1689766394000
    },
    {
        "time": 1689766400000,
        "value": 139.986,
        "_internal_originalTime": 1689766400000
    },
    {
        "time": 1689766401000,
        "value": 139.985,
        "_internal_originalTime": 1689766401000
    },
    {
        "time": 1689766402000,
        "value": 139.986,
        "_internal_originalTime": 1689766402000
    },
    {
        "time": 1689766403000,
        "value": 139.987,
        "_internal_originalTime": 1689766403000
    },
    {
        "time": 1689766404000,
        "value": 139.988,
        "_internal_originalTime": 1689766404000
    },
    {
        "time": 1689766406000,
        "value": 139.99,
        "_internal_originalTime": 1689766406000
    },
    {
        "time": 1689766407000,
        "value": 139.988,
        "_internal_originalTime": 1689766407000
    },
    {
        "time": 1689766412000,
        "value": 139.984,
        "_internal_originalTime": 1689766412000
    },
    {
        "time": 1689766413000,
        "value": 139.984,
        "_internal_originalTime": 1689766413000
    },
    {
        "time": 1689766414000,
        "value": 139.982,
        "_internal_originalTime": 1689766414000
    },
    {
        "time": 1689766416000,
        "value": 139.985,
        "_internal_originalTime": 1689766416000
    },
    {
        "time": 1689766417000,
        "value": 139.985,
        "_internal_originalTime": 1689766417000
    },
    {
        "time": 1689766419000,
        "value": 139.987,
        "_internal_originalTime": 1689766419000
    },
    {
        "time": 1689766420000,
        "value": 139.988,
        "_internal_originalTime": 1689766420000
    },
    {
        "time": 1689766421000,
        "value": 139.988,
        "_internal_originalTime": 1689766421000
    },
    {
        "time": 1689766422000,
        "value": 139.988,
        "_internal_originalTime": 1689766422000
    },
    {
        "time": 1689766425000,
        "value": 139.984,
        "_internal_originalTime": 1689766425000
    },
    {
        "time": 1689766427000,
        "value": 139.982,
        "_internal_originalTime": 1689766427000
    },
    {
        "time": 1689766428000,
        "value": 139.987,
        "_internal_originalTime": 1689766428000
    },
    {
        "time": 1689766429000,
        "value": 139.988,
        "_internal_originalTime": 1689766429000
    },
    {
        "time": 1689766431000,
        "value": 139.99,
        "_internal_originalTime": 1689766431000
    },
    {
        "time": 1689766432000,
        "value": 139.989,
        "_internal_originalTime": 1689766432000
    },
    {
        "time": 1689766433000,
        "value": 139.987,
        "_internal_originalTime": 1689766433000
    },
    {
        "time": 1689766435000,
        "value": 139.988,
        "_internal_originalTime": 1689766435000
    },
    {
        "time": 1689766436000,
        "value": 139.988,
        "_internal_originalTime": 1689766436000
    },
    {
        "time": 1689766439000,
        "value": 139.986,
        "_internal_originalTime": 1689766439000
    },
    {
        "time": 1689766440000,
        "value": 139.985,
        "_internal_originalTime": 1689766440000
    },
    {
        "time": 1689766441000,
        "value": 139.985,
        "_internal_originalTime": 1689766441000
    },
    {
        "time": 1689766442000,
        "value": 139.987,
        "_internal_originalTime": 1689766442000
    },
    {
        "time": 1689766443000,
        "value": 139.986,
        "_internal_originalTime": 1689766443000
    },
    {
        "time": 1689766445000,
        "value": 139.988,
        "_internal_originalTime": 1689766445000
    },
    {
        "time": 1689766446000,
        "value": 139.99,
        "_internal_originalTime": 1689766446000
    },
    {
        "time": 1689766447000,
        "value": 139.989,
        "_internal_originalTime": 1689766447000
    },
    {
        "time": 1689766448000,
        "value": 139.987,
        "_internal_originalTime": 1689766448000
    },
    {
        "time": 1689766451000,
        "value": 139.991,
        "_internal_originalTime": 1689766451000
    },
    {
        "time": 1689766459000,
        "value": 139.991,
        "_internal_originalTime": 1689766459000
    },
    {
        "time": 1689766462000,
        "value": 139.987,
        "_internal_originalTime": 1689766462000
    },
    {
        "time": 1689766463000,
        "value": 139.989,
        "_internal_originalTime": 1689766463000
    },
    {
        "time": 1689766465000,
        "value": 139.985,
        "_internal_originalTime": 1689766465000
    },
    {
        "time": 1689766466000,
        "value": 139.984,
        "_internal_originalTime": 1689766466000
    },
    {
        "time": 1689766468000,
        "value": 139.988,
        "_internal_originalTime": 1689766468000
    },
    {
        "time": 1689766469000,
        "value": 139.985,
        "_internal_originalTime": 1689766469000
    },
    {
        "time": 1689766470000,
        "value": 139.984,
        "_internal_originalTime": 1689766470000
    },
    {
        "time": 1689766472000,
        "value": 139.986,
        "_internal_originalTime": 1689766472000
    },
    {
        "time": 1689766473000,
        "value": 139.987,
        "_internal_originalTime": 1689766473000
    },
    {
        "time": 1689766474000,
        "value": 139.986,
        "_internal_originalTime": 1689766474000
    },
    {
        "time": 1689766476000,
        "value": 139.983,
        "_internal_originalTime": 1689766476000
    },
    {
        "time": 1689766477000,
        "value": 139.983,
        "_internal_originalTime": 1689766477000
    },
    {
        "time": 1689766478000,
        "value": 139.983,
        "_internal_originalTime": 1689766478000
    },
    {
        "time": 1689766479000,
        "value": 139.985,
        "_internal_originalTime": 1689766479000
    },
    {
        "time": 1689766480000,
        "value": 139.986,
        "_internal_originalTime": 1689766480000
    },
    {
        "time": 1689766481000,
        "value": 139.988,
        "_internal_originalTime": 1689766481000
    },
    {
        "time": 1689766482000,
        "value": 139.987,
        "_internal_originalTime": 1689766482000
    },
    {
        "time": 1689766483000,
        "value": 139.989,
        "_internal_originalTime": 1689766483000
    },
    {
        "time": 1689766484000,
        "value": 139.989,
        "_internal_originalTime": 1689766484000
    },
    {
        "time": 1689766485000,
        "value": 139.989,
        "_internal_originalTime": 1689766485000
    },
    {
        "time": 1689766486000,
        "value": 139.99,
        "_internal_originalTime": 1689766486000
    },
    {
        "time": 1689766487000,
        "value": 139.988,
        "_internal_originalTime": 1689766487000
    },
    {
        "time": 1689766489000,
        "value": 139.986,
        "_internal_originalTime": 1689766489000
    },
    {
        "time": 1689766490000,
        "value": 139.987,
        "_internal_originalTime": 1689766490000
    },
    {
        "time": 1689766492000,
        "value": 139.986,
        "_internal_originalTime": 1689766492000
    },
    {
        "time": 1689766494000,
        "value": 139.99,
        "_internal_originalTime": 1689766494000
    },
    {
        "time": 1689766496000,
        "value": 139.991,
        "_internal_originalTime": 1689766496000
    },
    {
        "time": 1689766497000,
        "value": 139.991,
        "_internal_originalTime": 1689766497000
    },
    {
        "time": 1689766498000,
        "value": 139.988,
        "_internal_originalTime": 1689766498000
    },
    {
        "time": 1689766499000,
        "value": 139.989,
        "_internal_originalTime": 1689766499000
    },
    {
        "time": 1689766500000,
        "value": 139.988,
        "_internal_originalTime": 1689766500000
    },
    {
        "time": 1689766501000,
        "value": 139.97,
        "_internal_originalTime": 1689766501000
    },
    {
        "time": 1689766503000,
        "value": 139.99,
        "_internal_originalTime": 1689766503000
    },
    {
        "time": 1689766504000,
        "value": 139.987,
        "_internal_originalTime": 1689766504000
    },
    {
        "time": 1689766505000,
        "value": 139.982,
        "_internal_originalTime": 1689766505000
    },
    {
        "time": 1689766507000,
        "value": 139.986,
        "_internal_originalTime": 1689766507000
    },
    {
        "time": 1689766508000,
        "value": 139.987,
        "_internal_originalTime": 1689766508000
    },
    {
        "time": 1689766509000,
        "value": 139.988,
        "_internal_originalTime": 1689766509000
    },
    {
        "time": 1689766511000,
        "value": 139.984,
        "_internal_originalTime": 1689766511000
    },
    {
        "time": 1689766512000,
        "value": 139.982,
        "_internal_originalTime": 1689766512000
    },
    {
        "time": 1689766513000,
        "value": 139.982,
        "_internal_originalTime": 1689766513000
    },
    {
        "time": 1689766514000,
        "value": 139.982,
        "_internal_originalTime": 1689766514000
    },
    {
        "time": 1689766515000,
        "value": 139.982,
        "_internal_originalTime": 1689766515000
    },
    {
        "time": 1689766516000,
        "value": 139.983,
        "_internal_originalTime": 1689766516000
    },
    {
        "time": 1689766517000,
        "value": 139.983,
        "_internal_originalTime": 1689766517000
    },
    {
        "time": 1689766518000,
        "value": 139.98,
        "_internal_originalTime": 1689766518000
    },
    {
        "time": 1689766520000,
        "value": 139.982,
        "_internal_originalTime": 1689766520000
    },
    {
        "time": 1689766521000,
        "value": 139.984,
        "_internal_originalTime": 1689766521000
    },
    {
        "time": 1689766524000,
        "value": 139.979,
        "_internal_originalTime": 1689766524000
    },
    {
        "time": 1689766525000,
        "value": 139.977,
        "_internal_originalTime": 1689766525000
    },
    {
        "time": 1689766527000,
        "value": 139.976,
        "_internal_originalTime": 1689766527000
    },
    {
        "time": 1689766528000,
        "value": 139.981,
        "_internal_originalTime": 1689766528000
    },
    {
        "time": 1689766533000,
        "value": 139.98,
        "_internal_originalTime": 1689766533000
    },
    {
        "time": 1689766534000,
        "value": 139.978,
        "_internal_originalTime": 1689766534000
    },
    {
        "time": 1689766538000,
        "value": 139.976,
        "_internal_originalTime": 1689766538000
    },
    {
        "time": 1689766541000,
        "value": 139.978,
        "_internal_originalTime": 1689766541000
    },
    {
        "time": 1689766542000,
        "value": 139.981,
        "_internal_originalTime": 1689766542000
    },
    {
        "time": 1689766544000,
        "value": 139.978,
        "_internal_originalTime": 1689766544000
    },
    {
        "time": 1689766546000,
        "value": 139.979,
        "_internal_originalTime": 1689766546000
    },
    {
        "time": 1689766548000,
        "value": 139.978,
        "_internal_originalTime": 1689766548000
    },
    {
        "time": 1689766549000,
        "value": 139.974,
        "_internal_originalTime": 1689766549000
    },
    {
        "time": 1689766551000,
        "value": 139.976,
        "_internal_originalTime": 1689766551000
    },
    {
        "time": 1689766552000,
        "value": 139.974,
        "_internal_originalTime": 1689766552000
    },
    {
        "time": 1689766553000,
        "value": 139.974,
        "_internal_originalTime": 1689766553000
    },
    {
        "time": 1689766555000,
        "value": 139.975,
        "_internal_originalTime": 1689766555000
    },
    {
        "time": 1689766556000,
        "value": 139.975,
        "_internal_originalTime": 1689766556000
    },
    {
        "time": 1689766557000,
        "value": 139.978,
        "_internal_originalTime": 1689766557000
    },
    {
        "time": 1689766558000,
        "value": 139.978,
        "_internal_originalTime": 1689766558000
    },
    {
        "time": 1689766559000,
        "value": 139.978,
        "_internal_originalTime": 1689766559000
    },
    {
        "time": 1689766560000,
        "value": 139.96,
        "_internal_originalTime": 1689766560000
    },
    {
        "time": 1689766561000,
        "value": 139.978,
        "_internal_originalTime": 1689766561000
    },
    {
        "time": 1689766564000,
        "value": 139.974,
        "_internal_originalTime": 1689766564000
    },
    {
        "time": 1689766565000,
        "value": 139.978,
        "_internal_originalTime": 1689766565000
    },
    {
        "time": 1689766568000,
        "value": 139.972,
        "_internal_originalTime": 1689766568000
    },
    {
        "time": 1689766570000,
        "value": 139.972,
        "_internal_originalTime": 1689766570000
    },
    {
        "time": 1689766572000,
        "value": 139.969,
        "_internal_originalTime": 1689766572000
    },
    {
        "time": 1689766573000,
        "value": 139.966,
        "_internal_originalTime": 1689766573000
    },
    {
        "time": 1689766576000,
        "value": 139.965,
        "_internal_originalTime": 1689766576000
    },
    {
        "time": 1689766577000,
        "value": 139.962,
        "_internal_originalTime": 1689766577000
    },
    {
        "time": 1689766579000,
        "value": 139.962,
        "_internal_originalTime": 1689766579000
    },
    {
        "time": 1689766580000,
        "value": 139.964,
        "_internal_originalTime": 1689766580000
    },
    {
        "time": 1689766581000,
        "value": 139.964,
        "_internal_originalTime": 1689766581000
    },
    {
        "time": 1689766583000,
        "value": 139.96,
        "_internal_originalTime": 1689766583000
    },
    {
        "time": 1689766584000,
        "value": 139.956,
        "_internal_originalTime": 1689766584000
    },
    {
        "time": 1689766586000,
        "value": 139.96,
        "_internal_originalTime": 1689766586000
    },
    {
        "time": 1689766587000,
        "value": 139.958,
        "_internal_originalTime": 1689766587000
    },
    {
        "time": 1689766588000,
        "value": 139.961,
        "_internal_originalTime": 1689766588000
    },
    {
        "time": 1689766589000,
        "value": 139.958,
        "_internal_originalTime": 1689766589000
    },
    {
        "time": 1689766590000,
        "value": 139.959,
        "_internal_originalTime": 1689766590000
    },
    {
        "time": 1689766592000,
        "value": 139.964,
        "_internal_originalTime": 1689766592000
    },
    {
        "time": 1689766594000,
        "value": 139.963,
        "_internal_originalTime": 1689766594000
    },
    {
        "time": 1689766596000,
        "value": 139.965,
        "_internal_originalTime": 1689766596000
    },
    {
        "time": 1689766597000,
        "value": 139.965,
        "_internal_originalTime": 1689766597000
    },
    {
        "time": 1689766598000,
        "value": 139.965,
        "_internal_originalTime": 1689766598000
    },
    {
        "time": 1689766600000,
        "value": 139.968,
        "_internal_originalTime": 1689766600000
    },
    {
        "time": 1689766601000,
        "value": 139.967,
        "_internal_originalTime": 1689766601000
    },
    {
        "time": 1689766606000,
        "value": 139.966,
        "_internal_originalTime": 1689766606000
    },
    {
        "time": 1689766607000,
        "value": 139.963,
        "_internal_originalTime": 1689766607000
    },
    {
        "time": 1689766608000,
        "value": 139.962,
        "_internal_originalTime": 1689766608000
    },
    {
        "time": 1689766610000,
        "value": 139.968,
        "_internal_originalTime": 1689766610000
    },
    {
        "time": 1689766612000,
        "value": 139.967,
        "_internal_originalTime": 1689766612000
    },
    {
        "time": 1689766613000,
        "value": 139.966,
        "_internal_originalTime": 1689766613000
    },
    {
        "time": 1689766614000,
        "value": 139.971,
        "_internal_originalTime": 1689766614000
    },
    {
        "time": 1689766616000,
        "value": 139.97,
        "_internal_originalTime": 1689766616000
    },
    {
        "time": 1689766617000,
        "value": 139.97,
        "_internal_originalTime": 1689766617000
    },
    {
        "time": 1689766620000,
        "value": 139.971,
        "_internal_originalTime": 1689766620000
    },
    {
        "time": 1689766622000,
        "value": 139.97,
        "_internal_originalTime": 1689766622000
    },
    {
        "time": 1689766623000,
        "value": 139.974,
        "_internal_originalTime": 1689766623000
    },
    {
        "time": 1689766624000,
        "value": 139.972,
        "_internal_originalTime": 1689766624000
    },
    {
        "time": 1689766625000,
        "value": 139.974,
        "_internal_originalTime": 1689766625000
    },
    {
        "time": 1689766627000,
        "value": 139.973,
        "_internal_originalTime": 1689766627000
    },
    {
        "time": 1689766628000,
        "value": 139.973,
        "_internal_originalTime": 1689766628000
    },
    {
        "time": 1689766629000,
        "value": 139.972,
        "_internal_originalTime": 1689766629000
    },
    {
        "time": 1689766630000,
        "value": 139.967,
        "_internal_originalTime": 1689766630000
    },
    {
        "time": 1689766631000,
        "value": 139.964,
        "_internal_originalTime": 1689766631000
    },
    {
        "time": 1689766635000,
        "value": 139.968,
        "_internal_originalTime": 1689766635000
    },
    {
        "time": 1689766636000,
        "value": 139.972,
        "_internal_originalTime": 1689766636000
    },
    {
        "time": 1689766637000,
        "value": 139.971,
        "_internal_originalTime": 1689766637000
    },
    {
        "time": 1689766638000,
        "value": 139.967,
        "_internal_originalTime": 1689766638000
    },
    {
        "time": 1689766640000,
        "value": 139.967,
        "_internal_originalTime": 1689766640000
    },
    {
        "time": 1689766641000,
        "value": 139.971,
        "_internal_originalTime": 1689766641000
    },
    {
        "time": 1689766643000,
        "value": 139.967,
        "_internal_originalTime": 1689766643000
    },
    {
        "time": 1689766644000,
        "value": 139.966,
        "_internal_originalTime": 1689766644000
    },
    {
        "time": 1689766645000,
        "value": 139.967,
        "_internal_originalTime": 1689766645000
    },
    {
        "time": 1689766648000,
        "value": 139.971,
        "_internal_originalTime": 1689766648000
    },
    {
        "time": 1689766650000,
        "value": 139.973,
        "_internal_originalTime": 1689766650000
    },
    {
        "time": 1689766651000,
        "value": 139.973,
        "_internal_originalTime": 1689766651000
    },
    {
        "time": 1689766652000,
        "value": 139.976,
        "_internal_originalTime": 1689766652000
    },
    {
        "time": 1689766655000,
        "value": 139.976,
        "_internal_originalTime": 1689766655000
    },
    {
        "time": 1689766656000,
        "value": 139.972,
        "_internal_originalTime": 1689766656000
    },
    {
        "time": 1689766658000,
        "value": 139.974,
        "_internal_originalTime": 1689766658000
    },
    {
        "time": 1689766659000,
        "value": 139.979,
        "_internal_originalTime": 1689766659000
    },
    {
        "time": 1689766660000,
        "value": 139.982,
        "_internal_originalTime": 1689766660000
    },
    {
        "time": 1689766665000,
        "value": 139.976,
        "_internal_originalTime": 1689766665000
    },
    {
        "time": 1689766666000,
        "value": 139.978,
        "_internal_originalTime": 1689766666000
    },
    {
        "time": 1689766668000,
        "value": 139.981,
        "_internal_originalTime": 1689766668000
    },
    {
        "time": 1689766670000,
        "value": 139.977,
        "_internal_originalTime": 1689766670000
    },
    {
        "time": 1689766671000,
        "value": 139.974,
        "_internal_originalTime": 1689766671000
    },
    {
        "time": 1689766673000,
        "value": 139.976,
        "_internal_originalTime": 1689766673000
    },
    {
        "time": 1689766674000,
        "value": 139.974,
        "_internal_originalTime": 1689766674000
    },
    {
        "time": 1689766676000,
        "value": 139.976,
        "_internal_originalTime": 1689766676000
    },
    {
        "time": 1689766677000,
        "value": 139.975,
        "_internal_originalTime": 1689766677000
    },
    {
        "time": 1689766678000,
        "value": 139.97,
        "_internal_originalTime": 1689766678000
    },
    {
        "time": 1689766680000,
        "value": 139.968,
        "_internal_originalTime": 1689766680000
    },
    {
        "time": 1689766681000,
        "value": 139.964,
        "_internal_originalTime": 1689766681000
    },
    {
        "time": 1689766682000,
        "value": 139.97,
        "_internal_originalTime": 1689766682000
    },
    {
        "time": 1689766685000,
        "value": 139.968,
        "_internal_originalTime": 1689766685000
    },
    {
        "time": 1689766688000,
        "value": 139.962,
        "_internal_originalTime": 1689766688000
    },
    {
        "time": 1689766691000,
        "value": 139.968,
        "_internal_originalTime": 1689766691000
    },
    {
        "time": 1689766693000,
        "value": 139.963,
        "_internal_originalTime": 1689766693000
    },
    {
        "time": 1689766694000,
        "value": 139.967,
        "_internal_originalTime": 1689766694000
    },
    {
        "time": 1689766696000,
        "value": 139.966,
        "_internal_originalTime": 1689766696000
    },
    {
        "time": 1689766700000,
        "value": 139.962,
        "_internal_originalTime": 1689766700000
    },
    {
        "time": 1689766701000,
        "value": 139.967,
        "_internal_originalTime": 1689766701000
    },
    {
        "time": 1689766704000,
        "value": 139.966,
        "_internal_originalTime": 1689766704000
    },
    {
        "time": 1689766709000,
        "value": 139.964,
        "_internal_originalTime": 1689766709000
    },
    {
        "time": 1689766710000,
        "value": 139.962,
        "_internal_originalTime": 1689766710000
    },
    {
        "time": 1689766711000,
        "value": 139.962,
        "_internal_originalTime": 1689766711000
    },
    {
        "time": 1689766712000,
        "value": 139.962,
        "_internal_originalTime": 1689766712000
    },
    {
        "time": 1689766713000,
        "value": 139.961,
        "_internal_originalTime": 1689766713000
    },
    {
        "time": 1689766714000,
        "value": 139.957,
        "_internal_originalTime": 1689766714000
    },
    {
        "time": 1689766717000,
        "value": 139.955,
        "_internal_originalTime": 1689766717000
    },
    {
        "time": 1689766719000,
        "value": 139.948,
        "_internal_originalTime": 1689766719000
    },
    {
        "time": 1689766720000,
        "value": 139.954,
        "_internal_originalTime": 1689766720000
    },
    {
        "time": 1689766723000,
        "value": 139.958,
        "_internal_originalTime": 1689766723000
    },
    {
        "time": 1689766726000,
        "value": 139.957,
        "_internal_originalTime": 1689766726000
    },
    {
        "time": 1689766730000,
        "value": 139.953,
        "_internal_originalTime": 1689766730000
    },
    {
        "time": 1689766733000,
        "value": 139.954,
        "_internal_originalTime": 1689766733000
    },
    {
        "time": 1689766737000,
        "value": 139.955,
        "_internal_originalTime": 1689766737000
    },
    {
        "time": 1689766741000,
        "value": 139.93,
        "_internal_originalTime": 1689766741000
    },
    {
        "time": 1689766743000,
        "value": 139.962,
        "_internal_originalTime": 1689766743000
    },
    {
        "time": 1689766746000,
        "value": 139.96,
        "_internal_originalTime": 1689766746000
    },
    {
        "time": 1689766749000,
        "value": 139.967,
        "_internal_originalTime": 1689766749000
    },
    {
        "time": 1689766753000,
        "value": 139.976,
        "_internal_originalTime": 1689766753000
    },
    {
        "time": 1689766756000,
        "value": 139.974,
        "_internal_originalTime": 1689766756000
    },
    {
        "time": 1689766757000,
        "value": 139.977,
        "_internal_originalTime": 1689766757000
    },
    {
        "time": 1689766759000,
        "value": 139.972,
        "_internal_originalTime": 1689766759000
    },
    {
        "time": 1689766761000,
        "value": 139.973,
        "_internal_originalTime": 1689766761000
    },
    {
        "time": 1689766763000,
        "value": 139.974,
        "_internal_originalTime": 1689766763000
    },
    {
        "time": 1689766765000,
        "value": 139.977,
        "_internal_originalTime": 1689766765000
    },
    {
        "time": 1689766767000,
        "value": 139.975,
        "_internal_originalTime": 1689766767000
    },
    {
        "time": 1689766769000,
        "value": 139.974,
        "_internal_originalTime": 1689766769000
    },
    {
        "time": 1689766772000,
        "value": 139.978,
        "_internal_originalTime": 1689766772000
    },
    {
        "time": 1689766773000,
        "value": 139.974,
        "_internal_originalTime": 1689766773000
    },
    {
        "time": 1689766774000,
        "value": 139.975,
        "_internal_originalTime": 1689766774000
    },
    {
        "time": 1689766775000,
        "value": 139.978,
        "_internal_originalTime": 1689766775000
    },
    {
        "time": 1689766777000,
        "value": 139.975,
        "_internal_originalTime": 1689766777000
    },
    {
        "time": 1689766778000,
        "value": 139.972,
        "_internal_originalTime": 1689766778000
    },
    {
        "time": 1689766780000,
        "value": 139.974,
        "_internal_originalTime": 1689766780000
    },
    {
        "time": 1689766783000,
        "value": 139.974,
        "_internal_originalTime": 1689766783000
    },
    {
        "time": 1689766785000,
        "value": 139.975,
        "_internal_originalTime": 1689766785000
    },
    {
        "time": 1689766786000,
        "value": 139.973,
        "_internal_originalTime": 1689766786000
    },
    {
        "time": 1689766788000,
        "value": 139.977,
        "_internal_originalTime": 1689766788000
    },
    {
        "time": 1689766789000,
        "value": 139.976,
        "_internal_originalTime": 1689766789000
    },
    {
        "time": 1689766791000,
        "value": 139.973,
        "_internal_originalTime": 1689766791000
    },
    {
        "time": 1689766792000,
        "value": 139.974,
        "_internal_originalTime": 1689766792000
    },
    {
        "time": 1689766793000,
        "value": 139.975,
        "_internal_originalTime": 1689766793000
    },
    {
        "time": 1689766795000,
        "value": 139.976,
        "_internal_originalTime": 1689766795000
    },
    {
        "time": 1689766796000,
        "value": 139.973,
        "_internal_originalTime": 1689766796000
    },
    {
        "time": 1689766798000,
        "value": 139.97,
        "_internal_originalTime": 1689766798000
    },
    {
        "time": 1689766800000,
        "value": 139.972,
        "_internal_originalTime": 1689766800000
    },
    {
        "time": 1689766801000,
        "value": 139.969,
        "_internal_originalTime": 1689766801000
    },
    {
        "time": 1689766803000,
        "value": 139.972,
        "_internal_originalTime": 1689766803000
    },
    {
        "time": 1689766804000,
        "value": 139.972,
        "_internal_originalTime": 1689766804000
    },
    {
        "time": 1689766805000,
        "value": 139.975,
        "_internal_originalTime": 1689766805000
    },
    {
        "time": 1689766806000,
        "value": 139.977,
        "_internal_originalTime": 1689766806000
    },
    {
        "time": 1689766808000,
        "value": 139.977,
        "_internal_originalTime": 1689766808000
    },
    {
        "time": 1689766809000,
        "value": 139.974,
        "_internal_originalTime": 1689766809000
    },
    {
        "time": 1689766811000,
        "value": 139.97,
        "_internal_originalTime": 1689766811000
    },
    {
        "time": 1689766812000,
        "value": 139.972,
        "_internal_originalTime": 1689766812000
    },
    {
        "time": 1689766813000,
        "value": 139.977,
        "_internal_originalTime": 1689766813000
    },
    {
        "time": 1689766814000,
        "value": 139.972,
        "_internal_originalTime": 1689766814000
    },
    {
        "time": 1689766816000,
        "value": 139.977,
        "_internal_originalTime": 1689766816000
    },
    {
        "time": 1689766820000,
        "value": 139.981,
        "_internal_originalTime": 1689766820000
    },
    {
        "time": 1689766821000,
        "value": 139.985,
        "_internal_originalTime": 1689766821000
    },
    {
        "time": 1689766823000,
        "value": 139.981,
        "_internal_originalTime": 1689766823000
    },
    {
        "time": 1689766824000,
        "value": 139.984,
        "_internal_originalTime": 1689766824000
    },
    {
        "time": 1689766825000,
        "value": 139.984,
        "_internal_originalTime": 1689766825000
    },
    {
        "time": 1689766827000,
        "value": 139.982,
        "_internal_originalTime": 1689766827000
    },
    {
        "time": 1689766828000,
        "value": 139.983,
        "_internal_originalTime": 1689766828000
    },
    {
        "time": 1689766831000,
        "value": 139.99,
        "_internal_originalTime": 1689766831000
    },
    {
        "time": 1689766832000,
        "value": 139.992,
        "_internal_originalTime": 1689766832000
    },
    {
        "time": 1689766833000,
        "value": 139.988,
        "_internal_originalTime": 1689766833000
    },
    {
        "time": 1689766835000,
        "value": 139.988,
        "_internal_originalTime": 1689766835000
    },
    {
        "time": 1689766836000,
        "value": 139.988,
        "_internal_originalTime": 1689766836000
    },
    {
        "time": 1689766837000,
        "value": 139.987,
        "_internal_originalTime": 1689766837000
    },
    {
        "time": 1689766839000,
        "value": 139.983,
        "_internal_originalTime": 1689766839000
    },
    {
        "time": 1689766840000,
        "value": 139.983,
        "_internal_originalTime": 1689766840000
    },
    {
        "time": 1689766842000,
        "value": 139.986,
        "_internal_originalTime": 1689766842000
    },
    {
        "time": 1689766843000,
        "value": 139.986,
        "_internal_originalTime": 1689766843000
    },
    {
        "time": 1689766845000,
        "value": 139.982,
        "_internal_originalTime": 1689766845000
    },
    {
        "time": 1689766847000,
        "value": 139.987,
        "_internal_originalTime": 1689766847000
    },
    {
        "time": 1689766849000,
        "value": 139.987,
        "_internal_originalTime": 1689766849000
    },
    {
        "time": 1689766851000,
        "value": 139.989,
        "_internal_originalTime": 1689766851000
    },
    {
        "time": 1689766852000,
        "value": 139.987,
        "_internal_originalTime": 1689766852000
    },
    {
        "time": 1689766853000,
        "value": 139.987,
        "_internal_originalTime": 1689766853000
    },
    {
        "time": 1689766854000,
        "value": 139.979,
        "_internal_originalTime": 1689766854000
    },
    {
        "time": 1689766856000,
        "value": 139.983,
        "_internal_originalTime": 1689766856000
    },
    {
        "time": 1689766857000,
        "value": 139.983,
        "_internal_originalTime": 1689766857000
    },
    {
        "time": 1689766859000,
        "value": 139.979,
        "_internal_originalTime": 1689766859000
    },
    {
        "time": 1689766861000,
        "value": 139.982,
        "_internal_originalTime": 1689766861000
    },
    {
        "time": 1689766862000,
        "value": 139.982,
        "_internal_originalTime": 1689766862000
    },
    {
        "time": 1689766863000,
        "value": 139.981,
        "_internal_originalTime": 1689766863000
    },
    {
        "time": 1689766865000,
        "value": 139.98,
        "_internal_originalTime": 1689766865000
    },
    {
        "time": 1689766866000,
        "value": 139.983,
        "_internal_originalTime": 1689766866000
    },
    {
        "time": 1689766867000,
        "value": 139.981,
        "_internal_originalTime": 1689766867000
    },
    {
        "time": 1689766868000,
        "value": 139.982,
        "_internal_originalTime": 1689766868000
    },
    {
        "time": 1689766869000,
        "value": 139.982,
        "_internal_originalTime": 1689766869000
    },
    {
        "time": 1689766871000,
        "value": 139.984,
        "_internal_originalTime": 1689766871000
    },
    {
        "time": 1689766872000,
        "value": 139.984,
        "_internal_originalTime": 1689766872000
    },
    {
        "time": 1689766873000,
        "value": 139.983,
        "_internal_originalTime": 1689766873000
    },
    {
        "time": 1689766874000,
        "value": 139.984,
        "_internal_originalTime": 1689766874000
    },
    {
        "time": 1689766876000,
        "value": 139.982,
        "_internal_originalTime": 1689766876000
    },
    {
        "time": 1689766878000,
        "value": 139.984,
        "_internal_originalTime": 1689766878000
    },
    {
        "time": 1689766879000,
        "value": 139.984,
        "_internal_originalTime": 1689766879000
    },
    {
        "time": 1689766880000,
        "value": 139.984,
        "_internal_originalTime": 1689766880000
    },
    {
        "time": 1689766882000,
        "value": 139.984,
        "_internal_originalTime": 1689766882000
    },
    {
        "time": 1689766883000,
        "value": 139.985,
        "_internal_originalTime": 1689766883000
    },
    {
        "time": 1689766885000,
        "value": 139.986,
        "_internal_originalTime": 1689766885000
    },
    {
        "time": 1689766886000,
        "value": 139.986,
        "_internal_originalTime": 1689766886000
    },
    {
        "time": 1689766888000,
        "value": 139.986,
        "_internal_originalTime": 1689766888000
    },
    {
        "time": 1689766889000,
        "value": 139.986,
        "_internal_originalTime": 1689766889000
    },
    {
        "time": 1689766891000,
        "value": 139.986,
        "_internal_originalTime": 1689766891000
    },
    {
        "time": 1689766892000,
        "value": 139.986,
        "_internal_originalTime": 1689766892000
    },
    {
        "time": 1689766893000,
        "value": 139.986,
        "_internal_originalTime": 1689766893000
    },
    {
        "time": 1689766894000,
        "value": 139.986,
        "_internal_originalTime": 1689766894000
    },
    {
        "time": 1689766896000,
        "value": 139.984,
        "_internal_originalTime": 1689766896000
    },
    {
        "time": 1689766898000,
        "value": 139.984,
        "_internal_originalTime": 1689766898000
    },
    {
        "time": 1689766899000,
        "value": 139.981,
        "_internal_originalTime": 1689766899000
    },
    {
        "time": 1689766900000,
        "value": 139.981,
        "_internal_originalTime": 1689766900000
    },
    {
        "time": 1689766902000,
        "value": 139.982,
        "_internal_originalTime": 1689766902000
    },
    {
        "time": 1689766904000,
        "value": 139.982,
        "_internal_originalTime": 1689766904000
    },
    {
        "time": 1689766905000,
        "value": 139.981,
        "_internal_originalTime": 1689766905000
    },
    {
        "time": 1689766906000,
        "value": 139.982,
        "_internal_originalTime": 1689766906000
    },
    {
        "time": 1689766907000,
        "value": 139.981,
        "_internal_originalTime": 1689766907000
    },
    {
        "time": 1689766909000,
        "value": 139.982,
        "_internal_originalTime": 1689766909000
    },
    {
        "time": 1689766911000,
        "value": 139.978,
        "_internal_originalTime": 1689766911000
    },
    {
        "time": 1689766914000,
        "value": 139.98,
        "_internal_originalTime": 1689766914000
    },
    {
        "time": 1689766915000,
        "value": 139.982,
        "_internal_originalTime": 1689766915000
    },
    {
        "time": 1689766916000,
        "value": 139.982,
        "_internal_originalTime": 1689766916000
    },
    {
        "time": 1689766920000,
        "value": 139.981,
        "_internal_originalTime": 1689766920000
    },
    {
        "time": 1689766921000,
        "value": 139.982,
        "_internal_originalTime": 1689766921000
    },
    {
        "time": 1689766922000,
        "value": 139.982,
        "_internal_originalTime": 1689766922000
    },
    {
        "time": 1689766923000,
        "value": 139.982,
        "_internal_originalTime": 1689766923000
    },
    {
        "time": 1689766925000,
        "value": 139.983,
        "_internal_originalTime": 1689766925000
    },
    {
        "time": 1689766926000,
        "value": 139.98,
        "_internal_originalTime": 1689766926000
    },
    {
        "time": 1689766927000,
        "value": 139.982,
        "_internal_originalTime": 1689766927000
    },
    {
        "time": 1689766928000,
        "value": 139.982,
        "_internal_originalTime": 1689766928000
    },
    {
        "time": 1689766929000,
        "value": 139.981,
        "_internal_originalTime": 1689766929000
    },
    {
        "time": 1689766932000,
        "value": 139.981,
        "_internal_originalTime": 1689766932000
    },
    {
        "time": 1689766934000,
        "value": 139.98,
        "_internal_originalTime": 1689766934000
    },
    {
        "time": 1689766935000,
        "value": 139.979,
        "_internal_originalTime": 1689766935000
    },
    {
        "time": 1689766936000,
        "value": 139.982,
        "_internal_originalTime": 1689766936000
    },
    {
        "time": 1689766937000,
        "value": 139.982,
        "_internal_originalTime": 1689766937000
    },
    {
        "time": 1689766938000,
        "value": 139.982,
        "_internal_originalTime": 1689766938000
    },
    {
        "time": 1689766940000,
        "value": 139.982,
        "_internal_originalTime": 1689766940000
    },
    {
        "time": 1689766941000,
        "value": 139.978,
        "_internal_originalTime": 1689766941000
    },
    {
        "time": 1689766943000,
        "value": 139.976,
        "_internal_originalTime": 1689766943000
    },
    {
        "time": 1689766944000,
        "value": 139.977,
        "_internal_originalTime": 1689766944000
    },
    {
        "time": 1689766945000,
        "value": 139.979,
        "_internal_originalTime": 1689766945000
    },
    {
        "time": 1689766946000,
        "value": 139.982,
        "_internal_originalTime": 1689766946000
    },
    {
        "time": 1689766948000,
        "value": 139.982,
        "_internal_originalTime": 1689766948000
    },
    {
        "time": 1689766950000,
        "value": 139.98,
        "_internal_originalTime": 1689766950000
    },
    {
        "time": 1689766952000,
        "value": 139.98,
        "_internal_originalTime": 1689766952000
    },
    {
        "time": 1689766953000,
        "value": 139.979,
        "_internal_originalTime": 1689766953000
    },
    {
        "time": 1689766954000,
        "value": 139.98,
        "_internal_originalTime": 1689766954000
    },
    {
        "time": 1689766958000,
        "value": 139.986,
        "_internal_originalTime": 1689766958000
    },
    {
        "time": 1689766960000,
        "value": 139.983,
        "_internal_originalTime": 1689766960000
    },
    {
        "time": 1689766962000,
        "value": 139.985,
        "_internal_originalTime": 1689766962000
    },
    {
        "time": 1689766963000,
        "value": 139.982,
        "_internal_originalTime": 1689766963000
    },
    {
        "time": 1689766965000,
        "value": 139.982,
        "_internal_originalTime": 1689766965000
    },
    {
        "time": 1689766966000,
        "value": 139.982,
        "_internal_originalTime": 1689766966000
    },
    {
        "time": 1689766968000,
        "value": 139.982,
        "_internal_originalTime": 1689766968000
    },
    {
        "time": 1689766969000,
        "value": 139.983,
        "_internal_originalTime": 1689766969000
    },
    {
        "time": 1689766971000,
        "value": 139.979,
        "_internal_originalTime": 1689766971000
    },
    {
        "time": 1689766972000,
        "value": 139.99,
        "_internal_originalTime": 1689766972000
    },
    {
        "time": 1689766973000,
        "value": 139.987,
        "_internal_originalTime": 1689766973000
    },
    {
        "time": 1689766975000,
        "value": 139.987,
        "_internal_originalTime": 1689766975000
    },
    {
        "time": 1689766976000,
        "value": 139.99,
        "_internal_originalTime": 1689766976000
    },
    {
        "time": 1689766977000,
        "value": 139.991,
        "_internal_originalTime": 1689766977000
    },
    {
        "time": 1689766979000,
        "value": 139.986,
        "_internal_originalTime": 1689766979000
    },
    {
        "time": 1689766980000,
        "value": 139.989,
        "_internal_originalTime": 1689766980000
    },
    {
        "time": 1689766981000,
        "value": 139.97,
        "_internal_originalTime": 1689766981000
    },
    {
        "time": 1689766982000,
        "value": 139.983,
        "_internal_originalTime": 1689766982000
    },
    {
        "time": 1689766983000,
        "value": 139.985,
        "_internal_originalTime": 1689766983000
    },
    {
        "time": 1689766986000,
        "value": 139.984,
        "_internal_originalTime": 1689766986000
    },
    {
        "time": 1689766988000,
        "value": 139.983,
        "_internal_originalTime": 1689766988000
    },
    {
        "time": 1689766989000,
        "value": 139.983,
        "_internal_originalTime": 1689766989000
    },
    {
        "time": 1689766990000,
        "value": 139.978,
        "_internal_originalTime": 1689766990000
    },
    {
        "time": 1689766992000,
        "value": 139.983,
        "_internal_originalTime": 1689766992000
    },
    {
        "time": 1689766993000,
        "value": 139.981,
        "_internal_originalTime": 1689766993000
    },
    {
        "time": 1689766994000,
        "value": 139.975,
        "_internal_originalTime": 1689766994000
    },
    {
        "time": 1689766998000,
        "value": 139.977,
        "_internal_originalTime": 1689766998000
    },
    {
        "time": 1689766999000,
        "value": 139.977,
        "_internal_originalTime": 1689766999000
    },
    {
        "time": 1689767001000,
        "value": 139.974,
        "_internal_originalTime": 1689767001000
    },
    {
        "time": 1689767004000,
        "value": 139.972,
        "_internal_originalTime": 1689767004000
    },
    {
        "time": 1689767006000,
        "value": 139.977,
        "_internal_originalTime": 1689767006000
    },
    {
        "time": 1689767007000,
        "value": 139.977,
        "_internal_originalTime": 1689767007000
    },
    {
        "time": 1689767008000,
        "value": 139.973,
        "_internal_originalTime": 1689767008000
    },
    {
        "time": 1689767010000,
        "value": 139.973,
        "_internal_originalTime": 1689767010000
    },
    {
        "time": 1689767012000,
        "value": 139.972,
        "_internal_originalTime": 1689767012000
    },
    {
        "time": 1689767013000,
        "value": 139.969,
        "_internal_originalTime": 1689767013000
    },
    {
        "time": 1689767016000,
        "value": 139.969,
        "_internal_originalTime": 1689767016000
    },
    {
        "time": 1689767017000,
        "value": 139.973,
        "_internal_originalTime": 1689767017000
    },
    {
        "time": 1689767019000,
        "value": 139.968,
        "_internal_originalTime": 1689767019000
    },
    {
        "time": 1689767021000,
        "value": 139.977,
        "_internal_originalTime": 1689767021000
    },
    {
        "time": 1689767023000,
        "value": 139.976,
        "_internal_originalTime": 1689767023000
    },
    {
        "time": 1689767025000,
        "value": 139.976,
        "_internal_originalTime": 1689767025000
    },
    {
        "time": 1689767026000,
        "value": 139.975,
        "_internal_originalTime": 1689767026000
    },
    {
        "time": 1689767028000,
        "value": 139.973,
        "_internal_originalTime": 1689767028000
    },
    {
        "time": 1689767029000,
        "value": 139.973,
        "_internal_originalTime": 1689767029000
    },
    {
        "time": 1689767030000,
        "value": 139.974,
        "_internal_originalTime": 1689767030000
    },
    {
        "time": 1689767031000,
        "value": 139.97,
        "_internal_originalTime": 1689767031000
    },
    {
        "time": 1689767032000,
        "value": 139.97,
        "_internal_originalTime": 1689767032000
    },
    {
        "time": 1689767034000,
        "value": 139.972,
        "_internal_originalTime": 1689767034000
    },
    {
        "time": 1689767035000,
        "value": 139.976,
        "_internal_originalTime": 1689767035000
    },
    {
        "time": 1689767036000,
        "value": 139.973,
        "_internal_originalTime": 1689767036000
    },
    {
        "time": 1689767038000,
        "value": 139.974,
        "_internal_originalTime": 1689767038000
    },
    {
        "time": 1689767040000,
        "value": 139.973,
        "_internal_originalTime": 1689767040000
    },
    {
        "time": 1689767042000,
        "value": 139.973,
        "_internal_originalTime": 1689767042000
    },
    {
        "time": 1689767044000,
        "value": 139.969,
        "_internal_originalTime": 1689767044000
    },
    {
        "time": 1689767045000,
        "value": 139.972,
        "_internal_originalTime": 1689767045000
    },
    {
        "time": 1689767046000,
        "value": 139.974,
        "_internal_originalTime": 1689767046000
    },
    {
        "time": 1689767048000,
        "value": 139.975,
        "_internal_originalTime": 1689767048000
    },
    {
        "time": 1689767050000,
        "value": 139.973,
        "_internal_originalTime": 1689767050000
    },
    {
        "time": 1689767052000,
        "value": 139.977,
        "_internal_originalTime": 1689767052000
    },
    {
        "time": 1689767053000,
        "value": 139.983,
        "_internal_originalTime": 1689767053000
    },
    {
        "time": 1689767054000,
        "value": 139.982,
        "_internal_originalTime": 1689767054000
    },
    {
        "time": 1689767056000,
        "value": 139.975,
        "_internal_originalTime": 1689767056000
    },
    {
        "time": 1689767057000,
        "value": 139.973,
        "_internal_originalTime": 1689767057000
    },
    {
        "time": 1689767058000,
        "value": 139.969,
        "_internal_originalTime": 1689767058000
    },
    {
        "time": 1689767060000,
        "value": 139.969,
        "_internal_originalTime": 1689767060000
    },
    {
        "time": 1689767061000,
        "value": 139.965,
        "_internal_originalTime": 1689767061000
    },
    {
        "time": 1689767062000,
        "value": 139.965,
        "_internal_originalTime": 1689767062000
    },
    {
        "time": 1689767064000,
        "value": 139.968,
        "_internal_originalTime": 1689767064000
    },
    {
        "time": 1689767065000,
        "value": 139.971,
        "_internal_originalTime": 1689767065000
    },
    {
        "time": 1689767066000,
        "value": 139.961,
        "_internal_originalTime": 1689767066000
    },
    {
        "time": 1689767068000,
        "value": 139.959,
        "_internal_originalTime": 1689767068000
    },
    {
        "time": 1689767069000,
        "value": 139.952,
        "_internal_originalTime": 1689767069000
    },
    {
        "time": 1689767070000,
        "value": 139.953,
        "_internal_originalTime": 1689767070000
    },
    {
        "time": 1689767072000,
        "value": 139.949,
        "_internal_originalTime": 1689767072000
    },
    {
        "time": 1689767073000,
        "value": 139.949,
        "_internal_originalTime": 1689767073000
    },
    {
        "time": 1689767074000,
        "value": 139.953,
        "_internal_originalTime": 1689767074000
    },
    {
        "time": 1689767075000,
        "value": 139.953,
        "_internal_originalTime": 1689767075000
    },
    {
        "time": 1689767076000,
        "value": 139.953,
        "_internal_originalTime": 1689767076000
    },
    {
        "time": 1689767078000,
        "value": 139.95,
        "_internal_originalTime": 1689767078000
    },
    {
        "time": 1689767080000,
        "value": 139.947,
        "_internal_originalTime": 1689767080000
    },
    {
        "time": 1689767081000,
        "value": 139.941,
        "_internal_originalTime": 1689767081000
    },
    {
        "time": 1689767084000,
        "value": 139.947,
        "_internal_originalTime": 1689767084000
    },
    {
        "time": 1689767086000,
        "value": 139.943,
        "_internal_originalTime": 1689767086000
    },
    {
        "time": 1689767087000,
        "value": 139.945,
        "_internal_originalTime": 1689767087000
    },
    {
        "time": 1689767088000,
        "value": 139.945,
        "_internal_originalTime": 1689767088000
    },
    {
        "time": 1689767090000,
        "value": 139.942,
        "_internal_originalTime": 1689767090000
    },
    {
        "time": 1689767091000,
        "value": 139.945,
        "_internal_originalTime": 1689767091000
    },
    {
        "time": 1689767094000,
        "value": 139.946,
        "_internal_originalTime": 1689767094000
    },
    {
        "time": 1689767095000,
        "value": 139.943,
        "_internal_originalTime": 1689767095000
    },
    {
        "time": 1689767097000,
        "value": 139.946,
        "_internal_originalTime": 1689767097000
    },
    {
        "time": 1689767098000,
        "value": 139.945,
        "_internal_originalTime": 1689767098000
    },
    {
        "time": 1689767100000,
        "value": 139.943,
        "_internal_originalTime": 1689767100000
    },
    {
        "time": 1689767101000,
        "value": 139.944,
        "_internal_originalTime": 1689767101000
    },
    {
        "time": 1689767102000,
        "value": 139.944,
        "_internal_originalTime": 1689767102000
    },
    {
        "time": 1689767103000,
        "value": 139.94,
        "_internal_originalTime": 1689767103000
    },
    {
        "time": 1689767104000,
        "value": 139.941,
        "_internal_originalTime": 1689767104000
    },
    {
        "time": 1689767105000,
        "value": 139.944,
        "_internal_originalTime": 1689767105000
    },
    {
        "time": 1689767106000,
        "value": 139.944,
        "_internal_originalTime": 1689767106000
    },
    {
        "time": 1689767107000,
        "value": 139.935,
        "_internal_originalTime": 1689767107000
    },
    {
        "time": 1689767108000,
        "value": 139.933,
        "_internal_originalTime": 1689767108000
    },
    {
        "time": 1689767110000,
        "value": 139.933,
        "_internal_originalTime": 1689767110000
    },
    {
        "time": 1689767111000,
        "value": 139.933,
        "_internal_originalTime": 1689767111000
    },
    {
        "time": 1689767112000,
        "value": 139.933,
        "_internal_originalTime": 1689767112000
    },
    {
        "time": 1689767114000,
        "value": 139.933,
        "_internal_originalTime": 1689767114000
    },
    {
        "time": 1689767115000,
        "value": 139.934,
        "_internal_originalTime": 1689767115000
    },
    {
        "time": 1689767117000,
        "value": 139.935,
        "_internal_originalTime": 1689767117000
    },
    {
        "time": 1689767118000,
        "value": 139.935,
        "_internal_originalTime": 1689767118000
    },
    {
        "time": 1689767119000,
        "value": 139.938,
        "_internal_originalTime": 1689767119000
    },
    {
        "time": 1689767121000,
        "value": 139.939,
        "_internal_originalTime": 1689767121000
    },
    {
        "time": 1689767122000,
        "value": 139.94,
        "_internal_originalTime": 1689767122000
    },
    {
        "time": 1689767123000,
        "value": 139.942,
        "_internal_originalTime": 1689767123000
    },
    {
        "time": 1689767125000,
        "value": 139.94,
        "_internal_originalTime": 1689767125000
    },
    {
        "time": 1689767126000,
        "value": 139.938,
        "_internal_originalTime": 1689767126000
    },
    {
        "time": 1689767128000,
        "value": 139.942,
        "_internal_originalTime": 1689767128000
    },
    {
        "time": 1689767130000,
        "value": 139.942,
        "_internal_originalTime": 1689767130000
    },
    {
        "time": 1689767131000,
        "value": 139.943,
        "_internal_originalTime": 1689767131000
    },
    {
        "time": 1689767132000,
        "value": 139.942,
        "_internal_originalTime": 1689767132000
    },
    {
        "time": 1689767134000,
        "value": 139.936,
        "_internal_originalTime": 1689767134000
    },
    {
        "time": 1689767135000,
        "value": 139.936,
        "_internal_originalTime": 1689767135000
    },
    {
        "time": 1689767138000,
        "value": 139.931,
        "_internal_originalTime": 1689767138000
    },
    {
        "time": 1689767139000,
        "value": 139.936,
        "_internal_originalTime": 1689767139000
    },
    {
        "time": 1689767142000,
        "value": 139.932,
        "_internal_originalTime": 1689767142000
    },
    {
        "time": 1689767144000,
        "value": 139.934,
        "_internal_originalTime": 1689767144000
    },
    {
        "time": 1689767145000,
        "value": 139.937,
        "_internal_originalTime": 1689767145000
    },
    {
        "time": 1689767147000,
        "value": 139.935,
        "_internal_originalTime": 1689767147000
    },
    {
        "time": 1689767148000,
        "value": 139.936,
        "_internal_originalTime": 1689767148000
    },
    {
        "time": 1689767149000,
        "value": 139.932,
        "_internal_originalTime": 1689767149000
    },
    {
        "time": 1689767151000,
        "value": 139.933,
        "_internal_originalTime": 1689767151000
    },
    {
        "time": 1689767152000,
        "value": 139.934,
        "_internal_originalTime": 1689767152000
    },
    {
        "time": 1689767153000,
        "value": 139.933,
        "_internal_originalTime": 1689767153000
    },
    {
        "time": 1689767155000,
        "value": 139.93,
        "_internal_originalTime": 1689767155000
    },
    {
        "time": 1689767157000,
        "value": 139.933,
        "_internal_originalTime": 1689767157000
    },
    {
        "time": 1689767158000,
        "value": 139.93,
        "_internal_originalTime": 1689767158000
    },
    {
        "time": 1689767160000,
        "value": 139.931,
        "_internal_originalTime": 1689767160000
    },
    {
        "time": 1689767161000,
        "value": 139.933,
        "_internal_originalTime": 1689767161000
    },
    {
        "time": 1689767162000,
        "value": 139.93,
        "_internal_originalTime": 1689767162000
    },
    {
        "time": 1689767163000,
        "value": 139.93,
        "_internal_originalTime": 1689767163000
    },
    {
        "time": 1689767165000,
        "value": 139.932,
        "_internal_originalTime": 1689767165000
    },
    {
        "time": 1689767166000,
        "value": 139.93,
        "_internal_originalTime": 1689767166000
    },
    {
        "time": 1689767167000,
        "value": 139.923,
        "_internal_originalTime": 1689767167000
    },
    {
        "time": 1689767169000,
        "value": 139.919,
        "_internal_originalTime": 1689767169000
    },
    {
        "time": 1689767170000,
        "value": 139.923,
        "_internal_originalTime": 1689767170000
    },
    {
        "time": 1689767171000,
        "value": 139.927,
        "_internal_originalTime": 1689767171000
    },
    {
        "time": 1689767173000,
        "value": 139.924,
        "_internal_originalTime": 1689767173000
    },
    {
        "time": 1689767174000,
        "value": 139.923,
        "_internal_originalTime": 1689767174000
    },
    {
        "time": 1689767175000,
        "value": 139.926,
        "_internal_originalTime": 1689767175000
    },
    {
        "time": 1689767176000,
        "value": 139.923,
        "_internal_originalTime": 1689767176000
    },
    {
        "time": 1689767178000,
        "value": 139.925,
        "_internal_originalTime": 1689767178000
    },
    {
        "time": 1689767179000,
        "value": 139.922,
        "_internal_originalTime": 1689767179000
    },
    {
        "time": 1689767181000,
        "value": 139.924,
        "_internal_originalTime": 1689767181000
    },
    {
        "time": 1689767182000,
        "value": 139.925,
        "_internal_originalTime": 1689767182000
    },
    {
        "time": 1689767183000,
        "value": 139.926,
        "_internal_originalTime": 1689767183000
    },
    {
        "time": 1689767185000,
        "value": 139.925,
        "_internal_originalTime": 1689767185000
    },
    {
        "time": 1689767187000,
        "value": 139.92,
        "_internal_originalTime": 1689767187000
    },
    {
        "time": 1689767188000,
        "value": 139.919,
        "_internal_originalTime": 1689767188000
    },
    {
        "time": 1689767190000,
        "value": 139.915,
        "_internal_originalTime": 1689767190000
    },
    {
        "time": 1689767191000,
        "value": 139.91,
        "_internal_originalTime": 1689767191000
    },
    {
        "time": 1689767192000,
        "value": 139.904,
        "_internal_originalTime": 1689767192000
    },
    {
        "time": 1689767194000,
        "value": 139.906,
        "_internal_originalTime": 1689767194000
    },
    {
        "time": 1689767195000,
        "value": 139.906,
        "_internal_originalTime": 1689767195000
    },
    {
        "time": 1689767198000,
        "value": 139.899,
        "_internal_originalTime": 1689767198000
    },
    {
        "time": 1689767199000,
        "value": 139.903,
        "_internal_originalTime": 1689767199000
    },
    {
        "time": 1689767201000,
        "value": 139.9,
        "_internal_originalTime": 1689767201000
    },
    {
        "time": 1689767202000,
        "value": 139.906,
        "_internal_originalTime": 1689767202000
    },
    {
        "time": 1689767204000,
        "value": 139.902,
        "_internal_originalTime": 1689767204000
    },
    {
        "time": 1689767205000,
        "value": 139.903,
        "_internal_originalTime": 1689767205000
    },
    {
        "time": 1689767206000,
        "value": 139.9,
        "_internal_originalTime": 1689767206000
    },
    {
        "time": 1689767208000,
        "value": 139.9,
        "_internal_originalTime": 1689767208000
    },
    {
        "time": 1689767209000,
        "value": 139.888,
        "_internal_originalTime": 1689767209000
    },
    {
        "time": 1689767210000,
        "value": 139.898,
        "_internal_originalTime": 1689767210000
    },
    {
        "time": 1689767212000,
        "value": 139.897,
        "_internal_originalTime": 1689767212000
    },
    {
        "time": 1689767213000,
        "value": 139.899,
        "_internal_originalTime": 1689767213000
    },
    {
        "time": 1689767215000,
        "value": 139.906,
        "_internal_originalTime": 1689767215000
    },
    {
        "time": 1689767216000,
        "value": 139.905,
        "_internal_originalTime": 1689767216000
    },
    {
        "time": 1689767217000,
        "value": 139.906,
        "_internal_originalTime": 1689767217000
    },
    {
        "time": 1689767219000,
        "value": 139.904,
        "_internal_originalTime": 1689767219000
    },
    {
        "time": 1689767220000,
        "value": 139.9,
        "_internal_originalTime": 1689767220000
    },
    {
        "time": 1689767222000,
        "value": 139.885,
        "_internal_originalTime": 1689767222000
    },
    {
        "time": 1689767223000,
        "value": 139.888,
        "_internal_originalTime": 1689767223000
    },
    {
        "time": 1689767224000,
        "value": 139.888,
        "_internal_originalTime": 1689767224000
    },
    {
        "time": 1689767226000,
        "value": 139.89,
        "_internal_originalTime": 1689767226000
    },
    {
        "time": 1689767227000,
        "value": 139.896,
        "_internal_originalTime": 1689767227000
    },
    {
        "time": 1689767228000,
        "value": 139.899,
        "_internal_originalTime": 1689767228000
    },
    {
        "time": 1689767229000,
        "value": 139.9,
        "_internal_originalTime": 1689767229000
    },
    {
        "time": 1689767231000,
        "value": 139.9,
        "_internal_originalTime": 1689767231000
    },
    {
        "time": 1689767232000,
        "value": 139.9,
        "_internal_originalTime": 1689767232000
    },
    {
        "time": 1689767233000,
        "value": 139.903,
        "_internal_originalTime": 1689767233000
    },
    {
        "time": 1689767235000,
        "value": 139.892,
        "_internal_originalTime": 1689767235000
    },
    {
        "time": 1689767237000,
        "value": 139.894,
        "_internal_originalTime": 1689767237000
    },
    {
        "time": 1689767238000,
        "value": 139.898,
        "_internal_originalTime": 1689767238000
    },
    {
        "time": 1689767239000,
        "value": 139.903,
        "_internal_originalTime": 1689767239000
    },
    {
        "time": 1689767241000,
        "value": 139.901,
        "_internal_originalTime": 1689767241000
    },
    {
        "time": 1689767242000,
        "value": 139.9,
        "_internal_originalTime": 1689767242000
    },
    {
        "time": 1689767243000,
        "value": 139.895,
        "_internal_originalTime": 1689767243000
    },
    {
        "time": 1689767245000,
        "value": 139.895,
        "_internal_originalTime": 1689767245000
    },
    {
        "time": 1689767246000,
        "value": 139.899,
        "_internal_originalTime": 1689767246000
    },
    {
        "time": 1689767247000,
        "value": 139.892,
        "_internal_originalTime": 1689767247000
    },
    {
        "time": 1689767248000,
        "value": 139.896,
        "_internal_originalTime": 1689767248000
    },
    {
        "time": 1689767250000,
        "value": 139.902,
        "_internal_originalTime": 1689767250000
    },
    {
        "time": 1689767252000,
        "value": 139.902,
        "_internal_originalTime": 1689767252000
    },
    {
        "time": 1689767253000,
        "value": 139.903,
        "_internal_originalTime": 1689767253000
    },
    {
        "time": 1689767254000,
        "value": 139.899,
        "_internal_originalTime": 1689767254000
    },
    {
        "time": 1689767256000,
        "value": 139.903,
        "_internal_originalTime": 1689767256000
    },
    {
        "time": 1689767257000,
        "value": 139.902,
        "_internal_originalTime": 1689767257000
    },
    {
        "time": 1689767258000,
        "value": 139.9,
        "_internal_originalTime": 1689767258000
    },
    {
        "time": 1689767261000,
        "value": 139.906,
        "_internal_originalTime": 1689767261000
    },
    {
        "time": 1689767263000,
        "value": 139.912,
        "_internal_originalTime": 1689767263000
    },
    {
        "time": 1689767264000,
        "value": 139.916,
        "_internal_originalTime": 1689767264000
    },
    {
        "time": 1689767265000,
        "value": 139.914,
        "_internal_originalTime": 1689767265000
    },
    {
        "time": 1689767266000,
        "value": 139.916,
        "_internal_originalTime": 1689767266000
    },
    {
        "time": 1689767268000,
        "value": 139.914,
        "_internal_originalTime": 1689767268000
    },
    {
        "time": 1689767269000,
        "value": 139.916,
        "_internal_originalTime": 1689767269000
    },
    {
        "time": 1689767270000,
        "value": 139.915,
        "_internal_originalTime": 1689767270000
    },
    {
        "time": 1689767273000,
        "value": 139.916,
        "_internal_originalTime": 1689767273000
    },
    {
        "time": 1689767278000,
        "value": 139.912,
        "_internal_originalTime": 1689767278000
    },
    {
        "time": 1689767279000,
        "value": 139.915,
        "_internal_originalTime": 1689767279000
    },
    {
        "time": 1689767281000,
        "value": 139.9,
        "_internal_originalTime": 1689767281000
    },
    {
        "time": 1689767284000,
        "value": 139.912,
        "_internal_originalTime": 1689767284000
    },
    {
        "time": 1689767285000,
        "value": 139.912,
        "_internal_originalTime": 1689767285000
    },
    {
        "time": 1689767286000,
        "value": 139.914,
        "_internal_originalTime": 1689767286000
    },
    {
        "time": 1689767287000,
        "value": 139.916,
        "_internal_originalTime": 1689767287000
    },
    {
        "time": 1689767289000,
        "value": 139.922,
        "_internal_originalTime": 1689767289000
    },
    {
        "time": 1689767290000,
        "value": 139.919,
        "_internal_originalTime": 1689767290000
    },
    {
        "time": 1689767291000,
        "value": 139.919,
        "_internal_originalTime": 1689767291000
    },
    {
        "time": 1689767293000,
        "value": 139.918,
        "_internal_originalTime": 1689767293000
    },
    {
        "time": 1689767295000,
        "value": 139.918,
        "_internal_originalTime": 1689767295000
    },
    {
        "time": 1689767297000,
        "value": 139.914,
        "_internal_originalTime": 1689767297000
    },
    {
        "time": 1689767298000,
        "value": 139.912,
        "_internal_originalTime": 1689767298000
    },
    {
        "time": 1689767299000,
        "value": 139.913,
        "_internal_originalTime": 1689767299000
    },
    {
        "time": 1689767301000,
        "value": 139.914,
        "_internal_originalTime": 1689767301000
    },
    {
        "time": 1689767302000,
        "value": 139.919,
        "_internal_originalTime": 1689767302000
    },
    {
        "time": 1689767304000,
        "value": 139.916,
        "_internal_originalTime": 1689767304000
    },
    {
        "time": 1689767305000,
        "value": 139.918,
        "_internal_originalTime": 1689767305000
    },
    {
        "time": 1689767307000,
        "value": 139.914,
        "_internal_originalTime": 1689767307000
    },
    {
        "time": 1689767308000,
        "value": 139.914,
        "_internal_originalTime": 1689767308000
    },
    {
        "time": 1689767310000,
        "value": 139.913,
        "_internal_originalTime": 1689767310000
    },
    {
        "time": 1689767311000,
        "value": 139.918,
        "_internal_originalTime": 1689767311000
    },
    {
        "time": 1689767312000,
        "value": 139.916,
        "_internal_originalTime": 1689767312000
    },
    {
        "time": 1689767316000,
        "value": 139.912,
        "_internal_originalTime": 1689767316000
    },
    {
        "time": 1689767317000,
        "value": 139.915,
        "_internal_originalTime": 1689767317000
    },
    {
        "time": 1689767318000,
        "value": 139.911,
        "_internal_originalTime": 1689767318000
    },
    {
        "time": 1689767321000,
        "value": 139.912,
        "_internal_originalTime": 1689767321000
    },
    {
        "time": 1689767322000,
        "value": 139.914,
        "_internal_originalTime": 1689767322000
    },
    {
        "time": 1689767326000,
        "value": 139.914,
        "_internal_originalTime": 1689767326000
    },
    {
        "time": 1689767327000,
        "value": 139.914,
        "_internal_originalTime": 1689767327000
    },
    {
        "time": 1689767329000,
        "value": 139.916,
        "_internal_originalTime": 1689767329000
    },
    {
        "time": 1689767330000,
        "value": 139.923,
        "_internal_originalTime": 1689767330000
    },
    {
        "time": 1689767332000,
        "value": 139.918,
        "_internal_originalTime": 1689767332000
    },
    {
        "time": 1689767333000,
        "value": 139.922,
        "_internal_originalTime": 1689767333000
    },
    {
        "time": 1689767334000,
        "value": 139.922,
        "_internal_originalTime": 1689767334000
    },
    {
        "time": 1689767336000,
        "value": 139.918,
        "_internal_originalTime": 1689767336000
    },
    {
        "time": 1689767337000,
        "value": 139.92,
        "_internal_originalTime": 1689767337000
    },
    {
        "time": 1689767339000,
        "value": 139.918,
        "_internal_originalTime": 1689767339000
    },
    {
        "time": 1689767340000,
        "value": 139.926,
        "_internal_originalTime": 1689767340000
    },
    {
        "time": 1689767341000,
        "value": 139.926,
        "_internal_originalTime": 1689767341000
    },
    {
        "time": 1689767342000,
        "value": 139.924,
        "_internal_originalTime": 1689767342000
    },
    {
        "time": 1689767344000,
        "value": 139.934,
        "_internal_originalTime": 1689767344000
    },
    {
        "time": 1689767345000,
        "value": 139.933,
        "_internal_originalTime": 1689767345000
    },
    {
        "time": 1689767346000,
        "value": 139.937,
        "_internal_originalTime": 1689767346000
    },
    {
        "time": 1689767348000,
        "value": 139.934,
        "_internal_originalTime": 1689767348000
    },
    {
        "time": 1689767349000,
        "value": 139.934,
        "_internal_originalTime": 1689767349000
    },
    {
        "time": 1689767351000,
        "value": 139.942,
        "_internal_originalTime": 1689767351000
    },
    {
        "time": 1689767352000,
        "value": 139.939,
        "_internal_originalTime": 1689767352000
    },
    {
        "time": 1689767353000,
        "value": 139.939,
        "_internal_originalTime": 1689767353000
    },
    {
        "time": 1689767355000,
        "value": 139.942,
        "_internal_originalTime": 1689767355000
    },
    {
        "time": 1689767356000,
        "value": 139.943,
        "_internal_originalTime": 1689767356000
    },
    {
        "time": 1689767357000,
        "value": 139.94,
        "_internal_originalTime": 1689767357000
    },
    {
        "time": 1689767358000,
        "value": 139.943,
        "_internal_originalTime": 1689767358000
    },
    {
        "time": 1689767360000,
        "value": 139.943,
        "_internal_originalTime": 1689767360000
    },
    {
        "time": 1689767361000,
        "value": 139.944,
        "_internal_originalTime": 1689767361000
    },
    {
        "time": 1689767364000,
        "value": 139.943,
        "_internal_originalTime": 1689767364000
    },
    {
        "time": 1689767366000,
        "value": 139.942,
        "_internal_originalTime": 1689767366000
    },
    {
        "time": 1689767368000,
        "value": 139.939,
        "_internal_originalTime": 1689767368000
    },
    {
        "time": 1689767369000,
        "value": 139.941,
        "_internal_originalTime": 1689767369000
    },
    {
        "time": 1689767372000,
        "value": 139.939,
        "_internal_originalTime": 1689767372000
    },
    {
        "time": 1689767373000,
        "value": 139.942,
        "_internal_originalTime": 1689767373000
    },
    {
        "time": 1689767374000,
        "value": 139.943,
        "_internal_originalTime": 1689767374000
    },
    {
        "time": 1689767376000,
        "value": 139.94,
        "_internal_originalTime": 1689767376000
    },
    {
        "time": 1689767377000,
        "value": 139.943,
        "_internal_originalTime": 1689767377000
    },
    {
        "time": 1689767378000,
        "value": 139.942,
        "_internal_originalTime": 1689767378000
    },
    {
        "time": 1689767380000,
        "value": 139.94,
        "_internal_originalTime": 1689767380000
    },
    {
        "time": 1689767381000,
        "value": 139.938,
        "_internal_originalTime": 1689767381000
    },
    {
        "time": 1689767385000,
        "value": 139.942,
        "_internal_originalTime": 1689767385000
    },
    {
        "time": 1689767386000,
        "value": 139.942,
        "_internal_originalTime": 1689767386000
    },
    {
        "time": 1689767387000,
        "value": 139.934,
        "_internal_originalTime": 1689767387000
    },
    {
        "time": 1689767388000,
        "value": 139.928,
        "_internal_originalTime": 1689767388000
    },
    {
        "time": 1689767389000,
        "value": 139.928,
        "_internal_originalTime": 1689767389000
    },
    {
        "time": 1689767391000,
        "value": 139.93,
        "_internal_originalTime": 1689767391000
    },
    {
        "time": 1689767392000,
        "value": 139.924,
        "_internal_originalTime": 1689767392000
    },
    {
        "time": 1689767394000,
        "value": 139.924,
        "_internal_originalTime": 1689767394000
    },
    {
        "time": 1689767395000,
        "value": 139.93,
        "_internal_originalTime": 1689767395000
    },
    {
        "time": 1689767396000,
        "value": 139.932,
        "_internal_originalTime": 1689767396000
    },
    {
        "time": 1689767398000,
        "value": 139.93,
        "_internal_originalTime": 1689767398000
    },
    {
        "time": 1689767399000,
        "value": 139.93,
        "_internal_originalTime": 1689767399000
    },
    {
        "time": 1689767400000,
        "value": 139.934,
        "_internal_originalTime": 1689767400000
    },
    {
        "time": 1689767401000,
        "value": 139.936,
        "_internal_originalTime": 1689767401000
    },
    {
        "time": 1689767402000,
        "value": 139.938,
        "_internal_originalTime": 1689767402000
    },
    {
        "time": 1689767403000,
        "value": 139.935,
        "_internal_originalTime": 1689767403000
    },
    {
        "time": 1689767404000,
        "value": 139.934,
        "_internal_originalTime": 1689767404000
    },
    {
        "time": 1689767406000,
        "value": 139.935,
        "_internal_originalTime": 1689767406000
    },
    {
        "time": 1689767407000,
        "value": 139.932,
        "_internal_originalTime": 1689767407000
    },
    {
        "time": 1689767409000,
        "value": 139.936,
        "_internal_originalTime": 1689767409000
    },
    {
        "time": 1689767410000,
        "value": 139.934,
        "_internal_originalTime": 1689767410000
    },
    {
        "time": 1689767411000,
        "value": 139.934,
        "_internal_originalTime": 1689767411000
    },
    {
        "time": 1689767413000,
        "value": 139.935,
        "_internal_originalTime": 1689767413000
    },
    {
        "time": 1689767416000,
        "value": 139.933,
        "_internal_originalTime": 1689767416000
    },
    {
        "time": 1689767417000,
        "value": 139.932,
        "_internal_originalTime": 1689767417000
    },
    {
        "time": 1689767418000,
        "value": 139.935,
        "_internal_originalTime": 1689767418000
    },
    {
        "time": 1689767420000,
        "value": 139.936,
        "_internal_originalTime": 1689767420000
    },
    {
        "time": 1689767421000,
        "value": 139.937,
        "_internal_originalTime": 1689767421000
    },
    {
        "time": 1689767423000,
        "value": 139.936,
        "_internal_originalTime": 1689767423000
    },
    {
        "time": 1689767424000,
        "value": 139.935,
        "_internal_originalTime": 1689767424000
    },
    {
        "time": 1689767425000,
        "value": 139.937,
        "_internal_originalTime": 1689767425000
    },
    {
        "time": 1689767426000,
        "value": 139.934,
        "_internal_originalTime": 1689767426000
    },
    {
        "time": 1689767428000,
        "value": 139.932,
        "_internal_originalTime": 1689767428000
    },
    {
        "time": 1689767430000,
        "value": 139.932,
        "_internal_originalTime": 1689767430000
    },
    {
        "time": 1689767432000,
        "value": 139.932,
        "_internal_originalTime": 1689767432000
    },
    {
        "time": 1689767433000,
        "value": 139.936,
        "_internal_originalTime": 1689767433000
    },
    {
        "time": 1689767435000,
        "value": 139.937,
        "_internal_originalTime": 1689767435000
    },
    {
        "time": 1689767437000,
        "value": 139.935,
        "_internal_originalTime": 1689767437000
    },
    {
        "time": 1689767438000,
        "value": 139.938,
        "_internal_originalTime": 1689767438000
    },
    {
        "time": 1689767440000,
        "value": 139.938,
        "_internal_originalTime": 1689767440000
    },
    {
        "time": 1689767441000,
        "value": 139.936,
        "_internal_originalTime": 1689767441000
    },
    {
        "time": 1689767443000,
        "value": 139.935,
        "_internal_originalTime": 1689767443000
    },
    {
        "time": 1689767445000,
        "value": 139.94,
        "_internal_originalTime": 1689767445000
    },
    {
        "time": 1689767446000,
        "value": 139.939,
        "_internal_originalTime": 1689767446000
    },
    {
        "time": 1689767447000,
        "value": 139.938,
        "_internal_originalTime": 1689767447000
    },
    {
        "time": 1689767449000,
        "value": 139.94,
        "_internal_originalTime": 1689767449000
    },
    {
        "time": 1689767450000,
        "value": 139.94,
        "_internal_originalTime": 1689767450000
    },
    {
        "time": 1689767452000,
        "value": 139.942,
        "_internal_originalTime": 1689767452000
    },
    {
        "time": 1689767453000,
        "value": 139.945,
        "_internal_originalTime": 1689767453000
    },
    {
        "time": 1689767454000,
        "value": 139.942,
        "_internal_originalTime": 1689767454000
    },
    {
        "time": 1689767456000,
        "value": 139.946,
        "_internal_originalTime": 1689767456000
    },
    {
        "time": 1689767457000,
        "value": 139.941,
        "_internal_originalTime": 1689767457000
    },
    {
        "time": 1689767460000,
        "value": 139.92,
        "_internal_originalTime": 1689767460000
    },
    {
        "time": 1689767461000,
        "value": 139.942,
        "_internal_originalTime": 1689767461000
    },
    {
        "time": 1689767462000,
        "value": 139.944,
        "_internal_originalTime": 1689767462000
    },
    {
        "time": 1689767464000,
        "value": 139.939,
        "_internal_originalTime": 1689767464000
    },
    {
        "time": 1689767465000,
        "value": 139.939,
        "_internal_originalTime": 1689767465000
    },
    {
        "time": 1689767468000,
        "value": 139.937,
        "_internal_originalTime": 1689767468000
    },
    {
        "time": 1689767469000,
        "value": 139.94,
        "_internal_originalTime": 1689767469000
    },
    {
        "time": 1689767470000,
        "value": 139.94,
        "_internal_originalTime": 1689767470000
    },
    {
        "time": 1689767472000,
        "value": 139.933,
        "_internal_originalTime": 1689767472000
    },
    {
        "time": 1689767474000,
        "value": 139.937,
        "_internal_originalTime": 1689767474000
    },
    {
        "time": 1689767476000,
        "value": 139.934,
        "_internal_originalTime": 1689767476000
    },
    {
        "time": 1689767478000,
        "value": 139.935,
        "_internal_originalTime": 1689767478000
    },
    {
        "time": 1689767480000,
        "value": 139.937,
        "_internal_originalTime": 1689767480000
    },
    {
        "time": 1689767482000,
        "value": 139.932,
        "_internal_originalTime": 1689767482000
    },
    {
        "time": 1689767484000,
        "value": 139.934,
        "_internal_originalTime": 1689767484000
    },
    {
        "time": 1689767486000,
        "value": 139.936,
        "_internal_originalTime": 1689767486000
    },
    {
        "time": 1689767488000,
        "value": 139.933,
        "_internal_originalTime": 1689767488000
    },
    {
        "time": 1689767490000,
        "value": 139.936,
        "_internal_originalTime": 1689767490000
    },
    {
        "time": 1689767491000,
        "value": 139.935,
        "_internal_originalTime": 1689767491000
    },
    {
        "time": 1689767492000,
        "value": 139.936,
        "_internal_originalTime": 1689767492000
    },
    {
        "time": 1689767494000,
        "value": 139.931,
        "_internal_originalTime": 1689767494000
    },
    {
        "time": 1689767495000,
        "value": 139.935,
        "_internal_originalTime": 1689767495000
    },
    {
        "time": 1689767497000,
        "value": 139.937,
        "_internal_originalTime": 1689767497000
    },
    {
        "time": 1689767499000,
        "value": 139.935,
        "_internal_originalTime": 1689767499000
    },
    {
        "time": 1689767500000,
        "value": 139.945,
        "_internal_originalTime": 1689767500000
    },
    {
        "time": 1689767501000,
        "value": 139.939,
        "_internal_originalTime": 1689767501000
    },
    {
        "time": 1689767503000,
        "value": 139.935,
        "_internal_originalTime": 1689767503000
    },
    {
        "time": 1689767505000,
        "value": 139.938,
        "_internal_originalTime": 1689767505000
    },
    {
        "time": 1689767506000,
        "value": 139.938,
        "_internal_originalTime": 1689767506000
    },
    {
        "time": 1689767507000,
        "value": 139.934,
        "_internal_originalTime": 1689767507000
    },
    {
        "time": 1689767509000,
        "value": 139.939,
        "_internal_originalTime": 1689767509000
    },
    {
        "time": 1689767510000,
        "value": 139.936,
        "_internal_originalTime": 1689767510000
    },
    {
        "time": 1689767512000,
        "value": 139.939,
        "_internal_originalTime": 1689767512000
    },
    {
        "time": 1689767513000,
        "value": 139.939,
        "_internal_originalTime": 1689767513000
    },
    {
        "time": 1689767515000,
        "value": 139.933,
        "_internal_originalTime": 1689767515000
    },
    {
        "time": 1689767516000,
        "value": 139.931,
        "_internal_originalTime": 1689767516000
    },
    {
        "time": 1689767517000,
        "value": 139.934,
        "_internal_originalTime": 1689767517000
    },
    {
        "time": 1689767520000,
        "value": 139.935,
        "_internal_originalTime": 1689767520000
    },
    {
        "time": 1689767521000,
        "value": 139.931,
        "_internal_originalTime": 1689767521000
    },
    {
        "time": 1689767522000,
        "value": 139.931,
        "_internal_originalTime": 1689767522000
    },
    {
        "time": 1689767524000,
        "value": 139.93,
        "_internal_originalTime": 1689767524000
    },
    {
        "time": 1689767525000,
        "value": 139.922,
        "_internal_originalTime": 1689767525000
    },
    {
        "time": 1689767526000,
        "value": 139.925,
        "_internal_originalTime": 1689767526000
    },
    {
        "time": 1689767528000,
        "value": 139.927,
        "_internal_originalTime": 1689767528000
    },
    {
        "time": 1689767529000,
        "value": 139.922,
        "_internal_originalTime": 1689767529000
    },
    {
        "time": 1689767532000,
        "value": 139.93,
        "_internal_originalTime": 1689767532000
    },
    {
        "time": 1689767533000,
        "value": 139.933,
        "_internal_originalTime": 1689767533000
    },
    {
        "time": 1689767535000,
        "value": 139.938,
        "_internal_originalTime": 1689767535000
    },
    {
        "time": 1689767536000,
        "value": 139.934,
        "_internal_originalTime": 1689767536000
    },
    {
        "time": 1689767539000,
        "value": 139.937,
        "_internal_originalTime": 1689767539000
    },
    {
        "time": 1689767540000,
        "value": 139.932,
        "_internal_originalTime": 1689767540000
    },
    {
        "time": 1689767542000,
        "value": 139.932,
        "_internal_originalTime": 1689767542000
    },
    {
        "time": 1689767543000,
        "value": 139.935,
        "_internal_originalTime": 1689767543000
    },
    {
        "time": 1689767544000,
        "value": 139.938,
        "_internal_originalTime": 1689767544000
    },
    {
        "time": 1689767545000,
        "value": 139.933,
        "_internal_originalTime": 1689767545000
    },
    {
        "time": 1689767550000,
        "value": 139.935,
        "_internal_originalTime": 1689767550000
    },
    {
        "time": 1689767552000,
        "value": 139.932,
        "_internal_originalTime": 1689767552000
    },
    {
        "time": 1689767554000,
        "value": 139.937,
        "_internal_originalTime": 1689767554000
    },
    {
        "time": 1689767555000,
        "value": 139.936,
        "_internal_originalTime": 1689767555000
    },
    {
        "time": 1689767556000,
        "value": 139.93,
        "_internal_originalTime": 1689767556000
    },
    {
        "time": 1689767558000,
        "value": 139.922,
        "_internal_originalTime": 1689767558000
    },
    {
        "time": 1689767561000,
        "value": 139.926,
        "_internal_originalTime": 1689767561000
    },
    {
        "time": 1689767563000,
        "value": 139.93,
        "_internal_originalTime": 1689767563000
    },
    {
        "time": 1689767565000,
        "value": 139.93,
        "_internal_originalTime": 1689767565000
    },
    {
        "time": 1689767567000,
        "value": 139.932,
        "_internal_originalTime": 1689767567000
    },
    {
        "time": 1689767568000,
        "value": 139.93,
        "_internal_originalTime": 1689767568000
    },
    {
        "time": 1689767569000,
        "value": 139.93,
        "_internal_originalTime": 1689767569000
    },
    {
        "time": 1689767570000,
        "value": 139.93,
        "_internal_originalTime": 1689767570000
    },
    {
        "time": 1689767572000,
        "value": 139.928,
        "_internal_originalTime": 1689767572000
    },
    {
        "time": 1689767575000,
        "value": 139.922,
        "_internal_originalTime": 1689767575000
    },
    {
        "time": 1689767577000,
        "value": 139.926,
        "_internal_originalTime": 1689767577000
    },
    {
        "time": 1689767578000,
        "value": 139.925,
        "_internal_originalTime": 1689767578000
    },
    {
        "time": 1689767579000,
        "value": 139.927,
        "_internal_originalTime": 1689767579000
    },
    {
        "time": 1689767580000,
        "value": 139.914,
        "_internal_originalTime": 1689767580000
    },
    {
        "time": 1689767581000,
        "value": 139.89,
        "_internal_originalTime": 1689767581000
    },
    {
        "time": 1689767582000,
        "value": 139.91,
        "_internal_originalTime": 1689767582000
    },
    {
        "time": 1689767584000,
        "value": 139.906,
        "_internal_originalTime": 1689767584000
    },
    {
        "time": 1689767585000,
        "value": 139.895,
        "_internal_originalTime": 1689767585000
    },
    {
        "time": 1689767586000,
        "value": 139.898,
        "_internal_originalTime": 1689767586000
    },
    {
        "time": 1689767587000,
        "value": 139.892,
        "_internal_originalTime": 1689767587000
    },
    {
        "time": 1689767589000,
        "value": 139.89,
        "_internal_originalTime": 1689767589000
    },
    {
        "time": 1689767590000,
        "value": 139.894,
        "_internal_originalTime": 1689767590000
    },
    {
        "time": 1689767592000,
        "value": 139.902,
        "_internal_originalTime": 1689767592000
    },
    {
        "time": 1689767593000,
        "value": 139.901,
        "_internal_originalTime": 1689767593000
    },
    {
        "time": 1689767594000,
        "value": 139.903,
        "_internal_originalTime": 1689767594000
    },
    {
        "time": 1689767597000,
        "value": 139.903,
        "_internal_originalTime": 1689767597000
    },
    {
        "time": 1689767598000,
        "value": 139.903,
        "_internal_originalTime": 1689767598000
    },
    {
        "time": 1689767600000,
        "value": 139.897,
        "_internal_originalTime": 1689767600000
    },
    {
        "time": 1689767601000,
        "value": 139.899,
        "_internal_originalTime": 1689767601000
    },
    {
        "time": 1689767602000,
        "value": 139.895,
        "_internal_originalTime": 1689767602000
    },
    {
        "time": 1689767604000,
        "value": 139.899,
        "_internal_originalTime": 1689767604000
    },
    {
        "time": 1689767606000,
        "value": 139.899,
        "_internal_originalTime": 1689767606000
    },
    {
        "time": 1689767607000,
        "value": 139.898,
        "_internal_originalTime": 1689767607000
    },
    {
        "time": 1689767608000,
        "value": 139.898,
        "_internal_originalTime": 1689767608000
    },
    {
        "time": 1689767610000,
        "value": 139.896,
        "_internal_originalTime": 1689767610000
    },
    {
        "time": 1689767611000,
        "value": 139.896,
        "_internal_originalTime": 1689767611000
    },
    {
        "time": 1689767613000,
        "value": 139.895,
        "_internal_originalTime": 1689767613000
    },
    {
        "time": 1689767614000,
        "value": 139.896,
        "_internal_originalTime": 1689767614000
    },
    {
        "time": 1689767615000,
        "value": 139.891,
        "_internal_originalTime": 1689767615000
    },
    {
        "time": 1689767617000,
        "value": 139.897,
        "_internal_originalTime": 1689767617000
    },
    {
        "time": 1689767618000,
        "value": 139.899,
        "_internal_originalTime": 1689767618000
    },
    {
        "time": 1689767620000,
        "value": 139.897,
        "_internal_originalTime": 1689767620000
    },
    {
        "time": 1689767621000,
        "value": 139.898,
        "_internal_originalTime": 1689767621000
    },
    {
        "time": 1689767623000,
        "value": 139.892,
        "_internal_originalTime": 1689767623000
    },
    {
        "time": 1689767624000,
        "value": 139.896,
        "_internal_originalTime": 1689767624000
    },
    {
        "time": 1689767625000,
        "value": 139.896,
        "_internal_originalTime": 1689767625000
    },
    {
        "time": 1689767626000,
        "value": 139.898,
        "_internal_originalTime": 1689767626000
    },
    {
        "time": 1689767627000,
        "value": 139.897,
        "_internal_originalTime": 1689767627000
    },
    {
        "time": 1689767628000,
        "value": 139.895,
        "_internal_originalTime": 1689767628000
    },
    {
        "time": 1689767630000,
        "value": 139.892,
        "_internal_originalTime": 1689767630000
    },
    {
        "time": 1689767632000,
        "value": 139.897,
        "_internal_originalTime": 1689767632000
    },
    {
        "time": 1689767634000,
        "value": 139.887,
        "_internal_originalTime": 1689767634000
    },
    {
        "time": 1689767635000,
        "value": 139.888,
        "_internal_originalTime": 1689767635000
    },
    {
        "time": 1689767638000,
        "value": 139.894,
        "_internal_originalTime": 1689767638000
    },
    {
        "time": 1689767639000,
        "value": 139.903,
        "_internal_originalTime": 1689767639000
    },
    {
        "time": 1689767640000,
        "value": 139.904,
        "_internal_originalTime": 1689767640000
    },
    {
        "time": 1689767641000,
        "value": 139.87,
        "_internal_originalTime": 1689767641000
    },
    {
        "time": 1689767642000,
        "value": 139.906,
        "_internal_originalTime": 1689767642000
    },
    {
        "time": 1689767643000,
        "value": 139.902,
        "_internal_originalTime": 1689767643000
    },
    {
        "time": 1689767645000,
        "value": 139.906,
        "_internal_originalTime": 1689767645000
    },
    {
        "time": 1689767646000,
        "value": 139.91,
        "_internal_originalTime": 1689767646000
    },
    {
        "time": 1689767647000,
        "value": 139.906,
        "_internal_originalTime": 1689767647000
    },
    {
        "time": 1689767649000,
        "value": 139.908,
        "_internal_originalTime": 1689767649000
    },
    {
        "time": 1689767652000,
        "value": 139.908,
        "_internal_originalTime": 1689767652000
    },
    {
        "time": 1689767653000,
        "value": 139.909,
        "_internal_originalTime": 1689767653000
    },
    {
        "time": 1689767655000,
        "value": 139.91,
        "_internal_originalTime": 1689767655000
    },
    {
        "time": 1689767657000,
        "value": 139.912,
        "_internal_originalTime": 1689767657000
    },
    {
        "time": 1689767658000,
        "value": 139.915,
        "_internal_originalTime": 1689767658000
    },
    {
        "time": 1689767660000,
        "value": 139.91,
        "_internal_originalTime": 1689767660000
    },
    {
        "time": 1689767661000,
        "value": 139.912,
        "_internal_originalTime": 1689767661000
    },
    {
        "time": 1689767662000,
        "value": 139.918,
        "_internal_originalTime": 1689767662000
    },
    {
        "time": 1689767664000,
        "value": 139.913,
        "_internal_originalTime": 1689767664000
    },
    {
        "time": 1689767665000,
        "value": 139.914,
        "_internal_originalTime": 1689767665000
    },
    {
        "time": 1689767667000,
        "value": 139.917,
        "_internal_originalTime": 1689767667000
    },
    {
        "time": 1689767668000,
        "value": 139.917,
        "_internal_originalTime": 1689767668000
    },
    {
        "time": 1689767670000,
        "value": 139.922,
        "_internal_originalTime": 1689767670000
    },
    {
        "time": 1689767672000,
        "value": 139.918,
        "_internal_originalTime": 1689767672000
    },
    {
        "time": 1689767673000,
        "value": 139.92,
        "_internal_originalTime": 1689767673000
    },
    {
        "time": 1689767674000,
        "value": 139.924,
        "_internal_originalTime": 1689767674000
    },
    {
        "time": 1689767676000,
        "value": 139.914,
        "_internal_originalTime": 1689767676000
    },
    {
        "time": 1689767677000,
        "value": 139.917,
        "_internal_originalTime": 1689767677000
    },
    {
        "time": 1689767679000,
        "value": 139.915,
        "_internal_originalTime": 1689767679000
    },
    {
        "time": 1689767680000,
        "value": 139.916,
        "_internal_originalTime": 1689767680000
    },
    {
        "time": 1689767682000,
        "value": 139.918,
        "_internal_originalTime": 1689767682000
    },
    {
        "time": 1689767683000,
        "value": 139.916,
        "_internal_originalTime": 1689767683000
    },
    {
        "time": 1689767685000,
        "value": 139.912,
        "_internal_originalTime": 1689767685000
    },
    {
        "time": 1689767687000,
        "value": 139.913,
        "_internal_originalTime": 1689767687000
    },
    {
        "time": 1689767688000,
        "value": 139.907,
        "_internal_originalTime": 1689767688000
    },
    {
        "time": 1689767690000,
        "value": 139.912,
        "_internal_originalTime": 1689767690000
    },
    {
        "time": 1689767692000,
        "value": 139.907,
        "_internal_originalTime": 1689767692000
    },
    {
        "time": 1689767693000,
        "value": 139.907,
        "_internal_originalTime": 1689767693000
    },
    {
        "time": 1689767695000,
        "value": 139.905,
        "_internal_originalTime": 1689767695000
    },
    {
        "time": 1689767696000,
        "value": 139.902,
        "_internal_originalTime": 1689767696000
    },
    {
        "time": 1689767698000,
        "value": 139.897,
        "_internal_originalTime": 1689767698000
    },
    {
        "time": 1689767699000,
        "value": 139.902,
        "_internal_originalTime": 1689767699000
    },
    {
        "time": 1689767700000,
        "value": 139.896,
        "_internal_originalTime": 1689767700000
    },
    {
        "time": 1689767702000,
        "value": 139.904,
        "_internal_originalTime": 1689767702000
    },
    {
        "time": 1689767703000,
        "value": 139.906,
        "_internal_originalTime": 1689767703000
    },
    {
        "time": 1689767704000,
        "value": 139.905,
        "_internal_originalTime": 1689767704000
    },
    {
        "time": 1689767705000,
        "value": 139.907,
        "_internal_originalTime": 1689767705000
    },
    {
        "time": 1689767706000,
        "value": 139.905,
        "_internal_originalTime": 1689767706000
    },
    {
        "time": 1689767708000,
        "value": 139.902,
        "_internal_originalTime": 1689767708000
    },
    {
        "time": 1689767710000,
        "value": 139.907,
        "_internal_originalTime": 1689767710000
    },
    {
        "time": 1689767711000,
        "value": 139.896,
        "_internal_originalTime": 1689767711000
    },
    {
        "time": 1689767712000,
        "value": 139.896,
        "_internal_originalTime": 1689767712000
    },
    {
        "time": 1689767713000,
        "value": 139.895,
        "_internal_originalTime": 1689767713000
    },
    {
        "time": 1689767714000,
        "value": 139.892,
        "_internal_originalTime": 1689767714000
    },
    {
        "time": 1689767716000,
        "value": 139.895,
        "_internal_originalTime": 1689767716000
    },
    {
        "time": 1689767718000,
        "value": 139.899,
        "_internal_originalTime": 1689767718000
    },
    {
        "time": 1689767719000,
        "value": 139.896,
        "_internal_originalTime": 1689767719000
    },
    {
        "time": 1689767720000,
        "value": 139.897,
        "_internal_originalTime": 1689767720000
    },
    {
        "time": 1689767723000,
        "value": 139.897,
        "_internal_originalTime": 1689767723000
    },
    {
        "time": 1689767724000,
        "value": 139.897,
        "_internal_originalTime": 1689767724000
    },
    {
        "time": 1689767726000,
        "value": 139.892,
        "_internal_originalTime": 1689767726000
    },
    {
        "time": 1689767727000,
        "value": 139.894,
        "_internal_originalTime": 1689767727000
    },
    {
        "time": 1689767728000,
        "value": 139.884,
        "_internal_originalTime": 1689767728000
    },
    {
        "time": 1689767730000,
        "value": 139.888,
        "_internal_originalTime": 1689767730000
    },
    {
        "time": 1689767731000,
        "value": 139.888,
        "_internal_originalTime": 1689767731000
    },
    {
        "time": 1689767733000,
        "value": 139.888,
        "_internal_originalTime": 1689767733000
    },
    {
        "time": 1689767734000,
        "value": 139.888,
        "_internal_originalTime": 1689767734000
    },
    {
        "time": 1689767736000,
        "value": 139.888,
        "_internal_originalTime": 1689767736000
    },
    {
        "time": 1689767737000,
        "value": 139.869,
        "_internal_originalTime": 1689767737000
    },
    {
        "time": 1689767738000,
        "value": 139.866,
        "_internal_originalTime": 1689767738000
    },
    {
        "time": 1689767740000,
        "value": 139.872,
        "_internal_originalTime": 1689767740000
    },
    {
        "time": 1689767742000,
        "value": 139.874,
        "_internal_originalTime": 1689767742000
    },
    {
        "time": 1689767743000,
        "value": 139.875,
        "_internal_originalTime": 1689767743000
    },
    {
        "time": 1689767744000,
        "value": 139.873,
        "_internal_originalTime": 1689767744000
    },
    {
        "time": 1689767747000,
        "value": 139.878,
        "_internal_originalTime": 1689767747000
    },
    {
        "time": 1689767748000,
        "value": 139.879,
        "_internal_originalTime": 1689767748000
    },
    {
        "time": 1689767749000,
        "value": 139.878,
        "_internal_originalTime": 1689767749000
    },
    {
        "time": 1689767751000,
        "value": 139.878,
        "_internal_originalTime": 1689767751000
    },
    {
        "time": 1689767753000,
        "value": 139.878,
        "_internal_originalTime": 1689767753000
    },
    {
        "time": 1689767757000,
        "value": 139.878,
        "_internal_originalTime": 1689767757000
    },
    {
        "time": 1689767759000,
        "value": 139.884,
        "_internal_originalTime": 1689767759000
    },
    {
        "time": 1689767760000,
        "value": 139.884,
        "_internal_originalTime": 1689767760000
    },
    {
        "time": 1689767761000,
        "value": 139.885,
        "_internal_originalTime": 1689767761000
    },
    {
        "time": 1689767763000,
        "value": 139.883,
        "_internal_originalTime": 1689767763000
    },
    {
        "time": 1689767765000,
        "value": 139.885,
        "_internal_originalTime": 1689767765000
    },
    {
        "time": 1689767766000,
        "value": 139.881,
        "_internal_originalTime": 1689767766000
    },
    {
        "time": 1689767767000,
        "value": 139.885,
        "_internal_originalTime": 1689767767000
    },
    {
        "time": 1689767769000,
        "value": 139.881,
        "_internal_originalTime": 1689767769000
    },
    {
        "time": 1689767771000,
        "value": 139.88,
        "_internal_originalTime": 1689767771000
    },
    {
        "time": 1689767772000,
        "value": 139.875,
        "_internal_originalTime": 1689767772000
    },
    {
        "time": 1689767773000,
        "value": 139.878,
        "_internal_originalTime": 1689767773000
    },
    {
        "time": 1689767774000,
        "value": 139.88,
        "_internal_originalTime": 1689767774000
    },
    {
        "time": 1689767776000,
        "value": 139.876,
        "_internal_originalTime": 1689767776000
    },
    {
        "time": 1689767777000,
        "value": 139.874,
        "_internal_originalTime": 1689767777000
    },
    {
        "time": 1689767779000,
        "value": 139.88,
        "_internal_originalTime": 1689767779000
    },
    {
        "time": 1689767780000,
        "value": 139.879,
        "_internal_originalTime": 1689767780000
    },
    {
        "time": 1689767781000,
        "value": 139.88,
        "_internal_originalTime": 1689767781000
    },
    {
        "time": 1689767783000,
        "value": 139.88,
        "_internal_originalTime": 1689767783000
    },
    {
        "time": 1689767784000,
        "value": 139.88,
        "_internal_originalTime": 1689767784000
    },
    {
        "time": 1689767785000,
        "value": 139.867,
        "_internal_originalTime": 1689767785000
    },
    {
        "time": 1689767787000,
        "value": 139.871,
        "_internal_originalTime": 1689767787000
    },
    {
        "time": 1689767788000,
        "value": 139.869,
        "_internal_originalTime": 1689767788000
    },
    {
        "time": 1689767790000,
        "value": 139.869,
        "_internal_originalTime": 1689767790000
    },
    {
        "time": 1689767791000,
        "value": 139.875,
        "_internal_originalTime": 1689767791000
    },
    {
        "time": 1689767792000,
        "value": 139.867,
        "_internal_originalTime": 1689767792000
    },
    {
        "time": 1689767794000,
        "value": 139.864,
        "_internal_originalTime": 1689767794000
    },
    {
        "time": 1689767795000,
        "value": 139.869,
        "_internal_originalTime": 1689767795000
    },
    {
        "time": 1689767796000,
        "value": 139.868,
        "_internal_originalTime": 1689767796000
    },
    {
        "time": 1689767798000,
        "value": 139.863,
        "_internal_originalTime": 1689767798000
    },
    {
        "time": 1689767799000,
        "value": 139.864,
        "_internal_originalTime": 1689767799000
    },
    {
        "time": 1689767801000,
        "value": 139.861,
        "_internal_originalTime": 1689767801000
    },
    {
        "time": 1689767802000,
        "value": 139.861,
        "_internal_originalTime": 1689767802000
    },
    {
        "time": 1689767804000,
        "value": 139.858,
        "_internal_originalTime": 1689767804000
    },
    {
        "time": 1689767805000,
        "value": 139.866,
        "_internal_originalTime": 1689767805000
    },
    {
        "time": 1689767806000,
        "value": 139.866,
        "_internal_originalTime": 1689767806000
    },
    {
        "time": 1689767807000,
        "value": 139.859,
        "_internal_originalTime": 1689767807000
    },
    {
        "time": 1689767809000,
        "value": 139.865,
        "_internal_originalTime": 1689767809000
    },
    {
        "time": 1689767810000,
        "value": 139.866,
        "_internal_originalTime": 1689767810000
    },
    {
        "time": 1689767812000,
        "value": 139.868,
        "_internal_originalTime": 1689767812000
    },
    {
        "time": 1689767813000,
        "value": 139.867,
        "_internal_originalTime": 1689767813000
    },
    {
        "time": 1689767815000,
        "value": 139.86,
        "_internal_originalTime": 1689767815000
    },
    {
        "time": 1689767816000,
        "value": 139.86,
        "_internal_originalTime": 1689767816000
    },
    {
        "time": 1689767817000,
        "value": 139.862,
        "_internal_originalTime": 1689767817000
    },
    {
        "time": 1689767818000,
        "value": 139.862,
        "_internal_originalTime": 1689767818000
    },
    {
        "time": 1689767819000,
        "value": 139.856,
        "_internal_originalTime": 1689767819000
    },
    {
        "time": 1689767820000,
        "value": 139.858,
        "_internal_originalTime": 1689767820000
    },
    {
        "time": 1689767821000,
        "value": 139.858,
        "_internal_originalTime": 1689767821000
    },
    {
        "time": 1689767822000,
        "value": 139.854,
        "_internal_originalTime": 1689767822000
    },
    {
        "time": 1689767823000,
        "value": 139.857,
        "_internal_originalTime": 1689767823000
    },
    {
        "time": 1689767824000,
        "value": 139.857,
        "_internal_originalTime": 1689767824000
    },
    {
        "time": 1689767826000,
        "value": 139.859,
        "_internal_originalTime": 1689767826000
    },
    {
        "time": 1689767827000,
        "value": 139.862,
        "_internal_originalTime": 1689767827000
    },
    {
        "time": 1689767828000,
        "value": 139.86,
        "_internal_originalTime": 1689767828000
    },
    {
        "time": 1689767830000,
        "value": 139.857,
        "_internal_originalTime": 1689767830000
    },
    {
        "time": 1689767831000,
        "value": 139.857,
        "_internal_originalTime": 1689767831000
    },
    {
        "time": 1689767833000,
        "value": 139.857,
        "_internal_originalTime": 1689767833000
    },
    {
        "time": 1689767836000,
        "value": 139.855,
        "_internal_originalTime": 1689767836000
    },
    {
        "time": 1689767839000,
        "value": 139.853,
        "_internal_originalTime": 1689767839000
    },
    {
        "time": 1689767841000,
        "value": 139.851,
        "_internal_originalTime": 1689767841000
    },
    {
        "time": 1689767842000,
        "value": 139.857,
        "_internal_originalTime": 1689767842000
    },
    {
        "time": 1689767843000,
        "value": 139.854,
        "_internal_originalTime": 1689767843000
    },
    {
        "time": 1689767845000,
        "value": 139.856,
        "_internal_originalTime": 1689767845000
    },
    {
        "time": 1689767847000,
        "value": 139.849,
        "_internal_originalTime": 1689767847000
    },
    {
        "time": 1689767849000,
        "value": 139.843,
        "_internal_originalTime": 1689767849000
    },
    {
        "time": 1689767850000,
        "value": 139.833,
        "_internal_originalTime": 1689767850000
    },
    {
        "time": 1689767851000,
        "value": 139.835,
        "_internal_originalTime": 1689767851000
    },
    {
        "time": 1689767853000,
        "value": 139.834,
        "_internal_originalTime": 1689767853000
    },
    {
        "time": 1689767854000,
        "value": 139.821,
        "_internal_originalTime": 1689767854000
    },
    {
        "time": 1689767855000,
        "value": 139.821,
        "_internal_originalTime": 1689767855000
    },
    {
        "time": 1689767856000,
        "value": 139.827,
        "_internal_originalTime": 1689767856000
    },
    {
        "time": 1689767858000,
        "value": 139.831,
        "_internal_originalTime": 1689767858000
    },
    {
        "time": 1689767860000,
        "value": 139.827,
        "_internal_originalTime": 1689767860000
    },
    {
        "time": 1689767861000,
        "value": 139.823,
        "_internal_originalTime": 1689767861000
    },
    {
        "time": 1689767862000,
        "value": 139.826,
        "_internal_originalTime": 1689767862000
    },
    {
        "time": 1689767864000,
        "value": 139.822,
        "_internal_originalTime": 1689767864000
    },
    {
        "time": 1689767865000,
        "value": 139.819,
        "_internal_originalTime": 1689767865000
    },
    {
        "time": 1689767867000,
        "value": 139.831,
        "_internal_originalTime": 1689767867000
    },
    {
        "time": 1689767868000,
        "value": 139.818,
        "_internal_originalTime": 1689767868000
    },
    {
        "time": 1689767869000,
        "value": 139.815,
        "_internal_originalTime": 1689767869000
    },
    {
        "time": 1689767871000,
        "value": 139.818,
        "_internal_originalTime": 1689767871000
    },
    {
        "time": 1689767872000,
        "value": 139.814,
        "_internal_originalTime": 1689767872000
    },
    {
        "time": 1689767874000,
        "value": 139.813,
        "_internal_originalTime": 1689767874000
    },
    {
        "time": 1689767875000,
        "value": 139.807,
        "_internal_originalTime": 1689767875000
    },
    {
        "time": 1689767876000,
        "value": 139.807,
        "_internal_originalTime": 1689767876000
    },
    {
        "time": 1689767878000,
        "value": 139.807,
        "_internal_originalTime": 1689767878000
    },
    {
        "time": 1689767879000,
        "value": 139.808,
        "_internal_originalTime": 1689767879000
    },
    {
        "time": 1689767880000,
        "value": 139.806,
        "_internal_originalTime": 1689767880000
    },
    {
        "time": 1689767881000,
        "value": 139.802,
        "_internal_originalTime": 1689767881000
    },
    {
        "time": 1689767882000,
        "value": 139.818,
        "_internal_originalTime": 1689767882000
    },
    {
        "time": 1689767883000,
        "value": 139.822,
        "_internal_originalTime": 1689767883000
    },
    {
        "time": 1689767885000,
        "value": 139.816,
        "_internal_originalTime": 1689767885000
    },
    {
        "time": 1689767886000,
        "value": 139.822,
        "_internal_originalTime": 1689767886000
    },
    {
        "time": 1689767887000,
        "value": 139.814,
        "_internal_originalTime": 1689767887000
    },
    {
        "time": 1689767888000,
        "value": 139.815,
        "_internal_originalTime": 1689767888000
    },
    {
        "time": 1689767890000,
        "value": 139.817,
        "_internal_originalTime": 1689767890000
    },
    {
        "time": 1689767891000,
        "value": 139.816,
        "_internal_originalTime": 1689767891000
    },
    {
        "time": 1689767892000,
        "value": 139.813,
        "_internal_originalTime": 1689767892000
    },
    {
        "time": 1689767894000,
        "value": 139.819,
        "_internal_originalTime": 1689767894000
    },
    {
        "time": 1689767895000,
        "value": 139.803,
        "_internal_originalTime": 1689767895000
    },
    {
        "time": 1689767897000,
        "value": 139.804,
        "_internal_originalTime": 1689767897000
    },
    {
        "time": 1689767898000,
        "value": 139.808,
        "_internal_originalTime": 1689767898000
    },
    {
        "time": 1689767899000,
        "value": 139.813,
        "_internal_originalTime": 1689767899000
    },
    {
        "time": 1689767901000,
        "value": 139.814,
        "_internal_originalTime": 1689767901000
    },
    {
        "time": 1689767902000,
        "value": 139.812,
        "_internal_originalTime": 1689767902000
    },
    {
        "time": 1689767903000,
        "value": 139.804,
        "_internal_originalTime": 1689767903000
    },
    {
        "time": 1689767905000,
        "value": 139.806,
        "_internal_originalTime": 1689767905000
    },
    {
        "time": 1689767906000,
        "value": 139.804,
        "_internal_originalTime": 1689767906000
    },
    {
        "time": 1689767908000,
        "value": 139.81,
        "_internal_originalTime": 1689767908000
    },
    {
        "time": 1689767909000,
        "value": 139.812,
        "_internal_originalTime": 1689767909000
    },
    {
        "time": 1689767911000,
        "value": 139.808,
        "_internal_originalTime": 1689767911000
    },
    {
        "time": 1689767912000,
        "value": 139.805,
        "_internal_originalTime": 1689767912000
    },
    {
        "time": 1689767913000,
        "value": 139.803,
        "_internal_originalTime": 1689767913000
    },
    {
        "time": 1689767914000,
        "value": 139.807,
        "_internal_originalTime": 1689767914000
    },
    {
        "time": 1689767915000,
        "value": 139.813,
        "_internal_originalTime": 1689767915000
    },
    {
        "time": 1689767917000,
        "value": 139.814,
        "_internal_originalTime": 1689767917000
    },
    {
        "time": 1689767918000,
        "value": 139.806,
        "_internal_originalTime": 1689767918000
    },
    {
        "time": 1689767920000,
        "value": 139.804,
        "_internal_originalTime": 1689767920000
    },
    {
        "time": 1689767921000,
        "value": 139.808,
        "_internal_originalTime": 1689767921000
    },
    {
        "time": 1689767923000,
        "value": 139.808,
        "_internal_originalTime": 1689767923000
    },
    {
        "time": 1689767924000,
        "value": 139.81,
        "_internal_originalTime": 1689767924000
    },
    {
        "time": 1689767925000,
        "value": 139.81,
        "_internal_originalTime": 1689767925000
    },
    {
        "time": 1689767926000,
        "value": 139.811,
        "_internal_originalTime": 1689767926000
    },
    {
        "time": 1689767927000,
        "value": 139.816,
        "_internal_originalTime": 1689767927000
    },
    {
        "time": 1689767929000,
        "value": 139.814,
        "_internal_originalTime": 1689767929000
    },
    {
        "time": 1689767930000,
        "value": 139.816,
        "_internal_originalTime": 1689767930000
    },
    {
        "time": 1689767932000,
        "value": 139.814,
        "_internal_originalTime": 1689767932000
    },
    {
        "time": 1689767933000,
        "value": 139.816,
        "_internal_originalTime": 1689767933000
    },
    {
        "time": 1689767934000,
        "value": 139.818,
        "_internal_originalTime": 1689767934000
    },
    {
        "time": 1689767936000,
        "value": 139.813,
        "_internal_originalTime": 1689767936000
    },
    {
        "time": 1689767937000,
        "value": 139.815,
        "_internal_originalTime": 1689767937000
    },
    {
        "time": 1689767938000,
        "value": 139.81,
        "_internal_originalTime": 1689767938000
    },
    {
        "time": 1689767939000,
        "value": 139.815,
        "_internal_originalTime": 1689767939000
    },
    {
        "time": 1689767940000,
        "value": 139.804,
        "_internal_originalTime": 1689767940000
    },
    {
        "time": 1689767941000,
        "value": 139.805,
        "_internal_originalTime": 1689767941000
    },
    {
        "time": 1689767943000,
        "value": 139.808,
        "_internal_originalTime": 1689767943000
    },
    {
        "time": 1689767944000,
        "value": 139.808,
        "_internal_originalTime": 1689767944000
    },
    {
        "time": 1689767946000,
        "value": 139.806,
        "_internal_originalTime": 1689767946000
    },
    {
        "time": 1689767947000,
        "value": 139.808,
        "_internal_originalTime": 1689767947000
    },
    {
        "time": 1689767949000,
        "value": 139.802,
        "_internal_originalTime": 1689767949000
    },
    {
        "time": 1689767950000,
        "value": 139.804,
        "_internal_originalTime": 1689767950000
    },
    {
        "time": 1689767953000,
        "value": 139.806,
        "_internal_originalTime": 1689767953000
    },
    {
        "time": 1689767954000,
        "value": 139.802,
        "_internal_originalTime": 1689767954000
    },
    {
        "time": 1689767955000,
        "value": 139.796,
        "_internal_originalTime": 1689767955000
    },
    {
        "time": 1689767956000,
        "value": 139.794,
        "_internal_originalTime": 1689767956000
    },
    {
        "time": 1689767957000,
        "value": 139.799,
        "_internal_originalTime": 1689767957000
    },
    {
        "time": 1689767958000,
        "value": 139.796,
        "_internal_originalTime": 1689767958000
    },
    {
        "time": 1689767960000,
        "value": 139.799,
        "_internal_originalTime": 1689767960000
    },
    {
        "time": 1689767961000,
        "value": 139.805,
        "_internal_originalTime": 1689767961000
    },
    {
        "time": 1689767962000,
        "value": 139.808,
        "_internal_originalTime": 1689767962000
    },
    {
        "time": 1689767965000,
        "value": 139.81,
        "_internal_originalTime": 1689767965000
    },
    {
        "time": 1689767966000,
        "value": 139.808,
        "_internal_originalTime": 1689767966000
    },
    {
        "time": 1689767968000,
        "value": 139.8,
        "_internal_originalTime": 1689767968000
    },
    {
        "time": 1689767970000,
        "value": 139.801,
        "_internal_originalTime": 1689767970000
    },
    {
        "time": 1689767971000,
        "value": 139.798,
        "_internal_originalTime": 1689767971000
    },
    {
        "time": 1689767972000,
        "value": 139.795,
        "_internal_originalTime": 1689767972000
    },
    {
        "time": 1689767973000,
        "value": 139.792,
        "_internal_originalTime": 1689767973000
    },
    {
        "time": 1689767975000,
        "value": 139.796,
        "_internal_originalTime": 1689767975000
    },
    {
        "time": 1689767976000,
        "value": 139.794,
        "_internal_originalTime": 1689767976000
    },
    {
        "time": 1689767978000,
        "value": 139.799,
        "_internal_originalTime": 1689767978000
    },
    {
        "time": 1689767979000,
        "value": 139.796,
        "_internal_originalTime": 1689767979000
    },
    {
        "time": 1689767981000,
        "value": 139.8,
        "_internal_originalTime": 1689767981000
    },
    {
        "time": 1689767982000,
        "value": 139.795,
        "_internal_originalTime": 1689767982000
    },
    {
        "time": 1689767984000,
        "value": 139.795,
        "_internal_originalTime": 1689767984000
    },
    {
        "time": 1689767985000,
        "value": 139.795,
        "_internal_originalTime": 1689767985000
    },
    {
        "time": 1689767986000,
        "value": 139.794,
        "_internal_originalTime": 1689767986000
    },
    {
        "time": 1689767988000,
        "value": 139.794,
        "_internal_originalTime": 1689767988000
    },
    {
        "time": 1689767989000,
        "value": 139.798,
        "_internal_originalTime": 1689767989000
    },
    {
        "time": 1689767991000,
        "value": 139.794,
        "_internal_originalTime": 1689767991000
    },
    {
        "time": 1689767992000,
        "value": 139.798,
        "_internal_originalTime": 1689767992000
    },
    {
        "time": 1689767993000,
        "value": 139.794,
        "_internal_originalTime": 1689767993000
    },
    {
        "time": 1689767995000,
        "value": 139.8,
        "_internal_originalTime": 1689767995000
    },
    {
        "time": 1689767997000,
        "value": 139.797,
        "_internal_originalTime": 1689767997000
    },
    {
        "time": 1689767998000,
        "value": 139.799,
        "_internal_originalTime": 1689767998000
    },
    {
        "time": 1689768000000,
        "value": 139.797,
        "_internal_originalTime": 1689768000000
    },
    {
        "time": 1689768001000,
        "value": 139.78,
        "_internal_originalTime": 1689768001000
    },
    {
        "time": 1689768002000,
        "value": 139.782,
        "_internal_originalTime": 1689768002000
    },
    {
        "time": 1689768003000,
        "value": 139.779,
        "_internal_originalTime": 1689768003000
    },
    {
        "time": 1689768004000,
        "value": 139.777,
        "_internal_originalTime": 1689768004000
    },
    {
        "time": 1689768005000,
        "value": 139.778,
        "_internal_originalTime": 1689768005000
    },
    {
        "time": 1689768006000,
        "value": 139.779,
        "_internal_originalTime": 1689768006000
    },
    {
        "time": 1689768007000,
        "value": 139.785,
        "_internal_originalTime": 1689768007000
    },
    {
        "time": 1689768009000,
        "value": 139.788,
        "_internal_originalTime": 1689768009000
    },
    {
        "time": 1689768010000,
        "value": 139.788,
        "_internal_originalTime": 1689768010000
    },
    {
        "time": 1689768011000,
        "value": 139.786,
        "_internal_originalTime": 1689768011000
    },
    {
        "time": 1689768013000,
        "value": 139.788,
        "_internal_originalTime": 1689768013000
    },
    {
        "time": 1689768015000,
        "value": 139.786,
        "_internal_originalTime": 1689768015000
    },
    {
        "time": 1689768016000,
        "value": 139.788,
        "_internal_originalTime": 1689768016000
    },
    {
        "time": 1689768018000,
        "value": 139.792,
        "_internal_originalTime": 1689768018000
    },
    {
        "time": 1689768019000,
        "value": 139.794,
        "_internal_originalTime": 1689768019000
    },
    {
        "time": 1689768020000,
        "value": 139.798,
        "_internal_originalTime": 1689768020000
    },
    {
        "time": 1689768021000,
        "value": 139.798,
        "_internal_originalTime": 1689768021000
    },
    {
        "time": 1689768023000,
        "value": 139.792,
        "_internal_originalTime": 1689768023000
    },
    {
        "time": 1689768024000,
        "value": 139.788,
        "_internal_originalTime": 1689768024000
    },
    {
        "time": 1689768025000,
        "value": 139.784,
        "_internal_originalTime": 1689768025000
    },
    {
        "time": 1689768027000,
        "value": 139.784,
        "_internal_originalTime": 1689768027000
    },
    {
        "time": 1689768028000,
        "value": 139.782,
        "_internal_originalTime": 1689768028000
    },
    {
        "time": 1689768030000,
        "value": 139.78,
        "_internal_originalTime": 1689768030000
    },
    {
        "time": 1689768031000,
        "value": 139.782,
        "_internal_originalTime": 1689768031000
    },
    {
        "time": 1689768032000,
        "value": 139.78,
        "_internal_originalTime": 1689768032000
    },
    {
        "time": 1689768034000,
        "value": 139.788,
        "_internal_originalTime": 1689768034000
    },
    {
        "time": 1689768035000,
        "value": 139.787,
        "_internal_originalTime": 1689768035000
    },
    {
        "time": 1689768036000,
        "value": 139.788,
        "_internal_originalTime": 1689768036000
    },
    {
        "time": 1689768038000,
        "value": 139.787,
        "_internal_originalTime": 1689768038000
    },
    {
        "time": 1689768039000,
        "value": 139.791,
        "_internal_originalTime": 1689768039000
    },
    {
        "time": 1689768041000,
        "value": 139.788,
        "_internal_originalTime": 1689768041000
    },
    {
        "time": 1689768042000,
        "value": 139.792,
        "_internal_originalTime": 1689768042000
    },
    {
        "time": 1689768043000,
        "value": 139.787,
        "_internal_originalTime": 1689768043000
    },
    {
        "time": 1689768044000,
        "value": 139.791,
        "_internal_originalTime": 1689768044000
    },
    {
        "time": 1689768045000,
        "value": 139.784,
        "_internal_originalTime": 1689768045000
    },
    {
        "time": 1689768047000,
        "value": 139.786,
        "_internal_originalTime": 1689768047000
    },
    {
        "time": 1689768048000,
        "value": 139.797,
        "_internal_originalTime": 1689768048000
    },
    {
        "time": 1689768050000,
        "value": 139.795,
        "_internal_originalTime": 1689768050000
    },
    {
        "time": 1689768051000,
        "value": 139.8,
        "_internal_originalTime": 1689768051000
    },
    {
        "time": 1689768052000,
        "value": 139.803,
        "_internal_originalTime": 1689768052000
    },
    {
        "time": 1689768054000,
        "value": 139.804,
        "_internal_originalTime": 1689768054000
    },
    {
        "time": 1689768055000,
        "value": 139.808,
        "_internal_originalTime": 1689768055000
    },
    {
        "time": 1689768056000,
        "value": 139.807,
        "_internal_originalTime": 1689768056000
    },
    {
        "time": 1689768058000,
        "value": 139.805,
        "_internal_originalTime": 1689768058000
    },
    {
        "time": 1689768059000,
        "value": 139.804,
        "_internal_originalTime": 1689768059000
    },
    {
        "time": 1689768060000,
        "value": 139.802,
        "_internal_originalTime": 1689768060000
    },
    {
        "time": 1689768061000,
        "value": 139.8,
        "_internal_originalTime": 1689768061000
    },
    {
        "time": 1689768062000,
        "value": 139.802,
        "_internal_originalTime": 1689768062000
    },
    {
        "time": 1689768063000,
        "value": 139.8,
        "_internal_originalTime": 1689768063000
    },
    {
        "time": 1689768064000,
        "value": 139.801,
        "_internal_originalTime": 1689768064000
    },
    {
        "time": 1689768066000,
        "value": 139.801,
        "_internal_originalTime": 1689768066000
    },
    {
        "time": 1689768068000,
        "value": 139.799,
        "_internal_originalTime": 1689768068000
    },
    {
        "time": 1689768069000,
        "value": 139.796,
        "_internal_originalTime": 1689768069000
    },
    {
        "time": 1689768071000,
        "value": 139.797,
        "_internal_originalTime": 1689768071000
    },
    {
        "time": 1689768073000,
        "value": 139.798,
        "_internal_originalTime": 1689768073000
    },
    {
        "time": 1689768074000,
        "value": 139.792,
        "_internal_originalTime": 1689768074000
    },
    {
        "time": 1689768075000,
        "value": 139.795,
        "_internal_originalTime": 1689768075000
    },
    {
        "time": 1689768076000,
        "value": 139.797,
        "_internal_originalTime": 1689768076000
    },
    {
        "time": 1689768077000,
        "value": 139.797,
        "_internal_originalTime": 1689768077000
    },
    {
        "time": 1689768078000,
        "value": 139.792,
        "_internal_originalTime": 1689768078000
    },
    {
        "time": 1689768079000,
        "value": 139.795,
        "_internal_originalTime": 1689768079000
    },
    {
        "time": 1689768081000,
        "value": 139.798,
        "_internal_originalTime": 1689768081000
    },
    {
        "time": 1689768084000,
        "value": 139.803,
        "_internal_originalTime": 1689768084000
    },
    {
        "time": 1689768085000,
        "value": 139.795,
        "_internal_originalTime": 1689768085000
    },
    {
        "time": 1689768086000,
        "value": 139.796,
        "_internal_originalTime": 1689768086000
    },
    {
        "time": 1689768087000,
        "value": 139.798,
        "_internal_originalTime": 1689768087000
    },
    {
        "time": 1689768089000,
        "value": 139.796,
        "_internal_originalTime": 1689768089000
    },
    {
        "time": 1689768090000,
        "value": 139.798,
        "_internal_originalTime": 1689768090000
    },
    {
        "time": 1689768091000,
        "value": 139.787,
        "_internal_originalTime": 1689768091000
    },
    {
        "time": 1689768092000,
        "value": 139.779,
        "_internal_originalTime": 1689768092000
    },
    {
        "time": 1689768094000,
        "value": 139.782,
        "_internal_originalTime": 1689768094000
    },
    {
        "time": 1689768095000,
        "value": 139.778,
        "_internal_originalTime": 1689768095000
    },
    {
        "time": 1689768096000,
        "value": 139.78,
        "_internal_originalTime": 1689768096000
    },
    {
        "time": 1689768098000,
        "value": 139.778,
        "_internal_originalTime": 1689768098000
    },
    {
        "time": 1689768100000,
        "value": 139.782,
        "_internal_originalTime": 1689768100000
    },
    {
        "time": 1689768101000,
        "value": 139.782,
        "_internal_originalTime": 1689768101000
    },
    {
        "time": 1689768102000,
        "value": 139.778,
        "_internal_originalTime": 1689768102000
    },
    {
        "time": 1689768105000,
        "value": 139.78,
        "_internal_originalTime": 1689768105000
    },
    {
        "time": 1689768107000,
        "value": 139.783,
        "_internal_originalTime": 1689768107000
    },
    {
        "time": 1689768108000,
        "value": 139.775,
        "_internal_originalTime": 1689768108000
    },
    {
        "time": 1689768109000,
        "value": 139.772,
        "_internal_originalTime": 1689768109000
    },
    {
        "time": 1689768111000,
        "value": 139.767,
        "_internal_originalTime": 1689768111000
    },
    {
        "time": 1689768116000,
        "value": 139.766,
        "_internal_originalTime": 1689768116000
    },
    {
        "time": 1689768117000,
        "value": 139.767,
        "_internal_originalTime": 1689768117000
    },
    {
        "time": 1689768118000,
        "value": 139.767,
        "_internal_originalTime": 1689768118000
    },
    {
        "time": 1689768119000,
        "value": 139.768,
        "_internal_originalTime": 1689768119000
    },
    {
        "time": 1689768121000,
        "value": 139.769,
        "_internal_originalTime": 1689768121000
    },
    {
        "time": 1689768122000,
        "value": 139.772,
        "_internal_originalTime": 1689768122000
    },
    {
        "time": 1689768123000,
        "value": 139.779,
        "_internal_originalTime": 1689768123000
    },
    {
        "time": 1689768125000,
        "value": 139.781,
        "_internal_originalTime": 1689768125000
    },
    {
        "time": 1689768126000,
        "value": 139.778,
        "_internal_originalTime": 1689768126000
    },
    {
        "time": 1689768127000,
        "value": 139.786,
        "_internal_originalTime": 1689768127000
    },
    {
        "time": 1689768128000,
        "value": 139.793,
        "_internal_originalTime": 1689768128000
    },
    {
        "time": 1689768130000,
        "value": 139.791,
        "_internal_originalTime": 1689768130000
    },
    {
        "time": 1689768132000,
        "value": 139.799,
        "_internal_originalTime": 1689768132000
    },
    {
        "time": 1689768133000,
        "value": 139.799,
        "_internal_originalTime": 1689768133000
    },
    {
        "time": 1689768135000,
        "value": 139.798,
        "_internal_originalTime": 1689768135000
    },
    {
        "time": 1689768136000,
        "value": 139.799,
        "_internal_originalTime": 1689768136000
    },
    {
        "time": 1689768137000,
        "value": 139.802,
        "_internal_originalTime": 1689768137000
    },
    {
        "time": 1689768139000,
        "value": 139.796,
        "_internal_originalTime": 1689768139000
    },
    {
        "time": 1689768141000,
        "value": 139.796,
        "_internal_originalTime": 1689768141000
    },
    {
        "time": 1689768143000,
        "value": 139.803,
        "_internal_originalTime": 1689768143000
    },
    {
        "time": 1689768144000,
        "value": 139.801,
        "_internal_originalTime": 1689768144000
    },
    {
        "time": 1689768145000,
        "value": 139.792,
        "_internal_originalTime": 1689768145000
    },
    {
        "time": 1689768146000,
        "value": 139.791,
        "_internal_originalTime": 1689768146000
    },
    {
        "time": 1689768148000,
        "value": 139.79,
        "_internal_originalTime": 1689768148000
    },
    {
        "time": 1689768150000,
        "value": 139.787,
        "_internal_originalTime": 1689768150000
    },
    {
        "time": 1689768151000,
        "value": 139.788,
        "_internal_originalTime": 1689768151000
    },
    {
        "time": 1689768152000,
        "value": 139.787,
        "_internal_originalTime": 1689768152000
    },
    {
        "time": 1689768153000,
        "value": 139.789,
        "_internal_originalTime": 1689768153000
    },
    {
        "time": 1689768154000,
        "value": 139.789,
        "_internal_originalTime": 1689768154000
    },
    {
        "time": 1689768156000,
        "value": 139.788,
        "_internal_originalTime": 1689768156000
    },
    {
        "time": 1689768157000,
        "value": 139.787,
        "_internal_originalTime": 1689768157000
    },
    {
        "time": 1689768159000,
        "value": 139.788,
        "_internal_originalTime": 1689768159000
    },
    {
        "time": 1689768160000,
        "value": 139.78,
        "_internal_originalTime": 1689768160000
    },
    {
        "time": 1689768161000,
        "value": 139.776,
        "_internal_originalTime": 1689768161000
    },
    {
        "time": 1689768163000,
        "value": 139.774,
        "_internal_originalTime": 1689768163000
    },
    {
        "time": 1689768164000,
        "value": 139.785,
        "_internal_originalTime": 1689768164000
    },
    {
        "time": 1689768165000,
        "value": 139.786,
        "_internal_originalTime": 1689768165000
    },
    {
        "time": 1689768166000,
        "value": 139.782,
        "_internal_originalTime": 1689768166000
    },
    {
        "time": 1689768168000,
        "value": 139.782,
        "_internal_originalTime": 1689768168000
    },
    {
        "time": 1689768169000,
        "value": 139.783,
        "_internal_originalTime": 1689768169000
    },
    {
        "time": 1689768171000,
        "value": 139.781,
        "_internal_originalTime": 1689768171000
    },
    {
        "time": 1689768172000,
        "value": 139.778,
        "_internal_originalTime": 1689768172000
    },
    {
        "time": 1689768173000,
        "value": 139.78,
        "_internal_originalTime": 1689768173000
    },
    {
        "time": 1689768175000,
        "value": 139.782,
        "_internal_originalTime": 1689768175000
    },
    {
        "time": 1689768176000,
        "value": 139.782,
        "_internal_originalTime": 1689768176000
    },
    {
        "time": 1689768178000,
        "value": 139.776,
        "_internal_originalTime": 1689768178000
    },
    {
        "time": 1689768180000,
        "value": 139.779,
        "_internal_originalTime": 1689768180000
    },
    {
        "time": 1689768181000,
        "value": 139.778,
        "_internal_originalTime": 1689768181000
    },
    {
        "time": 1689768182000,
        "value": 139.783,
        "_internal_originalTime": 1689768182000
    },
    {
        "time": 1689768184000,
        "value": 139.786,
        "_internal_originalTime": 1689768184000
    },
    {
        "time": 1689768185000,
        "value": 139.785,
        "_internal_originalTime": 1689768185000
    },
    {
        "time": 1689768186000,
        "value": 139.782,
        "_internal_originalTime": 1689768186000
    },
    {
        "time": 1689768188000,
        "value": 139.783,
        "_internal_originalTime": 1689768188000
    },
    {
        "time": 1689768189000,
        "value": 139.782,
        "_internal_originalTime": 1689768189000
    },
    {
        "time": 1689768190000,
        "value": 139.78,
        "_internal_originalTime": 1689768190000
    },
    {
        "time": 1689768191000,
        "value": 139.783,
        "_internal_originalTime": 1689768191000
    },
    {
        "time": 1689768194000,
        "value": 139.782,
        "_internal_originalTime": 1689768194000
    },
    {
        "time": 1689768197000,
        "value": 139.781,
        "_internal_originalTime": 1689768197000
    },
    {
        "time": 1689768198000,
        "value": 139.782,
        "_internal_originalTime": 1689768198000
    },
    {
        "time": 1689768200000,
        "value": 139.781,
        "_internal_originalTime": 1689768200000
    },
    {
        "time": 1689768201000,
        "value": 139.785,
        "_internal_originalTime": 1689768201000
    },
    {
        "time": 1689768202000,
        "value": 139.78,
        "_internal_originalTime": 1689768202000
    },
    {
        "time": 1689768203000,
        "value": 139.782,
        "_internal_originalTime": 1689768203000
    },
    {
        "time": 1689768205000,
        "value": 139.78,
        "_internal_originalTime": 1689768205000
    },
    {
        "time": 1689768206000,
        "value": 139.782,
        "_internal_originalTime": 1689768206000
    },
    {
        "time": 1689768207000,
        "value": 139.777,
        "_internal_originalTime": 1689768207000
    },
    {
        "time": 1689768209000,
        "value": 139.766,
        "_internal_originalTime": 1689768209000
    },
    {
        "time": 1689768210000,
        "value": 139.768,
        "_internal_originalTime": 1689768210000
    },
    {
        "time": 1689768213000,
        "value": 139.771,
        "_internal_originalTime": 1689768213000
    },
    {
        "time": 1689768214000,
        "value": 139.777,
        "_internal_originalTime": 1689768214000
    },
    {
        "time": 1689768215000,
        "value": 139.774,
        "_internal_originalTime": 1689768215000
    },
    {
        "time": 1689768217000,
        "value": 139.77,
        "_internal_originalTime": 1689768217000
    },
    {
        "time": 1689768218000,
        "value": 139.765,
        "_internal_originalTime": 1689768218000
    },
    {
        "time": 1689768220000,
        "value": 139.771,
        "_internal_originalTime": 1689768220000
    },
    {
        "time": 1689768221000,
        "value": 139.759,
        "_internal_originalTime": 1689768221000
    },
    {
        "time": 1689768222000,
        "value": 139.758,
        "_internal_originalTime": 1689768222000
    },
    {
        "time": 1689768226000,
        "value": 139.751,
        "_internal_originalTime": 1689768226000
    },
    {
        "time": 1689768228000,
        "value": 139.749,
        "_internal_originalTime": 1689768228000
    },
    {
        "time": 1689768229000,
        "value": 139.748,
        "_internal_originalTime": 1689768229000
    },
    {
        "time": 1689768231000,
        "value": 139.753,
        "_internal_originalTime": 1689768231000
    },
    {
        "time": 1689768232000,
        "value": 139.75,
        "_internal_originalTime": 1689768232000
    },
    {
        "time": 1689768234000,
        "value": 139.759,
        "_internal_originalTime": 1689768234000
    },
    {
        "time": 1689768235000,
        "value": 139.762,
        "_internal_originalTime": 1689768235000
    },
    {
        "time": 1689768236000,
        "value": 139.764,
        "_internal_originalTime": 1689768236000
    },
    {
        "time": 1689768238000,
        "value": 139.772,
        "_internal_originalTime": 1689768238000
    },
    {
        "time": 1689768240000,
        "value": 139.773,
        "_internal_originalTime": 1689768240000
    },
    {
        "time": 1689768241000,
        "value": 139.772,
        "_internal_originalTime": 1689768241000
    },
    {
        "time": 1689768242000,
        "value": 139.771,
        "_internal_originalTime": 1689768242000
    },
    {
        "time": 1689768243000,
        "value": 139.769,
        "_internal_originalTime": 1689768243000
    },
    {
        "time": 1689768244000,
        "value": 139.772,
        "_internal_originalTime": 1689768244000
    },
    {
        "time": 1689768246000,
        "value": 139.768,
        "_internal_originalTime": 1689768246000
    },
    {
        "time": 1689768248000,
        "value": 139.77,
        "_internal_originalTime": 1689768248000
    },
    {
        "time": 1689768249000,
        "value": 139.76,
        "_internal_originalTime": 1689768249000
    },
    {
        "time": 1689768250000,
        "value": 139.766,
        "_internal_originalTime": 1689768250000
    },
    {
        "time": 1689768252000,
        "value": 139.767,
        "_internal_originalTime": 1689768252000
    },
    {
        "time": 1689768253000,
        "value": 139.768,
        "_internal_originalTime": 1689768253000
    },
    {
        "time": 1689768254000,
        "value": 139.767,
        "_internal_originalTime": 1689768254000
    },
    {
        "time": 1689768255000,
        "value": 139.77,
        "_internal_originalTime": 1689768255000
    },
    {
        "time": 1689768256000,
        "value": 139.765,
        "_internal_originalTime": 1689768256000
    },
    {
        "time": 1689768257000,
        "value": 139.767,
        "_internal_originalTime": 1689768257000
    },
    {
        "time": 1689768258000,
        "value": 139.766,
        "_internal_originalTime": 1689768258000
    },
    {
        "time": 1689768260000,
        "value": 139.769,
        "_internal_originalTime": 1689768260000
    },
    {
        "time": 1689768261000,
        "value": 139.767,
        "_internal_originalTime": 1689768261000
    },
    {
        "time": 1689768262000,
        "value": 139.766,
        "_internal_originalTime": 1689768262000
    },
    {
        "time": 1689768264000,
        "value": 139.759,
        "_internal_originalTime": 1689768264000
    },
    {
        "time": 1689768266000,
        "value": 139.764,
        "_internal_originalTime": 1689768266000
    },
    {
        "time": 1689768267000,
        "value": 139.763,
        "_internal_originalTime": 1689768267000
    },
    {
        "time": 1689768269000,
        "value": 139.769,
        "_internal_originalTime": 1689768269000
    },
    {
        "time": 1689768270000,
        "value": 139.766,
        "_internal_originalTime": 1689768270000
    },
    {
        "time": 1689768271000,
        "value": 139.766,
        "_internal_originalTime": 1689768271000
    },
    {
        "time": 1689768273000,
        "value": 139.765,
        "_internal_originalTime": 1689768273000
    },
    {
        "time": 1689768274000,
        "value": 139.762,
        "_internal_originalTime": 1689768274000
    },
    {
        "time": 1689768276000,
        "value": 139.757,
        "_internal_originalTime": 1689768276000
    },
    {
        "time": 1689768277000,
        "value": 139.752,
        "_internal_originalTime": 1689768277000
    },
    {
        "time": 1689768278000,
        "value": 139.751,
        "_internal_originalTime": 1689768278000
    },
    {
        "time": 1689768279000,
        "value": 139.752,
        "_internal_originalTime": 1689768279000
    },
    {
        "time": 1689768281000,
        "value": 139.751,
        "_internal_originalTime": 1689768281000
    },
    {
        "time": 1689768282000,
        "value": 139.753,
        "_internal_originalTime": 1689768282000
    },
    {
        "time": 1689768283000,
        "value": 139.747,
        "_internal_originalTime": 1689768283000
    },
    {
        "time": 1689768285000,
        "value": 139.741,
        "_internal_originalTime": 1689768285000
    },
    {
        "time": 1689768287000,
        "value": 139.74,
        "_internal_originalTime": 1689768287000
    },
    {
        "time": 1689768288000,
        "value": 139.743,
        "_internal_originalTime": 1689768288000
    },
    {
        "time": 1689768289000,
        "value": 139.738,
        "_internal_originalTime": 1689768289000
    },
    {
        "time": 1689768291000,
        "value": 139.74,
        "_internal_originalTime": 1689768291000
    },
    {
        "time": 1689768292000,
        "value": 139.741,
        "_internal_originalTime": 1689768292000
    },
    {
        "time": 1689768294000,
        "value": 139.743,
        "_internal_originalTime": 1689768294000
    },
    {
        "time": 1689768295000,
        "value": 139.742,
        "_internal_originalTime": 1689768295000
    },
    {
        "time": 1689768297000,
        "value": 139.74,
        "_internal_originalTime": 1689768297000
    },
    {
        "time": 1689768298000,
        "value": 139.741,
        "_internal_originalTime": 1689768298000
    },
    {
        "time": 1689768300000,
        "value": 139.747,
        "_internal_originalTime": 1689768300000
    },
    {
        "time": 1689768301000,
        "value": 139.751,
        "_internal_originalTime": 1689768301000
    },
    {
        "time": 1689768302000,
        "value": 139.749,
        "_internal_originalTime": 1689768302000
    },
    {
        "time": 1689768304000,
        "value": 139.749,
        "_internal_originalTime": 1689768304000
    },
    {
        "time": 1689768306000,
        "value": 139.753,
        "_internal_originalTime": 1689768306000
    },
    {
        "time": 1689768307000,
        "value": 139.754,
        "_internal_originalTime": 1689768307000
    },
    {
        "time": 1689768309000,
        "value": 139.75,
        "_internal_originalTime": 1689768309000
    },
    {
        "time": 1689768310000,
        "value": 139.75,
        "_internal_originalTime": 1689768310000
    },
    {
        "time": 1689768312000,
        "value": 139.75,
        "_internal_originalTime": 1689768312000
    },
    {
        "time": 1689768315000,
        "value": 139.748,
        "_internal_originalTime": 1689768315000
    },
    {
        "time": 1689768316000,
        "value": 139.747,
        "_internal_originalTime": 1689768316000
    },
    {
        "time": 1689768317000,
        "value": 139.74,
        "_internal_originalTime": 1689768317000
    },
    {
        "time": 1689768318000,
        "value": 139.734,
        "_internal_originalTime": 1689768318000
    },
    {
        "time": 1689768319000,
        "value": 139.737,
        "_internal_originalTime": 1689768319000
    },
    {
        "time": 1689768321000,
        "value": 139.738,
        "_internal_originalTime": 1689768321000
    },
    {
        "time": 1689768322000,
        "value": 139.737,
        "_internal_originalTime": 1689768322000
    },
    {
        "time": 1689768323000,
        "value": 139.74,
        "_internal_originalTime": 1689768323000
    },
    {
        "time": 1689768324000,
        "value": 139.743,
        "_internal_originalTime": 1689768324000
    },
    {
        "time": 1689768326000,
        "value": 139.74,
        "_internal_originalTime": 1689768326000
    },
    {
        "time": 1689768328000,
        "value": 139.74,
        "_internal_originalTime": 1689768328000
    },
    {
        "time": 1689768330000,
        "value": 139.742,
        "_internal_originalTime": 1689768330000
    },
    {
        "time": 1689768332000,
        "value": 139.741,
        "_internal_originalTime": 1689768332000
    },
    {
        "time": 1689768333000,
        "value": 139.74,
        "_internal_originalTime": 1689768333000
    },
    {
        "time": 1689768335000,
        "value": 139.742,
        "_internal_originalTime": 1689768335000
    },
    {
        "time": 1689768336000,
        "value": 139.738,
        "_internal_originalTime": 1689768336000
    },
    {
        "time": 1689768337000,
        "value": 139.738,
        "_internal_originalTime": 1689768337000
    },
    {
        "time": 1689768339000,
        "value": 139.74,
        "_internal_originalTime": 1689768339000
    },
    {
        "time": 1689768340000,
        "value": 139.74,
        "_internal_originalTime": 1689768340000
    },
    {
        "time": 1689768342000,
        "value": 139.74,
        "_internal_originalTime": 1689768342000
    },
    {
        "time": 1689768343000,
        "value": 139.738,
        "_internal_originalTime": 1689768343000
    },
    {
        "time": 1689768345000,
        "value": 139.738,
        "_internal_originalTime": 1689768345000
    },
    {
        "time": 1689768346000,
        "value": 139.738,
        "_internal_originalTime": 1689768346000
    },
    {
        "time": 1689768347000,
        "value": 139.725,
        "_internal_originalTime": 1689768347000
    },
    {
        "time": 1689768348000,
        "value": 139.723,
        "_internal_originalTime": 1689768348000
    },
    {
        "time": 1689768350000,
        "value": 139.722,
        "_internal_originalTime": 1689768350000
    },
    {
        "time": 1689768351000,
        "value": 139.72,
        "_internal_originalTime": 1689768351000
    },
    {
        "time": 1689768352000,
        "value": 139.723,
        "_internal_originalTime": 1689768352000
    },
    {
        "time": 1689768354000,
        "value": 139.72,
        "_internal_originalTime": 1689768354000
    },
    {
        "time": 1689768355000,
        "value": 139.722,
        "_internal_originalTime": 1689768355000
    },
    {
        "time": 1689768357000,
        "value": 139.719,
        "_internal_originalTime": 1689768357000
    },
    {
        "time": 1689768358000,
        "value": 139.714,
        "_internal_originalTime": 1689768358000
    },
    {
        "time": 1689768359000,
        "value": 139.71,
        "_internal_originalTime": 1689768359000
    },
    {
        "time": 1689768360000,
        "value": 139.712,
        "_internal_originalTime": 1689768360000
    },
    {
        "time": 1689768362000,
        "value": 139.707,
        "_internal_originalTime": 1689768362000
    },
    {
        "time": 1689768363000,
        "value": 139.707,
        "_internal_originalTime": 1689768363000
    },
    {
        "time": 1689768364000,
        "value": 139.711,
        "_internal_originalTime": 1689768364000
    },
    {
        "time": 1689768365000,
        "value": 139.709,
        "_internal_originalTime": 1689768365000
    },
    {
        "time": 1689768366000,
        "value": 139.712,
        "_internal_originalTime": 1689768366000
    },
    {
        "time": 1689768367000,
        "value": 139.708,
        "_internal_originalTime": 1689768367000
    },
    {
        "time": 1689768369000,
        "value": 139.711,
        "_internal_originalTime": 1689768369000
    },
    {
        "time": 1689768370000,
        "value": 139.704,
        "_internal_originalTime": 1689768370000
    },
    {
        "time": 1689768372000,
        "value": 139.679,
        "_internal_originalTime": 1689768372000
    },
    {
        "time": 1689768373000,
        "value": 139.672,
        "_internal_originalTime": 1689768373000
    },
    {
        "time": 1689768375000,
        "value": 139.663,
        "_internal_originalTime": 1689768375000
    },
    {
        "time": 1689768376000,
        "value": 139.672,
        "_internal_originalTime": 1689768376000
    },
    {
        "time": 1689768377000,
        "value": 139.672,
        "_internal_originalTime": 1689768377000
    },
    {
        "time": 1689768378000,
        "value": 139.68,
        "_internal_originalTime": 1689768378000
    },
    {
        "time": 1689768379000,
        "value": 139.678,
        "_internal_originalTime": 1689768379000
    },
    {
        "time": 1689768381000,
        "value": 139.678,
        "_internal_originalTime": 1689768381000
    },
    {
        "time": 1689768382000,
        "value": 139.678,
        "_internal_originalTime": 1689768382000
    },
    {
        "time": 1689768383000,
        "value": 139.678,
        "_internal_originalTime": 1689768383000
    },
    {
        "time": 1689768385000,
        "value": 139.676,
        "_internal_originalTime": 1689768385000
    },
    {
        "time": 1689768386000,
        "value": 139.675,
        "_internal_originalTime": 1689768386000
    },
    {
        "time": 1689768388000,
        "value": 139.681,
        "_internal_originalTime": 1689768388000
    },
    {
        "time": 1689768390000,
        "value": 139.681,
        "_internal_originalTime": 1689768390000
    },
    {
        "time": 1689768391000,
        "value": 139.674,
        "_internal_originalTime": 1689768391000
    },
    {
        "time": 1689768392000,
        "value": 139.674,
        "_internal_originalTime": 1689768392000
    },
    {
        "time": 1689768393000,
        "value": 139.681,
        "_internal_originalTime": 1689768393000
    },
    {
        "time": 1689768395000,
        "value": 139.681,
        "_internal_originalTime": 1689768395000
    },
    {
        "time": 1689768396000,
        "value": 139.683,
        "_internal_originalTime": 1689768396000
    },
    {
        "time": 1689768397000,
        "value": 139.68,
        "_internal_originalTime": 1689768397000
    },
    {
        "time": 1689768398000,
        "value": 139.681,
        "_internal_originalTime": 1689768398000
    },
    {
        "time": 1689768399000,
        "value": 139.679,
        "_internal_originalTime": 1689768399000
    },
    {
        "time": 1689768400000,
        "value": 139.682,
        "_internal_originalTime": 1689768400000
    },
    {
        "time": 1689768402000,
        "value": 139.678,
        "_internal_originalTime": 1689768402000
    },
    {
        "time": 1689768403000,
        "value": 139.683,
        "_internal_originalTime": 1689768403000
    },
    {
        "time": 1689768405000,
        "value": 139.681,
        "_internal_originalTime": 1689768405000
    },
    {
        "time": 1689768406000,
        "value": 139.684,
        "_internal_originalTime": 1689768406000
    },
    {
        "time": 1689768408000,
        "value": 139.681,
        "_internal_originalTime": 1689768408000
    },
    {
        "time": 1689768409000,
        "value": 139.682,
        "_internal_originalTime": 1689768409000
    },
    {
        "time": 1689768411000,
        "value": 139.678,
        "_internal_originalTime": 1689768411000
    },
    {
        "time": 1689768412000,
        "value": 139.682,
        "_internal_originalTime": 1689768412000
    },
    {
        "time": 1689768413000,
        "value": 139.653,
        "_internal_originalTime": 1689768413000
    },
    {
        "time": 1689768415000,
        "value": 139.656,
        "_internal_originalTime": 1689768415000
    },
    {
        "time": 1689768416000,
        "value": 139.643,
        "_internal_originalTime": 1689768416000
    },
    {
        "time": 1689768417000,
        "value": 139.643,
        "_internal_originalTime": 1689768417000
    },
    {
        "time": 1689768419000,
        "value": 139.634,
        "_internal_originalTime": 1689768419000
    },
    {
        "time": 1689768420000,
        "value": 139.642,
        "_internal_originalTime": 1689768420000
    },
    {
        "time": 1689768422000,
        "value": 139.654,
        "_internal_originalTime": 1689768422000
    },
    {
        "time": 1689768423000,
        "value": 139.654,
        "_internal_originalTime": 1689768423000
    },
    {
        "time": 1689768424000,
        "value": 139.652,
        "_internal_originalTime": 1689768424000
    },
    {
        "time": 1689768425000,
        "value": 139.654,
        "_internal_originalTime": 1689768425000
    },
    {
        "time": 1689768426000,
        "value": 139.652,
        "_internal_originalTime": 1689768426000
    },
    {
        "time": 1689768427000,
        "value": 139.67,
        "_internal_originalTime": 1689768427000
    },
    {
        "time": 1689768428000,
        "value": 139.668,
        "_internal_originalTime": 1689768428000
    },
    {
        "time": 1689768430000,
        "value": 139.673,
        "_internal_originalTime": 1689768430000
    },
    {
        "time": 1689768431000,
        "value": 139.674,
        "_internal_originalTime": 1689768431000
    },
    {
        "time": 1689768432000,
        "value": 139.673,
        "_internal_originalTime": 1689768432000
    },
    {
        "time": 1689768434000,
        "value": 139.67,
        "_internal_originalTime": 1689768434000
    },
    {
        "time": 1689768435000,
        "value": 139.67,
        "_internal_originalTime": 1689768435000
    },
    {
        "time": 1689768437000,
        "value": 139.672,
        "_internal_originalTime": 1689768437000
    },
    {
        "time": 1689768438000,
        "value": 139.671,
        "_internal_originalTime": 1689768438000
    },
    {
        "time": 1689768439000,
        "value": 139.671,
        "_internal_originalTime": 1689768439000
    },
    {
        "time": 1689768440000,
        "value": 139.674,
        "_internal_originalTime": 1689768440000
    },
    {
        "time": 1689768441000,
        "value": 139.664,
        "_internal_originalTime": 1689768441000
    },
    {
        "time": 1689768443000,
        "value": 139.662,
        "_internal_originalTime": 1689768443000
    },
    {
        "time": 1689768444000,
        "value": 139.662,
        "_internal_originalTime": 1689768444000
    },
    {
        "time": 1689768446000,
        "value": 139.66,
        "_internal_originalTime": 1689768446000
    },
    {
        "time": 1689768448000,
        "value": 139.666,
        "_internal_originalTime": 1689768448000
    },
    {
        "time": 1689768450000,
        "value": 139.664,
        "_internal_originalTime": 1689768450000
    },
    {
        "time": 1689768451000,
        "value": 139.662,
        "_internal_originalTime": 1689768451000
    },
    {
        "time": 1689768453000,
        "value": 139.657,
        "_internal_originalTime": 1689768453000
    },
    {
        "time": 1689768455000,
        "value": 139.655,
        "_internal_originalTime": 1689768455000
    },
    {
        "time": 1689768456000,
        "value": 139.656,
        "_internal_originalTime": 1689768456000
    },
    {
        "time": 1689768457000,
        "value": 139.655,
        "_internal_originalTime": 1689768457000
    },
    {
        "time": 1689768459000,
        "value": 139.657,
        "_internal_originalTime": 1689768459000
    },
    {
        "time": 1689768460000,
        "value": 139.652,
        "_internal_originalTime": 1689768460000
    },
    {
        "time": 1689768461000,
        "value": 139.65,
        "_internal_originalTime": 1689768461000
    },
    {
        "time": 1689768463000,
        "value": 139.652,
        "_internal_originalTime": 1689768463000
    },
    {
        "time": 1689768464000,
        "value": 139.65,
        "_internal_originalTime": 1689768464000
    },
    {
        "time": 1689768466000,
        "value": 139.651,
        "_internal_originalTime": 1689768466000
    },
    {
        "time": 1689768467000,
        "value": 139.657,
        "_internal_originalTime": 1689768467000
    },
    {
        "time": 1689768468000,
        "value": 139.656,
        "_internal_originalTime": 1689768468000
    },
    {
        "time": 1689768470000,
        "value": 139.656,
        "_internal_originalTime": 1689768470000
    },
    {
        "time": 1689768471000,
        "value": 139.66,
        "_internal_originalTime": 1689768471000
    },
    {
        "time": 1689768473000,
        "value": 139.666,
        "_internal_originalTime": 1689768473000
    },
    {
        "time": 1689768474000,
        "value": 139.673,
        "_internal_originalTime": 1689768474000
    },
    {
        "time": 1689768475000,
        "value": 139.673,
        "_internal_originalTime": 1689768475000
    },
    {
        "time": 1689768476000,
        "value": 139.675,
        "_internal_originalTime": 1689768476000
    },
    {
        "time": 1689768478000,
        "value": 139.675,
        "_internal_originalTime": 1689768478000
    },
    {
        "time": 1689768479000,
        "value": 139.676,
        "_internal_originalTime": 1689768479000
    },
    {
        "time": 1689768480000,
        "value": 139.673,
        "_internal_originalTime": 1689768480000
    },
    {
        "time": 1689768481000,
        "value": 139.674,
        "_internal_originalTime": 1689768481000
    },
    {
        "time": 1689768482000,
        "value": 139.673,
        "_internal_originalTime": 1689768482000
    },
    {
        "time": 1689768484000,
        "value": 139.673,
        "_internal_originalTime": 1689768484000
    },
    {
        "time": 1689768485000,
        "value": 139.678,
        "_internal_originalTime": 1689768485000
    },
    {
        "time": 1689768487000,
        "value": 139.681,
        "_internal_originalTime": 1689768487000
    },
    {
        "time": 1689768488000,
        "value": 139.683,
        "_internal_originalTime": 1689768488000
    },
    {
        "time": 1689768489000,
        "value": 139.686,
        "_internal_originalTime": 1689768489000
    },
    {
        "time": 1689768490000,
        "value": 139.683,
        "_internal_originalTime": 1689768490000
    },
    {
        "time": 1689768492000,
        "value": 139.685,
        "_internal_originalTime": 1689768492000
    },
    {
        "time": 1689768493000,
        "value": 139.689,
        "_internal_originalTime": 1689768493000
    },
    {
        "time": 1689768495000,
        "value": 139.688,
        "_internal_originalTime": 1689768495000
    },
    {
        "time": 1689768497000,
        "value": 139.696,
        "_internal_originalTime": 1689768497000
    },
    {
        "time": 1689768498000,
        "value": 139.696,
        "_internal_originalTime": 1689768498000
    },
    {
        "time": 1689768499000,
        "value": 139.692,
        "_internal_originalTime": 1689768499000
    },
    {
        "time": 1689768501000,
        "value": 139.704,
        "_internal_originalTime": 1689768501000
    },
    {
        "time": 1689768502000,
        "value": 139.707,
        "_internal_originalTime": 1689768502000
    },
    {
        "time": 1689768503000,
        "value": 139.712,
        "_internal_originalTime": 1689768503000
    },
    {
        "time": 1689768505000,
        "value": 139.722,
        "_internal_originalTime": 1689768505000
    },
    {
        "time": 1689768507000,
        "value": 139.72,
        "_internal_originalTime": 1689768507000
    },
    {
        "time": 1689768508000,
        "value": 139.719,
        "_internal_originalTime": 1689768508000
    },
    {
        "time": 1689768509000,
        "value": 139.721,
        "_internal_originalTime": 1689768509000
    },
    {
        "time": 1689768510000,
        "value": 139.722,
        "_internal_originalTime": 1689768510000
    },
    {
        "time": 1689768511000,
        "value": 139.724,
        "_internal_originalTime": 1689768511000
    },
    {
        "time": 1689768513000,
        "value": 139.725,
        "_internal_originalTime": 1689768513000
    },
    {
        "time": 1689768514000,
        "value": 139.723,
        "_internal_originalTime": 1689768514000
    },
    {
        "time": 1689768515000,
        "value": 139.721,
        "_internal_originalTime": 1689768515000
    },
    {
        "time": 1689768517000,
        "value": 139.719,
        "_internal_originalTime": 1689768517000
    },
    {
        "time": 1689768518000,
        "value": 139.72,
        "_internal_originalTime": 1689768518000
    },
    {
        "time": 1689768519000,
        "value": 139.722,
        "_internal_originalTime": 1689768519000
    },
    {
        "time": 1689768521000,
        "value": 139.724,
        "_internal_originalTime": 1689768521000
    },
    {
        "time": 1689768522000,
        "value": 139.726,
        "_internal_originalTime": 1689768522000
    },
    {
        "time": 1689768524000,
        "value": 139.725,
        "_internal_originalTime": 1689768524000
    },
    {
        "time": 1689768525000,
        "value": 139.722,
        "_internal_originalTime": 1689768525000
    },
    {
        "time": 1689768526000,
        "value": 139.721,
        "_internal_originalTime": 1689768526000
    },
    {
        "time": 1689768528000,
        "value": 139.711,
        "_internal_originalTime": 1689768528000
    },
    {
        "time": 1689768530000,
        "value": 139.711,
        "_internal_originalTime": 1689768530000
    },
    {
        "time": 1689768531000,
        "value": 139.702,
        "_internal_originalTime": 1689768531000
    },
    {
        "time": 1689768532000,
        "value": 139.7,
        "_internal_originalTime": 1689768532000
    },
    {
        "time": 1689768534000,
        "value": 139.703,
        "_internal_originalTime": 1689768534000
    },
    {
        "time": 1689768535000,
        "value": 139.703,
        "_internal_originalTime": 1689768535000
    },
    {
        "time": 1689768536000,
        "value": 139.701,
        "_internal_originalTime": 1689768536000
    },
    {
        "time": 1689768538000,
        "value": 139.708,
        "_internal_originalTime": 1689768538000
    },
    {
        "time": 1689768540000,
        "value": 139.7,
        "_internal_originalTime": 1689768540000
    },
    {
        "time": 1689768541000,
        "value": 139.69,
        "_internal_originalTime": 1689768541000
    },
    {
        "time": 1689768542000,
        "value": 139.693,
        "_internal_originalTime": 1689768542000
    },
    {
        "time": 1689768543000,
        "value": 139.688,
        "_internal_originalTime": 1689768543000
    },
    {
        "time": 1689768545000,
        "value": 139.689,
        "_internal_originalTime": 1689768545000
    },
    {
        "time": 1689768546000,
        "value": 139.699,
        "_internal_originalTime": 1689768546000
    },
    {
        "time": 1689768547000,
        "value": 139.702,
        "_internal_originalTime": 1689768547000
    },
    {
        "time": 1689768548000,
        "value": 139.694,
        "_internal_originalTime": 1689768548000
    },
    {
        "time": 1689768550000,
        "value": 139.698,
        "_internal_originalTime": 1689768550000
    },
    {
        "time": 1689768551000,
        "value": 139.696,
        "_internal_originalTime": 1689768551000
    },
    {
        "time": 1689768553000,
        "value": 139.702,
        "_internal_originalTime": 1689768553000
    },
    {
        "time": 1689768554000,
        "value": 139.702,
        "_internal_originalTime": 1689768554000
    },
    {
        "time": 1689768555000,
        "value": 139.708,
        "_internal_originalTime": 1689768555000
    },
    {
        "time": 1689768556000,
        "value": 139.704,
        "_internal_originalTime": 1689768556000
    },
    {
        "time": 1689768557000,
        "value": 139.709,
        "_internal_originalTime": 1689768557000
    },
    {
        "time": 1689768559000,
        "value": 139.701,
        "_internal_originalTime": 1689768559000
    },
    {
        "time": 1689768560000,
        "value": 139.698,
        "_internal_originalTime": 1689768560000
    },
    {
        "time": 1689768561000,
        "value": 139.701,
        "_internal_originalTime": 1689768561000
    },
    {
        "time": 1689768563000,
        "value": 139.702,
        "_internal_originalTime": 1689768563000
    },
    {
        "time": 1689768565000,
        "value": 139.701,
        "_internal_originalTime": 1689768565000
    },
    {
        "time": 1689768566000,
        "value": 139.702,
        "_internal_originalTime": 1689768566000
    },
    {
        "time": 1689768567000,
        "value": 139.701,
        "_internal_originalTime": 1689768567000
    },
    {
        "time": 1689768569000,
        "value": 139.705,
        "_internal_originalTime": 1689768569000
    },
    {
        "time": 1689768570000,
        "value": 139.701,
        "_internal_originalTime": 1689768570000
    },
    {
        "time": 1689768572000,
        "value": 139.707,
        "_internal_originalTime": 1689768572000
    },
    {
        "time": 1689768573000,
        "value": 139.708,
        "_internal_originalTime": 1689768573000
    },
    {
        "time": 1689768574000,
        "value": 139.707,
        "_internal_originalTime": 1689768574000
    },
    {
        "time": 1689768576000,
        "value": 139.702,
        "_internal_originalTime": 1689768576000
    },
    {
        "time": 1689768577000,
        "value": 139.708,
        "_internal_originalTime": 1689768577000
    },
    {
        "time": 1689768578000,
        "value": 139.706,
        "_internal_originalTime": 1689768578000
    },
    {
        "time": 1689768580000,
        "value": 139.699,
        "_internal_originalTime": 1689768580000
    },
    {
        "time": 1689768582000,
        "value": 139.697,
        "_internal_originalTime": 1689768582000
    },
    {
        "time": 1689768583000,
        "value": 139.696,
        "_internal_originalTime": 1689768583000
    },
    {
        "time": 1689768587000,
        "value": 139.693,
        "_internal_originalTime": 1689768587000
    },
    {
        "time": 1689768588000,
        "value": 139.691,
        "_internal_originalTime": 1689768588000
    },
    {
        "time": 1689768590000,
        "value": 139.688,
        "_internal_originalTime": 1689768590000
    },
    {
        "time": 1689768591000,
        "value": 139.691,
        "_internal_originalTime": 1689768591000
    },
    {
        "time": 1689768593000,
        "value": 139.692,
        "_internal_originalTime": 1689768593000
    },
    {
        "time": 1689768594000,
        "value": 139.693,
        "_internal_originalTime": 1689768594000
    },
    {
        "time": 1689768596000,
        "value": 139.688,
        "_internal_originalTime": 1689768596000
    },
    {
        "time": 1689768597000,
        "value": 139.691,
        "_internal_originalTime": 1689768597000
    },
    {
        "time": 1689768600000,
        "value": 139.693,
        "_internal_originalTime": 1689768600000
    },
    {
        "time": 1689768601000,
        "value": 139.66,
        "_internal_originalTime": 1689768601000
    },
    {
        "time": 1689768602000,
        "value": 139.69,
        "_internal_originalTime": 1689768602000
    },
    {
        "time": 1689768603000,
        "value": 139.691,
        "_internal_originalTime": 1689768603000
    },
    {
        "time": 1689768604000,
        "value": 139.691,
        "_internal_originalTime": 1689768604000
    },
    {
        "time": 1689768607000,
        "value": 139.69,
        "_internal_originalTime": 1689768607000
    },
    {
        "time": 1689768608000,
        "value": 139.688,
        "_internal_originalTime": 1689768608000
    },
    {
        "time": 1689768610000,
        "value": 139.688,
        "_internal_originalTime": 1689768610000
    },
    {
        "time": 1689768612000,
        "value": 139.684,
        "_internal_originalTime": 1689768612000
    },
    {
        "time": 1689768613000,
        "value": 139.688,
        "_internal_originalTime": 1689768613000
    },
    {
        "time": 1689768614000,
        "value": 139.686,
        "_internal_originalTime": 1689768614000
    },
    {
        "time": 1689768615000,
        "value": 139.682,
        "_internal_originalTime": 1689768615000
    },
    {
        "time": 1689768616000,
        "value": 139.684,
        "_internal_originalTime": 1689768616000
    },
    {
        "time": 1689768617000,
        "value": 139.687,
        "_internal_originalTime": 1689768617000
    },
    {
        "time": 1689768618000,
        "value": 139.691,
        "_internal_originalTime": 1689768618000
    },
    {
        "time": 1689768619000,
        "value": 139.69,
        "_internal_originalTime": 1689768619000
    },
    {
        "time": 1689768621000,
        "value": 139.69,
        "_internal_originalTime": 1689768621000
    },
    {
        "time": 1689768622000,
        "value": 139.692,
        "_internal_originalTime": 1689768622000
    },
    {
        "time": 1689768625000,
        "value": 139.693,
        "_internal_originalTime": 1689768625000
    },
    {
        "time": 1689768626000,
        "value": 139.69,
        "_internal_originalTime": 1689768626000
    },
    {
        "time": 1689768628000,
        "value": 139.692,
        "_internal_originalTime": 1689768628000
    },
    {
        "time": 1689768629000,
        "value": 139.692,
        "_internal_originalTime": 1689768629000
    },
    {
        "time": 1689768630000,
        "value": 139.692,
        "_internal_originalTime": 1689768630000
    },
    {
        "time": 1689768632000,
        "value": 139.691,
        "_internal_originalTime": 1689768632000
    },
    {
        "time": 1689768634000,
        "value": 139.691,
        "_internal_originalTime": 1689768634000
    },
    {
        "time": 1689768635000,
        "value": 139.69,
        "_internal_originalTime": 1689768635000
    },
    {
        "time": 1689768637000,
        "value": 139.692,
        "_internal_originalTime": 1689768637000
    },
    {
        "time": 1689768638000,
        "value": 139.692,
        "_internal_originalTime": 1689768638000
    },
    {
        "time": 1689768640000,
        "value": 139.693,
        "_internal_originalTime": 1689768640000
    },
    {
        "time": 1689768641000,
        "value": 139.699,
        "_internal_originalTime": 1689768641000
    },
    {
        "time": 1689768643000,
        "value": 139.699,
        "_internal_originalTime": 1689768643000
    },
    {
        "time": 1689768644000,
        "value": 139.693,
        "_internal_originalTime": 1689768644000
    },
    {
        "time": 1689768646000,
        "value": 139.687,
        "_internal_originalTime": 1689768646000
    },
    {
        "time": 1689768647000,
        "value": 139.685,
        "_internal_originalTime": 1689768647000
    },
    {
        "time": 1689768649000,
        "value": 139.683,
        "_internal_originalTime": 1689768649000
    },
    {
        "time": 1689768650000,
        "value": 139.682,
        "_internal_originalTime": 1689768650000
    },
    {
        "time": 1689768652000,
        "value": 139.681,
        "_internal_originalTime": 1689768652000
    },
    {
        "time": 1689768653000,
        "value": 139.683,
        "_internal_originalTime": 1689768653000
    },
    {
        "time": 1689768655000,
        "value": 139.672,
        "_internal_originalTime": 1689768655000
    },
    {
        "time": 1689768656000,
        "value": 139.674,
        "_internal_originalTime": 1689768656000
    },
    {
        "time": 1689768658000,
        "value": 139.673,
        "_internal_originalTime": 1689768658000
    },
    {
        "time": 1689768660000,
        "value": 139.67,
        "_internal_originalTime": 1689768660000
    },
    {
        "time": 1689768661000,
        "value": 139.674,
        "_internal_originalTime": 1689768661000
    },
    {
        "time": 1689768662000,
        "value": 139.674,
        "_internal_originalTime": 1689768662000
    },
    {
        "time": 1689768664000,
        "value": 139.674,
        "_internal_originalTime": 1689768664000
    },
    {
        "time": 1689768665000,
        "value": 139.675,
        "_internal_originalTime": 1689768665000
    },
    {
        "time": 1689768666000,
        "value": 139.684,
        "_internal_originalTime": 1689768666000
    },
    {
        "time": 1689768667000,
        "value": 139.685,
        "_internal_originalTime": 1689768667000
    },
    {
        "time": 1689768668000,
        "value": 139.68,
        "_internal_originalTime": 1689768668000
    },
    {
        "time": 1689768670000,
        "value": 139.684,
        "_internal_originalTime": 1689768670000
    },
    {
        "time": 1689768672000,
        "value": 139.669,
        "_internal_originalTime": 1689768672000
    },
    {
        "time": 1689768674000,
        "value": 139.676,
        "_internal_originalTime": 1689768674000
    },
    {
        "time": 1689768675000,
        "value": 139.672,
        "_internal_originalTime": 1689768675000
    },
    {
        "time": 1689768677000,
        "value": 139.676,
        "_internal_originalTime": 1689768677000
    },
    {
        "time": 1689768678000,
        "value": 139.675,
        "_internal_originalTime": 1689768678000
    },
    {
        "time": 1689768680000,
        "value": 139.677,
        "_internal_originalTime": 1689768680000
    },
    {
        "time": 1689768681000,
        "value": 139.673,
        "_internal_originalTime": 1689768681000
    },
    {
        "time": 1689768683000,
        "value": 139.672,
        "_internal_originalTime": 1689768683000
    },
    {
        "time": 1689768685000,
        "value": 139.657,
        "_internal_originalTime": 1689768685000
    },
    {
        "time": 1689768686000,
        "value": 139.654,
        "_internal_originalTime": 1689768686000
    },
    {
        "time": 1689768687000,
        "value": 139.656,
        "_internal_originalTime": 1689768687000
    },
    {
        "time": 1689768690000,
        "value": 139.656,
        "_internal_originalTime": 1689768690000
    },
    {
        "time": 1689768691000,
        "value": 139.657,
        "_internal_originalTime": 1689768691000
    },
    {
        "time": 1689768693000,
        "value": 139.657,
        "_internal_originalTime": 1689768693000
    },
    {
        "time": 1689768694000,
        "value": 139.652,
        "_internal_originalTime": 1689768694000
    },
    {
        "time": 1689768696000,
        "value": 139.653,
        "_internal_originalTime": 1689768696000
    },
    {
        "time": 1689768697000,
        "value": 139.656,
        "_internal_originalTime": 1689768697000
    },
    {
        "time": 1689768698000,
        "value": 139.648,
        "_internal_originalTime": 1689768698000
    },
    {
        "time": 1689768700000,
        "value": 139.655,
        "_internal_originalTime": 1689768700000
    },
    {
        "time": 1689768701000,
        "value": 139.656,
        "_internal_originalTime": 1689768701000
    },
    {
        "time": 1689768703000,
        "value": 139.655,
        "_internal_originalTime": 1689768703000
    },
    {
        "time": 1689768704000,
        "value": 139.654,
        "_internal_originalTime": 1689768704000
    },
    {
        "time": 1689768705000,
        "value": 139.655,
        "_internal_originalTime": 1689768705000
    },
    {
        "time": 1689768707000,
        "value": 139.657,
        "_internal_originalTime": 1689768707000
    },
    {
        "time": 1689768709000,
        "value": 139.652,
        "_internal_originalTime": 1689768709000
    },
    {
        "time": 1689768713000,
        "value": 139.654,
        "_internal_originalTime": 1689768713000
    },
    {
        "time": 1689768714000,
        "value": 139.642,
        "_internal_originalTime": 1689768714000
    },
    {
        "time": 1689768716000,
        "value": 139.642,
        "_internal_originalTime": 1689768716000
    },
    {
        "time": 1689768718000,
        "value": 139.646,
        "_internal_originalTime": 1689768718000
    },
    {
        "time": 1689768720000,
        "value": 139.644,
        "_internal_originalTime": 1689768720000
    },
    {
        "time": 1689768721000,
        "value": 139.644,
        "_internal_originalTime": 1689768721000
    },
    {
        "time": 1689768722000,
        "value": 139.644,
        "_internal_originalTime": 1689768722000
    },
    {
        "time": 1689768723000,
        "value": 139.646,
        "_internal_originalTime": 1689768723000
    },
    {
        "time": 1689768725000,
        "value": 139.644,
        "_internal_originalTime": 1689768725000
    },
    {
        "time": 1689768726000,
        "value": 139.644,
        "_internal_originalTime": 1689768726000
    },
    {
        "time": 1689768728000,
        "value": 139.647,
        "_internal_originalTime": 1689768728000
    },
    {
        "time": 1689768729000,
        "value": 139.647,
        "_internal_originalTime": 1689768729000
    },
    {
        "time": 1689768730000,
        "value": 139.647,
        "_internal_originalTime": 1689768730000
    },
    {
        "time": 1689768731000,
        "value": 139.645,
        "_internal_originalTime": 1689768731000
    },
    {
        "time": 1689768733000,
        "value": 139.647,
        "_internal_originalTime": 1689768733000
    },
    {
        "time": 1689768735000,
        "value": 139.65,
        "_internal_originalTime": 1689768735000
    },
    {
        "time": 1689768736000,
        "value": 139.655,
        "_internal_originalTime": 1689768736000
    },
    {
        "time": 1689768738000,
        "value": 139.651,
        "_internal_originalTime": 1689768738000
    },
    {
        "time": 1689768740000,
        "value": 139.657,
        "_internal_originalTime": 1689768740000
    },
    {
        "time": 1689768741000,
        "value": 139.655,
        "_internal_originalTime": 1689768741000
    },
    {
        "time": 1689768743000,
        "value": 139.659,
        "_internal_originalTime": 1689768743000
    },
    {
        "time": 1689768744000,
        "value": 139.664,
        "_internal_originalTime": 1689768744000
    },
    {
        "time": 1689768745000,
        "value": 139.664,
        "_internal_originalTime": 1689768745000
    },
    {
        "time": 1689768747000,
        "value": 139.668,
        "_internal_originalTime": 1689768747000
    },
    {
        "time": 1689768749000,
        "value": 139.673,
        "_internal_originalTime": 1689768749000
    },
    {
        "time": 1689768750000,
        "value": 139.672,
        "_internal_originalTime": 1689768750000
    },
    {
        "time": 1689768751000,
        "value": 139.676,
        "_internal_originalTime": 1689768751000
    },
    {
        "time": 1689768753000,
        "value": 139.676,
        "_internal_originalTime": 1689768753000
    },
    {
        "time": 1689768755000,
        "value": 139.681,
        "_internal_originalTime": 1689768755000
    },
    {
        "time": 1689768756000,
        "value": 139.685,
        "_internal_originalTime": 1689768756000
    },
    {
        "time": 1689768758000,
        "value": 139.683,
        "_internal_originalTime": 1689768758000
    },
    {
        "time": 1689768759000,
        "value": 139.682,
        "_internal_originalTime": 1689768759000
    },
    {
        "time": 1689768760000,
        "value": 139.685,
        "_internal_originalTime": 1689768760000
    },
    {
        "time": 1689768762000,
        "value": 139.68,
        "_internal_originalTime": 1689768762000
    },
    {
        "time": 1689768763000,
        "value": 139.68,
        "_internal_originalTime": 1689768763000
    },
    {
        "time": 1689768765000,
        "value": 139.686,
        "_internal_originalTime": 1689768765000
    },
    {
        "time": 1689768767000,
        "value": 139.681,
        "_internal_originalTime": 1689768767000
    },
    {
        "time": 1689768768000,
        "value": 139.68,
        "_internal_originalTime": 1689768768000
    },
    {
        "time": 1689768771000,
        "value": 139.683,
        "_internal_originalTime": 1689768771000
    },
    {
        "time": 1689768772000,
        "value": 139.688,
        "_internal_originalTime": 1689768772000
    },
    {
        "time": 1689768775000,
        "value": 139.69,
        "_internal_originalTime": 1689768775000
    },
    {
        "time": 1689768776000,
        "value": 139.694,
        "_internal_originalTime": 1689768776000
    },
    {
        "time": 1689768778000,
        "value": 139.696,
        "_internal_originalTime": 1689768778000
    },
    {
        "time": 1689768781000,
        "value": 139.692,
        "_internal_originalTime": 1689768781000
    },
    {
        "time": 1689768782000,
        "value": 139.694,
        "_internal_originalTime": 1689768782000
    },
    {
        "time": 1689768783000,
        "value": 139.687,
        "_internal_originalTime": 1689768783000
    },
    {
        "time": 1689768784000,
        "value": 139.688,
        "_internal_originalTime": 1689768784000
    },
    {
        "time": 1689768785000,
        "value": 139.69,
        "_internal_originalTime": 1689768785000
    },
    {
        "time": 1689768787000,
        "value": 139.689,
        "_internal_originalTime": 1689768787000
    },
    {
        "time": 1689768789000,
        "value": 139.682,
        "_internal_originalTime": 1689768789000
    },
    {
        "time": 1689768790000,
        "value": 139.682,
        "_internal_originalTime": 1689768790000
    },
    {
        "time": 1689768791000,
        "value": 139.682,
        "_internal_originalTime": 1689768791000
    },
    {
        "time": 1689768793000,
        "value": 139.684,
        "_internal_originalTime": 1689768793000
    },
    {
        "time": 1689768795000,
        "value": 139.687,
        "_internal_originalTime": 1689768795000
    },
    {
        "time": 1689768796000,
        "value": 139.685,
        "_internal_originalTime": 1689768796000
    },
    {
        "time": 1689768798000,
        "value": 139.684,
        "_internal_originalTime": 1689768798000
    },
    {
        "time": 1689768799000,
        "value": 139.68,
        "_internal_originalTime": 1689768799000
    },
    {
        "time": 1689768800000,
        "value": 139.688,
        "_internal_originalTime": 1689768800000
    },
    {
        "time": 1689768802000,
        "value": 139.684,
        "_internal_originalTime": 1689768802000
    },
    {
        "time": 1689768803000,
        "value": 139.685,
        "_internal_originalTime": 1689768803000
    },
    {
        "time": 1689768806000,
        "value": 139.687,
        "_internal_originalTime": 1689768806000
    },
    {
        "time": 1689768807000,
        "value": 139.682,
        "_internal_originalTime": 1689768807000
    },
    {
        "time": 1689768808000,
        "value": 139.68,
        "_internal_originalTime": 1689768808000
    },
    {
        "time": 1689768810000,
        "value": 139.678,
        "_internal_originalTime": 1689768810000
    },
    {
        "time": 1689768811000,
        "value": 139.676,
        "_internal_originalTime": 1689768811000
    },
    {
        "time": 1689768813000,
        "value": 139.682,
        "_internal_originalTime": 1689768813000
    },
    {
        "time": 1689768814000,
        "value": 139.684,
        "_internal_originalTime": 1689768814000
    },
    {
        "time": 1689768816000,
        "value": 139.681,
        "_internal_originalTime": 1689768816000
    },
    {
        "time": 1689768817000,
        "value": 139.69,
        "_internal_originalTime": 1689768817000
    },
    {
        "time": 1689768818000,
        "value": 139.693,
        "_internal_originalTime": 1689768818000
    },
    {
        "time": 1689768820000,
        "value": 139.697,
        "_internal_originalTime": 1689768820000
    },
    {
        "time": 1689768821000,
        "value": 139.702,
        "_internal_originalTime": 1689768821000
    },
    {
        "time": 1689768822000,
        "value": 139.694,
        "_internal_originalTime": 1689768822000
    },
    {
        "time": 1689768824000,
        "value": 139.692,
        "_internal_originalTime": 1689768824000
    },
    {
        "time": 1689768825000,
        "value": 139.696,
        "_internal_originalTime": 1689768825000
    },
    {
        "time": 1689768826000,
        "value": 139.695,
        "_internal_originalTime": 1689768826000
    },
    {
        "time": 1689768827000,
        "value": 139.694,
        "_internal_originalTime": 1689768827000
    },
    {
        "time": 1689768828000,
        "value": 139.693,
        "_internal_originalTime": 1689768828000
    },
    {
        "time": 1689768830000,
        "value": 139.696,
        "_internal_originalTime": 1689768830000
    },
    {
        "time": 1689768831000,
        "value": 139.699,
        "_internal_originalTime": 1689768831000
    },
    {
        "time": 1689768833000,
        "value": 139.697,
        "_internal_originalTime": 1689768833000
    },
    {
        "time": 1689768834000,
        "value": 139.696,
        "_internal_originalTime": 1689768834000
    },
    {
        "time": 1689768836000,
        "value": 139.69,
        "_internal_originalTime": 1689768836000
    },
    {
        "time": 1689768837000,
        "value": 139.694,
        "_internal_originalTime": 1689768837000
    },
    {
        "time": 1689768838000,
        "value": 139.69,
        "_internal_originalTime": 1689768838000
    },
    {
        "time": 1689768840000,
        "value": 139.694,
        "_internal_originalTime": 1689768840000
    },
    {
        "time": 1689768842000,
        "value": 139.699,
        "_internal_originalTime": 1689768842000
    },
    {
        "time": 1689768843000,
        "value": 139.699,
        "_internal_originalTime": 1689768843000
    },
    {
        "time": 1689768845000,
        "value": 139.698,
        "_internal_originalTime": 1689768845000
    },
    {
        "time": 1689768847000,
        "value": 139.682,
        "_internal_originalTime": 1689768847000
    },
    {
        "time": 1689768848000,
        "value": 139.683,
        "_internal_originalTime": 1689768848000
    },
    {
        "time": 1689768849000,
        "value": 139.683,
        "_internal_originalTime": 1689768849000
    },
    {
        "time": 1689768850000,
        "value": 139.68,
        "_internal_originalTime": 1689768850000
    },
    {
        "time": 1689768852000,
        "value": 139.684,
        "_internal_originalTime": 1689768852000
    },
    {
        "time": 1689768854000,
        "value": 139.687,
        "_internal_originalTime": 1689768854000
    },
    {
        "time": 1689768855000,
        "value": 139.691,
        "_internal_originalTime": 1689768855000
    },
    {
        "time": 1689768856000,
        "value": 139.688,
        "_internal_originalTime": 1689768856000
    },
    {
        "time": 1689768858000,
        "value": 139.684,
        "_internal_originalTime": 1689768858000
    },
    {
        "time": 1689768860000,
        "value": 139.684,
        "_internal_originalTime": 1689768860000
    },
    {
        "time": 1689768861000,
        "value": 139.68,
        "_internal_originalTime": 1689768861000
    },
    {
        "time": 1689768862000,
        "value": 139.687,
        "_internal_originalTime": 1689768862000
    },
    {
        "time": 1689768863000,
        "value": 139.686,
        "_internal_originalTime": 1689768863000
    },
    {
        "time": 1689768864000,
        "value": 139.685,
        "_internal_originalTime": 1689768864000
    },
    {
        "time": 1689768866000,
        "value": 139.684,
        "_internal_originalTime": 1689768866000
    },
    {
        "time": 1689768867000,
        "value": 139.683,
        "_internal_originalTime": 1689768867000
    },
    {
        "time": 1689768869000,
        "value": 139.685,
        "_internal_originalTime": 1689768869000
    },
    {
        "time": 1689768870000,
        "value": 139.686,
        "_internal_originalTime": 1689768870000
    },
    {
        "time": 1689768871000,
        "value": 139.684,
        "_internal_originalTime": 1689768871000
    },
    {
        "time": 1689768872000,
        "value": 139.686,
        "_internal_originalTime": 1689768872000
    },
    {
        "time": 1689768874000,
        "value": 139.689,
        "_internal_originalTime": 1689768874000
    },
    {
        "time": 1689768875000,
        "value": 139.686,
        "_internal_originalTime": 1689768875000
    },
    {
        "time": 1689768876000,
        "value": 139.685,
        "_internal_originalTime": 1689768876000
    },
    {
        "time": 1689768878000,
        "value": 139.688,
        "_internal_originalTime": 1689768878000
    },
    {
        "time": 1689768879000,
        "value": 139.683,
        "_internal_originalTime": 1689768879000
    },
    {
        "time": 1689768881000,
        "value": 139.688,
        "_internal_originalTime": 1689768881000
    },
    {
        "time": 1689768882000,
        "value": 139.687,
        "_internal_originalTime": 1689768882000
    },
    {
        "time": 1689768883000,
        "value": 139.685,
        "_internal_originalTime": 1689768883000
    },
    {
        "time": 1689768885000,
        "value": 139.687,
        "_internal_originalTime": 1689768885000
    },
    {
        "time": 1689768886000,
        "value": 139.684,
        "_internal_originalTime": 1689768886000
    },
    {
        "time": 1689768887000,
        "value": 139.688,
        "_internal_originalTime": 1689768887000
    },
    {
        "time": 1689768892000,
        "value": 139.689,
        "_internal_originalTime": 1689768892000
    },
    {
        "time": 1689768894000,
        "value": 139.683,
        "_internal_originalTime": 1689768894000
    },
    {
        "time": 1689768895000,
        "value": 139.685,
        "_internal_originalTime": 1689768895000
    },
    {
        "time": 1689768896000,
        "value": 139.687,
        "_internal_originalTime": 1689768896000
    },
    {
        "time": 1689768898000,
        "value": 139.683,
        "_internal_originalTime": 1689768898000
    },
    {
        "time": 1689768899000,
        "value": 139.677,
        "_internal_originalTime": 1689768899000
    },
    {
        "time": 1689768900000,
        "value": 139.674,
        "_internal_originalTime": 1689768900000
    },
    {
        "time": 1689768901000,
        "value": 139.674,
        "_internal_originalTime": 1689768901000
    },
    {
        "time": 1689768903000,
        "value": 139.673,
        "_internal_originalTime": 1689768903000
    },
    {
        "time": 1689768904000,
        "value": 139.672,
        "_internal_originalTime": 1689768904000
    },
    {
        "time": 1689768906000,
        "value": 139.672,
        "_internal_originalTime": 1689768906000
    },
    {
        "time": 1689768908000,
        "value": 139.669,
        "_internal_originalTime": 1689768908000
    },
    {
        "time": 1689768909000,
        "value": 139.67,
        "_internal_originalTime": 1689768909000
    },
    {
        "time": 1689768910000,
        "value": 139.677,
        "_internal_originalTime": 1689768910000
    },
    {
        "time": 1689768912000,
        "value": 139.675,
        "_internal_originalTime": 1689768912000
    },
    {
        "time": 1689768914000,
        "value": 139.676,
        "_internal_originalTime": 1689768914000
    },
    {
        "time": 1689768915000,
        "value": 139.675,
        "_internal_originalTime": 1689768915000
    },
    {
        "time": 1689768917000,
        "value": 139.673,
        "_internal_originalTime": 1689768917000
    },
    {
        "time": 1689768918000,
        "value": 139.672,
        "_internal_originalTime": 1689768918000
    },
    {
        "time": 1689768919000,
        "value": 139.672,
        "_internal_originalTime": 1689768919000
    },
    {
        "time": 1689768921000,
        "value": 139.676,
        "_internal_originalTime": 1689768921000
    },
    {
        "time": 1689768925000,
        "value": 139.672,
        "_internal_originalTime": 1689768925000
    },
    {
        "time": 1689768926000,
        "value": 139.672,
        "_internal_originalTime": 1689768926000
    },
    {
        "time": 1689768929000,
        "value": 139.675,
        "_internal_originalTime": 1689768929000
    },
    {
        "time": 1689768930000,
        "value": 139.674,
        "_internal_originalTime": 1689768930000
    },
    {
        "time": 1689768931000,
        "value": 139.675,
        "_internal_originalTime": 1689768931000
    },
    {
        "time": 1689768932000,
        "value": 139.674,
        "_internal_originalTime": 1689768932000
    },
    {
        "time": 1689768934000,
        "value": 139.682,
        "_internal_originalTime": 1689768934000
    },
    {
        "time": 1689768935000,
        "value": 139.692,
        "_internal_originalTime": 1689768935000
    },
    {
        "time": 1689768936000,
        "value": 139.692,
        "_internal_originalTime": 1689768936000
    },
    {
        "time": 1689768937000,
        "value": 139.687,
        "_internal_originalTime": 1689768937000
    },
    {
        "time": 1689768939000,
        "value": 139.686,
        "_internal_originalTime": 1689768939000
    },
    {
        "time": 1689768940000,
        "value": 139.694,
        "_internal_originalTime": 1689768940000
    },
    {
        "time": 1689768942000,
        "value": 139.697,
        "_internal_originalTime": 1689768942000
    },
    {
        "time": 1689768943000,
        "value": 139.691,
        "_internal_originalTime": 1689768943000
    },
    {
        "time": 1689768944000,
        "value": 139.695,
        "_internal_originalTime": 1689768944000
    },
    {
        "time": 1689768945000,
        "value": 139.695,
        "_internal_originalTime": 1689768945000
    },
    {
        "time": 1689768946000,
        "value": 139.695,
        "_internal_originalTime": 1689768946000
    },
    {
        "time": 1689768949000,
        "value": 139.704,
        "_internal_originalTime": 1689768949000
    },
    {
        "time": 1689768950000,
        "value": 139.706,
        "_internal_originalTime": 1689768950000
    },
    {
        "time": 1689768952000,
        "value": 139.714,
        "_internal_originalTime": 1689768952000
    },
    {
        "time": 1689768953000,
        "value": 139.716,
        "_internal_originalTime": 1689768953000
    },
    {
        "time": 1689768954000,
        "value": 139.714,
        "_internal_originalTime": 1689768954000
    },
    {
        "time": 1689768956000,
        "value": 139.71,
        "_internal_originalTime": 1689768956000
    },
    {
        "time": 1689768957000,
        "value": 139.714,
        "_internal_originalTime": 1689768957000
    },
    {
        "time": 1689768960000,
        "value": 139.705,
        "_internal_originalTime": 1689768960000
    },
    {
        "time": 1689768962000,
        "value": 139.692,
        "_internal_originalTime": 1689768962000
    },
    {
        "time": 1689768964000,
        "value": 139.699,
        "_internal_originalTime": 1689768964000
    },
    {
        "time": 1689768965000,
        "value": 139.702,
        "_internal_originalTime": 1689768965000
    },
    {
        "time": 1689768966000,
        "value": 139.698,
        "_internal_originalTime": 1689768966000
    },
    {
        "time": 1689768967000,
        "value": 139.697,
        "_internal_originalTime": 1689768967000
    },
    {
        "time": 1689768968000,
        "value": 139.697,
        "_internal_originalTime": 1689768968000
    },
    {
        "time": 1689768969000,
        "value": 139.702,
        "_internal_originalTime": 1689768969000
    },
    {
        "time": 1689768971000,
        "value": 139.701,
        "_internal_originalTime": 1689768971000
    },
    {
        "time": 1689768972000,
        "value": 139.704,
        "_internal_originalTime": 1689768972000
    },
    {
        "time": 1689768975000,
        "value": 139.703,
        "_internal_originalTime": 1689768975000
    },
    {
        "time": 1689768976000,
        "value": 139.703,
        "_internal_originalTime": 1689768976000
    },
    {
        "time": 1689768978000,
        "value": 139.703,
        "_internal_originalTime": 1689768978000
    },
    {
        "time": 1689768979000,
        "value": 139.707,
        "_internal_originalTime": 1689768979000
    },
    {
        "time": 1689768980000,
        "value": 139.706,
        "_internal_originalTime": 1689768980000
    },
    {
        "time": 1689768981000,
        "value": 139.697,
        "_internal_originalTime": 1689768981000
    },
    {
        "time": 1689768983000,
        "value": 139.692,
        "_internal_originalTime": 1689768983000
    },
    {
        "time": 1689768984000,
        "value": 139.694,
        "_internal_originalTime": 1689768984000
    },
    {
        "time": 1689768986000,
        "value": 139.698,
        "_internal_originalTime": 1689768986000
    },
    {
        "time": 1689768987000,
        "value": 139.693,
        "_internal_originalTime": 1689768987000
    },
    {
        "time": 1689768989000,
        "value": 139.695,
        "_internal_originalTime": 1689768989000
    },
    {
        "time": 1689768990000,
        "value": 139.692,
        "_internal_originalTime": 1689768990000
    },
    {
        "time": 1689768992000,
        "value": 139.696,
        "_internal_originalTime": 1689768992000
    },
    {
        "time": 1689768993000,
        "value": 139.692,
        "_internal_originalTime": 1689768993000
    },
    {
        "time": 1689768994000,
        "value": 139.695,
        "_internal_originalTime": 1689768994000
    },
    {
        "time": 1689768995000,
        "value": 139.698,
        "_internal_originalTime": 1689768995000
    },
    {
        "time": 1689768996000,
        "value": 139.692,
        "_internal_originalTime": 1689768996000
    },
    {
        "time": 1689768998000,
        "value": 139.693,
        "_internal_originalTime": 1689768998000
    },
    {
        "time": 1689768999000,
        "value": 139.69,
        "_internal_originalTime": 1689768999000
    },
    {
        "time": 1689769000000,
        "value": 139.695,
        "_internal_originalTime": 1689769000000
    },
    {
        "time": 1689769002000,
        "value": 139.695,
        "_internal_originalTime": 1689769002000
    },
    {
        "time": 1689769003000,
        "value": 139.694,
        "_internal_originalTime": 1689769003000
    },
    {
        "time": 1689769004000,
        "value": 139.682,
        "_internal_originalTime": 1689769004000
    },
    {
        "time": 1689769005000,
        "value": 139.686,
        "_internal_originalTime": 1689769005000
    },
    {
        "time": 1689769007000,
        "value": 139.684,
        "_internal_originalTime": 1689769007000
    },
    {
        "time": 1689769008000,
        "value": 139.684,
        "_internal_originalTime": 1689769008000
    },
    {
        "time": 1689769009000,
        "value": 139.684,
        "_internal_originalTime": 1689769009000
    },
    {
        "time": 1689769011000,
        "value": 139.682,
        "_internal_originalTime": 1689769011000
    },
    {
        "time": 1689769012000,
        "value": 139.685,
        "_internal_originalTime": 1689769012000
    },
    {
        "time": 1689769013000,
        "value": 139.685,
        "_internal_originalTime": 1689769013000
    },
    {
        "time": 1689769015000,
        "value": 139.685,
        "_internal_originalTime": 1689769015000
    },
    {
        "time": 1689769016000,
        "value": 139.685,
        "_internal_originalTime": 1689769016000
    },
    {
        "time": 1689769018000,
        "value": 139.688,
        "_internal_originalTime": 1689769018000
    },
    {
        "time": 1689769019000,
        "value": 139.675,
        "_internal_originalTime": 1689769019000
    },
    {
        "time": 1689769020000,
        "value": 139.674,
        "_internal_originalTime": 1689769020000
    },
    {
        "time": 1689769021000,
        "value": 139.66,
        "_internal_originalTime": 1689769021000
    },
    {
        "time": 1689769022000,
        "value": 139.678,
        "_internal_originalTime": 1689769022000
    },
    {
        "time": 1689769024000,
        "value": 139.678,
        "_internal_originalTime": 1689769024000
    },
    {
        "time": 1689769025000,
        "value": 139.677,
        "_internal_originalTime": 1689769025000
    },
    {
        "time": 1689769026000,
        "value": 139.678,
        "_internal_originalTime": 1689769026000
    },
    {
        "time": 1689769028000,
        "value": 139.677,
        "_internal_originalTime": 1689769028000
    },
    {
        "time": 1689769029000,
        "value": 139.684,
        "_internal_originalTime": 1689769029000
    },
    {
        "time": 1689769030000,
        "value": 139.682,
        "_internal_originalTime": 1689769030000
    },
    {
        "time": 1689769032000,
        "value": 139.683,
        "_internal_originalTime": 1689769032000
    },
    {
        "time": 1689769034000,
        "value": 139.695,
        "_internal_originalTime": 1689769034000
    },
    {
        "time": 1689769035000,
        "value": 139.695,
        "_internal_originalTime": 1689769035000
    },
    {
        "time": 1689769036000,
        "value": 139.694,
        "_internal_originalTime": 1689769036000
    },
    {
        "time": 1689769037000,
        "value": 139.694,
        "_internal_originalTime": 1689769037000
    },
    {
        "time": 1689769038000,
        "value": 139.695,
        "_internal_originalTime": 1689769038000
    },
    {
        "time": 1689769040000,
        "value": 139.701,
        "_internal_originalTime": 1689769040000
    },
    {
        "time": 1689769041000,
        "value": 139.697,
        "_internal_originalTime": 1689769041000
    },
    {
        "time": 1689769042000,
        "value": 139.702,
        "_internal_originalTime": 1689769042000
    },
    {
        "time": 1689769044000,
        "value": 139.705,
        "_internal_originalTime": 1689769044000
    },
    {
        "time": 1689769045000,
        "value": 139.702,
        "_internal_originalTime": 1689769045000
    },
    {
        "time": 1689769047000,
        "value": 139.705,
        "_internal_originalTime": 1689769047000
    },
    {
        "time": 1689769049000,
        "value": 139.703,
        "_internal_originalTime": 1689769049000
    },
    {
        "time": 1689769051000,
        "value": 139.703,
        "_internal_originalTime": 1689769051000
    },
    {
        "time": 1689769052000,
        "value": 139.701,
        "_internal_originalTime": 1689769052000
    },
    {
        "time": 1689769054000,
        "value": 139.702,
        "_internal_originalTime": 1689769054000
    },
    {
        "time": 1689769055000,
        "value": 139.7,
        "_internal_originalTime": 1689769055000
    },
    {
        "time": 1689769057000,
        "value": 139.699,
        "_internal_originalTime": 1689769057000
    },
    {
        "time": 1689769058000,
        "value": 139.692,
        "_internal_originalTime": 1689769058000
    },
    {
        "time": 1689769059000,
        "value": 139.694,
        "_internal_originalTime": 1689769059000
    },
    {
        "time": 1689769060000,
        "value": 139.698,
        "_internal_originalTime": 1689769060000
    },
    {
        "time": 1689769062000,
        "value": 139.679,
        "_internal_originalTime": 1689769062000
    },
    {
        "time": 1689769063000,
        "value": 139.672,
        "_internal_originalTime": 1689769063000
    },
    {
        "time": 1689769064000,
        "value": 139.673,
        "_internal_originalTime": 1689769064000
    },
    {
        "time": 1689769066000,
        "value": 139.673,
        "_internal_originalTime": 1689769066000
    },
    {
        "time": 1689769067000,
        "value": 139.677,
        "_internal_originalTime": 1689769067000
    },
    {
        "time": 1689769069000,
        "value": 139.678,
        "_internal_originalTime": 1689769069000
    },
    {
        "time": 1689769071000,
        "value": 139.675,
        "_internal_originalTime": 1689769071000
    },
    {
        "time": 1689769072000,
        "value": 139.677,
        "_internal_originalTime": 1689769072000
    },
    {
        "time": 1689769073000,
        "value": 139.676,
        "_internal_originalTime": 1689769073000
    },
    {
        "time": 1689769075000,
        "value": 139.678,
        "_internal_originalTime": 1689769075000
    },
    {
        "time": 1689769076000,
        "value": 139.676,
        "_internal_originalTime": 1689769076000
    },
    {
        "time": 1689769078000,
        "value": 139.676,
        "_internal_originalTime": 1689769078000
    },
    {
        "time": 1689769079000,
        "value": 139.683,
        "_internal_originalTime": 1689769079000
    },
    {
        "time": 1689769081000,
        "value": 139.683,
        "_internal_originalTime": 1689769081000
    },
    {
        "time": 1689769083000,
        "value": 139.683,
        "_internal_originalTime": 1689769083000
    },
    {
        "time": 1689769084000,
        "value": 139.685,
        "_internal_originalTime": 1689769084000
    },
    {
        "time": 1689769085000,
        "value": 139.68,
        "_internal_originalTime": 1689769085000
    },
    {
        "time": 1689769087000,
        "value": 139.678,
        "_internal_originalTime": 1689769087000
    },
    {
        "time": 1689769089000,
        "value": 139.67,
        "_internal_originalTime": 1689769089000
    },
    {
        "time": 1689769091000,
        "value": 139.684,
        "_internal_originalTime": 1689769091000
    },
    {
        "time": 1689769092000,
        "value": 139.683,
        "_internal_originalTime": 1689769092000
    },
    {
        "time": 1689769095000,
        "value": 139.683,
        "_internal_originalTime": 1689769095000
    },
    {
        "time": 1689769097000,
        "value": 139.683,
        "_internal_originalTime": 1689769097000
    },
    {
        "time": 1689769098000,
        "value": 139.684,
        "_internal_originalTime": 1689769098000
    },
    {
        "time": 1689769099000,
        "value": 139.685,
        "_internal_originalTime": 1689769099000
    },
    {
        "time": 1689769101000,
        "value": 139.684,
        "_internal_originalTime": 1689769101000
    },
    {
        "time": 1689769103000,
        "value": 139.682,
        "_internal_originalTime": 1689769103000
    },
    {
        "time": 1689769105000,
        "value": 139.682,
        "_internal_originalTime": 1689769105000
    },
    {
        "time": 1689769106000,
        "value": 139.682,
        "_internal_originalTime": 1689769106000
    },
    {
        "time": 1689769110000,
        "value": 139.683,
        "_internal_originalTime": 1689769110000
    },
    {
        "time": 1689769111000,
        "value": 139.684,
        "_internal_originalTime": 1689769111000
    },
    {
        "time": 1689769113000,
        "value": 139.685,
        "_internal_originalTime": 1689769113000
    },
    {
        "time": 1689769114000,
        "value": 139.684,
        "_internal_originalTime": 1689769114000
    },
    {
        "time": 1689769115000,
        "value": 139.684,
        "_internal_originalTime": 1689769115000
    },
    {
        "time": 1689769117000,
        "value": 139.684,
        "_internal_originalTime": 1689769117000
    },
    {
        "time": 1689769118000,
        "value": 139.686,
        "_internal_originalTime": 1689769118000
    },
    {
        "time": 1689769120000,
        "value": 139.687,
        "_internal_originalTime": 1689769120000
    },
    {
        "time": 1689769121000,
        "value": 139.685,
        "_internal_originalTime": 1689769121000
    },
    {
        "time": 1689769122000,
        "value": 139.682,
        "_internal_originalTime": 1689769122000
    },
    {
        "time": 1689769124000,
        "value": 139.685,
        "_internal_originalTime": 1689769124000
    },
    {
        "time": 1689769125000,
        "value": 139.683,
        "_internal_originalTime": 1689769125000
    },
    {
        "time": 1689769127000,
        "value": 139.685,
        "_internal_originalTime": 1689769127000
    },
    {
        "time": 1689769129000,
        "value": 139.681,
        "_internal_originalTime": 1689769129000
    },
    {
        "time": 1689769131000,
        "value": 139.682,
        "_internal_originalTime": 1689769131000
    },
    {
        "time": 1689769133000,
        "value": 139.683,
        "_internal_originalTime": 1689769133000
    },
    {
        "time": 1689769134000,
        "value": 139.684,
        "_internal_originalTime": 1689769134000
    },
    {
        "time": 1689769135000,
        "value": 139.682,
        "_internal_originalTime": 1689769135000
    },
    {
        "time": 1689769137000,
        "value": 139.661,
        "_internal_originalTime": 1689769137000
    },
    {
        "time": 1689769138000,
        "value": 139.667,
        "_internal_originalTime": 1689769138000
    },
    {
        "time": 1689769139000,
        "value": 139.662,
        "_internal_originalTime": 1689769139000
    },
    {
        "time": 1689769140000,
        "value": 139.666,
        "_internal_originalTime": 1689769140000
    },
    {
        "time": 1689769141000,
        "value": 139.666,
        "_internal_originalTime": 1689769141000
    },
    {
        "time": 1689769142000,
        "value": 139.667,
        "_internal_originalTime": 1689769142000
    },
    {
        "time": 1689769143000,
        "value": 139.666,
        "_internal_originalTime": 1689769143000
    },
    {
        "time": 1689769145000,
        "value": 139.664,
        "_internal_originalTime": 1689769145000
    },
    {
        "time": 1689769146000,
        "value": 139.666,
        "_internal_originalTime": 1689769146000
    },
    {
        "time": 1689769147000,
        "value": 139.666,
        "_internal_originalTime": 1689769147000
    },
    {
        "time": 1689769149000,
        "value": 139.661,
        "_internal_originalTime": 1689769149000
    },
    {
        "time": 1689769150000,
        "value": 139.666,
        "_internal_originalTime": 1689769150000
    },
    {
        "time": 1689769151000,
        "value": 139.664,
        "_internal_originalTime": 1689769151000
    },
    {
        "time": 1689769153000,
        "value": 139.664,
        "_internal_originalTime": 1689769153000
    },
    {
        "time": 1689769154000,
        "value": 139.66,
        "_internal_originalTime": 1689769154000
    },
    {
        "time": 1689769155000,
        "value": 139.663,
        "_internal_originalTime": 1689769155000
    },
    {
        "time": 1689769156000,
        "value": 139.661,
        "_internal_originalTime": 1689769156000
    },
    {
        "time": 1689769157000,
        "value": 139.661,
        "_internal_originalTime": 1689769157000
    },
    {
        "time": 1689769158000,
        "value": 139.66,
        "_internal_originalTime": 1689769158000
    },
    {
        "time": 1689769160000,
        "value": 139.648,
        "_internal_originalTime": 1689769160000
    },
    {
        "time": 1689769161000,
        "value": 139.645,
        "_internal_originalTime": 1689769161000
    },
    {
        "time": 1689769162000,
        "value": 139.645,
        "_internal_originalTime": 1689769162000
    },
    {
        "time": 1689769164000,
        "value": 139.642,
        "_internal_originalTime": 1689769164000
    },
    {
        "time": 1689769165000,
        "value": 139.639,
        "_internal_originalTime": 1689769165000
    },
    {
        "time": 1689769166000,
        "value": 139.634,
        "_internal_originalTime": 1689769166000
    },
    {
        "time": 1689769167000,
        "value": 139.635,
        "_internal_originalTime": 1689769167000
    },
    {
        "time": 1689769168000,
        "value": 139.634,
        "_internal_originalTime": 1689769168000
    },
    {
        "time": 1689769169000,
        "value": 139.634,
        "_internal_originalTime": 1689769169000
    },
    {
        "time": 1689769170000,
        "value": 139.638,
        "_internal_originalTime": 1689769170000
    },
    {
        "time": 1689769172000,
        "value": 139.638,
        "_internal_originalTime": 1689769172000
    },
    {
        "time": 1689769173000,
        "value": 139.636,
        "_internal_originalTime": 1689769173000
    },
    {
        "time": 1689769175000,
        "value": 139.633,
        "_internal_originalTime": 1689769175000
    },
    {
        "time": 1689769176000,
        "value": 139.632,
        "_internal_originalTime": 1689769176000
    },
    {
        "time": 1689769177000,
        "value": 139.638,
        "_internal_originalTime": 1689769177000
    },
    {
        "time": 1689769178000,
        "value": 139.635,
        "_internal_originalTime": 1689769178000
    },
    {
        "time": 1689769180000,
        "value": 139.637,
        "_internal_originalTime": 1689769180000
    },
    {
        "time": 1689769181000,
        "value": 139.625,
        "_internal_originalTime": 1689769181000
    },
    {
        "time": 1689769183000,
        "value": 139.627,
        "_internal_originalTime": 1689769183000
    },
    {
        "time": 1689769184000,
        "value": 139.63,
        "_internal_originalTime": 1689769184000
    },
    {
        "time": 1689769185000,
        "value": 139.629,
        "_internal_originalTime": 1689769185000
    },
    {
        "time": 1689769186000,
        "value": 139.631,
        "_internal_originalTime": 1689769186000
    },
    {
        "time": 1689769187000,
        "value": 139.635,
        "_internal_originalTime": 1689769187000
    },
    {
        "time": 1689769189000,
        "value": 139.636,
        "_internal_originalTime": 1689769189000
    },
    {
        "time": 1689769190000,
        "value": 139.633,
        "_internal_originalTime": 1689769190000
    },
    {
        "time": 1689769191000,
        "value": 139.634,
        "_internal_originalTime": 1689769191000
    },
    {
        "time": 1689769193000,
        "value": 139.637,
        "_internal_originalTime": 1689769193000
    },
    {
        "time": 1689769194000,
        "value": 139.639,
        "_internal_originalTime": 1689769194000
    },
    {
        "time": 1689769195000,
        "value": 139.634,
        "_internal_originalTime": 1689769195000
    },
    {
        "time": 1689769197000,
        "value": 139.646,
        "_internal_originalTime": 1689769197000
    },
    {
        "time": 1689769199000,
        "value": 139.646,
        "_internal_originalTime": 1689769199000
    },
    {
        "time": 1689769200000,
        "value": 139.642,
        "_internal_originalTime": 1689769200000
    },
    {
        "time": 1689769201000,
        "value": 139.657,
        "_internal_originalTime": 1689769201000
    },
    {
        "time": 1689769203000,
        "value": 139.665,
        "_internal_originalTime": 1689769203000
    },
    {
        "time": 1689769204000,
        "value": 139.678,
        "_internal_originalTime": 1689769204000
    },
    {
        "time": 1689769205000,
        "value": 139.674,
        "_internal_originalTime": 1689769205000
    },
    {
        "time": 1689769206000,
        "value": 139.675,
        "_internal_originalTime": 1689769206000
    },
    {
        "time": 1689769207000,
        "value": 139.673,
        "_internal_originalTime": 1689769207000
    },
    {
        "time": 1689769209000,
        "value": 139.669,
        "_internal_originalTime": 1689769209000
    },
    {
        "time": 1689769210000,
        "value": 139.679,
        "_internal_originalTime": 1689769210000
    },
    {
        "time": 1689769211000,
        "value": 139.665,
        "_internal_originalTime": 1689769211000
    },
    {
        "time": 1689769213000,
        "value": 139.669,
        "_internal_originalTime": 1689769213000
    },
    {
        "time": 1689769214000,
        "value": 139.666,
        "_internal_originalTime": 1689769214000
    },
    {
        "time": 1689769215000,
        "value": 139.663,
        "_internal_originalTime": 1689769215000
    },
    {
        "time": 1689769216000,
        "value": 139.665,
        "_internal_originalTime": 1689769216000
    },
    {
        "time": 1689769217000,
        "value": 139.667,
        "_internal_originalTime": 1689769217000
    },
    {
        "time": 1689769219000,
        "value": 139.664,
        "_internal_originalTime": 1689769219000
    },
    {
        "time": 1689769220000,
        "value": 139.667,
        "_internal_originalTime": 1689769220000
    },
    {
        "time": 1689769221000,
        "value": 139.664,
        "_internal_originalTime": 1689769221000
    },
    {
        "time": 1689769223000,
        "value": 139.648,
        "_internal_originalTime": 1689769223000
    },
    {
        "time": 1689769224000,
        "value": 139.64,
        "_internal_originalTime": 1689769224000
    },
    {
        "time": 1689769226000,
        "value": 139.64,
        "_internal_originalTime": 1689769226000
    },
    {
        "time": 1689769227000,
        "value": 139.632,
        "_internal_originalTime": 1689769227000
    },
    {
        "time": 1689769230000,
        "value": 139.639,
        "_internal_originalTime": 1689769230000
    },
    {
        "time": 1689769232000,
        "value": 139.636,
        "_internal_originalTime": 1689769232000
    },
    {
        "time": 1689769233000,
        "value": 139.636,
        "_internal_originalTime": 1689769233000
    },
    {
        "time": 1689769234000,
        "value": 139.637,
        "_internal_originalTime": 1689769234000
    },
    {
        "time": 1689769235000,
        "value": 139.636,
        "_internal_originalTime": 1689769235000
    },
    {
        "time": 1689769237000,
        "value": 139.638,
        "_internal_originalTime": 1689769237000
    },
    {
        "time": 1689769238000,
        "value": 139.637,
        "_internal_originalTime": 1689769238000
    },
    {
        "time": 1689769240000,
        "value": 139.629,
        "_internal_originalTime": 1689769240000
    },
    {
        "time": 1689769241000,
        "value": 139.618,
        "_internal_originalTime": 1689769241000
    },
    {
        "time": 1689769243000,
        "value": 139.607,
        "_internal_originalTime": 1689769243000
    },
    {
        "time": 1689769244000,
        "value": 139.608,
        "_internal_originalTime": 1689769244000
    },
    {
        "time": 1689769246000,
        "value": 139.606,
        "_internal_originalTime": 1689769246000
    },
    {
        "time": 1689769247000,
        "value": 139.603,
        "_internal_originalTime": 1689769247000
    },
    {
        "time": 1689769248000,
        "value": 139.6,
        "_internal_originalTime": 1689769248000
    },
    {
        "time": 1689769249000,
        "value": 139.605,
        "_internal_originalTime": 1689769249000
    },
    {
        "time": 1689769251000,
        "value": 139.607,
        "_internal_originalTime": 1689769251000
    },
    {
        "time": 1689769252000,
        "value": 139.606,
        "_internal_originalTime": 1689769252000
    },
    {
        "time": 1689769254000,
        "value": 139.619,
        "_internal_originalTime": 1689769254000
    },
    {
        "time": 1689769255000,
        "value": 139.618,
        "_internal_originalTime": 1689769255000
    },
    {
        "time": 1689769256000,
        "value": 139.617,
        "_internal_originalTime": 1689769256000
    },
    {
        "time": 1689769258000,
        "value": 139.616,
        "_internal_originalTime": 1689769258000
    },
    {
        "time": 1689769260000,
        "value": 139.613,
        "_internal_originalTime": 1689769260000
    },
    {
        "time": 1689769262000,
        "value": 139.614,
        "_internal_originalTime": 1689769262000
    },
    {
        "time": 1689769263000,
        "value": 139.626,
        "_internal_originalTime": 1689769263000
    },
    {
        "time": 1689769264000,
        "value": 139.625,
        "_internal_originalTime": 1689769264000
    },
    {
        "time": 1689769265000,
        "value": 139.633,
        "_internal_originalTime": 1689769265000
    },
    {
        "time": 1689769266000,
        "value": 139.631,
        "_internal_originalTime": 1689769266000
    },
    {
        "time": 1689769268000,
        "value": 139.631,
        "_internal_originalTime": 1689769268000
    },
    {
        "time": 1689769269000,
        "value": 139.637,
        "_internal_originalTime": 1689769269000
    },
    {
        "time": 1689769270000,
        "value": 139.631,
        "_internal_originalTime": 1689769270000
    },
    {
        "time": 1689769271000,
        "value": 139.629,
        "_internal_originalTime": 1689769271000
    },
    {
        "time": 1689769272000,
        "value": 139.629,
        "_internal_originalTime": 1689769272000
    },
    {
        "time": 1689769274000,
        "value": 139.638,
        "_internal_originalTime": 1689769274000
    },
    {
        "time": 1689769276000,
        "value": 139.638,
        "_internal_originalTime": 1689769276000
    },
    {
        "time": 1689769277000,
        "value": 139.639,
        "_internal_originalTime": 1689769277000
    },
    {
        "time": 1689769278000,
        "value": 139.637,
        "_internal_originalTime": 1689769278000
    },
    {
        "time": 1689769279000,
        "value": 139.634,
        "_internal_originalTime": 1689769279000
    },
    {
        "time": 1689769281000,
        "value": 139.637,
        "_internal_originalTime": 1689769281000
    },
    {
        "time": 1689769282000,
        "value": 139.632,
        "_internal_originalTime": 1689769282000
    },
    {
        "time": 1689769283000,
        "value": 139.637,
        "_internal_originalTime": 1689769283000
    },
    {
        "time": 1689769284000,
        "value": 139.638,
        "_internal_originalTime": 1689769284000
    },
    {
        "time": 1689769286000,
        "value": 139.634,
        "_internal_originalTime": 1689769286000
    },
    {
        "time": 1689769287000,
        "value": 139.637,
        "_internal_originalTime": 1689769287000
    },
    {
        "time": 1689769288000,
        "value": 139.637,
        "_internal_originalTime": 1689769288000
    },
    {
        "time": 1689769289000,
        "value": 139.631,
        "_internal_originalTime": 1689769289000
    },
    {
        "time": 1689769290000,
        "value": 139.632,
        "_internal_originalTime": 1689769290000
    },
    {
        "time": 1689769291000,
        "value": 139.638,
        "_internal_originalTime": 1689769291000
    },
    {
        "time": 1689769292000,
        "value": 139.645,
        "_internal_originalTime": 1689769292000
    },
    {
        "time": 1689769294000,
        "value": 139.646,
        "_internal_originalTime": 1689769294000
    },
    {
        "time": 1689769295000,
        "value": 139.645,
        "_internal_originalTime": 1689769295000
    },
    {
        "time": 1689769296000,
        "value": 139.645,
        "_internal_originalTime": 1689769296000
    },
    {
        "time": 1689769298000,
        "value": 139.644,
        "_internal_originalTime": 1689769298000
    },
    {
        "time": 1689769299000,
        "value": 139.645,
        "_internal_originalTime": 1689769299000
    },
    {
        "time": 1689769301000,
        "value": 139.652,
        "_internal_originalTime": 1689769301000
    },
    {
        "time": 1689769302000,
        "value": 139.66,
        "_internal_originalTime": 1689769302000
    },
    {
        "time": 1689769303000,
        "value": 139.659,
        "_internal_originalTime": 1689769303000
    },
    {
        "time": 1689769305000,
        "value": 139.659,
        "_internal_originalTime": 1689769305000
    },
    {
        "time": 1689769307000,
        "value": 139.655,
        "_internal_originalTime": 1689769307000
    },
    {
        "time": 1689769308000,
        "value": 139.661,
        "_internal_originalTime": 1689769308000
    },
    {
        "time": 1689769311000,
        "value": 139.664,
        "_internal_originalTime": 1689769311000
    },
    {
        "time": 1689769312000,
        "value": 139.66,
        "_internal_originalTime": 1689769312000
    },
    {
        "time": 1689769313000,
        "value": 139.66,
        "_internal_originalTime": 1689769313000
    },
    {
        "time": 1689769315000,
        "value": 139.657,
        "_internal_originalTime": 1689769315000
    },
    {
        "time": 1689769317000,
        "value": 139.671,
        "_internal_originalTime": 1689769317000
    },
    {
        "time": 1689769318000,
        "value": 139.67,
        "_internal_originalTime": 1689769318000
    },
    {
        "time": 1689769319000,
        "value": 139.671,
        "_internal_originalTime": 1689769319000
    },
    {
        "time": 1689769321000,
        "value": 139.669,
        "_internal_originalTime": 1689769321000
    },
    {
        "time": 1689769322000,
        "value": 139.669,
        "_internal_originalTime": 1689769322000
    },
    {
        "time": 1689769323000,
        "value": 139.669,
        "_internal_originalTime": 1689769323000
    },
    {
        "time": 1689769324000,
        "value": 139.667,
        "_internal_originalTime": 1689769324000
    },
    {
        "time": 1689769326000,
        "value": 139.669,
        "_internal_originalTime": 1689769326000
    },
    {
        "time": 1689769327000,
        "value": 139.669,
        "_internal_originalTime": 1689769327000
    },
    {
        "time": 1689769328000,
        "value": 139.671,
        "_internal_originalTime": 1689769328000
    },
    {
        "time": 1689769330000,
        "value": 139.675,
        "_internal_originalTime": 1689769330000
    },
    {
        "time": 1689769331000,
        "value": 139.673,
        "_internal_originalTime": 1689769331000
    },
    {
        "time": 1689769332000,
        "value": 139.668,
        "_internal_originalTime": 1689769332000
    },
    {
        "time": 1689769333000,
        "value": 139.672,
        "_internal_originalTime": 1689769333000
    },
    {
        "time": 1689769334000,
        "value": 139.672,
        "_internal_originalTime": 1689769334000
    },
    {
        "time": 1689769335000,
        "value": 139.668,
        "_internal_originalTime": 1689769335000
    },
    {
        "time": 1689769337000,
        "value": 139.669,
        "_internal_originalTime": 1689769337000
    },
    {
        "time": 1689769338000,
        "value": 139.669,
        "_internal_originalTime": 1689769338000
    },
    {
        "time": 1689769339000,
        "value": 139.671,
        "_internal_originalTime": 1689769339000
    },
    {
        "time": 1689769340000,
        "value": 139.673,
        "_internal_originalTime": 1689769340000
    },
    {
        "time": 1689769342000,
        "value": 139.672,
        "_internal_originalTime": 1689769342000
    },
    {
        "time": 1689769343000,
        "value": 139.675,
        "_internal_originalTime": 1689769343000
    },
    {
        "time": 1689769344000,
        "value": 139.675,
        "_internal_originalTime": 1689769344000
    },
    {
        "time": 1689769346000,
        "value": 139.672,
        "_internal_originalTime": 1689769346000
    },
    {
        "time": 1689769347000,
        "value": 139.675,
        "_internal_originalTime": 1689769347000
    },
    {
        "time": 1689769349000,
        "value": 139.672,
        "_internal_originalTime": 1689769349000
    },
    {
        "time": 1689769350000,
        "value": 139.676,
        "_internal_originalTime": 1689769350000
    },
    {
        "time": 1689769352000,
        "value": 139.675,
        "_internal_originalTime": 1689769352000
    },
    {
        "time": 1689769353000,
        "value": 139.674,
        "_internal_originalTime": 1689769353000
    },
    {
        "time": 1689769354000,
        "value": 139.676,
        "_internal_originalTime": 1689769354000
    },
    {
        "time": 1689769356000,
        "value": 139.669,
        "_internal_originalTime": 1689769356000
    },
    {
        "time": 1689769357000,
        "value": 139.67,
        "_internal_originalTime": 1689769357000
    },
    {
        "time": 1689769359000,
        "value": 139.669,
        "_internal_originalTime": 1689769359000
    },
    {
        "time": 1689769360000,
        "value": 139.673,
        "_internal_originalTime": 1689769360000
    },
    {
        "time": 1689769361000,
        "value": 139.67,
        "_internal_originalTime": 1689769361000
    },
    {
        "time": 1689769362000,
        "value": 139.671,
        "_internal_originalTime": 1689769362000
    },
    {
        "time": 1689769364000,
        "value": 139.672,
        "_internal_originalTime": 1689769364000
    },
    {
        "time": 1689769365000,
        "value": 139.674,
        "_internal_originalTime": 1689769365000
    },
    {
        "time": 1689769366000,
        "value": 139.675,
        "_internal_originalTime": 1689769366000
    },
    {
        "time": 1689769367000,
        "value": 139.674,
        "_internal_originalTime": 1689769367000
    },
    {
        "time": 1689769368000,
        "value": 139.671,
        "_internal_originalTime": 1689769368000
    },
    {
        "time": 1689769370000,
        "value": 139.665,
        "_internal_originalTime": 1689769370000
    },
    {
        "time": 1689769371000,
        "value": 139.666,
        "_internal_originalTime": 1689769371000
    },
    {
        "time": 1689769373000,
        "value": 139.667,
        "_internal_originalTime": 1689769373000
    },
    {
        "time": 1689769374000,
        "value": 139.665,
        "_internal_originalTime": 1689769374000
    },
    {
        "time": 1689769375000,
        "value": 139.665,
        "_internal_originalTime": 1689769375000
    },
    {
        "time": 1689769377000,
        "value": 139.664,
        "_internal_originalTime": 1689769377000
    },
    {
        "time": 1689769379000,
        "value": 139.664,
        "_internal_originalTime": 1689769379000
    },
    {
        "time": 1689769381000,
        "value": 139.665,
        "_internal_originalTime": 1689769381000
    },
    {
        "time": 1689769382000,
        "value": 139.664,
        "_internal_originalTime": 1689769382000
    },
    {
        "time": 1689769383000,
        "value": 139.662,
        "_internal_originalTime": 1689769383000
    },
    {
        "time": 1689769385000,
        "value": 139.661,
        "_internal_originalTime": 1689769385000
    },
    {
        "time": 1689769386000,
        "value": 139.659,
        "_internal_originalTime": 1689769386000
    },
    {
        "time": 1689769388000,
        "value": 139.657,
        "_internal_originalTime": 1689769388000
    },
    {
        "time": 1689769389000,
        "value": 139.651,
        "_internal_originalTime": 1689769389000
    },
    {
        "time": 1689769390000,
        "value": 139.656,
        "_internal_originalTime": 1689769390000
    },
    {
        "time": 1689769391000,
        "value": 139.655,
        "_internal_originalTime": 1689769391000
    },
    {
        "time": 1689769393000,
        "value": 139.655,
        "_internal_originalTime": 1689769393000
    },
    {
        "time": 1689769394000,
        "value": 139.654,
        "_internal_originalTime": 1689769394000
    },
    {
        "time": 1689769395000,
        "value": 139.653,
        "_internal_originalTime": 1689769395000
    },
    {
        "time": 1689769396000,
        "value": 139.654,
        "_internal_originalTime": 1689769396000
    },
    {
        "time": 1689769398000,
        "value": 139.649,
        "_internal_originalTime": 1689769398000
    },
    {
        "time": 1689769399000,
        "value": 139.656,
        "_internal_originalTime": 1689769399000
    },
    {
        "time": 1689769401000,
        "value": 139.653,
        "_internal_originalTime": 1689769401000
    },
    {
        "time": 1689769402000,
        "value": 139.656,
        "_internal_originalTime": 1689769402000
    },
    {
        "time": 1689769403000,
        "value": 139.651,
        "_internal_originalTime": 1689769403000
    },
    {
        "time": 1689769405000,
        "value": 139.649,
        "_internal_originalTime": 1689769405000
    },
    {
        "time": 1689769406000,
        "value": 139.648,
        "_internal_originalTime": 1689769406000
    },
    {
        "time": 1689769407000,
        "value": 139.647,
        "_internal_originalTime": 1689769407000
    },
    {
        "time": 1689769410000,
        "value": 139.644,
        "_internal_originalTime": 1689769410000
    },
    {
        "time": 1689769412000,
        "value": 139.639,
        "_internal_originalTime": 1689769412000
    },
    {
        "time": 1689769413000,
        "value": 139.622,
        "_internal_originalTime": 1689769413000
    },
    {
        "time": 1689769414000,
        "value": 139.629,
        "_internal_originalTime": 1689769414000
    },
    {
        "time": 1689769416000,
        "value": 139.649,
        "_internal_originalTime": 1689769416000
    },
    {
        "time": 1689769417000,
        "value": 139.657,
        "_internal_originalTime": 1689769417000
    },
    {
        "time": 1689769419000,
        "value": 139.655,
        "_internal_originalTime": 1689769419000
    },
    {
        "time": 1689769420000,
        "value": 139.656,
        "_internal_originalTime": 1689769420000
    },
    {
        "time": 1689769422000,
        "value": 139.652,
        "_internal_originalTime": 1689769422000
    },
    {
        "time": 1689769423000,
        "value": 139.651,
        "_internal_originalTime": 1689769423000
    },
    {
        "time": 1689769424000,
        "value": 139.644,
        "_internal_originalTime": 1689769424000
    },
    {
        "time": 1689769425000,
        "value": 139.645,
        "_internal_originalTime": 1689769425000
    },
    {
        "time": 1689769426000,
        "value": 139.648,
        "_internal_originalTime": 1689769426000
    },
    {
        "time": 1689769427000,
        "value": 139.644,
        "_internal_originalTime": 1689769427000
    },
    {
        "time": 1689769429000,
        "value": 139.66,
        "_internal_originalTime": 1689769429000
    },
    {
        "time": 1689769430000,
        "value": 139.656,
        "_internal_originalTime": 1689769430000
    },
    {
        "time": 1689769431000,
        "value": 139.655,
        "_internal_originalTime": 1689769431000
    },
    {
        "time": 1689769433000,
        "value": 139.654,
        "_internal_originalTime": 1689769433000
    },
    {
        "time": 1689769434000,
        "value": 139.656,
        "_internal_originalTime": 1689769434000
    },
    {
        "time": 1689769437000,
        "value": 139.666,
        "_internal_originalTime": 1689769437000
    },
    {
        "time": 1689769438000,
        "value": 139.664,
        "_internal_originalTime": 1689769438000
    },
    {
        "time": 1689769440000,
        "value": 139.66,
        "_internal_originalTime": 1689769440000
    },
    {
        "time": 1689769441000,
        "value": 139.658,
        "_internal_originalTime": 1689769441000
    },
    {
        "time": 1689769442000,
        "value": 139.647,
        "_internal_originalTime": 1689769442000
    },
    {
        "time": 1689769443000,
        "value": 139.652,
        "_internal_originalTime": 1689769443000
    },
    {
        "time": 1689769445000,
        "value": 139.649,
        "_internal_originalTime": 1689769445000
    },
    {
        "time": 1689769447000,
        "value": 139.646,
        "_internal_originalTime": 1689769447000
    },
    {
        "time": 1689769449000,
        "value": 139.641,
        "_internal_originalTime": 1689769449000
    },
    {
        "time": 1689769450000,
        "value": 139.648,
        "_internal_originalTime": 1689769450000
    },
    {
        "time": 1689769452000,
        "value": 139.646,
        "_internal_originalTime": 1689769452000
    },
    {
        "time": 1689769453000,
        "value": 139.647,
        "_internal_originalTime": 1689769453000
    },
    {
        "time": 1689769454000,
        "value": 139.645,
        "_internal_originalTime": 1689769454000
    },
    {
        "time": 1689769455000,
        "value": 139.645,
        "_internal_originalTime": 1689769455000
    },
    {
        "time": 1689769456000,
        "value": 139.639,
        "_internal_originalTime": 1689769456000
    },
    {
        "time": 1689769457000,
        "value": 139.637,
        "_internal_originalTime": 1689769457000
    },
    {
        "time": 1689769459000,
        "value": 139.639,
        "_internal_originalTime": 1689769459000
    },
    {
        "time": 1689769461000,
        "value": 139.636,
        "_internal_originalTime": 1689769461000
    },
    {
        "time": 1689769462000,
        "value": 139.64,
        "_internal_originalTime": 1689769462000
    },
    {
        "time": 1689769463000,
        "value": 139.639,
        "_internal_originalTime": 1689769463000
    },
    {
        "time": 1689769464000,
        "value": 139.638,
        "_internal_originalTime": 1689769464000
    },
    {
        "time": 1689769466000,
        "value": 139.639,
        "_internal_originalTime": 1689769466000
    },
    {
        "time": 1689769467000,
        "value": 139.636,
        "_internal_originalTime": 1689769467000
    },
    {
        "time": 1689769469000,
        "value": 139.637,
        "_internal_originalTime": 1689769469000
    },
    {
        "time": 1689769470000,
        "value": 139.639,
        "_internal_originalTime": 1689769470000
    },
    {
        "time": 1689769471000,
        "value": 139.637,
        "_internal_originalTime": 1689769471000
    },
    {
        "time": 1689769472000,
        "value": 139.637,
        "_internal_originalTime": 1689769472000
    },
    {
        "time": 1689769473000,
        "value": 139.632,
        "_internal_originalTime": 1689769473000
    },
    {
        "time": 1689769474000,
        "value": 139.631,
        "_internal_originalTime": 1689769474000
    },
    {
        "time": 1689769475000,
        "value": 139.63,
        "_internal_originalTime": 1689769475000
    },
    {
        "time": 1689769477000,
        "value": 139.634,
        "_internal_originalTime": 1689769477000
    },
    {
        "time": 1689769478000,
        "value": 139.63,
        "_internal_originalTime": 1689769478000
    },
    {
        "time": 1689769480000,
        "value": 139.633,
        "_internal_originalTime": 1689769480000
    },
    {
        "time": 1689769481000,
        "value": 139.632,
        "_internal_originalTime": 1689769481000
    },
    {
        "time": 1689769483000,
        "value": 139.625,
        "_internal_originalTime": 1689769483000
    },
    {
        "time": 1689769485000,
        "value": 139.626,
        "_internal_originalTime": 1689769485000
    },
    {
        "time": 1689769486000,
        "value": 139.626,
        "_internal_originalTime": 1689769486000
    },
    {
        "time": 1689769487000,
        "value": 139.618,
        "_internal_originalTime": 1689769487000
    },
    {
        "time": 1689769488000,
        "value": 139.616,
        "_internal_originalTime": 1689769488000
    },
    {
        "time": 1689769490000,
        "value": 139.607,
        "_internal_originalTime": 1689769490000
    },
    {
        "time": 1689769491000,
        "value": 139.611,
        "_internal_originalTime": 1689769491000
    },
    {
        "time": 1689769492000,
        "value": 139.607,
        "_internal_originalTime": 1689769492000
    },
    {
        "time": 1689769494000,
        "value": 139.605,
        "_internal_originalTime": 1689769494000
    },
    {
        "time": 1689769495000,
        "value": 139.597,
        "_internal_originalTime": 1689769495000
    },
    {
        "time": 1689769497000,
        "value": 139.609,
        "_internal_originalTime": 1689769497000
    },
    {
        "time": 1689769499000,
        "value": 139.606,
        "_internal_originalTime": 1689769499000
    },
    {
        "time": 1689769500000,
        "value": 139.612,
        "_internal_originalTime": 1689769500000
    },
    {
        "time": 1689769501000,
        "value": 139.614,
        "_internal_originalTime": 1689769501000
    },
    {
        "time": 1689769503000,
        "value": 139.617,
        "_internal_originalTime": 1689769503000
    },
    {
        "time": 1689769505000,
        "value": 139.617,
        "_internal_originalTime": 1689769505000
    },
    {
        "time": 1689769506000,
        "value": 139.617,
        "_internal_originalTime": 1689769506000
    },
    {
        "time": 1689769507000,
        "value": 139.62,
        "_internal_originalTime": 1689769507000
    },
    {
        "time": 1689769508000,
        "value": 139.621,
        "_internal_originalTime": 1689769508000
    },
    {
        "time": 1689769510000,
        "value": 139.624,
        "_internal_originalTime": 1689769510000
    },
    {
        "time": 1689769511000,
        "value": 139.626,
        "_internal_originalTime": 1689769511000
    },
    {
        "time": 1689769512000,
        "value": 139.626,
        "_internal_originalTime": 1689769512000
    },
    {
        "time": 1689769514000,
        "value": 139.626,
        "_internal_originalTime": 1689769514000
    },
    {
        "time": 1689769515000,
        "value": 139.627,
        "_internal_originalTime": 1689769515000
    },
    {
        "time": 1689769516000,
        "value": 139.632,
        "_internal_originalTime": 1689769516000
    },
    {
        "time": 1689769518000,
        "value": 139.634,
        "_internal_originalTime": 1689769518000
    },
    {
        "time": 1689769519000,
        "value": 139.629,
        "_internal_originalTime": 1689769519000
    },
    {
        "time": 1689769520000,
        "value": 139.627,
        "_internal_originalTime": 1689769520000
    },
    {
        "time": 1689769521000,
        "value": 139.63,
        "_internal_originalTime": 1689769521000
    },
    {
        "time": 1689769523000,
        "value": 139.618,
        "_internal_originalTime": 1689769523000
    },
    {
        "time": 1689769524000,
        "value": 139.62,
        "_internal_originalTime": 1689769524000
    },
    {
        "time": 1689769526000,
        "value": 139.619,
        "_internal_originalTime": 1689769526000
    },
    {
        "time": 1689769527000,
        "value": 139.617,
        "_internal_originalTime": 1689769527000
    },
    {
        "time": 1689769528000,
        "value": 139.617,
        "_internal_originalTime": 1689769528000
    },
    {
        "time": 1689769530000,
        "value": 139.621,
        "_internal_originalTime": 1689769530000
    },
    {
        "time": 1689769531000,
        "value": 139.616,
        "_internal_originalTime": 1689769531000
    },
    {
        "time": 1689769532000,
        "value": 139.614,
        "_internal_originalTime": 1689769532000
    },
    {
        "time": 1689769533000,
        "value": 139.609,
        "_internal_originalTime": 1689769533000
    },
    {
        "time": 1689769534000,
        "value": 139.61,
        "_internal_originalTime": 1689769534000
    },
    {
        "time": 1689769536000,
        "value": 139.61,
        "_internal_originalTime": 1689769536000
    },
    {
        "time": 1689769537000,
        "value": 139.61,
        "_internal_originalTime": 1689769537000
    },
    {
        "time": 1689769538000,
        "value": 139.609,
        "_internal_originalTime": 1689769538000
    },
    {
        "time": 1689769540000,
        "value": 139.614,
        "_internal_originalTime": 1689769540000
    },
    {
        "time": 1689769541000,
        "value": 139.611,
        "_internal_originalTime": 1689769541000
    },
    {
        "time": 1689769542000,
        "value": 139.604,
        "_internal_originalTime": 1689769542000
    },
    {
        "time": 1689769544000,
        "value": 139.601,
        "_internal_originalTime": 1689769544000
    },
    {
        "time": 1689769545000,
        "value": 139.605,
        "_internal_originalTime": 1689769545000
    },
    {
        "time": 1689769547000,
        "value": 139.608,
        "_internal_originalTime": 1689769547000
    },
    {
        "time": 1689769548000,
        "value": 139.596,
        "_internal_originalTime": 1689769548000
    },
    {
        "time": 1689769549000,
        "value": 139.594,
        "_internal_originalTime": 1689769549000
    },
    {
        "time": 1689769550000,
        "value": 139.597,
        "_internal_originalTime": 1689769550000
    },
    {
        "time": 1689769551000,
        "value": 139.591,
        "_internal_originalTime": 1689769551000
    },
    {
        "time": 1689769552000,
        "value": 139.584,
        "_internal_originalTime": 1689769552000
    },
    {
        "time": 1689769554000,
        "value": 139.581,
        "_internal_originalTime": 1689769554000
    },
    {
        "time": 1689769555000,
        "value": 139.577,
        "_internal_originalTime": 1689769555000
    },
    {
        "time": 1689769557000,
        "value": 139.575,
        "_internal_originalTime": 1689769557000
    },
    {
        "time": 1689769558000,
        "value": 139.569,
        "_internal_originalTime": 1689769558000
    },
    {
        "time": 1689769559000,
        "value": 139.57,
        "_internal_originalTime": 1689769559000
    },
    {
        "time": 1689769560000,
        "value": 139.558,
        "_internal_originalTime": 1689769560000
    },
    {
        "time": 1689769561000,
        "value": 139.559,
        "_internal_originalTime": 1689769561000
    },
    {
        "time": 1689769562000,
        "value": 139.563,
        "_internal_originalTime": 1689769562000
    },
    {
        "time": 1689769563000,
        "value": 139.569,
        "_internal_originalTime": 1689769563000
    },
    {
        "time": 1689769565000,
        "value": 139.576,
        "_internal_originalTime": 1689769565000
    },
    {
        "time": 1689769566000,
        "value": 139.581,
        "_internal_originalTime": 1689769566000
    },
    {
        "time": 1689769567000,
        "value": 139.568,
        "_internal_originalTime": 1689769567000
    },
    {
        "time": 1689769569000,
        "value": 139.569,
        "_internal_originalTime": 1689769569000
    },
    {
        "time": 1689769570000,
        "value": 139.572,
        "_internal_originalTime": 1689769570000
    },
    {
        "time": 1689769571000,
        "value": 139.572,
        "_internal_originalTime": 1689769571000
    },
    {
        "time": 1689769573000,
        "value": 139.572,
        "_internal_originalTime": 1689769573000
    },
    {
        "time": 1689769575000,
        "value": 139.557,
        "_internal_originalTime": 1689769575000
    },
    {
        "time": 1689769576000,
        "value": 139.563,
        "_internal_originalTime": 1689769576000
    },
    {
        "time": 1689769577000,
        "value": 139.561,
        "_internal_originalTime": 1689769577000
    },
    {
        "time": 1689769579000,
        "value": 139.562,
        "_internal_originalTime": 1689769579000
    },
    {
        "time": 1689769580000,
        "value": 139.565,
        "_internal_originalTime": 1689769580000
    },
    {
        "time": 1689769581000,
        "value": 139.565,
        "_internal_originalTime": 1689769581000
    },
    {
        "time": 1689769582000,
        "value": 139.566,
        "_internal_originalTime": 1689769582000
    },
    {
        "time": 1689769584000,
        "value": 139.564,
        "_internal_originalTime": 1689769584000
    },
    {
        "time": 1689769585000,
        "value": 139.563,
        "_internal_originalTime": 1689769585000
    },
    {
        "time": 1689769586000,
        "value": 139.563,
        "_internal_originalTime": 1689769586000
    },
    {
        "time": 1689769587000,
        "value": 139.56,
        "_internal_originalTime": 1689769587000
    },
    {
        "time": 1689769589000,
        "value": 139.566,
        "_internal_originalTime": 1689769589000
    },
    {
        "time": 1689769590000,
        "value": 139.567,
        "_internal_originalTime": 1689769590000
    },
    {
        "time": 1689769591000,
        "value": 139.58,
        "_internal_originalTime": 1689769591000
    },
    {
        "time": 1689769592000,
        "value": 139.583,
        "_internal_originalTime": 1689769592000
    },
    {
        "time": 1689769593000,
        "value": 139.585,
        "_internal_originalTime": 1689769593000
    },
    {
        "time": 1689769595000,
        "value": 139.59,
        "_internal_originalTime": 1689769595000
    },
    {
        "time": 1689769596000,
        "value": 139.589,
        "_internal_originalTime": 1689769596000
    },
    {
        "time": 1689769597000,
        "value": 139.59,
        "_internal_originalTime": 1689769597000
    },
    {
        "time": 1689769598000,
        "value": 139.588,
        "_internal_originalTime": 1689769598000
    },
    {
        "time": 1689769600000,
        "value": 139.588,
        "_internal_originalTime": 1689769600000
    },
    {
        "time": 1689769601000,
        "value": 139.59,
        "_internal_originalTime": 1689769601000
    },
    {
        "time": 1689769603000,
        "value": 139.577,
        "_internal_originalTime": 1689769603000
    },
    {
        "time": 1689769605000,
        "value": 139.579,
        "_internal_originalTime": 1689769605000
    },
    {
        "time": 1689769606000,
        "value": 139.568,
        "_internal_originalTime": 1689769606000
    },
    {
        "time": 1689769607000,
        "value": 139.578,
        "_internal_originalTime": 1689769607000
    },
    {
        "time": 1689769608000,
        "value": 139.562,
        "_internal_originalTime": 1689769608000
    },
    {
        "time": 1689769610000,
        "value": 139.558,
        "_internal_originalTime": 1689769610000
    },
    {
        "time": 1689769611000,
        "value": 139.562,
        "_internal_originalTime": 1689769611000
    },
    {
        "time": 1689769613000,
        "value": 139.558,
        "_internal_originalTime": 1689769613000
    },
    {
        "time": 1689769614000,
        "value": 139.562,
        "_internal_originalTime": 1689769614000
    },
    {
        "time": 1689769615000,
        "value": 139.562,
        "_internal_originalTime": 1689769615000
    },
    {
        "time": 1689769617000,
        "value": 139.565,
        "_internal_originalTime": 1689769617000
    },
    {
        "time": 1689769618000,
        "value": 139.567,
        "_internal_originalTime": 1689769618000
    },
    {
        "time": 1689769619000,
        "value": 139.565,
        "_internal_originalTime": 1689769619000
    },
    {
        "time": 1689769620000,
        "value": 139.571,
        "_internal_originalTime": 1689769620000
    },
    {
        "time": 1689769621000,
        "value": 139.57,
        "_internal_originalTime": 1689769621000
    },
    {
        "time": 1689769622000,
        "value": 139.568,
        "_internal_originalTime": 1689769622000
    },
    {
        "time": 1689769623000,
        "value": 139.568,
        "_internal_originalTime": 1689769623000
    },
    {
        "time": 1689769625000,
        "value": 139.566,
        "_internal_originalTime": 1689769625000
    },
    {
        "time": 1689769626000,
        "value": 139.568,
        "_internal_originalTime": 1689769626000
    },
    {
        "time": 1689769627000,
        "value": 139.572,
        "_internal_originalTime": 1689769627000
    },
    {
        "time": 1689769629000,
        "value": 139.571,
        "_internal_originalTime": 1689769629000
    },
    {
        "time": 1689769630000,
        "value": 139.57,
        "_internal_originalTime": 1689769630000
    },
    {
        "time": 1689769632000,
        "value": 139.57,
        "_internal_originalTime": 1689769632000
    },
    {
        "time": 1689769633000,
        "value": 139.568,
        "_internal_originalTime": 1689769633000
    },
    {
        "time": 1689769635000,
        "value": 139.573,
        "_internal_originalTime": 1689769635000
    },
    {
        "time": 1689769636000,
        "value": 139.596,
        "_internal_originalTime": 1689769636000
    },
    {
        "time": 1689769638000,
        "value": 139.592,
        "_internal_originalTime": 1689769638000
    },
    {
        "time": 1689769640000,
        "value": 139.589,
        "_internal_originalTime": 1689769640000
    },
    {
        "time": 1689769641000,
        "value": 139.591,
        "_internal_originalTime": 1689769641000
    },
    {
        "time": 1689769643000,
        "value": 139.59,
        "_internal_originalTime": 1689769643000
    },
    {
        "time": 1689769645000,
        "value": 139.588,
        "_internal_originalTime": 1689769645000
    },
    {
        "time": 1689769646000,
        "value": 139.588,
        "_internal_originalTime": 1689769646000
    },
    {
        "time": 1689769647000,
        "value": 139.578,
        "_internal_originalTime": 1689769647000
    },
    {
        "time": 1689769648000,
        "value": 139.581,
        "_internal_originalTime": 1689769648000
    },
    {
        "time": 1689769649000,
        "value": 139.58,
        "_internal_originalTime": 1689769649000
    },
    {
        "time": 1689769650000,
        "value": 139.578,
        "_internal_originalTime": 1689769650000
    },
    {
        "time": 1689769652000,
        "value": 139.587,
        "_internal_originalTime": 1689769652000
    },
    {
        "time": 1689769653000,
        "value": 139.583,
        "_internal_originalTime": 1689769653000
    },
    {
        "time": 1689769654000,
        "value": 139.583,
        "_internal_originalTime": 1689769654000
    },
    {
        "time": 1689769656000,
        "value": 139.583,
        "_internal_originalTime": 1689769656000
    },
    {
        "time": 1689769657000,
        "value": 139.585,
        "_internal_originalTime": 1689769657000
    },
    {
        "time": 1689769658000,
        "value": 139.581,
        "_internal_originalTime": 1689769658000
    },
    {
        "time": 1689769660000,
        "value": 139.581,
        "_internal_originalTime": 1689769660000
    },
    {
        "time": 1689769662000,
        "value": 139.582,
        "_internal_originalTime": 1689769662000
    },
    {
        "time": 1689769663000,
        "value": 139.584,
        "_internal_originalTime": 1689769663000
    },
    {
        "time": 1689769665000,
        "value": 139.575,
        "_internal_originalTime": 1689769665000
    },
    {
        "time": 1689769666000,
        "value": 139.579,
        "_internal_originalTime": 1689769666000
    },
    {
        "time": 1689769668000,
        "value": 139.574,
        "_internal_originalTime": 1689769668000
    },
    {
        "time": 1689769670000,
        "value": 139.577,
        "_internal_originalTime": 1689769670000
    },
    {
        "time": 1689769671000,
        "value": 139.57,
        "_internal_originalTime": 1689769671000
    },
    {
        "time": 1689769673000,
        "value": 139.57,
        "_internal_originalTime": 1689769673000
    },
    {
        "time": 1689769674000,
        "value": 139.578,
        "_internal_originalTime": 1689769674000
    },
    {
        "time": 1689769675000,
        "value": 139.572,
        "_internal_originalTime": 1689769675000
    },
    {
        "time": 1689769676000,
        "value": 139.57,
        "_internal_originalTime": 1689769676000
    },
    {
        "time": 1689769679000,
        "value": 139.571,
        "_internal_originalTime": 1689769679000
    },
    {
        "time": 1689769681000,
        "value": 139.575,
        "_internal_originalTime": 1689769681000
    },
    {
        "time": 1689769682000,
        "value": 139.572,
        "_internal_originalTime": 1689769682000
    },
    {
        "time": 1689769683000,
        "value": 139.571,
        "_internal_originalTime": 1689769683000
    },
    {
        "time": 1689769684000,
        "value": 139.569,
        "_internal_originalTime": 1689769684000
    },
    {
        "time": 1689769686000,
        "value": 139.568,
        "_internal_originalTime": 1689769686000
    },
    {
        "time": 1689769687000,
        "value": 139.566,
        "_internal_originalTime": 1689769687000
    },
    {
        "time": 1689769689000,
        "value": 139.563,
        "_internal_originalTime": 1689769689000
    },
    {
        "time": 1689769690000,
        "value": 139.569,
        "_internal_originalTime": 1689769690000
    },
    {
        "time": 1689769691000,
        "value": 139.565,
        "_internal_originalTime": 1689769691000
    },
    {
        "time": 1689769693000,
        "value": 139.565,
        "_internal_originalTime": 1689769693000
    },
    {
        "time": 1689769695000,
        "value": 139.566,
        "_internal_originalTime": 1689769695000
    },
    {
        "time": 1689769697000,
        "value": 139.564,
        "_internal_originalTime": 1689769697000
    },
    {
        "time": 1689769698000,
        "value": 139.558,
        "_internal_originalTime": 1689769698000
    },
    {
        "time": 1689769699000,
        "value": 139.567,
        "_internal_originalTime": 1689769699000
    },
    {
        "time": 1689769700000,
        "value": 139.568,
        "_internal_originalTime": 1689769700000
    },
    {
        "time": 1689769701000,
        "value": 139.567,
        "_internal_originalTime": 1689769701000
    },
    {
        "time": 1689769703000,
        "value": 139.564,
        "_internal_originalTime": 1689769703000
    },
    {
        "time": 1689769704000,
        "value": 139.566,
        "_internal_originalTime": 1689769704000
    },
    {
        "time": 1689769705000,
        "value": 139.567,
        "_internal_originalTime": 1689769705000
    },
    {
        "time": 1689769707000,
        "value": 139.566,
        "_internal_originalTime": 1689769707000
    },
    {
        "time": 1689769709000,
        "value": 139.564,
        "_internal_originalTime": 1689769709000
    },
    {
        "time": 1689769710000,
        "value": 139.559,
        "_internal_originalTime": 1689769710000
    },
    {
        "time": 1689769711000,
        "value": 139.56,
        "_internal_originalTime": 1689769711000
    },
    {
        "time": 1689769713000,
        "value": 139.548,
        "_internal_originalTime": 1689769713000
    },
    {
        "time": 1689769714000,
        "value": 139.548,
        "_internal_originalTime": 1689769714000
    },
    {
        "time": 1689769715000,
        "value": 139.547,
        "_internal_originalTime": 1689769715000
    },
    {
        "time": 1689769717000,
        "value": 139.543,
        "_internal_originalTime": 1689769717000
    },
    {
        "time": 1689769718000,
        "value": 139.542,
        "_internal_originalTime": 1689769718000
    },
    {
        "time": 1689769720000,
        "value": 139.536,
        "_internal_originalTime": 1689769720000
    },
    {
        "time": 1689769721000,
        "value": 139.541,
        "_internal_originalTime": 1689769721000
    },
    {
        "time": 1689769723000,
        "value": 139.54,
        "_internal_originalTime": 1689769723000
    },
    {
        "time": 1689769725000,
        "value": 139.543,
        "_internal_originalTime": 1689769725000
    },
    {
        "time": 1689769726000,
        "value": 139.542,
        "_internal_originalTime": 1689769726000
    },
    {
        "time": 1689769727000,
        "value": 139.541,
        "_internal_originalTime": 1689769727000
    },
    {
        "time": 1689769729000,
        "value": 139.544,
        "_internal_originalTime": 1689769729000
    },
    {
        "time": 1689769730000,
        "value": 139.541,
        "_internal_originalTime": 1689769730000
    },
    {
        "time": 1689769732000,
        "value": 139.54,
        "_internal_originalTime": 1689769732000
    },
    {
        "time": 1689769733000,
        "value": 139.534,
        "_internal_originalTime": 1689769733000
    },
    {
        "time": 1689769734000,
        "value": 139.538,
        "_internal_originalTime": 1689769734000
    },
    {
        "time": 1689769735000,
        "value": 139.533,
        "_internal_originalTime": 1689769735000
    },
    {
        "time": 1689769736000,
        "value": 139.536,
        "_internal_originalTime": 1689769736000
    },
    {
        "time": 1689769738000,
        "value": 139.529,
        "_internal_originalTime": 1689769738000
    },
    {
        "time": 1689769739000,
        "value": 139.531,
        "_internal_originalTime": 1689769739000
    },
    {
        "time": 1689769740000,
        "value": 139.527,
        "_internal_originalTime": 1689769740000
    },
    {
        "time": 1689769741000,
        "value": 139.529,
        "_internal_originalTime": 1689769741000
    },
    {
        "time": 1689769742000,
        "value": 139.53,
        "_internal_originalTime": 1689769742000
    },
    {
        "time": 1689769743000,
        "value": 139.53,
        "_internal_originalTime": 1689769743000
    },
    {
        "time": 1689769746000,
        "value": 139.533,
        "_internal_originalTime": 1689769746000
    },
    {
        "time": 1689769747000,
        "value": 139.521,
        "_internal_originalTime": 1689769747000
    },
    {
        "time": 1689769749000,
        "value": 139.526,
        "_internal_originalTime": 1689769749000
    },
    {
        "time": 1689769750000,
        "value": 139.526,
        "_internal_originalTime": 1689769750000
    },
    {
        "time": 1689769751000,
        "value": 139.529,
        "_internal_originalTime": 1689769751000
    },
    {
        "time": 1689769752000,
        "value": 139.532,
        "_internal_originalTime": 1689769752000
    },
    {
        "time": 1689769755000,
        "value": 139.533,
        "_internal_originalTime": 1689769755000
    },
    {
        "time": 1689769756000,
        "value": 139.532,
        "_internal_originalTime": 1689769756000
    },
    {
        "time": 1689769758000,
        "value": 139.528,
        "_internal_originalTime": 1689769758000
    },
    {
        "time": 1689769759000,
        "value": 139.527,
        "_internal_originalTime": 1689769759000
    },
    {
        "time": 1689769760000,
        "value": 139.525,
        "_internal_originalTime": 1689769760000
    },
    {
        "time": 1689769761000,
        "value": 139.527,
        "_internal_originalTime": 1689769761000
    },
    {
        "time": 1689769763000,
        "value": 139.516,
        "_internal_originalTime": 1689769763000
    },
    {
        "time": 1689769764000,
        "value": 139.519,
        "_internal_originalTime": 1689769764000
    },
    {
        "time": 1689769766000,
        "value": 139.521,
        "_internal_originalTime": 1689769766000
    },
    {
        "time": 1689769767000,
        "value": 139.514,
        "_internal_originalTime": 1689769767000
    },
    {
        "time": 1689769769000,
        "value": 139.52,
        "_internal_originalTime": 1689769769000
    },
    {
        "time": 1689769770000,
        "value": 139.514,
        "_internal_originalTime": 1689769770000
    },
    {
        "time": 1689769771000,
        "value": 139.518,
        "_internal_originalTime": 1689769771000
    },
    {
        "time": 1689769772000,
        "value": 139.508,
        "_internal_originalTime": 1689769772000
    },
    {
        "time": 1689769774000,
        "value": 139.514,
        "_internal_originalTime": 1689769774000
    },
    {
        "time": 1689769775000,
        "value": 139.508,
        "_internal_originalTime": 1689769775000
    },
    {
        "time": 1689769776000,
        "value": 139.51,
        "_internal_originalTime": 1689769776000
    },
    {
        "time": 1689769778000,
        "value": 139.505,
        "_internal_originalTime": 1689769778000
    },
    {
        "time": 1689769779000,
        "value": 139.504,
        "_internal_originalTime": 1689769779000
    },
    {
        "time": 1689769781000,
        "value": 139.509,
        "_internal_originalTime": 1689769781000
    },
    {
        "time": 1689769782000,
        "value": 139.506,
        "_internal_originalTime": 1689769782000
    },
    {
        "time": 1689769784000,
        "value": 139.511,
        "_internal_originalTime": 1689769784000
    },
    {
        "time": 1689769785000,
        "value": 139.507,
        "_internal_originalTime": 1689769785000
    },
    {
        "time": 1689769786000,
        "value": 139.506,
        "_internal_originalTime": 1689769786000
    },
    {
        "time": 1689769788000,
        "value": 139.511,
        "_internal_originalTime": 1689769788000
    },
    {
        "time": 1689769789000,
        "value": 139.508,
        "_internal_originalTime": 1689769789000
    },
    {
        "time": 1689769791000,
        "value": 139.502,
        "_internal_originalTime": 1689769791000
    },
    {
        "time": 1689769793000,
        "value": 139.502,
        "_internal_originalTime": 1689769793000
    },
    {
        "time": 1689769794000,
        "value": 139.497,
        "_internal_originalTime": 1689769794000
    },
    {
        "time": 1689769795000,
        "value": 139.501,
        "_internal_originalTime": 1689769795000
    },
    {
        "time": 1689769797000,
        "value": 139.49,
        "_internal_originalTime": 1689769797000
    },
    {
        "time": 1689769798000,
        "value": 139.499,
        "_internal_originalTime": 1689769798000
    },
    {
        "time": 1689769799000,
        "value": 139.504,
        "_internal_originalTime": 1689769799000
    },
    {
        "time": 1689769800000,
        "value": 139.503,
        "_internal_originalTime": 1689769800000
    },
    {
        "time": 1689769801000,
        "value": 139.498,
        "_internal_originalTime": 1689769801000
    },
    {
        "time": 1689769802000,
        "value": 139.496,
        "_internal_originalTime": 1689769802000
    },
    {
        "time": 1689769803000,
        "value": 139.51,
        "_internal_originalTime": 1689769803000
    },
    {
        "time": 1689769804000,
        "value": 139.506,
        "_internal_originalTime": 1689769804000
    },
    {
        "time": 1689769806000,
        "value": 139.493,
        "_internal_originalTime": 1689769806000
    },
    {
        "time": 1689769807000,
        "value": 139.489,
        "_internal_originalTime": 1689769807000
    },
    {
        "time": 1689769809000,
        "value": 139.446,
        "_internal_originalTime": 1689769809000
    },
    {
        "time": 1689769810000,
        "value": 139.447,
        "_internal_originalTime": 1689769810000
    },
    {
        "time": 1689769811000,
        "value": 139.447,
        "_internal_originalTime": 1689769811000
    },
    {
        "time": 1689769812000,
        "value": 139.428,
        "_internal_originalTime": 1689769812000
    },
    {
        "time": 1689769813000,
        "value": 139.458,
        "_internal_originalTime": 1689769813000
    },
    {
        "time": 1689769815000,
        "value": 139.457,
        "_internal_originalTime": 1689769815000
    },
    {
        "time": 1689769816000,
        "value": 139.461,
        "_internal_originalTime": 1689769816000
    },
    {
        "time": 1689769817000,
        "value": 139.482,
        "_internal_originalTime": 1689769817000
    },
    {
        "time": 1689769819000,
        "value": 139.502,
        "_internal_originalTime": 1689769819000
    },
    {
        "time": 1689769820000,
        "value": 139.503,
        "_internal_originalTime": 1689769820000
    },
    {
        "time": 1689769821000,
        "value": 139.509,
        "_internal_originalTime": 1689769821000
    },
    {
        "time": 1689769822000,
        "value": 139.522,
        "_internal_originalTime": 1689769822000
    },
    {
        "time": 1689769824000,
        "value": 139.517,
        "_internal_originalTime": 1689769824000
    },
    {
        "time": 1689769825000,
        "value": 139.513,
        "_internal_originalTime": 1689769825000
    },
    {
        "time": 1689769827000,
        "value": 139.508,
        "_internal_originalTime": 1689769827000
    },
    {
        "time": 1689769828000,
        "value": 139.517,
        "_internal_originalTime": 1689769828000
    },
    {
        "time": 1689769829000,
        "value": 139.53,
        "_internal_originalTime": 1689769829000
    },
    {
        "time": 1689769830000,
        "value": 139.549,
        "_internal_originalTime": 1689769830000
    },
    {
        "time": 1689769831000,
        "value": 139.544,
        "_internal_originalTime": 1689769831000
    },
    {
        "time": 1689769832000,
        "value": 139.558,
        "_internal_originalTime": 1689769832000
    },
    {
        "time": 1689769834000,
        "value": 139.559,
        "_internal_originalTime": 1689769834000
    },
    {
        "time": 1689769835000,
        "value": 139.57,
        "_internal_originalTime": 1689769835000
    },
    {
        "time": 1689769836000,
        "value": 139.594,
        "_internal_originalTime": 1689769836000
    },
    {
        "time": 1689769837000,
        "value": 139.609,
        "_internal_originalTime": 1689769837000
    },
    {
        "time": 1689769838000,
        "value": 139.63,
        "_internal_originalTime": 1689769838000
    },
    {
        "time": 1689769840000,
        "value": 139.643,
        "_internal_originalTime": 1689769840000
    },
    {
        "time": 1689769841000,
        "value": 139.668,
        "_internal_originalTime": 1689769841000
    },
    {
        "time": 1689769842000,
        "value": 139.669,
        "_internal_originalTime": 1689769842000
    },
    {
        "time": 1689769843000,
        "value": 139.65,
        "_internal_originalTime": 1689769843000
    },
    {
        "time": 1689769844000,
        "value": 139.679,
        "_internal_originalTime": 1689769844000
    },
    {
        "time": 1689769845000,
        "value": 139.674,
        "_internal_originalTime": 1689769845000
    },
    {
        "time": 1689769846000,
        "value": 139.685,
        "_internal_originalTime": 1689769846000
    },
    {
        "time": 1689769847000,
        "value": 139.695,
        "_internal_originalTime": 1689769847000
    },
    {
        "time": 1689769849000,
        "value": 139.689,
        "_internal_originalTime": 1689769849000
    },
    {
        "time": 1689769850000,
        "value": 139.679,
        "_internal_originalTime": 1689769850000
    },
    {
        "time": 1689769851000,
        "value": 139.673,
        "_internal_originalTime": 1689769851000
    },
    {
        "time": 1689769852000,
        "value": 139.665,
        "_internal_originalTime": 1689769852000
    },
    {
        "time": 1689769854000,
        "value": 139.622,
        "_internal_originalTime": 1689769854000
    },
    {
        "time": 1689769855000,
        "value": 139.617,
        "_internal_originalTime": 1689769855000
    },
    {
        "time": 1689769856000,
        "value": 139.619,
        "_internal_originalTime": 1689769856000
    },
    {
        "time": 1689769857000,
        "value": 139.607,
        "_internal_originalTime": 1689769857000
    },
    {
        "time": 1689769859000,
        "value": 139.61,
        "_internal_originalTime": 1689769859000
    },
    {
        "time": 1689769861000,
        "value": 139.606,
        "_internal_originalTime": 1689769861000
    },
    {
        "time": 1689769862000,
        "value": 139.566,
        "_internal_originalTime": 1689769862000
    },
    {
        "time": 1689769864000,
        "value": 139.551,
        "_internal_originalTime": 1689769864000
    },
    {
        "time": 1689769865000,
        "value": 139.556,
        "_internal_originalTime": 1689769865000
    },
    {
        "time": 1689769866000,
        "value": 139.584,
        "_internal_originalTime": 1689769866000
    },
    {
        "time": 1689769867000,
        "value": 139.575,
        "_internal_originalTime": 1689769867000
    },
    {
        "time": 1689769868000,
        "value": 139.582,
        "_internal_originalTime": 1689769868000
    },
    {
        "time": 1689769869000,
        "value": 139.59,
        "_internal_originalTime": 1689769869000
    },
    {
        "time": 1689769870000,
        "value": 139.585,
        "_internal_originalTime": 1689769870000
    },
    {
        "time": 1689769872000,
        "value": 139.599,
        "_internal_originalTime": 1689769872000
    },
    {
        "time": 1689769873000,
        "value": 139.596,
        "_internal_originalTime": 1689769873000
    },
    {
        "time": 1689769874000,
        "value": 139.599,
        "_internal_originalTime": 1689769874000
    },
    {
        "time": 1689769875000,
        "value": 139.598,
        "_internal_originalTime": 1689769875000
    },
    {
        "time": 1689769877000,
        "value": 139.6,
        "_internal_originalTime": 1689769877000
    },
    {
        "time": 1689769878000,
        "value": 139.598,
        "_internal_originalTime": 1689769878000
    },
    {
        "time": 1689769879000,
        "value": 139.597,
        "_internal_originalTime": 1689769879000
    },
    {
        "time": 1689769881000,
        "value": 139.596,
        "_internal_originalTime": 1689769881000
    },
    {
        "time": 1689769882000,
        "value": 139.599,
        "_internal_originalTime": 1689769882000
    },
    {
        "time": 1689769883000,
        "value": 139.597,
        "_internal_originalTime": 1689769883000
    },
    {
        "time": 1689769885000,
        "value": 139.606,
        "_internal_originalTime": 1689769885000
    },
    {
        "time": 1689769886000,
        "value": 139.592,
        "_internal_originalTime": 1689769886000
    },
    {
        "time": 1689769887000,
        "value": 139.596,
        "_internal_originalTime": 1689769887000
    },
    {
        "time": 1689769890000,
        "value": 139.595,
        "_internal_originalTime": 1689769890000
    },
    {
        "time": 1689769891000,
        "value": 139.602,
        "_internal_originalTime": 1689769891000
    },
    {
        "time": 1689769892000,
        "value": 139.602,
        "_internal_originalTime": 1689769892000
    },
    {
        "time": 1689769893000,
        "value": 139.598,
        "_internal_originalTime": 1689769893000
    },
    {
        "time": 1689769894000,
        "value": 139.597,
        "_internal_originalTime": 1689769894000
    },
    {
        "time": 1689769896000,
        "value": 139.597,
        "_internal_originalTime": 1689769896000
    },
    {
        "time": 1689769897000,
        "value": 139.597,
        "_internal_originalTime": 1689769897000
    },
    {
        "time": 1689769899000,
        "value": 139.589,
        "_internal_originalTime": 1689769899000
    },
    {
        "time": 1689769900000,
        "value": 139.581,
        "_internal_originalTime": 1689769900000
    },
    {
        "time": 1689769901000,
        "value": 139.589,
        "_internal_originalTime": 1689769901000
    },
    {
        "time": 1689769902000,
        "value": 139.596,
        "_internal_originalTime": 1689769902000
    },
    {
        "time": 1689769904000,
        "value": 139.596,
        "_internal_originalTime": 1689769904000
    },
    {
        "time": 1689769906000,
        "value": 139.597,
        "_internal_originalTime": 1689769906000
    },
    {
        "time": 1689769907000,
        "value": 139.597,
        "_internal_originalTime": 1689769907000
    },
    {
        "time": 1689769908000,
        "value": 139.598,
        "_internal_originalTime": 1689769908000
    },
    {
        "time": 1689769910000,
        "value": 139.606,
        "_internal_originalTime": 1689769910000
    },
    {
        "time": 1689769911000,
        "value": 139.6,
        "_internal_originalTime": 1689769911000
    },
    {
        "time": 1689769912000,
        "value": 139.601,
        "_internal_originalTime": 1689769912000
    },
    {
        "time": 1689769913000,
        "value": 139.598,
        "_internal_originalTime": 1689769913000
    },
    {
        "time": 1689769915000,
        "value": 139.597,
        "_internal_originalTime": 1689769915000
    },
    {
        "time": 1689769916000,
        "value": 139.596,
        "_internal_originalTime": 1689769916000
    },
    {
        "time": 1689769919000,
        "value": 139.573,
        "_internal_originalTime": 1689769919000
    },
    {
        "time": 1689769920000,
        "value": 139.564,
        "_internal_originalTime": 1689769920000
    },
    {
        "time": 1689769921000,
        "value": 139.567,
        "_internal_originalTime": 1689769921000
    },
    {
        "time": 1689769922000,
        "value": 139.57,
        "_internal_originalTime": 1689769922000
    },
    {
        "time": 1689769923000,
        "value": 139.579,
        "_internal_originalTime": 1689769923000
    },
    {
        "time": 1689769924000,
        "value": 139.577,
        "_internal_originalTime": 1689769924000
    },
    {
        "time": 1689769926000,
        "value": 139.574,
        "_internal_originalTime": 1689769926000
    },
    {
        "time": 1689769928000,
        "value": 139.574,
        "_internal_originalTime": 1689769928000
    },
    {
        "time": 1689769929000,
        "value": 139.571,
        "_internal_originalTime": 1689769929000
    },
    {
        "time": 1689769930000,
        "value": 139.561,
        "_internal_originalTime": 1689769930000
    },
    {
        "time": 1689769931000,
        "value": 139.563,
        "_internal_originalTime": 1689769931000
    },
    {
        "time": 1689769933000,
        "value": 139.557,
        "_internal_originalTime": 1689769933000
    },
    {
        "time": 1689769935000,
        "value": 139.566,
        "_internal_originalTime": 1689769935000
    },
    {
        "time": 1689769936000,
        "value": 139.561,
        "_internal_originalTime": 1689769936000
    },
    {
        "time": 1689769937000,
        "value": 139.56,
        "_internal_originalTime": 1689769937000
    },
    {
        "time": 1689769938000,
        "value": 139.558,
        "_internal_originalTime": 1689769938000
    },
    {
        "time": 1689769940000,
        "value": 139.565,
        "_internal_originalTime": 1689769940000
    },
    {
        "time": 1689769941000,
        "value": 139.551,
        "_internal_originalTime": 1689769941000
    },
    {
        "time": 1689769942000,
        "value": 139.548,
        "_internal_originalTime": 1689769942000
    },
    {
        "time": 1689769943000,
        "value": 139.539,
        "_internal_originalTime": 1689769943000
    },
    {
        "time": 1689769945000,
        "value": 139.534,
        "_internal_originalTime": 1689769945000
    },
    {
        "time": 1689769946000,
        "value": 139.543,
        "_internal_originalTime": 1689769946000
    },
    {
        "time": 1689769947000,
        "value": 139.54,
        "_internal_originalTime": 1689769947000
    },
    {
        "time": 1689769950000,
        "value": 139.543,
        "_internal_originalTime": 1689769950000
    },
    {
        "time": 1689769952000,
        "value": 139.54,
        "_internal_originalTime": 1689769952000
    },
    {
        "time": 1689769953000,
        "value": 139.536,
        "_internal_originalTime": 1689769953000
    },
    {
        "time": 1689769954000,
        "value": 139.543,
        "_internal_originalTime": 1689769954000
    },
    {
        "time": 1689769955000,
        "value": 139.54,
        "_internal_originalTime": 1689769955000
    },
    {
        "time": 1689769957000,
        "value": 139.543,
        "_internal_originalTime": 1689769957000
    },
    {
        "time": 1689769958000,
        "value": 139.54,
        "_internal_originalTime": 1689769958000
    },
    {
        "time": 1689769959000,
        "value": 139.543,
        "_internal_originalTime": 1689769959000
    },
    {
        "time": 1689769961000,
        "value": 139.543,
        "_internal_originalTime": 1689769961000
    },
    {
        "time": 1689769962000,
        "value": 139.539,
        "_internal_originalTime": 1689769962000
    },
    {
        "time": 1689769963000,
        "value": 139.551,
        "_internal_originalTime": 1689769963000
    },
    {
        "time": 1689769965000,
        "value": 139.549,
        "_internal_originalTime": 1689769965000
    },
    {
        "time": 1689769967000,
        "value": 139.554,
        "_internal_originalTime": 1689769967000
    },
    {
        "time": 1689769968000,
        "value": 139.552,
        "_internal_originalTime": 1689769968000
    },
    {
        "time": 1689769970000,
        "value": 139.55,
        "_internal_originalTime": 1689769970000
    },
    {
        "time": 1689769971000,
        "value": 139.568,
        "_internal_originalTime": 1689769971000
    },
    {
        "time": 1689769972000,
        "value": 139.569,
        "_internal_originalTime": 1689769972000
    },
    {
        "time": 1689769973000,
        "value": 139.571,
        "_internal_originalTime": 1689769973000
    },
    {
        "time": 1689769975000,
        "value": 139.577,
        "_internal_originalTime": 1689769975000
    },
    {
        "time": 1689769976000,
        "value": 139.588,
        "_internal_originalTime": 1689769976000
    },
    {
        "time": 1689769977000,
        "value": 139.596,
        "_internal_originalTime": 1689769977000
    },
    {
        "time": 1689769978000,
        "value": 139.598,
        "_internal_originalTime": 1689769978000
    },
    {
        "time": 1689769980000,
        "value": 139.607,
        "_internal_originalTime": 1689769980000
    },
    {
        "time": 1689769981000,
        "value": 139.606,
        "_internal_originalTime": 1689769981000
    },
    {
        "time": 1689769982000,
        "value": 139.606,
        "_internal_originalTime": 1689769982000
    },
    {
        "time": 1689769983000,
        "value": 139.608,
        "_internal_originalTime": 1689769983000
    },
    {
        "time": 1689769985000,
        "value": 139.605,
        "_internal_originalTime": 1689769985000
    },
    {
        "time": 1689769986000,
        "value": 139.62,
        "_internal_originalTime": 1689769986000
    },
    {
        "time": 1689769987000,
        "value": 139.617,
        "_internal_originalTime": 1689769987000
    },
    {
        "time": 1689769988000,
        "value": 139.626,
        "_internal_originalTime": 1689769988000
    },
    {
        "time": 1689769989000,
        "value": 139.64,
        "_internal_originalTime": 1689769989000
    },
    {
        "time": 1689769991000,
        "value": 139.639,
        "_internal_originalTime": 1689769991000
    },
    {
        "time": 1689769992000,
        "value": 139.618,
        "_internal_originalTime": 1689769992000
    },
    {
        "time": 1689769993000,
        "value": 139.616,
        "_internal_originalTime": 1689769993000
    },
    {
        "time": 1689769995000,
        "value": 139.595,
        "_internal_originalTime": 1689769995000
    },
    {
        "time": 1689769997000,
        "value": 139.6,
        "_internal_originalTime": 1689769997000
    },
    {
        "time": 1689769998000,
        "value": 139.601,
        "_internal_originalTime": 1689769998000
    },
    {
        "time": 1689769999000,
        "value": 139.602,
        "_internal_originalTime": 1689769999000
    },
    {
        "time": 1689770000000,
        "value": 139.59,
        "_internal_originalTime": 1689770000000
    },
    {
        "time": 1689770002000,
        "value": 139.588,
        "_internal_originalTime": 1689770002000
    },
    {
        "time": 1689770003000,
        "value": 139.583,
        "_internal_originalTime": 1689770003000
    },
    {
        "time": 1689770004000,
        "value": 139.583,
        "_internal_originalTime": 1689770004000
    },
    {
        "time": 1689770006000,
        "value": 139.563,
        "_internal_originalTime": 1689770006000
    },
    {
        "time": 1689770007000,
        "value": 139.561,
        "_internal_originalTime": 1689770007000
    },
    {
        "time": 1689770009000,
        "value": 139.562,
        "_internal_originalTime": 1689770009000
    },
    {
        "time": 1689770010000,
        "value": 139.563,
        "_internal_originalTime": 1689770010000
    },
    {
        "time": 1689770012000,
        "value": 139.558,
        "_internal_originalTime": 1689770012000
    },
    {
        "time": 1689770014000,
        "value": 139.55,
        "_internal_originalTime": 1689770014000
    },
    {
        "time": 1689770015000,
        "value": 139.558,
        "_internal_originalTime": 1689770015000
    },
    {
        "time": 1689770016000,
        "value": 139.558,
        "_internal_originalTime": 1689770016000
    },
    {
        "time": 1689770018000,
        "value": 139.558,
        "_internal_originalTime": 1689770018000
    },
    {
        "time": 1689770019000,
        "value": 139.551,
        "_internal_originalTime": 1689770019000
    },
    {
        "time": 1689770021000,
        "value": 139.551,
        "_internal_originalTime": 1689770021000
    },
    {
        "time": 1689770022000,
        "value": 139.552,
        "_internal_originalTime": 1689770022000
    },
    {
        "time": 1689770023000,
        "value": 139.562,
        "_internal_originalTime": 1689770023000
    },
    {
        "time": 1689770024000,
        "value": 139.562,
        "_internal_originalTime": 1689770024000
    },
    {
        "time": 1689770025000,
        "value": 139.561,
        "_internal_originalTime": 1689770025000
    },
    {
        "time": 1689770027000,
        "value": 139.563,
        "_internal_originalTime": 1689770027000
    },
    {
        "time": 1689770028000,
        "value": 139.561,
        "_internal_originalTime": 1689770028000
    },
    {
        "time": 1689770030000,
        "value": 139.558,
        "_internal_originalTime": 1689770030000
    },
    {
        "time": 1689770031000,
        "value": 139.564,
        "_internal_originalTime": 1689770031000
    },
    {
        "time": 1689770032000,
        "value": 139.56,
        "_internal_originalTime": 1689770032000
    },
    {
        "time": 1689770034000,
        "value": 139.563,
        "_internal_originalTime": 1689770034000
    },
    {
        "time": 1689770035000,
        "value": 139.56,
        "_internal_originalTime": 1689770035000
    },
    {
        "time": 1689770037000,
        "value": 139.555,
        "_internal_originalTime": 1689770037000
    },
    {
        "time": 1689770038000,
        "value": 139.541,
        "_internal_originalTime": 1689770038000
    },
    {
        "time": 1689770040000,
        "value": 139.54,
        "_internal_originalTime": 1689770040000
    },
    {
        "time": 1689770041000,
        "value": 139.533,
        "_internal_originalTime": 1689770041000
    },
    {
        "time": 1689770042000,
        "value": 139.53,
        "_internal_originalTime": 1689770042000
    },
    {
        "time": 1689770043000,
        "value": 139.527,
        "_internal_originalTime": 1689770043000
    },
    {
        "time": 1689770045000,
        "value": 139.534,
        "_internal_originalTime": 1689770045000
    },
    {
        "time": 1689770046000,
        "value": 139.534,
        "_internal_originalTime": 1689770046000
    },
    {
        "time": 1689770047000,
        "value": 139.53,
        "_internal_originalTime": 1689770047000
    },
    {
        "time": 1689770049000,
        "value": 139.528,
        "_internal_originalTime": 1689770049000
    },
    {
        "time": 1689770050000,
        "value": 139.527,
        "_internal_originalTime": 1689770050000
    },
    {
        "time": 1689770051000,
        "value": 139.528,
        "_internal_originalTime": 1689770051000
    },
    {
        "time": 1689770052000,
        "value": 139.528,
        "_internal_originalTime": 1689770052000
    },
    {
        "time": 1689770055000,
        "value": 139.535,
        "_internal_originalTime": 1689770055000
    },
    {
        "time": 1689770056000,
        "value": 139.546,
        "_internal_originalTime": 1689770056000
    },
    {
        "time": 1689770057000,
        "value": 139.56,
        "_internal_originalTime": 1689770057000
    },
    {
        "time": 1689770058000,
        "value": 139.563,
        "_internal_originalTime": 1689770058000
    },
    {
        "time": 1689770060000,
        "value": 139.561,
        "_internal_originalTime": 1689770060000
    },
    {
        "time": 1689770061000,
        "value": 139.562,
        "_internal_originalTime": 1689770061000
    },
    {
        "time": 1689770063000,
        "value": 139.562,
        "_internal_originalTime": 1689770063000
    },
    {
        "time": 1689770064000,
        "value": 139.567,
        "_internal_originalTime": 1689770064000
    },
    {
        "time": 1689770066000,
        "value": 139.554,
        "_internal_originalTime": 1689770066000
    },
    {
        "time": 1689770067000,
        "value": 139.559,
        "_internal_originalTime": 1689770067000
    },
    {
        "time": 1689770068000,
        "value": 139.557,
        "_internal_originalTime": 1689770068000
    },
    {
        "time": 1689770069000,
        "value": 139.559,
        "_internal_originalTime": 1689770069000
    },
    {
        "time": 1689770070000,
        "value": 139.558,
        "_internal_originalTime": 1689770070000
    },
    {
        "time": 1689770071000,
        "value": 139.559,
        "_internal_originalTime": 1689770071000
    },
    {
        "time": 1689770072000,
        "value": 139.56,
        "_internal_originalTime": 1689770072000
    },
    {
        "time": 1689770073000,
        "value": 139.556,
        "_internal_originalTime": 1689770073000
    },
    {
        "time": 1689770074000,
        "value": 139.56,
        "_internal_originalTime": 1689770074000
    },
    {
        "time": 1689770077000,
        "value": 139.556,
        "_internal_originalTime": 1689770077000
    },
    {
        "time": 1689770078000,
        "value": 139.553,
        "_internal_originalTime": 1689770078000
    },
    {
        "time": 1689770079000,
        "value": 139.546,
        "_internal_originalTime": 1689770079000
    },
    {
        "time": 1689770081000,
        "value": 139.543,
        "_internal_originalTime": 1689770081000
    },
    {
        "time": 1689770082000,
        "value": 139.539,
        "_internal_originalTime": 1689770082000
    },
    {
        "time": 1689770083000,
        "value": 139.541,
        "_internal_originalTime": 1689770083000
    },
    {
        "time": 1689770084000,
        "value": 139.543,
        "_internal_originalTime": 1689770084000
    },
    {
        "time": 1689770085000,
        "value": 139.544,
        "_internal_originalTime": 1689770085000
    },
    {
        "time": 1689770086000,
        "value": 139.546,
        "_internal_originalTime": 1689770086000
    },
    {
        "time": 1689770087000,
        "value": 139.542,
        "_internal_originalTime": 1689770087000
    },
    {
        "time": 1689770089000,
        "value": 139.546,
        "_internal_originalTime": 1689770089000
    },
    {
        "time": 1689770090000,
        "value": 139.549,
        "_internal_originalTime": 1689770090000
    },
    {
        "time": 1689770091000,
        "value": 139.551,
        "_internal_originalTime": 1689770091000
    },
    {
        "time": 1689770093000,
        "value": 139.544,
        "_internal_originalTime": 1689770093000
    },
    {
        "time": 1689770094000,
        "value": 139.546,
        "_internal_originalTime": 1689770094000
    },
    {
        "time": 1689770095000,
        "value": 139.552,
        "_internal_originalTime": 1689770095000
    },
    {
        "time": 1689770097000,
        "value": 139.55,
        "_internal_originalTime": 1689770097000
    },
    {
        "time": 1689770098000,
        "value": 139.551,
        "_internal_originalTime": 1689770098000
    },
    {
        "time": 1689770100000,
        "value": 139.552,
        "_internal_originalTime": 1689770100000
    },
    {
        "time": 1689770101000,
        "value": 139.559,
        "_internal_originalTime": 1689770101000
    },
    {
        "time": 1689770103000,
        "value": 139.557,
        "_internal_originalTime": 1689770103000
    },
    {
        "time": 1689770104000,
        "value": 139.552,
        "_internal_originalTime": 1689770104000
    },
    {
        "time": 1689770105000,
        "value": 139.557,
        "_internal_originalTime": 1689770105000
    },
    {
        "time": 1689770107000,
        "value": 139.559,
        "_internal_originalTime": 1689770107000
    },
    {
        "time": 1689770109000,
        "value": 139.552,
        "_internal_originalTime": 1689770109000
    },
    {
        "time": 1689770110000,
        "value": 139.564,
        "_internal_originalTime": 1689770110000
    },
    {
        "time": 1689770111000,
        "value": 139.569,
        "_internal_originalTime": 1689770111000
    },
    {
        "time": 1689770113000,
        "value": 139.564,
        "_internal_originalTime": 1689770113000
    },
    {
        "time": 1689770115000,
        "value": 139.578,
        "_internal_originalTime": 1689770115000
    },
    {
        "time": 1689770116000,
        "value": 139.578,
        "_internal_originalTime": 1689770116000
    },
    {
        "time": 1689770117000,
        "value": 139.576,
        "_internal_originalTime": 1689770117000
    },
    {
        "time": 1689770118000,
        "value": 139.583,
        "_internal_originalTime": 1689770118000
    },
    {
        "time": 1689770120000,
        "value": 139.578,
        "_internal_originalTime": 1689770120000
    },
    {
        "time": 1689770121000,
        "value": 139.583,
        "_internal_originalTime": 1689770121000
    },
    {
        "time": 1689770122000,
        "value": 139.58,
        "_internal_originalTime": 1689770122000
    },
    {
        "time": 1689770124000,
        "value": 139.576,
        "_internal_originalTime": 1689770124000
    },
    {
        "time": 1689770125000,
        "value": 139.577,
        "_internal_originalTime": 1689770125000
    },
    {
        "time": 1689770126000,
        "value": 139.567,
        "_internal_originalTime": 1689770126000
    },
    {
        "time": 1689770128000,
        "value": 139.554,
        "_internal_originalTime": 1689770128000
    },
    {
        "time": 1689770130000,
        "value": 139.574,
        "_internal_originalTime": 1689770130000
    },
    {
        "time": 1689770131000,
        "value": 139.57,
        "_internal_originalTime": 1689770131000
    },
    {
        "time": 1689770132000,
        "value": 139.573,
        "_internal_originalTime": 1689770132000
    },
    {
        "time": 1689770133000,
        "value": 139.571,
        "_internal_originalTime": 1689770133000
    },
    {
        "time": 1689770135000,
        "value": 139.57,
        "_internal_originalTime": 1689770135000
    },
    {
        "time": 1689770136000,
        "value": 139.569,
        "_internal_originalTime": 1689770136000
    },
    {
        "time": 1689770137000,
        "value": 139.564,
        "_internal_originalTime": 1689770137000
    },
    {
        "time": 1689770138000,
        "value": 139.56,
        "_internal_originalTime": 1689770138000
    },
    {
        "time": 1689770139000,
        "value": 139.561,
        "_internal_originalTime": 1689770139000
    },
    {
        "time": 1689770140000,
        "value": 139.561,
        "_internal_originalTime": 1689770140000
    },
    {
        "time": 1689770142000,
        "value": 139.572,
        "_internal_originalTime": 1689770142000
    },
    {
        "time": 1689770144000,
        "value": 139.57,
        "_internal_originalTime": 1689770144000
    },
    {
        "time": 1689770145000,
        "value": 139.582,
        "_internal_originalTime": 1689770145000
    },
    {
        "time": 1689770146000,
        "value": 139.594,
        "_internal_originalTime": 1689770146000
    },
    {
        "time": 1689770148000,
        "value": 139.588,
        "_internal_originalTime": 1689770148000
    },
    {
        "time": 1689770149000,
        "value": 139.595,
        "_internal_originalTime": 1689770149000
    },
    {
        "time": 1689770151000,
        "value": 139.592,
        "_internal_originalTime": 1689770151000
    },
    {
        "time": 1689770152000,
        "value": 139.596,
        "_internal_originalTime": 1689770152000
    },
    {
        "time": 1689770153000,
        "value": 139.588,
        "_internal_originalTime": 1689770153000
    },
    {
        "time": 1689770154000,
        "value": 139.592,
        "_internal_originalTime": 1689770154000
    },
    {
        "time": 1689770156000,
        "value": 139.598,
        "_internal_originalTime": 1689770156000
    },
    {
        "time": 1689770157000,
        "value": 139.589,
        "_internal_originalTime": 1689770157000
    },
    {
        "time": 1689770159000,
        "value": 139.591,
        "_internal_originalTime": 1689770159000
    },
    {
        "time": 1689770160000,
        "value": 139.59,
        "_internal_originalTime": 1689770160000
    },
    {
        "time": 1689770161000,
        "value": 139.583,
        "_internal_originalTime": 1689770161000
    },
    {
        "time": 1689770162000,
        "value": 139.585,
        "_internal_originalTime": 1689770162000
    },
    {
        "time": 1689770163000,
        "value": 139.582,
        "_internal_originalTime": 1689770163000
    },
    {
        "time": 1689770164000,
        "value": 139.583,
        "_internal_originalTime": 1689770164000
    },
    {
        "time": 1689770166000,
        "value": 139.579,
        "_internal_originalTime": 1689770166000
    },
    {
        "time": 1689770167000,
        "value": 139.579,
        "_internal_originalTime": 1689770167000
    },
    {
        "time": 1689770168000,
        "value": 139.587,
        "_internal_originalTime": 1689770168000
    },
    {
        "time": 1689770169000,
        "value": 139.568,
        "_internal_originalTime": 1689770169000
    },
    {
        "time": 1689770171000,
        "value": 139.57,
        "_internal_originalTime": 1689770171000
    },
    {
        "time": 1689770174000,
        "value": 139.571,
        "_internal_originalTime": 1689770174000
    },
    {
        "time": 1689770175000,
        "value": 139.577,
        "_internal_originalTime": 1689770175000
    },
    {
        "time": 1689770176000,
        "value": 139.573,
        "_internal_originalTime": 1689770176000
    },
    {
        "time": 1689770178000,
        "value": 139.572,
        "_internal_originalTime": 1689770178000
    },
    {
        "time": 1689770179000,
        "value": 139.569,
        "_internal_originalTime": 1689770179000
    },
    {
        "time": 1689770180000,
        "value": 139.569,
        "_internal_originalTime": 1689770180000
    },
    {
        "time": 1689770181000,
        "value": 139.568,
        "_internal_originalTime": 1689770181000
    },
    {
        "time": 1689770183000,
        "value": 139.571,
        "_internal_originalTime": 1689770183000
    },
    {
        "time": 1689770185000,
        "value": 139.56,
        "_internal_originalTime": 1689770185000
    },
    {
        "time": 1689770186000,
        "value": 139.564,
        "_internal_originalTime": 1689770186000
    },
    {
        "time": 1689770188000,
        "value": 139.573,
        "_internal_originalTime": 1689770188000
    },
    {
        "time": 1689770189000,
        "value": 139.561,
        "_internal_originalTime": 1689770189000
    },
    {
        "time": 1689770190000,
        "value": 139.557,
        "_internal_originalTime": 1689770190000
    },
    {
        "time": 1689770191000,
        "value": 139.542,
        "_internal_originalTime": 1689770191000
    },
    {
        "time": 1689770192000,
        "value": 139.542,
        "_internal_originalTime": 1689770192000
    },
    {
        "time": 1689770194000,
        "value": 139.551,
        "_internal_originalTime": 1689770194000
    },
    {
        "time": 1689770195000,
        "value": 139.556,
        "_internal_originalTime": 1689770195000
    },
    {
        "time": 1689770196000,
        "value": 139.567,
        "_internal_originalTime": 1689770196000
    },
    {
        "time": 1689770198000,
        "value": 139.578,
        "_internal_originalTime": 1689770198000
    },
    {
        "time": 1689770199000,
        "value": 139.577,
        "_internal_originalTime": 1689770199000
    },
    {
        "time": 1689770200000,
        "value": 139.579,
        "_internal_originalTime": 1689770200000
    },
    {
        "time": 1689770202000,
        "value": 139.573,
        "_internal_originalTime": 1689770202000
    },
    {
        "time": 1689770203000,
        "value": 139.561,
        "_internal_originalTime": 1689770203000
    },
    {
        "time": 1689770205000,
        "value": 139.565,
        "_internal_originalTime": 1689770205000
    },
    {
        "time": 1689770206000,
        "value": 139.567,
        "_internal_originalTime": 1689770206000
    },
    {
        "time": 1689770207000,
        "value": 139.568,
        "_internal_originalTime": 1689770207000
    },
    {
        "time": 1689770208000,
        "value": 139.551,
        "_internal_originalTime": 1689770208000
    },
    {
        "time": 1689770209000,
        "value": 139.552,
        "_internal_originalTime": 1689770209000
    },
    {
        "time": 1689770211000,
        "value": 139.551,
        "_internal_originalTime": 1689770211000
    },
    {
        "time": 1689770212000,
        "value": 139.556,
        "_internal_originalTime": 1689770212000
    },
    {
        "time": 1689770213000,
        "value": 139.553,
        "_internal_originalTime": 1689770213000
    },
    {
        "time": 1689770215000,
        "value": 139.545,
        "_internal_originalTime": 1689770215000
    },
    {
        "time": 1689770216000,
        "value": 139.548,
        "_internal_originalTime": 1689770216000
    },
    {
        "time": 1689770217000,
        "value": 139.54,
        "_internal_originalTime": 1689770217000
    },
    {
        "time": 1689770218000,
        "value": 139.543,
        "_internal_originalTime": 1689770218000
    },
    {
        "time": 1689770220000,
        "value": 139.537,
        "_internal_originalTime": 1689770220000
    },
    {
        "time": 1689770221000,
        "value": 139.545,
        "_internal_originalTime": 1689770221000
    },
    {
        "time": 1689770222000,
        "value": 139.545,
        "_internal_originalTime": 1689770222000
    },
    {
        "time": 1689770223000,
        "value": 139.551,
        "_internal_originalTime": 1689770223000
    },
    {
        "time": 1689770224000,
        "value": 139.548,
        "_internal_originalTime": 1689770224000
    },
    {
        "time": 1689770226000,
        "value": 139.541,
        "_internal_originalTime": 1689770226000
    },
    {
        "time": 1689770227000,
        "value": 139.544,
        "_internal_originalTime": 1689770227000
    },
    {
        "time": 1689770229000,
        "value": 139.537,
        "_internal_originalTime": 1689770229000
    },
    {
        "time": 1689770231000,
        "value": 139.531,
        "_internal_originalTime": 1689770231000
    },
    {
        "time": 1689770232000,
        "value": 139.532,
        "_internal_originalTime": 1689770232000
    },
    {
        "time": 1689770234000,
        "value": 139.521,
        "_internal_originalTime": 1689770234000
    },
    {
        "time": 1689770235000,
        "value": 139.524,
        "_internal_originalTime": 1689770235000
    },
    {
        "time": 1689770237000,
        "value": 139.523,
        "_internal_originalTime": 1689770237000
    },
    {
        "time": 1689770238000,
        "value": 139.518,
        "_internal_originalTime": 1689770238000
    },
    {
        "time": 1689770240000,
        "value": 139.521,
        "_internal_originalTime": 1689770240000
    },
    {
        "time": 1689770241000,
        "value": 139.502,
        "_internal_originalTime": 1689770241000
    },
    {
        "time": 1689770242000,
        "value": 139.503,
        "_internal_originalTime": 1689770242000
    },
    {
        "time": 1689770244000,
        "value": 139.496,
        "_internal_originalTime": 1689770244000
    },
    {
        "time": 1689770245000,
        "value": 139.505,
        "_internal_originalTime": 1689770245000
    },
    {
        "time": 1689770246000,
        "value": 139.506,
        "_internal_originalTime": 1689770246000
    },
    {
        "time": 1689770248000,
        "value": 139.5,
        "_internal_originalTime": 1689770248000
    },
    {
        "time": 1689770250000,
        "value": 139.495,
        "_internal_originalTime": 1689770250000
    },
    {
        "time": 1689770251000,
        "value": 139.495,
        "_internal_originalTime": 1689770251000
    },
    {
        "time": 1689770252000,
        "value": 139.493,
        "_internal_originalTime": 1689770252000
    },
    {
        "time": 1689770254000,
        "value": 139.477,
        "_internal_originalTime": 1689770254000
    },
    {
        "time": 1689770255000,
        "value": 139.455,
        "_internal_originalTime": 1689770255000
    },
    {
        "time": 1689770256000,
        "value": 139.464,
        "_internal_originalTime": 1689770256000
    },
    {
        "time": 1689770257000,
        "value": 139.465,
        "_internal_originalTime": 1689770257000
    },
    {
        "time": 1689770258000,
        "value": 139.466,
        "_internal_originalTime": 1689770258000
    },
    {
        "time": 1689770260000,
        "value": 139.469,
        "_internal_originalTime": 1689770260000
    },
    {
        "time": 1689770261000,
        "value": 139.46,
        "_internal_originalTime": 1689770261000
    },
    {
        "time": 1689770262000,
        "value": 139.466,
        "_internal_originalTime": 1689770262000
    },
    {
        "time": 1689770264000,
        "value": 139.463,
        "_internal_originalTime": 1689770264000
    },
    {
        "time": 1689770265000,
        "value": 139.453,
        "_internal_originalTime": 1689770265000
    },
    {
        "time": 1689770267000,
        "value": 139.45,
        "_internal_originalTime": 1689770267000
    },
    {
        "time": 1689770268000,
        "value": 139.447,
        "_internal_originalTime": 1689770268000
    },
    {
        "time": 1689770270000,
        "value": 139.422,
        "_internal_originalTime": 1689770270000
    },
    {
        "time": 1689770271000,
        "value": 139.428,
        "_internal_originalTime": 1689770271000
    },
    {
        "time": 1689770272000,
        "value": 139.432,
        "_internal_originalTime": 1689770272000
    },
    {
        "time": 1689770273000,
        "value": 139.437,
        "_internal_originalTime": 1689770273000
    },
    {
        "time": 1689770274000,
        "value": 139.452,
        "_internal_originalTime": 1689770274000
    },
    {
        "time": 1689770275000,
        "value": 139.455,
        "_internal_originalTime": 1689770275000
    },
    {
        "time": 1689770277000,
        "value": 139.44,
        "_internal_originalTime": 1689770277000
    },
    {
        "time": 1689770279000,
        "value": 139.44,
        "_internal_originalTime": 1689770279000
    },
    {
        "time": 1689770280000,
        "value": 139.444,
        "_internal_originalTime": 1689770280000
    },
    {
        "time": 1689770281000,
        "value": 139.446,
        "_internal_originalTime": 1689770281000
    },
    {
        "time": 1689770282000,
        "value": 139.447,
        "_internal_originalTime": 1689770282000
    },
    {
        "time": 1689770283000,
        "value": 139.449,
        "_internal_originalTime": 1689770283000
    },
    {
        "time": 1689770284000,
        "value": 139.44,
        "_internal_originalTime": 1689770284000
    },
    {
        "time": 1689770286000,
        "value": 139.446,
        "_internal_originalTime": 1689770286000
    },
    {
        "time": 1689770287000,
        "value": 139.448,
        "_internal_originalTime": 1689770287000
    },
    {
        "time": 1689770289000,
        "value": 139.435,
        "_internal_originalTime": 1689770289000
    },
    {
        "time": 1689770290000,
        "value": 139.437,
        "_internal_originalTime": 1689770290000
    },
    {
        "time": 1689770291000,
        "value": 139.426,
        "_internal_originalTime": 1689770291000
    },
    {
        "time": 1689770292000,
        "value": 139.425,
        "_internal_originalTime": 1689770292000
    },
    {
        "time": 1689770293000,
        "value": 139.417,
        "_internal_originalTime": 1689770293000
    },
    {
        "time": 1689770295000,
        "value": 139.419,
        "_internal_originalTime": 1689770295000
    },
    {
        "time": 1689770296000,
        "value": 139.418,
        "_internal_originalTime": 1689770296000
    },
    {
        "time": 1689770297000,
        "value": 139.409,
        "_internal_originalTime": 1689770297000
    },
    {
        "time": 1689770298000,
        "value": 139.389,
        "_internal_originalTime": 1689770298000
    },
    {
        "time": 1689770300000,
        "value": 139.401,
        "_internal_originalTime": 1689770300000
    },
    {
        "time": 1689770301000,
        "value": 139.404,
        "_internal_originalTime": 1689770301000
    },
    {
        "time": 1689770302000,
        "value": 139.409,
        "_internal_originalTime": 1689770302000
    },
    {
        "time": 1689770303000,
        "value": 139.427,
        "_internal_originalTime": 1689770303000
    },
    {
        "time": 1689770304000,
        "value": 139.424,
        "_internal_originalTime": 1689770304000
    },
    {
        "time": 1689770306000,
        "value": 139.43,
        "_internal_originalTime": 1689770306000
    },
    {
        "time": 1689770307000,
        "value": 139.436,
        "_internal_originalTime": 1689770307000
    },
    {
        "time": 1689770309000,
        "value": 139.436,
        "_internal_originalTime": 1689770309000
    },
    {
        "time": 1689770310000,
        "value": 139.434,
        "_internal_originalTime": 1689770310000
    },
    {
        "time": 1689770311000,
        "value": 139.431,
        "_internal_originalTime": 1689770311000
    },
    {
        "time": 1689770312000,
        "value": 139.432,
        "_internal_originalTime": 1689770312000
    },
    {
        "time": 1689770314000,
        "value": 139.449,
        "_internal_originalTime": 1689770314000
    },
    {
        "time": 1689770315000,
        "value": 139.455,
        "_internal_originalTime": 1689770315000
    },
    {
        "time": 1689770317000,
        "value": 139.46,
        "_internal_originalTime": 1689770317000
    },
    {
        "time": 1689770318000,
        "value": 139.468,
        "_internal_originalTime": 1689770318000
    },
    {
        "time": 1689770319000,
        "value": 139.468,
        "_internal_originalTime": 1689770319000
    },
    {
        "time": 1689770320000,
        "value": 139.468,
        "_internal_originalTime": 1689770320000
    },
    {
        "time": 1689770322000,
        "value": 139.474,
        "_internal_originalTime": 1689770322000
    },
    {
        "time": 1689770323000,
        "value": 139.484,
        "_internal_originalTime": 1689770323000
    },
    {
        "time": 1689770325000,
        "value": 139.487,
        "_internal_originalTime": 1689770325000
    },
    {
        "time": 1689770326000,
        "value": 139.49,
        "_internal_originalTime": 1689770326000
    },
    {
        "time": 1689770328000,
        "value": 139.474,
        "_internal_originalTime": 1689770328000
    },
    {
        "time": 1689770330000,
        "value": 139.474,
        "_internal_originalTime": 1689770330000
    },
    {
        "time": 1689770332000,
        "value": 139.481,
        "_internal_originalTime": 1689770332000
    },
    {
        "time": 1689770333000,
        "value": 139.478,
        "_internal_originalTime": 1689770333000
    },
    {
        "time": 1689770334000,
        "value": 139.475,
        "_internal_originalTime": 1689770334000
    },
    {
        "time": 1689770336000,
        "value": 139.477,
        "_internal_originalTime": 1689770336000
    },
    {
        "time": 1689770337000,
        "value": 139.476,
        "_internal_originalTime": 1689770337000
    },
    {
        "time": 1689770339000,
        "value": 139.495,
        "_internal_originalTime": 1689770339000
    },
    {
        "time": 1689770340000,
        "value": 139.501,
        "_internal_originalTime": 1689770340000
    },
    {
        "time": 1689770341000,
        "value": 139.509,
        "_internal_originalTime": 1689770341000
    },
    {
        "time": 1689770342000,
        "value": 139.502,
        "_internal_originalTime": 1689770342000
    },
    {
        "time": 1689770343000,
        "value": 139.493,
        "_internal_originalTime": 1689770343000
    },
    {
        "time": 1689770344000,
        "value": 139.494,
        "_internal_originalTime": 1689770344000
    },
    {
        "time": 1689770346000,
        "value": 139.497,
        "_internal_originalTime": 1689770346000
    },
    {
        "time": 1689770347000,
        "value": 139.491,
        "_internal_originalTime": 1689770347000
    },
    {
        "time": 1689770348000,
        "value": 139.488,
        "_internal_originalTime": 1689770348000
    },
    {
        "time": 1689770350000,
        "value": 139.489,
        "_internal_originalTime": 1689770350000
    },
    {
        "time": 1689770351000,
        "value": 139.48,
        "_internal_originalTime": 1689770351000
    },
    {
        "time": 1689770352000,
        "value": 139.487,
        "_internal_originalTime": 1689770352000
    },
    {
        "time": 1689770354000,
        "value": 139.486,
        "_internal_originalTime": 1689770354000
    },
    {
        "time": 1689770355000,
        "value": 139.49,
        "_internal_originalTime": 1689770355000
    },
    {
        "time": 1689770357000,
        "value": 139.483,
        "_internal_originalTime": 1689770357000
    },
    {
        "time": 1689770358000,
        "value": 139.492,
        "_internal_originalTime": 1689770358000
    },
    {
        "time": 1689770359000,
        "value": 139.492,
        "_internal_originalTime": 1689770359000
    },
    {
        "time": 1689770361000,
        "value": 139.492,
        "_internal_originalTime": 1689770361000
    },
    {
        "time": 1689770362000,
        "value": 139.496,
        "_internal_originalTime": 1689770362000
    },
    {
        "time": 1689770363000,
        "value": 139.503,
        "_internal_originalTime": 1689770363000
    },
    {
        "time": 1689770365000,
        "value": 139.497,
        "_internal_originalTime": 1689770365000
    },
    {
        "time": 1689770366000,
        "value": 139.511,
        "_internal_originalTime": 1689770366000
    },
    {
        "time": 1689770369000,
        "value": 139.508,
        "_internal_originalTime": 1689770369000
    },
    {
        "time": 1689770371000,
        "value": 139.515,
        "_internal_originalTime": 1689770371000
    },
    {
        "time": 1689770372000,
        "value": 139.531,
        "_internal_originalTime": 1689770372000
    },
    {
        "time": 1689770374000,
        "value": 139.529,
        "_internal_originalTime": 1689770374000
    },
    {
        "time": 1689770375000,
        "value": 139.528,
        "_internal_originalTime": 1689770375000
    },
    {
        "time": 1689770376000,
        "value": 139.53,
        "_internal_originalTime": 1689770376000
    },
    {
        "time": 1689770377000,
        "value": 139.538,
        "_internal_originalTime": 1689770377000
    },
    {
        "time": 1689770379000,
        "value": 139.543,
        "_internal_originalTime": 1689770379000
    },
    {
        "time": 1689770380000,
        "value": 139.543,
        "_internal_originalTime": 1689770380000
    },
    {
        "time": 1689770381000,
        "value": 139.538,
        "_internal_originalTime": 1689770381000
    },
    {
        "time": 1689770383000,
        "value": 139.541,
        "_internal_originalTime": 1689770383000
    },
    {
        "time": 1689770384000,
        "value": 139.535,
        "_internal_originalTime": 1689770384000
    },
    {
        "time": 1689770385000,
        "value": 139.539,
        "_internal_originalTime": 1689770385000
    },
    {
        "time": 1689770386000,
        "value": 139.548,
        "_internal_originalTime": 1689770386000
    },
    {
        "time": 1689770387000,
        "value": 139.541,
        "_internal_originalTime": 1689770387000
    },
    {
        "time": 1689770388000,
        "value": 139.536,
        "_internal_originalTime": 1689770388000
    },
    {
        "time": 1689770390000,
        "value": 139.54,
        "_internal_originalTime": 1689770390000
    },
    {
        "time": 1689770391000,
        "value": 139.538,
        "_internal_originalTime": 1689770391000
    },
    {
        "time": 1689770392000,
        "value": 139.539,
        "_internal_originalTime": 1689770392000
    },
    {
        "time": 1689770393000,
        "value": 139.533,
        "_internal_originalTime": 1689770393000
    },
    {
        "time": 1689770395000,
        "value": 139.531,
        "_internal_originalTime": 1689770395000
    },
    {
        "time": 1689770397000,
        "value": 139.531,
        "_internal_originalTime": 1689770397000
    },
    {
        "time": 1689770398000,
        "value": 139.53,
        "_internal_originalTime": 1689770398000
    },
    {
        "time": 1689770400000,
        "value": 139.554,
        "_internal_originalTime": 1689770400000
    },
    {
        "time": 1689770401000,
        "value": 139.562,
        "_internal_originalTime": 1689770401000
    },
    {
        "time": 1689770402000,
        "value": 139.552,
        "_internal_originalTime": 1689770402000
    },
    {
        "time": 1689770403000,
        "value": 139.562,
        "_internal_originalTime": 1689770403000
    },
    {
        "time": 1689770405000,
        "value": 139.575,
        "_internal_originalTime": 1689770405000
    },
    {
        "time": 1689770406000,
        "value": 139.58,
        "_internal_originalTime": 1689770406000
    },
    {
        "time": 1689770407000,
        "value": 139.577,
        "_internal_originalTime": 1689770407000
    },
    {
        "time": 1689770409000,
        "value": 139.579,
        "_internal_originalTime": 1689770409000
    },
    {
        "time": 1689770410000,
        "value": 139.582,
        "_internal_originalTime": 1689770410000
    },
    {
        "time": 1689770412000,
        "value": 139.578,
        "_internal_originalTime": 1689770412000
    },
    {
        "time": 1689770413000,
        "value": 139.582,
        "_internal_originalTime": 1689770413000
    },
    {
        "time": 1689770414000,
        "value": 139.577,
        "_internal_originalTime": 1689770414000
    },
    {
        "time": 1689770415000,
        "value": 139.585,
        "_internal_originalTime": 1689770415000
    },
    {
        "time": 1689770416000,
        "value": 139.574,
        "_internal_originalTime": 1689770416000
    },
    {
        "time": 1689770417000,
        "value": 139.574,
        "_internal_originalTime": 1689770417000
    },
    {
        "time": 1689770419000,
        "value": 139.58,
        "_internal_originalTime": 1689770419000
    },
    {
        "time": 1689770421000,
        "value": 139.575,
        "_internal_originalTime": 1689770421000
    },
    {
        "time": 1689770422000,
        "value": 139.574,
        "_internal_originalTime": 1689770422000
    },
    {
        "time": 1689770424000,
        "value": 139.579,
        "_internal_originalTime": 1689770424000
    },
    {
        "time": 1689770425000,
        "value": 139.58,
        "_internal_originalTime": 1689770425000
    },
    {
        "time": 1689770427000,
        "value": 139.591,
        "_internal_originalTime": 1689770427000
    },
    {
        "time": 1689770428000,
        "value": 139.593,
        "_internal_originalTime": 1689770428000
    },
    {
        "time": 1689770429000,
        "value": 139.594,
        "_internal_originalTime": 1689770429000
    },
    {
        "time": 1689770430000,
        "value": 139.601,
        "_internal_originalTime": 1689770430000
    },
    {
        "time": 1689770432000,
        "value": 139.599,
        "_internal_originalTime": 1689770432000
    },
    {
        "time": 1689770433000,
        "value": 139.604,
        "_internal_originalTime": 1689770433000
    },
    {
        "time": 1689770434000,
        "value": 139.612,
        "_internal_originalTime": 1689770434000
    },
    {
        "time": 1689770435000,
        "value": 139.616,
        "_internal_originalTime": 1689770435000
    },
    {
        "time": 1689770437000,
        "value": 139.619,
        "_internal_originalTime": 1689770437000
    },
    {
        "time": 1689770438000,
        "value": 139.617,
        "_internal_originalTime": 1689770438000
    },
    {
        "time": 1689770440000,
        "value": 139.619,
        "_internal_originalTime": 1689770440000
    },
    {
        "time": 1689770441000,
        "value": 139.617,
        "_internal_originalTime": 1689770441000
    },
    {
        "time": 1689770443000,
        "value": 139.622,
        "_internal_originalTime": 1689770443000
    },
    {
        "time": 1689770444000,
        "value": 139.619,
        "_internal_originalTime": 1689770444000
    },
    {
        "time": 1689770445000,
        "value": 139.611,
        "_internal_originalTime": 1689770445000
    },
    {
        "time": 1689770446000,
        "value": 139.614,
        "_internal_originalTime": 1689770446000
    },
    {
        "time": 1689770447000,
        "value": 139.61,
        "_internal_originalTime": 1689770447000
    },
    {
        "time": 1689770448000,
        "value": 139.611,
        "_internal_originalTime": 1689770448000
    },
    {
        "time": 1689770451000,
        "value": 139.609,
        "_internal_originalTime": 1689770451000
    },
    {
        "time": 1689770452000,
        "value": 139.606,
        "_internal_originalTime": 1689770452000
    },
    {
        "time": 1689770453000,
        "value": 139.603,
        "_internal_originalTime": 1689770453000
    },
    {
        "time": 1689770455000,
        "value": 139.604,
        "_internal_originalTime": 1689770455000
    },
    {
        "time": 1689770456000,
        "value": 139.607,
        "_internal_originalTime": 1689770456000
    },
    {
        "time": 1689770457000,
        "value": 139.606,
        "_internal_originalTime": 1689770457000
    },
    {
        "time": 1689770459000,
        "value": 139.613,
        "_internal_originalTime": 1689770459000
    },
    {
        "time": 1689770460000,
        "value": 139.613,
        "_internal_originalTime": 1689770460000
    },
    {
        "time": 1689770461000,
        "value": 139.619,
        "_internal_originalTime": 1689770461000
    },
    {
        "time": 1689770462000,
        "value": 139.618,
        "_internal_originalTime": 1689770462000
    },
    {
        "time": 1689770464000,
        "value": 139.614,
        "_internal_originalTime": 1689770464000
    },
    {
        "time": 1689770465000,
        "value": 139.62,
        "_internal_originalTime": 1689770465000
    },
    {
        "time": 1689770466000,
        "value": 139.622,
        "_internal_originalTime": 1689770466000
    },
    {
        "time": 1689770468000,
        "value": 139.624,
        "_internal_originalTime": 1689770468000
    },
    {
        "time": 1689770469000,
        "value": 139.627,
        "_internal_originalTime": 1689770469000
    },
    {
        "time": 1689770472000,
        "value": 139.628,
        "_internal_originalTime": 1689770472000
    },
    {
        "time": 1689770473000,
        "value": 139.627,
        "_internal_originalTime": 1689770473000
    },
    {
        "time": 1689770474000,
        "value": 139.62,
        "_internal_originalTime": 1689770474000
    },
    {
        "time": 1689770475000,
        "value": 139.625,
        "_internal_originalTime": 1689770475000
    },
    {
        "time": 1689770476000,
        "value": 139.627,
        "_internal_originalTime": 1689770476000
    },
    {
        "time": 1689770477000,
        "value": 139.615,
        "_internal_originalTime": 1689770477000
    },
    {
        "time": 1689770479000,
        "value": 139.619,
        "_internal_originalTime": 1689770479000
    },
    {
        "time": 1689770481000,
        "value": 139.608,
        "_internal_originalTime": 1689770481000
    },
    {
        "time": 1689770482000,
        "value": 139.607,
        "_internal_originalTime": 1689770482000
    },
    {
        "time": 1689770484000,
        "value": 139.604,
        "_internal_originalTime": 1689770484000
    },
    {
        "time": 1689770485000,
        "value": 139.605,
        "_internal_originalTime": 1689770485000
    },
    {
        "time": 1689770487000,
        "value": 139.599,
        "_internal_originalTime": 1689770487000
    },
    {
        "time": 1689770488000,
        "value": 139.596,
        "_internal_originalTime": 1689770488000
    },
    {
        "time": 1689770489000,
        "value": 139.597,
        "_internal_originalTime": 1689770489000
    },
    {
        "time": 1689770490000,
        "value": 139.594,
        "_internal_originalTime": 1689770490000
    },
    {
        "time": 1689770492000,
        "value": 139.596,
        "_internal_originalTime": 1689770492000
    },
    {
        "time": 1689770495000,
        "value": 139.599,
        "_internal_originalTime": 1689770495000
    },
    {
        "time": 1689770496000,
        "value": 139.596,
        "_internal_originalTime": 1689770496000
    },
    {
        "time": 1689770497000,
        "value": 139.597,
        "_internal_originalTime": 1689770497000
    },
    {
        "time": 1689770498000,
        "value": 139.597,
        "_internal_originalTime": 1689770498000
    },
    {
        "time": 1689770499000,
        "value": 139.597,
        "_internal_originalTime": 1689770499000
    },
    {
        "time": 1689770501000,
        "value": 139.595,
        "_internal_originalTime": 1689770501000
    },
    {
        "time": 1689770503000,
        "value": 139.592,
        "_internal_originalTime": 1689770503000
    },
    {
        "time": 1689770504000,
        "value": 139.591,
        "_internal_originalTime": 1689770504000
    },
    {
        "time": 1689770506000,
        "value": 139.591,
        "_internal_originalTime": 1689770506000
    },
    {
        "time": 1689770507000,
        "value": 139.591,
        "_internal_originalTime": 1689770507000
    },
    {
        "time": 1689770509000,
        "value": 139.581,
        "_internal_originalTime": 1689770509000
    },
    {
        "time": 1689770510000,
        "value": 139.585,
        "_internal_originalTime": 1689770510000
    },
    {
        "time": 1689770511000,
        "value": 139.591,
        "_internal_originalTime": 1689770511000
    },
    {
        "time": 1689770512000,
        "value": 139.596,
        "_internal_originalTime": 1689770512000
    },
    {
        "time": 1689770513000,
        "value": 139.597,
        "_internal_originalTime": 1689770513000
    },
    {
        "time": 1689770515000,
        "value": 139.593,
        "_internal_originalTime": 1689770515000
    },
    {
        "time": 1689770516000,
        "value": 139.59,
        "_internal_originalTime": 1689770516000
    },
    {
        "time": 1689770517000,
        "value": 139.587,
        "_internal_originalTime": 1689770517000
    },
    {
        "time": 1689770519000,
        "value": 139.584,
        "_internal_originalTime": 1689770519000
    },
    {
        "time": 1689770520000,
        "value": 139.591,
        "_internal_originalTime": 1689770520000
    },
    {
        "time": 1689770521000,
        "value": 139.57,
        "_internal_originalTime": 1689770521000
    },
    {
        "time": 1689770522000,
        "value": 139.588,
        "_internal_originalTime": 1689770522000
    },
    {
        "time": 1689770523000,
        "value": 139.591,
        "_internal_originalTime": 1689770523000
    },
    {
        "time": 1689770525000,
        "value": 139.59,
        "_internal_originalTime": 1689770525000
    },
    {
        "time": 1689770526000,
        "value": 139.591,
        "_internal_originalTime": 1689770526000
    },
    {
        "time": 1689770528000,
        "value": 139.592,
        "_internal_originalTime": 1689770528000
    },
    {
        "time": 1689770529000,
        "value": 139.593,
        "_internal_originalTime": 1689770529000
    },
    {
        "time": 1689770530000,
        "value": 139.6,
        "_internal_originalTime": 1689770530000
    },
    {
        "time": 1689770532000,
        "value": 139.606,
        "_internal_originalTime": 1689770532000
    },
    {
        "time": 1689770533000,
        "value": 139.612,
        "_internal_originalTime": 1689770533000
    },
    {
        "time": 1689770534000,
        "value": 139.61,
        "_internal_originalTime": 1689770534000
    },
    {
        "time": 1689770535000,
        "value": 139.605,
        "_internal_originalTime": 1689770535000
    },
    {
        "time": 1689770536000,
        "value": 139.615,
        "_internal_originalTime": 1689770536000
    },
    {
        "time": 1689770537000,
        "value": 139.617,
        "_internal_originalTime": 1689770537000
    },
    {
        "time": 1689770538000,
        "value": 139.618,
        "_internal_originalTime": 1689770538000
    },
    {
        "time": 1689770539000,
        "value": 139.612,
        "_internal_originalTime": 1689770539000
    },
    {
        "time": 1689770540000,
        "value": 139.612,
        "_internal_originalTime": 1689770540000
    },
    {
        "time": 1689770543000,
        "value": 139.619,
        "_internal_originalTime": 1689770543000
    },
    {
        "time": 1689770544000,
        "value": 139.608,
        "_internal_originalTime": 1689770544000
    },
    {
        "time": 1689770545000,
        "value": 139.609,
        "_internal_originalTime": 1689770545000
    },
    {
        "time": 1689770546000,
        "value": 139.607,
        "_internal_originalTime": 1689770546000
    },
    {
        "time": 1689770547000,
        "value": 139.608,
        "_internal_originalTime": 1689770547000
    },
    {
        "time": 1689770548000,
        "value": 139.595,
        "_internal_originalTime": 1689770548000
    },
    {
        "time": 1689770550000,
        "value": 139.598,
        "_internal_originalTime": 1689770550000
    },
    {
        "time": 1689770551000,
        "value": 139.598,
        "_internal_originalTime": 1689770551000
    },
    {
        "time": 1689770552000,
        "value": 139.595,
        "_internal_originalTime": 1689770552000
    },
    {
        "time": 1689770555000,
        "value": 139.596,
        "_internal_originalTime": 1689770555000
    },
    {
        "time": 1689770556000,
        "value": 139.603,
        "_internal_originalTime": 1689770556000
    },
    {
        "time": 1689770557000,
        "value": 139.606,
        "_internal_originalTime": 1689770557000
    },
    {
        "time": 1689770558000,
        "value": 139.602,
        "_internal_originalTime": 1689770558000
    },
    {
        "time": 1689770560000,
        "value": 139.602,
        "_internal_originalTime": 1689770560000
    },
    {
        "time": 1689770561000,
        "value": 139.607,
        "_internal_originalTime": 1689770561000
    },
    {
        "time": 1689770563000,
        "value": 139.607,
        "_internal_originalTime": 1689770563000
    },
    {
        "time": 1689770564000,
        "value": 139.594,
        "_internal_originalTime": 1689770564000
    },
    {
        "time": 1689770565000,
        "value": 139.596,
        "_internal_originalTime": 1689770565000
    },
    {
        "time": 1689770566000,
        "value": 139.591,
        "_internal_originalTime": 1689770566000
    },
    {
        "time": 1689770567000,
        "value": 139.592,
        "_internal_originalTime": 1689770567000
    },
    {
        "time": 1689770568000,
        "value": 139.595,
        "_internal_originalTime": 1689770568000
    },
    {
        "time": 1689770569000,
        "value": 139.592,
        "_internal_originalTime": 1689770569000
    },
    {
        "time": 1689770571000,
        "value": 139.606,
        "_internal_originalTime": 1689770571000
    },
    {
        "time": 1689770572000,
        "value": 139.601,
        "_internal_originalTime": 1689770572000
    },
    {
        "time": 1689770574000,
        "value": 139.601,
        "_internal_originalTime": 1689770574000
    },
    {
        "time": 1689770576000,
        "value": 139.606,
        "_internal_originalTime": 1689770576000
    },
    {
        "time": 1689770577000,
        "value": 139.604,
        "_internal_originalTime": 1689770577000
    },
    {
        "time": 1689770578000,
        "value": 139.593,
        "_internal_originalTime": 1689770578000
    },
    {
        "time": 1689770579000,
        "value": 139.596,
        "_internal_originalTime": 1689770579000
    },
    {
        "time": 1689770580000,
        "value": 139.58,
        "_internal_originalTime": 1689770580000
    },
    {
        "time": 1689770581000,
        "value": 139.608,
        "_internal_originalTime": 1689770581000
    },
    {
        "time": 1689770582000,
        "value": 139.616,
        "_internal_originalTime": 1689770582000
    },
    {
        "time": 1689770584000,
        "value": 139.615,
        "_internal_originalTime": 1689770584000
    },
    {
        "time": 1689770585000,
        "value": 139.611,
        "_internal_originalTime": 1689770585000
    },
    {
        "time": 1689770586000,
        "value": 139.609,
        "_internal_originalTime": 1689770586000
    },
    {
        "time": 1689770587000,
        "value": 139.612,
        "_internal_originalTime": 1689770587000
    },
    {
        "time": 1689770588000,
        "value": 139.609,
        "_internal_originalTime": 1689770588000
    },
    {
        "time": 1689770590000,
        "value": 139.618,
        "_internal_originalTime": 1689770590000
    },
    {
        "time": 1689770591000,
        "value": 139.621,
        "_internal_originalTime": 1689770591000
    },
    {
        "time": 1689770592000,
        "value": 139.624,
        "_internal_originalTime": 1689770592000
    },
    {
        "time": 1689770594000,
        "value": 139.628,
        "_internal_originalTime": 1689770594000
    },
    {
        "time": 1689770595000,
        "value": 139.636,
        "_internal_originalTime": 1689770595000
    },
    {
        "time": 1689770596000,
        "value": 139.636,
        "_internal_originalTime": 1689770596000
    },
    {
        "time": 1689770597000,
        "value": 139.637,
        "_internal_originalTime": 1689770597000
    },
    {
        "time": 1689770599000,
        "value": 139.631,
        "_internal_originalTime": 1689770599000
    },
    {
        "time": 1689770600000,
        "value": 139.636,
        "_internal_originalTime": 1689770600000
    },
    {
        "time": 1689770601000,
        "value": 139.63,
        "_internal_originalTime": 1689770601000
    },
    {
        "time": 1689770603000,
        "value": 139.636,
        "_internal_originalTime": 1689770603000
    },
    {
        "time": 1689770604000,
        "value": 139.631,
        "_internal_originalTime": 1689770604000
    },
    {
        "time": 1689770606000,
        "value": 139.628,
        "_internal_originalTime": 1689770606000
    },
    {
        "time": 1689770607000,
        "value": 139.627,
        "_internal_originalTime": 1689770607000
    },
    {
        "time": 1689770609000,
        "value": 139.628,
        "_internal_originalTime": 1689770609000
    },
    {
        "time": 1689770610000,
        "value": 139.623,
        "_internal_originalTime": 1689770610000
    },
    {
        "time": 1689770611000,
        "value": 139.624,
        "_internal_originalTime": 1689770611000
    },
    {
        "time": 1689770613000,
        "value": 139.624,
        "_internal_originalTime": 1689770613000
    },
    {
        "time": 1689770614000,
        "value": 139.64,
        "_internal_originalTime": 1689770614000
    },
    {
        "time": 1689770616000,
        "value": 139.643,
        "_internal_originalTime": 1689770616000
    },
    {
        "time": 1689770617000,
        "value": 139.635,
        "_internal_originalTime": 1689770617000
    },
    {
        "time": 1689770618000,
        "value": 139.633,
        "_internal_originalTime": 1689770618000
    },
    {
        "time": 1689770620000,
        "value": 139.644,
        "_internal_originalTime": 1689770620000
    },
    {
        "time": 1689770622000,
        "value": 139.637,
        "_internal_originalTime": 1689770622000
    },
    {
        "time": 1689770623000,
        "value": 139.629,
        "_internal_originalTime": 1689770623000
    },
    {
        "time": 1689770624000,
        "value": 139.629,
        "_internal_originalTime": 1689770624000
    },
    {
        "time": 1689770625000,
        "value": 139.625,
        "_internal_originalTime": 1689770625000
    },
    {
        "time": 1689770626000,
        "value": 139.622,
        "_internal_originalTime": 1689770626000
    },
    {
        "time": 1689770627000,
        "value": 139.628,
        "_internal_originalTime": 1689770627000
    },
    {
        "time": 1689770628000,
        "value": 139.63,
        "_internal_originalTime": 1689770628000
    },
    {
        "time": 1689770630000,
        "value": 139.631,
        "_internal_originalTime": 1689770630000
    },
    {
        "time": 1689770631000,
        "value": 139.624,
        "_internal_originalTime": 1689770631000
    },
    {
        "time": 1689770633000,
        "value": 139.618,
        "_internal_originalTime": 1689770633000
    },
    {
        "time": 1689770634000,
        "value": 139.614,
        "_internal_originalTime": 1689770634000
    },
    {
        "time": 1689770636000,
        "value": 139.617,
        "_internal_originalTime": 1689770636000
    },
    {
        "time": 1689770639000,
        "value": 139.614,
        "_internal_originalTime": 1689770639000
    },
    {
        "time": 1689770640000,
        "value": 139.625,
        "_internal_originalTime": 1689770640000
    },
    {
        "time": 1689770642000,
        "value": 139.626,
        "_internal_originalTime": 1689770642000
    },
    {
        "time": 1689770643000,
        "value": 139.625,
        "_internal_originalTime": 1689770643000
    },
    {
        "time": 1689770644000,
        "value": 139.622,
        "_internal_originalTime": 1689770644000
    },
    {
        "time": 1689770646000,
        "value": 139.63,
        "_internal_originalTime": 1689770646000
    },
    {
        "time": 1689770647000,
        "value": 139.627,
        "_internal_originalTime": 1689770647000
    },
    {
        "time": 1689770648000,
        "value": 139.625,
        "_internal_originalTime": 1689770648000
    },
    {
        "time": 1689770650000,
        "value": 139.625,
        "_internal_originalTime": 1689770650000
    },
    {
        "time": 1689770651000,
        "value": 139.634,
        "_internal_originalTime": 1689770651000
    },
    {
        "time": 1689770653000,
        "value": 139.631,
        "_internal_originalTime": 1689770653000
    },
    {
        "time": 1689770655000,
        "value": 139.633,
        "_internal_originalTime": 1689770655000
    },
    {
        "time": 1689770656000,
        "value": 139.632,
        "_internal_originalTime": 1689770656000
    },
    {
        "time": 1689770657000,
        "value": 139.632,
        "_internal_originalTime": 1689770657000
    },
    {
        "time": 1689770659000,
        "value": 139.627,
        "_internal_originalTime": 1689770659000
    },
    {
        "time": 1689770660000,
        "value": 139.626,
        "_internal_originalTime": 1689770660000
    },
    {
        "time": 1689770662000,
        "value": 139.617,
        "_internal_originalTime": 1689770662000
    },
    {
        "time": 1689770663000,
        "value": 139.617,
        "_internal_originalTime": 1689770663000
    },
    {
        "time": 1689770664000,
        "value": 139.624,
        "_internal_originalTime": 1689770664000
    },
    {
        "time": 1689770666000,
        "value": 139.629,
        "_internal_originalTime": 1689770666000
    },
    {
        "time": 1689770667000,
        "value": 139.613,
        "_internal_originalTime": 1689770667000
    },
    {
        "time": 1689770668000,
        "value": 139.615,
        "_internal_originalTime": 1689770668000
    },
    {
        "time": 1689770670000,
        "value": 139.617,
        "_internal_originalTime": 1689770670000
    },
    {
        "time": 1689770671000,
        "value": 139.614,
        "_internal_originalTime": 1689770671000
    },
    {
        "time": 1689770673000,
        "value": 139.619,
        "_internal_originalTime": 1689770673000
    },
    {
        "time": 1689770675000,
        "value": 139.621,
        "_internal_originalTime": 1689770675000
    },
    {
        "time": 1689770676000,
        "value": 139.615,
        "_internal_originalTime": 1689770676000
    },
    {
        "time": 1689770678000,
        "value": 139.614,
        "_internal_originalTime": 1689770678000
    },
    {
        "time": 1689770680000,
        "value": 139.618,
        "_internal_originalTime": 1689770680000
    },
    {
        "time": 1689770682000,
        "value": 139.621,
        "_internal_originalTime": 1689770682000
    },
    {
        "time": 1689770683000,
        "value": 139.617,
        "_internal_originalTime": 1689770683000
    },
    {
        "time": 1689770684000,
        "value": 139.616,
        "_internal_originalTime": 1689770684000
    },
    {
        "time": 1689770686000,
        "value": 139.618,
        "_internal_originalTime": 1689770686000
    },
    {
        "time": 1689770688000,
        "value": 139.616,
        "_internal_originalTime": 1689770688000
    },
    {
        "time": 1689770689000,
        "value": 139.613,
        "_internal_originalTime": 1689770689000
    },
    {
        "time": 1689770690000,
        "value": 139.617,
        "_internal_originalTime": 1689770690000
    },
    {
        "time": 1689770692000,
        "value": 139.619,
        "_internal_originalTime": 1689770692000
    },
    {
        "time": 1689770693000,
        "value": 139.617,
        "_internal_originalTime": 1689770693000
    },
    {
        "time": 1689770694000,
        "value": 139.616,
        "_internal_originalTime": 1689770694000
    },
    {
        "time": 1689770695000,
        "value": 139.606,
        "_internal_originalTime": 1689770695000
    },
    {
        "time": 1689770697000,
        "value": 139.609,
        "_internal_originalTime": 1689770697000
    },
    {
        "time": 1689770698000,
        "value": 139.608,
        "_internal_originalTime": 1689770698000
    },
    {
        "time": 1689770700000,
        "value": 139.607,
        "_internal_originalTime": 1689770700000
    },
    {
        "time": 1689770701000,
        "value": 139.607,
        "_internal_originalTime": 1689770701000
    },
    {
        "time": 1689770702000,
        "value": 139.604,
        "_internal_originalTime": 1689770702000
    },
    {
        "time": 1689770703000,
        "value": 139.607,
        "_internal_originalTime": 1689770703000
    },
    {
        "time": 1689770704000,
        "value": 139.605,
        "_internal_originalTime": 1689770704000
    },
    {
        "time": 1689770706000,
        "value": 139.614,
        "_internal_originalTime": 1689770706000
    },
    {
        "time": 1689770708000,
        "value": 139.612,
        "_internal_originalTime": 1689770708000
    },
    {
        "time": 1689770709000,
        "value": 139.62,
        "_internal_originalTime": 1689770709000
    },
    {
        "time": 1689770710000,
        "value": 139.615,
        "_internal_originalTime": 1689770710000
    },
    {
        "time": 1689770712000,
        "value": 139.615,
        "_internal_originalTime": 1689770712000
    },
    {
        "time": 1689770715000,
        "value": 139.607,
        "_internal_originalTime": 1689770715000
    },
    {
        "time": 1689770717000,
        "value": 139.61,
        "_internal_originalTime": 1689770717000
    },
    {
        "time": 1689770718000,
        "value": 139.609,
        "_internal_originalTime": 1689770718000
    },
    {
        "time": 1689770721000,
        "value": 139.615,
        "_internal_originalTime": 1689770721000
    },
    {
        "time": 1689770722000,
        "value": 139.609,
        "_internal_originalTime": 1689770722000
    },
    {
        "time": 1689770723000,
        "value": 139.592,
        "_internal_originalTime": 1689770723000
    },
    {
        "time": 1689770725000,
        "value": 139.598,
        "_internal_originalTime": 1689770725000
    },
    {
        "time": 1689770726000,
        "value": 139.592,
        "_internal_originalTime": 1689770726000
    },
    {
        "time": 1689770727000,
        "value": 139.593,
        "_internal_originalTime": 1689770727000
    },
    {
        "time": 1689770728000,
        "value": 139.599,
        "_internal_originalTime": 1689770728000
    },
    {
        "time": 1689770730000,
        "value": 139.587,
        "_internal_originalTime": 1689770730000
    },
    {
        "time": 1689770731000,
        "value": 139.582,
        "_internal_originalTime": 1689770731000
    },
    {
        "time": 1689770732000,
        "value": 139.585,
        "_internal_originalTime": 1689770732000
    },
    {
        "time": 1689770734000,
        "value": 139.578,
        "_internal_originalTime": 1689770734000
    },
    {
        "time": 1689770735000,
        "value": 139.577,
        "_internal_originalTime": 1689770735000
    },
    {
        "time": 1689770736000,
        "value": 139.576,
        "_internal_originalTime": 1689770736000
    },
    {
        "time": 1689770738000,
        "value": 139.582,
        "_internal_originalTime": 1689770738000
    },
    {
        "time": 1689770739000,
        "value": 139.576,
        "_internal_originalTime": 1689770739000
    },
    {
        "time": 1689770740000,
        "value": 139.576,
        "_internal_originalTime": 1689770740000
    },
    {
        "time": 1689770741000,
        "value": 139.596,
        "_internal_originalTime": 1689770741000
    },
    {
        "time": 1689770743000,
        "value": 139.598,
        "_internal_originalTime": 1689770743000
    },
    {
        "time": 1689770745000,
        "value": 139.6,
        "_internal_originalTime": 1689770745000
    },
    {
        "time": 1689770746000,
        "value": 139.592,
        "_internal_originalTime": 1689770746000
    },
    {
        "time": 1689770747000,
        "value": 139.592,
        "_internal_originalTime": 1689770747000
    },
    {
        "time": 1689770748000,
        "value": 139.597,
        "_internal_originalTime": 1689770748000
    },
    {
        "time": 1689770750000,
        "value": 139.595,
        "_internal_originalTime": 1689770750000
    },
    {
        "time": 1689770751000,
        "value": 139.594,
        "_internal_originalTime": 1689770751000
    },
    {
        "time": 1689770754000,
        "value": 139.593,
        "_internal_originalTime": 1689770754000
    },
    {
        "time": 1689770755000,
        "value": 139.594,
        "_internal_originalTime": 1689770755000
    },
    {
        "time": 1689770757000,
        "value": 139.596,
        "_internal_originalTime": 1689770757000
    },
    {
        "time": 1689770759000,
        "value": 139.594,
        "_internal_originalTime": 1689770759000
    },
    {
        "time": 1689770760000,
        "value": 139.596,
        "_internal_originalTime": 1689770760000
    },
    {
        "time": 1689770762000,
        "value": 139.606,
        "_internal_originalTime": 1689770762000
    },
    {
        "time": 1689770763000,
        "value": 139.614,
        "_internal_originalTime": 1689770763000
    },
    {
        "time": 1689770764000,
        "value": 139.615,
        "_internal_originalTime": 1689770764000
    },
    {
        "time": 1689770765000,
        "value": 139.615,
        "_internal_originalTime": 1689770765000
    },
    {
        "time": 1689770766000,
        "value": 139.618,
        "_internal_originalTime": 1689770766000
    },
    {
        "time": 1689770768000,
        "value": 139.615,
        "_internal_originalTime": 1689770768000
    },
    {
        "time": 1689770769000,
        "value": 139.618,
        "_internal_originalTime": 1689770769000
    },
    {
        "time": 1689770770000,
        "value": 139.619,
        "_internal_originalTime": 1689770770000
    },
    {
        "time": 1689770772000,
        "value": 139.618,
        "_internal_originalTime": 1689770772000
    },
    {
        "time": 1689770773000,
        "value": 139.616,
        "_internal_originalTime": 1689770773000
    },
    {
        "time": 1689770775000,
        "value": 139.616,
        "_internal_originalTime": 1689770775000
    },
    {
        "time": 1689770776000,
        "value": 139.625,
        "_internal_originalTime": 1689770776000
    },
    {
        "time": 1689770777000,
        "value": 139.628,
        "_internal_originalTime": 1689770777000
    },
    {
        "time": 1689770779000,
        "value": 139.629,
        "_internal_originalTime": 1689770779000
    },
    {
        "time": 1689770781000,
        "value": 139.64,
        "_internal_originalTime": 1689770781000
    },
    {
        "time": 1689770782000,
        "value": 139.637,
        "_internal_originalTime": 1689770782000
    },
    {
        "time": 1689770783000,
        "value": 139.634,
        "_internal_originalTime": 1689770783000
    },
    {
        "time": 1689770785000,
        "value": 139.636,
        "_internal_originalTime": 1689770785000
    },
    {
        "time": 1689770786000,
        "value": 139.624,
        "_internal_originalTime": 1689770786000
    },
    {
        "time": 1689770787000,
        "value": 139.628,
        "_internal_originalTime": 1689770787000
    },
    {
        "time": 1689770789000,
        "value": 139.614,
        "_internal_originalTime": 1689770789000
    },
    {
        "time": 1689770790000,
        "value": 139.618,
        "_internal_originalTime": 1689770790000
    },
    {
        "time": 1689770791000,
        "value": 139.618,
        "_internal_originalTime": 1689770791000
    },
    {
        "time": 1689770793000,
        "value": 139.618,
        "_internal_originalTime": 1689770793000
    },
    {
        "time": 1689770794000,
        "value": 139.626,
        "_internal_originalTime": 1689770794000
    },
    {
        "time": 1689770796000,
        "value": 139.62,
        "_internal_originalTime": 1689770796000
    },
    {
        "time": 1689770797000,
        "value": 139.62,
        "_internal_originalTime": 1689770797000
    },
    {
        "time": 1689770798000,
        "value": 139.621,
        "_internal_originalTime": 1689770798000
    },
    {
        "time": 1689770800000,
        "value": 139.598,
        "_internal_originalTime": 1689770800000
    },
    {
        "time": 1689770801000,
        "value": 139.601,
        "_internal_originalTime": 1689770801000
    },
    {
        "time": 1689770802000,
        "value": 139.597,
        "_internal_originalTime": 1689770802000
    },
    {
        "time": 1689770804000,
        "value": 139.584,
        "_internal_originalTime": 1689770804000
    },
    {
        "time": 1689770805000,
        "value": 139.585,
        "_internal_originalTime": 1689770805000
    },
    {
        "time": 1689770806000,
        "value": 139.579,
        "_internal_originalTime": 1689770806000
    },
    {
        "time": 1689770807000,
        "value": 139.582,
        "_internal_originalTime": 1689770807000
    },
    {
        "time": 1689770808000,
        "value": 139.589,
        "_internal_originalTime": 1689770808000
    },
    {
        "time": 1689770810000,
        "value": 139.595,
        "_internal_originalTime": 1689770810000
    },
    {
        "time": 1689770811000,
        "value": 139.603,
        "_internal_originalTime": 1689770811000
    },
    {
        "time": 1689770812000,
        "value": 139.612,
        "_internal_originalTime": 1689770812000
    },
    {
        "time": 1689770814000,
        "value": 139.609,
        "_internal_originalTime": 1689770814000
    },
    {
        "time": 1689770815000,
        "value": 139.604,
        "_internal_originalTime": 1689770815000
    },
    {
        "time": 1689770817000,
        "value": 139.596,
        "_internal_originalTime": 1689770817000
    },
    {
        "time": 1689770818000,
        "value": 139.609,
        "_internal_originalTime": 1689770818000
    },
    {
        "time": 1689770819000,
        "value": 139.607,
        "_internal_originalTime": 1689770819000
    },
    {
        "time": 1689770820000,
        "value": 139.611,
        "_internal_originalTime": 1689770820000
    },
    {
        "time": 1689770821000,
        "value": 139.608,
        "_internal_originalTime": 1689770821000
    },
    {
        "time": 1689770822000,
        "value": 139.607,
        "_internal_originalTime": 1689770822000
    },
    {
        "time": 1689770823000,
        "value": 139.597,
        "_internal_originalTime": 1689770823000
    },
    {
        "time": 1689770825000,
        "value": 139.596,
        "_internal_originalTime": 1689770825000
    },
    {
        "time": 1689770826000,
        "value": 139.597,
        "_internal_originalTime": 1689770826000
    },
    {
        "time": 1689770828000,
        "value": 139.602,
        "_internal_originalTime": 1689770828000
    },
    {
        "time": 1689770829000,
        "value": 139.6,
        "_internal_originalTime": 1689770829000
    },
    {
        "time": 1689770830000,
        "value": 139.598,
        "_internal_originalTime": 1689770830000
    },
    {
        "time": 1689770832000,
        "value": 139.599,
        "_internal_originalTime": 1689770832000
    },
    {
        "time": 1689770833000,
        "value": 139.59,
        "_internal_originalTime": 1689770833000
    },
    {
        "time": 1689770835000,
        "value": 139.59,
        "_internal_originalTime": 1689770835000
    },
    {
        "time": 1689770836000,
        "value": 139.59,
        "_internal_originalTime": 1689770836000
    },
    {
        "time": 1689770837000,
        "value": 139.594,
        "_internal_originalTime": 1689770837000
    },
    {
        "time": 1689770839000,
        "value": 139.593,
        "_internal_originalTime": 1689770839000
    },
    {
        "time": 1689770840000,
        "value": 139.593,
        "_internal_originalTime": 1689770840000
    },
    {
        "time": 1689770842000,
        "value": 139.593,
        "_internal_originalTime": 1689770842000
    },
    {
        "time": 1689770843000,
        "value": 139.602,
        "_internal_originalTime": 1689770843000
    },
    {
        "time": 1689770844000,
        "value": 139.603,
        "_internal_originalTime": 1689770844000
    },
    {
        "time": 1689770846000,
        "value": 139.609,
        "_internal_originalTime": 1689770846000
    },
    {
        "time": 1689770847000,
        "value": 139.615,
        "_internal_originalTime": 1689770847000
    },
    {
        "time": 1689770848000,
        "value": 139.607,
        "_internal_originalTime": 1689770848000
    },
    {
        "time": 1689770850000,
        "value": 139.612,
        "_internal_originalTime": 1689770850000
    },
    {
        "time": 1689770851000,
        "value": 139.609,
        "_internal_originalTime": 1689770851000
    },
    {
        "time": 1689770852000,
        "value": 139.606,
        "_internal_originalTime": 1689770852000
    },
    {
        "time": 1689770853000,
        "value": 139.606,
        "_internal_originalTime": 1689770853000
    },
    {
        "time": 1689770855000,
        "value": 139.611,
        "_internal_originalTime": 1689770855000
    },
    {
        "time": 1689770856000,
        "value": 139.611,
        "_internal_originalTime": 1689770856000
    },
    {
        "time": 1689770858000,
        "value": 139.61,
        "_internal_originalTime": 1689770858000
    },
    {
        "time": 1689770859000,
        "value": 139.618,
        "_internal_originalTime": 1689770859000
    },
    {
        "time": 1689770860000,
        "value": 139.633,
        "_internal_originalTime": 1689770860000
    },
    {
        "time": 1689770861000,
        "value": 139.632,
        "_internal_originalTime": 1689770861000
    },
    {
        "time": 1689770863000,
        "value": 139.646,
        "_internal_originalTime": 1689770863000
    },
    {
        "time": 1689770865000,
        "value": 139.651,
        "_internal_originalTime": 1689770865000
    },
    {
        "time": 1689770866000,
        "value": 139.657,
        "_internal_originalTime": 1689770866000
    },
    {
        "time": 1689770867000,
        "value": 139.646,
        "_internal_originalTime": 1689770867000
    },
    {
        "time": 1689770868000,
        "value": 139.648,
        "_internal_originalTime": 1689770868000
    },
    {
        "time": 1689770870000,
        "value": 139.649,
        "_internal_originalTime": 1689770870000
    },
    {
        "time": 1689770871000,
        "value": 139.645,
        "_internal_originalTime": 1689770871000
    },
    {
        "time": 1689770872000,
        "value": 139.641,
        "_internal_originalTime": 1689770872000
    },
    {
        "time": 1689770873000,
        "value": 139.647,
        "_internal_originalTime": 1689770873000
    },
    {
        "time": 1689770875000,
        "value": 139.648,
        "_internal_originalTime": 1689770875000
    },
    {
        "time": 1689770876000,
        "value": 139.645,
        "_internal_originalTime": 1689770876000
    },
    {
        "time": 1689770877000,
        "value": 139.649,
        "_internal_originalTime": 1689770877000
    },
    {
        "time": 1689770879000,
        "value": 139.644,
        "_internal_originalTime": 1689770879000
    },
    {
        "time": 1689770880000,
        "value": 139.67,
        "_internal_originalTime": 1689770880000
    },
    {
        "time": 1689770881000,
        "value": 139.666,
        "_internal_originalTime": 1689770881000
    },
    {
        "time": 1689770882000,
        "value": 139.664,
        "_internal_originalTime": 1689770882000
    },
    {
        "time": 1689770883000,
        "value": 139.665,
        "_internal_originalTime": 1689770883000
    },
    {
        "time": 1689770885000,
        "value": 139.668,
        "_internal_originalTime": 1689770885000
    },
    {
        "time": 1689770886000,
        "value": 139.662,
        "_internal_originalTime": 1689770886000
    },
    {
        "time": 1689770887000,
        "value": 139.662,
        "_internal_originalTime": 1689770887000
    },
    {
        "time": 1689770888000,
        "value": 139.669,
        "_internal_originalTime": 1689770888000
    },
    {
        "time": 1689770890000,
        "value": 139.685,
        "_internal_originalTime": 1689770890000
    },
    {
        "time": 1689770891000,
        "value": 139.688,
        "_internal_originalTime": 1689770891000
    },
    {
        "time": 1689770892000,
        "value": 139.685,
        "_internal_originalTime": 1689770892000
    },
    {
        "time": 1689770893000,
        "value": 139.688,
        "_internal_originalTime": 1689770893000
    },
    {
        "time": 1689770895000,
        "value": 139.693,
        "_internal_originalTime": 1689770895000
    },
    {
        "time": 1689770896000,
        "value": 139.684,
        "_internal_originalTime": 1689770896000
    },
    {
        "time": 1689770898000,
        "value": 139.689,
        "_internal_originalTime": 1689770898000
    },
    {
        "time": 1689770899000,
        "value": 139.694,
        "_internal_originalTime": 1689770899000
    },
    {
        "time": 1689770900000,
        "value": 139.693,
        "_internal_originalTime": 1689770900000
    },
    {
        "time": 1689770901000,
        "value": 139.695,
        "_internal_originalTime": 1689770901000
    },
    {
        "time": 1689770903000,
        "value": 139.695,
        "_internal_originalTime": 1689770903000
    },
    {
        "time": 1689770904000,
        "value": 139.693,
        "_internal_originalTime": 1689770904000
    },
    {
        "time": 1689770905000,
        "value": 139.691,
        "_internal_originalTime": 1689770905000
    },
    {
        "time": 1689770907000,
        "value": 139.69,
        "_internal_originalTime": 1689770907000
    },
    {
        "time": 1689770908000,
        "value": 139.695,
        "_internal_originalTime": 1689770908000
    },
    {
        "time": 1689770910000,
        "value": 139.685,
        "_internal_originalTime": 1689770910000
    },
    {
        "time": 1689770911000,
        "value": 139.682,
        "_internal_originalTime": 1689770911000
    },
    {
        "time": 1689770912000,
        "value": 139.682,
        "_internal_originalTime": 1689770912000
    },
    {
        "time": 1689770913000,
        "value": 139.684,
        "_internal_originalTime": 1689770913000
    },
    {
        "time": 1689770915000,
        "value": 139.684,
        "_internal_originalTime": 1689770915000
    },
    {
        "time": 1689770916000,
        "value": 139.682,
        "_internal_originalTime": 1689770916000
    },
    {
        "time": 1689770918000,
        "value": 139.683,
        "_internal_originalTime": 1689770918000
    },
    {
        "time": 1689770919000,
        "value": 139.692,
        "_internal_originalTime": 1689770919000
    },
    {
        "time": 1689770920000,
        "value": 139.686,
        "_internal_originalTime": 1689770920000
    },
    {
        "time": 1689770921000,
        "value": 139.687,
        "_internal_originalTime": 1689770921000
    },
    {
        "time": 1689770922000,
        "value": 139.692,
        "_internal_originalTime": 1689770922000
    },
    {
        "time": 1689770924000,
        "value": 139.687,
        "_internal_originalTime": 1689770924000
    },
    {
        "time": 1689770925000,
        "value": 139.68,
        "_internal_originalTime": 1689770925000
    },
    {
        "time": 1689770927000,
        "value": 139.68,
        "_internal_originalTime": 1689770927000
    },
    {
        "time": 1689770928000,
        "value": 139.673,
        "_internal_originalTime": 1689770928000
    },
    {
        "time": 1689770929000,
        "value": 139.675,
        "_internal_originalTime": 1689770929000
    },
    {
        "time": 1689770931000,
        "value": 139.672,
        "_internal_originalTime": 1689770931000
    },
    {
        "time": 1689770932000,
        "value": 139.675,
        "_internal_originalTime": 1689770932000
    },
    {
        "time": 1689770933000,
        "value": 139.685,
        "_internal_originalTime": 1689770933000
    },
    {
        "time": 1689770935000,
        "value": 139.688,
        "_internal_originalTime": 1689770935000
    },
    {
        "time": 1689770936000,
        "value": 139.689,
        "_internal_originalTime": 1689770936000
    },
    {
        "time": 1689770937000,
        "value": 139.682,
        "_internal_originalTime": 1689770937000
    },
    {
        "time": 1689770939000,
        "value": 139.684,
        "_internal_originalTime": 1689770939000
    },
    {
        "time": 1689770940000,
        "value": 139.685,
        "_internal_originalTime": 1689770940000
    },
    {
        "time": 1689770941000,
        "value": 139.685,
        "_internal_originalTime": 1689770941000
    },
    {
        "time": 1689770942000,
        "value": 139.685,
        "_internal_originalTime": 1689770942000
    },
    {
        "time": 1689770943000,
        "value": 139.685,
        "_internal_originalTime": 1689770943000
    },
    {
        "time": 1689770945000,
        "value": 139.682,
        "_internal_originalTime": 1689770945000
    },
    {
        "time": 1689770946000,
        "value": 139.682,
        "_internal_originalTime": 1689770946000
    },
    {
        "time": 1689770947000,
        "value": 139.68,
        "_internal_originalTime": 1689770947000
    },
    {
        "time": 1689770949000,
        "value": 139.684,
        "_internal_originalTime": 1689770949000
    },
    {
        "time": 1689770950000,
        "value": 139.676,
        "_internal_originalTime": 1689770950000
    },
    {
        "time": 1689770951000,
        "value": 139.679,
        "_internal_originalTime": 1689770951000
    },
    {
        "time": 1689770953000,
        "value": 139.681,
        "_internal_originalTime": 1689770953000
    },
    {
        "time": 1689770954000,
        "value": 139.68,
        "_internal_originalTime": 1689770954000
    },
    {
        "time": 1689770955000,
        "value": 139.684,
        "_internal_originalTime": 1689770955000
    },
    {
        "time": 1689770956000,
        "value": 139.684,
        "_internal_originalTime": 1689770956000
    },
    {
        "time": 1689770957000,
        "value": 139.681,
        "_internal_originalTime": 1689770957000
    },
    {
        "time": 1689770958000,
        "value": 139.681,
        "_internal_originalTime": 1689770958000
    },
    {
        "time": 1689770960000,
        "value": 139.67,
        "_internal_originalTime": 1689770960000
    },
    {
        "time": 1689770961000,
        "value": 139.67,
        "_internal_originalTime": 1689770961000
    },
    {
        "time": 1689770962000,
        "value": 139.672,
        "_internal_originalTime": 1689770962000
    },
    {
        "time": 1689770964000,
        "value": 139.667,
        "_internal_originalTime": 1689770964000
    },
    {
        "time": 1689770965000,
        "value": 139.684,
        "_internal_originalTime": 1689770965000
    },
    {
        "time": 1689770967000,
        "value": 139.674,
        "_internal_originalTime": 1689770967000
    },
    {
        "time": 1689770968000,
        "value": 139.676,
        "_internal_originalTime": 1689770968000
    },
    {
        "time": 1689770970000,
        "value": 139.691,
        "_internal_originalTime": 1689770970000
    },
    {
        "time": 1689770971000,
        "value": 139.693,
        "_internal_originalTime": 1689770971000
    },
    {
        "time": 1689770972000,
        "value": 139.691,
        "_internal_originalTime": 1689770972000
    },
    {
        "time": 1689770974000,
        "value": 139.693,
        "_internal_originalTime": 1689770974000
    },
    {
        "time": 1689770975000,
        "value": 139.694,
        "_internal_originalTime": 1689770975000
    },
    {
        "time": 1689770977000,
        "value": 139.688,
        "_internal_originalTime": 1689770977000
    },
    {
        "time": 1689770978000,
        "value": 139.69,
        "_internal_originalTime": 1689770978000
    },
    {
        "time": 1689770979000,
        "value": 139.687,
        "_internal_originalTime": 1689770979000
    },
    {
        "time": 1689770981000,
        "value": 139.689,
        "_internal_originalTime": 1689770981000
    },
    {
        "time": 1689770982000,
        "value": 139.693,
        "_internal_originalTime": 1689770982000
    },
    {
        "time": 1689770984000,
        "value": 139.692,
        "_internal_originalTime": 1689770984000
    },
    {
        "time": 1689770985000,
        "value": 139.686,
        "_internal_originalTime": 1689770985000
    },
    {
        "time": 1689770987000,
        "value": 139.692,
        "_internal_originalTime": 1689770987000
    },
    {
        "time": 1689770988000,
        "value": 139.692,
        "_internal_originalTime": 1689770988000
    },
    {
        "time": 1689770990000,
        "value": 139.692,
        "_internal_originalTime": 1689770990000
    },
    {
        "time": 1689770991000,
        "value": 139.694,
        "_internal_originalTime": 1689770991000
    },
    {
        "time": 1689770993000,
        "value": 139.688,
        "_internal_originalTime": 1689770993000
    },
    {
        "time": 1689770994000,
        "value": 139.683,
        "_internal_originalTime": 1689770994000
    },
    {
        "time": 1689770995000,
        "value": 139.68,
        "_internal_originalTime": 1689770995000
    },
    {
        "time": 1689770999000,
        "value": 139.688,
        "_internal_originalTime": 1689770999000
    },
    {
        "time": 1689771000000,
        "value": 139.687,
        "_internal_originalTime": 1689771000000
    },
    {
        "time": 1689771001000,
        "value": 139.686,
        "_internal_originalTime": 1689771001000
    },
    {
        "time": 1689771002000,
        "value": 139.689,
        "_internal_originalTime": 1689771002000
    },
    {
        "time": 1689771003000,
        "value": 139.687,
        "_internal_originalTime": 1689771003000
    },
    {
        "time": 1689771004000,
        "value": 139.687,
        "_internal_originalTime": 1689771004000
    },
    {
        "time": 1689771006000,
        "value": 139.688,
        "_internal_originalTime": 1689771006000
    },
    {
        "time": 1689771007000,
        "value": 139.693,
        "_internal_originalTime": 1689771007000
    },
    {
        "time": 1689771008000,
        "value": 139.694,
        "_internal_originalTime": 1689771008000
    },
    {
        "time": 1689771009000,
        "value": 139.698,
        "_internal_originalTime": 1689771009000
    },
    {
        "time": 1689771011000,
        "value": 139.699,
        "_internal_originalTime": 1689771011000
    },
    {
        "time": 1689771013000,
        "value": 139.698,
        "_internal_originalTime": 1689771013000
    },
    {
        "time": 1689771014000,
        "value": 139.702,
        "_internal_originalTime": 1689771014000
    },
    {
        "time": 1689771015000,
        "value": 139.704,
        "_internal_originalTime": 1689771015000
    },
    {
        "time": 1689771016000,
        "value": 139.706,
        "_internal_originalTime": 1689771016000
    },
    {
        "time": 1689771018000,
        "value": 139.709,
        "_internal_originalTime": 1689771018000
    },
    {
        "time": 1689771019000,
        "value": 139.694,
        "_internal_originalTime": 1689771019000
    },
    {
        "time": 1689771021000,
        "value": 139.696,
        "_internal_originalTime": 1689771021000
    },
    {
        "time": 1689771022000,
        "value": 139.69,
        "_internal_originalTime": 1689771022000
    },
    {
        "time": 1689771023000,
        "value": 139.694,
        "_internal_originalTime": 1689771023000
    },
    {
        "time": 1689771024000,
        "value": 139.702,
        "_internal_originalTime": 1689771024000
    },
    {
        "time": 1689771026000,
        "value": 139.7,
        "_internal_originalTime": 1689771026000
    },
    {
        "time": 1689771028000,
        "value": 139.701,
        "_internal_originalTime": 1689771028000
    },
    {
        "time": 1689771029000,
        "value": 139.687,
        "_internal_originalTime": 1689771029000
    },
    {
        "time": 1689771030000,
        "value": 139.683,
        "_internal_originalTime": 1689771030000
    },
    {
        "time": 1689771031000,
        "value": 139.689,
        "_internal_originalTime": 1689771031000
    },
    {
        "time": 1689771032000,
        "value": 139.692,
        "_internal_originalTime": 1689771032000
    },
    {
        "time": 1689771034000,
        "value": 139.689,
        "_internal_originalTime": 1689771034000
    },
    {
        "time": 1689771035000,
        "value": 139.682,
        "_internal_originalTime": 1689771035000
    },
    {
        "time": 1689771037000,
        "value": 139.687,
        "_internal_originalTime": 1689771037000
    },
    {
        "time": 1689771038000,
        "value": 139.684,
        "_internal_originalTime": 1689771038000
    },
    {
        "time": 1689771039000,
        "value": 139.681,
        "_internal_originalTime": 1689771039000
    },
    {
        "time": 1689771041000,
        "value": 139.689,
        "_internal_originalTime": 1689771041000
    },
    {
        "time": 1689771042000,
        "value": 139.691,
        "_internal_originalTime": 1689771042000
    },
    {
        "time": 1689771044000,
        "value": 139.685,
        "_internal_originalTime": 1689771044000
    },
    {
        "time": 1689771045000,
        "value": 139.687,
        "_internal_originalTime": 1689771045000
    },
    {
        "time": 1689771047000,
        "value": 139.685,
        "_internal_originalTime": 1689771047000
    },
    {
        "time": 1689771048000,
        "value": 139.683,
        "_internal_originalTime": 1689771048000
    },
    {
        "time": 1689771049000,
        "value": 139.687,
        "_internal_originalTime": 1689771049000
    },
    {
        "time": 1689771050000,
        "value": 139.684,
        "_internal_originalTime": 1689771050000
    },
    {
        "time": 1689771051000,
        "value": 139.685,
        "_internal_originalTime": 1689771051000
    },
    {
        "time": 1689771053000,
        "value": 139.688,
        "_internal_originalTime": 1689771053000
    },
    {
        "time": 1689771055000,
        "value": 139.69,
        "_internal_originalTime": 1689771055000
    },
    {
        "time": 1689771056000,
        "value": 139.697,
        "_internal_originalTime": 1689771056000
    },
    {
        "time": 1689771057000,
        "value": 139.695,
        "_internal_originalTime": 1689771057000
    },
    {
        "time": 1689771060000,
        "value": 139.692,
        "_internal_originalTime": 1689771060000
    },
    {
        "time": 1689771061000,
        "value": 139.693,
        "_internal_originalTime": 1689771061000
    },
    {
        "time": 1689771062000,
        "value": 139.693,
        "_internal_originalTime": 1689771062000
    },
    {
        "time": 1689771063000,
        "value": 139.704,
        "_internal_originalTime": 1689771063000
    },
    {
        "time": 1689771064000,
        "value": 139.705,
        "_internal_originalTime": 1689771064000
    },
    {
        "time": 1689771066000,
        "value": 139.701,
        "_internal_originalTime": 1689771066000
    },
    {
        "time": 1689771067000,
        "value": 139.702,
        "_internal_originalTime": 1689771067000
    },
    {
        "time": 1689771068000,
        "value": 139.701,
        "_internal_originalTime": 1689771068000
    },
    {
        "time": 1689771070000,
        "value": 139.692,
        "_internal_originalTime": 1689771070000
    },
    {
        "time": 1689771072000,
        "value": 139.696,
        "_internal_originalTime": 1689771072000
    },
    {
        "time": 1689771074000,
        "value": 139.701,
        "_internal_originalTime": 1689771074000
    },
    {
        "time": 1689771076000,
        "value": 139.7,
        "_internal_originalTime": 1689771076000
    },
    {
        "time": 1689771077000,
        "value": 139.704,
        "_internal_originalTime": 1689771077000
    },
    {
        "time": 1689771078000,
        "value": 139.702,
        "_internal_originalTime": 1689771078000
    },
    {
        "time": 1689771079000,
        "value": 139.702,
        "_internal_originalTime": 1689771079000
    },
    {
        "time": 1689771081000,
        "value": 139.702,
        "_internal_originalTime": 1689771081000
    },
    {
        "time": 1689771082000,
        "value": 139.702,
        "_internal_originalTime": 1689771082000
    },
    {
        "time": 1689771084000,
        "value": 139.707,
        "_internal_originalTime": 1689771084000
    },
    {
        "time": 1689771085000,
        "value": 139.715,
        "_internal_originalTime": 1689771085000
    },
    {
        "time": 1689771086000,
        "value": 139.721,
        "_internal_originalTime": 1689771086000
    },
    {
        "time": 1689771087000,
        "value": 139.721,
        "_internal_originalTime": 1689771087000
    },
    {
        "time": 1689771090000,
        "value": 139.722,
        "_internal_originalTime": 1689771090000
    },
    {
        "time": 1689771092000,
        "value": 139.713,
        "_internal_originalTime": 1689771092000
    },
    {
        "time": 1689771093000,
        "value": 139.705,
        "_internal_originalTime": 1689771093000
    },
    {
        "time": 1689771094000,
        "value": 139.702,
        "_internal_originalTime": 1689771094000
    },
    {
        "time": 1689771095000,
        "value": 139.702,
        "_internal_originalTime": 1689771095000
    },
    {
        "time": 1689771097000,
        "value": 139.701,
        "_internal_originalTime": 1689771097000
    },
    {
        "time": 1689771098000,
        "value": 139.703,
        "_internal_originalTime": 1689771098000
    },
    {
        "time": 1689771099000,
        "value": 139.71,
        "_internal_originalTime": 1689771099000
    },
    {
        "time": 1689771101000,
        "value": 139.714,
        "_internal_originalTime": 1689771101000
    },
    {
        "time": 1689771102000,
        "value": 139.728,
        "_internal_originalTime": 1689771102000
    },
    {
        "time": 1689771104000,
        "value": 139.731,
        "_internal_originalTime": 1689771104000
    },
    {
        "time": 1689771105000,
        "value": 139.733,
        "_internal_originalTime": 1689771105000
    },
    {
        "time": 1689771107000,
        "value": 139.736,
        "_internal_originalTime": 1689771107000
    },
    {
        "time": 1689771108000,
        "value": 139.735,
        "_internal_originalTime": 1689771108000
    },
    {
        "time": 1689771109000,
        "value": 139.732,
        "_internal_originalTime": 1689771109000
    },
    {
        "time": 1689771111000,
        "value": 139.728,
        "_internal_originalTime": 1689771111000
    },
    {
        "time": 1689771112000,
        "value": 139.73,
        "_internal_originalTime": 1689771112000
    },
    {
        "time": 1689771114000,
        "value": 139.731,
        "_internal_originalTime": 1689771114000
    },
    {
        "time": 1689771115000,
        "value": 139.732,
        "_internal_originalTime": 1689771115000
    },
    {
        "time": 1689771116000,
        "value": 139.726,
        "_internal_originalTime": 1689771116000
    },
    {
        "time": 1689771118000,
        "value": 139.728,
        "_internal_originalTime": 1689771118000
    },
    {
        "time": 1689771120000,
        "value": 139.726,
        "_internal_originalTime": 1689771120000
    },
    {
        "time": 1689771121000,
        "value": 139.735,
        "_internal_originalTime": 1689771121000
    },
    {
        "time": 1689771122000,
        "value": 139.731,
        "_internal_originalTime": 1689771122000
    },
    {
        "time": 1689771124000,
        "value": 139.732,
        "_internal_originalTime": 1689771124000
    },
    {
        "time": 1689771125000,
        "value": 139.729,
        "_internal_originalTime": 1689771125000
    },
    {
        "time": 1689771127000,
        "value": 139.727,
        "_internal_originalTime": 1689771127000
    },
    {
        "time": 1689771128000,
        "value": 139.725,
        "_internal_originalTime": 1689771128000
    },
    {
        "time": 1689771129000,
        "value": 139.722,
        "_internal_originalTime": 1689771129000
    },
    {
        "time": 1689771130000,
        "value": 139.719,
        "_internal_originalTime": 1689771130000
    },
    {
        "time": 1689771132000,
        "value": 139.722,
        "_internal_originalTime": 1689771132000
    },
    {
        "time": 1689771133000,
        "value": 139.728,
        "_internal_originalTime": 1689771133000
    },
    {
        "time": 1689771134000,
        "value": 139.724,
        "_internal_originalTime": 1689771134000
    },
    {
        "time": 1689771135000,
        "value": 139.727,
        "_internal_originalTime": 1689771135000
    },
    {
        "time": 1689771136000,
        "value": 139.727,
        "_internal_originalTime": 1689771136000
    },
    {
        "time": 1689771137000,
        "value": 139.727,
        "_internal_originalTime": 1689771137000
    },
    {
        "time": 1689771138000,
        "value": 139.72,
        "_internal_originalTime": 1689771138000
    },
    {
        "time": 1689771140000,
        "value": 139.728,
        "_internal_originalTime": 1689771140000
    },
    {
        "time": 1689771141000,
        "value": 139.725,
        "_internal_originalTime": 1689771141000
    },
    {
        "time": 1689771142000,
        "value": 139.725,
        "_internal_originalTime": 1689771142000
    },
    {
        "time": 1689771144000,
        "value": 139.715,
        "_internal_originalTime": 1689771144000
    },
    {
        "time": 1689771145000,
        "value": 139.718,
        "_internal_originalTime": 1689771145000
    },
    {
        "time": 1689771146000,
        "value": 139.723,
        "_internal_originalTime": 1689771146000
    },
    {
        "time": 1689771148000,
        "value": 139.717,
        "_internal_originalTime": 1689771148000
    },
    {
        "time": 1689771149000,
        "value": 139.712,
        "_internal_originalTime": 1689771149000
    },
    {
        "time": 1689771150000,
        "value": 139.719,
        "_internal_originalTime": 1689771150000
    },
    {
        "time": 1689771152000,
        "value": 139.719,
        "_internal_originalTime": 1689771152000
    },
    {
        "time": 1689771153000,
        "value": 139.716,
        "_internal_originalTime": 1689771153000
    },
    {
        "time": 1689771155000,
        "value": 139.717,
        "_internal_originalTime": 1689771155000
    },
    {
        "time": 1689771156000,
        "value": 139.714,
        "_internal_originalTime": 1689771156000
    },
    {
        "time": 1689771157000,
        "value": 139.711,
        "_internal_originalTime": 1689771157000
    },
    {
        "time": 1689771160000,
        "value": 139.724,
        "_internal_originalTime": 1689771160000
    },
    {
        "time": 1689771162000,
        "value": 139.721,
        "_internal_originalTime": 1689771162000
    },
    {
        "time": 1689771163000,
        "value": 139.731,
        "_internal_originalTime": 1689771163000
    },
    {
        "time": 1689771164000,
        "value": 139.727,
        "_internal_originalTime": 1689771164000
    },
    {
        "time": 1689771165000,
        "value": 139.73,
        "_internal_originalTime": 1689771165000
    },
    {
        "time": 1689771166000,
        "value": 139.728,
        "_internal_originalTime": 1689771166000
    },
    {
        "time": 1689771167000,
        "value": 139.733,
        "_internal_originalTime": 1689771167000
    },
    {
        "time": 1689771168000,
        "value": 139.732,
        "_internal_originalTime": 1689771168000
    },
    {
        "time": 1689771170000,
        "value": 139.73,
        "_internal_originalTime": 1689771170000
    },
    {
        "time": 1689771171000,
        "value": 139.731,
        "_internal_originalTime": 1689771171000
    },
    {
        "time": 1689771172000,
        "value": 139.736,
        "_internal_originalTime": 1689771172000
    },
    {
        "time": 1689771173000,
        "value": 139.724,
        "_internal_originalTime": 1689771173000
    },
    {
        "time": 1689771175000,
        "value": 139.725,
        "_internal_originalTime": 1689771175000
    },
    {
        "time": 1689771176000,
        "value": 139.718,
        "_internal_originalTime": 1689771176000
    },
    {
        "time": 1689771177000,
        "value": 139.718,
        "_internal_originalTime": 1689771177000
    },
    {
        "time": 1689771180000,
        "value": 139.714,
        "_internal_originalTime": 1689771180000
    },
    {
        "time": 1689771182000,
        "value": 139.714,
        "_internal_originalTime": 1689771182000
    },
    {
        "time": 1689771184000,
        "value": 139.71,
        "_internal_originalTime": 1689771184000
    },
    {
        "time": 1689771185000,
        "value": 139.713,
        "_internal_originalTime": 1689771185000
    },
    {
        "time": 1689771186000,
        "value": 139.714,
        "_internal_originalTime": 1689771186000
    },
    {
        "time": 1689771188000,
        "value": 139.713,
        "_internal_originalTime": 1689771188000
    },
    {
        "time": 1689771189000,
        "value": 139.714,
        "_internal_originalTime": 1689771189000
    },
    {
        "time": 1689771191000,
        "value": 139.713,
        "_internal_originalTime": 1689771191000
    },
    {
        "time": 1689771192000,
        "value": 139.714,
        "_internal_originalTime": 1689771192000
    },
    {
        "time": 1689771193000,
        "value": 139.715,
        "_internal_originalTime": 1689771193000
    },
    {
        "time": 1689771195000,
        "value": 139.713,
        "_internal_originalTime": 1689771195000
    },
    {
        "time": 1689771196000,
        "value": 139.713,
        "_internal_originalTime": 1689771196000
    },
    {
        "time": 1689771197000,
        "value": 139.713,
        "_internal_originalTime": 1689771197000
    },
    {
        "time": 1689771199000,
        "value": 139.711,
        "_internal_originalTime": 1689771199000
    },
    {
        "time": 1689771200000,
        "value": 139.708,
        "_internal_originalTime": 1689771200000
    },
    {
        "time": 1689771201000,
        "value": 139.711,
        "_internal_originalTime": 1689771201000
    },
    {
        "time": 1689771202000,
        "value": 139.716,
        "_internal_originalTime": 1689771202000
    },
    {
        "time": 1689771204000,
        "value": 139.721,
        "_internal_originalTime": 1689771204000
    },
    {
        "time": 1689771206000,
        "value": 139.723,
        "_internal_originalTime": 1689771206000
    },
    {
        "time": 1689771207000,
        "value": 139.724,
        "_internal_originalTime": 1689771207000
    },
    {
        "time": 1689771208000,
        "value": 139.723,
        "_internal_originalTime": 1689771208000
    },
    {
        "time": 1689771209000,
        "value": 139.724,
        "_internal_originalTime": 1689771209000
    },
    {
        "time": 1689771210000,
        "value": 139.72,
        "_internal_originalTime": 1689771210000
    },
    {
        "time": 1689771212000,
        "value": 139.727,
        "_internal_originalTime": 1689771212000
    },
    {
        "time": 1689771213000,
        "value": 139.725,
        "_internal_originalTime": 1689771213000
    },
    {
        "time": 1689771214000,
        "value": 139.717,
        "_internal_originalTime": 1689771214000
    },
    {
        "time": 1689771215000,
        "value": 139.722,
        "_internal_originalTime": 1689771215000
    },
    {
        "time": 1689771217000,
        "value": 139.722,
        "_internal_originalTime": 1689771217000
    },
    {
        "time": 1689771218000,
        "value": 139.718,
        "_internal_originalTime": 1689771218000
    },
    {
        "time": 1689771220000,
        "value": 139.709,
        "_internal_originalTime": 1689771220000
    },
    {
        "time": 1689771221000,
        "value": 139.713,
        "_internal_originalTime": 1689771221000
    },
    {
        "time": 1689771222000,
        "value": 139.72,
        "_internal_originalTime": 1689771222000
    },
    {
        "time": 1689771224000,
        "value": 139.724,
        "_internal_originalTime": 1689771224000
    },
    {
        "time": 1689771225000,
        "value": 139.723,
        "_internal_originalTime": 1689771225000
    },
    {
        "time": 1689771227000,
        "value": 139.713,
        "_internal_originalTime": 1689771227000
    },
    {
        "time": 1689771228000,
        "value": 139.712,
        "_internal_originalTime": 1689771228000
    },
    {
        "time": 1689771230000,
        "value": 139.713,
        "_internal_originalTime": 1689771230000
    },
    {
        "time": 1689771231000,
        "value": 139.711,
        "_internal_originalTime": 1689771231000
    },
    {
        "time": 1689771233000,
        "value": 139.714,
        "_internal_originalTime": 1689771233000
    },
    {
        "time": 1689771234000,
        "value": 139.714,
        "_internal_originalTime": 1689771234000
    },
    {
        "time": 1689771236000,
        "value": 139.716,
        "_internal_originalTime": 1689771236000
    },
    {
        "time": 1689771237000,
        "value": 139.712,
        "_internal_originalTime": 1689771237000
    },
    {
        "time": 1689771238000,
        "value": 139.708,
        "_internal_originalTime": 1689771238000
    },
    {
        "time": 1689771239000,
        "value": 139.713,
        "_internal_originalTime": 1689771239000
    },
    {
        "time": 1689771240000,
        "value": 139.712,
        "_internal_originalTime": 1689771240000
    },
    {
        "time": 1689771242000,
        "value": 139.715,
        "_internal_originalTime": 1689771242000
    },
    {
        "time": 1689771243000,
        "value": 139.722,
        "_internal_originalTime": 1689771243000
    },
    {
        "time": 1689771244000,
        "value": 139.725,
        "_internal_originalTime": 1689771244000
    },
    {
        "time": 1689771245000,
        "value": 139.726,
        "_internal_originalTime": 1689771245000
    },
    {
        "time": 1689771247000,
        "value": 139.712,
        "_internal_originalTime": 1689771247000
    },
    {
        "time": 1689771248000,
        "value": 139.705,
        "_internal_originalTime": 1689771248000
    },
    {
        "time": 1689771249000,
        "value": 139.694,
        "_internal_originalTime": 1689771249000
    },
    {
        "time": 1689771250000,
        "value": 139.687,
        "_internal_originalTime": 1689771250000
    },
    {
        "time": 1689771252000,
        "value": 139.685,
        "_internal_originalTime": 1689771252000
    },
    {
        "time": 1689771253000,
        "value": 139.692,
        "_internal_originalTime": 1689771253000
    },
    {
        "time": 1689771255000,
        "value": 139.709,
        "_internal_originalTime": 1689771255000
    },
    {
        "time": 1689771256000,
        "value": 139.713,
        "_internal_originalTime": 1689771256000
    },
    {
        "time": 1689771257000,
        "value": 139.699,
        "_internal_originalTime": 1689771257000
    },
    {
        "time": 1689771259000,
        "value": 139.699,
        "_internal_originalTime": 1689771259000
    },
    {
        "time": 1689771261000,
        "value": 139.704,
        "_internal_originalTime": 1689771261000
    },
    {
        "time": 1689771262000,
        "value": 139.695,
        "_internal_originalTime": 1689771262000
    },
    {
        "time": 1689771263000,
        "value": 139.693,
        "_internal_originalTime": 1689771263000
    },
    {
        "time": 1689771264000,
        "value": 139.697,
        "_internal_originalTime": 1689771264000
    },
    {
        "time": 1689771266000,
        "value": 139.697,
        "_internal_originalTime": 1689771266000
    },
    {
        "time": 1689771267000,
        "value": 139.704,
        "_internal_originalTime": 1689771267000
    },
    {
        "time": 1689771268000,
        "value": 139.699,
        "_internal_originalTime": 1689771268000
    },
    {
        "time": 1689771270000,
        "value": 139.69,
        "_internal_originalTime": 1689771270000
    },
    {
        "time": 1689771271000,
        "value": 139.692,
        "_internal_originalTime": 1689771271000
    },
    {
        "time": 1689771272000,
        "value": 139.688,
        "_internal_originalTime": 1689771272000
    },
    {
        "time": 1689771274000,
        "value": 139.691,
        "_internal_originalTime": 1689771274000
    },
    {
        "time": 1689771275000,
        "value": 139.68,
        "_internal_originalTime": 1689771275000
    },
    {
        "time": 1689771276000,
        "value": 139.685,
        "_internal_originalTime": 1689771276000
    },
    {
        "time": 1689771278000,
        "value": 139.682,
        "_internal_originalTime": 1689771278000
    },
    {
        "time": 1689771280000,
        "value": 139.688,
        "_internal_originalTime": 1689771280000
    },
    {
        "time": 1689771281000,
        "value": 139.693,
        "_internal_originalTime": 1689771281000
    },
    {
        "time": 1689771282000,
        "value": 139.693,
        "_internal_originalTime": 1689771282000
    },
    {
        "time": 1689771284000,
        "value": 139.69,
        "_internal_originalTime": 1689771284000
    },
    {
        "time": 1689771285000,
        "value": 139.693,
        "_internal_originalTime": 1689771285000
    },
    {
        "time": 1689771287000,
        "value": 139.692,
        "_internal_originalTime": 1689771287000
    },
    {
        "time": 1689771289000,
        "value": 139.682,
        "_internal_originalTime": 1689771289000
    },
    {
        "time": 1689771291000,
        "value": 139.683,
        "_internal_originalTime": 1689771291000
    },
    {
        "time": 1689771292000,
        "value": 139.688,
        "_internal_originalTime": 1689771292000
    },
    {
        "time": 1689771293000,
        "value": 139.694,
        "_internal_originalTime": 1689771293000
    },
    {
        "time": 1689771295000,
        "value": 139.697,
        "_internal_originalTime": 1689771295000
    },
    {
        "time": 1689771296000,
        "value": 139.693,
        "_internal_originalTime": 1689771296000
    },
    {
        "time": 1689771297000,
        "value": 139.694,
        "_internal_originalTime": 1689771297000
    },
    {
        "time": 1689771298000,
        "value": 139.682,
        "_internal_originalTime": 1689771298000
    },
    {
        "time": 1689771300000,
        "value": 139.677,
        "_internal_originalTime": 1689771300000
    },
    {
        "time": 1689771301000,
        "value": 139.67,
        "_internal_originalTime": 1689771301000
    },
    {
        "time": 1689771303000,
        "value": 139.666,
        "_internal_originalTime": 1689771303000
    },
    {
        "time": 1689771304000,
        "value": 139.669,
        "_internal_originalTime": 1689771304000
    },
    {
        "time": 1689771305000,
        "value": 139.672,
        "_internal_originalTime": 1689771305000
    },
    {
        "time": 1689771306000,
        "value": 139.675,
        "_internal_originalTime": 1689771306000
    },
    {
        "time": 1689771308000,
        "value": 139.672,
        "_internal_originalTime": 1689771308000
    },
    {
        "time": 1689771309000,
        "value": 139.651,
        "_internal_originalTime": 1689771309000
    },
    {
        "time": 1689771311000,
        "value": 139.64,
        "_internal_originalTime": 1689771311000
    },
    {
        "time": 1689771312000,
        "value": 139.642,
        "_internal_originalTime": 1689771312000
    },
    {
        "time": 1689771313000,
        "value": 139.639,
        "_internal_originalTime": 1689771313000
    },
    {
        "time": 1689771315000,
        "value": 139.625,
        "_internal_originalTime": 1689771315000
    },
    {
        "time": 1689771316000,
        "value": 139.628,
        "_internal_originalTime": 1689771316000
    },
    {
        "time": 1689771317000,
        "value": 139.622,
        "_internal_originalTime": 1689771317000
    },
    {
        "time": 1689771319000,
        "value": 139.641,
        "_internal_originalTime": 1689771319000
    },
    {
        "time": 1689771321000,
        "value": 139.655,
        "_internal_originalTime": 1689771321000
    },
    {
        "time": 1689771322000,
        "value": 139.646,
        "_internal_originalTime": 1689771322000
    },
    {
        "time": 1689771324000,
        "value": 139.653,
        "_internal_originalTime": 1689771324000
    },
    {
        "time": 1689771325000,
        "value": 139.646,
        "_internal_originalTime": 1689771325000
    },
    {
        "time": 1689771327000,
        "value": 139.641,
        "_internal_originalTime": 1689771327000
    },
    {
        "time": 1689771328000,
        "value": 139.632,
        "_internal_originalTime": 1689771328000
    },
    {
        "time": 1689771330000,
        "value": 139.645,
        "_internal_originalTime": 1689771330000
    },
    {
        "time": 1689771331000,
        "value": 139.639,
        "_internal_originalTime": 1689771331000
    },
    {
        "time": 1689771332000,
        "value": 139.639,
        "_internal_originalTime": 1689771332000
    },
    {
        "time": 1689771333000,
        "value": 139.634,
        "_internal_originalTime": 1689771333000
    },
    {
        "time": 1689771335000,
        "value": 139.638,
        "_internal_originalTime": 1689771335000
    },
    {
        "time": 1689771336000,
        "value": 139.635,
        "_internal_originalTime": 1689771336000
    },
    {
        "time": 1689771337000,
        "value": 139.609,
        "_internal_originalTime": 1689771337000
    },
    {
        "time": 1689771338000,
        "value": 139.615,
        "_internal_originalTime": 1689771338000
    },
    {
        "time": 1689771340000,
        "value": 139.621,
        "_internal_originalTime": 1689771340000
    },
    {
        "time": 1689771341000,
        "value": 139.613,
        "_internal_originalTime": 1689771341000
    },
    {
        "time": 1689771343000,
        "value": 139.612,
        "_internal_originalTime": 1689771343000
    },
    {
        "time": 1689771344000,
        "value": 139.61,
        "_internal_originalTime": 1689771344000
    },
    {
        "time": 1689771346000,
        "value": 139.616,
        "_internal_originalTime": 1689771346000
    },
    {
        "time": 1689771347000,
        "value": 139.611,
        "_internal_originalTime": 1689771347000
    },
    {
        "time": 1689771348000,
        "value": 139.625,
        "_internal_originalTime": 1689771348000
    },
    {
        "time": 1689771349000,
        "value": 139.62,
        "_internal_originalTime": 1689771349000
    },
    {
        "time": 1689771350000,
        "value": 139.621,
        "_internal_originalTime": 1689771350000
    },
    {
        "time": 1689771352000,
        "value": 139.621,
        "_internal_originalTime": 1689771352000
    },
    {
        "time": 1689771353000,
        "value": 139.624,
        "_internal_originalTime": 1689771353000
    },
    {
        "time": 1689771354000,
        "value": 139.605,
        "_internal_originalTime": 1689771354000
    },
    {
        "time": 1689771356000,
        "value": 139.602,
        "_internal_originalTime": 1689771356000
    },
    {
        "time": 1689771357000,
        "value": 139.609,
        "_internal_originalTime": 1689771357000
    },
    {
        "time": 1689771358000,
        "value": 139.606,
        "_internal_originalTime": 1689771358000
    },
    {
        "time": 1689771360000,
        "value": 139.601,
        "_internal_originalTime": 1689771360000
    },
    {
        "time": 1689771362000,
        "value": 139.597,
        "_internal_originalTime": 1689771362000
    },
    {
        "time": 1689771363000,
        "value": 139.592,
        "_internal_originalTime": 1689771363000
    },
    {
        "time": 1689771364000,
        "value": 139.596,
        "_internal_originalTime": 1689771364000
    },
    {
        "time": 1689771366000,
        "value": 139.596,
        "_internal_originalTime": 1689771366000
    },
    {
        "time": 1689771367000,
        "value": 139.6,
        "_internal_originalTime": 1689771367000
    },
    {
        "time": 1689771368000,
        "value": 139.605,
        "_internal_originalTime": 1689771368000
    },
    {
        "time": 1689771369000,
        "value": 139.609,
        "_internal_originalTime": 1689771369000
    },
    {
        "time": 1689771371000,
        "value": 139.607,
        "_internal_originalTime": 1689771371000
    },
    {
        "time": 1689771375000,
        "value": 139.608,
        "_internal_originalTime": 1689771375000
    },
    {
        "time": 1689771377000,
        "value": 139.605,
        "_internal_originalTime": 1689771377000
    },
    {
        "time": 1689771378000,
        "value": 139.612,
        "_internal_originalTime": 1689771378000
    },
    {
        "time": 1689771380000,
        "value": 139.624,
        "_internal_originalTime": 1689771380000
    },
    {
        "time": 1689771381000,
        "value": 139.617,
        "_internal_originalTime": 1689771381000
    },
    {
        "time": 1689771382000,
        "value": 139.621,
        "_internal_originalTime": 1689771382000
    },
    {
        "time": 1689771383000,
        "value": 139.616,
        "_internal_originalTime": 1689771383000
    },
    {
        "time": 1689771385000,
        "value": 139.618,
        "_internal_originalTime": 1689771385000
    },
    {
        "time": 1689771386000,
        "value": 139.608,
        "_internal_originalTime": 1689771386000
    },
    {
        "time": 1689771387000,
        "value": 139.609,
        "_internal_originalTime": 1689771387000
    },
    {
        "time": 1689771388000,
        "value": 139.611,
        "_internal_originalTime": 1689771388000
    },
    {
        "time": 1689771390000,
        "value": 139.599,
        "_internal_originalTime": 1689771390000
    },
    {
        "time": 1689771392000,
        "value": 139.618,
        "_internal_originalTime": 1689771392000
    },
    {
        "time": 1689771393000,
        "value": 139.611,
        "_internal_originalTime": 1689771393000
    },
    {
        "time": 1689771394000,
        "value": 139.606,
        "_internal_originalTime": 1689771394000
    },
    {
        "time": 1689771396000,
        "value": 139.612,
        "_internal_originalTime": 1689771396000
    },
    {
        "time": 1689771397000,
        "value": 139.603,
        "_internal_originalTime": 1689771397000
    },
    {
        "time": 1689771398000,
        "value": 139.6,
        "_internal_originalTime": 1689771398000
    },
    {
        "time": 1689771400000,
        "value": 139.597,
        "_internal_originalTime": 1689771400000
    },
    {
        "time": 1689771401000,
        "value": 139.606,
        "_internal_originalTime": 1689771401000
    },
    {
        "time": 1689771402000,
        "value": 139.609,
        "_internal_originalTime": 1689771402000
    },
    {
        "time": 1689771404000,
        "value": 139.613,
        "_internal_originalTime": 1689771404000
    },
    {
        "time": 1689771405000,
        "value": 139.598,
        "_internal_originalTime": 1689771405000
    },
    {
        "time": 1689771406000,
        "value": 139.606,
        "_internal_originalTime": 1689771406000
    },
    {
        "time": 1689771408000,
        "value": 139.607,
        "_internal_originalTime": 1689771408000
    },
    {
        "time": 1689771411000,
        "value": 139.617,
        "_internal_originalTime": 1689771411000
    },
    {
        "time": 1689771412000,
        "value": 139.606,
        "_internal_originalTime": 1689771412000
    },
    {
        "time": 1689771413000,
        "value": 139.613,
        "_internal_originalTime": 1689771413000
    },
    {
        "time": 1689771414000,
        "value": 139.61,
        "_internal_originalTime": 1689771414000
    },
    {
        "time": 1689771415000,
        "value": 139.608,
        "_internal_originalTime": 1689771415000
    },
    {
        "time": 1689771417000,
        "value": 139.61,
        "_internal_originalTime": 1689771417000
    },
    {
        "time": 1689771419000,
        "value": 139.594,
        "_internal_originalTime": 1689771419000
    },
    {
        "time": 1689771420000,
        "value": 139.598,
        "_internal_originalTime": 1689771420000
    },
    {
        "time": 1689771422000,
        "value": 139.597,
        "_internal_originalTime": 1689771422000
    },
    {
        "time": 1689771423000,
        "value": 139.597,
        "_internal_originalTime": 1689771423000
    },
    {
        "time": 1689771424000,
        "value": 139.603,
        "_internal_originalTime": 1689771424000
    },
    {
        "time": 1689771425000,
        "value": 139.605,
        "_internal_originalTime": 1689771425000
    },
    {
        "time": 1689771426000,
        "value": 139.602,
        "_internal_originalTime": 1689771426000
    },
    {
        "time": 1689771428000,
        "value": 139.604,
        "_internal_originalTime": 1689771428000
    },
    {
        "time": 1689771429000,
        "value": 139.594,
        "_internal_originalTime": 1689771429000
    },
    {
        "time": 1689771430000,
        "value": 139.594,
        "_internal_originalTime": 1689771430000
    },
    {
        "time": 1689771431000,
        "value": 139.595,
        "_internal_originalTime": 1689771431000
    },
    {
        "time": 1689771432000,
        "value": 139.594,
        "_internal_originalTime": 1689771432000
    },
    {
        "time": 1689771435000,
        "value": 139.582,
        "_internal_originalTime": 1689771435000
    },
    {
        "time": 1689771436000,
        "value": 139.575,
        "_internal_originalTime": 1689771436000
    },
    {
        "time": 1689771437000,
        "value": 139.569,
        "_internal_originalTime": 1689771437000
    },
    {
        "time": 1689771438000,
        "value": 139.569,
        "_internal_originalTime": 1689771438000
    },
    {
        "time": 1689771440000,
        "value": 139.575,
        "_internal_originalTime": 1689771440000
    },
    {
        "time": 1689771441000,
        "value": 139.574,
        "_internal_originalTime": 1689771441000
    },
    {
        "time": 1689771442000,
        "value": 139.573,
        "_internal_originalTime": 1689771442000
    },
    {
        "time": 1689771444000,
        "value": 139.576,
        "_internal_originalTime": 1689771444000
    },
    {
        "time": 1689771445000,
        "value": 139.576,
        "_internal_originalTime": 1689771445000
    },
    {
        "time": 1689771447000,
        "value": 139.566,
        "_internal_originalTime": 1689771447000
    },
    {
        "time": 1689771448000,
        "value": 139.575,
        "_internal_originalTime": 1689771448000
    },
    {
        "time": 1689771450000,
        "value": 139.579,
        "_internal_originalTime": 1689771450000
    },
    {
        "time": 1689771451000,
        "value": 139.583,
        "_internal_originalTime": 1689771451000
    },
    {
        "time": 1689771452000,
        "value": 139.59,
        "_internal_originalTime": 1689771452000
    },
    {
        "time": 1689771453000,
        "value": 139.589,
        "_internal_originalTime": 1689771453000
    },
    {
        "time": 1689771455000,
        "value": 139.587,
        "_internal_originalTime": 1689771455000
    },
    {
        "time": 1689771456000,
        "value": 139.592,
        "_internal_originalTime": 1689771456000
    },
    {
        "time": 1689771457000,
        "value": 139.598,
        "_internal_originalTime": 1689771457000
    },
    {
        "time": 1689771459000,
        "value": 139.601,
        "_internal_originalTime": 1689771459000
    },
    {
        "time": 1689771460000,
        "value": 139.598,
        "_internal_originalTime": 1689771460000
    },
    {
        "time": 1689771462000,
        "value": 139.598,
        "_internal_originalTime": 1689771462000
    },
    {
        "time": 1689771464000,
        "value": 139.598,
        "_internal_originalTime": 1689771464000
    },
    {
        "time": 1689771465000,
        "value": 139.597,
        "_internal_originalTime": 1689771465000
    },
    {
        "time": 1689771466000,
        "value": 139.598,
        "_internal_originalTime": 1689771466000
    },
    {
        "time": 1689771468000,
        "value": 139.596,
        "_internal_originalTime": 1689771468000
    },
    {
        "time": 1689771469000,
        "value": 139.597,
        "_internal_originalTime": 1689771469000
    },
    {
        "time": 1689771470000,
        "value": 139.599,
        "_internal_originalTime": 1689771470000
    },
    {
        "time": 1689771472000,
        "value": 139.602,
        "_internal_originalTime": 1689771472000
    },
    {
        "time": 1689771473000,
        "value": 139.604,
        "_internal_originalTime": 1689771473000
    },
    {
        "time": 1689771474000,
        "value": 139.598,
        "_internal_originalTime": 1689771474000
    },
    {
        "time": 1689771476000,
        "value": 139.606,
        "_internal_originalTime": 1689771476000
    },
    {
        "time": 1689771477000,
        "value": 139.628,
        "_internal_originalTime": 1689771477000
    },
    {
        "time": 1689771478000,
        "value": 139.628,
        "_internal_originalTime": 1689771478000
    },
    {
        "time": 1689771480000,
        "value": 139.624,
        "_internal_originalTime": 1689771480000
    },
    {
        "time": 1689771481000,
        "value": 139.629,
        "_internal_originalTime": 1689771481000
    },
    {
        "time": 1689771482000,
        "value": 139.634,
        "_internal_originalTime": 1689771482000
    },
    {
        "time": 1689771483000,
        "value": 139.636,
        "_internal_originalTime": 1689771483000
    },
    {
        "time": 1689771485000,
        "value": 139.634,
        "_internal_originalTime": 1689771485000
    },
    {
        "time": 1689771486000,
        "value": 139.634,
        "_internal_originalTime": 1689771486000
    },
    {
        "time": 1689771487000,
        "value": 139.635,
        "_internal_originalTime": 1689771487000
    },
    {
        "time": 1689771489000,
        "value": 139.626,
        "_internal_originalTime": 1689771489000
    },
    {
        "time": 1689771490000,
        "value": 139.629,
        "_internal_originalTime": 1689771490000
    },
    {
        "time": 1689771491000,
        "value": 139.631,
        "_internal_originalTime": 1689771491000
    },
    {
        "time": 1689771493000,
        "value": 139.636,
        "_internal_originalTime": 1689771493000
    },
    {
        "time": 1689771494000,
        "value": 139.638,
        "_internal_originalTime": 1689771494000
    },
    {
        "time": 1689771495000,
        "value": 139.635,
        "_internal_originalTime": 1689771495000
    },
    {
        "time": 1689771496000,
        "value": 139.636,
        "_internal_originalTime": 1689771496000
    },
    {
        "time": 1689771497000,
        "value": 139.641,
        "_internal_originalTime": 1689771497000
    },
    {
        "time": 1689771499000,
        "value": 139.635,
        "_internal_originalTime": 1689771499000
    },
    {
        "time": 1689771500000,
        "value": 139.644,
        "_internal_originalTime": 1689771500000
    },
    {
        "time": 1689771501000,
        "value": 139.646,
        "_internal_originalTime": 1689771501000
    },
    {
        "time": 1689771503000,
        "value": 139.646,
        "_internal_originalTime": 1689771503000
    },
    {
        "time": 1689771504000,
        "value": 139.647,
        "_internal_originalTime": 1689771504000
    },
    {
        "time": 1689771505000,
        "value": 139.654,
        "_internal_originalTime": 1689771505000
    },
    {
        "time": 1689771507000,
        "value": 139.651,
        "_internal_originalTime": 1689771507000
    },
    {
        "time": 1689771508000,
        "value": 139.656,
        "_internal_originalTime": 1689771508000
    },
    {
        "time": 1689771510000,
        "value": 139.662,
        "_internal_originalTime": 1689771510000
    },
    {
        "time": 1689771511000,
        "value": 139.663,
        "_internal_originalTime": 1689771511000
    },
    {
        "time": 1689771513000,
        "value": 139.656,
        "_internal_originalTime": 1689771513000
    },
    {
        "time": 1689771514000,
        "value": 139.655,
        "_internal_originalTime": 1689771514000
    },
    {
        "time": 1689771515000,
        "value": 139.664,
        "_internal_originalTime": 1689771515000
    },
    {
        "time": 1689771517000,
        "value": 139.655,
        "_internal_originalTime": 1689771517000
    },
    {
        "time": 1689771518000,
        "value": 139.657,
        "_internal_originalTime": 1689771518000
    },
    {
        "time": 1689771519000,
        "value": 139.65,
        "_internal_originalTime": 1689771519000
    },
    {
        "time": 1689771520000,
        "value": 139.651,
        "_internal_originalTime": 1689771520000
    },
    {
        "time": 1689771522000,
        "value": 139.655,
        "_internal_originalTime": 1689771522000
    },
    {
        "time": 1689771524000,
        "value": 139.652,
        "_internal_originalTime": 1689771524000
    },
    {
        "time": 1689771525000,
        "value": 139.654,
        "_internal_originalTime": 1689771525000
    },
    {
        "time": 1689771526000,
        "value": 139.655,
        "_internal_originalTime": 1689771526000
    },
    {
        "time": 1689771528000,
        "value": 139.66,
        "_internal_originalTime": 1689771528000
    },
    {
        "time": 1689771529000,
        "value": 139.656,
        "_internal_originalTime": 1689771529000
    },
    {
        "time": 1689771530000,
        "value": 139.663,
        "_internal_originalTime": 1689771530000
    },
    {
        "time": 1689771532000,
        "value": 139.662,
        "_internal_originalTime": 1689771532000
    },
    {
        "time": 1689771533000,
        "value": 139.673,
        "_internal_originalTime": 1689771533000
    },
    {
        "time": 1689771534000,
        "value": 139.673,
        "_internal_originalTime": 1689771534000
    },
    {
        "time": 1689771536000,
        "value": 139.675,
        "_internal_originalTime": 1689771536000
    },
    {
        "time": 1689771537000,
        "value": 139.672,
        "_internal_originalTime": 1689771537000
    },
    {
        "time": 1689771538000,
        "value": 139.674,
        "_internal_originalTime": 1689771538000
    },
    {
        "time": 1689771539000,
        "value": 139.672,
        "_internal_originalTime": 1689771539000
    },
    {
        "time": 1689771541000,
        "value": 139.684,
        "_internal_originalTime": 1689771541000
    },
    {
        "time": 1689771542000,
        "value": 139.678,
        "_internal_originalTime": 1689771542000
    },
    {
        "time": 1689771543000,
        "value": 139.68,
        "_internal_originalTime": 1689771543000
    },
    {
        "time": 1689771544000,
        "value": 139.681,
        "_internal_originalTime": 1689771544000
    },
    {
        "time": 1689771545000,
        "value": 139.683,
        "_internal_originalTime": 1689771545000
    },
    {
        "time": 1689771547000,
        "value": 139.682,
        "_internal_originalTime": 1689771547000
    },
    {
        "time": 1689771548000,
        "value": 139.664,
        "_internal_originalTime": 1689771548000
    },
    {
        "time": 1689771549000,
        "value": 139.672,
        "_internal_originalTime": 1689771549000
    },
    {
        "time": 1689771551000,
        "value": 139.674,
        "_internal_originalTime": 1689771551000
    },
    {
        "time": 1689771552000,
        "value": 139.675,
        "_internal_originalTime": 1689771552000
    },
    {
        "time": 1689771553000,
        "value": 139.676,
        "_internal_originalTime": 1689771553000
    },
    {
        "time": 1689771556000,
        "value": 139.673,
        "_internal_originalTime": 1689771556000
    },
    {
        "time": 1689771557000,
        "value": 139.668,
        "_internal_originalTime": 1689771557000
    },
    {
        "time": 1689771559000,
        "value": 139.675,
        "_internal_originalTime": 1689771559000
    },
    {
        "time": 1689771560000,
        "value": 139.672,
        "_internal_originalTime": 1689771560000
    },
    {
        "time": 1689771561000,
        "value": 139.674,
        "_internal_originalTime": 1689771561000
    },
    {
        "time": 1689771563000,
        "value": 139.674,
        "_internal_originalTime": 1689771563000
    },
    {
        "time": 1689771564000,
        "value": 139.674,
        "_internal_originalTime": 1689771564000
    },
    {
        "time": 1689771565000,
        "value": 139.669,
        "_internal_originalTime": 1689771565000
    },
    {
        "time": 1689771567000,
        "value": 139.668,
        "_internal_originalTime": 1689771567000
    },
    {
        "time": 1689771568000,
        "value": 139.675,
        "_internal_originalTime": 1689771568000
    },
    {
        "time": 1689771570000,
        "value": 139.681,
        "_internal_originalTime": 1689771570000
    },
    {
        "time": 1689771571000,
        "value": 139.672,
        "_internal_originalTime": 1689771571000
    },
    {
        "time": 1689771572000,
        "value": 139.674,
        "_internal_originalTime": 1689771572000
    },
    {
        "time": 1689771573000,
        "value": 139.673,
        "_internal_originalTime": 1689771573000
    },
    {
        "time": 1689771575000,
        "value": 139.673,
        "_internal_originalTime": 1689771575000
    },
    {
        "time": 1689771577000,
        "value": 139.667,
        "_internal_originalTime": 1689771577000
    },
    {
        "time": 1689771578000,
        "value": 139.669,
        "_internal_originalTime": 1689771578000
    },
    {
        "time": 1689771579000,
        "value": 139.672,
        "_internal_originalTime": 1689771579000
    },
    {
        "time": 1689771581000,
        "value": 139.674,
        "_internal_originalTime": 1689771581000
    },
    {
        "time": 1689771582000,
        "value": 139.672,
        "_internal_originalTime": 1689771582000
    },
    {
        "time": 1689771584000,
        "value": 139.679,
        "_internal_originalTime": 1689771584000
    },
    {
        "time": 1689771586000,
        "value": 139.68,
        "_internal_originalTime": 1689771586000
    },
    {
        "time": 1689771587000,
        "value": 139.673,
        "_internal_originalTime": 1689771587000
    },
    {
        "time": 1689771588000,
        "value": 139.671,
        "_internal_originalTime": 1689771588000
    },
    {
        "time": 1689771590000,
        "value": 139.666,
        "_internal_originalTime": 1689771590000
    },
    {
        "time": 1689771591000,
        "value": 139.66,
        "_internal_originalTime": 1689771591000
    },
    {
        "time": 1689771593000,
        "value": 139.661,
        "_internal_originalTime": 1689771593000
    },
    {
        "time": 1689771594000,
        "value": 139.657,
        "_internal_originalTime": 1689771594000
    },
    {
        "time": 1689771595000,
        "value": 139.652,
        "_internal_originalTime": 1689771595000
    },
    {
        "time": 1689771596000,
        "value": 139.644,
        "_internal_originalTime": 1689771596000
    },
    {
        "time": 1689771597000,
        "value": 139.644,
        "_internal_originalTime": 1689771597000
    },
    {
        "time": 1689771598000,
        "value": 139.645,
        "_internal_originalTime": 1689771598000
    },
    {
        "time": 1689771599000,
        "value": 139.642,
        "_internal_originalTime": 1689771599000
    },
    {
        "time": 1689771600000,
        "value": 139.638,
        "_internal_originalTime": 1689771600000
    },
    {
        "time": 1689771601000,
        "value": 139.644,
        "_internal_originalTime": 1689771601000
    },
    {
        "time": 1689771602000,
        "value": 139.642,
        "_internal_originalTime": 1689771602000
    },
    {
        "time": 1689771604000,
        "value": 139.648,
        "_internal_originalTime": 1689771604000
    },
    {
        "time": 1689771605000,
        "value": 139.654,
        "_internal_originalTime": 1689771605000
    },
    {
        "time": 1689771606000,
        "value": 139.663,
        "_internal_originalTime": 1689771606000
    },
    {
        "time": 1689771607000,
        "value": 139.673,
        "_internal_originalTime": 1689771607000
    },
    {
        "time": 1689771609000,
        "value": 139.663,
        "_internal_originalTime": 1689771609000
    },
    {
        "time": 1689771610000,
        "value": 139.652,
        "_internal_originalTime": 1689771610000
    },
    {
        "time": 1689771611000,
        "value": 139.657,
        "_internal_originalTime": 1689771611000
    },
    {
        "time": 1689771612000,
        "value": 139.658,
        "_internal_originalTime": 1689771612000
    },
    {
        "time": 1689771614000,
        "value": 139.652,
        "_internal_originalTime": 1689771614000
    },
    {
        "time": 1689771615000,
        "value": 139.65,
        "_internal_originalTime": 1689771615000
    },
    {
        "time": 1689771616000,
        "value": 139.651,
        "_internal_originalTime": 1689771616000
    },
    {
        "time": 1689771618000,
        "value": 139.653,
        "_internal_originalTime": 1689771618000
    },
    {
        "time": 1689771619000,
        "value": 139.648,
        "_internal_originalTime": 1689771619000
    },
    {
        "time": 1689771620000,
        "value": 139.654,
        "_internal_originalTime": 1689771620000
    },
    {
        "time": 1689771621000,
        "value": 139.662,
        "_internal_originalTime": 1689771621000
    },
    {
        "time": 1689771623000,
        "value": 139.665,
        "_internal_originalTime": 1689771623000
    },
    {
        "time": 1689771624000,
        "value": 139.662,
        "_internal_originalTime": 1689771624000
    },
    {
        "time": 1689771625000,
        "value": 139.663,
        "_internal_originalTime": 1689771625000
    },
    {
        "time": 1689771627000,
        "value": 139.663,
        "_internal_originalTime": 1689771627000
    },
    {
        "time": 1689771628000,
        "value": 139.668,
        "_internal_originalTime": 1689771628000
    },
    {
        "time": 1689771630000,
        "value": 139.661,
        "_internal_originalTime": 1689771630000
    },
    {
        "time": 1689771631000,
        "value": 139.664,
        "_internal_originalTime": 1689771631000
    },
    {
        "time": 1689771632000,
        "value": 139.652,
        "_internal_originalTime": 1689771632000
    },
    {
        "time": 1689771633000,
        "value": 139.659,
        "_internal_originalTime": 1689771633000
    },
    {
        "time": 1689771635000,
        "value": 139.652,
        "_internal_originalTime": 1689771635000
    },
    {
        "time": 1689771636000,
        "value": 139.657,
        "_internal_originalTime": 1689771636000
    },
    {
        "time": 1689771637000,
        "value": 139.65,
        "_internal_originalTime": 1689771637000
    },
    {
        "time": 1689771639000,
        "value": 139.646,
        "_internal_originalTime": 1689771639000
    },
    {
        "time": 1689771640000,
        "value": 139.639,
        "_internal_originalTime": 1689771640000
    },
    {
        "time": 1689771641000,
        "value": 139.627,
        "_internal_originalTime": 1689771641000
    },
    {
        "time": 1689771642000,
        "value": 139.624,
        "_internal_originalTime": 1689771642000
    },
    {
        "time": 1689771644000,
        "value": 139.623,
        "_internal_originalTime": 1689771644000
    },
    {
        "time": 1689771645000,
        "value": 139.612,
        "_internal_originalTime": 1689771645000
    },
    {
        "time": 1689771647000,
        "value": 139.612,
        "_internal_originalTime": 1689771647000
    },
    {
        "time": 1689771648000,
        "value": 139.605,
        "_internal_originalTime": 1689771648000
    },
    {
        "time": 1689771649000,
        "value": 139.585,
        "_internal_originalTime": 1689771649000
    },
    {
        "time": 1689771650000,
        "value": 139.588,
        "_internal_originalTime": 1689771650000
    },
    {
        "time": 1689771652000,
        "value": 139.592,
        "_internal_originalTime": 1689771652000
    },
    {
        "time": 1689771653000,
        "value": 139.568,
        "_internal_originalTime": 1689771653000
    },
    {
        "time": 1689771654000,
        "value": 139.579,
        "_internal_originalTime": 1689771654000
    },
    {
        "time": 1689771656000,
        "value": 139.606,
        "_internal_originalTime": 1689771656000
    },
    {
        "time": 1689771657000,
        "value": 139.614,
        "_internal_originalTime": 1689771657000
    },
    {
        "time": 1689771658000,
        "value": 139.622,
        "_internal_originalTime": 1689771658000
    },
    {
        "time": 1689771660000,
        "value": 139.633,
        "_internal_originalTime": 1689771660000
    },
    {
        "time": 1689771661000,
        "value": 139.642,
        "_internal_originalTime": 1689771661000
    },
    {
        "time": 1689771662000,
        "value": 139.639,
        "_internal_originalTime": 1689771662000
    },
    {
        "time": 1689771664000,
        "value": 139.654,
        "_internal_originalTime": 1689771664000
    },
    {
        "time": 1689771665000,
        "value": 139.653,
        "_internal_originalTime": 1689771665000
    },
    {
        "time": 1689771666000,
        "value": 139.655,
        "_internal_originalTime": 1689771666000
    },
    {
        "time": 1689771667000,
        "value": 139.664,
        "_internal_originalTime": 1689771667000
    },
    {
        "time": 1689771668000,
        "value": 139.663,
        "_internal_originalTime": 1689771668000
    },
    {
        "time": 1689771670000,
        "value": 139.662,
        "_internal_originalTime": 1689771670000
    },
    {
        "time": 1689771672000,
        "value": 139.655,
        "_internal_originalTime": 1689771672000
    },
    {
        "time": 1689771673000,
        "value": 139.654,
        "_internal_originalTime": 1689771673000
    },
    {
        "time": 1689771674000,
        "value": 139.65,
        "_internal_originalTime": 1689771674000
    },
    {
        "time": 1689771676000,
        "value": 139.653,
        "_internal_originalTime": 1689771676000
    },
    {
        "time": 1689771677000,
        "value": 139.655,
        "_internal_originalTime": 1689771677000
    },
    {
        "time": 1689771678000,
        "value": 139.65,
        "_internal_originalTime": 1689771678000
    },
    {
        "time": 1689771680000,
        "value": 139.642,
        "_internal_originalTime": 1689771680000
    },
    {
        "time": 1689771681000,
        "value": 139.644,
        "_internal_originalTime": 1689771681000
    },
    {
        "time": 1689771682000,
        "value": 139.654,
        "_internal_originalTime": 1689771682000
    },
    {
        "time": 1689771684000,
        "value": 139.654,
        "_internal_originalTime": 1689771684000
    },
    {
        "time": 1689771685000,
        "value": 139.655,
        "_internal_originalTime": 1689771685000
    },
    {
        "time": 1689771686000,
        "value": 139.65,
        "_internal_originalTime": 1689771686000
    },
    {
        "time": 1689771687000,
        "value": 139.653,
        "_internal_originalTime": 1689771687000
    },
    {
        "time": 1689771689000,
        "value": 139.656,
        "_internal_originalTime": 1689771689000
    },
    {
        "time": 1689771690000,
        "value": 139.654,
        "_internal_originalTime": 1689771690000
    },
    {
        "time": 1689771692000,
        "value": 139.642,
        "_internal_originalTime": 1689771692000
    },
    {
        "time": 1689771693000,
        "value": 139.648,
        "_internal_originalTime": 1689771693000
    },
    {
        "time": 1689771695000,
        "value": 139.647,
        "_internal_originalTime": 1689771695000
    },
    {
        "time": 1689771697000,
        "value": 139.642,
        "_internal_originalTime": 1689771697000
    },
    {
        "time": 1689771698000,
        "value": 139.644,
        "_internal_originalTime": 1689771698000
    },
    {
        "time": 1689771700000,
        "value": 139.641,
        "_internal_originalTime": 1689771700000
    },
    {
        "time": 1689771701000,
        "value": 139.642,
        "_internal_originalTime": 1689771701000
    },
    {
        "time": 1689771702000,
        "value": 139.635,
        "_internal_originalTime": 1689771702000
    },
    {
        "time": 1689771704000,
        "value": 139.632,
        "_internal_originalTime": 1689771704000
    },
    {
        "time": 1689771705000,
        "value": 139.634,
        "_internal_originalTime": 1689771705000
    },
    {
        "time": 1689771707000,
        "value": 139.635,
        "_internal_originalTime": 1689771707000
    },
    {
        "time": 1689771708000,
        "value": 139.633,
        "_internal_originalTime": 1689771708000
    },
    {
        "time": 1689771709000,
        "value": 139.637,
        "_internal_originalTime": 1689771709000
    },
    {
        "time": 1689771711000,
        "value": 139.634,
        "_internal_originalTime": 1689771711000
    },
    {
        "time": 1689771712000,
        "value": 139.635,
        "_internal_originalTime": 1689771712000
    },
    {
        "time": 1689771713000,
        "value": 139.636,
        "_internal_originalTime": 1689771713000
    },
    {
        "time": 1689771714000,
        "value": 139.635,
        "_internal_originalTime": 1689771714000
    },
    {
        "time": 1689771716000,
        "value": 139.637,
        "_internal_originalTime": 1689771716000
    },
    {
        "time": 1689771717000,
        "value": 139.638,
        "_internal_originalTime": 1689771717000
    },
    {
        "time": 1689771719000,
        "value": 139.647,
        "_internal_originalTime": 1689771719000
    },
    {
        "time": 1689771720000,
        "value": 139.644,
        "_internal_originalTime": 1689771720000
    },
    {
        "time": 1689771721000,
        "value": 139.646,
        "_internal_originalTime": 1689771721000
    },
    {
        "time": 1689771723000,
        "value": 139.644,
        "_internal_originalTime": 1689771723000
    },
    {
        "time": 1689771724000,
        "value": 139.648,
        "_internal_originalTime": 1689771724000
    },
    {
        "time": 1689771726000,
        "value": 139.645,
        "_internal_originalTime": 1689771726000
    },
    {
        "time": 1689771727000,
        "value": 139.645,
        "_internal_originalTime": 1689771727000
    },
    {
        "time": 1689771728000,
        "value": 139.646,
        "_internal_originalTime": 1689771728000
    },
    {
        "time": 1689771729000,
        "value": 139.646,
        "_internal_originalTime": 1689771729000
    },
    {
        "time": 1689771731000,
        "value": 139.648,
        "_internal_originalTime": 1689771731000
    },
    {
        "time": 1689771733000,
        "value": 139.648,
        "_internal_originalTime": 1689771733000
    },
    {
        "time": 1689771734000,
        "value": 139.652,
        "_internal_originalTime": 1689771734000
    },
    {
        "time": 1689771736000,
        "value": 139.654,
        "_internal_originalTime": 1689771736000
    },
    {
        "time": 1689771737000,
        "value": 139.656,
        "_internal_originalTime": 1689771737000
    },
    {
        "time": 1689771738000,
        "value": 139.654,
        "_internal_originalTime": 1689771738000
    },
    {
        "time": 1689771739000,
        "value": 139.655,
        "_internal_originalTime": 1689771739000
    },
    {
        "time": 1689771741000,
        "value": 139.657,
        "_internal_originalTime": 1689771741000
    },
    {
        "time": 1689771742000,
        "value": 139.667,
        "_internal_originalTime": 1689771742000
    },
    {
        "time": 1689771744000,
        "value": 139.664,
        "_internal_originalTime": 1689771744000
    },
    {
        "time": 1689771745000,
        "value": 139.67,
        "_internal_originalTime": 1689771745000
    },
    {
        "time": 1689771746000,
        "value": 139.665,
        "_internal_originalTime": 1689771746000
    },
    {
        "time": 1689771748000,
        "value": 139.663,
        "_internal_originalTime": 1689771748000
    },
    {
        "time": 1689771750000,
        "value": 139.666,
        "_internal_originalTime": 1689771750000
    },
    {
        "time": 1689771751000,
        "value": 139.667,
        "_internal_originalTime": 1689771751000
    },
    {
        "time": 1689771752000,
        "value": 139.663,
        "_internal_originalTime": 1689771752000
    },
    {
        "time": 1689771754000,
        "value": 139.667,
        "_internal_originalTime": 1689771754000
    },
    {
        "time": 1689771755000,
        "value": 139.665,
        "_internal_originalTime": 1689771755000
    },
    {
        "time": 1689771756000,
        "value": 139.664,
        "_internal_originalTime": 1689771756000
    },
    {
        "time": 1689771758000,
        "value": 139.664,
        "_internal_originalTime": 1689771758000
    },
    {
        "time": 1689771759000,
        "value": 139.663,
        "_internal_originalTime": 1689771759000
    },
    {
        "time": 1689771761000,
        "value": 139.659,
        "_internal_originalTime": 1689771761000
    },
    {
        "time": 1689771762000,
        "value": 139.656,
        "_internal_originalTime": 1689771762000
    },
    {
        "time": 1689771764000,
        "value": 139.645,
        "_internal_originalTime": 1689771764000
    },
    {
        "time": 1689771765000,
        "value": 139.649,
        "_internal_originalTime": 1689771765000
    },
    {
        "time": 1689771767000,
        "value": 139.641,
        "_internal_originalTime": 1689771767000
    },
    {
        "time": 1689771768000,
        "value": 139.646,
        "_internal_originalTime": 1689771768000
    },
    {
        "time": 1689771769000,
        "value": 139.647,
        "_internal_originalTime": 1689771769000
    },
    {
        "time": 1689771771000,
        "value": 139.644,
        "_internal_originalTime": 1689771771000
    },
    {
        "time": 1689771772000,
        "value": 139.646,
        "_internal_originalTime": 1689771772000
    },
    {
        "time": 1689771773000,
        "value": 139.647,
        "_internal_originalTime": 1689771773000
    },
    {
        "time": 1689771775000,
        "value": 139.646,
        "_internal_originalTime": 1689771775000
    },
    {
        "time": 1689771776000,
        "value": 139.634,
        "_internal_originalTime": 1689771776000
    },
    {
        "time": 1689771778000,
        "value": 139.63,
        "_internal_originalTime": 1689771778000
    },
    {
        "time": 1689771779000,
        "value": 139.622,
        "_internal_originalTime": 1689771779000
    },
    {
        "time": 1689771780000,
        "value": 139.618,
        "_internal_originalTime": 1689771780000
    },
    {
        "time": 1689771781000,
        "value": 139.616,
        "_internal_originalTime": 1689771781000
    },
    {
        "time": 1689771782000,
        "value": 139.611,
        "_internal_originalTime": 1689771782000
    },
    {
        "time": 1689771783000,
        "value": 139.603,
        "_internal_originalTime": 1689771783000
    },
    {
        "time": 1689771784000,
        "value": 139.589,
        "_internal_originalTime": 1689771784000
    },
    {
        "time": 1689771786000,
        "value": 139.587,
        "_internal_originalTime": 1689771786000
    },
    {
        "time": 1689771787000,
        "value": 139.578,
        "_internal_originalTime": 1689771787000
    },
    {
        "time": 1689771788000,
        "value": 139.582,
        "_internal_originalTime": 1689771788000
    },
    {
        "time": 1689771789000,
        "value": 139.575,
        "_internal_originalTime": 1689771789000
    },
    {
        "time": 1689771791000,
        "value": 139.588,
        "_internal_originalTime": 1689771791000
    },
    {
        "time": 1689771792000,
        "value": 139.597,
        "_internal_originalTime": 1689771792000
    },
    {
        "time": 1689771793000,
        "value": 139.586,
        "_internal_originalTime": 1689771793000
    },
    {
        "time": 1689771795000,
        "value": 139.587,
        "_internal_originalTime": 1689771795000
    },
    {
        "time": 1689771796000,
        "value": 139.586,
        "_internal_originalTime": 1689771796000
    },
    {
        "time": 1689771797000,
        "value": 139.585,
        "_internal_originalTime": 1689771797000
    },
    {
        "time": 1689771798000,
        "value": 139.591,
        "_internal_originalTime": 1689771798000
    },
    {
        "time": 1689771800000,
        "value": 139.589,
        "_internal_originalTime": 1689771800000
    },
    {
        "time": 1689771801000,
        "value": 139.589,
        "_internal_originalTime": 1689771801000
    },
    {
        "time": 1689771802000,
        "value": 139.586,
        "_internal_originalTime": 1689771802000
    },
    {
        "time": 1689771803000,
        "value": 139.591,
        "_internal_originalTime": 1689771803000
    },
    {
        "time": 1689771805000,
        "value": 139.587,
        "_internal_originalTime": 1689771805000
    },
    {
        "time": 1689771807000,
        "value": 139.595,
        "_internal_originalTime": 1689771807000
    },
    {
        "time": 1689771808000,
        "value": 139.596,
        "_internal_originalTime": 1689771808000
    },
    {
        "time": 1689771810000,
        "value": 139.598,
        "_internal_originalTime": 1689771810000
    },
    {
        "time": 1689771811000,
        "value": 139.598,
        "_internal_originalTime": 1689771811000
    },
    {
        "time": 1689771812000,
        "value": 139.592,
        "_internal_originalTime": 1689771812000
    },
    {
        "time": 1689771813000,
        "value": 139.594,
        "_internal_originalTime": 1689771813000
    },
    {
        "time": 1689771815000,
        "value": 139.596,
        "_internal_originalTime": 1689771815000
    },
    {
        "time": 1689771816000,
        "value": 139.595,
        "_internal_originalTime": 1689771816000
    },
    {
        "time": 1689771817000,
        "value": 139.595,
        "_internal_originalTime": 1689771817000
    },
    {
        "time": 1689771819000,
        "value": 139.595,
        "_internal_originalTime": 1689771819000
    },
    {
        "time": 1689771820000,
        "value": 139.596,
        "_internal_originalTime": 1689771820000
    },
    {
        "time": 1689771822000,
        "value": 139.593,
        "_internal_originalTime": 1689771822000
    },
    {
        "time": 1689771825000,
        "value": 139.597,
        "_internal_originalTime": 1689771825000
    },
    {
        "time": 1689771826000,
        "value": 139.597,
        "_internal_originalTime": 1689771826000
    },
    {
        "time": 1689771828000,
        "value": 139.608,
        "_internal_originalTime": 1689771828000
    },
    {
        "time": 1689771829000,
        "value": 139.605,
        "_internal_originalTime": 1689771829000
    },
    {
        "time": 1689771830000,
        "value": 139.616,
        "_internal_originalTime": 1689771830000
    },
    {
        "time": 1689771832000,
        "value": 139.616,
        "_internal_originalTime": 1689771832000
    },
    {
        "time": 1689771833000,
        "value": 139.625,
        "_internal_originalTime": 1689771833000
    },
    {
        "time": 1689771834000,
        "value": 139.627,
        "_internal_originalTime": 1689771834000
    },
    {
        "time": 1689771836000,
        "value": 139.637,
        "_internal_originalTime": 1689771836000
    },
    {
        "time": 1689771837000,
        "value": 139.634,
        "_internal_originalTime": 1689771837000
    },
    {
        "time": 1689771838000,
        "value": 139.652,
        "_internal_originalTime": 1689771838000
    },
    {
        "time": 1689771839000,
        "value": 139.65,
        "_internal_originalTime": 1689771839000
    },
    {
        "time": 1689771840000,
        "value": 139.642,
        "_internal_originalTime": 1689771840000
    },
    {
        "time": 1689771841000,
        "value": 139.651,
        "_internal_originalTime": 1689771841000
    },
    {
        "time": 1689771842000,
        "value": 139.654,
        "_internal_originalTime": 1689771842000
    },
    {
        "time": 1689771843000,
        "value": 139.639,
        "_internal_originalTime": 1689771843000
    },
    {
        "time": 1689771844000,
        "value": 139.645,
        "_internal_originalTime": 1689771844000
    },
    {
        "time": 1689771846000,
        "value": 139.644,
        "_internal_originalTime": 1689771846000
    },
    {
        "time": 1689771847000,
        "value": 139.645,
        "_internal_originalTime": 1689771847000
    },
    {
        "time": 1689771848000,
        "value": 139.646,
        "_internal_originalTime": 1689771848000
    },
    {
        "time": 1689771850000,
        "value": 139.642,
        "_internal_originalTime": 1689771850000
    },
    {
        "time": 1689771851000,
        "value": 139.645,
        "_internal_originalTime": 1689771851000
    },
    {
        "time": 1689771853000,
        "value": 139.644,
        "_internal_originalTime": 1689771853000
    },
    {
        "time": 1689771855000,
        "value": 139.646,
        "_internal_originalTime": 1689771855000
    },
    {
        "time": 1689771857000,
        "value": 139.644,
        "_internal_originalTime": 1689771857000
    },
    {
        "time": 1689771858000,
        "value": 139.646,
        "_internal_originalTime": 1689771858000
    },
    {
        "time": 1689771859000,
        "value": 139.636,
        "_internal_originalTime": 1689771859000
    },
    {
        "time": 1689771860000,
        "value": 139.634,
        "_internal_originalTime": 1689771860000
    },
    {
        "time": 1689771861000,
        "value": 139.635,
        "_internal_originalTime": 1689771861000
    },
    {
        "time": 1689771863000,
        "value": 139.637,
        "_internal_originalTime": 1689771863000
    },
    {
        "time": 1689771864000,
        "value": 139.632,
        "_internal_originalTime": 1689771864000
    },
    {
        "time": 1689771865000,
        "value": 139.635,
        "_internal_originalTime": 1689771865000
    },
    {
        "time": 1689771866000,
        "value": 139.633,
        "_internal_originalTime": 1689771866000
    },
    {
        "time": 1689771867000,
        "value": 139.635,
        "_internal_originalTime": 1689771867000
    },
    {
        "time": 1689771869000,
        "value": 139.631,
        "_internal_originalTime": 1689771869000
    },
    {
        "time": 1689771870000,
        "value": 139.633,
        "_internal_originalTime": 1689771870000
    },
    {
        "time": 1689771871000,
        "value": 139.633,
        "_internal_originalTime": 1689771871000
    },
    {
        "time": 1689771872000,
        "value": 139.636,
        "_internal_originalTime": 1689771872000
    },
    {
        "time": 1689771873000,
        "value": 139.635,
        "_internal_originalTime": 1689771873000
    },
    {
        "time": 1689771874000,
        "value": 139.635,
        "_internal_originalTime": 1689771874000
    },
    {
        "time": 1689771875000,
        "value": 139.636,
        "_internal_originalTime": 1689771875000
    },
    {
        "time": 1689771876000,
        "value": 139.635,
        "_internal_originalTime": 1689771876000
    },
    {
        "time": 1689771878000,
        "value": 139.623,
        "_internal_originalTime": 1689771878000
    },
    {
        "time": 1689771879000,
        "value": 139.624,
        "_internal_originalTime": 1689771879000
    },
    {
        "time": 1689771880000,
        "value": 139.625,
        "_internal_originalTime": 1689771880000
    },
    {
        "time": 1689771881000,
        "value": 139.624,
        "_internal_originalTime": 1689771881000
    },
    {
        "time": 1689771882000,
        "value": 139.624,
        "_internal_originalTime": 1689771882000
    },
    {
        "time": 1689771884000,
        "value": 139.625,
        "_internal_originalTime": 1689771884000
    },
    {
        "time": 1689771885000,
        "value": 139.624,
        "_internal_originalTime": 1689771885000
    },
    {
        "time": 1689771887000,
        "value": 139.625,
        "_internal_originalTime": 1689771887000
    },
    {
        "time": 1689771888000,
        "value": 139.636,
        "_internal_originalTime": 1689771888000
    },
    {
        "time": 1689771889000,
        "value": 139.635,
        "_internal_originalTime": 1689771889000
    },
    {
        "time": 1689771890000,
        "value": 139.636,
        "_internal_originalTime": 1689771890000
    },
    {
        "time": 1689771892000,
        "value": 139.623,
        "_internal_originalTime": 1689771892000
    },
    {
        "time": 1689771893000,
        "value": 139.622,
        "_internal_originalTime": 1689771893000
    },
    {
        "time": 1689771894000,
        "value": 139.626,
        "_internal_originalTime": 1689771894000
    },
    {
        "time": 1689771896000,
        "value": 139.626,
        "_internal_originalTime": 1689771896000
    },
    {
        "time": 1689771897000,
        "value": 139.625,
        "_internal_originalTime": 1689771897000
    },
    {
        "time": 1689771899000,
        "value": 139.625,
        "_internal_originalTime": 1689771899000
    },
    {
        "time": 1689771901000,
        "value": 139.631,
        "_internal_originalTime": 1689771901000
    },
    {
        "time": 1689771902000,
        "value": 139.636,
        "_internal_originalTime": 1689771902000
    },
    {
        "time": 1689771903000,
        "value": 139.641,
        "_internal_originalTime": 1689771903000
    },
    {
        "time": 1689771904000,
        "value": 139.655,
        "_internal_originalTime": 1689771904000
    },
    {
        "time": 1689771905000,
        "value": 139.655,
        "_internal_originalTime": 1689771905000
    },
    {
        "time": 1689771906000,
        "value": 139.644,
        "_internal_originalTime": 1689771906000
    },
    {
        "time": 1689771908000,
        "value": 139.635,
        "_internal_originalTime": 1689771908000
    },
    {
        "time": 1689771910000,
        "value": 139.637,
        "_internal_originalTime": 1689771910000
    },
    {
        "time": 1689771912000,
        "value": 139.636,
        "_internal_originalTime": 1689771912000
    },
    {
        "time": 1689771913000,
        "value": 139.635,
        "_internal_originalTime": 1689771913000
    },
    {
        "time": 1689771914000,
        "value": 139.635,
        "_internal_originalTime": 1689771914000
    },
    {
        "time": 1689771915000,
        "value": 139.635,
        "_internal_originalTime": 1689771915000
    },
    {
        "time": 1689771916000,
        "value": 139.632,
        "_internal_originalTime": 1689771916000
    },
    {
        "time": 1689771917000,
        "value": 139.626,
        "_internal_originalTime": 1689771917000
    },
    {
        "time": 1689771919000,
        "value": 139.62,
        "_internal_originalTime": 1689771919000
    },
    {
        "time": 1689771920000,
        "value": 139.626,
        "_internal_originalTime": 1689771920000
    },
    {
        "time": 1689771921000,
        "value": 139.63,
        "_internal_originalTime": 1689771921000
    },
    {
        "time": 1689771923000,
        "value": 139.635,
        "_internal_originalTime": 1689771923000
    },
    {
        "time": 1689771924000,
        "value": 139.635,
        "_internal_originalTime": 1689771924000
    },
    {
        "time": 1689771925000,
        "value": 139.633,
        "_internal_originalTime": 1689771925000
    },
    {
        "time": 1689771927000,
        "value": 139.636,
        "_internal_originalTime": 1689771927000
    },
    {
        "time": 1689771928000,
        "value": 139.636,
        "_internal_originalTime": 1689771928000
    },
    {
        "time": 1689771930000,
        "value": 139.626,
        "_internal_originalTime": 1689771930000
    },
    {
        "time": 1689771931000,
        "value": 139.617,
        "_internal_originalTime": 1689771931000
    },
    {
        "time": 1689771932000,
        "value": 139.615,
        "_internal_originalTime": 1689771932000
    },
    {
        "time": 1689771933000,
        "value": 139.616,
        "_internal_originalTime": 1689771933000
    },
    {
        "time": 1689771934000,
        "value": 139.604,
        "_internal_originalTime": 1689771934000
    },
    {
        "time": 1689771936000,
        "value": 139.607,
        "_internal_originalTime": 1689771936000
    },
    {
        "time": 1689771937000,
        "value": 139.608,
        "_internal_originalTime": 1689771937000
    },
    {
        "time": 1689771938000,
        "value": 139.607,
        "_internal_originalTime": 1689771938000
    },
    {
        "time": 1689771940000,
        "value": 139.601,
        "_internal_originalTime": 1689771940000
    },
    {
        "time": 1689771942000,
        "value": 139.605,
        "_internal_originalTime": 1689771942000
    },
    {
        "time": 1689771943000,
        "value": 139.606,
        "_internal_originalTime": 1689771943000
    },
    {
        "time": 1689771944000,
        "value": 139.606,
        "_internal_originalTime": 1689771944000
    },
    {
        "time": 1689771945000,
        "value": 139.606,
        "_internal_originalTime": 1689771945000
    },
    {
        "time": 1689771946000,
        "value": 139.605,
        "_internal_originalTime": 1689771946000
    },
    {
        "time": 1689771948000,
        "value": 139.605,
        "_internal_originalTime": 1689771948000
    },
    {
        "time": 1689771949000,
        "value": 139.596,
        "_internal_originalTime": 1689771949000
    },
    {
        "time": 1689771951000,
        "value": 139.587,
        "_internal_originalTime": 1689771951000
    },
    {
        "time": 1689771952000,
        "value": 139.582,
        "_internal_originalTime": 1689771952000
    },
    {
        "time": 1689771954000,
        "value": 139.585,
        "_internal_originalTime": 1689771954000
    },
    {
        "time": 1689771955000,
        "value": 139.587,
        "_internal_originalTime": 1689771955000
    },
    {
        "time": 1689771956000,
        "value": 139.584,
        "_internal_originalTime": 1689771956000
    },
    {
        "time": 1689771957000,
        "value": 139.585,
        "_internal_originalTime": 1689771957000
    },
    {
        "time": 1689771958000,
        "value": 139.581,
        "_internal_originalTime": 1689771958000
    },
    {
        "time": 1689771959000,
        "value": 139.574,
        "_internal_originalTime": 1689771959000
    },
    {
        "time": 1689771961000,
        "value": 139.577,
        "_internal_originalTime": 1689771961000
    },
    {
        "time": 1689771962000,
        "value": 139.574,
        "_internal_originalTime": 1689771962000
    },
    {
        "time": 1689771963000,
        "value": 139.568,
        "_internal_originalTime": 1689771963000
    },
    {
        "time": 1689771964000,
        "value": 139.567,
        "_internal_originalTime": 1689771964000
    },
    {
        "time": 1689771965000,
        "value": 139.563,
        "_internal_originalTime": 1689771965000
    },
    {
        "time": 1689771966000,
        "value": 139.566,
        "_internal_originalTime": 1689771966000
    },
    {
        "time": 1689771967000,
        "value": 139.567,
        "_internal_originalTime": 1689771967000
    },
    {
        "time": 1689771968000,
        "value": 139.565,
        "_internal_originalTime": 1689771968000
    },
    {
        "time": 1689771970000,
        "value": 139.567,
        "_internal_originalTime": 1689771970000
    },
    {
        "time": 1689771971000,
        "value": 139.565,
        "_internal_originalTime": 1689771971000
    },
    {
        "time": 1689771975000,
        "value": 139.557,
        "_internal_originalTime": 1689771975000
    },
    {
        "time": 1689771977000,
        "value": 139.547,
        "_internal_originalTime": 1689771977000
    },
    {
        "time": 1689771978000,
        "value": 139.547,
        "_internal_originalTime": 1689771978000
    },
    {
        "time": 1689771979000,
        "value": 139.539,
        "_internal_originalTime": 1689771979000
    },
    {
        "time": 1689771980000,
        "value": 139.528,
        "_internal_originalTime": 1689771980000
    },
    {
        "time": 1689771981000,
        "value": 139.533,
        "_internal_originalTime": 1689771981000
    },
    {
        "time": 1689771983000,
        "value": 139.523,
        "_internal_originalTime": 1689771983000
    },
    {
        "time": 1689771984000,
        "value": 139.516,
        "_internal_originalTime": 1689771984000
    },
    {
        "time": 1689771985000,
        "value": 139.522,
        "_internal_originalTime": 1689771985000
    },
    {
        "time": 1689771986000,
        "value": 139.535,
        "_internal_originalTime": 1689771986000
    },
    {
        "time": 1689771988000,
        "value": 139.538,
        "_internal_originalTime": 1689771988000
    },
    {
        "time": 1689771990000,
        "value": 139.539,
        "_internal_originalTime": 1689771990000
    },
    {
        "time": 1689771992000,
        "value": 139.535,
        "_internal_originalTime": 1689771992000
    },
    {
        "time": 1689771993000,
        "value": 139.536,
        "_internal_originalTime": 1689771993000
    },
    {
        "time": 1689771994000,
        "value": 139.542,
        "_internal_originalTime": 1689771994000
    },
    {
        "time": 1689771996000,
        "value": 139.53,
        "_internal_originalTime": 1689771996000
    },
    {
        "time": 1689771997000,
        "value": 139.535,
        "_internal_originalTime": 1689771997000
    },
    {
        "time": 1689771998000,
        "value": 139.537,
        "_internal_originalTime": 1689771998000
    },
    {
        "time": 1689772000000,
        "value": 139.525,
        "_internal_originalTime": 1689772000000
    },
    {
        "time": 1689772001000,
        "value": 139.522,
        "_internal_originalTime": 1689772001000
    },
    {
        "time": 1689772002000,
        "value": 139.52,
        "_internal_originalTime": 1689772002000
    },
    {
        "time": 1689772003000,
        "value": 139.525,
        "_internal_originalTime": 1689772003000
    },
    {
        "time": 1689772005000,
        "value": 139.516,
        "_internal_originalTime": 1689772005000
    },
    {
        "time": 1689772007000,
        "value": 139.511,
        "_internal_originalTime": 1689772007000
    },
    {
        "time": 1689772008000,
        "value": 139.513,
        "_internal_originalTime": 1689772008000
    },
    {
        "time": 1689772010000,
        "value": 139.511,
        "_internal_originalTime": 1689772010000
    },
    {
        "time": 1689772011000,
        "value": 139.512,
        "_internal_originalTime": 1689772011000
    },
    {
        "time": 1689772012000,
        "value": 139.521,
        "_internal_originalTime": 1689772012000
    },
    {
        "time": 1689772016000,
        "value": 139.514,
        "_internal_originalTime": 1689772016000
    },
    {
        "time": 1689772017000,
        "value": 139.52,
        "_internal_originalTime": 1689772017000
    },
    {
        "time": 1689772019000,
        "value": 139.514,
        "_internal_originalTime": 1689772019000
    },
    {
        "time": 1689772020000,
        "value": 139.515,
        "_internal_originalTime": 1689772020000
    },
    {
        "time": 1689772021000,
        "value": 139.513,
        "_internal_originalTime": 1689772021000
    },
    {
        "time": 1689772022000,
        "value": 139.516,
        "_internal_originalTime": 1689772022000
    },
    {
        "time": 1689772023000,
        "value": 139.522,
        "_internal_originalTime": 1689772023000
    },
    {
        "time": 1689772025000,
        "value": 139.53,
        "_internal_originalTime": 1689772025000
    },
    {
        "time": 1689772026000,
        "value": 139.533,
        "_internal_originalTime": 1689772026000
    },
    {
        "time": 1689772027000,
        "value": 139.53,
        "_internal_originalTime": 1689772027000
    },
    {
        "time": 1689772029000,
        "value": 139.54,
        "_internal_originalTime": 1689772029000
    },
    {
        "time": 1689772030000,
        "value": 139.534,
        "_internal_originalTime": 1689772030000
    },
    {
        "time": 1689772031000,
        "value": 139.537,
        "_internal_originalTime": 1689772031000
    },
    {
        "time": 1689772032000,
        "value": 139.539,
        "_internal_originalTime": 1689772032000
    },
    {
        "time": 1689772034000,
        "value": 139.537,
        "_internal_originalTime": 1689772034000
    },
    {
        "time": 1689772036000,
        "value": 139.538,
        "_internal_originalTime": 1689772036000
    },
    {
        "time": 1689772037000,
        "value": 139.54,
        "_internal_originalTime": 1689772037000
    },
    {
        "time": 1689772040000,
        "value": 139.537,
        "_internal_originalTime": 1689772040000
    },
    {
        "time": 1689772041000,
        "value": 139.535,
        "_internal_originalTime": 1689772041000
    },
    {
        "time": 1689772043000,
        "value": 139.534,
        "_internal_originalTime": 1689772043000
    },
    {
        "time": 1689772045000,
        "value": 139.536,
        "_internal_originalTime": 1689772045000
    },
    {
        "time": 1689772046000,
        "value": 139.533,
        "_internal_originalTime": 1689772046000
    },
    {
        "time": 1689772047000,
        "value": 139.528,
        "_internal_originalTime": 1689772047000
    },
    {
        "time": 1689772048000,
        "value": 139.533,
        "_internal_originalTime": 1689772048000
    },
    {
        "time": 1689772049000,
        "value": 139.526,
        "_internal_originalTime": 1689772049000
    },
    {
        "time": 1689772050000,
        "value": 139.528,
        "_internal_originalTime": 1689772050000
    },
    {
        "time": 1689772051000,
        "value": 139.537,
        "_internal_originalTime": 1689772051000
    },
    {
        "time": 1689772052000,
        "value": 139.528,
        "_internal_originalTime": 1689772052000
    },
    {
        "time": 1689772053000,
        "value": 139.53,
        "_internal_originalTime": 1689772053000
    },
    {
        "time": 1689772054000,
        "value": 139.528,
        "_internal_originalTime": 1689772054000
    },
    {
        "time": 1689772058000,
        "value": 139.535,
        "_internal_originalTime": 1689772058000
    },
    {
        "time": 1689772059000,
        "value": 139.543,
        "_internal_originalTime": 1689772059000
    },
    {
        "time": 1689772061000,
        "value": 139.539,
        "_internal_originalTime": 1689772061000
    },
    {
        "time": 1689772062000,
        "value": 139.52,
        "_internal_originalTime": 1689772062000
    },
    {
        "time": 1689772063000,
        "value": 139.522,
        "_internal_originalTime": 1689772063000
    },
    {
        "time": 1689772064000,
        "value": 139.525,
        "_internal_originalTime": 1689772064000
    },
    {
        "time": 1689772065000,
        "value": 139.519,
        "_internal_originalTime": 1689772065000
    },
    {
        "time": 1689772066000,
        "value": 139.523,
        "_internal_originalTime": 1689772066000
    },
    {
        "time": 1689772067000,
        "value": 139.522,
        "_internal_originalTime": 1689772067000
    },
    {
        "time": 1689772069000,
        "value": 139.514,
        "_internal_originalTime": 1689772069000
    },
    {
        "time": 1689772070000,
        "value": 139.52,
        "_internal_originalTime": 1689772070000
    },
    {
        "time": 1689772071000,
        "value": 139.52,
        "_internal_originalTime": 1689772071000
    },
    {
        "time": 1689772072000,
        "value": 139.517,
        "_internal_originalTime": 1689772072000
    },
    {
        "time": 1689772074000,
        "value": 139.516,
        "_internal_originalTime": 1689772074000
    },
    {
        "time": 1689772075000,
        "value": 139.52,
        "_internal_originalTime": 1689772075000
    },
    {
        "time": 1689772077000,
        "value": 139.518,
        "_internal_originalTime": 1689772077000
    },
    {
        "time": 1689772078000,
        "value": 139.524,
        "_internal_originalTime": 1689772078000
    },
    {
        "time": 1689772080000,
        "value": 139.53,
        "_internal_originalTime": 1689772080000
    },
    {
        "time": 1689772081000,
        "value": 139.535,
        "_internal_originalTime": 1689772081000
    },
    {
        "time": 1689772083000,
        "value": 139.539,
        "_internal_originalTime": 1689772083000
    },
    {
        "time": 1689772084000,
        "value": 139.536,
        "_internal_originalTime": 1689772084000
    },
    {
        "time": 1689772085000,
        "value": 139.539,
        "_internal_originalTime": 1689772085000
    },
    {
        "time": 1689772086000,
        "value": 139.542,
        "_internal_originalTime": 1689772086000
    },
    {
        "time": 1689772088000,
        "value": 139.538,
        "_internal_originalTime": 1689772088000
    },
    {
        "time": 1689772090000,
        "value": 139.536,
        "_internal_originalTime": 1689772090000
    },
    {
        "time": 1689772092000,
        "value": 139.54,
        "_internal_originalTime": 1689772092000
    },
    {
        "time": 1689772094000,
        "value": 139.54,
        "_internal_originalTime": 1689772094000
    },
    {
        "time": 1689772096000,
        "value": 139.541,
        "_internal_originalTime": 1689772096000
    },
    {
        "time": 1689772097000,
        "value": 139.538,
        "_internal_originalTime": 1689772097000
    },
    {
        "time": 1689772100000,
        "value": 139.542,
        "_internal_originalTime": 1689772100000
    },
    {
        "time": 1689772102000,
        "value": 139.534,
        "_internal_originalTime": 1689772102000
    },
    {
        "time": 1689772103000,
        "value": 139.534,
        "_internal_originalTime": 1689772103000
    },
    {
        "time": 1689772104000,
        "value": 139.533,
        "_internal_originalTime": 1689772104000
    },
    {
        "time": 1689772105000,
        "value": 139.53,
        "_internal_originalTime": 1689772105000
    },
    {
        "time": 1689772107000,
        "value": 139.528,
        "_internal_originalTime": 1689772107000
    },
    {
        "time": 1689772108000,
        "value": 139.528,
        "_internal_originalTime": 1689772108000
    },
    {
        "time": 1689772110000,
        "value": 139.532,
        "_internal_originalTime": 1689772110000
    },
    {
        "time": 1689772111000,
        "value": 139.531,
        "_internal_originalTime": 1689772111000
    },
    {
        "time": 1689772112000,
        "value": 139.53,
        "_internal_originalTime": 1689772112000
    },
    {
        "time": 1689772113000,
        "value": 139.535,
        "_internal_originalTime": 1689772113000
    },
    {
        "time": 1689772115000,
        "value": 139.536,
        "_internal_originalTime": 1689772115000
    },
    {
        "time": 1689772116000,
        "value": 139.538,
        "_internal_originalTime": 1689772116000
    },
    {
        "time": 1689772117000,
        "value": 139.538,
        "_internal_originalTime": 1689772117000
    },
    {
        "time": 1689772119000,
        "value": 139.538,
        "_internal_originalTime": 1689772119000
    },
    {
        "time": 1689772121000,
        "value": 139.54,
        "_internal_originalTime": 1689772121000
    },
    {
        "time": 1689772122000,
        "value": 139.533,
        "_internal_originalTime": 1689772122000
    },
    {
        "time": 1689772124000,
        "value": 139.533,
        "_internal_originalTime": 1689772124000
    },
    {
        "time": 1689772125000,
        "value": 139.529,
        "_internal_originalTime": 1689772125000
    },
    {
        "time": 1689772127000,
        "value": 139.545,
        "_internal_originalTime": 1689772127000
    },
    {
        "time": 1689772128000,
        "value": 139.539,
        "_internal_originalTime": 1689772128000
    },
    {
        "time": 1689772129000,
        "value": 139.532,
        "_internal_originalTime": 1689772129000
    },
    {
        "time": 1689772130000,
        "value": 139.528,
        "_internal_originalTime": 1689772130000
    },
    {
        "time": 1689772132000,
        "value": 139.528,
        "_internal_originalTime": 1689772132000
    },
    {
        "time": 1689772133000,
        "value": 139.522,
        "_internal_originalTime": 1689772133000
    },
    {
        "time": 1689772134000,
        "value": 139.528,
        "_internal_originalTime": 1689772134000
    },
    {
        "time": 1689772136000,
        "value": 139.517,
        "_internal_originalTime": 1689772136000
    },
    {
        "time": 1689772138000,
        "value": 139.511,
        "_internal_originalTime": 1689772138000
    },
    {
        "time": 1689772139000,
        "value": 139.512,
        "_internal_originalTime": 1689772139000
    },
    {
        "time": 1689772140000,
        "value": 139.507,
        "_internal_originalTime": 1689772140000
    },
    {
        "time": 1689772141000,
        "value": 139.49,
        "_internal_originalTime": 1689772141000
    },
    {
        "time": 1689772142000,
        "value": 139.514,
        "_internal_originalTime": 1689772142000
    },
    {
        "time": 1689772144000,
        "value": 139.502,
        "_internal_originalTime": 1689772144000
    },
    {
        "time": 1689772145000,
        "value": 139.504,
        "_internal_originalTime": 1689772145000
    },
    {
        "time": 1689772146000,
        "value": 139.504,
        "_internal_originalTime": 1689772146000
    },
    {
        "time": 1689772147000,
        "value": 139.499,
        "_internal_originalTime": 1689772147000
    },
    {
        "time": 1689772148000,
        "value": 139.498,
        "_internal_originalTime": 1689772148000
    },
    {
        "time": 1689772150000,
        "value": 139.508,
        "_internal_originalTime": 1689772150000
    },
    {
        "time": 1689772151000,
        "value": 139.519,
        "_internal_originalTime": 1689772151000
    },
    {
        "time": 1689772153000,
        "value": 139.514,
        "_internal_originalTime": 1689772153000
    },
    {
        "time": 1689772154000,
        "value": 139.518,
        "_internal_originalTime": 1689772154000
    },
    {
        "time": 1689772155000,
        "value": 139.518,
        "_internal_originalTime": 1689772155000
    },
    {
        "time": 1689772157000,
        "value": 139.517,
        "_internal_originalTime": 1689772157000
    },
    {
        "time": 1689772158000,
        "value": 139.517,
        "_internal_originalTime": 1689772158000
    },
    {
        "time": 1689772159000,
        "value": 139.528,
        "_internal_originalTime": 1689772159000
    },
    {
        "time": 1689772160000,
        "value": 139.528,
        "_internal_originalTime": 1689772160000
    },
    {
        "time": 1689772161000,
        "value": 139.532,
        "_internal_originalTime": 1689772161000
    },
    {
        "time": 1689772163000,
        "value": 139.531,
        "_internal_originalTime": 1689772163000
    },
    {
        "time": 1689772164000,
        "value": 139.534,
        "_internal_originalTime": 1689772164000
    },
    {
        "time": 1689772166000,
        "value": 139.542,
        "_internal_originalTime": 1689772166000
    },
    {
        "time": 1689772167000,
        "value": 139.549,
        "_internal_originalTime": 1689772167000
    },
    {
        "time": 1689772169000,
        "value": 139.556,
        "_internal_originalTime": 1689772169000
    },
    {
        "time": 1689772170000,
        "value": 139.554,
        "_internal_originalTime": 1689772170000
    },
    {
        "time": 1689772172000,
        "value": 139.553,
        "_internal_originalTime": 1689772172000
    },
    {
        "time": 1689772174000,
        "value": 139.554,
        "_internal_originalTime": 1689772174000
    },
    {
        "time": 1689772175000,
        "value": 139.552,
        "_internal_originalTime": 1689772175000
    },
    {
        "time": 1689772176000,
        "value": 139.557,
        "_internal_originalTime": 1689772176000
    },
    {
        "time": 1689772178000,
        "value": 139.557,
        "_internal_originalTime": 1689772178000
    },
    {
        "time": 1689772180000,
        "value": 139.562,
        "_internal_originalTime": 1689772180000
    },
    {
        "time": 1689772181000,
        "value": 139.559,
        "_internal_originalTime": 1689772181000
    },
    {
        "time": 1689772182000,
        "value": 139.56,
        "_internal_originalTime": 1689772182000
    },
    {
        "time": 1689772183000,
        "value": 139.556,
        "_internal_originalTime": 1689772183000
    },
    {
        "time": 1689772185000,
        "value": 139.56,
        "_internal_originalTime": 1689772185000
    },
    {
        "time": 1689772187000,
        "value": 139.561,
        "_internal_originalTime": 1689772187000
    },
    {
        "time": 1689772188000,
        "value": 139.561,
        "_internal_originalTime": 1689772188000
    },
    {
        "time": 1689772189000,
        "value": 139.561,
        "_internal_originalTime": 1689772189000
    },
    {
        "time": 1689772191000,
        "value": 139.556,
        "_internal_originalTime": 1689772191000
    },
    {
        "time": 1689772192000,
        "value": 139.557,
        "_internal_originalTime": 1689772192000
    },
    {
        "time": 1689772193000,
        "value": 139.557,
        "_internal_originalTime": 1689772193000
    },
    {
        "time": 1689772195000,
        "value": 139.557,
        "_internal_originalTime": 1689772195000
    },
    {
        "time": 1689772196000,
        "value": 139.554,
        "_internal_originalTime": 1689772196000
    },
    {
        "time": 1689772198000,
        "value": 139.556,
        "_internal_originalTime": 1689772198000
    },
    {
        "time": 1689772199000,
        "value": 139.55,
        "_internal_originalTime": 1689772199000
    },
    {
        "time": 1689772200000,
        "value": 139.542,
        "_internal_originalTime": 1689772200000
    },
    {
        "time": 1689772201000,
        "value": 139.53,
        "_internal_originalTime": 1689772201000
    },
    {
        "time": 1689772202000,
        "value": 139.529,
        "_internal_originalTime": 1689772202000
    },
    {
        "time": 1689772204000,
        "value": 139.538,
        "_internal_originalTime": 1689772204000
    },
    {
        "time": 1689772205000,
        "value": 139.542,
        "_internal_originalTime": 1689772205000
    },
    {
        "time": 1689772206000,
        "value": 139.542,
        "_internal_originalTime": 1689772206000
    },
    {
        "time": 1689772208000,
        "value": 139.542,
        "_internal_originalTime": 1689772208000
    },
    {
        "time": 1689772209000,
        "value": 139.542,
        "_internal_originalTime": 1689772209000
    },
    {
        "time": 1689772210000,
        "value": 139.537,
        "_internal_originalTime": 1689772210000
    },
    {
        "time": 1689772211000,
        "value": 139.536,
        "_internal_originalTime": 1689772211000
    },
    {
        "time": 1689772212000,
        "value": 139.539,
        "_internal_originalTime": 1689772212000
    },
    {
        "time": 1689772214000,
        "value": 139.537,
        "_internal_originalTime": 1689772214000
    },
    {
        "time": 1689772215000,
        "value": 139.55,
        "_internal_originalTime": 1689772215000
    },
    {
        "time": 1689772217000,
        "value": 139.554,
        "_internal_originalTime": 1689772217000
    },
    {
        "time": 1689772218000,
        "value": 139.558,
        "_internal_originalTime": 1689772218000
    },
    {
        "time": 1689772219000,
        "value": 139.556,
        "_internal_originalTime": 1689772219000
    },
    {
        "time": 1689772221000,
        "value": 139.574,
        "_internal_originalTime": 1689772221000
    },
    {
        "time": 1689772222000,
        "value": 139.583,
        "_internal_originalTime": 1689772222000
    },
    {
        "time": 1689772223000,
        "value": 139.586,
        "_internal_originalTime": 1689772223000
    },
    {
        "time": 1689772225000,
        "value": 139.591,
        "_internal_originalTime": 1689772225000
    },
    {
        "time": 1689772226000,
        "value": 139.591,
        "_internal_originalTime": 1689772226000
    },
    {
        "time": 1689772228000,
        "value": 139.594,
        "_internal_originalTime": 1689772228000
    },
    {
        "time": 1689772229000,
        "value": 139.6,
        "_internal_originalTime": 1689772229000
    },
    {
        "time": 1689772230000,
        "value": 139.595,
        "_internal_originalTime": 1689772230000
    },
    {
        "time": 1689772232000,
        "value": 139.588,
        "_internal_originalTime": 1689772232000
    },
    {
        "time": 1689772234000,
        "value": 139.584,
        "_internal_originalTime": 1689772234000
    },
    {
        "time": 1689772236000,
        "value": 139.588,
        "_internal_originalTime": 1689772236000
    },
    {
        "time": 1689772237000,
        "value": 139.598,
        "_internal_originalTime": 1689772237000
    },
    {
        "time": 1689772238000,
        "value": 139.593,
        "_internal_originalTime": 1689772238000
    },
    {
        "time": 1689772240000,
        "value": 139.597,
        "_internal_originalTime": 1689772240000
    },
    {
        "time": 1689772242000,
        "value": 139.598,
        "_internal_originalTime": 1689772242000
    },
    {
        "time": 1689772244000,
        "value": 139.597,
        "_internal_originalTime": 1689772244000
    },
    {
        "time": 1689772245000,
        "value": 139.6,
        "_internal_originalTime": 1689772245000
    },
    {
        "time": 1689772247000,
        "value": 139.602,
        "_internal_originalTime": 1689772247000
    },
    {
        "time": 1689772248000,
        "value": 139.606,
        "_internal_originalTime": 1689772248000
    },
    {
        "time": 1689772249000,
        "value": 139.608,
        "_internal_originalTime": 1689772249000
    },
    {
        "time": 1689772250000,
        "value": 139.61,
        "_internal_originalTime": 1689772250000
    },
    {
        "time": 1689772251000,
        "value": 139.606,
        "_internal_originalTime": 1689772251000
    },
    {
        "time": 1689772253000,
        "value": 139.606,
        "_internal_originalTime": 1689772253000
    },
    {
        "time": 1689772254000,
        "value": 139.609,
        "_internal_originalTime": 1689772254000
    },
    {
        "time": 1689772256000,
        "value": 139.606,
        "_internal_originalTime": 1689772256000
    },
    {
        "time": 1689772257000,
        "value": 139.618,
        "_internal_originalTime": 1689772257000
    },
    {
        "time": 1689772258000,
        "value": 139.617,
        "_internal_originalTime": 1689772258000
    },
    {
        "time": 1689772260000,
        "value": 139.619,
        "_internal_originalTime": 1689772260000
    },
    {
        "time": 1689772261000,
        "value": 139.618,
        "_internal_originalTime": 1689772261000
    },
    {
        "time": 1689772263000,
        "value": 139.616,
        "_internal_originalTime": 1689772263000
    },
    {
        "time": 1689772264000,
        "value": 139.62,
        "_internal_originalTime": 1689772264000
    },
    {
        "time": 1689772265000,
        "value": 139.619,
        "_internal_originalTime": 1689772265000
    },
    {
        "time": 1689772266000,
        "value": 139.614,
        "_internal_originalTime": 1689772266000
    },
    {
        "time": 1689772268000,
        "value": 139.62,
        "_internal_originalTime": 1689772268000
    },
    {
        "time": 1689772270000,
        "value": 139.622,
        "_internal_originalTime": 1689772270000
    },
    {
        "time": 1689772271000,
        "value": 139.619,
        "_internal_originalTime": 1689772271000
    },
    {
        "time": 1689772272000,
        "value": 139.616,
        "_internal_originalTime": 1689772272000
    },
    {
        "time": 1689772273000,
        "value": 139.615,
        "_internal_originalTime": 1689772273000
    },
    {
        "time": 1689772274000,
        "value": 139.617,
        "_internal_originalTime": 1689772274000
    },
    {
        "time": 1689772275000,
        "value": 139.612,
        "_internal_originalTime": 1689772275000
    },
    {
        "time": 1689772277000,
        "value": 139.61,
        "_internal_originalTime": 1689772277000
    },
    {
        "time": 1689772279000,
        "value": 139.604,
        "_internal_originalTime": 1689772279000
    },
    {
        "time": 1689772280000,
        "value": 139.604,
        "_internal_originalTime": 1689772280000
    },
    {
        "time": 1689772281000,
        "value": 139.606,
        "_internal_originalTime": 1689772281000
    },
    {
        "time": 1689772283000,
        "value": 139.616,
        "_internal_originalTime": 1689772283000
    },
    {
        "time": 1689772284000,
        "value": 139.616,
        "_internal_originalTime": 1689772284000
    },
    {
        "time": 1689772285000,
        "value": 139.611,
        "_internal_originalTime": 1689772285000
    },
    {
        "time": 1689772286000,
        "value": 139.606,
        "_internal_originalTime": 1689772286000
    },
    {
        "time": 1689772288000,
        "value": 139.602,
        "_internal_originalTime": 1689772288000
    },
    {
        "time": 1689772290000,
        "value": 139.598,
        "_internal_originalTime": 1689772290000
    },
    {
        "time": 1689772291000,
        "value": 139.601,
        "_internal_originalTime": 1689772291000
    },
    {
        "time": 1689772292000,
        "value": 139.591,
        "_internal_originalTime": 1689772292000
    },
    {
        "time": 1689772293000,
        "value": 139.596,
        "_internal_originalTime": 1689772293000
    },
    {
        "time": 1689772294000,
        "value": 139.589,
        "_internal_originalTime": 1689772294000
    },
    {
        "time": 1689772296000,
        "value": 139.596,
        "_internal_originalTime": 1689772296000
    },
    {
        "time": 1689772298000,
        "value": 139.601,
        "_internal_originalTime": 1689772298000
    },
    {
        "time": 1689772299000,
        "value": 139.599,
        "_internal_originalTime": 1689772299000
    },
    {
        "time": 1689772301000,
        "value": 139.597,
        "_internal_originalTime": 1689772301000
    },
    {
        "time": 1689772302000,
        "value": 139.595,
        "_internal_originalTime": 1689772302000
    },
    {
        "time": 1689772304000,
        "value": 139.59,
        "_internal_originalTime": 1689772304000
    },
    {
        "time": 1689772305000,
        "value": 139.586,
        "_internal_originalTime": 1689772305000
    },
    {
        "time": 1689772306000,
        "value": 139.584,
        "_internal_originalTime": 1689772306000
    },
    {
        "time": 1689772308000,
        "value": 139.582,
        "_internal_originalTime": 1689772308000
    },
    {
        "time": 1689772309000,
        "value": 139.586,
        "_internal_originalTime": 1689772309000
    },
    {
        "time": 1689772310000,
        "value": 139.595,
        "_internal_originalTime": 1689772310000
    },
    {
        "time": 1689772311000,
        "value": 139.596,
        "_internal_originalTime": 1689772311000
    },
    {
        "time": 1689772312000,
        "value": 139.598,
        "_internal_originalTime": 1689772312000
    },
    {
        "time": 1689772314000,
        "value": 139.597,
        "_internal_originalTime": 1689772314000
    },
    {
        "time": 1689772315000,
        "value": 139.591,
        "_internal_originalTime": 1689772315000
    },
    {
        "time": 1689772317000,
        "value": 139.585,
        "_internal_originalTime": 1689772317000
    },
    {
        "time": 1689772318000,
        "value": 139.588,
        "_internal_originalTime": 1689772318000
    },
    {
        "time": 1689772319000,
        "value": 139.584,
        "_internal_originalTime": 1689772319000
    },
    {
        "time": 1689772321000,
        "value": 139.587,
        "_internal_originalTime": 1689772321000
    },
    {
        "time": 1689772322000,
        "value": 139.591,
        "_internal_originalTime": 1689772322000
    },
    {
        "time": 1689772323000,
        "value": 139.578,
        "_internal_originalTime": 1689772323000
    },
    {
        "time": 1689772324000,
        "value": 139.581,
        "_internal_originalTime": 1689772324000
    },
    {
        "time": 1689772325000,
        "value": 139.584,
        "_internal_originalTime": 1689772325000
    },
    {
        "time": 1689772326000,
        "value": 139.585,
        "_internal_originalTime": 1689772326000
    },
    {
        "time": 1689772328000,
        "value": 139.585,
        "_internal_originalTime": 1689772328000
    },
    {
        "time": 1689772329000,
        "value": 139.587,
        "_internal_originalTime": 1689772329000
    },
    {
        "time": 1689772330000,
        "value": 139.582,
        "_internal_originalTime": 1689772330000
    },
    {
        "time": 1689772332000,
        "value": 139.587,
        "_internal_originalTime": 1689772332000
    },
    {
        "time": 1689772333000,
        "value": 139.577,
        "_internal_originalTime": 1689772333000
    },
    {
        "time": 1689772335000,
        "value": 139.576,
        "_internal_originalTime": 1689772335000
    },
    {
        "time": 1689772336000,
        "value": 139.57,
        "_internal_originalTime": 1689772336000
    },
    {
        "time": 1689772338000,
        "value": 139.579,
        "_internal_originalTime": 1689772338000
    },
    {
        "time": 1689772340000,
        "value": 139.578,
        "_internal_originalTime": 1689772340000
    },
    {
        "time": 1689772341000,
        "value": 139.582,
        "_internal_originalTime": 1689772341000
    },
    {
        "time": 1689772342000,
        "value": 139.582,
        "_internal_originalTime": 1689772342000
    },
    {
        "time": 1689772343000,
        "value": 139.586,
        "_internal_originalTime": 1689772343000
    },
    {
        "time": 1689772345000,
        "value": 139.586,
        "_internal_originalTime": 1689772345000
    },
    {
        "time": 1689772346000,
        "value": 139.591,
        "_internal_originalTime": 1689772346000
    },
    {
        "time": 1689772347000,
        "value": 139.592,
        "_internal_originalTime": 1689772347000
    },
    {
        "time": 1689772349000,
        "value": 139.597,
        "_internal_originalTime": 1689772349000
    },
    {
        "time": 1689772350000,
        "value": 139.596,
        "_internal_originalTime": 1689772350000
    },
    {
        "time": 1689772351000,
        "value": 139.596,
        "_internal_originalTime": 1689772351000
    },
    {
        "time": 1689772352000,
        "value": 139.582,
        "_internal_originalTime": 1689772352000
    },
    {
        "time": 1689772353000,
        "value": 139.584,
        "_internal_originalTime": 1689772353000
    },
    {
        "time": 1689772354000,
        "value": 139.583,
        "_internal_originalTime": 1689772354000
    },
    {
        "time": 1689772356000,
        "value": 139.586,
        "_internal_originalTime": 1689772356000
    },
    {
        "time": 1689772358000,
        "value": 139.587,
        "_internal_originalTime": 1689772358000
    },
    {
        "time": 1689772359000,
        "value": 139.569,
        "_internal_originalTime": 1689772359000
    },
    {
        "time": 1689772360000,
        "value": 139.562,
        "_internal_originalTime": 1689772360000
    },
    {
        "time": 1689772362000,
        "value": 139.562,
        "_internal_originalTime": 1689772362000
    },
    {
        "time": 1689772363000,
        "value": 139.562,
        "_internal_originalTime": 1689772363000
    },
    {
        "time": 1689772365000,
        "value": 139.562,
        "_internal_originalTime": 1689772365000
    },
    {
        "time": 1689772366000,
        "value": 139.571,
        "_internal_originalTime": 1689772366000
    },
    {
        "time": 1689772367000,
        "value": 139.564,
        "_internal_originalTime": 1689772367000
    },
    {
        "time": 1689772369000,
        "value": 139.568,
        "_internal_originalTime": 1689772369000
    },
    {
        "time": 1689772370000,
        "value": 139.568,
        "_internal_originalTime": 1689772370000
    },
    {
        "time": 1689772373000,
        "value": 139.561,
        "_internal_originalTime": 1689772373000
    },
    {
        "time": 1689772374000,
        "value": 139.562,
        "_internal_originalTime": 1689772374000
    },
    {
        "time": 1689772375000,
        "value": 139.567,
        "_internal_originalTime": 1689772375000
    },
    {
        "time": 1689772377000,
        "value": 139.573,
        "_internal_originalTime": 1689772377000
    },
    {
        "time": 1689772378000,
        "value": 139.569,
        "_internal_originalTime": 1689772378000
    },
    {
        "time": 1689772379000,
        "value": 139.568,
        "_internal_originalTime": 1689772379000
    },
    {
        "time": 1689772380000,
        "value": 139.572,
        "_internal_originalTime": 1689772380000
    },
    {
        "time": 1689772382000,
        "value": 139.569,
        "_internal_originalTime": 1689772382000
    },
    {
        "time": 1689772383000,
        "value": 139.57,
        "_internal_originalTime": 1689772383000
    },
    {
        "time": 1689772384000,
        "value": 139.571,
        "_internal_originalTime": 1689772384000
    },
    {
        "time": 1689772385000,
        "value": 139.571,
        "_internal_originalTime": 1689772385000
    },
    {
        "time": 1689772387000,
        "value": 139.572,
        "_internal_originalTime": 1689772387000
    },
    {
        "time": 1689772388000,
        "value": 139.574,
        "_internal_originalTime": 1689772388000
    },
    {
        "time": 1689772389000,
        "value": 139.577,
        "_internal_originalTime": 1689772389000
    },
    {
        "time": 1689772391000,
        "value": 139.575,
        "_internal_originalTime": 1689772391000
    },
    {
        "time": 1689772395000,
        "value": 139.567,
        "_internal_originalTime": 1689772395000
    },
    {
        "time": 1689772397000,
        "value": 139.563,
        "_internal_originalTime": 1689772397000
    },
    {
        "time": 1689772398000,
        "value": 139.568,
        "_internal_originalTime": 1689772398000
    },
    {
        "time": 1689772399000,
        "value": 139.566,
        "_internal_originalTime": 1689772399000
    },
    {
        "time": 1689772401000,
        "value": 139.556,
        "_internal_originalTime": 1689772401000
    },
    {
        "time": 1689772402000,
        "value": 139.555,
        "_internal_originalTime": 1689772402000
    },
    {
        "time": 1689772405000,
        "value": 139.549,
        "_internal_originalTime": 1689772405000
    },
    {
        "time": 1689772406000,
        "value": 139.551,
        "_internal_originalTime": 1689772406000
    },
    {
        "time": 1689772410000,
        "value": 139.55,
        "_internal_originalTime": 1689772410000
    },
    {
        "time": 1689772411000,
        "value": 139.548,
        "_internal_originalTime": 1689772411000
    },
    {
        "time": 1689772412000,
        "value": 139.553,
        "_internal_originalTime": 1689772412000
    },
    {
        "time": 1689772413000,
        "value": 139.547,
        "_internal_originalTime": 1689772413000
    },
    {
        "time": 1689772415000,
        "value": 139.542,
        "_internal_originalTime": 1689772415000
    },
    {
        "time": 1689772417000,
        "value": 139.546,
        "_internal_originalTime": 1689772417000
    },
    {
        "time": 1689772418000,
        "value": 139.537,
        "_internal_originalTime": 1689772418000
    },
    {
        "time": 1689772420000,
        "value": 139.528,
        "_internal_originalTime": 1689772420000
    },
    {
        "time": 1689772421000,
        "value": 139.528,
        "_internal_originalTime": 1689772421000
    },
    {
        "time": 1689772422000,
        "value": 139.524,
        "_internal_originalTime": 1689772422000
    },
    {
        "time": 1689772424000,
        "value": 139.527,
        "_internal_originalTime": 1689772424000
    },
    {
        "time": 1689772425000,
        "value": 139.531,
        "_internal_originalTime": 1689772425000
    },
    {
        "time": 1689772427000,
        "value": 139.538,
        "_internal_originalTime": 1689772427000
    },
    {
        "time": 1689772428000,
        "value": 139.536,
        "_internal_originalTime": 1689772428000
    },
    {
        "time": 1689772429000,
        "value": 139.538,
        "_internal_originalTime": 1689772429000
    },
    {
        "time": 1689772431000,
        "value": 139.531,
        "_internal_originalTime": 1689772431000
    },
    {
        "time": 1689772432000,
        "value": 139.527,
        "_internal_originalTime": 1689772432000
    },
    {
        "time": 1689772434000,
        "value": 139.531,
        "_internal_originalTime": 1689772434000
    },
    {
        "time": 1689772435000,
        "value": 139.526,
        "_internal_originalTime": 1689772435000
    },
    {
        "time": 1689772437000,
        "value": 139.529,
        "_internal_originalTime": 1689772437000
    },
    {
        "time": 1689772438000,
        "value": 139.531,
        "_internal_originalTime": 1689772438000
    },
    {
        "time": 1689772440000,
        "value": 139.533,
        "_internal_originalTime": 1689772440000
    },
    {
        "time": 1689772441000,
        "value": 139.54,
        "_internal_originalTime": 1689772441000
    },
    {
        "time": 1689772442000,
        "value": 139.544,
        "_internal_originalTime": 1689772442000
    },
    {
        "time": 1689772443000,
        "value": 139.548,
        "_internal_originalTime": 1689772443000
    },
    {
        "time": 1689772444000,
        "value": 139.549,
        "_internal_originalTime": 1689772444000
    },
    {
        "time": 1689772445000,
        "value": 139.548,
        "_internal_originalTime": 1689772445000
    },
    {
        "time": 1689772446000,
        "value": 139.554,
        "_internal_originalTime": 1689772446000
    },
    {
        "time": 1689772447000,
        "value": 139.556,
        "_internal_originalTime": 1689772447000
    },
    {
        "time": 1689772449000,
        "value": 139.553,
        "_internal_originalTime": 1689772449000
    },
    {
        "time": 1689772450000,
        "value": 139.546,
        "_internal_originalTime": 1689772450000
    },
    {
        "time": 1689772451000,
        "value": 139.548,
        "_internal_originalTime": 1689772451000
    },
    {
        "time": 1689772453000,
        "value": 139.549,
        "_internal_originalTime": 1689772453000
    },
    {
        "time": 1689772455000,
        "value": 139.548,
        "_internal_originalTime": 1689772455000
    },
    {
        "time": 1689772457000,
        "value": 139.549,
        "_internal_originalTime": 1689772457000
    },
    {
        "time": 1689772459000,
        "value": 139.554,
        "_internal_originalTime": 1689772459000
    },
    {
        "time": 1689772460000,
        "value": 139.563,
        "_internal_originalTime": 1689772460000
    },
    {
        "time": 1689772461000,
        "value": 139.565,
        "_internal_originalTime": 1689772461000
    },
    {
        "time": 1689772462000,
        "value": 139.562,
        "_internal_originalTime": 1689772462000
    },
    {
        "time": 1689772464000,
        "value": 139.565,
        "_internal_originalTime": 1689772464000
    },
    {
        "time": 1689772465000,
        "value": 139.563,
        "_internal_originalTime": 1689772465000
    },
    {
        "time": 1689772468000,
        "value": 139.56,
        "_internal_originalTime": 1689772468000
    },
    {
        "time": 1689772469000,
        "value": 139.559,
        "_internal_originalTime": 1689772469000
    },
    {
        "time": 1689772470000,
        "value": 139.56,
        "_internal_originalTime": 1689772470000
    },
    {
        "time": 1689772471000,
        "value": 139.558,
        "_internal_originalTime": 1689772471000
    },
    {
        "time": 1689772472000,
        "value": 139.555,
        "_internal_originalTime": 1689772472000
    },
    {
        "time": 1689772473000,
        "value": 139.556,
        "_internal_originalTime": 1689772473000
    },
    {
        "time": 1689772474000,
        "value": 139.561,
        "_internal_originalTime": 1689772474000
    },
    {
        "time": 1689772476000,
        "value": 139.561,
        "_internal_originalTime": 1689772476000
    },
    {
        "time": 1689772477000,
        "value": 139.548,
        "_internal_originalTime": 1689772477000
    },
    {
        "time": 1689772478000,
        "value": 139.549,
        "_internal_originalTime": 1689772478000
    },
    {
        "time": 1689772479000,
        "value": 139.551,
        "_internal_originalTime": 1689772479000
    },
    {
        "time": 1689772481000,
        "value": 139.538,
        "_internal_originalTime": 1689772481000
    },
    {
        "time": 1689772482000,
        "value": 139.541,
        "_internal_originalTime": 1689772482000
    },
    {
        "time": 1689772483000,
        "value": 139.541,
        "_internal_originalTime": 1689772483000
    },
    {
        "time": 1689772484000,
        "value": 139.54,
        "_internal_originalTime": 1689772484000
    },
    {
        "time": 1689772485000,
        "value": 139.537,
        "_internal_originalTime": 1689772485000
    },
    {
        "time": 1689772486000,
        "value": 139.538,
        "_internal_originalTime": 1689772486000
    },
    {
        "time": 1689772487000,
        "value": 139.525,
        "_internal_originalTime": 1689772487000
    },
    {
        "time": 1689772489000,
        "value": 139.531,
        "_internal_originalTime": 1689772489000
    },
    {
        "time": 1689772490000,
        "value": 139.529,
        "_internal_originalTime": 1689772490000
    },
    {
        "time": 1689772491000,
        "value": 139.526,
        "_internal_originalTime": 1689772491000
    },
    {
        "time": 1689772492000,
        "value": 139.519,
        "_internal_originalTime": 1689772492000
    },
    {
        "time": 1689772493000,
        "value": 139.513,
        "_internal_originalTime": 1689772493000
    },
    {
        "time": 1689772494000,
        "value": 139.514,
        "_internal_originalTime": 1689772494000
    },
    {
        "time": 1689772496000,
        "value": 139.513,
        "_internal_originalTime": 1689772496000
    },
    {
        "time": 1689772497000,
        "value": 139.514,
        "_internal_originalTime": 1689772497000
    },
    {
        "time": 1689772499000,
        "value": 139.501,
        "_internal_originalTime": 1689772499000
    },
    {
        "time": 1689772500000,
        "value": 139.503,
        "_internal_originalTime": 1689772500000
    },
    {
        "time": 1689772502000,
        "value": 139.501,
        "_internal_originalTime": 1689772502000
    },
    {
        "time": 1689772503000,
        "value": 139.503,
        "_internal_originalTime": 1689772503000
    },
    {
        "time": 1689772504000,
        "value": 139.5,
        "_internal_originalTime": 1689772504000
    },
    {
        "time": 1689772506000,
        "value": 139.484,
        "_internal_originalTime": 1689772506000
    },
    {
        "time": 1689772507000,
        "value": 139.473,
        "_internal_originalTime": 1689772507000
    },
    {
        "time": 1689772509000,
        "value": 139.472,
        "_internal_originalTime": 1689772509000
    },
    {
        "time": 1689772510000,
        "value": 139.473,
        "_internal_originalTime": 1689772510000
    },
    {
        "time": 1689772511000,
        "value": 139.485,
        "_internal_originalTime": 1689772511000
    },
    {
        "time": 1689772512000,
        "value": 139.485,
        "_internal_originalTime": 1689772512000
    },
    {
        "time": 1689772513000,
        "value": 139.48,
        "_internal_originalTime": 1689772513000
    },
    {
        "time": 1689772514000,
        "value": 139.48,
        "_internal_originalTime": 1689772514000
    },
    {
        "time": 1689772515000,
        "value": 139.48,
        "_internal_originalTime": 1689772515000
    },
    {
        "time": 1689772516000,
        "value": 139.48,
        "_internal_originalTime": 1689772516000
    },
    {
        "time": 1689772517000,
        "value": 139.484,
        "_internal_originalTime": 1689772517000
    },
    {
        "time": 1689772518000,
        "value": 139.479,
        "_internal_originalTime": 1689772518000
    },
    {
        "time": 1689772519000,
        "value": 139.481,
        "_internal_originalTime": 1689772519000
    },
    {
        "time": 1689772520000,
        "value": 139.479,
        "_internal_originalTime": 1689772520000
    },
    {
        "time": 1689772521000,
        "value": 139.473,
        "_internal_originalTime": 1689772521000
    },
    {
        "time": 1689772522000,
        "value": 139.472,
        "_internal_originalTime": 1689772522000
    },
    {
        "time": 1689772523000,
        "value": 139.484,
        "_internal_originalTime": 1689772523000
    },
    {
        "time": 1689772525000,
        "value": 139.483,
        "_internal_originalTime": 1689772525000
    },
    {
        "time": 1689772527000,
        "value": 139.477,
        "_internal_originalTime": 1689772527000
    },
    {
        "time": 1689772528000,
        "value": 139.477,
        "_internal_originalTime": 1689772528000
    },
    {
        "time": 1689772530000,
        "value": 139.477,
        "_internal_originalTime": 1689772530000
    },
    {
        "time": 1689772531000,
        "value": 139.483,
        "_internal_originalTime": 1689772531000
    },
    {
        "time": 1689772532000,
        "value": 139.481,
        "_internal_originalTime": 1689772532000
    },
    {
        "time": 1689772533000,
        "value": 139.48,
        "_internal_originalTime": 1689772533000
    },
    {
        "time": 1689772534000,
        "value": 139.476,
        "_internal_originalTime": 1689772534000
    },
    {
        "time": 1689772535000,
        "value": 139.479,
        "_internal_originalTime": 1689772535000
    },
    {
        "time": 1689772536000,
        "value": 139.472,
        "_internal_originalTime": 1689772536000
    },
    {
        "time": 1689772537000,
        "value": 139.472,
        "_internal_originalTime": 1689772537000
    },
    {
        "time": 1689772538000,
        "value": 139.475,
        "_internal_originalTime": 1689772538000
    },
    {
        "time": 1689772540000,
        "value": 139.475,
        "_internal_originalTime": 1689772540000
    },
    {
        "time": 1689772541000,
        "value": 139.47,
        "_internal_originalTime": 1689772541000
    },
    {
        "time": 1689772542000,
        "value": 139.472,
        "_internal_originalTime": 1689772542000
    },
    {
        "time": 1689772543000,
        "value": 139.475,
        "_internal_originalTime": 1689772543000
    },
    {
        "time": 1689772544000,
        "value": 139.472,
        "_internal_originalTime": 1689772544000
    },
    {
        "time": 1689772545000,
        "value": 139.471,
        "_internal_originalTime": 1689772545000
    },
    {
        "time": 1689772546000,
        "value": 139.463,
        "_internal_originalTime": 1689772546000
    },
    {
        "time": 1689772547000,
        "value": 139.471,
        "_internal_originalTime": 1689772547000
    },
    {
        "time": 1689772548000,
        "value": 139.472,
        "_internal_originalTime": 1689772548000
    },
    {
        "time": 1689772550000,
        "value": 139.47,
        "_internal_originalTime": 1689772550000
    },
    {
        "time": 1689772551000,
        "value": 139.476,
        "_internal_originalTime": 1689772551000
    },
    {
        "time": 1689772552000,
        "value": 139.484,
        "_internal_originalTime": 1689772552000
    },
    {
        "time": 1689772553000,
        "value": 139.48,
        "_internal_originalTime": 1689772553000
    },
    {
        "time": 1689772554000,
        "value": 139.479,
        "_internal_originalTime": 1689772554000
    },
    {
        "time": 1689772556000,
        "value": 139.47,
        "_internal_originalTime": 1689772556000
    },
    {
        "time": 1689772557000,
        "value": 139.473,
        "_internal_originalTime": 1689772557000
    },
    {
        "time": 1689772558000,
        "value": 139.471,
        "_internal_originalTime": 1689772558000
    },
    {
        "time": 1689772560000,
        "value": 139.476,
        "_internal_originalTime": 1689772560000
    },
    {
        "time": 1689772561000,
        "value": 139.477,
        "_internal_originalTime": 1689772561000
    },
    {
        "time": 1689772562000,
        "value": 139.477,
        "_internal_originalTime": 1689772562000
    },
    {
        "time": 1689772564000,
        "value": 139.484,
        "_internal_originalTime": 1689772564000
    },
    {
        "time": 1689772565000,
        "value": 139.479,
        "_internal_originalTime": 1689772565000
    },
    {
        "time": 1689772566000,
        "value": 139.473,
        "_internal_originalTime": 1689772566000
    },
    {
        "time": 1689772567000,
        "value": 139.473,
        "_internal_originalTime": 1689772567000
    },
    {
        "time": 1689772568000,
        "value": 139.47,
        "_internal_originalTime": 1689772568000
    },
    {
        "time": 1689772570000,
        "value": 139.477,
        "_internal_originalTime": 1689772570000
    },
    {
        "time": 1689772571000,
        "value": 139.479,
        "_internal_originalTime": 1689772571000
    },
    {
        "time": 1689772572000,
        "value": 139.481,
        "_internal_originalTime": 1689772572000
    },
    {
        "time": 1689772573000,
        "value": 139.478,
        "_internal_originalTime": 1689772573000
    },
    {
        "time": 1689772574000,
        "value": 139.478,
        "_internal_originalTime": 1689772574000
    },
    {
        "time": 1689772575000,
        "value": 139.479,
        "_internal_originalTime": 1689772575000
    },
    {
        "time": 1689772576000,
        "value": 139.483,
        "_internal_originalTime": 1689772576000
    },
    {
        "time": 1689772577000,
        "value": 139.482,
        "_internal_originalTime": 1689772577000
    },
    {
        "time": 1689772578000,
        "value": 139.483,
        "_internal_originalTime": 1689772578000
    },
    {
        "time": 1689772580000,
        "value": 139.481,
        "_internal_originalTime": 1689772580000
    },
    {
        "time": 1689772581000,
        "value": 139.483,
        "_internal_originalTime": 1689772581000
    },
    {
        "time": 1689772583000,
        "value": 139.481,
        "_internal_originalTime": 1689772583000
    },
    {
        "time": 1689772584000,
        "value": 139.48,
        "_internal_originalTime": 1689772584000
    },
    {
        "time": 1689772585000,
        "value": 139.482,
        "_internal_originalTime": 1689772585000
    },
    {
        "time": 1689772587000,
        "value": 139.48,
        "_internal_originalTime": 1689772587000
    },
    {
        "time": 1689772588000,
        "value": 139.48,
        "_internal_originalTime": 1689772588000
    },
    {
        "time": 1689772590000,
        "value": 139.491,
        "_internal_originalTime": 1689772590000
    },
    {
        "time": 1689772591000,
        "value": 139.49,
        "_internal_originalTime": 1689772591000
    },
    {
        "time": 1689772592000,
        "value": 139.49,
        "_internal_originalTime": 1689772592000
    },
    {
        "time": 1689772593000,
        "value": 139.485,
        "_internal_originalTime": 1689772593000
    },
    {
        "time": 1689772594000,
        "value": 139.485,
        "_internal_originalTime": 1689772594000
    },
    {
        "time": 1689772596000,
        "value": 139.483,
        "_internal_originalTime": 1689772596000
    },
    {
        "time": 1689772597000,
        "value": 139.485,
        "_internal_originalTime": 1689772597000
    },
    {
        "time": 1689772598000,
        "value": 139.474,
        "_internal_originalTime": 1689772598000
    },
    {
        "time": 1689772600000,
        "value": 139.476,
        "_internal_originalTime": 1689772600000
    },
    {
        "time": 1689772601000,
        "value": 139.473,
        "_internal_originalTime": 1689772601000
    },
    {
        "time": 1689772602000,
        "value": 139.472,
        "_internal_originalTime": 1689772602000
    },
    {
        "time": 1689772604000,
        "value": 139.475,
        "_internal_originalTime": 1689772604000
    },
    {
        "time": 1689772605000,
        "value": 139.474,
        "_internal_originalTime": 1689772605000
    },
    {
        "time": 1689772606000,
        "value": 139.472,
        "_internal_originalTime": 1689772606000
    },
    {
        "time": 1689772608000,
        "value": 139.466,
        "_internal_originalTime": 1689772608000
    },
    {
        "time": 1689772609000,
        "value": 139.466,
        "_internal_originalTime": 1689772609000
    },
    {
        "time": 1689772610000,
        "value": 139.462,
        "_internal_originalTime": 1689772610000
    },
    {
        "time": 1689772612000,
        "value": 139.474,
        "_internal_originalTime": 1689772612000
    },
    {
        "time": 1689772613000,
        "value": 139.474,
        "_internal_originalTime": 1689772613000
    },
    {
        "time": 1689772614000,
        "value": 139.477,
        "_internal_originalTime": 1689772614000
    },
    {
        "time": 1689772615000,
        "value": 139.483,
        "_internal_originalTime": 1689772615000
    },
    {
        "time": 1689772617000,
        "value": 139.483,
        "_internal_originalTime": 1689772617000
    },
    {
        "time": 1689772619000,
        "value": 139.489,
        "_internal_originalTime": 1689772619000
    },
    {
        "time": 1689772620000,
        "value": 139.488,
        "_internal_originalTime": 1689772620000
    },
    {
        "time": 1689772621000,
        "value": 139.491,
        "_internal_originalTime": 1689772621000
    },
    {
        "time": 1689772623000,
        "value": 139.491,
        "_internal_originalTime": 1689772623000
    },
    {
        "time": 1689772624000,
        "value": 139.49,
        "_internal_originalTime": 1689772624000
    },
    {
        "time": 1689772625000,
        "value": 139.491,
        "_internal_originalTime": 1689772625000
    },
    {
        "time": 1689772626000,
        "value": 139.49,
        "_internal_originalTime": 1689772626000
    },
    {
        "time": 1689772628000,
        "value": 139.494,
        "_internal_originalTime": 1689772628000
    },
    {
        "time": 1689772629000,
        "value": 139.498,
        "_internal_originalTime": 1689772629000
    },
    {
        "time": 1689772630000,
        "value": 139.502,
        "_internal_originalTime": 1689772630000
    },
    {
        "time": 1689772632000,
        "value": 139.497,
        "_internal_originalTime": 1689772632000
    },
    {
        "time": 1689772633000,
        "value": 139.509,
        "_internal_originalTime": 1689772633000
    },
    {
        "time": 1689772634000,
        "value": 139.517,
        "_internal_originalTime": 1689772634000
    },
    {
        "time": 1689772635000,
        "value": 139.519,
        "_internal_originalTime": 1689772635000
    },
    {
        "time": 1689772637000,
        "value": 139.514,
        "_internal_originalTime": 1689772637000
    },
    {
        "time": 1689772639000,
        "value": 139.513,
        "_internal_originalTime": 1689772639000
    },
    {
        "time": 1689772641000,
        "value": 139.515,
        "_internal_originalTime": 1689772641000
    },
    {
        "time": 1689772643000,
        "value": 139.512,
        "_internal_originalTime": 1689772643000
    },
    {
        "time": 1689772644000,
        "value": 139.505,
        "_internal_originalTime": 1689772644000
    },
    {
        "time": 1689772645000,
        "value": 139.508,
        "_internal_originalTime": 1689772645000
    },
    {
        "time": 1689772649000,
        "value": 139.508,
        "_internal_originalTime": 1689772649000
    },
    {
        "time": 1689772650000,
        "value": 139.514,
        "_internal_originalTime": 1689772650000
    },
    {
        "time": 1689772651000,
        "value": 139.515,
        "_internal_originalTime": 1689772651000
    },
    {
        "time": 1689772652000,
        "value": 139.513,
        "_internal_originalTime": 1689772652000
    },
    {
        "time": 1689772654000,
        "value": 139.512,
        "_internal_originalTime": 1689772654000
    },
    {
        "time": 1689772655000,
        "value": 139.513,
        "_internal_originalTime": 1689772655000
    },
    {
        "time": 1689772656000,
        "value": 139.518,
        "_internal_originalTime": 1689772656000
    },
    {
        "time": 1689772658000,
        "value": 139.519,
        "_internal_originalTime": 1689772658000
    },
    {
        "time": 1689772659000,
        "value": 139.518,
        "_internal_originalTime": 1689772659000
    },
    {
        "time": 1689772661000,
        "value": 139.523,
        "_internal_originalTime": 1689772661000
    },
    {
        "time": 1689772663000,
        "value": 139.521,
        "_internal_originalTime": 1689772663000
    },
    {
        "time": 1689772665000,
        "value": 139.519,
        "_internal_originalTime": 1689772665000
    },
    {
        "time": 1689772667000,
        "value": 139.52,
        "_internal_originalTime": 1689772667000
    },
    {
        "time": 1689772669000,
        "value": 139.52,
        "_internal_originalTime": 1689772669000
    },
    {
        "time": 1689772670000,
        "value": 139.521,
        "_internal_originalTime": 1689772670000
    },
    {
        "time": 1689772672000,
        "value": 139.52,
        "_internal_originalTime": 1689772672000
    },
    {
        "time": 1689772673000,
        "value": 139.519,
        "_internal_originalTime": 1689772673000
    },
    {
        "time": 1689772674000,
        "value": 139.519,
        "_internal_originalTime": 1689772674000
    },
    {
        "time": 1689772675000,
        "value": 139.521,
        "_internal_originalTime": 1689772675000
    },
    {
        "time": 1689772677000,
        "value": 139.521,
        "_internal_originalTime": 1689772677000
    },
    {
        "time": 1689772678000,
        "value": 139.519,
        "_internal_originalTime": 1689772678000
    },
    {
        "time": 1689772680000,
        "value": 139.533,
        "_internal_originalTime": 1689772680000
    },
    {
        "time": 1689772682000,
        "value": 139.54,
        "_internal_originalTime": 1689772682000
    },
    {
        "time": 1689772683000,
        "value": 139.539,
        "_internal_originalTime": 1689772683000
    },
    {
        "time": 1689772684000,
        "value": 139.542,
        "_internal_originalTime": 1689772684000
    },
    {
        "time": 1689772686000,
        "value": 139.541,
        "_internal_originalTime": 1689772686000
    },
    {
        "time": 1689772687000,
        "value": 139.54,
        "_internal_originalTime": 1689772687000
    },
    {
        "time": 1689772689000,
        "value": 139.533,
        "_internal_originalTime": 1689772689000
    },
    {
        "time": 1689772690000,
        "value": 139.536,
        "_internal_originalTime": 1689772690000
    },
    {
        "time": 1689772691000,
        "value": 139.533,
        "_internal_originalTime": 1689772691000
    },
    {
        "time": 1689772693000,
        "value": 139.528,
        "_internal_originalTime": 1689772693000
    },
    {
        "time": 1689772694000,
        "value": 139.529,
        "_internal_originalTime": 1689772694000
    },
    {
        "time": 1689772695000,
        "value": 139.528,
        "_internal_originalTime": 1689772695000
    },
    {
        "time": 1689772696000,
        "value": 139.536,
        "_internal_originalTime": 1689772696000
    },
    {
        "time": 1689772697000,
        "value": 139.534,
        "_internal_originalTime": 1689772697000
    },
    {
        "time": 1689772699000,
        "value": 139.539,
        "_internal_originalTime": 1689772699000
    },
    {
        "time": 1689772700000,
        "value": 139.539,
        "_internal_originalTime": 1689772700000
    },
    {
        "time": 1689772701000,
        "value": 139.537,
        "_internal_originalTime": 1689772701000
    },
    {
        "time": 1689772702000,
        "value": 139.539,
        "_internal_originalTime": 1689772702000
    },
    {
        "time": 1689772704000,
        "value": 139.534,
        "_internal_originalTime": 1689772704000
    },
    {
        "time": 1689772705000,
        "value": 139.531,
        "_internal_originalTime": 1689772705000
    },
    {
        "time": 1689772706000,
        "value": 139.532,
        "_internal_originalTime": 1689772706000
    },
    {
        "time": 1689772707000,
        "value": 139.532,
        "_internal_originalTime": 1689772707000
    },
    {
        "time": 1689772709000,
        "value": 139.529,
        "_internal_originalTime": 1689772709000
    },
    {
        "time": 1689772710000,
        "value": 139.533,
        "_internal_originalTime": 1689772710000
    },
    {
        "time": 1689772711000,
        "value": 139.533,
        "_internal_originalTime": 1689772711000
    },
    {
        "time": 1689772712000,
        "value": 139.542,
        "_internal_originalTime": 1689772712000
    },
    {
        "time": 1689772713000,
        "value": 139.54,
        "_internal_originalTime": 1689772713000
    },
    {
        "time": 1689772715000,
        "value": 139.542,
        "_internal_originalTime": 1689772715000
    },
    {
        "time": 1689772716000,
        "value": 139.538,
        "_internal_originalTime": 1689772716000
    },
    {
        "time": 1689772718000,
        "value": 139.541,
        "_internal_originalTime": 1689772718000
    },
    {
        "time": 1689772719000,
        "value": 139.539,
        "_internal_originalTime": 1689772719000
    },
    {
        "time": 1689772721000,
        "value": 139.532,
        "_internal_originalTime": 1689772721000
    },
    {
        "time": 1689772723000,
        "value": 139.537,
        "_internal_originalTime": 1689772723000
    },
    {
        "time": 1689772724000,
        "value": 139.539,
        "_internal_originalTime": 1689772724000
    },
    {
        "time": 1689772725000,
        "value": 139.539,
        "_internal_originalTime": 1689772725000
    },
    {
        "time": 1689772727000,
        "value": 139.539,
        "_internal_originalTime": 1689772727000
    },
    {
        "time": 1689772728000,
        "value": 139.54,
        "_internal_originalTime": 1689772728000
    },
    {
        "time": 1689772729000,
        "value": 139.54,
        "_internal_originalTime": 1689772729000
    },
    {
        "time": 1689772730000,
        "value": 139.54,
        "_internal_originalTime": 1689772730000
    },
    {
        "time": 1689772732000,
        "value": 139.538,
        "_internal_originalTime": 1689772732000
    },
    {
        "time": 1689772733000,
        "value": 139.532,
        "_internal_originalTime": 1689772733000
    },
    {
        "time": 1689772734000,
        "value": 139.533,
        "_internal_originalTime": 1689772734000
    },
    {
        "time": 1689772735000,
        "value": 139.532,
        "_internal_originalTime": 1689772735000
    },
    {
        "time": 1689772737000,
        "value": 139.534,
        "_internal_originalTime": 1689772737000
    },
    {
        "time": 1689772740000,
        "value": 139.53,
        "_internal_originalTime": 1689772740000
    },
    {
        "time": 1689772743000,
        "value": 139.532,
        "_internal_originalTime": 1689772743000
    },
    {
        "time": 1689772744000,
        "value": 139.533,
        "_internal_originalTime": 1689772744000
    },
    {
        "time": 1689772745000,
        "value": 139.53,
        "_internal_originalTime": 1689772745000
    },
    {
        "time": 1689772747000,
        "value": 139.53,
        "_internal_originalTime": 1689772747000
    },
    {
        "time": 1689772748000,
        "value": 139.533,
        "_internal_originalTime": 1689772748000
    },
    {
        "time": 1689772749000,
        "value": 139.533,
        "_internal_originalTime": 1689772749000
    },
    {
        "time": 1689772750000,
        "value": 139.527,
        "_internal_originalTime": 1689772750000
    },
    {
        "time": 1689772752000,
        "value": 139.533,
        "_internal_originalTime": 1689772752000
    },
    {
        "time": 1689772753000,
        "value": 139.53,
        "_internal_originalTime": 1689772753000
    },
    {
        "time": 1689772754000,
        "value": 139.533,
        "_internal_originalTime": 1689772754000
    },
    {
        "time": 1689772756000,
        "value": 139.53,
        "_internal_originalTime": 1689772756000
    },
    {
        "time": 1689772757000,
        "value": 139.54,
        "_internal_originalTime": 1689772757000
    },
    {
        "time": 1689772758000,
        "value": 139.541,
        "_internal_originalTime": 1689772758000
    },
    {
        "time": 1689772759000,
        "value": 139.536,
        "_internal_originalTime": 1689772759000
    },
    {
        "time": 1689772761000,
        "value": 139.539,
        "_internal_originalTime": 1689772761000
    },
    {
        "time": 1689772762000,
        "value": 139.542,
        "_internal_originalTime": 1689772762000
    },
    {
        "time": 1689772763000,
        "value": 139.55,
        "_internal_originalTime": 1689772763000
    },
    {
        "time": 1689772765000,
        "value": 139.548,
        "_internal_originalTime": 1689772765000
    },
    {
        "time": 1689772766000,
        "value": 139.549,
        "_internal_originalTime": 1689772766000
    },
    {
        "time": 1689772767000,
        "value": 139.551,
        "_internal_originalTime": 1689772767000
    },
    {
        "time": 1689772769000,
        "value": 139.553,
        "_internal_originalTime": 1689772769000
    },
    {
        "time": 1689772770000,
        "value": 139.551,
        "_internal_originalTime": 1689772770000
    },
    {
        "time": 1689772771000,
        "value": 139.541,
        "_internal_originalTime": 1689772771000
    },
    {
        "time": 1689772772000,
        "value": 139.541,
        "_internal_originalTime": 1689772772000
    },
    {
        "time": 1689772773000,
        "value": 139.54,
        "_internal_originalTime": 1689772773000
    },
    {
        "time": 1689772774000,
        "value": 139.542,
        "_internal_originalTime": 1689772774000
    },
    {
        "time": 1689772775000,
        "value": 139.54,
        "_internal_originalTime": 1689772775000
    },
    {
        "time": 1689772776000,
        "value": 139.54,
        "_internal_originalTime": 1689772776000
    },
    {
        "time": 1689772778000,
        "value": 139.544,
        "_internal_originalTime": 1689772778000
    },
    {
        "time": 1689772780000,
        "value": 139.537,
        "_internal_originalTime": 1689772780000
    },
    {
        "time": 1689772782000,
        "value": 139.53,
        "_internal_originalTime": 1689772782000
    },
    {
        "time": 1689772783000,
        "value": 139.526,
        "_internal_originalTime": 1689772783000
    },
    {
        "time": 1689772785000,
        "value": 139.517,
        "_internal_originalTime": 1689772785000
    },
    {
        "time": 1689772786000,
        "value": 139.511,
        "_internal_originalTime": 1689772786000
    },
    {
        "time": 1689772787000,
        "value": 139.518,
        "_internal_originalTime": 1689772787000
    },
    {
        "time": 1689772788000,
        "value": 139.513,
        "_internal_originalTime": 1689772788000
    },
    {
        "time": 1689772790000,
        "value": 139.51,
        "_internal_originalTime": 1689772790000
    },
    {
        "time": 1689772791000,
        "value": 139.513,
        "_internal_originalTime": 1689772791000
    },
    {
        "time": 1689772792000,
        "value": 139.521,
        "_internal_originalTime": 1689772792000
    },
    {
        "time": 1689772793000,
        "value": 139.524,
        "_internal_originalTime": 1689772793000
    },
    {
        "time": 1689772794000,
        "value": 139.525,
        "_internal_originalTime": 1689772794000
    },
    {
        "time": 1689772796000,
        "value": 139.529,
        "_internal_originalTime": 1689772796000
    },
    {
        "time": 1689772797000,
        "value": 139.538,
        "_internal_originalTime": 1689772797000
    },
    {
        "time": 1689772798000,
        "value": 139.538,
        "_internal_originalTime": 1689772798000
    },
    {
        "time": 1689772799000,
        "value": 139.538,
        "_internal_originalTime": 1689772799000
    },
    {
        "time": 1689772800000,
        "value": 139.546,
        "_internal_originalTime": 1689772800000
    },
    {
        "time": 1689772801000,
        "value": 139.543,
        "_internal_originalTime": 1689772801000
    },
    {
        "time": 1689772802000,
        "value": 139.543,
        "_internal_originalTime": 1689772802000
    },
    {
        "time": 1689772803000,
        "value": 139.545,
        "_internal_originalTime": 1689772803000
    },
    {
        "time": 1689772804000,
        "value": 139.54,
        "_internal_originalTime": 1689772804000
    },
    {
        "time": 1689772805000,
        "value": 139.536,
        "_internal_originalTime": 1689772805000
    },
    {
        "time": 1689772806000,
        "value": 139.54,
        "_internal_originalTime": 1689772806000
    },
    {
        "time": 1689772807000,
        "value": 139.541,
        "_internal_originalTime": 1689772807000
    },
    {
        "time": 1689772808000,
        "value": 139.542,
        "_internal_originalTime": 1689772808000
    },
    {
        "time": 1689772809000,
        "value": 139.537,
        "_internal_originalTime": 1689772809000
    },
    {
        "time": 1689772811000,
        "value": 139.534,
        "_internal_originalTime": 1689772811000
    },
    {
        "time": 1689772812000,
        "value": 139.54,
        "_internal_originalTime": 1689772812000
    },
    {
        "time": 1689772813000,
        "value": 139.534,
        "_internal_originalTime": 1689772813000
    },
    {
        "time": 1689772815000,
        "value": 139.537,
        "_internal_originalTime": 1689772815000
    },
    {
        "time": 1689772816000,
        "value": 139.519,
        "_internal_originalTime": 1689772816000
    },
    {
        "time": 1689772817000,
        "value": 139.519,
        "_internal_originalTime": 1689772817000
    },
    {
        "time": 1689772818000,
        "value": 139.516,
        "_internal_originalTime": 1689772818000
    },
    {
        "time": 1689772819000,
        "value": 139.514,
        "_internal_originalTime": 1689772819000
    },
    {
        "time": 1689772820000,
        "value": 139.52,
        "_internal_originalTime": 1689772820000
    },
    {
        "time": 1689772821000,
        "value": 139.527,
        "_internal_originalTime": 1689772821000
    },
    {
        "time": 1689772822000,
        "value": 139.528,
        "_internal_originalTime": 1689772822000
    },
    {
        "time": 1689772823000,
        "value": 139.536,
        "_internal_originalTime": 1689772823000
    },
    {
        "time": 1689772825000,
        "value": 139.528,
        "_internal_originalTime": 1689772825000
    },
    {
        "time": 1689772826000,
        "value": 139.534,
        "_internal_originalTime": 1689772826000
    },
    {
        "time": 1689772827000,
        "value": 139.535,
        "_internal_originalTime": 1689772827000
    },
    {
        "time": 1689772829000,
        "value": 139.535,
        "_internal_originalTime": 1689772829000
    },
    {
        "time": 1689772830000,
        "value": 139.534,
        "_internal_originalTime": 1689772830000
    },
    {
        "time": 1689772832000,
        "value": 139.536,
        "_internal_originalTime": 1689772832000
    },
    {
        "time": 1689772833000,
        "value": 139.535,
        "_internal_originalTime": 1689772833000
    },
    {
        "time": 1689772834000,
        "value": 139.541,
        "_internal_originalTime": 1689772834000
    },
    {
        "time": 1689772835000,
        "value": 139.544,
        "_internal_originalTime": 1689772835000
    },
    {
        "time": 1689772836000,
        "value": 139.538,
        "_internal_originalTime": 1689772836000
    },
    {
        "time": 1689772837000,
        "value": 139.542,
        "_internal_originalTime": 1689772837000
    },
    {
        "time": 1689772838000,
        "value": 139.539,
        "_internal_originalTime": 1689772838000
    },
    {
        "time": 1689772840000,
        "value": 139.541,
        "_internal_originalTime": 1689772840000
    },
    {
        "time": 1689772841000,
        "value": 139.539,
        "_internal_originalTime": 1689772841000
    },
    {
        "time": 1689772842000,
        "value": 139.535,
        "_internal_originalTime": 1689772842000
    },
    {
        "time": 1689772843000,
        "value": 139.534,
        "_internal_originalTime": 1689772843000
    },
    {
        "time": 1689772844000,
        "value": 139.537,
        "_internal_originalTime": 1689772844000
    },
    {
        "time": 1689772845000,
        "value": 139.539,
        "_internal_originalTime": 1689772845000
    },
    {
        "time": 1689772846000,
        "value": 139.535,
        "_internal_originalTime": 1689772846000
    },
    {
        "time": 1689772848000,
        "value": 139.539,
        "_internal_originalTime": 1689772848000
    },
    {
        "time": 1689772849000,
        "value": 139.536,
        "_internal_originalTime": 1689772849000
    },
    {
        "time": 1689772850000,
        "value": 139.536,
        "_internal_originalTime": 1689772850000
    },
    {
        "time": 1689772852000,
        "value": 139.537,
        "_internal_originalTime": 1689772852000
    },
    {
        "time": 1689772853000,
        "value": 139.528,
        "_internal_originalTime": 1689772853000
    },
    {
        "time": 1689772854000,
        "value": 139.531,
        "_internal_originalTime": 1689772854000
    },
    {
        "time": 1689772855000,
        "value": 139.532,
        "_internal_originalTime": 1689772855000
    },
    {
        "time": 1689772857000,
        "value": 139.53,
        "_internal_originalTime": 1689772857000
    },
    {
        "time": 1689772859000,
        "value": 139.528,
        "_internal_originalTime": 1689772859000
    },
    {
        "time": 1689772860000,
        "value": 139.531,
        "_internal_originalTime": 1689772860000
    },
    {
        "time": 1689772861000,
        "value": 139.531,
        "_internal_originalTime": 1689772861000
    },
    {
        "time": 1689772862000,
        "value": 139.536,
        "_internal_originalTime": 1689772862000
    },
    {
        "time": 1689772863000,
        "value": 139.535,
        "_internal_originalTime": 1689772863000
    },
    {
        "time": 1689772866000,
        "value": 139.543,
        "_internal_originalTime": 1689772866000
    },
    {
        "time": 1689772867000,
        "value": 139.534,
        "_internal_originalTime": 1689772867000
    },
    {
        "time": 1689772868000,
        "value": 139.532,
        "_internal_originalTime": 1689772868000
    },
    {
        "time": 1689772869000,
        "value": 139.531,
        "_internal_originalTime": 1689772869000
    },
    {
        "time": 1689772870000,
        "value": 139.525,
        "_internal_originalTime": 1689772870000
    },
    {
        "time": 1689772872000,
        "value": 139.532,
        "_internal_originalTime": 1689772872000
    },
    {
        "time": 1689772873000,
        "value": 139.512,
        "_internal_originalTime": 1689772873000
    },
    {
        "time": 1689772875000,
        "value": 139.508,
        "_internal_originalTime": 1689772875000
    },
    {
        "time": 1689772876000,
        "value": 139.505,
        "_internal_originalTime": 1689772876000
    },
    {
        "time": 1689772877000,
        "value": 139.513,
        "_internal_originalTime": 1689772877000
    },
    {
        "time": 1689772879000,
        "value": 139.51,
        "_internal_originalTime": 1689772879000
    },
    {
        "time": 1689772880000,
        "value": 139.509,
        "_internal_originalTime": 1689772880000
    },
    {
        "time": 1689772881000,
        "value": 139.507,
        "_internal_originalTime": 1689772881000
    },
    {
        "time": 1689772883000,
        "value": 139.5,
        "_internal_originalTime": 1689772883000
    },
    {
        "time": 1689772884000,
        "value": 139.5,
        "_internal_originalTime": 1689772884000
    },
    {
        "time": 1689772885000,
        "value": 139.503,
        "_internal_originalTime": 1689772885000
    },
    {
        "time": 1689772887000,
        "value": 139.498,
        "_internal_originalTime": 1689772887000
    },
    {
        "time": 1689772888000,
        "value": 139.5,
        "_internal_originalTime": 1689772888000
    },
    {
        "time": 1689772889000,
        "value": 139.501,
        "_internal_originalTime": 1689772889000
    },
    {
        "time": 1689772890000,
        "value": 139.498,
        "_internal_originalTime": 1689772890000
    },
    {
        "time": 1689772891000,
        "value": 139.5,
        "_internal_originalTime": 1689772891000
    },
    {
        "time": 1689772893000,
        "value": 139.498,
        "_internal_originalTime": 1689772893000
    },
    {
        "time": 1689772894000,
        "value": 139.499,
        "_internal_originalTime": 1689772894000
    },
    {
        "time": 1689772895000,
        "value": 139.494,
        "_internal_originalTime": 1689772895000
    },
    {
        "time": 1689772896000,
        "value": 139.48,
        "_internal_originalTime": 1689772896000
    },
    {
        "time": 1689772898000,
        "value": 139.487,
        "_internal_originalTime": 1689772898000
    },
    {
        "time": 1689772899000,
        "value": 139.484,
        "_internal_originalTime": 1689772899000
    },
    {
        "time": 1689772900000,
        "value": 139.481,
        "_internal_originalTime": 1689772900000
    },
    {
        "time": 1689772902000,
        "value": 139.471,
        "_internal_originalTime": 1689772902000
    },
    {
        "time": 1689772904000,
        "value": 139.493,
        "_internal_originalTime": 1689772904000
    },
    {
        "time": 1689772905000,
        "value": 139.501,
        "_internal_originalTime": 1689772905000
    },
    {
        "time": 1689772907000,
        "value": 139.503,
        "_internal_originalTime": 1689772907000
    },
    {
        "time": 1689772909000,
        "value": 139.498,
        "_internal_originalTime": 1689772909000
    },
    {
        "time": 1689772910000,
        "value": 139.486,
        "_internal_originalTime": 1689772910000
    },
    {
        "time": 1689772911000,
        "value": 139.483,
        "_internal_originalTime": 1689772911000
    },
    {
        "time": 1689772912000,
        "value": 139.491,
        "_internal_originalTime": 1689772912000
    },
    {
        "time": 1689772913000,
        "value": 139.491,
        "_internal_originalTime": 1689772913000
    },
    {
        "time": 1689772915000,
        "value": 139.494,
        "_internal_originalTime": 1689772915000
    },
    {
        "time": 1689772916000,
        "value": 139.496,
        "_internal_originalTime": 1689772916000
    },
    {
        "time": 1689772917000,
        "value": 139.5,
        "_internal_originalTime": 1689772917000
    },
    {
        "time": 1689772918000,
        "value": 139.503,
        "_internal_originalTime": 1689772918000
    },
    {
        "time": 1689772919000,
        "value": 139.501,
        "_internal_originalTime": 1689772919000
    },
    {
        "time": 1689772920000,
        "value": 139.501,
        "_internal_originalTime": 1689772920000
    },
    {
        "time": 1689772921000,
        "value": 139.504,
        "_internal_originalTime": 1689772921000
    },
    {
        "time": 1689772923000,
        "value": 139.503,
        "_internal_originalTime": 1689772923000
    },
    {
        "time": 1689772924000,
        "value": 139.5,
        "_internal_originalTime": 1689772924000
    },
    {
        "time": 1689772926000,
        "value": 139.505,
        "_internal_originalTime": 1689772926000
    },
    {
        "time": 1689772928000,
        "value": 139.507,
        "_internal_originalTime": 1689772928000
    },
    {
        "time": 1689772929000,
        "value": 139.502,
        "_internal_originalTime": 1689772929000
    },
    {
        "time": 1689772930000,
        "value": 139.507,
        "_internal_originalTime": 1689772930000
    },
    {
        "time": 1689772932000,
        "value": 139.508,
        "_internal_originalTime": 1689772932000
    },
    {
        "time": 1689772933000,
        "value": 139.503,
        "_internal_originalTime": 1689772933000
    },
    {
        "time": 1689772934000,
        "value": 139.504,
        "_internal_originalTime": 1689772934000
    },
    {
        "time": 1689772936000,
        "value": 139.502,
        "_internal_originalTime": 1689772936000
    },
    {
        "time": 1689772937000,
        "value": 139.501,
        "_internal_originalTime": 1689772937000
    },
    {
        "time": 1689772939000,
        "value": 139.5,
        "_internal_originalTime": 1689772939000
    },
    {
        "time": 1689772940000,
        "value": 139.506,
        "_internal_originalTime": 1689772940000
    },
    {
        "time": 1689772941000,
        "value": 139.509,
        "_internal_originalTime": 1689772941000
    },
    {
        "time": 1689772942000,
        "value": 139.509,
        "_internal_originalTime": 1689772942000
    },
    {
        "time": 1689772944000,
        "value": 139.509,
        "_internal_originalTime": 1689772944000
    },
    {
        "time": 1689772945000,
        "value": 139.507,
        "_internal_originalTime": 1689772945000
    },
    {
        "time": 1689772946000,
        "value": 139.508,
        "_internal_originalTime": 1689772946000
    },
    {
        "time": 1689772947000,
        "value": 139.509,
        "_internal_originalTime": 1689772947000
    },
    {
        "time": 1689772949000,
        "value": 139.509,
        "_internal_originalTime": 1689772949000
    },
    {
        "time": 1689772950000,
        "value": 139.509,
        "_internal_originalTime": 1689772950000
    },
    {
        "time": 1689772952000,
        "value": 139.51,
        "_internal_originalTime": 1689772952000
    },
    {
        "time": 1689772953000,
        "value": 139.509,
        "_internal_originalTime": 1689772953000
    },
    {
        "time": 1689772955000,
        "value": 139.509,
        "_internal_originalTime": 1689772955000
    },
    {
        "time": 1689772956000,
        "value": 139.509,
        "_internal_originalTime": 1689772956000
    },
    {
        "time": 1689772958000,
        "value": 139.511,
        "_internal_originalTime": 1689772958000
    },
    {
        "time": 1689772959000,
        "value": 139.508,
        "_internal_originalTime": 1689772959000
    },
    {
        "time": 1689772960000,
        "value": 139.506,
        "_internal_originalTime": 1689772960000
    },
    {
        "time": 1689772961000,
        "value": 139.506,
        "_internal_originalTime": 1689772961000
    },
    {
        "time": 1689772962000,
        "value": 139.502,
        "_internal_originalTime": 1689772962000
    },
    {
        "time": 1689772964000,
        "value": 139.5,
        "_internal_originalTime": 1689772964000
    },
    {
        "time": 1689772965000,
        "value": 139.503,
        "_internal_originalTime": 1689772965000
    },
    {
        "time": 1689772966000,
        "value": 139.503,
        "_internal_originalTime": 1689772966000
    },
    {
        "time": 1689772968000,
        "value": 139.5,
        "_internal_originalTime": 1689772968000
    },
    {
        "time": 1689772969000,
        "value": 139.503,
        "_internal_originalTime": 1689772969000
    },
    {
        "time": 1689772970000,
        "value": 139.503,
        "_internal_originalTime": 1689772970000
    },
    {
        "time": 1689772971000,
        "value": 139.503,
        "_internal_originalTime": 1689772971000
    },
    {
        "time": 1689772973000,
        "value": 139.503,
        "_internal_originalTime": 1689772973000
    },
    {
        "time": 1689772974000,
        "value": 139.509,
        "_internal_originalTime": 1689772974000
    },
    {
        "time": 1689772975000,
        "value": 139.508,
        "_internal_originalTime": 1689772975000
    },
    {
        "time": 1689772977000,
        "value": 139.514,
        "_internal_originalTime": 1689772977000
    },
    {
        "time": 1689772979000,
        "value": 139.514,
        "_internal_originalTime": 1689772979000
    },
    {
        "time": 1689772980000,
        "value": 139.511,
        "_internal_originalTime": 1689772980000
    },
    {
        "time": 1689772981000,
        "value": 139.52,
        "_internal_originalTime": 1689772981000
    },
    {
        "time": 1689772982000,
        "value": 139.515,
        "_internal_originalTime": 1689772982000
    },
    {
        "time": 1689772983000,
        "value": 139.512,
        "_internal_originalTime": 1689772983000
    },
    {
        "time": 1689772984000,
        "value": 139.51,
        "_internal_originalTime": 1689772984000
    },
    {
        "time": 1689772985000,
        "value": 139.511,
        "_internal_originalTime": 1689772985000
    },
    {
        "time": 1689772986000,
        "value": 139.506,
        "_internal_originalTime": 1689772986000
    },
    {
        "time": 1689772987000,
        "value": 139.51,
        "_internal_originalTime": 1689772987000
    },
    {
        "time": 1689772989000,
        "value": 139.501,
        "_internal_originalTime": 1689772989000
    },
    {
        "time": 1689772990000,
        "value": 139.5,
        "_internal_originalTime": 1689772990000
    },
    {
        "time": 1689772991000,
        "value": 139.495,
        "_internal_originalTime": 1689772991000
    },
    {
        "time": 1689772992000,
        "value": 139.493,
        "_internal_originalTime": 1689772992000
    },
    {
        "time": 1689772993000,
        "value": 139.493,
        "_internal_originalTime": 1689772993000
    },
    {
        "time": 1689772994000,
        "value": 139.495,
        "_internal_originalTime": 1689772994000
    },
    {
        "time": 1689772995000,
        "value": 139.492,
        "_internal_originalTime": 1689772995000
    },
    {
        "time": 1689772996000,
        "value": 139.492,
        "_internal_originalTime": 1689772996000
    },
    {
        "time": 1689772997000,
        "value": 139.496,
        "_internal_originalTime": 1689772997000
    },
    {
        "time": 1689772998000,
        "value": 139.499,
        "_internal_originalTime": 1689772998000
    },
    {
        "time": 1689772999000,
        "value": 139.497,
        "_internal_originalTime": 1689772999000
    },
    {
        "time": 1689773000000,
        "value": 139.5,
        "_internal_originalTime": 1689773000000
    },
    {
        "time": 1689773002000,
        "value": 139.506,
        "_internal_originalTime": 1689773002000
    },
    {
        "time": 1689773003000,
        "value": 139.509,
        "_internal_originalTime": 1689773003000
    },
    {
        "time": 1689773005000,
        "value": 139.505,
        "_internal_originalTime": 1689773005000
    },
    {
        "time": 1689773006000,
        "value": 139.52,
        "_internal_originalTime": 1689773006000
    },
    {
        "time": 1689773007000,
        "value": 139.522,
        "_internal_originalTime": 1689773007000
    },
    {
        "time": 1689773008000,
        "value": 139.524,
        "_internal_originalTime": 1689773008000
    },
    {
        "time": 1689773009000,
        "value": 139.523,
        "_internal_originalTime": 1689773009000
    },
    {
        "time": 1689773010000,
        "value": 139.518,
        "_internal_originalTime": 1689773010000
    },
    {
        "time": 1689773011000,
        "value": 139.52,
        "_internal_originalTime": 1689773011000
    },
    {
        "time": 1689773012000,
        "value": 139.522,
        "_internal_originalTime": 1689773012000
    },
    {
        "time": 1689773013000,
        "value": 139.524,
        "_internal_originalTime": 1689773013000
    },
    {
        "time": 1689773014000,
        "value": 139.523,
        "_internal_originalTime": 1689773014000
    },
    {
        "time": 1689773016000,
        "value": 139.521,
        "_internal_originalTime": 1689773016000
    },
    {
        "time": 1689773017000,
        "value": 139.527,
        "_internal_originalTime": 1689773017000
    },
    {
        "time": 1689773018000,
        "value": 139.516,
        "_internal_originalTime": 1689773018000
    },
    {
        "time": 1689773020000,
        "value": 139.527,
        "_internal_originalTime": 1689773020000
    },
    {
        "time": 1689773021000,
        "value": 139.531,
        "_internal_originalTime": 1689773021000
    },
    {
        "time": 1689773022000,
        "value": 139.525,
        "_internal_originalTime": 1689773022000
    },
    {
        "time": 1689773023000,
        "value": 139.528,
        "_internal_originalTime": 1689773023000
    },
    {
        "time": 1689773024000,
        "value": 139.534,
        "_internal_originalTime": 1689773024000
    },
    {
        "time": 1689773025000,
        "value": 139.535,
        "_internal_originalTime": 1689773025000
    },
    {
        "time": 1689773026000,
        "value": 139.535,
        "_internal_originalTime": 1689773026000
    },
    {
        "time": 1689773027000,
        "value": 139.527,
        "_internal_originalTime": 1689773027000
    },
    {
        "time": 1689773029000,
        "value": 139.528,
        "_internal_originalTime": 1689773029000
    },
    {
        "time": 1689773030000,
        "value": 139.525,
        "_internal_originalTime": 1689773030000
    },
    {
        "time": 1689773031000,
        "value": 139.528,
        "_internal_originalTime": 1689773031000
    },
    {
        "time": 1689773034000,
        "value": 139.543,
        "_internal_originalTime": 1689773034000
    },
    {
        "time": 1689773035000,
        "value": 139.54,
        "_internal_originalTime": 1689773035000
    },
    {
        "time": 1689773036000,
        "value": 139.554,
        "_internal_originalTime": 1689773036000
    },
    {
        "time": 1689773038000,
        "value": 139.551,
        "_internal_originalTime": 1689773038000
    },
    {
        "time": 1689773040000,
        "value": 139.556,
        "_internal_originalTime": 1689773040000
    },
    {
        "time": 1689773041000,
        "value": 139.553,
        "_internal_originalTime": 1689773041000
    },
    {
        "time": 1689773042000,
        "value": 139.555,
        "_internal_originalTime": 1689773042000
    },
    {
        "time": 1689773043000,
        "value": 139.556,
        "_internal_originalTime": 1689773043000
    },
    {
        "time": 1689773044000,
        "value": 139.558,
        "_internal_originalTime": 1689773044000
    },
    {
        "time": 1689773045000,
        "value": 139.564,
        "_internal_originalTime": 1689773045000
    },
    {
        "time": 1689773046000,
        "value": 139.565,
        "_internal_originalTime": 1689773046000
    },
    {
        "time": 1689773048000,
        "value": 139.564,
        "_internal_originalTime": 1689773048000
    },
    {
        "time": 1689773049000,
        "value": 139.567,
        "_internal_originalTime": 1689773049000
    },
    {
        "time": 1689773050000,
        "value": 139.574,
        "_internal_originalTime": 1689773050000
    },
    {
        "time": 1689773052000,
        "value": 139.592,
        "_internal_originalTime": 1689773052000
    },
    {
        "time": 1689773053000,
        "value": 139.605,
        "_internal_originalTime": 1689773053000
    },
    {
        "time": 1689773055000,
        "value": 139.606,
        "_internal_originalTime": 1689773055000
    },
    {
        "time": 1689773056000,
        "value": 139.604,
        "_internal_originalTime": 1689773056000
    },
    {
        "time": 1689773057000,
        "value": 139.6,
        "_internal_originalTime": 1689773057000
    },
    {
        "time": 1689773058000,
        "value": 139.603,
        "_internal_originalTime": 1689773058000
    },
    {
        "time": 1689773059000,
        "value": 139.607,
        "_internal_originalTime": 1689773059000
    },
    {
        "time": 1689773060000,
        "value": 139.6,
        "_internal_originalTime": 1689773060000
    },
    {
        "time": 1689773061000,
        "value": 139.603,
        "_internal_originalTime": 1689773061000
    },
    {
        "time": 1689773062000,
        "value": 139.596,
        "_internal_originalTime": 1689773062000
    },
    {
        "time": 1689773064000,
        "value": 139.599,
        "_internal_originalTime": 1689773064000
    },
    {
        "time": 1689773065000,
        "value": 139.6,
        "_internal_originalTime": 1689773065000
    },
    {
        "time": 1689773066000,
        "value": 139.6,
        "_internal_originalTime": 1689773066000
    },
    {
        "time": 1689773067000,
        "value": 139.593,
        "_internal_originalTime": 1689773067000
    },
    {
        "time": 1689773068000,
        "value": 139.584,
        "_internal_originalTime": 1689773068000
    },
    {
        "time": 1689773070000,
        "value": 139.591,
        "_internal_originalTime": 1689773070000
    },
    {
        "time": 1689773071000,
        "value": 139.592,
        "_internal_originalTime": 1689773071000
    },
    {
        "time": 1689773072000,
        "value": 139.593,
        "_internal_originalTime": 1689773072000
    },
    {
        "time": 1689773073000,
        "value": 139.593,
        "_internal_originalTime": 1689773073000
    },
    {
        "time": 1689773074000,
        "value": 139.592,
        "_internal_originalTime": 1689773074000
    },
    {
        "time": 1689773075000,
        "value": 139.595,
        "_internal_originalTime": 1689773075000
    },
    {
        "time": 1689773076000,
        "value": 139.583,
        "_internal_originalTime": 1689773076000
    },
    {
        "time": 1689773077000,
        "value": 139.583,
        "_internal_originalTime": 1689773077000
    },
    {
        "time": 1689773078000,
        "value": 139.589,
        "_internal_originalTime": 1689773078000
    },
    {
        "time": 1689773080000,
        "value": 139.594,
        "_internal_originalTime": 1689773080000
    },
    {
        "time": 1689773081000,
        "value": 139.567,
        "_internal_originalTime": 1689773081000
    },
    {
        "time": 1689773082000,
        "value": 139.567,
        "_internal_originalTime": 1689773082000
    },
    {
        "time": 1689773083000,
        "value": 139.568,
        "_internal_originalTime": 1689773083000
    },
    {
        "time": 1689773085000,
        "value": 139.571,
        "_internal_originalTime": 1689773085000
    },
    {
        "time": 1689773086000,
        "value": 139.569,
        "_internal_originalTime": 1689773086000
    },
    {
        "time": 1689773087000,
        "value": 139.567,
        "_internal_originalTime": 1689773087000
    },
    {
        "time": 1689773088000,
        "value": 139.57,
        "_internal_originalTime": 1689773088000
    },
    {
        "time": 1689773090000,
        "value": 139.573,
        "_internal_originalTime": 1689773090000
    },
    {
        "time": 1689773091000,
        "value": 139.582,
        "_internal_originalTime": 1689773091000
    },
    {
        "time": 1689773092000,
        "value": 139.586,
        "_internal_originalTime": 1689773092000
    },
    {
        "time": 1689773093000,
        "value": 139.59,
        "_internal_originalTime": 1689773093000
    },
    {
        "time": 1689773094000,
        "value": 139.594,
        "_internal_originalTime": 1689773094000
    },
    {
        "time": 1689773095000,
        "value": 139.593,
        "_internal_originalTime": 1689773095000
    },
    {
        "time": 1689773097000,
        "value": 139.592,
        "_internal_originalTime": 1689773097000
    },
    {
        "time": 1689773098000,
        "value": 139.589,
        "_internal_originalTime": 1689773098000
    },
    {
        "time": 1689773100000,
        "value": 139.587,
        "_internal_originalTime": 1689773100000
    },
    {
        "time": 1689773101000,
        "value": 139.59,
        "_internal_originalTime": 1689773101000
    },
    {
        "time": 1689773102000,
        "value": 139.59,
        "_internal_originalTime": 1689773102000
    },
    {
        "time": 1689773103000,
        "value": 139.595,
        "_internal_originalTime": 1689773103000
    },
    {
        "time": 1689773104000,
        "value": 139.59,
        "_internal_originalTime": 1689773104000
    },
    {
        "time": 1689773105000,
        "value": 139.591,
        "_internal_originalTime": 1689773105000
    },
    {
        "time": 1689773106000,
        "value": 139.592,
        "_internal_originalTime": 1689773106000
    },
    {
        "time": 1689773108000,
        "value": 139.596,
        "_internal_originalTime": 1689773108000
    },
    {
        "time": 1689773110000,
        "value": 139.592,
        "_internal_originalTime": 1689773110000
    },
    {
        "time": 1689773111000,
        "value": 139.59,
        "_internal_originalTime": 1689773111000
    },
    {
        "time": 1689773112000,
        "value": 139.59,
        "_internal_originalTime": 1689773112000
    },
    {
        "time": 1689773115000,
        "value": 139.588,
        "_internal_originalTime": 1689773115000
    },
    {
        "time": 1689773117000,
        "value": 139.59,
        "_internal_originalTime": 1689773117000
    },
    {
        "time": 1689773118000,
        "value": 139.59,
        "_internal_originalTime": 1689773118000
    },
    {
        "time": 1689773119000,
        "value": 139.59,
        "_internal_originalTime": 1689773119000
    },
    {
        "time": 1689773120000,
        "value": 139.589,
        "_internal_originalTime": 1689773120000
    },
    {
        "time": 1689773121000,
        "value": 139.589,
        "_internal_originalTime": 1689773121000
    },
    {
        "time": 1689773123000,
        "value": 139.59,
        "_internal_originalTime": 1689773123000
    },
    {
        "time": 1689773124000,
        "value": 139.591,
        "_internal_originalTime": 1689773124000
    },
    {
        "time": 1689773125000,
        "value": 139.592,
        "_internal_originalTime": 1689773125000
    },
    {
        "time": 1689773127000,
        "value": 139.599,
        "_internal_originalTime": 1689773127000
    },
    {
        "time": 1689773129000,
        "value": 139.599,
        "_internal_originalTime": 1689773129000
    },
    {
        "time": 1689773130000,
        "value": 139.597,
        "_internal_originalTime": 1689773130000
    },
    {
        "time": 1689773131000,
        "value": 139.595,
        "_internal_originalTime": 1689773131000
    },
    {
        "time": 1689773132000,
        "value": 139.584,
        "_internal_originalTime": 1689773132000
    },
    {
        "time": 1689773134000,
        "value": 139.587,
        "_internal_originalTime": 1689773134000
    },
    {
        "time": 1689773135000,
        "value": 139.589,
        "_internal_originalTime": 1689773135000
    },
    {
        "time": 1689773136000,
        "value": 139.593,
        "_internal_originalTime": 1689773136000
    },
    {
        "time": 1689773137000,
        "value": 139.592,
        "_internal_originalTime": 1689773137000
    },
    {
        "time": 1689773138000,
        "value": 139.589,
        "_internal_originalTime": 1689773138000
    },
    {
        "time": 1689773139000,
        "value": 139.591,
        "_internal_originalTime": 1689773139000
    },
    {
        "time": 1689773140000,
        "value": 139.586,
        "_internal_originalTime": 1689773140000
    },
    {
        "time": 1689773141000,
        "value": 139.584,
        "_internal_originalTime": 1689773141000
    },
    {
        "time": 1689773143000,
        "value": 139.592,
        "_internal_originalTime": 1689773143000
    },
    {
        "time": 1689773145000,
        "value": 139.59,
        "_internal_originalTime": 1689773145000
    },
    {
        "time": 1689773146000,
        "value": 139.589,
        "_internal_originalTime": 1689773146000
    },
    {
        "time": 1689773147000,
        "value": 139.591,
        "_internal_originalTime": 1689773147000
    },
    {
        "time": 1689773149000,
        "value": 139.586,
        "_internal_originalTime": 1689773149000
    },
    {
        "time": 1689773150000,
        "value": 139.589,
        "_internal_originalTime": 1689773150000
    },
    {
        "time": 1689773151000,
        "value": 139.59,
        "_internal_originalTime": 1689773151000
    },
    {
        "time": 1689773153000,
        "value": 139.588,
        "_internal_originalTime": 1689773153000
    },
    {
        "time": 1689773154000,
        "value": 139.592,
        "_internal_originalTime": 1689773154000
    },
    {
        "time": 1689773155000,
        "value": 139.588,
        "_internal_originalTime": 1689773155000
    },
    {
        "time": 1689773156000,
        "value": 139.588,
        "_internal_originalTime": 1689773156000
    },
    {
        "time": 1689773158000,
        "value": 139.6,
        "_internal_originalTime": 1689773158000
    },
    {
        "time": 1689773159000,
        "value": 139.604,
        "_internal_originalTime": 1689773159000
    },
    {
        "time": 1689773160000,
        "value": 139.601,
        "_internal_originalTime": 1689773160000
    },
    {
        "time": 1689773161000,
        "value": 139.603,
        "_internal_originalTime": 1689773161000
    },
    {
        "time": 1689773163000,
        "value": 139.603,
        "_internal_originalTime": 1689773163000
    },
    {
        "time": 1689773164000,
        "value": 139.604,
        "_internal_originalTime": 1689773164000
    },
    {
        "time": 1689773165000,
        "value": 139.602,
        "_internal_originalTime": 1689773165000
    },
    {
        "time": 1689773167000,
        "value": 139.602,
        "_internal_originalTime": 1689773167000
    },
    {
        "time": 1689773168000,
        "value": 139.599,
        "_internal_originalTime": 1689773168000
    },
    {
        "time": 1689773170000,
        "value": 139.604,
        "_internal_originalTime": 1689773170000
    },
    {
        "time": 1689773171000,
        "value": 139.602,
        "_internal_originalTime": 1689773171000
    },
    {
        "time": 1689773172000,
        "value": 139.603,
        "_internal_originalTime": 1689773172000
    },
    {
        "time": 1689773173000,
        "value": 139.601,
        "_internal_originalTime": 1689773173000
    },
    {
        "time": 1689773175000,
        "value": 139.601,
        "_internal_originalTime": 1689773175000
    },
    {
        "time": 1689773176000,
        "value": 139.599,
        "_internal_originalTime": 1689773176000
    },
    {
        "time": 1689773177000,
        "value": 139.605,
        "_internal_originalTime": 1689773177000
    },
    {
        "time": 1689773178000,
        "value": 139.604,
        "_internal_originalTime": 1689773178000
    },
    {
        "time": 1689773179000,
        "value": 139.607,
        "_internal_originalTime": 1689773179000
    },
    {
        "time": 1689773180000,
        "value": 139.612,
        "_internal_originalTime": 1689773180000
    },
    {
        "time": 1689773181000,
        "value": 139.612,
        "_internal_originalTime": 1689773181000
    },
    {
        "time": 1689773182000,
        "value": 139.62,
        "_internal_originalTime": 1689773182000
    },
    {
        "time": 1689773183000,
        "value": 139.623,
        "_internal_originalTime": 1689773183000
    },
    {
        "time": 1689773184000,
        "value": 139.621,
        "_internal_originalTime": 1689773184000
    },
    {
        "time": 1689773185000,
        "value": 139.623,
        "_internal_originalTime": 1689773185000
    },
    {
        "time": 1689773187000,
        "value": 139.62,
        "_internal_originalTime": 1689773187000
    },
    {
        "time": 1689773188000,
        "value": 139.623,
        "_internal_originalTime": 1689773188000
    },
    {
        "time": 1689773190000,
        "value": 139.626,
        "_internal_originalTime": 1689773190000
    },
    {
        "time": 1689773191000,
        "value": 139.634,
        "_internal_originalTime": 1689773191000
    },
    {
        "time": 1689773192000,
        "value": 139.64,
        "_internal_originalTime": 1689773192000
    },
    {
        "time": 1689773193000,
        "value": 139.636,
        "_internal_originalTime": 1689773193000
    },
    {
        "time": 1689773194000,
        "value": 139.638,
        "_internal_originalTime": 1689773194000
    },
    {
        "time": 1689773195000,
        "value": 139.637,
        "_internal_originalTime": 1689773195000
    },
    {
        "time": 1689773196000,
        "value": 139.637,
        "_internal_originalTime": 1689773196000
    },
    {
        "time": 1689773198000,
        "value": 139.635,
        "_internal_originalTime": 1689773198000
    },
    {
        "time": 1689773200000,
        "value": 139.637,
        "_internal_originalTime": 1689773200000
    },
    {
        "time": 1689773201000,
        "value": 139.637,
        "_internal_originalTime": 1689773201000
    },
    {
        "time": 1689773202000,
        "value": 139.634,
        "_internal_originalTime": 1689773202000
    },
    {
        "time": 1689773203000,
        "value": 139.634,
        "_internal_originalTime": 1689773203000
    },
    {
        "time": 1689773205000,
        "value": 139.634,
        "_internal_originalTime": 1689773205000
    },
    {
        "time": 1689773206000,
        "value": 139.633,
        "_internal_originalTime": 1689773206000
    },
    {
        "time": 1689773208000,
        "value": 139.632,
        "_internal_originalTime": 1689773208000
    },
    {
        "time": 1689773209000,
        "value": 139.633,
        "_internal_originalTime": 1689773209000
    },
    {
        "time": 1689773210000,
        "value": 139.635,
        "_internal_originalTime": 1689773210000
    },
    {
        "time": 1689773212000,
        "value": 139.638,
        "_internal_originalTime": 1689773212000
    },
    {
        "time": 1689773213000,
        "value": 139.632,
        "_internal_originalTime": 1689773213000
    },
    {
        "time": 1689773215000,
        "value": 139.634,
        "_internal_originalTime": 1689773215000
    },
    {
        "time": 1689773216000,
        "value": 139.634,
        "_internal_originalTime": 1689773216000
    },
    {
        "time": 1689773217000,
        "value": 139.631,
        "_internal_originalTime": 1689773217000
    },
    {
        "time": 1689773220000,
        "value": 139.633,
        "_internal_originalTime": 1689773220000
    },
    {
        "time": 1689773221000,
        "value": 139.635,
        "_internal_originalTime": 1689773221000
    },
    {
        "time": 1689773222000,
        "value": 139.634,
        "_internal_originalTime": 1689773222000
    },
    {
        "time": 1689773223000,
        "value": 139.629,
        "_internal_originalTime": 1689773223000
    },
    {
        "time": 1689773224000,
        "value": 139.63,
        "_internal_originalTime": 1689773224000
    },
    {
        "time": 1689773225000,
        "value": 139.635,
        "_internal_originalTime": 1689773225000
    },
    {
        "time": 1689773226000,
        "value": 139.632,
        "_internal_originalTime": 1689773226000
    },
    {
        "time": 1689773227000,
        "value": 139.634,
        "_internal_originalTime": 1689773227000
    },
    {
        "time": 1689773229000,
        "value": 139.629,
        "_internal_originalTime": 1689773229000
    },
    {
        "time": 1689773230000,
        "value": 139.626,
        "_internal_originalTime": 1689773230000
    },
    {
        "time": 1689773231000,
        "value": 139.623,
        "_internal_originalTime": 1689773231000
    },
    {
        "time": 1689773233000,
        "value": 139.619,
        "_internal_originalTime": 1689773233000
    },
    {
        "time": 1689773235000,
        "value": 139.617,
        "_internal_originalTime": 1689773235000
    },
    {
        "time": 1689773237000,
        "value": 139.619,
        "_internal_originalTime": 1689773237000
    },
    {
        "time": 1689773238000,
        "value": 139.621,
        "_internal_originalTime": 1689773238000
    },
    {
        "time": 1689773240000,
        "value": 139.618,
        "_internal_originalTime": 1689773240000
    },
    {
        "time": 1689773242000,
        "value": 139.614,
        "_internal_originalTime": 1689773242000
    },
    {
        "time": 1689773243000,
        "value": 139.616,
        "_internal_originalTime": 1689773243000
    },
    {
        "time": 1689773244000,
        "value": 139.615,
        "_internal_originalTime": 1689773244000
    },
    {
        "time": 1689773246000,
        "value": 139.61,
        "_internal_originalTime": 1689773246000
    },
    {
        "time": 1689773247000,
        "value": 139.606,
        "_internal_originalTime": 1689773247000
    },
    {
        "time": 1689773248000,
        "value": 139.608,
        "_internal_originalTime": 1689773248000
    },
    {
        "time": 1689773250000,
        "value": 139.61,
        "_internal_originalTime": 1689773250000
    },
    {
        "time": 1689773251000,
        "value": 139.617,
        "_internal_originalTime": 1689773251000
    },
    {
        "time": 1689773252000,
        "value": 139.614,
        "_internal_originalTime": 1689773252000
    },
    {
        "time": 1689773253000,
        "value": 139.63,
        "_internal_originalTime": 1689773253000
    },
    {
        "time": 1689773255000,
        "value": 139.627,
        "_internal_originalTime": 1689773255000
    },
    {
        "time": 1689773256000,
        "value": 139.628,
        "_internal_originalTime": 1689773256000
    },
    {
        "time": 1689773257000,
        "value": 139.628,
        "_internal_originalTime": 1689773257000
    },
    {
        "time": 1689773258000,
        "value": 139.618,
        "_internal_originalTime": 1689773258000
    },
    {
        "time": 1689773259000,
        "value": 139.61,
        "_internal_originalTime": 1689773259000
    },
    {
        "time": 1689773261000,
        "value": 139.61,
        "_internal_originalTime": 1689773261000
    },
    {
        "time": 1689773262000,
        "value": 139.613,
        "_internal_originalTime": 1689773262000
    },
    {
        "time": 1689773263000,
        "value": 139.611,
        "_internal_originalTime": 1689773263000
    },
    {
        "time": 1689773265000,
        "value": 139.609,
        "_internal_originalTime": 1689773265000
    },
    {
        "time": 1689773267000,
        "value": 139.611,
        "_internal_originalTime": 1689773267000
    },
    {
        "time": 1689773268000,
        "value": 139.615,
        "_internal_originalTime": 1689773268000
    },
    {
        "time": 1689773269000,
        "value": 139.615,
        "_internal_originalTime": 1689773269000
    },
    {
        "time": 1689773270000,
        "value": 139.618,
        "_internal_originalTime": 1689773270000
    },
    {
        "time": 1689773271000,
        "value": 139.606,
        "_internal_originalTime": 1689773271000
    },
    {
        "time": 1689773273000,
        "value": 139.608,
        "_internal_originalTime": 1689773273000
    },
    {
        "time": 1689773274000,
        "value": 139.601,
        "_internal_originalTime": 1689773274000
    },
    {
        "time": 1689773275000,
        "value": 139.599,
        "_internal_originalTime": 1689773275000
    },
    {
        "time": 1689773276000,
        "value": 139.6,
        "_internal_originalTime": 1689773276000
    },
    {
        "time": 1689773278000,
        "value": 139.606,
        "_internal_originalTime": 1689773278000
    },
    {
        "time": 1689773280000,
        "value": 139.606,
        "_internal_originalTime": 1689773280000
    },
    {
        "time": 1689773281000,
        "value": 139.61,
        "_internal_originalTime": 1689773281000
    },
    {
        "time": 1689773282000,
        "value": 139.609,
        "_internal_originalTime": 1689773282000
    },
    {
        "time": 1689773284000,
        "value": 139.608,
        "_internal_originalTime": 1689773284000
    },
    {
        "time": 1689773285000,
        "value": 139.609,
        "_internal_originalTime": 1689773285000
    },
    {
        "time": 1689773286000,
        "value": 139.614,
        "_internal_originalTime": 1689773286000
    },
    {
        "time": 1689773287000,
        "value": 139.612,
        "_internal_originalTime": 1689773287000
    },
    {
        "time": 1689773288000,
        "value": 139.606,
        "_internal_originalTime": 1689773288000
    },
    {
        "time": 1689773290000,
        "value": 139.606,
        "_internal_originalTime": 1689773290000
    },
    {
        "time": 1689773291000,
        "value": 139.602,
        "_internal_originalTime": 1689773291000
    },
    {
        "time": 1689773292000,
        "value": 139.606,
        "_internal_originalTime": 1689773292000
    },
    {
        "time": 1689773293000,
        "value": 139.598,
        "_internal_originalTime": 1689773293000
    },
    {
        "time": 1689773295000,
        "value": 139.599,
        "_internal_originalTime": 1689773295000
    },
    {
        "time": 1689773296000,
        "value": 139.596,
        "_internal_originalTime": 1689773296000
    },
    {
        "time": 1689773297000,
        "value": 139.599,
        "_internal_originalTime": 1689773297000
    },
    {
        "time": 1689773299000,
        "value": 139.595,
        "_internal_originalTime": 1689773299000
    },
    {
        "time": 1689773300000,
        "value": 139.597,
        "_internal_originalTime": 1689773300000
    },
    {
        "time": 1689773301000,
        "value": 139.597,
        "_internal_originalTime": 1689773301000
    },
    {
        "time": 1689773303000,
        "value": 139.599,
        "_internal_originalTime": 1689773303000
    },
    {
        "time": 1689773304000,
        "value": 139.6,
        "_internal_originalTime": 1689773304000
    },
    {
        "time": 1689773305000,
        "value": 139.596,
        "_internal_originalTime": 1689773305000
    },
    {
        "time": 1689773307000,
        "value": 139.592,
        "_internal_originalTime": 1689773307000
    },
    {
        "time": 1689773308000,
        "value": 139.594,
        "_internal_originalTime": 1689773308000
    },
    {
        "time": 1689773310000,
        "value": 139.591,
        "_internal_originalTime": 1689773310000
    },
    {
        "time": 1689773311000,
        "value": 139.594,
        "_internal_originalTime": 1689773311000
    },
    {
        "time": 1689773313000,
        "value": 139.588,
        "_internal_originalTime": 1689773313000
    },
    {
        "time": 1689773314000,
        "value": 139.586,
        "_internal_originalTime": 1689773314000
    },
    {
        "time": 1689773315000,
        "value": 139.584,
        "_internal_originalTime": 1689773315000
    },
    {
        "time": 1689773316000,
        "value": 139.59,
        "_internal_originalTime": 1689773316000
    },
    {
        "time": 1689773318000,
        "value": 139.588,
        "_internal_originalTime": 1689773318000
    },
    {
        "time": 1689773319000,
        "value": 139.582,
        "_internal_originalTime": 1689773319000
    },
    {
        "time": 1689773320000,
        "value": 139.574,
        "_internal_originalTime": 1689773320000
    },
    {
        "time": 1689773321000,
        "value": 139.574,
        "_internal_originalTime": 1689773321000
    },
    {
        "time": 1689773322000,
        "value": 139.577,
        "_internal_originalTime": 1689773322000
    },
    {
        "time": 1689773325000,
        "value": 139.602,
        "_internal_originalTime": 1689773325000
    },
    {
        "time": 1689773326000,
        "value": 139.6,
        "_internal_originalTime": 1689773326000
    },
    {
        "time": 1689773327000,
        "value": 139.596,
        "_internal_originalTime": 1689773327000
    },
    {
        "time": 1689773329000,
        "value": 139.6,
        "_internal_originalTime": 1689773329000
    },
    {
        "time": 1689773331000,
        "value": 139.597,
        "_internal_originalTime": 1689773331000
    },
    {
        "time": 1689773332000,
        "value": 139.608,
        "_internal_originalTime": 1689773332000
    },
    {
        "time": 1689773333000,
        "value": 139.61,
        "_internal_originalTime": 1689773333000
    },
    {
        "time": 1689773334000,
        "value": 139.606,
        "_internal_originalTime": 1689773334000
    },
    {
        "time": 1689773336000,
        "value": 139.613,
        "_internal_originalTime": 1689773336000
    },
    {
        "time": 1689773337000,
        "value": 139.613,
        "_internal_originalTime": 1689773337000
    },
    {
        "time": 1689773338000,
        "value": 139.614,
        "_internal_originalTime": 1689773338000
    },
    {
        "time": 1689773340000,
        "value": 139.616,
        "_internal_originalTime": 1689773340000
    },
    {
        "time": 1689773341000,
        "value": 139.618,
        "_internal_originalTime": 1689773341000
    },
    {
        "time": 1689773343000,
        "value": 139.614,
        "_internal_originalTime": 1689773343000
    },
    {
        "time": 1689773344000,
        "value": 139.613,
        "_internal_originalTime": 1689773344000
    },
    {
        "time": 1689773346000,
        "value": 139.621,
        "_internal_originalTime": 1689773346000
    },
    {
        "time": 1689773347000,
        "value": 139.621,
        "_internal_originalTime": 1689773347000
    },
    {
        "time": 1689773348000,
        "value": 139.621,
        "_internal_originalTime": 1689773348000
    },
    {
        "time": 1689773349000,
        "value": 139.616,
        "_internal_originalTime": 1689773349000
    },
    {
        "time": 1689773350000,
        "value": 139.615,
        "_internal_originalTime": 1689773350000
    },
    {
        "time": 1689773352000,
        "value": 139.622,
        "_internal_originalTime": 1689773352000
    },
    {
        "time": 1689773353000,
        "value": 139.63,
        "_internal_originalTime": 1689773353000
    },
    {
        "time": 1689773355000,
        "value": 139.628,
        "_internal_originalTime": 1689773355000
    },
    {
        "time": 1689773356000,
        "value": 139.625,
        "_internal_originalTime": 1689773356000
    },
    {
        "time": 1689773357000,
        "value": 139.631,
        "_internal_originalTime": 1689773357000
    },
    {
        "time": 1689773358000,
        "value": 139.646,
        "_internal_originalTime": 1689773358000
    },
    {
        "time": 1689773359000,
        "value": 139.649,
        "_internal_originalTime": 1689773359000
    },
    {
        "time": 1689773360000,
        "value": 139.653,
        "_internal_originalTime": 1689773360000
    },
    {
        "time": 1689773362000,
        "value": 139.651,
        "_internal_originalTime": 1689773362000
    },
    {
        "time": 1689773363000,
        "value": 139.65,
        "_internal_originalTime": 1689773363000
    },
    {
        "time": 1689773364000,
        "value": 139.652,
        "_internal_originalTime": 1689773364000
    },
    {
        "time": 1689773366000,
        "value": 139.653,
        "_internal_originalTime": 1689773366000
    },
    {
        "time": 1689773369000,
        "value": 139.652,
        "_internal_originalTime": 1689773369000
    },
    {
        "time": 1689773370000,
        "value": 139.66,
        "_internal_originalTime": 1689773370000
    },
    {
        "time": 1689773371000,
        "value": 139.655,
        "_internal_originalTime": 1689773371000
    },
    {
        "time": 1689773372000,
        "value": 139.655,
        "_internal_originalTime": 1689773372000
    },
    {
        "time": 1689773373000,
        "value": 139.65,
        "_internal_originalTime": 1689773373000
    },
    {
        "time": 1689773375000,
        "value": 139.649,
        "_internal_originalTime": 1689773375000
    },
    {
        "time": 1689773376000,
        "value": 139.646,
        "_internal_originalTime": 1689773376000
    },
    {
        "time": 1689773377000,
        "value": 139.651,
        "_internal_originalTime": 1689773377000
    },
    {
        "time": 1689773378000,
        "value": 139.653,
        "_internal_originalTime": 1689773378000
    },
    {
        "time": 1689773379000,
        "value": 139.655,
        "_internal_originalTime": 1689773379000
    },
    {
        "time": 1689773380000,
        "value": 139.654,
        "_internal_originalTime": 1689773380000
    },
    {
        "time": 1689773381000,
        "value": 139.655,
        "_internal_originalTime": 1689773381000
    },
    {
        "time": 1689773382000,
        "value": 139.653,
        "_internal_originalTime": 1689773382000
    },
    {
        "time": 1689773383000,
        "value": 139.653,
        "_internal_originalTime": 1689773383000
    },
    {
        "time": 1689773385000,
        "value": 139.654,
        "_internal_originalTime": 1689773385000
    },
    {
        "time": 1689773386000,
        "value": 139.652,
        "_internal_originalTime": 1689773386000
    },
    {
        "time": 1689773387000,
        "value": 139.651,
        "_internal_originalTime": 1689773387000
    },
    {
        "time": 1689773388000,
        "value": 139.652,
        "_internal_originalTime": 1689773388000
    },
    {
        "time": 1689773390000,
        "value": 139.65,
        "_internal_originalTime": 1689773390000
    },
    {
        "time": 1689773391000,
        "value": 139.652,
        "_internal_originalTime": 1689773391000
    },
    {
        "time": 1689773392000,
        "value": 139.647,
        "_internal_originalTime": 1689773392000
    },
    {
        "time": 1689773393000,
        "value": 139.649,
        "_internal_originalTime": 1689773393000
    },
    {
        "time": 1689773394000,
        "value": 139.648,
        "_internal_originalTime": 1689773394000
    },
    {
        "time": 1689773395000,
        "value": 139.652,
        "_internal_originalTime": 1689773395000
    },
    {
        "time": 1689773396000,
        "value": 139.651,
        "_internal_originalTime": 1689773396000
    },
    {
        "time": 1689773398000,
        "value": 139.66,
        "_internal_originalTime": 1689773398000
    },
    {
        "time": 1689773399000,
        "value": 139.663,
        "_internal_originalTime": 1689773399000
    },
    {
        "time": 1689773400000,
        "value": 139.666,
        "_internal_originalTime": 1689773400000
    },
    {
        "time": 1689773401000,
        "value": 139.668,
        "_internal_originalTime": 1689773401000
    },
    {
        "time": 1689773402000,
        "value": 139.666,
        "_internal_originalTime": 1689773402000
    },
    {
        "time": 1689773403000,
        "value": 139.671,
        "_internal_originalTime": 1689773403000
    },
    {
        "time": 1689773404000,
        "value": 139.67,
        "_internal_originalTime": 1689773404000
    },
    {
        "time": 1689773405000,
        "value": 139.672,
        "_internal_originalTime": 1689773405000
    },
    {
        "time": 1689773406000,
        "value": 139.67,
        "_internal_originalTime": 1689773406000
    },
    {
        "time": 1689773407000,
        "value": 139.672,
        "_internal_originalTime": 1689773407000
    },
    {
        "time": 1689773408000,
        "value": 139.67,
        "_internal_originalTime": 1689773408000
    },
    {
        "time": 1689773409000,
        "value": 139.675,
        "_internal_originalTime": 1689773409000
    },
    {
        "time": 1689773410000,
        "value": 139.681,
        "_internal_originalTime": 1689773410000
    },
    {
        "time": 1689773411000,
        "value": 139.679,
        "_internal_originalTime": 1689773411000
    },
    {
        "time": 1689773412000,
        "value": 139.672,
        "_internal_originalTime": 1689773412000
    },
    {
        "time": 1689773416000,
        "value": 139.676,
        "_internal_originalTime": 1689773416000
    },
    {
        "time": 1689773417000,
        "value": 139.675,
        "_internal_originalTime": 1689773417000
    },
    {
        "time": 1689773418000,
        "value": 139.675,
        "_internal_originalTime": 1689773418000
    },
    {
        "time": 1689773419000,
        "value": 139.673,
        "_internal_originalTime": 1689773419000
    },
    {
        "time": 1689773421000,
        "value": 139.674,
        "_internal_originalTime": 1689773421000
    },
    {
        "time": 1689773422000,
        "value": 139.676,
        "_internal_originalTime": 1689773422000
    },
    {
        "time": 1689773423000,
        "value": 139.681,
        "_internal_originalTime": 1689773423000
    },
    {
        "time": 1689773424000,
        "value": 139.685,
        "_internal_originalTime": 1689773424000
    },
    {
        "time": 1689773425000,
        "value": 139.685,
        "_internal_originalTime": 1689773425000
    },
    {
        "time": 1689773426000,
        "value": 139.684,
        "_internal_originalTime": 1689773426000
    },
    {
        "time": 1689773427000,
        "value": 139.687,
        "_internal_originalTime": 1689773427000
    },
    {
        "time": 1689773428000,
        "value": 139.685,
        "_internal_originalTime": 1689773428000
    },
    {
        "time": 1689773429000,
        "value": 139.692,
        "_internal_originalTime": 1689773429000
    },
    {
        "time": 1689773430000,
        "value": 139.697,
        "_internal_originalTime": 1689773430000
    },
    {
        "time": 1689773431000,
        "value": 139.694,
        "_internal_originalTime": 1689773431000
    },
    {
        "time": 1689773432000,
        "value": 139.693,
        "_internal_originalTime": 1689773432000
    },
    {
        "time": 1689773433000,
        "value": 139.695,
        "_internal_originalTime": 1689773433000
    },
    {
        "time": 1689773434000,
        "value": 139.69,
        "_internal_originalTime": 1689773434000
    },
    {
        "time": 1689773435000,
        "value": 139.69,
        "_internal_originalTime": 1689773435000
    },
    {
        "time": 1689773436000,
        "value": 139.689,
        "_internal_originalTime": 1689773436000
    },
    {
        "time": 1689773438000,
        "value": 139.684,
        "_internal_originalTime": 1689773438000
    },
    {
        "time": 1689773440000,
        "value": 139.685,
        "_internal_originalTime": 1689773440000
    },
    {
        "time": 1689773441000,
        "value": 139.683,
        "_internal_originalTime": 1689773441000
    },
    {
        "time": 1689773442000,
        "value": 139.685,
        "_internal_originalTime": 1689773442000
    },
    {
        "time": 1689773444000,
        "value": 139.686,
        "_internal_originalTime": 1689773444000
    },
    {
        "time": 1689773445000,
        "value": 139.688,
        "_internal_originalTime": 1689773445000
    },
    {
        "time": 1689773446000,
        "value": 139.691,
        "_internal_originalTime": 1689773446000
    },
    {
        "time": 1689773447000,
        "value": 139.692,
        "_internal_originalTime": 1689773447000
    },
    {
        "time": 1689773453000,
        "value": 139.675,
        "_internal_originalTime": 1689773453000
    },
    {
        "time": 1689773454000,
        "value": 139.679,
        "_internal_originalTime": 1689773454000
    },
    {
        "time": 1689773455000,
        "value": 139.675,
        "_internal_originalTime": 1689773455000
    },
    {
        "time": 1689773457000,
        "value": 139.666,
        "_internal_originalTime": 1689773457000
    },
    {
        "time": 1689773458000,
        "value": 139.652,
        "_internal_originalTime": 1689773458000
    },
    {
        "time": 1689773459000,
        "value": 139.643,
        "_internal_originalTime": 1689773459000
    },
    {
        "time": 1689773460000,
        "value": 139.637,
        "_internal_originalTime": 1689773460000
    },
    {
        "time": 1689773461000,
        "value": 139.632,
        "_internal_originalTime": 1689773461000
    },
    {
        "time": 1689773462000,
        "value": 139.637,
        "_internal_originalTime": 1689773462000
    },
    {
        "time": 1689773463000,
        "value": 139.633,
        "_internal_originalTime": 1689773463000
    },
    {
        "time": 1689773464000,
        "value": 139.635,
        "_internal_originalTime": 1689773464000
    },
    {
        "time": 1689773465000,
        "value": 139.64,
        "_internal_originalTime": 1689773465000
    },
    {
        "time": 1689773466000,
        "value": 139.632,
        "_internal_originalTime": 1689773466000
    },
    {
        "time": 1689773467000,
        "value": 139.63,
        "_internal_originalTime": 1689773467000
    },
    {
        "time": 1689773468000,
        "value": 139.631,
        "_internal_originalTime": 1689773468000
    },
    {
        "time": 1689773469000,
        "value": 139.636,
        "_internal_originalTime": 1689773469000
    },
    {
        "time": 1689773470000,
        "value": 139.645,
        "_internal_originalTime": 1689773470000
    },
    {
        "time": 1689773471000,
        "value": 139.638,
        "_internal_originalTime": 1689773471000
    },
    {
        "time": 1689773472000,
        "value": 139.637,
        "_internal_originalTime": 1689773472000
    },
    {
        "time": 1689773473000,
        "value": 139.643,
        "_internal_originalTime": 1689773473000
    },
    {
        "time": 1689773474000,
        "value": 139.635,
        "_internal_originalTime": 1689773474000
    },
    {
        "time": 1689773475000,
        "value": 139.629,
        "_internal_originalTime": 1689773475000
    },
    {
        "time": 1689773476000,
        "value": 139.628,
        "_internal_originalTime": 1689773476000
    },
    {
        "time": 1689773477000,
        "value": 139.621,
        "_internal_originalTime": 1689773477000
    },
    {
        "time": 1689773478000,
        "value": 139.618,
        "_internal_originalTime": 1689773478000
    },
    {
        "time": 1689773479000,
        "value": 139.614,
        "_internal_originalTime": 1689773479000
    },
    {
        "time": 1689773480000,
        "value": 139.618,
        "_internal_originalTime": 1689773480000
    },
    {
        "time": 1689773481000,
        "value": 139.615,
        "_internal_originalTime": 1689773481000
    },
    {
        "time": 1689773482000,
        "value": 139.619,
        "_internal_originalTime": 1689773482000
    },
    {
        "time": 1689773483000,
        "value": 139.618,
        "_internal_originalTime": 1689773483000
    },
    {
        "time": 1689773484000,
        "value": 139.618,
        "_internal_originalTime": 1689773484000
    },
    {
        "time": 1689773485000,
        "value": 139.61,
        "_internal_originalTime": 1689773485000
    },
    {
        "time": 1689773486000,
        "value": 139.617,
        "_internal_originalTime": 1689773486000
    },
    {
        "time": 1689773487000,
        "value": 139.612,
        "_internal_originalTime": 1689773487000
    },
    {
        "time": 1689773489000,
        "value": 139.612,
        "_internal_originalTime": 1689773489000
    },
    {
        "time": 1689773490000,
        "value": 139.611,
        "_internal_originalTime": 1689773490000
    },
    {
        "time": 1689773491000,
        "value": 139.607,
        "_internal_originalTime": 1689773491000
    },
    {
        "time": 1689773492000,
        "value": 139.608,
        "_internal_originalTime": 1689773492000
    },
    {
        "time": 1689773493000,
        "value": 139.612,
        "_internal_originalTime": 1689773493000
    },
    {
        "time": 1689773494000,
        "value": 139.617,
        "_internal_originalTime": 1689773494000
    },
    {
        "time": 1689773495000,
        "value": 139.616,
        "_internal_originalTime": 1689773495000
    },
    {
        "time": 1689773496000,
        "value": 139.62,
        "_internal_originalTime": 1689773496000
    },
    {
        "time": 1689773497000,
        "value": 139.62,
        "_internal_originalTime": 1689773497000
    },
    {
        "time": 1689773498000,
        "value": 139.619,
        "_internal_originalTime": 1689773498000
    },
    {
        "time": 1689773499000,
        "value": 139.623,
        "_internal_originalTime": 1689773499000
    },
    {
        "time": 1689773500000,
        "value": 139.625,
        "_internal_originalTime": 1689773500000
    },
    {
        "time": 1689773501000,
        "value": 139.627,
        "_internal_originalTime": 1689773501000
    },
    {
        "time": 1689773502000,
        "value": 139.628,
        "_internal_originalTime": 1689773502000
    },
    {
        "time": 1689773503000,
        "value": 139.629,
        "_internal_originalTime": 1689773503000
    },
    {
        "time": 1689773504000,
        "value": 139.629,
        "_internal_originalTime": 1689773504000
    },
    {
        "time": 1689773505000,
        "value": 139.627,
        "_internal_originalTime": 1689773505000
    },
    {
        "time": 1689773506000,
        "value": 139.626,
        "_internal_originalTime": 1689773506000
    },
    {
        "time": 1689773507000,
        "value": 139.617,
        "_internal_originalTime": 1689773507000
    },
    {
        "time": 1689773508000,
        "value": 139.617,
        "_internal_originalTime": 1689773508000
    },
    {
        "time": 1689773509000,
        "value": 139.617,
        "_internal_originalTime": 1689773509000
    },
    {
        "time": 1689773510000,
        "value": 139.612,
        "_internal_originalTime": 1689773510000
    },
    {
        "time": 1689773511000,
        "value": 139.613,
        "_internal_originalTime": 1689773511000
    },
    {
        "time": 1689773512000,
        "value": 139.609,
        "_internal_originalTime": 1689773512000
    },
    {
        "time": 1689773513000,
        "value": 139.618,
        "_internal_originalTime": 1689773513000
    },
    {
        "time": 1689773514000,
        "value": 139.612,
        "_internal_originalTime": 1689773514000
    },
    {
        "time": 1689773515000,
        "value": 139.615,
        "_internal_originalTime": 1689773515000
    },
    {
        "time": 1689773516000,
        "value": 139.616,
        "_internal_originalTime": 1689773516000
    },
    {
        "time": 1689773517000,
        "value": 139.612,
        "_internal_originalTime": 1689773517000
    },
    {
        "time": 1689773518000,
        "value": 139.615,
        "_internal_originalTime": 1689773518000
    },
    {
        "time": 1689773519000,
        "value": 139.612,
        "_internal_originalTime": 1689773519000
    },
    {
        "time": 1689773520000,
        "value": 139.612,
        "_internal_originalTime": 1689773520000
    },
    {
        "time": 1689773521000,
        "value": 139.614,
        "_internal_originalTime": 1689773521000
    },
    {
        "time": 1689773522000,
        "value": 139.61,
        "_internal_originalTime": 1689773522000
    },
    {
        "time": 1689773523000,
        "value": 139.611,
        "_internal_originalTime": 1689773523000
    },
    {
        "time": 1689773525000,
        "value": 139.61,
        "_internal_originalTime": 1689773525000
    },
    {
        "time": 1689773526000,
        "value": 139.611,
        "_internal_originalTime": 1689773526000
    },
    {
        "time": 1689773528000,
        "value": 139.616,
        "_internal_originalTime": 1689773528000
    },
    {
        "time": 1689773529000,
        "value": 139.617,
        "_internal_originalTime": 1689773529000
    },
    {
        "time": 1689773530000,
        "value": 139.613,
        "_internal_originalTime": 1689773530000
    },
    {
        "time": 1689773531000,
        "value": 139.615,
        "_internal_originalTime": 1689773531000
    },
    {
        "time": 1689773532000,
        "value": 139.612,
        "_internal_originalTime": 1689773532000
    },
    {
        "time": 1689773533000,
        "value": 139.609,
        "_internal_originalTime": 1689773533000
    },
    {
        "time": 1689773534000,
        "value": 139.604,
        "_internal_originalTime": 1689773534000
    },
    {
        "time": 1689773535000,
        "value": 139.603,
        "_internal_originalTime": 1689773535000
    },
    {
        "time": 1689773536000,
        "value": 139.596,
        "_internal_originalTime": 1689773536000
    },
    {
        "time": 1689773538000,
        "value": 139.586,
        "_internal_originalTime": 1689773538000
    },
    {
        "time": 1689773539000,
        "value": 139.588,
        "_internal_originalTime": 1689773539000
    },
    {
        "time": 1689773540000,
        "value": 139.591,
        "_internal_originalTime": 1689773540000
    },
    {
        "time": 1689773541000,
        "value": 139.603,
        "_internal_originalTime": 1689773541000
    },
    {
        "time": 1689773542000,
        "value": 139.606,
        "_internal_originalTime": 1689773542000
    },
    {
        "time": 1689773543000,
        "value": 139.612,
        "_internal_originalTime": 1689773543000
    },
    {
        "time": 1689773544000,
        "value": 139.611,
        "_internal_originalTime": 1689773544000
    },
    {
        "time": 1689773545000,
        "value": 139.619,
        "_internal_originalTime": 1689773545000
    },
    {
        "time": 1689773546000,
        "value": 139.62,
        "_internal_originalTime": 1689773546000
    },
    {
        "time": 1689773547000,
        "value": 139.617,
        "_internal_originalTime": 1689773547000
    },
    {
        "time": 1689773548000,
        "value": 139.618,
        "_internal_originalTime": 1689773548000
    },
    {
        "time": 1689773549000,
        "value": 139.617,
        "_internal_originalTime": 1689773549000
    },
    {
        "time": 1689773550000,
        "value": 139.607,
        "_internal_originalTime": 1689773550000
    },
    {
        "time": 1689773551000,
        "value": 139.607,
        "_internal_originalTime": 1689773551000
    },
    {
        "time": 1689773552000,
        "value": 139.607,
        "_internal_originalTime": 1689773552000
    },
    {
        "time": 1689773553000,
        "value": 139.597,
        "_internal_originalTime": 1689773553000
    },
    {
        "time": 1689773554000,
        "value": 139.6,
        "_internal_originalTime": 1689773554000
    },
    {
        "time": 1689773555000,
        "value": 139.589,
        "_internal_originalTime": 1689773555000
    },
    {
        "time": 1689773556000,
        "value": 139.599,
        "_internal_originalTime": 1689773556000
    },
    {
        "time": 1689773557000,
        "value": 139.597,
        "_internal_originalTime": 1689773557000
    },
    {
        "time": 1689773558000,
        "value": 139.594,
        "_internal_originalTime": 1689773558000
    },
    {
        "time": 1689773560000,
        "value": 139.595,
        "_internal_originalTime": 1689773560000
    },
    {
        "time": 1689773562000,
        "value": 139.595,
        "_internal_originalTime": 1689773562000
    },
    {
        "time": 1689773563000,
        "value": 139.588,
        "_internal_originalTime": 1689773563000
    },
    {
        "time": 1689773564000,
        "value": 139.588,
        "_internal_originalTime": 1689773564000
    },
    {
        "time": 1689773565000,
        "value": 139.582,
        "_internal_originalTime": 1689773565000
    },
    {
        "time": 1689773567000,
        "value": 139.598,
        "_internal_originalTime": 1689773567000
    },
    {
        "time": 1689773568000,
        "value": 139.599,
        "_internal_originalTime": 1689773568000
    },
    {
        "time": 1689773571000,
        "value": 139.607,
        "_internal_originalTime": 1689773571000
    },
    {
        "time": 1689773572000,
        "value": 139.605,
        "_internal_originalTime": 1689773572000
    },
    {
        "time": 1689773573000,
        "value": 139.606,
        "_internal_originalTime": 1689773573000
    },
    {
        "time": 1689773574000,
        "value": 139.615,
        "_internal_originalTime": 1689773574000
    },
    {
        "time": 1689773575000,
        "value": 139.617,
        "_internal_originalTime": 1689773575000
    },
    {
        "time": 1689773576000,
        "value": 139.625,
        "_internal_originalTime": 1689773576000
    },
    {
        "time": 1689773577000,
        "value": 139.623,
        "_internal_originalTime": 1689773577000
    },
    {
        "time": 1689773578000,
        "value": 139.623,
        "_internal_originalTime": 1689773578000
    },
    {
        "time": 1689773580000,
        "value": 139.632,
        "_internal_originalTime": 1689773580000
    },
    {
        "time": 1689773581000,
        "value": 139.648,
        "_internal_originalTime": 1689773581000
    },
    {
        "time": 1689773582000,
        "value": 139.637,
        "_internal_originalTime": 1689773582000
    },
    {
        "time": 1689773583000,
        "value": 139.645,
        "_internal_originalTime": 1689773583000
    },
    {
        "time": 1689773584000,
        "value": 139.645,
        "_internal_originalTime": 1689773584000
    },
    {
        "time": 1689773585000,
        "value": 139.644,
        "_internal_originalTime": 1689773585000
    },
    {
        "time": 1689773587000,
        "value": 139.64,
        "_internal_originalTime": 1689773587000
    },
    {
        "time": 1689773588000,
        "value": 139.645,
        "_internal_originalTime": 1689773588000
    },
    {
        "time": 1689773589000,
        "value": 139.646,
        "_internal_originalTime": 1689773589000
    },
    {
        "time": 1689773590000,
        "value": 139.647,
        "_internal_originalTime": 1689773590000
    },
    {
        "time": 1689773591000,
        "value": 139.652,
        "_internal_originalTime": 1689773591000
    },
    {
        "time": 1689773592000,
        "value": 139.65,
        "_internal_originalTime": 1689773592000
    },
    {
        "time": 1689773593000,
        "value": 139.653,
        "_internal_originalTime": 1689773593000
    },
    {
        "time": 1689773594000,
        "value": 139.656,
        "_internal_originalTime": 1689773594000
    },
    {
        "time": 1689773595000,
        "value": 139.66,
        "_internal_originalTime": 1689773595000
    },
    {
        "time": 1689773596000,
        "value": 139.675,
        "_internal_originalTime": 1689773596000
    },
    {
        "time": 1689773597000,
        "value": 139.674,
        "_internal_originalTime": 1689773597000
    },
    {
        "time": 1689773598000,
        "value": 139.674,
        "_internal_originalTime": 1689773598000
    },
    {
        "time": 1689773599000,
        "value": 139.677,
        "_internal_originalTime": 1689773599000
    },
    {
        "time": 1689773600000,
        "value": 139.677,
        "_internal_originalTime": 1689773600000
    },
    {
        "time": 1689773601000,
        "value": 139.674,
        "_internal_originalTime": 1689773601000
    },
    {
        "time": 1689773602000,
        "value": 139.677,
        "_internal_originalTime": 1689773602000
    },
    {
        "time": 1689773603000,
        "value": 139.678,
        "_internal_originalTime": 1689773603000
    },
    {
        "time": 1689773605000,
        "value": 139.673,
        "_internal_originalTime": 1689773605000
    },
    {
        "time": 1689773606000,
        "value": 139.673,
        "_internal_originalTime": 1689773606000
    },
    {
        "time": 1689773607000,
        "value": 139.68,
        "_internal_originalTime": 1689773607000
    },
    {
        "time": 1689773608000,
        "value": 139.686,
        "_internal_originalTime": 1689773608000
    },
    {
        "time": 1689773609000,
        "value": 139.689,
        "_internal_originalTime": 1689773609000
    },
    {
        "time": 1689773610000,
        "value": 139.684,
        "_internal_originalTime": 1689773610000
    },
    {
        "time": 1689773611000,
        "value": 139.688,
        "_internal_originalTime": 1689773611000
    },
    {
        "time": 1689773612000,
        "value": 139.691,
        "_internal_originalTime": 1689773612000
    },
    {
        "time": 1689773613000,
        "value": 139.694,
        "_internal_originalTime": 1689773613000
    },
    {
        "time": 1689773614000,
        "value": 139.693,
        "_internal_originalTime": 1689773614000
    },
    {
        "time": 1689773615000,
        "value": 139.692,
        "_internal_originalTime": 1689773615000
    },
    {
        "time": 1689773616000,
        "value": 139.692,
        "_internal_originalTime": 1689773616000
    },
    {
        "time": 1689773618000,
        "value": 139.689,
        "_internal_originalTime": 1689773618000
    },
    {
        "time": 1689773619000,
        "value": 139.684,
        "_internal_originalTime": 1689773619000
    },
    {
        "time": 1689773621000,
        "value": 139.686,
        "_internal_originalTime": 1689773621000
    },
    {
        "time": 1689773622000,
        "value": 139.684,
        "_internal_originalTime": 1689773622000
    },
    {
        "time": 1689773623000,
        "value": 139.682,
        "_internal_originalTime": 1689773623000
    },
    {
        "time": 1689773624000,
        "value": 139.68,
        "_internal_originalTime": 1689773624000
    },
    {
        "time": 1689773625000,
        "value": 139.688,
        "_internal_originalTime": 1689773625000
    },
    {
        "time": 1689773626000,
        "value": 139.687,
        "_internal_originalTime": 1689773626000
    },
    {
        "time": 1689773627000,
        "value": 139.683,
        "_internal_originalTime": 1689773627000
    },
    {
        "time": 1689773628000,
        "value": 139.682,
        "_internal_originalTime": 1689773628000
    },
    {
        "time": 1689773629000,
        "value": 139.68,
        "_internal_originalTime": 1689773629000
    },
    {
        "time": 1689773631000,
        "value": 139.685,
        "_internal_originalTime": 1689773631000
    },
    {
        "time": 1689773632000,
        "value": 139.689,
        "_internal_originalTime": 1689773632000
    },
    {
        "time": 1689773634000,
        "value": 139.695,
        "_internal_originalTime": 1689773634000
    },
    {
        "time": 1689773635000,
        "value": 139.688,
        "_internal_originalTime": 1689773635000
    },
    {
        "time": 1689773636000,
        "value": 139.683,
        "_internal_originalTime": 1689773636000
    },
    {
        "time": 1689773638000,
        "value": 139.678,
        "_internal_originalTime": 1689773638000
    },
    {
        "time": 1689773639000,
        "value": 139.668,
        "_internal_originalTime": 1689773639000
    },
    {
        "time": 1689773640000,
        "value": 139.668,
        "_internal_originalTime": 1689773640000
    },
    {
        "time": 1689773641000,
        "value": 139.662,
        "_internal_originalTime": 1689773641000
    },
    {
        "time": 1689773642000,
        "value": 139.662,
        "_internal_originalTime": 1689773642000
    },
    {
        "time": 1689773643000,
        "value": 139.663,
        "_internal_originalTime": 1689773643000
    },
    {
        "time": 1689773645000,
        "value": 139.667,
        "_internal_originalTime": 1689773645000
    },
    {
        "time": 1689773646000,
        "value": 139.668,
        "_internal_originalTime": 1689773646000
    },
    {
        "time": 1689773648000,
        "value": 139.665,
        "_internal_originalTime": 1689773648000
    },
    {
        "time": 1689773649000,
        "value": 139.667,
        "_internal_originalTime": 1689773649000
    },
    {
        "time": 1689773650000,
        "value": 139.664,
        "_internal_originalTime": 1689773650000
    },
    {
        "time": 1689773651000,
        "value": 139.669,
        "_internal_originalTime": 1689773651000
    },
    {
        "time": 1689773652000,
        "value": 139.675,
        "_internal_originalTime": 1689773652000
    },
    {
        "time": 1689773653000,
        "value": 139.669,
        "_internal_originalTime": 1689773653000
    },
    {
        "time": 1689773654000,
        "value": 139.676,
        "_internal_originalTime": 1689773654000
    },
    {
        "time": 1689773655000,
        "value": 139.673,
        "_internal_originalTime": 1689773655000
    },
    {
        "time": 1689773656000,
        "value": 139.675,
        "_internal_originalTime": 1689773656000
    },
    {
        "time": 1689773657000,
        "value": 139.672,
        "_internal_originalTime": 1689773657000
    },
    {
        "time": 1689773658000,
        "value": 139.67,
        "_internal_originalTime": 1689773658000
    },
    {
        "time": 1689773660000,
        "value": 139.666,
        "_internal_originalTime": 1689773660000
    },
    {
        "time": 1689773661000,
        "value": 139.667,
        "_internal_originalTime": 1689773661000
    },
    {
        "time": 1689773662000,
        "value": 139.668,
        "_internal_originalTime": 1689773662000
    },
    {
        "time": 1689773664000,
        "value": 139.672,
        "_internal_originalTime": 1689773664000
    },
    {
        "time": 1689773666000,
        "value": 139.672,
        "_internal_originalTime": 1689773666000
    },
    {
        "time": 1689773667000,
        "value": 139.67,
        "_internal_originalTime": 1689773667000
    },
    {
        "time": 1689773668000,
        "value": 139.672,
        "_internal_originalTime": 1689773668000
    },
    {
        "time": 1689773670000,
        "value": 139.668,
        "_internal_originalTime": 1689773670000
    },
    {
        "time": 1689773672000,
        "value": 139.669,
        "_internal_originalTime": 1689773672000
    },
    {
        "time": 1689773673000,
        "value": 139.669,
        "_internal_originalTime": 1689773673000
    },
    {
        "time": 1689773674000,
        "value": 139.667,
        "_internal_originalTime": 1689773674000
    },
    {
        "time": 1689773675000,
        "value": 139.666,
        "_internal_originalTime": 1689773675000
    },
    {
        "time": 1689773676000,
        "value": 139.666,
        "_internal_originalTime": 1689773676000
    },
    {
        "time": 1689773677000,
        "value": 139.667,
        "_internal_originalTime": 1689773677000
    },
    {
        "time": 1689773678000,
        "value": 139.665,
        "_internal_originalTime": 1689773678000
    },
    {
        "time": 1689773679000,
        "value": 139.662,
        "_internal_originalTime": 1689773679000
    },
    {
        "time": 1689773681000,
        "value": 139.668,
        "_internal_originalTime": 1689773681000
    },
    {
        "time": 1689773682000,
        "value": 139.656,
        "_internal_originalTime": 1689773682000
    },
    {
        "time": 1689773683000,
        "value": 139.66,
        "_internal_originalTime": 1689773683000
    },
    {
        "time": 1689773685000,
        "value": 139.649,
        "_internal_originalTime": 1689773685000
    },
    {
        "time": 1689773686000,
        "value": 139.649,
        "_internal_originalTime": 1689773686000
    },
    {
        "time": 1689773687000,
        "value": 139.649,
        "_internal_originalTime": 1689773687000
    },
    {
        "time": 1689773689000,
        "value": 139.648,
        "_internal_originalTime": 1689773689000
    },
    {
        "time": 1689773691000,
        "value": 139.647,
        "_internal_originalTime": 1689773691000
    },
    {
        "time": 1689773693000,
        "value": 139.647,
        "_internal_originalTime": 1689773693000
    },
    {
        "time": 1689773694000,
        "value": 139.649,
        "_internal_originalTime": 1689773694000
    },
    {
        "time": 1689773696000,
        "value": 139.664,
        "_internal_originalTime": 1689773696000
    },
    {
        "time": 1689773697000,
        "value": 139.655,
        "_internal_originalTime": 1689773697000
    },
    {
        "time": 1689773698000,
        "value": 139.653,
        "_internal_originalTime": 1689773698000
    },
    {
        "time": 1689773700000,
        "value": 139.649,
        "_internal_originalTime": 1689773700000
    },
    {
        "time": 1689773702000,
        "value": 139.649,
        "_internal_originalTime": 1689773702000
    },
    {
        "time": 1689773703000,
        "value": 139.653,
        "_internal_originalTime": 1689773703000
    },
    {
        "time": 1689773704000,
        "value": 139.649,
        "_internal_originalTime": 1689773704000
    },
    {
        "time": 1689773705000,
        "value": 139.638,
        "_internal_originalTime": 1689773705000
    },
    {
        "time": 1689773706000,
        "value": 139.639,
        "_internal_originalTime": 1689773706000
    },
    {
        "time": 1689773707000,
        "value": 139.643,
        "_internal_originalTime": 1689773707000
    },
    {
        "time": 1689773709000,
        "value": 139.655,
        "_internal_originalTime": 1689773709000
    },
    {
        "time": 1689773710000,
        "value": 139.65,
        "_internal_originalTime": 1689773710000
    },
    {
        "time": 1689773712000,
        "value": 139.657,
        "_internal_originalTime": 1689773712000
    },
    {
        "time": 1689773713000,
        "value": 139.656,
        "_internal_originalTime": 1689773713000
    },
    {
        "time": 1689773715000,
        "value": 139.658,
        "_internal_originalTime": 1689773715000
    },
    {
        "time": 1689773717000,
        "value": 139.664,
        "_internal_originalTime": 1689773717000
    },
    {
        "time": 1689773718000,
        "value": 139.667,
        "_internal_originalTime": 1689773718000
    },
    {
        "time": 1689773719000,
        "value": 139.667,
        "_internal_originalTime": 1689773719000
    },
    {
        "time": 1689773721000,
        "value": 139.667,
        "_internal_originalTime": 1689773721000
    },
    {
        "time": 1689773722000,
        "value": 139.669,
        "_internal_originalTime": 1689773722000
    },
    {
        "time": 1689773723000,
        "value": 139.667,
        "_internal_originalTime": 1689773723000
    },
    {
        "time": 1689773724000,
        "value": 139.671,
        "_internal_originalTime": 1689773724000
    },
    {
        "time": 1689773725000,
        "value": 139.672,
        "_internal_originalTime": 1689773725000
    },
    {
        "time": 1689773726000,
        "value": 139.672,
        "_internal_originalTime": 1689773726000
    },
    {
        "time": 1689773727000,
        "value": 139.67,
        "_internal_originalTime": 1689773727000
    },
    {
        "time": 1689773728000,
        "value": 139.669,
        "_internal_originalTime": 1689773728000
    },
    {
        "time": 1689773730000,
        "value": 139.659,
        "_internal_originalTime": 1689773730000
    },
    {
        "time": 1689773731000,
        "value": 139.659,
        "_internal_originalTime": 1689773731000
    },
    {
        "time": 1689773732000,
        "value": 139.657,
        "_internal_originalTime": 1689773732000
    },
    {
        "time": 1689773734000,
        "value": 139.656,
        "_internal_originalTime": 1689773734000
    },
    {
        "time": 1689773736000,
        "value": 139.65,
        "_internal_originalTime": 1689773736000
    },
    {
        "time": 1689773737000,
        "value": 139.644,
        "_internal_originalTime": 1689773737000
    },
    {
        "time": 1689773739000,
        "value": 139.654,
        "_internal_originalTime": 1689773739000
    },
    {
        "time": 1689773740000,
        "value": 139.658,
        "_internal_originalTime": 1689773740000
    },
    {
        "time": 1689773741000,
        "value": 139.66,
        "_internal_originalTime": 1689773741000
    },
    {
        "time": 1689773743000,
        "value": 139.654,
        "_internal_originalTime": 1689773743000
    },
    {
        "time": 1689773744000,
        "value": 139.653,
        "_internal_originalTime": 1689773744000
    },
    {
        "time": 1689773745000,
        "value": 139.644,
        "_internal_originalTime": 1689773745000
    },
    {
        "time": 1689773747000,
        "value": 139.65,
        "_internal_originalTime": 1689773747000
    },
    {
        "time": 1689773749000,
        "value": 139.652,
        "_internal_originalTime": 1689773749000
    },
    {
        "time": 1689773750000,
        "value": 139.651,
        "_internal_originalTime": 1689773750000
    },
    {
        "time": 1689773751000,
        "value": 139.654,
        "_internal_originalTime": 1689773751000
    },
    {
        "time": 1689773753000,
        "value": 139.654,
        "_internal_originalTime": 1689773753000
    },
    {
        "time": 1689773754000,
        "value": 139.659,
        "_internal_originalTime": 1689773754000
    },
    {
        "time": 1689773755000,
        "value": 139.654,
        "_internal_originalTime": 1689773755000
    },
    {
        "time": 1689773756000,
        "value": 139.658,
        "_internal_originalTime": 1689773756000
    },
    {
        "time": 1689773757000,
        "value": 139.66,
        "_internal_originalTime": 1689773757000
    },
    {
        "time": 1689773759000,
        "value": 139.653,
        "_internal_originalTime": 1689773759000
    },
    {
        "time": 1689773760000,
        "value": 139.651,
        "_internal_originalTime": 1689773760000
    },
    {
        "time": 1689773761000,
        "value": 139.658,
        "_internal_originalTime": 1689773761000
    },
    {
        "time": 1689773763000,
        "value": 139.653,
        "_internal_originalTime": 1689773763000
    },
    {
        "time": 1689773764000,
        "value": 139.639,
        "_internal_originalTime": 1689773764000
    },
    {
        "time": 1689773766000,
        "value": 139.629,
        "_internal_originalTime": 1689773766000
    },
    {
        "time": 1689773768000,
        "value": 139.64,
        "_internal_originalTime": 1689773768000
    },
    {
        "time": 1689773769000,
        "value": 139.642,
        "_internal_originalTime": 1689773769000
    },
    {
        "time": 1689773770000,
        "value": 139.642,
        "_internal_originalTime": 1689773770000
    },
    {
        "time": 1689773772000,
        "value": 139.647,
        "_internal_originalTime": 1689773772000
    },
    {
        "time": 1689773773000,
        "value": 139.642,
        "_internal_originalTime": 1689773773000
    },
    {
        "time": 1689773774000,
        "value": 139.649,
        "_internal_originalTime": 1689773774000
    },
    {
        "time": 1689773775000,
        "value": 139.646,
        "_internal_originalTime": 1689773775000
    },
    {
        "time": 1689773776000,
        "value": 139.648,
        "_internal_originalTime": 1689773776000
    },
    {
        "time": 1689773778000,
        "value": 139.647,
        "_internal_originalTime": 1689773778000
    },
    {
        "time": 1689773780000,
        "value": 139.637,
        "_internal_originalTime": 1689773780000
    },
    {
        "time": 1689773781000,
        "value": 139.637,
        "_internal_originalTime": 1689773781000
    },
    {
        "time": 1689773782000,
        "value": 139.632,
        "_internal_originalTime": 1689773782000
    },
    {
        "time": 1689773784000,
        "value": 139.634,
        "_internal_originalTime": 1689773784000
    },
    {
        "time": 1689773785000,
        "value": 139.634,
        "_internal_originalTime": 1689773785000
    },
    {
        "time": 1689773787000,
        "value": 139.618,
        "_internal_originalTime": 1689773787000
    },
    {
        "time": 1689773788000,
        "value": 139.621,
        "_internal_originalTime": 1689773788000
    },
    {
        "time": 1689773790000,
        "value": 139.63,
        "_internal_originalTime": 1689773790000
    },
    {
        "time": 1689773792000,
        "value": 139.622,
        "_internal_originalTime": 1689773792000
    },
    {
        "time": 1689773793000,
        "value": 139.617,
        "_internal_originalTime": 1689773793000
    },
    {
        "time": 1689773832000,
        "value": 139.595,
        "_internal_originalTime": 1689773832000
    },
    {
        "time": 1689773834000,
        "value": 139.598,
        "_internal_originalTime": 1689773834000
    },
    {
        "time": 1689773835000,
        "value": 139.597,
        "_internal_originalTime": 1689773835000
    },
    {
        "time": 1689773836000,
        "value": 139.599,
        "_internal_originalTime": 1689773836000
    },
    {
        "time": 1689773837000,
        "value": 139.598,
        "_internal_originalTime": 1689773837000
    },
    {
        "time": 1689773838000,
        "value": 139.61,
        "_internal_originalTime": 1689773838000
    },
    {
        "time": 1689773839000,
        "value": 139.609,
        "_internal_originalTime": 1689773839000
    },
    {
        "time": 1689773840000,
        "value": 139.61,
        "_internal_originalTime": 1689773840000
    },
    {
        "time": 1689773842000,
        "value": 139.601,
        "_internal_originalTime": 1689773842000
    },
    {
        "time": 1689773843000,
        "value": 139.614,
        "_internal_originalTime": 1689773843000
    },
    {
        "time": 1689773844000,
        "value": 139.615,
        "_internal_originalTime": 1689773844000
    },
    {
        "time": 1689773845000,
        "value": 139.607,
        "_internal_originalTime": 1689773845000
    },
    {
        "time": 1689773846000,
        "value": 139.602,
        "_internal_originalTime": 1689773846000
    },
    {
        "time": 1689773848000,
        "value": 139.606,
        "_internal_originalTime": 1689773848000
    },
    {
        "time": 1689773849000,
        "value": 139.609,
        "_internal_originalTime": 1689773849000
    },
    {
        "time": 1689773850000,
        "value": 139.61,
        "_internal_originalTime": 1689773850000
    },
    {
        "time": 1689773851000,
        "value": 139.611,
        "_internal_originalTime": 1689773851000
    },
    {
        "time": 1689773852000,
        "value": 139.622,
        "_internal_originalTime": 1689773852000
    },
    {
        "time": 1689773853000,
        "value": 139.618,
        "_internal_originalTime": 1689773853000
    },
    {
        "time": 1689773854000,
        "value": 139.616,
        "_internal_originalTime": 1689773854000
    },
    {
        "time": 1689773856000,
        "value": 139.616,
        "_internal_originalTime": 1689773856000
    },
    {
        "time": 1689773857000,
        "value": 139.616,
        "_internal_originalTime": 1689773857000
    },
    {
        "time": 1689773858000,
        "value": 139.617,
        "_internal_originalTime": 1689773858000
    },
    {
        "time": 1689773860000,
        "value": 139.617,
        "_internal_originalTime": 1689773860000
    },
    {
        "time": 1689773861000,
        "value": 139.621,
        "_internal_originalTime": 1689773861000
    },
    {
        "time": 1689773862000,
        "value": 139.621,
        "_internal_originalTime": 1689773862000
    },
    {
        "time": 1689773864000,
        "value": 139.623,
        "_internal_originalTime": 1689773864000
    },
    {
        "time": 1689773865000,
        "value": 139.622,
        "_internal_originalTime": 1689773865000
    },
    {
        "time": 1689773866000,
        "value": 139.61,
        "_internal_originalTime": 1689773866000
    },
    {
        "time": 1689773867000,
        "value": 139.612,
        "_internal_originalTime": 1689773867000
    },
    {
        "time": 1689773868000,
        "value": 139.606,
        "_internal_originalTime": 1689773868000
    },
    {
        "time": 1689773870000,
        "value": 139.608,
        "_internal_originalTime": 1689773870000
    },
    {
        "time": 1689773871000,
        "value": 139.609,
        "_internal_originalTime": 1689773871000
    },
    {
        "time": 1689773872000,
        "value": 139.607,
        "_internal_originalTime": 1689773872000
    },
    {
        "time": 1689773873000,
        "value": 139.602,
        "_internal_originalTime": 1689773873000
    },
    {
        "time": 1689773874000,
        "value": 139.591,
        "_internal_originalTime": 1689773874000
    },
    {
        "time": 1689773875000,
        "value": 139.593,
        "_internal_originalTime": 1689773875000
    },
    {
        "time": 1689773876000,
        "value": 139.595,
        "_internal_originalTime": 1689773876000
    },
    {
        "time": 1689773877000,
        "value": 139.597,
        "_internal_originalTime": 1689773877000
    },
    {
        "time": 1689773878000,
        "value": 139.594,
        "_internal_originalTime": 1689773878000
    },
    {
        "time": 1689773879000,
        "value": 139.599,
        "_internal_originalTime": 1689773879000
    },
    {
        "time": 1689773880000,
        "value": 139.599,
        "_internal_originalTime": 1689773880000
    },
    {
        "time": 1689773881000,
        "value": 139.599,
        "_internal_originalTime": 1689773881000
    },
    {
        "time": 1689773882000,
        "value": 139.59,
        "_internal_originalTime": 1689773882000
    },
    {
        "time": 1689773883000,
        "value": 139.591,
        "_internal_originalTime": 1689773883000
    },
    {
        "time": 1689773884000,
        "value": 139.596,
        "_internal_originalTime": 1689773884000
    },
    {
        "time": 1689773885000,
        "value": 139.595,
        "_internal_originalTime": 1689773885000
    },
    {
        "time": 1689773886000,
        "value": 139.595,
        "_internal_originalTime": 1689773886000
    },
    {
        "time": 1689773888000,
        "value": 139.582,
        "_internal_originalTime": 1689773888000
    },
    {
        "time": 1689773889000,
        "value": 139.583,
        "_internal_originalTime": 1689773889000
    },
    {
        "time": 1689773890000,
        "value": 139.587,
        "_internal_originalTime": 1689773890000
    },
    {
        "time": 1689773891000,
        "value": 139.588,
        "_internal_originalTime": 1689773891000
    },
    {
        "time": 1689773892000,
        "value": 139.586,
        "_internal_originalTime": 1689773892000
    },
    {
        "time": 1689773893000,
        "value": 139.582,
        "_internal_originalTime": 1689773893000
    },
    {
        "time": 1689773894000,
        "value": 139.587,
        "_internal_originalTime": 1689773894000
    },
    {
        "time": 1689773895000,
        "value": 139.592,
        "_internal_originalTime": 1689773895000
    },
    {
        "time": 1689773896000,
        "value": 139.608,
        "_internal_originalTime": 1689773896000
    },
    {
        "time": 1689773897000,
        "value": 139.609,
        "_internal_originalTime": 1689773897000
    },
    {
        "time": 1689773898000,
        "value": 139.614,
        "_internal_originalTime": 1689773898000
    },
    {
        "time": 1689773899000,
        "value": 139.61,
        "_internal_originalTime": 1689773899000
    },
    {
        "time": 1689773900000,
        "value": 139.608,
        "_internal_originalTime": 1689773900000
    },
    {
        "time": 1689773901000,
        "value": 139.615,
        "_internal_originalTime": 1689773901000
    },
    {
        "time": 1689773902000,
        "value": 139.616,
        "_internal_originalTime": 1689773902000
    },
    {
        "time": 1689773903000,
        "value": 139.618,
        "_internal_originalTime": 1689773903000
    },
    {
        "time": 1689773904000,
        "value": 139.615,
        "_internal_originalTime": 1689773904000
    },
    {
        "time": 1689773905000,
        "value": 139.611,
        "_internal_originalTime": 1689773905000
    },
    {
        "time": 1689773906000,
        "value": 139.616,
        "_internal_originalTime": 1689773906000
    },
    {
        "time": 1689773907000,
        "value": 139.618,
        "_internal_originalTime": 1689773907000
    },
    {
        "time": 1689773908000,
        "value": 139.618,
        "_internal_originalTime": 1689773908000
    },
    {
        "time": 1689773909000,
        "value": 139.62,
        "_internal_originalTime": 1689773909000
    },
    {
        "time": 1689773910000,
        "value": 139.619,
        "_internal_originalTime": 1689773910000
    },
    {
        "time": 1689773911000,
        "value": 139.62,
        "_internal_originalTime": 1689773911000
    },
    {
        "time": 1689773912000,
        "value": 139.62,
        "_internal_originalTime": 1689773912000
    },
    {
        "time": 1689773913000,
        "value": 139.625,
        "_internal_originalTime": 1689773913000
    },
    {
        "time": 1689773914000,
        "value": 139.619,
        "_internal_originalTime": 1689773914000
    },
    {
        "time": 1689773915000,
        "value": 139.618,
        "_internal_originalTime": 1689773915000
    },
    {
        "time": 1689773916000,
        "value": 139.619,
        "_internal_originalTime": 1689773916000
    },
    {
        "time": 1689773917000,
        "value": 139.613,
        "_internal_originalTime": 1689773917000
    },
    {
        "time": 1689773918000,
        "value": 139.611,
        "_internal_originalTime": 1689773918000
    },
    {
        "time": 1689773919000,
        "value": 139.613,
        "_internal_originalTime": 1689773919000
    },
    {
        "time": 1689773920000,
        "value": 139.613,
        "_internal_originalTime": 1689773920000
    },
    {
        "time": 1689773921000,
        "value": 139.615,
        "_internal_originalTime": 1689773921000
    },
    {
        "time": 1689773922000,
        "value": 139.612,
        "_internal_originalTime": 1689773922000
    },
    {
        "time": 1689773923000,
        "value": 139.6,
        "_internal_originalTime": 1689773923000
    },
    {
        "time": 1689773924000,
        "value": 139.592,
        "_internal_originalTime": 1689773924000
    },
    {
        "time": 1689773925000,
        "value": 139.595,
        "_internal_originalTime": 1689773925000
    },
    {
        "time": 1689773926000,
        "value": 139.596,
        "_internal_originalTime": 1689773926000
    },
    {
        "time": 1689773927000,
        "value": 139.601,
        "_internal_originalTime": 1689773927000
    },
    {
        "time": 1689773928000,
        "value": 139.605,
        "_internal_originalTime": 1689773928000
    },
    {
        "time": 1689773929000,
        "value": 139.601,
        "_internal_originalTime": 1689773929000
    },
    {
        "time": 1689773931000,
        "value": 139.598,
        "_internal_originalTime": 1689773931000
    },
    {
        "time": 1689773932000,
        "value": 139.595,
        "_internal_originalTime": 1689773932000
    },
    {
        "time": 1689773933000,
        "value": 139.593,
        "_internal_originalTime": 1689773933000
    },
    {
        "time": 1689773934000,
        "value": 139.589,
        "_internal_originalTime": 1689773934000
    },
    {
        "time": 1689773935000,
        "value": 139.593,
        "_internal_originalTime": 1689773935000
    },
    {
        "time": 1689773936000,
        "value": 139.589,
        "_internal_originalTime": 1689773936000
    },
    {
        "time": 1689773937000,
        "value": 139.583,
        "_internal_originalTime": 1689773937000
    },
    {
        "time": 1689773938000,
        "value": 139.581,
        "_internal_originalTime": 1689773938000
    },
    {
        "time": 1689773939000,
        "value": 139.582,
        "_internal_originalTime": 1689773939000
    },
    {
        "time": 1689773940000,
        "value": 139.582,
        "_internal_originalTime": 1689773940000
    },
    {
        "time": 1689773941000,
        "value": 139.56,
        "_internal_originalTime": 1689773941000
    },
    {
        "time": 1689773942000,
        "value": 139.574,
        "_internal_originalTime": 1689773942000
    },
    {
        "time": 1689773943000,
        "value": 139.567,
        "_internal_originalTime": 1689773943000
    },
    {
        "time": 1689773944000,
        "value": 139.572,
        "_internal_originalTime": 1689773944000
    },
    {
        "time": 1689773945000,
        "value": 139.575,
        "_internal_originalTime": 1689773945000
    },
    {
        "time": 1689773946000,
        "value": 139.568,
        "_internal_originalTime": 1689773946000
    },
    {
        "time": 1689773947000,
        "value": 139.56,
        "_internal_originalTime": 1689773947000
    },
    {
        "time": 1689773948000,
        "value": 139.571,
        "_internal_originalTime": 1689773948000
    },
    {
        "time": 1689773949000,
        "value": 139.568,
        "_internal_originalTime": 1689773949000
    },
    {
        "time": 1689773950000,
        "value": 139.564,
        "_internal_originalTime": 1689773950000
    },
    {
        "time": 1689773952000,
        "value": 139.569,
        "_internal_originalTime": 1689773952000
    },
    {
        "time": 1689773953000,
        "value": 139.573,
        "_internal_originalTime": 1689773953000
    },
    {
        "time": 1689773954000,
        "value": 139.573,
        "_internal_originalTime": 1689773954000
    },
    {
        "time": 1689773955000,
        "value": 139.575,
        "_internal_originalTime": 1689773955000
    },
    {
        "time": 1689773956000,
        "value": 139.583,
        "_internal_originalTime": 1689773956000
    },
    {
        "time": 1689773957000,
        "value": 139.582,
        "_internal_originalTime": 1689773957000
    },
    {
        "time": 1689773959000,
        "value": 139.581,
        "_internal_originalTime": 1689773959000
    },
    {
        "time": 1689773960000,
        "value": 139.581,
        "_internal_originalTime": 1689773960000
    },
    {
        "time": 1689773961000,
        "value": 139.58,
        "_internal_originalTime": 1689773961000
    },
    {
        "time": 1689773962000,
        "value": 139.579,
        "_internal_originalTime": 1689773962000
    },
    {
        "time": 1689773963000,
        "value": 139.578,
        "_internal_originalTime": 1689773963000
    },
    {
        "time": 1689773964000,
        "value": 139.577,
        "_internal_originalTime": 1689773964000
    },
    {
        "time": 1689773965000,
        "value": 139.581,
        "_internal_originalTime": 1689773965000
    },
    {
        "time": 1689773966000,
        "value": 139.573,
        "_internal_originalTime": 1689773966000
    },
    {
        "time": 1689773967000,
        "value": 139.574,
        "_internal_originalTime": 1689773967000
    },
    {
        "time": 1689773968000,
        "value": 139.573,
        "_internal_originalTime": 1689773968000
    },
    {
        "time": 1689773969000,
        "value": 139.573,
        "_internal_originalTime": 1689773969000
    },
    {
        "time": 1689773970000,
        "value": 139.576,
        "_internal_originalTime": 1689773970000
    },
    {
        "time": 1689773971000,
        "value": 139.577,
        "_internal_originalTime": 1689773971000
    },
    {
        "time": 1689773972000,
        "value": 139.579,
        "_internal_originalTime": 1689773972000
    },
    {
        "time": 1689773974000,
        "value": 139.578,
        "_internal_originalTime": 1689773974000
    },
    {
        "time": 1689773975000,
        "value": 139.576,
        "_internal_originalTime": 1689773975000
    },
    {
        "time": 1689773976000,
        "value": 139.573,
        "_internal_originalTime": 1689773976000
    },
    {
        "time": 1689773977000,
        "value": 139.568,
        "_internal_originalTime": 1689773977000
    },
    {
        "time": 1689773978000,
        "value": 139.568,
        "_internal_originalTime": 1689773978000
    },
    {
        "time": 1689773979000,
        "value": 139.558,
        "_internal_originalTime": 1689773979000
    },
    {
        "time": 1689773980000,
        "value": 139.555,
        "_internal_originalTime": 1689773980000
    },
    {
        "time": 1689773981000,
        "value": 139.548,
        "_internal_originalTime": 1689773981000
    },
    {
        "time": 1689773982000,
        "value": 139.556,
        "_internal_originalTime": 1689773982000
    },
    {
        "time": 1689773984000,
        "value": 139.537,
        "_internal_originalTime": 1689773984000
    },
    {
        "time": 1689773985000,
        "value": 139.542,
        "_internal_originalTime": 1689773985000
    },
    {
        "time": 1689773986000,
        "value": 139.541,
        "_internal_originalTime": 1689773986000
    },
    {
        "time": 1689773987000,
        "value": 139.537,
        "_internal_originalTime": 1689773987000
    },
    {
        "time": 1689773988000,
        "value": 139.533,
        "_internal_originalTime": 1689773988000
    },
    {
        "time": 1689773989000,
        "value": 139.538,
        "_internal_originalTime": 1689773989000
    },
    {
        "time": 1689773990000,
        "value": 139.532,
        "_internal_originalTime": 1689773990000
    },
    {
        "time": 1689773991000,
        "value": 139.532,
        "_internal_originalTime": 1689773991000
    },
    {
        "time": 1689773992000,
        "value": 139.535,
        "_internal_originalTime": 1689773992000
    },
    {
        "time": 1689773993000,
        "value": 139.531,
        "_internal_originalTime": 1689773993000
    },
    {
        "time": 1689773994000,
        "value": 139.535,
        "_internal_originalTime": 1689773994000
    },
    {
        "time": 1689773996000,
        "value": 139.525,
        "_internal_originalTime": 1689773996000
    },
    {
        "time": 1689773997000,
        "value": 139.534,
        "_internal_originalTime": 1689773997000
    },
    {
        "time": 1689773998000,
        "value": 139.526,
        "_internal_originalTime": 1689773998000
    },
    {
        "time": 1689773999000,
        "value": 139.523,
        "_internal_originalTime": 1689773999000
    },
    {
        "time": 1689774000000,
        "value": 139.524,
        "_internal_originalTime": 1689774000000
    },
    {
        "time": 1689774002000,
        "value": 139.52,
        "_internal_originalTime": 1689774002000
    },
    {
        "time": 1689774003000,
        "value": 139.525,
        "_internal_originalTime": 1689774003000
    },
    {
        "time": 1689774004000,
        "value": 139.524,
        "_internal_originalTime": 1689774004000
    },
    {
        "time": 1689774005000,
        "value": 139.539,
        "_internal_originalTime": 1689774005000
    },
    {
        "time": 1689774006000,
        "value": 139.541,
        "_internal_originalTime": 1689774006000
    },
    {
        "time": 1689774007000,
        "value": 139.538,
        "_internal_originalTime": 1689774007000
    },
    {
        "time": 1689774008000,
        "value": 139.538,
        "_internal_originalTime": 1689774008000
    },
    {
        "time": 1689774009000,
        "value": 139.542,
        "_internal_originalTime": 1689774009000
    },
    {
        "time": 1689774010000,
        "value": 139.542,
        "_internal_originalTime": 1689774010000
    },
    {
        "time": 1689774012000,
        "value": 139.543,
        "_internal_originalTime": 1689774012000
    },
    {
        "time": 1689774014000,
        "value": 139.542,
        "_internal_originalTime": 1689774014000
    },
    {
        "time": 1689774015000,
        "value": 139.547,
        "_internal_originalTime": 1689774015000
    },
    {
        "time": 1689774016000,
        "value": 139.55,
        "_internal_originalTime": 1689774016000
    },
    {
        "time": 1689774018000,
        "value": 139.553,
        "_internal_originalTime": 1689774018000
    },
    {
        "time": 1689774019000,
        "value": 139.545,
        "_internal_originalTime": 1689774019000
    },
    {
        "time": 1689774021000,
        "value": 139.54,
        "_internal_originalTime": 1689774021000
    },
    {
        "time": 1689774022000,
        "value": 139.54,
        "_internal_originalTime": 1689774022000
    },
    {
        "time": 1689774024000,
        "value": 139.546,
        "_internal_originalTime": 1689774024000
    },
    {
        "time": 1689774025000,
        "value": 139.546,
        "_internal_originalTime": 1689774025000
    },
    {
        "time": 1689774026000,
        "value": 139.54,
        "_internal_originalTime": 1689774026000
    },
    {
        "time": 1689774027000,
        "value": 139.538,
        "_internal_originalTime": 1689774027000
    },
    {
        "time": 1689774028000,
        "value": 139.538,
        "_internal_originalTime": 1689774028000
    },
    {
        "time": 1689774029000,
        "value": 139.517,
        "_internal_originalTime": 1689774029000
    },
    {
        "time": 1689774031000,
        "value": 139.523,
        "_internal_originalTime": 1689774031000
    },
    {
        "time": 1689774032000,
        "value": 139.518,
        "_internal_originalTime": 1689774032000
    },
    {
        "time": 1689774033000,
        "value": 139.521,
        "_internal_originalTime": 1689774033000
    },
    {
        "time": 1689774034000,
        "value": 139.529,
        "_internal_originalTime": 1689774034000
    },
    {
        "time": 1689774035000,
        "value": 139.536,
        "_internal_originalTime": 1689774035000
    },
    {
        "time": 1689774036000,
        "value": 139.533,
        "_internal_originalTime": 1689774036000
    },
    {
        "time": 1689774038000,
        "value": 139.536,
        "_internal_originalTime": 1689774038000
    },
    {
        "time": 1689774039000,
        "value": 139.544,
        "_internal_originalTime": 1689774039000
    },
    {
        "time": 1689774040000,
        "value": 139.55,
        "_internal_originalTime": 1689774040000
    },
    {
        "time": 1689774041000,
        "value": 139.552,
        "_internal_originalTime": 1689774041000
    },
    {
        "time": 1689774042000,
        "value": 139.555,
        "_internal_originalTime": 1689774042000
    },
    {
        "time": 1689774043000,
        "value": 139.56,
        "_internal_originalTime": 1689774043000
    },
    {
        "time": 1689774045000,
        "value": 139.562,
        "_internal_originalTime": 1689774045000
    },
    {
        "time": 1689774046000,
        "value": 139.56,
        "_internal_originalTime": 1689774046000
    },
    {
        "time": 1689774047000,
        "value": 139.56,
        "_internal_originalTime": 1689774047000
    },
    {
        "time": 1689774048000,
        "value": 139.566,
        "_internal_originalTime": 1689774048000
    },
    {
        "time": 1689774049000,
        "value": 139.562,
        "_internal_originalTime": 1689774049000
    },
    {
        "time": 1689774050000,
        "value": 139.561,
        "_internal_originalTime": 1689774050000
    },
    {
        "time": 1689774051000,
        "value": 139.564,
        "_internal_originalTime": 1689774051000
    },
    {
        "time": 1689774053000,
        "value": 139.563,
        "_internal_originalTime": 1689774053000
    },
    {
        "time": 1689774054000,
        "value": 139.569,
        "_internal_originalTime": 1689774054000
    },
    {
        "time": 1689774055000,
        "value": 139.574,
        "_internal_originalTime": 1689774055000
    },
    {
        "time": 1689774056000,
        "value": 139.569,
        "_internal_originalTime": 1689774056000
    },
    {
        "time": 1689774057000,
        "value": 139.572,
        "_internal_originalTime": 1689774057000
    },
    {
        "time": 1689774058000,
        "value": 139.575,
        "_internal_originalTime": 1689774058000
    },
    {
        "time": 1689774059000,
        "value": 139.571,
        "_internal_originalTime": 1689774059000
    },
    {
        "time": 1689774060000,
        "value": 139.558,
        "_internal_originalTime": 1689774060000
    },
    {
        "time": 1689774061000,
        "value": 139.552,
        "_internal_originalTime": 1689774061000
    },
    {
        "time": 1689774063000,
        "value": 139.543,
        "_internal_originalTime": 1689774063000
    },
    {
        "time": 1689774064000,
        "value": 139.549,
        "_internal_originalTime": 1689774064000
    },
    {
        "time": 1689774066000,
        "value": 139.55,
        "_internal_originalTime": 1689774066000
    },
    {
        "time": 1689774067000,
        "value": 139.556,
        "_internal_originalTime": 1689774067000
    },
    {
        "time": 1689774068000,
        "value": 139.561,
        "_internal_originalTime": 1689774068000
    },
    {
        "time": 1689774069000,
        "value": 139.561,
        "_internal_originalTime": 1689774069000
    },
    {
        "time": 1689774070000,
        "value": 139.556,
        "_internal_originalTime": 1689774070000
    },
    {
        "time": 1689774071000,
        "value": 139.554,
        "_internal_originalTime": 1689774071000
    },
    {
        "time": 1689774073000,
        "value": 139.548,
        "_internal_originalTime": 1689774073000
    },
    {
        "time": 1689774074000,
        "value": 139.541,
        "_internal_originalTime": 1689774074000
    },
    {
        "time": 1689774075000,
        "value": 139.538,
        "_internal_originalTime": 1689774075000
    },
    {
        "time": 1689774076000,
        "value": 139.542,
        "_internal_originalTime": 1689774076000
    },
    {
        "time": 1689774077000,
        "value": 139.543,
        "_internal_originalTime": 1689774077000
    },
    {
        "time": 1689774078000,
        "value": 139.538,
        "_internal_originalTime": 1689774078000
    },
    {
        "time": 1689774080000,
        "value": 139.543,
        "_internal_originalTime": 1689774080000
    },
    {
        "time": 1689774081000,
        "value": 139.543,
        "_internal_originalTime": 1689774081000
    },
    {
        "time": 1689774082000,
        "value": 139.542,
        "_internal_originalTime": 1689774082000
    },
    {
        "time": 1689774084000,
        "value": 139.543,
        "_internal_originalTime": 1689774084000
    },
    {
        "time": 1689774085000,
        "value": 139.544,
        "_internal_originalTime": 1689774085000
    },
    {
        "time": 1689774086000,
        "value": 139.541,
        "_internal_originalTime": 1689774086000
    },
    {
        "time": 1689774087000,
        "value": 139.531,
        "_internal_originalTime": 1689774087000
    },
    {
        "time": 1689774088000,
        "value": 139.541,
        "_internal_originalTime": 1689774088000
    },
    {
        "time": 1689774090000,
        "value": 139.54,
        "_internal_originalTime": 1689774090000
    },
    {
        "time": 1689774091000,
        "value": 139.541,
        "_internal_originalTime": 1689774091000
    },
    {
        "time": 1689774092000,
        "value": 139.544,
        "_internal_originalTime": 1689774092000
    },
    {
        "time": 1689774094000,
        "value": 139.528,
        "_internal_originalTime": 1689774094000
    },
    {
        "time": 1689774095000,
        "value": 139.522,
        "_internal_originalTime": 1689774095000
    },
    {
        "time": 1689774096000,
        "value": 139.528,
        "_internal_originalTime": 1689774096000
    },
    {
        "time": 1689774097000,
        "value": 139.53,
        "_internal_originalTime": 1689774097000
    },
    {
        "time": 1689774098000,
        "value": 139.531,
        "_internal_originalTime": 1689774098000
    },
    {
        "time": 1689774099000,
        "value": 139.522,
        "_internal_originalTime": 1689774099000
    },
    {
        "time": 1689774100000,
        "value": 139.526,
        "_internal_originalTime": 1689774100000
    },
    {
        "time": 1689774101000,
        "value": 139.522,
        "_internal_originalTime": 1689774101000
    },
    {
        "time": 1689774102000,
        "value": 139.523,
        "_internal_originalTime": 1689774102000
    },
    {
        "time": 1689774103000,
        "value": 139.523,
        "_internal_originalTime": 1689774103000
    },
    {
        "time": 1689774105000,
        "value": 139.52,
        "_internal_originalTime": 1689774105000
    },
    {
        "time": 1689774106000,
        "value": 139.52,
        "_internal_originalTime": 1689774106000
    },
    {
        "time": 1689774107000,
        "value": 139.524,
        "_internal_originalTime": 1689774107000
    },
    {
        "time": 1689774108000,
        "value": 139.523,
        "_internal_originalTime": 1689774108000
    },
    {
        "time": 1689774109000,
        "value": 139.518,
        "_internal_originalTime": 1689774109000
    },
    {
        "time": 1689774111000,
        "value": 139.525,
        "_internal_originalTime": 1689774111000
    },
    {
        "time": 1689774112000,
        "value": 139.519,
        "_internal_originalTime": 1689774112000
    },
    {
        "time": 1689774113000,
        "value": 139.523,
        "_internal_originalTime": 1689774113000
    },
    {
        "time": 1689774114000,
        "value": 139.52,
        "_internal_originalTime": 1689774114000
    },
    {
        "time": 1689774116000,
        "value": 139.522,
        "_internal_originalTime": 1689774116000
    },
    {
        "time": 1689774117000,
        "value": 139.528,
        "_internal_originalTime": 1689774117000
    },
    {
        "time": 1689774119000,
        "value": 139.529,
        "_internal_originalTime": 1689774119000
    },
    {
        "time": 1689774120000,
        "value": 139.527,
        "_internal_originalTime": 1689774120000
    },
    {
        "time": 1689774122000,
        "value": 139.529,
        "_internal_originalTime": 1689774122000
    },
    {
        "time": 1689774123000,
        "value": 139.527,
        "_internal_originalTime": 1689774123000
    },
    {
        "time": 1689774124000,
        "value": 139.535,
        "_internal_originalTime": 1689774124000
    },
    {
        "time": 1689774125000,
        "value": 139.534,
        "_internal_originalTime": 1689774125000
    },
    {
        "time": 1689774126000,
        "value": 139.533,
        "_internal_originalTime": 1689774126000
    },
    {
        "time": 1689774128000,
        "value": 139.536,
        "_internal_originalTime": 1689774128000
    },
    {
        "time": 1689774129000,
        "value": 139.55,
        "_internal_originalTime": 1689774129000
    },
    {
        "time": 1689774130000,
        "value": 139.553,
        "_internal_originalTime": 1689774130000
    },
    {
        "time": 1689774132000,
        "value": 139.552,
        "_internal_originalTime": 1689774132000
    },
    {
        "time": 1689774133000,
        "value": 139.552,
        "_internal_originalTime": 1689774133000
    },
    {
        "time": 1689774135000,
        "value": 139.551,
        "_internal_originalTime": 1689774135000
    },
    {
        "time": 1689774137000,
        "value": 139.559,
        "_internal_originalTime": 1689774137000
    },
    {
        "time": 1689774138000,
        "value": 139.556,
        "_internal_originalTime": 1689774138000
    },
    {
        "time": 1689774140000,
        "value": 139.555,
        "_internal_originalTime": 1689774140000
    },
    {
        "time": 1689774141000,
        "value": 139.553,
        "_internal_originalTime": 1689774141000
    },
    {
        "time": 1689774166000,
        "value": 139.536,
        "_internal_originalTime": 1689774166000
    },
    {
        "time": 1689774168000,
        "value": 139.529,
        "_internal_originalTime": 1689774168000
    },
    {
        "time": 1689774169000,
        "value": 139.53,
        "_internal_originalTime": 1689774169000
    },
    {
        "time": 1689774171000,
        "value": 139.528,
        "_internal_originalTime": 1689774171000
    },
    {
        "time": 1689774172000,
        "value": 139.523,
        "_internal_originalTime": 1689774172000
    },
    {
        "time": 1689774174000,
        "value": 139.527,
        "_internal_originalTime": 1689774174000
    },
    {
        "time": 1689774175000,
        "value": 139.527,
        "_internal_originalTime": 1689774175000
    },
    {
        "time": 1689774176000,
        "value": 139.524,
        "_internal_originalTime": 1689774176000
    },
    {
        "time": 1689774178000,
        "value": 139.528,
        "_internal_originalTime": 1689774178000
    },
    {
        "time": 1689774179000,
        "value": 139.533,
        "_internal_originalTime": 1689774179000
    },
    {
        "time": 1689774180000,
        "value": 139.532,
        "_internal_originalTime": 1689774180000
    },
    {
        "time": 1689774181000,
        "value": 139.533,
        "_internal_originalTime": 1689774181000
    },
    {
        "time": 1689774183000,
        "value": 139.539,
        "_internal_originalTime": 1689774183000
    },
    {
        "time": 1689774184000,
        "value": 139.538,
        "_internal_originalTime": 1689774184000
    },
    {
        "time": 1689774185000,
        "value": 139.542,
        "_internal_originalTime": 1689774185000
    },
    {
        "time": 1689774186000,
        "value": 139.541,
        "_internal_originalTime": 1689774186000
    },
    {
        "time": 1689774188000,
        "value": 139.551,
        "_internal_originalTime": 1689774188000
    },
    {
        "time": 1689774189000,
        "value": 139.551,
        "_internal_originalTime": 1689774189000
    },
    {
        "time": 1689774190000,
        "value": 139.554,
        "_internal_originalTime": 1689774190000
    },
    {
        "time": 1689774191000,
        "value": 139.561,
        "_internal_originalTime": 1689774191000
    },
    {
        "time": 1689774192000,
        "value": 139.562,
        "_internal_originalTime": 1689774192000
    },
    {
        "time": 1689774193000,
        "value": 139.557,
        "_internal_originalTime": 1689774193000
    },
    {
        "time": 1689774195000,
        "value": 139.562,
        "_internal_originalTime": 1689774195000
    },
    {
        "time": 1689774196000,
        "value": 139.558,
        "_internal_originalTime": 1689774196000
    },
    {
        "time": 1689774197000,
        "value": 139.56,
        "_internal_originalTime": 1689774197000
    },
    {
        "time": 1689774199000,
        "value": 139.562,
        "_internal_originalTime": 1689774199000
    },
    {
        "time": 1689774201000,
        "value": 139.562,
        "_internal_originalTime": 1689774201000
    },
    {
        "time": 1689774203000,
        "value": 139.562,
        "_internal_originalTime": 1689774203000
    },
    {
        "time": 1689774204000,
        "value": 139.563,
        "_internal_originalTime": 1689774204000
    },
    {
        "time": 1689774206000,
        "value": 139.574,
        "_internal_originalTime": 1689774206000
    },
    {
        "time": 1689774207000,
        "value": 139.573,
        "_internal_originalTime": 1689774207000
    },
    {
        "time": 1689774208000,
        "value": 139.576,
        "_internal_originalTime": 1689774208000
    },
    {
        "time": 1689774210000,
        "value": 139.576,
        "_internal_originalTime": 1689774210000
    },
    {
        "time": 1689774212000,
        "value": 139.589,
        "_internal_originalTime": 1689774212000
    },
    {
        "time": 1689774214000,
        "value": 139.58,
        "_internal_originalTime": 1689774214000
    },
    {
        "time": 1689774215000,
        "value": 139.58,
        "_internal_originalTime": 1689774215000
    },
    {
        "time": 1689774216000,
        "value": 139.584,
        "_internal_originalTime": 1689774216000
    },
    {
        "time": 1689774218000,
        "value": 139.579,
        "_internal_originalTime": 1689774218000
    },
    {
        "time": 1689774219000,
        "value": 139.586,
        "_internal_originalTime": 1689774219000
    },
    {
        "time": 1689774221000,
        "value": 139.588,
        "_internal_originalTime": 1689774221000
    },
    {
        "time": 1689774222000,
        "value": 139.589,
        "_internal_originalTime": 1689774222000
    },
    {
        "time": 1689774225000,
        "value": 139.594,
        "_internal_originalTime": 1689774225000
    },
    {
        "time": 1689774226000,
        "value": 139.6,
        "_internal_originalTime": 1689774226000
    },
    {
        "time": 1689774227000,
        "value": 139.604,
        "_internal_originalTime": 1689774227000
    },
    {
        "time": 1689774228000,
        "value": 139.599,
        "_internal_originalTime": 1689774228000
    },
    {
        "time": 1689774230000,
        "value": 139.594,
        "_internal_originalTime": 1689774230000
    },
    {
        "time": 1689774232000,
        "value": 139.593,
        "_internal_originalTime": 1689774232000
    },
    {
        "time": 1689774233000,
        "value": 139.592,
        "_internal_originalTime": 1689774233000
    },
    {
        "time": 1689774234000,
        "value": 139.592,
        "_internal_originalTime": 1689774234000
    },
    {
        "time": 1689774236000,
        "value": 139.59,
        "_internal_originalTime": 1689774236000
    },
    {
        "time": 1689774237000,
        "value": 139.585,
        "_internal_originalTime": 1689774237000
    },
    {
        "time": 1689774238000,
        "value": 139.585,
        "_internal_originalTime": 1689774238000
    },
    {
        "time": 1689774239000,
        "value": 139.585,
        "_internal_originalTime": 1689774239000
    },
    {
        "time": 1689774240000,
        "value": 139.582,
        "_internal_originalTime": 1689774240000
    },
    {
        "time": 1689774241000,
        "value": 139.582,
        "_internal_originalTime": 1689774241000
    },
    {
        "time": 1689774242000,
        "value": 139.586,
        "_internal_originalTime": 1689774242000
    },
    {
        "time": 1689774243000,
        "value": 139.596,
        "_internal_originalTime": 1689774243000
    },
    {
        "time": 1689774244000,
        "value": 139.597,
        "_internal_originalTime": 1689774244000
    },
    {
        "time": 1689774245000,
        "value": 139.593,
        "_internal_originalTime": 1689774245000
    },
    {
        "time": 1689774246000,
        "value": 139.595,
        "_internal_originalTime": 1689774246000
    },
    {
        "time": 1689774247000,
        "value": 139.59,
        "_internal_originalTime": 1689774247000
    },
    {
        "time": 1689774248000,
        "value": 139.597,
        "_internal_originalTime": 1689774248000
    },
    {
        "time": 1689774250000,
        "value": 139.595,
        "_internal_originalTime": 1689774250000
    },
    {
        "time": 1689774251000,
        "value": 139.594,
        "_internal_originalTime": 1689774251000
    },
    {
        "time": 1689774252000,
        "value": 139.597,
        "_internal_originalTime": 1689774252000
    },
    {
        "time": 1689774254000,
        "value": 139.614,
        "_internal_originalTime": 1689774254000
    },
    {
        "time": 1689774255000,
        "value": 139.617,
        "_internal_originalTime": 1689774255000
    },
    {
        "time": 1689774256000,
        "value": 139.62,
        "_internal_originalTime": 1689774256000
    },
    {
        "time": 1689774258000,
        "value": 139.616,
        "_internal_originalTime": 1689774258000
    },
    {
        "time": 1689774260000,
        "value": 139.615,
        "_internal_originalTime": 1689774260000
    },
    {
        "time": 1689774261000,
        "value": 139.612,
        "_internal_originalTime": 1689774261000
    },
    {
        "time": 1689774262000,
        "value": 139.599,
        "_internal_originalTime": 1689774262000
    },
    {
        "time": 1689774263000,
        "value": 139.599,
        "_internal_originalTime": 1689774263000
    },
    {
        "time": 1689774264000,
        "value": 139.598,
        "_internal_originalTime": 1689774264000
    },
    {
        "time": 1689774265000,
        "value": 139.599,
        "_internal_originalTime": 1689774265000
    },
    {
        "time": 1689774267000,
        "value": 139.613,
        "_internal_originalTime": 1689774267000
    },
    {
        "time": 1689774268000,
        "value": 139.611,
        "_internal_originalTime": 1689774268000
    },
    {
        "time": 1689774269000,
        "value": 139.615,
        "_internal_originalTime": 1689774269000
    },
    {
        "time": 1689774270000,
        "value": 139.612,
        "_internal_originalTime": 1689774270000
    },
    {
        "time": 1689774271000,
        "value": 139.61,
        "_internal_originalTime": 1689774271000
    },
    {
        "time": 1689774272000,
        "value": 139.613,
        "_internal_originalTime": 1689774272000
    },
    {
        "time": 1689774273000,
        "value": 139.615,
        "_internal_originalTime": 1689774273000
    },
    {
        "time": 1689774274000,
        "value": 139.613,
        "_internal_originalTime": 1689774274000
    },
    {
        "time": 1689774275000,
        "value": 139.612,
        "_internal_originalTime": 1689774275000
    },
    {
        "time": 1689774276000,
        "value": 139.613,
        "_internal_originalTime": 1689774276000
    },
    {
        "time": 1689774277000,
        "value": 139.61,
        "_internal_originalTime": 1689774277000
    },
    {
        "time": 1689774279000,
        "value": 139.612,
        "_internal_originalTime": 1689774279000
    },
    {
        "time": 1689774281000,
        "value": 139.612,
        "_internal_originalTime": 1689774281000
    },
    {
        "time": 1689774282000,
        "value": 139.608,
        "_internal_originalTime": 1689774282000
    },
    {
        "time": 1689774283000,
        "value": 139.609,
        "_internal_originalTime": 1689774283000
    },
    {
        "time": 1689774284000,
        "value": 139.62,
        "_internal_originalTime": 1689774284000
    },
    {
        "time": 1689774285000,
        "value": 139.617,
        "_internal_originalTime": 1689774285000
    },
    {
        "time": 1689774286000,
        "value": 139.622,
        "_internal_originalTime": 1689774286000
    },
    {
        "time": 1689774287000,
        "value": 139.621,
        "_internal_originalTime": 1689774287000
    },
    {
        "time": 1689774288000,
        "value": 139.618,
        "_internal_originalTime": 1689774288000
    },
    {
        "time": 1689774289000,
        "value": 139.621,
        "_internal_originalTime": 1689774289000
    },
    {
        "time": 1689774290000,
        "value": 139.626,
        "_internal_originalTime": 1689774290000
    },
    {
        "time": 1689774291000,
        "value": 139.63,
        "_internal_originalTime": 1689774291000
    },
    {
        "time": 1689774293000,
        "value": 139.63,
        "_internal_originalTime": 1689774293000
    },
    {
        "time": 1689774294000,
        "value": 139.636,
        "_internal_originalTime": 1689774294000
    },
    {
        "time": 1689774295000,
        "value": 139.637,
        "_internal_originalTime": 1689774295000
    },
    {
        "time": 1689774296000,
        "value": 139.633,
        "_internal_originalTime": 1689774296000
    },
    {
        "time": 1689774297000,
        "value": 139.631,
        "_internal_originalTime": 1689774297000
    },
    {
        "time": 1689774299000,
        "value": 139.635,
        "_internal_originalTime": 1689774299000
    },
    {
        "time": 1689774300000,
        "value": 139.639,
        "_internal_originalTime": 1689774300000
    },
    {
        "time": 1689774301000,
        "value": 139.64,
        "_internal_originalTime": 1689774301000
    },
    {
        "time": 1689774302000,
        "value": 139.638,
        "_internal_originalTime": 1689774302000
    },
    {
        "time": 1689774303000,
        "value": 139.638,
        "_internal_originalTime": 1689774303000
    },
    {
        "time": 1689774304000,
        "value": 139.639,
        "_internal_originalTime": 1689774304000
    },
    {
        "time": 1689774305000,
        "value": 139.639,
        "_internal_originalTime": 1689774305000
    },
    {
        "time": 1689774306000,
        "value": 139.634,
        "_internal_originalTime": 1689774306000
    },
    {
        "time": 1689774308000,
        "value": 139.635,
        "_internal_originalTime": 1689774308000
    },
    {
        "time": 1689774310000,
        "value": 139.633,
        "_internal_originalTime": 1689774310000
    },
    {
        "time": 1689774311000,
        "value": 139.639,
        "_internal_originalTime": 1689774311000
    },
    {
        "time": 1689774312000,
        "value": 139.639,
        "_internal_originalTime": 1689774312000
    },
    {
        "time": 1689774313000,
        "value": 139.637,
        "_internal_originalTime": 1689774313000
    },
    {
        "time": 1689774314000,
        "value": 139.639,
        "_internal_originalTime": 1689774314000
    },
    {
        "time": 1689774315000,
        "value": 139.641,
        "_internal_originalTime": 1689774315000
    },
    {
        "time": 1689774316000,
        "value": 139.636,
        "_internal_originalTime": 1689774316000
    },
    {
        "time": 1689774317000,
        "value": 139.639,
        "_internal_originalTime": 1689774317000
    },
    {
        "time": 1689774318000,
        "value": 139.648,
        "_internal_originalTime": 1689774318000
    },
    {
        "time": 1689774319000,
        "value": 139.649,
        "_internal_originalTime": 1689774319000
    },
    {
        "time": 1689774320000,
        "value": 139.653,
        "_internal_originalTime": 1689774320000
    },
    {
        "time": 1689774321000,
        "value": 139.653,
        "_internal_originalTime": 1689774321000
    },
    {
        "time": 1689774322000,
        "value": 139.648,
        "_internal_originalTime": 1689774322000
    },
    {
        "time": 1689774323000,
        "value": 139.649,
        "_internal_originalTime": 1689774323000
    },
    {
        "time": 1689774324000,
        "value": 139.654,
        "_internal_originalTime": 1689774324000
    },
    {
        "time": 1689774325000,
        "value": 139.659,
        "_internal_originalTime": 1689774325000
    },
    {
        "time": 1689774326000,
        "value": 139.659,
        "_internal_originalTime": 1689774326000
    },
    {
        "time": 1689774327000,
        "value": 139.658,
        "_internal_originalTime": 1689774327000
    },
    {
        "time": 1689774328000,
        "value": 139.656,
        "_internal_originalTime": 1689774328000
    },
    {
        "time": 1689774329000,
        "value": 139.649,
        "_internal_originalTime": 1689774329000
    },
    {
        "time": 1689774330000,
        "value": 139.65,
        "_internal_originalTime": 1689774330000
    },
    {
        "time": 1689774331000,
        "value": 139.652,
        "_internal_originalTime": 1689774331000
    },
    {
        "time": 1689774332000,
        "value": 139.658,
        "_internal_originalTime": 1689774332000
    },
    {
        "time": 1689774333000,
        "value": 139.656,
        "_internal_originalTime": 1689774333000
    },
    {
        "time": 1689774334000,
        "value": 139.652,
        "_internal_originalTime": 1689774334000
    },
    {
        "time": 1689774335000,
        "value": 139.648,
        "_internal_originalTime": 1689774335000
    },
    {
        "time": 1689774336000,
        "value": 139.649,
        "_internal_originalTime": 1689774336000
    },
    {
        "time": 1689774337000,
        "value": 139.647,
        "_internal_originalTime": 1689774337000
    },
    {
        "time": 1689774338000,
        "value": 139.646,
        "_internal_originalTime": 1689774338000
    },
    {
        "time": 1689774339000,
        "value": 139.645,
        "_internal_originalTime": 1689774339000
    },
    {
        "time": 1689774340000,
        "value": 139.649,
        "_internal_originalTime": 1689774340000
    },
    {
        "time": 1689774341000,
        "value": 139.653,
        "_internal_originalTime": 1689774341000
    },
    {
        "time": 1689774342000,
        "value": 139.655,
        "_internal_originalTime": 1689774342000
    },
    {
        "time": 1689774343000,
        "value": 139.652,
        "_internal_originalTime": 1689774343000
    },
    {
        "time": 1689774344000,
        "value": 139.655,
        "_internal_originalTime": 1689774344000
    },
    {
        "time": 1689774345000,
        "value": 139.655,
        "_internal_originalTime": 1689774345000
    },
    {
        "time": 1689774346000,
        "value": 139.653,
        "_internal_originalTime": 1689774346000
    },
    {
        "time": 1689774347000,
        "value": 139.659,
        "_internal_originalTime": 1689774347000
    },
    {
        "time": 1689774348000,
        "value": 139.657,
        "_internal_originalTime": 1689774348000
    },
    {
        "time": 1689774349000,
        "value": 139.659,
        "_internal_originalTime": 1689774349000
    },
    {
        "time": 1689774350000,
        "value": 139.657,
        "_internal_originalTime": 1689774350000
    },
    {
        "time": 1689774351000,
        "value": 139.658,
        "_internal_originalTime": 1689774351000
    },
    {
        "time": 1689774352000,
        "value": 139.656,
        "_internal_originalTime": 1689774352000
    },
    {
        "time": 1689774353000,
        "value": 139.658,
        "_internal_originalTime": 1689774353000
    },
    {
        "time": 1689774354000,
        "value": 139.66,
        "_internal_originalTime": 1689774354000
    },
    {
        "time": 1689774355000,
        "value": 139.656,
        "_internal_originalTime": 1689774355000
    },
    {
        "time": 1689774356000,
        "value": 139.663,
        "_internal_originalTime": 1689774356000
    },
    {
        "time": 1689774358000,
        "value": 139.667,
        "_internal_originalTime": 1689774358000
    },
    {
        "time": 1689774360000,
        "value": 139.667,
        "_internal_originalTime": 1689774360000
    },
    {
        "time": 1689774363000,
        "value": 139.667,
        "_internal_originalTime": 1689774363000
    },
    {
        "time": 1689774364000,
        "value": 139.662,
        "_internal_originalTime": 1689774364000
    },
    {
        "time": 1689774369000,
        "value": 139.669,
        "_internal_originalTime": 1689774369000
    },
    {
        "time": 1689774373000,
        "value": 139.673,
        "_internal_originalTime": 1689774373000
    },
    {
        "time": 1689774374000,
        "value": 139.667,
        "_internal_originalTime": 1689774374000
    },
    {
        "time": 1689774376000,
        "value": 139.673,
        "_internal_originalTime": 1689774376000
    },
    {
        "time": 1689774377000,
        "value": 139.671,
        "_internal_originalTime": 1689774377000
    },
    {
        "time": 1689774378000,
        "value": 139.67,
        "_internal_originalTime": 1689774378000
    },
    {
        "time": 1689774379000,
        "value": 139.667,
        "_internal_originalTime": 1689774379000
    },
    {
        "time": 1689774380000,
        "value": 139.673,
        "_internal_originalTime": 1689774380000
    },
    {
        "time": 1689774382000,
        "value": 139.672,
        "_internal_originalTime": 1689774382000
    },
    {
        "time": 1689774383000,
        "value": 139.672,
        "_internal_originalTime": 1689774383000
    },
    {
        "time": 1689774384000,
        "value": 139.674,
        "_internal_originalTime": 1689774384000
    },
    {
        "time": 1689774385000,
        "value": 139.684,
        "_internal_originalTime": 1689774385000
    },
    {
        "time": 1689774386000,
        "value": 139.699,
        "_internal_originalTime": 1689774386000
    },
    {
        "time": 1689774387000,
        "value": 139.704,
        "_internal_originalTime": 1689774387000
    },
    {
        "time": 1689774388000,
        "value": 139.702,
        "_internal_originalTime": 1689774388000
    },
    {
        "time": 1689774389000,
        "value": 139.703,
        "_internal_originalTime": 1689774389000
    },
    {
        "time": 1689774390000,
        "value": 139.702,
        "_internal_originalTime": 1689774390000
    },
    {
        "time": 1689774391000,
        "value": 139.702,
        "_internal_originalTime": 1689774391000
    },
    {
        "time": 1689774392000,
        "value": 139.7,
        "_internal_originalTime": 1689774392000
    },
    {
        "time": 1689774394000,
        "value": 139.709,
        "_internal_originalTime": 1689774394000
    },
    {
        "time": 1689774395000,
        "value": 139.699,
        "_internal_originalTime": 1689774395000
    },
    {
        "time": 1689774396000,
        "value": 139.7,
        "_internal_originalTime": 1689774396000
    },
    {
        "time": 1689774398000,
        "value": 139.705,
        "_internal_originalTime": 1689774398000
    },
    {
        "time": 1689774399000,
        "value": 139.707,
        "_internal_originalTime": 1689774399000
    },
    {
        "time": 1689774400000,
        "value": 139.713,
        "_internal_originalTime": 1689774400000
    },
    {
        "time": 1689774401000,
        "value": 139.71,
        "_internal_originalTime": 1689774401000
    },
    {
        "time": 1689774402000,
        "value": 139.697,
        "_internal_originalTime": 1689774402000
    },
    {
        "time": 1689774403000,
        "value": 139.701,
        "_internal_originalTime": 1689774403000
    },
    {
        "time": 1689774405000,
        "value": 139.712,
        "_internal_originalTime": 1689774405000
    },
    {
        "time": 1689774406000,
        "value": 139.732,
        "_internal_originalTime": 1689774406000
    },
    {
        "time": 1689774408000,
        "value": 139.735,
        "_internal_originalTime": 1689774408000
    },
    {
        "time": 1689774409000,
        "value": 139.734,
        "_internal_originalTime": 1689774409000
    },
    {
        "time": 1689774410000,
        "value": 139.735,
        "_internal_originalTime": 1689774410000
    },
    {
        "time": 1689774411000,
        "value": 139.743,
        "_internal_originalTime": 1689774411000
    },
    {
        "time": 1689774466000,
        "value": 139.77,
        "_internal_originalTime": 1689774466000
    },
    {
        "time": 1689774467000,
        "value": 139.771,
        "_internal_originalTime": 1689774467000
    },
    {
        "time": 1689774468000,
        "value": 139.773,
        "_internal_originalTime": 1689774468000
    },
    {
        "time": 1689774469000,
        "value": 139.773,
        "_internal_originalTime": 1689774469000
    },
    {
        "time": 1689774470000,
        "value": 139.773,
        "_internal_originalTime": 1689774470000
    },
    {
        "time": 1689774471000,
        "value": 139.773,
        "_internal_originalTime": 1689774471000
    },
    {
        "time": 1689774472000,
        "value": 139.775,
        "_internal_originalTime": 1689774472000
    },
    {
        "time": 1689774473000,
        "value": 139.776,
        "_internal_originalTime": 1689774473000
    },
    {
        "time": 1689774475000,
        "value": 139.77,
        "_internal_originalTime": 1689774475000
    },
    {
        "time": 1689774476000,
        "value": 139.765,
        "_internal_originalTime": 1689774476000
    },
    {
        "time": 1689774477000,
        "value": 139.773,
        "_internal_originalTime": 1689774477000
    },
    {
        "time": 1689774478000,
        "value": 139.778,
        "_internal_originalTime": 1689774478000
    },
    {
        "time": 1689774479000,
        "value": 139.774,
        "_internal_originalTime": 1689774479000
    },
    {
        "time": 1689774480000,
        "value": 139.773,
        "_internal_originalTime": 1689774480000
    },
    {
        "time": 1689774481000,
        "value": 139.78,
        "_internal_originalTime": 1689774481000
    },
    {
        "time": 1689774482000,
        "value": 139.784,
        "_internal_originalTime": 1689774482000
    },
    {
        "time": 1689774483000,
        "value": 139.785,
        "_internal_originalTime": 1689774483000
    },
    {
        "time": 1689774484000,
        "value": 139.788,
        "_internal_originalTime": 1689774484000
    },
    {
        "time": 1689774485000,
        "value": 139.785,
        "_internal_originalTime": 1689774485000
    },
    {
        "time": 1689774486000,
        "value": 139.784,
        "_internal_originalTime": 1689774486000
    },
    {
        "time": 1689774487000,
        "value": 139.782,
        "_internal_originalTime": 1689774487000
    },
    {
        "time": 1689774488000,
        "value": 139.78,
        "_internal_originalTime": 1689774488000
    },
    {
        "time": 1689774490000,
        "value": 139.782,
        "_internal_originalTime": 1689774490000
    },
    {
        "time": 1689774491000,
        "value": 139.775,
        "_internal_originalTime": 1689774491000
    },
    {
        "time": 1689774492000,
        "value": 139.788,
        "_internal_originalTime": 1689774492000
    },
    {
        "time": 1689774493000,
        "value": 139.783,
        "_internal_originalTime": 1689774493000
    },
    {
        "time": 1689774494000,
        "value": 139.782,
        "_internal_originalTime": 1689774494000
    },
    {
        "time": 1689774495000,
        "value": 139.782,
        "_internal_originalTime": 1689774495000
    },
    {
        "time": 1689774496000,
        "value": 139.776,
        "_internal_originalTime": 1689774496000
    },
    {
        "time": 1689774497000,
        "value": 139.776,
        "_internal_originalTime": 1689774497000
    },
    {
        "time": 1689774498000,
        "value": 139.778,
        "_internal_originalTime": 1689774498000
    },
    {
        "time": 1689774500000,
        "value": 139.779,
        "_internal_originalTime": 1689774500000
    },
    {
        "time": 1689774501000,
        "value": 139.775,
        "_internal_originalTime": 1689774501000
    },
    {
        "time": 1689774502000,
        "value": 139.78,
        "_internal_originalTime": 1689774502000
    },
    {
        "time": 1689774503000,
        "value": 139.781,
        "_internal_originalTime": 1689774503000
    },
    {
        "time": 1689774505000,
        "value": 139.779,
        "_internal_originalTime": 1689774505000
    },
    {
        "time": 1689774506000,
        "value": 139.77,
        "_internal_originalTime": 1689774506000
    },
    {
        "time": 1689774508000,
        "value": 139.785,
        "_internal_originalTime": 1689774508000
    },
    {
        "time": 1689774509000,
        "value": 139.782,
        "_internal_originalTime": 1689774509000
    },
    {
        "time": 1689774511000,
        "value": 139.783,
        "_internal_originalTime": 1689774511000
    },
    {
        "time": 1689774512000,
        "value": 139.752,
        "_internal_originalTime": 1689774512000
    },
    {
        "time": 1689774514000,
        "value": 139.756,
        "_internal_originalTime": 1689774514000
    },
    {
        "time": 1689774516000,
        "value": 139.742,
        "_internal_originalTime": 1689774516000
    },
    {
        "time": 1689774517000,
        "value": 139.741,
        "_internal_originalTime": 1689774517000
    },
    {
        "time": 1689774518000,
        "value": 139.738,
        "_internal_originalTime": 1689774518000
    },
    {
        "time": 1689774519000,
        "value": 139.736,
        "_internal_originalTime": 1689774519000
    },
    {
        "time": 1689774520000,
        "value": 139.741,
        "_internal_originalTime": 1689774520000
    },
    {
        "time": 1689774521000,
        "value": 139.743,
        "_internal_originalTime": 1689774521000
    },
    {
        "time": 1689774522000,
        "value": 139.738,
        "_internal_originalTime": 1689774522000
    },
    {
        "time": 1689774523000,
        "value": 139.743,
        "_internal_originalTime": 1689774523000
    },
    {
        "time": 1689774525000,
        "value": 139.747,
        "_internal_originalTime": 1689774525000
    },
    {
        "time": 1689774526000,
        "value": 139.745,
        "_internal_originalTime": 1689774526000
    },
    {
        "time": 1689774527000,
        "value": 139.75,
        "_internal_originalTime": 1689774527000
    },
    {
        "time": 1689774529000,
        "value": 139.753,
        "_internal_originalTime": 1689774529000
    },
    {
        "time": 1689774530000,
        "value": 139.733,
        "_internal_originalTime": 1689774530000
    },
    {
        "time": 1689774531000,
        "value": 139.73,
        "_internal_originalTime": 1689774531000
    },
    {
        "time": 1689774532000,
        "value": 139.734,
        "_internal_originalTime": 1689774532000
    },
    {
        "time": 1689774533000,
        "value": 139.738,
        "_internal_originalTime": 1689774533000
    },
    {
        "time": 1689774534000,
        "value": 139.739,
        "_internal_originalTime": 1689774534000
    },
    {
        "time": 1689774535000,
        "value": 139.739,
        "_internal_originalTime": 1689774535000
    },
    {
        "time": 1689774536000,
        "value": 139.736,
        "_internal_originalTime": 1689774536000
    },
    {
        "time": 1689774537000,
        "value": 139.737,
        "_internal_originalTime": 1689774537000
    },
    {
        "time": 1689774538000,
        "value": 139.737,
        "_internal_originalTime": 1689774538000
    },
    {
        "time": 1689774540000,
        "value": 139.744,
        "_internal_originalTime": 1689774540000
    },
    {
        "time": 1689774542000,
        "value": 139.76,
        "_internal_originalTime": 1689774542000
    },
    {
        "time": 1689774543000,
        "value": 139.764,
        "_internal_originalTime": 1689774543000
    },
    {
        "time": 1689774544000,
        "value": 139.762,
        "_internal_originalTime": 1689774544000
    },
    {
        "time": 1689774545000,
        "value": 139.762,
        "_internal_originalTime": 1689774545000
    },
    {
        "time": 1689774546000,
        "value": 139.763,
        "_internal_originalTime": 1689774546000
    },
    {
        "time": 1689774548000,
        "value": 139.759,
        "_internal_originalTime": 1689774548000
    },
    {
        "time": 1689774549000,
        "value": 139.756,
        "_internal_originalTime": 1689774549000
    },
    {
        "time": 1689774550000,
        "value": 139.758,
        "_internal_originalTime": 1689774550000
    },
    {
        "time": 1689774552000,
        "value": 139.744,
        "_internal_originalTime": 1689774552000
    },
    {
        "time": 1689774553000,
        "value": 139.744,
        "_internal_originalTime": 1689774553000
    },
    {
        "time": 1689774554000,
        "value": 139.75,
        "_internal_originalTime": 1689774554000
    },
    {
        "time": 1689774555000,
        "value": 139.751,
        "_internal_originalTime": 1689774555000
    },
    {
        "time": 1689774556000,
        "value": 139.754,
        "_internal_originalTime": 1689774556000
    },
    {
        "time": 1689774557000,
        "value": 139.754,
        "_internal_originalTime": 1689774557000
    },
    {
        "time": 1689774558000,
        "value": 139.751,
        "_internal_originalTime": 1689774558000
    },
    {
        "time": 1689774559000,
        "value": 139.753,
        "_internal_originalTime": 1689774559000
    },
    {
        "time": 1689774560000,
        "value": 139.75,
        "_internal_originalTime": 1689774560000
    },
    {
        "time": 1689774561000,
        "value": 139.765,
        "_internal_originalTime": 1689774561000
    },
    {
        "time": 1689774563000,
        "value": 139.756,
        "_internal_originalTime": 1689774563000
    },
    {
        "time": 1689774565000,
        "value": 139.756,
        "_internal_originalTime": 1689774565000
    },
    {
        "time": 1689774567000,
        "value": 139.758,
        "_internal_originalTime": 1689774567000
    },
    {
        "time": 1689774568000,
        "value": 139.754,
        "_internal_originalTime": 1689774568000
    },
    {
        "time": 1689774569000,
        "value": 139.753,
        "_internal_originalTime": 1689774569000
    },
    {
        "time": 1689774570000,
        "value": 139.754,
        "_internal_originalTime": 1689774570000
    },
    {
        "time": 1689774571000,
        "value": 139.749,
        "_internal_originalTime": 1689774571000
    },
    {
        "time": 1689774572000,
        "value": 139.747,
        "_internal_originalTime": 1689774572000
    },
    {
        "time": 1689774573000,
        "value": 139.747,
        "_internal_originalTime": 1689774573000
    },
    {
        "time": 1689774574000,
        "value": 139.751,
        "_internal_originalTime": 1689774574000
    },
    {
        "time": 1689774576000,
        "value": 139.752,
        "_internal_originalTime": 1689774576000
    },
    {
        "time": 1689774577000,
        "value": 139.755,
        "_internal_originalTime": 1689774577000
    },
    {
        "time": 1689774578000,
        "value": 139.752,
        "_internal_originalTime": 1689774578000
    },
    {
        "time": 1689774579000,
        "value": 139.75,
        "_internal_originalTime": 1689774579000
    },
    {
        "time": 1689774580000,
        "value": 139.75,
        "_internal_originalTime": 1689774580000
    },
    {
        "time": 1689774581000,
        "value": 139.753,
        "_internal_originalTime": 1689774581000
    },
    {
        "time": 1689774582000,
        "value": 139.749,
        "_internal_originalTime": 1689774582000
    },
    {
        "time": 1689774583000,
        "value": 139.746,
        "_internal_originalTime": 1689774583000
    },
    {
        "time": 1689774584000,
        "value": 139.748,
        "_internal_originalTime": 1689774584000
    },
    {
        "time": 1689774587000,
        "value": 139.748,
        "_internal_originalTime": 1689774587000
    },
    {
        "time": 1689774589000,
        "value": 139.744,
        "_internal_originalTime": 1689774589000
    },
    {
        "time": 1689774590000,
        "value": 139.74,
        "_internal_originalTime": 1689774590000
    },
    {
        "time": 1689774591000,
        "value": 139.74,
        "_internal_originalTime": 1689774591000
    },
    {
        "time": 1689774593000,
        "value": 139.741,
        "_internal_originalTime": 1689774593000
    },
    {
        "time": 1689774594000,
        "value": 139.745,
        "_internal_originalTime": 1689774594000
    },
    {
        "time": 1689774595000,
        "value": 139.746,
        "_internal_originalTime": 1689774595000
    },
    {
        "time": 1689774596000,
        "value": 139.746,
        "_internal_originalTime": 1689774596000
    },
    {
        "time": 1689774598000,
        "value": 139.745,
        "_internal_originalTime": 1689774598000
    },
    {
        "time": 1689774599000,
        "value": 139.74,
        "_internal_originalTime": 1689774599000
    },
    {
        "time": 1689774601000,
        "value": 139.737,
        "_internal_originalTime": 1689774601000
    },
    {
        "time": 1689774602000,
        "value": 139.735,
        "_internal_originalTime": 1689774602000
    },
    {
        "time": 1689774603000,
        "value": 139.735,
        "_internal_originalTime": 1689774603000
    },
    {
        "time": 1689774604000,
        "value": 139.734,
        "_internal_originalTime": 1689774604000
    },
    {
        "time": 1689774605000,
        "value": 139.734,
        "_internal_originalTime": 1689774605000
    },
    {
        "time": 1689774607000,
        "value": 139.734,
        "_internal_originalTime": 1689774607000
    },
    {
        "time": 1689774608000,
        "value": 139.734,
        "_internal_originalTime": 1689774608000
    },
    {
        "time": 1689774609000,
        "value": 139.739,
        "_internal_originalTime": 1689774609000
    },
    {
        "time": 1689774610000,
        "value": 139.741,
        "_internal_originalTime": 1689774610000
    },
    {
        "time": 1689774611000,
        "value": 139.744,
        "_internal_originalTime": 1689774611000
    },
    {
        "time": 1689774612000,
        "value": 139.736,
        "_internal_originalTime": 1689774612000
    },
    {
        "time": 1689774613000,
        "value": 139.739,
        "_internal_originalTime": 1689774613000
    },
    {
        "time": 1689774614000,
        "value": 139.733,
        "_internal_originalTime": 1689774614000
    },
    {
        "time": 1689774616000,
        "value": 139.74,
        "_internal_originalTime": 1689774616000
    },
    {
        "time": 1689774617000,
        "value": 139.743,
        "_internal_originalTime": 1689774617000
    },
    {
        "time": 1689774619000,
        "value": 139.741,
        "_internal_originalTime": 1689774619000
    },
    {
        "time": 1689774620000,
        "value": 139.736,
        "_internal_originalTime": 1689774620000
    },
    {
        "time": 1689774622000,
        "value": 139.738,
        "_internal_originalTime": 1689774622000
    },
    {
        "time": 1689774623000,
        "value": 139.735,
        "_internal_originalTime": 1689774623000
    },
    {
        "time": 1689774624000,
        "value": 139.738,
        "_internal_originalTime": 1689774624000
    },
    {
        "time": 1689774626000,
        "value": 139.743,
        "_internal_originalTime": 1689774626000
    },
    {
        "time": 1689774627000,
        "value": 139.751,
        "_internal_originalTime": 1689774627000
    },
    {
        "time": 1689774629000,
        "value": 139.75,
        "_internal_originalTime": 1689774629000
    },
    {
        "time": 1689774631000,
        "value": 139.744,
        "_internal_originalTime": 1689774631000
    },
    {
        "time": 1689774632000,
        "value": 139.75,
        "_internal_originalTime": 1689774632000
    },
    {
        "time": 1689774633000,
        "value": 139.755,
        "_internal_originalTime": 1689774633000
    },
    {
        "time": 1689774634000,
        "value": 139.754,
        "_internal_originalTime": 1689774634000
    },
    {
        "time": 1689774635000,
        "value": 139.757,
        "_internal_originalTime": 1689774635000
    },
    {
        "time": 1689774636000,
        "value": 139.754,
        "_internal_originalTime": 1689774636000
    },
    {
        "time": 1689774638000,
        "value": 139.747,
        "_internal_originalTime": 1689774638000
    },
    {
        "time": 1689774639000,
        "value": 139.745,
        "_internal_originalTime": 1689774639000
    },
    {
        "time": 1689774640000,
        "value": 139.746,
        "_internal_originalTime": 1689774640000
    },
    {
        "time": 1689774641000,
        "value": 139.754,
        "_internal_originalTime": 1689774641000
    },
    {
        "time": 1689774642000,
        "value": 139.744,
        "_internal_originalTime": 1689774642000
    },
    {
        "time": 1689774644000,
        "value": 139.746,
        "_internal_originalTime": 1689774644000
    },
    {
        "time": 1689774645000,
        "value": 139.746,
        "_internal_originalTime": 1689774645000
    },
    {
        "time": 1689774646000,
        "value": 139.744,
        "_internal_originalTime": 1689774646000
    },
    {
        "time": 1689774647000,
        "value": 139.747,
        "_internal_originalTime": 1689774647000
    },
    {
        "time": 1689774648000,
        "value": 139.745,
        "_internal_originalTime": 1689774648000
    },
    {
        "time": 1689774650000,
        "value": 139.744,
        "_internal_originalTime": 1689774650000
    },
    {
        "time": 1689774651000,
        "value": 139.744,
        "_internal_originalTime": 1689774651000
    },
    {
        "time": 1689774653000,
        "value": 139.745,
        "_internal_originalTime": 1689774653000
    },
    {
        "time": 1689774654000,
        "value": 139.737,
        "_internal_originalTime": 1689774654000
    },
    {
        "time": 1689774656000,
        "value": 139.733,
        "_internal_originalTime": 1689774656000
    },
    {
        "time": 1689774657000,
        "value": 139.73,
        "_internal_originalTime": 1689774657000
    },
    {
        "time": 1689774659000,
        "value": 139.734,
        "_internal_originalTime": 1689774659000
    },
    {
        "time": 1689774660000,
        "value": 139.735,
        "_internal_originalTime": 1689774660000
    },
    {
        "time": 1689774661000,
        "value": 139.734,
        "_internal_originalTime": 1689774661000
    },
    {
        "time": 1689774662000,
        "value": 139.739,
        "_internal_originalTime": 1689774662000
    },
    {
        "time": 1689774663000,
        "value": 139.741,
        "_internal_originalTime": 1689774663000
    },
    {
        "time": 1689774664000,
        "value": 139.743,
        "_internal_originalTime": 1689774664000
    },
    {
        "time": 1689774666000,
        "value": 139.734,
        "_internal_originalTime": 1689774666000
    },
    {
        "time": 1689774667000,
        "value": 139.73,
        "_internal_originalTime": 1689774667000
    },
    {
        "time": 1689774668000,
        "value": 139.732,
        "_internal_originalTime": 1689774668000
    },
    {
        "time": 1689774670000,
        "value": 139.734,
        "_internal_originalTime": 1689774670000
    },
    {
        "time": 1689774671000,
        "value": 139.735,
        "_internal_originalTime": 1689774671000
    },
    {
        "time": 1689774673000,
        "value": 139.738,
        "_internal_originalTime": 1689774673000
    },
    {
        "time": 1689774675000,
        "value": 139.737,
        "_internal_originalTime": 1689774675000
    },
    {
        "time": 1689774677000,
        "value": 139.737,
        "_internal_originalTime": 1689774677000
    },
    {
        "time": 1689774678000,
        "value": 139.734,
        "_internal_originalTime": 1689774678000
    },
    {
        "time": 1689774679000,
        "value": 139.73,
        "_internal_originalTime": 1689774679000
    },
    {
        "time": 1689774680000,
        "value": 139.723,
        "_internal_originalTime": 1689774680000
    },
    {
        "time": 1689774681000,
        "value": 139.72,
        "_internal_originalTime": 1689774681000
    },
    {
        "time": 1689774683000,
        "value": 139.71,
        "_internal_originalTime": 1689774683000
    },
    {
        "time": 1689774684000,
        "value": 139.712,
        "_internal_originalTime": 1689774684000
    },
    {
        "time": 1689774686000,
        "value": 139.699,
        "_internal_originalTime": 1689774686000
    },
    {
        "time": 1689774687000,
        "value": 139.703,
        "_internal_originalTime": 1689774687000
    },
    {
        "time": 1689774688000,
        "value": 139.703,
        "_internal_originalTime": 1689774688000
    },
    {
        "time": 1689774689000,
        "value": 139.698,
        "_internal_originalTime": 1689774689000
    },
    {
        "time": 1689774690000,
        "value": 139.698,
        "_internal_originalTime": 1689774690000
    },
    {
        "time": 1689774691000,
        "value": 139.699,
        "_internal_originalTime": 1689774691000
    },
    {
        "time": 1689774692000,
        "value": 139.699,
        "_internal_originalTime": 1689774692000
    },
    {
        "time": 1689774694000,
        "value": 139.693,
        "_internal_originalTime": 1689774694000
    },
    {
        "time": 1689774695000,
        "value": 139.699,
        "_internal_originalTime": 1689774695000
    },
    {
        "time": 1689774697000,
        "value": 139.702,
        "_internal_originalTime": 1689774697000
    },
    {
        "time": 1689774698000,
        "value": 139.701,
        "_internal_originalTime": 1689774698000
    },
    {
        "time": 1689774699000,
        "value": 139.705,
        "_internal_originalTime": 1689774699000
    },
    {
        "time": 1689774701000,
        "value": 139.694,
        "_internal_originalTime": 1689774701000
    },
    {
        "time": 1689774702000,
        "value": 139.698,
        "_internal_originalTime": 1689774702000
    },
    {
        "time": 1689774703000,
        "value": 139.69,
        "_internal_originalTime": 1689774703000
    },
    {
        "time": 1689774705000,
        "value": 139.679,
        "_internal_originalTime": 1689774705000
    },
    {
        "time": 1689774706000,
        "value": 139.68,
        "_internal_originalTime": 1689774706000
    },
    {
        "time": 1689774707000,
        "value": 139.684,
        "_internal_originalTime": 1689774707000
    },
    {
        "time": 1689774708000,
        "value": 139.685,
        "_internal_originalTime": 1689774708000
    },
    {
        "time": 1689774710000,
        "value": 139.695,
        "_internal_originalTime": 1689774710000
    },
    {
        "time": 1689774711000,
        "value": 139.694,
        "_internal_originalTime": 1689774711000
    },
    {
        "time": 1689774713000,
        "value": 139.698,
        "_internal_originalTime": 1689774713000
    },
    {
        "time": 1689774714000,
        "value": 139.696,
        "_internal_originalTime": 1689774714000
    },
    {
        "time": 1689774716000,
        "value": 139.7,
        "_internal_originalTime": 1689774716000
    },
    {
        "time": 1689774719000,
        "value": 139.704,
        "_internal_originalTime": 1689774719000
    },
    {
        "time": 1689774720000,
        "value": 139.704,
        "_internal_originalTime": 1689774720000
    },
    {
        "time": 1689774721000,
        "value": 139.68,
        "_internal_originalTime": 1689774721000
    },
    {
        "time": 1689774723000,
        "value": 139.705,
        "_internal_originalTime": 1689774723000
    },
    {
        "time": 1689774724000,
        "value": 139.702,
        "_internal_originalTime": 1689774724000
    },
    {
        "time": 1689774726000,
        "value": 139.704,
        "_internal_originalTime": 1689774726000
    },
    {
        "time": 1689774727000,
        "value": 139.706,
        "_internal_originalTime": 1689774727000
    },
    {
        "time": 1689774729000,
        "value": 139.705,
        "_internal_originalTime": 1689774729000
    },
    {
        "time": 1689774730000,
        "value": 139.687,
        "_internal_originalTime": 1689774730000
    },
    {
        "time": 1689774731000,
        "value": 139.687,
        "_internal_originalTime": 1689774731000
    },
    {
        "time": 1689774733000,
        "value": 139.694,
        "_internal_originalTime": 1689774733000
    },
    {
        "time": 1689774734000,
        "value": 139.694,
        "_internal_originalTime": 1689774734000
    },
    {
        "time": 1689774735000,
        "value": 139.696,
        "_internal_originalTime": 1689774735000
    },
    {
        "time": 1689774736000,
        "value": 139.692,
        "_internal_originalTime": 1689774736000
    },
    {
        "time": 1689774738000,
        "value": 139.693,
        "_internal_originalTime": 1689774738000
    },
    {
        "time": 1689774740000,
        "value": 139.699,
        "_internal_originalTime": 1689774740000
    },
    {
        "time": 1689774741000,
        "value": 139.7,
        "_internal_originalTime": 1689774741000
    },
    {
        "time": 1689774742000,
        "value": 139.697,
        "_internal_originalTime": 1689774742000
    },
    {
        "time": 1689774744000,
        "value": 139.692,
        "_internal_originalTime": 1689774744000
    },
    {
        "time": 1689774745000,
        "value": 139.696,
        "_internal_originalTime": 1689774745000
    },
    {
        "time": 1689774746000,
        "value": 139.696,
        "_internal_originalTime": 1689774746000
    },
    {
        "time": 1689774748000,
        "value": 139.696,
        "_internal_originalTime": 1689774748000
    },
    {
        "time": 1689774750000,
        "value": 139.694,
        "_internal_originalTime": 1689774750000
    },
    {
        "time": 1689774751000,
        "value": 139.694,
        "_internal_originalTime": 1689774751000
    },
    {
        "time": 1689774753000,
        "value": 139.694,
        "_internal_originalTime": 1689774753000
    },
    {
        "time": 1689774802000,
        "value": 139.701,
        "_internal_originalTime": 1689774802000
    },
    {
        "time": 1689774803000,
        "value": 139.704,
        "_internal_originalTime": 1689774803000
    },
    {
        "time": 1689774804000,
        "value": 139.7,
        "_internal_originalTime": 1689774804000
    },
    {
        "time": 1689774806000,
        "value": 139.7,
        "_internal_originalTime": 1689774806000
    },
    {
        "time": 1689774807000,
        "value": 139.7,
        "_internal_originalTime": 1689774807000
    },
    {
        "time": 1689774809000,
        "value": 139.701,
        "_internal_originalTime": 1689774809000
    },
    {
        "time": 1689774810000,
        "value": 139.698,
        "_internal_originalTime": 1689774810000
    },
    {
        "time": 1689774812000,
        "value": 139.697,
        "_internal_originalTime": 1689774812000
    },
    {
        "time": 1689774813000,
        "value": 139.701,
        "_internal_originalTime": 1689774813000
    },
    {
        "time": 1689774815000,
        "value": 139.702,
        "_internal_originalTime": 1689774815000
    },
    {
        "time": 1689774816000,
        "value": 139.698,
        "_internal_originalTime": 1689774816000
    },
    {
        "time": 1689774817000,
        "value": 139.703,
        "_internal_originalTime": 1689774817000
    },
    {
        "time": 1689774818000,
        "value": 139.701,
        "_internal_originalTime": 1689774818000
    },
    {
        "time": 1689774819000,
        "value": 139.702,
        "_internal_originalTime": 1689774819000
    },
    {
        "time": 1689774820000,
        "value": 139.701,
        "_internal_originalTime": 1689774820000
    },
    {
        "time": 1689774822000,
        "value": 139.693,
        "_internal_originalTime": 1689774822000
    },
    {
        "time": 1689774823000,
        "value": 139.694,
        "_internal_originalTime": 1689774823000
    },
    {
        "time": 1689774825000,
        "value": 139.695,
        "_internal_originalTime": 1689774825000
    },
    {
        "time": 1689774827000,
        "value": 139.692,
        "_internal_originalTime": 1689774827000
    },
    {
        "time": 1689774829000,
        "value": 139.69,
        "_internal_originalTime": 1689774829000
    },
    {
        "time": 1689774830000,
        "value": 139.691,
        "_internal_originalTime": 1689774830000
    },
    {
        "time": 1689774831000,
        "value": 139.683,
        "_internal_originalTime": 1689774831000
    },
    {
        "time": 1689774832000,
        "value": 139.685,
        "_internal_originalTime": 1689774832000
    },
    {
        "time": 1689774834000,
        "value": 139.684,
        "_internal_originalTime": 1689774834000
    },
    {
        "time": 1689774836000,
        "value": 139.683,
        "_internal_originalTime": 1689774836000
    },
    {
        "time": 1689774838000,
        "value": 139.677,
        "_internal_originalTime": 1689774838000
    },
    {
        "time": 1689774839000,
        "value": 139.676,
        "_internal_originalTime": 1689774839000
    },
    {
        "time": 1689774840000,
        "value": 139.681,
        "_internal_originalTime": 1689774840000
    },
    {
        "time": 1689774841000,
        "value": 139.66,
        "_internal_originalTime": 1689774841000
    },
    {
        "time": 1689774842000,
        "value": 139.676,
        "_internal_originalTime": 1689774842000
    },
    {
        "time": 1689774844000,
        "value": 139.676,
        "_internal_originalTime": 1689774844000
    },
    {
        "time": 1689774845000,
        "value": 139.678,
        "_internal_originalTime": 1689774845000
    },
    {
        "time": 1689774846000,
        "value": 139.683,
        "_internal_originalTime": 1689774846000
    },
    {
        "time": 1689774847000,
        "value": 139.68,
        "_internal_originalTime": 1689774847000
    },
    {
        "time": 1689774848000,
        "value": 139.682,
        "_internal_originalTime": 1689774848000
    },
    {
        "time": 1689774849000,
        "value": 139.683,
        "_internal_originalTime": 1689774849000
    },
    {
        "time": 1689774850000,
        "value": 139.673,
        "_internal_originalTime": 1689774850000
    },
    {
        "time": 1689774851000,
        "value": 139.674,
        "_internal_originalTime": 1689774851000
    },
    {
        "time": 1689774852000,
        "value": 139.671,
        "_internal_originalTime": 1689774852000
    },
    {
        "time": 1689774853000,
        "value": 139.666,
        "_internal_originalTime": 1689774853000
    },
    {
        "time": 1689774855000,
        "value": 139.671,
        "_internal_originalTime": 1689774855000
    },
    {
        "time": 1689774856000,
        "value": 139.666,
        "_internal_originalTime": 1689774856000
    },
    {
        "time": 1689774857000,
        "value": 139.668,
        "_internal_originalTime": 1689774857000
    },
    {
        "time": 1689774858000,
        "value": 139.669,
        "_internal_originalTime": 1689774858000
    },
    {
        "time": 1689774860000,
        "value": 139.67,
        "_internal_originalTime": 1689774860000
    },
    {
        "time": 1689774861000,
        "value": 139.672,
        "_internal_originalTime": 1689774861000
    },
    {
        "time": 1689774863000,
        "value": 139.666,
        "_internal_originalTime": 1689774863000
    },
    {
        "time": 1689774864000,
        "value": 139.669,
        "_internal_originalTime": 1689774864000
    },
    {
        "time": 1689774865000,
        "value": 139.668,
        "_internal_originalTime": 1689774865000
    },
    {
        "time": 1689774866000,
        "value": 139.669,
        "_internal_originalTime": 1689774866000
    },
    {
        "time": 1689774868000,
        "value": 139.679,
        "_internal_originalTime": 1689774868000
    },
    {
        "time": 1689774869000,
        "value": 139.682,
        "_internal_originalTime": 1689774869000
    },
    {
        "time": 1689774870000,
        "value": 139.682,
        "_internal_originalTime": 1689774870000
    },
    {
        "time": 1689774872000,
        "value": 139.68,
        "_internal_originalTime": 1689774872000
    },
    {
        "time": 1689774873000,
        "value": 139.679,
        "_internal_originalTime": 1689774873000
    },
    {
        "time": 1689774874000,
        "value": 139.676,
        "_internal_originalTime": 1689774874000
    },
    {
        "time": 1689774875000,
        "value": 139.68,
        "_internal_originalTime": 1689774875000
    },
    {
        "time": 1689774876000,
        "value": 139.68,
        "_internal_originalTime": 1689774876000
    },
    {
        "time": 1689774877000,
        "value": 139.69,
        "_internal_originalTime": 1689774877000
    },
    {
        "time": 1689774878000,
        "value": 139.7,
        "_internal_originalTime": 1689774878000
    },
    {
        "time": 1689774879000,
        "value": 139.703,
        "_internal_originalTime": 1689774879000
    },
    {
        "time": 1689774880000,
        "value": 139.704,
        "_internal_originalTime": 1689774880000
    },
    {
        "time": 1689774881000,
        "value": 139.699,
        "_internal_originalTime": 1689774881000
    },
    {
        "time": 1689774883000,
        "value": 139.701,
        "_internal_originalTime": 1689774883000
    },
    {
        "time": 1689774884000,
        "value": 139.698,
        "_internal_originalTime": 1689774884000
    },
    {
        "time": 1689774886000,
        "value": 139.698,
        "_internal_originalTime": 1689774886000
    },
    {
        "time": 1689774887000,
        "value": 139.696,
        "_internal_originalTime": 1689774887000
    },
    {
        "time": 1689774888000,
        "value": 139.696,
        "_internal_originalTime": 1689774888000
    },
    {
        "time": 1689774889000,
        "value": 139.692,
        "_internal_originalTime": 1689774889000
    },
    {
        "time": 1689774891000,
        "value": 139.698,
        "_internal_originalTime": 1689774891000
    },
    {
        "time": 1689774892000,
        "value": 139.695,
        "_internal_originalTime": 1689774892000
    },
    {
        "time": 1689774893000,
        "value": 139.697,
        "_internal_originalTime": 1689774893000
    },
    {
        "time": 1689774894000,
        "value": 139.7,
        "_internal_originalTime": 1689774894000
    },
    {
        "time": 1689774896000,
        "value": 139.696,
        "_internal_originalTime": 1689774896000
    },
    {
        "time": 1689774897000,
        "value": 139.699,
        "_internal_originalTime": 1689774897000
    },
    {
        "time": 1689774898000,
        "value": 139.697,
        "_internal_originalTime": 1689774898000
    },
    {
        "time": 1689774899000,
        "value": 139.696,
        "_internal_originalTime": 1689774899000
    },
    {
        "time": 1689774900000,
        "value": 139.697,
        "_internal_originalTime": 1689774900000
    },
    {
        "time": 1689774901000,
        "value": 139.694,
        "_internal_originalTime": 1689774901000
    },
    {
        "time": 1689774903000,
        "value": 139.696,
        "_internal_originalTime": 1689774903000
    },
    {
        "time": 1689774904000,
        "value": 139.69,
        "_internal_originalTime": 1689774904000
    },
    {
        "time": 1689774905000,
        "value": 139.692,
        "_internal_originalTime": 1689774905000
    },
    {
        "time": 1689774906000,
        "value": 139.69,
        "_internal_originalTime": 1689774906000
    },
    {
        "time": 1689774907000,
        "value": 139.698,
        "_internal_originalTime": 1689774907000
    },
    {
        "time": 1689774908000,
        "value": 139.698,
        "_internal_originalTime": 1689774908000
    },
    {
        "time": 1689774909000,
        "value": 139.697,
        "_internal_originalTime": 1689774909000
    },
    {
        "time": 1689774910000,
        "value": 139.698,
        "_internal_originalTime": 1689774910000
    },
    {
        "time": 1689774912000,
        "value": 139.702,
        "_internal_originalTime": 1689774912000
    },
    {
        "time": 1689774913000,
        "value": 139.702,
        "_internal_originalTime": 1689774913000
    },
    {
        "time": 1689774915000,
        "value": 139.705,
        "_internal_originalTime": 1689774915000
    },
    {
        "time": 1689774916000,
        "value": 139.702,
        "_internal_originalTime": 1689774916000
    },
    {
        "time": 1689774917000,
        "value": 139.705,
        "_internal_originalTime": 1689774917000
    },
    {
        "time": 1689774919000,
        "value": 139.713,
        "_internal_originalTime": 1689774919000
    },
    {
        "time": 1689774920000,
        "value": 139.712,
        "_internal_originalTime": 1689774920000
    },
    {
        "time": 1689774922000,
        "value": 139.712,
        "_internal_originalTime": 1689774922000
    },
    {
        "time": 1689774923000,
        "value": 139.71,
        "_internal_originalTime": 1689774923000
    },
    {
        "time": 1689774925000,
        "value": 139.715,
        "_internal_originalTime": 1689774925000
    },
    {
        "time": 1689774926000,
        "value": 139.713,
        "_internal_originalTime": 1689774926000
    },
    {
        "time": 1689774927000,
        "value": 139.713,
        "_internal_originalTime": 1689774927000
    },
    {
        "time": 1689774929000,
        "value": 139.714,
        "_internal_originalTime": 1689774929000
    },
    {
        "time": 1689774930000,
        "value": 139.715,
        "_internal_originalTime": 1689774930000
    },
    {
        "time": 1689774931000,
        "value": 139.713,
        "_internal_originalTime": 1689774931000
    },
    {
        "time": 1689774932000,
        "value": 139.71,
        "_internal_originalTime": 1689774932000
    },
    {
        "time": 1689774933000,
        "value": 139.711,
        "_internal_originalTime": 1689774933000
    },
    {
        "time": 1689774934000,
        "value": 139.71,
        "_internal_originalTime": 1689774934000
    },
    {
        "time": 1689774935000,
        "value": 139.711,
        "_internal_originalTime": 1689774935000
    },
    {
        "time": 1689774936000,
        "value": 139.706,
        "_internal_originalTime": 1689774936000
    },
    {
        "time": 1689774938000,
        "value": 139.711,
        "_internal_originalTime": 1689774938000
    },
    {
        "time": 1689774939000,
        "value": 139.71,
        "_internal_originalTime": 1689774939000
    },
    {
        "time": 1689774941000,
        "value": 139.712,
        "_internal_originalTime": 1689774941000
    },
    {
        "time": 1689774942000,
        "value": 139.709,
        "_internal_originalTime": 1689774942000
    },
    {
        "time": 1689774943000,
        "value": 139.71,
        "_internal_originalTime": 1689774943000
    },
    {
        "time": 1689774944000,
        "value": 139.712,
        "_internal_originalTime": 1689774944000
    },
    {
        "time": 1689774945000,
        "value": 139.713,
        "_internal_originalTime": 1689774945000
    },
    {
        "time": 1689774946000,
        "value": 139.708,
        "_internal_originalTime": 1689774946000
    },
    {
        "time": 1689774947000,
        "value": 139.699,
        "_internal_originalTime": 1689774947000
    },
    {
        "time": 1689774948000,
        "value": 139.7,
        "_internal_originalTime": 1689774948000
    },
    {
        "time": 1689774949000,
        "value": 139.694,
        "_internal_originalTime": 1689774949000
    },
    {
        "time": 1689774950000,
        "value": 139.698,
        "_internal_originalTime": 1689774950000
    },
    {
        "time": 1689774952000,
        "value": 139.694,
        "_internal_originalTime": 1689774952000
    },
    {
        "time": 1689774953000,
        "value": 139.688,
        "_internal_originalTime": 1689774953000
    },
    {
        "time": 1689774954000,
        "value": 139.689,
        "_internal_originalTime": 1689774954000
    },
    {
        "time": 1689774955000,
        "value": 139.68,
        "_internal_originalTime": 1689774955000
    },
    {
        "time": 1689774956000,
        "value": 139.681,
        "_internal_originalTime": 1689774956000
    },
    {
        "time": 1689774957000,
        "value": 139.676,
        "_internal_originalTime": 1689774957000
    },
    {
        "time": 1689774958000,
        "value": 139.679,
        "_internal_originalTime": 1689774958000
    },
    {
        "time": 1689774959000,
        "value": 139.687,
        "_internal_originalTime": 1689774959000
    },
    {
        "time": 1689774960000,
        "value": 139.66,
        "_internal_originalTime": 1689774960000
    },
    {
        "time": 1689774961000,
        "value": 139.699,
        "_internal_originalTime": 1689774961000
    },
    {
        "time": 1689774962000,
        "value": 139.708,
        "_internal_originalTime": 1689774962000
    },
    {
        "time": 1689774963000,
        "value": 139.708,
        "_internal_originalTime": 1689774963000
    },
    {
        "time": 1689774964000,
        "value": 139.709,
        "_internal_originalTime": 1689774964000
    },
    {
        "time": 1689774965000,
        "value": 139.712,
        "_internal_originalTime": 1689774965000
    },
    {
        "time": 1689774966000,
        "value": 139.712,
        "_internal_originalTime": 1689774966000
    },
    {
        "time": 1689774967000,
        "value": 139.709,
        "_internal_originalTime": 1689774967000
    },
    {
        "time": 1689774968000,
        "value": 139.709,
        "_internal_originalTime": 1689774968000
    },
    {
        "time": 1689774969000,
        "value": 139.71,
        "_internal_originalTime": 1689774969000
    },
    {
        "time": 1689774970000,
        "value": 139.712,
        "_internal_originalTime": 1689774970000
    },
    {
        "time": 1689774972000,
        "value": 139.706,
        "_internal_originalTime": 1689774972000
    },
    {
        "time": 1689774975000,
        "value": 139.71,
        "_internal_originalTime": 1689774975000
    },
    {
        "time": 1689774976000,
        "value": 139.713,
        "_internal_originalTime": 1689774976000
    },
    {
        "time": 1689774977000,
        "value": 139.717,
        "_internal_originalTime": 1689774977000
    },
    {
        "time": 1689774979000,
        "value": 139.716,
        "_internal_originalTime": 1689774979000
    },
    {
        "time": 1689774980000,
        "value": 139.719,
        "_internal_originalTime": 1689774980000
    },
    {
        "time": 1689774981000,
        "value": 139.715,
        "_internal_originalTime": 1689774981000
    },
    {
        "time": 1689774982000,
        "value": 139.71,
        "_internal_originalTime": 1689774982000
    },
    {
        "time": 1689774983000,
        "value": 139.715,
        "_internal_originalTime": 1689774983000
    },
    {
        "time": 1689774984000,
        "value": 139.713,
        "_internal_originalTime": 1689774984000
    },
    {
        "time": 1689774986000,
        "value": 139.71,
        "_internal_originalTime": 1689774986000
    },
    {
        "time": 1689774988000,
        "value": 139.716,
        "_internal_originalTime": 1689774988000
    },
    {
        "time": 1689774990000,
        "value": 139.715,
        "_internal_originalTime": 1689774990000
    },
    {
        "time": 1689774991000,
        "value": 139.714,
        "_internal_originalTime": 1689774991000
    },
    {
        "time": 1689774992000,
        "value": 139.716,
        "_internal_originalTime": 1689774992000
    },
    {
        "time": 1689774993000,
        "value": 139.713,
        "_internal_originalTime": 1689774993000
    },
    {
        "time": 1689774994000,
        "value": 139.715,
        "_internal_originalTime": 1689774994000
    },
    {
        "time": 1689774995000,
        "value": 139.707,
        "_internal_originalTime": 1689774995000
    },
    {
        "time": 1689774996000,
        "value": 139.71,
        "_internal_originalTime": 1689774996000
    },
    {
        "time": 1689774997000,
        "value": 139.71,
        "_internal_originalTime": 1689774997000
    },
    {
        "time": 1689774998000,
        "value": 139.701,
        "_internal_originalTime": 1689774998000
    },
    {
        "time": 1689774999000,
        "value": 139.705,
        "_internal_originalTime": 1689774999000
    },
    {
        "time": 1689775001000,
        "value": 139.698,
        "_internal_originalTime": 1689775001000
    },
    {
        "time": 1689775002000,
        "value": 139.697,
        "_internal_originalTime": 1689775002000
    },
    {
        "time": 1689775003000,
        "value": 139.699,
        "_internal_originalTime": 1689775003000
    },
    {
        "time": 1689775004000,
        "value": 139.695,
        "_internal_originalTime": 1689775004000
    },
    {
        "time": 1689775005000,
        "value": 139.698,
        "_internal_originalTime": 1689775005000
    },
    {
        "time": 1689775006000,
        "value": 139.696,
        "_internal_originalTime": 1689775006000
    },
    {
        "time": 1689775007000,
        "value": 139.703,
        "_internal_originalTime": 1689775007000
    },
    {
        "time": 1689775008000,
        "value": 139.704,
        "_internal_originalTime": 1689775008000
    },
    {
        "time": 1689775009000,
        "value": 139.7,
        "_internal_originalTime": 1689775009000
    },
    {
        "time": 1689775010000,
        "value": 139.699,
        "_internal_originalTime": 1689775010000
    },
    {
        "time": 1689775011000,
        "value": 139.7,
        "_internal_originalTime": 1689775011000
    },
    {
        "time": 1689775012000,
        "value": 139.697,
        "_internal_originalTime": 1689775012000
    },
    {
        "time": 1689775013000,
        "value": 139.695,
        "_internal_originalTime": 1689775013000
    },
    {
        "time": 1689775014000,
        "value": 139.69,
        "_internal_originalTime": 1689775014000
    },
    {
        "time": 1689775015000,
        "value": 139.69,
        "_internal_originalTime": 1689775015000
    },
    {
        "time": 1689775016000,
        "value": 139.691,
        "_internal_originalTime": 1689775016000
    },
    {
        "time": 1689775017000,
        "value": 139.69,
        "_internal_originalTime": 1689775017000
    },
    {
        "time": 1689775018000,
        "value": 139.686,
        "_internal_originalTime": 1689775018000
    },
    {
        "time": 1689775019000,
        "value": 139.685,
        "_internal_originalTime": 1689775019000
    },
    {
        "time": 1689775020000,
        "value": 139.688,
        "_internal_originalTime": 1689775020000
    },
    {
        "time": 1689775022000,
        "value": 139.692,
        "_internal_originalTime": 1689775022000
    },
    {
        "time": 1689775023000,
        "value": 139.693,
        "_internal_originalTime": 1689775023000
    },
    {
        "time": 1689775024000,
        "value": 139.691,
        "_internal_originalTime": 1689775024000
    },
    {
        "time": 1689775025000,
        "value": 139.688,
        "_internal_originalTime": 1689775025000
    },
    {
        "time": 1689775026000,
        "value": 139.687,
        "_internal_originalTime": 1689775026000
    },
    {
        "time": 1689775028000,
        "value": 139.687,
        "_internal_originalTime": 1689775028000
    },
    {
        "time": 1689775029000,
        "value": 139.692,
        "_internal_originalTime": 1689775029000
    },
    {
        "time": 1689775030000,
        "value": 139.691,
        "_internal_originalTime": 1689775030000
    },
    {
        "time": 1689775031000,
        "value": 139.692,
        "_internal_originalTime": 1689775031000
    },
    {
        "time": 1689775032000,
        "value": 139.687,
        "_internal_originalTime": 1689775032000
    },
    {
        "time": 1689775034000,
        "value": 139.687,
        "_internal_originalTime": 1689775034000
    },
    {
        "time": 1689775035000,
        "value": 139.691,
        "_internal_originalTime": 1689775035000
    },
    {
        "time": 1689775036000,
        "value": 139.688,
        "_internal_originalTime": 1689775036000
    },
    {
        "time": 1689775037000,
        "value": 139.689,
        "_internal_originalTime": 1689775037000
    },
    {
        "time": 1689775038000,
        "value": 139.69,
        "_internal_originalTime": 1689775038000
    },
    {
        "time": 1689775040000,
        "value": 139.694,
        "_internal_originalTime": 1689775040000
    },
    {
        "time": 1689775041000,
        "value": 139.693,
        "_internal_originalTime": 1689775041000
    },
    {
        "time": 1689775042000,
        "value": 139.693,
        "_internal_originalTime": 1689775042000
    },
    {
        "time": 1689775044000,
        "value": 139.69,
        "_internal_originalTime": 1689775044000
    },
    {
        "time": 1689775045000,
        "value": 139.685,
        "_internal_originalTime": 1689775045000
    },
    {
        "time": 1689775046000,
        "value": 139.684,
        "_internal_originalTime": 1689775046000
    },
    {
        "time": 1689775047000,
        "value": 139.684,
        "_internal_originalTime": 1689775047000
    },
    {
        "time": 1689775048000,
        "value": 139.685,
        "_internal_originalTime": 1689775048000
    },
    {
        "time": 1689775049000,
        "value": 139.688,
        "_internal_originalTime": 1689775049000
    },
    {
        "time": 1689775050000,
        "value": 139.689,
        "_internal_originalTime": 1689775050000
    },
    {
        "time": 1689775051000,
        "value": 139.686,
        "_internal_originalTime": 1689775051000
    },
    {
        "time": 1689775053000,
        "value": 139.685,
        "_internal_originalTime": 1689775053000
    },
    {
        "time": 1689775054000,
        "value": 139.682,
        "_internal_originalTime": 1689775054000
    },
    {
        "time": 1689775055000,
        "value": 139.676,
        "_internal_originalTime": 1689775055000
    },
    {
        "time": 1689775056000,
        "value": 139.675,
        "_internal_originalTime": 1689775056000
    },
    {
        "time": 1689775057000,
        "value": 139.679,
        "_internal_originalTime": 1689775057000
    },
    {
        "time": 1689775058000,
        "value": 139.678,
        "_internal_originalTime": 1689775058000
    },
    {
        "time": 1689775059000,
        "value": 139.677,
        "_internal_originalTime": 1689775059000
    },
    {
        "time": 1689775060000,
        "value": 139.672,
        "_internal_originalTime": 1689775060000
    },
    {
        "time": 1689775061000,
        "value": 139.676,
        "_internal_originalTime": 1689775061000
    },
    {
        "time": 1689775062000,
        "value": 139.678,
        "_internal_originalTime": 1689775062000
    },
    {
        "time": 1689775063000,
        "value": 139.672,
        "_internal_originalTime": 1689775063000
    },
    {
        "time": 1689775064000,
        "value": 139.676,
        "_internal_originalTime": 1689775064000
    },
    {
        "time": 1689775065000,
        "value": 139.676,
        "_internal_originalTime": 1689775065000
    },
    {
        "time": 1689775067000,
        "value": 139.676,
        "_internal_originalTime": 1689775067000
    },
    {
        "time": 1689775068000,
        "value": 139.676,
        "_internal_originalTime": 1689775068000
    },
    {
        "time": 1689775069000,
        "value": 139.677,
        "_internal_originalTime": 1689775069000
    },
    {
        "time": 1689775071000,
        "value": 139.674,
        "_internal_originalTime": 1689775071000
    },
    {
        "time": 1689775072000,
        "value": 139.677,
        "_internal_originalTime": 1689775072000
    },
    {
        "time": 1689775073000,
        "value": 139.665,
        "_internal_originalTime": 1689775073000
    },
    {
        "time": 1689775074000,
        "value": 139.669,
        "_internal_originalTime": 1689775074000
    },
    {
        "time": 1689775075000,
        "value": 139.67,
        "_internal_originalTime": 1689775075000
    },
    {
        "time": 1689775077000,
        "value": 139.666,
        "_internal_originalTime": 1689775077000
    },
    {
        "time": 1689775078000,
        "value": 139.662,
        "_internal_originalTime": 1689775078000
    },
    {
        "time": 1689775079000,
        "value": 139.665,
        "_internal_originalTime": 1689775079000
    },
    {
        "time": 1689775080000,
        "value": 139.669,
        "_internal_originalTime": 1689775080000
    },
    {
        "time": 1689775081000,
        "value": 139.67,
        "_internal_originalTime": 1689775081000
    },
    {
        "time": 1689775082000,
        "value": 139.671,
        "_internal_originalTime": 1689775082000
    },
    {
        "time": 1689775083000,
        "value": 139.667,
        "_internal_originalTime": 1689775083000
    },
    {
        "time": 1689775084000,
        "value": 139.67,
        "_internal_originalTime": 1689775084000
    },
    {
        "time": 1689775085000,
        "value": 139.664,
        "_internal_originalTime": 1689775085000
    },
    {
        "time": 1689775086000,
        "value": 139.664,
        "_internal_originalTime": 1689775086000
    },
    {
        "time": 1689775087000,
        "value": 139.653,
        "_internal_originalTime": 1689775087000
    },
    {
        "time": 1689775088000,
        "value": 139.653,
        "_internal_originalTime": 1689775088000
    },
    {
        "time": 1689775089000,
        "value": 139.653,
        "_internal_originalTime": 1689775089000
    },
    {
        "time": 1689775090000,
        "value": 139.655,
        "_internal_originalTime": 1689775090000
    },
    {
        "time": 1689775091000,
        "value": 139.654,
        "_internal_originalTime": 1689775091000
    },
    {
        "time": 1689775093000,
        "value": 139.659,
        "_internal_originalTime": 1689775093000
    },
    {
        "time": 1689775094000,
        "value": 139.66,
        "_internal_originalTime": 1689775094000
    },
    {
        "time": 1689775095000,
        "value": 139.656,
        "_internal_originalTime": 1689775095000
    },
    {
        "time": 1689775096000,
        "value": 139.654,
        "_internal_originalTime": 1689775096000
    },
    {
        "time": 1689775097000,
        "value": 139.654,
        "_internal_originalTime": 1689775097000
    },
    {
        "time": 1689775098000,
        "value": 139.656,
        "_internal_originalTime": 1689775098000
    },
    {
        "time": 1689775099000,
        "value": 139.654,
        "_internal_originalTime": 1689775099000
    },
    {
        "time": 1689775100000,
        "value": 139.651,
        "_internal_originalTime": 1689775100000
    },
    {
        "time": 1689775101000,
        "value": 139.66,
        "_internal_originalTime": 1689775101000
    },
    {
        "time": 1689775102000,
        "value": 139.655,
        "_internal_originalTime": 1689775102000
    },
    {
        "time": 1689775103000,
        "value": 139.669,
        "_internal_originalTime": 1689775103000
    },
    {
        "time": 1689775104000,
        "value": 139.664,
        "_internal_originalTime": 1689775104000
    },
    {
        "time": 1689775105000,
        "value": 139.666,
        "_internal_originalTime": 1689775105000
    },
    {
        "time": 1689775107000,
        "value": 139.67,
        "_internal_originalTime": 1689775107000
    },
    {
        "time": 1689775108000,
        "value": 139.679,
        "_internal_originalTime": 1689775108000
    },
    {
        "time": 1689775109000,
        "value": 139.677,
        "_internal_originalTime": 1689775109000
    },
    {
        "time": 1689775110000,
        "value": 139.675,
        "_internal_originalTime": 1689775110000
    },
    {
        "time": 1689775111000,
        "value": 139.67,
        "_internal_originalTime": 1689775111000
    },
    {
        "time": 1689775112000,
        "value": 139.675,
        "_internal_originalTime": 1689775112000
    },
    {
        "time": 1689775113000,
        "value": 139.677,
        "_internal_originalTime": 1689775113000
    },
    {
        "time": 1689775115000,
        "value": 139.677,
        "_internal_originalTime": 1689775115000
    },
    {
        "time": 1689775116000,
        "value": 139.674,
        "_internal_originalTime": 1689775116000
    },
    {
        "time": 1689775118000,
        "value": 139.675,
        "_internal_originalTime": 1689775118000
    },
    {
        "time": 1689775119000,
        "value": 139.675,
        "_internal_originalTime": 1689775119000
    },
    {
        "time": 1689775120000,
        "value": 139.685,
        "_internal_originalTime": 1689775120000
    },
    {
        "time": 1689775121000,
        "value": 139.686,
        "_internal_originalTime": 1689775121000
    },
    {
        "time": 1689775122000,
        "value": 139.686,
        "_internal_originalTime": 1689775122000
    },
    {
        "time": 1689775123000,
        "value": 139.685,
        "_internal_originalTime": 1689775123000
    },
    {
        "time": 1689775125000,
        "value": 139.684,
        "_internal_originalTime": 1689775125000
    },
    {
        "time": 1689775126000,
        "value": 139.689,
        "_internal_originalTime": 1689775126000
    },
    {
        "time": 1689775127000,
        "value": 139.682,
        "_internal_originalTime": 1689775127000
    },
    {
        "time": 1689775129000,
        "value": 139.68,
        "_internal_originalTime": 1689775129000
    },
    {
        "time": 1689775130000,
        "value": 139.689,
        "_internal_originalTime": 1689775130000
    },
    {
        "time": 1689775131000,
        "value": 139.687,
        "_internal_originalTime": 1689775131000
    },
    {
        "time": 1689775132000,
        "value": 139.69,
        "_internal_originalTime": 1689775132000
    },
    {
        "time": 1689775133000,
        "value": 139.686,
        "_internal_originalTime": 1689775133000
    },
    {
        "time": 1689775134000,
        "value": 139.688,
        "_internal_originalTime": 1689775134000
    },
    {
        "time": 1689775135000,
        "value": 139.692,
        "_internal_originalTime": 1689775135000
    },
    {
        "time": 1689775136000,
        "value": 139.707,
        "_internal_originalTime": 1689775136000
    },
    {
        "time": 1689775137000,
        "value": 139.712,
        "_internal_originalTime": 1689775137000
    },
    {
        "time": 1689775138000,
        "value": 139.71,
        "_internal_originalTime": 1689775138000
    },
    {
        "time": 1689775140000,
        "value": 139.713,
        "_internal_originalTime": 1689775140000
    },
    {
        "time": 1689775141000,
        "value": 139.721,
        "_internal_originalTime": 1689775141000
    },
    {
        "time": 1689775142000,
        "value": 139.735,
        "_internal_originalTime": 1689775142000
    },
    {
        "time": 1689775143000,
        "value": 139.736,
        "_internal_originalTime": 1689775143000
    },
    {
        "time": 1689775145000,
        "value": 139.745,
        "_internal_originalTime": 1689775145000
    },
    {
        "time": 1689775146000,
        "value": 139.756,
        "_internal_originalTime": 1689775146000
    },
    {
        "time": 1689775147000,
        "value": 139.754,
        "_internal_originalTime": 1689775147000
    },
    {
        "time": 1689775148000,
        "value": 139.754,
        "_internal_originalTime": 1689775148000
    },
    {
        "time": 1689775149000,
        "value": 139.748,
        "_internal_originalTime": 1689775149000
    },
    {
        "time": 1689775151000,
        "value": 139.742,
        "_internal_originalTime": 1689775151000
    },
    {
        "time": 1689775152000,
        "value": 139.736,
        "_internal_originalTime": 1689775152000
    },
    {
        "time": 1689775153000,
        "value": 139.727,
        "_internal_originalTime": 1689775153000
    },
    {
        "time": 1689775155000,
        "value": 139.732,
        "_internal_originalTime": 1689775155000
    },
    {
        "time": 1689775156000,
        "value": 139.733,
        "_internal_originalTime": 1689775156000
    },
    {
        "time": 1689775157000,
        "value": 139.733,
        "_internal_originalTime": 1689775157000
    },
    {
        "time": 1689775159000,
        "value": 139.733,
        "_internal_originalTime": 1689775159000
    },
    {
        "time": 1689775160000,
        "value": 139.731,
        "_internal_originalTime": 1689775160000
    },
    {
        "time": 1689775162000,
        "value": 139.723,
        "_internal_originalTime": 1689775162000
    },
    {
        "time": 1689775163000,
        "value": 139.726,
        "_internal_originalTime": 1689775163000
    },
    {
        "time": 1689775164000,
        "value": 139.729,
        "_internal_originalTime": 1689775164000
    },
    {
        "time": 1689775165000,
        "value": 139.726,
        "_internal_originalTime": 1689775165000
    },
    {
        "time": 1689775166000,
        "value": 139.72,
        "_internal_originalTime": 1689775166000
    },
    {
        "time": 1689775168000,
        "value": 139.722,
        "_internal_originalTime": 1689775168000
    },
    {
        "time": 1689775169000,
        "value": 139.72,
        "_internal_originalTime": 1689775169000
    },
    {
        "time": 1689775170000,
        "value": 139.718,
        "_internal_originalTime": 1689775170000
    },
    {
        "time": 1689775171000,
        "value": 139.72,
        "_internal_originalTime": 1689775171000
    },
    {
        "time": 1689775172000,
        "value": 139.718,
        "_internal_originalTime": 1689775172000
    },
    {
        "time": 1689775173000,
        "value": 139.719,
        "_internal_originalTime": 1689775173000
    },
    {
        "time": 1689775174000,
        "value": 139.716,
        "_internal_originalTime": 1689775174000
    },
    {
        "time": 1689775181000,
        "value": 139.722,
        "_internal_originalTime": 1689775181000
    },
    {
        "time": 1689775182000,
        "value": 139.712,
        "_internal_originalTime": 1689775182000
    },
    {
        "time": 1689775183000,
        "value": 139.717,
        "_internal_originalTime": 1689775183000
    },
    {
        "time": 1689775184000,
        "value": 139.706,
        "_internal_originalTime": 1689775184000
    },
    {
        "time": 1689775186000,
        "value": 139.707,
        "_internal_originalTime": 1689775186000
    },
    {
        "time": 1689775187000,
        "value": 139.704,
        "_internal_originalTime": 1689775187000
    },
    {
        "time": 1689775189000,
        "value": 139.712,
        "_internal_originalTime": 1689775189000
    },
    {
        "time": 1689775190000,
        "value": 139.712,
        "_internal_originalTime": 1689775190000
    },
    {
        "time": 1689775191000,
        "value": 139.715,
        "_internal_originalTime": 1689775191000
    },
    {
        "time": 1689775193000,
        "value": 139.718,
        "_internal_originalTime": 1689775193000
    },
    {
        "time": 1689775194000,
        "value": 139.719,
        "_internal_originalTime": 1689775194000
    },
    {
        "time": 1689775195000,
        "value": 139.718,
        "_internal_originalTime": 1689775195000
    },
    {
        "time": 1689775196000,
        "value": 139.722,
        "_internal_originalTime": 1689775196000
    },
    {
        "time": 1689775198000,
        "value": 139.724,
        "_internal_originalTime": 1689775198000
    },
    {
        "time": 1689775199000,
        "value": 139.715,
        "_internal_originalTime": 1689775199000
    },
    {
        "time": 1689775200000,
        "value": 139.715,
        "_internal_originalTime": 1689775200000
    },
    {
        "time": 1689775201000,
        "value": 139.717,
        "_internal_originalTime": 1689775201000
    },
    {
        "time": 1689775202000,
        "value": 139.717,
        "_internal_originalTime": 1689775202000
    },
    {
        "time": 1689775203000,
        "value": 139.717,
        "_internal_originalTime": 1689775203000
    },
    {
        "time": 1689775204000,
        "value": 139.716,
        "_internal_originalTime": 1689775204000
    },
    {
        "time": 1689775205000,
        "value": 139.722,
        "_internal_originalTime": 1689775205000
    },
    {
        "time": 1689775207000,
        "value": 139.74,
        "_internal_originalTime": 1689775207000
    },
    {
        "time": 1689775209000,
        "value": 139.736,
        "_internal_originalTime": 1689775209000
    },
    {
        "time": 1689775210000,
        "value": 139.743,
        "_internal_originalTime": 1689775210000
    },
    {
        "time": 1689775211000,
        "value": 139.741,
        "_internal_originalTime": 1689775211000
    },
    {
        "time": 1689775212000,
        "value": 139.738,
        "_internal_originalTime": 1689775212000
    },
    {
        "time": 1689775213000,
        "value": 139.734,
        "_internal_originalTime": 1689775213000
    },
    {
        "time": 1689775214000,
        "value": 139.732,
        "_internal_originalTime": 1689775214000
    },
    {
        "time": 1689775215000,
        "value": 139.728,
        "_internal_originalTime": 1689775215000
    },
    {
        "time": 1689775217000,
        "value": 139.724,
        "_internal_originalTime": 1689775217000
    },
    {
        "time": 1689775218000,
        "value": 139.725,
        "_internal_originalTime": 1689775218000
    },
    {
        "time": 1689775220000,
        "value": 139.734,
        "_internal_originalTime": 1689775220000
    },
    {
        "time": 1689775221000,
        "value": 139.738,
        "_internal_originalTime": 1689775221000
    },
    {
        "time": 1689775222000,
        "value": 139.744,
        "_internal_originalTime": 1689775222000
    },
    {
        "time": 1689775223000,
        "value": 139.743,
        "_internal_originalTime": 1689775223000
    },
    {
        "time": 1689775224000,
        "value": 139.753,
        "_internal_originalTime": 1689775224000
    },
    {
        "time": 1689775226000,
        "value": 139.755,
        "_internal_originalTime": 1689775226000
    },
    {
        "time": 1689775227000,
        "value": 139.756,
        "_internal_originalTime": 1689775227000
    },
    {
        "time": 1689775228000,
        "value": 139.752,
        "_internal_originalTime": 1689775228000
    },
    {
        "time": 1689775230000,
        "value": 139.755,
        "_internal_originalTime": 1689775230000
    },
    {
        "time": 1689775231000,
        "value": 139.756,
        "_internal_originalTime": 1689775231000
    },
    {
        "time": 1689775232000,
        "value": 139.756,
        "_internal_originalTime": 1689775232000
    },
    {
        "time": 1689775233000,
        "value": 139.75,
        "_internal_originalTime": 1689775233000
    },
    {
        "time": 1689775234000,
        "value": 139.749,
        "_internal_originalTime": 1689775234000
    },
    {
        "time": 1689775236000,
        "value": 139.755,
        "_internal_originalTime": 1689775236000
    },
    {
        "time": 1689775237000,
        "value": 139.754,
        "_internal_originalTime": 1689775237000
    },
    {
        "time": 1689775238000,
        "value": 139.75,
        "_internal_originalTime": 1689775238000
    },
    {
        "time": 1689775240000,
        "value": 139.752,
        "_internal_originalTime": 1689775240000
    },
    {
        "time": 1689775241000,
        "value": 139.748,
        "_internal_originalTime": 1689775241000
    },
    {
        "time": 1689775243000,
        "value": 139.753,
        "_internal_originalTime": 1689775243000
    },
    {
        "time": 1689775244000,
        "value": 139.745,
        "_internal_originalTime": 1689775244000
    },
    {
        "time": 1689775245000,
        "value": 139.743,
        "_internal_originalTime": 1689775245000
    },
    {
        "time": 1689775246000,
        "value": 139.741,
        "_internal_originalTime": 1689775246000
    },
    {
        "time": 1689775247000,
        "value": 139.743,
        "_internal_originalTime": 1689775247000
    },
    {
        "time": 1689775249000,
        "value": 139.742,
        "_internal_originalTime": 1689775249000
    },
    {
        "time": 1689775250000,
        "value": 139.727,
        "_internal_originalTime": 1689775250000
    },
    {
        "time": 1689775252000,
        "value": 139.728,
        "_internal_originalTime": 1689775252000
    },
    {
        "time": 1689775253000,
        "value": 139.737,
        "_internal_originalTime": 1689775253000
    },
    {
        "time": 1689775254000,
        "value": 139.739,
        "_internal_originalTime": 1689775254000
    },
    {
        "time": 1689775256000,
        "value": 139.741,
        "_internal_originalTime": 1689775256000
    },
    {
        "time": 1689775257000,
        "value": 139.743,
        "_internal_originalTime": 1689775257000
    },
    {
        "time": 1689775258000,
        "value": 139.743,
        "_internal_originalTime": 1689775258000
    },
    {
        "time": 1689775260000,
        "value": 139.743,
        "_internal_originalTime": 1689775260000
    },
    {
        "time": 1689775261000,
        "value": 139.744,
        "_internal_originalTime": 1689775261000
    },
    {
        "time": 1689775262000,
        "value": 139.745,
        "_internal_originalTime": 1689775262000
    },
    {
        "time": 1689775263000,
        "value": 139.745,
        "_internal_originalTime": 1689775263000
    },
    {
        "time": 1689775264000,
        "value": 139.734,
        "_internal_originalTime": 1689775264000
    },
    {
        "time": 1689775265000,
        "value": 139.735,
        "_internal_originalTime": 1689775265000
    },
    {
        "time": 1689775267000,
        "value": 139.736,
        "_internal_originalTime": 1689775267000
    },
    {
        "time": 1689775268000,
        "value": 139.732,
        "_internal_originalTime": 1689775268000
    },
    {
        "time": 1689775270000,
        "value": 139.738,
        "_internal_originalTime": 1689775270000
    },
    {
        "time": 1689775271000,
        "value": 139.743,
        "_internal_originalTime": 1689775271000
    },
    {
        "time": 1689775273000,
        "value": 139.746,
        "_internal_originalTime": 1689775273000
    },
    {
        "time": 1689775275000,
        "value": 139.745,
        "_internal_originalTime": 1689775275000
    },
    {
        "time": 1689775276000,
        "value": 139.744,
        "_internal_originalTime": 1689775276000
    },
    {
        "time": 1689775277000,
        "value": 139.745,
        "_internal_originalTime": 1689775277000
    },
    {
        "time": 1689775278000,
        "value": 139.745,
        "_internal_originalTime": 1689775278000
    },
    {
        "time": 1689775280000,
        "value": 139.745,
        "_internal_originalTime": 1689775280000
    },
    {
        "time": 1689775282000,
        "value": 139.746,
        "_internal_originalTime": 1689775282000
    },
    {
        "time": 1689775283000,
        "value": 139.744,
        "_internal_originalTime": 1689775283000
    },
    {
        "time": 1689775284000,
        "value": 139.747,
        "_internal_originalTime": 1689775284000
    },
    {
        "time": 1689775286000,
        "value": 139.743,
        "_internal_originalTime": 1689775286000
    },
    {
        "time": 1689775287000,
        "value": 139.747,
        "_internal_originalTime": 1689775287000
    },
    {
        "time": 1689775289000,
        "value": 139.745,
        "_internal_originalTime": 1689775289000
    },
    {
        "time": 1689775290000,
        "value": 139.756,
        "_internal_originalTime": 1689775290000
    },
    {
        "time": 1689775291000,
        "value": 139.75,
        "_internal_originalTime": 1689775291000
    },
    {
        "time": 1689775293000,
        "value": 139.744,
        "_internal_originalTime": 1689775293000
    },
    {
        "time": 1689775294000,
        "value": 139.748,
        "_internal_originalTime": 1689775294000
    },
    {
        "time": 1689775295000,
        "value": 139.753,
        "_internal_originalTime": 1689775295000
    },
    {
        "time": 1689775296000,
        "value": 139.755,
        "_internal_originalTime": 1689775296000
    },
    {
        "time": 1689775297000,
        "value": 139.753,
        "_internal_originalTime": 1689775297000
    },
    {
        "time": 1689775298000,
        "value": 139.754,
        "_internal_originalTime": 1689775298000
    },
    {
        "time": 1689775299000,
        "value": 139.75,
        "_internal_originalTime": 1689775299000
    },
    {
        "time": 1689775301000,
        "value": 139.75,
        "_internal_originalTime": 1689775301000
    },
    {
        "time": 1689775302000,
        "value": 139.753,
        "_internal_originalTime": 1689775302000
    },
    {
        "time": 1689775304000,
        "value": 139.764,
        "_internal_originalTime": 1689775304000
    },
    {
        "time": 1689775305000,
        "value": 139.766,
        "_internal_originalTime": 1689775305000
    },
    {
        "time": 1689775307000,
        "value": 139.763,
        "_internal_originalTime": 1689775307000
    },
    {
        "time": 1689775308000,
        "value": 139.763,
        "_internal_originalTime": 1689775308000
    },
    {
        "time": 1689775309000,
        "value": 139.774,
        "_internal_originalTime": 1689775309000
    },
    {
        "time": 1689775311000,
        "value": 139.775,
        "_internal_originalTime": 1689775311000
    },
    {
        "time": 1689775312000,
        "value": 139.777,
        "_internal_originalTime": 1689775312000
    },
    {
        "time": 1689775313000,
        "value": 139.774,
        "_internal_originalTime": 1689775313000
    },
    {
        "time": 1689775315000,
        "value": 139.778,
        "_internal_originalTime": 1689775315000
    },
    {
        "time": 1689775316000,
        "value": 139.782,
        "_internal_originalTime": 1689775316000
    },
    {
        "time": 1689775317000,
        "value": 139.782,
        "_internal_originalTime": 1689775317000
    },
    {
        "time": 1689775319000,
        "value": 139.776,
        "_internal_originalTime": 1689775319000
    },
    {
        "time": 1689775320000,
        "value": 139.774,
        "_internal_originalTime": 1689775320000
    },
    {
        "time": 1689775321000,
        "value": 139.77,
        "_internal_originalTime": 1689775321000
    },
    {
        "time": 1689775322000,
        "value": 139.764,
        "_internal_originalTime": 1689775322000
    },
    {
        "time": 1689775324000,
        "value": 139.769,
        "_internal_originalTime": 1689775324000
    },
    {
        "time": 1689775325000,
        "value": 139.773,
        "_internal_originalTime": 1689775325000
    },
    {
        "time": 1689775327000,
        "value": 139.773,
        "_internal_originalTime": 1689775327000
    },
    {
        "time": 1689775329000,
        "value": 139.77,
        "_internal_originalTime": 1689775329000
    },
    {
        "time": 1689775330000,
        "value": 139.772,
        "_internal_originalTime": 1689775330000
    },
    {
        "time": 1689775331000,
        "value": 139.767,
        "_internal_originalTime": 1689775331000
    },
    {
        "time": 1689775333000,
        "value": 139.774,
        "_internal_originalTime": 1689775333000
    },
    {
        "time": 1689775336000,
        "value": 139.773,
        "_internal_originalTime": 1689775336000
    },
    {
        "time": 1689775338000,
        "value": 139.771,
        "_internal_originalTime": 1689775338000
    },
    {
        "time": 1689775339000,
        "value": 139.754,
        "_internal_originalTime": 1689775339000
    },
    {
        "time": 1689775340000,
        "value": 139.755,
        "_internal_originalTime": 1689775340000
    },
    {
        "time": 1689775342000,
        "value": 139.755,
        "_internal_originalTime": 1689775342000
    },
    {
        "time": 1689775344000,
        "value": 139.755,
        "_internal_originalTime": 1689775344000
    },
    {
        "time": 1689775345000,
        "value": 139.763,
        "_internal_originalTime": 1689775345000
    },
    {
        "time": 1689775346000,
        "value": 139.764,
        "_internal_originalTime": 1689775346000
    },
    {
        "time": 1689775347000,
        "value": 139.766,
        "_internal_originalTime": 1689775347000
    },
    {
        "time": 1689775349000,
        "value": 139.763,
        "_internal_originalTime": 1689775349000
    },
    {
        "time": 1689775350000,
        "value": 139.758,
        "_internal_originalTime": 1689775350000
    },
    {
        "time": 1689775351000,
        "value": 139.753,
        "_internal_originalTime": 1689775351000
    },
    {
        "time": 1689775353000,
        "value": 139.754,
        "_internal_originalTime": 1689775353000
    },
    {
        "time": 1689775354000,
        "value": 139.752,
        "_internal_originalTime": 1689775354000
    },
    {
        "time": 1689775358000,
        "value": 139.755,
        "_internal_originalTime": 1689775358000
    },
    {
        "time": 1689775360000,
        "value": 139.754,
        "_internal_originalTime": 1689775360000
    },
    {
        "time": 1689775361000,
        "value": 139.752,
        "_internal_originalTime": 1689775361000
    },
    {
        "time": 1689775362000,
        "value": 139.751,
        "_internal_originalTime": 1689775362000
    },
    {
        "time": 1689775364000,
        "value": 139.744,
        "_internal_originalTime": 1689775364000
    },
    {
        "time": 1689775365000,
        "value": 139.745,
        "_internal_originalTime": 1689775365000
    },
    {
        "time": 1689775366000,
        "value": 139.745,
        "_internal_originalTime": 1689775366000
    },
    {
        "time": 1689775368000,
        "value": 139.747,
        "_internal_originalTime": 1689775368000
    },
    {
        "time": 1689775370000,
        "value": 139.753,
        "_internal_originalTime": 1689775370000
    },
    {
        "time": 1689775371000,
        "value": 139.754,
        "_internal_originalTime": 1689775371000
    },
    {
        "time": 1689775373000,
        "value": 139.74,
        "_internal_originalTime": 1689775373000
    },
    {
        "time": 1689775374000,
        "value": 139.743,
        "_internal_originalTime": 1689775374000
    },
    {
        "time": 1689775376000,
        "value": 139.738,
        "_internal_originalTime": 1689775376000
    },
    {
        "time": 1689775377000,
        "value": 139.737,
        "_internal_originalTime": 1689775377000
    },
    {
        "time": 1689775378000,
        "value": 139.74,
        "_internal_originalTime": 1689775378000
    },
    {
        "time": 1689775379000,
        "value": 139.732,
        "_internal_originalTime": 1689775379000
    },
    {
        "time": 1689775381000,
        "value": 139.737,
        "_internal_originalTime": 1689775381000
    },
    {
        "time": 1689775382000,
        "value": 139.738,
        "_internal_originalTime": 1689775382000
    },
    {
        "time": 1689775383000,
        "value": 139.736,
        "_internal_originalTime": 1689775383000
    },
    {
        "time": 1689775384000,
        "value": 139.717,
        "_internal_originalTime": 1689775384000
    },
    {
        "time": 1689775385000,
        "value": 139.722,
        "_internal_originalTime": 1689775385000
    },
    {
        "time": 1689775386000,
        "value": 139.721,
        "_internal_originalTime": 1689775386000
    },
    {
        "time": 1689775387000,
        "value": 139.72,
        "_internal_originalTime": 1689775387000
    },
    {
        "time": 1689775388000,
        "value": 139.719,
        "_internal_originalTime": 1689775388000
    },
    {
        "time": 1689775390000,
        "value": 139.721,
        "_internal_originalTime": 1689775390000
    },
    {
        "time": 1689775391000,
        "value": 139.719,
        "_internal_originalTime": 1689775391000
    },
    {
        "time": 1689775392000,
        "value": 139.717,
        "_internal_originalTime": 1689775392000
    },
    {
        "time": 1689775393000,
        "value": 139.724,
        "_internal_originalTime": 1689775393000
    },
    {
        "time": 1689775394000,
        "value": 139.727,
        "_internal_originalTime": 1689775394000
    },
    {
        "time": 1689775395000,
        "value": 139.725,
        "_internal_originalTime": 1689775395000
    },
    {
        "time": 1689775397000,
        "value": 139.74,
        "_internal_originalTime": 1689775397000
    },
    {
        "time": 1689775398000,
        "value": 139.742,
        "_internal_originalTime": 1689775398000
    },
    {
        "time": 1689775399000,
        "value": 139.741,
        "_internal_originalTime": 1689775399000
    },
    {
        "time": 1689775400000,
        "value": 139.743,
        "_internal_originalTime": 1689775400000
    },
    {
        "time": 1689775401000,
        "value": 139.743,
        "_internal_originalTime": 1689775401000
    },
    {
        "time": 1689775403000,
        "value": 139.744,
        "_internal_originalTime": 1689775403000
    },
    {
        "time": 1689775404000,
        "value": 139.742,
        "_internal_originalTime": 1689775404000
    },
    {
        "time": 1689775405000,
        "value": 139.742,
        "_internal_originalTime": 1689775405000
    },
    {
        "time": 1689775406000,
        "value": 139.738,
        "_internal_originalTime": 1689775406000
    },
    {
        "time": 1689775407000,
        "value": 139.74,
        "_internal_originalTime": 1689775407000
    },
    {
        "time": 1689775408000,
        "value": 139.743,
        "_internal_originalTime": 1689775408000
    },
    {
        "time": 1689775409000,
        "value": 139.741,
        "_internal_originalTime": 1689775409000
    },
    {
        "time": 1689775411000,
        "value": 139.733,
        "_internal_originalTime": 1689775411000
    },
    {
        "time": 1689775412000,
        "value": 139.733,
        "_internal_originalTime": 1689775412000
    },
    {
        "time": 1689775413000,
        "value": 139.735,
        "_internal_originalTime": 1689775413000
    },
    {
        "time": 1689775415000,
        "value": 139.728,
        "_internal_originalTime": 1689775415000
    },
    {
        "time": 1689775416000,
        "value": 139.73,
        "_internal_originalTime": 1689775416000
    },
    {
        "time": 1689775417000,
        "value": 139.731,
        "_internal_originalTime": 1689775417000
    },
    {
        "time": 1689775418000,
        "value": 139.734,
        "_internal_originalTime": 1689775418000
    },
    {
        "time": 1689775419000,
        "value": 139.733,
        "_internal_originalTime": 1689775419000
    },
    {
        "time": 1689775420000,
        "value": 139.735,
        "_internal_originalTime": 1689775420000
    },
    {
        "time": 1689775421000,
        "value": 139.732,
        "_internal_originalTime": 1689775421000
    },
    {
        "time": 1689775422000,
        "value": 139.735,
        "_internal_originalTime": 1689775422000
    },
    {
        "time": 1689775423000,
        "value": 139.732,
        "_internal_originalTime": 1689775423000
    },
    {
        "time": 1689775425000,
        "value": 139.731,
        "_internal_originalTime": 1689775425000
    },
    {
        "time": 1689775426000,
        "value": 139.732,
        "_internal_originalTime": 1689775426000
    },
    {
        "time": 1689775427000,
        "value": 139.734,
        "_internal_originalTime": 1689775427000
    },
    {
        "time": 1689775428000,
        "value": 139.732,
        "_internal_originalTime": 1689775428000
    },
    {
        "time": 1689775430000,
        "value": 139.734,
        "_internal_originalTime": 1689775430000
    },
    {
        "time": 1689775431000,
        "value": 139.732,
        "_internal_originalTime": 1689775431000
    },
    {
        "time": 1689775432000,
        "value": 139.724,
        "_internal_originalTime": 1689775432000
    },
    {
        "time": 1689775433000,
        "value": 139.728,
        "_internal_originalTime": 1689775433000
    },
    {
        "time": 1689775435000,
        "value": 139.728,
        "_internal_originalTime": 1689775435000
    },
    {
        "time": 1689775436000,
        "value": 139.727,
        "_internal_originalTime": 1689775436000
    },
    {
        "time": 1689775437000,
        "value": 139.725,
        "_internal_originalTime": 1689775437000
    },
    {
        "time": 1689775438000,
        "value": 139.726,
        "_internal_originalTime": 1689775438000
    },
    {
        "time": 1689775439000,
        "value": 139.726,
        "_internal_originalTime": 1689775439000
    },
    {
        "time": 1689775440000,
        "value": 139.728,
        "_internal_originalTime": 1689775440000
    },
    {
        "time": 1689775441000,
        "value": 139.726,
        "_internal_originalTime": 1689775441000
    },
    {
        "time": 1689775442000,
        "value": 139.727,
        "_internal_originalTime": 1689775442000
    },
    {
        "time": 1689775443000,
        "value": 139.726,
        "_internal_originalTime": 1689775443000
    },
    {
        "time": 1689775444000,
        "value": 139.724,
        "_internal_originalTime": 1689775444000
    },
    {
        "time": 1689775445000,
        "value": 139.728,
        "_internal_originalTime": 1689775445000
    },
    {
        "time": 1689775446000,
        "value": 139.727,
        "_internal_originalTime": 1689775446000
    },
    {
        "time": 1689775447000,
        "value": 139.724,
        "_internal_originalTime": 1689775447000
    },
    {
        "time": 1689775448000,
        "value": 139.721,
        "_internal_originalTime": 1689775448000
    },
    {
        "time": 1689775449000,
        "value": 139.725,
        "_internal_originalTime": 1689775449000
    },
    {
        "time": 1689775450000,
        "value": 139.723,
        "_internal_originalTime": 1689775450000
    },
    {
        "time": 1689775451000,
        "value": 139.717,
        "_internal_originalTime": 1689775451000
    },
    {
        "time": 1689775453000,
        "value": 139.73,
        "_internal_originalTime": 1689775453000
    },
    {
        "time": 1689775454000,
        "value": 139.73,
        "_internal_originalTime": 1689775454000
    },
    {
        "time": 1689775455000,
        "value": 139.728,
        "_internal_originalTime": 1689775455000
    },
    {
        "time": 1689775456000,
        "value": 139.729,
        "_internal_originalTime": 1689775456000
    },
    {
        "time": 1689775457000,
        "value": 139.722,
        "_internal_originalTime": 1689775457000
    },
    {
        "time": 1689775458000,
        "value": 139.721,
        "_internal_originalTime": 1689775458000
    },
    {
        "time": 1689775459000,
        "value": 139.715,
        "_internal_originalTime": 1689775459000
    },
    {
        "time": 1689775460000,
        "value": 139.715,
        "_internal_originalTime": 1689775460000
    },
    {
        "time": 1689775462000,
        "value": 139.716,
        "_internal_originalTime": 1689775462000
    },
    {
        "time": 1689775463000,
        "value": 139.716,
        "_internal_originalTime": 1689775463000
    },
    {
        "time": 1689775464000,
        "value": 139.712,
        "_internal_originalTime": 1689775464000
    },
    {
        "time": 1689775465000,
        "value": 139.714,
        "_internal_originalTime": 1689775465000
    },
    {
        "time": 1689775466000,
        "value": 139.708,
        "_internal_originalTime": 1689775466000
    },
    {
        "time": 1689775467000,
        "value": 139.702,
        "_internal_originalTime": 1689775467000
    },
    {
        "time": 1689775468000,
        "value": 139.705,
        "_internal_originalTime": 1689775468000
    },
    {
        "time": 1689775469000,
        "value": 139.706,
        "_internal_originalTime": 1689775469000
    },
    {
        "time": 1689775470000,
        "value": 139.705,
        "_internal_originalTime": 1689775470000
    },
    {
        "time": 1689775471000,
        "value": 139.71,
        "_internal_originalTime": 1689775471000
    },
    {
        "time": 1689775472000,
        "value": 139.712,
        "_internal_originalTime": 1689775472000
    },
    {
        "time": 1689775473000,
        "value": 139.712,
        "_internal_originalTime": 1689775473000
    },
    {
        "time": 1689775475000,
        "value": 139.712,
        "_internal_originalTime": 1689775475000
    },
    {
        "time": 1689775476000,
        "value": 139.711,
        "_internal_originalTime": 1689775476000
    },
    {
        "time": 1689775477000,
        "value": 139.71,
        "_internal_originalTime": 1689775477000
    },
    {
        "time": 1689775478000,
        "value": 139.714,
        "_internal_originalTime": 1689775478000
    },
    {
        "time": 1689775479000,
        "value": 139.714,
        "_internal_originalTime": 1689775479000
    },
    {
        "time": 1689775480000,
        "value": 139.714,
        "_internal_originalTime": 1689775480000
    },
    {
        "time": 1689775481000,
        "value": 139.707,
        "_internal_originalTime": 1689775481000
    },
    {
        "time": 1689775482000,
        "value": 139.707,
        "_internal_originalTime": 1689775482000
    },
    {
        "time": 1689775483000,
        "value": 139.706,
        "_internal_originalTime": 1689775483000
    },
    {
        "time": 1689775484000,
        "value": 139.711,
        "_internal_originalTime": 1689775484000
    },
    {
        "time": 1689775485000,
        "value": 139.712,
        "_internal_originalTime": 1689775485000
    },
    {
        "time": 1689775486000,
        "value": 139.716,
        "_internal_originalTime": 1689775486000
    },
    {
        "time": 1689775488000,
        "value": 139.717,
        "_internal_originalTime": 1689775488000
    },
    {
        "time": 1689775489000,
        "value": 139.717,
        "_internal_originalTime": 1689775489000
    },
    {
        "time": 1689775490000,
        "value": 139.716,
        "_internal_originalTime": 1689775490000
    },
    {
        "time": 1689775491000,
        "value": 139.715,
        "_internal_originalTime": 1689775491000
    },
    {
        "time": 1689775492000,
        "value": 139.716,
        "_internal_originalTime": 1689775492000
    },
    {
        "time": 1689775494000,
        "value": 139.716,
        "_internal_originalTime": 1689775494000
    },
    {
        "time": 1689775495000,
        "value": 139.714,
        "_internal_originalTime": 1689775495000
    },
    {
        "time": 1689775496000,
        "value": 139.712,
        "_internal_originalTime": 1689775496000
    },
    {
        "time": 1689775497000,
        "value": 139.712,
        "_internal_originalTime": 1689775497000
    },
    {
        "time": 1689775499000,
        "value": 139.711,
        "_internal_originalTime": 1689775499000
    },
    {
        "time": 1689775501000,
        "value": 139.723,
        "_internal_originalTime": 1689775501000
    },
    {
        "time": 1689775502000,
        "value": 139.722,
        "_internal_originalTime": 1689775502000
    },
    {
        "time": 1689775503000,
        "value": 139.725,
        "_internal_originalTime": 1689775503000
    },
    {
        "time": 1689775504000,
        "value": 139.725,
        "_internal_originalTime": 1689775504000
    },
    {
        "time": 1689775505000,
        "value": 139.72,
        "_internal_originalTime": 1689775505000
    },
    {
        "time": 1689775506000,
        "value": 139.725,
        "_internal_originalTime": 1689775506000
    },
    {
        "time": 1689775507000,
        "value": 139.72,
        "_internal_originalTime": 1689775507000
    },
    {
        "time": 1689775508000,
        "value": 139.723,
        "_internal_originalTime": 1689775508000
    },
    {
        "time": 1689775509000,
        "value": 139.725,
        "_internal_originalTime": 1689775509000
    },
    {
        "time": 1689775510000,
        "value": 139.727,
        "_internal_originalTime": 1689775510000
    },
    {
        "time": 1689775511000,
        "value": 139.724,
        "_internal_originalTime": 1689775511000
    },
    {
        "time": 1689775512000,
        "value": 139.723,
        "_internal_originalTime": 1689775512000
    },
    {
        "time": 1689775513000,
        "value": 139.721,
        "_internal_originalTime": 1689775513000
    },
    {
        "time": 1689775514000,
        "value": 139.723,
        "_internal_originalTime": 1689775514000
    },
    {
        "time": 1689775515000,
        "value": 139.724,
        "_internal_originalTime": 1689775515000
    },
    {
        "time": 1689775516000,
        "value": 139.722,
        "_internal_originalTime": 1689775516000
    },
    {
        "time": 1689775517000,
        "value": 139.722,
        "_internal_originalTime": 1689775517000
    },
    {
        "time": 1689775518000,
        "value": 139.726,
        "_internal_originalTime": 1689775518000
    },
    {
        "time": 1689775519000,
        "value": 139.72,
        "_internal_originalTime": 1689775519000
    },
    {
        "time": 1689775520000,
        "value": 139.724,
        "_internal_originalTime": 1689775520000
    },
    {
        "time": 1689775521000,
        "value": 139.724,
        "_internal_originalTime": 1689775521000
    },
    {
        "time": 1689775522000,
        "value": 139.726,
        "_internal_originalTime": 1689775522000
    },
    {
        "time": 1689775523000,
        "value": 139.711,
        "_internal_originalTime": 1689775523000
    },
    {
        "time": 1689775524000,
        "value": 139.709,
        "_internal_originalTime": 1689775524000
    },
    {
        "time": 1689775525000,
        "value": 139.705,
        "_internal_originalTime": 1689775525000
    },
    {
        "time": 1689775526000,
        "value": 139.708,
        "_internal_originalTime": 1689775526000
    },
    {
        "time": 1689775527000,
        "value": 139.711,
        "_internal_originalTime": 1689775527000
    },
    {
        "time": 1689775528000,
        "value": 139.711,
        "_internal_originalTime": 1689775528000
    },
    {
        "time": 1689775530000,
        "value": 139.708,
        "_internal_originalTime": 1689775530000
    },
    {
        "time": 1689775532000,
        "value": 139.695,
        "_internal_originalTime": 1689775532000
    },
    {
        "time": 1689775533000,
        "value": 139.687,
        "_internal_originalTime": 1689775533000
    },
    {
        "time": 1689775534000,
        "value": 139.689,
        "_internal_originalTime": 1689775534000
    },
    {
        "time": 1689775535000,
        "value": 139.692,
        "_internal_originalTime": 1689775535000
    },
    {
        "time": 1689775536000,
        "value": 139.687,
        "_internal_originalTime": 1689775536000
    },
    {
        "time": 1689775537000,
        "value": 139.69,
        "_internal_originalTime": 1689775537000
    },
    {
        "time": 1689775538000,
        "value": 139.688,
        "_internal_originalTime": 1689775538000
    },
    {
        "time": 1689775539000,
        "value": 139.686,
        "_internal_originalTime": 1689775539000
    },
    {
        "time": 1689775540000,
        "value": 139.687,
        "_internal_originalTime": 1689775540000
    },
    {
        "time": 1689775541000,
        "value": 139.683,
        "_internal_originalTime": 1689775541000
    },
    {
        "time": 1689775543000,
        "value": 139.687,
        "_internal_originalTime": 1689775543000
    },
    {
        "time": 1689775545000,
        "value": 139.688,
        "_internal_originalTime": 1689775545000
    },
    {
        "time": 1689775552000,
        "value": 139.689,
        "_internal_originalTime": 1689775552000
    },
    {
        "time": 1689775553000,
        "value": 139.689,
        "_internal_originalTime": 1689775553000
    },
    {
        "time": 1689775554000,
        "value": 139.689,
        "_internal_originalTime": 1689775554000
    },
    {
        "time": 1689775555000,
        "value": 139.688,
        "_internal_originalTime": 1689775555000
    },
    {
        "time": 1689775556000,
        "value": 139.691,
        "_internal_originalTime": 1689775556000
    },
    {
        "time": 1689775558000,
        "value": 139.706,
        "_internal_originalTime": 1689775558000
    },
    {
        "time": 1689775559000,
        "value": 139.712,
        "_internal_originalTime": 1689775559000
    },
    {
        "time": 1689775560000,
        "value": 139.716,
        "_internal_originalTime": 1689775560000
    },
    {
        "time": 1689775561000,
        "value": 139.72,
        "_internal_originalTime": 1689775561000
    },
    {
        "time": 1689775562000,
        "value": 139.717,
        "_internal_originalTime": 1689775562000
    },
    {
        "time": 1689775563000,
        "value": 139.717,
        "_internal_originalTime": 1689775563000
    },
    {
        "time": 1689775564000,
        "value": 139.714,
        "_internal_originalTime": 1689775564000
    },
    {
        "time": 1689775565000,
        "value": 139.712,
        "_internal_originalTime": 1689775565000
    },
    {
        "time": 1689775566000,
        "value": 139.718,
        "_internal_originalTime": 1689775566000
    },
    {
        "time": 1689775567000,
        "value": 139.716,
        "_internal_originalTime": 1689775567000
    },
    {
        "time": 1689775568000,
        "value": 139.717,
        "_internal_originalTime": 1689775568000
    },
    {
        "time": 1689775569000,
        "value": 139.712,
        "_internal_originalTime": 1689775569000
    },
    {
        "time": 1689775570000,
        "value": 139.716,
        "_internal_originalTime": 1689775570000
    },
    {
        "time": 1689775571000,
        "value": 139.711,
        "_internal_originalTime": 1689775571000
    },
    {
        "time": 1689775572000,
        "value": 139.709,
        "_internal_originalTime": 1689775572000
    },
    {
        "time": 1689775573000,
        "value": 139.702,
        "_internal_originalTime": 1689775573000
    },
    {
        "time": 1689775574000,
        "value": 139.706,
        "_internal_originalTime": 1689775574000
    },
    {
        "time": 1689775575000,
        "value": 139.708,
        "_internal_originalTime": 1689775575000
    },
    {
        "time": 1689775576000,
        "value": 139.709,
        "_internal_originalTime": 1689775576000
    },
    {
        "time": 1689775577000,
        "value": 139.708,
        "_internal_originalTime": 1689775577000
    },
    {
        "time": 1689775578000,
        "value": 139.708,
        "_internal_originalTime": 1689775578000
    },
    {
        "time": 1689775579000,
        "value": 139.706,
        "_internal_originalTime": 1689775579000
    },
    {
        "time": 1689775580000,
        "value": 139.704,
        "_internal_originalTime": 1689775580000
    },
    {
        "time": 1689775581000,
        "value": 139.708,
        "_internal_originalTime": 1689775581000
    },
    {
        "time": 1689775583000,
        "value": 139.704,
        "_internal_originalTime": 1689775583000
    },
    {
        "time": 1689775584000,
        "value": 139.708,
        "_internal_originalTime": 1689775584000
    },
    {
        "time": 1689775585000,
        "value": 139.708,
        "_internal_originalTime": 1689775585000
    },
    {
        "time": 1689775586000,
        "value": 139.708,
        "_internal_originalTime": 1689775586000
    },
    {
        "time": 1689775587000,
        "value": 139.711,
        "_internal_originalTime": 1689775587000
    },
    {
        "time": 1689775588000,
        "value": 139.712,
        "_internal_originalTime": 1689775588000
    },
    {
        "time": 1689775590000,
        "value": 139.708,
        "_internal_originalTime": 1689775590000
    },
    {
        "time": 1689775591000,
        "value": 139.708,
        "_internal_originalTime": 1689775591000
    },
    {
        "time": 1689775592000,
        "value": 139.702,
        "_internal_originalTime": 1689775592000
    },
    {
        "time": 1689775593000,
        "value": 139.704,
        "_internal_originalTime": 1689775593000
    },
    {
        "time": 1689775595000,
        "value": 139.693,
        "_internal_originalTime": 1689775595000
    },
    {
        "time": 1689775596000,
        "value": 139.699,
        "_internal_originalTime": 1689775596000
    },
    {
        "time": 1689775597000,
        "value": 139.696,
        "_internal_originalTime": 1689775597000
    },
    {
        "time": 1689775598000,
        "value": 139.697,
        "_internal_originalTime": 1689775598000
    },
    {
        "time": 1689775599000,
        "value": 139.698,
        "_internal_originalTime": 1689775599000
    },
    {
        "time": 1689775601000,
        "value": 139.698,
        "_internal_originalTime": 1689775601000
    },
    {
        "time": 1689775602000,
        "value": 139.692,
        "_internal_originalTime": 1689775602000
    },
    {
        "time": 1689775603000,
        "value": 139.699,
        "_internal_originalTime": 1689775603000
    },
    {
        "time": 1689775604000,
        "value": 139.697,
        "_internal_originalTime": 1689775604000
    },
    {
        "time": 1689775605000,
        "value": 139.699,
        "_internal_originalTime": 1689775605000
    },
    {
        "time": 1689775606000,
        "value": 139.698,
        "_internal_originalTime": 1689775606000
    },
    {
        "time": 1689775607000,
        "value": 139.709,
        "_internal_originalTime": 1689775607000
    },
    {
        "time": 1689775608000,
        "value": 139.714,
        "_internal_originalTime": 1689775608000
    },
    {
        "time": 1689775609000,
        "value": 139.723,
        "_internal_originalTime": 1689775609000
    },
    {
        "time": 1689775610000,
        "value": 139.722,
        "_internal_originalTime": 1689775610000
    },
    {
        "time": 1689775612000,
        "value": 139.724,
        "_internal_originalTime": 1689775612000
    },
    {
        "time": 1689775613000,
        "value": 139.723,
        "_internal_originalTime": 1689775613000
    },
    {
        "time": 1689775614000,
        "value": 139.724,
        "_internal_originalTime": 1689775614000
    },
    {
        "time": 1689775615000,
        "value": 139.727,
        "_internal_originalTime": 1689775615000
    },
    {
        "time": 1689775616000,
        "value": 139.734,
        "_internal_originalTime": 1689775616000
    },
    {
        "time": 1689775617000,
        "value": 139.734,
        "_internal_originalTime": 1689775617000
    },
    {
        "time": 1689775619000,
        "value": 139.752,
        "_internal_originalTime": 1689775619000
    },
    {
        "time": 1689775620000,
        "value": 139.748,
        "_internal_originalTime": 1689775620000
    },
    {
        "time": 1689775622000,
        "value": 139.752,
        "_internal_originalTime": 1689775622000
    },
    {
        "time": 1689775624000,
        "value": 139.748,
        "_internal_originalTime": 1689775624000
    },
    {
        "time": 1689775625000,
        "value": 139.758,
        "_internal_originalTime": 1689775625000
    },
    {
        "time": 1689775626000,
        "value": 139.756,
        "_internal_originalTime": 1689775626000
    },
    {
        "time": 1689775628000,
        "value": 139.755,
        "_internal_originalTime": 1689775628000
    },
    {
        "time": 1689775629000,
        "value": 139.754,
        "_internal_originalTime": 1689775629000
    },
    {
        "time": 1689775630000,
        "value": 139.755,
        "_internal_originalTime": 1689775630000
    },
    {
        "time": 1689775631000,
        "value": 139.754,
        "_internal_originalTime": 1689775631000
    },
    {
        "time": 1689775632000,
        "value": 139.755,
        "_internal_originalTime": 1689775632000
    },
    {
        "time": 1689775634000,
        "value": 139.752,
        "_internal_originalTime": 1689775634000
    },
    {
        "time": 1689775635000,
        "value": 139.752,
        "_internal_originalTime": 1689775635000
    },
    {
        "time": 1689775636000,
        "value": 139.755,
        "_internal_originalTime": 1689775636000
    },
    {
        "time": 1689775637000,
        "value": 139.757,
        "_internal_originalTime": 1689775637000
    },
    {
        "time": 1689775638000,
        "value": 139.76,
        "_internal_originalTime": 1689775638000
    },
    {
        "time": 1689775639000,
        "value": 139.765,
        "_internal_originalTime": 1689775639000
    },
    {
        "time": 1689775641000,
        "value": 139.77,
        "_internal_originalTime": 1689775641000
    },
    {
        "time": 1689775642000,
        "value": 139.774,
        "_internal_originalTime": 1689775642000
    },
    {
        "time": 1689775643000,
        "value": 139.774,
        "_internal_originalTime": 1689775643000
    },
    {
        "time": 1689775644000,
        "value": 139.781,
        "_internal_originalTime": 1689775644000
    },
    {
        "time": 1689775646000,
        "value": 139.781,
        "_internal_originalTime": 1689775646000
    },
    {
        "time": 1689775647000,
        "value": 139.781,
        "_internal_originalTime": 1689775647000
    },
    {
        "time": 1689775648000,
        "value": 139.764,
        "_internal_originalTime": 1689775648000
    },
    {
        "time": 1689775649000,
        "value": 139.765,
        "_internal_originalTime": 1689775649000
    },
    {
        "time": 1689775650000,
        "value": 139.764,
        "_internal_originalTime": 1689775650000
    },
    {
        "time": 1689775651000,
        "value": 139.762,
        "_internal_originalTime": 1689775651000
    },
    {
        "time": 1689775652000,
        "value": 139.764,
        "_internal_originalTime": 1689775652000
    },
    {
        "time": 1689775653000,
        "value": 139.764,
        "_internal_originalTime": 1689775653000
    },
    {
        "time": 1689775654000,
        "value": 139.76,
        "_internal_originalTime": 1689775654000
    },
    {
        "time": 1689775655000,
        "value": 139.756,
        "_internal_originalTime": 1689775655000
    },
    {
        "time": 1689775656000,
        "value": 139.757,
        "_internal_originalTime": 1689775656000
    },
    {
        "time": 1689775657000,
        "value": 139.752,
        "_internal_originalTime": 1689775657000
    },
    {
        "time": 1689775661000,
        "value": 139.76,
        "_internal_originalTime": 1689775661000
    },
    {
        "time": 1689775662000,
        "value": 139.76,
        "_internal_originalTime": 1689775662000
    },
    {
        "time": 1689775663000,
        "value": 139.764,
        "_internal_originalTime": 1689775663000
    },
    {
        "time": 1689775664000,
        "value": 139.764,
        "_internal_originalTime": 1689775664000
    },
    {
        "time": 1689775667000,
        "value": 139.763,
        "_internal_originalTime": 1689775667000
    },
    {
        "time": 1689775668000,
        "value": 139.758,
        "_internal_originalTime": 1689775668000
    },
    {
        "time": 1689775669000,
        "value": 139.758,
        "_internal_originalTime": 1689775669000
    },
    {
        "time": 1689775670000,
        "value": 139.764,
        "_internal_originalTime": 1689775670000
    },
    {
        "time": 1689775671000,
        "value": 139.76,
        "_internal_originalTime": 1689775671000
    },
    {
        "time": 1689775673000,
        "value": 139.762,
        "_internal_originalTime": 1689775673000
    },
    {
        "time": 1689775674000,
        "value": 139.763,
        "_internal_originalTime": 1689775674000
    },
    {
        "time": 1689775675000,
        "value": 139.764,
        "_internal_originalTime": 1689775675000
    },
    {
        "time": 1689775676000,
        "value": 139.763,
        "_internal_originalTime": 1689775676000
    },
    {
        "time": 1689775677000,
        "value": 139.759,
        "_internal_originalTime": 1689775677000
    },
    {
        "time": 1689775678000,
        "value": 139.758,
        "_internal_originalTime": 1689775678000
    },
    {
        "time": 1689775679000,
        "value": 139.768,
        "_internal_originalTime": 1689775679000
    },
    {
        "time": 1689775680000,
        "value": 139.768,
        "_internal_originalTime": 1689775680000
    },
    {
        "time": 1689775682000,
        "value": 139.768,
        "_internal_originalTime": 1689775682000
    },
    {
        "time": 1689775683000,
        "value": 139.768,
        "_internal_originalTime": 1689775683000
    },
    {
        "time": 1689775685000,
        "value": 139.764,
        "_internal_originalTime": 1689775685000
    },
    {
        "time": 1689775686000,
        "value": 139.766,
        "_internal_originalTime": 1689775686000
    },
    {
        "time": 1689775687000,
        "value": 139.773,
        "_internal_originalTime": 1689775687000
    },
    {
        "time": 1689775688000,
        "value": 139.772,
        "_internal_originalTime": 1689775688000
    },
    {
        "time": 1689775689000,
        "value": 139.772,
        "_internal_originalTime": 1689775689000
    },
    {
        "time": 1689775692000,
        "value": 139.777,
        "_internal_originalTime": 1689775692000
    },
    {
        "time": 1689775693000,
        "value": 139.782,
        "_internal_originalTime": 1689775693000
    },
    {
        "time": 1689775695000,
        "value": 139.78,
        "_internal_originalTime": 1689775695000
    },
    {
        "time": 1689775696000,
        "value": 139.785,
        "_internal_originalTime": 1689775696000
    },
    {
        "time": 1689775697000,
        "value": 139.785,
        "_internal_originalTime": 1689775697000
    },
    {
        "time": 1689775698000,
        "value": 139.785,
        "_internal_originalTime": 1689775698000
    },
    {
        "time": 1689775700000,
        "value": 139.791,
        "_internal_originalTime": 1689775700000
    },
    {
        "time": 1689775701000,
        "value": 139.793,
        "_internal_originalTime": 1689775701000
    },
    {
        "time": 1689775702000,
        "value": 139.794,
        "_internal_originalTime": 1689775702000
    },
    {
        "time": 1689775703000,
        "value": 139.795,
        "_internal_originalTime": 1689775703000
    },
    {
        "time": 1689775704000,
        "value": 139.794,
        "_internal_originalTime": 1689775704000
    },
    {
        "time": 1689775705000,
        "value": 139.795,
        "_internal_originalTime": 1689775705000
    },
    {
        "time": 1689775708000,
        "value": 139.793,
        "_internal_originalTime": 1689775708000
    },
    {
        "time": 1689775709000,
        "value": 139.791,
        "_internal_originalTime": 1689775709000
    },
    {
        "time": 1689775710000,
        "value": 139.789,
        "_internal_originalTime": 1689775710000
    },
    {
        "time": 1689775712000,
        "value": 139.782,
        "_internal_originalTime": 1689775712000
    },
    {
        "time": 1689775713000,
        "value": 139.78,
        "_internal_originalTime": 1689775713000
    },
    {
        "time": 1689775714000,
        "value": 139.787,
        "_internal_originalTime": 1689775714000
    },
    {
        "time": 1689775716000,
        "value": 139.787,
        "_internal_originalTime": 1689775716000
    },
    {
        "time": 1689775717000,
        "value": 139.785,
        "_internal_originalTime": 1689775717000
    },
    {
        "time": 1689775718000,
        "value": 139.783,
        "_internal_originalTime": 1689775718000
    },
    {
        "time": 1689775720000,
        "value": 139.781,
        "_internal_originalTime": 1689775720000
    },
    {
        "time": 1689775721000,
        "value": 139.774,
        "_internal_originalTime": 1689775721000
    },
    {
        "time": 1689775723000,
        "value": 139.781,
        "_internal_originalTime": 1689775723000
    },
    {
        "time": 1689775726000,
        "value": 139.774,
        "_internal_originalTime": 1689775726000
    },
    {
        "time": 1689775727000,
        "value": 139.779,
        "_internal_originalTime": 1689775727000
    },
    {
        "time": 1689775729000,
        "value": 139.782,
        "_internal_originalTime": 1689775729000
    },
    {
        "time": 1689775730000,
        "value": 139.788,
        "_internal_originalTime": 1689775730000
    },
    {
        "time": 1689775731000,
        "value": 139.788,
        "_internal_originalTime": 1689775731000
    },
    {
        "time": 1689775733000,
        "value": 139.791,
        "_internal_originalTime": 1689775733000
    },
    {
        "time": 1689775734000,
        "value": 139.788,
        "_internal_originalTime": 1689775734000
    },
    {
        "time": 1689775736000,
        "value": 139.795,
        "_internal_originalTime": 1689775736000
    },
    {
        "time": 1689775737000,
        "value": 139.803,
        "_internal_originalTime": 1689775737000
    },
    {
        "time": 1689775739000,
        "value": 139.804,
        "_internal_originalTime": 1689775739000
    },
    {
        "time": 1689775740000,
        "value": 139.807,
        "_internal_originalTime": 1689775740000
    },
    {
        "time": 1689775742000,
        "value": 139.801,
        "_internal_originalTime": 1689775742000
    },
    {
        "time": 1689775743000,
        "value": 139.801,
        "_internal_originalTime": 1689775743000
    },
    {
        "time": 1689775745000,
        "value": 139.801,
        "_internal_originalTime": 1689775745000
    },
    {
        "time": 1689775746000,
        "value": 139.797,
        "_internal_originalTime": 1689775746000
    },
    {
        "time": 1689775748000,
        "value": 139.798,
        "_internal_originalTime": 1689775748000
    },
    {
        "time": 1689775750000,
        "value": 139.802,
        "_internal_originalTime": 1689775750000
    },
    {
        "time": 1689775751000,
        "value": 139.801,
        "_internal_originalTime": 1689775751000
    },
    {
        "time": 1689775752000,
        "value": 139.802,
        "_internal_originalTime": 1689775752000
    },
    {
        "time": 1689775753000,
        "value": 139.801,
        "_internal_originalTime": 1689775753000
    },
    {
        "time": 1689775755000,
        "value": 139.797,
        "_internal_originalTime": 1689775755000
    },
    {
        "time": 1689775756000,
        "value": 139.795,
        "_internal_originalTime": 1689775756000
    },
    {
        "time": 1689775757000,
        "value": 139.792,
        "_internal_originalTime": 1689775757000
    },
    {
        "time": 1689775758000,
        "value": 139.788,
        "_internal_originalTime": 1689775758000
    },
    {
        "time": 1689775759000,
        "value": 139.792,
        "_internal_originalTime": 1689775759000
    },
    {
        "time": 1689775761000,
        "value": 139.794,
        "_internal_originalTime": 1689775761000
    },
    {
        "time": 1689775762000,
        "value": 139.796,
        "_internal_originalTime": 1689775762000
    },
    {
        "time": 1689775763000,
        "value": 139.798,
        "_internal_originalTime": 1689775763000
    },
    {
        "time": 1689775764000,
        "value": 139.798,
        "_internal_originalTime": 1689775764000
    },
    {
        "time": 1689775766000,
        "value": 139.792,
        "_internal_originalTime": 1689775766000
    },
    {
        "time": 1689775767000,
        "value": 139.796,
        "_internal_originalTime": 1689775767000
    },
    {
        "time": 1689775768000,
        "value": 139.796,
        "_internal_originalTime": 1689775768000
    },
    {
        "time": 1689775770000,
        "value": 139.801,
        "_internal_originalTime": 1689775770000
    },
    {
        "time": 1689775771000,
        "value": 139.801,
        "_internal_originalTime": 1689775771000
    },
    {
        "time": 1689775772000,
        "value": 139.801,
        "_internal_originalTime": 1689775772000
    },
    {
        "time": 1689775773000,
        "value": 139.811,
        "_internal_originalTime": 1689775773000
    },
    {
        "time": 1689775774000,
        "value": 139.814,
        "_internal_originalTime": 1689775774000
    },
    {
        "time": 1689775775000,
        "value": 139.825,
        "_internal_originalTime": 1689775775000
    },
    {
        "time": 1689775776000,
        "value": 139.827,
        "_internal_originalTime": 1689775776000
    },
    {
        "time": 1689775777000,
        "value": 139.831,
        "_internal_originalTime": 1689775777000
    },
    {
        "time": 1689775778000,
        "value": 139.83,
        "_internal_originalTime": 1689775778000
    },
    {
        "time": 1689775780000,
        "value": 139.829,
        "_internal_originalTime": 1689775780000
    },
    {
        "time": 1689775781000,
        "value": 139.823,
        "_internal_originalTime": 1689775781000
    },
    {
        "time": 1689775782000,
        "value": 139.831,
        "_internal_originalTime": 1689775782000
    },
    {
        "time": 1689775783000,
        "value": 139.836,
        "_internal_originalTime": 1689775783000
    },
    {
        "time": 1689775785000,
        "value": 139.828,
        "_internal_originalTime": 1689775785000
    },
    {
        "time": 1689775787000,
        "value": 139.833,
        "_internal_originalTime": 1689775787000
    },
    {
        "time": 1689775788000,
        "value": 139.839,
        "_internal_originalTime": 1689775788000
    },
    {
        "time": 1689775789000,
        "value": 139.839,
        "_internal_originalTime": 1689775789000
    },
    {
        "time": 1689775791000,
        "value": 139.834,
        "_internal_originalTime": 1689775791000
    },
    {
        "time": 1689775792000,
        "value": 139.839,
        "_internal_originalTime": 1689775792000
    },
    {
        "time": 1689775793000,
        "value": 139.84,
        "_internal_originalTime": 1689775793000
    },
    {
        "time": 1689775794000,
        "value": 139.841,
        "_internal_originalTime": 1689775794000
    },
    {
        "time": 1689775795000,
        "value": 139.84,
        "_internal_originalTime": 1689775795000
    },
    {
        "time": 1689775797000,
        "value": 139.853,
        "_internal_originalTime": 1689775797000
    },
    {
        "time": 1689775798000,
        "value": 139.844,
        "_internal_originalTime": 1689775798000
    },
    {
        "time": 1689775799000,
        "value": 139.851,
        "_internal_originalTime": 1689775799000
    },
    {
        "time": 1689775800000,
        "value": 139.855,
        "_internal_originalTime": 1689775800000
    },
    {
        "time": 1689775801000,
        "value": 139.869,
        "_internal_originalTime": 1689775801000
    },
    {
        "time": 1689775803000,
        "value": 139.854,
        "_internal_originalTime": 1689775803000
    },
    {
        "time": 1689775805000,
        "value": 139.862,
        "_internal_originalTime": 1689775805000
    },
    {
        "time": 1689775806000,
        "value": 139.864,
        "_internal_originalTime": 1689775806000
    },
    {
        "time": 1689775807000,
        "value": 139.865,
        "_internal_originalTime": 1689775807000
    },
    {
        "time": 1689775808000,
        "value": 139.87,
        "_internal_originalTime": 1689775808000
    },
    {
        "time": 1689775810000,
        "value": 139.862,
        "_internal_originalTime": 1689775810000
    },
    {
        "time": 1689775811000,
        "value": 139.859,
        "_internal_originalTime": 1689775811000
    },
    {
        "time": 1689775812000,
        "value": 139.869,
        "_internal_originalTime": 1689775812000
    },
    {
        "time": 1689775813000,
        "value": 139.867,
        "_internal_originalTime": 1689775813000
    },
    {
        "time": 1689775814000,
        "value": 139.863,
        "_internal_originalTime": 1689775814000
    },
    {
        "time": 1689775816000,
        "value": 139.861,
        "_internal_originalTime": 1689775816000
    },
    {
        "time": 1689775818000,
        "value": 139.87,
        "_internal_originalTime": 1689775818000
    },
    {
        "time": 1689775819000,
        "value": 139.869,
        "_internal_originalTime": 1689775819000
    },
    {
        "time": 1689775820000,
        "value": 139.864,
        "_internal_originalTime": 1689775820000
    },
    {
        "time": 1689775821000,
        "value": 139.87,
        "_internal_originalTime": 1689775821000
    },
    {
        "time": 1689775823000,
        "value": 139.869,
        "_internal_originalTime": 1689775823000
    },
    {
        "time": 1689775824000,
        "value": 139.87,
        "_internal_originalTime": 1689775824000
    },
    {
        "time": 1689775825000,
        "value": 139.874,
        "_internal_originalTime": 1689775825000
    },
    {
        "time": 1689775826000,
        "value": 139.868,
        "_internal_originalTime": 1689775826000
    },
    {
        "time": 1689775828000,
        "value": 139.869,
        "_internal_originalTime": 1689775828000
    },
    {
        "time": 1689775830000,
        "value": 139.869,
        "_internal_originalTime": 1689775830000
    },
    {
        "time": 1689775831000,
        "value": 139.872,
        "_internal_originalTime": 1689775831000
    },
    {
        "time": 1689775832000,
        "value": 139.872,
        "_internal_originalTime": 1689775832000
    },
    {
        "time": 1689775833000,
        "value": 139.856,
        "_internal_originalTime": 1689775833000
    },
    {
        "time": 1689775835000,
        "value": 139.856,
        "_internal_originalTime": 1689775835000
    },
    {
        "time": 1689775837000,
        "value": 139.865,
        "_internal_originalTime": 1689775837000
    },
    {
        "time": 1689775839000,
        "value": 139.867,
        "_internal_originalTime": 1689775839000
    },
    {
        "time": 1689775840000,
        "value": 139.864,
        "_internal_originalTime": 1689775840000
    },
    {
        "time": 1689775842000,
        "value": 139.86,
        "_internal_originalTime": 1689775842000
    },
    {
        "time": 1689775843000,
        "value": 139.854,
        "_internal_originalTime": 1689775843000
    },
    {
        "time": 1689775845000,
        "value": 139.858,
        "_internal_originalTime": 1689775845000
    },
    {
        "time": 1689775846000,
        "value": 139.862,
        "_internal_originalTime": 1689775846000
    },
    {
        "time": 1689775847000,
        "value": 139.858,
        "_internal_originalTime": 1689775847000
    },
    {
        "time": 1689775848000,
        "value": 139.864,
        "_internal_originalTime": 1689775848000
    },
    {
        "time": 1689775849000,
        "value": 139.863,
        "_internal_originalTime": 1689775849000
    },
    {
        "time": 1689775851000,
        "value": 139.856,
        "_internal_originalTime": 1689775851000
    },
    {
        "time": 1689775852000,
        "value": 139.858,
        "_internal_originalTime": 1689775852000
    },
    {
        "time": 1689775853000,
        "value": 139.859,
        "_internal_originalTime": 1689775853000
    },
    {
        "time": 1689775854000,
        "value": 139.858,
        "_internal_originalTime": 1689775854000
    },
    {
        "time": 1689775855000,
        "value": 139.857,
        "_internal_originalTime": 1689775855000
    },
    {
        "time": 1689775857000,
        "value": 139.866,
        "_internal_originalTime": 1689775857000
    },
    {
        "time": 1689775858000,
        "value": 139.864,
        "_internal_originalTime": 1689775858000
    },
    {
        "time": 1689775860000,
        "value": 139.871,
        "_internal_originalTime": 1689775860000
    },
    {
        "time": 1689775861000,
        "value": 139.866,
        "_internal_originalTime": 1689775861000
    },
    {
        "time": 1689775862000,
        "value": 139.872,
        "_internal_originalTime": 1689775862000
    },
    {
        "time": 1689775863000,
        "value": 139.868,
        "_internal_originalTime": 1689775863000
    },
    {
        "time": 1689775864000,
        "value": 139.871,
        "_internal_originalTime": 1689775864000
    },
    {
        "time": 1689775865000,
        "value": 139.87,
        "_internal_originalTime": 1689775865000
    },
    {
        "time": 1689775866000,
        "value": 139.876,
        "_internal_originalTime": 1689775866000
    },
    {
        "time": 1689775867000,
        "value": 139.868,
        "_internal_originalTime": 1689775867000
    },
    {
        "time": 1689775869000,
        "value": 139.866,
        "_internal_originalTime": 1689775869000
    },
    {
        "time": 1689775870000,
        "value": 139.87,
        "_internal_originalTime": 1689775870000
    },
    {
        "time": 1689775871000,
        "value": 139.874,
        "_internal_originalTime": 1689775871000
    },
    {
        "time": 1689775872000,
        "value": 139.874,
        "_internal_originalTime": 1689775872000
    },
    {
        "time": 1689775874000,
        "value": 139.877,
        "_internal_originalTime": 1689775874000
    },
    {
        "time": 1689775875000,
        "value": 139.872,
        "_internal_originalTime": 1689775875000
    },
    {
        "time": 1689775877000,
        "value": 139.871,
        "_internal_originalTime": 1689775877000
    },
    {
        "time": 1689775879000,
        "value": 139.877,
        "_internal_originalTime": 1689775879000
    },
    {
        "time": 1689775880000,
        "value": 139.872,
        "_internal_originalTime": 1689775880000
    },
    {
        "time": 1689775884000,
        "value": 139.868,
        "_internal_originalTime": 1689775884000
    },
    {
        "time": 1689775885000,
        "value": 139.881,
        "_internal_originalTime": 1689775885000
    },
    {
        "time": 1689775886000,
        "value": 139.876,
        "_internal_originalTime": 1689775886000
    },
    {
        "time": 1689775887000,
        "value": 139.875,
        "_internal_originalTime": 1689775887000
    },
    {
        "time": 1689775888000,
        "value": 139.886,
        "_internal_originalTime": 1689775888000
    },
    {
        "time": 1689775889000,
        "value": 139.889,
        "_internal_originalTime": 1689775889000
    },
    {
        "time": 1689775890000,
        "value": 139.89,
        "_internal_originalTime": 1689775890000
    },
    {
        "time": 1689775891000,
        "value": 139.887,
        "_internal_originalTime": 1689775891000
    },
    {
        "time": 1689775892000,
        "value": 139.884,
        "_internal_originalTime": 1689775892000
    },
    {
        "time": 1689775893000,
        "value": 139.885,
        "_internal_originalTime": 1689775893000
    },
    {
        "time": 1689775894000,
        "value": 139.889,
        "_internal_originalTime": 1689775894000
    },
    {
        "time": 1689775895000,
        "value": 139.889,
        "_internal_originalTime": 1689775895000
    },
    {
        "time": 1689775896000,
        "value": 139.891,
        "_internal_originalTime": 1689775896000
    },
    {
        "time": 1689775897000,
        "value": 139.889,
        "_internal_originalTime": 1689775897000
    },
    {
        "time": 1689775898000,
        "value": 139.89,
        "_internal_originalTime": 1689775898000
    },
    {
        "time": 1689775899000,
        "value": 139.887,
        "_internal_originalTime": 1689775899000
    },
    {
        "time": 1689775900000,
        "value": 139.888,
        "_internal_originalTime": 1689775900000
    },
    {
        "time": 1689775901000,
        "value": 139.89,
        "_internal_originalTime": 1689775901000
    },
    {
        "time": 1689775903000,
        "value": 139.896,
        "_internal_originalTime": 1689775903000
    },
    {
        "time": 1689775904000,
        "value": 139.897,
        "_internal_originalTime": 1689775904000
    },
    {
        "time": 1689775905000,
        "value": 139.892,
        "_internal_originalTime": 1689775905000
    },
    {
        "time": 1689775907000,
        "value": 139.893,
        "_internal_originalTime": 1689775907000
    },
    {
        "time": 1689775908000,
        "value": 139.893,
        "_internal_originalTime": 1689775908000
    },
    {
        "time": 1689775909000,
        "value": 139.892,
        "_internal_originalTime": 1689775909000
    },
    {
        "time": 1689775910000,
        "value": 139.89,
        "_internal_originalTime": 1689775910000
    },
    {
        "time": 1689775911000,
        "value": 139.894,
        "_internal_originalTime": 1689775911000
    },
    {
        "time": 1689775912000,
        "value": 139.898,
        "_internal_originalTime": 1689775912000
    },
    {
        "time": 1689775913000,
        "value": 139.89,
        "_internal_originalTime": 1689775913000
    },
    {
        "time": 1689775914000,
        "value": 139.894,
        "_internal_originalTime": 1689775914000
    },
    {
        "time": 1689775915000,
        "value": 139.892,
        "_internal_originalTime": 1689775915000
    },
    {
        "time": 1689775916000,
        "value": 139.888,
        "_internal_originalTime": 1689775916000
    },
    {
        "time": 1689775917000,
        "value": 139.888,
        "_internal_originalTime": 1689775917000
    },
    {
        "time": 1689775918000,
        "value": 139.887,
        "_internal_originalTime": 1689775918000
    },
    {
        "time": 1689775919000,
        "value": 139.889,
        "_internal_originalTime": 1689775919000
    },
    {
        "time": 1689775920000,
        "value": 139.892,
        "_internal_originalTime": 1689775920000
    },
    {
        "time": 1689775921000,
        "value": 139.896,
        "_internal_originalTime": 1689775921000
    },
    {
        "time": 1689775922000,
        "value": 139.896,
        "_internal_originalTime": 1689775922000
    },
    {
        "time": 1689775923000,
        "value": 139.896,
        "_internal_originalTime": 1689775923000
    },
    {
        "time": 1689775924000,
        "value": 139.897,
        "_internal_originalTime": 1689775924000
    },
    {
        "time": 1689775925000,
        "value": 139.895,
        "_internal_originalTime": 1689775925000
    },
    {
        "time": 1689775926000,
        "value": 139.902,
        "_internal_originalTime": 1689775926000
    },
    {
        "time": 1689775927000,
        "value": 139.898,
        "_internal_originalTime": 1689775927000
    },
    {
        "time": 1689775928000,
        "value": 139.896,
        "_internal_originalTime": 1689775928000
    },
    {
        "time": 1689775929000,
        "value": 139.891,
        "_internal_originalTime": 1689775929000
    },
    {
        "time": 1689775930000,
        "value": 139.894,
        "_internal_originalTime": 1689775930000
    },
    {
        "time": 1689775931000,
        "value": 139.892,
        "_internal_originalTime": 1689775931000
    },
    {
        "time": 1689775932000,
        "value": 139.894,
        "_internal_originalTime": 1689775932000
    },
    {
        "time": 1689775933000,
        "value": 139.897,
        "_internal_originalTime": 1689775933000
    },
    {
        "time": 1689775934000,
        "value": 139.895,
        "_internal_originalTime": 1689775934000
    },
    {
        "time": 1689775935000,
        "value": 139.887,
        "_internal_originalTime": 1689775935000
    },
    {
        "time": 1689775936000,
        "value": 139.89,
        "_internal_originalTime": 1689775936000
    },
    {
        "time": 1689775937000,
        "value": 139.892,
        "_internal_originalTime": 1689775937000
    },
    {
        "time": 1689775938000,
        "value": 139.893,
        "_internal_originalTime": 1689775938000
    },
    {
        "time": 1689775939000,
        "value": 139.897,
        "_internal_originalTime": 1689775939000
    },
    {
        "time": 1689775942000,
        "value": 139.9,
        "_internal_originalTime": 1689775942000
    },
    {
        "time": 1689775944000,
        "value": 139.902,
        "_internal_originalTime": 1689775944000
    },
    {
        "time": 1689775945000,
        "value": 139.896,
        "_internal_originalTime": 1689775945000
    },
    {
        "time": 1689775946000,
        "value": 139.898,
        "_internal_originalTime": 1689775946000
    },
    {
        "time": 1689775947000,
        "value": 139.897,
        "_internal_originalTime": 1689775947000
    },
    {
        "time": 1689775948000,
        "value": 139.901,
        "_internal_originalTime": 1689775948000
    },
    {
        "time": 1689775949000,
        "value": 139.897,
        "_internal_originalTime": 1689775949000
    },
    {
        "time": 1689775950000,
        "value": 139.899,
        "_internal_originalTime": 1689775950000
    },
    {
        "time": 1689775951000,
        "value": 139.894,
        "_internal_originalTime": 1689775951000
    },
    {
        "time": 1689775952000,
        "value": 139.897,
        "_internal_originalTime": 1689775952000
    },
    {
        "time": 1689775953000,
        "value": 139.896,
        "_internal_originalTime": 1689775953000
    },
    {
        "time": 1689775955000,
        "value": 139.898,
        "_internal_originalTime": 1689775955000
    },
    {
        "time": 1689775956000,
        "value": 139.899,
        "_internal_originalTime": 1689775956000
    },
    {
        "time": 1689775957000,
        "value": 139.897,
        "_internal_originalTime": 1689775957000
    },
    {
        "time": 1689775959000,
        "value": 139.89,
        "_internal_originalTime": 1689775959000
    },
    {
        "time": 1689775960000,
        "value": 139.891,
        "_internal_originalTime": 1689775960000
    },
    {
        "time": 1689775961000,
        "value": 139.894,
        "_internal_originalTime": 1689775961000
    },
    {
        "time": 1689775962000,
        "value": 139.89,
        "_internal_originalTime": 1689775962000
    },
    {
        "time": 1689775964000,
        "value": 139.888,
        "_internal_originalTime": 1689775964000
    },
    {
        "time": 1689775966000,
        "value": 139.894,
        "_internal_originalTime": 1689775966000
    },
    {
        "time": 1689775967000,
        "value": 139.899,
        "_internal_originalTime": 1689775967000
    },
    {
        "time": 1689775968000,
        "value": 139.898,
        "_internal_originalTime": 1689775968000
    },
    {
        "time": 1689775969000,
        "value": 139.906,
        "_internal_originalTime": 1689775969000
    },
    {
        "time": 1689775971000,
        "value": 139.901,
        "_internal_originalTime": 1689775971000
    },
    {
        "time": 1689775972000,
        "value": 139.899,
        "_internal_originalTime": 1689775972000
    },
    {
        "time": 1689775973000,
        "value": 139.902,
        "_internal_originalTime": 1689775973000
    },
    {
        "time": 1689775974000,
        "value": 139.905,
        "_internal_originalTime": 1689775974000
    },
    {
        "time": 1689775975000,
        "value": 139.896,
        "_internal_originalTime": 1689775975000
    },
    {
        "time": 1689775977000,
        "value": 139.908,
        "_internal_originalTime": 1689775977000
    },
    {
        "time": 1689775978000,
        "value": 139.914,
        "_internal_originalTime": 1689775978000
    },
    {
        "time": 1689775979000,
        "value": 139.922,
        "_internal_originalTime": 1689775979000
    },
    {
        "time": 1689775980000,
        "value": 139.926,
        "_internal_originalTime": 1689775980000
    },
    {
        "time": 1689775981000,
        "value": 139.926,
        "_internal_originalTime": 1689775981000
    },
    {
        "time": 1689775983000,
        "value": 139.918,
        "_internal_originalTime": 1689775983000
    },
    {
        "time": 1689775985000,
        "value": 139.918,
        "_internal_originalTime": 1689775985000
    },
    {
        "time": 1689775986000,
        "value": 139.918,
        "_internal_originalTime": 1689775986000
    },
    {
        "time": 1689775987000,
        "value": 139.925,
        "_internal_originalTime": 1689775987000
    },
    {
        "time": 1689775988000,
        "value": 139.926,
        "_internal_originalTime": 1689775988000
    },
    {
        "time": 1689775990000,
        "value": 139.925,
        "_internal_originalTime": 1689775990000
    },
    {
        "time": 1689775991000,
        "value": 139.922,
        "_internal_originalTime": 1689775991000
    },
    {
        "time": 1689775992000,
        "value": 139.92,
        "_internal_originalTime": 1689775992000
    },
    {
        "time": 1689775993000,
        "value": 139.925,
        "_internal_originalTime": 1689775993000
    },
    {
        "time": 1689775995000,
        "value": 139.933,
        "_internal_originalTime": 1689775995000
    },
    {
        "time": 1689775996000,
        "value": 139.927,
        "_internal_originalTime": 1689775996000
    },
    {
        "time": 1689775997000,
        "value": 139.928,
        "_internal_originalTime": 1689775997000
    },
    {
        "time": 1689775998000,
        "value": 139.932,
        "_internal_originalTime": 1689775998000
    },
    {
        "time": 1689775999000,
        "value": 139.928,
        "_internal_originalTime": 1689775999000
    },
    {
        "time": 1689776000000,
        "value": 139.941,
        "_internal_originalTime": 1689776000000
    },
    {
        "time": 1689776001000,
        "value": 139.943,
        "_internal_originalTime": 1689776001000
    },
    {
        "time": 1689776002000,
        "value": 139.94,
        "_internal_originalTime": 1689776002000
    },
    {
        "time": 1689776003000,
        "value": 139.936,
        "_internal_originalTime": 1689776003000
    },
    {
        "time": 1689776004000,
        "value": 139.938,
        "_internal_originalTime": 1689776004000
    },
    {
        "time": 1689776005000,
        "value": 139.942,
        "_internal_originalTime": 1689776005000
    },
    {
        "time": 1689776006000,
        "value": 139.942,
        "_internal_originalTime": 1689776006000
    },
    {
        "time": 1689776007000,
        "value": 139.939,
        "_internal_originalTime": 1689776007000
    },
    {
        "time": 1689776008000,
        "value": 139.935,
        "_internal_originalTime": 1689776008000
    },
    {
        "time": 1689776009000,
        "value": 139.943,
        "_internal_originalTime": 1689776009000
    },
    {
        "time": 1689776011000,
        "value": 139.942,
        "_internal_originalTime": 1689776011000
    },
    {
        "time": 1689776012000,
        "value": 139.94,
        "_internal_originalTime": 1689776012000
    },
    {
        "time": 1689776014000,
        "value": 139.942,
        "_internal_originalTime": 1689776014000
    },
    {
        "time": 1689776015000,
        "value": 139.945,
        "_internal_originalTime": 1689776015000
    },
    {
        "time": 1689776016000,
        "value": 139.936,
        "_internal_originalTime": 1689776016000
    },
    {
        "time": 1689776017000,
        "value": 139.942,
        "_internal_originalTime": 1689776017000
    },
    {
        "time": 1689776018000,
        "value": 139.928,
        "_internal_originalTime": 1689776018000
    },
    {
        "time": 1689776019000,
        "value": 139.924,
        "_internal_originalTime": 1689776019000
    },
    {
        "time": 1689776020000,
        "value": 139.933,
        "_internal_originalTime": 1689776020000
    },
    {
        "time": 1689776021000,
        "value": 139.94,
        "_internal_originalTime": 1689776021000
    },
    {
        "time": 1689776023000,
        "value": 139.938,
        "_internal_originalTime": 1689776023000
    },
    {
        "time": 1689776024000,
        "value": 139.945,
        "_internal_originalTime": 1689776024000
    },
    {
        "time": 1689776026000,
        "value": 139.945,
        "_internal_originalTime": 1689776026000
    },
    {
        "time": 1689776027000,
        "value": 139.942,
        "_internal_originalTime": 1689776027000
    },
    {
        "time": 1689776028000,
        "value": 139.94,
        "_internal_originalTime": 1689776028000
    },
    {
        "time": 1689776029000,
        "value": 139.942,
        "_internal_originalTime": 1689776029000
    },
    {
        "time": 1689776030000,
        "value": 139.943,
        "_internal_originalTime": 1689776030000
    },
    {
        "time": 1689776031000,
        "value": 139.951,
        "_internal_originalTime": 1689776031000
    },
    {
        "time": 1689776032000,
        "value": 139.942,
        "_internal_originalTime": 1689776032000
    },
    {
        "time": 1689776034000,
        "value": 139.946,
        "_internal_originalTime": 1689776034000
    },
    {
        "time": 1689776035000,
        "value": 139.946,
        "_internal_originalTime": 1689776035000
    },
    {
        "time": 1689776036000,
        "value": 139.934,
        "_internal_originalTime": 1689776036000
    },
    {
        "time": 1689776038000,
        "value": 139.929,
        "_internal_originalTime": 1689776038000
    },
    {
        "time": 1689776039000,
        "value": 139.93,
        "_internal_originalTime": 1689776039000
    },
    {
        "time": 1689776040000,
        "value": 139.93,
        "_internal_originalTime": 1689776040000
    },
    {
        "time": 1689776042000,
        "value": 139.932,
        "_internal_originalTime": 1689776042000
    },
    {
        "time": 1689776043000,
        "value": 139.938,
        "_internal_originalTime": 1689776043000
    },
    {
        "time": 1689776044000,
        "value": 139.929,
        "_internal_originalTime": 1689776044000
    },
    {
        "time": 1689776046000,
        "value": 139.927,
        "_internal_originalTime": 1689776046000
    },
    {
        "time": 1689776047000,
        "value": 139.926,
        "_internal_originalTime": 1689776047000
    },
    {
        "time": 1689776048000,
        "value": 139.929,
        "_internal_originalTime": 1689776048000
    },
    {
        "time": 1689776049000,
        "value": 139.928,
        "_internal_originalTime": 1689776049000
    },
    {
        "time": 1689776050000,
        "value": 139.921,
        "_internal_originalTime": 1689776050000
    },
    {
        "time": 1689776051000,
        "value": 139.925,
        "_internal_originalTime": 1689776051000
    },
    {
        "time": 1689776053000,
        "value": 139.922,
        "_internal_originalTime": 1689776053000
    },
    {
        "time": 1689776054000,
        "value": 139.918,
        "_internal_originalTime": 1689776054000
    },
    {
        "time": 1689776055000,
        "value": 139.917,
        "_internal_originalTime": 1689776055000
    },
    {
        "time": 1689776056000,
        "value": 139.93,
        "_internal_originalTime": 1689776056000
    },
    {
        "time": 1689776058000,
        "value": 139.929,
        "_internal_originalTime": 1689776058000
    },
    {
        "time": 1689776059000,
        "value": 139.928,
        "_internal_originalTime": 1689776059000
    },
    {
        "time": 1689776061000,
        "value": 139.921,
        "_internal_originalTime": 1689776061000
    },
    {
        "time": 1689776062000,
        "value": 139.924,
        "_internal_originalTime": 1689776062000
    },
    {
        "time": 1689776064000,
        "value": 139.925,
        "_internal_originalTime": 1689776064000
    },
    {
        "time": 1689776065000,
        "value": 139.928,
        "_internal_originalTime": 1689776065000
    },
    {
        "time": 1689776066000,
        "value": 139.929,
        "_internal_originalTime": 1689776066000
    },
    {
        "time": 1689776067000,
        "value": 139.928,
        "_internal_originalTime": 1689776067000
    },
    {
        "time": 1689776068000,
        "value": 139.923,
        "_internal_originalTime": 1689776068000
    },
    {
        "time": 1689776069000,
        "value": 139.925,
        "_internal_originalTime": 1689776069000
    },
    {
        "time": 1689776070000,
        "value": 139.922,
        "_internal_originalTime": 1689776070000
    },
    {
        "time": 1689776071000,
        "value": 139.919,
        "_internal_originalTime": 1689776071000
    },
    {
        "time": 1689776073000,
        "value": 139.915,
        "_internal_originalTime": 1689776073000
    },
    {
        "time": 1689776074000,
        "value": 139.917,
        "_internal_originalTime": 1689776074000
    },
    {
        "time": 1689776075000,
        "value": 139.919,
        "_internal_originalTime": 1689776075000
    },
    {
        "time": 1689776076000,
        "value": 139.923,
        "_internal_originalTime": 1689776076000
    },
    {
        "time": 1689776077000,
        "value": 139.916,
        "_internal_originalTime": 1689776077000
    },
    {
        "time": 1689776078000,
        "value": 139.92,
        "_internal_originalTime": 1689776078000
    },
    {
        "time": 1689776080000,
        "value": 139.919,
        "_internal_originalTime": 1689776080000
    },
    {
        "time": 1689776081000,
        "value": 139.916,
        "_internal_originalTime": 1689776081000
    },
    {
        "time": 1689776082000,
        "value": 139.922,
        "_internal_originalTime": 1689776082000
    },
    {
        "time": 1689776083000,
        "value": 139.919,
        "_internal_originalTime": 1689776083000
    },
    {
        "time": 1689776085000,
        "value": 139.925,
        "_internal_originalTime": 1689776085000
    },
    {
        "time": 1689776086000,
        "value": 139.923,
        "_internal_originalTime": 1689776086000
    },
    {
        "time": 1689776087000,
        "value": 139.922,
        "_internal_originalTime": 1689776087000
    },
    {
        "time": 1689776088000,
        "value": 139.921,
        "_internal_originalTime": 1689776088000
    },
    {
        "time": 1689776089000,
        "value": 139.922,
        "_internal_originalTime": 1689776089000
    },
    {
        "time": 1689776090000,
        "value": 139.916,
        "_internal_originalTime": 1689776090000
    },
    {
        "time": 1689776092000,
        "value": 139.92,
        "_internal_originalTime": 1689776092000
    },
    {
        "time": 1689776093000,
        "value": 139.926,
        "_internal_originalTime": 1689776093000
    },
    {
        "time": 1689776097000,
        "value": 139.929,
        "_internal_originalTime": 1689776097000
    },
    {
        "time": 1689776098000,
        "value": 139.928,
        "_internal_originalTime": 1689776098000
    },
    {
        "time": 1689776099000,
        "value": 139.928,
        "_internal_originalTime": 1689776099000
    },
    {
        "time": 1689776100000,
        "value": 139.946,
        "_internal_originalTime": 1689776100000
    },
    {
        "time": 1689776102000,
        "value": 139.945,
        "_internal_originalTime": 1689776102000
    },
    {
        "time": 1689776103000,
        "value": 139.949,
        "_internal_originalTime": 1689776103000
    },
    {
        "time": 1689776104000,
        "value": 139.951,
        "_internal_originalTime": 1689776104000
    },
    {
        "time": 1689776106000,
        "value": 139.944,
        "_internal_originalTime": 1689776106000
    },
    {
        "time": 1689776107000,
        "value": 139.947,
        "_internal_originalTime": 1689776107000
    },
    {
        "time": 1689776108000,
        "value": 139.945,
        "_internal_originalTime": 1689776108000
    },
    {
        "time": 1689776109000,
        "value": 139.94,
        "_internal_originalTime": 1689776109000
    },
    {
        "time": 1689776111000,
        "value": 139.947,
        "_internal_originalTime": 1689776111000
    },
    {
        "time": 1689776112000,
        "value": 139.943,
        "_internal_originalTime": 1689776112000
    },
    {
        "time": 1689776113000,
        "value": 139.945,
        "_internal_originalTime": 1689776113000
    },
    {
        "time": 1689776115000,
        "value": 139.945,
        "_internal_originalTime": 1689776115000
    },
    {
        "time": 1689776116000,
        "value": 139.945,
        "_internal_originalTime": 1689776116000
    },
    {
        "time": 1689776117000,
        "value": 139.938,
        "_internal_originalTime": 1689776117000
    },
    {
        "time": 1689776119000,
        "value": 139.932,
        "_internal_originalTime": 1689776119000
    },
    {
        "time": 1689776120000,
        "value": 139.926,
        "_internal_originalTime": 1689776120000
    },
    {
        "time": 1689776121000,
        "value": 139.922,
        "_internal_originalTime": 1689776121000
    },
    {
        "time": 1689776123000,
        "value": 139.931,
        "_internal_originalTime": 1689776123000
    },
    {
        "time": 1689776124000,
        "value": 139.928,
        "_internal_originalTime": 1689776124000
    },
    {
        "time": 1689776125000,
        "value": 139.928,
        "_internal_originalTime": 1689776125000
    },
    {
        "time": 1689776127000,
        "value": 139.932,
        "_internal_originalTime": 1689776127000
    },
    {
        "time": 1689776128000,
        "value": 139.928,
        "_internal_originalTime": 1689776128000
    },
    {
        "time": 1689776129000,
        "value": 139.926,
        "_internal_originalTime": 1689776129000
    },
    {
        "time": 1689776130000,
        "value": 139.926,
        "_internal_originalTime": 1689776130000
    },
    {
        "time": 1689776131000,
        "value": 139.926,
        "_internal_originalTime": 1689776131000
    },
    {
        "time": 1689776132000,
        "value": 139.926,
        "_internal_originalTime": 1689776132000
    },
    {
        "time": 1689776133000,
        "value": 139.926,
        "_internal_originalTime": 1689776133000
    },
    {
        "time": 1689776135000,
        "value": 139.926,
        "_internal_originalTime": 1689776135000
    },
    {
        "time": 1689776136000,
        "value": 139.922,
        "_internal_originalTime": 1689776136000
    },
    {
        "time": 1689776137000,
        "value": 139.926,
        "_internal_originalTime": 1689776137000
    },
    {
        "time": 1689776138000,
        "value": 139.928,
        "_internal_originalTime": 1689776138000
    },
    {
        "time": 1689776140000,
        "value": 139.923,
        "_internal_originalTime": 1689776140000
    },
    {
        "time": 1689776141000,
        "value": 139.922,
        "_internal_originalTime": 1689776141000
    },
    {
        "time": 1689776142000,
        "value": 139.924,
        "_internal_originalTime": 1689776142000
    },
    {
        "time": 1689776143000,
        "value": 139.926,
        "_internal_originalTime": 1689776143000
    },
    {
        "time": 1689776145000,
        "value": 139.926,
        "_internal_originalTime": 1689776145000
    },
    {
        "time": 1689776146000,
        "value": 139.922,
        "_internal_originalTime": 1689776146000
    },
    {
        "time": 1689776148000,
        "value": 139.924,
        "_internal_originalTime": 1689776148000
    },
    {
        "time": 1689776149000,
        "value": 139.924,
        "_internal_originalTime": 1689776149000
    },
    {
        "time": 1689776152000,
        "value": 139.913,
        "_internal_originalTime": 1689776152000
    },
    {
        "time": 1689776153000,
        "value": 139.918,
        "_internal_originalTime": 1689776153000
    },
    {
        "time": 1689776154000,
        "value": 139.92,
        "_internal_originalTime": 1689776154000
    },
    {
        "time": 1689776155000,
        "value": 139.915,
        "_internal_originalTime": 1689776155000
    },
    {
        "time": 1689776157000,
        "value": 139.916,
        "_internal_originalTime": 1689776157000
    },
    {
        "time": 1689776158000,
        "value": 139.915,
        "_internal_originalTime": 1689776158000
    },
    {
        "time": 1689776159000,
        "value": 139.91,
        "_internal_originalTime": 1689776159000
    },
    {
        "time": 1689776160000,
        "value": 139.916,
        "_internal_originalTime": 1689776160000
    },
    {
        "time": 1689776161000,
        "value": 139.918,
        "_internal_originalTime": 1689776161000
    },
    {
        "time": 1689776162000,
        "value": 139.916,
        "_internal_originalTime": 1689776162000
    },
    {
        "time": 1689776164000,
        "value": 139.916,
        "_internal_originalTime": 1689776164000
    },
    {
        "time": 1689776165000,
        "value": 139.908,
        "_internal_originalTime": 1689776165000
    },
    {
        "time": 1689776166000,
        "value": 139.909,
        "_internal_originalTime": 1689776166000
    },
    {
        "time": 1689776167000,
        "value": 139.911,
        "_internal_originalTime": 1689776167000
    },
    {
        "time": 1689776169000,
        "value": 139.899,
        "_internal_originalTime": 1689776169000
    },
    {
        "time": 1689776170000,
        "value": 139.905,
        "_internal_originalTime": 1689776170000
    },
    {
        "time": 1689776171000,
        "value": 139.894,
        "_internal_originalTime": 1689776171000
    },
    {
        "time": 1689776172000,
        "value": 139.899,
        "_internal_originalTime": 1689776172000
    },
    {
        "time": 1689776173000,
        "value": 139.897,
        "_internal_originalTime": 1689776173000
    },
    {
        "time": 1689776175000,
        "value": 139.893,
        "_internal_originalTime": 1689776175000
    },
    {
        "time": 1689776176000,
        "value": 139.886,
        "_internal_originalTime": 1689776176000
    },
    {
        "time": 1689776177000,
        "value": 139.894,
        "_internal_originalTime": 1689776177000
    },
    {
        "time": 1689776179000,
        "value": 139.897,
        "_internal_originalTime": 1689776179000
    },
    {
        "time": 1689776180000,
        "value": 139.896,
        "_internal_originalTime": 1689776180000
    },
    {
        "time": 1689776181000,
        "value": 139.89,
        "_internal_originalTime": 1689776181000
    },
    {
        "time": 1689776183000,
        "value": 139.892,
        "_internal_originalTime": 1689776183000
    },
    {
        "time": 1689776184000,
        "value": 139.888,
        "_internal_originalTime": 1689776184000
    },
    {
        "time": 1689776185000,
        "value": 139.881,
        "_internal_originalTime": 1689776185000
    },
    {
        "time": 1689776187000,
        "value": 139.881,
        "_internal_originalTime": 1689776187000
    },
    {
        "time": 1689776188000,
        "value": 139.883,
        "_internal_originalTime": 1689776188000
    },
    {
        "time": 1689776189000,
        "value": 139.879,
        "_internal_originalTime": 1689776189000
    },
    {
        "time": 1689776190000,
        "value": 139.879,
        "_internal_originalTime": 1689776190000
    },
    {
        "time": 1689776191000,
        "value": 139.879,
        "_internal_originalTime": 1689776191000
    },
    {
        "time": 1689776193000,
        "value": 139.872,
        "_internal_originalTime": 1689776193000
    },
    {
        "time": 1689776194000,
        "value": 139.877,
        "_internal_originalTime": 1689776194000
    },
    {
        "time": 1689776195000,
        "value": 139.88,
        "_internal_originalTime": 1689776195000
    },
    {
        "time": 1689776196000,
        "value": 139.879,
        "_internal_originalTime": 1689776196000
    },
    {
        "time": 1689776197000,
        "value": 139.876,
        "_internal_originalTime": 1689776197000
    },
    {
        "time": 1689776199000,
        "value": 139.882,
        "_internal_originalTime": 1689776199000
    },
    {
        "time": 1689776200000,
        "value": 139.881,
        "_internal_originalTime": 1689776200000
    },
    {
        "time": 1689776202000,
        "value": 139.884,
        "_internal_originalTime": 1689776202000
    },
    {
        "time": 1689776203000,
        "value": 139.882,
        "_internal_originalTime": 1689776203000
    },
    {
        "time": 1689776205000,
        "value": 139.881,
        "_internal_originalTime": 1689776205000
    },
    {
        "time": 1689776206000,
        "value": 139.895,
        "_internal_originalTime": 1689776206000
    },
    {
        "time": 1689776208000,
        "value": 139.895,
        "_internal_originalTime": 1689776208000
    },
    {
        "time": 1689776209000,
        "value": 139.895,
        "_internal_originalTime": 1689776209000
    },
    {
        "time": 1689776210000,
        "value": 139.896,
        "_internal_originalTime": 1689776210000
    },
    {
        "time": 1689776211000,
        "value": 139.891,
        "_internal_originalTime": 1689776211000
    },
    {
        "time": 1689776213000,
        "value": 139.895,
        "_internal_originalTime": 1689776213000
    },
    {
        "time": 1689776214000,
        "value": 139.893,
        "_internal_originalTime": 1689776214000
    },
    {
        "time": 1689776215000,
        "value": 139.899,
        "_internal_originalTime": 1689776215000
    },
    {
        "time": 1689776216000,
        "value": 139.903,
        "_internal_originalTime": 1689776216000
    },
    {
        "time": 1689776217000,
        "value": 139.898,
        "_internal_originalTime": 1689776217000
    },
    {
        "time": 1689776218000,
        "value": 139.899,
        "_internal_originalTime": 1689776218000
    },
    {
        "time": 1689776220000,
        "value": 139.902,
        "_internal_originalTime": 1689776220000
    },
    {
        "time": 1689776222000,
        "value": 139.905,
        "_internal_originalTime": 1689776222000
    },
    {
        "time": 1689776223000,
        "value": 139.905,
        "_internal_originalTime": 1689776223000
    },
    {
        "time": 1689776225000,
        "value": 139.909,
        "_internal_originalTime": 1689776225000
    },
    {
        "time": 1689776226000,
        "value": 139.912,
        "_internal_originalTime": 1689776226000
    },
    {
        "time": 1689776228000,
        "value": 139.904,
        "_internal_originalTime": 1689776228000
    },
    {
        "time": 1689776229000,
        "value": 139.908,
        "_internal_originalTime": 1689776229000
    },
    {
        "time": 1689776230000,
        "value": 139.908,
        "_internal_originalTime": 1689776230000
    },
    {
        "time": 1689776232000,
        "value": 139.901,
        "_internal_originalTime": 1689776232000
    },
    {
        "time": 1689776233000,
        "value": 139.905,
        "_internal_originalTime": 1689776233000
    },
    {
        "time": 1689776234000,
        "value": 139.908,
        "_internal_originalTime": 1689776234000
    },
    {
        "time": 1689776235000,
        "value": 139.904,
        "_internal_originalTime": 1689776235000
    },
    {
        "time": 1689776237000,
        "value": 139.898,
        "_internal_originalTime": 1689776237000
    },
    {
        "time": 1689776238000,
        "value": 139.902,
        "_internal_originalTime": 1689776238000
    },
    {
        "time": 1689776239000,
        "value": 139.9,
        "_internal_originalTime": 1689776239000
    },
    {
        "time": 1689776242000,
        "value": 139.906,
        "_internal_originalTime": 1689776242000
    },
    {
        "time": 1689776243000,
        "value": 139.905,
        "_internal_originalTime": 1689776243000
    },
    {
        "time": 1689776244000,
        "value": 139.906,
        "_internal_originalTime": 1689776244000
    },
    {
        "time": 1689776245000,
        "value": 139.905,
        "_internal_originalTime": 1689776245000
    },
    {
        "time": 1689776246000,
        "value": 139.904,
        "_internal_originalTime": 1689776246000
    },
    {
        "time": 1689776248000,
        "value": 139.9,
        "_internal_originalTime": 1689776248000
    },
    {
        "time": 1689776249000,
        "value": 139.903,
        "_internal_originalTime": 1689776249000
    },
    {
        "time": 1689776250000,
        "value": 139.902,
        "_internal_originalTime": 1689776250000
    },
    {
        "time": 1689776251000,
        "value": 139.903,
        "_internal_originalTime": 1689776251000
    },
    {
        "time": 1689776252000,
        "value": 139.902,
        "_internal_originalTime": 1689776252000
    },
    {
        "time": 1689776253000,
        "value": 139.905,
        "_internal_originalTime": 1689776253000
    },
    {
        "time": 1689776254000,
        "value": 139.902,
        "_internal_originalTime": 1689776254000
    },
    {
        "time": 1689776256000,
        "value": 139.905,
        "_internal_originalTime": 1689776256000
    },
    {
        "time": 1689776257000,
        "value": 139.905,
        "_internal_originalTime": 1689776257000
    },
    {
        "time": 1689776258000,
        "value": 139.903,
        "_internal_originalTime": 1689776258000
    },
    {
        "time": 1689776262000,
        "value": 139.905,
        "_internal_originalTime": 1689776262000
    },
    {
        "time": 1689776263000,
        "value": 139.904,
        "_internal_originalTime": 1689776263000
    },
    {
        "time": 1689776265000,
        "value": 139.89,
        "_internal_originalTime": 1689776265000
    },
    {
        "time": 1689776266000,
        "value": 139.893,
        "_internal_originalTime": 1689776266000
    },
    {
        "time": 1689776268000,
        "value": 139.895,
        "_internal_originalTime": 1689776268000
    },
    {
        "time": 1689776269000,
        "value": 139.886,
        "_internal_originalTime": 1689776269000
    },
    {
        "time": 1689776270000,
        "value": 139.883,
        "_internal_originalTime": 1689776270000
    },
    {
        "time": 1689776272000,
        "value": 139.887,
        "_internal_originalTime": 1689776272000
    },
    {
        "time": 1689776273000,
        "value": 139.883,
        "_internal_originalTime": 1689776273000
    },
    {
        "time": 1689776275000,
        "value": 139.888,
        "_internal_originalTime": 1689776275000
    },
    {
        "time": 1689776277000,
        "value": 139.885,
        "_internal_originalTime": 1689776277000
    },
    {
        "time": 1689776278000,
        "value": 139.898,
        "_internal_originalTime": 1689776278000
    },
    {
        "time": 1689776279000,
        "value": 139.905,
        "_internal_originalTime": 1689776279000
    },
    {
        "time": 1689776280000,
        "value": 139.906,
        "_internal_originalTime": 1689776280000
    },
    {
        "time": 1689776281000,
        "value": 139.904,
        "_internal_originalTime": 1689776281000
    },
    {
        "time": 1689776282000,
        "value": 139.913,
        "_internal_originalTime": 1689776282000
    },
    {
        "time": 1689776284000,
        "value": 139.922,
        "_internal_originalTime": 1689776284000
    },
    {
        "time": 1689776285000,
        "value": 139.921,
        "_internal_originalTime": 1689776285000
    },
    {
        "time": 1689776286000,
        "value": 139.914,
        "_internal_originalTime": 1689776286000
    },
    {
        "time": 1689776287000,
        "value": 139.912,
        "_internal_originalTime": 1689776287000
    },
    {
        "time": 1689776289000,
        "value": 139.922,
        "_internal_originalTime": 1689776289000
    },
    {
        "time": 1689776291000,
        "value": 139.909,
        "_internal_originalTime": 1689776291000
    },
    {
        "time": 1689776292000,
        "value": 139.905,
        "_internal_originalTime": 1689776292000
    },
    {
        "time": 1689776294000,
        "value": 139.91,
        "_internal_originalTime": 1689776294000
    },
    {
        "time": 1689776295000,
        "value": 139.911,
        "_internal_originalTime": 1689776295000
    },
    {
        "time": 1689776296000,
        "value": 139.909,
        "_internal_originalTime": 1689776296000
    },
    {
        "time": 1689776300000,
        "value": 139.905,
        "_internal_originalTime": 1689776300000
    },
    {
        "time": 1689776302000,
        "value": 139.904,
        "_internal_originalTime": 1689776302000
    },
    {
        "time": 1689776303000,
        "value": 139.912,
        "_internal_originalTime": 1689776303000
    },
    {
        "time": 1689776305000,
        "value": 139.917,
        "_internal_originalTime": 1689776305000
    },
    {
        "time": 1689776307000,
        "value": 139.915,
        "_internal_originalTime": 1689776307000
    },
    {
        "time": 1689776308000,
        "value": 139.912,
        "_internal_originalTime": 1689776308000
    },
    {
        "time": 1689776309000,
        "value": 139.921,
        "_internal_originalTime": 1689776309000
    },
    {
        "time": 1689776311000,
        "value": 139.921,
        "_internal_originalTime": 1689776311000
    },
    {
        "time": 1689776312000,
        "value": 139.929,
        "_internal_originalTime": 1689776312000
    },
    {
        "time": 1689776313000,
        "value": 139.928,
        "_internal_originalTime": 1689776313000
    },
    {
        "time": 1689776315000,
        "value": 139.934,
        "_internal_originalTime": 1689776315000
    },
    {
        "time": 1689776316000,
        "value": 139.93,
        "_internal_originalTime": 1689776316000
    },
    {
        "time": 1689776317000,
        "value": 139.935,
        "_internal_originalTime": 1689776317000
    },
    {
        "time": 1689776318000,
        "value": 139.934,
        "_internal_originalTime": 1689776318000
    },
    {
        "time": 1689776320000,
        "value": 139.939,
        "_internal_originalTime": 1689776320000
    },
    {
        "time": 1689776321000,
        "value": 139.943,
        "_internal_originalTime": 1689776321000
    },
    {
        "time": 1689776322000,
        "value": 139.929,
        "_internal_originalTime": 1689776322000
    },
    {
        "time": 1689776323000,
        "value": 139.932,
        "_internal_originalTime": 1689776323000
    },
    {
        "time": 1689776325000,
        "value": 139.925,
        "_internal_originalTime": 1689776325000
    },
    {
        "time": 1689776327000,
        "value": 139.916,
        "_internal_originalTime": 1689776327000
    },
    {
        "time": 1689776329000,
        "value": 139.912,
        "_internal_originalTime": 1689776329000
    },
    {
        "time": 1689776331000,
        "value": 139.917,
        "_internal_originalTime": 1689776331000
    },
    {
        "time": 1689776332000,
        "value": 139.915,
        "_internal_originalTime": 1689776332000
    },
    {
        "time": 1689776333000,
        "value": 139.912,
        "_internal_originalTime": 1689776333000
    },
    {
        "time": 1689776335000,
        "value": 139.921,
        "_internal_originalTime": 1689776335000
    },
    {
        "time": 1689776336000,
        "value": 139.926,
        "_internal_originalTime": 1689776336000
    },
    {
        "time": 1689776337000,
        "value": 139.932,
        "_internal_originalTime": 1689776337000
    },
    {
        "time": 1689776338000,
        "value": 139.932,
        "_internal_originalTime": 1689776338000
    },
    {
        "time": 1689776339000,
        "value": 139.931,
        "_internal_originalTime": 1689776339000
    },
    {
        "time": 1689776340000,
        "value": 139.928,
        "_internal_originalTime": 1689776340000
    },
    {
        "time": 1689776341000,
        "value": 139.934,
        "_internal_originalTime": 1689776341000
    },
    {
        "time": 1689776343000,
        "value": 139.933,
        "_internal_originalTime": 1689776343000
    },
    {
        "time": 1689776345000,
        "value": 139.934,
        "_internal_originalTime": 1689776345000
    },
    {
        "time": 1689776346000,
        "value": 139.936,
        "_internal_originalTime": 1689776346000
    },
    {
        "time": 1689776347000,
        "value": 139.942,
        "_internal_originalTime": 1689776347000
    },
    {
        "time": 1689776348000,
        "value": 139.943,
        "_internal_originalTime": 1689776348000
    },
    {
        "time": 1689776349000,
        "value": 139.943,
        "_internal_originalTime": 1689776349000
    },
    {
        "time": 1689776350000,
        "value": 139.94,
        "_internal_originalTime": 1689776350000
    },
    {
        "time": 1689776351000,
        "value": 139.939,
        "_internal_originalTime": 1689776351000
    },
    {
        "time": 1689776353000,
        "value": 139.928,
        "_internal_originalTime": 1689776353000
    },
    {
        "time": 1689776354000,
        "value": 139.931,
        "_internal_originalTime": 1689776354000
    },
    {
        "time": 1689776355000,
        "value": 139.928,
        "_internal_originalTime": 1689776355000
    },
    {
        "time": 1689776356000,
        "value": 139.929,
        "_internal_originalTime": 1689776356000
    },
    {
        "time": 1689776357000,
        "value": 139.929,
        "_internal_originalTime": 1689776357000
    },
    {
        "time": 1689776358000,
        "value": 139.92,
        "_internal_originalTime": 1689776358000
    },
    {
        "time": 1689776359000,
        "value": 139.921,
        "_internal_originalTime": 1689776359000
    },
    {
        "time": 1689776360000,
        "value": 139.92,
        "_internal_originalTime": 1689776360000
    },
    {
        "time": 1689776361000,
        "value": 139.92,
        "_internal_originalTime": 1689776361000
    },
    {
        "time": 1689776362000,
        "value": 139.92,
        "_internal_originalTime": 1689776362000
    },
    {
        "time": 1689776363000,
        "value": 139.919,
        "_internal_originalTime": 1689776363000
    },
    {
        "time": 1689776365000,
        "value": 139.916,
        "_internal_originalTime": 1689776365000
    },
    {
        "time": 1689776366000,
        "value": 139.918,
        "_internal_originalTime": 1689776366000
    },
    {
        "time": 1689776367000,
        "value": 139.918,
        "_internal_originalTime": 1689776367000
    },
    {
        "time": 1689776369000,
        "value": 139.922,
        "_internal_originalTime": 1689776369000
    },
    {
        "time": 1689776370000,
        "value": 139.914,
        "_internal_originalTime": 1689776370000
    },
    {
        "time": 1689776371000,
        "value": 139.911,
        "_internal_originalTime": 1689776371000
    },
    {
        "time": 1689776373000,
        "value": 139.908,
        "_internal_originalTime": 1689776373000
    },
    {
        "time": 1689776374000,
        "value": 139.912,
        "_internal_originalTime": 1689776374000
    },
    {
        "time": 1689776375000,
        "value": 139.904,
        "_internal_originalTime": 1689776375000
    },
    {
        "time": 1689776376000,
        "value": 139.907,
        "_internal_originalTime": 1689776376000
    },
    {
        "time": 1689776377000,
        "value": 139.907,
        "_internal_originalTime": 1689776377000
    },
    {
        "time": 1689776379000,
        "value": 139.906,
        "_internal_originalTime": 1689776379000
    },
    {
        "time": 1689776380000,
        "value": 139.898,
        "_internal_originalTime": 1689776380000
    },
    {
        "time": 1689776381000,
        "value": 139.901,
        "_internal_originalTime": 1689776381000
    },
    {
        "time": 1689776382000,
        "value": 139.901,
        "_internal_originalTime": 1689776382000
    },
    {
        "time": 1689776383000,
        "value": 139.896,
        "_internal_originalTime": 1689776383000
    },
    {
        "time": 1689776385000,
        "value": 139.899,
        "_internal_originalTime": 1689776385000
    },
    {
        "time": 1689776387000,
        "value": 139.889,
        "_internal_originalTime": 1689776387000
    },
    {
        "time": 1689776388000,
        "value": 139.891,
        "_internal_originalTime": 1689776388000
    },
    {
        "time": 1689776389000,
        "value": 139.885,
        "_internal_originalTime": 1689776389000
    },
    {
        "time": 1689776390000,
        "value": 139.89,
        "_internal_originalTime": 1689776390000
    },
    {
        "time": 1689776391000,
        "value": 139.888,
        "_internal_originalTime": 1689776391000
    },
    {
        "time": 1689776392000,
        "value": 139.884,
        "_internal_originalTime": 1689776392000
    },
    {
        "time": 1689776393000,
        "value": 139.89,
        "_internal_originalTime": 1689776393000
    },
    {
        "time": 1689776394000,
        "value": 139.882,
        "_internal_originalTime": 1689776394000
    },
    {
        "time": 1689776395000,
        "value": 139.888,
        "_internal_originalTime": 1689776395000
    },
    {
        "time": 1689776396000,
        "value": 139.89,
        "_internal_originalTime": 1689776396000
    },
    {
        "time": 1689776397000,
        "value": 139.889,
        "_internal_originalTime": 1689776397000
    },
    {
        "time": 1689776398000,
        "value": 139.884,
        "_internal_originalTime": 1689776398000
    },
    {
        "time": 1689776399000,
        "value": 139.883,
        "_internal_originalTime": 1689776399000
    },
    {
        "time": 1689776400000,
        "value": 139.887,
        "_internal_originalTime": 1689776400000
    },
    {
        "time": 1689776402000,
        "value": 139.88,
        "_internal_originalTime": 1689776402000
    },
    {
        "time": 1689776403000,
        "value": 139.884,
        "_internal_originalTime": 1689776403000
    },
    {
        "time": 1689776404000,
        "value": 139.885,
        "_internal_originalTime": 1689776404000
    },
    {
        "time": 1689776405000,
        "value": 139.882,
        "_internal_originalTime": 1689776405000
    },
    {
        "time": 1689776406000,
        "value": 139.879,
        "_internal_originalTime": 1689776406000
    },
    {
        "time": 1689776407000,
        "value": 139.883,
        "_internal_originalTime": 1689776407000
    },
    {
        "time": 1689776409000,
        "value": 139.876,
        "_internal_originalTime": 1689776409000
    },
    {
        "time": 1689776410000,
        "value": 139.884,
        "_internal_originalTime": 1689776410000
    },
    {
        "time": 1689776411000,
        "value": 139.888,
        "_internal_originalTime": 1689776411000
    },
    {
        "time": 1689776412000,
        "value": 139.885,
        "_internal_originalTime": 1689776412000
    },
    {
        "time": 1689776413000,
        "value": 139.876,
        "_internal_originalTime": 1689776413000
    },
    {
        "time": 1689776414000,
        "value": 139.88,
        "_internal_originalTime": 1689776414000
    },
    {
        "time": 1689776415000,
        "value": 139.872,
        "_internal_originalTime": 1689776415000
    },
    {
        "time": 1689776416000,
        "value": 139.882,
        "_internal_originalTime": 1689776416000
    },
    {
        "time": 1689776417000,
        "value": 139.88,
        "_internal_originalTime": 1689776417000
    },
    {
        "time": 1689776418000,
        "value": 139.873,
        "_internal_originalTime": 1689776418000
    },
    {
        "time": 1689776419000,
        "value": 139.873,
        "_internal_originalTime": 1689776419000
    },
    {
        "time": 1689776421000,
        "value": 139.871,
        "_internal_originalTime": 1689776421000
    },
    {
        "time": 1689776423000,
        "value": 139.876,
        "_internal_originalTime": 1689776423000
    },
    {
        "time": 1689776425000,
        "value": 139.88,
        "_internal_originalTime": 1689776425000
    },
    {
        "time": 1689776426000,
        "value": 139.877,
        "_internal_originalTime": 1689776426000
    },
    {
        "time": 1689776428000,
        "value": 139.875,
        "_internal_originalTime": 1689776428000
    },
    {
        "time": 1689776429000,
        "value": 139.876,
        "_internal_originalTime": 1689776429000
    },
    {
        "time": 1689776430000,
        "value": 139.877,
        "_internal_originalTime": 1689776430000
    },
    {
        "time": 1689776431000,
        "value": 139.873,
        "_internal_originalTime": 1689776431000
    },
    {
        "time": 1689776432000,
        "value": 139.877,
        "_internal_originalTime": 1689776432000
    },
    {
        "time": 1689776433000,
        "value": 139.884,
        "_internal_originalTime": 1689776433000
    },
    {
        "time": 1689776435000,
        "value": 139.88,
        "_internal_originalTime": 1689776435000
    },
    {
        "time": 1689776436000,
        "value": 139.886,
        "_internal_originalTime": 1689776436000
    },
    {
        "time": 1689776437000,
        "value": 139.883,
        "_internal_originalTime": 1689776437000
    },
    {
        "time": 1689776438000,
        "value": 139.886,
        "_internal_originalTime": 1689776438000
    },
    {
        "time": 1689776439000,
        "value": 139.886,
        "_internal_originalTime": 1689776439000
    },
    {
        "time": 1689776441000,
        "value": 139.884,
        "_internal_originalTime": 1689776441000
    },
    {
        "time": 1689776442000,
        "value": 139.882,
        "_internal_originalTime": 1689776442000
    },
    {
        "time": 1689776443000,
        "value": 139.874,
        "_internal_originalTime": 1689776443000
    },
    {
        "time": 1689776444000,
        "value": 139.877,
        "_internal_originalTime": 1689776444000
    },
    {
        "time": 1689776445000,
        "value": 139.873,
        "_internal_originalTime": 1689776445000
    },
    {
        "time": 1689776447000,
        "value": 139.876,
        "_internal_originalTime": 1689776447000
    },
    {
        "time": 1689776448000,
        "value": 139.875,
        "_internal_originalTime": 1689776448000
    },
    {
        "time": 1689776449000,
        "value": 139.876,
        "_internal_originalTime": 1689776449000
    },
    {
        "time": 1689776450000,
        "value": 139.876,
        "_internal_originalTime": 1689776450000
    },
    {
        "time": 1689776452000,
        "value": 139.875,
        "_internal_originalTime": 1689776452000
    },
    {
        "time": 1689776453000,
        "value": 139.879,
        "_internal_originalTime": 1689776453000
    },
    {
        "time": 1689776454000,
        "value": 139.88,
        "_internal_originalTime": 1689776454000
    },
    {
        "time": 1689776455000,
        "value": 139.874,
        "_internal_originalTime": 1689776455000
    },
    {
        "time": 1689776457000,
        "value": 139.873,
        "_internal_originalTime": 1689776457000
    },
    {
        "time": 1689776458000,
        "value": 139.878,
        "_internal_originalTime": 1689776458000
    },
    {
        "time": 1689776459000,
        "value": 139.878,
        "_internal_originalTime": 1689776459000
    },
    {
        "time": 1689776460000,
        "value": 139.873,
        "_internal_originalTime": 1689776460000
    },
    {
        "time": 1689776461000,
        "value": 139.887,
        "_internal_originalTime": 1689776461000
    },
    {
        "time": 1689776463000,
        "value": 139.889,
        "_internal_originalTime": 1689776463000
    },
    {
        "time": 1689776465000,
        "value": 139.909,
        "_internal_originalTime": 1689776465000
    },
    {
        "time": 1689776466000,
        "value": 139.901,
        "_internal_originalTime": 1689776466000
    },
    {
        "time": 1689776467000,
        "value": 139.895,
        "_internal_originalTime": 1689776467000
    },
    {
        "time": 1689776469000,
        "value": 139.9,
        "_internal_originalTime": 1689776469000
    },
    {
        "time": 1689776470000,
        "value": 139.916,
        "_internal_originalTime": 1689776470000
    },
    {
        "time": 1689776471000,
        "value": 139.914,
        "_internal_originalTime": 1689776471000
    },
    {
        "time": 1689776472000,
        "value": 139.915,
        "_internal_originalTime": 1689776472000
    },
    {
        "time": 1689776473000,
        "value": 139.921,
        "_internal_originalTime": 1689776473000
    },
    {
        "time": 1689776475000,
        "value": 139.92,
        "_internal_originalTime": 1689776475000
    },
    {
        "time": 1689776477000,
        "value": 139.92,
        "_internal_originalTime": 1689776477000
    },
    {
        "time": 1689776478000,
        "value": 139.921,
        "_internal_originalTime": 1689776478000
    },
    {
        "time": 1689776479000,
        "value": 139.921,
        "_internal_originalTime": 1689776479000
    },
    {
        "time": 1689776480000,
        "value": 139.911,
        "_internal_originalTime": 1689776480000
    },
    {
        "time": 1689776482000,
        "value": 139.913,
        "_internal_originalTime": 1689776482000
    },
    {
        "time": 1689776483000,
        "value": 139.912,
        "_internal_originalTime": 1689776483000
    },
    {
        "time": 1689776484000,
        "value": 139.91,
        "_internal_originalTime": 1689776484000
    },
    {
        "time": 1689776485000,
        "value": 139.904,
        "_internal_originalTime": 1689776485000
    },
    {
        "time": 1689776486000,
        "value": 139.904,
        "_internal_originalTime": 1689776486000
    },
    {
        "time": 1689776487000,
        "value": 139.909,
        "_internal_originalTime": 1689776487000
    },
    {
        "time": 1689776488000,
        "value": 139.909,
        "_internal_originalTime": 1689776488000
    },
    {
        "time": 1689776490000,
        "value": 139.911,
        "_internal_originalTime": 1689776490000
    },
    {
        "time": 1689776491000,
        "value": 139.913,
        "_internal_originalTime": 1689776491000
    },
    {
        "time": 1689776492000,
        "value": 139.908,
        "_internal_originalTime": 1689776492000
    },
    {
        "time": 1689776493000,
        "value": 139.907,
        "_internal_originalTime": 1689776493000
    },
    {
        "time": 1689776495000,
        "value": 139.908,
        "_internal_originalTime": 1689776495000
    },
    {
        "time": 1689776496000,
        "value": 139.909,
        "_internal_originalTime": 1689776496000
    },
    {
        "time": 1689776497000,
        "value": 139.912,
        "_internal_originalTime": 1689776497000
    },
    {
        "time": 1689776498000,
        "value": 139.91,
        "_internal_originalTime": 1689776498000
    },
    {
        "time": 1689776499000,
        "value": 139.914,
        "_internal_originalTime": 1689776499000
    },
    {
        "time": 1689776500000,
        "value": 139.915,
        "_internal_originalTime": 1689776500000
    },
    {
        "time": 1689776502000,
        "value": 139.925,
        "_internal_originalTime": 1689776502000
    },
    {
        "time": 1689776503000,
        "value": 139.943,
        "_internal_originalTime": 1689776503000
    },
    {
        "time": 1689776505000,
        "value": 139.946,
        "_internal_originalTime": 1689776505000
    },
    {
        "time": 1689776507000,
        "value": 139.94,
        "_internal_originalTime": 1689776507000
    },
    {
        "time": 1689776508000,
        "value": 139.945,
        "_internal_originalTime": 1689776508000
    },
    {
        "time": 1689776509000,
        "value": 139.946,
        "_internal_originalTime": 1689776509000
    },
    {
        "time": 1689776510000,
        "value": 139.945,
        "_internal_originalTime": 1689776510000
    },
    {
        "time": 1689776512000,
        "value": 139.948,
        "_internal_originalTime": 1689776512000
    },
    {
        "time": 1689776514000,
        "value": 139.947,
        "_internal_originalTime": 1689776514000
    },
    {
        "time": 1689776516000,
        "value": 139.947,
        "_internal_originalTime": 1689776516000
    },
    {
        "time": 1689776517000,
        "value": 139.943,
        "_internal_originalTime": 1689776517000
    },
    {
        "time": 1689776518000,
        "value": 139.946,
        "_internal_originalTime": 1689776518000
    },
    {
        "time": 1689776520000,
        "value": 139.945,
        "_internal_originalTime": 1689776520000
    },
    {
        "time": 1689776521000,
        "value": 139.947,
        "_internal_originalTime": 1689776521000
    },
    {
        "time": 1689776522000,
        "value": 139.94,
        "_internal_originalTime": 1689776522000
    },
    {
        "time": 1689776523000,
        "value": 139.946,
        "_internal_originalTime": 1689776523000
    },
    {
        "time": 1689776525000,
        "value": 139.94,
        "_internal_originalTime": 1689776525000
    },
    {
        "time": 1689776526000,
        "value": 139.955,
        "_internal_originalTime": 1689776526000
    },
    {
        "time": 1689776528000,
        "value": 139.955,
        "_internal_originalTime": 1689776528000
    },
    {
        "time": 1689776529000,
        "value": 139.953,
        "_internal_originalTime": 1689776529000
    },
    {
        "time": 1689776531000,
        "value": 139.945,
        "_internal_originalTime": 1689776531000
    },
    {
        "time": 1689776532000,
        "value": 139.944,
        "_internal_originalTime": 1689776532000
    },
    {
        "time": 1689776534000,
        "value": 139.944,
        "_internal_originalTime": 1689776534000
    },
    {
        "time": 1689776535000,
        "value": 139.936,
        "_internal_originalTime": 1689776535000
    },
    {
        "time": 1689776536000,
        "value": 139.942,
        "_internal_originalTime": 1689776536000
    },
    {
        "time": 1689776537000,
        "value": 139.939,
        "_internal_originalTime": 1689776537000
    },
    {
        "time": 1689776538000,
        "value": 139.944,
        "_internal_originalTime": 1689776538000
    },
    {
        "time": 1689776540000,
        "value": 139.94,
        "_internal_originalTime": 1689776540000
    },
    {
        "time": 1689776541000,
        "value": 139.938,
        "_internal_originalTime": 1689776541000
    },
    {
        "time": 1689776542000,
        "value": 139.944,
        "_internal_originalTime": 1689776542000
    },
    {
        "time": 1689776544000,
        "value": 139.936,
        "_internal_originalTime": 1689776544000
    },
    {
        "time": 1689776546000,
        "value": 139.923,
        "_internal_originalTime": 1689776546000
    },
    {
        "time": 1689776547000,
        "value": 139.926,
        "_internal_originalTime": 1689776547000
    },
    {
        "time": 1689776548000,
        "value": 139.924,
        "_internal_originalTime": 1689776548000
    },
    {
        "time": 1689776550000,
        "value": 139.933,
        "_internal_originalTime": 1689776550000
    },
    {
        "time": 1689776551000,
        "value": 139.939,
        "_internal_originalTime": 1689776551000
    },
    {
        "time": 1689776552000,
        "value": 139.944,
        "_internal_originalTime": 1689776552000
    },
    {
        "time": 1689776554000,
        "value": 139.946,
        "_internal_originalTime": 1689776554000
    },
    {
        "time": 1689776555000,
        "value": 139.94,
        "_internal_originalTime": 1689776555000
    },
    {
        "time": 1689776556000,
        "value": 139.946,
        "_internal_originalTime": 1689776556000
    },
    {
        "time": 1689776557000,
        "value": 139.94,
        "_internal_originalTime": 1689776557000
    },
    {
        "time": 1689776558000,
        "value": 139.945,
        "_internal_originalTime": 1689776558000
    },
    {
        "time": 1689776560000,
        "value": 139.947,
        "_internal_originalTime": 1689776560000
    },
    {
        "time": 1689776561000,
        "value": 139.948,
        "_internal_originalTime": 1689776561000
    },
    {
        "time": 1689776562000,
        "value": 139.95,
        "_internal_originalTime": 1689776562000
    },
    {
        "time": 1689776564000,
        "value": 139.948,
        "_internal_originalTime": 1689776564000
    },
    {
        "time": 1689776565000,
        "value": 139.953,
        "_internal_originalTime": 1689776565000
    },
    {
        "time": 1689776566000,
        "value": 139.955,
        "_internal_originalTime": 1689776566000
    },
    {
        "time": 1689776568000,
        "value": 139.962,
        "_internal_originalTime": 1689776568000
    },
    {
        "time": 1689776570000,
        "value": 139.962,
        "_internal_originalTime": 1689776570000
    },
    {
        "time": 1689776572000,
        "value": 139.955,
        "_internal_originalTime": 1689776572000
    },
    {
        "time": 1689776574000,
        "value": 139.955,
        "_internal_originalTime": 1689776574000
    },
    {
        "time": 1689776576000,
        "value": 139.952,
        "_internal_originalTime": 1689776576000
    },
    {
        "time": 1689776577000,
        "value": 139.958,
        "_internal_originalTime": 1689776577000
    },
    {
        "time": 1689776579000,
        "value": 139.959,
        "_internal_originalTime": 1689776579000
    },
    {
        "time": 1689776580000,
        "value": 139.962,
        "_internal_originalTime": 1689776580000
    },
    {
        "time": 1689776582000,
        "value": 139.967,
        "_internal_originalTime": 1689776582000
    },
    {
        "time": 1689776584000,
        "value": 139.966,
        "_internal_originalTime": 1689776584000
    },
    {
        "time": 1689776585000,
        "value": 139.967,
        "_internal_originalTime": 1689776585000
    },
    {
        "time": 1689776587000,
        "value": 139.965,
        "_internal_originalTime": 1689776587000
    },
    {
        "time": 1689776588000,
        "value": 139.964,
        "_internal_originalTime": 1689776588000
    },
    {
        "time": 1689776590000,
        "value": 139.971,
        "_internal_originalTime": 1689776590000
    },
    {
        "time": 1689776591000,
        "value": 139.97,
        "_internal_originalTime": 1689776591000
    },
    {
        "time": 1689776592000,
        "value": 139.969,
        "_internal_originalTime": 1689776592000
    },
    {
        "time": 1689776593000,
        "value": 139.97,
        "_internal_originalTime": 1689776593000
    },
    {
        "time": 1689776594000,
        "value": 139.975,
        "_internal_originalTime": 1689776594000
    },
    {
        "time": 1689776595000,
        "value": 139.972,
        "_internal_originalTime": 1689776595000
    },
    {
        "time": 1689776596000,
        "value": 139.98,
        "_internal_originalTime": 1689776596000
    },
    {
        "time": 1689776597000,
        "value": 139.977,
        "_internal_originalTime": 1689776597000
    },
    {
        "time": 1689776598000,
        "value": 139.977,
        "_internal_originalTime": 1689776598000
    },
    {
        "time": 1689776600000,
        "value": 139.98,
        "_internal_originalTime": 1689776600000
    },
    {
        "time": 1689776601000,
        "value": 139.974,
        "_internal_originalTime": 1689776601000
    },
    {
        "time": 1689776602000,
        "value": 139.973,
        "_internal_originalTime": 1689776602000
    },
    {
        "time": 1689776604000,
        "value": 139.97,
        "_internal_originalTime": 1689776604000
    },
    {
        "time": 1689776605000,
        "value": 139.97,
        "_internal_originalTime": 1689776605000
    },
    {
        "time": 1689776606000,
        "value": 139.975,
        "_internal_originalTime": 1689776606000
    },
    {
        "time": 1689776607000,
        "value": 139.973,
        "_internal_originalTime": 1689776607000
    },
    {
        "time": 1689776608000,
        "value": 139.974,
        "_internal_originalTime": 1689776608000
    },
    {
        "time": 1689776610000,
        "value": 139.975,
        "_internal_originalTime": 1689776610000
    },
    {
        "time": 1689776611000,
        "value": 139.975,
        "_internal_originalTime": 1689776611000
    },
    {
        "time": 1689776612000,
        "value": 139.981,
        "_internal_originalTime": 1689776612000
    },
    {
        "time": 1689776614000,
        "value": 139.981,
        "_internal_originalTime": 1689776614000
    },
    {
        "time": 1689776615000,
        "value": 139.981,
        "_internal_originalTime": 1689776615000
    },
    {
        "time": 1689776616000,
        "value": 139.982,
        "_internal_originalTime": 1689776616000
    },
    {
        "time": 1689776618000,
        "value": 139.983,
        "_internal_originalTime": 1689776618000
    },
    {
        "time": 1689776619000,
        "value": 139.981,
        "_internal_originalTime": 1689776619000
    },
    {
        "time": 1689776621000,
        "value": 139.978,
        "_internal_originalTime": 1689776621000
    },
    {
        "time": 1689776622000,
        "value": 139.981,
        "_internal_originalTime": 1689776622000
    },
    {
        "time": 1689776624000,
        "value": 139.979,
        "_internal_originalTime": 1689776624000
    },
    {
        "time": 1689776625000,
        "value": 139.982,
        "_internal_originalTime": 1689776625000
    },
    {
        "time": 1689776626000,
        "value": 139.972,
        "_internal_originalTime": 1689776626000
    },
    {
        "time": 1689776627000,
        "value": 139.974,
        "_internal_originalTime": 1689776627000
    },
    {
        "time": 1689776628000,
        "value": 139.974,
        "_internal_originalTime": 1689776628000
    },
    {
        "time": 1689776629000,
        "value": 139.973,
        "_internal_originalTime": 1689776629000
    },
    {
        "time": 1689776630000,
        "value": 139.977,
        "_internal_originalTime": 1689776630000
    },
    {
        "time": 1689776631000,
        "value": 139.972,
        "_internal_originalTime": 1689776631000
    },
    {
        "time": 1689776632000,
        "value": 139.976,
        "_internal_originalTime": 1689776632000
    },
    {
        "time": 1689776633000,
        "value": 139.972,
        "_internal_originalTime": 1689776633000
    },
    {
        "time": 1689776635000,
        "value": 139.977,
        "_internal_originalTime": 1689776635000
    },
    {
        "time": 1689776636000,
        "value": 139.976,
        "_internal_originalTime": 1689776636000
    },
    {
        "time": 1689776637000,
        "value": 139.976,
        "_internal_originalTime": 1689776637000
    },
    {
        "time": 1689776640000,
        "value": 139.982,
        "_internal_originalTime": 1689776640000
    },
    {
        "time": 1689776641000,
        "value": 139.976,
        "_internal_originalTime": 1689776641000
    },
    {
        "time": 1689776642000,
        "value": 139.975,
        "_internal_originalTime": 1689776642000
    },
    {
        "time": 1689776643000,
        "value": 139.977,
        "_internal_originalTime": 1689776643000
    },
    {
        "time": 1689776644000,
        "value": 139.98,
        "_internal_originalTime": 1689776644000
    },
    {
        "time": 1689776646000,
        "value": 139.976,
        "_internal_originalTime": 1689776646000
    },
    {
        "time": 1689776647000,
        "value": 139.977,
        "_internal_originalTime": 1689776647000
    },
    {
        "time": 1689776648000,
        "value": 139.976,
        "_internal_originalTime": 1689776648000
    },
    {
        "time": 1689776649000,
        "value": 139.972,
        "_internal_originalTime": 1689776649000
    },
    {
        "time": 1689776650000,
        "value": 139.977,
        "_internal_originalTime": 1689776650000
    },
    {
        "time": 1689776651000,
        "value": 139.979,
        "_internal_originalTime": 1689776651000
    },
    {
        "time": 1689776652000,
        "value": 139.981,
        "_internal_originalTime": 1689776652000
    },
    {
        "time": 1689776653000,
        "value": 139.984,
        "_internal_originalTime": 1689776653000
    },
    {
        "time": 1689776655000,
        "value": 139.984,
        "_internal_originalTime": 1689776655000
    },
    {
        "time": 1689776657000,
        "value": 139.983,
        "_internal_originalTime": 1689776657000
    },
    {
        "time": 1689776659000,
        "value": 139.975,
        "_internal_originalTime": 1689776659000
    },
    {
        "time": 1689776660000,
        "value": 139.97,
        "_internal_originalTime": 1689776660000
    },
    {
        "time": 1689776661000,
        "value": 139.976,
        "_internal_originalTime": 1689776661000
    },
    {
        "time": 1689776662000,
        "value": 139.976,
        "_internal_originalTime": 1689776662000
    },
    {
        "time": 1689776664000,
        "value": 139.976,
        "_internal_originalTime": 1689776664000
    },
    {
        "time": 1689776666000,
        "value": 139.976,
        "_internal_originalTime": 1689776666000
    },
    {
        "time": 1689776667000,
        "value": 139.98,
        "_internal_originalTime": 1689776667000
    },
    {
        "time": 1689776668000,
        "value": 139.976,
        "_internal_originalTime": 1689776668000
    },
    {
        "time": 1689776669000,
        "value": 139.977,
        "_internal_originalTime": 1689776669000
    },
    {
        "time": 1689776671000,
        "value": 139.98,
        "_internal_originalTime": 1689776671000
    },
    {
        "time": 1689776672000,
        "value": 139.98,
        "_internal_originalTime": 1689776672000
    },
    {
        "time": 1689776674000,
        "value": 139.979,
        "_internal_originalTime": 1689776674000
    },
    {
        "time": 1689776675000,
        "value": 139.98,
        "_internal_originalTime": 1689776675000
    },
    {
        "time": 1689776676000,
        "value": 139.979,
        "_internal_originalTime": 1689776676000
    },
    {
        "time": 1689776678000,
        "value": 139.976,
        "_internal_originalTime": 1689776678000
    },
    {
        "time": 1689776679000,
        "value": 139.975,
        "_internal_originalTime": 1689776679000
    },
    {
        "time": 1689776680000,
        "value": 139.97,
        "_internal_originalTime": 1689776680000
    },
    {
        "time": 1689776681000,
        "value": 139.975,
        "_internal_originalTime": 1689776681000
    },
    {
        "time": 1689776682000,
        "value": 139.97,
        "_internal_originalTime": 1689776682000
    },
    {
        "time": 1689776683000,
        "value": 139.976,
        "_internal_originalTime": 1689776683000
    },
    {
        "time": 1689776684000,
        "value": 139.972,
        "_internal_originalTime": 1689776684000
    },
    {
        "time": 1689776685000,
        "value": 139.972,
        "_internal_originalTime": 1689776685000
    },
    {
        "time": 1689776686000,
        "value": 139.976,
        "_internal_originalTime": 1689776686000
    },
    {
        "time": 1689776687000,
        "value": 139.974,
        "_internal_originalTime": 1689776687000
    },
    {
        "time": 1689776688000,
        "value": 139.975,
        "_internal_originalTime": 1689776688000
    },
    {
        "time": 1689776689000,
        "value": 139.968,
        "_internal_originalTime": 1689776689000
    },
    {
        "time": 1689776691000,
        "value": 139.969,
        "_internal_originalTime": 1689776691000
    },
    {
        "time": 1689776692000,
        "value": 139.968,
        "_internal_originalTime": 1689776692000
    },
    {
        "time": 1689776693000,
        "value": 139.966,
        "_internal_originalTime": 1689776693000
    },
    {
        "time": 1689776694000,
        "value": 139.967,
        "_internal_originalTime": 1689776694000
    },
    {
        "time": 1689776695000,
        "value": 139.968,
        "_internal_originalTime": 1689776695000
    },
    {
        "time": 1689776696000,
        "value": 139.97,
        "_internal_originalTime": 1689776696000
    },
    {
        "time": 1689776698000,
        "value": 139.972,
        "_internal_originalTime": 1689776698000
    },
    {
        "time": 1689776699000,
        "value": 139.971,
        "_internal_originalTime": 1689776699000
    },
    {
        "time": 1689776700000,
        "value": 139.973,
        "_internal_originalTime": 1689776700000
    },
    {
        "time": 1689776702000,
        "value": 139.974,
        "_internal_originalTime": 1689776702000
    },
    {
        "time": 1689776703000,
        "value": 139.975,
        "_internal_originalTime": 1689776703000
    },
    {
        "time": 1689776704000,
        "value": 139.976,
        "_internal_originalTime": 1689776704000
    },
    {
        "time": 1689776705000,
        "value": 139.977,
        "_internal_originalTime": 1689776705000
    },
    {
        "time": 1689776706000,
        "value": 139.978,
        "_internal_originalTime": 1689776706000
    },
    {
        "time": 1689776708000,
        "value": 139.975,
        "_internal_originalTime": 1689776708000
    },
    {
        "time": 1689776709000,
        "value": 139.973,
        "_internal_originalTime": 1689776709000
    },
    {
        "time": 1689776710000,
        "value": 139.976,
        "_internal_originalTime": 1689776710000
    },
    {
        "time": 1689776711000,
        "value": 139.971,
        "_internal_originalTime": 1689776711000
    },
    {
        "time": 1689776716000,
        "value": 139.981,
        "_internal_originalTime": 1689776716000
    },
    {
        "time": 1689776718000,
        "value": 139.98,
        "_internal_originalTime": 1689776718000
    },
    {
        "time": 1689776719000,
        "value": 139.98,
        "_internal_originalTime": 1689776719000
    },
    {
        "time": 1689776721000,
        "value": 139.984,
        "_internal_originalTime": 1689776721000
    },
    {
        "time": 1689776722000,
        "value": 139.981,
        "_internal_originalTime": 1689776722000
    },
    {
        "time": 1689776724000,
        "value": 139.977,
        "_internal_originalTime": 1689776724000
    },
    {
        "time": 1689776725000,
        "value": 139.983,
        "_internal_originalTime": 1689776725000
    },
    {
        "time": 1689776726000,
        "value": 139.978,
        "_internal_originalTime": 1689776726000
    },
    {
        "time": 1689776727000,
        "value": 139.976,
        "_internal_originalTime": 1689776727000
    },
    {
        "time": 1689776728000,
        "value": 139.982,
        "_internal_originalTime": 1689776728000
    },
    {
        "time": 1689776729000,
        "value": 139.983,
        "_internal_originalTime": 1689776729000
    },
    {
        "time": 1689776730000,
        "value": 139.98,
        "_internal_originalTime": 1689776730000
    },
    {
        "time": 1689776731000,
        "value": 139.985,
        "_internal_originalTime": 1689776731000
    },
    {
        "time": 1689776733000,
        "value": 139.985,
        "_internal_originalTime": 1689776733000
    },
    {
        "time": 1689776734000,
        "value": 139.972,
        "_internal_originalTime": 1689776734000
    },
    {
        "time": 1689776736000,
        "value": 139.976,
        "_internal_originalTime": 1689776736000
    },
    {
        "time": 1689776737000,
        "value": 139.978,
        "_internal_originalTime": 1689776737000
    },
    {
        "time": 1689776738000,
        "value": 139.985,
        "_internal_originalTime": 1689776738000
    },
    {
        "time": 1689776740000,
        "value": 139.98,
        "_internal_originalTime": 1689776740000
    },
    {
        "time": 1689776742000,
        "value": 139.977,
        "_internal_originalTime": 1689776742000
    },
    {
        "time": 1689776743000,
        "value": 139.984,
        "_internal_originalTime": 1689776743000
    },
    {
        "time": 1689776744000,
        "value": 139.983,
        "_internal_originalTime": 1689776744000
    },
    {
        "time": 1689776745000,
        "value": 139.984,
        "_internal_originalTime": 1689776745000
    },
    {
        "time": 1689776746000,
        "value": 139.979,
        "_internal_originalTime": 1689776746000
    },
    {
        "time": 1689776747000,
        "value": 139.98,
        "_internal_originalTime": 1689776747000
    },
    {
        "time": 1689776748000,
        "value": 139.984,
        "_internal_originalTime": 1689776748000
    },
    {
        "time": 1689776749000,
        "value": 139.979,
        "_internal_originalTime": 1689776749000
    },
    {
        "time": 1689776750000,
        "value": 139.987,
        "_internal_originalTime": 1689776750000
    },
    {
        "time": 1689776751000,
        "value": 139.983,
        "_internal_originalTime": 1689776751000
    },
    {
        "time": 1689776753000,
        "value": 139.977,
        "_internal_originalTime": 1689776753000
    },
    {
        "time": 1689776754000,
        "value": 139.982,
        "_internal_originalTime": 1689776754000
    },
    {
        "time": 1689776756000,
        "value": 139.979,
        "_internal_originalTime": 1689776756000
    },
    {
        "time": 1689776757000,
        "value": 139.983,
        "_internal_originalTime": 1689776757000
    },
    {
        "time": 1689776758000,
        "value": 139.983,
        "_internal_originalTime": 1689776758000
    },
    {
        "time": 1689776759000,
        "value": 139.978,
        "_internal_originalTime": 1689776759000
    },
    {
        "time": 1689776760000,
        "value": 139.983,
        "_internal_originalTime": 1689776760000
    },
    {
        "time": 1689776762000,
        "value": 139.978,
        "_internal_originalTime": 1689776762000
    },
    {
        "time": 1689776763000,
        "value": 139.977,
        "_internal_originalTime": 1689776763000
    },
    {
        "time": 1689776764000,
        "value": 139.978,
        "_internal_originalTime": 1689776764000
    },
    {
        "time": 1689776766000,
        "value": 139.977,
        "_internal_originalTime": 1689776766000
    },
    {
        "time": 1689776767000,
        "value": 139.978,
        "_internal_originalTime": 1689776767000
    },
    {
        "time": 1689776769000,
        "value": 139.97,
        "_internal_originalTime": 1689776769000
    },
    {
        "time": 1689776770000,
        "value": 139.977,
        "_internal_originalTime": 1689776770000
    },
    {
        "time": 1689776771000,
        "value": 139.972,
        "_internal_originalTime": 1689776771000
    },
    {
        "time": 1689776772000,
        "value": 139.978,
        "_internal_originalTime": 1689776772000
    },
    {
        "time": 1689776773000,
        "value": 139.976,
        "_internal_originalTime": 1689776773000
    },
    {
        "time": 1689776774000,
        "value": 139.983,
        "_internal_originalTime": 1689776774000
    },
    {
        "time": 1689776776000,
        "value": 139.985,
        "_internal_originalTime": 1689776776000
    },
    {
        "time": 1689776777000,
        "value": 139.986,
        "_internal_originalTime": 1689776777000
    },
    {
        "time": 1689776778000,
        "value": 139.986,
        "_internal_originalTime": 1689776778000
    },
    {
        "time": 1689776779000,
        "value": 139.986,
        "_internal_originalTime": 1689776779000
    },
    {
        "time": 1689776780000,
        "value": 139.98,
        "_internal_originalTime": 1689776780000
    },
    {
        "time": 1689776781000,
        "value": 139.985,
        "_internal_originalTime": 1689776781000
    },
    {
        "time": 1689776782000,
        "value": 139.98,
        "_internal_originalTime": 1689776782000
    },
    {
        "time": 1689776783000,
        "value": 139.984,
        "_internal_originalTime": 1689776783000
    },
    {
        "time": 1689776784000,
        "value": 139.982,
        "_internal_originalTime": 1689776784000
    },
    {
        "time": 1689776785000,
        "value": 139.984,
        "_internal_originalTime": 1689776785000
    },
    {
        "time": 1689776786000,
        "value": 139.979,
        "_internal_originalTime": 1689776786000
    },
    {
        "time": 1689776787000,
        "value": 139.979,
        "_internal_originalTime": 1689776787000
    },
    {
        "time": 1689776788000,
        "value": 139.983,
        "_internal_originalTime": 1689776788000
    },
    {
        "time": 1689776790000,
        "value": 139.978,
        "_internal_originalTime": 1689776790000
    },
    {
        "time": 1689776791000,
        "value": 139.97,
        "_internal_originalTime": 1689776791000
    },
    {
        "time": 1689776792000,
        "value": 139.975,
        "_internal_originalTime": 1689776792000
    },
    {
        "time": 1689776795000,
        "value": 139.97,
        "_internal_originalTime": 1689776795000
    },
    {
        "time": 1689776797000,
        "value": 139.97,
        "_internal_originalTime": 1689776797000
    },
    {
        "time": 1689776798000,
        "value": 139.964,
        "_internal_originalTime": 1689776798000
    },
    {
        "time": 1689776800000,
        "value": 139.962,
        "_internal_originalTime": 1689776800000
    },
    {
        "time": 1689776801000,
        "value": 139.957,
        "_internal_originalTime": 1689776801000
    },
    {
        "time": 1689776802000,
        "value": 139.955,
        "_internal_originalTime": 1689776802000
    },
    {
        "time": 1689776804000,
        "value": 139.927,
        "_internal_originalTime": 1689776804000
    },
    {
        "time": 1689776805000,
        "value": 139.945,
        "_internal_originalTime": 1689776805000
    },
    {
        "time": 1689776806000,
        "value": 139.938,
        "_internal_originalTime": 1689776806000
    },
    {
        "time": 1689776807000,
        "value": 139.94,
        "_internal_originalTime": 1689776807000
    },
    {
        "time": 1689776808000,
        "value": 139.938,
        "_internal_originalTime": 1689776808000
    },
    {
        "time": 1689776809000,
        "value": 139.938,
        "_internal_originalTime": 1689776809000
    },
    {
        "time": 1689776811000,
        "value": 139.934,
        "_internal_originalTime": 1689776811000
    },
    {
        "time": 1689776813000,
        "value": 139.925,
        "_internal_originalTime": 1689776813000
    },
    {
        "time": 1689776814000,
        "value": 139.921,
        "_internal_originalTime": 1689776814000
    },
    {
        "time": 1689776815000,
        "value": 139.925,
        "_internal_originalTime": 1689776815000
    },
    {
        "time": 1689776818000,
        "value": 139.928,
        "_internal_originalTime": 1689776818000
    },
    {
        "time": 1689776819000,
        "value": 139.924,
        "_internal_originalTime": 1689776819000
    },
    {
        "time": 1689776820000,
        "value": 139.924,
        "_internal_originalTime": 1689776820000
    },
    {
        "time": 1689776822000,
        "value": 139.932,
        "_internal_originalTime": 1689776822000
    },
    {
        "time": 1689776823000,
        "value": 139.929,
        "_internal_originalTime": 1689776823000
    },
    {
        "time": 1689776824000,
        "value": 139.937,
        "_internal_originalTime": 1689776824000
    },
    {
        "time": 1689776825000,
        "value": 139.935,
        "_internal_originalTime": 1689776825000
    },
    {
        "time": 1689776827000,
        "value": 139.94,
        "_internal_originalTime": 1689776827000
    },
    {
        "time": 1689776828000,
        "value": 139.939,
        "_internal_originalTime": 1689776828000
    },
    {
        "time": 1689776829000,
        "value": 139.936,
        "_internal_originalTime": 1689776829000
    },
    {
        "time": 1689776830000,
        "value": 139.941,
        "_internal_originalTime": 1689776830000
    },
    {
        "time": 1689776831000,
        "value": 139.938,
        "_internal_originalTime": 1689776831000
    },
    {
        "time": 1689776832000,
        "value": 139.94,
        "_internal_originalTime": 1689776832000
    },
    {
        "time": 1689776833000,
        "value": 139.944,
        "_internal_originalTime": 1689776833000
    },
    {
        "time": 1689776835000,
        "value": 139.956,
        "_internal_originalTime": 1689776835000
    },
    {
        "time": 1689776836000,
        "value": 139.954,
        "_internal_originalTime": 1689776836000
    },
    {
        "time": 1689776837000,
        "value": 139.954,
        "_internal_originalTime": 1689776837000
    },
    {
        "time": 1689776838000,
        "value": 139.954,
        "_internal_originalTime": 1689776838000
    },
    {
        "time": 1689776840000,
        "value": 139.954,
        "_internal_originalTime": 1689776840000
    },
    {
        "time": 1689776843000,
        "value": 139.955,
        "_internal_originalTime": 1689776843000
    },
    {
        "time": 1689776844000,
        "value": 139.954,
        "_internal_originalTime": 1689776844000
    },
    {
        "time": 1689776845000,
        "value": 139.949,
        "_internal_originalTime": 1689776845000
    },
    {
        "time": 1689776846000,
        "value": 139.946,
        "_internal_originalTime": 1689776846000
    },
    {
        "time": 1689776848000,
        "value": 139.954,
        "_internal_originalTime": 1689776848000
    },
    {
        "time": 1689776849000,
        "value": 139.954,
        "_internal_originalTime": 1689776849000
    },
    {
        "time": 1689776850000,
        "value": 139.952,
        "_internal_originalTime": 1689776850000
    },
    {
        "time": 1689776851000,
        "value": 139.954,
        "_internal_originalTime": 1689776851000
    },
    {
        "time": 1689776853000,
        "value": 139.953,
        "_internal_originalTime": 1689776853000
    },
    {
        "time": 1689776854000,
        "value": 139.951,
        "_internal_originalTime": 1689776854000
    },
    {
        "time": 1689776855000,
        "value": 139.955,
        "_internal_originalTime": 1689776855000
    },
    {
        "time": 1689776856000,
        "value": 139.956,
        "_internal_originalTime": 1689776856000
    },
    {
        "time": 1689776857000,
        "value": 139.95,
        "_internal_originalTime": 1689776857000
    },
    {
        "time": 1689776858000,
        "value": 139.951,
        "_internal_originalTime": 1689776858000
    },
    {
        "time": 1689776859000,
        "value": 139.954,
        "_internal_originalTime": 1689776859000
    },
    {
        "time": 1689776860000,
        "value": 139.954,
        "_internal_originalTime": 1689776860000
    },
    {
        "time": 1689776861000,
        "value": 139.954,
        "_internal_originalTime": 1689776861000
    },
    {
        "time": 1689776862000,
        "value": 139.952,
        "_internal_originalTime": 1689776862000
    },
    {
        "time": 1689776863000,
        "value": 139.942,
        "_internal_originalTime": 1689776863000
    },
    {
        "time": 1689776865000,
        "value": 139.947,
        "_internal_originalTime": 1689776865000
    },
    {
        "time": 1689776867000,
        "value": 139.954,
        "_internal_originalTime": 1689776867000
    },
    {
        "time": 1689776868000,
        "value": 139.95,
        "_internal_originalTime": 1689776868000
    },
    {
        "time": 1689776869000,
        "value": 139.95,
        "_internal_originalTime": 1689776869000
    },
    {
        "time": 1689776871000,
        "value": 139.951,
        "_internal_originalTime": 1689776871000
    },
    {
        "time": 1689776873000,
        "value": 139.954,
        "_internal_originalTime": 1689776873000
    },
    {
        "time": 1689776874000,
        "value": 139.95,
        "_internal_originalTime": 1689776874000
    },
    {
        "time": 1689776876000,
        "value": 139.954,
        "_internal_originalTime": 1689776876000
    },
    {
        "time": 1689776877000,
        "value": 139.954,
        "_internal_originalTime": 1689776877000
    },
    {
        "time": 1689776878000,
        "value": 139.954,
        "_internal_originalTime": 1689776878000
    },
    {
        "time": 1689776880000,
        "value": 139.952,
        "_internal_originalTime": 1689776880000
    },
    {
        "time": 1689776882000,
        "value": 139.954,
        "_internal_originalTime": 1689776882000
    },
    {
        "time": 1689776883000,
        "value": 139.959,
        "_internal_originalTime": 1689776883000
    },
    {
        "time": 1689776885000,
        "value": 139.955,
        "_internal_originalTime": 1689776885000
    },
    {
        "time": 1689776886000,
        "value": 139.954,
        "_internal_originalTime": 1689776886000
    },
    {
        "time": 1689776887000,
        "value": 139.955,
        "_internal_originalTime": 1689776887000
    },
    {
        "time": 1689776889000,
        "value": 139.958,
        "_internal_originalTime": 1689776889000
    },
    {
        "time": 1689776890000,
        "value": 139.955,
        "_internal_originalTime": 1689776890000
    },
    {
        "time": 1689776891000,
        "value": 139.956,
        "_internal_originalTime": 1689776891000
    },
    {
        "time": 1689776893000,
        "value": 139.964,
        "_internal_originalTime": 1689776893000
    },
    {
        "time": 1689776894000,
        "value": 139.968,
        "_internal_originalTime": 1689776894000
    },
    {
        "time": 1689776895000,
        "value": 139.968,
        "_internal_originalTime": 1689776895000
    },
    {
        "time": 1689776896000,
        "value": 139.963,
        "_internal_originalTime": 1689776896000
    },
    {
        "time": 1689776897000,
        "value": 139.958,
        "_internal_originalTime": 1689776897000
    },
    {
        "time": 1689776898000,
        "value": 139.96,
        "_internal_originalTime": 1689776898000
    },
    {
        "time": 1689776900000,
        "value": 139.964,
        "_internal_originalTime": 1689776900000
    },
    {
        "time": 1689776901000,
        "value": 139.958,
        "_internal_originalTime": 1689776901000
    },
    {
        "time": 1689776902000,
        "value": 139.969,
        "_internal_originalTime": 1689776902000
    },
    {
        "time": 1689776903000,
        "value": 139.966,
        "_internal_originalTime": 1689776903000
    },
    {
        "time": 1689776905000,
        "value": 139.97,
        "_internal_originalTime": 1689776905000
    },
    {
        "time": 1689776906000,
        "value": 139.963,
        "_internal_originalTime": 1689776906000
    },
    {
        "time": 1689776907000,
        "value": 139.959,
        "_internal_originalTime": 1689776907000
    },
    {
        "time": 1689776908000,
        "value": 139.95,
        "_internal_originalTime": 1689776908000
    },
    {
        "time": 1689776909000,
        "value": 139.951,
        "_internal_originalTime": 1689776909000
    },
    {
        "time": 1689776910000,
        "value": 139.948,
        "_internal_originalTime": 1689776910000
    },
    {
        "time": 1689776911000,
        "value": 139.954,
        "_internal_originalTime": 1689776911000
    },
    {
        "time": 1689776912000,
        "value": 139.944,
        "_internal_originalTime": 1689776912000
    },
    {
        "time": 1689776914000,
        "value": 139.942,
        "_internal_originalTime": 1689776914000
    },
    {
        "time": 1689776915000,
        "value": 139.948,
        "_internal_originalTime": 1689776915000
    },
    {
        "time": 1689776916000,
        "value": 139.945,
        "_internal_originalTime": 1689776916000
    },
    {
        "time": 1689776917000,
        "value": 139.945,
        "_internal_originalTime": 1689776917000
    },
    {
        "time": 1689776918000,
        "value": 139.944,
        "_internal_originalTime": 1689776918000
    },
    {
        "time": 1689776919000,
        "value": 139.943,
        "_internal_originalTime": 1689776919000
    },
    {
        "time": 1689776920000,
        "value": 139.945,
        "_internal_originalTime": 1689776920000
    },
    {
        "time": 1689776921000,
        "value": 139.942,
        "_internal_originalTime": 1689776921000
    },
    {
        "time": 1689776922000,
        "value": 139.945,
        "_internal_originalTime": 1689776922000
    },
    {
        "time": 1689776924000,
        "value": 139.946,
        "_internal_originalTime": 1689776924000
    },
    {
        "time": 1689776925000,
        "value": 139.955,
        "_internal_originalTime": 1689776925000
    },
    {
        "time": 1689776926000,
        "value": 139.959,
        "_internal_originalTime": 1689776926000
    },
    {
        "time": 1689776927000,
        "value": 139.955,
        "_internal_originalTime": 1689776927000
    },
    {
        "time": 1689776928000,
        "value": 139.946,
        "_internal_originalTime": 1689776928000
    },
    {
        "time": 1689776929000,
        "value": 139.938,
        "_internal_originalTime": 1689776929000
    },
    {
        "time": 1689776931000,
        "value": 139.94,
        "_internal_originalTime": 1689776931000
    },
    {
        "time": 1689776932000,
        "value": 139.943,
        "_internal_originalTime": 1689776932000
    },
    {
        "time": 1689776933000,
        "value": 139.94,
        "_internal_originalTime": 1689776933000
    },
    {
        "time": 1689776934000,
        "value": 139.946,
        "_internal_originalTime": 1689776934000
    },
    {
        "time": 1689776935000,
        "value": 139.946,
        "_internal_originalTime": 1689776935000
    },
    {
        "time": 1689776936000,
        "value": 139.945,
        "_internal_originalTime": 1689776936000
    },
    {
        "time": 1689776937000,
        "value": 139.944,
        "_internal_originalTime": 1689776937000
    },
    {
        "time": 1689776939000,
        "value": 139.948,
        "_internal_originalTime": 1689776939000
    },
    {
        "time": 1689776940000,
        "value": 139.948,
        "_internal_originalTime": 1689776940000
    },
    {
        "time": 1689776941000,
        "value": 139.92,
        "_internal_originalTime": 1689776941000
    },
    {
        "time": 1689776942000,
        "value": 139.954,
        "_internal_originalTime": 1689776942000
    },
    {
        "time": 1689776944000,
        "value": 139.948,
        "_internal_originalTime": 1689776944000
    },
    {
        "time": 1689776945000,
        "value": 139.95,
        "_internal_originalTime": 1689776945000
    },
    {
        "time": 1689776947000,
        "value": 139.948,
        "_internal_originalTime": 1689776947000
    },
    {
        "time": 1689776948000,
        "value": 139.947,
        "_internal_originalTime": 1689776948000
    },
    {
        "time": 1689776949000,
        "value": 139.947,
        "_internal_originalTime": 1689776949000
    },
    {
        "time": 1689776950000,
        "value": 139.947,
        "_internal_originalTime": 1689776950000
    },
    {
        "time": 1689776952000,
        "value": 139.943,
        "_internal_originalTime": 1689776952000
    },
    {
        "time": 1689776953000,
        "value": 139.947,
        "_internal_originalTime": 1689776953000
    },
    {
        "time": 1689776954000,
        "value": 139.95,
        "_internal_originalTime": 1689776954000
    },
    {
        "time": 1689776955000,
        "value": 139.957,
        "_internal_originalTime": 1689776955000
    },
    {
        "time": 1689776956000,
        "value": 139.957,
        "_internal_originalTime": 1689776956000
    },
    {
        "time": 1689776958000,
        "value": 139.946,
        "_internal_originalTime": 1689776958000
    },
    {
        "time": 1689776959000,
        "value": 139.948,
        "_internal_originalTime": 1689776959000
    },
    {
        "time": 1689776960000,
        "value": 139.949,
        "_internal_originalTime": 1689776960000
    },
    {
        "time": 1689776962000,
        "value": 139.948,
        "_internal_originalTime": 1689776962000
    },
    {
        "time": 1689776965000,
        "value": 139.954,
        "_internal_originalTime": 1689776965000
    },
    {
        "time": 1689776967000,
        "value": 139.954,
        "_internal_originalTime": 1689776967000
    },
    {
        "time": 1689776968000,
        "value": 139.951,
        "_internal_originalTime": 1689776968000
    },
    {
        "time": 1689776969000,
        "value": 139.954,
        "_internal_originalTime": 1689776969000
    },
    {
        "time": 1689776970000,
        "value": 139.955,
        "_internal_originalTime": 1689776970000
    },
    {
        "time": 1689776971000,
        "value": 139.953,
        "_internal_originalTime": 1689776971000
    },
    {
        "time": 1689776972000,
        "value": 139.954,
        "_internal_originalTime": 1689776972000
    },
    {
        "time": 1689776973000,
        "value": 139.949,
        "_internal_originalTime": 1689776973000
    },
    {
        "time": 1689776975000,
        "value": 139.951,
        "_internal_originalTime": 1689776975000
    },
    {
        "time": 1689776977000,
        "value": 139.954,
        "_internal_originalTime": 1689776977000
    },
    {
        "time": 1689776978000,
        "value": 139.963,
        "_internal_originalTime": 1689776978000
    },
    {
        "time": 1689776980000,
        "value": 139.958,
        "_internal_originalTime": 1689776980000
    },
    {
        "time": 1689776981000,
        "value": 139.952,
        "_internal_originalTime": 1689776981000
    },
    {
        "time": 1689776982000,
        "value": 139.953,
        "_internal_originalTime": 1689776982000
    },
    {
        "time": 1689776983000,
        "value": 139.953,
        "_internal_originalTime": 1689776983000
    },
    {
        "time": 1689776985000,
        "value": 139.957,
        "_internal_originalTime": 1689776985000
    },
    {
        "time": 1689776987000,
        "value": 139.954,
        "_internal_originalTime": 1689776987000
    },
    {
        "time": 1689776988000,
        "value": 139.949,
        "_internal_originalTime": 1689776988000
    },
    {
        "time": 1689776989000,
        "value": 139.954,
        "_internal_originalTime": 1689776989000
    },
    {
        "time": 1689776990000,
        "value": 139.952,
        "_internal_originalTime": 1689776990000
    },
    {
        "time": 1689776992000,
        "value": 139.961,
        "_internal_originalTime": 1689776992000
    },
    {
        "time": 1689776993000,
        "value": 139.958,
        "_internal_originalTime": 1689776993000
    },
    {
        "time": 1689776994000,
        "value": 139.952,
        "_internal_originalTime": 1689776994000
    },
    {
        "time": 1689776995000,
        "value": 139.957,
        "_internal_originalTime": 1689776995000
    },
    {
        "time": 1689776997000,
        "value": 139.956,
        "_internal_originalTime": 1689776997000
    },
    {
        "time": 1689776999000,
        "value": 139.955,
        "_internal_originalTime": 1689776999000
    },
    {
        "time": 1689777000000,
        "value": 139.955,
        "_internal_originalTime": 1689777000000
    },
    {
        "time": 1689777001000,
        "value": 139.95,
        "_internal_originalTime": 1689777001000
    },
    {
        "time": 1689777002000,
        "value": 139.951,
        "_internal_originalTime": 1689777002000
    },
    {
        "time": 1689777003000,
        "value": 139.95,
        "_internal_originalTime": 1689777003000
    },
    {
        "time": 1689777005000,
        "value": 139.948,
        "_internal_originalTime": 1689777005000
    },
    {
        "time": 1689777006000,
        "value": 139.946,
        "_internal_originalTime": 1689777006000
    },
    {
        "time": 1689777007000,
        "value": 139.94,
        "_internal_originalTime": 1689777007000
    },
    {
        "time": 1689777008000,
        "value": 139.947,
        "_internal_originalTime": 1689777008000
    },
    {
        "time": 1689777010000,
        "value": 139.942,
        "_internal_originalTime": 1689777010000
    },
    {
        "time": 1689777012000,
        "value": 139.94,
        "_internal_originalTime": 1689777012000
    },
    {
        "time": 1689777013000,
        "value": 139.95,
        "_internal_originalTime": 1689777013000
    },
    {
        "time": 1689777015000,
        "value": 139.955,
        "_internal_originalTime": 1689777015000
    },
    {
        "time": 1689777016000,
        "value": 139.95,
        "_internal_originalTime": 1689777016000
    },
    {
        "time": 1689777017000,
        "value": 139.954,
        "_internal_originalTime": 1689777017000
    },
    {
        "time": 1689777018000,
        "value": 139.947,
        "_internal_originalTime": 1689777018000
    },
    {
        "time": 1689777019000,
        "value": 139.95,
        "_internal_originalTime": 1689777019000
    },
    {
        "time": 1689777020000,
        "value": 139.951,
        "_internal_originalTime": 1689777020000
    },
    {
        "time": 1689777021000,
        "value": 139.954,
        "_internal_originalTime": 1689777021000
    },
    {
        "time": 1689777022000,
        "value": 139.951,
        "_internal_originalTime": 1689777022000
    },
    {
        "time": 1689777023000,
        "value": 139.949,
        "_internal_originalTime": 1689777023000
    },
    {
        "time": 1689777024000,
        "value": 139.95,
        "_internal_originalTime": 1689777024000
    },
    {
        "time": 1689777025000,
        "value": 139.95,
        "_internal_originalTime": 1689777025000
    },
    {
        "time": 1689777027000,
        "value": 139.953,
        "_internal_originalTime": 1689777027000
    },
    {
        "time": 1689777028000,
        "value": 139.956,
        "_internal_originalTime": 1689777028000
    },
    {
        "time": 1689777029000,
        "value": 139.962,
        "_internal_originalTime": 1689777029000
    },
    {
        "time": 1689777030000,
        "value": 139.963,
        "_internal_originalTime": 1689777030000
    },
    {
        "time": 1689777031000,
        "value": 139.964,
        "_internal_originalTime": 1689777031000
    },
    {
        "time": 1689777033000,
        "value": 139.963,
        "_internal_originalTime": 1689777033000
    },
    {
        "time": 1689777034000,
        "value": 139.96,
        "_internal_originalTime": 1689777034000
    },
    {
        "time": 1689777035000,
        "value": 139.961,
        "_internal_originalTime": 1689777035000
    },
    {
        "time": 1689777036000,
        "value": 139.958,
        "_internal_originalTime": 1689777036000
    },
    {
        "time": 1689777037000,
        "value": 139.962,
        "_internal_originalTime": 1689777037000
    },
    {
        "time": 1689777038000,
        "value": 139.961,
        "_internal_originalTime": 1689777038000
    },
    {
        "time": 1689777040000,
        "value": 139.955,
        "_internal_originalTime": 1689777040000
    },
    {
        "time": 1689777042000,
        "value": 139.949,
        "_internal_originalTime": 1689777042000
    },
    {
        "time": 1689777043000,
        "value": 139.945,
        "_internal_originalTime": 1689777043000
    },
    {
        "time": 1689777044000,
        "value": 139.944,
        "_internal_originalTime": 1689777044000
    },
    {
        "time": 1689777046000,
        "value": 139.942,
        "_internal_originalTime": 1689777046000
    },
    {
        "time": 1689777047000,
        "value": 139.944,
        "_internal_originalTime": 1689777047000
    },
    {
        "time": 1689777048000,
        "value": 139.952,
        "_internal_originalTime": 1689777048000
    },
    {
        "time": 1689777049000,
        "value": 139.952,
        "_internal_originalTime": 1689777049000
    },
    {
        "time": 1689777050000,
        "value": 139.954,
        "_internal_originalTime": 1689777050000
    },
    {
        "time": 1689777052000,
        "value": 139.954,
        "_internal_originalTime": 1689777052000
    },
    {
        "time": 1689777053000,
        "value": 139.95,
        "_internal_originalTime": 1689777053000
    },
    {
        "time": 1689777054000,
        "value": 139.95,
        "_internal_originalTime": 1689777054000
    },
    {
        "time": 1689777056000,
        "value": 139.954,
        "_internal_originalTime": 1689777056000
    },
    {
        "time": 1689777057000,
        "value": 139.954,
        "_internal_originalTime": 1689777057000
    },
    {
        "time": 1689777058000,
        "value": 139.95,
        "_internal_originalTime": 1689777058000
    },
    {
        "time": 1689777059000,
        "value": 139.956,
        "_internal_originalTime": 1689777059000
    },
    {
        "time": 1689777060000,
        "value": 139.957,
        "_internal_originalTime": 1689777060000
    },
    {
        "time": 1689777061000,
        "value": 139.964,
        "_internal_originalTime": 1689777061000
    },
    {
        "time": 1689777062000,
        "value": 139.963,
        "_internal_originalTime": 1689777062000
    },
    {
        "time": 1689777064000,
        "value": 139.959,
        "_internal_originalTime": 1689777064000
    },
    {
        "time": 1689777065000,
        "value": 139.963,
        "_internal_originalTime": 1689777065000
    },
    {
        "time": 1689777066000,
        "value": 139.958,
        "_internal_originalTime": 1689777066000
    },
    {
        "time": 1689777067000,
        "value": 139.958,
        "_internal_originalTime": 1689777067000
    },
    {
        "time": 1689777068000,
        "value": 139.948,
        "_internal_originalTime": 1689777068000
    },
    {
        "time": 1689777070000,
        "value": 139.956,
        "_internal_originalTime": 1689777070000
    },
    {
        "time": 1689777071000,
        "value": 139.95,
        "_internal_originalTime": 1689777071000
    },
    {
        "time": 1689777073000,
        "value": 139.955,
        "_internal_originalTime": 1689777073000
    },
    {
        "time": 1689777074000,
        "value": 139.95,
        "_internal_originalTime": 1689777074000
    },
    {
        "time": 1689777075000,
        "value": 139.955,
        "_internal_originalTime": 1689777075000
    },
    {
        "time": 1689777076000,
        "value": 139.942,
        "_internal_originalTime": 1689777076000
    },
    {
        "time": 1689777077000,
        "value": 139.943,
        "_internal_originalTime": 1689777077000
    },
    {
        "time": 1689777078000,
        "value": 139.939,
        "_internal_originalTime": 1689777078000
    },
    {
        "time": 1689777079000,
        "value": 139.935,
        "_internal_originalTime": 1689777079000
    },
    {
        "time": 1689777080000,
        "value": 139.935,
        "_internal_originalTime": 1689777080000
    },
    {
        "time": 1689777081000,
        "value": 139.937,
        "_internal_originalTime": 1689777081000
    },
    {
        "time": 1689777082000,
        "value": 139.932,
        "_internal_originalTime": 1689777082000
    },
    {
        "time": 1689777084000,
        "value": 139.937,
        "_internal_originalTime": 1689777084000
    },
    {
        "time": 1689777085000,
        "value": 139.924,
        "_internal_originalTime": 1689777085000
    },
    {
        "time": 1689777087000,
        "value": 139.927,
        "_internal_originalTime": 1689777087000
    },
    {
        "time": 1689777088000,
        "value": 139.92,
        "_internal_originalTime": 1689777088000
    },
    {
        "time": 1689777089000,
        "value": 139.924,
        "_internal_originalTime": 1689777089000
    },
    {
        "time": 1689777090000,
        "value": 139.917,
        "_internal_originalTime": 1689777090000
    },
    {
        "time": 1689777091000,
        "value": 139.924,
        "_internal_originalTime": 1689777091000
    },
    {
        "time": 1689777092000,
        "value": 139.916,
        "_internal_originalTime": 1689777092000
    },
    {
        "time": 1689777093000,
        "value": 139.913,
        "_internal_originalTime": 1689777093000
    },
    {
        "time": 1689777094000,
        "value": 139.91,
        "_internal_originalTime": 1689777094000
    },
    {
        "time": 1689777095000,
        "value": 139.91,
        "_internal_originalTime": 1689777095000
    },
    {
        "time": 1689777096000,
        "value": 139.91,
        "_internal_originalTime": 1689777096000
    },
    {
        "time": 1689777097000,
        "value": 139.917,
        "_internal_originalTime": 1689777097000
    },
    {
        "time": 1689777098000,
        "value": 139.911,
        "_internal_originalTime": 1689777098000
    },
    {
        "time": 1689777100000,
        "value": 139.916,
        "_internal_originalTime": 1689777100000
    },
    {
        "time": 1689777102000,
        "value": 139.917,
        "_internal_originalTime": 1689777102000
    },
    {
        "time": 1689777104000,
        "value": 139.92,
        "_internal_originalTime": 1689777104000
    },
    {
        "time": 1689777105000,
        "value": 139.905,
        "_internal_originalTime": 1689777105000
    },
    {
        "time": 1689777106000,
        "value": 139.906,
        "_internal_originalTime": 1689777106000
    },
    {
        "time": 1689777107000,
        "value": 139.908,
        "_internal_originalTime": 1689777107000
    },
    {
        "time": 1689777108000,
        "value": 139.908,
        "_internal_originalTime": 1689777108000
    },
    {
        "time": 1689777109000,
        "value": 139.907,
        "_internal_originalTime": 1689777109000
    },
    {
        "time": 1689777110000,
        "value": 139.902,
        "_internal_originalTime": 1689777110000
    },
    {
        "time": 1689777111000,
        "value": 139.9,
        "_internal_originalTime": 1689777111000
    },
    {
        "time": 1689777113000,
        "value": 139.903,
        "_internal_originalTime": 1689777113000
    },
    {
        "time": 1689777114000,
        "value": 139.902,
        "_internal_originalTime": 1689777114000
    },
    {
        "time": 1689777115000,
        "value": 139.908,
        "_internal_originalTime": 1689777115000
    },
    {
        "time": 1689777116000,
        "value": 139.914,
        "_internal_originalTime": 1689777116000
    },
    {
        "time": 1689777117000,
        "value": 139.918,
        "_internal_originalTime": 1689777117000
    },
    {
        "time": 1689777118000,
        "value": 139.922,
        "_internal_originalTime": 1689777118000
    },
    {
        "time": 1689777119000,
        "value": 139.92,
        "_internal_originalTime": 1689777119000
    },
    {
        "time": 1689777120000,
        "value": 139.916,
        "_internal_originalTime": 1689777120000
    },
    {
        "time": 1689777121000,
        "value": 139.908,
        "_internal_originalTime": 1689777121000
    },
    {
        "time": 1689777122000,
        "value": 139.908,
        "_internal_originalTime": 1689777122000
    },
    {
        "time": 1689777124000,
        "value": 139.918,
        "_internal_originalTime": 1689777124000
    },
    {
        "time": 1689777126000,
        "value": 139.923,
        "_internal_originalTime": 1689777126000
    },
    {
        "time": 1689777127000,
        "value": 139.926,
        "_internal_originalTime": 1689777127000
    },
    {
        "time": 1689777137000,
        "value": 139.926,
        "_internal_originalTime": 1689777137000
    },
    {
        "time": 1689777138000,
        "value": 139.925,
        "_internal_originalTime": 1689777138000
    },
    {
        "time": 1689777139000,
        "value": 139.924,
        "_internal_originalTime": 1689777139000
    },
    {
        "time": 1689777140000,
        "value": 139.914,
        "_internal_originalTime": 1689777140000
    },
    {
        "time": 1689777141000,
        "value": 139.916,
        "_internal_originalTime": 1689777141000
    },
    {
        "time": 1689777142000,
        "value": 139.916,
        "_internal_originalTime": 1689777142000
    },
    {
        "time": 1689777143000,
        "value": 139.92,
        "_internal_originalTime": 1689777143000
    },
    {
        "time": 1689777144000,
        "value": 139.918,
        "_internal_originalTime": 1689777144000
    },
    {
        "time": 1689777145000,
        "value": 139.918,
        "_internal_originalTime": 1689777145000
    },
    {
        "time": 1689777146000,
        "value": 139.914,
        "_internal_originalTime": 1689777146000
    },
    {
        "time": 1689777147000,
        "value": 139.918,
        "_internal_originalTime": 1689777147000
    },
    {
        "time": 1689777150000,
        "value": 139.917,
        "_internal_originalTime": 1689777150000
    },
    {
        "time": 1689777152000,
        "value": 139.909,
        "_internal_originalTime": 1689777152000
    },
    {
        "time": 1689777153000,
        "value": 139.909,
        "_internal_originalTime": 1689777153000
    },
    {
        "time": 1689777155000,
        "value": 139.909,
        "_internal_originalTime": 1689777155000
    },
    {
        "time": 1689777156000,
        "value": 139.907,
        "_internal_originalTime": 1689777156000
    },
    {
        "time": 1689777157000,
        "value": 139.903,
        "_internal_originalTime": 1689777157000
    },
    {
        "time": 1689777158000,
        "value": 139.905,
        "_internal_originalTime": 1689777158000
    },
    {
        "time": 1689777160000,
        "value": 139.905,
        "_internal_originalTime": 1689777160000
    },
    {
        "time": 1689777161000,
        "value": 139.905,
        "_internal_originalTime": 1689777161000
    },
    {
        "time": 1689777162000,
        "value": 139.902,
        "_internal_originalTime": 1689777162000
    },
    {
        "time": 1689777163000,
        "value": 139.907,
        "_internal_originalTime": 1689777163000
    },
    {
        "time": 1689777167000,
        "value": 139.91,
        "_internal_originalTime": 1689777167000
    },
    {
        "time": 1689777168000,
        "value": 139.925,
        "_internal_originalTime": 1689777168000
    },
    {
        "time": 1689777169000,
        "value": 139.924,
        "_internal_originalTime": 1689777169000
    },
    {
        "time": 1689777172000,
        "value": 139.935,
        "_internal_originalTime": 1689777172000
    },
    {
        "time": 1689777173000,
        "value": 139.935,
        "_internal_originalTime": 1689777173000
    },
    {
        "time": 1689777174000,
        "value": 139.944,
        "_internal_originalTime": 1689777174000
    },
    {
        "time": 1689777175000,
        "value": 139.938,
        "_internal_originalTime": 1689777175000
    },
    {
        "time": 1689777176000,
        "value": 139.938,
        "_internal_originalTime": 1689777176000
    },
    {
        "time": 1689777177000,
        "value": 139.939,
        "_internal_originalTime": 1689777177000
    },
    {
        "time": 1689777178000,
        "value": 139.942,
        "_internal_originalTime": 1689777178000
    },
    {
        "time": 1689777179000,
        "value": 139.937,
        "_internal_originalTime": 1689777179000
    },
    {
        "time": 1689777180000,
        "value": 139.936,
        "_internal_originalTime": 1689777180000
    },
    {
        "time": 1689777182000,
        "value": 139.936,
        "_internal_originalTime": 1689777182000
    },
    {
        "time": 1689777183000,
        "value": 139.934,
        "_internal_originalTime": 1689777183000
    },
    {
        "time": 1689777185000,
        "value": 139.943,
        "_internal_originalTime": 1689777185000
    },
    {
        "time": 1689777186000,
        "value": 139.944,
        "_internal_originalTime": 1689777186000
    },
    {
        "time": 1689777187000,
        "value": 139.948,
        "_internal_originalTime": 1689777187000
    },
    {
        "time": 1689777188000,
        "value": 139.954,
        "_internal_originalTime": 1689777188000
    },
    {
        "time": 1689777189000,
        "value": 139.95,
        "_internal_originalTime": 1689777189000
    },
    {
        "time": 1689777190000,
        "value": 139.95,
        "_internal_originalTime": 1689777190000
    },
    {
        "time": 1689777191000,
        "value": 139.95,
        "_internal_originalTime": 1689777191000
    },
    {
        "time": 1689777193000,
        "value": 139.948,
        "_internal_originalTime": 1689777193000
    },
    {
        "time": 1689777194000,
        "value": 139.946,
        "_internal_originalTime": 1689777194000
    },
    {
        "time": 1689777195000,
        "value": 139.952,
        "_internal_originalTime": 1689777195000
    },
    {
        "time": 1689777196000,
        "value": 139.952,
        "_internal_originalTime": 1689777196000
    },
    {
        "time": 1689777197000,
        "value": 139.947,
        "_internal_originalTime": 1689777197000
    },
    {
        "time": 1689777198000,
        "value": 139.945,
        "_internal_originalTime": 1689777198000
    },
    {
        "time": 1689777199000,
        "value": 139.944,
        "_internal_originalTime": 1689777199000
    },
    {
        "time": 1689777200000,
        "value": 139.944,
        "_internal_originalTime": 1689777200000
    },
    {
        "time": 1689777201000,
        "value": 139.945,
        "_internal_originalTime": 1689777201000
    },
    {
        "time": 1689777203000,
        "value": 139.944,
        "_internal_originalTime": 1689777203000
    },
    {
        "time": 1689777204000,
        "value": 139.944,
        "_internal_originalTime": 1689777204000
    },
    {
        "time": 1689777205000,
        "value": 139.944,
        "_internal_originalTime": 1689777205000
    },
    {
        "time": 1689777207000,
        "value": 139.938,
        "_internal_originalTime": 1689777207000
    },
    {
        "time": 1689777208000,
        "value": 139.944,
        "_internal_originalTime": 1689777208000
    },
    {
        "time": 1689777209000,
        "value": 139.94,
        "_internal_originalTime": 1689777209000
    },
    {
        "time": 1689777210000,
        "value": 139.942,
        "_internal_originalTime": 1689777210000
    },
    {
        "time": 1689777213000,
        "value": 139.944,
        "_internal_originalTime": 1689777213000
    },
    {
        "time": 1689777214000,
        "value": 139.945,
        "_internal_originalTime": 1689777214000
    },
    {
        "time": 1689777215000,
        "value": 139.945,
        "_internal_originalTime": 1689777215000
    },
    {
        "time": 1689777216000,
        "value": 139.943,
        "_internal_originalTime": 1689777216000
    },
    {
        "time": 1689777217000,
        "value": 139.944,
        "_internal_originalTime": 1689777217000
    },
    {
        "time": 1689777218000,
        "value": 139.942,
        "_internal_originalTime": 1689777218000
    },
    {
        "time": 1689777220000,
        "value": 139.94,
        "_internal_originalTime": 1689777220000
    },
    {
        "time": 1689777221000,
        "value": 139.942,
        "_internal_originalTime": 1689777221000
    },
    {
        "time": 1689777222000,
        "value": 139.944,
        "_internal_originalTime": 1689777222000
    },
    {
        "time": 1689777223000,
        "value": 139.944,
        "_internal_originalTime": 1689777223000
    },
    {
        "time": 1689777224000,
        "value": 139.944,
        "_internal_originalTime": 1689777224000
    },
    {
        "time": 1689777225000,
        "value": 139.944,
        "_internal_originalTime": 1689777225000
    },
    {
        "time": 1689777227000,
        "value": 139.942,
        "_internal_originalTime": 1689777227000
    },
    {
        "time": 1689777228000,
        "value": 139.952,
        "_internal_originalTime": 1689777228000
    },
    {
        "time": 1689777230000,
        "value": 139.95,
        "_internal_originalTime": 1689777230000
    },
    {
        "time": 1689777232000,
        "value": 139.953,
        "_internal_originalTime": 1689777232000
    },
    {
        "time": 1689777233000,
        "value": 139.94,
        "_internal_originalTime": 1689777233000
    },
    {
        "time": 1689777235000,
        "value": 139.944,
        "_internal_originalTime": 1689777235000
    },
    {
        "time": 1689777236000,
        "value": 139.944,
        "_internal_originalTime": 1689777236000
    },
    {
        "time": 1689777238000,
        "value": 139.942,
        "_internal_originalTime": 1689777238000
    },
    {
        "time": 1689777239000,
        "value": 139.944,
        "_internal_originalTime": 1689777239000
    },
    {
        "time": 1689777240000,
        "value": 139.944,
        "_internal_originalTime": 1689777240000
    },
    {
        "time": 1689777241000,
        "value": 139.93,
        "_internal_originalTime": 1689777241000
    },
    {
        "time": 1689777242000,
        "value": 139.935,
        "_internal_originalTime": 1689777242000
    },
    {
        "time": 1689777243000,
        "value": 139.94,
        "_internal_originalTime": 1689777243000
    },
    {
        "time": 1689777244000,
        "value": 139.933,
        "_internal_originalTime": 1689777244000
    },
    {
        "time": 1689777245000,
        "value": 139.934,
        "_internal_originalTime": 1689777245000
    },
    {
        "time": 1689777246000,
        "value": 139.934,
        "_internal_originalTime": 1689777246000
    },
    {
        "time": 1689777247000,
        "value": 139.932,
        "_internal_originalTime": 1689777247000
    },
    {
        "time": 1689777248000,
        "value": 139.927,
        "_internal_originalTime": 1689777248000
    },
    {
        "time": 1689777249000,
        "value": 139.92,
        "_internal_originalTime": 1689777249000
    },
    {
        "time": 1689777251000,
        "value": 139.919,
        "_internal_originalTime": 1689777251000
    },
    {
        "time": 1689777252000,
        "value": 139.922,
        "_internal_originalTime": 1689777252000
    },
    {
        "time": 1689777253000,
        "value": 139.922,
        "_internal_originalTime": 1689777253000
    },
    {
        "time": 1689777254000,
        "value": 139.924,
        "_internal_originalTime": 1689777254000
    },
    {
        "time": 1689777256000,
        "value": 139.925,
        "_internal_originalTime": 1689777256000
    },
    {
        "time": 1689777257000,
        "value": 139.928,
        "_internal_originalTime": 1689777257000
    },
    {
        "time": 1689777258000,
        "value": 139.927,
        "_internal_originalTime": 1689777258000
    },
    {
        "time": 1689777259000,
        "value": 139.928,
        "_internal_originalTime": 1689777259000
    },
    {
        "time": 1689777261000,
        "value": 139.933,
        "_internal_originalTime": 1689777261000
    },
    {
        "time": 1689777263000,
        "value": 139.932,
        "_internal_originalTime": 1689777263000
    },
    {
        "time": 1689777264000,
        "value": 139.929,
        "_internal_originalTime": 1689777264000
    },
    {
        "time": 1689777265000,
        "value": 139.927,
        "_internal_originalTime": 1689777265000
    },
    {
        "time": 1689777266000,
        "value": 139.924,
        "_internal_originalTime": 1689777266000
    },
    {
        "time": 1689777267000,
        "value": 139.927,
        "_internal_originalTime": 1689777267000
    },
    {
        "time": 1689777268000,
        "value": 139.926,
        "_internal_originalTime": 1689777268000
    },
    {
        "time": 1689777270000,
        "value": 139.92,
        "_internal_originalTime": 1689777270000
    },
    {
        "time": 1689777271000,
        "value": 139.921,
        "_internal_originalTime": 1689777271000
    },
    {
        "time": 1689777272000,
        "value": 139.934,
        "_internal_originalTime": 1689777272000
    },
    {
        "time": 1689777273000,
        "value": 139.934,
        "_internal_originalTime": 1689777273000
    },
    {
        "time": 1689777274000,
        "value": 139.93,
        "_internal_originalTime": 1689777274000
    },
    {
        "time": 1689777276000,
        "value": 139.931,
        "_internal_originalTime": 1689777276000
    },
    {
        "time": 1689777277000,
        "value": 139.934,
        "_internal_originalTime": 1689777277000
    },
    {
        "time": 1689777279000,
        "value": 139.924,
        "_internal_originalTime": 1689777279000
    },
    {
        "time": 1689777280000,
        "value": 139.927,
        "_internal_originalTime": 1689777280000
    },
    {
        "time": 1689777281000,
        "value": 139.924,
        "_internal_originalTime": 1689777281000
    },
    {
        "time": 1689777282000,
        "value": 139.918,
        "_internal_originalTime": 1689777282000
    },
    {
        "time": 1689777283000,
        "value": 139.921,
        "_internal_originalTime": 1689777283000
    },
    {
        "time": 1689777285000,
        "value": 139.918,
        "_internal_originalTime": 1689777285000
    },
    {
        "time": 1689777286000,
        "value": 139.924,
        "_internal_originalTime": 1689777286000
    },
    {
        "time": 1689777287000,
        "value": 139.924,
        "_internal_originalTime": 1689777287000
    },
    {
        "time": 1689777288000,
        "value": 139.923,
        "_internal_originalTime": 1689777288000
    },
    {
        "time": 1689777289000,
        "value": 139.921,
        "_internal_originalTime": 1689777289000
    },
    {
        "time": 1689777291000,
        "value": 139.922,
        "_internal_originalTime": 1689777291000
    },
    {
        "time": 1689777292000,
        "value": 139.923,
        "_internal_originalTime": 1689777292000
    },
    {
        "time": 1689777293000,
        "value": 139.922,
        "_internal_originalTime": 1689777293000
    },
    {
        "time": 1689777294000,
        "value": 139.934,
        "_internal_originalTime": 1689777294000
    },
    {
        "time": 1689777295000,
        "value": 139.924,
        "_internal_originalTime": 1689777295000
    },
    {
        "time": 1689777297000,
        "value": 139.909,
        "_internal_originalTime": 1689777297000
    },
    {
        "time": 1689777298000,
        "value": 139.902,
        "_internal_originalTime": 1689777298000
    },
    {
        "time": 1689777300000,
        "value": 139.906,
        "_internal_originalTime": 1689777300000
    },
    {
        "time": 1689777301000,
        "value": 139.906,
        "_internal_originalTime": 1689777301000
    },
    {
        "time": 1689777302000,
        "value": 139.905,
        "_internal_originalTime": 1689777302000
    },
    {
        "time": 1689777304000,
        "value": 139.905,
        "_internal_originalTime": 1689777304000
    },
    {
        "time": 1689777305000,
        "value": 139.899,
        "_internal_originalTime": 1689777305000
    },
    {
        "time": 1689777307000,
        "value": 139.893,
        "_internal_originalTime": 1689777307000
    },
    {
        "time": 1689777308000,
        "value": 139.894,
        "_internal_originalTime": 1689777308000
    },
    {
        "time": 1689777310000,
        "value": 139.899,
        "_internal_originalTime": 1689777310000
    },
    {
        "time": 1689777311000,
        "value": 139.903,
        "_internal_originalTime": 1689777311000
    },
    {
        "time": 1689777312000,
        "value": 139.898,
        "_internal_originalTime": 1689777312000
    },
    {
        "time": 1689777313000,
        "value": 139.895,
        "_internal_originalTime": 1689777313000
    },
    {
        "time": 1689777315000,
        "value": 139.894,
        "_internal_originalTime": 1689777315000
    },
    {
        "time": 1689777316000,
        "value": 139.89,
        "_internal_originalTime": 1689777316000
    },
    {
        "time": 1689777317000,
        "value": 139.894,
        "_internal_originalTime": 1689777317000
    },
    {
        "time": 1689777318000,
        "value": 139.889,
        "_internal_originalTime": 1689777318000
    },
    {
        "time": 1689777319000,
        "value": 139.883,
        "_internal_originalTime": 1689777319000
    },
    {
        "time": 1689777320000,
        "value": 139.886,
        "_internal_originalTime": 1689777320000
    },
    {
        "time": 1689777321000,
        "value": 139.885,
        "_internal_originalTime": 1689777321000
    },
    {
        "time": 1689777323000,
        "value": 139.89,
        "_internal_originalTime": 1689777323000
    },
    {
        "time": 1689777324000,
        "value": 139.886,
        "_internal_originalTime": 1689777324000
    },
    {
        "time": 1689777326000,
        "value": 139.888,
        "_internal_originalTime": 1689777326000
    },
    {
        "time": 1689777328000,
        "value": 139.882,
        "_internal_originalTime": 1689777328000
    },
    {
        "time": 1689777329000,
        "value": 139.875,
        "_internal_originalTime": 1689777329000
    },
    {
        "time": 1689777330000,
        "value": 139.872,
        "_internal_originalTime": 1689777330000
    },
    {
        "time": 1689777332000,
        "value": 139.87,
        "_internal_originalTime": 1689777332000
    },
    {
        "time": 1689777334000,
        "value": 139.864,
        "_internal_originalTime": 1689777334000
    },
    {
        "time": 1689777336000,
        "value": 139.858,
        "_internal_originalTime": 1689777336000
    },
    {
        "time": 1689777337000,
        "value": 139.845,
        "_internal_originalTime": 1689777337000
    },
    {
        "time": 1689777339000,
        "value": 139.856,
        "_internal_originalTime": 1689777339000
    },
    {
        "time": 1689777340000,
        "value": 139.853,
        "_internal_originalTime": 1689777340000
    },
    {
        "time": 1689777341000,
        "value": 139.843,
        "_internal_originalTime": 1689777341000
    },
    {
        "time": 1689777342000,
        "value": 139.847,
        "_internal_originalTime": 1689777342000
    },
    {
        "time": 1689777343000,
        "value": 139.847,
        "_internal_originalTime": 1689777343000
    },
    {
        "time": 1689777344000,
        "value": 139.826,
        "_internal_originalTime": 1689777344000
    },
    {
        "time": 1689777345000,
        "value": 139.824,
        "_internal_originalTime": 1689777345000
    },
    {
        "time": 1689777346000,
        "value": 139.817,
        "_internal_originalTime": 1689777346000
    },
    {
        "time": 1689777347000,
        "value": 139.818,
        "_internal_originalTime": 1689777347000
    },
    {
        "time": 1689777348000,
        "value": 139.821,
        "_internal_originalTime": 1689777348000
    },
    {
        "time": 1689777350000,
        "value": 139.818,
        "_internal_originalTime": 1689777350000
    },
    {
        "time": 1689777351000,
        "value": 139.809,
        "_internal_originalTime": 1689777351000
    },
    {
        "time": 1689777352000,
        "value": 139.811,
        "_internal_originalTime": 1689777352000
    },
    {
        "time": 1689777353000,
        "value": 139.814,
        "_internal_originalTime": 1689777353000
    },
    {
        "time": 1689777354000,
        "value": 139.816,
        "_internal_originalTime": 1689777354000
    },
    {
        "time": 1689777356000,
        "value": 139.827,
        "_internal_originalTime": 1689777356000
    },
    {
        "time": 1689777357000,
        "value": 139.818,
        "_internal_originalTime": 1689777357000
    },
    {
        "time": 1689777358000,
        "value": 139.802,
        "_internal_originalTime": 1689777358000
    },
    {
        "time": 1689777360000,
        "value": 139.802,
        "_internal_originalTime": 1689777360000
    },
    {
        "time": 1689777362000,
        "value": 139.81,
        "_internal_originalTime": 1689777362000
    },
    {
        "time": 1689777363000,
        "value": 139.813,
        "_internal_originalTime": 1689777363000
    },
    {
        "time": 1689777365000,
        "value": 139.81,
        "_internal_originalTime": 1689777365000
    },
    {
        "time": 1689777366000,
        "value": 139.809,
        "_internal_originalTime": 1689777366000
    },
    {
        "time": 1689777367000,
        "value": 139.811,
        "_internal_originalTime": 1689777367000
    },
    {
        "time": 1689777369000,
        "value": 139.809,
        "_internal_originalTime": 1689777369000
    },
    {
        "time": 1689777370000,
        "value": 139.811,
        "_internal_originalTime": 1689777370000
    },
    {
        "time": 1689777371000,
        "value": 139.824,
        "_internal_originalTime": 1689777371000
    },
    {
        "time": 1689777372000,
        "value": 139.814,
        "_internal_originalTime": 1689777372000
    },
    {
        "time": 1689777373000,
        "value": 139.819,
        "_internal_originalTime": 1689777373000
    },
    {
        "time": 1689777375000,
        "value": 139.8,
        "_internal_originalTime": 1689777375000
    },
    {
        "time": 1689777376000,
        "value": 139.799,
        "_internal_originalTime": 1689777376000
    },
    {
        "time": 1689777377000,
        "value": 139.82,
        "_internal_originalTime": 1689777377000
    },
    {
        "time": 1689777379000,
        "value": 139.821,
        "_internal_originalTime": 1689777379000
    },
    {
        "time": 1689777380000,
        "value": 139.817,
        "_internal_originalTime": 1689777380000
    },
    {
        "time": 1689777381000,
        "value": 139.822,
        "_internal_originalTime": 1689777381000
    },
    {
        "time": 1689777382000,
        "value": 139.822,
        "_internal_originalTime": 1689777382000
    },
    {
        "time": 1689777383000,
        "value": 139.822,
        "_internal_originalTime": 1689777383000
    },
    {
        "time": 1689777384000,
        "value": 139.829,
        "_internal_originalTime": 1689777384000
    },
    {
        "time": 1689777386000,
        "value": 139.828,
        "_internal_originalTime": 1689777386000
    },
    {
        "time": 1689777387000,
        "value": 139.839,
        "_internal_originalTime": 1689777387000
    },
    {
        "time": 1689777388000,
        "value": 139.836,
        "_internal_originalTime": 1689777388000
    },
    {
        "time": 1689777389000,
        "value": 139.838,
        "_internal_originalTime": 1689777389000
    },
    {
        "time": 1689777390000,
        "value": 139.836,
        "_internal_originalTime": 1689777390000
    },
    {
        "time": 1689777391000,
        "value": 139.844,
        "_internal_originalTime": 1689777391000
    },
    {
        "time": 1689777393000,
        "value": 139.846,
        "_internal_originalTime": 1689777393000
    },
    {
        "time": 1689777394000,
        "value": 139.847,
        "_internal_originalTime": 1689777394000
    },
    {
        "time": 1689777395000,
        "value": 139.852,
        "_internal_originalTime": 1689777395000
    },
    {
        "time": 1689777396000,
        "value": 139.852,
        "_internal_originalTime": 1689777396000
    },
    {
        "time": 1689777397000,
        "value": 139.855,
        "_internal_originalTime": 1689777397000
    },
    {
        "time": 1689777398000,
        "value": 139.855,
        "_internal_originalTime": 1689777398000
    },
    {
        "time": 1689777400000,
        "value": 139.857,
        "_internal_originalTime": 1689777400000
    },
    {
        "time": 1689777401000,
        "value": 139.857,
        "_internal_originalTime": 1689777401000
    },
    {
        "time": 1689777402000,
        "value": 139.855,
        "_internal_originalTime": 1689777402000
    },
    {
        "time": 1689777404000,
        "value": 139.854,
        "_internal_originalTime": 1689777404000
    },
    {
        "time": 1689777405000,
        "value": 139.857,
        "_internal_originalTime": 1689777405000
    },
    {
        "time": 1689777406000,
        "value": 139.849,
        "_internal_originalTime": 1689777406000
    },
    {
        "time": 1689777407000,
        "value": 139.841,
        "_internal_originalTime": 1689777407000
    },
    {
        "time": 1689777408000,
        "value": 139.835,
        "_internal_originalTime": 1689777408000
    },
    {
        "time": 1689777409000,
        "value": 139.837,
        "_internal_originalTime": 1689777409000
    },
    {
        "time": 1689777410000,
        "value": 139.839,
        "_internal_originalTime": 1689777410000
    },
    {
        "time": 1689777411000,
        "value": 139.841,
        "_internal_originalTime": 1689777411000
    },
    {
        "time": 1689777412000,
        "value": 139.846,
        "_internal_originalTime": 1689777412000
    },
    {
        "time": 1689777413000,
        "value": 139.847,
        "_internal_originalTime": 1689777413000
    },
    {
        "time": 1689777414000,
        "value": 139.842,
        "_internal_originalTime": 1689777414000
    },
    {
        "time": 1689777415000,
        "value": 139.848,
        "_internal_originalTime": 1689777415000
    },
    {
        "time": 1689777416000,
        "value": 139.848,
        "_internal_originalTime": 1689777416000
    },
    {
        "time": 1689777418000,
        "value": 139.849,
        "_internal_originalTime": 1689777418000
    },
    {
        "time": 1689777419000,
        "value": 139.854,
        "_internal_originalTime": 1689777419000
    },
    {
        "time": 1689777420000,
        "value": 139.85,
        "_internal_originalTime": 1689777420000
    },
    {
        "time": 1689777421000,
        "value": 139.83,
        "_internal_originalTime": 1689777421000
    },
    {
        "time": 1689777423000,
        "value": 139.848,
        "_internal_originalTime": 1689777423000
    },
    {
        "time": 1689777425000,
        "value": 139.849,
        "_internal_originalTime": 1689777425000
    },
    {
        "time": 1689777426000,
        "value": 139.849,
        "_internal_originalTime": 1689777426000
    },
    {
        "time": 1689777428000,
        "value": 139.849,
        "_internal_originalTime": 1689777428000
    },
    {
        "time": 1689777429000,
        "value": 139.844,
        "_internal_originalTime": 1689777429000
    },
    {
        "time": 1689777430000,
        "value": 139.851,
        "_internal_originalTime": 1689777430000
    },
    {
        "time": 1689777431000,
        "value": 139.857,
        "_internal_originalTime": 1689777431000
    },
    {
        "time": 1689777432000,
        "value": 139.851,
        "_internal_originalTime": 1689777432000
    },
    {
        "time": 1689777433000,
        "value": 139.85,
        "_internal_originalTime": 1689777433000
    },
    {
        "time": 1689777435000,
        "value": 139.853,
        "_internal_originalTime": 1689777435000
    },
    {
        "time": 1689777437000,
        "value": 139.847,
        "_internal_originalTime": 1689777437000
    },
    {
        "time": 1689777438000,
        "value": 139.849,
        "_internal_originalTime": 1689777438000
    },
    {
        "time": 1689777439000,
        "value": 139.85,
        "_internal_originalTime": 1689777439000
    },
    {
        "time": 1689777441000,
        "value": 139.846,
        "_internal_originalTime": 1689777441000
    },
    {
        "time": 1689777442000,
        "value": 139.849,
        "_internal_originalTime": 1689777442000
    },
    {
        "time": 1689777443000,
        "value": 139.85,
        "_internal_originalTime": 1689777443000
    },
    {
        "time": 1689777444000,
        "value": 139.847,
        "_internal_originalTime": 1689777444000
    },
    {
        "time": 1689777445000,
        "value": 139.846,
        "_internal_originalTime": 1689777445000
    },
    {
        "time": 1689777446000,
        "value": 139.853,
        "_internal_originalTime": 1689777446000
    },
    {
        "time": 1689777447000,
        "value": 139.851,
        "_internal_originalTime": 1689777447000
    },
    {
        "time": 1689777448000,
        "value": 139.847,
        "_internal_originalTime": 1689777448000
    },
    {
        "time": 1689777449000,
        "value": 139.836,
        "_internal_originalTime": 1689777449000
    },
    {
        "time": 1689777450000,
        "value": 139.84,
        "_internal_originalTime": 1689777450000
    },
    {
        "time": 1689777451000,
        "value": 139.842,
        "_internal_originalTime": 1689777451000
    },
    {
        "time": 1689777452000,
        "value": 139.84,
        "_internal_originalTime": 1689777452000
    },
    {
        "time": 1689777453000,
        "value": 139.838,
        "_internal_originalTime": 1689777453000
    },
    {
        "time": 1689777454000,
        "value": 139.831,
        "_internal_originalTime": 1689777454000
    },
    {
        "time": 1689777456000,
        "value": 139.835,
        "_internal_originalTime": 1689777456000
    },
    {
        "time": 1689777458000,
        "value": 139.839,
        "_internal_originalTime": 1689777458000
    },
    {
        "time": 1689777459000,
        "value": 139.834,
        "_internal_originalTime": 1689777459000
    },
    {
        "time": 1689777462000,
        "value": 139.848,
        "_internal_originalTime": 1689777462000
    },
    {
        "time": 1689777463000,
        "value": 139.846,
        "_internal_originalTime": 1689777463000
    },
    {
        "time": 1689777464000,
        "value": 139.846,
        "_internal_originalTime": 1689777464000
    },
    {
        "time": 1689777465000,
        "value": 139.847,
        "_internal_originalTime": 1689777465000
    },
    {
        "time": 1689777485000,
        "value": 139.847,
        "_internal_originalTime": 1689777485000
    },
    {
        "time": 1689777486000,
        "value": 139.847,
        "_internal_originalTime": 1689777486000
    },
    {
        "time": 1689777488000,
        "value": 139.848,
        "_internal_originalTime": 1689777488000
    },
    {
        "time": 1689777490000,
        "value": 139.845,
        "_internal_originalTime": 1689777490000
    },
    {
        "time": 1689777491000,
        "value": 139.842,
        "_internal_originalTime": 1689777491000
    },
    {
        "time": 1689777492000,
        "value": 139.842,
        "_internal_originalTime": 1689777492000
    },
    {
        "time": 1689777493000,
        "value": 139.846,
        "_internal_originalTime": 1689777493000
    },
    {
        "time": 1689777494000,
        "value": 139.842,
        "_internal_originalTime": 1689777494000
    },
    {
        "time": 1689777496000,
        "value": 139.847,
        "_internal_originalTime": 1689777496000
    },
    {
        "time": 1689777497000,
        "value": 139.857,
        "_internal_originalTime": 1689777497000
    },
    {
        "time": 1689777498000,
        "value": 139.86,
        "_internal_originalTime": 1689777498000
    },
    {
        "time": 1689777499000,
        "value": 139.866,
        "_internal_originalTime": 1689777499000
    },
    {
        "time": 1689777500000,
        "value": 139.866,
        "_internal_originalTime": 1689777500000
    },
    {
        "time": 1689777502000,
        "value": 139.868,
        "_internal_originalTime": 1689777502000
    },
    {
        "time": 1689777503000,
        "value": 139.868,
        "_internal_originalTime": 1689777503000
    },
    {
        "time": 1689777504000,
        "value": 139.87,
        "_internal_originalTime": 1689777504000
    },
    {
        "time": 1689777505000,
        "value": 139.87,
        "_internal_originalTime": 1689777505000
    },
    {
        "time": 1689777506000,
        "value": 139.866,
        "_internal_originalTime": 1689777506000
    },
    {
        "time": 1689777507000,
        "value": 139.869,
        "_internal_originalTime": 1689777507000
    },
    {
        "time": 1689777508000,
        "value": 139.87,
        "_internal_originalTime": 1689777508000
    },
    {
        "time": 1689777509000,
        "value": 139.869,
        "_internal_originalTime": 1689777509000
    },
    {
        "time": 1689777510000,
        "value": 139.876,
        "_internal_originalTime": 1689777510000
    },
    {
        "time": 1689777511000,
        "value": 139.876,
        "_internal_originalTime": 1689777511000
    },
    {
        "time": 1689777513000,
        "value": 139.879,
        "_internal_originalTime": 1689777513000
    },
    {
        "time": 1689777514000,
        "value": 139.88,
        "_internal_originalTime": 1689777514000
    },
    {
        "time": 1689777515000,
        "value": 139.879,
        "_internal_originalTime": 1689777515000
    },
    {
        "time": 1689777516000,
        "value": 139.876,
        "_internal_originalTime": 1689777516000
    },
    {
        "time": 1689777517000,
        "value": 139.879,
        "_internal_originalTime": 1689777517000
    },
    {
        "time": 1689777518000,
        "value": 139.879,
        "_internal_originalTime": 1689777518000
    },
    {
        "time": 1689777519000,
        "value": 139.877,
        "_internal_originalTime": 1689777519000
    },
    {
        "time": 1689777520000,
        "value": 139.877,
        "_internal_originalTime": 1689777520000
    },
    {
        "time": 1689777521000,
        "value": 139.878,
        "_internal_originalTime": 1689777521000
    },
    {
        "time": 1689777522000,
        "value": 139.876,
        "_internal_originalTime": 1689777522000
    },
    {
        "time": 1689777523000,
        "value": 139.879,
        "_internal_originalTime": 1689777523000
    },
    {
        "time": 1689777524000,
        "value": 139.88,
        "_internal_originalTime": 1689777524000
    },
    {
        "time": 1689777526000,
        "value": 139.878,
        "_internal_originalTime": 1689777526000
    },
    {
        "time": 1689777527000,
        "value": 139.886,
        "_internal_originalTime": 1689777527000
    },
    {
        "time": 1689777528000,
        "value": 139.884,
        "_internal_originalTime": 1689777528000
    },
    {
        "time": 1689777529000,
        "value": 139.879,
        "_internal_originalTime": 1689777529000
    },
    {
        "time": 1689777530000,
        "value": 139.88,
        "_internal_originalTime": 1689777530000
    },
    {
        "time": 1689777531000,
        "value": 139.884,
        "_internal_originalTime": 1689777531000
    },
    {
        "time": 1689777532000,
        "value": 139.884,
        "_internal_originalTime": 1689777532000
    },
    {
        "time": 1689777533000,
        "value": 139.891,
        "_internal_originalTime": 1689777533000
    },
    {
        "time": 1689777534000,
        "value": 139.891,
        "_internal_originalTime": 1689777534000
    },
    {
        "time": 1689777535000,
        "value": 139.892,
        "_internal_originalTime": 1689777535000
    },
    {
        "time": 1689777536000,
        "value": 139.89,
        "_internal_originalTime": 1689777536000
    },
    {
        "time": 1689777537000,
        "value": 139.893,
        "_internal_originalTime": 1689777537000
    },
    {
        "time": 1689777538000,
        "value": 139.892,
        "_internal_originalTime": 1689777538000
    },
    {
        "time": 1689777540000,
        "value": 139.892,
        "_internal_originalTime": 1689777540000
    },
    {
        "time": 1689777541000,
        "value": 139.893,
        "_internal_originalTime": 1689777541000
    },
    {
        "time": 1689777542000,
        "value": 139.891,
        "_internal_originalTime": 1689777542000
    },
    {
        "time": 1689777544000,
        "value": 139.898,
        "_internal_originalTime": 1689777544000
    },
    {
        "time": 1689777545000,
        "value": 139.899,
        "_internal_originalTime": 1689777545000
    },
    {
        "time": 1689777546000,
        "value": 139.894,
        "_internal_originalTime": 1689777546000
    },
    {
        "time": 1689777547000,
        "value": 139.895,
        "_internal_originalTime": 1689777547000
    },
    {
        "time": 1689777548000,
        "value": 139.892,
        "_internal_originalTime": 1689777548000
    },
    {
        "time": 1689777550000,
        "value": 139.891,
        "_internal_originalTime": 1689777550000
    },
    {
        "time": 1689777551000,
        "value": 139.888,
        "_internal_originalTime": 1689777551000
    },
    {
        "time": 1689777552000,
        "value": 139.884,
        "_internal_originalTime": 1689777552000
    },
    {
        "time": 1689777553000,
        "value": 139.895,
        "_internal_originalTime": 1689777553000
    },
    {
        "time": 1689777554000,
        "value": 139.899,
        "_internal_originalTime": 1689777554000
    },
    {
        "time": 1689777555000,
        "value": 139.899,
        "_internal_originalTime": 1689777555000
    },
    {
        "time": 1689777556000,
        "value": 139.899,
        "_internal_originalTime": 1689777556000
    },
    {
        "time": 1689777557000,
        "value": 139.876,
        "_internal_originalTime": 1689777557000
    },
    {
        "time": 1689777558000,
        "value": 139.879,
        "_internal_originalTime": 1689777558000
    },
    {
        "time": 1689777559000,
        "value": 139.876,
        "_internal_originalTime": 1689777559000
    },
    {
        "time": 1689777560000,
        "value": 139.879,
        "_internal_originalTime": 1689777560000
    },
    {
        "time": 1689777561000,
        "value": 139.874,
        "_internal_originalTime": 1689777561000
    },
    {
        "time": 1689777562000,
        "value": 139.874,
        "_internal_originalTime": 1689777562000
    },
    {
        "time": 1689777563000,
        "value": 139.867,
        "_internal_originalTime": 1689777563000
    },
    {
        "time": 1689777565000,
        "value": 139.859,
        "_internal_originalTime": 1689777565000
    },
    {
        "time": 1689777566000,
        "value": 139.86,
        "_internal_originalTime": 1689777566000
    },
    {
        "time": 1689777567000,
        "value": 139.86,
        "_internal_originalTime": 1689777567000
    },
    {
        "time": 1689777568000,
        "value": 139.86,
        "_internal_originalTime": 1689777568000
    },
    {
        "time": 1689777569000,
        "value": 139.856,
        "_internal_originalTime": 1689777569000
    },
    {
        "time": 1689777570000,
        "value": 139.856,
        "_internal_originalTime": 1689777570000
    },
    {
        "time": 1689777571000,
        "value": 139.85,
        "_internal_originalTime": 1689777571000
    },
    {
        "time": 1689777572000,
        "value": 139.859,
        "_internal_originalTime": 1689777572000
    },
    {
        "time": 1689777573000,
        "value": 139.849,
        "_internal_originalTime": 1689777573000
    },
    {
        "time": 1689777574000,
        "value": 139.846,
        "_internal_originalTime": 1689777574000
    },
    {
        "time": 1689777575000,
        "value": 139.848,
        "_internal_originalTime": 1689777575000
    },
    {
        "time": 1689777576000,
        "value": 139.849,
        "_internal_originalTime": 1689777576000
    },
    {
        "time": 1689777577000,
        "value": 139.849,
        "_internal_originalTime": 1689777577000
    },
    {
        "time": 1689777578000,
        "value": 139.85,
        "_internal_originalTime": 1689777578000
    },
    {
        "time": 1689777579000,
        "value": 139.847,
        "_internal_originalTime": 1689777579000
    },
    {
        "time": 1689777582000,
        "value": 139.844,
        "_internal_originalTime": 1689777582000
    },
    {
        "time": 1689777583000,
        "value": 139.849,
        "_internal_originalTime": 1689777583000
    },
    {
        "time": 1689777584000,
        "value": 139.849,
        "_internal_originalTime": 1689777584000
    },
    {
        "time": 1689777585000,
        "value": 139.85,
        "_internal_originalTime": 1689777585000
    },
    {
        "time": 1689777586000,
        "value": 139.85,
        "_internal_originalTime": 1689777586000
    },
    {
        "time": 1689777587000,
        "value": 139.848,
        "_internal_originalTime": 1689777587000
    },
    {
        "time": 1689777588000,
        "value": 139.85,
        "_internal_originalTime": 1689777588000
    },
    {
        "time": 1689777589000,
        "value": 139.849,
        "_internal_originalTime": 1689777589000
    },
    {
        "time": 1689777590000,
        "value": 139.849,
        "_internal_originalTime": 1689777590000
    },
    {
        "time": 1689777591000,
        "value": 139.854,
        "_internal_originalTime": 1689777591000
    },
    {
        "time": 1689777592000,
        "value": 139.859,
        "_internal_originalTime": 1689777592000
    },
    {
        "time": 1689777593000,
        "value": 139.861,
        "_internal_originalTime": 1689777593000
    },
    {
        "time": 1689777594000,
        "value": 139.861,
        "_internal_originalTime": 1689777594000
    },
    {
        "time": 1689777595000,
        "value": 139.856,
        "_internal_originalTime": 1689777595000
    },
    {
        "time": 1689777596000,
        "value": 139.861,
        "_internal_originalTime": 1689777596000
    },
    {
        "time": 1689777597000,
        "value": 139.858,
        "_internal_originalTime": 1689777597000
    },
    {
        "time": 1689777598000,
        "value": 139.865,
        "_internal_originalTime": 1689777598000
    },
    {
        "time": 1689777599000,
        "value": 139.865,
        "_internal_originalTime": 1689777599000
    },
    {
        "time": 1689777600000,
        "value": 139.868,
        "_internal_originalTime": 1689777600000
    },
    {
        "time": 1689777601000,
        "value": 139.861,
        "_internal_originalTime": 1689777601000
    },
    {
        "time": 1689777602000,
        "value": 139.869,
        "_internal_originalTime": 1689777602000
    },
    {
        "time": 1689777604000,
        "value": 139.868,
        "_internal_originalTime": 1689777604000
    },
    {
        "time": 1689777607000,
        "value": 139.864,
        "_internal_originalTime": 1689777607000
    },
    {
        "time": 1689777608000,
        "value": 139.867,
        "_internal_originalTime": 1689777608000
    },
    {
        "time": 1689777609000,
        "value": 139.864,
        "_internal_originalTime": 1689777609000
    },
    {
        "time": 1689777610000,
        "value": 139.868,
        "_internal_originalTime": 1689777610000
    },
    {
        "time": 1689777611000,
        "value": 139.868,
        "_internal_originalTime": 1689777611000
    },
    {
        "time": 1689777612000,
        "value": 139.869,
        "_internal_originalTime": 1689777612000
    },
    {
        "time": 1689777613000,
        "value": 139.868,
        "_internal_originalTime": 1689777613000
    },
    {
        "time": 1689777614000,
        "value": 139.852,
        "_internal_originalTime": 1689777614000
    },
    {
        "time": 1689777615000,
        "value": 139.858,
        "_internal_originalTime": 1689777615000
    },
    {
        "time": 1689777616000,
        "value": 139.854,
        "_internal_originalTime": 1689777616000
    },
    {
        "time": 1689777617000,
        "value": 139.86,
        "_internal_originalTime": 1689777617000
    },
    {
        "time": 1689777618000,
        "value": 139.858,
        "_internal_originalTime": 1689777618000
    },
    {
        "time": 1689777619000,
        "value": 139.859,
        "_internal_originalTime": 1689777619000
    },
    {
        "time": 1689777620000,
        "value": 139.858,
        "_internal_originalTime": 1689777620000
    },
    {
        "time": 1689777621000,
        "value": 139.859,
        "_internal_originalTime": 1689777621000
    },
    {
        "time": 1689777622000,
        "value": 139.864,
        "_internal_originalTime": 1689777622000
    },
    {
        "time": 1689777623000,
        "value": 139.856,
        "_internal_originalTime": 1689777623000
    },
    {
        "time": 1689777624000,
        "value": 139.858,
        "_internal_originalTime": 1689777624000
    },
    {
        "time": 1689777625000,
        "value": 139.858,
        "_internal_originalTime": 1689777625000
    },
    {
        "time": 1689777626000,
        "value": 139.854,
        "_internal_originalTime": 1689777626000
    },
    {
        "time": 1689777627000,
        "value": 139.861,
        "_internal_originalTime": 1689777627000
    },
    {
        "time": 1689777628000,
        "value": 139.86,
        "_internal_originalTime": 1689777628000
    },
    {
        "time": 1689777629000,
        "value": 139.859,
        "_internal_originalTime": 1689777629000
    },
    {
        "time": 1689777630000,
        "value": 139.857,
        "_internal_originalTime": 1689777630000
    },
    {
        "time": 1689777631000,
        "value": 139.859,
        "_internal_originalTime": 1689777631000
    },
    {
        "time": 1689777634000,
        "value": 139.857,
        "_internal_originalTime": 1689777634000
    },
    {
        "time": 1689777635000,
        "value": 139.851,
        "_internal_originalTime": 1689777635000
    },
    {
        "time": 1689777636000,
        "value": 139.852,
        "_internal_originalTime": 1689777636000
    },
    {
        "time": 1689777637000,
        "value": 139.853,
        "_internal_originalTime": 1689777637000
    },
    {
        "time": 1689777638000,
        "value": 139.864,
        "_internal_originalTime": 1689777638000
    },
    {
        "time": 1689777639000,
        "value": 139.87,
        "_internal_originalTime": 1689777639000
    },
    {
        "time": 1689777641000,
        "value": 139.869,
        "_internal_originalTime": 1689777641000
    },
    {
        "time": 1689777642000,
        "value": 139.864,
        "_internal_originalTime": 1689777642000
    },
    {
        "time": 1689777643000,
        "value": 139.868,
        "_internal_originalTime": 1689777643000
    },
    {
        "time": 1689777645000,
        "value": 139.869,
        "_internal_originalTime": 1689777645000
    },
    {
        "time": 1689777646000,
        "value": 139.87,
        "_internal_originalTime": 1689777646000
    },
    {
        "time": 1689777647000,
        "value": 139.871,
        "_internal_originalTime": 1689777647000
    },
    {
        "time": 1689777648000,
        "value": 139.871,
        "_internal_originalTime": 1689777648000
    },
    {
        "time": 1689777649000,
        "value": 139.879,
        "_internal_originalTime": 1689777649000
    },
    {
        "time": 1689777650000,
        "value": 139.874,
        "_internal_originalTime": 1689777650000
    },
    {
        "time": 1689777651000,
        "value": 139.879,
        "_internal_originalTime": 1689777651000
    },
    {
        "time": 1689777652000,
        "value": 139.881,
        "_internal_originalTime": 1689777652000
    },
    {
        "time": 1689777653000,
        "value": 139.879,
        "_internal_originalTime": 1689777653000
    },
    {
        "time": 1689777654000,
        "value": 139.874,
        "_internal_originalTime": 1689777654000
    },
    {
        "time": 1689777655000,
        "value": 139.873,
        "_internal_originalTime": 1689777655000
    },
    {
        "time": 1689777657000,
        "value": 139.879,
        "_internal_originalTime": 1689777657000
    },
    {
        "time": 1689777658000,
        "value": 139.879,
        "_internal_originalTime": 1689777658000
    },
    {
        "time": 1689777659000,
        "value": 139.874,
        "_internal_originalTime": 1689777659000
    },
    {
        "time": 1689777660000,
        "value": 139.863,
        "_internal_originalTime": 1689777660000
    },
    {
        "time": 1689777661000,
        "value": 139.845,
        "_internal_originalTime": 1689777661000
    },
    {
        "time": 1689777662000,
        "value": 139.848,
        "_internal_originalTime": 1689777662000
    },
    {
        "time": 1689777664000,
        "value": 139.849,
        "_internal_originalTime": 1689777664000
    },
    {
        "time": 1689777665000,
        "value": 139.842,
        "_internal_originalTime": 1689777665000
    },
    {
        "time": 1689777666000,
        "value": 139.842,
        "_internal_originalTime": 1689777666000
    },
    {
        "time": 1689777667000,
        "value": 139.848,
        "_internal_originalTime": 1689777667000
    },
    {
        "time": 1689777668000,
        "value": 139.845,
        "_internal_originalTime": 1689777668000
    },
    {
        "time": 1689777669000,
        "value": 139.844,
        "_internal_originalTime": 1689777669000
    },
    {
        "time": 1689777670000,
        "value": 139.845,
        "_internal_originalTime": 1689777670000
    },
    {
        "time": 1689777671000,
        "value": 139.843,
        "_internal_originalTime": 1689777671000
    },
    {
        "time": 1689777672000,
        "value": 139.832,
        "_internal_originalTime": 1689777672000
    },
    {
        "time": 1689777673000,
        "value": 139.837,
        "_internal_originalTime": 1689777673000
    },
    {
        "time": 1689777674000,
        "value": 139.837,
        "_internal_originalTime": 1689777674000
    },
    {
        "time": 1689777675000,
        "value": 139.836,
        "_internal_originalTime": 1689777675000
    },
    {
        "time": 1689777677000,
        "value": 139.841,
        "_internal_originalTime": 1689777677000
    },
    {
        "time": 1689777678000,
        "value": 139.834,
        "_internal_originalTime": 1689777678000
    },
    {
        "time": 1689777680000,
        "value": 139.837,
        "_internal_originalTime": 1689777680000
    },
    {
        "time": 1689777681000,
        "value": 139.84,
        "_internal_originalTime": 1689777681000
    },
    {
        "time": 1689777682000,
        "value": 139.832,
        "_internal_originalTime": 1689777682000
    },
    {
        "time": 1689777684000,
        "value": 139.832,
        "_internal_originalTime": 1689777684000
    },
    {
        "time": 1689777685000,
        "value": 139.834,
        "_internal_originalTime": 1689777685000
    },
    {
        "time": 1689777687000,
        "value": 139.845,
        "_internal_originalTime": 1689777687000
    },
    {
        "time": 1689777688000,
        "value": 139.847,
        "_internal_originalTime": 1689777688000
    },
    {
        "time": 1689777689000,
        "value": 139.849,
        "_internal_originalTime": 1689777689000
    },
    {
        "time": 1689777690000,
        "value": 139.847,
        "_internal_originalTime": 1689777690000
    },
    {
        "time": 1689777691000,
        "value": 139.847,
        "_internal_originalTime": 1689777691000
    },
    {
        "time": 1689777692000,
        "value": 139.853,
        "_internal_originalTime": 1689777692000
    },
    {
        "time": 1689777693000,
        "value": 139.851,
        "_internal_originalTime": 1689777693000
    },
    {
        "time": 1689777694000,
        "value": 139.855,
        "_internal_originalTime": 1689777694000
    },
    {
        "time": 1689777697000,
        "value": 139.869,
        "_internal_originalTime": 1689777697000
    },
    {
        "time": 1689777698000,
        "value": 139.864,
        "_internal_originalTime": 1689777698000
    },
    {
        "time": 1689777699000,
        "value": 139.868,
        "_internal_originalTime": 1689777699000
    },
    {
        "time": 1689777700000,
        "value": 139.871,
        "_internal_originalTime": 1689777700000
    },
    {
        "time": 1689777702000,
        "value": 139.88,
        "_internal_originalTime": 1689777702000
    },
    {
        "time": 1689777703000,
        "value": 139.877,
        "_internal_originalTime": 1689777703000
    },
    {
        "time": 1689777704000,
        "value": 139.864,
        "_internal_originalTime": 1689777704000
    },
    {
        "time": 1689777705000,
        "value": 139.864,
        "_internal_originalTime": 1689777705000
    },
    {
        "time": 1689777706000,
        "value": 139.866,
        "_internal_originalTime": 1689777706000
    },
    {
        "time": 1689777707000,
        "value": 139.866,
        "_internal_originalTime": 1689777707000
    },
    {
        "time": 1689777708000,
        "value": 139.877,
        "_internal_originalTime": 1689777708000
    },
    {
        "time": 1689777709000,
        "value": 139.884,
        "_internal_originalTime": 1689777709000
    },
    {
        "time": 1689777710000,
        "value": 139.879,
        "_internal_originalTime": 1689777710000
    },
    {
        "time": 1689777711000,
        "value": 139.878,
        "_internal_originalTime": 1689777711000
    },
    {
        "time": 1689777712000,
        "value": 139.88,
        "_internal_originalTime": 1689777712000
    },
    {
        "time": 1689777713000,
        "value": 139.882,
        "_internal_originalTime": 1689777713000
    },
    {
        "time": 1689777714000,
        "value": 139.887,
        "_internal_originalTime": 1689777714000
    },
    {
        "time": 1689777715000,
        "value": 139.888,
        "_internal_originalTime": 1689777715000
    },
    {
        "time": 1689777716000,
        "value": 139.88,
        "_internal_originalTime": 1689777716000
    },
    {
        "time": 1689777718000,
        "value": 139.894,
        "_internal_originalTime": 1689777718000
    },
    {
        "time": 1689777719000,
        "value": 139.886,
        "_internal_originalTime": 1689777719000
    },
    {
        "time": 1689777720000,
        "value": 139.89,
        "_internal_originalTime": 1689777720000
    },
    {
        "time": 1689777721000,
        "value": 139.893,
        "_internal_originalTime": 1689777721000
    },
    {
        "time": 1689777722000,
        "value": 139.895,
        "_internal_originalTime": 1689777722000
    },
    {
        "time": 1689777723000,
        "value": 139.895,
        "_internal_originalTime": 1689777723000
    },
    {
        "time": 1689777724000,
        "value": 139.897,
        "_internal_originalTime": 1689777724000
    },
    {
        "time": 1689777725000,
        "value": 139.898,
        "_internal_originalTime": 1689777725000
    },
    {
        "time": 1689777726000,
        "value": 139.899,
        "_internal_originalTime": 1689777726000
    },
    {
        "time": 1689777728000,
        "value": 139.899,
        "_internal_originalTime": 1689777728000
    },
    {
        "time": 1689777729000,
        "value": 139.898,
        "_internal_originalTime": 1689777729000
    },
    {
        "time": 1689777731000,
        "value": 139.899,
        "_internal_originalTime": 1689777731000
    },
    {
        "time": 1689777732000,
        "value": 139.9,
        "_internal_originalTime": 1689777732000
    },
    {
        "time": 1689777734000,
        "value": 139.91,
        "_internal_originalTime": 1689777734000
    },
    {
        "time": 1689777736000,
        "value": 139.908,
        "_internal_originalTime": 1689777736000
    },
    {
        "time": 1689777737000,
        "value": 139.908,
        "_internal_originalTime": 1689777737000
    },
    {
        "time": 1689777739000,
        "value": 139.908,
        "_internal_originalTime": 1689777739000
    },
    {
        "time": 1689777740000,
        "value": 139.902,
        "_internal_originalTime": 1689777740000
    },
    {
        "time": 1689777741000,
        "value": 139.903,
        "_internal_originalTime": 1689777741000
    },
    {
        "time": 1689777742000,
        "value": 139.904,
        "_internal_originalTime": 1689777742000
    },
    {
        "time": 1689777744000,
        "value": 139.903,
        "_internal_originalTime": 1689777744000
    },
    {
        "time": 1689777745000,
        "value": 139.908,
        "_internal_originalTime": 1689777745000
    },
    {
        "time": 1689777746000,
        "value": 139.912,
        "_internal_originalTime": 1689777746000
    },
    {
        "time": 1689777747000,
        "value": 139.913,
        "_internal_originalTime": 1689777747000
    },
    {
        "time": 1689777748000,
        "value": 139.916,
        "_internal_originalTime": 1689777748000
    },
    {
        "time": 1689777749000,
        "value": 139.916,
        "_internal_originalTime": 1689777749000
    },
    {
        "time": 1689777751000,
        "value": 139.912,
        "_internal_originalTime": 1689777751000
    },
    {
        "time": 1689777752000,
        "value": 139.907,
        "_internal_originalTime": 1689777752000
    },
    {
        "time": 1689777753000,
        "value": 139.902,
        "_internal_originalTime": 1689777753000
    },
    {
        "time": 1689777754000,
        "value": 139.904,
        "_internal_originalTime": 1689777754000
    },
    {
        "time": 1689777755000,
        "value": 139.909,
        "_internal_originalTime": 1689777755000
    },
    {
        "time": 1689777756000,
        "value": 139.909,
        "_internal_originalTime": 1689777756000
    },
    {
        "time": 1689777757000,
        "value": 139.909,
        "_internal_originalTime": 1689777757000
    },
    {
        "time": 1689777758000,
        "value": 139.909,
        "_internal_originalTime": 1689777758000
    },
    {
        "time": 1689777759000,
        "value": 139.904,
        "_internal_originalTime": 1689777759000
    },
    {
        "time": 1689777760000,
        "value": 139.911,
        "_internal_originalTime": 1689777760000
    },
    {
        "time": 1689777761000,
        "value": 139.911,
        "_internal_originalTime": 1689777761000
    },
    {
        "time": 1689777762000,
        "value": 139.909,
        "_internal_originalTime": 1689777762000
    },
    {
        "time": 1689777763000,
        "value": 139.907,
        "_internal_originalTime": 1689777763000
    },
    {
        "time": 1689777764000,
        "value": 139.908,
        "_internal_originalTime": 1689777764000
    },
    {
        "time": 1689777765000,
        "value": 139.91,
        "_internal_originalTime": 1689777765000
    },
    {
        "time": 1689777766000,
        "value": 139.903,
        "_internal_originalTime": 1689777766000
    },
    {
        "time": 1689777767000,
        "value": 139.904,
        "_internal_originalTime": 1689777767000
    },
    {
        "time": 1689777768000,
        "value": 139.902,
        "_internal_originalTime": 1689777768000
    },
    {
        "time": 1689777770000,
        "value": 139.896,
        "_internal_originalTime": 1689777770000
    },
    {
        "time": 1689777771000,
        "value": 139.901,
        "_internal_originalTime": 1689777771000
    },
    {
        "time": 1689777774000,
        "value": 139.902,
        "_internal_originalTime": 1689777774000
    },
    {
        "time": 1689777775000,
        "value": 139.903,
        "_internal_originalTime": 1689777775000
    },
    {
        "time": 1689777777000,
        "value": 139.914,
        "_internal_originalTime": 1689777777000
    },
    {
        "time": 1689777778000,
        "value": 139.915,
        "_internal_originalTime": 1689777778000
    },
    {
        "time": 1689777779000,
        "value": 139.914,
        "_internal_originalTime": 1689777779000
    },
    {
        "time": 1689777780000,
        "value": 139.913,
        "_internal_originalTime": 1689777780000
    },
    {
        "time": 1689777781000,
        "value": 139.914,
        "_internal_originalTime": 1689777781000
    },
    {
        "time": 1689777782000,
        "value": 139.919,
        "_internal_originalTime": 1689777782000
    },
    {
        "time": 1689777783000,
        "value": 139.917,
        "_internal_originalTime": 1689777783000
    },
    {
        "time": 1689777784000,
        "value": 139.906,
        "_internal_originalTime": 1689777784000
    },
    {
        "time": 1689777803000,
        "value": 139.886,
        "_internal_originalTime": 1689777803000
    },
    {
        "time": 1689777804000,
        "value": 139.882,
        "_internal_originalTime": 1689777804000
    },
    {
        "time": 1689777806000,
        "value": 139.887,
        "_internal_originalTime": 1689777806000
    },
    {
        "time": 1689777808000,
        "value": 139.887,
        "_internal_originalTime": 1689777808000
    },
    {
        "time": 1689777809000,
        "value": 139.887,
        "_internal_originalTime": 1689777809000
    },
    {
        "time": 1689777810000,
        "value": 139.887,
        "_internal_originalTime": 1689777810000
    },
    {
        "time": 1689777812000,
        "value": 139.887,
        "_internal_originalTime": 1689777812000
    },
    {
        "time": 1689777813000,
        "value": 139.887,
        "_internal_originalTime": 1689777813000
    },
    {
        "time": 1689777814000,
        "value": 139.887,
        "_internal_originalTime": 1689777814000
    },
    {
        "time": 1689777816000,
        "value": 139.886,
        "_internal_originalTime": 1689777816000
    },
    {
        "time": 1689777817000,
        "value": 139.876,
        "_internal_originalTime": 1689777817000
    },
    {
        "time": 1689777818000,
        "value": 139.872,
        "_internal_originalTime": 1689777818000
    },
    {
        "time": 1689777819000,
        "value": 139.873,
        "_internal_originalTime": 1689777819000
    },
    {
        "time": 1689777821000,
        "value": 139.869,
        "_internal_originalTime": 1689777821000
    },
    {
        "time": 1689777823000,
        "value": 139.877,
        "_internal_originalTime": 1689777823000
    },
    {
        "time": 1689777825000,
        "value": 139.869,
        "_internal_originalTime": 1689777825000
    },
    {
        "time": 1689777826000,
        "value": 139.869,
        "_internal_originalTime": 1689777826000
    },
    {
        "time": 1689777827000,
        "value": 139.87,
        "_internal_originalTime": 1689777827000
    },
    {
        "time": 1689777828000,
        "value": 139.872,
        "_internal_originalTime": 1689777828000
    },
    {
        "time": 1689777829000,
        "value": 139.874,
        "_internal_originalTime": 1689777829000
    },
    {
        "time": 1689777831000,
        "value": 139.873,
        "_internal_originalTime": 1689777831000
    },
    {
        "time": 1689777832000,
        "value": 139.871,
        "_internal_originalTime": 1689777832000
    },
    {
        "time": 1689777833000,
        "value": 139.874,
        "_internal_originalTime": 1689777833000
    },
    {
        "time": 1689777834000,
        "value": 139.868,
        "_internal_originalTime": 1689777834000
    },
    {
        "time": 1689777836000,
        "value": 139.869,
        "_internal_originalTime": 1689777836000
    },
    {
        "time": 1689777838000,
        "value": 139.868,
        "_internal_originalTime": 1689777838000
    },
    {
        "time": 1689777839000,
        "value": 139.866,
        "_internal_originalTime": 1689777839000
    },
    {
        "time": 1689777840000,
        "value": 139.868,
        "_internal_originalTime": 1689777840000
    },
    {
        "time": 1689777842000,
        "value": 139.88,
        "_internal_originalTime": 1689777842000
    },
    {
        "time": 1689777843000,
        "value": 139.876,
        "_internal_originalTime": 1689777843000
    },
    {
        "time": 1689777844000,
        "value": 139.875,
        "_internal_originalTime": 1689777844000
    },
    {
        "time": 1689777846000,
        "value": 139.877,
        "_internal_originalTime": 1689777846000
    },
    {
        "time": 1689777848000,
        "value": 139.873,
        "_internal_originalTime": 1689777848000
    },
    {
        "time": 1689777850000,
        "value": 139.864,
        "_internal_originalTime": 1689777850000
    },
    {
        "time": 1689777851000,
        "value": 139.865,
        "_internal_originalTime": 1689777851000
    },
    {
        "time": 1689777852000,
        "value": 139.864,
        "_internal_originalTime": 1689777852000
    },
    {
        "time": 1689777853000,
        "value": 139.867,
        "_internal_originalTime": 1689777853000
    },
    {
        "time": 1689777855000,
        "value": 139.868,
        "_internal_originalTime": 1689777855000
    },
    {
        "time": 1689777856000,
        "value": 139.866,
        "_internal_originalTime": 1689777856000
    },
    {
        "time": 1689777857000,
        "value": 139.871,
        "_internal_originalTime": 1689777857000
    },
    {
        "time": 1689777859000,
        "value": 139.865,
        "_internal_originalTime": 1689777859000
    },
    {
        "time": 1689777860000,
        "value": 139.867,
        "_internal_originalTime": 1689777860000
    },
    {
        "time": 1689777861000,
        "value": 139.867,
        "_internal_originalTime": 1689777861000
    },
    {
        "time": 1689777863000,
        "value": 139.868,
        "_internal_originalTime": 1689777863000
    },
    {
        "time": 1689777865000,
        "value": 139.867,
        "_internal_originalTime": 1689777865000
    },
    {
        "time": 1689777866000,
        "value": 139.862,
        "_internal_originalTime": 1689777866000
    },
    {
        "time": 1689777867000,
        "value": 139.858,
        "_internal_originalTime": 1689777867000
    },
    {
        "time": 1689777868000,
        "value": 139.859,
        "_internal_originalTime": 1689777868000
    },
    {
        "time": 1689777870000,
        "value": 139.843,
        "_internal_originalTime": 1689777870000
    },
    {
        "time": 1689777872000,
        "value": 139.845,
        "_internal_originalTime": 1689777872000
    },
    {
        "time": 1689777873000,
        "value": 139.844,
        "_internal_originalTime": 1689777873000
    },
    {
        "time": 1689777874000,
        "value": 139.835,
        "_internal_originalTime": 1689777874000
    },
    {
        "time": 1689777875000,
        "value": 139.835,
        "_internal_originalTime": 1689777875000
    },
    {
        "time": 1689777876000,
        "value": 139.832,
        "_internal_originalTime": 1689777876000
    },
    {
        "time": 1689777877000,
        "value": 139.83,
        "_internal_originalTime": 1689777877000
    },
    {
        "time": 1689777878000,
        "value": 139.835,
        "_internal_originalTime": 1689777878000
    },
    {
        "time": 1689777879000,
        "value": 139.827,
        "_internal_originalTime": 1689777879000
    },
    {
        "time": 1689777881000,
        "value": 139.833,
        "_internal_originalTime": 1689777881000
    },
    {
        "time": 1689777882000,
        "value": 139.836,
        "_internal_originalTime": 1689777882000
    },
    {
        "time": 1689777884000,
        "value": 139.829,
        "_internal_originalTime": 1689777884000
    },
    {
        "time": 1689777885000,
        "value": 139.826,
        "_internal_originalTime": 1689777885000
    },
    {
        "time": 1689777886000,
        "value": 139.825,
        "_internal_originalTime": 1689777886000
    },
    {
        "time": 1689777887000,
        "value": 139.82,
        "_internal_originalTime": 1689777887000
    },
    {
        "time": 1689777889000,
        "value": 139.823,
        "_internal_originalTime": 1689777889000
    },
    {
        "time": 1689777890000,
        "value": 139.822,
        "_internal_originalTime": 1689777890000
    },
    {
        "time": 1689777891000,
        "value": 139.822,
        "_internal_originalTime": 1689777891000
    },
    {
        "time": 1689777893000,
        "value": 139.831,
        "_internal_originalTime": 1689777893000
    },
    {
        "time": 1689777894000,
        "value": 139.83,
        "_internal_originalTime": 1689777894000
    },
    {
        "time": 1689777895000,
        "value": 139.833,
        "_internal_originalTime": 1689777895000
    },
    {
        "time": 1689777896000,
        "value": 139.83,
        "_internal_originalTime": 1689777896000
    },
    {
        "time": 1689777898000,
        "value": 139.844,
        "_internal_originalTime": 1689777898000
    },
    {
        "time": 1689777899000,
        "value": 139.848,
        "_internal_originalTime": 1689777899000
    },
    {
        "time": 1689777900000,
        "value": 139.855,
        "_internal_originalTime": 1689777900000
    },
    {
        "time": 1689777901000,
        "value": 139.861,
        "_internal_originalTime": 1689777901000
    },
    {
        "time": 1689777902000,
        "value": 139.86,
        "_internal_originalTime": 1689777902000
    },
    {
        "time": 1689777903000,
        "value": 139.86,
        "_internal_originalTime": 1689777903000
    },
    {
        "time": 1689777905000,
        "value": 139.874,
        "_internal_originalTime": 1689777905000
    },
    {
        "time": 1689777906000,
        "value": 139.867,
        "_internal_originalTime": 1689777906000
    },
    {
        "time": 1689777907000,
        "value": 139.868,
        "_internal_originalTime": 1689777907000
    },
    {
        "time": 1689777908000,
        "value": 139.866,
        "_internal_originalTime": 1689777908000
    },
    {
        "time": 1689777910000,
        "value": 139.864,
        "_internal_originalTime": 1689777910000
    },
    {
        "time": 1689777912000,
        "value": 139.876,
        "_internal_originalTime": 1689777912000
    },
    {
        "time": 1689777913000,
        "value": 139.872,
        "_internal_originalTime": 1689777913000
    },
    {
        "time": 1689777914000,
        "value": 139.874,
        "_internal_originalTime": 1689777914000
    },
    {
        "time": 1689777915000,
        "value": 139.879,
        "_internal_originalTime": 1689777915000
    },
    {
        "time": 1689777916000,
        "value": 139.882,
        "_internal_originalTime": 1689777916000
    },
    {
        "time": 1689777917000,
        "value": 139.883,
        "_internal_originalTime": 1689777917000
    },
    {
        "time": 1689777918000,
        "value": 139.887,
        "_internal_originalTime": 1689777918000
    },
    {
        "time": 1689777919000,
        "value": 139.885,
        "_internal_originalTime": 1689777919000
    },
    {
        "time": 1689777921000,
        "value": 139.891,
        "_internal_originalTime": 1689777921000
    },
    {
        "time": 1689777923000,
        "value": 139.883,
        "_internal_originalTime": 1689777923000
    },
    {
        "time": 1689777924000,
        "value": 139.884,
        "_internal_originalTime": 1689777924000
    },
    {
        "time": 1689777926000,
        "value": 139.878,
        "_internal_originalTime": 1689777926000
    },
    {
        "time": 1689777927000,
        "value": 139.878,
        "_internal_originalTime": 1689777927000
    },
    {
        "time": 1689777929000,
        "value": 139.881,
        "_internal_originalTime": 1689777929000
    },
    {
        "time": 1689777930000,
        "value": 139.879,
        "_internal_originalTime": 1689777930000
    },
    {
        "time": 1689777931000,
        "value": 139.884,
        "_internal_originalTime": 1689777931000
    },
    {
        "time": 1689777932000,
        "value": 139.883,
        "_internal_originalTime": 1689777932000
    },
    {
        "time": 1689777933000,
        "value": 139.879,
        "_internal_originalTime": 1689777933000
    },
    {
        "time": 1689777934000,
        "value": 139.878,
        "_internal_originalTime": 1689777934000
    },
    {
        "time": 1689777935000,
        "value": 139.877,
        "_internal_originalTime": 1689777935000
    },
    {
        "time": 1689777936000,
        "value": 139.872,
        "_internal_originalTime": 1689777936000
    },
    {
        "time": 1689777938000,
        "value": 139.876,
        "_internal_originalTime": 1689777938000
    },
    {
        "time": 1689777940000,
        "value": 139.876,
        "_internal_originalTime": 1689777940000
    },
    {
        "time": 1689777941000,
        "value": 139.877,
        "_internal_originalTime": 1689777941000
    },
    {
        "time": 1689777942000,
        "value": 139.875,
        "_internal_originalTime": 1689777942000
    },
    {
        "time": 1689777943000,
        "value": 139.88,
        "_internal_originalTime": 1689777943000
    },
    {
        "time": 1689777944000,
        "value": 139.878,
        "_internal_originalTime": 1689777944000
    },
    {
        "time": 1689777945000,
        "value": 139.88,
        "_internal_originalTime": 1689777945000
    },
    {
        "time": 1689777946000,
        "value": 139.873,
        "_internal_originalTime": 1689777946000
    },
    {
        "time": 1689777947000,
        "value": 139.881,
        "_internal_originalTime": 1689777947000
    },
    {
        "time": 1689777948000,
        "value": 139.88,
        "_internal_originalTime": 1689777948000
    },
    {
        "time": 1689777949000,
        "value": 139.873,
        "_internal_originalTime": 1689777949000
    },
    {
        "time": 1689777950000,
        "value": 139.878,
        "_internal_originalTime": 1689777950000
    },
    {
        "time": 1689777951000,
        "value": 139.887,
        "_internal_originalTime": 1689777951000
    },
    {
        "time": 1689777952000,
        "value": 139.889,
        "_internal_originalTime": 1689777952000
    },
    {
        "time": 1689777953000,
        "value": 139.891,
        "_internal_originalTime": 1689777953000
    },
    {
        "time": 1689777954000,
        "value": 139.896,
        "_internal_originalTime": 1689777954000
    },
    {
        "time": 1689777955000,
        "value": 139.903,
        "_internal_originalTime": 1689777955000
    },
    {
        "time": 1689777956000,
        "value": 139.896,
        "_internal_originalTime": 1689777956000
    },
    {
        "time": 1689777957000,
        "value": 139.896,
        "_internal_originalTime": 1689777957000
    },
    {
        "time": 1689777959000,
        "value": 139.898,
        "_internal_originalTime": 1689777959000
    },
    {
        "time": 1689777960000,
        "value": 139.892,
        "_internal_originalTime": 1689777960000
    },
    {
        "time": 1689777961000,
        "value": 139.896,
        "_internal_originalTime": 1689777961000
    },
    {
        "time": 1689777962000,
        "value": 139.896,
        "_internal_originalTime": 1689777962000
    },
    {
        "time": 1689777963000,
        "value": 139.9,
        "_internal_originalTime": 1689777963000
    },
    {
        "time": 1689777964000,
        "value": 139.906,
        "_internal_originalTime": 1689777964000
    },
    {
        "time": 1689777965000,
        "value": 139.898,
        "_internal_originalTime": 1689777965000
    },
    {
        "time": 1689777966000,
        "value": 139.897,
        "_internal_originalTime": 1689777966000
    },
    {
        "time": 1689777968000,
        "value": 139.898,
        "_internal_originalTime": 1689777968000
    },
    {
        "time": 1689777969000,
        "value": 139.897,
        "_internal_originalTime": 1689777969000
    },
    {
        "time": 1689777970000,
        "value": 139.898,
        "_internal_originalTime": 1689777970000
    },
    {
        "time": 1689777971000,
        "value": 139.896,
        "_internal_originalTime": 1689777971000
    },
    {
        "time": 1689777972000,
        "value": 139.895,
        "_internal_originalTime": 1689777972000
    },
    {
        "time": 1689777973000,
        "value": 139.905,
        "_internal_originalTime": 1689777973000
    },
    {
        "time": 1689777974000,
        "value": 139.903,
        "_internal_originalTime": 1689777974000
    },
    {
        "time": 1689777975000,
        "value": 139.905,
        "_internal_originalTime": 1689777975000
    },
    {
        "time": 1689777976000,
        "value": 139.902,
        "_internal_originalTime": 1689777976000
    },
    {
        "time": 1689777977000,
        "value": 139.902,
        "_internal_originalTime": 1689777977000
    },
    {
        "time": 1689777978000,
        "value": 139.9,
        "_internal_originalTime": 1689777978000
    },
    {
        "time": 1689777979000,
        "value": 139.907,
        "_internal_originalTime": 1689777979000
    },
    {
        "time": 1689777980000,
        "value": 139.903,
        "_internal_originalTime": 1689777980000
    },
    {
        "time": 1689777981000,
        "value": 139.901,
        "_internal_originalTime": 1689777981000
    },
    {
        "time": 1689777982000,
        "value": 139.902,
        "_internal_originalTime": 1689777982000
    },
    {
        "time": 1689777983000,
        "value": 139.907,
        "_internal_originalTime": 1689777983000
    },
    {
        "time": 1689777984000,
        "value": 139.906,
        "_internal_originalTime": 1689777984000
    },
    {
        "time": 1689777985000,
        "value": 139.895,
        "_internal_originalTime": 1689777985000
    },
    {
        "time": 1689777987000,
        "value": 139.892,
        "_internal_originalTime": 1689777987000
    },
    {
        "time": 1689777988000,
        "value": 139.897,
        "_internal_originalTime": 1689777988000
    },
    {
        "time": 1689777989000,
        "value": 139.897,
        "_internal_originalTime": 1689777989000
    },
    {
        "time": 1689777990000,
        "value": 139.89,
        "_internal_originalTime": 1689777990000
    },
    {
        "time": 1689777991000,
        "value": 139.897,
        "_internal_originalTime": 1689777991000
    },
    {
        "time": 1689777992000,
        "value": 139.889,
        "_internal_originalTime": 1689777992000
    },
    {
        "time": 1689777993000,
        "value": 139.867,
        "_internal_originalTime": 1689777993000
    },
    {
        "time": 1689777994000,
        "value": 139.872,
        "_internal_originalTime": 1689777994000
    },
    {
        "time": 1689777995000,
        "value": 139.877,
        "_internal_originalTime": 1689777995000
    },
    {
        "time": 1689777996000,
        "value": 139.873,
        "_internal_originalTime": 1689777996000
    },
    {
        "time": 1689777998000,
        "value": 139.874,
        "_internal_originalTime": 1689777998000
    },
    {
        "time": 1689777999000,
        "value": 139.877,
        "_internal_originalTime": 1689777999000
    },
    {
        "time": 1689778000000,
        "value": 139.873,
        "_internal_originalTime": 1689778000000
    },
    {
        "time": 1689778002000,
        "value": 139.874,
        "_internal_originalTime": 1689778002000
    },
    {
        "time": 1689778003000,
        "value": 139.875,
        "_internal_originalTime": 1689778003000
    },
    {
        "time": 1689778004000,
        "value": 139.874,
        "_internal_originalTime": 1689778004000
    },
    {
        "time": 1689778005000,
        "value": 139.878,
        "_internal_originalTime": 1689778005000
    },
    {
        "time": 1689778006000,
        "value": 139.876,
        "_internal_originalTime": 1689778006000
    },
    {
        "time": 1689778009000,
        "value": 139.872,
        "_internal_originalTime": 1689778009000
    },
    {
        "time": 1689778010000,
        "value": 139.877,
        "_internal_originalTime": 1689778010000
    },
    {
        "time": 1689778012000,
        "value": 139.873,
        "_internal_originalTime": 1689778012000
    },
    {
        "time": 1689778014000,
        "value": 139.876,
        "_internal_originalTime": 1689778014000
    },
    {
        "time": 1689778017000,
        "value": 139.864,
        "_internal_originalTime": 1689778017000
    },
    {
        "time": 1689778018000,
        "value": 139.858,
        "_internal_originalTime": 1689778018000
    },
    {
        "time": 1689778019000,
        "value": 139.862,
        "_internal_originalTime": 1689778019000
    },
    {
        "time": 1689778020000,
        "value": 139.865,
        "_internal_originalTime": 1689778020000
    },
    {
        "time": 1689778021000,
        "value": 139.858,
        "_internal_originalTime": 1689778021000
    },
    {
        "time": 1689778022000,
        "value": 139.857,
        "_internal_originalTime": 1689778022000
    },
    {
        "time": 1689778023000,
        "value": 139.861,
        "_internal_originalTime": 1689778023000
    },
    {
        "time": 1689778024000,
        "value": 139.858,
        "_internal_originalTime": 1689778024000
    },
    {
        "time": 1689778025000,
        "value": 139.859,
        "_internal_originalTime": 1689778025000
    },
    {
        "time": 1689778026000,
        "value": 139.853,
        "_internal_originalTime": 1689778026000
    },
    {
        "time": 1689778028000,
        "value": 139.857,
        "_internal_originalTime": 1689778028000
    },
    {
        "time": 1689778029000,
        "value": 139.85,
        "_internal_originalTime": 1689778029000
    },
    {
        "time": 1689778030000,
        "value": 139.854,
        "_internal_originalTime": 1689778030000
    },
    {
        "time": 1689778031000,
        "value": 139.858,
        "_internal_originalTime": 1689778031000
    },
    {
        "time": 1689778032000,
        "value": 139.853,
        "_internal_originalTime": 1689778032000
    },
    {
        "time": 1689778033000,
        "value": 139.858,
        "_internal_originalTime": 1689778033000
    },
    {
        "time": 1689778034000,
        "value": 139.859,
        "_internal_originalTime": 1689778034000
    },
    {
        "time": 1689778035000,
        "value": 139.854,
        "_internal_originalTime": 1689778035000
    },
    {
        "time": 1689778036000,
        "value": 139.851,
        "_internal_originalTime": 1689778036000
    },
    {
        "time": 1689778037000,
        "value": 139.851,
        "_internal_originalTime": 1689778037000
    },
    {
        "time": 1689778038000,
        "value": 139.85,
        "_internal_originalTime": 1689778038000
    },
    {
        "time": 1689778039000,
        "value": 139.852,
        "_internal_originalTime": 1689778039000
    },
    {
        "time": 1689778040000,
        "value": 139.849,
        "_internal_originalTime": 1689778040000
    },
    {
        "time": 1689778041000,
        "value": 139.851,
        "_internal_originalTime": 1689778041000
    },
    {
        "time": 1689778043000,
        "value": 139.847,
        "_internal_originalTime": 1689778043000
    },
    {
        "time": 1689778044000,
        "value": 139.843,
        "_internal_originalTime": 1689778044000
    },
    {
        "time": 1689778045000,
        "value": 139.847,
        "_internal_originalTime": 1689778045000
    },
    {
        "time": 1689778046000,
        "value": 139.845,
        "_internal_originalTime": 1689778046000
    },
    {
        "time": 1689778047000,
        "value": 139.849,
        "_internal_originalTime": 1689778047000
    },
    {
        "time": 1689778048000,
        "value": 139.849,
        "_internal_originalTime": 1689778048000
    },
    {
        "time": 1689778049000,
        "value": 139.848,
        "_internal_originalTime": 1689778049000
    },
    {
        "time": 1689778050000,
        "value": 139.847,
        "_internal_originalTime": 1689778050000
    },
    {
        "time": 1689778051000,
        "value": 139.851,
        "_internal_originalTime": 1689778051000
    },
    {
        "time": 1689778052000,
        "value": 139.855,
        "_internal_originalTime": 1689778052000
    },
    {
        "time": 1689778053000,
        "value": 139.851,
        "_internal_originalTime": 1689778053000
    },
    {
        "time": 1689778054000,
        "value": 139.86,
        "_internal_originalTime": 1689778054000
    },
    {
        "time": 1689778055000,
        "value": 139.859,
        "_internal_originalTime": 1689778055000
    },
    {
        "time": 1689778056000,
        "value": 139.865,
        "_internal_originalTime": 1689778056000
    },
    {
        "time": 1689778057000,
        "value": 139.861,
        "_internal_originalTime": 1689778057000
    },
    {
        "time": 1689778058000,
        "value": 139.862,
        "_internal_originalTime": 1689778058000
    },
    {
        "time": 1689778059000,
        "value": 139.867,
        "_internal_originalTime": 1689778059000
    },
    {
        "time": 1689778060000,
        "value": 139.868,
        "_internal_originalTime": 1689778060000
    },
    {
        "time": 1689778062000,
        "value": 139.871,
        "_internal_originalTime": 1689778062000
    },
    {
        "time": 1689778063000,
        "value": 139.868,
        "_internal_originalTime": 1689778063000
    },
    {
        "time": 1689778064000,
        "value": 139.876,
        "_internal_originalTime": 1689778064000
    },
    {
        "time": 1689778065000,
        "value": 139.878,
        "_internal_originalTime": 1689778065000
    },
    {
        "time": 1689778066000,
        "value": 139.883,
        "_internal_originalTime": 1689778066000
    },
    {
        "time": 1689778067000,
        "value": 139.883,
        "_internal_originalTime": 1689778067000
    },
    {
        "time": 1689778068000,
        "value": 139.887,
        "_internal_originalTime": 1689778068000
    },
    {
        "time": 1689778069000,
        "value": 139.882,
        "_internal_originalTime": 1689778069000
    },
    {
        "time": 1689778070000,
        "value": 139.879,
        "_internal_originalTime": 1689778070000
    },
    {
        "time": 1689778071000,
        "value": 139.879,
        "_internal_originalTime": 1689778071000
    },
    {
        "time": 1689778072000,
        "value": 139.879,
        "_internal_originalTime": 1689778072000
    },
    {
        "time": 1689778073000,
        "value": 139.876,
        "_internal_originalTime": 1689778073000
    },
    {
        "time": 1689778075000,
        "value": 139.873,
        "_internal_originalTime": 1689778075000
    },
    {
        "time": 1689778076000,
        "value": 139.869,
        "_internal_originalTime": 1689778076000
    },
    {
        "time": 1689778077000,
        "value": 139.87,
        "_internal_originalTime": 1689778077000
    },
    {
        "time": 1689778078000,
        "value": 139.882,
        "_internal_originalTime": 1689778078000
    },
    {
        "time": 1689778080000,
        "value": 139.882,
        "_internal_originalTime": 1689778080000
    },
    {
        "time": 1689778081000,
        "value": 139.883,
        "_internal_originalTime": 1689778081000
    },
    {
        "time": 1689778082000,
        "value": 139.884,
        "_internal_originalTime": 1689778082000
    },
    {
        "time": 1689778084000,
        "value": 139.883,
        "_internal_originalTime": 1689778084000
    },
    {
        "time": 1689778085000,
        "value": 139.865,
        "_internal_originalTime": 1689778085000
    },
    {
        "time": 1689778086000,
        "value": 139.867,
        "_internal_originalTime": 1689778086000
    },
    {
        "time": 1689778087000,
        "value": 139.866,
        "_internal_originalTime": 1689778087000
    },
    {
        "time": 1689778088000,
        "value": 139.879,
        "_internal_originalTime": 1689778088000
    },
    {
        "time": 1689778089000,
        "value": 139.877,
        "_internal_originalTime": 1689778089000
    },
    {
        "time": 1689778090000,
        "value": 139.878,
        "_internal_originalTime": 1689778090000
    },
    {
        "time": 1689778091000,
        "value": 139.881,
        "_internal_originalTime": 1689778091000
    },
    {
        "time": 1689778092000,
        "value": 139.877,
        "_internal_originalTime": 1689778092000
    },
    {
        "time": 1689778093000,
        "value": 139.866,
        "_internal_originalTime": 1689778093000
    },
    {
        "time": 1689778094000,
        "value": 139.87,
        "_internal_originalTime": 1689778094000
    },
    {
        "time": 1689778095000,
        "value": 139.867,
        "_internal_originalTime": 1689778095000
    },
    {
        "time": 1689778097000,
        "value": 139.867,
        "_internal_originalTime": 1689778097000
    },
    {
        "time": 1689778098000,
        "value": 139.866,
        "_internal_originalTime": 1689778098000
    },
    {
        "time": 1689778099000,
        "value": 139.871,
        "_internal_originalTime": 1689778099000
    },
    {
        "time": 1689778100000,
        "value": 139.869,
        "_internal_originalTime": 1689778100000
    },
    {
        "time": 1689778101000,
        "value": 139.868,
        "_internal_originalTime": 1689778101000
    },
    {
        "time": 1689778103000,
        "value": 139.868,
        "_internal_originalTime": 1689778103000
    },
    {
        "time": 1689778104000,
        "value": 139.865,
        "_internal_originalTime": 1689778104000
    },
    {
        "time": 1689778105000,
        "value": 139.864,
        "_internal_originalTime": 1689778105000
    },
    {
        "time": 1689778106000,
        "value": 139.877,
        "_internal_originalTime": 1689778106000
    },
    {
        "time": 1689778107000,
        "value": 139.878,
        "_internal_originalTime": 1689778107000
    },
    {
        "time": 1689778108000,
        "value": 139.875,
        "_internal_originalTime": 1689778108000
    },
    {
        "time": 1689778110000,
        "value": 139.876,
        "_internal_originalTime": 1689778110000
    },
    {
        "time": 1689778112000,
        "value": 139.887,
        "_internal_originalTime": 1689778112000
    },
    {
        "time": 1689778115000,
        "value": 139.886,
        "_internal_originalTime": 1689778115000
    },
    {
        "time": 1689778116000,
        "value": 139.889,
        "_internal_originalTime": 1689778116000
    },
    {
        "time": 1689778117000,
        "value": 139.893,
        "_internal_originalTime": 1689778117000
    },
    {
        "time": 1689778118000,
        "value": 139.891,
        "_internal_originalTime": 1689778118000
    },
    {
        "time": 1689778120000,
        "value": 139.889,
        "_internal_originalTime": 1689778120000
    },
    {
        "time": 1689778131000,
        "value": 139.879,
        "_internal_originalTime": 1689778131000
    },
    {
        "time": 1689778132000,
        "value": 139.879,
        "_internal_originalTime": 1689778132000
    },
    {
        "time": 1689778133000,
        "value": 139.879,
        "_internal_originalTime": 1689778133000
    },
    {
        "time": 1689778134000,
        "value": 139.879,
        "_internal_originalTime": 1689778134000
    },
    {
        "time": 1689778135000,
        "value": 139.878,
        "_internal_originalTime": 1689778135000
    },
    {
        "time": 1689778136000,
        "value": 139.879,
        "_internal_originalTime": 1689778136000
    },
    {
        "time": 1689778138000,
        "value": 139.872,
        "_internal_originalTime": 1689778138000
    },
    {
        "time": 1689778140000,
        "value": 139.874,
        "_internal_originalTime": 1689778140000
    },
    {
        "time": 1689778141000,
        "value": 139.877,
        "_internal_originalTime": 1689778141000
    },
    {
        "time": 1689778142000,
        "value": 139.876,
        "_internal_originalTime": 1689778142000
    },
    {
        "time": 1689778144000,
        "value": 139.882,
        "_internal_originalTime": 1689778144000
    },
    {
        "time": 1689778145000,
        "value": 139.883,
        "_internal_originalTime": 1689778145000
    },
    {
        "time": 1689778146000,
        "value": 139.884,
        "_internal_originalTime": 1689778146000
    },
    {
        "time": 1689778148000,
        "value": 139.885,
        "_internal_originalTime": 1689778148000
    },
    {
        "time": 1689778149000,
        "value": 139.881,
        "_internal_originalTime": 1689778149000
    },
    {
        "time": 1689778150000,
        "value": 139.884,
        "_internal_originalTime": 1689778150000
    },
    {
        "time": 1689778151000,
        "value": 139.888,
        "_internal_originalTime": 1689778151000
    },
    {
        "time": 1689778152000,
        "value": 139.876,
        "_internal_originalTime": 1689778152000
    },
    {
        "time": 1689778153000,
        "value": 139.878,
        "_internal_originalTime": 1689778153000
    },
    {
        "time": 1689778154000,
        "value": 139.879,
        "_internal_originalTime": 1689778154000
    },
    {
        "time": 1689778155000,
        "value": 139.877,
        "_internal_originalTime": 1689778155000
    },
    {
        "time": 1689778157000,
        "value": 139.878,
        "_internal_originalTime": 1689778157000
    },
    {
        "time": 1689778158000,
        "value": 139.881,
        "_internal_originalTime": 1689778158000
    },
    {
        "time": 1689778160000,
        "value": 139.884,
        "_internal_originalTime": 1689778160000
    },
    {
        "time": 1689778161000,
        "value": 139.888,
        "_internal_originalTime": 1689778161000
    },
    {
        "time": 1689778162000,
        "value": 139.894,
        "_internal_originalTime": 1689778162000
    },
    {
        "time": 1689778163000,
        "value": 139.897,
        "_internal_originalTime": 1689778163000
    },
    {
        "time": 1689778164000,
        "value": 139.89,
        "_internal_originalTime": 1689778164000
    },
    {
        "time": 1689778166000,
        "value": 139.892,
        "_internal_originalTime": 1689778166000
    },
    {
        "time": 1689778168000,
        "value": 139.899,
        "_internal_originalTime": 1689778168000
    },
    {
        "time": 1689778170000,
        "value": 139.898,
        "_internal_originalTime": 1689778170000
    },
    {
        "time": 1689778171000,
        "value": 139.904,
        "_internal_originalTime": 1689778171000
    },
    {
        "time": 1689778172000,
        "value": 139.9,
        "_internal_originalTime": 1689778172000
    },
    {
        "time": 1689778173000,
        "value": 139.901,
        "_internal_originalTime": 1689778173000
    },
    {
        "time": 1689778175000,
        "value": 139.896,
        "_internal_originalTime": 1689778175000
    },
    {
        "time": 1689778176000,
        "value": 139.9,
        "_internal_originalTime": 1689778176000
    },
    {
        "time": 1689778178000,
        "value": 139.901,
        "_internal_originalTime": 1689778178000
    },
    {
        "time": 1689778179000,
        "value": 139.904,
        "_internal_originalTime": 1689778179000
    },
    {
        "time": 1689778181000,
        "value": 139.905,
        "_internal_originalTime": 1689778181000
    },
    {
        "time": 1689778182000,
        "value": 139.901,
        "_internal_originalTime": 1689778182000
    },
    {
        "time": 1689778183000,
        "value": 139.903,
        "_internal_originalTime": 1689778183000
    },
    {
        "time": 1689778184000,
        "value": 139.898,
        "_internal_originalTime": 1689778184000
    },
    {
        "time": 1689778186000,
        "value": 139.898,
        "_internal_originalTime": 1689778186000
    },
    {
        "time": 1689778188000,
        "value": 139.899,
        "_internal_originalTime": 1689778188000
    },
    {
        "time": 1689778189000,
        "value": 139.893,
        "_internal_originalTime": 1689778189000
    },
    {
        "time": 1689778190000,
        "value": 139.892,
        "_internal_originalTime": 1689778190000
    },
    {
        "time": 1689778192000,
        "value": 139.895,
        "_internal_originalTime": 1689778192000
    },
    {
        "time": 1689778193000,
        "value": 139.893,
        "_internal_originalTime": 1689778193000
    },
    {
        "time": 1689778195000,
        "value": 139.878,
        "_internal_originalTime": 1689778195000
    },
    {
        "time": 1689778197000,
        "value": 139.876,
        "_internal_originalTime": 1689778197000
    },
    {
        "time": 1689778199000,
        "value": 139.88,
        "_internal_originalTime": 1689778199000
    },
    {
        "time": 1689778200000,
        "value": 139.876,
        "_internal_originalTime": 1689778200000
    },
    {
        "time": 1689778201000,
        "value": 139.879,
        "_internal_originalTime": 1689778201000
    },
    {
        "time": 1689778203000,
        "value": 139.872,
        "_internal_originalTime": 1689778203000
    },
    {
        "time": 1689778204000,
        "value": 139.876,
        "_internal_originalTime": 1689778204000
    },
    {
        "time": 1689778205000,
        "value": 139.885,
        "_internal_originalTime": 1689778205000
    },
    {
        "time": 1689778207000,
        "value": 139.891,
        "_internal_originalTime": 1689778207000
    },
    {
        "time": 1689778208000,
        "value": 139.892,
        "_internal_originalTime": 1689778208000
    },
    {
        "time": 1689778210000,
        "value": 139.898,
        "_internal_originalTime": 1689778210000
    },
    {
        "time": 1689778211000,
        "value": 139.902,
        "_internal_originalTime": 1689778211000
    },
    {
        "time": 1689778212000,
        "value": 139.893,
        "_internal_originalTime": 1689778212000
    },
    {
        "time": 1689778214000,
        "value": 139.892,
        "_internal_originalTime": 1689778214000
    },
    {
        "time": 1689778215000,
        "value": 139.888,
        "_internal_originalTime": 1689778215000
    },
    {
        "time": 1689778218000,
        "value": 139.881,
        "_internal_originalTime": 1689778218000
    },
    {
        "time": 1689778219000,
        "value": 139.88,
        "_internal_originalTime": 1689778219000
    },
    {
        "time": 1689778220000,
        "value": 139.881,
        "_internal_originalTime": 1689778220000
    },
    {
        "time": 1689778221000,
        "value": 139.875,
        "_internal_originalTime": 1689778221000
    },
    {
        "time": 1689778223000,
        "value": 139.873,
        "_internal_originalTime": 1689778223000
    },
    {
        "time": 1689778224000,
        "value": 139.872,
        "_internal_originalTime": 1689778224000
    },
    {
        "time": 1689778226000,
        "value": 139.872,
        "_internal_originalTime": 1689778226000
    },
    {
        "time": 1689778227000,
        "value": 139.866,
        "_internal_originalTime": 1689778227000
    },
    {
        "time": 1689778230000,
        "value": 139.868,
        "_internal_originalTime": 1689778230000
    },
    {
        "time": 1689778231000,
        "value": 139.865,
        "_internal_originalTime": 1689778231000
    },
    {
        "time": 1689778232000,
        "value": 139.869,
        "_internal_originalTime": 1689778232000
    },
    {
        "time": 1689778233000,
        "value": 139.869,
        "_internal_originalTime": 1689778233000
    },
    {
        "time": 1689778234000,
        "value": 139.869,
        "_internal_originalTime": 1689778234000
    },
    {
        "time": 1689778236000,
        "value": 139.867,
        "_internal_originalTime": 1689778236000
    },
    {
        "time": 1689778237000,
        "value": 139.866,
        "_internal_originalTime": 1689778237000
    },
    {
        "time": 1689778238000,
        "value": 139.868,
        "_internal_originalTime": 1689778238000
    },
    {
        "time": 1689778240000,
        "value": 139.868,
        "_internal_originalTime": 1689778240000
    },
    {
        "time": 1689778241000,
        "value": 139.863,
        "_internal_originalTime": 1689778241000
    },
    {
        "time": 1689778243000,
        "value": 139.867,
        "_internal_originalTime": 1689778243000
    },
    {
        "time": 1689778244000,
        "value": 139.861,
        "_internal_originalTime": 1689778244000
    },
    {
        "time": 1689778247000,
        "value": 139.863,
        "_internal_originalTime": 1689778247000
    },
    {
        "time": 1689778248000,
        "value": 139.858,
        "_internal_originalTime": 1689778248000
    },
    {
        "time": 1689778249000,
        "value": 139.866,
        "_internal_originalTime": 1689778249000
    },
    {
        "time": 1689778250000,
        "value": 139.861,
        "_internal_originalTime": 1689778250000
    },
    {
        "time": 1689778252000,
        "value": 139.867,
        "_internal_originalTime": 1689778252000
    },
    {
        "time": 1689778253000,
        "value": 139.866,
        "_internal_originalTime": 1689778253000
    },
    {
        "time": 1689778254000,
        "value": 139.87,
        "_internal_originalTime": 1689778254000
    },
    {
        "time": 1689778255000,
        "value": 139.869,
        "_internal_originalTime": 1689778255000
    },
    {
        "time": 1689778257000,
        "value": 139.867,
        "_internal_originalTime": 1689778257000
    },
    {
        "time": 1689778258000,
        "value": 139.857,
        "_internal_originalTime": 1689778258000
    },
    {
        "time": 1689778259000,
        "value": 139.859,
        "_internal_originalTime": 1689778259000
    },
    {
        "time": 1689778261000,
        "value": 139.854,
        "_internal_originalTime": 1689778261000
    },
    {
        "time": 1689778262000,
        "value": 139.86,
        "_internal_originalTime": 1689778262000
    },
    {
        "time": 1689778263000,
        "value": 139.86,
        "_internal_originalTime": 1689778263000
    },
    {
        "time": 1689778265000,
        "value": 139.862,
        "_internal_originalTime": 1689778265000
    },
    {
        "time": 1689778267000,
        "value": 139.867,
        "_internal_originalTime": 1689778267000
    },
    {
        "time": 1689778268000,
        "value": 139.866,
        "_internal_originalTime": 1689778268000
    },
    {
        "time": 1689778269000,
        "value": 139.869,
        "_internal_originalTime": 1689778269000
    },
    {
        "time": 1689778271000,
        "value": 139.869,
        "_internal_originalTime": 1689778271000
    },
    {
        "time": 1689778273000,
        "value": 139.872,
        "_internal_originalTime": 1689778273000
    },
    {
        "time": 1689778274000,
        "value": 139.858,
        "_internal_originalTime": 1689778274000
    },
    {
        "time": 1689778275000,
        "value": 139.866,
        "_internal_originalTime": 1689778275000
    },
    {
        "time": 1689778276000,
        "value": 139.867,
        "_internal_originalTime": 1689778276000
    },
    {
        "time": 1689778277000,
        "value": 139.864,
        "_internal_originalTime": 1689778277000
    },
    {
        "time": 1689778278000,
        "value": 139.869,
        "_internal_originalTime": 1689778278000
    },
    {
        "time": 1689778280000,
        "value": 139.854,
        "_internal_originalTime": 1689778280000
    },
    {
        "time": 1689778281000,
        "value": 139.853,
        "_internal_originalTime": 1689778281000
    },
    {
        "time": 1689778282000,
        "value": 139.857,
        "_internal_originalTime": 1689778282000
    },
    {
        "time": 1689778283000,
        "value": 139.847,
        "_internal_originalTime": 1689778283000
    },
    {
        "time": 1689778284000,
        "value": 139.849,
        "_internal_originalTime": 1689778284000
    },
    {
        "time": 1689778285000,
        "value": 139.846,
        "_internal_originalTime": 1689778285000
    },
    {
        "time": 1689778287000,
        "value": 139.845,
        "_internal_originalTime": 1689778287000
    },
    {
        "time": 1689778289000,
        "value": 139.852,
        "_internal_originalTime": 1689778289000
    },
    {
        "time": 1689778290000,
        "value": 139.848,
        "_internal_originalTime": 1689778290000
    },
    {
        "time": 1689778292000,
        "value": 139.847,
        "_internal_originalTime": 1689778292000
    },
    {
        "time": 1689778293000,
        "value": 139.844,
        "_internal_originalTime": 1689778293000
    },
    {
        "time": 1689778294000,
        "value": 139.84,
        "_internal_originalTime": 1689778294000
    },
    {
        "time": 1689778295000,
        "value": 139.843,
        "_internal_originalTime": 1689778295000
    },
    {
        "time": 1689778296000,
        "value": 139.838,
        "_internal_originalTime": 1689778296000
    },
    {
        "time": 1689778298000,
        "value": 139.83,
        "_internal_originalTime": 1689778298000
    },
    {
        "time": 1689778299000,
        "value": 139.836,
        "_internal_originalTime": 1689778299000
    },
    {
        "time": 1689778300000,
        "value": 139.829,
        "_internal_originalTime": 1689778300000
    },
    {
        "time": 1689778302000,
        "value": 139.828,
        "_internal_originalTime": 1689778302000
    },
    {
        "time": 1689778303000,
        "value": 139.823,
        "_internal_originalTime": 1689778303000
    },
    {
        "time": 1689778305000,
        "value": 139.822,
        "_internal_originalTime": 1689778305000
    },
    {
        "time": 1689778307000,
        "value": 139.807,
        "_internal_originalTime": 1689778307000
    },
    {
        "time": 1689778308000,
        "value": 139.805,
        "_internal_originalTime": 1689778308000
    },
    {
        "time": 1689778309000,
        "value": 139.81,
        "_internal_originalTime": 1689778309000
    },
    {
        "time": 1689778311000,
        "value": 139.805,
        "_internal_originalTime": 1689778311000
    },
    {
        "time": 1689778313000,
        "value": 139.812,
        "_internal_originalTime": 1689778313000
    },
    {
        "time": 1689778314000,
        "value": 139.814,
        "_internal_originalTime": 1689778314000
    },
    {
        "time": 1689778315000,
        "value": 139.808,
        "_internal_originalTime": 1689778315000
    },
    {
        "time": 1689778316000,
        "value": 139.806,
        "_internal_originalTime": 1689778316000
    },
    {
        "time": 1689778317000,
        "value": 139.795,
        "_internal_originalTime": 1689778317000
    },
    {
        "time": 1689778319000,
        "value": 139.793,
        "_internal_originalTime": 1689778319000
    },
    {
        "time": 1689778320000,
        "value": 139.781,
        "_internal_originalTime": 1689778320000
    },
    {
        "time": 1689778321000,
        "value": 139.784,
        "_internal_originalTime": 1689778321000
    },
    {
        "time": 1689778322000,
        "value": 139.783,
        "_internal_originalTime": 1689778322000
    },
    {
        "time": 1689778324000,
        "value": 139.784,
        "_internal_originalTime": 1689778324000
    },
    {
        "time": 1689778325000,
        "value": 139.788,
        "_internal_originalTime": 1689778325000
    },
    {
        "time": 1689778326000,
        "value": 139.796,
        "_internal_originalTime": 1689778326000
    },
    {
        "time": 1689778327000,
        "value": 139.798,
        "_internal_originalTime": 1689778327000
    },
    {
        "time": 1689778328000,
        "value": 139.804,
        "_internal_originalTime": 1689778328000
    },
    {
        "time": 1689778329000,
        "value": 139.8,
        "_internal_originalTime": 1689778329000
    },
    {
        "time": 1689778330000,
        "value": 139.796,
        "_internal_originalTime": 1689778330000
    },
    {
        "time": 1689778331000,
        "value": 139.802,
        "_internal_originalTime": 1689778331000
    },
    {
        "time": 1689778332000,
        "value": 139.795,
        "_internal_originalTime": 1689778332000
    },
    {
        "time": 1689778333000,
        "value": 139.799,
        "_internal_originalTime": 1689778333000
    },
    {
        "time": 1689778335000,
        "value": 139.819,
        "_internal_originalTime": 1689778335000
    },
    {
        "time": 1689778336000,
        "value": 139.826,
        "_internal_originalTime": 1689778336000
    },
    {
        "time": 1689778337000,
        "value": 139.83,
        "_internal_originalTime": 1689778337000
    },
    {
        "time": 1689778338000,
        "value": 139.83,
        "_internal_originalTime": 1689778338000
    },
    {
        "time": 1689778340000,
        "value": 139.824,
        "_internal_originalTime": 1689778340000
    },
    {
        "time": 1689778342000,
        "value": 139.818,
        "_internal_originalTime": 1689778342000
    },
    {
        "time": 1689778343000,
        "value": 139.817,
        "_internal_originalTime": 1689778343000
    },
    {
        "time": 1689778344000,
        "value": 139.808,
        "_internal_originalTime": 1689778344000
    },
    {
        "time": 1689778345000,
        "value": 139.809,
        "_internal_originalTime": 1689778345000
    },
    {
        "time": 1689778346000,
        "value": 139.811,
        "_internal_originalTime": 1689778346000
    },
    {
        "time": 1689778347000,
        "value": 139.796,
        "_internal_originalTime": 1689778347000
    },
    {
        "time": 1689778348000,
        "value": 139.797,
        "_internal_originalTime": 1689778348000
    },
    {
        "time": 1689778349000,
        "value": 139.797,
        "_internal_originalTime": 1689778349000
    },
    {
        "time": 1689778350000,
        "value": 139.803,
        "_internal_originalTime": 1689778350000
    },
    {
        "time": 1689778351000,
        "value": 139.809,
        "_internal_originalTime": 1689778351000
    },
    {
        "time": 1689778352000,
        "value": 139.805,
        "_internal_originalTime": 1689778352000
    },
    {
        "time": 1689778353000,
        "value": 139.808,
        "_internal_originalTime": 1689778353000
    },
    {
        "time": 1689778354000,
        "value": 139.808,
        "_internal_originalTime": 1689778354000
    },
    {
        "time": 1689778355000,
        "value": 139.8,
        "_internal_originalTime": 1689778355000
    },
    {
        "time": 1689778356000,
        "value": 139.804,
        "_internal_originalTime": 1689778356000
    },
    {
        "time": 1689778357000,
        "value": 139.804,
        "_internal_originalTime": 1689778357000
    },
    {
        "time": 1689778358000,
        "value": 139.803,
        "_internal_originalTime": 1689778358000
    },
    {
        "time": 1689778360000,
        "value": 139.799,
        "_internal_originalTime": 1689778360000
    },
    {
        "time": 1689778361000,
        "value": 139.804,
        "_internal_originalTime": 1689778361000
    },
    {
        "time": 1689778362000,
        "value": 139.8,
        "_internal_originalTime": 1689778362000
    },
    {
        "time": 1689778364000,
        "value": 139.799,
        "_internal_originalTime": 1689778364000
    },
    {
        "time": 1689778365000,
        "value": 139.796,
        "_internal_originalTime": 1689778365000
    },
    {
        "time": 1689778366000,
        "value": 139.79,
        "_internal_originalTime": 1689778366000
    },
    {
        "time": 1689778367000,
        "value": 139.787,
        "_internal_originalTime": 1689778367000
    },
    {
        "time": 1689778368000,
        "value": 139.789,
        "_internal_originalTime": 1689778368000
    },
    {
        "time": 1689778370000,
        "value": 139.793,
        "_internal_originalTime": 1689778370000
    },
    {
        "time": 1689778371000,
        "value": 139.795,
        "_internal_originalTime": 1689778371000
    },
    {
        "time": 1689778372000,
        "value": 139.793,
        "_internal_originalTime": 1689778372000
    },
    {
        "time": 1689778373000,
        "value": 139.784,
        "_internal_originalTime": 1689778373000
    },
    {
        "time": 1689778374000,
        "value": 139.785,
        "_internal_originalTime": 1689778374000
    },
    {
        "time": 1689778375000,
        "value": 139.789,
        "_internal_originalTime": 1689778375000
    },
    {
        "time": 1689778376000,
        "value": 139.783,
        "_internal_originalTime": 1689778376000
    },
    {
        "time": 1689778378000,
        "value": 139.769,
        "_internal_originalTime": 1689778378000
    },
    {
        "time": 1689778379000,
        "value": 139.772,
        "_internal_originalTime": 1689778379000
    },
    {
        "time": 1689778380000,
        "value": 139.763,
        "_internal_originalTime": 1689778380000
    },
    {
        "time": 1689778381000,
        "value": 139.769,
        "_internal_originalTime": 1689778381000
    },
    {
        "time": 1689778382000,
        "value": 139.769,
        "_internal_originalTime": 1689778382000
    },
    {
        "time": 1689778384000,
        "value": 139.768,
        "_internal_originalTime": 1689778384000
    },
    {
        "time": 1689778385000,
        "value": 139.764,
        "_internal_originalTime": 1689778385000
    },
    {
        "time": 1689778388000,
        "value": 139.764,
        "_internal_originalTime": 1689778388000
    },
    {
        "time": 1689778389000,
        "value": 139.768,
        "_internal_originalTime": 1689778389000
    },
    {
        "time": 1689778390000,
        "value": 139.768,
        "_internal_originalTime": 1689778390000
    },
    {
        "time": 1689778391000,
        "value": 139.774,
        "_internal_originalTime": 1689778391000
    },
    {
        "time": 1689778393000,
        "value": 139.774,
        "_internal_originalTime": 1689778393000
    },
    {
        "time": 1689778394000,
        "value": 139.771,
        "_internal_originalTime": 1689778394000
    },
    {
        "time": 1689778395000,
        "value": 139.767,
        "_internal_originalTime": 1689778395000
    },
    {
        "time": 1689778396000,
        "value": 139.768,
        "_internal_originalTime": 1689778396000
    },
    {
        "time": 1689778397000,
        "value": 139.775,
        "_internal_originalTime": 1689778397000
    },
    {
        "time": 1689778398000,
        "value": 139.774,
        "_internal_originalTime": 1689778398000
    },
    {
        "time": 1689778399000,
        "value": 139.768,
        "_internal_originalTime": 1689778399000
    },
    {
        "time": 1689778401000,
        "value": 139.756,
        "_internal_originalTime": 1689778401000
    },
    {
        "time": 1689778402000,
        "value": 139.76,
        "_internal_originalTime": 1689778402000
    },
    {
        "time": 1689778403000,
        "value": 139.76,
        "_internal_originalTime": 1689778403000
    },
    {
        "time": 1689778404000,
        "value": 139.76,
        "_internal_originalTime": 1689778404000
    },
    {
        "time": 1689778405000,
        "value": 139.761,
        "_internal_originalTime": 1689778405000
    },
    {
        "time": 1689778406000,
        "value": 139.756,
        "_internal_originalTime": 1689778406000
    },
    {
        "time": 1689778407000,
        "value": 139.754,
        "_internal_originalTime": 1689778407000
    },
    {
        "time": 1689778409000,
        "value": 139.755,
        "_internal_originalTime": 1689778409000
    },
    {
        "time": 1689778410000,
        "value": 139.759,
        "_internal_originalTime": 1689778410000
    },
    {
        "time": 1689778411000,
        "value": 139.758,
        "_internal_originalTime": 1689778411000
    },
    {
        "time": 1689778412000,
        "value": 139.764,
        "_internal_originalTime": 1689778412000
    },
    {
        "time": 1689778414000,
        "value": 139.755,
        "_internal_originalTime": 1689778414000
    },
    {
        "time": 1689778415000,
        "value": 139.747,
        "_internal_originalTime": 1689778415000
    },
    {
        "time": 1689778416000,
        "value": 139.75,
        "_internal_originalTime": 1689778416000
    },
    {
        "time": 1689778417000,
        "value": 139.749,
        "_internal_originalTime": 1689778417000
    },
    {
        "time": 1689778418000,
        "value": 139.75,
        "_internal_originalTime": 1689778418000
    },
    {
        "time": 1689778419000,
        "value": 139.748,
        "_internal_originalTime": 1689778419000
    },
    {
        "time": 1689778420000,
        "value": 139.745,
        "_internal_originalTime": 1689778420000
    },
    {
        "time": 1689778421000,
        "value": 139.738,
        "_internal_originalTime": 1689778421000
    },
    {
        "time": 1689778423000,
        "value": 139.753,
        "_internal_originalTime": 1689778423000
    },
    {
        "time": 1689778424000,
        "value": 139.753,
        "_internal_originalTime": 1689778424000
    },
    {
        "time": 1689778425000,
        "value": 139.748,
        "_internal_originalTime": 1689778425000
    },
    {
        "time": 1689778426000,
        "value": 139.748,
        "_internal_originalTime": 1689778426000
    },
    {
        "time": 1689778429000,
        "value": 139.746,
        "_internal_originalTime": 1689778429000
    },
    {
        "time": 1689778430000,
        "value": 139.743,
        "_internal_originalTime": 1689778430000
    },
    {
        "time": 1689778431000,
        "value": 139.743,
        "_internal_originalTime": 1689778431000
    },
    {
        "time": 1689778432000,
        "value": 139.734,
        "_internal_originalTime": 1689778432000
    },
    {
        "time": 1689778433000,
        "value": 139.736,
        "_internal_originalTime": 1689778433000
    },
    {
        "time": 1689778434000,
        "value": 139.736,
        "_internal_originalTime": 1689778434000
    },
    {
        "time": 1689778435000,
        "value": 139.735,
        "_internal_originalTime": 1689778435000
    },
    {
        "time": 1689778436000,
        "value": 139.732,
        "_internal_originalTime": 1689778436000
    },
    {
        "time": 1689778437000,
        "value": 139.724,
        "_internal_originalTime": 1689778437000
    },
    {
        "time": 1689778439000,
        "value": 139.734,
        "_internal_originalTime": 1689778439000
    },
    {
        "time": 1689778440000,
        "value": 139.743,
        "_internal_originalTime": 1689778440000
    },
    {
        "time": 1689778441000,
        "value": 139.727,
        "_internal_originalTime": 1689778441000
    },
    {
        "time": 1689778442000,
        "value": 139.725,
        "_internal_originalTime": 1689778442000
    },
    {
        "time": 1689778443000,
        "value": 139.722,
        "_internal_originalTime": 1689778443000
    },
    {
        "time": 1689778444000,
        "value": 139.725,
        "_internal_originalTime": 1689778444000
    },
    {
        "time": 1689778445000,
        "value": 139.728,
        "_internal_originalTime": 1689778445000
    },
    {
        "time": 1689778446000,
        "value": 139.73,
        "_internal_originalTime": 1689778446000
    },
    {
        "time": 1689778447000,
        "value": 139.729,
        "_internal_originalTime": 1689778447000
    },
    {
        "time": 1689778448000,
        "value": 139.732,
        "_internal_originalTime": 1689778448000
    },
    {
        "time": 1689778449000,
        "value": 139.736,
        "_internal_originalTime": 1689778449000
    },
    {
        "time": 1689778450000,
        "value": 139.742,
        "_internal_originalTime": 1689778450000
    },
    {
        "time": 1689778451000,
        "value": 139.738,
        "_internal_originalTime": 1689778451000
    },
    {
        "time": 1689778452000,
        "value": 139.736,
        "_internal_originalTime": 1689778452000
    },
    {
        "time": 1689778453000,
        "value": 139.735,
        "_internal_originalTime": 1689778453000
    },
    {
        "time": 1689778454000,
        "value": 139.735,
        "_internal_originalTime": 1689778454000
    },
    {
        "time": 1689778455000,
        "value": 139.743,
        "_internal_originalTime": 1689778455000
    },
    {
        "time": 1689778456000,
        "value": 139.741,
        "_internal_originalTime": 1689778456000
    },
    {
        "time": 1689778457000,
        "value": 139.743,
        "_internal_originalTime": 1689778457000
    },
    {
        "time": 1689778459000,
        "value": 139.745,
        "_internal_originalTime": 1689778459000
    },
    {
        "time": 1689778460000,
        "value": 139.748,
        "_internal_originalTime": 1689778460000
    },
    {
        "time": 1689778461000,
        "value": 139.753,
        "_internal_originalTime": 1689778461000
    },
    {
        "time": 1689778462000,
        "value": 139.759,
        "_internal_originalTime": 1689778462000
    },
    {
        "time": 1689778464000,
        "value": 139.761,
        "_internal_originalTime": 1689778464000
    },
    {
        "time": 1689778467000,
        "value": 139.75,
        "_internal_originalTime": 1689778467000
    },
    {
        "time": 1689778468000,
        "value": 139.747,
        "_internal_originalTime": 1689778468000
    },
    {
        "time": 1689778469000,
        "value": 139.758,
        "_internal_originalTime": 1689778469000
    },
    {
        "time": 1689778470000,
        "value": 139.758,
        "_internal_originalTime": 1689778470000
    },
    {
        "time": 1689778471000,
        "value": 139.748,
        "_internal_originalTime": 1689778471000
    },
    {
        "time": 1689778472000,
        "value": 139.74,
        "_internal_originalTime": 1689778472000
    },
    {
        "time": 1689778473000,
        "value": 139.738,
        "_internal_originalTime": 1689778473000
    },
    {
        "time": 1689778474000,
        "value": 139.739,
        "_internal_originalTime": 1689778474000
    },
    {
        "time": 1689778475000,
        "value": 139.736,
        "_internal_originalTime": 1689778475000
    },
    {
        "time": 1689778476000,
        "value": 139.732,
        "_internal_originalTime": 1689778476000
    },
    {
        "time": 1689778478000,
        "value": 139.726,
        "_internal_originalTime": 1689778478000
    },
    {
        "time": 1689778479000,
        "value": 139.723,
        "_internal_originalTime": 1689778479000
    },
    {
        "time": 1689778480000,
        "value": 139.722,
        "_internal_originalTime": 1689778480000
    },
    {
        "time": 1689778481000,
        "value": 139.727,
        "_internal_originalTime": 1689778481000
    },
    {
        "time": 1689778482000,
        "value": 139.719,
        "_internal_originalTime": 1689778482000
    },
    {
        "time": 1689778483000,
        "value": 139.725,
        "_internal_originalTime": 1689778483000
    },
    {
        "time": 1689778484000,
        "value": 139.73,
        "_internal_originalTime": 1689778484000
    },
    {
        "time": 1689778485000,
        "value": 139.73,
        "_internal_originalTime": 1689778485000
    },
    {
        "time": 1689778486000,
        "value": 139.732,
        "_internal_originalTime": 1689778486000
    },
    {
        "time": 1689778487000,
        "value": 139.743,
        "_internal_originalTime": 1689778487000
    },
    {
        "time": 1689778488000,
        "value": 139.74,
        "_internal_originalTime": 1689778488000
    },
    {
        "time": 1689778489000,
        "value": 139.739,
        "_internal_originalTime": 1689778489000
    },
    {
        "time": 1689778490000,
        "value": 139.74,
        "_internal_originalTime": 1689778490000
    },
    {
        "time": 1689778491000,
        "value": 139.734,
        "_internal_originalTime": 1689778491000
    },
    {
        "time": 1689778493000,
        "value": 139.724,
        "_internal_originalTime": 1689778493000
    },
    {
        "time": 1689778495000,
        "value": 139.725,
        "_internal_originalTime": 1689778495000
    },
    {
        "time": 1689778496000,
        "value": 139.727,
        "_internal_originalTime": 1689778496000
    },
    {
        "time": 1689778497000,
        "value": 139.728,
        "_internal_originalTime": 1689778497000
    },
    {
        "time": 1689778498000,
        "value": 139.724,
        "_internal_originalTime": 1689778498000
    },
    {
        "time": 1689778499000,
        "value": 139.73,
        "_internal_originalTime": 1689778499000
    },
    {
        "time": 1689778500000,
        "value": 139.725,
        "_internal_originalTime": 1689778500000
    },
    {
        "time": 1689778502000,
        "value": 139.72,
        "_internal_originalTime": 1689778502000
    },
    {
        "time": 1689778503000,
        "value": 139.724,
        "_internal_originalTime": 1689778503000
    },
    {
        "time": 1689778505000,
        "value": 139.722,
        "_internal_originalTime": 1689778505000
    },
    {
        "time": 1689778506000,
        "value": 139.725,
        "_internal_originalTime": 1689778506000
    },
    {
        "time": 1689778507000,
        "value": 139.727,
        "_internal_originalTime": 1689778507000
    },
    {
        "time": 1689778508000,
        "value": 139.716,
        "_internal_originalTime": 1689778508000
    },
    {
        "time": 1689778509000,
        "value": 139.716,
        "_internal_originalTime": 1689778509000
    },
    {
        "time": 1689778510000,
        "value": 139.71,
        "_internal_originalTime": 1689778510000
    },
    {
        "time": 1689778511000,
        "value": 139.721,
        "_internal_originalTime": 1689778511000
    },
    {
        "time": 1689778512000,
        "value": 139.722,
        "_internal_originalTime": 1689778512000
    },
    {
        "time": 1689778513000,
        "value": 139.724,
        "_internal_originalTime": 1689778513000
    },
    {
        "time": 1689778514000,
        "value": 139.728,
        "_internal_originalTime": 1689778514000
    },
    {
        "time": 1689778516000,
        "value": 139.724,
        "_internal_originalTime": 1689778516000
    },
    {
        "time": 1689778517000,
        "value": 139.718,
        "_internal_originalTime": 1689778517000
    },
    {
        "time": 1689778518000,
        "value": 139.721,
        "_internal_originalTime": 1689778518000
    },
    {
        "time": 1689778520000,
        "value": 139.72,
        "_internal_originalTime": 1689778520000
    },
    {
        "time": 1689778521000,
        "value": 139.719,
        "_internal_originalTime": 1689778521000
    },
    {
        "time": 1689778522000,
        "value": 139.714,
        "_internal_originalTime": 1689778522000
    },
    {
        "time": 1689778549000,
        "value": 139.68,
        "_internal_originalTime": 1689778549000
    },
    {
        "time": 1689778550000,
        "value": 139.68,
        "_internal_originalTime": 1689778550000
    },
    {
        "time": 1689778551000,
        "value": 139.68,
        "_internal_originalTime": 1689778551000
    },
    {
        "time": 1689778552000,
        "value": 139.676,
        "_internal_originalTime": 1689778552000
    },
    {
        "time": 1689778554000,
        "value": 139.681,
        "_internal_originalTime": 1689778554000
    },
    {
        "time": 1689778555000,
        "value": 139.68,
        "_internal_originalTime": 1689778555000
    },
    {
        "time": 1689778556000,
        "value": 139.689,
        "_internal_originalTime": 1689778556000
    },
    {
        "time": 1689778557000,
        "value": 139.682,
        "_internal_originalTime": 1689778557000
    },
    {
        "time": 1689778558000,
        "value": 139.685,
        "_internal_originalTime": 1689778558000
    },
    {
        "time": 1689778559000,
        "value": 139.686,
        "_internal_originalTime": 1689778559000
    },
    {
        "time": 1689778561000,
        "value": 139.677,
        "_internal_originalTime": 1689778561000
    },
    {
        "time": 1689778562000,
        "value": 139.684,
        "_internal_originalTime": 1689778562000
    },
    {
        "time": 1689778563000,
        "value": 139.685,
        "_internal_originalTime": 1689778563000
    },
    {
        "time": 1689778564000,
        "value": 139.685,
        "_internal_originalTime": 1689778564000
    },
    {
        "time": 1689778565000,
        "value": 139.685,
        "_internal_originalTime": 1689778565000
    },
    {
        "time": 1689778566000,
        "value": 139.68,
        "_internal_originalTime": 1689778566000
    },
    {
        "time": 1689778568000,
        "value": 139.681,
        "_internal_originalTime": 1689778568000
    },
    {
        "time": 1689778569000,
        "value": 139.685,
        "_internal_originalTime": 1689778569000
    },
    {
        "time": 1689778570000,
        "value": 139.687,
        "_internal_originalTime": 1689778570000
    },
    {
        "time": 1689778571000,
        "value": 139.681,
        "_internal_originalTime": 1689778571000
    },
    {
        "time": 1689778572000,
        "value": 139.68,
        "_internal_originalTime": 1689778572000
    },
    {
        "time": 1689778573000,
        "value": 139.681,
        "_internal_originalTime": 1689778573000
    },
    {
        "time": 1689778574000,
        "value": 139.68,
        "_internal_originalTime": 1689778574000
    },
    {
        "time": 1689778575000,
        "value": 139.68,
        "_internal_originalTime": 1689778575000
    },
    {
        "time": 1689778576000,
        "value": 139.687,
        "_internal_originalTime": 1689778576000
    },
    {
        "time": 1689778577000,
        "value": 139.682,
        "_internal_originalTime": 1689778577000
    },
    {
        "time": 1689778578000,
        "value": 139.686,
        "_internal_originalTime": 1689778578000
    },
    {
        "time": 1689778579000,
        "value": 139.687,
        "_internal_originalTime": 1689778579000
    },
    {
        "time": 1689778581000,
        "value": 139.7,
        "_internal_originalTime": 1689778581000
    },
    {
        "time": 1689778582000,
        "value": 139.704,
        "_internal_originalTime": 1689778582000
    },
    {
        "time": 1689778583000,
        "value": 139.705,
        "_internal_originalTime": 1689778583000
    },
    {
        "time": 1689778584000,
        "value": 139.707,
        "_internal_originalTime": 1689778584000
    },
    {
        "time": 1689778585000,
        "value": 139.707,
        "_internal_originalTime": 1689778585000
    },
    {
        "time": 1689778586000,
        "value": 139.714,
        "_internal_originalTime": 1689778586000
    },
    {
        "time": 1689778587000,
        "value": 139.718,
        "_internal_originalTime": 1689778587000
    },
    {
        "time": 1689778588000,
        "value": 139.719,
        "_internal_originalTime": 1689778588000
    },
    {
        "time": 1689778589000,
        "value": 139.723,
        "_internal_originalTime": 1689778589000
    },
    {
        "time": 1689778590000,
        "value": 139.726,
        "_internal_originalTime": 1689778590000
    },
    {
        "time": 1689778591000,
        "value": 139.729,
        "_internal_originalTime": 1689778591000
    },
    {
        "time": 1689778592000,
        "value": 139.729,
        "_internal_originalTime": 1689778592000
    },
    {
        "time": 1689778593000,
        "value": 139.73,
        "_internal_originalTime": 1689778593000
    },
    {
        "time": 1689778594000,
        "value": 139.73,
        "_internal_originalTime": 1689778594000
    },
    {
        "time": 1689778595000,
        "value": 139.724,
        "_internal_originalTime": 1689778595000
    },
    {
        "time": 1689778596000,
        "value": 139.726,
        "_internal_originalTime": 1689778596000
    },
    {
        "time": 1689778598000,
        "value": 139.726,
        "_internal_originalTime": 1689778598000
    },
    {
        "time": 1689778599000,
        "value": 139.729,
        "_internal_originalTime": 1689778599000
    },
    {
        "time": 1689778600000,
        "value": 139.724,
        "_internal_originalTime": 1689778600000
    },
    {
        "time": 1689778602000,
        "value": 139.726,
        "_internal_originalTime": 1689778602000
    },
    {
        "time": 1689778603000,
        "value": 139.728,
        "_internal_originalTime": 1689778603000
    },
    {
        "time": 1689778604000,
        "value": 139.721,
        "_internal_originalTime": 1689778604000
    },
    {
        "time": 1689778606000,
        "value": 139.723,
        "_internal_originalTime": 1689778606000
    },
    {
        "time": 1689778608000,
        "value": 139.74,
        "_internal_originalTime": 1689778608000
    },
    {
        "time": 1689778610000,
        "value": 139.75,
        "_internal_originalTime": 1689778610000
    },
    {
        "time": 1689778611000,
        "value": 139.757,
        "_internal_originalTime": 1689778611000
    },
    {
        "time": 1689778612000,
        "value": 139.755,
        "_internal_originalTime": 1689778612000
    },
    {
        "time": 1689778613000,
        "value": 139.758,
        "_internal_originalTime": 1689778613000
    },
    {
        "time": 1689778615000,
        "value": 139.753,
        "_internal_originalTime": 1689778615000
    },
    {
        "time": 1689778616000,
        "value": 139.755,
        "_internal_originalTime": 1689778616000
    },
    {
        "time": 1689778618000,
        "value": 139.759,
        "_internal_originalTime": 1689778618000
    },
    {
        "time": 1689778620000,
        "value": 139.762,
        "_internal_originalTime": 1689778620000
    },
    {
        "time": 1689778621000,
        "value": 139.73,
        "_internal_originalTime": 1689778621000
    },
    {
        "time": 1689778622000,
        "value": 139.766,
        "_internal_originalTime": 1689778622000
    },
    {
        "time": 1689778623000,
        "value": 139.769,
        "_internal_originalTime": 1689778623000
    },
    {
        "time": 1689778624000,
        "value": 139.759,
        "_internal_originalTime": 1689778624000
    },
    {
        "time": 1689778625000,
        "value": 139.766,
        "_internal_originalTime": 1689778625000
    },
    {
        "time": 1689778627000,
        "value": 139.763,
        "_internal_originalTime": 1689778627000
    },
    {
        "time": 1689778628000,
        "value": 139.764,
        "_internal_originalTime": 1689778628000
    },
    {
        "time": 1689778629000,
        "value": 139.767,
        "_internal_originalTime": 1689778629000
    },
    {
        "time": 1689778630000,
        "value": 139.764,
        "_internal_originalTime": 1689778630000
    },
    {
        "time": 1689778631000,
        "value": 139.768,
        "_internal_originalTime": 1689778631000
    },
    {
        "time": 1689778632000,
        "value": 139.764,
        "_internal_originalTime": 1689778632000
    },
    {
        "time": 1689778633000,
        "value": 139.758,
        "_internal_originalTime": 1689778633000
    },
    {
        "time": 1689778635000,
        "value": 139.752,
        "_internal_originalTime": 1689778635000
    },
    {
        "time": 1689778636000,
        "value": 139.748,
        "_internal_originalTime": 1689778636000
    },
    {
        "time": 1689778637000,
        "value": 139.752,
        "_internal_originalTime": 1689778637000
    },
    {
        "time": 1689778639000,
        "value": 139.75,
        "_internal_originalTime": 1689778639000
    },
    {
        "time": 1689778641000,
        "value": 139.748,
        "_internal_originalTime": 1689778641000
    },
    {
        "time": 1689778642000,
        "value": 139.75,
        "_internal_originalTime": 1689778642000
    },
    {
        "time": 1689778643000,
        "value": 139.747,
        "_internal_originalTime": 1689778643000
    },
    {
        "time": 1689778645000,
        "value": 139.743,
        "_internal_originalTime": 1689778645000
    },
    {
        "time": 1689778646000,
        "value": 139.747,
        "_internal_originalTime": 1689778646000
    },
    {
        "time": 1689778647000,
        "value": 139.742,
        "_internal_originalTime": 1689778647000
    },
    {
        "time": 1689778648000,
        "value": 139.738,
        "_internal_originalTime": 1689778648000
    },
    {
        "time": 1689778649000,
        "value": 139.74,
        "_internal_originalTime": 1689778649000
    },
    {
        "time": 1689778651000,
        "value": 139.723,
        "_internal_originalTime": 1689778651000
    },
    {
        "time": 1689778652000,
        "value": 139.707,
        "_internal_originalTime": 1689778652000
    },
    {
        "time": 1689778653000,
        "value": 139.709,
        "_internal_originalTime": 1689778653000
    },
    {
        "time": 1689778654000,
        "value": 139.706,
        "_internal_originalTime": 1689778654000
    },
    {
        "time": 1689778656000,
        "value": 139.698,
        "_internal_originalTime": 1689778656000
    },
    {
        "time": 1689778657000,
        "value": 139.697,
        "_internal_originalTime": 1689778657000
    },
    {
        "time": 1689778658000,
        "value": 139.688,
        "_internal_originalTime": 1689778658000
    },
    {
        "time": 1689778659000,
        "value": 139.685,
        "_internal_originalTime": 1689778659000
    },
    {
        "time": 1689778660000,
        "value": 139.691,
        "_internal_originalTime": 1689778660000
    },
    {
        "time": 1689778661000,
        "value": 139.678,
        "_internal_originalTime": 1689778661000
    },
    {
        "time": 1689778663000,
        "value": 139.674,
        "_internal_originalTime": 1689778663000
    },
    {
        "time": 1689778664000,
        "value": 139.673,
        "_internal_originalTime": 1689778664000
    },
    {
        "time": 1689778665000,
        "value": 139.673,
        "_internal_originalTime": 1689778665000
    },
    {
        "time": 1689778666000,
        "value": 139.67,
        "_internal_originalTime": 1689778666000
    },
    {
        "time": 1689778668000,
        "value": 139.664,
        "_internal_originalTime": 1689778668000
    },
    {
        "time": 1689778669000,
        "value": 139.658,
        "_internal_originalTime": 1689778669000
    },
    {
        "time": 1689778670000,
        "value": 139.648,
        "_internal_originalTime": 1689778670000
    },
    {
        "time": 1689778671000,
        "value": 139.643,
        "_internal_originalTime": 1689778671000
    },
    {
        "time": 1689778672000,
        "value": 139.651,
        "_internal_originalTime": 1689778672000
    },
    {
        "time": 1689778674000,
        "value": 139.644,
        "_internal_originalTime": 1689778674000
    },
    {
        "time": 1689778675000,
        "value": 139.642,
        "_internal_originalTime": 1689778675000
    },
    {
        "time": 1689778677000,
        "value": 139.634,
        "_internal_originalTime": 1689778677000
    },
    {
        "time": 1689778678000,
        "value": 139.635,
        "_internal_originalTime": 1689778678000
    },
    {
        "time": 1689778679000,
        "value": 139.64,
        "_internal_originalTime": 1689778679000
    },
    {
        "time": 1689778680000,
        "value": 139.628,
        "_internal_originalTime": 1689778680000
    },
    {
        "time": 1689778682000,
        "value": 139.631,
        "_internal_originalTime": 1689778682000
    },
    {
        "time": 1689778683000,
        "value": 139.631,
        "_internal_originalTime": 1689778683000
    },
    {
        "time": 1689778684000,
        "value": 139.633,
        "_internal_originalTime": 1689778684000
    },
    {
        "time": 1689778686000,
        "value": 139.642,
        "_internal_originalTime": 1689778686000
    },
    {
        "time": 1689778688000,
        "value": 139.657,
        "_internal_originalTime": 1689778688000
    },
    {
        "time": 1689778689000,
        "value": 139.654,
        "_internal_originalTime": 1689778689000
    },
    {
        "time": 1689778690000,
        "value": 139.655,
        "_internal_originalTime": 1689778690000
    },
    {
        "time": 1689778692000,
        "value": 139.65,
        "_internal_originalTime": 1689778692000
    },
    {
        "time": 1689778693000,
        "value": 139.656,
        "_internal_originalTime": 1689778693000
    },
    {
        "time": 1689778695000,
        "value": 139.654,
        "_internal_originalTime": 1689778695000
    },
    {
        "time": 1689778696000,
        "value": 139.651,
        "_internal_originalTime": 1689778696000
    },
    {
        "time": 1689778697000,
        "value": 139.641,
        "_internal_originalTime": 1689778697000
    },
    {
        "time": 1689778699000,
        "value": 139.643,
        "_internal_originalTime": 1689778699000
    },
    {
        "time": 1689778700000,
        "value": 139.648,
        "_internal_originalTime": 1689778700000
    },
    {
        "time": 1689778701000,
        "value": 139.651,
        "_internal_originalTime": 1689778701000
    },
    {
        "time": 1689778702000,
        "value": 139.651,
        "_internal_originalTime": 1689778702000
    },
    {
        "time": 1689778703000,
        "value": 139.648,
        "_internal_originalTime": 1689778703000
    },
    {
        "time": 1689778705000,
        "value": 139.642,
        "_internal_originalTime": 1689778705000
    },
    {
        "time": 1689778706000,
        "value": 139.642,
        "_internal_originalTime": 1689778706000
    },
    {
        "time": 1689778708000,
        "value": 139.639,
        "_internal_originalTime": 1689778708000
    },
    {
        "time": 1689778710000,
        "value": 139.624,
        "_internal_originalTime": 1689778710000
    },
    {
        "time": 1689778711000,
        "value": 139.623,
        "_internal_originalTime": 1689778711000
    },
    {
        "time": 1689778713000,
        "value": 139.621,
        "_internal_originalTime": 1689778713000
    },
    {
        "time": 1689778715000,
        "value": 139.622,
        "_internal_originalTime": 1689778715000
    },
    {
        "time": 1689778716000,
        "value": 139.624,
        "_internal_originalTime": 1689778716000
    },
    {
        "time": 1689778717000,
        "value": 139.624,
        "_internal_originalTime": 1689778717000
    },
    {
        "time": 1689778718000,
        "value": 139.627,
        "_internal_originalTime": 1689778718000
    },
    {
        "time": 1689778719000,
        "value": 139.621,
        "_internal_originalTime": 1689778719000
    },
    {
        "time": 1689778720000,
        "value": 139.622,
        "_internal_originalTime": 1689778720000
    },
    {
        "time": 1689778721000,
        "value": 139.62,
        "_internal_originalTime": 1689778721000
    },
    {
        "time": 1689778723000,
        "value": 139.614,
        "_internal_originalTime": 1689778723000
    },
    {
        "time": 1689778724000,
        "value": 139.614,
        "_internal_originalTime": 1689778724000
    },
    {
        "time": 1689778725000,
        "value": 139.614,
        "_internal_originalTime": 1689778725000
    },
    {
        "time": 1689778726000,
        "value": 139.612,
        "_internal_originalTime": 1689778726000
    },
    {
        "time": 1689778727000,
        "value": 139.611,
        "_internal_originalTime": 1689778727000
    },
    {
        "time": 1689778728000,
        "value": 139.605,
        "_internal_originalTime": 1689778728000
    },
    {
        "time": 1689778729000,
        "value": 139.607,
        "_internal_originalTime": 1689778729000
    },
    {
        "time": 1689778730000,
        "value": 139.611,
        "_internal_originalTime": 1689778730000
    },
    {
        "time": 1689778732000,
        "value": 139.609,
        "_internal_originalTime": 1689778732000
    },
    {
        "time": 1689778733000,
        "value": 139.609,
        "_internal_originalTime": 1689778733000
    },
    {
        "time": 1689778734000,
        "value": 139.617,
        "_internal_originalTime": 1689778734000
    },
    {
        "time": 1689778735000,
        "value": 139.626,
        "_internal_originalTime": 1689778735000
    },
    {
        "time": 1689778736000,
        "value": 139.623,
        "_internal_originalTime": 1689778736000
    },
    {
        "time": 1689778737000,
        "value": 139.61,
        "_internal_originalTime": 1689778737000
    },
    {
        "time": 1689778739000,
        "value": 139.612,
        "_internal_originalTime": 1689778739000
    },
    {
        "time": 1689778740000,
        "value": 139.613,
        "_internal_originalTime": 1689778740000
    },
    {
        "time": 1689778741000,
        "value": 139.611,
        "_internal_originalTime": 1689778741000
    },
    {
        "time": 1689778742000,
        "value": 139.617,
        "_internal_originalTime": 1689778742000
    },
    {
        "time": 1689778743000,
        "value": 139.615,
        "_internal_originalTime": 1689778743000
    },
    {
        "time": 1689778744000,
        "value": 139.615,
        "_internal_originalTime": 1689778744000
    },
    {
        "time": 1689778745000,
        "value": 139.616,
        "_internal_originalTime": 1689778745000
    },
    {
        "time": 1689778746000,
        "value": 139.616,
        "_internal_originalTime": 1689778746000
    },
    {
        "time": 1689778748000,
        "value": 139.613,
        "_internal_originalTime": 1689778748000
    },
    {
        "time": 1689778749000,
        "value": 139.616,
        "_internal_originalTime": 1689778749000
    },
    {
        "time": 1689778750000,
        "value": 139.616,
        "_internal_originalTime": 1689778750000
    },
    {
        "time": 1689778751000,
        "value": 139.614,
        "_internal_originalTime": 1689778751000
    },
    {
        "time": 1689778753000,
        "value": 139.615,
        "_internal_originalTime": 1689778753000
    },
    {
        "time": 1689778754000,
        "value": 139.61,
        "_internal_originalTime": 1689778754000
    },
    {
        "time": 1689778755000,
        "value": 139.613,
        "_internal_originalTime": 1689778755000
    },
    {
        "time": 1689778756000,
        "value": 139.617,
        "_internal_originalTime": 1689778756000
    },
    {
        "time": 1689778757000,
        "value": 139.613,
        "_internal_originalTime": 1689778757000
    },
    {
        "time": 1689778758000,
        "value": 139.615,
        "_internal_originalTime": 1689778758000
    },
    {
        "time": 1689778759000,
        "value": 139.615,
        "_internal_originalTime": 1689778759000
    },
    {
        "time": 1689778760000,
        "value": 139.615,
        "_internal_originalTime": 1689778760000
    },
    {
        "time": 1689778761000,
        "value": 139.622,
        "_internal_originalTime": 1689778761000
    },
    {
        "time": 1689778762000,
        "value": 139.622,
        "_internal_originalTime": 1689778762000
    },
    {
        "time": 1689778763000,
        "value": 139.622,
        "_internal_originalTime": 1689778763000
    },
    {
        "time": 1689778764000,
        "value": 139.626,
        "_internal_originalTime": 1689778764000
    },
    {
        "time": 1689778765000,
        "value": 139.623,
        "_internal_originalTime": 1689778765000
    },
    {
        "time": 1689778767000,
        "value": 139.626,
        "_internal_originalTime": 1689778767000
    },
    {
        "time": 1689778768000,
        "value": 139.623,
        "_internal_originalTime": 1689778768000
    },
    {
        "time": 1689778770000,
        "value": 139.625,
        "_internal_originalTime": 1689778770000
    },
    {
        "time": 1689778771000,
        "value": 139.624,
        "_internal_originalTime": 1689778771000
    },
    {
        "time": 1689778773000,
        "value": 139.624,
        "_internal_originalTime": 1689778773000
    },
    {
        "time": 1689778774000,
        "value": 139.631,
        "_internal_originalTime": 1689778774000
    },
    {
        "time": 1689778775000,
        "value": 139.623,
        "_internal_originalTime": 1689778775000
    },
    {
        "time": 1689778777000,
        "value": 139.629,
        "_internal_originalTime": 1689778777000
    },
    {
        "time": 1689778779000,
        "value": 139.627,
        "_internal_originalTime": 1689778779000
    },
    {
        "time": 1689778780000,
        "value": 139.628,
        "_internal_originalTime": 1689778780000
    },
    {
        "time": 1689778781000,
        "value": 139.631,
        "_internal_originalTime": 1689778781000
    },
    {
        "time": 1689778782000,
        "value": 139.622,
        "_internal_originalTime": 1689778782000
    },
    {
        "time": 1689778783000,
        "value": 139.624,
        "_internal_originalTime": 1689778783000
    },
    {
        "time": 1689778785000,
        "value": 139.63,
        "_internal_originalTime": 1689778785000
    },
    {
        "time": 1689778787000,
        "value": 139.62,
        "_internal_originalTime": 1689778787000
    },
    {
        "time": 1689778788000,
        "value": 139.619,
        "_internal_originalTime": 1689778788000
    },
    {
        "time": 1689778790000,
        "value": 139.625,
        "_internal_originalTime": 1689778790000
    },
    {
        "time": 1689778791000,
        "value": 139.617,
        "_internal_originalTime": 1689778791000
    },
    {
        "time": 1689778792000,
        "value": 139.614,
        "_internal_originalTime": 1689778792000
    },
    {
        "time": 1689778793000,
        "value": 139.617,
        "_internal_originalTime": 1689778793000
    },
    {
        "time": 1689778794000,
        "value": 139.612,
        "_internal_originalTime": 1689778794000
    },
    {
        "time": 1689778796000,
        "value": 139.611,
        "_internal_originalTime": 1689778796000
    },
    {
        "time": 1689778798000,
        "value": 139.617,
        "_internal_originalTime": 1689778798000
    },
    {
        "time": 1689778799000,
        "value": 139.617,
        "_internal_originalTime": 1689778799000
    },
    {
        "time": 1689778800000,
        "value": 139.613,
        "_internal_originalTime": 1689778800000
    },
    {
        "time": 1689778801000,
        "value": 139.612,
        "_internal_originalTime": 1689778801000
    },
    {
        "time": 1689778803000,
        "value": 139.614,
        "_internal_originalTime": 1689778803000
    },
    {
        "time": 1689778804000,
        "value": 139.613,
        "_internal_originalTime": 1689778804000
    },
    {
        "time": 1689778806000,
        "value": 139.597,
        "_internal_originalTime": 1689778806000
    },
    {
        "time": 1689778807000,
        "value": 139.598,
        "_internal_originalTime": 1689778807000
    },
    {
        "time": 1689778808000,
        "value": 139.592,
        "_internal_originalTime": 1689778808000
    },
    {
        "time": 1689778809000,
        "value": 139.593,
        "_internal_originalTime": 1689778809000
    },
    {
        "time": 1689778810000,
        "value": 139.587,
        "_internal_originalTime": 1689778810000
    },
    {
        "time": 1689778811000,
        "value": 139.59,
        "_internal_originalTime": 1689778811000
    },
    {
        "time": 1689778813000,
        "value": 139.582,
        "_internal_originalTime": 1689778813000
    },
    {
        "time": 1689778814000,
        "value": 139.585,
        "_internal_originalTime": 1689778814000
    },
    {
        "time": 1689778815000,
        "value": 139.588,
        "_internal_originalTime": 1689778815000
    },
    {
        "time": 1689778816000,
        "value": 139.596,
        "_internal_originalTime": 1689778816000
    },
    {
        "time": 1689778817000,
        "value": 139.595,
        "_internal_originalTime": 1689778817000
    },
    {
        "time": 1689778818000,
        "value": 139.592,
        "_internal_originalTime": 1689778818000
    },
    {
        "time": 1689778819000,
        "value": 139.587,
        "_internal_originalTime": 1689778819000
    },
    {
        "time": 1689778820000,
        "value": 139.589,
        "_internal_originalTime": 1689778820000
    },
    {
        "time": 1689778822000,
        "value": 139.583,
        "_internal_originalTime": 1689778822000
    },
    {
        "time": 1689778823000,
        "value": 139.582,
        "_internal_originalTime": 1689778823000
    },
    {
        "time": 1689778824000,
        "value": 139.576,
        "_internal_originalTime": 1689778824000
    },
    {
        "time": 1689778825000,
        "value": 139.584,
        "_internal_originalTime": 1689778825000
    },
    {
        "time": 1689778826000,
        "value": 139.574,
        "_internal_originalTime": 1689778826000
    },
    {
        "time": 1689778828000,
        "value": 139.586,
        "_internal_originalTime": 1689778828000
    },
    {
        "time": 1689778829000,
        "value": 139.594,
        "_internal_originalTime": 1689778829000
    },
    {
        "time": 1689778830000,
        "value": 139.587,
        "_internal_originalTime": 1689778830000
    },
    {
        "time": 1689778831000,
        "value": 139.587,
        "_internal_originalTime": 1689778831000
    },
    {
        "time": 1689778832000,
        "value": 139.59,
        "_internal_originalTime": 1689778832000
    },
    {
        "time": 1689778834000,
        "value": 139.594,
        "_internal_originalTime": 1689778834000
    },
    {
        "time": 1689778835000,
        "value": 139.589,
        "_internal_originalTime": 1689778835000
    },
    {
        "time": 1689778836000,
        "value": 139.59,
        "_internal_originalTime": 1689778836000
    },
    {
        "time": 1689778837000,
        "value": 139.586,
        "_internal_originalTime": 1689778837000
    },
    {
        "time": 1689778838000,
        "value": 139.584,
        "_internal_originalTime": 1689778838000
    },
    {
        "time": 1689778840000,
        "value": 139.584,
        "_internal_originalTime": 1689778840000
    },
    {
        "time": 1689778841000,
        "value": 139.584,
        "_internal_originalTime": 1689778841000
    },
    {
        "time": 1689778843000,
        "value": 139.579,
        "_internal_originalTime": 1689778843000
    },
    {
        "time": 1689778845000,
        "value": 139.578,
        "_internal_originalTime": 1689778845000
    },
    {
        "time": 1689778847000,
        "value": 139.577,
        "_internal_originalTime": 1689778847000
    },
    {
        "time": 1689778848000,
        "value": 139.578,
        "_internal_originalTime": 1689778848000
    },
    {
        "time": 1689778849000,
        "value": 139.565,
        "_internal_originalTime": 1689778849000
    },
    {
        "time": 1689778850000,
        "value": 139.576,
        "_internal_originalTime": 1689778850000
    },
    {
        "time": 1689778851000,
        "value": 139.575,
        "_internal_originalTime": 1689778851000
    },
    {
        "time": 1689778853000,
        "value": 139.574,
        "_internal_originalTime": 1689778853000
    },
    {
        "time": 1689778854000,
        "value": 139.571,
        "_internal_originalTime": 1689778854000
    },
    {
        "time": 1689778855000,
        "value": 139.583,
        "_internal_originalTime": 1689778855000
    },
    {
        "time": 1689778856000,
        "value": 139.585,
        "_internal_originalTime": 1689778856000
    },
    {
        "time": 1689778857000,
        "value": 139.589,
        "_internal_originalTime": 1689778857000
    },
    {
        "time": 1689778859000,
        "value": 139.597,
        "_internal_originalTime": 1689778859000
    },
    {
        "time": 1689778860000,
        "value": 139.608,
        "_internal_originalTime": 1689778860000
    },
    {
        "time": 1689778861000,
        "value": 139.601,
        "_internal_originalTime": 1689778861000
    },
    {
        "time": 1689778862000,
        "value": 139.601,
        "_internal_originalTime": 1689778862000
    },
    {
        "time": 1689778864000,
        "value": 139.587,
        "_internal_originalTime": 1689778864000
    },
    {
        "time": 1689778865000,
        "value": 139.592,
        "_internal_originalTime": 1689778865000
    },
    {
        "time": 1689778866000,
        "value": 139.591,
        "_internal_originalTime": 1689778866000
    },
    {
        "time": 1689778868000,
        "value": 139.592,
        "_internal_originalTime": 1689778868000
    },
    {
        "time": 1689778869000,
        "value": 139.596,
        "_internal_originalTime": 1689778869000
    },
    {
        "time": 1689778870000,
        "value": 139.593,
        "_internal_originalTime": 1689778870000
    },
    {
        "time": 1689778871000,
        "value": 139.592,
        "_internal_originalTime": 1689778871000
    },
    {
        "time": 1689778872000,
        "value": 139.585,
        "_internal_originalTime": 1689778872000
    },
    {
        "time": 1689778873000,
        "value": 139.578,
        "_internal_originalTime": 1689778873000
    },
    {
        "time": 1689778874000,
        "value": 139.576,
        "_internal_originalTime": 1689778874000
    },
    {
        "time": 1689778875000,
        "value": 139.573,
        "_internal_originalTime": 1689778875000
    },
    {
        "time": 1689778876000,
        "value": 139.572,
        "_internal_originalTime": 1689778876000
    },
    {
        "time": 1689778877000,
        "value": 139.57,
        "_internal_originalTime": 1689778877000
    },
    {
        "time": 1689778878000,
        "value": 139.567,
        "_internal_originalTime": 1689778878000
    },
    {
        "time": 1689778879000,
        "value": 139.571,
        "_internal_originalTime": 1689778879000
    },
    {
        "time": 1689778881000,
        "value": 139.571,
        "_internal_originalTime": 1689778881000
    },
    {
        "time": 1689778882000,
        "value": 139.572,
        "_internal_originalTime": 1689778882000
    },
    {
        "time": 1689778883000,
        "value": 139.573,
        "_internal_originalTime": 1689778883000
    },
    {
        "time": 1689778884000,
        "value": 139.571,
        "_internal_originalTime": 1689778884000
    },
    {
        "time": 1689778885000,
        "value": 139.571,
        "_internal_originalTime": 1689778885000
    },
    {
        "time": 1689778886000,
        "value": 139.559,
        "_internal_originalTime": 1689778886000
    },
    {
        "time": 1689778887000,
        "value": 139.557,
        "_internal_originalTime": 1689778887000
    },
    {
        "time": 1689778888000,
        "value": 139.563,
        "_internal_originalTime": 1689778888000
    },
    {
        "time": 1689778890000,
        "value": 139.562,
        "_internal_originalTime": 1689778890000
    },
    {
        "time": 1689778891000,
        "value": 139.558,
        "_internal_originalTime": 1689778891000
    },
    {
        "time": 1689778893000,
        "value": 139.552,
        "_internal_originalTime": 1689778893000
    },
    {
        "time": 1689778895000,
        "value": 139.558,
        "_internal_originalTime": 1689778895000
    },
    {
        "time": 1689778897000,
        "value": 139.548,
        "_internal_originalTime": 1689778897000
    },
    {
        "time": 1689778898000,
        "value": 139.538,
        "_internal_originalTime": 1689778898000
    },
    {
        "time": 1689778899000,
        "value": 139.547,
        "_internal_originalTime": 1689778899000
    },
    {
        "time": 1689778900000,
        "value": 139.547,
        "_internal_originalTime": 1689778900000
    },
    {
        "time": 1689778901000,
        "value": 139.544,
        "_internal_originalTime": 1689778901000
    },
    {
        "time": 1689778903000,
        "value": 139.546,
        "_internal_originalTime": 1689778903000
    },
    {
        "time": 1689778904000,
        "value": 139.558,
        "_internal_originalTime": 1689778904000
    },
    {
        "time": 1689778905000,
        "value": 139.555,
        "_internal_originalTime": 1689778905000
    },
    {
        "time": 1689778906000,
        "value": 139.539,
        "_internal_originalTime": 1689778906000
    },
    {
        "time": 1689778907000,
        "value": 139.536,
        "_internal_originalTime": 1689778907000
    },
    {
        "time": 1689778908000,
        "value": 139.541,
        "_internal_originalTime": 1689778908000
    },
    {
        "time": 1689778909000,
        "value": 139.539,
        "_internal_originalTime": 1689778909000
    },
    {
        "time": 1689778911000,
        "value": 139.532,
        "_internal_originalTime": 1689778911000
    },
    {
        "time": 1689778912000,
        "value": 139.534,
        "_internal_originalTime": 1689778912000
    },
    {
        "time": 1689778913000,
        "value": 139.536,
        "_internal_originalTime": 1689778913000
    },
    {
        "time": 1689778914000,
        "value": 139.55,
        "_internal_originalTime": 1689778914000
    },
    {
        "time": 1689778915000,
        "value": 139.552,
        "_internal_originalTime": 1689778915000
    },
    {
        "time": 1689778916000,
        "value": 139.551,
        "_internal_originalTime": 1689778916000
    },
    {
        "time": 1689778917000,
        "value": 139.553,
        "_internal_originalTime": 1689778917000
    },
    {
        "time": 1689778918000,
        "value": 139.562,
        "_internal_originalTime": 1689778918000
    },
    {
        "time": 1689778919000,
        "value": 139.561,
        "_internal_originalTime": 1689778919000
    },
    {
        "time": 1689778920000,
        "value": 139.556,
        "_internal_originalTime": 1689778920000
    },
    {
        "time": 1689778921000,
        "value": 139.559,
        "_internal_originalTime": 1689778921000
    },
    {
        "time": 1689778922000,
        "value": 139.559,
        "_internal_originalTime": 1689778922000
    },
    {
        "time": 1689778923000,
        "value": 139.567,
        "_internal_originalTime": 1689778923000
    },
    {
        "time": 1689778925000,
        "value": 139.569,
        "_internal_originalTime": 1689778925000
    },
    {
        "time": 1689778926000,
        "value": 139.572,
        "_internal_originalTime": 1689778926000
    },
    {
        "time": 1689778927000,
        "value": 139.569,
        "_internal_originalTime": 1689778927000
    },
    {
        "time": 1689778928000,
        "value": 139.558,
        "_internal_originalTime": 1689778928000
    },
    {
        "time": 1689778931000,
        "value": 139.562,
        "_internal_originalTime": 1689778931000
    },
    {
        "time": 1689778932000,
        "value": 139.564,
        "_internal_originalTime": 1689778932000
    },
    {
        "time": 1689778933000,
        "value": 139.562,
        "_internal_originalTime": 1689778933000
    },
    {
        "time": 1689778934000,
        "value": 139.56,
        "_internal_originalTime": 1689778934000
    },
    {
        "time": 1689778935000,
        "value": 139.56,
        "_internal_originalTime": 1689778935000
    },
    {
        "time": 1689778936000,
        "value": 139.562,
        "_internal_originalTime": 1689778936000
    },
    {
        "time": 1689778937000,
        "value": 139.562,
        "_internal_originalTime": 1689778937000
    },
    {
        "time": 1689778938000,
        "value": 139.554,
        "_internal_originalTime": 1689778938000
    },
    {
        "time": 1689778939000,
        "value": 139.554,
        "_internal_originalTime": 1689778939000
    },
    {
        "time": 1689778940000,
        "value": 139.558,
        "_internal_originalTime": 1689778940000
    },
    {
        "time": 1689778941000,
        "value": 139.564,
        "_internal_originalTime": 1689778941000
    },
    {
        "time": 1689778942000,
        "value": 139.564,
        "_internal_originalTime": 1689778942000
    },
    {
        "time": 1689778943000,
        "value": 139.564,
        "_internal_originalTime": 1689778943000
    },
    {
        "time": 1689778944000,
        "value": 139.568,
        "_internal_originalTime": 1689778944000
    },
    {
        "time": 1689778945000,
        "value": 139.562,
        "_internal_originalTime": 1689778945000
    },
    {
        "time": 1689778947000,
        "value": 139.573,
        "_internal_originalTime": 1689778947000
    },
    {
        "time": 1689778948000,
        "value": 139.569,
        "_internal_originalTime": 1689778948000
    },
    {
        "time": 1689778949000,
        "value": 139.574,
        "_internal_originalTime": 1689778949000
    },
    {
        "time": 1689778950000,
        "value": 139.578,
        "_internal_originalTime": 1689778950000
    },
    {
        "time": 1689778951000,
        "value": 139.582,
        "_internal_originalTime": 1689778951000
    },
    {
        "time": 1689778952000,
        "value": 139.58,
        "_internal_originalTime": 1689778952000
    },
    {
        "time": 1689778954000,
        "value": 139.582,
        "_internal_originalTime": 1689778954000
    },
    {
        "time": 1689778955000,
        "value": 139.58,
        "_internal_originalTime": 1689778955000
    },
    {
        "time": 1689778956000,
        "value": 139.589,
        "_internal_originalTime": 1689778956000
    },
    {
        "time": 1689778957000,
        "value": 139.589,
        "_internal_originalTime": 1689778957000
    },
    {
        "time": 1689778958000,
        "value": 139.579,
        "_internal_originalTime": 1689778958000
    },
    {
        "time": 1689778960000,
        "value": 139.582,
        "_internal_originalTime": 1689778960000
    },
    {
        "time": 1689778961000,
        "value": 139.582,
        "_internal_originalTime": 1689778961000
    },
    {
        "time": 1689778962000,
        "value": 139.58,
        "_internal_originalTime": 1689778962000
    },
    {
        "time": 1689778963000,
        "value": 139.578,
        "_internal_originalTime": 1689778963000
    },
    {
        "time": 1689778964000,
        "value": 139.584,
        "_internal_originalTime": 1689778964000
    },
    {
        "time": 1689778965000,
        "value": 139.584,
        "_internal_originalTime": 1689778965000
    },
    {
        "time": 1689778966000,
        "value": 139.583,
        "_internal_originalTime": 1689778966000
    },
    {
        "time": 1689778967000,
        "value": 139.579,
        "_internal_originalTime": 1689778967000
    },
    {
        "time": 1689778968000,
        "value": 139.581,
        "_internal_originalTime": 1689778968000
    },
    {
        "time": 1689778969000,
        "value": 139.575,
        "_internal_originalTime": 1689778969000
    },
    {
        "time": 1689778970000,
        "value": 139.57,
        "_internal_originalTime": 1689778970000
    },
    {
        "time": 1689778973000,
        "value": 139.552,
        "_internal_originalTime": 1689778973000
    },
    {
        "time": 1689778974000,
        "value": 139.548,
        "_internal_originalTime": 1689778974000
    },
    {
        "time": 1689778975000,
        "value": 139.552,
        "_internal_originalTime": 1689778975000
    },
    {
        "time": 1689778976000,
        "value": 139.55,
        "_internal_originalTime": 1689778976000
    },
    {
        "time": 1689778977000,
        "value": 139.548,
        "_internal_originalTime": 1689778977000
    },
    {
        "time": 1689778978000,
        "value": 139.55,
        "_internal_originalTime": 1689778978000
    },
    {
        "time": 1689778979000,
        "value": 139.548,
        "_internal_originalTime": 1689778979000
    },
    {
        "time": 1689778980000,
        "value": 139.546,
        "_internal_originalTime": 1689778980000
    },
    {
        "time": 1689778982000,
        "value": 139.533,
        "_internal_originalTime": 1689778982000
    },
    {
        "time": 1689778983000,
        "value": 139.531,
        "_internal_originalTime": 1689778983000
    },
    {
        "time": 1689778984000,
        "value": 139.532,
        "_internal_originalTime": 1689778984000
    },
    {
        "time": 1689778985000,
        "value": 139.532,
        "_internal_originalTime": 1689778985000
    },
    {
        "time": 1689778986000,
        "value": 139.532,
        "_internal_originalTime": 1689778986000
    },
    {
        "time": 1689778987000,
        "value": 139.524,
        "_internal_originalTime": 1689778987000
    },
    {
        "time": 1689778989000,
        "value": 139.518,
        "_internal_originalTime": 1689778989000
    },
    {
        "time": 1689778990000,
        "value": 139.521,
        "_internal_originalTime": 1689778990000
    },
    {
        "time": 1689778991000,
        "value": 139.523,
        "_internal_originalTime": 1689778991000
    },
    {
        "time": 1689778992000,
        "value": 139.53,
        "_internal_originalTime": 1689778992000
    },
    {
        "time": 1689778993000,
        "value": 139.532,
        "_internal_originalTime": 1689778993000
    },
    {
        "time": 1689778994000,
        "value": 139.54,
        "_internal_originalTime": 1689778994000
    },
    {
        "time": 1689778995000,
        "value": 139.541,
        "_internal_originalTime": 1689778995000
    },
    {
        "time": 1689778996000,
        "value": 139.538,
        "_internal_originalTime": 1689778996000
    },
    {
        "time": 1689778998000,
        "value": 139.543,
        "_internal_originalTime": 1689778998000
    },
    {
        "time": 1689778999000,
        "value": 139.54,
        "_internal_originalTime": 1689778999000
    },
    {
        "time": 1689779000000,
        "value": 139.539,
        "_internal_originalTime": 1689779000000
    },
    {
        "time": 1689779001000,
        "value": 139.543,
        "_internal_originalTime": 1689779001000
    },
    {
        "time": 1689779002000,
        "value": 139.542,
        "_internal_originalTime": 1689779002000
    },
    {
        "time": 1689779003000,
        "value": 139.547,
        "_internal_originalTime": 1689779003000
    },
    {
        "time": 1689779004000,
        "value": 139.536,
        "_internal_originalTime": 1689779004000
    },
    {
        "time": 1689779005000,
        "value": 139.541,
        "_internal_originalTime": 1689779005000
    },
    {
        "time": 1689779006000,
        "value": 139.541,
        "_internal_originalTime": 1689779006000
    },
    {
        "time": 1689779007000,
        "value": 139.541,
        "_internal_originalTime": 1689779007000
    },
    {
        "time": 1689779008000,
        "value": 139.537,
        "_internal_originalTime": 1689779008000
    },
    {
        "time": 1689779009000,
        "value": 139.536,
        "_internal_originalTime": 1689779009000
    },
    {
        "time": 1689779010000,
        "value": 139.541,
        "_internal_originalTime": 1689779010000
    },
    {
        "time": 1689779011000,
        "value": 139.539,
        "_internal_originalTime": 1689779011000
    },
    {
        "time": 1689779012000,
        "value": 139.538,
        "_internal_originalTime": 1689779012000
    },
    {
        "time": 1689779013000,
        "value": 139.542,
        "_internal_originalTime": 1689779013000
    },
    {
        "time": 1689779014000,
        "value": 139.539,
        "_internal_originalTime": 1689779014000
    },
    {
        "time": 1689779015000,
        "value": 139.539,
        "_internal_originalTime": 1689779015000
    },
    {
        "time": 1689779016000,
        "value": 139.521,
        "_internal_originalTime": 1689779016000
    },
    {
        "time": 1689779017000,
        "value": 139.525,
        "_internal_originalTime": 1689779017000
    },
    {
        "time": 1689779018000,
        "value": 139.525,
        "_internal_originalTime": 1689779018000
    },
    {
        "time": 1689779020000,
        "value": 139.529,
        "_internal_originalTime": 1689779020000
    },
    {
        "time": 1689779021000,
        "value": 139.527,
        "_internal_originalTime": 1689779021000
    },
    {
        "time": 1689779022000,
        "value": 139.531,
        "_internal_originalTime": 1689779022000
    },
    {
        "time": 1689779023000,
        "value": 139.527,
        "_internal_originalTime": 1689779023000
    },
    {
        "time": 1689779024000,
        "value": 139.53,
        "_internal_originalTime": 1689779024000
    },
    {
        "time": 1689779025000,
        "value": 139.532,
        "_internal_originalTime": 1689779025000
    },
    {
        "time": 1689779026000,
        "value": 139.532,
        "_internal_originalTime": 1689779026000
    },
    {
        "time": 1689779027000,
        "value": 139.53,
        "_internal_originalTime": 1689779027000
    },
    {
        "time": 1689779028000,
        "value": 139.538,
        "_internal_originalTime": 1689779028000
    },
    {
        "time": 1689779030000,
        "value": 139.536,
        "_internal_originalTime": 1689779030000
    },
    {
        "time": 1689779031000,
        "value": 139.54,
        "_internal_originalTime": 1689779031000
    },
    {
        "time": 1689779032000,
        "value": 139.538,
        "_internal_originalTime": 1689779032000
    },
    {
        "time": 1689779035000,
        "value": 139.538,
        "_internal_originalTime": 1689779035000
    },
    {
        "time": 1689779037000,
        "value": 139.542,
        "_internal_originalTime": 1689779037000
    },
    {
        "time": 1689779038000,
        "value": 139.541,
        "_internal_originalTime": 1689779038000
    },
    {
        "time": 1689779040000,
        "value": 139.548,
        "_internal_originalTime": 1689779040000
    },
    {
        "time": 1689779041000,
        "value": 139.559,
        "_internal_originalTime": 1689779041000
    },
    {
        "time": 1689779042000,
        "value": 139.553,
        "_internal_originalTime": 1689779042000
    },
    {
        "time": 1689779043000,
        "value": 139.551,
        "_internal_originalTime": 1689779043000
    },
    {
        "time": 1689779045000,
        "value": 139.545,
        "_internal_originalTime": 1689779045000
    },
    {
        "time": 1689779046000,
        "value": 139.541,
        "_internal_originalTime": 1689779046000
    },
    {
        "time": 1689779047000,
        "value": 139.536,
        "_internal_originalTime": 1689779047000
    },
    {
        "time": 1689779048000,
        "value": 139.534,
        "_internal_originalTime": 1689779048000
    },
    {
        "time": 1689779050000,
        "value": 139.538,
        "_internal_originalTime": 1689779050000
    },
    {
        "time": 1689779051000,
        "value": 139.538,
        "_internal_originalTime": 1689779051000
    },
    {
        "time": 1689779052000,
        "value": 139.534,
        "_internal_originalTime": 1689779052000
    },
    {
        "time": 1689779053000,
        "value": 139.539,
        "_internal_originalTime": 1689779053000
    },
    {
        "time": 1689779054000,
        "value": 139.551,
        "_internal_originalTime": 1689779054000
    },
    {
        "time": 1689779056000,
        "value": 139.546,
        "_internal_originalTime": 1689779056000
    },
    {
        "time": 1689779057000,
        "value": 139.554,
        "_internal_originalTime": 1689779057000
    },
    {
        "time": 1689779058000,
        "value": 139.558,
        "_internal_originalTime": 1689779058000
    },
    {
        "time": 1689779059000,
        "value": 139.562,
        "_internal_originalTime": 1689779059000
    },
    {
        "time": 1689779060000,
        "value": 139.56,
        "_internal_originalTime": 1689779060000
    },
    {
        "time": 1689779061000,
        "value": 139.566,
        "_internal_originalTime": 1689779061000
    },
    {
        "time": 1689779063000,
        "value": 139.565,
        "_internal_originalTime": 1689779063000
    },
    {
        "time": 1689779064000,
        "value": 139.572,
        "_internal_originalTime": 1689779064000
    },
    {
        "time": 1689779065000,
        "value": 139.572,
        "_internal_originalTime": 1689779065000
    },
    {
        "time": 1689779066000,
        "value": 139.569,
        "_internal_originalTime": 1689779066000
    },
    {
        "time": 1689779067000,
        "value": 139.563,
        "_internal_originalTime": 1689779067000
    },
    {
        "time": 1689779068000,
        "value": 139.558,
        "_internal_originalTime": 1689779068000
    },
    {
        "time": 1689779069000,
        "value": 139.558,
        "_internal_originalTime": 1689779069000
    },
    {
        "time": 1689779070000,
        "value": 139.556,
        "_internal_originalTime": 1689779070000
    },
    {
        "time": 1689779071000,
        "value": 139.562,
        "_internal_originalTime": 1689779071000
    },
    {
        "time": 1689779072000,
        "value": 139.552,
        "_internal_originalTime": 1689779072000
    },
    {
        "time": 1689779074000,
        "value": 139.559,
        "_internal_originalTime": 1689779074000
    },
    {
        "time": 1689779075000,
        "value": 139.561,
        "_internal_originalTime": 1689779075000
    },
    {
        "time": 1689779077000,
        "value": 139.56,
        "_internal_originalTime": 1689779077000
    },
    {
        "time": 1689779078000,
        "value": 139.559,
        "_internal_originalTime": 1689779078000
    },
    {
        "time": 1689779080000,
        "value": 139.56,
        "_internal_originalTime": 1689779080000
    },
    {
        "time": 1689779081000,
        "value": 139.554,
        "_internal_originalTime": 1689779081000
    },
    {
        "time": 1689779082000,
        "value": 139.552,
        "_internal_originalTime": 1689779082000
    },
    {
        "time": 1689779083000,
        "value": 139.557,
        "_internal_originalTime": 1689779083000
    },
    {
        "time": 1689779084000,
        "value": 139.567,
        "_internal_originalTime": 1689779084000
    },
    {
        "time": 1689779085000,
        "value": 139.568,
        "_internal_originalTime": 1689779085000
    },
    {
        "time": 1689779086000,
        "value": 139.569,
        "_internal_originalTime": 1689779086000
    },
    {
        "time": 1689779088000,
        "value": 139.568,
        "_internal_originalTime": 1689779088000
    },
    {
        "time": 1689779089000,
        "value": 139.58,
        "_internal_originalTime": 1689779089000
    },
    {
        "time": 1689779090000,
        "value": 139.575,
        "_internal_originalTime": 1689779090000
    },
    {
        "time": 1689779091000,
        "value": 139.57,
        "_internal_originalTime": 1689779091000
    },
    {
        "time": 1689779093000,
        "value": 139.562,
        "_internal_originalTime": 1689779093000
    },
    {
        "time": 1689779094000,
        "value": 139.55,
        "_internal_originalTime": 1689779094000
    },
    {
        "time": 1689779096000,
        "value": 139.554,
        "_internal_originalTime": 1689779096000
    },
    {
        "time": 1689779097000,
        "value": 139.55,
        "_internal_originalTime": 1689779097000
    },
    {
        "time": 1689779099000,
        "value": 139.557,
        "_internal_originalTime": 1689779099000
    },
    {
        "time": 1689779100000,
        "value": 139.53,
        "_internal_originalTime": 1689779100000
    },
    {
        "time": 1689779101000,
        "value": 139.558,
        "_internal_originalTime": 1689779101000
    },
    {
        "time": 1689779102000,
        "value": 139.55,
        "_internal_originalTime": 1689779102000
    },
    {
        "time": 1689779103000,
        "value": 139.552,
        "_internal_originalTime": 1689779103000
    },
    {
        "time": 1689779104000,
        "value": 139.54,
        "_internal_originalTime": 1689779104000
    },
    {
        "time": 1689779105000,
        "value": 139.543,
        "_internal_originalTime": 1689779105000
    },
    {
        "time": 1689779106000,
        "value": 139.539,
        "_internal_originalTime": 1689779106000
    },
    {
        "time": 1689779108000,
        "value": 139.545,
        "_internal_originalTime": 1689779108000
    },
    {
        "time": 1689779109000,
        "value": 139.545,
        "_internal_originalTime": 1689779109000
    },
    {
        "time": 1689779111000,
        "value": 139.548,
        "_internal_originalTime": 1689779111000
    },
    {
        "time": 1689779112000,
        "value": 139.554,
        "_internal_originalTime": 1689779112000
    },
    {
        "time": 1689779113000,
        "value": 139.556,
        "_internal_originalTime": 1689779113000
    },
    {
        "time": 1689779114000,
        "value": 139.56,
        "_internal_originalTime": 1689779114000
    },
    {
        "time": 1689779115000,
        "value": 139.561,
        "_internal_originalTime": 1689779115000
    },
    {
        "time": 1689779116000,
        "value": 139.57,
        "_internal_originalTime": 1689779116000
    },
    {
        "time": 1689779117000,
        "value": 139.564,
        "_internal_originalTime": 1689779117000
    },
    {
        "time": 1689779119000,
        "value": 139.567,
        "_internal_originalTime": 1689779119000
    },
    {
        "time": 1689779121000,
        "value": 139.562,
        "_internal_originalTime": 1689779121000
    },
    {
        "time": 1689779122000,
        "value": 139.563,
        "_internal_originalTime": 1689779122000
    },
    {
        "time": 1689779123000,
        "value": 139.558,
        "_internal_originalTime": 1689779123000
    },
    {
        "time": 1689779124000,
        "value": 139.562,
        "_internal_originalTime": 1689779124000
    },
    {
        "time": 1689779125000,
        "value": 139.562,
        "_internal_originalTime": 1689779125000
    },
    {
        "time": 1689779126000,
        "value": 139.563,
        "_internal_originalTime": 1689779126000
    },
    {
        "time": 1689779130000,
        "value": 139.578,
        "_internal_originalTime": 1689779130000
    },
    {
        "time": 1689779132000,
        "value": 139.579,
        "_internal_originalTime": 1689779132000
    },
    {
        "time": 1689779133000,
        "value": 139.578,
        "_internal_originalTime": 1689779133000
    },
    {
        "time": 1689779136000,
        "value": 139.581,
        "_internal_originalTime": 1689779136000
    },
    {
        "time": 1689779137000,
        "value": 139.562,
        "_internal_originalTime": 1689779137000
    },
    {
        "time": 1689779138000,
        "value": 139.56,
        "_internal_originalTime": 1689779138000
    },
    {
        "time": 1689779139000,
        "value": 139.56,
        "_internal_originalTime": 1689779139000
    },
    {
        "time": 1689779141000,
        "value": 139.563,
        "_internal_originalTime": 1689779141000
    },
    {
        "time": 1689779142000,
        "value": 139.571,
        "_internal_originalTime": 1689779142000
    },
    {
        "time": 1689779143000,
        "value": 139.569,
        "_internal_originalTime": 1689779143000
    },
    {
        "time": 1689779144000,
        "value": 139.564,
        "_internal_originalTime": 1689779144000
    },
    {
        "time": 1689779145000,
        "value": 139.565,
        "_internal_originalTime": 1689779145000
    },
    {
        "time": 1689779146000,
        "value": 139.562,
        "_internal_originalTime": 1689779146000
    },
    {
        "time": 1689779147000,
        "value": 139.559,
        "_internal_originalTime": 1689779147000
    },
    {
        "time": 1689779149000,
        "value": 139.563,
        "_internal_originalTime": 1689779149000
    },
    {
        "time": 1689779150000,
        "value": 139.56,
        "_internal_originalTime": 1689779150000
    },
    {
        "time": 1689779152000,
        "value": 139.561,
        "_internal_originalTime": 1689779152000
    },
    {
        "time": 1689779153000,
        "value": 139.56,
        "_internal_originalTime": 1689779153000
    },
    {
        "time": 1689779156000,
        "value": 139.561,
        "_internal_originalTime": 1689779156000
    },
    {
        "time": 1689779157000,
        "value": 139.574,
        "_internal_originalTime": 1689779157000
    },
    {
        "time": 1689779158000,
        "value": 139.574,
        "_internal_originalTime": 1689779158000
    },
    {
        "time": 1689779159000,
        "value": 139.578,
        "_internal_originalTime": 1689779159000
    },
    {
        "time": 1689779160000,
        "value": 139.576,
        "_internal_originalTime": 1689779160000
    },
    {
        "time": 1689779161000,
        "value": 139.58,
        "_internal_originalTime": 1689779161000
    },
    {
        "time": 1689779162000,
        "value": 139.578,
        "_internal_originalTime": 1689779162000
    },
    {
        "time": 1689779164000,
        "value": 139.577,
        "_internal_originalTime": 1689779164000
    },
    {
        "time": 1689779166000,
        "value": 139.581,
        "_internal_originalTime": 1689779166000
    },
    {
        "time": 1689779168000,
        "value": 139.57,
        "_internal_originalTime": 1689779168000
    },
    {
        "time": 1689779169000,
        "value": 139.573,
        "_internal_originalTime": 1689779169000
    },
    {
        "time": 1689779170000,
        "value": 139.569,
        "_internal_originalTime": 1689779170000
    },
    {
        "time": 1689779171000,
        "value": 139.563,
        "_internal_originalTime": 1689779171000
    },
    {
        "time": 1689779172000,
        "value": 139.558,
        "_internal_originalTime": 1689779172000
    },
    {
        "time": 1689779173000,
        "value": 139.559,
        "_internal_originalTime": 1689779173000
    },
    {
        "time": 1689779175000,
        "value": 139.562,
        "_internal_originalTime": 1689779175000
    },
    {
        "time": 1689779176000,
        "value": 139.558,
        "_internal_originalTime": 1689779176000
    },
    {
        "time": 1689779177000,
        "value": 139.562,
        "_internal_originalTime": 1689779177000
    },
    {
        "time": 1689779179000,
        "value": 139.558,
        "_internal_originalTime": 1689779179000
    },
    {
        "time": 1689779180000,
        "value": 139.559,
        "_internal_originalTime": 1689779180000
    },
    {
        "time": 1689779181000,
        "value": 139.56,
        "_internal_originalTime": 1689779181000
    },
    {
        "time": 1689779182000,
        "value": 139.558,
        "_internal_originalTime": 1689779182000
    },
    {
        "time": 1689779183000,
        "value": 139.562,
        "_internal_originalTime": 1689779183000
    },
    {
        "time": 1689779184000,
        "value": 139.562,
        "_internal_originalTime": 1689779184000
    },
    {
        "time": 1689779186000,
        "value": 139.562,
        "_internal_originalTime": 1689779186000
    },
    {
        "time": 1689779187000,
        "value": 139.562,
        "_internal_originalTime": 1689779187000
    },
    {
        "time": 1689779188000,
        "value": 139.562,
        "_internal_originalTime": 1689779188000
    },
    {
        "time": 1689779190000,
        "value": 139.558,
        "_internal_originalTime": 1689779190000
    },
    {
        "time": 1689779191000,
        "value": 139.563,
        "_internal_originalTime": 1689779191000
    },
    {
        "time": 1689779192000,
        "value": 139.563,
        "_internal_originalTime": 1689779192000
    },
    {
        "time": 1689779194000,
        "value": 139.566,
        "_internal_originalTime": 1689779194000
    },
    {
        "time": 1689779195000,
        "value": 139.567,
        "_internal_originalTime": 1689779195000
    },
    {
        "time": 1689779196000,
        "value": 139.577,
        "_internal_originalTime": 1689779196000
    },
    {
        "time": 1689779197000,
        "value": 139.573,
        "_internal_originalTime": 1689779197000
    },
    {
        "time": 1689779199000,
        "value": 139.57,
        "_internal_originalTime": 1689779199000
    },
    {
        "time": 1689779200000,
        "value": 139.568,
        "_internal_originalTime": 1689779200000
    },
    {
        "time": 1689779201000,
        "value": 139.555,
        "_internal_originalTime": 1689779201000
    },
    {
        "time": 1689779203000,
        "value": 139.558,
        "_internal_originalTime": 1689779203000
    },
    {
        "time": 1689779204000,
        "value": 139.562,
        "_internal_originalTime": 1689779204000
    },
    {
        "time": 1689779205000,
        "value": 139.564,
        "_internal_originalTime": 1689779205000
    },
    {
        "time": 1689779206000,
        "value": 139.559,
        "_internal_originalTime": 1689779206000
    },
    {
        "time": 1689779207000,
        "value": 139.56,
        "_internal_originalTime": 1689779207000
    },
    {
        "time": 1689779209000,
        "value": 139.565,
        "_internal_originalTime": 1689779209000
    },
    {
        "time": 1689779210000,
        "value": 139.566,
        "_internal_originalTime": 1689779210000
    },
    {
        "time": 1689779211000,
        "value": 139.565,
        "_internal_originalTime": 1689779211000
    },
    {
        "time": 1689779212000,
        "value": 139.569,
        "_internal_originalTime": 1689779212000
    },
    {
        "time": 1689779213000,
        "value": 139.567,
        "_internal_originalTime": 1689779213000
    },
    {
        "time": 1689779215000,
        "value": 139.577,
        "_internal_originalTime": 1689779215000
    },
    {
        "time": 1689779216000,
        "value": 139.578,
        "_internal_originalTime": 1689779216000
    },
    {
        "time": 1689779217000,
        "value": 139.581,
        "_internal_originalTime": 1689779217000
    },
    {
        "time": 1689779218000,
        "value": 139.581,
        "_internal_originalTime": 1689779218000
    },
    {
        "time": 1689779219000,
        "value": 139.582,
        "_internal_originalTime": 1689779219000
    },
    {
        "time": 1689779220000,
        "value": 139.56,
        "_internal_originalTime": 1689779220000
    },
    {
        "time": 1689779221000,
        "value": 139.583,
        "_internal_originalTime": 1689779221000
    },
    {
        "time": 1689779223000,
        "value": 139.565,
        "_internal_originalTime": 1689779223000
    },
    {
        "time": 1689779224000,
        "value": 139.565,
        "_internal_originalTime": 1689779224000
    },
    {
        "time": 1689779225000,
        "value": 139.565,
        "_internal_originalTime": 1689779225000
    },
    {
        "time": 1689779227000,
        "value": 139.564,
        "_internal_originalTime": 1689779227000
    },
    {
        "time": 1689779228000,
        "value": 139.562,
        "_internal_originalTime": 1689779228000
    },
    {
        "time": 1689779230000,
        "value": 139.56,
        "_internal_originalTime": 1689779230000
    },
    {
        "time": 1689779231000,
        "value": 139.565,
        "_internal_originalTime": 1689779231000
    },
    {
        "time": 1689779232000,
        "value": 139.555,
        "_internal_originalTime": 1689779232000
    },
    {
        "time": 1689779233000,
        "value": 139.555,
        "_internal_originalTime": 1689779233000
    },
    {
        "time": 1689779234000,
        "value": 139.563,
        "_internal_originalTime": 1689779234000
    },
    {
        "time": 1689779235000,
        "value": 139.564,
        "_internal_originalTime": 1689779235000
    },
    {
        "time": 1689779237000,
        "value": 139.564,
        "_internal_originalTime": 1689779237000
    },
    {
        "time": 1689779238000,
        "value": 139.569,
        "_internal_originalTime": 1689779238000
    },
    {
        "time": 1689779239000,
        "value": 139.568,
        "_internal_originalTime": 1689779239000
    },
    {
        "time": 1689779240000,
        "value": 139.569,
        "_internal_originalTime": 1689779240000
    },
    {
        "time": 1689779242000,
        "value": 139.568,
        "_internal_originalTime": 1689779242000
    },
    {
        "time": 1689779243000,
        "value": 139.568,
        "_internal_originalTime": 1689779243000
    },
    {
        "time": 1689779244000,
        "value": 139.564,
        "_internal_originalTime": 1689779244000
    },
    {
        "time": 1689779245000,
        "value": 139.564,
        "_internal_originalTime": 1689779245000
    },
    {
        "time": 1689779247000,
        "value": 139.563,
        "_internal_originalTime": 1689779247000
    },
    {
        "time": 1689779248000,
        "value": 139.563,
        "_internal_originalTime": 1689779248000
    },
    {
        "time": 1689779250000,
        "value": 139.563,
        "_internal_originalTime": 1689779250000
    },
    {
        "time": 1689779251000,
        "value": 139.563,
        "_internal_originalTime": 1689779251000
    },
    {
        "time": 1689779252000,
        "value": 139.558,
        "_internal_originalTime": 1689779252000
    },
    {
        "time": 1689779253000,
        "value": 139.56,
        "_internal_originalTime": 1689779253000
    },
    {
        "time": 1689779254000,
        "value": 139.562,
        "_internal_originalTime": 1689779254000
    },
    {
        "time": 1689779257000,
        "value": 139.558,
        "_internal_originalTime": 1689779257000
    },
    {
        "time": 1689779258000,
        "value": 139.563,
        "_internal_originalTime": 1689779258000
    },
    {
        "time": 1689779259000,
        "value": 139.563,
        "_internal_originalTime": 1689779259000
    },
    {
        "time": 1689779260000,
        "value": 139.562,
        "_internal_originalTime": 1689779260000
    },
    {
        "time": 1689779261000,
        "value": 139.563,
        "_internal_originalTime": 1689779261000
    },
    {
        "time": 1689779263000,
        "value": 139.57,
        "_internal_originalTime": 1689779263000
    },
    {
        "time": 1689779264000,
        "value": 139.571,
        "_internal_originalTime": 1689779264000
    },
    {
        "time": 1689779265000,
        "value": 139.573,
        "_internal_originalTime": 1689779265000
    },
    {
        "time": 1689779266000,
        "value": 139.57,
        "_internal_originalTime": 1689779266000
    },
    {
        "time": 1689779267000,
        "value": 139.571,
        "_internal_originalTime": 1689779267000
    },
    {
        "time": 1689779268000,
        "value": 139.57,
        "_internal_originalTime": 1689779268000
    },
    {
        "time": 1689779269000,
        "value": 139.573,
        "_internal_originalTime": 1689779269000
    },
    {
        "time": 1689779271000,
        "value": 139.569,
        "_internal_originalTime": 1689779271000
    },
    {
        "time": 1689779274000,
        "value": 139.578,
        "_internal_originalTime": 1689779274000
    },
    {
        "time": 1689779276000,
        "value": 139.57,
        "_internal_originalTime": 1689779276000
    },
    {
        "time": 1689779277000,
        "value": 139.573,
        "_internal_originalTime": 1689779277000
    },
    {
        "time": 1689779278000,
        "value": 139.573,
        "_internal_originalTime": 1689779278000
    },
    {
        "time": 1689779279000,
        "value": 139.573,
        "_internal_originalTime": 1689779279000
    },
    {
        "time": 1689779280000,
        "value": 139.57,
        "_internal_originalTime": 1689779280000
    },
    {
        "time": 1689779281000,
        "value": 139.576,
        "_internal_originalTime": 1689779281000
    },
    {
        "time": 1689779282000,
        "value": 139.587,
        "_internal_originalTime": 1689779282000
    },
    {
        "time": 1689779283000,
        "value": 139.584,
        "_internal_originalTime": 1689779283000
    },
    {
        "time": 1689779284000,
        "value": 139.591,
        "_internal_originalTime": 1689779284000
    },
    {
        "time": 1689779285000,
        "value": 139.594,
        "_internal_originalTime": 1689779285000
    },
    {
        "time": 1689779287000,
        "value": 139.595,
        "_internal_originalTime": 1689779287000
    },
    {
        "time": 1689779288000,
        "value": 139.597,
        "_internal_originalTime": 1689779288000
    },
    {
        "time": 1689779290000,
        "value": 139.595,
        "_internal_originalTime": 1689779290000
    },
    {
        "time": 1689779291000,
        "value": 139.594,
        "_internal_originalTime": 1689779291000
    },
    {
        "time": 1689779292000,
        "value": 139.605,
        "_internal_originalTime": 1689779292000
    },
    {
        "time": 1689779293000,
        "value": 139.607,
        "_internal_originalTime": 1689779293000
    },
    {
        "time": 1689779294000,
        "value": 139.61,
        "_internal_originalTime": 1689779294000
    },
    {
        "time": 1689779296000,
        "value": 139.612,
        "_internal_originalTime": 1689779296000
    },
    {
        "time": 1689779297000,
        "value": 139.604,
        "_internal_originalTime": 1689779297000
    },
    {
        "time": 1689779299000,
        "value": 139.605,
        "_internal_originalTime": 1689779299000
    },
    {
        "time": 1689779300000,
        "value": 139.602,
        "_internal_originalTime": 1689779300000
    },
    {
        "time": 1689779301000,
        "value": 139.608,
        "_internal_originalTime": 1689779301000
    },
    {
        "time": 1689779302000,
        "value": 139.609,
        "_internal_originalTime": 1689779302000
    },
    {
        "time": 1689779304000,
        "value": 139.606,
        "_internal_originalTime": 1689779304000
    },
    {
        "time": 1689779306000,
        "value": 139.609,
        "_internal_originalTime": 1689779306000
    },
    {
        "time": 1689779307000,
        "value": 139.608,
        "_internal_originalTime": 1689779307000
    },
    {
        "time": 1689779308000,
        "value": 139.604,
        "_internal_originalTime": 1689779308000
    },
    {
        "time": 1689779310000,
        "value": 139.597,
        "_internal_originalTime": 1689779310000
    },
    {
        "time": 1689779311000,
        "value": 139.603,
        "_internal_originalTime": 1689779311000
    },
    {
        "time": 1689779312000,
        "value": 139.603,
        "_internal_originalTime": 1689779312000
    },
    {
        "time": 1689779313000,
        "value": 139.602,
        "_internal_originalTime": 1689779313000
    },
    {
        "time": 1689779315000,
        "value": 139.606,
        "_internal_originalTime": 1689779315000
    },
    {
        "time": 1689779316000,
        "value": 139.61,
        "_internal_originalTime": 1689779316000
    },
    {
        "time": 1689779317000,
        "value": 139.61,
        "_internal_originalTime": 1689779317000
    },
    {
        "time": 1689779318000,
        "value": 139.611,
        "_internal_originalTime": 1689779318000
    },
    {
        "time": 1689779320000,
        "value": 139.603,
        "_internal_originalTime": 1689779320000
    },
    {
        "time": 1689779321000,
        "value": 139.59,
        "_internal_originalTime": 1689779321000
    },
    {
        "time": 1689779323000,
        "value": 139.585,
        "_internal_originalTime": 1689779323000
    },
    {
        "time": 1689779325000,
        "value": 139.588,
        "_internal_originalTime": 1689779325000
    },
    {
        "time": 1689779326000,
        "value": 139.593,
        "_internal_originalTime": 1689779326000
    },
    {
        "time": 1689779328000,
        "value": 139.594,
        "_internal_originalTime": 1689779328000
    },
    {
        "time": 1689779329000,
        "value": 139.589,
        "_internal_originalTime": 1689779329000
    },
    {
        "time": 1689779331000,
        "value": 139.583,
        "_internal_originalTime": 1689779331000
    },
    {
        "time": 1689779332000,
        "value": 139.577,
        "_internal_originalTime": 1689779332000
    },
    {
        "time": 1689779334000,
        "value": 139.575,
        "_internal_originalTime": 1689779334000
    },
    {
        "time": 1689779335000,
        "value": 139.573,
        "_internal_originalTime": 1689779335000
    },
    {
        "time": 1689779336000,
        "value": 139.574,
        "_internal_originalTime": 1689779336000
    },
    {
        "time": 1689779337000,
        "value": 139.574,
        "_internal_originalTime": 1689779337000
    },
    {
        "time": 1689779339000,
        "value": 139.57,
        "_internal_originalTime": 1689779339000
    },
    {
        "time": 1689779340000,
        "value": 139.573,
        "_internal_originalTime": 1689779340000
    },
    {
        "time": 1689779341000,
        "value": 139.564,
        "_internal_originalTime": 1689779341000
    },
    {
        "time": 1689779342000,
        "value": 139.572,
        "_internal_originalTime": 1689779342000
    },
    {
        "time": 1689779344000,
        "value": 139.568,
        "_internal_originalTime": 1689779344000
    },
    {
        "time": 1689779345000,
        "value": 139.574,
        "_internal_originalTime": 1689779345000
    },
    {
        "time": 1689779346000,
        "value": 139.574,
        "_internal_originalTime": 1689779346000
    },
    {
        "time": 1689779347000,
        "value": 139.578,
        "_internal_originalTime": 1689779347000
    },
    {
        "time": 1689779349000,
        "value": 139.577,
        "_internal_originalTime": 1689779349000
    },
    {
        "time": 1689779351000,
        "value": 139.583,
        "_internal_originalTime": 1689779351000
    },
    {
        "time": 1689779352000,
        "value": 139.578,
        "_internal_originalTime": 1689779352000
    },
    {
        "time": 1689779353000,
        "value": 139.588,
        "_internal_originalTime": 1689779353000
    },
    {
        "time": 1689779354000,
        "value": 139.588,
        "_internal_originalTime": 1689779354000
    },
    {
        "time": 1689779355000,
        "value": 139.589,
        "_internal_originalTime": 1689779355000
    },
    {
        "time": 1689779357000,
        "value": 139.591,
        "_internal_originalTime": 1689779357000
    },
    {
        "time": 1689779358000,
        "value": 139.595,
        "_internal_originalTime": 1689779358000
    },
    {
        "time": 1689779359000,
        "value": 139.604,
        "_internal_originalTime": 1689779359000
    },
    {
        "time": 1689779360000,
        "value": 139.6,
        "_internal_originalTime": 1689779360000
    },
    {
        "time": 1689779362000,
        "value": 139.604,
        "_internal_originalTime": 1689779362000
    },
    {
        "time": 1689779363000,
        "value": 139.6,
        "_internal_originalTime": 1689779363000
    },
    {
        "time": 1689779364000,
        "value": 139.604,
        "_internal_originalTime": 1689779364000
    },
    {
        "time": 1689779365000,
        "value": 139.6,
        "_internal_originalTime": 1689779365000
    },
    {
        "time": 1689779366000,
        "value": 139.596,
        "_internal_originalTime": 1689779366000
    },
    {
        "time": 1689779367000,
        "value": 139.601,
        "_internal_originalTime": 1689779367000
    },
    {
        "time": 1689779369000,
        "value": 139.601,
        "_internal_originalTime": 1689779369000
    },
    {
        "time": 1689779371000,
        "value": 139.611,
        "_internal_originalTime": 1689779371000
    },
    {
        "time": 1689779372000,
        "value": 139.608,
        "_internal_originalTime": 1689779372000
    },
    {
        "time": 1689779374000,
        "value": 139.609,
        "_internal_originalTime": 1689779374000
    },
    {
        "time": 1689779375000,
        "value": 139.607,
        "_internal_originalTime": 1689779375000
    },
    {
        "time": 1689779376000,
        "value": 139.606,
        "_internal_originalTime": 1689779376000
    },
    {
        "time": 1689779378000,
        "value": 139.608,
        "_internal_originalTime": 1689779378000
    },
    {
        "time": 1689779379000,
        "value": 139.609,
        "_internal_originalTime": 1689779379000
    },
    {
        "time": 1689779380000,
        "value": 139.615,
        "_internal_originalTime": 1689779380000
    },
    {
        "time": 1689779382000,
        "value": 139.616,
        "_internal_originalTime": 1689779382000
    },
    {
        "time": 1689779383000,
        "value": 139.612,
        "_internal_originalTime": 1689779383000
    },
    {
        "time": 1689779386000,
        "value": 139.62,
        "_internal_originalTime": 1689779386000
    },
    {
        "time": 1689779387000,
        "value": 139.616,
        "_internal_originalTime": 1689779387000
    },
    {
        "time": 1689779388000,
        "value": 139.616,
        "_internal_originalTime": 1689779388000
    },
    {
        "time": 1689779389000,
        "value": 139.616,
        "_internal_originalTime": 1689779389000
    },
    {
        "time": 1689779390000,
        "value": 139.613,
        "_internal_originalTime": 1689779390000
    },
    {
        "time": 1689779391000,
        "value": 139.612,
        "_internal_originalTime": 1689779391000
    },
    {
        "time": 1689779392000,
        "value": 139.613,
        "_internal_originalTime": 1689779392000
    },
    {
        "time": 1689779393000,
        "value": 139.612,
        "_internal_originalTime": 1689779393000
    },
    {
        "time": 1689779395000,
        "value": 139.612,
        "_internal_originalTime": 1689779395000
    },
    {
        "time": 1689779397000,
        "value": 139.607,
        "_internal_originalTime": 1689779397000
    },
    {
        "time": 1689779398000,
        "value": 139.611,
        "_internal_originalTime": 1689779398000
    },
    {
        "time": 1689779400000,
        "value": 139.62,
        "_internal_originalTime": 1689779400000
    },
    {
        "time": 1689779401000,
        "value": 139.614,
        "_internal_originalTime": 1689779401000
    },
    {
        "time": 1689779402000,
        "value": 139.616,
        "_internal_originalTime": 1689779402000
    },
    {
        "time": 1689779403000,
        "value": 139.616,
        "_internal_originalTime": 1689779403000
    },
    {
        "time": 1689779404000,
        "value": 139.623,
        "_internal_originalTime": 1689779404000
    },
    {
        "time": 1689779405000,
        "value": 139.626,
        "_internal_originalTime": 1689779405000
    },
    {
        "time": 1689779406000,
        "value": 139.623,
        "_internal_originalTime": 1689779406000
    },
    {
        "time": 1689779408000,
        "value": 139.626,
        "_internal_originalTime": 1689779408000
    },
    {
        "time": 1689779409000,
        "value": 139.621,
        "_internal_originalTime": 1689779409000
    },
    {
        "time": 1689779411000,
        "value": 139.623,
        "_internal_originalTime": 1689779411000
    },
    {
        "time": 1689779412000,
        "value": 139.633,
        "_internal_originalTime": 1689779412000
    },
    {
        "time": 1689779413000,
        "value": 139.631,
        "_internal_originalTime": 1689779413000
    },
    {
        "time": 1689779414000,
        "value": 139.631,
        "_internal_originalTime": 1689779414000
    },
    {
        "time": 1689779415000,
        "value": 139.626,
        "_internal_originalTime": 1689779415000
    },
    {
        "time": 1689779416000,
        "value": 139.628,
        "_internal_originalTime": 1689779416000
    },
    {
        "time": 1689779417000,
        "value": 139.634,
        "_internal_originalTime": 1689779417000
    },
    {
        "time": 1689779418000,
        "value": 139.632,
        "_internal_originalTime": 1689779418000
    },
    {
        "time": 1689779419000,
        "value": 139.62,
        "_internal_originalTime": 1689779419000
    },
    {
        "time": 1689779420000,
        "value": 139.626,
        "_internal_originalTime": 1689779420000
    },
    {
        "time": 1689779422000,
        "value": 139.62,
        "_internal_originalTime": 1689779422000
    },
    {
        "time": 1689779423000,
        "value": 139.623,
        "_internal_originalTime": 1689779423000
    },
    {
        "time": 1689779424000,
        "value": 139.623,
        "_internal_originalTime": 1689779424000
    },
    {
        "time": 1689779425000,
        "value": 139.622,
        "_internal_originalTime": 1689779425000
    },
    {
        "time": 1689779426000,
        "value": 139.626,
        "_internal_originalTime": 1689779426000
    },
    {
        "time": 1689779427000,
        "value": 139.624,
        "_internal_originalTime": 1689779427000
    },
    {
        "time": 1689779428000,
        "value": 139.625,
        "_internal_originalTime": 1689779428000
    },
    {
        "time": 1689779430000,
        "value": 139.622,
        "_internal_originalTime": 1689779430000
    },
    {
        "time": 1689779431000,
        "value": 139.617,
        "_internal_originalTime": 1689779431000
    },
    {
        "time": 1689779432000,
        "value": 139.62,
        "_internal_originalTime": 1689779432000
    },
    {
        "time": 1689779433000,
        "value": 139.56,
        "_internal_originalTime": 1689779433000
    },
    {
        "time": 1689779435000,
        "value": 139.618,
        "_internal_originalTime": 1689779435000
    },
    {
        "time": 1689779437000,
        "value": 139.633,
        "_internal_originalTime": 1689779437000
    },
    {
        "time": 1689779438000,
        "value": 139.62,
        "_internal_originalTime": 1689779438000
    },
    {
        "time": 1689779439000,
        "value": 139.613,
        "_internal_originalTime": 1689779439000
    },
    {
        "time": 1689779441000,
        "value": 139.606,
        "_internal_originalTime": 1689779441000
    },
    {
        "time": 1689779442000,
        "value": 139.602,
        "_internal_originalTime": 1689779442000
    },
    {
        "time": 1689779443000,
        "value": 139.595,
        "_internal_originalTime": 1689779443000
    },
    {
        "time": 1689779444000,
        "value": 139.602,
        "_internal_originalTime": 1689779444000
    },
    {
        "time": 1689779445000,
        "value": 139.6,
        "_internal_originalTime": 1689779445000
    },
    {
        "time": 1689779446000,
        "value": 139.596,
        "_internal_originalTime": 1689779446000
    },
    {
        "time": 1689779447000,
        "value": 139.596,
        "_internal_originalTime": 1689779447000
    },
    {
        "time": 1689779448000,
        "value": 139.599,
        "_internal_originalTime": 1689779448000
    },
    {
        "time": 1689779449000,
        "value": 139.6,
        "_internal_originalTime": 1689779449000
    },
    {
        "time": 1689779450000,
        "value": 139.602,
        "_internal_originalTime": 1689779450000
    },
    {
        "time": 1689779451000,
        "value": 139.601,
        "_internal_originalTime": 1689779451000
    },
    {
        "time": 1689779452000,
        "value": 139.607,
        "_internal_originalTime": 1689779452000
    },
    {
        "time": 1689779454000,
        "value": 139.62,
        "_internal_originalTime": 1689779454000
    },
    {
        "time": 1689779455000,
        "value": 139.62,
        "_internal_originalTime": 1689779455000
    },
    {
        "time": 1689779456000,
        "value": 139.62,
        "_internal_originalTime": 1689779456000
    },
    {
        "time": 1689779457000,
        "value": 139.616,
        "_internal_originalTime": 1689779457000
    },
    {
        "time": 1689779459000,
        "value": 139.616,
        "_internal_originalTime": 1689779459000
    },
    {
        "time": 1689779461000,
        "value": 139.614,
        "_internal_originalTime": 1689779461000
    },
    {
        "time": 1689779462000,
        "value": 139.614,
        "_internal_originalTime": 1689779462000
    },
    {
        "time": 1689779463000,
        "value": 139.611,
        "_internal_originalTime": 1689779463000
    },
    {
        "time": 1689779464000,
        "value": 139.607,
        "_internal_originalTime": 1689779464000
    },
    {
        "time": 1689779465000,
        "value": 139.606,
        "_internal_originalTime": 1689779465000
    },
    {
        "time": 1689779466000,
        "value": 139.603,
        "_internal_originalTime": 1689779466000
    },
    {
        "time": 1689779468000,
        "value": 139.589,
        "_internal_originalTime": 1689779468000
    },
    {
        "time": 1689779469000,
        "value": 139.584,
        "_internal_originalTime": 1689779469000
    },
    {
        "time": 1689779471000,
        "value": 139.587,
        "_internal_originalTime": 1689779471000
    },
    {
        "time": 1689779472000,
        "value": 139.572,
        "_internal_originalTime": 1689779472000
    },
    {
        "time": 1689779473000,
        "value": 139.559,
        "_internal_originalTime": 1689779473000
    },
    {
        "time": 1689779474000,
        "value": 139.563,
        "_internal_originalTime": 1689779474000
    },
    {
        "time": 1689779475000,
        "value": 139.558,
        "_internal_originalTime": 1689779475000
    },
    {
        "time": 1689779476000,
        "value": 139.558,
        "_internal_originalTime": 1689779476000
    },
    {
        "time": 1689779477000,
        "value": 139.561,
        "_internal_originalTime": 1689779477000
    },
    {
        "time": 1689779478000,
        "value": 139.557,
        "_internal_originalTime": 1689779478000
    },
    {
        "time": 1689779479000,
        "value": 139.56,
        "_internal_originalTime": 1689779479000
    },
    {
        "time": 1689779480000,
        "value": 139.557,
        "_internal_originalTime": 1689779480000
    },
    {
        "time": 1689779481000,
        "value": 139.545,
        "_internal_originalTime": 1689779481000
    },
    {
        "time": 1689779482000,
        "value": 139.54,
        "_internal_originalTime": 1689779482000
    },
    {
        "time": 1689779483000,
        "value": 139.54,
        "_internal_originalTime": 1689779483000
    },
    {
        "time": 1689779484000,
        "value": 139.543,
        "_internal_originalTime": 1689779484000
    },
    {
        "time": 1689779485000,
        "value": 139.544,
        "_internal_originalTime": 1689779485000
    },
    {
        "time": 1689779486000,
        "value": 139.549,
        "_internal_originalTime": 1689779486000
    },
    {
        "time": 1689779487000,
        "value": 139.551,
        "_internal_originalTime": 1689779487000
    },
    {
        "time": 1689779488000,
        "value": 139.544,
        "_internal_originalTime": 1689779488000
    },
    {
        "time": 1689779489000,
        "value": 139.55,
        "_internal_originalTime": 1689779489000
    },
    {
        "time": 1689779490000,
        "value": 139.551,
        "_internal_originalTime": 1689779490000
    },
    {
        "time": 1689779491000,
        "value": 139.542,
        "_internal_originalTime": 1689779491000
    },
    {
        "time": 1689779492000,
        "value": 139.547,
        "_internal_originalTime": 1689779492000
    },
    {
        "time": 1689779493000,
        "value": 139.547,
        "_internal_originalTime": 1689779493000
    },
    {
        "time": 1689779495000,
        "value": 139.546,
        "_internal_originalTime": 1689779495000
    },
    {
        "time": 1689779496000,
        "value": 139.547,
        "_internal_originalTime": 1689779496000
    },
    {
        "time": 1689779497000,
        "value": 139.539,
        "_internal_originalTime": 1689779497000
    },
    {
        "time": 1689779498000,
        "value": 139.543,
        "_internal_originalTime": 1689779498000
    },
    {
        "time": 1689779499000,
        "value": 139.54,
        "_internal_originalTime": 1689779499000
    },
    {
        "time": 1689779501000,
        "value": 139.555,
        "_internal_originalTime": 1689779501000
    },
    {
        "time": 1689779502000,
        "value": 139.559,
        "_internal_originalTime": 1689779502000
    },
    {
        "time": 1689779503000,
        "value": 139.558,
        "_internal_originalTime": 1689779503000
    },
    {
        "time": 1689779505000,
        "value": 139.56,
        "_internal_originalTime": 1689779505000
    },
    {
        "time": 1689779508000,
        "value": 139.565,
        "_internal_originalTime": 1689779508000
    },
    {
        "time": 1689779509000,
        "value": 139.569,
        "_internal_originalTime": 1689779509000
    },
    {
        "time": 1689779510000,
        "value": 139.563,
        "_internal_originalTime": 1689779510000
    },
    {
        "time": 1689779511000,
        "value": 139.558,
        "_internal_originalTime": 1689779511000
    },
    {
        "time": 1689779512000,
        "value": 139.568,
        "_internal_originalTime": 1689779512000
    },
    {
        "time": 1689779513000,
        "value": 139.566,
        "_internal_originalTime": 1689779513000
    },
    {
        "time": 1689779514000,
        "value": 139.564,
        "_internal_originalTime": 1689779514000
    },
    {
        "time": 1689779515000,
        "value": 139.567,
        "_internal_originalTime": 1689779515000
    },
    {
        "time": 1689779516000,
        "value": 139.569,
        "_internal_originalTime": 1689779516000
    },
    {
        "time": 1689779517000,
        "value": 139.568,
        "_internal_originalTime": 1689779517000
    },
    {
        "time": 1689779518000,
        "value": 139.571,
        "_internal_originalTime": 1689779518000
    },
    {
        "time": 1689779519000,
        "value": 139.568,
        "_internal_originalTime": 1689779519000
    },
    {
        "time": 1689779520000,
        "value": 139.561,
        "_internal_originalTime": 1689779520000
    },
    {
        "time": 1689779522000,
        "value": 139.568,
        "_internal_originalTime": 1689779522000
    },
    {
        "time": 1689779523000,
        "value": 139.572,
        "_internal_originalTime": 1689779523000
    },
    {
        "time": 1689779524000,
        "value": 139.573,
        "_internal_originalTime": 1689779524000
    },
    {
        "time": 1689779525000,
        "value": 139.566,
        "_internal_originalTime": 1689779525000
    },
    {
        "time": 1689779527000,
        "value": 139.564,
        "_internal_originalTime": 1689779527000
    },
    {
        "time": 1689779528000,
        "value": 139.545,
        "_internal_originalTime": 1689779528000
    },
    {
        "time": 1689779530000,
        "value": 139.544,
        "_internal_originalTime": 1689779530000
    },
    {
        "time": 1689779531000,
        "value": 139.548,
        "_internal_originalTime": 1689779531000
    },
    {
        "time": 1689779532000,
        "value": 139.549,
        "_internal_originalTime": 1689779532000
    },
    {
        "time": 1689779533000,
        "value": 139.547,
        "_internal_originalTime": 1689779533000
    },
    {
        "time": 1689779535000,
        "value": 139.546,
        "_internal_originalTime": 1689779535000
    },
    {
        "time": 1689779536000,
        "value": 139.547,
        "_internal_originalTime": 1689779536000
    },
    {
        "time": 1689779537000,
        "value": 139.541,
        "_internal_originalTime": 1689779537000
    },
    {
        "time": 1689779538000,
        "value": 139.549,
        "_internal_originalTime": 1689779538000
    },
    {
        "time": 1689779539000,
        "value": 139.551,
        "_internal_originalTime": 1689779539000
    },
    {
        "time": 1689779540000,
        "value": 139.548,
        "_internal_originalTime": 1689779540000
    },
    {
        "time": 1689779541000,
        "value": 139.548,
        "_internal_originalTime": 1689779541000
    },
    {
        "time": 1689779542000,
        "value": 139.553,
        "_internal_originalTime": 1689779542000
    },
    {
        "time": 1689779543000,
        "value": 139.554,
        "_internal_originalTime": 1689779543000
    },
    {
        "time": 1689779544000,
        "value": 139.563,
        "_internal_originalTime": 1689779544000
    },
    {
        "time": 1689779545000,
        "value": 139.563,
        "_internal_originalTime": 1689779545000
    },
    {
        "time": 1689779546000,
        "value": 139.56,
        "_internal_originalTime": 1689779546000
    },
    {
        "time": 1689779547000,
        "value": 139.562,
        "_internal_originalTime": 1689779547000
    },
    {
        "time": 1689779549000,
        "value": 139.569,
        "_internal_originalTime": 1689779549000
    },
    {
        "time": 1689779550000,
        "value": 139.566,
        "_internal_originalTime": 1689779550000
    },
    {
        "time": 1689779551000,
        "value": 139.565,
        "_internal_originalTime": 1689779551000
    },
    {
        "time": 1689779552000,
        "value": 139.564,
        "_internal_originalTime": 1689779552000
    },
    {
        "time": 1689779555000,
        "value": 139.563,
        "_internal_originalTime": 1689779555000
    },
    {
        "time": 1689779556000,
        "value": 139.563,
        "_internal_originalTime": 1689779556000
    },
    {
        "time": 1689779557000,
        "value": 139.561,
        "_internal_originalTime": 1689779557000
    },
    {
        "time": 1689779558000,
        "value": 139.562,
        "_internal_originalTime": 1689779558000
    },
    {
        "time": 1689779559000,
        "value": 139.562,
        "_internal_originalTime": 1689779559000
    },
    {
        "time": 1689779560000,
        "value": 139.563,
        "_internal_originalTime": 1689779560000
    },
    {
        "time": 1689779562000,
        "value": 139.561,
        "_internal_originalTime": 1689779562000
    },
    {
        "time": 1689779563000,
        "value": 139.557,
        "_internal_originalTime": 1689779563000
    },
    {
        "time": 1689779564000,
        "value": 139.552,
        "_internal_originalTime": 1689779564000
    },
    {
        "time": 1689779565000,
        "value": 139.557,
        "_internal_originalTime": 1689779565000
    },
    {
        "time": 1689779566000,
        "value": 139.559,
        "_internal_originalTime": 1689779566000
    },
    {
        "time": 1689779567000,
        "value": 139.557,
        "_internal_originalTime": 1689779567000
    },
    {
        "time": 1689779569000,
        "value": 139.553,
        "_internal_originalTime": 1689779569000
    },
    {
        "time": 1689779570000,
        "value": 139.557,
        "_internal_originalTime": 1689779570000
    },
    {
        "time": 1689779571000,
        "value": 139.556,
        "_internal_originalTime": 1689779571000
    },
    {
        "time": 1689779572000,
        "value": 139.559,
        "_internal_originalTime": 1689779572000
    },
    {
        "time": 1689779575000,
        "value": 139.558,
        "_internal_originalTime": 1689779575000
    },
    {
        "time": 1689779576000,
        "value": 139.569,
        "_internal_originalTime": 1689779576000
    },
    {
        "time": 1689779577000,
        "value": 139.568,
        "_internal_originalTime": 1689779577000
    },
    {
        "time": 1689779579000,
        "value": 139.576,
        "_internal_originalTime": 1689779579000
    },
    {
        "time": 1689779580000,
        "value": 139.585,
        "_internal_originalTime": 1689779580000
    },
    {
        "time": 1689779581000,
        "value": 139.585,
        "_internal_originalTime": 1689779581000
    },
    {
        "time": 1689779583000,
        "value": 139.58,
        "_internal_originalTime": 1689779583000
    },
    {
        "time": 1689779584000,
        "value": 139.585,
        "_internal_originalTime": 1689779584000
    },
    {
        "time": 1689779585000,
        "value": 139.586,
        "_internal_originalTime": 1689779585000
    },
    {
        "time": 1689779586000,
        "value": 139.583,
        "_internal_originalTime": 1689779586000
    },
    {
        "time": 1689779589000,
        "value": 139.589,
        "_internal_originalTime": 1689779589000
    },
    {
        "time": 1689779590000,
        "value": 139.591,
        "_internal_originalTime": 1689779590000
    },
    {
        "time": 1689779591000,
        "value": 139.595,
        "_internal_originalTime": 1689779591000
    },
    {
        "time": 1689779592000,
        "value": 139.595,
        "_internal_originalTime": 1689779592000
    },
    {
        "time": 1689779593000,
        "value": 139.597,
        "_internal_originalTime": 1689779593000
    },
    {
        "time": 1689779594000,
        "value": 139.596,
        "_internal_originalTime": 1689779594000
    },
    {
        "time": 1689779596000,
        "value": 139.592,
        "_internal_originalTime": 1689779596000
    },
    {
        "time": 1689779597000,
        "value": 139.59,
        "_internal_originalTime": 1689779597000
    },
    {
        "time": 1689779599000,
        "value": 139.601,
        "_internal_originalTime": 1689779599000
    },
    {
        "time": 1689779600000,
        "value": 139.596,
        "_internal_originalTime": 1689779600000
    },
    {
        "time": 1689779603000,
        "value": 139.6,
        "_internal_originalTime": 1689779603000
    },
    {
        "time": 1689779604000,
        "value": 139.6,
        "_internal_originalTime": 1689779604000
    },
    {
        "time": 1689779605000,
        "value": 139.595,
        "_internal_originalTime": 1689779605000
    },
    {
        "time": 1689779606000,
        "value": 139.595,
        "_internal_originalTime": 1689779606000
    },
    {
        "time": 1689779607000,
        "value": 139.588,
        "_internal_originalTime": 1689779607000
    },
    {
        "time": 1689779608000,
        "value": 139.589,
        "_internal_originalTime": 1689779608000
    },
    {
        "time": 1689779610000,
        "value": 139.583,
        "_internal_originalTime": 1689779610000
    },
    {
        "time": 1689779611000,
        "value": 139.585,
        "_internal_originalTime": 1689779611000
    },
    {
        "time": 1689779613000,
        "value": 139.583,
        "_internal_originalTime": 1689779613000
    },
    {
        "time": 1689779616000,
        "value": 139.579,
        "_internal_originalTime": 1689779616000
    },
    {
        "time": 1689779617000,
        "value": 139.58,
        "_internal_originalTime": 1689779617000
    },
    {
        "time": 1689779619000,
        "value": 139.581,
        "_internal_originalTime": 1689779619000
    },
    {
        "time": 1689779620000,
        "value": 139.58,
        "_internal_originalTime": 1689779620000
    },
    {
        "time": 1689779621000,
        "value": 139.576,
        "_internal_originalTime": 1689779621000
    },
    {
        "time": 1689779623000,
        "value": 139.574,
        "_internal_originalTime": 1689779623000
    },
    {
        "time": 1689779624000,
        "value": 139.574,
        "_internal_originalTime": 1689779624000
    },
    {
        "time": 1689779625000,
        "value": 139.577,
        "_internal_originalTime": 1689779625000
    },
    {
        "time": 1689779627000,
        "value": 139.578,
        "_internal_originalTime": 1689779627000
    },
    {
        "time": 1689779628000,
        "value": 139.578,
        "_internal_originalTime": 1689779628000
    },
    {
        "time": 1689779629000,
        "value": 139.568,
        "_internal_originalTime": 1689779629000
    },
    {
        "time": 1689779630000,
        "value": 139.572,
        "_internal_originalTime": 1689779630000
    },
    {
        "time": 1689779631000,
        "value": 139.574,
        "_internal_originalTime": 1689779631000
    },
    {
        "time": 1689779633000,
        "value": 139.572,
        "_internal_originalTime": 1689779633000
    },
    {
        "time": 1689779634000,
        "value": 139.579,
        "_internal_originalTime": 1689779634000
    },
    {
        "time": 1689779637000,
        "value": 139.583,
        "_internal_originalTime": 1689779637000
    },
    {
        "time": 1689779638000,
        "value": 139.58,
        "_internal_originalTime": 1689779638000
    },
    {
        "time": 1689779640000,
        "value": 139.574,
        "_internal_originalTime": 1689779640000
    },
    {
        "time": 1689779641000,
        "value": 139.572,
        "_internal_originalTime": 1689779641000
    },
    {
        "time": 1689779642000,
        "value": 139.571,
        "_internal_originalTime": 1689779642000
    },
    {
        "time": 1689779643000,
        "value": 139.565,
        "_internal_originalTime": 1689779643000
    },
    {
        "time": 1689779644000,
        "value": 139.558,
        "_internal_originalTime": 1689779644000
    },
    {
        "time": 1689779645000,
        "value": 139.556,
        "_internal_originalTime": 1689779645000
    },
    {
        "time": 1689779646000,
        "value": 139.558,
        "_internal_originalTime": 1689779646000
    },
    {
        "time": 1689779648000,
        "value": 139.56,
        "_internal_originalTime": 1689779648000
    },
    {
        "time": 1689779649000,
        "value": 139.562,
        "_internal_originalTime": 1689779649000
    },
    {
        "time": 1689779650000,
        "value": 139.566,
        "_internal_originalTime": 1689779650000
    },
    {
        "time": 1689779651000,
        "value": 139.563,
        "_internal_originalTime": 1689779651000
    },
    {
        "time": 1689779652000,
        "value": 139.567,
        "_internal_originalTime": 1689779652000
    },
    {
        "time": 1689779653000,
        "value": 139.562,
        "_internal_originalTime": 1689779653000
    },
    {
        "time": 1689779655000,
        "value": 139.564,
        "_internal_originalTime": 1689779655000
    },
    {
        "time": 1689779656000,
        "value": 139.561,
        "_internal_originalTime": 1689779656000
    },
    {
        "time": 1689779657000,
        "value": 139.565,
        "_internal_originalTime": 1689779657000
    },
    {
        "time": 1689779659000,
        "value": 139.573,
        "_internal_originalTime": 1689779659000
    },
    {
        "time": 1689779661000,
        "value": 139.582,
        "_internal_originalTime": 1689779661000
    },
    {
        "time": 1689779662000,
        "value": 139.582,
        "_internal_originalTime": 1689779662000
    },
    {
        "time": 1689779663000,
        "value": 139.573,
        "_internal_originalTime": 1689779663000
    },
    {
        "time": 1689779665000,
        "value": 139.568,
        "_internal_originalTime": 1689779665000
    },
    {
        "time": 1689779666000,
        "value": 139.57,
        "_internal_originalTime": 1689779666000
    },
    {
        "time": 1689779667000,
        "value": 139.564,
        "_internal_originalTime": 1689779667000
    },
    {
        "time": 1689779668000,
        "value": 139.564,
        "_internal_originalTime": 1689779668000
    },
    {
        "time": 1689779670000,
        "value": 139.568,
        "_internal_originalTime": 1689779670000
    },
    {
        "time": 1689779671000,
        "value": 139.562,
        "_internal_originalTime": 1689779671000
    },
    {
        "time": 1689779673000,
        "value": 139.566,
        "_internal_originalTime": 1689779673000
    },
    {
        "time": 1689779674000,
        "value": 139.562,
        "_internal_originalTime": 1689779674000
    },
    {
        "time": 1689779675000,
        "value": 139.567,
        "_internal_originalTime": 1689779675000
    },
    {
        "time": 1689779676000,
        "value": 139.56,
        "_internal_originalTime": 1689779676000
    },
    {
        "time": 1689779677000,
        "value": 139.564,
        "_internal_originalTime": 1689779677000
    },
    {
        "time": 1689779678000,
        "value": 139.563,
        "_internal_originalTime": 1689779678000
    },
    {
        "time": 1689779679000,
        "value": 139.565,
        "_internal_originalTime": 1689779679000
    },
    {
        "time": 1689779680000,
        "value": 139.562,
        "_internal_originalTime": 1689779680000
    },
    {
        "time": 1689779681000,
        "value": 139.56,
        "_internal_originalTime": 1689779681000
    },
    {
        "time": 1689779683000,
        "value": 139.558,
        "_internal_originalTime": 1689779683000
    },
    {
        "time": 1689779685000,
        "value": 139.562,
        "_internal_originalTime": 1689779685000
    },
    {
        "time": 1689779686000,
        "value": 139.564,
        "_internal_originalTime": 1689779686000
    },
    {
        "time": 1689779687000,
        "value": 139.57,
        "_internal_originalTime": 1689779687000
    },
    {
        "time": 1689779688000,
        "value": 139.589,
        "_internal_originalTime": 1689779688000
    },
    {
        "time": 1689779689000,
        "value": 139.59,
        "_internal_originalTime": 1689779689000
    },
    {
        "time": 1689779690000,
        "value": 139.592,
        "_internal_originalTime": 1689779690000
    },
    {
        "time": 1689779691000,
        "value": 139.59,
        "_internal_originalTime": 1689779691000
    },
    {
        "time": 1689779693000,
        "value": 139.59,
        "_internal_originalTime": 1689779693000
    },
    {
        "time": 1689779694000,
        "value": 139.595,
        "_internal_originalTime": 1689779694000
    },
    {
        "time": 1689779695000,
        "value": 139.596,
        "_internal_originalTime": 1689779695000
    },
    {
        "time": 1689779696000,
        "value": 139.596,
        "_internal_originalTime": 1689779696000
    },
    {
        "time": 1689779697000,
        "value": 139.594,
        "_internal_originalTime": 1689779697000
    },
    {
        "time": 1689779699000,
        "value": 139.599,
        "_internal_originalTime": 1689779699000
    },
    {
        "time": 1689779700000,
        "value": 139.599,
        "_internal_originalTime": 1689779700000
    },
    {
        "time": 1689779701000,
        "value": 139.59,
        "_internal_originalTime": 1689779701000
    },
    {
        "time": 1689779702000,
        "value": 139.589,
        "_internal_originalTime": 1689779702000
    },
    {
        "time": 1689779704000,
        "value": 139.589,
        "_internal_originalTime": 1689779704000
    },
    {
        "time": 1689779705000,
        "value": 139.59,
        "_internal_originalTime": 1689779705000
    },
    {
        "time": 1689779706000,
        "value": 139.59,
        "_internal_originalTime": 1689779706000
    },
    {
        "time": 1689779708000,
        "value": 139.582,
        "_internal_originalTime": 1689779708000
    },
    {
        "time": 1689779709000,
        "value": 139.579,
        "_internal_originalTime": 1689779709000
    },
    {
        "time": 1689779710000,
        "value": 139.583,
        "_internal_originalTime": 1689779710000
    },
    {
        "time": 1689779711000,
        "value": 139.581,
        "_internal_originalTime": 1689779711000
    },
    {
        "time": 1689779712000,
        "value": 139.581,
        "_internal_originalTime": 1689779712000
    },
    {
        "time": 1689779714000,
        "value": 139.585,
        "_internal_originalTime": 1689779714000
    },
    {
        "time": 1689779715000,
        "value": 139.585,
        "_internal_originalTime": 1689779715000
    },
    {
        "time": 1689779716000,
        "value": 139.583,
        "_internal_originalTime": 1689779716000
    },
    {
        "time": 1689779718000,
        "value": 139.587,
        "_internal_originalTime": 1689779718000
    },
    {
        "time": 1689779720000,
        "value": 139.587,
        "_internal_originalTime": 1689779720000
    },
    {
        "time": 1689779721000,
        "value": 139.59,
        "_internal_originalTime": 1689779721000
    },
    {
        "time": 1689779722000,
        "value": 139.589,
        "_internal_originalTime": 1689779722000
    },
    {
        "time": 1689779724000,
        "value": 139.595,
        "_internal_originalTime": 1689779724000
    },
    {
        "time": 1689779725000,
        "value": 139.582,
        "_internal_originalTime": 1689779725000
    },
    {
        "time": 1689779726000,
        "value": 139.581,
        "_internal_originalTime": 1689779726000
    },
    {
        "time": 1689779727000,
        "value": 139.578,
        "_internal_originalTime": 1689779727000
    },
    {
        "time": 1689779729000,
        "value": 139.578,
        "_internal_originalTime": 1689779729000
    },
    {
        "time": 1689779730000,
        "value": 139.558,
        "_internal_originalTime": 1689779730000
    },
    {
        "time": 1689779731000,
        "value": 139.568,
        "_internal_originalTime": 1689779731000
    },
    {
        "time": 1689779732000,
        "value": 139.562,
        "_internal_originalTime": 1689779732000
    },
    {
        "time": 1689779733000,
        "value": 139.566,
        "_internal_originalTime": 1689779733000
    },
    {
        "time": 1689779734000,
        "value": 139.567,
        "_internal_originalTime": 1689779734000
    },
    {
        "time": 1689779735000,
        "value": 139.568,
        "_internal_originalTime": 1689779735000
    },
    {
        "time": 1689779736000,
        "value": 139.571,
        "_internal_originalTime": 1689779736000
    },
    {
        "time": 1689779737000,
        "value": 139.573,
        "_internal_originalTime": 1689779737000
    },
    {
        "time": 1689779738000,
        "value": 139.569,
        "_internal_originalTime": 1689779738000
    },
    {
        "time": 1689779739000,
        "value": 139.572,
        "_internal_originalTime": 1689779739000
    },
    {
        "time": 1689779740000,
        "value": 139.572,
        "_internal_originalTime": 1689779740000
    },
    {
        "time": 1689779742000,
        "value": 139.573,
        "_internal_originalTime": 1689779742000
    },
    {
        "time": 1689779743000,
        "value": 139.567,
        "_internal_originalTime": 1689779743000
    },
    {
        "time": 1689779745000,
        "value": 139.572,
        "_internal_originalTime": 1689779745000
    },
    {
        "time": 1689779746000,
        "value": 139.565,
        "_internal_originalTime": 1689779746000
    },
    {
        "time": 1689779748000,
        "value": 139.565,
        "_internal_originalTime": 1689779748000
    },
    {
        "time": 1689779749000,
        "value": 139.565,
        "_internal_originalTime": 1689779749000
    },
    {
        "time": 1689779751000,
        "value": 139.562,
        "_internal_originalTime": 1689779751000
    },
    {
        "time": 1689779753000,
        "value": 139.576,
        "_internal_originalTime": 1689779753000
    },
    {
        "time": 1689779754000,
        "value": 139.56,
        "_internal_originalTime": 1689779754000
    },
    {
        "time": 1689779757000,
        "value": 139.569,
        "_internal_originalTime": 1689779757000
    },
    {
        "time": 1689779759000,
        "value": 139.576,
        "_internal_originalTime": 1689779759000
    },
    {
        "time": 1689779760000,
        "value": 139.583,
        "_internal_originalTime": 1689779760000
    },
    {
        "time": 1689779761000,
        "value": 139.583,
        "_internal_originalTime": 1689779761000
    },
    {
        "time": 1689779762000,
        "value": 139.57,
        "_internal_originalTime": 1689779762000
    },
    {
        "time": 1689779763000,
        "value": 139.571,
        "_internal_originalTime": 1689779763000
    },
    {
        "time": 1689779764000,
        "value": 139.569,
        "_internal_originalTime": 1689779764000
    },
    {
        "time": 1689779765000,
        "value": 139.572,
        "_internal_originalTime": 1689779765000
    },
    {
        "time": 1689779766000,
        "value": 139.572,
        "_internal_originalTime": 1689779766000
    },
    {
        "time": 1689779768000,
        "value": 139.572,
        "_internal_originalTime": 1689779768000
    },
    {
        "time": 1689779770000,
        "value": 139.57,
        "_internal_originalTime": 1689779770000
    },
    {
        "time": 1689779771000,
        "value": 139.571,
        "_internal_originalTime": 1689779771000
    },
    {
        "time": 1689779772000,
        "value": 139.569,
        "_internal_originalTime": 1689779772000
    },
    {
        "time": 1689779773000,
        "value": 139.565,
        "_internal_originalTime": 1689779773000
    },
    {
        "time": 1689779775000,
        "value": 139.568,
        "_internal_originalTime": 1689779775000
    },
    {
        "time": 1689779776000,
        "value": 139.568,
        "_internal_originalTime": 1689779776000
    },
    {
        "time": 1689779777000,
        "value": 139.572,
        "_internal_originalTime": 1689779777000
    },
    {
        "time": 1689779778000,
        "value": 139.577,
        "_internal_originalTime": 1689779778000
    },
    {
        "time": 1689779779000,
        "value": 139.58,
        "_internal_originalTime": 1689779779000
    },
    {
        "time": 1689779780000,
        "value": 139.58,
        "_internal_originalTime": 1689779780000
    },
    {
        "time": 1689779782000,
        "value": 139.592,
        "_internal_originalTime": 1689779782000
    },
    {
        "time": 1689779783000,
        "value": 139.592,
        "_internal_originalTime": 1689779783000
    },
    {
        "time": 1689779784000,
        "value": 139.596,
        "_internal_originalTime": 1689779784000
    },
    {
        "time": 1689779785000,
        "value": 139.593,
        "_internal_originalTime": 1689779785000
    },
    {
        "time": 1689779786000,
        "value": 139.591,
        "_internal_originalTime": 1689779786000
    },
    {
        "time": 1689779787000,
        "value": 139.593,
        "_internal_originalTime": 1689779787000
    },
    {
        "time": 1689779788000,
        "value": 139.594,
        "_internal_originalTime": 1689779788000
    },
    {
        "time": 1689779789000,
        "value": 139.593,
        "_internal_originalTime": 1689779789000
    },
    {
        "time": 1689779794000,
        "value": 139.592,
        "_internal_originalTime": 1689779794000
    },
    {
        "time": 1689779795000,
        "value": 139.589,
        "_internal_originalTime": 1689779795000
    },
    {
        "time": 1689779797000,
        "value": 139.589,
        "_internal_originalTime": 1689779797000
    },
    {
        "time": 1689779798000,
        "value": 139.595,
        "_internal_originalTime": 1689779798000
    },
    {
        "time": 1689779799000,
        "value": 139.589,
        "_internal_originalTime": 1689779799000
    },
    {
        "time": 1689779800000,
        "value": 139.59,
        "_internal_originalTime": 1689779800000
    },
    {
        "time": 1689779802000,
        "value": 139.592,
        "_internal_originalTime": 1689779802000
    },
    {
        "time": 1689779803000,
        "value": 139.588,
        "_internal_originalTime": 1689779803000
    },
    {
        "time": 1689779804000,
        "value": 139.588,
        "_internal_originalTime": 1689779804000
    },
    {
        "time": 1689779805000,
        "value": 139.585,
        "_internal_originalTime": 1689779805000
    },
    {
        "time": 1689779806000,
        "value": 139.601,
        "_internal_originalTime": 1689779806000
    },
    {
        "time": 1689779808000,
        "value": 139.608,
        "_internal_originalTime": 1689779808000
    },
    {
        "time": 1689779809000,
        "value": 139.604,
        "_internal_originalTime": 1689779809000
    },
    {
        "time": 1689779811000,
        "value": 139.6,
        "_internal_originalTime": 1689779811000
    },
    {
        "time": 1689779812000,
        "value": 139.606,
        "_internal_originalTime": 1689779812000
    },
    {
        "time": 1689779814000,
        "value": 139.607,
        "_internal_originalTime": 1689779814000
    },
    {
        "time": 1689779815000,
        "value": 139.612,
        "_internal_originalTime": 1689779815000
    },
    {
        "time": 1689779816000,
        "value": 139.611,
        "_internal_originalTime": 1689779816000
    },
    {
        "time": 1689779817000,
        "value": 139.611,
        "_internal_originalTime": 1689779817000
    },
    {
        "time": 1689779819000,
        "value": 139.617,
        "_internal_originalTime": 1689779819000
    },
    {
        "time": 1689779820000,
        "value": 139.628,
        "_internal_originalTime": 1689779820000
    },
    {
        "time": 1689779821000,
        "value": 139.63,
        "_internal_originalTime": 1689779821000
    },
    {
        "time": 1689779822000,
        "value": 139.628,
        "_internal_originalTime": 1689779822000
    },
    {
        "time": 1689779824000,
        "value": 139.628,
        "_internal_originalTime": 1689779824000
    },
    {
        "time": 1689779825000,
        "value": 139.628,
        "_internal_originalTime": 1689779825000
    },
    {
        "time": 1689779826000,
        "value": 139.627,
        "_internal_originalTime": 1689779826000
    },
    {
        "time": 1689779827000,
        "value": 139.629,
        "_internal_originalTime": 1689779827000
    },
    {
        "time": 1689779828000,
        "value": 139.625,
        "_internal_originalTime": 1689779828000
    },
    {
        "time": 1689779830000,
        "value": 139.628,
        "_internal_originalTime": 1689779830000
    },
    {
        "time": 1689779831000,
        "value": 139.629,
        "_internal_originalTime": 1689779831000
    },
    {
        "time": 1689779832000,
        "value": 139.624,
        "_internal_originalTime": 1689779832000
    },
    {
        "time": 1689779833000,
        "value": 139.622,
        "_internal_originalTime": 1689779833000
    },
    {
        "time": 1689779835000,
        "value": 139.618,
        "_internal_originalTime": 1689779835000
    },
    {
        "time": 1689779836000,
        "value": 139.622,
        "_internal_originalTime": 1689779836000
    },
    {
        "time": 1689779837000,
        "value": 139.62,
        "_internal_originalTime": 1689779837000
    },
    {
        "time": 1689779838000,
        "value": 139.621,
        "_internal_originalTime": 1689779838000
    },
    {
        "time": 1689779840000,
        "value": 139.619,
        "_internal_originalTime": 1689779840000
    },
    {
        "time": 1689779842000,
        "value": 139.62,
        "_internal_originalTime": 1689779842000
    },
    {
        "time": 1689779843000,
        "value": 139.615,
        "_internal_originalTime": 1689779843000
    },
    {
        "time": 1689779844000,
        "value": 139.612,
        "_internal_originalTime": 1689779844000
    },
    {
        "time": 1689779845000,
        "value": 139.612,
        "_internal_originalTime": 1689779845000
    },
    {
        "time": 1689779847000,
        "value": 139.611,
        "_internal_originalTime": 1689779847000
    },
    {
        "time": 1689779848000,
        "value": 139.607,
        "_internal_originalTime": 1689779848000
    },
    {
        "time": 1689779850000,
        "value": 139.611,
        "_internal_originalTime": 1689779850000
    },
    {
        "time": 1689779851000,
        "value": 139.607,
        "_internal_originalTime": 1689779851000
    },
    {
        "time": 1689779852000,
        "value": 139.61,
        "_internal_originalTime": 1689779852000
    },
    {
        "time": 1689779853000,
        "value": 139.606,
        "_internal_originalTime": 1689779853000
    },
    {
        "time": 1689779854000,
        "value": 139.611,
        "_internal_originalTime": 1689779854000
    },
    {
        "time": 1689779855000,
        "value": 139.605,
        "_internal_originalTime": 1689779855000
    },
    {
        "time": 1689779856000,
        "value": 139.598,
        "_internal_originalTime": 1689779856000
    },
    {
        "time": 1689779857000,
        "value": 139.599,
        "_internal_originalTime": 1689779857000
    },
    {
        "time": 1689779858000,
        "value": 139.602,
        "_internal_originalTime": 1689779858000
    },
    {
        "time": 1689779860000,
        "value": 139.596,
        "_internal_originalTime": 1689779860000
    },
    {
        "time": 1689779861000,
        "value": 139.606,
        "_internal_originalTime": 1689779861000
    },
    {
        "time": 1689779863000,
        "value": 139.611,
        "_internal_originalTime": 1689779863000
    },
    {
        "time": 1689779864000,
        "value": 139.615,
        "_internal_originalTime": 1689779864000
    },
    {
        "time": 1689779865000,
        "value": 139.612,
        "_internal_originalTime": 1689779865000
    },
    {
        "time": 1689779866000,
        "value": 139.615,
        "_internal_originalTime": 1689779866000
    },
    {
        "time": 1689779867000,
        "value": 139.618,
        "_internal_originalTime": 1689779867000
    },
    {
        "time": 1689779868000,
        "value": 139.62,
        "_internal_originalTime": 1689779868000
    },
    {
        "time": 1689779870000,
        "value": 139.619,
        "_internal_originalTime": 1689779870000
    },
    {
        "time": 1689779871000,
        "value": 139.619,
        "_internal_originalTime": 1689779871000
    },
    {
        "time": 1689779872000,
        "value": 139.616,
        "_internal_originalTime": 1689779872000
    },
    {
        "time": 1689779874000,
        "value": 139.626,
        "_internal_originalTime": 1689779874000
    },
    {
        "time": 1689779875000,
        "value": 139.624,
        "_internal_originalTime": 1689779875000
    },
    {
        "time": 1689779877000,
        "value": 139.628,
        "_internal_originalTime": 1689779877000
    },
    {
        "time": 1689779878000,
        "value": 139.633,
        "_internal_originalTime": 1689779878000
    },
    {
        "time": 1689779879000,
        "value": 139.629,
        "_internal_originalTime": 1689779879000
    },
    {
        "time": 1689779880000,
        "value": 139.632,
        "_internal_originalTime": 1689779880000
    },
    {
        "time": 1689779881000,
        "value": 139.631,
        "_internal_originalTime": 1689779881000
    },
    {
        "time": 1689779882000,
        "value": 139.627,
        "_internal_originalTime": 1689779882000
    },
    {
        "time": 1689779883000,
        "value": 139.627,
        "_internal_originalTime": 1689779883000
    },
    {
        "time": 1689779884000,
        "value": 139.629,
        "_internal_originalTime": 1689779884000
    },
    {
        "time": 1689779885000,
        "value": 139.626,
        "_internal_originalTime": 1689779885000
    },
    {
        "time": 1689779886000,
        "value": 139.626,
        "_internal_originalTime": 1689779886000
    },
    {
        "time": 1689779887000,
        "value": 139.631,
        "_internal_originalTime": 1689779887000
    },
    {
        "time": 1689779888000,
        "value": 139.632,
        "_internal_originalTime": 1689779888000
    },
    {
        "time": 1689779889000,
        "value": 139.63,
        "_internal_originalTime": 1689779889000
    },
    {
        "time": 1689779890000,
        "value": 139.63,
        "_internal_originalTime": 1689779890000
    },
    {
        "time": 1689779891000,
        "value": 139.629,
        "_internal_originalTime": 1689779891000
    },
    {
        "time": 1689779892000,
        "value": 139.629,
        "_internal_originalTime": 1689779892000
    },
    {
        "time": 1689779894000,
        "value": 139.625,
        "_internal_originalTime": 1689779894000
    },
    {
        "time": 1689779895000,
        "value": 139.628,
        "_internal_originalTime": 1689779895000
    },
    {
        "time": 1689779897000,
        "value": 139.627,
        "_internal_originalTime": 1689779897000
    },
    {
        "time": 1689779898000,
        "value": 139.63,
        "_internal_originalTime": 1689779898000
    },
    {
        "time": 1689779900000,
        "value": 139.63,
        "_internal_originalTime": 1689779900000
    },
    {
        "time": 1689779901000,
        "value": 139.636,
        "_internal_originalTime": 1689779901000
    },
    {
        "time": 1689779902000,
        "value": 139.639,
        "_internal_originalTime": 1689779902000
    },
    {
        "time": 1689779903000,
        "value": 139.637,
        "_internal_originalTime": 1689779903000
    },
    {
        "time": 1689779904000,
        "value": 139.644,
        "_internal_originalTime": 1689779904000
    },
    {
        "time": 1689779905000,
        "value": 139.648,
        "_internal_originalTime": 1689779905000
    },
    {
        "time": 1689779906000,
        "value": 139.648,
        "_internal_originalTime": 1689779906000
    },
    {
        "time": 1689779907000,
        "value": 139.646,
        "_internal_originalTime": 1689779907000
    },
    {
        "time": 1689779908000,
        "value": 139.649,
        "_internal_originalTime": 1689779908000
    },
    {
        "time": 1689779910000,
        "value": 139.65,
        "_internal_originalTime": 1689779910000
    },
    {
        "time": 1689779912000,
        "value": 139.648,
        "_internal_originalTime": 1689779912000
    },
    {
        "time": 1689779913000,
        "value": 139.658,
        "_internal_originalTime": 1689779913000
    },
    {
        "time": 1689779914000,
        "value": 139.665,
        "_internal_originalTime": 1689779914000
    },
    {
        "time": 1689779915000,
        "value": 139.668,
        "_internal_originalTime": 1689779915000
    },
    {
        "time": 1689779916000,
        "value": 139.662,
        "_internal_originalTime": 1689779916000
    },
    {
        "time": 1689779917000,
        "value": 139.665,
        "_internal_originalTime": 1689779917000
    },
    {
        "time": 1689779918000,
        "value": 139.662,
        "_internal_originalTime": 1689779918000
    },
    {
        "time": 1689779919000,
        "value": 139.66,
        "_internal_originalTime": 1689779919000
    },
    {
        "time": 1689779920000,
        "value": 139.661,
        "_internal_originalTime": 1689779920000
    },
    {
        "time": 1689779921000,
        "value": 139.66,
        "_internal_originalTime": 1689779921000
    },
    {
        "time": 1689779922000,
        "value": 139.656,
        "_internal_originalTime": 1689779922000
    },
    {
        "time": 1689779923000,
        "value": 139.65,
        "_internal_originalTime": 1689779923000
    },
    {
        "time": 1689779924000,
        "value": 139.656,
        "_internal_originalTime": 1689779924000
    },
    {
        "time": 1689779925000,
        "value": 139.652,
        "_internal_originalTime": 1689779925000
    },
    {
        "time": 1689779926000,
        "value": 139.647,
        "_internal_originalTime": 1689779926000
    },
    {
        "time": 1689779928000,
        "value": 139.651,
        "_internal_originalTime": 1689779928000
    },
    {
        "time": 1689779930000,
        "value": 139.651,
        "_internal_originalTime": 1689779930000
    },
    {
        "time": 1689779932000,
        "value": 139.65,
        "_internal_originalTime": 1689779932000
    },
    {
        "time": 1689779933000,
        "value": 139.648,
        "_internal_originalTime": 1689779933000
    },
    {
        "time": 1689779934000,
        "value": 139.645,
        "_internal_originalTime": 1689779934000
    },
    {
        "time": 1689779935000,
        "value": 139.641,
        "_internal_originalTime": 1689779935000
    },
    {
        "time": 1689779936000,
        "value": 139.636,
        "_internal_originalTime": 1689779936000
    },
    {
        "time": 1689779938000,
        "value": 139.641,
        "_internal_originalTime": 1689779938000
    },
    {
        "time": 1689779939000,
        "value": 139.641,
        "_internal_originalTime": 1689779939000
    },
    {
        "time": 1689779940000,
        "value": 139.642,
        "_internal_originalTime": 1689779940000
    },
    {
        "time": 1689779941000,
        "value": 139.64,
        "_internal_originalTime": 1689779941000
    },
    {
        "time": 1689779942000,
        "value": 139.64,
        "_internal_originalTime": 1689779942000
    },
    {
        "time": 1689779943000,
        "value": 139.639,
        "_internal_originalTime": 1689779943000
    },
    {
        "time": 1689779944000,
        "value": 139.639,
        "_internal_originalTime": 1689779944000
    },
    {
        "time": 1689779945000,
        "value": 139.639,
        "_internal_originalTime": 1689779945000
    },
    {
        "time": 1689779947000,
        "value": 139.642,
        "_internal_originalTime": 1689779947000
    },
    {
        "time": 1689779948000,
        "value": 139.642,
        "_internal_originalTime": 1689779948000
    },
    {
        "time": 1689779949000,
        "value": 139.644,
        "_internal_originalTime": 1689779949000
    },
    {
        "time": 1689779950000,
        "value": 139.636,
        "_internal_originalTime": 1689779950000
    },
    {
        "time": 1689779951000,
        "value": 139.632,
        "_internal_originalTime": 1689779951000
    },
    {
        "time": 1689779952000,
        "value": 139.633,
        "_internal_originalTime": 1689779952000
    },
    {
        "time": 1689779954000,
        "value": 139.63,
        "_internal_originalTime": 1689779954000
    },
    {
        "time": 1689779955000,
        "value": 139.633,
        "_internal_originalTime": 1689779955000
    },
    {
        "time": 1689779957000,
        "value": 139.634,
        "_internal_originalTime": 1689779957000
    },
    {
        "time": 1689779959000,
        "value": 139.63,
        "_internal_originalTime": 1689779959000
    },
    {
        "time": 1689779960000,
        "value": 139.635,
        "_internal_originalTime": 1689779960000
    },
    {
        "time": 1689779961000,
        "value": 139.633,
        "_internal_originalTime": 1689779961000
    },
    {
        "time": 1689779962000,
        "value": 139.637,
        "_internal_originalTime": 1689779962000
    },
    {
        "time": 1689779963000,
        "value": 139.637,
        "_internal_originalTime": 1689779963000
    },
    {
        "time": 1689779964000,
        "value": 139.628,
        "_internal_originalTime": 1689779964000
    },
    {
        "time": 1689779965000,
        "value": 139.634,
        "_internal_originalTime": 1689779965000
    },
    {
        "time": 1689779967000,
        "value": 139.632,
        "_internal_originalTime": 1689779967000
    },
    {
        "time": 1689779968000,
        "value": 139.638,
        "_internal_originalTime": 1689779968000
    },
    {
        "time": 1689779969000,
        "value": 139.637,
        "_internal_originalTime": 1689779969000
    },
    {
        "time": 1689779970000,
        "value": 139.636,
        "_internal_originalTime": 1689779970000
    },
    {
        "time": 1689779971000,
        "value": 139.637,
        "_internal_originalTime": 1689779971000
    },
    {
        "time": 1689779972000,
        "value": 139.636,
        "_internal_originalTime": 1689779972000
    },
    {
        "time": 1689779974000,
        "value": 139.64,
        "_internal_originalTime": 1689779974000
    },
    {
        "time": 1689779975000,
        "value": 139.66,
        "_internal_originalTime": 1689779975000
    },
    {
        "time": 1689779978000,
        "value": 139.657,
        "_internal_originalTime": 1689779978000
    },
    {
        "time": 1689779979000,
        "value": 139.65,
        "_internal_originalTime": 1689779979000
    },
    {
        "time": 1689779981000,
        "value": 139.655,
        "_internal_originalTime": 1689779981000
    },
    {
        "time": 1689779982000,
        "value": 139.657,
        "_internal_originalTime": 1689779982000
    },
    {
        "time": 1689779983000,
        "value": 139.655,
        "_internal_originalTime": 1689779983000
    },
    {
        "time": 1689779985000,
        "value": 139.655,
        "_internal_originalTime": 1689779985000
    },
    {
        "time": 1689779986000,
        "value": 139.652,
        "_internal_originalTime": 1689779986000
    },
    {
        "time": 1689779988000,
        "value": 139.656,
        "_internal_originalTime": 1689779988000
    },
    {
        "time": 1689779990000,
        "value": 139.648,
        "_internal_originalTime": 1689779990000
    },
    {
        "time": 1689779991000,
        "value": 139.649,
        "_internal_originalTime": 1689779991000
    },
    {
        "time": 1689779992000,
        "value": 139.646,
        "_internal_originalTime": 1689779992000
    },
    {
        "time": 1689779993000,
        "value": 139.65,
        "_internal_originalTime": 1689779993000
    },
    {
        "time": 1689779995000,
        "value": 139.65,
        "_internal_originalTime": 1689779995000
    },
    {
        "time": 1689779996000,
        "value": 139.65,
        "_internal_originalTime": 1689779996000
    },
    {
        "time": 1689779997000,
        "value": 139.651,
        "_internal_originalTime": 1689779997000
    },
    {
        "time": 1689779998000,
        "value": 139.65,
        "_internal_originalTime": 1689779998000
    },
    {
        "time": 1689779999000,
        "value": 139.65,
        "_internal_originalTime": 1689779999000
    },
    {
        "time": 1689780000000,
        "value": 139.646,
        "_internal_originalTime": 1689780000000
    },
    {
        "time": 1689780002000,
        "value": 139.649,
        "_internal_originalTime": 1689780002000
    },
    {
        "time": 1689780003000,
        "value": 139.66,
        "_internal_originalTime": 1689780003000
    },
    {
        "time": 1689780004000,
        "value": 139.656,
        "_internal_originalTime": 1689780004000
    },
    {
        "time": 1689780005000,
        "value": 139.66,
        "_internal_originalTime": 1689780005000
    },
    {
        "time": 1689780006000,
        "value": 139.654,
        "_internal_originalTime": 1689780006000
    },
    {
        "time": 1689780007000,
        "value": 139.644,
        "_internal_originalTime": 1689780007000
    },
    {
        "time": 1689780008000,
        "value": 139.642,
        "_internal_originalTime": 1689780008000
    },
    {
        "time": 1689780009000,
        "value": 139.646,
        "_internal_originalTime": 1689780009000
    },
    {
        "time": 1689780011000,
        "value": 139.651,
        "_internal_originalTime": 1689780011000
    },
    {
        "time": 1689780012000,
        "value": 139.667,
        "_internal_originalTime": 1689780012000
    },
    {
        "time": 1689780013000,
        "value": 139.667,
        "_internal_originalTime": 1689780013000
    },
    {
        "time": 1689780014000,
        "value": 139.672,
        "_internal_originalTime": 1689780014000
    },
    {
        "time": 1689780015000,
        "value": 139.668,
        "_internal_originalTime": 1689780015000
    },
    {
        "time": 1689780016000,
        "value": 139.674,
        "_internal_originalTime": 1689780016000
    },
    {
        "time": 1689780017000,
        "value": 139.675,
        "_internal_originalTime": 1689780017000
    },
    {
        "time": 1689780018000,
        "value": 139.674,
        "_internal_originalTime": 1689780018000
    },
    {
        "time": 1689780019000,
        "value": 139.669,
        "_internal_originalTime": 1689780019000
    },
    {
        "time": 1689780020000,
        "value": 139.668,
        "_internal_originalTime": 1689780020000
    },
    {
        "time": 1689780021000,
        "value": 139.664,
        "_internal_originalTime": 1689780021000
    },
    {
        "time": 1689780022000,
        "value": 139.668,
        "_internal_originalTime": 1689780022000
    },
    {
        "time": 1689780023000,
        "value": 139.662,
        "_internal_originalTime": 1689780023000
    },
    {
        "time": 1689780025000,
        "value": 139.656,
        "_internal_originalTime": 1689780025000
    },
    {
        "time": 1689780026000,
        "value": 139.66,
        "_internal_originalTime": 1689780026000
    },
    {
        "time": 1689780027000,
        "value": 139.661,
        "_internal_originalTime": 1689780027000
    },
    {
        "time": 1689780029000,
        "value": 139.661,
        "_internal_originalTime": 1689780029000
    },
    {
        "time": 1689780030000,
        "value": 139.667,
        "_internal_originalTime": 1689780030000
    },
    {
        "time": 1689780032000,
        "value": 139.669,
        "_internal_originalTime": 1689780032000
    },
    {
        "time": 1689780033000,
        "value": 139.67,
        "_internal_originalTime": 1689780033000
    },
    {
        "time": 1689780034000,
        "value": 139.664,
        "_internal_originalTime": 1689780034000
    },
    {
        "time": 1689780035000,
        "value": 139.664,
        "_internal_originalTime": 1689780035000
    },
    {
        "time": 1689780036000,
        "value": 139.659,
        "_internal_originalTime": 1689780036000
    },
    {
        "time": 1689780037000,
        "value": 139.663,
        "_internal_originalTime": 1689780037000
    },
    {
        "time": 1689780038000,
        "value": 139.656,
        "_internal_originalTime": 1689780038000
    },
    {
        "time": 1689780039000,
        "value": 139.662,
        "_internal_originalTime": 1689780039000
    },
    {
        "time": 1689780041000,
        "value": 139.66,
        "_internal_originalTime": 1689780041000
    },
    {
        "time": 1689780042000,
        "value": 139.659,
        "_internal_originalTime": 1689780042000
    },
    {
        "time": 1689780043000,
        "value": 139.663,
        "_internal_originalTime": 1689780043000
    },
    {
        "time": 1689780044000,
        "value": 139.666,
        "_internal_originalTime": 1689780044000
    },
    {
        "time": 1689780046000,
        "value": 139.665,
        "_internal_originalTime": 1689780046000
    },
    {
        "time": 1689780048000,
        "value": 139.662,
        "_internal_originalTime": 1689780048000
    },
    {
        "time": 1689780049000,
        "value": 139.661,
        "_internal_originalTime": 1689780049000
    },
    {
        "time": 1689780050000,
        "value": 139.661,
        "_internal_originalTime": 1689780050000
    },
    {
        "time": 1689780051000,
        "value": 139.66,
        "_internal_originalTime": 1689780051000
    },
    {
        "time": 1689780052000,
        "value": 139.659,
        "_internal_originalTime": 1689780052000
    },
    {
        "time": 1689780054000,
        "value": 139.66,
        "_internal_originalTime": 1689780054000
    },
    {
        "time": 1689780055000,
        "value": 139.66,
        "_internal_originalTime": 1689780055000
    },
    {
        "time": 1689780056000,
        "value": 139.66,
        "_internal_originalTime": 1689780056000
    },
    {
        "time": 1689780059000,
        "value": 139.65,
        "_internal_originalTime": 1689780059000
    },
    {
        "time": 1689780060000,
        "value": 139.651,
        "_internal_originalTime": 1689780060000
    },
    {
        "time": 1689780062000,
        "value": 139.648,
        "_internal_originalTime": 1689780062000
    },
    {
        "time": 1689780063000,
        "value": 139.653,
        "_internal_originalTime": 1689780063000
    },
    {
        "time": 1689780064000,
        "value": 139.645,
        "_internal_originalTime": 1689780064000
    },
    {
        "time": 1689780065000,
        "value": 139.636,
        "_internal_originalTime": 1689780065000
    },
    {
        "time": 1689780066000,
        "value": 139.646,
        "_internal_originalTime": 1689780066000
    },
    {
        "time": 1689780068000,
        "value": 139.644,
        "_internal_originalTime": 1689780068000
    },
    {
        "time": 1689780070000,
        "value": 139.649,
        "_internal_originalTime": 1689780070000
    },
    {
        "time": 1689780071000,
        "value": 139.65,
        "_internal_originalTime": 1689780071000
    },
    {
        "time": 1689780072000,
        "value": 139.653,
        "_internal_originalTime": 1689780072000
    },
    {
        "time": 1689780074000,
        "value": 139.646,
        "_internal_originalTime": 1689780074000
    },
    {
        "time": 1689780075000,
        "value": 139.637,
        "_internal_originalTime": 1689780075000
    },
    {
        "time": 1689780077000,
        "value": 139.632,
        "_internal_originalTime": 1689780077000
    },
    {
        "time": 1689780078000,
        "value": 139.627,
        "_internal_originalTime": 1689780078000
    },
    {
        "time": 1689780079000,
        "value": 139.627,
        "_internal_originalTime": 1689780079000
    },
    {
        "time": 1689780080000,
        "value": 139.622,
        "_internal_originalTime": 1689780080000
    },
    {
        "time": 1689780082000,
        "value": 139.614,
        "_internal_originalTime": 1689780082000
    },
    {
        "time": 1689780083000,
        "value": 139.614,
        "_internal_originalTime": 1689780083000
    },
    {
        "time": 1689780084000,
        "value": 139.616,
        "_internal_originalTime": 1689780084000
    },
    {
        "time": 1689780086000,
        "value": 139.615,
        "_internal_originalTime": 1689780086000
    },
    {
        "time": 1689780087000,
        "value": 139.612,
        "_internal_originalTime": 1689780087000
    },
    {
        "time": 1689780088000,
        "value": 139.618,
        "_internal_originalTime": 1689780088000
    },
    {
        "time": 1689780090000,
        "value": 139.613,
        "_internal_originalTime": 1689780090000
    },
    {
        "time": 1689780091000,
        "value": 139.611,
        "_internal_originalTime": 1689780091000
    },
    {
        "time": 1689780092000,
        "value": 139.616,
        "_internal_originalTime": 1689780092000
    },
    {
        "time": 1689780093000,
        "value": 139.616,
        "_internal_originalTime": 1689780093000
    },
    {
        "time": 1689780094000,
        "value": 139.615,
        "_internal_originalTime": 1689780094000
    },
    {
        "time": 1689780096000,
        "value": 139.614,
        "_internal_originalTime": 1689780096000
    },
    {
        "time": 1689780097000,
        "value": 139.612,
        "_internal_originalTime": 1689780097000
    },
    {
        "time": 1689780098000,
        "value": 139.612,
        "_internal_originalTime": 1689780098000
    },
    {
        "time": 1689780100000,
        "value": 139.602,
        "_internal_originalTime": 1689780100000
    },
    {
        "time": 1689780101000,
        "value": 139.607,
        "_internal_originalTime": 1689780101000
    },
    {
        "time": 1689780102000,
        "value": 139.606,
        "_internal_originalTime": 1689780102000
    },
    {
        "time": 1689780103000,
        "value": 139.607,
        "_internal_originalTime": 1689780103000
    },
    {
        "time": 1689780104000,
        "value": 139.613,
        "_internal_originalTime": 1689780104000
    },
    {
        "time": 1689780105000,
        "value": 139.609,
        "_internal_originalTime": 1689780105000
    },
    {
        "time": 1689780107000,
        "value": 139.616,
        "_internal_originalTime": 1689780107000
    },
    {
        "time": 1689780110000,
        "value": 139.615,
        "_internal_originalTime": 1689780110000
    },
    {
        "time": 1689780111000,
        "value": 139.615,
        "_internal_originalTime": 1689780111000
    },
    {
        "time": 1689780112000,
        "value": 139.615,
        "_internal_originalTime": 1689780112000
    },
    {
        "time": 1689780113000,
        "value": 139.617,
        "_internal_originalTime": 1689780113000
    },
    {
        "time": 1689780115000,
        "value": 139.612,
        "_internal_originalTime": 1689780115000
    },
    {
        "time": 1689780116000,
        "value": 139.614,
        "_internal_originalTime": 1689780116000
    },
    {
        "time": 1689780118000,
        "value": 139.621,
        "_internal_originalTime": 1689780118000
    },
    {
        "time": 1689780120000,
        "value": 139.62,
        "_internal_originalTime": 1689780120000
    },
    {
        "time": 1689780121000,
        "value": 139.61,
        "_internal_originalTime": 1689780121000
    },
    {
        "time": 1689780122000,
        "value": 139.608,
        "_internal_originalTime": 1689780122000
    },
    {
        "time": 1689780123000,
        "value": 139.61,
        "_internal_originalTime": 1689780123000
    },
    {
        "time": 1689780124000,
        "value": 139.608,
        "_internal_originalTime": 1689780124000
    },
    {
        "time": 1689780126000,
        "value": 139.611,
        "_internal_originalTime": 1689780126000
    },
    {
        "time": 1689780127000,
        "value": 139.608,
        "_internal_originalTime": 1689780127000
    },
    {
        "time": 1689780128000,
        "value": 139.613,
        "_internal_originalTime": 1689780128000
    },
    {
        "time": 1689780129000,
        "value": 139.613,
        "_internal_originalTime": 1689780129000
    },
    {
        "time": 1689780130000,
        "value": 139.613,
        "_internal_originalTime": 1689780130000
    },
    {
        "time": 1689780132000,
        "value": 139.61,
        "_internal_originalTime": 1689780132000
    },
    {
        "time": 1689780133000,
        "value": 139.609,
        "_internal_originalTime": 1689780133000
    },
    {
        "time": 1689780135000,
        "value": 139.606,
        "_internal_originalTime": 1689780135000
    },
    {
        "time": 1689780136000,
        "value": 139.603,
        "_internal_originalTime": 1689780136000
    },
    {
        "time": 1689780137000,
        "value": 139.602,
        "_internal_originalTime": 1689780137000
    },
    {
        "time": 1689780138000,
        "value": 139.598,
        "_internal_originalTime": 1689780138000
    },
    {
        "time": 1689780139000,
        "value": 139.602,
        "_internal_originalTime": 1689780139000
    },
    {
        "time": 1689780140000,
        "value": 139.607,
        "_internal_originalTime": 1689780140000
    },
    {
        "time": 1689780141000,
        "value": 139.61,
        "_internal_originalTime": 1689780141000
    },
    {
        "time": 1689780142000,
        "value": 139.608,
        "_internal_originalTime": 1689780142000
    },
    {
        "time": 1689780143000,
        "value": 139.613,
        "_internal_originalTime": 1689780143000
    },
    {
        "time": 1689780145000,
        "value": 139.611,
        "_internal_originalTime": 1689780145000
    },
    {
        "time": 1689780146000,
        "value": 139.611,
        "_internal_originalTime": 1689780146000
    },
    {
        "time": 1689780147000,
        "value": 139.608,
        "_internal_originalTime": 1689780147000
    },
    {
        "time": 1689780148000,
        "value": 139.604,
        "_internal_originalTime": 1689780148000
    },
    {
        "time": 1689780150000,
        "value": 139.609,
        "_internal_originalTime": 1689780150000
    },
    {
        "time": 1689780151000,
        "value": 139.605,
        "_internal_originalTime": 1689780151000
    },
    {
        "time": 1689780153000,
        "value": 139.607,
        "_internal_originalTime": 1689780153000
    },
    {
        "time": 1689780154000,
        "value": 139.6,
        "_internal_originalTime": 1689780154000
    },
    {
        "time": 1689780155000,
        "value": 139.603,
        "_internal_originalTime": 1689780155000
    },
    {
        "time": 1689780156000,
        "value": 139.607,
        "_internal_originalTime": 1689780156000
    },
    {
        "time": 1689780157000,
        "value": 139.604,
        "_internal_originalTime": 1689780157000
    },
    {
        "time": 1689780158000,
        "value": 139.604,
        "_internal_originalTime": 1689780158000
    },
    {
        "time": 1689780162000,
        "value": 139.607,
        "_internal_originalTime": 1689780162000
    },
    {
        "time": 1689780163000,
        "value": 139.602,
        "_internal_originalTime": 1689780163000
    },
    {
        "time": 1689780165000,
        "value": 139.604,
        "_internal_originalTime": 1689780165000
    },
    {
        "time": 1689780166000,
        "value": 139.605,
        "_internal_originalTime": 1689780166000
    },
    {
        "time": 1689780167000,
        "value": 139.601,
        "_internal_originalTime": 1689780167000
    },
    {
        "time": 1689780169000,
        "value": 139.601,
        "_internal_originalTime": 1689780169000
    },
    {
        "time": 1689780171000,
        "value": 139.602,
        "_internal_originalTime": 1689780171000
    },
    {
        "time": 1689780175000,
        "value": 139.598,
        "_internal_originalTime": 1689780175000
    },
    {
        "time": 1689780176000,
        "value": 139.598,
        "_internal_originalTime": 1689780176000
    },
    {
        "time": 1689780178000,
        "value": 139.588,
        "_internal_originalTime": 1689780178000
    },
    {
        "time": 1689780179000,
        "value": 139.588,
        "_internal_originalTime": 1689780179000
    },
    {
        "time": 1689780180000,
        "value": 139.587,
        "_internal_originalTime": 1689780180000
    },
    {
        "time": 1689780181000,
        "value": 139.586,
        "_internal_originalTime": 1689780181000
    },
    {
        "time": 1689780182000,
        "value": 139.587,
        "_internal_originalTime": 1689780182000
    },
    {
        "time": 1689780183000,
        "value": 139.58,
        "_internal_originalTime": 1689780183000
    },
    {
        "time": 1689780185000,
        "value": 139.584,
        "_internal_originalTime": 1689780185000
    },
    {
        "time": 1689780186000,
        "value": 139.587,
        "_internal_originalTime": 1689780186000
    },
    {
        "time": 1689780187000,
        "value": 139.583,
        "_internal_originalTime": 1689780187000
    },
    {
        "time": 1689780189000,
        "value": 139.579,
        "_internal_originalTime": 1689780189000
    },
    {
        "time": 1689780190000,
        "value": 139.582,
        "_internal_originalTime": 1689780190000
    },
    {
        "time": 1689780191000,
        "value": 139.587,
        "_internal_originalTime": 1689780191000
    },
    {
        "time": 1689780192000,
        "value": 139.59,
        "_internal_originalTime": 1689780192000
    },
    {
        "time": 1689780194000,
        "value": 139.587,
        "_internal_originalTime": 1689780194000
    },
    {
        "time": 1689780195000,
        "value": 139.586,
        "_internal_originalTime": 1689780195000
    },
    {
        "time": 1689780196000,
        "value": 139.593,
        "_internal_originalTime": 1689780196000
    },
    {
        "time": 1689780197000,
        "value": 139.589,
        "_internal_originalTime": 1689780197000
    },
    {
        "time": 1689780198000,
        "value": 139.595,
        "_internal_originalTime": 1689780198000
    },
    {
        "time": 1689780200000,
        "value": 139.6,
        "_internal_originalTime": 1689780200000
    },
    {
        "time": 1689780201000,
        "value": 139.604,
        "_internal_originalTime": 1689780201000
    },
    {
        "time": 1689780203000,
        "value": 139.6,
        "_internal_originalTime": 1689780203000
    },
    {
        "time": 1689780204000,
        "value": 139.608,
        "_internal_originalTime": 1689780204000
    },
    {
        "time": 1689780206000,
        "value": 139.615,
        "_internal_originalTime": 1689780206000
    },
    {
        "time": 1689780207000,
        "value": 139.614,
        "_internal_originalTime": 1689780207000
    },
    {
        "time": 1689780208000,
        "value": 139.608,
        "_internal_originalTime": 1689780208000
    },
    {
        "time": 1689780210000,
        "value": 139.614,
        "_internal_originalTime": 1689780210000
    },
    {
        "time": 1689780211000,
        "value": 139.61,
        "_internal_originalTime": 1689780211000
    },
    {
        "time": 1689780212000,
        "value": 139.614,
        "_internal_originalTime": 1689780212000
    },
    {
        "time": 1689780213000,
        "value": 139.614,
        "_internal_originalTime": 1689780213000
    },
    {
        "time": 1689780214000,
        "value": 139.614,
        "_internal_originalTime": 1689780214000
    },
    {
        "time": 1689780215000,
        "value": 139.611,
        "_internal_originalTime": 1689780215000
    },
    {
        "time": 1689780216000,
        "value": 139.616,
        "_internal_originalTime": 1689780216000
    },
    {
        "time": 1689780218000,
        "value": 139.62,
        "_internal_originalTime": 1689780218000
    },
    {
        "time": 1689780219000,
        "value": 139.63,
        "_internal_originalTime": 1689780219000
    },
    {
        "time": 1689780220000,
        "value": 139.628,
        "_internal_originalTime": 1689780220000
    },
    {
        "time": 1689780221000,
        "value": 139.629,
        "_internal_originalTime": 1689780221000
    },
    {
        "time": 1689780222000,
        "value": 139.628,
        "_internal_originalTime": 1689780222000
    },
    {
        "time": 1689780223000,
        "value": 139.631,
        "_internal_originalTime": 1689780223000
    },
    {
        "time": 1689780225000,
        "value": 139.622,
        "_internal_originalTime": 1689780225000
    },
    {
        "time": 1689780226000,
        "value": 139.621,
        "_internal_originalTime": 1689780226000
    },
    {
        "time": 1689780227000,
        "value": 139.622,
        "_internal_originalTime": 1689780227000
    },
    {
        "time": 1689780228000,
        "value": 139.624,
        "_internal_originalTime": 1689780228000
    },
    {
        "time": 1689780229000,
        "value": 139.627,
        "_internal_originalTime": 1689780229000
    },
    {
        "time": 1689780231000,
        "value": 139.62,
        "_internal_originalTime": 1689780231000
    },
    {
        "time": 1689780232000,
        "value": 139.621,
        "_internal_originalTime": 1689780232000
    },
    {
        "time": 1689780233000,
        "value": 139.62,
        "_internal_originalTime": 1689780233000
    },
    {
        "time": 1689780236000,
        "value": 139.612,
        "_internal_originalTime": 1689780236000
    },
    {
        "time": 1689780237000,
        "value": 139.609,
        "_internal_originalTime": 1689780237000
    },
    {
        "time": 1689780239000,
        "value": 139.613,
        "_internal_originalTime": 1689780239000
    },
    {
        "time": 1689780240000,
        "value": 139.612,
        "_internal_originalTime": 1689780240000
    },
    {
        "time": 1689780242000,
        "value": 139.617,
        "_internal_originalTime": 1689780242000
    },
    {
        "time": 1689780243000,
        "value": 139.612,
        "_internal_originalTime": 1689780243000
    },
    {
        "time": 1689780244000,
        "value": 139.618,
        "_internal_originalTime": 1689780244000
    },
    {
        "time": 1689780245000,
        "value": 139.616,
        "_internal_originalTime": 1689780245000
    },
    {
        "time": 1689780247000,
        "value": 139.618,
        "_internal_originalTime": 1689780247000
    },
    {
        "time": 1689780248000,
        "value": 139.615,
        "_internal_originalTime": 1689780248000
    },
    {
        "time": 1689780249000,
        "value": 139.616,
        "_internal_originalTime": 1689780249000
    },
    {
        "time": 1689780250000,
        "value": 139.613,
        "_internal_originalTime": 1689780250000
    },
    {
        "time": 1689780251000,
        "value": 139.616,
        "_internal_originalTime": 1689780251000
    },
    {
        "time": 1689780252000,
        "value": 139.614,
        "_internal_originalTime": 1689780252000
    },
    {
        "time": 1689780253000,
        "value": 139.614,
        "_internal_originalTime": 1689780253000
    },
    {
        "time": 1689780255000,
        "value": 139.622,
        "_internal_originalTime": 1689780255000
    },
    {
        "time": 1689780256000,
        "value": 139.616,
        "_internal_originalTime": 1689780256000
    },
    {
        "time": 1689780257000,
        "value": 139.62,
        "_internal_originalTime": 1689780257000
    },
    {
        "time": 1689780260000,
        "value": 139.616,
        "_internal_originalTime": 1689780260000
    },
    {
        "time": 1689780261000,
        "value": 139.62,
        "_internal_originalTime": 1689780261000
    },
    {
        "time": 1689780263000,
        "value": 139.624,
        "_internal_originalTime": 1689780263000
    },
    {
        "time": 1689780265000,
        "value": 139.62,
        "_internal_originalTime": 1689780265000
    },
    {
        "time": 1689780266000,
        "value": 139.62,
        "_internal_originalTime": 1689780266000
    },
    {
        "time": 1689780267000,
        "value": 139.623,
        "_internal_originalTime": 1689780267000
    },
    {
        "time": 1689780270000,
        "value": 139.62,
        "_internal_originalTime": 1689780270000
    },
    {
        "time": 1689780271000,
        "value": 139.623,
        "_internal_originalTime": 1689780271000
    },
    {
        "time": 1689780272000,
        "value": 139.62,
        "_internal_originalTime": 1689780272000
    },
    {
        "time": 1689780274000,
        "value": 139.62,
        "_internal_originalTime": 1689780274000
    },
    {
        "time": 1689780275000,
        "value": 139.625,
        "_internal_originalTime": 1689780275000
    },
    {
        "time": 1689780276000,
        "value": 139.623,
        "_internal_originalTime": 1689780276000
    },
    {
        "time": 1689780278000,
        "value": 139.623,
        "_internal_originalTime": 1689780278000
    },
    {
        "time": 1689780279000,
        "value": 139.623,
        "_internal_originalTime": 1689780279000
    },
    {
        "time": 1689780280000,
        "value": 139.622,
        "_internal_originalTime": 1689780280000
    },
    {
        "time": 1689780282000,
        "value": 139.624,
        "_internal_originalTime": 1689780282000
    },
    {
        "time": 1689780283000,
        "value": 139.624,
        "_internal_originalTime": 1689780283000
    },
    {
        "time": 1689780285000,
        "value": 139.63,
        "_internal_originalTime": 1689780285000
    },
    {
        "time": 1689780286000,
        "value": 139.632,
        "_internal_originalTime": 1689780286000
    },
    {
        "time": 1689780287000,
        "value": 139.628,
        "_internal_originalTime": 1689780287000
    },
    {
        "time": 1689780289000,
        "value": 139.632,
        "_internal_originalTime": 1689780289000
    },
    {
        "time": 1689780290000,
        "value": 139.626,
        "_internal_originalTime": 1689780290000
    },
    {
        "time": 1689780291000,
        "value": 139.623,
        "_internal_originalTime": 1689780291000
    },
    {
        "time": 1689780292000,
        "value": 139.622,
        "_internal_originalTime": 1689780292000
    },
    {
        "time": 1689780294000,
        "value": 139.614,
        "_internal_originalTime": 1689780294000
    },
    {
        "time": 1689780295000,
        "value": 139.613,
        "_internal_originalTime": 1689780295000
    },
    {
        "time": 1689780297000,
        "value": 139.612,
        "_internal_originalTime": 1689780297000
    },
    {
        "time": 1689780298000,
        "value": 139.607,
        "_internal_originalTime": 1689780298000
    },
    {
        "time": 1689780299000,
        "value": 139.613,
        "_internal_originalTime": 1689780299000
    },
    {
        "time": 1689780300000,
        "value": 139.607,
        "_internal_originalTime": 1689780300000
    },
    {
        "time": 1689780301000,
        "value": 139.611,
        "_internal_originalTime": 1689780301000
    },
    {
        "time": 1689780303000,
        "value": 139.607,
        "_internal_originalTime": 1689780303000
    },
    {
        "time": 1689780304000,
        "value": 139.609,
        "_internal_originalTime": 1689780304000
    },
    {
        "time": 1689780306000,
        "value": 139.608,
        "_internal_originalTime": 1689780306000
    },
    {
        "time": 1689780307000,
        "value": 139.599,
        "_internal_originalTime": 1689780307000
    },
    {
        "time": 1689780308000,
        "value": 139.605,
        "_internal_originalTime": 1689780308000
    },
    {
        "time": 1689780309000,
        "value": 139.619,
        "_internal_originalTime": 1689780309000
    },
    {
        "time": 1689780310000,
        "value": 139.615,
        "_internal_originalTime": 1689780310000
    },
    {
        "time": 1689780312000,
        "value": 139.608,
        "_internal_originalTime": 1689780312000
    },
    {
        "time": 1689780313000,
        "value": 139.606,
        "_internal_originalTime": 1689780313000
    },
    {
        "time": 1689780314000,
        "value": 139.61,
        "_internal_originalTime": 1689780314000
    },
    {
        "time": 1689780315000,
        "value": 139.604,
        "_internal_originalTime": 1689780315000
    },
    {
        "time": 1689780317000,
        "value": 139.6,
        "_internal_originalTime": 1689780317000
    },
    {
        "time": 1689780319000,
        "value": 139.601,
        "_internal_originalTime": 1689780319000
    },
    {
        "time": 1689780320000,
        "value": 139.598,
        "_internal_originalTime": 1689780320000
    },
    {
        "time": 1689780321000,
        "value": 139.603,
        "_internal_originalTime": 1689780321000
    },
    {
        "time": 1689780322000,
        "value": 139.591,
        "_internal_originalTime": 1689780322000
    },
    {
        "time": 1689780323000,
        "value": 139.596,
        "_internal_originalTime": 1689780323000
    },
    {
        "time": 1689780324000,
        "value": 139.603,
        "_internal_originalTime": 1689780324000
    },
    {
        "time": 1689780326000,
        "value": 139.604,
        "_internal_originalTime": 1689780326000
    },
    {
        "time": 1689780327000,
        "value": 139.61,
        "_internal_originalTime": 1689780327000
    },
    {
        "time": 1689780328000,
        "value": 139.604,
        "_internal_originalTime": 1689780328000
    },
    {
        "time": 1689780329000,
        "value": 139.61,
        "_internal_originalTime": 1689780329000
    },
    {
        "time": 1689780330000,
        "value": 139.609,
        "_internal_originalTime": 1689780330000
    },
    {
        "time": 1689780331000,
        "value": 139.609,
        "_internal_originalTime": 1689780331000
    },
    {
        "time": 1689780332000,
        "value": 139.605,
        "_internal_originalTime": 1689780332000
    },
    {
        "time": 1689780334000,
        "value": 139.603,
        "_internal_originalTime": 1689780334000
    },
    {
        "time": 1689780335000,
        "value": 139.602,
        "_internal_originalTime": 1689780335000
    },
    {
        "time": 1689780337000,
        "value": 139.605,
        "_internal_originalTime": 1689780337000
    },
    {
        "time": 1689780338000,
        "value": 139.602,
        "_internal_originalTime": 1689780338000
    },
    {
        "time": 1689780339000,
        "value": 139.602,
        "_internal_originalTime": 1689780339000
    },
    {
        "time": 1689780340000,
        "value": 139.602,
        "_internal_originalTime": 1689780340000
    },
    {
        "time": 1689780343000,
        "value": 139.601,
        "_internal_originalTime": 1689780343000
    },
    {
        "time": 1689780344000,
        "value": 139.604,
        "_internal_originalTime": 1689780344000
    },
    {
        "time": 1689780345000,
        "value": 139.606,
        "_internal_originalTime": 1689780345000
    },
    {
        "time": 1689780347000,
        "value": 139.606,
        "_internal_originalTime": 1689780347000
    },
    {
        "time": 1689780348000,
        "value": 139.601,
        "_internal_originalTime": 1689780348000
    },
    {
        "time": 1689780349000,
        "value": 139.596,
        "_internal_originalTime": 1689780349000
    },
    {
        "time": 1689780351000,
        "value": 139.598,
        "_internal_originalTime": 1689780351000
    },
    {
        "time": 1689780352000,
        "value": 139.594,
        "_internal_originalTime": 1689780352000
    },
    {
        "time": 1689780354000,
        "value": 139.592,
        "_internal_originalTime": 1689780354000
    },
    {
        "time": 1689780356000,
        "value": 139.596,
        "_internal_originalTime": 1689780356000
    },
    {
        "time": 1689780357000,
        "value": 139.594,
        "_internal_originalTime": 1689780357000
    },
    {
        "time": 1689780360000,
        "value": 139.603,
        "_internal_originalTime": 1689780360000
    },
    {
        "time": 1689780362000,
        "value": 139.602,
        "_internal_originalTime": 1689780362000
    },
    {
        "time": 1689780364000,
        "value": 139.602,
        "_internal_originalTime": 1689780364000
    },
    {
        "time": 1689780365000,
        "value": 139.595,
        "_internal_originalTime": 1689780365000
    },
    {
        "time": 1689780368000,
        "value": 139.596,
        "_internal_originalTime": 1689780368000
    },
    {
        "time": 1689780370000,
        "value": 139.601,
        "_internal_originalTime": 1689780370000
    },
    {
        "time": 1689780372000,
        "value": 139.599,
        "_internal_originalTime": 1689780372000
    },
    {
        "time": 1689780373000,
        "value": 139.595,
        "_internal_originalTime": 1689780373000
    },
    {
        "time": 1689780375000,
        "value": 139.603,
        "_internal_originalTime": 1689780375000
    },
    {
        "time": 1689780377000,
        "value": 139.598,
        "_internal_originalTime": 1689780377000
    },
    {
        "time": 1689780378000,
        "value": 139.603,
        "_internal_originalTime": 1689780378000
    },
    {
        "time": 1689780380000,
        "value": 139.603,
        "_internal_originalTime": 1689780380000
    },
    {
        "time": 1689780381000,
        "value": 139.603,
        "_internal_originalTime": 1689780381000
    },
    {
        "time": 1689780384000,
        "value": 139.599,
        "_internal_originalTime": 1689780384000
    },
    {
        "time": 1689780385000,
        "value": 139.611,
        "_internal_originalTime": 1689780385000
    },
    {
        "time": 1689780386000,
        "value": 139.611,
        "_internal_originalTime": 1689780386000
    },
    {
        "time": 1689780388000,
        "value": 139.615,
        "_internal_originalTime": 1689780388000
    },
    {
        "time": 1689780389000,
        "value": 139.615,
        "_internal_originalTime": 1689780389000
    },
    {
        "time": 1689780390000,
        "value": 139.61,
        "_internal_originalTime": 1689780390000
    },
    {
        "time": 1689780391000,
        "value": 139.612,
        "_internal_originalTime": 1689780391000
    },
    {
        "time": 1689780392000,
        "value": 139.61,
        "_internal_originalTime": 1689780392000
    },
    {
        "time": 1689780394000,
        "value": 139.611,
        "_internal_originalTime": 1689780394000
    },
    {
        "time": 1689780395000,
        "value": 139.611,
        "_internal_originalTime": 1689780395000
    },
    {
        "time": 1689780397000,
        "value": 139.611,
        "_internal_originalTime": 1689780397000
    },
    {
        "time": 1689780398000,
        "value": 139.61,
        "_internal_originalTime": 1689780398000
    },
    {
        "time": 1689780401000,
        "value": 139.608,
        "_internal_originalTime": 1689780401000
    },
    {
        "time": 1689780402000,
        "value": 139.602,
        "_internal_originalTime": 1689780402000
    },
    {
        "time": 1689780403000,
        "value": 139.601,
        "_internal_originalTime": 1689780403000
    },
    {
        "time": 1689780404000,
        "value": 139.611,
        "_internal_originalTime": 1689780404000
    },
    {
        "time": 1689780405000,
        "value": 139.618,
        "_internal_originalTime": 1689780405000
    },
    {
        "time": 1689780407000,
        "value": 139.618,
        "_internal_originalTime": 1689780407000
    },
    {
        "time": 1689780411000,
        "value": 139.632,
        "_internal_originalTime": 1689780411000
    },
    {
        "time": 1689780413000,
        "value": 139.634,
        "_internal_originalTime": 1689780413000
    },
    {
        "time": 1689780415000,
        "value": 139.63,
        "_internal_originalTime": 1689780415000
    },
    {
        "time": 1689780416000,
        "value": 139.638,
        "_internal_originalTime": 1689780416000
    },
    {
        "time": 1689780417000,
        "value": 139.643,
        "_internal_originalTime": 1689780417000
    },
    {
        "time": 1689780419000,
        "value": 139.641,
        "_internal_originalTime": 1689780419000
    },
    {
        "time": 1689780420000,
        "value": 139.638,
        "_internal_originalTime": 1689780420000
    },
    {
        "time": 1689780421000,
        "value": 139.636,
        "_internal_originalTime": 1689780421000
    },
    {
        "time": 1689780422000,
        "value": 139.638,
        "_internal_originalTime": 1689780422000
    },
    {
        "time": 1689780423000,
        "value": 139.637,
        "_internal_originalTime": 1689780423000
    },
    {
        "time": 1689780426000,
        "value": 139.632,
        "_internal_originalTime": 1689780426000
    },
    {
        "time": 1689780428000,
        "value": 139.631,
        "_internal_originalTime": 1689780428000
    },
    {
        "time": 1689780429000,
        "value": 139.632,
        "_internal_originalTime": 1689780429000
    },
    {
        "time": 1689780430000,
        "value": 139.641,
        "_internal_originalTime": 1689780430000
    },
    {
        "time": 1689780431000,
        "value": 139.638,
        "_internal_originalTime": 1689780431000
    },
    {
        "time": 1689780432000,
        "value": 139.636,
        "_internal_originalTime": 1689780432000
    },
    {
        "time": 1689780433000,
        "value": 139.639,
        "_internal_originalTime": 1689780433000
    },
    {
        "time": 1689780434000,
        "value": 139.649,
        "_internal_originalTime": 1689780434000
    },
    {
        "time": 1689780436000,
        "value": 139.649,
        "_internal_originalTime": 1689780436000
    },
    {
        "time": 1689780438000,
        "value": 139.648,
        "_internal_originalTime": 1689780438000
    },
    {
        "time": 1689780440000,
        "value": 139.649,
        "_internal_originalTime": 1689780440000
    },
    {
        "time": 1689780441000,
        "value": 139.648,
        "_internal_originalTime": 1689780441000
    },
    {
        "time": 1689780443000,
        "value": 139.642,
        "_internal_originalTime": 1689780443000
    },
    {
        "time": 1689780444000,
        "value": 139.64,
        "_internal_originalTime": 1689780444000
    },
    {
        "time": 1689780445000,
        "value": 139.637,
        "_internal_originalTime": 1689780445000
    },
    {
        "time": 1689780446000,
        "value": 139.633,
        "_internal_originalTime": 1689780446000
    },
    {
        "time": 1689780449000,
        "value": 139.636,
        "_internal_originalTime": 1689780449000
    },
    {
        "time": 1689780450000,
        "value": 139.63,
        "_internal_originalTime": 1689780450000
    },
    {
        "time": 1689780452000,
        "value": 139.631,
        "_internal_originalTime": 1689780452000
    },
    {
        "time": 1689780453000,
        "value": 139.63,
        "_internal_originalTime": 1689780453000
    },
    {
        "time": 1689780454000,
        "value": 139.622,
        "_internal_originalTime": 1689780454000
    },
    {
        "time": 1689780455000,
        "value": 139.628,
        "_internal_originalTime": 1689780455000
    },
    {
        "time": 1689780456000,
        "value": 139.618,
        "_internal_originalTime": 1689780456000
    },
    {
        "time": 1689780458000,
        "value": 139.619,
        "_internal_originalTime": 1689780458000
    },
    {
        "time": 1689780459000,
        "value": 139.618,
        "_internal_originalTime": 1689780459000
    },
    {
        "time": 1689780462000,
        "value": 139.621,
        "_internal_originalTime": 1689780462000
    },
    {
        "time": 1689780463000,
        "value": 139.616,
        "_internal_originalTime": 1689780463000
    },
    {
        "time": 1689780464000,
        "value": 139.626,
        "_internal_originalTime": 1689780464000
    },
    {
        "time": 1689780466000,
        "value": 139.626,
        "_internal_originalTime": 1689780466000
    },
    {
        "time": 1689780467000,
        "value": 139.626,
        "_internal_originalTime": 1689780467000
    },
    {
        "time": 1689780468000,
        "value": 139.638,
        "_internal_originalTime": 1689780468000
    },
    {
        "time": 1689780469000,
        "value": 139.638,
        "_internal_originalTime": 1689780469000
    },
    {
        "time": 1689780471000,
        "value": 139.632,
        "_internal_originalTime": 1689780471000
    },
    {
        "time": 1689780472000,
        "value": 139.633,
        "_internal_originalTime": 1689780472000
    },
    {
        "time": 1689780473000,
        "value": 139.629,
        "_internal_originalTime": 1689780473000
    },
    {
        "time": 1689780475000,
        "value": 139.632,
        "_internal_originalTime": 1689780475000
    },
    {
        "time": 1689780477000,
        "value": 139.622,
        "_internal_originalTime": 1689780477000
    },
    {
        "time": 1689780478000,
        "value": 139.626,
        "_internal_originalTime": 1689780478000
    },
    {
        "time": 1689780480000,
        "value": 139.624,
        "_internal_originalTime": 1689780480000
    },
    {
        "time": 1689780481000,
        "value": 139.618,
        "_internal_originalTime": 1689780481000
    },
    {
        "time": 1689780482000,
        "value": 139.62,
        "_internal_originalTime": 1689780482000
    },
    {
        "time": 1689780483000,
        "value": 139.622,
        "_internal_originalTime": 1689780483000
    },
    {
        "time": 1689780484000,
        "value": 139.622,
        "_internal_originalTime": 1689780484000
    },
    {
        "time": 1689780486000,
        "value": 139.622,
        "_internal_originalTime": 1689780486000
    },
    {
        "time": 1689780488000,
        "value": 139.613,
        "_internal_originalTime": 1689780488000
    },
    {
        "time": 1689780489000,
        "value": 139.616,
        "_internal_originalTime": 1689780489000
    },
    {
        "time": 1689780490000,
        "value": 139.624,
        "_internal_originalTime": 1689780490000
    },
    {
        "time": 1689780491000,
        "value": 139.623,
        "_internal_originalTime": 1689780491000
    },
    {
        "time": 1689780492000,
        "value": 139.62,
        "_internal_originalTime": 1689780492000
    },
    {
        "time": 1689780493000,
        "value": 139.612,
        "_internal_originalTime": 1689780493000
    },
    {
        "time": 1689780495000,
        "value": 139.619,
        "_internal_originalTime": 1689780495000
    },
    {
        "time": 1689780496000,
        "value": 139.613,
        "_internal_originalTime": 1689780496000
    },
    {
        "time": 1689780497000,
        "value": 139.618,
        "_internal_originalTime": 1689780497000
    },
    {
        "time": 1689780499000,
        "value": 139.619,
        "_internal_originalTime": 1689780499000
    },
    {
        "time": 1689780500000,
        "value": 139.619,
        "_internal_originalTime": 1689780500000
    },
    {
        "time": 1689780501000,
        "value": 139.615,
        "_internal_originalTime": 1689780501000
    },
    {
        "time": 1689780502000,
        "value": 139.616,
        "_internal_originalTime": 1689780502000
    },
    {
        "time": 1689780503000,
        "value": 139.616,
        "_internal_originalTime": 1689780503000
    },
    {
        "time": 1689780504000,
        "value": 139.617,
        "_internal_originalTime": 1689780504000
    },
    {
        "time": 1689780505000,
        "value": 139.62,
        "_internal_originalTime": 1689780505000
    },
    {
        "time": 1689780506000,
        "value": 139.621,
        "_internal_originalTime": 1689780506000
    },
    {
        "time": 1689780508000,
        "value": 139.62,
        "_internal_originalTime": 1689780508000
    },
    {
        "time": 1689780510000,
        "value": 139.614,
        "_internal_originalTime": 1689780510000
    },
    {
        "time": 1689780511000,
        "value": 139.613,
        "_internal_originalTime": 1689780511000
    },
    {
        "time": 1689780512000,
        "value": 139.613,
        "_internal_originalTime": 1689780512000
    },
    {
        "time": 1689780514000,
        "value": 139.607,
        "_internal_originalTime": 1689780514000
    },
    {
        "time": 1689780515000,
        "value": 139.611,
        "_internal_originalTime": 1689780515000
    },
    {
        "time": 1689780516000,
        "value": 139.612,
        "_internal_originalTime": 1689780516000
    },
    {
        "time": 1689780518000,
        "value": 139.612,
        "_internal_originalTime": 1689780518000
    },
    {
        "time": 1689780520000,
        "value": 139.608,
        "_internal_originalTime": 1689780520000
    },
    {
        "time": 1689780522000,
        "value": 139.611,
        "_internal_originalTime": 1689780522000
    },
    {
        "time": 1689780523000,
        "value": 139.607,
        "_internal_originalTime": 1689780523000
    },
    {
        "time": 1689780525000,
        "value": 139.611,
        "_internal_originalTime": 1689780525000
    },
    {
        "time": 1689780527000,
        "value": 139.609,
        "_internal_originalTime": 1689780527000
    },
    {
        "time": 1689780528000,
        "value": 139.608,
        "_internal_originalTime": 1689780528000
    },
    {
        "time": 1689780529000,
        "value": 139.612,
        "_internal_originalTime": 1689780529000
    },
    {
        "time": 1689780531000,
        "value": 139.607,
        "_internal_originalTime": 1689780531000
    },
    {
        "time": 1689780532000,
        "value": 139.609,
        "_internal_originalTime": 1689780532000
    },
    {
        "time": 1689780533000,
        "value": 139.612,
        "_internal_originalTime": 1689780533000
    },
    {
        "time": 1689780535000,
        "value": 139.61,
        "_internal_originalTime": 1689780535000
    },
    {
        "time": 1689780537000,
        "value": 139.611,
        "_internal_originalTime": 1689780537000
    },
    {
        "time": 1689780538000,
        "value": 139.602,
        "_internal_originalTime": 1689780538000
    },
    {
        "time": 1689780539000,
        "value": 139.602,
        "_internal_originalTime": 1689780539000
    },
    {
        "time": 1689780540000,
        "value": 139.598,
        "_internal_originalTime": 1689780540000
    },
    {
        "time": 1689780541000,
        "value": 139.601,
        "_internal_originalTime": 1689780541000
    },
    {
        "time": 1689780542000,
        "value": 139.602,
        "_internal_originalTime": 1689780542000
    },
    {
        "time": 1689780543000,
        "value": 139.601,
        "_internal_originalTime": 1689780543000
    },
    {
        "time": 1689780545000,
        "value": 139.601,
        "_internal_originalTime": 1689780545000
    },
    {
        "time": 1689780547000,
        "value": 139.607,
        "_internal_originalTime": 1689780547000
    },
    {
        "time": 1689780548000,
        "value": 139.602,
        "_internal_originalTime": 1689780548000
    },
    {
        "time": 1689780549000,
        "value": 139.608,
        "_internal_originalTime": 1689780549000
    },
    {
        "time": 1689780550000,
        "value": 139.606,
        "_internal_originalTime": 1689780550000
    },
    {
        "time": 1689780551000,
        "value": 139.611,
        "_internal_originalTime": 1689780551000
    },
    {
        "time": 1689780552000,
        "value": 139.611,
        "_internal_originalTime": 1689780552000
    },
    {
        "time": 1689780553000,
        "value": 139.604,
        "_internal_originalTime": 1689780553000
    },
    {
        "time": 1689780554000,
        "value": 139.606,
        "_internal_originalTime": 1689780554000
    },
    {
        "time": 1689780557000,
        "value": 139.604,
        "_internal_originalTime": 1689780557000
    },
    {
        "time": 1689780559000,
        "value": 139.606,
        "_internal_originalTime": 1689780559000
    },
    {
        "time": 1689780563000,
        "value": 139.604,
        "_internal_originalTime": 1689780563000
    },
    {
        "time": 1689780566000,
        "value": 139.605,
        "_internal_originalTime": 1689780566000
    },
    {
        "time": 1689780567000,
        "value": 139.608,
        "_internal_originalTime": 1689780567000
    },
    {
        "time": 1689780568000,
        "value": 139.609,
        "_internal_originalTime": 1689780568000
    },
    {
        "time": 1689780570000,
        "value": 139.596,
        "_internal_originalTime": 1689780570000
    },
    {
        "time": 1689780571000,
        "value": 139.597,
        "_internal_originalTime": 1689780571000
    },
    {
        "time": 1689780572000,
        "value": 139.596,
        "_internal_originalTime": 1689780572000
    },
    {
        "time": 1689780573000,
        "value": 139.597,
        "_internal_originalTime": 1689780573000
    },
    {
        "time": 1689780574000,
        "value": 139.601,
        "_internal_originalTime": 1689780574000
    },
    {
        "time": 1689780575000,
        "value": 139.597,
        "_internal_originalTime": 1689780575000
    },
    {
        "time": 1689780577000,
        "value": 139.605,
        "_internal_originalTime": 1689780577000
    },
    {
        "time": 1689780579000,
        "value": 139.612,
        "_internal_originalTime": 1689780579000
    },
    {
        "time": 1689780580000,
        "value": 139.615,
        "_internal_originalTime": 1689780580000
    },
    {
        "time": 1689780581000,
        "value": 139.612,
        "_internal_originalTime": 1689780581000
    },
    {
        "time": 1689780582000,
        "value": 139.612,
        "_internal_originalTime": 1689780582000
    },
    {
        "time": 1689780583000,
        "value": 139.611,
        "_internal_originalTime": 1689780583000
    },
    {
        "time": 1689780585000,
        "value": 139.611,
        "_internal_originalTime": 1689780585000
    },
    {
        "time": 1689780587000,
        "value": 139.617,
        "_internal_originalTime": 1689780587000
    },
    {
        "time": 1689780588000,
        "value": 139.612,
        "_internal_originalTime": 1689780588000
    },
    {
        "time": 1689780589000,
        "value": 139.619,
        "_internal_originalTime": 1689780589000
    },
    {
        "time": 1689780591000,
        "value": 139.616,
        "_internal_originalTime": 1689780591000
    },
    {
        "time": 1689780592000,
        "value": 139.61,
        "_internal_originalTime": 1689780592000
    },
    {
        "time": 1689780593000,
        "value": 139.612,
        "_internal_originalTime": 1689780593000
    },
    {
        "time": 1689780594000,
        "value": 139.615,
        "_internal_originalTime": 1689780594000
    },
    {
        "time": 1689780596000,
        "value": 139.609,
        "_internal_originalTime": 1689780596000
    },
    {
        "time": 1689780597000,
        "value": 139.615,
        "_internal_originalTime": 1689780597000
    },
    {
        "time": 1689780598000,
        "value": 139.614,
        "_internal_originalTime": 1689780598000
    },
    {
        "time": 1689780600000,
        "value": 139.609,
        "_internal_originalTime": 1689780600000
    },
    {
        "time": 1689780601000,
        "value": 139.619,
        "_internal_originalTime": 1689780601000
    },
    {
        "time": 1689780602000,
        "value": 139.621,
        "_internal_originalTime": 1689780602000
    },
    {
        "time": 1689780603000,
        "value": 139.622,
        "_internal_originalTime": 1689780603000
    },
    {
        "time": 1689780605000,
        "value": 139.62,
        "_internal_originalTime": 1689780605000
    },
    {
        "time": 1689780606000,
        "value": 139.619,
        "_internal_originalTime": 1689780606000
    },
    {
        "time": 1689780607000,
        "value": 139.623,
        "_internal_originalTime": 1689780607000
    },
    {
        "time": 1689780608000,
        "value": 139.62,
        "_internal_originalTime": 1689780608000
    },
    {
        "time": 1689780609000,
        "value": 139.625,
        "_internal_originalTime": 1689780609000
    },
    {
        "time": 1689780610000,
        "value": 139.62,
        "_internal_originalTime": 1689780610000
    },
    {
        "time": 1689780612000,
        "value": 139.62,
        "_internal_originalTime": 1689780612000
    },
    {
        "time": 1689780613000,
        "value": 139.624,
        "_internal_originalTime": 1689780613000
    },
    {
        "time": 1689780614000,
        "value": 139.617,
        "_internal_originalTime": 1689780614000
    },
    {
        "time": 1689780615000,
        "value": 139.618,
        "_internal_originalTime": 1689780615000
    },
    {
        "time": 1689780616000,
        "value": 139.609,
        "_internal_originalTime": 1689780616000
    },
    {
        "time": 1689780617000,
        "value": 139.614,
        "_internal_originalTime": 1689780617000
    },
    {
        "time": 1689780618000,
        "value": 139.615,
        "_internal_originalTime": 1689780618000
    },
    {
        "time": 1689780620000,
        "value": 139.608,
        "_internal_originalTime": 1689780620000
    },
    {
        "time": 1689780621000,
        "value": 139.61,
        "_internal_originalTime": 1689780621000
    },
    {
        "time": 1689780623000,
        "value": 139.609,
        "_internal_originalTime": 1689780623000
    },
    {
        "time": 1689780624000,
        "value": 139.606,
        "_internal_originalTime": 1689780624000
    },
    {
        "time": 1689780625000,
        "value": 139.604,
        "_internal_originalTime": 1689780625000
    },
    {
        "time": 1689780627000,
        "value": 139.602,
        "_internal_originalTime": 1689780627000
    },
    {
        "time": 1689780628000,
        "value": 139.602,
        "_internal_originalTime": 1689780628000
    },
    {
        "time": 1689780629000,
        "value": 139.607,
        "_internal_originalTime": 1689780629000
    },
    {
        "time": 1689780630000,
        "value": 139.609,
        "_internal_originalTime": 1689780630000
    },
    {
        "time": 1689780633000,
        "value": 139.61,
        "_internal_originalTime": 1689780633000
    },
    {
        "time": 1689780635000,
        "value": 139.606,
        "_internal_originalTime": 1689780635000
    },
    {
        "time": 1689780637000,
        "value": 139.61,
        "_internal_originalTime": 1689780637000
    },
    {
        "time": 1689780638000,
        "value": 139.61,
        "_internal_originalTime": 1689780638000
    },
    {
        "time": 1689780639000,
        "value": 139.609,
        "_internal_originalTime": 1689780639000
    },
    {
        "time": 1689780640000,
        "value": 139.608,
        "_internal_originalTime": 1689780640000
    },
    {
        "time": 1689780642000,
        "value": 139.608,
        "_internal_originalTime": 1689780642000
    },
    {
        "time": 1689780643000,
        "value": 139.606,
        "_internal_originalTime": 1689780643000
    },
    {
        "time": 1689780646000,
        "value": 139.603,
        "_internal_originalTime": 1689780646000
    },
    {
        "time": 1689780647000,
        "value": 139.61,
        "_internal_originalTime": 1689780647000
    },
    {
        "time": 1689780648000,
        "value": 139.611,
        "_internal_originalTime": 1689780648000
    },
    {
        "time": 1689780649000,
        "value": 139.611,
        "_internal_originalTime": 1689780649000
    },
    {
        "time": 1689780650000,
        "value": 139.61,
        "_internal_originalTime": 1689780650000
    },
    {
        "time": 1689780651000,
        "value": 139.605,
        "_internal_originalTime": 1689780651000
    },
    {
        "time": 1689780652000,
        "value": 139.607,
        "_internal_originalTime": 1689780652000
    },
    {
        "time": 1689780655000,
        "value": 139.606,
        "_internal_originalTime": 1689780655000
    },
    {
        "time": 1689780656000,
        "value": 139.608,
        "_internal_originalTime": 1689780656000
    },
    {
        "time": 1689780657000,
        "value": 139.602,
        "_internal_originalTime": 1689780657000
    },
    {
        "time": 1689780658000,
        "value": 139.604,
        "_internal_originalTime": 1689780658000
    },
    {
        "time": 1689780660000,
        "value": 139.598,
        "_internal_originalTime": 1689780660000
    },
    {
        "time": 1689780662000,
        "value": 139.606,
        "_internal_originalTime": 1689780662000
    },
    {
        "time": 1689780663000,
        "value": 139.607,
        "_internal_originalTime": 1689780663000
    },
    {
        "time": 1689780664000,
        "value": 139.608,
        "_internal_originalTime": 1689780664000
    },
    {
        "time": 1689780665000,
        "value": 139.61,
        "_internal_originalTime": 1689780665000
    },
    {
        "time": 1689780666000,
        "value": 139.605,
        "_internal_originalTime": 1689780666000
    },
    {
        "time": 1689780668000,
        "value": 139.604,
        "_internal_originalTime": 1689780668000
    },
    {
        "time": 1689780669000,
        "value": 139.609,
        "_internal_originalTime": 1689780669000
    },
    {
        "time": 1689780670000,
        "value": 139.607,
        "_internal_originalTime": 1689780670000
    },
    {
        "time": 1689780672000,
        "value": 139.607,
        "_internal_originalTime": 1689780672000
    },
    {
        "time": 1689780673000,
        "value": 139.604,
        "_internal_originalTime": 1689780673000
    },
    {
        "time": 1689780674000,
        "value": 139.608,
        "_internal_originalTime": 1689780674000
    },
    {
        "time": 1689780676000,
        "value": 139.605,
        "_internal_originalTime": 1689780676000
    },
    {
        "time": 1689780677000,
        "value": 139.617,
        "_internal_originalTime": 1689780677000
    },
    {
        "time": 1689780678000,
        "value": 139.62,
        "_internal_originalTime": 1689780678000
    },
    {
        "time": 1689780680000,
        "value": 139.62,
        "_internal_originalTime": 1689780680000
    },
    {
        "time": 1689780683000,
        "value": 139.619,
        "_internal_originalTime": 1689780683000
    },
    {
        "time": 1689780684000,
        "value": 139.61,
        "_internal_originalTime": 1689780684000
    },
    {
        "time": 1689780686000,
        "value": 139.612,
        "_internal_originalTime": 1689780686000
    },
    {
        "time": 1689780687000,
        "value": 139.621,
        "_internal_originalTime": 1689780687000
    },
    {
        "time": 1689780690000,
        "value": 139.617,
        "_internal_originalTime": 1689780690000
    },
    {
        "time": 1689780692000,
        "value": 139.614,
        "_internal_originalTime": 1689780692000
    },
    {
        "time": 1689780693000,
        "value": 139.619,
        "_internal_originalTime": 1689780693000
    },
    {
        "time": 1689780694000,
        "value": 139.622,
        "_internal_originalTime": 1689780694000
    },
    {
        "time": 1689780696000,
        "value": 139.622,
        "_internal_originalTime": 1689780696000
    },
    {
        "time": 1689780697000,
        "value": 139.627,
        "_internal_originalTime": 1689780697000
    },
    {
        "time": 1689780698000,
        "value": 139.622,
        "_internal_originalTime": 1689780698000
    },
    {
        "time": 1689780699000,
        "value": 139.627,
        "_internal_originalTime": 1689780699000
    },
    {
        "time": 1689780701000,
        "value": 139.627,
        "_internal_originalTime": 1689780701000
    },
    {
        "time": 1689780702000,
        "value": 139.627,
        "_internal_originalTime": 1689780702000
    },
    {
        "time": 1689780703000,
        "value": 139.619,
        "_internal_originalTime": 1689780703000
    },
    {
        "time": 1689780704000,
        "value": 139.627,
        "_internal_originalTime": 1689780704000
    },
    {
        "time": 1689780708000,
        "value": 139.62,
        "_internal_originalTime": 1689780708000
    },
    {
        "time": 1689780718000,
        "value": 139.622,
        "_internal_originalTime": 1689780718000
    },
    {
        "time": 1689780721000,
        "value": 139.629,
        "_internal_originalTime": 1689780721000
    },
    {
        "time": 1689780724000,
        "value": 139.629,
        "_internal_originalTime": 1689780724000
    },
    {
        "time": 1689780725000,
        "value": 139.623,
        "_internal_originalTime": 1689780725000
    },
    {
        "time": 1689780726000,
        "value": 139.623,
        "_internal_originalTime": 1689780726000
    },
    {
        "time": 1689780728000,
        "value": 139.626,
        "_internal_originalTime": 1689780728000
    },
    {
        "time": 1689780730000,
        "value": 139.626,
        "_internal_originalTime": 1689780730000
    },
    {
        "time": 1689780731000,
        "value": 139.623,
        "_internal_originalTime": 1689780731000
    },
    {
        "time": 1689780733000,
        "value": 139.623,
        "_internal_originalTime": 1689780733000
    },
    {
        "time": 1689780734000,
        "value": 139.625,
        "_internal_originalTime": 1689780734000
    },
    {
        "time": 1689780735000,
        "value": 139.633,
        "_internal_originalTime": 1689780735000
    },
    {
        "time": 1689780736000,
        "value": 139.631,
        "_internal_originalTime": 1689780736000
    },
    {
        "time": 1689780737000,
        "value": 139.645,
        "_internal_originalTime": 1689780737000
    },
    {
        "time": 1689780739000,
        "value": 139.651,
        "_internal_originalTime": 1689780739000
    },
    {
        "time": 1689780740000,
        "value": 139.647,
        "_internal_originalTime": 1689780740000
    },
    {
        "time": 1689780742000,
        "value": 139.651,
        "_internal_originalTime": 1689780742000
    },
    {
        "time": 1689780743000,
        "value": 139.65,
        "_internal_originalTime": 1689780743000
    },
    {
        "time": 1689780744000,
        "value": 139.65,
        "_internal_originalTime": 1689780744000
    },
    {
        "time": 1689780745000,
        "value": 139.651,
        "_internal_originalTime": 1689780745000
    },
    {
        "time": 1689780746000,
        "value": 139.646,
        "_internal_originalTime": 1689780746000
    },
    {
        "time": 1689780748000,
        "value": 139.654,
        "_internal_originalTime": 1689780748000
    },
    {
        "time": 1689780749000,
        "value": 139.668,
        "_internal_originalTime": 1689780749000
    },
    {
        "time": 1689780750000,
        "value": 139.666,
        "_internal_originalTime": 1689780750000
    },
    {
        "time": 1689780751000,
        "value": 139.671,
        "_internal_originalTime": 1689780751000
    },
    {
        "time": 1689780752000,
        "value": 139.667,
        "_internal_originalTime": 1689780752000
    },
    {
        "time": 1689780753000,
        "value": 139.668,
        "_internal_originalTime": 1689780753000
    },
    {
        "time": 1689780754000,
        "value": 139.666,
        "_internal_originalTime": 1689780754000
    },
    {
        "time": 1689780755000,
        "value": 139.658,
        "_internal_originalTime": 1689780755000
    },
    {
        "time": 1689780756000,
        "value": 139.662,
        "_internal_originalTime": 1689780756000
    },
    {
        "time": 1689780757000,
        "value": 139.656,
        "_internal_originalTime": 1689780757000
    },
    {
        "time": 1689780758000,
        "value": 139.657,
        "_internal_originalTime": 1689780758000
    },
    {
        "time": 1689780759000,
        "value": 139.659,
        "_internal_originalTime": 1689780759000
    },
    {
        "time": 1689780761000,
        "value": 139.651,
        "_internal_originalTime": 1689780761000
    },
    {
        "time": 1689780762000,
        "value": 139.644,
        "_internal_originalTime": 1689780762000
    },
    {
        "time": 1689780764000,
        "value": 139.651,
        "_internal_originalTime": 1689780764000
    },
    {
        "time": 1689780765000,
        "value": 139.646,
        "_internal_originalTime": 1689780765000
    },
    {
        "time": 1689780766000,
        "value": 139.651,
        "_internal_originalTime": 1689780766000
    },
    {
        "time": 1689780768000,
        "value": 139.647,
        "_internal_originalTime": 1689780768000
    },
    {
        "time": 1689780769000,
        "value": 139.644,
        "_internal_originalTime": 1689780769000
    },
    {
        "time": 1689780770000,
        "value": 139.655,
        "_internal_originalTime": 1689780770000
    },
    {
        "time": 1689780771000,
        "value": 139.656,
        "_internal_originalTime": 1689780771000
    },
    {
        "time": 1689780773000,
        "value": 139.652,
        "_internal_originalTime": 1689780773000
    },
    {
        "time": 1689780775000,
        "value": 139.659,
        "_internal_originalTime": 1689780775000
    },
    {
        "time": 1689780778000,
        "value": 139.671,
        "_internal_originalTime": 1689780778000
    },
    {
        "time": 1689780779000,
        "value": 139.661,
        "_internal_originalTime": 1689780779000
    },
    {
        "time": 1689780780000,
        "value": 139.662,
        "_internal_originalTime": 1689780780000
    },
    {
        "time": 1689780782000,
        "value": 139.656,
        "_internal_originalTime": 1689780782000
    },
    {
        "time": 1689780783000,
        "value": 139.649,
        "_internal_originalTime": 1689780783000
    },
    {
        "time": 1689780785000,
        "value": 139.649,
        "_internal_originalTime": 1689780785000
    },
    {
        "time": 1689780786000,
        "value": 139.644,
        "_internal_originalTime": 1689780786000
    },
    {
        "time": 1689780787000,
        "value": 139.648,
        "_internal_originalTime": 1689780787000
    },
    {
        "time": 1689780788000,
        "value": 139.652,
        "_internal_originalTime": 1689780788000
    },
    {
        "time": 1689780789000,
        "value": 139.648,
        "_internal_originalTime": 1689780789000
    },
    {
        "time": 1689780790000,
        "value": 139.655,
        "_internal_originalTime": 1689780790000
    },
    {
        "time": 1689780791000,
        "value": 139.654,
        "_internal_originalTime": 1689780791000
    },
    {
        "time": 1689780792000,
        "value": 139.648,
        "_internal_originalTime": 1689780792000
    },
    {
        "time": 1689780793000,
        "value": 139.647,
        "_internal_originalTime": 1689780793000
    },
    {
        "time": 1689780794000,
        "value": 139.642,
        "_internal_originalTime": 1689780794000
    },
    {
        "time": 1689780795000,
        "value": 139.643,
        "_internal_originalTime": 1689780795000
    },
    {
        "time": 1689780797000,
        "value": 139.639,
        "_internal_originalTime": 1689780797000
    },
    {
        "time": 1689780798000,
        "value": 139.639,
        "_internal_originalTime": 1689780798000
    },
    {
        "time": 1689780799000,
        "value": 139.634,
        "_internal_originalTime": 1689780799000
    },
    {
        "time": 1689780800000,
        "value": 139.64,
        "_internal_originalTime": 1689780800000
    },
    {
        "time": 1689780801000,
        "value": 139.641,
        "_internal_originalTime": 1689780801000
    },
    {
        "time": 1689780804000,
        "value": 139.641,
        "_internal_originalTime": 1689780804000
    },
    {
        "time": 1689780805000,
        "value": 139.638,
        "_internal_originalTime": 1689780805000
    },
    {
        "time": 1689780806000,
        "value": 139.64,
        "_internal_originalTime": 1689780806000
    },
    {
        "time": 1689780807000,
        "value": 139.642,
        "_internal_originalTime": 1689780807000
    },
    {
        "time": 1689780808000,
        "value": 139.641,
        "_internal_originalTime": 1689780808000
    },
    {
        "time": 1689780809000,
        "value": 139.635,
        "_internal_originalTime": 1689780809000
    },
    {
        "time": 1689780810000,
        "value": 139.634,
        "_internal_originalTime": 1689780810000
    },
    {
        "time": 1689780812000,
        "value": 139.639,
        "_internal_originalTime": 1689780812000
    },
    {
        "time": 1689780813000,
        "value": 139.638,
        "_internal_originalTime": 1689780813000
    },
    {
        "time": 1689780814000,
        "value": 139.638,
        "_internal_originalTime": 1689780814000
    },
    {
        "time": 1689780815000,
        "value": 139.637,
        "_internal_originalTime": 1689780815000
    },
    {
        "time": 1689780816000,
        "value": 139.637,
        "_internal_originalTime": 1689780816000
    },
    {
        "time": 1689780817000,
        "value": 139.647,
        "_internal_originalTime": 1689780817000
    },
    {
        "time": 1689780818000,
        "value": 139.647,
        "_internal_originalTime": 1689780818000
    },
    {
        "time": 1689780819000,
        "value": 139.644,
        "_internal_originalTime": 1689780819000
    },
    {
        "time": 1689780821000,
        "value": 139.645,
        "_internal_originalTime": 1689780821000
    },
    {
        "time": 1689780822000,
        "value": 139.649,
        "_internal_originalTime": 1689780822000
    },
    {
        "time": 1689780823000,
        "value": 139.647,
        "_internal_originalTime": 1689780823000
    },
    {
        "time": 1689780825000,
        "value": 139.655,
        "_internal_originalTime": 1689780825000
    },
    {
        "time": 1689780826000,
        "value": 139.654,
        "_internal_originalTime": 1689780826000
    },
    {
        "time": 1689780830000,
        "value": 139.664,
        "_internal_originalTime": 1689780830000
    },
    {
        "time": 1689780831000,
        "value": 139.658,
        "_internal_originalTime": 1689780831000
    },
    {
        "time": 1689780832000,
        "value": 139.659,
        "_internal_originalTime": 1689780832000
    },
    {
        "time": 1689780835000,
        "value": 139.658,
        "_internal_originalTime": 1689780835000
    },
    {
        "time": 1689780837000,
        "value": 139.659,
        "_internal_originalTime": 1689780837000
    },
    {
        "time": 1689780838000,
        "value": 139.658,
        "_internal_originalTime": 1689780838000
    },
    {
        "time": 1689780840000,
        "value": 139.657,
        "_internal_originalTime": 1689780840000
    },
    {
        "time": 1689780841000,
        "value": 139.658,
        "_internal_originalTime": 1689780841000
    },
    {
        "time": 1689780843000,
        "value": 139.652,
        "_internal_originalTime": 1689780843000
    },
    {
        "time": 1689780845000,
        "value": 139.648,
        "_internal_originalTime": 1689780845000
    },
    {
        "time": 1689780846000,
        "value": 139.647,
        "_internal_originalTime": 1689780846000
    },
    {
        "time": 1689780849000,
        "value": 139.644,
        "_internal_originalTime": 1689780849000
    },
    {
        "time": 1689780851000,
        "value": 139.644,
        "_internal_originalTime": 1689780851000
    },
    {
        "time": 1689780853000,
        "value": 139.643,
        "_internal_originalTime": 1689780853000
    },
    {
        "time": 1689780855000,
        "value": 139.639,
        "_internal_originalTime": 1689780855000
    },
    {
        "time": 1689780856000,
        "value": 139.634,
        "_internal_originalTime": 1689780856000
    },
    {
        "time": 1689780857000,
        "value": 139.635,
        "_internal_originalTime": 1689780857000
    },
    {
        "time": 1689780858000,
        "value": 139.634,
        "_internal_originalTime": 1689780858000
    },
    {
        "time": 1689780860000,
        "value": 139.634,
        "_internal_originalTime": 1689780860000
    },
    {
        "time": 1689780861000,
        "value": 139.635,
        "_internal_originalTime": 1689780861000
    },
    {
        "time": 1689780862000,
        "value": 139.64,
        "_internal_originalTime": 1689780862000
    },
    {
        "time": 1689780863000,
        "value": 139.636,
        "_internal_originalTime": 1689780863000
    },
    {
        "time": 1689780864000,
        "value": 139.632,
        "_internal_originalTime": 1689780864000
    },
    {
        "time": 1689780868000,
        "value": 139.651,
        "_internal_originalTime": 1689780868000
    },
    {
        "time": 1689780869000,
        "value": 139.648,
        "_internal_originalTime": 1689780869000
    },
    {
        "time": 1689780870000,
        "value": 139.664,
        "_internal_originalTime": 1689780870000
    },
    {
        "time": 1689780871000,
        "value": 139.67,
        "_internal_originalTime": 1689780871000
    },
    {
        "time": 1689780872000,
        "value": 139.67,
        "_internal_originalTime": 1689780872000
    },
    {
        "time": 1689780873000,
        "value": 139.672,
        "_internal_originalTime": 1689780873000
    },
    {
        "time": 1689780875000,
        "value": 139.667,
        "_internal_originalTime": 1689780875000
    },
    {
        "time": 1689780876000,
        "value": 139.672,
        "_internal_originalTime": 1689780876000
    },
    {
        "time": 1689780877000,
        "value": 139.667,
        "_internal_originalTime": 1689780877000
    },
    {
        "time": 1689780878000,
        "value": 139.671,
        "_internal_originalTime": 1689780878000
    },
    {
        "time": 1689780879000,
        "value": 139.671,
        "_internal_originalTime": 1689780879000
    },
    {
        "time": 1689780880000,
        "value": 139.673,
        "_internal_originalTime": 1689780880000
    },
    {
        "time": 1689780881000,
        "value": 139.672,
        "_internal_originalTime": 1689780881000
    },
    {
        "time": 1689780883000,
        "value": 139.665,
        "_internal_originalTime": 1689780883000
    },
    {
        "time": 1689780885000,
        "value": 139.667,
        "_internal_originalTime": 1689780885000
    },
    {
        "time": 1689780886000,
        "value": 139.67,
        "_internal_originalTime": 1689780886000
    },
    {
        "time": 1689780887000,
        "value": 139.671,
        "_internal_originalTime": 1689780887000
    },
    {
        "time": 1689780889000,
        "value": 139.675,
        "_internal_originalTime": 1689780889000
    },
    {
        "time": 1689780890000,
        "value": 139.67,
        "_internal_originalTime": 1689780890000
    },
    {
        "time": 1689780891000,
        "value": 139.671,
        "_internal_originalTime": 1689780891000
    },
    {
        "time": 1689780892000,
        "value": 139.673,
        "_internal_originalTime": 1689780892000
    },
    {
        "time": 1689780893000,
        "value": 139.672,
        "_internal_originalTime": 1689780893000
    },
    {
        "time": 1689780895000,
        "value": 139.672,
        "_internal_originalTime": 1689780895000
    },
    {
        "time": 1689780898000,
        "value": 139.672,
        "_internal_originalTime": 1689780898000
    },
    {
        "time": 1689780899000,
        "value": 139.676,
        "_internal_originalTime": 1689780899000
    },
    {
        "time": 1689780901000,
        "value": 139.667,
        "_internal_originalTime": 1689780901000
    },
    {
        "time": 1689780902000,
        "value": 139.67,
        "_internal_originalTime": 1689780902000
    },
    {
        "time": 1689780904000,
        "value": 139.668,
        "_internal_originalTime": 1689780904000
    },
    {
        "time": 1689780905000,
        "value": 139.667,
        "_internal_originalTime": 1689780905000
    },
    {
        "time": 1689780907000,
        "value": 139.667,
        "_internal_originalTime": 1689780907000
    },
    {
        "time": 1689780908000,
        "value": 139.665,
        "_internal_originalTime": 1689780908000
    },
    {
        "time": 1689780909000,
        "value": 139.664,
        "_internal_originalTime": 1689780909000
    },
    {
        "time": 1689780911000,
        "value": 139.669,
        "_internal_originalTime": 1689780911000
    },
    {
        "time": 1689780913000,
        "value": 139.672,
        "_internal_originalTime": 1689780913000
    },
    {
        "time": 1689780915000,
        "value": 139.674,
        "_internal_originalTime": 1689780915000
    },
    {
        "time": 1689780917000,
        "value": 139.675,
        "_internal_originalTime": 1689780917000
    },
    {
        "time": 1689780919000,
        "value": 139.672,
        "_internal_originalTime": 1689780919000
    },
    {
        "time": 1689780921000,
        "value": 139.674,
        "_internal_originalTime": 1689780921000
    },
    {
        "time": 1689780925000,
        "value": 139.664,
        "_internal_originalTime": 1689780925000
    },
    {
        "time": 1689780926000,
        "value": 139.667,
        "_internal_originalTime": 1689780926000
    },
    {
        "time": 1689780928000,
        "value": 139.667,
        "_internal_originalTime": 1689780928000
    },
    {
        "time": 1689780930000,
        "value": 139.667,
        "_internal_originalTime": 1689780930000
    },
    {
        "time": 1689780931000,
        "value": 139.67,
        "_internal_originalTime": 1689780931000
    },
    {
        "time": 1689780932000,
        "value": 139.674,
        "_internal_originalTime": 1689780932000
    },
    {
        "time": 1689780933000,
        "value": 139.675,
        "_internal_originalTime": 1689780933000
    },
    {
        "time": 1689780935000,
        "value": 139.67,
        "_internal_originalTime": 1689780935000
    },
    {
        "time": 1689780936000,
        "value": 139.671,
        "_internal_originalTime": 1689780936000
    },
    {
        "time": 1689780937000,
        "value": 139.67,
        "_internal_originalTime": 1689780937000
    },
    {
        "time": 1689780938000,
        "value": 139.671,
        "_internal_originalTime": 1689780938000
    },
    {
        "time": 1689780939000,
        "value": 139.67,
        "_internal_originalTime": 1689780939000
    },
    {
        "time": 1689780940000,
        "value": 139.671,
        "_internal_originalTime": 1689780940000
    },
    {
        "time": 1689780941000,
        "value": 139.67,
        "_internal_originalTime": 1689780941000
    },
    {
        "time": 1689780942000,
        "value": 139.675,
        "_internal_originalTime": 1689780942000
    },
    {
        "time": 1689780943000,
        "value": 139.67,
        "_internal_originalTime": 1689780943000
    },
    {
        "time": 1689780944000,
        "value": 139.67,
        "_internal_originalTime": 1689780944000
    },
    {
        "time": 1689780945000,
        "value": 139.675,
        "_internal_originalTime": 1689780945000
    },
    {
        "time": 1689780946000,
        "value": 139.674,
        "_internal_originalTime": 1689780946000
    },
    {
        "time": 1689780947000,
        "value": 139.679,
        "_internal_originalTime": 1689780947000
    },
    {
        "time": 1689780948000,
        "value": 139.68,
        "_internal_originalTime": 1689780948000
    },
    {
        "time": 1689780949000,
        "value": 139.678,
        "_internal_originalTime": 1689780949000
    },
    {
        "time": 1689780950000,
        "value": 139.677,
        "_internal_originalTime": 1689780950000
    },
    {
        "time": 1689780951000,
        "value": 139.68,
        "_internal_originalTime": 1689780951000
    },
    {
        "time": 1689780952000,
        "value": 139.681,
        "_internal_originalTime": 1689780952000
    },
    {
        "time": 1689780954000,
        "value": 139.684,
        "_internal_originalTime": 1689780954000
    },
    {
        "time": 1689780956000,
        "value": 139.681,
        "_internal_originalTime": 1689780956000
    },
    {
        "time": 1689780957000,
        "value": 139.687,
        "_internal_originalTime": 1689780957000
    },
    {
        "time": 1689780958000,
        "value": 139.687,
        "_internal_originalTime": 1689780958000
    },
    {
        "time": 1689780959000,
        "value": 139.687,
        "_internal_originalTime": 1689780959000
    },
    {
        "time": 1689780960000,
        "value": 139.683,
        "_internal_originalTime": 1689780960000
    },
    {
        "time": 1689780961000,
        "value": 139.685,
        "_internal_originalTime": 1689780961000
    },
    {
        "time": 1689780966000,
        "value": 139.676,
        "_internal_originalTime": 1689780966000
    },
    {
        "time": 1689780967000,
        "value": 139.676,
        "_internal_originalTime": 1689780967000
    },
    {
        "time": 1689780968000,
        "value": 139.672,
        "_internal_originalTime": 1689780968000
    },
    {
        "time": 1689780969000,
        "value": 139.672,
        "_internal_originalTime": 1689780969000
    },
    {
        "time": 1689780971000,
        "value": 139.676,
        "_internal_originalTime": 1689780971000
    },
    {
        "time": 1689780972000,
        "value": 139.67,
        "_internal_originalTime": 1689780972000
    },
    {
        "time": 1689780974000,
        "value": 139.67,
        "_internal_originalTime": 1689780974000
    },
    {
        "time": 1689780975000,
        "value": 139.668,
        "_internal_originalTime": 1689780975000
    },
    {
        "time": 1689780976000,
        "value": 139.67,
        "_internal_originalTime": 1689780976000
    },
    {
        "time": 1689780977000,
        "value": 139.67,
        "_internal_originalTime": 1689780977000
    },
    {
        "time": 1689780978000,
        "value": 139.671,
        "_internal_originalTime": 1689780978000
    },
    {
        "time": 1689780979000,
        "value": 139.669,
        "_internal_originalTime": 1689780979000
    },
    {
        "time": 1689780980000,
        "value": 139.674,
        "_internal_originalTime": 1689780980000
    },
    {
        "time": 1689780981000,
        "value": 139.675,
        "_internal_originalTime": 1689780981000
    },
    {
        "time": 1689780983000,
        "value": 139.686,
        "_internal_originalTime": 1689780983000
    },
    {
        "time": 1689780984000,
        "value": 139.685,
        "_internal_originalTime": 1689780984000
    },
    {
        "time": 1689780985000,
        "value": 139.686,
        "_internal_originalTime": 1689780985000
    },
    {
        "time": 1689780986000,
        "value": 139.688,
        "_internal_originalTime": 1689780986000
    },
    {
        "time": 1689780987000,
        "value": 139.686,
        "_internal_originalTime": 1689780987000
    },
    {
        "time": 1689780988000,
        "value": 139.688,
        "_internal_originalTime": 1689780988000
    },
    {
        "time": 1689780989000,
        "value": 139.686,
        "_internal_originalTime": 1689780989000
    },
    {
        "time": 1689780990000,
        "value": 139.682,
        "_internal_originalTime": 1689780990000
    },
    {
        "time": 1689780991000,
        "value": 139.685,
        "_internal_originalTime": 1689780991000
    },
    {
        "time": 1689780993000,
        "value": 139.682,
        "_internal_originalTime": 1689780993000
    },
    {
        "time": 1689780994000,
        "value": 139.684,
        "_internal_originalTime": 1689780994000
    },
    {
        "time": 1689780996000,
        "value": 139.685,
        "_internal_originalTime": 1689780996000
    },
    {
        "time": 1689780998000,
        "value": 139.683,
        "_internal_originalTime": 1689780998000
    },
    {
        "time": 1689781000000,
        "value": 139.684,
        "_internal_originalTime": 1689781000000
    },
    {
        "time": 1689781001000,
        "value": 139.685,
        "_internal_originalTime": 1689781001000
    },
    {
        "time": 1689781002000,
        "value": 139.688,
        "_internal_originalTime": 1689781002000
    },
    {
        "time": 1689781005000,
        "value": 139.683,
        "_internal_originalTime": 1689781005000
    },
    {
        "time": 1689781006000,
        "value": 139.682,
        "_internal_originalTime": 1689781006000
    },
    {
        "time": 1689781007000,
        "value": 139.685,
        "_internal_originalTime": 1689781007000
    },
    {
        "time": 1689781008000,
        "value": 139.682,
        "_internal_originalTime": 1689781008000
    },
    {
        "time": 1689781009000,
        "value": 139.679,
        "_internal_originalTime": 1689781009000
    },
    {
        "time": 1689781010000,
        "value": 139.674,
        "_internal_originalTime": 1689781010000
    },
    {
        "time": 1689781011000,
        "value": 139.676,
        "_internal_originalTime": 1689781011000
    },
    {
        "time": 1689781012000,
        "value": 139.675,
        "_internal_originalTime": 1689781012000
    },
    {
        "time": 1689781014000,
        "value": 139.672,
        "_internal_originalTime": 1689781014000
    },
    {
        "time": 1689781016000,
        "value": 139.685,
        "_internal_originalTime": 1689781016000
    },
    {
        "time": 1689781017000,
        "value": 139.688,
        "_internal_originalTime": 1689781017000
    },
    {
        "time": 1689781019000,
        "value": 139.689,
        "_internal_originalTime": 1689781019000
    },
    {
        "time": 1689781021000,
        "value": 139.684,
        "_internal_originalTime": 1689781021000
    },
    {
        "time": 1689781022000,
        "value": 139.689,
        "_internal_originalTime": 1689781022000
    },
    {
        "time": 1689781023000,
        "value": 139.689,
        "_internal_originalTime": 1689781023000
    },
    {
        "time": 1689781024000,
        "value": 139.684,
        "_internal_originalTime": 1689781024000
    },
    {
        "time": 1689781025000,
        "value": 139.692,
        "_internal_originalTime": 1689781025000
    },
    {
        "time": 1689781026000,
        "value": 139.689,
        "_internal_originalTime": 1689781026000
    },
    {
        "time": 1689781027000,
        "value": 139.691,
        "_internal_originalTime": 1689781027000
    },
    {
        "time": 1689781028000,
        "value": 139.69,
        "_internal_originalTime": 1689781028000
    },
    {
        "time": 1689781029000,
        "value": 139.689,
        "_internal_originalTime": 1689781029000
    },
    {
        "time": 1689781030000,
        "value": 139.689,
        "_internal_originalTime": 1689781030000
    },
    {
        "time": 1689781032000,
        "value": 139.693,
        "_internal_originalTime": 1689781032000
    },
    {
        "time": 1689781033000,
        "value": 139.693,
        "_internal_originalTime": 1689781033000
    },
    {
        "time": 1689781034000,
        "value": 139.692,
        "_internal_originalTime": 1689781034000
    },
    {
        "time": 1689781035000,
        "value": 139.685,
        "_internal_originalTime": 1689781035000
    },
    {
        "time": 1689781037000,
        "value": 139.689,
        "_internal_originalTime": 1689781037000
    },
    {
        "time": 1689781038000,
        "value": 139.688,
        "_internal_originalTime": 1689781038000
    },
    {
        "time": 1689781039000,
        "value": 139.682,
        "_internal_originalTime": 1689781039000
    },
    {
        "time": 1689781041000,
        "value": 139.687,
        "_internal_originalTime": 1689781041000
    },
    {
        "time": 1689781042000,
        "value": 139.684,
        "_internal_originalTime": 1689781042000
    },
    {
        "time": 1689781043000,
        "value": 139.694,
        "_internal_originalTime": 1689781043000
    },
    {
        "time": 1689781045000,
        "value": 139.697,
        "_internal_originalTime": 1689781045000
    },
    {
        "time": 1689781046000,
        "value": 139.702,
        "_internal_originalTime": 1689781046000
    },
    {
        "time": 1689781047000,
        "value": 139.7,
        "_internal_originalTime": 1689781047000
    },
    {
        "time": 1689781048000,
        "value": 139.705,
        "_internal_originalTime": 1689781048000
    },
    {
        "time": 1689781050000,
        "value": 139.702,
        "_internal_originalTime": 1689781050000
    },
    {
        "time": 1689781052000,
        "value": 139.712,
        "_internal_originalTime": 1689781052000
    },
    {
        "time": 1689781053000,
        "value": 139.714,
        "_internal_originalTime": 1689781053000
    },
    {
        "time": 1689781055000,
        "value": 139.716,
        "_internal_originalTime": 1689781055000
    },
    {
        "time": 1689781056000,
        "value": 139.719,
        "_internal_originalTime": 1689781056000
    },
    {
        "time": 1689781058000,
        "value": 139.713,
        "_internal_originalTime": 1689781058000
    },
    {
        "time": 1689781059000,
        "value": 139.716,
        "_internal_originalTime": 1689781059000
    },
    {
        "time": 1689781060000,
        "value": 139.716,
        "_internal_originalTime": 1689781060000
    },
    {
        "time": 1689781061000,
        "value": 139.716,
        "_internal_originalTime": 1689781061000
    },
    {
        "time": 1689781062000,
        "value": 139.715,
        "_internal_originalTime": 1689781062000
    },
    {
        "time": 1689781063000,
        "value": 139.708,
        "_internal_originalTime": 1689781063000
    },
    {
        "time": 1689781065000,
        "value": 139.714,
        "_internal_originalTime": 1689781065000
    },
    {
        "time": 1689781067000,
        "value": 139.714,
        "_internal_originalTime": 1689781067000
    },
    {
        "time": 1689781068000,
        "value": 139.714,
        "_internal_originalTime": 1689781068000
    },
    {
        "time": 1689781070000,
        "value": 139.713,
        "_internal_originalTime": 1689781070000
    },
    {
        "time": 1689781072000,
        "value": 139.715,
        "_internal_originalTime": 1689781072000
    },
    {
        "time": 1689781073000,
        "value": 139.71,
        "_internal_originalTime": 1689781073000
    },
    {
        "time": 1689781074000,
        "value": 139.71,
        "_internal_originalTime": 1689781074000
    },
    {
        "time": 1689781075000,
        "value": 139.714,
        "_internal_originalTime": 1689781075000
    },
    {
        "time": 1689781076000,
        "value": 139.708,
        "_internal_originalTime": 1689781076000
    },
    {
        "time": 1689781077000,
        "value": 139.71,
        "_internal_originalTime": 1689781077000
    },
    {
        "time": 1689781079000,
        "value": 139.711,
        "_internal_originalTime": 1689781079000
    },
    {
        "time": 1689781080000,
        "value": 139.716,
        "_internal_originalTime": 1689781080000
    },
    {
        "time": 1689781081000,
        "value": 139.717,
        "_internal_originalTime": 1689781081000
    },
    {
        "time": 1689781082000,
        "value": 139.716,
        "_internal_originalTime": 1689781082000
    },
    {
        "time": 1689781083000,
        "value": 139.718,
        "_internal_originalTime": 1689781083000
    },
    {
        "time": 1689781084000,
        "value": 139.718,
        "_internal_originalTime": 1689781084000
    },
    {
        "time": 1689781086000,
        "value": 139.722,
        "_internal_originalTime": 1689781086000
    },
    {
        "time": 1689781087000,
        "value": 139.729,
        "_internal_originalTime": 1689781087000
    },
    {
        "time": 1689781088000,
        "value": 139.726,
        "_internal_originalTime": 1689781088000
    },
    {
        "time": 1689781090000,
        "value": 139.725,
        "_internal_originalTime": 1689781090000
    },
    {
        "time": 1689781091000,
        "value": 139.718,
        "_internal_originalTime": 1689781091000
    },
    {
        "time": 1689781092000,
        "value": 139.718,
        "_internal_originalTime": 1689781092000
    },
    {
        "time": 1689781093000,
        "value": 139.714,
        "_internal_originalTime": 1689781093000
    },
    {
        "time": 1689781095000,
        "value": 139.717,
        "_internal_originalTime": 1689781095000
    },
    {
        "time": 1689781096000,
        "value": 139.708,
        "_internal_originalTime": 1689781096000
    },
    {
        "time": 1689781098000,
        "value": 139.711,
        "_internal_originalTime": 1689781098000
    },
    {
        "time": 1689781099000,
        "value": 139.714,
        "_internal_originalTime": 1689781099000
    },
    {
        "time": 1689781100000,
        "value": 139.718,
        "_internal_originalTime": 1689781100000
    },
    {
        "time": 1689781101000,
        "value": 139.721,
        "_internal_originalTime": 1689781101000
    },
    {
        "time": 1689781102000,
        "value": 139.711,
        "_internal_originalTime": 1689781102000
    },
    {
        "time": 1689781103000,
        "value": 139.707,
        "_internal_originalTime": 1689781103000
    },
    {
        "time": 1689781104000,
        "value": 139.711,
        "_internal_originalTime": 1689781104000
    },
    {
        "time": 1689781105000,
        "value": 139.71,
        "_internal_originalTime": 1689781105000
    },
    {
        "time": 1689781106000,
        "value": 139.714,
        "_internal_originalTime": 1689781106000
    },
    {
        "time": 1689781107000,
        "value": 139.712,
        "_internal_originalTime": 1689781107000
    },
    {
        "time": 1689781108000,
        "value": 139.707,
        "_internal_originalTime": 1689781108000
    },
    {
        "time": 1689781109000,
        "value": 139.712,
        "_internal_originalTime": 1689781109000
    },
    {
        "time": 1689781110000,
        "value": 139.711,
        "_internal_originalTime": 1689781110000
    },
    {
        "time": 1689781111000,
        "value": 139.707,
        "_internal_originalTime": 1689781111000
    },
    {
        "time": 1689781113000,
        "value": 139.708,
        "_internal_originalTime": 1689781113000
    },
    {
        "time": 1689781114000,
        "value": 139.712,
        "_internal_originalTime": 1689781114000
    },
    {
        "time": 1689781115000,
        "value": 139.712,
        "_internal_originalTime": 1689781115000
    },
    {
        "time": 1689781116000,
        "value": 139.71,
        "_internal_originalTime": 1689781116000
    },
    {
        "time": 1689781117000,
        "value": 139.712,
        "_internal_originalTime": 1689781117000
    },
    {
        "time": 1689781118000,
        "value": 139.711,
        "_internal_originalTime": 1689781118000
    },
    {
        "time": 1689781120000,
        "value": 139.712,
        "_internal_originalTime": 1689781120000
    },
    {
        "time": 1689781121000,
        "value": 139.707,
        "_internal_originalTime": 1689781121000
    },
    {
        "time": 1689781122000,
        "value": 139.711,
        "_internal_originalTime": 1689781122000
    },
    {
        "time": 1689781123000,
        "value": 139.711,
        "_internal_originalTime": 1689781123000
    },
    {
        "time": 1689781127000,
        "value": 139.711,
        "_internal_originalTime": 1689781127000
    },
    {
        "time": 1689781128000,
        "value": 139.707,
        "_internal_originalTime": 1689781128000
    },
    {
        "time": 1689781129000,
        "value": 139.71,
        "_internal_originalTime": 1689781129000
    },
    {
        "time": 1689781130000,
        "value": 139.709,
        "_internal_originalTime": 1689781130000
    },
    {
        "time": 1689781131000,
        "value": 139.704,
        "_internal_originalTime": 1689781131000
    },
    {
        "time": 1689781132000,
        "value": 139.708,
        "_internal_originalTime": 1689781132000
    },
    {
        "time": 1689781133000,
        "value": 139.707,
        "_internal_originalTime": 1689781133000
    },
    {
        "time": 1689781136000,
        "value": 139.707,
        "_internal_originalTime": 1689781136000
    },
    {
        "time": 1689781137000,
        "value": 139.707,
        "_internal_originalTime": 1689781137000
    },
    {
        "time": 1689781138000,
        "value": 139.707,
        "_internal_originalTime": 1689781138000
    },
    {
        "time": 1689781140000,
        "value": 139.705,
        "_internal_originalTime": 1689781140000
    },
    {
        "time": 1689781141000,
        "value": 139.705,
        "_internal_originalTime": 1689781141000
    },
    {
        "time": 1689781142000,
        "value": 139.702,
        "_internal_originalTime": 1689781142000
    },
    {
        "time": 1689781143000,
        "value": 139.702,
        "_internal_originalTime": 1689781143000
    },
    {
        "time": 1689781144000,
        "value": 139.705,
        "_internal_originalTime": 1689781144000
    },
    {
        "time": 1689781146000,
        "value": 139.709,
        "_internal_originalTime": 1689781146000
    },
    {
        "time": 1689781147000,
        "value": 139.711,
        "_internal_originalTime": 1689781147000
    },
    {
        "time": 1689781149000,
        "value": 139.713,
        "_internal_originalTime": 1689781149000
    },
    {
        "time": 1689781150000,
        "value": 139.715,
        "_internal_originalTime": 1689781150000
    },
    {
        "time": 1689781152000,
        "value": 139.71,
        "_internal_originalTime": 1689781152000
    },
    {
        "time": 1689781153000,
        "value": 139.714,
        "_internal_originalTime": 1689781153000
    },
    {
        "time": 1689781155000,
        "value": 139.711,
        "_internal_originalTime": 1689781155000
    },
    {
        "time": 1689781156000,
        "value": 139.711,
        "_internal_originalTime": 1689781156000
    },
    {
        "time": 1689781158000,
        "value": 139.711,
        "_internal_originalTime": 1689781158000
    },
    {
        "time": 1689781159000,
        "value": 139.704,
        "_internal_originalTime": 1689781159000
    },
    {
        "time": 1689781160000,
        "value": 139.712,
        "_internal_originalTime": 1689781160000
    },
    {
        "time": 1689781161000,
        "value": 139.712,
        "_internal_originalTime": 1689781161000
    },
    {
        "time": 1689781162000,
        "value": 139.706,
        "_internal_originalTime": 1689781162000
    },
    {
        "time": 1689781164000,
        "value": 139.704,
        "_internal_originalTime": 1689781164000
    },
    {
        "time": 1689781165000,
        "value": 139.71,
        "_internal_originalTime": 1689781165000
    },
    {
        "time": 1689781166000,
        "value": 139.712,
        "_internal_originalTime": 1689781166000
    },
    {
        "time": 1689781167000,
        "value": 139.715,
        "_internal_originalTime": 1689781167000
    },
    {
        "time": 1689781170000,
        "value": 139.715,
        "_internal_originalTime": 1689781170000
    },
    {
        "time": 1689781171000,
        "value": 139.716,
        "_internal_originalTime": 1689781171000
    },
    {
        "time": 1689781172000,
        "value": 139.715,
        "_internal_originalTime": 1689781172000
    },
    {
        "time": 1689781173000,
        "value": 139.715,
        "_internal_originalTime": 1689781173000
    },
    {
        "time": 1689781174000,
        "value": 139.712,
        "_internal_originalTime": 1689781174000
    },
    {
        "time": 1689781175000,
        "value": 139.712,
        "_internal_originalTime": 1689781175000
    },
    {
        "time": 1689781177000,
        "value": 139.713,
        "_internal_originalTime": 1689781177000
    },
    {
        "time": 1689781178000,
        "value": 139.709,
        "_internal_originalTime": 1689781178000
    },
    {
        "time": 1689781180000,
        "value": 139.708,
        "_internal_originalTime": 1689781180000
    },
    {
        "time": 1689781181000,
        "value": 139.715,
        "_internal_originalTime": 1689781181000
    },
    {
        "time": 1689781182000,
        "value": 139.715,
        "_internal_originalTime": 1689781182000
    },
    {
        "time": 1689781184000,
        "value": 139.715,
        "_internal_originalTime": 1689781184000
    },
    {
        "time": 1689781185000,
        "value": 139.715,
        "_internal_originalTime": 1689781185000
    },
    {
        "time": 1689781186000,
        "value": 139.71,
        "_internal_originalTime": 1689781186000
    },
    {
        "time": 1689781187000,
        "value": 139.71,
        "_internal_originalTime": 1689781187000
    },
    {
        "time": 1689781188000,
        "value": 139.71,
        "_internal_originalTime": 1689781188000
    },
    {
        "time": 1689781189000,
        "value": 139.717,
        "_internal_originalTime": 1689781189000
    },
    {
        "time": 1689781190000,
        "value": 139.711,
        "_internal_originalTime": 1689781190000
    },
    {
        "time": 1689781191000,
        "value": 139.711,
        "_internal_originalTime": 1689781191000
    },
    {
        "time": 1689781192000,
        "value": 139.712,
        "_internal_originalTime": 1689781192000
    },
    {
        "time": 1689781193000,
        "value": 139.708,
        "_internal_originalTime": 1689781193000
    },
    {
        "time": 1689781194000,
        "value": 139.716,
        "_internal_originalTime": 1689781194000
    },
    {
        "time": 1689781195000,
        "value": 139.713,
        "_internal_originalTime": 1689781195000
    },
    {
        "time": 1689781196000,
        "value": 139.717,
        "_internal_originalTime": 1689781196000
    },
    {
        "time": 1689781197000,
        "value": 139.717,
        "_internal_originalTime": 1689781197000
    },
    {
        "time": 1689781199000,
        "value": 139.718,
        "_internal_originalTime": 1689781199000
    },
    {
        "time": 1689781200000,
        "value": 139.714,
        "_internal_originalTime": 1689781200000
    },
    {
        "time": 1689781201000,
        "value": 139.718,
        "_internal_originalTime": 1689781201000
    },
    {
        "time": 1689781202000,
        "value": 139.719,
        "_internal_originalTime": 1689781202000
    },
    {
        "time": 1689781203000,
        "value": 139.715,
        "_internal_originalTime": 1689781203000
    },
    {
        "time": 1689781205000,
        "value": 139.715,
        "_internal_originalTime": 1689781205000
    },
    {
        "time": 1689781206000,
        "value": 139.714,
        "_internal_originalTime": 1689781206000
    },
    {
        "time": 1689781208000,
        "value": 139.716,
        "_internal_originalTime": 1689781208000
    },
    {
        "time": 1689781209000,
        "value": 139.708,
        "_internal_originalTime": 1689781209000
    },
    {
        "time": 1689781210000,
        "value": 139.711,
        "_internal_originalTime": 1689781210000
    },
    {
        "time": 1689781211000,
        "value": 139.719,
        "_internal_originalTime": 1689781211000
    },
    {
        "time": 1689781212000,
        "value": 139.714,
        "_internal_originalTime": 1689781212000
    },
    {
        "time": 1689781213000,
        "value": 139.716,
        "_internal_originalTime": 1689781213000
    },
    {
        "time": 1689781215000,
        "value": 139.714,
        "_internal_originalTime": 1689781215000
    },
    {
        "time": 1689781216000,
        "value": 139.706,
        "_internal_originalTime": 1689781216000
    },
    {
        "time": 1689781217000,
        "value": 139.705,
        "_internal_originalTime": 1689781217000
    },
    {
        "time": 1689781218000,
        "value": 139.705,
        "_internal_originalTime": 1689781218000
    },
    {
        "time": 1689781219000,
        "value": 139.705,
        "_internal_originalTime": 1689781219000
    },
    {
        "time": 1689781220000,
        "value": 139.702,
        "_internal_originalTime": 1689781220000
    },
    {
        "time": 1689781221000,
        "value": 139.702,
        "_internal_originalTime": 1689781221000
    },
    {
        "time": 1689781222000,
        "value": 139.706,
        "_internal_originalTime": 1689781222000
    },
    {
        "time": 1689781223000,
        "value": 139.702,
        "_internal_originalTime": 1689781223000
    },
    {
        "time": 1689781224000,
        "value": 139.705,
        "_internal_originalTime": 1689781224000
    },
    {
        "time": 1689781225000,
        "value": 139.702,
        "_internal_originalTime": 1689781225000
    },
    {
        "time": 1689781226000,
        "value": 139.702,
        "_internal_originalTime": 1689781226000
    },
    {
        "time": 1689781229000,
        "value": 139.702,
        "_internal_originalTime": 1689781229000
    },
    {
        "time": 1689781230000,
        "value": 139.702,
        "_internal_originalTime": 1689781230000
    },
    {
        "time": 1689781231000,
        "value": 139.702,
        "_internal_originalTime": 1689781231000
    },
    {
        "time": 1689781232000,
        "value": 139.702,
        "_internal_originalTime": 1689781232000
    },
    {
        "time": 1689781233000,
        "value": 139.706,
        "_internal_originalTime": 1689781233000
    },
    {
        "time": 1689781235000,
        "value": 139.706,
        "_internal_originalTime": 1689781235000
    },
    {
        "time": 1689781236000,
        "value": 139.706,
        "_internal_originalTime": 1689781236000
    },
    {
        "time": 1689781238000,
        "value": 139.705,
        "_internal_originalTime": 1689781238000
    },
    {
        "time": 1689781239000,
        "value": 139.706,
        "_internal_originalTime": 1689781239000
    },
    {
        "time": 1689781240000,
        "value": 139.702,
        "_internal_originalTime": 1689781240000
    },
    {
        "time": 1689781241000,
        "value": 139.7,
        "_internal_originalTime": 1689781241000
    },
    {
        "time": 1689781243000,
        "value": 139.694,
        "_internal_originalTime": 1689781243000
    },
    {
        "time": 1689781244000,
        "value": 139.694,
        "_internal_originalTime": 1689781244000
    },
    {
        "time": 1689781245000,
        "value": 139.698,
        "_internal_originalTime": 1689781245000
    },
    {
        "time": 1689781246000,
        "value": 139.704,
        "_internal_originalTime": 1689781246000
    },
    {
        "time": 1689781247000,
        "value": 139.706,
        "_internal_originalTime": 1689781247000
    },
    {
        "time": 1689781249000,
        "value": 139.706,
        "_internal_originalTime": 1689781249000
    },
    {
        "time": 1689781250000,
        "value": 139.702,
        "_internal_originalTime": 1689781250000
    },
    {
        "time": 1689781252000,
        "value": 139.706,
        "_internal_originalTime": 1689781252000
    },
    {
        "time": 1689781253000,
        "value": 139.702,
        "_internal_originalTime": 1689781253000
    },
    {
        "time": 1689781255000,
        "value": 139.698,
        "_internal_originalTime": 1689781255000
    },
    {
        "time": 1689781257000,
        "value": 139.697,
        "_internal_originalTime": 1689781257000
    },
    {
        "time": 1689781258000,
        "value": 139.694,
        "_internal_originalTime": 1689781258000
    },
    {
        "time": 1689781260000,
        "value": 139.692,
        "_internal_originalTime": 1689781260000
    },
    {
        "time": 1689781261000,
        "value": 139.687,
        "_internal_originalTime": 1689781261000
    },
    {
        "time": 1689781262000,
        "value": 139.688,
        "_internal_originalTime": 1689781262000
    },
    {
        "time": 1689781263000,
        "value": 139.687,
        "_internal_originalTime": 1689781263000
    },
    {
        "time": 1689781264000,
        "value": 139.688,
        "_internal_originalTime": 1689781264000
    },
    {
        "time": 1689781265000,
        "value": 139.689,
        "_internal_originalTime": 1689781265000
    },
    {
        "time": 1689781266000,
        "value": 139.689,
        "_internal_originalTime": 1689781266000
    },
    {
        "time": 1689781267000,
        "value": 139.686,
        "_internal_originalTime": 1689781267000
    },
    {
        "time": 1689781268000,
        "value": 139.691,
        "_internal_originalTime": 1689781268000
    },
    {
        "time": 1689781269000,
        "value": 139.692,
        "_internal_originalTime": 1689781269000
    },
    {
        "time": 1689781270000,
        "value": 139.689,
        "_internal_originalTime": 1689781270000
    },
    {
        "time": 1689781271000,
        "value": 139.688,
        "_internal_originalTime": 1689781271000
    },
    {
        "time": 1689781272000,
        "value": 139.689,
        "_internal_originalTime": 1689781272000
    },
    {
        "time": 1689781274000,
        "value": 139.681,
        "_internal_originalTime": 1689781274000
    },
    {
        "time": 1689781275000,
        "value": 139.688,
        "_internal_originalTime": 1689781275000
    },
    {
        "time": 1689781276000,
        "value": 139.689,
        "_internal_originalTime": 1689781276000
    },
    {
        "time": 1689781277000,
        "value": 139.688,
        "_internal_originalTime": 1689781277000
    },
    {
        "time": 1689781281000,
        "value": 139.687,
        "_internal_originalTime": 1689781281000
    },
    {
        "time": 1689781283000,
        "value": 139.691,
        "_internal_originalTime": 1689781283000
    },
    {
        "time": 1689781285000,
        "value": 139.694,
        "_internal_originalTime": 1689781285000
    },
    {
        "time": 1689781286000,
        "value": 139.698,
        "_internal_originalTime": 1689781286000
    },
    {
        "time": 1689781287000,
        "value": 139.701,
        "_internal_originalTime": 1689781287000
    },
    {
        "time": 1689781288000,
        "value": 139.697,
        "_internal_originalTime": 1689781288000
    },
    {
        "time": 1689781289000,
        "value": 139.696,
        "_internal_originalTime": 1689781289000
    },
    {
        "time": 1689781290000,
        "value": 139.702,
        "_internal_originalTime": 1689781290000
    },
    {
        "time": 1689781291000,
        "value": 139.702,
        "_internal_originalTime": 1689781291000
    },
    {
        "time": 1689781292000,
        "value": 139.7,
        "_internal_originalTime": 1689781292000
    },
    {
        "time": 1689781293000,
        "value": 139.705,
        "_internal_originalTime": 1689781293000
    },
    {
        "time": 1689781294000,
        "value": 139.705,
        "_internal_originalTime": 1689781294000
    },
    {
        "time": 1689781295000,
        "value": 139.705,
        "_internal_originalTime": 1689781295000
    },
    {
        "time": 1689781297000,
        "value": 139.702,
        "_internal_originalTime": 1689781297000
    },
    {
        "time": 1689781298000,
        "value": 139.703,
        "_internal_originalTime": 1689781298000
    },
    {
        "time": 1689781299000,
        "value": 139.707,
        "_internal_originalTime": 1689781299000
    },
    {
        "time": 1689781300000,
        "value": 139.707,
        "_internal_originalTime": 1689781300000
    },
    {
        "time": 1689781301000,
        "value": 139.706,
        "_internal_originalTime": 1689781301000
    },
    {
        "time": 1689781302000,
        "value": 139.707,
        "_internal_originalTime": 1689781302000
    },
    {
        "time": 1689781303000,
        "value": 139.707,
        "_internal_originalTime": 1689781303000
    },
    {
        "time": 1689781304000,
        "value": 139.703,
        "_internal_originalTime": 1689781304000
    },
    {
        "time": 1689781305000,
        "value": 139.707,
        "_internal_originalTime": 1689781305000
    },
    {
        "time": 1689781306000,
        "value": 139.707,
        "_internal_originalTime": 1689781306000
    },
    {
        "time": 1689781309000,
        "value": 139.702,
        "_internal_originalTime": 1689781309000
    },
    {
        "time": 1689781310000,
        "value": 139.705,
        "_internal_originalTime": 1689781310000
    },
    {
        "time": 1689781311000,
        "value": 139.709,
        "_internal_originalTime": 1689781311000
    },
    {
        "time": 1689781313000,
        "value": 139.709,
        "_internal_originalTime": 1689781313000
    },
    {
        "time": 1689781314000,
        "value": 139.706,
        "_internal_originalTime": 1689781314000
    },
    {
        "time": 1689781315000,
        "value": 139.702,
        "_internal_originalTime": 1689781315000
    },
    {
        "time": 1689781316000,
        "value": 139.702,
        "_internal_originalTime": 1689781316000
    },
    {
        "time": 1689781318000,
        "value": 139.704,
        "_internal_originalTime": 1689781318000
    },
    {
        "time": 1689781319000,
        "value": 139.707,
        "_internal_originalTime": 1689781319000
    },
    {
        "time": 1689781320000,
        "value": 139.707,
        "_internal_originalTime": 1689781320000
    },
    {
        "time": 1689781321000,
        "value": 139.707,
        "_internal_originalTime": 1689781321000
    },
    {
        "time": 1689781322000,
        "value": 139.705,
        "_internal_originalTime": 1689781322000
    },
    {
        "time": 1689781323000,
        "value": 139.707,
        "_internal_originalTime": 1689781323000
    },
    {
        "time": 1689781324000,
        "value": 139.702,
        "_internal_originalTime": 1689781324000
    },
    {
        "time": 1689781325000,
        "value": 139.7,
        "_internal_originalTime": 1689781325000
    },
    {
        "time": 1689781327000,
        "value": 139.7,
        "_internal_originalTime": 1689781327000
    },
    {
        "time": 1689781328000,
        "value": 139.696,
        "_internal_originalTime": 1689781328000
    },
    {
        "time": 1689781329000,
        "value": 139.702,
        "_internal_originalTime": 1689781329000
    },
    {
        "time": 1689781330000,
        "value": 139.697,
        "_internal_originalTime": 1689781330000
    },
    {
        "time": 1689781333000,
        "value": 139.7,
        "_internal_originalTime": 1689781333000
    },
    {
        "time": 1689781334000,
        "value": 139.694,
        "_internal_originalTime": 1689781334000
    },
    {
        "time": 1689781335000,
        "value": 139.701,
        "_internal_originalTime": 1689781335000
    },
    {
        "time": 1689781337000,
        "value": 139.703,
        "_internal_originalTime": 1689781337000
    },
    {
        "time": 1689781338000,
        "value": 139.703,
        "_internal_originalTime": 1689781338000
    },
    {
        "time": 1689781340000,
        "value": 139.698,
        "_internal_originalTime": 1689781340000
    },
    {
        "time": 1689781342000,
        "value": 139.697,
        "_internal_originalTime": 1689781342000
    },
    {
        "time": 1689781343000,
        "value": 139.699,
        "_internal_originalTime": 1689781343000
    },
    {
        "time": 1689781351000,
        "value": 139.699,
        "_internal_originalTime": 1689781351000
    },
    {
        "time": 1689781352000,
        "value": 139.699,
        "_internal_originalTime": 1689781352000
    },
    {
        "time": 1689781353000,
        "value": 139.696,
        "_internal_originalTime": 1689781353000
    },
    {
        "time": 1689781354000,
        "value": 139.697,
        "_internal_originalTime": 1689781354000
    },
    {
        "time": 1689781355000,
        "value": 139.692,
        "_internal_originalTime": 1689781355000
    },
    {
        "time": 1689781356000,
        "value": 139.696,
        "_internal_originalTime": 1689781356000
    },
    {
        "time": 1689781358000,
        "value": 139.695,
        "_internal_originalTime": 1689781358000
    },
    {
        "time": 1689781360000,
        "value": 139.682,
        "_internal_originalTime": 1689781360000
    },
    {
        "time": 1689781363000,
        "value": 139.686,
        "_internal_originalTime": 1689781363000
    },
    {
        "time": 1689781364000,
        "value": 139.687,
        "_internal_originalTime": 1689781364000
    },
    {
        "time": 1689781366000,
        "value": 139.683,
        "_internal_originalTime": 1689781366000
    },
    {
        "time": 1689781367000,
        "value": 139.685,
        "_internal_originalTime": 1689781367000
    },
    {
        "time": 1689781368000,
        "value": 139.687,
        "_internal_originalTime": 1689781368000
    },
    {
        "time": 1689781369000,
        "value": 139.684,
        "_internal_originalTime": 1689781369000
    },
    {
        "time": 1689781371000,
        "value": 139.683,
        "_internal_originalTime": 1689781371000
    },
    {
        "time": 1689781373000,
        "value": 139.684,
        "_internal_originalTime": 1689781373000
    },
    {
        "time": 1689781374000,
        "value": 139.686,
        "_internal_originalTime": 1689781374000
    },
    {
        "time": 1689781375000,
        "value": 139.687,
        "_internal_originalTime": 1689781375000
    },
    {
        "time": 1689781376000,
        "value": 139.687,
        "_internal_originalTime": 1689781376000
    },
    {
        "time": 1689781377000,
        "value": 139.686,
        "_internal_originalTime": 1689781377000
    },
    {
        "time": 1689781379000,
        "value": 139.687,
        "_internal_originalTime": 1689781379000
    },
    {
        "time": 1689781380000,
        "value": 139.69,
        "_internal_originalTime": 1689781380000
    },
    {
        "time": 1689781381000,
        "value": 139.691,
        "_internal_originalTime": 1689781381000
    },
    {
        "time": 1689781382000,
        "value": 139.693,
        "_internal_originalTime": 1689781382000
    },
    {
        "time": 1689781384000,
        "value": 139.695,
        "_internal_originalTime": 1689781384000
    },
    {
        "time": 1689781386000,
        "value": 139.687,
        "_internal_originalTime": 1689781386000
    },
    {
        "time": 1689781387000,
        "value": 139.687,
        "_internal_originalTime": 1689781387000
    },
    {
        "time": 1689781388000,
        "value": 139.689,
        "_internal_originalTime": 1689781388000
    },
    {
        "time": 1689781389000,
        "value": 139.693,
        "_internal_originalTime": 1689781389000
    },
    {
        "time": 1689781390000,
        "value": 139.692,
        "_internal_originalTime": 1689781390000
    },
    {
        "time": 1689781392000,
        "value": 139.689,
        "_internal_originalTime": 1689781392000
    },
    {
        "time": 1689781394000,
        "value": 139.685,
        "_internal_originalTime": 1689781394000
    },
    {
        "time": 1689781396000,
        "value": 139.684,
        "_internal_originalTime": 1689781396000
    },
    {
        "time": 1689781398000,
        "value": 139.688,
        "_internal_originalTime": 1689781398000
    },
    {
        "time": 1689781400000,
        "value": 139.688,
        "_internal_originalTime": 1689781400000
    },
    {
        "time": 1689781401000,
        "value": 139.689,
        "_internal_originalTime": 1689781401000
    },
    {
        "time": 1689781405000,
        "value": 139.688,
        "_internal_originalTime": 1689781405000
    },
    {
        "time": 1689781406000,
        "value": 139.682,
        "_internal_originalTime": 1689781406000
    },
    {
        "time": 1689781407000,
        "value": 139.688,
        "_internal_originalTime": 1689781407000
    },
    {
        "time": 1689781408000,
        "value": 139.685,
        "_internal_originalTime": 1689781408000
    },
    {
        "time": 1689781409000,
        "value": 139.684,
        "_internal_originalTime": 1689781409000
    },
    {
        "time": 1689781410000,
        "value": 139.684,
        "_internal_originalTime": 1689781410000
    },
    {
        "time": 1689781411000,
        "value": 139.684,
        "_internal_originalTime": 1689781411000
    },
    {
        "time": 1689781412000,
        "value": 139.684,
        "_internal_originalTime": 1689781412000
    },
    {
        "time": 1689781413000,
        "value": 139.688,
        "_internal_originalTime": 1689781413000
    },
    {
        "time": 1689781414000,
        "value": 139.693,
        "_internal_originalTime": 1689781414000
    },
    {
        "time": 1689781416000,
        "value": 139.696,
        "_internal_originalTime": 1689781416000
    },
    {
        "time": 1689781418000,
        "value": 139.696,
        "_internal_originalTime": 1689781418000
    },
    {
        "time": 1689781419000,
        "value": 139.697,
        "_internal_originalTime": 1689781419000
    },
    {
        "time": 1689781421000,
        "value": 139.699,
        "_internal_originalTime": 1689781421000
    },
    {
        "time": 1689781422000,
        "value": 139.694,
        "_internal_originalTime": 1689781422000
    },
    {
        "time": 1689781423000,
        "value": 139.695,
        "_internal_originalTime": 1689781423000
    },
    {
        "time": 1689781424000,
        "value": 139.693,
        "_internal_originalTime": 1689781424000
    },
    {
        "time": 1689781425000,
        "value": 139.691,
        "_internal_originalTime": 1689781425000
    },
    {
        "time": 1689781427000,
        "value": 139.695,
        "_internal_originalTime": 1689781427000
    },
    {
        "time": 1689781429000,
        "value": 139.69,
        "_internal_originalTime": 1689781429000
    },
    {
        "time": 1689781431000,
        "value": 139.693,
        "_internal_originalTime": 1689781431000
    },
    {
        "time": 1689781432000,
        "value": 139.692,
        "_internal_originalTime": 1689781432000
    },
    {
        "time": 1689781433000,
        "value": 139.695,
        "_internal_originalTime": 1689781433000
    },
    {
        "time": 1689781435000,
        "value": 139.68,
        "_internal_originalTime": 1689781435000
    },
    {
        "time": 1689781436000,
        "value": 139.681,
        "_internal_originalTime": 1689781436000
    },
    {
        "time": 1689781438000,
        "value": 139.681,
        "_internal_originalTime": 1689781438000
    },
    {
        "time": 1689781439000,
        "value": 139.687,
        "_internal_originalTime": 1689781439000
    },
    {
        "time": 1689781440000,
        "value": 139.682,
        "_internal_originalTime": 1689781440000
    },
    {
        "time": 1689781441000,
        "value": 139.685,
        "_internal_originalTime": 1689781441000
    },
    {
        "time": 1689781444000,
        "value": 139.68,
        "_internal_originalTime": 1689781444000
    },
    {
        "time": 1689781445000,
        "value": 139.683,
        "_internal_originalTime": 1689781445000
    },
    {
        "time": 1689781446000,
        "value": 139.681,
        "_internal_originalTime": 1689781446000
    },
    {
        "time": 1689781447000,
        "value": 139.686,
        "_internal_originalTime": 1689781447000
    },
    {
        "time": 1689781448000,
        "value": 139.694,
        "_internal_originalTime": 1689781448000
    },
    {
        "time": 1689781449000,
        "value": 139.691,
        "_internal_originalTime": 1689781449000
    },
    {
        "time": 1689781450000,
        "value": 139.69,
        "_internal_originalTime": 1689781450000
    },
    {
        "time": 1689781454000,
        "value": 139.689,
        "_internal_originalTime": 1689781454000
    },
    {
        "time": 1689781460000,
        "value": 139.684,
        "_internal_originalTime": 1689781460000
    },
    {
        "time": 1689781462000,
        "value": 139.687,
        "_internal_originalTime": 1689781462000
    },
    {
        "time": 1689781464000,
        "value": 139.687,
        "_internal_originalTime": 1689781464000
    },
    {
        "time": 1689781465000,
        "value": 139.683,
        "_internal_originalTime": 1689781465000
    },
    {
        "time": 1689781467000,
        "value": 139.682,
        "_internal_originalTime": 1689781467000
    },
    {
        "time": 1689781469000,
        "value": 139.68,
        "_internal_originalTime": 1689781469000
    },
    {
        "time": 1689781470000,
        "value": 139.68,
        "_internal_originalTime": 1689781470000
    },
    {
        "time": 1689781472000,
        "value": 139.683,
        "_internal_originalTime": 1689781472000
    },
    {
        "time": 1689781473000,
        "value": 139.685,
        "_internal_originalTime": 1689781473000
    },
    {
        "time": 1689781475000,
        "value": 139.691,
        "_internal_originalTime": 1689781475000
    },
    {
        "time": 1689781476000,
        "value": 139.693,
        "_internal_originalTime": 1689781476000
    },
    {
        "time": 1689781478000,
        "value": 139.694,
        "_internal_originalTime": 1689781478000
    },
    {
        "time": 1689781479000,
        "value": 139.695,
        "_internal_originalTime": 1689781479000
    },
    {
        "time": 1689781480000,
        "value": 139.696,
        "_internal_originalTime": 1689781480000
    },
    {
        "time": 1689781481000,
        "value": 139.697,
        "_internal_originalTime": 1689781481000
    },
    {
        "time": 1689781484000,
        "value": 139.693,
        "_internal_originalTime": 1689781484000
    },
    {
        "time": 1689781485000,
        "value": 139.699,
        "_internal_originalTime": 1689781485000
    },
    {
        "time": 1689781488000,
        "value": 139.692,
        "_internal_originalTime": 1689781488000
    },
    {
        "time": 1689781489000,
        "value": 139.694,
        "_internal_originalTime": 1689781489000
    },
    {
        "time": 1689781490000,
        "value": 139.694,
        "_internal_originalTime": 1689781490000
    },
    {
        "time": 1689781491000,
        "value": 139.696,
        "_internal_originalTime": 1689781491000
    },
    {
        "time": 1689781492000,
        "value": 139.69,
        "_internal_originalTime": 1689781492000
    },
    {
        "time": 1689781497000,
        "value": 139.692,
        "_internal_originalTime": 1689781497000
    },
    {
        "time": 1689781498000,
        "value": 139.692,
        "_internal_originalTime": 1689781498000
    },
    {
        "time": 1689781499000,
        "value": 139.696,
        "_internal_originalTime": 1689781499000
    },
    {
        "time": 1689781502000,
        "value": 139.692,
        "_internal_originalTime": 1689781502000
    },
    {
        "time": 1689781503000,
        "value": 139.689,
        "_internal_originalTime": 1689781503000
    },
    {
        "time": 1689781505000,
        "value": 139.686,
        "_internal_originalTime": 1689781505000
    },
    {
        "time": 1689781506000,
        "value": 139.674,
        "_internal_originalTime": 1689781506000
    },
    {
        "time": 1689781507000,
        "value": 139.674,
        "_internal_originalTime": 1689781507000
    },
    {
        "time": 1689781508000,
        "value": 139.674,
        "_internal_originalTime": 1689781508000
    },
    {
        "time": 1689781509000,
        "value": 139.674,
        "_internal_originalTime": 1689781509000
    },
    {
        "time": 1689781510000,
        "value": 139.676,
        "_internal_originalTime": 1689781510000
    },
    {
        "time": 1689781513000,
        "value": 139.674,
        "_internal_originalTime": 1689781513000
    },
    {
        "time": 1689781515000,
        "value": 139.677,
        "_internal_originalTime": 1689781515000
    },
    {
        "time": 1689781517000,
        "value": 139.672,
        "_internal_originalTime": 1689781517000
    },
    {
        "time": 1689781518000,
        "value": 139.676,
        "_internal_originalTime": 1689781518000
    },
    {
        "time": 1689781520000,
        "value": 139.672,
        "_internal_originalTime": 1689781520000
    },
    {
        "time": 1689781521000,
        "value": 139.676,
        "_internal_originalTime": 1689781521000
    },
    {
        "time": 1689781523000,
        "value": 139.675,
        "_internal_originalTime": 1689781523000
    },
    {
        "time": 1689781524000,
        "value": 139.677,
        "_internal_originalTime": 1689781524000
    },
    {
        "time": 1689781525000,
        "value": 139.675,
        "_internal_originalTime": 1689781525000
    },
    {
        "time": 1689781526000,
        "value": 139.676,
        "_internal_originalTime": 1689781526000
    },
    {
        "time": 1689781527000,
        "value": 139.676,
        "_internal_originalTime": 1689781527000
    },
    {
        "time": 1689781529000,
        "value": 139.675,
        "_internal_originalTime": 1689781529000
    },
    {
        "time": 1689781535000,
        "value": 139.677,
        "_internal_originalTime": 1689781535000
    },
    {
        "time": 1689781536000,
        "value": 139.674,
        "_internal_originalTime": 1689781536000
    },
    {
        "time": 1689781537000,
        "value": 139.677,
        "_internal_originalTime": 1689781537000
    },
    {
        "time": 1689781538000,
        "value": 139.67,
        "_internal_originalTime": 1689781538000
    },
    {
        "time": 1689781539000,
        "value": 139.672,
        "_internal_originalTime": 1689781539000
    },
    {
        "time": 1689781541000,
        "value": 139.676,
        "_internal_originalTime": 1689781541000
    },
    {
        "time": 1689781542000,
        "value": 139.674,
        "_internal_originalTime": 1689781542000
    },
    {
        "time": 1689781543000,
        "value": 139.677,
        "_internal_originalTime": 1689781543000
    },
    {
        "time": 1689781544000,
        "value": 139.676,
        "_internal_originalTime": 1689781544000
    },
    {
        "time": 1689781546000,
        "value": 139.679,
        "_internal_originalTime": 1689781546000
    },
    {
        "time": 1689781547000,
        "value": 139.678,
        "_internal_originalTime": 1689781547000
    },
    {
        "time": 1689781548000,
        "value": 139.683,
        "_internal_originalTime": 1689781548000
    },
    {
        "time": 1689781549000,
        "value": 139.684,
        "_internal_originalTime": 1689781549000
    },
    {
        "time": 1689781550000,
        "value": 139.683,
        "_internal_originalTime": 1689781550000
    },
    {
        "time": 1689781552000,
        "value": 139.68,
        "_internal_originalTime": 1689781552000
    },
    {
        "time": 1689781553000,
        "value": 139.678,
        "_internal_originalTime": 1689781553000
    },
    {
        "time": 1689781554000,
        "value": 139.673,
        "_internal_originalTime": 1689781554000
    },
    {
        "time": 1689781558000,
        "value": 139.676,
        "_internal_originalTime": 1689781558000
    },
    {
        "time": 1689781559000,
        "value": 139.673,
        "_internal_originalTime": 1689781559000
    },
    {
        "time": 1689781560000,
        "value": 139.673,
        "_internal_originalTime": 1689781560000
    },
    {
        "time": 1689781561000,
        "value": 139.681,
        "_internal_originalTime": 1689781561000
    },
    {
        "time": 1689781563000,
        "value": 139.682,
        "_internal_originalTime": 1689781563000
    },
    {
        "time": 1689781567000,
        "value": 139.684,
        "_internal_originalTime": 1689781567000
    },
    {
        "time": 1689781568000,
        "value": 139.682,
        "_internal_originalTime": 1689781568000
    },
    {
        "time": 1689781570000,
        "value": 139.686,
        "_internal_originalTime": 1689781570000
    },
    {
        "time": 1689781572000,
        "value": 139.682,
        "_internal_originalTime": 1689781572000
    },
    {
        "time": 1689781573000,
        "value": 139.686,
        "_internal_originalTime": 1689781573000
    },
    {
        "time": 1689781574000,
        "value": 139.682,
        "_internal_originalTime": 1689781574000
    },
    {
        "time": 1689781576000,
        "value": 139.687,
        "_internal_originalTime": 1689781576000
    },
    {
        "time": 1689781577000,
        "value": 139.692,
        "_internal_originalTime": 1689781577000
    },
    {
        "time": 1689781578000,
        "value": 139.691,
        "_internal_originalTime": 1689781578000
    },
    {
        "time": 1689781579000,
        "value": 139.687,
        "_internal_originalTime": 1689781579000
    },
    {
        "time": 1689781580000,
        "value": 139.689,
        "_internal_originalTime": 1689781580000
    },
    {
        "time": 1689781581000,
        "value": 139.696,
        "_internal_originalTime": 1689781581000
    },
    {
        "time": 1689781582000,
        "value": 139.702,
        "_internal_originalTime": 1689781582000
    },
    {
        "time": 1689781583000,
        "value": 139.7,
        "_internal_originalTime": 1689781583000
    },
    {
        "time": 1689781584000,
        "value": 139.702,
        "_internal_originalTime": 1689781584000
    },
    {
        "time": 1689781585000,
        "value": 139.702,
        "_internal_originalTime": 1689781585000
    },
    {
        "time": 1689781588000,
        "value": 139.707,
        "_internal_originalTime": 1689781588000
    },
    {
        "time": 1689781591000,
        "value": 139.702,
        "_internal_originalTime": 1689781591000
    },
    {
        "time": 1689781593000,
        "value": 139.699,
        "_internal_originalTime": 1689781593000
    },
    {
        "time": 1689781594000,
        "value": 139.696,
        "_internal_originalTime": 1689781594000
    },
    {
        "time": 1689781596000,
        "value": 139.696,
        "_internal_originalTime": 1689781596000
    },
    {
        "time": 1689781597000,
        "value": 139.694,
        "_internal_originalTime": 1689781597000
    },
    {
        "time": 1689781598000,
        "value": 139.698,
        "_internal_originalTime": 1689781598000
    },
    {
        "time": 1689781599000,
        "value": 139.697,
        "_internal_originalTime": 1689781599000
    },
    {
        "time": 1689781602000,
        "value": 139.696,
        "_internal_originalTime": 1689781602000
    },
    {
        "time": 1689781604000,
        "value": 139.692,
        "_internal_originalTime": 1689781604000
    },
    {
        "time": 1689781608000,
        "value": 139.697,
        "_internal_originalTime": 1689781608000
    },
    {
        "time": 1689781609000,
        "value": 139.699,
        "_internal_originalTime": 1689781609000
    },
    {
        "time": 1689781610000,
        "value": 139.699,
        "_internal_originalTime": 1689781610000
    },
    {
        "time": 1689781612000,
        "value": 139.697,
        "_internal_originalTime": 1689781612000
    },
    {
        "time": 1689781613000,
        "value": 139.688,
        "_internal_originalTime": 1689781613000
    },
    {
        "time": 1689781615000,
        "value": 139.691,
        "_internal_originalTime": 1689781615000
    },
    {
        "time": 1689781618000,
        "value": 139.694,
        "_internal_originalTime": 1689781618000
    },
    {
        "time": 1689781623000,
        "value": 139.692,
        "_internal_originalTime": 1689781623000
    },
    {
        "time": 1689781625000,
        "value": 139.698,
        "_internal_originalTime": 1689781625000
    },
    {
        "time": 1689781626000,
        "value": 139.703,
        "_internal_originalTime": 1689781626000
    },
    {
        "time": 1689781627000,
        "value": 139.706,
        "_internal_originalTime": 1689781627000
    },
    {
        "time": 1689781628000,
        "value": 139.707,
        "_internal_originalTime": 1689781628000
    },
    {
        "time": 1689781629000,
        "value": 139.707,
        "_internal_originalTime": 1689781629000
    },
    {
        "time": 1689781631000,
        "value": 139.71,
        "_internal_originalTime": 1689781631000
    },
    {
        "time": 1689781632000,
        "value": 139.716,
        "_internal_originalTime": 1689781632000
    },
    {
        "time": 1689781634000,
        "value": 139.711,
        "_internal_originalTime": 1689781634000
    },
    {
        "time": 1689781635000,
        "value": 139.715,
        "_internal_originalTime": 1689781635000
    },
    {
        "time": 1689781639000,
        "value": 139.714,
        "_internal_originalTime": 1689781639000
    },
    {
        "time": 1689781640000,
        "value": 139.714,
        "_internal_originalTime": 1689781640000
    },
    {
        "time": 1689781641000,
        "value": 139.707,
        "_internal_originalTime": 1689781641000
    },
    {
        "time": 1689781642000,
        "value": 139.706,
        "_internal_originalTime": 1689781642000
    },
    {
        "time": 1689781643000,
        "value": 139.709,
        "_internal_originalTime": 1689781643000
    },
    {
        "time": 1689781644000,
        "value": 139.71,
        "_internal_originalTime": 1689781644000
    },
    {
        "time": 1689781645000,
        "value": 139.712,
        "_internal_originalTime": 1689781645000
    },
    {
        "time": 1689781647000,
        "value": 139.706,
        "_internal_originalTime": 1689781647000
    },
    {
        "time": 1689781649000,
        "value": 139.714,
        "_internal_originalTime": 1689781649000
    },
    {
        "time": 1689781650000,
        "value": 139.712,
        "_internal_originalTime": 1689781650000
    },
    {
        "time": 1689781651000,
        "value": 139.712,
        "_internal_originalTime": 1689781651000
    },
    {
        "time": 1689781652000,
        "value": 139.713,
        "_internal_originalTime": 1689781652000
    },
    {
        "time": 1689781653000,
        "value": 139.712,
        "_internal_originalTime": 1689781653000
    },
    {
        "time": 1689781654000,
        "value": 139.711,
        "_internal_originalTime": 1689781654000
    },
    {
        "time": 1689781655000,
        "value": 139.711,
        "_internal_originalTime": 1689781655000
    },
    {
        "time": 1689781656000,
        "value": 139.712,
        "_internal_originalTime": 1689781656000
    },
    {
        "time": 1689781658000,
        "value": 139.714,
        "_internal_originalTime": 1689781658000
    },
    {
        "time": 1689781659000,
        "value": 139.716,
        "_internal_originalTime": 1689781659000
    },
    {
        "time": 1689781660000,
        "value": 139.716,
        "_internal_originalTime": 1689781660000
    },
    {
        "time": 1689781661000,
        "value": 139.716,
        "_internal_originalTime": 1689781661000
    },
    {
        "time": 1689781662000,
        "value": 139.712,
        "_internal_originalTime": 1689781662000
    },
    {
        "time": 1689781664000,
        "value": 139.71,
        "_internal_originalTime": 1689781664000
    },
    {
        "time": 1689781665000,
        "value": 139.706,
        "_internal_originalTime": 1689781665000
    },
    {
        "time": 1689781666000,
        "value": 139.708,
        "_internal_originalTime": 1689781666000
    },
    {
        "time": 1689781668000,
        "value": 139.708,
        "_internal_originalTime": 1689781668000
    },
    {
        "time": 1689781669000,
        "value": 139.709,
        "_internal_originalTime": 1689781669000
    },
    {
        "time": 1689781671000,
        "value": 139.707,
        "_internal_originalTime": 1689781671000
    },
    {
        "time": 1689781672000,
        "value": 139.708,
        "_internal_originalTime": 1689781672000
    },
    {
        "time": 1689781674000,
        "value": 139.711,
        "_internal_originalTime": 1689781674000
    },
    {
        "time": 1689781675000,
        "value": 139.709,
        "_internal_originalTime": 1689781675000
    },
    {
        "time": 1689781676000,
        "value": 139.712,
        "_internal_originalTime": 1689781676000
    },
    {
        "time": 1689781678000,
        "value": 139.711,
        "_internal_originalTime": 1689781678000
    },
    {
        "time": 1689781679000,
        "value": 139.707,
        "_internal_originalTime": 1689781679000
    },
    {
        "time": 1689781680000,
        "value": 139.711,
        "_internal_originalTime": 1689781680000
    },
    {
        "time": 1689781681000,
        "value": 139.704,
        "_internal_originalTime": 1689781681000
    },
    {
        "time": 1689781682000,
        "value": 139.706,
        "_internal_originalTime": 1689781682000
    },
    {
        "time": 1689781684000,
        "value": 139.707,
        "_internal_originalTime": 1689781684000
    },
    {
        "time": 1689781685000,
        "value": 139.705,
        "_internal_originalTime": 1689781685000
    },
    {
        "time": 1689781687000,
        "value": 139.714,
        "_internal_originalTime": 1689781687000
    },
    {
        "time": 1689781688000,
        "value": 139.709,
        "_internal_originalTime": 1689781688000
    },
    {
        "time": 1689781689000,
        "value": 139.712,
        "_internal_originalTime": 1689781689000
    },
    {
        "time": 1689781690000,
        "value": 139.715,
        "_internal_originalTime": 1689781690000
    },
    {
        "time": 1689781691000,
        "value": 139.715,
        "_internal_originalTime": 1689781691000
    },
    {
        "time": 1689781693000,
        "value": 139.712,
        "_internal_originalTime": 1689781693000
    },
    {
        "time": 1689781694000,
        "value": 139.712,
        "_internal_originalTime": 1689781694000
    },
    {
        "time": 1689781695000,
        "value": 139.712,
        "_internal_originalTime": 1689781695000
    },
    {
        "time": 1689781696000,
        "value": 139.712,
        "_internal_originalTime": 1689781696000
    },
    {
        "time": 1689781697000,
        "value": 139.712,
        "_internal_originalTime": 1689781697000
    },
    {
        "time": 1689781698000,
        "value": 139.712,
        "_internal_originalTime": 1689781698000
    },
    {
        "time": 1689781700000,
        "value": 139.714,
        "_internal_originalTime": 1689781700000
    },
    {
        "time": 1689781701000,
        "value": 139.713,
        "_internal_originalTime": 1689781701000
    },
    {
        "time": 1689781703000,
        "value": 139.711,
        "_internal_originalTime": 1689781703000
    },
    {
        "time": 1689781705000,
        "value": 139.711,
        "_internal_originalTime": 1689781705000
    },
    {
        "time": 1689781706000,
        "value": 139.704,
        "_internal_originalTime": 1689781706000
    },
    {
        "time": 1689781707000,
        "value": 139.709,
        "_internal_originalTime": 1689781707000
    },
    {
        "time": 1689781708000,
        "value": 139.709,
        "_internal_originalTime": 1689781708000
    },
    {
        "time": 1689781709000,
        "value": 139.706,
        "_internal_originalTime": 1689781709000
    },
    {
        "time": 1689781711000,
        "value": 139.706,
        "_internal_originalTime": 1689781711000
    },
    {
        "time": 1689781712000,
        "value": 139.698,
        "_internal_originalTime": 1689781712000
    },
    {
        "time": 1689781713000,
        "value": 139.702,
        "_internal_originalTime": 1689781713000
    },
    {
        "time": 1689781714000,
        "value": 139.703,
        "_internal_originalTime": 1689781714000
    },
    {
        "time": 1689781715000,
        "value": 139.699,
        "_internal_originalTime": 1689781715000
    },
    {
        "time": 1689781716000,
        "value": 139.7,
        "_internal_originalTime": 1689781716000
    },
    {
        "time": 1689781718000,
        "value": 139.7,
        "_internal_originalTime": 1689781718000
    },
    {
        "time": 1689781719000,
        "value": 139.7,
        "_internal_originalTime": 1689781719000
    },
    {
        "time": 1689781721000,
        "value": 139.706,
        "_internal_originalTime": 1689781721000
    },
    {
        "time": 1689781722000,
        "value": 139.709,
        "_internal_originalTime": 1689781722000
    },
    {
        "time": 1689781723000,
        "value": 139.71,
        "_internal_originalTime": 1689781723000
    },
    {
        "time": 1689781724000,
        "value": 139.705,
        "_internal_originalTime": 1689781724000
    },
    {
        "time": 1689781725000,
        "value": 139.704,
        "_internal_originalTime": 1689781725000
    },
    {
        "time": 1689781726000,
        "value": 139.702,
        "_internal_originalTime": 1689781726000
    },
    {
        "time": 1689781727000,
        "value": 139.704,
        "_internal_originalTime": 1689781727000
    },
    {
        "time": 1689781729000,
        "value": 139.703,
        "_internal_originalTime": 1689781729000
    },
    {
        "time": 1689781730000,
        "value": 139.704,
        "_internal_originalTime": 1689781730000
    },
    {
        "time": 1689781731000,
        "value": 139.706,
        "_internal_originalTime": 1689781731000
    },
    {
        "time": 1689781733000,
        "value": 139.706,
        "_internal_originalTime": 1689781733000
    },
    {
        "time": 1689781734000,
        "value": 139.708,
        "_internal_originalTime": 1689781734000
    },
    {
        "time": 1689781736000,
        "value": 139.707,
        "_internal_originalTime": 1689781736000
    },
    {
        "time": 1689781739000,
        "value": 139.704,
        "_internal_originalTime": 1689781739000
    },
    {
        "time": 1689781740000,
        "value": 139.706,
        "_internal_originalTime": 1689781740000
    },
    {
        "time": 1689781741000,
        "value": 139.699,
        "_internal_originalTime": 1689781741000
    },
    {
        "time": 1689781742000,
        "value": 139.698,
        "_internal_originalTime": 1689781742000
    },
    {
        "time": 1689781744000,
        "value": 139.702,
        "_internal_originalTime": 1689781744000
    },
    {
        "time": 1689781747000,
        "value": 139.702,
        "_internal_originalTime": 1689781747000
    },
    {
        "time": 1689781748000,
        "value": 139.7,
        "_internal_originalTime": 1689781748000
    },
    {
        "time": 1689781749000,
        "value": 139.698,
        "_internal_originalTime": 1689781749000
    },
    {
        "time": 1689781750000,
        "value": 139.705,
        "_internal_originalTime": 1689781750000
    },
    {
        "time": 1689781752000,
        "value": 139.707,
        "_internal_originalTime": 1689781752000
    },
    {
        "time": 1689781753000,
        "value": 139.704,
        "_internal_originalTime": 1689781753000
    },
    {
        "time": 1689781755000,
        "value": 139.706,
        "_internal_originalTime": 1689781755000
    },
    {
        "time": 1689781756000,
        "value": 139.708,
        "_internal_originalTime": 1689781756000
    },
    {
        "time": 1689781757000,
        "value": 139.716,
        "_internal_originalTime": 1689781757000
    },
    {
        "time": 1689781758000,
        "value": 139.712,
        "_internal_originalTime": 1689781758000
    },
    {
        "time": 1689781760000,
        "value": 139.714,
        "_internal_originalTime": 1689781760000
    },
    {
        "time": 1689781761000,
        "value": 139.717,
        "_internal_originalTime": 1689781761000
    },
    {
        "time": 1689781762000,
        "value": 139.714,
        "_internal_originalTime": 1689781762000
    },
    {
        "time": 1689781764000,
        "value": 139.716,
        "_internal_originalTime": 1689781764000
    },
    {
        "time": 1689781765000,
        "value": 139.719,
        "_internal_originalTime": 1689781765000
    },
    {
        "time": 1689781766000,
        "value": 139.718,
        "_internal_originalTime": 1689781766000
    },
    {
        "time": 1689781767000,
        "value": 139.712,
        "_internal_originalTime": 1689781767000
    },
    {
        "time": 1689781768000,
        "value": 139.712,
        "_internal_originalTime": 1689781768000
    },
    {
        "time": 1689781769000,
        "value": 139.712,
        "_internal_originalTime": 1689781769000
    },
    {
        "time": 1689781770000,
        "value": 139.716,
        "_internal_originalTime": 1689781770000
    },
    {
        "time": 1689781771000,
        "value": 139.72,
        "_internal_originalTime": 1689781771000
    },
    {
        "time": 1689781772000,
        "value": 139.719,
        "_internal_originalTime": 1689781772000
    },
    {
        "time": 1689781773000,
        "value": 139.714,
        "_internal_originalTime": 1689781773000
    },
    {
        "time": 1689781775000,
        "value": 139.719,
        "_internal_originalTime": 1689781775000
    },
    {
        "time": 1689781776000,
        "value": 139.718,
        "_internal_originalTime": 1689781776000
    },
    {
        "time": 1689781777000,
        "value": 139.714,
        "_internal_originalTime": 1689781777000
    },
    {
        "time": 1689781778000,
        "value": 139.713,
        "_internal_originalTime": 1689781778000
    },
    {
        "time": 1689781780000,
        "value": 139.714,
        "_internal_originalTime": 1689781780000
    },
    {
        "time": 1689781781000,
        "value": 139.714,
        "_internal_originalTime": 1689781781000
    },
    {
        "time": 1689781782000,
        "value": 139.722,
        "_internal_originalTime": 1689781782000
    },
    {
        "time": 1689781783000,
        "value": 139.724,
        "_internal_originalTime": 1689781783000
    },
    {
        "time": 1689781784000,
        "value": 139.728,
        "_internal_originalTime": 1689781784000
    },
    {
        "time": 1689781786000,
        "value": 139.728,
        "_internal_originalTime": 1689781786000
    },
    {
        "time": 1689781787000,
        "value": 139.725,
        "_internal_originalTime": 1689781787000
    },
    {
        "time": 1689781788000,
        "value": 139.728,
        "_internal_originalTime": 1689781788000
    },
    {
        "time": 1689781789000,
        "value": 139.726,
        "_internal_originalTime": 1689781789000
    },
    {
        "time": 1689781790000,
        "value": 139.72,
        "_internal_originalTime": 1689781790000
    },
    {
        "time": 1689781792000,
        "value": 139.717,
        "_internal_originalTime": 1689781792000
    },
    {
        "time": 1689781793000,
        "value": 139.716,
        "_internal_originalTime": 1689781793000
    },
    {
        "time": 1689781795000,
        "value": 139.719,
        "_internal_originalTime": 1689781795000
    },
    {
        "time": 1689781796000,
        "value": 139.719,
        "_internal_originalTime": 1689781796000
    },
    {
        "time": 1689781797000,
        "value": 139.719,
        "_internal_originalTime": 1689781797000
    },
    {
        "time": 1689781798000,
        "value": 139.719,
        "_internal_originalTime": 1689781798000
    },
    {
        "time": 1689781800000,
        "value": 139.715,
        "_internal_originalTime": 1689781800000
    },
    {
        "time": 1689781801000,
        "value": 139.716,
        "_internal_originalTime": 1689781801000
    },
    {
        "time": 1689781802000,
        "value": 139.719,
        "_internal_originalTime": 1689781802000
    },
    {
        "time": 1689781803000,
        "value": 139.719,
        "_internal_originalTime": 1689781803000
    },
    {
        "time": 1689781804000,
        "value": 139.716,
        "_internal_originalTime": 1689781804000
    },
    {
        "time": 1689781806000,
        "value": 139.714,
        "_internal_originalTime": 1689781806000
    },
    {
        "time": 1689781808000,
        "value": 139.718,
        "_internal_originalTime": 1689781808000
    },
    {
        "time": 1689781812000,
        "value": 139.712,
        "_internal_originalTime": 1689781812000
    },
    {
        "time": 1689781813000,
        "value": 139.718,
        "_internal_originalTime": 1689781813000
    },
    {
        "time": 1689781814000,
        "value": 139.712,
        "_internal_originalTime": 1689781814000
    },
    {
        "time": 1689781815000,
        "value": 139.717,
        "_internal_originalTime": 1689781815000
    },
    {
        "time": 1689781817000,
        "value": 139.717,
        "_internal_originalTime": 1689781817000
    },
    {
        "time": 1689781819000,
        "value": 139.712,
        "_internal_originalTime": 1689781819000
    },
    {
        "time": 1689781820000,
        "value": 139.715,
        "_internal_originalTime": 1689781820000
    },
    {
        "time": 1689781821000,
        "value": 139.715,
        "_internal_originalTime": 1689781821000
    },
    {
        "time": 1689781822000,
        "value": 139.715,
        "_internal_originalTime": 1689781822000
    },
    {
        "time": 1689781823000,
        "value": 139.719,
        "_internal_originalTime": 1689781823000
    },
    {
        "time": 1689781825000,
        "value": 139.717,
        "_internal_originalTime": 1689781825000
    },
    {
        "time": 1689781826000,
        "value": 139.727,
        "_internal_originalTime": 1689781826000
    },
    {
        "time": 1689781827000,
        "value": 139.725,
        "_internal_originalTime": 1689781827000
    },
    {
        "time": 1689781828000,
        "value": 139.727,
        "_internal_originalTime": 1689781828000
    },
    {
        "time": 1689781830000,
        "value": 139.724,
        "_internal_originalTime": 1689781830000
    },
    {
        "time": 1689781831000,
        "value": 139.721,
        "_internal_originalTime": 1689781831000
    },
    {
        "time": 1689781832000,
        "value": 139.718,
        "_internal_originalTime": 1689781832000
    },
    {
        "time": 1689781833000,
        "value": 139.718,
        "_internal_originalTime": 1689781833000
    },
    {
        "time": 1689781835000,
        "value": 139.701,
        "_internal_originalTime": 1689781835000
    },
    {
        "time": 1689781837000,
        "value": 139.697,
        "_internal_originalTime": 1689781837000
    },
    {
        "time": 1689781838000,
        "value": 139.693,
        "_internal_originalTime": 1689781838000
    },
    {
        "time": 1689781839000,
        "value": 139.694,
        "_internal_originalTime": 1689781839000
    },
    {
        "time": 1689781840000,
        "value": 139.697,
        "_internal_originalTime": 1689781840000
    },
    {
        "time": 1689781841000,
        "value": 139.698,
        "_internal_originalTime": 1689781841000
    },
    {
        "time": 1689781842000,
        "value": 139.698,
        "_internal_originalTime": 1689781842000
    },
    {
        "time": 1689781844000,
        "value": 139.694,
        "_internal_originalTime": 1689781844000
    },
    {
        "time": 1689781845000,
        "value": 139.694,
        "_internal_originalTime": 1689781845000
    },
    {
        "time": 1689781846000,
        "value": 139.692,
        "_internal_originalTime": 1689781846000
    },
    {
        "time": 1689781847000,
        "value": 139.697,
        "_internal_originalTime": 1689781847000
    },
    {
        "time": 1689781848000,
        "value": 139.697,
        "_internal_originalTime": 1689781848000
    },
    {
        "time": 1689781851000,
        "value": 139.698,
        "_internal_originalTime": 1689781851000
    },
    {
        "time": 1689781852000,
        "value": 139.698,
        "_internal_originalTime": 1689781852000
    },
    {
        "time": 1689781853000,
        "value": 139.697,
        "_internal_originalTime": 1689781853000
    },
    {
        "time": 1689781855000,
        "value": 139.699,
        "_internal_originalTime": 1689781855000
    },
    {
        "time": 1689781858000,
        "value": 139.694,
        "_internal_originalTime": 1689781858000
    },
    {
        "time": 1689781861000,
        "value": 139.692,
        "_internal_originalTime": 1689781861000
    },
    {
        "time": 1689781863000,
        "value": 139.691,
        "_internal_originalTime": 1689781863000
    },
    {
        "time": 1689781864000,
        "value": 139.691,
        "_internal_originalTime": 1689781864000
    },
    {
        "time": 1689781865000,
        "value": 139.69,
        "_internal_originalTime": 1689781865000
    },
    {
        "time": 1689781866000,
        "value": 139.69,
        "_internal_originalTime": 1689781866000
    },
    {
        "time": 1689781867000,
        "value": 139.687,
        "_internal_originalTime": 1689781867000
    },
    {
        "time": 1689781868000,
        "value": 139.682,
        "_internal_originalTime": 1689781868000
    },
    {
        "time": 1689781869000,
        "value": 139.681,
        "_internal_originalTime": 1689781869000
    },
    {
        "time": 1689781870000,
        "value": 139.679,
        "_internal_originalTime": 1689781870000
    },
    {
        "time": 1689781871000,
        "value": 139.674,
        "_internal_originalTime": 1689781871000
    },
    {
        "time": 1689781872000,
        "value": 139.681,
        "_internal_originalTime": 1689781872000
    },
    {
        "time": 1689781873000,
        "value": 139.676,
        "_internal_originalTime": 1689781873000
    },
    {
        "time": 1689781874000,
        "value": 139.681,
        "_internal_originalTime": 1689781874000
    },
    {
        "time": 1689781876000,
        "value": 139.676,
        "_internal_originalTime": 1689781876000
    },
    {
        "time": 1689781877000,
        "value": 139.688,
        "_internal_originalTime": 1689781877000
    },
    {
        "time": 1689781878000,
        "value": 139.682,
        "_internal_originalTime": 1689781878000
    },
    {
        "time": 1689781882000,
        "value": 139.687,
        "_internal_originalTime": 1689781882000
    },
    {
        "time": 1689781884000,
        "value": 139.682,
        "_internal_originalTime": 1689781884000
    },
    {
        "time": 1689781885000,
        "value": 139.688,
        "_internal_originalTime": 1689781885000
    },
    {
        "time": 1689781886000,
        "value": 139.687,
        "_internal_originalTime": 1689781886000
    },
    {
        "time": 1689781892000,
        "value": 139.687,
        "_internal_originalTime": 1689781892000
    },
    {
        "time": 1689781894000,
        "value": 139.687,
        "_internal_originalTime": 1689781894000
    },
    {
        "time": 1689781895000,
        "value": 139.687,
        "_internal_originalTime": 1689781895000
    },
    {
        "time": 1689781896000,
        "value": 139.687,
        "_internal_originalTime": 1689781896000
    },
    {
        "time": 1689781898000,
        "value": 139.687,
        "_internal_originalTime": 1689781898000
    },
    {
        "time": 1689781899000,
        "value": 139.683,
        "_internal_originalTime": 1689781899000
    },
    {
        "time": 1689781900000,
        "value": 139.682,
        "_internal_originalTime": 1689781900000
    },
    {
        "time": 1689781902000,
        "value": 139.676,
        "_internal_originalTime": 1689781902000
    },
    {
        "time": 1689781903000,
        "value": 139.674,
        "_internal_originalTime": 1689781903000
    },
    {
        "time": 1689781905000,
        "value": 139.672,
        "_internal_originalTime": 1689781905000
    },
    {
        "time": 1689781906000,
        "value": 139.673,
        "_internal_originalTime": 1689781906000
    },
    {
        "time": 1689781908000,
        "value": 139.647,
        "_internal_originalTime": 1689781908000
    },
    {
        "time": 1689781909000,
        "value": 139.653,
        "_internal_originalTime": 1689781909000
    },
    {
        "time": 1689781910000,
        "value": 139.653,
        "_internal_originalTime": 1689781910000
    },
    {
        "time": 1689781911000,
        "value": 139.648,
        "_internal_originalTime": 1689781911000
    },
    {
        "time": 1689781913000,
        "value": 139.646,
        "_internal_originalTime": 1689781913000
    },
    {
        "time": 1689781915000,
        "value": 139.643,
        "_internal_originalTime": 1689781915000
    },
    {
        "time": 1689781916000,
        "value": 139.632,
        "_internal_originalTime": 1689781916000
    },
    {
        "time": 1689781917000,
        "value": 139.636,
        "_internal_originalTime": 1689781917000
    },
    {
        "time": 1689781919000,
        "value": 139.64,
        "_internal_originalTime": 1689781919000
    },
    {
        "time": 1689781920000,
        "value": 139.642,
        "_internal_originalTime": 1689781920000
    },
    {
        "time": 1689781922000,
        "value": 139.642,
        "_internal_originalTime": 1689781922000
    },
    {
        "time": 1689781923000,
        "value": 139.638,
        "_internal_originalTime": 1689781923000
    },
    {
        "time": 1689781924000,
        "value": 139.636,
        "_internal_originalTime": 1689781924000
    },
    {
        "time": 1689781925000,
        "value": 139.636,
        "_internal_originalTime": 1689781925000
    },
    {
        "time": 1689781927000,
        "value": 139.643,
        "_internal_originalTime": 1689781927000
    },
    {
        "time": 1689781928000,
        "value": 139.642,
        "_internal_originalTime": 1689781928000
    },
    {
        "time": 1689781930000,
        "value": 139.644,
        "_internal_originalTime": 1689781930000
    },
    {
        "time": 1689781931000,
        "value": 139.648,
        "_internal_originalTime": 1689781931000
    },
    {
        "time": 1689781932000,
        "value": 139.649,
        "_internal_originalTime": 1689781932000
    },
    {
        "time": 1689781933000,
        "value": 139.653,
        "_internal_originalTime": 1689781933000
    },
    {
        "time": 1689781934000,
        "value": 139.648,
        "_internal_originalTime": 1689781934000
    },
    {
        "time": 1689781935000,
        "value": 139.653,
        "_internal_originalTime": 1689781935000
    },
    {
        "time": 1689781936000,
        "value": 139.647,
        "_internal_originalTime": 1689781936000
    },
    {
        "time": 1689781937000,
        "value": 139.652,
        "_internal_originalTime": 1689781937000
    },
    {
        "time": 1689781938000,
        "value": 139.647,
        "_internal_originalTime": 1689781938000
    },
    {
        "time": 1689781942000,
        "value": 139.653,
        "_internal_originalTime": 1689781942000
    },
    {
        "time": 1689781943000,
        "value": 139.651,
        "_internal_originalTime": 1689781943000
    },
    {
        "time": 1689781944000,
        "value": 139.651,
        "_internal_originalTime": 1689781944000
    },
    {
        "time": 1689781945000,
        "value": 139.652,
        "_internal_originalTime": 1689781945000
    },
    {
        "time": 1689781946000,
        "value": 139.651,
        "_internal_originalTime": 1689781946000
    },
    {
        "time": 1689781947000,
        "value": 139.653,
        "_internal_originalTime": 1689781947000
    },
    {
        "time": 1689781948000,
        "value": 139.646,
        "_internal_originalTime": 1689781948000
    },
    {
        "time": 1689781949000,
        "value": 139.65,
        "_internal_originalTime": 1689781949000
    },
    {
        "time": 1689781950000,
        "value": 139.651,
        "_internal_originalTime": 1689781950000
    },
    {
        "time": 1689781951000,
        "value": 139.648,
        "_internal_originalTime": 1689781951000
    },
    {
        "time": 1689781952000,
        "value": 139.644,
        "_internal_originalTime": 1689781952000
    },
    {
        "time": 1689781953000,
        "value": 139.648,
        "_internal_originalTime": 1689781953000
    },
    {
        "time": 1689781955000,
        "value": 139.648,
        "_internal_originalTime": 1689781955000
    },
    {
        "time": 1689781958000,
        "value": 139.645,
        "_internal_originalTime": 1689781958000
    },
    {
        "time": 1689781960000,
        "value": 139.647,
        "_internal_originalTime": 1689781960000
    },
    {
        "time": 1689781961000,
        "value": 139.645,
        "_internal_originalTime": 1689781961000
    },
    {
        "time": 1689781962000,
        "value": 139.649,
        "_internal_originalTime": 1689781962000
    },
    {
        "time": 1689781963000,
        "value": 139.653,
        "_internal_originalTime": 1689781963000
    },
    {
        "time": 1689781965000,
        "value": 139.649,
        "_internal_originalTime": 1689781965000
    },
    {
        "time": 1689781966000,
        "value": 139.647,
        "_internal_originalTime": 1689781966000
    },
    {
        "time": 1689781967000,
        "value": 139.646,
        "_internal_originalTime": 1689781967000
    },
    {
        "time": 1689781969000,
        "value": 139.653,
        "_internal_originalTime": 1689781969000
    },
    {
        "time": 1689781970000,
        "value": 139.648,
        "_internal_originalTime": 1689781970000
    },
    {
        "time": 1689781971000,
        "value": 139.649,
        "_internal_originalTime": 1689781971000
    },
    {
        "time": 1689781972000,
        "value": 139.653,
        "_internal_originalTime": 1689781972000
    },
    {
        "time": 1689781973000,
        "value": 139.649,
        "_internal_originalTime": 1689781973000
    },
    {
        "time": 1689781975000,
        "value": 139.651,
        "_internal_originalTime": 1689781975000
    },
    {
        "time": 1689781977000,
        "value": 139.646,
        "_internal_originalTime": 1689781977000
    },
    {
        "time": 1689781979000,
        "value": 139.648,
        "_internal_originalTime": 1689781979000
    },
    {
        "time": 1689781980000,
        "value": 139.648,
        "_internal_originalTime": 1689781980000
    },
    {
        "time": 1689781982000,
        "value": 139.645,
        "_internal_originalTime": 1689781982000
    },
    {
        "time": 1689781983000,
        "value": 139.649,
        "_internal_originalTime": 1689781983000
    },
    {
        "time": 1689781984000,
        "value": 139.645,
        "_internal_originalTime": 1689781984000
    },
    {
        "time": 1689781985000,
        "value": 139.646,
        "_internal_originalTime": 1689781985000
    },
    {
        "time": 1689781986000,
        "value": 139.642,
        "_internal_originalTime": 1689781986000
    },
    {
        "time": 1689781987000,
        "value": 139.643,
        "_internal_originalTime": 1689781987000
    },
    {
        "time": 1689781988000,
        "value": 139.643,
        "_internal_originalTime": 1689781988000
    },
    {
        "time": 1689781989000,
        "value": 139.644,
        "_internal_originalTime": 1689781989000
    },
    {
        "time": 1689781990000,
        "value": 139.644,
        "_internal_originalTime": 1689781990000
    },
    {
        "time": 1689781992000,
        "value": 139.644,
        "_internal_originalTime": 1689781992000
    },
    {
        "time": 1689781993000,
        "value": 139.649,
        "_internal_originalTime": 1689781993000
    },
    {
        "time": 1689781994000,
        "value": 139.644,
        "_internal_originalTime": 1689781994000
    },
    {
        "time": 1689781998000,
        "value": 139.645,
        "_internal_originalTime": 1689781998000
    },
    {
        "time": 1689781999000,
        "value": 139.644,
        "_internal_originalTime": 1689781999000
    },
    {
        "time": 1689782000000,
        "value": 139.649,
        "_internal_originalTime": 1689782000000
    },
    {
        "time": 1689782001000,
        "value": 139.646,
        "_internal_originalTime": 1689782001000
    },
    {
        "time": 1689782002000,
        "value": 139.649,
        "_internal_originalTime": 1689782002000
    },
    {
        "time": 1689782003000,
        "value": 139.652,
        "_internal_originalTime": 1689782003000
    },
    {
        "time": 1689782004000,
        "value": 139.653,
        "_internal_originalTime": 1689782004000
    },
    {
        "time": 1689782005000,
        "value": 139.654,
        "_internal_originalTime": 1689782005000
    },
    {
        "time": 1689782007000,
        "value": 139.654,
        "_internal_originalTime": 1689782007000
    },
    {
        "time": 1689782008000,
        "value": 139.662,
        "_internal_originalTime": 1689782008000
    },
    {
        "time": 1689782009000,
        "value": 139.66,
        "_internal_originalTime": 1689782009000
    },
    {
        "time": 1689782011000,
        "value": 139.66,
        "_internal_originalTime": 1689782011000
    },
    {
        "time": 1689782013000,
        "value": 139.664,
        "_internal_originalTime": 1689782013000
    },
    {
        "time": 1689782014000,
        "value": 139.671,
        "_internal_originalTime": 1689782014000
    },
    {
        "time": 1689782015000,
        "value": 139.671,
        "_internal_originalTime": 1689782015000
    },
    {
        "time": 1689782016000,
        "value": 139.677,
        "_internal_originalTime": 1689782016000
    },
    {
        "time": 1689782018000,
        "value": 139.672,
        "_internal_originalTime": 1689782018000
    },
    {
        "time": 1689782019000,
        "value": 139.677,
        "_internal_originalTime": 1689782019000
    },
    {
        "time": 1689782020000,
        "value": 139.674,
        "_internal_originalTime": 1689782020000
    },
    {
        "time": 1689782022000,
        "value": 139.672,
        "_internal_originalTime": 1689782022000
    },
    {
        "time": 1689782023000,
        "value": 139.674,
        "_internal_originalTime": 1689782023000
    },
    {
        "time": 1689782025000,
        "value": 139.68,
        "_internal_originalTime": 1689782025000
    },
    {
        "time": 1689782026000,
        "value": 139.683,
        "_internal_originalTime": 1689782026000
    },
    {
        "time": 1689782028000,
        "value": 139.68,
        "_internal_originalTime": 1689782028000
    },
    {
        "time": 1689782029000,
        "value": 139.684,
        "_internal_originalTime": 1689782029000
    },
    {
        "time": 1689782030000,
        "value": 139.685,
        "_internal_originalTime": 1689782030000
    },
    {
        "time": 1689782031000,
        "value": 139.678,
        "_internal_originalTime": 1689782031000
    },
    {
        "time": 1689782032000,
        "value": 139.683,
        "_internal_originalTime": 1689782032000
    },
    {
        "time": 1689782033000,
        "value": 139.679,
        "_internal_originalTime": 1689782033000
    },
    {
        "time": 1689782034000,
        "value": 139.678,
        "_internal_originalTime": 1689782034000
    },
    {
        "time": 1689782036000,
        "value": 139.68,
        "_internal_originalTime": 1689782036000
    },
    {
        "time": 1689782037000,
        "value": 139.682,
        "_internal_originalTime": 1689782037000
    },
    {
        "time": 1689782038000,
        "value": 139.682,
        "_internal_originalTime": 1689782038000
    },
    {
        "time": 1689782040000,
        "value": 139.682,
        "_internal_originalTime": 1689782040000
    },
    {
        "time": 1689782041000,
        "value": 139.683,
        "_internal_originalTime": 1689782041000
    },
    {
        "time": 1689782042000,
        "value": 139.688,
        "_internal_originalTime": 1689782042000
    },
    {
        "time": 1689782043000,
        "value": 139.684,
        "_internal_originalTime": 1689782043000
    },
    {
        "time": 1689782044000,
        "value": 139.689,
        "_internal_originalTime": 1689782044000
    },
    {
        "time": 1689782045000,
        "value": 139.688,
        "_internal_originalTime": 1689782045000
    },
    {
        "time": 1689782047000,
        "value": 139.69,
        "_internal_originalTime": 1689782047000
    },
    {
        "time": 1689782048000,
        "value": 139.689,
        "_internal_originalTime": 1689782048000
    },
    {
        "time": 1689782049000,
        "value": 139.692,
        "_internal_originalTime": 1689782049000
    },
    {
        "time": 1689782050000,
        "value": 139.688,
        "_internal_originalTime": 1689782050000
    },
    {
        "time": 1689782052000,
        "value": 139.692,
        "_internal_originalTime": 1689782052000
    },
    {
        "time": 1689782053000,
        "value": 139.688,
        "_internal_originalTime": 1689782053000
    },
    {
        "time": 1689782055000,
        "value": 139.69,
        "_internal_originalTime": 1689782055000
    },
    {
        "time": 1689782056000,
        "value": 139.689,
        "_internal_originalTime": 1689782056000
    },
    {
        "time": 1689782057000,
        "value": 139.687,
        "_internal_originalTime": 1689782057000
    },
    {
        "time": 1689782059000,
        "value": 139.686,
        "_internal_originalTime": 1689782059000
    },
    {
        "time": 1689782061000,
        "value": 139.688,
        "_internal_originalTime": 1689782061000
    },
    {
        "time": 1689782062000,
        "value": 139.69,
        "_internal_originalTime": 1689782062000
    },
    {
        "time": 1689782063000,
        "value": 139.691,
        "_internal_originalTime": 1689782063000
    },
    {
        "time": 1689782064000,
        "value": 139.692,
        "_internal_originalTime": 1689782064000
    },
    {
        "time": 1689782066000,
        "value": 139.693,
        "_internal_originalTime": 1689782066000
    },
    {
        "time": 1689782067000,
        "value": 139.688,
        "_internal_originalTime": 1689782067000
    },
    {
        "time": 1689782068000,
        "value": 139.688,
        "_internal_originalTime": 1689782068000
    },
    {
        "time": 1689782069000,
        "value": 139.69,
        "_internal_originalTime": 1689782069000
    },
    {
        "time": 1689782071000,
        "value": 139.692,
        "_internal_originalTime": 1689782071000
    },
    {
        "time": 1689782072000,
        "value": 139.688,
        "_internal_originalTime": 1689782072000
    },
    {
        "time": 1689782075000,
        "value": 139.69,
        "_internal_originalTime": 1689782075000
    },
    {
        "time": 1689782077000,
        "value": 139.685,
        "_internal_originalTime": 1689782077000
    },
    {
        "time": 1689782078000,
        "value": 139.69,
        "_internal_originalTime": 1689782078000
    },
    {
        "time": 1689782079000,
        "value": 139.688,
        "_internal_originalTime": 1689782079000
    },
    {
        "time": 1689782080000,
        "value": 139.686,
        "_internal_originalTime": 1689782080000
    },
    {
        "time": 1689782081000,
        "value": 139.689,
        "_internal_originalTime": 1689782081000
    },
    {
        "time": 1689782082000,
        "value": 139.689,
        "_internal_originalTime": 1689782082000
    },
    {
        "time": 1689782084000,
        "value": 139.683,
        "_internal_originalTime": 1689782084000
    },
    {
        "time": 1689782088000,
        "value": 139.689,
        "_internal_originalTime": 1689782088000
    },
    {
        "time": 1689782089000,
        "value": 139.69,
        "_internal_originalTime": 1689782089000
    },
    {
        "time": 1689782092000,
        "value": 139.691,
        "_internal_originalTime": 1689782092000
    },
    {
        "time": 1689782093000,
        "value": 139.692,
        "_internal_originalTime": 1689782093000
    },
    {
        "time": 1689782095000,
        "value": 139.691,
        "_internal_originalTime": 1689782095000
    },
    {
        "time": 1689782096000,
        "value": 139.691,
        "_internal_originalTime": 1689782096000
    },
    {
        "time": 1689782100000,
        "value": 139.686,
        "_internal_originalTime": 1689782100000
    },
    {
        "time": 1689782101000,
        "value": 139.689,
        "_internal_originalTime": 1689782101000
    },
    {
        "time": 1689782102000,
        "value": 139.687,
        "_internal_originalTime": 1689782102000
    },
    {
        "time": 1689782103000,
        "value": 139.689,
        "_internal_originalTime": 1689782103000
    },
    {
        "time": 1689782104000,
        "value": 139.689,
        "_internal_originalTime": 1689782104000
    },
    {
        "time": 1689782105000,
        "value": 139.689,
        "_internal_originalTime": 1689782105000
    },
    {
        "time": 1689782107000,
        "value": 139.688,
        "_internal_originalTime": 1689782107000
    },
    {
        "time": 1689782108000,
        "value": 139.688,
        "_internal_originalTime": 1689782108000
    },
    {
        "time": 1689782110000,
        "value": 139.687,
        "_internal_originalTime": 1689782110000
    },
    {
        "time": 1689782111000,
        "value": 139.683,
        "_internal_originalTime": 1689782111000
    },
    {
        "time": 1689782112000,
        "value": 139.676,
        "_internal_originalTime": 1689782112000
    },
    {
        "time": 1689782113000,
        "value": 139.686,
        "_internal_originalTime": 1689782113000
    },
    {
        "time": 1689782114000,
        "value": 139.683,
        "_internal_originalTime": 1689782114000
    },
    {
        "time": 1689782115000,
        "value": 139.69,
        "_internal_originalTime": 1689782115000
    },
    {
        "time": 1689782116000,
        "value": 139.69,
        "_internal_originalTime": 1689782116000
    },
    {
        "time": 1689782117000,
        "value": 139.687,
        "_internal_originalTime": 1689782117000
    },
    {
        "time": 1689782120000,
        "value": 139.686,
        "_internal_originalTime": 1689782120000
    },
    {
        "time": 1689782127000,
        "value": 139.687,
        "_internal_originalTime": 1689782127000
    },
    {
        "time": 1689782128000,
        "value": 139.688,
        "_internal_originalTime": 1689782128000
    },
    {
        "time": 1689782129000,
        "value": 139.683,
        "_internal_originalTime": 1689782129000
    },
    {
        "time": 1689782130000,
        "value": 139.688,
        "_internal_originalTime": 1689782130000
    },
    {
        "time": 1689782131000,
        "value": 139.684,
        "_internal_originalTime": 1689782131000
    },
    {
        "time": 1689782133000,
        "value": 139.694,
        "_internal_originalTime": 1689782133000
    },
    {
        "time": 1689782134000,
        "value": 139.69,
        "_internal_originalTime": 1689782134000
    },
    {
        "time": 1689782135000,
        "value": 139.692,
        "_internal_originalTime": 1689782135000
    },
    {
        "time": 1689782137000,
        "value": 139.684,
        "_internal_originalTime": 1689782137000
    },
    {
        "time": 1689782138000,
        "value": 139.687,
        "_internal_originalTime": 1689782138000
    },
    {
        "time": 1689782139000,
        "value": 139.682,
        "_internal_originalTime": 1689782139000
    },
    {
        "time": 1689782141000,
        "value": 139.684,
        "_internal_originalTime": 1689782141000
    },
    {
        "time": 1689782142000,
        "value": 139.691,
        "_internal_originalTime": 1689782142000
    },
    {
        "time": 1689782143000,
        "value": 139.686,
        "_internal_originalTime": 1689782143000
    },
    {
        "time": 1689782145000,
        "value": 139.688,
        "_internal_originalTime": 1689782145000
    },
    {
        "time": 1689782146000,
        "value": 139.692,
        "_internal_originalTime": 1689782146000
    },
    {
        "time": 1689782148000,
        "value": 139.69,
        "_internal_originalTime": 1689782148000
    },
    {
        "time": 1689782149000,
        "value": 139.688,
        "_internal_originalTime": 1689782149000
    },
    {
        "time": 1689782152000,
        "value": 139.688,
        "_internal_originalTime": 1689782152000
    },
    {
        "time": 1689782154000,
        "value": 139.686,
        "_internal_originalTime": 1689782154000
    },
    {
        "time": 1689782155000,
        "value": 139.688,
        "_internal_originalTime": 1689782155000
    },
    {
        "time": 1689782156000,
        "value": 139.681,
        "_internal_originalTime": 1689782156000
    },
    {
        "time": 1689782157000,
        "value": 139.676,
        "_internal_originalTime": 1689782157000
    },
    {
        "time": 1689782158000,
        "value": 139.679,
        "_internal_originalTime": 1689782158000
    },
    {
        "time": 1689782159000,
        "value": 139.678,
        "_internal_originalTime": 1689782159000
    },
    {
        "time": 1689782160000,
        "value": 139.678,
        "_internal_originalTime": 1689782160000
    },
    {
        "time": 1689782161000,
        "value": 139.678,
        "_internal_originalTime": 1689782161000
    },
    {
        "time": 1689782163000,
        "value": 139.679,
        "_internal_originalTime": 1689782163000
    },
    {
        "time": 1689782164000,
        "value": 139.675,
        "_internal_originalTime": 1689782164000
    },
    {
        "time": 1689782165000,
        "value": 139.674,
        "_internal_originalTime": 1689782165000
    },
    {
        "time": 1689782166000,
        "value": 139.675,
        "_internal_originalTime": 1689782166000
    },
    {
        "time": 1689782168000,
        "value": 139.673,
        "_internal_originalTime": 1689782168000
    },
    {
        "time": 1689782169000,
        "value": 139.67,
        "_internal_originalTime": 1689782169000
    },
    {
        "time": 1689782173000,
        "value": 139.671,
        "_internal_originalTime": 1689782173000
    },
    {
        "time": 1689782174000,
        "value": 139.664,
        "_internal_originalTime": 1689782174000
    },
    {
        "time": 1689782175000,
        "value": 139.666,
        "_internal_originalTime": 1689782175000
    },
    {
        "time": 1689782177000,
        "value": 139.671,
        "_internal_originalTime": 1689782177000
    },
    {
        "time": 1689782179000,
        "value": 139.67,
        "_internal_originalTime": 1689782179000
    },
    {
        "time": 1689782180000,
        "value": 139.67,
        "_internal_originalTime": 1689782180000
    },
    {
        "time": 1689782182000,
        "value": 139.67,
        "_internal_originalTime": 1689782182000
    },
    {
        "time": 1689782184000,
        "value": 139.685,
        "_internal_originalTime": 1689782184000
    },
    {
        "time": 1689782185000,
        "value": 139.688,
        "_internal_originalTime": 1689782185000
    },
    {
        "time": 1689782186000,
        "value": 139.688,
        "_internal_originalTime": 1689782186000
    },
    {
        "time": 1689782188000,
        "value": 139.684,
        "_internal_originalTime": 1689782188000
    },
    {
        "time": 1689782189000,
        "value": 139.684,
        "_internal_originalTime": 1689782189000
    },
    {
        "time": 1689782190000,
        "value": 139.69,
        "_internal_originalTime": 1689782190000
    },
    {
        "time": 1689782192000,
        "value": 139.684,
        "_internal_originalTime": 1689782192000
    },
    {
        "time": 1689782194000,
        "value": 139.687,
        "_internal_originalTime": 1689782194000
    },
    {
        "time": 1689782198000,
        "value": 139.687,
        "_internal_originalTime": 1689782198000
    },
    {
        "time": 1689782200000,
        "value": 139.689,
        "_internal_originalTime": 1689782200000
    },
    {
        "time": 1689782201000,
        "value": 139.688,
        "_internal_originalTime": 1689782201000
    },
    {
        "time": 1689782202000,
        "value": 139.69,
        "_internal_originalTime": 1689782202000
    },
    {
        "time": 1689782204000,
        "value": 139.678,
        "_internal_originalTime": 1689782204000
    },
    {
        "time": 1689782205000,
        "value": 139.682,
        "_internal_originalTime": 1689782205000
    },
    {
        "time": 1689782207000,
        "value": 139.68,
        "_internal_originalTime": 1689782207000
    },
    {
        "time": 1689782208000,
        "value": 139.676,
        "_internal_originalTime": 1689782208000
    },
    {
        "time": 1689782209000,
        "value": 139.677,
        "_internal_originalTime": 1689782209000
    },
    {
        "time": 1689782210000,
        "value": 139.679,
        "_internal_originalTime": 1689782210000
    },
    {
        "time": 1689782211000,
        "value": 139.675,
        "_internal_originalTime": 1689782211000
    },
    {
        "time": 1689782212000,
        "value": 139.678,
        "_internal_originalTime": 1689782212000
    },
    {
        "time": 1689782213000,
        "value": 139.679,
        "_internal_originalTime": 1689782213000
    },
    {
        "time": 1689782215000,
        "value": 139.68,
        "_internal_originalTime": 1689782215000
    },
    {
        "time": 1689782218000,
        "value": 139.679,
        "_internal_originalTime": 1689782218000
    },
    {
        "time": 1689782220000,
        "value": 139.681,
        "_internal_originalTime": 1689782220000
    },
    {
        "time": 1689782221000,
        "value": 139.68,
        "_internal_originalTime": 1689782221000
    },
    {
        "time": 1689782222000,
        "value": 139.686,
        "_internal_originalTime": 1689782222000
    },
    {
        "time": 1689782223000,
        "value": 139.687,
        "_internal_originalTime": 1689782223000
    },
    {
        "time": 1689782225000,
        "value": 139.682,
        "_internal_originalTime": 1689782225000
    },
    {
        "time": 1689782226000,
        "value": 139.689,
        "_internal_originalTime": 1689782226000
    },
    {
        "time": 1689782227000,
        "value": 139.698,
        "_internal_originalTime": 1689782227000
    },
    {
        "time": 1689782228000,
        "value": 139.699,
        "_internal_originalTime": 1689782228000
    },
    {
        "time": 1689782230000,
        "value": 139.699,
        "_internal_originalTime": 1689782230000
    },
    {
        "time": 1689782231000,
        "value": 139.701,
        "_internal_originalTime": 1689782231000
    },
    {
        "time": 1689782232000,
        "value": 139.7,
        "_internal_originalTime": 1689782232000
    },
    {
        "time": 1689782233000,
        "value": 139.698,
        "_internal_originalTime": 1689782233000
    },
    {
        "time": 1689782235000,
        "value": 139.701,
        "_internal_originalTime": 1689782235000
    },
    {
        "time": 1689782236000,
        "value": 139.709,
        "_internal_originalTime": 1689782236000
    },
    {
        "time": 1689782237000,
        "value": 139.711,
        "_internal_originalTime": 1689782237000
    },
    {
        "time": 1689782238000,
        "value": 139.707,
        "_internal_originalTime": 1689782238000
    },
    {
        "time": 1689782240000,
        "value": 139.712,
        "_internal_originalTime": 1689782240000
    },
    {
        "time": 1689782241000,
        "value": 139.711,
        "_internal_originalTime": 1689782241000
    },
    {
        "time": 1689782242000,
        "value": 139.719,
        "_internal_originalTime": 1689782242000
    },
    {
        "time": 1689782243000,
        "value": 139.719,
        "_internal_originalTime": 1689782243000
    },
    {
        "time": 1689782244000,
        "value": 139.717,
        "_internal_originalTime": 1689782244000
    },
    {
        "time": 1689782245000,
        "value": 139.718,
        "_internal_originalTime": 1689782245000
    },
    {
        "time": 1689782246000,
        "value": 139.714,
        "_internal_originalTime": 1689782246000
    },
    {
        "time": 1689782248000,
        "value": 139.718,
        "_internal_originalTime": 1689782248000
    },
    {
        "time": 1689782250000,
        "value": 139.718,
        "_internal_originalTime": 1689782250000
    },
    {
        "time": 1689782252000,
        "value": 139.714,
        "_internal_originalTime": 1689782252000
    },
    {
        "time": 1689782253000,
        "value": 139.718,
        "_internal_originalTime": 1689782253000
    },
    {
        "time": 1689782255000,
        "value": 139.718,
        "_internal_originalTime": 1689782255000
    },
    {
        "time": 1689782256000,
        "value": 139.714,
        "_internal_originalTime": 1689782256000
    },
    {
        "time": 1689782258000,
        "value": 139.714,
        "_internal_originalTime": 1689782258000
    },
    {
        "time": 1689782259000,
        "value": 139.716,
        "_internal_originalTime": 1689782259000
    },
    {
        "time": 1689782260000,
        "value": 139.716,
        "_internal_originalTime": 1689782260000
    },
    {
        "time": 1689782261000,
        "value": 139.717,
        "_internal_originalTime": 1689782261000
    },
    {
        "time": 1689782262000,
        "value": 139.716,
        "_internal_originalTime": 1689782262000
    },
    {
        "time": 1689782264000,
        "value": 139.711,
        "_internal_originalTime": 1689782264000
    },
    {
        "time": 1689782266000,
        "value": 139.72,
        "_internal_originalTime": 1689782266000
    },
    {
        "time": 1689782268000,
        "value": 139.723,
        "_internal_originalTime": 1689782268000
    },
    {
        "time": 1689782269000,
        "value": 139.721,
        "_internal_originalTime": 1689782269000
    },
    {
        "time": 1689782272000,
        "value": 139.722,
        "_internal_originalTime": 1689782272000
    },
    {
        "time": 1689782273000,
        "value": 139.72,
        "_internal_originalTime": 1689782273000
    },
    {
        "time": 1689782274000,
        "value": 139.718,
        "_internal_originalTime": 1689782274000
    },
    {
        "time": 1689782276000,
        "value": 139.714,
        "_internal_originalTime": 1689782276000
    },
    {
        "time": 1689782277000,
        "value": 139.72,
        "_internal_originalTime": 1689782277000
    },
    {
        "time": 1689782278000,
        "value": 139.72,
        "_internal_originalTime": 1689782278000
    },
    {
        "time": 1689782279000,
        "value": 139.714,
        "_internal_originalTime": 1689782279000
    },
    {
        "time": 1689782280000,
        "value": 139.722,
        "_internal_originalTime": 1689782280000
    },
    {
        "time": 1689782281000,
        "value": 139.719,
        "_internal_originalTime": 1689782281000
    },
    {
        "time": 1689782282000,
        "value": 139.72,
        "_internal_originalTime": 1689782282000
    },
    {
        "time": 1689782283000,
        "value": 139.722,
        "_internal_originalTime": 1689782283000
    },
    {
        "time": 1689782284000,
        "value": 139.722,
        "_internal_originalTime": 1689782284000
    },
    {
        "time": 1689782285000,
        "value": 139.721,
        "_internal_originalTime": 1689782285000
    },
    {
        "time": 1689782286000,
        "value": 139.722,
        "_internal_originalTime": 1689782286000
    },
    {
        "time": 1689782287000,
        "value": 139.729,
        "_internal_originalTime": 1689782287000
    },
    {
        "time": 1689782288000,
        "value": 139.73,
        "_internal_originalTime": 1689782288000
    },
    {
        "time": 1689782291000,
        "value": 139.729,
        "_internal_originalTime": 1689782291000
    },
    {
        "time": 1689782293000,
        "value": 139.726,
        "_internal_originalTime": 1689782293000
    },
    {
        "time": 1689782294000,
        "value": 139.726,
        "_internal_originalTime": 1689782294000
    },
    {
        "time": 1689782295000,
        "value": 139.722,
        "_internal_originalTime": 1689782295000
    },
    {
        "time": 1689782296000,
        "value": 139.722,
        "_internal_originalTime": 1689782296000
    },
    {
        "time": 1689782297000,
        "value": 139.725,
        "_internal_originalTime": 1689782297000
    },
    {
        "time": 1689782298000,
        "value": 139.722,
        "_internal_originalTime": 1689782298000
    },
    {
        "time": 1689782300000,
        "value": 139.724,
        "_internal_originalTime": 1689782300000
    },
    {
        "time": 1689782301000,
        "value": 139.727,
        "_internal_originalTime": 1689782301000
    },
    {
        "time": 1689782302000,
        "value": 139.726,
        "_internal_originalTime": 1689782302000
    },
    {
        "time": 1689782303000,
        "value": 139.726,
        "_internal_originalTime": 1689782303000
    },
    {
        "time": 1689782304000,
        "value": 139.73,
        "_internal_originalTime": 1689782304000
    },
    {
        "time": 1689782306000,
        "value": 139.726,
        "_internal_originalTime": 1689782306000
    },
    {
        "time": 1689782307000,
        "value": 139.729,
        "_internal_originalTime": 1689782307000
    },
    {
        "time": 1689782308000,
        "value": 139.728,
        "_internal_originalTime": 1689782308000
    },
    {
        "time": 1689782310000,
        "value": 139.728,
        "_internal_originalTime": 1689782310000
    },
    {
        "time": 1689782311000,
        "value": 139.732,
        "_internal_originalTime": 1689782311000
    },
    {
        "time": 1689782312000,
        "value": 139.73,
        "_internal_originalTime": 1689782312000
    },
    {
        "time": 1689782313000,
        "value": 139.73,
        "_internal_originalTime": 1689782313000
    },
    {
        "time": 1689782314000,
        "value": 139.73,
        "_internal_originalTime": 1689782314000
    },
    {
        "time": 1689782316000,
        "value": 139.737,
        "_internal_originalTime": 1689782316000
    },
    {
        "time": 1689782317000,
        "value": 139.736,
        "_internal_originalTime": 1689782317000
    },
    {
        "time": 1689782318000,
        "value": 139.737,
        "_internal_originalTime": 1689782318000
    },
    {
        "time": 1689782319000,
        "value": 139.74,
        "_internal_originalTime": 1689782319000
    },
    {
        "time": 1689782320000,
        "value": 139.741,
        "_internal_originalTime": 1689782320000
    },
    {
        "time": 1689782321000,
        "value": 139.736,
        "_internal_originalTime": 1689782321000
    },
    {
        "time": 1689782323000,
        "value": 139.741,
        "_internal_originalTime": 1689782323000
    },
    {
        "time": 1689782324000,
        "value": 139.735,
        "_internal_originalTime": 1689782324000
    },
    {
        "time": 1689782326000,
        "value": 139.742,
        "_internal_originalTime": 1689782326000
    },
    {
        "time": 1689782328000,
        "value": 139.741,
        "_internal_originalTime": 1689782328000
    },
    {
        "time": 1689782329000,
        "value": 139.746,
        "_internal_originalTime": 1689782329000
    },
    {
        "time": 1689782330000,
        "value": 139.749,
        "_internal_originalTime": 1689782330000
    },
    {
        "time": 1689782331000,
        "value": 139.746,
        "_internal_originalTime": 1689782331000
    },
    {
        "time": 1689782332000,
        "value": 139.749,
        "_internal_originalTime": 1689782332000
    },
    {
        "time": 1689782333000,
        "value": 139.749,
        "_internal_originalTime": 1689782333000
    },
    {
        "time": 1689782335000,
        "value": 139.749,
        "_internal_originalTime": 1689782335000
    },
    {
        "time": 1689782336000,
        "value": 139.745,
        "_internal_originalTime": 1689782336000
    },
    {
        "time": 1689782337000,
        "value": 139.733,
        "_internal_originalTime": 1689782337000
    },
    {
        "time": 1689782338000,
        "value": 139.731,
        "_internal_originalTime": 1689782338000
    },
    {
        "time": 1689782339000,
        "value": 139.731,
        "_internal_originalTime": 1689782339000
    },
    {
        "time": 1689782341000,
        "value": 139.735,
        "_internal_originalTime": 1689782341000
    },
    {
        "time": 1689782343000,
        "value": 139.733,
        "_internal_originalTime": 1689782343000
    },
    {
        "time": 1689782344000,
        "value": 139.729,
        "_internal_originalTime": 1689782344000
    },
    {
        "time": 1689782345000,
        "value": 139.73,
        "_internal_originalTime": 1689782345000
    },
    {
        "time": 1689782346000,
        "value": 139.722,
        "_internal_originalTime": 1689782346000
    },
    {
        "time": 1689782348000,
        "value": 139.726,
        "_internal_originalTime": 1689782348000
    },
    {
        "time": 1689782349000,
        "value": 139.732,
        "_internal_originalTime": 1689782349000
    },
    {
        "time": 1689782351000,
        "value": 139.739,
        "_internal_originalTime": 1689782351000
    },
    {
        "time": 1689782352000,
        "value": 139.739,
        "_internal_originalTime": 1689782352000
    },
    {
        "time": 1689782353000,
        "value": 139.739,
        "_internal_originalTime": 1689782353000
    },
    {
        "time": 1689782354000,
        "value": 139.736,
        "_internal_originalTime": 1689782354000
    },
    {
        "time": 1689782355000,
        "value": 139.736,
        "_internal_originalTime": 1689782355000
    },
    {
        "time": 1689782356000,
        "value": 139.732,
        "_internal_originalTime": 1689782356000
    },
    {
        "time": 1689782357000,
        "value": 139.732,
        "_internal_originalTime": 1689782357000
    },
    {
        "time": 1689782358000,
        "value": 139.731,
        "_internal_originalTime": 1689782358000
    },
    {
        "time": 1689782360000,
        "value": 139.732,
        "_internal_originalTime": 1689782360000
    },
    {
        "time": 1689782361000,
        "value": 139.731,
        "_internal_originalTime": 1689782361000
    },
    {
        "time": 1689782362000,
        "value": 139.732,
        "_internal_originalTime": 1689782362000
    },
    {
        "time": 1689782364000,
        "value": 139.731,
        "_internal_originalTime": 1689782364000
    },
    {
        "time": 1689782365000,
        "value": 139.731,
        "_internal_originalTime": 1689782365000
    },
    {
        "time": 1689782366000,
        "value": 139.728,
        "_internal_originalTime": 1689782366000
    },
    {
        "time": 1689782368000,
        "value": 139.733,
        "_internal_originalTime": 1689782368000
    },
    {
        "time": 1689782369000,
        "value": 139.736,
        "_internal_originalTime": 1689782369000
    },
    {
        "time": 1689782370000,
        "value": 139.734,
        "_internal_originalTime": 1689782370000
    },
    {
        "time": 1689782372000,
        "value": 139.734,
        "_internal_originalTime": 1689782372000
    },
    {
        "time": 1689782373000,
        "value": 139.737,
        "_internal_originalTime": 1689782373000
    },
    {
        "time": 1689782374000,
        "value": 139.737,
        "_internal_originalTime": 1689782374000
    },
    {
        "time": 1689782375000,
        "value": 139.735,
        "_internal_originalTime": 1689782375000
    },
    {
        "time": 1689782376000,
        "value": 139.736,
        "_internal_originalTime": 1689782376000
    },
    {
        "time": 1689782378000,
        "value": 139.74,
        "_internal_originalTime": 1689782378000
    },
    {
        "time": 1689782379000,
        "value": 139.742,
        "_internal_originalTime": 1689782379000
    },
    {
        "time": 1689782381000,
        "value": 139.75,
        "_internal_originalTime": 1689782381000
    },
    {
        "time": 1689782382000,
        "value": 139.746,
        "_internal_originalTime": 1689782382000
    },
    {
        "time": 1689782385000,
        "value": 139.746,
        "_internal_originalTime": 1689782385000
    },
    {
        "time": 1689782386000,
        "value": 139.747,
        "_internal_originalTime": 1689782386000
    },
    {
        "time": 1689782387000,
        "value": 139.747,
        "_internal_originalTime": 1689782387000
    },
    {
        "time": 1689782388000,
        "value": 139.746,
        "_internal_originalTime": 1689782388000
    },
    {
        "time": 1689782389000,
        "value": 139.75,
        "_internal_originalTime": 1689782389000
    },
    {
        "time": 1689782390000,
        "value": 139.748,
        "_internal_originalTime": 1689782390000
    },
    {
        "time": 1689782391000,
        "value": 139.746,
        "_internal_originalTime": 1689782391000
    },
    {
        "time": 1689782392000,
        "value": 139.762,
        "_internal_originalTime": 1689782392000
    },
    {
        "time": 1689782394000,
        "value": 139.763,
        "_internal_originalTime": 1689782394000
    },
    {
        "time": 1689782395000,
        "value": 139.761,
        "_internal_originalTime": 1689782395000
    },
    {
        "time": 1689782396000,
        "value": 139.759,
        "_internal_originalTime": 1689782396000
    },
    {
        "time": 1689782397000,
        "value": 139.76,
        "_internal_originalTime": 1689782397000
    },
    {
        "time": 1689782399000,
        "value": 139.763,
        "_internal_originalTime": 1689782399000
    },
    {
        "time": 1689782400000,
        "value": 139.769,
        "_internal_originalTime": 1689782400000
    },
    {
        "time": 1689782401000,
        "value": 139.762,
        "_internal_originalTime": 1689782401000
    },
    {
        "time": 1689782402000,
        "value": 139.762,
        "_internal_originalTime": 1689782402000
    },
    {
        "time": 1689782404000,
        "value": 139.767,
        "_internal_originalTime": 1689782404000
    },
    {
        "time": 1689782406000,
        "value": 139.768,
        "_internal_originalTime": 1689782406000
    },
    {
        "time": 1689782408000,
        "value": 139.752,
        "_internal_originalTime": 1689782408000
    },
    {
        "time": 1689782409000,
        "value": 139.749,
        "_internal_originalTime": 1689782409000
    },
    {
        "time": 1689782410000,
        "value": 139.756,
        "_internal_originalTime": 1689782410000
    },
    {
        "time": 1689782411000,
        "value": 139.761,
        "_internal_originalTime": 1689782411000
    },
    {
        "time": 1689782412000,
        "value": 139.756,
        "_internal_originalTime": 1689782412000
    },
    {
        "time": 1689782413000,
        "value": 139.761,
        "_internal_originalTime": 1689782413000
    },
    {
        "time": 1689782414000,
        "value": 139.753,
        "_internal_originalTime": 1689782414000
    },
    {
        "time": 1689782416000,
        "value": 139.754,
        "_internal_originalTime": 1689782416000
    },
    {
        "time": 1689782417000,
        "value": 139.756,
        "_internal_originalTime": 1689782417000
    },
    {
        "time": 1689782418000,
        "value": 139.754,
        "_internal_originalTime": 1689782418000
    },
    {
        "time": 1689782419000,
        "value": 139.759,
        "_internal_originalTime": 1689782419000
    },
    {
        "time": 1689782420000,
        "value": 139.762,
        "_internal_originalTime": 1689782420000
    },
    {
        "time": 1689782422000,
        "value": 139.761,
        "_internal_originalTime": 1689782422000
    },
    {
        "time": 1689782424000,
        "value": 139.758,
        "_internal_originalTime": 1689782424000
    },
    {
        "time": 1689782425000,
        "value": 139.764,
        "_internal_originalTime": 1689782425000
    },
    {
        "time": 1689782426000,
        "value": 139.761,
        "_internal_originalTime": 1689782426000
    },
    {
        "time": 1689782427000,
        "value": 139.755,
        "_internal_originalTime": 1689782427000
    },
    {
        "time": 1689782428000,
        "value": 139.754,
        "_internal_originalTime": 1689782428000
    },
    {
        "time": 1689782429000,
        "value": 139.752,
        "_internal_originalTime": 1689782429000
    },
    {
        "time": 1689782430000,
        "value": 139.754,
        "_internal_originalTime": 1689782430000
    },
    {
        "time": 1689782431000,
        "value": 139.753,
        "_internal_originalTime": 1689782431000
    },
    {
        "time": 1689782433000,
        "value": 139.754,
        "_internal_originalTime": 1689782433000
    },
    {
        "time": 1689782434000,
        "value": 139.746,
        "_internal_originalTime": 1689782434000
    },
    {
        "time": 1689782435000,
        "value": 139.748,
        "_internal_originalTime": 1689782435000
    },
    {
        "time": 1689782436000,
        "value": 139.747,
        "_internal_originalTime": 1689782436000
    },
    {
        "time": 1689782437000,
        "value": 139.754,
        "_internal_originalTime": 1689782437000
    },
    {
        "time": 1689782438000,
        "value": 139.753,
        "_internal_originalTime": 1689782438000
    },
    {
        "time": 1689782440000,
        "value": 139.755,
        "_internal_originalTime": 1689782440000
    },
    {
        "time": 1689782441000,
        "value": 139.757,
        "_internal_originalTime": 1689782441000
    },
    {
        "time": 1689782442000,
        "value": 139.75,
        "_internal_originalTime": 1689782442000
    },
    {
        "time": 1689782443000,
        "value": 139.753,
        "_internal_originalTime": 1689782443000
    },
    {
        "time": 1689782444000,
        "value": 139.747,
        "_internal_originalTime": 1689782444000
    },
    {
        "time": 1689782447000,
        "value": 139.753,
        "_internal_originalTime": 1689782447000
    },
    {
        "time": 1689782448000,
        "value": 139.747,
        "_internal_originalTime": 1689782448000
    },
    {
        "time": 1689782449000,
        "value": 139.748,
        "_internal_originalTime": 1689782449000
    },
    {
        "time": 1689782450000,
        "value": 139.746,
        "_internal_originalTime": 1689782450000
    },
    {
        "time": 1689782451000,
        "value": 139.742,
        "_internal_originalTime": 1689782451000
    },
    {
        "time": 1689782452000,
        "value": 139.745,
        "_internal_originalTime": 1689782452000
    },
    {
        "time": 1689782453000,
        "value": 139.745,
        "_internal_originalTime": 1689782453000
    },
    {
        "time": 1689782454000,
        "value": 139.745,
        "_internal_originalTime": 1689782454000
    },
    {
        "time": 1689782456000,
        "value": 139.748,
        "_internal_originalTime": 1689782456000
    },
    {
        "time": 1689782458000,
        "value": 139.745,
        "_internal_originalTime": 1689782458000
    },
    {
        "time": 1689782460000,
        "value": 139.743,
        "_internal_originalTime": 1689782460000
    },
    {
        "time": 1689782461000,
        "value": 139.742,
        "_internal_originalTime": 1689782461000
    },
    {
        "time": 1689782462000,
        "value": 139.734,
        "_internal_originalTime": 1689782462000
    },
    {
        "time": 1689782463000,
        "value": 139.738,
        "_internal_originalTime": 1689782463000
    },
    {
        "time": 1689782465000,
        "value": 139.734,
        "_internal_originalTime": 1689782465000
    },
    {
        "time": 1689782466000,
        "value": 139.735,
        "_internal_originalTime": 1689782466000
    },
    {
        "time": 1689782467000,
        "value": 139.737,
        "_internal_originalTime": 1689782467000
    },
    {
        "time": 1689782468000,
        "value": 139.739,
        "_internal_originalTime": 1689782468000
    },
    {
        "time": 1689782469000,
        "value": 139.738,
        "_internal_originalTime": 1689782469000
    },
    {
        "time": 1689782470000,
        "value": 139.742,
        "_internal_originalTime": 1689782470000
    },
    {
        "time": 1689782471000,
        "value": 139.742,
        "_internal_originalTime": 1689782471000
    },
    {
        "time": 1689782472000,
        "value": 139.736,
        "_internal_originalTime": 1689782472000
    },
    {
        "time": 1689782473000,
        "value": 139.742,
        "_internal_originalTime": 1689782473000
    },
    {
        "time": 1689782475000,
        "value": 139.739,
        "_internal_originalTime": 1689782475000
    },
    {
        "time": 1689782476000,
        "value": 139.738,
        "_internal_originalTime": 1689782476000
    },
    {
        "time": 1689782477000,
        "value": 139.738,
        "_internal_originalTime": 1689782477000
    },
    {
        "time": 1689782479000,
        "value": 139.744,
        "_internal_originalTime": 1689782479000
    },
    {
        "time": 1689782482000,
        "value": 139.745,
        "_internal_originalTime": 1689782482000
    },
    {
        "time": 1689782483000,
        "value": 139.741,
        "_internal_originalTime": 1689782483000
    },
    {
        "time": 1689782484000,
        "value": 139.738,
        "_internal_originalTime": 1689782484000
    },
    {
        "time": 1689782486000,
        "value": 139.738,
        "_internal_originalTime": 1689782486000
    },
    {
        "time": 1689782488000,
        "value": 139.741,
        "_internal_originalTime": 1689782488000
    },
    {
        "time": 1689782489000,
        "value": 139.739,
        "_internal_originalTime": 1689782489000
    },
    {
        "time": 1689782490000,
        "value": 139.737,
        "_internal_originalTime": 1689782490000
    },
    {
        "time": 1689782491000,
        "value": 139.738,
        "_internal_originalTime": 1689782491000
    },
    {
        "time": 1689782492000,
        "value": 139.734,
        "_internal_originalTime": 1689782492000
    },
    {
        "time": 1689782493000,
        "value": 139.734,
        "_internal_originalTime": 1689782493000
    },
    {
        "time": 1689782495000,
        "value": 139.73,
        "_internal_originalTime": 1689782495000
    },
    {
        "time": 1689782497000,
        "value": 139.734,
        "_internal_originalTime": 1689782497000
    },
    {
        "time": 1689782498000,
        "value": 139.736,
        "_internal_originalTime": 1689782498000
    },
    {
        "time": 1689782499000,
        "value": 139.735,
        "_internal_originalTime": 1689782499000
    },
    {
        "time": 1689782501000,
        "value": 139.736,
        "_internal_originalTime": 1689782501000
    },
    {
        "time": 1689782502000,
        "value": 139.732,
        "_internal_originalTime": 1689782502000
    },
    {
        "time": 1689782504000,
        "value": 139.734,
        "_internal_originalTime": 1689782504000
    },
    {
        "time": 1689782505000,
        "value": 139.742,
        "_internal_originalTime": 1689782505000
    },
    {
        "time": 1689782506000,
        "value": 139.741,
        "_internal_originalTime": 1689782506000
    },
    {
        "time": 1689782507000,
        "value": 139.743,
        "_internal_originalTime": 1689782507000
    },
    {
        "time": 1689782508000,
        "value": 139.745,
        "_internal_originalTime": 1689782508000
    },
    {
        "time": 1689782509000,
        "value": 139.742,
        "_internal_originalTime": 1689782509000
    },
    {
        "time": 1689782511000,
        "value": 139.742,
        "_internal_originalTime": 1689782511000
    },
    {
        "time": 1689782512000,
        "value": 139.746,
        "_internal_originalTime": 1689782512000
    },
    {
        "time": 1689782513000,
        "value": 139.746,
        "_internal_originalTime": 1689782513000
    },
    {
        "time": 1689782515000,
        "value": 139.747,
        "_internal_originalTime": 1689782515000
    },
    {
        "time": 1689782517000,
        "value": 139.742,
        "_internal_originalTime": 1689782517000
    },
    {
        "time": 1689782518000,
        "value": 139.74,
        "_internal_originalTime": 1689782518000
    },
    {
        "time": 1689782520000,
        "value": 139.738,
        "_internal_originalTime": 1689782520000
    },
    {
        "time": 1689782521000,
        "value": 139.74,
        "_internal_originalTime": 1689782521000
    },
    {
        "time": 1689782522000,
        "value": 139.737,
        "_internal_originalTime": 1689782522000
    },
    {
        "time": 1689782523000,
        "value": 139.74,
        "_internal_originalTime": 1689782523000
    },
    {
        "time": 1689782524000,
        "value": 139.74,
        "_internal_originalTime": 1689782524000
    },
    {
        "time": 1689782525000,
        "value": 139.74,
        "_internal_originalTime": 1689782525000
    },
    {
        "time": 1689782526000,
        "value": 139.738,
        "_internal_originalTime": 1689782526000
    },
    {
        "time": 1689782527000,
        "value": 139.738,
        "_internal_originalTime": 1689782527000
    },
    {
        "time": 1689782528000,
        "value": 139.738,
        "_internal_originalTime": 1689782528000
    },
    {
        "time": 1689782529000,
        "value": 139.738,
        "_internal_originalTime": 1689782529000
    },
    {
        "time": 1689782530000,
        "value": 139.742,
        "_internal_originalTime": 1689782530000
    },
    {
        "time": 1689782531000,
        "value": 139.742,
        "_internal_originalTime": 1689782531000
    },
    {
        "time": 1689782533000,
        "value": 139.742,
        "_internal_originalTime": 1689782533000
    },
    {
        "time": 1689782534000,
        "value": 139.747,
        "_internal_originalTime": 1689782534000
    },
    {
        "time": 1689782535000,
        "value": 139.753,
        "_internal_originalTime": 1689782535000
    },
    {
        "time": 1689782536000,
        "value": 139.75,
        "_internal_originalTime": 1689782536000
    },
    {
        "time": 1689782537000,
        "value": 139.751,
        "_internal_originalTime": 1689782537000
    },
    {
        "time": 1689782538000,
        "value": 139.75,
        "_internal_originalTime": 1689782538000
    },
    {
        "time": 1689782539000,
        "value": 139.753,
        "_internal_originalTime": 1689782539000
    },
    {
        "time": 1689782540000,
        "value": 139.753,
        "_internal_originalTime": 1689782540000
    },
    {
        "time": 1689782542000,
        "value": 139.754,
        "_internal_originalTime": 1689782542000
    },
    {
        "time": 1689782543000,
        "value": 139.753,
        "_internal_originalTime": 1689782543000
    },
    {
        "time": 1689782546000,
        "value": 139.752,
        "_internal_originalTime": 1689782546000
    },
    {
        "time": 1689782547000,
        "value": 139.763,
        "_internal_originalTime": 1689782547000
    },
    {
        "time": 1689782549000,
        "value": 139.76,
        "_internal_originalTime": 1689782549000
    },
    {
        "time": 1689782550000,
        "value": 139.759,
        "_internal_originalTime": 1689782550000
    },
    {
        "time": 1689782551000,
        "value": 139.756,
        "_internal_originalTime": 1689782551000
    },
    {
        "time": 1689782552000,
        "value": 139.757,
        "_internal_originalTime": 1689782552000
    },
    {
        "time": 1689782554000,
        "value": 139.755,
        "_internal_originalTime": 1689782554000
    },
    {
        "time": 1689782555000,
        "value": 139.754,
        "_internal_originalTime": 1689782555000
    },
    {
        "time": 1689782556000,
        "value": 139.756,
        "_internal_originalTime": 1689782556000
    },
    {
        "time": 1689782558000,
        "value": 139.757,
        "_internal_originalTime": 1689782558000
    },
    {
        "time": 1689782559000,
        "value": 139.755,
        "_internal_originalTime": 1689782559000
    },
    {
        "time": 1689782561000,
        "value": 139.757,
        "_internal_originalTime": 1689782561000
    },
    {
        "time": 1689782562000,
        "value": 139.755,
        "_internal_originalTime": 1689782562000
    },
    {
        "time": 1689782564000,
        "value": 139.76,
        "_internal_originalTime": 1689782564000
    },
    {
        "time": 1689782565000,
        "value": 139.758,
        "_internal_originalTime": 1689782565000
    },
    {
        "time": 1689782567000,
        "value": 139.762,
        "_internal_originalTime": 1689782567000
    },
    {
        "time": 1689782569000,
        "value": 139.761,
        "_internal_originalTime": 1689782569000
    },
    {
        "time": 1689782570000,
        "value": 139.758,
        "_internal_originalTime": 1689782570000
    },
    {
        "time": 1689782571000,
        "value": 139.759,
        "_internal_originalTime": 1689782571000
    },
    {
        "time": 1689782572000,
        "value": 139.761,
        "_internal_originalTime": 1689782572000
    },
    {
        "time": 1689782575000,
        "value": 139.762,
        "_internal_originalTime": 1689782575000
    },
    {
        "time": 1689782576000,
        "value": 139.761,
        "_internal_originalTime": 1689782576000
    },
    {
        "time": 1689782577000,
        "value": 139.761,
        "_internal_originalTime": 1689782577000
    },
    {
        "time": 1689782578000,
        "value": 139.762,
        "_internal_originalTime": 1689782578000
    },
    {
        "time": 1689782579000,
        "value": 139.76,
        "_internal_originalTime": 1689782579000
    },
    {
        "time": 1689782581000,
        "value": 139.764,
        "_internal_originalTime": 1689782581000
    },
    {
        "time": 1689782582000,
        "value": 139.762,
        "_internal_originalTime": 1689782582000
    },
    {
        "time": 1689782583000,
        "value": 139.763,
        "_internal_originalTime": 1689782583000
    },
    {
        "time": 1689782584000,
        "value": 139.764,
        "_internal_originalTime": 1689782584000
    },
    {
        "time": 1689782586000,
        "value": 139.764,
        "_internal_originalTime": 1689782586000
    },
    {
        "time": 1689782587000,
        "value": 139.766,
        "_internal_originalTime": 1689782587000
    },
    {
        "time": 1689782588000,
        "value": 139.767,
        "_internal_originalTime": 1689782588000
    },
    {
        "time": 1689782589000,
        "value": 139.762,
        "_internal_originalTime": 1689782589000
    },
    {
        "time": 1689782590000,
        "value": 139.764,
        "_internal_originalTime": 1689782590000
    },
    {
        "time": 1689782591000,
        "value": 139.767,
        "_internal_originalTime": 1689782591000
    },
    {
        "time": 1689782593000,
        "value": 139.766,
        "_internal_originalTime": 1689782593000
    },
    {
        "time": 1689782594000,
        "value": 139.761,
        "_internal_originalTime": 1689782594000
    },
    {
        "time": 1689782595000,
        "value": 139.767,
        "_internal_originalTime": 1689782595000
    },
    {
        "time": 1689782596000,
        "value": 139.765,
        "_internal_originalTime": 1689782596000
    },
    {
        "time": 1689782598000,
        "value": 139.765,
        "_internal_originalTime": 1689782598000
    },
    {
        "time": 1689782599000,
        "value": 139.766,
        "_internal_originalTime": 1689782599000
    },
    {
        "time": 1689782600000,
        "value": 139.767,
        "_internal_originalTime": 1689782600000
    },
    {
        "time": 1689782601000,
        "value": 139.768,
        "_internal_originalTime": 1689782601000
    },
    {
        "time": 1689782603000,
        "value": 139.764,
        "_internal_originalTime": 1689782603000
    },
    {
        "time": 1689782604000,
        "value": 139.755,
        "_internal_originalTime": 1689782604000
    },
    {
        "time": 1689782605000,
        "value": 139.754,
        "_internal_originalTime": 1689782605000
    },
    {
        "time": 1689782606000,
        "value": 139.755,
        "_internal_originalTime": 1689782606000
    },
    {
        "time": 1689782609000,
        "value": 139.758,
        "_internal_originalTime": 1689782609000
    },
    {
        "time": 1689782612000,
        "value": 139.758,
        "_internal_originalTime": 1689782612000
    },
    {
        "time": 1689782614000,
        "value": 139.76,
        "_internal_originalTime": 1689782614000
    },
    {
        "time": 1689782616000,
        "value": 139.757,
        "_internal_originalTime": 1689782616000
    },
    {
        "time": 1689782617000,
        "value": 139.756,
        "_internal_originalTime": 1689782617000
    },
    {
        "time": 1689782618000,
        "value": 139.759,
        "_internal_originalTime": 1689782618000
    },
    {
        "time": 1689782619000,
        "value": 139.756,
        "_internal_originalTime": 1689782619000
    },
    {
        "time": 1689782620000,
        "value": 139.758,
        "_internal_originalTime": 1689782620000
    },
    {
        "time": 1689782624000,
        "value": 139.752,
        "_internal_originalTime": 1689782624000
    },
    {
        "time": 1689782626000,
        "value": 139.755,
        "_internal_originalTime": 1689782626000
    },
    {
        "time": 1689782631000,
        "value": 139.759,
        "_internal_originalTime": 1689782631000
    },
    {
        "time": 1689782632000,
        "value": 139.765,
        "_internal_originalTime": 1689782632000
    },
    {
        "time": 1689782633000,
        "value": 139.76,
        "_internal_originalTime": 1689782633000
    },
    {
        "time": 1689782635000,
        "value": 139.763,
        "_internal_originalTime": 1689782635000
    },
    {
        "time": 1689782636000,
        "value": 139.76,
        "_internal_originalTime": 1689782636000
    },
    {
        "time": 1689782637000,
        "value": 139.762,
        "_internal_originalTime": 1689782637000
    },
    {
        "time": 1689782639000,
        "value": 139.76,
        "_internal_originalTime": 1689782639000
    },
    {
        "time": 1689782640000,
        "value": 139.756,
        "_internal_originalTime": 1689782640000
    },
    {
        "time": 1689782642000,
        "value": 139.756,
        "_internal_originalTime": 1689782642000
    },
    {
        "time": 1689782643000,
        "value": 139.756,
        "_internal_originalTime": 1689782643000
    },
    {
        "time": 1689782644000,
        "value": 139.758,
        "_internal_originalTime": 1689782644000
    },
    {
        "time": 1689782645000,
        "value": 139.761,
        "_internal_originalTime": 1689782645000
    },
    {
        "time": 1689782646000,
        "value": 139.762,
        "_internal_originalTime": 1689782646000
    },
    {
        "time": 1689782647000,
        "value": 139.756,
        "_internal_originalTime": 1689782647000
    },
    {
        "time": 1689782648000,
        "value": 139.761,
        "_internal_originalTime": 1689782648000
    },
    {
        "time": 1689782649000,
        "value": 139.762,
        "_internal_originalTime": 1689782649000
    },
    {
        "time": 1689782650000,
        "value": 139.76,
        "_internal_originalTime": 1689782650000
    },
    {
        "time": 1689782651000,
        "value": 139.758,
        "_internal_originalTime": 1689782651000
    },
    {
        "time": 1689782653000,
        "value": 139.759,
        "_internal_originalTime": 1689782653000
    },
    {
        "time": 1689782655000,
        "value": 139.764,
        "_internal_originalTime": 1689782655000
    },
    {
        "time": 1689782656000,
        "value": 139.766,
        "_internal_originalTime": 1689782656000
    },
    {
        "time": 1689782657000,
        "value": 139.764,
        "_internal_originalTime": 1689782657000
    },
    {
        "time": 1689782659000,
        "value": 139.769,
        "_internal_originalTime": 1689782659000
    },
    {
        "time": 1689782660000,
        "value": 139.767,
        "_internal_originalTime": 1689782660000
    },
    {
        "time": 1689782661000,
        "value": 139.768,
        "_internal_originalTime": 1689782661000
    },
    {
        "time": 1689782662000,
        "value": 139.779,
        "_internal_originalTime": 1689782662000
    },
    {
        "time": 1689782664000,
        "value": 139.778,
        "_internal_originalTime": 1689782664000
    },
    {
        "time": 1689782665000,
        "value": 139.779,
        "_internal_originalTime": 1689782665000
    },
    {
        "time": 1689782666000,
        "value": 139.778,
        "_internal_originalTime": 1689782666000
    },
    {
        "time": 1689782668000,
        "value": 139.779,
        "_internal_originalTime": 1689782668000
    },
    {
        "time": 1689782669000,
        "value": 139.783,
        "_internal_originalTime": 1689782669000
    },
    {
        "time": 1689782671000,
        "value": 139.783,
        "_internal_originalTime": 1689782671000
    },
    {
        "time": 1689782672000,
        "value": 139.782,
        "_internal_originalTime": 1689782672000
    },
    {
        "time": 1689782674000,
        "value": 139.788,
        "_internal_originalTime": 1689782674000
    },
    {
        "time": 1689782675000,
        "value": 139.788,
        "_internal_originalTime": 1689782675000
    },
    {
        "time": 1689782677000,
        "value": 139.786,
        "_internal_originalTime": 1689782677000
    },
    {
        "time": 1689782678000,
        "value": 139.785,
        "_internal_originalTime": 1689782678000
    },
    {
        "time": 1689782679000,
        "value": 139.789,
        "_internal_originalTime": 1689782679000
    },
    {
        "time": 1689782680000,
        "value": 139.793,
        "_internal_originalTime": 1689782680000
    },
    {
        "time": 1689782681000,
        "value": 139.793,
        "_internal_originalTime": 1689782681000
    },
    {
        "time": 1689782682000,
        "value": 139.796,
        "_internal_originalTime": 1689782682000
    },
    {
        "time": 1689782685000,
        "value": 139.795,
        "_internal_originalTime": 1689782685000
    },
    {
        "time": 1689782686000,
        "value": 139.801,
        "_internal_originalTime": 1689782686000
    },
    {
        "time": 1689782687000,
        "value": 139.799,
        "_internal_originalTime": 1689782687000
    },
    {
        "time": 1689782688000,
        "value": 139.801,
        "_internal_originalTime": 1689782688000
    },
    {
        "time": 1689782689000,
        "value": 139.802,
        "_internal_originalTime": 1689782689000
    },
    {
        "time": 1689782690000,
        "value": 139.8,
        "_internal_originalTime": 1689782690000
    },
    {
        "time": 1689782691000,
        "value": 139.802,
        "_internal_originalTime": 1689782691000
    },
    {
        "time": 1689782692000,
        "value": 139.801,
        "_internal_originalTime": 1689782692000
    },
    {
        "time": 1689782693000,
        "value": 139.796,
        "_internal_originalTime": 1689782693000
    },
    {
        "time": 1689782695000,
        "value": 139.796,
        "_internal_originalTime": 1689782695000
    },
    {
        "time": 1689782696000,
        "value": 139.796,
        "_internal_originalTime": 1689782696000
    },
    {
        "time": 1689782699000,
        "value": 139.797,
        "_internal_originalTime": 1689782699000
    },
    {
        "time": 1689782700000,
        "value": 139.796,
        "_internal_originalTime": 1689782700000
    },
    {
        "time": 1689782701000,
        "value": 139.794,
        "_internal_originalTime": 1689782701000
    },
    {
        "time": 1689782703000,
        "value": 139.799,
        "_internal_originalTime": 1689782703000
    },
    {
        "time": 1689782704000,
        "value": 139.798,
        "_internal_originalTime": 1689782704000
    },
    {
        "time": 1689782705000,
        "value": 139.795,
        "_internal_originalTime": 1689782705000
    },
    {
        "time": 1689782706000,
        "value": 139.794,
        "_internal_originalTime": 1689782706000
    },
    {
        "time": 1689782708000,
        "value": 139.796,
        "_internal_originalTime": 1689782708000
    },
    {
        "time": 1689782709000,
        "value": 139.794,
        "_internal_originalTime": 1689782709000
    },
    {
        "time": 1689782710000,
        "value": 139.795,
        "_internal_originalTime": 1689782710000
    },
    {
        "time": 1689782711000,
        "value": 139.795,
        "_internal_originalTime": 1689782711000
    },
    {
        "time": 1689782712000,
        "value": 139.794,
        "_internal_originalTime": 1689782712000
    },
    {
        "time": 1689782713000,
        "value": 139.79,
        "_internal_originalTime": 1689782713000
    },
    {
        "time": 1689782715000,
        "value": 139.783,
        "_internal_originalTime": 1689782715000
    },
    {
        "time": 1689782716000,
        "value": 139.784,
        "_internal_originalTime": 1689782716000
    },
    {
        "time": 1689782717000,
        "value": 139.786,
        "_internal_originalTime": 1689782717000
    },
    {
        "time": 1689782719000,
        "value": 139.782,
        "_internal_originalTime": 1689782719000
    },
    {
        "time": 1689782720000,
        "value": 139.783,
        "_internal_originalTime": 1689782720000
    },
    {
        "time": 1689782722000,
        "value": 139.784,
        "_internal_originalTime": 1689782722000
    },
    {
        "time": 1689782723000,
        "value": 139.781,
        "_internal_originalTime": 1689782723000
    },
    {
        "time": 1689782724000,
        "value": 139.785,
        "_internal_originalTime": 1689782724000
    },
    {
        "time": 1689782726000,
        "value": 139.784,
        "_internal_originalTime": 1689782726000
    },
    {
        "time": 1689782727000,
        "value": 139.778,
        "_internal_originalTime": 1689782727000
    },
    {
        "time": 1689782728000,
        "value": 139.774,
        "_internal_originalTime": 1689782728000
    },
    {
        "time": 1689782729000,
        "value": 139.772,
        "_internal_originalTime": 1689782729000
    },
    {
        "time": 1689782731000,
        "value": 139.774,
        "_internal_originalTime": 1689782731000
    },
    {
        "time": 1689782732000,
        "value": 139.777,
        "_internal_originalTime": 1689782732000
    },
    {
        "time": 1689782733000,
        "value": 139.778,
        "_internal_originalTime": 1689782733000
    },
    {
        "time": 1689782734000,
        "value": 139.784,
        "_internal_originalTime": 1689782734000
    },
    {
        "time": 1689782735000,
        "value": 139.783,
        "_internal_originalTime": 1689782735000
    },
    {
        "time": 1689782737000,
        "value": 139.784,
        "_internal_originalTime": 1689782737000
    },
    {
        "time": 1689782738000,
        "value": 139.783,
        "_internal_originalTime": 1689782738000
    },
    {
        "time": 1689782739000,
        "value": 139.783,
        "_internal_originalTime": 1689782739000
    },
    {
        "time": 1689782741000,
        "value": 139.784,
        "_internal_originalTime": 1689782741000
    },
    {
        "time": 1689782742000,
        "value": 139.786,
        "_internal_originalTime": 1689782742000
    },
    {
        "time": 1689782743000,
        "value": 139.783,
        "_internal_originalTime": 1689782743000
    },
    {
        "time": 1689782744000,
        "value": 139.777,
        "_internal_originalTime": 1689782744000
    },
    {
        "time": 1689782747000,
        "value": 139.784,
        "_internal_originalTime": 1689782747000
    },
    {
        "time": 1689782748000,
        "value": 139.789,
        "_internal_originalTime": 1689782748000
    },
    {
        "time": 1689782749000,
        "value": 139.788,
        "_internal_originalTime": 1689782749000
    },
    {
        "time": 1689782750000,
        "value": 139.789,
        "_internal_originalTime": 1689782750000
    },
    {
        "time": 1689782752000,
        "value": 139.785,
        "_internal_originalTime": 1689782752000
    },
    {
        "time": 1689782754000,
        "value": 139.785,
        "_internal_originalTime": 1689782754000
    },
    {
        "time": 1689782755000,
        "value": 139.792,
        "_internal_originalTime": 1689782755000
    },
    {
        "time": 1689782757000,
        "value": 139.783,
        "_internal_originalTime": 1689782757000
    },
    {
        "time": 1689782759000,
        "value": 139.785,
        "_internal_originalTime": 1689782759000
    },
    {
        "time": 1689782760000,
        "value": 139.783,
        "_internal_originalTime": 1689782760000
    },
    {
        "time": 1689782761000,
        "value": 139.783,
        "_internal_originalTime": 1689782761000
    },
    {
        "time": 1689782762000,
        "value": 139.783,
        "_internal_originalTime": 1689782762000
    },
    {
        "time": 1689782763000,
        "value": 139.783,
        "_internal_originalTime": 1689782763000
    },
    {
        "time": 1689782765000,
        "value": 139.782,
        "_internal_originalTime": 1689782765000
    },
    {
        "time": 1689782766000,
        "value": 139.784,
        "_internal_originalTime": 1689782766000
    },
    {
        "time": 1689782768000,
        "value": 139.784,
        "_internal_originalTime": 1689782768000
    },
    {
        "time": 1689782769000,
        "value": 139.782,
        "_internal_originalTime": 1689782769000
    },
    {
        "time": 1689782771000,
        "value": 139.783,
        "_internal_originalTime": 1689782771000
    },
    {
        "time": 1689782772000,
        "value": 139.777,
        "_internal_originalTime": 1689782772000
    },
    {
        "time": 1689782773000,
        "value": 139.776,
        "_internal_originalTime": 1689782773000
    },
    {
        "time": 1689782774000,
        "value": 139.77,
        "_internal_originalTime": 1689782774000
    },
    {
        "time": 1689782775000,
        "value": 139.772,
        "_internal_originalTime": 1689782775000
    },
    {
        "time": 1689782779000,
        "value": 139.775,
        "_internal_originalTime": 1689782779000
    },
    {
        "time": 1689782782000,
        "value": 139.774,
        "_internal_originalTime": 1689782782000
    },
    {
        "time": 1689782784000,
        "value": 139.772,
        "_internal_originalTime": 1689782784000
    },
    {
        "time": 1689782785000,
        "value": 139.774,
        "_internal_originalTime": 1689782785000
    },
    {
        "time": 1689782787000,
        "value": 139.775,
        "_internal_originalTime": 1689782787000
    },
    {
        "time": 1689782790000,
        "value": 139.774,
        "_internal_originalTime": 1689782790000
    },
    {
        "time": 1689782791000,
        "value": 139.78,
        "_internal_originalTime": 1689782791000
    },
    {
        "time": 1689782792000,
        "value": 139.781,
        "_internal_originalTime": 1689782792000
    },
    {
        "time": 1689782793000,
        "value": 139.783,
        "_internal_originalTime": 1689782793000
    },
    {
        "time": 1689782795000,
        "value": 139.786,
        "_internal_originalTime": 1689782795000
    },
    {
        "time": 1689782796000,
        "value": 139.785,
        "_internal_originalTime": 1689782796000
    },
    {
        "time": 1689782797000,
        "value": 139.781,
        "_internal_originalTime": 1689782797000
    },
    {
        "time": 1689782798000,
        "value": 139.783,
        "_internal_originalTime": 1689782798000
    },
    {
        "time": 1689782799000,
        "value": 139.78,
        "_internal_originalTime": 1689782799000
    },
    {
        "time": 1689782800000,
        "value": 139.782,
        "_internal_originalTime": 1689782800000
    },
    {
        "time": 1689782802000,
        "value": 139.784,
        "_internal_originalTime": 1689782802000
    },
    {
        "time": 1689782805000,
        "value": 139.786,
        "_internal_originalTime": 1689782805000
    },
    {
        "time": 1689782807000,
        "value": 139.784,
        "_internal_originalTime": 1689782807000
    },
    {
        "time": 1689782812000,
        "value": 139.786,
        "_internal_originalTime": 1689782812000
    },
    {
        "time": 1689782813000,
        "value": 139.781,
        "_internal_originalTime": 1689782813000
    },
    {
        "time": 1689782816000,
        "value": 139.78,
        "_internal_originalTime": 1689782816000
    },
    {
        "time": 1689782817000,
        "value": 139.784,
        "_internal_originalTime": 1689782817000
    },
    {
        "time": 1689782818000,
        "value": 139.781,
        "_internal_originalTime": 1689782818000
    },
    {
        "time": 1689782820000,
        "value": 139.78,
        "_internal_originalTime": 1689782820000
    },
    {
        "time": 1689782824000,
        "value": 139.784,
        "_internal_originalTime": 1689782824000
    },
    {
        "time": 1689782826000,
        "value": 139.78,
        "_internal_originalTime": 1689782826000
    },
    {
        "time": 1689782827000,
        "value": 139.779,
        "_internal_originalTime": 1689782827000
    },
    {
        "time": 1689782828000,
        "value": 139.776,
        "_internal_originalTime": 1689782828000
    },
    {
        "time": 1689782829000,
        "value": 139.78,
        "_internal_originalTime": 1689782829000
    },
    {
        "time": 1689782830000,
        "value": 139.782,
        "_internal_originalTime": 1689782830000
    },
    {
        "time": 1689782832000,
        "value": 139.777,
        "_internal_originalTime": 1689782832000
    },
    {
        "time": 1689782834000,
        "value": 139.774,
        "_internal_originalTime": 1689782834000
    },
    {
        "time": 1689782835000,
        "value": 139.776,
        "_internal_originalTime": 1689782835000
    },
    {
        "time": 1689782836000,
        "value": 139.78,
        "_internal_originalTime": 1689782836000
    },
    {
        "time": 1689782838000,
        "value": 139.779,
        "_internal_originalTime": 1689782838000
    },
    {
        "time": 1689782839000,
        "value": 139.775,
        "_internal_originalTime": 1689782839000
    },
    {
        "time": 1689782840000,
        "value": 139.774,
        "_internal_originalTime": 1689782840000
    },
    {
        "time": 1689782841000,
        "value": 139.774,
        "_internal_originalTime": 1689782841000
    },
    {
        "time": 1689782842000,
        "value": 139.775,
        "_internal_originalTime": 1689782842000
    },
    {
        "time": 1689782844000,
        "value": 139.774,
        "_internal_originalTime": 1689782844000
    },
    {
        "time": 1689782847000,
        "value": 139.762,
        "_internal_originalTime": 1689782847000
    },
    {
        "time": 1689782848000,
        "value": 139.765,
        "_internal_originalTime": 1689782848000
    },
    {
        "time": 1689782850000,
        "value": 139.762,
        "_internal_originalTime": 1689782850000
    },
    {
        "time": 1689782851000,
        "value": 139.765,
        "_internal_originalTime": 1689782851000
    },
    {
        "time": 1689782854000,
        "value": 139.762,
        "_internal_originalTime": 1689782854000
    },
    {
        "time": 1689782856000,
        "value": 139.766,
        "_internal_originalTime": 1689782856000
    },
    {
        "time": 1689782857000,
        "value": 139.773,
        "_internal_originalTime": 1689782857000
    },
    {
        "time": 1689782858000,
        "value": 139.773,
        "_internal_originalTime": 1689782858000
    },
    {
        "time": 1689782859000,
        "value": 139.77,
        "_internal_originalTime": 1689782859000
    },
    {
        "time": 1689782860000,
        "value": 139.77,
        "_internal_originalTime": 1689782860000
    },
    {
        "time": 1689782861000,
        "value": 139.773,
        "_internal_originalTime": 1689782861000
    },
    {
        "time": 1689782863000,
        "value": 139.77,
        "_internal_originalTime": 1689782863000
    },
    {
        "time": 1689782864000,
        "value": 139.764,
        "_internal_originalTime": 1689782864000
    },
    {
        "time": 1689782865000,
        "value": 139.769,
        "_internal_originalTime": 1689782865000
    },
    {
        "time": 1689782867000,
        "value": 139.763,
        "_internal_originalTime": 1689782867000
    },
    {
        "time": 1689782869000,
        "value": 139.767,
        "_internal_originalTime": 1689782869000
    },
    {
        "time": 1689782870000,
        "value": 139.767,
        "_internal_originalTime": 1689782870000
    },
    {
        "time": 1689782871000,
        "value": 139.768,
        "_internal_originalTime": 1689782871000
    },
    {
        "time": 1689782872000,
        "value": 139.767,
        "_internal_originalTime": 1689782872000
    },
    {
        "time": 1689782874000,
        "value": 139.762,
        "_internal_originalTime": 1689782874000
    },
    {
        "time": 1689782875000,
        "value": 139.765,
        "_internal_originalTime": 1689782875000
    },
    {
        "time": 1689782876000,
        "value": 139.764,
        "_internal_originalTime": 1689782876000
    },
    {
        "time": 1689782877000,
        "value": 139.765,
        "_internal_originalTime": 1689782877000
    },
    {
        "time": 1689782879000,
        "value": 139.768,
        "_internal_originalTime": 1689782879000
    },
    {
        "time": 1689782880000,
        "value": 139.765,
        "_internal_originalTime": 1689782880000
    },
    {
        "time": 1689782881000,
        "value": 139.765,
        "_internal_originalTime": 1689782881000
    },
    {
        "time": 1689782883000,
        "value": 139.767,
        "_internal_originalTime": 1689782883000
    },
    {
        "time": 1689782884000,
        "value": 139.766,
        "_internal_originalTime": 1689782884000
    },
    {
        "time": 1689782885000,
        "value": 139.764,
        "_internal_originalTime": 1689782885000
    },
    {
        "time": 1689782886000,
        "value": 139.764,
        "_internal_originalTime": 1689782886000
    },
    {
        "time": 1689782888000,
        "value": 139.762,
        "_internal_originalTime": 1689782888000
    },
    {
        "time": 1689782889000,
        "value": 139.753,
        "_internal_originalTime": 1689782889000
    },
    {
        "time": 1689782890000,
        "value": 139.754,
        "_internal_originalTime": 1689782890000
    },
    {
        "time": 1689782892000,
        "value": 139.75,
        "_internal_originalTime": 1689782892000
    },
    {
        "time": 1689782894000,
        "value": 139.749,
        "_internal_originalTime": 1689782894000
    },
    {
        "time": 1689782897000,
        "value": 139.754,
        "_internal_originalTime": 1689782897000
    },
    {
        "time": 1689782898000,
        "value": 139.761,
        "_internal_originalTime": 1689782898000
    },
    {
        "time": 1689782899000,
        "value": 139.759,
        "_internal_originalTime": 1689782899000
    },
    {
        "time": 1689782901000,
        "value": 139.759,
        "_internal_originalTime": 1689782901000
    },
    {
        "time": 1689782902000,
        "value": 139.75,
        "_internal_originalTime": 1689782902000
    },
    {
        "time": 1689782904000,
        "value": 139.75,
        "_internal_originalTime": 1689782904000
    },
    {
        "time": 1689782905000,
        "value": 139.751,
        "_internal_originalTime": 1689782905000
    },
    {
        "time": 1689782906000,
        "value": 139.751,
        "_internal_originalTime": 1689782906000
    },
    {
        "time": 1689782907000,
        "value": 139.75,
        "_internal_originalTime": 1689782907000
    },
    {
        "time": 1689782908000,
        "value": 139.751,
        "_internal_originalTime": 1689782908000
    },
    {
        "time": 1689782910000,
        "value": 139.748,
        "_internal_originalTime": 1689782910000
    },
    {
        "time": 1689782911000,
        "value": 139.75,
        "_internal_originalTime": 1689782911000
    },
    {
        "time": 1689782912000,
        "value": 139.75,
        "_internal_originalTime": 1689782912000
    },
    {
        "time": 1689782914000,
        "value": 139.75,
        "_internal_originalTime": 1689782914000
    },
    {
        "time": 1689782915000,
        "value": 139.749,
        "_internal_originalTime": 1689782915000
    },
    {
        "time": 1689782916000,
        "value": 139.746,
        "_internal_originalTime": 1689782916000
    },
    {
        "time": 1689782917000,
        "value": 139.748,
        "_internal_originalTime": 1689782917000
    },
    {
        "time": 1689782918000,
        "value": 139.748,
        "_internal_originalTime": 1689782918000
    },
    {
        "time": 1689782919000,
        "value": 139.749,
        "_internal_originalTime": 1689782919000
    },
    {
        "time": 1689782921000,
        "value": 139.749,
        "_internal_originalTime": 1689782921000
    },
    {
        "time": 1689782922000,
        "value": 139.746,
        "_internal_originalTime": 1689782922000
    },
    {
        "time": 1689782924000,
        "value": 139.741,
        "_internal_originalTime": 1689782924000
    },
    {
        "time": 1689782926000,
        "value": 139.742,
        "_internal_originalTime": 1689782926000
    },
    {
        "time": 1689782927000,
        "value": 139.745,
        "_internal_originalTime": 1689782927000
    },
    {
        "time": 1689782928000,
        "value": 139.746,
        "_internal_originalTime": 1689782928000
    },
    {
        "time": 1689782929000,
        "value": 139.749,
        "_internal_originalTime": 1689782929000
    },
    {
        "time": 1689782930000,
        "value": 139.748,
        "_internal_originalTime": 1689782930000
    },
    {
        "time": 1689782932000,
        "value": 139.755,
        "_internal_originalTime": 1689782932000
    },
    {
        "time": 1689782933000,
        "value": 139.754,
        "_internal_originalTime": 1689782933000
    },
    {
        "time": 1689782934000,
        "value": 139.756,
        "_internal_originalTime": 1689782934000
    },
    {
        "time": 1689782935000,
        "value": 139.759,
        "_internal_originalTime": 1689782935000
    },
    {
        "time": 1689782937000,
        "value": 139.762,
        "_internal_originalTime": 1689782937000
    },
    {
        "time": 1689782939000,
        "value": 139.761,
        "_internal_originalTime": 1689782939000
    },
    {
        "time": 1689782940000,
        "value": 139.762,
        "_internal_originalTime": 1689782940000
    },
    {
        "time": 1689782941000,
        "value": 139.76,
        "_internal_originalTime": 1689782941000
    },
    {
        "time": 1689782942000,
        "value": 139.765,
        "_internal_originalTime": 1689782942000
    },
    {
        "time": 1689782943000,
        "value": 139.764,
        "_internal_originalTime": 1689782943000
    },
    {
        "time": 1689782944000,
        "value": 139.765,
        "_internal_originalTime": 1689782944000
    },
    {
        "time": 1689782945000,
        "value": 139.774,
        "_internal_originalTime": 1689782945000
    },
    {
        "time": 1689782946000,
        "value": 139.77,
        "_internal_originalTime": 1689782946000
    },
    {
        "time": 1689782947000,
        "value": 139.771,
        "_internal_originalTime": 1689782947000
    },
    {
        "time": 1689782948000,
        "value": 139.772,
        "_internal_originalTime": 1689782948000
    },
    {
        "time": 1689782949000,
        "value": 139.772,
        "_internal_originalTime": 1689782949000
    },
    {
        "time": 1689782953000,
        "value": 139.775,
        "_internal_originalTime": 1689782953000
    },
    {
        "time": 1689782954000,
        "value": 139.768,
        "_internal_originalTime": 1689782954000
    },
    {
        "time": 1689782955000,
        "value": 139.774,
        "_internal_originalTime": 1689782955000
    },
    {
        "time": 1689782956000,
        "value": 139.772,
        "_internal_originalTime": 1689782956000
    },
    {
        "time": 1689782957000,
        "value": 139.774,
        "_internal_originalTime": 1689782957000
    },
    {
        "time": 1689782959000,
        "value": 139.77,
        "_internal_originalTime": 1689782959000
    },
    {
        "time": 1689782960000,
        "value": 139.767,
        "_internal_originalTime": 1689782960000
    },
    {
        "time": 1689782961000,
        "value": 139.768,
        "_internal_originalTime": 1689782961000
    },
    {
        "time": 1689782962000,
        "value": 139.772,
        "_internal_originalTime": 1689782962000
    },
    {
        "time": 1689782963000,
        "value": 139.77,
        "_internal_originalTime": 1689782963000
    },
    {
        "time": 1689782964000,
        "value": 139.768,
        "_internal_originalTime": 1689782964000
    },
    {
        "time": 1689782965000,
        "value": 139.767,
        "_internal_originalTime": 1689782965000
    },
    {
        "time": 1689782967000,
        "value": 139.768,
        "_internal_originalTime": 1689782967000
    },
    {
        "time": 1689782968000,
        "value": 139.764,
        "_internal_originalTime": 1689782968000
    },
    {
        "time": 1689782971000,
        "value": 139.764,
        "_internal_originalTime": 1689782971000
    },
    {
        "time": 1689782973000,
        "value": 139.76,
        "_internal_originalTime": 1689782973000
    },
    {
        "time": 1689782974000,
        "value": 139.76,
        "_internal_originalTime": 1689782974000
    },
    {
        "time": 1689782975000,
        "value": 139.762,
        "_internal_originalTime": 1689782975000
    },
    {
        "time": 1689782977000,
        "value": 139.762,
        "_internal_originalTime": 1689782977000
    },
    {
        "time": 1689782978000,
        "value": 139.764,
        "_internal_originalTime": 1689782978000
    },
    {
        "time": 1689782979000,
        "value": 139.765,
        "_internal_originalTime": 1689782979000
    },
    {
        "time": 1689782981000,
        "value": 139.76,
        "_internal_originalTime": 1689782981000
    },
    {
        "time": 1689782983000,
        "value": 139.76,
        "_internal_originalTime": 1689782983000
    },
    {
        "time": 1689782985000,
        "value": 139.764,
        "_internal_originalTime": 1689782985000
    },
    {
        "time": 1689782987000,
        "value": 139.753,
        "_internal_originalTime": 1689782987000
    },
    {
        "time": 1689782990000,
        "value": 139.754,
        "_internal_originalTime": 1689782990000
    },
    {
        "time": 1689782991000,
        "value": 139.752,
        "_internal_originalTime": 1689782991000
    },
    {
        "time": 1689782992000,
        "value": 139.754,
        "_internal_originalTime": 1689782992000
    },
    {
        "time": 1689782993000,
        "value": 139.754,
        "_internal_originalTime": 1689782993000
    },
    {
        "time": 1689782994000,
        "value": 139.751,
        "_internal_originalTime": 1689782994000
    },
    {
        "time": 1689782995000,
        "value": 139.755,
        "_internal_originalTime": 1689782995000
    },
    {
        "time": 1689782996000,
        "value": 139.754,
        "_internal_originalTime": 1689782996000
    },
    {
        "time": 1689782999000,
        "value": 139.753,
        "_internal_originalTime": 1689782999000
    },
    {
        "time": 1689783000000,
        "value": 139.752,
        "_internal_originalTime": 1689783000000
    },
    {
        "time": 1689783002000,
        "value": 139.749,
        "_internal_originalTime": 1689783002000
    },
    {
        "time": 1689783004000,
        "value": 139.748,
        "_internal_originalTime": 1689783004000
    },
    {
        "time": 1689783005000,
        "value": 139.75,
        "_internal_originalTime": 1689783005000
    },
    {
        "time": 1689783006000,
        "value": 139.752,
        "_internal_originalTime": 1689783006000
    },
    {
        "time": 1689783007000,
        "value": 139.752,
        "_internal_originalTime": 1689783007000
    },
    {
        "time": 1689783008000,
        "value": 139.752,
        "_internal_originalTime": 1689783008000
    },
    {
        "time": 1689783011000,
        "value": 139.753,
        "_internal_originalTime": 1689783011000
    },
    {
        "time": 1689783014000,
        "value": 139.753,
        "_internal_originalTime": 1689783014000
    },
    {
        "time": 1689783015000,
        "value": 139.757,
        "_internal_originalTime": 1689783015000
    },
    {
        "time": 1689783016000,
        "value": 139.757,
        "_internal_originalTime": 1689783016000
    },
    {
        "time": 1689783017000,
        "value": 139.757,
        "_internal_originalTime": 1689783017000
    },
    {
        "time": 1689783018000,
        "value": 139.759,
        "_internal_originalTime": 1689783018000
    },
    {
        "time": 1689783019000,
        "value": 139.759,
        "_internal_originalTime": 1689783019000
    },
    {
        "time": 1689783020000,
        "value": 139.756,
        "_internal_originalTime": 1689783020000
    },
    {
        "time": 1689783022000,
        "value": 139.763,
        "_internal_originalTime": 1689783022000
    },
    {
        "time": 1689783023000,
        "value": 139.768,
        "_internal_originalTime": 1689783023000
    },
    {
        "time": 1689783024000,
        "value": 139.77,
        "_internal_originalTime": 1689783024000
    },
    {
        "time": 1689783027000,
        "value": 139.768,
        "_internal_originalTime": 1689783027000
    },
    {
        "time": 1689783028000,
        "value": 139.77,
        "_internal_originalTime": 1689783028000
    },
    {
        "time": 1689783029000,
        "value": 139.766,
        "_internal_originalTime": 1689783029000
    },
    {
        "time": 1689783030000,
        "value": 139.768,
        "_internal_originalTime": 1689783030000
    },
    {
        "time": 1689783033000,
        "value": 139.768,
        "_internal_originalTime": 1689783033000
    },
    {
        "time": 1689783038000,
        "value": 139.766,
        "_internal_originalTime": 1689783038000
    },
    {
        "time": 1689783041000,
        "value": 139.77,
        "_internal_originalTime": 1689783041000
    },
    {
        "time": 1689783042000,
        "value": 139.773,
        "_internal_originalTime": 1689783042000
    },
    {
        "time": 1689783043000,
        "value": 139.77,
        "_internal_originalTime": 1689783043000
    },
    {
        "time": 1689783044000,
        "value": 139.769,
        "_internal_originalTime": 1689783044000
    },
    {
        "time": 1689783045000,
        "value": 139.772,
        "_internal_originalTime": 1689783045000
    },
    {
        "time": 1689783046000,
        "value": 139.77,
        "_internal_originalTime": 1689783046000
    },
    {
        "time": 1689783048000,
        "value": 139.777,
        "_internal_originalTime": 1689783048000
    },
    {
        "time": 1689783049000,
        "value": 139.776,
        "_internal_originalTime": 1689783049000
    },
    {
        "time": 1689783050000,
        "value": 139.787,
        "_internal_originalTime": 1689783050000
    },
    {
        "time": 1689783051000,
        "value": 139.787,
        "_internal_originalTime": 1689783051000
    },
    {
        "time": 1689783053000,
        "value": 139.785,
        "_internal_originalTime": 1689783053000
    },
    {
        "time": 1689783054000,
        "value": 139.795,
        "_internal_originalTime": 1689783054000
    },
    {
        "time": 1689783055000,
        "value": 139.796,
        "_internal_originalTime": 1689783055000
    },
    {
        "time": 1689783056000,
        "value": 139.796,
        "_internal_originalTime": 1689783056000
    },
    {
        "time": 1689783057000,
        "value": 139.791,
        "_internal_originalTime": 1689783057000
    },
    {
        "time": 1689783059000,
        "value": 139.793,
        "_internal_originalTime": 1689783059000
    },
    {
        "time": 1689783062000,
        "value": 139.791,
        "_internal_originalTime": 1689783062000
    },
    {
        "time": 1689783066000,
        "value": 139.789,
        "_internal_originalTime": 1689783066000
    },
    {
        "time": 1689783067000,
        "value": 139.788,
        "_internal_originalTime": 1689783067000
    },
    {
        "time": 1689783068000,
        "value": 139.787,
        "_internal_originalTime": 1689783068000
    },
    {
        "time": 1689783070000,
        "value": 139.784,
        "_internal_originalTime": 1689783070000
    },
    {
        "time": 1689783071000,
        "value": 139.776,
        "_internal_originalTime": 1689783071000
    },
    {
        "time": 1689783072000,
        "value": 139.772,
        "_internal_originalTime": 1689783072000
    },
    {
        "time": 1689783074000,
        "value": 139.77,
        "_internal_originalTime": 1689783074000
    },
    {
        "time": 1689783075000,
        "value": 139.771,
        "_internal_originalTime": 1689783075000
    },
    {
        "time": 1689783077000,
        "value": 139.775,
        "_internal_originalTime": 1689783077000
    },
    {
        "time": 1689783080000,
        "value": 139.77,
        "_internal_originalTime": 1689783080000
    },
    {
        "time": 1689783081000,
        "value": 139.775,
        "_internal_originalTime": 1689783081000
    },
    {
        "time": 1689783082000,
        "value": 139.775,
        "_internal_originalTime": 1689783082000
    },
    {
        "time": 1689783083000,
        "value": 139.775,
        "_internal_originalTime": 1689783083000
    },
    {
        "time": 1689783084000,
        "value": 139.776,
        "_internal_originalTime": 1689783084000
    },
    {
        "time": 1689783085000,
        "value": 139.775,
        "_internal_originalTime": 1689783085000
    },
    {
        "time": 1689783087000,
        "value": 139.777,
        "_internal_originalTime": 1689783087000
    },
    {
        "time": 1689783088000,
        "value": 139.774,
        "_internal_originalTime": 1689783088000
    },
    {
        "time": 1689783090000,
        "value": 139.77,
        "_internal_originalTime": 1689783090000
    },
    {
        "time": 1689783093000,
        "value": 139.774,
        "_internal_originalTime": 1689783093000
    },
    {
        "time": 1689783095000,
        "value": 139.77,
        "_internal_originalTime": 1689783095000
    },
    {
        "time": 1689783098000,
        "value": 139.773,
        "_internal_originalTime": 1689783098000
    },
    {
        "time": 1689783101000,
        "value": 139.775,
        "_internal_originalTime": 1689783101000
    },
    {
        "time": 1689783105000,
        "value": 139.776,
        "_internal_originalTime": 1689783105000
    },
    {
        "time": 1689783106000,
        "value": 139.773,
        "_internal_originalTime": 1689783106000
    },
    {
        "time": 1689783109000,
        "value": 139.774,
        "_internal_originalTime": 1689783109000
    },
    {
        "time": 1689783110000,
        "value": 139.773,
        "_internal_originalTime": 1689783110000
    },
    {
        "time": 1689783118000,
        "value": 139.77,
        "_internal_originalTime": 1689783118000
    },
    {
        "time": 1689783119000,
        "value": 139.774,
        "_internal_originalTime": 1689783119000
    },
    {
        "time": 1689783121000,
        "value": 139.774,
        "_internal_originalTime": 1689783121000
    },
    {
        "time": 1689783123000,
        "value": 139.772,
        "_internal_originalTime": 1689783123000
    },
    {
        "time": 1689783124000,
        "value": 139.778,
        "_internal_originalTime": 1689783124000
    },
    {
        "time": 1689783125000,
        "value": 139.774,
        "_internal_originalTime": 1689783125000
    },
    {
        "time": 1689783126000,
        "value": 139.781,
        "_internal_originalTime": 1689783126000
    },
    {
        "time": 1689783127000,
        "value": 139.778,
        "_internal_originalTime": 1689783127000
    },
    {
        "time": 1689783128000,
        "value": 139.78,
        "_internal_originalTime": 1689783128000
    },
    {
        "time": 1689783129000,
        "value": 139.781,
        "_internal_originalTime": 1689783129000
    },
    {
        "time": 1689783131000,
        "value": 139.778,
        "_internal_originalTime": 1689783131000
    },
    {
        "time": 1689783132000,
        "value": 139.787,
        "_internal_originalTime": 1689783132000
    },
    {
        "time": 1689783133000,
        "value": 139.791,
        "_internal_originalTime": 1689783133000
    },
    {
        "time": 1689783134000,
        "value": 139.792,
        "_internal_originalTime": 1689783134000
    },
    {
        "time": 1689783135000,
        "value": 139.791,
        "_internal_originalTime": 1689783135000
    },
    {
        "time": 1689783136000,
        "value": 139.791,
        "_internal_originalTime": 1689783136000
    },
    {
        "time": 1689783138000,
        "value": 139.796,
        "_internal_originalTime": 1689783138000
    },
    {
        "time": 1689783139000,
        "value": 139.795,
        "_internal_originalTime": 1689783139000
    },
    {
        "time": 1689783143000,
        "value": 139.796,
        "_internal_originalTime": 1689783143000
    },
    {
        "time": 1689783144000,
        "value": 139.796,
        "_internal_originalTime": 1689783144000
    },
    {
        "time": 1689783146000,
        "value": 139.792,
        "_internal_originalTime": 1689783146000
    },
    {
        "time": 1689783151000,
        "value": 139.789,
        "_internal_originalTime": 1689783151000
    },
    {
        "time": 1689783152000,
        "value": 139.785,
        "_internal_originalTime": 1689783152000
    },
    {
        "time": 1689783154000,
        "value": 139.782,
        "_internal_originalTime": 1689783154000
    },
    {
        "time": 1689783155000,
        "value": 139.787,
        "_internal_originalTime": 1689783155000
    },
    {
        "time": 1689783157000,
        "value": 139.786,
        "_internal_originalTime": 1689783157000
    },
    {
        "time": 1689783158000,
        "value": 139.784,
        "_internal_originalTime": 1689783158000
    },
    {
        "time": 1689783159000,
        "value": 139.78,
        "_internal_originalTime": 1689783159000
    },
    {
        "time": 1689783160000,
        "value": 139.781,
        "_internal_originalTime": 1689783160000
    },
    {
        "time": 1689783161000,
        "value": 139.785,
        "_internal_originalTime": 1689783161000
    },
    {
        "time": 1689783163000,
        "value": 139.78,
        "_internal_originalTime": 1689783163000
    },
    {
        "time": 1689783165000,
        "value": 139.775,
        "_internal_originalTime": 1689783165000
    },
    {
        "time": 1689783167000,
        "value": 139.775,
        "_internal_originalTime": 1689783167000
    },
    {
        "time": 1689783169000,
        "value": 139.772,
        "_internal_originalTime": 1689783169000
    },
    {
        "time": 1689783174000,
        "value": 139.768,
        "_internal_originalTime": 1689783174000
    },
    {
        "time": 1689783175000,
        "value": 139.768,
        "_internal_originalTime": 1689783175000
    },
    {
        "time": 1689783176000,
        "value": 139.77,
        "_internal_originalTime": 1689783176000
    },
    {
        "time": 1689783182000,
        "value": 139.77,
        "_internal_originalTime": 1689783182000
    },
    {
        "time": 1689783183000,
        "value": 139.768,
        "_internal_originalTime": 1689783183000
    },
    {
        "time": 1689783184000,
        "value": 139.768,
        "_internal_originalTime": 1689783184000
    },
    {
        "time": 1689783185000,
        "value": 139.768,
        "_internal_originalTime": 1689783185000
    },
    {
        "time": 1689783187000,
        "value": 139.771,
        "_internal_originalTime": 1689783187000
    },
    {
        "time": 1689783188000,
        "value": 139.769,
        "_internal_originalTime": 1689783188000
    },
    {
        "time": 1689783190000,
        "value": 139.767,
        "_internal_originalTime": 1689783190000
    },
    {
        "time": 1689783191000,
        "value": 139.767,
        "_internal_originalTime": 1689783191000
    },
    {
        "time": 1689783192000,
        "value": 139.767,
        "_internal_originalTime": 1689783192000
    },
    {
        "time": 1689783194000,
        "value": 139.765,
        "_internal_originalTime": 1689783194000
    },
    {
        "time": 1689783195000,
        "value": 139.766,
        "_internal_originalTime": 1689783195000
    },
    {
        "time": 1689783196000,
        "value": 139.766,
        "_internal_originalTime": 1689783196000
    },
    {
        "time": 1689783198000,
        "value": 139.77,
        "_internal_originalTime": 1689783198000
    },
    {
        "time": 1689783199000,
        "value": 139.762,
        "_internal_originalTime": 1689783199000
    },
    {
        "time": 1689783201000,
        "value": 139.766,
        "_internal_originalTime": 1689783201000
    },
    {
        "time": 1689783203000,
        "value": 139.768,
        "_internal_originalTime": 1689783203000
    },
    {
        "time": 1689783204000,
        "value": 139.762,
        "_internal_originalTime": 1689783204000
    },
    {
        "time": 1689783207000,
        "value": 139.766,
        "_internal_originalTime": 1689783207000
    },
    {
        "time": 1689783209000,
        "value": 139.767,
        "_internal_originalTime": 1689783209000
    },
    {
        "time": 1689783210000,
        "value": 139.767,
        "_internal_originalTime": 1689783210000
    },
    {
        "time": 1689783212000,
        "value": 139.767,
        "_internal_originalTime": 1689783212000
    },
    {
        "time": 1689783213000,
        "value": 139.766,
        "_internal_originalTime": 1689783213000
    },
    {
        "time": 1689783214000,
        "value": 139.766,
        "_internal_originalTime": 1689783214000
    },
    {
        "time": 1689783215000,
        "value": 139.765,
        "_internal_originalTime": 1689783215000
    },
    {
        "time": 1689783216000,
        "value": 139.766,
        "_internal_originalTime": 1689783216000
    },
    {
        "time": 1689783218000,
        "value": 139.764,
        "_internal_originalTime": 1689783218000
    },
    {
        "time": 1689783220000,
        "value": 139.766,
        "_internal_originalTime": 1689783220000
    },
    {
        "time": 1689783221000,
        "value": 139.765,
        "_internal_originalTime": 1689783221000
    },
    {
        "time": 1689783222000,
        "value": 139.764,
        "_internal_originalTime": 1689783222000
    },
    {
        "time": 1689783224000,
        "value": 139.76,
        "_internal_originalTime": 1689783224000
    },
    {
        "time": 1689783225000,
        "value": 139.764,
        "_internal_originalTime": 1689783225000
    },
    {
        "time": 1689783226000,
        "value": 139.764,
        "_internal_originalTime": 1689783226000
    },
    {
        "time": 1689783227000,
        "value": 139.765,
        "_internal_originalTime": 1689783227000
    },
    {
        "time": 1689783228000,
        "value": 139.761,
        "_internal_originalTime": 1689783228000
    },
    {
        "time": 1689783229000,
        "value": 139.755,
        "_internal_originalTime": 1689783229000
    },
    {
        "time": 1689783230000,
        "value": 139.754,
        "_internal_originalTime": 1689783230000
    },
    {
        "time": 1689783232000,
        "value": 139.748,
        "_internal_originalTime": 1689783232000
    },
    {
        "time": 1689783233000,
        "value": 139.747,
        "_internal_originalTime": 1689783233000
    },
    {
        "time": 1689783235000,
        "value": 139.746,
        "_internal_originalTime": 1689783235000
    },
    {
        "time": 1689783236000,
        "value": 139.746,
        "_internal_originalTime": 1689783236000
    },
    {
        "time": 1689783238000,
        "value": 139.754,
        "_internal_originalTime": 1689783238000
    },
    {
        "time": 1689783239000,
        "value": 139.75,
        "_internal_originalTime": 1689783239000
    },
    {
        "time": 1689783240000,
        "value": 139.755,
        "_internal_originalTime": 1689783240000
    },
    {
        "time": 1689783242000,
        "value": 139.754,
        "_internal_originalTime": 1689783242000
    },
    {
        "time": 1689783244000,
        "value": 139.755,
        "_internal_originalTime": 1689783244000
    },
    {
        "time": 1689783245000,
        "value": 139.755,
        "_internal_originalTime": 1689783245000
    },
    {
        "time": 1689783246000,
        "value": 139.755,
        "_internal_originalTime": 1689783246000
    },
    {
        "time": 1689783247000,
        "value": 139.756,
        "_internal_originalTime": 1689783247000
    },
    {
        "time": 1689783248000,
        "value": 139.756,
        "_internal_originalTime": 1689783248000
    },
    {
        "time": 1689783250000,
        "value": 139.755,
        "_internal_originalTime": 1689783250000
    },
    {
        "time": 1689783251000,
        "value": 139.754,
        "_internal_originalTime": 1689783251000
    },
    {
        "time": 1689783253000,
        "value": 139.744,
        "_internal_originalTime": 1689783253000
    },
    {
        "time": 1689783254000,
        "value": 139.749,
        "_internal_originalTime": 1689783254000
    },
    {
        "time": 1689783256000,
        "value": 139.748,
        "_internal_originalTime": 1689783256000
    },
    {
        "time": 1689783257000,
        "value": 139.747,
        "_internal_originalTime": 1689783257000
    },
    {
        "time": 1689783258000,
        "value": 139.748,
        "_internal_originalTime": 1689783258000
    },
    {
        "time": 1689783259000,
        "value": 139.743,
        "_internal_originalTime": 1689783259000
    },
    {
        "time": 1689783260000,
        "value": 139.748,
        "_internal_originalTime": 1689783260000
    },
    {
        "time": 1689783263000,
        "value": 139.742,
        "_internal_originalTime": 1689783263000
    },
    {
        "time": 1689783266000,
        "value": 139.748,
        "_internal_originalTime": 1689783266000
    },
    {
        "time": 1689783268000,
        "value": 139.748,
        "_internal_originalTime": 1689783268000
    },
    {
        "time": 1689783269000,
        "value": 139.748,
        "_internal_originalTime": 1689783269000
    },
    {
        "time": 1689783271000,
        "value": 139.742,
        "_internal_originalTime": 1689783271000
    },
    {
        "time": 1689783278000,
        "value": 139.745,
        "_internal_originalTime": 1689783278000
    },
    {
        "time": 1689783281000,
        "value": 139.752,
        "_internal_originalTime": 1689783281000
    },
    {
        "time": 1689783284000,
        "value": 139.748,
        "_internal_originalTime": 1689783284000
    },
    {
        "time": 1689783285000,
        "value": 139.753,
        "_internal_originalTime": 1689783285000
    },
    {
        "time": 1689783286000,
        "value": 139.748,
        "_internal_originalTime": 1689783286000
    },
    {
        "time": 1689783287000,
        "value": 139.738,
        "_internal_originalTime": 1689783287000
    },
    {
        "time": 1689783288000,
        "value": 139.739,
        "_internal_originalTime": 1689783288000
    },
    {
        "time": 1689783289000,
        "value": 139.741,
        "_internal_originalTime": 1689783289000
    },
    {
        "time": 1689783291000,
        "value": 139.752,
        "_internal_originalTime": 1689783291000
    },
    {
        "time": 1689783292000,
        "value": 139.75,
        "_internal_originalTime": 1689783292000
    },
    {
        "time": 1689783293000,
        "value": 139.752,
        "_internal_originalTime": 1689783293000
    },
    {
        "time": 1689783294000,
        "value": 139.749,
        "_internal_originalTime": 1689783294000
    },
    {
        "time": 1689783295000,
        "value": 139.75,
        "_internal_originalTime": 1689783295000
    },
    {
        "time": 1689783296000,
        "value": 139.747,
        "_internal_originalTime": 1689783296000
    },
    {
        "time": 1689783297000,
        "value": 139.744,
        "_internal_originalTime": 1689783297000
    },
    {
        "time": 1689783300000,
        "value": 139.742,
        "_internal_originalTime": 1689783300000
    },
    {
        "time": 1689783302000,
        "value": 139.746,
        "_internal_originalTime": 1689783302000
    },
    {
        "time": 1689783304000,
        "value": 139.744,
        "_internal_originalTime": 1689783304000
    },
    {
        "time": 1689783306000,
        "value": 139.744,
        "_internal_originalTime": 1689783306000
    },
    {
        "time": 1689783308000,
        "value": 139.743,
        "_internal_originalTime": 1689783308000
    },
    {
        "time": 1689783309000,
        "value": 139.745,
        "_internal_originalTime": 1689783309000
    },
    {
        "time": 1689783310000,
        "value": 139.745,
        "_internal_originalTime": 1689783310000
    },
    {
        "time": 1689783311000,
        "value": 139.743,
        "_internal_originalTime": 1689783311000
    },
    {
        "time": 1689783312000,
        "value": 139.74,
        "_internal_originalTime": 1689783312000
    },
    {
        "time": 1689783314000,
        "value": 139.736,
        "_internal_originalTime": 1689783314000
    },
    {
        "time": 1689783315000,
        "value": 139.744,
        "_internal_originalTime": 1689783315000
    },
    {
        "time": 1689783316000,
        "value": 139.745,
        "_internal_originalTime": 1689783316000
    },
    {
        "time": 1689783318000,
        "value": 139.741,
        "_internal_originalTime": 1689783318000
    },
    {
        "time": 1689783319000,
        "value": 139.747,
        "_internal_originalTime": 1689783319000
    },
    {
        "time": 1689783320000,
        "value": 139.746,
        "_internal_originalTime": 1689783320000
    },
    {
        "time": 1689783326000,
        "value": 139.746,
        "_internal_originalTime": 1689783326000
    },
    {
        "time": 1689783327000,
        "value": 139.742,
        "_internal_originalTime": 1689783327000
    },
    {
        "time": 1689783329000,
        "value": 139.751,
        "_internal_originalTime": 1689783329000
    },
    {
        "time": 1689783330000,
        "value": 139.747,
        "_internal_originalTime": 1689783330000
    },
    {
        "time": 1689783333000,
        "value": 139.749,
        "_internal_originalTime": 1689783333000
    },
    {
        "time": 1689783335000,
        "value": 139.752,
        "_internal_originalTime": 1689783335000
    },
    {
        "time": 1689783337000,
        "value": 139.758,
        "_internal_originalTime": 1689783337000
    },
    {
        "time": 1689783338000,
        "value": 139.76,
        "_internal_originalTime": 1689783338000
    },
    {
        "time": 1689783339000,
        "value": 139.763,
        "_internal_originalTime": 1689783339000
    },
    {
        "time": 1689783342000,
        "value": 139.767,
        "_internal_originalTime": 1689783342000
    },
    {
        "time": 1689783343000,
        "value": 139.76,
        "_internal_originalTime": 1689783343000
    },
    {
        "time": 1689783344000,
        "value": 139.759,
        "_internal_originalTime": 1689783344000
    },
    {
        "time": 1689783346000,
        "value": 139.766,
        "_internal_originalTime": 1689783346000
    },
    {
        "time": 1689783347000,
        "value": 139.76,
        "_internal_originalTime": 1689783347000
    },
    {
        "time": 1689783348000,
        "value": 139.766,
        "_internal_originalTime": 1689783348000
    },
    {
        "time": 1689783349000,
        "value": 139.767,
        "_internal_originalTime": 1689783349000
    },
    {
        "time": 1689783357000,
        "value": 139.766,
        "_internal_originalTime": 1689783357000
    },
    {
        "time": 1689783358000,
        "value": 139.767,
        "_internal_originalTime": 1689783358000
    },
    {
        "time": 1689783359000,
        "value": 139.761,
        "_internal_originalTime": 1689783359000
    },
    {
        "time": 1689783360000,
        "value": 139.766,
        "_internal_originalTime": 1689783360000
    },
    {
        "time": 1689783366000,
        "value": 139.761,
        "_internal_originalTime": 1689783366000
    },
    {
        "time": 1689783367000,
        "value": 139.762,
        "_internal_originalTime": 1689783367000
    },
    {
        "time": 1689783368000,
        "value": 139.762,
        "_internal_originalTime": 1689783368000
    },
    {
        "time": 1689783370000,
        "value": 139.762,
        "_internal_originalTime": 1689783370000
    },
    {
        "time": 1689783374000,
        "value": 139.76,
        "_internal_originalTime": 1689783374000
    },
    {
        "time": 1689783375000,
        "value": 139.766,
        "_internal_originalTime": 1689783375000
    },
    {
        "time": 1689783376000,
        "value": 139.764,
        "_internal_originalTime": 1689783376000
    },
    {
        "time": 1689783377000,
        "value": 139.766,
        "_internal_originalTime": 1689783377000
    },
    {
        "time": 1689783378000,
        "value": 139.765,
        "_internal_originalTime": 1689783378000
    },
    {
        "time": 1689783379000,
        "value": 139.764,
        "_internal_originalTime": 1689783379000
    },
    {
        "time": 1689783380000,
        "value": 139.768,
        "_internal_originalTime": 1689783380000
    },
    {
        "time": 1689783382000,
        "value": 139.77,
        "_internal_originalTime": 1689783382000
    },
    {
        "time": 1689783383000,
        "value": 139.77,
        "_internal_originalTime": 1689783383000
    },
    {
        "time": 1689783384000,
        "value": 139.772,
        "_internal_originalTime": 1689783384000
    },
    {
        "time": 1689783385000,
        "value": 139.77,
        "_internal_originalTime": 1689783385000
    },
    {
        "time": 1689783386000,
        "value": 139.767,
        "_internal_originalTime": 1689783386000
    },
    {
        "time": 1689783387000,
        "value": 139.771,
        "_internal_originalTime": 1689783387000
    },
    {
        "time": 1689783388000,
        "value": 139.772,
        "_internal_originalTime": 1689783388000
    },
    {
        "time": 1689783389000,
        "value": 139.771,
        "_internal_originalTime": 1689783389000
    },
    {
        "time": 1689783390000,
        "value": 139.772,
        "_internal_originalTime": 1689783390000
    },
    {
        "time": 1689783396000,
        "value": 139.771,
        "_internal_originalTime": 1689783396000
    },
    {
        "time": 1689783398000,
        "value": 139.769,
        "_internal_originalTime": 1689783398000
    },
    {
        "time": 1689783401000,
        "value": 139.768,
        "_internal_originalTime": 1689783401000
    },
    {
        "time": 1689783403000,
        "value": 139.768,
        "_internal_originalTime": 1689783403000
    },
    {
        "time": 1689783404000,
        "value": 139.767,
        "_internal_originalTime": 1689783404000
    },
    {
        "time": 1689783405000,
        "value": 139.772,
        "_internal_originalTime": 1689783405000
    },
    {
        "time": 1689783407000,
        "value": 139.772,
        "_internal_originalTime": 1689783407000
    },
    {
        "time": 1689783408000,
        "value": 139.768,
        "_internal_originalTime": 1689783408000
    },
    {
        "time": 1689783410000,
        "value": 139.775,
        "_internal_originalTime": 1689783410000
    },
    {
        "time": 1689783412000,
        "value": 139.78,
        "_internal_originalTime": 1689783412000
    },
    {
        "time": 1689783416000,
        "value": 139.777,
        "_internal_originalTime": 1689783416000
    },
    {
        "time": 1689783417000,
        "value": 139.776,
        "_internal_originalTime": 1689783417000
    },
    {
        "time": 1689783419000,
        "value": 139.768,
        "_internal_originalTime": 1689783419000
    },
    {
        "time": 1689783420000,
        "value": 139.767,
        "_internal_originalTime": 1689783420000
    },
    {
        "time": 1689783421000,
        "value": 139.765,
        "_internal_originalTime": 1689783421000
    },
    {
        "time": 1689783423000,
        "value": 139.767,
        "_internal_originalTime": 1689783423000
    },
    {
        "time": 1689783424000,
        "value": 139.769,
        "_internal_originalTime": 1689783424000
    },
    {
        "time": 1689783425000,
        "value": 139.76,
        "_internal_originalTime": 1689783425000
    },
    {
        "time": 1689783426000,
        "value": 139.766,
        "_internal_originalTime": 1689783426000
    },
    {
        "time": 1689783427000,
        "value": 139.767,
        "_internal_originalTime": 1689783427000
    },
    {
        "time": 1689783428000,
        "value": 139.767,
        "_internal_originalTime": 1689783428000
    },
    {
        "time": 1689783429000,
        "value": 139.755,
        "_internal_originalTime": 1689783429000
    },
    {
        "time": 1689783431000,
        "value": 139.759,
        "_internal_originalTime": 1689783431000
    },
    {
        "time": 1689783432000,
        "value": 139.755,
        "_internal_originalTime": 1689783432000
    },
    {
        "time": 1689783433000,
        "value": 139.757,
        "_internal_originalTime": 1689783433000
    },
    {
        "time": 1689783435000,
        "value": 139.749,
        "_internal_originalTime": 1689783435000
    },
    {
        "time": 1689783436000,
        "value": 139.753,
        "_internal_originalTime": 1689783436000
    },
    {
        "time": 1689783440000,
        "value": 139.748,
        "_internal_originalTime": 1689783440000
    },
    {
        "time": 1689783441000,
        "value": 139.755,
        "_internal_originalTime": 1689783441000
    },
    {
        "time": 1689783442000,
        "value": 139.748,
        "_internal_originalTime": 1689783442000
    },
    {
        "time": 1689783444000,
        "value": 139.751,
        "_internal_originalTime": 1689783444000
    },
    {
        "time": 1689783445000,
        "value": 139.736,
        "_internal_originalTime": 1689783445000
    },
    {
        "time": 1689783449000,
        "value": 139.742,
        "_internal_originalTime": 1689783449000
    },
    {
        "time": 1689783450000,
        "value": 139.736,
        "_internal_originalTime": 1689783450000
    },
    {
        "time": 1689783451000,
        "value": 139.736,
        "_internal_originalTime": 1689783451000
    },
    {
        "time": 1689783453000,
        "value": 139.741,
        "_internal_originalTime": 1689783453000
    },
    {
        "time": 1689783454000,
        "value": 139.729,
        "_internal_originalTime": 1689783454000
    },
    {
        "time": 1689783455000,
        "value": 139.728,
        "_internal_originalTime": 1689783455000
    },
    {
        "time": 1689783456000,
        "value": 139.719,
        "_internal_originalTime": 1689783456000
    },
    {
        "time": 1689783457000,
        "value": 139.714,
        "_internal_originalTime": 1689783457000
    },
    {
        "time": 1689783459000,
        "value": 139.722,
        "_internal_originalTime": 1689783459000
    },
    {
        "time": 1689783461000,
        "value": 139.723,
        "_internal_originalTime": 1689783461000
    },
    {
        "time": 1689783462000,
        "value": 139.718,
        "_internal_originalTime": 1689783462000
    },
    {
        "time": 1689783463000,
        "value": 139.726,
        "_internal_originalTime": 1689783463000
    },
    {
        "time": 1689783465000,
        "value": 139.726,
        "_internal_originalTime": 1689783465000
    },
    {
        "time": 1689783466000,
        "value": 139.708,
        "_internal_originalTime": 1689783466000
    },
    {
        "time": 1689783467000,
        "value": 139.716,
        "_internal_originalTime": 1689783467000
    },
    {
        "time": 1689783468000,
        "value": 139.71,
        "_internal_originalTime": 1689783468000
    },
    {
        "time": 1689783469000,
        "value": 139.71,
        "_internal_originalTime": 1689783469000
    },
    {
        "time": 1689783470000,
        "value": 139.71,
        "_internal_originalTime": 1689783470000
    },
    {
        "time": 1689783471000,
        "value": 139.714,
        "_internal_originalTime": 1689783471000
    },
    {
        "time": 1689783472000,
        "value": 139.715,
        "_internal_originalTime": 1689783472000
    },
    {
        "time": 1689783474000,
        "value": 139.702,
        "_internal_originalTime": 1689783474000
    },
    {
        "time": 1689783475000,
        "value": 139.707,
        "_internal_originalTime": 1689783475000
    },
    {
        "time": 1689783476000,
        "value": 139.704,
        "_internal_originalTime": 1689783476000
    },
    {
        "time": 1689783477000,
        "value": 139.71,
        "_internal_originalTime": 1689783477000
    },
    {
        "time": 1689783479000,
        "value": 139.706,
        "_internal_originalTime": 1689783479000
    },
    {
        "time": 1689783480000,
        "value": 139.694,
        "_internal_originalTime": 1689783480000
    },
    {
        "time": 1689783481000,
        "value": 139.696,
        "_internal_originalTime": 1689783481000
    },
    {
        "time": 1689783482000,
        "value": 139.7,
        "_internal_originalTime": 1689783482000
    },
    {
        "time": 1689783484000,
        "value": 139.704,
        "_internal_originalTime": 1689783484000
    },
    {
        "time": 1689783486000,
        "value": 139.701,
        "_internal_originalTime": 1689783486000
    },
    {
        "time": 1689783488000,
        "value": 139.697,
        "_internal_originalTime": 1689783488000
    },
    {
        "time": 1689783489000,
        "value": 139.708,
        "_internal_originalTime": 1689783489000
    },
    {
        "time": 1689783490000,
        "value": 139.715,
        "_internal_originalTime": 1689783490000
    },
    {
        "time": 1689783491000,
        "value": 139.712,
        "_internal_originalTime": 1689783491000
    },
    {
        "time": 1689783492000,
        "value": 139.708,
        "_internal_originalTime": 1689783492000
    },
    {
        "time": 1689783493000,
        "value": 139.708,
        "_internal_originalTime": 1689783493000
    },
    {
        "time": 1689783495000,
        "value": 139.71,
        "_internal_originalTime": 1689783495000
    },
    {
        "time": 1689783496000,
        "value": 139.712,
        "_internal_originalTime": 1689783496000
    },
    {
        "time": 1689783498000,
        "value": 139.714,
        "_internal_originalTime": 1689783498000
    },
    {
        "time": 1689783499000,
        "value": 139.721,
        "_internal_originalTime": 1689783499000
    },
    {
        "time": 1689783500000,
        "value": 139.724,
        "_internal_originalTime": 1689783500000
    },
    {
        "time": 1689783502000,
        "value": 139.725,
        "_internal_originalTime": 1689783502000
    },
    {
        "time": 1689783503000,
        "value": 139.734,
        "_internal_originalTime": 1689783503000
    },
    {
        "time": 1689783504000,
        "value": 139.734,
        "_internal_originalTime": 1689783504000
    },
    {
        "time": 1689783505000,
        "value": 139.736,
        "_internal_originalTime": 1689783505000
    },
    {
        "time": 1689783507000,
        "value": 139.737,
        "_internal_originalTime": 1689783507000
    },
    {
        "time": 1689783508000,
        "value": 139.737,
        "_internal_originalTime": 1689783508000
    },
    {
        "time": 1689783509000,
        "value": 139.732,
        "_internal_originalTime": 1689783509000
    },
    {
        "time": 1689783511000,
        "value": 139.739,
        "_internal_originalTime": 1689783511000
    },
    {
        "time": 1689783512000,
        "value": 139.732,
        "_internal_originalTime": 1689783512000
    },
    {
        "time": 1689783513000,
        "value": 139.738,
        "_internal_originalTime": 1689783513000
    },
    {
        "time": 1689783514000,
        "value": 139.73,
        "_internal_originalTime": 1689783514000
    },
    {
        "time": 1689783515000,
        "value": 139.737,
        "_internal_originalTime": 1689783515000
    },
    {
        "time": 1689783516000,
        "value": 139.737,
        "_internal_originalTime": 1689783516000
    },
    {
        "time": 1689783517000,
        "value": 139.731,
        "_internal_originalTime": 1689783517000
    },
    {
        "time": 1689783518000,
        "value": 139.731,
        "_internal_originalTime": 1689783518000
    },
    {
        "time": 1689783520000,
        "value": 139.732,
        "_internal_originalTime": 1689783520000
    },
    {
        "time": 1689783521000,
        "value": 139.736,
        "_internal_originalTime": 1689783521000
    },
    {
        "time": 1689783523000,
        "value": 139.737,
        "_internal_originalTime": 1689783523000
    },
    {
        "time": 1689783524000,
        "value": 139.73,
        "_internal_originalTime": 1689783524000
    },
    {
        "time": 1689783526000,
        "value": 139.737,
        "_internal_originalTime": 1689783526000
    },
    {
        "time": 1689783527000,
        "value": 139.736,
        "_internal_originalTime": 1689783527000
    },
    {
        "time": 1689783532000,
        "value": 139.734,
        "_internal_originalTime": 1689783532000
    },
    {
        "time": 1689783534000,
        "value": 139.736,
        "_internal_originalTime": 1689783534000
    },
    {
        "time": 1689783535000,
        "value": 139.736,
        "_internal_originalTime": 1689783535000
    },
    {
        "time": 1689783537000,
        "value": 139.732,
        "_internal_originalTime": 1689783537000
    },
    {
        "time": 1689783539000,
        "value": 139.73,
        "_internal_originalTime": 1689783539000
    },
    {
        "time": 1689783541000,
        "value": 139.732,
        "_internal_originalTime": 1689783541000
    },
    {
        "time": 1689783543000,
        "value": 139.736,
        "_internal_originalTime": 1689783543000
    },
    {
        "time": 1689783544000,
        "value": 139.737,
        "_internal_originalTime": 1689783544000
    },
    {
        "time": 1689783545000,
        "value": 139.732,
        "_internal_originalTime": 1689783545000
    },
    {
        "time": 1689783546000,
        "value": 139.732,
        "_internal_originalTime": 1689783546000
    },
    {
        "time": 1689783548000,
        "value": 139.733,
        "_internal_originalTime": 1689783548000
    },
    {
        "time": 1689783551000,
        "value": 139.733,
        "_internal_originalTime": 1689783551000
    },
    {
        "time": 1689783553000,
        "value": 139.734,
        "_internal_originalTime": 1689783553000
    },
    {
        "time": 1689783554000,
        "value": 139.729,
        "_internal_originalTime": 1689783554000
    },
    {
        "time": 1689783556000,
        "value": 139.73,
        "_internal_originalTime": 1689783556000
    },
    {
        "time": 1689783558000,
        "value": 139.73,
        "_internal_originalTime": 1689783558000
    },
    {
        "time": 1689783560000,
        "value": 139.736,
        "_internal_originalTime": 1689783560000
    },
    {
        "time": 1689783561000,
        "value": 139.736,
        "_internal_originalTime": 1689783561000
    },
    {
        "time": 1689783562000,
        "value": 139.732,
        "_internal_originalTime": 1689783562000
    },
    {
        "time": 1689783563000,
        "value": 139.732,
        "_internal_originalTime": 1689783563000
    },
    {
        "time": 1689783565000,
        "value": 139.732,
        "_internal_originalTime": 1689783565000
    },
    {
        "time": 1689783566000,
        "value": 139.732,
        "_internal_originalTime": 1689783566000
    },
    {
        "time": 1689783568000,
        "value": 139.731,
        "_internal_originalTime": 1689783568000
    },
    {
        "time": 1689783569000,
        "value": 139.732,
        "_internal_originalTime": 1689783569000
    },
    {
        "time": 1689783570000,
        "value": 139.736,
        "_internal_originalTime": 1689783570000
    },
    {
        "time": 1689783571000,
        "value": 139.734,
        "_internal_originalTime": 1689783571000
    },
    {
        "time": 1689783572000,
        "value": 139.735,
        "_internal_originalTime": 1689783572000
    },
    {
        "time": 1689783573000,
        "value": 139.73,
        "_internal_originalTime": 1689783573000
    },
    {
        "time": 1689783574000,
        "value": 139.734,
        "_internal_originalTime": 1689783574000
    },
    {
        "time": 1689783576000,
        "value": 139.733,
        "_internal_originalTime": 1689783576000
    },
    {
        "time": 1689783577000,
        "value": 139.724,
        "_internal_originalTime": 1689783577000
    },
    {
        "time": 1689783578000,
        "value": 139.725,
        "_internal_originalTime": 1689783578000
    },
    {
        "time": 1689783580000,
        "value": 139.729,
        "_internal_originalTime": 1689783580000
    },
    {
        "time": 1689783582000,
        "value": 139.734,
        "_internal_originalTime": 1689783582000
    },
    {
        "time": 1689783584000,
        "value": 139.729,
        "_internal_originalTime": 1689783584000
    },
    {
        "time": 1689783586000,
        "value": 139.736,
        "_internal_originalTime": 1689783586000
    },
    {
        "time": 1689783587000,
        "value": 139.751,
        "_internal_originalTime": 1689783587000
    },
    {
        "time": 1689783588000,
        "value": 139.74,
        "_internal_originalTime": 1689783588000
    },
    {
        "time": 1689783589000,
        "value": 139.745,
        "_internal_originalTime": 1689783589000
    },
    {
        "time": 1689783590000,
        "value": 139.752,
        "_internal_originalTime": 1689783590000
    },
    {
        "time": 1689783591000,
        "value": 139.752,
        "_internal_originalTime": 1689783591000
    },
    {
        "time": 1689783593000,
        "value": 139.748,
        "_internal_originalTime": 1689783593000
    },
    {
        "time": 1689783594000,
        "value": 139.74,
        "_internal_originalTime": 1689783594000
    },
    {
        "time": 1689783596000,
        "value": 139.743,
        "_internal_originalTime": 1689783596000
    },
    {
        "time": 1689783597000,
        "value": 139.742,
        "_internal_originalTime": 1689783597000
    },
    {
        "time": 1689783600000,
        "value": 139.743,
        "_internal_originalTime": 1689783600000
    },
    {
        "time": 1689783601000,
        "value": 139.741,
        "_internal_originalTime": 1689783601000
    },
    {
        "time": 1689783602000,
        "value": 139.738,
        "_internal_originalTime": 1689783602000
    },
    {
        "time": 1689783603000,
        "value": 139.735,
        "_internal_originalTime": 1689783603000
    },
    {
        "time": 1689783605000,
        "value": 139.734,
        "_internal_originalTime": 1689783605000
    },
    {
        "time": 1689783606000,
        "value": 139.735,
        "_internal_originalTime": 1689783606000
    },
    {
        "time": 1689783608000,
        "value": 139.737,
        "_internal_originalTime": 1689783608000
    },
    {
        "time": 1689783609000,
        "value": 139.73,
        "_internal_originalTime": 1689783609000
    },
    {
        "time": 1689783610000,
        "value": 139.736,
        "_internal_originalTime": 1689783610000
    },
    {
        "time": 1689783611000,
        "value": 139.736,
        "_internal_originalTime": 1689783611000
    },
    {
        "time": 1689783612000,
        "value": 139.736,
        "_internal_originalTime": 1689783612000
    },
    {
        "time": 1689783613000,
        "value": 139.736,
        "_internal_originalTime": 1689783613000
    },
    {
        "time": 1689783615000,
        "value": 139.732,
        "_internal_originalTime": 1689783615000
    },
    {
        "time": 1689783616000,
        "value": 139.732,
        "_internal_originalTime": 1689783616000
    },
    {
        "time": 1689783617000,
        "value": 139.736,
        "_internal_originalTime": 1689783617000
    },
    {
        "time": 1689783619000,
        "value": 139.736,
        "_internal_originalTime": 1689783619000
    },
    {
        "time": 1689783623000,
        "value": 139.741,
        "_internal_originalTime": 1689783623000
    },
    {
        "time": 1689783624000,
        "value": 139.74,
        "_internal_originalTime": 1689783624000
    },
    {
        "time": 1689783625000,
        "value": 139.74,
        "_internal_originalTime": 1689783625000
    },
    {
        "time": 1689783626000,
        "value": 139.736,
        "_internal_originalTime": 1689783626000
    },
    {
        "time": 1689783630000,
        "value": 139.74,
        "_internal_originalTime": 1689783630000
    },
    {
        "time": 1689783631000,
        "value": 139.744,
        "_internal_originalTime": 1689783631000
    },
    {
        "time": 1689783632000,
        "value": 139.74,
        "_internal_originalTime": 1689783632000
    },
    {
        "time": 1689783633000,
        "value": 139.741,
        "_internal_originalTime": 1689783633000
    },
    {
        "time": 1689783634000,
        "value": 139.74,
        "_internal_originalTime": 1689783634000
    },
    {
        "time": 1689783636000,
        "value": 139.744,
        "_internal_originalTime": 1689783636000
    },
    {
        "time": 1689783638000,
        "value": 139.742,
        "_internal_originalTime": 1689783638000
    },
    {
        "time": 1689783644000,
        "value": 139.745,
        "_internal_originalTime": 1689783644000
    },
    {
        "time": 1689783646000,
        "value": 139.751,
        "_internal_originalTime": 1689783646000
    },
    {
        "time": 1689783647000,
        "value": 139.752,
        "_internal_originalTime": 1689783647000
    },
    {
        "time": 1689783648000,
        "value": 139.748,
        "_internal_originalTime": 1689783648000
    },
    {
        "time": 1689783649000,
        "value": 139.75,
        "_internal_originalTime": 1689783649000
    },
    {
        "time": 1689783650000,
        "value": 139.753,
        "_internal_originalTime": 1689783650000
    },
    {
        "time": 1689783652000,
        "value": 139.75,
        "_internal_originalTime": 1689783652000
    },
    {
        "time": 1689783653000,
        "value": 139.752,
        "_internal_originalTime": 1689783653000
    },
    {
        "time": 1689783656000,
        "value": 139.754,
        "_internal_originalTime": 1689783656000
    },
    {
        "time": 1689783657000,
        "value": 139.75,
        "_internal_originalTime": 1689783657000
    },
    {
        "time": 1689783658000,
        "value": 139.754,
        "_internal_originalTime": 1689783658000
    },
    {
        "time": 1689783659000,
        "value": 139.752,
        "_internal_originalTime": 1689783659000
    },
    {
        "time": 1689783660000,
        "value": 139.75,
        "_internal_originalTime": 1689783660000
    },
    {
        "time": 1689783662000,
        "value": 139.75,
        "_internal_originalTime": 1689783662000
    },
    {
        "time": 1689783663000,
        "value": 139.753,
        "_internal_originalTime": 1689783663000
    },
    {
        "time": 1689783665000,
        "value": 139.75,
        "_internal_originalTime": 1689783665000
    },
    {
        "time": 1689783666000,
        "value": 139.751,
        "_internal_originalTime": 1689783666000
    },
    {
        "time": 1689783668000,
        "value": 139.75,
        "_internal_originalTime": 1689783668000
    },
    {
        "time": 1689783669000,
        "value": 139.748,
        "_internal_originalTime": 1689783669000
    },
    {
        "time": 1689783670000,
        "value": 139.75,
        "_internal_originalTime": 1689783670000
    },
    {
        "time": 1689783673000,
        "value": 139.75,
        "_internal_originalTime": 1689783673000
    },
    {
        "time": 1689783674000,
        "value": 139.747,
        "_internal_originalTime": 1689783674000
    },
    {
        "time": 1689783677000,
        "value": 139.754,
        "_internal_originalTime": 1689783677000
    },
    {
        "time": 1689783679000,
        "value": 139.75,
        "_internal_originalTime": 1689783679000
    },
    {
        "time": 1689783680000,
        "value": 139.75,
        "_internal_originalTime": 1689783680000
    },
    {
        "time": 1689783681000,
        "value": 139.748,
        "_internal_originalTime": 1689783681000
    },
    {
        "time": 1689783684000,
        "value": 139.748,
        "_internal_originalTime": 1689783684000
    },
    {
        "time": 1689783685000,
        "value": 139.748,
        "_internal_originalTime": 1689783685000
    },
    {
        "time": 1689783686000,
        "value": 139.743,
        "_internal_originalTime": 1689783686000
    },
    {
        "time": 1689783687000,
        "value": 139.742,
        "_internal_originalTime": 1689783687000
    },
    {
        "time": 1689783688000,
        "value": 139.747,
        "_internal_originalTime": 1689783688000
    },
    {
        "time": 1689783696000,
        "value": 139.746,
        "_internal_originalTime": 1689783696000
    },
    {
        "time": 1689783697000,
        "value": 139.755,
        "_internal_originalTime": 1689783697000
    },
    {
        "time": 1689783699000,
        "value": 139.76,
        "_internal_originalTime": 1689783699000
    },
    {
        "time": 1689783700000,
        "value": 139.76,
        "_internal_originalTime": 1689783700000
    },
    {
        "time": 1689783701000,
        "value": 139.758,
        "_internal_originalTime": 1689783701000
    },
    {
        "time": 1689783702000,
        "value": 139.758,
        "_internal_originalTime": 1689783702000
    },
    {
        "time": 1689783704000,
        "value": 139.753,
        "_internal_originalTime": 1689783704000
    },
    {
        "time": 1689783705000,
        "value": 139.759,
        "_internal_originalTime": 1689783705000
    },
    {
        "time": 1689783707000,
        "value": 139.758,
        "_internal_originalTime": 1689783707000
    },
    {
        "time": 1689783708000,
        "value": 139.758,
        "_internal_originalTime": 1689783708000
    },
    {
        "time": 1689783709000,
        "value": 139.762,
        "_internal_originalTime": 1689783709000
    },
    {
        "time": 1689783711000,
        "value": 139.758,
        "_internal_originalTime": 1689783711000
    },
    {
        "time": 1689783713000,
        "value": 139.758,
        "_internal_originalTime": 1689783713000
    },
    {
        "time": 1689783716000,
        "value": 139.756,
        "_internal_originalTime": 1689783716000
    },
    {
        "time": 1689783717000,
        "value": 139.754,
        "_internal_originalTime": 1689783717000
    },
    {
        "time": 1689783718000,
        "value": 139.756,
        "_internal_originalTime": 1689783718000
    },
    {
        "time": 1689783720000,
        "value": 139.754,
        "_internal_originalTime": 1689783720000
    },
    {
        "time": 1689783722000,
        "value": 139.738,
        "_internal_originalTime": 1689783722000
    },
    {
        "time": 1689783723000,
        "value": 139.735,
        "_internal_originalTime": 1689783723000
    },
    {
        "time": 1689783724000,
        "value": 139.74,
        "_internal_originalTime": 1689783724000
    },
    {
        "time": 1689783725000,
        "value": 139.732,
        "_internal_originalTime": 1689783725000
    },
    {
        "time": 1689783726000,
        "value": 139.737,
        "_internal_originalTime": 1689783726000
    },
    {
        "time": 1689783727000,
        "value": 139.731,
        "_internal_originalTime": 1689783727000
    },
    {
        "time": 1689783728000,
        "value": 139.738,
        "_internal_originalTime": 1689783728000
    },
    {
        "time": 1689783729000,
        "value": 139.743,
        "_internal_originalTime": 1689783729000
    },
    {
        "time": 1689783731000,
        "value": 139.738,
        "_internal_originalTime": 1689783731000
    },
    {
        "time": 1689783732000,
        "value": 139.738,
        "_internal_originalTime": 1689783732000
    },
    {
        "time": 1689783734000,
        "value": 139.737,
        "_internal_originalTime": 1689783734000
    },
    {
        "time": 1689783736000,
        "value": 139.744,
        "_internal_originalTime": 1689783736000
    },
    {
        "time": 1689783737000,
        "value": 139.743,
        "_internal_originalTime": 1689783737000
    },
    {
        "time": 1689783741000,
        "value": 139.738,
        "_internal_originalTime": 1689783741000
    },
    {
        "time": 1689783742000,
        "value": 139.738,
        "_internal_originalTime": 1689783742000
    },
    {
        "time": 1689783744000,
        "value": 139.738,
        "_internal_originalTime": 1689783744000
    },
    {
        "time": 1689783748000,
        "value": 139.745,
        "_internal_originalTime": 1689783748000
    },
    {
        "time": 1689783749000,
        "value": 139.743,
        "_internal_originalTime": 1689783749000
    },
    {
        "time": 1689783752000,
        "value": 139.741,
        "_internal_originalTime": 1689783752000
    },
    {
        "time": 1689783754000,
        "value": 139.737,
        "_internal_originalTime": 1689783754000
    },
    {
        "time": 1689783758000,
        "value": 139.739,
        "_internal_originalTime": 1689783758000
    },
    {
        "time": 1689783761000,
        "value": 139.739,
        "_internal_originalTime": 1689783761000
    },
    {
        "time": 1689783762000,
        "value": 139.737,
        "_internal_originalTime": 1689783762000
    },
    {
        "time": 1689783764000,
        "value": 139.733,
        "_internal_originalTime": 1689783764000
    },
    {
        "time": 1689783765000,
        "value": 139.734,
        "_internal_originalTime": 1689783765000
    },
    {
        "time": 1689783766000,
        "value": 139.732,
        "_internal_originalTime": 1689783766000
    },
    {
        "time": 1689783767000,
        "value": 139.732,
        "_internal_originalTime": 1689783767000
    },
    {
        "time": 1689783768000,
        "value": 139.732,
        "_internal_originalTime": 1689783768000
    },
    {
        "time": 1689783769000,
        "value": 139.735,
        "_internal_originalTime": 1689783769000
    },
    {
        "time": 1689783770000,
        "value": 139.735,
        "_internal_originalTime": 1689783770000
    },
    {
        "time": 1689783775000,
        "value": 139.732,
        "_internal_originalTime": 1689783775000
    },
    {
        "time": 1689783776000,
        "value": 139.733,
        "_internal_originalTime": 1689783776000
    },
    {
        "time": 1689783777000,
        "value": 139.735,
        "_internal_originalTime": 1689783777000
    },
    {
        "time": 1689783779000,
        "value": 139.735,
        "_internal_originalTime": 1689783779000
    },
    {
        "time": 1689783780000,
        "value": 139.735,
        "_internal_originalTime": 1689783780000
    },
    {
        "time": 1689783781000,
        "value": 139.736,
        "_internal_originalTime": 1689783781000
    },
    {
        "time": 1689783783000,
        "value": 139.736,
        "_internal_originalTime": 1689783783000
    },
    {
        "time": 1689783785000,
        "value": 139.73,
        "_internal_originalTime": 1689783785000
    },
    {
        "time": 1689783786000,
        "value": 139.73,
        "_internal_originalTime": 1689783786000
    },
    {
        "time": 1689783787000,
        "value": 139.745,
        "_internal_originalTime": 1689783787000
    },
    {
        "time": 1689783788000,
        "value": 139.744,
        "_internal_originalTime": 1689783788000
    },
    {
        "time": 1689783789000,
        "value": 139.741,
        "_internal_originalTime": 1689783789000
    },
    {
        "time": 1689783790000,
        "value": 139.744,
        "_internal_originalTime": 1689783790000
    },
    {
        "time": 1689783791000,
        "value": 139.74,
        "_internal_originalTime": 1689783791000
    },
    {
        "time": 1689783793000,
        "value": 139.746,
        "_internal_originalTime": 1689783793000
    },
    {
        "time": 1689783795000,
        "value": 139.738,
        "_internal_originalTime": 1689783795000
    },
    {
        "time": 1689783796000,
        "value": 139.73,
        "_internal_originalTime": 1689783796000
    },
    {
        "time": 1689783797000,
        "value": 139.737,
        "_internal_originalTime": 1689783797000
    },
    {
        "time": 1689783798000,
        "value": 139.737,
        "_internal_originalTime": 1689783798000
    },
    {
        "time": 1689783801000,
        "value": 139.732,
        "_internal_originalTime": 1689783801000
    },
    {
        "time": 1689783804000,
        "value": 139.732,
        "_internal_originalTime": 1689783804000
    },
    {
        "time": 1689783805000,
        "value": 139.739,
        "_internal_originalTime": 1689783805000
    },
    {
        "time": 1689783806000,
        "value": 139.735,
        "_internal_originalTime": 1689783806000
    },
    {
        "time": 1689783807000,
        "value": 139.734,
        "_internal_originalTime": 1689783807000
    },
    {
        "time": 1689783811000,
        "value": 139.737,
        "_internal_originalTime": 1689783811000
    },
    {
        "time": 1689783812000,
        "value": 139.73,
        "_internal_originalTime": 1689783812000
    },
    {
        "time": 1689783813000,
        "value": 139.737,
        "_internal_originalTime": 1689783813000
    },
    {
        "time": 1689783814000,
        "value": 139.737,
        "_internal_originalTime": 1689783814000
    },
    {
        "time": 1689783817000,
        "value": 139.737,
        "_internal_originalTime": 1689783817000
    },
    {
        "time": 1689783818000,
        "value": 139.733,
        "_internal_originalTime": 1689783818000
    },
    {
        "time": 1689783819000,
        "value": 139.734,
        "_internal_originalTime": 1689783819000
    },
    {
        "time": 1689783820000,
        "value": 139.741,
        "_internal_originalTime": 1689783820000
    },
    {
        "time": 1689783822000,
        "value": 139.744,
        "_internal_originalTime": 1689783822000
    },
    {
        "time": 1689783825000,
        "value": 139.74,
        "_internal_originalTime": 1689783825000
    },
    {
        "time": 1689783826000,
        "value": 139.746,
        "_internal_originalTime": 1689783826000
    },
    {
        "time": 1689783828000,
        "value": 139.748,
        "_internal_originalTime": 1689783828000
    },
    {
        "time": 1689783829000,
        "value": 139.758,
        "_internal_originalTime": 1689783829000
    },
    {
        "time": 1689783830000,
        "value": 139.754,
        "_internal_originalTime": 1689783830000
    },
    {
        "time": 1689783831000,
        "value": 139.758,
        "_internal_originalTime": 1689783831000
    },
    {
        "time": 1689783832000,
        "value": 139.76,
        "_internal_originalTime": 1689783832000
    },
    {
        "time": 1689783833000,
        "value": 139.753,
        "_internal_originalTime": 1689783833000
    },
    {
        "time": 1689783836000,
        "value": 139.757,
        "_internal_originalTime": 1689783836000
    },
    {
        "time": 1689783837000,
        "value": 139.756,
        "_internal_originalTime": 1689783837000
    },
    {
        "time": 1689783838000,
        "value": 139.756,
        "_internal_originalTime": 1689783838000
    },
    {
        "time": 1689783840000,
        "value": 139.752,
        "_internal_originalTime": 1689783840000
    },
    {
        "time": 1689783841000,
        "value": 139.757,
        "_internal_originalTime": 1689783841000
    },
    {
        "time": 1689783842000,
        "value": 139.75,
        "_internal_originalTime": 1689783842000
    },
    {
        "time": 1689783843000,
        "value": 139.753,
        "_internal_originalTime": 1689783843000
    },
    {
        "time": 1689783845000,
        "value": 139.75,
        "_internal_originalTime": 1689783845000
    },
    {
        "time": 1689783846000,
        "value": 139.748,
        "_internal_originalTime": 1689783846000
    },
    {
        "time": 1689783847000,
        "value": 139.752,
        "_internal_originalTime": 1689783847000
    },
    {
        "time": 1689783848000,
        "value": 139.748,
        "_internal_originalTime": 1689783848000
    },
    {
        "time": 1689783849000,
        "value": 139.745,
        "_internal_originalTime": 1689783849000
    },
    {
        "time": 1689783850000,
        "value": 139.748,
        "_internal_originalTime": 1689783850000
    },
    {
        "time": 1689783851000,
        "value": 139.748,
        "_internal_originalTime": 1689783851000
    },
    {
        "time": 1689783853000,
        "value": 139.748,
        "_internal_originalTime": 1689783853000
    },
    {
        "time": 1689783854000,
        "value": 139.747,
        "_internal_originalTime": 1689783854000
    },
    {
        "time": 1689783855000,
        "value": 139.74,
        "_internal_originalTime": 1689783855000
    },
    {
        "time": 1689783856000,
        "value": 139.746,
        "_internal_originalTime": 1689783856000
    },
    {
        "time": 1689783857000,
        "value": 139.736,
        "_internal_originalTime": 1689783857000
    },
    {
        "time": 1689783858000,
        "value": 139.743,
        "_internal_originalTime": 1689783858000
    },
    {
        "time": 1689783859000,
        "value": 139.746,
        "_internal_originalTime": 1689783859000
    },
    {
        "time": 1689783860000,
        "value": 139.747,
        "_internal_originalTime": 1689783860000
    },
    {
        "time": 1689783862000,
        "value": 139.749,
        "_internal_originalTime": 1689783862000
    },
    {
        "time": 1689783863000,
        "value": 139.754,
        "_internal_originalTime": 1689783863000
    },
    {
        "time": 1689783864000,
        "value": 139.751,
        "_internal_originalTime": 1689783864000
    },
    {
        "time": 1689783865000,
        "value": 139.748,
        "_internal_originalTime": 1689783865000
    },
    {
        "time": 1689783866000,
        "value": 139.752,
        "_internal_originalTime": 1689783866000
    },
    {
        "time": 1689783869000,
        "value": 139.75,
        "_internal_originalTime": 1689783869000
    },
    {
        "time": 1689783870000,
        "value": 139.753,
        "_internal_originalTime": 1689783870000
    },
    {
        "time": 1689783872000,
        "value": 139.753,
        "_internal_originalTime": 1689783872000
    },
    {
        "time": 1689783873000,
        "value": 139.755,
        "_internal_originalTime": 1689783873000
    },
    {
        "time": 1689783875000,
        "value": 139.754,
        "_internal_originalTime": 1689783875000
    },
    {
        "time": 1689783876000,
        "value": 139.75,
        "_internal_originalTime": 1689783876000
    },
    {
        "time": 1689783878000,
        "value": 139.752,
        "_internal_originalTime": 1689783878000
    },
    {
        "time": 1689783880000,
        "value": 139.752,
        "_internal_originalTime": 1689783880000
    },
    {
        "time": 1689783881000,
        "value": 139.753,
        "_internal_originalTime": 1689783881000
    },
    {
        "time": 1689783883000,
        "value": 139.759,
        "_internal_originalTime": 1689783883000
    },
    {
        "time": 1689783885000,
        "value": 139.756,
        "_internal_originalTime": 1689783885000
    },
    {
        "time": 1689783890000,
        "value": 139.76,
        "_internal_originalTime": 1689783890000
    },
    {
        "time": 1689783891000,
        "value": 139.771,
        "_internal_originalTime": 1689783891000
    },
    {
        "time": 1689783892000,
        "value": 139.767,
        "_internal_originalTime": 1689783892000
    },
    {
        "time": 1689783895000,
        "value": 139.762,
        "_internal_originalTime": 1689783895000
    },
    {
        "time": 1689783897000,
        "value": 139.762,
        "_internal_originalTime": 1689783897000
    },
    {
        "time": 1689783898000,
        "value": 139.765,
        "_internal_originalTime": 1689783898000
    },
    {
        "time": 1689783899000,
        "value": 139.765,
        "_internal_originalTime": 1689783899000
    },
    {
        "time": 1689783900000,
        "value": 139.763,
        "_internal_originalTime": 1689783900000
    },
    {
        "time": 1689783902000,
        "value": 139.757,
        "_internal_originalTime": 1689783902000
    },
    {
        "time": 1689783903000,
        "value": 139.758,
        "_internal_originalTime": 1689783903000
    },
    {
        "time": 1689783905000,
        "value": 139.757,
        "_internal_originalTime": 1689783905000
    },
    {
        "time": 1689783906000,
        "value": 139.757,
        "_internal_originalTime": 1689783906000
    },
    {
        "time": 1689783907000,
        "value": 139.759,
        "_internal_originalTime": 1689783907000
    },
    {
        "time": 1689783908000,
        "value": 139.758,
        "_internal_originalTime": 1689783908000
    },
    {
        "time": 1689783909000,
        "value": 139.757,
        "_internal_originalTime": 1689783909000
    },
    {
        "time": 1689783910000,
        "value": 139.748,
        "_internal_originalTime": 1689783910000
    },
    {
        "time": 1689783911000,
        "value": 139.749,
        "_internal_originalTime": 1689783911000
    },
    {
        "time": 1689783912000,
        "value": 139.754,
        "_internal_originalTime": 1689783912000
    },
    {
        "time": 1689783914000,
        "value": 139.753,
        "_internal_originalTime": 1689783914000
    },
    {
        "time": 1689783915000,
        "value": 139.749,
        "_internal_originalTime": 1689783915000
    },
    {
        "time": 1689783916000,
        "value": 139.753,
        "_internal_originalTime": 1689783916000
    },
    {
        "time": 1689783917000,
        "value": 139.75,
        "_internal_originalTime": 1689783917000
    },
    {
        "time": 1689783918000,
        "value": 139.75,
        "_internal_originalTime": 1689783918000
    },
    {
        "time": 1689783919000,
        "value": 139.749,
        "_internal_originalTime": 1689783919000
    },
    {
        "time": 1689783921000,
        "value": 139.744,
        "_internal_originalTime": 1689783921000
    },
    {
        "time": 1689783922000,
        "value": 139.745,
        "_internal_originalTime": 1689783922000
    },
    {
        "time": 1689783923000,
        "value": 139.744,
        "_internal_originalTime": 1689783923000
    },
    {
        "time": 1689783924000,
        "value": 139.741,
        "_internal_originalTime": 1689783924000
    },
    {
        "time": 1689783926000,
        "value": 139.747,
        "_internal_originalTime": 1689783926000
    },
    {
        "time": 1689783928000,
        "value": 139.74,
        "_internal_originalTime": 1689783928000
    },
    {
        "time": 1689783930000,
        "value": 139.73,
        "_internal_originalTime": 1689783930000
    },
    {
        "time": 1689783931000,
        "value": 139.735,
        "_internal_originalTime": 1689783931000
    },
    {
        "time": 1689783933000,
        "value": 139.734,
        "_internal_originalTime": 1689783933000
    },
    {
        "time": 1689783934000,
        "value": 139.73,
        "_internal_originalTime": 1689783934000
    },
    {
        "time": 1689783935000,
        "value": 139.73,
        "_internal_originalTime": 1689783935000
    },
    {
        "time": 1689783936000,
        "value": 139.727,
        "_internal_originalTime": 1689783936000
    },
    {
        "time": 1689783938000,
        "value": 139.728,
        "_internal_originalTime": 1689783938000
    },
    {
        "time": 1689783941000,
        "value": 139.729,
        "_internal_originalTime": 1689783941000
    },
    {
        "time": 1689783942000,
        "value": 139.728,
        "_internal_originalTime": 1689783942000
    },
    {
        "time": 1689783944000,
        "value": 139.729,
        "_internal_originalTime": 1689783944000
    },
    {
        "time": 1689783945000,
        "value": 139.728,
        "_internal_originalTime": 1689783945000
    },
    {
        "time": 1689783947000,
        "value": 139.735,
        "_internal_originalTime": 1689783947000
    },
    {
        "time": 1689783948000,
        "value": 139.73,
        "_internal_originalTime": 1689783948000
    },
    {
        "time": 1689783949000,
        "value": 139.735,
        "_internal_originalTime": 1689783949000
    },
    {
        "time": 1689783950000,
        "value": 139.735,
        "_internal_originalTime": 1689783950000
    },
    {
        "time": 1689783955000,
        "value": 139.735,
        "_internal_originalTime": 1689783955000
    },
    {
        "time": 1689783956000,
        "value": 139.735,
        "_internal_originalTime": 1689783956000
    },
    {
        "time": 1689783957000,
        "value": 139.735,
        "_internal_originalTime": 1689783957000
    },
    {
        "time": 1689783958000,
        "value": 139.73,
        "_internal_originalTime": 1689783958000
    },
    {
        "time": 1689783960000,
        "value": 139.729,
        "_internal_originalTime": 1689783960000
    },
    {
        "time": 1689783961000,
        "value": 139.729,
        "_internal_originalTime": 1689783961000
    },
    {
        "time": 1689783963000,
        "value": 139.729,
        "_internal_originalTime": 1689783963000
    },
    {
        "time": 1689783964000,
        "value": 139.734,
        "_internal_originalTime": 1689783964000
    },
    {
        "time": 1689783967000,
        "value": 139.728,
        "_internal_originalTime": 1689783967000
    },
    {
        "time": 1689783968000,
        "value": 139.732,
        "_internal_originalTime": 1689783968000
    },
    {
        "time": 1689783970000,
        "value": 139.731,
        "_internal_originalTime": 1689783970000
    },
    {
        "time": 1689783971000,
        "value": 139.73,
        "_internal_originalTime": 1689783971000
    },
    {
        "time": 1689783972000,
        "value": 139.735,
        "_internal_originalTime": 1689783972000
    },
    {
        "time": 1689783973000,
        "value": 139.736,
        "_internal_originalTime": 1689783973000
    },
    {
        "time": 1689783975000,
        "value": 139.729,
        "_internal_originalTime": 1689783975000
    },
    {
        "time": 1689783976000,
        "value": 139.73,
        "_internal_originalTime": 1689783976000
    },
    {
        "time": 1689783978000,
        "value": 139.735,
        "_internal_originalTime": 1689783978000
    },
    {
        "time": 1689783980000,
        "value": 139.737,
        "_internal_originalTime": 1689783980000
    },
    {
        "time": 1689783981000,
        "value": 139.738,
        "_internal_originalTime": 1689783981000
    },
    {
        "time": 1689783982000,
        "value": 139.74,
        "_internal_originalTime": 1689783982000
    },
    {
        "time": 1689783983000,
        "value": 139.738,
        "_internal_originalTime": 1689783983000
    },
    {
        "time": 1689783984000,
        "value": 139.737,
        "_internal_originalTime": 1689783984000
    },
    {
        "time": 1689783985000,
        "value": 139.731,
        "_internal_originalTime": 1689783985000
    },
    {
        "time": 1689783986000,
        "value": 139.736,
        "_internal_originalTime": 1689783986000
    },
    {
        "time": 1689783988000,
        "value": 139.73,
        "_internal_originalTime": 1689783988000
    },
    {
        "time": 1689783990000,
        "value": 139.729,
        "_internal_originalTime": 1689783990000
    },
    {
        "time": 1689783991000,
        "value": 139.725,
        "_internal_originalTime": 1689783991000
    },
    {
        "time": 1689783992000,
        "value": 139.73,
        "_internal_originalTime": 1689783992000
    },
    {
        "time": 1689783993000,
        "value": 139.73,
        "_internal_originalTime": 1689783993000
    },
    {
        "time": 1689783994000,
        "value": 139.728,
        "_internal_originalTime": 1689783994000
    },
    {
        "time": 1689783995000,
        "value": 139.726,
        "_internal_originalTime": 1689783995000
    },
    {
        "time": 1689783996000,
        "value": 139.728,
        "_internal_originalTime": 1689783996000
    },
    {
        "time": 1689783998000,
        "value": 139.727,
        "_internal_originalTime": 1689783998000
    },
    {
        "time": 1689783999000,
        "value": 139.737,
        "_internal_originalTime": 1689783999000
    },
    {
        "time": 1689784000000,
        "value": 139.729,
        "_internal_originalTime": 1689784000000
    },
    {
        "time": 1689784002000,
        "value": 139.729,
        "_internal_originalTime": 1689784002000
    },
    {
        "time": 1689784003000,
        "value": 139.728,
        "_internal_originalTime": 1689784003000
    },
    {
        "time": 1689784004000,
        "value": 139.73,
        "_internal_originalTime": 1689784004000
    },
    {
        "time": 1689784005000,
        "value": 139.736,
        "_internal_originalTime": 1689784005000
    },
    {
        "time": 1689784006000,
        "value": 139.736,
        "_internal_originalTime": 1689784006000
    },
    {
        "time": 1689784008000,
        "value": 139.729,
        "_internal_originalTime": 1689784008000
    },
    {
        "time": 1689784009000,
        "value": 139.728,
        "_internal_originalTime": 1689784009000
    },
    {
        "time": 1689784010000,
        "value": 139.733,
        "_internal_originalTime": 1689784010000
    },
    {
        "time": 1689784012000,
        "value": 139.727,
        "_internal_originalTime": 1689784012000
    },
    {
        "time": 1689784013000,
        "value": 139.733,
        "_internal_originalTime": 1689784013000
    },
    {
        "time": 1689784014000,
        "value": 139.735,
        "_internal_originalTime": 1689784014000
    },
    {
        "time": 1689784015000,
        "value": 139.735,
        "_internal_originalTime": 1689784015000
    },
    {
        "time": 1689784016000,
        "value": 139.735,
        "_internal_originalTime": 1689784016000
    },
    {
        "time": 1689784017000,
        "value": 139.739,
        "_internal_originalTime": 1689784017000
    },
    {
        "time": 1689784018000,
        "value": 139.733,
        "_internal_originalTime": 1689784018000
    },
    {
        "time": 1689784019000,
        "value": 139.734,
        "_internal_originalTime": 1689784019000
    },
    {
        "time": 1689784020000,
        "value": 139.744,
        "_internal_originalTime": 1689784020000
    },
    {
        "time": 1689784021000,
        "value": 139.749,
        "_internal_originalTime": 1689784021000
    },
    {
        "time": 1689784022000,
        "value": 139.742,
        "_internal_originalTime": 1689784022000
    },
    {
        "time": 1689784024000,
        "value": 139.744,
        "_internal_originalTime": 1689784024000
    },
    {
        "time": 1689784025000,
        "value": 139.739,
        "_internal_originalTime": 1689784025000
    },
    {
        "time": 1689784027000,
        "value": 139.745,
        "_internal_originalTime": 1689784027000
    },
    {
        "time": 1689784028000,
        "value": 139.743,
        "_internal_originalTime": 1689784028000
    },
    {
        "time": 1689784029000,
        "value": 139.744,
        "_internal_originalTime": 1689784029000
    },
    {
        "time": 1689784030000,
        "value": 139.74,
        "_internal_originalTime": 1689784030000
    },
    {
        "time": 1689784031000,
        "value": 139.746,
        "_internal_originalTime": 1689784031000
    },
    {
        "time": 1689784033000,
        "value": 139.746,
        "_internal_originalTime": 1689784033000
    },
    {
        "time": 1689784035000,
        "value": 139.74,
        "_internal_originalTime": 1689784035000
    },
    {
        "time": 1689784036000,
        "value": 139.748,
        "_internal_originalTime": 1689784036000
    },
    {
        "time": 1689784037000,
        "value": 139.747,
        "_internal_originalTime": 1689784037000
    },
    {
        "time": 1689784039000,
        "value": 139.747,
        "_internal_originalTime": 1689784039000
    },
    {
        "time": 1689784040000,
        "value": 139.743,
        "_internal_originalTime": 1689784040000
    },
    {
        "time": 1689784047000,
        "value": 139.742,
        "_internal_originalTime": 1689784047000
    },
    {
        "time": 1689784048000,
        "value": 139.746,
        "_internal_originalTime": 1689784048000
    },
    {
        "time": 1689784049000,
        "value": 139.743,
        "_internal_originalTime": 1689784049000
    },
    {
        "time": 1689784050000,
        "value": 139.747,
        "_internal_originalTime": 1689784050000
    },
    {
        "time": 1689784051000,
        "value": 139.746,
        "_internal_originalTime": 1689784051000
    },
    {
        "time": 1689784052000,
        "value": 139.741,
        "_internal_originalTime": 1689784052000
    },
    {
        "time": 1689784053000,
        "value": 139.74,
        "_internal_originalTime": 1689784053000
    },
    {
        "time": 1689784054000,
        "value": 139.741,
        "_internal_originalTime": 1689784054000
    },
    {
        "time": 1689784059000,
        "value": 139.74,
        "_internal_originalTime": 1689784059000
    },
    {
        "time": 1689784061000,
        "value": 139.741,
        "_internal_originalTime": 1689784061000
    },
    {
        "time": 1689784062000,
        "value": 139.74,
        "_internal_originalTime": 1689784062000
    },
    {
        "time": 1689784063000,
        "value": 139.741,
        "_internal_originalTime": 1689784063000
    },
    {
        "time": 1689784064000,
        "value": 139.74,
        "_internal_originalTime": 1689784064000
    },
    {
        "time": 1689784065000,
        "value": 139.747,
        "_internal_originalTime": 1689784065000
    },
    {
        "time": 1689784066000,
        "value": 139.75,
        "_internal_originalTime": 1689784066000
    },
    {
        "time": 1689784068000,
        "value": 139.744,
        "_internal_originalTime": 1689784068000
    },
    {
        "time": 1689784069000,
        "value": 139.746,
        "_internal_originalTime": 1689784069000
    },
    {
        "time": 1689784071000,
        "value": 139.743,
        "_internal_originalTime": 1689784071000
    },
    {
        "time": 1689784073000,
        "value": 139.745,
        "_internal_originalTime": 1689784073000
    },
    {
        "time": 1689784074000,
        "value": 139.746,
        "_internal_originalTime": 1689784074000
    },
    {
        "time": 1689784075000,
        "value": 139.739,
        "_internal_originalTime": 1689784075000
    },
    {
        "time": 1689784076000,
        "value": 139.741,
        "_internal_originalTime": 1689784076000
    },
    {
        "time": 1689784080000,
        "value": 139.74,
        "_internal_originalTime": 1689784080000
    },
    {
        "time": 1689784081000,
        "value": 139.746,
        "_internal_originalTime": 1689784081000
    },
    {
        "time": 1689784082000,
        "value": 139.741,
        "_internal_originalTime": 1689784082000
    },
    {
        "time": 1689784084000,
        "value": 139.739,
        "_internal_originalTime": 1689784084000
    },
    {
        "time": 1689784086000,
        "value": 139.745,
        "_internal_originalTime": 1689784086000
    },
    {
        "time": 1689784088000,
        "value": 139.738,
        "_internal_originalTime": 1689784088000
    },
    {
        "time": 1689784090000,
        "value": 139.731,
        "_internal_originalTime": 1689784090000
    },
    {
        "time": 1689784091000,
        "value": 139.739,
        "_internal_originalTime": 1689784091000
    },
    {
        "time": 1689784092000,
        "value": 139.731,
        "_internal_originalTime": 1689784092000
    },
    {
        "time": 1689784093000,
        "value": 139.731,
        "_internal_originalTime": 1689784093000
    },
    {
        "time": 1689784095000,
        "value": 139.73,
        "_internal_originalTime": 1689784095000
    },
    {
        "time": 1689784097000,
        "value": 139.732,
        "_internal_originalTime": 1689784097000
    },
    {
        "time": 1689784098000,
        "value": 139.73,
        "_internal_originalTime": 1689784098000
    },
    {
        "time": 1689784100000,
        "value": 139.73,
        "_internal_originalTime": 1689784100000
    },
    {
        "time": 1689784101000,
        "value": 139.73,
        "_internal_originalTime": 1689784101000
    },
    {
        "time": 1689784104000,
        "value": 139.73,
        "_internal_originalTime": 1689784104000
    },
    {
        "time": 1689784105000,
        "value": 139.736,
        "_internal_originalTime": 1689784105000
    },
    {
        "time": 1689784106000,
        "value": 139.73,
        "_internal_originalTime": 1689784106000
    },
    {
        "time": 1689784108000,
        "value": 139.734,
        "_internal_originalTime": 1689784108000
    },
    {
        "time": 1689784109000,
        "value": 139.73,
        "_internal_originalTime": 1689784109000
    },
    {
        "time": 1689784110000,
        "value": 139.733,
        "_internal_originalTime": 1689784110000
    },
    {
        "time": 1689784111000,
        "value": 139.73,
        "_internal_originalTime": 1689784111000
    },
    {
        "time": 1689784112000,
        "value": 139.731,
        "_internal_originalTime": 1689784112000
    },
    {
        "time": 1689784113000,
        "value": 139.73,
        "_internal_originalTime": 1689784113000
    },
    {
        "time": 1689784114000,
        "value": 139.734,
        "_internal_originalTime": 1689784114000
    },
    {
        "time": 1689784116000,
        "value": 139.734,
        "_internal_originalTime": 1689784116000
    },
    {
        "time": 1689784118000,
        "value": 139.73,
        "_internal_originalTime": 1689784118000
    },
    {
        "time": 1689784119000,
        "value": 139.731,
        "_internal_originalTime": 1689784119000
    },
    {
        "time": 1689784120000,
        "value": 139.73,
        "_internal_originalTime": 1689784120000
    },
    {
        "time": 1689784121000,
        "value": 139.729,
        "_internal_originalTime": 1689784121000
    },
    {
        "time": 1689784123000,
        "value": 139.731,
        "_internal_originalTime": 1689784123000
    },
    {
        "time": 1689784124000,
        "value": 139.734,
        "_internal_originalTime": 1689784124000
    },
    {
        "time": 1689784125000,
        "value": 139.729,
        "_internal_originalTime": 1689784125000
    },
    {
        "time": 1689784127000,
        "value": 139.729,
        "_internal_originalTime": 1689784127000
    },
    {
        "time": 1689784128000,
        "value": 139.729,
        "_internal_originalTime": 1689784128000
    },
    {
        "time": 1689784130000,
        "value": 139.731,
        "_internal_originalTime": 1689784130000
    },
    {
        "time": 1689784131000,
        "value": 139.733,
        "_internal_originalTime": 1689784131000
    },
    {
        "time": 1689784132000,
        "value": 139.734,
        "_internal_originalTime": 1689784132000
    },
    {
        "time": 1689784134000,
        "value": 139.736,
        "_internal_originalTime": 1689784134000
    },
    {
        "time": 1689784139000,
        "value": 139.73,
        "_internal_originalTime": 1689784139000
    },
    {
        "time": 1689784140000,
        "value": 139.728,
        "_internal_originalTime": 1689784140000
    },
    {
        "time": 1689784141000,
        "value": 139.723,
        "_internal_originalTime": 1689784141000
    },
    {
        "time": 1689784142000,
        "value": 139.728,
        "_internal_originalTime": 1689784142000
    },
    {
        "time": 1689784143000,
        "value": 139.728,
        "_internal_originalTime": 1689784143000
    },
    {
        "time": 1689784144000,
        "value": 139.722,
        "_internal_originalTime": 1689784144000
    },
    {
        "time": 1689784145000,
        "value": 139.72,
        "_internal_originalTime": 1689784145000
    },
    {
        "time": 1689784146000,
        "value": 139.719,
        "_internal_originalTime": 1689784146000
    },
    {
        "time": 1689784148000,
        "value": 139.716,
        "_internal_originalTime": 1689784148000
    },
    {
        "time": 1689784149000,
        "value": 139.719,
        "_internal_originalTime": 1689784149000
    },
    {
        "time": 1689784151000,
        "value": 139.718,
        "_internal_originalTime": 1689784151000
    },
    {
        "time": 1689784154000,
        "value": 139.718,
        "_internal_originalTime": 1689784154000
    },
    {
        "time": 1689784155000,
        "value": 139.712,
        "_internal_originalTime": 1689784155000
    },
    {
        "time": 1689784156000,
        "value": 139.719,
        "_internal_originalTime": 1689784156000
    },
    {
        "time": 1689784157000,
        "value": 139.718,
        "_internal_originalTime": 1689784157000
    },
    {
        "time": 1689784158000,
        "value": 139.719,
        "_internal_originalTime": 1689784158000
    },
    {
        "time": 1689784159000,
        "value": 139.718,
        "_internal_originalTime": 1689784159000
    },
    {
        "time": 1689784160000,
        "value": 139.718,
        "_internal_originalTime": 1689784160000
    },
    {
        "time": 1689784162000,
        "value": 139.718,
        "_internal_originalTime": 1689784162000
    },
    {
        "time": 1689784164000,
        "value": 139.717,
        "_internal_originalTime": 1689784164000
    },
    {
        "time": 1689784165000,
        "value": 139.715,
        "_internal_originalTime": 1689784165000
    },
    {
        "time": 1689784166000,
        "value": 139.715,
        "_internal_originalTime": 1689784166000
    },
    {
        "time": 1689784167000,
        "value": 139.71,
        "_internal_originalTime": 1689784167000
    },
    {
        "time": 1689784170000,
        "value": 139.709,
        "_internal_originalTime": 1689784170000
    },
    {
        "time": 1689784171000,
        "value": 139.71,
        "_internal_originalTime": 1689784171000
    },
    {
        "time": 1689784172000,
        "value": 139.711,
        "_internal_originalTime": 1689784172000
    },
    {
        "time": 1689784178000,
        "value": 139.713,
        "_internal_originalTime": 1689784178000
    },
    {
        "time": 1689784179000,
        "value": 139.716,
        "_internal_originalTime": 1689784179000
    },
    {
        "time": 1689784180000,
        "value": 139.707,
        "_internal_originalTime": 1689784180000
    },
    {
        "time": 1689784181000,
        "value": 139.706,
        "_internal_originalTime": 1689784181000
    },
    {
        "time": 1689784183000,
        "value": 139.707,
        "_internal_originalTime": 1689784183000
    },
    {
        "time": 1689784185000,
        "value": 139.708,
        "_internal_originalTime": 1689784185000
    },
    {
        "time": 1689784186000,
        "value": 139.707,
        "_internal_originalTime": 1689784186000
    },
    {
        "time": 1689784187000,
        "value": 139.713,
        "_internal_originalTime": 1689784187000
    },
    {
        "time": 1689784188000,
        "value": 139.709,
        "_internal_originalTime": 1689784188000
    },
    {
        "time": 1689784190000,
        "value": 139.714,
        "_internal_originalTime": 1689784190000
    },
    {
        "time": 1689784191000,
        "value": 139.71,
        "_internal_originalTime": 1689784191000
    },
    {
        "time": 1689784192000,
        "value": 139.709,
        "_internal_originalTime": 1689784192000
    },
    {
        "time": 1689784193000,
        "value": 139.71,
        "_internal_originalTime": 1689784193000
    },
    {
        "time": 1689784194000,
        "value": 139.704,
        "_internal_originalTime": 1689784194000
    },
    {
        "time": 1689784198000,
        "value": 139.714,
        "_internal_originalTime": 1689784198000
    },
    {
        "time": 1689784200000,
        "value": 139.714,
        "_internal_originalTime": 1689784200000
    },
    {
        "time": 1689784201000,
        "value": 139.708,
        "_internal_originalTime": 1689784201000
    },
    {
        "time": 1689784203000,
        "value": 139.706,
        "_internal_originalTime": 1689784203000
    },
    {
        "time": 1689784204000,
        "value": 139.707,
        "_internal_originalTime": 1689784204000
    },
    {
        "time": 1689784205000,
        "value": 139.71,
        "_internal_originalTime": 1689784205000
    },
    {
        "time": 1689784206000,
        "value": 139.704,
        "_internal_originalTime": 1689784206000
    },
    {
        "time": 1689784208000,
        "value": 139.705,
        "_internal_originalTime": 1689784208000
    },
    {
        "time": 1689784210000,
        "value": 139.702,
        "_internal_originalTime": 1689784210000
    },
    {
        "time": 1689784211000,
        "value": 139.702,
        "_internal_originalTime": 1689784211000
    },
    {
        "time": 1689784212000,
        "value": 139.708,
        "_internal_originalTime": 1689784212000
    },
    {
        "time": 1689784214000,
        "value": 139.713,
        "_internal_originalTime": 1689784214000
    },
    {
        "time": 1689784215000,
        "value": 139.704,
        "_internal_originalTime": 1689784215000
    },
    {
        "time": 1689784216000,
        "value": 139.71,
        "_internal_originalTime": 1689784216000
    },
    {
        "time": 1689784219000,
        "value": 139.709,
        "_internal_originalTime": 1689784219000
    },
    {
        "time": 1689784220000,
        "value": 139.704,
        "_internal_originalTime": 1689784220000
    },
    {
        "time": 1689784222000,
        "value": 139.708,
        "_internal_originalTime": 1689784222000
    },
    {
        "time": 1689784223000,
        "value": 139.702,
        "_internal_originalTime": 1689784223000
    },
    {
        "time": 1689784224000,
        "value": 139.709,
        "_internal_originalTime": 1689784224000
    },
    {
        "time": 1689784225000,
        "value": 139.708,
        "_internal_originalTime": 1689784225000
    },
    {
        "time": 1689784226000,
        "value": 139.7,
        "_internal_originalTime": 1689784226000
    },
    {
        "time": 1689784228000,
        "value": 139.699,
        "_internal_originalTime": 1689784228000
    },
    {
        "time": 1689784229000,
        "value": 139.696,
        "_internal_originalTime": 1689784229000
    },
    {
        "time": 1689784230000,
        "value": 139.694,
        "_internal_originalTime": 1689784230000
    },
    {
        "time": 1689784231000,
        "value": 139.684,
        "_internal_originalTime": 1689784231000
    },
    {
        "time": 1689784233000,
        "value": 139.689,
        "_internal_originalTime": 1689784233000
    },
    {
        "time": 1689784234000,
        "value": 139.681,
        "_internal_originalTime": 1689784234000
    },
    {
        "time": 1689784235000,
        "value": 139.687,
        "_internal_originalTime": 1689784235000
    },
    {
        "time": 1689784237000,
        "value": 139.687,
        "_internal_originalTime": 1689784237000
    },
    {
        "time": 1689784238000,
        "value": 139.688,
        "_internal_originalTime": 1689784238000
    },
    {
        "time": 1689784239000,
        "value": 139.685,
        "_internal_originalTime": 1689784239000
    },
    {
        "time": 1689784240000,
        "value": 139.685,
        "_internal_originalTime": 1689784240000
    },
    {
        "time": 1689784241000,
        "value": 139.688,
        "_internal_originalTime": 1689784241000
    },
    {
        "time": 1689784243000,
        "value": 139.688,
        "_internal_originalTime": 1689784243000
    },
    {
        "time": 1689784244000,
        "value": 139.684,
        "_internal_originalTime": 1689784244000
    },
    {
        "time": 1689784245000,
        "value": 139.685,
        "_internal_originalTime": 1689784245000
    },
    {
        "time": 1689784246000,
        "value": 139.682,
        "_internal_originalTime": 1689784246000
    },
    {
        "time": 1689784248000,
        "value": 139.689,
        "_internal_originalTime": 1689784248000
    },
    {
        "time": 1689784250000,
        "value": 139.693,
        "_internal_originalTime": 1689784250000
    },
    {
        "time": 1689784251000,
        "value": 139.689,
        "_internal_originalTime": 1689784251000
    },
    {
        "time": 1689784252000,
        "value": 139.691,
        "_internal_originalTime": 1689784252000
    },
    {
        "time": 1689784253000,
        "value": 139.695,
        "_internal_originalTime": 1689784253000
    },
    {
        "time": 1689784254000,
        "value": 139.692,
        "_internal_originalTime": 1689784254000
    },
    {
        "time": 1689784255000,
        "value": 139.692,
        "_internal_originalTime": 1689784255000
    },
    {
        "time": 1689784257000,
        "value": 139.692,
        "_internal_originalTime": 1689784257000
    },
    {
        "time": 1689784258000,
        "value": 139.703,
        "_internal_originalTime": 1689784258000
    },
    {
        "time": 1689784259000,
        "value": 139.698,
        "_internal_originalTime": 1689784259000
    },
    {
        "time": 1689784260000,
        "value": 139.704,
        "_internal_originalTime": 1689784260000
    },
    {
        "time": 1689784261000,
        "value": 139.702,
        "_internal_originalTime": 1689784261000
    },
    {
        "time": 1689784262000,
        "value": 139.703,
        "_internal_originalTime": 1689784262000
    },
    {
        "time": 1689784263000,
        "value": 139.703,
        "_internal_originalTime": 1689784263000
    },
    {
        "time": 1689784264000,
        "value": 139.705,
        "_internal_originalTime": 1689784264000
    },
    {
        "time": 1689784265000,
        "value": 139.705,
        "_internal_originalTime": 1689784265000
    },
    {
        "time": 1689784266000,
        "value": 139.7,
        "_internal_originalTime": 1689784266000
    },
    {
        "time": 1689784268000,
        "value": 139.703,
        "_internal_originalTime": 1689784268000
    },
    {
        "time": 1689784269000,
        "value": 139.707,
        "_internal_originalTime": 1689784269000
    },
    {
        "time": 1689784273000,
        "value": 139.7,
        "_internal_originalTime": 1689784273000
    },
    {
        "time": 1689784274000,
        "value": 139.707,
        "_internal_originalTime": 1689784274000
    },
    {
        "time": 1689784276000,
        "value": 139.696,
        "_internal_originalTime": 1689784276000
    },
    {
        "time": 1689784277000,
        "value": 139.692,
        "_internal_originalTime": 1689784277000
    },
    {
        "time": 1689784279000,
        "value": 139.687,
        "_internal_originalTime": 1689784279000
    },
    {
        "time": 1689784280000,
        "value": 139.688,
        "_internal_originalTime": 1689784280000
    },
    {
        "time": 1689784281000,
        "value": 139.678,
        "_internal_originalTime": 1689784281000
    },
    {
        "time": 1689784282000,
        "value": 139.689,
        "_internal_originalTime": 1689784282000
    },
    {
        "time": 1689784283000,
        "value": 139.689,
        "_internal_originalTime": 1689784283000
    },
    {
        "time": 1689784284000,
        "value": 139.683,
        "_internal_originalTime": 1689784284000
    },
    {
        "time": 1689784285000,
        "value": 139.684,
        "_internal_originalTime": 1689784285000
    },
    {
        "time": 1689784286000,
        "value": 139.684,
        "_internal_originalTime": 1689784286000
    },
    {
        "time": 1689784288000,
        "value": 139.689,
        "_internal_originalTime": 1689784288000
    },
    {
        "time": 1689784289000,
        "value": 139.69,
        "_internal_originalTime": 1689784289000
    },
    {
        "time": 1689784290000,
        "value": 139.688,
        "_internal_originalTime": 1689784290000
    },
    {
        "time": 1689784291000,
        "value": 139.693,
        "_internal_originalTime": 1689784291000
    },
    {
        "time": 1689784292000,
        "value": 139.698,
        "_internal_originalTime": 1689784292000
    },
    {
        "time": 1689784297000,
        "value": 139.699,
        "_internal_originalTime": 1689784297000
    },
    {
        "time": 1689784298000,
        "value": 139.699,
        "_internal_originalTime": 1689784298000
    },
    {
        "time": 1689784300000,
        "value": 139.7,
        "_internal_originalTime": 1689784300000
    },
    {
        "time": 1689784301000,
        "value": 139.699,
        "_internal_originalTime": 1689784301000
    },
    {
        "time": 1689784302000,
        "value": 139.7,
        "_internal_originalTime": 1689784302000
    },
    {
        "time": 1689784304000,
        "value": 139.696,
        "_internal_originalTime": 1689784304000
    },
    {
        "time": 1689784305000,
        "value": 139.701,
        "_internal_originalTime": 1689784305000
    },
    {
        "time": 1689784306000,
        "value": 139.699,
        "_internal_originalTime": 1689784306000
    },
    {
        "time": 1689784307000,
        "value": 139.698,
        "_internal_originalTime": 1689784307000
    },
    {
        "time": 1689784309000,
        "value": 139.7,
        "_internal_originalTime": 1689784309000
    },
    {
        "time": 1689784310000,
        "value": 139.705,
        "_internal_originalTime": 1689784310000
    },
    {
        "time": 1689784311000,
        "value": 139.7,
        "_internal_originalTime": 1689784311000
    },
    {
        "time": 1689784313000,
        "value": 139.702,
        "_internal_originalTime": 1689784313000
    },
    {
        "time": 1689784314000,
        "value": 139.706,
        "_internal_originalTime": 1689784314000
    },
    {
        "time": 1689784315000,
        "value": 139.706,
        "_internal_originalTime": 1689784315000
    },
    {
        "time": 1689784316000,
        "value": 139.708,
        "_internal_originalTime": 1689784316000
    },
    {
        "time": 1689784317000,
        "value": 139.705,
        "_internal_originalTime": 1689784317000
    },
    {
        "time": 1689784318000,
        "value": 139.706,
        "_internal_originalTime": 1689784318000
    },
    {
        "time": 1689784320000,
        "value": 139.697,
        "_internal_originalTime": 1689784320000
    },
    {
        "time": 1689784322000,
        "value": 139.696,
        "_internal_originalTime": 1689784322000
    },
    {
        "time": 1689784323000,
        "value": 139.695,
        "_internal_originalTime": 1689784323000
    },
    {
        "time": 1689784325000,
        "value": 139.695,
        "_internal_originalTime": 1689784325000
    },
    {
        "time": 1689784326000,
        "value": 139.693,
        "_internal_originalTime": 1689784326000
    },
    {
        "time": 1689784327000,
        "value": 139.693,
        "_internal_originalTime": 1689784327000
    },
    {
        "time": 1689784328000,
        "value": 139.696,
        "_internal_originalTime": 1689784328000
    },
    {
        "time": 1689784329000,
        "value": 139.698,
        "_internal_originalTime": 1689784329000
    },
    {
        "time": 1689784331000,
        "value": 139.693,
        "_internal_originalTime": 1689784331000
    },
    {
        "time": 1689784332000,
        "value": 139.698,
        "_internal_originalTime": 1689784332000
    },
    {
        "time": 1689784333000,
        "value": 139.697,
        "_internal_originalTime": 1689784333000
    },
    {
        "time": 1689784334000,
        "value": 139.698,
        "_internal_originalTime": 1689784334000
    },
    {
        "time": 1689784335000,
        "value": 139.697,
        "_internal_originalTime": 1689784335000
    },
    {
        "time": 1689784336000,
        "value": 139.69,
        "_internal_originalTime": 1689784336000
    },
    {
        "time": 1689784337000,
        "value": 139.698,
        "_internal_originalTime": 1689784337000
    },
    {
        "time": 1689784338000,
        "value": 139.695,
        "_internal_originalTime": 1689784338000
    },
    {
        "time": 1689784339000,
        "value": 139.696,
        "_internal_originalTime": 1689784339000
    },
    {
        "time": 1689784340000,
        "value": 139.693,
        "_internal_originalTime": 1689784340000
    },
    {
        "time": 1689784341000,
        "value": 139.687,
        "_internal_originalTime": 1689784341000
    },
    {
        "time": 1689784342000,
        "value": 139.694,
        "_internal_originalTime": 1689784342000
    },
    {
        "time": 1689784344000,
        "value": 139.694,
        "_internal_originalTime": 1689784344000
    },
    {
        "time": 1689784345000,
        "value": 139.695,
        "_internal_originalTime": 1689784345000
    },
    {
        "time": 1689784346000,
        "value": 139.694,
        "_internal_originalTime": 1689784346000
    },
    {
        "time": 1689784347000,
        "value": 139.694,
        "_internal_originalTime": 1689784347000
    },
    {
        "time": 1689784348000,
        "value": 139.693,
        "_internal_originalTime": 1689784348000
    },
    {
        "time": 1689784350000,
        "value": 139.694,
        "_internal_originalTime": 1689784350000
    },
    {
        "time": 1689784351000,
        "value": 139.694,
        "_internal_originalTime": 1689784351000
    },
    {
        "time": 1689784353000,
        "value": 139.694,
        "_internal_originalTime": 1689784353000
    },
    {
        "time": 1689784354000,
        "value": 139.689,
        "_internal_originalTime": 1689784354000
    },
    {
        "time": 1689784355000,
        "value": 139.69,
        "_internal_originalTime": 1689784355000
    },
    {
        "time": 1689784357000,
        "value": 139.688,
        "_internal_originalTime": 1689784357000
    },
    {
        "time": 1689784358000,
        "value": 139.685,
        "_internal_originalTime": 1689784358000
    },
    {
        "time": 1689784361000,
        "value": 139.685,
        "_internal_originalTime": 1689784361000
    },
    {
        "time": 1689784362000,
        "value": 139.679,
        "_internal_originalTime": 1689784362000
    },
    {
        "time": 1689784363000,
        "value": 139.679,
        "_internal_originalTime": 1689784363000
    },
    {
        "time": 1689784364000,
        "value": 139.679,
        "_internal_originalTime": 1689784364000
    },
    {
        "time": 1689784365000,
        "value": 139.669,
        "_internal_originalTime": 1689784365000
    },
    {
        "time": 1689784366000,
        "value": 139.67,
        "_internal_originalTime": 1689784366000
    },
    {
        "time": 1689784367000,
        "value": 139.667,
        "_internal_originalTime": 1689784367000
    },
    {
        "time": 1689784368000,
        "value": 139.67,
        "_internal_originalTime": 1689784368000
    },
    {
        "time": 1689784369000,
        "value": 139.675,
        "_internal_originalTime": 1689784369000
    },
    {
        "time": 1689784370000,
        "value": 139.67,
        "_internal_originalTime": 1689784370000
    },
    {
        "time": 1689784371000,
        "value": 139.671,
        "_internal_originalTime": 1689784371000
    },
    {
        "time": 1689784374000,
        "value": 139.671,
        "_internal_originalTime": 1689784374000
    },
    {
        "time": 1689784377000,
        "value": 139.677,
        "_internal_originalTime": 1689784377000
    },
    {
        "time": 1689784379000,
        "value": 139.677,
        "_internal_originalTime": 1689784379000
    },
    {
        "time": 1689784380000,
        "value": 139.675,
        "_internal_originalTime": 1689784380000
    },
    {
        "time": 1689784385000,
        "value": 139.675,
        "_internal_originalTime": 1689784385000
    },
    {
        "time": 1689784386000,
        "value": 139.672,
        "_internal_originalTime": 1689784386000
    },
    {
        "time": 1689784387000,
        "value": 139.675,
        "_internal_originalTime": 1689784387000
    },
    {
        "time": 1689784388000,
        "value": 139.675,
        "_internal_originalTime": 1689784388000
    },
    {
        "time": 1689784390000,
        "value": 139.675,
        "_internal_originalTime": 1689784390000
    },
    {
        "time": 1689784391000,
        "value": 139.675,
        "_internal_originalTime": 1689784391000
    },
    {
        "time": 1689784395000,
        "value": 139.67,
        "_internal_originalTime": 1689784395000
    },
    {
        "time": 1689784398000,
        "value": 139.678,
        "_internal_originalTime": 1689784398000
    },
    {
        "time": 1689784400000,
        "value": 139.674,
        "_internal_originalTime": 1689784400000
    },
    {
        "time": 1689784402000,
        "value": 139.674,
        "_internal_originalTime": 1689784402000
    },
    {
        "time": 1689784403000,
        "value": 139.671,
        "_internal_originalTime": 1689784403000
    },
    {
        "time": 1689784405000,
        "value": 139.671,
        "_internal_originalTime": 1689784405000
    },
    {
        "time": 1689784406000,
        "value": 139.675,
        "_internal_originalTime": 1689784406000
    },
    {
        "time": 1689784407000,
        "value": 139.672,
        "_internal_originalTime": 1689784407000
    },
    {
        "time": 1689784408000,
        "value": 139.674,
        "_internal_originalTime": 1689784408000
    },
    {
        "time": 1689784409000,
        "value": 139.674,
        "_internal_originalTime": 1689784409000
    },
    {
        "time": 1689784410000,
        "value": 139.664,
        "_internal_originalTime": 1689784410000
    },
    {
        "time": 1689784412000,
        "value": 139.67,
        "_internal_originalTime": 1689784412000
    },
    {
        "time": 1689784413000,
        "value": 139.669,
        "_internal_originalTime": 1689784413000
    },
    {
        "time": 1689784415000,
        "value": 139.669,
        "_internal_originalTime": 1689784415000
    },
    {
        "time": 1689784416000,
        "value": 139.668,
        "_internal_originalTime": 1689784416000
    },
    {
        "time": 1689784417000,
        "value": 139.67,
        "_internal_originalTime": 1689784417000
    },
    {
        "time": 1689784418000,
        "value": 139.669,
        "_internal_originalTime": 1689784418000
    },
    {
        "time": 1689784420000,
        "value": 139.666,
        "_internal_originalTime": 1689784420000
    },
    {
        "time": 1689784421000,
        "value": 139.674,
        "_internal_originalTime": 1689784421000
    },
    {
        "time": 1689784422000,
        "value": 139.675,
        "_internal_originalTime": 1689784422000
    },
    {
        "time": 1689784423000,
        "value": 139.674,
        "_internal_originalTime": 1689784423000
    },
    {
        "time": 1689784424000,
        "value": 139.674,
        "_internal_originalTime": 1689784424000
    },
    {
        "time": 1689784425000,
        "value": 139.669,
        "_internal_originalTime": 1689784425000
    },
    {
        "time": 1689784426000,
        "value": 139.67,
        "_internal_originalTime": 1689784426000
    },
    {
        "time": 1689784427000,
        "value": 139.674,
        "_internal_originalTime": 1689784427000
    },
    {
        "time": 1689784428000,
        "value": 139.672,
        "_internal_originalTime": 1689784428000
    },
    {
        "time": 1689784429000,
        "value": 139.671,
        "_internal_originalTime": 1689784429000
    },
    {
        "time": 1689784430000,
        "value": 139.674,
        "_internal_originalTime": 1689784430000
    },
    {
        "time": 1689784432000,
        "value": 139.674,
        "_internal_originalTime": 1689784432000
    },
    {
        "time": 1689784433000,
        "value": 139.674,
        "_internal_originalTime": 1689784433000
    },
    {
        "time": 1689784434000,
        "value": 139.675,
        "_internal_originalTime": 1689784434000
    },
    {
        "time": 1689784435000,
        "value": 139.672,
        "_internal_originalTime": 1689784435000
    },
    {
        "time": 1689784436000,
        "value": 139.677,
        "_internal_originalTime": 1689784436000
    },
    {
        "time": 1689784438000,
        "value": 139.672,
        "_internal_originalTime": 1689784438000
    },
    {
        "time": 1689784441000,
        "value": 139.672,
        "_internal_originalTime": 1689784441000
    },
    {
        "time": 1689784442000,
        "value": 139.668,
        "_internal_originalTime": 1689784442000
    },
    {
        "time": 1689784445000,
        "value": 139.667,
        "_internal_originalTime": 1689784445000
    },
    {
        "time": 1689784447000,
        "value": 139.67,
        "_internal_originalTime": 1689784447000
    },
    {
        "time": 1689784448000,
        "value": 139.67,
        "_internal_originalTime": 1689784448000
    },
    {
        "time": 1689784449000,
        "value": 139.672,
        "_internal_originalTime": 1689784449000
    },
    {
        "time": 1689784450000,
        "value": 139.67,
        "_internal_originalTime": 1689784450000
    },
    {
        "time": 1689784451000,
        "value": 139.67,
        "_internal_originalTime": 1689784451000
    },
    {
        "time": 1689784452000,
        "value": 139.674,
        "_internal_originalTime": 1689784452000
    },
    {
        "time": 1689784453000,
        "value": 139.668,
        "_internal_originalTime": 1689784453000
    },
    {
        "time": 1689784454000,
        "value": 139.663,
        "_internal_originalTime": 1689784454000
    },
    {
        "time": 1689784455000,
        "value": 139.66,
        "_internal_originalTime": 1689784455000
    },
    {
        "time": 1689784456000,
        "value": 139.662,
        "_internal_originalTime": 1689784456000
    },
    {
        "time": 1689784457000,
        "value": 139.656,
        "_internal_originalTime": 1689784457000
    },
    {
        "time": 1689784458000,
        "value": 139.653,
        "_internal_originalTime": 1689784458000
    },
    {
        "time": 1689784460000,
        "value": 139.653,
        "_internal_originalTime": 1689784460000
    },
    {
        "time": 1689784461000,
        "value": 139.652,
        "_internal_originalTime": 1689784461000
    },
    {
        "time": 1689784462000,
        "value": 139.652,
        "_internal_originalTime": 1689784462000
    },
    {
        "time": 1689784463000,
        "value": 139.653,
        "_internal_originalTime": 1689784463000
    },
    {
        "time": 1689784464000,
        "value": 139.655,
        "_internal_originalTime": 1689784464000
    },
    {
        "time": 1689784466000,
        "value": 139.66,
        "_internal_originalTime": 1689784466000
    },
    {
        "time": 1689784468000,
        "value": 139.664,
        "_internal_originalTime": 1689784468000
    },
    {
        "time": 1689784470000,
        "value": 139.664,
        "_internal_originalTime": 1689784470000
    },
    {
        "time": 1689784471000,
        "value": 139.66,
        "_internal_originalTime": 1689784471000
    },
    {
        "time": 1689784472000,
        "value": 139.659,
        "_internal_originalTime": 1689784472000
    },
    {
        "time": 1689784473000,
        "value": 139.655,
        "_internal_originalTime": 1689784473000
    },
    {
        "time": 1689784474000,
        "value": 139.658,
        "_internal_originalTime": 1689784474000
    },
    {
        "time": 1689784475000,
        "value": 139.658,
        "_internal_originalTime": 1689784475000
    },
    {
        "time": 1689784477000,
        "value": 139.664,
        "_internal_originalTime": 1689784477000
    },
    {
        "time": 1689784478000,
        "value": 139.663,
        "_internal_originalTime": 1689784478000
    },
    {
        "time": 1689784479000,
        "value": 139.67,
        "_internal_originalTime": 1689784479000
    },
    {
        "time": 1689784480000,
        "value": 139.665,
        "_internal_originalTime": 1689784480000
    },
    {
        "time": 1689784482000,
        "value": 139.673,
        "_internal_originalTime": 1689784482000
    },
    {
        "time": 1689784483000,
        "value": 139.674,
        "_internal_originalTime": 1689784483000
    },
    {
        "time": 1689784485000,
        "value": 139.673,
        "_internal_originalTime": 1689784485000
    },
    {
        "time": 1689784486000,
        "value": 139.674,
        "_internal_originalTime": 1689784486000
    },
    {
        "time": 1689784487000,
        "value": 139.677,
        "_internal_originalTime": 1689784487000
    },
    {
        "time": 1689784488000,
        "value": 139.675,
        "_internal_originalTime": 1689784488000
    },
    {
        "time": 1689784490000,
        "value": 139.671,
        "_internal_originalTime": 1689784490000
    },
    {
        "time": 1689784491000,
        "value": 139.672,
        "_internal_originalTime": 1689784491000
    },
    {
        "time": 1689784492000,
        "value": 139.674,
        "_internal_originalTime": 1689784492000
    },
    {
        "time": 1689784493000,
        "value": 139.675,
        "_internal_originalTime": 1689784493000
    },
    {
        "time": 1689784495000,
        "value": 139.676,
        "_internal_originalTime": 1689784495000
    },
    {
        "time": 1689784497000,
        "value": 139.678,
        "_internal_originalTime": 1689784497000
    },
    {
        "time": 1689784498000,
        "value": 139.677,
        "_internal_originalTime": 1689784498000
    },
    {
        "time": 1689784499000,
        "value": 139.671,
        "_internal_originalTime": 1689784499000
    },
    {
        "time": 1689784500000,
        "value": 139.667,
        "_internal_originalTime": 1689784500000
    },
    {
        "time": 1689784501000,
        "value": 139.665,
        "_internal_originalTime": 1689784501000
    },
    {
        "time": 1689784502000,
        "value": 139.66,
        "_internal_originalTime": 1689784502000
    },
    {
        "time": 1689784503000,
        "value": 139.665,
        "_internal_originalTime": 1689784503000
    },
    {
        "time": 1689784505000,
        "value": 139.663,
        "_internal_originalTime": 1689784505000
    },
    {
        "time": 1689784506000,
        "value": 139.665,
        "_internal_originalTime": 1689784506000
    },
    {
        "time": 1689784508000,
        "value": 139.665,
        "_internal_originalTime": 1689784508000
    },
    {
        "time": 1689784511000,
        "value": 139.665,
        "_internal_originalTime": 1689784511000
    },
    {
        "time": 1689784512000,
        "value": 139.672,
        "_internal_originalTime": 1689784512000
    },
    {
        "time": 1689784513000,
        "value": 139.672,
        "_internal_originalTime": 1689784513000
    },
    {
        "time": 1689784515000,
        "value": 139.663,
        "_internal_originalTime": 1689784515000
    },
    {
        "time": 1689784516000,
        "value": 139.666,
        "_internal_originalTime": 1689784516000
    },
    {
        "time": 1689784518000,
        "value": 139.663,
        "_internal_originalTime": 1689784518000
    },
    {
        "time": 1689784519000,
        "value": 139.664,
        "_internal_originalTime": 1689784519000
    },
    {
        "time": 1689784522000,
        "value": 139.662,
        "_internal_originalTime": 1689784522000
    },
    {
        "time": 1689784523000,
        "value": 139.652,
        "_internal_originalTime": 1689784523000
    },
    {
        "time": 1689784524000,
        "value": 139.656,
        "_internal_originalTime": 1689784524000
    },
    {
        "time": 1689784525000,
        "value": 139.656,
        "_internal_originalTime": 1689784525000
    },
    {
        "time": 1689784526000,
        "value": 139.657,
        "_internal_originalTime": 1689784526000
    },
    {
        "time": 1689784527000,
        "value": 139.657,
        "_internal_originalTime": 1689784527000
    },
    {
        "time": 1689784529000,
        "value": 139.658,
        "_internal_originalTime": 1689784529000
    },
    {
        "time": 1689784530000,
        "value": 139.658,
        "_internal_originalTime": 1689784530000
    },
    {
        "time": 1689784531000,
        "value": 139.658,
        "_internal_originalTime": 1689784531000
    },
    {
        "time": 1689784533000,
        "value": 139.658,
        "_internal_originalTime": 1689784533000
    },
    {
        "time": 1689784534000,
        "value": 139.655,
        "_internal_originalTime": 1689784534000
    },
    {
        "time": 1689784535000,
        "value": 139.656,
        "_internal_originalTime": 1689784535000
    },
    {
        "time": 1689784536000,
        "value": 139.66,
        "_internal_originalTime": 1689784536000
    },
    {
        "time": 1689784537000,
        "value": 139.658,
        "_internal_originalTime": 1689784537000
    },
    {
        "time": 1689784539000,
        "value": 139.656,
        "_internal_originalTime": 1689784539000
    },
    {
        "time": 1689784540000,
        "value": 139.655,
        "_internal_originalTime": 1689784540000
    },
    {
        "time": 1689784541000,
        "value": 139.655,
        "_internal_originalTime": 1689784541000
    },
    {
        "time": 1689784543000,
        "value": 139.652,
        "_internal_originalTime": 1689784543000
    },
    {
        "time": 1689784544000,
        "value": 139.652,
        "_internal_originalTime": 1689784544000
    },
    {
        "time": 1689784545000,
        "value": 139.652,
        "_internal_originalTime": 1689784545000
    },
    {
        "time": 1689784546000,
        "value": 139.653,
        "_internal_originalTime": 1689784546000
    },
    {
        "time": 1689784550000,
        "value": 139.656,
        "_internal_originalTime": 1689784550000
    },
    {
        "time": 1689784551000,
        "value": 139.656,
        "_internal_originalTime": 1689784551000
    },
    {
        "time": 1689784553000,
        "value": 139.656,
        "_internal_originalTime": 1689784553000
    },
    {
        "time": 1689784556000,
        "value": 139.655,
        "_internal_originalTime": 1689784556000
    },
    {
        "time": 1689784558000,
        "value": 139.65,
        "_internal_originalTime": 1689784558000
    },
    {
        "time": 1689784561000,
        "value": 139.646,
        "_internal_originalTime": 1689784561000
    },
    {
        "time": 1689784562000,
        "value": 139.655,
        "_internal_originalTime": 1689784562000
    },
    {
        "time": 1689784564000,
        "value": 139.66,
        "_internal_originalTime": 1689784564000
    },
    {
        "time": 1689784566000,
        "value": 139.657,
        "_internal_originalTime": 1689784566000
    },
    {
        "time": 1689784567000,
        "value": 139.657,
        "_internal_originalTime": 1689784567000
    },
    {
        "time": 1689784568000,
        "value": 139.655,
        "_internal_originalTime": 1689784568000
    },
    {
        "time": 1689784570000,
        "value": 139.65,
        "_internal_originalTime": 1689784570000
    },
    {
        "time": 1689784572000,
        "value": 139.652,
        "_internal_originalTime": 1689784572000
    },
    {
        "time": 1689784573000,
        "value": 139.652,
        "_internal_originalTime": 1689784573000
    },
    {
        "time": 1689784574000,
        "value": 139.655,
        "_internal_originalTime": 1689784574000
    },
    {
        "time": 1689784575000,
        "value": 139.65,
        "_internal_originalTime": 1689784575000
    },
    {
        "time": 1689784576000,
        "value": 139.652,
        "_internal_originalTime": 1689784576000
    },
    {
        "time": 1689784578000,
        "value": 139.652,
        "_internal_originalTime": 1689784578000
    },
    {
        "time": 1689784579000,
        "value": 139.656,
        "_internal_originalTime": 1689784579000
    },
    {
        "time": 1689784584000,
        "value": 139.653,
        "_internal_originalTime": 1689784584000
    },
    {
        "time": 1689784586000,
        "value": 139.657,
        "_internal_originalTime": 1689784586000
    },
    {
        "time": 1689784587000,
        "value": 139.657,
        "_internal_originalTime": 1689784587000
    },
    {
        "time": 1689784592000,
        "value": 139.652,
        "_internal_originalTime": 1689784592000
    },
    {
        "time": 1689784593000,
        "value": 139.657,
        "_internal_originalTime": 1689784593000
    },
    {
        "time": 1689784594000,
        "value": 139.663,
        "_internal_originalTime": 1689784594000
    },
    {
        "time": 1689784598000,
        "value": 139.662,
        "_internal_originalTime": 1689784598000
    },
    {
        "time": 1689784603000,
        "value": 139.663,
        "_internal_originalTime": 1689784603000
    },
    {
        "time": 1689784605000,
        "value": 139.662,
        "_internal_originalTime": 1689784605000
    },
    {
        "time": 1689784606000,
        "value": 139.67,
        "_internal_originalTime": 1689784606000
    },
    {
        "time": 1689784607000,
        "value": 139.674,
        "_internal_originalTime": 1689784607000
    },
    {
        "time": 1689784608000,
        "value": 139.664,
        "_internal_originalTime": 1689784608000
    },
    {
        "time": 1689784609000,
        "value": 139.665,
        "_internal_originalTime": 1689784609000
    },
    {
        "time": 1689784610000,
        "value": 139.663,
        "_internal_originalTime": 1689784610000
    },
    {
        "time": 1689784611000,
        "value": 139.666,
        "_internal_originalTime": 1689784611000
    },
    {
        "time": 1689784613000,
        "value": 139.667,
        "_internal_originalTime": 1689784613000
    },
    {
        "time": 1689784614000,
        "value": 139.667,
        "_internal_originalTime": 1689784614000
    },
    {
        "time": 1689784615000,
        "value": 139.667,
        "_internal_originalTime": 1689784615000
    },
    {
        "time": 1689784616000,
        "value": 139.665,
        "_internal_originalTime": 1689784616000
    },
    {
        "time": 1689784617000,
        "value": 139.664,
        "_internal_originalTime": 1689784617000
    },
    {
        "time": 1689784618000,
        "value": 139.665,
        "_internal_originalTime": 1689784618000
    },
    {
        "time": 1689784619000,
        "value": 139.664,
        "_internal_originalTime": 1689784619000
    },
    {
        "time": 1689784620000,
        "value": 139.665,
        "_internal_originalTime": 1689784620000
    },
    {
        "time": 1689784622000,
        "value": 139.667,
        "_internal_originalTime": 1689784622000
    },
    {
        "time": 1689784623000,
        "value": 139.665,
        "_internal_originalTime": 1689784623000
    },
    {
        "time": 1689784624000,
        "value": 139.664,
        "_internal_originalTime": 1689784624000
    },
    {
        "time": 1689784625000,
        "value": 139.664,
        "_internal_originalTime": 1689784625000
    },
    {
        "time": 1689784627000,
        "value": 139.666,
        "_internal_originalTime": 1689784627000
    },
    {
        "time": 1689784628000,
        "value": 139.663,
        "_internal_originalTime": 1689784628000
    },
    {
        "time": 1689784630000,
        "value": 139.662,
        "_internal_originalTime": 1689784630000
    },
    {
        "time": 1689784631000,
        "value": 139.657,
        "_internal_originalTime": 1689784631000
    },
    {
        "time": 1689784632000,
        "value": 139.659,
        "_internal_originalTime": 1689784632000
    },
    {
        "time": 1689784633000,
        "value": 139.65,
        "_internal_originalTime": 1689784633000
    },
    {
        "time": 1689784634000,
        "value": 139.65,
        "_internal_originalTime": 1689784634000
    },
    {
        "time": 1689784635000,
        "value": 139.649,
        "_internal_originalTime": 1689784635000
    },
    {
        "time": 1689784636000,
        "value": 139.657,
        "_internal_originalTime": 1689784636000
    },
    {
        "time": 1689784637000,
        "value": 139.657,
        "_internal_originalTime": 1689784637000
    },
    {
        "time": 1689784639000,
        "value": 139.652,
        "_internal_originalTime": 1689784639000
    },
    {
        "time": 1689784640000,
        "value": 139.65,
        "_internal_originalTime": 1689784640000
    },
    {
        "time": 1689784641000,
        "value": 139.655,
        "_internal_originalTime": 1689784641000
    },
    {
        "time": 1689784642000,
        "value": 139.656,
        "_internal_originalTime": 1689784642000
    },
    {
        "time": 1689784643000,
        "value": 139.653,
        "_internal_originalTime": 1689784643000
    },
    {
        "time": 1689784644000,
        "value": 139.656,
        "_internal_originalTime": 1689784644000
    },
    {
        "time": 1689784645000,
        "value": 139.656,
        "_internal_originalTime": 1689784645000
    },
    {
        "time": 1689784647000,
        "value": 139.654,
        "_internal_originalTime": 1689784647000
    },
    {
        "time": 1689784648000,
        "value": 139.655,
        "_internal_originalTime": 1689784648000
    },
    {
        "time": 1689784649000,
        "value": 139.655,
        "_internal_originalTime": 1689784649000
    },
    {
        "time": 1689784652000,
        "value": 139.656,
        "_internal_originalTime": 1689784652000
    },
    {
        "time": 1689784654000,
        "value": 139.656,
        "_internal_originalTime": 1689784654000
    },
    {
        "time": 1689784655000,
        "value": 139.655,
        "_internal_originalTime": 1689784655000
    },
    {
        "time": 1689784657000,
        "value": 139.654,
        "_internal_originalTime": 1689784657000
    },
    {
        "time": 1689784658000,
        "value": 139.649,
        "_internal_originalTime": 1689784658000
    },
    {
        "time": 1689784660000,
        "value": 139.65,
        "_internal_originalTime": 1689784660000
    },
    {
        "time": 1689784661000,
        "value": 139.65,
        "_internal_originalTime": 1689784661000
    },
    {
        "time": 1689784663000,
        "value": 139.653,
        "_internal_originalTime": 1689784663000
    },
    {
        "time": 1689784664000,
        "value": 139.651,
        "_internal_originalTime": 1689784664000
    },
    {
        "time": 1689784665000,
        "value": 139.649,
        "_internal_originalTime": 1689784665000
    },
    {
        "time": 1689784667000,
        "value": 139.653,
        "_internal_originalTime": 1689784667000
    },
    {
        "time": 1689784671000,
        "value": 139.654,
        "_internal_originalTime": 1689784671000
    },
    {
        "time": 1689784680000,
        "value": 139.655,
        "_internal_originalTime": 1689784680000
    },
    {
        "time": 1689784681000,
        "value": 139.653,
        "_internal_originalTime": 1689784681000
    },
    {
        "time": 1689784684000,
        "value": 139.654,
        "_internal_originalTime": 1689784684000
    },
    {
        "time": 1689784685000,
        "value": 139.65,
        "_internal_originalTime": 1689784685000
    },
    {
        "time": 1689784688000,
        "value": 139.646,
        "_internal_originalTime": 1689784688000
    },
    {
        "time": 1689784689000,
        "value": 139.645,
        "_internal_originalTime": 1689784689000
    },
    {
        "time": 1689784690000,
        "value": 139.645,
        "_internal_originalTime": 1689784690000
    },
    {
        "time": 1689784691000,
        "value": 139.649,
        "_internal_originalTime": 1689784691000
    },
    {
        "time": 1689784692000,
        "value": 139.65,
        "_internal_originalTime": 1689784692000
    },
    {
        "time": 1689784694000,
        "value": 139.654,
        "_internal_originalTime": 1689784694000
    },
    {
        "time": 1689784695000,
        "value": 139.646,
        "_internal_originalTime": 1689784695000
    },
    {
        "time": 1689784696000,
        "value": 139.645,
        "_internal_originalTime": 1689784696000
    },
    {
        "time": 1689784697000,
        "value": 139.65,
        "_internal_originalTime": 1689784697000
    },
    {
        "time": 1689784704000,
        "value": 139.654,
        "_internal_originalTime": 1689784704000
    },
    {
        "time": 1689784705000,
        "value": 139.654,
        "_internal_originalTime": 1689784705000
    },
    {
        "time": 1689784718000,
        "value": 139.649,
        "_internal_originalTime": 1689784718000
    },
    {
        "time": 1689784719000,
        "value": 139.65,
        "_internal_originalTime": 1689784719000
    },
    {
        "time": 1689784720000,
        "value": 139.65,
        "_internal_originalTime": 1689784720000
    },
    {
        "time": 1689784721000,
        "value": 139.65,
        "_internal_originalTime": 1689784721000
    },
    {
        "time": 1689784722000,
        "value": 139.649,
        "_internal_originalTime": 1689784722000
    },
    {
        "time": 1689784724000,
        "value": 139.649,
        "_internal_originalTime": 1689784724000
    },
    {
        "time": 1689784726000,
        "value": 139.649,
        "_internal_originalTime": 1689784726000
    },
    {
        "time": 1689784728000,
        "value": 139.648,
        "_internal_originalTime": 1689784728000
    },
    {
        "time": 1689784729000,
        "value": 139.645,
        "_internal_originalTime": 1689784729000
    },
    {
        "time": 1689784730000,
        "value": 139.649,
        "_internal_originalTime": 1689784730000
    },
    {
        "time": 1689784733000,
        "value": 139.645,
        "_internal_originalTime": 1689784733000
    },
    {
        "time": 1689784735000,
        "value": 139.648,
        "_internal_originalTime": 1689784735000
    },
    {
        "time": 1689784737000,
        "value": 139.648,
        "_internal_originalTime": 1689784737000
    },
    {
        "time": 1689784740000,
        "value": 139.649,
        "_internal_originalTime": 1689784740000
    },
    {
        "time": 1689784741000,
        "value": 139.65,
        "_internal_originalTime": 1689784741000
    },
    {
        "time": 1689784742000,
        "value": 139.647,
        "_internal_originalTime": 1689784742000
    },
    {
        "time": 1689784743000,
        "value": 139.65,
        "_internal_originalTime": 1689784743000
    },
    {
        "time": 1689784745000,
        "value": 139.652,
        "_internal_originalTime": 1689784745000
    },
    {
        "time": 1689784748000,
        "value": 139.647,
        "_internal_originalTime": 1689784748000
    },
    {
        "time": 1689784749000,
        "value": 139.649,
        "_internal_originalTime": 1689784749000
    },
    {
        "time": 1689784750000,
        "value": 139.651,
        "_internal_originalTime": 1689784750000
    },
    {
        "time": 1689784751000,
        "value": 139.646,
        "_internal_originalTime": 1689784751000
    },
    {
        "time": 1689784752000,
        "value": 139.649,
        "_internal_originalTime": 1689784752000
    },
    {
        "time": 1689784754000,
        "value": 139.652,
        "_internal_originalTime": 1689784754000
    },
    {
        "time": 1689784755000,
        "value": 139.646,
        "_internal_originalTime": 1689784755000
    },
    {
        "time": 1689784758000,
        "value": 139.652,
        "_internal_originalTime": 1689784758000
    },
    {
        "time": 1689784759000,
        "value": 139.642,
        "_internal_originalTime": 1689784759000
    },
    {
        "time": 1689784760000,
        "value": 139.646,
        "_internal_originalTime": 1689784760000
    },
    {
        "time": 1689784761000,
        "value": 139.646,
        "_internal_originalTime": 1689784761000
    },
    {
        "time": 1689784763000,
        "value": 139.648,
        "_internal_originalTime": 1689784763000
    },
    {
        "time": 1689784764000,
        "value": 139.642,
        "_internal_originalTime": 1689784764000
    },
    {
        "time": 1689784765000,
        "value": 139.642,
        "_internal_originalTime": 1689784765000
    },
    {
        "time": 1689784766000,
        "value": 139.646,
        "_internal_originalTime": 1689784766000
    },
    {
        "time": 1689784767000,
        "value": 139.642,
        "_internal_originalTime": 1689784767000
    },
    {
        "time": 1689784769000,
        "value": 139.647,
        "_internal_originalTime": 1689784769000
    },
    {
        "time": 1689784771000,
        "value": 139.647,
        "_internal_originalTime": 1689784771000
    },
    {
        "time": 1689784775000,
        "value": 139.646,
        "_internal_originalTime": 1689784775000
    },
    {
        "time": 1689784776000,
        "value": 139.646,
        "_internal_originalTime": 1689784776000
    },
    {
        "time": 1689784777000,
        "value": 139.646,
        "_internal_originalTime": 1689784777000
    },
    {
        "time": 1689784779000,
        "value": 139.645,
        "_internal_originalTime": 1689784779000
    },
    {
        "time": 1689784783000,
        "value": 139.646,
        "_internal_originalTime": 1689784783000
    },
    {
        "time": 1689784785000,
        "value": 139.641,
        "_internal_originalTime": 1689784785000
    },
    {
        "time": 1689784788000,
        "value": 139.646,
        "_internal_originalTime": 1689784788000
    },
    {
        "time": 1689784789000,
        "value": 139.642,
        "_internal_originalTime": 1689784789000
    },
    {
        "time": 1689784791000,
        "value": 139.641,
        "_internal_originalTime": 1689784791000
    },
    {
        "time": 1689784792000,
        "value": 139.642,
        "_internal_originalTime": 1689784792000
    },
    {
        "time": 1689784793000,
        "value": 139.641,
        "_internal_originalTime": 1689784793000
    },
    {
        "time": 1689784794000,
        "value": 139.642,
        "_internal_originalTime": 1689784794000
    },
    {
        "time": 1689784795000,
        "value": 139.646,
        "_internal_originalTime": 1689784795000
    },
    {
        "time": 1689784797000,
        "value": 139.641,
        "_internal_originalTime": 1689784797000
    },
    {
        "time": 1689784798000,
        "value": 139.642,
        "_internal_originalTime": 1689784798000
    },
    {
        "time": 1689784801000,
        "value": 139.646,
        "_internal_originalTime": 1689784801000
    },
    {
        "time": 1689784802000,
        "value": 139.646,
        "_internal_originalTime": 1689784802000
    },
    {
        "time": 1689784804000,
        "value": 139.644,
        "_internal_originalTime": 1689784804000
    },
    {
        "time": 1689784805000,
        "value": 139.639,
        "_internal_originalTime": 1689784805000
    },
    {
        "time": 1689784806000,
        "value": 139.635,
        "_internal_originalTime": 1689784806000
    },
    {
        "time": 1689784808000,
        "value": 139.638,
        "_internal_originalTime": 1689784808000
    },
    {
        "time": 1689784809000,
        "value": 139.638,
        "_internal_originalTime": 1689784809000
    },
    {
        "time": 1689784810000,
        "value": 139.636,
        "_internal_originalTime": 1689784810000
    },
    {
        "time": 1689784811000,
        "value": 139.635,
        "_internal_originalTime": 1689784811000
    },
    {
        "time": 1689784813000,
        "value": 139.636,
        "_internal_originalTime": 1689784813000
    },
    {
        "time": 1689784815000,
        "value": 139.63,
        "_internal_originalTime": 1689784815000
    },
    {
        "time": 1689784816000,
        "value": 139.632,
        "_internal_originalTime": 1689784816000
    },
    {
        "time": 1689784818000,
        "value": 139.635,
        "_internal_originalTime": 1689784818000
    },
    {
        "time": 1689784819000,
        "value": 139.633,
        "_internal_originalTime": 1689784819000
    },
    {
        "time": 1689784820000,
        "value": 139.636,
        "_internal_originalTime": 1689784820000
    },
    {
        "time": 1689784821000,
        "value": 139.633,
        "_internal_originalTime": 1689784821000
    },
    {
        "time": 1689784822000,
        "value": 139.637,
        "_internal_originalTime": 1689784822000
    },
    {
        "time": 1689784823000,
        "value": 139.636,
        "_internal_originalTime": 1689784823000
    },
    {
        "time": 1689784824000,
        "value": 139.636,
        "_internal_originalTime": 1689784824000
    },
    {
        "time": 1689784825000,
        "value": 139.636,
        "_internal_originalTime": 1689784825000
    },
    {
        "time": 1689784826000,
        "value": 139.635,
        "_internal_originalTime": 1689784826000
    },
    {
        "time": 1689784828000,
        "value": 139.636,
        "_internal_originalTime": 1689784828000
    },
    {
        "time": 1689784829000,
        "value": 139.636,
        "_internal_originalTime": 1689784829000
    },
    {
        "time": 1689784833000,
        "value": 139.636,
        "_internal_originalTime": 1689784833000
    },
    {
        "time": 1689784834000,
        "value": 139.633,
        "_internal_originalTime": 1689784834000
    },
    {
        "time": 1689784835000,
        "value": 139.63,
        "_internal_originalTime": 1689784835000
    },
    {
        "time": 1689784836000,
        "value": 139.611,
        "_internal_originalTime": 1689784836000
    },
    {
        "time": 1689784838000,
        "value": 139.614,
        "_internal_originalTime": 1689784838000
    },
    {
        "time": 1689784839000,
        "value": 139.614,
        "_internal_originalTime": 1689784839000
    },
    {
        "time": 1689784840000,
        "value": 139.613,
        "_internal_originalTime": 1689784840000
    },
    {
        "time": 1689784841000,
        "value": 139.612,
        "_internal_originalTime": 1689784841000
    },
    {
        "time": 1689784842000,
        "value": 139.615,
        "_internal_originalTime": 1689784842000
    },
    {
        "time": 1689784844000,
        "value": 139.613,
        "_internal_originalTime": 1689784844000
    },
    {
        "time": 1689784845000,
        "value": 139.612,
        "_internal_originalTime": 1689784845000
    },
    {
        "time": 1689784848000,
        "value": 139.616,
        "_internal_originalTime": 1689784848000
    },
    {
        "time": 1689784850000,
        "value": 139.617,
        "_internal_originalTime": 1689784850000
    },
    {
        "time": 1689784851000,
        "value": 139.617,
        "_internal_originalTime": 1689784851000
    },
    {
        "time": 1689784852000,
        "value": 139.612,
        "_internal_originalTime": 1689784852000
    },
    {
        "time": 1689784854000,
        "value": 139.616,
        "_internal_originalTime": 1689784854000
    },
    {
        "time": 1689784855000,
        "value": 139.616,
        "_internal_originalTime": 1689784855000
    },
    {
        "time": 1689784859000,
        "value": 139.612,
        "_internal_originalTime": 1689784859000
    },
    {
        "time": 1689784860000,
        "value": 139.611,
        "_internal_originalTime": 1689784860000
    },
    {
        "time": 1689784861000,
        "value": 139.612,
        "_internal_originalTime": 1689784861000
    },
    {
        "time": 1689784862000,
        "value": 139.605,
        "_internal_originalTime": 1689784862000
    },
    {
        "time": 1689784863000,
        "value": 139.604,
        "_internal_originalTime": 1689784863000
    },
    {
        "time": 1689784864000,
        "value": 139.605,
        "_internal_originalTime": 1689784864000
    },
    {
        "time": 1689784865000,
        "value": 139.604,
        "_internal_originalTime": 1689784865000
    },
    {
        "time": 1689784867000,
        "value": 139.604,
        "_internal_originalTime": 1689784867000
    },
    {
        "time": 1689784868000,
        "value": 139.607,
        "_internal_originalTime": 1689784868000
    },
    {
        "time": 1689784869000,
        "value": 139.609,
        "_internal_originalTime": 1689784869000
    },
    {
        "time": 1689784871000,
        "value": 139.607,
        "_internal_originalTime": 1689784871000
    },
    {
        "time": 1689784872000,
        "value": 139.609,
        "_internal_originalTime": 1689784872000
    },
    {
        "time": 1689784873000,
        "value": 139.608,
        "_internal_originalTime": 1689784873000
    },
    {
        "time": 1689784874000,
        "value": 139.61,
        "_internal_originalTime": 1689784874000
    },
    {
        "time": 1689784875000,
        "value": 139.61,
        "_internal_originalTime": 1689784875000
    },
    {
        "time": 1689784876000,
        "value": 139.61,
        "_internal_originalTime": 1689784876000
    },
    {
        "time": 1689784877000,
        "value": 139.605,
        "_internal_originalTime": 1689784877000
    },
    {
        "time": 1689784878000,
        "value": 139.604,
        "_internal_originalTime": 1689784878000
    },
    {
        "time": 1689784879000,
        "value": 139.609,
        "_internal_originalTime": 1689784879000
    },
    {
        "time": 1689784881000,
        "value": 139.604,
        "_internal_originalTime": 1689784881000
    },
    {
        "time": 1689784882000,
        "value": 139.598,
        "_internal_originalTime": 1689784882000
    },
    {
        "time": 1689784883000,
        "value": 139.601,
        "_internal_originalTime": 1689784883000
    },
    {
        "time": 1689784884000,
        "value": 139.6,
        "_internal_originalTime": 1689784884000
    },
    {
        "time": 1689784885000,
        "value": 139.601,
        "_internal_originalTime": 1689784885000
    },
    {
        "time": 1689784887000,
        "value": 139.599,
        "_internal_originalTime": 1689784887000
    },
    {
        "time": 1689784888000,
        "value": 139.6,
        "_internal_originalTime": 1689784888000
    },
    {
        "time": 1689784889000,
        "value": 139.601,
        "_internal_originalTime": 1689784889000
    },
    {
        "time": 1689784891000,
        "value": 139.602,
        "_internal_originalTime": 1689784891000
    },
    {
        "time": 1689784892000,
        "value": 139.599,
        "_internal_originalTime": 1689784892000
    },
    {
        "time": 1689784893000,
        "value": 139.599,
        "_internal_originalTime": 1689784893000
    },
    {
        "time": 1689784894000,
        "value": 139.605,
        "_internal_originalTime": 1689784894000
    },
    {
        "time": 1689784895000,
        "value": 139.605,
        "_internal_originalTime": 1689784895000
    },
    {
        "time": 1689784897000,
        "value": 139.604,
        "_internal_originalTime": 1689784897000
    },
    {
        "time": 1689784899000,
        "value": 139.602,
        "_internal_originalTime": 1689784899000
    },
    {
        "time": 1689784900000,
        "value": 139.6,
        "_internal_originalTime": 1689784900000
    },
    {
        "time": 1689784901000,
        "value": 139.603,
        "_internal_originalTime": 1689784901000
    },
    {
        "time": 1689784902000,
        "value": 139.603,
        "_internal_originalTime": 1689784902000
    },
    {
        "time": 1689784905000,
        "value": 139.605,
        "_internal_originalTime": 1689784905000
    },
    {
        "time": 1689784907000,
        "value": 139.6,
        "_internal_originalTime": 1689784907000
    },
    {
        "time": 1689784908000,
        "value": 139.599,
        "_internal_originalTime": 1689784908000
    },
    {
        "time": 1689784909000,
        "value": 139.603,
        "_internal_originalTime": 1689784909000
    },
    {
        "time": 1689784911000,
        "value": 139.611,
        "_internal_originalTime": 1689784911000
    },
    {
        "time": 1689784912000,
        "value": 139.609,
        "_internal_originalTime": 1689784912000
    },
    {
        "time": 1689784913000,
        "value": 139.611,
        "_internal_originalTime": 1689784913000
    },
    {
        "time": 1689784915000,
        "value": 139.606,
        "_internal_originalTime": 1689784915000
    },
    {
        "time": 1689784916000,
        "value": 139.611,
        "_internal_originalTime": 1689784916000
    },
    {
        "time": 1689784918000,
        "value": 139.612,
        "_internal_originalTime": 1689784918000
    },
    {
        "time": 1689784919000,
        "value": 139.611,
        "_internal_originalTime": 1689784919000
    },
    {
        "time": 1689784920000,
        "value": 139.615,
        "_internal_originalTime": 1689784920000
    },
    {
        "time": 1689784921000,
        "value": 139.616,
        "_internal_originalTime": 1689784921000
    },
    {
        "time": 1689784923000,
        "value": 139.608,
        "_internal_originalTime": 1689784923000
    },
    {
        "time": 1689784924000,
        "value": 139.614,
        "_internal_originalTime": 1689784924000
    },
    {
        "time": 1689784925000,
        "value": 139.613,
        "_internal_originalTime": 1689784925000
    },
    {
        "time": 1689784926000,
        "value": 139.616,
        "_internal_originalTime": 1689784926000
    },
    {
        "time": 1689784927000,
        "value": 139.617,
        "_internal_originalTime": 1689784927000
    },
    {
        "time": 1689784928000,
        "value": 139.616,
        "_internal_originalTime": 1689784928000
    },
    {
        "time": 1689784929000,
        "value": 139.616,
        "_internal_originalTime": 1689784929000
    },
    {
        "time": 1689784931000,
        "value": 139.62,
        "_internal_originalTime": 1689784931000
    },
    {
        "time": 1689784932000,
        "value": 139.617,
        "_internal_originalTime": 1689784932000
    },
    {
        "time": 1689784934000,
        "value": 139.615,
        "_internal_originalTime": 1689784934000
    },
    {
        "time": 1689784935000,
        "value": 139.61,
        "_internal_originalTime": 1689784935000
    },
    {
        "time": 1689784936000,
        "value": 139.615,
        "_internal_originalTime": 1689784936000
    },
    {
        "time": 1689784937000,
        "value": 139.615,
        "_internal_originalTime": 1689784937000
    },
    {
        "time": 1689784938000,
        "value": 139.615,
        "_internal_originalTime": 1689784938000
    },
    {
        "time": 1689784940000,
        "value": 139.615,
        "_internal_originalTime": 1689784940000
    },
    {
        "time": 1689784941000,
        "value": 139.615,
        "_internal_originalTime": 1689784941000
    },
    {
        "time": 1689784942000,
        "value": 139.618,
        "_internal_originalTime": 1689784942000
    },
    {
        "time": 1689784944000,
        "value": 139.619,
        "_internal_originalTime": 1689784944000
    },
    {
        "time": 1689784945000,
        "value": 139.612,
        "_internal_originalTime": 1689784945000
    },
    {
        "time": 1689784946000,
        "value": 139.608,
        "_internal_originalTime": 1689784946000
    },
    {
        "time": 1689784947000,
        "value": 139.605,
        "_internal_originalTime": 1689784947000
    },
    {
        "time": 1689784948000,
        "value": 139.604,
        "_internal_originalTime": 1689784948000
    },
    {
        "time": 1689784950000,
        "value": 139.608,
        "_internal_originalTime": 1689784950000
    },
    {
        "time": 1689784954000,
        "value": 139.602,
        "_internal_originalTime": 1689784954000
    },
    {
        "time": 1689784955000,
        "value": 139.605,
        "_internal_originalTime": 1689784955000
    },
    {
        "time": 1689784956000,
        "value": 139.606,
        "_internal_originalTime": 1689784956000
    },
    {
        "time": 1689784957000,
        "value": 139.6,
        "_internal_originalTime": 1689784957000
    },
    {
        "time": 1689784958000,
        "value": 139.601,
        "_internal_originalTime": 1689784958000
    },
    {
        "time": 1689784960000,
        "value": 139.599,
        "_internal_originalTime": 1689784960000
    },
    {
        "time": 1689784962000,
        "value": 139.602,
        "_internal_originalTime": 1689784962000
    },
    {
        "time": 1689784963000,
        "value": 139.6,
        "_internal_originalTime": 1689784963000
    },
    {
        "time": 1689784965000,
        "value": 139.598,
        "_internal_originalTime": 1689784965000
    },
    {
        "time": 1689784968000,
        "value": 139.601,
        "_internal_originalTime": 1689784968000
    },
    {
        "time": 1689784969000,
        "value": 139.6,
        "_internal_originalTime": 1689784969000
    },
    {
        "time": 1689784970000,
        "value": 139.601,
        "_internal_originalTime": 1689784970000
    },
    {
        "time": 1689784971000,
        "value": 139.596,
        "_internal_originalTime": 1689784971000
    },
    {
        "time": 1689784973000,
        "value": 139.602,
        "_internal_originalTime": 1689784973000
    },
    {
        "time": 1689784975000,
        "value": 139.596,
        "_internal_originalTime": 1689784975000
    },
    {
        "time": 1689784976000,
        "value": 139.59,
        "_internal_originalTime": 1689784976000
    },
    {
        "time": 1689784977000,
        "value": 139.594,
        "_internal_originalTime": 1689784977000
    },
    {
        "time": 1689784978000,
        "value": 139.595,
        "_internal_originalTime": 1689784978000
    },
    {
        "time": 1689784979000,
        "value": 139.595,
        "_internal_originalTime": 1689784979000
    },
    {
        "time": 1689784980000,
        "value": 139.595,
        "_internal_originalTime": 1689784980000
    },
    {
        "time": 1689784981000,
        "value": 139.592,
        "_internal_originalTime": 1689784981000
    },
    {
        "time": 1689784982000,
        "value": 139.592,
        "_internal_originalTime": 1689784982000
    },
    {
        "time": 1689784983000,
        "value": 139.595,
        "_internal_originalTime": 1689784983000
    },
    {
        "time": 1689784986000,
        "value": 139.592,
        "_internal_originalTime": 1689784986000
    },
    {
        "time": 1689784988000,
        "value": 139.592,
        "_internal_originalTime": 1689784988000
    },
    {
        "time": 1689784990000,
        "value": 139.589,
        "_internal_originalTime": 1689784990000
    },
    {
        "time": 1689784991000,
        "value": 139.59,
        "_internal_originalTime": 1689784991000
    },
    {
        "time": 1689784992000,
        "value": 139.587,
        "_internal_originalTime": 1689784992000
    },
    {
        "time": 1689784994000,
        "value": 139.586,
        "_internal_originalTime": 1689784994000
    },
    {
        "time": 1689784995000,
        "value": 139.587,
        "_internal_originalTime": 1689784995000
    },
    {
        "time": 1689784996000,
        "value": 139.59,
        "_internal_originalTime": 1689784996000
    },
    {
        "time": 1689785000000,
        "value": 139.591,
        "_internal_originalTime": 1689785000000
    },
    {
        "time": 1689785003000,
        "value": 139.597,
        "_internal_originalTime": 1689785003000
    },
    {
        "time": 1689785004000,
        "value": 139.599,
        "_internal_originalTime": 1689785004000
    },
    {
        "time": 1689785006000,
        "value": 139.598,
        "_internal_originalTime": 1689785006000
    },
    {
        "time": 1689785008000,
        "value": 139.599,
        "_internal_originalTime": 1689785008000
    },
    {
        "time": 1689785009000,
        "value": 139.603,
        "_internal_originalTime": 1689785009000
    },
    {
        "time": 1689785011000,
        "value": 139.603,
        "_internal_originalTime": 1689785011000
    },
    {
        "time": 1689785012000,
        "value": 139.6,
        "_internal_originalTime": 1689785012000
    },
    {
        "time": 1689785014000,
        "value": 139.594,
        "_internal_originalTime": 1689785014000
    },
    {
        "time": 1689785015000,
        "value": 139.594,
        "_internal_originalTime": 1689785015000
    },
    {
        "time": 1689785016000,
        "value": 139.591,
        "_internal_originalTime": 1689785016000
    },
    {
        "time": 1689785018000,
        "value": 139.593,
        "_internal_originalTime": 1689785018000
    },
    {
        "time": 1689785019000,
        "value": 139.592,
        "_internal_originalTime": 1689785019000
    },
    {
        "time": 1689785020000,
        "value": 139.592,
        "_internal_originalTime": 1689785020000
    },
    {
        "time": 1689785023000,
        "value": 139.589,
        "_internal_originalTime": 1689785023000
    },
    {
        "time": 1689785024000,
        "value": 139.592,
        "_internal_originalTime": 1689785024000
    },
    {
        "time": 1689785026000,
        "value": 139.59,
        "_internal_originalTime": 1689785026000
    },
    {
        "time": 1689785028000,
        "value": 139.595,
        "_internal_originalTime": 1689785028000
    },
    {
        "time": 1689785032000,
        "value": 139.591,
        "_internal_originalTime": 1689785032000
    },
    {
        "time": 1689785033000,
        "value": 139.593,
        "_internal_originalTime": 1689785033000
    },
    {
        "time": 1689785037000,
        "value": 139.584,
        "_internal_originalTime": 1689785037000
    },
    {
        "time": 1689785038000,
        "value": 139.59,
        "_internal_originalTime": 1689785038000
    },
    {
        "time": 1689785039000,
        "value": 139.584,
        "_internal_originalTime": 1689785039000
    },
    {
        "time": 1689785040000,
        "value": 139.59,
        "_internal_originalTime": 1689785040000
    },
    {
        "time": 1689785041000,
        "value": 139.583,
        "_internal_originalTime": 1689785041000
    },
    {
        "time": 1689785042000,
        "value": 139.58,
        "_internal_originalTime": 1689785042000
    },
    {
        "time": 1689785045000,
        "value": 139.578,
        "_internal_originalTime": 1689785045000
    },
    {
        "time": 1689785046000,
        "value": 139.58,
        "_internal_originalTime": 1689785046000
    },
    {
        "time": 1689785048000,
        "value": 139.582,
        "_internal_originalTime": 1689785048000
    },
    {
        "time": 1689785050000,
        "value": 139.587,
        "_internal_originalTime": 1689785050000
    },
    {
        "time": 1689785051000,
        "value": 139.584,
        "_internal_originalTime": 1689785051000
    },
    {
        "time": 1689785052000,
        "value": 139.59,
        "_internal_originalTime": 1689785052000
    },
    {
        "time": 1689785053000,
        "value": 139.588,
        "_internal_originalTime": 1689785053000
    },
    {
        "time": 1689785055000,
        "value": 139.586,
        "_internal_originalTime": 1689785055000
    },
    {
        "time": 1689785060000,
        "value": 139.587,
        "_internal_originalTime": 1689785060000
    },
    {
        "time": 1689785061000,
        "value": 139.588,
        "_internal_originalTime": 1689785061000
    },
    {
        "time": 1689785063000,
        "value": 139.592,
        "_internal_originalTime": 1689785063000
    },
    {
        "time": 1689785065000,
        "value": 139.583,
        "_internal_originalTime": 1689785065000
    },
    {
        "time": 1689785066000,
        "value": 139.583,
        "_internal_originalTime": 1689785066000
    },
    {
        "time": 1689785067000,
        "value": 139.581,
        "_internal_originalTime": 1689785067000
    },
    {
        "time": 1689785068000,
        "value": 139.576,
        "_internal_originalTime": 1689785068000
    },
    {
        "time": 1689785069000,
        "value": 139.577,
        "_internal_originalTime": 1689785069000
    },
    {
        "time": 1689785071000,
        "value": 139.582,
        "_internal_originalTime": 1689785071000
    },
    {
        "time": 1689785072000,
        "value": 139.581,
        "_internal_originalTime": 1689785072000
    },
    {
        "time": 1689785074000,
        "value": 139.578,
        "_internal_originalTime": 1689785074000
    },
    {
        "time": 1689785076000,
        "value": 139.582,
        "_internal_originalTime": 1689785076000
    },
    {
        "time": 1689785077000,
        "value": 139.578,
        "_internal_originalTime": 1689785077000
    },
    {
        "time": 1689785079000,
        "value": 139.58,
        "_internal_originalTime": 1689785079000
    },
    {
        "time": 1689785082000,
        "value": 139.577,
        "_internal_originalTime": 1689785082000
    },
    {
        "time": 1689785083000,
        "value": 139.579,
        "_internal_originalTime": 1689785083000
    },
    {
        "time": 1689785085000,
        "value": 139.579,
        "_internal_originalTime": 1689785085000
    },
    {
        "time": 1689785087000,
        "value": 139.575,
        "_internal_originalTime": 1689785087000
    },
    {
        "time": 1689785089000,
        "value": 139.579,
        "_internal_originalTime": 1689785089000
    },
    {
        "time": 1689785090000,
        "value": 139.579,
        "_internal_originalTime": 1689785090000
    },
    {
        "time": 1689785092000,
        "value": 139.579,
        "_internal_originalTime": 1689785092000
    },
    {
        "time": 1689785093000,
        "value": 139.579,
        "_internal_originalTime": 1689785093000
    },
    {
        "time": 1689785094000,
        "value": 139.575,
        "_internal_originalTime": 1689785094000
    },
    {
        "time": 1689785096000,
        "value": 139.578,
        "_internal_originalTime": 1689785096000
    },
    {
        "time": 1689785100000,
        "value": 139.579,
        "_internal_originalTime": 1689785100000
    },
    {
        "time": 1689785101000,
        "value": 139.575,
        "_internal_originalTime": 1689785101000
    },
    {
        "time": 1689785102000,
        "value": 139.575,
        "_internal_originalTime": 1689785102000
    },
    {
        "time": 1689785103000,
        "value": 139.577,
        "_internal_originalTime": 1689785103000
    },
    {
        "time": 1689785104000,
        "value": 139.576,
        "_internal_originalTime": 1689785104000
    },
    {
        "time": 1689785105000,
        "value": 139.577,
        "_internal_originalTime": 1689785105000
    },
    {
        "time": 1689785108000,
        "value": 139.577,
        "_internal_originalTime": 1689785108000
    },
    {
        "time": 1689785109000,
        "value": 139.579,
        "_internal_originalTime": 1689785109000
    },
    {
        "time": 1689785110000,
        "value": 139.58,
        "_internal_originalTime": 1689785110000
    },
    {
        "time": 1689785111000,
        "value": 139.58,
        "_internal_originalTime": 1689785111000
    },
    {
        "time": 1689785113000,
        "value": 139.578,
        "_internal_originalTime": 1689785113000
    },
    {
        "time": 1689785115000,
        "value": 139.574,
        "_internal_originalTime": 1689785115000
    },
    {
        "time": 1689785116000,
        "value": 139.577,
        "_internal_originalTime": 1689785116000
    },
    {
        "time": 1689785117000,
        "value": 139.575,
        "_internal_originalTime": 1689785117000
    },
    {
        "time": 1689785118000,
        "value": 139.579,
        "_internal_originalTime": 1689785118000
    },
    {
        "time": 1689785120000,
        "value": 139.577,
        "_internal_originalTime": 1689785120000
    },
    {
        "time": 1689785121000,
        "value": 139.58,
        "_internal_originalTime": 1689785121000
    },
    {
        "time": 1689785122000,
        "value": 139.579,
        "_internal_originalTime": 1689785122000
    },
    {
        "time": 1689785123000,
        "value": 139.579,
        "_internal_originalTime": 1689785123000
    },
    {
        "time": 1689785124000,
        "value": 139.577,
        "_internal_originalTime": 1689785124000
    },
    {
        "time": 1689785125000,
        "value": 139.58,
        "_internal_originalTime": 1689785125000
    },
    {
        "time": 1689785127000,
        "value": 139.579,
        "_internal_originalTime": 1689785127000
    },
    {
        "time": 1689785128000,
        "value": 139.58,
        "_internal_originalTime": 1689785128000
    },
    {
        "time": 1689785130000,
        "value": 139.579,
        "_internal_originalTime": 1689785130000
    },
    {
        "time": 1689785132000,
        "value": 139.574,
        "_internal_originalTime": 1689785132000
    },
    {
        "time": 1689785133000,
        "value": 139.575,
        "_internal_originalTime": 1689785133000
    },
    {
        "time": 1689785134000,
        "value": 139.579,
        "_internal_originalTime": 1689785134000
    },
    {
        "time": 1689785136000,
        "value": 139.579,
        "_internal_originalTime": 1689785136000
    },
    {
        "time": 1689785137000,
        "value": 139.578,
        "_internal_originalTime": 1689785137000
    },
    {
        "time": 1689785138000,
        "value": 139.576,
        "_internal_originalTime": 1689785138000
    },
    {
        "time": 1689785139000,
        "value": 139.576,
        "_internal_originalTime": 1689785139000
    },
    {
        "time": 1689785141000,
        "value": 139.576,
        "_internal_originalTime": 1689785141000
    },
    {
        "time": 1689785142000,
        "value": 139.576,
        "_internal_originalTime": 1689785142000
    },
    {
        "time": 1689785144000,
        "value": 139.58,
        "_internal_originalTime": 1689785144000
    },
    {
        "time": 1689785145000,
        "value": 139.576,
        "_internal_originalTime": 1689785145000
    },
    {
        "time": 1689785148000,
        "value": 139.58,
        "_internal_originalTime": 1689785148000
    },
    {
        "time": 1689785149000,
        "value": 139.577,
        "_internal_originalTime": 1689785149000
    },
    {
        "time": 1689785150000,
        "value": 139.577,
        "_internal_originalTime": 1689785150000
    },
    {
        "time": 1689785151000,
        "value": 139.576,
        "_internal_originalTime": 1689785151000
    },
    {
        "time": 1689785152000,
        "value": 139.579,
        "_internal_originalTime": 1689785152000
    },
    {
        "time": 1689785154000,
        "value": 139.577,
        "_internal_originalTime": 1689785154000
    },
    {
        "time": 1689785155000,
        "value": 139.579,
        "_internal_originalTime": 1689785155000
    },
    {
        "time": 1689785156000,
        "value": 139.579,
        "_internal_originalTime": 1689785156000
    },
    {
        "time": 1689785157000,
        "value": 139.579,
        "_internal_originalTime": 1689785157000
    },
    {
        "time": 1689785158000,
        "value": 139.574,
        "_internal_originalTime": 1689785158000
    },
    {
        "time": 1689785160000,
        "value": 139.577,
        "_internal_originalTime": 1689785160000
    },
    {
        "time": 1689785162000,
        "value": 139.58,
        "_internal_originalTime": 1689785162000
    },
    {
        "time": 1689785164000,
        "value": 139.579,
        "_internal_originalTime": 1689785164000
    },
    {
        "time": 1689785165000,
        "value": 139.579,
        "_internal_originalTime": 1689785165000
    },
    {
        "time": 1689785166000,
        "value": 139.579,
        "_internal_originalTime": 1689785166000
    },
    {
        "time": 1689785167000,
        "value": 139.577,
        "_internal_originalTime": 1689785167000
    },
    {
        "time": 1689785169000,
        "value": 139.578,
        "_internal_originalTime": 1689785169000
    },
    {
        "time": 1689785170000,
        "value": 139.578,
        "_internal_originalTime": 1689785170000
    },
    {
        "time": 1689785171000,
        "value": 139.579,
        "_internal_originalTime": 1689785171000
    },
    {
        "time": 1689785173000,
        "value": 139.578,
        "_internal_originalTime": 1689785173000
    },
    {
        "time": 1689785174000,
        "value": 139.578,
        "_internal_originalTime": 1689785174000
    },
    {
        "time": 1689785175000,
        "value": 139.575,
        "_internal_originalTime": 1689785175000
    },
    {
        "time": 1689785177000,
        "value": 139.576,
        "_internal_originalTime": 1689785177000
    },
    {
        "time": 1689785179000,
        "value": 139.578,
        "_internal_originalTime": 1689785179000
    },
    {
        "time": 1689785180000,
        "value": 139.577,
        "_internal_originalTime": 1689785180000
    },
    {
        "time": 1689785182000,
        "value": 139.579,
        "_internal_originalTime": 1689785182000
    },
    {
        "time": 1689785183000,
        "value": 139.58,
        "_internal_originalTime": 1689785183000
    },
    {
        "time": 1689785184000,
        "value": 139.576,
        "_internal_originalTime": 1689785184000
    },
    {
        "time": 1689785185000,
        "value": 139.579,
        "_internal_originalTime": 1689785185000
    },
    {
        "time": 1689785197000,
        "value": 139.58,
        "_internal_originalTime": 1689785197000
    },
    {
        "time": 1689785198000,
        "value": 139.57,
        "_internal_originalTime": 1689785198000
    },
    {
        "time": 1689785205000,
        "value": 139.568,
        "_internal_originalTime": 1689785205000
    },
    {
        "time": 1689785207000,
        "value": 139.566,
        "_internal_originalTime": 1689785207000
    },
    {
        "time": 1689785208000,
        "value": 139.564,
        "_internal_originalTime": 1689785208000
    },
    {
        "time": 1689785209000,
        "value": 139.569,
        "_internal_originalTime": 1689785209000
    },
    {
        "time": 1689785212000,
        "value": 139.571,
        "_internal_originalTime": 1689785212000
    },
    {
        "time": 1689785214000,
        "value": 139.567,
        "_internal_originalTime": 1689785214000
    },
    {
        "time": 1689785216000,
        "value": 139.563,
        "_internal_originalTime": 1689785216000
    },
    {
        "time": 1689785217000,
        "value": 139.558,
        "_internal_originalTime": 1689785217000
    },
    {
        "time": 1689785220000,
        "value": 139.561,
        "_internal_originalTime": 1689785220000
    },
    {
        "time": 1689785222000,
        "value": 139.561,
        "_internal_originalTime": 1689785222000
    },
    {
        "time": 1689785228000,
        "value": 139.561,
        "_internal_originalTime": 1689785228000
    },
    {
        "time": 1689785229000,
        "value": 139.559,
        "_internal_originalTime": 1689785229000
    },
    {
        "time": 1689785230000,
        "value": 139.558,
        "_internal_originalTime": 1689785230000
    },
    {
        "time": 1689785231000,
        "value": 139.548,
        "_internal_originalTime": 1689785231000
    },
    {
        "time": 1689785232000,
        "value": 139.549,
        "_internal_originalTime": 1689785232000
    },
    {
        "time": 1689785234000,
        "value": 139.548,
        "_internal_originalTime": 1689785234000
    },
    {
        "time": 1689785235000,
        "value": 139.542,
        "_internal_originalTime": 1689785235000
    },
    {
        "time": 1689785237000,
        "value": 139.544,
        "_internal_originalTime": 1689785237000
    },
    {
        "time": 1689785238000,
        "value": 139.545,
        "_internal_originalTime": 1689785238000
    },
    {
        "time": 1689785239000,
        "value": 139.546,
        "_internal_originalTime": 1689785239000
    },
    {
        "time": 1689785240000,
        "value": 139.543,
        "_internal_originalTime": 1689785240000
    },
    {
        "time": 1689785241000,
        "value": 139.544,
        "_internal_originalTime": 1689785241000
    },
    {
        "time": 1689785242000,
        "value": 139.541,
        "_internal_originalTime": 1689785242000
    },
    {
        "time": 1689785244000,
        "value": 139.543,
        "_internal_originalTime": 1689785244000
    },
    {
        "time": 1689785245000,
        "value": 139.548,
        "_internal_originalTime": 1689785245000
    },
    {
        "time": 1689785246000,
        "value": 139.547,
        "_internal_originalTime": 1689785246000
    },
    {
        "time": 1689785247000,
        "value": 139.549,
        "_internal_originalTime": 1689785247000
    },
    {
        "time": 1689785249000,
        "value": 139.549,
        "_internal_originalTime": 1689785249000
    },
    {
        "time": 1689785250000,
        "value": 139.54,
        "_internal_originalTime": 1689785250000
    },
    {
        "time": 1689785251000,
        "value": 139.541,
        "_internal_originalTime": 1689785251000
    },
    {
        "time": 1689785253000,
        "value": 139.538,
        "_internal_originalTime": 1689785253000
    },
    {
        "time": 1689785254000,
        "value": 139.538,
        "_internal_originalTime": 1689785254000
    },
    {
        "time": 1689785255000,
        "value": 139.53,
        "_internal_originalTime": 1689785255000
    },
    {
        "time": 1689785256000,
        "value": 139.534,
        "_internal_originalTime": 1689785256000
    },
    {
        "time": 1689785258000,
        "value": 139.534,
        "_internal_originalTime": 1689785258000
    },
    {
        "time": 1689785259000,
        "value": 139.533,
        "_internal_originalTime": 1689785259000
    },
    {
        "time": 1689785260000,
        "value": 139.534,
        "_internal_originalTime": 1689785260000
    },
    {
        "time": 1689785261000,
        "value": 139.535,
        "_internal_originalTime": 1689785261000
    },
    {
        "time": 1689785263000,
        "value": 139.539,
        "_internal_originalTime": 1689785263000
    },
    {
        "time": 1689785264000,
        "value": 139.539,
        "_internal_originalTime": 1689785264000
    },
    {
        "time": 1689785265000,
        "value": 139.535,
        "_internal_originalTime": 1689785265000
    },
    {
        "time": 1689785267000,
        "value": 139.536,
        "_internal_originalTime": 1689785267000
    },
    {
        "time": 1689785268000,
        "value": 139.539,
        "_internal_originalTime": 1689785268000
    },
    {
        "time": 1689785269000,
        "value": 139.541,
        "_internal_originalTime": 1689785269000
    },
    {
        "time": 1689785270000,
        "value": 139.536,
        "_internal_originalTime": 1689785270000
    },
    {
        "time": 1689785271000,
        "value": 139.539,
        "_internal_originalTime": 1689785271000
    },
    {
        "time": 1689785273000,
        "value": 139.536,
        "_internal_originalTime": 1689785273000
    },
    {
        "time": 1689785274000,
        "value": 139.54,
        "_internal_originalTime": 1689785274000
    },
    {
        "time": 1689785278000,
        "value": 139.536,
        "_internal_originalTime": 1689785278000
    },
    {
        "time": 1689785282000,
        "value": 139.541,
        "_internal_originalTime": 1689785282000
    },
    {
        "time": 1689785284000,
        "value": 139.539,
        "_internal_originalTime": 1689785284000
    },
    {
        "time": 1689785285000,
        "value": 139.541,
        "_internal_originalTime": 1689785285000
    },
    {
        "time": 1689785287000,
        "value": 139.533,
        "_internal_originalTime": 1689785287000
    },
    {
        "time": 1689785288000,
        "value": 139.53,
        "_internal_originalTime": 1689785288000
    },
    {
        "time": 1689785289000,
        "value": 139.533,
        "_internal_originalTime": 1689785289000
    },
    {
        "time": 1689785290000,
        "value": 139.531,
        "_internal_originalTime": 1689785290000
    },
    {
        "time": 1689785291000,
        "value": 139.529,
        "_internal_originalTime": 1689785291000
    },
    {
        "time": 1689785293000,
        "value": 139.531,
        "_internal_originalTime": 1689785293000
    },
    {
        "time": 1689785295000,
        "value": 139.527,
        "_internal_originalTime": 1689785295000
    },
    {
        "time": 1689785297000,
        "value": 139.53,
        "_internal_originalTime": 1689785297000
    },
    {
        "time": 1689785299000,
        "value": 139.533,
        "_internal_originalTime": 1689785299000
    },
    {
        "time": 1689785300000,
        "value": 139.539,
        "_internal_originalTime": 1689785300000
    },
    {
        "time": 1689785302000,
        "value": 139.538,
        "_internal_originalTime": 1689785302000
    },
    {
        "time": 1689785303000,
        "value": 139.538,
        "_internal_originalTime": 1689785303000
    },
    {
        "time": 1689785304000,
        "value": 139.528,
        "_internal_originalTime": 1689785304000
    },
    {
        "time": 1689785305000,
        "value": 139.53,
        "_internal_originalTime": 1689785305000
    },
    {
        "time": 1689785307000,
        "value": 139.53,
        "_internal_originalTime": 1689785307000
    },
    {
        "time": 1689785308000,
        "value": 139.531,
        "_internal_originalTime": 1689785308000
    },
    {
        "time": 1689785310000,
        "value": 139.53,
        "_internal_originalTime": 1689785310000
    },
    {
        "time": 1689785311000,
        "value": 139.529,
        "_internal_originalTime": 1689785311000
    },
    {
        "time": 1689785312000,
        "value": 139.53,
        "_internal_originalTime": 1689785312000
    },
    {
        "time": 1689785313000,
        "value": 139.528,
        "_internal_originalTime": 1689785313000
    },
    {
        "time": 1689785315000,
        "value": 139.527,
        "_internal_originalTime": 1689785315000
    },
    {
        "time": 1689785316000,
        "value": 139.528,
        "_internal_originalTime": 1689785316000
    },
    {
        "time": 1689785317000,
        "value": 139.534,
        "_internal_originalTime": 1689785317000
    },
    {
        "time": 1689785318000,
        "value": 139.538,
        "_internal_originalTime": 1689785318000
    },
    {
        "time": 1689785322000,
        "value": 139.536,
        "_internal_originalTime": 1689785322000
    },
    {
        "time": 1689785323000,
        "value": 139.538,
        "_internal_originalTime": 1689785323000
    },
    {
        "time": 1689785324000,
        "value": 139.535,
        "_internal_originalTime": 1689785324000
    },
    {
        "time": 1689785325000,
        "value": 139.538,
        "_internal_originalTime": 1689785325000
    },
    {
        "time": 1689785330000,
        "value": 139.535,
        "_internal_originalTime": 1689785330000
    },
    {
        "time": 1689785331000,
        "value": 139.534,
        "_internal_originalTime": 1689785331000
    },
    {
        "time": 1689785332000,
        "value": 139.535,
        "_internal_originalTime": 1689785332000
    },
    {
        "time": 1689785333000,
        "value": 139.523,
        "_internal_originalTime": 1689785333000
    },
    {
        "time": 1689785334000,
        "value": 139.526,
        "_internal_originalTime": 1689785334000
    },
    {
        "time": 1689785335000,
        "value": 139.522,
        "_internal_originalTime": 1689785335000
    },
    {
        "time": 1689785338000,
        "value": 139.528,
        "_internal_originalTime": 1689785338000
    },
    {
        "time": 1689785339000,
        "value": 139.528,
        "_internal_originalTime": 1689785339000
    },
    {
        "time": 1689785340000,
        "value": 139.528,
        "_internal_originalTime": 1689785340000
    },
    {
        "time": 1689785342000,
        "value": 139.526,
        "_internal_originalTime": 1689785342000
    },
    {
        "time": 1689785343000,
        "value": 139.523,
        "_internal_originalTime": 1689785343000
    },
    {
        "time": 1689785344000,
        "value": 139.526,
        "_internal_originalTime": 1689785344000
    },
    {
        "time": 1689785345000,
        "value": 139.521,
        "_internal_originalTime": 1689785345000
    },
    {
        "time": 1689785347000,
        "value": 139.515,
        "_internal_originalTime": 1689785347000
    },
    {
        "time": 1689785348000,
        "value": 139.519,
        "_internal_originalTime": 1689785348000
    },
    {
        "time": 1689785349000,
        "value": 139.519,
        "_internal_originalTime": 1689785349000
    },
    {
        "time": 1689785350000,
        "value": 139.521,
        "_internal_originalTime": 1689785350000
    },
    {
        "time": 1689785351000,
        "value": 139.521,
        "_internal_originalTime": 1689785351000
    },
    {
        "time": 1689785352000,
        "value": 139.521,
        "_internal_originalTime": 1689785352000
    },
    {
        "time": 1689785353000,
        "value": 139.519,
        "_internal_originalTime": 1689785353000
    },
    {
        "time": 1689785355000,
        "value": 139.522,
        "_internal_originalTime": 1689785355000
    },
    {
        "time": 1689785357000,
        "value": 139.521,
        "_internal_originalTime": 1689785357000
    },
    {
        "time": 1689785358000,
        "value": 139.521,
        "_internal_originalTime": 1689785358000
    },
    {
        "time": 1689785360000,
        "value": 139.518,
        "_internal_originalTime": 1689785360000
    },
    {
        "time": 1689785361000,
        "value": 139.521,
        "_internal_originalTime": 1689785361000
    },
    {
        "time": 1689785362000,
        "value": 139.519,
        "_internal_originalTime": 1689785362000
    },
    {
        "time": 1689785363000,
        "value": 139.52,
        "_internal_originalTime": 1689785363000
    },
    {
        "time": 1689785364000,
        "value": 139.517,
        "_internal_originalTime": 1689785364000
    },
    {
        "time": 1689785366000,
        "value": 139.52,
        "_internal_originalTime": 1689785366000
    },
    {
        "time": 1689785367000,
        "value": 139.519,
        "_internal_originalTime": 1689785367000
    },
    {
        "time": 1689785369000,
        "value": 139.517,
        "_internal_originalTime": 1689785369000
    },
    {
        "time": 1689785370000,
        "value": 139.527,
        "_internal_originalTime": 1689785370000
    },
    {
        "time": 1689785371000,
        "value": 139.529,
        "_internal_originalTime": 1689785371000
    },
    {
        "time": 1689785372000,
        "value": 139.527,
        "_internal_originalTime": 1689785372000
    },
    {
        "time": 1689785373000,
        "value": 139.528,
        "_internal_originalTime": 1689785373000
    },
    {
        "time": 1689785375000,
        "value": 139.528,
        "_internal_originalTime": 1689785375000
    },
    {
        "time": 1689785376000,
        "value": 139.528,
        "_internal_originalTime": 1689785376000
    },
    {
        "time": 1689785378000,
        "value": 139.53,
        "_internal_originalTime": 1689785378000
    },
    {
        "time": 1689785379000,
        "value": 139.535,
        "_internal_originalTime": 1689785379000
    },
    {
        "time": 1689785381000,
        "value": 139.537,
        "_internal_originalTime": 1689785381000
    },
    {
        "time": 1689785382000,
        "value": 139.533,
        "_internal_originalTime": 1689785382000
    },
    {
        "time": 1689785385000,
        "value": 139.532,
        "_internal_originalTime": 1689785385000
    },
    {
        "time": 1689785386000,
        "value": 139.539,
        "_internal_originalTime": 1689785386000
    },
    {
        "time": 1689785387000,
        "value": 139.539,
        "_internal_originalTime": 1689785387000
    },
    {
        "time": 1689785388000,
        "value": 139.538,
        "_internal_originalTime": 1689785388000
    },
    {
        "time": 1689785389000,
        "value": 139.536,
        "_internal_originalTime": 1689785389000
    },
    {
        "time": 1689785391000,
        "value": 139.539,
        "_internal_originalTime": 1689785391000
    },
    {
        "time": 1689785392000,
        "value": 139.54,
        "_internal_originalTime": 1689785392000
    },
    {
        "time": 1689785393000,
        "value": 139.54,
        "_internal_originalTime": 1689785393000
    },
    {
        "time": 1689785394000,
        "value": 139.538,
        "_internal_originalTime": 1689785394000
    },
    {
        "time": 1689785395000,
        "value": 139.534,
        "_internal_originalTime": 1689785395000
    },
    {
        "time": 1689785396000,
        "value": 139.537,
        "_internal_originalTime": 1689785396000
    },
    {
        "time": 1689785398000,
        "value": 139.528,
        "_internal_originalTime": 1689785398000
    },
    {
        "time": 1689785399000,
        "value": 139.533,
        "_internal_originalTime": 1689785399000
    },
    {
        "time": 1689785401000,
        "value": 139.532,
        "_internal_originalTime": 1689785401000
    },
    {
        "time": 1689785403000,
        "value": 139.532,
        "_internal_originalTime": 1689785403000
    },
    {
        "time": 1689785404000,
        "value": 139.532,
        "_internal_originalTime": 1689785404000
    },
    {
        "time": 1689785405000,
        "value": 139.528,
        "_internal_originalTime": 1689785405000
    },
    {
        "time": 1689785406000,
        "value": 139.53,
        "_internal_originalTime": 1689785406000
    },
    {
        "time": 1689785408000,
        "value": 139.531,
        "_internal_originalTime": 1689785408000
    },
    {
        "time": 1689785410000,
        "value": 139.529,
        "_internal_originalTime": 1689785410000
    },
    {
        "time": 1689785411000,
        "value": 139.532,
        "_internal_originalTime": 1689785411000
    },
    {
        "time": 1689785413000,
        "value": 139.529,
        "_internal_originalTime": 1689785413000
    },
    {
        "time": 1689785414000,
        "value": 139.529,
        "_internal_originalTime": 1689785414000
    },
    {
        "time": 1689785415000,
        "value": 139.532,
        "_internal_originalTime": 1689785415000
    },
    {
        "time": 1689785416000,
        "value": 139.535,
        "_internal_originalTime": 1689785416000
    },
    {
        "time": 1689785418000,
        "value": 139.538,
        "_internal_originalTime": 1689785418000
    },
    {
        "time": 1689785419000,
        "value": 139.537,
        "_internal_originalTime": 1689785419000
    },
    {
        "time": 1689785420000,
        "value": 139.539,
        "_internal_originalTime": 1689785420000
    },
    {
        "time": 1689785421000,
        "value": 139.54,
        "_internal_originalTime": 1689785421000
    },
    {
        "time": 1689785422000,
        "value": 139.54,
        "_internal_originalTime": 1689785422000
    },
    {
        "time": 1689785423000,
        "value": 139.544,
        "_internal_originalTime": 1689785423000
    },
    {
        "time": 1689785425000,
        "value": 139.549,
        "_internal_originalTime": 1689785425000
    },
    {
        "time": 1689785426000,
        "value": 139.552,
        "_internal_originalTime": 1689785426000
    },
    {
        "time": 1689785427000,
        "value": 139.55,
        "_internal_originalTime": 1689785427000
    },
    {
        "time": 1689785428000,
        "value": 139.556,
        "_internal_originalTime": 1689785428000
    },
    {
        "time": 1689785429000,
        "value": 139.558,
        "_internal_originalTime": 1689785429000
    },
    {
        "time": 1689785431000,
        "value": 139.556,
        "_internal_originalTime": 1689785431000
    },
    {
        "time": 1689785432000,
        "value": 139.559,
        "_internal_originalTime": 1689785432000
    },
    {
        "time": 1689785433000,
        "value": 139.558,
        "_internal_originalTime": 1689785433000
    },
    {
        "time": 1689785434000,
        "value": 139.557,
        "_internal_originalTime": 1689785434000
    },
    {
        "time": 1689785435000,
        "value": 139.559,
        "_internal_originalTime": 1689785435000
    },
    {
        "time": 1689785436000,
        "value": 139.557,
        "_internal_originalTime": 1689785436000
    },
    {
        "time": 1689785438000,
        "value": 139.559,
        "_internal_originalTime": 1689785438000
    },
    {
        "time": 1689785441000,
        "value": 139.561,
        "_internal_originalTime": 1689785441000
    },
    {
        "time": 1689785442000,
        "value": 139.562,
        "_internal_originalTime": 1689785442000
    },
    {
        "time": 1689785443000,
        "value": 139.563,
        "_internal_originalTime": 1689785443000
    },
    {
        "time": 1689785444000,
        "value": 139.563,
        "_internal_originalTime": 1689785444000
    },
    {
        "time": 1689785445000,
        "value": 139.561,
        "_internal_originalTime": 1689785445000
    },
    {
        "time": 1689785447000,
        "value": 139.562,
        "_internal_originalTime": 1689785447000
    },
    {
        "time": 1689785448000,
        "value": 139.559,
        "_internal_originalTime": 1689785448000
    },
    {
        "time": 1689785449000,
        "value": 139.559,
        "_internal_originalTime": 1689785449000
    },
    {
        "time": 1689785451000,
        "value": 139.571,
        "_internal_originalTime": 1689785451000
    },
    {
        "time": 1689785452000,
        "value": 139.568,
        "_internal_originalTime": 1689785452000
    },
    {
        "time": 1689785453000,
        "value": 139.568,
        "_internal_originalTime": 1689785453000
    },
    {
        "time": 1689785454000,
        "value": 139.569,
        "_internal_originalTime": 1689785454000
    },
    {
        "time": 1689785456000,
        "value": 139.566,
        "_internal_originalTime": 1689785456000
    },
    {
        "time": 1689785458000,
        "value": 139.569,
        "_internal_originalTime": 1689785458000
    },
    {
        "time": 1689785459000,
        "value": 139.572,
        "_internal_originalTime": 1689785459000
    },
    {
        "time": 1689785460000,
        "value": 139.571,
        "_internal_originalTime": 1689785460000
    },
    {
        "time": 1689785462000,
        "value": 139.57,
        "_internal_originalTime": 1689785462000
    },
    {
        "time": 1689785463000,
        "value": 139.569,
        "_internal_originalTime": 1689785463000
    },
    {
        "time": 1689785464000,
        "value": 139.565,
        "_internal_originalTime": 1689785464000
    },
    {
        "time": 1689785465000,
        "value": 139.566,
        "_internal_originalTime": 1689785465000
    },
    {
        "time": 1689785466000,
        "value": 139.572,
        "_internal_originalTime": 1689785466000
    },
    {
        "time": 1689785468000,
        "value": 139.572,
        "_internal_originalTime": 1689785468000
    },
    {
        "time": 1689785469000,
        "value": 139.573,
        "_internal_originalTime": 1689785469000
    },
    {
        "time": 1689785471000,
        "value": 139.572,
        "_internal_originalTime": 1689785471000
    },
    {
        "time": 1689785472000,
        "value": 139.57,
        "_internal_originalTime": 1689785472000
    },
    {
        "time": 1689785473000,
        "value": 139.571,
        "_internal_originalTime": 1689785473000
    },
    {
        "time": 1689785475000,
        "value": 139.566,
        "_internal_originalTime": 1689785475000
    },
    {
        "time": 1689785477000,
        "value": 139.578,
        "_internal_originalTime": 1689785477000
    },
    {
        "time": 1689785479000,
        "value": 139.579,
        "_internal_originalTime": 1689785479000
    },
    {
        "time": 1689785480000,
        "value": 139.578,
        "_internal_originalTime": 1689785480000
    },
    {
        "time": 1689785481000,
        "value": 139.576,
        "_internal_originalTime": 1689785481000
    },
    {
        "time": 1689785482000,
        "value": 139.57,
        "_internal_originalTime": 1689785482000
    },
    {
        "time": 1689785483000,
        "value": 139.573,
        "_internal_originalTime": 1689785483000
    },
    {
        "time": 1689785485000,
        "value": 139.57,
        "_internal_originalTime": 1689785485000
    },
    {
        "time": 1689785486000,
        "value": 139.57,
        "_internal_originalTime": 1689785486000
    },
    {
        "time": 1689785488000,
        "value": 139.578,
        "_internal_originalTime": 1689785488000
    },
    {
        "time": 1689785489000,
        "value": 139.577,
        "_internal_originalTime": 1689785489000
    },
    {
        "time": 1689785491000,
        "value": 139.577,
        "_internal_originalTime": 1689785491000
    },
    {
        "time": 1689785492000,
        "value": 139.576,
        "_internal_originalTime": 1689785492000
    },
    {
        "time": 1689785493000,
        "value": 139.577,
        "_internal_originalTime": 1689785493000
    },
    {
        "time": 1689785494000,
        "value": 139.576,
        "_internal_originalTime": 1689785494000
    },
    {
        "time": 1689785496000,
        "value": 139.576,
        "_internal_originalTime": 1689785496000
    },
    {
        "time": 1689785497000,
        "value": 139.574,
        "_internal_originalTime": 1689785497000
    },
    {
        "time": 1689785498000,
        "value": 139.57,
        "_internal_originalTime": 1689785498000
    },
    {
        "time": 1689785499000,
        "value": 139.573,
        "_internal_originalTime": 1689785499000
    },
    {
        "time": 1689785500000,
        "value": 139.573,
        "_internal_originalTime": 1689785500000
    },
    {
        "time": 1689785501000,
        "value": 139.57,
        "_internal_originalTime": 1689785501000
    },
    {
        "time": 1689785502000,
        "value": 139.571,
        "_internal_originalTime": 1689785502000
    },
    {
        "time": 1689785503000,
        "value": 139.58,
        "_internal_originalTime": 1689785503000
    },
    {
        "time": 1689785504000,
        "value": 139.58,
        "_internal_originalTime": 1689785504000
    },
    {
        "time": 1689785505000,
        "value": 139.58,
        "_internal_originalTime": 1689785505000
    },
    {
        "time": 1689785507000,
        "value": 139.58,
        "_internal_originalTime": 1689785507000
    },
    {
        "time": 1689785508000,
        "value": 139.578,
        "_internal_originalTime": 1689785508000
    },
    {
        "time": 1689785510000,
        "value": 139.58,
        "_internal_originalTime": 1689785510000
    },
    {
        "time": 1689785511000,
        "value": 139.582,
        "_internal_originalTime": 1689785511000
    },
    {
        "time": 1689785512000,
        "value": 139.582,
        "_internal_originalTime": 1689785512000
    },
    {
        "time": 1689785514000,
        "value": 139.588,
        "_internal_originalTime": 1689785514000
    },
    {
        "time": 1689785515000,
        "value": 139.587,
        "_internal_originalTime": 1689785515000
    },
    {
        "time": 1689785516000,
        "value": 139.587,
        "_internal_originalTime": 1689785516000
    },
    {
        "time": 1689785517000,
        "value": 139.587,
        "_internal_originalTime": 1689785517000
    },
    {
        "time": 1689785519000,
        "value": 139.589,
        "_internal_originalTime": 1689785519000
    },
    {
        "time": 1689785522000,
        "value": 139.582,
        "_internal_originalTime": 1689785522000
    },
    {
        "time": 1689785523000,
        "value": 139.586,
        "_internal_originalTime": 1689785523000
    },
    {
        "time": 1689785525000,
        "value": 139.587,
        "_internal_originalTime": 1689785525000
    },
    {
        "time": 1689785526000,
        "value": 139.589,
        "_internal_originalTime": 1689785526000
    },
    {
        "time": 1689785527000,
        "value": 139.589,
        "_internal_originalTime": 1689785527000
    },
    {
        "time": 1689785528000,
        "value": 139.591,
        "_internal_originalTime": 1689785528000
    },
    {
        "time": 1689785530000,
        "value": 139.588,
        "_internal_originalTime": 1689785530000
    },
    {
        "time": 1689785531000,
        "value": 139.589,
        "_internal_originalTime": 1689785531000
    },
    {
        "time": 1689785532000,
        "value": 139.592,
        "_internal_originalTime": 1689785532000
    },
    {
        "time": 1689785534000,
        "value": 139.588,
        "_internal_originalTime": 1689785534000
    },
    {
        "time": 1689785535000,
        "value": 139.593,
        "_internal_originalTime": 1689785535000
    },
    {
        "time": 1689785537000,
        "value": 139.597,
        "_internal_originalTime": 1689785537000
    },
    {
        "time": 1689785538000,
        "value": 139.59,
        "_internal_originalTime": 1689785538000
    },
    {
        "time": 1689785539000,
        "value": 139.588,
        "_internal_originalTime": 1689785539000
    },
    {
        "time": 1689785541000,
        "value": 139.593,
        "_internal_originalTime": 1689785541000
    },
    {
        "time": 1689785542000,
        "value": 139.589,
        "_internal_originalTime": 1689785542000
    },
    {
        "time": 1689785543000,
        "value": 139.589,
        "_internal_originalTime": 1689785543000
    },
    {
        "time": 1689785544000,
        "value": 139.591,
        "_internal_originalTime": 1689785544000
    },
    {
        "time": 1689785545000,
        "value": 139.59,
        "_internal_originalTime": 1689785545000
    },
    {
        "time": 1689785546000,
        "value": 139.59,
        "_internal_originalTime": 1689785546000
    },
    {
        "time": 1689785547000,
        "value": 139.591,
        "_internal_originalTime": 1689785547000
    },
    {
        "time": 1689785549000,
        "value": 139.589,
        "_internal_originalTime": 1689785549000
    },
    {
        "time": 1689785550000,
        "value": 139.594,
        "_internal_originalTime": 1689785550000
    },
    {
        "time": 1689785552000,
        "value": 139.597,
        "_internal_originalTime": 1689785552000
    },
    {
        "time": 1689785553000,
        "value": 139.595,
        "_internal_originalTime": 1689785553000
    },
    {
        "time": 1689785554000,
        "value": 139.599,
        "_internal_originalTime": 1689785554000
    },
    {
        "time": 1689785555000,
        "value": 139.598,
        "_internal_originalTime": 1689785555000
    },
    {
        "time": 1689785557000,
        "value": 139.597,
        "_internal_originalTime": 1689785557000
    },
    {
        "time": 1689785558000,
        "value": 139.594,
        "_internal_originalTime": 1689785558000
    },
    {
        "time": 1689785559000,
        "value": 139.593,
        "_internal_originalTime": 1689785559000
    },
    {
        "time": 1689785560000,
        "value": 139.593,
        "_internal_originalTime": 1689785560000
    },
    {
        "time": 1689785561000,
        "value": 139.591,
        "_internal_originalTime": 1689785561000
    },
    {
        "time": 1689785564000,
        "value": 139.594,
        "_internal_originalTime": 1689785564000
    },
    {
        "time": 1689785565000,
        "value": 139.59,
        "_internal_originalTime": 1689785565000
    },
    {
        "time": 1689785566000,
        "value": 139.598,
        "_internal_originalTime": 1689785566000
    },
    {
        "time": 1689785567000,
        "value": 139.596,
        "_internal_originalTime": 1689785567000
    },
    {
        "time": 1689785569000,
        "value": 139.597,
        "_internal_originalTime": 1689785569000
    },
    {
        "time": 1689785571000,
        "value": 139.599,
        "_internal_originalTime": 1689785571000
    },
    {
        "time": 1689785573000,
        "value": 139.599,
        "_internal_originalTime": 1689785573000
    },
    {
        "time": 1689785575000,
        "value": 139.602,
        "_internal_originalTime": 1689785575000
    },
    {
        "time": 1689785577000,
        "value": 139.607,
        "_internal_originalTime": 1689785577000
    },
    {
        "time": 1689785579000,
        "value": 139.598,
        "_internal_originalTime": 1689785579000
    },
    {
        "time": 1689785580000,
        "value": 139.6,
        "_internal_originalTime": 1689785580000
    },
    {
        "time": 1689785581000,
        "value": 139.599,
        "_internal_originalTime": 1689785581000
    },
    {
        "time": 1689785582000,
        "value": 139.601,
        "_internal_originalTime": 1689785582000
    },
    {
        "time": 1689785584000,
        "value": 139.598,
        "_internal_originalTime": 1689785584000
    },
    {
        "time": 1689785585000,
        "value": 139.602,
        "_internal_originalTime": 1689785585000
    },
    {
        "time": 1689785586000,
        "value": 139.598,
        "_internal_originalTime": 1689785586000
    },
    {
        "time": 1689785587000,
        "value": 139.602,
        "_internal_originalTime": 1689785587000
    },
    {
        "time": 1689785588000,
        "value": 139.597,
        "_internal_originalTime": 1689785588000
    },
    {
        "time": 1689785589000,
        "value": 139.598,
        "_internal_originalTime": 1689785589000
    },
    {
        "time": 1689785590000,
        "value": 139.599,
        "_internal_originalTime": 1689785590000
    },
    {
        "time": 1689785591000,
        "value": 139.6,
        "_internal_originalTime": 1689785591000
    },
    {
        "time": 1689785593000,
        "value": 139.601,
        "_internal_originalTime": 1689785593000
    },
    {
        "time": 1689785594000,
        "value": 139.598,
        "_internal_originalTime": 1689785594000
    },
    {
        "time": 1689785595000,
        "value": 139.594,
        "_internal_originalTime": 1689785595000
    },
    {
        "time": 1689785598000,
        "value": 139.598,
        "_internal_originalTime": 1689785598000
    },
    {
        "time": 1689785599000,
        "value": 139.6,
        "_internal_originalTime": 1689785599000
    },
    {
        "time": 1689785601000,
        "value": 139.601,
        "_internal_originalTime": 1689785601000
    },
    {
        "time": 1689785604000,
        "value": 139.599,
        "_internal_originalTime": 1689785604000
    },
    {
        "time": 1689785605000,
        "value": 139.6,
        "_internal_originalTime": 1689785605000
    },
    {
        "time": 1689785608000,
        "value": 139.601,
        "_internal_originalTime": 1689785608000
    },
    {
        "time": 1689785610000,
        "value": 139.607,
        "_internal_originalTime": 1689785610000
    },
    {
        "time": 1689785612000,
        "value": 139.606,
        "_internal_originalTime": 1689785612000
    },
    {
        "time": 1689785613000,
        "value": 139.606,
        "_internal_originalTime": 1689785613000
    },
    {
        "time": 1689785614000,
        "value": 139.608,
        "_internal_originalTime": 1689785614000
    },
    {
        "time": 1689785617000,
        "value": 139.606,
        "_internal_originalTime": 1689785617000
    },
    {
        "time": 1689785618000,
        "value": 139.606,
        "_internal_originalTime": 1689785618000
    },
    {
        "time": 1689785619000,
        "value": 139.608,
        "_internal_originalTime": 1689785619000
    },
    {
        "time": 1689785621000,
        "value": 139.601,
        "_internal_originalTime": 1689785621000
    },
    {
        "time": 1689785622000,
        "value": 139.6,
        "_internal_originalTime": 1689785622000
    },
    {
        "time": 1689785623000,
        "value": 139.605,
        "_internal_originalTime": 1689785623000
    },
    {
        "time": 1689785624000,
        "value": 139.602,
        "_internal_originalTime": 1689785624000
    },
    {
        "time": 1689785625000,
        "value": 139.605,
        "_internal_originalTime": 1689785625000
    },
    {
        "time": 1689785626000,
        "value": 139.602,
        "_internal_originalTime": 1689785626000
    },
    {
        "time": 1689785628000,
        "value": 139.6,
        "_internal_originalTime": 1689785628000
    },
    {
        "time": 1689785631000,
        "value": 139.594,
        "_internal_originalTime": 1689785631000
    },
    {
        "time": 1689785632000,
        "value": 139.598,
        "_internal_originalTime": 1689785632000
    },
    {
        "time": 1689785633000,
        "value": 139.599,
        "_internal_originalTime": 1689785633000
    },
    {
        "time": 1689785634000,
        "value": 139.595,
        "_internal_originalTime": 1689785634000
    },
    {
        "time": 1689785635000,
        "value": 139.6,
        "_internal_originalTime": 1689785635000
    },
    {
        "time": 1689785637000,
        "value": 139.596,
        "_internal_originalTime": 1689785637000
    },
    {
        "time": 1689785639000,
        "value": 139.59,
        "_internal_originalTime": 1689785639000
    },
    {
        "time": 1689785640000,
        "value": 139.59,
        "_internal_originalTime": 1689785640000
    },
    {
        "time": 1689785641000,
        "value": 139.59,
        "_internal_originalTime": 1689785641000
    },
    {
        "time": 1689785642000,
        "value": 139.592,
        "_internal_originalTime": 1689785642000
    },
    {
        "time": 1689785643000,
        "value": 139.589,
        "_internal_originalTime": 1689785643000
    },
    {
        "time": 1689785644000,
        "value": 139.589,
        "_internal_originalTime": 1689785644000
    },
    {
        "time": 1689785646000,
        "value": 139.591,
        "_internal_originalTime": 1689785646000
    },
    {
        "time": 1689785648000,
        "value": 139.589,
        "_internal_originalTime": 1689785648000
    },
    {
        "time": 1689785649000,
        "value": 139.591,
        "_internal_originalTime": 1689785649000
    },
    {
        "time": 1689785651000,
        "value": 139.597,
        "_internal_originalTime": 1689785651000
    },
    {
        "time": 1689785652000,
        "value": 139.597,
        "_internal_originalTime": 1689785652000
    },
    {
        "time": 1689785653000,
        "value": 139.597,
        "_internal_originalTime": 1689785653000
    },
    {
        "time": 1689785654000,
        "value": 139.594,
        "_internal_originalTime": 1689785654000
    },
    {
        "time": 1689785656000,
        "value": 139.588,
        "_internal_originalTime": 1689785656000
    },
    {
        "time": 1689785657000,
        "value": 139.588,
        "_internal_originalTime": 1689785657000
    },
    {
        "time": 1689785658000,
        "value": 139.588,
        "_internal_originalTime": 1689785658000
    },
    {
        "time": 1689785662000,
        "value": 139.589,
        "_internal_originalTime": 1689785662000
    },
    {
        "time": 1689785663000,
        "value": 139.589,
        "_internal_originalTime": 1689785663000
    },
    {
        "time": 1689785664000,
        "value": 139.591,
        "_internal_originalTime": 1689785664000
    },
    {
        "time": 1689785665000,
        "value": 139.592,
        "_internal_originalTime": 1689785665000
    },
    {
        "time": 1689785666000,
        "value": 139.593,
        "_internal_originalTime": 1689785666000
    },
    {
        "time": 1689785667000,
        "value": 139.596,
        "_internal_originalTime": 1689785667000
    },
    {
        "time": 1689785670000,
        "value": 139.593,
        "_internal_originalTime": 1689785670000
    },
    {
        "time": 1689785671000,
        "value": 139.594,
        "_internal_originalTime": 1689785671000
    },
    {
        "time": 1689785672000,
        "value": 139.596,
        "_internal_originalTime": 1689785672000
    },
    {
        "time": 1689785673000,
        "value": 139.595,
        "_internal_originalTime": 1689785673000
    },
    {
        "time": 1689785675000,
        "value": 139.593,
        "_internal_originalTime": 1689785675000
    },
    {
        "time": 1689785677000,
        "value": 139.597,
        "_internal_originalTime": 1689785677000
    },
    {
        "time": 1689785678000,
        "value": 139.599,
        "_internal_originalTime": 1689785678000
    },
    {
        "time": 1689785679000,
        "value": 139.597,
        "_internal_originalTime": 1689785679000
    },
    {
        "time": 1689785681000,
        "value": 139.598,
        "_internal_originalTime": 1689785681000
    },
    {
        "time": 1689785682000,
        "value": 139.598,
        "_internal_originalTime": 1689785682000
    },
    {
        "time": 1689785683000,
        "value": 139.598,
        "_internal_originalTime": 1689785683000
    },
    {
        "time": 1689785685000,
        "value": 139.594,
        "_internal_originalTime": 1689785685000
    },
    {
        "time": 1689785687000,
        "value": 139.598,
        "_internal_originalTime": 1689785687000
    },
    {
        "time": 1689785688000,
        "value": 139.598,
        "_internal_originalTime": 1689785688000
    },
    {
        "time": 1689785691000,
        "value": 139.598,
        "_internal_originalTime": 1689785691000
    },
    {
        "time": 1689785692000,
        "value": 139.598,
        "_internal_originalTime": 1689785692000
    },
    {
        "time": 1689785695000,
        "value": 139.601,
        "_internal_originalTime": 1689785695000
    },
    {
        "time": 1689785696000,
        "value": 139.606,
        "_internal_originalTime": 1689785696000
    },
    {
        "time": 1689785697000,
        "value": 139.603,
        "_internal_originalTime": 1689785697000
    },
    {
        "time": 1689785698000,
        "value": 139.602,
        "_internal_originalTime": 1689785698000
    },
    {
        "time": 1689785700000,
        "value": 139.602,
        "_internal_originalTime": 1689785700000
    },
    {
        "time": 1689785701000,
        "value": 139.601,
        "_internal_originalTime": 1689785701000
    },
    {
        "time": 1689785702000,
        "value": 139.598,
        "_internal_originalTime": 1689785702000
    },
    {
        "time": 1689785703000,
        "value": 139.588,
        "_internal_originalTime": 1689785703000
    },
    {
        "time": 1689785704000,
        "value": 139.589,
        "_internal_originalTime": 1689785704000
    },
    {
        "time": 1689785705000,
        "value": 139.588,
        "_internal_originalTime": 1689785705000
    },
    {
        "time": 1689785706000,
        "value": 139.589,
        "_internal_originalTime": 1689785706000
    },
    {
        "time": 1689785708000,
        "value": 139.592,
        "_internal_originalTime": 1689785708000
    },
    {
        "time": 1689785710000,
        "value": 139.592,
        "_internal_originalTime": 1689785710000
    },
    {
        "time": 1689785711000,
        "value": 139.591,
        "_internal_originalTime": 1689785711000
    },
    {
        "time": 1689785712000,
        "value": 139.609,
        "_internal_originalTime": 1689785712000
    },
    {
        "time": 1689785713000,
        "value": 139.611,
        "_internal_originalTime": 1689785713000
    },
    {
        "time": 1689785714000,
        "value": 139.611,
        "_internal_originalTime": 1689785714000
    },
    {
        "time": 1689785715000,
        "value": 139.605,
        "_internal_originalTime": 1689785715000
    },
    {
        "time": 1689785717000,
        "value": 139.608,
        "_internal_originalTime": 1689785717000
    },
    {
        "time": 1689785719000,
        "value": 139.611,
        "_internal_originalTime": 1689785719000
    },
    {
        "time": 1689785720000,
        "value": 139.61,
        "_internal_originalTime": 1689785720000
    },
    {
        "time": 1689785721000,
        "value": 139.615,
        "_internal_originalTime": 1689785721000
    },
    {
        "time": 1689785722000,
        "value": 139.614,
        "_internal_originalTime": 1689785722000
    },
    {
        "time": 1689785723000,
        "value": 139.618,
        "_internal_originalTime": 1689785723000
    },
    {
        "time": 1689785724000,
        "value": 139.612,
        "_internal_originalTime": 1689785724000
    },
    {
        "time": 1689785725000,
        "value": 139.61,
        "_internal_originalTime": 1689785725000
    },
    {
        "time": 1689785727000,
        "value": 139.613,
        "_internal_originalTime": 1689785727000
    },
    {
        "time": 1689785728000,
        "value": 139.612,
        "_internal_originalTime": 1689785728000
    },
    {
        "time": 1689785730000,
        "value": 139.61,
        "_internal_originalTime": 1689785730000
    },
    {
        "time": 1689785731000,
        "value": 139.611,
        "_internal_originalTime": 1689785731000
    },
    {
        "time": 1689785732000,
        "value": 139.614,
        "_internal_originalTime": 1689785732000
    },
    {
        "time": 1689785734000,
        "value": 139.612,
        "_internal_originalTime": 1689785734000
    },
    {
        "time": 1689785736000,
        "value": 139.612,
        "_internal_originalTime": 1689785736000
    },
    {
        "time": 1689785737000,
        "value": 139.614,
        "_internal_originalTime": 1689785737000
    },
    {
        "time": 1689785738000,
        "value": 139.61,
        "_internal_originalTime": 1689785738000
    },
    {
        "time": 1689785739000,
        "value": 139.611,
        "_internal_originalTime": 1689785739000
    },
    {
        "time": 1689785740000,
        "value": 139.615,
        "_internal_originalTime": 1689785740000
    },
    {
        "time": 1689785742000,
        "value": 139.619,
        "_internal_originalTime": 1689785742000
    },
    {
        "time": 1689785743000,
        "value": 139.621,
        "_internal_originalTime": 1689785743000
    },
    {
        "time": 1689785744000,
        "value": 139.621,
        "_internal_originalTime": 1689785744000
    },
    {
        "time": 1689785745000,
        "value": 139.615,
        "_internal_originalTime": 1689785745000
    },
    {
        "time": 1689785746000,
        "value": 139.619,
        "_internal_originalTime": 1689785746000
    },
    {
        "time": 1689785748000,
        "value": 139.618,
        "_internal_originalTime": 1689785748000
    },
    {
        "time": 1689785749000,
        "value": 139.617,
        "_internal_originalTime": 1689785749000
    },
    {
        "time": 1689785750000,
        "value": 139.621,
        "_internal_originalTime": 1689785750000
    },
    {
        "time": 1689785751000,
        "value": 139.615,
        "_internal_originalTime": 1689785751000
    },
    {
        "time": 1689785753000,
        "value": 139.614,
        "_internal_originalTime": 1689785753000
    },
    {
        "time": 1689785754000,
        "value": 139.61,
        "_internal_originalTime": 1689785754000
    },
    {
        "time": 1689785755000,
        "value": 139.617,
        "_internal_originalTime": 1689785755000
    },
    {
        "time": 1689785757000,
        "value": 139.616,
        "_internal_originalTime": 1689785757000
    },
    {
        "time": 1689785758000,
        "value": 139.608,
        "_internal_originalTime": 1689785758000
    },
    {
        "time": 1689785760000,
        "value": 139.607,
        "_internal_originalTime": 1689785760000
    },
    {
        "time": 1689785761000,
        "value": 139.609,
        "_internal_originalTime": 1689785761000
    },
    {
        "time": 1689785763000,
        "value": 139.608,
        "_internal_originalTime": 1689785763000
    },
    {
        "time": 1689785765000,
        "value": 139.603,
        "_internal_originalTime": 1689785765000
    },
    {
        "time": 1689785767000,
        "value": 139.604,
        "_internal_originalTime": 1689785767000
    },
    {
        "time": 1689785768000,
        "value": 139.602,
        "_internal_originalTime": 1689785768000
    },
    {
        "time": 1689785769000,
        "value": 139.598,
        "_internal_originalTime": 1689785769000
    },
    {
        "time": 1689785773000,
        "value": 139.601,
        "_internal_originalTime": 1689785773000
    },
    {
        "time": 1689785775000,
        "value": 139.598,
        "_internal_originalTime": 1689785775000
    },
    {
        "time": 1689785777000,
        "value": 139.601,
        "_internal_originalTime": 1689785777000
    },
    {
        "time": 1689785778000,
        "value": 139.606,
        "_internal_originalTime": 1689785778000
    },
    {
        "time": 1689785779000,
        "value": 139.606,
        "_internal_originalTime": 1689785779000
    },
    {
        "time": 1689785780000,
        "value": 139.607,
        "_internal_originalTime": 1689785780000
    },
    {
        "time": 1689785781000,
        "value": 139.61,
        "_internal_originalTime": 1689785781000
    },
    {
        "time": 1689785783000,
        "value": 139.61,
        "_internal_originalTime": 1689785783000
    },
    {
        "time": 1689785784000,
        "value": 139.611,
        "_internal_originalTime": 1689785784000
    },
    {
        "time": 1689785785000,
        "value": 139.609,
        "_internal_originalTime": 1689785785000
    },
    {
        "time": 1689785786000,
        "value": 139.611,
        "_internal_originalTime": 1689785786000
    },
    {
        "time": 1689785788000,
        "value": 139.609,
        "_internal_originalTime": 1689785788000
    },
    {
        "time": 1689785790000,
        "value": 139.61,
        "_internal_originalTime": 1689785790000
    },
    {
        "time": 1689785792000,
        "value": 139.61,
        "_internal_originalTime": 1689785792000
    },
    {
        "time": 1689785793000,
        "value": 139.609,
        "_internal_originalTime": 1689785793000
    },
    {
        "time": 1689785794000,
        "value": 139.61,
        "_internal_originalTime": 1689785794000
    },
    {
        "time": 1689785795000,
        "value": 139.605,
        "_internal_originalTime": 1689785795000
    },
    {
        "time": 1689785796000,
        "value": 139.602,
        "_internal_originalTime": 1689785796000
    },
    {
        "time": 1689785798000,
        "value": 139.603,
        "_internal_originalTime": 1689785798000
    },
    {
        "time": 1689785799000,
        "value": 139.603,
        "_internal_originalTime": 1689785799000
    },
    {
        "time": 1689785800000,
        "value": 139.605,
        "_internal_originalTime": 1689785800000
    },
    {
        "time": 1689785801000,
        "value": 139.607,
        "_internal_originalTime": 1689785801000
    },
    {
        "time": 1689785802000,
        "value": 139.604,
        "_internal_originalTime": 1689785802000
    },
    {
        "time": 1689785803000,
        "value": 139.606,
        "_internal_originalTime": 1689785803000
    },
    {
        "time": 1689785804000,
        "value": 139.606,
        "_internal_originalTime": 1689785804000
    },
    {
        "time": 1689785805000,
        "value": 139.606,
        "_internal_originalTime": 1689785805000
    },
    {
        "time": 1689785806000,
        "value": 139.606,
        "_internal_originalTime": 1689785806000
    },
    {
        "time": 1689785807000,
        "value": 139.606,
        "_internal_originalTime": 1689785807000
    },
    {
        "time": 1689785809000,
        "value": 139.606,
        "_internal_originalTime": 1689785809000
    },
    {
        "time": 1689785810000,
        "value": 139.608,
        "_internal_originalTime": 1689785810000
    },
    {
        "time": 1689785811000,
        "value": 139.609,
        "_internal_originalTime": 1689785811000
    },
    {
        "time": 1689785813000,
        "value": 139.614,
        "_internal_originalTime": 1689785813000
    },
    {
        "time": 1689785814000,
        "value": 139.618,
        "_internal_originalTime": 1689785814000
    },
    {
        "time": 1689785815000,
        "value": 139.619,
        "_internal_originalTime": 1689785815000
    },
    {
        "time": 1689785816000,
        "value": 139.622,
        "_internal_originalTime": 1689785816000
    },
    {
        "time": 1689785817000,
        "value": 139.622,
        "_internal_originalTime": 1689785817000
    },
    {
        "time": 1689785818000,
        "value": 139.622,
        "_internal_originalTime": 1689785818000
    },
    {
        "time": 1689785820000,
        "value": 139.619,
        "_internal_originalTime": 1689785820000
    },
    {
        "time": 1689785821000,
        "value": 139.618,
        "_internal_originalTime": 1689785821000
    },
    {
        "time": 1689785822000,
        "value": 139.618,
        "_internal_originalTime": 1689785822000
    },
    {
        "time": 1689785823000,
        "value": 139.618,
        "_internal_originalTime": 1689785823000
    },
    {
        "time": 1689785824000,
        "value": 139.618,
        "_internal_originalTime": 1689785824000
    },
    {
        "time": 1689785827000,
        "value": 139.618,
        "_internal_originalTime": 1689785827000
    },
    {
        "time": 1689785828000,
        "value": 139.619,
        "_internal_originalTime": 1689785828000
    },
    {
        "time": 1689785829000,
        "value": 139.616,
        "_internal_originalTime": 1689785829000
    },
    {
        "time": 1689785830000,
        "value": 139.619,
        "_internal_originalTime": 1689785830000
    },
    {
        "time": 1689785831000,
        "value": 139.619,
        "_internal_originalTime": 1689785831000
    },
    {
        "time": 1689785834000,
        "value": 139.619,
        "_internal_originalTime": 1689785834000
    },
    {
        "time": 1689785835000,
        "value": 139.615,
        "_internal_originalTime": 1689785835000
    },
    {
        "time": 1689785836000,
        "value": 139.621,
        "_internal_originalTime": 1689785836000
    },
    {
        "time": 1689785837000,
        "value": 139.619,
        "_internal_originalTime": 1689785837000
    },
    {
        "time": 1689785839000,
        "value": 139.616,
        "_internal_originalTime": 1689785839000
    },
    {
        "time": 1689785840000,
        "value": 139.618,
        "_internal_originalTime": 1689785840000
    },
    {
        "time": 1689785843000,
        "value": 139.618,
        "_internal_originalTime": 1689785843000
    },
    {
        "time": 1689785845000,
        "value": 139.612,
        "_internal_originalTime": 1689785845000
    },
    {
        "time": 1689785846000,
        "value": 139.608,
        "_internal_originalTime": 1689785846000
    },
    {
        "time": 1689785847000,
        "value": 139.611,
        "_internal_originalTime": 1689785847000
    },
    {
        "time": 1689785848000,
        "value": 139.61,
        "_internal_originalTime": 1689785848000
    },
    {
        "time": 1689785849000,
        "value": 139.611,
        "_internal_originalTime": 1689785849000
    },
    {
        "time": 1689785851000,
        "value": 139.613,
        "_internal_originalTime": 1689785851000
    },
    {
        "time": 1689785852000,
        "value": 139.614,
        "_internal_originalTime": 1689785852000
    },
    {
        "time": 1689785853000,
        "value": 139.616,
        "_internal_originalTime": 1689785853000
    },
    {
        "time": 1689785854000,
        "value": 139.618,
        "_internal_originalTime": 1689785854000
    },
    {
        "time": 1689785855000,
        "value": 139.616,
        "_internal_originalTime": 1689785855000
    },
    {
        "time": 1689785856000,
        "value": 139.617,
        "_internal_originalTime": 1689785856000
    },
    {
        "time": 1689785858000,
        "value": 139.618,
        "_internal_originalTime": 1689785858000
    },
    {
        "time": 1689785860000,
        "value": 139.616,
        "_internal_originalTime": 1689785860000
    },
    {
        "time": 1689785861000,
        "value": 139.617,
        "_internal_originalTime": 1689785861000
    },
    {
        "time": 1689785862000,
        "value": 139.616,
        "_internal_originalTime": 1689785862000
    },
    {
        "time": 1689785863000,
        "value": 139.618,
        "_internal_originalTime": 1689785863000
    },
    {
        "time": 1689785864000,
        "value": 139.617,
        "_internal_originalTime": 1689785864000
    },
    {
        "time": 1689785865000,
        "value": 139.618,
        "_internal_originalTime": 1689785865000
    },
    {
        "time": 1689785866000,
        "value": 139.62,
        "_internal_originalTime": 1689785866000
    },
    {
        "time": 1689785868000,
        "value": 139.621,
        "_internal_originalTime": 1689785868000
    },
    {
        "time": 1689785869000,
        "value": 139.619,
        "_internal_originalTime": 1689785869000
    },
    {
        "time": 1689785870000,
        "value": 139.617,
        "_internal_originalTime": 1689785870000
    },
    {
        "time": 1689785871000,
        "value": 139.616,
        "_internal_originalTime": 1689785871000
    },
    {
        "time": 1689785873000,
        "value": 139.618,
        "_internal_originalTime": 1689785873000
    },
    {
        "time": 1689785874000,
        "value": 139.616,
        "_internal_originalTime": 1689785874000
    },
    {
        "time": 1689785877000,
        "value": 139.616,
        "_internal_originalTime": 1689785877000
    },
    {
        "time": 1689785878000,
        "value": 139.616,
        "_internal_originalTime": 1689785878000
    },
    {
        "time": 1689785880000,
        "value": 139.616,
        "_internal_originalTime": 1689785880000
    },
    {
        "time": 1689785881000,
        "value": 139.616,
        "_internal_originalTime": 1689785881000
    },
    {
        "time": 1689785882000,
        "value": 139.615,
        "_internal_originalTime": 1689785882000
    },
    {
        "time": 1689785883000,
        "value": 139.617,
        "_internal_originalTime": 1689785883000
    },
    {
        "time": 1689785885000,
        "value": 139.616,
        "_internal_originalTime": 1689785885000
    },
    {
        "time": 1689785886000,
        "value": 139.616,
        "_internal_originalTime": 1689785886000
    },
    {
        "time": 1689785887000,
        "value": 139.615,
        "_internal_originalTime": 1689785887000
    },
    {
        "time": 1689785888000,
        "value": 139.615,
        "_internal_originalTime": 1689785888000
    },
    {
        "time": 1689785889000,
        "value": 139.612,
        "_internal_originalTime": 1689785889000
    },
    {
        "time": 1689785890000,
        "value": 139.614,
        "_internal_originalTime": 1689785890000
    },
    {
        "time": 1689785892000,
        "value": 139.615,
        "_internal_originalTime": 1689785892000
    },
    {
        "time": 1689785893000,
        "value": 139.617,
        "_internal_originalTime": 1689785893000
    },
    {
        "time": 1689785895000,
        "value": 139.616,
        "_internal_originalTime": 1689785895000
    },
    {
        "time": 1689785896000,
        "value": 139.619,
        "_internal_originalTime": 1689785896000
    },
    {
        "time": 1689785897000,
        "value": 139.618,
        "_internal_originalTime": 1689785897000
    },
    {
        "time": 1689785898000,
        "value": 139.62,
        "_internal_originalTime": 1689785898000
    },
    {
        "time": 1689785900000,
        "value": 139.618,
        "_internal_originalTime": 1689785900000
    },
    {
        "time": 1689785902000,
        "value": 139.618,
        "_internal_originalTime": 1689785902000
    },
    {
        "time": 1689785903000,
        "value": 139.617,
        "_internal_originalTime": 1689785903000
    },
    {
        "time": 1689785905000,
        "value": 139.618,
        "_internal_originalTime": 1689785905000
    },
    {
        "time": 1689785907000,
        "value": 139.618,
        "_internal_originalTime": 1689785907000
    },
    {
        "time": 1689785908000,
        "value": 139.616,
        "_internal_originalTime": 1689785908000
    },
    {
        "time": 1689785910000,
        "value": 139.616,
        "_internal_originalTime": 1689785910000
    },
    {
        "time": 1689785912000,
        "value": 139.619,
        "_internal_originalTime": 1689785912000
    },
    {
        "time": 1689785913000,
        "value": 139.628,
        "_internal_originalTime": 1689785913000
    },
    {
        "time": 1689785914000,
        "value": 139.627,
        "_internal_originalTime": 1689785914000
    },
    {
        "time": 1689785916000,
        "value": 139.628,
        "_internal_originalTime": 1689785916000
    },
    {
        "time": 1689785917000,
        "value": 139.63,
        "_internal_originalTime": 1689785917000
    },
    {
        "time": 1689785918000,
        "value": 139.631,
        "_internal_originalTime": 1689785918000
    },
    {
        "time": 1689785920000,
        "value": 139.632,
        "_internal_originalTime": 1689785920000
    },
    {
        "time": 1689785922000,
        "value": 139.637,
        "_internal_originalTime": 1689785922000
    },
    {
        "time": 1689785923000,
        "value": 139.639,
        "_internal_originalTime": 1689785923000
    },
    {
        "time": 1689785924000,
        "value": 139.64,
        "_internal_originalTime": 1689785924000
    },
    {
        "time": 1689785926000,
        "value": 139.649,
        "_internal_originalTime": 1689785926000
    },
    {
        "time": 1689785927000,
        "value": 139.653,
        "_internal_originalTime": 1689785927000
    },
    {
        "time": 1689785928000,
        "value": 139.654,
        "_internal_originalTime": 1689785928000
    },
    {
        "time": 1689785931000,
        "value": 139.653,
        "_internal_originalTime": 1689785931000
    },
    {
        "time": 1689785932000,
        "value": 139.653,
        "_internal_originalTime": 1689785932000
    },
    {
        "time": 1689785933000,
        "value": 139.653,
        "_internal_originalTime": 1689785933000
    },
    {
        "time": 1689785935000,
        "value": 139.654,
        "_internal_originalTime": 1689785935000
    },
    {
        "time": 1689785936000,
        "value": 139.656,
        "_internal_originalTime": 1689785936000
    },
    {
        "time": 1689785937000,
        "value": 139.657,
        "_internal_originalTime": 1689785937000
    },
    {
        "time": 1689785940000,
        "value": 139.65,
        "_internal_originalTime": 1689785940000
    },
    {
        "time": 1689785942000,
        "value": 139.642,
        "_internal_originalTime": 1689785942000
    },
    {
        "time": 1689785943000,
        "value": 139.641,
        "_internal_originalTime": 1689785943000
    },
    {
        "time": 1689785944000,
        "value": 139.636,
        "_internal_originalTime": 1689785944000
    },
    {
        "time": 1689785946000,
        "value": 139.63,
        "_internal_originalTime": 1689785946000
    },
    {
        "time": 1689785947000,
        "value": 139.629,
        "_internal_originalTime": 1689785947000
    },
    {
        "time": 1689785948000,
        "value": 139.63,
        "_internal_originalTime": 1689785948000
    },
    {
        "time": 1689785949000,
        "value": 139.628,
        "_internal_originalTime": 1689785949000
    },
    {
        "time": 1689785951000,
        "value": 139.618,
        "_internal_originalTime": 1689785951000
    },
    {
        "time": 1689785952000,
        "value": 139.617,
        "_internal_originalTime": 1689785952000
    },
    {
        "time": 1689785953000,
        "value": 139.612,
        "_internal_originalTime": 1689785953000
    },
    {
        "time": 1689785954000,
        "value": 139.612,
        "_internal_originalTime": 1689785954000
    },
    {
        "time": 1689785956000,
        "value": 139.615,
        "_internal_originalTime": 1689785956000
    },
    {
        "time": 1689785957000,
        "value": 139.613,
        "_internal_originalTime": 1689785957000
    },
    {
        "time": 1689785959000,
        "value": 139.616,
        "_internal_originalTime": 1689785959000
    },
    {
        "time": 1689785960000,
        "value": 139.614,
        "_internal_originalTime": 1689785960000
    },
    {
        "time": 1689785962000,
        "value": 139.616,
        "_internal_originalTime": 1689785962000
    },
    {
        "time": 1689785964000,
        "value": 139.619,
        "_internal_originalTime": 1689785964000
    },
    {
        "time": 1689785966000,
        "value": 139.618,
        "_internal_originalTime": 1689785966000
    },
    {
        "time": 1689785967000,
        "value": 139.616,
        "_internal_originalTime": 1689785967000
    },
    {
        "time": 1689785968000,
        "value": 139.617,
        "_internal_originalTime": 1689785968000
    },
    {
        "time": 1689785969000,
        "value": 139.616,
        "_internal_originalTime": 1689785969000
    },
    {
        "time": 1689785971000,
        "value": 139.619,
        "_internal_originalTime": 1689785971000
    },
    {
        "time": 1689785972000,
        "value": 139.617,
        "_internal_originalTime": 1689785972000
    },
    {
        "time": 1689785974000,
        "value": 139.62,
        "_internal_originalTime": 1689785974000
    },
    {
        "time": 1689785976000,
        "value": 139.616,
        "_internal_originalTime": 1689785976000
    },
    {
        "time": 1689785978000,
        "value": 139.62,
        "_internal_originalTime": 1689785978000
    },
    {
        "time": 1689785980000,
        "value": 139.616,
        "_internal_originalTime": 1689785980000
    },
    {
        "time": 1689785982000,
        "value": 139.619,
        "_internal_originalTime": 1689785982000
    },
    {
        "time": 1689785983000,
        "value": 139.616,
        "_internal_originalTime": 1689785983000
    },
    {
        "time": 1689785984000,
        "value": 139.617,
        "_internal_originalTime": 1689785984000
    },
    {
        "time": 1689785985000,
        "value": 139.627,
        "_internal_originalTime": 1689785985000
    },
    {
        "time": 1689785986000,
        "value": 139.628,
        "_internal_originalTime": 1689785986000
    },
    {
        "time": 1689785987000,
        "value": 139.629,
        "_internal_originalTime": 1689785987000
    },
    {
        "time": 1689785989000,
        "value": 139.629,
        "_internal_originalTime": 1689785989000
    },
    {
        "time": 1689785990000,
        "value": 139.635,
        "_internal_originalTime": 1689785990000
    },
    {
        "time": 1689785991000,
        "value": 139.635,
        "_internal_originalTime": 1689785991000
    },
    {
        "time": 1689785992000,
        "value": 139.636,
        "_internal_originalTime": 1689785992000
    },
    {
        "time": 1689785993000,
        "value": 139.635,
        "_internal_originalTime": 1689785993000
    },
    {
        "time": 1689785994000,
        "value": 139.634,
        "_internal_originalTime": 1689785994000
    },
    {
        "time": 1689785996000,
        "value": 139.635,
        "_internal_originalTime": 1689785996000
    },
    {
        "time": 1689785997000,
        "value": 139.634,
        "_internal_originalTime": 1689785997000
    },
    {
        "time": 1689785999000,
        "value": 139.628,
        "_internal_originalTime": 1689785999000
    },
    {
        "time": 1689786000000,
        "value": 139.628,
        "_internal_originalTime": 1689786000000
    },
    {
        "time": 1689786001000,
        "value": 139.63,
        "_internal_originalTime": 1689786001000
    },
    {
        "time": 1689786002000,
        "value": 139.626,
        "_internal_originalTime": 1689786002000
    },
    {
        "time": 1689786003000,
        "value": 139.628,
        "_internal_originalTime": 1689786003000
    },
    {
        "time": 1689786004000,
        "value": 139.63,
        "_internal_originalTime": 1689786004000
    },
    {
        "time": 1689786005000,
        "value": 139.628,
        "_internal_originalTime": 1689786005000
    },
    {
        "time": 1689786006000,
        "value": 139.628,
        "_internal_originalTime": 1689786006000
    },
    {
        "time": 1689786007000,
        "value": 139.628,
        "_internal_originalTime": 1689786007000
    },
    {
        "time": 1689786008000,
        "value": 139.631,
        "_internal_originalTime": 1689786008000
    },
    {
        "time": 1689786010000,
        "value": 139.633,
        "_internal_originalTime": 1689786010000
    },
    {
        "time": 1689786011000,
        "value": 139.636,
        "_internal_originalTime": 1689786011000
    },
    {
        "time": 1689786012000,
        "value": 139.639,
        "_internal_originalTime": 1689786012000
    },
    {
        "time": 1689786014000,
        "value": 139.636,
        "_internal_originalTime": 1689786014000
    },
    {
        "time": 1689786015000,
        "value": 139.633,
        "_internal_originalTime": 1689786015000
    },
    {
        "time": 1689786016000,
        "value": 139.636,
        "_internal_originalTime": 1689786016000
    },
    {
        "time": 1689786018000,
        "value": 139.636,
        "_internal_originalTime": 1689786018000
    },
    {
        "time": 1689786020000,
        "value": 139.63,
        "_internal_originalTime": 1689786020000
    },
    {
        "time": 1689786023000,
        "value": 139.635,
        "_internal_originalTime": 1689786023000
    },
    {
        "time": 1689786024000,
        "value": 139.636,
        "_internal_originalTime": 1689786024000
    },
    {
        "time": 1689786025000,
        "value": 139.64,
        "_internal_originalTime": 1689786025000
    },
    {
        "time": 1689786026000,
        "value": 139.639,
        "_internal_originalTime": 1689786026000
    },
    {
        "time": 1689786029000,
        "value": 139.64,
        "_internal_originalTime": 1689786029000
    },
    {
        "time": 1689786030000,
        "value": 139.633,
        "_internal_originalTime": 1689786030000
    },
    {
        "time": 1689786031000,
        "value": 139.63,
        "_internal_originalTime": 1689786031000
    },
    {
        "time": 1689786032000,
        "value": 139.632,
        "_internal_originalTime": 1689786032000
    },
    {
        "time": 1689786033000,
        "value": 139.633,
        "_internal_originalTime": 1689786033000
    },
    {
        "time": 1689786035000,
        "value": 139.629,
        "_internal_originalTime": 1689786035000
    },
    {
        "time": 1689786037000,
        "value": 139.639,
        "_internal_originalTime": 1689786037000
    },
    {
        "time": 1689786038000,
        "value": 139.642,
        "_internal_originalTime": 1689786038000
    },
    {
        "time": 1689786039000,
        "value": 139.64,
        "_internal_originalTime": 1689786039000
    },
    {
        "time": 1689786040000,
        "value": 139.64,
        "_internal_originalTime": 1689786040000
    },
    {
        "time": 1689786041000,
        "value": 139.638,
        "_internal_originalTime": 1689786041000
    },
    {
        "time": 1689786043000,
        "value": 139.636,
        "_internal_originalTime": 1689786043000
    },
    {
        "time": 1689786045000,
        "value": 139.634,
        "_internal_originalTime": 1689786045000
    },
    {
        "time": 1689786051000,
        "value": 139.636,
        "_internal_originalTime": 1689786051000
    },
    {
        "time": 1689786052000,
        "value": 139.638,
        "_internal_originalTime": 1689786052000
    },
    {
        "time": 1689786053000,
        "value": 139.638,
        "_internal_originalTime": 1689786053000
    },
    {
        "time": 1689786055000,
        "value": 139.635,
        "_internal_originalTime": 1689786055000
    },
    {
        "time": 1689786059000,
        "value": 139.638,
        "_internal_originalTime": 1689786059000
    },
    {
        "time": 1689786060000,
        "value": 139.638,
        "_internal_originalTime": 1689786060000
    },
    {
        "time": 1689786061000,
        "value": 139.638,
        "_internal_originalTime": 1689786061000
    },
    {
        "time": 1689786062000,
        "value": 139.638,
        "_internal_originalTime": 1689786062000
    },
    {
        "time": 1689786063000,
        "value": 139.636,
        "_internal_originalTime": 1689786063000
    },
    {
        "time": 1689786065000,
        "value": 139.646,
        "_internal_originalTime": 1689786065000
    },
    {
        "time": 1689786066000,
        "value": 139.644,
        "_internal_originalTime": 1689786066000
    },
    {
        "time": 1689786068000,
        "value": 139.645,
        "_internal_originalTime": 1689786068000
    },
    {
        "time": 1689786069000,
        "value": 139.642,
        "_internal_originalTime": 1689786069000
    },
    {
        "time": 1689786071000,
        "value": 139.649,
        "_internal_originalTime": 1689786071000
    },
    {
        "time": 1689786072000,
        "value": 139.649,
        "_internal_originalTime": 1689786072000
    },
    {
        "time": 1689786073000,
        "value": 139.649,
        "_internal_originalTime": 1689786073000
    },
    {
        "time": 1689786076000,
        "value": 139.649,
        "_internal_originalTime": 1689786076000
    },
    {
        "time": 1689786077000,
        "value": 139.65,
        "_internal_originalTime": 1689786077000
    },
    {
        "time": 1689786078000,
        "value": 139.649,
        "_internal_originalTime": 1689786078000
    },
    {
        "time": 1689786080000,
        "value": 139.648,
        "_internal_originalTime": 1689786080000
    },
    {
        "time": 1689786081000,
        "value": 139.646,
        "_internal_originalTime": 1689786081000
    },
    {
        "time": 1689786084000,
        "value": 139.647,
        "_internal_originalTime": 1689786084000
    },
    {
        "time": 1689786085000,
        "value": 139.648,
        "_internal_originalTime": 1689786085000
    },
    {
        "time": 1689786087000,
        "value": 139.649,
        "_internal_originalTime": 1689786087000
    },
    {
        "time": 1689786089000,
        "value": 139.642,
        "_internal_originalTime": 1689786089000
    },
    {
        "time": 1689786090000,
        "value": 139.646,
        "_internal_originalTime": 1689786090000
    },
    {
        "time": 1689786091000,
        "value": 139.648,
        "_internal_originalTime": 1689786091000
    },
    {
        "time": 1689786092000,
        "value": 139.649,
        "_internal_originalTime": 1689786092000
    },
    {
        "time": 1689786094000,
        "value": 139.654,
        "_internal_originalTime": 1689786094000
    },
    {
        "time": 1689786096000,
        "value": 139.659,
        "_internal_originalTime": 1689786096000
    },
    {
        "time": 1689786097000,
        "value": 139.655,
        "_internal_originalTime": 1689786097000
    },
    {
        "time": 1689786098000,
        "value": 139.653,
        "_internal_originalTime": 1689786098000
    },
    {
        "time": 1689786099000,
        "value": 139.654,
        "_internal_originalTime": 1689786099000
    },
    {
        "time": 1689786101000,
        "value": 139.658,
        "_internal_originalTime": 1689786101000
    },
    {
        "time": 1689786103000,
        "value": 139.655,
        "_internal_originalTime": 1689786103000
    },
    {
        "time": 1689786104000,
        "value": 139.658,
        "_internal_originalTime": 1689786104000
    },
    {
        "time": 1689786106000,
        "value": 139.659,
        "_internal_originalTime": 1689786106000
    },
    {
        "time": 1689786107000,
        "value": 139.659,
        "_internal_originalTime": 1689786107000
    },
    {
        "time": 1689786109000,
        "value": 139.658,
        "_internal_originalTime": 1689786109000
    },
    {
        "time": 1689786110000,
        "value": 139.645,
        "_internal_originalTime": 1689786110000
    },
    {
        "time": 1689786112000,
        "value": 139.644,
        "_internal_originalTime": 1689786112000
    },
    {
        "time": 1689786113000,
        "value": 139.645,
        "_internal_originalTime": 1689786113000
    },
    {
        "time": 1689786114000,
        "value": 139.649,
        "_internal_originalTime": 1689786114000
    },
    {
        "time": 1689786115000,
        "value": 139.648,
        "_internal_originalTime": 1689786115000
    },
    {
        "time": 1689786116000,
        "value": 139.648,
        "_internal_originalTime": 1689786116000
    },
    {
        "time": 1689786118000,
        "value": 139.647,
        "_internal_originalTime": 1689786118000
    },
    {
        "time": 1689786121000,
        "value": 139.647,
        "_internal_originalTime": 1689786121000
    },
    {
        "time": 1689786122000,
        "value": 139.647,
        "_internal_originalTime": 1689786122000
    },
    {
        "time": 1689786123000,
        "value": 139.647,
        "_internal_originalTime": 1689786123000
    },
    {
        "time": 1689786124000,
        "value": 139.647,
        "_internal_originalTime": 1689786124000
    },
    {
        "time": 1689786126000,
        "value": 139.648,
        "_internal_originalTime": 1689786126000
    },
    {
        "time": 1689786128000,
        "value": 139.648,
        "_internal_originalTime": 1689786128000
    },
    {
        "time": 1689786129000,
        "value": 139.647,
        "_internal_originalTime": 1689786129000
    },
    {
        "time": 1689786130000,
        "value": 139.654,
        "_internal_originalTime": 1689786130000
    },
    {
        "time": 1689786131000,
        "value": 139.653,
        "_internal_originalTime": 1689786131000
    },
    {
        "time": 1689786132000,
        "value": 139.659,
        "_internal_originalTime": 1689786132000
    },
    {
        "time": 1689786134000,
        "value": 139.663,
        "_internal_originalTime": 1689786134000
    },
    {
        "time": 1689786136000,
        "value": 139.67,
        "_internal_originalTime": 1689786136000
    },
    {
        "time": 1689786137000,
        "value": 139.667,
        "_internal_originalTime": 1689786137000
    },
    {
        "time": 1689786138000,
        "value": 139.667,
        "_internal_originalTime": 1689786138000
    },
    {
        "time": 1689786139000,
        "value": 139.667,
        "_internal_originalTime": 1689786139000
    },
    {
        "time": 1689786140000,
        "value": 139.665,
        "_internal_originalTime": 1689786140000
    },
    {
        "time": 1689786141000,
        "value": 139.664,
        "_internal_originalTime": 1689786141000
    },
    {
        "time": 1689786143000,
        "value": 139.666,
        "_internal_originalTime": 1689786143000
    },
    {
        "time": 1689786145000,
        "value": 139.66,
        "_internal_originalTime": 1689786145000
    },
    {
        "time": 1689786147000,
        "value": 139.66,
        "_internal_originalTime": 1689786147000
    },
    {
        "time": 1689786150000,
        "value": 139.66,
        "_internal_originalTime": 1689786150000
    },
    {
        "time": 1689786152000,
        "value": 139.652,
        "_internal_originalTime": 1689786152000
    },
    {
        "time": 1689786153000,
        "value": 139.655,
        "_internal_originalTime": 1689786153000
    },
    {
        "time": 1689786154000,
        "value": 139.655,
        "_internal_originalTime": 1689786154000
    },
    {
        "time": 1689786155000,
        "value": 139.645,
        "_internal_originalTime": 1689786155000
    },
    {
        "time": 1689786156000,
        "value": 139.64,
        "_internal_originalTime": 1689786156000
    },
    {
        "time": 1689786157000,
        "value": 139.641,
        "_internal_originalTime": 1689786157000
    },
    {
        "time": 1689786158000,
        "value": 139.639,
        "_internal_originalTime": 1689786158000
    },
    {
        "time": 1689786160000,
        "value": 139.64,
        "_internal_originalTime": 1689786160000
    },
    {
        "time": 1689786162000,
        "value": 139.639,
        "_internal_originalTime": 1689786162000
    },
    {
        "time": 1689786163000,
        "value": 139.641,
        "_internal_originalTime": 1689786163000
    },
    {
        "time": 1689786164000,
        "value": 139.639,
        "_internal_originalTime": 1689786164000
    },
    {
        "time": 1689786165000,
        "value": 139.64,
        "_internal_originalTime": 1689786165000
    },
    {
        "time": 1689786166000,
        "value": 139.639,
        "_internal_originalTime": 1689786166000
    },
    {
        "time": 1689786167000,
        "value": 139.641,
        "_internal_originalTime": 1689786167000
    },
    {
        "time": 1689786169000,
        "value": 139.639,
        "_internal_originalTime": 1689786169000
    },
    {
        "time": 1689786170000,
        "value": 139.633,
        "_internal_originalTime": 1689786170000
    },
    {
        "time": 1689786171000,
        "value": 139.635,
        "_internal_originalTime": 1689786171000
    },
    {
        "time": 1689786172000,
        "value": 139.637,
        "_internal_originalTime": 1689786172000
    },
    {
        "time": 1689786173000,
        "value": 139.635,
        "_internal_originalTime": 1689786173000
    },
    {
        "time": 1689786175000,
        "value": 139.63,
        "_internal_originalTime": 1689786175000
    },
    {
        "time": 1689786176000,
        "value": 139.622,
        "_internal_originalTime": 1689786176000
    },
    {
        "time": 1689786177000,
        "value": 139.626,
        "_internal_originalTime": 1689786177000
    },
    {
        "time": 1689786180000,
        "value": 139.627,
        "_internal_originalTime": 1689786180000
    },
    {
        "time": 1689786182000,
        "value": 139.614,
        "_internal_originalTime": 1689786182000
    },
    {
        "time": 1689786184000,
        "value": 139.62,
        "_internal_originalTime": 1689786184000
    },
    {
        "time": 1689786185000,
        "value": 139.617,
        "_internal_originalTime": 1689786185000
    },
    {
        "time": 1689786187000,
        "value": 139.619,
        "_internal_originalTime": 1689786187000
    },
    {
        "time": 1689786189000,
        "value": 139.627,
        "_internal_originalTime": 1689786189000
    },
    {
        "time": 1689786190000,
        "value": 139.626,
        "_internal_originalTime": 1689786190000
    },
    {
        "time": 1689786191000,
        "value": 139.628,
        "_internal_originalTime": 1689786191000
    },
    {
        "time": 1689786192000,
        "value": 139.627,
        "_internal_originalTime": 1689786192000
    },
    {
        "time": 1689786194000,
        "value": 139.627,
        "_internal_originalTime": 1689786194000
    },
    {
        "time": 1689786196000,
        "value": 139.624,
        "_internal_originalTime": 1689786196000
    },
    {
        "time": 1689786199000,
        "value": 139.627,
        "_internal_originalTime": 1689786199000
    },
    {
        "time": 1689786200000,
        "value": 139.627,
        "_internal_originalTime": 1689786200000
    },
    {
        "time": 1689786201000,
        "value": 139.627,
        "_internal_originalTime": 1689786201000
    },
    {
        "time": 1689786202000,
        "value": 139.628,
        "_internal_originalTime": 1689786202000
    },
    {
        "time": 1689786203000,
        "value": 139.622,
        "_internal_originalTime": 1689786203000
    },
    {
        "time": 1689786204000,
        "value": 139.623,
        "_internal_originalTime": 1689786204000
    },
    {
        "time": 1689786205000,
        "value": 139.623,
        "_internal_originalTime": 1689786205000
    },
    {
        "time": 1689786207000,
        "value": 139.624,
        "_internal_originalTime": 1689786207000
    },
    {
        "time": 1689786208000,
        "value": 139.623,
        "_internal_originalTime": 1689786208000
    },
    {
        "time": 1689786209000,
        "value": 139.627,
        "_internal_originalTime": 1689786209000
    },
    {
        "time": 1689786211000,
        "value": 139.624,
        "_internal_originalTime": 1689786211000
    },
    {
        "time": 1689786212000,
        "value": 139.624,
        "_internal_originalTime": 1689786212000
    },
    {
        "time": 1689786214000,
        "value": 139.624,
        "_internal_originalTime": 1689786214000
    },
    {
        "time": 1689786215000,
        "value": 139.628,
        "_internal_originalTime": 1689786215000
    },
    {
        "time": 1689786217000,
        "value": 139.626,
        "_internal_originalTime": 1689786217000
    },
    {
        "time": 1689786218000,
        "value": 139.624,
        "_internal_originalTime": 1689786218000
    },
    {
        "time": 1689786220000,
        "value": 139.624,
        "_internal_originalTime": 1689786220000
    },
    {
        "time": 1689786222000,
        "value": 139.624,
        "_internal_originalTime": 1689786222000
    },
    {
        "time": 1689786223000,
        "value": 139.625,
        "_internal_originalTime": 1689786223000
    },
    {
        "time": 1689786225000,
        "value": 139.625,
        "_internal_originalTime": 1689786225000
    },
    {
        "time": 1689786227000,
        "value": 139.625,
        "_internal_originalTime": 1689786227000
    },
    {
        "time": 1689786229000,
        "value": 139.63,
        "_internal_originalTime": 1689786229000
    },
    {
        "time": 1689786230000,
        "value": 139.629,
        "_internal_originalTime": 1689786230000
    },
    {
        "time": 1689786231000,
        "value": 139.638,
        "_internal_originalTime": 1689786231000
    },
    {
        "time": 1689786232000,
        "value": 139.641,
        "_internal_originalTime": 1689786232000
    },
    {
        "time": 1689786234000,
        "value": 139.643,
        "_internal_originalTime": 1689786234000
    },
    {
        "time": 1689786235000,
        "value": 139.642,
        "_internal_originalTime": 1689786235000
    },
    {
        "time": 1689786237000,
        "value": 139.648,
        "_internal_originalTime": 1689786237000
    },
    {
        "time": 1689786238000,
        "value": 139.647,
        "_internal_originalTime": 1689786238000
    },
    {
        "time": 1689786240000,
        "value": 139.644,
        "_internal_originalTime": 1689786240000
    },
    {
        "time": 1689786241000,
        "value": 139.644,
        "_internal_originalTime": 1689786241000
    },
    {
        "time": 1689786242000,
        "value": 139.647,
        "_internal_originalTime": 1689786242000
    },
    {
        "time": 1689786243000,
        "value": 139.647,
        "_internal_originalTime": 1689786243000
    },
    {
        "time": 1689786244000,
        "value": 139.651,
        "_internal_originalTime": 1689786244000
    },
    {
        "time": 1689786245000,
        "value": 139.65,
        "_internal_originalTime": 1689786245000
    },
    {
        "time": 1689786247000,
        "value": 139.646,
        "_internal_originalTime": 1689786247000
    },
    {
        "time": 1689786248000,
        "value": 139.65,
        "_internal_originalTime": 1689786248000
    },
    {
        "time": 1689786249000,
        "value": 139.649,
        "_internal_originalTime": 1689786249000
    },
    {
        "time": 1689786251000,
        "value": 139.648,
        "_internal_originalTime": 1689786251000
    },
    {
        "time": 1689786252000,
        "value": 139.653,
        "_internal_originalTime": 1689786252000
    },
    {
        "time": 1689786254000,
        "value": 139.647,
        "_internal_originalTime": 1689786254000
    },
    {
        "time": 1689786256000,
        "value": 139.647,
        "_internal_originalTime": 1689786256000
    },
    {
        "time": 1689786257000,
        "value": 139.647,
        "_internal_originalTime": 1689786257000
    },
    {
        "time": 1689786258000,
        "value": 139.647,
        "_internal_originalTime": 1689786258000
    },
    {
        "time": 1689786259000,
        "value": 139.647,
        "_internal_originalTime": 1689786259000
    },
    {
        "time": 1689786260000,
        "value": 139.636,
        "_internal_originalTime": 1689786260000
    },
    {
        "time": 1689786261000,
        "value": 139.644,
        "_internal_originalTime": 1689786261000
    },
    {
        "time": 1689786262000,
        "value": 139.641,
        "_internal_originalTime": 1689786262000
    },
    {
        "time": 1689786263000,
        "value": 139.64,
        "_internal_originalTime": 1689786263000
    },
    {
        "time": 1689786265000,
        "value": 139.631,
        "_internal_originalTime": 1689786265000
    },
    {
        "time": 1689786267000,
        "value": 139.635,
        "_internal_originalTime": 1689786267000
    },
    {
        "time": 1689786269000,
        "value": 139.644,
        "_internal_originalTime": 1689786269000
    },
    {
        "time": 1689786270000,
        "value": 139.643,
        "_internal_originalTime": 1689786270000
    },
    {
        "time": 1689786272000,
        "value": 139.65,
        "_internal_originalTime": 1689786272000
    },
    {
        "time": 1689786273000,
        "value": 139.645,
        "_internal_originalTime": 1689786273000
    },
    {
        "time": 1689786274000,
        "value": 139.647,
        "_internal_originalTime": 1689786274000
    },
    {
        "time": 1689786275000,
        "value": 139.65,
        "_internal_originalTime": 1689786275000
    },
    {
        "time": 1689786277000,
        "value": 139.649,
        "_internal_originalTime": 1689786277000
    },
    {
        "time": 1689786278000,
        "value": 139.647,
        "_internal_originalTime": 1689786278000
    },
    {
        "time": 1689786279000,
        "value": 139.647,
        "_internal_originalTime": 1689786279000
    },
    {
        "time": 1689786280000,
        "value": 139.648,
        "_internal_originalTime": 1689786280000
    },
    {
        "time": 1689786282000,
        "value": 139.645,
        "_internal_originalTime": 1689786282000
    },
    {
        "time": 1689786283000,
        "value": 139.644,
        "_internal_originalTime": 1689786283000
    },
    {
        "time": 1689786286000,
        "value": 139.639,
        "_internal_originalTime": 1689786286000
    },
    {
        "time": 1689786289000,
        "value": 139.644,
        "_internal_originalTime": 1689786289000
    },
    {
        "time": 1689786290000,
        "value": 139.647,
        "_internal_originalTime": 1689786290000
    },
    {
        "time": 1689786293000,
        "value": 139.645,
        "_internal_originalTime": 1689786293000
    },
    {
        "time": 1689786295000,
        "value": 139.644,
        "_internal_originalTime": 1689786295000
    },
    {
        "time": 1689786297000,
        "value": 139.649,
        "_internal_originalTime": 1689786297000
    },
    {
        "time": 1689786299000,
        "value": 139.656,
        "_internal_originalTime": 1689786299000
    },
    {
        "time": 1689786300000,
        "value": 139.659,
        "_internal_originalTime": 1689786300000
    },
    {
        "time": 1689786301000,
        "value": 139.66,
        "_internal_originalTime": 1689786301000
    },
    {
        "time": 1689786302000,
        "value": 139.647,
        "_internal_originalTime": 1689786302000
    },
    {
        "time": 1689786303000,
        "value": 139.647,
        "_internal_originalTime": 1689786303000
    },
    {
        "time": 1689786304000,
        "value": 139.651,
        "_internal_originalTime": 1689786304000
    },
    {
        "time": 1689786306000,
        "value": 139.648,
        "_internal_originalTime": 1689786306000
    },
    {
        "time": 1689786307000,
        "value": 139.649,
        "_internal_originalTime": 1689786307000
    },
    {
        "time": 1689786308000,
        "value": 139.648,
        "_internal_originalTime": 1689786308000
    },
    {
        "time": 1689786309000,
        "value": 139.66,
        "_internal_originalTime": 1689786309000
    },
    {
        "time": 1689786310000,
        "value": 139.656,
        "_internal_originalTime": 1689786310000
    },
    {
        "time": 1689786312000,
        "value": 139.659,
        "_internal_originalTime": 1689786312000
    },
    {
        "time": 1689786313000,
        "value": 139.659,
        "_internal_originalTime": 1689786313000
    },
    {
        "time": 1689786314000,
        "value": 139.652,
        "_internal_originalTime": 1689786314000
    },
    {
        "time": 1689786315000,
        "value": 139.653,
        "_internal_originalTime": 1689786315000
    },
    {
        "time": 1689786316000,
        "value": 139.651,
        "_internal_originalTime": 1689786316000
    },
    {
        "time": 1689786317000,
        "value": 139.653,
        "_internal_originalTime": 1689786317000
    },
    {
        "time": 1689786319000,
        "value": 139.651,
        "_internal_originalTime": 1689786319000
    },
    {
        "time": 1689786320000,
        "value": 139.65,
        "_internal_originalTime": 1689786320000
    },
    {
        "time": 1689786321000,
        "value": 139.649,
        "_internal_originalTime": 1689786321000
    },
    {
        "time": 1689786322000,
        "value": 139.648,
        "_internal_originalTime": 1689786322000
    },
    {
        "time": 1689786324000,
        "value": 139.649,
        "_internal_originalTime": 1689786324000
    },
    {
        "time": 1689786326000,
        "value": 139.653,
        "_internal_originalTime": 1689786326000
    },
    {
        "time": 1689786327000,
        "value": 139.645,
        "_internal_originalTime": 1689786327000
    },
    {
        "time": 1689786328000,
        "value": 139.65,
        "_internal_originalTime": 1689786328000
    },
    {
        "time": 1689786330000,
        "value": 139.651,
        "_internal_originalTime": 1689786330000
    },
    {
        "time": 1689786332000,
        "value": 139.654,
        "_internal_originalTime": 1689786332000
    },
    {
        "time": 1689786333000,
        "value": 139.657,
        "_internal_originalTime": 1689786333000
    },
    {
        "time": 1689786335000,
        "value": 139.658,
        "_internal_originalTime": 1689786335000
    },
    {
        "time": 1689786336000,
        "value": 139.659,
        "_internal_originalTime": 1689786336000
    },
    {
        "time": 1689786337000,
        "value": 139.659,
        "_internal_originalTime": 1689786337000
    },
    {
        "time": 1689786339000,
        "value": 139.656,
        "_internal_originalTime": 1689786339000
    },
    {
        "time": 1689786340000,
        "value": 139.651,
        "_internal_originalTime": 1689786340000
    },
    {
        "time": 1689786342000,
        "value": 139.648,
        "_internal_originalTime": 1689786342000
    },
    {
        "time": 1689786344000,
        "value": 139.645,
        "_internal_originalTime": 1689786344000
    },
    {
        "time": 1689786345000,
        "value": 139.648,
        "_internal_originalTime": 1689786345000
    },
    {
        "time": 1689786346000,
        "value": 139.648,
        "_internal_originalTime": 1689786346000
    },
    {
        "time": 1689786347000,
        "value": 139.648,
        "_internal_originalTime": 1689786347000
    },
    {
        "time": 1689786348000,
        "value": 139.645,
        "_internal_originalTime": 1689786348000
    },
    {
        "time": 1689786350000,
        "value": 139.644,
        "_internal_originalTime": 1689786350000
    },
    {
        "time": 1689786351000,
        "value": 139.655,
        "_internal_originalTime": 1689786351000
    },
    {
        "time": 1689786352000,
        "value": 139.653,
        "_internal_originalTime": 1689786352000
    },
    {
        "time": 1689786353000,
        "value": 139.654,
        "_internal_originalTime": 1689786353000
    },
    {
        "time": 1689786355000,
        "value": 139.651,
        "_internal_originalTime": 1689786355000
    },
    {
        "time": 1689786358000,
        "value": 139.656,
        "_internal_originalTime": 1689786358000
    },
    {
        "time": 1689786359000,
        "value": 139.658,
        "_internal_originalTime": 1689786359000
    },
    {
        "time": 1689786360000,
        "value": 139.657,
        "_internal_originalTime": 1689786360000
    },
    {
        "time": 1689786362000,
        "value": 139.651,
        "_internal_originalTime": 1689786362000
    },
    {
        "time": 1689786363000,
        "value": 139.65,
        "_internal_originalTime": 1689786363000
    },
    {
        "time": 1689786365000,
        "value": 139.65,
        "_internal_originalTime": 1689786365000
    },
    {
        "time": 1689786366000,
        "value": 139.654,
        "_internal_originalTime": 1689786366000
    },
    {
        "time": 1689786367000,
        "value": 139.655,
        "_internal_originalTime": 1689786367000
    },
    {
        "time": 1689786369000,
        "value": 139.655,
        "_internal_originalTime": 1689786369000
    },
    {
        "time": 1689786370000,
        "value": 139.655,
        "_internal_originalTime": 1689786370000
    },
    {
        "time": 1689786371000,
        "value": 139.651,
        "_internal_originalTime": 1689786371000
    },
    {
        "time": 1689786372000,
        "value": 139.646,
        "_internal_originalTime": 1689786372000
    },
    {
        "time": 1689786375000,
        "value": 139.645,
        "_internal_originalTime": 1689786375000
    },
    {
        "time": 1689786377000,
        "value": 139.651,
        "_internal_originalTime": 1689786377000
    },
    {
        "time": 1689786378000,
        "value": 139.648,
        "_internal_originalTime": 1689786378000
    },
    {
        "time": 1689786379000,
        "value": 139.657,
        "_internal_originalTime": 1689786379000
    },
    {
        "time": 1689786380000,
        "value": 139.652,
        "_internal_originalTime": 1689786380000
    },
    {
        "time": 1689786381000,
        "value": 139.654,
        "_internal_originalTime": 1689786381000
    },
    {
        "time": 1689786382000,
        "value": 139.64,
        "_internal_originalTime": 1689786382000
    },
    {
        "time": 1689786383000,
        "value": 139.642,
        "_internal_originalTime": 1689786383000
    },
    {
        "time": 1689786385000,
        "value": 139.64,
        "_internal_originalTime": 1689786385000
    },
    {
        "time": 1689786386000,
        "value": 139.639,
        "_internal_originalTime": 1689786386000
    },
    {
        "time": 1689786387000,
        "value": 139.635,
        "_internal_originalTime": 1689786387000
    },
    {
        "time": 1689786392000,
        "value": 139.635,
        "_internal_originalTime": 1689786392000
    },
    {
        "time": 1689786393000,
        "value": 139.634,
        "_internal_originalTime": 1689786393000
    },
    {
        "time": 1689786394000,
        "value": 139.635,
        "_internal_originalTime": 1689786394000
    },
    {
        "time": 1689786395000,
        "value": 139.638,
        "_internal_originalTime": 1689786395000
    },
    {
        "time": 1689786397000,
        "value": 139.636,
        "_internal_originalTime": 1689786397000
    },
    {
        "time": 1689786398000,
        "value": 139.631,
        "_internal_originalTime": 1689786398000
    },
    {
        "time": 1689786400000,
        "value": 139.645,
        "_internal_originalTime": 1689786400000
    },
    {
        "time": 1689786401000,
        "value": 139.648,
        "_internal_originalTime": 1689786401000
    },
    {
        "time": 1689786402000,
        "value": 139.648,
        "_internal_originalTime": 1689786402000
    },
    {
        "time": 1689786403000,
        "value": 139.648,
        "_internal_originalTime": 1689786403000
    },
    {
        "time": 1689786404000,
        "value": 139.647,
        "_internal_originalTime": 1689786404000
    },
    {
        "time": 1689786405000,
        "value": 139.648,
        "_internal_originalTime": 1689786405000
    },
    {
        "time": 1689786406000,
        "value": 139.644,
        "_internal_originalTime": 1689786406000
    },
    {
        "time": 1689786407000,
        "value": 139.645,
        "_internal_originalTime": 1689786407000
    },
    {
        "time": 1689786408000,
        "value": 139.644,
        "_internal_originalTime": 1689786408000
    },
    {
        "time": 1689786409000,
        "value": 139.644,
        "_internal_originalTime": 1689786409000
    },
    {
        "time": 1689786411000,
        "value": 139.643,
        "_internal_originalTime": 1689786411000
    },
    {
        "time": 1689786413000,
        "value": 139.642,
        "_internal_originalTime": 1689786413000
    },
    {
        "time": 1689786414000,
        "value": 139.643,
        "_internal_originalTime": 1689786414000
    },
    {
        "time": 1689786416000,
        "value": 139.643,
        "_internal_originalTime": 1689786416000
    },
    {
        "time": 1689786418000,
        "value": 139.64,
        "_internal_originalTime": 1689786418000
    },
    {
        "time": 1689786420000,
        "value": 139.643,
        "_internal_originalTime": 1689786420000
    },
    {
        "time": 1689786422000,
        "value": 139.638,
        "_internal_originalTime": 1689786422000
    },
    {
        "time": 1689786423000,
        "value": 139.638,
        "_internal_originalTime": 1689786423000
    },
    {
        "time": 1689786424000,
        "value": 139.636,
        "_internal_originalTime": 1689786424000
    },
    {
        "time": 1689786425000,
        "value": 139.638,
        "_internal_originalTime": 1689786425000
    },
    {
        "time": 1689786426000,
        "value": 139.636,
        "_internal_originalTime": 1689786426000
    },
    {
        "time": 1689786428000,
        "value": 139.634,
        "_internal_originalTime": 1689786428000
    },
    {
        "time": 1689786430000,
        "value": 139.634,
        "_internal_originalTime": 1689786430000
    },
    {
        "time": 1689786431000,
        "value": 139.637,
        "_internal_originalTime": 1689786431000
    },
    {
        "time": 1689786432000,
        "value": 139.634,
        "_internal_originalTime": 1689786432000
    },
    {
        "time": 1689786434000,
        "value": 139.637,
        "_internal_originalTime": 1689786434000
    },
    {
        "time": 1689786436000,
        "value": 139.637,
        "_internal_originalTime": 1689786436000
    },
    {
        "time": 1689786437000,
        "value": 139.639,
        "_internal_originalTime": 1689786437000
    },
    {
        "time": 1689786438000,
        "value": 139.636,
        "_internal_originalTime": 1689786438000
    },
    {
        "time": 1689786439000,
        "value": 139.64,
        "_internal_originalTime": 1689786439000
    },
    {
        "time": 1689786440000,
        "value": 139.641,
        "_internal_originalTime": 1689786440000
    },
    {
        "time": 1689786442000,
        "value": 139.641,
        "_internal_originalTime": 1689786442000
    },
    {
        "time": 1689786443000,
        "value": 139.642,
        "_internal_originalTime": 1689786443000
    },
    {
        "time": 1689786445000,
        "value": 139.645,
        "_internal_originalTime": 1689786445000
    },
    {
        "time": 1689786447000,
        "value": 139.638,
        "_internal_originalTime": 1689786447000
    },
    {
        "time": 1689786448000,
        "value": 139.64,
        "_internal_originalTime": 1689786448000
    },
    {
        "time": 1689786449000,
        "value": 139.639,
        "_internal_originalTime": 1689786449000
    },
    {
        "time": 1689786450000,
        "value": 139.634,
        "_internal_originalTime": 1689786450000
    },
    {
        "time": 1689786451000,
        "value": 139.638,
        "_internal_originalTime": 1689786451000
    },
    {
        "time": 1689786453000,
        "value": 139.635,
        "_internal_originalTime": 1689786453000
    },
    {
        "time": 1689786454000,
        "value": 139.633,
        "_internal_originalTime": 1689786454000
    },
    {
        "time": 1689786455000,
        "value": 139.633,
        "_internal_originalTime": 1689786455000
    },
    {
        "time": 1689786458000,
        "value": 139.631,
        "_internal_originalTime": 1689786458000
    },
    {
        "time": 1689786459000,
        "value": 139.633,
        "_internal_originalTime": 1689786459000
    },
    {
        "time": 1689786461000,
        "value": 139.635,
        "_internal_originalTime": 1689786461000
    },
    {
        "time": 1689786462000,
        "value": 139.635,
        "_internal_originalTime": 1689786462000
    },
    {
        "time": 1689786464000,
        "value": 139.64,
        "_internal_originalTime": 1689786464000
    },
    {
        "time": 1689786465000,
        "value": 139.646,
        "_internal_originalTime": 1689786465000
    },
    {
        "time": 1689786467000,
        "value": 139.646,
        "_internal_originalTime": 1689786467000
    },
    {
        "time": 1689786469000,
        "value": 139.642,
        "_internal_originalTime": 1689786469000
    },
    {
        "time": 1689786470000,
        "value": 139.642,
        "_internal_originalTime": 1689786470000
    },
    {
        "time": 1689786471000,
        "value": 139.64,
        "_internal_originalTime": 1689786471000
    },
    {
        "time": 1689786473000,
        "value": 139.638,
        "_internal_originalTime": 1689786473000
    },
    {
        "time": 1689786474000,
        "value": 139.642,
        "_internal_originalTime": 1689786474000
    },
    {
        "time": 1689786476000,
        "value": 139.636,
        "_internal_originalTime": 1689786476000
    },
    {
        "time": 1689786477000,
        "value": 139.644,
        "_internal_originalTime": 1689786477000
    },
    {
        "time": 1689786480000,
        "value": 139.64,
        "_internal_originalTime": 1689786480000
    },
    {
        "time": 1689786482000,
        "value": 139.636,
        "_internal_originalTime": 1689786482000
    },
    {
        "time": 1689786483000,
        "value": 139.642,
        "_internal_originalTime": 1689786483000
    },
    {
        "time": 1689786484000,
        "value": 139.636,
        "_internal_originalTime": 1689786484000
    },
    {
        "time": 1689786485000,
        "value": 139.64,
        "_internal_originalTime": 1689786485000
    },
    {
        "time": 1689786486000,
        "value": 139.636,
        "_internal_originalTime": 1689786486000
    },
    {
        "time": 1689786487000,
        "value": 139.635,
        "_internal_originalTime": 1689786487000
    },
    {
        "time": 1689786488000,
        "value": 139.637,
        "_internal_originalTime": 1689786488000
    },
    {
        "time": 1689786490000,
        "value": 139.638,
        "_internal_originalTime": 1689786490000
    },
    {
        "time": 1689786491000,
        "value": 139.629,
        "_internal_originalTime": 1689786491000
    },
    {
        "time": 1689786492000,
        "value": 139.633,
        "_internal_originalTime": 1689786492000
    },
    {
        "time": 1689786493000,
        "value": 139.634,
        "_internal_originalTime": 1689786493000
    },
    {
        "time": 1689786494000,
        "value": 139.637,
        "_internal_originalTime": 1689786494000
    },
    {
        "time": 1689786495000,
        "value": 139.632,
        "_internal_originalTime": 1689786495000
    },
    {
        "time": 1689786497000,
        "value": 139.634,
        "_internal_originalTime": 1689786497000
    },
    {
        "time": 1689786498000,
        "value": 139.636,
        "_internal_originalTime": 1689786498000
    },
    {
        "time": 1689786500000,
        "value": 139.644,
        "_internal_originalTime": 1689786500000
    },
    {
        "time": 1689786501000,
        "value": 139.64,
        "_internal_originalTime": 1689786501000
    },
    {
        "time": 1689786502000,
        "value": 139.638,
        "_internal_originalTime": 1689786502000
    },
    {
        "time": 1689786503000,
        "value": 139.639,
        "_internal_originalTime": 1689786503000
    },
    {
        "time": 1689786504000,
        "value": 139.641,
        "_internal_originalTime": 1689786504000
    },
    {
        "time": 1689786505000,
        "value": 139.641,
        "_internal_originalTime": 1689786505000
    },
    {
        "time": 1689786507000,
        "value": 139.643,
        "_internal_originalTime": 1689786507000
    },
    {
        "time": 1689786508000,
        "value": 139.642,
        "_internal_originalTime": 1689786508000
    },
    {
        "time": 1689786509000,
        "value": 139.64,
        "_internal_originalTime": 1689786509000
    },
    {
        "time": 1689786511000,
        "value": 139.644,
        "_internal_originalTime": 1689786511000
    },
    {
        "time": 1689786514000,
        "value": 139.638,
        "_internal_originalTime": 1689786514000
    },
    {
        "time": 1689786516000,
        "value": 139.639,
        "_internal_originalTime": 1689786516000
    },
    {
        "time": 1689786517000,
        "value": 139.632,
        "_internal_originalTime": 1689786517000
    },
    {
        "time": 1689786518000,
        "value": 139.633,
        "_internal_originalTime": 1689786518000
    },
    {
        "time": 1689786519000,
        "value": 139.632,
        "_internal_originalTime": 1689786519000
    },
    {
        "time": 1689786521000,
        "value": 139.631,
        "_internal_originalTime": 1689786521000
    },
    {
        "time": 1689786522000,
        "value": 139.627,
        "_internal_originalTime": 1689786522000
    },
    {
        "time": 1689786523000,
        "value": 139.628,
        "_internal_originalTime": 1689786523000
    },
    {
        "time": 1689786524000,
        "value": 139.627,
        "_internal_originalTime": 1689786524000
    },
    {
        "time": 1689786525000,
        "value": 139.631,
        "_internal_originalTime": 1689786525000
    },
    {
        "time": 1689786526000,
        "value": 139.625,
        "_internal_originalTime": 1689786526000
    },
    {
        "time": 1689786527000,
        "value": 139.631,
        "_internal_originalTime": 1689786527000
    },
    {
        "time": 1689786528000,
        "value": 139.625,
        "_internal_originalTime": 1689786528000
    },
    {
        "time": 1689786529000,
        "value": 139.632,
        "_internal_originalTime": 1689786529000
    },
    {
        "time": 1689786531000,
        "value": 139.633,
        "_internal_originalTime": 1689786531000
    },
    {
        "time": 1689786532000,
        "value": 139.635,
        "_internal_originalTime": 1689786532000
    },
    {
        "time": 1689786533000,
        "value": 139.642,
        "_internal_originalTime": 1689786533000
    },
    {
        "time": 1689786534000,
        "value": 139.642,
        "_internal_originalTime": 1689786534000
    },
    {
        "time": 1689786535000,
        "value": 139.639,
        "_internal_originalTime": 1689786535000
    },
    {
        "time": 1689786536000,
        "value": 139.636,
        "_internal_originalTime": 1689786536000
    },
    {
        "time": 1689786537000,
        "value": 139.64,
        "_internal_originalTime": 1689786537000
    },
    {
        "time": 1689786539000,
        "value": 139.638,
        "_internal_originalTime": 1689786539000
    },
    {
        "time": 1689786540000,
        "value": 139.632,
        "_internal_originalTime": 1689786540000
    },
    {
        "time": 1689786541000,
        "value": 139.628,
        "_internal_originalTime": 1689786541000
    },
    {
        "time": 1689786542000,
        "value": 139.632,
        "_internal_originalTime": 1689786542000
    },
    {
        "time": 1689786544000,
        "value": 139.627,
        "_internal_originalTime": 1689786544000
    },
    {
        "time": 1689786546000,
        "value": 139.635,
        "_internal_originalTime": 1689786546000
    },
    {
        "time": 1689786547000,
        "value": 139.634,
        "_internal_originalTime": 1689786547000
    },
    {
        "time": 1689786548000,
        "value": 139.632,
        "_internal_originalTime": 1689786548000
    },
    {
        "time": 1689786549000,
        "value": 139.63,
        "_internal_originalTime": 1689786549000
    },
    {
        "time": 1689786550000,
        "value": 139.633,
        "_internal_originalTime": 1689786550000
    },
    {
        "time": 1689786551000,
        "value": 139.634,
        "_internal_originalTime": 1689786551000
    },
    {
        "time": 1689786553000,
        "value": 139.63,
        "_internal_originalTime": 1689786553000
    },
    {
        "time": 1689786554000,
        "value": 139.63,
        "_internal_originalTime": 1689786554000
    },
    {
        "time": 1689786555000,
        "value": 139.632,
        "_internal_originalTime": 1689786555000
    },
    {
        "time": 1689786556000,
        "value": 139.631,
        "_internal_originalTime": 1689786556000
    },
    {
        "time": 1689786559000,
        "value": 139.64,
        "_internal_originalTime": 1689786559000
    },
    {
        "time": 1689786560000,
        "value": 139.641,
        "_internal_originalTime": 1689786560000
    },
    {
        "time": 1689786561000,
        "value": 139.637,
        "_internal_originalTime": 1689786561000
    },
    {
        "time": 1689786562000,
        "value": 139.638,
        "_internal_originalTime": 1689786562000
    },
    {
        "time": 1689786563000,
        "value": 139.645,
        "_internal_originalTime": 1689786563000
    },
    {
        "time": 1689786564000,
        "value": 139.647,
        "_internal_originalTime": 1689786564000
    },
    {
        "time": 1689786567000,
        "value": 139.646,
        "_internal_originalTime": 1689786567000
    },
    {
        "time": 1689786569000,
        "value": 139.644,
        "_internal_originalTime": 1689786569000
    },
    {
        "time": 1689786570000,
        "value": 139.647,
        "_internal_originalTime": 1689786570000
    },
    {
        "time": 1689786571000,
        "value": 139.642,
        "_internal_originalTime": 1689786571000
    },
    {
        "time": 1689786572000,
        "value": 139.645,
        "_internal_originalTime": 1689786572000
    },
    {
        "time": 1689786574000,
        "value": 139.644,
        "_internal_originalTime": 1689786574000
    },
    {
        "time": 1689786576000,
        "value": 139.643,
        "_internal_originalTime": 1689786576000
    },
    {
        "time": 1689786577000,
        "value": 139.638,
        "_internal_originalTime": 1689786577000
    },
    {
        "time": 1689786578000,
        "value": 139.642,
        "_internal_originalTime": 1689786578000
    },
    {
        "time": 1689786580000,
        "value": 139.642,
        "_internal_originalTime": 1689786580000
    },
    {
        "time": 1689786581000,
        "value": 139.643,
        "_internal_originalTime": 1689786581000
    },
    {
        "time": 1689786585000,
        "value": 139.642,
        "_internal_originalTime": 1689786585000
    },
    {
        "time": 1689786586000,
        "value": 139.639,
        "_internal_originalTime": 1689786586000
    },
    {
        "time": 1689786587000,
        "value": 139.642,
        "_internal_originalTime": 1689786587000
    },
    {
        "time": 1689786588000,
        "value": 139.642,
        "_internal_originalTime": 1689786588000
    },
    {
        "time": 1689786593000,
        "value": 139.657,
        "_internal_originalTime": 1689786593000
    },
    {
        "time": 1689786594000,
        "value": 139.658,
        "_internal_originalTime": 1689786594000
    },
    {
        "time": 1689786596000,
        "value": 139.658,
        "_internal_originalTime": 1689786596000
    },
    {
        "time": 1689786599000,
        "value": 139.659,
        "_internal_originalTime": 1689786599000
    },
    {
        "time": 1689786600000,
        "value": 139.659,
        "_internal_originalTime": 1689786600000
    },
    {
        "time": 1689786601000,
        "value": 139.654,
        "_internal_originalTime": 1689786601000
    },
    {
        "time": 1689786602000,
        "value": 139.66,
        "_internal_originalTime": 1689786602000
    },
    {
        "time": 1689786603000,
        "value": 139.663,
        "_internal_originalTime": 1689786603000
    },
    {
        "time": 1689786604000,
        "value": 139.666,
        "_internal_originalTime": 1689786604000
    },
    {
        "time": 1689786605000,
        "value": 139.666,
        "_internal_originalTime": 1689786605000
    },
    {
        "time": 1689786606000,
        "value": 139.663,
        "_internal_originalTime": 1689786606000
    },
    {
        "time": 1689786607000,
        "value": 139.67,
        "_internal_originalTime": 1689786607000
    },
    {
        "time": 1689786608000,
        "value": 139.665,
        "_internal_originalTime": 1689786608000
    },
    {
        "time": 1689786611000,
        "value": 139.67,
        "_internal_originalTime": 1689786611000
    },
    {
        "time": 1689786612000,
        "value": 139.664,
        "_internal_originalTime": 1689786612000
    },
    {
        "time": 1689786616000,
        "value": 139.667,
        "_internal_originalTime": 1689786616000
    },
    {
        "time": 1689786617000,
        "value": 139.666,
        "_internal_originalTime": 1689786617000
    },
    {
        "time": 1689786619000,
        "value": 139.666,
        "_internal_originalTime": 1689786619000
    },
    {
        "time": 1689786620000,
        "value": 139.665,
        "_internal_originalTime": 1689786620000
    },
    {
        "time": 1689786621000,
        "value": 139.659,
        "_internal_originalTime": 1689786621000
    },
    {
        "time": 1689786623000,
        "value": 139.663,
        "_internal_originalTime": 1689786623000
    },
    {
        "time": 1689786624000,
        "value": 139.666,
        "_internal_originalTime": 1689786624000
    },
    {
        "time": 1689786625000,
        "value": 139.668,
        "_internal_originalTime": 1689786625000
    },
    {
        "time": 1689786626000,
        "value": 139.666,
        "_internal_originalTime": 1689786626000
    },
    {
        "time": 1689786627000,
        "value": 139.668,
        "_internal_originalTime": 1689786627000
    },
    {
        "time": 1689786629000,
        "value": 139.662,
        "_internal_originalTime": 1689786629000
    },
    {
        "time": 1689786630000,
        "value": 139.663,
        "_internal_originalTime": 1689786630000
    },
    {
        "time": 1689786631000,
        "value": 139.667,
        "_internal_originalTime": 1689786631000
    },
    {
        "time": 1689786632000,
        "value": 139.669,
        "_internal_originalTime": 1689786632000
    },
    {
        "time": 1689786635000,
        "value": 139.668,
        "_internal_originalTime": 1689786635000
    },
    {
        "time": 1689786637000,
        "value": 139.67,
        "_internal_originalTime": 1689786637000
    },
    {
        "time": 1689786638000,
        "value": 139.665,
        "_internal_originalTime": 1689786638000
    },
    {
        "time": 1689786639000,
        "value": 139.659,
        "_internal_originalTime": 1689786639000
    },
    {
        "time": 1689786640000,
        "value": 139.658,
        "_internal_originalTime": 1689786640000
    },
    {
        "time": 1689786642000,
        "value": 139.665,
        "_internal_originalTime": 1689786642000
    },
    {
        "time": 1689786643000,
        "value": 139.67,
        "_internal_originalTime": 1689786643000
    },
    {
        "time": 1689786644000,
        "value": 139.669,
        "_internal_originalTime": 1689786644000
    },
    {
        "time": 1689786646000,
        "value": 139.662,
        "_internal_originalTime": 1689786646000
    },
    {
        "time": 1689786647000,
        "value": 139.664,
        "_internal_originalTime": 1689786647000
    },
    {
        "time": 1689786648000,
        "value": 139.672,
        "_internal_originalTime": 1689786648000
    },
    {
        "time": 1689786651000,
        "value": 139.674,
        "_internal_originalTime": 1689786651000
    },
    {
        "time": 1689786652000,
        "value": 139.674,
        "_internal_originalTime": 1689786652000
    },
    {
        "time": 1689786653000,
        "value": 139.674,
        "_internal_originalTime": 1689786653000
    },
    {
        "time": 1689786655000,
        "value": 139.671,
        "_internal_originalTime": 1689786655000
    },
    {
        "time": 1689786656000,
        "value": 139.675,
        "_internal_originalTime": 1689786656000
    },
    {
        "time": 1689786657000,
        "value": 139.669,
        "_internal_originalTime": 1689786657000
    },
    {
        "time": 1689786658000,
        "value": 139.68,
        "_internal_originalTime": 1689786658000
    },
    {
        "time": 1689786659000,
        "value": 139.676,
        "_internal_originalTime": 1689786659000
    },
    {
        "time": 1689786660000,
        "value": 139.674,
        "_internal_originalTime": 1689786660000
    },
    {
        "time": 1689786662000,
        "value": 139.68,
        "_internal_originalTime": 1689786662000
    },
    {
        "time": 1689786663000,
        "value": 139.676,
        "_internal_originalTime": 1689786663000
    },
    {
        "time": 1689786664000,
        "value": 139.68,
        "_internal_originalTime": 1689786664000
    },
    {
        "time": 1689786665000,
        "value": 139.676,
        "_internal_originalTime": 1689786665000
    },
    {
        "time": 1689786668000,
        "value": 139.677,
        "_internal_originalTime": 1689786668000
    },
    {
        "time": 1689786669000,
        "value": 139.679,
        "_internal_originalTime": 1689786669000
    },
    {
        "time": 1689786670000,
        "value": 139.68,
        "_internal_originalTime": 1689786670000
    },
    {
        "time": 1689786671000,
        "value": 139.676,
        "_internal_originalTime": 1689786671000
    },
    {
        "time": 1689786672000,
        "value": 139.681,
        "_internal_originalTime": 1689786672000
    },
    {
        "time": 1689786673000,
        "value": 139.684,
        "_internal_originalTime": 1689786673000
    },
    {
        "time": 1689786675000,
        "value": 139.684,
        "_internal_originalTime": 1689786675000
    },
    {
        "time": 1689786676000,
        "value": 139.684,
        "_internal_originalTime": 1689786676000
    },
    {
        "time": 1689786677000,
        "value": 139.684,
        "_internal_originalTime": 1689786677000
    },
    {
        "time": 1689786678000,
        "value": 139.684,
        "_internal_originalTime": 1689786678000
    },
    {
        "time": 1689786680000,
        "value": 139.686,
        "_internal_originalTime": 1689786680000
    },
    {
        "time": 1689786681000,
        "value": 139.696,
        "_internal_originalTime": 1689786681000
    },
    {
        "time": 1689786682000,
        "value": 139.692,
        "_internal_originalTime": 1689786682000
    },
    {
        "time": 1689786683000,
        "value": 139.694,
        "_internal_originalTime": 1689786683000
    },
    {
        "time": 1689786684000,
        "value": 139.694,
        "_internal_originalTime": 1689786684000
    },
    {
        "time": 1689786686000,
        "value": 139.687,
        "_internal_originalTime": 1689786686000
    },
    {
        "time": 1689786687000,
        "value": 139.69,
        "_internal_originalTime": 1689786687000
    },
    {
        "time": 1689786689000,
        "value": 139.684,
        "_internal_originalTime": 1689786689000
    },
    {
        "time": 1689786690000,
        "value": 139.685,
        "_internal_originalTime": 1689786690000
    },
    {
        "time": 1689786692000,
        "value": 139.69,
        "_internal_originalTime": 1689786692000
    },
    {
        "time": 1689786693000,
        "value": 139.684,
        "_internal_originalTime": 1689786693000
    },
    {
        "time": 1689786695000,
        "value": 139.69,
        "_internal_originalTime": 1689786695000
    },
    {
        "time": 1689786697000,
        "value": 139.691,
        "_internal_originalTime": 1689786697000
    },
    {
        "time": 1689786698000,
        "value": 139.686,
        "_internal_originalTime": 1689786698000
    },
    {
        "time": 1689786699000,
        "value": 139.687,
        "_internal_originalTime": 1689786699000
    },
    {
        "time": 1689786700000,
        "value": 139.686,
        "_internal_originalTime": 1689786700000
    },
    {
        "time": 1689786703000,
        "value": 139.687,
        "_internal_originalTime": 1689786703000
    },
    {
        "time": 1689786705000,
        "value": 139.686,
        "_internal_originalTime": 1689786705000
    },
    {
        "time": 1689786706000,
        "value": 139.689,
        "_internal_originalTime": 1689786706000
    },
    {
        "time": 1689786707000,
        "value": 139.69,
        "_internal_originalTime": 1689786707000
    },
    {
        "time": 1689786708000,
        "value": 139.687,
        "_internal_originalTime": 1689786708000
    },
    {
        "time": 1689786709000,
        "value": 139.685,
        "_internal_originalTime": 1689786709000
    },
    {
        "time": 1689786710000,
        "value": 139.69,
        "_internal_originalTime": 1689786710000
    },
    {
        "time": 1689786712000,
        "value": 139.687,
        "_internal_originalTime": 1689786712000
    },
    {
        "time": 1689786713000,
        "value": 139.684,
        "_internal_originalTime": 1689786713000
    },
    {
        "time": 1689786715000,
        "value": 139.687,
        "_internal_originalTime": 1689786715000
    },
    {
        "time": 1689786719000,
        "value": 139.687,
        "_internal_originalTime": 1689786719000
    },
    {
        "time": 1689786720000,
        "value": 139.69,
        "_internal_originalTime": 1689786720000
    },
    {
        "time": 1689786722000,
        "value": 139.686,
        "_internal_originalTime": 1689786722000
    },
    {
        "time": 1689786723000,
        "value": 139.683,
        "_internal_originalTime": 1689786723000
    },
    {
        "time": 1689786724000,
        "value": 139.682,
        "_internal_originalTime": 1689786724000
    },
    {
        "time": 1689786725000,
        "value": 139.679,
        "_internal_originalTime": 1689786725000
    },
    {
        "time": 1689786726000,
        "value": 139.682,
        "_internal_originalTime": 1689786726000
    },
    {
        "time": 1689786727000,
        "value": 139.684,
        "_internal_originalTime": 1689786727000
    },
    {
        "time": 1689786728000,
        "value": 139.683,
        "_internal_originalTime": 1689786728000
    },
    {
        "time": 1689786729000,
        "value": 139.681,
        "_internal_originalTime": 1689786729000
    },
    {
        "time": 1689786730000,
        "value": 139.686,
        "_internal_originalTime": 1689786730000
    },
    {
        "time": 1689786731000,
        "value": 139.682,
        "_internal_originalTime": 1689786731000
    },
    {
        "time": 1689786733000,
        "value": 139.683,
        "_internal_originalTime": 1689786733000
    },
    {
        "time": 1689786734000,
        "value": 139.682,
        "_internal_originalTime": 1689786734000
    },
    {
        "time": 1689786735000,
        "value": 139.688,
        "_internal_originalTime": 1689786735000
    },
    {
        "time": 1689786736000,
        "value": 139.682,
        "_internal_originalTime": 1689786736000
    },
    {
        "time": 1689786737000,
        "value": 139.684,
        "_internal_originalTime": 1689786737000
    },
    {
        "time": 1689786738000,
        "value": 139.685,
        "_internal_originalTime": 1689786738000
    },
    {
        "time": 1689786742000,
        "value": 139.686,
        "_internal_originalTime": 1689786742000
    },
    {
        "time": 1689786743000,
        "value": 139.685,
        "_internal_originalTime": 1689786743000
    },
    {
        "time": 1689786744000,
        "value": 139.682,
        "_internal_originalTime": 1689786744000
    },
    {
        "time": 1689786747000,
        "value": 139.683,
        "_internal_originalTime": 1689786747000
    },
    {
        "time": 1689786748000,
        "value": 139.683,
        "_internal_originalTime": 1689786748000
    },
    {
        "time": 1689786749000,
        "value": 139.687,
        "_internal_originalTime": 1689786749000
    },
    {
        "time": 1689786750000,
        "value": 139.682,
        "_internal_originalTime": 1689786750000
    },
    {
        "time": 1689786751000,
        "value": 139.688,
        "_internal_originalTime": 1689786751000
    },
    {
        "time": 1689786756000,
        "value": 139.687,
        "_internal_originalTime": 1689786756000
    },
    {
        "time": 1689786758000,
        "value": 139.687,
        "_internal_originalTime": 1689786758000
    },
    {
        "time": 1689786759000,
        "value": 139.684,
        "_internal_originalTime": 1689786759000
    },
    {
        "time": 1689786763000,
        "value": 139.685,
        "_internal_originalTime": 1689786763000
    },
    {
        "time": 1689786766000,
        "value": 139.681,
        "_internal_originalTime": 1689786766000
    },
    {
        "time": 1689786769000,
        "value": 139.687,
        "_internal_originalTime": 1689786769000
    },
    {
        "time": 1689786771000,
        "value": 139.688,
        "_internal_originalTime": 1689786771000
    },
    {
        "time": 1689786773000,
        "value": 139.687,
        "_internal_originalTime": 1689786773000
    },
    {
        "time": 1689786780000,
        "value": 139.689,
        "_internal_originalTime": 1689786780000
    },
    {
        "time": 1689786781000,
        "value": 139.682,
        "_internal_originalTime": 1689786781000
    },
    {
        "time": 1689786782000,
        "value": 139.687,
        "_internal_originalTime": 1689786782000
    },
    {
        "time": 1689786783000,
        "value": 139.687,
        "_internal_originalTime": 1689786783000
    },
    {
        "time": 1689786785000,
        "value": 139.685,
        "_internal_originalTime": 1689786785000
    },
    {
        "time": 1689786786000,
        "value": 139.685,
        "_internal_originalTime": 1689786786000
    },
    {
        "time": 1689786788000,
        "value": 139.688,
        "_internal_originalTime": 1689786788000
    },
    {
        "time": 1689786789000,
        "value": 139.686,
        "_internal_originalTime": 1689786789000
    },
    {
        "time": 1689786790000,
        "value": 139.685,
        "_internal_originalTime": 1689786790000
    },
    {
        "time": 1689786791000,
        "value": 139.686,
        "_internal_originalTime": 1689786791000
    },
    {
        "time": 1689786792000,
        "value": 139.683,
        "_internal_originalTime": 1689786792000
    },
    {
        "time": 1689786794000,
        "value": 139.686,
        "_internal_originalTime": 1689786794000
    },
    {
        "time": 1689786795000,
        "value": 139.684,
        "_internal_originalTime": 1689786795000
    },
    {
        "time": 1689786796000,
        "value": 139.682,
        "_internal_originalTime": 1689786796000
    },
    {
        "time": 1689786799000,
        "value": 139.686,
        "_internal_originalTime": 1689786799000
    },
    {
        "time": 1689786800000,
        "value": 139.685,
        "_internal_originalTime": 1689786800000
    },
    {
        "time": 1689786801000,
        "value": 139.686,
        "_internal_originalTime": 1689786801000
    },
    {
        "time": 1689786802000,
        "value": 139.683,
        "_internal_originalTime": 1689786802000
    },
    {
        "time": 1689786804000,
        "value": 139.682,
        "_internal_originalTime": 1689786804000
    },
    {
        "time": 1689786805000,
        "value": 139.683,
        "_internal_originalTime": 1689786805000
    },
    {
        "time": 1689786806000,
        "value": 139.684,
        "_internal_originalTime": 1689786806000
    },
    {
        "time": 1689786807000,
        "value": 139.685,
        "_internal_originalTime": 1689786807000
    },
    {
        "time": 1689786808000,
        "value": 139.688,
        "_internal_originalTime": 1689786808000
    },
    {
        "time": 1689786810000,
        "value": 139.696,
        "_internal_originalTime": 1689786810000
    },
    {
        "time": 1689786811000,
        "value": 139.694,
        "_internal_originalTime": 1689786811000
    },
    {
        "time": 1689786812000,
        "value": 139.696,
        "_internal_originalTime": 1689786812000
    },
    {
        "time": 1689786813000,
        "value": 139.697,
        "_internal_originalTime": 1689786813000
    },
    {
        "time": 1689786814000,
        "value": 139.698,
        "_internal_originalTime": 1689786814000
    },
    {
        "time": 1689786816000,
        "value": 139.695,
        "_internal_originalTime": 1689786816000
    },
    {
        "time": 1689786817000,
        "value": 139.697,
        "_internal_originalTime": 1689786817000
    },
    {
        "time": 1689786819000,
        "value": 139.697,
        "_internal_originalTime": 1689786819000
    },
    {
        "time": 1689786820000,
        "value": 139.706,
        "_internal_originalTime": 1689786820000
    },
    {
        "time": 1689786822000,
        "value": 139.705,
        "_internal_originalTime": 1689786822000
    },
    {
        "time": 1689786826000,
        "value": 139.7,
        "_internal_originalTime": 1689786826000
    },
    {
        "time": 1689786827000,
        "value": 139.699,
        "_internal_originalTime": 1689786827000
    },
    {
        "time": 1689786828000,
        "value": 139.701,
        "_internal_originalTime": 1689786828000
    },
    {
        "time": 1689786829000,
        "value": 139.704,
        "_internal_originalTime": 1689786829000
    },
    {
        "time": 1689786830000,
        "value": 139.701,
        "_internal_originalTime": 1689786830000
    },
    {
        "time": 1689786831000,
        "value": 139.705,
        "_internal_originalTime": 1689786831000
    },
    {
        "time": 1689786833000,
        "value": 139.707,
        "_internal_originalTime": 1689786833000
    },
    {
        "time": 1689786834000,
        "value": 139.705,
        "_internal_originalTime": 1689786834000
    },
    {
        "time": 1689786835000,
        "value": 139.705,
        "_internal_originalTime": 1689786835000
    },
    {
        "time": 1689786838000,
        "value": 139.706,
        "_internal_originalTime": 1689786838000
    },
    {
        "time": 1689786839000,
        "value": 139.706,
        "_internal_originalTime": 1689786839000
    },
    {
        "time": 1689786840000,
        "value": 139.705,
        "_internal_originalTime": 1689786840000
    },
    {
        "time": 1689786841000,
        "value": 139.705,
        "_internal_originalTime": 1689786841000
    },
    {
        "time": 1689786842000,
        "value": 139.705,
        "_internal_originalTime": 1689786842000
    },
    {
        "time": 1689786843000,
        "value": 139.702,
        "_internal_originalTime": 1689786843000
    },
    {
        "time": 1689786844000,
        "value": 139.702,
        "_internal_originalTime": 1689786844000
    },
    {
        "time": 1689786845000,
        "value": 139.702,
        "_internal_originalTime": 1689786845000
    },
    {
        "time": 1689786848000,
        "value": 139.705,
        "_internal_originalTime": 1689786848000
    },
    {
        "time": 1689786849000,
        "value": 139.705,
        "_internal_originalTime": 1689786849000
    },
    {
        "time": 1689786851000,
        "value": 139.709,
        "_internal_originalTime": 1689786851000
    },
    {
        "time": 1689786852000,
        "value": 139.708,
        "_internal_originalTime": 1689786852000
    },
    {
        "time": 1689786854000,
        "value": 139.706,
        "_internal_originalTime": 1689786854000
    },
    {
        "time": 1689786856000,
        "value": 139.7,
        "_internal_originalTime": 1689786856000
    },
    {
        "time": 1689786857000,
        "value": 139.709,
        "_internal_originalTime": 1689786857000
    },
    {
        "time": 1689786859000,
        "value": 139.708,
        "_internal_originalTime": 1689786859000
    },
    {
        "time": 1689786860000,
        "value": 139.712,
        "_internal_originalTime": 1689786860000
    },
    {
        "time": 1689786862000,
        "value": 139.71,
        "_internal_originalTime": 1689786862000
    },
    {
        "time": 1689786863000,
        "value": 139.708,
        "_internal_originalTime": 1689786863000
    },
    {
        "time": 1689786864000,
        "value": 139.706,
        "_internal_originalTime": 1689786864000
    },
    {
        "time": 1689786866000,
        "value": 139.709,
        "_internal_originalTime": 1689786866000
    },
    {
        "time": 1689786867000,
        "value": 139.708,
        "_internal_originalTime": 1689786867000
    },
    {
        "time": 1689786868000,
        "value": 139.705,
        "_internal_originalTime": 1689786868000
    },
    {
        "time": 1689786869000,
        "value": 139.702,
        "_internal_originalTime": 1689786869000
    },
    {
        "time": 1689786870000,
        "value": 139.705,
        "_internal_originalTime": 1689786870000
    },
    {
        "time": 1689786872000,
        "value": 139.701,
        "_internal_originalTime": 1689786872000
    },
    {
        "time": 1689786873000,
        "value": 139.698,
        "_internal_originalTime": 1689786873000
    },
    {
        "time": 1689786874000,
        "value": 139.7,
        "_internal_originalTime": 1689786874000
    },
    {
        "time": 1689786876000,
        "value": 139.694,
        "_internal_originalTime": 1689786876000
    },
    {
        "time": 1689786877000,
        "value": 139.693,
        "_internal_originalTime": 1689786877000
    },
    {
        "time": 1689786878000,
        "value": 139.698,
        "_internal_originalTime": 1689786878000
    },
    {
        "time": 1689786881000,
        "value": 139.694,
        "_internal_originalTime": 1689786881000
    },
    {
        "time": 1689786883000,
        "value": 139.701,
        "_internal_originalTime": 1689786883000
    },
    {
        "time": 1689786884000,
        "value": 139.697,
        "_internal_originalTime": 1689786884000
    },
    {
        "time": 1689786885000,
        "value": 139.696,
        "_internal_originalTime": 1689786885000
    },
    {
        "time": 1689786887000,
        "value": 139.697,
        "_internal_originalTime": 1689786887000
    },
    {
        "time": 1689786889000,
        "value": 139.698,
        "_internal_originalTime": 1689786889000
    },
    {
        "time": 1689786890000,
        "value": 139.7,
        "_internal_originalTime": 1689786890000
    },
    {
        "time": 1689786891000,
        "value": 139.699,
        "_internal_originalTime": 1689786891000
    },
    {
        "time": 1689786892000,
        "value": 139.7,
        "_internal_originalTime": 1689786892000
    },
    {
        "time": 1689786894000,
        "value": 139.699,
        "_internal_originalTime": 1689786894000
    },
    {
        "time": 1689786895000,
        "value": 139.705,
        "_internal_originalTime": 1689786895000
    },
    {
        "time": 1689786896000,
        "value": 139.704,
        "_internal_originalTime": 1689786896000
    },
    {
        "time": 1689786897000,
        "value": 139.712,
        "_internal_originalTime": 1689786897000
    },
    {
        "time": 1689786898000,
        "value": 139.712,
        "_internal_originalTime": 1689786898000
    },
    {
        "time": 1689786901000,
        "value": 139.71,
        "_internal_originalTime": 1689786901000
    },
    {
        "time": 1689786902000,
        "value": 139.714,
        "_internal_originalTime": 1689786902000
    },
    {
        "time": 1689786904000,
        "value": 139.71,
        "_internal_originalTime": 1689786904000
    },
    {
        "time": 1689786905000,
        "value": 139.707,
        "_internal_originalTime": 1689786905000
    },
    {
        "time": 1689786906000,
        "value": 139.71,
        "_internal_originalTime": 1689786906000
    },
    {
        "time": 1689786907000,
        "value": 139.71,
        "_internal_originalTime": 1689786907000
    },
    {
        "time": 1689786908000,
        "value": 139.712,
        "_internal_originalTime": 1689786908000
    },
    {
        "time": 1689786909000,
        "value": 139.712,
        "_internal_originalTime": 1689786909000
    },
    {
        "time": 1689786911000,
        "value": 139.707,
        "_internal_originalTime": 1689786911000
    },
    {
        "time": 1689786912000,
        "value": 139.707,
        "_internal_originalTime": 1689786912000
    },
    {
        "time": 1689786914000,
        "value": 139.711,
        "_internal_originalTime": 1689786914000
    },
    {
        "time": 1689786916000,
        "value": 139.707,
        "_internal_originalTime": 1689786916000
    },
    {
        "time": 1689786917000,
        "value": 139.709,
        "_internal_originalTime": 1689786917000
    },
    {
        "time": 1689786923000,
        "value": 139.708,
        "_internal_originalTime": 1689786923000
    },
    {
        "time": 1689786929000,
        "value": 139.704,
        "_internal_originalTime": 1689786929000
    },
    {
        "time": 1689786931000,
        "value": 139.705,
        "_internal_originalTime": 1689786931000
    },
    {
        "time": 1689786932000,
        "value": 139.701,
        "_internal_originalTime": 1689786932000
    },
    {
        "time": 1689786933000,
        "value": 139.698,
        "_internal_originalTime": 1689786933000
    },
    {
        "time": 1689786935000,
        "value": 139.698,
        "_internal_originalTime": 1689786935000
    },
    {
        "time": 1689786936000,
        "value": 139.705,
        "_internal_originalTime": 1689786936000
    },
    {
        "time": 1689786938000,
        "value": 139.702,
        "_internal_originalTime": 1689786938000
    },
    {
        "time": 1689786939000,
        "value": 139.7,
        "_internal_originalTime": 1689786939000
    },
    {
        "time": 1689786945000,
        "value": 139.706,
        "_internal_originalTime": 1689786945000
    },
    {
        "time": 1689786946000,
        "value": 139.708,
        "_internal_originalTime": 1689786946000
    },
    {
        "time": 1689786947000,
        "value": 139.709,
        "_internal_originalTime": 1689786947000
    },
    {
        "time": 1689786948000,
        "value": 139.702,
        "_internal_originalTime": 1689786948000
    },
    {
        "time": 1689786950000,
        "value": 139.707,
        "_internal_originalTime": 1689786950000
    },
    {
        "time": 1689786951000,
        "value": 139.705,
        "_internal_originalTime": 1689786951000
    },
    {
        "time": 1689786957000,
        "value": 139.701,
        "_internal_originalTime": 1689786957000
    },
    {
        "time": 1689786958000,
        "value": 139.701,
        "_internal_originalTime": 1689786958000
    },
    {
        "time": 1689786961000,
        "value": 139.695,
        "_internal_originalTime": 1689786961000
    },
    {
        "time": 1689786962000,
        "value": 139.692,
        "_internal_originalTime": 1689786962000
    },
    {
        "time": 1689786963000,
        "value": 139.694,
        "_internal_originalTime": 1689786963000
    },
    {
        "time": 1689786964000,
        "value": 139.698,
        "_internal_originalTime": 1689786964000
    },
    {
        "time": 1689786965000,
        "value": 139.698,
        "_internal_originalTime": 1689786965000
    },
    {
        "time": 1689786967000,
        "value": 139.695,
        "_internal_originalTime": 1689786967000
    },
    {
        "time": 1689786968000,
        "value": 139.695,
        "_internal_originalTime": 1689786968000
    },
    {
        "time": 1689786970000,
        "value": 139.698,
        "_internal_originalTime": 1689786970000
    },
    {
        "time": 1689786971000,
        "value": 139.698,
        "_internal_originalTime": 1689786971000
    },
    {
        "time": 1689786973000,
        "value": 139.696,
        "_internal_originalTime": 1689786973000
    },
    {
        "time": 1689786974000,
        "value": 139.695,
        "_internal_originalTime": 1689786974000
    },
    {
        "time": 1689786976000,
        "value": 139.692,
        "_internal_originalTime": 1689786976000
    },
    {
        "time": 1689786977000,
        "value": 139.695,
        "_internal_originalTime": 1689786977000
    },
    {
        "time": 1689786986000,
        "value": 139.695,
        "_internal_originalTime": 1689786986000
    },
    {
        "time": 1689786987000,
        "value": 139.697,
        "_internal_originalTime": 1689786987000
    },
    {
        "time": 1689786988000,
        "value": 139.699,
        "_internal_originalTime": 1689786988000
    },
    {
        "time": 1689786990000,
        "value": 139.7,
        "_internal_originalTime": 1689786990000
    },
    {
        "time": 1689786991000,
        "value": 139.699,
        "_internal_originalTime": 1689786991000
    },
    {
        "time": 1689786992000,
        "value": 139.7,
        "_internal_originalTime": 1689786992000
    },
    {
        "time": 1689786993000,
        "value": 139.696,
        "_internal_originalTime": 1689786993000
    },
    {
        "time": 1689786994000,
        "value": 139.694,
        "_internal_originalTime": 1689786994000
    },
    {
        "time": 1689786995000,
        "value": 139.696,
        "_internal_originalTime": 1689786995000
    },
    {
        "time": 1689786997000,
        "value": 139.697,
        "_internal_originalTime": 1689786997000
    },
    {
        "time": 1689786998000,
        "value": 139.694,
        "_internal_originalTime": 1689786998000
    },
    {
        "time": 1689787000000,
        "value": 139.694,
        "_internal_originalTime": 1689787000000
    },
    {
        "time": 1689787001000,
        "value": 139.695,
        "_internal_originalTime": 1689787001000
    },
    {
        "time": 1689787006000,
        "value": 139.692,
        "_internal_originalTime": 1689787006000
    },
    {
        "time": 1689787008000,
        "value": 139.696,
        "_internal_originalTime": 1689787008000
    },
    {
        "time": 1689787009000,
        "value": 139.692,
        "_internal_originalTime": 1689787009000
    },
    {
        "time": 1689787010000,
        "value": 139.696,
        "_internal_originalTime": 1689787010000
    },
    {
        "time": 1689787013000,
        "value": 139.698,
        "_internal_originalTime": 1689787013000
    },
    {
        "time": 1689787014000,
        "value": 139.692,
        "_internal_originalTime": 1689787014000
    },
    {
        "time": 1689787015000,
        "value": 139.696,
        "_internal_originalTime": 1689787015000
    },
    {
        "time": 1689787016000,
        "value": 139.696,
        "_internal_originalTime": 1689787016000
    },
    {
        "time": 1689787017000,
        "value": 139.696,
        "_internal_originalTime": 1689787017000
    },
    {
        "time": 1689787020000,
        "value": 139.695,
        "_internal_originalTime": 1689787020000
    },
    {
        "time": 1689787022000,
        "value": 139.692,
        "_internal_originalTime": 1689787022000
    },
    {
        "time": 1689787028000,
        "value": 139.695,
        "_internal_originalTime": 1689787028000
    },
    {
        "time": 1689787029000,
        "value": 139.701,
        "_internal_originalTime": 1689787029000
    },
    {
        "time": 1689787030000,
        "value": 139.698,
        "_internal_originalTime": 1689787030000
    },
    {
        "time": 1689787031000,
        "value": 139.701,
        "_internal_originalTime": 1689787031000
    },
    {
        "time": 1689787032000,
        "value": 139.696,
        "_internal_originalTime": 1689787032000
    },
    {
        "time": 1689787034000,
        "value": 139.694,
        "_internal_originalTime": 1689787034000
    },
    {
        "time": 1689787035000,
        "value": 139.698,
        "_internal_originalTime": 1689787035000
    },
    {
        "time": 1689787036000,
        "value": 139.699,
        "_internal_originalTime": 1689787036000
    },
    {
        "time": 1689787042000,
        "value": 139.697,
        "_internal_originalTime": 1689787042000
    },
    {
        "time": 1689787043000,
        "value": 139.702,
        "_internal_originalTime": 1689787043000
    },
    {
        "time": 1689787045000,
        "value": 139.699,
        "_internal_originalTime": 1689787045000
    },
    {
        "time": 1689787046000,
        "value": 139.7,
        "_internal_originalTime": 1689787046000
    },
    {
        "time": 1689787047000,
        "value": 139.7,
        "_internal_originalTime": 1689787047000
    },
    {
        "time": 1689787048000,
        "value": 139.699,
        "_internal_originalTime": 1689787048000
    },
    {
        "time": 1689787049000,
        "value": 139.695,
        "_internal_originalTime": 1689787049000
    },
    {
        "time": 1689787051000,
        "value": 139.698,
        "_internal_originalTime": 1689787051000
    },
    {
        "time": 1689787052000,
        "value": 139.699,
        "_internal_originalTime": 1689787052000
    },
    {
        "time": 1689787053000,
        "value": 139.7,
        "_internal_originalTime": 1689787053000
    },
    {
        "time": 1689787054000,
        "value": 139.7,
        "_internal_originalTime": 1689787054000
    },
    {
        "time": 1689787056000,
        "value": 139.696,
        "_internal_originalTime": 1689787056000
    },
    {
        "time": 1689787057000,
        "value": 139.698,
        "_internal_originalTime": 1689787057000
    },
    {
        "time": 1689787058000,
        "value": 139.69,
        "_internal_originalTime": 1689787058000
    },
    {
        "time": 1689787065000,
        "value": 139.691,
        "_internal_originalTime": 1689787065000
    },
    {
        "time": 1689787066000,
        "value": 139.695,
        "_internal_originalTime": 1689787066000
    },
    {
        "time": 1689787067000,
        "value": 139.691,
        "_internal_originalTime": 1689787067000
    },
    {
        "time": 1689787068000,
        "value": 139.69,
        "_internal_originalTime": 1689787068000
    },
    {
        "time": 1689787070000,
        "value": 139.686,
        "_internal_originalTime": 1689787070000
    },
    {
        "time": 1689787071000,
        "value": 139.691,
        "_internal_originalTime": 1689787071000
    },
    {
        "time": 1689787072000,
        "value": 139.691,
        "_internal_originalTime": 1689787072000
    },
    {
        "time": 1689787073000,
        "value": 139.693,
        "_internal_originalTime": 1689787073000
    },
    {
        "time": 1689787076000,
        "value": 139.689,
        "_internal_originalTime": 1689787076000
    },
    {
        "time": 1689787079000,
        "value": 139.691,
        "_internal_originalTime": 1689787079000
    },
    {
        "time": 1689787080000,
        "value": 139.691,
        "_internal_originalTime": 1689787080000
    },
    {
        "time": 1689787081000,
        "value": 139.683,
        "_internal_originalTime": 1689787081000
    },
    {
        "time": 1689787083000,
        "value": 139.682,
        "_internal_originalTime": 1689787083000
    },
    {
        "time": 1689787084000,
        "value": 139.675,
        "_internal_originalTime": 1689787084000
    },
    {
        "time": 1689787085000,
        "value": 139.676,
        "_internal_originalTime": 1689787085000
    },
    {
        "time": 1689787086000,
        "value": 139.677,
        "_internal_originalTime": 1689787086000
    },
    {
        "time": 1689787088000,
        "value": 139.672,
        "_internal_originalTime": 1689787088000
    },
    {
        "time": 1689787089000,
        "value": 139.671,
        "_internal_originalTime": 1689787089000
    },
    {
        "time": 1689787090000,
        "value": 139.667,
        "_internal_originalTime": 1689787090000
    },
    {
        "time": 1689787091000,
        "value": 139.666,
        "_internal_originalTime": 1689787091000
    },
    {
        "time": 1689787092000,
        "value": 139.661,
        "_internal_originalTime": 1689787092000
    },
    {
        "time": 1689787094000,
        "value": 139.669,
        "_internal_originalTime": 1689787094000
    },
    {
        "time": 1689787096000,
        "value": 139.657,
        "_internal_originalTime": 1689787096000
    },
    {
        "time": 1689787097000,
        "value": 139.66,
        "_internal_originalTime": 1689787097000
    },
    {
        "time": 1689787098000,
        "value": 139.659,
        "_internal_originalTime": 1689787098000
    },
    {
        "time": 1689787099000,
        "value": 139.661,
        "_internal_originalTime": 1689787099000
    },
    {
        "time": 1689787100000,
        "value": 139.664,
        "_internal_originalTime": 1689787100000
    },
    {
        "time": 1689787101000,
        "value": 139.66,
        "_internal_originalTime": 1689787101000
    },
    {
        "time": 1689787103000,
        "value": 139.664,
        "_internal_originalTime": 1689787103000
    },
    {
        "time": 1689787104000,
        "value": 139.668,
        "_internal_originalTime": 1689787104000
    },
    {
        "time": 1689787106000,
        "value": 139.669,
        "_internal_originalTime": 1689787106000
    },
    {
        "time": 1689787108000,
        "value": 139.67,
        "_internal_originalTime": 1689787108000
    },
    {
        "time": 1689787110000,
        "value": 139.664,
        "_internal_originalTime": 1689787110000
    },
    {
        "time": 1689787111000,
        "value": 139.671,
        "_internal_originalTime": 1689787111000
    },
    {
        "time": 1689787112000,
        "value": 139.672,
        "_internal_originalTime": 1689787112000
    },
    {
        "time": 1689787113000,
        "value": 139.671,
        "_internal_originalTime": 1689787113000
    },
    {
        "time": 1689787114000,
        "value": 139.671,
        "_internal_originalTime": 1689787114000
    },
    {
        "time": 1689787117000,
        "value": 139.676,
        "_internal_originalTime": 1689787117000
    },
    {
        "time": 1689787118000,
        "value": 139.672,
        "_internal_originalTime": 1689787118000
    },
    {
        "time": 1689787119000,
        "value": 139.671,
        "_internal_originalTime": 1689787119000
    },
    {
        "time": 1689787120000,
        "value": 139.666,
        "_internal_originalTime": 1689787120000
    },
    {
        "time": 1689787126000,
        "value": 139.664,
        "_internal_originalTime": 1689787126000
    },
    {
        "time": 1689787127000,
        "value": 139.666,
        "_internal_originalTime": 1689787127000
    },
    {
        "time": 1689787128000,
        "value": 139.672,
        "_internal_originalTime": 1689787128000
    },
    {
        "time": 1689787130000,
        "value": 139.673,
        "_internal_originalTime": 1689787130000
    },
    {
        "time": 1689787134000,
        "value": 139.669,
        "_internal_originalTime": 1689787134000
    },
    {
        "time": 1689787135000,
        "value": 139.677,
        "_internal_originalTime": 1689787135000
    },
    {
        "time": 1689787136000,
        "value": 139.673,
        "_internal_originalTime": 1689787136000
    },
    {
        "time": 1689787142000,
        "value": 139.672,
        "_internal_originalTime": 1689787142000
    },
    {
        "time": 1689787145000,
        "value": 139.673,
        "_internal_originalTime": 1689787145000
    },
    {
        "time": 1689787146000,
        "value": 139.667,
        "_internal_originalTime": 1689787146000
    },
    {
        "time": 1689787148000,
        "value": 139.674,
        "_internal_originalTime": 1689787148000
    },
    {
        "time": 1689787149000,
        "value": 139.673,
        "_internal_originalTime": 1689787149000
    },
    {
        "time": 1689787156000,
        "value": 139.67,
        "_internal_originalTime": 1689787156000
    },
    {
        "time": 1689787158000,
        "value": 139.672,
        "_internal_originalTime": 1689787158000
    },
    {
        "time": 1689787161000,
        "value": 139.675,
        "_internal_originalTime": 1689787161000
    },
    {
        "time": 1689787162000,
        "value": 139.678,
        "_internal_originalTime": 1689787162000
    },
    {
        "time": 1689787163000,
        "value": 139.679,
        "_internal_originalTime": 1689787163000
    },
    {
        "time": 1689787168000,
        "value": 139.679,
        "_internal_originalTime": 1689787168000
    },
    {
        "time": 1689787170000,
        "value": 139.683,
        "_internal_originalTime": 1689787170000
    },
    {
        "time": 1689787172000,
        "value": 139.689,
        "_internal_originalTime": 1689787172000
    },
    {
        "time": 1689787173000,
        "value": 139.685,
        "_internal_originalTime": 1689787173000
    },
    {
        "time": 1689787174000,
        "value": 139.684,
        "_internal_originalTime": 1689787174000
    },
    {
        "time": 1689787175000,
        "value": 139.689,
        "_internal_originalTime": 1689787175000
    },
    {
        "time": 1689787177000,
        "value": 139.688,
        "_internal_originalTime": 1689787177000
    },
    {
        "time": 1689787178000,
        "value": 139.689,
        "_internal_originalTime": 1689787178000
    },
    {
        "time": 1689787179000,
        "value": 139.687,
        "_internal_originalTime": 1689787179000
    },
    {
        "time": 1689787180000,
        "value": 139.69,
        "_internal_originalTime": 1689787180000
    },
    {
        "time": 1689787181000,
        "value": 139.691,
        "_internal_originalTime": 1689787181000
    },
    {
        "time": 1689787184000,
        "value": 139.69,
        "_internal_originalTime": 1689787184000
    },
    {
        "time": 1689787186000,
        "value": 139.682,
        "_internal_originalTime": 1689787186000
    },
    {
        "time": 1689787187000,
        "value": 139.687,
        "_internal_originalTime": 1689787187000
    },
    {
        "time": 1689787190000,
        "value": 139.686,
        "_internal_originalTime": 1689787190000
    },
    {
        "time": 1689787191000,
        "value": 139.684,
        "_internal_originalTime": 1689787191000
    },
    {
        "time": 1689787193000,
        "value": 139.687,
        "_internal_originalTime": 1689787193000
    },
    {
        "time": 1689787195000,
        "value": 139.686,
        "_internal_originalTime": 1689787195000
    },
    {
        "time": 1689787196000,
        "value": 139.682,
        "_internal_originalTime": 1689787196000
    },
    {
        "time": 1689787198000,
        "value": 139.684,
        "_internal_originalTime": 1689787198000
    },
    {
        "time": 1689787199000,
        "value": 139.682,
        "_internal_originalTime": 1689787199000
    },
    {
        "time": 1689787203000,
        "value": 139.684,
        "_internal_originalTime": 1689787203000
    },
    {
        "time": 1689787206000,
        "value": 139.684,
        "_internal_originalTime": 1689787206000
    },
    {
        "time": 1689787208000,
        "value": 139.685,
        "_internal_originalTime": 1689787208000
    },
    {
        "time": 1689787210000,
        "value": 139.682,
        "_internal_originalTime": 1689787210000
    },
    {
        "time": 1689787215000,
        "value": 139.69,
        "_internal_originalTime": 1689787215000
    },
    {
        "time": 1689787216000,
        "value": 139.684,
        "_internal_originalTime": 1689787216000
    },
    {
        "time": 1689787218000,
        "value": 139.69,
        "_internal_originalTime": 1689787218000
    },
    {
        "time": 1689787219000,
        "value": 139.684,
        "_internal_originalTime": 1689787219000
    },
    {
        "time": 1689787220000,
        "value": 139.685,
        "_internal_originalTime": 1689787220000
    },
    {
        "time": 1689787222000,
        "value": 139.691,
        "_internal_originalTime": 1689787222000
    },
    {
        "time": 1689787224000,
        "value": 139.687,
        "_internal_originalTime": 1689787224000
    },
    {
        "time": 1689787225000,
        "value": 139.69,
        "_internal_originalTime": 1689787225000
    },
    {
        "time": 1689787228000,
        "value": 139.687,
        "_internal_originalTime": 1689787228000
    },
    {
        "time": 1689787229000,
        "value": 139.69,
        "_internal_originalTime": 1689787229000
    },
    {
        "time": 1689787232000,
        "value": 139.69,
        "_internal_originalTime": 1689787232000
    },
    {
        "time": 1689787236000,
        "value": 139.684,
        "_internal_originalTime": 1689787236000
    },
    {
        "time": 1689787238000,
        "value": 139.69,
        "_internal_originalTime": 1689787238000
    },
    {
        "time": 1689787239000,
        "value": 139.684,
        "_internal_originalTime": 1689787239000
    },
    {
        "time": 1689787241000,
        "value": 139.684,
        "_internal_originalTime": 1689787241000
    },
    {
        "time": 1689787242000,
        "value": 139.685,
        "_internal_originalTime": 1689787242000
    },
    {
        "time": 1689787243000,
        "value": 139.684,
        "_internal_originalTime": 1689787243000
    },
    {
        "time": 1689787244000,
        "value": 139.685,
        "_internal_originalTime": 1689787244000
    },
    {
        "time": 1689787245000,
        "value": 139.682,
        "_internal_originalTime": 1689787245000
    },
    {
        "time": 1689787246000,
        "value": 139.684,
        "_internal_originalTime": 1689787246000
    },
    {
        "time": 1689787247000,
        "value": 139.684,
        "_internal_originalTime": 1689787247000
    },
    {
        "time": 1689787248000,
        "value": 139.675,
        "_internal_originalTime": 1689787248000
    },
    {
        "time": 1689787250000,
        "value": 139.671,
        "_internal_originalTime": 1689787250000
    },
    {
        "time": 1689787251000,
        "value": 139.67,
        "_internal_originalTime": 1689787251000
    },
    {
        "time": 1689787252000,
        "value": 139.677,
        "_internal_originalTime": 1689787252000
    },
    {
        "time": 1689787253000,
        "value": 139.67,
        "_internal_originalTime": 1689787253000
    },
    {
        "time": 1689787256000,
        "value": 139.67,
        "_internal_originalTime": 1689787256000
    },
    {
        "time": 1689787258000,
        "value": 139.665,
        "_internal_originalTime": 1689787258000
    },
    {
        "time": 1689787261000,
        "value": 139.664,
        "_internal_originalTime": 1689787261000
    },
    {
        "time": 1689787262000,
        "value": 139.663,
        "_internal_originalTime": 1689787262000
    },
    {
        "time": 1689787263000,
        "value": 139.672,
        "_internal_originalTime": 1689787263000
    },
    {
        "time": 1689787264000,
        "value": 139.666,
        "_internal_originalTime": 1689787264000
    },
    {
        "time": 1689787265000,
        "value": 139.671,
        "_internal_originalTime": 1689787265000
    },
    {
        "time": 1689787267000,
        "value": 139.662,
        "_internal_originalTime": 1689787267000
    },
    {
        "time": 1689787268000,
        "value": 139.669,
        "_internal_originalTime": 1689787268000
    },
    {
        "time": 1689787269000,
        "value": 139.668,
        "_internal_originalTime": 1689787269000
    },
    {
        "time": 1689787270000,
        "value": 139.67,
        "_internal_originalTime": 1689787270000
    },
    {
        "time": 1689787271000,
        "value": 139.676,
        "_internal_originalTime": 1689787271000
    },
    {
        "time": 1689787273000,
        "value": 139.678,
        "_internal_originalTime": 1689787273000
    },
    {
        "time": 1689787274000,
        "value": 139.681,
        "_internal_originalTime": 1689787274000
    },
    {
        "time": 1689787275000,
        "value": 139.676,
        "_internal_originalTime": 1689787275000
    },
    {
        "time": 1689787276000,
        "value": 139.682,
        "_internal_originalTime": 1689787276000
    },
    {
        "time": 1689787278000,
        "value": 139.683,
        "_internal_originalTime": 1689787278000
    },
    {
        "time": 1689787279000,
        "value": 139.679,
        "_internal_originalTime": 1689787279000
    },
    {
        "time": 1689787280000,
        "value": 139.683,
        "_internal_originalTime": 1689787280000
    },
    {
        "time": 1689787281000,
        "value": 139.676,
        "_internal_originalTime": 1689787281000
    },
    {
        "time": 1689787283000,
        "value": 139.682,
        "_internal_originalTime": 1689787283000
    },
    {
        "time": 1689787284000,
        "value": 139.682,
        "_internal_originalTime": 1689787284000
    },
    {
        "time": 1689787285000,
        "value": 139.682,
        "_internal_originalTime": 1689787285000
    },
    {
        "time": 1689787289000,
        "value": 139.683,
        "_internal_originalTime": 1689787289000
    },
    {
        "time": 1689787290000,
        "value": 139.681,
        "_internal_originalTime": 1689787290000
    },
    {
        "time": 1689787291000,
        "value": 139.686,
        "_internal_originalTime": 1689787291000
    },
    {
        "time": 1689787292000,
        "value": 139.679,
        "_internal_originalTime": 1689787292000
    },
    {
        "time": 1689787293000,
        "value": 139.681,
        "_internal_originalTime": 1689787293000
    },
    {
        "time": 1689787294000,
        "value": 139.681,
        "_internal_originalTime": 1689787294000
    },
    {
        "time": 1689787296000,
        "value": 139.685,
        "_internal_originalTime": 1689787296000
    },
    {
        "time": 1689787297000,
        "value": 139.686,
        "_internal_originalTime": 1689787297000
    },
    {
        "time": 1689787298000,
        "value": 139.687,
        "_internal_originalTime": 1689787298000
    },
    {
        "time": 1689787299000,
        "value": 139.681,
        "_internal_originalTime": 1689787299000
    },
    {
        "time": 1689787301000,
        "value": 139.687,
        "_internal_originalTime": 1689787301000
    },
    {
        "time": 1689787302000,
        "value": 139.682,
        "_internal_originalTime": 1689787302000
    },
    {
        "time": 1689787303000,
        "value": 139.681,
        "_internal_originalTime": 1689787303000
    },
    {
        "time": 1689787304000,
        "value": 139.683,
        "_internal_originalTime": 1689787304000
    },
    {
        "time": 1689787306000,
        "value": 139.681,
        "_internal_originalTime": 1689787306000
    },
    {
        "time": 1689787309000,
        "value": 139.683,
        "_internal_originalTime": 1689787309000
    },
    {
        "time": 1689787311000,
        "value": 139.683,
        "_internal_originalTime": 1689787311000
    },
    {
        "time": 1689787312000,
        "value": 139.691,
        "_internal_originalTime": 1689787312000
    },
    {
        "time": 1689787313000,
        "value": 139.682,
        "_internal_originalTime": 1689787313000
    },
    {
        "time": 1689787315000,
        "value": 139.684,
        "_internal_originalTime": 1689787315000
    },
    {
        "time": 1689787316000,
        "value": 139.682,
        "_internal_originalTime": 1689787316000
    },
    {
        "time": 1689787318000,
        "value": 139.676,
        "_internal_originalTime": 1689787318000
    },
    {
        "time": 1689787319000,
        "value": 139.676,
        "_internal_originalTime": 1689787319000
    },
    {
        "time": 1689787320000,
        "value": 139.683,
        "_internal_originalTime": 1689787320000
    },
    {
        "time": 1689787321000,
        "value": 139.683,
        "_internal_originalTime": 1689787321000
    },
    {
        "time": 1689787322000,
        "value": 139.683,
        "_internal_originalTime": 1689787322000
    },
    {
        "time": 1689787323000,
        "value": 139.676,
        "_internal_originalTime": 1689787323000
    },
    {
        "time": 1689787324000,
        "value": 139.678,
        "_internal_originalTime": 1689787324000
    },
    {
        "time": 1689787326000,
        "value": 139.681,
        "_internal_originalTime": 1689787326000
    },
    {
        "time": 1689787328000,
        "value": 139.677,
        "_internal_originalTime": 1689787328000
    },
    {
        "time": 1689787329000,
        "value": 139.678,
        "_internal_originalTime": 1689787329000
    },
    {
        "time": 1689787330000,
        "value": 139.682,
        "_internal_originalTime": 1689787330000
    },
    {
        "time": 1689787331000,
        "value": 139.683,
        "_internal_originalTime": 1689787331000
    },
    {
        "time": 1689787334000,
        "value": 139.684,
        "_internal_originalTime": 1689787334000
    },
    {
        "time": 1689787335000,
        "value": 139.683,
        "_internal_originalTime": 1689787335000
    },
    {
        "time": 1689787336000,
        "value": 139.676,
        "_internal_originalTime": 1689787336000
    },
    {
        "time": 1689787338000,
        "value": 139.678,
        "_internal_originalTime": 1689787338000
    },
    {
        "time": 1689787339000,
        "value": 139.677,
        "_internal_originalTime": 1689787339000
    },
    {
        "time": 1689787340000,
        "value": 139.681,
        "_internal_originalTime": 1689787340000
    },
    {
        "time": 1689787342000,
        "value": 139.677,
        "_internal_originalTime": 1689787342000
    },
    {
        "time": 1689787343000,
        "value": 139.677,
        "_internal_originalTime": 1689787343000
    },
    {
        "time": 1689787344000,
        "value": 139.676,
        "_internal_originalTime": 1689787344000
    },
    {
        "time": 1689787345000,
        "value": 139.682,
        "_internal_originalTime": 1689787345000
    },
    {
        "time": 1689787349000,
        "value": 139.676,
        "_internal_originalTime": 1689787349000
    },
    {
        "time": 1689787351000,
        "value": 139.674,
        "_internal_originalTime": 1689787351000
    },
    {
        "time": 1689787357000,
        "value": 139.676,
        "_internal_originalTime": 1689787357000
    },
    {
        "time": 1689787358000,
        "value": 139.675,
        "_internal_originalTime": 1689787358000
    },
    {
        "time": 1689787361000,
        "value": 139.674,
        "_internal_originalTime": 1689787361000
    },
    {
        "time": 1689787364000,
        "value": 139.674,
        "_internal_originalTime": 1689787364000
    },
    {
        "time": 1689787365000,
        "value": 139.676,
        "_internal_originalTime": 1689787365000
    },
    {
        "time": 1689787366000,
        "value": 139.676,
        "_internal_originalTime": 1689787366000
    },
    {
        "time": 1689787368000,
        "value": 139.674,
        "_internal_originalTime": 1689787368000
    },
    {
        "time": 1689787371000,
        "value": 139.675,
        "_internal_originalTime": 1689787371000
    },
    {
        "time": 1689787374000,
        "value": 139.679,
        "_internal_originalTime": 1689787374000
    },
    {
        "time": 1689787375000,
        "value": 139.679,
        "_internal_originalTime": 1689787375000
    },
    {
        "time": 1689787376000,
        "value": 139.676,
        "_internal_originalTime": 1689787376000
    },
    {
        "time": 1689787377000,
        "value": 139.676,
        "_internal_originalTime": 1689787377000
    },
    {
        "time": 1689787379000,
        "value": 139.675,
        "_internal_originalTime": 1689787379000
    },
    {
        "time": 1689787382000,
        "value": 139.681,
        "_internal_originalTime": 1689787382000
    },
    {
        "time": 1689787383000,
        "value": 139.686,
        "_internal_originalTime": 1689787383000
    },
    {
        "time": 1689787388000,
        "value": 139.692,
        "_internal_originalTime": 1689787388000
    },
    {
        "time": 1689787389000,
        "value": 139.687,
        "_internal_originalTime": 1689787389000
    },
    {
        "time": 1689787391000,
        "value": 139.684,
        "_internal_originalTime": 1689787391000
    },
    {
        "time": 1689787392000,
        "value": 139.688,
        "_internal_originalTime": 1689787392000
    },
    {
        "time": 1689787395000,
        "value": 139.684,
        "_internal_originalTime": 1689787395000
    },
    {
        "time": 1689787396000,
        "value": 139.679,
        "_internal_originalTime": 1689787396000
    },
    {
        "time": 1689787398000,
        "value": 139.681,
        "_internal_originalTime": 1689787398000
    },
    {
        "time": 1689787399000,
        "value": 139.683,
        "_internal_originalTime": 1689787399000
    },
    {
        "time": 1689787400000,
        "value": 139.682,
        "_internal_originalTime": 1689787400000
    },
    {
        "time": 1689787402000,
        "value": 139.679,
        "_internal_originalTime": 1689787402000
    },
    {
        "time": 1689787403000,
        "value": 139.676,
        "_internal_originalTime": 1689787403000
    },
    {
        "time": 1689787404000,
        "value": 139.672,
        "_internal_originalTime": 1689787404000
    },
    {
        "time": 1689787405000,
        "value": 139.672,
        "_internal_originalTime": 1689787405000
    },
    {
        "time": 1689787406000,
        "value": 139.672,
        "_internal_originalTime": 1689787406000
    },
    {
        "time": 1689787411000,
        "value": 139.682,
        "_internal_originalTime": 1689787411000
    },
    {
        "time": 1689787412000,
        "value": 139.682,
        "_internal_originalTime": 1689787412000
    },
    {
        "time": 1689787413000,
        "value": 139.679,
        "_internal_originalTime": 1689787413000
    },
    {
        "time": 1689787414000,
        "value": 139.678,
        "_internal_originalTime": 1689787414000
    },
    {
        "time": 1689787415000,
        "value": 139.679,
        "_internal_originalTime": 1689787415000
    },
    {
        "time": 1689787416000,
        "value": 139.681,
        "_internal_originalTime": 1689787416000
    },
    {
        "time": 1689787418000,
        "value": 139.678,
        "_internal_originalTime": 1689787418000
    },
    {
        "time": 1689787420000,
        "value": 139.679,
        "_internal_originalTime": 1689787420000
    },
    {
        "time": 1689787421000,
        "value": 139.68,
        "_internal_originalTime": 1689787421000
    },
    {
        "time": 1689787422000,
        "value": 139.681,
        "_internal_originalTime": 1689787422000
    },
    {
        "time": 1689787423000,
        "value": 139.675,
        "_internal_originalTime": 1689787423000
    },
    {
        "time": 1689787424000,
        "value": 139.676,
        "_internal_originalTime": 1689787424000
    },
    {
        "time": 1689787426000,
        "value": 139.674,
        "_internal_originalTime": 1689787426000
    },
    {
        "time": 1689787428000,
        "value": 139.675,
        "_internal_originalTime": 1689787428000
    },
    {
        "time": 1689787429000,
        "value": 139.675,
        "_internal_originalTime": 1689787429000
    },
    {
        "time": 1689787430000,
        "value": 139.675,
        "_internal_originalTime": 1689787430000
    },
    {
        "time": 1689787434000,
        "value": 139.675,
        "_internal_originalTime": 1689787434000
    },
    {
        "time": 1689787435000,
        "value": 139.681,
        "_internal_originalTime": 1689787435000
    },
    {
        "time": 1689787436000,
        "value": 139.681,
        "_internal_originalTime": 1689787436000
    },
    {
        "time": 1689787440000,
        "value": 139.674,
        "_internal_originalTime": 1689787440000
    },
    {
        "time": 1689787441000,
        "value": 139.678,
        "_internal_originalTime": 1689787441000
    },
    {
        "time": 1689787442000,
        "value": 139.678,
        "_internal_originalTime": 1689787442000
    },
    {
        "time": 1689787443000,
        "value": 139.671,
        "_internal_originalTime": 1689787443000
    },
    {
        "time": 1689787445000,
        "value": 139.67,
        "_internal_originalTime": 1689787445000
    },
    {
        "time": 1689787447000,
        "value": 139.67,
        "_internal_originalTime": 1689787447000
    },
    {
        "time": 1689787448000,
        "value": 139.671,
        "_internal_originalTime": 1689787448000
    },
    {
        "time": 1689787449000,
        "value": 139.671,
        "_internal_originalTime": 1689787449000
    },
    {
        "time": 1689787451000,
        "value": 139.686,
        "_internal_originalTime": 1689787451000
    },
    {
        "time": 1689787452000,
        "value": 139.686,
        "_internal_originalTime": 1689787452000
    },
    {
        "time": 1689787453000,
        "value": 139.674,
        "_internal_originalTime": 1689787453000
    },
    {
        "time": 1689787454000,
        "value": 139.681,
        "_internal_originalTime": 1689787454000
    },
    {
        "time": 1689787455000,
        "value": 139.677,
        "_internal_originalTime": 1689787455000
    },
    {
        "time": 1689787456000,
        "value": 139.675,
        "_internal_originalTime": 1689787456000
    },
    {
        "time": 1689787457000,
        "value": 139.676,
        "_internal_originalTime": 1689787457000
    },
    {
        "time": 1689787458000,
        "value": 139.676,
        "_internal_originalTime": 1689787458000
    },
    {
        "time": 1689787459000,
        "value": 139.677,
        "_internal_originalTime": 1689787459000
    },
    {
        "time": 1689787460000,
        "value": 139.674,
        "_internal_originalTime": 1689787460000
    },
    {
        "time": 1689787462000,
        "value": 139.674,
        "_internal_originalTime": 1689787462000
    },
    {
        "time": 1689787463000,
        "value": 139.674,
        "_internal_originalTime": 1689787463000
    },
    {
        "time": 1689787464000,
        "value": 139.676,
        "_internal_originalTime": 1689787464000
    },
    {
        "time": 1689787465000,
        "value": 139.681,
        "_internal_originalTime": 1689787465000
    },
    {
        "time": 1689787467000,
        "value": 139.677,
        "_internal_originalTime": 1689787467000
    },
    {
        "time": 1689787469000,
        "value": 139.676,
        "_internal_originalTime": 1689787469000
    },
    {
        "time": 1689787470000,
        "value": 139.676,
        "_internal_originalTime": 1689787470000
    },
    {
        "time": 1689787471000,
        "value": 139.676,
        "_internal_originalTime": 1689787471000
    },
    {
        "time": 1689787474000,
        "value": 139.674,
        "_internal_originalTime": 1689787474000
    },
    {
        "time": 1689787475000,
        "value": 139.674,
        "_internal_originalTime": 1689787475000
    },
    {
        "time": 1689787476000,
        "value": 139.676,
        "_internal_originalTime": 1689787476000
    },
    {
        "time": 1689787477000,
        "value": 139.676,
        "_internal_originalTime": 1689787477000
    },
    {
        "time": 1689787478000,
        "value": 139.676,
        "_internal_originalTime": 1689787478000
    },
    {
        "time": 1689787479000,
        "value": 139.676,
        "_internal_originalTime": 1689787479000
    },
    {
        "time": 1689787481000,
        "value": 139.676,
        "_internal_originalTime": 1689787481000
    },
    {
        "time": 1689787486000,
        "value": 139.673,
        "_internal_originalTime": 1689787486000
    },
    {
        "time": 1689787488000,
        "value": 139.675,
        "_internal_originalTime": 1689787488000
    },
    {
        "time": 1689787489000,
        "value": 139.674,
        "_internal_originalTime": 1689787489000
    },
    {
        "time": 1689787490000,
        "value": 139.681,
        "_internal_originalTime": 1689787490000
    },
    {
        "time": 1689787494000,
        "value": 139.681,
        "_internal_originalTime": 1689787494000
    },
    {
        "time": 1689787495000,
        "value": 139.677,
        "_internal_originalTime": 1689787495000
    },
    {
        "time": 1689787496000,
        "value": 139.676,
        "_internal_originalTime": 1689787496000
    },
    {
        "time": 1689787497000,
        "value": 139.674,
        "_internal_originalTime": 1689787497000
    },
    {
        "time": 1689787498000,
        "value": 139.675,
        "_internal_originalTime": 1689787498000
    },
    {
        "time": 1689787500000,
        "value": 139.679,
        "_internal_originalTime": 1689787500000
    },
    {
        "time": 1689787502000,
        "value": 139.672,
        "_internal_originalTime": 1689787502000
    },
    {
        "time": 1689787503000,
        "value": 139.673,
        "_internal_originalTime": 1689787503000
    },
    {
        "time": 1689787504000,
        "value": 139.672,
        "_internal_originalTime": 1689787504000
    },
    {
        "time": 1689787505000,
        "value": 139.677,
        "_internal_originalTime": 1689787505000
    },
    {
        "time": 1689787506000,
        "value": 139.674,
        "_internal_originalTime": 1689787506000
    },
    {
        "time": 1689787507000,
        "value": 139.677,
        "_internal_originalTime": 1689787507000
    },
    {
        "time": 1689787508000,
        "value": 139.677,
        "_internal_originalTime": 1689787508000
    },
    {
        "time": 1689787510000,
        "value": 139.677,
        "_internal_originalTime": 1689787510000
    },
    {
        "time": 1689787513000,
        "value": 139.678,
        "_internal_originalTime": 1689787513000
    },
    {
        "time": 1689787514000,
        "value": 139.674,
        "_internal_originalTime": 1689787514000
    },
    {
        "time": 1689787516000,
        "value": 139.67,
        "_internal_originalTime": 1689787516000
    },
    {
        "time": 1689787518000,
        "value": 139.676,
        "_internal_originalTime": 1689787518000
    },
    {
        "time": 1689787519000,
        "value": 139.671,
        "_internal_originalTime": 1689787519000
    },
    {
        "time": 1689787520000,
        "value": 139.676,
        "_internal_originalTime": 1689787520000
    },
    {
        "time": 1689787522000,
        "value": 139.674,
        "_internal_originalTime": 1689787522000
    },
    {
        "time": 1689787523000,
        "value": 139.668,
        "_internal_originalTime": 1689787523000
    },
    {
        "time": 1689787524000,
        "value": 139.676,
        "_internal_originalTime": 1689787524000
    },
    {
        "time": 1689787525000,
        "value": 139.676,
        "_internal_originalTime": 1689787525000
    },
    {
        "time": 1689787527000,
        "value": 139.672,
        "_internal_originalTime": 1689787527000
    },
    {
        "time": 1689787528000,
        "value": 139.672,
        "_internal_originalTime": 1689787528000
    },
    {
        "time": 1689787530000,
        "value": 139.672,
        "_internal_originalTime": 1689787530000
    },
    {
        "time": 1689787532000,
        "value": 139.671,
        "_internal_originalTime": 1689787532000
    },
    {
        "time": 1689787533000,
        "value": 139.67,
        "_internal_originalTime": 1689787533000
    },
    {
        "time": 1689787536000,
        "value": 139.672,
        "_internal_originalTime": 1689787536000
    },
    {
        "time": 1689787537000,
        "value": 139.671,
        "_internal_originalTime": 1689787537000
    },
    {
        "time": 1689787538000,
        "value": 139.677,
        "_internal_originalTime": 1689787538000
    },
    {
        "time": 1689787539000,
        "value": 139.671,
        "_internal_originalTime": 1689787539000
    },
    {
        "time": 1689787540000,
        "value": 139.671,
        "_internal_originalTime": 1689787540000
    },
    {
        "time": 1689787541000,
        "value": 139.672,
        "_internal_originalTime": 1689787541000
    },
    {
        "time": 1689787545000,
        "value": 139.677,
        "_internal_originalTime": 1689787545000
    },
    {
        "time": 1689787546000,
        "value": 139.67,
        "_internal_originalTime": 1689787546000
    },
    {
        "time": 1689787549000,
        "value": 139.671,
        "_internal_originalTime": 1689787549000
    },
    {
        "time": 1689787550000,
        "value": 139.681,
        "_internal_originalTime": 1689787550000
    },
    {
        "time": 1689787551000,
        "value": 139.682,
        "_internal_originalTime": 1689787551000
    },
    {
        "time": 1689787553000,
        "value": 139.681,
        "_internal_originalTime": 1689787553000
    },
    {
        "time": 1689787554000,
        "value": 139.682,
        "_internal_originalTime": 1689787554000
    },
    {
        "time": 1689787555000,
        "value": 139.676,
        "_internal_originalTime": 1689787555000
    },
    {
        "time": 1689787557000,
        "value": 139.681,
        "_internal_originalTime": 1689787557000
    },
    {
        "time": 1689787559000,
        "value": 139.674,
        "_internal_originalTime": 1689787559000
    },
    {
        "time": 1689787560000,
        "value": 139.681,
        "_internal_originalTime": 1689787560000
    },
    {
        "time": 1689787561000,
        "value": 139.675,
        "_internal_originalTime": 1689787561000
    },
    {
        "time": 1689787562000,
        "value": 139.674,
        "_internal_originalTime": 1689787562000
    },
    {
        "time": 1689787564000,
        "value": 139.677,
        "_internal_originalTime": 1689787564000
    },
    {
        "time": 1689787565000,
        "value": 139.676,
        "_internal_originalTime": 1689787565000
    },
    {
        "time": 1689787566000,
        "value": 139.68,
        "_internal_originalTime": 1689787566000
    },
    {
        "time": 1689787567000,
        "value": 139.674,
        "_internal_originalTime": 1689787567000
    },
    {
        "time": 1689787568000,
        "value": 139.691,
        "_internal_originalTime": 1689787568000
    },
    {
        "time": 1689787570000,
        "value": 139.687,
        "_internal_originalTime": 1689787570000
    },
    {
        "time": 1689787572000,
        "value": 139.687,
        "_internal_originalTime": 1689787572000
    },
    {
        "time": 1689787573000,
        "value": 139.695,
        "_internal_originalTime": 1689787573000
    },
    {
        "time": 1689787574000,
        "value": 139.689,
        "_internal_originalTime": 1689787574000
    },
    {
        "time": 1689787576000,
        "value": 139.689,
        "_internal_originalTime": 1689787576000
    },
    {
        "time": 1689787577000,
        "value": 139.689,
        "_internal_originalTime": 1689787577000
    },
    {
        "time": 1689787579000,
        "value": 139.689,
        "_internal_originalTime": 1689787579000
    },
    {
        "time": 1689787580000,
        "value": 139.689,
        "_internal_originalTime": 1689787580000
    },
    {
        "time": 1689787581000,
        "value": 139.689,
        "_internal_originalTime": 1689787581000
    },
    {
        "time": 1689787583000,
        "value": 139.689,
        "_internal_originalTime": 1689787583000
    },
    {
        "time": 1689787585000,
        "value": 139.693,
        "_internal_originalTime": 1689787585000
    },
    {
        "time": 1689787588000,
        "value": 139.685,
        "_internal_originalTime": 1689787588000
    },
    {
        "time": 1689787589000,
        "value": 139.686,
        "_internal_originalTime": 1689787589000
    },
    {
        "time": 1689787590000,
        "value": 139.698,
        "_internal_originalTime": 1689787590000
    },
    {
        "time": 1689787591000,
        "value": 139.69,
        "_internal_originalTime": 1689787591000
    },
    {
        "time": 1689787593000,
        "value": 139.69,
        "_internal_originalTime": 1689787593000
    },
    {
        "time": 1689787595000,
        "value": 139.697,
        "_internal_originalTime": 1689787595000
    },
    {
        "time": 1689787597000,
        "value": 139.687,
        "_internal_originalTime": 1689787597000
    },
    {
        "time": 1689787599000,
        "value": 139.691,
        "_internal_originalTime": 1689787599000
    },
    {
        "time": 1689787600000,
        "value": 139.689,
        "_internal_originalTime": 1689787600000
    },
    {
        "time": 1689787601000,
        "value": 139.683,
        "_internal_originalTime": 1689787601000
    },
    {
        "time": 1689787602000,
        "value": 139.689,
        "_internal_originalTime": 1689787602000
    },
    {
        "time": 1689787604000,
        "value": 139.682,
        "_internal_originalTime": 1689787604000
    },
    {
        "time": 1689787606000,
        "value": 139.689,
        "_internal_originalTime": 1689787606000
    },
    {
        "time": 1689787608000,
        "value": 139.684,
        "_internal_originalTime": 1689787608000
    },
    {
        "time": 1689787609000,
        "value": 139.69,
        "_internal_originalTime": 1689787609000
    },
    {
        "time": 1689787610000,
        "value": 139.683,
        "_internal_originalTime": 1689787610000
    },
    {
        "time": 1689787611000,
        "value": 139.684,
        "_internal_originalTime": 1689787611000
    },
    {
        "time": 1689787612000,
        "value": 139.683,
        "_internal_originalTime": 1689787612000
    },
    {
        "time": 1689787614000,
        "value": 139.683,
        "_internal_originalTime": 1689787614000
    },
    {
        "time": 1689787615000,
        "value": 139.682,
        "_internal_originalTime": 1689787615000
    },
    {
        "time": 1689787616000,
        "value": 139.683,
        "_internal_originalTime": 1689787616000
    },
    {
        "time": 1689787619000,
        "value": 139.683,
        "_internal_originalTime": 1689787619000
    },
    {
        "time": 1689787620000,
        "value": 139.682,
        "_internal_originalTime": 1689787620000
    },
    {
        "time": 1689787621000,
        "value": 139.682,
        "_internal_originalTime": 1689787621000
    },
    {
        "time": 1689787622000,
        "value": 139.682,
        "_internal_originalTime": 1689787622000
    },
    {
        "time": 1689787624000,
        "value": 139.688,
        "_internal_originalTime": 1689787624000
    },
    {
        "time": 1689787625000,
        "value": 139.676,
        "_internal_originalTime": 1689787625000
    },
    {
        "time": 1689787626000,
        "value": 139.674,
        "_internal_originalTime": 1689787626000
    },
    {
        "time": 1689787627000,
        "value": 139.681,
        "_internal_originalTime": 1689787627000
    },
    {
        "time": 1689787628000,
        "value": 139.679,
        "_internal_originalTime": 1689787628000
    },
    {
        "time": 1689787629000,
        "value": 139.679,
        "_internal_originalTime": 1689787629000
    },
    {
        "time": 1689787631000,
        "value": 139.679,
        "_internal_originalTime": 1689787631000
    },
    {
        "time": 1689787632000,
        "value": 139.679,
        "_internal_originalTime": 1689787632000
    },
    {
        "time": 1689787633000,
        "value": 139.673,
        "_internal_originalTime": 1689787633000
    },
    {
        "time": 1689787634000,
        "value": 139.681,
        "_internal_originalTime": 1689787634000
    },
    {
        "time": 1689787635000,
        "value": 139.673,
        "_internal_originalTime": 1689787635000
    },
    {
        "time": 1689787636000,
        "value": 139.686,
        "_internal_originalTime": 1689787636000
    },
    {
        "time": 1689787638000,
        "value": 139.683,
        "_internal_originalTime": 1689787638000
    },
    {
        "time": 1689787639000,
        "value": 139.681,
        "_internal_originalTime": 1689787639000
    },
    {
        "time": 1689787640000,
        "value": 139.678,
        "_internal_originalTime": 1689787640000
    },
    {
        "time": 1689787641000,
        "value": 139.687,
        "_internal_originalTime": 1689787641000
    },
    {
        "time": 1689787642000,
        "value": 139.687,
        "_internal_originalTime": 1689787642000
    },
    {
        "time": 1689787644000,
        "value": 139.684,
        "_internal_originalTime": 1689787644000
    },
    {
        "time": 1689787647000,
        "value": 139.69,
        "_internal_originalTime": 1689787647000
    },
    {
        "time": 1689787648000,
        "value": 139.683,
        "_internal_originalTime": 1689787648000
    },
    {
        "time": 1689787649000,
        "value": 139.685,
        "_internal_originalTime": 1689787649000
    },
    {
        "time": 1689787650000,
        "value": 139.695,
        "_internal_originalTime": 1689787650000
    },
    {
        "time": 1689787651000,
        "value": 139.695,
        "_internal_originalTime": 1689787651000
    },
    {
        "time": 1689787652000,
        "value": 139.695,
        "_internal_originalTime": 1689787652000
    },
    {
        "time": 1689787653000,
        "value": 139.69,
        "_internal_originalTime": 1689787653000
    },
    {
        "time": 1689787655000,
        "value": 139.689,
        "_internal_originalTime": 1689787655000
    },
    {
        "time": 1689787656000,
        "value": 139.697,
        "_internal_originalTime": 1689787656000
    },
    {
        "time": 1689787657000,
        "value": 139.692,
        "_internal_originalTime": 1689787657000
    },
    {
        "time": 1689787658000,
        "value": 139.692,
        "_internal_originalTime": 1689787658000
    },
    {
        "time": 1689787659000,
        "value": 139.692,
        "_internal_originalTime": 1689787659000
    },
    {
        "time": 1689787661000,
        "value": 139.692,
        "_internal_originalTime": 1689787661000
    },
    {
        "time": 1689787662000,
        "value": 139.699,
        "_internal_originalTime": 1689787662000
    },
    {
        "time": 1689787663000,
        "value": 139.693,
        "_internal_originalTime": 1689787663000
    },
    {
        "time": 1689787664000,
        "value": 139.693,
        "_internal_originalTime": 1689787664000
    },
    {
        "time": 1689787665000,
        "value": 139.699,
        "_internal_originalTime": 1689787665000
    },
    {
        "time": 1689787666000,
        "value": 139.704,
        "_internal_originalTime": 1689787666000
    },
    {
        "time": 1689787667000,
        "value": 139.7,
        "_internal_originalTime": 1689787667000
    },
    {
        "time": 1689787668000,
        "value": 139.706,
        "_internal_originalTime": 1689787668000
    },
    {
        "time": 1689787669000,
        "value": 139.7,
        "_internal_originalTime": 1689787669000
    },
    {
        "time": 1689787671000,
        "value": 139.702,
        "_internal_originalTime": 1689787671000
    },
    {
        "time": 1689787672000,
        "value": 139.701,
        "_internal_originalTime": 1689787672000
    },
    {
        "time": 1689787674000,
        "value": 139.695,
        "_internal_originalTime": 1689787674000
    },
    {
        "time": 1689787676000,
        "value": 139.7,
        "_internal_originalTime": 1689787676000
    },
    {
        "time": 1689787678000,
        "value": 139.701,
        "_internal_originalTime": 1689787678000
    },
    {
        "time": 1689787680000,
        "value": 139.7,
        "_internal_originalTime": 1689787680000
    },
    {
        "time": 1689787681000,
        "value": 139.704,
        "_internal_originalTime": 1689787681000
    },
    {
        "time": 1689787682000,
        "value": 139.699,
        "_internal_originalTime": 1689787682000
    },
    {
        "time": 1689787683000,
        "value": 139.691,
        "_internal_originalTime": 1689787683000
    },
    {
        "time": 1689787684000,
        "value": 139.69,
        "_internal_originalTime": 1689787684000
    },
    {
        "time": 1689787686000,
        "value": 139.691,
        "_internal_originalTime": 1689787686000
    },
    {
        "time": 1689787688000,
        "value": 139.693,
        "_internal_originalTime": 1689787688000
    },
    {
        "time": 1689787689000,
        "value": 139.698,
        "_internal_originalTime": 1689787689000
    },
    {
        "time": 1689787691000,
        "value": 139.697,
        "_internal_originalTime": 1689787691000
    },
    {
        "time": 1689787693000,
        "value": 139.693,
        "_internal_originalTime": 1689787693000
    },
    {
        "time": 1689787694000,
        "value": 139.685,
        "_internal_originalTime": 1689787694000
    },
    {
        "time": 1689787696000,
        "value": 139.682,
        "_internal_originalTime": 1689787696000
    },
    {
        "time": 1689787697000,
        "value": 139.684,
        "_internal_originalTime": 1689787697000
    },
    {
        "time": 1689787698000,
        "value": 139.696,
        "_internal_originalTime": 1689787698000
    },
    {
        "time": 1689787699000,
        "value": 139.696,
        "_internal_originalTime": 1689787699000
    },
    {
        "time": 1689787700000,
        "value": 139.695,
        "_internal_originalTime": 1689787700000
    },
    {
        "time": 1689787701000,
        "value": 139.69,
        "_internal_originalTime": 1689787701000
    },
    {
        "time": 1689787703000,
        "value": 139.697,
        "_internal_originalTime": 1689787703000
    },
    {
        "time": 1689787704000,
        "value": 139.69,
        "_internal_originalTime": 1689787704000
    },
    {
        "time": 1689787707000,
        "value": 139.689,
        "_internal_originalTime": 1689787707000
    },
    {
        "time": 1689787708000,
        "value": 139.69,
        "_internal_originalTime": 1689787708000
    },
    {
        "time": 1689787709000,
        "value": 139.688,
        "_internal_originalTime": 1689787709000
    },
    {
        "time": 1689787710000,
        "value": 139.69,
        "_internal_originalTime": 1689787710000
    },
    {
        "time": 1689787711000,
        "value": 139.697,
        "_internal_originalTime": 1689787711000
    },
    {
        "time": 1689787712000,
        "value": 139.69,
        "_internal_originalTime": 1689787712000
    },
    {
        "time": 1689787713000,
        "value": 139.689,
        "_internal_originalTime": 1689787713000
    },
    {
        "time": 1689787714000,
        "value": 139.69,
        "_internal_originalTime": 1689787714000
    },
    {
        "time": 1689787716000,
        "value": 139.695,
        "_internal_originalTime": 1689787716000
    },
    {
        "time": 1689787717000,
        "value": 139.695,
        "_internal_originalTime": 1689787717000
    },
    {
        "time": 1689787718000,
        "value": 139.695,
        "_internal_originalTime": 1689787718000
    },
    {
        "time": 1689787720000,
        "value": 139.695,
        "_internal_originalTime": 1689787720000
    },
    {
        "time": 1689787721000,
        "value": 139.69,
        "_internal_originalTime": 1689787721000
    },
    {
        "time": 1689787722000,
        "value": 139.697,
        "_internal_originalTime": 1689787722000
    },
    {
        "time": 1689787723000,
        "value": 139.697,
        "_internal_originalTime": 1689787723000
    },
    {
        "time": 1689787725000,
        "value": 139.695,
        "_internal_originalTime": 1689787725000
    },
    {
        "time": 1689787727000,
        "value": 139.695,
        "_internal_originalTime": 1689787727000
    },
    {
        "time": 1689787728000,
        "value": 139.699,
        "_internal_originalTime": 1689787728000
    },
    {
        "time": 1689787730000,
        "value": 139.697,
        "_internal_originalTime": 1689787730000
    },
    {
        "time": 1689787732000,
        "value": 139.699,
        "_internal_originalTime": 1689787732000
    },
    {
        "time": 1689787736000,
        "value": 139.698,
        "_internal_originalTime": 1689787736000
    },
    {
        "time": 1689787739000,
        "value": 139.705,
        "_internal_originalTime": 1689787739000
    },
    {
        "time": 1689787741000,
        "value": 139.698,
        "_internal_originalTime": 1689787741000
    },
    {
        "time": 1689787742000,
        "value": 139.698,
        "_internal_originalTime": 1689787742000
    },
    {
        "time": 1689787746000,
        "value": 139.701,
        "_internal_originalTime": 1689787746000
    },
    {
        "time": 1689787747000,
        "value": 139.701,
        "_internal_originalTime": 1689787747000
    },
    {
        "time": 1689787748000,
        "value": 139.701,
        "_internal_originalTime": 1689787748000
    },
    {
        "time": 1689787752000,
        "value": 139.701,
        "_internal_originalTime": 1689787752000
    },
    {
        "time": 1689787753000,
        "value": 139.701,
        "_internal_originalTime": 1689787753000
    },
    {
        "time": 1689787755000,
        "value": 139.703,
        "_internal_originalTime": 1689787755000
    },
    {
        "time": 1689787756000,
        "value": 139.697,
        "_internal_originalTime": 1689787756000
    },
    {
        "time": 1689787757000,
        "value": 139.705,
        "_internal_originalTime": 1689787757000
    },
    {
        "time": 1689787758000,
        "value": 139.706,
        "_internal_originalTime": 1689787758000
    },
    {
        "time": 1689787759000,
        "value": 139.705,
        "_internal_originalTime": 1689787759000
    },
    {
        "time": 1689787762000,
        "value": 139.704,
        "_internal_originalTime": 1689787762000
    },
    {
        "time": 1689787763000,
        "value": 139.7,
        "_internal_originalTime": 1689787763000
    },
    {
        "time": 1689787765000,
        "value": 139.694,
        "_internal_originalTime": 1689787765000
    },
    {
        "time": 1689787768000,
        "value": 139.692,
        "_internal_originalTime": 1689787768000
    },
    {
        "time": 1689787770000,
        "value": 139.7,
        "_internal_originalTime": 1689787770000
    },
    {
        "time": 1689787771000,
        "value": 139.694,
        "_internal_originalTime": 1689787771000
    },
    {
        "time": 1689787773000,
        "value": 139.695,
        "_internal_originalTime": 1689787773000
    },
    {
        "time": 1689787774000,
        "value": 139.694,
        "_internal_originalTime": 1689787774000
    },
    {
        "time": 1689787776000,
        "value": 139.695,
        "_internal_originalTime": 1689787776000
    },
    {
        "time": 1689787778000,
        "value": 139.694,
        "_internal_originalTime": 1689787778000
    },
    {
        "time": 1689787780000,
        "value": 139.696,
        "_internal_originalTime": 1689787780000
    },
    {
        "time": 1689787782000,
        "value": 139.696,
        "_internal_originalTime": 1689787782000
    },
    {
        "time": 1689787783000,
        "value": 139.695,
        "_internal_originalTime": 1689787783000
    },
    {
        "time": 1689787784000,
        "value": 139.696,
        "_internal_originalTime": 1689787784000
    },
    {
        "time": 1689787786000,
        "value": 139.701,
        "_internal_originalTime": 1689787786000
    },
    {
        "time": 1689787787000,
        "value": 139.7,
        "_internal_originalTime": 1689787787000
    },
    {
        "time": 1689787788000,
        "value": 139.7,
        "_internal_originalTime": 1689787788000
    },
    {
        "time": 1689787790000,
        "value": 139.701,
        "_internal_originalTime": 1689787790000
    },
    {
        "time": 1689787791000,
        "value": 139.704,
        "_internal_originalTime": 1689787791000
    },
    {
        "time": 1689787792000,
        "value": 139.696,
        "_internal_originalTime": 1689787792000
    },
    {
        "time": 1689787794000,
        "value": 139.695,
        "_internal_originalTime": 1689787794000
    },
    {
        "time": 1689787795000,
        "value": 139.699,
        "_internal_originalTime": 1689787795000
    },
    {
        "time": 1689787796000,
        "value": 139.698,
        "_internal_originalTime": 1689787796000
    },
    {
        "time": 1689787797000,
        "value": 139.691,
        "_internal_originalTime": 1689787797000
    },
    {
        "time": 1689787799000,
        "value": 139.691,
        "_internal_originalTime": 1689787799000
    },
    {
        "time": 1689787800000,
        "value": 139.698,
        "_internal_originalTime": 1689787800000
    },
    {
        "time": 1689787801000,
        "value": 139.696,
        "_internal_originalTime": 1689787801000
    },
    {
        "time": 1689787802000,
        "value": 139.696,
        "_internal_originalTime": 1689787802000
    },
    {
        "time": 1689787803000,
        "value": 139.691,
        "_internal_originalTime": 1689787803000
    },
    {
        "time": 1689787804000,
        "value": 139.69,
        "_internal_originalTime": 1689787804000
    },
    {
        "time": 1689787805000,
        "value": 139.697,
        "_internal_originalTime": 1689787805000
    },
    {
        "time": 1689787806000,
        "value": 139.692,
        "_internal_originalTime": 1689787806000
    },
    {
        "time": 1689787808000,
        "value": 139.681,
        "_internal_originalTime": 1689787808000
    },
    {
        "time": 1689787809000,
        "value": 139.681,
        "_internal_originalTime": 1689787809000
    },
    {
        "time": 1689787810000,
        "value": 139.686,
        "_internal_originalTime": 1689787810000
    },
    {
        "time": 1689787811000,
        "value": 139.69,
        "_internal_originalTime": 1689787811000
    },
    {
        "time": 1689787812000,
        "value": 139.691,
        "_internal_originalTime": 1689787812000
    },
    {
        "time": 1689787813000,
        "value": 139.69,
        "_internal_originalTime": 1689787813000
    },
    {
        "time": 1689787815000,
        "value": 139.692,
        "_internal_originalTime": 1689787815000
    },
    {
        "time": 1689787816000,
        "value": 139.695,
        "_internal_originalTime": 1689787816000
    },
    {
        "time": 1689787819000,
        "value": 139.693,
        "_internal_originalTime": 1689787819000
    },
    {
        "time": 1689787820000,
        "value": 139.69,
        "_internal_originalTime": 1689787820000
    },
    {
        "time": 1689787821000,
        "value": 139.685,
        "_internal_originalTime": 1689787821000
    },
    {
        "time": 1689787822000,
        "value": 139.688,
        "_internal_originalTime": 1689787822000
    },
    {
        "time": 1689787823000,
        "value": 139.682,
        "_internal_originalTime": 1689787823000
    },
    {
        "time": 1689787824000,
        "value": 139.69,
        "_internal_originalTime": 1689787824000
    },
    {
        "time": 1689787825000,
        "value": 139.689,
        "_internal_originalTime": 1689787825000
    },
    {
        "time": 1689787827000,
        "value": 139.689,
        "_internal_originalTime": 1689787827000
    },
    {
        "time": 1689787829000,
        "value": 139.695,
        "_internal_originalTime": 1689787829000
    },
    {
        "time": 1689787830000,
        "value": 139.689,
        "_internal_originalTime": 1689787830000
    },
    {
        "time": 1689787831000,
        "value": 139.688,
        "_internal_originalTime": 1689787831000
    },
    {
        "time": 1689787832000,
        "value": 139.683,
        "_internal_originalTime": 1689787832000
    },
    {
        "time": 1689787833000,
        "value": 139.689,
        "_internal_originalTime": 1689787833000
    },
    {
        "time": 1689787834000,
        "value": 139.684,
        "_internal_originalTime": 1689787834000
    },
    {
        "time": 1689787836000,
        "value": 139.684,
        "_internal_originalTime": 1689787836000
    },
    {
        "time": 1689787838000,
        "value": 139.682,
        "_internal_originalTime": 1689787838000
    },
    {
        "time": 1689787839000,
        "value": 139.664,
        "_internal_originalTime": 1689787839000
    },
    {
        "time": 1689787840000,
        "value": 139.669,
        "_internal_originalTime": 1689787840000
    },
    {
        "time": 1689787842000,
        "value": 139.663,
        "_internal_originalTime": 1689787842000
    },
    {
        "time": 1689787843000,
        "value": 139.667,
        "_internal_originalTime": 1689787843000
    },
    {
        "time": 1689787844000,
        "value": 139.663,
        "_internal_originalTime": 1689787844000
    },
    {
        "time": 1689787846000,
        "value": 139.66,
        "_internal_originalTime": 1689787846000
    },
    {
        "time": 1689787850000,
        "value": 139.662,
        "_internal_originalTime": 1689787850000
    },
    {
        "time": 1689787851000,
        "value": 139.657,
        "_internal_originalTime": 1689787851000
    },
    {
        "time": 1689787853000,
        "value": 139.663,
        "_internal_originalTime": 1689787853000
    },
    {
        "time": 1689787854000,
        "value": 139.655,
        "_internal_originalTime": 1689787854000
    },
    {
        "time": 1689787855000,
        "value": 139.654,
        "_internal_originalTime": 1689787855000
    },
    {
        "time": 1689787856000,
        "value": 139.653,
        "_internal_originalTime": 1689787856000
    },
    {
        "time": 1689787857000,
        "value": 139.653,
        "_internal_originalTime": 1689787857000
    },
    {
        "time": 1689787860000,
        "value": 139.652,
        "_internal_originalTime": 1689787860000
    },
    {
        "time": 1689787862000,
        "value": 139.659,
        "_internal_originalTime": 1689787862000
    },
    {
        "time": 1689787863000,
        "value": 139.658,
        "_internal_originalTime": 1689787863000
    },
    {
        "time": 1689787864000,
        "value": 139.642,
        "_internal_originalTime": 1689787864000
    },
    {
        "time": 1689787865000,
        "value": 139.64,
        "_internal_originalTime": 1689787865000
    },
    {
        "time": 1689787866000,
        "value": 139.642,
        "_internal_originalTime": 1689787866000
    },
    {
        "time": 1689787867000,
        "value": 139.648,
        "_internal_originalTime": 1689787867000
    },
    {
        "time": 1689787868000,
        "value": 139.642,
        "_internal_originalTime": 1689787868000
    },
    {
        "time": 1689787870000,
        "value": 139.647,
        "_internal_originalTime": 1689787870000
    },
    {
        "time": 1689787871000,
        "value": 139.643,
        "_internal_originalTime": 1689787871000
    },
    {
        "time": 1689787872000,
        "value": 139.645,
        "_internal_originalTime": 1689787872000
    },
    {
        "time": 1689787873000,
        "value": 139.648,
        "_internal_originalTime": 1689787873000
    },
    {
        "time": 1689787874000,
        "value": 139.639,
        "_internal_originalTime": 1689787874000
    },
    {
        "time": 1689787875000,
        "value": 139.634,
        "_internal_originalTime": 1689787875000
    },
    {
        "time": 1689787877000,
        "value": 139.633,
        "_internal_originalTime": 1689787877000
    },
    {
        "time": 1689787879000,
        "value": 139.639,
        "_internal_originalTime": 1689787879000
    },
    {
        "time": 1689787880000,
        "value": 139.639,
        "_internal_originalTime": 1689787880000
    },
    {
        "time": 1689787881000,
        "value": 139.634,
        "_internal_originalTime": 1689787881000
    },
    {
        "time": 1689787883000,
        "value": 139.632,
        "_internal_originalTime": 1689787883000
    },
    {
        "time": 1689787885000,
        "value": 139.633,
        "_internal_originalTime": 1689787885000
    },
    {
        "time": 1689787887000,
        "value": 139.63,
        "_internal_originalTime": 1689787887000
    },
    {
        "time": 1689787888000,
        "value": 139.633,
        "_internal_originalTime": 1689787888000
    },
    {
        "time": 1689787889000,
        "value": 139.633,
        "_internal_originalTime": 1689787889000
    },
    {
        "time": 1689787890000,
        "value": 139.633,
        "_internal_originalTime": 1689787890000
    },
    {
        "time": 1689787892000,
        "value": 139.632,
        "_internal_originalTime": 1689787892000
    },
    {
        "time": 1689787893000,
        "value": 139.628,
        "_internal_originalTime": 1689787893000
    },
    {
        "time": 1689787895000,
        "value": 139.629,
        "_internal_originalTime": 1689787895000
    },
    {
        "time": 1689787896000,
        "value": 139.623,
        "_internal_originalTime": 1689787896000
    },
    {
        "time": 1689787898000,
        "value": 139.623,
        "_internal_originalTime": 1689787898000
    },
    {
        "time": 1689787900000,
        "value": 139.629,
        "_internal_originalTime": 1689787900000
    },
    {
        "time": 1689787903000,
        "value": 139.625,
        "_internal_originalTime": 1689787903000
    },
    {
        "time": 1689787905000,
        "value": 139.63,
        "_internal_originalTime": 1689787905000
    },
    {
        "time": 1689787906000,
        "value": 139.629,
        "_internal_originalTime": 1689787906000
    },
    {
        "time": 1689787907000,
        "value": 139.637,
        "_internal_originalTime": 1689787907000
    },
    {
        "time": 1689787908000,
        "value": 139.644,
        "_internal_originalTime": 1689787908000
    },
    {
        "time": 1689787909000,
        "value": 139.65,
        "_internal_originalTime": 1689787909000
    },
    {
        "time": 1689787910000,
        "value": 139.646,
        "_internal_originalTime": 1689787910000
    },
    {
        "time": 1689787912000,
        "value": 139.645,
        "_internal_originalTime": 1689787912000
    },
    {
        "time": 1689787916000,
        "value": 139.644,
        "_internal_originalTime": 1689787916000
    },
    {
        "time": 1689787920000,
        "value": 139.643,
        "_internal_originalTime": 1689787920000
    },
    {
        "time": 1689787921000,
        "value": 139.644,
        "_internal_originalTime": 1689787921000
    },
    {
        "time": 1689787922000,
        "value": 139.643,
        "_internal_originalTime": 1689787922000
    },
    {
        "time": 1689787923000,
        "value": 139.641,
        "_internal_originalTime": 1689787923000
    },
    {
        "time": 1689787925000,
        "value": 139.656,
        "_internal_originalTime": 1689787925000
    },
    {
        "time": 1689787927000,
        "value": 139.653,
        "_internal_originalTime": 1689787927000
    },
    {
        "time": 1689787928000,
        "value": 139.658,
        "_internal_originalTime": 1689787928000
    },
    {
        "time": 1689787929000,
        "value": 139.652,
        "_internal_originalTime": 1689787929000
    },
    {
        "time": 1689787931000,
        "value": 139.658,
        "_internal_originalTime": 1689787931000
    },
    {
        "time": 1689787932000,
        "value": 139.653,
        "_internal_originalTime": 1689787932000
    },
    {
        "time": 1689787934000,
        "value": 139.658,
        "_internal_originalTime": 1689787934000
    },
    {
        "time": 1689787935000,
        "value": 139.651,
        "_internal_originalTime": 1689787935000
    },
    {
        "time": 1689787936000,
        "value": 139.667,
        "_internal_originalTime": 1689787936000
    },
    {
        "time": 1689787938000,
        "value": 139.663,
        "_internal_originalTime": 1689787938000
    },
    {
        "time": 1689787939000,
        "value": 139.666,
        "_internal_originalTime": 1689787939000
    },
    {
        "time": 1689787940000,
        "value": 139.659,
        "_internal_originalTime": 1689787940000
    },
    {
        "time": 1689787941000,
        "value": 139.666,
        "_internal_originalTime": 1689787941000
    },
    {
        "time": 1689787943000,
        "value": 139.667,
        "_internal_originalTime": 1689787943000
    },
    {
        "time": 1689787945000,
        "value": 139.659,
        "_internal_originalTime": 1689787945000
    },
    {
        "time": 1689787946000,
        "value": 139.659,
        "_internal_originalTime": 1689787946000
    },
    {
        "time": 1689787948000,
        "value": 139.667,
        "_internal_originalTime": 1689787948000
    },
    {
        "time": 1689787950000,
        "value": 139.659,
        "_internal_originalTime": 1689787950000
    },
    {
        "time": 1689787953000,
        "value": 139.652,
        "_internal_originalTime": 1689787953000
    },
    {
        "time": 1689787956000,
        "value": 139.653,
        "_internal_originalTime": 1689787956000
    },
    {
        "time": 1689787958000,
        "value": 139.653,
        "_internal_originalTime": 1689787958000
    },
    {
        "time": 1689787959000,
        "value": 139.652,
        "_internal_originalTime": 1689787959000
    },
    {
        "time": 1689787960000,
        "value": 139.653,
        "_internal_originalTime": 1689787960000
    },
    {
        "time": 1689787961000,
        "value": 139.655,
        "_internal_originalTime": 1689787961000
    },
    {
        "time": 1689787962000,
        "value": 139.658,
        "_internal_originalTime": 1689787962000
    },
    {
        "time": 1689787965000,
        "value": 139.658,
        "_internal_originalTime": 1689787965000
    },
    {
        "time": 1689787966000,
        "value": 139.649,
        "_internal_originalTime": 1689787966000
    },
    {
        "time": 1689787969000,
        "value": 139.653,
        "_internal_originalTime": 1689787969000
    },
    {
        "time": 1689787970000,
        "value": 139.652,
        "_internal_originalTime": 1689787970000
    },
    {
        "time": 1689787972000,
        "value": 139.651,
        "_internal_originalTime": 1689787972000
    },
    {
        "time": 1689787973000,
        "value": 139.651,
        "_internal_originalTime": 1689787973000
    },
    {
        "time": 1689787974000,
        "value": 139.651,
        "_internal_originalTime": 1689787974000
    },
    {
        "time": 1689787976000,
        "value": 139.651,
        "_internal_originalTime": 1689787976000
    },
    {
        "time": 1689787978000,
        "value": 139.65,
        "_internal_originalTime": 1689787978000
    },
    {
        "time": 1689787979000,
        "value": 139.652,
        "_internal_originalTime": 1689787979000
    },
    {
        "time": 1689787980000,
        "value": 139.658,
        "_internal_originalTime": 1689787980000
    },
    {
        "time": 1689787981000,
        "value": 139.651,
        "_internal_originalTime": 1689787981000
    },
    {
        "time": 1689787982000,
        "value": 139.653,
        "_internal_originalTime": 1689787982000
    },
    {
        "time": 1689787983000,
        "value": 139.651,
        "_internal_originalTime": 1689787983000
    },
    {
        "time": 1689787984000,
        "value": 139.65,
        "_internal_originalTime": 1689787984000
    },
    {
        "time": 1689787985000,
        "value": 139.653,
        "_internal_originalTime": 1689787985000
    },
    {
        "time": 1689787987000,
        "value": 139.65,
        "_internal_originalTime": 1689787987000
    },
    {
        "time": 1689787988000,
        "value": 139.652,
        "_internal_originalTime": 1689787988000
    },
    {
        "time": 1689787989000,
        "value": 139.661,
        "_internal_originalTime": 1689787989000
    },
    {
        "time": 1689787990000,
        "value": 139.659,
        "_internal_originalTime": 1689787990000
    },
    {
        "time": 1689787993000,
        "value": 139.652,
        "_internal_originalTime": 1689787993000
    },
    {
        "time": 1689787995000,
        "value": 139.649,
        "_internal_originalTime": 1689787995000
    },
    {
        "time": 1689787996000,
        "value": 139.651,
        "_internal_originalTime": 1689787996000
    },
    {
        "time": 1689787997000,
        "value": 139.65,
        "_internal_originalTime": 1689787997000
    },
    {
        "time": 1689787999000,
        "value": 139.643,
        "_internal_originalTime": 1689787999000
    },
    {
        "time": 1689788000000,
        "value": 139.651,
        "_internal_originalTime": 1689788000000
    },
    {
        "time": 1689788001000,
        "value": 139.644,
        "_internal_originalTime": 1689788001000
    },
    {
        "time": 1689788002000,
        "value": 139.643,
        "_internal_originalTime": 1689788002000
    },
    {
        "time": 1689788003000,
        "value": 139.642,
        "_internal_originalTime": 1689788003000
    },
    {
        "time": 1689788004000,
        "value": 139.643,
        "_internal_originalTime": 1689788004000
    },
    {
        "time": 1689788007000,
        "value": 139.642,
        "_internal_originalTime": 1689788007000
    },
    {
        "time": 1689788009000,
        "value": 139.641,
        "_internal_originalTime": 1689788009000
    },
    {
        "time": 1689788011000,
        "value": 139.639,
        "_internal_originalTime": 1689788011000
    },
    {
        "time": 1689788012000,
        "value": 139.64,
        "_internal_originalTime": 1689788012000
    },
    {
        "time": 1689788013000,
        "value": 139.639,
        "_internal_originalTime": 1689788013000
    },
    {
        "time": 1689788015000,
        "value": 139.639,
        "_internal_originalTime": 1689788015000
    },
    {
        "time": 1689788018000,
        "value": 139.635,
        "_internal_originalTime": 1689788018000
    },
    {
        "time": 1689788019000,
        "value": 139.639,
        "_internal_originalTime": 1689788019000
    },
    {
        "time": 1689788020000,
        "value": 139.639,
        "_internal_originalTime": 1689788020000
    },
    {
        "time": 1689788022000,
        "value": 139.638,
        "_internal_originalTime": 1689788022000
    },
    {
        "time": 1689788023000,
        "value": 139.632,
        "_internal_originalTime": 1689788023000
    },
    {
        "time": 1689788025000,
        "value": 139.624,
        "_internal_originalTime": 1689788025000
    },
    {
        "time": 1689788026000,
        "value": 139.633,
        "_internal_originalTime": 1689788026000
    },
    {
        "time": 1689788027000,
        "value": 139.633,
        "_internal_originalTime": 1689788027000
    },
    {
        "time": 1689788028000,
        "value": 139.626,
        "_internal_originalTime": 1689788028000
    },
    {
        "time": 1689788030000,
        "value": 139.636,
        "_internal_originalTime": 1689788030000
    },
    {
        "time": 1689788033000,
        "value": 139.63,
        "_internal_originalTime": 1689788033000
    },
    {
        "time": 1689788034000,
        "value": 139.631,
        "_internal_originalTime": 1689788034000
    },
    {
        "time": 1689788035000,
        "value": 139.63,
        "_internal_originalTime": 1689788035000
    },
    {
        "time": 1689788036000,
        "value": 139.631,
        "_internal_originalTime": 1689788036000
    },
    {
        "time": 1689788038000,
        "value": 139.634,
        "_internal_originalTime": 1689788038000
    },
    {
        "time": 1689788039000,
        "value": 139.632,
        "_internal_originalTime": 1689788039000
    },
    {
        "time": 1689788041000,
        "value": 139.629,
        "_internal_originalTime": 1689788041000
    },
    {
        "time": 1689788043000,
        "value": 139.629,
        "_internal_originalTime": 1689788043000
    },
    {
        "time": 1689788045000,
        "value": 139.629,
        "_internal_originalTime": 1689788045000
    },
    {
        "time": 1689788047000,
        "value": 139.625,
        "_internal_originalTime": 1689788047000
    },
    {
        "time": 1689788048000,
        "value": 139.629,
        "_internal_originalTime": 1689788048000
    },
    {
        "time": 1689788050000,
        "value": 139.632,
        "_internal_originalTime": 1689788050000
    },
    {
        "time": 1689788052000,
        "value": 139.625,
        "_internal_originalTime": 1689788052000
    },
    {
        "time": 1689788053000,
        "value": 139.636,
        "_internal_originalTime": 1689788053000
    },
    {
        "time": 1689788054000,
        "value": 139.633,
        "_internal_originalTime": 1689788054000
    },
    {
        "time": 1689788056000,
        "value": 139.64,
        "_internal_originalTime": 1689788056000
    },
    {
        "time": 1689788057000,
        "value": 139.629,
        "_internal_originalTime": 1689788057000
    },
    {
        "time": 1689788058000,
        "value": 139.625,
        "_internal_originalTime": 1689788058000
    },
    {
        "time": 1689788060000,
        "value": 139.624,
        "_internal_originalTime": 1689788060000
    },
    {
        "time": 1689788061000,
        "value": 139.623,
        "_internal_originalTime": 1689788061000
    },
    {
        "time": 1689788063000,
        "value": 139.628,
        "_internal_originalTime": 1689788063000
    },
    {
        "time": 1689788064000,
        "value": 139.629,
        "_internal_originalTime": 1689788064000
    },
    {
        "time": 1689788065000,
        "value": 139.629,
        "_internal_originalTime": 1689788065000
    },
    {
        "time": 1689788069000,
        "value": 139.629,
        "_internal_originalTime": 1689788069000
    },
    {
        "time": 1689788070000,
        "value": 139.623,
        "_internal_originalTime": 1689788070000
    },
    {
        "time": 1689788071000,
        "value": 139.622,
        "_internal_originalTime": 1689788071000
    },
    {
        "time": 1689788072000,
        "value": 139.623,
        "_internal_originalTime": 1689788072000
    },
    {
        "time": 1689788073000,
        "value": 139.622,
        "_internal_originalTime": 1689788073000
    },
    {
        "time": 1689788075000,
        "value": 139.622,
        "_internal_originalTime": 1689788075000
    },
    {
        "time": 1689788077000,
        "value": 139.629,
        "_internal_originalTime": 1689788077000
    },
    {
        "time": 1689788078000,
        "value": 139.625,
        "_internal_originalTime": 1689788078000
    },
    {
        "time": 1689788080000,
        "value": 139.629,
        "_internal_originalTime": 1689788080000
    },
    {
        "time": 1689788082000,
        "value": 139.629,
        "_internal_originalTime": 1689788082000
    },
    {
        "time": 1689788084000,
        "value": 139.627,
        "_internal_originalTime": 1689788084000
    },
    {
        "time": 1689788085000,
        "value": 139.628,
        "_internal_originalTime": 1689788085000
    },
    {
        "time": 1689788086000,
        "value": 139.62,
        "_internal_originalTime": 1689788086000
    },
    {
        "time": 1689788088000,
        "value": 139.628,
        "_internal_originalTime": 1689788088000
    },
    {
        "time": 1689788089000,
        "value": 139.62,
        "_internal_originalTime": 1689788089000
    },
    {
        "time": 1689788090000,
        "value": 139.621,
        "_internal_originalTime": 1689788090000
    },
    {
        "time": 1689788092000,
        "value": 139.622,
        "_internal_originalTime": 1689788092000
    },
    {
        "time": 1689788096000,
        "value": 139.628,
        "_internal_originalTime": 1689788096000
    },
    {
        "time": 1689788097000,
        "value": 139.629,
        "_internal_originalTime": 1689788097000
    },
    {
        "time": 1689788100000,
        "value": 139.623,
        "_internal_originalTime": 1689788100000
    },
    {
        "time": 1689788101000,
        "value": 139.628,
        "_internal_originalTime": 1689788101000
    },
    {
        "time": 1689788102000,
        "value": 139.621,
        "_internal_originalTime": 1689788102000
    },
    {
        "time": 1689788104000,
        "value": 139.622,
        "_internal_originalTime": 1689788104000
    },
    {
        "time": 1689788105000,
        "value": 139.623,
        "_internal_originalTime": 1689788105000
    },
    {
        "time": 1689788107000,
        "value": 139.623,
        "_internal_originalTime": 1689788107000
    },
    {
        "time": 1689788108000,
        "value": 139.625,
        "_internal_originalTime": 1689788108000
    },
    {
        "time": 1689788109000,
        "value": 139.621,
        "_internal_originalTime": 1689788109000
    },
    {
        "time": 1689788110000,
        "value": 139.629,
        "_internal_originalTime": 1689788110000
    },
    {
        "time": 1689788112000,
        "value": 139.629,
        "_internal_originalTime": 1689788112000
    },
    {
        "time": 1689788113000,
        "value": 139.637,
        "_internal_originalTime": 1689788113000
    },
    {
        "time": 1689788115000,
        "value": 139.633,
        "_internal_originalTime": 1689788115000
    },
    {
        "time": 1689788116000,
        "value": 139.633,
        "_internal_originalTime": 1689788116000
    },
    {
        "time": 1689788117000,
        "value": 139.63,
        "_internal_originalTime": 1689788117000
    },
    {
        "time": 1689788119000,
        "value": 139.63,
        "_internal_originalTime": 1689788119000
    },
    {
        "time": 1689788120000,
        "value": 139.623,
        "_internal_originalTime": 1689788120000
    },
    {
        "time": 1689788122000,
        "value": 139.635,
        "_internal_originalTime": 1689788122000
    },
    {
        "time": 1689788124000,
        "value": 139.635,
        "_internal_originalTime": 1689788124000
    },
    {
        "time": 1689788128000,
        "value": 139.631,
        "_internal_originalTime": 1689788128000
    },
    {
        "time": 1689788129000,
        "value": 139.631,
        "_internal_originalTime": 1689788129000
    },
    {
        "time": 1689788130000,
        "value": 139.638,
        "_internal_originalTime": 1689788130000
    },
    {
        "time": 1689788131000,
        "value": 139.638,
        "_internal_originalTime": 1689788131000
    },
    {
        "time": 1689788133000,
        "value": 139.631,
        "_internal_originalTime": 1689788133000
    },
    {
        "time": 1689788134000,
        "value": 139.632,
        "_internal_originalTime": 1689788134000
    },
    {
        "time": 1689788136000,
        "value": 139.63,
        "_internal_originalTime": 1689788136000
    },
    {
        "time": 1689788137000,
        "value": 139.625,
        "_internal_originalTime": 1689788137000
    },
    {
        "time": 1689788138000,
        "value": 139.625,
        "_internal_originalTime": 1689788138000
    },
    {
        "time": 1689788141000,
        "value": 139.629,
        "_internal_originalTime": 1689788141000
    },
    {
        "time": 1689788142000,
        "value": 139.629,
        "_internal_originalTime": 1689788142000
    },
    {
        "time": 1689788145000,
        "value": 139.625,
        "_internal_originalTime": 1689788145000
    },
    {
        "time": 1689788147000,
        "value": 139.624,
        "_internal_originalTime": 1689788147000
    },
    {
        "time": 1689788148000,
        "value": 139.63,
        "_internal_originalTime": 1689788148000
    },
    {
        "time": 1689788149000,
        "value": 139.629,
        "_internal_originalTime": 1689788149000
    },
    {
        "time": 1689788151000,
        "value": 139.629,
        "_internal_originalTime": 1689788151000
    },
    {
        "time": 1689788153000,
        "value": 139.625,
        "_internal_originalTime": 1689788153000
    },
    {
        "time": 1689788154000,
        "value": 139.623,
        "_internal_originalTime": 1689788154000
    },
    {
        "time": 1689788157000,
        "value": 139.628,
        "_internal_originalTime": 1689788157000
    },
    {
        "time": 1689788158000,
        "value": 139.629,
        "_internal_originalTime": 1689788158000
    },
    {
        "time": 1689788160000,
        "value": 139.623,
        "_internal_originalTime": 1689788160000
    },
    {
        "time": 1689788161000,
        "value": 139.629,
        "_internal_originalTime": 1689788161000
    },
    {
        "time": 1689788163000,
        "value": 139.63,
        "_internal_originalTime": 1689788163000
    },
    {
        "time": 1689788165000,
        "value": 139.629,
        "_internal_originalTime": 1689788165000
    },
    {
        "time": 1689788167000,
        "value": 139.639,
        "_internal_originalTime": 1689788167000
    },
    {
        "time": 1689788168000,
        "value": 139.635,
        "_internal_originalTime": 1689788168000
    },
    {
        "time": 1689788171000,
        "value": 139.632,
        "_internal_originalTime": 1689788171000
    },
    {
        "time": 1689788173000,
        "value": 139.638,
        "_internal_originalTime": 1689788173000
    },
    {
        "time": 1689788175000,
        "value": 139.633,
        "_internal_originalTime": 1689788175000
    },
    {
        "time": 1689788176000,
        "value": 139.64,
        "_internal_originalTime": 1689788176000
    },
    {
        "time": 1689788177000,
        "value": 139.64,
        "_internal_originalTime": 1689788177000
    },
    {
        "time": 1689788178000,
        "value": 139.642,
        "_internal_originalTime": 1689788178000
    },
    {
        "time": 1689788179000,
        "value": 139.636,
        "_internal_originalTime": 1689788179000
    },
    {
        "time": 1689788180000,
        "value": 139.636,
        "_internal_originalTime": 1689788180000
    },
    {
        "time": 1689788181000,
        "value": 139.64,
        "_internal_originalTime": 1689788181000
    },
    {
        "time": 1689788182000,
        "value": 139.641,
        "_internal_originalTime": 1689788182000
    },
    {
        "time": 1689788183000,
        "value": 139.635,
        "_internal_originalTime": 1689788183000
    },
    {
        "time": 1689788184000,
        "value": 139.634,
        "_internal_originalTime": 1689788184000
    },
    {
        "time": 1689788186000,
        "value": 139.64,
        "_internal_originalTime": 1689788186000
    },
    {
        "time": 1689788188000,
        "value": 139.645,
        "_internal_originalTime": 1689788188000
    },
    {
        "time": 1689788189000,
        "value": 139.636,
        "_internal_originalTime": 1689788189000
    },
    {
        "time": 1689788190000,
        "value": 139.642,
        "_internal_originalTime": 1689788190000
    },
    {
        "time": 1689788192000,
        "value": 139.641,
        "_internal_originalTime": 1689788192000
    },
    {
        "time": 1689788193000,
        "value": 139.639,
        "_internal_originalTime": 1689788193000
    },
    {
        "time": 1689788194000,
        "value": 139.636,
        "_internal_originalTime": 1689788194000
    },
    {
        "time": 1689788195000,
        "value": 139.635,
        "_internal_originalTime": 1689788195000
    },
    {
        "time": 1689788196000,
        "value": 139.64,
        "_internal_originalTime": 1689788196000
    },
    {
        "time": 1689788198000,
        "value": 139.635,
        "_internal_originalTime": 1689788198000
    },
    {
        "time": 1689788200000,
        "value": 139.639,
        "_internal_originalTime": 1689788200000
    },
    {
        "time": 1689788201000,
        "value": 139.639,
        "_internal_originalTime": 1689788201000
    },
    {
        "time": 1689788204000,
        "value": 139.635,
        "_internal_originalTime": 1689788204000
    },
    {
        "time": 1689788206000,
        "value": 139.634,
        "_internal_originalTime": 1689788206000
    },
    {
        "time": 1689788213000,
        "value": 139.64,
        "_internal_originalTime": 1689788213000
    },
    {
        "time": 1689788214000,
        "value": 139.64,
        "_internal_originalTime": 1689788214000
    },
    {
        "time": 1689788216000,
        "value": 139.644,
        "_internal_originalTime": 1689788216000
    },
    {
        "time": 1689788218000,
        "value": 139.641,
        "_internal_originalTime": 1689788218000
    },
    {
        "time": 1689788220000,
        "value": 139.641,
        "_internal_originalTime": 1689788220000
    },
    {
        "time": 1689788221000,
        "value": 139.64,
        "_internal_originalTime": 1689788221000
    },
    {
        "time": 1689788228000,
        "value": 139.644,
        "_internal_originalTime": 1689788228000
    },
    {
        "time": 1689788230000,
        "value": 139.64,
        "_internal_originalTime": 1689788230000
    },
    {
        "time": 1689788232000,
        "value": 139.646,
        "_internal_originalTime": 1689788232000
    },
    {
        "time": 1689788233000,
        "value": 139.646,
        "_internal_originalTime": 1689788233000
    },
    {
        "time": 1689788234000,
        "value": 139.647,
        "_internal_originalTime": 1689788234000
    },
    {
        "time": 1689788236000,
        "value": 139.64,
        "_internal_originalTime": 1689788236000
    },
    {
        "time": 1689788246000,
        "value": 139.641,
        "_internal_originalTime": 1689788246000
    },
    {
        "time": 1689788248000,
        "value": 139.646,
        "_internal_originalTime": 1689788248000
    },
    {
        "time": 1689788252000,
        "value": 139.646,
        "_internal_originalTime": 1689788252000
    },
    {
        "time": 1689788255000,
        "value": 139.628,
        "_internal_originalTime": 1689788255000
    },
    {
        "time": 1689788256000,
        "value": 139.631,
        "_internal_originalTime": 1689788256000
    },
    {
        "time": 1689788258000,
        "value": 139.626,
        "_internal_originalTime": 1689788258000
    },
    {
        "time": 1689788259000,
        "value": 139.633,
        "_internal_originalTime": 1689788259000
    },
    {
        "time": 1689788260000,
        "value": 139.633,
        "_internal_originalTime": 1689788260000
    },
    {
        "time": 1689788261000,
        "value": 139.628,
        "_internal_originalTime": 1689788261000
    },
    {
        "time": 1689788263000,
        "value": 139.628,
        "_internal_originalTime": 1689788263000
    },
    {
        "time": 1689788264000,
        "value": 139.628,
        "_internal_originalTime": 1689788264000
    },
    {
        "time": 1689788266000,
        "value": 139.626,
        "_internal_originalTime": 1689788266000
    },
    {
        "time": 1689788267000,
        "value": 139.633,
        "_internal_originalTime": 1689788267000
    },
    {
        "time": 1689788268000,
        "value": 139.627,
        "_internal_originalTime": 1689788268000
    },
    {
        "time": 1689788270000,
        "value": 139.63,
        "_internal_originalTime": 1689788270000
    },
    {
        "time": 1689788272000,
        "value": 139.638,
        "_internal_originalTime": 1689788272000
    },
    {
        "time": 1689788281000,
        "value": 139.638,
        "_internal_originalTime": 1689788281000
    },
    {
        "time": 1689788283000,
        "value": 139.638,
        "_internal_originalTime": 1689788283000
    },
    {
        "time": 1689788287000,
        "value": 139.632,
        "_internal_originalTime": 1689788287000
    },
    {
        "time": 1689788288000,
        "value": 139.634,
        "_internal_originalTime": 1689788288000
    },
    {
        "time": 1689788289000,
        "value": 139.637,
        "_internal_originalTime": 1689788289000
    },
    {
        "time": 1689788290000,
        "value": 139.638,
        "_internal_originalTime": 1689788290000
    },
    {
        "time": 1689788294000,
        "value": 139.638,
        "_internal_originalTime": 1689788294000
    },
    {
        "time": 1689788296000,
        "value": 139.632,
        "_internal_originalTime": 1689788296000
    },
    {
        "time": 1689788304000,
        "value": 139.634,
        "_internal_originalTime": 1689788304000
    },
    {
        "time": 1689788305000,
        "value": 139.638,
        "_internal_originalTime": 1689788305000
    },
    {
        "time": 1689788312000,
        "value": 139.632,
        "_internal_originalTime": 1689788312000
    },
    {
        "time": 1689788314000,
        "value": 139.633,
        "_internal_originalTime": 1689788314000
    },
    {
        "time": 1689788318000,
        "value": 139.632,
        "_internal_originalTime": 1689788318000
    },
    {
        "time": 1689788323000,
        "value": 139.633,
        "_internal_originalTime": 1689788323000
    },
    {
        "time": 1689788326000,
        "value": 139.632,
        "_internal_originalTime": 1689788326000
    },
    {
        "time": 1689788331000,
        "value": 139.632,
        "_internal_originalTime": 1689788331000
    },
    {
        "time": 1689788334000,
        "value": 139.633,
        "_internal_originalTime": 1689788334000
    },
    {
        "time": 1689788335000,
        "value": 139.64,
        "_internal_originalTime": 1689788335000
    },
    {
        "time": 1689788336000,
        "value": 139.64,
        "_internal_originalTime": 1689788336000
    },
    {
        "time": 1689788337000,
        "value": 139.641,
        "_internal_originalTime": 1689788337000
    },
    {
        "time": 1689788340000,
        "value": 139.647,
        "_internal_originalTime": 1689788340000
    },
    {
        "time": 1689788341000,
        "value": 139.639,
        "_internal_originalTime": 1689788341000
    },
    {
        "time": 1689788342000,
        "value": 139.641,
        "_internal_originalTime": 1689788342000
    },
    {
        "time": 1689788343000,
        "value": 139.636,
        "_internal_originalTime": 1689788343000
    },
    {
        "time": 1689788344000,
        "value": 139.643,
        "_internal_originalTime": 1689788344000
    },
    {
        "time": 1689788346000,
        "value": 139.636,
        "_internal_originalTime": 1689788346000
    },
    {
        "time": 1689788347000,
        "value": 139.641,
        "_internal_originalTime": 1689788347000
    },
    {
        "time": 1689788348000,
        "value": 139.641,
        "_internal_originalTime": 1689788348000
    },
    {
        "time": 1689788349000,
        "value": 139.635,
        "_internal_originalTime": 1689788349000
    },
    {
        "time": 1689788350000,
        "value": 139.641,
        "_internal_originalTime": 1689788350000
    },
    {
        "time": 1689788351000,
        "value": 139.635,
        "_internal_originalTime": 1689788351000
    },
    {
        "time": 1689788353000,
        "value": 139.634,
        "_internal_originalTime": 1689788353000
    },
    {
        "time": 1689788355000,
        "value": 139.646,
        "_internal_originalTime": 1689788355000
    },
    {
        "time": 1689788356000,
        "value": 139.647,
        "_internal_originalTime": 1689788356000
    },
    {
        "time": 1689788360000,
        "value": 139.64,
        "_internal_originalTime": 1689788360000
    },
    {
        "time": 1689788365000,
        "value": 139.641,
        "_internal_originalTime": 1689788365000
    },
    {
        "time": 1689788367000,
        "value": 139.64,
        "_internal_originalTime": 1689788367000
    },
    {
        "time": 1689788368000,
        "value": 139.641,
        "_internal_originalTime": 1689788368000
    },
    {
        "time": 1689788371000,
        "value": 139.64,
        "_internal_originalTime": 1689788371000
    },
    {
        "time": 1689788375000,
        "value": 139.641,
        "_internal_originalTime": 1689788375000
    },
    {
        "time": 1689788376000,
        "value": 139.641,
        "_internal_originalTime": 1689788376000
    },
    {
        "time": 1689788378000,
        "value": 139.637,
        "_internal_originalTime": 1689788378000
    },
    {
        "time": 1689788383000,
        "value": 139.641,
        "_internal_originalTime": 1689788383000
    },
    {
        "time": 1689788385000,
        "value": 139.635,
        "_internal_originalTime": 1689788385000
    },
    {
        "time": 1689788386000,
        "value": 139.636,
        "_internal_originalTime": 1689788386000
    },
    {
        "time": 1689788387000,
        "value": 139.641,
        "_internal_originalTime": 1689788387000
    },
    {
        "time": 1689788389000,
        "value": 139.635,
        "_internal_originalTime": 1689788389000
    },
    {
        "time": 1689788390000,
        "value": 139.634,
        "_internal_originalTime": 1689788390000
    },
    {
        "time": 1689788391000,
        "value": 139.641,
        "_internal_originalTime": 1689788391000
    },
    {
        "time": 1689788392000,
        "value": 139.637,
        "_internal_originalTime": 1689788392000
    },
    {
        "time": 1689788393000,
        "value": 139.64,
        "_internal_originalTime": 1689788393000
    },
    {
        "time": 1689788395000,
        "value": 139.641,
        "_internal_originalTime": 1689788395000
    },
    {
        "time": 1689788397000,
        "value": 139.636,
        "_internal_originalTime": 1689788397000
    },
    {
        "time": 1689788398000,
        "value": 139.641,
        "_internal_originalTime": 1689788398000
    },
    {
        "time": 1689788399000,
        "value": 139.64,
        "_internal_originalTime": 1689788399000
    },
    {
        "time": 1689788400000,
        "value": 139.639,
        "_internal_originalTime": 1689788400000
    },
    {
        "time": 1689788402000,
        "value": 139.641,
        "_internal_originalTime": 1689788402000
    },
    {
        "time": 1689788403000,
        "value": 139.641,
        "_internal_originalTime": 1689788403000
    },
    {
        "time": 1689788404000,
        "value": 139.636,
        "_internal_originalTime": 1689788404000
    },
    {
        "time": 1689788405000,
        "value": 139.636,
        "_internal_originalTime": 1689788405000
    },
    {
        "time": 1689788407000,
        "value": 139.637,
        "_internal_originalTime": 1689788407000
    },
    {
        "time": 1689788408000,
        "value": 139.639,
        "_internal_originalTime": 1689788408000
    },
    {
        "time": 1689788409000,
        "value": 139.635,
        "_internal_originalTime": 1689788409000
    },
    {
        "time": 1689788412000,
        "value": 139.636,
        "_internal_originalTime": 1689788412000
    },
    {
        "time": 1689788414000,
        "value": 139.641,
        "_internal_originalTime": 1689788414000
    },
    {
        "time": 1689788416000,
        "value": 139.641,
        "_internal_originalTime": 1689788416000
    },
    {
        "time": 1689788417000,
        "value": 139.642,
        "_internal_originalTime": 1689788417000
    },
    {
        "time": 1689788418000,
        "value": 139.639,
        "_internal_originalTime": 1689788418000
    },
    {
        "time": 1689788422000,
        "value": 139.639,
        "_internal_originalTime": 1689788422000
    },
    {
        "time": 1689788423000,
        "value": 139.64,
        "_internal_originalTime": 1689788423000
    },
    {
        "time": 1689788424000,
        "value": 139.639,
        "_internal_originalTime": 1689788424000
    },
    {
        "time": 1689788426000,
        "value": 139.64,
        "_internal_originalTime": 1689788426000
    },
    {
        "time": 1689788427000,
        "value": 139.636,
        "_internal_originalTime": 1689788427000
    },
    {
        "time": 1689788429000,
        "value": 139.64,
        "_internal_originalTime": 1689788429000
    },
    {
        "time": 1689788430000,
        "value": 139.64,
        "_internal_originalTime": 1689788430000
    },
    {
        "time": 1689788431000,
        "value": 139.635,
        "_internal_originalTime": 1689788431000
    },
    {
        "time": 1689788432000,
        "value": 139.639,
        "_internal_originalTime": 1689788432000
    },
    {
        "time": 1689788434000,
        "value": 139.64,
        "_internal_originalTime": 1689788434000
    },
    {
        "time": 1689788435000,
        "value": 139.64,
        "_internal_originalTime": 1689788435000
    },
    {
        "time": 1689788439000,
        "value": 139.639,
        "_internal_originalTime": 1689788439000
    },
    {
        "time": 1689788440000,
        "value": 139.642,
        "_internal_originalTime": 1689788440000
    },
    {
        "time": 1689788441000,
        "value": 139.636,
        "_internal_originalTime": 1689788441000
    },
    {
        "time": 1689788442000,
        "value": 139.639,
        "_internal_originalTime": 1689788442000
    },
    {
        "time": 1689788445000,
        "value": 139.636,
        "_internal_originalTime": 1689788445000
    },
    {
        "time": 1689788446000,
        "value": 139.64,
        "_internal_originalTime": 1689788446000
    },
    {
        "time": 1689788447000,
        "value": 139.637,
        "_internal_originalTime": 1689788447000
    },
    {
        "time": 1689788448000,
        "value": 139.641,
        "_internal_originalTime": 1689788448000
    },
    {
        "time": 1689788450000,
        "value": 139.636,
        "_internal_originalTime": 1689788450000
    },
    {
        "time": 1689788451000,
        "value": 139.636,
        "_internal_originalTime": 1689788451000
    },
    {
        "time": 1689788452000,
        "value": 139.639,
        "_internal_originalTime": 1689788452000
    },
    {
        "time": 1689788460000,
        "value": 139.639,
        "_internal_originalTime": 1689788460000
    },
    {
        "time": 1689788463000,
        "value": 139.639,
        "_internal_originalTime": 1689788463000
    },
    {
        "time": 1689788467000,
        "value": 139.636,
        "_internal_originalTime": 1689788467000
    },
    {
        "time": 1689788468000,
        "value": 139.636,
        "_internal_originalTime": 1689788468000
    },
    {
        "time": 1689788469000,
        "value": 139.636,
        "_internal_originalTime": 1689788469000
    },
    {
        "time": 1689788470000,
        "value": 139.636,
        "_internal_originalTime": 1689788470000
    },
    {
        "time": 1689788471000,
        "value": 139.64,
        "_internal_originalTime": 1689788471000
    },
    {
        "time": 1689788472000,
        "value": 139.64,
        "_internal_originalTime": 1689788472000
    },
    {
        "time": 1689788474000,
        "value": 139.64,
        "_internal_originalTime": 1689788474000
    },
    {
        "time": 1689788475000,
        "value": 139.641,
        "_internal_originalTime": 1689788475000
    },
    {
        "time": 1689788476000,
        "value": 139.639,
        "_internal_originalTime": 1689788476000
    },
    {
        "time": 1689788477000,
        "value": 139.639,
        "_internal_originalTime": 1689788477000
    },
    {
        "time": 1689788479000,
        "value": 139.636,
        "_internal_originalTime": 1689788479000
    },
    {
        "time": 1689788480000,
        "value": 139.64,
        "_internal_originalTime": 1689788480000
    },
    {
        "time": 1689788484000,
        "value": 139.636,
        "_internal_originalTime": 1689788484000
    },
    {
        "time": 1689788485000,
        "value": 139.636,
        "_internal_originalTime": 1689788485000
    },
    {
        "time": 1689788487000,
        "value": 139.639,
        "_internal_originalTime": 1689788487000
    },
    {
        "time": 1689788498000,
        "value": 139.637,
        "_internal_originalTime": 1689788498000
    },
    {
        "time": 1689788506000,
        "value": 139.634,
        "_internal_originalTime": 1689788506000
    },
    {
        "time": 1689788509000,
        "value": 139.634,
        "_internal_originalTime": 1689788509000
    },
    {
        "time": 1689788514000,
        "value": 139.635,
        "_internal_originalTime": 1689788514000
    },
    {
        "time": 1689788515000,
        "value": 139.635,
        "_internal_originalTime": 1689788515000
    },
    {
        "time": 1689788516000,
        "value": 139.636,
        "_internal_originalTime": 1689788516000
    },
    {
        "time": 1689788519000,
        "value": 139.636,
        "_internal_originalTime": 1689788519000
    },
    {
        "time": 1689788522000,
        "value": 139.639,
        "_internal_originalTime": 1689788522000
    },
    {
        "time": 1689788526000,
        "value": 139.639,
        "_internal_originalTime": 1689788526000
    },
    {
        "time": 1689788528000,
        "value": 139.637,
        "_internal_originalTime": 1689788528000
    },
    {
        "time": 1689788529000,
        "value": 139.639,
        "_internal_originalTime": 1689788529000
    },
    {
        "time": 1689788530000,
        "value": 139.639,
        "_internal_originalTime": 1689788530000
    },
    {
        "time": 1689788533000,
        "value": 139.639,
        "_internal_originalTime": 1689788533000
    },
    {
        "time": 1689788536000,
        "value": 139.633,
        "_internal_originalTime": 1689788536000
    },
    {
        "time": 1689788541000,
        "value": 139.635,
        "_internal_originalTime": 1689788541000
    },
    {
        "time": 1689788542000,
        "value": 139.636,
        "_internal_originalTime": 1689788542000
    },
    {
        "time": 1689788544000,
        "value": 139.641,
        "_internal_originalTime": 1689788544000
    },
    {
        "time": 1689788545000,
        "value": 139.642,
        "_internal_originalTime": 1689788545000
    },
    {
        "time": 1689788546000,
        "value": 139.643,
        "_internal_originalTime": 1689788546000
    },
    {
        "time": 1689788548000,
        "value": 139.644,
        "_internal_originalTime": 1689788548000
    },
    {
        "time": 1689788549000,
        "value": 139.643,
        "_internal_originalTime": 1689788549000
    },
    {
        "time": 1689788550000,
        "value": 139.64,
        "_internal_originalTime": 1689788550000
    },
    {
        "time": 1689788551000,
        "value": 139.641,
        "_internal_originalTime": 1689788551000
    },
    {
        "time": 1689788553000,
        "value": 139.644,
        "_internal_originalTime": 1689788553000
    },
    {
        "time": 1689788555000,
        "value": 139.645,
        "_internal_originalTime": 1689788555000
    },
    {
        "time": 1689788556000,
        "value": 139.638,
        "_internal_originalTime": 1689788556000
    },
    {
        "time": 1689788557000,
        "value": 139.639,
        "_internal_originalTime": 1689788557000
    },
    {
        "time": 1689788559000,
        "value": 139.645,
        "_internal_originalTime": 1689788559000
    },
    {
        "time": 1689788561000,
        "value": 139.641,
        "_internal_originalTime": 1689788561000
    },
    {
        "time": 1689788562000,
        "value": 139.641,
        "_internal_originalTime": 1689788562000
    },
    {
        "time": 1689788563000,
        "value": 139.641,
        "_internal_originalTime": 1689788563000
    },
    {
        "time": 1689788567000,
        "value": 139.657,
        "_internal_originalTime": 1689788567000
    },
    {
        "time": 1689788568000,
        "value": 139.656,
        "_internal_originalTime": 1689788568000
    },
    {
        "time": 1689788569000,
        "value": 139.658,
        "_internal_originalTime": 1689788569000
    },
    {
        "time": 1689788571000,
        "value": 139.657,
        "_internal_originalTime": 1689788571000
    },
    {
        "time": 1689788572000,
        "value": 139.659,
        "_internal_originalTime": 1689788572000
    },
    {
        "time": 1689788573000,
        "value": 139.655,
        "_internal_originalTime": 1689788573000
    },
    {
        "time": 1689788575000,
        "value": 139.655,
        "_internal_originalTime": 1689788575000
    },
    {
        "time": 1689788576000,
        "value": 139.659,
        "_internal_originalTime": 1689788576000
    },
    {
        "time": 1689788577000,
        "value": 139.658,
        "_internal_originalTime": 1689788577000
    },
    {
        "time": 1689788578000,
        "value": 139.655,
        "_internal_originalTime": 1689788578000
    },
    {
        "time": 1689788580000,
        "value": 139.66,
        "_internal_originalTime": 1689788580000
    },
    {
        "time": 1689788581000,
        "value": 139.663,
        "_internal_originalTime": 1689788581000
    },
    {
        "time": 1689788582000,
        "value": 139.661,
        "_internal_originalTime": 1689788582000
    },
    {
        "time": 1689788588000,
        "value": 139.663,
        "_internal_originalTime": 1689788588000
    },
    {
        "time": 1689788595000,
        "value": 139.662,
        "_internal_originalTime": 1689788595000
    },
    {
        "time": 1689788597000,
        "value": 139.657,
        "_internal_originalTime": 1689788597000
    },
    {
        "time": 1689788598000,
        "value": 139.651,
        "_internal_originalTime": 1689788598000
    },
    {
        "time": 1689788599000,
        "value": 139.647,
        "_internal_originalTime": 1689788599000
    },
    {
        "time": 1689788603000,
        "value": 139.646,
        "_internal_originalTime": 1689788603000
    },
    {
        "time": 1689788604000,
        "value": 139.646,
        "_internal_originalTime": 1689788604000
    },
    {
        "time": 1689788605000,
        "value": 139.646,
        "_internal_originalTime": 1689788605000
    },
    {
        "time": 1689788606000,
        "value": 139.647,
        "_internal_originalTime": 1689788606000
    },
    {
        "time": 1689788608000,
        "value": 139.646,
        "_internal_originalTime": 1689788608000
    },
    {
        "time": 1689788609000,
        "value": 139.646,
        "_internal_originalTime": 1689788609000
    },
    {
        "time": 1689788610000,
        "value": 139.646,
        "_internal_originalTime": 1689788610000
    },
    {
        "time": 1689788612000,
        "value": 139.638,
        "_internal_originalTime": 1689788612000
    },
    {
        "time": 1689788614000,
        "value": 139.642,
        "_internal_originalTime": 1689788614000
    },
    {
        "time": 1689788615000,
        "value": 139.631,
        "_internal_originalTime": 1689788615000
    },
    {
        "time": 1689788616000,
        "value": 139.63,
        "_internal_originalTime": 1689788616000
    },
    {
        "time": 1689788618000,
        "value": 139.63,
        "_internal_originalTime": 1689788618000
    },
    {
        "time": 1689788619000,
        "value": 139.628,
        "_internal_originalTime": 1689788619000
    },
    {
        "time": 1689788620000,
        "value": 139.63,
        "_internal_originalTime": 1689788620000
    },
    {
        "time": 1689788624000,
        "value": 139.63,
        "_internal_originalTime": 1689788624000
    },
    {
        "time": 1689788625000,
        "value": 139.629,
        "_internal_originalTime": 1689788625000
    },
    {
        "time": 1689788627000,
        "value": 139.627,
        "_internal_originalTime": 1689788627000
    },
    {
        "time": 1689788628000,
        "value": 139.631,
        "_internal_originalTime": 1689788628000
    },
    {
        "time": 1689788630000,
        "value": 139.63,
        "_internal_originalTime": 1689788630000
    },
    {
        "time": 1689788632000,
        "value": 139.63,
        "_internal_originalTime": 1689788632000
    },
    {
        "time": 1689788634000,
        "value": 139.628,
        "_internal_originalTime": 1689788634000
    },
    {
        "time": 1689788636000,
        "value": 139.63,
        "_internal_originalTime": 1689788636000
    },
    {
        "time": 1689788637000,
        "value": 139.629,
        "_internal_originalTime": 1689788637000
    },
    {
        "time": 1689788639000,
        "value": 139.629,
        "_internal_originalTime": 1689788639000
    },
    {
        "time": 1689788640000,
        "value": 139.629,
        "_internal_originalTime": 1689788640000
    },
    {
        "time": 1689788642000,
        "value": 139.635,
        "_internal_originalTime": 1689788642000
    },
    {
        "time": 1689788644000,
        "value": 139.632,
        "_internal_originalTime": 1689788644000
    },
    {
        "time": 1689788645000,
        "value": 139.631,
        "_internal_originalTime": 1689788645000
    },
    {
        "time": 1689788646000,
        "value": 139.631,
        "_internal_originalTime": 1689788646000
    },
    {
        "time": 1689788648000,
        "value": 139.628,
        "_internal_originalTime": 1689788648000
    },
    {
        "time": 1689788649000,
        "value": 139.624,
        "_internal_originalTime": 1689788649000
    },
    {
        "time": 1689788650000,
        "value": 139.62,
        "_internal_originalTime": 1689788650000
    },
    {
        "time": 1689788652000,
        "value": 139.618,
        "_internal_originalTime": 1689788652000
    },
    {
        "time": 1689788653000,
        "value": 139.614,
        "_internal_originalTime": 1689788653000
    },
    {
        "time": 1689788655000,
        "value": 139.616,
        "_internal_originalTime": 1689788655000
    },
    {
        "time": 1689788656000,
        "value": 139.612,
        "_internal_originalTime": 1689788656000
    },
    {
        "time": 1689788657000,
        "value": 139.61,
        "_internal_originalTime": 1689788657000
    },
    {
        "time": 1689788658000,
        "value": 139.608,
        "_internal_originalTime": 1689788658000
    },
    {
        "time": 1689788659000,
        "value": 139.611,
        "_internal_originalTime": 1689788659000
    },
    {
        "time": 1689788660000,
        "value": 139.605,
        "_internal_originalTime": 1689788660000
    },
    {
        "time": 1689788662000,
        "value": 139.608,
        "_internal_originalTime": 1689788662000
    },
    {
        "time": 1689788664000,
        "value": 139.607,
        "_internal_originalTime": 1689788664000
    },
    {
        "time": 1689788665000,
        "value": 139.604,
        "_internal_originalTime": 1689788665000
    },
    {
        "time": 1689788666000,
        "value": 139.608,
        "_internal_originalTime": 1689788666000
    },
    {
        "time": 1689788668000,
        "value": 139.61,
        "_internal_originalTime": 1689788668000
    },
    {
        "time": 1689788669000,
        "value": 139.605,
        "_internal_originalTime": 1689788669000
    },
    {
        "time": 1689788670000,
        "value": 139.61,
        "_internal_originalTime": 1689788670000
    },
    {
        "time": 1689788672000,
        "value": 139.605,
        "_internal_originalTime": 1689788672000
    },
    {
        "time": 1689788673000,
        "value": 139.607,
        "_internal_originalTime": 1689788673000
    },
    {
        "time": 1689788674000,
        "value": 139.611,
        "_internal_originalTime": 1689788674000
    },
    {
        "time": 1689788675000,
        "value": 139.612,
        "_internal_originalTime": 1689788675000
    },
    {
        "time": 1689788677000,
        "value": 139.612,
        "_internal_originalTime": 1689788677000
    },
    {
        "time": 1689788678000,
        "value": 139.618,
        "_internal_originalTime": 1689788678000
    },
    {
        "time": 1689788681000,
        "value": 139.608,
        "_internal_originalTime": 1689788681000
    },
    {
        "time": 1689788682000,
        "value": 139.613,
        "_internal_originalTime": 1689788682000
    },
    {
        "time": 1689788683000,
        "value": 139.613,
        "_internal_originalTime": 1689788683000
    },
    {
        "time": 1689788685000,
        "value": 139.612,
        "_internal_originalTime": 1689788685000
    },
    {
        "time": 1689788686000,
        "value": 139.613,
        "_internal_originalTime": 1689788686000
    },
    {
        "time": 1689788687000,
        "value": 139.612,
        "_internal_originalTime": 1689788687000
    },
    {
        "time": 1689788690000,
        "value": 139.607,
        "_internal_originalTime": 1689788690000
    },
    {
        "time": 1689788691000,
        "value": 139.604,
        "_internal_originalTime": 1689788691000
    },
    {
        "time": 1689788692000,
        "value": 139.605,
        "_internal_originalTime": 1689788692000
    },
    {
        "time": 1689788694000,
        "value": 139.603,
        "_internal_originalTime": 1689788694000
    },
    {
        "time": 1689788696000,
        "value": 139.605,
        "_internal_originalTime": 1689788696000
    },
    {
        "time": 1689788697000,
        "value": 139.605,
        "_internal_originalTime": 1689788697000
    },
    {
        "time": 1689788698000,
        "value": 139.604,
        "_internal_originalTime": 1689788698000
    },
    {
        "time": 1689788701000,
        "value": 139.605,
        "_internal_originalTime": 1689788701000
    },
    {
        "time": 1689788703000,
        "value": 139.608,
        "_internal_originalTime": 1689788703000
    },
    {
        "time": 1689788704000,
        "value": 139.602,
        "_internal_originalTime": 1689788704000
    },
    {
        "time": 1689788707000,
        "value": 139.601,
        "_internal_originalTime": 1689788707000
    },
    {
        "time": 1689788708000,
        "value": 139.607,
        "_internal_originalTime": 1689788708000
    },
    {
        "time": 1689788711000,
        "value": 139.602,
        "_internal_originalTime": 1689788711000
    },
    {
        "time": 1689788713000,
        "value": 139.602,
        "_internal_originalTime": 1689788713000
    },
    {
        "time": 1689788714000,
        "value": 139.602,
        "_internal_originalTime": 1689788714000
    },
    {
        "time": 1689788716000,
        "value": 139.6,
        "_internal_originalTime": 1689788716000
    },
    {
        "time": 1689788721000,
        "value": 139.601,
        "_internal_originalTime": 1689788721000
    },
    {
        "time": 1689788725000,
        "value": 139.602,
        "_internal_originalTime": 1689788725000
    },
    {
        "time": 1689788729000,
        "value": 139.601,
        "_internal_originalTime": 1689788729000
    },
    {
        "time": 1689788730000,
        "value": 139.601,
        "_internal_originalTime": 1689788730000
    },
    {
        "time": 1689788732000,
        "value": 139.602,
        "_internal_originalTime": 1689788732000
    },
    {
        "time": 1689788734000,
        "value": 139.606,
        "_internal_originalTime": 1689788734000
    },
    {
        "time": 1689788735000,
        "value": 139.606,
        "_internal_originalTime": 1689788735000
    },
    {
        "time": 1689788737000,
        "value": 139.603,
        "_internal_originalTime": 1689788737000
    },
    {
        "time": 1689788738000,
        "value": 139.601,
        "_internal_originalTime": 1689788738000
    },
    {
        "time": 1689788740000,
        "value": 139.597,
        "_internal_originalTime": 1689788740000
    },
    {
        "time": 1689788742000,
        "value": 139.602,
        "_internal_originalTime": 1689788742000
    },
    {
        "time": 1689788743000,
        "value": 139.596,
        "_internal_originalTime": 1689788743000
    },
    {
        "time": 1689788746000,
        "value": 139.595,
        "_internal_originalTime": 1689788746000
    },
    {
        "time": 1689788748000,
        "value": 139.593,
        "_internal_originalTime": 1689788748000
    },
    {
        "time": 1689788749000,
        "value": 139.59,
        "_internal_originalTime": 1689788749000
    },
    {
        "time": 1689788750000,
        "value": 139.595,
        "_internal_originalTime": 1689788750000
    },
    {
        "time": 1689788754000,
        "value": 139.591,
        "_internal_originalTime": 1689788754000
    },
    {
        "time": 1689788756000,
        "value": 139.594,
        "_internal_originalTime": 1689788756000
    },
    {
        "time": 1689788760000,
        "value": 139.594,
        "_internal_originalTime": 1689788760000
    },
    {
        "time": 1689788761000,
        "value": 139.591,
        "_internal_originalTime": 1689788761000
    },
    {
        "time": 1689788762000,
        "value": 139.601,
        "_internal_originalTime": 1689788762000
    },
    {
        "time": 1689788763000,
        "value": 139.603,
        "_internal_originalTime": 1689788763000
    },
    {
        "time": 1689788764000,
        "value": 139.599,
        "_internal_originalTime": 1689788764000
    },
    {
        "time": 1689788765000,
        "value": 139.604,
        "_internal_originalTime": 1689788765000
    },
    {
        "time": 1689788767000,
        "value": 139.598,
        "_internal_originalTime": 1689788767000
    },
    {
        "time": 1689788768000,
        "value": 139.606,
        "_internal_originalTime": 1689788768000
    },
    {
        "time": 1689788769000,
        "value": 139.604,
        "_internal_originalTime": 1689788769000
    },
    {
        "time": 1689788775000,
        "value": 139.608,
        "_internal_originalTime": 1689788775000
    },
    {
        "time": 1689788776000,
        "value": 139.601,
        "_internal_originalTime": 1689788776000
    },
    {
        "time": 1689788779000,
        "value": 139.608,
        "_internal_originalTime": 1689788779000
    },
    {
        "time": 1689788782000,
        "value": 139.608,
        "_internal_originalTime": 1689788782000
    },
    {
        "time": 1689788783000,
        "value": 139.608,
        "_internal_originalTime": 1689788783000
    },
    {
        "time": 1689788784000,
        "value": 139.608,
        "_internal_originalTime": 1689788784000
    },
    {
        "time": 1689788789000,
        "value": 139.611,
        "_internal_originalTime": 1689788789000
    },
    {
        "time": 1689788790000,
        "value": 139.61,
        "_internal_originalTime": 1689788790000
    },
    {
        "time": 1689788792000,
        "value": 139.609,
        "_internal_originalTime": 1689788792000
    },
    {
        "time": 1689788793000,
        "value": 139.609,
        "_internal_originalTime": 1689788793000
    },
    {
        "time": 1689788795000,
        "value": 139.614,
        "_internal_originalTime": 1689788795000
    },
    {
        "time": 1689788796000,
        "value": 139.614,
        "_internal_originalTime": 1689788796000
    },
    {
        "time": 1689788798000,
        "value": 139.616,
        "_internal_originalTime": 1689788798000
    },
    {
        "time": 1689788802000,
        "value": 139.613,
        "_internal_originalTime": 1689788802000
    },
    {
        "time": 1689788803000,
        "value": 139.614,
        "_internal_originalTime": 1689788803000
    },
    {
        "time": 1689788804000,
        "value": 139.617,
        "_internal_originalTime": 1689788804000
    },
    {
        "time": 1689788805000,
        "value": 139.618,
        "_internal_originalTime": 1689788805000
    },
    {
        "time": 1689788806000,
        "value": 139.612,
        "_internal_originalTime": 1689788806000
    },
    {
        "time": 1689788808000,
        "value": 139.614,
        "_internal_originalTime": 1689788808000
    },
    {
        "time": 1689788810000,
        "value": 139.618,
        "_internal_originalTime": 1689788810000
    },
    {
        "time": 1689788811000,
        "value": 139.618,
        "_internal_originalTime": 1689788811000
    },
    {
        "time": 1689788812000,
        "value": 139.613,
        "_internal_originalTime": 1689788812000
    },
    {
        "time": 1689788814000,
        "value": 139.614,
        "_internal_originalTime": 1689788814000
    },
    {
        "time": 1689788816000,
        "value": 139.615,
        "_internal_originalTime": 1689788816000
    },
    {
        "time": 1689788818000,
        "value": 139.621,
        "_internal_originalTime": 1689788818000
    },
    {
        "time": 1689788820000,
        "value": 139.623,
        "_internal_originalTime": 1689788820000
    },
    {
        "time": 1689788821000,
        "value": 139.623,
        "_internal_originalTime": 1689788821000
    },
    {
        "time": 1689788822000,
        "value": 139.62,
        "_internal_originalTime": 1689788822000
    },
    {
        "time": 1689788824000,
        "value": 139.616,
        "_internal_originalTime": 1689788824000
    },
    {
        "time": 1689788825000,
        "value": 139.621,
        "_internal_originalTime": 1689788825000
    },
    {
        "time": 1689788826000,
        "value": 139.625,
        "_internal_originalTime": 1689788826000
    },
    {
        "time": 1689788827000,
        "value": 139.623,
        "_internal_originalTime": 1689788827000
    },
    {
        "time": 1689788828000,
        "value": 139.621,
        "_internal_originalTime": 1689788828000
    },
    {
        "time": 1689788829000,
        "value": 139.62,
        "_internal_originalTime": 1689788829000
    },
    {
        "time": 1689788830000,
        "value": 139.621,
        "_internal_originalTime": 1689788830000
    },
    {
        "time": 1689788832000,
        "value": 139.618,
        "_internal_originalTime": 1689788832000
    },
    {
        "time": 1689788833000,
        "value": 139.617,
        "_internal_originalTime": 1689788833000
    },
    {
        "time": 1689788834000,
        "value": 139.616,
        "_internal_originalTime": 1689788834000
    },
    {
        "time": 1689788835000,
        "value": 139.617,
        "_internal_originalTime": 1689788835000
    },
    {
        "time": 1689788837000,
        "value": 139.621,
        "_internal_originalTime": 1689788837000
    },
    {
        "time": 1689788839000,
        "value": 139.62,
        "_internal_originalTime": 1689788839000
    },
    {
        "time": 1689788840000,
        "value": 139.621,
        "_internal_originalTime": 1689788840000
    },
    {
        "time": 1689788841000,
        "value": 139.618,
        "_internal_originalTime": 1689788841000
    },
    {
        "time": 1689788842000,
        "value": 139.621,
        "_internal_originalTime": 1689788842000
    },
    {
        "time": 1689788843000,
        "value": 139.617,
        "_internal_originalTime": 1689788843000
    },
    {
        "time": 1689788844000,
        "value": 139.62,
        "_internal_originalTime": 1689788844000
    },
    {
        "time": 1689788846000,
        "value": 139.621,
        "_internal_originalTime": 1689788846000
    },
    {
        "time": 1689788849000,
        "value": 139.62,
        "_internal_originalTime": 1689788849000
    },
    {
        "time": 1689788852000,
        "value": 139.619,
        "_internal_originalTime": 1689788852000
    },
    {
        "time": 1689788854000,
        "value": 139.62,
        "_internal_originalTime": 1689788854000
    },
    {
        "time": 1689788858000,
        "value": 139.619,
        "_internal_originalTime": 1689788858000
    },
    {
        "time": 1689788859000,
        "value": 139.619,
        "_internal_originalTime": 1689788859000
    },
    {
        "time": 1689788860000,
        "value": 139.618,
        "_internal_originalTime": 1689788860000
    },
    {
        "time": 1689788861000,
        "value": 139.618,
        "_internal_originalTime": 1689788861000
    },
    {
        "time": 1689788863000,
        "value": 139.618,
        "_internal_originalTime": 1689788863000
    },
    {
        "time": 1689788864000,
        "value": 139.615,
        "_internal_originalTime": 1689788864000
    },
    {
        "time": 1689788865000,
        "value": 139.619,
        "_internal_originalTime": 1689788865000
    },
    {
        "time": 1689788866000,
        "value": 139.614,
        "_internal_originalTime": 1689788866000
    },
    {
        "time": 1689788871000,
        "value": 139.618,
        "_internal_originalTime": 1689788871000
    },
    {
        "time": 1689788873000,
        "value": 139.618,
        "_internal_originalTime": 1689788873000
    },
    {
        "time": 1689788874000,
        "value": 139.619,
        "_internal_originalTime": 1689788874000
    },
    {
        "time": 1689788875000,
        "value": 139.616,
        "_internal_originalTime": 1689788875000
    },
    {
        "time": 1689788877000,
        "value": 139.618,
        "_internal_originalTime": 1689788877000
    },
    {
        "time": 1689788878000,
        "value": 139.617,
        "_internal_originalTime": 1689788878000
    },
    {
        "time": 1689788879000,
        "value": 139.614,
        "_internal_originalTime": 1689788879000
    },
    {
        "time": 1689788880000,
        "value": 139.618,
        "_internal_originalTime": 1689788880000
    },
    {
        "time": 1689788882000,
        "value": 139.611,
        "_internal_originalTime": 1689788882000
    },
    {
        "time": 1689788883000,
        "value": 139.618,
        "_internal_originalTime": 1689788883000
    },
    {
        "time": 1689788884000,
        "value": 139.615,
        "_internal_originalTime": 1689788884000
    },
    {
        "time": 1689788885000,
        "value": 139.614,
        "_internal_originalTime": 1689788885000
    },
    {
        "time": 1689788886000,
        "value": 139.614,
        "_internal_originalTime": 1689788886000
    },
    {
        "time": 1689788888000,
        "value": 139.619,
        "_internal_originalTime": 1689788888000
    },
    {
        "time": 1689788889000,
        "value": 139.621,
        "_internal_originalTime": 1689788889000
    },
    {
        "time": 1689788890000,
        "value": 139.621,
        "_internal_originalTime": 1689788890000
    },
    {
        "time": 1689788894000,
        "value": 139.621,
        "_internal_originalTime": 1689788894000
    },
    {
        "time": 1689788895000,
        "value": 139.617,
        "_internal_originalTime": 1689788895000
    },
    {
        "time": 1689788897000,
        "value": 139.621,
        "_internal_originalTime": 1689788897000
    },
    {
        "time": 1689788898000,
        "value": 139.623,
        "_internal_originalTime": 1689788898000
    },
    {
        "time": 1689788899000,
        "value": 139.62,
        "_internal_originalTime": 1689788899000
    },
    {
        "time": 1689788900000,
        "value": 139.621,
        "_internal_originalTime": 1689788900000
    },
    {
        "time": 1689788901000,
        "value": 139.623,
        "_internal_originalTime": 1689788901000
    },
    {
        "time": 1689788902000,
        "value": 139.617,
        "_internal_originalTime": 1689788902000
    },
    {
        "time": 1689788904000,
        "value": 139.619,
        "_internal_originalTime": 1689788904000
    },
    {
        "time": 1689788905000,
        "value": 139.617,
        "_internal_originalTime": 1689788905000
    },
    {
        "time": 1689788906000,
        "value": 139.617,
        "_internal_originalTime": 1689788906000
    },
    {
        "time": 1689788908000,
        "value": 139.622,
        "_internal_originalTime": 1689788908000
    },
    {
        "time": 1689788909000,
        "value": 139.619,
        "_internal_originalTime": 1689788909000
    },
    {
        "time": 1689788911000,
        "value": 139.619,
        "_internal_originalTime": 1689788911000
    },
    {
        "time": 1689788912000,
        "value": 139.623,
        "_internal_originalTime": 1689788912000
    },
    {
        "time": 1689788913000,
        "value": 139.622,
        "_internal_originalTime": 1689788913000
    },
    {
        "time": 1689788914000,
        "value": 139.619,
        "_internal_originalTime": 1689788914000
    },
    {
        "time": 1689788916000,
        "value": 139.622,
        "_internal_originalTime": 1689788916000
    },
    {
        "time": 1689788917000,
        "value": 139.625,
        "_internal_originalTime": 1689788917000
    },
    {
        "time": 1689788919000,
        "value": 139.624,
        "_internal_originalTime": 1689788919000
    },
    {
        "time": 1689788920000,
        "value": 139.628,
        "_internal_originalTime": 1689788920000
    },
    {
        "time": 1689788923000,
        "value": 139.628,
        "_internal_originalTime": 1689788923000
    },
    {
        "time": 1689788925000,
        "value": 139.63,
        "_internal_originalTime": 1689788925000
    },
    {
        "time": 1689788928000,
        "value": 139.627,
        "_internal_originalTime": 1689788928000
    },
    {
        "time": 1689788934000,
        "value": 139.628,
        "_internal_originalTime": 1689788934000
    },
    {
        "time": 1689788938000,
        "value": 139.623,
        "_internal_originalTime": 1689788938000
    },
    {
        "time": 1689788940000,
        "value": 139.625,
        "_internal_originalTime": 1689788940000
    },
    {
        "time": 1689788941000,
        "value": 139.628,
        "_internal_originalTime": 1689788941000
    },
    {
        "time": 1689788942000,
        "value": 139.627,
        "_internal_originalTime": 1689788942000
    },
    {
        "time": 1689788946000,
        "value": 139.627,
        "_internal_originalTime": 1689788946000
    },
    {
        "time": 1689788948000,
        "value": 139.626,
        "_internal_originalTime": 1689788948000
    },
    {
        "time": 1689788949000,
        "value": 139.627,
        "_internal_originalTime": 1689788949000
    },
    {
        "time": 1689788950000,
        "value": 139.623,
        "_internal_originalTime": 1689788950000
    },
    {
        "time": 1689788952000,
        "value": 139.628,
        "_internal_originalTime": 1689788952000
    },
    {
        "time": 1689788953000,
        "value": 139.628,
        "_internal_originalTime": 1689788953000
    },
    {
        "time": 1689788956000,
        "value": 139.622,
        "_internal_originalTime": 1689788956000
    },
    {
        "time": 1689788957000,
        "value": 139.628,
        "_internal_originalTime": 1689788957000
    },
    {
        "time": 1689788958000,
        "value": 139.628,
        "_internal_originalTime": 1689788958000
    },
    {
        "time": 1689788960000,
        "value": 139.628,
        "_internal_originalTime": 1689788960000
    },
    {
        "time": 1689788961000,
        "value": 139.628,
        "_internal_originalTime": 1689788961000
    },
    {
        "time": 1689788962000,
        "value": 139.628,
        "_internal_originalTime": 1689788962000
    },
    {
        "time": 1689788963000,
        "value": 139.628,
        "_internal_originalTime": 1689788963000
    },
    {
        "time": 1689788965000,
        "value": 139.628,
        "_internal_originalTime": 1689788965000
    },
    {
        "time": 1689788966000,
        "value": 139.628,
        "_internal_originalTime": 1689788966000
    },
    {
        "time": 1689788968000,
        "value": 139.628,
        "_internal_originalTime": 1689788968000
    },
    {
        "time": 1689788969000,
        "value": 139.623,
        "_internal_originalTime": 1689788969000
    },
    {
        "time": 1689788970000,
        "value": 139.628,
        "_internal_originalTime": 1689788970000
    },
    {
        "time": 1689788971000,
        "value": 139.628,
        "_internal_originalTime": 1689788971000
    },
    {
        "time": 1689788973000,
        "value": 139.628,
        "_internal_originalTime": 1689788973000
    },
    {
        "time": 1689788974000,
        "value": 139.628,
        "_internal_originalTime": 1689788974000
    },
    {
        "time": 1689788978000,
        "value": 139.626,
        "_internal_originalTime": 1689788978000
    },
    {
        "time": 1689788982000,
        "value": 139.628,
        "_internal_originalTime": 1689788982000
    },
    {
        "time": 1689788983000,
        "value": 139.623,
        "_internal_originalTime": 1689788983000
    },
    {
        "time": 1689788985000,
        "value": 139.622,
        "_internal_originalTime": 1689788985000
    },
    {
        "time": 1689788986000,
        "value": 139.615,
        "_internal_originalTime": 1689788986000
    },
    {
        "time": 1689788987000,
        "value": 139.617,
        "_internal_originalTime": 1689788987000
    },
    {
        "time": 1689788988000,
        "value": 139.615,
        "_internal_originalTime": 1689788988000
    },
    {
        "time": 1689788990000,
        "value": 139.61,
        "_internal_originalTime": 1689788990000
    },
    {
        "time": 1689788992000,
        "value": 139.616,
        "_internal_originalTime": 1689788992000
    },
    {
        "time": 1689788993000,
        "value": 139.61,
        "_internal_originalTime": 1689788993000
    },
    {
        "time": 1689788994000,
        "value": 139.611,
        "_internal_originalTime": 1689788994000
    },
    {
        "time": 1689788996000,
        "value": 139.616,
        "_internal_originalTime": 1689788996000
    },
    {
        "time": 1689788998000,
        "value": 139.616,
        "_internal_originalTime": 1689788998000
    },
    {
        "time": 1689789000000,
        "value": 139.616,
        "_internal_originalTime": 1689789000000
    },
    {
        "time": 1689789001000,
        "value": 139.614,
        "_internal_originalTime": 1689789001000
    },
    {
        "time": 1689789002000,
        "value": 139.612,
        "_internal_originalTime": 1689789002000
    },
    {
        "time": 1689789004000,
        "value": 139.61,
        "_internal_originalTime": 1689789004000
    },
    {
        "time": 1689789005000,
        "value": 139.602,
        "_internal_originalTime": 1689789005000
    },
    {
        "time": 1689789006000,
        "value": 139.609,
        "_internal_originalTime": 1689789006000
    },
    {
        "time": 1689789007000,
        "value": 139.609,
        "_internal_originalTime": 1689789007000
    },
    {
        "time": 1689789008000,
        "value": 139.609,
        "_internal_originalTime": 1689789008000
    },
    {
        "time": 1689789009000,
        "value": 139.609,
        "_internal_originalTime": 1689789009000
    },
    {
        "time": 1689789011000,
        "value": 139.609,
        "_internal_originalTime": 1689789011000
    },
    {
        "time": 1689789013000,
        "value": 139.609,
        "_internal_originalTime": 1689789013000
    },
    {
        "time": 1689789014000,
        "value": 139.605,
        "_internal_originalTime": 1689789014000
    },
    {
        "time": 1689789017000,
        "value": 139.602,
        "_internal_originalTime": 1689789017000
    },
    {
        "time": 1689789019000,
        "value": 139.604,
        "_internal_originalTime": 1689789019000
    },
    {
        "time": 1689789020000,
        "value": 139.605,
        "_internal_originalTime": 1689789020000
    },
    {
        "time": 1689789022000,
        "value": 139.611,
        "_internal_originalTime": 1689789022000
    },
    {
        "time": 1689789023000,
        "value": 139.613,
        "_internal_originalTime": 1689789023000
    },
    {
        "time": 1689789024000,
        "value": 139.609,
        "_internal_originalTime": 1689789024000
    },
    {
        "time": 1689789026000,
        "value": 139.613,
        "_internal_originalTime": 1689789026000
    },
    {
        "time": 1689789028000,
        "value": 139.616,
        "_internal_originalTime": 1689789028000
    },
    {
        "time": 1689789029000,
        "value": 139.615,
        "_internal_originalTime": 1689789029000
    },
    {
        "time": 1689789031000,
        "value": 139.616,
        "_internal_originalTime": 1689789031000
    },
    {
        "time": 1689789032000,
        "value": 139.617,
        "_internal_originalTime": 1689789032000
    },
    {
        "time": 1689789033000,
        "value": 139.619,
        "_internal_originalTime": 1689789033000
    },
    {
        "time": 1689789034000,
        "value": 139.619,
        "_internal_originalTime": 1689789034000
    },
    {
        "time": 1689789036000,
        "value": 139.619,
        "_internal_originalTime": 1689789036000
    },
    {
        "time": 1689789038000,
        "value": 139.617,
        "_internal_originalTime": 1689789038000
    },
    {
        "time": 1689789039000,
        "value": 139.619,
        "_internal_originalTime": 1689789039000
    },
    {
        "time": 1689789041000,
        "value": 139.619,
        "_internal_originalTime": 1689789041000
    },
    {
        "time": 1689789044000,
        "value": 139.619,
        "_internal_originalTime": 1689789044000
    },
    {
        "time": 1689789045000,
        "value": 139.612,
        "_internal_originalTime": 1689789045000
    },
    {
        "time": 1689789047000,
        "value": 139.603,
        "_internal_originalTime": 1689789047000
    },
    {
        "time": 1689789048000,
        "value": 139.607,
        "_internal_originalTime": 1689789048000
    },
    {
        "time": 1689789050000,
        "value": 139.609,
        "_internal_originalTime": 1689789050000
    },
    {
        "time": 1689789053000,
        "value": 139.609,
        "_internal_originalTime": 1689789053000
    },
    {
        "time": 1689789060000,
        "value": 139.604,
        "_internal_originalTime": 1689789060000
    },
    {
        "time": 1689789061000,
        "value": 139.607,
        "_internal_originalTime": 1689789061000
    },
    {
        "time": 1689789062000,
        "value": 139.606,
        "_internal_originalTime": 1689789062000
    },
    {
        "time": 1689789064000,
        "value": 139.604,
        "_internal_originalTime": 1689789064000
    },
    {
        "time": 1689789065000,
        "value": 139.604,
        "_internal_originalTime": 1689789065000
    },
    {
        "time": 1689789066000,
        "value": 139.606,
        "_internal_originalTime": 1689789066000
    },
    {
        "time": 1689789067000,
        "value": 139.608,
        "_internal_originalTime": 1689789067000
    },
    {
        "time": 1689789068000,
        "value": 139.609,
        "_internal_originalTime": 1689789068000
    },
    {
        "time": 1689789069000,
        "value": 139.611,
        "_internal_originalTime": 1689789069000
    },
    {
        "time": 1689789071000,
        "value": 139.608,
        "_internal_originalTime": 1689789071000
    },
    {
        "time": 1689789072000,
        "value": 139.607,
        "_internal_originalTime": 1689789072000
    },
    {
        "time": 1689789073000,
        "value": 139.611,
        "_internal_originalTime": 1689789073000
    },
    {
        "time": 1689789074000,
        "value": 139.611,
        "_internal_originalTime": 1689789074000
    },
    {
        "time": 1689789077000,
        "value": 139.606,
        "_internal_originalTime": 1689789077000
    },
    {
        "time": 1689789078000,
        "value": 139.612,
        "_internal_originalTime": 1689789078000
    },
    {
        "time": 1689789079000,
        "value": 139.615,
        "_internal_originalTime": 1689789079000
    },
    {
        "time": 1689789080000,
        "value": 139.615,
        "_internal_originalTime": 1689789080000
    },
    {
        "time": 1689789082000,
        "value": 139.61,
        "_internal_originalTime": 1689789082000
    },
    {
        "time": 1689789083000,
        "value": 139.608,
        "_internal_originalTime": 1689789083000
    },
    {
        "time": 1689789087000,
        "value": 139.604,
        "_internal_originalTime": 1689789087000
    },
    {
        "time": 1689789088000,
        "value": 139.605,
        "_internal_originalTime": 1689789088000
    },
    {
        "time": 1689789089000,
        "value": 139.608,
        "_internal_originalTime": 1689789089000
    },
    {
        "time": 1689789090000,
        "value": 139.6,
        "_internal_originalTime": 1689789090000
    },
    {
        "time": 1689789091000,
        "value": 139.6,
        "_internal_originalTime": 1689789091000
    },
    {
        "time": 1689789094000,
        "value": 139.599,
        "_internal_originalTime": 1689789094000
    },
    {
        "time": 1689789095000,
        "value": 139.598,
        "_internal_originalTime": 1689789095000
    },
    {
        "time": 1689789096000,
        "value": 139.6,
        "_internal_originalTime": 1689789096000
    },
    {
        "time": 1689789098000,
        "value": 139.602,
        "_internal_originalTime": 1689789098000
    },
    {
        "time": 1689789106000,
        "value": 139.6,
        "_internal_originalTime": 1689789106000
    },
    {
        "time": 1689789107000,
        "value": 139.597,
        "_internal_originalTime": 1689789107000
    },
    {
        "time": 1689789108000,
        "value": 139.603,
        "_internal_originalTime": 1689789108000
    },
    {
        "time": 1689789110000,
        "value": 139.605,
        "_internal_originalTime": 1689789110000
    },
    {
        "time": 1689789111000,
        "value": 139.603,
        "_internal_originalTime": 1689789111000
    },
    {
        "time": 1689789112000,
        "value": 139.6,
        "_internal_originalTime": 1689789112000
    },
    {
        "time": 1689789113000,
        "value": 139.6,
        "_internal_originalTime": 1689789113000
    },
    {
        "time": 1689789116000,
        "value": 139.602,
        "_internal_originalTime": 1689789116000
    },
    {
        "time": 1689789118000,
        "value": 139.607,
        "_internal_originalTime": 1689789118000
    },
    {
        "time": 1689789119000,
        "value": 139.607,
        "_internal_originalTime": 1689789119000
    },
    {
        "time": 1689789121000,
        "value": 139.602,
        "_internal_originalTime": 1689789121000
    },
    {
        "time": 1689789122000,
        "value": 139.606,
        "_internal_originalTime": 1689789122000
    },
    {
        "time": 1689789123000,
        "value": 139.606,
        "_internal_originalTime": 1689789123000
    },
    {
        "time": 1689789124000,
        "value": 139.603,
        "_internal_originalTime": 1689789124000
    },
    {
        "time": 1689789126000,
        "value": 139.602,
        "_internal_originalTime": 1689789126000
    },
    {
        "time": 1689789128000,
        "value": 139.605,
        "_internal_originalTime": 1689789128000
    },
    {
        "time": 1689789132000,
        "value": 139.604,
        "_internal_originalTime": 1689789132000
    },
    {
        "time": 1689789133000,
        "value": 139.61,
        "_internal_originalTime": 1689789133000
    },
    {
        "time": 1689789134000,
        "value": 139.61,
        "_internal_originalTime": 1689789134000
    },
    {
        "time": 1689789137000,
        "value": 139.606,
        "_internal_originalTime": 1689789137000
    },
    {
        "time": 1689789138000,
        "value": 139.608,
        "_internal_originalTime": 1689789138000
    },
    {
        "time": 1689789140000,
        "value": 139.61,
        "_internal_originalTime": 1689789140000
    },
    {
        "time": 1689789141000,
        "value": 139.61,
        "_internal_originalTime": 1689789141000
    },
    {
        "time": 1689789144000,
        "value": 139.611,
        "_internal_originalTime": 1689789144000
    },
    {
        "time": 1689789146000,
        "value": 139.615,
        "_internal_originalTime": 1689789146000
    },
    {
        "time": 1689789147000,
        "value": 139.611,
        "_internal_originalTime": 1689789147000
    },
    {
        "time": 1689789148000,
        "value": 139.613,
        "_internal_originalTime": 1689789148000
    },
    {
        "time": 1689789149000,
        "value": 139.613,
        "_internal_originalTime": 1689789149000
    },
    {
        "time": 1689789150000,
        "value": 139.612,
        "_internal_originalTime": 1689789150000
    },
    {
        "time": 1689789151000,
        "value": 139.613,
        "_internal_originalTime": 1689789151000
    },
    {
        "time": 1689789153000,
        "value": 139.612,
        "_internal_originalTime": 1689789153000
    },
    {
        "time": 1689789154000,
        "value": 139.612,
        "_internal_originalTime": 1689789154000
    },
    {
        "time": 1689789158000,
        "value": 139.611,
        "_internal_originalTime": 1689789158000
    },
    {
        "time": 1689789159000,
        "value": 139.612,
        "_internal_originalTime": 1689789159000
    },
    {
        "time": 1689789160000,
        "value": 139.607,
        "_internal_originalTime": 1689789160000
    },
    {
        "time": 1689789161000,
        "value": 139.608,
        "_internal_originalTime": 1689789161000
    },
    {
        "time": 1689789164000,
        "value": 139.609,
        "_internal_originalTime": 1689789164000
    },
    {
        "time": 1689789166000,
        "value": 139.612,
        "_internal_originalTime": 1689789166000
    },
    {
        "time": 1689789167000,
        "value": 139.613,
        "_internal_originalTime": 1689789167000
    },
    {
        "time": 1689789168000,
        "value": 139.616,
        "_internal_originalTime": 1689789168000
    },
    {
        "time": 1689789169000,
        "value": 139.612,
        "_internal_originalTime": 1689789169000
    },
    {
        "time": 1689789170000,
        "value": 139.613,
        "_internal_originalTime": 1689789170000
    },
    {
        "time": 1689789172000,
        "value": 139.611,
        "_internal_originalTime": 1689789172000
    },
    {
        "time": 1689789173000,
        "value": 139.611,
        "_internal_originalTime": 1689789173000
    },
    {
        "time": 1689789175000,
        "value": 139.608,
        "_internal_originalTime": 1689789175000
    },
    {
        "time": 1689789176000,
        "value": 139.607,
        "_internal_originalTime": 1689789176000
    },
    {
        "time": 1689789177000,
        "value": 139.608,
        "_internal_originalTime": 1689789177000
    },
    {
        "time": 1689789180000,
        "value": 139.607,
        "_internal_originalTime": 1689789180000
    },
    {
        "time": 1689789182000,
        "value": 139.609,
        "_internal_originalTime": 1689789182000
    },
    {
        "time": 1689789183000,
        "value": 139.609,
        "_internal_originalTime": 1689789183000
    },
    {
        "time": 1689789186000,
        "value": 139.609,
        "_internal_originalTime": 1689789186000
    },
    {
        "time": 1689789188000,
        "value": 139.608,
        "_internal_originalTime": 1689789188000
    },
    {
        "time": 1689789190000,
        "value": 139.606,
        "_internal_originalTime": 1689789190000
    },
    {
        "time": 1689789192000,
        "value": 139.608,
        "_internal_originalTime": 1689789192000
    },
    {
        "time": 1689789194000,
        "value": 139.607,
        "_internal_originalTime": 1689789194000
    },
    {
        "time": 1689789195000,
        "value": 139.608,
        "_internal_originalTime": 1689789195000
    },
    {
        "time": 1689789196000,
        "value": 139.604,
        "_internal_originalTime": 1689789196000
    },
    {
        "time": 1689789197000,
        "value": 139.604,
        "_internal_originalTime": 1689789197000
    },
    {
        "time": 1689789199000,
        "value": 139.603,
        "_internal_originalTime": 1689789199000
    },
    {
        "time": 1689789200000,
        "value": 139.6,
        "_internal_originalTime": 1689789200000
    },
    {
        "time": 1689789202000,
        "value": 139.6,
        "_internal_originalTime": 1689789202000
    },
    {
        "time": 1689789203000,
        "value": 139.6,
        "_internal_originalTime": 1689789203000
    },
    {
        "time": 1689789204000,
        "value": 139.599,
        "_internal_originalTime": 1689789204000
    },
    {
        "time": 1689789206000,
        "value": 139.6,
        "_internal_originalTime": 1689789206000
    },
    {
        "time": 1689789215000,
        "value": 139.603,
        "_internal_originalTime": 1689789215000
    },
    {
        "time": 1689789216000,
        "value": 139.603,
        "_internal_originalTime": 1689789216000
    },
    {
        "time": 1689789218000,
        "value": 139.599,
        "_internal_originalTime": 1689789218000
    },
    {
        "time": 1689789220000,
        "value": 139.602,
        "_internal_originalTime": 1689789220000
    },
    {
        "time": 1689789222000,
        "value": 139.606,
        "_internal_originalTime": 1689789222000
    },
    {
        "time": 1689789225000,
        "value": 139.604,
        "_internal_originalTime": 1689789225000
    },
    {
        "time": 1689789227000,
        "value": 139.6,
        "_internal_originalTime": 1689789227000
    },
    {
        "time": 1689789228000,
        "value": 139.603,
        "_internal_originalTime": 1689789228000
    },
    {
        "time": 1689789230000,
        "value": 139.603,
        "_internal_originalTime": 1689789230000
    },
    {
        "time": 1689789231000,
        "value": 139.599,
        "_internal_originalTime": 1689789231000
    },
    {
        "time": 1689789234000,
        "value": 139.604,
        "_internal_originalTime": 1689789234000
    },
    {
        "time": 1689789235000,
        "value": 139.604,
        "_internal_originalTime": 1689789235000
    },
    {
        "time": 1689789237000,
        "value": 139.603,
        "_internal_originalTime": 1689789237000
    },
    {
        "time": 1689789238000,
        "value": 139.599,
        "_internal_originalTime": 1689789238000
    },
    {
        "time": 1689789240000,
        "value": 139.598,
        "_internal_originalTime": 1689789240000
    },
    {
        "time": 1689789241000,
        "value": 139.599,
        "_internal_originalTime": 1689789241000
    },
    {
        "time": 1689789243000,
        "value": 139.602,
        "_internal_originalTime": 1689789243000
    },
    {
        "time": 1689789244000,
        "value": 139.602,
        "_internal_originalTime": 1689789244000
    },
    {
        "time": 1689789248000,
        "value": 139.603,
        "_internal_originalTime": 1689789248000
    },
    {
        "time": 1689789252000,
        "value": 139.604,
        "_internal_originalTime": 1689789252000
    },
    {
        "time": 1689789253000,
        "value": 139.604,
        "_internal_originalTime": 1689789253000
    },
    {
        "time": 1689789255000,
        "value": 139.605,
        "_internal_originalTime": 1689789255000
    },
    {
        "time": 1689789256000,
        "value": 139.605,
        "_internal_originalTime": 1689789256000
    },
    {
        "time": 1689789258000,
        "value": 139.603,
        "_internal_originalTime": 1689789258000
    },
    {
        "time": 1689789259000,
        "value": 139.601,
        "_internal_originalTime": 1689789259000
    },
    {
        "time": 1689789260000,
        "value": 139.602,
        "_internal_originalTime": 1689789260000
    },
    {
        "time": 1689789261000,
        "value": 139.603,
        "_internal_originalTime": 1689789261000
    },
    {
        "time": 1689789262000,
        "value": 139.603,
        "_internal_originalTime": 1689789262000
    },
    {
        "time": 1689789263000,
        "value": 139.602,
        "_internal_originalTime": 1689789263000
    },
    {
        "time": 1689789264000,
        "value": 139.599,
        "_internal_originalTime": 1689789264000
    },
    {
        "time": 1689789266000,
        "value": 139.606,
        "_internal_originalTime": 1689789266000
    },
    {
        "time": 1689789267000,
        "value": 139.605,
        "_internal_originalTime": 1689789267000
    },
    {
        "time": 1689789269000,
        "value": 139.609,
        "_internal_originalTime": 1689789269000
    },
    {
        "time": 1689789270000,
        "value": 139.607,
        "_internal_originalTime": 1689789270000
    },
    {
        "time": 1689789272000,
        "value": 139.605,
        "_internal_originalTime": 1689789272000
    },
    {
        "time": 1689789274000,
        "value": 139.604,
        "_internal_originalTime": 1689789274000
    },
    {
        "time": 1689789278000,
        "value": 139.608,
        "_internal_originalTime": 1689789278000
    },
    {
        "time": 1689789279000,
        "value": 139.61,
        "_internal_originalTime": 1689789279000
    },
    {
        "time": 1689789283000,
        "value": 139.612,
        "_internal_originalTime": 1689789283000
    },
    {
        "time": 1689789284000,
        "value": 139.611,
        "_internal_originalTime": 1689789284000
    },
    {
        "time": 1689789285000,
        "value": 139.609,
        "_internal_originalTime": 1689789285000
    },
    {
        "time": 1689789287000,
        "value": 139.605,
        "_internal_originalTime": 1689789287000
    },
    {
        "time": 1689789288000,
        "value": 139.609,
        "_internal_originalTime": 1689789288000
    },
    {
        "time": 1689789289000,
        "value": 139.608,
        "_internal_originalTime": 1689789289000
    },
    {
        "time": 1689789290000,
        "value": 139.609,
        "_internal_originalTime": 1689789290000
    },
    {
        "time": 1689789292000,
        "value": 139.615,
        "_internal_originalTime": 1689789292000
    },
    {
        "time": 1689789293000,
        "value": 139.614,
        "_internal_originalTime": 1689789293000
    },
    {
        "time": 1689789294000,
        "value": 139.62,
        "_internal_originalTime": 1689789294000
    },
    {
        "time": 1689789296000,
        "value": 139.619,
        "_internal_originalTime": 1689789296000
    },
    {
        "time": 1689789298000,
        "value": 139.616,
        "_internal_originalTime": 1689789298000
    },
    {
        "time": 1689789300000,
        "value": 139.62,
        "_internal_originalTime": 1689789300000
    },
    {
        "time": 1689789301000,
        "value": 139.617,
        "_internal_originalTime": 1689789301000
    },
    {
        "time": 1689789302000,
        "value": 139.618,
        "_internal_originalTime": 1689789302000
    },
    {
        "time": 1689789303000,
        "value": 139.619,
        "_internal_originalTime": 1689789303000
    },
    {
        "time": 1689789304000,
        "value": 139.62,
        "_internal_originalTime": 1689789304000
    },
    {
        "time": 1689789305000,
        "value": 139.617,
        "_internal_originalTime": 1689789305000
    },
    {
        "time": 1689789308000,
        "value": 139.62,
        "_internal_originalTime": 1689789308000
    },
    {
        "time": 1689789310000,
        "value": 139.619,
        "_internal_originalTime": 1689789310000
    },
    {
        "time": 1689789311000,
        "value": 139.618,
        "_internal_originalTime": 1689789311000
    },
    {
        "time": 1689789312000,
        "value": 139.616,
        "_internal_originalTime": 1689789312000
    },
    {
        "time": 1689789313000,
        "value": 139.617,
        "_internal_originalTime": 1689789313000
    },
    {
        "time": 1689789314000,
        "value": 139.616,
        "_internal_originalTime": 1689789314000
    },
    {
        "time": 1689789315000,
        "value": 139.62,
        "_internal_originalTime": 1689789315000
    },
    {
        "time": 1689789317000,
        "value": 139.616,
        "_internal_originalTime": 1689789317000
    },
    {
        "time": 1689789318000,
        "value": 139.617,
        "_internal_originalTime": 1689789318000
    },
    {
        "time": 1689789319000,
        "value": 139.616,
        "_internal_originalTime": 1689789319000
    },
    {
        "time": 1689789320000,
        "value": 139.617,
        "_internal_originalTime": 1689789320000
    },
    {
        "time": 1689789322000,
        "value": 139.616,
        "_internal_originalTime": 1689789322000
    },
    {
        "time": 1689789323000,
        "value": 139.616,
        "_internal_originalTime": 1689789323000
    },
    {
        "time": 1689789325000,
        "value": 139.616,
        "_internal_originalTime": 1689789325000
    },
    {
        "time": 1689789326000,
        "value": 139.616,
        "_internal_originalTime": 1689789326000
    },
    {
        "time": 1689789327000,
        "value": 139.619,
        "_internal_originalTime": 1689789327000
    },
    {
        "time": 1689789329000,
        "value": 139.616,
        "_internal_originalTime": 1689789329000
    },
    {
        "time": 1689789330000,
        "value": 139.616,
        "_internal_originalTime": 1689789330000
    },
    {
        "time": 1689789332000,
        "value": 139.616,
        "_internal_originalTime": 1689789332000
    },
    {
        "time": 1689789333000,
        "value": 139.618,
        "_internal_originalTime": 1689789333000
    },
    {
        "time": 1689789335000,
        "value": 139.612,
        "_internal_originalTime": 1689789335000
    },
    {
        "time": 1689789337000,
        "value": 139.609,
        "_internal_originalTime": 1689789337000
    },
    {
        "time": 1689789338000,
        "value": 139.611,
        "_internal_originalTime": 1689789338000
    },
    {
        "time": 1689789339000,
        "value": 139.609,
        "_internal_originalTime": 1689789339000
    },
    {
        "time": 1689789345000,
        "value": 139.612,
        "_internal_originalTime": 1689789345000
    },
    {
        "time": 1689789347000,
        "value": 139.605,
        "_internal_originalTime": 1689789347000
    },
    {
        "time": 1689789348000,
        "value": 139.608,
        "_internal_originalTime": 1689789348000
    },
    {
        "time": 1689789349000,
        "value": 139.609,
        "_internal_originalTime": 1689789349000
    },
    {
        "time": 1689789357000,
        "value": 139.606,
        "_internal_originalTime": 1689789357000
    },
    {
        "time": 1689789358000,
        "value": 139.607,
        "_internal_originalTime": 1689789358000
    },
    {
        "time": 1689789360000,
        "value": 139.611,
        "_internal_originalTime": 1689789360000
    },
    {
        "time": 1689789361000,
        "value": 139.609,
        "_internal_originalTime": 1689789361000
    },
    {
        "time": 1689789362000,
        "value": 139.609,
        "_internal_originalTime": 1689789362000
    },
    {
        "time": 1689789364000,
        "value": 139.604,
        "_internal_originalTime": 1689789364000
    },
    {
        "time": 1689789365000,
        "value": 139.605,
        "_internal_originalTime": 1689789365000
    },
    {
        "time": 1689789368000,
        "value": 139.604,
        "_internal_originalTime": 1689789368000
    },
    {
        "time": 1689789369000,
        "value": 139.603,
        "_internal_originalTime": 1689789369000
    },
    {
        "time": 1689789370000,
        "value": 139.602,
        "_internal_originalTime": 1689789370000
    },
    {
        "time": 1689789371000,
        "value": 139.602,
        "_internal_originalTime": 1689789371000
    },
    {
        "time": 1689789377000,
        "value": 139.597,
        "_internal_originalTime": 1689789377000
    },
    {
        "time": 1689789382000,
        "value": 139.6,
        "_internal_originalTime": 1689789382000
    },
    {
        "time": 1689789383000,
        "value": 139.61,
        "_internal_originalTime": 1689789383000
    },
    {
        "time": 1689789385000,
        "value": 139.606,
        "_internal_originalTime": 1689789385000
    },
    {
        "time": 1689789388000,
        "value": 139.606,
        "_internal_originalTime": 1689789388000
    },
    {
        "time": 1689789389000,
        "value": 139.61,
        "_internal_originalTime": 1689789389000
    },
    {
        "time": 1689789390000,
        "value": 139.609,
        "_internal_originalTime": 1689789390000
    },
    {
        "time": 1689789392000,
        "value": 139.606,
        "_internal_originalTime": 1689789392000
    },
    {
        "time": 1689789393000,
        "value": 139.606,
        "_internal_originalTime": 1689789393000
    },
    {
        "time": 1689789398000,
        "value": 139.607,
        "_internal_originalTime": 1689789398000
    },
    {
        "time": 1689789399000,
        "value": 139.607,
        "_internal_originalTime": 1689789399000
    },
    {
        "time": 1689789402000,
        "value": 139.607,
        "_internal_originalTime": 1689789402000
    },
    {
        "time": 1689789406000,
        "value": 139.607,
        "_internal_originalTime": 1689789406000
    },
    {
        "time": 1689789407000,
        "value": 139.604,
        "_internal_originalTime": 1689789407000
    },
    {
        "time": 1689789408000,
        "value": 139.607,
        "_internal_originalTime": 1689789408000
    },
    {
        "time": 1689789412000,
        "value": 139.607,
        "_internal_originalTime": 1689789412000
    },
    {
        "time": 1689789413000,
        "value": 139.608,
        "_internal_originalTime": 1689789413000
    },
    {
        "time": 1689789415000,
        "value": 139.611,
        "_internal_originalTime": 1689789415000
    },
    {
        "time": 1689789416000,
        "value": 139.607,
        "_internal_originalTime": 1689789416000
    },
    {
        "time": 1689789417000,
        "value": 139.609,
        "_internal_originalTime": 1689789417000
    },
    {
        "time": 1689789418000,
        "value": 139.61,
        "_internal_originalTime": 1689789418000
    },
    {
        "time": 1689789420000,
        "value": 139.612,
        "_internal_originalTime": 1689789420000
    },
    {
        "time": 1689789421000,
        "value": 139.607,
        "_internal_originalTime": 1689789421000
    },
    {
        "time": 1689789424000,
        "value": 139.608,
        "_internal_originalTime": 1689789424000
    },
    {
        "time": 1689789427000,
        "value": 139.609,
        "_internal_originalTime": 1689789427000
    },
    {
        "time": 1689789428000,
        "value": 139.61,
        "_internal_originalTime": 1689789428000
    },
    {
        "time": 1689789430000,
        "value": 139.607,
        "_internal_originalTime": 1689789430000
    },
    {
        "time": 1689789435000,
        "value": 139.609,
        "_internal_originalTime": 1689789435000
    },
    {
        "time": 1689789436000,
        "value": 139.608,
        "_internal_originalTime": 1689789436000
    },
    {
        "time": 1689789437000,
        "value": 139.607,
        "_internal_originalTime": 1689789437000
    },
    {
        "time": 1689789439000,
        "value": 139.607,
        "_internal_originalTime": 1689789439000
    },
    {
        "time": 1689789440000,
        "value": 139.607,
        "_internal_originalTime": 1689789440000
    },
    {
        "time": 1689789441000,
        "value": 139.608,
        "_internal_originalTime": 1689789441000
    },
    {
        "time": 1689789451000,
        "value": 139.606,
        "_internal_originalTime": 1689789451000
    },
    {
        "time": 1689789452000,
        "value": 139.608,
        "_internal_originalTime": 1689789452000
    },
    {
        "time": 1689789453000,
        "value": 139.606,
        "_internal_originalTime": 1689789453000
    },
    {
        "time": 1689789455000,
        "value": 139.607,
        "_internal_originalTime": 1689789455000
    },
    {
        "time": 1689789458000,
        "value": 139.609,
        "_internal_originalTime": 1689789458000
    },
    {
        "time": 1689789460000,
        "value": 139.612,
        "_internal_originalTime": 1689789460000
    },
    {
        "time": 1689789462000,
        "value": 139.608,
        "_internal_originalTime": 1689789462000
    },
    {
        "time": 1689789463000,
        "value": 139.607,
        "_internal_originalTime": 1689789463000
    },
    {
        "time": 1689789467000,
        "value": 139.605,
        "_internal_originalTime": 1689789467000
    },
    {
        "time": 1689789475000,
        "value": 139.611,
        "_internal_originalTime": 1689789475000
    },
    {
        "time": 1689789476000,
        "value": 139.607,
        "_internal_originalTime": 1689789476000
    },
    {
        "time": 1689789478000,
        "value": 139.607,
        "_internal_originalTime": 1689789478000
    },
    {
        "time": 1689789479000,
        "value": 139.607,
        "_internal_originalTime": 1689789479000
    },
    {
        "time": 1689789480000,
        "value": 139.607,
        "_internal_originalTime": 1689789480000
    },
    {
        "time": 1689789481000,
        "value": 139.607,
        "_internal_originalTime": 1689789481000
    },
    {
        "time": 1689789483000,
        "value": 139.607,
        "_internal_originalTime": 1689789483000
    },
    {
        "time": 1689789485000,
        "value": 139.607,
        "_internal_originalTime": 1689789485000
    },
    {
        "time": 1689789488000,
        "value": 139.609,
        "_internal_originalTime": 1689789488000
    },
    {
        "time": 1689789497000,
        "value": 139.605,
        "_internal_originalTime": 1689789497000
    },
    {
        "time": 1689789498000,
        "value": 139.607,
        "_internal_originalTime": 1689789498000
    },
    {
        "time": 1689789499000,
        "value": 139.601,
        "_internal_originalTime": 1689789499000
    },
    {
        "time": 1689789500000,
        "value": 139.595,
        "_internal_originalTime": 1689789500000
    },
    {
        "time": 1689789501000,
        "value": 139.6,
        "_internal_originalTime": 1689789501000
    },
    {
        "time": 1689789502000,
        "value": 139.6,
        "_internal_originalTime": 1689789502000
    },
    {
        "time": 1689789503000,
        "value": 139.6,
        "_internal_originalTime": 1689789503000
    },
    {
        "time": 1689789505000,
        "value": 139.597,
        "_internal_originalTime": 1689789505000
    },
    {
        "time": 1689789506000,
        "value": 139.596,
        "_internal_originalTime": 1689789506000
    },
    {
        "time": 1689789507000,
        "value": 139.599,
        "_internal_originalTime": 1689789507000
    },
    {
        "time": 1689789509000,
        "value": 139.598,
        "_internal_originalTime": 1689789509000
    },
    {
        "time": 1689789510000,
        "value": 139.597,
        "_internal_originalTime": 1689789510000
    },
    {
        "time": 1689789512000,
        "value": 139.597,
        "_internal_originalTime": 1689789512000
    },
    {
        "time": 1689789514000,
        "value": 139.595,
        "_internal_originalTime": 1689789514000
    },
    {
        "time": 1689789515000,
        "value": 139.597,
        "_internal_originalTime": 1689789515000
    },
    {
        "time": 1689789517000,
        "value": 139.597,
        "_internal_originalTime": 1689789517000
    },
    {
        "time": 1689789518000,
        "value": 139.599,
        "_internal_originalTime": 1689789518000
    },
    {
        "time": 1689789519000,
        "value": 139.597,
        "_internal_originalTime": 1689789519000
    },
    {
        "time": 1689789522000,
        "value": 139.599,
        "_internal_originalTime": 1689789522000
    },
    {
        "time": 1689789525000,
        "value": 139.597,
        "_internal_originalTime": 1689789525000
    },
    {
        "time": 1689789526000,
        "value": 139.596,
        "_internal_originalTime": 1689789526000
    },
    {
        "time": 1689789527000,
        "value": 139.594,
        "_internal_originalTime": 1689789527000
    },
    {
        "time": 1689789528000,
        "value": 139.596,
        "_internal_originalTime": 1689789528000
    },
    {
        "time": 1689789529000,
        "value": 139.596,
        "_internal_originalTime": 1689789529000
    },
    {
        "time": 1689789530000,
        "value": 139.597,
        "_internal_originalTime": 1689789530000
    },
    {
        "time": 1689789532000,
        "value": 139.597,
        "_internal_originalTime": 1689789532000
    },
    {
        "time": 1689789535000,
        "value": 139.597,
        "_internal_originalTime": 1689789535000
    },
    {
        "time": 1689789538000,
        "value": 139.597,
        "_internal_originalTime": 1689789538000
    },
    {
        "time": 1689789540000,
        "value": 139.601,
        "_internal_originalTime": 1689789540000
    },
    {
        "time": 1689789541000,
        "value": 139.598,
        "_internal_originalTime": 1689789541000
    },
    {
        "time": 1689789542000,
        "value": 139.599,
        "_internal_originalTime": 1689789542000
    },
    {
        "time": 1689789544000,
        "value": 139.596,
        "_internal_originalTime": 1689789544000
    },
    {
        "time": 1689789545000,
        "value": 139.6,
        "_internal_originalTime": 1689789545000
    },
    {
        "time": 1689789547000,
        "value": 139.599,
        "_internal_originalTime": 1689789547000
    },
    {
        "time": 1689789548000,
        "value": 139.598,
        "_internal_originalTime": 1689789548000
    },
    {
        "time": 1689789549000,
        "value": 139.594,
        "_internal_originalTime": 1689789549000
    },
    {
        "time": 1689789550000,
        "value": 139.594,
        "_internal_originalTime": 1689789550000
    },
    {
        "time": 1689789551000,
        "value": 139.595,
        "_internal_originalTime": 1689789551000
    },
    {
        "time": 1689789552000,
        "value": 139.594,
        "_internal_originalTime": 1689789552000
    },
    {
        "time": 1689789555000,
        "value": 139.599,
        "_internal_originalTime": 1689789555000
    },
    {
        "time": 1689789557000,
        "value": 139.589,
        "_internal_originalTime": 1689789557000
    },
    {
        "time": 1689789558000,
        "value": 139.582,
        "_internal_originalTime": 1689789558000
    },
    {
        "time": 1689789559000,
        "value": 139.568,
        "_internal_originalTime": 1689789559000
    },
    {
        "time": 1689789560000,
        "value": 139.569,
        "_internal_originalTime": 1689789560000
    },
    {
        "time": 1689789563000,
        "value": 139.567,
        "_internal_originalTime": 1689789563000
    },
    {
        "time": 1689789565000,
        "value": 139.575,
        "_internal_originalTime": 1689789565000
    },
    {
        "time": 1689789567000,
        "value": 139.576,
        "_internal_originalTime": 1689789567000
    },
    {
        "time": 1689789568000,
        "value": 139.578,
        "_internal_originalTime": 1689789568000
    },
    {
        "time": 1689789570000,
        "value": 139.574,
        "_internal_originalTime": 1689789570000
    },
    {
        "time": 1689789571000,
        "value": 139.573,
        "_internal_originalTime": 1689789571000
    },
    {
        "time": 1689789574000,
        "value": 139.573,
        "_internal_originalTime": 1689789574000
    },
    {
        "time": 1689789575000,
        "value": 139.578,
        "_internal_originalTime": 1689789575000
    },
    {
        "time": 1689789578000,
        "value": 139.577,
        "_internal_originalTime": 1689789578000
    },
    {
        "time": 1689789580000,
        "value": 139.577,
        "_internal_originalTime": 1689789580000
    },
    {
        "time": 1689789581000,
        "value": 139.576,
        "_internal_originalTime": 1689789581000
    },
    {
        "time": 1689789582000,
        "value": 139.581,
        "_internal_originalTime": 1689789582000
    },
    {
        "time": 1689789584000,
        "value": 139.581,
        "_internal_originalTime": 1689789584000
    },
    {
        "time": 1689789585000,
        "value": 139.579,
        "_internal_originalTime": 1689789585000
    },
    {
        "time": 1689789587000,
        "value": 139.575,
        "_internal_originalTime": 1689789587000
    },
    {
        "time": 1689789591000,
        "value": 139.581,
        "_internal_originalTime": 1689789591000
    },
    {
        "time": 1689789592000,
        "value": 139.581,
        "_internal_originalTime": 1689789592000
    },
    {
        "time": 1689789593000,
        "value": 139.58,
        "_internal_originalTime": 1689789593000
    },
    {
        "time": 1689789594000,
        "value": 139.582,
        "_internal_originalTime": 1689789594000
    },
    {
        "time": 1689789596000,
        "value": 139.582,
        "_internal_originalTime": 1689789596000
    },
    {
        "time": 1689789597000,
        "value": 139.583,
        "_internal_originalTime": 1689789597000
    },
    {
        "time": 1689789598000,
        "value": 139.582,
        "_internal_originalTime": 1689789598000
    },
    {
        "time": 1689789599000,
        "value": 139.583,
        "_internal_originalTime": 1689789599000
    },
    {
        "time": 1689789601000,
        "value": 139.582,
        "_internal_originalTime": 1689789601000
    },
    {
        "time": 1689789602000,
        "value": 139.579,
        "_internal_originalTime": 1689789602000
    },
    {
        "time": 1689789604000,
        "value": 139.58,
        "_internal_originalTime": 1689789604000
    },
    {
        "time": 1689789606000,
        "value": 139.58,
        "_internal_originalTime": 1689789606000
    },
    {
        "time": 1689789608000,
        "value": 139.579,
        "_internal_originalTime": 1689789608000
    },
    {
        "time": 1689789609000,
        "value": 139.575,
        "_internal_originalTime": 1689789609000
    },
    {
        "time": 1689789610000,
        "value": 139.581,
        "_internal_originalTime": 1689789610000
    },
    {
        "time": 1689789612000,
        "value": 139.58,
        "_internal_originalTime": 1689789612000
    },
    {
        "time": 1689789615000,
        "value": 139.58,
        "_internal_originalTime": 1689789615000
    },
    {
        "time": 1689789616000,
        "value": 139.574,
        "_internal_originalTime": 1689789616000
    },
    {
        "time": 1689789617000,
        "value": 139.571,
        "_internal_originalTime": 1689789617000
    },
    {
        "time": 1689789618000,
        "value": 139.573,
        "_internal_originalTime": 1689789618000
    },
    {
        "time": 1689789619000,
        "value": 139.569,
        "_internal_originalTime": 1689789619000
    },
    {
        "time": 1689789625000,
        "value": 139.571,
        "_internal_originalTime": 1689789625000
    },
    {
        "time": 1689789627000,
        "value": 139.568,
        "_internal_originalTime": 1689789627000
    },
    {
        "time": 1689789629000,
        "value": 139.571,
        "_internal_originalTime": 1689789629000
    },
    {
        "time": 1689789630000,
        "value": 139.563,
        "_internal_originalTime": 1689789630000
    },
    {
        "time": 1689789632000,
        "value": 139.563,
        "_internal_originalTime": 1689789632000
    },
    {
        "time": 1689789633000,
        "value": 139.567,
        "_internal_originalTime": 1689789633000
    },
    {
        "time": 1689789634000,
        "value": 139.565,
        "_internal_originalTime": 1689789634000
    },
    {
        "time": 1689789635000,
        "value": 139.568,
        "_internal_originalTime": 1689789635000
    },
    {
        "time": 1689789636000,
        "value": 139.567,
        "_internal_originalTime": 1689789636000
    },
    {
        "time": 1689789638000,
        "value": 139.566,
        "_internal_originalTime": 1689789638000
    },
    {
        "time": 1689789639000,
        "value": 139.562,
        "_internal_originalTime": 1689789639000
    },
    {
        "time": 1689789640000,
        "value": 139.568,
        "_internal_originalTime": 1689789640000
    },
    {
        "time": 1689789642000,
        "value": 139.57,
        "_internal_originalTime": 1689789642000
    },
    {
        "time": 1689789646000,
        "value": 139.568,
        "_internal_originalTime": 1689789646000
    },
    {
        "time": 1689789647000,
        "value": 139.564,
        "_internal_originalTime": 1689789647000
    },
    {
        "time": 1689789649000,
        "value": 139.568,
        "_internal_originalTime": 1689789649000
    },
    {
        "time": 1689789651000,
        "value": 139.568,
        "_internal_originalTime": 1689789651000
    },
    {
        "time": 1689789654000,
        "value": 139.57,
        "_internal_originalTime": 1689789654000
    },
    {
        "time": 1689789656000,
        "value": 139.571,
        "_internal_originalTime": 1689789656000
    },
    {
        "time": 1689789657000,
        "value": 139.578,
        "_internal_originalTime": 1689789657000
    },
    {
        "time": 1689789658000,
        "value": 139.575,
        "_internal_originalTime": 1689789658000
    },
    {
        "time": 1689789659000,
        "value": 139.575,
        "_internal_originalTime": 1689789659000
    },
    {
        "time": 1689789663000,
        "value": 139.57,
        "_internal_originalTime": 1689789663000
    },
    {
        "time": 1689789665000,
        "value": 139.573,
        "_internal_originalTime": 1689789665000
    },
    {
        "time": 1689789666000,
        "value": 139.577,
        "_internal_originalTime": 1689789666000
    },
    {
        "time": 1689789668000,
        "value": 139.577,
        "_internal_originalTime": 1689789668000
    },
    {
        "time": 1689789669000,
        "value": 139.578,
        "_internal_originalTime": 1689789669000
    },
    {
        "time": 1689789671000,
        "value": 139.578,
        "_internal_originalTime": 1689789671000
    },
    {
        "time": 1689789672000,
        "value": 139.576,
        "_internal_originalTime": 1689789672000
    },
    {
        "time": 1689789673000,
        "value": 139.576,
        "_internal_originalTime": 1689789673000
    },
    {
        "time": 1689789675000,
        "value": 139.571,
        "_internal_originalTime": 1689789675000
    },
    {
        "time": 1689789676000,
        "value": 139.577,
        "_internal_originalTime": 1689789676000
    },
    {
        "time": 1689789677000,
        "value": 139.572,
        "_internal_originalTime": 1689789677000
    },
    {
        "time": 1689789679000,
        "value": 139.573,
        "_internal_originalTime": 1689789679000
    },
    {
        "time": 1689789680000,
        "value": 139.579,
        "_internal_originalTime": 1689789680000
    },
    {
        "time": 1689789681000,
        "value": 139.577,
        "_internal_originalTime": 1689789681000
    },
    {
        "time": 1689789682000,
        "value": 139.579,
        "_internal_originalTime": 1689789682000
    },
    {
        "time": 1689789683000,
        "value": 139.583,
        "_internal_originalTime": 1689789683000
    },
    {
        "time": 1689789685000,
        "value": 139.58,
        "_internal_originalTime": 1689789685000
    },
    {
        "time": 1689789687000,
        "value": 139.583,
        "_internal_originalTime": 1689789687000
    },
    {
        "time": 1689789690000,
        "value": 139.583,
        "_internal_originalTime": 1689789690000
    },
    {
        "time": 1689789691000,
        "value": 139.579,
        "_internal_originalTime": 1689789691000
    },
    {
        "time": 1689789692000,
        "value": 139.578,
        "_internal_originalTime": 1689789692000
    },
    {
        "time": 1689789693000,
        "value": 139.582,
        "_internal_originalTime": 1689789693000
    },
    {
        "time": 1689789695000,
        "value": 139.578,
        "_internal_originalTime": 1689789695000
    },
    {
        "time": 1689789696000,
        "value": 139.582,
        "_internal_originalTime": 1689789696000
    },
    {
        "time": 1689789697000,
        "value": 139.581,
        "_internal_originalTime": 1689789697000
    },
    {
        "time": 1689789698000,
        "value": 139.574,
        "_internal_originalTime": 1689789698000
    },
    {
        "time": 1689789699000,
        "value": 139.575,
        "_internal_originalTime": 1689789699000
    },
    {
        "time": 1689789701000,
        "value": 139.574,
        "_internal_originalTime": 1689789701000
    },
    {
        "time": 1689789702000,
        "value": 139.569,
        "_internal_originalTime": 1689789702000
    },
    {
        "time": 1689789706000,
        "value": 139.574,
        "_internal_originalTime": 1689789706000
    },
    {
        "time": 1689789707000,
        "value": 139.573,
        "_internal_originalTime": 1689789707000
    },
    {
        "time": 1689789708000,
        "value": 139.578,
        "_internal_originalTime": 1689789708000
    },
    {
        "time": 1689789709000,
        "value": 139.578,
        "_internal_originalTime": 1689789709000
    },
    {
        "time": 1689789710000,
        "value": 139.579,
        "_internal_originalTime": 1689789710000
    },
    {
        "time": 1689789711000,
        "value": 139.578,
        "_internal_originalTime": 1689789711000
    },
    {
        "time": 1689789712000,
        "value": 139.579,
        "_internal_originalTime": 1689789712000
    },
    {
        "time": 1689789713000,
        "value": 139.577,
        "_internal_originalTime": 1689789713000
    },
    {
        "time": 1689789716000,
        "value": 139.578,
        "_internal_originalTime": 1689789716000
    },
    {
        "time": 1689789717000,
        "value": 139.574,
        "_internal_originalTime": 1689789717000
    },
    {
        "time": 1689789720000,
        "value": 139.573,
        "_internal_originalTime": 1689789720000
    },
    {
        "time": 1689789721000,
        "value": 139.573,
        "_internal_originalTime": 1689789721000
    },
    {
        "time": 1689789723000,
        "value": 139.57,
        "_internal_originalTime": 1689789723000
    },
    {
        "time": 1689789724000,
        "value": 139.57,
        "_internal_originalTime": 1689789724000
    },
    {
        "time": 1689789726000,
        "value": 139.57,
        "_internal_originalTime": 1689789726000
    },
    {
        "time": 1689789727000,
        "value": 139.569,
        "_internal_originalTime": 1689789727000
    },
    {
        "time": 1689789728000,
        "value": 139.569,
        "_internal_originalTime": 1689789728000
    },
    {
        "time": 1689789729000,
        "value": 139.568,
        "_internal_originalTime": 1689789729000
    },
    {
        "time": 1689789731000,
        "value": 139.568,
        "_internal_originalTime": 1689789731000
    },
    {
        "time": 1689789732000,
        "value": 139.568,
        "_internal_originalTime": 1689789732000
    },
    {
        "time": 1689789733000,
        "value": 139.568,
        "_internal_originalTime": 1689789733000
    },
    {
        "time": 1689789735000,
        "value": 139.567,
        "_internal_originalTime": 1689789735000
    },
    {
        "time": 1689789736000,
        "value": 139.567,
        "_internal_originalTime": 1689789736000
    },
    {
        "time": 1689789737000,
        "value": 139.567,
        "_internal_originalTime": 1689789737000
    },
    {
        "time": 1689789738000,
        "value": 139.561,
        "_internal_originalTime": 1689789738000
    },
    {
        "time": 1689789740000,
        "value": 139.564,
        "_internal_originalTime": 1689789740000
    },
    {
        "time": 1689789741000,
        "value": 139.558,
        "_internal_originalTime": 1689789741000
    },
    {
        "time": 1689789742000,
        "value": 139.555,
        "_internal_originalTime": 1689789742000
    },
    {
        "time": 1689789743000,
        "value": 139.557,
        "_internal_originalTime": 1689789743000
    },
    {
        "time": 1689789744000,
        "value": 139.553,
        "_internal_originalTime": 1689789744000
    },
    {
        "time": 1689789746000,
        "value": 139.558,
        "_internal_originalTime": 1689789746000
    },
    {
        "time": 1689789748000,
        "value": 139.557,
        "_internal_originalTime": 1689789748000
    },
    {
        "time": 1689789749000,
        "value": 139.557,
        "_internal_originalTime": 1689789749000
    },
    {
        "time": 1689789750000,
        "value": 139.551,
        "_internal_originalTime": 1689789750000
    },
    {
        "time": 1689789751000,
        "value": 139.553,
        "_internal_originalTime": 1689789751000
    },
    {
        "time": 1689789754000,
        "value": 139.55,
        "_internal_originalTime": 1689789754000
    },
    {
        "time": 1689789755000,
        "value": 139.551,
        "_internal_originalTime": 1689789755000
    },
    {
        "time": 1689789757000,
        "value": 139.55,
        "_internal_originalTime": 1689789757000
    },
    {
        "time": 1689789758000,
        "value": 139.553,
        "_internal_originalTime": 1689789758000
    },
    {
        "time": 1689789759000,
        "value": 139.554,
        "_internal_originalTime": 1689789759000
    },
    {
        "time": 1689789762000,
        "value": 139.551,
        "_internal_originalTime": 1689789762000
    },
    {
        "time": 1689789764000,
        "value": 139.55,
        "_internal_originalTime": 1689789764000
    },
    {
        "time": 1689789765000,
        "value": 139.554,
        "_internal_originalTime": 1689789765000
    },
    {
        "time": 1689789767000,
        "value": 139.547,
        "_internal_originalTime": 1689789767000
    },
    {
        "time": 1689789768000,
        "value": 139.547,
        "_internal_originalTime": 1689789768000
    },
    {
        "time": 1689789772000,
        "value": 139.551,
        "_internal_originalTime": 1689789772000
    },
    {
        "time": 1689789773000,
        "value": 139.555,
        "_internal_originalTime": 1689789773000
    },
    {
        "time": 1689789774000,
        "value": 139.554,
        "_internal_originalTime": 1689789774000
    },
    {
        "time": 1689789780000,
        "value": 139.547,
        "_internal_originalTime": 1689789780000
    },
    {
        "time": 1689789781000,
        "value": 139.552,
        "_internal_originalTime": 1689789781000
    },
    {
        "time": 1689789783000,
        "value": 139.542,
        "_internal_originalTime": 1689789783000
    },
    {
        "time": 1689789784000,
        "value": 139.542,
        "_internal_originalTime": 1689789784000
    },
    {
        "time": 1689789786000,
        "value": 139.541,
        "_internal_originalTime": 1689789786000
    },
    {
        "time": 1689789787000,
        "value": 139.547,
        "_internal_originalTime": 1689789787000
    },
    {
        "time": 1689789788000,
        "value": 139.548,
        "_internal_originalTime": 1689789788000
    },
    {
        "time": 1689789792000,
        "value": 139.547,
        "_internal_originalTime": 1689789792000
    },
    {
        "time": 1689789794000,
        "value": 139.545,
        "_internal_originalTime": 1689789794000
    },
    {
        "time": 1689789795000,
        "value": 139.545,
        "_internal_originalTime": 1689789795000
    },
    {
        "time": 1689789796000,
        "value": 139.542,
        "_internal_originalTime": 1689789796000
    },
    {
        "time": 1689789797000,
        "value": 139.545,
        "_internal_originalTime": 1689789797000
    },
    {
        "time": 1689789800000,
        "value": 139.545,
        "_internal_originalTime": 1689789800000
    },
    {
        "time": 1689789806000,
        "value": 139.541,
        "_internal_originalTime": 1689789806000
    },
    {
        "time": 1689789816000,
        "value": 139.544,
        "_internal_originalTime": 1689789816000
    },
    {
        "time": 1689789819000,
        "value": 139.543,
        "_internal_originalTime": 1689789819000
    },
    {
        "time": 1689789822000,
        "value": 139.539,
        "_internal_originalTime": 1689789822000
    },
    {
        "time": 1689789824000,
        "value": 139.539,
        "_internal_originalTime": 1689789824000
    },
    {
        "time": 1689789826000,
        "value": 139.539,
        "_internal_originalTime": 1689789826000
    },
    {
        "time": 1689789827000,
        "value": 139.539,
        "_internal_originalTime": 1689789827000
    },
    {
        "time": 1689789828000,
        "value": 139.547,
        "_internal_originalTime": 1689789828000
    },
    {
        "time": 1689789830000,
        "value": 139.541,
        "_internal_originalTime": 1689789830000
    },
    {
        "time": 1689789831000,
        "value": 139.542,
        "_internal_originalTime": 1689789831000
    },
    {
        "time": 1689789833000,
        "value": 139.55,
        "_internal_originalTime": 1689789833000
    },
    {
        "time": 1689789838000,
        "value": 139.55,
        "_internal_originalTime": 1689789838000
    },
    {
        "time": 1689789840000,
        "value": 139.548,
        "_internal_originalTime": 1689789840000
    },
    {
        "time": 1689789841000,
        "value": 139.547,
        "_internal_originalTime": 1689789841000
    },
    {
        "time": 1689789842000,
        "value": 139.545,
        "_internal_originalTime": 1689789842000
    },
    {
        "time": 1689789844000,
        "value": 139.538,
        "_internal_originalTime": 1689789844000
    },
    {
        "time": 1689789845000,
        "value": 139.539,
        "_internal_originalTime": 1689789845000
    },
    {
        "time": 1689789847000,
        "value": 139.539,
        "_internal_originalTime": 1689789847000
    },
    {
        "time": 1689789848000,
        "value": 139.535,
        "_internal_originalTime": 1689789848000
    },
    {
        "time": 1689789849000,
        "value": 139.534,
        "_internal_originalTime": 1689789849000
    },
    {
        "time": 1689789850000,
        "value": 139.529,
        "_internal_originalTime": 1689789850000
    },
    {
        "time": 1689789851000,
        "value": 139.529,
        "_internal_originalTime": 1689789851000
    },
    {
        "time": 1689789854000,
        "value": 139.529,
        "_internal_originalTime": 1689789854000
    },
    {
        "time": 1689789858000,
        "value": 139.535,
        "_internal_originalTime": 1689789858000
    },
    {
        "time": 1689789859000,
        "value": 139.528,
        "_internal_originalTime": 1689789859000
    },
    {
        "time": 1689789860000,
        "value": 139.528,
        "_internal_originalTime": 1689789860000
    },
    {
        "time": 1689789861000,
        "value": 139.527,
        "_internal_originalTime": 1689789861000
    },
    {
        "time": 1689789862000,
        "value": 139.533,
        "_internal_originalTime": 1689789862000
    },
    {
        "time": 1689789863000,
        "value": 139.533,
        "_internal_originalTime": 1689789863000
    },
    {
        "time": 1689789864000,
        "value": 139.528,
        "_internal_originalTime": 1689789864000
    },
    {
        "time": 1689789871000,
        "value": 139.529,
        "_internal_originalTime": 1689789871000
    },
    {
        "time": 1689789872000,
        "value": 139.533,
        "_internal_originalTime": 1689789872000
    },
    {
        "time": 1689789878000,
        "value": 139.53,
        "_internal_originalTime": 1689789878000
    },
    {
        "time": 1689789879000,
        "value": 139.528,
        "_internal_originalTime": 1689789879000
    },
    {
        "time": 1689789883000,
        "value": 139.528,
        "_internal_originalTime": 1689789883000
    },
    {
        "time": 1689789885000,
        "value": 139.54,
        "_internal_originalTime": 1689789885000
    },
    {
        "time": 1689789887000,
        "value": 139.533,
        "_internal_originalTime": 1689789887000
    },
    {
        "time": 1689789888000,
        "value": 139.533,
        "_internal_originalTime": 1689789888000
    },
    {
        "time": 1689789889000,
        "value": 139.533,
        "_internal_originalTime": 1689789889000
    },
    {
        "time": 1689789891000,
        "value": 139.533,
        "_internal_originalTime": 1689789891000
    },
    {
        "time": 1689789893000,
        "value": 139.535,
        "_internal_originalTime": 1689789893000
    },
    {
        "time": 1689789894000,
        "value": 139.539,
        "_internal_originalTime": 1689789894000
    },
    {
        "time": 1689789896000,
        "value": 139.541,
        "_internal_originalTime": 1689789896000
    },
    {
        "time": 1689789899000,
        "value": 139.539,
        "_internal_originalTime": 1689789899000
    },
    {
        "time": 1689789906000,
        "value": 139.533,
        "_internal_originalTime": 1689789906000
    },
    {
        "time": 1689789907000,
        "value": 139.535,
        "_internal_originalTime": 1689789907000
    },
    {
        "time": 1689789908000,
        "value": 139.532,
        "_internal_originalTime": 1689789908000
    },
    {
        "time": 1689789909000,
        "value": 139.528,
        "_internal_originalTime": 1689789909000
    },
    {
        "time": 1689789911000,
        "value": 139.535,
        "_internal_originalTime": 1689789911000
    },
    {
        "time": 1689789912000,
        "value": 139.528,
        "_internal_originalTime": 1689789912000
    },
    {
        "time": 1689789914000,
        "value": 139.521,
        "_internal_originalTime": 1689789914000
    },
    {
        "time": 1689789915000,
        "value": 139.517,
        "_internal_originalTime": 1689789915000
    },
    {
        "time": 1689789916000,
        "value": 139.519,
        "_internal_originalTime": 1689789916000
    },
    {
        "time": 1689789917000,
        "value": 139.511,
        "_internal_originalTime": 1689789917000
    },
    {
        "time": 1689789919000,
        "value": 139.521,
        "_internal_originalTime": 1689789919000
    },
    {
        "time": 1689789920000,
        "value": 139.518,
        "_internal_originalTime": 1689789920000
    },
    {
        "time": 1689789922000,
        "value": 139.519,
        "_internal_originalTime": 1689789922000
    },
    {
        "time": 1689789924000,
        "value": 139.52,
        "_internal_originalTime": 1689789924000
    },
    {
        "time": 1689789925000,
        "value": 139.515,
        "_internal_originalTime": 1689789925000
    },
    {
        "time": 1689789927000,
        "value": 139.517,
        "_internal_originalTime": 1689789927000
    },
    {
        "time": 1689789929000,
        "value": 139.521,
        "_internal_originalTime": 1689789929000
    },
    {
        "time": 1689789931000,
        "value": 139.524,
        "_internal_originalTime": 1689789931000
    },
    {
        "time": 1689789935000,
        "value": 139.516,
        "_internal_originalTime": 1689789935000
    },
    {
        "time": 1689789936000,
        "value": 139.521,
        "_internal_originalTime": 1689789936000
    },
    {
        "time": 1689789937000,
        "value": 139.521,
        "_internal_originalTime": 1689789937000
    },
    {
        "time": 1689789938000,
        "value": 139.518,
        "_internal_originalTime": 1689789938000
    },
    {
        "time": 1689789939000,
        "value": 139.521,
        "_internal_originalTime": 1689789939000
    },
    {
        "time": 1689789940000,
        "value": 139.521,
        "_internal_originalTime": 1689789940000
    },
    {
        "time": 1689789941000,
        "value": 139.521,
        "_internal_originalTime": 1689789941000
    },
    {
        "time": 1689789942000,
        "value": 139.521,
        "_internal_originalTime": 1689789942000
    },
    {
        "time": 1689789944000,
        "value": 139.517,
        "_internal_originalTime": 1689789944000
    },
    {
        "time": 1689789945000,
        "value": 139.517,
        "_internal_originalTime": 1689789945000
    },
    {
        "time": 1689789946000,
        "value": 139.521,
        "_internal_originalTime": 1689789946000
    },
    {
        "time": 1689789947000,
        "value": 139.521,
        "_internal_originalTime": 1689789947000
    },
    {
        "time": 1689789949000,
        "value": 139.523,
        "_internal_originalTime": 1689789949000
    },
    {
        "time": 1689789952000,
        "value": 139.521,
        "_internal_originalTime": 1689789952000
    },
    {
        "time": 1689789954000,
        "value": 139.529,
        "_internal_originalTime": 1689789954000
    },
    {
        "time": 1689789956000,
        "value": 139.527,
        "_internal_originalTime": 1689789956000
    },
    {
        "time": 1689789960000,
        "value": 139.527,
        "_internal_originalTime": 1689789960000
    },
    {
        "time": 1689789961000,
        "value": 139.531,
        "_internal_originalTime": 1689789961000
    },
    {
        "time": 1689789962000,
        "value": 139.533,
        "_internal_originalTime": 1689789962000
    },
    {
        "time": 1689789963000,
        "value": 139.531,
        "_internal_originalTime": 1689789963000
    },
    {
        "time": 1689789966000,
        "value": 139.527,
        "_internal_originalTime": 1689789966000
    },
    {
        "time": 1689789968000,
        "value": 139.53,
        "_internal_originalTime": 1689789968000
    },
    {
        "time": 1689789969000,
        "value": 139.527,
        "_internal_originalTime": 1689789969000
    },
    {
        "time": 1689789970000,
        "value": 139.528,
        "_internal_originalTime": 1689789970000
    },
    {
        "time": 1689789971000,
        "value": 139.527,
        "_internal_originalTime": 1689789971000
    },
    {
        "time": 1689789975000,
        "value": 139.528,
        "_internal_originalTime": 1689789975000
    },
    {
        "time": 1689789976000,
        "value": 139.529,
        "_internal_originalTime": 1689789976000
    },
    {
        "time": 1689789977000,
        "value": 139.526,
        "_internal_originalTime": 1689789977000
    },
    {
        "time": 1689789981000,
        "value": 139.538,
        "_internal_originalTime": 1689789981000
    },
    {
        "time": 1689789982000,
        "value": 139.539,
        "_internal_originalTime": 1689789982000
    },
    {
        "time": 1689789983000,
        "value": 139.534,
        "_internal_originalTime": 1689789983000
    },
    {
        "time": 1689789985000,
        "value": 139.533,
        "_internal_originalTime": 1689789985000
    },
    {
        "time": 1689789987000,
        "value": 139.532,
        "_internal_originalTime": 1689789987000
    },
    {
        "time": 1689789989000,
        "value": 139.536,
        "_internal_originalTime": 1689789989000
    },
    {
        "time": 1689789994000,
        "value": 139.536,
        "_internal_originalTime": 1689789994000
    },
    {
        "time": 1689789995000,
        "value": 139.531,
        "_internal_originalTime": 1689789995000
    },
    {
        "time": 1689789996000,
        "value": 139.537,
        "_internal_originalTime": 1689789996000
    },
    {
        "time": 1689789998000,
        "value": 139.535,
        "_internal_originalTime": 1689789998000
    },
    {
        "time": 1689790000000,
        "value": 139.536,
        "_internal_originalTime": 1689790000000
    },
    {
        "time": 1689790002000,
        "value": 139.537,
        "_internal_originalTime": 1689790002000
    },
    {
        "time": 1689790003000,
        "value": 139.538,
        "_internal_originalTime": 1689790003000
    },
    {
        "time": 1689790004000,
        "value": 139.534,
        "_internal_originalTime": 1689790004000
    },
    {
        "time": 1689790008000,
        "value": 139.535,
        "_internal_originalTime": 1689790008000
    },
    {
        "time": 1689790010000,
        "value": 139.535,
        "_internal_originalTime": 1689790010000
    },
    {
        "time": 1689790011000,
        "value": 139.535,
        "_internal_originalTime": 1689790011000
    },
    {
        "time": 1689790013000,
        "value": 139.536,
        "_internal_originalTime": 1689790013000
    },
    {
        "time": 1689790016000,
        "value": 139.535,
        "_internal_originalTime": 1689790016000
    },
    {
        "time": 1689790018000,
        "value": 139.535,
        "_internal_originalTime": 1689790018000
    },
    {
        "time": 1689790019000,
        "value": 139.54,
        "_internal_originalTime": 1689790019000
    },
    {
        "time": 1689790020000,
        "value": 139.536,
        "_internal_originalTime": 1689790020000
    },
    {
        "time": 1689790022000,
        "value": 139.536,
        "_internal_originalTime": 1689790022000
    },
    {
        "time": 1689790024000,
        "value": 139.539,
        "_internal_originalTime": 1689790024000
    },
    {
        "time": 1689790025000,
        "value": 139.548,
        "_internal_originalTime": 1689790025000
    },
    {
        "time": 1689790028000,
        "value": 139.548,
        "_internal_originalTime": 1689790028000
    },
    {
        "time": 1689790029000,
        "value": 139.54,
        "_internal_originalTime": 1689790029000
    },
    {
        "time": 1689790030000,
        "value": 139.543,
        "_internal_originalTime": 1689790030000
    },
    {
        "time": 1689790031000,
        "value": 139.542,
        "_internal_originalTime": 1689790031000
    },
    {
        "time": 1689790032000,
        "value": 139.542,
        "_internal_originalTime": 1689790032000
    },
    {
        "time": 1689790034000,
        "value": 139.542,
        "_internal_originalTime": 1689790034000
    },
    {
        "time": 1689790036000,
        "value": 139.544,
        "_internal_originalTime": 1689790036000
    },
    {
        "time": 1689790038000,
        "value": 139.54,
        "_internal_originalTime": 1689790038000
    },
    {
        "time": 1689790039000,
        "value": 139.54,
        "_internal_originalTime": 1689790039000
    },
    {
        "time": 1689790041000,
        "value": 139.539,
        "_internal_originalTime": 1689790041000
    },
    {
        "time": 1689790043000,
        "value": 139.539,
        "_internal_originalTime": 1689790043000
    },
    {
        "time": 1689790045000,
        "value": 139.542,
        "_internal_originalTime": 1689790045000
    },
    {
        "time": 1689790047000,
        "value": 139.541,
        "_internal_originalTime": 1689790047000
    },
    {
        "time": 1689790048000,
        "value": 139.543,
        "_internal_originalTime": 1689790048000
    },
    {
        "time": 1689790049000,
        "value": 139.542,
        "_internal_originalTime": 1689790049000
    },
    {
        "time": 1689790052000,
        "value": 139.539,
        "_internal_originalTime": 1689790052000
    },
    {
        "time": 1689790053000,
        "value": 139.543,
        "_internal_originalTime": 1689790053000
    },
    {
        "time": 1689790054000,
        "value": 139.543,
        "_internal_originalTime": 1689790054000
    },
    {
        "time": 1689790055000,
        "value": 139.542,
        "_internal_originalTime": 1689790055000
    },
    {
        "time": 1689790056000,
        "value": 139.539,
        "_internal_originalTime": 1689790056000
    },
    {
        "time": 1689790057000,
        "value": 139.545,
        "_internal_originalTime": 1689790057000
    },
    {
        "time": 1689790058000,
        "value": 139.544,
        "_internal_originalTime": 1689790058000
    },
    {
        "time": 1689790059000,
        "value": 139.541,
        "_internal_originalTime": 1689790059000
    },
    {
        "time": 1689790062000,
        "value": 139.546,
        "_internal_originalTime": 1689790062000
    },
    {
        "time": 1689790063000,
        "value": 139.542,
        "_internal_originalTime": 1689790063000
    },
    {
        "time": 1689790064000,
        "value": 139.543,
        "_internal_originalTime": 1689790064000
    },
    {
        "time": 1689790065000,
        "value": 139.548,
        "_internal_originalTime": 1689790065000
    },
    {
        "time": 1689790067000,
        "value": 139.542,
        "_internal_originalTime": 1689790067000
    },
    {
        "time": 1689790069000,
        "value": 139.545,
        "_internal_originalTime": 1689790069000
    },
    {
        "time": 1689790070000,
        "value": 139.546,
        "_internal_originalTime": 1689790070000
    },
    {
        "time": 1689790072000,
        "value": 139.547,
        "_internal_originalTime": 1689790072000
    },
    {
        "time": 1689790073000,
        "value": 139.549,
        "_internal_originalTime": 1689790073000
    },
    {
        "time": 1689790074000,
        "value": 139.549,
        "_internal_originalTime": 1689790074000
    },
    {
        "time": 1689790076000,
        "value": 139.551,
        "_internal_originalTime": 1689790076000
    },
    {
        "time": 1689790077000,
        "value": 139.55,
        "_internal_originalTime": 1689790077000
    },
    {
        "time": 1689790079000,
        "value": 139.552,
        "_internal_originalTime": 1689790079000
    },
    {
        "time": 1689790080000,
        "value": 139.55,
        "_internal_originalTime": 1689790080000
    },
    {
        "time": 1689790081000,
        "value": 139.549,
        "_internal_originalTime": 1689790081000
    },
    {
        "time": 1689790082000,
        "value": 139.549,
        "_internal_originalTime": 1689790082000
    },
    {
        "time": 1689790085000,
        "value": 139.546,
        "_internal_originalTime": 1689790085000
    },
    {
        "time": 1689790086000,
        "value": 139.548,
        "_internal_originalTime": 1689790086000
    },
    {
        "time": 1689790087000,
        "value": 139.548,
        "_internal_originalTime": 1689790087000
    },
    {
        "time": 1689790088000,
        "value": 139.551,
        "_internal_originalTime": 1689790088000
    },
    {
        "time": 1689790089000,
        "value": 139.548,
        "_internal_originalTime": 1689790089000
    },
    {
        "time": 1689790090000,
        "value": 139.553,
        "_internal_originalTime": 1689790090000
    },
    {
        "time": 1689790092000,
        "value": 139.561,
        "_internal_originalTime": 1689790092000
    },
    {
        "time": 1689790093000,
        "value": 139.56,
        "_internal_originalTime": 1689790093000
    },
    {
        "time": 1689790094000,
        "value": 139.56,
        "_internal_originalTime": 1689790094000
    },
    {
        "time": 1689790095000,
        "value": 139.549,
        "_internal_originalTime": 1689790095000
    },
    {
        "time": 1689790096000,
        "value": 139.555,
        "_internal_originalTime": 1689790096000
    },
    {
        "time": 1689790097000,
        "value": 139.548,
        "_internal_originalTime": 1689790097000
    },
    {
        "time": 1689790100000,
        "value": 139.548,
        "_internal_originalTime": 1689790100000
    },
    {
        "time": 1689790101000,
        "value": 139.552,
        "_internal_originalTime": 1689790101000
    },
    {
        "time": 1689790102000,
        "value": 139.551,
        "_internal_originalTime": 1689790102000
    },
    {
        "time": 1689790104000,
        "value": 139.55,
        "_internal_originalTime": 1689790104000
    },
    {
        "time": 1689790105000,
        "value": 139.549,
        "_internal_originalTime": 1689790105000
    },
    {
        "time": 1689790107000,
        "value": 139.549,
        "_internal_originalTime": 1689790107000
    },
    {
        "time": 1689790108000,
        "value": 139.546,
        "_internal_originalTime": 1689790108000
    },
    {
        "time": 1689790111000,
        "value": 139.548,
        "_internal_originalTime": 1689790111000
    },
    {
        "time": 1689790112000,
        "value": 139.547,
        "_internal_originalTime": 1689790112000
    },
    {
        "time": 1689790113000,
        "value": 139.547,
        "_internal_originalTime": 1689790113000
    },
    {
        "time": 1689790114000,
        "value": 139.544,
        "_internal_originalTime": 1689790114000
    },
    {
        "time": 1689790115000,
        "value": 139.55,
        "_internal_originalTime": 1689790115000
    },
    {
        "time": 1689790116000,
        "value": 139.548,
        "_internal_originalTime": 1689790116000
    },
    {
        "time": 1689790118000,
        "value": 139.552,
        "_internal_originalTime": 1689790118000
    },
    {
        "time": 1689790119000,
        "value": 139.549,
        "_internal_originalTime": 1689790119000
    },
    {
        "time": 1689790121000,
        "value": 139.547,
        "_internal_originalTime": 1689790121000
    },
    {
        "time": 1689790122000,
        "value": 139.549,
        "_internal_originalTime": 1689790122000
    },
    {
        "time": 1689790123000,
        "value": 139.548,
        "_internal_originalTime": 1689790123000
    },
    {
        "time": 1689790124000,
        "value": 139.547,
        "_internal_originalTime": 1689790124000
    },
    {
        "time": 1689790127000,
        "value": 139.544,
        "_internal_originalTime": 1689790127000
    },
    {
        "time": 1689790128000,
        "value": 139.548,
        "_internal_originalTime": 1689790128000
    },
    {
        "time": 1689790129000,
        "value": 139.546,
        "_internal_originalTime": 1689790129000
    },
    {
        "time": 1689790130000,
        "value": 139.548,
        "_internal_originalTime": 1689790130000
    },
    {
        "time": 1689790131000,
        "value": 139.548,
        "_internal_originalTime": 1689790131000
    },
    {
        "time": 1689790132000,
        "value": 139.543,
        "_internal_originalTime": 1689790132000
    },
    {
        "time": 1689790133000,
        "value": 139.541,
        "_internal_originalTime": 1689790133000
    },
    {
        "time": 1689790135000,
        "value": 139.538,
        "_internal_originalTime": 1689790135000
    },
    {
        "time": 1689790136000,
        "value": 139.539,
        "_internal_originalTime": 1689790136000
    },
    {
        "time": 1689790138000,
        "value": 139.538,
        "_internal_originalTime": 1689790138000
    },
    {
        "time": 1689790140000,
        "value": 139.546,
        "_internal_originalTime": 1689790140000
    },
    {
        "time": 1689790141000,
        "value": 139.544,
        "_internal_originalTime": 1689790141000
    },
    {
        "time": 1689790142000,
        "value": 139.545,
        "_internal_originalTime": 1689790142000
    },
    {
        "time": 1689790144000,
        "value": 139.544,
        "_internal_originalTime": 1689790144000
    },
    {
        "time": 1689790145000,
        "value": 139.547,
        "_internal_originalTime": 1689790145000
    },
    {
        "time": 1689790146000,
        "value": 139.544,
        "_internal_originalTime": 1689790146000
    },
    {
        "time": 1689790148000,
        "value": 139.548,
        "_internal_originalTime": 1689790148000
    },
    {
        "time": 1689790150000,
        "value": 139.544,
        "_internal_originalTime": 1689790150000
    },
    {
        "time": 1689790151000,
        "value": 139.549,
        "_internal_originalTime": 1689790151000
    },
    {
        "time": 1689790153000,
        "value": 139.548,
        "_internal_originalTime": 1689790153000
    },
    {
        "time": 1689790154000,
        "value": 139.546,
        "_internal_originalTime": 1689790154000
    },
    {
        "time": 1689790156000,
        "value": 139.546,
        "_internal_originalTime": 1689790156000
    },
    {
        "time": 1689790157000,
        "value": 139.549,
        "_internal_originalTime": 1689790157000
    },
    {
        "time": 1689790158000,
        "value": 139.546,
        "_internal_originalTime": 1689790158000
    },
    {
        "time": 1689790159000,
        "value": 139.546,
        "_internal_originalTime": 1689790159000
    },
    {
        "time": 1689790161000,
        "value": 139.546,
        "_internal_originalTime": 1689790161000
    },
    {
        "time": 1689790163000,
        "value": 139.546,
        "_internal_originalTime": 1689790163000
    },
    {
        "time": 1689790164000,
        "value": 139.551,
        "_internal_originalTime": 1689790164000
    },
    {
        "time": 1689790166000,
        "value": 139.547,
        "_internal_originalTime": 1689790166000
    },
    {
        "time": 1689790168000,
        "value": 139.548,
        "_internal_originalTime": 1689790168000
    },
    {
        "time": 1689790170000,
        "value": 139.536,
        "_internal_originalTime": 1689790170000
    },
    {
        "time": 1689790171000,
        "value": 139.536,
        "_internal_originalTime": 1689790171000
    },
    {
        "time": 1689790172000,
        "value": 139.536,
        "_internal_originalTime": 1689790172000
    },
    {
        "time": 1689790177000,
        "value": 139.537,
        "_internal_originalTime": 1689790177000
    },
    {
        "time": 1689790178000,
        "value": 139.541,
        "_internal_originalTime": 1689790178000
    },
    {
        "time": 1689790183000,
        "value": 139.54,
        "_internal_originalTime": 1689790183000
    },
    {
        "time": 1689790186000,
        "value": 139.538,
        "_internal_originalTime": 1689790186000
    },
    {
        "time": 1689790187000,
        "value": 139.54,
        "_internal_originalTime": 1689790187000
    },
    {
        "time": 1689790191000,
        "value": 139.538,
        "_internal_originalTime": 1689790191000
    },
    {
        "time": 1689790192000,
        "value": 139.541,
        "_internal_originalTime": 1689790192000
    },
    {
        "time": 1689790193000,
        "value": 139.54,
        "_internal_originalTime": 1689790193000
    },
    {
        "time": 1689790194000,
        "value": 139.539,
        "_internal_originalTime": 1689790194000
    },
    {
        "time": 1689790195000,
        "value": 139.538,
        "_internal_originalTime": 1689790195000
    },
    {
        "time": 1689790197000,
        "value": 139.541,
        "_internal_originalTime": 1689790197000
    },
    {
        "time": 1689790199000,
        "value": 139.539,
        "_internal_originalTime": 1689790199000
    },
    {
        "time": 1689790200000,
        "value": 139.538,
        "_internal_originalTime": 1689790200000
    },
    {
        "time": 1689790201000,
        "value": 139.54,
        "_internal_originalTime": 1689790201000
    },
    {
        "time": 1689790204000,
        "value": 139.539,
        "_internal_originalTime": 1689790204000
    },
    {
        "time": 1689790205000,
        "value": 139.538,
        "_internal_originalTime": 1689790205000
    },
    {
        "time": 1689790206000,
        "value": 139.538,
        "_internal_originalTime": 1689790206000
    },
    {
        "time": 1689790207000,
        "value": 139.537,
        "_internal_originalTime": 1689790207000
    },
    {
        "time": 1689790208000,
        "value": 139.536,
        "_internal_originalTime": 1689790208000
    },
    {
        "time": 1689790209000,
        "value": 139.536,
        "_internal_originalTime": 1689790209000
    },
    {
        "time": 1689790210000,
        "value": 139.539,
        "_internal_originalTime": 1689790210000
    },
    {
        "time": 1689790211000,
        "value": 139.538,
        "_internal_originalTime": 1689790211000
    },
    {
        "time": 1689790213000,
        "value": 139.538,
        "_internal_originalTime": 1689790213000
    },
    {
        "time": 1689790214000,
        "value": 139.54,
        "_internal_originalTime": 1689790214000
    },
    {
        "time": 1689790216000,
        "value": 139.536,
        "_internal_originalTime": 1689790216000
    },
    {
        "time": 1689790217000,
        "value": 139.531,
        "_internal_originalTime": 1689790217000
    },
    {
        "time": 1689790218000,
        "value": 139.534,
        "_internal_originalTime": 1689790218000
    },
    {
        "time": 1689790220000,
        "value": 139.534,
        "_internal_originalTime": 1689790220000
    },
    {
        "time": 1689790222000,
        "value": 139.538,
        "_internal_originalTime": 1689790222000
    },
    {
        "time": 1689790223000,
        "value": 139.536,
        "_internal_originalTime": 1689790223000
    },
    {
        "time": 1689790224000,
        "value": 139.54,
        "_internal_originalTime": 1689790224000
    },
    {
        "time": 1689790227000,
        "value": 139.537,
        "_internal_originalTime": 1689790227000
    },
    {
        "time": 1689790228000,
        "value": 139.536,
        "_internal_originalTime": 1689790228000
    },
    {
        "time": 1689790229000,
        "value": 139.537,
        "_internal_originalTime": 1689790229000
    },
    {
        "time": 1689790233000,
        "value": 139.54,
        "_internal_originalTime": 1689790233000
    },
    {
        "time": 1689790237000,
        "value": 139.537,
        "_internal_originalTime": 1689790237000
    },
    {
        "time": 1689790238000,
        "value": 139.536,
        "_internal_originalTime": 1689790238000
    },
    {
        "time": 1689790240000,
        "value": 139.538,
        "_internal_originalTime": 1689790240000
    },
    {
        "time": 1689790241000,
        "value": 139.539,
        "_internal_originalTime": 1689790241000
    },
    {
        "time": 1689790242000,
        "value": 139.541,
        "_internal_originalTime": 1689790242000
    },
    {
        "time": 1689790243000,
        "value": 139.538,
        "_internal_originalTime": 1689790243000
    },
    {
        "time": 1689790245000,
        "value": 139.539,
        "_internal_originalTime": 1689790245000
    },
    {
        "time": 1689790247000,
        "value": 139.536,
        "_internal_originalTime": 1689790247000
    },
    {
        "time": 1689790253000,
        "value": 139.538,
        "_internal_originalTime": 1689790253000
    },
    {
        "time": 1689790254000,
        "value": 139.539,
        "_internal_originalTime": 1689790254000
    },
    {
        "time": 1689790256000,
        "value": 139.538,
        "_internal_originalTime": 1689790256000
    },
    {
        "time": 1689790260000,
        "value": 139.54,
        "_internal_originalTime": 1689790260000
    },
    {
        "time": 1689790261000,
        "value": 139.542,
        "_internal_originalTime": 1689790261000
    },
    {
        "time": 1689790263000,
        "value": 139.538,
        "_internal_originalTime": 1689790263000
    },
    {
        "time": 1689790265000,
        "value": 139.539,
        "_internal_originalTime": 1689790265000
    },
    {
        "time": 1689790267000,
        "value": 139.538,
        "_internal_originalTime": 1689790267000
    },
    {
        "time": 1689790268000,
        "value": 139.542,
        "_internal_originalTime": 1689790268000
    },
    {
        "time": 1689790269000,
        "value": 139.54,
        "_internal_originalTime": 1689790269000
    },
    {
        "time": 1689790270000,
        "value": 139.538,
        "_internal_originalTime": 1689790270000
    },
    {
        "time": 1689790272000,
        "value": 139.536,
        "_internal_originalTime": 1689790272000
    },
    {
        "time": 1689790273000,
        "value": 139.538,
        "_internal_originalTime": 1689790273000
    },
    {
        "time": 1689790274000,
        "value": 139.553,
        "_internal_originalTime": 1689790274000
    },
    {
        "time": 1689790276000,
        "value": 139.551,
        "_internal_originalTime": 1689790276000
    },
    {
        "time": 1689790277000,
        "value": 139.554,
        "_internal_originalTime": 1689790277000
    },
    {
        "time": 1689790278000,
        "value": 139.55,
        "_internal_originalTime": 1689790278000
    },
    {
        "time": 1689790279000,
        "value": 139.549,
        "_internal_originalTime": 1689790279000
    },
    {
        "time": 1689790280000,
        "value": 139.551,
        "_internal_originalTime": 1689790280000
    },
    {
        "time": 1689790281000,
        "value": 139.551,
        "_internal_originalTime": 1689790281000
    },
    {
        "time": 1689790282000,
        "value": 139.551,
        "_internal_originalTime": 1689790282000
    },
    {
        "time": 1689790284000,
        "value": 139.55,
        "_internal_originalTime": 1689790284000
    },
    {
        "time": 1689790286000,
        "value": 139.551,
        "_internal_originalTime": 1689790286000
    },
    {
        "time": 1689790287000,
        "value": 139.551,
        "_internal_originalTime": 1689790287000
    },
    {
        "time": 1689790288000,
        "value": 139.548,
        "_internal_originalTime": 1689790288000
    },
    {
        "time": 1689790289000,
        "value": 139.549,
        "_internal_originalTime": 1689790289000
    },
    {
        "time": 1689790291000,
        "value": 139.551,
        "_internal_originalTime": 1689790291000
    },
    {
        "time": 1689790293000,
        "value": 139.551,
        "_internal_originalTime": 1689790293000
    },
    {
        "time": 1689790296000,
        "value": 139.55,
        "_internal_originalTime": 1689790296000
    },
    {
        "time": 1689790297000,
        "value": 139.548,
        "_internal_originalTime": 1689790297000
    },
    {
        "time": 1689790298000,
        "value": 139.549,
        "_internal_originalTime": 1689790298000
    },
    {
        "time": 1689790299000,
        "value": 139.55,
        "_internal_originalTime": 1689790299000
    },
    {
        "time": 1689790300000,
        "value": 139.548,
        "_internal_originalTime": 1689790300000
    },
    {
        "time": 1689790301000,
        "value": 139.55,
        "_internal_originalTime": 1689790301000
    },
    {
        "time": 1689790302000,
        "value": 139.548,
        "_internal_originalTime": 1689790302000
    },
    {
        "time": 1689790304000,
        "value": 139.546,
        "_internal_originalTime": 1689790304000
    },
    {
        "time": 1689790305000,
        "value": 139.549,
        "_internal_originalTime": 1689790305000
    },
    {
        "time": 1689790306000,
        "value": 139.548,
        "_internal_originalTime": 1689790306000
    },
    {
        "time": 1689790307000,
        "value": 139.549,
        "_internal_originalTime": 1689790307000
    },
    {
        "time": 1689790309000,
        "value": 139.546,
        "_internal_originalTime": 1689790309000
    },
    {
        "time": 1689790310000,
        "value": 139.542,
        "_internal_originalTime": 1689790310000
    },
    {
        "time": 1689790312000,
        "value": 139.543,
        "_internal_originalTime": 1689790312000
    },
    {
        "time": 1689790314000,
        "value": 139.54,
        "_internal_originalTime": 1689790314000
    },
    {
        "time": 1689790316000,
        "value": 139.541,
        "_internal_originalTime": 1689790316000
    },
    {
        "time": 1689790318000,
        "value": 139.54,
        "_internal_originalTime": 1689790318000
    },
    {
        "time": 1689790319000,
        "value": 139.542,
        "_internal_originalTime": 1689790319000
    },
    {
        "time": 1689790320000,
        "value": 139.54,
        "_internal_originalTime": 1689790320000
    },
    {
        "time": 1689790321000,
        "value": 139.543,
        "_internal_originalTime": 1689790321000
    },
    {
        "time": 1689790322000,
        "value": 139.547,
        "_internal_originalTime": 1689790322000
    },
    {
        "time": 1689790323000,
        "value": 139.546,
        "_internal_originalTime": 1689790323000
    },
    {
        "time": 1689790324000,
        "value": 139.546,
        "_internal_originalTime": 1689790324000
    },
    {
        "time": 1689790325000,
        "value": 139.546,
        "_internal_originalTime": 1689790325000
    },
    {
        "time": 1689790326000,
        "value": 139.543,
        "_internal_originalTime": 1689790326000
    },
    {
        "time": 1689790328000,
        "value": 139.546,
        "_internal_originalTime": 1689790328000
    },
    {
        "time": 1689790329000,
        "value": 139.549,
        "_internal_originalTime": 1689790329000
    },
    {
        "time": 1689790330000,
        "value": 139.547,
        "_internal_originalTime": 1689790330000
    },
    {
        "time": 1689790331000,
        "value": 139.548,
        "_internal_originalTime": 1689790331000
    },
    {
        "time": 1689790333000,
        "value": 139.553,
        "_internal_originalTime": 1689790333000
    },
    {
        "time": 1689790334000,
        "value": 139.549,
        "_internal_originalTime": 1689790334000
    },
    {
        "time": 1689790335000,
        "value": 139.548,
        "_internal_originalTime": 1689790335000
    },
    {
        "time": 1689790336000,
        "value": 139.554,
        "_internal_originalTime": 1689790336000
    },
    {
        "time": 1689790337000,
        "value": 139.55,
        "_internal_originalTime": 1689790337000
    },
    {
        "time": 1689790338000,
        "value": 139.553,
        "_internal_originalTime": 1689790338000
    },
    {
        "time": 1689790339000,
        "value": 139.55,
        "_internal_originalTime": 1689790339000
    },
    {
        "time": 1689790340000,
        "value": 139.551,
        "_internal_originalTime": 1689790340000
    },
    {
        "time": 1689790341000,
        "value": 139.551,
        "_internal_originalTime": 1689790341000
    },
    {
        "time": 1689790343000,
        "value": 139.55,
        "_internal_originalTime": 1689790343000
    },
    {
        "time": 1689790345000,
        "value": 139.548,
        "_internal_originalTime": 1689790345000
    },
    {
        "time": 1689790346000,
        "value": 139.549,
        "_internal_originalTime": 1689790346000
    },
    {
        "time": 1689790348000,
        "value": 139.551,
        "_internal_originalTime": 1689790348000
    },
    {
        "time": 1689790349000,
        "value": 139.55,
        "_internal_originalTime": 1689790349000
    },
    {
        "time": 1689790351000,
        "value": 139.551,
        "_internal_originalTime": 1689790351000
    },
    {
        "time": 1689790352000,
        "value": 139.548,
        "_internal_originalTime": 1689790352000
    },
    {
        "time": 1689790353000,
        "value": 139.548,
        "_internal_originalTime": 1689790353000
    },
    {
        "time": 1689790354000,
        "value": 139.549,
        "_internal_originalTime": 1689790354000
    },
    {
        "time": 1689790356000,
        "value": 139.55,
        "_internal_originalTime": 1689790356000
    },
    {
        "time": 1689790357000,
        "value": 139.551,
        "_internal_originalTime": 1689790357000
    },
    {
        "time": 1689790358000,
        "value": 139.549,
        "_internal_originalTime": 1689790358000
    },
    {
        "time": 1689790359000,
        "value": 139.547,
        "_internal_originalTime": 1689790359000
    },
    {
        "time": 1689790360000,
        "value": 139.548,
        "_internal_originalTime": 1689790360000
    },
    {
        "time": 1689790362000,
        "value": 139.548,
        "_internal_originalTime": 1689790362000
    },
    {
        "time": 1689790365000,
        "value": 139.549,
        "_internal_originalTime": 1689790365000
    },
    {
        "time": 1689790366000,
        "value": 139.545,
        "_internal_originalTime": 1689790366000
    },
    {
        "time": 1689790367000,
        "value": 139.544,
        "_internal_originalTime": 1689790367000
    },
    {
        "time": 1689790370000,
        "value": 139.549,
        "_internal_originalTime": 1689790370000
    },
    {
        "time": 1689790371000,
        "value": 139.548,
        "_internal_originalTime": 1689790371000
    },
    {
        "time": 1689790373000,
        "value": 139.55,
        "_internal_originalTime": 1689790373000
    },
    {
        "time": 1689790375000,
        "value": 139.548,
        "_internal_originalTime": 1689790375000
    },
    {
        "time": 1689790376000,
        "value": 139.549,
        "_internal_originalTime": 1689790376000
    },
    {
        "time": 1689790377000,
        "value": 139.549,
        "_internal_originalTime": 1689790377000
    },
    {
        "time": 1689790379000,
        "value": 139.551,
        "_internal_originalTime": 1689790379000
    },
    {
        "time": 1689790381000,
        "value": 139.538,
        "_internal_originalTime": 1689790381000
    },
    {
        "time": 1689790383000,
        "value": 139.541,
        "_internal_originalTime": 1689790383000
    },
    {
        "time": 1689790384000,
        "value": 139.54,
        "_internal_originalTime": 1689790384000
    },
    {
        "time": 1689790385000,
        "value": 139.541,
        "_internal_originalTime": 1689790385000
    },
    {
        "time": 1689790388000,
        "value": 139.543,
        "_internal_originalTime": 1689790388000
    },
    {
        "time": 1689790389000,
        "value": 139.54,
        "_internal_originalTime": 1689790389000
    },
    {
        "time": 1689790390000,
        "value": 139.541,
        "_internal_originalTime": 1689790390000
    },
    {
        "time": 1689790392000,
        "value": 139.541,
        "_internal_originalTime": 1689790392000
    },
    {
        "time": 1689790393000,
        "value": 139.542,
        "_internal_originalTime": 1689790393000
    },
    {
        "time": 1689790394000,
        "value": 139.543,
        "_internal_originalTime": 1689790394000
    },
    {
        "time": 1689790397000,
        "value": 139.543,
        "_internal_originalTime": 1689790397000
    },
    {
        "time": 1689790398000,
        "value": 139.538,
        "_internal_originalTime": 1689790398000
    },
    {
        "time": 1689790400000,
        "value": 139.539,
        "_internal_originalTime": 1689790400000
    },
    {
        "time": 1689790401000,
        "value": 139.54,
        "_internal_originalTime": 1689790401000
    },
    {
        "time": 1689790403000,
        "value": 139.539,
        "_internal_originalTime": 1689790403000
    },
    {
        "time": 1689790404000,
        "value": 139.54,
        "_internal_originalTime": 1689790404000
    },
    {
        "time": 1689790405000,
        "value": 139.543,
        "_internal_originalTime": 1689790405000
    },
    {
        "time": 1689790406000,
        "value": 139.541,
        "_internal_originalTime": 1689790406000
    },
    {
        "time": 1689790408000,
        "value": 139.541,
        "_internal_originalTime": 1689790408000
    },
    {
        "time": 1689790409000,
        "value": 139.538,
        "_internal_originalTime": 1689790409000
    },
    {
        "time": 1689790410000,
        "value": 139.54,
        "_internal_originalTime": 1689790410000
    },
    {
        "time": 1689790411000,
        "value": 139.538,
        "_internal_originalTime": 1689790411000
    },
    {
        "time": 1689790412000,
        "value": 139.539,
        "_internal_originalTime": 1689790412000
    },
    {
        "time": 1689790413000,
        "value": 139.538,
        "_internal_originalTime": 1689790413000
    },
    {
        "time": 1689790415000,
        "value": 139.538,
        "_internal_originalTime": 1689790415000
    },
    {
        "time": 1689790416000,
        "value": 139.538,
        "_internal_originalTime": 1689790416000
    },
    {
        "time": 1689790417000,
        "value": 139.539,
        "_internal_originalTime": 1689790417000
    },
    {
        "time": 1689790418000,
        "value": 139.538,
        "_internal_originalTime": 1689790418000
    },
    {
        "time": 1689790419000,
        "value": 139.539,
        "_internal_originalTime": 1689790419000
    },
    {
        "time": 1689790420000,
        "value": 139.538,
        "_internal_originalTime": 1689790420000
    },
    {
        "time": 1689790421000,
        "value": 139.538,
        "_internal_originalTime": 1689790421000
    },
    {
        "time": 1689790424000,
        "value": 139.538,
        "_internal_originalTime": 1689790424000
    },
    {
        "time": 1689790425000,
        "value": 139.539,
        "_internal_originalTime": 1689790425000
    },
    {
        "time": 1689790426000,
        "value": 139.538,
        "_internal_originalTime": 1689790426000
    },
    {
        "time": 1689790427000,
        "value": 139.538,
        "_internal_originalTime": 1689790427000
    },
    {
        "time": 1689790428000,
        "value": 139.538,
        "_internal_originalTime": 1689790428000
    },
    {
        "time": 1689790430000,
        "value": 139.543,
        "_internal_originalTime": 1689790430000
    },
    {
        "time": 1689790431000,
        "value": 139.551,
        "_internal_originalTime": 1689790431000
    },
    {
        "time": 1689790432000,
        "value": 139.547,
        "_internal_originalTime": 1689790432000
    },
    {
        "time": 1689790434000,
        "value": 139.547,
        "_internal_originalTime": 1689790434000
    },
    {
        "time": 1689790435000,
        "value": 139.547,
        "_internal_originalTime": 1689790435000
    },
    {
        "time": 1689790436000,
        "value": 139.548,
        "_internal_originalTime": 1689790436000
    },
    {
        "time": 1689790438000,
        "value": 139.545,
        "_internal_originalTime": 1689790438000
    },
    {
        "time": 1689790439000,
        "value": 139.551,
        "_internal_originalTime": 1689790439000
    },
    {
        "time": 1689790441000,
        "value": 139.55,
        "_internal_originalTime": 1689790441000
    },
    {
        "time": 1689790442000,
        "value": 139.545,
        "_internal_originalTime": 1689790442000
    },
    {
        "time": 1689790444000,
        "value": 139.545,
        "_internal_originalTime": 1689790444000
    },
    {
        "time": 1689790446000,
        "value": 139.546,
        "_internal_originalTime": 1689790446000
    },
    {
        "time": 1689790447000,
        "value": 139.545,
        "_internal_originalTime": 1689790447000
    },
    {
        "time": 1689790448000,
        "value": 139.546,
        "_internal_originalTime": 1689790448000
    },
    {
        "time": 1689790449000,
        "value": 139.542,
        "_internal_originalTime": 1689790449000
    },
    {
        "time": 1689790453000,
        "value": 139.542,
        "_internal_originalTime": 1689790453000
    },
    {
        "time": 1689790454000,
        "value": 139.545,
        "_internal_originalTime": 1689790454000
    },
    {
        "time": 1689790455000,
        "value": 139.545,
        "_internal_originalTime": 1689790455000
    },
    {
        "time": 1689790456000,
        "value": 139.549,
        "_internal_originalTime": 1689790456000
    },
    {
        "time": 1689790458000,
        "value": 139.548,
        "_internal_originalTime": 1689790458000
    },
    {
        "time": 1689790459000,
        "value": 139.557,
        "_internal_originalTime": 1689790459000
    },
    {
        "time": 1689790460000,
        "value": 139.556,
        "_internal_originalTime": 1689790460000
    },
    {
        "time": 1689790461000,
        "value": 139.552,
        "_internal_originalTime": 1689790461000
    },
    {
        "time": 1689790462000,
        "value": 139.553,
        "_internal_originalTime": 1689790462000
    },
    {
        "time": 1689790464000,
        "value": 139.554,
        "_internal_originalTime": 1689790464000
    },
    {
        "time": 1689790466000,
        "value": 139.554,
        "_internal_originalTime": 1689790466000
    },
    {
        "time": 1689790467000,
        "value": 139.55,
        "_internal_originalTime": 1689790467000
    },
    {
        "time": 1689790468000,
        "value": 139.555,
        "_internal_originalTime": 1689790468000
    },
    {
        "time": 1689790469000,
        "value": 139.553,
        "_internal_originalTime": 1689790469000
    },
    {
        "time": 1689790470000,
        "value": 139.552,
        "_internal_originalTime": 1689790470000
    },
    {
        "time": 1689790472000,
        "value": 139.556,
        "_internal_originalTime": 1689790472000
    },
    {
        "time": 1689790473000,
        "value": 139.553,
        "_internal_originalTime": 1689790473000
    },
    {
        "time": 1689790474000,
        "value": 139.553,
        "_internal_originalTime": 1689790474000
    },
    {
        "time": 1689790478000,
        "value": 139.555,
        "_internal_originalTime": 1689790478000
    },
    {
        "time": 1689790485000,
        "value": 139.552,
        "_internal_originalTime": 1689790485000
    },
    {
        "time": 1689790487000,
        "value": 139.552,
        "_internal_originalTime": 1689790487000
    },
    {
        "time": 1689790488000,
        "value": 139.559,
        "_internal_originalTime": 1689790488000
    },
    {
        "time": 1689790489000,
        "value": 139.559,
        "_internal_originalTime": 1689790489000
    },
    {
        "time": 1689790491000,
        "value": 139.559,
        "_internal_originalTime": 1689790491000
    },
    {
        "time": 1689790492000,
        "value": 139.556,
        "_internal_originalTime": 1689790492000
    },
    {
        "time": 1689790493000,
        "value": 139.559,
        "_internal_originalTime": 1689790493000
    },
    {
        "time": 1689790494000,
        "value": 139.559,
        "_internal_originalTime": 1689790494000
    },
    {
        "time": 1689790496000,
        "value": 139.557,
        "_internal_originalTime": 1689790496000
    },
    {
        "time": 1689790498000,
        "value": 139.557,
        "_internal_originalTime": 1689790498000
    },
    {
        "time": 1689790499000,
        "value": 139.55,
        "_internal_originalTime": 1689790499000
    },
    {
        "time": 1689790500000,
        "value": 139.552,
        "_internal_originalTime": 1689790500000
    },
    {
        "time": 1689790501000,
        "value": 139.549,
        "_internal_originalTime": 1689790501000
    },
    {
        "time": 1689790504000,
        "value": 139.548,
        "_internal_originalTime": 1689790504000
    },
    {
        "time": 1689790506000,
        "value": 139.548,
        "_internal_originalTime": 1689790506000
    },
    {
        "time": 1689790507000,
        "value": 139.549,
        "_internal_originalTime": 1689790507000
    },
    {
        "time": 1689790508000,
        "value": 139.552,
        "_internal_originalTime": 1689790508000
    },
    {
        "time": 1689790509000,
        "value": 139.552,
        "_internal_originalTime": 1689790509000
    },
    {
        "time": 1689790511000,
        "value": 139.549,
        "_internal_originalTime": 1689790511000
    },
    {
        "time": 1689790513000,
        "value": 139.548,
        "_internal_originalTime": 1689790513000
    },
    {
        "time": 1689790514000,
        "value": 139.548,
        "_internal_originalTime": 1689790514000
    },
    {
        "time": 1689790516000,
        "value": 139.552,
        "_internal_originalTime": 1689790516000
    },
    {
        "time": 1689790517000,
        "value": 139.545,
        "_internal_originalTime": 1689790517000
    },
    {
        "time": 1689790518000,
        "value": 139.549,
        "_internal_originalTime": 1689790518000
    },
    {
        "time": 1689790519000,
        "value": 139.548,
        "_internal_originalTime": 1689790519000
    },
    {
        "time": 1689790523000,
        "value": 139.551,
        "_internal_originalTime": 1689790523000
    },
    {
        "time": 1689790524000,
        "value": 139.55,
        "_internal_originalTime": 1689790524000
    },
    {
        "time": 1689790526000,
        "value": 139.55,
        "_internal_originalTime": 1689790526000
    },
    {
        "time": 1689790527000,
        "value": 139.552,
        "_internal_originalTime": 1689790527000
    },
    {
        "time": 1689790528000,
        "value": 139.551,
        "_internal_originalTime": 1689790528000
    },
    {
        "time": 1689790529000,
        "value": 139.547,
        "_internal_originalTime": 1689790529000
    },
    {
        "time": 1689790530000,
        "value": 139.548,
        "_internal_originalTime": 1689790530000
    },
    {
        "time": 1689790531000,
        "value": 139.549,
        "_internal_originalTime": 1689790531000
    },
    {
        "time": 1689790534000,
        "value": 139.545,
        "_internal_originalTime": 1689790534000
    },
    {
        "time": 1689790536000,
        "value": 139.548,
        "_internal_originalTime": 1689790536000
    },
    {
        "time": 1689790538000,
        "value": 139.546,
        "_internal_originalTime": 1689790538000
    },
    {
        "time": 1689790539000,
        "value": 139.545,
        "_internal_originalTime": 1689790539000
    },
    {
        "time": 1689790541000,
        "value": 139.544,
        "_internal_originalTime": 1689790541000
    },
    {
        "time": 1689790542000,
        "value": 139.548,
        "_internal_originalTime": 1689790542000
    },
    {
        "time": 1689790546000,
        "value": 139.545,
        "_internal_originalTime": 1689790546000
    },
    {
        "time": 1689790548000,
        "value": 139.548,
        "_internal_originalTime": 1689790548000
    },
    {
        "time": 1689790550000,
        "value": 139.548,
        "_internal_originalTime": 1689790550000
    },
    {
        "time": 1689790551000,
        "value": 139.545,
        "_internal_originalTime": 1689790551000
    },
    {
        "time": 1689790553000,
        "value": 139.546,
        "_internal_originalTime": 1689790553000
    },
    {
        "time": 1689790554000,
        "value": 139.547,
        "_internal_originalTime": 1689790554000
    },
    {
        "time": 1689790556000,
        "value": 139.548,
        "_internal_originalTime": 1689790556000
    },
    {
        "time": 1689790560000,
        "value": 139.546,
        "_internal_originalTime": 1689790560000
    },
    {
        "time": 1689790562000,
        "value": 139.548,
        "_internal_originalTime": 1689790562000
    },
    {
        "time": 1689790564000,
        "value": 139.549,
        "_internal_originalTime": 1689790564000
    },
    {
        "time": 1689790565000,
        "value": 139.547,
        "_internal_originalTime": 1689790565000
    },
    {
        "time": 1689790566000,
        "value": 139.546,
        "_internal_originalTime": 1689790566000
    },
    {
        "time": 1689790568000,
        "value": 139.545,
        "_internal_originalTime": 1689790568000
    },
    {
        "time": 1689790569000,
        "value": 139.546,
        "_internal_originalTime": 1689790569000
    },
    {
        "time": 1689790575000,
        "value": 139.547,
        "_internal_originalTime": 1689790575000
    },
    {
        "time": 1689790577000,
        "value": 139.544,
        "_internal_originalTime": 1689790577000
    },
    {
        "time": 1689790579000,
        "value": 139.547,
        "_internal_originalTime": 1689790579000
    },
    {
        "time": 1689790581000,
        "value": 139.546,
        "_internal_originalTime": 1689790581000
    },
    {
        "time": 1689790582000,
        "value": 139.546,
        "_internal_originalTime": 1689790582000
    },
    {
        "time": 1689790585000,
        "value": 139.545,
        "_internal_originalTime": 1689790585000
    },
    {
        "time": 1689790589000,
        "value": 139.546,
        "_internal_originalTime": 1689790589000
    },
    {
        "time": 1689790590000,
        "value": 139.547,
        "_internal_originalTime": 1689790590000
    },
    {
        "time": 1689790591000,
        "value": 139.545,
        "_internal_originalTime": 1689790591000
    },
    {
        "time": 1689790595000,
        "value": 139.547,
        "_internal_originalTime": 1689790595000
    },
    {
        "time": 1689790598000,
        "value": 139.547,
        "_internal_originalTime": 1689790598000
    },
    {
        "time": 1689790603000,
        "value": 139.546,
        "_internal_originalTime": 1689790603000
    },
    {
        "time": 1689790604000,
        "value": 139.546,
        "_internal_originalTime": 1689790604000
    },
    {
        "time": 1689790608000,
        "value": 139.546,
        "_internal_originalTime": 1689790608000
    },
    {
        "time": 1689790609000,
        "value": 139.546,
        "_internal_originalTime": 1689790609000
    },
    {
        "time": 1689790611000,
        "value": 139.546,
        "_internal_originalTime": 1689790611000
    },
    {
        "time": 1689790614000,
        "value": 139.553,
        "_internal_originalTime": 1689790614000
    },
    {
        "time": 1689790618000,
        "value": 139.549,
        "_internal_originalTime": 1689790618000
    },
    {
        "time": 1689790619000,
        "value": 139.549,
        "_internal_originalTime": 1689790619000
    },
    {
        "time": 1689790620000,
        "value": 139.548,
        "_internal_originalTime": 1689790620000
    },
    {
        "time": 1689790622000,
        "value": 139.548,
        "_internal_originalTime": 1689790622000
    },
    {
        "time": 1689790623000,
        "value": 139.548,
        "_internal_originalTime": 1689790623000
    },
    {
        "time": 1689790624000,
        "value": 139.549,
        "_internal_originalTime": 1689790624000
    },
    {
        "time": 1689790625000,
        "value": 139.548,
        "_internal_originalTime": 1689790625000
    },
    {
        "time": 1689790626000,
        "value": 139.549,
        "_internal_originalTime": 1689790626000
    },
    {
        "time": 1689790627000,
        "value": 139.548,
        "_internal_originalTime": 1689790627000
    },
    {
        "time": 1689790629000,
        "value": 139.548,
        "_internal_originalTime": 1689790629000
    },
    {
        "time": 1689790630000,
        "value": 139.548,
        "_internal_originalTime": 1689790630000
    },
    {
        "time": 1689790632000,
        "value": 139.548,
        "_internal_originalTime": 1689790632000
    },
    {
        "time": 1689790633000,
        "value": 139.549,
        "_internal_originalTime": 1689790633000
    },
    {
        "time": 1689790635000,
        "value": 139.553,
        "_internal_originalTime": 1689790635000
    },
    {
        "time": 1689790637000,
        "value": 139.548,
        "_internal_originalTime": 1689790637000
    },
    {
        "time": 1689790638000,
        "value": 139.552,
        "_internal_originalTime": 1689790638000
    },
    {
        "time": 1689790647000,
        "value": 139.548,
        "_internal_originalTime": 1689790647000
    },
    {
        "time": 1689790648000,
        "value": 139.547,
        "_internal_originalTime": 1689790648000
    },
    {
        "time": 1689790649000,
        "value": 139.55,
        "_internal_originalTime": 1689790649000
    },
    {
        "time": 1689790650000,
        "value": 139.551,
        "_internal_originalTime": 1689790650000
    },
    {
        "time": 1689790651000,
        "value": 139.548,
        "_internal_originalTime": 1689790651000
    },
    {
        "time": 1689790652000,
        "value": 139.549,
        "_internal_originalTime": 1689790652000
    },
    {
        "time": 1689790653000,
        "value": 139.55,
        "_internal_originalTime": 1689790653000
    },
    {
        "time": 1689790655000,
        "value": 139.548,
        "_internal_originalTime": 1689790655000
    },
    {
        "time": 1689790656000,
        "value": 139.548,
        "_internal_originalTime": 1689790656000
    },
    {
        "time": 1689790657000,
        "value": 139.55,
        "_internal_originalTime": 1689790657000
    },
    {
        "time": 1689790658000,
        "value": 139.549,
        "_internal_originalTime": 1689790658000
    },
    {
        "time": 1689790661000,
        "value": 139.549,
        "_internal_originalTime": 1689790661000
    },
    {
        "time": 1689790662000,
        "value": 139.549,
        "_internal_originalTime": 1689790662000
    },
    {
        "time": 1689790664000,
        "value": 139.548,
        "_internal_originalTime": 1689790664000
    },
    {
        "time": 1689790665000,
        "value": 139.549,
        "_internal_originalTime": 1689790665000
    },
    {
        "time": 1689790667000,
        "value": 139.544,
        "_internal_originalTime": 1689790667000
    },
    {
        "time": 1689790670000,
        "value": 139.547,
        "_internal_originalTime": 1689790670000
    },
    {
        "time": 1689790671000,
        "value": 139.55,
        "_internal_originalTime": 1689790671000
    },
    {
        "time": 1689790672000,
        "value": 139.551,
        "_internal_originalTime": 1689790672000
    },
    {
        "time": 1689790673000,
        "value": 139.549,
        "_internal_originalTime": 1689790673000
    },
    {
        "time": 1689790675000,
        "value": 139.552,
        "_internal_originalTime": 1689790675000
    },
    {
        "time": 1689790676000,
        "value": 139.553,
        "_internal_originalTime": 1689790676000
    },
    {
        "time": 1689790679000,
        "value": 139.548,
        "_internal_originalTime": 1689790679000
    },
    {
        "time": 1689790680000,
        "value": 139.548,
        "_internal_originalTime": 1689790680000
    },
    {
        "time": 1689790683000,
        "value": 139.553,
        "_internal_originalTime": 1689790683000
    },
    {
        "time": 1689790687000,
        "value": 139.547,
        "_internal_originalTime": 1689790687000
    },
    {
        "time": 1689790688000,
        "value": 139.544,
        "_internal_originalTime": 1689790688000
    },
    {
        "time": 1689790689000,
        "value": 139.548,
        "_internal_originalTime": 1689790689000
    },
    {
        "time": 1689790690000,
        "value": 139.549,
        "_internal_originalTime": 1689790690000
    },
    {
        "time": 1689790692000,
        "value": 139.546,
        "_internal_originalTime": 1689790692000
    },
    {
        "time": 1689790695000,
        "value": 139.546,
        "_internal_originalTime": 1689790695000
    },
    {
        "time": 1689790696000,
        "value": 139.546,
        "_internal_originalTime": 1689790696000
    },
    {
        "time": 1689790697000,
        "value": 139.547,
        "_internal_originalTime": 1689790697000
    },
    {
        "time": 1689790698000,
        "value": 139.546,
        "_internal_originalTime": 1689790698000
    },
    {
        "time": 1689790702000,
        "value": 139.553,
        "_internal_originalTime": 1689790702000
    },
    {
        "time": 1689790703000,
        "value": 139.551,
        "_internal_originalTime": 1689790703000
    },
    {
        "time": 1689790704000,
        "value": 139.551,
        "_internal_originalTime": 1689790704000
    },
    {
        "time": 1689790706000,
        "value": 139.557,
        "_internal_originalTime": 1689790706000
    },
    {
        "time": 1689790708000,
        "value": 139.556,
        "_internal_originalTime": 1689790708000
    },
    {
        "time": 1689790710000,
        "value": 139.56,
        "_internal_originalTime": 1689790710000
    },
    {
        "time": 1689790711000,
        "value": 139.56,
        "_internal_originalTime": 1689790711000
    },
    {
        "time": 1689790713000,
        "value": 139.556,
        "_internal_originalTime": 1689790713000
    },
    {
        "time": 1689790715000,
        "value": 139.557,
        "_internal_originalTime": 1689790715000
    },
    {
        "time": 1689790718000,
        "value": 139.558,
        "_internal_originalTime": 1689790718000
    },
    {
        "time": 1689790719000,
        "value": 139.557,
        "_internal_originalTime": 1689790719000
    },
    {
        "time": 1689790722000,
        "value": 139.561,
        "_internal_originalTime": 1689790722000
    },
    {
        "time": 1689790723000,
        "value": 139.559,
        "_internal_originalTime": 1689790723000
    },
    {
        "time": 1689790724000,
        "value": 139.557,
        "_internal_originalTime": 1689790724000
    },
    {
        "time": 1689790726000,
        "value": 139.562,
        "_internal_originalTime": 1689790726000
    },
    {
        "time": 1689790727000,
        "value": 139.566,
        "_internal_originalTime": 1689790727000
    },
    {
        "time": 1689790728000,
        "value": 139.563,
        "_internal_originalTime": 1689790728000
    },
    {
        "time": 1689790730000,
        "value": 139.56,
        "_internal_originalTime": 1689790730000
    },
    {
        "time": 1689790731000,
        "value": 139.56,
        "_internal_originalTime": 1689790731000
    },
    {
        "time": 1689790733000,
        "value": 139.557,
        "_internal_originalTime": 1689790733000
    },
    {
        "time": 1689790734000,
        "value": 139.558,
        "_internal_originalTime": 1689790734000
    },
    {
        "time": 1689790736000,
        "value": 139.562,
        "_internal_originalTime": 1689790736000
    },
    {
        "time": 1689790737000,
        "value": 139.562,
        "_internal_originalTime": 1689790737000
    },
    {
        "time": 1689790738000,
        "value": 139.562,
        "_internal_originalTime": 1689790738000
    },
    {
        "time": 1689790740000,
        "value": 139.561,
        "_internal_originalTime": 1689790740000
    },
    {
        "time": 1689790741000,
        "value": 139.557,
        "_internal_originalTime": 1689790741000
    },
    {
        "time": 1689790742000,
        "value": 139.558,
        "_internal_originalTime": 1689790742000
    },
    {
        "time": 1689790743000,
        "value": 139.559,
        "_internal_originalTime": 1689790743000
    },
    {
        "time": 1689790746000,
        "value": 139.558,
        "_internal_originalTime": 1689790746000
    },
    {
        "time": 1689790747000,
        "value": 139.557,
        "_internal_originalTime": 1689790747000
    },
    {
        "time": 1689790748000,
        "value": 139.561,
        "_internal_originalTime": 1689790748000
    },
    {
        "time": 1689790749000,
        "value": 139.562,
        "_internal_originalTime": 1689790749000
    },
    {
        "time": 1689790750000,
        "value": 139.562,
        "_internal_originalTime": 1689790750000
    },
    {
        "time": 1689790751000,
        "value": 139.563,
        "_internal_originalTime": 1689790751000
    },
    {
        "time": 1689790753000,
        "value": 139.562,
        "_internal_originalTime": 1689790753000
    },
    {
        "time": 1689790754000,
        "value": 139.557,
        "_internal_originalTime": 1689790754000
    },
    {
        "time": 1689790755000,
        "value": 139.557,
        "_internal_originalTime": 1689790755000
    },
    {
        "time": 1689790756000,
        "value": 139.554,
        "_internal_originalTime": 1689790756000
    },
    {
        "time": 1689790758000,
        "value": 139.556,
        "_internal_originalTime": 1689790758000
    },
    {
        "time": 1689790759000,
        "value": 139.558,
        "_internal_originalTime": 1689790759000
    },
    {
        "time": 1689790760000,
        "value": 139.557,
        "_internal_originalTime": 1689790760000
    },
    {
        "time": 1689790762000,
        "value": 139.557,
        "_internal_originalTime": 1689790762000
    },
    {
        "time": 1689790763000,
        "value": 139.556,
        "_internal_originalTime": 1689790763000
    },
    {
        "time": 1689790764000,
        "value": 139.558,
        "_internal_originalTime": 1689790764000
    },
    {
        "time": 1689790766000,
        "value": 139.555,
        "_internal_originalTime": 1689790766000
    },
    {
        "time": 1689790767000,
        "value": 139.555,
        "_internal_originalTime": 1689790767000
    },
    {
        "time": 1689790768000,
        "value": 139.554,
        "_internal_originalTime": 1689790768000
    },
    {
        "time": 1689790770000,
        "value": 139.554,
        "_internal_originalTime": 1689790770000
    },
    {
        "time": 1689790771000,
        "value": 139.555,
        "_internal_originalTime": 1689790771000
    },
    {
        "time": 1689790772000,
        "value": 139.558,
        "_internal_originalTime": 1689790772000
    },
    {
        "time": 1689790773000,
        "value": 139.555,
        "_internal_originalTime": 1689790773000
    },
    {
        "time": 1689790775000,
        "value": 139.557,
        "_internal_originalTime": 1689790775000
    },
    {
        "time": 1689790776000,
        "value": 139.56,
        "_internal_originalTime": 1689790776000
    },
    {
        "time": 1689790778000,
        "value": 139.559,
        "_internal_originalTime": 1689790778000
    },
    {
        "time": 1689790779000,
        "value": 139.558,
        "_internal_originalTime": 1689790779000
    },
    {
        "time": 1689790780000,
        "value": 139.559,
        "_internal_originalTime": 1689790780000
    },
    {
        "time": 1689790781000,
        "value": 139.561,
        "_internal_originalTime": 1689790781000
    },
    {
        "time": 1689790782000,
        "value": 139.559,
        "_internal_originalTime": 1689790782000
    },
    {
        "time": 1689790784000,
        "value": 139.559,
        "_internal_originalTime": 1689790784000
    },
    {
        "time": 1689790785000,
        "value": 139.558,
        "_internal_originalTime": 1689790785000
    },
    {
        "time": 1689790786000,
        "value": 139.557,
        "_internal_originalTime": 1689790786000
    },
    {
        "time": 1689790787000,
        "value": 139.554,
        "_internal_originalTime": 1689790787000
    },
    {
        "time": 1689790789000,
        "value": 139.557,
        "_internal_originalTime": 1689790789000
    },
    {
        "time": 1689790793000,
        "value": 139.559,
        "_internal_originalTime": 1689790793000
    },
    {
        "time": 1689790800000,
        "value": 139.561,
        "_internal_originalTime": 1689790800000
    },
    {
        "time": 1689790802000,
        "value": 139.559,
        "_internal_originalTime": 1689790802000
    },
    {
        "time": 1689790804000,
        "value": 139.56,
        "_internal_originalTime": 1689790804000
    },
    {
        "time": 1689790805000,
        "value": 139.556,
        "_internal_originalTime": 1689790805000
    },
    {
        "time": 1689790807000,
        "value": 139.559,
        "_internal_originalTime": 1689790807000
    },
    {
        "time": 1689790808000,
        "value": 139.563,
        "_internal_originalTime": 1689790808000
    },
    {
        "time": 1689790809000,
        "value": 139.563,
        "_internal_originalTime": 1689790809000
    },
    {
        "time": 1689790811000,
        "value": 139.564,
        "_internal_originalTime": 1689790811000
    },
    {
        "time": 1689790813000,
        "value": 139.563,
        "_internal_originalTime": 1689790813000
    },
    {
        "time": 1689790814000,
        "value": 139.565,
        "_internal_originalTime": 1689790814000
    },
    {
        "time": 1689790815000,
        "value": 139.566,
        "_internal_originalTime": 1689790815000
    },
    {
        "time": 1689790816000,
        "value": 139.562,
        "_internal_originalTime": 1689790816000
    },
    {
        "time": 1689790818000,
        "value": 139.564,
        "_internal_originalTime": 1689790818000
    },
    {
        "time": 1689790819000,
        "value": 139.563,
        "_internal_originalTime": 1689790819000
    },
    {
        "time": 1689790820000,
        "value": 139.566,
        "_internal_originalTime": 1689790820000
    },
    {
        "time": 1689790821000,
        "value": 139.566,
        "_internal_originalTime": 1689790821000
    },
    {
        "time": 1689790822000,
        "value": 139.563,
        "_internal_originalTime": 1689790822000
    },
    {
        "time": 1689790824000,
        "value": 139.564,
        "_internal_originalTime": 1689790824000
    },
    {
        "time": 1689790825000,
        "value": 139.566,
        "_internal_originalTime": 1689790825000
    },
    {
        "time": 1689790828000,
        "value": 139.567,
        "_internal_originalTime": 1689790828000
    },
    {
        "time": 1689790829000,
        "value": 139.564,
        "_internal_originalTime": 1689790829000
    },
    {
        "time": 1689790831000,
        "value": 139.562,
        "_internal_originalTime": 1689790831000
    },
    {
        "time": 1689790832000,
        "value": 139.561,
        "_internal_originalTime": 1689790832000
    },
    {
        "time": 1689790833000,
        "value": 139.565,
        "_internal_originalTime": 1689790833000
    },
    {
        "time": 1689790834000,
        "value": 139.567,
        "_internal_originalTime": 1689790834000
    },
    {
        "time": 1689790835000,
        "value": 139.565,
        "_internal_originalTime": 1689790835000
    },
    {
        "time": 1689790836000,
        "value": 139.564,
        "_internal_originalTime": 1689790836000
    },
    {
        "time": 1689790838000,
        "value": 139.563,
        "_internal_originalTime": 1689790838000
    },
    {
        "time": 1689790839000,
        "value": 139.563,
        "_internal_originalTime": 1689790839000
    },
    {
        "time": 1689790840000,
        "value": 139.565,
        "_internal_originalTime": 1689790840000
    },
    {
        "time": 1689790842000,
        "value": 139.577,
        "_internal_originalTime": 1689790842000
    },
    {
        "time": 1689790843000,
        "value": 139.577,
        "_internal_originalTime": 1689790843000
    },
    {
        "time": 1689790845000,
        "value": 139.58,
        "_internal_originalTime": 1689790845000
    },
    {
        "time": 1689790846000,
        "value": 139.582,
        "_internal_originalTime": 1689790846000
    },
    {
        "time": 1689790847000,
        "value": 139.577,
        "_internal_originalTime": 1689790847000
    },
    {
        "time": 1689790849000,
        "value": 139.583,
        "_internal_originalTime": 1689790849000
    },
    {
        "time": 1689790850000,
        "value": 139.58,
        "_internal_originalTime": 1689790850000
    },
    {
        "time": 1689790851000,
        "value": 139.579,
        "_internal_originalTime": 1689790851000
    },
    {
        "time": 1689790852000,
        "value": 139.579,
        "_internal_originalTime": 1689790852000
    },
    {
        "time": 1689790853000,
        "value": 139.583,
        "_internal_originalTime": 1689790853000
    },
    {
        "time": 1689790855000,
        "value": 139.58,
        "_internal_originalTime": 1689790855000
    },
    {
        "time": 1689790856000,
        "value": 139.587,
        "_internal_originalTime": 1689790856000
    },
    {
        "time": 1689790858000,
        "value": 139.587,
        "_internal_originalTime": 1689790858000
    },
    {
        "time": 1689790859000,
        "value": 139.588,
        "_internal_originalTime": 1689790859000
    },
    {
        "time": 1689790864000,
        "value": 139.588,
        "_internal_originalTime": 1689790864000
    },
    {
        "time": 1689790867000,
        "value": 139.589,
        "_internal_originalTime": 1689790867000
    },
    {
        "time": 1689790868000,
        "value": 139.588,
        "_internal_originalTime": 1689790868000
    },
    {
        "time": 1689790869000,
        "value": 139.591,
        "_internal_originalTime": 1689790869000
    },
    {
        "time": 1689790870000,
        "value": 139.591,
        "_internal_originalTime": 1689790870000
    },
    {
        "time": 1689790872000,
        "value": 139.588,
        "_internal_originalTime": 1689790872000
    },
    {
        "time": 1689790874000,
        "value": 139.591,
        "_internal_originalTime": 1689790874000
    },
    {
        "time": 1689790875000,
        "value": 139.59,
        "_internal_originalTime": 1689790875000
    },
    {
        "time": 1689790876000,
        "value": 139.587,
        "_internal_originalTime": 1689790876000
    },
    {
        "time": 1689790877000,
        "value": 139.587,
        "_internal_originalTime": 1689790877000
    },
    {
        "time": 1689790881000,
        "value": 139.587,
        "_internal_originalTime": 1689790881000
    },
    {
        "time": 1689790882000,
        "value": 139.588,
        "_internal_originalTime": 1689790882000
    },
    {
        "time": 1689790883000,
        "value": 139.587,
        "_internal_originalTime": 1689790883000
    },
    {
        "time": 1689790885000,
        "value": 139.593,
        "_internal_originalTime": 1689790885000
    },
    {
        "time": 1689790886000,
        "value": 139.592,
        "_internal_originalTime": 1689790886000
    },
    {
        "time": 1689790887000,
        "value": 139.591,
        "_internal_originalTime": 1689790887000
    },
    {
        "time": 1689790889000,
        "value": 139.589,
        "_internal_originalTime": 1689790889000
    },
    {
        "time": 1689790890000,
        "value": 139.587,
        "_internal_originalTime": 1689790890000
    },
    {
        "time": 1689790891000,
        "value": 139.589,
        "_internal_originalTime": 1689790891000
    },
    {
        "time": 1689790893000,
        "value": 139.588,
        "_internal_originalTime": 1689790893000
    },
    {
        "time": 1689790895000,
        "value": 139.588,
        "_internal_originalTime": 1689790895000
    },
    {
        "time": 1689790896000,
        "value": 139.592,
        "_internal_originalTime": 1689790896000
    },
    {
        "time": 1689790898000,
        "value": 139.591,
        "_internal_originalTime": 1689790898000
    },
    {
        "time": 1689790901000,
        "value": 139.591,
        "_internal_originalTime": 1689790901000
    },
    {
        "time": 1689790903000,
        "value": 139.591,
        "_internal_originalTime": 1689790903000
    },
    {
        "time": 1689790904000,
        "value": 139.589,
        "_internal_originalTime": 1689790904000
    },
    {
        "time": 1689790905000,
        "value": 139.595,
        "_internal_originalTime": 1689790905000
    },
    {
        "time": 1689790906000,
        "value": 139.6,
        "_internal_originalTime": 1689790906000
    },
    {
        "time": 1689790907000,
        "value": 139.599,
        "_internal_originalTime": 1689790907000
    },
    {
        "time": 1689790908000,
        "value": 139.602,
        "_internal_originalTime": 1689790908000
    },
    {
        "time": 1689790910000,
        "value": 139.602,
        "_internal_originalTime": 1689790910000
    },
    {
        "time": 1689790911000,
        "value": 139.602,
        "_internal_originalTime": 1689790911000
    },
    {
        "time": 1689790913000,
        "value": 139.602,
        "_internal_originalTime": 1689790913000
    },
    {
        "time": 1689790917000,
        "value": 139.601,
        "_internal_originalTime": 1689790917000
    },
    {
        "time": 1689790918000,
        "value": 139.602,
        "_internal_originalTime": 1689790918000
    },
    {
        "time": 1689790920000,
        "value": 139.602,
        "_internal_originalTime": 1689790920000
    },
    {
        "time": 1689790921000,
        "value": 139.601,
        "_internal_originalTime": 1689790921000
    },
    {
        "time": 1689790922000,
        "value": 139.6,
        "_internal_originalTime": 1689790922000
    },
    {
        "time": 1689790923000,
        "value": 139.601,
        "_internal_originalTime": 1689790923000
    },
    {
        "time": 1689790924000,
        "value": 139.604,
        "_internal_originalTime": 1689790924000
    },
    {
        "time": 1689790926000,
        "value": 139.601,
        "_internal_originalTime": 1689790926000
    },
    {
        "time": 1689790928000,
        "value": 139.603,
        "_internal_originalTime": 1689790928000
    },
    {
        "time": 1689790929000,
        "value": 139.6,
        "_internal_originalTime": 1689790929000
    },
    {
        "time": 1689790931000,
        "value": 139.6,
        "_internal_originalTime": 1689790931000
    },
    {
        "time": 1689790932000,
        "value": 139.607,
        "_internal_originalTime": 1689790932000
    },
    {
        "time": 1689790933000,
        "value": 139.615,
        "_internal_originalTime": 1689790933000
    },
    {
        "time": 1689790934000,
        "value": 139.611,
        "_internal_originalTime": 1689790934000
    },
    {
        "time": 1689790936000,
        "value": 139.612,
        "_internal_originalTime": 1689790936000
    },
    {
        "time": 1689790937000,
        "value": 139.605,
        "_internal_originalTime": 1689790937000
    },
    {
        "time": 1689790939000,
        "value": 139.61,
        "_internal_originalTime": 1689790939000
    },
    {
        "time": 1689790940000,
        "value": 139.613,
        "_internal_originalTime": 1689790940000
    },
    {
        "time": 1689790941000,
        "value": 139.611,
        "_internal_originalTime": 1689790941000
    },
    {
        "time": 1689790942000,
        "value": 139.612,
        "_internal_originalTime": 1689790942000
    },
    {
        "time": 1689790947000,
        "value": 139.612,
        "_internal_originalTime": 1689790947000
    },
    {
        "time": 1689790948000,
        "value": 139.61,
        "_internal_originalTime": 1689790948000
    },
    {
        "time": 1689790955000,
        "value": 139.611,
        "_internal_originalTime": 1689790955000
    },
    {
        "time": 1689790957000,
        "value": 139.611,
        "_internal_originalTime": 1689790957000
    },
    {
        "time": 1689790958000,
        "value": 139.611,
        "_internal_originalTime": 1689790958000
    },
    {
        "time": 1689790961000,
        "value": 139.614,
        "_internal_originalTime": 1689790961000
    },
    {
        "time": 1689790962000,
        "value": 139.612,
        "_internal_originalTime": 1689790962000
    },
    {
        "time": 1689790964000,
        "value": 139.606,
        "_internal_originalTime": 1689790964000
    },
    {
        "time": 1689790965000,
        "value": 139.607,
        "_internal_originalTime": 1689790965000
    },
    {
        "time": 1689790966000,
        "value": 139.607,
        "_internal_originalTime": 1689790966000
    },
    {
        "time": 1689790967000,
        "value": 139.606,
        "_internal_originalTime": 1689790967000
    },
    {
        "time": 1689790969000,
        "value": 139.61,
        "_internal_originalTime": 1689790969000
    },
    {
        "time": 1689790970000,
        "value": 139.613,
        "_internal_originalTime": 1689790970000
    },
    {
        "time": 1689790971000,
        "value": 139.609,
        "_internal_originalTime": 1689790971000
    },
    {
        "time": 1689790974000,
        "value": 139.613,
        "_internal_originalTime": 1689790974000
    },
    {
        "time": 1689790975000,
        "value": 139.61,
        "_internal_originalTime": 1689790975000
    },
    {
        "time": 1689790976000,
        "value": 139.61,
        "_internal_originalTime": 1689790976000
    },
    {
        "time": 1689790978000,
        "value": 139.61,
        "_internal_originalTime": 1689790978000
    },
    {
        "time": 1689790979000,
        "value": 139.61,
        "_internal_originalTime": 1689790979000
    },
    {
        "time": 1689790980000,
        "value": 139.612,
        "_internal_originalTime": 1689790980000
    },
    {
        "time": 1689790982000,
        "value": 139.616,
        "_internal_originalTime": 1689790982000
    },
    {
        "time": 1689790983000,
        "value": 139.616,
        "_internal_originalTime": 1689790983000
    },
    {
        "time": 1689790984000,
        "value": 139.608,
        "_internal_originalTime": 1689790984000
    },
    {
        "time": 1689790985000,
        "value": 139.61,
        "_internal_originalTime": 1689790985000
    },
    {
        "time": 1689790986000,
        "value": 139.61,
        "_internal_originalTime": 1689790986000
    },
    {
        "time": 1689790988000,
        "value": 139.614,
        "_internal_originalTime": 1689790988000
    },
    {
        "time": 1689790989000,
        "value": 139.609,
        "_internal_originalTime": 1689790989000
    },
    {
        "time": 1689790990000,
        "value": 139.608,
        "_internal_originalTime": 1689790990000
    },
    {
        "time": 1689790992000,
        "value": 139.607,
        "_internal_originalTime": 1689790992000
    },
    {
        "time": 1689790994000,
        "value": 139.611,
        "_internal_originalTime": 1689790994000
    },
    {
        "time": 1689790995000,
        "value": 139.607,
        "_internal_originalTime": 1689790995000
    },
    {
        "time": 1689790996000,
        "value": 139.608,
        "_internal_originalTime": 1689790996000
    },
    {
        "time": 1689790997000,
        "value": 139.603,
        "_internal_originalTime": 1689790997000
    },
    {
        "time": 1689790999000,
        "value": 139.604,
        "_internal_originalTime": 1689790999000
    },
    {
        "time": 1689791001000,
        "value": 139.603,
        "_internal_originalTime": 1689791001000
    },
    {
        "time": 1689791002000,
        "value": 139.602,
        "_internal_originalTime": 1689791002000
    },
    {
        "time": 1689791003000,
        "value": 139.603,
        "_internal_originalTime": 1689791003000
    },
    {
        "time": 1689791008000,
        "value": 139.594,
        "_internal_originalTime": 1689791008000
    },
    {
        "time": 1689791009000,
        "value": 139.593,
        "_internal_originalTime": 1689791009000
    },
    {
        "time": 1689791010000,
        "value": 139.591,
        "_internal_originalTime": 1689791010000
    },
    {
        "time": 1689791012000,
        "value": 139.59,
        "_internal_originalTime": 1689791012000
    },
    {
        "time": 1689791013000,
        "value": 139.588,
        "_internal_originalTime": 1689791013000
    },
    {
        "time": 1689791018000,
        "value": 139.589,
        "_internal_originalTime": 1689791018000
    },
    {
        "time": 1689791019000,
        "value": 139.588,
        "_internal_originalTime": 1689791019000
    },
    {
        "time": 1689791022000,
        "value": 139.591,
        "_internal_originalTime": 1689791022000
    },
    {
        "time": 1689791024000,
        "value": 139.588,
        "_internal_originalTime": 1689791024000
    },
    {
        "time": 1689791025000,
        "value": 139.587,
        "_internal_originalTime": 1689791025000
    },
    {
        "time": 1689791027000,
        "value": 139.584,
        "_internal_originalTime": 1689791027000
    },
    {
        "time": 1689791028000,
        "value": 139.589,
        "_internal_originalTime": 1689791028000
    },
    {
        "time": 1689791029000,
        "value": 139.591,
        "_internal_originalTime": 1689791029000
    },
    {
        "time": 1689791030000,
        "value": 139.591,
        "_internal_originalTime": 1689791030000
    },
    {
        "time": 1689791031000,
        "value": 139.588,
        "_internal_originalTime": 1689791031000
    },
    {
        "time": 1689791039000,
        "value": 139.588,
        "_internal_originalTime": 1689791039000
    },
    {
        "time": 1689791041000,
        "value": 139.588,
        "_internal_originalTime": 1689791041000
    },
    {
        "time": 1689791044000,
        "value": 139.587,
        "_internal_originalTime": 1689791044000
    },
    {
        "time": 1689791046000,
        "value": 139.587,
        "_internal_originalTime": 1689791046000
    },
    {
        "time": 1689791048000,
        "value": 139.59,
        "_internal_originalTime": 1689791048000
    },
    {
        "time": 1689791050000,
        "value": 139.591,
        "_internal_originalTime": 1689791050000
    },
    {
        "time": 1689791052000,
        "value": 139.59,
        "_internal_originalTime": 1689791052000
    },
    {
        "time": 1689791053000,
        "value": 139.587,
        "_internal_originalTime": 1689791053000
    },
    {
        "time": 1689791054000,
        "value": 139.587,
        "_internal_originalTime": 1689791054000
    },
    {
        "time": 1689791055000,
        "value": 139.59,
        "_internal_originalTime": 1689791055000
    },
    {
        "time": 1689791057000,
        "value": 139.584,
        "_internal_originalTime": 1689791057000
    },
    {
        "time": 1689791061000,
        "value": 139.588,
        "_internal_originalTime": 1689791061000
    },
    {
        "time": 1689791062000,
        "value": 139.59,
        "_internal_originalTime": 1689791062000
    },
    {
        "time": 1689791063000,
        "value": 139.59,
        "_internal_originalTime": 1689791063000
    },
    {
        "time": 1689791065000,
        "value": 139.59,
        "_internal_originalTime": 1689791065000
    },
    {
        "time": 1689791067000,
        "value": 139.59,
        "_internal_originalTime": 1689791067000
    },
    {
        "time": 1689791069000,
        "value": 139.587,
        "_internal_originalTime": 1689791069000
    },
    {
        "time": 1689791070000,
        "value": 139.588,
        "_internal_originalTime": 1689791070000
    },
    {
        "time": 1689791071000,
        "value": 139.588,
        "_internal_originalTime": 1689791071000
    },
    {
        "time": 1689791072000,
        "value": 139.587,
        "_internal_originalTime": 1689791072000
    },
    {
        "time": 1689791074000,
        "value": 139.583,
        "_internal_originalTime": 1689791074000
    },
    {
        "time": 1689791075000,
        "value": 139.578,
        "_internal_originalTime": 1689791075000
    },
    {
        "time": 1689791076000,
        "value": 139.579,
        "_internal_originalTime": 1689791076000
    },
    {
        "time": 1689791078000,
        "value": 139.579,
        "_internal_originalTime": 1689791078000
    },
    {
        "time": 1689791081000,
        "value": 139.582,
        "_internal_originalTime": 1689791081000
    },
    {
        "time": 1689791085000,
        "value": 139.581,
        "_internal_originalTime": 1689791085000
    },
    {
        "time": 1689791087000,
        "value": 139.577,
        "_internal_originalTime": 1689791087000
    },
    {
        "time": 1689791089000,
        "value": 139.581,
        "_internal_originalTime": 1689791089000
    },
    {
        "time": 1689791090000,
        "value": 139.582,
        "_internal_originalTime": 1689791090000
    },
    {
        "time": 1689791091000,
        "value": 139.581,
        "_internal_originalTime": 1689791091000
    },
    {
        "time": 1689791092000,
        "value": 139.582,
        "_internal_originalTime": 1689791092000
    },
    {
        "time": 1689791094000,
        "value": 139.577,
        "_internal_originalTime": 1689791094000
    },
    {
        "time": 1689791095000,
        "value": 139.581,
        "_internal_originalTime": 1689791095000
    },
    {
        "time": 1689791099000,
        "value": 139.579,
        "_internal_originalTime": 1689791099000
    },
    {
        "time": 1689791101000,
        "value": 139.58,
        "_internal_originalTime": 1689791101000
    },
    {
        "time": 1689791108000,
        "value": 139.584,
        "_internal_originalTime": 1689791108000
    },
    {
        "time": 1689791109000,
        "value": 139.585,
        "_internal_originalTime": 1689791109000
    },
    {
        "time": 1689791115000,
        "value": 139.588,
        "_internal_originalTime": 1689791115000
    },
    {
        "time": 1689791116000,
        "value": 139.587,
        "_internal_originalTime": 1689791116000
    },
    {
        "time": 1689791117000,
        "value": 139.585,
        "_internal_originalTime": 1689791117000
    },
    {
        "time": 1689791118000,
        "value": 139.585,
        "_internal_originalTime": 1689791118000
    },
    {
        "time": 1689791120000,
        "value": 139.586,
        "_internal_originalTime": 1689791120000
    },
    {
        "time": 1689791121000,
        "value": 139.587,
        "_internal_originalTime": 1689791121000
    },
    {
        "time": 1689791122000,
        "value": 139.59,
        "_internal_originalTime": 1689791122000
    },
    {
        "time": 1689791123000,
        "value": 139.583,
        "_internal_originalTime": 1689791123000
    },
    {
        "time": 1689791124000,
        "value": 139.584,
        "_internal_originalTime": 1689791124000
    },
    {
        "time": 1689791126000,
        "value": 139.581,
        "_internal_originalTime": 1689791126000
    },
    {
        "time": 1689791130000,
        "value": 139.58,
        "_internal_originalTime": 1689791130000
    },
    {
        "time": 1689791131000,
        "value": 139.581,
        "_internal_originalTime": 1689791131000
    },
    {
        "time": 1689791132000,
        "value": 139.58,
        "_internal_originalTime": 1689791132000
    },
    {
        "time": 1689791133000,
        "value": 139.581,
        "_internal_originalTime": 1689791133000
    },
    {
        "time": 1689791134000,
        "value": 139.57,
        "_internal_originalTime": 1689791134000
    },
    {
        "time": 1689791137000,
        "value": 139.571,
        "_internal_originalTime": 1689791137000
    },
    {
        "time": 1689791138000,
        "value": 139.573,
        "_internal_originalTime": 1689791138000
    },
    {
        "time": 1689791139000,
        "value": 139.569,
        "_internal_originalTime": 1689791139000
    },
    {
        "time": 1689791140000,
        "value": 139.568,
        "_internal_originalTime": 1689791140000
    },
    {
        "time": 1689791143000,
        "value": 139.568,
        "_internal_originalTime": 1689791143000
    },
    {
        "time": 1689791145000,
        "value": 139.574,
        "_internal_originalTime": 1689791145000
    },
    {
        "time": 1689791147000,
        "value": 139.571,
        "_internal_originalTime": 1689791147000
    },
    {
        "time": 1689791149000,
        "value": 139.571,
        "_internal_originalTime": 1689791149000
    },
    {
        "time": 1689791152000,
        "value": 139.573,
        "_internal_originalTime": 1689791152000
    },
    {
        "time": 1689791157000,
        "value": 139.579,
        "_internal_originalTime": 1689791157000
    },
    {
        "time": 1689791158000,
        "value": 139.579,
        "_internal_originalTime": 1689791158000
    },
    {
        "time": 1689791159000,
        "value": 139.577,
        "_internal_originalTime": 1689791159000
    },
    {
        "time": 1689791160000,
        "value": 139.579,
        "_internal_originalTime": 1689791160000
    },
    {
        "time": 1689791161000,
        "value": 139.58,
        "_internal_originalTime": 1689791161000
    },
    {
        "time": 1689791162000,
        "value": 139.584,
        "_internal_originalTime": 1689791162000
    },
    {
        "time": 1689791163000,
        "value": 139.586,
        "_internal_originalTime": 1689791163000
    },
    {
        "time": 1689791164000,
        "value": 139.586,
        "_internal_originalTime": 1689791164000
    },
    {
        "time": 1689791167000,
        "value": 139.583,
        "_internal_originalTime": 1689791167000
    },
    {
        "time": 1689791169000,
        "value": 139.582,
        "_internal_originalTime": 1689791169000
    },
    {
        "time": 1689791170000,
        "value": 139.585,
        "_internal_originalTime": 1689791170000
    },
    {
        "time": 1689791173000,
        "value": 139.587,
        "_internal_originalTime": 1689791173000
    },
    {
        "time": 1689791174000,
        "value": 139.59,
        "_internal_originalTime": 1689791174000
    },
    {
        "time": 1689791177000,
        "value": 139.583,
        "_internal_originalTime": 1689791177000
    },
    {
        "time": 1689791180000,
        "value": 139.584,
        "_internal_originalTime": 1689791180000
    },
    {
        "time": 1689791182000,
        "value": 139.59,
        "_internal_originalTime": 1689791182000
    },
    {
        "time": 1689791186000,
        "value": 139.588,
        "_internal_originalTime": 1689791186000
    },
    {
        "time": 1689791188000,
        "value": 139.581,
        "_internal_originalTime": 1689791188000
    },
    {
        "time": 1689791189000,
        "value": 139.577,
        "_internal_originalTime": 1689791189000
    },
    {
        "time": 1689791191000,
        "value": 139.579,
        "_internal_originalTime": 1689791191000
    },
    {
        "time": 1689791192000,
        "value": 139.586,
        "_internal_originalTime": 1689791192000
    },
    {
        "time": 1689791193000,
        "value": 139.587,
        "_internal_originalTime": 1689791193000
    },
    {
        "time": 1689791194000,
        "value": 139.583,
        "_internal_originalTime": 1689791194000
    },
    {
        "time": 1689791195000,
        "value": 139.583,
        "_internal_originalTime": 1689791195000
    },
    {
        "time": 1689791198000,
        "value": 139.583,
        "_internal_originalTime": 1689791198000
    },
    {
        "time": 1689791202000,
        "value": 139.583,
        "_internal_originalTime": 1689791202000
    },
    {
        "time": 1689791203000,
        "value": 139.583,
        "_internal_originalTime": 1689791203000
    },
    {
        "time": 1689791204000,
        "value": 139.585,
        "_internal_originalTime": 1689791204000
    },
    {
        "time": 1689791205000,
        "value": 139.579,
        "_internal_originalTime": 1689791205000
    },
    {
        "time": 1689791206000,
        "value": 139.583,
        "_internal_originalTime": 1689791206000
    },
    {
        "time": 1689791207000,
        "value": 139.577,
        "_internal_originalTime": 1689791207000
    },
    {
        "time": 1689791210000,
        "value": 139.579,
        "_internal_originalTime": 1689791210000
    },
    {
        "time": 1689791211000,
        "value": 139.578,
        "_internal_originalTime": 1689791211000
    },
    {
        "time": 1689791212000,
        "value": 139.583,
        "_internal_originalTime": 1689791212000
    },
    {
        "time": 1689791213000,
        "value": 139.583,
        "_internal_originalTime": 1689791213000
    },
    {
        "time": 1689791215000,
        "value": 139.583,
        "_internal_originalTime": 1689791215000
    },
    {
        "time": 1689791216000,
        "value": 139.582,
        "_internal_originalTime": 1689791216000
    },
    {
        "time": 1689791218000,
        "value": 139.579,
        "_internal_originalTime": 1689791218000
    },
    {
        "time": 1689791223000,
        "value": 139.577,
        "_internal_originalTime": 1689791223000
    },
    {
        "time": 1689791228000,
        "value": 139.577,
        "_internal_originalTime": 1689791228000
    },
    {
        "time": 1689791229000,
        "value": 139.58,
        "_internal_originalTime": 1689791229000
    },
    {
        "time": 1689791231000,
        "value": 139.575,
        "_internal_originalTime": 1689791231000
    },
    {
        "time": 1689791232000,
        "value": 139.577,
        "_internal_originalTime": 1689791232000
    },
    {
        "time": 1689791234000,
        "value": 139.576,
        "_internal_originalTime": 1689791234000
    },
    {
        "time": 1689791237000,
        "value": 139.574,
        "_internal_originalTime": 1689791237000
    },
    {
        "time": 1689791242000,
        "value": 139.575,
        "_internal_originalTime": 1689791242000
    },
    {
        "time": 1689791243000,
        "value": 139.58,
        "_internal_originalTime": 1689791243000
    },
    {
        "time": 1689791245000,
        "value": 139.575,
        "_internal_originalTime": 1689791245000
    },
    {
        "time": 1689791248000,
        "value": 139.576,
        "_internal_originalTime": 1689791248000
    },
    {
        "time": 1689791249000,
        "value": 139.575,
        "_internal_originalTime": 1689791249000
    },
    {
        "time": 1689791259000,
        "value": 139.576,
        "_internal_originalTime": 1689791259000
    },
    {
        "time": 1689791261000,
        "value": 139.569,
        "_internal_originalTime": 1689791261000
    },
    {
        "time": 1689791262000,
        "value": 139.565,
        "_internal_originalTime": 1689791262000
    },
    {
        "time": 1689791263000,
        "value": 139.572,
        "_internal_originalTime": 1689791263000
    },
    {
        "time": 1689791265000,
        "value": 139.572,
        "_internal_originalTime": 1689791265000
    },
    {
        "time": 1689791267000,
        "value": 139.572,
        "_internal_originalTime": 1689791267000
    },
    {
        "time": 1689791268000,
        "value": 139.574,
        "_internal_originalTime": 1689791268000
    },
    {
        "time": 1689791270000,
        "value": 139.575,
        "_internal_originalTime": 1689791270000
    },
    {
        "time": 1689791271000,
        "value": 139.574,
        "_internal_originalTime": 1689791271000
    },
    {
        "time": 1689791272000,
        "value": 139.576,
        "_internal_originalTime": 1689791272000
    },
    {
        "time": 1689791276000,
        "value": 139.571,
        "_internal_originalTime": 1689791276000
    },
    {
        "time": 1689791278000,
        "value": 139.578,
        "_internal_originalTime": 1689791278000
    },
    {
        "time": 1689791280000,
        "value": 139.573,
        "_internal_originalTime": 1689791280000
    },
    {
        "time": 1689791281000,
        "value": 139.572,
        "_internal_originalTime": 1689791281000
    },
    {
        "time": 1689791282000,
        "value": 139.573,
        "_internal_originalTime": 1689791282000
    },
    {
        "time": 1689791284000,
        "value": 139.572,
        "_internal_originalTime": 1689791284000
    },
    {
        "time": 1689791286000,
        "value": 139.57,
        "_internal_originalTime": 1689791286000
    },
    {
        "time": 1689791288000,
        "value": 139.568,
        "_internal_originalTime": 1689791288000
    },
    {
        "time": 1689791290000,
        "value": 139.567,
        "_internal_originalTime": 1689791290000
    },
    {
        "time": 1689791291000,
        "value": 139.566,
        "_internal_originalTime": 1689791291000
    },
    {
        "time": 1689791293000,
        "value": 139.571,
        "_internal_originalTime": 1689791293000
    },
    {
        "time": 1689791294000,
        "value": 139.573,
        "_internal_originalTime": 1689791294000
    },
    {
        "time": 1689791296000,
        "value": 139.573,
        "_internal_originalTime": 1689791296000
    },
    {
        "time": 1689791297000,
        "value": 139.568,
        "_internal_originalTime": 1689791297000
    },
    {
        "time": 1689791304000,
        "value": 139.571,
        "_internal_originalTime": 1689791304000
    },
    {
        "time": 1689791305000,
        "value": 139.573,
        "_internal_originalTime": 1689791305000
    },
    {
        "time": 1689791307000,
        "value": 139.575,
        "_internal_originalTime": 1689791307000
    },
    {
        "time": 1689791310000,
        "value": 139.57,
        "_internal_originalTime": 1689791310000
    },
    {
        "time": 1689791311000,
        "value": 139.573,
        "_internal_originalTime": 1689791311000
    },
    {
        "time": 1689791315000,
        "value": 139.574,
        "_internal_originalTime": 1689791315000
    },
    {
        "time": 1689791316000,
        "value": 139.574,
        "_internal_originalTime": 1689791316000
    },
    {
        "time": 1689791318000,
        "value": 139.57,
        "_internal_originalTime": 1689791318000
    },
    {
        "time": 1689791323000,
        "value": 139.573,
        "_internal_originalTime": 1689791323000
    },
    {
        "time": 1689791327000,
        "value": 139.568,
        "_internal_originalTime": 1689791327000
    },
    {
        "time": 1689791328000,
        "value": 139.569,
        "_internal_originalTime": 1689791328000
    },
    {
        "time": 1689791331000,
        "value": 139.569,
        "_internal_originalTime": 1689791331000
    },
    {
        "time": 1689791332000,
        "value": 139.575,
        "_internal_originalTime": 1689791332000
    },
    {
        "time": 1689791333000,
        "value": 139.575,
        "_internal_originalTime": 1689791333000
    },
    {
        "time": 1689791334000,
        "value": 139.573,
        "_internal_originalTime": 1689791334000
    },
    {
        "time": 1689791338000,
        "value": 139.574,
        "_internal_originalTime": 1689791338000
    },
    {
        "time": 1689791339000,
        "value": 139.573,
        "_internal_originalTime": 1689791339000
    },
    {
        "time": 1689791340000,
        "value": 139.573,
        "_internal_originalTime": 1689791340000
    },
    {
        "time": 1689791341000,
        "value": 139.568,
        "_internal_originalTime": 1689791341000
    },
    {
        "time": 1689791342000,
        "value": 139.568,
        "_internal_originalTime": 1689791342000
    },
    {
        "time": 1689791344000,
        "value": 139.568,
        "_internal_originalTime": 1689791344000
    },
    {
        "time": 1689791346000,
        "value": 139.568,
        "_internal_originalTime": 1689791346000
    },
    {
        "time": 1689791347000,
        "value": 139.568,
        "_internal_originalTime": 1689791347000
    },
    {
        "time": 1689791348000,
        "value": 139.568,
        "_internal_originalTime": 1689791348000
    },
    {
        "time": 1689791349000,
        "value": 139.568,
        "_internal_originalTime": 1689791349000
    },
    {
        "time": 1689791352000,
        "value": 139.567,
        "_internal_originalTime": 1689791352000
    },
    {
        "time": 1689791354000,
        "value": 139.568,
        "_internal_originalTime": 1689791354000
    },
    {
        "time": 1689791355000,
        "value": 139.571,
        "_internal_originalTime": 1689791355000
    },
    {
        "time": 1689791357000,
        "value": 139.566,
        "_internal_originalTime": 1689791357000
    },
    {
        "time": 1689791361000,
        "value": 139.569,
        "_internal_originalTime": 1689791361000
    },
    {
        "time": 1689791362000,
        "value": 139.57,
        "_internal_originalTime": 1689791362000
    },
    {
        "time": 1689791365000,
        "value": 139.569,
        "_internal_originalTime": 1689791365000
    },
    {
        "time": 1689791369000,
        "value": 139.565,
        "_internal_originalTime": 1689791369000
    },
    {
        "time": 1689791371000,
        "value": 139.57,
        "_internal_originalTime": 1689791371000
    },
    {
        "time": 1689791372000,
        "value": 139.571,
        "_internal_originalTime": 1689791372000
    },
    {
        "time": 1689791375000,
        "value": 139.566,
        "_internal_originalTime": 1689791375000
    },
    {
        "time": 1689791377000,
        "value": 139.566,
        "_internal_originalTime": 1689791377000
    },
    {
        "time": 1689791378000,
        "value": 139.566,
        "_internal_originalTime": 1689791378000
    },
    {
        "time": 1689791379000,
        "value": 139.565,
        "_internal_originalTime": 1689791379000
    },
    {
        "time": 1689791381000,
        "value": 139.566,
        "_internal_originalTime": 1689791381000
    },
    {
        "time": 1689791382000,
        "value": 139.571,
        "_internal_originalTime": 1689791382000
    },
    {
        "time": 1689791383000,
        "value": 139.566,
        "_internal_originalTime": 1689791383000
    },
    {
        "time": 1689791384000,
        "value": 139.565,
        "_internal_originalTime": 1689791384000
    },
    {
        "time": 1689791387000,
        "value": 139.571,
        "_internal_originalTime": 1689791387000
    },
    {
        "time": 1689791391000,
        "value": 139.567,
        "_internal_originalTime": 1689791391000
    },
    {
        "time": 1689791392000,
        "value": 139.571,
        "_internal_originalTime": 1689791392000
    },
    {
        "time": 1689791394000,
        "value": 139.566,
        "_internal_originalTime": 1689791394000
    },
    {
        "time": 1689791395000,
        "value": 139.565,
        "_internal_originalTime": 1689791395000
    },
    {
        "time": 1689791400000,
        "value": 139.574,
        "_internal_originalTime": 1689791400000
    },
    {
        "time": 1689791401000,
        "value": 139.57,
        "_internal_originalTime": 1689791401000
    },
    {
        "time": 1689791402000,
        "value": 139.574,
        "_internal_originalTime": 1689791402000
    },
    {
        "time": 1689791403000,
        "value": 139.576,
        "_internal_originalTime": 1689791403000
    },
    {
        "time": 1689791404000,
        "value": 139.576,
        "_internal_originalTime": 1689791404000
    },
    {
        "time": 1689791405000,
        "value": 139.57,
        "_internal_originalTime": 1689791405000
    },
    {
        "time": 1689791406000,
        "value": 139.57,
        "_internal_originalTime": 1689791406000
    },
    {
        "time": 1689791408000,
        "value": 139.574,
        "_internal_originalTime": 1689791408000
    },
    {
        "time": 1689791409000,
        "value": 139.574,
        "_internal_originalTime": 1689791409000
    },
    {
        "time": 1689791412000,
        "value": 139.574,
        "_internal_originalTime": 1689791412000
    },
    {
        "time": 1689791415000,
        "value": 139.574,
        "_internal_originalTime": 1689791415000
    },
    {
        "time": 1689791416000,
        "value": 139.575,
        "_internal_originalTime": 1689791416000
    },
    {
        "time": 1689791417000,
        "value": 139.569,
        "_internal_originalTime": 1689791417000
    },
    {
        "time": 1689791418000,
        "value": 139.575,
        "_internal_originalTime": 1689791418000
    },
    {
        "time": 1689791419000,
        "value": 139.576,
        "_internal_originalTime": 1689791419000
    },
    {
        "time": 1689791421000,
        "value": 139.57,
        "_internal_originalTime": 1689791421000
    },
    {
        "time": 1689791422000,
        "value": 139.577,
        "_internal_originalTime": 1689791422000
    },
    {
        "time": 1689791423000,
        "value": 139.577,
        "_internal_originalTime": 1689791423000
    },
    {
        "time": 1689791424000,
        "value": 139.579,
        "_internal_originalTime": 1689791424000
    },
    {
        "time": 1689791426000,
        "value": 139.581,
        "_internal_originalTime": 1689791426000
    },
    {
        "time": 1689791427000,
        "value": 139.583,
        "_internal_originalTime": 1689791427000
    },
    {
        "time": 1689791428000,
        "value": 139.582,
        "_internal_originalTime": 1689791428000
    },
    {
        "time": 1689791432000,
        "value": 139.577,
        "_internal_originalTime": 1689791432000
    },
    {
        "time": 1689791434000,
        "value": 139.58,
        "_internal_originalTime": 1689791434000
    },
    {
        "time": 1689791435000,
        "value": 139.581,
        "_internal_originalTime": 1689791435000
    },
    {
        "time": 1689791438000,
        "value": 139.58,
        "_internal_originalTime": 1689791438000
    },
    {
        "time": 1689791444000,
        "value": 139.58,
        "_internal_originalTime": 1689791444000
    },
    {
        "time": 1689791447000,
        "value": 139.574,
        "_internal_originalTime": 1689791447000
    },
    {
        "time": 1689791463000,
        "value": 139.576,
        "_internal_originalTime": 1689791463000
    },
    {
        "time": 1689791464000,
        "value": 139.577,
        "_internal_originalTime": 1689791464000
    },
    {
        "time": 1689791468000,
        "value": 139.579,
        "_internal_originalTime": 1689791468000
    },
    {
        "time": 1689791477000,
        "value": 139.574,
        "_internal_originalTime": 1689791477000
    },
    {
        "time": 1689791480000,
        "value": 139.586,
        "_internal_originalTime": 1689791480000
    },
    {
        "time": 1689791481000,
        "value": 139.588,
        "_internal_originalTime": 1689791481000
    },
    {
        "time": 1689791482000,
        "value": 139.585,
        "_internal_originalTime": 1689791482000
    },
    {
        "time": 1689791483000,
        "value": 139.584,
        "_internal_originalTime": 1689791483000
    },
    {
        "time": 1689791484000,
        "value": 139.585,
        "_internal_originalTime": 1689791484000
    },
    {
        "time": 1689791489000,
        "value": 139.584,
        "_internal_originalTime": 1689791489000
    },
    {
        "time": 1689791494000,
        "value": 139.584,
        "_internal_originalTime": 1689791494000
    },
    {
        "time": 1689791495000,
        "value": 139.585,
        "_internal_originalTime": 1689791495000
    },
    {
        "time": 1689791498000,
        "value": 139.586,
        "_internal_originalTime": 1689791498000
    },
    {
        "time": 1689791502000,
        "value": 139.584,
        "_internal_originalTime": 1689791502000
    },
    {
        "time": 1689791507000,
        "value": 139.583,
        "_internal_originalTime": 1689791507000
    },
    {
        "time": 1689791514000,
        "value": 139.585,
        "_internal_originalTime": 1689791514000
    },
    {
        "time": 1689791515000,
        "value": 139.584,
        "_internal_originalTime": 1689791515000
    },
    {
        "time": 1689791519000,
        "value": 139.585,
        "_internal_originalTime": 1689791519000
    },
    {
        "time": 1689791525000,
        "value": 139.584,
        "_internal_originalTime": 1689791525000
    },
    {
        "time": 1689791528000,
        "value": 139.586,
        "_internal_originalTime": 1689791528000
    },
    {
        "time": 1689791538000,
        "value": 139.583,
        "_internal_originalTime": 1689791538000
    },
    {
        "time": 1689791545000,
        "value": 139.587,
        "_internal_originalTime": 1689791545000
    },
    {
        "time": 1689791558000,
        "value": 139.587,
        "_internal_originalTime": 1689791558000
    },
    {
        "time": 1689791563000,
        "value": 139.589,
        "_internal_originalTime": 1689791563000
    },
    {
        "time": 1689791564000,
        "value": 139.59,
        "_internal_originalTime": 1689791564000
    },
    {
        "time": 1689791566000,
        "value": 139.59,
        "_internal_originalTime": 1689791566000
    },
    {
        "time": 1689791568000,
        "value": 139.584,
        "_internal_originalTime": 1689791568000
    },
    {
        "time": 1689791571000,
        "value": 139.587,
        "_internal_originalTime": 1689791571000
    },
    {
        "time": 1689791572000,
        "value": 139.585,
        "_internal_originalTime": 1689791572000
    },
    {
        "time": 1689791588000,
        "value": 139.586,
        "_internal_originalTime": 1689791588000
    },
    {
        "time": 1689791589000,
        "value": 139.584,
        "_internal_originalTime": 1689791589000
    },
    {
        "time": 1689791595000,
        "value": 139.587,
        "_internal_originalTime": 1689791595000
    },
    {
        "time": 1689791596000,
        "value": 139.591,
        "_internal_originalTime": 1689791596000
    },
    {
        "time": 1689791598000,
        "value": 139.586,
        "_internal_originalTime": 1689791598000
    },
    {
        "time": 1689791599000,
        "value": 139.588,
        "_internal_originalTime": 1689791599000
    },
    {
        "time": 1689791601000,
        "value": 139.587,
        "_internal_originalTime": 1689791601000
    },
    {
        "time": 1689791603000,
        "value": 139.589,
        "_internal_originalTime": 1689791603000
    },
    {
        "time": 1689791606000,
        "value": 139.589,
        "_internal_originalTime": 1689791606000
    },
    {
        "time": 1689791608000,
        "value": 139.589,
        "_internal_originalTime": 1689791608000
    },
    {
        "time": 1689791615000,
        "value": 139.589,
        "_internal_originalTime": 1689791615000
    },
    {
        "time": 1689791616000,
        "value": 139.589,
        "_internal_originalTime": 1689791616000
    },
    {
        "time": 1689791618000,
        "value": 139.586,
        "_internal_originalTime": 1689791618000
    },
    {
        "time": 1689791620000,
        "value": 139.584,
        "_internal_originalTime": 1689791620000
    },
    {
        "time": 1689791623000,
        "value": 139.582,
        "_internal_originalTime": 1689791623000
    },
    {
        "time": 1689791624000,
        "value": 139.579,
        "_internal_originalTime": 1689791624000
    },
    {
        "time": 1689791625000,
        "value": 139.583,
        "_internal_originalTime": 1689791625000
    },
    {
        "time": 1689791628000,
        "value": 139.578,
        "_internal_originalTime": 1689791628000
    },
    {
        "time": 1689791629000,
        "value": 139.583,
        "_internal_originalTime": 1689791629000
    },
    {
        "time": 1689791631000,
        "value": 139.577,
        "_internal_originalTime": 1689791631000
    },
    {
        "time": 1689791632000,
        "value": 139.578,
        "_internal_originalTime": 1689791632000
    },
    {
        "time": 1689791633000,
        "value": 139.577,
        "_internal_originalTime": 1689791633000
    },
    {
        "time": 1689791637000,
        "value": 139.576,
        "_internal_originalTime": 1689791637000
    },
    {
        "time": 1689791638000,
        "value": 139.581,
        "_internal_originalTime": 1689791638000
    },
    {
        "time": 1689791639000,
        "value": 139.577,
        "_internal_originalTime": 1689791639000
    },
    {
        "time": 1689791641000,
        "value": 139.577,
        "_internal_originalTime": 1689791641000
    },
    {
        "time": 1689791642000,
        "value": 139.578,
        "_internal_originalTime": 1689791642000
    },
    {
        "time": 1689791644000,
        "value": 139.582,
        "_internal_originalTime": 1689791644000
    },
    {
        "time": 1689791645000,
        "value": 139.577,
        "_internal_originalTime": 1689791645000
    },
    {
        "time": 1689791648000,
        "value": 139.582,
        "_internal_originalTime": 1689791648000
    },
    {
        "time": 1689791649000,
        "value": 139.579,
        "_internal_originalTime": 1689791649000
    },
    {
        "time": 1689791651000,
        "value": 139.578,
        "_internal_originalTime": 1689791651000
    },
    {
        "time": 1689791656000,
        "value": 139.588,
        "_internal_originalTime": 1689791656000
    },
    {
        "time": 1689791658000,
        "value": 139.589,
        "_internal_originalTime": 1689791658000
    },
    {
        "time": 1689791659000,
        "value": 139.585,
        "_internal_originalTime": 1689791659000
    },
    {
        "time": 1689791660000,
        "value": 139.589,
        "_internal_originalTime": 1689791660000
    },
    {
        "time": 1689791661000,
        "value": 139.589,
        "_internal_originalTime": 1689791661000
    },
    {
        "time": 1689791670000,
        "value": 139.586,
        "_internal_originalTime": 1689791670000
    },
    {
        "time": 1689791673000,
        "value": 139.589,
        "_internal_originalTime": 1689791673000
    },
    {
        "time": 1689791674000,
        "value": 139.59,
        "_internal_originalTime": 1689791674000
    },
    {
        "time": 1689791675000,
        "value": 139.587,
        "_internal_originalTime": 1689791675000
    },
    {
        "time": 1689791677000,
        "value": 139.588,
        "_internal_originalTime": 1689791677000
    },
    {
        "time": 1689791678000,
        "value": 139.589,
        "_internal_originalTime": 1689791678000
    },
    {
        "time": 1689791679000,
        "value": 139.586,
        "_internal_originalTime": 1689791679000
    },
    {
        "time": 1689791680000,
        "value": 139.586,
        "_internal_originalTime": 1689791680000
    },
    {
        "time": 1689791682000,
        "value": 139.585,
        "_internal_originalTime": 1689791682000
    },
    {
        "time": 1689791683000,
        "value": 139.589,
        "_internal_originalTime": 1689791683000
    },
    {
        "time": 1689791685000,
        "value": 139.585,
        "_internal_originalTime": 1689791685000
    },
    {
        "time": 1689791688000,
        "value": 139.584,
        "_internal_originalTime": 1689791688000
    },
    {
        "time": 1689791689000,
        "value": 139.586,
        "_internal_originalTime": 1689791689000
    },
    {
        "time": 1689791690000,
        "value": 139.59,
        "_internal_originalTime": 1689791690000
    },
    {
        "time": 1689791692000,
        "value": 139.588,
        "_internal_originalTime": 1689791692000
    },
    {
        "time": 1689791695000,
        "value": 139.586,
        "_internal_originalTime": 1689791695000
    },
    {
        "time": 1689791696000,
        "value": 139.586,
        "_internal_originalTime": 1689791696000
    },
    {
        "time": 1689791698000,
        "value": 139.59,
        "_internal_originalTime": 1689791698000
    },
    {
        "time": 1689791701000,
        "value": 139.586,
        "_internal_originalTime": 1689791701000
    },
    {
        "time": 1689791702000,
        "value": 139.589,
        "_internal_originalTime": 1689791702000
    },
    {
        "time": 1689791703000,
        "value": 139.586,
        "_internal_originalTime": 1689791703000
    },
    {
        "time": 1689791704000,
        "value": 139.589,
        "_internal_originalTime": 1689791704000
    },
    {
        "time": 1689791705000,
        "value": 139.587,
        "_internal_originalTime": 1689791705000
    },
    {
        "time": 1689791706000,
        "value": 139.586,
        "_internal_originalTime": 1689791706000
    },
    {
        "time": 1689791708000,
        "value": 139.587,
        "_internal_originalTime": 1689791708000
    },
    {
        "time": 1689791709000,
        "value": 139.589,
        "_internal_originalTime": 1689791709000
    },
    {
        "time": 1689791713000,
        "value": 139.584,
        "_internal_originalTime": 1689791713000
    },
    {
        "time": 1689791716000,
        "value": 139.589,
        "_internal_originalTime": 1689791716000
    },
    {
        "time": 1689791718000,
        "value": 139.585,
        "_internal_originalTime": 1689791718000
    },
    {
        "time": 1689791722000,
        "value": 139.59,
        "_internal_originalTime": 1689791722000
    },
    {
        "time": 1689791723000,
        "value": 139.586,
        "_internal_originalTime": 1689791723000
    },
    {
        "time": 1689791726000,
        "value": 139.589,
        "_internal_originalTime": 1689791726000
    },
    {
        "time": 1689791730000,
        "value": 139.59,
        "_internal_originalTime": 1689791730000
    },
    {
        "time": 1689791732000,
        "value": 139.59,
        "_internal_originalTime": 1689791732000
    },
    {
        "time": 1689791734000,
        "value": 139.584,
        "_internal_originalTime": 1689791734000
    },
    {
        "time": 1689791736000,
        "value": 139.586,
        "_internal_originalTime": 1689791736000
    },
    {
        "time": 1689791737000,
        "value": 139.59,
        "_internal_originalTime": 1689791737000
    },
    {
        "time": 1689791738000,
        "value": 139.587,
        "_internal_originalTime": 1689791738000
    },
    {
        "time": 1689791739000,
        "value": 139.586,
        "_internal_originalTime": 1689791739000
    },
    {
        "time": 1689791741000,
        "value": 139.589,
        "_internal_originalTime": 1689791741000
    },
    {
        "time": 1689791742000,
        "value": 139.589,
        "_internal_originalTime": 1689791742000
    },
    {
        "time": 1689791744000,
        "value": 139.583,
        "_internal_originalTime": 1689791744000
    },
    {
        "time": 1689791748000,
        "value": 139.586,
        "_internal_originalTime": 1689791748000
    },
    {
        "time": 1689791750000,
        "value": 139.585,
        "_internal_originalTime": 1689791750000
    },
    {
        "time": 1689791751000,
        "value": 139.586,
        "_internal_originalTime": 1689791751000
    },
    {
        "time": 1689791754000,
        "value": 139.586,
        "_internal_originalTime": 1689791754000
    },
    {
        "time": 1689791759000,
        "value": 139.587,
        "_internal_originalTime": 1689791759000
    },
    {
        "time": 1689791761000,
        "value": 139.586,
        "_internal_originalTime": 1689791761000
    },
    {
        "time": 1689791764000,
        "value": 139.587,
        "_internal_originalTime": 1689791764000
    },
    {
        "time": 1689791766000,
        "value": 139.589,
        "_internal_originalTime": 1689791766000
    },
    {
        "time": 1689791767000,
        "value": 139.59,
        "_internal_originalTime": 1689791767000
    },
    {
        "time": 1689791768000,
        "value": 139.587,
        "_internal_originalTime": 1689791768000
    },
    {
        "time": 1689791772000,
        "value": 139.587,
        "_internal_originalTime": 1689791772000
    },
    {
        "time": 1689791773000,
        "value": 139.586,
        "_internal_originalTime": 1689791773000
    },
    {
        "time": 1689791774000,
        "value": 139.589,
        "_internal_originalTime": 1689791774000
    },
    {
        "time": 1689791775000,
        "value": 139.59,
        "_internal_originalTime": 1689791775000
    },
    {
        "time": 1689791785000,
        "value": 139.59,
        "_internal_originalTime": 1689791785000
    },
    {
        "time": 1689791791000,
        "value": 139.589,
        "_internal_originalTime": 1689791791000
    },
    {
        "time": 1689791792000,
        "value": 139.59,
        "_internal_originalTime": 1689791792000
    },
    {
        "time": 1689791794000,
        "value": 139.59,
        "_internal_originalTime": 1689791794000
    },
    {
        "time": 1689791798000,
        "value": 139.587,
        "_internal_originalTime": 1689791798000
    },
    {
        "time": 1689791801000,
        "value": 139.58,
        "_internal_originalTime": 1689791801000
    },
    {
        "time": 1689791803000,
        "value": 139.582,
        "_internal_originalTime": 1689791803000
    },
    {
        "time": 1689791804000,
        "value": 139.585,
        "_internal_originalTime": 1689791804000
    },
    {
        "time": 1689791805000,
        "value": 139.582,
        "_internal_originalTime": 1689791805000
    },
    {
        "time": 1689791806000,
        "value": 139.579,
        "_internal_originalTime": 1689791806000
    },
    {
        "time": 1689791807000,
        "value": 139.582,
        "_internal_originalTime": 1689791807000
    },
    {
        "time": 1689791808000,
        "value": 139.584,
        "_internal_originalTime": 1689791808000
    },
    {
        "time": 1689791809000,
        "value": 139.584,
        "_internal_originalTime": 1689791809000
    },
    {
        "time": 1689791811000,
        "value": 139.582,
        "_internal_originalTime": 1689791811000
    },
    {
        "time": 1689791812000,
        "value": 139.581,
        "_internal_originalTime": 1689791812000
    },
    {
        "time": 1689791813000,
        "value": 139.578,
        "_internal_originalTime": 1689791813000
    },
    {
        "time": 1689791816000,
        "value": 139.579,
        "_internal_originalTime": 1689791816000
    },
    {
        "time": 1689791817000,
        "value": 139.578,
        "_internal_originalTime": 1689791817000
    },
    {
        "time": 1689791819000,
        "value": 139.579,
        "_internal_originalTime": 1689791819000
    },
    {
        "time": 1689791822000,
        "value": 139.579,
        "_internal_originalTime": 1689791822000
    },
    {
        "time": 1689791825000,
        "value": 139.581,
        "_internal_originalTime": 1689791825000
    },
    {
        "time": 1689791826000,
        "value": 139.577,
        "_internal_originalTime": 1689791826000
    },
    {
        "time": 1689791827000,
        "value": 139.576,
        "_internal_originalTime": 1689791827000
    },
    {
        "time": 1689791828000,
        "value": 139.579,
        "_internal_originalTime": 1689791828000
    },
    {
        "time": 1689791832000,
        "value": 139.581,
        "_internal_originalTime": 1689791832000
    },
    {
        "time": 1689791834000,
        "value": 139.581,
        "_internal_originalTime": 1689791834000
    },
    {
        "time": 1689791837000,
        "value": 139.575,
        "_internal_originalTime": 1689791837000
    },
    {
        "time": 1689791838000,
        "value": 139.575,
        "_internal_originalTime": 1689791838000
    },
    {
        "time": 1689791847000,
        "value": 139.577,
        "_internal_originalTime": 1689791847000
    },
    {
        "time": 1689791849000,
        "value": 139.582,
        "_internal_originalTime": 1689791849000
    },
    {
        "time": 1689791850000,
        "value": 139.577,
        "_internal_originalTime": 1689791850000
    },
    {
        "time": 1689791851000,
        "value": 139.577,
        "_internal_originalTime": 1689791851000
    },
    {
        "time": 1689791852000,
        "value": 139.581,
        "_internal_originalTime": 1689791852000
    },
    {
        "time": 1689791858000,
        "value": 139.577,
        "_internal_originalTime": 1689791858000
    },
    {
        "time": 1689791861000,
        "value": 139.577,
        "_internal_originalTime": 1689791861000
    },
    {
        "time": 1689791863000,
        "value": 139.579,
        "_internal_originalTime": 1689791863000
    },
    {
        "time": 1689791866000,
        "value": 139.582,
        "_internal_originalTime": 1689791866000
    },
    {
        "time": 1689791868000,
        "value": 139.575,
        "_internal_originalTime": 1689791868000
    },
    {
        "time": 1689791870000,
        "value": 139.576,
        "_internal_originalTime": 1689791870000
    },
    {
        "time": 1689791874000,
        "value": 139.582,
        "_internal_originalTime": 1689791874000
    },
    {
        "time": 1689791878000,
        "value": 139.587,
        "_internal_originalTime": 1689791878000
    },
    {
        "time": 1689791879000,
        "value": 139.586,
        "_internal_originalTime": 1689791879000
    },
    {
        "time": 1689791880000,
        "value": 139.586,
        "_internal_originalTime": 1689791880000
    },
    {
        "time": 1689791881000,
        "value": 139.589,
        "_internal_originalTime": 1689791881000
    },
    {
        "time": 1689791882000,
        "value": 139.589,
        "_internal_originalTime": 1689791882000
    },
    {
        "time": 1689791883000,
        "value": 139.59,
        "_internal_originalTime": 1689791883000
    },
    {
        "time": 1689791886000,
        "value": 139.589,
        "_internal_originalTime": 1689791886000
    },
    {
        "time": 1689791887000,
        "value": 139.587,
        "_internal_originalTime": 1689791887000
    },
    {
        "time": 1689791889000,
        "value": 139.59,
        "_internal_originalTime": 1689791889000
    },
    {
        "time": 1689791891000,
        "value": 139.589,
        "_internal_originalTime": 1689791891000
    },
    {
        "time": 1689791896000,
        "value": 139.586,
        "_internal_originalTime": 1689791896000
    },
    {
        "time": 1689791898000,
        "value": 139.588,
        "_internal_originalTime": 1689791898000
    },
    {
        "time": 1689791899000,
        "value": 139.587,
        "_internal_originalTime": 1689791899000
    },
    {
        "time": 1689791900000,
        "value": 139.6,
        "_internal_originalTime": 1689791900000
    },
    {
        "time": 1689791902000,
        "value": 139.603,
        "_internal_originalTime": 1689791902000
    },
    {
        "time": 1689791903000,
        "value": 139.599,
        "_internal_originalTime": 1689791903000
    },
    {
        "time": 1689791905000,
        "value": 139.597,
        "_internal_originalTime": 1689791905000
    },
    {
        "time": 1689791906000,
        "value": 139.597,
        "_internal_originalTime": 1689791906000
    },
    {
        "time": 1689791910000,
        "value": 139.596,
        "_internal_originalTime": 1689791910000
    },
    {
        "time": 1689791918000,
        "value": 139.599,
        "_internal_originalTime": 1689791918000
    },
    {
        "time": 1689791919000,
        "value": 139.599,
        "_internal_originalTime": 1689791919000
    },
    {
        "time": 1689791921000,
        "value": 139.598,
        "_internal_originalTime": 1689791921000
    },
    {
        "time": 1689791923000,
        "value": 139.6,
        "_internal_originalTime": 1689791923000
    },
    {
        "time": 1689791924000,
        "value": 139.601,
        "_internal_originalTime": 1689791924000
    },
    {
        "time": 1689791928000,
        "value": 139.596,
        "_internal_originalTime": 1689791928000
    },
    {
        "time": 1689791929000,
        "value": 139.602,
        "_internal_originalTime": 1689791929000
    },
    {
        "time": 1689791930000,
        "value": 139.598,
        "_internal_originalTime": 1689791930000
    },
    {
        "time": 1689791932000,
        "value": 139.596,
        "_internal_originalTime": 1689791932000
    },
    {
        "time": 1689791941000,
        "value": 139.601,
        "_internal_originalTime": 1689791941000
    },
    {
        "time": 1689791942000,
        "value": 139.599,
        "_internal_originalTime": 1689791942000
    },
    {
        "time": 1689791944000,
        "value": 139.605,
        "_internal_originalTime": 1689791944000
    },
    {
        "time": 1689791946000,
        "value": 139.602,
        "_internal_originalTime": 1689791946000
    },
    {
        "time": 1689791947000,
        "value": 139.601,
        "_internal_originalTime": 1689791947000
    },
    {
        "time": 1689791948000,
        "value": 139.6,
        "_internal_originalTime": 1689791948000
    },
    {
        "time": 1689791949000,
        "value": 139.602,
        "_internal_originalTime": 1689791949000
    },
    {
        "time": 1689791951000,
        "value": 139.598,
        "_internal_originalTime": 1689791951000
    },
    {
        "time": 1689791953000,
        "value": 139.604,
        "_internal_originalTime": 1689791953000
    },
    {
        "time": 1689791955000,
        "value": 139.604,
        "_internal_originalTime": 1689791955000
    },
    {
        "time": 1689791958000,
        "value": 139.603,
        "_internal_originalTime": 1689791958000
    },
    {
        "time": 1689791963000,
        "value": 139.603,
        "_internal_originalTime": 1689791963000
    },
    {
        "time": 1689791964000,
        "value": 139.609,
        "_internal_originalTime": 1689791964000
    },
    {
        "time": 1689791965000,
        "value": 139.605,
        "_internal_originalTime": 1689791965000
    },
    {
        "time": 1689791968000,
        "value": 139.604,
        "_internal_originalTime": 1689791968000
    },
    {
        "time": 1689791971000,
        "value": 139.605,
        "_internal_originalTime": 1689791971000
    },
    {
        "time": 1689791972000,
        "value": 139.61,
        "_internal_originalTime": 1689791972000
    },
    {
        "time": 1689791974000,
        "value": 139.61,
        "_internal_originalTime": 1689791974000
    },
    {
        "time": 1689791975000,
        "value": 139.611,
        "_internal_originalTime": 1689791975000
    },
    {
        "time": 1689791976000,
        "value": 139.612,
        "_internal_originalTime": 1689791976000
    },
    {
        "time": 1689791978000,
        "value": 139.61,
        "_internal_originalTime": 1689791978000
    },
    {
        "time": 1689791979000,
        "value": 139.606,
        "_internal_originalTime": 1689791979000
    },
    {
        "time": 1689791981000,
        "value": 139.606,
        "_internal_originalTime": 1689791981000
    },
    {
        "time": 1689791982000,
        "value": 139.607,
        "_internal_originalTime": 1689791982000
    },
    {
        "time": 1689791984000,
        "value": 139.609,
        "_internal_originalTime": 1689791984000
    },
    {
        "time": 1689791986000,
        "value": 139.606,
        "_internal_originalTime": 1689791986000
    },
    {
        "time": 1689791987000,
        "value": 139.607,
        "_internal_originalTime": 1689791987000
    },
    {
        "time": 1689791988000,
        "value": 139.606,
        "_internal_originalTime": 1689791988000
    },
    {
        "time": 1689791989000,
        "value": 139.611,
        "_internal_originalTime": 1689791989000
    },
    {
        "time": 1689791993000,
        "value": 139.611,
        "_internal_originalTime": 1689791993000
    },
    {
        "time": 1689791994000,
        "value": 139.608,
        "_internal_originalTime": 1689791994000
    },
    {
        "time": 1689791995000,
        "value": 139.609,
        "_internal_originalTime": 1689791995000
    },
    {
        "time": 1689791998000,
        "value": 139.61,
        "_internal_originalTime": 1689791998000
    },
    {
        "time": 1689792000000,
        "value": 139.607,
        "_internal_originalTime": 1689792000000
    },
    {
        "time": 1689792001000,
        "value": 139.609,
        "_internal_originalTime": 1689792001000
    },
    {
        "time": 1689792003000,
        "value": 139.609,
        "_internal_originalTime": 1689792003000
    },
    {
        "time": 1689792004000,
        "value": 139.61,
        "_internal_originalTime": 1689792004000
    },
    {
        "time": 1689792006000,
        "value": 139.61,
        "_internal_originalTime": 1689792006000
    },
    {
        "time": 1689792008000,
        "value": 139.61,
        "_internal_originalTime": 1689792008000
    },
    {
        "time": 1689792009000,
        "value": 139.608,
        "_internal_originalTime": 1689792009000
    },
    {
        "time": 1689792012000,
        "value": 139.611,
        "_internal_originalTime": 1689792012000
    },
    {
        "time": 1689792014000,
        "value": 139.61,
        "_internal_originalTime": 1689792014000
    },
    {
        "time": 1689792018000,
        "value": 139.606,
        "_internal_originalTime": 1689792018000
    },
    {
        "time": 1689792025000,
        "value": 139.611,
        "_internal_originalTime": 1689792025000
    },
    {
        "time": 1689792026000,
        "value": 139.612,
        "_internal_originalTime": 1689792026000
    },
    {
        "time": 1689792027000,
        "value": 139.612,
        "_internal_originalTime": 1689792027000
    },
    {
        "time": 1689792030000,
        "value": 139.612,
        "_internal_originalTime": 1689792030000
    },
    {
        "time": 1689792038000,
        "value": 139.61,
        "_internal_originalTime": 1689792038000
    },
    {
        "time": 1689792045000,
        "value": 139.612,
        "_internal_originalTime": 1689792045000
    },
    {
        "time": 1689792046000,
        "value": 139.617,
        "_internal_originalTime": 1689792046000
    },
    {
        "time": 1689792047000,
        "value": 139.616,
        "_internal_originalTime": 1689792047000
    },
    {
        "time": 1689792048000,
        "value": 139.616,
        "_internal_originalTime": 1689792048000
    },
    {
        "time": 1689792049000,
        "value": 139.616,
        "_internal_originalTime": 1689792049000
    },
    {
        "time": 1689792051000,
        "value": 139.618,
        "_internal_originalTime": 1689792051000
    },
    {
        "time": 1689792052000,
        "value": 139.613,
        "_internal_originalTime": 1689792052000
    },
    {
        "time": 1689792053000,
        "value": 139.618,
        "_internal_originalTime": 1689792053000
    },
    {
        "time": 1689792054000,
        "value": 139.616,
        "_internal_originalTime": 1689792054000
    },
    {
        "time": 1689792055000,
        "value": 139.618,
        "_internal_originalTime": 1689792055000
    },
    {
        "time": 1689792058000,
        "value": 139.614,
        "_internal_originalTime": 1689792058000
    },
    {
        "time": 1689792062000,
        "value": 139.617,
        "_internal_originalTime": 1689792062000
    },
    {
        "time": 1689792063000,
        "value": 139.619,
        "_internal_originalTime": 1689792063000
    },
    {
        "time": 1689792064000,
        "value": 139.623,
        "_internal_originalTime": 1689792064000
    },
    {
        "time": 1689792066000,
        "value": 139.62,
        "_internal_originalTime": 1689792066000
    },
    {
        "time": 1689792067000,
        "value": 139.621,
        "_internal_originalTime": 1689792067000
    },
    {
        "time": 1689792068000,
        "value": 139.625,
        "_internal_originalTime": 1689792068000
    },
    {
        "time": 1689792070000,
        "value": 139.623,
        "_internal_originalTime": 1689792070000
    },
    {
        "time": 1689792071000,
        "value": 139.623,
        "_internal_originalTime": 1689792071000
    },
    {
        "time": 1689792072000,
        "value": 139.625,
        "_internal_originalTime": 1689792072000
    },
    {
        "time": 1689792075000,
        "value": 139.627,
        "_internal_originalTime": 1689792075000
    },
    {
        "time": 1689792076000,
        "value": 139.63,
        "_internal_originalTime": 1689792076000
    },
    {
        "time": 1689792077000,
        "value": 139.636,
        "_internal_originalTime": 1689792077000
    },
    {
        "time": 1689792078000,
        "value": 139.638,
        "_internal_originalTime": 1689792078000
    },
    {
        "time": 1689792082000,
        "value": 139.638,
        "_internal_originalTime": 1689792082000
    },
    {
        "time": 1689792083000,
        "value": 139.638,
        "_internal_originalTime": 1689792083000
    },
    {
        "time": 1689792085000,
        "value": 139.637,
        "_internal_originalTime": 1689792085000
    },
    {
        "time": 1689792086000,
        "value": 139.638,
        "_internal_originalTime": 1689792086000
    },
    {
        "time": 1689792087000,
        "value": 139.636,
        "_internal_originalTime": 1689792087000
    },
    {
        "time": 1689792089000,
        "value": 139.634,
        "_internal_originalTime": 1689792089000
    },
    {
        "time": 1689792096000,
        "value": 139.64,
        "_internal_originalTime": 1689792096000
    },
    {
        "time": 1689792098000,
        "value": 139.639,
        "_internal_originalTime": 1689792098000
    },
    {
        "time": 1689792103000,
        "value": 139.641,
        "_internal_originalTime": 1689792103000
    },
    {
        "time": 1689792104000,
        "value": 139.639,
        "_internal_originalTime": 1689792104000
    },
    {
        "time": 1689792108000,
        "value": 139.632,
        "_internal_originalTime": 1689792108000
    },
    {
        "time": 1689792109000,
        "value": 139.64,
        "_internal_originalTime": 1689792109000
    },
    {
        "time": 1689792110000,
        "value": 139.638,
        "_internal_originalTime": 1689792110000
    },
    {
        "time": 1689792111000,
        "value": 139.641,
        "_internal_originalTime": 1689792111000
    },
    {
        "time": 1689792114000,
        "value": 139.64,
        "_internal_originalTime": 1689792114000
    },
    {
        "time": 1689792115000,
        "value": 139.643,
        "_internal_originalTime": 1689792115000
    },
    {
        "time": 1689792116000,
        "value": 139.639,
        "_internal_originalTime": 1689792116000
    },
    {
        "time": 1689792118000,
        "value": 139.645,
        "_internal_originalTime": 1689792118000
    },
    {
        "time": 1689792121000,
        "value": 139.639,
        "_internal_originalTime": 1689792121000
    },
    {
        "time": 1689792122000,
        "value": 139.639,
        "_internal_originalTime": 1689792122000
    },
    {
        "time": 1689792128000,
        "value": 139.641,
        "_internal_originalTime": 1689792128000
    },
    {
        "time": 1689792129000,
        "value": 139.641,
        "_internal_originalTime": 1689792129000
    },
    {
        "time": 1689792130000,
        "value": 139.635,
        "_internal_originalTime": 1689792130000
    },
    {
        "time": 1689792132000,
        "value": 139.636,
        "_internal_originalTime": 1689792132000
    },
    {
        "time": 1689792133000,
        "value": 139.639,
        "_internal_originalTime": 1689792133000
    },
    {
        "time": 1689792134000,
        "value": 139.639,
        "_internal_originalTime": 1689792134000
    },
    {
        "time": 1689792135000,
        "value": 139.64,
        "_internal_originalTime": 1689792135000
    },
    {
        "time": 1689792136000,
        "value": 139.64,
        "_internal_originalTime": 1689792136000
    },
    {
        "time": 1689792138000,
        "value": 139.649,
        "_internal_originalTime": 1689792138000
    },
    {
        "time": 1689792139000,
        "value": 139.647,
        "_internal_originalTime": 1689792139000
    },
    {
        "time": 1689792140000,
        "value": 139.648,
        "_internal_originalTime": 1689792140000
    },
    {
        "time": 1689792147000,
        "value": 139.649,
        "_internal_originalTime": 1689792147000
    },
    {
        "time": 1689792149000,
        "value": 139.647,
        "_internal_originalTime": 1689792149000
    },
    {
        "time": 1689792150000,
        "value": 139.649,
        "_internal_originalTime": 1689792150000
    },
    {
        "time": 1689792151000,
        "value": 139.649,
        "_internal_originalTime": 1689792151000
    },
    {
        "time": 1689792153000,
        "value": 139.65,
        "_internal_originalTime": 1689792153000
    },
    {
        "time": 1689792156000,
        "value": 139.649,
        "_internal_originalTime": 1689792156000
    },
    {
        "time": 1689792158000,
        "value": 139.65,
        "_internal_originalTime": 1689792158000
    },
    {
        "time": 1689792161000,
        "value": 139.647,
        "_internal_originalTime": 1689792161000
    },
    {
        "time": 1689792164000,
        "value": 139.648,
        "_internal_originalTime": 1689792164000
    },
    {
        "time": 1689792165000,
        "value": 139.647,
        "_internal_originalTime": 1689792165000
    },
    {
        "time": 1689792166000,
        "value": 139.648,
        "_internal_originalTime": 1689792166000
    },
    {
        "time": 1689792168000,
        "value": 139.641,
        "_internal_originalTime": 1689792168000
    },
    {
        "time": 1689792169000,
        "value": 139.646,
        "_internal_originalTime": 1689792169000
    },
    {
        "time": 1689792170000,
        "value": 139.647,
        "_internal_originalTime": 1689792170000
    },
    {
        "time": 1689792176000,
        "value": 139.645,
        "_internal_originalTime": 1689792176000
    },
    {
        "time": 1689792180000,
        "value": 139.645,
        "_internal_originalTime": 1689792180000
    },
    {
        "time": 1689792181000,
        "value": 139.638,
        "_internal_originalTime": 1689792181000
    },
    {
        "time": 1689792182000,
        "value": 139.638,
        "_internal_originalTime": 1689792182000
    },
    {
        "time": 1689792183000,
        "value": 139.636,
        "_internal_originalTime": 1689792183000
    },
    {
        "time": 1689792184000,
        "value": 139.636,
        "_internal_originalTime": 1689792184000
    },
    {
        "time": 1689792185000,
        "value": 139.635,
        "_internal_originalTime": 1689792185000
    },
    {
        "time": 1689792188000,
        "value": 139.638,
        "_internal_originalTime": 1689792188000
    },
    {
        "time": 1689792189000,
        "value": 139.636,
        "_internal_originalTime": 1689792189000
    },
    {
        "time": 1689792190000,
        "value": 139.636,
        "_internal_originalTime": 1689792190000
    },
    {
        "time": 1689792194000,
        "value": 139.636,
        "_internal_originalTime": 1689792194000
    },
    {
        "time": 1689792195000,
        "value": 139.636,
        "_internal_originalTime": 1689792195000
    },
    {
        "time": 1689792197000,
        "value": 139.638,
        "_internal_originalTime": 1689792197000
    },
    {
        "time": 1689792198000,
        "value": 139.633,
        "_internal_originalTime": 1689792198000
    },
    {
        "time": 1689792202000,
        "value": 139.636,
        "_internal_originalTime": 1689792202000
    },
    {
        "time": 1689792203000,
        "value": 139.639,
        "_internal_originalTime": 1689792203000
    },
    {
        "time": 1689792204000,
        "value": 139.638,
        "_internal_originalTime": 1689792204000
    },
    {
        "time": 1689792206000,
        "value": 139.635,
        "_internal_originalTime": 1689792206000
    },
    {
        "time": 1689792210000,
        "value": 139.636,
        "_internal_originalTime": 1689792210000
    },
    {
        "time": 1689792214000,
        "value": 139.636,
        "_internal_originalTime": 1689792214000
    },
    {
        "time": 1689792218000,
        "value": 139.639,
        "_internal_originalTime": 1689792218000
    },
    {
        "time": 1689792220000,
        "value": 139.636,
        "_internal_originalTime": 1689792220000
    },
    {
        "time": 1689792223000,
        "value": 139.638,
        "_internal_originalTime": 1689792223000
    },
    {
        "time": 1689792224000,
        "value": 139.636,
        "_internal_originalTime": 1689792224000
    },
    {
        "time": 1689792226000,
        "value": 139.636,
        "_internal_originalTime": 1689792226000
    },
    {
        "time": 1689792228000,
        "value": 139.636,
        "_internal_originalTime": 1689792228000
    },
    {
        "time": 1689792229000,
        "value": 139.636,
        "_internal_originalTime": 1689792229000
    },
    {
        "time": 1689792239000,
        "value": 139.63,
        "_internal_originalTime": 1689792239000
    },
    {
        "time": 1689792240000,
        "value": 139.629,
        "_internal_originalTime": 1689792240000
    },
    {
        "time": 1689792241000,
        "value": 139.627,
        "_internal_originalTime": 1689792241000
    },
    {
        "time": 1689792242000,
        "value": 139.631,
        "_internal_originalTime": 1689792242000
    },
    {
        "time": 1689792243000,
        "value": 139.627,
        "_internal_originalTime": 1689792243000
    },
    {
        "time": 1689792244000,
        "value": 139.625,
        "_internal_originalTime": 1689792244000
    },
    {
        "time": 1689792247000,
        "value": 139.629,
        "_internal_originalTime": 1689792247000
    },
    {
        "time": 1689792248000,
        "value": 139.627,
        "_internal_originalTime": 1689792248000
    },
    {
        "time": 1689792249000,
        "value": 139.626,
        "_internal_originalTime": 1689792249000
    },
    {
        "time": 1689792255000,
        "value": 139.624,
        "_internal_originalTime": 1689792255000
    },
    {
        "time": 1689792256000,
        "value": 139.625,
        "_internal_originalTime": 1689792256000
    },
    {
        "time": 1689792258000,
        "value": 139.618,
        "_internal_originalTime": 1689792258000
    },
    {
        "time": 1689792259000,
        "value": 139.625,
        "_internal_originalTime": 1689792259000
    },
    {
        "time": 1689792260000,
        "value": 139.625,
        "_internal_originalTime": 1689792260000
    },
    {
        "time": 1689792261000,
        "value": 139.621,
        "_internal_originalTime": 1689792261000
    },
    {
        "time": 1689792263000,
        "value": 139.619,
        "_internal_originalTime": 1689792263000
    },
    {
        "time": 1689792270000,
        "value": 139.616,
        "_internal_originalTime": 1689792270000
    },
    {
        "time": 1689792274000,
        "value": 139.612,
        "_internal_originalTime": 1689792274000
    },
    {
        "time": 1689792276000,
        "value": 139.617,
        "_internal_originalTime": 1689792276000
    },
    {
        "time": 1689792277000,
        "value": 139.618,
        "_internal_originalTime": 1689792277000
    },
    {
        "time": 1689792278000,
        "value": 139.618,
        "_internal_originalTime": 1689792278000
    },
    {
        "time": 1689792280000,
        "value": 139.616,
        "_internal_originalTime": 1689792280000
    },
    {
        "time": 1689792281000,
        "value": 139.612,
        "_internal_originalTime": 1689792281000
    },
    {
        "time": 1689792282000,
        "value": 139.615,
        "_internal_originalTime": 1689792282000
    },
    {
        "time": 1689792284000,
        "value": 139.611,
        "_internal_originalTime": 1689792284000
    },
    {
        "time": 1689792285000,
        "value": 139.613,
        "_internal_originalTime": 1689792285000
    },
    {
        "time": 1689792287000,
        "value": 139.609,
        "_internal_originalTime": 1689792287000
    },
    {
        "time": 1689792288000,
        "value": 139.616,
        "_internal_originalTime": 1689792288000
    },
    {
        "time": 1689792289000,
        "value": 139.616,
        "_internal_originalTime": 1689792289000
    },
    {
        "time": 1689792292000,
        "value": 139.62,
        "_internal_originalTime": 1689792292000
    },
    {
        "time": 1689792293000,
        "value": 139.617,
        "_internal_originalTime": 1689792293000
    },
    {
        "time": 1689792298000,
        "value": 139.616,
        "_internal_originalTime": 1689792298000
    },
    {
        "time": 1689792300000,
        "value": 139.619,
        "_internal_originalTime": 1689792300000
    },
    {
        "time": 1689792301000,
        "value": 139.619,
        "_internal_originalTime": 1689792301000
    },
    {
        "time": 1689792303000,
        "value": 139.62,
        "_internal_originalTime": 1689792303000
    },
    {
        "time": 1689792304000,
        "value": 139.618,
        "_internal_originalTime": 1689792304000
    },
    {
        "time": 1689792305000,
        "value": 139.617,
        "_internal_originalTime": 1689792305000
    },
    {
        "time": 1689792306000,
        "value": 139.615,
        "_internal_originalTime": 1689792306000
    },
    {
        "time": 1689792308000,
        "value": 139.619,
        "_internal_originalTime": 1689792308000
    },
    {
        "time": 1689792310000,
        "value": 139.616,
        "_internal_originalTime": 1689792310000
    },
    {
        "time": 1689792312000,
        "value": 139.616,
        "_internal_originalTime": 1689792312000
    },
    {
        "time": 1689792313000,
        "value": 139.617,
        "_internal_originalTime": 1689792313000
    },
    {
        "time": 1689792314000,
        "value": 139.616,
        "_internal_originalTime": 1689792314000
    },
    {
        "time": 1689792315000,
        "value": 139.617,
        "_internal_originalTime": 1689792315000
    },
    {
        "time": 1689792316000,
        "value": 139.62,
        "_internal_originalTime": 1689792316000
    },
    {
        "time": 1689792317000,
        "value": 139.617,
        "_internal_originalTime": 1689792317000
    },
    {
        "time": 1689792318000,
        "value": 139.616,
        "_internal_originalTime": 1689792318000
    },
    {
        "time": 1689792320000,
        "value": 139.617,
        "_internal_originalTime": 1689792320000
    },
    {
        "time": 1689792322000,
        "value": 139.617,
        "_internal_originalTime": 1689792322000
    },
    {
        "time": 1689792324000,
        "value": 139.617,
        "_internal_originalTime": 1689792324000
    },
    {
        "time": 1689792325000,
        "value": 139.616,
        "_internal_originalTime": 1689792325000
    },
    {
        "time": 1689792327000,
        "value": 139.619,
        "_internal_originalTime": 1689792327000
    },
    {
        "time": 1689792328000,
        "value": 139.616,
        "_internal_originalTime": 1689792328000
    },
    {
        "time": 1689792329000,
        "value": 139.617,
        "_internal_originalTime": 1689792329000
    },
    {
        "time": 1689792330000,
        "value": 139.617,
        "_internal_originalTime": 1689792330000
    },
    {
        "time": 1689792331000,
        "value": 139.618,
        "_internal_originalTime": 1689792331000
    },
    {
        "time": 1689792332000,
        "value": 139.616,
        "_internal_originalTime": 1689792332000
    },
    {
        "time": 1689792335000,
        "value": 139.617,
        "_internal_originalTime": 1689792335000
    },
    {
        "time": 1689792336000,
        "value": 139.619,
        "_internal_originalTime": 1689792336000
    },
    {
        "time": 1689792338000,
        "value": 139.621,
        "_internal_originalTime": 1689792338000
    },
    {
        "time": 1689792340000,
        "value": 139.618,
        "_internal_originalTime": 1689792340000
    },
    {
        "time": 1689792341000,
        "value": 139.618,
        "_internal_originalTime": 1689792341000
    },
    {
        "time": 1689792343000,
        "value": 139.617,
        "_internal_originalTime": 1689792343000
    },
    {
        "time": 1689792344000,
        "value": 139.618,
        "_internal_originalTime": 1689792344000
    },
    {
        "time": 1689792346000,
        "value": 139.62,
        "_internal_originalTime": 1689792346000
    },
    {
        "time": 1689792348000,
        "value": 139.619,
        "_internal_originalTime": 1689792348000
    },
    {
        "time": 1689792349000,
        "value": 139.617,
        "_internal_originalTime": 1689792349000
    },
    {
        "time": 1689792350000,
        "value": 139.616,
        "_internal_originalTime": 1689792350000
    },
    {
        "time": 1689792352000,
        "value": 139.62,
        "_internal_originalTime": 1689792352000
    },
    {
        "time": 1689792354000,
        "value": 139.617,
        "_internal_originalTime": 1689792354000
    },
    {
        "time": 1689792359000,
        "value": 139.617,
        "_internal_originalTime": 1689792359000
    },
    {
        "time": 1689792362000,
        "value": 139.617,
        "_internal_originalTime": 1689792362000
    },
    {
        "time": 1689792363000,
        "value": 139.619,
        "_internal_originalTime": 1689792363000
    },
    {
        "time": 1689792364000,
        "value": 139.616,
        "_internal_originalTime": 1689792364000
    },
    {
        "time": 1689792365000,
        "value": 139.617,
        "_internal_originalTime": 1689792365000
    },
    {
        "time": 1689792368000,
        "value": 139.619,
        "_internal_originalTime": 1689792368000
    },
    {
        "time": 1689792369000,
        "value": 139.617,
        "_internal_originalTime": 1689792369000
    },
    {
        "time": 1689792374000,
        "value": 139.617,
        "_internal_originalTime": 1689792374000
    },
    {
        "time": 1689792378000,
        "value": 139.613,
        "_internal_originalTime": 1689792378000
    },
    {
        "time": 1689792379000,
        "value": 139.616,
        "_internal_originalTime": 1689792379000
    },
    {
        "time": 1689792380000,
        "value": 139.617,
        "_internal_originalTime": 1689792380000
    },
    {
        "time": 1689792381000,
        "value": 139.619,
        "_internal_originalTime": 1689792381000
    },
    {
        "time": 1689792383000,
        "value": 139.616,
        "_internal_originalTime": 1689792383000
    },
    {
        "time": 1689792384000,
        "value": 139.616,
        "_internal_originalTime": 1689792384000
    },
    {
        "time": 1689792385000,
        "value": 139.617,
        "_internal_originalTime": 1689792385000
    },
    {
        "time": 1689792387000,
        "value": 139.616,
        "_internal_originalTime": 1689792387000
    },
    {
        "time": 1689792392000,
        "value": 139.617,
        "_internal_originalTime": 1689792392000
    },
    {
        "time": 1689792393000,
        "value": 139.616,
        "_internal_originalTime": 1689792393000
    },
    {
        "time": 1689792394000,
        "value": 139.615,
        "_internal_originalTime": 1689792394000
    },
    {
        "time": 1689792395000,
        "value": 139.615,
        "_internal_originalTime": 1689792395000
    },
    {
        "time": 1689792398000,
        "value": 139.611,
        "_internal_originalTime": 1689792398000
    },
    {
        "time": 1689792399000,
        "value": 139.605,
        "_internal_originalTime": 1689792399000
    },
    {
        "time": 1689792400000,
        "value": 139.603,
        "_internal_originalTime": 1689792400000
    },
    {
        "time": 1689792401000,
        "value": 139.605,
        "_internal_originalTime": 1689792401000
    },
    {
        "time": 1689792404000,
        "value": 139.607,
        "_internal_originalTime": 1689792404000
    },
    {
        "time": 1689792408000,
        "value": 139.602,
        "_internal_originalTime": 1689792408000
    },
    {
        "time": 1689792412000,
        "value": 139.608,
        "_internal_originalTime": 1689792412000
    },
    {
        "time": 1689792413000,
        "value": 139.609,
        "_internal_originalTime": 1689792413000
    },
    {
        "time": 1689792420000,
        "value": 139.607,
        "_internal_originalTime": 1689792420000
    },
    {
        "time": 1689792421000,
        "value": 139.606,
        "_internal_originalTime": 1689792421000
    },
    {
        "time": 1689792422000,
        "value": 139.607,
        "_internal_originalTime": 1689792422000
    },
    {
        "time": 1689792424000,
        "value": 139.608,
        "_internal_originalTime": 1689792424000
    },
    {
        "time": 1689792427000,
        "value": 139.611,
        "_internal_originalTime": 1689792427000
    },
    {
        "time": 1689792428000,
        "value": 139.611,
        "_internal_originalTime": 1689792428000
    },
    {
        "time": 1689792429000,
        "value": 139.609,
        "_internal_originalTime": 1689792429000
    },
    {
        "time": 1689792430000,
        "value": 139.617,
        "_internal_originalTime": 1689792430000
    },
    {
        "time": 1689792431000,
        "value": 139.622,
        "_internal_originalTime": 1689792431000
    },
    {
        "time": 1689792432000,
        "value": 139.624,
        "_internal_originalTime": 1689792432000
    },
    {
        "time": 1689792434000,
        "value": 139.628,
        "_internal_originalTime": 1689792434000
    },
    {
        "time": 1689792436000,
        "value": 139.627,
        "_internal_originalTime": 1689792436000
    },
    {
        "time": 1689792438000,
        "value": 139.625,
        "_internal_originalTime": 1689792438000
    },
    {
        "time": 1689792439000,
        "value": 139.626,
        "_internal_originalTime": 1689792439000
    },
    {
        "time": 1689792440000,
        "value": 139.618,
        "_internal_originalTime": 1689792440000
    },
    {
        "time": 1689792441000,
        "value": 139.619,
        "_internal_originalTime": 1689792441000
    },
    {
        "time": 1689792442000,
        "value": 139.621,
        "_internal_originalTime": 1689792442000
    },
    {
        "time": 1689792445000,
        "value": 139.617,
        "_internal_originalTime": 1689792445000
    },
    {
        "time": 1689792447000,
        "value": 139.619,
        "_internal_originalTime": 1689792447000
    },
    {
        "time": 1689792448000,
        "value": 139.616,
        "_internal_originalTime": 1689792448000
    },
    {
        "time": 1689792450000,
        "value": 139.617,
        "_internal_originalTime": 1689792450000
    },
    {
        "time": 1689792453000,
        "value": 139.617,
        "_internal_originalTime": 1689792453000
    },
    {
        "time": 1689792455000,
        "value": 139.619,
        "_internal_originalTime": 1689792455000
    },
    {
        "time": 1689792457000,
        "value": 139.618,
        "_internal_originalTime": 1689792457000
    },
    {
        "time": 1689792458000,
        "value": 139.62,
        "_internal_originalTime": 1689792458000
    },
    {
        "time": 1689792459000,
        "value": 139.617,
        "_internal_originalTime": 1689792459000
    },
    {
        "time": 1689792460000,
        "value": 139.619,
        "_internal_originalTime": 1689792460000
    },
    {
        "time": 1689792461000,
        "value": 139.619,
        "_internal_originalTime": 1689792461000
    },
    {
        "time": 1689792463000,
        "value": 139.619,
        "_internal_originalTime": 1689792463000
    },
    {
        "time": 1689792464000,
        "value": 139.622,
        "_internal_originalTime": 1689792464000
    },
    {
        "time": 1689792465000,
        "value": 139.622,
        "_internal_originalTime": 1689792465000
    },
    {
        "time": 1689792467000,
        "value": 139.621,
        "_internal_originalTime": 1689792467000
    },
    {
        "time": 1689792468000,
        "value": 139.625,
        "_internal_originalTime": 1689792468000
    },
    {
        "time": 1689792469000,
        "value": 139.623,
        "_internal_originalTime": 1689792469000
    },
    {
        "time": 1689792474000,
        "value": 139.622,
        "_internal_originalTime": 1689792474000
    },
    {
        "time": 1689792475000,
        "value": 139.626,
        "_internal_originalTime": 1689792475000
    },
    {
        "time": 1689792476000,
        "value": 139.628,
        "_internal_originalTime": 1689792476000
    },
    {
        "time": 1689792481000,
        "value": 139.626,
        "_internal_originalTime": 1689792481000
    },
    {
        "time": 1689792482000,
        "value": 139.627,
        "_internal_originalTime": 1689792482000
    },
    {
        "time": 1689792483000,
        "value": 139.624,
        "_internal_originalTime": 1689792483000
    },
    {
        "time": 1689792484000,
        "value": 139.624,
        "_internal_originalTime": 1689792484000
    },
    {
        "time": 1689792486000,
        "value": 139.625,
        "_internal_originalTime": 1689792486000
    },
    {
        "time": 1689792487000,
        "value": 139.628,
        "_internal_originalTime": 1689792487000
    },
    {
        "time": 1689792488000,
        "value": 139.625,
        "_internal_originalTime": 1689792488000
    },
    {
        "time": 1689792489000,
        "value": 139.625,
        "_internal_originalTime": 1689792489000
    },
    {
        "time": 1689792491000,
        "value": 139.627,
        "_internal_originalTime": 1689792491000
    },
    {
        "time": 1689792494000,
        "value": 139.627,
        "_internal_originalTime": 1689792494000
    },
    {
        "time": 1689792495000,
        "value": 139.624,
        "_internal_originalTime": 1689792495000
    },
    {
        "time": 1689792497000,
        "value": 139.627,
        "_internal_originalTime": 1689792497000
    },
    {
        "time": 1689792499000,
        "value": 139.629,
        "_internal_originalTime": 1689792499000
    },
    {
        "time": 1689792500000,
        "value": 139.629,
        "_internal_originalTime": 1689792500000
    },
    {
        "time": 1689792501000,
        "value": 139.629,
        "_internal_originalTime": 1689792501000
    },
    {
        "time": 1689792502000,
        "value": 139.628,
        "_internal_originalTime": 1689792502000
    },
    {
        "time": 1689792506000,
        "value": 139.628,
        "_internal_originalTime": 1689792506000
    },
    {
        "time": 1689792507000,
        "value": 139.628,
        "_internal_originalTime": 1689792507000
    },
    {
        "time": 1689792509000,
        "value": 139.63,
        "_internal_originalTime": 1689792509000
    },
    {
        "time": 1689792511000,
        "value": 139.627,
        "_internal_originalTime": 1689792511000
    },
    {
        "time": 1689792514000,
        "value": 139.627,
        "_internal_originalTime": 1689792514000
    },
    {
        "time": 1689792515000,
        "value": 139.627,
        "_internal_originalTime": 1689792515000
    },
    {
        "time": 1689792518000,
        "value": 139.629,
        "_internal_originalTime": 1689792518000
    },
    {
        "time": 1689792519000,
        "value": 139.627,
        "_internal_originalTime": 1689792519000
    },
    {
        "time": 1689792521000,
        "value": 139.625,
        "_internal_originalTime": 1689792521000
    },
    {
        "time": 1689792523000,
        "value": 139.627,
        "_internal_originalTime": 1689792523000
    },
    {
        "time": 1689792524000,
        "value": 139.627,
        "_internal_originalTime": 1689792524000
    },
    {
        "time": 1689792525000,
        "value": 139.627,
        "_internal_originalTime": 1689792525000
    },
    {
        "time": 1689792527000,
        "value": 139.63,
        "_internal_originalTime": 1689792527000
    },
    {
        "time": 1689792528000,
        "value": 139.629,
        "_internal_originalTime": 1689792528000
    },
    {
        "time": 1689792529000,
        "value": 139.629,
        "_internal_originalTime": 1689792529000
    },
    {
        "time": 1689792530000,
        "value": 139.628,
        "_internal_originalTime": 1689792530000
    },
    {
        "time": 1689792531000,
        "value": 139.629,
        "_internal_originalTime": 1689792531000
    },
    {
        "time": 1689792533000,
        "value": 139.628,
        "_internal_originalTime": 1689792533000
    },
    {
        "time": 1689792535000,
        "value": 139.632,
        "_internal_originalTime": 1689792535000
    },
    {
        "time": 1689792536000,
        "value": 139.631,
        "_internal_originalTime": 1689792536000
    },
    {
        "time": 1689792537000,
        "value": 139.629,
        "_internal_originalTime": 1689792537000
    },
    {
        "time": 1689792538000,
        "value": 139.627,
        "_internal_originalTime": 1689792538000
    },
    {
        "time": 1689792540000,
        "value": 139.625,
        "_internal_originalTime": 1689792540000
    },
    {
        "time": 1689792541000,
        "value": 139.624,
        "_internal_originalTime": 1689792541000
    },
    {
        "time": 1689792542000,
        "value": 139.625,
        "_internal_originalTime": 1689792542000
    },
    {
        "time": 1689792543000,
        "value": 139.627,
        "_internal_originalTime": 1689792543000
    },
    {
        "time": 1689792545000,
        "value": 139.626,
        "_internal_originalTime": 1689792545000
    },
    {
        "time": 1689792548000,
        "value": 139.625,
        "_internal_originalTime": 1689792548000
    },
    {
        "time": 1689792550000,
        "value": 139.629,
        "_internal_originalTime": 1689792550000
    },
    {
        "time": 1689792552000,
        "value": 139.626,
        "_internal_originalTime": 1689792552000
    },
    {
        "time": 1689792553000,
        "value": 139.63,
        "_internal_originalTime": 1689792553000
    },
    {
        "time": 1689792554000,
        "value": 139.627,
        "_internal_originalTime": 1689792554000
    },
    {
        "time": 1689792558000,
        "value": 139.622,
        "_internal_originalTime": 1689792558000
    },
    {
        "time": 1689792559000,
        "value": 139.627,
        "_internal_originalTime": 1689792559000
    },
    {
        "time": 1689792561000,
        "value": 139.627,
        "_internal_originalTime": 1689792561000
    },
    {
        "time": 1689792562000,
        "value": 139.63,
        "_internal_originalTime": 1689792562000
    },
    {
        "time": 1689792563000,
        "value": 139.629,
        "_internal_originalTime": 1689792563000
    },
    {
        "time": 1689792565000,
        "value": 139.628,
        "_internal_originalTime": 1689792565000
    },
    {
        "time": 1689792566000,
        "value": 139.628,
        "_internal_originalTime": 1689792566000
    },
    {
        "time": 1689792570000,
        "value": 139.628,
        "_internal_originalTime": 1689792570000
    },
    {
        "time": 1689792574000,
        "value": 139.628,
        "_internal_originalTime": 1689792574000
    },
    {
        "time": 1689792575000,
        "value": 139.629,
        "_internal_originalTime": 1689792575000
    },
    {
        "time": 1689792576000,
        "value": 139.632,
        "_internal_originalTime": 1689792576000
    },
    {
        "time": 1689792577000,
        "value": 139.633,
        "_internal_originalTime": 1689792577000
    },
    {
        "time": 1689792578000,
        "value": 139.63,
        "_internal_originalTime": 1689792578000
    },
    {
        "time": 1689792581000,
        "value": 139.631,
        "_internal_originalTime": 1689792581000
    },
    {
        "time": 1689792583000,
        "value": 139.631,
        "_internal_originalTime": 1689792583000
    },
    {
        "time": 1689792584000,
        "value": 139.633,
        "_internal_originalTime": 1689792584000
    },
    {
        "time": 1689792586000,
        "value": 139.63,
        "_internal_originalTime": 1689792586000
    },
    {
        "time": 1689792587000,
        "value": 139.63,
        "_internal_originalTime": 1689792587000
    },
    {
        "time": 1689792588000,
        "value": 139.628,
        "_internal_originalTime": 1689792588000
    },
    {
        "time": 1689792589000,
        "value": 139.631,
        "_internal_originalTime": 1689792589000
    },
    {
        "time": 1689792591000,
        "value": 139.63,
        "_internal_originalTime": 1689792591000
    },
    {
        "time": 1689792594000,
        "value": 139.631,
        "_internal_originalTime": 1689792594000
    },
    {
        "time": 1689792595000,
        "value": 139.628,
        "_internal_originalTime": 1689792595000
    },
    {
        "time": 1689792596000,
        "value": 139.63,
        "_internal_originalTime": 1689792596000
    },
    {
        "time": 1689792598000,
        "value": 139.63,
        "_internal_originalTime": 1689792598000
    },
    {
        "time": 1689792600000,
        "value": 139.629,
        "_internal_originalTime": 1689792600000
    },
    {
        "time": 1689792601000,
        "value": 139.632,
        "_internal_originalTime": 1689792601000
    },
    {
        "time": 1689792602000,
        "value": 139.628,
        "_internal_originalTime": 1689792602000
    },
    {
        "time": 1689792604000,
        "value": 139.628,
        "_internal_originalTime": 1689792604000
    },
    {
        "time": 1689792606000,
        "value": 139.629,
        "_internal_originalTime": 1689792606000
    },
    {
        "time": 1689792607000,
        "value": 139.631,
        "_internal_originalTime": 1689792607000
    },
    {
        "time": 1689792608000,
        "value": 139.63,
        "_internal_originalTime": 1689792608000
    },
    {
        "time": 1689792609000,
        "value": 139.632,
        "_internal_originalTime": 1689792609000
    },
    {
        "time": 1689792610000,
        "value": 139.637,
        "_internal_originalTime": 1689792610000
    },
    {
        "time": 1689792611000,
        "value": 139.637,
        "_internal_originalTime": 1689792611000
    },
    {
        "time": 1689792613000,
        "value": 139.642,
        "_internal_originalTime": 1689792613000
    },
    {
        "time": 1689792615000,
        "value": 139.64,
        "_internal_originalTime": 1689792615000
    },
    {
        "time": 1689792616000,
        "value": 139.642,
        "_internal_originalTime": 1689792616000
    },
    {
        "time": 1689792618000,
        "value": 139.633,
        "_internal_originalTime": 1689792618000
    },
    {
        "time": 1689792619000,
        "value": 139.638,
        "_internal_originalTime": 1689792619000
    },
    {
        "time": 1689792622000,
        "value": 139.646,
        "_internal_originalTime": 1689792622000
    },
    {
        "time": 1689792624000,
        "value": 139.651,
        "_internal_originalTime": 1689792624000
    },
    {
        "time": 1689792625000,
        "value": 139.647,
        "_internal_originalTime": 1689792625000
    },
    {
        "time": 1689792626000,
        "value": 139.649,
        "_internal_originalTime": 1689792626000
    },
    {
        "time": 1689792628000,
        "value": 139.65,
        "_internal_originalTime": 1689792628000
    },
    {
        "time": 1689792629000,
        "value": 139.648,
        "_internal_originalTime": 1689792629000
    },
    {
        "time": 1689792631000,
        "value": 139.647,
        "_internal_originalTime": 1689792631000
    },
    {
        "time": 1689792635000,
        "value": 139.647,
        "_internal_originalTime": 1689792635000
    },
    {
        "time": 1689792639000,
        "value": 139.65,
        "_internal_originalTime": 1689792639000
    },
    {
        "time": 1689792640000,
        "value": 139.652,
        "_internal_originalTime": 1689792640000
    },
    {
        "time": 1689792641000,
        "value": 139.653,
        "_internal_originalTime": 1689792641000
    },
    {
        "time": 1689792643000,
        "value": 139.652,
        "_internal_originalTime": 1689792643000
    },
    {
        "time": 1689792644000,
        "value": 139.648,
        "_internal_originalTime": 1689792644000
    },
    {
        "time": 1689792645000,
        "value": 139.649,
        "_internal_originalTime": 1689792645000
    },
    {
        "time": 1689792648000,
        "value": 139.645,
        "_internal_originalTime": 1689792648000
    },
    {
        "time": 1689792650000,
        "value": 139.65,
        "_internal_originalTime": 1689792650000
    },
    {
        "time": 1689792652000,
        "value": 139.647,
        "_internal_originalTime": 1689792652000
    },
    {
        "time": 1689792653000,
        "value": 139.65,
        "_internal_originalTime": 1689792653000
    },
    {
        "time": 1689792655000,
        "value": 139.646,
        "_internal_originalTime": 1689792655000
    },
    {
        "time": 1689792656000,
        "value": 139.649,
        "_internal_originalTime": 1689792656000
    },
    {
        "time": 1689792657000,
        "value": 139.648,
        "_internal_originalTime": 1689792657000
    },
    {
        "time": 1689792658000,
        "value": 139.649,
        "_internal_originalTime": 1689792658000
    },
    {
        "time": 1689792659000,
        "value": 139.646,
        "_internal_originalTime": 1689792659000
    },
    {
        "time": 1689792660000,
        "value": 139.649,
        "_internal_originalTime": 1689792660000
    },
    {
        "time": 1689792662000,
        "value": 139.643,
        "_internal_originalTime": 1689792662000
    },
    {
        "time": 1689792664000,
        "value": 139.642,
        "_internal_originalTime": 1689792664000
    },
    {
        "time": 1689792665000,
        "value": 139.643,
        "_internal_originalTime": 1689792665000
    },
    {
        "time": 1689792667000,
        "value": 139.64,
        "_internal_originalTime": 1689792667000
    },
    {
        "time": 1689792668000,
        "value": 139.643,
        "_internal_originalTime": 1689792668000
    },
    {
        "time": 1689792669000,
        "value": 139.642,
        "_internal_originalTime": 1689792669000
    },
    {
        "time": 1689792670000,
        "value": 139.645,
        "_internal_originalTime": 1689792670000
    },
    {
        "time": 1689792671000,
        "value": 139.644,
        "_internal_originalTime": 1689792671000
    },
    {
        "time": 1689792672000,
        "value": 139.65,
        "_internal_originalTime": 1689792672000
    },
    {
        "time": 1689792673000,
        "value": 139.651,
        "_internal_originalTime": 1689792673000
    },
    {
        "time": 1689792674000,
        "value": 139.651,
        "_internal_originalTime": 1689792674000
    },
    {
        "time": 1689792675000,
        "value": 139.651,
        "_internal_originalTime": 1689792675000
    },
    {
        "time": 1689792678000,
        "value": 139.651,
        "_internal_originalTime": 1689792678000
    },
    {
        "time": 1689792682000,
        "value": 139.651,
        "_internal_originalTime": 1689792682000
    },
    {
        "time": 1689792683000,
        "value": 139.646,
        "_internal_originalTime": 1689792683000
    },
    {
        "time": 1689792684000,
        "value": 139.651,
        "_internal_originalTime": 1689792684000
    },
    {
        "time": 1689792686000,
        "value": 139.651,
        "_internal_originalTime": 1689792686000
    },
    {
        "time": 1689792687000,
        "value": 139.649,
        "_internal_originalTime": 1689792687000
    },
    {
        "time": 1689792689000,
        "value": 139.64,
        "_internal_originalTime": 1689792689000
    },
    {
        "time": 1689792690000,
        "value": 139.641,
        "_internal_originalTime": 1689792690000
    },
    {
        "time": 1689792691000,
        "value": 139.64,
        "_internal_originalTime": 1689792691000
    },
    {
        "time": 1689792692000,
        "value": 139.641,
        "_internal_originalTime": 1689792692000
    },
    {
        "time": 1689792693000,
        "value": 139.642,
        "_internal_originalTime": 1689792693000
    },
    {
        "time": 1689792694000,
        "value": 139.642,
        "_internal_originalTime": 1689792694000
    },
    {
        "time": 1689792697000,
        "value": 139.637,
        "_internal_originalTime": 1689792697000
    },
    {
        "time": 1689792699000,
        "value": 139.638,
        "_internal_originalTime": 1689792699000
    },
    {
        "time": 1689792700000,
        "value": 139.641,
        "_internal_originalTime": 1689792700000
    },
    {
        "time": 1689792703000,
        "value": 139.637,
        "_internal_originalTime": 1689792703000
    },
    {
        "time": 1689792704000,
        "value": 139.638,
        "_internal_originalTime": 1689792704000
    },
    {
        "time": 1689792707000,
        "value": 139.646,
        "_internal_originalTime": 1689792707000
    },
    {
        "time": 1689792708000,
        "value": 139.642,
        "_internal_originalTime": 1689792708000
    },
    {
        "time": 1689792709000,
        "value": 139.644,
        "_internal_originalTime": 1689792709000
    },
    {
        "time": 1689792710000,
        "value": 139.645,
        "_internal_originalTime": 1689792710000
    },
    {
        "time": 1689792711000,
        "value": 139.647,
        "_internal_originalTime": 1689792711000
    },
    {
        "time": 1689792713000,
        "value": 139.649,
        "_internal_originalTime": 1689792713000
    },
    {
        "time": 1689792714000,
        "value": 139.647,
        "_internal_originalTime": 1689792714000
    },
    {
        "time": 1689792715000,
        "value": 139.647,
        "_internal_originalTime": 1689792715000
    },
    {
        "time": 1689792717000,
        "value": 139.642,
        "_internal_originalTime": 1689792717000
    },
    {
        "time": 1689792718000,
        "value": 139.639,
        "_internal_originalTime": 1689792718000
    },
    {
        "time": 1689792721000,
        "value": 139.639,
        "_internal_originalTime": 1689792721000
    },
    {
        "time": 1689792722000,
        "value": 139.636,
        "_internal_originalTime": 1689792722000
    },
    {
        "time": 1689792726000,
        "value": 139.639,
        "_internal_originalTime": 1689792726000
    },
    {
        "time": 1689792728000,
        "value": 139.639,
        "_internal_originalTime": 1689792728000
    },
    {
        "time": 1689792737000,
        "value": 139.639,
        "_internal_originalTime": 1689792737000
    },
    {
        "time": 1689792738000,
        "value": 139.634,
        "_internal_originalTime": 1689792738000
    },
    {
        "time": 1689792740000,
        "value": 139.64,
        "_internal_originalTime": 1689792740000
    },
    {
        "time": 1689792743000,
        "value": 139.639,
        "_internal_originalTime": 1689792743000
    },
    {
        "time": 1689792746000,
        "value": 139.635,
        "_internal_originalTime": 1689792746000
    },
    {
        "time": 1689792751000,
        "value": 139.631,
        "_internal_originalTime": 1689792751000
    },
    {
        "time": 1689792752000,
        "value": 139.633,
        "_internal_originalTime": 1689792752000
    },
    {
        "time": 1689792753000,
        "value": 139.63,
        "_internal_originalTime": 1689792753000
    },
    {
        "time": 1689792754000,
        "value": 139.628,
        "_internal_originalTime": 1689792754000
    },
    {
        "time": 1689792756000,
        "value": 139.633,
        "_internal_originalTime": 1689792756000
    },
    {
        "time": 1689792758000,
        "value": 139.635,
        "_internal_originalTime": 1689792758000
    },
    {
        "time": 1689792762000,
        "value": 139.636,
        "_internal_originalTime": 1689792762000
    },
    {
        "time": 1689792763000,
        "value": 139.638,
        "_internal_originalTime": 1689792763000
    },
    {
        "time": 1689792767000,
        "value": 139.636,
        "_internal_originalTime": 1689792767000
    },
    {
        "time": 1689792769000,
        "value": 139.635,
        "_internal_originalTime": 1689792769000
    },
    {
        "time": 1689792770000,
        "value": 139.636,
        "_internal_originalTime": 1689792770000
    },
    {
        "time": 1689792771000,
        "value": 139.635,
        "_internal_originalTime": 1689792771000
    },
    {
        "time": 1689792772000,
        "value": 139.636,
        "_internal_originalTime": 1689792772000
    },
    {
        "time": 1689792773000,
        "value": 139.635,
        "_internal_originalTime": 1689792773000
    },
    {
        "time": 1689792776000,
        "value": 139.64,
        "_internal_originalTime": 1689792776000
    },
    {
        "time": 1689792777000,
        "value": 139.64,
        "_internal_originalTime": 1689792777000
    },
    {
        "time": 1689792778000,
        "value": 139.636,
        "_internal_originalTime": 1689792778000
    },
    {
        "time": 1689792779000,
        "value": 139.636,
        "_internal_originalTime": 1689792779000
    },
    {
        "time": 1689792781000,
        "value": 139.633,
        "_internal_originalTime": 1689792781000
    },
    {
        "time": 1689792782000,
        "value": 139.634,
        "_internal_originalTime": 1689792782000
    },
    {
        "time": 1689792783000,
        "value": 139.631,
        "_internal_originalTime": 1689792783000
    },
    {
        "time": 1689792785000,
        "value": 139.631,
        "_internal_originalTime": 1689792785000
    },
    {
        "time": 1689792786000,
        "value": 139.633,
        "_internal_originalTime": 1689792786000
    },
    {
        "time": 1689792787000,
        "value": 139.636,
        "_internal_originalTime": 1689792787000
    },
    {
        "time": 1689792788000,
        "value": 139.647,
        "_internal_originalTime": 1689792788000
    },
    {
        "time": 1689792789000,
        "value": 139.65,
        "_internal_originalTime": 1689792789000
    },
    {
        "time": 1689792790000,
        "value": 139.651,
        "_internal_originalTime": 1689792790000
    },
    {
        "time": 1689792792000,
        "value": 139.649,
        "_internal_originalTime": 1689792792000
    },
    {
        "time": 1689792793000,
        "value": 139.648,
        "_internal_originalTime": 1689792793000
    },
    {
        "time": 1689792795000,
        "value": 139.649,
        "_internal_originalTime": 1689792795000
    },
    {
        "time": 1689792796000,
        "value": 139.648,
        "_internal_originalTime": 1689792796000
    },
    {
        "time": 1689792797000,
        "value": 139.649,
        "_internal_originalTime": 1689792797000
    },
    {
        "time": 1689792799000,
        "value": 139.647,
        "_internal_originalTime": 1689792799000
    },
    {
        "time": 1689792801000,
        "value": 139.646,
        "_internal_originalTime": 1689792801000
    },
    {
        "time": 1689792802000,
        "value": 139.647,
        "_internal_originalTime": 1689792802000
    },
    {
        "time": 1689792804000,
        "value": 139.649,
        "_internal_originalTime": 1689792804000
    },
    {
        "time": 1689792806000,
        "value": 139.647,
        "_internal_originalTime": 1689792806000
    },
    {
        "time": 1689792808000,
        "value": 139.649,
        "_internal_originalTime": 1689792808000
    },
    {
        "time": 1689792814000,
        "value": 139.648,
        "_internal_originalTime": 1689792814000
    },
    {
        "time": 1689792818000,
        "value": 139.646,
        "_internal_originalTime": 1689792818000
    },
    {
        "time": 1689792820000,
        "value": 139.647,
        "_internal_originalTime": 1689792820000
    },
    {
        "time": 1689792822000,
        "value": 139.647,
        "_internal_originalTime": 1689792822000
    },
    {
        "time": 1689792823000,
        "value": 139.647,
        "_internal_originalTime": 1689792823000
    },
    {
        "time": 1689792825000,
        "value": 139.647,
        "_internal_originalTime": 1689792825000
    },
    {
        "time": 1689792826000,
        "value": 139.644,
        "_internal_originalTime": 1689792826000
    },
    {
        "time": 1689792827000,
        "value": 139.645,
        "_internal_originalTime": 1689792827000
    },
    {
        "time": 1689792829000,
        "value": 139.642,
        "_internal_originalTime": 1689792829000
    },
    {
        "time": 1689792836000,
        "value": 139.639,
        "_internal_originalTime": 1689792836000
    },
    {
        "time": 1689792839000,
        "value": 139.637,
        "_internal_originalTime": 1689792839000
    },
    {
        "time": 1689792840000,
        "value": 139.642,
        "_internal_originalTime": 1689792840000
    },
    {
        "time": 1689792841000,
        "value": 139.641,
        "_internal_originalTime": 1689792841000
    },
    {
        "time": 1689792843000,
        "value": 139.642,
        "_internal_originalTime": 1689792843000
    },
    {
        "time": 1689792845000,
        "value": 139.638,
        "_internal_originalTime": 1689792845000
    },
    {
        "time": 1689792848000,
        "value": 139.641,
        "_internal_originalTime": 1689792848000
    },
    {
        "time": 1689792850000,
        "value": 139.641,
        "_internal_originalTime": 1689792850000
    },
    {
        "time": 1689792851000,
        "value": 139.634,
        "_internal_originalTime": 1689792851000
    },
    {
        "time": 1689792852000,
        "value": 139.637,
        "_internal_originalTime": 1689792852000
    },
    {
        "time": 1689792853000,
        "value": 139.638,
        "_internal_originalTime": 1689792853000
    },
    {
        "time": 1689792856000,
        "value": 139.639,
        "_internal_originalTime": 1689792856000
    },
    {
        "time": 1689792858000,
        "value": 139.633,
        "_internal_originalTime": 1689792858000
    },
    {
        "time": 1689792860000,
        "value": 139.641,
        "_internal_originalTime": 1689792860000
    },
    {
        "time": 1689792865000,
        "value": 139.639,
        "_internal_originalTime": 1689792865000
    },
    {
        "time": 1689792867000,
        "value": 139.635,
        "_internal_originalTime": 1689792867000
    },
    {
        "time": 1689792869000,
        "value": 139.634,
        "_internal_originalTime": 1689792869000
    },
    {
        "time": 1689792871000,
        "value": 139.634,
        "_internal_originalTime": 1689792871000
    },
    {
        "time": 1689792872000,
        "value": 139.631,
        "_internal_originalTime": 1689792872000
    },
    {
        "time": 1689792874000,
        "value": 139.637,
        "_internal_originalTime": 1689792874000
    },
    {
        "time": 1689792876000,
        "value": 139.637,
        "_internal_originalTime": 1689792876000
    },
    {
        "time": 1689792878000,
        "value": 139.634,
        "_internal_originalTime": 1689792878000
    },
    {
        "time": 1689792879000,
        "value": 139.633,
        "_internal_originalTime": 1689792879000
    },
    {
        "time": 1689792880000,
        "value": 139.637,
        "_internal_originalTime": 1689792880000
    },
    {
        "time": 1689792881000,
        "value": 139.638,
        "_internal_originalTime": 1689792881000
    },
    {
        "time": 1689792882000,
        "value": 139.638,
        "_internal_originalTime": 1689792882000
    },
    {
        "time": 1689792884000,
        "value": 139.639,
        "_internal_originalTime": 1689792884000
    },
    {
        "time": 1689792886000,
        "value": 139.634,
        "_internal_originalTime": 1689792886000
    },
    {
        "time": 1689792887000,
        "value": 139.637,
        "_internal_originalTime": 1689792887000
    },
    {
        "time": 1689792888000,
        "value": 139.632,
        "_internal_originalTime": 1689792888000
    },
    {
        "time": 1689792889000,
        "value": 139.638,
        "_internal_originalTime": 1689792889000
    },
    {
        "time": 1689792892000,
        "value": 139.635,
        "_internal_originalTime": 1689792892000
    },
    {
        "time": 1689792893000,
        "value": 139.634,
        "_internal_originalTime": 1689792893000
    },
    {
        "time": 1689792894000,
        "value": 139.634,
        "_internal_originalTime": 1689792894000
    },
    {
        "time": 1689792896000,
        "value": 139.639,
        "_internal_originalTime": 1689792896000
    },
    {
        "time": 1689792897000,
        "value": 139.639,
        "_internal_originalTime": 1689792897000
    },
    {
        "time": 1689792899000,
        "value": 139.634,
        "_internal_originalTime": 1689792899000
    },
    {
        "time": 1689792900000,
        "value": 139.635,
        "_internal_originalTime": 1689792900000
    },
    {
        "time": 1689792901000,
        "value": 139.639,
        "_internal_originalTime": 1689792901000
    },
    {
        "time": 1689792903000,
        "value": 139.642,
        "_internal_originalTime": 1689792903000
    },
    {
        "time": 1689792904000,
        "value": 139.638,
        "_internal_originalTime": 1689792904000
    },
    {
        "time": 1689792906000,
        "value": 139.639,
        "_internal_originalTime": 1689792906000
    },
    {
        "time": 1689792908000,
        "value": 139.639,
        "_internal_originalTime": 1689792908000
    },
    {
        "time": 1689792909000,
        "value": 139.638,
        "_internal_originalTime": 1689792909000
    },
    {
        "time": 1689792910000,
        "value": 139.637,
        "_internal_originalTime": 1689792910000
    },
    {
        "time": 1689792911000,
        "value": 139.638,
        "_internal_originalTime": 1689792911000
    },
    {
        "time": 1689792912000,
        "value": 139.638,
        "_internal_originalTime": 1689792912000
    },
    {
        "time": 1689792913000,
        "value": 139.637,
        "_internal_originalTime": 1689792913000
    },
    {
        "time": 1689792917000,
        "value": 139.637,
        "_internal_originalTime": 1689792917000
    },
    {
        "time": 1689792919000,
        "value": 139.637,
        "_internal_originalTime": 1689792919000
    },
    {
        "time": 1689792920000,
        "value": 139.637,
        "_internal_originalTime": 1689792920000
    },
    {
        "time": 1689792921000,
        "value": 139.636,
        "_internal_originalTime": 1689792921000
    },
    {
        "time": 1689792922000,
        "value": 139.63,
        "_internal_originalTime": 1689792922000
    },
    {
        "time": 1689792923000,
        "value": 139.626,
        "_internal_originalTime": 1689792923000
    },
    {
        "time": 1689792925000,
        "value": 139.629,
        "_internal_originalTime": 1689792925000
    },
    {
        "time": 1689792926000,
        "value": 139.626,
        "_internal_originalTime": 1689792926000
    },
    {
        "time": 1689792927000,
        "value": 139.629,
        "_internal_originalTime": 1689792927000
    },
    {
        "time": 1689792928000,
        "value": 139.619,
        "_internal_originalTime": 1689792928000
    },
    {
        "time": 1689792930000,
        "value": 139.615,
        "_internal_originalTime": 1689792930000
    },
    {
        "time": 1689792932000,
        "value": 139.618,
        "_internal_originalTime": 1689792932000
    },
    {
        "time": 1689792933000,
        "value": 139.618,
        "_internal_originalTime": 1689792933000
    },
    {
        "time": 1689792934000,
        "value": 139.619,
        "_internal_originalTime": 1689792934000
    },
    {
        "time": 1689792936000,
        "value": 139.618,
        "_internal_originalTime": 1689792936000
    },
    {
        "time": 1689792938000,
        "value": 139.616,
        "_internal_originalTime": 1689792938000
    },
    {
        "time": 1689792941000,
        "value": 139.62,
        "_internal_originalTime": 1689792941000
    },
    {
        "time": 1689792942000,
        "value": 139.617,
        "_internal_originalTime": 1689792942000
    },
    {
        "time": 1689792943000,
        "value": 139.616,
        "_internal_originalTime": 1689792943000
    },
    {
        "time": 1689792946000,
        "value": 139.617,
        "_internal_originalTime": 1689792946000
    },
    {
        "time": 1689792947000,
        "value": 139.62,
        "_internal_originalTime": 1689792947000
    },
    {
        "time": 1689792948000,
        "value": 139.616,
        "_internal_originalTime": 1689792948000
    },
    {
        "time": 1689792950000,
        "value": 139.62,
        "_internal_originalTime": 1689792950000
    },
    {
        "time": 1689792952000,
        "value": 139.616,
        "_internal_originalTime": 1689792952000
    },
    {
        "time": 1689792953000,
        "value": 139.614,
        "_internal_originalTime": 1689792953000
    },
    {
        "time": 1689792954000,
        "value": 139.616,
        "_internal_originalTime": 1689792954000
    },
    {
        "time": 1689792955000,
        "value": 139.616,
        "_internal_originalTime": 1689792955000
    },
    {
        "time": 1689792956000,
        "value": 139.62,
        "_internal_originalTime": 1689792956000
    },
    {
        "time": 1689792958000,
        "value": 139.619,
        "_internal_originalTime": 1689792958000
    },
    {
        "time": 1689792962000,
        "value": 139.621,
        "_internal_originalTime": 1689792962000
    },
    {
        "time": 1689792963000,
        "value": 139.625,
        "_internal_originalTime": 1689792963000
    },
    {
        "time": 1689792964000,
        "value": 139.626,
        "_internal_originalTime": 1689792964000
    },
    {
        "time": 1689792965000,
        "value": 139.621,
        "_internal_originalTime": 1689792965000
    },
    {
        "time": 1689792967000,
        "value": 139.627,
        "_internal_originalTime": 1689792967000
    },
    {
        "time": 1689792968000,
        "value": 139.627,
        "_internal_originalTime": 1689792968000
    },
    {
        "time": 1689792970000,
        "value": 139.629,
        "_internal_originalTime": 1689792970000
    },
    {
        "time": 1689792971000,
        "value": 139.63,
        "_internal_originalTime": 1689792971000
    },
    {
        "time": 1689792973000,
        "value": 139.631,
        "_internal_originalTime": 1689792973000
    },
    {
        "time": 1689792974000,
        "value": 139.631,
        "_internal_originalTime": 1689792974000
    },
    {
        "time": 1689792975000,
        "value": 139.631,
        "_internal_originalTime": 1689792975000
    },
    {
        "time": 1689792976000,
        "value": 139.632,
        "_internal_originalTime": 1689792976000
    },
    {
        "time": 1689792977000,
        "value": 139.633,
        "_internal_originalTime": 1689792977000
    },
    {
        "time": 1689792978000,
        "value": 139.635,
        "_internal_originalTime": 1689792978000
    },
    {
        "time": 1689792979000,
        "value": 139.631,
        "_internal_originalTime": 1689792979000
    },
    {
        "time": 1689792980000,
        "value": 139.628,
        "_internal_originalTime": 1689792980000
    },
    {
        "time": 1689792982000,
        "value": 139.628,
        "_internal_originalTime": 1689792982000
    },
    {
        "time": 1689792984000,
        "value": 139.632,
        "_internal_originalTime": 1689792984000
    },
    {
        "time": 1689792985000,
        "value": 139.631,
        "_internal_originalTime": 1689792985000
    },
    {
        "time": 1689792988000,
        "value": 139.628,
        "_internal_originalTime": 1689792988000
    },
    {
        "time": 1689792990000,
        "value": 139.628,
        "_internal_originalTime": 1689792990000
    },
    {
        "time": 1689792993000,
        "value": 139.628,
        "_internal_originalTime": 1689792993000
    },
    {
        "time": 1689792994000,
        "value": 139.627,
        "_internal_originalTime": 1689792994000
    },
    {
        "time": 1689792997000,
        "value": 139.629,
        "_internal_originalTime": 1689792997000
    },
    {
        "time": 1689792998000,
        "value": 139.63,
        "_internal_originalTime": 1689792998000
    },
    {
        "time": 1689793000000,
        "value": 139.63,
        "_internal_originalTime": 1689793000000
    },
    {
        "time": 1689793001000,
        "value": 139.627,
        "_internal_originalTime": 1689793001000
    },
    {
        "time": 1689793002000,
        "value": 139.627,
        "_internal_originalTime": 1689793002000
    },
    {
        "time": 1689793005000,
        "value": 139.63,
        "_internal_originalTime": 1689793005000
    },
    {
        "time": 1689793007000,
        "value": 139.629,
        "_internal_originalTime": 1689793007000
    },
    {
        "time": 1689793008000,
        "value": 139.623,
        "_internal_originalTime": 1689793008000
    },
    {
        "time": 1689793010000,
        "value": 139.63,
        "_internal_originalTime": 1689793010000
    },
    {
        "time": 1689793012000,
        "value": 139.627,
        "_internal_originalTime": 1689793012000
    },
    {
        "time": 1689793013000,
        "value": 139.627,
        "_internal_originalTime": 1689793013000
    },
    {
        "time": 1689793014000,
        "value": 139.627,
        "_internal_originalTime": 1689793014000
    },
    {
        "time": 1689793015000,
        "value": 139.631,
        "_internal_originalTime": 1689793015000
    },
    {
        "time": 1689793016000,
        "value": 139.628,
        "_internal_originalTime": 1689793016000
    },
    {
        "time": 1689793022000,
        "value": 139.628,
        "_internal_originalTime": 1689793022000
    },
    {
        "time": 1689793024000,
        "value": 139.63,
        "_internal_originalTime": 1689793024000
    },
    {
        "time": 1689793025000,
        "value": 139.628,
        "_internal_originalTime": 1689793025000
    },
    {
        "time": 1689793026000,
        "value": 139.628,
        "_internal_originalTime": 1689793026000
    },
    {
        "time": 1689793028000,
        "value": 139.626,
        "_internal_originalTime": 1689793028000
    },
    {
        "time": 1689793031000,
        "value": 139.631,
        "_internal_originalTime": 1689793031000
    },
    {
        "time": 1689793035000,
        "value": 139.638,
        "_internal_originalTime": 1689793035000
    },
    {
        "time": 1689793036000,
        "value": 139.64,
        "_internal_originalTime": 1689793036000
    },
    {
        "time": 1689793037000,
        "value": 139.641,
        "_internal_originalTime": 1689793037000
    },
    {
        "time": 1689793038000,
        "value": 139.636,
        "_internal_originalTime": 1689793038000
    },
    {
        "time": 1689793040000,
        "value": 139.641,
        "_internal_originalTime": 1689793040000
    },
    {
        "time": 1689793041000,
        "value": 139.64,
        "_internal_originalTime": 1689793041000
    },
    {
        "time": 1689793042000,
        "value": 139.639,
        "_internal_originalTime": 1689793042000
    },
    {
        "time": 1689793044000,
        "value": 139.636,
        "_internal_originalTime": 1689793044000
    },
    {
        "time": 1689793045000,
        "value": 139.636,
        "_internal_originalTime": 1689793045000
    },
    {
        "time": 1689793049000,
        "value": 139.636,
        "_internal_originalTime": 1689793049000
    },
    {
        "time": 1689793050000,
        "value": 139.638,
        "_internal_originalTime": 1689793050000
    },
    {
        "time": 1689793051000,
        "value": 139.642,
        "_internal_originalTime": 1689793051000
    },
    {
        "time": 1689793053000,
        "value": 139.639,
        "_internal_originalTime": 1689793053000
    },
    {
        "time": 1689793055000,
        "value": 139.64,
        "_internal_originalTime": 1689793055000
    },
    {
        "time": 1689793056000,
        "value": 139.639,
        "_internal_originalTime": 1689793056000
    },
    {
        "time": 1689793058000,
        "value": 139.638,
        "_internal_originalTime": 1689793058000
    },
    {
        "time": 1689793060000,
        "value": 139.634,
        "_internal_originalTime": 1689793060000
    },
    {
        "time": 1689793061000,
        "value": 139.635,
        "_internal_originalTime": 1689793061000
    },
    {
        "time": 1689793062000,
        "value": 139.633,
        "_internal_originalTime": 1689793062000
    },
    {
        "time": 1689793063000,
        "value": 139.635,
        "_internal_originalTime": 1689793063000
    },
    {
        "time": 1689793064000,
        "value": 139.638,
        "_internal_originalTime": 1689793064000
    },
    {
        "time": 1689793065000,
        "value": 139.636,
        "_internal_originalTime": 1689793065000
    },
    {
        "time": 1689793066000,
        "value": 139.639,
        "_internal_originalTime": 1689793066000
    },
    {
        "time": 1689793068000,
        "value": 139.632,
        "_internal_originalTime": 1689793068000
    },
    {
        "time": 1689793071000,
        "value": 139.638,
        "_internal_originalTime": 1689793071000
    },
    {
        "time": 1689793072000,
        "value": 139.64,
        "_internal_originalTime": 1689793072000
    },
    {
        "time": 1689793073000,
        "value": 139.639,
        "_internal_originalTime": 1689793073000
    },
    {
        "time": 1689793074000,
        "value": 139.638,
        "_internal_originalTime": 1689793074000
    },
    {
        "time": 1689793075000,
        "value": 139.636,
        "_internal_originalTime": 1689793075000
    },
    {
        "time": 1689793076000,
        "value": 139.642,
        "_internal_originalTime": 1689793076000
    },
    {
        "time": 1689793077000,
        "value": 139.638,
        "_internal_originalTime": 1689793077000
    },
    {
        "time": 1689793078000,
        "value": 139.638,
        "_internal_originalTime": 1689793078000
    },
    {
        "time": 1689793079000,
        "value": 139.636,
        "_internal_originalTime": 1689793079000
    },
    {
        "time": 1689793080000,
        "value": 139.641,
        "_internal_originalTime": 1689793080000
    },
    {
        "time": 1689793081000,
        "value": 139.639,
        "_internal_originalTime": 1689793081000
    },
    {
        "time": 1689793083000,
        "value": 139.637,
        "_internal_originalTime": 1689793083000
    },
    {
        "time": 1689793084000,
        "value": 139.637,
        "_internal_originalTime": 1689793084000
    },
    {
        "time": 1689793085000,
        "value": 139.633,
        "_internal_originalTime": 1689793085000
    },
    {
        "time": 1689793086000,
        "value": 139.632,
        "_internal_originalTime": 1689793086000
    },
    {
        "time": 1689793088000,
        "value": 139.631,
        "_internal_originalTime": 1689793088000
    },
    {
        "time": 1689793089000,
        "value": 139.636,
        "_internal_originalTime": 1689793089000
    },
    {
        "time": 1689793091000,
        "value": 139.627,
        "_internal_originalTime": 1689793091000
    },
    {
        "time": 1689793094000,
        "value": 139.633,
        "_internal_originalTime": 1689793094000
    },
    {
        "time": 1689793095000,
        "value": 139.636,
        "_internal_originalTime": 1689793095000
    },
    {
        "time": 1689793096000,
        "value": 139.635,
        "_internal_originalTime": 1689793096000
    },
    {
        "time": 1689793097000,
        "value": 139.635,
        "_internal_originalTime": 1689793097000
    },
    {
        "time": 1689793099000,
        "value": 139.638,
        "_internal_originalTime": 1689793099000
    },
    {
        "time": 1689793100000,
        "value": 139.638,
        "_internal_originalTime": 1689793100000
    },
    {
        "time": 1689793101000,
        "value": 139.638,
        "_internal_originalTime": 1689793101000
    },
    {
        "time": 1689793102000,
        "value": 139.633,
        "_internal_originalTime": 1689793102000
    },
    {
        "time": 1689793105000,
        "value": 139.635,
        "_internal_originalTime": 1689793105000
    },
    {
        "time": 1689793107000,
        "value": 139.638,
        "_internal_originalTime": 1689793107000
    },
    {
        "time": 1689793108000,
        "value": 139.63,
        "_internal_originalTime": 1689793108000
    },
    {
        "time": 1689793109000,
        "value": 139.628,
        "_internal_originalTime": 1689793109000
    },
    {
        "time": 1689793110000,
        "value": 139.626,
        "_internal_originalTime": 1689793110000
    },
    {
        "time": 1689793112000,
        "value": 139.631,
        "_internal_originalTime": 1689793112000
    },
    {
        "time": 1689793118000,
        "value": 139.628,
        "_internal_originalTime": 1689793118000
    },
    {
        "time": 1689793122000,
        "value": 139.625,
        "_internal_originalTime": 1689793122000
    },
    {
        "time": 1689793125000,
        "value": 139.629,
        "_internal_originalTime": 1689793125000
    },
    {
        "time": 1689793127000,
        "value": 139.626,
        "_internal_originalTime": 1689793127000
    },
    {
        "time": 1689793128000,
        "value": 139.625,
        "_internal_originalTime": 1689793128000
    },
    {
        "time": 1689793132000,
        "value": 139.623,
        "_internal_originalTime": 1689793132000
    },
    {
        "time": 1689793133000,
        "value": 139.625,
        "_internal_originalTime": 1689793133000
    },
    {
        "time": 1689793134000,
        "value": 139.623,
        "_internal_originalTime": 1689793134000
    },
    {
        "time": 1689793137000,
        "value": 139.625,
        "_internal_originalTime": 1689793137000
    },
    {
        "time": 1689793138000,
        "value": 139.623,
        "_internal_originalTime": 1689793138000
    },
    {
        "time": 1689793140000,
        "value": 139.623,
        "_internal_originalTime": 1689793140000
    },
    {
        "time": 1689793142000,
        "value": 139.625,
        "_internal_originalTime": 1689793142000
    },
    {
        "time": 1689793143000,
        "value": 139.62,
        "_internal_originalTime": 1689793143000
    },
    {
        "time": 1689793144000,
        "value": 139.619,
        "_internal_originalTime": 1689793144000
    },
    {
        "time": 1689793145000,
        "value": 139.622,
        "_internal_originalTime": 1689793145000
    },
    {
        "time": 1689793147000,
        "value": 139.623,
        "_internal_originalTime": 1689793147000
    },
    {
        "time": 1689793148000,
        "value": 139.622,
        "_internal_originalTime": 1689793148000
    },
    {
        "time": 1689793149000,
        "value": 139.618,
        "_internal_originalTime": 1689793149000
    },
    {
        "time": 1689793151000,
        "value": 139.622,
        "_internal_originalTime": 1689793151000
    },
    {
        "time": 1689793152000,
        "value": 139.62,
        "_internal_originalTime": 1689793152000
    },
    {
        "time": 1689793154000,
        "value": 139.619,
        "_internal_originalTime": 1689793154000
    },
    {
        "time": 1689793155000,
        "value": 139.619,
        "_internal_originalTime": 1689793155000
    },
    {
        "time": 1689793157000,
        "value": 139.614,
        "_internal_originalTime": 1689793157000
    },
    {
        "time": 1689793158000,
        "value": 139.607,
        "_internal_originalTime": 1689793158000
    },
    {
        "time": 1689793159000,
        "value": 139.614,
        "_internal_originalTime": 1689793159000
    },
    {
        "time": 1689793160000,
        "value": 139.615,
        "_internal_originalTime": 1689793160000
    },
    {
        "time": 1689793162000,
        "value": 139.61,
        "_internal_originalTime": 1689793162000
    },
    {
        "time": 1689793164000,
        "value": 139.614,
        "_internal_originalTime": 1689793164000
    },
    {
        "time": 1689793166000,
        "value": 139.611,
        "_internal_originalTime": 1689793166000
    },
    {
        "time": 1689793167000,
        "value": 139.611,
        "_internal_originalTime": 1689793167000
    },
    {
        "time": 1689793169000,
        "value": 139.615,
        "_internal_originalTime": 1689793169000
    },
    {
        "time": 1689793170000,
        "value": 139.611,
        "_internal_originalTime": 1689793170000
    },
    {
        "time": 1689793171000,
        "value": 139.61,
        "_internal_originalTime": 1689793171000
    },
    {
        "time": 1689793172000,
        "value": 139.613,
        "_internal_originalTime": 1689793172000
    },
    {
        "time": 1689793173000,
        "value": 139.609,
        "_internal_originalTime": 1689793173000
    },
    {
        "time": 1689793178000,
        "value": 139.611,
        "_internal_originalTime": 1689793178000
    },
    {
        "time": 1689793180000,
        "value": 139.608,
        "_internal_originalTime": 1689793180000
    },
    {
        "time": 1689793181000,
        "value": 139.607,
        "_internal_originalTime": 1689793181000
    },
    {
        "time": 1689793182000,
        "value": 139.611,
        "_internal_originalTime": 1689793182000
    },
    {
        "time": 1689793183000,
        "value": 139.611,
        "_internal_originalTime": 1689793183000
    },
    {
        "time": 1689793184000,
        "value": 139.606,
        "_internal_originalTime": 1689793184000
    },
    {
        "time": 1689793185000,
        "value": 139.606,
        "_internal_originalTime": 1689793185000
    },
    {
        "time": 1689793186000,
        "value": 139.612,
        "_internal_originalTime": 1689793186000
    },
    {
        "time": 1689793188000,
        "value": 139.606,
        "_internal_originalTime": 1689793188000
    },
    {
        "time": 1689793189000,
        "value": 139.613,
        "_internal_originalTime": 1689793189000
    },
    {
        "time": 1689793190000,
        "value": 139.608,
        "_internal_originalTime": 1689793190000
    },
    {
        "time": 1689793191000,
        "value": 139.617,
        "_internal_originalTime": 1689793191000
    },
    {
        "time": 1689793192000,
        "value": 139.617,
        "_internal_originalTime": 1689793192000
    },
    {
        "time": 1689793193000,
        "value": 139.617,
        "_internal_originalTime": 1689793193000
    },
    {
        "time": 1689793194000,
        "value": 139.617,
        "_internal_originalTime": 1689793194000
    },
    {
        "time": 1689793195000,
        "value": 139.616,
        "_internal_originalTime": 1689793195000
    },
    {
        "time": 1689793196000,
        "value": 139.617,
        "_internal_originalTime": 1689793196000
    },
    {
        "time": 1689793197000,
        "value": 139.616,
        "_internal_originalTime": 1689793197000
    },
    {
        "time": 1689793198000,
        "value": 139.619,
        "_internal_originalTime": 1689793198000
    },
    {
        "time": 1689793200000,
        "value": 139.619,
        "_internal_originalTime": 1689793200000
    },
    {
        "time": 1689793201000,
        "value": 139.618,
        "_internal_originalTime": 1689793201000
    },
    {
        "time": 1689793202000,
        "value": 139.619,
        "_internal_originalTime": 1689793202000
    },
    {
        "time": 1689793203000,
        "value": 139.618,
        "_internal_originalTime": 1689793203000
    },
    {
        "time": 1689793205000,
        "value": 139.618,
        "_internal_originalTime": 1689793205000
    },
    {
        "time": 1689793206000,
        "value": 139.619,
        "_internal_originalTime": 1689793206000
    },
    {
        "time": 1689793208000,
        "value": 139.62,
        "_internal_originalTime": 1689793208000
    },
    {
        "time": 1689793210000,
        "value": 139.618,
        "_internal_originalTime": 1689793210000
    },
    {
        "time": 1689793212000,
        "value": 139.619,
        "_internal_originalTime": 1689793212000
    },
    {
        "time": 1689793213000,
        "value": 139.617,
        "_internal_originalTime": 1689793213000
    },
    {
        "time": 1689793214000,
        "value": 139.617,
        "_internal_originalTime": 1689793214000
    },
    {
        "time": 1689793218000,
        "value": 139.616,
        "_internal_originalTime": 1689793218000
    },
    {
        "time": 1689793224000,
        "value": 139.617,
        "_internal_originalTime": 1689793224000
    },
    {
        "time": 1689793225000,
        "value": 139.616,
        "_internal_originalTime": 1689793225000
    },
    {
        "time": 1689793227000,
        "value": 139.617,
        "_internal_originalTime": 1689793227000
    },
    {
        "time": 1689793238000,
        "value": 139.617,
        "_internal_originalTime": 1689793238000
    },
    {
        "time": 1689793239000,
        "value": 139.616,
        "_internal_originalTime": 1689793239000
    },
    {
        "time": 1689793241000,
        "value": 139.617,
        "_internal_originalTime": 1689793241000
    },
    {
        "time": 1689793242000,
        "value": 139.617,
        "_internal_originalTime": 1689793242000
    },
    {
        "time": 1689793243000,
        "value": 139.621,
        "_internal_originalTime": 1689793243000
    },
    {
        "time": 1689793245000,
        "value": 139.619,
        "_internal_originalTime": 1689793245000
    },
    {
        "time": 1689793246000,
        "value": 139.619,
        "_internal_originalTime": 1689793246000
    },
    {
        "time": 1689793247000,
        "value": 139.617,
        "_internal_originalTime": 1689793247000
    },
    {
        "time": 1689793249000,
        "value": 139.614,
        "_internal_originalTime": 1689793249000
    },
    {
        "time": 1689793251000,
        "value": 139.612,
        "_internal_originalTime": 1689793251000
    },
    {
        "time": 1689793258000,
        "value": 139.614,
        "_internal_originalTime": 1689793258000
    },
    {
        "time": 1689793259000,
        "value": 139.611,
        "_internal_originalTime": 1689793259000
    },
    {
        "time": 1689793261000,
        "value": 139.606,
        "_internal_originalTime": 1689793261000
    },
    {
        "time": 1689793262000,
        "value": 139.598,
        "_internal_originalTime": 1689793262000
    },
    {
        "time": 1689793263000,
        "value": 139.599,
        "_internal_originalTime": 1689793263000
    },
    {
        "time": 1689793265000,
        "value": 139.601,
        "_internal_originalTime": 1689793265000
    },
    {
        "time": 1689793267000,
        "value": 139.599,
        "_internal_originalTime": 1689793267000
    },
    {
        "time": 1689793268000,
        "value": 139.608,
        "_internal_originalTime": 1689793268000
    },
    {
        "time": 1689793269000,
        "value": 139.607,
        "_internal_originalTime": 1689793269000
    },
    {
        "time": 1689793271000,
        "value": 139.61,
        "_internal_originalTime": 1689793271000
    },
    {
        "time": 1689793272000,
        "value": 139.608,
        "_internal_originalTime": 1689793272000
    },
    {
        "time": 1689793273000,
        "value": 139.608,
        "_internal_originalTime": 1689793273000
    },
    {
        "time": 1689793275000,
        "value": 139.604,
        "_internal_originalTime": 1689793275000
    },
    {
        "time": 1689793277000,
        "value": 139.606,
        "_internal_originalTime": 1689793277000
    },
    {
        "time": 1689793278000,
        "value": 139.605,
        "_internal_originalTime": 1689793278000
    },
    {
        "time": 1689793280000,
        "value": 139.607,
        "_internal_originalTime": 1689793280000
    },
    {
        "time": 1689793282000,
        "value": 139.606,
        "_internal_originalTime": 1689793282000
    },
    {
        "time": 1689793283000,
        "value": 139.605,
        "_internal_originalTime": 1689793283000
    },
    {
        "time": 1689793284000,
        "value": 139.607,
        "_internal_originalTime": 1689793284000
    },
    {
        "time": 1689793286000,
        "value": 139.604,
        "_internal_originalTime": 1689793286000
    },
    {
        "time": 1689793291000,
        "value": 139.609,
        "_internal_originalTime": 1689793291000
    },
    {
        "time": 1689793293000,
        "value": 139.609,
        "_internal_originalTime": 1689793293000
    },
    {
        "time": 1689793297000,
        "value": 139.606,
        "_internal_originalTime": 1689793297000
    },
    {
        "time": 1689793298000,
        "value": 139.605,
        "_internal_originalTime": 1689793298000
    },
    {
        "time": 1689793300000,
        "value": 139.61,
        "_internal_originalTime": 1689793300000
    },
    {
        "time": 1689793302000,
        "value": 139.608,
        "_internal_originalTime": 1689793302000
    },
    {
        "time": 1689793304000,
        "value": 139.612,
        "_internal_originalTime": 1689793304000
    },
    {
        "time": 1689793305000,
        "value": 139.613,
        "_internal_originalTime": 1689793305000
    },
    {
        "time": 1689793307000,
        "value": 139.61,
        "_internal_originalTime": 1689793307000
    },
    {
        "time": 1689793308000,
        "value": 139.61,
        "_internal_originalTime": 1689793308000
    },
    {
        "time": 1689793310000,
        "value": 139.611,
        "_internal_originalTime": 1689793310000
    },
    {
        "time": 1689793311000,
        "value": 139.615,
        "_internal_originalTime": 1689793311000
    },
    {
        "time": 1689793313000,
        "value": 139.617,
        "_internal_originalTime": 1689793313000
    },
    {
        "time": 1689793314000,
        "value": 139.613,
        "_internal_originalTime": 1689793314000
    },
    {
        "time": 1689793316000,
        "value": 139.615,
        "_internal_originalTime": 1689793316000
    },
    {
        "time": 1689793319000,
        "value": 139.621,
        "_internal_originalTime": 1689793319000
    },
    {
        "time": 1689793320000,
        "value": 139.62,
        "_internal_originalTime": 1689793320000
    },
    {
        "time": 1689793321000,
        "value": 139.619,
        "_internal_originalTime": 1689793321000
    },
    {
        "time": 1689793322000,
        "value": 139.617,
        "_internal_originalTime": 1689793322000
    },
    {
        "time": 1689793324000,
        "value": 139.613,
        "_internal_originalTime": 1689793324000
    },
    {
        "time": 1689793325000,
        "value": 139.614,
        "_internal_originalTime": 1689793325000
    },
    {
        "time": 1689793326000,
        "value": 139.614,
        "_internal_originalTime": 1689793326000
    },
    {
        "time": 1689793328000,
        "value": 139.613,
        "_internal_originalTime": 1689793328000
    },
    {
        "time": 1689793329000,
        "value": 139.614,
        "_internal_originalTime": 1689793329000
    },
    {
        "time": 1689793330000,
        "value": 139.618,
        "_internal_originalTime": 1689793330000
    },
    {
        "time": 1689793332000,
        "value": 139.618,
        "_internal_originalTime": 1689793332000
    },
    {
        "time": 1689793333000,
        "value": 139.617,
        "_internal_originalTime": 1689793333000
    },
    {
        "time": 1689793336000,
        "value": 139.621,
        "_internal_originalTime": 1689793336000
    },
    {
        "time": 1689793337000,
        "value": 139.616,
        "_internal_originalTime": 1689793337000
    },
    {
        "time": 1689793338000,
        "value": 139.616,
        "_internal_originalTime": 1689793338000
    },
    {
        "time": 1689793339000,
        "value": 139.616,
        "_internal_originalTime": 1689793339000
    },
    {
        "time": 1689793342000,
        "value": 139.616,
        "_internal_originalTime": 1689793342000
    },
    {
        "time": 1689793343000,
        "value": 139.62,
        "_internal_originalTime": 1689793343000
    },
    {
        "time": 1689793345000,
        "value": 139.619,
        "_internal_originalTime": 1689793345000
    },
    {
        "time": 1689793347000,
        "value": 139.61,
        "_internal_originalTime": 1689793347000
    },
    {
        "time": 1689793348000,
        "value": 139.607,
        "_internal_originalTime": 1689793348000
    },
    {
        "time": 1689793350000,
        "value": 139.605,
        "_internal_originalTime": 1689793350000
    },
    {
        "time": 1689793351000,
        "value": 139.61,
        "_internal_originalTime": 1689793351000
    },
    {
        "time": 1689793352000,
        "value": 139.606,
        "_internal_originalTime": 1689793352000
    },
    {
        "time": 1689793353000,
        "value": 139.61,
        "_internal_originalTime": 1689793353000
    },
    {
        "time": 1689793354000,
        "value": 139.607,
        "_internal_originalTime": 1689793354000
    },
    {
        "time": 1689793355000,
        "value": 139.609,
        "_internal_originalTime": 1689793355000
    },
    {
        "time": 1689793356000,
        "value": 139.609,
        "_internal_originalTime": 1689793356000
    },
    {
        "time": 1689793358000,
        "value": 139.607,
        "_internal_originalTime": 1689793358000
    },
    {
        "time": 1689793359000,
        "value": 139.605,
        "_internal_originalTime": 1689793359000
    },
    {
        "time": 1689793360000,
        "value": 139.606,
        "_internal_originalTime": 1689793360000
    },
    {
        "time": 1689793362000,
        "value": 139.605,
        "_internal_originalTime": 1689793362000
    },
    {
        "time": 1689793363000,
        "value": 139.608,
        "_internal_originalTime": 1689793363000
    },
    {
        "time": 1689793365000,
        "value": 139.6,
        "_internal_originalTime": 1689793365000
    },
    {
        "time": 1689793366000,
        "value": 139.599,
        "_internal_originalTime": 1689793366000
    },
    {
        "time": 1689793367000,
        "value": 139.599,
        "_internal_originalTime": 1689793367000
    },
    {
        "time": 1689793368000,
        "value": 139.596,
        "_internal_originalTime": 1689793368000
    },
    {
        "time": 1689793369000,
        "value": 139.6,
        "_internal_originalTime": 1689793369000
    },
    {
        "time": 1689793370000,
        "value": 139.601,
        "_internal_originalTime": 1689793370000
    },
    {
        "time": 1689793372000,
        "value": 139.607,
        "_internal_originalTime": 1689793372000
    },
    {
        "time": 1689793374000,
        "value": 139.601,
        "_internal_originalTime": 1689793374000
    },
    {
        "time": 1689793375000,
        "value": 139.6,
        "_internal_originalTime": 1689793375000
    },
    {
        "time": 1689793377000,
        "value": 139.598,
        "_internal_originalTime": 1689793377000
    },
    {
        "time": 1689793379000,
        "value": 139.606,
        "_internal_originalTime": 1689793379000
    },
    {
        "time": 1689793380000,
        "value": 139.603,
        "_internal_originalTime": 1689793380000
    },
    {
        "time": 1689793382000,
        "value": 139.611,
        "_internal_originalTime": 1689793382000
    },
    {
        "time": 1689793383000,
        "value": 139.611,
        "_internal_originalTime": 1689793383000
    },
    {
        "time": 1689793385000,
        "value": 139.611,
        "_internal_originalTime": 1689793385000
    },
    {
        "time": 1689793386000,
        "value": 139.607,
        "_internal_originalTime": 1689793386000
    },
    {
        "time": 1689793387000,
        "value": 139.61,
        "_internal_originalTime": 1689793387000
    },
    {
        "time": 1689793388000,
        "value": 139.609,
        "_internal_originalTime": 1689793388000
    },
    {
        "time": 1689793390000,
        "value": 139.606,
        "_internal_originalTime": 1689793390000
    },
    {
        "time": 1689793391000,
        "value": 139.609,
        "_internal_originalTime": 1689793391000
    },
    {
        "time": 1689793393000,
        "value": 139.609,
        "_internal_originalTime": 1689793393000
    },
    {
        "time": 1689793394000,
        "value": 139.603,
        "_internal_originalTime": 1689793394000
    },
    {
        "time": 1689793396000,
        "value": 139.6,
        "_internal_originalTime": 1689793396000
    },
    {
        "time": 1689793397000,
        "value": 139.599,
        "_internal_originalTime": 1689793397000
    },
    {
        "time": 1689793404000,
        "value": 139.602,
        "_internal_originalTime": 1689793404000
    },
    {
        "time": 1689793405000,
        "value": 139.598,
        "_internal_originalTime": 1689793405000
    },
    {
        "time": 1689793406000,
        "value": 139.603,
        "_internal_originalTime": 1689793406000
    },
    {
        "time": 1689793407000,
        "value": 139.602,
        "_internal_originalTime": 1689793407000
    },
    {
        "time": 1689793408000,
        "value": 139.597,
        "_internal_originalTime": 1689793408000
    },
    {
        "time": 1689793409000,
        "value": 139.602,
        "_internal_originalTime": 1689793409000
    },
    {
        "time": 1689793411000,
        "value": 139.601,
        "_internal_originalTime": 1689793411000
    },
    {
        "time": 1689793419000,
        "value": 139.6,
        "_internal_originalTime": 1689793419000
    },
    {
        "time": 1689793420000,
        "value": 139.597,
        "_internal_originalTime": 1689793420000
    },
    {
        "time": 1689793424000,
        "value": 139.594,
        "_internal_originalTime": 1689793424000
    },
    {
        "time": 1689793425000,
        "value": 139.598,
        "_internal_originalTime": 1689793425000
    },
    {
        "time": 1689793429000,
        "value": 139.598,
        "_internal_originalTime": 1689793429000
    },
    {
        "time": 1689793430000,
        "value": 139.598,
        "_internal_originalTime": 1689793430000
    },
    {
        "time": 1689793432000,
        "value": 139.594,
        "_internal_originalTime": 1689793432000
    },
    {
        "time": 1689793433000,
        "value": 139.597,
        "_internal_originalTime": 1689793433000
    },
    {
        "time": 1689793434000,
        "value": 139.593,
        "_internal_originalTime": 1689793434000
    },
    {
        "time": 1689793435000,
        "value": 139.593,
        "_internal_originalTime": 1689793435000
    },
    {
        "time": 1689793437000,
        "value": 139.593,
        "_internal_originalTime": 1689793437000
    },
    {
        "time": 1689793439000,
        "value": 139.592,
        "_internal_originalTime": 1689793439000
    },
    {
        "time": 1689793440000,
        "value": 139.592,
        "_internal_originalTime": 1689793440000
    },
    {
        "time": 1689793441000,
        "value": 139.593,
        "_internal_originalTime": 1689793441000
    },
    {
        "time": 1689793442000,
        "value": 139.593,
        "_internal_originalTime": 1689793442000
    },
    {
        "time": 1689793443000,
        "value": 139.59,
        "_internal_originalTime": 1689793443000
    },
    {
        "time": 1689793444000,
        "value": 139.589,
        "_internal_originalTime": 1689793444000
    },
    {
        "time": 1689793445000,
        "value": 139.589,
        "_internal_originalTime": 1689793445000
    },
    {
        "time": 1689793448000,
        "value": 139.59,
        "_internal_originalTime": 1689793448000
    },
    {
        "time": 1689793449000,
        "value": 139.592,
        "_internal_originalTime": 1689793449000
    },
    {
        "time": 1689793450000,
        "value": 139.596,
        "_internal_originalTime": 1689793450000
    },
    {
        "time": 1689793451000,
        "value": 139.596,
        "_internal_originalTime": 1689793451000
    },
    {
        "time": 1689793452000,
        "value": 139.597,
        "_internal_originalTime": 1689793452000
    },
    {
        "time": 1689793453000,
        "value": 139.601,
        "_internal_originalTime": 1689793453000
    },
    {
        "time": 1689793455000,
        "value": 139.609,
        "_internal_originalTime": 1689793455000
    },
    {
        "time": 1689793457000,
        "value": 139.609,
        "_internal_originalTime": 1689793457000
    },
    {
        "time": 1689793458000,
        "value": 139.603,
        "_internal_originalTime": 1689793458000
    },
    {
        "time": 1689793460000,
        "value": 139.607,
        "_internal_originalTime": 1689793460000
    },
    {
        "time": 1689793462000,
        "value": 139.611,
        "_internal_originalTime": 1689793462000
    },
    {
        "time": 1689793464000,
        "value": 139.61,
        "_internal_originalTime": 1689793464000
    },
    {
        "time": 1689793466000,
        "value": 139.61,
        "_internal_originalTime": 1689793466000
    },
    {
        "time": 1689793467000,
        "value": 139.607,
        "_internal_originalTime": 1689793467000
    },
    {
        "time": 1689793470000,
        "value": 139.611,
        "_internal_originalTime": 1689793470000
    },
    {
        "time": 1689793473000,
        "value": 139.608,
        "_internal_originalTime": 1689793473000
    },
    {
        "time": 1689793476000,
        "value": 139.611,
        "_internal_originalTime": 1689793476000
    },
    {
        "time": 1689793477000,
        "value": 139.61,
        "_internal_originalTime": 1689793477000
    },
    {
        "time": 1689793478000,
        "value": 139.61,
        "_internal_originalTime": 1689793478000
    },
    {
        "time": 1689793480000,
        "value": 139.607,
        "_internal_originalTime": 1689793480000
    },
    {
        "time": 1689793481000,
        "value": 139.609,
        "_internal_originalTime": 1689793481000
    },
    {
        "time": 1689793482000,
        "value": 139.609,
        "_internal_originalTime": 1689793482000
    },
    {
        "time": 1689793484000,
        "value": 139.609,
        "_internal_originalTime": 1689793484000
    },
    {
        "time": 1689793486000,
        "value": 139.606,
        "_internal_originalTime": 1689793486000
    },
    {
        "time": 1689793487000,
        "value": 139.612,
        "_internal_originalTime": 1689793487000
    },
    {
        "time": 1689793488000,
        "value": 139.606,
        "_internal_originalTime": 1689793488000
    },
    {
        "time": 1689793492000,
        "value": 139.607,
        "_internal_originalTime": 1689793492000
    },
    {
        "time": 1689793494000,
        "value": 139.61,
        "_internal_originalTime": 1689793494000
    },
    {
        "time": 1689793496000,
        "value": 139.61,
        "_internal_originalTime": 1689793496000
    },
    {
        "time": 1689793497000,
        "value": 139.61,
        "_internal_originalTime": 1689793497000
    },
    {
        "time": 1689793498000,
        "value": 139.61,
        "_internal_originalTime": 1689793498000
    },
    {
        "time": 1689793499000,
        "value": 139.61,
        "_internal_originalTime": 1689793499000
    },
    {
        "time": 1689793500000,
        "value": 139.606,
        "_internal_originalTime": 1689793500000
    },
    {
        "time": 1689793501000,
        "value": 139.61,
        "_internal_originalTime": 1689793501000
    },
    {
        "time": 1689793504000,
        "value": 139.612,
        "_internal_originalTime": 1689793504000
    },
    {
        "time": 1689793506000,
        "value": 139.61,
        "_internal_originalTime": 1689793506000
    },
    {
        "time": 1689793508000,
        "value": 139.615,
        "_internal_originalTime": 1689793508000
    },
    {
        "time": 1689793510000,
        "value": 139.614,
        "_internal_originalTime": 1689793510000
    },
    {
        "time": 1689793512000,
        "value": 139.616,
        "_internal_originalTime": 1689793512000
    },
    {
        "time": 1689793513000,
        "value": 139.617,
        "_internal_originalTime": 1689793513000
    },
    {
        "time": 1689793514000,
        "value": 139.618,
        "_internal_originalTime": 1689793514000
    },
    {
        "time": 1689793515000,
        "value": 139.622,
        "_internal_originalTime": 1689793515000
    },
    {
        "time": 1689793516000,
        "value": 139.628,
        "_internal_originalTime": 1689793516000
    },
    {
        "time": 1689793517000,
        "value": 139.631,
        "_internal_originalTime": 1689793517000
    },
    {
        "time": 1689793518000,
        "value": 139.631,
        "_internal_originalTime": 1689793518000
    },
    {
        "time": 1689793520000,
        "value": 139.628,
        "_internal_originalTime": 1689793520000
    },
    {
        "time": 1689793521000,
        "value": 139.633,
        "_internal_originalTime": 1689793521000
    },
    {
        "time": 1689793522000,
        "value": 139.63,
        "_internal_originalTime": 1689793522000
    },
    {
        "time": 1689793524000,
        "value": 139.631,
        "_internal_originalTime": 1689793524000
    },
    {
        "time": 1689793526000,
        "value": 139.628,
        "_internal_originalTime": 1689793526000
    },
    {
        "time": 1689793527000,
        "value": 139.628,
        "_internal_originalTime": 1689793527000
    },
    {
        "time": 1689793529000,
        "value": 139.629,
        "_internal_originalTime": 1689793529000
    },
    {
        "time": 1689793531000,
        "value": 139.627,
        "_internal_originalTime": 1689793531000
    },
    {
        "time": 1689793534000,
        "value": 139.626,
        "_internal_originalTime": 1689793534000
    },
    {
        "time": 1689793535000,
        "value": 139.627,
        "_internal_originalTime": 1689793535000
    },
    {
        "time": 1689793536000,
        "value": 139.62,
        "_internal_originalTime": 1689793536000
    },
    {
        "time": 1689793537000,
        "value": 139.621,
        "_internal_originalTime": 1689793537000
    },
    {
        "time": 1689793538000,
        "value": 139.619,
        "_internal_originalTime": 1689793538000
    },
    {
        "time": 1689793539000,
        "value": 139.616,
        "_internal_originalTime": 1689793539000
    },
    {
        "time": 1689793540000,
        "value": 139.617,
        "_internal_originalTime": 1689793540000
    },
    {
        "time": 1689793541000,
        "value": 139.619,
        "_internal_originalTime": 1689793541000
    },
    {
        "time": 1689793543000,
        "value": 139.617,
        "_internal_originalTime": 1689793543000
    },
    {
        "time": 1689793545000,
        "value": 139.619,
        "_internal_originalTime": 1689793545000
    },
    {
        "time": 1689793546000,
        "value": 139.619,
        "_internal_originalTime": 1689793546000
    },
    {
        "time": 1689793547000,
        "value": 139.619,
        "_internal_originalTime": 1689793547000
    },
    {
        "time": 1689793548000,
        "value": 139.619,
        "_internal_originalTime": 1689793548000
    },
    {
        "time": 1689793549000,
        "value": 139.619,
        "_internal_originalTime": 1689793549000
    },
    {
        "time": 1689793550000,
        "value": 139.622,
        "_internal_originalTime": 1689793550000
    },
    {
        "time": 1689793551000,
        "value": 139.622,
        "_internal_originalTime": 1689793551000
    },
    {
        "time": 1689793552000,
        "value": 139.621,
        "_internal_originalTime": 1689793552000
    },
    {
        "time": 1689793554000,
        "value": 139.618,
        "_internal_originalTime": 1689793554000
    },
    {
        "time": 1689793556000,
        "value": 139.616,
        "_internal_originalTime": 1689793556000
    },
    {
        "time": 1689793560000,
        "value": 139.617,
        "_internal_originalTime": 1689793560000
    },
    {
        "time": 1689793561000,
        "value": 139.619,
        "_internal_originalTime": 1689793561000
    },
    {
        "time": 1689793562000,
        "value": 139.618,
        "_internal_originalTime": 1689793562000
    },
    {
        "time": 1689793564000,
        "value": 139.622,
        "_internal_originalTime": 1689793564000
    },
    {
        "time": 1689793565000,
        "value": 139.626,
        "_internal_originalTime": 1689793565000
    },
    {
        "time": 1689793566000,
        "value": 139.627,
        "_internal_originalTime": 1689793566000
    },
    {
        "time": 1689793568000,
        "value": 139.626,
        "_internal_originalTime": 1689793568000
    },
    {
        "time": 1689793570000,
        "value": 139.624,
        "_internal_originalTime": 1689793570000
    },
    {
        "time": 1689793574000,
        "value": 139.626,
        "_internal_originalTime": 1689793574000
    },
    {
        "time": 1689793578000,
        "value": 139.627,
        "_internal_originalTime": 1689793578000
    },
    {
        "time": 1689793579000,
        "value": 139.626,
        "_internal_originalTime": 1689793579000
    },
    {
        "time": 1689793580000,
        "value": 139.626,
        "_internal_originalTime": 1689793580000
    },
    {
        "time": 1689793581000,
        "value": 139.627,
        "_internal_originalTime": 1689793581000
    },
    {
        "time": 1689793582000,
        "value": 139.627,
        "_internal_originalTime": 1689793582000
    },
    {
        "time": 1689793596000,
        "value": 139.624,
        "_internal_originalTime": 1689793596000
    },
    {
        "time": 1689793598000,
        "value": 139.628,
        "_internal_originalTime": 1689793598000
    },
    {
        "time": 1689793605000,
        "value": 139.627,
        "_internal_originalTime": 1689793605000
    },
    {
        "time": 1689793606000,
        "value": 139.628,
        "_internal_originalTime": 1689793606000
    },
    {
        "time": 1689793607000,
        "value": 139.627,
        "_internal_originalTime": 1689793607000
    },
    {
        "time": 1689793608000,
        "value": 139.627,
        "_internal_originalTime": 1689793608000
    },
    {
        "time": 1689793610000,
        "value": 139.632,
        "_internal_originalTime": 1689793610000
    },
    {
        "time": 1689793612000,
        "value": 139.627,
        "_internal_originalTime": 1689793612000
    },
    {
        "time": 1689793613000,
        "value": 139.628,
        "_internal_originalTime": 1689793613000
    },
    {
        "time": 1689793614000,
        "value": 139.631,
        "_internal_originalTime": 1689793614000
    },
    {
        "time": 1689793616000,
        "value": 139.629,
        "_internal_originalTime": 1689793616000
    },
    {
        "time": 1689793617000,
        "value": 139.629,
        "_internal_originalTime": 1689793617000
    },
    {
        "time": 1689793618000,
        "value": 139.628,
        "_internal_originalTime": 1689793618000
    },
    {
        "time": 1689793620000,
        "value": 139.628,
        "_internal_originalTime": 1689793620000
    },
    {
        "time": 1689793622000,
        "value": 139.628,
        "_internal_originalTime": 1689793622000
    },
    {
        "time": 1689793624000,
        "value": 139.633,
        "_internal_originalTime": 1689793624000
    },
    {
        "time": 1689793626000,
        "value": 139.631,
        "_internal_originalTime": 1689793626000
    },
    {
        "time": 1689793628000,
        "value": 139.633,
        "_internal_originalTime": 1689793628000
    },
    {
        "time": 1689793629000,
        "value": 139.632,
        "_internal_originalTime": 1689793629000
    },
    {
        "time": 1689793630000,
        "value": 139.631,
        "_internal_originalTime": 1689793630000
    },
    {
        "time": 1689793631000,
        "value": 139.631,
        "_internal_originalTime": 1689793631000
    },
    {
        "time": 1689793632000,
        "value": 139.633,
        "_internal_originalTime": 1689793632000
    },
    {
        "time": 1689793634000,
        "value": 139.635,
        "_internal_originalTime": 1689793634000
    },
    {
        "time": 1689793635000,
        "value": 139.634,
        "_internal_originalTime": 1689793635000
    },
    {
        "time": 1689793636000,
        "value": 139.638,
        "_internal_originalTime": 1689793636000
    },
    {
        "time": 1689793637000,
        "value": 139.635,
        "_internal_originalTime": 1689793637000
    },
    {
        "time": 1689793639000,
        "value": 139.637,
        "_internal_originalTime": 1689793639000
    },
    {
        "time": 1689793640000,
        "value": 139.636,
        "_internal_originalTime": 1689793640000
    },
    {
        "time": 1689793642000,
        "value": 139.637,
        "_internal_originalTime": 1689793642000
    },
    {
        "time": 1689793644000,
        "value": 139.635,
        "_internal_originalTime": 1689793644000
    },
    {
        "time": 1689793646000,
        "value": 139.632,
        "_internal_originalTime": 1689793646000
    },
    {
        "time": 1689793649000,
        "value": 139.634,
        "_internal_originalTime": 1689793649000
    },
    {
        "time": 1689793650000,
        "value": 139.634,
        "_internal_originalTime": 1689793650000
    },
    {
        "time": 1689793651000,
        "value": 139.632,
        "_internal_originalTime": 1689793651000
    },
    {
        "time": 1689793653000,
        "value": 139.633,
        "_internal_originalTime": 1689793653000
    },
    {
        "time": 1689793658000,
        "value": 139.63,
        "_internal_originalTime": 1689793658000
    },
    {
        "time": 1689793659000,
        "value": 139.632,
        "_internal_originalTime": 1689793659000
    },
    {
        "time": 1689793663000,
        "value": 139.632,
        "_internal_originalTime": 1689793663000
    },
    {
        "time": 1689793665000,
        "value": 139.635,
        "_internal_originalTime": 1689793665000
    },
    {
        "time": 1689793666000,
        "value": 139.633,
        "_internal_originalTime": 1689793666000
    },
    {
        "time": 1689793667000,
        "value": 139.635,
        "_internal_originalTime": 1689793667000
    },
    {
        "time": 1689793668000,
        "value": 139.634,
        "_internal_originalTime": 1689793668000
    },
    {
        "time": 1689793669000,
        "value": 139.636,
        "_internal_originalTime": 1689793669000
    },
    {
        "time": 1689793670000,
        "value": 139.639,
        "_internal_originalTime": 1689793670000
    },
    {
        "time": 1689793672000,
        "value": 139.64,
        "_internal_originalTime": 1689793672000
    },
    {
        "time": 1689793674000,
        "value": 139.64,
        "_internal_originalTime": 1689793674000
    },
    {
        "time": 1689793677000,
        "value": 139.64,
        "_internal_originalTime": 1689793677000
    },
    {
        "time": 1689793678000,
        "value": 139.64,
        "_internal_originalTime": 1689793678000
    },
    {
        "time": 1689793681000,
        "value": 139.64,
        "_internal_originalTime": 1689793681000
    },
    {
        "time": 1689793686000,
        "value": 139.638,
        "_internal_originalTime": 1689793686000
    },
    {
        "time": 1689793688000,
        "value": 139.64,
        "_internal_originalTime": 1689793688000
    },
    {
        "time": 1689793690000,
        "value": 139.637,
        "_internal_originalTime": 1689793690000
    },
    {
        "time": 1689793691000,
        "value": 139.636,
        "_internal_originalTime": 1689793691000
    },
    {
        "time": 1689793692000,
        "value": 139.637,
        "_internal_originalTime": 1689793692000
    },
    {
        "time": 1689793693000,
        "value": 139.64,
        "_internal_originalTime": 1689793693000
    },
    {
        "time": 1689793694000,
        "value": 139.637,
        "_internal_originalTime": 1689793694000
    },
    {
        "time": 1689793695000,
        "value": 139.64,
        "_internal_originalTime": 1689793695000
    },
    {
        "time": 1689793697000,
        "value": 139.636,
        "_internal_originalTime": 1689793697000
    },
    {
        "time": 1689793698000,
        "value": 139.634,
        "_internal_originalTime": 1689793698000
    },
    {
        "time": 1689793700000,
        "value": 139.636,
        "_internal_originalTime": 1689793700000
    },
    {
        "time": 1689793704000,
        "value": 139.636,
        "_internal_originalTime": 1689793704000
    },
    {
        "time": 1689793705000,
        "value": 139.637,
        "_internal_originalTime": 1689793705000
    },
    {
        "time": 1689793708000,
        "value": 139.637,
        "_internal_originalTime": 1689793708000
    },
    {
        "time": 1689793709000,
        "value": 139.636,
        "_internal_originalTime": 1689793709000
    },
    {
        "time": 1689793717000,
        "value": 139.636,
        "_internal_originalTime": 1689793717000
    },
    {
        "time": 1689793718000,
        "value": 139.638,
        "_internal_originalTime": 1689793718000
    },
    {
        "time": 1689793722000,
        "value": 139.634,
        "_internal_originalTime": 1689793722000
    },
    {
        "time": 1689793725000,
        "value": 139.639,
        "_internal_originalTime": 1689793725000
    },
    {
        "time": 1689793729000,
        "value": 139.639,
        "_internal_originalTime": 1689793729000
    },
    {
        "time": 1689793736000,
        "value": 139.639,
        "_internal_originalTime": 1689793736000
    },
    {
        "time": 1689793738000,
        "value": 139.636,
        "_internal_originalTime": 1689793738000
    },
    {
        "time": 1689793739000,
        "value": 139.635,
        "_internal_originalTime": 1689793739000
    },
    {
        "time": 1689793742000,
        "value": 139.636,
        "_internal_originalTime": 1689793742000
    },
    {
        "time": 1689793744000,
        "value": 139.63,
        "_internal_originalTime": 1689793744000
    },
    {
        "time": 1689793745000,
        "value": 139.629,
        "_internal_originalTime": 1689793745000
    },
    {
        "time": 1689793747000,
        "value": 139.64,
        "_internal_originalTime": 1689793747000
    },
    {
        "time": 1689793748000,
        "value": 139.637,
        "_internal_originalTime": 1689793748000
    },
    {
        "time": 1689793749000,
        "value": 139.637,
        "_internal_originalTime": 1689793749000
    },
    {
        "time": 1689793750000,
        "value": 139.636,
        "_internal_originalTime": 1689793750000
    },
    {
        "time": 1689793751000,
        "value": 139.637,
        "_internal_originalTime": 1689793751000
    },
    {
        "time": 1689793752000,
        "value": 139.636,
        "_internal_originalTime": 1689793752000
    },
    {
        "time": 1689793753000,
        "value": 139.635,
        "_internal_originalTime": 1689793753000
    },
    {
        "time": 1689793758000,
        "value": 139.634,
        "_internal_originalTime": 1689793758000
    },
    {
        "time": 1689793772000,
        "value": 139.637,
        "_internal_originalTime": 1689793772000
    },
    {
        "time": 1689793774000,
        "value": 139.64,
        "_internal_originalTime": 1689793774000
    },
    {
        "time": 1689793775000,
        "value": 139.643,
        "_internal_originalTime": 1689793775000
    },
    {
        "time": 1689793777000,
        "value": 139.638,
        "_internal_originalTime": 1689793777000
    },
    {
        "time": 1689793778000,
        "value": 139.638,
        "_internal_originalTime": 1689793778000
    },
    {
        "time": 1689793780000,
        "value": 139.641,
        "_internal_originalTime": 1689793780000
    },
    {
        "time": 1689793782000,
        "value": 139.636,
        "_internal_originalTime": 1689793782000
    },
    {
        "time": 1689793784000,
        "value": 139.635,
        "_internal_originalTime": 1689793784000
    },
    {
        "time": 1689793788000,
        "value": 139.642,
        "_internal_originalTime": 1689793788000
    },
    {
        "time": 1689793789000,
        "value": 139.646,
        "_internal_originalTime": 1689793789000
    },
    {
        "time": 1689793790000,
        "value": 139.648,
        "_internal_originalTime": 1689793790000
    },
    {
        "time": 1689793800000,
        "value": 139.648,
        "_internal_originalTime": 1689793800000
    },
    {
        "time": 1689793801000,
        "value": 139.645,
        "_internal_originalTime": 1689793801000
    },
    {
        "time": 1689793805000,
        "value": 139.638,
        "_internal_originalTime": 1689793805000
    },
    {
        "time": 1689793806000,
        "value": 139.64,
        "_internal_originalTime": 1689793806000
    },
    {
        "time": 1689793808000,
        "value": 139.64,
        "_internal_originalTime": 1689793808000
    },
    {
        "time": 1689793812000,
        "value": 139.641,
        "_internal_originalTime": 1689793812000
    },
    {
        "time": 1689793813000,
        "value": 139.638,
        "_internal_originalTime": 1689793813000
    },
    {
        "time": 1689793815000,
        "value": 139.635,
        "_internal_originalTime": 1689793815000
    },
    {
        "time": 1689793817000,
        "value": 139.638,
        "_internal_originalTime": 1689793817000
    },
    {
        "time": 1689793818000,
        "value": 139.644,
        "_internal_originalTime": 1689793818000
    },
    {
        "time": 1689793819000,
        "value": 139.648,
        "_internal_originalTime": 1689793819000
    },
    {
        "time": 1689793821000,
        "value": 139.648,
        "_internal_originalTime": 1689793821000
    },
    {
        "time": 1689793830000,
        "value": 139.647,
        "_internal_originalTime": 1689793830000
    },
    {
        "time": 1689793831000,
        "value": 139.649,
        "_internal_originalTime": 1689793831000
    },
    {
        "time": 1689793832000,
        "value": 139.648,
        "_internal_originalTime": 1689793832000
    },
    {
        "time": 1689793835000,
        "value": 139.648,
        "_internal_originalTime": 1689793835000
    },
    {
        "time": 1689793837000,
        "value": 139.654,
        "_internal_originalTime": 1689793837000
    },
    {
        "time": 1689793838000,
        "value": 139.653,
        "_internal_originalTime": 1689793838000
    },
    {
        "time": 1689793840000,
        "value": 139.653,
        "_internal_originalTime": 1689793840000
    },
    {
        "time": 1689793842000,
        "value": 139.651,
        "_internal_originalTime": 1689793842000
    },
    {
        "time": 1689793843000,
        "value": 139.649,
        "_internal_originalTime": 1689793843000
    },
    {
        "time": 1689793845000,
        "value": 139.651,
        "_internal_originalTime": 1689793845000
    },
    {
        "time": 1689793846000,
        "value": 139.649,
        "_internal_originalTime": 1689793846000
    },
    {
        "time": 1689793848000,
        "value": 139.651,
        "_internal_originalTime": 1689793848000
    },
    {
        "time": 1689793852000,
        "value": 139.654,
        "_internal_originalTime": 1689793852000
    },
    {
        "time": 1689793853000,
        "value": 139.653,
        "_internal_originalTime": 1689793853000
    },
    {
        "time": 1689793860000,
        "value": 139.654,
        "_internal_originalTime": 1689793860000
    },
    {
        "time": 1689793861000,
        "value": 139.654,
        "_internal_originalTime": 1689793861000
    },
    {
        "time": 1689793862000,
        "value": 139.655,
        "_internal_originalTime": 1689793862000
    },
    {
        "time": 1689793864000,
        "value": 139.647,
        "_internal_originalTime": 1689793864000
    },
    {
        "time": 1689793865000,
        "value": 139.649,
        "_internal_originalTime": 1689793865000
    },
    {
        "time": 1689793867000,
        "value": 139.647,
        "_internal_originalTime": 1689793867000
    },
    {
        "time": 1689793868000,
        "value": 139.651,
        "_internal_originalTime": 1689793868000
    },
    {
        "time": 1689793870000,
        "value": 139.648,
        "_internal_originalTime": 1689793870000
    },
    {
        "time": 1689793873000,
        "value": 139.648,
        "_internal_originalTime": 1689793873000
    },
    {
        "time": 1689793874000,
        "value": 139.648,
        "_internal_originalTime": 1689793874000
    },
    {
        "time": 1689793875000,
        "value": 139.649,
        "_internal_originalTime": 1689793875000
    },
    {
        "time": 1689793876000,
        "value": 139.65,
        "_internal_originalTime": 1689793876000
    },
    {
        "time": 1689793877000,
        "value": 139.647,
        "_internal_originalTime": 1689793877000
    },
    {
        "time": 1689793879000,
        "value": 139.647,
        "_internal_originalTime": 1689793879000
    },
    {
        "time": 1689793880000,
        "value": 139.651,
        "_internal_originalTime": 1689793880000
    },
    {
        "time": 1689793881000,
        "value": 139.65,
        "_internal_originalTime": 1689793881000
    },
    {
        "time": 1689793882000,
        "value": 139.651,
        "_internal_originalTime": 1689793882000
    },
    {
        "time": 1689793888000,
        "value": 139.649,
        "_internal_originalTime": 1689793888000
    },
    {
        "time": 1689793890000,
        "value": 139.647,
        "_internal_originalTime": 1689793890000
    },
    {
        "time": 1689793892000,
        "value": 139.647,
        "_internal_originalTime": 1689793892000
    },
    {
        "time": 1689793893000,
        "value": 139.65,
        "_internal_originalTime": 1689793893000
    },
    {
        "time": 1689793896000,
        "value": 139.646,
        "_internal_originalTime": 1689793896000
    },
    {
        "time": 1689793899000,
        "value": 139.646,
        "_internal_originalTime": 1689793899000
    },
    {
        "time": 1689793901000,
        "value": 139.646,
        "_internal_originalTime": 1689793901000
    },
    {
        "time": 1689793902000,
        "value": 139.646,
        "_internal_originalTime": 1689793902000
    },
    {
        "time": 1689793904000,
        "value": 139.649,
        "_internal_originalTime": 1689793904000
    },
    {
        "time": 1689793908000,
        "value": 139.643,
        "_internal_originalTime": 1689793908000
    },
    {
        "time": 1689793909000,
        "value": 139.637,
        "_internal_originalTime": 1689793909000
    },
    {
        "time": 1689793911000,
        "value": 139.638,
        "_internal_originalTime": 1689793911000
    },
    {
        "time": 1689793912000,
        "value": 139.643,
        "_internal_originalTime": 1689793912000
    },
    {
        "time": 1689793913000,
        "value": 139.643,
        "_internal_originalTime": 1689793913000
    },
    {
        "time": 1689793914000,
        "value": 139.643,
        "_internal_originalTime": 1689793914000
    },
    {
        "time": 1689793915000,
        "value": 139.643,
        "_internal_originalTime": 1689793915000
    },
    {
        "time": 1689793916000,
        "value": 139.642,
        "_internal_originalTime": 1689793916000
    },
    {
        "time": 1689793919000,
        "value": 139.637,
        "_internal_originalTime": 1689793919000
    },
    {
        "time": 1689793920000,
        "value": 139.636,
        "_internal_originalTime": 1689793920000
    },
    {
        "time": 1689793923000,
        "value": 139.648,
        "_internal_originalTime": 1689793923000
    },
    {
        "time": 1689793924000,
        "value": 139.648,
        "_internal_originalTime": 1689793924000
    },
    {
        "time": 1689793926000,
        "value": 139.645,
        "_internal_originalTime": 1689793926000
    },
    {
        "time": 1689793927000,
        "value": 139.645,
        "_internal_originalTime": 1689793927000
    },
    {
        "time": 1689793928000,
        "value": 139.646,
        "_internal_originalTime": 1689793928000
    },
    {
        "time": 1689793929000,
        "value": 139.646,
        "_internal_originalTime": 1689793929000
    },
    {
        "time": 1689793930000,
        "value": 139.645,
        "_internal_originalTime": 1689793930000
    },
    {
        "time": 1689793931000,
        "value": 139.642,
        "_internal_originalTime": 1689793931000
    },
    {
        "time": 1689793932000,
        "value": 139.642,
        "_internal_originalTime": 1689793932000
    },
    {
        "time": 1689793934000,
        "value": 139.642,
        "_internal_originalTime": 1689793934000
    },
    {
        "time": 1689793935000,
        "value": 139.645,
        "_internal_originalTime": 1689793935000
    },
    {
        "time": 1689793936000,
        "value": 139.642,
        "_internal_originalTime": 1689793936000
    },
    {
        "time": 1689793939000,
        "value": 139.642,
        "_internal_originalTime": 1689793939000
    },
    {
        "time": 1689793942000,
        "value": 139.647,
        "_internal_originalTime": 1689793942000
    },
    {
        "time": 1689793951000,
        "value": 139.643,
        "_internal_originalTime": 1689793951000
    },
    {
        "time": 1689793952000,
        "value": 139.644,
        "_internal_originalTime": 1689793952000
    },
    {
        "time": 1689793953000,
        "value": 139.646,
        "_internal_originalTime": 1689793953000
    },
    {
        "time": 1689793954000,
        "value": 139.642,
        "_internal_originalTime": 1689793954000
    },
    {
        "time": 1689793956000,
        "value": 139.642,
        "_internal_originalTime": 1689793956000
    },
    {
        "time": 1689793958000,
        "value": 139.645,
        "_internal_originalTime": 1689793958000
    },
    {
        "time": 1689793961000,
        "value": 139.646,
        "_internal_originalTime": 1689793961000
    },
    {
        "time": 1689793964000,
        "value": 139.646,
        "_internal_originalTime": 1689793964000
    },
    {
        "time": 1689793965000,
        "value": 139.645,
        "_internal_originalTime": 1689793965000
    },
    {
        "time": 1689793966000,
        "value": 139.651,
        "_internal_originalTime": 1689793966000
    },
    {
        "time": 1689793967000,
        "value": 139.647,
        "_internal_originalTime": 1689793967000
    },
    {
        "time": 1689793969000,
        "value": 139.647,
        "_internal_originalTime": 1689793969000
    },
    {
        "time": 1689793970000,
        "value": 139.653,
        "_internal_originalTime": 1689793970000
    },
    {
        "time": 1689793972000,
        "value": 139.648,
        "_internal_originalTime": 1689793972000
    },
    {
        "time": 1689793973000,
        "value": 139.647,
        "_internal_originalTime": 1689793973000
    },
    {
        "time": 1689793974000,
        "value": 139.647,
        "_internal_originalTime": 1689793974000
    },
    {
        "time": 1689793976000,
        "value": 139.647,
        "_internal_originalTime": 1689793976000
    },
    {
        "time": 1689793979000,
        "value": 139.651,
        "_internal_originalTime": 1689793979000
    },
    {
        "time": 1689793980000,
        "value": 139.649,
        "_internal_originalTime": 1689793980000
    },
    {
        "time": 1689793982000,
        "value": 139.65,
        "_internal_originalTime": 1689793982000
    },
    {
        "time": 1689793988000,
        "value": 139.647,
        "_internal_originalTime": 1689793988000
    },
    {
        "time": 1689793989000,
        "value": 139.646,
        "_internal_originalTime": 1689793989000
    },
    {
        "time": 1689793991000,
        "value": 139.653,
        "_internal_originalTime": 1689793991000
    },
    {
        "time": 1689793992000,
        "value": 139.65,
        "_internal_originalTime": 1689793992000
    },
    {
        "time": 1689793999000,
        "value": 139.648,
        "_internal_originalTime": 1689793999000
    },
    {
        "time": 1689794000000,
        "value": 139.657,
        "_internal_originalTime": 1689794000000
    },
    {
        "time": 1689794002000,
        "value": 139.655,
        "_internal_originalTime": 1689794002000
    },
    {
        "time": 1689794004000,
        "value": 139.649,
        "_internal_originalTime": 1689794004000
    },
    {
        "time": 1689794007000,
        "value": 139.65,
        "_internal_originalTime": 1689794007000
    },
    {
        "time": 1689794009000,
        "value": 139.653,
        "_internal_originalTime": 1689794009000
    },
    {
        "time": 1689794013000,
        "value": 139.65,
        "_internal_originalTime": 1689794013000
    },
    {
        "time": 1689794014000,
        "value": 139.65,
        "_internal_originalTime": 1689794014000
    },
    {
        "time": 1689794016000,
        "value": 139.65,
        "_internal_originalTime": 1689794016000
    },
    {
        "time": 1689794018000,
        "value": 139.652,
        "_internal_originalTime": 1689794018000
    },
    {
        "time": 1689794019000,
        "value": 139.65,
        "_internal_originalTime": 1689794019000
    },
    {
        "time": 1689794024000,
        "value": 139.653,
        "_internal_originalTime": 1689794024000
    },
    {
        "time": 1689794029000,
        "value": 139.652,
        "_internal_originalTime": 1689794029000
    },
    {
        "time": 1689794033000,
        "value": 139.656,
        "_internal_originalTime": 1689794033000
    },
    {
        "time": 1689794035000,
        "value": 139.652,
        "_internal_originalTime": 1689794035000
    },
    {
        "time": 1689794039000,
        "value": 139.653,
        "_internal_originalTime": 1689794039000
    },
    {
        "time": 1689794040000,
        "value": 139.653,
        "_internal_originalTime": 1689794040000
    },
    {
        "time": 1689794042000,
        "value": 139.652,
        "_internal_originalTime": 1689794042000
    },
    {
        "time": 1689794043000,
        "value": 139.652,
        "_internal_originalTime": 1689794043000
    },
    {
        "time": 1689794044000,
        "value": 139.653,
        "_internal_originalTime": 1689794044000
    },
    {
        "time": 1689794046000,
        "value": 139.647,
        "_internal_originalTime": 1689794046000
    },
    {
        "time": 1689794048000,
        "value": 139.647,
        "_internal_originalTime": 1689794048000
    },
    {
        "time": 1689794049000,
        "value": 139.648,
        "_internal_originalTime": 1689794049000
    },
    {
        "time": 1689794051000,
        "value": 139.65,
        "_internal_originalTime": 1689794051000
    },
    {
        "time": 1689794053000,
        "value": 139.656,
        "_internal_originalTime": 1689794053000
    },
    {
        "time": 1689794054000,
        "value": 139.658,
        "_internal_originalTime": 1689794054000
    },
    {
        "time": 1689794055000,
        "value": 139.657,
        "_internal_originalTime": 1689794055000
    },
    {
        "time": 1689794057000,
        "value": 139.657,
        "_internal_originalTime": 1689794057000
    },
    {
        "time": 1689794059000,
        "value": 139.655,
        "_internal_originalTime": 1689794059000
    },
    {
        "time": 1689794060000,
        "value": 139.654,
        "_internal_originalTime": 1689794060000
    },
    {
        "time": 1689794062000,
        "value": 139.658,
        "_internal_originalTime": 1689794062000
    },
    {
        "time": 1689794063000,
        "value": 139.657,
        "_internal_originalTime": 1689794063000
    },
    {
        "time": 1689794066000,
        "value": 139.652,
        "_internal_originalTime": 1689794066000
    },
    {
        "time": 1689794067000,
        "value": 139.653,
        "_internal_originalTime": 1689794067000
    },
    {
        "time": 1689794072000,
        "value": 139.657,
        "_internal_originalTime": 1689794072000
    },
    {
        "time": 1689794074000,
        "value": 139.66,
        "_internal_originalTime": 1689794074000
    },
    {
        "time": 1689794078000,
        "value": 139.654,
        "_internal_originalTime": 1689794078000
    },
    {
        "time": 1689794089000,
        "value": 139.651,
        "_internal_originalTime": 1689794089000
    },
    {
        "time": 1689794098000,
        "value": 139.655,
        "_internal_originalTime": 1689794098000
    },
    {
        "time": 1689794099000,
        "value": 139.654,
        "_internal_originalTime": 1689794099000
    },
    {
        "time": 1689794100000,
        "value": 139.658,
        "_internal_originalTime": 1689794100000
    },
    {
        "time": 1689794101000,
        "value": 139.659,
        "_internal_originalTime": 1689794101000
    },
    {
        "time": 1689794103000,
        "value": 139.658,
        "_internal_originalTime": 1689794103000
    },
    {
        "time": 1689794105000,
        "value": 139.658,
        "_internal_originalTime": 1689794105000
    },
    {
        "time": 1689794108000,
        "value": 139.656,
        "_internal_originalTime": 1689794108000
    },
    {
        "time": 1689794111000,
        "value": 139.654,
        "_internal_originalTime": 1689794111000
    },
    {
        "time": 1689794112000,
        "value": 139.658,
        "_internal_originalTime": 1689794112000
    },
    {
        "time": 1689794114000,
        "value": 139.658,
        "_internal_originalTime": 1689794114000
    },
    {
        "time": 1689794115000,
        "value": 139.658,
        "_internal_originalTime": 1689794115000
    },
    {
        "time": 1689794117000,
        "value": 139.658,
        "_internal_originalTime": 1689794117000
    },
    {
        "time": 1689794118000,
        "value": 139.658,
        "_internal_originalTime": 1689794118000
    },
    {
        "time": 1689794119000,
        "value": 139.65,
        "_internal_originalTime": 1689794119000
    },
    {
        "time": 1689794120000,
        "value": 139.658,
        "_internal_originalTime": 1689794120000
    },
    {
        "time": 1689794122000,
        "value": 139.654,
        "_internal_originalTime": 1689794122000
    },
    {
        "time": 1689794123000,
        "value": 139.658,
        "_internal_originalTime": 1689794123000
    },
    {
        "time": 1689794124000,
        "value": 139.658,
        "_internal_originalTime": 1689794124000
    },
    {
        "time": 1689794125000,
        "value": 139.658,
        "_internal_originalTime": 1689794125000
    },
    {
        "time": 1689794128000,
        "value": 139.658,
        "_internal_originalTime": 1689794128000
    },
    {
        "time": 1689794129000,
        "value": 139.659,
        "_internal_originalTime": 1689794129000
    },
    {
        "time": 1689794131000,
        "value": 139.654,
        "_internal_originalTime": 1689794131000
    },
    {
        "time": 1689794132000,
        "value": 139.658,
        "_internal_originalTime": 1689794132000
    },
    {
        "time": 1689794134000,
        "value": 139.655,
        "_internal_originalTime": 1689794134000
    },
    {
        "time": 1689794135000,
        "value": 139.658,
        "_internal_originalTime": 1689794135000
    },
    {
        "time": 1689794136000,
        "value": 139.654,
        "_internal_originalTime": 1689794136000
    },
    {
        "time": 1689794138000,
        "value": 139.655,
        "_internal_originalTime": 1689794138000
    },
    {
        "time": 1689794140000,
        "value": 139.654,
        "_internal_originalTime": 1689794140000
    },
    {
        "time": 1689794141000,
        "value": 139.655,
        "_internal_originalTime": 1689794141000
    },
    {
        "time": 1689794142000,
        "value": 139.659,
        "_internal_originalTime": 1689794142000
    },
    {
        "time": 1689794145000,
        "value": 139.658,
        "_internal_originalTime": 1689794145000
    },
    {
        "time": 1689794149000,
        "value": 139.652,
        "_internal_originalTime": 1689794149000
    },
    {
        "time": 1689794152000,
        "value": 139.655,
        "_internal_originalTime": 1689794152000
    },
    {
        "time": 1689794154000,
        "value": 139.655,
        "_internal_originalTime": 1689794154000
    },
    {
        "time": 1689794155000,
        "value": 139.658,
        "_internal_originalTime": 1689794155000
    },
    {
        "time": 1689794157000,
        "value": 139.665,
        "_internal_originalTime": 1689794157000
    },
    {
        "time": 1689794159000,
        "value": 139.664,
        "_internal_originalTime": 1689794159000
    },
    {
        "time": 1689794160000,
        "value": 139.671,
        "_internal_originalTime": 1689794160000
    },
    {
        "time": 1689794161000,
        "value": 139.671,
        "_internal_originalTime": 1689794161000
    },
    {
        "time": 1689794162000,
        "value": 139.668,
        "_internal_originalTime": 1689794162000
    },
    {
        "time": 1689794164000,
        "value": 139.674,
        "_internal_originalTime": 1689794164000
    },
    {
        "time": 1689794165000,
        "value": 139.673,
        "_internal_originalTime": 1689794165000
    },
    {
        "time": 1689794166000,
        "value": 139.67,
        "_internal_originalTime": 1689794166000
    },
    {
        "time": 1689794168000,
        "value": 139.664,
        "_internal_originalTime": 1689794168000
    },
    {
        "time": 1689794169000,
        "value": 139.666,
        "_internal_originalTime": 1689794169000
    },
    {
        "time": 1689794170000,
        "value": 139.667,
        "_internal_originalTime": 1689794170000
    },
    {
        "time": 1689794172000,
        "value": 139.666,
        "_internal_originalTime": 1689794172000
    },
    {
        "time": 1689794173000,
        "value": 139.666,
        "_internal_originalTime": 1689794173000
    },
    {
        "time": 1689794174000,
        "value": 139.67,
        "_internal_originalTime": 1689794174000
    },
    {
        "time": 1689794177000,
        "value": 139.67,
        "_internal_originalTime": 1689794177000
    },
    {
        "time": 1689794178000,
        "value": 139.665,
        "_internal_originalTime": 1689794178000
    },
    {
        "time": 1689794179000,
        "value": 139.671,
        "_internal_originalTime": 1689794179000
    },
    {
        "time": 1689794180000,
        "value": 139.67,
        "_internal_originalTime": 1689794180000
    },
    {
        "time": 1689794181000,
        "value": 139.671,
        "_internal_originalTime": 1689794181000
    },
    {
        "time": 1689794182000,
        "value": 139.667,
        "_internal_originalTime": 1689794182000
    },
    {
        "time": 1689794183000,
        "value": 139.671,
        "_internal_originalTime": 1689794183000
    },
    {
        "time": 1689794184000,
        "value": 139.667,
        "_internal_originalTime": 1689794184000
    },
    {
        "time": 1689794187000,
        "value": 139.667,
        "_internal_originalTime": 1689794187000
    },
    {
        "time": 1689794188000,
        "value": 139.67,
        "_internal_originalTime": 1689794188000
    },
    {
        "time": 1689794189000,
        "value": 139.667,
        "_internal_originalTime": 1689794189000
    },
    {
        "time": 1689794190000,
        "value": 139.666,
        "_internal_originalTime": 1689794190000
    },
    {
        "time": 1689794191000,
        "value": 139.665,
        "_internal_originalTime": 1689794191000
    },
    {
        "time": 1689794192000,
        "value": 139.668,
        "_internal_originalTime": 1689794192000
    },
    {
        "time": 1689794193000,
        "value": 139.665,
        "_internal_originalTime": 1689794193000
    },
    {
        "time": 1689794194000,
        "value": 139.664,
        "_internal_originalTime": 1689794194000
    },
    {
        "time": 1689794197000,
        "value": 139.67,
        "_internal_originalTime": 1689794197000
    },
    {
        "time": 1689794198000,
        "value": 139.668,
        "_internal_originalTime": 1689794198000
    },
    {
        "time": 1689794199000,
        "value": 139.671,
        "_internal_originalTime": 1689794199000
    },
    {
        "time": 1689794200000,
        "value": 139.667,
        "_internal_originalTime": 1689794200000
    },
    {
        "time": 1689794201000,
        "value": 139.666,
        "_internal_originalTime": 1689794201000
    },
    {
        "time": 1689794202000,
        "value": 139.667,
        "_internal_originalTime": 1689794202000
    },
    {
        "time": 1689794204000,
        "value": 139.666,
        "_internal_originalTime": 1689794204000
    },
    {
        "time": 1689794206000,
        "value": 139.665,
        "_internal_originalTime": 1689794206000
    },
    {
        "time": 1689794209000,
        "value": 139.661,
        "_internal_originalTime": 1689794209000
    },
    {
        "time": 1689794213000,
        "value": 139.664,
        "_internal_originalTime": 1689794213000
    },
    {
        "time": 1689794215000,
        "value": 139.665,
        "_internal_originalTime": 1689794215000
    },
    {
        "time": 1689794216000,
        "value": 139.664,
        "_internal_originalTime": 1689794216000
    },
    {
        "time": 1689794219000,
        "value": 139.666,
        "_internal_originalTime": 1689794219000
    },
    {
        "time": 1689794220000,
        "value": 139.673,
        "_internal_originalTime": 1689794220000
    },
    {
        "time": 1689794221000,
        "value": 139.668,
        "_internal_originalTime": 1689794221000
    },
    {
        "time": 1689794222000,
        "value": 139.667,
        "_internal_originalTime": 1689794222000
    },
    {
        "time": 1689794223000,
        "value": 139.668,
        "_internal_originalTime": 1689794223000
    },
    {
        "time": 1689794224000,
        "value": 139.668,
        "_internal_originalTime": 1689794224000
    },
    {
        "time": 1689794225000,
        "value": 139.668,
        "_internal_originalTime": 1689794225000
    },
    {
        "time": 1689794226000,
        "value": 139.668,
        "_internal_originalTime": 1689794226000
    },
    {
        "time": 1689794228000,
        "value": 139.667,
        "_internal_originalTime": 1689794228000
    },
    {
        "time": 1689794229000,
        "value": 139.667,
        "_internal_originalTime": 1689794229000
    },
    {
        "time": 1689794230000,
        "value": 139.668,
        "_internal_originalTime": 1689794230000
    },
    {
        "time": 1689794235000,
        "value": 139.671,
        "_internal_originalTime": 1689794235000
    },
    {
        "time": 1689794236000,
        "value": 139.668,
        "_internal_originalTime": 1689794236000
    },
    {
        "time": 1689794238000,
        "value": 139.668,
        "_internal_originalTime": 1689794238000
    },
    {
        "time": 1689794239000,
        "value": 139.666,
        "_internal_originalTime": 1689794239000
    },
    {
        "time": 1689794240000,
        "value": 139.671,
        "_internal_originalTime": 1689794240000
    },
    {
        "time": 1689794242000,
        "value": 139.671,
        "_internal_originalTime": 1689794242000
    },
    {
        "time": 1689794243000,
        "value": 139.671,
        "_internal_originalTime": 1689794243000
    },
    {
        "time": 1689794245000,
        "value": 139.671,
        "_internal_originalTime": 1689794245000
    },
    {
        "time": 1689794246000,
        "value": 139.669,
        "_internal_originalTime": 1689794246000
    },
    {
        "time": 1689794247000,
        "value": 139.667,
        "_internal_originalTime": 1689794247000
    },
    {
        "time": 1689794248000,
        "value": 139.67,
        "_internal_originalTime": 1689794248000
    },
    {
        "time": 1689794249000,
        "value": 139.666,
        "_internal_originalTime": 1689794249000
    },
    {
        "time": 1689794250000,
        "value": 139.667,
        "_internal_originalTime": 1689794250000
    },
    {
        "time": 1689794251000,
        "value": 139.67,
        "_internal_originalTime": 1689794251000
    },
    {
        "time": 1689794254000,
        "value": 139.666,
        "_internal_originalTime": 1689794254000
    },
    {
        "time": 1689794255000,
        "value": 139.671,
        "_internal_originalTime": 1689794255000
    },
    {
        "time": 1689794256000,
        "value": 139.668,
        "_internal_originalTime": 1689794256000
    },
    {
        "time": 1689794258000,
        "value": 139.668,
        "_internal_originalTime": 1689794258000
    },
    {
        "time": 1689794259000,
        "value": 139.662,
        "_internal_originalTime": 1689794259000
    },
    {
        "time": 1689794260000,
        "value": 139.661,
        "_internal_originalTime": 1689794260000
    },
    {
        "time": 1689794262000,
        "value": 139.659,
        "_internal_originalTime": 1689794262000
    },
    {
        "time": 1689794264000,
        "value": 139.662,
        "_internal_originalTime": 1689794264000
    },
    {
        "time": 1689794265000,
        "value": 139.657,
        "_internal_originalTime": 1689794265000
    },
    {
        "time": 1689794266000,
        "value": 139.659,
        "_internal_originalTime": 1689794266000
    },
    {
        "time": 1689794267000,
        "value": 139.66,
        "_internal_originalTime": 1689794267000
    },
    {
        "time": 1689794269000,
        "value": 139.663,
        "_internal_originalTime": 1689794269000
    },
    {
        "time": 1689794271000,
        "value": 139.66,
        "_internal_originalTime": 1689794271000
    },
    {
        "time": 1689794272000,
        "value": 139.666,
        "_internal_originalTime": 1689794272000
    },
    {
        "time": 1689794273000,
        "value": 139.665,
        "_internal_originalTime": 1689794273000
    },
    {
        "time": 1689794274000,
        "value": 139.668,
        "_internal_originalTime": 1689794274000
    },
    {
        "time": 1689794275000,
        "value": 139.664,
        "_internal_originalTime": 1689794275000
    },
    {
        "time": 1689794276000,
        "value": 139.665,
        "_internal_originalTime": 1689794276000
    },
    {
        "time": 1689794277000,
        "value": 139.664,
        "_internal_originalTime": 1689794277000
    },
    {
        "time": 1689794281000,
        "value": 139.668,
        "_internal_originalTime": 1689794281000
    },
    {
        "time": 1689794282000,
        "value": 139.664,
        "_internal_originalTime": 1689794282000
    },
    {
        "time": 1689794283000,
        "value": 139.665,
        "_internal_originalTime": 1689794283000
    },
    {
        "time": 1689794285000,
        "value": 139.665,
        "_internal_originalTime": 1689794285000
    },
    {
        "time": 1689794288000,
        "value": 139.667,
        "_internal_originalTime": 1689794288000
    },
    {
        "time": 1689794290000,
        "value": 139.664,
        "_internal_originalTime": 1689794290000
    },
    {
        "time": 1689794291000,
        "value": 139.665,
        "_internal_originalTime": 1689794291000
    },
    {
        "time": 1689794292000,
        "value": 139.668,
        "_internal_originalTime": 1689794292000
    },
    {
        "time": 1689794293000,
        "value": 139.668,
        "_internal_originalTime": 1689794293000
    },
    {
        "time": 1689794295000,
        "value": 139.668,
        "_internal_originalTime": 1689794295000
    },
    {
        "time": 1689794296000,
        "value": 139.672,
        "_internal_originalTime": 1689794296000
    },
    {
        "time": 1689794299000,
        "value": 139.666,
        "_internal_originalTime": 1689794299000
    },
    {
        "time": 1689794303000,
        "value": 139.667,
        "_internal_originalTime": 1689794303000
    },
    {
        "time": 1689794304000,
        "value": 139.664,
        "_internal_originalTime": 1689794304000
    },
    {
        "time": 1689794305000,
        "value": 139.664,
        "_internal_originalTime": 1689794305000
    },
    {
        "time": 1689794308000,
        "value": 139.665,
        "_internal_originalTime": 1689794308000
    },
    {
        "time": 1689794309000,
        "value": 139.669,
        "_internal_originalTime": 1689794309000
    },
    {
        "time": 1689794310000,
        "value": 139.666,
        "_internal_originalTime": 1689794310000
    },
    {
        "time": 1689794312000,
        "value": 139.666,
        "_internal_originalTime": 1689794312000
    },
    {
        "time": 1689794313000,
        "value": 139.668,
        "_internal_originalTime": 1689794313000
    },
    {
        "time": 1689794315000,
        "value": 139.671,
        "_internal_originalTime": 1689794315000
    },
    {
        "time": 1689794317000,
        "value": 139.677,
        "_internal_originalTime": 1689794317000
    },
    {
        "time": 1689794318000,
        "value": 139.676,
        "_internal_originalTime": 1689794318000
    },
    {
        "time": 1689794319000,
        "value": 139.672,
        "_internal_originalTime": 1689794319000
    },
    {
        "time": 1689794320000,
        "value": 139.676,
        "_internal_originalTime": 1689794320000
    },
    {
        "time": 1689794322000,
        "value": 139.678,
        "_internal_originalTime": 1689794322000
    },
    {
        "time": 1689794324000,
        "value": 139.684,
        "_internal_originalTime": 1689794324000
    },
    {
        "time": 1689794326000,
        "value": 139.676,
        "_internal_originalTime": 1689794326000
    },
    {
        "time": 1689794327000,
        "value": 139.676,
        "_internal_originalTime": 1689794327000
    },
    {
        "time": 1689794328000,
        "value": 139.679,
        "_internal_originalTime": 1689794328000
    },
    {
        "time": 1689794329000,
        "value": 139.676,
        "_internal_originalTime": 1689794329000
    },
    {
        "time": 1689794334000,
        "value": 139.677,
        "_internal_originalTime": 1689794334000
    },
    {
        "time": 1689794337000,
        "value": 139.677,
        "_internal_originalTime": 1689794337000
    },
    {
        "time": 1689794339000,
        "value": 139.679,
        "_internal_originalTime": 1689794339000
    },
    {
        "time": 1689794340000,
        "value": 139.683,
        "_internal_originalTime": 1689794340000
    },
    {
        "time": 1689794341000,
        "value": 139.681,
        "_internal_originalTime": 1689794341000
    },
    {
        "time": 1689794342000,
        "value": 139.683,
        "_internal_originalTime": 1689794342000
    },
    {
        "time": 1689794343000,
        "value": 139.681,
        "_internal_originalTime": 1689794343000
    },
    {
        "time": 1689794344000,
        "value": 139.676,
        "_internal_originalTime": 1689794344000
    },
    {
        "time": 1689794346000,
        "value": 139.681,
        "_internal_originalTime": 1689794346000
    },
    {
        "time": 1689794347000,
        "value": 139.681,
        "_internal_originalTime": 1689794347000
    },
    {
        "time": 1689794348000,
        "value": 139.68,
        "_internal_originalTime": 1689794348000
    },
    {
        "time": 1689794350000,
        "value": 139.677,
        "_internal_originalTime": 1689794350000
    },
    {
        "time": 1689794352000,
        "value": 139.679,
        "_internal_originalTime": 1689794352000
    },
    {
        "time": 1689794353000,
        "value": 139.674,
        "_internal_originalTime": 1689794353000
    },
    {
        "time": 1689794354000,
        "value": 139.675,
        "_internal_originalTime": 1689794354000
    },
    {
        "time": 1689794355000,
        "value": 139.674,
        "_internal_originalTime": 1689794355000
    },
    {
        "time": 1689794356000,
        "value": 139.675,
        "_internal_originalTime": 1689794356000
    },
    {
        "time": 1689794357000,
        "value": 139.678,
        "_internal_originalTime": 1689794357000
    },
    {
        "time": 1689794359000,
        "value": 139.671,
        "_internal_originalTime": 1689794359000
    },
    {
        "time": 1689794363000,
        "value": 139.67,
        "_internal_originalTime": 1689794363000
    },
    {
        "time": 1689794374000,
        "value": 139.67,
        "_internal_originalTime": 1689794374000
    },
    {
        "time": 1689794378000,
        "value": 139.668,
        "_internal_originalTime": 1689794378000
    },
    {
        "time": 1689794380000,
        "value": 139.67,
        "_internal_originalTime": 1689794380000
    },
    {
        "time": 1689794383000,
        "value": 139.666,
        "_internal_originalTime": 1689794383000
    },
    {
        "time": 1689794385000,
        "value": 139.666,
        "_internal_originalTime": 1689794385000
    },
    {
        "time": 1689794387000,
        "value": 139.666,
        "_internal_originalTime": 1689794387000
    },
    {
        "time": 1689794389000,
        "value": 139.67,
        "_internal_originalTime": 1689794389000
    },
    {
        "time": 1689794390000,
        "value": 139.671,
        "_internal_originalTime": 1689794390000
    },
    {
        "time": 1689794391000,
        "value": 139.671,
        "_internal_originalTime": 1689794391000
    },
    {
        "time": 1689794408000,
        "value": 139.668,
        "_internal_originalTime": 1689794408000
    },
    {
        "time": 1689794410000,
        "value": 139.665,
        "_internal_originalTime": 1689794410000
    },
    {
        "time": 1689794415000,
        "value": 139.67,
        "_internal_originalTime": 1689794415000
    },
    {
        "time": 1689794416000,
        "value": 139.67,
        "_internal_originalTime": 1689794416000
    },
    {
        "time": 1689794418000,
        "value": 139.667,
        "_internal_originalTime": 1689794418000
    },
    {
        "time": 1689794421000,
        "value": 139.66,
        "_internal_originalTime": 1689794421000
    },
    {
        "time": 1689794423000,
        "value": 139.663,
        "_internal_originalTime": 1689794423000
    },
    {
        "time": 1689794424000,
        "value": 139.657,
        "_internal_originalTime": 1689794424000
    },
    {
        "time": 1689794425000,
        "value": 139.661,
        "_internal_originalTime": 1689794425000
    },
    {
        "time": 1689794427000,
        "value": 139.662,
        "_internal_originalTime": 1689794427000
    },
    {
        "time": 1689794429000,
        "value": 139.659,
        "_internal_originalTime": 1689794429000
    },
    {
        "time": 1689794430000,
        "value": 139.661,
        "_internal_originalTime": 1689794430000
    },
    {
        "time": 1689794431000,
        "value": 139.659,
        "_internal_originalTime": 1689794431000
    },
    {
        "time": 1689794432000,
        "value": 139.661,
        "_internal_originalTime": 1689794432000
    },
    {
        "time": 1689794435000,
        "value": 139.662,
        "_internal_originalTime": 1689794435000
    },
    {
        "time": 1689794436000,
        "value": 139.661,
        "_internal_originalTime": 1689794436000
    },
    {
        "time": 1689794437000,
        "value": 139.661,
        "_internal_originalTime": 1689794437000
    },
    {
        "time": 1689794439000,
        "value": 139.661,
        "_internal_originalTime": 1689794439000
    },
    {
        "time": 1689794440000,
        "value": 139.657,
        "_internal_originalTime": 1689794440000
    },
    {
        "time": 1689794441000,
        "value": 139.656,
        "_internal_originalTime": 1689794441000
    },
    {
        "time": 1689794442000,
        "value": 139.662,
        "_internal_originalTime": 1689794442000
    },
    {
        "time": 1689794445000,
        "value": 139.661,
        "_internal_originalTime": 1689794445000
    },
    {
        "time": 1689794446000,
        "value": 139.661,
        "_internal_originalTime": 1689794446000
    },
    {
        "time": 1689794447000,
        "value": 139.66,
        "_internal_originalTime": 1689794447000
    },
    {
        "time": 1689794449000,
        "value": 139.654,
        "_internal_originalTime": 1689794449000
    },
    {
        "time": 1689794457000,
        "value": 139.661,
        "_internal_originalTime": 1689794457000
    },
    {
        "time": 1689794458000,
        "value": 139.661,
        "_internal_originalTime": 1689794458000
    },
    {
        "time": 1689794461000,
        "value": 139.659,
        "_internal_originalTime": 1689794461000
    },
    {
        "time": 1689794462000,
        "value": 139.66,
        "_internal_originalTime": 1689794462000
    },
    {
        "time": 1689794463000,
        "value": 139.659,
        "_internal_originalTime": 1689794463000
    },
    {
        "time": 1689794464000,
        "value": 139.673,
        "_internal_originalTime": 1689794464000
    },
    {
        "time": 1689794465000,
        "value": 139.673,
        "_internal_originalTime": 1689794465000
    },
    {
        "time": 1689794466000,
        "value": 139.674,
        "_internal_originalTime": 1689794466000
    },
    {
        "time": 1689794467000,
        "value": 139.675,
        "_internal_originalTime": 1689794467000
    },
    {
        "time": 1689794469000,
        "value": 139.67,
        "_internal_originalTime": 1689794469000
    },
    {
        "time": 1689794471000,
        "value": 139.673,
        "_internal_originalTime": 1689794471000
    },
    {
        "time": 1689794472000,
        "value": 139.679,
        "_internal_originalTime": 1689794472000
    },
    {
        "time": 1689794474000,
        "value": 139.676,
        "_internal_originalTime": 1689794474000
    },
    {
        "time": 1689794475000,
        "value": 139.679,
        "_internal_originalTime": 1689794475000
    },
    {
        "time": 1689794477000,
        "value": 139.676,
        "_internal_originalTime": 1689794477000
    },
    {
        "time": 1689794478000,
        "value": 139.68,
        "_internal_originalTime": 1689794478000
    },
    {
        "time": 1689794479000,
        "value": 139.676,
        "_internal_originalTime": 1689794479000
    },
    {
        "time": 1689794480000,
        "value": 139.68,
        "_internal_originalTime": 1689794480000
    },
    {
        "time": 1689794482000,
        "value": 139.676,
        "_internal_originalTime": 1689794482000
    },
    {
        "time": 1689794483000,
        "value": 139.677,
        "_internal_originalTime": 1689794483000
    },
    {
        "time": 1689794485000,
        "value": 139.679,
        "_internal_originalTime": 1689794485000
    },
    {
        "time": 1689794487000,
        "value": 139.676,
        "_internal_originalTime": 1689794487000
    },
    {
        "time": 1689794488000,
        "value": 139.674,
        "_internal_originalTime": 1689794488000
    },
    {
        "time": 1689794489000,
        "value": 139.675,
        "_internal_originalTime": 1689794489000
    },
    {
        "time": 1689794490000,
        "value": 139.676,
        "_internal_originalTime": 1689794490000
    },
    {
        "time": 1689794492000,
        "value": 139.674,
        "_internal_originalTime": 1689794492000
    },
    {
        "time": 1689794493000,
        "value": 139.683,
        "_internal_originalTime": 1689794493000
    },
    {
        "time": 1689794494000,
        "value": 139.683,
        "_internal_originalTime": 1689794494000
    },
    {
        "time": 1689794495000,
        "value": 139.683,
        "_internal_originalTime": 1689794495000
    },
    {
        "time": 1689794496000,
        "value": 139.684,
        "_internal_originalTime": 1689794496000
    },
    {
        "time": 1689794498000,
        "value": 139.682,
        "_internal_originalTime": 1689794498000
    },
    {
        "time": 1689794501000,
        "value": 139.683,
        "_internal_originalTime": 1689794501000
    },
    {
        "time": 1689794502000,
        "value": 139.682,
        "_internal_originalTime": 1689794502000
    },
    {
        "time": 1689794503000,
        "value": 139.679,
        "_internal_originalTime": 1689794503000
    },
    {
        "time": 1689794505000,
        "value": 139.681,
        "_internal_originalTime": 1689794505000
    },
    {
        "time": 1689794508000,
        "value": 139.681,
        "_internal_originalTime": 1689794508000
    },
    {
        "time": 1689794509000,
        "value": 139.681,
        "_internal_originalTime": 1689794509000
    },
    {
        "time": 1689794510000,
        "value": 139.683,
        "_internal_originalTime": 1689794510000
    },
    {
        "time": 1689794512000,
        "value": 139.683,
        "_internal_originalTime": 1689794512000
    },
    {
        "time": 1689794514000,
        "value": 139.68,
        "_internal_originalTime": 1689794514000
    },
    {
        "time": 1689794515000,
        "value": 139.68,
        "_internal_originalTime": 1689794515000
    },
    {
        "time": 1689794516000,
        "value": 139.68,
        "_internal_originalTime": 1689794516000
    },
    {
        "time": 1689794518000,
        "value": 139.676,
        "_internal_originalTime": 1689794518000
    },
    {
        "time": 1689794519000,
        "value": 139.67,
        "_internal_originalTime": 1689794519000
    },
    {
        "time": 1689794520000,
        "value": 139.673,
        "_internal_originalTime": 1689794520000
    },
    {
        "time": 1689794521000,
        "value": 139.673,
        "_internal_originalTime": 1689794521000
    },
    {
        "time": 1689794522000,
        "value": 139.676,
        "_internal_originalTime": 1689794522000
    },
    {
        "time": 1689794524000,
        "value": 139.677,
        "_internal_originalTime": 1689794524000
    },
    {
        "time": 1689794525000,
        "value": 139.677,
        "_internal_originalTime": 1689794525000
    },
    {
        "time": 1689794526000,
        "value": 139.674,
        "_internal_originalTime": 1689794526000
    },
    {
        "time": 1689794527000,
        "value": 139.673,
        "_internal_originalTime": 1689794527000
    },
    {
        "time": 1689794528000,
        "value": 139.669,
        "_internal_originalTime": 1689794528000
    },
    {
        "time": 1689794534000,
        "value": 139.666,
        "_internal_originalTime": 1689794534000
    },
    {
        "time": 1689794535000,
        "value": 139.667,
        "_internal_originalTime": 1689794535000
    },
    {
        "time": 1689794536000,
        "value": 139.67,
        "_internal_originalTime": 1689794536000
    },
    {
        "time": 1689794537000,
        "value": 139.666,
        "_internal_originalTime": 1689794537000
    },
    {
        "time": 1689794538000,
        "value": 139.666,
        "_internal_originalTime": 1689794538000
    },
    {
        "time": 1689794539000,
        "value": 139.664,
        "_internal_originalTime": 1689794539000
    },
    {
        "time": 1689794544000,
        "value": 139.67,
        "_internal_originalTime": 1689794544000
    },
    {
        "time": 1689794546000,
        "value": 139.67,
        "_internal_originalTime": 1689794546000
    },
    {
        "time": 1689794547000,
        "value": 139.668,
        "_internal_originalTime": 1689794547000
    },
    {
        "time": 1689794548000,
        "value": 139.674,
        "_internal_originalTime": 1689794548000
    },
    {
        "time": 1689794550000,
        "value": 139.676,
        "_internal_originalTime": 1689794550000
    },
    {
        "time": 1689794551000,
        "value": 139.676,
        "_internal_originalTime": 1689794551000
    },
    {
        "time": 1689794553000,
        "value": 139.672,
        "_internal_originalTime": 1689794553000
    },
    {
        "time": 1689794554000,
        "value": 139.676,
        "_internal_originalTime": 1689794554000
    },
    {
        "time": 1689794555000,
        "value": 139.677,
        "_internal_originalTime": 1689794555000
    },
    {
        "time": 1689794556000,
        "value": 139.673,
        "_internal_originalTime": 1689794556000
    },
    {
        "time": 1689794558000,
        "value": 139.672,
        "_internal_originalTime": 1689794558000
    },
    {
        "time": 1689794559000,
        "value": 139.673,
        "_internal_originalTime": 1689794559000
    },
    {
        "time": 1689794561000,
        "value": 139.677,
        "_internal_originalTime": 1689794561000
    },
    {
        "time": 1689794562000,
        "value": 139.677,
        "_internal_originalTime": 1689794562000
    },
    {
        "time": 1689794564000,
        "value": 139.677,
        "_internal_originalTime": 1689794564000
    },
    {
        "time": 1689794565000,
        "value": 139.671,
        "_internal_originalTime": 1689794565000
    },
    {
        "time": 1689794567000,
        "value": 139.677,
        "_internal_originalTime": 1689794567000
    },
    {
        "time": 1689794569000,
        "value": 139.672,
        "_internal_originalTime": 1689794569000
    },
    {
        "time": 1689794572000,
        "value": 139.673,
        "_internal_originalTime": 1689794572000
    },
    {
        "time": 1689794574000,
        "value": 139.677,
        "_internal_originalTime": 1689794574000
    },
    {
        "time": 1689794576000,
        "value": 139.677,
        "_internal_originalTime": 1689794576000
    },
    {
        "time": 1689794577000,
        "value": 139.677,
        "_internal_originalTime": 1689794577000
    },
    {
        "time": 1689794579000,
        "value": 139.677,
        "_internal_originalTime": 1689794579000
    },
    {
        "time": 1689794580000,
        "value": 139.672,
        "_internal_originalTime": 1689794580000
    },
    {
        "time": 1689794581000,
        "value": 139.673,
        "_internal_originalTime": 1689794581000
    },
    {
        "time": 1689794583000,
        "value": 139.671,
        "_internal_originalTime": 1689794583000
    },
    {
        "time": 1689794584000,
        "value": 139.676,
        "_internal_originalTime": 1689794584000
    },
    {
        "time": 1689794586000,
        "value": 139.672,
        "_internal_originalTime": 1689794586000
    },
    {
        "time": 1689794587000,
        "value": 139.672,
        "_internal_originalTime": 1689794587000
    },
    {
        "time": 1689794588000,
        "value": 139.675,
        "_internal_originalTime": 1689794588000
    },
    {
        "time": 1689794591000,
        "value": 139.677,
        "_internal_originalTime": 1689794591000
    },
    {
        "time": 1689794592000,
        "value": 139.676,
        "_internal_originalTime": 1689794592000
    },
    {
        "time": 1689794593000,
        "value": 139.672,
        "_internal_originalTime": 1689794593000
    },
    {
        "time": 1689794594000,
        "value": 139.672,
        "_internal_originalTime": 1689794594000
    },
    {
        "time": 1689794595000,
        "value": 139.675,
        "_internal_originalTime": 1689794595000
    },
    {
        "time": 1689794597000,
        "value": 139.675,
        "_internal_originalTime": 1689794597000
    },
    {
        "time": 1689794599000,
        "value": 139.668,
        "_internal_originalTime": 1689794599000
    },
    {
        "time": 1689794600000,
        "value": 139.675,
        "_internal_originalTime": 1689794600000
    },
    {
        "time": 1689794602000,
        "value": 139.675,
        "_internal_originalTime": 1689794602000
    },
    {
        "time": 1689794604000,
        "value": 139.675,
        "_internal_originalTime": 1689794604000
    },
    {
        "time": 1689794605000,
        "value": 139.676,
        "_internal_originalTime": 1689794605000
    },
    {
        "time": 1689794606000,
        "value": 139.671,
        "_internal_originalTime": 1689794606000
    },
    {
        "time": 1689794608000,
        "value": 139.676,
        "_internal_originalTime": 1689794608000
    },
    {
        "time": 1689794610000,
        "value": 139.672,
        "_internal_originalTime": 1689794610000
    },
    {
        "time": 1689794611000,
        "value": 139.676,
        "_internal_originalTime": 1689794611000
    },
    {
        "time": 1689794615000,
        "value": 139.674,
        "_internal_originalTime": 1689794615000
    },
    {
        "time": 1689794616000,
        "value": 139.674,
        "_internal_originalTime": 1689794616000
    },
    {
        "time": 1689794617000,
        "value": 139.669,
        "_internal_originalTime": 1689794617000
    },
    {
        "time": 1689794618000,
        "value": 139.671,
        "_internal_originalTime": 1689794618000
    },
    {
        "time": 1689794619000,
        "value": 139.674,
        "_internal_originalTime": 1689794619000
    },
    {
        "time": 1689794620000,
        "value": 139.668,
        "_internal_originalTime": 1689794620000
    },
    {
        "time": 1689794621000,
        "value": 139.672,
        "_internal_originalTime": 1689794621000
    },
    {
        "time": 1689794622000,
        "value": 139.676,
        "_internal_originalTime": 1689794622000
    },
    {
        "time": 1689794623000,
        "value": 139.676,
        "_internal_originalTime": 1689794623000
    },
    {
        "time": 1689794624000,
        "value": 139.677,
        "_internal_originalTime": 1689794624000
    },
    {
        "time": 1689794625000,
        "value": 139.677,
        "_internal_originalTime": 1689794625000
    },
    {
        "time": 1689794627000,
        "value": 139.672,
        "_internal_originalTime": 1689794627000
    },
    {
        "time": 1689794629000,
        "value": 139.677,
        "_internal_originalTime": 1689794629000
    },
    {
        "time": 1689794630000,
        "value": 139.669,
        "_internal_originalTime": 1689794630000
    },
    {
        "time": 1689794631000,
        "value": 139.668,
        "_internal_originalTime": 1689794631000
    },
    {
        "time": 1689794632000,
        "value": 139.67,
        "_internal_originalTime": 1689794632000
    },
    {
        "time": 1689794633000,
        "value": 139.666,
        "_internal_originalTime": 1689794633000
    },
    {
        "time": 1689794636000,
        "value": 139.667,
        "_internal_originalTime": 1689794636000
    },
    {
        "time": 1689794639000,
        "value": 139.67,
        "_internal_originalTime": 1689794639000
    },
    {
        "time": 1689794640000,
        "value": 139.671,
        "_internal_originalTime": 1689794640000
    },
    {
        "time": 1689794642000,
        "value": 139.67,
        "_internal_originalTime": 1689794642000
    },
    {
        "time": 1689794647000,
        "value": 139.671,
        "_internal_originalTime": 1689794647000
    },
    {
        "time": 1689794648000,
        "value": 139.671,
        "_internal_originalTime": 1689794648000
    },
    {
        "time": 1689794649000,
        "value": 139.666,
        "_internal_originalTime": 1689794649000
    },
    {
        "time": 1689794651000,
        "value": 139.669,
        "_internal_originalTime": 1689794651000
    },
    {
        "time": 1689794652000,
        "value": 139.673,
        "_internal_originalTime": 1689794652000
    },
    {
        "time": 1689794653000,
        "value": 139.672,
        "_internal_originalTime": 1689794653000
    },
    {
        "time": 1689794654000,
        "value": 139.672,
        "_internal_originalTime": 1689794654000
    },
    {
        "time": 1689794656000,
        "value": 139.671,
        "_internal_originalTime": 1689794656000
    },
    {
        "time": 1689794658000,
        "value": 139.667,
        "_internal_originalTime": 1689794658000
    },
    {
        "time": 1689794659000,
        "value": 139.667,
        "_internal_originalTime": 1689794659000
    },
    {
        "time": 1689794661000,
        "value": 139.67,
        "_internal_originalTime": 1689794661000
    },
    {
        "time": 1689794662000,
        "value": 139.67,
        "_internal_originalTime": 1689794662000
    },
    {
        "time": 1689794664000,
        "value": 139.671,
        "_internal_originalTime": 1689794664000
    },
    {
        "time": 1689794665000,
        "value": 139.667,
        "_internal_originalTime": 1689794665000
    },
    {
        "time": 1689794666000,
        "value": 139.667,
        "_internal_originalTime": 1689794666000
    },
    {
        "time": 1689794667000,
        "value": 139.67,
        "_internal_originalTime": 1689794667000
    },
    {
        "time": 1689794669000,
        "value": 139.674,
        "_internal_originalTime": 1689794669000
    },
    {
        "time": 1689794670000,
        "value": 139.675,
        "_internal_originalTime": 1689794670000
    },
    {
        "time": 1689794671000,
        "value": 139.67,
        "_internal_originalTime": 1689794671000
    },
    {
        "time": 1689794672000,
        "value": 139.669,
        "_internal_originalTime": 1689794672000
    },
    {
        "time": 1689794673000,
        "value": 139.672,
        "_internal_originalTime": 1689794673000
    },
    {
        "time": 1689794674000,
        "value": 139.669,
        "_internal_originalTime": 1689794674000
    },
    {
        "time": 1689794675000,
        "value": 139.671,
        "_internal_originalTime": 1689794675000
    },
    {
        "time": 1689794676000,
        "value": 139.672,
        "_internal_originalTime": 1689794676000
    },
    {
        "time": 1689794677000,
        "value": 139.667,
        "_internal_originalTime": 1689794677000
    },
    {
        "time": 1689794678000,
        "value": 139.669,
        "_internal_originalTime": 1689794678000
    },
    {
        "time": 1689794680000,
        "value": 139.671,
        "_internal_originalTime": 1689794680000
    },
    {
        "time": 1689794686000,
        "value": 139.67,
        "_internal_originalTime": 1689794686000
    },
    {
        "time": 1689794689000,
        "value": 139.665,
        "_internal_originalTime": 1689794689000
    },
    {
        "time": 1689794691000,
        "value": 139.671,
        "_internal_originalTime": 1689794691000
    },
    {
        "time": 1689794692000,
        "value": 139.667,
        "_internal_originalTime": 1689794692000
    },
    {
        "time": 1689794697000,
        "value": 139.669,
        "_internal_originalTime": 1689794697000
    },
    {
        "time": 1689794700000,
        "value": 139.668,
        "_internal_originalTime": 1689794700000
    },
    {
        "time": 1689794701000,
        "value": 139.668,
        "_internal_originalTime": 1689794701000
    },
    {
        "time": 1689794702000,
        "value": 139.667,
        "_internal_originalTime": 1689794702000
    },
    {
        "time": 1689794703000,
        "value": 139.667,
        "_internal_originalTime": 1689794703000
    },
    {
        "time": 1689794705000,
        "value": 139.672,
        "_internal_originalTime": 1689794705000
    },
    {
        "time": 1689794706000,
        "value": 139.669,
        "_internal_originalTime": 1689794706000
    },
    {
        "time": 1689794708000,
        "value": 139.67,
        "_internal_originalTime": 1689794708000
    },
    {
        "time": 1689794709000,
        "value": 139.668,
        "_internal_originalTime": 1689794709000
    },
    {
        "time": 1689794710000,
        "value": 139.672,
        "_internal_originalTime": 1689794710000
    },
    {
        "time": 1689794711000,
        "value": 139.67,
        "_internal_originalTime": 1689794711000
    },
    {
        "time": 1689794712000,
        "value": 139.67,
        "_internal_originalTime": 1689794712000
    },
    {
        "time": 1689794717000,
        "value": 139.671,
        "_internal_originalTime": 1689794717000
    },
    {
        "time": 1689794719000,
        "value": 139.668,
        "_internal_originalTime": 1689794719000
    },
    {
        "time": 1689794720000,
        "value": 139.669,
        "_internal_originalTime": 1689794720000
    },
    {
        "time": 1689794723000,
        "value": 139.674,
        "_internal_originalTime": 1689794723000
    },
    {
        "time": 1689794724000,
        "value": 139.667,
        "_internal_originalTime": 1689794724000
    },
    {
        "time": 1689794726000,
        "value": 139.669,
        "_internal_originalTime": 1689794726000
    },
    {
        "time": 1689794727000,
        "value": 139.667,
        "_internal_originalTime": 1689794727000
    },
    {
        "time": 1689794733000,
        "value": 139.669,
        "_internal_originalTime": 1689794733000
    },
    {
        "time": 1689794734000,
        "value": 139.669,
        "_internal_originalTime": 1689794734000
    },
    {
        "time": 1689794736000,
        "value": 139.669,
        "_internal_originalTime": 1689794736000
    },
    {
        "time": 1689794738000,
        "value": 139.67,
        "_internal_originalTime": 1689794738000
    },
    {
        "time": 1689794740000,
        "value": 139.667,
        "_internal_originalTime": 1689794740000
    },
    {
        "time": 1689794742000,
        "value": 139.669,
        "_internal_originalTime": 1689794742000
    },
    {
        "time": 1689794744000,
        "value": 139.671,
        "_internal_originalTime": 1689794744000
    },
    {
        "time": 1689794746000,
        "value": 139.667,
        "_internal_originalTime": 1689794746000
    },
    {
        "time": 1689794749000,
        "value": 139.667,
        "_internal_originalTime": 1689794749000
    },
    {
        "time": 1689794751000,
        "value": 139.666,
        "_internal_originalTime": 1689794751000
    },
    {
        "time": 1689794752000,
        "value": 139.667,
        "_internal_originalTime": 1689794752000
    },
    {
        "time": 1689794753000,
        "value": 139.669,
        "_internal_originalTime": 1689794753000
    },
    {
        "time": 1689794755000,
        "value": 139.669,
        "_internal_originalTime": 1689794755000
    },
    {
        "time": 1689794758000,
        "value": 139.671,
        "_internal_originalTime": 1689794758000
    },
    {
        "time": 1689794759000,
        "value": 139.674,
        "_internal_originalTime": 1689794759000
    },
    {
        "time": 1689794760000,
        "value": 139.672,
        "_internal_originalTime": 1689794760000
    },
    {
        "time": 1689794761000,
        "value": 139.671,
        "_internal_originalTime": 1689794761000
    },
    {
        "time": 1689794762000,
        "value": 139.668,
        "_internal_originalTime": 1689794762000
    },
    {
        "time": 1689794763000,
        "value": 139.667,
        "_internal_originalTime": 1689794763000
    },
    {
        "time": 1689794764000,
        "value": 139.667,
        "_internal_originalTime": 1689794764000
    },
    {
        "time": 1689794765000,
        "value": 139.667,
        "_internal_originalTime": 1689794765000
    },
    {
        "time": 1689794766000,
        "value": 139.67,
        "_internal_originalTime": 1689794766000
    },
    {
        "time": 1689794769000,
        "value": 139.669,
        "_internal_originalTime": 1689794769000
    },
    {
        "time": 1689794770000,
        "value": 139.669,
        "_internal_originalTime": 1689794770000
    },
    {
        "time": 1689794775000,
        "value": 139.667,
        "_internal_originalTime": 1689794775000
    },
    {
        "time": 1689794776000,
        "value": 139.667,
        "_internal_originalTime": 1689794776000
    },
    {
        "time": 1689794777000,
        "value": 139.666,
        "_internal_originalTime": 1689794777000
    },
    {
        "time": 1689794778000,
        "value": 139.667,
        "_internal_originalTime": 1689794778000
    },
    {
        "time": 1689794779000,
        "value": 139.664,
        "_internal_originalTime": 1689794779000
    },
    {
        "time": 1689794781000,
        "value": 139.666,
        "_internal_originalTime": 1689794781000
    },
    {
        "time": 1689794782000,
        "value": 139.667,
        "_internal_originalTime": 1689794782000
    },
    {
        "time": 1689794784000,
        "value": 139.668,
        "_internal_originalTime": 1689794784000
    },
    {
        "time": 1689794785000,
        "value": 139.666,
        "_internal_originalTime": 1689794785000
    },
    {
        "time": 1689794787000,
        "value": 139.667,
        "_internal_originalTime": 1689794787000
    },
    {
        "time": 1689794788000,
        "value": 139.666,
        "_internal_originalTime": 1689794788000
    },
    {
        "time": 1689794790000,
        "value": 139.671,
        "_internal_originalTime": 1689794790000
    },
    {
        "time": 1689794791000,
        "value": 139.676,
        "_internal_originalTime": 1689794791000
    },
    {
        "time": 1689794792000,
        "value": 139.679,
        "_internal_originalTime": 1689794792000
    },
    {
        "time": 1689794794000,
        "value": 139.677,
        "_internal_originalTime": 1689794794000
    },
    {
        "time": 1689794795000,
        "value": 139.676,
        "_internal_originalTime": 1689794795000
    },
    {
        "time": 1689794797000,
        "value": 139.683,
        "_internal_originalTime": 1689794797000
    },
    {
        "time": 1689794798000,
        "value": 139.688,
        "_internal_originalTime": 1689794798000
    },
    {
        "time": 1689794799000,
        "value": 139.687,
        "_internal_originalTime": 1689794799000
    },
    {
        "time": 1689794800000,
        "value": 139.688,
        "_internal_originalTime": 1689794800000
    },
    {
        "time": 1689794801000,
        "value": 139.685,
        "_internal_originalTime": 1689794801000
    },
    {
        "time": 1689794803000,
        "value": 139.684,
        "_internal_originalTime": 1689794803000
    },
    {
        "time": 1689794805000,
        "value": 139.685,
        "_internal_originalTime": 1689794805000
    },
    {
        "time": 1689794809000,
        "value": 139.687,
        "_internal_originalTime": 1689794809000
    },
    {
        "time": 1689794810000,
        "value": 139.688,
        "_internal_originalTime": 1689794810000
    },
    {
        "time": 1689794811000,
        "value": 139.689,
        "_internal_originalTime": 1689794811000
    },
    {
        "time": 1689794814000,
        "value": 139.684,
        "_internal_originalTime": 1689794814000
    },
    {
        "time": 1689794815000,
        "value": 139.685,
        "_internal_originalTime": 1689794815000
    },
    {
        "time": 1689794817000,
        "value": 139.684,
        "_internal_originalTime": 1689794817000
    },
    {
        "time": 1689794819000,
        "value": 139.683,
        "_internal_originalTime": 1689794819000
    },
    {
        "time": 1689794824000,
        "value": 139.687,
        "_internal_originalTime": 1689794824000
    },
    {
        "time": 1689794826000,
        "value": 139.688,
        "_internal_originalTime": 1689794826000
    },
    {
        "time": 1689794828000,
        "value": 139.685,
        "_internal_originalTime": 1689794828000
    },
    {
        "time": 1689794833000,
        "value": 139.684,
        "_internal_originalTime": 1689794833000
    },
    {
        "time": 1689794834000,
        "value": 139.685,
        "_internal_originalTime": 1689794834000
    },
    {
        "time": 1689794836000,
        "value": 139.684,
        "_internal_originalTime": 1689794836000
    },
    {
        "time": 1689794837000,
        "value": 139.687,
        "_internal_originalTime": 1689794837000
    },
    {
        "time": 1689794840000,
        "value": 139.685,
        "_internal_originalTime": 1689794840000
    },
    {
        "time": 1689794845000,
        "value": 139.683,
        "_internal_originalTime": 1689794845000
    },
    {
        "time": 1689794852000,
        "value": 139.684,
        "_internal_originalTime": 1689794852000
    },
    {
        "time": 1689794853000,
        "value": 139.685,
        "_internal_originalTime": 1689794853000
    },
    {
        "time": 1689794854000,
        "value": 139.688,
        "_internal_originalTime": 1689794854000
    },
    {
        "time": 1689794855000,
        "value": 139.685,
        "_internal_originalTime": 1689794855000
    },
    {
        "time": 1689794856000,
        "value": 139.687,
        "_internal_originalTime": 1689794856000
    },
    {
        "time": 1689794858000,
        "value": 139.686,
        "_internal_originalTime": 1689794858000
    },
    {
        "time": 1689794861000,
        "value": 139.685,
        "_internal_originalTime": 1689794861000
    },
    {
        "time": 1689794862000,
        "value": 139.684,
        "_internal_originalTime": 1689794862000
    },
    {
        "time": 1689794863000,
        "value": 139.685,
        "_internal_originalTime": 1689794863000
    },
    {
        "time": 1689794865000,
        "value": 139.69,
        "_internal_originalTime": 1689794865000
    },
    {
        "time": 1689794867000,
        "value": 139.688,
        "_internal_originalTime": 1689794867000
    },
    {
        "time": 1689794868000,
        "value": 139.687,
        "_internal_originalTime": 1689794868000
    },
    {
        "time": 1689794869000,
        "value": 139.682,
        "_internal_originalTime": 1689794869000
    },
    {
        "time": 1689794871000,
        "value": 139.685,
        "_internal_originalTime": 1689794871000
    },
    {
        "time": 1689794875000,
        "value": 139.685,
        "_internal_originalTime": 1689794875000
    },
    {
        "time": 1689794877000,
        "value": 139.684,
        "_internal_originalTime": 1689794877000
    },
    {
        "time": 1689794879000,
        "value": 139.687,
        "_internal_originalTime": 1689794879000
    },
    {
        "time": 1689794880000,
        "value": 139.685,
        "_internal_originalTime": 1689794880000
    },
    {
        "time": 1689794881000,
        "value": 139.685,
        "_internal_originalTime": 1689794881000
    },
    {
        "time": 1689794882000,
        "value": 139.688,
        "_internal_originalTime": 1689794882000
    },
    {
        "time": 1689794888000,
        "value": 139.687,
        "_internal_originalTime": 1689794888000
    },
    {
        "time": 1689794891000,
        "value": 139.685,
        "_internal_originalTime": 1689794891000
    },
    {
        "time": 1689794893000,
        "value": 139.689,
        "_internal_originalTime": 1689794893000
    },
    {
        "time": 1689794894000,
        "value": 139.688,
        "_internal_originalTime": 1689794894000
    },
    {
        "time": 1689794895000,
        "value": 139.689,
        "_internal_originalTime": 1689794895000
    },
    {
        "time": 1689794899000,
        "value": 139.683,
        "_internal_originalTime": 1689794899000
    },
    {
        "time": 1689794903000,
        "value": 139.687,
        "_internal_originalTime": 1689794903000
    },
    {
        "time": 1689794904000,
        "value": 139.684,
        "_internal_originalTime": 1689794904000
    },
    {
        "time": 1689794905000,
        "value": 139.68,
        "_internal_originalTime": 1689794905000
    },
    {
        "time": 1689794908000,
        "value": 139.679,
        "_internal_originalTime": 1689794908000
    },
    {
        "time": 1689794909000,
        "value": 139.684,
        "_internal_originalTime": 1689794909000
    },
    {
        "time": 1689794910000,
        "value": 139.681,
        "_internal_originalTime": 1689794910000
    },
    {
        "time": 1689794913000,
        "value": 139.684,
        "_internal_originalTime": 1689794913000
    },
    {
        "time": 1689794914000,
        "value": 139.684,
        "_internal_originalTime": 1689794914000
    },
    {
        "time": 1689794915000,
        "value": 139.685,
        "_internal_originalTime": 1689794915000
    },
    {
        "time": 1689794918000,
        "value": 139.684,
        "_internal_originalTime": 1689794918000
    },
    {
        "time": 1689794919000,
        "value": 139.687,
        "_internal_originalTime": 1689794919000
    },
    {
        "time": 1689794920000,
        "value": 139.688,
        "_internal_originalTime": 1689794920000
    },
    {
        "time": 1689794923000,
        "value": 139.683,
        "_internal_originalTime": 1689794923000
    },
    {
        "time": 1689794924000,
        "value": 139.685,
        "_internal_originalTime": 1689794924000
    },
    {
        "time": 1689794927000,
        "value": 139.693,
        "_internal_originalTime": 1689794927000
    },
    {
        "time": 1689794928000,
        "value": 139.701,
        "_internal_originalTime": 1689794928000
    },
    {
        "time": 1689794929000,
        "value": 139.704,
        "_internal_originalTime": 1689794929000
    },
    {
        "time": 1689794931000,
        "value": 139.704,
        "_internal_originalTime": 1689794931000
    },
    {
        "time": 1689794932000,
        "value": 139.706,
        "_internal_originalTime": 1689794932000
    },
    {
        "time": 1689794934000,
        "value": 139.707,
        "_internal_originalTime": 1689794934000
    },
    {
        "time": 1689794935000,
        "value": 139.705,
        "_internal_originalTime": 1689794935000
    },
    {
        "time": 1689794936000,
        "value": 139.702,
        "_internal_originalTime": 1689794936000
    },
    {
        "time": 1689794938000,
        "value": 139.698,
        "_internal_originalTime": 1689794938000
    },
    {
        "time": 1689794939000,
        "value": 139.699,
        "_internal_originalTime": 1689794939000
    },
    {
        "time": 1689794940000,
        "value": 139.698,
        "_internal_originalTime": 1689794940000
    },
    {
        "time": 1689794941000,
        "value": 139.698,
        "_internal_originalTime": 1689794941000
    },
    {
        "time": 1689794943000,
        "value": 139.699,
        "_internal_originalTime": 1689794943000
    },
    {
        "time": 1689794946000,
        "value": 139.7,
        "_internal_originalTime": 1689794946000
    },
    {
        "time": 1689794947000,
        "value": 139.695,
        "_internal_originalTime": 1689794947000
    },
    {
        "time": 1689794948000,
        "value": 139.697,
        "_internal_originalTime": 1689794948000
    },
    {
        "time": 1689794950000,
        "value": 139.7,
        "_internal_originalTime": 1689794950000
    },
    {
        "time": 1689794951000,
        "value": 139.7,
        "_internal_originalTime": 1689794951000
    },
    {
        "time": 1689794952000,
        "value": 139.697,
        "_internal_originalTime": 1689794952000
    },
    {
        "time": 1689794954000,
        "value": 139.696,
        "_internal_originalTime": 1689794954000
    },
    {
        "time": 1689794956000,
        "value": 139.698,
        "_internal_originalTime": 1689794956000
    },
    {
        "time": 1689794957000,
        "value": 139.696,
        "_internal_originalTime": 1689794957000
    },
    {
        "time": 1689794959000,
        "value": 139.693,
        "_internal_originalTime": 1689794959000
    },
    {
        "time": 1689794961000,
        "value": 139.698,
        "_internal_originalTime": 1689794961000
    },
    {
        "time": 1689794963000,
        "value": 139.701,
        "_internal_originalTime": 1689794963000
    },
    {
        "time": 1689794964000,
        "value": 139.701,
        "_internal_originalTime": 1689794964000
    },
    {
        "time": 1689794965000,
        "value": 139.7,
        "_internal_originalTime": 1689794965000
    },
    {
        "time": 1689794967000,
        "value": 139.698,
        "_internal_originalTime": 1689794967000
    },
    {
        "time": 1689794969000,
        "value": 139.698,
        "_internal_originalTime": 1689794969000
    },
    {
        "time": 1689794970000,
        "value": 139.699,
        "_internal_originalTime": 1689794970000
    },
    {
        "time": 1689794971000,
        "value": 139.7,
        "_internal_originalTime": 1689794971000
    },
    {
        "time": 1689794972000,
        "value": 139.696,
        "_internal_originalTime": 1689794972000
    },
    {
        "time": 1689794973000,
        "value": 139.7,
        "_internal_originalTime": 1689794973000
    },
    {
        "time": 1689794977000,
        "value": 139.696,
        "_internal_originalTime": 1689794977000
    },
    {
        "time": 1689794978000,
        "value": 139.699,
        "_internal_originalTime": 1689794978000
    },
    {
        "time": 1689794979000,
        "value": 139.697,
        "_internal_originalTime": 1689794979000
    },
    {
        "time": 1689794980000,
        "value": 139.7,
        "_internal_originalTime": 1689794980000
    },
    {
        "time": 1689794983000,
        "value": 139.698,
        "_internal_originalTime": 1689794983000
    },
    {
        "time": 1689794985000,
        "value": 139.698,
        "_internal_originalTime": 1689794985000
    },
    {
        "time": 1689794986000,
        "value": 139.697,
        "_internal_originalTime": 1689794986000
    },
    {
        "time": 1689794987000,
        "value": 139.697,
        "_internal_originalTime": 1689794987000
    },
    {
        "time": 1689794988000,
        "value": 139.7,
        "_internal_originalTime": 1689794988000
    },
    {
        "time": 1689794989000,
        "value": 139.696,
        "_internal_originalTime": 1689794989000
    },
    {
        "time": 1689794990000,
        "value": 139.697,
        "_internal_originalTime": 1689794990000
    },
    {
        "time": 1689794992000,
        "value": 139.698,
        "_internal_originalTime": 1689794992000
    },
    {
        "time": 1689794995000,
        "value": 139.697,
        "_internal_originalTime": 1689794995000
    },
    {
        "time": 1689794997000,
        "value": 139.696,
        "_internal_originalTime": 1689794997000
    },
    {
        "time": 1689795000000,
        "value": 139.699,
        "_internal_originalTime": 1689795000000
    },
    {
        "time": 1689795001000,
        "value": 139.689,
        "_internal_originalTime": 1689795001000
    },
    {
        "time": 1689795002000,
        "value": 139.688,
        "_internal_originalTime": 1689795002000
    },
    {
        "time": 1689795003000,
        "value": 139.686,
        "_internal_originalTime": 1689795003000
    },
    {
        "time": 1689795004000,
        "value": 139.687,
        "_internal_originalTime": 1689795004000
    },
    {
        "time": 1689795005000,
        "value": 139.687,
        "_internal_originalTime": 1689795005000
    },
    {
        "time": 1689795007000,
        "value": 139.69,
        "_internal_originalTime": 1689795007000
    },
    {
        "time": 1689795008000,
        "value": 139.688,
        "_internal_originalTime": 1689795008000
    },
    {
        "time": 1689795009000,
        "value": 139.689,
        "_internal_originalTime": 1689795009000
    },
    {
        "time": 1689795010000,
        "value": 139.689,
        "_internal_originalTime": 1689795010000
    },
    {
        "time": 1689795011000,
        "value": 139.69,
        "_internal_originalTime": 1689795011000
    },
    {
        "time": 1689795015000,
        "value": 139.689,
        "_internal_originalTime": 1689795015000
    },
    {
        "time": 1689795016000,
        "value": 139.687,
        "_internal_originalTime": 1689795016000
    },
    {
        "time": 1689795018000,
        "value": 139.688,
        "_internal_originalTime": 1689795018000
    },
    {
        "time": 1689795019000,
        "value": 139.684,
        "_internal_originalTime": 1689795019000
    },
    {
        "time": 1689795020000,
        "value": 139.688,
        "_internal_originalTime": 1689795020000
    },
    {
        "time": 1689795022000,
        "value": 139.689,
        "_internal_originalTime": 1689795022000
    },
    {
        "time": 1689795023000,
        "value": 139.69,
        "_internal_originalTime": 1689795023000
    },
    {
        "time": 1689795024000,
        "value": 139.689,
        "_internal_originalTime": 1689795024000
    },
    {
        "time": 1689795026000,
        "value": 139.687,
        "_internal_originalTime": 1689795026000
    },
    {
        "time": 1689795027000,
        "value": 139.69,
        "_internal_originalTime": 1689795027000
    },
    {
        "time": 1689795029000,
        "value": 139.689,
        "_internal_originalTime": 1689795029000
    },
    {
        "time": 1689795030000,
        "value": 139.688,
        "_internal_originalTime": 1689795030000
    },
    {
        "time": 1689795031000,
        "value": 139.689,
        "_internal_originalTime": 1689795031000
    },
    {
        "time": 1689795032000,
        "value": 139.69,
        "_internal_originalTime": 1689795032000
    },
    {
        "time": 1689795035000,
        "value": 139.69,
        "_internal_originalTime": 1689795035000
    },
    {
        "time": 1689795036000,
        "value": 139.689,
        "_internal_originalTime": 1689795036000
    },
    {
        "time": 1689795038000,
        "value": 139.688,
        "_internal_originalTime": 1689795038000
    },
    {
        "time": 1689795040000,
        "value": 139.689,
        "_internal_originalTime": 1689795040000
    },
    {
        "time": 1689795043000,
        "value": 139.686,
        "_internal_originalTime": 1689795043000
    },
    {
        "time": 1689795045000,
        "value": 139.687,
        "_internal_originalTime": 1689795045000
    },
    {
        "time": 1689795049000,
        "value": 139.684,
        "_internal_originalTime": 1689795049000
    },
    {
        "time": 1689795055000,
        "value": 139.69,
        "_internal_originalTime": 1689795055000
    },
    {
        "time": 1689795058000,
        "value": 139.686,
        "_internal_originalTime": 1689795058000
    },
    {
        "time": 1689795059000,
        "value": 139.687,
        "_internal_originalTime": 1689795059000
    },
    {
        "time": 1689795060000,
        "value": 139.685,
        "_internal_originalTime": 1689795060000
    },
    {
        "time": 1689795061000,
        "value": 139.686,
        "_internal_originalTime": 1689795061000
    },
    {
        "time": 1689795063000,
        "value": 139.685,
        "_internal_originalTime": 1689795063000
    },
    {
        "time": 1689795064000,
        "value": 139.687,
        "_internal_originalTime": 1689795064000
    },
    {
        "time": 1689795066000,
        "value": 139.684,
        "_internal_originalTime": 1689795066000
    },
    {
        "time": 1689795068000,
        "value": 139.685,
        "_internal_originalTime": 1689795068000
    },
    {
        "time": 1689795069000,
        "value": 139.687,
        "_internal_originalTime": 1689795069000
    },
    {
        "time": 1689795071000,
        "value": 139.687,
        "_internal_originalTime": 1689795071000
    },
    {
        "time": 1689795072000,
        "value": 139.689,
        "_internal_originalTime": 1689795072000
    },
    {
        "time": 1689795073000,
        "value": 139.686,
        "_internal_originalTime": 1689795073000
    },
    {
        "time": 1689795075000,
        "value": 139.689,
        "_internal_originalTime": 1689795075000
    },
    {
        "time": 1689795078000,
        "value": 139.689,
        "_internal_originalTime": 1689795078000
    },
    {
        "time": 1689795079000,
        "value": 139.683,
        "_internal_originalTime": 1689795079000
    },
    {
        "time": 1689795086000,
        "value": 139.685,
        "_internal_originalTime": 1689795086000
    },
    {
        "time": 1689795087000,
        "value": 139.688,
        "_internal_originalTime": 1689795087000
    },
    {
        "time": 1689795089000,
        "value": 139.685,
        "_internal_originalTime": 1689795089000
    },
    {
        "time": 1689795090000,
        "value": 139.684,
        "_internal_originalTime": 1689795090000
    },
    {
        "time": 1689795091000,
        "value": 139.685,
        "_internal_originalTime": 1689795091000
    },
    {
        "time": 1689795095000,
        "value": 139.684,
        "_internal_originalTime": 1689795095000
    },
    {
        "time": 1689795096000,
        "value": 139.685,
        "_internal_originalTime": 1689795096000
    },
    {
        "time": 1689795098000,
        "value": 139.686,
        "_internal_originalTime": 1689795098000
    },
    {
        "time": 1689795101000,
        "value": 139.688,
        "_internal_originalTime": 1689795101000
    },
    {
        "time": 1689795102000,
        "value": 139.685,
        "_internal_originalTime": 1689795102000
    },
    {
        "time": 1689795105000,
        "value": 139.687,
        "_internal_originalTime": 1689795105000
    },
    {
        "time": 1689795107000,
        "value": 139.688,
        "_internal_originalTime": 1689795107000
    },
    {
        "time": 1689795109000,
        "value": 139.681,
        "_internal_originalTime": 1689795109000
    },
    {
        "time": 1689795113000,
        "value": 139.683,
        "_internal_originalTime": 1689795113000
    },
    {
        "time": 1689795115000,
        "value": 139.688,
        "_internal_originalTime": 1689795115000
    },
    {
        "time": 1689795116000,
        "value": 139.685,
        "_internal_originalTime": 1689795116000
    },
    {
        "time": 1689795118000,
        "value": 139.688,
        "_internal_originalTime": 1689795118000
    },
    {
        "time": 1689795119000,
        "value": 139.687,
        "_internal_originalTime": 1689795119000
    },
    {
        "time": 1689795120000,
        "value": 139.688,
        "_internal_originalTime": 1689795120000
    },
    {
        "time": 1689795121000,
        "value": 139.686,
        "_internal_originalTime": 1689795121000
    },
    {
        "time": 1689795122000,
        "value": 139.687,
        "_internal_originalTime": 1689795122000
    },
    {
        "time": 1689795123000,
        "value": 139.686,
        "_internal_originalTime": 1689795123000
    },
    {
        "time": 1689795125000,
        "value": 139.687,
        "_internal_originalTime": 1689795125000
    },
    {
        "time": 1689795127000,
        "value": 139.688,
        "_internal_originalTime": 1689795127000
    },
    {
        "time": 1689795128000,
        "value": 139.686,
        "_internal_originalTime": 1689795128000
    },
    {
        "time": 1689795129000,
        "value": 139.684,
        "_internal_originalTime": 1689795129000
    },
    {
        "time": 1689795130000,
        "value": 139.687,
        "_internal_originalTime": 1689795130000
    },
    {
        "time": 1689795132000,
        "value": 139.688,
        "_internal_originalTime": 1689795132000
    },
    {
        "time": 1689795133000,
        "value": 139.69,
        "_internal_originalTime": 1689795133000
    },
    {
        "time": 1689795134000,
        "value": 139.693,
        "_internal_originalTime": 1689795134000
    },
    {
        "time": 1689795135000,
        "value": 139.693,
        "_internal_originalTime": 1689795135000
    },
    {
        "time": 1689795137000,
        "value": 139.696,
        "_internal_originalTime": 1689795137000
    },
    {
        "time": 1689795139000,
        "value": 139.689,
        "_internal_originalTime": 1689795139000
    },
    {
        "time": 1689795140000,
        "value": 139.693,
        "_internal_originalTime": 1689795140000
    },
    {
        "time": 1689795141000,
        "value": 139.697,
        "_internal_originalTime": 1689795141000
    },
    {
        "time": 1689795142000,
        "value": 139.697,
        "_internal_originalTime": 1689795142000
    },
    {
        "time": 1689795143000,
        "value": 139.692,
        "_internal_originalTime": 1689795143000
    },
    {
        "time": 1689795144000,
        "value": 139.696,
        "_internal_originalTime": 1689795144000
    },
    {
        "time": 1689795145000,
        "value": 139.696,
        "_internal_originalTime": 1689795145000
    },
    {
        "time": 1689795151000,
        "value": 139.692,
        "_internal_originalTime": 1689795151000
    },
    {
        "time": 1689795154000,
        "value": 139.693,
        "_internal_originalTime": 1689795154000
    },
    {
        "time": 1689795158000,
        "value": 139.695,
        "_internal_originalTime": 1689795158000
    },
    {
        "time": 1689795160000,
        "value": 139.692,
        "_internal_originalTime": 1689795160000
    },
    {
        "time": 1689795161000,
        "value": 139.698,
        "_internal_originalTime": 1689795161000
    },
    {
        "time": 1689795162000,
        "value": 139.695,
        "_internal_originalTime": 1689795162000
    },
    {
        "time": 1689795164000,
        "value": 139.697,
        "_internal_originalTime": 1689795164000
    },
    {
        "time": 1689795165000,
        "value": 139.694,
        "_internal_originalTime": 1689795165000
    },
    {
        "time": 1689795166000,
        "value": 139.699,
        "_internal_originalTime": 1689795166000
    },
    {
        "time": 1689795167000,
        "value": 139.699,
        "_internal_originalTime": 1689795167000
    },
    {
        "time": 1689795168000,
        "value": 139.699,
        "_internal_originalTime": 1689795168000
    },
    {
        "time": 1689795169000,
        "value": 139.692,
        "_internal_originalTime": 1689795169000
    },
    {
        "time": 1689795171000,
        "value": 139.694,
        "_internal_originalTime": 1689795171000
    },
    {
        "time": 1689795174000,
        "value": 139.698,
        "_internal_originalTime": 1689795174000
    },
    {
        "time": 1689795175000,
        "value": 139.697,
        "_internal_originalTime": 1689795175000
    },
    {
        "time": 1689795176000,
        "value": 139.693,
        "_internal_originalTime": 1689795176000
    },
    {
        "time": 1689795178000,
        "value": 139.696,
        "_internal_originalTime": 1689795178000
    },
    {
        "time": 1689795182000,
        "value": 139.688,
        "_internal_originalTime": 1689795182000
    },
    {
        "time": 1689795183000,
        "value": 139.692,
        "_internal_originalTime": 1689795183000
    },
    {
        "time": 1689795184000,
        "value": 139.69,
        "_internal_originalTime": 1689795184000
    },
    {
        "time": 1689795186000,
        "value": 139.692,
        "_internal_originalTime": 1689795186000
    },
    {
        "time": 1689795187000,
        "value": 139.688,
        "_internal_originalTime": 1689795187000
    },
    {
        "time": 1689795188000,
        "value": 139.691,
        "_internal_originalTime": 1689795188000
    },
    {
        "time": 1689795189000,
        "value": 139.692,
        "_internal_originalTime": 1689795189000
    },
    {
        "time": 1689795191000,
        "value": 139.692,
        "_internal_originalTime": 1689795191000
    },
    {
        "time": 1689795193000,
        "value": 139.692,
        "_internal_originalTime": 1689795193000
    },
    {
        "time": 1689795194000,
        "value": 139.686,
        "_internal_originalTime": 1689795194000
    },
    {
        "time": 1689795195000,
        "value": 139.686,
        "_internal_originalTime": 1689795195000
    },
    {
        "time": 1689795197000,
        "value": 139.687,
        "_internal_originalTime": 1689795197000
    },
    {
        "time": 1689795200000,
        "value": 139.683,
        "_internal_originalTime": 1689795200000
    },
    {
        "time": 1689795201000,
        "value": 139.683,
        "_internal_originalTime": 1689795201000
    },
    {
        "time": 1689795202000,
        "value": 139.683,
        "_internal_originalTime": 1689795202000
    },
    {
        "time": 1689795203000,
        "value": 139.683,
        "_internal_originalTime": 1689795203000
    },
    {
        "time": 1689795205000,
        "value": 139.683,
        "_internal_originalTime": 1689795205000
    },
    {
        "time": 1689795206000,
        "value": 139.688,
        "_internal_originalTime": 1689795206000
    },
    {
        "time": 1689795207000,
        "value": 139.686,
        "_internal_originalTime": 1689795207000
    },
    {
        "time": 1689795209000,
        "value": 139.688,
        "_internal_originalTime": 1689795209000
    },
    {
        "time": 1689795212000,
        "value": 139.689,
        "_internal_originalTime": 1689795212000
    },
    {
        "time": 1689795213000,
        "value": 139.686,
        "_internal_originalTime": 1689795213000
    },
    {
        "time": 1689795216000,
        "value": 139.689,
        "_internal_originalTime": 1689795216000
    },
    {
        "time": 1689795218000,
        "value": 139.688,
        "_internal_originalTime": 1689795218000
    },
    {
        "time": 1689795219000,
        "value": 139.689,
        "_internal_originalTime": 1689795219000
    },
    {
        "time": 1689795220000,
        "value": 139.689,
        "_internal_originalTime": 1689795220000
    },
    {
        "time": 1689795221000,
        "value": 139.689,
        "_internal_originalTime": 1689795221000
    },
    {
        "time": 1689795222000,
        "value": 139.684,
        "_internal_originalTime": 1689795222000
    },
    {
        "time": 1689795224000,
        "value": 139.685,
        "_internal_originalTime": 1689795224000
    },
    {
        "time": 1689795225000,
        "value": 139.686,
        "_internal_originalTime": 1689795225000
    },
    {
        "time": 1689795227000,
        "value": 139.689,
        "_internal_originalTime": 1689795227000
    },
    {
        "time": 1689795228000,
        "value": 139.69,
        "_internal_originalTime": 1689795228000
    },
    {
        "time": 1689795229000,
        "value": 139.69,
        "_internal_originalTime": 1689795229000
    },
    {
        "time": 1689795230000,
        "value": 139.69,
        "_internal_originalTime": 1689795230000
    },
    {
        "time": 1689795231000,
        "value": 139.688,
        "_internal_originalTime": 1689795231000
    },
    {
        "time": 1689795232000,
        "value": 139.69,
        "_internal_originalTime": 1689795232000
    },
    {
        "time": 1689795233000,
        "value": 139.689,
        "_internal_originalTime": 1689795233000
    },
    {
        "time": 1689795238000,
        "value": 139.689,
        "_internal_originalTime": 1689795238000
    },
    {
        "time": 1689795239000,
        "value": 139.689,
        "_internal_originalTime": 1689795239000
    },
    {
        "time": 1689795243000,
        "value": 139.69,
        "_internal_originalTime": 1689795243000
    },
    {
        "time": 1689795244000,
        "value": 139.69,
        "_internal_originalTime": 1689795244000
    },
    {
        "time": 1689795245000,
        "value": 139.688,
        "_internal_originalTime": 1689795245000
    },
    {
        "time": 1689795246000,
        "value": 139.689,
        "_internal_originalTime": 1689795246000
    },
    {
        "time": 1689795247000,
        "value": 139.693,
        "_internal_originalTime": 1689795247000
    },
    {
        "time": 1689795248000,
        "value": 139.691,
        "_internal_originalTime": 1689795248000
    },
    {
        "time": 1689795253000,
        "value": 139.686,
        "_internal_originalTime": 1689795253000
    },
    {
        "time": 1689795254000,
        "value": 139.692,
        "_internal_originalTime": 1689795254000
    },
    {
        "time": 1689795255000,
        "value": 139.693,
        "_internal_originalTime": 1689795255000
    },
    {
        "time": 1689795259000,
        "value": 139.686,
        "_internal_originalTime": 1689795259000
    },
    {
        "time": 1689795265000,
        "value": 139.688,
        "_internal_originalTime": 1689795265000
    },
    {
        "time": 1689795266000,
        "value": 139.69,
        "_internal_originalTime": 1689795266000
    },
    {
        "time": 1689795267000,
        "value": 139.689,
        "_internal_originalTime": 1689795267000
    },
    {
        "time": 1689795268000,
        "value": 139.688,
        "_internal_originalTime": 1689795268000
    },
    {
        "time": 1689795270000,
        "value": 139.69,
        "_internal_originalTime": 1689795270000
    },
    {
        "time": 1689795271000,
        "value": 139.689,
        "_internal_originalTime": 1689795271000
    },
    {
        "time": 1689795273000,
        "value": 139.689,
        "_internal_originalTime": 1689795273000
    },
    {
        "time": 1689795274000,
        "value": 139.689,
        "_internal_originalTime": 1689795274000
    },
    {
        "time": 1689795275000,
        "value": 139.689,
        "_internal_originalTime": 1689795275000
    },
    {
        "time": 1689795276000,
        "value": 139.687,
        "_internal_originalTime": 1689795276000
    },
    {
        "time": 1689795278000,
        "value": 139.69,
        "_internal_originalTime": 1689795278000
    },
    {
        "time": 1689795280000,
        "value": 139.689,
        "_internal_originalTime": 1689795280000
    },
    {
        "time": 1689795283000,
        "value": 139.69,
        "_internal_originalTime": 1689795283000
    },
    {
        "time": 1689795284000,
        "value": 139.686,
        "_internal_originalTime": 1689795284000
    },
    {
        "time": 1689795286000,
        "value": 139.688,
        "_internal_originalTime": 1689795286000
    },
    {
        "time": 1689795288000,
        "value": 139.692,
        "_internal_originalTime": 1689795288000
    },
    {
        "time": 1689795290000,
        "value": 139.689,
        "_internal_originalTime": 1689795290000
    },
    {
        "time": 1689795293000,
        "value": 139.688,
        "_internal_originalTime": 1689795293000
    },
    {
        "time": 1689795294000,
        "value": 139.692,
        "_internal_originalTime": 1689795294000
    },
    {
        "time": 1689795295000,
        "value": 139.689,
        "_internal_originalTime": 1689795295000
    },
    {
        "time": 1689795301000,
        "value": 139.692,
        "_internal_originalTime": 1689795301000
    },
    {
        "time": 1689795303000,
        "value": 139.693,
        "_internal_originalTime": 1689795303000
    },
    {
        "time": 1689795304000,
        "value": 139.692,
        "_internal_originalTime": 1689795304000
    },
    {
        "time": 1689795305000,
        "value": 139.692,
        "_internal_originalTime": 1689795305000
    },
    {
        "time": 1689795307000,
        "value": 139.693,
        "_internal_originalTime": 1689795307000
    },
    {
        "time": 1689795308000,
        "value": 139.693,
        "_internal_originalTime": 1689795308000
    },
    {
        "time": 1689795310000,
        "value": 139.69,
        "_internal_originalTime": 1689795310000
    },
    {
        "time": 1689795312000,
        "value": 139.69,
        "_internal_originalTime": 1689795312000
    },
    {
        "time": 1689795313000,
        "value": 139.686,
        "_internal_originalTime": 1689795313000
    },
    {
        "time": 1689795314000,
        "value": 139.689,
        "_internal_originalTime": 1689795314000
    },
    {
        "time": 1689795315000,
        "value": 139.689,
        "_internal_originalTime": 1689795315000
    },
    {
        "time": 1689795319000,
        "value": 139.683,
        "_internal_originalTime": 1689795319000
    },
    {
        "time": 1689795320000,
        "value": 139.684,
        "_internal_originalTime": 1689795320000
    },
    {
        "time": 1689795321000,
        "value": 139.685,
        "_internal_originalTime": 1689795321000
    },
    {
        "time": 1689795323000,
        "value": 139.689,
        "_internal_originalTime": 1689795323000
    },
    {
        "time": 1689795325000,
        "value": 139.688,
        "_internal_originalTime": 1689795325000
    },
    {
        "time": 1689795328000,
        "value": 139.689,
        "_internal_originalTime": 1689795328000
    },
    {
        "time": 1689795330000,
        "value": 139.691,
        "_internal_originalTime": 1689795330000
    },
    {
        "time": 1689795331000,
        "value": 139.689,
        "_internal_originalTime": 1689795331000
    },
    {
        "time": 1689795332000,
        "value": 139.687,
        "_internal_originalTime": 1689795332000
    },
    {
        "time": 1689795335000,
        "value": 139.688,
        "_internal_originalTime": 1689795335000
    },
    {
        "time": 1689795336000,
        "value": 139.687,
        "_internal_originalTime": 1689795336000
    },
    {
        "time": 1689795338000,
        "value": 139.686,
        "_internal_originalTime": 1689795338000
    },
    {
        "time": 1689795341000,
        "value": 139.684,
        "_internal_originalTime": 1689795341000
    },
    {
        "time": 1689795342000,
        "value": 139.684,
        "_internal_originalTime": 1689795342000
    },
    {
        "time": 1689795343000,
        "value": 139.69,
        "_internal_originalTime": 1689795343000
    },
    {
        "time": 1689795345000,
        "value": 139.688,
        "_internal_originalTime": 1689795345000
    },
    {
        "time": 1689795347000,
        "value": 139.689,
        "_internal_originalTime": 1689795347000
    },
    {
        "time": 1689795349000,
        "value": 139.687,
        "_internal_originalTime": 1689795349000
    },
    {
        "time": 1689795352000,
        "value": 139.691,
        "_internal_originalTime": 1689795352000
    },
    {
        "time": 1689795354000,
        "value": 139.689,
        "_internal_originalTime": 1689795354000
    },
    {
        "time": 1689795355000,
        "value": 139.693,
        "_internal_originalTime": 1689795355000
    },
    {
        "time": 1689795357000,
        "value": 139.695,
        "_internal_originalTime": 1689795357000
    },
    {
        "time": 1689795358000,
        "value": 139.695,
        "_internal_originalTime": 1689795358000
    },
    {
        "time": 1689795360000,
        "value": 139.698,
        "_internal_originalTime": 1689795360000
    },
    {
        "time": 1689795366000,
        "value": 139.699,
        "_internal_originalTime": 1689795366000
    },
    {
        "time": 1689795368000,
        "value": 139.697,
        "_internal_originalTime": 1689795368000
    },
    {
        "time": 1689795372000,
        "value": 139.699,
        "_internal_originalTime": 1689795372000
    },
    {
        "time": 1689795374000,
        "value": 139.7,
        "_internal_originalTime": 1689795374000
    },
    {
        "time": 1689795375000,
        "value": 139.696,
        "_internal_originalTime": 1689795375000
    },
    {
        "time": 1689795376000,
        "value": 139.7,
        "_internal_originalTime": 1689795376000
    },
    {
        "time": 1689795379000,
        "value": 139.695,
        "_internal_originalTime": 1689795379000
    },
    {
        "time": 1689795381000,
        "value": 139.697,
        "_internal_originalTime": 1689795381000
    },
    {
        "time": 1689795382000,
        "value": 139.696,
        "_internal_originalTime": 1689795382000
    },
    {
        "time": 1689795383000,
        "value": 139.7,
        "_internal_originalTime": 1689795383000
    },
    {
        "time": 1689795385000,
        "value": 139.701,
        "_internal_originalTime": 1689795385000
    },
    {
        "time": 1689795387000,
        "value": 139.7,
        "_internal_originalTime": 1689795387000
    },
    {
        "time": 1689795388000,
        "value": 139.7,
        "_internal_originalTime": 1689795388000
    },
    {
        "time": 1689795389000,
        "value": 139.697,
        "_internal_originalTime": 1689795389000
    },
    {
        "time": 1689795394000,
        "value": 139.697,
        "_internal_originalTime": 1689795394000
    },
    {
        "time": 1689795395000,
        "value": 139.698,
        "_internal_originalTime": 1689795395000
    },
    {
        "time": 1689795396000,
        "value": 139.699,
        "_internal_originalTime": 1689795396000
    },
    {
        "time": 1689795397000,
        "value": 139.699,
        "_internal_originalTime": 1689795397000
    },
    {
        "time": 1689795398000,
        "value": 139.7,
        "_internal_originalTime": 1689795398000
    },
    {
        "time": 1689795401000,
        "value": 139.699,
        "_internal_originalTime": 1689795401000
    },
    {
        "time": 1689795402000,
        "value": 139.699,
        "_internal_originalTime": 1689795402000
    },
    {
        "time": 1689795404000,
        "value": 139.697,
        "_internal_originalTime": 1689795404000
    },
    {
        "time": 1689795406000,
        "value": 139.699,
        "_internal_originalTime": 1689795406000
    },
    {
        "time": 1689795407000,
        "value": 139.696,
        "_internal_originalTime": 1689795407000
    },
    {
        "time": 1689795409000,
        "value": 139.696,
        "_internal_originalTime": 1689795409000
    },
    {
        "time": 1689795410000,
        "value": 139.696,
        "_internal_originalTime": 1689795410000
    },
    {
        "time": 1689795414000,
        "value": 139.699,
        "_internal_originalTime": 1689795414000
    },
    {
        "time": 1689795416000,
        "value": 139.699,
        "_internal_originalTime": 1689795416000
    },
    {
        "time": 1689795419000,
        "value": 139.699,
        "_internal_originalTime": 1689795419000
    },
    {
        "time": 1689795421000,
        "value": 139.699,
        "_internal_originalTime": 1689795421000
    },
    {
        "time": 1689795422000,
        "value": 139.696,
        "_internal_originalTime": 1689795422000
    },
    {
        "time": 1689795424000,
        "value": 139.697,
        "_internal_originalTime": 1689795424000
    },
    {
        "time": 1689795425000,
        "value": 139.7,
        "_internal_originalTime": 1689795425000
    },
    {
        "time": 1689795428000,
        "value": 139.699,
        "_internal_originalTime": 1689795428000
    },
    {
        "time": 1689795429000,
        "value": 139.697,
        "_internal_originalTime": 1689795429000
    },
    {
        "time": 1689795435000,
        "value": 139.7,
        "_internal_originalTime": 1689795435000
    },
    {
        "time": 1689795436000,
        "value": 139.697,
        "_internal_originalTime": 1689795436000
    },
    {
        "time": 1689795437000,
        "value": 139.701,
        "_internal_originalTime": 1689795437000
    },
    {
        "time": 1689795439000,
        "value": 139.695,
        "_internal_originalTime": 1689795439000
    },
    {
        "time": 1689795440000,
        "value": 139.696,
        "_internal_originalTime": 1689795440000
    },
    {
        "time": 1689795445000,
        "value": 139.704,
        "_internal_originalTime": 1689795445000
    },
    {
        "time": 1689795447000,
        "value": 139.696,
        "_internal_originalTime": 1689795447000
    },
    {
        "time": 1689795448000,
        "value": 139.696,
        "_internal_originalTime": 1689795448000
    },
    {
        "time": 1689795449000,
        "value": 139.699,
        "_internal_originalTime": 1689795449000
    },
    {
        "time": 1689795450000,
        "value": 139.696,
        "_internal_originalTime": 1689795450000
    },
    {
        "time": 1689795452000,
        "value": 139.699,
        "_internal_originalTime": 1689795452000
    },
    {
        "time": 1689795453000,
        "value": 139.699,
        "_internal_originalTime": 1689795453000
    },
    {
        "time": 1689795454000,
        "value": 139.698,
        "_internal_originalTime": 1689795454000
    },
    {
        "time": 1689795455000,
        "value": 139.694,
        "_internal_originalTime": 1689795455000
    },
    {
        "time": 1689795456000,
        "value": 139.695,
        "_internal_originalTime": 1689795456000
    },
    {
        "time": 1689795458000,
        "value": 139.696,
        "_internal_originalTime": 1689795458000
    },
    {
        "time": 1689795462000,
        "value": 139.698,
        "_internal_originalTime": 1689795462000
    },
    {
        "time": 1689795463000,
        "value": 139.694,
        "_internal_originalTime": 1689795463000
    },
    {
        "time": 1689795464000,
        "value": 139.697,
        "_internal_originalTime": 1689795464000
    },
    {
        "time": 1689795465000,
        "value": 139.695,
        "_internal_originalTime": 1689795465000
    },
    {
        "time": 1689795468000,
        "value": 139.695,
        "_internal_originalTime": 1689795468000
    },
    {
        "time": 1689795469000,
        "value": 139.696,
        "_internal_originalTime": 1689795469000
    },
    {
        "time": 1689795470000,
        "value": 139.699,
        "_internal_originalTime": 1689795470000
    },
    {
        "time": 1689795471000,
        "value": 139.705,
        "_internal_originalTime": 1689795471000
    },
    {
        "time": 1689795472000,
        "value": 139.708,
        "_internal_originalTime": 1689795472000
    },
    {
        "time": 1689795473000,
        "value": 139.705,
        "_internal_originalTime": 1689795473000
    },
    {
        "time": 1689795474000,
        "value": 139.704,
        "_internal_originalTime": 1689795474000
    },
    {
        "time": 1689795475000,
        "value": 139.709,
        "_internal_originalTime": 1689795475000
    },
    {
        "time": 1689795476000,
        "value": 139.705,
        "_internal_originalTime": 1689795476000
    },
    {
        "time": 1689795480000,
        "value": 139.704,
        "_internal_originalTime": 1689795480000
    },
    {
        "time": 1689795481000,
        "value": 139.698,
        "_internal_originalTime": 1689795481000
    },
    {
        "time": 1689795484000,
        "value": 139.701,
        "_internal_originalTime": 1689795484000
    },
    {
        "time": 1689795485000,
        "value": 139.698,
        "_internal_originalTime": 1689795485000
    },
    {
        "time": 1689795487000,
        "value": 139.701,
        "_internal_originalTime": 1689795487000
    },
    {
        "time": 1689795488000,
        "value": 139.7,
        "_internal_originalTime": 1689795488000
    },
    {
        "time": 1689795490000,
        "value": 139.7,
        "_internal_originalTime": 1689795490000
    },
    {
        "time": 1689795492000,
        "value": 139.698,
        "_internal_originalTime": 1689795492000
    },
    {
        "time": 1689795494000,
        "value": 139.704,
        "_internal_originalTime": 1689795494000
    },
    {
        "time": 1689795495000,
        "value": 139.7,
        "_internal_originalTime": 1689795495000
    },
    {
        "time": 1689795496000,
        "value": 139.697,
        "_internal_originalTime": 1689795496000
    },
    {
        "time": 1689795497000,
        "value": 139.695,
        "_internal_originalTime": 1689795497000
    },
    {
        "time": 1689795498000,
        "value": 139.698,
        "_internal_originalTime": 1689795498000
    },
    {
        "time": 1689795499000,
        "value": 139.696,
        "_internal_originalTime": 1689795499000
    },
    {
        "time": 1689795500000,
        "value": 139.696,
        "_internal_originalTime": 1689795500000
    },
    {
        "time": 1689795502000,
        "value": 139.696,
        "_internal_originalTime": 1689795502000
    },
    {
        "time": 1689795503000,
        "value": 139.693,
        "_internal_originalTime": 1689795503000
    },
    {
        "time": 1689795506000,
        "value": 139.695,
        "_internal_originalTime": 1689795506000
    },
    {
        "time": 1689795507000,
        "value": 139.698,
        "_internal_originalTime": 1689795507000
    },
    {
        "time": 1689795508000,
        "value": 139.702,
        "_internal_originalTime": 1689795508000
    },
    {
        "time": 1689795510000,
        "value": 139.699,
        "_internal_originalTime": 1689795510000
    },
    {
        "time": 1689795511000,
        "value": 139.701,
        "_internal_originalTime": 1689795511000
    },
    {
        "time": 1689795513000,
        "value": 139.701,
        "_internal_originalTime": 1689795513000
    },
    {
        "time": 1689795516000,
        "value": 139.706,
        "_internal_originalTime": 1689795516000
    },
    {
        "time": 1689795518000,
        "value": 139.704,
        "_internal_originalTime": 1689795518000
    },
    {
        "time": 1689795519000,
        "value": 139.702,
        "_internal_originalTime": 1689795519000
    },
    {
        "time": 1689795521000,
        "value": 139.705,
        "_internal_originalTime": 1689795521000
    },
    {
        "time": 1689795524000,
        "value": 139.708,
        "_internal_originalTime": 1689795524000
    },
    {
        "time": 1689795525000,
        "value": 139.709,
        "_internal_originalTime": 1689795525000
    },
    {
        "time": 1689795527000,
        "value": 139.709,
        "_internal_originalTime": 1689795527000
    },
    {
        "time": 1689795529000,
        "value": 139.701,
        "_internal_originalTime": 1689795529000
    },
    {
        "time": 1689795530000,
        "value": 139.708,
        "_internal_originalTime": 1689795530000
    },
    {
        "time": 1689795533000,
        "value": 139.704,
        "_internal_originalTime": 1689795533000
    },
    {
        "time": 1689795534000,
        "value": 139.709,
        "_internal_originalTime": 1689795534000
    },
    {
        "time": 1689795535000,
        "value": 139.708,
        "_internal_originalTime": 1689795535000
    },
    {
        "time": 1689795536000,
        "value": 139.705,
        "_internal_originalTime": 1689795536000
    },
    {
        "time": 1689795538000,
        "value": 139.704,
        "_internal_originalTime": 1689795538000
    },
    {
        "time": 1689795539000,
        "value": 139.705,
        "_internal_originalTime": 1689795539000
    },
    {
        "time": 1689795540000,
        "value": 139.707,
        "_internal_originalTime": 1689795540000
    },
    {
        "time": 1689795541000,
        "value": 139.705,
        "_internal_originalTime": 1689795541000
    },
    {
        "time": 1689795542000,
        "value": 139.708,
        "_internal_originalTime": 1689795542000
    },
    {
        "time": 1689795546000,
        "value": 139.709,
        "_internal_originalTime": 1689795546000
    },
    {
        "time": 1689795547000,
        "value": 139.708,
        "_internal_originalTime": 1689795547000
    },
    {
        "time": 1689795548000,
        "value": 139.714,
        "_internal_originalTime": 1689795548000
    },
    {
        "time": 1689795549000,
        "value": 139.714,
        "_internal_originalTime": 1689795549000
    },
    {
        "time": 1689795550000,
        "value": 139.714,
        "_internal_originalTime": 1689795550000
    },
    {
        "time": 1689795551000,
        "value": 139.714,
        "_internal_originalTime": 1689795551000
    },
    {
        "time": 1689795552000,
        "value": 139.71,
        "_internal_originalTime": 1689795552000
    },
    {
        "time": 1689795553000,
        "value": 139.713,
        "_internal_originalTime": 1689795553000
    },
    {
        "time": 1689795555000,
        "value": 139.708,
        "_internal_originalTime": 1689795555000
    },
    {
        "time": 1689795559000,
        "value": 139.718,
        "_internal_originalTime": 1689795559000
    },
    {
        "time": 1689795560000,
        "value": 139.726,
        "_internal_originalTime": 1689795560000
    },
    {
        "time": 1689795561000,
        "value": 139.723,
        "_internal_originalTime": 1689795561000
    },
    {
        "time": 1689795563000,
        "value": 139.725,
        "_internal_originalTime": 1689795563000
    },
    {
        "time": 1689795565000,
        "value": 139.725,
        "_internal_originalTime": 1689795565000
    },
    {
        "time": 1689795567000,
        "value": 139.724,
        "_internal_originalTime": 1689795567000
    },
    {
        "time": 1689795569000,
        "value": 139.725,
        "_internal_originalTime": 1689795569000
    },
    {
        "time": 1689795571000,
        "value": 139.724,
        "_internal_originalTime": 1689795571000
    },
    {
        "time": 1689795574000,
        "value": 139.728,
        "_internal_originalTime": 1689795574000
    },
    {
        "time": 1689795577000,
        "value": 139.726,
        "_internal_originalTime": 1689795577000
    },
    {
        "time": 1689795578000,
        "value": 139.724,
        "_internal_originalTime": 1689795578000
    },
    {
        "time": 1689795579000,
        "value": 139.72,
        "_internal_originalTime": 1689795579000
    },
    {
        "time": 1689795580000,
        "value": 139.722,
        "_internal_originalTime": 1689795580000
    },
    {
        "time": 1689795581000,
        "value": 139.72,
        "_internal_originalTime": 1689795581000
    },
    {
        "time": 1689795582000,
        "value": 139.716,
        "_internal_originalTime": 1689795582000
    },
    {
        "time": 1689795583000,
        "value": 139.716,
        "_internal_originalTime": 1689795583000
    },
    {
        "time": 1689795584000,
        "value": 139.721,
        "_internal_originalTime": 1689795584000
    },
    {
        "time": 1689795585000,
        "value": 139.723,
        "_internal_originalTime": 1689795585000
    },
    {
        "time": 1689795586000,
        "value": 139.725,
        "_internal_originalTime": 1689795586000
    },
    {
        "time": 1689795587000,
        "value": 139.725,
        "_internal_originalTime": 1689795587000
    },
    {
        "time": 1689795588000,
        "value": 139.726,
        "_internal_originalTime": 1689795588000
    },
    {
        "time": 1689795589000,
        "value": 139.721,
        "_internal_originalTime": 1689795589000
    },
    {
        "time": 1689795597000,
        "value": 139.723,
        "_internal_originalTime": 1689795597000
    },
    {
        "time": 1689795598000,
        "value": 139.722,
        "_internal_originalTime": 1689795598000
    },
    {
        "time": 1689795600000,
        "value": 139.724,
        "_internal_originalTime": 1689795600000
    },
    {
        "time": 1689795601000,
        "value": 139.724,
        "_internal_originalTime": 1689795601000
    },
    {
        "time": 1689795604000,
        "value": 139.717,
        "_internal_originalTime": 1689795604000
    },
    {
        "time": 1689795605000,
        "value": 139.718,
        "_internal_originalTime": 1689795605000
    },
    {
        "time": 1689795606000,
        "value": 139.714,
        "_internal_originalTime": 1689795606000
    },
    {
        "time": 1689795608000,
        "value": 139.719,
        "_internal_originalTime": 1689795608000
    },
    {
        "time": 1689795609000,
        "value": 139.72,
        "_internal_originalTime": 1689795609000
    },
    {
        "time": 1689795611000,
        "value": 139.714,
        "_internal_originalTime": 1689795611000
    },
    {
        "time": 1689795612000,
        "value": 139.714,
        "_internal_originalTime": 1689795612000
    },
    {
        "time": 1689795614000,
        "value": 139.72,
        "_internal_originalTime": 1689795614000
    },
    {
        "time": 1689795615000,
        "value": 139.719,
        "_internal_originalTime": 1689795615000
    },
    {
        "time": 1689795616000,
        "value": 139.721,
        "_internal_originalTime": 1689795616000
    },
    {
        "time": 1689795617000,
        "value": 139.714,
        "_internal_originalTime": 1689795617000
    },
    {
        "time": 1689795619000,
        "value": 139.713,
        "_internal_originalTime": 1689795619000
    },
    {
        "time": 1689795620000,
        "value": 139.714,
        "_internal_originalTime": 1689795620000
    },
    {
        "time": 1689795622000,
        "value": 139.719,
        "_internal_originalTime": 1689795622000
    },
    {
        "time": 1689795623000,
        "value": 139.719,
        "_internal_originalTime": 1689795623000
    },
    {
        "time": 1689795626000,
        "value": 139.714,
        "_internal_originalTime": 1689795626000
    },
    {
        "time": 1689795627000,
        "value": 139.714,
        "_internal_originalTime": 1689795627000
    },
    {
        "time": 1689795629000,
        "value": 139.719,
        "_internal_originalTime": 1689795629000
    },
    {
        "time": 1689795630000,
        "value": 139.719,
        "_internal_originalTime": 1689795630000
    },
    {
        "time": 1689795632000,
        "value": 139.714,
        "_internal_originalTime": 1689795632000
    },
    {
        "time": 1689795635000,
        "value": 139.717,
        "_internal_originalTime": 1689795635000
    },
    {
        "time": 1689795636000,
        "value": 139.714,
        "_internal_originalTime": 1689795636000
    },
    {
        "time": 1689795638000,
        "value": 139.714,
        "_internal_originalTime": 1689795638000
    },
    {
        "time": 1689795639000,
        "value": 139.712,
        "_internal_originalTime": 1689795639000
    },
    {
        "time": 1689795640000,
        "value": 139.716,
        "_internal_originalTime": 1689795640000
    },
    {
        "time": 1689795641000,
        "value": 139.718,
        "_internal_originalTime": 1689795641000
    },
    {
        "time": 1689795644000,
        "value": 139.714,
        "_internal_originalTime": 1689795644000
    },
    {
        "time": 1689795647000,
        "value": 139.717,
        "_internal_originalTime": 1689795647000
    },
    {
        "time": 1689795648000,
        "value": 139.717,
        "_internal_originalTime": 1689795648000
    },
    {
        "time": 1689795649000,
        "value": 139.713,
        "_internal_originalTime": 1689795649000
    },
    {
        "time": 1689795654000,
        "value": 139.714,
        "_internal_originalTime": 1689795654000
    },
    {
        "time": 1689795659000,
        "value": 139.716,
        "_internal_originalTime": 1689795659000
    },
    {
        "time": 1689795660000,
        "value": 139.717,
        "_internal_originalTime": 1689795660000
    },
    {
        "time": 1689795661000,
        "value": 139.712,
        "_internal_originalTime": 1689795661000
    },
    {
        "time": 1689795663000,
        "value": 139.718,
        "_internal_originalTime": 1689795663000
    },
    {
        "time": 1689795664000,
        "value": 139.719,
        "_internal_originalTime": 1689795664000
    },
    {
        "time": 1689795665000,
        "value": 139.718,
        "_internal_originalTime": 1689795665000
    },
    {
        "time": 1689795667000,
        "value": 139.717,
        "_internal_originalTime": 1689795667000
    },
    {
        "time": 1689795668000,
        "value": 139.715,
        "_internal_originalTime": 1689795668000
    },
    {
        "time": 1689795670000,
        "value": 139.719,
        "_internal_originalTime": 1689795670000
    },
    {
        "time": 1689795671000,
        "value": 139.719,
        "_internal_originalTime": 1689795671000
    },
    {
        "time": 1689795673000,
        "value": 139.714,
        "_internal_originalTime": 1689795673000
    },
    {
        "time": 1689795674000,
        "value": 139.714,
        "_internal_originalTime": 1689795674000
    },
    {
        "time": 1689795676000,
        "value": 139.717,
        "_internal_originalTime": 1689795676000
    },
    {
        "time": 1689795677000,
        "value": 139.712,
        "_internal_originalTime": 1689795677000
    },
    {
        "time": 1689795678000,
        "value": 139.716,
        "_internal_originalTime": 1689795678000
    },
    {
        "time": 1689795679000,
        "value": 139.711,
        "_internal_originalTime": 1689795679000
    },
    {
        "time": 1689795680000,
        "value": 139.712,
        "_internal_originalTime": 1689795680000
    },
    {
        "time": 1689795682000,
        "value": 139.715,
        "_internal_originalTime": 1689795682000
    },
    {
        "time": 1689795683000,
        "value": 139.712,
        "_internal_originalTime": 1689795683000
    },
    {
        "time": 1689795684000,
        "value": 139.717,
        "_internal_originalTime": 1689795684000
    },
    {
        "time": 1689795686000,
        "value": 139.719,
        "_internal_originalTime": 1689795686000
    },
    {
        "time": 1689795687000,
        "value": 139.719,
        "_internal_originalTime": 1689795687000
    },
    {
        "time": 1689795689000,
        "value": 139.719,
        "_internal_originalTime": 1689795689000
    },
    {
        "time": 1689795690000,
        "value": 139.719,
        "_internal_originalTime": 1689795690000
    },
    {
        "time": 1689795691000,
        "value": 139.719,
        "_internal_originalTime": 1689795691000
    },
    {
        "time": 1689795692000,
        "value": 139.714,
        "_internal_originalTime": 1689795692000
    },
    {
        "time": 1689795696000,
        "value": 139.718,
        "_internal_originalTime": 1689795696000
    },
    {
        "time": 1689795697000,
        "value": 139.716,
        "_internal_originalTime": 1689795697000
    },
    {
        "time": 1689795698000,
        "value": 139.711,
        "_internal_originalTime": 1689795698000
    },
    {
        "time": 1689795699000,
        "value": 139.712,
        "_internal_originalTime": 1689795699000
    },
    {
        "time": 1689795700000,
        "value": 139.713,
        "_internal_originalTime": 1689795700000
    },
    {
        "time": 1689795702000,
        "value": 139.71,
        "_internal_originalTime": 1689795702000
    },
    {
        "time": 1689795703000,
        "value": 139.708,
        "_internal_originalTime": 1689795703000
    },
    {
        "time": 1689795704000,
        "value": 139.706,
        "_internal_originalTime": 1689795704000
    },
    {
        "time": 1689795707000,
        "value": 139.711,
        "_internal_originalTime": 1689795707000
    },
    {
        "time": 1689795708000,
        "value": 139.71,
        "_internal_originalTime": 1689795708000
    },
    {
        "time": 1689795709000,
        "value": 139.71,
        "_internal_originalTime": 1689795709000
    },
    {
        "time": 1689795714000,
        "value": 139.711,
        "_internal_originalTime": 1689795714000
    },
    {
        "time": 1689795720000,
        "value": 139.71,
        "_internal_originalTime": 1689795720000
    },
    {
        "time": 1689795722000,
        "value": 139.709,
        "_internal_originalTime": 1689795722000
    },
    {
        "time": 1689795723000,
        "value": 139.709,
        "_internal_originalTime": 1689795723000
    },
    {
        "time": 1689795726000,
        "value": 139.71,
        "_internal_originalTime": 1689795726000
    },
    {
        "time": 1689795728000,
        "value": 139.71,
        "_internal_originalTime": 1689795728000
    },
    {
        "time": 1689795730000,
        "value": 139.71,
        "_internal_originalTime": 1689795730000
    },
    {
        "time": 1689795733000,
        "value": 139.711,
        "_internal_originalTime": 1689795733000
    },
    {
        "time": 1689795735000,
        "value": 139.711,
        "_internal_originalTime": 1689795735000
    },
    {
        "time": 1689795737000,
        "value": 139.709,
        "_internal_originalTime": 1689795737000
    },
    {
        "time": 1689795738000,
        "value": 139.71,
        "_internal_originalTime": 1689795738000
    },
    {
        "time": 1689795739000,
        "value": 139.705,
        "_internal_originalTime": 1689795739000
    },
    {
        "time": 1689795740000,
        "value": 139.711,
        "_internal_originalTime": 1689795740000
    },
    {
        "time": 1689795741000,
        "value": 139.71,
        "_internal_originalTime": 1689795741000
    },
    {
        "time": 1689795743000,
        "value": 139.711,
        "_internal_originalTime": 1689795743000
    },
    {
        "time": 1689795745000,
        "value": 139.709,
        "_internal_originalTime": 1689795745000
    },
    {
        "time": 1689795749000,
        "value": 139.706,
        "_internal_originalTime": 1689795749000
    },
    {
        "time": 1689795750000,
        "value": 139.71,
        "_internal_originalTime": 1689795750000
    },
    {
        "time": 1689795751000,
        "value": 139.708,
        "_internal_originalTime": 1689795751000
    },
    {
        "time": 1689795752000,
        "value": 139.719,
        "_internal_originalTime": 1689795752000
    },
    {
        "time": 1689795753000,
        "value": 139.717,
        "_internal_originalTime": 1689795753000
    },
    {
        "time": 1689795754000,
        "value": 139.713,
        "_internal_originalTime": 1689795754000
    },
    {
        "time": 1689795756000,
        "value": 139.714,
        "_internal_originalTime": 1689795756000
    },
    {
        "time": 1689795758000,
        "value": 139.718,
        "_internal_originalTime": 1689795758000
    },
    {
        "time": 1689795759000,
        "value": 139.717,
        "_internal_originalTime": 1689795759000
    },
    {
        "time": 1689795764000,
        "value": 139.714,
        "_internal_originalTime": 1689795764000
    },
    {
        "time": 1689795769000,
        "value": 139.712,
        "_internal_originalTime": 1689795769000
    },
    {
        "time": 1689795771000,
        "value": 139.719,
        "_internal_originalTime": 1689795771000
    },
    {
        "time": 1689795773000,
        "value": 139.718,
        "_internal_originalTime": 1689795773000
    },
    {
        "time": 1689795778000,
        "value": 139.717,
        "_internal_originalTime": 1689795778000
    },
    {
        "time": 1689795779000,
        "value": 139.72,
        "_internal_originalTime": 1689795779000
    },
    {
        "time": 1689795781000,
        "value": 139.72,
        "_internal_originalTime": 1689795781000
    },
    {
        "time": 1689795784000,
        "value": 139.719,
        "_internal_originalTime": 1689795784000
    },
    {
        "time": 1689795786000,
        "value": 139.719,
        "_internal_originalTime": 1689795786000
    },
    {
        "time": 1689795788000,
        "value": 139.717,
        "_internal_originalTime": 1689795788000
    },
    {
        "time": 1689795790000,
        "value": 139.717,
        "_internal_originalTime": 1689795790000
    },
    {
        "time": 1689795792000,
        "value": 139.715,
        "_internal_originalTime": 1689795792000
    },
    {
        "time": 1689795794000,
        "value": 139.717,
        "_internal_originalTime": 1689795794000
    },
    {
        "time": 1689795795000,
        "value": 139.716,
        "_internal_originalTime": 1689795795000
    },
    {
        "time": 1689795799000,
        "value": 139.712,
        "_internal_originalTime": 1689795799000
    },
    {
        "time": 1689795802000,
        "value": 139.715,
        "_internal_originalTime": 1689795802000
    },
    {
        "time": 1689795803000,
        "value": 139.714,
        "_internal_originalTime": 1689795803000
    },
    {
        "time": 1689795805000,
        "value": 139.718,
        "_internal_originalTime": 1689795805000
    },
    {
        "time": 1689795806000,
        "value": 139.719,
        "_internal_originalTime": 1689795806000
    },
    {
        "time": 1689795807000,
        "value": 139.718,
        "_internal_originalTime": 1689795807000
    },
    {
        "time": 1689795810000,
        "value": 139.714,
        "_internal_originalTime": 1689795810000
    },
    {
        "time": 1689795811000,
        "value": 139.715,
        "_internal_originalTime": 1689795811000
    },
    {
        "time": 1689795812000,
        "value": 139.711,
        "_internal_originalTime": 1689795812000
    },
    {
        "time": 1689795813000,
        "value": 139.711,
        "_internal_originalTime": 1689795813000
    },
    {
        "time": 1689795815000,
        "value": 139.711,
        "_internal_originalTime": 1689795815000
    },
    {
        "time": 1689795817000,
        "value": 139.711,
        "_internal_originalTime": 1689795817000
    },
    {
        "time": 1689795818000,
        "value": 139.706,
        "_internal_originalTime": 1689795818000
    },
    {
        "time": 1689795821000,
        "value": 139.713,
        "_internal_originalTime": 1689795821000
    },
    {
        "time": 1689795822000,
        "value": 139.709,
        "_internal_originalTime": 1689795822000
    },
    {
        "time": 1689795824000,
        "value": 139.717,
        "_internal_originalTime": 1689795824000
    },
    {
        "time": 1689795825000,
        "value": 139.712,
        "_internal_originalTime": 1689795825000
    },
    {
        "time": 1689795830000,
        "value": 139.712,
        "_internal_originalTime": 1689795830000
    },
    {
        "time": 1689795837000,
        "value": 139.718,
        "_internal_originalTime": 1689795837000
    },
    {
        "time": 1689795840000,
        "value": 139.712,
        "_internal_originalTime": 1689795840000
    },
    {
        "time": 1689795842000,
        "value": 139.717,
        "_internal_originalTime": 1689795842000
    },
    {
        "time": 1689795845000,
        "value": 139.712,
        "_internal_originalTime": 1689795845000
    },
    {
        "time": 1689795847000,
        "value": 139.716,
        "_internal_originalTime": 1689795847000
    },
    {
        "time": 1689795848000,
        "value": 139.718,
        "_internal_originalTime": 1689795848000
    },
    {
        "time": 1689795849000,
        "value": 139.713,
        "_internal_originalTime": 1689795849000
    },
    {
        "time": 1689795855000,
        "value": 139.72,
        "_internal_originalTime": 1689795855000
    },
    {
        "time": 1689795859000,
        "value": 139.716,
        "_internal_originalTime": 1689795859000
    },
    {
        "time": 1689795860000,
        "value": 139.72,
        "_internal_originalTime": 1689795860000
    },
    {
        "time": 1689795861000,
        "value": 139.719,
        "_internal_originalTime": 1689795861000
    },
    {
        "time": 1689795862000,
        "value": 139.717,
        "_internal_originalTime": 1689795862000
    },
    {
        "time": 1689795863000,
        "value": 139.717,
        "_internal_originalTime": 1689795863000
    },
    {
        "time": 1689795864000,
        "value": 139.719,
        "_internal_originalTime": 1689795864000
    },
    {
        "time": 1689795867000,
        "value": 139.72,
        "_internal_originalTime": 1689795867000
    },
    {
        "time": 1689795868000,
        "value": 139.716,
        "_internal_originalTime": 1689795868000
    },
    {
        "time": 1689795871000,
        "value": 139.714,
        "_internal_originalTime": 1689795871000
    },
    {
        "time": 1689795872000,
        "value": 139.716,
        "_internal_originalTime": 1689795872000
    },
    {
        "time": 1689795873000,
        "value": 139.714,
        "_internal_originalTime": 1689795873000
    },
    {
        "time": 1689795875000,
        "value": 139.716,
        "_internal_originalTime": 1689795875000
    },
    {
        "time": 1689795876000,
        "value": 139.717,
        "_internal_originalTime": 1689795876000
    },
    {
        "time": 1689795878000,
        "value": 139.717,
        "_internal_originalTime": 1689795878000
    },
    {
        "time": 1689795879000,
        "value": 139.714,
        "_internal_originalTime": 1689795879000
    },
    {
        "time": 1689795880000,
        "value": 139.714,
        "_internal_originalTime": 1689795880000
    },
    {
        "time": 1689795883000,
        "value": 139.714,
        "_internal_originalTime": 1689795883000
    },
    {
        "time": 1689795887000,
        "value": 139.714,
        "_internal_originalTime": 1689795887000
    },
    {
        "time": 1689795889000,
        "value": 139.714,
        "_internal_originalTime": 1689795889000
    },
    {
        "time": 1689795891000,
        "value": 139.719,
        "_internal_originalTime": 1689795891000
    },
    {
        "time": 1689795893000,
        "value": 139.718,
        "_internal_originalTime": 1689795893000
    },
    {
        "time": 1689795895000,
        "value": 139.719,
        "_internal_originalTime": 1689795895000
    },
    {
        "time": 1689795900000,
        "value": 139.714,
        "_internal_originalTime": 1689795900000
    },
    {
        "time": 1689795901000,
        "value": 139.717,
        "_internal_originalTime": 1689795901000
    },
    {
        "time": 1689795902000,
        "value": 139.717,
        "_internal_originalTime": 1689795902000
    },
    {
        "time": 1689795903000,
        "value": 139.714,
        "_internal_originalTime": 1689795903000
    },
    {
        "time": 1689795905000,
        "value": 139.717,
        "_internal_originalTime": 1689795905000
    },
    {
        "time": 1689795906000,
        "value": 139.717,
        "_internal_originalTime": 1689795906000
    },
    {
        "time": 1689795908000,
        "value": 139.715,
        "_internal_originalTime": 1689795908000
    },
    {
        "time": 1689795910000,
        "value": 139.714,
        "_internal_originalTime": 1689795910000
    },
    {
        "time": 1689795911000,
        "value": 139.715,
        "_internal_originalTime": 1689795911000
    },
    {
        "time": 1689795912000,
        "value": 139.709,
        "_internal_originalTime": 1689795912000
    },
    {
        "time": 1689795913000,
        "value": 139.709,
        "_internal_originalTime": 1689795913000
    },
    {
        "time": 1689795916000,
        "value": 139.708,
        "_internal_originalTime": 1689795916000
    },
    {
        "time": 1689795919000,
        "value": 139.703,
        "_internal_originalTime": 1689795919000
    },
    {
        "time": 1689795920000,
        "value": 139.709,
        "_internal_originalTime": 1689795920000
    },
    {
        "time": 1689795922000,
        "value": 139.706,
        "_internal_originalTime": 1689795922000
    },
    {
        "time": 1689795923000,
        "value": 139.706,
        "_internal_originalTime": 1689795923000
    },
    {
        "time": 1689795924000,
        "value": 139.708,
        "_internal_originalTime": 1689795924000
    },
    {
        "time": 1689795925000,
        "value": 139.704,
        "_internal_originalTime": 1689795925000
    },
    {
        "time": 1689795928000,
        "value": 139.705,
        "_internal_originalTime": 1689795928000
    },
    {
        "time": 1689795929000,
        "value": 139.702,
        "_internal_originalTime": 1689795929000
    },
    {
        "time": 1689795931000,
        "value": 139.706,
        "_internal_originalTime": 1689795931000
    },
    {
        "time": 1689795933000,
        "value": 139.702,
        "_internal_originalTime": 1689795933000
    },
    {
        "time": 1689795934000,
        "value": 139.703,
        "_internal_originalTime": 1689795934000
    },
    {
        "time": 1689795935000,
        "value": 139.7,
        "_internal_originalTime": 1689795935000
    },
    {
        "time": 1689795936000,
        "value": 139.706,
        "_internal_originalTime": 1689795936000
    },
    {
        "time": 1689795938000,
        "value": 139.703,
        "_internal_originalTime": 1689795938000
    },
    {
        "time": 1689795939000,
        "value": 139.702,
        "_internal_originalTime": 1689795939000
    },
    {
        "time": 1689795941000,
        "value": 139.701,
        "_internal_originalTime": 1689795941000
    },
    {
        "time": 1689795942000,
        "value": 139.707,
        "_internal_originalTime": 1689795942000
    },
    {
        "time": 1689795949000,
        "value": 139.702,
        "_internal_originalTime": 1689795949000
    },
    {
        "time": 1689795950000,
        "value": 139.709,
        "_internal_originalTime": 1689795950000
    },
    {
        "time": 1689795951000,
        "value": 139.712,
        "_internal_originalTime": 1689795951000
    },
    {
        "time": 1689795952000,
        "value": 139.708,
        "_internal_originalTime": 1689795952000
    },
    {
        "time": 1689795954000,
        "value": 139.71,
        "_internal_originalTime": 1689795954000
    },
    {
        "time": 1689795955000,
        "value": 139.71,
        "_internal_originalTime": 1689795955000
    },
    {
        "time": 1689795960000,
        "value": 139.705,
        "_internal_originalTime": 1689795960000
    },
    {
        "time": 1689795961000,
        "value": 139.705,
        "_internal_originalTime": 1689795961000
    },
    {
        "time": 1689795962000,
        "value": 139.706,
        "_internal_originalTime": 1689795962000
    },
    {
        "time": 1689795968000,
        "value": 139.707,
        "_internal_originalTime": 1689795968000
    },
    {
        "time": 1689795969000,
        "value": 139.706,
        "_internal_originalTime": 1689795969000
    },
    {
        "time": 1689795970000,
        "value": 139.706,
        "_internal_originalTime": 1689795970000
    },
    {
        "time": 1689795971000,
        "value": 139.707,
        "_internal_originalTime": 1689795971000
    },
    {
        "time": 1689795973000,
        "value": 139.711,
        "_internal_originalTime": 1689795973000
    },
    {
        "time": 1689795974000,
        "value": 139.705,
        "_internal_originalTime": 1689795974000
    },
    {
        "time": 1689795975000,
        "value": 139.705,
        "_internal_originalTime": 1689795975000
    },
    {
        "time": 1689795979000,
        "value": 139.705,
        "_internal_originalTime": 1689795979000
    },
    {
        "time": 1689795982000,
        "value": 139.708,
        "_internal_originalTime": 1689795982000
    },
    {
        "time": 1689795984000,
        "value": 139.704,
        "_internal_originalTime": 1689795984000
    },
    {
        "time": 1689795986000,
        "value": 139.707,
        "_internal_originalTime": 1689795986000
    },
    {
        "time": 1689795988000,
        "value": 139.703,
        "_internal_originalTime": 1689795988000
    },
    {
        "time": 1689795989000,
        "value": 139.702,
        "_internal_originalTime": 1689795989000
    },
    {
        "time": 1689795990000,
        "value": 139.706,
        "_internal_originalTime": 1689795990000
    },
    {
        "time": 1689795991000,
        "value": 139.702,
        "_internal_originalTime": 1689795991000
    },
    {
        "time": 1689795993000,
        "value": 139.702,
        "_internal_originalTime": 1689795993000
    },
    {
        "time": 1689795994000,
        "value": 139.699,
        "_internal_originalTime": 1689795994000
    },
    {
        "time": 1689795995000,
        "value": 139.698,
        "_internal_originalTime": 1689795995000
    },
    {
        "time": 1689795996000,
        "value": 139.699,
        "_internal_originalTime": 1689795996000
    },
    {
        "time": 1689795998000,
        "value": 139.699,
        "_internal_originalTime": 1689795998000
    },
    {
        "time": 1689795999000,
        "value": 139.702,
        "_internal_originalTime": 1689795999000
    },
    {
        "time": 1689796000000,
        "value": 139.698,
        "_internal_originalTime": 1689796000000
    },
    {
        "time": 1689796002000,
        "value": 139.695,
        "_internal_originalTime": 1689796002000
    },
    {
        "time": 1689796003000,
        "value": 139.695,
        "_internal_originalTime": 1689796003000
    },
    {
        "time": 1689796005000,
        "value": 139.698,
        "_internal_originalTime": 1689796005000
    },
    {
        "time": 1689796006000,
        "value": 139.701,
        "_internal_originalTime": 1689796006000
    },
    {
        "time": 1689796009000,
        "value": 139.695,
        "_internal_originalTime": 1689796009000
    },
    {
        "time": 1689796012000,
        "value": 139.697,
        "_internal_originalTime": 1689796012000
    },
    {
        "time": 1689796017000,
        "value": 139.699,
        "_internal_originalTime": 1689796017000
    },
    {
        "time": 1689796018000,
        "value": 139.695,
        "_internal_originalTime": 1689796018000
    },
    {
        "time": 1689796020000,
        "value": 139.694,
        "_internal_originalTime": 1689796020000
    },
    {
        "time": 1689796028000,
        "value": 139.697,
        "_internal_originalTime": 1689796028000
    },
    {
        "time": 1689796031000,
        "value": 139.7,
        "_internal_originalTime": 1689796031000
    },
    {
        "time": 1689796034000,
        "value": 139.695,
        "_internal_originalTime": 1689796034000
    },
    {
        "time": 1689796036000,
        "value": 139.695,
        "_internal_originalTime": 1689796036000
    },
    {
        "time": 1689796039000,
        "value": 139.695,
        "_internal_originalTime": 1689796039000
    },
    {
        "time": 1689796047000,
        "value": 139.695,
        "_internal_originalTime": 1689796047000
    },
    {
        "time": 1689796048000,
        "value": 139.699,
        "_internal_originalTime": 1689796048000
    },
    {
        "time": 1689796049000,
        "value": 139.694,
        "_internal_originalTime": 1689796049000
    },
    {
        "time": 1689796050000,
        "value": 139.699,
        "_internal_originalTime": 1689796050000
    },
    {
        "time": 1689796051000,
        "value": 139.695,
        "_internal_originalTime": 1689796051000
    },
    {
        "time": 1689796053000,
        "value": 139.694,
        "_internal_originalTime": 1689796053000
    },
    {
        "time": 1689796055000,
        "value": 139.699,
        "_internal_originalTime": 1689796055000
    },
    {
        "time": 1689796056000,
        "value": 139.699,
        "_internal_originalTime": 1689796056000
    },
    {
        "time": 1689796058000,
        "value": 139.7,
        "_internal_originalTime": 1689796058000
    },
    {
        "time": 1689796059000,
        "value": 139.701,
        "_internal_originalTime": 1689796059000
    },
    {
        "time": 1689796062000,
        "value": 139.699,
        "_internal_originalTime": 1689796062000
    },
    {
        "time": 1689796063000,
        "value": 139.702,
        "_internal_originalTime": 1689796063000
    },
    {
        "time": 1689796067000,
        "value": 139.698,
        "_internal_originalTime": 1689796067000
    },
    {
        "time": 1689796069000,
        "value": 139.705,
        "_internal_originalTime": 1689796069000
    },
    {
        "time": 1689796071000,
        "value": 139.701,
        "_internal_originalTime": 1689796071000
    },
    {
        "time": 1689796072000,
        "value": 139.702,
        "_internal_originalTime": 1689796072000
    },
    {
        "time": 1689796087000,
        "value": 139.707,
        "_internal_originalTime": 1689796087000
    },
    {
        "time": 1689796088000,
        "value": 139.705,
        "_internal_originalTime": 1689796088000
    },
    {
        "time": 1689796091000,
        "value": 139.704,
        "_internal_originalTime": 1689796091000
    },
    {
        "time": 1689796094000,
        "value": 139.707,
        "_internal_originalTime": 1689796094000
    },
    {
        "time": 1689796095000,
        "value": 139.707,
        "_internal_originalTime": 1689796095000
    },
    {
        "time": 1689796097000,
        "value": 139.707,
        "_internal_originalTime": 1689796097000
    },
    {
        "time": 1689796098000,
        "value": 139.704,
        "_internal_originalTime": 1689796098000
    },
    {
        "time": 1689796099000,
        "value": 139.702,
        "_internal_originalTime": 1689796099000
    },
    {
        "time": 1689796104000,
        "value": 139.706,
        "_internal_originalTime": 1689796104000
    },
    {
        "time": 1689796105000,
        "value": 139.706,
        "_internal_originalTime": 1689796105000
    },
    {
        "time": 1689796109000,
        "value": 139.702,
        "_internal_originalTime": 1689796109000
    },
    {
        "time": 1689796118000,
        "value": 139.705,
        "_internal_originalTime": 1689796118000
    },
    {
        "time": 1689796129000,
        "value": 139.701,
        "_internal_originalTime": 1689796129000
    },
    {
        "time": 1689796130000,
        "value": 139.701,
        "_internal_originalTime": 1689796130000
    },
    {
        "time": 1689796132000,
        "value": 139.708,
        "_internal_originalTime": 1689796132000
    },
    {
        "time": 1689796133000,
        "value": 139.707,
        "_internal_originalTime": 1689796133000
    },
    {
        "time": 1689796134000,
        "value": 139.707,
        "_internal_originalTime": 1689796134000
    },
    {
        "time": 1689796135000,
        "value": 139.708,
        "_internal_originalTime": 1689796135000
    },
    {
        "time": 1689796136000,
        "value": 139.713,
        "_internal_originalTime": 1689796136000
    },
    {
        "time": 1689796137000,
        "value": 139.714,
        "_internal_originalTime": 1689796137000
    },
    {
        "time": 1689796144000,
        "value": 139.709,
        "_internal_originalTime": 1689796144000
    },
    {
        "time": 1689796145000,
        "value": 139.709,
        "_internal_originalTime": 1689796145000
    },
    {
        "time": 1689796146000,
        "value": 139.706,
        "_internal_originalTime": 1689796146000
    },
    {
        "time": 1689796147000,
        "value": 139.707,
        "_internal_originalTime": 1689796147000
    },
    {
        "time": 1689796148000,
        "value": 139.707,
        "_internal_originalTime": 1689796148000
    },
    {
        "time": 1689796149000,
        "value": 139.707,
        "_internal_originalTime": 1689796149000
    },
    {
        "time": 1689796151000,
        "value": 139.707,
        "_internal_originalTime": 1689796151000
    },
    {
        "time": 1689796152000,
        "value": 139.707,
        "_internal_originalTime": 1689796152000
    },
    {
        "time": 1689796154000,
        "value": 139.71,
        "_internal_originalTime": 1689796154000
    },
    {
        "time": 1689796156000,
        "value": 139.71,
        "_internal_originalTime": 1689796156000
    },
    {
        "time": 1689796157000,
        "value": 139.706,
        "_internal_originalTime": 1689796157000
    },
    {
        "time": 1689796158000,
        "value": 139.707,
        "_internal_originalTime": 1689796158000
    },
    {
        "time": 1689796159000,
        "value": 139.704,
        "_internal_originalTime": 1689796159000
    },
    {
        "time": 1689796161000,
        "value": 139.705,
        "_internal_originalTime": 1689796161000
    },
    {
        "time": 1689796163000,
        "value": 139.707,
        "_internal_originalTime": 1689796163000
    },
    {
        "time": 1689796164000,
        "value": 139.706,
        "_internal_originalTime": 1689796164000
    },
    {
        "time": 1689796166000,
        "value": 139.706,
        "_internal_originalTime": 1689796166000
    },
    {
        "time": 1689796167000,
        "value": 139.706,
        "_internal_originalTime": 1689796167000
    },
    {
        "time": 1689796175000,
        "value": 139.707,
        "_internal_originalTime": 1689796175000
    },
    {
        "time": 1689796176000,
        "value": 139.708,
        "_internal_originalTime": 1689796176000
    },
    {
        "time": 1689796178000,
        "value": 139.71,
        "_internal_originalTime": 1689796178000
    },
    {
        "time": 1689796182000,
        "value": 139.711,
        "_internal_originalTime": 1689796182000
    },
    {
        "time": 1689796183000,
        "value": 139.711,
        "_internal_originalTime": 1689796183000
    },
    {
        "time": 1689796184000,
        "value": 139.711,
        "_internal_originalTime": 1689796184000
    },
    {
        "time": 1689796185000,
        "value": 139.707,
        "_internal_originalTime": 1689796185000
    },
    {
        "time": 1689796186000,
        "value": 139.711,
        "_internal_originalTime": 1689796186000
    },
    {
        "time": 1689796187000,
        "value": 139.707,
        "_internal_originalTime": 1689796187000
    },
    {
        "time": 1689796189000,
        "value": 139.705,
        "_internal_originalTime": 1689796189000
    },
    {
        "time": 1689796190000,
        "value": 139.711,
        "_internal_originalTime": 1689796190000
    },
    {
        "time": 1689796191000,
        "value": 139.711,
        "_internal_originalTime": 1689796191000
    },
    {
        "time": 1689796192000,
        "value": 139.706,
        "_internal_originalTime": 1689796192000
    },
    {
        "time": 1689796194000,
        "value": 139.711,
        "_internal_originalTime": 1689796194000
    },
    {
        "time": 1689796199000,
        "value": 139.708,
        "_internal_originalTime": 1689796199000
    },
    {
        "time": 1689796200000,
        "value": 139.711,
        "_internal_originalTime": 1689796200000
    },
    {
        "time": 1689796201000,
        "value": 139.706,
        "_internal_originalTime": 1689796201000
    },
    {
        "time": 1689796202000,
        "value": 139.707,
        "_internal_originalTime": 1689796202000
    },
    {
        "time": 1689796206000,
        "value": 139.704,
        "_internal_originalTime": 1689796206000
    },
    {
        "time": 1689796207000,
        "value": 139.707,
        "_internal_originalTime": 1689796207000
    },
    {
        "time": 1689796209000,
        "value": 139.707,
        "_internal_originalTime": 1689796209000
    },
    {
        "time": 1689796210000,
        "value": 139.704,
        "_internal_originalTime": 1689796210000
    },
    {
        "time": 1689796211000,
        "value": 139.704,
        "_internal_originalTime": 1689796211000
    },
    {
        "time": 1689796212000,
        "value": 139.708,
        "_internal_originalTime": 1689796212000
    },
    {
        "time": 1689796213000,
        "value": 139.707,
        "_internal_originalTime": 1689796213000
    },
    {
        "time": 1689796215000,
        "value": 139.705,
        "_internal_originalTime": 1689796215000
    },
    {
        "time": 1689796216000,
        "value": 139.702,
        "_internal_originalTime": 1689796216000
    },
    {
        "time": 1689796217000,
        "value": 139.705,
        "_internal_originalTime": 1689796217000
    },
    {
        "time": 1689796218000,
        "value": 139.707,
        "_internal_originalTime": 1689796218000
    },
    {
        "time": 1689796220000,
        "value": 139.707,
        "_internal_originalTime": 1689796220000
    },
    {
        "time": 1689796221000,
        "value": 139.705,
        "_internal_originalTime": 1689796221000
    },
    {
        "time": 1689796223000,
        "value": 139.702,
        "_internal_originalTime": 1689796223000
    },
    {
        "time": 1689796224000,
        "value": 139.704,
        "_internal_originalTime": 1689796224000
    },
    {
        "time": 1689796225000,
        "value": 139.701,
        "_internal_originalTime": 1689796225000
    },
    {
        "time": 1689796226000,
        "value": 139.704,
        "_internal_originalTime": 1689796226000
    },
    {
        "time": 1689796227000,
        "value": 139.705,
        "_internal_originalTime": 1689796227000
    },
    {
        "time": 1689796229000,
        "value": 139.703,
        "_internal_originalTime": 1689796229000
    },
    {
        "time": 1689796237000,
        "value": 139.706,
        "_internal_originalTime": 1689796237000
    },
    {
        "time": 1689796238000,
        "value": 139.705,
        "_internal_originalTime": 1689796238000
    },
    {
        "time": 1689796242000,
        "value": 139.702,
        "_internal_originalTime": 1689796242000
    },
    {
        "time": 1689796243000,
        "value": 139.704,
        "_internal_originalTime": 1689796243000
    },
    {
        "time": 1689796244000,
        "value": 139.7,
        "_internal_originalTime": 1689796244000
    },
    {
        "time": 1689796245000,
        "value": 139.7,
        "_internal_originalTime": 1689796245000
    },
    {
        "time": 1689796250000,
        "value": 139.704,
        "_internal_originalTime": 1689796250000
    },
    {
        "time": 1689796251000,
        "value": 139.709,
        "_internal_originalTime": 1689796251000
    },
    {
        "time": 1689796252000,
        "value": 139.704,
        "_internal_originalTime": 1689796252000
    },
    {
        "time": 1689796254000,
        "value": 139.704,
        "_internal_originalTime": 1689796254000
    },
    {
        "time": 1689796259000,
        "value": 139.705,
        "_internal_originalTime": 1689796259000
    },
    {
        "time": 1689796260000,
        "value": 139.71,
        "_internal_originalTime": 1689796260000
    },
    {
        "time": 1689796261000,
        "value": 139.708,
        "_internal_originalTime": 1689796261000
    },
    {
        "time": 1689796266000,
        "value": 139.708,
        "_internal_originalTime": 1689796266000
    },
    {
        "time": 1689796268000,
        "value": 139.706,
        "_internal_originalTime": 1689796268000
    },
    {
        "time": 1689796271000,
        "value": 139.704,
        "_internal_originalTime": 1689796271000
    },
    {
        "time": 1689796272000,
        "value": 139.709,
        "_internal_originalTime": 1689796272000
    },
    {
        "time": 1689796274000,
        "value": 139.704,
        "_internal_originalTime": 1689796274000
    },
    {
        "time": 1689796323000,
        "value": 139.687,
        "_internal_originalTime": 1689796323000
    },
    {
        "time": 1689796325000,
        "value": 139.686,
        "_internal_originalTime": 1689796325000
    },
    {
        "time": 1689796327000,
        "value": 139.684,
        "_internal_originalTime": 1689796327000
    },
    {
        "time": 1689796328000,
        "value": 139.683,
        "_internal_originalTime": 1689796328000
    },
    {
        "time": 1689796329000,
        "value": 139.684,
        "_internal_originalTime": 1689796329000
    },
    {
        "time": 1689796334000,
        "value": 139.681,
        "_internal_originalTime": 1689796334000
    },
    {
        "time": 1689796336000,
        "value": 139.681,
        "_internal_originalTime": 1689796336000
    },
    {
        "time": 1689796337000,
        "value": 139.68,
        "_internal_originalTime": 1689796337000
    },
    {
        "time": 1689796339000,
        "value": 139.674,
        "_internal_originalTime": 1689796339000
    },
    {
        "time": 1689796343000,
        "value": 139.683,
        "_internal_originalTime": 1689796343000
    },
    {
        "time": 1689796346000,
        "value": 139.679,
        "_internal_originalTime": 1689796346000
    },
    {
        "time": 1689796349000,
        "value": 139.682,
        "_internal_originalTime": 1689796349000
    },
    {
        "time": 1689796351000,
        "value": 139.683,
        "_internal_originalTime": 1689796351000
    },
    {
        "time": 1689796352000,
        "value": 139.679,
        "_internal_originalTime": 1689796352000
    },
    {
        "time": 1689796354000,
        "value": 139.681,
        "_internal_originalTime": 1689796354000
    },
    {
        "time": 1689796355000,
        "value": 139.687,
        "_internal_originalTime": 1689796355000
    },
    {
        "time": 1689796358000,
        "value": 139.684,
        "_internal_originalTime": 1689796358000
    },
    {
        "time": 1689796359000,
        "value": 139.683,
        "_internal_originalTime": 1689796359000
    },
    {
        "time": 1689796360000,
        "value": 139.687,
        "_internal_originalTime": 1689796360000
    },
    {
        "time": 1689796362000,
        "value": 139.687,
        "_internal_originalTime": 1689796362000
    },
    {
        "time": 1689796363000,
        "value": 139.689,
        "_internal_originalTime": 1689796363000
    },
    {
        "time": 1689796365000,
        "value": 139.686,
        "_internal_originalTime": 1689796365000
    },
    {
        "time": 1689796366000,
        "value": 139.689,
        "_internal_originalTime": 1689796366000
    },
    {
        "time": 1689796369000,
        "value": 139.684,
        "_internal_originalTime": 1689796369000
    },
    {
        "time": 1689796372000,
        "value": 139.691,
        "_internal_originalTime": 1689796372000
    },
    {
        "time": 1689796374000,
        "value": 139.69,
        "_internal_originalTime": 1689796374000
    },
    {
        "time": 1689796375000,
        "value": 139.69,
        "_internal_originalTime": 1689796375000
    },
    {
        "time": 1689796376000,
        "value": 139.686,
        "_internal_originalTime": 1689796376000
    },
    {
        "time": 1689796377000,
        "value": 139.69,
        "_internal_originalTime": 1689796377000
    },
    {
        "time": 1689796379000,
        "value": 139.687,
        "_internal_originalTime": 1689796379000
    },
    {
        "time": 1689796380000,
        "value": 139.691,
        "_internal_originalTime": 1689796380000
    },
    {
        "time": 1689796381000,
        "value": 139.693,
        "_internal_originalTime": 1689796381000
    },
    {
        "time": 1689796382000,
        "value": 139.694,
        "_internal_originalTime": 1689796382000
    },
    {
        "time": 1689796383000,
        "value": 139.695,
        "_internal_originalTime": 1689796383000
    },
    {
        "time": 1689796385000,
        "value": 139.693,
        "_internal_originalTime": 1689796385000
    },
    {
        "time": 1689796386000,
        "value": 139.693,
        "_internal_originalTime": 1689796386000
    },
    {
        "time": 1689796388000,
        "value": 139.694,
        "_internal_originalTime": 1689796388000
    },
    {
        "time": 1689796391000,
        "value": 139.695,
        "_internal_originalTime": 1689796391000
    },
    {
        "time": 1689796393000,
        "value": 139.693,
        "_internal_originalTime": 1689796393000
    },
    {
        "time": 1689796394000,
        "value": 139.693,
        "_internal_originalTime": 1689796394000
    },
    {
        "time": 1689796395000,
        "value": 139.69,
        "_internal_originalTime": 1689796395000
    },
    {
        "time": 1689796396000,
        "value": 139.691,
        "_internal_originalTime": 1689796396000
    },
    {
        "time": 1689796397000,
        "value": 139.69,
        "_internal_originalTime": 1689796397000
    },
    {
        "time": 1689796399000,
        "value": 139.687,
        "_internal_originalTime": 1689796399000
    },
    {
        "time": 1689796400000,
        "value": 139.687,
        "_internal_originalTime": 1689796400000
    },
    {
        "time": 1689796401000,
        "value": 139.687,
        "_internal_originalTime": 1689796401000
    },
    {
        "time": 1689796404000,
        "value": 139.69,
        "_internal_originalTime": 1689796404000
    },
    {
        "time": 1689796408000,
        "value": 139.687,
        "_internal_originalTime": 1689796408000
    },
    {
        "time": 1689796409000,
        "value": 139.687,
        "_internal_originalTime": 1689796409000
    },
    {
        "time": 1689796411000,
        "value": 139.685,
        "_internal_originalTime": 1689796411000
    },
    {
        "time": 1689796418000,
        "value": 139.689,
        "_internal_originalTime": 1689796418000
    },
    {
        "time": 1689796421000,
        "value": 139.688,
        "_internal_originalTime": 1689796421000
    },
    {
        "time": 1689796422000,
        "value": 139.689,
        "_internal_originalTime": 1689796422000
    },
    {
        "time": 1689796423000,
        "value": 139.687,
        "_internal_originalTime": 1689796423000
    },
    {
        "time": 1689796424000,
        "value": 139.69,
        "_internal_originalTime": 1689796424000
    },
    {
        "time": 1689796425000,
        "value": 139.689,
        "_internal_originalTime": 1689796425000
    },
    {
        "time": 1689796428000,
        "value": 139.687,
        "_internal_originalTime": 1689796428000
    },
    {
        "time": 1689796429000,
        "value": 139.687,
        "_internal_originalTime": 1689796429000
    },
    {
        "time": 1689796485000,
        "value": 139.692,
        "_internal_originalTime": 1689796485000
    },
    {
        "time": 1689796487000,
        "value": 139.689,
        "_internal_originalTime": 1689796487000
    },
    {
        "time": 1689796488000,
        "value": 139.69,
        "_internal_originalTime": 1689796488000
    },
    {
        "time": 1689796490000,
        "value": 139.686,
        "_internal_originalTime": 1689796490000
    },
    {
        "time": 1689796491000,
        "value": 139.688,
        "_internal_originalTime": 1689796491000
    },
    {
        "time": 1689796492000,
        "value": 139.689,
        "_internal_originalTime": 1689796492000
    },
    {
        "time": 1689796493000,
        "value": 139.687,
        "_internal_originalTime": 1689796493000
    },
    {
        "time": 1689796494000,
        "value": 139.687,
        "_internal_originalTime": 1689796494000
    },
    {
        "time": 1689796495000,
        "value": 139.689,
        "_internal_originalTime": 1689796495000
    },
    {
        "time": 1689796497000,
        "value": 139.687,
        "_internal_originalTime": 1689796497000
    },
    {
        "time": 1689796499000,
        "value": 139.688,
        "_internal_originalTime": 1689796499000
    },
    {
        "time": 1689796500000,
        "value": 139.689,
        "_internal_originalTime": 1689796500000
    },
    {
        "time": 1689796505000,
        "value": 139.688,
        "_internal_originalTime": 1689796505000
    },
    {
        "time": 1689796506000,
        "value": 139.686,
        "_internal_originalTime": 1689796506000
    },
    {
        "time": 1689796507000,
        "value": 139.687,
        "_internal_originalTime": 1689796507000
    },
    {
        "time": 1689796508000,
        "value": 139.687,
        "_internal_originalTime": 1689796508000
    },
    {
        "time": 1689796509000,
        "value": 139.688,
        "_internal_originalTime": 1689796509000
    },
    {
        "time": 1689796511000,
        "value": 139.688,
        "_internal_originalTime": 1689796511000
    },
    {
        "time": 1689796512000,
        "value": 139.69,
        "_internal_originalTime": 1689796512000
    },
    {
        "time": 1689796513000,
        "value": 139.69,
        "_internal_originalTime": 1689796513000
    },
    {
        "time": 1689796514000,
        "value": 139.688,
        "_internal_originalTime": 1689796514000
    },
    {
        "time": 1689796516000,
        "value": 139.687,
        "_internal_originalTime": 1689796516000
    },
    {
        "time": 1689796517000,
        "value": 139.685,
        "_internal_originalTime": 1689796517000
    },
    {
        "time": 1689796518000,
        "value": 139.69,
        "_internal_originalTime": 1689796518000
    },
    {
        "time": 1689796520000,
        "value": 139.685,
        "_internal_originalTime": 1689796520000
    },
    {
        "time": 1689796523000,
        "value": 139.688,
        "_internal_originalTime": 1689796523000
    },
    {
        "time": 1689796525000,
        "value": 139.691,
        "_internal_originalTime": 1689796525000
    },
    {
        "time": 1689796527000,
        "value": 139.692,
        "_internal_originalTime": 1689796527000
    },
    {
        "time": 1689796528000,
        "value": 139.691,
        "_internal_originalTime": 1689796528000
    },
    {
        "time": 1689796530000,
        "value": 139.693,
        "_internal_originalTime": 1689796530000
    },
    {
        "time": 1689796531000,
        "value": 139.69,
        "_internal_originalTime": 1689796531000
    },
    {
        "time": 1689796533000,
        "value": 139.692,
        "_internal_originalTime": 1689796533000
    },
    {
        "time": 1689796534000,
        "value": 139.694,
        "_internal_originalTime": 1689796534000
    },
    {
        "time": 1689796535000,
        "value": 139.69,
        "_internal_originalTime": 1689796535000
    },
    {
        "time": 1689796536000,
        "value": 139.692,
        "_internal_originalTime": 1689796536000
    },
    {
        "time": 1689796538000,
        "value": 139.694,
        "_internal_originalTime": 1689796538000
    },
    {
        "time": 1689796539000,
        "value": 139.693,
        "_internal_originalTime": 1689796539000
    },
    {
        "time": 1689796540000,
        "value": 139.69,
        "_internal_originalTime": 1689796540000
    },
    {
        "time": 1689796541000,
        "value": 139.694,
        "_internal_originalTime": 1689796541000
    },
    {
        "time": 1689796542000,
        "value": 139.691,
        "_internal_originalTime": 1689796542000
    },
    {
        "time": 1689796543000,
        "value": 139.688,
        "_internal_originalTime": 1689796543000
    },
    {
        "time": 1689796544000,
        "value": 139.689,
        "_internal_originalTime": 1689796544000
    },
    {
        "time": 1689796545000,
        "value": 139.691,
        "_internal_originalTime": 1689796545000
    },
    {
        "time": 1689796546000,
        "value": 139.69,
        "_internal_originalTime": 1689796546000
    },
    {
        "time": 1689796547000,
        "value": 139.687,
        "_internal_originalTime": 1689796547000
    },
    {
        "time": 1689796548000,
        "value": 139.688,
        "_internal_originalTime": 1689796548000
    },
    {
        "time": 1689796549000,
        "value": 139.685,
        "_internal_originalTime": 1689796549000
    },
    {
        "time": 1689796550000,
        "value": 139.683,
        "_internal_originalTime": 1689796550000
    },
    {
        "time": 1689796551000,
        "value": 139.688,
        "_internal_originalTime": 1689796551000
    },
    {
        "time": 1689796553000,
        "value": 139.684,
        "_internal_originalTime": 1689796553000
    },
    {
        "time": 1689796554000,
        "value": 139.687,
        "_internal_originalTime": 1689796554000
    },
    {
        "time": 1689796555000,
        "value": 139.685,
        "_internal_originalTime": 1689796555000
    },
    {
        "time": 1689796556000,
        "value": 139.685,
        "_internal_originalTime": 1689796556000
    },
    {
        "time": 1689796558000,
        "value": 139.686,
        "_internal_originalTime": 1689796558000
    },
    {
        "time": 1689796559000,
        "value": 139.683,
        "_internal_originalTime": 1689796559000
    },
    {
        "time": 1689796560000,
        "value": 139.67500305,
        "_internal_originalTime": 1689796560000
    },
    {
        "time": 1689796664000,
        "value": 139.68,
        "_internal_originalTime": 1689796664000
    },
    {
        "time": 1689796667000,
        "value": 139.679,
        "_internal_originalTime": 1689796667000
    },
    {
        "time": 1689796668000,
        "value": 139.676,
        "_internal_originalTime": 1689796668000
    },
    {
        "time": 1689796669000,
        "value": 139.68,
        "_internal_originalTime": 1689796669000
    },
    {
        "time": 1689796671000,
        "value": 139.681,
        "_internal_originalTime": 1689796671000
    },
    {
        "time": 1689796673000,
        "value": 139.681,
        "_internal_originalTime": 1689796673000
    },
    {
        "time": 1689796674000,
        "value": 139.677,
        "_internal_originalTime": 1689796674000
    },
    {
        "time": 1689796679000,
        "value": 139.676,
        "_internal_originalTime": 1689796679000
    },
    {
        "time": 1689796680000,
        "value": 139.68,
        "_internal_originalTime": 1689796680000
    },
    {
        "time": 1689796681000,
        "value": 139.685,
        "_internal_originalTime": 1689796681000
    },
    {
        "time": 1689796682000,
        "value": 139.686,
        "_internal_originalTime": 1689796682000
    },
    {
        "time": 1689796683000,
        "value": 139.682,
        "_internal_originalTime": 1689796683000
    },
    {
        "time": 1689796685000,
        "value": 139.686,
        "_internal_originalTime": 1689796685000
    },
    {
        "time": 1689796687000,
        "value": 139.698,
        "_internal_originalTime": 1689796687000
    },
    {
        "time": 1689796688000,
        "value": 139.697,
        "_internal_originalTime": 1689796688000
    },
    {
        "time": 1689796689000,
        "value": 139.695,
        "_internal_originalTime": 1689796689000
    },
    {
        "time": 1689796690000,
        "value": 139.695,
        "_internal_originalTime": 1689796690000
    },
    {
        "time": 1689796691000,
        "value": 139.696,
        "_internal_originalTime": 1689796691000
    },
    {
        "time": 1689796693000,
        "value": 139.693,
        "_internal_originalTime": 1689796693000
    },
    {
        "time": 1689796694000,
        "value": 139.691,
        "_internal_originalTime": 1689796694000
    },
    {
        "time": 1689796695000,
        "value": 139.692,
        "_internal_originalTime": 1689796695000
    },
    {
        "time": 1689796696000,
        "value": 139.692,
        "_internal_originalTime": 1689796696000
    },
    {
        "time": 1689796697000,
        "value": 139.691,
        "_internal_originalTime": 1689796697000
    },
    {
        "time": 1689796701000,
        "value": 139.691,
        "_internal_originalTime": 1689796701000
    },
    {
        "time": 1689796703000,
        "value": 139.691,
        "_internal_originalTime": 1689796703000
    },
    {
        "time": 1689796705000,
        "value": 139.686,
        "_internal_originalTime": 1689796705000
    },
    {
        "time": 1689796706000,
        "value": 139.687,
        "_internal_originalTime": 1689796706000
    },
    {
        "time": 1689796708000,
        "value": 139.686,
        "_internal_originalTime": 1689796708000
    },
    {
        "time": 1689796709000,
        "value": 139.684,
        "_internal_originalTime": 1689796709000
    },
    {
        "time": 1689796711000,
        "value": 139.69,
        "_internal_originalTime": 1689796711000
    },
    {
        "time": 1689796713000,
        "value": 139.691,
        "_internal_originalTime": 1689796713000
    },
    {
        "time": 1689796715000,
        "value": 139.689,
        "_internal_originalTime": 1689796715000
    },
    {
        "time": 1689796717000,
        "value": 139.694,
        "_internal_originalTime": 1689796717000
    },
    {
        "time": 1689796718000,
        "value": 139.688,
        "_internal_originalTime": 1689796718000
    },
    {
        "time": 1689796719000,
        "value": 139.693,
        "_internal_originalTime": 1689796719000
    },
    {
        "time": 1689796720000,
        "value": 139.689,
        "_internal_originalTime": 1689796720000
    },
    {
        "time": 1689796740000,
        "value": 139.70500183,
        "_internal_originalTime": 1689796740000
    },
    {
        "time": 1689796853000,
        "value": 139.685,
        "_internal_originalTime": 1689796853000
    },
    {
        "time": 1689796854000,
        "value": 139.688,
        "_internal_originalTime": 1689796854000
    },
    {
        "time": 1689796855000,
        "value": 139.682,
        "_internal_originalTime": 1689796855000
    },
    {
        "time": 1689796857000,
        "value": 139.684,
        "_internal_originalTime": 1689796857000
    },
    {
        "time": 1689796858000,
        "value": 139.68,
        "_internal_originalTime": 1689796858000
    },
    {
        "time": 1689796860000,
        "value": 139.683,
        "_internal_originalTime": 1689796860000
    },
    {
        "time": 1689796861000,
        "value": 139.681,
        "_internal_originalTime": 1689796861000
    },
    {
        "time": 1689796862000,
        "value": 139.683,
        "_internal_originalTime": 1689796862000
    },
    {
        "time": 1689796864000,
        "value": 139.682,
        "_internal_originalTime": 1689796864000
    },
    {
        "time": 1689796865000,
        "value": 139.682,
        "_internal_originalTime": 1689796865000
    },
    {
        "time": 1689796866000,
        "value": 139.686,
        "_internal_originalTime": 1689796866000
    },
    {
        "time": 1689796868000,
        "value": 139.685,
        "_internal_originalTime": 1689796868000
    },
    {
        "time": 1689796869000,
        "value": 139.689,
        "_internal_originalTime": 1689796869000
    },
    {
        "time": 1689796870000,
        "value": 139.688,
        "_internal_originalTime": 1689796870000
    },
    {
        "time": 1689796872000,
        "value": 139.688,
        "_internal_originalTime": 1689796872000
    },
    {
        "time": 1689796873000,
        "value": 139.687,
        "_internal_originalTime": 1689796873000
    },
    {
        "time": 1689796874000,
        "value": 139.689,
        "_internal_originalTime": 1689796874000
    },
    {
        "time": 1689796876000,
        "value": 139.686,
        "_internal_originalTime": 1689796876000
    },
    {
        "time": 1689796877000,
        "value": 139.68,
        "_internal_originalTime": 1689796877000
    },
    {
        "time": 1689796880000,
        "value": 139.679,
        "_internal_originalTime": 1689796880000
    },
    {
        "time": 1689796882000,
        "value": 139.682,
        "_internal_originalTime": 1689796882000
    },
    {
        "time": 1689796883000,
        "value": 139.688,
        "_internal_originalTime": 1689796883000
    },
    {
        "time": 1689796885000,
        "value": 139.688,
        "_internal_originalTime": 1689796885000
    },
    {
        "time": 1689796887000,
        "value": 139.682,
        "_internal_originalTime": 1689796887000
    },
    {
        "time": 1689796888000,
        "value": 139.68,
        "_internal_originalTime": 1689796888000
    },
    {
        "time": 1689796889000,
        "value": 139.682,
        "_internal_originalTime": 1689796889000
    },
    {
        "time": 1689796890000,
        "value": 139.68,
        "_internal_originalTime": 1689796890000
    },
    {
        "time": 1689796894000,
        "value": 139.691,
        "_internal_originalTime": 1689796894000
    },
    {
        "time": 1689796895000,
        "value": 139.687,
        "_internal_originalTime": 1689796895000
    },
    {
        "time": 1689796896000,
        "value": 139.688,
        "_internal_originalTime": 1689796896000
    },
    {
        "time": 1689796898000,
        "value": 139.686,
        "_internal_originalTime": 1689796898000
    },
    {
        "time": 1689796901000,
        "value": 139.686,
        "_internal_originalTime": 1689796901000
    },
    {
        "time": 1689796902000,
        "value": 139.686,
        "_internal_originalTime": 1689796902000
    },
    {
        "time": 1689796903000,
        "value": 139.687,
        "_internal_originalTime": 1689796903000
    },
    {
        "time": 1689796905000,
        "value": 139.691,
        "_internal_originalTime": 1689796905000
    },
    {
        "time": 1689796906000,
        "value": 139.686,
        "_internal_originalTime": 1689796906000
    },
    {
        "time": 1689796907000,
        "value": 139.69,
        "_internal_originalTime": 1689796907000
    },
    {
        "time": 1689796909000,
        "value": 139.687,
        "_internal_originalTime": 1689796909000
    },
    {
        "time": 1689796910000,
        "value": 139.687,
        "_internal_originalTime": 1689796910000
    },
    {
        "time": 1689796911000,
        "value": 139.687,
        "_internal_originalTime": 1689796911000
    },
    {
        "time": 1689796913000,
        "value": 139.688,
        "_internal_originalTime": 1689796913000
    },
    {
        "time": 1689796914000,
        "value": 139.685,
        "_internal_originalTime": 1689796914000
    },
    {
        "time": 1689796915000,
        "value": 139.688,
        "_internal_originalTime": 1689796915000
    },
    {
        "time": 1689796916000,
        "value": 139.688,
        "_internal_originalTime": 1689796916000
    },
    {
        "time": 1689796918000,
        "value": 139.684,
        "_internal_originalTime": 1689796918000
    },
    {
        "time": 1689796919000,
        "value": 139.685,
        "_internal_originalTime": 1689796919000
    },
    {
        "time": 1689796921000,
        "value": 139.688,
        "_internal_originalTime": 1689796921000
    },
    {
        "time": 1689796922000,
        "value": 139.688,
        "_internal_originalTime": 1689796922000
    },
    {
        "time": 1689796925000,
        "value": 139.688,
        "_internal_originalTime": 1689796925000
    },
    {
        "time": 1689796927000,
        "value": 139.685,
        "_internal_originalTime": 1689796927000
    },
    {
        "time": 1689796928000,
        "value": 139.692,
        "_internal_originalTime": 1689796928000
    },
    {
        "time": 1689796929000,
        "value": 139.688,
        "_internal_originalTime": 1689796929000
    },
    {
        "time": 1689796931000,
        "value": 139.693,
        "_internal_originalTime": 1689796931000
    },
    {
        "time": 1689796932000,
        "value": 139.697,
        "_internal_originalTime": 1689796932000
    },
    {
        "time": 1689796934000,
        "value": 139.692,
        "_internal_originalTime": 1689796934000
    },
    {
        "time": 1689796935000,
        "value": 139.698,
        "_internal_originalTime": 1689796935000
    },
    {
        "time": 1689796937000,
        "value": 139.696,
        "_internal_originalTime": 1689796937000
    },
    {
        "time": 1689796940000,
        "value": 139.698,
        "_internal_originalTime": 1689796940000
    },
    {
        "time": 1689796942000,
        "value": 139.696,
        "_internal_originalTime": 1689796942000
    },
    {
        "time": 1689796943000,
        "value": 139.689,
        "_internal_originalTime": 1689796943000
    },
    {
        "time": 1689796944000,
        "value": 139.692,
        "_internal_originalTime": 1689796944000
    },
    {
        "time": 1689796945000,
        "value": 139.687,
        "_internal_originalTime": 1689796945000
    },
    {
        "time": 1689796946000,
        "value": 139.689,
        "_internal_originalTime": 1689796946000
    },
    {
        "time": 1689796948000,
        "value": 139.689,
        "_internal_originalTime": 1689796948000
    },
    {
        "time": 1689796950000,
        "value": 139.687,
        "_internal_originalTime": 1689796950000
    },
    {
        "time": 1689796951000,
        "value": 139.688,
        "_internal_originalTime": 1689796951000
    },
    {
        "time": 1689796954000,
        "value": 139.692,
        "_internal_originalTime": 1689796954000
    },
    {
        "time": 1689796955000,
        "value": 139.686,
        "_internal_originalTime": 1689796955000
    },
    {
        "time": 1689796957000,
        "value": 139.685,
        "_internal_originalTime": 1689796957000
    },
    {
        "time": 1689796958000,
        "value": 139.689,
        "_internal_originalTime": 1689796958000
    },
    {
        "time": 1689796959000,
        "value": 139.686,
        "_internal_originalTime": 1689796959000
    },
    {
        "time": 1689796962000,
        "value": 139.688,
        "_internal_originalTime": 1689796962000
    },
    {
        "time": 1689796965000,
        "value": 139.686,
        "_internal_originalTime": 1689796965000
    },
    {
        "time": 1689796968000,
        "value": 139.689,
        "_internal_originalTime": 1689796968000
    },
    {
        "time": 1689796969000,
        "value": 139.687,
        "_internal_originalTime": 1689796969000
    },
    {
        "time": 1689796971000,
        "value": 139.687,
        "_internal_originalTime": 1689796971000
    },
    {
        "time": 1689796972000,
        "value": 139.686,
        "_internal_originalTime": 1689796972000
    },
    {
        "time": 1689796973000,
        "value": 139.69,
        "_internal_originalTime": 1689796973000
    },
    {
        "time": 1689796975000,
        "value": 139.688,
        "_internal_originalTime": 1689796975000
    },
    {
        "time": 1689796977000,
        "value": 139.689,
        "_internal_originalTime": 1689796977000
    },
    {
        "time": 1689796978000,
        "value": 139.69,
        "_internal_originalTime": 1689796978000
    },
    {
        "time": 1689796979000,
        "value": 139.688,
        "_internal_originalTime": 1689796979000
    },
    {
        "time": 1689796981000,
        "value": 139.691,
        "_internal_originalTime": 1689796981000
    },
    {
        "time": 1689796982000,
        "value": 139.69,
        "_internal_originalTime": 1689796982000
    },
    {
        "time": 1689796984000,
        "value": 139.691,
        "_internal_originalTime": 1689796984000
    },
    {
        "time": 1689796985000,
        "value": 139.69,
        "_internal_originalTime": 1689796985000
    },
    {
        "time": 1689796986000,
        "value": 139.692,
        "_internal_originalTime": 1689796986000
    },
    {
        "time": 1689796988000,
        "value": 139.69,
        "_internal_originalTime": 1689796988000
    },
    {
        "time": 1689796998000,
        "value": 139.69,
        "_internal_originalTime": 1689796998000
    },
    {
        "time": 1689797000000,
        "value": 139.686,
        "_internal_originalTime": 1689797000000
    },
    {
        "time": 1689797005000,
        "value": 139.69,
        "_internal_originalTime": 1689797005000
    },
    {
        "time": 1689797006000,
        "value": 139.69,
        "_internal_originalTime": 1689797006000
    },
    {
        "time": 1689797008000,
        "value": 139.69,
        "_internal_originalTime": 1689797008000
    },
    {
        "time": 1689797009000,
        "value": 139.69,
        "_internal_originalTime": 1689797009000
    },
    {
        "time": 1689797011000,
        "value": 139.69,
        "_internal_originalTime": 1689797011000
    },
    {
        "time": 1689797013000,
        "value": 139.69,
        "_internal_originalTime": 1689797013000
    },
    {
        "time": 1689797014000,
        "value": 139.69,
        "_internal_originalTime": 1689797014000
    },
    {
        "time": 1689797017000,
        "value": 139.695,
        "_internal_originalTime": 1689797017000
    },
    {
        "time": 1689797018000,
        "value": 139.695,
        "_internal_originalTime": 1689797018000
    },
    {
        "time": 1689797019000,
        "value": 139.693,
        "_internal_originalTime": 1689797019000
    },
    {
        "time": 1689797021000,
        "value": 139.694,
        "_internal_originalTime": 1689797021000
    },
    {
        "time": 1689797026000,
        "value": 139.698,
        "_internal_originalTime": 1689797026000
    },
    {
        "time": 1689797027000,
        "value": 139.695,
        "_internal_originalTime": 1689797027000
    },
    {
        "time": 1689797029000,
        "value": 139.698,
        "_internal_originalTime": 1689797029000
    },
    {
        "time": 1689797030000,
        "value": 139.692,
        "_internal_originalTime": 1689797030000
    },
    {
        "time": 1689797031000,
        "value": 139.695,
        "_internal_originalTime": 1689797031000
    },
    {
        "time": 1689797033000,
        "value": 139.697,
        "_internal_originalTime": 1689797033000
    },
    {
        "time": 1689797034000,
        "value": 139.698,
        "_internal_originalTime": 1689797034000
    },
    {
        "time": 1689797036000,
        "value": 139.695,
        "_internal_originalTime": 1689797036000
    },
    {
        "time": 1689797037000,
        "value": 139.693,
        "_internal_originalTime": 1689797037000
    },
    {
        "time": 1689797039000,
        "value": 139.697,
        "_internal_originalTime": 1689797039000
    },
    {
        "time": 1689797040000,
        "value": 139.698,
        "_internal_originalTime": 1689797040000
    },
    {
        "time": 1689797043000,
        "value": 139.695,
        "_internal_originalTime": 1689797043000
    },
    {
        "time": 1689797044000,
        "value": 139.698,
        "_internal_originalTime": 1689797044000
    },
    {
        "time": 1689797048000,
        "value": 139.696,
        "_internal_originalTime": 1689797048000
    },
    {
        "time": 1689797060000,
        "value": 139.692,
        "_internal_originalTime": 1689797060000
    },
    {
        "time": 1689797069000,
        "value": 139.698,
        "_internal_originalTime": 1689797069000
    },
    {
        "time": 1689797077000,
        "value": 139.687,
        "_internal_originalTime": 1689797077000
    },
    {
        "time": 1689797078000,
        "value": 139.688,
        "_internal_originalTime": 1689797078000
    },
    {
        "time": 1689797079000,
        "value": 139.686,
        "_internal_originalTime": 1689797079000
    },
    {
        "time": 1689797081000,
        "value": 139.691,
        "_internal_originalTime": 1689797081000
    },
    {
        "time": 1689797084000,
        "value": 139.69,
        "_internal_originalTime": 1689797084000
    },
    {
        "time": 1689797085000,
        "value": 139.693,
        "_internal_originalTime": 1689797085000
    },
    {
        "time": 1689797086000,
        "value": 139.691,
        "_internal_originalTime": 1689797086000
    },
    {
        "time": 1689797087000,
        "value": 139.69,
        "_internal_originalTime": 1689797087000
    },
    {
        "time": 1689797090000,
        "value": 139.687,
        "_internal_originalTime": 1689797090000
    },
    {
        "time": 1689797098000,
        "value": 139.69,
        "_internal_originalTime": 1689797098000
    },
    {
        "time": 1689797099000,
        "value": 139.693,
        "_internal_originalTime": 1689797099000
    },
    {
        "time": 1689797101000,
        "value": 139.688,
        "_internal_originalTime": 1689797101000
    },
    {
        "time": 1689797105000,
        "value": 139.689,
        "_internal_originalTime": 1689797105000
    },
    {
        "time": 1689797106000,
        "value": 139.688,
        "_internal_originalTime": 1689797106000
    },
    {
        "time": 1689797108000,
        "value": 139.689,
        "_internal_originalTime": 1689797108000
    },
    {
        "time": 1689797112000,
        "value": 139.688,
        "_internal_originalTime": 1689797112000
    },
    {
        "time": 1689797114000,
        "value": 139.688,
        "_internal_originalTime": 1689797114000
    },
    {
        "time": 1689797116000,
        "value": 139.692,
        "_internal_originalTime": 1689797116000
    },
    {
        "time": 1689797119000,
        "value": 139.689,
        "_internal_originalTime": 1689797119000
    },
    {
        "time": 1689797120000,
        "value": 139.686,
        "_internal_originalTime": 1689797120000
    },
    {
        "time": 1689797123000,
        "value": 139.689,
        "_internal_originalTime": 1689797123000
    },
    {
        "time": 1689797131000,
        "value": 139.692,
        "_internal_originalTime": 1689797131000
    },
    {
        "time": 1689797164000,
        "value": 139.691,
        "_internal_originalTime": 1689797164000
    },
    {
        "time": 1689797165000,
        "value": 139.691,
        "_internal_originalTime": 1689797165000
    },
    {
        "time": 1689797166000,
        "value": 139.688,
        "_internal_originalTime": 1689797166000
    },
    {
        "time": 1689797168000,
        "value": 139.69,
        "_internal_originalTime": 1689797168000
    },
    {
        "time": 1689797170000,
        "value": 139.691,
        "_internal_originalTime": 1689797170000
    },
    {
        "time": 1689797172000,
        "value": 139.693,
        "_internal_originalTime": 1689797172000
    },
    {
        "time": 1689797173000,
        "value": 139.692,
        "_internal_originalTime": 1689797173000
    },
    {
        "time": 1689797176000,
        "value": 139.692,
        "_internal_originalTime": 1689797176000
    },
    {
        "time": 1689797180000,
        "value": 139.685,
        "_internal_originalTime": 1689797180000
    },
    {
        "time": 1689797181000,
        "value": 139.688,
        "_internal_originalTime": 1689797181000
    },
    {
        "time": 1689797186000,
        "value": 139.689,
        "_internal_originalTime": 1689797186000
    },
    {
        "time": 1689797188000,
        "value": 139.688,
        "_internal_originalTime": 1689797188000
    },
    {
        "time": 1689797190000,
        "value": 139.692,
        "_internal_originalTime": 1689797190000
    },
    {
        "time": 1689797192000,
        "value": 139.687,
        "_internal_originalTime": 1689797192000
    },
    {
        "time": 1689797194000,
        "value": 139.688,
        "_internal_originalTime": 1689797194000
    },
    {
        "time": 1689797195000,
        "value": 139.691,
        "_internal_originalTime": 1689797195000
    },
    {
        "time": 1689797196000,
        "value": 139.693,
        "_internal_originalTime": 1689797196000
    },
    {
        "time": 1689797198000,
        "value": 139.689,
        "_internal_originalTime": 1689797198000
    },
    {
        "time": 1689797204000,
        "value": 139.688,
        "_internal_originalTime": 1689797204000
    },
    {
        "time": 1689797205000,
        "value": 139.692,
        "_internal_originalTime": 1689797205000
    },
    {
        "time": 1689797208000,
        "value": 139.687,
        "_internal_originalTime": 1689797208000
    },
    {
        "time": 1689797210000,
        "value": 139.69,
        "_internal_originalTime": 1689797210000
    },
    {
        "time": 1689797215000,
        "value": 139.687,
        "_internal_originalTime": 1689797215000
    },
    {
        "time": 1689797216000,
        "value": 139.687,
        "_internal_originalTime": 1689797216000
    },
    {
        "time": 1689797223000,
        "value": 139.686,
        "_internal_originalTime": 1689797223000
    },
    {
        "time": 1689797224000,
        "value": 139.687,
        "_internal_originalTime": 1689797224000
    },
    {
        "time": 1689797226000,
        "value": 139.686,
        "_internal_originalTime": 1689797226000
    },
    {
        "time": 1689797228000,
        "value": 139.687,
        "_internal_originalTime": 1689797228000
    },
    {
        "time": 1689797232000,
        "value": 139.686,
        "_internal_originalTime": 1689797232000
    },
    {
        "time": 1689797233000,
        "value": 139.685,
        "_internal_originalTime": 1689797233000
    },
    {
        "time": 1689797240000,
        "value": 139.682,
        "_internal_originalTime": 1689797240000
    },
    {
        "time": 1689797254000,
        "value": 139.683,
        "_internal_originalTime": 1689797254000
    },
    {
        "time": 1689797256000,
        "value": 139.683,
        "_internal_originalTime": 1689797256000
    },
    {
        "time": 1689797258000,
        "value": 139.686,
        "_internal_originalTime": 1689797258000
    },
    {
        "time": 1689797265000,
        "value": 139.687,
        "_internal_originalTime": 1689797265000
    },
    {
        "time": 1689797266000,
        "value": 139.688,
        "_internal_originalTime": 1689797266000
    },
    {
        "time": 1689797270000,
        "value": 139.693,
        "_internal_originalTime": 1689797270000
    },
    {
        "time": 1689797271000,
        "value": 139.692,
        "_internal_originalTime": 1689797271000
    },
    {
        "time": 1689797272000,
        "value": 139.693,
        "_internal_originalTime": 1689797272000
    },
    {
        "time": 1689797274000,
        "value": 139.692,
        "_internal_originalTime": 1689797274000
    },
    {
        "time": 1689797275000,
        "value": 139.698,
        "_internal_originalTime": 1689797275000
    },
    {
        "time": 1689797278000,
        "value": 139.699,
        "_internal_originalTime": 1689797278000
    },
    {
        "time": 1689797284000,
        "value": 139.701,
        "_internal_originalTime": 1689797284000
    },
    {
        "time": 1689797286000,
        "value": 139.696,
        "_internal_originalTime": 1689797286000
    },
    {
        "time": 1689797287000,
        "value": 139.7,
        "_internal_originalTime": 1689797287000
    },
    {
        "time": 1689797288000,
        "value": 139.7,
        "_internal_originalTime": 1689797288000
    },
    {
        "time": 1689797290000,
        "value": 139.7,
        "_internal_originalTime": 1689797290000
    },
    {
        "time": 1689797291000,
        "value": 139.7,
        "_internal_originalTime": 1689797291000
    },
    {
        "time": 1689797292000,
        "value": 139.7,
        "_internal_originalTime": 1689797292000
    },
    {
        "time": 1689797294000,
        "value": 139.701,
        "_internal_originalTime": 1689797294000
    },
    {
        "time": 1689797296000,
        "value": 139.691,
        "_internal_originalTime": 1689797296000
    },
    {
        "time": 1689797297000,
        "value": 139.692,
        "_internal_originalTime": 1689797297000
    },
    {
        "time": 1689797298000,
        "value": 139.697,
        "_internal_originalTime": 1689797298000
    },
    {
        "time": 1689797300000,
        "value": 139.694,
        "_internal_originalTime": 1689797300000
    },
    {
        "time": 1689797305000,
        "value": 139.699,
        "_internal_originalTime": 1689797305000
    },
    {
        "time": 1689797306000,
        "value": 139.703,
        "_internal_originalTime": 1689797306000
    },
    {
        "time": 1689797307000,
        "value": 139.703,
        "_internal_originalTime": 1689797307000
    },
    {
        "time": 1689797316000,
        "value": 139.7,
        "_internal_originalTime": 1689797316000
    },
    {
        "time": 1689797318000,
        "value": 139.704,
        "_internal_originalTime": 1689797318000
    },
    {
        "time": 1689797319000,
        "value": 139.701,
        "_internal_originalTime": 1689797319000
    },
    {
        "time": 1689797324000,
        "value": 139.7,
        "_internal_originalTime": 1689797324000
    },
    {
        "time": 1689797326000,
        "value": 139.702,
        "_internal_originalTime": 1689797326000
    },
    {
        "time": 1689797330000,
        "value": 139.697,
        "_internal_originalTime": 1689797330000
    },
    {
        "time": 1689797331000,
        "value": 139.7,
        "_internal_originalTime": 1689797331000
    },
    {
        "time": 1689797332000,
        "value": 139.7,
        "_internal_originalTime": 1689797332000
    },
    {
        "time": 1689797333000,
        "value": 139.7,
        "_internal_originalTime": 1689797333000
    },
    {
        "time": 1689797336000,
        "value": 139.7,
        "_internal_originalTime": 1689797336000
    },
    {
        "time": 1689797337000,
        "value": 139.7,
        "_internal_originalTime": 1689797337000
    },
    {
        "time": 1689797346000,
        "value": 139.708,
        "_internal_originalTime": 1689797346000
    },
    {
        "time": 1689797347000,
        "value": 139.705,
        "_internal_originalTime": 1689797347000
    },
    {
        "time": 1689797349000,
        "value": 139.708,
        "_internal_originalTime": 1689797349000
    },
    {
        "time": 1689797350000,
        "value": 139.711,
        "_internal_originalTime": 1689797350000
    },
    {
        "time": 1689797351000,
        "value": 139.713,
        "_internal_originalTime": 1689797351000
    },
    {
        "time": 1689797352000,
        "value": 139.714,
        "_internal_originalTime": 1689797352000
    },
    {
        "time": 1689797353000,
        "value": 139.713,
        "_internal_originalTime": 1689797353000
    },
    {
        "time": 1689797356000,
        "value": 139.71,
        "_internal_originalTime": 1689797356000
    },
    {
        "time": 1689797357000,
        "value": 139.71,
        "_internal_originalTime": 1689797357000
    },
    {
        "time": 1689797359000,
        "value": 139.713,
        "_internal_originalTime": 1689797359000
    },
    {
        "time": 1689797360000,
        "value": 139.71,
        "_internal_originalTime": 1689797360000
    },
    {
        "time": 1689797361000,
        "value": 139.71,
        "_internal_originalTime": 1689797361000
    },
    {
        "time": 1689797365000,
        "value": 139.71,
        "_internal_originalTime": 1689797365000
    },
    {
        "time": 1689797366000,
        "value": 139.71,
        "_internal_originalTime": 1689797366000
    },
    {
        "time": 1689797367000,
        "value": 139.71,
        "_internal_originalTime": 1689797367000
    },
    {
        "time": 1689797370000,
        "value": 139.711,
        "_internal_originalTime": 1689797370000
    },
    {
        "time": 1689797371000,
        "value": 139.713,
        "_internal_originalTime": 1689797371000
    },
    {
        "time": 1689797373000,
        "value": 139.711,
        "_internal_originalTime": 1689797373000
    },
    {
        "time": 1689797374000,
        "value": 139.713,
        "_internal_originalTime": 1689797374000
    },
    {
        "time": 1689797376000,
        "value": 139.71,
        "_internal_originalTime": 1689797376000
    },
    {
        "time": 1689797377000,
        "value": 139.709,
        "_internal_originalTime": 1689797377000
    },
    {
        "time": 1689797378000,
        "value": 139.712,
        "_internal_originalTime": 1689797378000
    },
    {
        "time": 1689797379000,
        "value": 139.715,
        "_internal_originalTime": 1689797379000
    },
    {
        "time": 1689797382000,
        "value": 139.715,
        "_internal_originalTime": 1689797382000
    },
    {
        "time": 1689797383000,
        "value": 139.711,
        "_internal_originalTime": 1689797383000
    },
    {
        "time": 1689797385000,
        "value": 139.712,
        "_internal_originalTime": 1689797385000
    },
    {
        "time": 1689797387000,
        "value": 139.714,
        "_internal_originalTime": 1689797387000
    },
    {
        "time": 1689797390000,
        "value": 139.714,
        "_internal_originalTime": 1689797390000
    },
    {
        "time": 1689797392000,
        "value": 139.714,
        "_internal_originalTime": 1689797392000
    },
    {
        "time": 1689797393000,
        "value": 139.714,
        "_internal_originalTime": 1689797393000
    },
    {
        "time": 1689797394000,
        "value": 139.714,
        "_internal_originalTime": 1689797394000
    },
    {
        "time": 1689797397000,
        "value": 139.715,
        "_internal_originalTime": 1689797397000
    },
    {
        "time": 1689797399000,
        "value": 139.714,
        "_internal_originalTime": 1689797399000
    },
    {
        "time": 1689797401000,
        "value": 139.711,
        "_internal_originalTime": 1689797401000
    },
    {
        "time": 1689797402000,
        "value": 139.712,
        "_internal_originalTime": 1689797402000
    },
    {
        "time": 1689797403000,
        "value": 139.711,
        "_internal_originalTime": 1689797403000
    },
    {
        "time": 1689797405000,
        "value": 139.71,
        "_internal_originalTime": 1689797405000
    },
    {
        "time": 1689797407000,
        "value": 139.71,
        "_internal_originalTime": 1689797407000
    },
    {
        "time": 1689797408000,
        "value": 139.704,
        "_internal_originalTime": 1689797408000
    },
    {
        "time": 1689797409000,
        "value": 139.701,
        "_internal_originalTime": 1689797409000
    },
    {
        "time": 1689797412000,
        "value": 139.707,
        "_internal_originalTime": 1689797412000
    },
    {
        "time": 1689797413000,
        "value": 139.705,
        "_internal_originalTime": 1689797413000
    },
    {
        "time": 1689797414000,
        "value": 139.707,
        "_internal_originalTime": 1689797414000
    },
    {
        "time": 1689797415000,
        "value": 139.707,
        "_internal_originalTime": 1689797415000
    },
    {
        "time": 1689797416000,
        "value": 139.704,
        "_internal_originalTime": 1689797416000
    },
    {
        "time": 1689797418000,
        "value": 139.708,
        "_internal_originalTime": 1689797418000
    },
    {
        "time": 1689797419000,
        "value": 139.709,
        "_internal_originalTime": 1689797419000
    },
    {
        "time": 1689797421000,
        "value": 139.71,
        "_internal_originalTime": 1689797421000
    },
    {
        "time": 1689797423000,
        "value": 139.706,
        "_internal_originalTime": 1689797423000
    },
    {
        "time": 1689797427000,
        "value": 139.706,
        "_internal_originalTime": 1689797427000
    },
    {
        "time": 1689797429000,
        "value": 139.708,
        "_internal_originalTime": 1689797429000
    },
    {
        "time": 1689797431000,
        "value": 139.71,
        "_internal_originalTime": 1689797431000
    },
    {
        "time": 1689797438000,
        "value": 139.709,
        "_internal_originalTime": 1689797438000
    },
    {
        "time": 1689797440000,
        "value": 139.705,
        "_internal_originalTime": 1689797440000
    },
    {
        "time": 1689797447000,
        "value": 139.705,
        "_internal_originalTime": 1689797447000
    },
    {
        "time": 1689797450000,
        "value": 139.703,
        "_internal_originalTime": 1689797450000
    },
    {
        "time": 1689797452000,
        "value": 139.711,
        "_internal_originalTime": 1689797452000
    },
    {
        "time": 1689797453000,
        "value": 139.708,
        "_internal_originalTime": 1689797453000
    },
    {
        "time": 1689797457000,
        "value": 139.708,
        "_internal_originalTime": 1689797457000
    },
    {
        "time": 1689797458000,
        "value": 139.708,
        "_internal_originalTime": 1689797458000
    },
    {
        "time": 1689797459000,
        "value": 139.707,
        "_internal_originalTime": 1689797459000
    },
    {
        "time": 1689797461000,
        "value": 139.71,
        "_internal_originalTime": 1689797461000
    },
    {
        "time": 1689797462000,
        "value": 139.707,
        "_internal_originalTime": 1689797462000
    },
    {
        "time": 1689797463000,
        "value": 139.707,
        "_internal_originalTime": 1689797463000
    },
    {
        "time": 1689797464000,
        "value": 139.707,
        "_internal_originalTime": 1689797464000
    },
    {
        "time": 1689797466000,
        "value": 139.708,
        "_internal_originalTime": 1689797466000
    },
    {
        "time": 1689797468000,
        "value": 139.71,
        "_internal_originalTime": 1689797468000
    },
    {
        "time": 1689797469000,
        "value": 139.708,
        "_internal_originalTime": 1689797469000
    },
    {
        "time": 1689797471000,
        "value": 139.708,
        "_internal_originalTime": 1689797471000
    },
    {
        "time": 1689797474000,
        "value": 139.708,
        "_internal_originalTime": 1689797474000
    },
    {
        "time": 1689797475000,
        "value": 139.71,
        "_internal_originalTime": 1689797475000
    },
    {
        "time": 1689797477000,
        "value": 139.71,
        "_internal_originalTime": 1689797477000
    },
    {
        "time": 1689797478000,
        "value": 139.71,
        "_internal_originalTime": 1689797478000
    },
    {
        "time": 1689797480000,
        "value": 139.704,
        "_internal_originalTime": 1689797480000
    },
    {
        "time": 1689797486000,
        "value": 139.706,
        "_internal_originalTime": 1689797486000
    },
    {
        "time": 1689797498000,
        "value": 139.709,
        "_internal_originalTime": 1689797498000
    },
    {
        "time": 1689797502000,
        "value": 139.705,
        "_internal_originalTime": 1689797502000
    },
    {
        "time": 1689797506000,
        "value": 139.705,
        "_internal_originalTime": 1689797506000
    },
    {
        "time": 1689797510000,
        "value": 139.705,
        "_internal_originalTime": 1689797510000
    },
    {
        "time": 1689797512000,
        "value": 139.706,
        "_internal_originalTime": 1689797512000
    },
    {
        "time": 1689797525000,
        "value": 139.709,
        "_internal_originalTime": 1689797525000
    },
    {
        "time": 1689797526000,
        "value": 139.704,
        "_internal_originalTime": 1689797526000
    },
    {
        "time": 1689797528000,
        "value": 139.708,
        "_internal_originalTime": 1689797528000
    },
    {
        "time": 1689797533000,
        "value": 139.704,
        "_internal_originalTime": 1689797533000
    },
    {
        "time": 1689797540000,
        "value": 139.703,
        "_internal_originalTime": 1689797540000
    },
    {
        "time": 1689797545000,
        "value": 139.711,
        "_internal_originalTime": 1689797545000
    },
    {
        "time": 1689797546000,
        "value": 139.71,
        "_internal_originalTime": 1689797546000
    },
    {
        "time": 1689797548000,
        "value": 139.71,
        "_internal_originalTime": 1689797548000
    },
    {
        "time": 1689797553000,
        "value": 139.709,
        "_internal_originalTime": 1689797553000
    },
    {
        "time": 1689797555000,
        "value": 139.705,
        "_internal_originalTime": 1689797555000
    },
    {
        "time": 1689797558000,
        "value": 139.707,
        "_internal_originalTime": 1689797558000
    },
    {
        "time": 1689797561000,
        "value": 139.708,
        "_internal_originalTime": 1689797561000
    },
    {
        "time": 1689797563000,
        "value": 139.704,
        "_internal_originalTime": 1689797563000
    },
    {
        "time": 1689797564000,
        "value": 139.703,
        "_internal_originalTime": 1689797564000
    },
    {
        "time": 1689797565000,
        "value": 139.705,
        "_internal_originalTime": 1689797565000
    },
    {
        "time": 1689797567000,
        "value": 139.704,
        "_internal_originalTime": 1689797567000
    },
    {
        "time": 1689797569000,
        "value": 139.705,
        "_internal_originalTime": 1689797569000
    },
    {
        "time": 1689797570000,
        "value": 139.701,
        "_internal_originalTime": 1689797570000
    },
    {
        "time": 1689797580000,
        "value": 139.704,
        "_internal_originalTime": 1689797580000
    },
    {
        "time": 1689797581000,
        "value": 139.705,
        "_internal_originalTime": 1689797581000
    },
    {
        "time": 1689797582000,
        "value": 139.706,
        "_internal_originalTime": 1689797582000
    },
    {
        "time": 1689797583000,
        "value": 139.706,
        "_internal_originalTime": 1689797583000
    },
    {
        "time": 1689797585000,
        "value": 139.706,
        "_internal_originalTime": 1689797585000
    },
    {
        "time": 1689797588000,
        "value": 139.706,
        "_internal_originalTime": 1689797588000
    },
    {
        "time": 1689797595000,
        "value": 139.704,
        "_internal_originalTime": 1689797595000
    },
    {
        "time": 1689797600000,
        "value": 139.703,
        "_internal_originalTime": 1689797600000
    },
    {
        "time": 1689797601000,
        "value": 139.704,
        "_internal_originalTime": 1689797601000
    },
    {
        "time": 1689797604000,
        "value": 139.706,
        "_internal_originalTime": 1689797604000
    },
    {
        "time": 1689797606000,
        "value": 139.706,
        "_internal_originalTime": 1689797606000
    },
    {
        "time": 1689797607000,
        "value": 139.706,
        "_internal_originalTime": 1689797607000
    },
    {
        "time": 1689797608000,
        "value": 139.706,
        "_internal_originalTime": 1689797608000
    },
    {
        "time": 1689797609000,
        "value": 139.706,
        "_internal_originalTime": 1689797609000
    },
    {
        "time": 1689797611000,
        "value": 139.706,
        "_internal_originalTime": 1689797611000
    },
    {
        "time": 1689797612000,
        "value": 139.706,
        "_internal_originalTime": 1689797612000
    },
    {
        "time": 1689797614000,
        "value": 139.71,
        "_internal_originalTime": 1689797614000
    },
    {
        "time": 1689797615000,
        "value": 139.706,
        "_internal_originalTime": 1689797615000
    },
    {
        "time": 1689797617000,
        "value": 139.706,
        "_internal_originalTime": 1689797617000
    },
    {
        "time": 1689797618000,
        "value": 139.709,
        "_internal_originalTime": 1689797618000
    },
    {
        "time": 1689797622000,
        "value": 139.709,
        "_internal_originalTime": 1689797622000
    },
    {
        "time": 1689797626000,
        "value": 139.704,
        "_internal_originalTime": 1689797626000
    },
    {
        "time": 1689797630000,
        "value": 139.703,
        "_internal_originalTime": 1689797630000
    },
    {
        "time": 1689797642000,
        "value": 139.71,
        "_internal_originalTime": 1689797642000
    },
    {
        "time": 1689797643000,
        "value": 139.706,
        "_internal_originalTime": 1689797643000
    },
    {
        "time": 1689797648000,
        "value": 139.707,
        "_internal_originalTime": 1689797648000
    },
    {
        "time": 1689797650000,
        "value": 139.705,
        "_internal_originalTime": 1689797650000
    },
    {
        "time": 1689797651000,
        "value": 139.71,
        "_internal_originalTime": 1689797651000
    },
    {
        "time": 1689797653000,
        "value": 139.711,
        "_internal_originalTime": 1689797653000
    },
    {
        "time": 1689797656000,
        "value": 139.713,
        "_internal_originalTime": 1689797656000
    },
    {
        "time": 1689797657000,
        "value": 139.713,
        "_internal_originalTime": 1689797657000
    },
    {
        "time": 1689797660000,
        "value": 139.708,
        "_internal_originalTime": 1689797660000
    },
    {
        "time": 1689797665000,
        "value": 139.71,
        "_internal_originalTime": 1689797665000
    },
    {
        "time": 1689797666000,
        "value": 139.71,
        "_internal_originalTime": 1689797666000
    },
    {
        "time": 1689797674000,
        "value": 139.713,
        "_internal_originalTime": 1689797674000
    },
    {
        "time": 1689797676000,
        "value": 139.71,
        "_internal_originalTime": 1689797676000
    },
    {
        "time": 1689797678000,
        "value": 139.708,
        "_internal_originalTime": 1689797678000
    },
    {
        "time": 1689797679000,
        "value": 139.712,
        "_internal_originalTime": 1689797679000
    },
    {
        "time": 1689797683000,
        "value": 139.71,
        "_internal_originalTime": 1689797683000
    },
    {
        "time": 1689797685000,
        "value": 139.713,
        "_internal_originalTime": 1689797685000
    },
    {
        "time": 1689797688000,
        "value": 139.709,
        "_internal_originalTime": 1689797688000
    },
    {
        "time": 1689797690000,
        "value": 139.707,
        "_internal_originalTime": 1689797690000
    },
    {
        "time": 1689797697000,
        "value": 139.708,
        "_internal_originalTime": 1689797697000
    },
    {
        "time": 1689797708000,
        "value": 139.709,
        "_internal_originalTime": 1689797708000
    },
    {
        "time": 1689797713000,
        "value": 139.712,
        "_internal_originalTime": 1689797713000
    },
    {
        "time": 1689797719000,
        "value": 139.707,
        "_internal_originalTime": 1689797719000
    },
    {
        "time": 1689797720000,
        "value": 139.705,
        "_internal_originalTime": 1689797720000
    },
    {
        "time": 1689797722000,
        "value": 139.708,
        "_internal_originalTime": 1689797722000
    },
    {
        "time": 1689797739000,
        "value": 139.707,
        "_internal_originalTime": 1689797739000
    },
    {
        "time": 1689797740000,
        "value": 139.706,
        "_internal_originalTime": 1689797740000
    },
    {
        "time": 1689797742000,
        "value": 139.704,
        "_internal_originalTime": 1689797742000
    },
    {
        "time": 1689797748000,
        "value": 139.705,
        "_internal_originalTime": 1689797748000
    },
    {
        "time": 1689797750000,
        "value": 139.702,
        "_internal_originalTime": 1689797750000
    },
    {
        "time": 1689797751000,
        "value": 139.704,
        "_internal_originalTime": 1689797751000
    },
    {
        "time": 1689797753000,
        "value": 139.706,
        "_internal_originalTime": 1689797753000
    },
    {
        "time": 1689797754000,
        "value": 139.706,
        "_internal_originalTime": 1689797754000
    },
    {
        "time": 1689797759000,
        "value": 139.706,
        "_internal_originalTime": 1689797759000
    },
    {
        "time": 1689797763000,
        "value": 139.709,
        "_internal_originalTime": 1689797763000
    },
    {
        "time": 1689797765000,
        "value": 139.708,
        "_internal_originalTime": 1689797765000
    },
    {
        "time": 1689797766000,
        "value": 139.704,
        "_internal_originalTime": 1689797766000
    },
    {
        "time": 1689797768000,
        "value": 139.706,
        "_internal_originalTime": 1689797768000
    },
    {
        "time": 1689797779000,
        "value": 139.707,
        "_internal_originalTime": 1689797779000
    },
    {
        "time": 1689797780000,
        "value": 139.712,
        "_internal_originalTime": 1689797780000
    },
    {
        "time": 1689797781000,
        "value": 139.713,
        "_internal_originalTime": 1689797781000
    },
    {
        "time": 1689797784000,
        "value": 139.717,
        "_internal_originalTime": 1689797784000
    },
    {
        "time": 1689797798000,
        "value": 139.717,
        "_internal_originalTime": 1689797798000
    },
    {
        "time": 1689797810000,
        "value": 139.712,
        "_internal_originalTime": 1689797810000
    },
    {
        "time": 1689797812000,
        "value": 139.712,
        "_internal_originalTime": 1689797812000
    },
    {
        "time": 1689797815000,
        "value": 139.714,
        "_internal_originalTime": 1689797815000
    },
    {
        "time": 1689797817000,
        "value": 139.714,
        "_internal_originalTime": 1689797817000
    },
    {
        "time": 1689797819000,
        "value": 139.714,
        "_internal_originalTime": 1689797819000
    },
    {
        "time": 1689797820000,
        "value": 139.717,
        "_internal_originalTime": 1689797820000
    },
    {
        "time": 1689797822000,
        "value": 139.718,
        "_internal_originalTime": 1689797822000
    },
    {
        "time": 1689797826000,
        "value": 139.718,
        "_internal_originalTime": 1689797826000
    },
    {
        "time": 1689797828000,
        "value": 139.717,
        "_internal_originalTime": 1689797828000
    },
    {
        "time": 1689797829000,
        "value": 139.717,
        "_internal_originalTime": 1689797829000
    },
    {
        "time": 1689797837000,
        "value": 139.718,
        "_internal_originalTime": 1689797837000
    },
    {
        "time": 1689797840000,
        "value": 139.713,
        "_internal_originalTime": 1689797840000
    },
    {
        "time": 1689797844000,
        "value": 139.719,
        "_internal_originalTime": 1689797844000
    },
    {
        "time": 1689797848000,
        "value": 139.718,
        "_internal_originalTime": 1689797848000
    },
    {
        "time": 1689797851000,
        "value": 139.717,
        "_internal_originalTime": 1689797851000
    },
    {
        "time": 1689797852000,
        "value": 139.714,
        "_internal_originalTime": 1689797852000
    },
    {
        "time": 1689797856000,
        "value": 139.717,
        "_internal_originalTime": 1689797856000
    },
    {
        "time": 1689797858000,
        "value": 139.717,
        "_internal_originalTime": 1689797858000
    },
    {
        "time": 1689797860000,
        "value": 139.714,
        "_internal_originalTime": 1689797860000
    },
    {
        "time": 1689797861000,
        "value": 139.714,
        "_internal_originalTime": 1689797861000
    },
    {
        "time": 1689797870000,
        "value": 139.714,
        "_internal_originalTime": 1689797870000
    },
    {
        "time": 1689797874000,
        "value": 139.714,
        "_internal_originalTime": 1689797874000
    },
    {
        "time": 1689797878000,
        "value": 139.718,
        "_internal_originalTime": 1689797878000
    },
    {
        "time": 1689797882000,
        "value": 139.714,
        "_internal_originalTime": 1689797882000
    },
    {
        "time": 1689797888000,
        "value": 139.717,
        "_internal_originalTime": 1689797888000
    },
    {
        "time": 1689797889000,
        "value": 139.717,
        "_internal_originalTime": 1689797889000
    },
    {
        "time": 1689797898000,
        "value": 139.717,
        "_internal_originalTime": 1689797898000
    },
    {
        "time": 1689797900000,
        "value": 139.711,
        "_internal_originalTime": 1689797900000
    },
    {
        "time": 1689797905000,
        "value": 139.713,
        "_internal_originalTime": 1689797905000
    },
    {
        "time": 1689797909000,
        "value": 139.713,
        "_internal_originalTime": 1689797909000
    },
    {
        "time": 1689797912000,
        "value": 139.714,
        "_internal_originalTime": 1689797912000
    },
    {
        "time": 1689797918000,
        "value": 139.716,
        "_internal_originalTime": 1689797918000
    },
    {
        "time": 1689797930000,
        "value": 139.711,
        "_internal_originalTime": 1689797930000
    },
    {
        "time": 1689797933000,
        "value": 139.718,
        "_internal_originalTime": 1689797933000
    },
    {
        "time": 1689797936000,
        "value": 139.714,
        "_internal_originalTime": 1689797936000
    },
    {
        "time": 1689797940000,
        "value": 139.717,
        "_internal_originalTime": 1689797940000
    },
    {
        "time": 1689797942000,
        "value": 139.714,
        "_internal_originalTime": 1689797942000
    },
    {
        "time": 1689797948000,
        "value": 139.717,
        "_internal_originalTime": 1689797948000
    },
    {
        "time": 1689797960000,
        "value": 139.712,
        "_internal_originalTime": 1689797960000
    },
    {
        "time": 1689797961000,
        "value": 139.714,
        "_internal_originalTime": 1689797961000
    },
    {
        "time": 1689797967000,
        "value": 139.713,
        "_internal_originalTime": 1689797967000
    },
    {
        "time": 1689797969000,
        "value": 139.717,
        "_internal_originalTime": 1689797969000
    },
    {
        "time": 1689797971000,
        "value": 139.717,
        "_internal_originalTime": 1689797971000
    },
    {
        "time": 1689797978000,
        "value": 139.714,
        "_internal_originalTime": 1689797978000
    },
    {
        "time": 1689797980000,
        "value": 139.717,
        "_internal_originalTime": 1689797980000
    },
    {
        "time": 1689797990000,
        "value": 139.712,
        "_internal_originalTime": 1689797990000
    },
    {
        "time": 1689797992000,
        "value": 139.714,
        "_internal_originalTime": 1689797992000
    },
    {
        "time": 1689797998000,
        "value": 139.713,
        "_internal_originalTime": 1689797998000
    },
    {
        "time": 1689798001000,
        "value": 139.714,
        "_internal_originalTime": 1689798001000
    },
    {
        "time": 1689798005000,
        "value": 139.712,
        "_internal_originalTime": 1689798005000
    },
    {
        "time": 1689798006000,
        "value": 139.713,
        "_internal_originalTime": 1689798006000
    },
    {
        "time": 1689798010000,
        "value": 139.716,
        "_internal_originalTime": 1689798010000
    },
    {
        "time": 1689798014000,
        "value": 139.717,
        "_internal_originalTime": 1689798014000
    },
    {
        "time": 1689798020000,
        "value": 139.709,
        "_internal_originalTime": 1689798020000
    },
    {
        "time": 1689798022000,
        "value": 139.714,
        "_internal_originalTime": 1689798022000
    },
    {
        "time": 1689798029000,
        "value": 139.714,
        "_internal_originalTime": 1689798029000
    },
    {
        "time": 1689798031000,
        "value": 139.717,
        "_internal_originalTime": 1689798031000
    },
    {
        "time": 1689798042000,
        "value": 139.713,
        "_internal_originalTime": 1689798042000
    },
    {
        "time": 1689798044000,
        "value": 139.713,
        "_internal_originalTime": 1689798044000
    },
    {
        "time": 1689798048000,
        "value": 139.717,
        "_internal_originalTime": 1689798048000
    },
    {
        "time": 1689798051000,
        "value": 139.714,
        "_internal_originalTime": 1689798051000
    },
    {
        "time": 1689798053000,
        "value": 139.72,
        "_internal_originalTime": 1689798053000
    },
    {
        "time": 1689798055000,
        "value": 139.72,
        "_internal_originalTime": 1689798055000
    },
    {
        "time": 1689798060000,
        "value": 139.715,
        "_internal_originalTime": 1689798060000
    },
    {
        "time": 1689798063000,
        "value": 139.721,
        "_internal_originalTime": 1689798063000
    },
    {
        "time": 1689798065000,
        "value": 139.721,
        "_internal_originalTime": 1689798065000
    },
    {
        "time": 1689798066000,
        "value": 139.726,
        "_internal_originalTime": 1689798066000
    },
    {
        "time": 1689798069000,
        "value": 139.72,
        "_internal_originalTime": 1689798069000
    },
    {
        "time": 1689798070000,
        "value": 139.725,
        "_internal_originalTime": 1689798070000
    },
    {
        "time": 1689798078000,
        "value": 139.726,
        "_internal_originalTime": 1689798078000
    },
    {
        "time": 1689798080000,
        "value": 139.72,
        "_internal_originalTime": 1689798080000
    },
    {
        "time": 1689798091000,
        "value": 139.721,
        "_internal_originalTime": 1689798091000
    },
    {
        "time": 1689798096000,
        "value": 139.722,
        "_internal_originalTime": 1689798096000
    },
    {
        "time": 1689798100000,
        "value": 139.724,
        "_internal_originalTime": 1689798100000
    },
    {
        "time": 1689798110000,
        "value": 139.719,
        "_internal_originalTime": 1689798110000
    },
    {
        "time": 1689798117000,
        "value": 139.722,
        "_internal_originalTime": 1689798117000
    },
    {
        "time": 1689798120000,
        "value": 139.722,
        "_internal_originalTime": 1689798120000
    },
    {
        "time": 1689798122000,
        "value": 139.723,
        "_internal_originalTime": 1689798122000
    },
    {
        "time": 1689798130000,
        "value": 139.724,
        "_internal_originalTime": 1689798130000
    },
    {
        "time": 1689798140000,
        "value": 139.723,
        "_internal_originalTime": 1689798140000
    },
    {
        "time": 1689798142000,
        "value": 139.723,
        "_internal_originalTime": 1689798142000
    },
    {
        "time": 1689798145000,
        "value": 139.721,
        "_internal_originalTime": 1689798145000
    },
    {
        "time": 1689798148000,
        "value": 139.72,
        "_internal_originalTime": 1689798148000
    },
    {
        "time": 1689798149000,
        "value": 139.719,
        "_internal_originalTime": 1689798149000
    },
    {
        "time": 1689798150000,
        "value": 139.719,
        "_internal_originalTime": 1689798150000
    },
    {
        "time": 1689798153000,
        "value": 139.718,
        "_internal_originalTime": 1689798153000
    },
    {
        "time": 1689798156000,
        "value": 139.718,
        "_internal_originalTime": 1689798156000
    },
    {
        "time": 1689798160000,
        "value": 139.721,
        "_internal_originalTime": 1689798160000
    },
    {
        "time": 1689798162000,
        "value": 139.719,
        "_internal_originalTime": 1689798162000
    },
    {
        "time": 1689798164000,
        "value": 139.716,
        "_internal_originalTime": 1689798164000
    },
    {
        "time": 1689798165000,
        "value": 139.716,
        "_internal_originalTime": 1689798165000
    },
    {
        "time": 1689798168000,
        "value": 139.716,
        "_internal_originalTime": 1689798168000
    },
    {
        "time": 1689798170000,
        "value": 139.712,
        "_internal_originalTime": 1689798170000
    },
    {
        "time": 1689798174000,
        "value": 139.717,
        "_internal_originalTime": 1689798174000
    },
    {
        "time": 1689798176000,
        "value": 139.714,
        "_internal_originalTime": 1689798176000
    },
    {
        "time": 1689798177000,
        "value": 139.714,
        "_internal_originalTime": 1689798177000
    },
    {
        "time": 1689798184000,
        "value": 139.713,
        "_internal_originalTime": 1689798184000
    },
    {
        "time": 1689798188000,
        "value": 139.715,
        "_internal_originalTime": 1689798188000
    },
    {
        "time": 1689798190000,
        "value": 139.713,
        "_internal_originalTime": 1689798190000
    },
    {
        "time": 1689798191000,
        "value": 139.712,
        "_internal_originalTime": 1689798191000
    },
    {
        "time": 1689798197000,
        "value": 139.712,
        "_internal_originalTime": 1689798197000
    },
    {
        "time": 1689798200000,
        "value": 139.71,
        "_internal_originalTime": 1689798200000
    },
    {
        "time": 1689798201000,
        "value": 139.712,
        "_internal_originalTime": 1689798201000
    },
    {
        "time": 1689798204000,
        "value": 139.712,
        "_internal_originalTime": 1689798204000
    },
    {
        "time": 1689798209000,
        "value": 139.712,
        "_internal_originalTime": 1689798209000
    },
    {
        "time": 1689798215000,
        "value": 139.711,
        "_internal_originalTime": 1689798215000
    },
    {
        "time": 1689798220000,
        "value": 139.715,
        "_internal_originalTime": 1689798220000
    },
    {
        "time": 1689798227000,
        "value": 139.718,
        "_internal_originalTime": 1689798227000
    },
    {
        "time": 1689798229000,
        "value": 139.717,
        "_internal_originalTime": 1689798229000
    },
    {
        "time": 1689798230000,
        "value": 139.711,
        "_internal_originalTime": 1689798230000
    },
    {
        "time": 1689798233000,
        "value": 139.714,
        "_internal_originalTime": 1689798233000
    },
    {
        "time": 1689798239000,
        "value": 139.718,
        "_internal_originalTime": 1689798239000
    },
    {
        "time": 1689798240000,
        "value": 139.717,
        "_internal_originalTime": 1689798240000
    },
    {
        "time": 1689798242000,
        "value": 139.718,
        "_internal_originalTime": 1689798242000
    },
    {
        "time": 1689798244000,
        "value": 139.717,
        "_internal_originalTime": 1689798244000
    },
    {
        "time": 1689798246000,
        "value": 139.711,
        "_internal_originalTime": 1689798246000
    },
    {
        "time": 1689798250000,
        "value": 139.714,
        "_internal_originalTime": 1689798250000
    },
    {
        "time": 1689798260000,
        "value": 139.709,
        "_internal_originalTime": 1689798260000
    },
    {
        "time": 1689798265000,
        "value": 139.712,
        "_internal_originalTime": 1689798265000
    },
    {
        "time": 1689798280000,
        "value": 139.714,
        "_internal_originalTime": 1689798280000
    },
    {
        "time": 1689798290000,
        "value": 139.709,
        "_internal_originalTime": 1689798290000
    },
    {
        "time": 1689798297000,
        "value": 139.711,
        "_internal_originalTime": 1689798297000
    },
    {
        "time": 1689798298000,
        "value": 139.709,
        "_internal_originalTime": 1689798298000
    },
    {
        "time": 1689798299000,
        "value": 139.709,
        "_internal_originalTime": 1689798299000
    },
    {
        "time": 1689798303000,
        "value": 139.704,
        "_internal_originalTime": 1689798303000
    },
    {
        "time": 1689798308000,
        "value": 139.703,
        "_internal_originalTime": 1689798308000
    },
    {
        "time": 1689798313000,
        "value": 139.705,
        "_internal_originalTime": 1689798313000
    },
    {
        "time": 1689798320000,
        "value": 139.701,
        "_internal_originalTime": 1689798320000
    },
    {
        "time": 1689798325000,
        "value": 139.704,
        "_internal_originalTime": 1689798325000
    },
    {
        "time": 1689798327000,
        "value": 139.71,
        "_internal_originalTime": 1689798327000
    },
    {
        "time": 1689798340000,
        "value": 139.709,
        "_internal_originalTime": 1689798340000
    },
    {
        "time": 1689798342000,
        "value": 139.706,
        "_internal_originalTime": 1689798342000
    },
    {
        "time": 1689798346000,
        "value": 139.706,
        "_internal_originalTime": 1689798346000
    },
    {
        "time": 1689798350000,
        "value": 139.702,
        "_internal_originalTime": 1689798350000
    },
    {
        "time": 1689798353000,
        "value": 139.706,
        "_internal_originalTime": 1689798353000
    },
    {
        "time": 1689798358000,
        "value": 139.706,
        "_internal_originalTime": 1689798358000
    },
    {
        "time": 1689798359000,
        "value": 139.706,
        "_internal_originalTime": 1689798359000
    },
    {
        "time": 1689798364000,
        "value": 139.709,
        "_internal_originalTime": 1689798364000
    },
    {
        "time": 1689798366000,
        "value": 139.706,
        "_internal_originalTime": 1689798366000
    },
    {
        "time": 1689798372000,
        "value": 139.709,
        "_internal_originalTime": 1689798372000
    },
    {
        "time": 1689798374000,
        "value": 139.706,
        "_internal_originalTime": 1689798374000
    },
    {
        "time": 1689798378000,
        "value": 139.71,
        "_internal_originalTime": 1689798378000
    },
    {
        "time": 1689798381000,
        "value": 139.706,
        "_internal_originalTime": 1689798381000
    },
    {
        "time": 1689798385000,
        "value": 139.707,
        "_internal_originalTime": 1689798385000
    },
    {
        "time": 1689798390000,
        "value": 139.707,
        "_internal_originalTime": 1689798390000
    },
    {
        "time": 1689798393000,
        "value": 139.708,
        "_internal_originalTime": 1689798393000
    },
    {
        "time": 1689798401000,
        "value": 139.707,
        "_internal_originalTime": 1689798401000
    },
    {
        "time": 1689798403000,
        "value": 139.709,
        "_internal_originalTime": 1689798403000
    },
    {
        "time": 1689798409000,
        "value": 139.71,
        "_internal_originalTime": 1689798409000
    },
    {
        "time": 1689798411000,
        "value": 139.712,
        "_internal_originalTime": 1689798411000
    },
    {
        "time": 1689798412000,
        "value": 139.711,
        "_internal_originalTime": 1689798412000
    },
    {
        "time": 1689798421000,
        "value": 139.712,
        "_internal_originalTime": 1689798421000
    },
    {
        "time": 1689798424000,
        "value": 139.712,
        "_internal_originalTime": 1689798424000
    },
    {
        "time": 1689798427000,
        "value": 139.712,
        "_internal_originalTime": 1689798427000
    },
    {
        "time": 1689798432000,
        "value": 139.711,
        "_internal_originalTime": 1689798432000
    },
    {
        "time": 1689798437000,
        "value": 139.712,
        "_internal_originalTime": 1689798437000
    },
    {
        "time": 1689798440000,
        "value": 139.707,
        "_internal_originalTime": 1689798440000
    },
    {
        "time": 1689798447000,
        "value": 139.712,
        "_internal_originalTime": 1689798447000
    },
    {
        "time": 1689798451000,
        "value": 139.719,
        "_internal_originalTime": 1689798451000
    },
    {
        "time": 1689798453000,
        "value": 139.719,
        "_internal_originalTime": 1689798453000
    },
    {
        "time": 1689798458000,
        "value": 139.719,
        "_internal_originalTime": 1689798458000
    },
    {
        "time": 1689798463000,
        "value": 139.712,
        "_internal_originalTime": 1689798463000
    },
    {
        "time": 1689798467000,
        "value": 139.714,
        "_internal_originalTime": 1689798467000
    },
    {
        "time": 1689798472000,
        "value": 139.714,
        "_internal_originalTime": 1689798472000
    },
    {
        "time": 1689798474000,
        "value": 139.714,
        "_internal_originalTime": 1689798474000
    },
    {
        "time": 1689798477000,
        "value": 139.714,
        "_internal_originalTime": 1689798477000
    },
    {
        "time": 1689798481000,
        "value": 139.714,
        "_internal_originalTime": 1689798481000
    },
    {
        "time": 1689798490000,
        "value": 139.717,
        "_internal_originalTime": 1689798490000
    },
    {
        "time": 1689798494000,
        "value": 139.712,
        "_internal_originalTime": 1689798494000
    },
    {
        "time": 1689798500000,
        "value": 139.709,
        "_internal_originalTime": 1689798500000
    },
    {
        "time": 1689798505000,
        "value": 139.719,
        "_internal_originalTime": 1689798505000
    },
    {
        "time": 1689798509000,
        "value": 139.719,
        "_internal_originalTime": 1689798509000
    },
    {
        "time": 1689798522000,
        "value": 139.715,
        "_internal_originalTime": 1689798522000
    },
    {
        "time": 1689798525000,
        "value": 139.722,
        "_internal_originalTime": 1689798525000
    },
    {
        "time": 1689798528000,
        "value": 139.722,
        "_internal_originalTime": 1689798528000
    },
    {
        "time": 1689798532000,
        "value": 139.722,
        "_internal_originalTime": 1689798532000
    },
    {
        "time": 1689798534000,
        "value": 139.726,
        "_internal_originalTime": 1689798534000
    },
    {
        "time": 1689798541000,
        "value": 139.727,
        "_internal_originalTime": 1689798541000
    },
    {
        "time": 1689798542000,
        "value": 139.723,
        "_internal_originalTime": 1689798542000
    },
    {
        "time": 1689798550000,
        "value": 139.724,
        "_internal_originalTime": 1689798550000
    },
    {
        "time": 1689798557000,
        "value": 139.719,
        "_internal_originalTime": 1689798557000
    },
    {
        "time": 1689798561000,
        "value": 139.726,
        "_internal_originalTime": 1689798561000
    },
    {
        "time": 1689798565000,
        "value": 139.712,
        "_internal_originalTime": 1689798565000
    },
    {
        "time": 1689798567000,
        "value": 139.718,
        "_internal_originalTime": 1689798567000
    },
    {
        "time": 1689798572000,
        "value": 139.712,
        "_internal_originalTime": 1689798572000
    },
    {
        "time": 1689798580000,
        "value": 139.719,
        "_internal_originalTime": 1689798580000
    },
    {
        "time": 1689798588000,
        "value": 139.718,
        "_internal_originalTime": 1689798588000
    },
    {
        "time": 1689798592000,
        "value": 139.717,
        "_internal_originalTime": 1689798592000
    },
    {
        "time": 1689798595000,
        "value": 139.718,
        "_internal_originalTime": 1689798595000
    },
    {
        "time": 1689798600000,
        "value": 139.717,
        "_internal_originalTime": 1689798600000
    },
    {
        "time": 1689798602000,
        "value": 139.718,
        "_internal_originalTime": 1689798602000
    },
    {
        "time": 1689798606000,
        "value": 139.712,
        "_internal_originalTime": 1689798606000
    },
    {
        "time": 1689798610000,
        "value": 139.714,
        "_internal_originalTime": 1689798610000
    },
    {
        "time": 1689798613000,
        "value": 139.712,
        "_internal_originalTime": 1689798613000
    },
    {
        "time": 1689798618000,
        "value": 139.712,
        "_internal_originalTime": 1689798618000
    },
    {
        "time": 1689798621000,
        "value": 139.714,
        "_internal_originalTime": 1689798621000
    },
    {
        "time": 1689798623000,
        "value": 139.719,
        "_internal_originalTime": 1689798623000
    },
    {
        "time": 1689798634000,
        "value": 139.714,
        "_internal_originalTime": 1689798634000
    },
    {
        "time": 1689798640000,
        "value": 139.719,
        "_internal_originalTime": 1689798640000
    },
    {
        "time": 1689798649000,
        "value": 139.712,
        "_internal_originalTime": 1689798649000
    },
    {
        "time": 1689798650000,
        "value": 139.709,
        "_internal_originalTime": 1689798650000
    },
    {
        "time": 1689798655000,
        "value": 139.712,
        "_internal_originalTime": 1689798655000
    },
    {
        "time": 1689798660000,
        "value": 139.713,
        "_internal_originalTime": 1689798660000
    },
    {
        "time": 1689798699000,
        "value": 139.718,
        "_internal_originalTime": 1689798699000
    },
    {
        "time": 1689798702000,
        "value": 139.712,
        "_internal_originalTime": 1689798702000
    },
    {
        "time": 1689798710000,
        "value": 139.708,
        "_internal_originalTime": 1689798710000
    },
    {
        "time": 1689798711000,
        "value": 139.712,
        "_internal_originalTime": 1689798711000
    },
    {
        "time": 1689798719000,
        "value": 139.715,
        "_internal_originalTime": 1689798719000
    },
    {
        "time": 1689798723000,
        "value": 139.718,
        "_internal_originalTime": 1689798723000
    },
    {
        "time": 1689798726000,
        "value": 139.718,
        "_internal_originalTime": 1689798726000
    },
    {
        "time": 1689798733000,
        "value": 139.716,
        "_internal_originalTime": 1689798733000
    },
    {
        "time": 1689798742000,
        "value": 139.713,
        "_internal_originalTime": 1689798742000
    },
    {
        "time": 1689798747000,
        "value": 139.712,
        "_internal_originalTime": 1689798747000
    },
    {
        "time": 1689798749000,
        "value": 139.714,
        "_internal_originalTime": 1689798749000
    },
    {
        "time": 1689798763000,
        "value": 139.712,
        "_internal_originalTime": 1689798763000
    },
    {
        "time": 1689798766000,
        "value": 139.712,
        "_internal_originalTime": 1689798766000
    },
    {
        "time": 1689798768000,
        "value": 139.712,
        "_internal_originalTime": 1689798768000
    },
    {
        "time": 1689798773000,
        "value": 139.712,
        "_internal_originalTime": 1689798773000
    },
    {
        "time": 1689798777000,
        "value": 139.705,
        "_internal_originalTime": 1689798777000
    },
    {
        "time": 1689798780000,
        "value": 139.704,
        "_internal_originalTime": 1689798780000
    },
    {
        "time": 1689798784000,
        "value": 139.711,
        "_internal_originalTime": 1689798784000
    },
    {
        "time": 1689798788000,
        "value": 139.702,
        "_internal_originalTime": 1689798788000
    },
    {
        "time": 1689798789000,
        "value": 139.707,
        "_internal_originalTime": 1689798789000
    },
    {
        "time": 1689798799000,
        "value": 139.703,
        "_internal_originalTime": 1689798799000
    },
    {
        "time": 1689798802000,
        "value": 139.702,
        "_internal_originalTime": 1689798802000
    },
    {
        "time": 1689798804000,
        "value": 139.702,
        "_internal_originalTime": 1689798804000
    },
    {
        "time": 1689798809000,
        "value": 139.705,
        "_internal_originalTime": 1689798809000
    },
    {
        "time": 1689798814000,
        "value": 139.702,
        "_internal_originalTime": 1689798814000
    },
    {
        "time": 1689798819000,
        "value": 139.702,
        "_internal_originalTime": 1689798819000
    },
    {
        "time": 1689798820000,
        "value": 139.702,
        "_internal_originalTime": 1689798820000
    },
    {
        "time": 1689798825000,
        "value": 139.703,
        "_internal_originalTime": 1689798825000
    },
    {
        "time": 1689798831000,
        "value": 139.703,
        "_internal_originalTime": 1689798831000
    },
    {
        "time": 1689798835000,
        "value": 139.703,
        "_internal_originalTime": 1689798835000
    },
    {
        "time": 1689798838000,
        "value": 139.703,
        "_internal_originalTime": 1689798838000
    },
    {
        "time": 1689798839000,
        "value": 139.705,
        "_internal_originalTime": 1689798839000
    },
    {
        "time": 1689798842000,
        "value": 139.702,
        "_internal_originalTime": 1689798842000
    },
    {
        "time": 1689798851000,
        "value": 139.702,
        "_internal_originalTime": 1689798851000
    },
    {
        "time": 1689798857000,
        "value": 139.707,
        "_internal_originalTime": 1689798857000
    },
    {
        "time": 1689798860000,
        "value": 139.697,
        "_internal_originalTime": 1689798860000
    },
    {
        "time": 1689798870000,
        "value": 139.7,
        "_internal_originalTime": 1689798870000
    },
    {
        "time": 1689798872000,
        "value": 139.709,
        "_internal_originalTime": 1689798872000
    },
    {
        "time": 1689798876000,
        "value": 139.704,
        "_internal_originalTime": 1689798876000
    },
    {
        "time": 1689798881000,
        "value": 139.703,
        "_internal_originalTime": 1689798881000
    },
    {
        "time": 1689798884000,
        "value": 139.703,
        "_internal_originalTime": 1689798884000
    },
    {
        "time": 1689798889000,
        "value": 139.704,
        "_internal_originalTime": 1689798889000
    },
    {
        "time": 1689798890000,
        "value": 139.7,
        "_internal_originalTime": 1689798890000
    },
    {
        "time": 1689798896000,
        "value": 139.704,
        "_internal_originalTime": 1689798896000
    },
    {
        "time": 1689798899000,
        "value": 139.702,
        "_internal_originalTime": 1689798899000
    },
    {
        "time": 1689798901000,
        "value": 139.704,
        "_internal_originalTime": 1689798901000
    },
    {
        "time": 1689798906000,
        "value": 139.703,
        "_internal_originalTime": 1689798906000
    },
    {
        "time": 1689798908000,
        "value": 139.705,
        "_internal_originalTime": 1689798908000
    },
    {
        "time": 1689798921000,
        "value": 139.705,
        "_internal_originalTime": 1689798921000
    },
    {
        "time": 1689798928000,
        "value": 139.702,
        "_internal_originalTime": 1689798928000
    },
    {
        "time": 1689798932000,
        "value": 139.702,
        "_internal_originalTime": 1689798932000
    },
    {
        "time": 1689798940000,
        "value": 139.702,
        "_internal_originalTime": 1689798940000
    },
    {
        "time": 1689798944000,
        "value": 139.702,
        "_internal_originalTime": 1689798944000
    },
    {
        "time": 1689798947000,
        "value": 139.708,
        "_internal_originalTime": 1689798947000
    },
    {
        "time": 1689798951000,
        "value": 139.704,
        "_internal_originalTime": 1689798951000
    },
    {
        "time": 1689798953000,
        "value": 139.708,
        "_internal_originalTime": 1689798953000
    },
    {
        "time": 1689798959000,
        "value": 139.701,
        "_internal_originalTime": 1689798959000
    },
    {
        "time": 1689798963000,
        "value": 139.702,
        "_internal_originalTime": 1689798963000
    },
    {
        "time": 1689798965000,
        "value": 139.701,
        "_internal_originalTime": 1689798965000
    },
    {
        "time": 1689798971000,
        "value": 139.702,
        "_internal_originalTime": 1689798971000
    },
    {
        "time": 1689798973000,
        "value": 139.702,
        "_internal_originalTime": 1689798973000
    },
    {
        "time": 1689798979000,
        "value": 139.708,
        "_internal_originalTime": 1689798979000
    },
    {
        "time": 1689798981000,
        "value": 139.7,
        "_internal_originalTime": 1689798981000
    },
    {
        "time": 1689798988000,
        "value": 139.702,
        "_internal_originalTime": 1689798988000
    },
    {
        "time": 1689798992000,
        "value": 139.708,
        "_internal_originalTime": 1689798992000
    },
    {
        "time": 1689798995000,
        "value": 139.708,
        "_internal_originalTime": 1689798995000
    },
    {
        "time": 1689799000000,
        "value": 139.704,
        "_internal_originalTime": 1689799000000
    },
    {
        "time": 1689799008000,
        "value": 139.705,
        "_internal_originalTime": 1689799008000
    },
    {
        "time": 1689799011000,
        "value": 139.699,
        "_internal_originalTime": 1689799011000
    },
    {
        "time": 1689799020000,
        "value": 139.707,
        "_internal_originalTime": 1689799020000
    },
    {
        "time": 1689799022000,
        "value": 139.708,
        "_internal_originalTime": 1689799022000
    },
    {
        "time": 1689799028000,
        "value": 139.703,
        "_internal_originalTime": 1689799028000
    },
    {
        "time": 1689799032000,
        "value": 139.708,
        "_internal_originalTime": 1689799032000
    },
    {
        "time": 1689799033000,
        "value": 139.705,
        "_internal_originalTime": 1689799033000
    },
    {
        "time": 1689799041000,
        "value": 139.7,
        "_internal_originalTime": 1689799041000
    },
    {
        "time": 1689799042000,
        "value": 139.708,
        "_internal_originalTime": 1689799042000
    },
    {
        "time": 1689799049000,
        "value": 139.705,
        "_internal_originalTime": 1689799049000
    },
    {
        "time": 1689799052000,
        "value": 139.702,
        "_internal_originalTime": 1689799052000
    },
    {
        "time": 1689799064000,
        "value": 139.701,
        "_internal_originalTime": 1689799064000
    },
    {
        "time": 1689799071000,
        "value": 139.7,
        "_internal_originalTime": 1689799071000
    },
    {
        "time": 1689799077000,
        "value": 139.705,
        "_internal_originalTime": 1689799077000
    },
    {
        "time": 1689799081000,
        "value": 139.705,
        "_internal_originalTime": 1689799081000
    },
    {
        "time": 1689799085000,
        "value": 139.706,
        "_internal_originalTime": 1689799085000
    },
    {
        "time": 1689799089000,
        "value": 139.716,
        "_internal_originalTime": 1689799089000
    },
    {
        "time": 1689799092000,
        "value": 139.716,
        "_internal_originalTime": 1689799092000
    },
    {
        "time": 1689799097000,
        "value": 139.715,
        "_internal_originalTime": 1689799097000
    },
    {
        "time": 1689799101000,
        "value": 139.716,
        "_internal_originalTime": 1689799101000
    },
    {
        "time": 1689799109000,
        "value": 139.716,
        "_internal_originalTime": 1689799109000
    },
    {
        "time": 1689799114000,
        "value": 139.71,
        "_internal_originalTime": 1689799114000
    },
    {
        "time": 1689799124000,
        "value": 139.706,
        "_internal_originalTime": 1689799124000
    },
    {
        "time": 1689799131000,
        "value": 139.7,
        "_internal_originalTime": 1689799131000
    },
    {
        "time": 1689799136000,
        "value": 139.705,
        "_internal_originalTime": 1689799136000
    },
    {
        "time": 1689799137000,
        "value": 139.709,
        "_internal_originalTime": 1689799137000
    },
    {
        "time": 1689799139000,
        "value": 139.705,
        "_internal_originalTime": 1689799139000
    },
    {
        "time": 1689799140000,
        "value": 139.704,
        "_internal_originalTime": 1689799140000
    },
    {
        "time": 1689799141000,
        "value": 139.705,
        "_internal_originalTime": 1689799141000
    },
    {
        "time": 1689799146000,
        "value": 139.704,
        "_internal_originalTime": 1689799146000
    },
    {
        "time": 1689799147000,
        "value": 139.705,
        "_internal_originalTime": 1689799147000
    },
    {
        "time": 1689799161000,
        "value": 139.7,
        "_internal_originalTime": 1689799161000
    },
    {
        "time": 1689799167000,
        "value": 139.704,
        "_internal_originalTime": 1689799167000
    },
    {
        "time": 1689799169000,
        "value": 139.705,
        "_internal_originalTime": 1689799169000
    },
    {
        "time": 1689799176000,
        "value": 139.703,
        "_internal_originalTime": 1689799176000
    },
    {
        "time": 1689799179000,
        "value": 139.709,
        "_internal_originalTime": 1689799179000
    },
    {
        "time": 1689799185000,
        "value": 139.71,
        "_internal_originalTime": 1689799185000
    },
    {
        "time": 1689799187000,
        "value": 139.706,
        "_internal_originalTime": 1689799187000
    },
    {
        "time": 1689799191000,
        "value": 139.701,
        "_internal_originalTime": 1689799191000
    },
    {
        "time": 1689799196000,
        "value": 139.706,
        "_internal_originalTime": 1689799196000
    },
    {
        "time": 1689799199000,
        "value": 139.707,
        "_internal_originalTime": 1689799199000
    },
    {
        "time": 1689799208000,
        "value": 139.709,
        "_internal_originalTime": 1689799208000
    },
    {
        "time": 1689799209000,
        "value": 139.704,
        "_internal_originalTime": 1689799209000
    },
    {
        "time": 1689799221000,
        "value": 139.7,
        "_internal_originalTime": 1689799221000
    },
    {
        "time": 1689799229000,
        "value": 139.706,
        "_internal_originalTime": 1689799229000
    },
    {
        "time": 1689799230000,
        "value": 139.704,
        "_internal_originalTime": 1689799230000
    },
    {
        "time": 1689799238000,
        "value": 139.705,
        "_internal_originalTime": 1689799238000
    },
    {
        "time": 1689799240000,
        "value": 139.706,
        "_internal_originalTime": 1689799240000
    },
    {
        "time": 1689799246000,
        "value": 139.706,
        "_internal_originalTime": 1689799246000
    },
    {
        "time": 1689799253000,
        "value": 139.704,
        "_internal_originalTime": 1689799253000
    },
    {
        "time": 1689799256000,
        "value": 139.704,
        "_internal_originalTime": 1689799256000
    },
    {
        "time": 1689799259000,
        "value": 139.706,
        "_internal_originalTime": 1689799259000
    },
    {
        "time": 1689799271000,
        "value": 139.704,
        "_internal_originalTime": 1689799271000
    },
    {
        "time": 1689799275000,
        "value": 139.704,
        "_internal_originalTime": 1689799275000
    },
    {
        "time": 1689799281000,
        "value": 139.701,
        "_internal_originalTime": 1689799281000
    },
    {
        "time": 1689799287000,
        "value": 139.71,
        "_internal_originalTime": 1689799287000
    },
    {
        "time": 1689799289000,
        "value": 139.706,
        "_internal_originalTime": 1689799289000
    },
    {
        "time": 1689799294000,
        "value": 139.704,
        "_internal_originalTime": 1689799294000
    },
    {
        "time": 1689799301000,
        "value": 139.711,
        "_internal_originalTime": 1689799301000
    },
    {
        "time": 1689799308000,
        "value": 139.716,
        "_internal_originalTime": 1689799308000
    },
    {
        "time": 1689799311000,
        "value": 139.721,
        "_internal_originalTime": 1689799311000
    },
    {
        "time": 1689799316000,
        "value": 139.717,
        "_internal_originalTime": 1689799316000
    },
    {
        "time": 1689799318000,
        "value": 139.717,
        "_internal_originalTime": 1689799318000
    },
    {
        "time": 1689799322000,
        "value": 139.717,
        "_internal_originalTime": 1689799322000
    },
    {
        "time": 1689799327000,
        "value": 139.716,
        "_internal_originalTime": 1689799327000
    },
    {
        "time": 1689799331000,
        "value": 139.716,
        "_internal_originalTime": 1689799331000
    },
    {
        "time": 1689799334000,
        "value": 139.716,
        "_internal_originalTime": 1689799334000
    },
    {
        "time": 1689799338000,
        "value": 139.719,
        "_internal_originalTime": 1689799338000
    },
    {
        "time": 1689799341000,
        "value": 139.71,
        "_internal_originalTime": 1689799341000
    },
    {
        "time": 1689799349000,
        "value": 139.716,
        "_internal_originalTime": 1689799349000
    },
    {
        "time": 1689799356000,
        "value": 139.72,
        "_internal_originalTime": 1689799356000
    },
    {
        "time": 1689799365000,
        "value": 139.719,
        "_internal_originalTime": 1689799365000
    },
    {
        "time": 1689799372000,
        "value": 139.714,
        "_internal_originalTime": 1689799372000
    },
    {
        "time": 1689799380000,
        "value": 139.712,
        "_internal_originalTime": 1689799380000
    },
    {
        "time": 1689799384000,
        "value": 139.724,
        "_internal_originalTime": 1689799384000
    },
    {
        "time": 1689799388000,
        "value": 139.719,
        "_internal_originalTime": 1689799388000
    },
    {
        "time": 1689799392000,
        "value": 139.716,
        "_internal_originalTime": 1689799392000
    },
    {
        "time": 1689799394000,
        "value": 139.724,
        "_internal_originalTime": 1689799394000
    },
    {
        "time": 1689799400000,
        "value": 139.719,
        "_internal_originalTime": 1689799400000
    },
    {
        "time": 1689799404000,
        "value": 139.72,
        "_internal_originalTime": 1689799404000
    },
    {
        "time": 1689799408000,
        "value": 139.722,
        "_internal_originalTime": 1689799408000
    },
    {
        "time": 1689799412000,
        "value": 139.723,
        "_internal_originalTime": 1689799412000
    },
    {
        "time": 1689799416000,
        "value": 139.723,
        "_internal_originalTime": 1689799416000
    },
    {
        "time": 1689799421000,
        "value": 139.723,
        "_internal_originalTime": 1689799421000
    },
    {
        "time": 1689799425000,
        "value": 139.723,
        "_internal_originalTime": 1689799425000
    },
    {
        "time": 1689799426000,
        "value": 139.722,
        "_internal_originalTime": 1689799426000
    },
    {
        "time": 1689799431000,
        "value": 139.715,
        "_internal_originalTime": 1689799431000
    },
    {
        "time": 1689799437000,
        "value": 139.728,
        "_internal_originalTime": 1689799437000
    },
    {
        "time": 1689799439000,
        "value": 139.726,
        "_internal_originalTime": 1689799439000
    },
    {
        "time": 1689799440000,
        "value": 139.73,
        "_internal_originalTime": 1689799440000
    },
    {
        "time": 1689799441000,
        "value": 139.734,
        "_internal_originalTime": 1689799441000
    },
    {
        "time": 1689799443000,
        "value": 139.734,
        "_internal_originalTime": 1689799443000
    },
    {
        "time": 1689799444000,
        "value": 139.734,
        "_internal_originalTime": 1689799444000
    },
    {
        "time": 1689799448000,
        "value": 139.736,
        "_internal_originalTime": 1689799448000
    },
    {
        "time": 1689799453000,
        "value": 139.74,
        "_internal_originalTime": 1689799453000
    },
    {
        "time": 1689799457000,
        "value": 139.732,
        "_internal_originalTime": 1689799457000
    },
    {
        "time": 1689799460000,
        "value": 139.726,
        "_internal_originalTime": 1689799460000
    },
    {
        "time": 1689799464000,
        "value": 139.73,
        "_internal_originalTime": 1689799464000
    },
    {
        "time": 1689799468000,
        "value": 139.74,
        "_internal_originalTime": 1689799468000
    },
    {
        "time": 1689799472000,
        "value": 139.736,
        "_internal_originalTime": 1689799472000
    },
    {
        "time": 1689799476000,
        "value": 139.744,
        "_internal_originalTime": 1689799476000
    },
    {
        "time": 1689799481000,
        "value": 139.742,
        "_internal_originalTime": 1689799481000
    },
    {
        "time": 1689799484000,
        "value": 139.742,
        "_internal_originalTime": 1689799484000
    },
    {
        "time": 1689799488000,
        "value": 139.745,
        "_internal_originalTime": 1689799488000
    },
    {
        "time": 1689799492000,
        "value": 139.741,
        "_internal_originalTime": 1689799492000
    },
    {
        "time": 1689799496000,
        "value": 139.743,
        "_internal_originalTime": 1689799496000
    },
    {
        "time": 1689799499000,
        "value": 139.739,
        "_internal_originalTime": 1689799499000
    },
    {
        "time": 1689799502000,
        "value": 139.746,
        "_internal_originalTime": 1689799502000
    },
    {
        "time": 1689799506000,
        "value": 139.745,
        "_internal_originalTime": 1689799506000
    },
    {
        "time": 1689799510000,
        "value": 139.752,
        "_internal_originalTime": 1689799510000
    },
    {
        "time": 1689799513000,
        "value": 139.752,
        "_internal_originalTime": 1689799513000
    },
    {
        "time": 1689799518000,
        "value": 139.756,
        "_internal_originalTime": 1689799518000
    },
    {
        "time": 1689799521000,
        "value": 139.756,
        "_internal_originalTime": 1689799521000
    },
    {
        "time": 1689799526000,
        "value": 139.752,
        "_internal_originalTime": 1689799526000
    },
    {
        "time": 1689799529000,
        "value": 139.757,
        "_internal_originalTime": 1689799529000
    },
    {
        "time": 1689799535000,
        "value": 139.757,
        "_internal_originalTime": 1689799535000
    },
    {
        "time": 1689799539000,
        "value": 139.758,
        "_internal_originalTime": 1689799539000
    },
    {
        "time": 1689799544000,
        "value": 139.747,
        "_internal_originalTime": 1689799544000
    },
    {
        "time": 1689799547000,
        "value": 139.757,
        "_internal_originalTime": 1689799547000
    },
    {
        "time": 1689799552000,
        "value": 139.756,
        "_internal_originalTime": 1689799552000
    },
    {
        "time": 1689799556000,
        "value": 139.75,
        "_internal_originalTime": 1689799556000
    },
    {
        "time": 1689799558000,
        "value": 139.745,
        "_internal_originalTime": 1689799558000
    },
    {
        "time": 1689799562000,
        "value": 139.749,
        "_internal_originalTime": 1689799562000
    },
    {
        "time": 1689799567000,
        "value": 139.752,
        "_internal_originalTime": 1689799567000
    },
    {
        "time": 1689799570000,
        "value": 139.753,
        "_internal_originalTime": 1689799570000
    },
    {
        "time": 1689799575000,
        "value": 139.751,
        "_internal_originalTime": 1689799575000
    },
    {
        "time": 1689799580000,
        "value": 139.752,
        "_internal_originalTime": 1689799580000
    },
    {
        "time": 1689799583000,
        "value": 139.741,
        "_internal_originalTime": 1689799583000
    },
    {
        "time": 1689799588000,
        "value": 139.74,
        "_internal_originalTime": 1689799588000
    },
    {
        "time": 1689799592000,
        "value": 139.742,
        "_internal_originalTime": 1689799592000
    },
    {
        "time": 1689799596000,
        "value": 139.744,
        "_internal_originalTime": 1689799596000
    },
    {
        "time": 1689799601000,
        "value": 139.741,
        "_internal_originalTime": 1689799601000
    },
    {
        "time": 1689799606000,
        "value": 139.744,
        "_internal_originalTime": 1689799606000
    },
    {
        "time": 1689799610000,
        "value": 139.743,
        "_internal_originalTime": 1689799610000
    },
    {
        "time": 1689799614000,
        "value": 139.742,
        "_internal_originalTime": 1689799614000
    },
    {
        "time": 1689799619000,
        "value": 139.745,
        "_internal_originalTime": 1689799619000
    },
    {
        "time": 1689799621000,
        "value": 139.745,
        "_internal_originalTime": 1689799621000
    },
    {
        "time": 1689799626000,
        "value": 139.751,
        "_internal_originalTime": 1689799626000
    },
    {
        "time": 1689799629000,
        "value": 139.741,
        "_internal_originalTime": 1689799629000
    },
    {
        "time": 1689799633000,
        "value": 139.734,
        "_internal_originalTime": 1689799633000
    },
    {
        "time": 1689799638000,
        "value": 139.74,
        "_internal_originalTime": 1689799638000
    },
    {
        "time": 1689799641000,
        "value": 139.732,
        "_internal_originalTime": 1689799641000
    },
    {
        "time": 1689799647000,
        "value": 139.741,
        "_internal_originalTime": 1689799647000
    },
    {
        "time": 1689799650000,
        "value": 139.739,
        "_internal_originalTime": 1689799650000
    },
    {
        "time": 1689799655000,
        "value": 139.747,
        "_internal_originalTime": 1689799655000
    },
    {
        "time": 1689799659000,
        "value": 139.746,
        "_internal_originalTime": 1689799659000
    },
    {
        "time": 1689799664000,
        "value": 139.748,
        "_internal_originalTime": 1689799664000
    },
    {
        "time": 1689799668000,
        "value": 139.737,
        "_internal_originalTime": 1689799668000
    },
    {
        "time": 1689799672000,
        "value": 139.747,
        "_internal_originalTime": 1689799672000
    },
    {
        "time": 1689799677000,
        "value": 139.731,
        "_internal_originalTime": 1689799677000
    },
    {
        "time": 1689799679000,
        "value": 139.734,
        "_internal_originalTime": 1689799679000
    },
    {
        "time": 1689799683000,
        "value": 139.727,
        "_internal_originalTime": 1689799683000
    },
    {
        "time": 1689799688000,
        "value": 139.729,
        "_internal_originalTime": 1689799688000
    },
    {
        "time": 1689799691000,
        "value": 139.724,
        "_internal_originalTime": 1689799691000
    },
    {
        "time": 1689799696000,
        "value": 139.728,
        "_internal_originalTime": 1689799696000
    },
    {
        "time": 1689799700000,
        "value": 139.725,
        "_internal_originalTime": 1689799700000
    },
    {
        "time": 1689799704000,
        "value": 139.726,
        "_internal_originalTime": 1689799704000
    },
    {
        "time": 1689799710000,
        "value": 139.729,
        "_internal_originalTime": 1689799710000
    },
    {
        "time": 1689799713000,
        "value": 139.73,
        "_internal_originalTime": 1689799713000
    },
    {
        "time": 1689799718000,
        "value": 139.727,
        "_internal_originalTime": 1689799718000
    },
    {
        "time": 1689799722000,
        "value": 139.728,
        "_internal_originalTime": 1689799722000
    },
    {
        "time": 1689799726000,
        "value": 139.736,
        "_internal_originalTime": 1689799726000
    },
    {
        "time": 1689799731000,
        "value": 139.738,
        "_internal_originalTime": 1689799731000
    },
    {
        "time": 1689799735000,
        "value": 139.729,
        "_internal_originalTime": 1689799735000
    },
    {
        "time": 1689799737000,
        "value": 139.727,
        "_internal_originalTime": 1689799737000
    },
    {
        "time": 1689799739000,
        "value": 139.727,
        "_internal_originalTime": 1689799739000
    },
    {
        "time": 1689799740000,
        "value": 139.736,
        "_internal_originalTime": 1689799740000
    },
    {
        "time": 1689799742000,
        "value": 139.726,
        "_internal_originalTime": 1689799742000
    },
    {
        "time": 1689799745000,
        "value": 139.73,
        "_internal_originalTime": 1689799745000
    },
    {
        "time": 1689799750000,
        "value": 139.721,
        "_internal_originalTime": 1689799750000
    },
    {
        "time": 1689799755000,
        "value": 139.726,
        "_internal_originalTime": 1689799755000
    },
    {
        "time": 1689799758000,
        "value": 139.719,
        "_internal_originalTime": 1689799758000
    },
    {
        "time": 1689799763000,
        "value": 139.705,
        "_internal_originalTime": 1689799763000
    },
    {
        "time": 1689799766000,
        "value": 139.704,
        "_internal_originalTime": 1689799766000
    },
    {
        "time": 1689799771000,
        "value": 139.707,
        "_internal_originalTime": 1689799771000
    },
    {
        "time": 1689799776000,
        "value": 139.705,
        "_internal_originalTime": 1689799776000
    },
    {
        "time": 1689799780000,
        "value": 139.706,
        "_internal_originalTime": 1689799780000
    },
    {
        "time": 1689799783000,
        "value": 139.697,
        "_internal_originalTime": 1689799783000
    },
    {
        "time": 1689799788000,
        "value": 139.696,
        "_internal_originalTime": 1689799788000
    },
    {
        "time": 1689799792000,
        "value": 139.682,
        "_internal_originalTime": 1689799792000
    },
    {
        "time": 1689799797000,
        "value": 139.685,
        "_internal_originalTime": 1689799797000
    },
    {
        "time": 1689799798000,
        "value": 139.675,
        "_internal_originalTime": 1689799798000
    },
    {
        "time": 1689799802000,
        "value": 139.688,
        "_internal_originalTime": 1689799802000
    },
    {
        "time": 1689799805000,
        "value": 139.694,
        "_internal_originalTime": 1689799805000
    },
    {
        "time": 1689799811000,
        "value": 139.688,
        "_internal_originalTime": 1689799811000
    },
    {
        "time": 1689799816000,
        "value": 139.702,
        "_internal_originalTime": 1689799816000
    },
    {
        "time": 1689799819000,
        "value": 139.709,
        "_internal_originalTime": 1689799819000
    },
    {
        "time": 1689799823000,
        "value": 139.7,
        "_internal_originalTime": 1689799823000
    },
    {
        "time": 1689799828000,
        "value": 139.707,
        "_internal_originalTime": 1689799828000
    },
    {
        "time": 1689799832000,
        "value": 139.707,
        "_internal_originalTime": 1689799832000
    },
    {
        "time": 1689799836000,
        "value": 139.699,
        "_internal_originalTime": 1689799836000
    },
    {
        "time": 1689799841000,
        "value": 139.697,
        "_internal_originalTime": 1689799841000
    },
    {
        "time": 1689799844000,
        "value": 139.696,
        "_internal_originalTime": 1689799844000
    },
    {
        "time": 1689799849000,
        "value": 139.7,
        "_internal_originalTime": 1689799849000
    },
    {
        "time": 1689799853000,
        "value": 139.7,
        "_internal_originalTime": 1689799853000
    },
    {
        "time": 1689799858000,
        "value": 139.7,
        "_internal_originalTime": 1689799858000
    },
    {
        "time": 1689799860000,
        "value": 139.695,
        "_internal_originalTime": 1689799860000
    },
    {
        "time": 1689799862000,
        "value": 139.698,
        "_internal_originalTime": 1689799862000
    },
    {
        "time": 1689799866000,
        "value": 139.692,
        "_internal_originalTime": 1689799866000
    },
    {
        "time": 1689799873000,
        "value": 139.691,
        "_internal_originalTime": 1689799873000
    },
    {
        "time": 1689799876000,
        "value": 139.691,
        "_internal_originalTime": 1689799876000
    },
    {
        "time": 1689799881000,
        "value": 139.689,
        "_internal_originalTime": 1689799881000
    },
    {
        "time": 1689799886000,
        "value": 139.694,
        "_internal_originalTime": 1689799886000
    },
    {
        "time": 1689799891000,
        "value": 139.691,
        "_internal_originalTime": 1689799891000
    },
    {
        "time": 1689799895000,
        "value": 139.695,
        "_internal_originalTime": 1689799895000
    },
    {
        "time": 1689799899000,
        "value": 139.697,
        "_internal_originalTime": 1689799899000
    },
    {
        "time": 1689799903000,
        "value": 139.694,
        "_internal_originalTime": 1689799903000
    },
    {
        "time": 1689799908000,
        "value": 139.695,
        "_internal_originalTime": 1689799908000
    },
    {
        "time": 1689799912000,
        "value": 139.691,
        "_internal_originalTime": 1689799912000
    },
    {
        "time": 1689799916000,
        "value": 139.69,
        "_internal_originalTime": 1689799916000
    },
    {
        "time": 1689799919000,
        "value": 139.691,
        "_internal_originalTime": 1689799919000
    },
    {
        "time": 1689799922000,
        "value": 139.69,
        "_internal_originalTime": 1689799922000
    },
    {
        "time": 1689799932000,
        "value": 139.685,
        "_internal_originalTime": 1689799932000
    },
    {
        "time": 1689799934000,
        "value": 139.685,
        "_internal_originalTime": 1689799934000
    },
    {
        "time": 1689799940000,
        "value": 139.684,
        "_internal_originalTime": 1689799940000
    },
    {
        "time": 1689799945000,
        "value": 139.694,
        "_internal_originalTime": 1689799945000
    },
    {
        "time": 1689799949000,
        "value": 139.69,
        "_internal_originalTime": 1689799949000
    },
    {
        "time": 1689799952000,
        "value": 139.691,
        "_internal_originalTime": 1689799952000
    },
    {
        "time": 1689799957000,
        "value": 139.693,
        "_internal_originalTime": 1689799957000
    },
    {
        "time": 1689799961000,
        "value": 139.694,
        "_internal_originalTime": 1689799961000
    },
    {
        "time": 1689799965000,
        "value": 139.692,
        "_internal_originalTime": 1689799965000
    },
    {
        "time": 1689799969000,
        "value": 139.696,
        "_internal_originalTime": 1689799969000
    },
    {
        "time": 1689799973000,
        "value": 139.7,
        "_internal_originalTime": 1689799973000
    },
    {
        "time": 1689799977000,
        "value": 139.704,
        "_internal_originalTime": 1689799977000
    },
    {
        "time": 1689799980000,
        "value": 139.705,
        "_internal_originalTime": 1689799980000
    },
    {
        "time": 1689799983000,
        "value": 139.699,
        "_internal_originalTime": 1689799983000
    },
    {
        "time": 1689799987000,
        "value": 139.703,
        "_internal_originalTime": 1689799987000
    },
    {
        "time": 1689799992000,
        "value": 139.709,
        "_internal_originalTime": 1689799992000
    },
    {
        "time": 1689799996000,
        "value": 139.71,
        "_internal_originalTime": 1689799996000
    },
    {
        "time": 1689800000000,
        "value": 139.708,
        "_internal_originalTime": 1689800000000
    },
    {
        "time": 1689800005000,
        "value": 139.705,
        "_internal_originalTime": 1689800005000
    },
    {
        "time": 1689800009000,
        "value": 139.707,
        "_internal_originalTime": 1689800009000
    },
    {
        "time": 1689800013000,
        "value": 139.711,
        "_internal_originalTime": 1689800013000
    },
    {
        "time": 1689800017000,
        "value": 139.71,
        "_internal_originalTime": 1689800017000
    },
    {
        "time": 1689800021000,
        "value": 139.714,
        "_internal_originalTime": 1689800021000
    },
    {
        "time": 1689800026000,
        "value": 139.714,
        "_internal_originalTime": 1689800026000
    },
    {
        "time": 1689800030000,
        "value": 139.714,
        "_internal_originalTime": 1689800030000
    },
    {
        "time": 1689800034000,
        "value": 139.713,
        "_internal_originalTime": 1689800034000
    },
    {
        "time": 1689800037000,
        "value": 139.716,
        "_internal_originalTime": 1689800037000
    },
    {
        "time": 1689800039000,
        "value": 139.715,
        "_internal_originalTime": 1689800039000
    },
    {
        "time": 1689800040000,
        "value": 139.71,
        "_internal_originalTime": 1689800040000
    },
    {
        "time": 1689800041000,
        "value": 139.712,
        "_internal_originalTime": 1689800041000
    },
    {
        "time": 1689800045000,
        "value": 139.712,
        "_internal_originalTime": 1689800045000
    },
    {
        "time": 1689800049000,
        "value": 139.715,
        "_internal_originalTime": 1689800049000
    },
    {
        "time": 1689800053000,
        "value": 139.71,
        "_internal_originalTime": 1689800053000
    },
    {
        "time": 1689800057000,
        "value": 139.713,
        "_internal_originalTime": 1689800057000
    },
    {
        "time": 1689800061000,
        "value": 139.713,
        "_internal_originalTime": 1689800061000
    },
    {
        "time": 1689800065000,
        "value": 139.71,
        "_internal_originalTime": 1689800065000
    },
    {
        "time": 1689800069000,
        "value": 139.708,
        "_internal_originalTime": 1689800069000
    },
    {
        "time": 1689800073000,
        "value": 139.714,
        "_internal_originalTime": 1689800073000
    },
    {
        "time": 1689800078000,
        "value": 139.714,
        "_internal_originalTime": 1689800078000
    },
    {
        "time": 1689800083000,
        "value": 139.713,
        "_internal_originalTime": 1689800083000
    },
    {
        "time": 1689800087000,
        "value": 139.707,
        "_internal_originalTime": 1689800087000
    },
    {
        "time": 1689800091000,
        "value": 139.704,
        "_internal_originalTime": 1689800091000
    },
    {
        "time": 1689800095000,
        "value": 139.705,
        "_internal_originalTime": 1689800095000
    },
    {
        "time": 1689800099000,
        "value": 139.704,
        "_internal_originalTime": 1689800099000
    },
    {
        "time": 1689800103000,
        "value": 139.702,
        "_internal_originalTime": 1689800103000
    },
    {
        "time": 1689800105000,
        "value": 139.698,
        "_internal_originalTime": 1689800105000
    },
    {
        "time": 1689800110000,
        "value": 139.7,
        "_internal_originalTime": 1689800110000
    },
    {
        "time": 1689800113000,
        "value": 139.695,
        "_internal_originalTime": 1689800113000
    },
    {
        "time": 1689800118000,
        "value": 139.688,
        "_internal_originalTime": 1689800118000
    },
    {
        "time": 1689800122000,
        "value": 139.692,
        "_internal_originalTime": 1689800122000
    },
    {
        "time": 1689800126000,
        "value": 139.687,
        "_internal_originalTime": 1689800126000
    },
    {
        "time": 1689800130000,
        "value": 139.688,
        "_internal_originalTime": 1689800130000
    },
    {
        "time": 1689800135000,
        "value": 139.686,
        "_internal_originalTime": 1689800135000
    },
    {
        "time": 1689800139000,
        "value": 139.692,
        "_internal_originalTime": 1689800139000
    },
    {
        "time": 1689800143000,
        "value": 139.697,
        "_internal_originalTime": 1689800143000
    },
    {
        "time": 1689800147000,
        "value": 139.703,
        "_internal_originalTime": 1689800147000
    },
    {
        "time": 1689800151000,
        "value": 139.698,
        "_internal_originalTime": 1689800151000
    },
    {
        "time": 1689800154000,
        "value": 139.7,
        "_internal_originalTime": 1689800154000
    },
    {
        "time": 1689800158000,
        "value": 139.694,
        "_internal_originalTime": 1689800158000
    },
    {
        "time": 1689800161000,
        "value": 139.696,
        "_internal_originalTime": 1689800161000
    },
    {
        "time": 1689800165000,
        "value": 139.698,
        "_internal_originalTime": 1689800165000
    },
    {
        "time": 1689800168000,
        "value": 139.696,
        "_internal_originalTime": 1689800168000
    },
    {
        "time": 1689800173000,
        "value": 139.699,
        "_internal_originalTime": 1689800173000
    },
    {
        "time": 1689800176000,
        "value": 139.698,
        "_internal_originalTime": 1689800176000
    },
    {
        "time": 1689800180000,
        "value": 139.696,
        "_internal_originalTime": 1689800180000
    },
    {
        "time": 1689800185000,
        "value": 139.696,
        "_internal_originalTime": 1689800185000
    },
    {
        "time": 1689800189000,
        "value": 139.698,
        "_internal_originalTime": 1689800189000
    },
    {
        "time": 1689800193000,
        "value": 139.696,
        "_internal_originalTime": 1689800193000
    },
    {
        "time": 1689800198000,
        "value": 139.695,
        "_internal_originalTime": 1689800198000
    },
    {
        "time": 1689800202000,
        "value": 139.696,
        "_internal_originalTime": 1689800202000
    },
    {
        "time": 1689800207000,
        "value": 139.688,
        "_internal_originalTime": 1689800207000
    },
    {
        "time": 1689800210000,
        "value": 139.69,
        "_internal_originalTime": 1689800210000
    },
    {
        "time": 1689800213000,
        "value": 139.693,
        "_internal_originalTime": 1689800213000
    },
    {
        "time": 1689800219000,
        "value": 139.688,
        "_internal_originalTime": 1689800219000
    },
    {
        "time": 1689800221000,
        "value": 139.682,
        "_internal_originalTime": 1689800221000
    },
    {
        "time": 1689800225000,
        "value": 139.687,
        "_internal_originalTime": 1689800225000
    },
    {
        "time": 1689800228000,
        "value": 139.686,
        "_internal_originalTime": 1689800228000
    },
    {
        "time": 1689800234000,
        "value": 139.686,
        "_internal_originalTime": 1689800234000
    },
    {
        "time": 1689800238000,
        "value": 139.687,
        "_internal_originalTime": 1689800238000
    },
    {
        "time": 1689800242000,
        "value": 139.684,
        "_internal_originalTime": 1689800242000
    },
    {
        "time": 1689800246000,
        "value": 139.685,
        "_internal_originalTime": 1689800246000
    },
    {
        "time": 1689800250000,
        "value": 139.683,
        "_internal_originalTime": 1689800250000
    },
    {
        "time": 1689800255000,
        "value": 139.684,
        "_internal_originalTime": 1689800255000
    },
    {
        "time": 1689800259000,
        "value": 139.687,
        "_internal_originalTime": 1689800259000
    },
    {
        "time": 1689800264000,
        "value": 139.685,
        "_internal_originalTime": 1689800264000
    },
    {
        "time": 1689800267000,
        "value": 139.688,
        "_internal_originalTime": 1689800267000
    },
    {
        "time": 1689800271000,
        "value": 139.674,
        "_internal_originalTime": 1689800271000
    },
    {
        "time": 1689800276000,
        "value": 139.68,
        "_internal_originalTime": 1689800276000
    },
    {
        "time": 1689800279000,
        "value": 139.675,
        "_internal_originalTime": 1689800279000
    },
    {
        "time": 1689800282000,
        "value": 139.676,
        "_internal_originalTime": 1689800282000
    },
    {
        "time": 1689800286000,
        "value": 139.67,
        "_internal_originalTime": 1689800286000
    },
    {
        "time": 1689800290000,
        "value": 139.671,
        "_internal_originalTime": 1689800290000
    },
    {
        "time": 1689800294000,
        "value": 139.666,
        "_internal_originalTime": 1689800294000
    },
    {
        "time": 1689800298000,
        "value": 139.67,
        "_internal_originalTime": 1689800298000
    },
    {
        "time": 1689800303000,
        "value": 139.667,
        "_internal_originalTime": 1689800303000
    },
    {
        "time": 1689800306000,
        "value": 139.667,
        "_internal_originalTime": 1689800306000
    },
    {
        "time": 1689800311000,
        "value": 139.671,
        "_internal_originalTime": 1689800311000
    },
    {
        "time": 1689800314000,
        "value": 139.669,
        "_internal_originalTime": 1689800314000
    },
    {
        "time": 1689800319000,
        "value": 139.67,
        "_internal_originalTime": 1689800319000
    },
    {
        "time": 1689800323000,
        "value": 139.676,
        "_internal_originalTime": 1689800323000
    },
    {
        "time": 1689800326000,
        "value": 139.663,
        "_internal_originalTime": 1689800326000
    },
    {
        "time": 1689800331000,
        "value": 139.668,
        "_internal_originalTime": 1689800331000
    },
    {
        "time": 1689800334000,
        "value": 139.67,
        "_internal_originalTime": 1689800334000
    },
    {
        "time": 1689800338000,
        "value": 139.666,
        "_internal_originalTime": 1689800338000
    },
    {
        "time": 1689800339000,
        "value": 139.67,
        "_internal_originalTime": 1689800339000
    },
    {
        "time": 1689800341000,
        "value": 139.67,
        "_internal_originalTime": 1689800341000
    },
    {
        "time": 1689800342000,
        "value": 139.669,
        "_internal_originalTime": 1689800342000
    },
    {
        "time": 1689800344000,
        "value": 139.667,
        "_internal_originalTime": 1689800344000
    },
    {
        "time": 1689800348000,
        "value": 139.668,
        "_internal_originalTime": 1689800348000
    },
    {
        "time": 1689800353000,
        "value": 139.667,
        "_internal_originalTime": 1689800353000
    },
    {
        "time": 1689800356000,
        "value": 139.672,
        "_internal_originalTime": 1689800356000
    },
    {
        "time": 1689800362000,
        "value": 139.672,
        "_internal_originalTime": 1689800362000
    },
    {
        "time": 1689800365000,
        "value": 139.667,
        "_internal_originalTime": 1689800365000
    },
    {
        "time": 1689800369000,
        "value": 139.662,
        "_internal_originalTime": 1689800369000
    },
    {
        "time": 1689800374000,
        "value": 139.66,
        "_internal_originalTime": 1689800374000
    },
    {
        "time": 1689800378000,
        "value": 139.694,
        "_internal_originalTime": 1689800378000
    },
    {
        "time": 1689800382000,
        "value": 139.636,
        "_internal_originalTime": 1689800382000
    },
    {
        "time": 1689800386000,
        "value": 139.636,
        "_internal_originalTime": 1689800386000
    },
    {
        "time": 1689800389000,
        "value": 139.667,
        "_internal_originalTime": 1689800389000
    },
    {
        "time": 1689800394000,
        "value": 139.669,
        "_internal_originalTime": 1689800394000
    },
    {
        "time": 1689800398000,
        "value": 139.599,
        "_internal_originalTime": 1689800398000
    },
    {
        "time": 1689800400000,
        "value": 139.571,
        "_internal_originalTime": 1689800400000
    },
    {
        "time": 1689800401000,
        "value": 139.595,
        "_internal_originalTime": 1689800401000
    },
    {
        "time": 1689800405000,
        "value": 139.57,
        "_internal_originalTime": 1689800405000
    },
    {
        "time": 1689800417000,
        "value": 139.605,
        "_internal_originalTime": 1689800417000
    },
    {
        "time": 1689800421000,
        "value": 139.585,
        "_internal_originalTime": 1689800421000
    },
    {
        "time": 1689800433000,
        "value": 139.605,
        "_internal_originalTime": 1689800433000
    },
    {
        "time": 1689800444000,
        "value": 139.59,
        "_internal_originalTime": 1689800444000
    },
    {
        "time": 1689800478000,
        "value": 139.593,
        "_internal_originalTime": 1689800478000
    },
    {
        "time": 1689800522000,
        "value": 139.59,
        "_internal_originalTime": 1689800522000
    },
    {
        "time": 1689800527000,
        "value": 139.568,
        "_internal_originalTime": 1689800527000
    },
    {
        "time": 1689800536000,
        "value": 139.59,
        "_internal_originalTime": 1689800536000
    },
    {
        "time": 1689800550000,
        "value": 139.581,
        "_internal_originalTime": 1689800550000
    },
    {
        "time": 1689800557000,
        "value": 139.595,
        "_internal_originalTime": 1689800557000
    },
    {
        "time": 1689800584000,
        "value": 139.589,
        "_internal_originalTime": 1689800584000
    },
    {
        "time": 1689800588000,
        "value": 139.615,
        "_internal_originalTime": 1689800588000
    },
    {
        "time": 1689800590000,
        "value": 139.605,
        "_internal_originalTime": 1689800590000
    },
    {
        "time": 1689800596000,
        "value": 139.616,
        "_internal_originalTime": 1689800596000
    },
    {
        "time": 1689800597000,
        "value": 139.61,
        "_internal_originalTime": 1689800597000
    },
    {
        "time": 1689800612000,
        "value": 139.611,
        "_internal_originalTime": 1689800612000
    },
    {
        "time": 1689800613000,
        "value": 139.635,
        "_internal_originalTime": 1689800613000
    },
    {
        "time": 1689800620000,
        "value": 139.617,
        "_internal_originalTime": 1689800620000
    },
    {
        "time": 1689800644000,
        "value": 139.636,
        "_internal_originalTime": 1689800644000
    },
    {
        "time": 1689800651000,
        "value": 139.636,
        "_internal_originalTime": 1689800651000
    },
    {
        "time": 1689800661000,
        "value": 139.636,
        "_internal_originalTime": 1689800661000
    },
    {
        "time": 1689800673000,
        "value": 139.658,
        "_internal_originalTime": 1689800673000
    },
    {
        "time": 1689800691000,
        "value": 139.636,
        "_internal_originalTime": 1689800691000
    },
    {
        "time": 1689800702000,
        "value": 139.633,
        "_internal_originalTime": 1689800702000
    },
    {
        "time": 1689800705000,
        "value": 139.6,
        "_internal_originalTime": 1689800705000
    },
    {
        "time": 1689800713000,
        "value": 139.603,
        "_internal_originalTime": 1689800713000
    },
    {
        "time": 1689800719000,
        "value": 139.61,
        "_internal_originalTime": 1689800719000
    },
    {
        "time": 1689800721000,
        "value": 139.622,
        "_internal_originalTime": 1689800721000
    },
    {
        "time": 1689800729000,
        "value": 139.628,
        "_internal_originalTime": 1689800729000
    },
    {
        "time": 1689800733000,
        "value": 139.588,
        "_internal_originalTime": 1689800733000
    },
    {
        "time": 1689800753000,
        "value": 139.612,
        "_internal_originalTime": 1689800753000
    },
    {
        "time": 1689800757000,
        "value": 139.62,
        "_internal_originalTime": 1689800757000
    },
    {
        "time": 1689800763000,
        "value": 139.626,
        "_internal_originalTime": 1689800763000
    },
    {
        "time": 1689800768000,
        "value": 139.612,
        "_internal_originalTime": 1689800768000
    },
    {
        "time": 1689800771000,
        "value": 139.615,
        "_internal_originalTime": 1689800771000
    },
    {
        "time": 1689800783000,
        "value": 139.614,
        "_internal_originalTime": 1689800783000
    },
    {
        "time": 1689800790000,
        "value": 139.619,
        "_internal_originalTime": 1689800790000
    },
    {
        "time": 1689800793000,
        "value": 139.629,
        "_internal_originalTime": 1689800793000
    },
    {
        "time": 1689800803000,
        "value": 139.62,
        "_internal_originalTime": 1689800803000
    },
    {
        "time": 1689800813000,
        "value": 139.62,
        "_internal_originalTime": 1689800813000
    },
    {
        "time": 1689800825000,
        "value": 139.608,
        "_internal_originalTime": 1689800825000
    },
    {
        "time": 1689800828000,
        "value": 139.602,
        "_internal_originalTime": 1689800828000
    },
    {
        "time": 1689800833000,
        "value": 139.617,
        "_internal_originalTime": 1689800833000
    },
    {
        "time": 1689800842000,
        "value": 139.602,
        "_internal_originalTime": 1689800842000
    },
    {
        "time": 1689800848000,
        "value": 139.602,
        "_internal_originalTime": 1689800848000
    },
    {
        "time": 1689800853000,
        "value": 139.603,
        "_internal_originalTime": 1689800853000
    },
    {
        "time": 1689800859000,
        "value": 139.604,
        "_internal_originalTime": 1689800859000
    },
    {
        "time": 1689800873000,
        "value": 139.603,
        "_internal_originalTime": 1689800873000
    },
    {
        "time": 1689800883000,
        "value": 139.628,
        "_internal_originalTime": 1689800883000
    },
    {
        "time": 1689800894000,
        "value": 139.603,
        "_internal_originalTime": 1689800894000
    },
    {
        "time": 1689800901000,
        "value": 139.615,
        "_internal_originalTime": 1689800901000
    },
    {
        "time": 1689800913000,
        "value": 139.617,
        "_internal_originalTime": 1689800913000
    },
    {
        "time": 1689800916000,
        "value": 139.602,
        "_internal_originalTime": 1689800916000
    },
    {
        "time": 1689800924000,
        "value": 139.602,
        "_internal_originalTime": 1689800924000
    },
    {
        "time": 1689800929000,
        "value": 139.628,
        "_internal_originalTime": 1689800929000
    },
    {
        "time": 1689800943000,
        "value": 139.617,
        "_internal_originalTime": 1689800943000
    },
    {
        "time": 1689800952000,
        "value": 139.628,
        "_internal_originalTime": 1689800952000
    },
    {
        "time": 1689800958000,
        "value": 139.628,
        "_internal_originalTime": 1689800958000
    },
    {
        "time": 1689800961000,
        "value": 139.628,
        "_internal_originalTime": 1689800961000
    },
    {
        "time": 1689800976000,
        "value": 139.628,
        "_internal_originalTime": 1689800976000
    },
    {
        "time": 1689800983000,
        "value": 139.628,
        "_internal_originalTime": 1689800983000
    },
    {
        "time": 1689800985000,
        "value": 139.629,
        "_internal_originalTime": 1689800985000
    },
    {
        "time": 1689800992000,
        "value": 139.628,
        "_internal_originalTime": 1689800992000
    },
    {
        "time": 1689801003000,
        "value": 139.619,
        "_internal_originalTime": 1689801003000
    },
    {
        "time": 1689801009000,
        "value": 139.613,
        "_internal_originalTime": 1689801009000
    },
    {
        "time": 1689801013000,
        "value": 139.62,
        "_internal_originalTime": 1689801013000
    },
    {
        "time": 1689801020000,
        "value": 139.621,
        "_internal_originalTime": 1689801020000
    },
    {
        "time": 1689801033000,
        "value": 139.626,
        "_internal_originalTime": 1689801033000
    },
    {
        "time": 1689801039000,
        "value": 139.62,
        "_internal_originalTime": 1689801039000
    },
    {
        "time": 1689801049000,
        "value": 139.621,
        "_internal_originalTime": 1689801049000
    },
    {
        "time": 1689801057000,
        "value": 139.62,
        "_internal_originalTime": 1689801057000
    },
    {
        "time": 1689801065000,
        "value": 139.619,
        "_internal_originalTime": 1689801065000
    },
    {
        "time": 1689801078000,
        "value": 139.618,
        "_internal_originalTime": 1689801078000
    },
    {
        "time": 1689801081000,
        "value": 139.618,
        "_internal_originalTime": 1689801081000
    },
    {
        "time": 1689801090000,
        "value": 139.619,
        "_internal_originalTime": 1689801090000
    },
    {
        "time": 1689801097000,
        "value": 139.628,
        "_internal_originalTime": 1689801097000
    },
    {
        "time": 1689801100000,
        "value": 139.626,
        "_internal_originalTime": 1689801100000
    },
    {
        "time": 1689801109000,
        "value": 139.628,
        "_internal_originalTime": 1689801109000
    },
    {
        "time": 1689801111000,
        "value": 139.628,
        "_internal_originalTime": 1689801111000
    },
    {
        "time": 1689801127000,
        "value": 139.628,
        "_internal_originalTime": 1689801127000
    },
    {
        "time": 1689801137000,
        "value": 139.628,
        "_internal_originalTime": 1689801137000
    },
    {
        "time": 1689801141000,
        "value": 139.629,
        "_internal_originalTime": 1689801141000
    },
    {
        "time": 1689801146000,
        "value": 139.629,
        "_internal_originalTime": 1689801146000
    },
    {
        "time": 1689801154000,
        "value": 139.63,
        "_internal_originalTime": 1689801154000
    },
    {
        "time": 1689801166000,
        "value": 139.628,
        "_internal_originalTime": 1689801166000
    },
    {
        "time": 1689801181000,
        "value": 139.63,
        "_internal_originalTime": 1689801181000
    },
    {
        "time": 1689801183000,
        "value": 139.63,
        "_internal_originalTime": 1689801183000
    },
    {
        "time": 1689801193000,
        "value": 139.631,
        "_internal_originalTime": 1689801193000
    },
    {
        "time": 1689801195000,
        "value": 139.63,
        "_internal_originalTime": 1689801195000
    },
    {
        "time": 1689801205000,
        "value": 139.611,
        "_internal_originalTime": 1689801205000
    },
    {
        "time": 1689801206000,
        "value": 139.614,
        "_internal_originalTime": 1689801206000
    },
    {
        "time": 1689801216000,
        "value": 139.611,
        "_internal_originalTime": 1689801216000
    },
    {
        "time": 1689801223000,
        "value": 139.613,
        "_internal_originalTime": 1689801223000
    },
    {
        "time": 1689801231000,
        "value": 139.63,
        "_internal_originalTime": 1689801231000
    },
    {
        "time": 1689801237000,
        "value": 139.631,
        "_internal_originalTime": 1689801237000
    },
    {
        "time": 1689801238000,
        "value": 139.631,
        "_internal_originalTime": 1689801238000
    },
    {
        "time": 1689801240000,
        "value": 139.632,
        "_internal_originalTime": 1689801240000
    },
    {
        "time": 1689801245000,
        "value": 139.64,
        "_internal_originalTime": 1689801245000
    },
    {
        "time": 1689801252000,
        "value": 139.63,
        "_internal_originalTime": 1689801252000
    },
    {
        "time": 1689801295000,
        "value": 139.63,
        "_internal_originalTime": 1689801295000
    },
    {
        "time": 1689801301000,
        "value": 139.617,
        "_internal_originalTime": 1689801301000
    },
    {
        "time": 1689801310000,
        "value": 139.63,
        "_internal_originalTime": 1689801310000
    },
    {
        "time": 1689801313000,
        "value": 139.63,
        "_internal_originalTime": 1689801313000
    },
    {
        "time": 1689801331000,
        "value": 139.628,
        "_internal_originalTime": 1689801331000
    },
    {
        "time": 1689801336000,
        "value": 139.63,
        "_internal_originalTime": 1689801336000
    },
    {
        "time": 1689801351000,
        "value": 139.629,
        "_internal_originalTime": 1689801351000
    },
    {
        "time": 1689801373000,
        "value": 139.628,
        "_internal_originalTime": 1689801373000
    },
    {
        "time": 1689801382000,
        "value": 139.628,
        "_internal_originalTime": 1689801382000
    },
    {
        "time": 1689801398000,
        "value": 139.63,
        "_internal_originalTime": 1689801398000
    },
    {
        "time": 1689801414000,
        "value": 139.629,
        "_internal_originalTime": 1689801414000
    },
    {
        "time": 1689801432000,
        "value": 139.62,
        "_internal_originalTime": 1689801432000
    },
    {
        "time": 1689801444000,
        "value": 139.628,
        "_internal_originalTime": 1689801444000
    },
    {
        "time": 1689801464000,
        "value": 139.627,
        "_internal_originalTime": 1689801464000
    },
    {
        "time": 1689801474000,
        "value": 139.629,
        "_internal_originalTime": 1689801474000
    },
    {
        "time": 1689801493000,
        "value": 139.628,
        "_internal_originalTime": 1689801493000
    },
    {
        "time": 1689801500000,
        "value": 139.616,
        "_internal_originalTime": 1689801500000
    },
    {
        "time": 1689801520000,
        "value": 139.625,
        "_internal_originalTime": 1689801520000
    },
    {
        "time": 1689801530000,
        "value": 139.628,
        "_internal_originalTime": 1689801530000
    },
    {
        "time": 1689801553000,
        "value": 139.628,
        "_internal_originalTime": 1689801553000
    },
    {
        "time": 1689801567000,
        "value": 139.629,
        "_internal_originalTime": 1689801567000
    },
    {
        "time": 1689801581000,
        "value": 139.609,
        "_internal_originalTime": 1689801581000
    },
    {
        "time": 1689801590000,
        "value": 139.628,
        "_internal_originalTime": 1689801590000
    },
    {
        "time": 1689801612000,
        "value": 139.628,
        "_internal_originalTime": 1689801612000
    },
    {
        "time": 1689801631000,
        "value": 139.627,
        "_internal_originalTime": 1689801631000
    },
    {
        "time": 1689801644000,
        "value": 139.628,
        "_internal_originalTime": 1689801644000
    },
    {
        "time": 1689801653000,
        "value": 139.628,
        "_internal_originalTime": 1689801653000
    },
    {
        "time": 1689801674000,
        "value": 139.606,
        "_internal_originalTime": 1689801674000
    },
    {
        "time": 1689801692000,
        "value": 139.628,
        "_internal_originalTime": 1689801692000
    },
    {
        "time": 1689801705000,
        "value": 139.611,
        "_internal_originalTime": 1689801705000
    },
    {
        "time": 1689801716000,
        "value": 139.628,
        "_internal_originalTime": 1689801716000
    },
    {
        "time": 1689801737000,
        "value": 139.631,
        "_internal_originalTime": 1689801737000
    },
    {
        "time": 1689801752000,
        "value": 139.622,
        "_internal_originalTime": 1689801752000
    },
    {
        "time": 1689801767000,
        "value": 139.617,
        "_internal_originalTime": 1689801767000
    },
    {
        "time": 1689801781000,
        "value": 139.628,
        "_internal_originalTime": 1689801781000
    },
    {
        "time": 1689801799000,
        "value": 139.631,
        "_internal_originalTime": 1689801799000
    },
    {
        "time": 1689801804000,
        "value": 139.63,
        "_internal_originalTime": 1689801804000
    },
    {
        "time": 1689801830000,
        "value": 139.635,
        "_internal_originalTime": 1689801830000
    },
    {
        "time": 1689801836000,
        "value": 139.624,
        "_internal_originalTime": 1689801836000
    },
    {
        "time": 1689801840000,
        "value": 139.631,
        "_internal_originalTime": 1689801840000
    },
    {
        "time": 1689801841000,
        "value": 139.625,
        "_internal_originalTime": 1689801841000
    },
    {
        "time": 1689801853000,
        "value": 139.63,
        "_internal_originalTime": 1689801853000
    },
    {
        "time": 1689801869000,
        "value": 139.625,
        "_internal_originalTime": 1689801869000
    },
    {
        "time": 1689801882000,
        "value": 139.636,
        "_internal_originalTime": 1689801882000
    },
    {
        "time": 1689801897000,
        "value": 139.627,
        "_internal_originalTime": 1689801897000
    },
    {
        "time": 1689801915000,
        "value": 139.622,
        "_internal_originalTime": 1689801915000
    },
    {
        "time": 1689801930000,
        "value": 139.621,
        "_internal_originalTime": 1689801930000
    },
    {
        "time": 1689801945000,
        "value": 139.618,
        "_internal_originalTime": 1689801945000
    },
    {
        "time": 1689801953000,
        "value": 139.614,
        "_internal_originalTime": 1689801953000
    },
    {
        "time": 1689801977000,
        "value": 139.616,
        "_internal_originalTime": 1689801977000
    },
    {
        "time": 1689801992000,
        "value": 139.621,
        "_internal_originalTime": 1689801992000
    },
    {
        "time": 1689802007000,
        "value": 139.617,
        "_internal_originalTime": 1689802007000
    },
    {
        "time": 1689802021000,
        "value": 139.624,
        "_internal_originalTime": 1689802021000
    },
    {
        "time": 1689802034000,
        "value": 139.618,
        "_internal_originalTime": 1689802034000
    },
    {
        "time": 1689802046000,
        "value": 139.615,
        "_internal_originalTime": 1689802046000
    },
    {
        "time": 1689802068000,
        "value": 139.621,
        "_internal_originalTime": 1689802068000
    },
    {
        "time": 1689802084000,
        "value": 139.617,
        "_internal_originalTime": 1689802084000
    },
    {
        "time": 1689802100000,
        "value": 139.622,
        "_internal_originalTime": 1689802100000
    },
    {
        "time": 1689802110000,
        "value": 139.622,
        "_internal_originalTime": 1689802110000
    },
    {
        "time": 1689802122000,
        "value": 139.633,
        "_internal_originalTime": 1689802122000
    },
    {
        "time": 1689802133000,
        "value": 139.623,
        "_internal_originalTime": 1689802133000
    },
    {
        "time": 1689802139000,
        "value": 139.617,
        "_internal_originalTime": 1689802139000
    },
    {
        "time": 1689802152000,
        "value": 139.636,
        "_internal_originalTime": 1689802152000
    },
    {
        "time": 1689802169000,
        "value": 139.618,
        "_internal_originalTime": 1689802169000
    },
    {
        "time": 1689802182000,
        "value": 139.624,
        "_internal_originalTime": 1689802182000
    },
    {
        "time": 1689802200000,
        "value": 139.621,
        "_internal_originalTime": 1689802200000
    },
    {
        "time": 1689802212000,
        "value": 139.628,
        "_internal_originalTime": 1689802212000
    },
    {
        "time": 1689802229000,
        "value": 139.617,
        "_internal_originalTime": 1689802229000
    },
    {
        "time": 1689802242000,
        "value": 139.63,
        "_internal_originalTime": 1689802242000
    },
    {
        "time": 1689802258000,
        "value": 139.615,
        "_internal_originalTime": 1689802258000
    },
    {
        "time": 1689802273000,
        "value": 139.616,
        "_internal_originalTime": 1689802273000
    },
    {
        "time": 1689802283000,
        "value": 139.619,
        "_internal_originalTime": 1689802283000
    },
    {
        "time": 1689802305000,
        "value": 139.616,
        "_internal_originalTime": 1689802305000
    },
    {
        "time": 1689802317000,
        "value": 139.625,
        "_internal_originalTime": 1689802317000
    },
    {
        "time": 1689802336000,
        "value": 139.618,
        "_internal_originalTime": 1689802336000
    },
    {
        "time": 1689802348000,
        "value": 139.614,
        "_internal_originalTime": 1689802348000
    },
    {
        "time": 1689802366000,
        "value": 139.622,
        "_internal_originalTime": 1689802366000
    },
    {
        "time": 1689802381000,
        "value": 139.618,
        "_internal_originalTime": 1689802381000
    },
    {
        "time": 1689802396000,
        "value": 139.613,
        "_internal_originalTime": 1689802396000
    },
    {
        "time": 1689802411000,
        "value": 139.612,
        "_internal_originalTime": 1689802411000
    },
    {
        "time": 1689802426000,
        "value": 139.612,
        "_internal_originalTime": 1689802426000
    },
    {
        "time": 1689802432000,
        "value": 139.617,
        "_internal_originalTime": 1689802432000
    },
    {
        "time": 1689802440000,
        "value": 139.619,
        "_internal_originalTime": 1689802440000
    },
    {
        "time": 1689802450000,
        "value": 139.616,
        "_internal_originalTime": 1689802450000
    },
    {
        "time": 1689802471000,
        "value": 139.616,
        "_internal_originalTime": 1689802471000
    },
    {
        "time": 1689802485000,
        "value": 139.607,
        "_internal_originalTime": 1689802485000
    },
    {
        "time": 1689802500000,
        "value": 139.609,
        "_internal_originalTime": 1689802500000
    },
    {
        "time": 1689802511000,
        "value": 139.609,
        "_internal_originalTime": 1689802511000
    },
    {
        "time": 1689802532000,
        "value": 139.546,
        "_internal_originalTime": 1689802532000
    },
    {
        "time": 1689802547000,
        "value": 139.54,
        "_internal_originalTime": 1689802547000
    },
    {
        "time": 1689802561000,
        "value": 139.543,
        "_internal_originalTime": 1689802561000
    },
    {
        "time": 1689802573000,
        "value": 139.528,
        "_internal_originalTime": 1689802573000
    },
    {
        "time": 1689802590000,
        "value": 139.555,
        "_internal_originalTime": 1689802590000
    },
    {
        "time": 1689802607000,
        "value": 139.583,
        "_internal_originalTime": 1689802607000
    },
    {
        "time": 1689802622000,
        "value": 139.565,
        "_internal_originalTime": 1689802622000
    },
    {
        "time": 1689802638000,
        "value": 139.596,
        "_internal_originalTime": 1689802638000
    },
    {
        "time": 1689802653000,
        "value": 139.589,
        "_internal_originalTime": 1689802653000
    },
    {
        "time": 1689802668000,
        "value": 139.588,
        "_internal_originalTime": 1689802668000
    },
    {
        "time": 1689802680000,
        "value": 139.603,
        "_internal_originalTime": 1689802680000
    },
    {
        "time": 1689802697000,
        "value": 139.579,
        "_internal_originalTime": 1689802697000
    },
    {
        "time": 1689802714000,
        "value": 139.608,
        "_internal_originalTime": 1689802714000
    },
    {
        "time": 1689802730000,
        "value": 139.567,
        "_internal_originalTime": 1689802730000
    },
    {
        "time": 1689802739000,
        "value": 139.569,
        "_internal_originalTime": 1689802739000
    },
    {
        "time": 1689802740000,
        "value": 139.577,
        "_internal_originalTime": 1689802740000
    },
    {
        "time": 1689802748000,
        "value": 139.568,
        "_internal_originalTime": 1689802748000
    },
    {
        "time": 1689802767000,
        "value": 139.579,
        "_internal_originalTime": 1689802767000
    },
    {
        "time": 1689802783000,
        "value": 139.57,
        "_internal_originalTime": 1689802783000
    },
    {
        "time": 1689802792000,
        "value": 139.532,
        "_internal_originalTime": 1689802792000
    },
    {
        "time": 1689802810000,
        "value": 139.595,
        "_internal_originalTime": 1689802810000
    },
    {
        "time": 1689802821000,
        "value": 139.558,
        "_internal_originalTime": 1689802821000
    },
    {
        "time": 1689802837000,
        "value": 139.569,
        "_internal_originalTime": 1689802837000
    },
    {
        "time": 1689802859000,
        "value": 139.572,
        "_internal_originalTime": 1689802859000
    },
    {
        "time": 1689802873000,
        "value": 139.588,
        "_internal_originalTime": 1689802873000
    },
    {
        "time": 1689802883000,
        "value": 139.56,
        "_internal_originalTime": 1689802883000
    },
    {
        "time": 1689802904000,
        "value": 139.594,
        "_internal_originalTime": 1689802904000
    },
    {
        "time": 1689802917000,
        "value": 139.584,
        "_internal_originalTime": 1689802917000
    },
    {
        "time": 1689802926000,
        "value": 139.588,
        "_internal_originalTime": 1689802926000
    },
    {
        "time": 1689802945000,
        "value": 139.574,
        "_internal_originalTime": 1689802945000
    },
    {
        "time": 1689802964000,
        "value": 139.593,
        "_internal_originalTime": 1689802964000
    },
    {
        "time": 1689802976000,
        "value": 139.581,
        "_internal_originalTime": 1689802976000
    },
    {
        "time": 1689802991000,
        "value": 139.569,
        "_internal_originalTime": 1689802991000
    },
    {
        "time": 1689803007000,
        "value": 139.579,
        "_internal_originalTime": 1689803007000
    },
    {
        "time": 1689803024000,
        "value": 139.597,
        "_internal_originalTime": 1689803024000
    },
    {
        "time": 1689803038000,
        "value": 139.586,
        "_internal_originalTime": 1689803038000
    },
    {
        "time": 1689803056000,
        "value": 139.603,
        "_internal_originalTime": 1689803056000
    },
    {
        "time": 1689803071000,
        "value": 139.599,
        "_internal_originalTime": 1689803071000
    },
    {
        "time": 1689803087000,
        "value": 139.565,
        "_internal_originalTime": 1689803087000
    },
    {
        "time": 1689803102000,
        "value": 139.579,
        "_internal_originalTime": 1689803102000
    },
    {
        "time": 1689803114000,
        "value": 139.58,
        "_internal_originalTime": 1689803114000
    },
    {
        "time": 1689803132000,
        "value": 139.581,
        "_internal_originalTime": 1689803132000
    },
    {
        "time": 1689803139000,
        "value": 139.591,
        "_internal_originalTime": 1689803139000
    },
    {
        "time": 1689803162000,
        "value": 139.564,
        "_internal_originalTime": 1689803162000
    },
    {
        "time": 1689803177000,
        "value": 139.554,
        "_internal_originalTime": 1689803177000
    },
    {
        "time": 1689803184000,
        "value": 139.552,
        "_internal_originalTime": 1689803184000
    },
    {
        "time": 1689803206000,
        "value": 139.551,
        "_internal_originalTime": 1689803206000
    },
    {
        "time": 1689803215000,
        "value": 139.578,
        "_internal_originalTime": 1689803215000
    },
    {
        "time": 1689803234000,
        "value": 139.578,
        "_internal_originalTime": 1689803234000
    },
    {
        "time": 1689803242000,
        "value": 139.549,
        "_internal_originalTime": 1689803242000
    },
    {
        "time": 1689803263000,
        "value": 139.575,
        "_internal_originalTime": 1689803263000
    },
    {
        "time": 1689803275000,
        "value": 139.574,
        "_internal_originalTime": 1689803275000
    },
    {
        "time": 1689803299000,
        "value": 139.576,
        "_internal_originalTime": 1689803299000
    },
    {
        "time": 1689803314000,
        "value": 139.553,
        "_internal_originalTime": 1689803314000
    },
    {
        "time": 1689803323000,
        "value": 139.577,
        "_internal_originalTime": 1689803323000
    },
    {
        "time": 1689803337000,
        "value": 139.552,
        "_internal_originalTime": 1689803337000
    },
    {
        "time": 1689803354000,
        "value": 139.577,
        "_internal_originalTime": 1689803354000
    },
    {
        "time": 1689803363000,
        "value": 139.552,
        "_internal_originalTime": 1689803363000
    },
    {
        "time": 1689803385000,
        "value": 139.555,
        "_internal_originalTime": 1689803385000
    },
    {
        "time": 1689803400000,
        "value": 139.577,
        "_internal_originalTime": 1689803400000
    },
    {
        "time": 1689803417000,
        "value": 139.576,
        "_internal_originalTime": 1689803417000
    },
    {
        "time": 1689803422000,
        "value": 139.577,
        "_internal_originalTime": 1689803422000
    },
    {
        "time": 1689803447000,
        "value": 139.582,
        "_internal_originalTime": 1689803447000
    },
    {
        "time": 1689803456000,
        "value": 139.563,
        "_internal_originalTime": 1689803456000
    },
    {
        "time": 1689803477000,
        "value": 139.561,
        "_internal_originalTime": 1689803477000
    },
    {
        "time": 1689803493000,
        "value": 139.551,
        "_internal_originalTime": 1689803493000
    },
    {
        "time": 1689803507000,
        "value": 139.578,
        "_internal_originalTime": 1689803507000
    },
    {
        "time": 1689803522000,
        "value": 139.582,
        "_internal_originalTime": 1689803522000
    },
    {
        "time": 1689803534000,
        "value": 139.57,
        "_internal_originalTime": 1689803534000
    },
    {
        "time": 1689803553000,
        "value": 139.587,
        "_internal_originalTime": 1689803553000
    },
    {
        "time": 1689803565000,
        "value": 139.571,
        "_internal_originalTime": 1689803565000
    },
    {
        "time": 1689803585000,
        "value": 139.581,
        "_internal_originalTime": 1689803585000
    },
    {
        "time": 1689803596000,
        "value": 139.571,
        "_internal_originalTime": 1689803596000
    },
    {
        "time": 1689803615000,
        "value": 139.582,
        "_internal_originalTime": 1689803615000
    },
    {
        "time": 1689803627000,
        "value": 139.572,
        "_internal_originalTime": 1689803627000
    },
    {
        "time": 1689803637000,
        "value": 139.568,
        "_internal_originalTime": 1689803637000
    },
    {
        "time": 1689803655000,
        "value": 139.584,
        "_internal_originalTime": 1689803655000
    },
    {
        "time": 1689803668000,
        "value": 139.586,
        "_internal_originalTime": 1689803668000
    },
    {
        "time": 1689803685000,
        "value": 139.587,
        "_internal_originalTime": 1689803685000
    },
    {
        "time": 1689803693000,
        "value": 139.587,
        "_internal_originalTime": 1689803693000
    },
    {
        "time": 1689803715000,
        "value": 139.584,
        "_internal_originalTime": 1689803715000
    },
    {
        "time": 1689803727000,
        "value": 139.591,
        "_internal_originalTime": 1689803727000
    },
    {
        "time": 1689803747000,
        "value": 139.602,
        "_internal_originalTime": 1689803747000
    },
    {
        "time": 1689803758000,
        "value": 139.608,
        "_internal_originalTime": 1689803758000
    },
    {
        "time": 1689803769000,
        "value": 139.607,
        "_internal_originalTime": 1689803769000
    },
    {
        "time": 1689803782000,
        "value": 139.588,
        "_internal_originalTime": 1689803782000
    },
    {
        "time": 1689803803000,
        "value": 139.605,
        "_internal_originalTime": 1689803803000
    },
    {
        "time": 1689803821000,
        "value": 139.586,
        "_internal_originalTime": 1689803821000
    },
    {
        "time": 1689803829000,
        "value": 139.614,
        "_internal_originalTime": 1689803829000
    },
    {
        "time": 1689803852000,
        "value": 139.604,
        "_internal_originalTime": 1689803852000
    },
    {
        "time": 1689803866000,
        "value": 139.604,
        "_internal_originalTime": 1689803866000
    },
    {
        "time": 1689803877000,
        "value": 139.605,
        "_internal_originalTime": 1689803877000
    },
    {
        "time": 1689803895000,
        "value": 139.607,
        "_internal_originalTime": 1689803895000
    },
    {
        "time": 1689803914000,
        "value": 139.606,
        "_internal_originalTime": 1689803914000
    },
    {
        "time": 1689803921000,
        "value": 139.603,
        "_internal_originalTime": 1689803921000
    },
    {
        "time": 1689803937000,
        "value": 139.594,
        "_internal_originalTime": 1689803937000
    },
    {
        "time": 1689803940000,
        "value": 139.598,
        "_internal_originalTime": 1689803940000
    },
    {
        "time": 1689803949000,
        "value": 139.605,
        "_internal_originalTime": 1689803949000
    },
    {
        "time": 1689803962000,
        "value": 139.586,
        "_internal_originalTime": 1689803962000
    },
    {
        "time": 1689803979000,
        "value": 139.604,
        "_internal_originalTime": 1689803979000
    },
    {
        "time": 1689803995000,
        "value": 139.597,
        "_internal_originalTime": 1689803995000
    },
    {
        "time": 1689804004000,
        "value": 139.609,
        "_internal_originalTime": 1689804004000
    },
    {
        "time": 1689804020000,
        "value": 139.598,
        "_internal_originalTime": 1689804020000
    },
    {
        "time": 1689804035000,
        "value": 139.619,
        "_internal_originalTime": 1689804035000
    },
    {
        "time": 1689804051000,
        "value": 139.626,
        "_internal_originalTime": 1689804051000
    },
    {
        "time": 1689804062000,
        "value": 139.603,
        "_internal_originalTime": 1689804062000
    },
    {
        "time": 1689804077000,
        "value": 139.601,
        "_internal_originalTime": 1689804077000
    },
    {
        "time": 1689804092000,
        "value": 139.61,
        "_internal_originalTime": 1689804092000
    },
    {
        "time": 1689804107000,
        "value": 139.609,
        "_internal_originalTime": 1689804107000
    },
    {
        "time": 1689804121000,
        "value": 139.626,
        "_internal_originalTime": 1689804121000
    },
    {
        "time": 1689804136000,
        "value": 139.62,
        "_internal_originalTime": 1689804136000
    },
    {
        "time": 1689804151000,
        "value": 139.594,
        "_internal_originalTime": 1689804151000
    },
    {
        "time": 1689804166000,
        "value": 139.622,
        "_internal_originalTime": 1689804166000
    },
    {
        "time": 1689804182000,
        "value": 139.59,
        "_internal_originalTime": 1689804182000
    },
    {
        "time": 1689804197000,
        "value": 139.619,
        "_internal_originalTime": 1689804197000
    },
    {
        "time": 1689804212000,
        "value": 139.588,
        "_internal_originalTime": 1689804212000
    },
    {
        "time": 1689804228000,
        "value": 139.63,
        "_internal_originalTime": 1689804228000
    },
    {
        "time": 1689804239000,
        "value": 139.623,
        "_internal_originalTime": 1689804239000
    },
    {
        "time": 1689804240000,
        "value": 139.623,
        "_internal_originalTime": 1689804240000
    },
    {
        "time": 1689804245000,
        "value": 139.591,
        "_internal_originalTime": 1689804245000
    },
    {
        "time": 1689804260000,
        "value": 139.621,
        "_internal_originalTime": 1689804260000
    },
    {
        "time": 1689804275000,
        "value": 139.622,
        "_internal_originalTime": 1689804275000
    },
    {
        "time": 1689804290000,
        "value": 139.621,
        "_internal_originalTime": 1689804290000
    },
    {
        "time": 1689804306000,
        "value": 139.634,
        "_internal_originalTime": 1689804306000
    },
    {
        "time": 1689804320000,
        "value": 139.632,
        "_internal_originalTime": 1689804320000
    },
    {
        "time": 1689804334000,
        "value": 139.629,
        "_internal_originalTime": 1689804334000
    },
    {
        "time": 1689804351000,
        "value": 139.638,
        "_internal_originalTime": 1689804351000
    },
    {
        "time": 1689804366000,
        "value": 139.628,
        "_internal_originalTime": 1689804366000
    },
    {
        "time": 1689804376000,
        "value": 139.63,
        "_internal_originalTime": 1689804376000
    },
    {
        "time": 1689804396000,
        "value": 139.626,
        "_internal_originalTime": 1689804396000
    },
    {
        "time": 1689804412000,
        "value": 139.631,
        "_internal_originalTime": 1689804412000
    },
    {
        "time": 1689804421000,
        "value": 139.639,
        "_internal_originalTime": 1689804421000
    },
    {
        "time": 1689804442000,
        "value": 139.64,
        "_internal_originalTime": 1689804442000
    },
    {
        "time": 1689804458000,
        "value": 139.631,
        "_internal_originalTime": 1689804458000
    },
    {
        "time": 1689804473000,
        "value": 139.628,
        "_internal_originalTime": 1689804473000
    },
    {
        "time": 1689804488000,
        "value": 139.631,
        "_internal_originalTime": 1689804488000
    },
    {
        "time": 1689804513000,
        "value": 139.633,
        "_internal_originalTime": 1689804513000
    },
    {
        "time": 1689804529000,
        "value": 139.632,
        "_internal_originalTime": 1689804529000
    },
    {
        "time": 1689804545000,
        "value": 139.63,
        "_internal_originalTime": 1689804545000
    },
    {
        "time": 1689804557000,
        "value": 139.63,
        "_internal_originalTime": 1689804557000
    },
    {
        "time": 1689804567000,
        "value": 139.629,
        "_internal_originalTime": 1689804567000
    },
    {
        "time": 1689804568000,
        "value": 139.629,
        "_internal_originalTime": 1689804568000
    },
    {
        "time": 1689804583000,
        "value": 139.636,
        "_internal_originalTime": 1689804583000
    },
    {
        "time": 1689804597000,
        "value": 139.629,
        "_internal_originalTime": 1689804597000
    },
    {
        "time": 1689804613000,
        "value": 139.632,
        "_internal_originalTime": 1689804613000
    },
    {
        "time": 1689804628000,
        "value": 139.633,
        "_internal_originalTime": 1689804628000
    },
    {
        "time": 1689804639000,
        "value": 139.632,
        "_internal_originalTime": 1689804639000
    },
    {
        "time": 1689804659000,
        "value": 139.622,
        "_internal_originalTime": 1689804659000
    },
    {
        "time": 1689804670000,
        "value": 139.612,
        "_internal_originalTime": 1689804670000
    },
    {
        "time": 1689804689000,
        "value": 139.593,
        "_internal_originalTime": 1689804689000
    },
    {
        "time": 1689804704000,
        "value": 139.615,
        "_internal_originalTime": 1689804704000
    },
    {
        "time": 1689804720000,
        "value": 139.608,
        "_internal_originalTime": 1689804720000
    },
    {
        "time": 1689804734000,
        "value": 139.597,
        "_internal_originalTime": 1689804734000
    },
    {
        "time": 1689804750000,
        "value": 139.599,
        "_internal_originalTime": 1689804750000
    },
    {
        "time": 1689804763000,
        "value": 139.595,
        "_internal_originalTime": 1689804763000
    },
    {
        "time": 1689804776000,
        "value": 139.602,
        "_internal_originalTime": 1689804776000
    },
    {
        "time": 1689804795000,
        "value": 139.6,
        "_internal_originalTime": 1689804795000
    },
    {
        "time": 1689804810000,
        "value": 139.601,
        "_internal_originalTime": 1689804810000
    },
    {
        "time": 1689804825000,
        "value": 139.625,
        "_internal_originalTime": 1689804825000
    },
    {
        "time": 1689804840000,
        "value": 139.626,
        "_internal_originalTime": 1689804840000
    },
    {
        "time": 1689804855000,
        "value": 139.614,
        "_internal_originalTime": 1689804855000
    },
    {
        "time": 1689804867000,
        "value": 139.6,
        "_internal_originalTime": 1689804867000
    },
    {
        "time": 1689804871000,
        "value": 139.6,
        "_internal_originalTime": 1689804871000
    },
    {
        "time": 1689804885000,
        "value": 139.609,
        "_internal_originalTime": 1689804885000
    },
    {
        "time": 1689804900000,
        "value": 139.602,
        "_internal_originalTime": 1689804900000
    },
    {
        "time": 1689804933000,
        "value": 139.606,
        "_internal_originalTime": 1689804933000
    },
    {
        "time": 1689804947000,
        "value": 139.602,
        "_internal_originalTime": 1689804947000
    },
    {
        "time": 1689804963000,
        "value": 139.61,
        "_internal_originalTime": 1689804963000
    },
    {
        "time": 1689804978000,
        "value": 139.611,
        "_internal_originalTime": 1689804978000
    },
    {
        "time": 1689804993000,
        "value": 139.613,
        "_internal_originalTime": 1689804993000
    },
    {
        "time": 1689805006000,
        "value": 139.612,
        "_internal_originalTime": 1689805006000
    },
    {
        "time": 1689805023000,
        "value": 139.608,
        "_internal_originalTime": 1689805023000
    },
    {
        "time": 1689805038000,
        "value": 139.608,
        "_internal_originalTime": 1689805038000
    },
    {
        "time": 1689805054000,
        "value": 139.606,
        "_internal_originalTime": 1689805054000
    },
    {
        "time": 1689805068000,
        "value": 139.605,
        "_internal_originalTime": 1689805068000
    },
    {
        "time": 1689805081000,
        "value": 139.604,
        "_internal_originalTime": 1689805081000
    },
    {
        "time": 1689805096000,
        "value": 139.595,
        "_internal_originalTime": 1689805096000
    },
    {
        "time": 1689805111000,
        "value": 139.592,
        "_internal_originalTime": 1689805111000
    },
    {
        "time": 1689805126000,
        "value": 139.601,
        "_internal_originalTime": 1689805126000
    },
    {
        "time": 1689805140000,
        "value": 139.59,
        "_internal_originalTime": 1689805140000
    },
    {
        "time": 1689805151000,
        "value": 139.601,
        "_internal_originalTime": 1689805151000
    },
    {
        "time": 1689805166000,
        "value": 139.604,
        "_internal_originalTime": 1689805166000
    },
    {
        "time": 1689805182000,
        "value": 139.621,
        "_internal_originalTime": 1689805182000
    },
    {
        "time": 1689805198000,
        "value": 139.61,
        "_internal_originalTime": 1689805198000
    },
    {
        "time": 1689805203000,
        "value": 139.607,
        "_internal_originalTime": 1689805203000
    },
    {
        "time": 1689805218000,
        "value": 139.597,
        "_internal_originalTime": 1689805218000
    },
    {
        "time": 1689805233000,
        "value": 139.602,
        "_internal_originalTime": 1689805233000
    },
    {
        "time": 1689805248000,
        "value": 139.597,
        "_internal_originalTime": 1689805248000
    },
    {
        "time": 1689805260000,
        "value": 139.597,
        "_internal_originalTime": 1689805260000
    },
    {
        "time": 1689805279000,
        "value": 139.601,
        "_internal_originalTime": 1689805279000
    },
    {
        "time": 1689805294000,
        "value": 139.604,
        "_internal_originalTime": 1689805294000
    },
    {
        "time": 1689805310000,
        "value": 139.612,
        "_internal_originalTime": 1689805310000
    },
    {
        "time": 1689805313000,
        "value": 139.604,
        "_internal_originalTime": 1689805313000
    },
    {
        "time": 1689805332000,
        "value": 139.604,
        "_internal_originalTime": 1689805332000
    },
    {
        "time": 1689805347000,
        "value": 139.604,
        "_internal_originalTime": 1689805347000
    },
    {
        "time": 1689805364000,
        "value": 139.59,
        "_internal_originalTime": 1689805364000
    },
    {
        "time": 1689805378000,
        "value": 139.602,
        "_internal_originalTime": 1689805378000
    },
    {
        "time": 1689805393000,
        "value": 139.606,
        "_internal_originalTime": 1689805393000
    },
    {
        "time": 1689805406000,
        "value": 139.606,
        "_internal_originalTime": 1689805406000
    },
    {
        "time": 1689805420000,
        "value": 139.6,
        "_internal_originalTime": 1689805420000
    },
    {
        "time": 1689805437000,
        "value": 139.602,
        "_internal_originalTime": 1689805437000
    },
    {
        "time": 1689805444000,
        "value": 139.601,
        "_internal_originalTime": 1689805444000
    },
    {
        "time": 1689805452000,
        "value": 139.6,
        "_internal_originalTime": 1689805452000
    },
    {
        "time": 1689805473000,
        "value": 139.602,
        "_internal_originalTime": 1689805473000
    },
    {
        "time": 1689805480000,
        "value": 139.6,
        "_internal_originalTime": 1689805480000
    },
    {
        "time": 1689805501000,
        "value": 139.598,
        "_internal_originalTime": 1689805501000
    },
    {
        "time": 1689805516000,
        "value": 139.597,
        "_internal_originalTime": 1689805516000
    },
    {
        "time": 1689805530000,
        "value": 139.598,
        "_internal_originalTime": 1689805530000
    },
    {
        "time": 1689805550000,
        "value": 139.599,
        "_internal_originalTime": 1689805550000
    },
    {
        "time": 1689805565000,
        "value": 139.597,
        "_internal_originalTime": 1689805565000
    },
    {
        "time": 1689805573000,
        "value": 139.596,
        "_internal_originalTime": 1689805573000
    },
    {
        "time": 1689805594000,
        "value": 139.597,
        "_internal_originalTime": 1689805594000
    },
    {
        "time": 1689805609000,
        "value": 139.602,
        "_internal_originalTime": 1689805609000
    },
    {
        "time": 1689805623000,
        "value": 139.598,
        "_internal_originalTime": 1689805623000
    },
    {
        "time": 1689805639000,
        "value": 139.598,
        "_internal_originalTime": 1689805639000
    },
    {
        "time": 1689805654000,
        "value": 139.598,
        "_internal_originalTime": 1689805654000
    },
    {
        "time": 1689805670000,
        "value": 139.599,
        "_internal_originalTime": 1689805670000
    },
    {
        "time": 1689805685000,
        "value": 139.597,
        "_internal_originalTime": 1689805685000
    },
    {
        "time": 1689805696000,
        "value": 139.6,
        "_internal_originalTime": 1689805696000
    },
    {
        "time": 1689805716000,
        "value": 139.604,
        "_internal_originalTime": 1689805716000
    },
    {
        "time": 1689805728000,
        "value": 139.599,
        "_internal_originalTime": 1689805728000
    },
    {
        "time": 1689805738000,
        "value": 139.603,
        "_internal_originalTime": 1689805738000
    },
    {
        "time": 1689805740000,
        "value": 139.603,
        "_internal_originalTime": 1689805740000
    },
    {
        "time": 1689805742000,
        "value": 139.603,
        "_internal_originalTime": 1689805742000
    },
    {
        "time": 1689805749000,
        "value": 139.611,
        "_internal_originalTime": 1689805749000
    },
    {
        "time": 1689805764000,
        "value": 139.608,
        "_internal_originalTime": 1689805764000
    },
    {
        "time": 1689805780000,
        "value": 139.601,
        "_internal_originalTime": 1689805780000
    },
    {
        "time": 1689805795000,
        "value": 139.63,
        "_internal_originalTime": 1689805795000
    },
    {
        "time": 1689805806000,
        "value": 139.63,
        "_internal_originalTime": 1689805806000
    },
    {
        "time": 1689805822000,
        "value": 139.63,
        "_internal_originalTime": 1689805822000
    },
    {
        "time": 1689805833000,
        "value": 139.633,
        "_internal_originalTime": 1689805833000
    },
    {
        "time": 1689805852000,
        "value": 139.63,
        "_internal_originalTime": 1689805852000
    },
    {
        "time": 1689805868000,
        "value": 139.63,
        "_internal_originalTime": 1689805868000
    },
    {
        "time": 1689805877000,
        "value": 139.624,
        "_internal_originalTime": 1689805877000
    },
    {
        "time": 1689805890000,
        "value": 139.62,
        "_internal_originalTime": 1689805890000
    },
    {
        "time": 1689805913000,
        "value": 139.619,
        "_internal_originalTime": 1689805913000
    },
    {
        "time": 1689805924000,
        "value": 139.616,
        "_internal_originalTime": 1689805924000
    },
    {
        "time": 1689805939000,
        "value": 139.621,
        "_internal_originalTime": 1689805939000
    },
    {
        "time": 1689805949000,
        "value": 139.619,
        "_internal_originalTime": 1689805949000
    },
    {
        "time": 1689805964000,
        "value": 139.623,
        "_internal_originalTime": 1689805964000
    },
    {
        "time": 1689805982000,
        "value": 139.623,
        "_internal_originalTime": 1689805982000
    },
    {
        "time": 1689805997000,
        "value": 139.63,
        "_internal_originalTime": 1689805997000
    },
    {
        "time": 1689806011000,
        "value": 139.63,
        "_internal_originalTime": 1689806011000
    },
    {
        "time": 1689806026000,
        "value": 139.629,
        "_internal_originalTime": 1689806026000
    },
    {
        "time": 1689806039000,
        "value": 139.632,
        "_internal_originalTime": 1689806039000
    },
    {
        "time": 1689806040000,
        "value": 139.63,
        "_internal_originalTime": 1689806040000
    },
    {
        "time": 1689806047000,
        "value": 139.634,
        "_internal_originalTime": 1689806047000
    },
    {
        "time": 1689806061000,
        "value": 139.621,
        "_internal_originalTime": 1689806061000
    },
    {
        "time": 1689806076000,
        "value": 139.624,
        "_internal_originalTime": 1689806076000
    },
    {
        "time": 1689806091000,
        "value": 139.64,
        "_internal_originalTime": 1689806091000
    },
    {
        "time": 1689806106000,
        "value": 139.638,
        "_internal_originalTime": 1689806106000
    },
    {
        "time": 1689806122000,
        "value": 139.639,
        "_internal_originalTime": 1689806122000
    },
    {
        "time": 1689806137000,
        "value": 139.645,
        "_internal_originalTime": 1689806137000
    },
    {
        "time": 1689806160000,
        "value": 139.645,
        "_internal_originalTime": 1689806160000
    },
    {
        "time": 1689806174000,
        "value": 139.641,
        "_internal_originalTime": 1689806174000
    },
    {
        "time": 1689806188000,
        "value": 139.636,
        "_internal_originalTime": 1689806188000
    },
    {
        "time": 1689806203000,
        "value": 139.633,
        "_internal_originalTime": 1689806203000
    },
    {
        "time": 1689806216000,
        "value": 139.634,
        "_internal_originalTime": 1689806216000
    },
    {
        "time": 1689806235000,
        "value": 139.632,
        "_internal_originalTime": 1689806235000
    },
    {
        "time": 1689806249000,
        "value": 139.628,
        "_internal_originalTime": 1689806249000
    },
    {
        "time": 1689806264000,
        "value": 139.622,
        "_internal_originalTime": 1689806264000
    },
    {
        "time": 1689806279000,
        "value": 139.621,
        "_internal_originalTime": 1689806279000
    },
    {
        "time": 1689806295000,
        "value": 139.626,
        "_internal_originalTime": 1689806295000
    },
    {
        "time": 1689806308000,
        "value": 139.622,
        "_internal_originalTime": 1689806308000
    },
    {
        "time": 1689806324000,
        "value": 139.621,
        "_internal_originalTime": 1689806324000
    },
    {
        "time": 1689806336000,
        "value": 139.622,
        "_internal_originalTime": 1689806336000
    },
    {
        "time": 1689806351000,
        "value": 139.621,
        "_internal_originalTime": 1689806351000
    },
    {
        "time": 1689806367000,
        "value": 139.623,
        "_internal_originalTime": 1689806367000
    },
    {
        "time": 1689806369000,
        "value": 139.621,
        "_internal_originalTime": 1689806369000
    },
    {
        "time": 1689806372000,
        "value": 139.622,
        "_internal_originalTime": 1689806372000
    },
    {
        "time": 1689806386000,
        "value": 139.624,
        "_internal_originalTime": 1689806386000
    },
    {
        "time": 1689806402000,
        "value": 139.624,
        "_internal_originalTime": 1689806402000
    },
    {
        "time": 1689806417000,
        "value": 139.601,
        "_internal_originalTime": 1689806417000
    },
    {
        "time": 1689806429000,
        "value": 139.634,
        "_internal_originalTime": 1689806429000
    },
    {
        "time": 1689806443000,
        "value": 139.632,
        "_internal_originalTime": 1689806443000
    },
    {
        "time": 1689806460000,
        "value": 139.631,
        "_internal_originalTime": 1689806460000
    },
    {
        "time": 1689806472000,
        "value": 139.625,
        "_internal_originalTime": 1689806472000
    },
    {
        "time": 1689806487000,
        "value": 139.624,
        "_internal_originalTime": 1689806487000
    },
    {
        "time": 1689806499000,
        "value": 139.623,
        "_internal_originalTime": 1689806499000
    },
    {
        "time": 1689806518000,
        "value": 139.622,
        "_internal_originalTime": 1689806518000
    },
    {
        "time": 1689806534000,
        "value": 139.621,
        "_internal_originalTime": 1689806534000
    },
    {
        "time": 1689806549000,
        "value": 139.621,
        "_internal_originalTime": 1689806549000
    },
    {
        "time": 1689806559000,
        "value": 139.617,
        "_internal_originalTime": 1689806559000
    },
    {
        "time": 1689806579000,
        "value": 139.616,
        "_internal_originalTime": 1689806579000
    },
    {
        "time": 1689806589000,
        "value": 139.616,
        "_internal_originalTime": 1689806589000
    },
    {
        "time": 1689806606000,
        "value": 139.616,
        "_internal_originalTime": 1689806606000
    },
    {
        "time": 1689806624000,
        "value": 139.619,
        "_internal_originalTime": 1689806624000
    },
    {
        "time": 1689806638000,
        "value": 139.612,
        "_internal_originalTime": 1689806638000
    },
    {
        "time": 1689806640000,
        "value": 139.614,
        "_internal_originalTime": 1689806640000
    },
    {
        "time": 1689806657000,
        "value": 139.624,
        "_internal_originalTime": 1689806657000
    },
    {
        "time": 1689806670000,
        "value": 139.623,
        "_internal_originalTime": 1689806670000
    },
    {
        "time": 1689806685000,
        "value": 139.622,
        "_internal_originalTime": 1689806685000
    },
    {
        "time": 1689806704000,
        "value": 139.621,
        "_internal_originalTime": 1689806704000
    },
    {
        "time": 1689806713000,
        "value": 139.626,
        "_internal_originalTime": 1689806713000
    },
    {
        "time": 1689806733000,
        "value": 139.624,
        "_internal_originalTime": 1689806733000
    },
    {
        "time": 1689806749000,
        "value": 139.641,
        "_internal_originalTime": 1689806749000
    },
    {
        "time": 1689806763000,
        "value": 139.631,
        "_internal_originalTime": 1689806763000
    },
    {
        "time": 1689806778000,
        "value": 139.619,
        "_internal_originalTime": 1689806778000
    },
    {
        "time": 1689806793000,
        "value": 139.609,
        "_internal_originalTime": 1689806793000
    },
    {
        "time": 1689806808000,
        "value": 139.602,
        "_internal_originalTime": 1689806808000
    },
    {
        "time": 1689806820000,
        "value": 139.599,
        "_internal_originalTime": 1689806820000
    },
    {
        "time": 1689806835000,
        "value": 139.589,
        "_internal_originalTime": 1689806835000
    },
    {
        "time": 1689806850000,
        "value": 139.597,
        "_internal_originalTime": 1689806850000
    },
    {
        "time": 1689806864000,
        "value": 139.597,
        "_internal_originalTime": 1689806864000
    },
    {
        "time": 1689806879000,
        "value": 139.589,
        "_internal_originalTime": 1689806879000
    },
    {
        "time": 1689806893000,
        "value": 139.613,
        "_internal_originalTime": 1689806893000
    },
    {
        "time": 1689806909000,
        "value": 139.616,
        "_internal_originalTime": 1689806909000
    },
    {
        "time": 1689806924000,
        "value": 139.604,
        "_internal_originalTime": 1689806924000
    },
    {
        "time": 1689806938000,
        "value": 139.595,
        "_internal_originalTime": 1689806938000
    },
    {
        "time": 1689806940000,
        "value": 139.596,
        "_internal_originalTime": 1689806940000
    },
    {
        "time": 1689806941000,
        "value": 139.617,
        "_internal_originalTime": 1689806941000
    },
    {
        "time": 1689806943000,
        "value": 139.612,
        "_internal_originalTime": 1689806943000
    },
    {
        "time": 1689806959000,
        "value": 139.622,
        "_internal_originalTime": 1689806959000
    },
    {
        "time": 1689806974000,
        "value": 139.608,
        "_internal_originalTime": 1689806974000
    },
    {
        "time": 1689806990000,
        "value": 139.622,
        "_internal_originalTime": 1689806990000
    },
    {
        "time": 1689807001000,
        "value": 139.613,
        "_internal_originalTime": 1689807001000
    },
    {
        "time": 1689807016000,
        "value": 139.612,
        "_internal_originalTime": 1689807016000
    },
    {
        "time": 1689807031000,
        "value": 139.627,
        "_internal_originalTime": 1689807031000
    },
    {
        "time": 1689807043000,
        "value": 139.624,
        "_internal_originalTime": 1689807043000
    },
    {
        "time": 1689807061000,
        "value": 139.624,
        "_internal_originalTime": 1689807061000
    },
    {
        "time": 1689807075000,
        "value": 139.627,
        "_internal_originalTime": 1689807075000
    },
    {
        "time": 1689807092000,
        "value": 139.622,
        "_internal_originalTime": 1689807092000
    },
    {
        "time": 1689807107000,
        "value": 139.623,
        "_internal_originalTime": 1689807107000
    },
    {
        "time": 1689807119000,
        "value": 139.622,
        "_internal_originalTime": 1689807119000
    },
    {
        "time": 1689807133000,
        "value": 139.62,
        "_internal_originalTime": 1689807133000
    },
    {
        "time": 1689807145000,
        "value": 139.622,
        "_internal_originalTime": 1689807145000
    },
    {
        "time": 1689807163000,
        "value": 139.621,
        "_internal_originalTime": 1689807163000
    },
    {
        "time": 1689807179000,
        "value": 139.632,
        "_internal_originalTime": 1689807179000
    },
    {
        "time": 1689807189000,
        "value": 139.633,
        "_internal_originalTime": 1689807189000
    },
    {
        "time": 1689807210000,
        "value": 139.631,
        "_internal_originalTime": 1689807210000
    },
    {
        "time": 1689807224000,
        "value": 139.637,
        "_internal_originalTime": 1689807224000
    },
    {
        "time": 1689807238000,
        "value": 139.631,
        "_internal_originalTime": 1689807238000
    },
    {
        "time": 1689807241000,
        "value": 139.63,
        "_internal_originalTime": 1689807241000
    },
    {
        "time": 1689807257000,
        "value": 139.632,
        "_internal_originalTime": 1689807257000
    },
    {
        "time": 1689807266000,
        "value": 139.633,
        "_internal_originalTime": 1689807266000
    },
    {
        "time": 1689807287000,
        "value": 139.619,
        "_internal_originalTime": 1689807287000
    },
    {
        "time": 1689807301000,
        "value": 139.62,
        "_internal_originalTime": 1689807301000
    },
    {
        "time": 1689807317000,
        "value": 139.621,
        "_internal_originalTime": 1689807317000
    },
    {
        "time": 1689807332000,
        "value": 139.613,
        "_internal_originalTime": 1689807332000
    },
    {
        "time": 1689807348000,
        "value": 139.622,
        "_internal_originalTime": 1689807348000
    },
    {
        "time": 1689807362000,
        "value": 139.622,
        "_internal_originalTime": 1689807362000
    },
    {
        "time": 1689807377000,
        "value": 139.621,
        "_internal_originalTime": 1689807377000
    },
    {
        "time": 1689807390000,
        "value": 139.623,
        "_internal_originalTime": 1689807390000
    },
    {
        "time": 1689807407000,
        "value": 139.635,
        "_internal_originalTime": 1689807407000
    },
    {
        "time": 1689807420000,
        "value": 139.639,
        "_internal_originalTime": 1689807420000
    },
    {
        "time": 1689807429000,
        "value": 139.64,
        "_internal_originalTime": 1689807429000
    },
    {
        "time": 1689807447000,
        "value": 139.633,
        "_internal_originalTime": 1689807447000
    },
    {
        "time": 1689807459000,
        "value": 139.632,
        "_internal_originalTime": 1689807459000
    },
    {
        "time": 1689807478000,
        "value": 139.635,
        "_internal_originalTime": 1689807478000
    },
    {
        "time": 1689807492000,
        "value": 139.632,
        "_internal_originalTime": 1689807492000
    },
    {
        "time": 1689807506000,
        "value": 139.63,
        "_internal_originalTime": 1689807506000
    },
    {
        "time": 1689807519000,
        "value": 139.628,
        "_internal_originalTime": 1689807519000
    },
    {
        "time": 1689807540000,
        "value": 139.611,
        "_internal_originalTime": 1689807540000
    },
    {
        "time": 1689807541000,
        "value": 139.607,
        "_internal_originalTime": 1689807541000
    },
    {
        "time": 1689807543000,
        "value": 139.61,
        "_internal_originalTime": 1689807543000
    },
    {
        "time": 1689807558000,
        "value": 139.6,
        "_internal_originalTime": 1689807558000
    },
    {
        "time": 1689807572000,
        "value": 139.602,
        "_internal_originalTime": 1689807572000
    },
    {
        "time": 1689807582000,
        "value": 139.602,
        "_internal_originalTime": 1689807582000
    },
    {
        "time": 1689807601000,
        "value": 139.611,
        "_internal_originalTime": 1689807601000
    },
    {
        "time": 1689807615000,
        "value": 139.602,
        "_internal_originalTime": 1689807615000
    },
    {
        "time": 1689807630000,
        "value": 139.607,
        "_internal_originalTime": 1689807630000
    },
    {
        "time": 1689807645000,
        "value": 139.618,
        "_internal_originalTime": 1689807645000
    },
    {
        "time": 1689807658000,
        "value": 139.631,
        "_internal_originalTime": 1689807658000
    },
    {
        "time": 1689807667000,
        "value": 139.642,
        "_internal_originalTime": 1689807667000
    },
    {
        "time": 1689807680000,
        "value": 139.644,
        "_internal_originalTime": 1689807680000
    },
    {
        "time": 1689807697000,
        "value": 139.641,
        "_internal_originalTime": 1689807697000
    },
    {
        "time": 1689807713000,
        "value": 139.63,
        "_internal_originalTime": 1689807713000
    },
    {
        "time": 1689807714000,
        "value": 139.63,
        "_internal_originalTime": 1689807714000
    },
    {
        "time": 1689807734000,
        "value": 139.632,
        "_internal_originalTime": 1689807734000
    },
    {
        "time": 1689807747000,
        "value": 139.633,
        "_internal_originalTime": 1689807747000
    },
    {
        "time": 1689807763000,
        "value": 139.634,
        "_internal_originalTime": 1689807763000
    },
    {
        "time": 1689807774000,
        "value": 139.63,
        "_internal_originalTime": 1689807774000
    },
    {
        "time": 1689807789000,
        "value": 139.634,
        "_internal_originalTime": 1689807789000
    },
    {
        "time": 1689807804000,
        "value": 139.633,
        "_internal_originalTime": 1689807804000
    },
    {
        "time": 1689807818000,
        "value": 139.652,
        "_internal_originalTime": 1689807818000
    },
    {
        "time": 1689807833000,
        "value": 139.663,
        "_internal_originalTime": 1689807833000
    },
    {
        "time": 1689807839000,
        "value": 139.659,
        "_internal_originalTime": 1689807839000
    },
    {
        "time": 1689807842000,
        "value": 139.659,
        "_internal_originalTime": 1689807842000
    },
    {
        "time": 1689807843000,
        "value": 139.662,
        "_internal_originalTime": 1689807843000
    },
    {
        "time": 1689807855000,
        "value": 139.661,
        "_internal_originalTime": 1689807855000
    },
    {
        "time": 1689807871000,
        "value": 139.664,
        "_internal_originalTime": 1689807871000
    },
    {
        "time": 1689807886000,
        "value": 139.656,
        "_internal_originalTime": 1689807886000
    },
    {
        "time": 1689807899000,
        "value": 139.651,
        "_internal_originalTime": 1689807899000
    },
    {
        "time": 1689807910000,
        "value": 139.654,
        "_internal_originalTime": 1689807910000
    },
    {
        "time": 1689807924000,
        "value": 139.635,
        "_internal_originalTime": 1689807924000
    },
    {
        "time": 1689807940000,
        "value": 139.636,
        "_internal_originalTime": 1689807940000
    },
    {
        "time": 1689807954000,
        "value": 139.634,
        "_internal_originalTime": 1689807954000
    },
    {
        "time": 1689807960000,
        "value": 139.64,
        "_internal_originalTime": 1689807960000
    },
    {
        "time": 1689807974000,
        "value": 139.64,
        "_internal_originalTime": 1689807974000
    },
    {
        "time": 1689807988000,
        "value": 139.64,
        "_internal_originalTime": 1689807988000
    },
    {
        "time": 1689808006000,
        "value": 139.65,
        "_internal_originalTime": 1689808006000
    },
    {
        "time": 1689808018000,
        "value": 139.672,
        "_internal_originalTime": 1689808018000
    },
    {
        "time": 1689808026000,
        "value": 139.671,
        "_internal_originalTime": 1689808026000
    },
    {
        "time": 1689808040000,
        "value": 139.672,
        "_internal_originalTime": 1689808040000
    },
    {
        "time": 1689808055000,
        "value": 139.671,
        "_internal_originalTime": 1689808055000
    },
    {
        "time": 1689808070000,
        "value": 139.67,
        "_internal_originalTime": 1689808070000
    },
    {
        "time": 1689808077000,
        "value": 139.669,
        "_internal_originalTime": 1689808077000
    },
    {
        "time": 1689808090000,
        "value": 139.672,
        "_internal_originalTime": 1689808090000
    },
    {
        "time": 1689808108000,
        "value": 139.662,
        "_internal_originalTime": 1689808108000
    },
    {
        "time": 1689808122000,
        "value": 139.661,
        "_internal_originalTime": 1689808122000
    },
    {
        "time": 1689808134000,
        "value": 139.662,
        "_internal_originalTime": 1689808134000
    },
    {
        "time": 1689808140000,
        "value": 139.671,
        "_internal_originalTime": 1689808140000
    },
    {
        "time": 1689808147000,
        "value": 139.669,
        "_internal_originalTime": 1689808147000
    },
    {
        "time": 1689808159000,
        "value": 139.674,
        "_internal_originalTime": 1689808159000
    },
    {
        "time": 1689808177000,
        "value": 139.674,
        "_internal_originalTime": 1689808177000
    },
    {
        "time": 1689808192000,
        "value": 139.673,
        "_internal_originalTime": 1689808192000
    },
    {
        "time": 1689808199000,
        "value": 139.672,
        "_internal_originalTime": 1689808199000
    },
    {
        "time": 1689808215000,
        "value": 139.664,
        "_internal_originalTime": 1689808215000
    },
    {
        "time": 1689808227000,
        "value": 139.661,
        "_internal_originalTime": 1689808227000
    },
    {
        "time": 1689808244000,
        "value": 139.66,
        "_internal_originalTime": 1689808244000
    },
    {
        "time": 1689808258000,
        "value": 139.651,
        "_internal_originalTime": 1689808258000
    },
    {
        "time": 1689808267000,
        "value": 139.654,
        "_internal_originalTime": 1689808267000
    },
    {
        "time": 1689808282000,
        "value": 139.653,
        "_internal_originalTime": 1689808282000
    },
    {
        "time": 1689808296000,
        "value": 139.659,
        "_internal_originalTime": 1689808296000
    },
    {
        "time": 1689808311000,
        "value": 139.657,
        "_internal_originalTime": 1689808311000
    },
    {
        "time": 1689808314000,
        "value": 139.656,
        "_internal_originalTime": 1689808314000
    },
    {
        "time": 1689808332000,
        "value": 139.654,
        "_internal_originalTime": 1689808332000
    },
    {
        "time": 1689808346000,
        "value": 139.652,
        "_internal_originalTime": 1689808346000
    },
    {
        "time": 1689808359000,
        "value": 139.652,
        "_internal_originalTime": 1689808359000
    },
    {
        "time": 1689808378000,
        "value": 139.652,
        "_internal_originalTime": 1689808378000
    },
    {
        "time": 1689808383000,
        "value": 139.655,
        "_internal_originalTime": 1689808383000
    },
    {
        "time": 1689808399000,
        "value": 139.652,
        "_internal_originalTime": 1689808399000
    },
    {
        "time": 1689808413000,
        "value": 139.651,
        "_internal_originalTime": 1689808413000
    },
    {
        "time": 1689808429000,
        "value": 139.649,
        "_internal_originalTime": 1689808429000
    },
    {
        "time": 1689808436000,
        "value": 139.649,
        "_internal_originalTime": 1689808436000
    },
    {
        "time": 1689808439000,
        "value": 139.648,
        "_internal_originalTime": 1689808439000
    },
    {
        "time": 1689808442000,
        "value": 139.645,
        "_internal_originalTime": 1689808442000
    },
    {
        "time": 1689808453000,
        "value": 139.649,
        "_internal_originalTime": 1689808453000
    },
    {
        "time": 1689808464000,
        "value": 139.647,
        "_internal_originalTime": 1689808464000
    },
    {
        "time": 1689808488000,
        "value": 139.655,
        "_internal_originalTime": 1689808488000
    },
    {
        "time": 1689808499000,
        "value": 139.665,
        "_internal_originalTime": 1689808499000
    },
    {
        "time": 1689808509000,
        "value": 139.67,
        "_internal_originalTime": 1689808509000
    },
    {
        "time": 1689808524000,
        "value": 139.661,
        "_internal_originalTime": 1689808524000
    },
    {
        "time": 1689808525000,
        "value": 139.662,
        "_internal_originalTime": 1689808525000
    },
    {
        "time": 1689808539000,
        "value": 139.658,
        "_internal_originalTime": 1689808539000
    },
    {
        "time": 1689808553000,
        "value": 139.654,
        "_internal_originalTime": 1689808553000
    },
    {
        "time": 1689808556000,
        "value": 139.65,
        "_internal_originalTime": 1689808556000
    },
    {
        "time": 1689808574000,
        "value": 139.656,
        "_internal_originalTime": 1689808574000
    },
    {
        "time": 1689808584000,
        "value": 139.651,
        "_internal_originalTime": 1689808584000
    },
    {
        "time": 1689808593000,
        "value": 139.652,
        "_internal_originalTime": 1689808593000
    },
    {
        "time": 1689808606000,
        "value": 139.653,
        "_internal_originalTime": 1689808606000
    },
    {
        "time": 1689808619000,
        "value": 139.652,
        "_internal_originalTime": 1689808619000
    },
    {
        "time": 1689808631000,
        "value": 139.652,
        "_internal_originalTime": 1689808631000
    },
    {
        "time": 1689808642000,
        "value": 139.65,
        "_internal_originalTime": 1689808642000
    },
    {
        "time": 1689808646000,
        "value": 139.651,
        "_internal_originalTime": 1689808646000
    },
    {
        "time": 1689808662000,
        "value": 139.65,
        "_internal_originalTime": 1689808662000
    },
    {
        "time": 1689808673000,
        "value": 139.655,
        "_internal_originalTime": 1689808673000
    },
    {
        "time": 1689808684000,
        "value": 139.656,
        "_internal_originalTime": 1689808684000
    },
    {
        "time": 1689808693000,
        "value": 139.655,
        "_internal_originalTime": 1689808693000
    },
    {
        "time": 1689808705000,
        "value": 139.655,
        "_internal_originalTime": 1689808705000
    },
    {
        "time": 1689808714000,
        "value": 139.648,
        "_internal_originalTime": 1689808714000
    },
    {
        "time": 1689808727000,
        "value": 139.65,
        "_internal_originalTime": 1689808727000
    },
    {
        "time": 1689808739000,
        "value": 139.653,
        "_internal_originalTime": 1689808739000
    },
    {
        "time": 1689808753000,
        "value": 139.657,
        "_internal_originalTime": 1689808753000
    },
    {
        "time": 1689808764000,
        "value": 139.654,
        "_internal_originalTime": 1689808764000
    },
    {
        "time": 1689808769000,
        "value": 139.657,
        "_internal_originalTime": 1689808769000
    },
    {
        "time": 1689808783000,
        "value": 139.663,
        "_internal_originalTime": 1689808783000
    },
    {
        "time": 1689808786000,
        "value": 139.658,
        "_internal_originalTime": 1689808786000
    },
    {
        "time": 1689808805000,
        "value": 139.656,
        "_internal_originalTime": 1689808805000
    },
    {
        "time": 1689808815000,
        "value": 139.668,
        "_internal_originalTime": 1689808815000
    },
    {
        "time": 1689808826000,
        "value": 139.668,
        "_internal_originalTime": 1689808826000
    },
    {
        "time": 1689808828000,
        "value": 139.664,
        "_internal_originalTime": 1689808828000
    },
    {
        "time": 1689808843000,
        "value": 139.664,
        "_internal_originalTime": 1689808843000
    },
    {
        "time": 1689808871000,
        "value": 139.645,
        "_internal_originalTime": 1689808871000
    },
    {
        "time": 1689808885000,
        "value": 139.645,
        "_internal_originalTime": 1689808885000
    },
    {
        "time": 1689808896000,
        "value": 139.653,
        "_internal_originalTime": 1689808896000
    },
    {
        "time": 1689808908000,
        "value": 139.652,
        "_internal_originalTime": 1689808908000
    },
    {
        "time": 1689808918000,
        "value": 139.655,
        "_internal_originalTime": 1689808918000
    },
    {
        "time": 1689808923000,
        "value": 139.653,
        "_internal_originalTime": 1689808923000
    },
    {
        "time": 1689808935000,
        "value": 139.657,
        "_internal_originalTime": 1689808935000
    },
    {
        "time": 1689808947000,
        "value": 139.66,
        "_internal_originalTime": 1689808947000
    },
    {
        "time": 1689808956000,
        "value": 139.657,
        "_internal_originalTime": 1689808956000
    },
    {
        "time": 1689808966000,
        "value": 139.657,
        "_internal_originalTime": 1689808966000
    },
    {
        "time": 1689808975000,
        "value": 139.65,
        "_internal_originalTime": 1689808975000
    },
    {
        "time": 1689808987000,
        "value": 139.655,
        "_internal_originalTime": 1689808987000
    },
    {
        "time": 1689808997000,
        "value": 139.656,
        "_internal_originalTime": 1689808997000
    },
    {
        "time": 1689809008000,
        "value": 139.652,
        "_internal_originalTime": 1689809008000
    },
    {
        "time": 1689809025000,
        "value": 139.638,
        "_internal_originalTime": 1689809025000
    },
    {
        "time": 1689809035000,
        "value": 139.635,
        "_internal_originalTime": 1689809035000
    },
    {
        "time": 1689809040000,
        "value": 139.638,
        "_internal_originalTime": 1689809040000
    },
    {
        "time": 1689809042000,
        "value": 139.638,
        "_internal_originalTime": 1689809042000
    },
    {
        "time": 1689809054000,
        "value": 139.638,
        "_internal_originalTime": 1689809054000
    },
    {
        "time": 1689809067000,
        "value": 139.633,
        "_internal_originalTime": 1689809067000
    },
    {
        "time": 1689809078000,
        "value": 139.616,
        "_internal_originalTime": 1689809078000
    },
    {
        "time": 1689809090000,
        "value": 139.617,
        "_internal_originalTime": 1689809090000
    },
    {
        "time": 1689809098000,
        "value": 139.624,
        "_internal_originalTime": 1689809098000
    },
    {
        "time": 1689809107000,
        "value": 139.626,
        "_internal_originalTime": 1689809107000
    },
    {
        "time": 1689809120000,
        "value": 139.62,
        "_internal_originalTime": 1689809120000
    },
    {
        "time": 1689809127000,
        "value": 139.625,
        "_internal_originalTime": 1689809127000
    },
    {
        "time": 1689809140000,
        "value": 139.616,
        "_internal_originalTime": 1689809140000
    },
    {
        "time": 1689809185000,
        "value": 139.611,
        "_internal_originalTime": 1689809185000
    },
    {
        "time": 1689809199000,
        "value": 139.617,
        "_internal_originalTime": 1689809199000
    },
    {
        "time": 1689809208000,
        "value": 139.618,
        "_internal_originalTime": 1689809208000
    },
    {
        "time": 1689809217000,
        "value": 139.626,
        "_internal_originalTime": 1689809217000
    },
    {
        "time": 1689809224000,
        "value": 139.626,
        "_internal_originalTime": 1689809224000
    },
    {
        "time": 1689809229000,
        "value": 139.625,
        "_internal_originalTime": 1689809229000
    },
    {
        "time": 1689809251000,
        "value": 139.625,
        "_internal_originalTime": 1689809251000
    },
    {
        "time": 1689809259000,
        "value": 139.633,
        "_internal_originalTime": 1689809259000
    },
    {
        "time": 1689809275000,
        "value": 139.629,
        "_internal_originalTime": 1689809275000
    },
    {
        "time": 1689809282000,
        "value": 139.624,
        "_internal_originalTime": 1689809282000
    },
    {
        "time": 1689809294000,
        "value": 139.629,
        "_internal_originalTime": 1689809294000
    },
    {
        "time": 1689809308000,
        "value": 139.627,
        "_internal_originalTime": 1689809308000
    },
    {
        "time": 1689809319000,
        "value": 139.646,
        "_internal_originalTime": 1689809319000
    },
    {
        "time": 1689809330000,
        "value": 139.645,
        "_internal_originalTime": 1689809330000
    },
    {
        "time": 1689809335000,
        "value": 139.646,
        "_internal_originalTime": 1689809335000
    },
    {
        "time": 1689809342000,
        "value": 139.647,
        "_internal_originalTime": 1689809342000
    },
    {
        "time": 1689809344000,
        "value": 139.641,
        "_internal_originalTime": 1689809344000
    },
    {
        "time": 1689809358000,
        "value": 139.647,
        "_internal_originalTime": 1689809358000
    },
    {
        "time": 1689809371000,
        "value": 139.647,
        "_internal_originalTime": 1689809371000
    },
    {
        "time": 1689809381000,
        "value": 139.647,
        "_internal_originalTime": 1689809381000
    },
    {
        "time": 1689809397000,
        "value": 139.647,
        "_internal_originalTime": 1689809397000
    },
    {
        "time": 1689809402000,
        "value": 139.641,
        "_internal_originalTime": 1689809402000
    },
    {
        "time": 1689809409000,
        "value": 139.646,
        "_internal_originalTime": 1689809409000
    },
    {
        "time": 1689809425000,
        "value": 139.647,
        "_internal_originalTime": 1689809425000
    },
    {
        "time": 1689809438000,
        "value": 139.636,
        "_internal_originalTime": 1689809438000
    },
    {
        "time": 1689809446000,
        "value": 139.637,
        "_internal_originalTime": 1689809446000
    },
    {
        "time": 1689809459000,
        "value": 139.654,
        "_internal_originalTime": 1689809459000
    },
    {
        "time": 1689809466000,
        "value": 139.654,
        "_internal_originalTime": 1689809466000
    },
    {
        "time": 1689809477000,
        "value": 139.672,
        "_internal_originalTime": 1689809477000
    },
    {
        "time": 1689809489000,
        "value": 139.676,
        "_internal_originalTime": 1689809489000
    },
    {
        "time": 1689809503000,
        "value": 139.692,
        "_internal_originalTime": 1689809503000
    },
    {
        "time": 1689809519000,
        "value": 139.692,
        "_internal_originalTime": 1689809519000
    },
    {
        "time": 1689809526000,
        "value": 139.693,
        "_internal_originalTime": 1689809526000
    },
    {
        "time": 1689809531000,
        "value": 139.692,
        "_internal_originalTime": 1689809531000
    },
    {
        "time": 1689809548000,
        "value": 139.695,
        "_internal_originalTime": 1689809548000
    },
    {
        "time": 1689809561000,
        "value": 139.682,
        "_internal_originalTime": 1689809561000
    },
    {
        "time": 1689809572000,
        "value": 139.684,
        "_internal_originalTime": 1689809572000
    },
    {
        "time": 1689809575000,
        "value": 139.684,
        "_internal_originalTime": 1689809575000
    },
    {
        "time": 1689809592000,
        "value": 139.684,
        "_internal_originalTime": 1689809592000
    },
    {
        "time": 1689809601000,
        "value": 139.681,
        "_internal_originalTime": 1689809601000
    },
    {
        "time": 1689809607000,
        "value": 139.68,
        "_internal_originalTime": 1689809607000
    },
    {
        "time": 1689809623000,
        "value": 139.679,
        "_internal_originalTime": 1689809623000
    },
    {
        "time": 1689809636000,
        "value": 139.683,
        "_internal_originalTime": 1689809636000
    },
    {
        "time": 1689809640000,
        "value": 139.68,
        "_internal_originalTime": 1689809640000
    },
    {
        "time": 1689809642000,
        "value": 139.682,
        "_internal_originalTime": 1689809642000
    },
    {
        "time": 1689809644000,
        "value": 139.694,
        "_internal_originalTime": 1689809644000
    },
    {
        "time": 1689809654000,
        "value": 139.686,
        "_internal_originalTime": 1689809654000
    },
    {
        "time": 1689809668000,
        "value": 139.685,
        "_internal_originalTime": 1689809668000
    },
    {
        "time": 1689809682000,
        "value": 139.671,
        "_internal_originalTime": 1689809682000
    },
    {
        "time": 1689809694000,
        "value": 139.671,
        "_internal_originalTime": 1689809694000
    },
    {
        "time": 1689809699000,
        "value": 139.67,
        "_internal_originalTime": 1689809699000
    },
    {
        "time": 1689809709000,
        "value": 139.673,
        "_internal_originalTime": 1689809709000
    },
    {
        "time": 1689809720000,
        "value": 139.674,
        "_internal_originalTime": 1689809720000
    },
    {
        "time": 1689809732000,
        "value": 139.674,
        "_internal_originalTime": 1689809732000
    },
    {
        "time": 1689809743000,
        "value": 139.676,
        "_internal_originalTime": 1689809743000
    },
    {
        "time": 1689809756000,
        "value": 139.672,
        "_internal_originalTime": 1689809756000
    },
    {
        "time": 1689809762000,
        "value": 139.673,
        "_internal_originalTime": 1689809762000
    },
    {
        "time": 1689809775000,
        "value": 139.668,
        "_internal_originalTime": 1689809775000
    },
    {
        "time": 1689809788000,
        "value": 139.67,
        "_internal_originalTime": 1689809788000
    },
    {
        "time": 1689809799000,
        "value": 139.671,
        "_internal_originalTime": 1689809799000
    },
    {
        "time": 1689809811000,
        "value": 139.652,
        "_internal_originalTime": 1689809811000
    },
    {
        "time": 1689809815000,
        "value": 139.649,
        "_internal_originalTime": 1689809815000
    },
    {
        "time": 1689809829000,
        "value": 139.646,
        "_internal_originalTime": 1689809829000
    },
    {
        "time": 1689809842000,
        "value": 139.645,
        "_internal_originalTime": 1689809842000
    },
    {
        "time": 1689809852000,
        "value": 139.632,
        "_internal_originalTime": 1689809852000
    },
    {
        "time": 1689809863000,
        "value": 139.636,
        "_internal_originalTime": 1689809863000
    },
    {
        "time": 1689809878000,
        "value": 139.646,
        "_internal_originalTime": 1689809878000
    },
    {
        "time": 1689809883000,
        "value": 139.647,
        "_internal_originalTime": 1689809883000
    },
    {
        "time": 1689809894000,
        "value": 139.647,
        "_internal_originalTime": 1689809894000
    },
    {
        "time": 1689809907000,
        "value": 139.646,
        "_internal_originalTime": 1689809907000
    },
    {
        "time": 1689809918000,
        "value": 139.632,
        "_internal_originalTime": 1689809918000
    },
    {
        "time": 1689809929000,
        "value": 139.633,
        "_internal_originalTime": 1689809929000
    },
    {
        "time": 1689809936000,
        "value": 139.632,
        "_internal_originalTime": 1689809936000
    },
    {
        "time": 1689809938000,
        "value": 139.631,
        "_internal_originalTime": 1689809938000
    },
    {
        "time": 1689809940000,
        "value": 139.626,
        "_internal_originalTime": 1689809940000
    },
    {
        "time": 1689809942000,
        "value": 139.629,
        "_internal_originalTime": 1689809942000
    },
    {
        "time": 1689809947000,
        "value": 139.637,
        "_internal_originalTime": 1689809947000
    },
    {
        "time": 1689809958000,
        "value": 139.636,
        "_internal_originalTime": 1689809958000
    },
    {
        "time": 1689809970000,
        "value": 139.628,
        "_internal_originalTime": 1689809970000
    },
    {
        "time": 1689809981000,
        "value": 139.63,
        "_internal_originalTime": 1689809981000
    },
    {
        "time": 1689809993000,
        "value": 139.619,
        "_internal_originalTime": 1689809993000
    },
    {
        "time": 1689809999000,
        "value": 139.614,
        "_internal_originalTime": 1689809999000
    },
    {
        "time": 1689810009000,
        "value": 139.617,
        "_internal_originalTime": 1689810009000
    },
    {
        "time": 1689810022000,
        "value": 139.612,
        "_internal_originalTime": 1689810022000
    },
    {
        "time": 1689810032000,
        "value": 139.605,
        "_internal_originalTime": 1689810032000
    },
    {
        "time": 1689810044000,
        "value": 139.595,
        "_internal_originalTime": 1689810044000
    },
    {
        "time": 1689810057000,
        "value": 139.594,
        "_internal_originalTime": 1689810057000
    },
    {
        "time": 1689810060000,
        "value": 139.594,
        "_internal_originalTime": 1689810060000
    },
    {
        "time": 1689810075000,
        "value": 139.603,
        "_internal_originalTime": 1689810075000
    },
    {
        "time": 1689810085000,
        "value": 139.594,
        "_internal_originalTime": 1689810085000
    },
    {
        "time": 1689810096000,
        "value": 139.608,
        "_internal_originalTime": 1689810096000
    },
    {
        "time": 1689810108000,
        "value": 139.62,
        "_internal_originalTime": 1689810108000
    },
    {
        "time": 1689810118000,
        "value": 139.619,
        "_internal_originalTime": 1689810118000
    },
    {
        "time": 1689810123000,
        "value": 139.618,
        "_internal_originalTime": 1689810123000
    },
    {
        "time": 1689810134000,
        "value": 139.627,
        "_internal_originalTime": 1689810134000
    },
    {
        "time": 1689810147000,
        "value": 139.626,
        "_internal_originalTime": 1689810147000
    },
    {
        "time": 1689810157000,
        "value": 139.629,
        "_internal_originalTime": 1689810157000
    },
    {
        "time": 1689810169000,
        "value": 139.628,
        "_internal_originalTime": 1689810169000
    },
    {
        "time": 1689810179000,
        "value": 139.624,
        "_internal_originalTime": 1689810179000
    },
    {
        "time": 1689810185000,
        "value": 139.623,
        "_internal_originalTime": 1689810185000
    },
    {
        "time": 1689810197000,
        "value": 139.628,
        "_internal_originalTime": 1689810197000
    },
    {
        "time": 1689810210000,
        "value": 139.634,
        "_internal_originalTime": 1689810210000
    },
    {
        "time": 1689810233000,
        "value": 139.632,
        "_internal_originalTime": 1689810233000
    },
    {
        "time": 1689810235000,
        "value": 139.625,
        "_internal_originalTime": 1689810235000
    },
    {
        "time": 1689810241000,
        "value": 139.634,
        "_internal_originalTime": 1689810241000
    },
    {
        "time": 1689810242000,
        "value": 139.634,
        "_internal_originalTime": 1689810242000
    },
    {
        "time": 1689810249000,
        "value": 139.63,
        "_internal_originalTime": 1689810249000
    },
    {
        "time": 1689810264000,
        "value": 139.636,
        "_internal_originalTime": 1689810264000
    },
    {
        "time": 1689810274000,
        "value": 139.636,
        "_internal_originalTime": 1689810274000
    },
    {
        "time": 1689810288000,
        "value": 139.636,
        "_internal_originalTime": 1689810288000
    },
    {
        "time": 1689810299000,
        "value": 139.635,
        "_internal_originalTime": 1689810299000
    },
    {
        "time": 1689810302000,
        "value": 139.639,
        "_internal_originalTime": 1689810302000
    },
    {
        "time": 1689810306000,
        "value": 139.64,
        "_internal_originalTime": 1689810306000
    },
    {
        "time": 1689810312000,
        "value": 139.629,
        "_internal_originalTime": 1689810312000
    },
    {
        "time": 1689810316000,
        "value": 139.626,
        "_internal_originalTime": 1689810316000
    },
    {
        "time": 1689810322000,
        "value": 139.629,
        "_internal_originalTime": 1689810322000
    },
    {
        "time": 1689810330000,
        "value": 139.627,
        "_internal_originalTime": 1689810330000
    },
    {
        "time": 1689810333000,
        "value": 139.627,
        "_internal_originalTime": 1689810333000
    },
    {
        "time": 1689810338000,
        "value": 139.628,
        "_internal_originalTime": 1689810338000
    },
    {
        "time": 1689810342000,
        "value": 139.629,
        "_internal_originalTime": 1689810342000
    },
    {
        "time": 1689810345000,
        "value": 139.632,
        "_internal_originalTime": 1689810345000
    },
    {
        "time": 1689810354000,
        "value": 139.629,
        "_internal_originalTime": 1689810354000
    },
    {
        "time": 1689810359000,
        "value": 139.627,
        "_internal_originalTime": 1689810359000
    },
    {
        "time": 1689810363000,
        "value": 139.629,
        "_internal_originalTime": 1689810363000
    },
    {
        "time": 1689810367000,
        "value": 139.625,
        "_internal_originalTime": 1689810367000
    },
    {
        "time": 1689810372000,
        "value": 139.626,
        "_internal_originalTime": 1689810372000
    },
    {
        "time": 1689810374000,
        "value": 139.626,
        "_internal_originalTime": 1689810374000
    },
    {
        "time": 1689810381000,
        "value": 139.63,
        "_internal_originalTime": 1689810381000
    },
    {
        "time": 1689810386000,
        "value": 139.636,
        "_internal_originalTime": 1689810386000
    },
    {
        "time": 1689810391000,
        "value": 139.629,
        "_internal_originalTime": 1689810391000
    },
    {
        "time": 1689810397000,
        "value": 139.629,
        "_internal_originalTime": 1689810397000
    },
    {
        "time": 1689810399000,
        "value": 139.627,
        "_internal_originalTime": 1689810399000
    },
    {
        "time": 1689810405000,
        "value": 139.633,
        "_internal_originalTime": 1689810405000
    },
    {
        "time": 1689810410000,
        "value": 139.631,
        "_internal_originalTime": 1689810410000
    },
    {
        "time": 1689810416000,
        "value": 139.637,
        "_internal_originalTime": 1689810416000
    },
    {
        "time": 1689810422000,
        "value": 139.635,
        "_internal_originalTime": 1689810422000
    },
    {
        "time": 1689810427000,
        "value": 139.64,
        "_internal_originalTime": 1689810427000
    },
    {
        "time": 1689810432000,
        "value": 139.636,
        "_internal_originalTime": 1689810432000
    },
    {
        "time": 1689810437000,
        "value": 139.634,
        "_internal_originalTime": 1689810437000
    },
    {
        "time": 1689810441000,
        "value": 139.638,
        "_internal_originalTime": 1689810441000
    },
    {
        "time": 1689810446000,
        "value": 139.634,
        "_internal_originalTime": 1689810446000
    },
    {
        "time": 1689810452000,
        "value": 139.634,
        "_internal_originalTime": 1689810452000
    },
    {
        "time": 1689810458000,
        "value": 139.641,
        "_internal_originalTime": 1689810458000
    },
    {
        "time": 1689810464000,
        "value": 139.65,
        "_internal_originalTime": 1689810464000
    },
    {
        "time": 1689810469000,
        "value": 139.647,
        "_internal_originalTime": 1689810469000
    },
    {
        "time": 1689810476000,
        "value": 139.649,
        "_internal_originalTime": 1689810476000
    },
    {
        "time": 1689810478000,
        "value": 139.649,
        "_internal_originalTime": 1689810478000
    },
    {
        "time": 1689810484000,
        "value": 139.647,
        "_internal_originalTime": 1689810484000
    },
    {
        "time": 1689810490000,
        "value": 139.636,
        "_internal_originalTime": 1689810490000
    },
    {
        "time": 1689810493000,
        "value": 139.642,
        "_internal_originalTime": 1689810493000
    },
    {
        "time": 1689810500000,
        "value": 139.648,
        "_internal_originalTime": 1689810500000
    },
    {
        "time": 1689810507000,
        "value": 139.653,
        "_internal_originalTime": 1689810507000
    },
    {
        "time": 1689810511000,
        "value": 139.665,
        "_internal_originalTime": 1689810511000
    },
    {
        "time": 1689810516000,
        "value": 139.664,
        "_internal_originalTime": 1689810516000
    },
    {
        "time": 1689810522000,
        "value": 139.672,
        "_internal_originalTime": 1689810522000
    },
    {
        "time": 1689810528000,
        "value": 139.676,
        "_internal_originalTime": 1689810528000
    },
    {
        "time": 1689810531000,
        "value": 139.666,
        "_internal_originalTime": 1689810531000
    },
    {
        "time": 1689810538000,
        "value": 139.666,
        "_internal_originalTime": 1689810538000
    },
    {
        "time": 1689810539000,
        "value": 139.666,
        "_internal_originalTime": 1689810539000
    },
    {
        "time": 1689810542000,
        "value": 139.666,
        "_internal_originalTime": 1689810542000
    },
    {
        "time": 1689810544000,
        "value": 139.666,
        "_internal_originalTime": 1689810544000
    },
    {
        "time": 1689810549000,
        "value": 139.666,
        "_internal_originalTime": 1689810549000
    },
    {
        "time": 1689810553000,
        "value": 139.665,
        "_internal_originalTime": 1689810553000
    },
    {
        "time": 1689810556000,
        "value": 139.667,
        "_internal_originalTime": 1689810556000
    },
    {
        "time": 1689810565000,
        "value": 139.664,
        "_internal_originalTime": 1689810565000
    },
    {
        "time": 1689810571000,
        "value": 139.675,
        "_internal_originalTime": 1689810571000
    },
    {
        "time": 1689810577000,
        "value": 139.668,
        "_internal_originalTime": 1689810577000
    },
    {
        "time": 1689810582000,
        "value": 139.664,
        "_internal_originalTime": 1689810582000
    },
    {
        "time": 1689810587000,
        "value": 139.664,
        "_internal_originalTime": 1689810587000
    },
    {
        "time": 1689810592000,
        "value": 139.668,
        "_internal_originalTime": 1689810592000
    },
    {
        "time": 1689810596000,
        "value": 139.665,
        "_internal_originalTime": 1689810596000
    },
    {
        "time": 1689810601000,
        "value": 139.648,
        "_internal_originalTime": 1689810601000
    },
    {
        "time": 1689810606000,
        "value": 139.65,
        "_internal_originalTime": 1689810606000
    },
    {
        "time": 1689810617000,
        "value": 139.65,
        "_internal_originalTime": 1689810617000
    },
    {
        "time": 1689810623000,
        "value": 139.652,
        "_internal_originalTime": 1689810623000
    },
    {
        "time": 1689810629000,
        "value": 139.651,
        "_internal_originalTime": 1689810629000
    },
    {
        "time": 1689810630000,
        "value": 139.653,
        "_internal_originalTime": 1689810630000
    },
    {
        "time": 1689810639000,
        "value": 139.65,
        "_internal_originalTime": 1689810639000
    },
    {
        "time": 1689810645000,
        "value": 139.642,
        "_internal_originalTime": 1689810645000
    },
    {
        "time": 1689810650000,
        "value": 139.645,
        "_internal_originalTime": 1689810650000
    },
    {
        "time": 1689810655000,
        "value": 139.641,
        "_internal_originalTime": 1689810655000
    },
    {
        "time": 1689810661000,
        "value": 139.65,
        "_internal_originalTime": 1689810661000
    },
    {
        "time": 1689810667000,
        "value": 139.648,
        "_internal_originalTime": 1689810667000
    },
    {
        "time": 1689810675000,
        "value": 139.644,
        "_internal_originalTime": 1689810675000
    },
    {
        "time": 1689810680000,
        "value": 139.648,
        "_internal_originalTime": 1689810680000
    },
    {
        "time": 1689810685000,
        "value": 139.64,
        "_internal_originalTime": 1689810685000
    },
    {
        "time": 1689810690000,
        "value": 139.642,
        "_internal_originalTime": 1689810690000
    },
    {
        "time": 1689810696000,
        "value": 139.642,
        "_internal_originalTime": 1689810696000
    },
    {
        "time": 1689810699000,
        "value": 139.644,
        "_internal_originalTime": 1689810699000
    },
    {
        "time": 1689810708000,
        "value": 139.642,
        "_internal_originalTime": 1689810708000
    },
    {
        "time": 1689810713000,
        "value": 139.644,
        "_internal_originalTime": 1689810713000
    },
    {
        "time": 1689810715000,
        "value": 139.641,
        "_internal_originalTime": 1689810715000
    },
    {
        "time": 1689810721000,
        "value": 139.638,
        "_internal_originalTime": 1689810721000
    },
    {
        "time": 1689810725000,
        "value": 139.637,
        "_internal_originalTime": 1689810725000
    },
    {
        "time": 1689810732000,
        "value": 139.64,
        "_internal_originalTime": 1689810732000
    },
    {
        "time": 1689810735000,
        "value": 139.64,
        "_internal_originalTime": 1689810735000
    },
    {
        "time": 1689810739000,
        "value": 139.635,
        "_internal_originalTime": 1689810739000
    },
    {
        "time": 1689810745000,
        "value": 139.632,
        "_internal_originalTime": 1689810745000
    },
    {
        "time": 1689810750000,
        "value": 139.635,
        "_internal_originalTime": 1689810750000
    },
    {
        "time": 1689810753000,
        "value": 139.639,
        "_internal_originalTime": 1689810753000
    },
    {
        "time": 1689810759000,
        "value": 139.639,
        "_internal_originalTime": 1689810759000
    },
    {
        "time": 1689810770000,
        "value": 139.633,
        "_internal_originalTime": 1689810770000
    },
    {
        "time": 1689810775000,
        "value": 139.632,
        "_internal_originalTime": 1689810775000
    },
    {
        "time": 1689810778000,
        "value": 139.64,
        "_internal_originalTime": 1689810778000
    },
    {
        "time": 1689810782000,
        "value": 139.638,
        "_internal_originalTime": 1689810782000
    },
    {
        "time": 1689810787000,
        "value": 139.639,
        "_internal_originalTime": 1689810787000
    },
    {
        "time": 1689810792000,
        "value": 139.633,
        "_internal_originalTime": 1689810792000
    },
    {
        "time": 1689810795000,
        "value": 139.624,
        "_internal_originalTime": 1689810795000
    },
    {
        "time": 1689810807000,
        "value": 139.629,
        "_internal_originalTime": 1689810807000
    },
    {
        "time": 1689810813000,
        "value": 139.63,
        "_internal_originalTime": 1689810813000
    },
    {
        "time": 1689810822000,
        "value": 139.627,
        "_internal_originalTime": 1689810822000
    },
    {
        "time": 1689810823000,
        "value": 139.628,
        "_internal_originalTime": 1689810823000
    },
    {
        "time": 1689810832000,
        "value": 139.624,
        "_internal_originalTime": 1689810832000
    },
    {
        "time": 1689810837000,
        "value": 139.624,
        "_internal_originalTime": 1689810837000
    },
    {
        "time": 1689810841000,
        "value": 139.628,
        "_internal_originalTime": 1689810841000
    },
    {
        "time": 1689810844000,
        "value": 139.634,
        "_internal_originalTime": 1689810844000
    },
    {
        "time": 1689810849000,
        "value": 139.637,
        "_internal_originalTime": 1689810849000
    },
    {
        "time": 1689810854000,
        "value": 139.638,
        "_internal_originalTime": 1689810854000
    },
    {
        "time": 1689810856000,
        "value": 139.637,
        "_internal_originalTime": 1689810856000
    },
    {
        "time": 1689810865000,
        "value": 139.63,
        "_internal_originalTime": 1689810865000
    },
    {
        "time": 1689810870000,
        "value": 139.634,
        "_internal_originalTime": 1689810870000
    },
    {
        "time": 1689810871000,
        "value": 139.638,
        "_internal_originalTime": 1689810871000
    },
    {
        "time": 1689810879000,
        "value": 139.639,
        "_internal_originalTime": 1689810879000
    },
    {
        "time": 1689810886000,
        "value": 139.63,
        "_internal_originalTime": 1689810886000
    },
    {
        "time": 1689810889000,
        "value": 139.63,
        "_internal_originalTime": 1689810889000
    },
    {
        "time": 1689810895000,
        "value": 139.63,
        "_internal_originalTime": 1689810895000
    },
    {
        "time": 1689810897000,
        "value": 139.637,
        "_internal_originalTime": 1689810897000
    },
    {
        "time": 1689810902000,
        "value": 139.635,
        "_internal_originalTime": 1689810902000
    },
    {
        "time": 1689810906000,
        "value": 139.624,
        "_internal_originalTime": 1689810906000
    },
    {
        "time": 1689810913000,
        "value": 139.624,
        "_internal_originalTime": 1689810913000
    },
    {
        "time": 1689810917000,
        "value": 139.63,
        "_internal_originalTime": 1689810917000
    },
    {
        "time": 1689810922000,
        "value": 139.638,
        "_internal_originalTime": 1689810922000
    },
    {
        "time": 1689810926000,
        "value": 139.632,
        "_internal_originalTime": 1689810926000
    },
    {
        "time": 1689810932000,
        "value": 139.637,
        "_internal_originalTime": 1689810932000
    },
    {
        "time": 1689810937000,
        "value": 139.63,
        "_internal_originalTime": 1689810937000
    },
    {
        "time": 1689810939000,
        "value": 139.634,
        "_internal_originalTime": 1689810939000
    },
    {
        "time": 1689810946000,
        "value": 139.637,
        "_internal_originalTime": 1689810946000
    },
    {
        "time": 1689810947000,
        "value": 139.636,
        "_internal_originalTime": 1689810947000
    },
    {
        "time": 1689810956000,
        "value": 139.633,
        "_internal_originalTime": 1689810956000
    },
    {
        "time": 1689810959000,
        "value": 139.633,
        "_internal_originalTime": 1689810959000
    },
    {
        "time": 1689810973000,
        "value": 139.632,
        "_internal_originalTime": 1689810973000
    },
    {
        "time": 1689810979000,
        "value": 139.633,
        "_internal_originalTime": 1689810979000
    },
    {
        "time": 1689810990000,
        "value": 139.628,
        "_internal_originalTime": 1689810990000
    },
    {
        "time": 1689810994000,
        "value": 139.628,
        "_internal_originalTime": 1689810994000
    },
    {
        "time": 1689810999000,
        "value": 139.618,
        "_internal_originalTime": 1689810999000
    },
    {
        "time": 1689811004000,
        "value": 139.617,
        "_internal_originalTime": 1689811004000
    },
    {
        "time": 1689811008000,
        "value": 139.61,
        "_internal_originalTime": 1689811008000
    },
    {
        "time": 1689811013000,
        "value": 139.613,
        "_internal_originalTime": 1689811013000
    },
    {
        "time": 1689811018000,
        "value": 139.609,
        "_internal_originalTime": 1689811018000
    },
    {
        "time": 1689811020000,
        "value": 139.606,
        "_internal_originalTime": 1689811020000
    },
    {
        "time": 1689811023000,
        "value": 139.604,
        "_internal_originalTime": 1689811023000
    },
    {
        "time": 1689811029000,
        "value": 139.607,
        "_internal_originalTime": 1689811029000
    },
    {
        "time": 1689811032000,
        "value": 139.608,
        "_internal_originalTime": 1689811032000
    },
    {
        "time": 1689811041000,
        "value": 139.617,
        "_internal_originalTime": 1689811041000
    },
    {
        "time": 1689811045000,
        "value": 139.612,
        "_internal_originalTime": 1689811045000
    },
    {
        "time": 1689811049000,
        "value": 139.613,
        "_internal_originalTime": 1689811049000
    },
    {
        "time": 1689811057000,
        "value": 139.604,
        "_internal_originalTime": 1689811057000
    },
    {
        "time": 1689811064000,
        "value": 139.605,
        "_internal_originalTime": 1689811064000
    },
    {
        "time": 1689811065000,
        "value": 139.604,
        "_internal_originalTime": 1689811065000
    },
    {
        "time": 1689811071000,
        "value": 139.608,
        "_internal_originalTime": 1689811071000
    },
    {
        "time": 1689811078000,
        "value": 139.604,
        "_internal_originalTime": 1689811078000
    },
    {
        "time": 1689811080000,
        "value": 139.602,
        "_internal_originalTime": 1689811080000
    },
    {
        "time": 1689811084000,
        "value": 139.602,
        "_internal_originalTime": 1689811084000
    },
    {
        "time": 1689811089000,
        "value": 139.606,
        "_internal_originalTime": 1689811089000
    },
    {
        "time": 1689811094000,
        "value": 139.609,
        "_internal_originalTime": 1689811094000
    },
    {
        "time": 1689811099000,
        "value": 139.604,
        "_internal_originalTime": 1689811099000
    },
    {
        "time": 1689811104000,
        "value": 139.592,
        "_internal_originalTime": 1689811104000
    },
    {
        "time": 1689811105000,
        "value": 139.592,
        "_internal_originalTime": 1689811105000
    },
    {
        "time": 1689811113000,
        "value": 139.598,
        "_internal_originalTime": 1689811113000
    },
    {
        "time": 1689811119000,
        "value": 139.598,
        "_internal_originalTime": 1689811119000
    },
    {
        "time": 1689811124000,
        "value": 139.601,
        "_internal_originalTime": 1689811124000
    },
    {
        "time": 1689811127000,
        "value": 139.596,
        "_internal_originalTime": 1689811127000
    },
    {
        "time": 1689811134000,
        "value": 139.598,
        "_internal_originalTime": 1689811134000
    },
    {
        "time": 1689811139000,
        "value": 139.594,
        "_internal_originalTime": 1689811139000
    },
    {
        "time": 1689811141000,
        "value": 139.595,
        "_internal_originalTime": 1689811141000
    },
    {
        "time": 1689811142000,
        "value": 139.596,
        "_internal_originalTime": 1689811142000
    },
    {
        "time": 1689811145000,
        "value": 139.598,
        "_internal_originalTime": 1689811145000
    },
    {
        "time": 1689811146000,
        "value": 139.598,
        "_internal_originalTime": 1689811146000
    },
    {
        "time": 1689811152000,
        "value": 139.608,
        "_internal_originalTime": 1689811152000
    },
    {
        "time": 1689811158000,
        "value": 139.609,
        "_internal_originalTime": 1689811158000
    },
    {
        "time": 1689811163000,
        "value": 139.61,
        "_internal_originalTime": 1689811163000
    },
    {
        "time": 1689811165000,
        "value": 139.61,
        "_internal_originalTime": 1689811165000
    },
    {
        "time": 1689811173000,
        "value": 139.603,
        "_internal_originalTime": 1689811173000
    },
    {
        "time": 1689811180000,
        "value": 139.604,
        "_internal_originalTime": 1689811180000
    },
    {
        "time": 1689811184000,
        "value": 139.604,
        "_internal_originalTime": 1689811184000
    },
    {
        "time": 1689811190000,
        "value": 139.61,
        "_internal_originalTime": 1689811190000
    },
    {
        "time": 1689811195000,
        "value": 139.6,
        "_internal_originalTime": 1689811195000
    },
    {
        "time": 1689811199000,
        "value": 139.609,
        "_internal_originalTime": 1689811199000
    },
    {
        "time": 1689811202000,
        "value": 139.609,
        "_internal_originalTime": 1689811202000
    },
    {
        "time": 1689811205000,
        "value": 139.609,
        "_internal_originalTime": 1689811205000
    },
    {
        "time": 1689811208000,
        "value": 139.607,
        "_internal_originalTime": 1689811208000
    },
    {
        "time": 1689811212000,
        "value": 139.6,
        "_internal_originalTime": 1689811212000
    },
    {
        "time": 1689811214000,
        "value": 139.607,
        "_internal_originalTime": 1689811214000
    },
    {
        "time": 1689811218000,
        "value": 139.609,
        "_internal_originalTime": 1689811218000
    },
    {
        "time": 1689811222000,
        "value": 139.645,
        "_internal_originalTime": 1689811222000
    },
    {
        "time": 1689811226000,
        "value": 139.617,
        "_internal_originalTime": 1689811226000
    },
    {
        "time": 1689811230000,
        "value": 139.607,
        "_internal_originalTime": 1689811230000
    },
    {
        "time": 1689811233000,
        "value": 139.601,
        "_internal_originalTime": 1689811233000
    },
    {
        "time": 1689811236000,
        "value": 139.588,
        "_internal_originalTime": 1689811236000
    },
    {
        "time": 1689811241000,
        "value": 139.579,
        "_internal_originalTime": 1689811241000
    },
    {
        "time": 1689811245000,
        "value": 139.586,
        "_internal_originalTime": 1689811245000
    },
    {
        "time": 1689811248000,
        "value": 139.589,
        "_internal_originalTime": 1689811248000
    },
    {
        "time": 1689811252000,
        "value": 139.588,
        "_internal_originalTime": 1689811252000
    },
    {
        "time": 1689811256000,
        "value": 139.607,
        "_internal_originalTime": 1689811256000
    },
    {
        "time": 1689811259000,
        "value": 139.611,
        "_internal_originalTime": 1689811259000
    },
    {
        "time": 1689811261000,
        "value": 139.604,
        "_internal_originalTime": 1689811261000
    },
    {
        "time": 1689811265000,
        "value": 139.637,
        "_internal_originalTime": 1689811265000
    },
    {
        "time": 1689811268000,
        "value": 139.649,
        "_internal_originalTime": 1689811268000
    },
    {
        "time": 1689811272000,
        "value": 139.646,
        "_internal_originalTime": 1689811272000
    },
    {
        "time": 1689811275000,
        "value": 139.662,
        "_internal_originalTime": 1689811275000
    },
    {
        "time": 1689811279000,
        "value": 139.663,
        "_internal_originalTime": 1689811279000
    },
    {
        "time": 1689811283000,
        "value": 139.662,
        "_internal_originalTime": 1689811283000
    },
    {
        "time": 1689811287000,
        "value": 139.679,
        "_internal_originalTime": 1689811287000
    },
    {
        "time": 1689811290000,
        "value": 139.671,
        "_internal_originalTime": 1689811290000
    },
    {
        "time": 1689811293000,
        "value": 139.652,
        "_internal_originalTime": 1689811293000
    },
    {
        "time": 1689811297000,
        "value": 139.654,
        "_internal_originalTime": 1689811297000
    },
    {
        "time": 1689811300000,
        "value": 139.659,
        "_internal_originalTime": 1689811300000
    },
    {
        "time": 1689811304000,
        "value": 139.676,
        "_internal_originalTime": 1689811304000
    },
    {
        "time": 1689811306000,
        "value": 139.671,
        "_internal_originalTime": 1689811306000
    },
    {
        "time": 1689811311000,
        "value": 139.67,
        "_internal_originalTime": 1689811311000
    },
    {
        "time": 1689811316000,
        "value": 139.668,
        "_internal_originalTime": 1689811316000
    },
    {
        "time": 1689811319000,
        "value": 139.666,
        "_internal_originalTime": 1689811319000
    },
    {
        "time": 1689811321000,
        "value": 139.663,
        "_internal_originalTime": 1689811321000
    },
    {
        "time": 1689811324000,
        "value": 139.667,
        "_internal_originalTime": 1689811324000
    },
    {
        "time": 1689811328000,
        "value": 139.678,
        "_internal_originalTime": 1689811328000
    },
    {
        "time": 1689811330000,
        "value": 139.681,
        "_internal_originalTime": 1689811330000
    },
    {
        "time": 1689811335000,
        "value": 139.675,
        "_internal_originalTime": 1689811335000
    },
    {
        "time": 1689811339000,
        "value": 139.685,
        "_internal_originalTime": 1689811339000
    },
    {
        "time": 1689811343000,
        "value": 139.683,
        "_internal_originalTime": 1689811343000
    },
    {
        "time": 1689811348000,
        "value": 139.698,
        "_internal_originalTime": 1689811348000
    },
    {
        "time": 1689811351000,
        "value": 139.694,
        "_internal_originalTime": 1689811351000
    },
    {
        "time": 1689811355000,
        "value": 139.687,
        "_internal_originalTime": 1689811355000
    },
    {
        "time": 1689811360000,
        "value": 139.687,
        "_internal_originalTime": 1689811360000
    },
    {
        "time": 1689811364000,
        "value": 139.694,
        "_internal_originalTime": 1689811364000
    },
    {
        "time": 1689811367000,
        "value": 139.69,
        "_internal_originalTime": 1689811367000
    },
    {
        "time": 1689811370000,
        "value": 139.686,
        "_internal_originalTime": 1689811370000
    },
    {
        "time": 1689811375000,
        "value": 139.699,
        "_internal_originalTime": 1689811375000
    },
    {
        "time": 1689811378000,
        "value": 139.7,
        "_internal_originalTime": 1689811378000
    },
    {
        "time": 1689811380000,
        "value": 139.694,
        "_internal_originalTime": 1689811380000
    },
    {
        "time": 1689811383000,
        "value": 139.682,
        "_internal_originalTime": 1689811383000
    },
    {
        "time": 1689811387000,
        "value": 139.68,
        "_internal_originalTime": 1689811387000
    },
    {
        "time": 1689811391000,
        "value": 139.674,
        "_internal_originalTime": 1689811391000
    },
    {
        "time": 1689811395000,
        "value": 139.675,
        "_internal_originalTime": 1689811395000
    },
    {
        "time": 1689811398000,
        "value": 139.67,
        "_internal_originalTime": 1689811398000
    },
    {
        "time": 1689811407000,
        "value": 139.668,
        "_internal_originalTime": 1689811407000
    },
    {
        "time": 1689811410000,
        "value": 139.67,
        "_internal_originalTime": 1689811410000
    },
    {
        "time": 1689811414000,
        "value": 139.669,
        "_internal_originalTime": 1689811414000
    },
    {
        "time": 1689811418000,
        "value": 139.672,
        "_internal_originalTime": 1689811418000
    },
    {
        "time": 1689811422000,
        "value": 139.67,
        "_internal_originalTime": 1689811422000
    },
    {
        "time": 1689811426000,
        "value": 139.668,
        "_internal_originalTime": 1689811426000
    },
    {
        "time": 1689811429000,
        "value": 139.67,
        "_internal_originalTime": 1689811429000
    },
    {
        "time": 1689811434000,
        "value": 139.668,
        "_internal_originalTime": 1689811434000
    },
    {
        "time": 1689811438000,
        "value": 139.68,
        "_internal_originalTime": 1689811438000
    },
    {
        "time": 1689811439000,
        "value": 139.68,
        "_internal_originalTime": 1689811439000
    },
    {
        "time": 1689811441000,
        "value": 139.683,
        "_internal_originalTime": 1689811441000
    },
    {
        "time": 1689811442000,
        "value": 139.664,
        "_internal_originalTime": 1689811442000
    },
    {
        "time": 1689811443000,
        "value": 139.665,
        "_internal_originalTime": 1689811443000
    },
    {
        "time": 1689811446000,
        "value": 139.66,
        "_internal_originalTime": 1689811446000
    },
    {
        "time": 1689811450000,
        "value": 139.667,
        "_internal_originalTime": 1689811450000
    },
    {
        "time": 1689811455000,
        "value": 139.664,
        "_internal_originalTime": 1689811455000
    },
    {
        "time": 1689811458000,
        "value": 139.666,
        "_internal_originalTime": 1689811458000
    },
    {
        "time": 1689811461000,
        "value": 139.67,
        "_internal_originalTime": 1689811461000
    },
    {
        "time": 1689811467000,
        "value": 139.667,
        "_internal_originalTime": 1689811467000
    },
    {
        "time": 1689811470000,
        "value": 139.665,
        "_internal_originalTime": 1689811470000
    },
    {
        "time": 1689811474000,
        "value": 139.656,
        "_internal_originalTime": 1689811474000
    },
    {
        "time": 1689811477000,
        "value": 139.655,
        "_internal_originalTime": 1689811477000
    },
    {
        "time": 1689811482000,
        "value": 139.648,
        "_internal_originalTime": 1689811482000
    },
    {
        "time": 1689811486000,
        "value": 139.646,
        "_internal_originalTime": 1689811486000
    },
    {
        "time": 1689811491000,
        "value": 139.646,
        "_internal_originalTime": 1689811491000
    },
    {
        "time": 1689811495000,
        "value": 139.631,
        "_internal_originalTime": 1689811495000
    },
    {
        "time": 1689811498000,
        "value": 139.631,
        "_internal_originalTime": 1689811498000
    },
    {
        "time": 1689811500000,
        "value": 139.636,
        "_internal_originalTime": 1689811500000
    },
    {
        "time": 1689811503000,
        "value": 139.625,
        "_internal_originalTime": 1689811503000
    },
    {
        "time": 1689811507000,
        "value": 139.622,
        "_internal_originalTime": 1689811507000
    },
    {
        "time": 1689811511000,
        "value": 139.62,
        "_internal_originalTime": 1689811511000
    },
    {
        "time": 1689811514000,
        "value": 139.635,
        "_internal_originalTime": 1689811514000
    },
    {
        "time": 1689811518000,
        "value": 139.627,
        "_internal_originalTime": 1689811518000
    },
    {
        "time": 1689811522000,
        "value": 139.626,
        "_internal_originalTime": 1689811522000
    },
    {
        "time": 1689811527000,
        "value": 139.616,
        "_internal_originalTime": 1689811527000
    },
    {
        "time": 1689811530000,
        "value": 139.596,
        "_internal_originalTime": 1689811530000
    },
    {
        "time": 1689811534000,
        "value": 139.591,
        "_internal_originalTime": 1689811534000
    },
    {
        "time": 1689811538000,
        "value": 139.596,
        "_internal_originalTime": 1689811538000
    },
    {
        "time": 1689811543000,
        "value": 139.575,
        "_internal_originalTime": 1689811543000
    },
    {
        "time": 1689811545000,
        "value": 139.58,
        "_internal_originalTime": 1689811545000
    },
    {
        "time": 1689811549000,
        "value": 139.561,
        "_internal_originalTime": 1689811549000
    },
    {
        "time": 1689811552000,
        "value": 139.555,
        "_internal_originalTime": 1689811552000
    },
    {
        "time": 1689811557000,
        "value": 139.555,
        "_internal_originalTime": 1689811557000
    },
    {
        "time": 1689811559000,
        "value": 139.554,
        "_internal_originalTime": 1689811559000
    },
    {
        "time": 1689811561000,
        "value": 139.562,
        "_internal_originalTime": 1689811561000
    },
    {
        "time": 1689811565000,
        "value": 139.558,
        "_internal_originalTime": 1689811565000
    },
    {
        "time": 1689811570000,
        "value": 139.546,
        "_internal_originalTime": 1689811570000
    },
    {
        "time": 1689811574000,
        "value": 139.555,
        "_internal_originalTime": 1689811574000
    },
    {
        "time": 1689811577000,
        "value": 139.554,
        "_internal_originalTime": 1689811577000
    },
    {
        "time": 1689811581000,
        "value": 139.552,
        "_internal_originalTime": 1689811581000
    },
    {
        "time": 1689811585000,
        "value": 139.552,
        "_internal_originalTime": 1689811585000
    },
    {
        "time": 1689811589000,
        "value": 139.554,
        "_internal_originalTime": 1689811589000
    },
    {
        "time": 1689811594000,
        "value": 139.555,
        "_internal_originalTime": 1689811594000
    },
    {
        "time": 1689811598000,
        "value": 139.562,
        "_internal_originalTime": 1689811598000
    },
    {
        "time": 1689811603000,
        "value": 139.558,
        "_internal_originalTime": 1689811603000
    },
    {
        "time": 1689811607000,
        "value": 139.559,
        "_internal_originalTime": 1689811607000
    },
    {
        "time": 1689811610000,
        "value": 139.548,
        "_internal_originalTime": 1689811610000
    },
    {
        "time": 1689811615000,
        "value": 139.546,
        "_internal_originalTime": 1689811615000
    },
    {
        "time": 1689811619000,
        "value": 139.549,
        "_internal_originalTime": 1689811619000
    },
    {
        "time": 1689811622000,
        "value": 139.546,
        "_internal_originalTime": 1689811622000
    },
    {
        "time": 1689811624000,
        "value": 139.546,
        "_internal_originalTime": 1689811624000
    },
    {
        "time": 1689811628000,
        "value": 139.55,
        "_internal_originalTime": 1689811628000
    },
    {
        "time": 1689811632000,
        "value": 139.549,
        "_internal_originalTime": 1689811632000
    },
    {
        "time": 1689811634000,
        "value": 139.548,
        "_internal_originalTime": 1689811634000
    },
    {
        "time": 1689811637000,
        "value": 139.549,
        "_internal_originalTime": 1689811637000
    },
    {
        "time": 1689811643000,
        "value": 139.549,
        "_internal_originalTime": 1689811643000
    },
    {
        "time": 1689811648000,
        "value": 139.542,
        "_internal_originalTime": 1689811648000
    },
    {
        "time": 1689811651000,
        "value": 139.537,
        "_internal_originalTime": 1689811651000
    },
    {
        "time": 1689811655000,
        "value": 139.528,
        "_internal_originalTime": 1689811655000
    },
    {
        "time": 1689811659000,
        "value": 139.519,
        "_internal_originalTime": 1689811659000
    },
    {
        "time": 1689811664000,
        "value": 139.518,
        "_internal_originalTime": 1689811664000
    },
    {
        "time": 1689811667000,
        "value": 139.532,
        "_internal_originalTime": 1689811667000
    },
    {
        "time": 1689811671000,
        "value": 139.541,
        "_internal_originalTime": 1689811671000
    },
    {
        "time": 1689811676000,
        "value": 139.54,
        "_internal_originalTime": 1689811676000
    },
    {
        "time": 1689811678000,
        "value": 139.539,
        "_internal_originalTime": 1689811678000
    },
    {
        "time": 1689811681000,
        "value": 139.539,
        "_internal_originalTime": 1689811681000
    },
    {
        "time": 1689811684000,
        "value": 139.546,
        "_internal_originalTime": 1689811684000
    },
    {
        "time": 1689811689000,
        "value": 139.55,
        "_internal_originalTime": 1689811689000
    },
    {
        "time": 1689811692000,
        "value": 139.567,
        "_internal_originalTime": 1689811692000
    },
    {
        "time": 1689811695000,
        "value": 139.559,
        "_internal_originalTime": 1689811695000
    },
    {
        "time": 1689811699000,
        "value": 139.555,
        "_internal_originalTime": 1689811699000
    },
    {
        "time": 1689811702000,
        "value": 139.55,
        "_internal_originalTime": 1689811702000
    },
    {
        "time": 1689811706000,
        "value": 139.549,
        "_internal_originalTime": 1689811706000
    },
    {
        "time": 1689811710000,
        "value": 139.549,
        "_internal_originalTime": 1689811710000
    },
    {
        "time": 1689811713000,
        "value": 139.544,
        "_internal_originalTime": 1689811713000
    },
    {
        "time": 1689811718000,
        "value": 139.547,
        "_internal_originalTime": 1689811718000
    },
    {
        "time": 1689811720000,
        "value": 139.548,
        "_internal_originalTime": 1689811720000
    },
    {
        "time": 1689811726000,
        "value": 139.539,
        "_internal_originalTime": 1689811726000
    },
    {
        "time": 1689811730000,
        "value": 139.528,
        "_internal_originalTime": 1689811730000
    },
    {
        "time": 1689811734000,
        "value": 139.536,
        "_internal_originalTime": 1689811734000
    },
    {
        "time": 1689811738000,
        "value": 139.545,
        "_internal_originalTime": 1689811738000
    },
    {
        "time": 1689811739000,
        "value": 139.544,
        "_internal_originalTime": 1689811739000
    },
    {
        "time": 1689811740000,
        "value": 139.545,
        "_internal_originalTime": 1689811740000
    },
    {
        "time": 1689811741000,
        "value": 139.544,
        "_internal_originalTime": 1689811741000
    },
    {
        "time": 1689811743000,
        "value": 139.55,
        "_internal_originalTime": 1689811743000
    },
    {
        "time": 1689811747000,
        "value": 139.549,
        "_internal_originalTime": 1689811747000
    },
    {
        "time": 1689811752000,
        "value": 139.548,
        "_internal_originalTime": 1689811752000
    },
    {
        "time": 1689811756000,
        "value": 139.552,
        "_internal_originalTime": 1689811756000
    },
    {
        "time": 1689811759000,
        "value": 139.551,
        "_internal_originalTime": 1689811759000
    },
    {
        "time": 1689811764000,
        "value": 139.55,
        "_internal_originalTime": 1689811764000
    },
    {
        "time": 1689811766000,
        "value": 139.543,
        "_internal_originalTime": 1689811766000
    },
    {
        "time": 1689811771000,
        "value": 139.552,
        "_internal_originalTime": 1689811771000
    },
    {
        "time": 1689811775000,
        "value": 139.545,
        "_internal_originalTime": 1689811775000
    },
    {
        "time": 1689811779000,
        "value": 139.547,
        "_internal_originalTime": 1689811779000
    },
    {
        "time": 1689811784000,
        "value": 139.549,
        "_internal_originalTime": 1689811784000
    },
    {
        "time": 1689811786000,
        "value": 139.538,
        "_internal_originalTime": 1689811786000
    },
    {
        "time": 1689811790000,
        "value": 139.543,
        "_internal_originalTime": 1689811790000
    },
    {
        "time": 1689811794000,
        "value": 139.555,
        "_internal_originalTime": 1689811794000
    },
    {
        "time": 1689811799000,
        "value": 139.557,
        "_internal_originalTime": 1689811799000
    },
    {
        "time": 1689811802000,
        "value": 139.545,
        "_internal_originalTime": 1689811802000
    },
    {
        "time": 1689811804000,
        "value": 139.54,
        "_internal_originalTime": 1689811804000
    },
    {
        "time": 1689811809000,
        "value": 139.544,
        "_internal_originalTime": 1689811809000
    },
    {
        "time": 1689811814000,
        "value": 139.538,
        "_internal_originalTime": 1689811814000
    },
    {
        "time": 1689811816000,
        "value": 139.536,
        "_internal_originalTime": 1689811816000
    },
    {
        "time": 1689811820000,
        "value": 139.529,
        "_internal_originalTime": 1689811820000
    },
    {
        "time": 1689811824000,
        "value": 139.529,
        "_internal_originalTime": 1689811824000
    },
    {
        "time": 1689811827000,
        "value": 139.528,
        "_internal_originalTime": 1689811827000
    },
    {
        "time": 1689811831000,
        "value": 139.54,
        "_internal_originalTime": 1689811831000
    },
    {
        "time": 1689811834000,
        "value": 139.539,
        "_internal_originalTime": 1689811834000
    },
    {
        "time": 1689811838000,
        "value": 139.536,
        "_internal_originalTime": 1689811838000
    },
    {
        "time": 1689811842000,
        "value": 139.542,
        "_internal_originalTime": 1689811842000
    },
    {
        "time": 1689811844000,
        "value": 139.538,
        "_internal_originalTime": 1689811844000
    },
    {
        "time": 1689811849000,
        "value": 139.541,
        "_internal_originalTime": 1689811849000
    },
    {
        "time": 1689811853000,
        "value": 139.538,
        "_internal_originalTime": 1689811853000
    },
    {
        "time": 1689811857000,
        "value": 139.538,
        "_internal_originalTime": 1689811857000
    },
    {
        "time": 1689811859000,
        "value": 139.54,
        "_internal_originalTime": 1689811859000
    },
    {
        "time": 1689811861000,
        "value": 139.538,
        "_internal_originalTime": 1689811861000
    },
    {
        "time": 1689811865000,
        "value": 139.539,
        "_internal_originalTime": 1689811865000
    },
    {
        "time": 1689811869000,
        "value": 139.532,
        "_internal_originalTime": 1689811869000
    },
    {
        "time": 1689811874000,
        "value": 139.539,
        "_internal_originalTime": 1689811874000
    },
    {
        "time": 1689811875000,
        "value": 139.538,
        "_internal_originalTime": 1689811875000
    },
    {
        "time": 1689811880000,
        "value": 139.538,
        "_internal_originalTime": 1689811880000
    },
    {
        "time": 1689811884000,
        "value": 139.532,
        "_internal_originalTime": 1689811884000
    },
    {
        "time": 1689811889000,
        "value": 139.538,
        "_internal_originalTime": 1689811889000
    },
    {
        "time": 1689811891000,
        "value": 139.546,
        "_internal_originalTime": 1689811891000
    },
    {
        "time": 1689811895000,
        "value": 139.546,
        "_internal_originalTime": 1689811895000
    },
    {
        "time": 1689811899000,
        "value": 139.549,
        "_internal_originalTime": 1689811899000
    },
    {
        "time": 1689811903000,
        "value": 139.55,
        "_internal_originalTime": 1689811903000
    },
    {
        "time": 1689811906000,
        "value": 139.55,
        "_internal_originalTime": 1689811906000
    },
    {
        "time": 1689811908000,
        "value": 139.549,
        "_internal_originalTime": 1689811908000
    },
    {
        "time": 1689811913000,
        "value": 139.551,
        "_internal_originalTime": 1689811913000
    },
    {
        "time": 1689811918000,
        "value": 139.558,
        "_internal_originalTime": 1689811918000
    },
    {
        "time": 1689811921000,
        "value": 139.569,
        "_internal_originalTime": 1689811921000
    },
    {
        "time": 1689811923000,
        "value": 139.572,
        "_internal_originalTime": 1689811923000
    },
    {
        "time": 1689811929000,
        "value": 139.574,
        "_internal_originalTime": 1689811929000
    },
    {
        "time": 1689811933000,
        "value": 139.569,
        "_internal_originalTime": 1689811933000
    },
    {
        "time": 1689811936000,
        "value": 139.568,
        "_internal_originalTime": 1689811936000
    },
    {
        "time": 1689811937000,
        "value": 139.569,
        "_internal_originalTime": 1689811937000
    },
    {
        "time": 1689811946000,
        "value": 139.554,
        "_internal_originalTime": 1689811946000
    },
    {
        "time": 1689811947000,
        "value": 139.561,
        "_internal_originalTime": 1689811947000
    },
    {
        "time": 1689811952000,
        "value": 139.558,
        "_internal_originalTime": 1689811952000
    },
    {
        "time": 1689811957000,
        "value": 139.556,
        "_internal_originalTime": 1689811957000
    },
    {
        "time": 1689811961000,
        "value": 139.559,
        "_internal_originalTime": 1689811961000
    },
    {
        "time": 1689811964000,
        "value": 139.561,
        "_internal_originalTime": 1689811964000
    },
    {
        "time": 1689811968000,
        "value": 139.556,
        "_internal_originalTime": 1689811968000
    },
    {
        "time": 1689811972000,
        "value": 139.559,
        "_internal_originalTime": 1689811972000
    },
    {
        "time": 1689811976000,
        "value": 139.554,
        "_internal_originalTime": 1689811976000
    },
    {
        "time": 1689811979000,
        "value": 139.575,
        "_internal_originalTime": 1689811979000
    },
    {
        "time": 1689811980000,
        "value": 139.572,
        "_internal_originalTime": 1689811980000
    },
    {
        "time": 1689811985000,
        "value": 139.561,
        "_internal_originalTime": 1689811985000
    },
    {
        "time": 1689811988000,
        "value": 139.563,
        "_internal_originalTime": 1689811988000
    },
    {
        "time": 1689811992000,
        "value": 139.558,
        "_internal_originalTime": 1689811992000
    },
    {
        "time": 1689811993000,
        "value": 139.559,
        "_internal_originalTime": 1689811993000
    },
    {
        "time": 1689811998000,
        "value": 139.558,
        "_internal_originalTime": 1689811998000
    },
    {
        "time": 1689812001000,
        "value": 139.563,
        "_internal_originalTime": 1689812001000
    },
    {
        "time": 1689812006000,
        "value": 139.554,
        "_internal_originalTime": 1689812006000
    },
    {
        "time": 1689812008000,
        "value": 139.561,
        "_internal_originalTime": 1689812008000
    },
    {
        "time": 1689812016000,
        "value": 139.562,
        "_internal_originalTime": 1689812016000
    },
    {
        "time": 1689812019000,
        "value": 139.556,
        "_internal_originalTime": 1689812019000
    },
    {
        "time": 1689812023000,
        "value": 139.561,
        "_internal_originalTime": 1689812023000
    },
    {
        "time": 1689812027000,
        "value": 139.547,
        "_internal_originalTime": 1689812027000
    },
    {
        "time": 1689812031000,
        "value": 139.544,
        "_internal_originalTime": 1689812031000
    },
    {
        "time": 1689812033000,
        "value": 139.549,
        "_internal_originalTime": 1689812033000
    },
    {
        "time": 1689812038000,
        "value": 139.544,
        "_internal_originalTime": 1689812038000
    },
    {
        "time": 1689812039000,
        "value": 139.545,
        "_internal_originalTime": 1689812039000
    },
    {
        "time": 1689812040000,
        "value": 139.538,
        "_internal_originalTime": 1689812040000
    },
    {
        "time": 1689812041000,
        "value": 139.541,
        "_internal_originalTime": 1689812041000
    },
    {
        "time": 1689812042000,
        "value": 139.526,
        "_internal_originalTime": 1689812042000
    },
    {
        "time": 1689812044000,
        "value": 139.525,
        "_internal_originalTime": 1689812044000
    },
    {
        "time": 1689812048000,
        "value": 139.526,
        "_internal_originalTime": 1689812048000
    },
    {
        "time": 1689812051000,
        "value": 139.526,
        "_internal_originalTime": 1689812051000
    },
    {
        "time": 1689812055000,
        "value": 139.529,
        "_internal_originalTime": 1689812055000
    },
    {
        "time": 1689812059000,
        "value": 139.53,
        "_internal_originalTime": 1689812059000
    },
    {
        "time": 1689812063000,
        "value": 139.53,
        "_internal_originalTime": 1689812063000
    },
    {
        "time": 1689812066000,
        "value": 139.529,
        "_internal_originalTime": 1689812066000
    },
    {
        "time": 1689812071000,
        "value": 139.539,
        "_internal_originalTime": 1689812071000
    },
    {
        "time": 1689812073000,
        "value": 139.541,
        "_internal_originalTime": 1689812073000
    },
    {
        "time": 1689812077000,
        "value": 139.531,
        "_internal_originalTime": 1689812077000
    },
    {
        "time": 1689812081000,
        "value": 139.526,
        "_internal_originalTime": 1689812081000
    },
    {
        "time": 1689812085000,
        "value": 139.529,
        "_internal_originalTime": 1689812085000
    },
    {
        "time": 1689812090000,
        "value": 139.516,
        "_internal_originalTime": 1689812090000
    },
    {
        "time": 1689812094000,
        "value": 139.521,
        "_internal_originalTime": 1689812094000
    },
    {
        "time": 1689812098000,
        "value": 139.518,
        "_internal_originalTime": 1689812098000
    },
    {
        "time": 1689812100000,
        "value": 139.518,
        "_internal_originalTime": 1689812100000
    },
    {
        "time": 1689812101000,
        "value": 139.518,
        "_internal_originalTime": 1689812101000
    },
    {
        "time": 1689812102000,
        "value": 139.52,
        "_internal_originalTime": 1689812102000
    },
    {
        "time": 1689812104000,
        "value": 139.519,
        "_internal_originalTime": 1689812104000
    },
    {
        "time": 1689812105000,
        "value": 139.521,
        "_internal_originalTime": 1689812105000
    },
    {
        "time": 1689812108000,
        "value": 139.521,
        "_internal_originalTime": 1689812108000
    },
    {
        "time": 1689812109000,
        "value": 139.521,
        "_internal_originalTime": 1689812109000
    },
    {
        "time": 1689812112000,
        "value": 139.517,
        "_internal_originalTime": 1689812112000
    },
    {
        "time": 1689812113000,
        "value": 139.518,
        "_internal_originalTime": 1689812113000
    },
    {
        "time": 1689812115000,
        "value": 139.522,
        "_internal_originalTime": 1689812115000
    },
    {
        "time": 1689812118000,
        "value": 139.52,
        "_internal_originalTime": 1689812118000
    },
    {
        "time": 1689812121000,
        "value": 139.512,
        "_internal_originalTime": 1689812121000
    },
    {
        "time": 1689812122000,
        "value": 139.508,
        "_internal_originalTime": 1689812122000
    },
    {
        "time": 1689812125000,
        "value": 139.505,
        "_internal_originalTime": 1689812125000
    },
    {
        "time": 1689812128000,
        "value": 139.504,
        "_internal_originalTime": 1689812128000
    },
    {
        "time": 1689812129000,
        "value": 139.498,
        "_internal_originalTime": 1689812129000
    },
    {
        "time": 1689812132000,
        "value": 139.5,
        "_internal_originalTime": 1689812132000
    },
    {
        "time": 1689812133000,
        "value": 139.498,
        "_internal_originalTime": 1689812133000
    },
    {
        "time": 1689812137000,
        "value": 139.54,
        "_internal_originalTime": 1689812137000
    },
    {
        "time": 1689812139000,
        "value": 139.533,
        "_internal_originalTime": 1689812139000
    },
    {
        "time": 1689812142000,
        "value": 139.539,
        "_internal_originalTime": 1689812142000
    },
    {
        "time": 1689812144000,
        "value": 139.531,
        "_internal_originalTime": 1689812144000
    },
    {
        "time": 1689812146000,
        "value": 139.533,
        "_internal_originalTime": 1689812146000
    },
    {
        "time": 1689812149000,
        "value": 139.532,
        "_internal_originalTime": 1689812149000
    },
    {
        "time": 1689812151000,
        "value": 139.534,
        "_internal_originalTime": 1689812151000
    },
    {
        "time": 1689812152000,
        "value": 139.539,
        "_internal_originalTime": 1689812152000
    },
    {
        "time": 1689812156000,
        "value": 139.533,
        "_internal_originalTime": 1689812156000
    },
    {
        "time": 1689812158000,
        "value": 139.539,
        "_internal_originalTime": 1689812158000
    },
    {
        "time": 1689812161000,
        "value": 139.548,
        "_internal_originalTime": 1689812161000
    },
    {
        "time": 1689812163000,
        "value": 139.545,
        "_internal_originalTime": 1689812163000
    },
    {
        "time": 1689812166000,
        "value": 139.548,
        "_internal_originalTime": 1689812166000
    },
    {
        "time": 1689812168000,
        "value": 139.548,
        "_internal_originalTime": 1689812168000
    },
    {
        "time": 1689812169000,
        "value": 139.548,
        "_internal_originalTime": 1689812169000
    },
    {
        "time": 1689812174000,
        "value": 139.55,
        "_internal_originalTime": 1689812174000
    },
    {
        "time": 1689812175000,
        "value": 139.555,
        "_internal_originalTime": 1689812175000
    },
    {
        "time": 1689812177000,
        "value": 139.554,
        "_internal_originalTime": 1689812177000
    },
    {
        "time": 1689812179000,
        "value": 139.555,
        "_internal_originalTime": 1689812179000
    },
    {
        "time": 1689812182000,
        "value": 139.548,
        "_internal_originalTime": 1689812182000
    },
    {
        "time": 1689812185000,
        "value": 139.547,
        "_internal_originalTime": 1689812185000
    },
    {
        "time": 1689812187000,
        "value": 139.547,
        "_internal_originalTime": 1689812187000
    },
    {
        "time": 1689812189000,
        "value": 139.549,
        "_internal_originalTime": 1689812189000
    },
    {
        "time": 1689812191000,
        "value": 139.536,
        "_internal_originalTime": 1689812191000
    },
    {
        "time": 1689812194000,
        "value": 139.54,
        "_internal_originalTime": 1689812194000
    },
    {
        "time": 1689812196000,
        "value": 139.538,
        "_internal_originalTime": 1689812196000
    },
    {
        "time": 1689812199000,
        "value": 139.538,
        "_internal_originalTime": 1689812199000
    },
    {
        "time": 1689812200000,
        "value": 139.54,
        "_internal_originalTime": 1689812200000
    },
    {
        "time": 1689812203000,
        "value": 139.539,
        "_internal_originalTime": 1689812203000
    },
    {
        "time": 1689812205000,
        "value": 139.539,
        "_internal_originalTime": 1689812205000
    },
    {
        "time": 1689812208000,
        "value": 139.536,
        "_internal_originalTime": 1689812208000
    },
    {
        "time": 1689812211000,
        "value": 139.537,
        "_internal_originalTime": 1689812211000
    },
    {
        "time": 1689812214000,
        "value": 139.539,
        "_internal_originalTime": 1689812214000
    },
    {
        "time": 1689812216000,
        "value": 139.542,
        "_internal_originalTime": 1689812216000
    },
    {
        "time": 1689812219000,
        "value": 139.539,
        "_internal_originalTime": 1689812219000
    },
    {
        "time": 1689812222000,
        "value": 139.54,
        "_internal_originalTime": 1689812222000
    },
    {
        "time": 1689812224000,
        "value": 139.53,
        "_internal_originalTime": 1689812224000
    },
    {
        "time": 1689812227000,
        "value": 139.535,
        "_internal_originalTime": 1689812227000
    },
    {
        "time": 1689812229000,
        "value": 139.527,
        "_internal_originalTime": 1689812229000
    },
    {
        "time": 1689812231000,
        "value": 139.525,
        "_internal_originalTime": 1689812231000
    },
    {
        "time": 1689812233000,
        "value": 139.526,
        "_internal_originalTime": 1689812233000
    },
    {
        "time": 1689812236000,
        "value": 139.526,
        "_internal_originalTime": 1689812236000
    },
    {
        "time": 1689812239000,
        "value": 139.526,
        "_internal_originalTime": 1689812239000
    },
    {
        "time": 1689812241000,
        "value": 139.519,
        "_internal_originalTime": 1689812241000
    },
    {
        "time": 1689812244000,
        "value": 139.528,
        "_internal_originalTime": 1689812244000
    },
    {
        "time": 1689812246000,
        "value": 139.53,
        "_internal_originalTime": 1689812246000
    },
    {
        "time": 1689812249000,
        "value": 139.525,
        "_internal_originalTime": 1689812249000
    },
    {
        "time": 1689812251000,
        "value": 139.53,
        "_internal_originalTime": 1689812251000
    },
    {
        "time": 1689812253000,
        "value": 139.517,
        "_internal_originalTime": 1689812253000
    },
    {
        "time": 1689812256000,
        "value": 139.518,
        "_internal_originalTime": 1689812256000
    },
    {
        "time": 1689812259000,
        "value": 139.518,
        "_internal_originalTime": 1689812259000
    },
    {
        "time": 1689812262000,
        "value": 139.525,
        "_internal_originalTime": 1689812262000
    },
    {
        "time": 1689812264000,
        "value": 139.523,
        "_internal_originalTime": 1689812264000
    },
    {
        "time": 1689812266000,
        "value": 139.518,
        "_internal_originalTime": 1689812266000
    },
    {
        "time": 1689812268000,
        "value": 139.52,
        "_internal_originalTime": 1689812268000
    },
    {
        "time": 1689812271000,
        "value": 139.521,
        "_internal_originalTime": 1689812271000
    },
    {
        "time": 1689812274000,
        "value": 139.52,
        "_internal_originalTime": 1689812274000
    },
    {
        "time": 1689812277000,
        "value": 139.524,
        "_internal_originalTime": 1689812277000
    },
    {
        "time": 1689812278000,
        "value": 139.519,
        "_internal_originalTime": 1689812278000
    },
    {
        "time": 1689812279000,
        "value": 139.524,
        "_internal_originalTime": 1689812279000
    },
    {
        "time": 1689812285000,
        "value": 139.52,
        "_internal_originalTime": 1689812285000
    },
    {
        "time": 1689812287000,
        "value": 139.518,
        "_internal_originalTime": 1689812287000
    },
    {
        "time": 1689812290000,
        "value": 139.522,
        "_internal_originalTime": 1689812290000
    },
    {
        "time": 1689812293000,
        "value": 139.511,
        "_internal_originalTime": 1689812293000
    },
    {
        "time": 1689812295000,
        "value": 139.493,
        "_internal_originalTime": 1689812295000
    },
    {
        "time": 1689812296000,
        "value": 139.496,
        "_internal_originalTime": 1689812296000
    },
    {
        "time": 1689812299000,
        "value": 139.504,
        "_internal_originalTime": 1689812299000
    },
    {
        "time": 1689812302000,
        "value": 139.506,
        "_internal_originalTime": 1689812302000
    },
    {
        "time": 1689812305000,
        "value": 139.511,
        "_internal_originalTime": 1689812305000
    },
    {
        "time": 1689812307000,
        "value": 139.522,
        "_internal_originalTime": 1689812307000
    },
    {
        "time": 1689812309000,
        "value": 139.53,
        "_internal_originalTime": 1689812309000
    },
    {
        "time": 1689812312000,
        "value": 139.538,
        "_internal_originalTime": 1689812312000
    },
    {
        "time": 1689812314000,
        "value": 139.538,
        "_internal_originalTime": 1689812314000
    },
    {
        "time": 1689812316000,
        "value": 139.549,
        "_internal_originalTime": 1689812316000
    },
    {
        "time": 1689812319000,
        "value": 139.548,
        "_internal_originalTime": 1689812319000
    },
    {
        "time": 1689812322000,
        "value": 139.558,
        "_internal_originalTime": 1689812322000
    },
    {
        "time": 1689812324000,
        "value": 139.557,
        "_internal_originalTime": 1689812324000
    },
    {
        "time": 1689812325000,
        "value": 139.555,
        "_internal_originalTime": 1689812325000
    },
    {
        "time": 1689812329000,
        "value": 139.551,
        "_internal_originalTime": 1689812329000
    },
    {
        "time": 1689812331000,
        "value": 139.554,
        "_internal_originalTime": 1689812331000
    },
    {
        "time": 1689812333000,
        "value": 139.558,
        "_internal_originalTime": 1689812333000
    },
    {
        "time": 1689812337000,
        "value": 139.556,
        "_internal_originalTime": 1689812337000
    },
    {
        "time": 1689812338000,
        "value": 139.552,
        "_internal_originalTime": 1689812338000
    },
    {
        "time": 1689812339000,
        "value": 139.55,
        "_internal_originalTime": 1689812339000
    },
    {
        "time": 1689812340000,
        "value": 139.555,
        "_internal_originalTime": 1689812340000
    },
    {
        "time": 1689812346000,
        "value": 139.55,
        "_internal_originalTime": 1689812346000
    },
    {
        "time": 1689812349000,
        "value": 139.549,
        "_internal_originalTime": 1689812349000
    },
    {
        "time": 1689812350000,
        "value": 139.551,
        "_internal_originalTime": 1689812350000
    },
    {
        "time": 1689812354000,
        "value": 139.55,
        "_internal_originalTime": 1689812354000
    },
    {
        "time": 1689812355000,
        "value": 139.551,
        "_internal_originalTime": 1689812355000
    },
    {
        "time": 1689812359000,
        "value": 139.559,
        "_internal_originalTime": 1689812359000
    },
    {
        "time": 1689812362000,
        "value": 139.561,
        "_internal_originalTime": 1689812362000
    },
    {
        "time": 1689812364000,
        "value": 139.556,
        "_internal_originalTime": 1689812364000
    },
    {
        "time": 1689812367000,
        "value": 139.556,
        "_internal_originalTime": 1689812367000
    },
    {
        "time": 1689812368000,
        "value": 139.56,
        "_internal_originalTime": 1689812368000
    },
    {
        "time": 1689812372000,
        "value": 139.554,
        "_internal_originalTime": 1689812372000
    },
    {
        "time": 1689812373000,
        "value": 139.555,
        "_internal_originalTime": 1689812373000
    },
    {
        "time": 1689812377000,
        "value": 139.569,
        "_internal_originalTime": 1689812377000
    },
    {
        "time": 1689812378000,
        "value": 139.566,
        "_internal_originalTime": 1689812378000
    },
    {
        "time": 1689812382000,
        "value": 139.577,
        "_internal_originalTime": 1689812382000
    },
    {
        "time": 1689812383000,
        "value": 139.579,
        "_internal_originalTime": 1689812383000
    },
    {
        "time": 1689812386000,
        "value": 139.579,
        "_internal_originalTime": 1689812386000
    },
    {
        "time": 1689812389000,
        "value": 139.576,
        "_internal_originalTime": 1689812389000
    },
    {
        "time": 1689812391000,
        "value": 139.578,
        "_internal_originalTime": 1689812391000
    },
    {
        "time": 1689812394000,
        "value": 139.58,
        "_internal_originalTime": 1689812394000
    },
    {
        "time": 1689812397000,
        "value": 139.591,
        "_internal_originalTime": 1689812397000
    },
    {
        "time": 1689812399000,
        "value": 139.588,
        "_internal_originalTime": 1689812399000
    },
    {
        "time": 1689812400000,
        "value": 139.591,
        "_internal_originalTime": 1689812400000
    },
    {
        "time": 1689812401000,
        "value": 139.589,
        "_internal_originalTime": 1689812401000
    },
    {
        "time": 1689812404000,
        "value": 139.596,
        "_internal_originalTime": 1689812404000
    },
    {
        "time": 1689812406000,
        "value": 139.606,
        "_internal_originalTime": 1689812406000
    },
    {
        "time": 1689812409000,
        "value": 139.618,
        "_internal_originalTime": 1689812409000
    },
    {
        "time": 1689812411000,
        "value": 139.616,
        "_internal_originalTime": 1689812411000
    },
    {
        "time": 1689812414000,
        "value": 139.606,
        "_internal_originalTime": 1689812414000
    },
    {
        "time": 1689812416000,
        "value": 139.602,
        "_internal_originalTime": 1689812416000
    },
    {
        "time": 1689812419000,
        "value": 139.602,
        "_internal_originalTime": 1689812419000
    },
    {
        "time": 1689812420000,
        "value": 139.607,
        "_internal_originalTime": 1689812420000
    },
    {
        "time": 1689812423000,
        "value": 139.605,
        "_internal_originalTime": 1689812423000
    },
    {
        "time": 1689812425000,
        "value": 139.608,
        "_internal_originalTime": 1689812425000
    },
    {
        "time": 1689812426000,
        "value": 139.61,
        "_internal_originalTime": 1689812426000
    },
    {
        "time": 1689812428000,
        "value": 139.607,
        "_internal_originalTime": 1689812428000
    },
    {
        "time": 1689812430000,
        "value": 139.611,
        "_internal_originalTime": 1689812430000
    },
    {
        "time": 1689812431000,
        "value": 139.607,
        "_internal_originalTime": 1689812431000
    },
    {
        "time": 1689812432000,
        "value": 139.61,
        "_internal_originalTime": 1689812432000
    },
    {
        "time": 1689812434000,
        "value": 139.615,
        "_internal_originalTime": 1689812434000
    },
    {
        "time": 1689812435000,
        "value": 139.618,
        "_internal_originalTime": 1689812435000
    },
    {
        "time": 1689812436000,
        "value": 139.614,
        "_internal_originalTime": 1689812436000
    },
    {
        "time": 1689812438000,
        "value": 139.614,
        "_internal_originalTime": 1689812438000
    },
    {
        "time": 1689812439000,
        "value": 139.61,
        "_internal_originalTime": 1689812439000
    },
    {
        "time": 1689812441000,
        "value": 139.617,
        "_internal_originalTime": 1689812441000
    },
    {
        "time": 1689812443000,
        "value": 139.63,
        "_internal_originalTime": 1689812443000
    },
    {
        "time": 1689812445000,
        "value": 139.624,
        "_internal_originalTime": 1689812445000
    },
    {
        "time": 1689812448000,
        "value": 139.627,
        "_internal_originalTime": 1689812448000
    },
    {
        "time": 1689812450000,
        "value": 139.622,
        "_internal_originalTime": 1689812450000
    },
    {
        "time": 1689812453000,
        "value": 139.619,
        "_internal_originalTime": 1689812453000
    },
    {
        "time": 1689812455000,
        "value": 139.618,
        "_internal_originalTime": 1689812455000
    },
    {
        "time": 1689812458000,
        "value": 139.622,
        "_internal_originalTime": 1689812458000
    },
    {
        "time": 1689812459000,
        "value": 139.624,
        "_internal_originalTime": 1689812459000
    },
    {
        "time": 1689812460000,
        "value": 139.625,
        "_internal_originalTime": 1689812460000
    },
    {
        "time": 1689812462000,
        "value": 139.625,
        "_internal_originalTime": 1689812462000
    },
    {
        "time": 1689812463000,
        "value": 139.631,
        "_internal_originalTime": 1689812463000
    },
    {
        "time": 1689812464000,
        "value": 139.627,
        "_internal_originalTime": 1689812464000
    },
    {
        "time": 1689812465000,
        "value": 139.632,
        "_internal_originalTime": 1689812465000
    },
    {
        "time": 1689812467000,
        "value": 139.631,
        "_internal_originalTime": 1689812467000
    },
    {
        "time": 1689812469000,
        "value": 139.63,
        "_internal_originalTime": 1689812469000
    },
    {
        "time": 1689812472000,
        "value": 139.628,
        "_internal_originalTime": 1689812472000
    },
    {
        "time": 1689812473000,
        "value": 139.628,
        "_internal_originalTime": 1689812473000
    },
    {
        "time": 1689812475000,
        "value": 139.631,
        "_internal_originalTime": 1689812475000
    },
    {
        "time": 1689812478000,
        "value": 139.631,
        "_internal_originalTime": 1689812478000
    },
    {
        "time": 1689812480000,
        "value": 139.622,
        "_internal_originalTime": 1689812480000
    },
    {
        "time": 1689812482000,
        "value": 139.624,
        "_internal_originalTime": 1689812482000
    },
    {
        "time": 1689812484000,
        "value": 139.623,
        "_internal_originalTime": 1689812484000
    },
    {
        "time": 1689812488000,
        "value": 139.616,
        "_internal_originalTime": 1689812488000
    },
    {
        "time": 1689812490000,
        "value": 139.617,
        "_internal_originalTime": 1689812490000
    },
    {
        "time": 1689812491000,
        "value": 139.614,
        "_internal_originalTime": 1689812491000
    },
    {
        "time": 1689812494000,
        "value": 139.617,
        "_internal_originalTime": 1689812494000
    },
    {
        "time": 1689812496000,
        "value": 139.62,
        "_internal_originalTime": 1689812496000
    },
    {
        "time": 1689812498000,
        "value": 139.626,
        "_internal_originalTime": 1689812498000
    },
    {
        "time": 1689812501000,
        "value": 139.622,
        "_internal_originalTime": 1689812501000
    },
    {
        "time": 1689812504000,
        "value": 139.62,
        "_internal_originalTime": 1689812504000
    },
    {
        "time": 1689812506000,
        "value": 139.637,
        "_internal_originalTime": 1689812506000
    },
    {
        "time": 1689812508000,
        "value": 139.636,
        "_internal_originalTime": 1689812508000
    },
    {
        "time": 1689812510000,
        "value": 139.636,
        "_internal_originalTime": 1689812510000
    },
    {
        "time": 1689812512000,
        "value": 139.636,
        "_internal_originalTime": 1689812512000
    },
    {
        "time": 1689812515000,
        "value": 139.639,
        "_internal_originalTime": 1689812515000
    },
    {
        "time": 1689812516000,
        "value": 139.636,
        "_internal_originalTime": 1689812516000
    },
    {
        "time": 1689812517000,
        "value": 139.636,
        "_internal_originalTime": 1689812517000
    },
    {
        "time": 1689812520000,
        "value": 139.634,
        "_internal_originalTime": 1689812520000
    },
    {
        "time": 1689812523000,
        "value": 139.642,
        "_internal_originalTime": 1689812523000
    },
    {
        "time": 1689812525000,
        "value": 139.634,
        "_internal_originalTime": 1689812525000
    },
    {
        "time": 1689812527000,
        "value": 139.632,
        "_internal_originalTime": 1689812527000
    },
    {
        "time": 1689812529000,
        "value": 139.633,
        "_internal_originalTime": 1689812529000
    },
    {
        "time": 1689812531000,
        "value": 139.634,
        "_internal_originalTime": 1689812531000
    },
    {
        "time": 1689812533000,
        "value": 139.628,
        "_internal_originalTime": 1689812533000
    },
    {
        "time": 1689812534000,
        "value": 139.633,
        "_internal_originalTime": 1689812534000
    },
    {
        "time": 1689812537000,
        "value": 139.632,
        "_internal_originalTime": 1689812537000
    },
    {
        "time": 1689812540000,
        "value": 139.633,
        "_internal_originalTime": 1689812540000
    },
    {
        "time": 1689812543000,
        "value": 139.621,
        "_internal_originalTime": 1689812543000
    },
    {
        "time": 1689812546000,
        "value": 139.62,
        "_internal_originalTime": 1689812546000
    },
    {
        "time": 1689812548000,
        "value": 139.626,
        "_internal_originalTime": 1689812548000
    },
    {
        "time": 1689812550000,
        "value": 139.624,
        "_internal_originalTime": 1689812550000
    },
    {
        "time": 1689812552000,
        "value": 139.63,
        "_internal_originalTime": 1689812552000
    },
    {
        "time": 1689812555000,
        "value": 139.628,
        "_internal_originalTime": 1689812555000
    },
    {
        "time": 1689812557000,
        "value": 139.632,
        "_internal_originalTime": 1689812557000
    },
    {
        "time": 1689812560000,
        "value": 139.635,
        "_internal_originalTime": 1689812560000
    },
    {
        "time": 1689812563000,
        "value": 139.634,
        "_internal_originalTime": 1689812563000
    },
    {
        "time": 1689812564000,
        "value": 139.635,
        "_internal_originalTime": 1689812564000
    },
    {
        "time": 1689812567000,
        "value": 139.64,
        "_internal_originalTime": 1689812567000
    },
    {
        "time": 1689812570000,
        "value": 139.637,
        "_internal_originalTime": 1689812570000
    },
    {
        "time": 1689812573000,
        "value": 139.626,
        "_internal_originalTime": 1689812573000
    },
    {
        "time": 1689812576000,
        "value": 139.62,
        "_internal_originalTime": 1689812576000
    },
    {
        "time": 1689812579000,
        "value": 139.623,
        "_internal_originalTime": 1689812579000
    },
    {
        "time": 1689812581000,
        "value": 139.621,
        "_internal_originalTime": 1689812581000
    },
    {
        "time": 1689812584000,
        "value": 139.612,
        "_internal_originalTime": 1689812584000
    },
    {
        "time": 1689812585000,
        "value": 139.618,
        "_internal_originalTime": 1689812585000
    },
    {
        "time": 1689812589000,
        "value": 139.622,
        "_internal_originalTime": 1689812589000
    },
    {
        "time": 1689812591000,
        "value": 139.626,
        "_internal_originalTime": 1689812591000
    },
    {
        "time": 1689812594000,
        "value": 139.62,
        "_internal_originalTime": 1689812594000
    },
    {
        "time": 1689812596000,
        "value": 139.61,
        "_internal_originalTime": 1689812596000
    },
    {
        "time": 1689812598000,
        "value": 139.608,
        "_internal_originalTime": 1689812598000
    },
    {
        "time": 1689812601000,
        "value": 139.607,
        "_internal_originalTime": 1689812601000
    },
    {
        "time": 1689812603000,
        "value": 139.607,
        "_internal_originalTime": 1689812603000
    },
    {
        "time": 1689812607000,
        "value": 139.594,
        "_internal_originalTime": 1689812607000
    },
    {
        "time": 1689812609000,
        "value": 139.595,
        "_internal_originalTime": 1689812609000
    },
    {
        "time": 1689812611000,
        "value": 139.598,
        "_internal_originalTime": 1689812611000
    },
    {
        "time": 1689812613000,
        "value": 139.598,
        "_internal_originalTime": 1689812613000
    },
    {
        "time": 1689812615000,
        "value": 139.597,
        "_internal_originalTime": 1689812615000
    },
    {
        "time": 1689812619000,
        "value": 139.599,
        "_internal_originalTime": 1689812619000
    },
    {
        "time": 1689812622000,
        "value": 139.593,
        "_internal_originalTime": 1689812622000
    },
    {
        "time": 1689812624000,
        "value": 139.598,
        "_internal_originalTime": 1689812624000
    },
    {
        "time": 1689812626000,
        "value": 139.597,
        "_internal_originalTime": 1689812626000
    },
    {
        "time": 1689812628000,
        "value": 139.598,
        "_internal_originalTime": 1689812628000
    },
    {
        "time": 1689812632000,
        "value": 139.591,
        "_internal_originalTime": 1689812632000
    },
    {
        "time": 1689812635000,
        "value": 139.598,
        "_internal_originalTime": 1689812635000
    },
    {
        "time": 1689812636000,
        "value": 139.598,
        "_internal_originalTime": 1689812636000
    },
    {
        "time": 1689812639000,
        "value": 139.598,
        "_internal_originalTime": 1689812639000
    },
    {
        "time": 1689812640000,
        "value": 139.597,
        "_internal_originalTime": 1689812640000
    },
    {
        "time": 1689812641000,
        "value": 139.59,
        "_internal_originalTime": 1689812641000
    },
    {
        "time": 1689812642000,
        "value": 139.592,
        "_internal_originalTime": 1689812642000
    },
    {
        "time": 1689812645000,
        "value": 139.592,
        "_internal_originalTime": 1689812645000
    },
    {
        "time": 1689812647000,
        "value": 139.597,
        "_internal_originalTime": 1689812647000
    },
    {
        "time": 1689812651000,
        "value": 139.59,
        "_internal_originalTime": 1689812651000
    },
    {
        "time": 1689812654000,
        "value": 139.591,
        "_internal_originalTime": 1689812654000
    },
    {
        "time": 1689812656000,
        "value": 139.596,
        "_internal_originalTime": 1689812656000
    },
    {
        "time": 1689812658000,
        "value": 139.59,
        "_internal_originalTime": 1689812658000
    },
    {
        "time": 1689812660000,
        "value": 139.588,
        "_internal_originalTime": 1689812660000
    },
    {
        "time": 1689812663000,
        "value": 139.597,
        "_internal_originalTime": 1689812663000
    },
    {
        "time": 1689812665000,
        "value": 139.597,
        "_internal_originalTime": 1689812665000
    },
    {
        "time": 1689812668000,
        "value": 139.59,
        "_internal_originalTime": 1689812668000
    },
    {
        "time": 1689812670000,
        "value": 139.596,
        "_internal_originalTime": 1689812670000
    },
    {
        "time": 1689812672000,
        "value": 139.59,
        "_internal_originalTime": 1689812672000
    },
    {
        "time": 1689812673000,
        "value": 139.588,
        "_internal_originalTime": 1689812673000
    },
    {
        "time": 1689812680000,
        "value": 139.582,
        "_internal_originalTime": 1689812680000
    },
    {
        "time": 1689812682000,
        "value": 139.582,
        "_internal_originalTime": 1689812682000
    },
    {
        "time": 1689812684000,
        "value": 139.582,
        "_internal_originalTime": 1689812684000
    },
    {
        "time": 1689812689000,
        "value": 139.582,
        "_internal_originalTime": 1689812689000
    },
    {
        "time": 1689812692000,
        "value": 139.588,
        "_internal_originalTime": 1689812692000
    },
    {
        "time": 1689812695000,
        "value": 139.588,
        "_internal_originalTime": 1689812695000
    },
    {
        "time": 1689812698000,
        "value": 139.588,
        "_internal_originalTime": 1689812698000
    },
    {
        "time": 1689812701000,
        "value": 139.586,
        "_internal_originalTime": 1689812701000
    },
    {
        "time": 1689812702000,
        "value": 139.598,
        "_internal_originalTime": 1689812702000
    },
    {
        "time": 1689812704000,
        "value": 139.588,
        "_internal_originalTime": 1689812704000
    },
    {
        "time": 1689812705000,
        "value": 139.588,
        "_internal_originalTime": 1689812705000
    },
    {
        "time": 1689812709000,
        "value": 139.586,
        "_internal_originalTime": 1689812709000
    },
    {
        "time": 1689812712000,
        "value": 139.582,
        "_internal_originalTime": 1689812712000
    },
    {
        "time": 1689812713000,
        "value": 139.589,
        "_internal_originalTime": 1689812713000
    },
    {
        "time": 1689812716000,
        "value": 139.592,
        "_internal_originalTime": 1689812716000
    },
    {
        "time": 1689812719000,
        "value": 139.597,
        "_internal_originalTime": 1689812719000
    },
    {
        "time": 1689812722000,
        "value": 139.592,
        "_internal_originalTime": 1689812722000
    },
    {
        "time": 1689812726000,
        "value": 139.592,
        "_internal_originalTime": 1689812726000
    },
    {
        "time": 1689812729000,
        "value": 139.592,
        "_internal_originalTime": 1689812729000
    },
    {
        "time": 1689812732000,
        "value": 139.594,
        "_internal_originalTime": 1689812732000
    },
    {
        "time": 1689812733000,
        "value": 139.598,
        "_internal_originalTime": 1689812733000
    },
    {
        "time": 1689812737000,
        "value": 139.594,
        "_internal_originalTime": 1689812737000
    },
    {
        "time": 1689812740000,
        "value": 139.588,
        "_internal_originalTime": 1689812740000
    },
    {
        "time": 1689812743000,
        "value": 139.589,
        "_internal_originalTime": 1689812743000
    },
    {
        "time": 1689812747000,
        "value": 139.569,
        "_internal_originalTime": 1689812747000
    },
    {
        "time": 1689812750000,
        "value": 139.569,
        "_internal_originalTime": 1689812750000
    },
    {
        "time": 1689812757000,
        "value": 139.578,
        "_internal_originalTime": 1689812757000
    },
    {
        "time": 1689812759000,
        "value": 139.573,
        "_internal_originalTime": 1689812759000
    },
    {
        "time": 1689812761000,
        "value": 139.573,
        "_internal_originalTime": 1689812761000
    },
    {
        "time": 1689812763000,
        "value": 139.575,
        "_internal_originalTime": 1689812763000
    },
    {
        "time": 1689812764000,
        "value": 139.575,
        "_internal_originalTime": 1689812764000
    },
    {
        "time": 1689812767000,
        "value": 139.578,
        "_internal_originalTime": 1689812767000
    },
    {
        "time": 1689812769000,
        "value": 139.578,
        "_internal_originalTime": 1689812769000
    },
    {
        "time": 1689812773000,
        "value": 139.578,
        "_internal_originalTime": 1689812773000
    },
    {
        "time": 1689812775000,
        "value": 139.572,
        "_internal_originalTime": 1689812775000
    },
    {
        "time": 1689812778000,
        "value": 139.572,
        "_internal_originalTime": 1689812778000
    },
    {
        "time": 1689812780000,
        "value": 139.578,
        "_internal_originalTime": 1689812780000
    },
    {
        "time": 1689812784000,
        "value": 139.57,
        "_internal_originalTime": 1689812784000
    },
    {
        "time": 1689812787000,
        "value": 139.572,
        "_internal_originalTime": 1689812787000
    },
    {
        "time": 1689812789000,
        "value": 139.574,
        "_internal_originalTime": 1689812789000
    },
    {
        "time": 1689812791000,
        "value": 139.578,
        "_internal_originalTime": 1689812791000
    },
    {
        "time": 1689812794000,
        "value": 139.578,
        "_internal_originalTime": 1689812794000
    },
    {
        "time": 1689812797000,
        "value": 139.578,
        "_internal_originalTime": 1689812797000
    },
    {
        "time": 1689812799000,
        "value": 139.574,
        "_internal_originalTime": 1689812799000
    },
    {
        "time": 1689812801000,
        "value": 139.575,
        "_internal_originalTime": 1689812801000
    },
    {
        "time": 1689812808000,
        "value": 139.57,
        "_internal_originalTime": 1689812808000
    },
    {
        "time": 1689812809000,
        "value": 139.566,
        "_internal_originalTime": 1689812809000
    },
    {
        "time": 1689812813000,
        "value": 139.57,
        "_internal_originalTime": 1689812813000
    },
    {
        "time": 1689812816000,
        "value": 139.573,
        "_internal_originalTime": 1689812816000
    },
    {
        "time": 1689812818000,
        "value": 139.572,
        "_internal_originalTime": 1689812818000
    },
    {
        "time": 1689812819000,
        "value": 139.573,
        "_internal_originalTime": 1689812819000
    },
    {
        "time": 1689812821000,
        "value": 139.572,
        "_internal_originalTime": 1689812821000
    },
    {
        "time": 1689812822000,
        "value": 139.572,
        "_internal_originalTime": 1689812822000
    },
    {
        "time": 1689812824000,
        "value": 139.572,
        "_internal_originalTime": 1689812824000
    },
    {
        "time": 1689812825000,
        "value": 139.571,
        "_internal_originalTime": 1689812825000
    },
    {
        "time": 1689812827000,
        "value": 139.578,
        "_internal_originalTime": 1689812827000
    },
    {
        "time": 1689812828000,
        "value": 139.572,
        "_internal_originalTime": 1689812828000
    },
    {
        "time": 1689812829000,
        "value": 139.572,
        "_internal_originalTime": 1689812829000
    },
    {
        "time": 1689812831000,
        "value": 139.578,
        "_internal_originalTime": 1689812831000
    },
    {
        "time": 1689812832000,
        "value": 139.572,
        "_internal_originalTime": 1689812832000
    },
    {
        "time": 1689812834000,
        "value": 139.578,
        "_internal_originalTime": 1689812834000
    },
    {
        "time": 1689812835000,
        "value": 139.578,
        "_internal_originalTime": 1689812835000
    },
    {
        "time": 1689812836000,
        "value": 139.572,
        "_internal_originalTime": 1689812836000
    },
    {
        "time": 1689812837000,
        "value": 139.573,
        "_internal_originalTime": 1689812837000
    },
    {
        "time": 1689812838000,
        "value": 139.578,
        "_internal_originalTime": 1689812838000
    },
    {
        "time": 1689812840000,
        "value": 139.578,
        "_internal_originalTime": 1689812840000
    },
    {
        "time": 1689812841000,
        "value": 139.578,
        "_internal_originalTime": 1689812841000
    },
    {
        "time": 1689812845000,
        "value": 139.573,
        "_internal_originalTime": 1689812845000
    },
    {
        "time": 1689812848000,
        "value": 139.578,
        "_internal_originalTime": 1689812848000
    },
    {
        "time": 1689812849000,
        "value": 139.578,
        "_internal_originalTime": 1689812849000
    },
    {
        "time": 1689812852000,
        "value": 139.578,
        "_internal_originalTime": 1689812852000
    },
    {
        "time": 1689812854000,
        "value": 139.572,
        "_internal_originalTime": 1689812854000
    },
    {
        "time": 1689812856000,
        "value": 139.573,
        "_internal_originalTime": 1689812856000
    },
    {
        "time": 1689812859000,
        "value": 139.573,
        "_internal_originalTime": 1689812859000
    },
    {
        "time": 1689812863000,
        "value": 139.574,
        "_internal_originalTime": 1689812863000
    },
    {
        "time": 1689812867000,
        "value": 139.578,
        "_internal_originalTime": 1689812867000
    },
    {
        "time": 1689812872000,
        "value": 139.573,
        "_internal_originalTime": 1689812872000
    },
    {
        "time": 1689812876000,
        "value": 139.571,
        "_internal_originalTime": 1689812876000
    },
    {
        "time": 1689812879000,
        "value": 139.569,
        "_internal_originalTime": 1689812879000
    },
    {
        "time": 1689812880000,
        "value": 139.569,
        "_internal_originalTime": 1689812880000
    },
    {
        "time": 1689812885000,
        "value": 139.572,
        "_internal_originalTime": 1689812885000
    },
    {
        "time": 1689812888000,
        "value": 139.566,
        "_internal_originalTime": 1689812888000
    },
    {
        "time": 1689812889000,
        "value": 139.568,
        "_internal_originalTime": 1689812889000
    },
    {
        "time": 1689812892000,
        "value": 139.567,
        "_internal_originalTime": 1689812892000
    },
    {
        "time": 1689812895000,
        "value": 139.572,
        "_internal_originalTime": 1689812895000
    },
    {
        "time": 1689812896000,
        "value": 139.572,
        "_internal_originalTime": 1689812896000
    },
    {
        "time": 1689812898000,
        "value": 139.567,
        "_internal_originalTime": 1689812898000
    },
    {
        "time": 1689812902000,
        "value": 139.564,
        "_internal_originalTime": 1689812902000
    },
    {
        "time": 1689812908000,
        "value": 139.565,
        "_internal_originalTime": 1689812908000
    },
    {
        "time": 1689812910000,
        "value": 139.573,
        "_internal_originalTime": 1689812910000
    },
    {
        "time": 1689812914000,
        "value": 139.572,
        "_internal_originalTime": 1689812914000
    },
    {
        "time": 1689812916000,
        "value": 139.572,
        "_internal_originalTime": 1689812916000
    },
    {
        "time": 1689812919000,
        "value": 139.576,
        "_internal_originalTime": 1689812919000
    },
    {
        "time": 1689812922000,
        "value": 139.584,
        "_internal_originalTime": 1689812922000
    },
    {
        "time": 1689812924000,
        "value": 139.587,
        "_internal_originalTime": 1689812924000
    },
    {
        "time": 1689812926000,
        "value": 139.595,
        "_internal_originalTime": 1689812926000
    },
    {
        "time": 1689812928000,
        "value": 139.609,
        "_internal_originalTime": 1689812928000
    },
    {
        "time": 1689812930000,
        "value": 139.608,
        "_internal_originalTime": 1689812930000
    },
    {
        "time": 1689812933000,
        "value": 139.615,
        "_internal_originalTime": 1689812933000
    },
    {
        "time": 1689812935000,
        "value": 139.614,
        "_internal_originalTime": 1689812935000
    },
    {
        "time": 1689812937000,
        "value": 139.604,
        "_internal_originalTime": 1689812937000
    },
    {
        "time": 1689812939000,
        "value": 139.605,
        "_internal_originalTime": 1689812939000
    },
    {
        "time": 1689812940000,
        "value": 139.604,
        "_internal_originalTime": 1689812940000
    },
    {
        "time": 1689812941000,
        "value": 139.604,
        "_internal_originalTime": 1689812941000
    },
    {
        "time": 1689812943000,
        "value": 139.597,
        "_internal_originalTime": 1689812943000
    },
    {
        "time": 1689812944000,
        "value": 139.59,
        "_internal_originalTime": 1689812944000
    },
    {
        "time": 1689812946000,
        "value": 139.588,
        "_internal_originalTime": 1689812946000
    },
    {
        "time": 1689812950000,
        "value": 139.587,
        "_internal_originalTime": 1689812950000
    },
    {
        "time": 1689812953000,
        "value": 139.588,
        "_internal_originalTime": 1689812953000
    },
    {
        "time": 1689812955000,
        "value": 139.587,
        "_internal_originalTime": 1689812955000
    },
    {
        "time": 1689812958000,
        "value": 139.59,
        "_internal_originalTime": 1689812958000
    },
    {
        "time": 1689812960000,
        "value": 139.588,
        "_internal_originalTime": 1689812960000
    },
    {
        "time": 1689812963000,
        "value": 139.589,
        "_internal_originalTime": 1689812963000
    },
    {
        "time": 1689812969000,
        "value": 139.588,
        "_internal_originalTime": 1689812969000
    },
    {
        "time": 1689812971000,
        "value": 139.586,
        "_internal_originalTime": 1689812971000
    },
    {
        "time": 1689812973000,
        "value": 139.591,
        "_internal_originalTime": 1689812973000
    },
    {
        "time": 1689812975000,
        "value": 139.607,
        "_internal_originalTime": 1689812975000
    },
    {
        "time": 1689812977000,
        "value": 139.608,
        "_internal_originalTime": 1689812977000
    },
    {
        "time": 1689812980000,
        "value": 139.603,
        "_internal_originalTime": 1689812980000
    },
    {
        "time": 1689812983000,
        "value": 139.612,
        "_internal_originalTime": 1689812983000
    },
    {
        "time": 1689812985000,
        "value": 139.609,
        "_internal_originalTime": 1689812985000
    },
    {
        "time": 1689812987000,
        "value": 139.608,
        "_internal_originalTime": 1689812987000
    },
    {
        "time": 1689812990000,
        "value": 139.609,
        "_internal_originalTime": 1689812990000
    },
    {
        "time": 1689812993000,
        "value": 139.609,
        "_internal_originalTime": 1689812993000
    },
    {
        "time": 1689812995000,
        "value": 139.6,
        "_internal_originalTime": 1689812995000
    },
    {
        "time": 1689812997000,
        "value": 139.603,
        "_internal_originalTime": 1689812997000
    },
    {
        "time": 1689813000000,
        "value": 139.595,
        "_internal_originalTime": 1689813000000
    },
    {
        "time": 1689813001000,
        "value": 139.595,
        "_internal_originalTime": 1689813001000
    },
    {
        "time": 1689813003000,
        "value": 139.597,
        "_internal_originalTime": 1689813003000
    },
    {
        "time": 1689813004000,
        "value": 139.601,
        "_internal_originalTime": 1689813004000
    },
    {
        "time": 1689813005000,
        "value": 139.598,
        "_internal_originalTime": 1689813005000
    },
    {
        "time": 1689813008000,
        "value": 139.595,
        "_internal_originalTime": 1689813008000
    },
    {
        "time": 1689813009000,
        "value": 139.592,
        "_internal_originalTime": 1689813009000
    },
    {
        "time": 1689813011000,
        "value": 139.594,
        "_internal_originalTime": 1689813011000
    },
    {
        "time": 1689813014000,
        "value": 139.597,
        "_internal_originalTime": 1689813014000
    },
    {
        "time": 1689813016000,
        "value": 139.596,
        "_internal_originalTime": 1689813016000
    },
    {
        "time": 1689813018000,
        "value": 139.591,
        "_internal_originalTime": 1689813018000
    },
    {
        "time": 1689813020000,
        "value": 139.59,
        "_internal_originalTime": 1689813020000
    },
    {
        "time": 1689813021000,
        "value": 139.589,
        "_internal_originalTime": 1689813021000
    },
    {
        "time": 1689813023000,
        "value": 139.588,
        "_internal_originalTime": 1689813023000
    },
    {
        "time": 1689813025000,
        "value": 139.594,
        "_internal_originalTime": 1689813025000
    },
    {
        "time": 1689813026000,
        "value": 139.594,
        "_internal_originalTime": 1689813026000
    },
    {
        "time": 1689813029000,
        "value": 139.598,
        "_internal_originalTime": 1689813029000
    },
    {
        "time": 1689813030000,
        "value": 139.584,
        "_internal_originalTime": 1689813030000
    },
    {
        "time": 1689813031000,
        "value": 139.582,
        "_internal_originalTime": 1689813031000
    },
    {
        "time": 1689813034000,
        "value": 139.583,
        "_internal_originalTime": 1689813034000
    },
    {
        "time": 1689813037000,
        "value": 139.584,
        "_internal_originalTime": 1689813037000
    },
    {
        "time": 1689813039000,
        "value": 139.544,
        "_internal_originalTime": 1689813039000
    },
    {
        "time": 1689813041000,
        "value": 139.553,
        "_internal_originalTime": 1689813041000
    },
    {
        "time": 1689813042000,
        "value": 139.556,
        "_internal_originalTime": 1689813042000
    },
    {
        "time": 1689813044000,
        "value": 139.556,
        "_internal_originalTime": 1689813044000
    },
    {
        "time": 1689813045000,
        "value": 139.553,
        "_internal_originalTime": 1689813045000
    },
    {
        "time": 1689813047000,
        "value": 139.553,
        "_internal_originalTime": 1689813047000
    },
    {
        "time": 1689813049000,
        "value": 139.552,
        "_internal_originalTime": 1689813049000
    },
    {
        "time": 1689813051000,
        "value": 139.556,
        "_internal_originalTime": 1689813051000
    },
    {
        "time": 1689813053000,
        "value": 139.554,
        "_internal_originalTime": 1689813053000
    },
    {
        "time": 1689813056000,
        "value": 139.568,
        "_internal_originalTime": 1689813056000
    },
    {
        "time": 1689813058000,
        "value": 139.564,
        "_internal_originalTime": 1689813058000
    },
    {
        "time": 1689813061000,
        "value": 139.568,
        "_internal_originalTime": 1689813061000
    },
    {
        "time": 1689813062000,
        "value": 139.55,
        "_internal_originalTime": 1689813062000
    },
    {
        "time": 1689813063000,
        "value": 139.558,
        "_internal_originalTime": 1689813063000
    },
    {
        "time": 1689813069000,
        "value": 139.558,
        "_internal_originalTime": 1689813069000
    },
    {
        "time": 1689813071000,
        "value": 139.557,
        "_internal_originalTime": 1689813071000
    },
    {
        "time": 1689813073000,
        "value": 139.558,
        "_internal_originalTime": 1689813073000
    },
    {
        "time": 1689813074000,
        "value": 139.553,
        "_internal_originalTime": 1689813074000
    },
    {
        "time": 1689813079000,
        "value": 139.539,
        "_internal_originalTime": 1689813079000
    },
    {
        "time": 1689813081000,
        "value": 139.538,
        "_internal_originalTime": 1689813081000
    },
    {
        "time": 1689813082000,
        "value": 139.532,
        "_internal_originalTime": 1689813082000
    },
    {
        "time": 1689813084000,
        "value": 139.539,
        "_internal_originalTime": 1689813084000
    },
    {
        "time": 1689813086000,
        "value": 139.535,
        "_internal_originalTime": 1689813086000
    },
    {
        "time": 1689813087000,
        "value": 139.536,
        "_internal_originalTime": 1689813087000
    },
    {
        "time": 1689813089000,
        "value": 139.536,
        "_internal_originalTime": 1689813089000
    },
    {
        "time": 1689813091000,
        "value": 139.54,
        "_internal_originalTime": 1689813091000
    },
    {
        "time": 1689813094000,
        "value": 139.539,
        "_internal_originalTime": 1689813094000
    },
    {
        "time": 1689813095000,
        "value": 139.538,
        "_internal_originalTime": 1689813095000
    },
    {
        "time": 1689813097000,
        "value": 139.539,
        "_internal_originalTime": 1689813097000
    },
    {
        "time": 1689813099000,
        "value": 139.541,
        "_internal_originalTime": 1689813099000
    },
    {
        "time": 1689813102000,
        "value": 139.543,
        "_internal_originalTime": 1689813102000
    },
    {
        "time": 1689813104000,
        "value": 139.538,
        "_internal_originalTime": 1689813104000
    },
    {
        "time": 1689813105000,
        "value": 139.539,
        "_internal_originalTime": 1689813105000
    },
    {
        "time": 1689813107000,
        "value": 139.539,
        "_internal_originalTime": 1689813107000
    },
    {
        "time": 1689813110000,
        "value": 139.543,
        "_internal_originalTime": 1689813110000
    },
    {
        "time": 1689813112000,
        "value": 139.539,
        "_internal_originalTime": 1689813112000
    },
    {
        "time": 1689813115000,
        "value": 139.541,
        "_internal_originalTime": 1689813115000
    },
    {
        "time": 1689813117000,
        "value": 139.543,
        "_internal_originalTime": 1689813117000
    },
    {
        "time": 1689813119000,
        "value": 139.536,
        "_internal_originalTime": 1689813119000
    },
    {
        "time": 1689813121000,
        "value": 139.538,
        "_internal_originalTime": 1689813121000
    },
    {
        "time": 1689813123000,
        "value": 139.537,
        "_internal_originalTime": 1689813123000
    },
    {
        "time": 1689813125000,
        "value": 139.534,
        "_internal_originalTime": 1689813125000
    },
    {
        "time": 1689813126000,
        "value": 139.534,
        "_internal_originalTime": 1689813126000
    },
    {
        "time": 1689813130000,
        "value": 139.506,
        "_internal_originalTime": 1689813130000
    },
    {
        "time": 1689813132000,
        "value": 139.513,
        "_internal_originalTime": 1689813132000
    },
    {
        "time": 1689813134000,
        "value": 139.513,
        "_internal_originalTime": 1689813134000
    },
    {
        "time": 1689813136000,
        "value": 139.509,
        "_internal_originalTime": 1689813136000
    },
    {
        "time": 1689813138000,
        "value": 139.513,
        "_internal_originalTime": 1689813138000
    },
    {
        "time": 1689813140000,
        "value": 139.508,
        "_internal_originalTime": 1689813140000
    },
    {
        "time": 1689813142000,
        "value": 139.516,
        "_internal_originalTime": 1689813142000
    },
    {
        "time": 1689813143000,
        "value": 139.515,
        "_internal_originalTime": 1689813143000
    },
    {
        "time": 1689813145000,
        "value": 139.511,
        "_internal_originalTime": 1689813145000
    },
    {
        "time": 1689813147000,
        "value": 139.513,
        "_internal_originalTime": 1689813147000
    },
    {
        "time": 1689813149000,
        "value": 139.512,
        "_internal_originalTime": 1689813149000
    },
    {
        "time": 1689813150000,
        "value": 139.503,
        "_internal_originalTime": 1689813150000
    },
    {
        "time": 1689813152000,
        "value": 139.498,
        "_internal_originalTime": 1689813152000
    },
    {
        "time": 1689813154000,
        "value": 139.49,
        "_internal_originalTime": 1689813154000
    },
    {
        "time": 1689813156000,
        "value": 139.488,
        "_internal_originalTime": 1689813156000
    },
    {
        "time": 1689813158000,
        "value": 139.486,
        "_internal_originalTime": 1689813158000
    },
    {
        "time": 1689813160000,
        "value": 139.484,
        "_internal_originalTime": 1689813160000
    },
    {
        "time": 1689813161000,
        "value": 139.49,
        "_internal_originalTime": 1689813161000
    },
    {
        "time": 1689813164000,
        "value": 139.486,
        "_internal_originalTime": 1689813164000
    },
    {
        "time": 1689813165000,
        "value": 139.492,
        "_internal_originalTime": 1689813165000
    },
    {
        "time": 1689813166000,
        "value": 139.492,
        "_internal_originalTime": 1689813166000
    },
    {
        "time": 1689813171000,
        "value": 139.487,
        "_internal_originalTime": 1689813171000
    },
    {
        "time": 1689813172000,
        "value": 139.493,
        "_internal_originalTime": 1689813172000
    },
    {
        "time": 1689813177000,
        "value": 139.5,
        "_internal_originalTime": 1689813177000
    },
    {
        "time": 1689813178000,
        "value": 139.504,
        "_internal_originalTime": 1689813178000
    },
    {
        "time": 1689813179000,
        "value": 139.502,
        "_internal_originalTime": 1689813179000
    },
    {
        "time": 1689813181000,
        "value": 139.512,
        "_internal_originalTime": 1689813181000
    },
    {
        "time": 1689813182000,
        "value": 139.519,
        "_internal_originalTime": 1689813182000
    },
    {
        "time": 1689813184000,
        "value": 139.52,
        "_internal_originalTime": 1689813184000
    },
    {
        "time": 1689813185000,
        "value": 139.521,
        "_internal_originalTime": 1689813185000
    },
    {
        "time": 1689813187000,
        "value": 139.53,
        "_internal_originalTime": 1689813187000
    },
    {
        "time": 1689813189000,
        "value": 139.519,
        "_internal_originalTime": 1689813189000
    },
    {
        "time": 1689813193000,
        "value": 139.518,
        "_internal_originalTime": 1689813193000
    },
    {
        "time": 1689813194000,
        "value": 139.519,
        "_internal_originalTime": 1689813194000
    },
    {
        "time": 1689813197000,
        "value": 139.514,
        "_internal_originalTime": 1689813197000
    },
    {
        "time": 1689813199000,
        "value": 139.516,
        "_internal_originalTime": 1689813199000
    },
    {
        "time": 1689813200000,
        "value": 139.516,
        "_internal_originalTime": 1689813200000
    },
    {
        "time": 1689813203000,
        "value": 139.516,
        "_internal_originalTime": 1689813203000
    },
    {
        "time": 1689813204000,
        "value": 139.517,
        "_internal_originalTime": 1689813204000
    },
    {
        "time": 1689813206000,
        "value": 139.519,
        "_internal_originalTime": 1689813206000
    },
    {
        "time": 1689813208000,
        "value": 139.508,
        "_internal_originalTime": 1689813208000
    },
    {
        "time": 1689813210000,
        "value": 139.52,
        "_internal_originalTime": 1689813210000
    },
    {
        "time": 1689813213000,
        "value": 139.52,
        "_internal_originalTime": 1689813213000
    },
    {
        "time": 1689813217000,
        "value": 139.52,
        "_internal_originalTime": 1689813217000
    },
    {
        "time": 1689813220000,
        "value": 139.522,
        "_internal_originalTime": 1689813220000
    },
    {
        "time": 1689813221000,
        "value": 139.519,
        "_internal_originalTime": 1689813221000
    },
    {
        "time": 1689813224000,
        "value": 139.52,
        "_internal_originalTime": 1689813224000
    },
    {
        "time": 1689813225000,
        "value": 139.521,
        "_internal_originalTime": 1689813225000
    },
    {
        "time": 1689813227000,
        "value": 139.521,
        "_internal_originalTime": 1689813227000
    },
    {
        "time": 1689813228000,
        "value": 139.52,
        "_internal_originalTime": 1689813228000
    },
    {
        "time": 1689813230000,
        "value": 139.519,
        "_internal_originalTime": 1689813230000
    },
    {
        "time": 1689813232000,
        "value": 139.518,
        "_internal_originalTime": 1689813232000
    },
    {
        "time": 1689813235000,
        "value": 139.52,
        "_internal_originalTime": 1689813235000
    },
    {
        "time": 1689813236000,
        "value": 139.521,
        "_internal_originalTime": 1689813236000
    },
    {
        "time": 1689813238000,
        "value": 139.523,
        "_internal_originalTime": 1689813238000
    },
    {
        "time": 1689813239000,
        "value": 139.52,
        "_internal_originalTime": 1689813239000
    },
    {
        "time": 1689813240000,
        "value": 139.524,
        "_internal_originalTime": 1689813240000
    },
    {
        "time": 1689813241000,
        "value": 139.521,
        "_internal_originalTime": 1689813241000
    },
    {
        "time": 1689813243000,
        "value": 139.522,
        "_internal_originalTime": 1689813243000
    },
    {
        "time": 1689813244000,
        "value": 139.523,
        "_internal_originalTime": 1689813244000
    },
    {
        "time": 1689813246000,
        "value": 139.518,
        "_internal_originalTime": 1689813246000
    },
    {
        "time": 1689813249000,
        "value": 139.519,
        "_internal_originalTime": 1689813249000
    },
    {
        "time": 1689813252000,
        "value": 139.514,
        "_internal_originalTime": 1689813252000
    },
    {
        "time": 1689813254000,
        "value": 139.512,
        "_internal_originalTime": 1689813254000
    },
    {
        "time": 1689813256000,
        "value": 139.514,
        "_internal_originalTime": 1689813256000
    },
    {
        "time": 1689813258000,
        "value": 139.514,
        "_internal_originalTime": 1689813258000
    },
    {
        "time": 1689813260000,
        "value": 139.512,
        "_internal_originalTime": 1689813260000
    },
    {
        "time": 1689813262000,
        "value": 139.508,
        "_internal_originalTime": 1689813262000
    },
    {
        "time": 1689813263000,
        "value": 139.514,
        "_internal_originalTime": 1689813263000
    },
    {
        "time": 1689813264000,
        "value": 139.509,
        "_internal_originalTime": 1689813264000
    },
    {
        "time": 1689813267000,
        "value": 139.51,
        "_internal_originalTime": 1689813267000
    },
    {
        "time": 1689813268000,
        "value": 139.508,
        "_internal_originalTime": 1689813268000
    },
    {
        "time": 1689813270000,
        "value": 139.508,
        "_internal_originalTime": 1689813270000
    },
    {
        "time": 1689813272000,
        "value": 139.504,
        "_internal_originalTime": 1689813272000
    },
    {
        "time": 1689813273000,
        "value": 139.497,
        "_internal_originalTime": 1689813273000
    },
    {
        "time": 1689813276000,
        "value": 139.476,
        "_internal_originalTime": 1689813276000
    },
    {
        "time": 1689813277000,
        "value": 139.462,
        "_internal_originalTime": 1689813277000
    },
    {
        "time": 1689813279000,
        "value": 139.467,
        "_internal_originalTime": 1689813279000
    },
    {
        "time": 1689813281000,
        "value": 139.46,
        "_internal_originalTime": 1689813281000
    },
    {
        "time": 1689813283000,
        "value": 139.463,
        "_internal_originalTime": 1689813283000
    },
    {
        "time": 1689813284000,
        "value": 139.459,
        "_internal_originalTime": 1689813284000
    },
    {
        "time": 1689813287000,
        "value": 139.459,
        "_internal_originalTime": 1689813287000
    },
    {
        "time": 1689813288000,
        "value": 139.458,
        "_internal_originalTime": 1689813288000
    },
    {
        "time": 1689813290000,
        "value": 139.458,
        "_internal_originalTime": 1689813290000
    },
    {
        "time": 1689813292000,
        "value": 139.464,
        "_internal_originalTime": 1689813292000
    },
    {
        "time": 1689813294000,
        "value": 139.47,
        "_internal_originalTime": 1689813294000
    },
    {
        "time": 1689813296000,
        "value": 139.475,
        "_internal_originalTime": 1689813296000
    },
    {
        "time": 1689813298000,
        "value": 139.477,
        "_internal_originalTime": 1689813298000
    },
    {
        "time": 1689813299000,
        "value": 139.479,
        "_internal_originalTime": 1689813299000
    },
    {
        "time": 1689813300000,
        "value": 139.478,
        "_internal_originalTime": 1689813300000
    },
    {
        "time": 1689813301000,
        "value": 139.478,
        "_internal_originalTime": 1689813301000
    },
    {
        "time": 1689813303000,
        "value": 139.488,
        "_internal_originalTime": 1689813303000
    },
    {
        "time": 1689813305000,
        "value": 139.492,
        "_internal_originalTime": 1689813305000
    },
    {
        "time": 1689813307000,
        "value": 139.496,
        "_internal_originalTime": 1689813307000
    },
    {
        "time": 1689813308000,
        "value": 139.502,
        "_internal_originalTime": 1689813308000
    },
    {
        "time": 1689813311000,
        "value": 139.5,
        "_internal_originalTime": 1689813311000
    },
    {
        "time": 1689813313000,
        "value": 139.501,
        "_internal_originalTime": 1689813313000
    },
    {
        "time": 1689813315000,
        "value": 139.5,
        "_internal_originalTime": 1689813315000
    },
    {
        "time": 1689813317000,
        "value": 139.497,
        "_internal_originalTime": 1689813317000
    },
    {
        "time": 1689813319000,
        "value": 139.5,
        "_internal_originalTime": 1689813319000
    },
    {
        "time": 1689813321000,
        "value": 139.499,
        "_internal_originalTime": 1689813321000
    },
    {
        "time": 1689813322000,
        "value": 139.502,
        "_internal_originalTime": 1689813322000
    },
    {
        "time": 1689813324000,
        "value": 139.498,
        "_internal_originalTime": 1689813324000
    },
    {
        "time": 1689813326000,
        "value": 139.498,
        "_internal_originalTime": 1689813326000
    },
    {
        "time": 1689813327000,
        "value": 139.486,
        "_internal_originalTime": 1689813327000
    },
    {
        "time": 1689813329000,
        "value": 139.482,
        "_internal_originalTime": 1689813329000
    },
    {
        "time": 1689813331000,
        "value": 139.482,
        "_internal_originalTime": 1689813331000
    },
    {
        "time": 1689813333000,
        "value": 139.492,
        "_internal_originalTime": 1689813333000
    },
    {
        "time": 1689813335000,
        "value": 139.492,
        "_internal_originalTime": 1689813335000
    },
    {
        "time": 1689813337000,
        "value": 139.486,
        "_internal_originalTime": 1689813337000
    },
    {
        "time": 1689813339000,
        "value": 139.485,
        "_internal_originalTime": 1689813339000
    },
    {
        "time": 1689813340000,
        "value": 139.481,
        "_internal_originalTime": 1689813340000
    },
    {
        "time": 1689813346000,
        "value": 139.479,
        "_internal_originalTime": 1689813346000
    },
    {
        "time": 1689813349000,
        "value": 139.476,
        "_internal_originalTime": 1689813349000
    },
    {
        "time": 1689813351000,
        "value": 139.479,
        "_internal_originalTime": 1689813351000
    },
    {
        "time": 1689813352000,
        "value": 139.479,
        "_internal_originalTime": 1689813352000
    },
    {
        "time": 1689813356000,
        "value": 139.476,
        "_internal_originalTime": 1689813356000
    },
    {
        "time": 1689813358000,
        "value": 139.479,
        "_internal_originalTime": 1689813358000
    },
    {
        "time": 1689813359000,
        "value": 139.477,
        "_internal_originalTime": 1689813359000
    },
    {
        "time": 1689813360000,
        "value": 139.476,
        "_internal_originalTime": 1689813360000
    },
    {
        "time": 1689813362000,
        "value": 139.479,
        "_internal_originalTime": 1689813362000
    },
    {
        "time": 1689813363000,
        "value": 139.479,
        "_internal_originalTime": 1689813363000
    },
    {
        "time": 1689813365000,
        "value": 139.479,
        "_internal_originalTime": 1689813365000
    },
    {
        "time": 1689813367000,
        "value": 139.477,
        "_internal_originalTime": 1689813367000
    },
    {
        "time": 1689813369000,
        "value": 139.481,
        "_internal_originalTime": 1689813369000
    },
    {
        "time": 1689813371000,
        "value": 139.479,
        "_internal_originalTime": 1689813371000
    },
    {
        "time": 1689813373000,
        "value": 139.495,
        "_internal_originalTime": 1689813373000
    },
    {
        "time": 1689813374000,
        "value": 139.495,
        "_internal_originalTime": 1689813374000
    },
    {
        "time": 1689813377000,
        "value": 139.494,
        "_internal_originalTime": 1689813377000
    },
    {
        "time": 1689813379000,
        "value": 139.511,
        "_internal_originalTime": 1689813379000
    },
    {
        "time": 1689813380000,
        "value": 139.51,
        "_internal_originalTime": 1689813380000
    },
    {
        "time": 1689813382000,
        "value": 139.51,
        "_internal_originalTime": 1689813382000
    },
    {
        "time": 1689813384000,
        "value": 139.51,
        "_internal_originalTime": 1689813384000
    },
    {
        "time": 1689813386000,
        "value": 139.507,
        "_internal_originalTime": 1689813386000
    },
    {
        "time": 1689813388000,
        "value": 139.51,
        "_internal_originalTime": 1689813388000
    },
    {
        "time": 1689813390000,
        "value": 139.512,
        "_internal_originalTime": 1689813390000
    },
    {
        "time": 1689813392000,
        "value": 139.512,
        "_internal_originalTime": 1689813392000
    },
    {
        "time": 1689813393000,
        "value": 139.508,
        "_internal_originalTime": 1689813393000
    },
    {
        "time": 1689813396000,
        "value": 139.513,
        "_internal_originalTime": 1689813396000
    },
    {
        "time": 1689813398000,
        "value": 139.513,
        "_internal_originalTime": 1689813398000
    },
    {
        "time": 1689813399000,
        "value": 139.511,
        "_internal_originalTime": 1689813399000
    },
    {
        "time": 1689813404000,
        "value": 139.51,
        "_internal_originalTime": 1689813404000
    },
    {
        "time": 1689813407000,
        "value": 139.514,
        "_internal_originalTime": 1689813407000
    },
    {
        "time": 1689813410000,
        "value": 139.498,
        "_internal_originalTime": 1689813410000
    },
    {
        "time": 1689813411000,
        "value": 139.502,
        "_internal_originalTime": 1689813411000
    },
    {
        "time": 1689813413000,
        "value": 139.505,
        "_internal_originalTime": 1689813413000
    },
    {
        "time": 1689813415000,
        "value": 139.503,
        "_internal_originalTime": 1689813415000
    },
    {
        "time": 1689813416000,
        "value": 139.497,
        "_internal_originalTime": 1689813416000
    },
    {
        "time": 1689813420000,
        "value": 139.505,
        "_internal_originalTime": 1689813420000
    },
    {
        "time": 1689813421000,
        "value": 139.502,
        "_internal_originalTime": 1689813421000
    },
    {
        "time": 1689813425000,
        "value": 139.509,
        "_internal_originalTime": 1689813425000
    },
    {
        "time": 1689813427000,
        "value": 139.515,
        "_internal_originalTime": 1689813427000
    },
    {
        "time": 1689813429000,
        "value": 139.515,
        "_internal_originalTime": 1689813429000
    },
    {
        "time": 1689813431000,
        "value": 139.512,
        "_internal_originalTime": 1689813431000
    },
    {
        "time": 1689813433000,
        "value": 139.512,
        "_internal_originalTime": 1689813433000
    },
    {
        "time": 1689813434000,
        "value": 139.51,
        "_internal_originalTime": 1689813434000
    },
    {
        "time": 1689813435000,
        "value": 139.51,
        "_internal_originalTime": 1689813435000
    },
    {
        "time": 1689813438000,
        "value": 139.522,
        "_internal_originalTime": 1689813438000
    },
    {
        "time": 1689813440000,
        "value": 139.519,
        "_internal_originalTime": 1689813440000
    },
    {
        "time": 1689813442000,
        "value": 139.521,
        "_internal_originalTime": 1689813442000
    },
    {
        "time": 1689813445000,
        "value": 139.522,
        "_internal_originalTime": 1689813445000
    },
    {
        "time": 1689813446000,
        "value": 139.516,
        "_internal_originalTime": 1689813446000
    },
    {
        "time": 1689813448000,
        "value": 139.518,
        "_internal_originalTime": 1689813448000
    },
    {
        "time": 1689813450000,
        "value": 139.518,
        "_internal_originalTime": 1689813450000
    },
    {
        "time": 1689813452000,
        "value": 139.522,
        "_internal_originalTime": 1689813452000
    },
    {
        "time": 1689813454000,
        "value": 139.518,
        "_internal_originalTime": 1689813454000
    },
    {
        "time": 1689813455000,
        "value": 139.518,
        "_internal_originalTime": 1689813455000
    },
    {
        "time": 1689813457000,
        "value": 139.519,
        "_internal_originalTime": 1689813457000
    },
    {
        "time": 1689813459000,
        "value": 139.523,
        "_internal_originalTime": 1689813459000
    },
    {
        "time": 1689813461000,
        "value": 139.518,
        "_internal_originalTime": 1689813461000
    },
    {
        "time": 1689813464000,
        "value": 139.519,
        "_internal_originalTime": 1689813464000
    },
    {
        "time": 1689813465000,
        "value": 139.522,
        "_internal_originalTime": 1689813465000
    },
    {
        "time": 1689813467000,
        "value": 139.522,
        "_internal_originalTime": 1689813467000
    },
    {
        "time": 1689813470000,
        "value": 139.516,
        "_internal_originalTime": 1689813470000
    },
    {
        "time": 1689813472000,
        "value": 139.518,
        "_internal_originalTime": 1689813472000
    },
    {
        "time": 1689813474000,
        "value": 139.518,
        "_internal_originalTime": 1689813474000
    },
    {
        "time": 1689813476000,
        "value": 139.516,
        "_internal_originalTime": 1689813476000
    },
    {
        "time": 1689813478000,
        "value": 139.522,
        "_internal_originalTime": 1689813478000
    },
    {
        "time": 1689813481000,
        "value": 139.517,
        "_internal_originalTime": 1689813481000
    },
    {
        "time": 1689813482000,
        "value": 139.514,
        "_internal_originalTime": 1689813482000
    },
    {
        "time": 1689813484000,
        "value": 139.503,
        "_internal_originalTime": 1689813484000
    },
    {
        "time": 1689813486000,
        "value": 139.498,
        "_internal_originalTime": 1689813486000
    },
    {
        "time": 1689813488000,
        "value": 139.489,
        "_internal_originalTime": 1689813488000
    },
    {
        "time": 1689813490000,
        "value": 139.485,
        "_internal_originalTime": 1689813490000
    },
    {
        "time": 1689813492000,
        "value": 139.475,
        "_internal_originalTime": 1689813492000
    },
    {
        "time": 1689813493000,
        "value": 139.476,
        "_internal_originalTime": 1689813493000
    },
    {
        "time": 1689813496000,
        "value": 139.47,
        "_internal_originalTime": 1689813496000
    },
    {
        "time": 1689813499000,
        "value": 139.475,
        "_internal_originalTime": 1689813499000
    },
    {
        "time": 1689813501000,
        "value": 139.472,
        "_internal_originalTime": 1689813501000
    },
    {
        "time": 1689813502000,
        "value": 139.47,
        "_internal_originalTime": 1689813502000
    },
    {
        "time": 1689813504000,
        "value": 139.474,
        "_internal_originalTime": 1689813504000
    },
    {
        "time": 1689813506000,
        "value": 139.468,
        "_internal_originalTime": 1689813506000
    },
    {
        "time": 1689813507000,
        "value": 139.468,
        "_internal_originalTime": 1689813507000
    },
    {
        "time": 1689813509000,
        "value": 139.474,
        "_internal_originalTime": 1689813509000
    },
    {
        "time": 1689813511000,
        "value": 139.47,
        "_internal_originalTime": 1689813511000
    },
    {
        "time": 1689813513000,
        "value": 139.468,
        "_internal_originalTime": 1689813513000
    },
    {
        "time": 1689813515000,
        "value": 139.473,
        "_internal_originalTime": 1689813515000
    },
    {
        "time": 1689813517000,
        "value": 139.473,
        "_internal_originalTime": 1689813517000
    },
    {
        "time": 1689813519000,
        "value": 139.475,
        "_internal_originalTime": 1689813519000
    },
    {
        "time": 1689813521000,
        "value": 139.479,
        "_internal_originalTime": 1689813521000
    },
    {
        "time": 1689813523000,
        "value": 139.48,
        "_internal_originalTime": 1689813523000
    },
    {
        "time": 1689813524000,
        "value": 139.475,
        "_internal_originalTime": 1689813524000
    },
    {
        "time": 1689813526000,
        "value": 139.474,
        "_internal_originalTime": 1689813526000
    },
    {
        "time": 1689813527000,
        "value": 139.479,
        "_internal_originalTime": 1689813527000
    },
    {
        "time": 1689813531000,
        "value": 139.474,
        "_internal_originalTime": 1689813531000
    },
    {
        "time": 1689813533000,
        "value": 139.474,
        "_internal_originalTime": 1689813533000
    },
    {
        "time": 1689813535000,
        "value": 139.479,
        "_internal_originalTime": 1689813535000
    },
    {
        "time": 1689813537000,
        "value": 139.474,
        "_internal_originalTime": 1689813537000
    },
    {
        "time": 1689813539000,
        "value": 139.474,
        "_internal_originalTime": 1689813539000
    },
    {
        "time": 1689813540000,
        "value": 139.473,
        "_internal_originalTime": 1689813540000
    },
    {
        "time": 1689813541000,
        "value": 139.474,
        "_internal_originalTime": 1689813541000
    },
    {
        "time": 1689813543000,
        "value": 139.469,
        "_internal_originalTime": 1689813543000
    },
    {
        "time": 1689813544000,
        "value": 139.473,
        "_internal_originalTime": 1689813544000
    },
    {
        "time": 1689813547000,
        "value": 139.473,
        "_internal_originalTime": 1689813547000
    },
    {
        "time": 1689813551000,
        "value": 139.468,
        "_internal_originalTime": 1689813551000
    },
    {
        "time": 1689813553000,
        "value": 139.47,
        "_internal_originalTime": 1689813553000
    },
    {
        "time": 1689813555000,
        "value": 139.454,
        "_internal_originalTime": 1689813555000
    },
    {
        "time": 1689813557000,
        "value": 139.452,
        "_internal_originalTime": 1689813557000
    },
    {
        "time": 1689813559000,
        "value": 139.463,
        "_internal_originalTime": 1689813559000
    },
    {
        "time": 1689813560000,
        "value": 139.462,
        "_internal_originalTime": 1689813560000
    },
    {
        "time": 1689813563000,
        "value": 139.462,
        "_internal_originalTime": 1689813563000
    },
    {
        "time": 1689813566000,
        "value": 139.467,
        "_internal_originalTime": 1689813566000
    },
    {
        "time": 1689813567000,
        "value": 139.48,
        "_internal_originalTime": 1689813567000
    },
    {
        "time": 1689813569000,
        "value": 139.487,
        "_internal_originalTime": 1689813569000
    },
    {
        "time": 1689813571000,
        "value": 139.487,
        "_internal_originalTime": 1689813571000
    },
    {
        "time": 1689813572000,
        "value": 139.494,
        "_internal_originalTime": 1689813572000
    },
    {
        "time": 1689813574000,
        "value": 139.493,
        "_internal_originalTime": 1689813574000
    },
    {
        "time": 1689813576000,
        "value": 139.493,
        "_internal_originalTime": 1689813576000
    },
    {
        "time": 1689813578000,
        "value": 139.493,
        "_internal_originalTime": 1689813578000
    },
    {
        "time": 1689813580000,
        "value": 139.493,
        "_internal_originalTime": 1689813580000
    },
    {
        "time": 1689813582000,
        "value": 139.501,
        "_internal_originalTime": 1689813582000
    },
    {
        "time": 1689813585000,
        "value": 139.498,
        "_internal_originalTime": 1689813585000
    },
    {
        "time": 1689813587000,
        "value": 139.497,
        "_internal_originalTime": 1689813587000
    },
    {
        "time": 1689813589000,
        "value": 139.496,
        "_internal_originalTime": 1689813589000
    },
    {
        "time": 1689813591000,
        "value": 139.501,
        "_internal_originalTime": 1689813591000
    },
    {
        "time": 1689813593000,
        "value": 139.49,
        "_internal_originalTime": 1689813593000
    },
    {
        "time": 1689813595000,
        "value": 139.5,
        "_internal_originalTime": 1689813595000
    },
    {
        "time": 1689813596000,
        "value": 139.497,
        "_internal_originalTime": 1689813596000
    },
    {
        "time": 1689813599000,
        "value": 139.501,
        "_internal_originalTime": 1689813599000
    },
    {
        "time": 1689813600000,
        "value": 139.499,
        "_internal_originalTime": 1689813600000
    },
    {
        "time": 1689813602000,
        "value": 139.498,
        "_internal_originalTime": 1689813602000
    },
    {
        "time": 1689813604000,
        "value": 139.502,
        "_internal_originalTime": 1689813604000
    },
    {
        "time": 1689813606000,
        "value": 139.504,
        "_internal_originalTime": 1689813606000
    },
    {
        "time": 1689813608000,
        "value": 139.514,
        "_internal_originalTime": 1689813608000
    },
    {
        "time": 1689813610000,
        "value": 139.518,
        "_internal_originalTime": 1689813610000
    },
    {
        "time": 1689813612000,
        "value": 139.517,
        "_internal_originalTime": 1689813612000
    },
    {
        "time": 1689813613000,
        "value": 139.52,
        "_internal_originalTime": 1689813613000
    },
    {
        "time": 1689813616000,
        "value": 139.519,
        "_internal_originalTime": 1689813616000
    },
    {
        "time": 1689813617000,
        "value": 139.519,
        "_internal_originalTime": 1689813617000
    },
    {
        "time": 1689813619000,
        "value": 139.521,
        "_internal_originalTime": 1689813619000
    },
    {
        "time": 1689813621000,
        "value": 139.52,
        "_internal_originalTime": 1689813621000
    },
    {
        "time": 1689813623000,
        "value": 139.516,
        "_internal_originalTime": 1689813623000
    },
    {
        "time": 1689813625000,
        "value": 139.52,
        "_internal_originalTime": 1689813625000
    },
    {
        "time": 1689813626000,
        "value": 139.514,
        "_internal_originalTime": 1689813626000
    },
    {
        "time": 1689813628000,
        "value": 139.52,
        "_internal_originalTime": 1689813628000
    },
    {
        "time": 1689813630000,
        "value": 139.516,
        "_internal_originalTime": 1689813630000
    },
    {
        "time": 1689813632000,
        "value": 139.517,
        "_internal_originalTime": 1689813632000
    },
    {
        "time": 1689813634000,
        "value": 139.521,
        "_internal_originalTime": 1689813634000
    },
    {
        "time": 1689813636000,
        "value": 139.518,
        "_internal_originalTime": 1689813636000
    },
    {
        "time": 1689813638000,
        "value": 139.517,
        "_internal_originalTime": 1689813638000
    },
    {
        "time": 1689813639000,
        "value": 139.516,
        "_internal_originalTime": 1689813639000
    },
    {
        "time": 1689813641000,
        "value": 139.519,
        "_internal_originalTime": 1689813641000
    },
    {
        "time": 1689813643000,
        "value": 139.52,
        "_internal_originalTime": 1689813643000
    },
    {
        "time": 1689813645000,
        "value": 139.52,
        "_internal_originalTime": 1689813645000
    },
    {
        "time": 1689813646000,
        "value": 139.518,
        "_internal_originalTime": 1689813646000
    },
    {
        "time": 1689813648000,
        "value": 139.5,
        "_internal_originalTime": 1689813648000
    },
    {
        "time": 1689813650000,
        "value": 139.503,
        "_internal_originalTime": 1689813650000
    },
    {
        "time": 1689813652000,
        "value": 139.5,
        "_internal_originalTime": 1689813652000
    },
    {
        "time": 1689813654000,
        "value": 139.5,
        "_internal_originalTime": 1689813654000
    },
    {
        "time": 1689813656000,
        "value": 139.505,
        "_internal_originalTime": 1689813656000
    },
    {
        "time": 1689813658000,
        "value": 139.511,
        "_internal_originalTime": 1689813658000
    },
    {
        "time": 1689813659000,
        "value": 139.513,
        "_internal_originalTime": 1689813659000
    },
    {
        "time": 1689813660000,
        "value": 139.508,
        "_internal_originalTime": 1689813660000
    },
    {
        "time": 1689813662000,
        "value": 139.507,
        "_internal_originalTime": 1689813662000
    },
    {
        "time": 1689813663000,
        "value": 139.508,
        "_internal_originalTime": 1689813663000
    },
    {
        "time": 1689813665000,
        "value": 139.514,
        "_internal_originalTime": 1689813665000
    },
    {
        "time": 1689813667000,
        "value": 139.51,
        "_internal_originalTime": 1689813667000
    },
    {
        "time": 1689813670000,
        "value": 139.516,
        "_internal_originalTime": 1689813670000
    },
    {
        "time": 1689813672000,
        "value": 139.513,
        "_internal_originalTime": 1689813672000
    },
    {
        "time": 1689813674000,
        "value": 139.513,
        "_internal_originalTime": 1689813674000
    },
    {
        "time": 1689813676000,
        "value": 139.514,
        "_internal_originalTime": 1689813676000
    },
    {
        "time": 1689813678000,
        "value": 139.509,
        "_internal_originalTime": 1689813678000
    },
    {
        "time": 1689813680000,
        "value": 139.509,
        "_internal_originalTime": 1689813680000
    },
    {
        "time": 1689813681000,
        "value": 139.51,
        "_internal_originalTime": 1689813681000
    },
    {
        "time": 1689813686000,
        "value": 139.514,
        "_internal_originalTime": 1689813686000
    },
    {
        "time": 1689813688000,
        "value": 139.518,
        "_internal_originalTime": 1689813688000
    },
    {
        "time": 1689813689000,
        "value": 139.52,
        "_internal_originalTime": 1689813689000
    },
    {
        "time": 1689813690000,
        "value": 139.514,
        "_internal_originalTime": 1689813690000
    },
    {
        "time": 1689813693000,
        "value": 139.512,
        "_internal_originalTime": 1689813693000
    },
    {
        "time": 1689813694000,
        "value": 139.514,
        "_internal_originalTime": 1689813694000
    },
    {
        "time": 1689813696000,
        "value": 139.509,
        "_internal_originalTime": 1689813696000
    },
    {
        "time": 1689813699000,
        "value": 139.514,
        "_internal_originalTime": 1689813699000
    },
    {
        "time": 1689813700000,
        "value": 139.509,
        "_internal_originalTime": 1689813700000
    },
    {
        "time": 1689813702000,
        "value": 139.508,
        "_internal_originalTime": 1689813702000
    },
    {
        "time": 1689813703000,
        "value": 139.509,
        "_internal_originalTime": 1689813703000
    },
    {
        "time": 1689813707000,
        "value": 139.488,
        "_internal_originalTime": 1689813707000
    },
    {
        "time": 1689813708000,
        "value": 139.493,
        "_internal_originalTime": 1689813708000
    },
    {
        "time": 1689813709000,
        "value": 139.493,
        "_internal_originalTime": 1689813709000
    },
    {
        "time": 1689813711000,
        "value": 139.483,
        "_internal_originalTime": 1689813711000
    },
    {
        "time": 1689813713000,
        "value": 139.48,
        "_internal_originalTime": 1689813713000
    },
    {
        "time": 1689813715000,
        "value": 139.476,
        "_internal_originalTime": 1689813715000
    },
    {
        "time": 1689813717000,
        "value": 139.481,
        "_internal_originalTime": 1689813717000
    },
    {
        "time": 1689813719000,
        "value": 139.485,
        "_internal_originalTime": 1689813719000
    },
    {
        "time": 1689813721000,
        "value": 139.489,
        "_internal_originalTime": 1689813721000
    },
    {
        "time": 1689813722000,
        "value": 139.489,
        "_internal_originalTime": 1689813722000
    },
    {
        "time": 1689813724000,
        "value": 139.493,
        "_internal_originalTime": 1689813724000
    },
    {
        "time": 1689813725000,
        "value": 139.502,
        "_internal_originalTime": 1689813725000
    },
    {
        "time": 1689813726000,
        "value": 139.503,
        "_internal_originalTime": 1689813726000
    },
    {
        "time": 1689813729000,
        "value": 139.5,
        "_internal_originalTime": 1689813729000
    },
    {
        "time": 1689813731000,
        "value": 139.507,
        "_internal_originalTime": 1689813731000
    },
    {
        "time": 1689813733000,
        "value": 139.517,
        "_internal_originalTime": 1689813733000
    },
    {
        "time": 1689813734000,
        "value": 139.516,
        "_internal_originalTime": 1689813734000
    },
    {
        "time": 1689813736000,
        "value": 139.518,
        "_internal_originalTime": 1689813736000
    },
    {
        "time": 1689813738000,
        "value": 139.508,
        "_internal_originalTime": 1689813738000
    },
    {
        "time": 1689813740000,
        "value": 139.508,
        "_internal_originalTime": 1689813740000
    },
    {
        "time": 1689813742000,
        "value": 139.508,
        "_internal_originalTime": 1689813742000
    },
    {
        "time": 1689813744000,
        "value": 139.511,
        "_internal_originalTime": 1689813744000
    },
    {
        "time": 1689813745000,
        "value": 139.508,
        "_internal_originalTime": 1689813745000
    },
    {
        "time": 1689813746000,
        "value": 139.505,
        "_internal_originalTime": 1689813746000
    },
    {
        "time": 1689813750000,
        "value": 139.509,
        "_internal_originalTime": 1689813750000
    },
    {
        "time": 1689813752000,
        "value": 139.513,
        "_internal_originalTime": 1689813752000
    },
    {
        "time": 1689813753000,
        "value": 139.52,
        "_internal_originalTime": 1689813753000
    },
    {
        "time": 1689813755000,
        "value": 139.521,
        "_internal_originalTime": 1689813755000
    },
    {
        "time": 1689813757000,
        "value": 139.52,
        "_internal_originalTime": 1689813757000
    },
    {
        "time": 1689813759000,
        "value": 139.518,
        "_internal_originalTime": 1689813759000
    },
    {
        "time": 1689813763000,
        "value": 139.521,
        "_internal_originalTime": 1689813763000
    },
    {
        "time": 1689813765000,
        "value": 139.519,
        "_internal_originalTime": 1689813765000
    },
    {
        "time": 1689813766000,
        "value": 139.523,
        "_internal_originalTime": 1689813766000
    },
    {
        "time": 1689813768000,
        "value": 139.531,
        "_internal_originalTime": 1689813768000
    },
    {
        "time": 1689813769000,
        "value": 139.538,
        "_internal_originalTime": 1689813769000
    },
    {
        "time": 1689813771000,
        "value": 139.551,
        "_internal_originalTime": 1689813771000
    },
    {
        "time": 1689813773000,
        "value": 139.553,
        "_internal_originalTime": 1689813773000
    },
    {
        "time": 1689813775000,
        "value": 139.551,
        "_internal_originalTime": 1689813775000
    },
    {
        "time": 1689813777000,
        "value": 139.555,
        "_internal_originalTime": 1689813777000
    },
    {
        "time": 1689813779000,
        "value": 139.554,
        "_internal_originalTime": 1689813779000
    },
    {
        "time": 1689813780000,
        "value": 139.553,
        "_internal_originalTime": 1689813780000
    },
    {
        "time": 1689813782000,
        "value": 139.555,
        "_internal_originalTime": 1689813782000
    },
    {
        "time": 1689813783000,
        "value": 139.55,
        "_internal_originalTime": 1689813783000
    },
    {
        "time": 1689813785000,
        "value": 139.549,
        "_internal_originalTime": 1689813785000
    },
    {
        "time": 1689813787000,
        "value": 139.546,
        "_internal_originalTime": 1689813787000
    },
    {
        "time": 1689813789000,
        "value": 139.546,
        "_internal_originalTime": 1689813789000
    },
    {
        "time": 1689813790000,
        "value": 139.547,
        "_internal_originalTime": 1689813790000
    },
    {
        "time": 1689813792000,
        "value": 139.539,
        "_internal_originalTime": 1689813792000
    },
    {
        "time": 1689813794000,
        "value": 139.543,
        "_internal_originalTime": 1689813794000
    },
    {
        "time": 1689813795000,
        "value": 139.536,
        "_internal_originalTime": 1689813795000
    },
    {
        "time": 1689813797000,
        "value": 139.538,
        "_internal_originalTime": 1689813797000
    },
    {
        "time": 1689813800000,
        "value": 139.543,
        "_internal_originalTime": 1689813800000
    },
    {
        "time": 1689813802000,
        "value": 139.541,
        "_internal_originalTime": 1689813802000
    },
    {
        "time": 1689813804000,
        "value": 139.535,
        "_internal_originalTime": 1689813804000
    },
    {
        "time": 1689813806000,
        "value": 139.532,
        "_internal_originalTime": 1689813806000
    },
    {
        "time": 1689813810000,
        "value": 139.541,
        "_internal_originalTime": 1689813810000
    },
    {
        "time": 1689813811000,
        "value": 139.542,
        "_internal_originalTime": 1689813811000
    },
    {
        "time": 1689813812000,
        "value": 139.536,
        "_internal_originalTime": 1689813812000
    },
    {
        "time": 1689813815000,
        "value": 139.538,
        "_internal_originalTime": 1689813815000
    },
    {
        "time": 1689813817000,
        "value": 139.536,
        "_internal_originalTime": 1689813817000
    },
    {
        "time": 1689813819000,
        "value": 139.542,
        "_internal_originalTime": 1689813819000
    },
    {
        "time": 1689813820000,
        "value": 139.541,
        "_internal_originalTime": 1689813820000
    },
    {
        "time": 1689813822000,
        "value": 139.536,
        "_internal_originalTime": 1689813822000
    },
    {
        "time": 1689813824000,
        "value": 139.537,
        "_internal_originalTime": 1689813824000
    },
    {
        "time": 1689813827000,
        "value": 139.536,
        "_internal_originalTime": 1689813827000
    },
    {
        "time": 1689813828000,
        "value": 139.54,
        "_internal_originalTime": 1689813828000
    },
    {
        "time": 1689813831000,
        "value": 139.534,
        "_internal_originalTime": 1689813831000
    },
    {
        "time": 1689813832000,
        "value": 139.536,
        "_internal_originalTime": 1689813832000
    },
    {
        "time": 1689813834000,
        "value": 139.537,
        "_internal_originalTime": 1689813834000
    },
    {
        "time": 1689813837000,
        "value": 139.542,
        "_internal_originalTime": 1689813837000
    },
    {
        "time": 1689813841000,
        "value": 139.538,
        "_internal_originalTime": 1689813841000
    },
    {
        "time": 1689813842000,
        "value": 139.548,
        "_internal_originalTime": 1689813842000
    },
    {
        "time": 1689813843000,
        "value": 139.55,
        "_internal_originalTime": 1689813843000
    },
    {
        "time": 1689813844000,
        "value": 139.541,
        "_internal_originalTime": 1689813844000
    },
    {
        "time": 1689813846000,
        "value": 139.535,
        "_internal_originalTime": 1689813846000
    },
    {
        "time": 1689813848000,
        "value": 139.535,
        "_internal_originalTime": 1689813848000
    },
    {
        "time": 1689813850000,
        "value": 139.543,
        "_internal_originalTime": 1689813850000
    },
    {
        "time": 1689813853000,
        "value": 139.536,
        "_internal_originalTime": 1689813853000
    },
    {
        "time": 1689813854000,
        "value": 139.538,
        "_internal_originalTime": 1689813854000
    },
    {
        "time": 1689813856000,
        "value": 139.538,
        "_internal_originalTime": 1689813856000
    },
    {
        "time": 1689813858000,
        "value": 139.546,
        "_internal_originalTime": 1689813858000
    },
    {
        "time": 1689813860000,
        "value": 139.542,
        "_internal_originalTime": 1689813860000
    },
    {
        "time": 1689813862000,
        "value": 139.546,
        "_internal_originalTime": 1689813862000
    },
    {
        "time": 1689813864000,
        "value": 139.546,
        "_internal_originalTime": 1689813864000
    },
    {
        "time": 1689813866000,
        "value": 139.551,
        "_internal_originalTime": 1689813866000
    },
    {
        "time": 1689813867000,
        "value": 139.548,
        "_internal_originalTime": 1689813867000
    },
    {
        "time": 1689813869000,
        "value": 139.554,
        "_internal_originalTime": 1689813869000
    },
    {
        "time": 1689813870000,
        "value": 139.548,
        "_internal_originalTime": 1689813870000
    },
    {
        "time": 1689813872000,
        "value": 139.55,
        "_internal_originalTime": 1689813872000
    },
    {
        "time": 1689813874000,
        "value": 139.548,
        "_internal_originalTime": 1689813874000
    },
    {
        "time": 1689813876000,
        "value": 139.547,
        "_internal_originalTime": 1689813876000
    },
    {
        "time": 1689813877000,
        "value": 139.547,
        "_internal_originalTime": 1689813877000
    },
    {
        "time": 1689813880000,
        "value": 139.548,
        "_internal_originalTime": 1689813880000
    },
    {
        "time": 1689813881000,
        "value": 139.548,
        "_internal_originalTime": 1689813881000
    },
    {
        "time": 1689813883000,
        "value": 139.554,
        "_internal_originalTime": 1689813883000
    },
    {
        "time": 1689813884000,
        "value": 139.553,
        "_internal_originalTime": 1689813884000
    },
    {
        "time": 1689813886000,
        "value": 139.55,
        "_internal_originalTime": 1689813886000
    },
    {
        "time": 1689813887000,
        "value": 139.55,
        "_internal_originalTime": 1689813887000
    },
    {
        "time": 1689813889000,
        "value": 139.551,
        "_internal_originalTime": 1689813889000
    },
    {
        "time": 1689813891000,
        "value": 139.55,
        "_internal_originalTime": 1689813891000
    },
    {
        "time": 1689813893000,
        "value": 139.549,
        "_internal_originalTime": 1689813893000
    },
    {
        "time": 1689813896000,
        "value": 139.548,
        "_internal_originalTime": 1689813896000
    },
    {
        "time": 1689813898000,
        "value": 139.553,
        "_internal_originalTime": 1689813898000
    },
    {
        "time": 1689813899000,
        "value": 139.558,
        "_internal_originalTime": 1689813899000
    },
    {
        "time": 1689813900000,
        "value": 139.552,
        "_internal_originalTime": 1689813900000
    },
    {
        "time": 1689813902000,
        "value": 139.554,
        "_internal_originalTime": 1689813902000
    },
    {
        "time": 1689813904000,
        "value": 139.554,
        "_internal_originalTime": 1689813904000
    },
    {
        "time": 1689813907000,
        "value": 139.559,
        "_internal_originalTime": 1689813907000
    },
    {
        "time": 1689813909000,
        "value": 139.56,
        "_internal_originalTime": 1689813909000
    },
    {
        "time": 1689813911000,
        "value": 139.561,
        "_internal_originalTime": 1689813911000
    },
    {
        "time": 1689813913000,
        "value": 139.558,
        "_internal_originalTime": 1689813913000
    },
    {
        "time": 1689813915000,
        "value": 139.558,
        "_internal_originalTime": 1689813915000
    },
    {
        "time": 1689813917000,
        "value": 139.556,
        "_internal_originalTime": 1689813917000
    },
    {
        "time": 1689813919000,
        "value": 139.562,
        "_internal_originalTime": 1689813919000
    },
    {
        "time": 1689813921000,
        "value": 139.562,
        "_internal_originalTime": 1689813921000
    },
    {
        "time": 1689813923000,
        "value": 139.558,
        "_internal_originalTime": 1689813923000
    },
    {
        "time": 1689813925000,
        "value": 139.561,
        "_internal_originalTime": 1689813925000
    },
    {
        "time": 1689813926000,
        "value": 139.554,
        "_internal_originalTime": 1689813926000
    },
    {
        "time": 1689813929000,
        "value": 139.556,
        "_internal_originalTime": 1689813929000
    },
    {
        "time": 1689813932000,
        "value": 139.546,
        "_internal_originalTime": 1689813932000
    },
    {
        "time": 1689813935000,
        "value": 139.549,
        "_internal_originalTime": 1689813935000
    },
    {
        "time": 1689813940000,
        "value": 139.54,
        "_internal_originalTime": 1689813940000
    },
    {
        "time": 1689813941000,
        "value": 139.539,
        "_internal_originalTime": 1689813941000
    },
    {
        "time": 1689813943000,
        "value": 139.537,
        "_internal_originalTime": 1689813943000
    },
    {
        "time": 1689813944000,
        "value": 139.541,
        "_internal_originalTime": 1689813944000
    },
    {
        "time": 1689813945000,
        "value": 139.536,
        "_internal_originalTime": 1689813945000
    },
    {
        "time": 1689813947000,
        "value": 139.54,
        "_internal_originalTime": 1689813947000
    },
    {
        "time": 1689813950000,
        "value": 139.542,
        "_internal_originalTime": 1689813950000
    },
    {
        "time": 1689813955000,
        "value": 139.549,
        "_internal_originalTime": 1689813955000
    },
    {
        "time": 1689813957000,
        "value": 139.542,
        "_internal_originalTime": 1689813957000
    },
    {
        "time": 1689813959000,
        "value": 139.55,
        "_internal_originalTime": 1689813959000
    },
    {
        "time": 1689813961000,
        "value": 139.552,
        "_internal_originalTime": 1689813961000
    },
    {
        "time": 1689813963000,
        "value": 139.551,
        "_internal_originalTime": 1689813963000
    },
    {
        "time": 1689813964000,
        "value": 139.555,
        "_internal_originalTime": 1689813964000
    },
    {
        "time": 1689813966000,
        "value": 139.562,
        "_internal_originalTime": 1689813966000
    },
    {
        "time": 1689813968000,
        "value": 139.565,
        "_internal_originalTime": 1689813968000
    },
    {
        "time": 1689813970000,
        "value": 139.565,
        "_internal_originalTime": 1689813970000
    },
    {
        "time": 1689813972000,
        "value": 139.564,
        "_internal_originalTime": 1689813972000
    },
    {
        "time": 1689813974000,
        "value": 139.576,
        "_internal_originalTime": 1689813974000
    },
    {
        "time": 1689813975000,
        "value": 139.58,
        "_internal_originalTime": 1689813975000
    },
    {
        "time": 1689813977000,
        "value": 139.576,
        "_internal_originalTime": 1689813977000
    },
    {
        "time": 1689813980000,
        "value": 139.581,
        "_internal_originalTime": 1689813980000
    },
    {
        "time": 1689813982000,
        "value": 139.581,
        "_internal_originalTime": 1689813982000
    },
    {
        "time": 1689813983000,
        "value": 139.576,
        "_internal_originalTime": 1689813983000
    },
    {
        "time": 1689813986000,
        "value": 139.579,
        "_internal_originalTime": 1689813986000
    },
    {
        "time": 1689813987000,
        "value": 139.566,
        "_internal_originalTime": 1689813987000
    },
    {
        "time": 1689813988000,
        "value": 139.568,
        "_internal_originalTime": 1689813988000
    },
    {
        "time": 1689813990000,
        "value": 139.574,
        "_internal_originalTime": 1689813990000
    },
    {
        "time": 1689813992000,
        "value": 139.575,
        "_internal_originalTime": 1689813992000
    },
    {
        "time": 1689813994000,
        "value": 139.566,
        "_internal_originalTime": 1689813994000
    },
    {
        "time": 1689813998000,
        "value": 139.563,
        "_internal_originalTime": 1689813998000
    },
    {
        "time": 1689814000000,
        "value": 139.559,
        "_internal_originalTime": 1689814000000
    },
    {
        "time": 1689814002000,
        "value": 139.561,
        "_internal_originalTime": 1689814002000
    },
    {
        "time": 1689814003000,
        "value": 139.554,
        "_internal_originalTime": 1689814003000
    },
    {
        "time": 1689814008000,
        "value": 139.555,
        "_internal_originalTime": 1689814008000
    },
    {
        "time": 1689814011000,
        "value": 139.57,
        "_internal_originalTime": 1689814011000
    },
    {
        "time": 1689814013000,
        "value": 139.566,
        "_internal_originalTime": 1689814013000
    },
    {
        "time": 1689814017000,
        "value": 139.565,
        "_internal_originalTime": 1689814017000
    },
    {
        "time": 1689814019000,
        "value": 139.566,
        "_internal_originalTime": 1689814019000
    },
    {
        "time": 1689814020000,
        "value": 139.571,
        "_internal_originalTime": 1689814020000
    },
    {
        "time": 1689814021000,
        "value": 139.566,
        "_internal_originalTime": 1689814021000
    },
    {
        "time": 1689814022000,
        "value": 139.574,
        "_internal_originalTime": 1689814022000
    },
    {
        "time": 1689814023000,
        "value": 139.575,
        "_internal_originalTime": 1689814023000
    },
    {
        "time": 1689814025000,
        "value": 139.579,
        "_internal_originalTime": 1689814025000
    },
    {
        "time": 1689814026000,
        "value": 139.575,
        "_internal_originalTime": 1689814026000
    },
    {
        "time": 1689814027000,
        "value": 139.58,
        "_internal_originalTime": 1689814027000
    },
    {
        "time": 1689814028000,
        "value": 139.574,
        "_internal_originalTime": 1689814028000
    },
    {
        "time": 1689814029000,
        "value": 139.576,
        "_internal_originalTime": 1689814029000
    },
    {
        "time": 1689814032000,
        "value": 139.58,
        "_internal_originalTime": 1689814032000
    },
    {
        "time": 1689814034000,
        "value": 139.574,
        "_internal_originalTime": 1689814034000
    },
    {
        "time": 1689814043000,
        "value": 139.574,
        "_internal_originalTime": 1689814043000
    },
    {
        "time": 1689814046000,
        "value": 139.576,
        "_internal_originalTime": 1689814046000
    },
    {
        "time": 1689814047000,
        "value": 139.581,
        "_internal_originalTime": 1689814047000
    },
    {
        "time": 1689814049000,
        "value": 139.581,
        "_internal_originalTime": 1689814049000
    },
    {
        "time": 1689814050000,
        "value": 139.576,
        "_internal_originalTime": 1689814050000
    },
    {
        "time": 1689814052000,
        "value": 139.574,
        "_internal_originalTime": 1689814052000
    },
    {
        "time": 1689814054000,
        "value": 139.574,
        "_internal_originalTime": 1689814054000
    },
    {
        "time": 1689814056000,
        "value": 139.575,
        "_internal_originalTime": 1689814056000
    },
    {
        "time": 1689814058000,
        "value": 139.58,
        "_internal_originalTime": 1689814058000
    },
    {
        "time": 1689814061000,
        "value": 139.582,
        "_internal_originalTime": 1689814061000
    },
    {
        "time": 1689814062000,
        "value": 139.576,
        "_internal_originalTime": 1689814062000
    },
    {
        "time": 1689814065000,
        "value": 139.576,
        "_internal_originalTime": 1689814065000
    },
    {
        "time": 1689814066000,
        "value": 139.576,
        "_internal_originalTime": 1689814066000
    },
    {
        "time": 1689814068000,
        "value": 139.581,
        "_internal_originalTime": 1689814068000
    },
    {
        "time": 1689814070000,
        "value": 139.588,
        "_internal_originalTime": 1689814070000
    },
    {
        "time": 1689814074000,
        "value": 139.574,
        "_internal_originalTime": 1689814074000
    },
    {
        "time": 1689814076000,
        "value": 139.564,
        "_internal_originalTime": 1689814076000
    },
    {
        "time": 1689814078000,
        "value": 139.57,
        "_internal_originalTime": 1689814078000
    },
    {
        "time": 1689814079000,
        "value": 139.569,
        "_internal_originalTime": 1689814079000
    },
    {
        "time": 1689814080000,
        "value": 139.564,
        "_internal_originalTime": 1689814080000
    },
    {
        "time": 1689814081000,
        "value": 139.562,
        "_internal_originalTime": 1689814081000
    },
    {
        "time": 1689814083000,
        "value": 139.56,
        "_internal_originalTime": 1689814083000
    },
    {
        "time": 1689814085000,
        "value": 139.55,
        "_internal_originalTime": 1689814085000
    },
    {
        "time": 1689814087000,
        "value": 139.544,
        "_internal_originalTime": 1689814087000
    },
    {
        "time": 1689814088000,
        "value": 139.544,
        "_internal_originalTime": 1689814088000
    },
    {
        "time": 1689814089000,
        "value": 139.55,
        "_internal_originalTime": 1689814089000
    },
    {
        "time": 1689814095000,
        "value": 139.55,
        "_internal_originalTime": 1689814095000
    },
    {
        "time": 1689814097000,
        "value": 139.544,
        "_internal_originalTime": 1689814097000
    },
    {
        "time": 1689814099000,
        "value": 139.543,
        "_internal_originalTime": 1689814099000
    },
    {
        "time": 1689814101000,
        "value": 139.544,
        "_internal_originalTime": 1689814101000
    },
    {
        "time": 1689814102000,
        "value": 139.538,
        "_internal_originalTime": 1689814102000
    },
    {
        "time": 1689814104000,
        "value": 139.538,
        "_internal_originalTime": 1689814104000
    },
    {
        "time": 1689814107000,
        "value": 139.536,
        "_internal_originalTime": 1689814107000
    },
    {
        "time": 1689814108000,
        "value": 139.542,
        "_internal_originalTime": 1689814108000
    },
    {
        "time": 1689814110000,
        "value": 139.541,
        "_internal_originalTime": 1689814110000
    },
    {
        "time": 1689814111000,
        "value": 139.536,
        "_internal_originalTime": 1689814111000
    },
    {
        "time": 1689814113000,
        "value": 139.553,
        "_internal_originalTime": 1689814113000
    },
    {
        "time": 1689814115000,
        "value": 139.539,
        "_internal_originalTime": 1689814115000
    },
    {
        "time": 1689814117000,
        "value": 139.538,
        "_internal_originalTime": 1689814117000
    },
    {
        "time": 1689814119000,
        "value": 139.538,
        "_internal_originalTime": 1689814119000
    },
    {
        "time": 1689814120000,
        "value": 139.536,
        "_internal_originalTime": 1689814120000
    },
    {
        "time": 1689814123000,
        "value": 139.541,
        "_internal_originalTime": 1689814123000
    },
    {
        "time": 1689814125000,
        "value": 139.542,
        "_internal_originalTime": 1689814125000
    },
    {
        "time": 1689814127000,
        "value": 139.537,
        "_internal_originalTime": 1689814127000
    },
    {
        "time": 1689814129000,
        "value": 139.542,
        "_internal_originalTime": 1689814129000
    },
    {
        "time": 1689814131000,
        "value": 139.541,
        "_internal_originalTime": 1689814131000
    },
    {
        "time": 1689814132000,
        "value": 139.542,
        "_internal_originalTime": 1689814132000
    },
    {
        "time": 1689814134000,
        "value": 139.536,
        "_internal_originalTime": 1689814134000
    },
    {
        "time": 1689814137000,
        "value": 139.542,
        "_internal_originalTime": 1689814137000
    },
    {
        "time": 1689814140000,
        "value": 139.539,
        "_internal_originalTime": 1689814140000
    },
    {
        "time": 1689814141000,
        "value": 139.539,
        "_internal_originalTime": 1689814141000
    },
    {
        "time": 1689814142000,
        "value": 139.542,
        "_internal_originalTime": 1689814142000
    },
    {
        "time": 1689814147000,
        "value": 139.542,
        "_internal_originalTime": 1689814147000
    },
    {
        "time": 1689814149000,
        "value": 139.536,
        "_internal_originalTime": 1689814149000
    },
    {
        "time": 1689814151000,
        "value": 139.539,
        "_internal_originalTime": 1689814151000
    },
    {
        "time": 1689814154000,
        "value": 139.539,
        "_internal_originalTime": 1689814154000
    },
    {
        "time": 1689814156000,
        "value": 139.539,
        "_internal_originalTime": 1689814156000
    },
    {
        "time": 1689814159000,
        "value": 139.539,
        "_internal_originalTime": 1689814159000
    },
    {
        "time": 1689814160000,
        "value": 139.537,
        "_internal_originalTime": 1689814160000
    },
    {
        "time": 1689814162000,
        "value": 139.541,
        "_internal_originalTime": 1689814162000
    },
    {
        "time": 1689814164000,
        "value": 139.541,
        "_internal_originalTime": 1689814164000
    },
    {
        "time": 1689814166000,
        "value": 139.541,
        "_internal_originalTime": 1689814166000
    },
    {
        "time": 1689814167000,
        "value": 139.536,
        "_internal_originalTime": 1689814167000
    },
    {
        "time": 1689814170000,
        "value": 139.536,
        "_internal_originalTime": 1689814170000
    },
    {
        "time": 1689814171000,
        "value": 139.541,
        "_internal_originalTime": 1689814171000
    },
    {
        "time": 1689814173000,
        "value": 139.542,
        "_internal_originalTime": 1689814173000
    },
    {
        "time": 1689814177000,
        "value": 139.536,
        "_internal_originalTime": 1689814177000
    },
    {
        "time": 1689814179000,
        "value": 139.541,
        "_internal_originalTime": 1689814179000
    },
    {
        "time": 1689814182000,
        "value": 139.546,
        "_internal_originalTime": 1689814182000
    },
    {
        "time": 1689814183000,
        "value": 139.55,
        "_internal_originalTime": 1689814183000
    },
    {
        "time": 1689814186000,
        "value": 139.546,
        "_internal_originalTime": 1689814186000
    },
    {
        "time": 1689814187000,
        "value": 139.546,
        "_internal_originalTime": 1689814187000
    },
    {
        "time": 1689814189000,
        "value": 139.545,
        "_internal_originalTime": 1689814189000
    },
    {
        "time": 1689814191000,
        "value": 139.548,
        "_internal_originalTime": 1689814191000
    },
    {
        "time": 1689814195000,
        "value": 139.556,
        "_internal_originalTime": 1689814195000
    },
    {
        "time": 1689814197000,
        "value": 139.556,
        "_internal_originalTime": 1689814197000
    },
    {
        "time": 1689814199000,
        "value": 139.555,
        "_internal_originalTime": 1689814199000
    },
    {
        "time": 1689814200000,
        "value": 139.557,
        "_internal_originalTime": 1689814200000
    },
    {
        "time": 1689814202000,
        "value": 139.558,
        "_internal_originalTime": 1689814202000
    },
    {
        "time": 1689814204000,
        "value": 139.557,
        "_internal_originalTime": 1689814204000
    },
    {
        "time": 1689814206000,
        "value": 139.555,
        "_internal_originalTime": 1689814206000
    },
    {
        "time": 1689814207000,
        "value": 139.551,
        "_internal_originalTime": 1689814207000
    },
    {
        "time": 1689814209000,
        "value": 139.541,
        "_internal_originalTime": 1689814209000
    },
    {
        "time": 1689814211000,
        "value": 139.54,
        "_internal_originalTime": 1689814211000
    },
    {
        "time": 1689814213000,
        "value": 139.532,
        "_internal_originalTime": 1689814213000
    },
    {
        "time": 1689814215000,
        "value": 139.532,
        "_internal_originalTime": 1689814215000
    },
    {
        "time": 1689814217000,
        "value": 139.521,
        "_internal_originalTime": 1689814217000
    },
    {
        "time": 1689814218000,
        "value": 139.521,
        "_internal_originalTime": 1689814218000
    },
    {
        "time": 1689814221000,
        "value": 139.521,
        "_internal_originalTime": 1689814221000
    },
    {
        "time": 1689814223000,
        "value": 139.532,
        "_internal_originalTime": 1689814223000
    },
    {
        "time": 1689814224000,
        "value": 139.537,
        "_internal_originalTime": 1689814224000
    },
    {
        "time": 1689814226000,
        "value": 139.535,
        "_internal_originalTime": 1689814226000
    },
    {
        "time": 1689814227000,
        "value": 139.534,
        "_internal_originalTime": 1689814227000
    },
    {
        "time": 1689814228000,
        "value": 139.534,
        "_internal_originalTime": 1689814228000
    },
    {
        "time": 1689814231000,
        "value": 139.535,
        "_internal_originalTime": 1689814231000
    },
    {
        "time": 1689814232000,
        "value": 139.531,
        "_internal_originalTime": 1689814232000
    },
    {
        "time": 1689814233000,
        "value": 139.536,
        "_internal_originalTime": 1689814233000
    },
    {
        "time": 1689814239000,
        "value": 139.53,
        "_internal_originalTime": 1689814239000
    },
    {
        "time": 1689814241000,
        "value": 139.536,
        "_internal_originalTime": 1689814241000
    },
    {
        "time": 1689814244000,
        "value": 139.53,
        "_internal_originalTime": 1689814244000
    },
    {
        "time": 1689814245000,
        "value": 139.536,
        "_internal_originalTime": 1689814245000
    },
    {
        "time": 1689814247000,
        "value": 139.53,
        "_internal_originalTime": 1689814247000
    },
    {
        "time": 1689814248000,
        "value": 139.536,
        "_internal_originalTime": 1689814248000
    },
    {
        "time": 1689814251000,
        "value": 139.53,
        "_internal_originalTime": 1689814251000
    },
    {
        "time": 1689814252000,
        "value": 139.53,
        "_internal_originalTime": 1689814252000
    },
    {
        "time": 1689814258000,
        "value": 139.521,
        "_internal_originalTime": 1689814258000
    },
    {
        "time": 1689814259000,
        "value": 139.524,
        "_internal_originalTime": 1689814259000
    },
    {
        "time": 1689814260000,
        "value": 139.518,
        "_internal_originalTime": 1689814260000
    },
    {
        "time": 1689814261000,
        "value": 139.522,
        "_internal_originalTime": 1689814261000
    },
    {
        "time": 1689814263000,
        "value": 139.532,
        "_internal_originalTime": 1689814263000
    },
    {
        "time": 1689814264000,
        "value": 139.529,
        "_internal_originalTime": 1689814264000
    },
    {
        "time": 1689814266000,
        "value": 139.528,
        "_internal_originalTime": 1689814266000
    },
    {
        "time": 1689814267000,
        "value": 139.534,
        "_internal_originalTime": 1689814267000
    },
    {
        "time": 1689814270000,
        "value": 139.531,
        "_internal_originalTime": 1689814270000
    },
    {
        "time": 1689814273000,
        "value": 139.546,
        "_internal_originalTime": 1689814273000
    },
    {
        "time": 1689814274000,
        "value": 139.545,
        "_internal_originalTime": 1689814274000
    },
    {
        "time": 1689814276000,
        "value": 139.566,
        "_internal_originalTime": 1689814276000
    },
    {
        "time": 1689814277000,
        "value": 139.573,
        "_internal_originalTime": 1689814277000
    },
    {
        "time": 1689814280000,
        "value": 139.584,
        "_internal_originalTime": 1689814280000
    },
    {
        "time": 1689814281000,
        "value": 139.584,
        "_internal_originalTime": 1689814281000
    },
    {
        "time": 1689814283000,
        "value": 139.565,
        "_internal_originalTime": 1689814283000
    },
    {
        "time": 1689814284000,
        "value": 139.563,
        "_internal_originalTime": 1689814284000
    },
    {
        "time": 1689814286000,
        "value": 139.562,
        "_internal_originalTime": 1689814286000
    },
    {
        "time": 1689814287000,
        "value": 139.561,
        "_internal_originalTime": 1689814287000
    },
    {
        "time": 1689814289000,
        "value": 139.558,
        "_internal_originalTime": 1689814289000
    },
    {
        "time": 1689814291000,
        "value": 139.561,
        "_internal_originalTime": 1689814291000
    },
    {
        "time": 1689814293000,
        "value": 139.56,
        "_internal_originalTime": 1689814293000
    },
    {
        "time": 1689814295000,
        "value": 139.555,
        "_internal_originalTime": 1689814295000
    },
    {
        "time": 1689814297000,
        "value": 139.558,
        "_internal_originalTime": 1689814297000
    },
    {
        "time": 1689814304000,
        "value": 139.564,
        "_internal_originalTime": 1689814304000
    },
    {
        "time": 1689814305000,
        "value": 139.565,
        "_internal_originalTime": 1689814305000
    },
    {
        "time": 1689814307000,
        "value": 139.564,
        "_internal_originalTime": 1689814307000
    },
    {
        "time": 1689814309000,
        "value": 139.571,
        "_internal_originalTime": 1689814309000
    },
    {
        "time": 1689814310000,
        "value": 139.568,
        "_internal_originalTime": 1689814310000
    },
    {
        "time": 1689814312000,
        "value": 139.568,
        "_internal_originalTime": 1689814312000
    },
    {
        "time": 1689814314000,
        "value": 139.564,
        "_internal_originalTime": 1689814314000
    },
    {
        "time": 1689814316000,
        "value": 139.564,
        "_internal_originalTime": 1689814316000
    },
    {
        "time": 1689814318000,
        "value": 139.566,
        "_internal_originalTime": 1689814318000
    },
    {
        "time": 1689814320000,
        "value": 139.573,
        "_internal_originalTime": 1689814320000
    },
    {
        "time": 1689814322000,
        "value": 139.578,
        "_internal_originalTime": 1689814322000
    },
    {
        "time": 1689814323000,
        "value": 139.586,
        "_internal_originalTime": 1689814323000
    },
    {
        "time": 1689814325000,
        "value": 139.587,
        "_internal_originalTime": 1689814325000
    },
    {
        "time": 1689814327000,
        "value": 139.587,
        "_internal_originalTime": 1689814327000
    },
    {
        "time": 1689814329000,
        "value": 139.585,
        "_internal_originalTime": 1689814329000
    },
    {
        "time": 1689814331000,
        "value": 139.595,
        "_internal_originalTime": 1689814331000
    },
    {
        "time": 1689814332000,
        "value": 139.59,
        "_internal_originalTime": 1689814332000
    },
    {
        "time": 1689814335000,
        "value": 139.596,
        "_internal_originalTime": 1689814335000
    },
    {
        "time": 1689814336000,
        "value": 139.596,
        "_internal_originalTime": 1689814336000
    },
    {
        "time": 1689814338000,
        "value": 139.594,
        "_internal_originalTime": 1689814338000
    },
    {
        "time": 1689814340000,
        "value": 139.594,
        "_internal_originalTime": 1689814340000
    },
    {
        "time": 1689814342000,
        "value": 139.6,
        "_internal_originalTime": 1689814342000
    },
    {
        "time": 1689814344000,
        "value": 139.595,
        "_internal_originalTime": 1689814344000
    },
    {
        "time": 1689814345000,
        "value": 139.61,
        "_internal_originalTime": 1689814345000
    },
    {
        "time": 1689814347000,
        "value": 139.603,
        "_internal_originalTime": 1689814347000
    },
    {
        "time": 1689814348000,
        "value": 139.605,
        "_internal_originalTime": 1689814348000
    },
    {
        "time": 1689814350000,
        "value": 139.595,
        "_internal_originalTime": 1689814350000
    },
    {
        "time": 1689814351000,
        "value": 139.598,
        "_internal_originalTime": 1689814351000
    },
    {
        "time": 1689814353000,
        "value": 139.576,
        "_internal_originalTime": 1689814353000
    },
    {
        "time": 1689814354000,
        "value": 139.575,
        "_internal_originalTime": 1689814354000
    },
    {
        "time": 1689814357000,
        "value": 139.584,
        "_internal_originalTime": 1689814357000
    },
    {
        "time": 1689814358000,
        "value": 139.584,
        "_internal_originalTime": 1689814358000
    },
    {
        "time": 1689814361000,
        "value": 139.579,
        "_internal_originalTime": 1689814361000
    },
    {
        "time": 1689814363000,
        "value": 139.568,
        "_internal_originalTime": 1689814363000
    },
    {
        "time": 1689814364000,
        "value": 139.569,
        "_internal_originalTime": 1689814364000
    },
    {
        "time": 1689814366000,
        "value": 139.56,
        "_internal_originalTime": 1689814366000
    },
    {
        "time": 1689814367000,
        "value": 139.561,
        "_internal_originalTime": 1689814367000
    },
    {
        "time": 1689814370000,
        "value": 139.554,
        "_internal_originalTime": 1689814370000
    },
    {
        "time": 1689814371000,
        "value": 139.567,
        "_internal_originalTime": 1689814371000
    },
    {
        "time": 1689814373000,
        "value": 139.575,
        "_internal_originalTime": 1689814373000
    },
    {
        "time": 1689814375000,
        "value": 139.566,
        "_internal_originalTime": 1689814375000
    },
    {
        "time": 1689814377000,
        "value": 139.572,
        "_internal_originalTime": 1689814377000
    },
    {
        "time": 1689814379000,
        "value": 139.569,
        "_internal_originalTime": 1689814379000
    },
    {
        "time": 1689814381000,
        "value": 139.573,
        "_internal_originalTime": 1689814381000
    },
    {
        "time": 1689814382000,
        "value": 139.549,
        "_internal_originalTime": 1689814382000
    },
    {
        "time": 1689814384000,
        "value": 139.549,
        "_internal_originalTime": 1689814384000
    },
    {
        "time": 1689814386000,
        "value": 139.549,
        "_internal_originalTime": 1689814386000
    },
    {
        "time": 1689814387000,
        "value": 139.556,
        "_internal_originalTime": 1689814387000
    },
    {
        "time": 1689814389000,
        "value": 139.558,
        "_internal_originalTime": 1689814389000
    },
    {
        "time": 1689814391000,
        "value": 139.557,
        "_internal_originalTime": 1689814391000
    },
    {
        "time": 1689814393000,
        "value": 139.552,
        "_internal_originalTime": 1689814393000
    },
    {
        "time": 1689814395000,
        "value": 139.553,
        "_internal_originalTime": 1689814395000
    },
    {
        "time": 1689814397000,
        "value": 139.551,
        "_internal_originalTime": 1689814397000
    },
    {
        "time": 1689814399000,
        "value": 139.552,
        "_internal_originalTime": 1689814399000
    },
    {
        "time": 1689814401000,
        "value": 139.552,
        "_internal_originalTime": 1689814401000
    },
    {
        "time": 1689814403000,
        "value": 139.548,
        "_internal_originalTime": 1689814403000
    },
    {
        "time": 1689814404000,
        "value": 139.552,
        "_internal_originalTime": 1689814404000
    },
    {
        "time": 1689814407000,
        "value": 139.543,
        "_internal_originalTime": 1689814407000
    },
    {
        "time": 1689814408000,
        "value": 139.546,
        "_internal_originalTime": 1689814408000
    },
    {
        "time": 1689814410000,
        "value": 139.552,
        "_internal_originalTime": 1689814410000
    },
    {
        "time": 1689814412000,
        "value": 139.548,
        "_internal_originalTime": 1689814412000
    },
    {
        "time": 1689814415000,
        "value": 139.546,
        "_internal_originalTime": 1689814415000
    },
    {
        "time": 1689814417000,
        "value": 139.542,
        "_internal_originalTime": 1689814417000
    },
    {
        "time": 1689814421000,
        "value": 139.545,
        "_internal_originalTime": 1689814421000
    },
    {
        "time": 1689814424000,
        "value": 139.538,
        "_internal_originalTime": 1689814424000
    },
    {
        "time": 1689814427000,
        "value": 139.543,
        "_internal_originalTime": 1689814427000
    },
    {
        "time": 1689814431000,
        "value": 139.554,
        "_internal_originalTime": 1689814431000
    },
    {
        "time": 1689814434000,
        "value": 139.559,
        "_internal_originalTime": 1689814434000
    },
    {
        "time": 1689814436000,
        "value": 139.568,
        "_internal_originalTime": 1689814436000
    },
    {
        "time": 1689814437000,
        "value": 139.568,
        "_internal_originalTime": 1689814437000
    },
    {
        "time": 1689814438000,
        "value": 139.574,
        "_internal_originalTime": 1689814438000
    },
    {
        "time": 1689814439000,
        "value": 139.579,
        "_internal_originalTime": 1689814439000
    },
    {
        "time": 1689814440000,
        "value": 139.574,
        "_internal_originalTime": 1689814440000
    },
    {
        "time": 1689814441000,
        "value": 139.582,
        "_internal_originalTime": 1689814441000
    },
    {
        "time": 1689814443000,
        "value": 139.58,
        "_internal_originalTime": 1689814443000
    },
    {
        "time": 1689814444000,
        "value": 139.603,
        "_internal_originalTime": 1689814444000
    },
    {
        "time": 1689814446000,
        "value": 139.594,
        "_internal_originalTime": 1689814446000
    },
    {
        "time": 1689814448000,
        "value": 139.587,
        "_internal_originalTime": 1689814448000
    },
    {
        "time": 1689814450000,
        "value": 139.584,
        "_internal_originalTime": 1689814450000
    },
    {
        "time": 1689814452000,
        "value": 139.574,
        "_internal_originalTime": 1689814452000
    },
    {
        "time": 1689814453000,
        "value": 139.569,
        "_internal_originalTime": 1689814453000
    },
    {
        "time": 1689814454000,
        "value": 139.57,
        "_internal_originalTime": 1689814454000
    },
    {
        "time": 1689814457000,
        "value": 139.572,
        "_internal_originalTime": 1689814457000
    },
    {
        "time": 1689814459000,
        "value": 139.577,
        "_internal_originalTime": 1689814459000
    },
    {
        "time": 1689814461000,
        "value": 139.582,
        "_internal_originalTime": 1689814461000
    },
    {
        "time": 1689814462000,
        "value": 139.582,
        "_internal_originalTime": 1689814462000
    },
    {
        "time": 1689814465000,
        "value": 139.584,
        "_internal_originalTime": 1689814465000
    },
    {
        "time": 1689814466000,
        "value": 139.581,
        "_internal_originalTime": 1689814466000
    },
    {
        "time": 1689814467000,
        "value": 139.581,
        "_internal_originalTime": 1689814467000
    },
    {
        "time": 1689814468000,
        "value": 139.579,
        "_internal_originalTime": 1689814468000
    },
    {
        "time": 1689814470000,
        "value": 139.584,
        "_internal_originalTime": 1689814470000
    },
    {
        "time": 1689814471000,
        "value": 139.583,
        "_internal_originalTime": 1689814471000
    },
    {
        "time": 1689814473000,
        "value": 139.581,
        "_internal_originalTime": 1689814473000
    },
    {
        "time": 1689814475000,
        "value": 139.586,
        "_internal_originalTime": 1689814475000
    },
    {
        "time": 1689814477000,
        "value": 139.578,
        "_internal_originalTime": 1689814477000
    },
    {
        "time": 1689814480000,
        "value": 139.584,
        "_internal_originalTime": 1689814480000
    },
    {
        "time": 1689814482000,
        "value": 139.58,
        "_internal_originalTime": 1689814482000
    },
    {
        "time": 1689814484000,
        "value": 139.585,
        "_internal_originalTime": 1689814484000
    },
    {
        "time": 1689814485000,
        "value": 139.584,
        "_internal_originalTime": 1689814485000
    },
    {
        "time": 1689814487000,
        "value": 139.593,
        "_internal_originalTime": 1689814487000
    },
    {
        "time": 1689814488000,
        "value": 139.593,
        "_internal_originalTime": 1689814488000
    },
    {
        "time": 1689814490000,
        "value": 139.593,
        "_internal_originalTime": 1689814490000
    },
    {
        "time": 1689814492000,
        "value": 139.585,
        "_internal_originalTime": 1689814492000
    },
    {
        "time": 1689814494000,
        "value": 139.579,
        "_internal_originalTime": 1689814494000
    },
    {
        "time": 1689814496000,
        "value": 139.588,
        "_internal_originalTime": 1689814496000
    },
    {
        "time": 1689814497000,
        "value": 139.577,
        "_internal_originalTime": 1689814497000
    },
    {
        "time": 1689814499000,
        "value": 139.58,
        "_internal_originalTime": 1689814499000
    },
    {
        "time": 1689814501000,
        "value": 139.576,
        "_internal_originalTime": 1689814501000
    },
    {
        "time": 1689814502000,
        "value": 139.585,
        "_internal_originalTime": 1689814502000
    },
    {
        "time": 1689814504000,
        "value": 139.582,
        "_internal_originalTime": 1689814504000
    },
    {
        "time": 1689814505000,
        "value": 139.575,
        "_internal_originalTime": 1689814505000
    },
    {
        "time": 1689814507000,
        "value": 139.567,
        "_internal_originalTime": 1689814507000
    },
    {
        "time": 1689814509000,
        "value": 139.562,
        "_internal_originalTime": 1689814509000
    },
    {
        "time": 1689814510000,
        "value": 139.553,
        "_internal_originalTime": 1689814510000
    },
    {
        "time": 1689814512000,
        "value": 139.552,
        "_internal_originalTime": 1689814512000
    },
    {
        "time": 1689814514000,
        "value": 139.551,
        "_internal_originalTime": 1689814514000
    },
    {
        "time": 1689814515000,
        "value": 139.551,
        "_internal_originalTime": 1689814515000
    },
    {
        "time": 1689814518000,
        "value": 139.555,
        "_internal_originalTime": 1689814518000
    },
    {
        "time": 1689814519000,
        "value": 139.532,
        "_internal_originalTime": 1689814519000
    },
    {
        "time": 1689814521000,
        "value": 139.534,
        "_internal_originalTime": 1689814521000
    },
    {
        "time": 1689814523000,
        "value": 139.536,
        "_internal_originalTime": 1689814523000
    },
    {
        "time": 1689814525000,
        "value": 139.533,
        "_internal_originalTime": 1689814525000
    },
    {
        "time": 1689814527000,
        "value": 139.523,
        "_internal_originalTime": 1689814527000
    },
    {
        "time": 1689814529000,
        "value": 139.532,
        "_internal_originalTime": 1689814529000
    },
    {
        "time": 1689814530000,
        "value": 139.523,
        "_internal_originalTime": 1689814530000
    },
    {
        "time": 1689814532000,
        "value": 139.518,
        "_internal_originalTime": 1689814532000
    },
    {
        "time": 1689814533000,
        "value": 139.517,
        "_internal_originalTime": 1689814533000
    },
    {
        "time": 1689814534000,
        "value": 139.506,
        "_internal_originalTime": 1689814534000
    },
    {
        "time": 1689814536000,
        "value": 139.506,
        "_internal_originalTime": 1689814536000
    },
    {
        "time": 1689814538000,
        "value": 139.508,
        "_internal_originalTime": 1689814538000
    },
    {
        "time": 1689814540000,
        "value": 139.507,
        "_internal_originalTime": 1689814540000
    },
    {
        "time": 1689814542000,
        "value": 139.502,
        "_internal_originalTime": 1689814542000
    },
    {
        "time": 1689814543000,
        "value": 139.503,
        "_internal_originalTime": 1689814543000
    },
    {
        "time": 1689814545000,
        "value": 139.511,
        "_internal_originalTime": 1689814545000
    },
    {
        "time": 1689814546000,
        "value": 139.501,
        "_internal_originalTime": 1689814546000
    },
    {
        "time": 1689814548000,
        "value": 139.499,
        "_internal_originalTime": 1689814548000
    },
    {
        "time": 1689814550000,
        "value": 139.486,
        "_internal_originalTime": 1689814550000
    },
    {
        "time": 1689814551000,
        "value": 139.476,
        "_internal_originalTime": 1689814551000
    },
    {
        "time": 1689814553000,
        "value": 139.476,
        "_internal_originalTime": 1689814553000
    },
    {
        "time": 1689814555000,
        "value": 139.474,
        "_internal_originalTime": 1689814555000
    },
    {
        "time": 1689814557000,
        "value": 139.471,
        "_internal_originalTime": 1689814557000
    },
    {
        "time": 1689814559000,
        "value": 139.492,
        "_internal_originalTime": 1689814559000
    },
    {
        "time": 1689814560000,
        "value": 139.49,
        "_internal_originalTime": 1689814560000
    },
    {
        "time": 1689814562000,
        "value": 139.496,
        "_internal_originalTime": 1689814562000
    },
    {
        "time": 1689814564000,
        "value": 139.496,
        "_internal_originalTime": 1689814564000
    },
    {
        "time": 1689814566000,
        "value": 139.498,
        "_internal_originalTime": 1689814566000
    },
    {
        "time": 1689814568000,
        "value": 139.5,
        "_internal_originalTime": 1689814568000
    },
    {
        "time": 1689814570000,
        "value": 139.496,
        "_internal_originalTime": 1689814570000
    },
    {
        "time": 1689814571000,
        "value": 139.492,
        "_internal_originalTime": 1689814571000
    },
    {
        "time": 1689814572000,
        "value": 139.492,
        "_internal_originalTime": 1689814572000
    },
    {
        "time": 1689814577000,
        "value": 139.494,
        "_internal_originalTime": 1689814577000
    },
    {
        "time": 1689814579000,
        "value": 139.494,
        "_internal_originalTime": 1689814579000
    },
    {
        "time": 1689814581000,
        "value": 139.492,
        "_internal_originalTime": 1689814581000
    },
    {
        "time": 1689814582000,
        "value": 139.498,
        "_internal_originalTime": 1689814582000
    },
    {
        "time": 1689814585000,
        "value": 139.493,
        "_internal_originalTime": 1689814585000
    },
    {
        "time": 1689814586000,
        "value": 139.493,
        "_internal_originalTime": 1689814586000
    },
    {
        "time": 1689814587000,
        "value": 139.488,
        "_internal_originalTime": 1689814587000
    },
    {
        "time": 1689814589000,
        "value": 139.487,
        "_internal_originalTime": 1689814589000
    },
    {
        "time": 1689814590000,
        "value": 139.488,
        "_internal_originalTime": 1689814590000
    },
    {
        "time": 1689814592000,
        "value": 139.47,
        "_internal_originalTime": 1689814592000
    },
    {
        "time": 1689814594000,
        "value": 139.456,
        "_internal_originalTime": 1689814594000
    },
    {
        "time": 1689814596000,
        "value": 139.451,
        "_internal_originalTime": 1689814596000
    },
    {
        "time": 1689814598000,
        "value": 139.459,
        "_internal_originalTime": 1689814598000
    },
    {
        "time": 1689814600000,
        "value": 139.454,
        "_internal_originalTime": 1689814600000
    },
    {
        "time": 1689814602000,
        "value": 139.465,
        "_internal_originalTime": 1689814602000
    },
    {
        "time": 1689814604000,
        "value": 139.472,
        "_internal_originalTime": 1689814604000
    },
    {
        "time": 1689814607000,
        "value": 139.468,
        "_internal_originalTime": 1689814607000
    },
    {
        "time": 1689814608000,
        "value": 139.474,
        "_internal_originalTime": 1689814608000
    },
    {
        "time": 1689814610000,
        "value": 139.481,
        "_internal_originalTime": 1689814610000
    },
    {
        "time": 1689814612000,
        "value": 139.472,
        "_internal_originalTime": 1689814612000
    },
    {
        "time": 1689814614000,
        "value": 139.473,
        "_internal_originalTime": 1689814614000
    },
    {
        "time": 1689814615000,
        "value": 139.463,
        "_internal_originalTime": 1689814615000
    },
    {
        "time": 1689814617000,
        "value": 139.46,
        "_internal_originalTime": 1689814617000
    },
    {
        "time": 1689814619000,
        "value": 139.467,
        "_internal_originalTime": 1689814619000
    },
    {
        "time": 1689814621000,
        "value": 139.464,
        "_internal_originalTime": 1689814621000
    },
    {
        "time": 1689814623000,
        "value": 139.466,
        "_internal_originalTime": 1689814623000
    },
    {
        "time": 1689814624000,
        "value": 139.466,
        "_internal_originalTime": 1689814624000
    },
    {
        "time": 1689814626000,
        "value": 139.471,
        "_internal_originalTime": 1689814626000
    },
    {
        "time": 1689814628000,
        "value": 139.464,
        "_internal_originalTime": 1689814628000
    },
    {
        "time": 1689814630000,
        "value": 139.47,
        "_internal_originalTime": 1689814630000
    },
    {
        "time": 1689814632000,
        "value": 139.472,
        "_internal_originalTime": 1689814632000
    },
    {
        "time": 1689814633000,
        "value": 139.473,
        "_internal_originalTime": 1689814633000
    },
    {
        "time": 1689814635000,
        "value": 139.472,
        "_internal_originalTime": 1689814635000
    },
    {
        "time": 1689814637000,
        "value": 139.466,
        "_internal_originalTime": 1689814637000
    },
    {
        "time": 1689814639000,
        "value": 139.469,
        "_internal_originalTime": 1689814639000
    },
    {
        "time": 1689814641000,
        "value": 139.47,
        "_internal_originalTime": 1689814641000
    },
    {
        "time": 1689814643000,
        "value": 139.464,
        "_internal_originalTime": 1689814643000
    },
    {
        "time": 1689814645000,
        "value": 139.477,
        "_internal_originalTime": 1689814645000
    },
    {
        "time": 1689814647000,
        "value": 139.468,
        "_internal_originalTime": 1689814647000
    },
    {
        "time": 1689814648000,
        "value": 139.47,
        "_internal_originalTime": 1689814648000
    },
    {
        "time": 1689814649000,
        "value": 139.466,
        "_internal_originalTime": 1689814649000
    },
    {
        "time": 1689814650000,
        "value": 139.466,
        "_internal_originalTime": 1689814650000
    },
    {
        "time": 1689814652000,
        "value": 139.466,
        "_internal_originalTime": 1689814652000
    },
    {
        "time": 1689814654000,
        "value": 139.464,
        "_internal_originalTime": 1689814654000
    },
    {
        "time": 1689814655000,
        "value": 139.462,
        "_internal_originalTime": 1689814655000
    },
    {
        "time": 1689814658000,
        "value": 139.462,
        "_internal_originalTime": 1689814658000
    },
    {
        "time": 1689814660000,
        "value": 139.464,
        "_internal_originalTime": 1689814660000
    },
    {
        "time": 1689814662000,
        "value": 139.462,
        "_internal_originalTime": 1689814662000
    },
    {
        "time": 1689814664000,
        "value": 139.468,
        "_internal_originalTime": 1689814664000
    },
    {
        "time": 1689814665000,
        "value": 139.472,
        "_internal_originalTime": 1689814665000
    },
    {
        "time": 1689814667000,
        "value": 139.471,
        "_internal_originalTime": 1689814667000
    },
    {
        "time": 1689814669000,
        "value": 139.462,
        "_internal_originalTime": 1689814669000
    },
    {
        "time": 1689814671000,
        "value": 139.463,
        "_internal_originalTime": 1689814671000
    },
    {
        "time": 1689814672000,
        "value": 139.461,
        "_internal_originalTime": 1689814672000
    },
    {
        "time": 1689814674000,
        "value": 139.464,
        "_internal_originalTime": 1689814674000
    },
    {
        "time": 1689814676000,
        "value": 139.46,
        "_internal_originalTime": 1689814676000
    },
    {
        "time": 1689814680000,
        "value": 139.453,
        "_internal_originalTime": 1689814680000
    },
    {
        "time": 1689814682000,
        "value": 139.43,
        "_internal_originalTime": 1689814682000
    },
    {
        "time": 1689814683000,
        "value": 139.437,
        "_internal_originalTime": 1689814683000
    },
    {
        "time": 1689814684000,
        "value": 139.432,
        "_internal_originalTime": 1689814684000
    },
    {
        "time": 1689814686000,
        "value": 139.438,
        "_internal_originalTime": 1689814686000
    },
    {
        "time": 1689814688000,
        "value": 139.428,
        "_internal_originalTime": 1689814688000
    },
    {
        "time": 1689814690000,
        "value": 139.426,
        "_internal_originalTime": 1689814690000
    },
    {
        "time": 1689814691000,
        "value": 139.42,
        "_internal_originalTime": 1689814691000
    },
    {
        "time": 1689814694000,
        "value": 139.405,
        "_internal_originalTime": 1689814694000
    },
    {
        "time": 1689814696000,
        "value": 139.41,
        "_internal_originalTime": 1689814696000
    },
    {
        "time": 1689814698000,
        "value": 139.414,
        "_internal_originalTime": 1689814698000
    },
    {
        "time": 1689814700000,
        "value": 139.435,
        "_internal_originalTime": 1689814700000
    },
    {
        "time": 1689814702000,
        "value": 139.437,
        "_internal_originalTime": 1689814702000
    },
    {
        "time": 1689814704000,
        "value": 139.443,
        "_internal_originalTime": 1689814704000
    },
    {
        "time": 1689814706000,
        "value": 139.441,
        "_internal_originalTime": 1689814706000
    },
    {
        "time": 1689814707000,
        "value": 139.439,
        "_internal_originalTime": 1689814707000
    },
    {
        "time": 1689814709000,
        "value": 139.439,
        "_internal_originalTime": 1689814709000
    },
    {
        "time": 1689814710000,
        "value": 139.436,
        "_internal_originalTime": 1689814710000
    },
    {
        "time": 1689814712000,
        "value": 139.442,
        "_internal_originalTime": 1689814712000
    },
    {
        "time": 1689814714000,
        "value": 139.437,
        "_internal_originalTime": 1689814714000
    },
    {
        "time": 1689814716000,
        "value": 139.438,
        "_internal_originalTime": 1689814716000
    },
    {
        "time": 1689814718000,
        "value": 139.442,
        "_internal_originalTime": 1689814718000
    },
    {
        "time": 1689814720000,
        "value": 139.444,
        "_internal_originalTime": 1689814720000
    },
    {
        "time": 1689814722000,
        "value": 139.442,
        "_internal_originalTime": 1689814722000
    },
    {
        "time": 1689814724000,
        "value": 139.442,
        "_internal_originalTime": 1689814724000
    },
    {
        "time": 1689814726000,
        "value": 139.442,
        "_internal_originalTime": 1689814726000
    },
    {
        "time": 1689814728000,
        "value": 139.447,
        "_internal_originalTime": 1689814728000
    },
    {
        "time": 1689814730000,
        "value": 139.444,
        "_internal_originalTime": 1689814730000
    },
    {
        "time": 1689814731000,
        "value": 139.437,
        "_internal_originalTime": 1689814731000
    },
    {
        "time": 1689814733000,
        "value": 139.435,
        "_internal_originalTime": 1689814733000
    },
    {
        "time": 1689814735000,
        "value": 139.436,
        "_internal_originalTime": 1689814735000
    },
    {
        "time": 1689814738000,
        "value": 139.441,
        "_internal_originalTime": 1689814738000
    },
    {
        "time": 1689814739000,
        "value": 139.441,
        "_internal_originalTime": 1689814739000
    },
    {
        "time": 1689814741000,
        "value": 139.442,
        "_internal_originalTime": 1689814741000
    },
    {
        "time": 1689814745000,
        "value": 139.441,
        "_internal_originalTime": 1689814745000
    },
    {
        "time": 1689814747000,
        "value": 139.436,
        "_internal_originalTime": 1689814747000
    },
    {
        "time": 1689814749000,
        "value": 139.442,
        "_internal_originalTime": 1689814749000
    },
    {
        "time": 1689814751000,
        "value": 139.442,
        "_internal_originalTime": 1689814751000
    },
    {
        "time": 1689814753000,
        "value": 139.445,
        "_internal_originalTime": 1689814753000
    },
    {
        "time": 1689814754000,
        "value": 139.443,
        "_internal_originalTime": 1689814754000
    },
    {
        "time": 1689814756000,
        "value": 139.445,
        "_internal_originalTime": 1689814756000
    },
    {
        "time": 1689814758000,
        "value": 139.44,
        "_internal_originalTime": 1689814758000
    },
    {
        "time": 1689814759000,
        "value": 139.444,
        "_internal_originalTime": 1689814759000
    },
    {
        "time": 1689814762000,
        "value": 139.446,
        "_internal_originalTime": 1689814762000
    },
    {
        "time": 1689814763000,
        "value": 139.445,
        "_internal_originalTime": 1689814763000
    },
    {
        "time": 1689814765000,
        "value": 139.423,
        "_internal_originalTime": 1689814765000
    },
    {
        "time": 1689814766000,
        "value": 139.422,
        "_internal_originalTime": 1689814766000
    },
    {
        "time": 1689814767000,
        "value": 139.425,
        "_internal_originalTime": 1689814767000
    },
    {
        "time": 1689814768000,
        "value": 139.426,
        "_internal_originalTime": 1689814768000
    },
    {
        "time": 1689814769000,
        "value": 139.429,
        "_internal_originalTime": 1689814769000
    },
    {
        "time": 1689814770000,
        "value": 139.424,
        "_internal_originalTime": 1689814770000
    },
    {
        "time": 1689814771000,
        "value": 139.414,
        "_internal_originalTime": 1689814771000
    },
    {
        "time": 1689814772000,
        "value": 139.414,
        "_internal_originalTime": 1689814772000
    },
    {
        "time": 1689814774000,
        "value": 139.413,
        "_internal_originalTime": 1689814774000
    },
    {
        "time": 1689814777000,
        "value": 139.414,
        "_internal_originalTime": 1689814777000
    },
    {
        "time": 1689814779000,
        "value": 139.421,
        "_internal_originalTime": 1689814779000
    },
    {
        "time": 1689814781000,
        "value": 139.42,
        "_internal_originalTime": 1689814781000
    },
    {
        "time": 1689814783000,
        "value": 139.424,
        "_internal_originalTime": 1689814783000
    },
    {
        "time": 1689814784000,
        "value": 139.424,
        "_internal_originalTime": 1689814784000
    },
    {
        "time": 1689814788000,
        "value": 139.441,
        "_internal_originalTime": 1689814788000
    },
    {
        "time": 1689814789000,
        "value": 139.442,
        "_internal_originalTime": 1689814789000
    },
    {
        "time": 1689814791000,
        "value": 139.436,
        "_internal_originalTime": 1689814791000
    },
    {
        "time": 1689814794000,
        "value": 139.438,
        "_internal_originalTime": 1689814794000
    },
    {
        "time": 1689814795000,
        "value": 139.445,
        "_internal_originalTime": 1689814795000
    },
    {
        "time": 1689814798000,
        "value": 139.445,
        "_internal_originalTime": 1689814798000
    },
    {
        "time": 1689814799000,
        "value": 139.443,
        "_internal_originalTime": 1689814799000
    },
    {
        "time": 1689814801000,
        "value": 139.425,
        "_internal_originalTime": 1689814801000
    },
    {
        "time": 1689814803000,
        "value": 139.418,
        "_internal_originalTime": 1689814803000
    },
    {
        "time": 1689814804000,
        "value": 139.414,
        "_internal_originalTime": 1689814804000
    },
    {
        "time": 1689814806000,
        "value": 139.413,
        "_internal_originalTime": 1689814806000
    },
    {
        "time": 1689814807000,
        "value": 139.402,
        "_internal_originalTime": 1689814807000
    },
    {
        "time": 1689814809000,
        "value": 139.407,
        "_internal_originalTime": 1689814809000
    },
    {
        "time": 1689814811000,
        "value": 139.398,
        "_internal_originalTime": 1689814811000
    },
    {
        "time": 1689814813000,
        "value": 139.399,
        "_internal_originalTime": 1689814813000
    },
    {
        "time": 1689814815000,
        "value": 139.394,
        "_internal_originalTime": 1689814815000
    },
    {
        "time": 1689814816000,
        "value": 139.398,
        "_internal_originalTime": 1689814816000
    },
    {
        "time": 1689814818000,
        "value": 139.409,
        "_internal_originalTime": 1689814818000
    },
    {
        "time": 1689814820000,
        "value": 139.408,
        "_internal_originalTime": 1689814820000
    },
    {
        "time": 1689814822000,
        "value": 139.407,
        "_internal_originalTime": 1689814822000
    },
    {
        "time": 1689814824000,
        "value": 139.409,
        "_internal_originalTime": 1689814824000
    },
    {
        "time": 1689814826000,
        "value": 139.407,
        "_internal_originalTime": 1689814826000
    },
    {
        "time": 1689814827000,
        "value": 139.418,
        "_internal_originalTime": 1689814827000
    },
    {
        "time": 1689814829000,
        "value": 139.42,
        "_internal_originalTime": 1689814829000
    },
    {
        "time": 1689814830000,
        "value": 139.424,
        "_internal_originalTime": 1689814830000
    },
    {
        "time": 1689814831000,
        "value": 139.425,
        "_internal_originalTime": 1689814831000
    },
    {
        "time": 1689814834000,
        "value": 139.414,
        "_internal_originalTime": 1689814834000
    },
    {
        "time": 1689814836000,
        "value": 139.412,
        "_internal_originalTime": 1689814836000
    },
    {
        "time": 1689814838000,
        "value": 139.402,
        "_internal_originalTime": 1689814838000
    },
    {
        "time": 1689814840000,
        "value": 139.4,
        "_internal_originalTime": 1689814840000
    },
    {
        "time": 1689814842000,
        "value": 139.398,
        "_internal_originalTime": 1689814842000
    },
    {
        "time": 1689814844000,
        "value": 139.4,
        "_internal_originalTime": 1689814844000
    },
    {
        "time": 1689814845000,
        "value": 139.395,
        "_internal_originalTime": 1689814845000
    },
    {
        "time": 1689814846000,
        "value": 139.394,
        "_internal_originalTime": 1689814846000
    },
    {
        "time": 1689814849000,
        "value": 139.395,
        "_internal_originalTime": 1689814849000
    },
    {
        "time": 1689814850000,
        "value": 139.398,
        "_internal_originalTime": 1689814850000
    },
    {
        "time": 1689814852000,
        "value": 139.395,
        "_internal_originalTime": 1689814852000
    },
    {
        "time": 1689814854000,
        "value": 139.395,
        "_internal_originalTime": 1689814854000
    },
    {
        "time": 1689814857000,
        "value": 139.394,
        "_internal_originalTime": 1689814857000
    },
    {
        "time": 1689814858000,
        "value": 139.407,
        "_internal_originalTime": 1689814858000
    },
    {
        "time": 1689814860000,
        "value": 139.404,
        "_internal_originalTime": 1689814860000
    },
    {
        "time": 1689814862000,
        "value": 139.404,
        "_internal_originalTime": 1689814862000
    },
    {
        "time": 1689814863000,
        "value": 139.404,
        "_internal_originalTime": 1689814863000
    },
    {
        "time": 1689814866000,
        "value": 139.405,
        "_internal_originalTime": 1689814866000
    },
    {
        "time": 1689814867000,
        "value": 139.398,
        "_internal_originalTime": 1689814867000
    },
    {
        "time": 1689814869000,
        "value": 139.398,
        "_internal_originalTime": 1689814869000
    },
    {
        "time": 1689814871000,
        "value": 139.392,
        "_internal_originalTime": 1689814871000
    },
    {
        "time": 1689814873000,
        "value": 139.39,
        "_internal_originalTime": 1689814873000
    },
    {
        "time": 1689814875000,
        "value": 139.395,
        "_internal_originalTime": 1689814875000
    },
    {
        "time": 1689814876000,
        "value": 139.405,
        "_internal_originalTime": 1689814876000
    },
    {
        "time": 1689814878000,
        "value": 139.399,
        "_internal_originalTime": 1689814878000
    },
    {
        "time": 1689814880000,
        "value": 139.406,
        "_internal_originalTime": 1689814880000
    },
    {
        "time": 1689814881000,
        "value": 139.403,
        "_internal_originalTime": 1689814881000
    },
    {
        "time": 1689814884000,
        "value": 139.406,
        "_internal_originalTime": 1689814884000
    },
    {
        "time": 1689814885000,
        "value": 139.404,
        "_internal_originalTime": 1689814885000
    },
    {
        "time": 1689814887000,
        "value": 139.405,
        "_internal_originalTime": 1689814887000
    },
    {
        "time": 1689814888000,
        "value": 139.406,
        "_internal_originalTime": 1689814888000
    },
    {
        "time": 1689814889000,
        "value": 139.403,
        "_internal_originalTime": 1689814889000
    },
    {
        "time": 1689814891000,
        "value": 139.406,
        "_internal_originalTime": 1689814891000
    },
    {
        "time": 1689814894000,
        "value": 139.4,
        "_internal_originalTime": 1689814894000
    },
    {
        "time": 1689814896000,
        "value": 139.402,
        "_internal_originalTime": 1689814896000
    },
    {
        "time": 1689814898000,
        "value": 139.414,
        "_internal_originalTime": 1689814898000
    },
    {
        "time": 1689814899000,
        "value": 139.414,
        "_internal_originalTime": 1689814899000
    },
    {
        "time": 1689814901000,
        "value": 139.41,
        "_internal_originalTime": 1689814901000
    },
    {
        "time": 1689814902000,
        "value": 139.416,
        "_internal_originalTime": 1689814902000
    },
    {
        "time": 1689814904000,
        "value": 139.412,
        "_internal_originalTime": 1689814904000
    },
    {
        "time": 1689814905000,
        "value": 139.409,
        "_internal_originalTime": 1689814905000
    },
    {
        "time": 1689814907000,
        "value": 139.407,
        "_internal_originalTime": 1689814907000
    },
    {
        "time": 1689814908000,
        "value": 139.407,
        "_internal_originalTime": 1689814908000
    },
    {
        "time": 1689814909000,
        "value": 139.413,
        "_internal_originalTime": 1689814909000
    },
    {
        "time": 1689814910000,
        "value": 139.42,
        "_internal_originalTime": 1689814910000
    },
    {
        "time": 1689814911000,
        "value": 139.423,
        "_internal_originalTime": 1689814911000
    },
    {
        "time": 1689814912000,
        "value": 139.423,
        "_internal_originalTime": 1689814912000
    },
    {
        "time": 1689814913000,
        "value": 139.425,
        "_internal_originalTime": 1689814913000
    },
    {
        "time": 1689814914000,
        "value": 139.428,
        "_internal_originalTime": 1689814914000
    },
    {
        "time": 1689814916000,
        "value": 139.432,
        "_internal_originalTime": 1689814916000
    },
    {
        "time": 1689814918000,
        "value": 139.428,
        "_internal_originalTime": 1689814918000
    },
    {
        "time": 1689814920000,
        "value": 139.422,
        "_internal_originalTime": 1689814920000
    },
    {
        "time": 1689814921000,
        "value": 139.42,
        "_internal_originalTime": 1689814921000
    },
    {
        "time": 1689814923000,
        "value": 139.418,
        "_internal_originalTime": 1689814923000
    },
    {
        "time": 1689814925000,
        "value": 139.423,
        "_internal_originalTime": 1689814925000
    },
    {
        "time": 1689814926000,
        "value": 139.405,
        "_internal_originalTime": 1689814926000
    },
    {
        "time": 1689814928000,
        "value": 139.402,
        "_internal_originalTime": 1689814928000
    },
    {
        "time": 1689814930000,
        "value": 139.41,
        "_internal_originalTime": 1689814930000
    },
    {
        "time": 1689814931000,
        "value": 139.414,
        "_internal_originalTime": 1689814931000
    },
    {
        "time": 1689814934000,
        "value": 139.424,
        "_internal_originalTime": 1689814934000
    },
    {
        "time": 1689814935000,
        "value": 139.422,
        "_internal_originalTime": 1689814935000
    },
    {
        "time": 1689814937000,
        "value": 139.423,
        "_internal_originalTime": 1689814937000
    },
    {
        "time": 1689814939000,
        "value": 139.424,
        "_internal_originalTime": 1689814939000
    },
    {
        "time": 1689814941000,
        "value": 139.422,
        "_internal_originalTime": 1689814941000
    },
    {
        "time": 1689814943000,
        "value": 139.425,
        "_internal_originalTime": 1689814943000
    },
    {
        "time": 1689814945000,
        "value": 139.419,
        "_internal_originalTime": 1689814945000
    },
    {
        "time": 1689814946000,
        "value": 139.414,
        "_internal_originalTime": 1689814946000
    },
    {
        "time": 1689814947000,
        "value": 139.407,
        "_internal_originalTime": 1689814947000
    },
    {
        "time": 1689814948000,
        "value": 139.414,
        "_internal_originalTime": 1689814948000
    },
    {
        "time": 1689814949000,
        "value": 139.41,
        "_internal_originalTime": 1689814949000
    },
    {
        "time": 1689814952000,
        "value": 139.415,
        "_internal_originalTime": 1689814952000
    },
    {
        "time": 1689814954000,
        "value": 139.4,
        "_internal_originalTime": 1689814954000
    },
    {
        "time": 1689814956000,
        "value": 139.405,
        "_internal_originalTime": 1689814956000
    },
    {
        "time": 1689814957000,
        "value": 139.4,
        "_internal_originalTime": 1689814957000
    },
    {
        "time": 1689814959000,
        "value": 139.392,
        "_internal_originalTime": 1689814959000
    },
    {
        "time": 1689814961000,
        "value": 139.392,
        "_internal_originalTime": 1689814961000
    },
    {
        "time": 1689814963000,
        "value": 139.395,
        "_internal_originalTime": 1689814963000
    },
    {
        "time": 1689814964000,
        "value": 139.395,
        "_internal_originalTime": 1689814964000
    },
    {
        "time": 1689814965000,
        "value": 139.391,
        "_internal_originalTime": 1689814965000
    },
    {
        "time": 1689814968000,
        "value": 139.4,
        "_internal_originalTime": 1689814968000
    },
    {
        "time": 1689814970000,
        "value": 139.397,
        "_internal_originalTime": 1689814970000
    },
    {
        "time": 1689814971000,
        "value": 139.396,
        "_internal_originalTime": 1689814971000
    },
    {
        "time": 1689814973000,
        "value": 139.392,
        "_internal_originalTime": 1689814973000
    },
    {
        "time": 1689814974000,
        "value": 139.39,
        "_internal_originalTime": 1689814974000
    },
    {
        "time": 1689814977000,
        "value": 139.392,
        "_internal_originalTime": 1689814977000
    },
    {
        "time": 1689814979000,
        "value": 139.392,
        "_internal_originalTime": 1689814979000
    },
    {
        "time": 1689814980000,
        "value": 139.39,
        "_internal_originalTime": 1689814980000
    },
    {
        "time": 1689814982000,
        "value": 139.395,
        "_internal_originalTime": 1689814982000
    },
    {
        "time": 1689814984000,
        "value": 139.408,
        "_internal_originalTime": 1689814984000
    },
    {
        "time": 1689814986000,
        "value": 139.404,
        "_internal_originalTime": 1689814986000
    },
    {
        "time": 1689814987000,
        "value": 139.404,
        "_internal_originalTime": 1689814987000
    },
    {
        "time": 1689814989000,
        "value": 139.406,
        "_internal_originalTime": 1689814989000
    },
    {
        "time": 1689814991000,
        "value": 139.416,
        "_internal_originalTime": 1689814991000
    },
    {
        "time": 1689814993000,
        "value": 139.412,
        "_internal_originalTime": 1689814993000
    },
    {
        "time": 1689814995000,
        "value": 139.412,
        "_internal_originalTime": 1689814995000
    },
    {
        "time": 1689814996000,
        "value": 139.412,
        "_internal_originalTime": 1689814996000
    },
    {
        "time": 1689814998000,
        "value": 139.414,
        "_internal_originalTime": 1689814998000
    },
    {
        "time": 1689815001000,
        "value": 139.414,
        "_internal_originalTime": 1689815001000
    },
    {
        "time": 1689815002000,
        "value": 139.41,
        "_internal_originalTime": 1689815002000
    },
    {
        "time": 1689815003000,
        "value": 139.408,
        "_internal_originalTime": 1689815003000
    },
    {
        "time": 1689815007000,
        "value": 139.409,
        "_internal_originalTime": 1689815007000
    },
    {
        "time": 1689815008000,
        "value": 139.415,
        "_internal_originalTime": 1689815008000
    },
    {
        "time": 1689815010000,
        "value": 139.413,
        "_internal_originalTime": 1689815010000
    },
    {
        "time": 1689815011000,
        "value": 139.411,
        "_internal_originalTime": 1689815011000
    },
    {
        "time": 1689815012000,
        "value": 139.411,
        "_internal_originalTime": 1689815012000
    },
    {
        "time": 1689815022000,
        "value": 139.408,
        "_internal_originalTime": 1689815022000
    },
    {
        "time": 1689815024000,
        "value": 139.41,
        "_internal_originalTime": 1689815024000
    },
    {
        "time": 1689815025000,
        "value": 139.407,
        "_internal_originalTime": 1689815025000
    },
    {
        "time": 1689815027000,
        "value": 139.407,
        "_internal_originalTime": 1689815027000
    },
    {
        "time": 1689815028000,
        "value": 139.411,
        "_internal_originalTime": 1689815028000
    },
    {
        "time": 1689815031000,
        "value": 139.376,
        "_internal_originalTime": 1689815031000
    },
    {
        "time": 1689815032000,
        "value": 139.378,
        "_internal_originalTime": 1689815032000
    },
    {
        "time": 1689815035000,
        "value": 139.374,
        "_internal_originalTime": 1689815035000
    },
    {
        "time": 1689815037000,
        "value": 139.38,
        "_internal_originalTime": 1689815037000
    },
    {
        "time": 1689815038000,
        "value": 139.38,
        "_internal_originalTime": 1689815038000
    },
    {
        "time": 1689815039000,
        "value": 139.379,
        "_internal_originalTime": 1689815039000
    },
    {
        "time": 1689815040000,
        "value": 139.381,
        "_internal_originalTime": 1689815040000
    },
    {
        "time": 1689815041000,
        "value": 139.384,
        "_internal_originalTime": 1689815041000
    },
    {
        "time": 1689815042000,
        "value": 139.382,
        "_internal_originalTime": 1689815042000
    },
    {
        "time": 1689815043000,
        "value": 139.386,
        "_internal_originalTime": 1689815043000
    },
    {
        "time": 1689815044000,
        "value": 139.383,
        "_internal_originalTime": 1689815044000
    },
    {
        "time": 1689815048000,
        "value": 139.371,
        "_internal_originalTime": 1689815048000
    },
    {
        "time": 1689815049000,
        "value": 139.373,
        "_internal_originalTime": 1689815049000
    },
    {
        "time": 1689815051000,
        "value": 139.377,
        "_internal_originalTime": 1689815051000
    },
    {
        "time": 1689815052000,
        "value": 139.376,
        "_internal_originalTime": 1689815052000
    },
    {
        "time": 1689815055000,
        "value": 139.376,
        "_internal_originalTime": 1689815055000
    },
    {
        "time": 1689815057000,
        "value": 139.371,
        "_internal_originalTime": 1689815057000
    },
    {
        "time": 1689815059000,
        "value": 139.373,
        "_internal_originalTime": 1689815059000
    },
    {
        "time": 1689815061000,
        "value": 139.376,
        "_internal_originalTime": 1689815061000
    },
    {
        "time": 1689815062000,
        "value": 139.369,
        "_internal_originalTime": 1689815062000
    },
    {
        "time": 1689815064000,
        "value": 139.385,
        "_internal_originalTime": 1689815064000
    },
    {
        "time": 1689815066000,
        "value": 139.378,
        "_internal_originalTime": 1689815066000
    },
    {
        "time": 1689815067000,
        "value": 139.372,
        "_internal_originalTime": 1689815067000
    },
    {
        "time": 1689815069000,
        "value": 139.368,
        "_internal_originalTime": 1689815069000
    },
    {
        "time": 1689815070000,
        "value": 139.365,
        "_internal_originalTime": 1689815070000
    },
    {
        "time": 1689815072000,
        "value": 139.369,
        "_internal_originalTime": 1689815072000
    },
    {
        "time": 1689815074000,
        "value": 139.367,
        "_internal_originalTime": 1689815074000
    },
    {
        "time": 1689815076000,
        "value": 139.368,
        "_internal_originalTime": 1689815076000
    },
    {
        "time": 1689815078000,
        "value": 139.368,
        "_internal_originalTime": 1689815078000
    },
    {
        "time": 1689815080000,
        "value": 139.372,
        "_internal_originalTime": 1689815080000
    },
    {
        "time": 1689815081000,
        "value": 139.373,
        "_internal_originalTime": 1689815081000
    },
    {
        "time": 1689815083000,
        "value": 139.377,
        "_internal_originalTime": 1689815083000
    },
    {
        "time": 1689815084000,
        "value": 139.376,
        "_internal_originalTime": 1689815084000
    },
    {
        "time": 1689815086000,
        "value": 139.371,
        "_internal_originalTime": 1689815086000
    },
    {
        "time": 1689815088000,
        "value": 139.39,
        "_internal_originalTime": 1689815088000
    },
    {
        "time": 1689815090000,
        "value": 139.386,
        "_internal_originalTime": 1689815090000
    },
    {
        "time": 1689815091000,
        "value": 139.384,
        "_internal_originalTime": 1689815091000
    },
    {
        "time": 1689815094000,
        "value": 139.381,
        "_internal_originalTime": 1689815094000
    },
    {
        "time": 1689815097000,
        "value": 139.389,
        "_internal_originalTime": 1689815097000
    },
    {
        "time": 1689815098000,
        "value": 139.389,
        "_internal_originalTime": 1689815098000
    },
    {
        "time": 1689815099000,
        "value": 139.391,
        "_internal_originalTime": 1689815099000
    },
    {
        "time": 1689815100000,
        "value": 139.386,
        "_internal_originalTime": 1689815100000
    },
    {
        "time": 1689815103000,
        "value": 139.389,
        "_internal_originalTime": 1689815103000
    },
    {
        "time": 1689815105000,
        "value": 139.386,
        "_internal_originalTime": 1689815105000
    },
    {
        "time": 1689815107000,
        "value": 139.387,
        "_internal_originalTime": 1689815107000
    },
    {
        "time": 1689815109000,
        "value": 139.377,
        "_internal_originalTime": 1689815109000
    },
    {
        "time": 1689815111000,
        "value": 139.377,
        "_internal_originalTime": 1689815111000
    },
    {
        "time": 1689815113000,
        "value": 139.377,
        "_internal_originalTime": 1689815113000
    },
    {
        "time": 1689815115000,
        "value": 139.375,
        "_internal_originalTime": 1689815115000
    },
    {
        "time": 1689815118000,
        "value": 139.377,
        "_internal_originalTime": 1689815118000
    },
    {
        "time": 1689815120000,
        "value": 139.37,
        "_internal_originalTime": 1689815120000
    },
    {
        "time": 1689815121000,
        "value": 139.377,
        "_internal_originalTime": 1689815121000
    },
    {
        "time": 1689815122000,
        "value": 139.377,
        "_internal_originalTime": 1689815122000
    },
    {
        "time": 1689815124000,
        "value": 139.375,
        "_internal_originalTime": 1689815124000
    },
    {
        "time": 1689815127000,
        "value": 139.378,
        "_internal_originalTime": 1689815127000
    },
    {
        "time": 1689815128000,
        "value": 139.384,
        "_internal_originalTime": 1689815128000
    },
    {
        "time": 1689815130000,
        "value": 139.389,
        "_internal_originalTime": 1689815130000
    },
    {
        "time": 1689815131000,
        "value": 139.38,
        "_internal_originalTime": 1689815131000
    },
    {
        "time": 1689815133000,
        "value": 139.38,
        "_internal_originalTime": 1689815133000
    },
    {
        "time": 1689815134000,
        "value": 139.377,
        "_internal_originalTime": 1689815134000
    },
    {
        "time": 1689815138000,
        "value": 139.372,
        "_internal_originalTime": 1689815138000
    },
    {
        "time": 1689815140000,
        "value": 139.379,
        "_internal_originalTime": 1689815140000
    },
    {
        "time": 1689815141000,
        "value": 139.378,
        "_internal_originalTime": 1689815141000
    },
    {
        "time": 1689815143000,
        "value": 139.375,
        "_internal_originalTime": 1689815143000
    },
    {
        "time": 1689815144000,
        "value": 139.375,
        "_internal_originalTime": 1689815144000
    },
    {
        "time": 1689815146000,
        "value": 139.374,
        "_internal_originalTime": 1689815146000
    },
    {
        "time": 1689815147000,
        "value": 139.368,
        "_internal_originalTime": 1689815147000
    },
    {
        "time": 1689815149000,
        "value": 139.372,
        "_internal_originalTime": 1689815149000
    },
    {
        "time": 1689815150000,
        "value": 139.371,
        "_internal_originalTime": 1689815150000
    },
    {
        "time": 1689815152000,
        "value": 139.369,
        "_internal_originalTime": 1689815152000
    },
    {
        "time": 1689815154000,
        "value": 139.369,
        "_internal_originalTime": 1689815154000
    },
    {
        "time": 1689815157000,
        "value": 139.362,
        "_internal_originalTime": 1689815157000
    },
    {
        "time": 1689815159000,
        "value": 139.374,
        "_internal_originalTime": 1689815159000
    },
    {
        "time": 1689815161000,
        "value": 139.371,
        "_internal_originalTime": 1689815161000
    },
    {
        "time": 1689815164000,
        "value": 139.372,
        "_internal_originalTime": 1689815164000
    },
    {
        "time": 1689815167000,
        "value": 139.373,
        "_internal_originalTime": 1689815167000
    },
    {
        "time": 1689815169000,
        "value": 139.372,
        "_internal_originalTime": 1689815169000
    },
    {
        "time": 1689815171000,
        "value": 139.375,
        "_internal_originalTime": 1689815171000
    },
    {
        "time": 1689815175000,
        "value": 139.373,
        "_internal_originalTime": 1689815175000
    },
    {
        "time": 1689815176000,
        "value": 139.372,
        "_internal_originalTime": 1689815176000
    },
    {
        "time": 1689815178000,
        "value": 139.371,
        "_internal_originalTime": 1689815178000
    },
    {
        "time": 1689815179000,
        "value": 139.368,
        "_internal_originalTime": 1689815179000
    },
    {
        "time": 1689815181000,
        "value": 139.367,
        "_internal_originalTime": 1689815181000
    },
    {
        "time": 1689815183000,
        "value": 139.368,
        "_internal_originalTime": 1689815183000
    },
    {
        "time": 1689815185000,
        "value": 139.368,
        "_internal_originalTime": 1689815185000
    },
    {
        "time": 1689815187000,
        "value": 139.375,
        "_internal_originalTime": 1689815187000
    },
    {
        "time": 1689815188000,
        "value": 139.37,
        "_internal_originalTime": 1689815188000
    },
    {
        "time": 1689815189000,
        "value": 139.37,
        "_internal_originalTime": 1689815189000
    },
    {
        "time": 1689815192000,
        "value": 139.375,
        "_internal_originalTime": 1689815192000
    },
    {
        "time": 1689815196000,
        "value": 139.371,
        "_internal_originalTime": 1689815196000
    },
    {
        "time": 1689815198000,
        "value": 139.37,
        "_internal_originalTime": 1689815198000
    },
    {
        "time": 1689815199000,
        "value": 139.382,
        "_internal_originalTime": 1689815199000
    },
    {
        "time": 1689815200000,
        "value": 139.386,
        "_internal_originalTime": 1689815200000
    },
    {
        "time": 1689815203000,
        "value": 139.382,
        "_internal_originalTime": 1689815203000
    },
    {
        "time": 1689815205000,
        "value": 139.38,
        "_internal_originalTime": 1689815205000
    },
    {
        "time": 1689815206000,
        "value": 139.389,
        "_internal_originalTime": 1689815206000
    },
    {
        "time": 1689815208000,
        "value": 139.387,
        "_internal_originalTime": 1689815208000
    },
    {
        "time": 1689815210000,
        "value": 139.385,
        "_internal_originalTime": 1689815210000
    },
    {
        "time": 1689815212000,
        "value": 139.388,
        "_internal_originalTime": 1689815212000
    },
    {
        "time": 1689815214000,
        "value": 139.397,
        "_internal_originalTime": 1689815214000
    },
    {
        "time": 1689815216000,
        "value": 139.401,
        "_internal_originalTime": 1689815216000
    },
    {
        "time": 1689815218000,
        "value": 139.406,
        "_internal_originalTime": 1689815218000
    },
    {
        "time": 1689815219000,
        "value": 139.407,
        "_internal_originalTime": 1689815219000
    },
    {
        "time": 1689815220000,
        "value": 139.404,
        "_internal_originalTime": 1689815220000
    },
    {
        "time": 1689815222000,
        "value": 139.405,
        "_internal_originalTime": 1689815222000
    },
    {
        "time": 1689815223000,
        "value": 139.403,
        "_internal_originalTime": 1689815223000
    },
    {
        "time": 1689815224000,
        "value": 139.415,
        "_internal_originalTime": 1689815224000
    },
    {
        "time": 1689815227000,
        "value": 139.413,
        "_internal_originalTime": 1689815227000
    },
    {
        "time": 1689815229000,
        "value": 139.402,
        "_internal_originalTime": 1689815229000
    },
    {
        "time": 1689815231000,
        "value": 139.4,
        "_internal_originalTime": 1689815231000
    },
    {
        "time": 1689815233000,
        "value": 139.404,
        "_internal_originalTime": 1689815233000
    },
    {
        "time": 1689815234000,
        "value": 139.404,
        "_internal_originalTime": 1689815234000
    },
    {
        "time": 1689815236000,
        "value": 139.399,
        "_internal_originalTime": 1689815236000
    },
    {
        "time": 1689815238000,
        "value": 139.396,
        "_internal_originalTime": 1689815238000
    },
    {
        "time": 1689815240000,
        "value": 139.396,
        "_internal_originalTime": 1689815240000
    },
    {
        "time": 1689815243000,
        "value": 139.392,
        "_internal_originalTime": 1689815243000
    },
    {
        "time": 1689815244000,
        "value": 139.394,
        "_internal_originalTime": 1689815244000
    },
    {
        "time": 1689815246000,
        "value": 139.398,
        "_internal_originalTime": 1689815246000
    },
    {
        "time": 1689815247000,
        "value": 139.401,
        "_internal_originalTime": 1689815247000
    },
    {
        "time": 1689815249000,
        "value": 139.398,
        "_internal_originalTime": 1689815249000
    },
    {
        "time": 1689815251000,
        "value": 139.394,
        "_internal_originalTime": 1689815251000
    },
    {
        "time": 1689815253000,
        "value": 139.388,
        "_internal_originalTime": 1689815253000
    },
    {
        "time": 1689815255000,
        "value": 139.384,
        "_internal_originalTime": 1689815255000
    },
    {
        "time": 1689815256000,
        "value": 139.386,
        "_internal_originalTime": 1689815256000
    },
    {
        "time": 1689815259000,
        "value": 139.386,
        "_internal_originalTime": 1689815259000
    },
    {
        "time": 1689815265000,
        "value": 139.387,
        "_internal_originalTime": 1689815265000
    },
    {
        "time": 1689815266000,
        "value": 139.383,
        "_internal_originalTime": 1689815266000
    },
    {
        "time": 1689815268000,
        "value": 139.382,
        "_internal_originalTime": 1689815268000
    },
    {
        "time": 1689815270000,
        "value": 139.383,
        "_internal_originalTime": 1689815270000
    },
    {
        "time": 1689815272000,
        "value": 139.384,
        "_internal_originalTime": 1689815272000
    },
    {
        "time": 1689815274000,
        "value": 139.387,
        "_internal_originalTime": 1689815274000
    },
    {
        "time": 1689815276000,
        "value": 139.38,
        "_internal_originalTime": 1689815276000
    },
    {
        "time": 1689815278000,
        "value": 139.383,
        "_internal_originalTime": 1689815278000
    },
    {
        "time": 1689815279000,
        "value": 139.382,
        "_internal_originalTime": 1689815279000
    },
    {
        "time": 1689815280000,
        "value": 139.38,
        "_internal_originalTime": 1689815280000
    },
    {
        "time": 1689815282000,
        "value": 139.382,
        "_internal_originalTime": 1689815282000
    },
    {
        "time": 1689815283000,
        "value": 139.382,
        "_internal_originalTime": 1689815283000
    },
    {
        "time": 1689815284000,
        "value": 139.382,
        "_internal_originalTime": 1689815284000
    },
    {
        "time": 1689815286000,
        "value": 139.381,
        "_internal_originalTime": 1689815286000
    },
    {
        "time": 1689815289000,
        "value": 139.375,
        "_internal_originalTime": 1689815289000
    },
    {
        "time": 1689815290000,
        "value": 139.375,
        "_internal_originalTime": 1689815290000
    },
    {
        "time": 1689815291000,
        "value": 139.369,
        "_internal_originalTime": 1689815291000
    },
    {
        "time": 1689815292000,
        "value": 139.371,
        "_internal_originalTime": 1689815292000
    },
    {
        "time": 1689815295000,
        "value": 139.375,
        "_internal_originalTime": 1689815295000
    },
    {
        "time": 1689815296000,
        "value": 139.373,
        "_internal_originalTime": 1689815296000
    },
    {
        "time": 1689815297000,
        "value": 139.371,
        "_internal_originalTime": 1689815297000
    },
    {
        "time": 1689815299000,
        "value": 139.37,
        "_internal_originalTime": 1689815299000
    },
    {
        "time": 1689815301000,
        "value": 139.367,
        "_internal_originalTime": 1689815301000
    },
    {
        "time": 1689815304000,
        "value": 139.366,
        "_internal_originalTime": 1689815304000
    },
    {
        "time": 1689815305000,
        "value": 139.366,
        "_internal_originalTime": 1689815305000
    },
    {
        "time": 1689815307000,
        "value": 139.362,
        "_internal_originalTime": 1689815307000
    },
    {
        "time": 1689815309000,
        "value": 139.36,
        "_internal_originalTime": 1689815309000
    },
    {
        "time": 1689815310000,
        "value": 139.363,
        "_internal_originalTime": 1689815310000
    },
    {
        "time": 1689815313000,
        "value": 139.369,
        "_internal_originalTime": 1689815313000
    },
    {
        "time": 1689815314000,
        "value": 139.375,
        "_internal_originalTime": 1689815314000
    },
    {
        "time": 1689815317000,
        "value": 139.376,
        "_internal_originalTime": 1689815317000
    },
    {
        "time": 1689815319000,
        "value": 139.384,
        "_internal_originalTime": 1689815319000
    },
    {
        "time": 1689815320000,
        "value": 139.381,
        "_internal_originalTime": 1689815320000
    },
    {
        "time": 1689815322000,
        "value": 139.38,
        "_internal_originalTime": 1689815322000
    },
    {
        "time": 1689815324000,
        "value": 139.384,
        "_internal_originalTime": 1689815324000
    },
    {
        "time": 1689815328000,
        "value": 139.383,
        "_internal_originalTime": 1689815328000
    },
    {
        "time": 1689815330000,
        "value": 139.384,
        "_internal_originalTime": 1689815330000
    },
    {
        "time": 1689815333000,
        "value": 139.38,
        "_internal_originalTime": 1689815333000
    },
    {
        "time": 1689815336000,
        "value": 139.391,
        "_internal_originalTime": 1689815336000
    },
    {
        "time": 1689815338000,
        "value": 139.392,
        "_internal_originalTime": 1689815338000
    },
    {
        "time": 1689815339000,
        "value": 139.398,
        "_internal_originalTime": 1689815339000
    },
    {
        "time": 1689815340000,
        "value": 139.393,
        "_internal_originalTime": 1689815340000
    },
    {
        "time": 1689815341000,
        "value": 139.395,
        "_internal_originalTime": 1689815341000
    },
    {
        "time": 1689815343000,
        "value": 139.394,
        "_internal_originalTime": 1689815343000
    },
    {
        "time": 1689815344000,
        "value": 139.398,
        "_internal_originalTime": 1689815344000
    },
    {
        "time": 1689815345000,
        "value": 139.394,
        "_internal_originalTime": 1689815345000
    },
    {
        "time": 1689815348000,
        "value": 139.396,
        "_internal_originalTime": 1689815348000
    },
    {
        "time": 1689815350000,
        "value": 139.395,
        "_internal_originalTime": 1689815350000
    },
    {
        "time": 1689815352000,
        "value": 139.396,
        "_internal_originalTime": 1689815352000
    },
    {
        "time": 1689815356000,
        "value": 139.39,
        "_internal_originalTime": 1689815356000
    },
    {
        "time": 1689815357000,
        "value": 139.396,
        "_internal_originalTime": 1689815357000
    },
    {
        "time": 1689815360000,
        "value": 139.393,
        "_internal_originalTime": 1689815360000
    },
    {
        "time": 1689815361000,
        "value": 139.392,
        "_internal_originalTime": 1689815361000
    },
    {
        "time": 1689815363000,
        "value": 139.393,
        "_internal_originalTime": 1689815363000
    },
    {
        "time": 1689815365000,
        "value": 139.394,
        "_internal_originalTime": 1689815365000
    },
    {
        "time": 1689815367000,
        "value": 139.388,
        "_internal_originalTime": 1689815367000
    },
    {
        "time": 1689815368000,
        "value": 139.393,
        "_internal_originalTime": 1689815368000
    },
    {
        "time": 1689815370000,
        "value": 139.394,
        "_internal_originalTime": 1689815370000
    },
    {
        "time": 1689815374000,
        "value": 139.393,
        "_internal_originalTime": 1689815374000
    },
    {
        "time": 1689815376000,
        "value": 139.39,
        "_internal_originalTime": 1689815376000
    },
    {
        "time": 1689815380000,
        "value": 139.393,
        "_internal_originalTime": 1689815380000
    },
    {
        "time": 1689815382000,
        "value": 139.403,
        "_internal_originalTime": 1689815382000
    },
    {
        "time": 1689815383000,
        "value": 139.404,
        "_internal_originalTime": 1689815383000
    },
    {
        "time": 1689815385000,
        "value": 139.402,
        "_internal_originalTime": 1689815385000
    },
    {
        "time": 1689815387000,
        "value": 139.402,
        "_internal_originalTime": 1689815387000
    },
    {
        "time": 1689815388000,
        "value": 139.407,
        "_internal_originalTime": 1689815388000
    },
    {
        "time": 1689815390000,
        "value": 139.404,
        "_internal_originalTime": 1689815390000
    },
    {
        "time": 1689815392000,
        "value": 139.406,
        "_internal_originalTime": 1689815392000
    },
    {
        "time": 1689815394000,
        "value": 139.409,
        "_internal_originalTime": 1689815394000
    },
    {
        "time": 1689815397000,
        "value": 139.412,
        "_internal_originalTime": 1689815397000
    },
    {
        "time": 1689815398000,
        "value": 139.414,
        "_internal_originalTime": 1689815398000
    },
    {
        "time": 1689815400000,
        "value": 139.415,
        "_internal_originalTime": 1689815400000
    },
    {
        "time": 1689815402000,
        "value": 139.41,
        "_internal_originalTime": 1689815402000
    },
    {
        "time": 1689815404000,
        "value": 139.414,
        "_internal_originalTime": 1689815404000
    },
    {
        "time": 1689815405000,
        "value": 139.408,
        "_internal_originalTime": 1689815405000
    },
    {
        "time": 1689815407000,
        "value": 139.412,
        "_internal_originalTime": 1689815407000
    },
    {
        "time": 1689815409000,
        "value": 139.422,
        "_internal_originalTime": 1689815409000
    },
    {
        "time": 1689815411000,
        "value": 139.414,
        "_internal_originalTime": 1689815411000
    },
    {
        "time": 1689815413000,
        "value": 139.416,
        "_internal_originalTime": 1689815413000
    },
    {
        "time": 1689815414000,
        "value": 139.41,
        "_internal_originalTime": 1689815414000
    },
    {
        "time": 1689815416000,
        "value": 139.42,
        "_internal_originalTime": 1689815416000
    },
    {
        "time": 1689815418000,
        "value": 139.417,
        "_internal_originalTime": 1689815418000
    },
    {
        "time": 1689815419000,
        "value": 139.416,
        "_internal_originalTime": 1689815419000
    },
    {
        "time": 1689815421000,
        "value": 139.419,
        "_internal_originalTime": 1689815421000
    },
    {
        "time": 1689815424000,
        "value": 139.419,
        "_internal_originalTime": 1689815424000
    },
    {
        "time": 1689815425000,
        "value": 139.418,
        "_internal_originalTime": 1689815425000
    },
    {
        "time": 1689815427000,
        "value": 139.412,
        "_internal_originalTime": 1689815427000
    },
    {
        "time": 1689815430000,
        "value": 139.416,
        "_internal_originalTime": 1689815430000
    },
    {
        "time": 1689815431000,
        "value": 139.416,
        "_internal_originalTime": 1689815431000
    },
    {
        "time": 1689815432000,
        "value": 139.416,
        "_internal_originalTime": 1689815432000
    },
    {
        "time": 1689815435000,
        "value": 139.415,
        "_internal_originalTime": 1689815435000
    },
    {
        "time": 1689815437000,
        "value": 139.42,
        "_internal_originalTime": 1689815437000
    },
    {
        "time": 1689815439000,
        "value": 139.418,
        "_internal_originalTime": 1689815439000
    },
    {
        "time": 1689815441000,
        "value": 139.42,
        "_internal_originalTime": 1689815441000
    },
    {
        "time": 1689815443000,
        "value": 139.411,
        "_internal_originalTime": 1689815443000
    },
    {
        "time": 1689815444000,
        "value": 139.408,
        "_internal_originalTime": 1689815444000
    },
    {
        "time": 1689815445000,
        "value": 139.412,
        "_internal_originalTime": 1689815445000
    },
    {
        "time": 1689815448000,
        "value": 139.413,
        "_internal_originalTime": 1689815448000
    },
    {
        "time": 1689815451000,
        "value": 139.409,
        "_internal_originalTime": 1689815451000
    },
    {
        "time": 1689815454000,
        "value": 139.409,
        "_internal_originalTime": 1689815454000
    },
    {
        "time": 1689815455000,
        "value": 139.408,
        "_internal_originalTime": 1689815455000
    },
    {
        "time": 1689815457000,
        "value": 139.409,
        "_internal_originalTime": 1689815457000
    },
    {
        "time": 1689815459000,
        "value": 139.427,
        "_internal_originalTime": 1689815459000
    },
    {
        "time": 1689815460000,
        "value": 139.428,
        "_internal_originalTime": 1689815460000
    },
    {
        "time": 1689815462000,
        "value": 139.425,
        "_internal_originalTime": 1689815462000
    },
    {
        "time": 1689815464000,
        "value": 139.422,
        "_internal_originalTime": 1689815464000
    },
    {
        "time": 1689815466000,
        "value": 139.426,
        "_internal_originalTime": 1689815466000
    },
    {
        "time": 1689815471000,
        "value": 139.427,
        "_internal_originalTime": 1689815471000
    },
    {
        "time": 1689815473000,
        "value": 139.424,
        "_internal_originalTime": 1689815473000
    },
    {
        "time": 1689815475000,
        "value": 139.424,
        "_internal_originalTime": 1689815475000
    },
    {
        "time": 1689815477000,
        "value": 139.434,
        "_internal_originalTime": 1689815477000
    },
    {
        "time": 1689815479000,
        "value": 139.433,
        "_internal_originalTime": 1689815479000
    },
    {
        "time": 1689815480000,
        "value": 139.43,
        "_internal_originalTime": 1689815480000
    },
    {
        "time": 1689815482000,
        "value": 139.435,
        "_internal_originalTime": 1689815482000
    },
    {
        "time": 1689815485000,
        "value": 139.436,
        "_internal_originalTime": 1689815485000
    },
    {
        "time": 1689815488000,
        "value": 139.432,
        "_internal_originalTime": 1689815488000
    },
    {
        "time": 1689815490000,
        "value": 139.433,
        "_internal_originalTime": 1689815490000
    },
    {
        "time": 1689815492000,
        "value": 139.433,
        "_internal_originalTime": 1689815492000
    },
    {
        "time": 1689815494000,
        "value": 139.435,
        "_internal_originalTime": 1689815494000
    },
    {
        "time": 1689815496000,
        "value": 139.44,
        "_internal_originalTime": 1689815496000
    },
    {
        "time": 1689815497000,
        "value": 139.445,
        "_internal_originalTime": 1689815497000
    },
    {
        "time": 1689815500000,
        "value": 139.443,
        "_internal_originalTime": 1689815500000
    },
    {
        "time": 1689815502000,
        "value": 139.44,
        "_internal_originalTime": 1689815502000
    },
    {
        "time": 1689815504000,
        "value": 139.44,
        "_internal_originalTime": 1689815504000
    },
    {
        "time": 1689815505000,
        "value": 139.44,
        "_internal_originalTime": 1689815505000
    },
    {
        "time": 1689815507000,
        "value": 139.44,
        "_internal_originalTime": 1689815507000
    },
    {
        "time": 1689815509000,
        "value": 139.444,
        "_internal_originalTime": 1689815509000
    },
    {
        "time": 1689815511000,
        "value": 139.441,
        "_internal_originalTime": 1689815511000
    },
    {
        "time": 1689815513000,
        "value": 139.444,
        "_internal_originalTime": 1689815513000
    },
    {
        "time": 1689815514000,
        "value": 139.442,
        "_internal_originalTime": 1689815514000
    },
    {
        "time": 1689815517000,
        "value": 139.44,
        "_internal_originalTime": 1689815517000
    },
    {
        "time": 1689815519000,
        "value": 139.447,
        "_internal_originalTime": 1689815519000
    },
    {
        "time": 1689815521000,
        "value": 139.442,
        "_internal_originalTime": 1689815521000
    },
    {
        "time": 1689815522000,
        "value": 139.447,
        "_internal_originalTime": 1689815522000
    },
    {
        "time": 1689815524000,
        "value": 139.447,
        "_internal_originalTime": 1689815524000
    },
    {
        "time": 1689815527000,
        "value": 139.444,
        "_internal_originalTime": 1689815527000
    },
    {
        "time": 1689815528000,
        "value": 139.44,
        "_internal_originalTime": 1689815528000
    },
    {
        "time": 1689815530000,
        "value": 139.442,
        "_internal_originalTime": 1689815530000
    },
    {
        "time": 1689815532000,
        "value": 139.446,
        "_internal_originalTime": 1689815532000
    },
    {
        "time": 1689815534000,
        "value": 139.445,
        "_internal_originalTime": 1689815534000
    },
    {
        "time": 1689815536000,
        "value": 139.444,
        "_internal_originalTime": 1689815536000
    },
    {
        "time": 1689815538000,
        "value": 139.438,
        "_internal_originalTime": 1689815538000
    },
    {
        "time": 1689815540000,
        "value": 139.445,
        "_internal_originalTime": 1689815540000
    },
    {
        "time": 1689815542000,
        "value": 139.44,
        "_internal_originalTime": 1689815542000
    },
    {
        "time": 1689815544000,
        "value": 139.44,
        "_internal_originalTime": 1689815544000
    },
    {
        "time": 1689815545000,
        "value": 139.436,
        "_internal_originalTime": 1689815545000
    },
    {
        "time": 1689815548000,
        "value": 139.435,
        "_internal_originalTime": 1689815548000
    },
    {
        "time": 1689815549000,
        "value": 139.435,
        "_internal_originalTime": 1689815549000
    },
    {
        "time": 1689815551000,
        "value": 139.435,
        "_internal_originalTime": 1689815551000
    },
    {
        "time": 1689815553000,
        "value": 139.43,
        "_internal_originalTime": 1689815553000
    },
    {
        "time": 1689815555000,
        "value": 139.434,
        "_internal_originalTime": 1689815555000
    },
    {
        "time": 1689815557000,
        "value": 139.431,
        "_internal_originalTime": 1689815557000
    },
    {
        "time": 1689815559000,
        "value": 139.434,
        "_internal_originalTime": 1689815559000
    },
    {
        "time": 1689815561000,
        "value": 139.438,
        "_internal_originalTime": 1689815561000
    },
    {
        "time": 1689815562000,
        "value": 139.441,
        "_internal_originalTime": 1689815562000
    },
    {
        "time": 1689815565000,
        "value": 139.445,
        "_internal_originalTime": 1689815565000
    },
    {
        "time": 1689815568000,
        "value": 139.444,
        "_internal_originalTime": 1689815568000
    },
    {
        "time": 1689815575000,
        "value": 139.444,
        "_internal_originalTime": 1689815575000
    },
    {
        "time": 1689815576000,
        "value": 139.445,
        "_internal_originalTime": 1689815576000
    },
    {
        "time": 1689815579000,
        "value": 139.453,
        "_internal_originalTime": 1689815579000
    },
    {
        "time": 1689815580000,
        "value": 139.454,
        "_internal_originalTime": 1689815580000
    },
    {
        "time": 1689815581000,
        "value": 139.455,
        "_internal_originalTime": 1689815581000
    },
    {
        "time": 1689815582000,
        "value": 139.451,
        "_internal_originalTime": 1689815582000
    },
    {
        "time": 1689815584000,
        "value": 139.45,
        "_internal_originalTime": 1689815584000
    },
    {
        "time": 1689815585000,
        "value": 139.448,
        "_internal_originalTime": 1689815585000
    },
    {
        "time": 1689815587000,
        "value": 139.448,
        "_internal_originalTime": 1689815587000
    },
    {
        "time": 1689815589000,
        "value": 139.44,
        "_internal_originalTime": 1689815589000
    },
    {
        "time": 1689815591000,
        "value": 139.445,
        "_internal_originalTime": 1689815591000
    },
    {
        "time": 1689815593000,
        "value": 139.44,
        "_internal_originalTime": 1689815593000
    },
    {
        "time": 1689815595000,
        "value": 139.446,
        "_internal_originalTime": 1689815595000
    },
    {
        "time": 1689815596000,
        "value": 139.448,
        "_internal_originalTime": 1689815596000
    },
    {
        "time": 1689815597000,
        "value": 139.445,
        "_internal_originalTime": 1689815597000
    },
    {
        "time": 1689815599000,
        "value": 139.448,
        "_internal_originalTime": 1689815599000
    },
    {
        "time": 1689815600000,
        "value": 139.45,
        "_internal_originalTime": 1689815600000
    },
    {
        "time": 1689815602000,
        "value": 139.448,
        "_internal_originalTime": 1689815602000
    },
    {
        "time": 1689815604000,
        "value": 139.45,
        "_internal_originalTime": 1689815604000
    },
    {
        "time": 1689815607000,
        "value": 139.449,
        "_internal_originalTime": 1689815607000
    },
    {
        "time": 1689815613000,
        "value": 139.454,
        "_internal_originalTime": 1689815613000
    },
    {
        "time": 1689815615000,
        "value": 139.447,
        "_internal_originalTime": 1689815615000
    },
    {
        "time": 1689815616000,
        "value": 139.454,
        "_internal_originalTime": 1689815616000
    },
    {
        "time": 1689815686000,
        "value": 139.433,
        "_internal_originalTime": 1689815686000
    },
    {
        "time": 1689815688000,
        "value": 139.432,
        "_internal_originalTime": 1689815688000
    },
    {
        "time": 1689815690000,
        "value": 139.43,
        "_internal_originalTime": 1689815690000
    },
    {
        "time": 1689815692000,
        "value": 139.434,
        "_internal_originalTime": 1689815692000
    },
    {
        "time": 1689815693000,
        "value": 139.43,
        "_internal_originalTime": 1689815693000
    },
    {
        "time": 1689815697000,
        "value": 139.428,
        "_internal_originalTime": 1689815697000
    },
    {
        "time": 1689815698000,
        "value": 139.434,
        "_internal_originalTime": 1689815698000
    },
    {
        "time": 1689815701000,
        "value": 139.428,
        "_internal_originalTime": 1689815701000
    },
    {
        "time": 1689815702000,
        "value": 139.434,
        "_internal_originalTime": 1689815702000
    },
    {
        "time": 1689815703000,
        "value": 139.434,
        "_internal_originalTime": 1689815703000
    },
    {
        "time": 1689815704000,
        "value": 139.433,
        "_internal_originalTime": 1689815704000
    },
    {
        "time": 1689815705000,
        "value": 139.427,
        "_internal_originalTime": 1689815705000
    },
    {
        "time": 1689815707000,
        "value": 139.426,
        "_internal_originalTime": 1689815707000
    },
    {
        "time": 1689815709000,
        "value": 139.42,
        "_internal_originalTime": 1689815709000
    },
    {
        "time": 1689815712000,
        "value": 139.42,
        "_internal_originalTime": 1689815712000
    },
    {
        "time": 1689815713000,
        "value": 139.425,
        "_internal_originalTime": 1689815713000
    },
    {
        "time": 1689815715000,
        "value": 139.42,
        "_internal_originalTime": 1689815715000
    },
    {
        "time": 1689815716000,
        "value": 139.421,
        "_internal_originalTime": 1689815716000
    },
    {
        "time": 1689815719000,
        "value": 139.416,
        "_internal_originalTime": 1689815719000
    },
    {
        "time": 1689815721000,
        "value": 139.414,
        "_internal_originalTime": 1689815721000
    },
    {
        "time": 1689815723000,
        "value": 139.404,
        "_internal_originalTime": 1689815723000
    },
    {
        "time": 1689815725000,
        "value": 139.408,
        "_internal_originalTime": 1689815725000
    },
    {
        "time": 1689815726000,
        "value": 139.402,
        "_internal_originalTime": 1689815726000
    },
    {
        "time": 1689815728000,
        "value": 139.382,
        "_internal_originalTime": 1689815728000
    },
    {
        "time": 1689815730000,
        "value": 139.386,
        "_internal_originalTime": 1689815730000
    },
    {
        "time": 1689815732000,
        "value": 139.395,
        "_internal_originalTime": 1689815732000
    },
    {
        "time": 1689815733000,
        "value": 139.385,
        "_internal_originalTime": 1689815733000
    },
    {
        "time": 1689815736000,
        "value": 139.389,
        "_internal_originalTime": 1689815736000
    },
    {
        "time": 1689815738000,
        "value": 139.393,
        "_internal_originalTime": 1689815738000
    },
    {
        "time": 1689815739000,
        "value": 139.392,
        "_internal_originalTime": 1689815739000
    },
    {
        "time": 1689815742000,
        "value": 139.387,
        "_internal_originalTime": 1689815742000
    },
    {
        "time": 1689815744000,
        "value": 139.379,
        "_internal_originalTime": 1689815744000
    },
    {
        "time": 1689815745000,
        "value": 139.374,
        "_internal_originalTime": 1689815745000
    },
    {
        "time": 1689815747000,
        "value": 139.375,
        "_internal_originalTime": 1689815747000
    },
    {
        "time": 1689815749000,
        "value": 139.379,
        "_internal_originalTime": 1689815749000
    },
    {
        "time": 1689815751000,
        "value": 139.378,
        "_internal_originalTime": 1689815751000
    },
    {
        "time": 1689815753000,
        "value": 139.373,
        "_internal_originalTime": 1689815753000
    },
    {
        "time": 1689815755000,
        "value": 139.369,
        "_internal_originalTime": 1689815755000
    },
    {
        "time": 1689815757000,
        "value": 139.354,
        "_internal_originalTime": 1689815757000
    },
    {
        "time": 1689815758000,
        "value": 139.358,
        "_internal_originalTime": 1689815758000
    },
    {
        "time": 1689815760000,
        "value": 139.365,
        "_internal_originalTime": 1689815760000
    },
    {
        "time": 1689815761000,
        "value": 139.366,
        "_internal_originalTime": 1689815761000
    },
    {
        "time": 1689815763000,
        "value": 139.366,
        "_internal_originalTime": 1689815763000
    },
    {
        "time": 1689815764000,
        "value": 139.369,
        "_internal_originalTime": 1689815764000
    },
    {
        "time": 1689815766000,
        "value": 139.361,
        "_internal_originalTime": 1689815766000
    },
    {
        "time": 1689815768000,
        "value": 139.361,
        "_internal_originalTime": 1689815768000
    },
    {
        "time": 1689815770000,
        "value": 139.363,
        "_internal_originalTime": 1689815770000
    },
    {
        "time": 1689815772000,
        "value": 139.372,
        "_internal_originalTime": 1689815772000
    },
    {
        "time": 1689815774000,
        "value": 139.372,
        "_internal_originalTime": 1689815774000
    },
    {
        "time": 1689815776000,
        "value": 139.37,
        "_internal_originalTime": 1689815776000
    },
    {
        "time": 1689815778000,
        "value": 139.373,
        "_internal_originalTime": 1689815778000
    },
    {
        "time": 1689815780000,
        "value": 139.365,
        "_internal_originalTime": 1689815780000
    },
    {
        "time": 1689815782000,
        "value": 139.372,
        "_internal_originalTime": 1689815782000
    },
    {
        "time": 1689815784000,
        "value": 139.368,
        "_internal_originalTime": 1689815784000
    },
    {
        "time": 1689815786000,
        "value": 139.375,
        "_internal_originalTime": 1689815786000
    },
    {
        "time": 1689815787000,
        "value": 139.384,
        "_internal_originalTime": 1689815787000
    },
    {
        "time": 1689815789000,
        "value": 139.385,
        "_internal_originalTime": 1689815789000
    },
    {
        "time": 1689815790000,
        "value": 139.385,
        "_internal_originalTime": 1689815790000
    },
    {
        "time": 1689815793000,
        "value": 139.38,
        "_internal_originalTime": 1689815793000
    },
    {
        "time": 1689815795000,
        "value": 139.381,
        "_internal_originalTime": 1689815795000
    },
    {
        "time": 1689815797000,
        "value": 139.382,
        "_internal_originalTime": 1689815797000
    },
    {
        "time": 1689815799000,
        "value": 139.387,
        "_internal_originalTime": 1689815799000
    },
    {
        "time": 1689815801000,
        "value": 139.386,
        "_internal_originalTime": 1689815801000
    },
    {
        "time": 1689815803000,
        "value": 139.379,
        "_internal_originalTime": 1689815803000
    },
    {
        "time": 1689815805000,
        "value": 139.377,
        "_internal_originalTime": 1689815805000
    },
    {
        "time": 1689815807000,
        "value": 139.37,
        "_internal_originalTime": 1689815807000
    },
    {
        "time": 1689815809000,
        "value": 139.365,
        "_internal_originalTime": 1689815809000
    },
    {
        "time": 1689815810000,
        "value": 139.362,
        "_internal_originalTime": 1689815810000
    },
    {
        "time": 1689815812000,
        "value": 139.366,
        "_internal_originalTime": 1689815812000
    },
    {
        "time": 1689815815000,
        "value": 139.361,
        "_internal_originalTime": 1689815815000
    },
    {
        "time": 1689815817000,
        "value": 139.366,
        "_internal_originalTime": 1689815817000
    },
    {
        "time": 1689815819000,
        "value": 139.362,
        "_internal_originalTime": 1689815819000
    },
    {
        "time": 1689815820000,
        "value": 139.355,
        "_internal_originalTime": 1689815820000
    },
    {
        "time": 1689815821000,
        "value": 139.35,
        "_internal_originalTime": 1689815821000
    },
    {
        "time": 1689815823000,
        "value": 139.354,
        "_internal_originalTime": 1689815823000
    },
    {
        "time": 1689815824000,
        "value": 139.354,
        "_internal_originalTime": 1689815824000
    },
    {
        "time": 1689815827000,
        "value": 139.351,
        "_internal_originalTime": 1689815827000
    },
    {
        "time": 1689815828000,
        "value": 139.348,
        "_internal_originalTime": 1689815828000
    },
    {
        "time": 1689815831000,
        "value": 139.364,
        "_internal_originalTime": 1689815831000
    },
    {
        "time": 1689815833000,
        "value": 139.364,
        "_internal_originalTime": 1689815833000
    },
    {
        "time": 1689815835000,
        "value": 139.367,
        "_internal_originalTime": 1689815835000
    },
    {
        "time": 1689815836000,
        "value": 139.362,
        "_internal_originalTime": 1689815836000
    },
    {
        "time": 1689815839000,
        "value": 139.364,
        "_internal_originalTime": 1689815839000
    },
    {
        "time": 1689815843000,
        "value": 139.375,
        "_internal_originalTime": 1689815843000
    },
    {
        "time": 1689815845000,
        "value": 139.377,
        "_internal_originalTime": 1689815845000
    },
    {
        "time": 1689815846000,
        "value": 139.383,
        "_internal_originalTime": 1689815846000
    },
    {
        "time": 1689815849000,
        "value": 139.384,
        "_internal_originalTime": 1689815849000
    },
    {
        "time": 1689815850000,
        "value": 139.38,
        "_internal_originalTime": 1689815850000
    },
    {
        "time": 1689815852000,
        "value": 139.378,
        "_internal_originalTime": 1689815852000
    },
    {
        "time": 1689815853000,
        "value": 139.374,
        "_internal_originalTime": 1689815853000
    },
    {
        "time": 1689815855000,
        "value": 139.379,
        "_internal_originalTime": 1689815855000
    },
    {
        "time": 1689815858000,
        "value": 139.378,
        "_internal_originalTime": 1689815858000
    },
    {
        "time": 1689815859000,
        "value": 139.383,
        "_internal_originalTime": 1689815859000
    },
    {
        "time": 1689815862000,
        "value": 139.375,
        "_internal_originalTime": 1689815862000
    },
    {
        "time": 1689815864000,
        "value": 139.377,
        "_internal_originalTime": 1689815864000
    },
    {
        "time": 1689815865000,
        "value": 139.372,
        "_internal_originalTime": 1689815865000
    },
    {
        "time": 1689815867000,
        "value": 139.38,
        "_internal_originalTime": 1689815867000
    },
    {
        "time": 1689815870000,
        "value": 139.376,
        "_internal_originalTime": 1689815870000
    },
    {
        "time": 1689815871000,
        "value": 139.38,
        "_internal_originalTime": 1689815871000
    },
    {
        "time": 1689815873000,
        "value": 139.376,
        "_internal_originalTime": 1689815873000
    },
    {
        "time": 1689815875000,
        "value": 139.38,
        "_internal_originalTime": 1689815875000
    },
    {
        "time": 1689815876000,
        "value": 139.374,
        "_internal_originalTime": 1689815876000
    },
    {
        "time": 1689815878000,
        "value": 139.38,
        "_internal_originalTime": 1689815878000
    },
    {
        "time": 1689815880000,
        "value": 139.372,
        "_internal_originalTime": 1689815880000
    },
    {
        "time": 1689815881000,
        "value": 139.372,
        "_internal_originalTime": 1689815881000
    },
    {
        "time": 1689815882000,
        "value": 139.374,
        "_internal_originalTime": 1689815882000
    },
    {
        "time": 1689815884000,
        "value": 139.374,
        "_internal_originalTime": 1689815884000
    },
    {
        "time": 1689815886000,
        "value": 139.367,
        "_internal_originalTime": 1689815886000
    },
    {
        "time": 1689815888000,
        "value": 139.363,
        "_internal_originalTime": 1689815888000
    },
    {
        "time": 1689815889000,
        "value": 139.364,
        "_internal_originalTime": 1689815889000
    },
    {
        "time": 1689815892000,
        "value": 139.376,
        "_internal_originalTime": 1689815892000
    },
    {
        "time": 1689815894000,
        "value": 139.365,
        "_internal_originalTime": 1689815894000
    },
    {
        "time": 1689815897000,
        "value": 139.368,
        "_internal_originalTime": 1689815897000
    },
    {
        "time": 1689815900000,
        "value": 139.369,
        "_internal_originalTime": 1689815900000
    },
    {
        "time": 1689815902000,
        "value": 139.369,
        "_internal_originalTime": 1689815902000
    },
    {
        "time": 1689815903000,
        "value": 139.364,
        "_internal_originalTime": 1689815903000
    },
    {
        "time": 1689815905000,
        "value": 139.364,
        "_internal_originalTime": 1689815905000
    },
    {
        "time": 1689815907000,
        "value": 139.369,
        "_internal_originalTime": 1689815907000
    },
    {
        "time": 1689815908000,
        "value": 139.372,
        "_internal_originalTime": 1689815908000
    },
    {
        "time": 1689815909000,
        "value": 139.37,
        "_internal_originalTime": 1689815909000
    },
    {
        "time": 1689815911000,
        "value": 139.366,
        "_internal_originalTime": 1689815911000
    },
    {
        "time": 1689815912000,
        "value": 139.362,
        "_internal_originalTime": 1689815912000
    },
    {
        "time": 1689815913000,
        "value": 139.364,
        "_internal_originalTime": 1689815913000
    },
    {
        "time": 1689815914000,
        "value": 139.365,
        "_internal_originalTime": 1689815914000
    },
    {
        "time": 1689815916000,
        "value": 139.365,
        "_internal_originalTime": 1689815916000
    },
    {
        "time": 1689815917000,
        "value": 139.37,
        "_internal_originalTime": 1689815917000
    },
    {
        "time": 1689815918000,
        "value": 139.369,
        "_internal_originalTime": 1689815918000
    },
    {
        "time": 1689815919000,
        "value": 139.367,
        "_internal_originalTime": 1689815919000
    },
    {
        "time": 1689815922000,
        "value": 139.367,
        "_internal_originalTime": 1689815922000
    },
    {
        "time": 1689815924000,
        "value": 139.366,
        "_internal_originalTime": 1689815924000
    },
    {
        "time": 1689815925000,
        "value": 139.365,
        "_internal_originalTime": 1689815925000
    },
    {
        "time": 1689815929000,
        "value": 139.364,
        "_internal_originalTime": 1689815929000
    },
    {
        "time": 1689815933000,
        "value": 139.376,
        "_internal_originalTime": 1689815933000
    },
    {
        "time": 1689815934000,
        "value": 139.375,
        "_internal_originalTime": 1689815934000
    },
    {
        "time": 1689815936000,
        "value": 139.378,
        "_internal_originalTime": 1689815936000
    },
    {
        "time": 1689815937000,
        "value": 139.379,
        "_internal_originalTime": 1689815937000
    },
    {
        "time": 1689815938000,
        "value": 139.378,
        "_internal_originalTime": 1689815938000
    },
    {
        "time": 1689815940000,
        "value": 139.379,
        "_internal_originalTime": 1689815940000
    },
    {
        "time": 1689815941000,
        "value": 139.39,
        "_internal_originalTime": 1689815941000
    },
    {
        "time": 1689815942000,
        "value": 139.394,
        "_internal_originalTime": 1689815942000
    },
    {
        "time": 1689815944000,
        "value": 139.388,
        "_internal_originalTime": 1689815944000
    },
    {
        "time": 1689815946000,
        "value": 139.354,
        "_internal_originalTime": 1689815946000
    },
    {
        "time": 1689815948000,
        "value": 139.35,
        "_internal_originalTime": 1689815948000
    },
    {
        "time": 1689815950000,
        "value": 139.353,
        "_internal_originalTime": 1689815950000
    },
    {
        "time": 1689815951000,
        "value": 139.353,
        "_internal_originalTime": 1689815951000
    },
    {
        "time": 1689815954000,
        "value": 139.334,
        "_internal_originalTime": 1689815954000
    },
    {
        "time": 1689815955000,
        "value": 139.339,
        "_internal_originalTime": 1689815955000
    },
    {
        "time": 1689815958000,
        "value": 139.334,
        "_internal_originalTime": 1689815958000
    },
    {
        "time": 1689815960000,
        "value": 139.336,
        "_internal_originalTime": 1689815960000
    },
    {
        "time": 1689815962000,
        "value": 139.338,
        "_internal_originalTime": 1689815962000
    },
    {
        "time": 1689815965000,
        "value": 139.336,
        "_internal_originalTime": 1689815965000
    },
    {
        "time": 1689815967000,
        "value": 139.34,
        "_internal_originalTime": 1689815967000
    },
    {
        "time": 1689815969000,
        "value": 139.339,
        "_internal_originalTime": 1689815969000
    },
    {
        "time": 1689815970000,
        "value": 139.334,
        "_internal_originalTime": 1689815970000
    },
    {
        "time": 1689815972000,
        "value": 139.339,
        "_internal_originalTime": 1689815972000
    },
    {
        "time": 1689815975000,
        "value": 139.329,
        "_internal_originalTime": 1689815975000
    },
    {
        "time": 1689815977000,
        "value": 139.321,
        "_internal_originalTime": 1689815977000
    },
    {
        "time": 1689815979000,
        "value": 139.32,
        "_internal_originalTime": 1689815979000
    },
    {
        "time": 1689815981000,
        "value": 139.318,
        "_internal_originalTime": 1689815981000
    },
    {
        "time": 1689815982000,
        "value": 139.316,
        "_internal_originalTime": 1689815982000
    },
    {
        "time": 1689815984000,
        "value": 139.322,
        "_internal_originalTime": 1689815984000
    },
    {
        "time": 1689815985000,
        "value": 139.324,
        "_internal_originalTime": 1689815985000
    },
    {
        "time": 1689815987000,
        "value": 139.325,
        "_internal_originalTime": 1689815987000
    },
    {
        "time": 1689815989000,
        "value": 139.328,
        "_internal_originalTime": 1689815989000
    },
    {
        "time": 1689815991000,
        "value": 139.341,
        "_internal_originalTime": 1689815991000
    },
    {
        "time": 1689815993000,
        "value": 139.332,
        "_internal_originalTime": 1689815993000
    },
    {
        "time": 1689815995000,
        "value": 139.329,
        "_internal_originalTime": 1689815995000
    },
    {
        "time": 1689815998000,
        "value": 139.323,
        "_internal_originalTime": 1689815998000
    },
    {
        "time": 1689815999000,
        "value": 139.331,
        "_internal_originalTime": 1689815999000
    },
    {
        "time": 1689816000000,
        "value": 139.332,
        "_internal_originalTime": 1689816000000
    },
    {
        "time": 1689816001000,
        "value": 139.33,
        "_internal_originalTime": 1689816001000
    },
    {
        "time": 1689816003000,
        "value": 139.319,
        "_internal_originalTime": 1689816003000
    },
    {
        "time": 1689816006000,
        "value": 139.324,
        "_internal_originalTime": 1689816006000
    },
    {
        "time": 1689816008000,
        "value": 139.324,
        "_internal_originalTime": 1689816008000
    },
    {
        "time": 1689816010000,
        "value": 139.312,
        "_internal_originalTime": 1689816010000
    },
    {
        "time": 1689816012000,
        "value": 139.314,
        "_internal_originalTime": 1689816012000
    },
    {
        "time": 1689816013000,
        "value": 139.316,
        "_internal_originalTime": 1689816013000
    },
    {
        "time": 1689816015000,
        "value": 139.314,
        "_internal_originalTime": 1689816015000
    },
    {
        "time": 1689816017000,
        "value": 139.315,
        "_internal_originalTime": 1689816017000
    },
    {
        "time": 1689816019000,
        "value": 139.315,
        "_internal_originalTime": 1689816019000
    },
    {
        "time": 1689816021000,
        "value": 139.314,
        "_internal_originalTime": 1689816021000
    },
    {
        "time": 1689816023000,
        "value": 139.317,
        "_internal_originalTime": 1689816023000
    },
    {
        "time": 1689816025000,
        "value": 139.319,
        "_internal_originalTime": 1689816025000
    },
    {
        "time": 1689816027000,
        "value": 139.32,
        "_internal_originalTime": 1689816027000
    },
    {
        "time": 1689816028000,
        "value": 139.314,
        "_internal_originalTime": 1689816028000
    },
    {
        "time": 1689816030000,
        "value": 139.318,
        "_internal_originalTime": 1689816030000
    },
    {
        "time": 1689816032000,
        "value": 139.312,
        "_internal_originalTime": 1689816032000
    },
    {
        "time": 1689816034000,
        "value": 139.318,
        "_internal_originalTime": 1689816034000
    },
    {
        "time": 1689816037000,
        "value": 139.316,
        "_internal_originalTime": 1689816037000
    },
    {
        "time": 1689816039000,
        "value": 139.32,
        "_internal_originalTime": 1689816039000
    },
    {
        "time": 1689816041000,
        "value": 139.319,
        "_internal_originalTime": 1689816041000
    },
    {
        "time": 1689816042000,
        "value": 139.322,
        "_internal_originalTime": 1689816042000
    },
    {
        "time": 1689816044000,
        "value": 139.326,
        "_internal_originalTime": 1689816044000
    },
    {
        "time": 1689816045000,
        "value": 139.324,
        "_internal_originalTime": 1689816045000
    },
    {
        "time": 1689816046000,
        "value": 139.328,
        "_internal_originalTime": 1689816046000
    },
    {
        "time": 1689816048000,
        "value": 139.322,
        "_internal_originalTime": 1689816048000
    },
    {
        "time": 1689816051000,
        "value": 139.323,
        "_internal_originalTime": 1689816051000
    },
    {
        "time": 1689816052000,
        "value": 139.317,
        "_internal_originalTime": 1689816052000
    },
    {
        "time": 1689816054000,
        "value": 139.314,
        "_internal_originalTime": 1689816054000
    },
    {
        "time": 1689816057000,
        "value": 139.313,
        "_internal_originalTime": 1689816057000
    },
    {
        "time": 1689816058000,
        "value": 139.316,
        "_internal_originalTime": 1689816058000
    },
    {
        "time": 1689816060000,
        "value": 139.317,
        "_internal_originalTime": 1689816060000
    },
    {
        "time": 1689816061000,
        "value": 139.327,
        "_internal_originalTime": 1689816061000
    },
    {
        "time": 1689816062000,
        "value": 139.328,
        "_internal_originalTime": 1689816062000
    },
    {
        "time": 1689816064000,
        "value": 139.325,
        "_internal_originalTime": 1689816064000
    },
    {
        "time": 1689816066000,
        "value": 139.319,
        "_internal_originalTime": 1689816066000
    },
    {
        "time": 1689816068000,
        "value": 139.316,
        "_internal_originalTime": 1689816068000
    },
    {
        "time": 1689816070000,
        "value": 139.316,
        "_internal_originalTime": 1689816070000
    },
    {
        "time": 1689816072000,
        "value": 139.316,
        "_internal_originalTime": 1689816072000
    },
    {
        "time": 1689816073000,
        "value": 139.314,
        "_internal_originalTime": 1689816073000
    },
    {
        "time": 1689816076000,
        "value": 139.314,
        "_internal_originalTime": 1689816076000
    },
    {
        "time": 1689816077000,
        "value": 139.316,
        "_internal_originalTime": 1689816077000
    },
    {
        "time": 1689816080000,
        "value": 139.307,
        "_internal_originalTime": 1689816080000
    },
    {
        "time": 1689816081000,
        "value": 139.304,
        "_internal_originalTime": 1689816081000
    },
    {
        "time": 1689816082000,
        "value": 139.311,
        "_internal_originalTime": 1689816082000
    },
    {
        "time": 1689816084000,
        "value": 139.308,
        "_internal_originalTime": 1689816084000
    },
    {
        "time": 1689816085000,
        "value": 139.308,
        "_internal_originalTime": 1689816085000
    },
    {
        "time": 1689816087000,
        "value": 139.313,
        "_internal_originalTime": 1689816087000
    },
    {
        "time": 1689816088000,
        "value": 139.315,
        "_internal_originalTime": 1689816088000
    },
    {
        "time": 1689816089000,
        "value": 139.313,
        "_internal_originalTime": 1689816089000
    },
    {
        "time": 1689816091000,
        "value": 139.313,
        "_internal_originalTime": 1689816091000
    },
    {
        "time": 1689816092000,
        "value": 139.292,
        "_internal_originalTime": 1689816092000
    },
    {
        "time": 1689816093000,
        "value": 139.283,
        "_internal_originalTime": 1689816093000
    },
    {
        "time": 1689816094000,
        "value": 139.278,
        "_internal_originalTime": 1689816094000
    },
    {
        "time": 1689816095000,
        "value": 139.283,
        "_internal_originalTime": 1689816095000
    },
    {
        "time": 1689816097000,
        "value": 139.289,
        "_internal_originalTime": 1689816097000
    },
    {
        "time": 1689816098000,
        "value": 139.294,
        "_internal_originalTime": 1689816098000
    },
    {
        "time": 1689816099000,
        "value": 139.291,
        "_internal_originalTime": 1689816099000
    },
    {
        "time": 1689816100000,
        "value": 139.301,
        "_internal_originalTime": 1689816100000
    },
    {
        "time": 1689816101000,
        "value": 139.291,
        "_internal_originalTime": 1689816101000
    },
    {
        "time": 1689816102000,
        "value": 139.293,
        "_internal_originalTime": 1689816102000
    },
    {
        "time": 1689816103000,
        "value": 139.293,
        "_internal_originalTime": 1689816103000
    },
    {
        "time": 1689816105000,
        "value": 139.292,
        "_internal_originalTime": 1689816105000
    },
    {
        "time": 1689816106000,
        "value": 139.293,
        "_internal_originalTime": 1689816106000
    },
    {
        "time": 1689816108000,
        "value": 139.294,
        "_internal_originalTime": 1689816108000
    },
    {
        "time": 1689816110000,
        "value": 139.292,
        "_internal_originalTime": 1689816110000
    },
    {
        "time": 1689816112000,
        "value": 139.294,
        "_internal_originalTime": 1689816112000
    },
    {
        "time": 1689816114000,
        "value": 139.305,
        "_internal_originalTime": 1689816114000
    },
    {
        "time": 1689816116000,
        "value": 139.301,
        "_internal_originalTime": 1689816116000
    },
    {
        "time": 1689816118000,
        "value": 139.312,
        "_internal_originalTime": 1689816118000
    },
    {
        "time": 1689816119000,
        "value": 139.31,
        "_internal_originalTime": 1689816119000
    },
    {
        "time": 1689816120000,
        "value": 139.316,
        "_internal_originalTime": 1689816120000
    },
    {
        "time": 1689816122000,
        "value": 139.314,
        "_internal_originalTime": 1689816122000
    },
    {
        "time": 1689816123000,
        "value": 139.307,
        "_internal_originalTime": 1689816123000
    },
    {
        "time": 1689816125000,
        "value": 139.304,
        "_internal_originalTime": 1689816125000
    },
    {
        "time": 1689816126000,
        "value": 139.297,
        "_internal_originalTime": 1689816126000
    },
    {
        "time": 1689816128000,
        "value": 139.304,
        "_internal_originalTime": 1689816128000
    },
    {
        "time": 1689816129000,
        "value": 139.299,
        "_internal_originalTime": 1689816129000
    },
    {
        "time": 1689816132000,
        "value": 139.311,
        "_internal_originalTime": 1689816132000
    },
    {
        "time": 1689816133000,
        "value": 139.31,
        "_internal_originalTime": 1689816133000
    },
    {
        "time": 1689816136000,
        "value": 139.309,
        "_internal_originalTime": 1689816136000
    },
    {
        "time": 1689816138000,
        "value": 139.307,
        "_internal_originalTime": 1689816138000
    },
    {
        "time": 1689816140000,
        "value": 139.298,
        "_internal_originalTime": 1689816140000
    },
    {
        "time": 1689816142000,
        "value": 139.291,
        "_internal_originalTime": 1689816142000
    },
    {
        "time": 1689816143000,
        "value": 139.292,
        "_internal_originalTime": 1689816143000
    },
    {
        "time": 1689816145000,
        "value": 139.288,
        "_internal_originalTime": 1689816145000
    },
    {
        "time": 1689816147000,
        "value": 139.296,
        "_internal_originalTime": 1689816147000
    },
    {
        "time": 1689816149000,
        "value": 139.301,
        "_internal_originalTime": 1689816149000
    },
    {
        "time": 1689816150000,
        "value": 139.3,
        "_internal_originalTime": 1689816150000
    },
    {
        "time": 1689816152000,
        "value": 139.301,
        "_internal_originalTime": 1689816152000
    },
    {
        "time": 1689816154000,
        "value": 139.299,
        "_internal_originalTime": 1689816154000
    },
    {
        "time": 1689816156000,
        "value": 139.293,
        "_internal_originalTime": 1689816156000
    },
    {
        "time": 1689816158000,
        "value": 139.288,
        "_internal_originalTime": 1689816158000
    },
    {
        "time": 1689816160000,
        "value": 139.292,
        "_internal_originalTime": 1689816160000
    },
    {
        "time": 1689816162000,
        "value": 139.28,
        "_internal_originalTime": 1689816162000
    },
    {
        "time": 1689816163000,
        "value": 139.276,
        "_internal_originalTime": 1689816163000
    },
    {
        "time": 1689816165000,
        "value": 139.277,
        "_internal_originalTime": 1689816165000
    },
    {
        "time": 1689816166000,
        "value": 139.278,
        "_internal_originalTime": 1689816166000
    },
    {
        "time": 1689816168000,
        "value": 139.278,
        "_internal_originalTime": 1689816168000
    },
    {
        "time": 1689816170000,
        "value": 139.279,
        "_internal_originalTime": 1689816170000
    },
    {
        "time": 1689816172000,
        "value": 139.277,
        "_internal_originalTime": 1689816172000
    },
    {
        "time": 1689816174000,
        "value": 139.28,
        "_internal_originalTime": 1689816174000
    },
    {
        "time": 1689816177000,
        "value": 139.277,
        "_internal_originalTime": 1689816177000
    },
    {
        "time": 1689816180000,
        "value": 139.284,
        "_internal_originalTime": 1689816180000
    },
    {
        "time": 1689816181000,
        "value": 139.288,
        "_internal_originalTime": 1689816181000
    },
    {
        "time": 1689816183000,
        "value": 139.289,
        "_internal_originalTime": 1689816183000
    },
    {
        "time": 1689816186000,
        "value": 139.289,
        "_internal_originalTime": 1689816186000
    },
    {
        "time": 1689816188000,
        "value": 139.278,
        "_internal_originalTime": 1689816188000
    },
    {
        "time": 1689816189000,
        "value": 139.28,
        "_internal_originalTime": 1689816189000
    },
    {
        "time": 1689816192000,
        "value": 139.28,
        "_internal_originalTime": 1689816192000
    },
    {
        "time": 1689816194000,
        "value": 139.293,
        "_internal_originalTime": 1689816194000
    },
    {
        "time": 1689816195000,
        "value": 139.293,
        "_internal_originalTime": 1689816195000
    },
    {
        "time": 1689816197000,
        "value": 139.289,
        "_internal_originalTime": 1689816197000
    },
    {
        "time": 1689816200000,
        "value": 139.292,
        "_internal_originalTime": 1689816200000
    },
    {
        "time": 1689816202000,
        "value": 139.296,
        "_internal_originalTime": 1689816202000
    },
    {
        "time": 1689816203000,
        "value": 139.298,
        "_internal_originalTime": 1689816203000
    },
    {
        "time": 1689816205000,
        "value": 139.295,
        "_internal_originalTime": 1689816205000
    },
    {
        "time": 1689816207000,
        "value": 139.292,
        "_internal_originalTime": 1689816207000
    },
    {
        "time": 1689816208000,
        "value": 139.288,
        "_internal_originalTime": 1689816208000
    },
    {
        "time": 1689816210000,
        "value": 139.291,
        "_internal_originalTime": 1689816210000
    },
    {
        "time": 1689816212000,
        "value": 139.288,
        "_internal_originalTime": 1689816212000
    },
    {
        "time": 1689816213000,
        "value": 139.292,
        "_internal_originalTime": 1689816213000
    },
    {
        "time": 1689816215000,
        "value": 139.288,
        "_internal_originalTime": 1689816215000
    },
    {
        "time": 1689816216000,
        "value": 139.289,
        "_internal_originalTime": 1689816216000
    },
    {
        "time": 1689816220000,
        "value": 139.292,
        "_internal_originalTime": 1689816220000
    },
    {
        "time": 1689816222000,
        "value": 139.289,
        "_internal_originalTime": 1689816222000
    },
    {
        "time": 1689816224000,
        "value": 139.284,
        "_internal_originalTime": 1689816224000
    },
    {
        "time": 1689816226000,
        "value": 139.287,
        "_internal_originalTime": 1689816226000
    },
    {
        "time": 1689816228000,
        "value": 139.291,
        "_internal_originalTime": 1689816228000
    },
    {
        "time": 1689816230000,
        "value": 139.27,
        "_internal_originalTime": 1689816230000
    },
    {
        "time": 1689816232000,
        "value": 139.279,
        "_internal_originalTime": 1689816232000
    },
    {
        "time": 1689816234000,
        "value": 139.275,
        "_internal_originalTime": 1689816234000
    },
    {
        "time": 1689816236000,
        "value": 139.271,
        "_internal_originalTime": 1689816236000
    },
    {
        "time": 1689816237000,
        "value": 139.267,
        "_internal_originalTime": 1689816237000
    },
    {
        "time": 1689816240000,
        "value": 139.275,
        "_internal_originalTime": 1689816240000
    },
    {
        "time": 1689816241000,
        "value": 139.27,
        "_internal_originalTime": 1689816241000
    },
    {
        "time": 1689816243000,
        "value": 139.273,
        "_internal_originalTime": 1689816243000
    },
    {
        "time": 1689816244000,
        "value": 139.273,
        "_internal_originalTime": 1689816244000
    },
    {
        "time": 1689816245000,
        "value": 139.271,
        "_internal_originalTime": 1689816245000
    },
    {
        "time": 1689816247000,
        "value": 139.274,
        "_internal_originalTime": 1689816247000
    },
    {
        "time": 1689816249000,
        "value": 139.281,
        "_internal_originalTime": 1689816249000
    },
    {
        "time": 1689816251000,
        "value": 139.271,
        "_internal_originalTime": 1689816251000
    },
    {
        "time": 1689816253000,
        "value": 139.263,
        "_internal_originalTime": 1689816253000
    },
    {
        "time": 1689816255000,
        "value": 139.268,
        "_internal_originalTime": 1689816255000
    },
    {
        "time": 1689816256000,
        "value": 139.268,
        "_internal_originalTime": 1689816256000
    },
    {
        "time": 1689816258000,
        "value": 139.263,
        "_internal_originalTime": 1689816258000
    },
    {
        "time": 1689816260000,
        "value": 139.266,
        "_internal_originalTime": 1689816260000
    },
    {
        "time": 1689816261000,
        "value": 139.264,
        "_internal_originalTime": 1689816261000
    },
    {
        "time": 1689816263000,
        "value": 139.263,
        "_internal_originalTime": 1689816263000
    },
    {
        "time": 1689816267000,
        "value": 139.269,
        "_internal_originalTime": 1689816267000
    },
    {
        "time": 1689816270000,
        "value": 139.262,
        "_internal_originalTime": 1689816270000
    },
    {
        "time": 1689816271000,
        "value": 139.255,
        "_internal_originalTime": 1689816271000
    },
    {
        "time": 1689816273000,
        "value": 139.253,
        "_internal_originalTime": 1689816273000
    },
    {
        "time": 1689816275000,
        "value": 139.254,
        "_internal_originalTime": 1689816275000
    },
    {
        "time": 1689816278000,
        "value": 139.258,
        "_internal_originalTime": 1689816278000
    },
    {
        "time": 1689816279000,
        "value": 139.261,
        "_internal_originalTime": 1689816279000
    },
    {
        "time": 1689816281000,
        "value": 139.261,
        "_internal_originalTime": 1689816281000
    },
    {
        "time": 1689816285000,
        "value": 139.255,
        "_internal_originalTime": 1689816285000
    },
    {
        "time": 1689816286000,
        "value": 139.248,
        "_internal_originalTime": 1689816286000
    },
    {
        "time": 1689816287000,
        "value": 139.255,
        "_internal_originalTime": 1689816287000
    },
    {
        "time": 1689816289000,
        "value": 139.249,
        "_internal_originalTime": 1689816289000
    },
    {
        "time": 1689816291000,
        "value": 139.254,
        "_internal_originalTime": 1689816291000
    },
    {
        "time": 1689816294000,
        "value": 139.251,
        "_internal_originalTime": 1689816294000
    },
    {
        "time": 1689816296000,
        "value": 139.256,
        "_internal_originalTime": 1689816296000
    },
    {
        "time": 1689816297000,
        "value": 139.255,
        "_internal_originalTime": 1689816297000
    },
    {
        "time": 1689816300000,
        "value": 139.252,
        "_internal_originalTime": 1689816300000
    },
    {
        "time": 1689816301000,
        "value": 139.258,
        "_internal_originalTime": 1689816301000
    },
    {
        "time": 1689816303000,
        "value": 139.256,
        "_internal_originalTime": 1689816303000
    },
    {
        "time": 1689816304000,
        "value": 139.256,
        "_internal_originalTime": 1689816304000
    },
    {
        "time": 1689816306000,
        "value": 139.26,
        "_internal_originalTime": 1689816306000
    },
    {
        "time": 1689816307000,
        "value": 139.267,
        "_internal_originalTime": 1689816307000
    },
    {
        "time": 1689816309000,
        "value": 139.268,
        "_internal_originalTime": 1689816309000
    },
    {
        "time": 1689816311000,
        "value": 139.268,
        "_internal_originalTime": 1689816311000
    },
    {
        "time": 1689816313000,
        "value": 139.267,
        "_internal_originalTime": 1689816313000
    },
    {
        "time": 1689816315000,
        "value": 139.272,
        "_internal_originalTime": 1689816315000
    },
    {
        "time": 1689816317000,
        "value": 139.28,
        "_internal_originalTime": 1689816317000
    },
    {
        "time": 1689816319000,
        "value": 139.273,
        "_internal_originalTime": 1689816319000
    },
    {
        "time": 1689816321000,
        "value": 139.272,
        "_internal_originalTime": 1689816321000
    },
    {
        "time": 1689816323000,
        "value": 139.274,
        "_internal_originalTime": 1689816323000
    },
    {
        "time": 1689816324000,
        "value": 139.268,
        "_internal_originalTime": 1689816324000
    },
    {
        "time": 1689816326000,
        "value": 139.268,
        "_internal_originalTime": 1689816326000
    },
    {
        "time": 1689816328000,
        "value": 139.267,
        "_internal_originalTime": 1689816328000
    },
    {
        "time": 1689816329000,
        "value": 139.268,
        "_internal_originalTime": 1689816329000
    },
    {
        "time": 1689816330000,
        "value": 139.267,
        "_internal_originalTime": 1689816330000
    },
    {
        "time": 1689816332000,
        "value": 139.268,
        "_internal_originalTime": 1689816332000
    },
    {
        "time": 1689816334000,
        "value": 139.271,
        "_internal_originalTime": 1689816334000
    },
    {
        "time": 1689816337000,
        "value": 139.274,
        "_internal_originalTime": 1689816337000
    },
    {
        "time": 1689816339000,
        "value": 139.271,
        "_internal_originalTime": 1689816339000
    },
    {
        "time": 1689816341000,
        "value": 139.269,
        "_internal_originalTime": 1689816341000
    },
    {
        "time": 1689816343000,
        "value": 139.27,
        "_internal_originalTime": 1689816343000
    },
    {
        "time": 1689816345000,
        "value": 139.271,
        "_internal_originalTime": 1689816345000
    },
    {
        "time": 1689816346000,
        "value": 139.272,
        "_internal_originalTime": 1689816346000
    },
    {
        "time": 1689816347000,
        "value": 139.272,
        "_internal_originalTime": 1689816347000
    },
    {
        "time": 1689816349000,
        "value": 139.268,
        "_internal_originalTime": 1689816349000
    },
    {
        "time": 1689816351000,
        "value": 139.267,
        "_internal_originalTime": 1689816351000
    },
    {
        "time": 1689816353000,
        "value": 139.253,
        "_internal_originalTime": 1689816353000
    },
    {
        "time": 1689816355000,
        "value": 139.25,
        "_internal_originalTime": 1689816355000
    },
    {
        "time": 1689816358000,
        "value": 139.257,
        "_internal_originalTime": 1689816358000
    },
    {
        "time": 1689816359000,
        "value": 139.25,
        "_internal_originalTime": 1689816359000
    },
    {
        "time": 1689816360000,
        "value": 139.253,
        "_internal_originalTime": 1689816360000
    },
    {
        "time": 1689816362000,
        "value": 139.25,
        "_internal_originalTime": 1689816362000
    },
    {
        "time": 1689816364000,
        "value": 139.256,
        "_internal_originalTime": 1689816364000
    },
    {
        "time": 1689816365000,
        "value": 139.256,
        "_internal_originalTime": 1689816365000
    },
    {
        "time": 1689816367000,
        "value": 139.25,
        "_internal_originalTime": 1689816367000
    },
    {
        "time": 1689816368000,
        "value": 139.253,
        "_internal_originalTime": 1689816368000
    },
    {
        "time": 1689816370000,
        "value": 139.253,
        "_internal_originalTime": 1689816370000
    },
    {
        "time": 1689816374000,
        "value": 139.254,
        "_internal_originalTime": 1689816374000
    },
    {
        "time": 1689816376000,
        "value": 139.256,
        "_internal_originalTime": 1689816376000
    },
    {
        "time": 1689816378000,
        "value": 139.256,
        "_internal_originalTime": 1689816378000
    },
    {
        "time": 1689816379000,
        "value": 139.254,
        "_internal_originalTime": 1689816379000
    },
    {
        "time": 1689816381000,
        "value": 139.254,
        "_internal_originalTime": 1689816381000
    },
    {
        "time": 1689816383000,
        "value": 139.254,
        "_internal_originalTime": 1689816383000
    },
    {
        "time": 1689816384000,
        "value": 139.25,
        "_internal_originalTime": 1689816384000
    },
    {
        "time": 1689816385000,
        "value": 139.25,
        "_internal_originalTime": 1689816385000
    },
    {
        "time": 1689816391000,
        "value": 139.257,
        "_internal_originalTime": 1689816391000
    },
    {
        "time": 1689816392000,
        "value": 139.258,
        "_internal_originalTime": 1689816392000
    },
    {
        "time": 1689816394000,
        "value": 139.251,
        "_internal_originalTime": 1689816394000
    },
    {
        "time": 1689816396000,
        "value": 139.254,
        "_internal_originalTime": 1689816396000
    },
    {
        "time": 1689816399000,
        "value": 139.262,
        "_internal_originalTime": 1689816399000
    },
    {
        "time": 1689816402000,
        "value": 139.258,
        "_internal_originalTime": 1689816402000
    },
    {
        "time": 1689816404000,
        "value": 139.263,
        "_internal_originalTime": 1689816404000
    },
    {
        "time": 1689816406000,
        "value": 139.268,
        "_internal_originalTime": 1689816406000
    },
    {
        "time": 1689816407000,
        "value": 139.268,
        "_internal_originalTime": 1689816407000
    },
    {
        "time": 1689816409000,
        "value": 139.265,
        "_internal_originalTime": 1689816409000
    },
    {
        "time": 1689816410000,
        "value": 139.268,
        "_internal_originalTime": 1689816410000
    },
    {
        "time": 1689816412000,
        "value": 139.271,
        "_internal_originalTime": 1689816412000
    },
    {
        "time": 1689816414000,
        "value": 139.267,
        "_internal_originalTime": 1689816414000
    },
    {
        "time": 1689816416000,
        "value": 139.272,
        "_internal_originalTime": 1689816416000
    },
    {
        "time": 1689816418000,
        "value": 139.276,
        "_internal_originalTime": 1689816418000
    },
    {
        "time": 1689816419000,
        "value": 139.274,
        "_internal_originalTime": 1689816419000
    },
    {
        "time": 1689816421000,
        "value": 139.272,
        "_internal_originalTime": 1689816421000
    },
    {
        "time": 1689816422000,
        "value": 139.274,
        "_internal_originalTime": 1689816422000
    },
    {
        "time": 1689816424000,
        "value": 139.276,
        "_internal_originalTime": 1689816424000
    },
    {
        "time": 1689816428000,
        "value": 139.281,
        "_internal_originalTime": 1689816428000
    },
    {
        "time": 1689816430000,
        "value": 139.263,
        "_internal_originalTime": 1689816430000
    },
    {
        "time": 1689816431000,
        "value": 139.267,
        "_internal_originalTime": 1689816431000
    },
    {
        "time": 1689816433000,
        "value": 139.269,
        "_internal_originalTime": 1689816433000
    },
    {
        "time": 1689816435000,
        "value": 139.278,
        "_internal_originalTime": 1689816435000
    },
    {
        "time": 1689816437000,
        "value": 139.275,
        "_internal_originalTime": 1689816437000
    },
    {
        "time": 1689816439000,
        "value": 139.272,
        "_internal_originalTime": 1689816439000
    },
    {
        "time": 1689816440000,
        "value": 139.269,
        "_internal_originalTime": 1689816440000
    },
    {
        "time": 1689816442000,
        "value": 139.262,
        "_internal_originalTime": 1689816442000
    },
    {
        "time": 1689816444000,
        "value": 139.258,
        "_internal_originalTime": 1689816444000
    },
    {
        "time": 1689816446000,
        "value": 139.257,
        "_internal_originalTime": 1689816446000
    },
    {
        "time": 1689816447000,
        "value": 139.259,
        "_internal_originalTime": 1689816447000
    },
    {
        "time": 1689816448000,
        "value": 139.26,
        "_internal_originalTime": 1689816448000
    },
    {
        "time": 1689816450000,
        "value": 139.258,
        "_internal_originalTime": 1689816450000
    },
    {
        "time": 1689816452000,
        "value": 139.263,
        "_internal_originalTime": 1689816452000
    },
    {
        "time": 1689816454000,
        "value": 139.26,
        "_internal_originalTime": 1689816454000
    },
    {
        "time": 1689816456000,
        "value": 139.25,
        "_internal_originalTime": 1689816456000
    },
    {
        "time": 1689816457000,
        "value": 139.253,
        "_internal_originalTime": 1689816457000
    },
    {
        "time": 1689816461000,
        "value": 139.242,
        "_internal_originalTime": 1689816461000
    },
    {
        "time": 1689816462000,
        "value": 139.251,
        "_internal_originalTime": 1689816462000
    },
    {
        "time": 1689816466000,
        "value": 139.252,
        "_internal_originalTime": 1689816466000
    },
    {
        "time": 1689816467000,
        "value": 139.251,
        "_internal_originalTime": 1689816467000
    },
    {
        "time": 1689816469000,
        "value": 139.24,
        "_internal_originalTime": 1689816469000
    },
    {
        "time": 1689816471000,
        "value": 139.238,
        "_internal_originalTime": 1689816471000
    },
    {
        "time": 1689816473000,
        "value": 139.24,
        "_internal_originalTime": 1689816473000
    },
    {
        "time": 1689816476000,
        "value": 139.242,
        "_internal_originalTime": 1689816476000
    },
    {
        "time": 1689816478000,
        "value": 139.244,
        "_internal_originalTime": 1689816478000
    },
    {
        "time": 1689816479000,
        "value": 139.242,
        "_internal_originalTime": 1689816479000
    },
    {
        "time": 1689816481000,
        "value": 139.24,
        "_internal_originalTime": 1689816481000
    },
    {
        "time": 1689816483000,
        "value": 139.248,
        "_internal_originalTime": 1689816483000
    },
    {
        "time": 1689816484000,
        "value": 139.248,
        "_internal_originalTime": 1689816484000
    },
    {
        "time": 1689816486000,
        "value": 139.251,
        "_internal_originalTime": 1689816486000
    },
    {
        "time": 1689816488000,
        "value": 139.248,
        "_internal_originalTime": 1689816488000
    },
    {
        "time": 1689816490000,
        "value": 139.252,
        "_internal_originalTime": 1689816490000
    },
    {
        "time": 1689816491000,
        "value": 139.251,
        "_internal_originalTime": 1689816491000
    },
    {
        "time": 1689816494000,
        "value": 139.248,
        "_internal_originalTime": 1689816494000
    },
    {
        "time": 1689816495000,
        "value": 139.244,
        "_internal_originalTime": 1689816495000
    },
    {
        "time": 1689816496000,
        "value": 139.251,
        "_internal_originalTime": 1689816496000
    },
    {
        "time": 1689816499000,
        "value": 139.241,
        "_internal_originalTime": 1689816499000
    },
    {
        "time": 1689816501000,
        "value": 139.238,
        "_internal_originalTime": 1689816501000
    },
    {
        "time": 1689816503000,
        "value": 139.245,
        "_internal_originalTime": 1689816503000
    },
    {
        "time": 1689816505000,
        "value": 139.24,
        "_internal_originalTime": 1689816505000
    },
    {
        "time": 1689816507000,
        "value": 139.246,
        "_internal_originalTime": 1689816507000
    },
    {
        "time": 1689816508000,
        "value": 139.232,
        "_internal_originalTime": 1689816508000
    },
    {
        "time": 1689816509000,
        "value": 139.233,
        "_internal_originalTime": 1689816509000
    },
    {
        "time": 1689816511000,
        "value": 139.229,
        "_internal_originalTime": 1689816511000
    },
    {
        "time": 1689816512000,
        "value": 139.226,
        "_internal_originalTime": 1689816512000
    },
    {
        "time": 1689816514000,
        "value": 139.212,
        "_internal_originalTime": 1689816514000
    },
    {
        "time": 1689816515000,
        "value": 139.215,
        "_internal_originalTime": 1689816515000
    },
    {
        "time": 1689816517000,
        "value": 139.208,
        "_internal_originalTime": 1689816517000
    },
    {
        "time": 1689816518000,
        "value": 139.213,
        "_internal_originalTime": 1689816518000
    },
    {
        "time": 1689816520000,
        "value": 139.204,
        "_internal_originalTime": 1689816520000
    },
    {
        "time": 1689816521000,
        "value": 139.209,
        "_internal_originalTime": 1689816521000
    },
    {
        "time": 1689816522000,
        "value": 139.215,
        "_internal_originalTime": 1689816522000
    },
    {
        "time": 1689816524000,
        "value": 139.213,
        "_internal_originalTime": 1689816524000
    },
    {
        "time": 1689816525000,
        "value": 139.204,
        "_internal_originalTime": 1689816525000
    },
    {
        "time": 1689816526000,
        "value": 139.21,
        "_internal_originalTime": 1689816526000
    },
    {
        "time": 1689816527000,
        "value": 139.21,
        "_internal_originalTime": 1689816527000
    },
    {
        "time": 1689816529000,
        "value": 139.215,
        "_internal_originalTime": 1689816529000
    },
    {
        "time": 1689816531000,
        "value": 139.213,
        "_internal_originalTime": 1689816531000
    },
    {
        "time": 1689816533000,
        "value": 139.214,
        "_internal_originalTime": 1689816533000
    },
    {
        "time": 1689816535000,
        "value": 139.21,
        "_internal_originalTime": 1689816535000
    },
    {
        "time": 1689816538000,
        "value": 139.205,
        "_internal_originalTime": 1689816538000
    },
    {
        "time": 1689816540000,
        "value": 139.211,
        "_internal_originalTime": 1689816540000
    },
    {
        "time": 1689816541000,
        "value": 139.204,
        "_internal_originalTime": 1689816541000
    },
    {
        "time": 1689816542000,
        "value": 139.205,
        "_internal_originalTime": 1689816542000
    },
    {
        "time": 1689816544000,
        "value": 139.207,
        "_internal_originalTime": 1689816544000
    },
    {
        "time": 1689816546000,
        "value": 139.207,
        "_internal_originalTime": 1689816546000
    },
    {
        "time": 1689816547000,
        "value": 139.208,
        "_internal_originalTime": 1689816547000
    },
    {
        "time": 1689816549000,
        "value": 139.212,
        "_internal_originalTime": 1689816549000
    },
    {
        "time": 1689816551000,
        "value": 139.211,
        "_internal_originalTime": 1689816551000
    },
    {
        "time": 1689816554000,
        "value": 139.226,
        "_internal_originalTime": 1689816554000
    },
    {
        "time": 1689816555000,
        "value": 139.225,
        "_internal_originalTime": 1689816555000
    },
    {
        "time": 1689816557000,
        "value": 139.23,
        "_internal_originalTime": 1689816557000
    },
    {
        "time": 1689816559000,
        "value": 139.23,
        "_internal_originalTime": 1689816559000
    },
    {
        "time": 1689816561000,
        "value": 139.233,
        "_internal_originalTime": 1689816561000
    },
    {
        "time": 1689816563000,
        "value": 139.232,
        "_internal_originalTime": 1689816563000
    },
    {
        "time": 1689816567000,
        "value": 139.232,
        "_internal_originalTime": 1689816567000
    },
    {
        "time": 1689816568000,
        "value": 139.228,
        "_internal_originalTime": 1689816568000
    },
    {
        "time": 1689816570000,
        "value": 139.231,
        "_internal_originalTime": 1689816570000
    },
    {
        "time": 1689816571000,
        "value": 139.23,
        "_internal_originalTime": 1689816571000
    },
    {
        "time": 1689816572000,
        "value": 139.232,
        "_internal_originalTime": 1689816572000
    },
    {
        "time": 1689816574000,
        "value": 139.233,
        "_internal_originalTime": 1689816574000
    },
    {
        "time": 1689816576000,
        "value": 139.228,
        "_internal_originalTime": 1689816576000
    },
    {
        "time": 1689816577000,
        "value": 139.233,
        "_internal_originalTime": 1689816577000
    },
    {
        "time": 1689816580000,
        "value": 139.228,
        "_internal_originalTime": 1689816580000
    },
    {
        "time": 1689816582000,
        "value": 139.222,
        "_internal_originalTime": 1689816582000
    },
    {
        "time": 1689816585000,
        "value": 139.223,
        "_internal_originalTime": 1689816585000
    },
    {
        "time": 1689816586000,
        "value": 139.226,
        "_internal_originalTime": 1689816586000
    },
    {
        "time": 1689816588000,
        "value": 139.234,
        "_internal_originalTime": 1689816588000
    },
    {
        "time": 1689816590000,
        "value": 139.23,
        "_internal_originalTime": 1689816590000
    },
    {
        "time": 1689816591000,
        "value": 139.231,
        "_internal_originalTime": 1689816591000
    },
    {
        "time": 1689816592000,
        "value": 139.23,
        "_internal_originalTime": 1689816592000
    },
    {
        "time": 1689816596000,
        "value": 139.234,
        "_internal_originalTime": 1689816596000
    },
    {
        "time": 1689816598000,
        "value": 139.226,
        "_internal_originalTime": 1689816598000
    },
    {
        "time": 1689816599000,
        "value": 139.228,
        "_internal_originalTime": 1689816599000
    },
    {
        "time": 1689816601000,
        "value": 139.231,
        "_internal_originalTime": 1689816601000
    },
    {
        "time": 1689816602000,
        "value": 139.235,
        "_internal_originalTime": 1689816602000
    },
    {
        "time": 1689816604000,
        "value": 139.249,
        "_internal_originalTime": 1689816604000
    },
    {
        "time": 1689816605000,
        "value": 139.248,
        "_internal_originalTime": 1689816605000
    },
    {
        "time": 1689816607000,
        "value": 139.247,
        "_internal_originalTime": 1689816607000
    },
    {
        "time": 1689816609000,
        "value": 139.246,
        "_internal_originalTime": 1689816609000
    },
    {
        "time": 1689816610000,
        "value": 139.25,
        "_internal_originalTime": 1689816610000
    },
    {
        "time": 1689816612000,
        "value": 139.262,
        "_internal_originalTime": 1689816612000
    },
    {
        "time": 1689816614000,
        "value": 139.256,
        "_internal_originalTime": 1689816614000
    },
    {
        "time": 1689816615000,
        "value": 139.249,
        "_internal_originalTime": 1689816615000
    },
    {
        "time": 1689816617000,
        "value": 139.264,
        "_internal_originalTime": 1689816617000
    },
    {
        "time": 1689816619000,
        "value": 139.277,
        "_internal_originalTime": 1689816619000
    },
    {
        "time": 1689816620000,
        "value": 139.275,
        "_internal_originalTime": 1689816620000
    },
    {
        "time": 1689816622000,
        "value": 139.274,
        "_internal_originalTime": 1689816622000
    },
    {
        "time": 1689816624000,
        "value": 139.285,
        "_internal_originalTime": 1689816624000
    },
    {
        "time": 1689816626000,
        "value": 139.289,
        "_internal_originalTime": 1689816626000
    },
    {
        "time": 1689816627000,
        "value": 139.298,
        "_internal_originalTime": 1689816627000
    },
    {
        "time": 1689816628000,
        "value": 139.29,
        "_internal_originalTime": 1689816628000
    },
    {
        "time": 1689816630000,
        "value": 139.293,
        "_internal_originalTime": 1689816630000
    },
    {
        "time": 1689816631000,
        "value": 139.301,
        "_internal_originalTime": 1689816631000
    },
    {
        "time": 1689816632000,
        "value": 139.298,
        "_internal_originalTime": 1689816632000
    },
    {
        "time": 1689816634000,
        "value": 139.3,
        "_internal_originalTime": 1689816634000
    },
    {
        "time": 1689816637000,
        "value": 139.294,
        "_internal_originalTime": 1689816637000
    },
    {
        "time": 1689816639000,
        "value": 139.295,
        "_internal_originalTime": 1689816639000
    },
    {
        "time": 1689816641000,
        "value": 139.29,
        "_internal_originalTime": 1689816641000
    },
    {
        "time": 1689816643000,
        "value": 139.29,
        "_internal_originalTime": 1689816643000
    },
    {
        "time": 1689816644000,
        "value": 139.299,
        "_internal_originalTime": 1689816644000
    },
    {
        "time": 1689816668000,
        "value": 139.298,
        "_internal_originalTime": 1689816668000
    },
    {
        "time": 1689816670000,
        "value": 139.298,
        "_internal_originalTime": 1689816670000
    },
    {
        "time": 1689816672000,
        "value": 139.298,
        "_internal_originalTime": 1689816672000
    },
    {
        "time": 1689816674000,
        "value": 139.294,
        "_internal_originalTime": 1689816674000
    },
    {
        "time": 1689816677000,
        "value": 139.289,
        "_internal_originalTime": 1689816677000
    },
    {
        "time": 1689816679000,
        "value": 139.286,
        "_internal_originalTime": 1689816679000
    },
    {
        "time": 1689816680000,
        "value": 139.279,
        "_internal_originalTime": 1689816680000
    },
    {
        "time": 1689816682000,
        "value": 139.273,
        "_internal_originalTime": 1689816682000
    },
    {
        "time": 1689816684000,
        "value": 139.277,
        "_internal_originalTime": 1689816684000
    },
    {
        "time": 1689816686000,
        "value": 139.274,
        "_internal_originalTime": 1689816686000
    },
    {
        "time": 1689816688000,
        "value": 139.276,
        "_internal_originalTime": 1689816688000
    },
    {
        "time": 1689816690000,
        "value": 139.278,
        "_internal_originalTime": 1689816690000
    },
    {
        "time": 1689816692000,
        "value": 139.277,
        "_internal_originalTime": 1689816692000
    },
    {
        "time": 1689816695000,
        "value": 139.278,
        "_internal_originalTime": 1689816695000
    },
    {
        "time": 1689816697000,
        "value": 139.268,
        "_internal_originalTime": 1689816697000
    },
    {
        "time": 1689816698000,
        "value": 139.278,
        "_internal_originalTime": 1689816698000
    },
    {
        "time": 1689816701000,
        "value": 139.279,
        "_internal_originalTime": 1689816701000
    },
    {
        "time": 1689816702000,
        "value": 139.291,
        "_internal_originalTime": 1689816702000
    },
    {
        "time": 1689816703000,
        "value": 139.288,
        "_internal_originalTime": 1689816703000
    },
    {
        "time": 1689816705000,
        "value": 139.301,
        "_internal_originalTime": 1689816705000
    },
    {
        "time": 1689816706000,
        "value": 139.3,
        "_internal_originalTime": 1689816706000
    },
    {
        "time": 1689816708000,
        "value": 139.3,
        "_internal_originalTime": 1689816708000
    },
    {
        "time": 1689816710000,
        "value": 139.303,
        "_internal_originalTime": 1689816710000
    },
    {
        "time": 1689816712000,
        "value": 139.304,
        "_internal_originalTime": 1689816712000
    },
    {
        "time": 1689816714000,
        "value": 139.307,
        "_internal_originalTime": 1689816714000
    },
    {
        "time": 1689816716000,
        "value": 139.292,
        "_internal_originalTime": 1689816716000
    },
    {
        "time": 1689816718000,
        "value": 139.296,
        "_internal_originalTime": 1689816718000
    },
    {
        "time": 1689816720000,
        "value": 139.293,
        "_internal_originalTime": 1689816720000
    },
    {
        "time": 1689816721000,
        "value": 139.29,
        "_internal_originalTime": 1689816721000
    },
    {
        "time": 1689816723000,
        "value": 139.298,
        "_internal_originalTime": 1689816723000
    },
    {
        "time": 1689816725000,
        "value": 139.302,
        "_internal_originalTime": 1689816725000
    },
    {
        "time": 1689816726000,
        "value": 139.303,
        "_internal_originalTime": 1689816726000
    },
    {
        "time": 1689816728000,
        "value": 139.304,
        "_internal_originalTime": 1689816728000
    },
    {
        "time": 1689816730000,
        "value": 139.3,
        "_internal_originalTime": 1689816730000
    },
    {
        "time": 1689816732000,
        "value": 139.308,
        "_internal_originalTime": 1689816732000
    },
    {
        "time": 1689816734000,
        "value": 139.311,
        "_internal_originalTime": 1689816734000
    },
    {
        "time": 1689816735000,
        "value": 139.31,
        "_internal_originalTime": 1689816735000
    },
    {
        "time": 1689816738000,
        "value": 139.309,
        "_internal_originalTime": 1689816738000
    },
    {
        "time": 1689816739000,
        "value": 139.298,
        "_internal_originalTime": 1689816739000
    },
    {
        "time": 1689816741000,
        "value": 139.305,
        "_internal_originalTime": 1689816741000
    },
    {
        "time": 1689816743000,
        "value": 139.301,
        "_internal_originalTime": 1689816743000
    },
    {
        "time": 1689816744000,
        "value": 139.303,
        "_internal_originalTime": 1689816744000
    },
    {
        "time": 1689816746000,
        "value": 139.304,
        "_internal_originalTime": 1689816746000
    },
    {
        "time": 1689816748000,
        "value": 139.298,
        "_internal_originalTime": 1689816748000
    },
    {
        "time": 1689816751000,
        "value": 139.299,
        "_internal_originalTime": 1689816751000
    },
    {
        "time": 1689816753000,
        "value": 139.299,
        "_internal_originalTime": 1689816753000
    },
    {
        "time": 1689816755000,
        "value": 139.287,
        "_internal_originalTime": 1689816755000
    },
    {
        "time": 1689816757000,
        "value": 139.282,
        "_internal_originalTime": 1689816757000
    },
    {
        "time": 1689816759000,
        "value": 139.278,
        "_internal_originalTime": 1689816759000
    },
    {
        "time": 1689816761000,
        "value": 139.279,
        "_internal_originalTime": 1689816761000
    },
    {
        "time": 1689816763000,
        "value": 139.278,
        "_internal_originalTime": 1689816763000
    },
    {
        "time": 1689816764000,
        "value": 139.282,
        "_internal_originalTime": 1689816764000
    },
    {
        "time": 1689816765000,
        "value": 139.279,
        "_internal_originalTime": 1689816765000
    },
    {
        "time": 1689816768000,
        "value": 139.283,
        "_internal_originalTime": 1689816768000
    },
    {
        "time": 1689816769000,
        "value": 139.282,
        "_internal_originalTime": 1689816769000
    },
    {
        "time": 1689816772000,
        "value": 139.279,
        "_internal_originalTime": 1689816772000
    },
    {
        "time": 1689816773000,
        "value": 139.28,
        "_internal_originalTime": 1689816773000
    },
    {
        "time": 1689816775000,
        "value": 139.28,
        "_internal_originalTime": 1689816775000
    },
    {
        "time": 1689816778000,
        "value": 139.275,
        "_internal_originalTime": 1689816778000
    },
    {
        "time": 1689816780000,
        "value": 139.278,
        "_internal_originalTime": 1689816780000
    },
    {
        "time": 1689816781000,
        "value": 139.281,
        "_internal_originalTime": 1689816781000
    },
    {
        "time": 1689816783000,
        "value": 139.278,
        "_internal_originalTime": 1689816783000
    },
    {
        "time": 1689816784000,
        "value": 139.278,
        "_internal_originalTime": 1689816784000
    },
    {
        "time": 1689816786000,
        "value": 139.264,
        "_internal_originalTime": 1689816786000
    },
    {
        "time": 1689816788000,
        "value": 139.272,
        "_internal_originalTime": 1689816788000
    },
    {
        "time": 1689816790000,
        "value": 139.274,
        "_internal_originalTime": 1689816790000
    },
    {
        "time": 1689816792000,
        "value": 139.27,
        "_internal_originalTime": 1689816792000
    },
    {
        "time": 1689816794000,
        "value": 139.272,
        "_internal_originalTime": 1689816794000
    },
    {
        "time": 1689816796000,
        "value": 139.274,
        "_internal_originalTime": 1689816796000
    },
    {
        "time": 1689816797000,
        "value": 139.273,
        "_internal_originalTime": 1689816797000
    },
    {
        "time": 1689816798000,
        "value": 139.27,
        "_internal_originalTime": 1689816798000
    },
    {
        "time": 1689816801000,
        "value": 139.264,
        "_internal_originalTime": 1689816801000
    },
    {
        "time": 1689816802000,
        "value": 139.252,
        "_internal_originalTime": 1689816802000
    },
    {
        "time": 1689816804000,
        "value": 139.261,
        "_internal_originalTime": 1689816804000
    },
    {
        "time": 1689816806000,
        "value": 139.263,
        "_internal_originalTime": 1689816806000
    },
    {
        "time": 1689816808000,
        "value": 139.263,
        "_internal_originalTime": 1689816808000
    },
    {
        "time": 1689816810000,
        "value": 139.254,
        "_internal_originalTime": 1689816810000
    },
    {
        "time": 1689816811000,
        "value": 139.249,
        "_internal_originalTime": 1689816811000
    },
    {
        "time": 1689816812000,
        "value": 139.256,
        "_internal_originalTime": 1689816812000
    },
    {
        "time": 1689816814000,
        "value": 139.26,
        "_internal_originalTime": 1689816814000
    },
    {
        "time": 1689816816000,
        "value": 139.259,
        "_internal_originalTime": 1689816816000
    },
    {
        "time": 1689816818000,
        "value": 139.258,
        "_internal_originalTime": 1689816818000
    },
    {
        "time": 1689816820000,
        "value": 139.25,
        "_internal_originalTime": 1689816820000
    },
    {
        "time": 1689816821000,
        "value": 139.246,
        "_internal_originalTime": 1689816821000
    },
    {
        "time": 1689816823000,
        "value": 139.246,
        "_internal_originalTime": 1689816823000
    },
    {
        "time": 1689816825000,
        "value": 139.252,
        "_internal_originalTime": 1689816825000
    },
    {
        "time": 1689816826000,
        "value": 139.253,
        "_internal_originalTime": 1689816826000
    },
    {
        "time": 1689816828000,
        "value": 139.25,
        "_internal_originalTime": 1689816828000
    },
    {
        "time": 1689816830000,
        "value": 139.252,
        "_internal_originalTime": 1689816830000
    },
    {
        "time": 1689816832000,
        "value": 139.236,
        "_internal_originalTime": 1689816832000
    },
    {
        "time": 1689816833000,
        "value": 139.241,
        "_internal_originalTime": 1689816833000
    },
    {
        "time": 1689816836000,
        "value": 139.243,
        "_internal_originalTime": 1689816836000
    },
    {
        "time": 1689816838000,
        "value": 139.238,
        "_internal_originalTime": 1689816838000
    },
    {
        "time": 1689816839000,
        "value": 139.216,
        "_internal_originalTime": 1689816839000
    },
    {
        "time": 1689816840000,
        "value": 139.206,
        "_internal_originalTime": 1689816840000
    },
    {
        "time": 1689816841000,
        "value": 139.206,
        "_internal_originalTime": 1689816841000
    },
    {
        "time": 1689816842000,
        "value": 139.209,
        "_internal_originalTime": 1689816842000
    },
    {
        "time": 1689816843000,
        "value": 139.21,
        "_internal_originalTime": 1689816843000
    },
    {
        "time": 1689816845000,
        "value": 139.216,
        "_internal_originalTime": 1689816845000
    },
    {
        "time": 1689816846000,
        "value": 139.221,
        "_internal_originalTime": 1689816846000
    },
    {
        "time": 1689816848000,
        "value": 139.221,
        "_internal_originalTime": 1689816848000
    },
    {
        "time": 1689816850000,
        "value": 139.221,
        "_internal_originalTime": 1689816850000
    },
    {
        "time": 1689816852000,
        "value": 139.222,
        "_internal_originalTime": 1689816852000
    },
    {
        "time": 1689816854000,
        "value": 139.224,
        "_internal_originalTime": 1689816854000
    },
    {
        "time": 1689816855000,
        "value": 139.226,
        "_internal_originalTime": 1689816855000
    },
    {
        "time": 1689816857000,
        "value": 139.222,
        "_internal_originalTime": 1689816857000
    },
    {
        "time": 1689816859000,
        "value": 139.227,
        "_internal_originalTime": 1689816859000
    },
    {
        "time": 1689816861000,
        "value": 139.223,
        "_internal_originalTime": 1689816861000
    },
    {
        "time": 1689816863000,
        "value": 139.218,
        "_internal_originalTime": 1689816863000
    },
    {
        "time": 1689816864000,
        "value": 139.214,
        "_internal_originalTime": 1689816864000
    },
    {
        "time": 1689816866000,
        "value": 139.212,
        "_internal_originalTime": 1689816866000
    },
    {
        "time": 1689816868000,
        "value": 139.21,
        "_internal_originalTime": 1689816868000
    },
    {
        "time": 1689816869000,
        "value": 139.216,
        "_internal_originalTime": 1689816869000
    },
    {
        "time": 1689816871000,
        "value": 139.216,
        "_internal_originalTime": 1689816871000
    },
    {
        "time": 1689816872000,
        "value": 139.215,
        "_internal_originalTime": 1689816872000
    },
    {
        "time": 1689816875000,
        "value": 139.21,
        "_internal_originalTime": 1689816875000
    },
    {
        "time": 1689816876000,
        "value": 139.212,
        "_internal_originalTime": 1689816876000
    },
    {
        "time": 1689816879000,
        "value": 139.214,
        "_internal_originalTime": 1689816879000
    },
    {
        "time": 1689816882000,
        "value": 139.214,
        "_internal_originalTime": 1689816882000
    },
    {
        "time": 1689816884000,
        "value": 139.205,
        "_internal_originalTime": 1689816884000
    },
    {
        "time": 1689816885000,
        "value": 139.214,
        "_internal_originalTime": 1689816885000
    },
    {
        "time": 1689816887000,
        "value": 139.216,
        "_internal_originalTime": 1689816887000
    },
    {
        "time": 1689816888000,
        "value": 139.222,
        "_internal_originalTime": 1689816888000
    },
    {
        "time": 1689816890000,
        "value": 139.219,
        "_internal_originalTime": 1689816890000
    },
    {
        "time": 1689816892000,
        "value": 139.218,
        "_internal_originalTime": 1689816892000
    },
    {
        "time": 1689816894000,
        "value": 139.22,
        "_internal_originalTime": 1689816894000
    },
    {
        "time": 1689816895000,
        "value": 139.221,
        "_internal_originalTime": 1689816895000
    },
    {
        "time": 1689816898000,
        "value": 139.224,
        "_internal_originalTime": 1689816898000
    },
    {
        "time": 1689816900000,
        "value": 139.231,
        "_internal_originalTime": 1689816900000
    },
    {
        "time": 1689816901000,
        "value": 139.224,
        "_internal_originalTime": 1689816901000
    },
    {
        "time": 1689816903000,
        "value": 139.225,
        "_internal_originalTime": 1689816903000
    },
    {
        "time": 1689816904000,
        "value": 139.216,
        "_internal_originalTime": 1689816904000
    },
    {
        "time": 1689816906000,
        "value": 139.205,
        "_internal_originalTime": 1689816906000
    },
    {
        "time": 1689816908000,
        "value": 139.207,
        "_internal_originalTime": 1689816908000
    },
    {
        "time": 1689816909000,
        "value": 139.214,
        "_internal_originalTime": 1689816909000
    },
    {
        "time": 1689816911000,
        "value": 139.221,
        "_internal_originalTime": 1689816911000
    },
    {
        "time": 1689816914000,
        "value": 139.229,
        "_internal_originalTime": 1689816914000
    },
    {
        "time": 1689816915000,
        "value": 139.232,
        "_internal_originalTime": 1689816915000
    },
    {
        "time": 1689816917000,
        "value": 139.229,
        "_internal_originalTime": 1689816917000
    },
    {
        "time": 1689816919000,
        "value": 139.219,
        "_internal_originalTime": 1689816919000
    },
    {
        "time": 1689816921000,
        "value": 139.216,
        "_internal_originalTime": 1689816921000
    },
    {
        "time": 1689816923000,
        "value": 139.225,
        "_internal_originalTime": 1689816923000
    },
    {
        "time": 1689816924000,
        "value": 139.22,
        "_internal_originalTime": 1689816924000
    },
    {
        "time": 1689816925000,
        "value": 139.221,
        "_internal_originalTime": 1689816925000
    },
    {
        "time": 1689816927000,
        "value": 139.223,
        "_internal_originalTime": 1689816927000
    },
    {
        "time": 1689816928000,
        "value": 139.218,
        "_internal_originalTime": 1689816928000
    },
    {
        "time": 1689816930000,
        "value": 139.217,
        "_internal_originalTime": 1689816930000
    },
    {
        "time": 1689816931000,
        "value": 139.217,
        "_internal_originalTime": 1689816931000
    },
    {
        "time": 1689816934000,
        "value": 139.208,
        "_internal_originalTime": 1689816934000
    },
    {
        "time": 1689816936000,
        "value": 139.208,
        "_internal_originalTime": 1689816936000
    },
    {
        "time": 1689816938000,
        "value": 139.208,
        "_internal_originalTime": 1689816938000
    },
    {
        "time": 1689816940000,
        "value": 139.208,
        "_internal_originalTime": 1689816940000
    },
    {
        "time": 1689816942000,
        "value": 139.213,
        "_internal_originalTime": 1689816942000
    },
    {
        "time": 1689816943000,
        "value": 139.208,
        "_internal_originalTime": 1689816943000
    },
    {
        "time": 1689816945000,
        "value": 139.218,
        "_internal_originalTime": 1689816945000
    },
    {
        "time": 1689816946000,
        "value": 139.221,
        "_internal_originalTime": 1689816946000
    },
    {
        "time": 1689816948000,
        "value": 139.218,
        "_internal_originalTime": 1689816948000
    },
    {
        "time": 1689816950000,
        "value": 139.222,
        "_internal_originalTime": 1689816950000
    },
    {
        "time": 1689816951000,
        "value": 139.223,
        "_internal_originalTime": 1689816951000
    },
    {
        "time": 1689816953000,
        "value": 139.217,
        "_internal_originalTime": 1689816953000
    },
    {
        "time": 1689816955000,
        "value": 139.217,
        "_internal_originalTime": 1689816955000
    },
    {
        "time": 1689816957000,
        "value": 139.221,
        "_internal_originalTime": 1689816957000
    },
    {
        "time": 1689816959000,
        "value": 139.219,
        "_internal_originalTime": 1689816959000
    },
    {
        "time": 1689816960000,
        "value": 139.218,
        "_internal_originalTime": 1689816960000
    },
    {
        "time": 1689816961000,
        "value": 139.225,
        "_internal_originalTime": 1689816961000
    },
    {
        "time": 1689816963000,
        "value": 139.219,
        "_internal_originalTime": 1689816963000
    },
    {
        "time": 1689816964000,
        "value": 139.221,
        "_internal_originalTime": 1689816964000
    },
    {
        "time": 1689816966000,
        "value": 139.212,
        "_internal_originalTime": 1689816966000
    },
    {
        "time": 1689816967000,
        "value": 139.214,
        "_internal_originalTime": 1689816967000
    },
    {
        "time": 1689816969000,
        "value": 139.22,
        "_internal_originalTime": 1689816969000
    },
    {
        "time": 1689816971000,
        "value": 139.218,
        "_internal_originalTime": 1689816971000
    },
    {
        "time": 1689816974000,
        "value": 139.222,
        "_internal_originalTime": 1689816974000
    },
    {
        "time": 1689816975000,
        "value": 139.218,
        "_internal_originalTime": 1689816975000
    },
    {
        "time": 1689816977000,
        "value": 139.218,
        "_internal_originalTime": 1689816977000
    },
    {
        "time": 1689816979000,
        "value": 139.22,
        "_internal_originalTime": 1689816979000
    },
    {
        "time": 1689816981000,
        "value": 139.221,
        "_internal_originalTime": 1689816981000
    },
    {
        "time": 1689816982000,
        "value": 139.226,
        "_internal_originalTime": 1689816982000
    },
    {
        "time": 1689816983000,
        "value": 139.224,
        "_internal_originalTime": 1689816983000
    },
    {
        "time": 1689816985000,
        "value": 139.225,
        "_internal_originalTime": 1689816985000
    },
    {
        "time": 1689816987000,
        "value": 139.226,
        "_internal_originalTime": 1689816987000
    },
    {
        "time": 1689816989000,
        "value": 139.219,
        "_internal_originalTime": 1689816989000
    },
    {
        "time": 1689816990000,
        "value": 139.227,
        "_internal_originalTime": 1689816990000
    },
    {
        "time": 1689816992000,
        "value": 139.226,
        "_internal_originalTime": 1689816992000
    },
    {
        "time": 1689816994000,
        "value": 139.218,
        "_internal_originalTime": 1689816994000
    },
    {
        "time": 1689816996000,
        "value": 139.22,
        "_internal_originalTime": 1689816996000
    },
    {
        "time": 1689816999000,
        "value": 139.219,
        "_internal_originalTime": 1689816999000
    },
    {
        "time": 1689817000000,
        "value": 139.22,
        "_internal_originalTime": 1689817000000
    },
    {
        "time": 1689817002000,
        "value": 139.23,
        "_internal_originalTime": 1689817002000
    },
    {
        "time": 1689817003000,
        "value": 139.232,
        "_internal_originalTime": 1689817003000
    },
    {
        "time": 1689817005000,
        "value": 139.23,
        "_internal_originalTime": 1689817005000
    },
    {
        "time": 1689817006000,
        "value": 139.239,
        "_internal_originalTime": 1689817006000
    },
    {
        "time": 1689817008000,
        "value": 139.251,
        "_internal_originalTime": 1689817008000
    },
    {
        "time": 1689817009000,
        "value": 139.247,
        "_internal_originalTime": 1689817009000
    },
    {
        "time": 1689817012000,
        "value": 139.253,
        "_internal_originalTime": 1689817012000
    },
    {
        "time": 1689817013000,
        "value": 139.249,
        "_internal_originalTime": 1689817013000
    },
    {
        "time": 1689817015000,
        "value": 139.243,
        "_internal_originalTime": 1689817015000
    },
    {
        "time": 1689817017000,
        "value": 139.246,
        "_internal_originalTime": 1689817017000
    },
    {
        "time": 1689817019000,
        "value": 139.247,
        "_internal_originalTime": 1689817019000
    },
    {
        "time": 1689817020000,
        "value": 139.238,
        "_internal_originalTime": 1689817020000
    },
    {
        "time": 1689817021000,
        "value": 139.231,
        "_internal_originalTime": 1689817021000
    },
    {
        "time": 1689817023000,
        "value": 139.228,
        "_internal_originalTime": 1689817023000
    },
    {
        "time": 1689817024000,
        "value": 139.216,
        "_internal_originalTime": 1689817024000
    },
    {
        "time": 1689817026000,
        "value": 139.209,
        "_internal_originalTime": 1689817026000
    },
    {
        "time": 1689817028000,
        "value": 139.208,
        "_internal_originalTime": 1689817028000
    },
    {
        "time": 1689817030000,
        "value": 139.212,
        "_internal_originalTime": 1689817030000
    },
    {
        "time": 1689817032000,
        "value": 139.21,
        "_internal_originalTime": 1689817032000
    },
    {
        "time": 1689817033000,
        "value": 139.211,
        "_internal_originalTime": 1689817033000
    },
    {
        "time": 1689817035000,
        "value": 139.208,
        "_internal_originalTime": 1689817035000
    },
    {
        "time": 1689817037000,
        "value": 139.202,
        "_internal_originalTime": 1689817037000
    },
    {
        "time": 1689817039000,
        "value": 139.211,
        "_internal_originalTime": 1689817039000
    },
    {
        "time": 1689817040000,
        "value": 139.211,
        "_internal_originalTime": 1689817040000
    },
    {
        "time": 1689817043000,
        "value": 139.212,
        "_internal_originalTime": 1689817043000
    },
    {
        "time": 1689817044000,
        "value": 139.21,
        "_internal_originalTime": 1689817044000
    },
    {
        "time": 1689817046000,
        "value": 139.209,
        "_internal_originalTime": 1689817046000
    },
    {
        "time": 1689817047000,
        "value": 139.221,
        "_internal_originalTime": 1689817047000
    },
    {
        "time": 1689817049000,
        "value": 139.218,
        "_internal_originalTime": 1689817049000
    },
    {
        "time": 1689817051000,
        "value": 139.221,
        "_internal_originalTime": 1689817051000
    },
    {
        "time": 1689817052000,
        "value": 139.231,
        "_internal_originalTime": 1689817052000
    },
    {
        "time": 1689817054000,
        "value": 139.233,
        "_internal_originalTime": 1689817054000
    },
    {
        "time": 1689817056000,
        "value": 139.231,
        "_internal_originalTime": 1689817056000
    },
    {
        "time": 1689817058000,
        "value": 139.229,
        "_internal_originalTime": 1689817058000
    },
    {
        "time": 1689817060000,
        "value": 139.23,
        "_internal_originalTime": 1689817060000
    },
    {
        "time": 1689817062000,
        "value": 139.231,
        "_internal_originalTime": 1689817062000
    },
    {
        "time": 1689817063000,
        "value": 139.224,
        "_internal_originalTime": 1689817063000
    },
    {
        "time": 1689817065000,
        "value": 139.227,
        "_internal_originalTime": 1689817065000
    },
    {
        "time": 1689817066000,
        "value": 139.228,
        "_internal_originalTime": 1689817066000
    },
    {
        "time": 1689817067000,
        "value": 139.23,
        "_internal_originalTime": 1689817067000
    },
    {
        "time": 1689817070000,
        "value": 139.212,
        "_internal_originalTime": 1689817070000
    },
    {
        "time": 1689817074000,
        "value": 139.221,
        "_internal_originalTime": 1689817074000
    },
    {
        "time": 1689817075000,
        "value": 139.218,
        "_internal_originalTime": 1689817075000
    },
    {
        "time": 1689817077000,
        "value": 139.221,
        "_internal_originalTime": 1689817077000
    },
    {
        "time": 1689817079000,
        "value": 139.226,
        "_internal_originalTime": 1689817079000
    },
    {
        "time": 1689817081000,
        "value": 139.225,
        "_internal_originalTime": 1689817081000
    },
    {
        "time": 1689817082000,
        "value": 139.234,
        "_internal_originalTime": 1689817082000
    },
    {
        "time": 1689817084000,
        "value": 139.234,
        "_internal_originalTime": 1689817084000
    },
    {
        "time": 1689817086000,
        "value": 139.231,
        "_internal_originalTime": 1689817086000
    },
    {
        "time": 1689817088000,
        "value": 139.229,
        "_internal_originalTime": 1689817088000
    },
    {
        "time": 1689817090000,
        "value": 139.227,
        "_internal_originalTime": 1689817090000
    },
    {
        "time": 1689817092000,
        "value": 139.227,
        "_internal_originalTime": 1689817092000
    },
    {
        "time": 1689817094000,
        "value": 139.218,
        "_internal_originalTime": 1689817094000
    },
    {
        "time": 1689817095000,
        "value": 139.222,
        "_internal_originalTime": 1689817095000
    },
    {
        "time": 1689817097000,
        "value": 139.226,
        "_internal_originalTime": 1689817097000
    },
    {
        "time": 1689817099000,
        "value": 139.218,
        "_internal_originalTime": 1689817099000
    },
    {
        "time": 1689817101000,
        "value": 139.22,
        "_internal_originalTime": 1689817101000
    },
    {
        "time": 1689817103000,
        "value": 139.217,
        "_internal_originalTime": 1689817103000
    },
    {
        "time": 1689817104000,
        "value": 139.22,
        "_internal_originalTime": 1689817104000
    },
    {
        "time": 1689817107000,
        "value": 139.221,
        "_internal_originalTime": 1689817107000
    },
    {
        "time": 1689817108000,
        "value": 139.221,
        "_internal_originalTime": 1689817108000
    },
    {
        "time": 1689817110000,
        "value": 139.221,
        "_internal_originalTime": 1689817110000
    },
    {
        "time": 1689817111000,
        "value": 139.222,
        "_internal_originalTime": 1689817111000
    },
    {
        "time": 1689817113000,
        "value": 139.221,
        "_internal_originalTime": 1689817113000
    },
    {
        "time": 1689817115000,
        "value": 139.22,
        "_internal_originalTime": 1689817115000
    },
    {
        "time": 1689817117000,
        "value": 139.22,
        "_internal_originalTime": 1689817117000
    },
    {
        "time": 1689817119000,
        "value": 139.219,
        "_internal_originalTime": 1689817119000
    },
    {
        "time": 1689817121000,
        "value": 139.22,
        "_internal_originalTime": 1689817121000
    },
    {
        "time": 1689817122000,
        "value": 139.221,
        "_internal_originalTime": 1689817122000
    },
    {
        "time": 1689817125000,
        "value": 139.221,
        "_internal_originalTime": 1689817125000
    },
    {
        "time": 1689817126000,
        "value": 139.221,
        "_internal_originalTime": 1689817126000
    },
    {
        "time": 1689817127000,
        "value": 139.221,
        "_internal_originalTime": 1689817127000
    },
    {
        "time": 1689817130000,
        "value": 139.218,
        "_internal_originalTime": 1689817130000
    },
    {
        "time": 1689817132000,
        "value": 139.22,
        "_internal_originalTime": 1689817132000
    },
    {
        "time": 1689817134000,
        "value": 139.231,
        "_internal_originalTime": 1689817134000
    },
    {
        "time": 1689817135000,
        "value": 139.232,
        "_internal_originalTime": 1689817135000
    },
    {
        "time": 1689817138000,
        "value": 139.23,
        "_internal_originalTime": 1689817138000
    },
    {
        "time": 1689817139000,
        "value": 139.235,
        "_internal_originalTime": 1689817139000
    },
    {
        "time": 1689817140000,
        "value": 139.234,
        "_internal_originalTime": 1689817140000
    },
    {
        "time": 1689817141000,
        "value": 139.232,
        "_internal_originalTime": 1689817141000
    },
    {
        "time": 1689817143000,
        "value": 139.232,
        "_internal_originalTime": 1689817143000
    },
    {
        "time": 1689817145000,
        "value": 139.228,
        "_internal_originalTime": 1689817145000
    },
    {
        "time": 1689817146000,
        "value": 139.232,
        "_internal_originalTime": 1689817146000
    },
    {
        "time": 1689817149000,
        "value": 139.229,
        "_internal_originalTime": 1689817149000
    },
    {
        "time": 1689817151000,
        "value": 139.228,
        "_internal_originalTime": 1689817151000
    },
    {
        "time": 1689817153000,
        "value": 139.232,
        "_internal_originalTime": 1689817153000
    },
    {
        "time": 1689817155000,
        "value": 139.23,
        "_internal_originalTime": 1689817155000
    },
    {
        "time": 1689817156000,
        "value": 139.23,
        "_internal_originalTime": 1689817156000
    },
    {
        "time": 1689817158000,
        "value": 139.231,
        "_internal_originalTime": 1689817158000
    },
    {
        "time": 1689817160000,
        "value": 139.231,
        "_internal_originalTime": 1689817160000
    },
    {
        "time": 1689817162000,
        "value": 139.22,
        "_internal_originalTime": 1689817162000
    },
    {
        "time": 1689817163000,
        "value": 139.218,
        "_internal_originalTime": 1689817163000
    },
    {
        "time": 1689817166000,
        "value": 139.22,
        "_internal_originalTime": 1689817166000
    },
    {
        "time": 1689817168000,
        "value": 139.212,
        "_internal_originalTime": 1689817168000
    },
    {
        "time": 1689817171000,
        "value": 139.22,
        "_internal_originalTime": 1689817171000
    },
    {
        "time": 1689817172000,
        "value": 139.22,
        "_internal_originalTime": 1689817172000
    },
    {
        "time": 1689817174000,
        "value": 139.217,
        "_internal_originalTime": 1689817174000
    },
    {
        "time": 1689817175000,
        "value": 139.221,
        "_internal_originalTime": 1689817175000
    },
    {
        "time": 1689817179000,
        "value": 139.216,
        "_internal_originalTime": 1689817179000
    },
    {
        "time": 1689817181000,
        "value": 139.216,
        "_internal_originalTime": 1689817181000
    },
    {
        "time": 1689817182000,
        "value": 139.216,
        "_internal_originalTime": 1689817182000
    },
    {
        "time": 1689817184000,
        "value": 139.218,
        "_internal_originalTime": 1689817184000
    },
    {
        "time": 1689817186000,
        "value": 139.221,
        "_internal_originalTime": 1689817186000
    },
    {
        "time": 1689817187000,
        "value": 139.218,
        "_internal_originalTime": 1689817187000
    },
    {
        "time": 1689817189000,
        "value": 139.218,
        "_internal_originalTime": 1689817189000
    },
    {
        "time": 1689817191000,
        "value": 139.222,
        "_internal_originalTime": 1689817191000
    },
    {
        "time": 1689817193000,
        "value": 139.227,
        "_internal_originalTime": 1689817193000
    },
    {
        "time": 1689817195000,
        "value": 139.22,
        "_internal_originalTime": 1689817195000
    },
    {
        "time": 1689817197000,
        "value": 139.225,
        "_internal_originalTime": 1689817197000
    },
    {
        "time": 1689817198000,
        "value": 139.224,
        "_internal_originalTime": 1689817198000
    },
    {
        "time": 1689817200000,
        "value": 139.226,
        "_internal_originalTime": 1689817200000
    },
    {
        "time": 1689817202000,
        "value": 139.222,
        "_internal_originalTime": 1689817202000
    },
    {
        "time": 1689817204000,
        "value": 139.234,
        "_internal_originalTime": 1689817204000
    },
    {
        "time": 1689817205000,
        "value": 139.236,
        "_internal_originalTime": 1689817205000
    },
    {
        "time": 1689817207000,
        "value": 139.253,
        "_internal_originalTime": 1689817207000
    },
    {
        "time": 1689817208000,
        "value": 139.254,
        "_internal_originalTime": 1689817208000
    },
    {
        "time": 1689817210000,
        "value": 139.253,
        "_internal_originalTime": 1689817210000
    },
    {
        "time": 1689817213000,
        "value": 139.257,
        "_internal_originalTime": 1689817213000
    },
    {
        "time": 1689817214000,
        "value": 139.258,
        "_internal_originalTime": 1689817214000
    },
    {
        "time": 1689817215000,
        "value": 139.256,
        "_internal_originalTime": 1689817215000
    },
    {
        "time": 1689817218000,
        "value": 139.262,
        "_internal_originalTime": 1689817218000
    },
    {
        "time": 1689817220000,
        "value": 139.266,
        "_internal_originalTime": 1689817220000
    },
    {
        "time": 1689817221000,
        "value": 139.262,
        "_internal_originalTime": 1689817221000
    },
    {
        "time": 1689817223000,
        "value": 139.264,
        "_internal_originalTime": 1689817223000
    },
    {
        "time": 1689817225000,
        "value": 139.264,
        "_internal_originalTime": 1689817225000
    },
    {
        "time": 1689817227000,
        "value": 139.248,
        "_internal_originalTime": 1689817227000
    },
    {
        "time": 1689817228000,
        "value": 139.246,
        "_internal_originalTime": 1689817228000
    },
    {
        "time": 1689817229000,
        "value": 139.247,
        "_internal_originalTime": 1689817229000
    },
    {
        "time": 1689817231000,
        "value": 139.252,
        "_internal_originalTime": 1689817231000
    },
    {
        "time": 1689817232000,
        "value": 139.246,
        "_internal_originalTime": 1689817232000
    },
    {
        "time": 1689817233000,
        "value": 139.248,
        "_internal_originalTime": 1689817233000
    },
    {
        "time": 1689817235000,
        "value": 139.264,
        "_internal_originalTime": 1689817235000
    },
    {
        "time": 1689817236000,
        "value": 139.26,
        "_internal_originalTime": 1689817236000
    },
    {
        "time": 1689817237000,
        "value": 139.264,
        "_internal_originalTime": 1689817237000
    },
    {
        "time": 1689817238000,
        "value": 139.264,
        "_internal_originalTime": 1689817238000
    },
    {
        "time": 1689817239000,
        "value": 139.26,
        "_internal_originalTime": 1689817239000
    },
    {
        "time": 1689817241000,
        "value": 139.256,
        "_internal_originalTime": 1689817241000
    },
    {
        "time": 1689817242000,
        "value": 139.254,
        "_internal_originalTime": 1689817242000
    },
    {
        "time": 1689817243000,
        "value": 139.253,
        "_internal_originalTime": 1689817243000
    },
    {
        "time": 1689817244000,
        "value": 139.252,
        "_internal_originalTime": 1689817244000
    },
    {
        "time": 1689817245000,
        "value": 139.252,
        "_internal_originalTime": 1689817245000
    },
    {
        "time": 1689817246000,
        "value": 139.253,
        "_internal_originalTime": 1689817246000
    },
    {
        "time": 1689817247000,
        "value": 139.25,
        "_internal_originalTime": 1689817247000
    },
    {
        "time": 1689817248000,
        "value": 139.249,
        "_internal_originalTime": 1689817248000
    },
    {
        "time": 1689817249000,
        "value": 139.248,
        "_internal_originalTime": 1689817249000
    },
    {
        "time": 1689817250000,
        "value": 139.246,
        "_internal_originalTime": 1689817250000
    },
    {
        "time": 1689817251000,
        "value": 139.248,
        "_internal_originalTime": 1689817251000
    },
    {
        "time": 1689817252000,
        "value": 139.242,
        "_internal_originalTime": 1689817252000
    },
    {
        "time": 1689817253000,
        "value": 139.24,
        "_internal_originalTime": 1689817253000
    },
    {
        "time": 1689817255000,
        "value": 139.25,
        "_internal_originalTime": 1689817255000
    },
    {
        "time": 1689817257000,
        "value": 139.239,
        "_internal_originalTime": 1689817257000
    },
    {
        "time": 1689817259000,
        "value": 139.239,
        "_internal_originalTime": 1689817259000
    },
    {
        "time": 1689817261000,
        "value": 139.241,
        "_internal_originalTime": 1689817261000
    },
    {
        "time": 1689817262000,
        "value": 139.247,
        "_internal_originalTime": 1689817262000
    },
    {
        "time": 1689817263000,
        "value": 139.244,
        "_internal_originalTime": 1689817263000
    },
    {
        "time": 1689817265000,
        "value": 139.242,
        "_internal_originalTime": 1689817265000
    },
    {
        "time": 1689817267000,
        "value": 139.249,
        "_internal_originalTime": 1689817267000
    },
    {
        "time": 1689817269000,
        "value": 139.256,
        "_internal_originalTime": 1689817269000
    },
    {
        "time": 1689817270000,
        "value": 139.258,
        "_internal_originalTime": 1689817270000
    },
    {
        "time": 1689817272000,
        "value": 139.258,
        "_internal_originalTime": 1689817272000
    },
    {
        "time": 1689817274000,
        "value": 139.262,
        "_internal_originalTime": 1689817274000
    },
    {
        "time": 1689817275000,
        "value": 139.26,
        "_internal_originalTime": 1689817275000
    },
    {
        "time": 1689817277000,
        "value": 139.26,
        "_internal_originalTime": 1689817277000
    },
    {
        "time": 1689817279000,
        "value": 139.258,
        "_internal_originalTime": 1689817279000
    },
    {
        "time": 1689817281000,
        "value": 139.26,
        "_internal_originalTime": 1689817281000
    },
    {
        "time": 1689817283000,
        "value": 139.26,
        "_internal_originalTime": 1689817283000
    },
    {
        "time": 1689817286000,
        "value": 139.256,
        "_internal_originalTime": 1689817286000
    },
    {
        "time": 1689817288000,
        "value": 139.254,
        "_internal_originalTime": 1689817288000
    },
    {
        "time": 1689817289000,
        "value": 139.252,
        "_internal_originalTime": 1689817289000
    },
    {
        "time": 1689817291000,
        "value": 139.258,
        "_internal_originalTime": 1689817291000
    },
    {
        "time": 1689817293000,
        "value": 139.255,
        "_internal_originalTime": 1689817293000
    },
    {
        "time": 1689817295000,
        "value": 139.259,
        "_internal_originalTime": 1689817295000
    },
    {
        "time": 1689817296000,
        "value": 139.26,
        "_internal_originalTime": 1689817296000
    },
    {
        "time": 1689817299000,
        "value": 139.26,
        "_internal_originalTime": 1689817299000
    },
    {
        "time": 1689817300000,
        "value": 139.266,
        "_internal_originalTime": 1689817300000
    },
    {
        "time": 1689817302000,
        "value": 139.269,
        "_internal_originalTime": 1689817302000
    },
    {
        "time": 1689817303000,
        "value": 139.269,
        "_internal_originalTime": 1689817303000
    },
    {
        "time": 1689817304000,
        "value": 139.268,
        "_internal_originalTime": 1689817304000
    },
    {
        "time": 1689817308000,
        "value": 139.27,
        "_internal_originalTime": 1689817308000
    },
    {
        "time": 1689817310000,
        "value": 139.285,
        "_internal_originalTime": 1689817310000
    },
    {
        "time": 1689817311000,
        "value": 139.286,
        "_internal_originalTime": 1689817311000
    },
    {
        "time": 1689817313000,
        "value": 139.289,
        "_internal_originalTime": 1689817313000
    },
    {
        "time": 1689817315000,
        "value": 139.293,
        "_internal_originalTime": 1689817315000
    },
    {
        "time": 1689817317000,
        "value": 139.287,
        "_internal_originalTime": 1689817317000
    },
    {
        "time": 1689817319000,
        "value": 139.289,
        "_internal_originalTime": 1689817319000
    },
    {
        "time": 1689817320000,
        "value": 139.292,
        "_internal_originalTime": 1689817320000
    },
    {
        "time": 1689817321000,
        "value": 139.292,
        "_internal_originalTime": 1689817321000
    },
    {
        "time": 1689817322000,
        "value": 139.292,
        "_internal_originalTime": 1689817322000
    },
    {
        "time": 1689817324000,
        "value": 139.285,
        "_internal_originalTime": 1689817324000
    },
    {
        "time": 1689817325000,
        "value": 139.284,
        "_internal_originalTime": 1689817325000
    },
    {
        "time": 1689817327000,
        "value": 139.287,
        "_internal_originalTime": 1689817327000
    },
    {
        "time": 1689817329000,
        "value": 139.284,
        "_internal_originalTime": 1689817329000
    },
    {
        "time": 1689817331000,
        "value": 139.288,
        "_internal_originalTime": 1689817331000
    },
    {
        "time": 1689817333000,
        "value": 139.29,
        "_internal_originalTime": 1689817333000
    },
    {
        "time": 1689817335000,
        "value": 139.288,
        "_internal_originalTime": 1689817335000
    },
    {
        "time": 1689817336000,
        "value": 139.298,
        "_internal_originalTime": 1689817336000
    },
    {
        "time": 1689817338000,
        "value": 139.299,
        "_internal_originalTime": 1689817338000
    },
    {
        "time": 1689817340000,
        "value": 139.298,
        "_internal_originalTime": 1689817340000
    },
    {
        "time": 1689817342000,
        "value": 139.297,
        "_internal_originalTime": 1689817342000
    },
    {
        "time": 1689817343000,
        "value": 139.299,
        "_internal_originalTime": 1689817343000
    },
    {
        "time": 1689817345000,
        "value": 139.306,
        "_internal_originalTime": 1689817345000
    },
    {
        "time": 1689817346000,
        "value": 139.306,
        "_internal_originalTime": 1689817346000
    },
    {
        "time": 1689817349000,
        "value": 139.296,
        "_internal_originalTime": 1689817349000
    },
    {
        "time": 1689817351000,
        "value": 139.298,
        "_internal_originalTime": 1689817351000
    },
    {
        "time": 1689817353000,
        "value": 139.289,
        "_internal_originalTime": 1689817353000
    },
    {
        "time": 1689817355000,
        "value": 139.286,
        "_internal_originalTime": 1689817355000
    },
    {
        "time": 1689817356000,
        "value": 139.286,
        "_internal_originalTime": 1689817356000
    },
    {
        "time": 1689817359000,
        "value": 139.289,
        "_internal_originalTime": 1689817359000
    },
    {
        "time": 1689817360000,
        "value": 139.286,
        "_internal_originalTime": 1689817360000
    },
    {
        "time": 1689817363000,
        "value": 139.292,
        "_internal_originalTime": 1689817363000
    },
    {
        "time": 1689817364000,
        "value": 139.29,
        "_internal_originalTime": 1689817364000
    },
    {
        "time": 1689817366000,
        "value": 139.295,
        "_internal_originalTime": 1689817366000
    },
    {
        "time": 1689817367000,
        "value": 139.299,
        "_internal_originalTime": 1689817367000
    },
    {
        "time": 1689817369000,
        "value": 139.3,
        "_internal_originalTime": 1689817369000
    },
    {
        "time": 1689817371000,
        "value": 139.3,
        "_internal_originalTime": 1689817371000
    },
    {
        "time": 1689817372000,
        "value": 139.297,
        "_internal_originalTime": 1689817372000
    },
    {
        "time": 1689817374000,
        "value": 139.298,
        "_internal_originalTime": 1689817374000
    },
    {
        "time": 1689817376000,
        "value": 139.299,
        "_internal_originalTime": 1689817376000
    },
    {
        "time": 1689817378000,
        "value": 139.294,
        "_internal_originalTime": 1689817378000
    },
    {
        "time": 1689817379000,
        "value": 139.3,
        "_internal_originalTime": 1689817379000
    },
    {
        "time": 1689817380000,
        "value": 139.301,
        "_internal_originalTime": 1689817380000
    },
    {
        "time": 1689817382000,
        "value": 139.3,
        "_internal_originalTime": 1689817382000
    },
    {
        "time": 1689817383000,
        "value": 139.299,
        "_internal_originalTime": 1689817383000
    },
    {
        "time": 1689817384000,
        "value": 139.298,
        "_internal_originalTime": 1689817384000
    },
    {
        "time": 1689817386000,
        "value": 139.303,
        "_internal_originalTime": 1689817386000
    },
    {
        "time": 1689817388000,
        "value": 139.303,
        "_internal_originalTime": 1689817388000
    },
    {
        "time": 1689817389000,
        "value": 139.3,
        "_internal_originalTime": 1689817389000
    },
    {
        "time": 1689817391000,
        "value": 139.298,
        "_internal_originalTime": 1689817391000
    },
    {
        "time": 1689817393000,
        "value": 139.296,
        "_internal_originalTime": 1689817393000
    },
    {
        "time": 1689817395000,
        "value": 139.299,
        "_internal_originalTime": 1689817395000
    },
    {
        "time": 1689817397000,
        "value": 139.301,
        "_internal_originalTime": 1689817397000
    },
    {
        "time": 1689817399000,
        "value": 139.298,
        "_internal_originalTime": 1689817399000
    },
    {
        "time": 1689817400000,
        "value": 139.3,
        "_internal_originalTime": 1689817400000
    },
    {
        "time": 1689817401000,
        "value": 139.3,
        "_internal_originalTime": 1689817401000
    },
    {
        "time": 1689817403000,
        "value": 139.298,
        "_internal_originalTime": 1689817403000
    },
    {
        "time": 1689817405000,
        "value": 139.302,
        "_internal_originalTime": 1689817405000
    },
    {
        "time": 1689817407000,
        "value": 139.299,
        "_internal_originalTime": 1689817407000
    },
    {
        "time": 1689817408000,
        "value": 139.295,
        "_internal_originalTime": 1689817408000
    },
    {
        "time": 1689817410000,
        "value": 139.298,
        "_internal_originalTime": 1689817410000
    },
    {
        "time": 1689817411000,
        "value": 139.301,
        "_internal_originalTime": 1689817411000
    },
    {
        "time": 1689817413000,
        "value": 139.278,
        "_internal_originalTime": 1689817413000
    },
    {
        "time": 1689817415000,
        "value": 139.268,
        "_internal_originalTime": 1689817415000
    },
    {
        "time": 1689817417000,
        "value": 139.27,
        "_internal_originalTime": 1689817417000
    },
    {
        "time": 1689817419000,
        "value": 139.269,
        "_internal_originalTime": 1689817419000
    },
    {
        "time": 1689817420000,
        "value": 139.267,
        "_internal_originalTime": 1689817420000
    },
    {
        "time": 1689817422000,
        "value": 139.269,
        "_internal_originalTime": 1689817422000
    },
    {
        "time": 1689817423000,
        "value": 139.266,
        "_internal_originalTime": 1689817423000
    },
    {
        "time": 1689817425000,
        "value": 139.262,
        "_internal_originalTime": 1689817425000
    },
    {
        "time": 1689817426000,
        "value": 139.258,
        "_internal_originalTime": 1689817426000
    },
    {
        "time": 1689817428000,
        "value": 139.262,
        "_internal_originalTime": 1689817428000
    },
    {
        "time": 1689817429000,
        "value": 139.264,
        "_internal_originalTime": 1689817429000
    },
    {
        "time": 1689817431000,
        "value": 139.262,
        "_internal_originalTime": 1689817431000
    },
    {
        "time": 1689817434000,
        "value": 139.261,
        "_internal_originalTime": 1689817434000
    },
    {
        "time": 1689817436000,
        "value": 139.257,
        "_internal_originalTime": 1689817436000
    },
    {
        "time": 1689817437000,
        "value": 139.258,
        "_internal_originalTime": 1689817437000
    },
    {
        "time": 1689817439000,
        "value": 139.257,
        "_internal_originalTime": 1689817439000
    },
    {
        "time": 1689817440000,
        "value": 139.261,
        "_internal_originalTime": 1689817440000
    },
    {
        "time": 1689817442000,
        "value": 139.259,
        "_internal_originalTime": 1689817442000
    },
    {
        "time": 1689817443000,
        "value": 139.26,
        "_internal_originalTime": 1689817443000
    },
    {
        "time": 1689817444000,
        "value": 139.26,
        "_internal_originalTime": 1689817444000
    },
    {
        "time": 1689817445000,
        "value": 139.256,
        "_internal_originalTime": 1689817445000
    },
    {
        "time": 1689817447000,
        "value": 139.263,
        "_internal_originalTime": 1689817447000
    },
    {
        "time": 1689817448000,
        "value": 139.264,
        "_internal_originalTime": 1689817448000
    },
    {
        "time": 1689817450000,
        "value": 139.27,
        "_internal_originalTime": 1689817450000
    },
    {
        "time": 1689817452000,
        "value": 139.274,
        "_internal_originalTime": 1689817452000
    },
    {
        "time": 1689817454000,
        "value": 139.274,
        "_internal_originalTime": 1689817454000
    },
    {
        "time": 1689817456000,
        "value": 139.273,
        "_internal_originalTime": 1689817456000
    },
    {
        "time": 1689817458000,
        "value": 139.26,
        "_internal_originalTime": 1689817458000
    },
    {
        "time": 1689817460000,
        "value": 139.265,
        "_internal_originalTime": 1689817460000
    },
    {
        "time": 1689817462000,
        "value": 139.27,
        "_internal_originalTime": 1689817462000
    },
    {
        "time": 1689817465000,
        "value": 139.268,
        "_internal_originalTime": 1689817465000
    },
    {
        "time": 1689817467000,
        "value": 139.267,
        "_internal_originalTime": 1689817467000
    },
    {
        "time": 1689817468000,
        "value": 139.271,
        "_internal_originalTime": 1689817468000
    },
    {
        "time": 1689817469000,
        "value": 139.267,
        "_internal_originalTime": 1689817469000
    },
    {
        "time": 1689817471000,
        "value": 139.266,
        "_internal_originalTime": 1689817471000
    },
    {
        "time": 1689817473000,
        "value": 139.271,
        "_internal_originalTime": 1689817473000
    },
    {
        "time": 1689817475000,
        "value": 139.266,
        "_internal_originalTime": 1689817475000
    },
    {
        "time": 1689817477000,
        "value": 139.268,
        "_internal_originalTime": 1689817477000
    },
    {
        "time": 1689817479000,
        "value": 139.27,
        "_internal_originalTime": 1689817479000
    },
    {
        "time": 1689817480000,
        "value": 139.269,
        "_internal_originalTime": 1689817480000
    },
    {
        "time": 1689817483000,
        "value": 139.268,
        "_internal_originalTime": 1689817483000
    },
    {
        "time": 1689817484000,
        "value": 139.268,
        "_internal_originalTime": 1689817484000
    },
    {
        "time": 1689817485000,
        "value": 139.267,
        "_internal_originalTime": 1689817485000
    },
    {
        "time": 1689817487000,
        "value": 139.268,
        "_internal_originalTime": 1689817487000
    },
    {
        "time": 1689817488000,
        "value": 139.268,
        "_internal_originalTime": 1689817488000
    },
    {
        "time": 1689817492000,
        "value": 139.266,
        "_internal_originalTime": 1689817492000
    },
    {
        "time": 1689817494000,
        "value": 139.267,
        "_internal_originalTime": 1689817494000
    },
    {
        "time": 1689817496000,
        "value": 139.267,
        "_internal_originalTime": 1689817496000
    },
    {
        "time": 1689817498000,
        "value": 139.26,
        "_internal_originalTime": 1689817498000
    },
    {
        "time": 1689817500000,
        "value": 139.261,
        "_internal_originalTime": 1689817500000
    },
    {
        "time": 1689817502000,
        "value": 139.242,
        "_internal_originalTime": 1689817502000
    },
    {
        "time": 1689817503000,
        "value": 139.242,
        "_internal_originalTime": 1689817503000
    },
    {
        "time": 1689817505000,
        "value": 139.243,
        "_internal_originalTime": 1689817505000
    },
    {
        "time": 1689817506000,
        "value": 139.237,
        "_internal_originalTime": 1689817506000
    },
    {
        "time": 1689817507000,
        "value": 139.241,
        "_internal_originalTime": 1689817507000
    },
    {
        "time": 1689817508000,
        "value": 139.23,
        "_internal_originalTime": 1689817508000
    },
    {
        "time": 1689817509000,
        "value": 139.229,
        "_internal_originalTime": 1689817509000
    },
    {
        "time": 1689817510000,
        "value": 139.227,
        "_internal_originalTime": 1689817510000
    },
    {
        "time": 1689817511000,
        "value": 139.224,
        "_internal_originalTime": 1689817511000
    },
    {
        "time": 1689817512000,
        "value": 139.22,
        "_internal_originalTime": 1689817512000
    },
    {
        "time": 1689817513000,
        "value": 139.225,
        "_internal_originalTime": 1689817513000
    },
    {
        "time": 1689817514000,
        "value": 139.222,
        "_internal_originalTime": 1689817514000
    },
    {
        "time": 1689817515000,
        "value": 139.227,
        "_internal_originalTime": 1689817515000
    },
    {
        "time": 1689817516000,
        "value": 139.222,
        "_internal_originalTime": 1689817516000
    },
    {
        "time": 1689817517000,
        "value": 139.216,
        "_internal_originalTime": 1689817517000
    },
    {
        "time": 1689817518000,
        "value": 139.218,
        "_internal_originalTime": 1689817518000
    },
    {
        "time": 1689817519000,
        "value": 139.215,
        "_internal_originalTime": 1689817519000
    },
    {
        "time": 1689817520000,
        "value": 139.214,
        "_internal_originalTime": 1689817520000
    },
    {
        "time": 1689817522000,
        "value": 139.217,
        "_internal_originalTime": 1689817522000
    },
    {
        "time": 1689817523000,
        "value": 139.208,
        "_internal_originalTime": 1689817523000
    },
    {
        "time": 1689817524000,
        "value": 139.211,
        "_internal_originalTime": 1689817524000
    },
    {
        "time": 1689817525000,
        "value": 139.207,
        "_internal_originalTime": 1689817525000
    },
    {
        "time": 1689817527000,
        "value": 139.204,
        "_internal_originalTime": 1689817527000
    },
    {
        "time": 1689817528000,
        "value": 139.206,
        "_internal_originalTime": 1689817528000
    },
    {
        "time": 1689817529000,
        "value": 139.209,
        "_internal_originalTime": 1689817529000
    },
    {
        "time": 1689817530000,
        "value": 139.205,
        "_internal_originalTime": 1689817530000
    },
    {
        "time": 1689817532000,
        "value": 139.198,
        "_internal_originalTime": 1689817532000
    },
    {
        "time": 1689817534000,
        "value": 139.207,
        "_internal_originalTime": 1689817534000
    },
    {
        "time": 1689817535000,
        "value": 139.202,
        "_internal_originalTime": 1689817535000
    },
    {
        "time": 1689817536000,
        "value": 139.199,
        "_internal_originalTime": 1689817536000
    },
    {
        "time": 1689817537000,
        "value": 139.203,
        "_internal_originalTime": 1689817537000
    },
    {
        "time": 1689817539000,
        "value": 139.201,
        "_internal_originalTime": 1689817539000
    },
    {
        "time": 1689817540000,
        "value": 139.202,
        "_internal_originalTime": 1689817540000
    },
    {
        "time": 1689817541000,
        "value": 139.21,
        "_internal_originalTime": 1689817541000
    },
    {
        "time": 1689817542000,
        "value": 139.206,
        "_internal_originalTime": 1689817542000
    },
    {
        "time": 1689817543000,
        "value": 139.206,
        "_internal_originalTime": 1689817543000
    },
    {
        "time": 1689817544000,
        "value": 139.205,
        "_internal_originalTime": 1689817544000
    },
    {
        "time": 1689817545000,
        "value": 139.204,
        "_internal_originalTime": 1689817545000
    },
    {
        "time": 1689817547000,
        "value": 139.204,
        "_internal_originalTime": 1689817547000
    },
    {
        "time": 1689817548000,
        "value": 139.211,
        "_internal_originalTime": 1689817548000
    },
    {
        "time": 1689817550000,
        "value": 139.21,
        "_internal_originalTime": 1689817550000
    },
    {
        "time": 1689817552000,
        "value": 139.224,
        "_internal_originalTime": 1689817552000
    },
    {
        "time": 1689817553000,
        "value": 139.214,
        "_internal_originalTime": 1689817553000
    },
    {
        "time": 1689817554000,
        "value": 139.215,
        "_internal_originalTime": 1689817554000
    },
    {
        "time": 1689817556000,
        "value": 139.214,
        "_internal_originalTime": 1689817556000
    },
    {
        "time": 1689817557000,
        "value": 139.216,
        "_internal_originalTime": 1689817557000
    },
    {
        "time": 1689817558000,
        "value": 139.216,
        "_internal_originalTime": 1689817558000
    },
    {
        "time": 1689817559000,
        "value": 139.212,
        "_internal_originalTime": 1689817559000
    },
    {
        "time": 1689817561000,
        "value": 139.217,
        "_internal_originalTime": 1689817561000
    },
    {
        "time": 1689817562000,
        "value": 139.217,
        "_internal_originalTime": 1689817562000
    },
    {
        "time": 1689817563000,
        "value": 139.212,
        "_internal_originalTime": 1689817563000
    },
    {
        "time": 1689817564000,
        "value": 139.216,
        "_internal_originalTime": 1689817564000
    },
    {
        "time": 1689817565000,
        "value": 139.212,
        "_internal_originalTime": 1689817565000
    },
    {
        "time": 1689817566000,
        "value": 139.215,
        "_internal_originalTime": 1689817566000
    },
    {
        "time": 1689817568000,
        "value": 139.215,
        "_internal_originalTime": 1689817568000
    },
    {
        "time": 1689817569000,
        "value": 139.213,
        "_internal_originalTime": 1689817569000
    },
    {
        "time": 1689817570000,
        "value": 139.213,
        "_internal_originalTime": 1689817570000
    },
    {
        "time": 1689817571000,
        "value": 139.208,
        "_internal_originalTime": 1689817571000
    },
    {
        "time": 1689817572000,
        "value": 139.198,
        "_internal_originalTime": 1689817572000
    },
    {
        "time": 1689817574000,
        "value": 139.203,
        "_internal_originalTime": 1689817574000
    },
    {
        "time": 1689817575000,
        "value": 139.203,
        "_internal_originalTime": 1689817575000
    },
    {
        "time": 1689817576000,
        "value": 139.191,
        "_internal_originalTime": 1689817576000
    },
    {
        "time": 1689817577000,
        "value": 139.177,
        "_internal_originalTime": 1689817577000
    },
    {
        "time": 1689817579000,
        "value": 139.182,
        "_internal_originalTime": 1689817579000
    },
    {
        "time": 1689817580000,
        "value": 139.183,
        "_internal_originalTime": 1689817580000
    },
    {
        "time": 1689817582000,
        "value": 139.179,
        "_internal_originalTime": 1689817582000
    },
    {
        "time": 1689817583000,
        "value": 139.175,
        "_internal_originalTime": 1689817583000
    },
    {
        "time": 1689817584000,
        "value": 139.175,
        "_internal_originalTime": 1689817584000
    },
    {
        "time": 1689817585000,
        "value": 139.171,
        "_internal_originalTime": 1689817585000
    },
    {
        "time": 1689817587000,
        "value": 139.174,
        "_internal_originalTime": 1689817587000
    },
    {
        "time": 1689817588000,
        "value": 139.17,
        "_internal_originalTime": 1689817588000
    },
    {
        "time": 1689817589000,
        "value": 139.174,
        "_internal_originalTime": 1689817589000
    },
    {
        "time": 1689817590000,
        "value": 139.172,
        "_internal_originalTime": 1689817590000
    },
    {
        "time": 1689817592000,
        "value": 139.158,
        "_internal_originalTime": 1689817592000
    },
    {
        "time": 1689817593000,
        "value": 139.166,
        "_internal_originalTime": 1689817593000
    },
    {
        "time": 1689817594000,
        "value": 139.168,
        "_internal_originalTime": 1689817594000
    },
    {
        "time": 1689817595000,
        "value": 139.167,
        "_internal_originalTime": 1689817595000
    },
    {
        "time": 1689817597000,
        "value": 139.168,
        "_internal_originalTime": 1689817597000
    },
    {
        "time": 1689817598000,
        "value": 139.164,
        "_internal_originalTime": 1689817598000
    },
    {
        "time": 1689817599000,
        "value": 139.167,
        "_internal_originalTime": 1689817599000
    },
    {
        "time": 1689817600000,
        "value": 139.165,
        "_internal_originalTime": 1689817600000
    },
    {
        "time": 1689817602000,
        "value": 139.168,
        "_internal_originalTime": 1689817602000
    },
    {
        "time": 1689817603000,
        "value": 139.17,
        "_internal_originalTime": 1689817603000
    },
    {
        "time": 1689817605000,
        "value": 139.167,
        "_internal_originalTime": 1689817605000
    },
    {
        "time": 1689817606000,
        "value": 139.164,
        "_internal_originalTime": 1689817606000
    },
    {
        "time": 1689817607000,
        "value": 139.164,
        "_internal_originalTime": 1689817607000
    },
    {
        "time": 1689817608000,
        "value": 139.164,
        "_internal_originalTime": 1689817608000
    },
    {
        "time": 1689817609000,
        "value": 139.166,
        "_internal_originalTime": 1689817609000
    },
    {
        "time": 1689817610000,
        "value": 139.166,
        "_internal_originalTime": 1689817610000
    },
    {
        "time": 1689817611000,
        "value": 139.16,
        "_internal_originalTime": 1689817611000
    },
    {
        "time": 1689817612000,
        "value": 139.159,
        "_internal_originalTime": 1689817612000
    },
    {
        "time": 1689817613000,
        "value": 139.16,
        "_internal_originalTime": 1689817613000
    },
    {
        "time": 1689817614000,
        "value": 139.16,
        "_internal_originalTime": 1689817614000
    },
    {
        "time": 1689817616000,
        "value": 139.153,
        "_internal_originalTime": 1689817616000
    },
    {
        "time": 1689817617000,
        "value": 139.154,
        "_internal_originalTime": 1689817617000
    },
    {
        "time": 1689817618000,
        "value": 139.155,
        "_internal_originalTime": 1689817618000
    },
    {
        "time": 1689817619000,
        "value": 139.153,
        "_internal_originalTime": 1689817619000
    },
    {
        "time": 1689817621000,
        "value": 139.136,
        "_internal_originalTime": 1689817621000
    },
    {
        "time": 1689817622000,
        "value": 139.139,
        "_internal_originalTime": 1689817622000
    },
    {
        "time": 1689817623000,
        "value": 139.138,
        "_internal_originalTime": 1689817623000
    },
    {
        "time": 1689817624000,
        "value": 139.148,
        "_internal_originalTime": 1689817624000
    },
    {
        "time": 1689817626000,
        "value": 139.156,
        "_internal_originalTime": 1689817626000
    },
    {
        "time": 1689817627000,
        "value": 139.16,
        "_internal_originalTime": 1689817627000
    },
    {
        "time": 1689817628000,
        "value": 139.156,
        "_internal_originalTime": 1689817628000
    },
    {
        "time": 1689817629000,
        "value": 139.16,
        "_internal_originalTime": 1689817629000
    },
    {
        "time": 1689817631000,
        "value": 139.16,
        "_internal_originalTime": 1689817631000
    },
    {
        "time": 1689817632000,
        "value": 139.164,
        "_internal_originalTime": 1689817632000
    },
    {
        "time": 1689817633000,
        "value": 139.166,
        "_internal_originalTime": 1689817633000
    },
    {
        "time": 1689817635000,
        "value": 139.158,
        "_internal_originalTime": 1689817635000
    },
    {
        "time": 1689817637000,
        "value": 139.152,
        "_internal_originalTime": 1689817637000
    },
    {
        "time": 1689817638000,
        "value": 139.153,
        "_internal_originalTime": 1689817638000
    },
    {
        "time": 1689817639000,
        "value": 139.153,
        "_internal_originalTime": 1689817639000
    },
    {
        "time": 1689817640000,
        "value": 139.155,
        "_internal_originalTime": 1689817640000
    },
    {
        "time": 1689817642000,
        "value": 139.157,
        "_internal_originalTime": 1689817642000
    },
    {
        "time": 1689817643000,
        "value": 139.154,
        "_internal_originalTime": 1689817643000
    },
    {
        "time": 1689817646000,
        "value": 139.158,
        "_internal_originalTime": 1689817646000
    },
    {
        "time": 1689817648000,
        "value": 139.16,
        "_internal_originalTime": 1689817648000
    },
    {
        "time": 1689817649000,
        "value": 139.16,
        "_internal_originalTime": 1689817649000
    },
    {
        "time": 1689817650000,
        "value": 139.163,
        "_internal_originalTime": 1689817650000
    },
    {
        "time": 1689817651000,
        "value": 139.161,
        "_internal_originalTime": 1689817651000
    },
    {
        "time": 1689817652000,
        "value": 139.155,
        "_internal_originalTime": 1689817652000
    },
    {
        "time": 1689817654000,
        "value": 139.157,
        "_internal_originalTime": 1689817654000
    },
    {
        "time": 1689817656000,
        "value": 139.153,
        "_internal_originalTime": 1689817656000
    },
    {
        "time": 1689817657000,
        "value": 139.152,
        "_internal_originalTime": 1689817657000
    },
    {
        "time": 1689817658000,
        "value": 139.156,
        "_internal_originalTime": 1689817658000
    },
    {
        "time": 1689817660000,
        "value": 139.152,
        "_internal_originalTime": 1689817660000
    },
    {
        "time": 1689817662000,
        "value": 139.147,
        "_internal_originalTime": 1689817662000
    },
    {
        "time": 1689817663000,
        "value": 139.147,
        "_internal_originalTime": 1689817663000
    },
    {
        "time": 1689817665000,
        "value": 139.144,
        "_internal_originalTime": 1689817665000
    },
    {
        "time": 1689817666000,
        "value": 139.142,
        "_internal_originalTime": 1689817666000
    },
    {
        "time": 1689817667000,
        "value": 139.147,
        "_internal_originalTime": 1689817667000
    },
    {
        "time": 1689817668000,
        "value": 139.148,
        "_internal_originalTime": 1689817668000
    },
    {
        "time": 1689817669000,
        "value": 139.137,
        "_internal_originalTime": 1689817669000
    },
    {
        "time": 1689817670000,
        "value": 139.135,
        "_internal_originalTime": 1689817670000
    },
    {
        "time": 1689817671000,
        "value": 139.14,
        "_internal_originalTime": 1689817671000
    },
    {
        "time": 1689817672000,
        "value": 139.136,
        "_internal_originalTime": 1689817672000
    },
    {
        "time": 1689817674000,
        "value": 139.138,
        "_internal_originalTime": 1689817674000
    },
    {
        "time": 1689817675000,
        "value": 139.135,
        "_internal_originalTime": 1689817675000
    },
    {
        "time": 1689817676000,
        "value": 139.131,
        "_internal_originalTime": 1689817676000
    },
    {
        "time": 1689817678000,
        "value": 139.129,
        "_internal_originalTime": 1689817678000
    },
    {
        "time": 1689817680000,
        "value": 139.128,
        "_internal_originalTime": 1689817680000
    },
    {
        "time": 1689817681000,
        "value": 139.129,
        "_internal_originalTime": 1689817681000
    },
    {
        "time": 1689817682000,
        "value": 139.13,
        "_internal_originalTime": 1689817682000
    },
    {
        "time": 1689817683000,
        "value": 139.125,
        "_internal_originalTime": 1689817683000
    },
    {
        "time": 1689817684000,
        "value": 139.124,
        "_internal_originalTime": 1689817684000
    },
    {
        "time": 1689817685000,
        "value": 139.129,
        "_internal_originalTime": 1689817685000
    },
    {
        "time": 1689817686000,
        "value": 139.124,
        "_internal_originalTime": 1689817686000
    },
    {
        "time": 1689817687000,
        "value": 139.12,
        "_internal_originalTime": 1689817687000
    },
    {
        "time": 1689817688000,
        "value": 139.12,
        "_internal_originalTime": 1689817688000
    },
    {
        "time": 1689817689000,
        "value": 139.123,
        "_internal_originalTime": 1689817689000
    },
    {
        "time": 1689817690000,
        "value": 139.124,
        "_internal_originalTime": 1689817690000
    },
    {
        "time": 1689817693000,
        "value": 139.122,
        "_internal_originalTime": 1689817693000
    },
    {
        "time": 1689817694000,
        "value": 139.122,
        "_internal_originalTime": 1689817694000
    },
    {
        "time": 1689817695000,
        "value": 139.13,
        "_internal_originalTime": 1689817695000
    },
    {
        "time": 1689817696000,
        "value": 139.135,
        "_internal_originalTime": 1689817696000
    },
    {
        "time": 1689817697000,
        "value": 139.136,
        "_internal_originalTime": 1689817697000
    },
    {
        "time": 1689817699000,
        "value": 139.136,
        "_internal_originalTime": 1689817699000
    },
    {
        "time": 1689817700000,
        "value": 139.134,
        "_internal_originalTime": 1689817700000
    },
    {
        "time": 1689817702000,
        "value": 139.13,
        "_internal_originalTime": 1689817702000
    },
    {
        "time": 1689817703000,
        "value": 139.124,
        "_internal_originalTime": 1689817703000
    },
    {
        "time": 1689817704000,
        "value": 139.124,
        "_internal_originalTime": 1689817704000
    },
    {
        "time": 1689817707000,
        "value": 139.126,
        "_internal_originalTime": 1689817707000
    },
    {
        "time": 1689817709000,
        "value": 139.124,
        "_internal_originalTime": 1689817709000
    },
    {
        "time": 1689817711000,
        "value": 139.125,
        "_internal_originalTime": 1689817711000
    },
    {
        "time": 1689817712000,
        "value": 139.129,
        "_internal_originalTime": 1689817712000
    },
    {
        "time": 1689817714000,
        "value": 139.129,
        "_internal_originalTime": 1689817714000
    },
    {
        "time": 1689817715000,
        "value": 139.128,
        "_internal_originalTime": 1689817715000
    },
    {
        "time": 1689817716000,
        "value": 139.13,
        "_internal_originalTime": 1689817716000
    },
    {
        "time": 1689817718000,
        "value": 139.124,
        "_internal_originalTime": 1689817718000
    },
    {
        "time": 1689817719000,
        "value": 139.13,
        "_internal_originalTime": 1689817719000
    },
    {
        "time": 1689817720000,
        "value": 139.125,
        "_internal_originalTime": 1689817720000
    },
    {
        "time": 1689817721000,
        "value": 139.124,
        "_internal_originalTime": 1689817721000
    },
    {
        "time": 1689817723000,
        "value": 139.13,
        "_internal_originalTime": 1689817723000
    },
    {
        "time": 1689817727000,
        "value": 139.126,
        "_internal_originalTime": 1689817727000
    },
    {
        "time": 1689817728000,
        "value": 139.133,
        "_internal_originalTime": 1689817728000
    },
    {
        "time": 1689817729000,
        "value": 139.132,
        "_internal_originalTime": 1689817729000
    },
    {
        "time": 1689817732000,
        "value": 139.138,
        "_internal_originalTime": 1689817732000
    },
    {
        "time": 1689817733000,
        "value": 139.148,
        "_internal_originalTime": 1689817733000
    },
    {
        "time": 1689817734000,
        "value": 139.144,
        "_internal_originalTime": 1689817734000
    },
    {
        "time": 1689817736000,
        "value": 139.146,
        "_internal_originalTime": 1689817736000
    },
    {
        "time": 1689817737000,
        "value": 139.156,
        "_internal_originalTime": 1689817737000
    },
    {
        "time": 1689817738000,
        "value": 139.157,
        "_internal_originalTime": 1689817738000
    },
    {
        "time": 1689817740000,
        "value": 139.153,
        "_internal_originalTime": 1689817740000
    },
    {
        "time": 1689817741000,
        "value": 139.155,
        "_internal_originalTime": 1689817741000
    },
    {
        "time": 1689817742000,
        "value": 139.148,
        "_internal_originalTime": 1689817742000
    },
    {
        "time": 1689817743000,
        "value": 139.145,
        "_internal_originalTime": 1689817743000
    },
    {
        "time": 1689817745000,
        "value": 139.142,
        "_internal_originalTime": 1689817745000
    },
    {
        "time": 1689817746000,
        "value": 139.146,
        "_internal_originalTime": 1689817746000
    },
    {
        "time": 1689817747000,
        "value": 139.146,
        "_internal_originalTime": 1689817747000
    },
    {
        "time": 1689817748000,
        "value": 139.148,
        "_internal_originalTime": 1689817748000
    },
    {
        "time": 1689817749000,
        "value": 139.146,
        "_internal_originalTime": 1689817749000
    },
    {
        "time": 1689817751000,
        "value": 139.147,
        "_internal_originalTime": 1689817751000
    },
    {
        "time": 1689817752000,
        "value": 139.156,
        "_internal_originalTime": 1689817752000
    },
    {
        "time": 1689817753000,
        "value": 139.154,
        "_internal_originalTime": 1689817753000
    },
    {
        "time": 1689817754000,
        "value": 139.154,
        "_internal_originalTime": 1689817754000
    },
    {
        "time": 1689817756000,
        "value": 139.155,
        "_internal_originalTime": 1689817756000
    },
    {
        "time": 1689817758000,
        "value": 139.154,
        "_internal_originalTime": 1689817758000
    },
    {
        "time": 1689817759000,
        "value": 139.152,
        "_internal_originalTime": 1689817759000
    },
    {
        "time": 1689817760000,
        "value": 139.15,
        "_internal_originalTime": 1689817760000
    },
    {
        "time": 1689817762000,
        "value": 139.144,
        "_internal_originalTime": 1689817762000
    },
    {
        "time": 1689817763000,
        "value": 139.142,
        "_internal_originalTime": 1689817763000
    },
    {
        "time": 1689817764000,
        "value": 139.146,
        "_internal_originalTime": 1689817764000
    },
    {
        "time": 1689817766000,
        "value": 139.145,
        "_internal_originalTime": 1689817766000
    },
    {
        "time": 1689817768000,
        "value": 139.146,
        "_internal_originalTime": 1689817768000
    },
    {
        "time": 1689817770000,
        "value": 139.142,
        "_internal_originalTime": 1689817770000
    },
    {
        "time": 1689817771000,
        "value": 139.144,
        "_internal_originalTime": 1689817771000
    },
    {
        "time": 1689817773000,
        "value": 139.144,
        "_internal_originalTime": 1689817773000
    },
    {
        "time": 1689817777000,
        "value": 139.154,
        "_internal_originalTime": 1689817777000
    },
    {
        "time": 1689817778000,
        "value": 139.154,
        "_internal_originalTime": 1689817778000
    },
    {
        "time": 1689817779000,
        "value": 139.153,
        "_internal_originalTime": 1689817779000
    },
    {
        "time": 1689817780000,
        "value": 139.152,
        "_internal_originalTime": 1689817780000
    },
    {
        "time": 1689817782000,
        "value": 139.163,
        "_internal_originalTime": 1689817782000
    },
    {
        "time": 1689817784000,
        "value": 139.173,
        "_internal_originalTime": 1689817784000
    },
    {
        "time": 1689817785000,
        "value": 139.172,
        "_internal_originalTime": 1689817785000
    },
    {
        "time": 1689817786000,
        "value": 139.174,
        "_internal_originalTime": 1689817786000
    },
    {
        "time": 1689817788000,
        "value": 139.161,
        "_internal_originalTime": 1689817788000
    },
    {
        "time": 1689817789000,
        "value": 139.162,
        "_internal_originalTime": 1689817789000
    },
    {
        "time": 1689817793000,
        "value": 139.165,
        "_internal_originalTime": 1689817793000
    },
    {
        "time": 1689817795000,
        "value": 139.171,
        "_internal_originalTime": 1689817795000
    },
    {
        "time": 1689817797000,
        "value": 139.171,
        "_internal_originalTime": 1689817797000
    },
    {
        "time": 1689817798000,
        "value": 139.163,
        "_internal_originalTime": 1689817798000
    },
    {
        "time": 1689817799000,
        "value": 139.164,
        "_internal_originalTime": 1689817799000
    },
    {
        "time": 1689817800000,
        "value": 139.165,
        "_internal_originalTime": 1689817800000
    },
    {
        "time": 1689817801000,
        "value": 139.164,
        "_internal_originalTime": 1689817801000
    },
    {
        "time": 1689817803000,
        "value": 139.164,
        "_internal_originalTime": 1689817803000
    },
    {
        "time": 1689817804000,
        "value": 139.166,
        "_internal_originalTime": 1689817804000
    },
    {
        "time": 1689817806000,
        "value": 139.164,
        "_internal_originalTime": 1689817806000
    },
    {
        "time": 1689817807000,
        "value": 139.165,
        "_internal_originalTime": 1689817807000
    },
    {
        "time": 1689817808000,
        "value": 139.172,
        "_internal_originalTime": 1689817808000
    },
    {
        "time": 1689817809000,
        "value": 139.17,
        "_internal_originalTime": 1689817809000
    },
    {
        "time": 1689817810000,
        "value": 139.168,
        "_internal_originalTime": 1689817810000
    },
    {
        "time": 1689817812000,
        "value": 139.168,
        "_internal_originalTime": 1689817812000
    },
    {
        "time": 1689817813000,
        "value": 139.164,
        "_internal_originalTime": 1689817813000
    },
    {
        "time": 1689817815000,
        "value": 139.167,
        "_internal_originalTime": 1689817815000
    },
    {
        "time": 1689817816000,
        "value": 139.166,
        "_internal_originalTime": 1689817816000
    },
    {
        "time": 1689817817000,
        "value": 139.164,
        "_internal_originalTime": 1689817817000
    },
    {
        "time": 1689817819000,
        "value": 139.164,
        "_internal_originalTime": 1689817819000
    },
    {
        "time": 1689817820000,
        "value": 139.164,
        "_internal_originalTime": 1689817820000
    },
    {
        "time": 1689817822000,
        "value": 139.159,
        "_internal_originalTime": 1689817822000
    },
    {
        "time": 1689817824000,
        "value": 139.159,
        "_internal_originalTime": 1689817824000
    },
    {
        "time": 1689817825000,
        "value": 139.159,
        "_internal_originalTime": 1689817825000
    },
    {
        "time": 1689817826000,
        "value": 139.158,
        "_internal_originalTime": 1689817826000
    },
    {
        "time": 1689817828000,
        "value": 139.157,
        "_internal_originalTime": 1689817828000
    },
    {
        "time": 1689817829000,
        "value": 139.154,
        "_internal_originalTime": 1689817829000
    },
    {
        "time": 1689817830000,
        "value": 139.157,
        "_internal_originalTime": 1689817830000
    },
    {
        "time": 1689817831000,
        "value": 139.157,
        "_internal_originalTime": 1689817831000
    },
    {
        "time": 1689817833000,
        "value": 139.153,
        "_internal_originalTime": 1689817833000
    },
    {
        "time": 1689817834000,
        "value": 139.156,
        "_internal_originalTime": 1689817834000
    },
    {
        "time": 1689817835000,
        "value": 139.153,
        "_internal_originalTime": 1689817835000
    },
    {
        "time": 1689817836000,
        "value": 139.148,
        "_internal_originalTime": 1689817836000
    },
    {
        "time": 1689817838000,
        "value": 139.142,
        "_internal_originalTime": 1689817838000
    },
    {
        "time": 1689817839000,
        "value": 139.145,
        "_internal_originalTime": 1689817839000
    },
    {
        "time": 1689817841000,
        "value": 139.145,
        "_internal_originalTime": 1689817841000
    },
    {
        "time": 1689817842000,
        "value": 139.144,
        "_internal_originalTime": 1689817842000
    },
    {
        "time": 1689817844000,
        "value": 139.144,
        "_internal_originalTime": 1689817844000
    },
    {
        "time": 1689817845000,
        "value": 139.149,
        "_internal_originalTime": 1689817845000
    },
    {
        "time": 1689817846000,
        "value": 139.148,
        "_internal_originalTime": 1689817846000
    },
    {
        "time": 1689817847000,
        "value": 139.157,
        "_internal_originalTime": 1689817847000
    },
    {
        "time": 1689817848000,
        "value": 139.158,
        "_internal_originalTime": 1689817848000
    },
    {
        "time": 1689817850000,
        "value": 139.156,
        "_internal_originalTime": 1689817850000
    },
    {
        "time": 1689817851000,
        "value": 139.154,
        "_internal_originalTime": 1689817851000
    },
    {
        "time": 1689817852000,
        "value": 139.154,
        "_internal_originalTime": 1689817852000
    },
    {
        "time": 1689817854000,
        "value": 139.162,
        "_internal_originalTime": 1689817854000
    },
    {
        "time": 1689817855000,
        "value": 139.162,
        "_internal_originalTime": 1689817855000
    },
    {
        "time": 1689817856000,
        "value": 139.159,
        "_internal_originalTime": 1689817856000
    },
    {
        "time": 1689817858000,
        "value": 139.162,
        "_internal_originalTime": 1689817858000
    },
    {
        "time": 1689817859000,
        "value": 139.164,
        "_internal_originalTime": 1689817859000
    },
    {
        "time": 1689817860000,
        "value": 139.165,
        "_internal_originalTime": 1689817860000
    },
    {
        "time": 1689817861000,
        "value": 139.166,
        "_internal_originalTime": 1689817861000
    },
    {
        "time": 1689817862000,
        "value": 139.166,
        "_internal_originalTime": 1689817862000
    },
    {
        "time": 1689817864000,
        "value": 139.168,
        "_internal_originalTime": 1689817864000
    },
    {
        "time": 1689817865000,
        "value": 139.165,
        "_internal_originalTime": 1689817865000
    },
    {
        "time": 1689817866000,
        "value": 139.162,
        "_internal_originalTime": 1689817866000
    },
    {
        "time": 1689817867000,
        "value": 139.167,
        "_internal_originalTime": 1689817867000
    },
    {
        "time": 1689817869000,
        "value": 139.165,
        "_internal_originalTime": 1689817869000
    },
    {
        "time": 1689817870000,
        "value": 139.167,
        "_internal_originalTime": 1689817870000
    },
    {
        "time": 1689817871000,
        "value": 139.164,
        "_internal_originalTime": 1689817871000
    },
    {
        "time": 1689817872000,
        "value": 139.171,
        "_internal_originalTime": 1689817872000
    },
    {
        "time": 1689817873000,
        "value": 139.167,
        "_internal_originalTime": 1689817873000
    },
    {
        "time": 1689817874000,
        "value": 139.162,
        "_internal_originalTime": 1689817874000
    },
    {
        "time": 1689817880000,
        "value": 139.162,
        "_internal_originalTime": 1689817880000
    },
    {
        "time": 1689817881000,
        "value": 139.171,
        "_internal_originalTime": 1689817881000
    },
    {
        "time": 1689817882000,
        "value": 139.173,
        "_internal_originalTime": 1689817882000
    },
    {
        "time": 1689817883000,
        "value": 139.17,
        "_internal_originalTime": 1689817883000
    },
    {
        "time": 1689817884000,
        "value": 139.176,
        "_internal_originalTime": 1689817884000
    },
    {
        "time": 1689817885000,
        "value": 139.177,
        "_internal_originalTime": 1689817885000
    },
    {
        "time": 1689817886000,
        "value": 139.174,
        "_internal_originalTime": 1689817886000
    },
    {
        "time": 1689817889000,
        "value": 139.168,
        "_internal_originalTime": 1689817889000
    },
    {
        "time": 1689817891000,
        "value": 139.175,
        "_internal_originalTime": 1689817891000
    },
    {
        "time": 1689817892000,
        "value": 139.175,
        "_internal_originalTime": 1689817892000
    },
    {
        "time": 1689817893000,
        "value": 139.172,
        "_internal_originalTime": 1689817893000
    },
    {
        "time": 1689817894000,
        "value": 139.176,
        "_internal_originalTime": 1689817894000
    },
    {
        "time": 1689817895000,
        "value": 139.174,
        "_internal_originalTime": 1689817895000
    },
    {
        "time": 1689817897000,
        "value": 139.175,
        "_internal_originalTime": 1689817897000
    },
    {
        "time": 1689817898000,
        "value": 139.187,
        "_internal_originalTime": 1689817898000
    },
    {
        "time": 1689817900000,
        "value": 139.191,
        "_internal_originalTime": 1689817900000
    },
    {
        "time": 1689817902000,
        "value": 139.182,
        "_internal_originalTime": 1689817902000
    },
    {
        "time": 1689817903000,
        "value": 139.18,
        "_internal_originalTime": 1689817903000
    },
    {
        "time": 1689817904000,
        "value": 139.185,
        "_internal_originalTime": 1689817904000
    },
    {
        "time": 1689817905000,
        "value": 139.182,
        "_internal_originalTime": 1689817905000
    },
    {
        "time": 1689817906000,
        "value": 139.184,
        "_internal_originalTime": 1689817906000
    },
    {
        "time": 1689817907000,
        "value": 139.183,
        "_internal_originalTime": 1689817907000
    },
    {
        "time": 1689817909000,
        "value": 139.184,
        "_internal_originalTime": 1689817909000
    },
    {
        "time": 1689817910000,
        "value": 139.18,
        "_internal_originalTime": 1689817910000
    },
    {
        "time": 1689817911000,
        "value": 139.18,
        "_internal_originalTime": 1689817911000
    },
    {
        "time": 1689817912000,
        "value": 139.181,
        "_internal_originalTime": 1689817912000
    },
    {
        "time": 1689817913000,
        "value": 139.182,
        "_internal_originalTime": 1689817913000
    },
    {
        "time": 1689817914000,
        "value": 139.186,
        "_internal_originalTime": 1689817914000
    },
    {
        "time": 1689817915000,
        "value": 139.183,
        "_internal_originalTime": 1689817915000
    },
    {
        "time": 1689817917000,
        "value": 139.187,
        "_internal_originalTime": 1689817917000
    },
    {
        "time": 1689817919000,
        "value": 139.187,
        "_internal_originalTime": 1689817919000
    },
    {
        "time": 1689817920000,
        "value": 139.18,
        "_internal_originalTime": 1689817920000
    },
    {
        "time": 1689817923000,
        "value": 139.17,
        "_internal_originalTime": 1689817923000
    },
    {
        "time": 1689817924000,
        "value": 139.18,
        "_internal_originalTime": 1689817924000
    },
    {
        "time": 1689817925000,
        "value": 139.18,
        "_internal_originalTime": 1689817925000
    },
    {
        "time": 1689817926000,
        "value": 139.176,
        "_internal_originalTime": 1689817926000
    },
    {
        "time": 1689817927000,
        "value": 139.176,
        "_internal_originalTime": 1689817927000
    },
    {
        "time": 1689817929000,
        "value": 139.175,
        "_internal_originalTime": 1689817929000
    },
    {
        "time": 1689817930000,
        "value": 139.17,
        "_internal_originalTime": 1689817930000
    },
    {
        "time": 1689817932000,
        "value": 139.168,
        "_internal_originalTime": 1689817932000
    },
    {
        "time": 1689817933000,
        "value": 139.174,
        "_internal_originalTime": 1689817933000
    },
    {
        "time": 1689817934000,
        "value": 139.174,
        "_internal_originalTime": 1689817934000
    },
    {
        "time": 1689817935000,
        "value": 139.177,
        "_internal_originalTime": 1689817935000
    },
    {
        "time": 1689817937000,
        "value": 139.173,
        "_internal_originalTime": 1689817937000
    },
    {
        "time": 1689817939000,
        "value": 139.173,
        "_internal_originalTime": 1689817939000
    },
    {
        "time": 1689817940000,
        "value": 139.171,
        "_internal_originalTime": 1689817940000
    },
    {
        "time": 1689817942000,
        "value": 139.17,
        "_internal_originalTime": 1689817942000
    },
    {
        "time": 1689817943000,
        "value": 139.178,
        "_internal_originalTime": 1689817943000
    },
    {
        "time": 1689817945000,
        "value": 139.175,
        "_internal_originalTime": 1689817945000
    },
    {
        "time": 1689817946000,
        "value": 139.174,
        "_internal_originalTime": 1689817946000
    },
    {
        "time": 1689817948000,
        "value": 139.172,
        "_internal_originalTime": 1689817948000
    },
    {
        "time": 1689817955000,
        "value": 139.174,
        "_internal_originalTime": 1689817955000
    },
    {
        "time": 1689817956000,
        "value": 139.175,
        "_internal_originalTime": 1689817956000
    },
    {
        "time": 1689817957000,
        "value": 139.179,
        "_internal_originalTime": 1689817957000
    },
    {
        "time": 1689817959000,
        "value": 139.182,
        "_internal_originalTime": 1689817959000
    },
    {
        "time": 1689817961000,
        "value": 139.185,
        "_internal_originalTime": 1689817961000
    },
    {
        "time": 1689817962000,
        "value": 139.186,
        "_internal_originalTime": 1689817962000
    },
    {
        "time": 1689817963000,
        "value": 139.185,
        "_internal_originalTime": 1689817963000
    },
    {
        "time": 1689817964000,
        "value": 139.185,
        "_internal_originalTime": 1689817964000
    },
    {
        "time": 1689817965000,
        "value": 139.183,
        "_internal_originalTime": 1689817965000
    },
    {
        "time": 1689817966000,
        "value": 139.182,
        "_internal_originalTime": 1689817966000
    },
    {
        "time": 1689817970000,
        "value": 139.18,
        "_internal_originalTime": 1689817970000
    },
    {
        "time": 1689817971000,
        "value": 139.186,
        "_internal_originalTime": 1689817971000
    },
    {
        "time": 1689817972000,
        "value": 139.185,
        "_internal_originalTime": 1689817972000
    },
    {
        "time": 1689817974000,
        "value": 139.184,
        "_internal_originalTime": 1689817974000
    },
    {
        "time": 1689817977000,
        "value": 139.186,
        "_internal_originalTime": 1689817977000
    },
    {
        "time": 1689817978000,
        "value": 139.18,
        "_internal_originalTime": 1689817978000
    },
    {
        "time": 1689817980000,
        "value": 139.183,
        "_internal_originalTime": 1689817980000
    },
    {
        "time": 1689817981000,
        "value": 139.182,
        "_internal_originalTime": 1689817981000
    },
    {
        "time": 1689817982000,
        "value": 139.182,
        "_internal_originalTime": 1689817982000
    },
    {
        "time": 1689817983000,
        "value": 139.191,
        "_internal_originalTime": 1689817983000
    },
    {
        "time": 1689817984000,
        "value": 139.189,
        "_internal_originalTime": 1689817984000
    },
    {
        "time": 1689817985000,
        "value": 139.19,
        "_internal_originalTime": 1689817985000
    },
    {
        "time": 1689817987000,
        "value": 139.192,
        "_internal_originalTime": 1689817987000
    },
    {
        "time": 1689817989000,
        "value": 139.192,
        "_internal_originalTime": 1689817989000
    },
    {
        "time": 1689817991000,
        "value": 139.19,
        "_internal_originalTime": 1689817991000
    },
    {
        "time": 1689817993000,
        "value": 139.194,
        "_internal_originalTime": 1689817993000
    },
    {
        "time": 1689817994000,
        "value": 139.195,
        "_internal_originalTime": 1689817994000
    },
    {
        "time": 1689817995000,
        "value": 139.192,
        "_internal_originalTime": 1689817995000
    },
    {
        "time": 1689817996000,
        "value": 139.189,
        "_internal_originalTime": 1689817996000
    },
    {
        "time": 1689817999000,
        "value": 139.19,
        "_internal_originalTime": 1689817999000
    },
    {
        "time": 1689818000000,
        "value": 139.19,
        "_internal_originalTime": 1689818000000
    },
    {
        "time": 1689818002000,
        "value": 139.189,
        "_internal_originalTime": 1689818002000
    },
    {
        "time": 1689818003000,
        "value": 139.193,
        "_internal_originalTime": 1689818003000
    },
    {
        "time": 1689818005000,
        "value": 139.193,
        "_internal_originalTime": 1689818005000
    },
    {
        "time": 1689818006000,
        "value": 139.189,
        "_internal_originalTime": 1689818006000
    },
    {
        "time": 1689818008000,
        "value": 139.202,
        "_internal_originalTime": 1689818008000
    },
    {
        "time": 1689818009000,
        "value": 139.205,
        "_internal_originalTime": 1689818009000
    },
    {
        "time": 1689818010000,
        "value": 139.204,
        "_internal_originalTime": 1689818010000
    },
    {
        "time": 1689818011000,
        "value": 139.204,
        "_internal_originalTime": 1689818011000
    },
    {
        "time": 1689818013000,
        "value": 139.205,
        "_internal_originalTime": 1689818013000
    },
    {
        "time": 1689818014000,
        "value": 139.206,
        "_internal_originalTime": 1689818014000
    },
    {
        "time": 1689818015000,
        "value": 139.202,
        "_internal_originalTime": 1689818015000
    },
    {
        "time": 1689818017000,
        "value": 139.2,
        "_internal_originalTime": 1689818017000
    },
    {
        "time": 1689818019000,
        "value": 139.21,
        "_internal_originalTime": 1689818019000
    },
    {
        "time": 1689818020000,
        "value": 139.213,
        "_internal_originalTime": 1689818020000
    },
    {
        "time": 1689818022000,
        "value": 139.213,
        "_internal_originalTime": 1689818022000
    },
    {
        "time": 1689818024000,
        "value": 139.21,
        "_internal_originalTime": 1689818024000
    },
    {
        "time": 1689818025000,
        "value": 139.214,
        "_internal_originalTime": 1689818025000
    },
    {
        "time": 1689818026000,
        "value": 139.214,
        "_internal_originalTime": 1689818026000
    },
    {
        "time": 1689818028000,
        "value": 139.202,
        "_internal_originalTime": 1689818028000
    },
    {
        "time": 1689818029000,
        "value": 139.207,
        "_internal_originalTime": 1689818029000
    },
    {
        "time": 1689818030000,
        "value": 139.202,
        "_internal_originalTime": 1689818030000
    },
    {
        "time": 1689818031000,
        "value": 139.201,
        "_internal_originalTime": 1689818031000
    },
    {
        "time": 1689818033000,
        "value": 139.201,
        "_internal_originalTime": 1689818033000
    },
    {
        "time": 1689818037000,
        "value": 139.204,
        "_internal_originalTime": 1689818037000
    },
    {
        "time": 1689818038000,
        "value": 139.199,
        "_internal_originalTime": 1689818038000
    },
    {
        "time": 1689818039000,
        "value": 139.202,
        "_internal_originalTime": 1689818039000
    },
    {
        "time": 1689818040000,
        "value": 139.206,
        "_internal_originalTime": 1689818040000
    },
    {
        "time": 1689818041000,
        "value": 139.204,
        "_internal_originalTime": 1689818041000
    },
    {
        "time": 1689818042000,
        "value": 139.203,
        "_internal_originalTime": 1689818042000
    },
    {
        "time": 1689818043000,
        "value": 139.202,
        "_internal_originalTime": 1689818043000
    },
    {
        "time": 1689818045000,
        "value": 139.202,
        "_internal_originalTime": 1689818045000
    },
    {
        "time": 1689818046000,
        "value": 139.201,
        "_internal_originalTime": 1689818046000
    },
    {
        "time": 1689818048000,
        "value": 139.203,
        "_internal_originalTime": 1689818048000
    },
    {
        "time": 1689818049000,
        "value": 139.202,
        "_internal_originalTime": 1689818049000
    },
    {
        "time": 1689818051000,
        "value": 139.196,
        "_internal_originalTime": 1689818051000
    },
    {
        "time": 1689818052000,
        "value": 139.195,
        "_internal_originalTime": 1689818052000
    },
    {
        "time": 1689818054000,
        "value": 139.19,
        "_internal_originalTime": 1689818054000
    },
    {
        "time": 1689818055000,
        "value": 139.195,
        "_internal_originalTime": 1689818055000
    },
    {
        "time": 1689818056000,
        "value": 139.195,
        "_internal_originalTime": 1689818056000
    },
    {
        "time": 1689818057000,
        "value": 139.196,
        "_internal_originalTime": 1689818057000
    },
    {
        "time": 1689818058000,
        "value": 139.195,
        "_internal_originalTime": 1689818058000
    },
    {
        "time": 1689818060000,
        "value": 139.196,
        "_internal_originalTime": 1689818060000
    },
    {
        "time": 1689818061000,
        "value": 139.19,
        "_internal_originalTime": 1689818061000
    },
    {
        "time": 1689818062000,
        "value": 139.197,
        "_internal_originalTime": 1689818062000
    },
    {
        "time": 1689818063000,
        "value": 139.19,
        "_internal_originalTime": 1689818063000
    },
    {
        "time": 1689818064000,
        "value": 139.195,
        "_internal_originalTime": 1689818064000
    },
    {
        "time": 1689818067000,
        "value": 139.196,
        "_internal_originalTime": 1689818067000
    },
    {
        "time": 1689818068000,
        "value": 139.195,
        "_internal_originalTime": 1689818068000
    },
    {
        "time": 1689818070000,
        "value": 139.19,
        "_internal_originalTime": 1689818070000
    },
    {
        "time": 1689818071000,
        "value": 139.196,
        "_internal_originalTime": 1689818071000
    },
    {
        "time": 1689818072000,
        "value": 139.195,
        "_internal_originalTime": 1689818072000
    },
    {
        "time": 1689818074000,
        "value": 139.195,
        "_internal_originalTime": 1689818074000
    },
    {
        "time": 1689818075000,
        "value": 139.192,
        "_internal_originalTime": 1689818075000
    },
    {
        "time": 1689818077000,
        "value": 139.19,
        "_internal_originalTime": 1689818077000
    },
    {
        "time": 1689818078000,
        "value": 139.194,
        "_internal_originalTime": 1689818078000
    },
    {
        "time": 1689818079000,
        "value": 139.19,
        "_internal_originalTime": 1689818079000
    },
    {
        "time": 1689818080000,
        "value": 139.19,
        "_internal_originalTime": 1689818080000
    },
    {
        "time": 1689818082000,
        "value": 139.19,
        "_internal_originalTime": 1689818082000
    },
    {
        "time": 1689818083000,
        "value": 139.19,
        "_internal_originalTime": 1689818083000
    },
    {
        "time": 1689818084000,
        "value": 139.195,
        "_internal_originalTime": 1689818084000
    },
    {
        "time": 1689818085000,
        "value": 139.19,
        "_internal_originalTime": 1689818085000
    },
    {
        "time": 1689818086000,
        "value": 139.19,
        "_internal_originalTime": 1689818086000
    },
    {
        "time": 1689818087000,
        "value": 139.191,
        "_internal_originalTime": 1689818087000
    },
    {
        "time": 1689818089000,
        "value": 139.195,
        "_internal_originalTime": 1689818089000
    },
    {
        "time": 1689818090000,
        "value": 139.19,
        "_internal_originalTime": 1689818090000
    },
    {
        "time": 1689818092000,
        "value": 139.19,
        "_internal_originalTime": 1689818092000
    },
    {
        "time": 1689818093000,
        "value": 139.19,
        "_internal_originalTime": 1689818093000
    },
    {
        "time": 1689818095000,
        "value": 139.189,
        "_internal_originalTime": 1689818095000
    },
    {
        "time": 1689818098000,
        "value": 139.188,
        "_internal_originalTime": 1689818098000
    },
    {
        "time": 1689818101000,
        "value": 139.19,
        "_internal_originalTime": 1689818101000
    },
    {
        "time": 1689818102000,
        "value": 139.185,
        "_internal_originalTime": 1689818102000
    },
    {
        "time": 1689818103000,
        "value": 139.185,
        "_internal_originalTime": 1689818103000
    },
    {
        "time": 1689818104000,
        "value": 139.185,
        "_internal_originalTime": 1689818104000
    },
    {
        "time": 1689818105000,
        "value": 139.18,
        "_internal_originalTime": 1689818105000
    },
    {
        "time": 1689818109000,
        "value": 139.183,
        "_internal_originalTime": 1689818109000
    },
    {
        "time": 1689818112000,
        "value": 139.18,
        "_internal_originalTime": 1689818112000
    },
    {
        "time": 1689818114000,
        "value": 139.186,
        "_internal_originalTime": 1689818114000
    },
    {
        "time": 1689818115000,
        "value": 139.185,
        "_internal_originalTime": 1689818115000
    },
    {
        "time": 1689818117000,
        "value": 139.18,
        "_internal_originalTime": 1689818117000
    },
    {
        "time": 1689818119000,
        "value": 139.181,
        "_internal_originalTime": 1689818119000
    },
    {
        "time": 1689818120000,
        "value": 139.188,
        "_internal_originalTime": 1689818120000
    },
    {
        "time": 1689818122000,
        "value": 139.199,
        "_internal_originalTime": 1689818122000
    },
    {
        "time": 1689818124000,
        "value": 139.196,
        "_internal_originalTime": 1689818124000
    },
    {
        "time": 1689818125000,
        "value": 139.193,
        "_internal_originalTime": 1689818125000
    },
    {
        "time": 1689818126000,
        "value": 139.189,
        "_internal_originalTime": 1689818126000
    },
    {
        "time": 1689818128000,
        "value": 139.19,
        "_internal_originalTime": 1689818128000
    },
    {
        "time": 1689818129000,
        "value": 139.195,
        "_internal_originalTime": 1689818129000
    },
    {
        "time": 1689818131000,
        "value": 139.197,
        "_internal_originalTime": 1689818131000
    },
    {
        "time": 1689818133000,
        "value": 139.193,
        "_internal_originalTime": 1689818133000
    },
    {
        "time": 1689818134000,
        "value": 139.192,
        "_internal_originalTime": 1689818134000
    },
    {
        "time": 1689818135000,
        "value": 139.193,
        "_internal_originalTime": 1689818135000
    },
    {
        "time": 1689818136000,
        "value": 139.192,
        "_internal_originalTime": 1689818136000
    },
    {
        "time": 1689818137000,
        "value": 139.198,
        "_internal_originalTime": 1689818137000
    },
    {
        "time": 1689818139000,
        "value": 139.198,
        "_internal_originalTime": 1689818139000
    },
    {
        "time": 1689818141000,
        "value": 139.197,
        "_internal_originalTime": 1689818141000
    },
    {
        "time": 1689818142000,
        "value": 139.202,
        "_internal_originalTime": 1689818142000
    },
    {
        "time": 1689818144000,
        "value": 139.196,
        "_internal_originalTime": 1689818144000
    },
    {
        "time": 1689818146000,
        "value": 139.2,
        "_internal_originalTime": 1689818146000
    },
    {
        "time": 1689818147000,
        "value": 139.206,
        "_internal_originalTime": 1689818147000
    },
    {
        "time": 1689818149000,
        "value": 139.202,
        "_internal_originalTime": 1689818149000
    },
    {
        "time": 1689818150000,
        "value": 139.21,
        "_internal_originalTime": 1689818150000
    },
    {
        "time": 1689818152000,
        "value": 139.213,
        "_internal_originalTime": 1689818152000
    },
    {
        "time": 1689818153000,
        "value": 139.214,
        "_internal_originalTime": 1689818153000
    },
    {
        "time": 1689818155000,
        "value": 139.212,
        "_internal_originalTime": 1689818155000
    },
    {
        "time": 1689818156000,
        "value": 139.222,
        "_internal_originalTime": 1689818156000
    },
    {
        "time": 1689818157000,
        "value": 139.216,
        "_internal_originalTime": 1689818157000
    },
    {
        "time": 1689818158000,
        "value": 139.216,
        "_internal_originalTime": 1689818158000
    },
    {
        "time": 1689818161000,
        "value": 139.217,
        "_internal_originalTime": 1689818161000
    },
    {
        "time": 1689818163000,
        "value": 139.222,
        "_internal_originalTime": 1689818163000
    },
    {
        "time": 1689818164000,
        "value": 139.221,
        "_internal_originalTime": 1689818164000
    },
    {
        "time": 1689818166000,
        "value": 139.22,
        "_internal_originalTime": 1689818166000
    },
    {
        "time": 1689818167000,
        "value": 139.219,
        "_internal_originalTime": 1689818167000
    },
    {
        "time": 1689818168000,
        "value": 139.219,
        "_internal_originalTime": 1689818168000
    },
    {
        "time": 1689818169000,
        "value": 139.223,
        "_internal_originalTime": 1689818169000
    },
    {
        "time": 1689818170000,
        "value": 139.223,
        "_internal_originalTime": 1689818170000
    },
    {
        "time": 1689818171000,
        "value": 139.218,
        "_internal_originalTime": 1689818171000
    },
    {
        "time": 1689818172000,
        "value": 139.219,
        "_internal_originalTime": 1689818172000
    },
    {
        "time": 1689818174000,
        "value": 139.217,
        "_internal_originalTime": 1689818174000
    },
    {
        "time": 1689818176000,
        "value": 139.218,
        "_internal_originalTime": 1689818176000
    },
    {
        "time": 1689818178000,
        "value": 139.222,
        "_internal_originalTime": 1689818178000
    },
    {
        "time": 1689818179000,
        "value": 139.224,
        "_internal_originalTime": 1689818179000
    },
    {
        "time": 1689818180000,
        "value": 139.231,
        "_internal_originalTime": 1689818180000
    },
    {
        "time": 1689818181000,
        "value": 139.221,
        "_internal_originalTime": 1689818181000
    },
    {
        "time": 1689818182000,
        "value": 139.224,
        "_internal_originalTime": 1689818182000
    },
    {
        "time": 1689818183000,
        "value": 139.223,
        "_internal_originalTime": 1689818183000
    },
    {
        "time": 1689818185000,
        "value": 139.221,
        "_internal_originalTime": 1689818185000
    },
    {
        "time": 1689818187000,
        "value": 139.22,
        "_internal_originalTime": 1689818187000
    },
    {
        "time": 1689818188000,
        "value": 139.223,
        "_internal_originalTime": 1689818188000
    },
    {
        "time": 1689818189000,
        "value": 139.22,
        "_internal_originalTime": 1689818189000
    },
    {
        "time": 1689818190000,
        "value": 139.216,
        "_internal_originalTime": 1689818190000
    },
    {
        "time": 1689818192000,
        "value": 139.223,
        "_internal_originalTime": 1689818192000
    },
    {
        "time": 1689818193000,
        "value": 139.224,
        "_internal_originalTime": 1689818193000
    },
    {
        "time": 1689818194000,
        "value": 139.226,
        "_internal_originalTime": 1689818194000
    },
    {
        "time": 1689818195000,
        "value": 139.22,
        "_internal_originalTime": 1689818195000
    },
    {
        "time": 1689818197000,
        "value": 139.222,
        "_internal_originalTime": 1689818197000
    },
    {
        "time": 1689818200000,
        "value": 139.22,
        "_internal_originalTime": 1689818200000
    },
    {
        "time": 1689818202000,
        "value": 139.233,
        "_internal_originalTime": 1689818202000
    },
    {
        "time": 1689818203000,
        "value": 139.234,
        "_internal_originalTime": 1689818203000
    },
    {
        "time": 1689818204000,
        "value": 139.232,
        "_internal_originalTime": 1689818204000
    },
    {
        "time": 1689818205000,
        "value": 139.234,
        "_internal_originalTime": 1689818205000
    },
    {
        "time": 1689818206000,
        "value": 139.233,
        "_internal_originalTime": 1689818206000
    },
    {
        "time": 1689818207000,
        "value": 139.23,
        "_internal_originalTime": 1689818207000
    },
    {
        "time": 1689818212000,
        "value": 139.23,
        "_internal_originalTime": 1689818212000
    },
    {
        "time": 1689818214000,
        "value": 139.229,
        "_internal_originalTime": 1689818214000
    },
    {
        "time": 1689818215000,
        "value": 139.227,
        "_internal_originalTime": 1689818215000
    },
    {
        "time": 1689818216000,
        "value": 139.23,
        "_internal_originalTime": 1689818216000
    },
    {
        "time": 1689818217000,
        "value": 139.228,
        "_internal_originalTime": 1689818217000
    },
    {
        "time": 1689818218000,
        "value": 139.231,
        "_internal_originalTime": 1689818218000
    },
    {
        "time": 1689818219000,
        "value": 139.232,
        "_internal_originalTime": 1689818219000
    },
    {
        "time": 1689818220000,
        "value": 139.233,
        "_internal_originalTime": 1689818220000
    },
    {
        "time": 1689818222000,
        "value": 139.228,
        "_internal_originalTime": 1689818222000
    },
    {
        "time": 1689818224000,
        "value": 139.231,
        "_internal_originalTime": 1689818224000
    },
    {
        "time": 1689818225000,
        "value": 139.225,
        "_internal_originalTime": 1689818225000
    },
    {
        "time": 1689818226000,
        "value": 139.225,
        "_internal_originalTime": 1689818226000
    },
    {
        "time": 1689818227000,
        "value": 139.226,
        "_internal_originalTime": 1689818227000
    },
    {
        "time": 1689818228000,
        "value": 139.221,
        "_internal_originalTime": 1689818228000
    },
    {
        "time": 1689818229000,
        "value": 139.22,
        "_internal_originalTime": 1689818229000
    },
    {
        "time": 1689818231000,
        "value": 139.223,
        "_internal_originalTime": 1689818231000
    },
    {
        "time": 1689818233000,
        "value": 139.226,
        "_internal_originalTime": 1689818233000
    },
    {
        "time": 1689818234000,
        "value": 139.225,
        "_internal_originalTime": 1689818234000
    },
    {
        "time": 1689818236000,
        "value": 139.226,
        "_internal_originalTime": 1689818236000
    },
    {
        "time": 1689818239000,
        "value": 139.225,
        "_internal_originalTime": 1689818239000
    },
    {
        "time": 1689818240000,
        "value": 139.225,
        "_internal_originalTime": 1689818240000
    },
    {
        "time": 1689818242000,
        "value": 139.222,
        "_internal_originalTime": 1689818242000
    },
    {
        "time": 1689818243000,
        "value": 139.22,
        "_internal_originalTime": 1689818243000
    },
    {
        "time": 1689818245000,
        "value": 139.226,
        "_internal_originalTime": 1689818245000
    },
    {
        "time": 1689818246000,
        "value": 139.225,
        "_internal_originalTime": 1689818246000
    },
    {
        "time": 1689818247000,
        "value": 139.22,
        "_internal_originalTime": 1689818247000
    },
    {
        "time": 1689818250000,
        "value": 139.225,
        "_internal_originalTime": 1689818250000
    },
    {
        "time": 1689818251000,
        "value": 139.22,
        "_internal_originalTime": 1689818251000
    },
    {
        "time": 1689818254000,
        "value": 139.22,
        "_internal_originalTime": 1689818254000
    },
    {
        "time": 1689818255000,
        "value": 139.222,
        "_internal_originalTime": 1689818255000
    },
    {
        "time": 1689818256000,
        "value": 139.222,
        "_internal_originalTime": 1689818256000
    },
    {
        "time": 1689818258000,
        "value": 139.221,
        "_internal_originalTime": 1689818258000
    },
    {
        "time": 1689818259000,
        "value": 139.22,
        "_internal_originalTime": 1689818259000
    },
    {
        "time": 1689818260000,
        "value": 139.218,
        "_internal_originalTime": 1689818260000
    },
    {
        "time": 1689818261000,
        "value": 139.215,
        "_internal_originalTime": 1689818261000
    },
    {
        "time": 1689818263000,
        "value": 139.216,
        "_internal_originalTime": 1689818263000
    },
    {
        "time": 1689818264000,
        "value": 139.21,
        "_internal_originalTime": 1689818264000
    },
    {
        "time": 1689818265000,
        "value": 139.215,
        "_internal_originalTime": 1689818265000
    },
    {
        "time": 1689818267000,
        "value": 139.21,
        "_internal_originalTime": 1689818267000
    },
    {
        "time": 1689818269000,
        "value": 139.216,
        "_internal_originalTime": 1689818269000
    },
    {
        "time": 1689818271000,
        "value": 139.216,
        "_internal_originalTime": 1689818271000
    },
    {
        "time": 1689818273000,
        "value": 139.216,
        "_internal_originalTime": 1689818273000
    },
    {
        "time": 1689818275000,
        "value": 139.21,
        "_internal_originalTime": 1689818275000
    },
    {
        "time": 1689818277000,
        "value": 139.212,
        "_internal_originalTime": 1689818277000
    },
    {
        "time": 1689818278000,
        "value": 139.216,
        "_internal_originalTime": 1689818278000
    },
    {
        "time": 1689818279000,
        "value": 139.216,
        "_internal_originalTime": 1689818279000
    },
    {
        "time": 1689818280000,
        "value": 139.216,
        "_internal_originalTime": 1689818280000
    },
    {
        "time": 1689818281000,
        "value": 139.222,
        "_internal_originalTime": 1689818281000
    },
    {
        "time": 1689818282000,
        "value": 139.222,
        "_internal_originalTime": 1689818282000
    },
    {
        "time": 1689818283000,
        "value": 139.218,
        "_internal_originalTime": 1689818283000
    },
    {
        "time": 1689818284000,
        "value": 139.222,
        "_internal_originalTime": 1689818284000
    },
    {
        "time": 1689818285000,
        "value": 139.224,
        "_internal_originalTime": 1689818285000
    },
    {
        "time": 1689818286000,
        "value": 139.22,
        "_internal_originalTime": 1689818286000
    },
    {
        "time": 1689818289000,
        "value": 139.222,
        "_internal_originalTime": 1689818289000
    },
    {
        "time": 1689818290000,
        "value": 139.221,
        "_internal_originalTime": 1689818290000
    },
    {
        "time": 1689818293000,
        "value": 139.21,
        "_internal_originalTime": 1689818293000
    },
    {
        "time": 1689818294000,
        "value": 139.218,
        "_internal_originalTime": 1689818294000
    },
    {
        "time": 1689818295000,
        "value": 139.219,
        "_internal_originalTime": 1689818295000
    },
    {
        "time": 1689818296000,
        "value": 139.214,
        "_internal_originalTime": 1689818296000
    },
    {
        "time": 1689818299000,
        "value": 139.212,
        "_internal_originalTime": 1689818299000
    },
    {
        "time": 1689818302000,
        "value": 139.21,
        "_internal_originalTime": 1689818302000
    },
    {
        "time": 1689818303000,
        "value": 139.21,
        "_internal_originalTime": 1689818303000
    },
    {
        "time": 1689818305000,
        "value": 139.216,
        "_internal_originalTime": 1689818305000
    },
    {
        "time": 1689818306000,
        "value": 139.215,
        "_internal_originalTime": 1689818306000
    },
    {
        "time": 1689818308000,
        "value": 139.21,
        "_internal_originalTime": 1689818308000
    },
    {
        "time": 1689818309000,
        "value": 139.216,
        "_internal_originalTime": 1689818309000
    },
    {
        "time": 1689818311000,
        "value": 139.21,
        "_internal_originalTime": 1689818311000
    },
    {
        "time": 1689818312000,
        "value": 139.21,
        "_internal_originalTime": 1689818312000
    },
    {
        "time": 1689818314000,
        "value": 139.215,
        "_internal_originalTime": 1689818314000
    },
    {
        "time": 1689818316000,
        "value": 139.21,
        "_internal_originalTime": 1689818316000
    },
    {
        "time": 1689818317000,
        "value": 139.21,
        "_internal_originalTime": 1689818317000
    },
    {
        "time": 1689818318000,
        "value": 139.215,
        "_internal_originalTime": 1689818318000
    },
    {
        "time": 1689818319000,
        "value": 139.212,
        "_internal_originalTime": 1689818319000
    },
    {
        "time": 1689818321000,
        "value": 139.212,
        "_internal_originalTime": 1689818321000
    },
    {
        "time": 1689818322000,
        "value": 139.215,
        "_internal_originalTime": 1689818322000
    },
    {
        "time": 1689818323000,
        "value": 139.211,
        "_internal_originalTime": 1689818323000
    },
    {
        "time": 1689818324000,
        "value": 139.212,
        "_internal_originalTime": 1689818324000
    },
    {
        "time": 1689818326000,
        "value": 139.215,
        "_internal_originalTime": 1689818326000
    },
    {
        "time": 1689818327000,
        "value": 139.21,
        "_internal_originalTime": 1689818327000
    },
    {
        "time": 1689818330000,
        "value": 139.212,
        "_internal_originalTime": 1689818330000
    },
    {
        "time": 1689818331000,
        "value": 139.215,
        "_internal_originalTime": 1689818331000
    },
    {
        "time": 1689818332000,
        "value": 139.21,
        "_internal_originalTime": 1689818332000
    },
    {
        "time": 1689818333000,
        "value": 139.21,
        "_internal_originalTime": 1689818333000
    },
    {
        "time": 1689818338000,
        "value": 139.209,
        "_internal_originalTime": 1689818338000
    },
    {
        "time": 1689818339000,
        "value": 139.21,
        "_internal_originalTime": 1689818339000
    },
    {
        "time": 1689818341000,
        "value": 139.2,
        "_internal_originalTime": 1689818341000
    },
    {
        "time": 1689818342000,
        "value": 139.203,
        "_internal_originalTime": 1689818342000
    },
    {
        "time": 1689818344000,
        "value": 139.198,
        "_internal_originalTime": 1689818344000
    },
    {
        "time": 1689818345000,
        "value": 139.203,
        "_internal_originalTime": 1689818345000
    },
    {
        "time": 1689818346000,
        "value": 139.205,
        "_internal_originalTime": 1689818346000
    },
    {
        "time": 1689818348000,
        "value": 139.205,
        "_internal_originalTime": 1689818348000
    },
    {
        "time": 1689818349000,
        "value": 139.203,
        "_internal_originalTime": 1689818349000
    },
    {
        "time": 1689818352000,
        "value": 139.202,
        "_internal_originalTime": 1689818352000
    },
    {
        "time": 1689818353000,
        "value": 139.205,
        "_internal_originalTime": 1689818353000
    },
    {
        "time": 1689818354000,
        "value": 139.206,
        "_internal_originalTime": 1689818354000
    },
    {
        "time": 1689818355000,
        "value": 139.209,
        "_internal_originalTime": 1689818355000
    },
    {
        "time": 1689818356000,
        "value": 139.206,
        "_internal_originalTime": 1689818356000
    },
    {
        "time": 1689818358000,
        "value": 139.205,
        "_internal_originalTime": 1689818358000
    },
    {
        "time": 1689818359000,
        "value": 139.206,
        "_internal_originalTime": 1689818359000
    },
    {
        "time": 1689818360000,
        "value": 139.206,
        "_internal_originalTime": 1689818360000
    },
    {
        "time": 1689818362000,
        "value": 139.21,
        "_internal_originalTime": 1689818362000
    },
    {
        "time": 1689818363000,
        "value": 139.209,
        "_internal_originalTime": 1689818363000
    },
    {
        "time": 1689818364000,
        "value": 139.207,
        "_internal_originalTime": 1689818364000
    },
    {
        "time": 1689818366000,
        "value": 139.21,
        "_internal_originalTime": 1689818366000
    },
    {
        "time": 1689818368000,
        "value": 139.21,
        "_internal_originalTime": 1689818368000
    },
    {
        "time": 1689818369000,
        "value": 139.204,
        "_internal_originalTime": 1689818369000
    },
    {
        "time": 1689818371000,
        "value": 139.2,
        "_internal_originalTime": 1689818371000
    },
    {
        "time": 1689818372000,
        "value": 139.2,
        "_internal_originalTime": 1689818372000
    },
    {
        "time": 1689818373000,
        "value": 139.202,
        "_internal_originalTime": 1689818373000
    },
    {
        "time": 1689818374000,
        "value": 139.204,
        "_internal_originalTime": 1689818374000
    },
    {
        "time": 1689818375000,
        "value": 139.204,
        "_internal_originalTime": 1689818375000
    },
    {
        "time": 1689818377000,
        "value": 139.2,
        "_internal_originalTime": 1689818377000
    },
    {
        "time": 1689818378000,
        "value": 139.204,
        "_internal_originalTime": 1689818378000
    },
    {
        "time": 1689818379000,
        "value": 139.198,
        "_internal_originalTime": 1689818379000
    },
    {
        "time": 1689818381000,
        "value": 139.197,
        "_internal_originalTime": 1689818381000
    },
    {
        "time": 1689818382000,
        "value": 139.192,
        "_internal_originalTime": 1689818382000
    },
    {
        "time": 1689818383000,
        "value": 139.197,
        "_internal_originalTime": 1689818383000
    },
    {
        "time": 1689818384000,
        "value": 139.192,
        "_internal_originalTime": 1689818384000
    },
    {
        "time": 1689818386000,
        "value": 139.186,
        "_internal_originalTime": 1689818386000
    },
    {
        "time": 1689818390000,
        "value": 139.18,
        "_internal_originalTime": 1689818390000
    },
    {
        "time": 1689818391000,
        "value": 139.188,
        "_internal_originalTime": 1689818391000
    },
    {
        "time": 1689818392000,
        "value": 139.184,
        "_internal_originalTime": 1689818392000
    },
    {
        "time": 1689818393000,
        "value": 139.187,
        "_internal_originalTime": 1689818393000
    },
    {
        "time": 1689818395000,
        "value": 139.185,
        "_internal_originalTime": 1689818395000
    },
    {
        "time": 1689818397000,
        "value": 139.187,
        "_internal_originalTime": 1689818397000
    },
    {
        "time": 1689818398000,
        "value": 139.181,
        "_internal_originalTime": 1689818398000
    },
    {
        "time": 1689818399000,
        "value": 139.183,
        "_internal_originalTime": 1689818399000
    },
    {
        "time": 1689818401000,
        "value": 139.186,
        "_internal_originalTime": 1689818401000
    },
    {
        "time": 1689818403000,
        "value": 139.186,
        "_internal_originalTime": 1689818403000
    },
    {
        "time": 1689818404000,
        "value": 139.186,
        "_internal_originalTime": 1689818404000
    },
    {
        "time": 1689818406000,
        "value": 139.194,
        "_internal_originalTime": 1689818406000
    },
    {
        "time": 1689818407000,
        "value": 139.192,
        "_internal_originalTime": 1689818407000
    },
    {
        "time": 1689818408000,
        "value": 139.195,
        "_internal_originalTime": 1689818408000
    },
    {
        "time": 1689818409000,
        "value": 139.194,
        "_internal_originalTime": 1689818409000
    },
    {
        "time": 1689818411000,
        "value": 139.196,
        "_internal_originalTime": 1689818411000
    },
    {
        "time": 1689818412000,
        "value": 139.196,
        "_internal_originalTime": 1689818412000
    },
    {
        "time": 1689818413000,
        "value": 139.192,
        "_internal_originalTime": 1689818413000
    },
    {
        "time": 1689818414000,
        "value": 139.195,
        "_internal_originalTime": 1689818414000
    },
    {
        "time": 1689818416000,
        "value": 139.197,
        "_internal_originalTime": 1689818416000
    },
    {
        "time": 1689818418000,
        "value": 139.197,
        "_internal_originalTime": 1689818418000
    },
    {
        "time": 1689818419000,
        "value": 139.194,
        "_internal_originalTime": 1689818419000
    },
    {
        "time": 1689818420000,
        "value": 139.197,
        "_internal_originalTime": 1689818420000
    },
    {
        "time": 1689818421000,
        "value": 139.194,
        "_internal_originalTime": 1689818421000
    },
    {
        "time": 1689818422000,
        "value": 139.197,
        "_internal_originalTime": 1689818422000
    },
    {
        "time": 1689818424000,
        "value": 139.198,
        "_internal_originalTime": 1689818424000
    },
    {
        "time": 1689818425000,
        "value": 139.197,
        "_internal_originalTime": 1689818425000
    },
    {
        "time": 1689818429000,
        "value": 139.196,
        "_internal_originalTime": 1689818429000
    },
    {
        "time": 1689818430000,
        "value": 139.204,
        "_internal_originalTime": 1689818430000
    },
    {
        "time": 1689818431000,
        "value": 139.197,
        "_internal_originalTime": 1689818431000
    },
    {
        "time": 1689818432000,
        "value": 139.197,
        "_internal_originalTime": 1689818432000
    },
    {
        "time": 1689818433000,
        "value": 139.198,
        "_internal_originalTime": 1689818433000
    },
    {
        "time": 1689818435000,
        "value": 139.196,
        "_internal_originalTime": 1689818435000
    },
    {
        "time": 1689818436000,
        "value": 139.198,
        "_internal_originalTime": 1689818436000
    },
    {
        "time": 1689818439000,
        "value": 139.198,
        "_internal_originalTime": 1689818439000
    },
    {
        "time": 1689818440000,
        "value": 139.201,
        "_internal_originalTime": 1689818440000
    },
    {
        "time": 1689818441000,
        "value": 139.197,
        "_internal_originalTime": 1689818441000
    },
    {
        "time": 1689818442000,
        "value": 139.198,
        "_internal_originalTime": 1689818442000
    },
    {
        "time": 1689818443000,
        "value": 139.194,
        "_internal_originalTime": 1689818443000
    },
    {
        "time": 1689818444000,
        "value": 139.195,
        "_internal_originalTime": 1689818444000
    },
    {
        "time": 1689818446000,
        "value": 139.192,
        "_internal_originalTime": 1689818446000
    },
    {
        "time": 1689818448000,
        "value": 139.193,
        "_internal_originalTime": 1689818448000
    },
    {
        "time": 1689818449000,
        "value": 139.195,
        "_internal_originalTime": 1689818449000
    },
    {
        "time": 1689818450000,
        "value": 139.188,
        "_internal_originalTime": 1689818450000
    },
    {
        "time": 1689818452000,
        "value": 139.192,
        "_internal_originalTime": 1689818452000
    },
    {
        "time": 1689818453000,
        "value": 139.196,
        "_internal_originalTime": 1689818453000
    },
    {
        "time": 1689818455000,
        "value": 139.203,
        "_internal_originalTime": 1689818455000
    },
    {
        "time": 1689818456000,
        "value": 139.196,
        "_internal_originalTime": 1689818456000
    },
    {
        "time": 1689818457000,
        "value": 139.196,
        "_internal_originalTime": 1689818457000
    },
    {
        "time": 1689818458000,
        "value": 139.199,
        "_internal_originalTime": 1689818458000
    },
    {
        "time": 1689818459000,
        "value": 139.199,
        "_internal_originalTime": 1689818459000
    },
    {
        "time": 1689818462000,
        "value": 139.196,
        "_internal_originalTime": 1689818462000
    },
    {
        "time": 1689818463000,
        "value": 139.203,
        "_internal_originalTime": 1689818463000
    },
    {
        "time": 1689818464000,
        "value": 139.198,
        "_internal_originalTime": 1689818464000
    },
    {
        "time": 1689818465000,
        "value": 139.201,
        "_internal_originalTime": 1689818465000
    },
    {
        "time": 1689818467000,
        "value": 139.195,
        "_internal_originalTime": 1689818467000
    },
    {
        "time": 1689818469000,
        "value": 139.197,
        "_internal_originalTime": 1689818469000
    },
    {
        "time": 1689818470000,
        "value": 139.196,
        "_internal_originalTime": 1689818470000
    },
    {
        "time": 1689818471000,
        "value": 139.203,
        "_internal_originalTime": 1689818471000
    },
    {
        "time": 1689818473000,
        "value": 139.203,
        "_internal_originalTime": 1689818473000
    },
    {
        "time": 1689818474000,
        "value": 139.202,
        "_internal_originalTime": 1689818474000
    },
    {
        "time": 1689818475000,
        "value": 139.199,
        "_internal_originalTime": 1689818475000
    },
    {
        "time": 1689818476000,
        "value": 139.199,
        "_internal_originalTime": 1689818476000
    },
    {
        "time": 1689818477000,
        "value": 139.197,
        "_internal_originalTime": 1689818477000
    },
    {
        "time": 1689818479000,
        "value": 139.195,
        "_internal_originalTime": 1689818479000
    },
    {
        "time": 1689818481000,
        "value": 139.195,
        "_internal_originalTime": 1689818481000
    },
    {
        "time": 1689818482000,
        "value": 139.196,
        "_internal_originalTime": 1689818482000
    },
    {
        "time": 1689818483000,
        "value": 139.206,
        "_internal_originalTime": 1689818483000
    },
    {
        "time": 1689818485000,
        "value": 139.2,
        "_internal_originalTime": 1689818485000
    },
    {
        "time": 1689818486000,
        "value": 139.2,
        "_internal_originalTime": 1689818486000
    },
    {
        "time": 1689818487000,
        "value": 139.2,
        "_internal_originalTime": 1689818487000
    },
    {
        "time": 1689818489000,
        "value": 139.198,
        "_internal_originalTime": 1689818489000
    },
    {
        "time": 1689818491000,
        "value": 139.196,
        "_internal_originalTime": 1689818491000
    },
    {
        "time": 1689818493000,
        "value": 139.197,
        "_internal_originalTime": 1689818493000
    },
    {
        "time": 1689818494000,
        "value": 139.194,
        "_internal_originalTime": 1689818494000
    },
    {
        "time": 1689818496000,
        "value": 139.196,
        "_internal_originalTime": 1689818496000
    },
    {
        "time": 1689818498000,
        "value": 139.192,
        "_internal_originalTime": 1689818498000
    },
    {
        "time": 1689818499000,
        "value": 139.195,
        "_internal_originalTime": 1689818499000
    },
    {
        "time": 1689818500000,
        "value": 139.192,
        "_internal_originalTime": 1689818500000
    },
    {
        "time": 1689818502000,
        "value": 139.196,
        "_internal_originalTime": 1689818502000
    },
    {
        "time": 1689818503000,
        "value": 139.197,
        "_internal_originalTime": 1689818503000
    },
    {
        "time": 1689818505000,
        "value": 139.193,
        "_internal_originalTime": 1689818505000
    },
    {
        "time": 1689818506000,
        "value": 139.192,
        "_internal_originalTime": 1689818506000
    },
    {
        "time": 1689818507000,
        "value": 139.193,
        "_internal_originalTime": 1689818507000
    },
    {
        "time": 1689818509000,
        "value": 139.192,
        "_internal_originalTime": 1689818509000
    },
    {
        "time": 1689818510000,
        "value": 139.197,
        "_internal_originalTime": 1689818510000
    },
    {
        "time": 1689818512000,
        "value": 139.193,
        "_internal_originalTime": 1689818512000
    },
    {
        "time": 1689818513000,
        "value": 139.197,
        "_internal_originalTime": 1689818513000
    },
    {
        "time": 1689818514000,
        "value": 139.193,
        "_internal_originalTime": 1689818514000
    },
    {
        "time": 1689818516000,
        "value": 139.192,
        "_internal_originalTime": 1689818516000
    },
    {
        "time": 1689818517000,
        "value": 139.193,
        "_internal_originalTime": 1689818517000
    },
    {
        "time": 1689818518000,
        "value": 139.19,
        "_internal_originalTime": 1689818518000
    },
    {
        "time": 1689818520000,
        "value": 139.193,
        "_internal_originalTime": 1689818520000
    },
    {
        "time": 1689818521000,
        "value": 139.198,
        "_internal_originalTime": 1689818521000
    },
    {
        "time": 1689818523000,
        "value": 139.197,
        "_internal_originalTime": 1689818523000
    },
    {
        "time": 1689818524000,
        "value": 139.197,
        "_internal_originalTime": 1689818524000
    },
    {
        "time": 1689818526000,
        "value": 139.196,
        "_internal_originalTime": 1689818526000
    },
    {
        "time": 1689818528000,
        "value": 139.195,
        "_internal_originalTime": 1689818528000
    },
    {
        "time": 1689818529000,
        "value": 139.193,
        "_internal_originalTime": 1689818529000
    },
    {
        "time": 1689818530000,
        "value": 139.197,
        "_internal_originalTime": 1689818530000
    },
    {
        "time": 1689818531000,
        "value": 139.197,
        "_internal_originalTime": 1689818531000
    },
    {
        "time": 1689818532000,
        "value": 139.197,
        "_internal_originalTime": 1689818532000
    },
    {
        "time": 1689818533000,
        "value": 139.197,
        "_internal_originalTime": 1689818533000
    },
    {
        "time": 1689818535000,
        "value": 139.198,
        "_internal_originalTime": 1689818535000
    },
    {
        "time": 1689818536000,
        "value": 139.195,
        "_internal_originalTime": 1689818536000
    },
    {
        "time": 1689818537000,
        "value": 139.194,
        "_internal_originalTime": 1689818537000
    },
    {
        "time": 1689818539000,
        "value": 139.199,
        "_internal_originalTime": 1689818539000
    },
    {
        "time": 1689818541000,
        "value": 139.194,
        "_internal_originalTime": 1689818541000
    },
    {
        "time": 1689818542000,
        "value": 139.184,
        "_internal_originalTime": 1689818542000
    },
    {
        "time": 1689818543000,
        "value": 139.185,
        "_internal_originalTime": 1689818543000
    },
    {
        "time": 1689818544000,
        "value": 139.189,
        "_internal_originalTime": 1689818544000
    },
    {
        "time": 1689818546000,
        "value": 139.193,
        "_internal_originalTime": 1689818546000
    },
    {
        "time": 1689818547000,
        "value": 139.189,
        "_internal_originalTime": 1689818547000
    },
    {
        "time": 1689818548000,
        "value": 139.188,
        "_internal_originalTime": 1689818548000
    },
    {
        "time": 1689818550000,
        "value": 139.19,
        "_internal_originalTime": 1689818550000
    },
    {
        "time": 1689818551000,
        "value": 139.195,
        "_internal_originalTime": 1689818551000
    },
    {
        "time": 1689818553000,
        "value": 139.195,
        "_internal_originalTime": 1689818553000
    },
    {
        "time": 1689818555000,
        "value": 139.196,
        "_internal_originalTime": 1689818555000
    },
    {
        "time": 1689818556000,
        "value": 139.19,
        "_internal_originalTime": 1689818556000
    },
    {
        "time": 1689818559000,
        "value": 139.196,
        "_internal_originalTime": 1689818559000
    },
    {
        "time": 1689818560000,
        "value": 139.195,
        "_internal_originalTime": 1689818560000
    },
    {
        "time": 1689818561000,
        "value": 139.189,
        "_internal_originalTime": 1689818561000
    },
    {
        "time": 1689818562000,
        "value": 139.188,
        "_internal_originalTime": 1689818562000
    },
    {
        "time": 1689818563000,
        "value": 139.19,
        "_internal_originalTime": 1689818563000
    },
    {
        "time": 1689818564000,
        "value": 139.194,
        "_internal_originalTime": 1689818564000
    },
    {
        "time": 1689818566000,
        "value": 139.192,
        "_internal_originalTime": 1689818566000
    },
    {
        "time": 1689818567000,
        "value": 139.197,
        "_internal_originalTime": 1689818567000
    },
    {
        "time": 1689818568000,
        "value": 139.203,
        "_internal_originalTime": 1689818568000
    },
    {
        "time": 1689818569000,
        "value": 139.199,
        "_internal_originalTime": 1689818569000
    },
    {
        "time": 1689818570000,
        "value": 139.2,
        "_internal_originalTime": 1689818570000
    },
    {
        "time": 1689818572000,
        "value": 139.206,
        "_internal_originalTime": 1689818572000
    },
    {
        "time": 1689818573000,
        "value": 139.204,
        "_internal_originalTime": 1689818573000
    },
    {
        "time": 1689818575000,
        "value": 139.2,
        "_internal_originalTime": 1689818575000
    },
    {
        "time": 1689818578000,
        "value": 139.202,
        "_internal_originalTime": 1689818578000
    },
    {
        "time": 1689818579000,
        "value": 139.201,
        "_internal_originalTime": 1689818579000
    },
    {
        "time": 1689818580000,
        "value": 139.203,
        "_internal_originalTime": 1689818580000
    },
    {
        "time": 1689818584000,
        "value": 139.198,
        "_internal_originalTime": 1689818584000
    },
    {
        "time": 1689818585000,
        "value": 139.202,
        "_internal_originalTime": 1689818585000
    },
    {
        "time": 1689818586000,
        "value": 139.2,
        "_internal_originalTime": 1689818586000
    },
    {
        "time": 1689818587000,
        "value": 139.218,
        "_internal_originalTime": 1689818587000
    },
    {
        "time": 1689818588000,
        "value": 139.214,
        "_internal_originalTime": 1689818588000
    },
    {
        "time": 1689818590000,
        "value": 139.21,
        "_internal_originalTime": 1689818590000
    },
    {
        "time": 1689818591000,
        "value": 139.208,
        "_internal_originalTime": 1689818591000
    },
    {
        "time": 1689818592000,
        "value": 139.214,
        "_internal_originalTime": 1689818592000
    },
    {
        "time": 1689818595000,
        "value": 139.21,
        "_internal_originalTime": 1689818595000
    },
    {
        "time": 1689818596000,
        "value": 139.209,
        "_internal_originalTime": 1689818596000
    },
    {
        "time": 1689818597000,
        "value": 139.221,
        "_internal_originalTime": 1689818597000
    },
    {
        "time": 1689818599000,
        "value": 139.228,
        "_internal_originalTime": 1689818599000
    },
    {
        "time": 1689818600000,
        "value": 139.233,
        "_internal_originalTime": 1689818600000
    },
    {
        "time": 1689818601000,
        "value": 139.235,
        "_internal_originalTime": 1689818601000
    },
    {
        "time": 1689818602000,
        "value": 139.234,
        "_internal_originalTime": 1689818602000
    },
    {
        "time": 1689818603000,
        "value": 139.234,
        "_internal_originalTime": 1689818603000
    },
    {
        "time": 1689818605000,
        "value": 139.229,
        "_internal_originalTime": 1689818605000
    },
    {
        "time": 1689818606000,
        "value": 139.229,
        "_internal_originalTime": 1689818606000
    },
    {
        "time": 1689818607000,
        "value": 139.234,
        "_internal_originalTime": 1689818607000
    },
    {
        "time": 1689818608000,
        "value": 139.235,
        "_internal_originalTime": 1689818608000
    },
    {
        "time": 1689818609000,
        "value": 139.236,
        "_internal_originalTime": 1689818609000
    },
    {
        "time": 1689818610000,
        "value": 139.238,
        "_internal_originalTime": 1689818610000
    },
    {
        "time": 1689818612000,
        "value": 139.235,
        "_internal_originalTime": 1689818612000
    },
    {
        "time": 1689818613000,
        "value": 139.234,
        "_internal_originalTime": 1689818613000
    },
    {
        "time": 1689818614000,
        "value": 139.232,
        "_internal_originalTime": 1689818614000
    },
    {
        "time": 1689818615000,
        "value": 139.232,
        "_internal_originalTime": 1689818615000
    },
    {
        "time": 1689818616000,
        "value": 139.229,
        "_internal_originalTime": 1689818616000
    },
    {
        "time": 1689818618000,
        "value": 139.237,
        "_internal_originalTime": 1689818618000
    },
    {
        "time": 1689818619000,
        "value": 139.235,
        "_internal_originalTime": 1689818619000
    },
    {
        "time": 1689818621000,
        "value": 139.235,
        "_internal_originalTime": 1689818621000
    },
    {
        "time": 1689818622000,
        "value": 139.229,
        "_internal_originalTime": 1689818622000
    },
    {
        "time": 1689818625000,
        "value": 139.232,
        "_internal_originalTime": 1689818625000
    },
    {
        "time": 1689818626000,
        "value": 139.234,
        "_internal_originalTime": 1689818626000
    },
    {
        "time": 1689818627000,
        "value": 139.228,
        "_internal_originalTime": 1689818627000
    },
    {
        "time": 1689818629000,
        "value": 139.227,
        "_internal_originalTime": 1689818629000
    },
    {
        "time": 1689818631000,
        "value": 139.227,
        "_internal_originalTime": 1689818631000
    },
    {
        "time": 1689818632000,
        "value": 139.222,
        "_internal_originalTime": 1689818632000
    },
    {
        "time": 1689818633000,
        "value": 139.227,
        "_internal_originalTime": 1689818633000
    },
    {
        "time": 1689818635000,
        "value": 139.224,
        "_internal_originalTime": 1689818635000
    },
    {
        "time": 1689818638000,
        "value": 139.235,
        "_internal_originalTime": 1689818638000
    },
    {
        "time": 1689818639000,
        "value": 139.23,
        "_internal_originalTime": 1689818639000
    },
    {
        "time": 1689818640000,
        "value": 139.234,
        "_internal_originalTime": 1689818640000
    },
    {
        "time": 1689818641000,
        "value": 139.237,
        "_internal_originalTime": 1689818641000
    },
    {
        "time": 1689818642000,
        "value": 139.23,
        "_internal_originalTime": 1689818642000
    },
    {
        "time": 1689818643000,
        "value": 139.232,
        "_internal_originalTime": 1689818643000
    },
    {
        "time": 1689818644000,
        "value": 139.23,
        "_internal_originalTime": 1689818644000
    },
    {
        "time": 1689818646000,
        "value": 139.23,
        "_internal_originalTime": 1689818646000
    },
    {
        "time": 1689818648000,
        "value": 139.236,
        "_internal_originalTime": 1689818648000
    },
    {
        "time": 1689818649000,
        "value": 139.234,
        "_internal_originalTime": 1689818649000
    },
    {
        "time": 1689818651000,
        "value": 139.23,
        "_internal_originalTime": 1689818651000
    },
    {
        "time": 1689818652000,
        "value": 139.23,
        "_internal_originalTime": 1689818652000
    },
    {
        "time": 1689818653000,
        "value": 139.23,
        "_internal_originalTime": 1689818653000
    },
    {
        "time": 1689818655000,
        "value": 139.234,
        "_internal_originalTime": 1689818655000
    },
    {
        "time": 1689818657000,
        "value": 139.226,
        "_internal_originalTime": 1689818657000
    },
    {
        "time": 1689818658000,
        "value": 139.225,
        "_internal_originalTime": 1689818658000
    },
    {
        "time": 1689818659000,
        "value": 139.225,
        "_internal_originalTime": 1689818659000
    },
    {
        "time": 1689818660000,
        "value": 139.223,
        "_internal_originalTime": 1689818660000
    },
    {
        "time": 1689818662000,
        "value": 139.226,
        "_internal_originalTime": 1689818662000
    },
    {
        "time": 1689818663000,
        "value": 139.224,
        "_internal_originalTime": 1689818663000
    },
    {
        "time": 1689818664000,
        "value": 139.226,
        "_internal_originalTime": 1689818664000
    },
    {
        "time": 1689818665000,
        "value": 139.229,
        "_internal_originalTime": 1689818665000
    },
    {
        "time": 1689818666000,
        "value": 139.232,
        "_internal_originalTime": 1689818666000
    },
    {
        "time": 1689818668000,
        "value": 139.233,
        "_internal_originalTime": 1689818668000
    },
    {
        "time": 1689818670000,
        "value": 139.233,
        "_internal_originalTime": 1689818670000
    },
    {
        "time": 1689818671000,
        "value": 139.232,
        "_internal_originalTime": 1689818671000
    },
    {
        "time": 1689818672000,
        "value": 139.229,
        "_internal_originalTime": 1689818672000
    },
    {
        "time": 1689818674000,
        "value": 139.224,
        "_internal_originalTime": 1689818674000
    },
    {
        "time": 1689818675000,
        "value": 139.229,
        "_internal_originalTime": 1689818675000
    },
    {
        "time": 1689818676000,
        "value": 139.229,
        "_internal_originalTime": 1689818676000
    },
    {
        "time": 1689818677000,
        "value": 139.232,
        "_internal_originalTime": 1689818677000
    },
    {
        "time": 1689818679000,
        "value": 139.232,
        "_internal_originalTime": 1689818679000
    },
    {
        "time": 1689818680000,
        "value": 139.232,
        "_internal_originalTime": 1689818680000
    },
    {
        "time": 1689818682000,
        "value": 139.229,
        "_internal_originalTime": 1689818682000
    },
    {
        "time": 1689818683000,
        "value": 139.229,
        "_internal_originalTime": 1689818683000
    },
    {
        "time": 1689818684000,
        "value": 139.233,
        "_internal_originalTime": 1689818684000
    },
    {
        "time": 1689818685000,
        "value": 139.232,
        "_internal_originalTime": 1689818685000
    },
    {
        "time": 1689818687000,
        "value": 139.227,
        "_internal_originalTime": 1689818687000
    },
    {
        "time": 1689818688000,
        "value": 139.227,
        "_internal_originalTime": 1689818688000
    },
    {
        "time": 1689818690000,
        "value": 139.223,
        "_internal_originalTime": 1689818690000
    },
    {
        "time": 1689818691000,
        "value": 139.226,
        "_internal_originalTime": 1689818691000
    },
    {
        "time": 1689818694000,
        "value": 139.228,
        "_internal_originalTime": 1689818694000
    },
    {
        "time": 1689818695000,
        "value": 139.227,
        "_internal_originalTime": 1689818695000
    },
    {
        "time": 1689818696000,
        "value": 139.226,
        "_internal_originalTime": 1689818696000
    },
    {
        "time": 1689818697000,
        "value": 139.228,
        "_internal_originalTime": 1689818697000
    },
    {
        "time": 1689818699000,
        "value": 139.228,
        "_internal_originalTime": 1689818699000
    },
    {
        "time": 1689818701000,
        "value": 139.233,
        "_internal_originalTime": 1689818701000
    },
    {
        "time": 1689818702000,
        "value": 139.226,
        "_internal_originalTime": 1689818702000
    },
    {
        "time": 1689818705000,
        "value": 139.227,
        "_internal_originalTime": 1689818705000
    },
    {
        "time": 1689818709000,
        "value": 139.22,
        "_internal_originalTime": 1689818709000
    },
    {
        "time": 1689818713000,
        "value": 139.219,
        "_internal_originalTime": 1689818713000
    },
    {
        "time": 1689818714000,
        "value": 139.222,
        "_internal_originalTime": 1689818714000
    },
    {
        "time": 1689818715000,
        "value": 139.218,
        "_internal_originalTime": 1689818715000
    },
    {
        "time": 1689818716000,
        "value": 139.219,
        "_internal_originalTime": 1689818716000
    },
    {
        "time": 1689818717000,
        "value": 139.218,
        "_internal_originalTime": 1689818717000
    },
    {
        "time": 1689818719000,
        "value": 139.223,
        "_internal_originalTime": 1689818719000
    },
    {
        "time": 1689818720000,
        "value": 139.224,
        "_internal_originalTime": 1689818720000
    },
    {
        "time": 1689818721000,
        "value": 139.224,
        "_internal_originalTime": 1689818721000
    },
    {
        "time": 1689818722000,
        "value": 139.225,
        "_internal_originalTime": 1689818722000
    },
    {
        "time": 1689818724000,
        "value": 139.226,
        "_internal_originalTime": 1689818724000
    },
    {
        "time": 1689818726000,
        "value": 139.22,
        "_internal_originalTime": 1689818726000
    },
    {
        "time": 1689818727000,
        "value": 139.224,
        "_internal_originalTime": 1689818727000
    },
    {
        "time": 1689818728000,
        "value": 139.224,
        "_internal_originalTime": 1689818728000
    },
    {
        "time": 1689818729000,
        "value": 139.232,
        "_internal_originalTime": 1689818729000
    },
    {
        "time": 1689818730000,
        "value": 139.228,
        "_internal_originalTime": 1689818730000
    },
    {
        "time": 1689818731000,
        "value": 139.228,
        "_internal_originalTime": 1689818731000
    },
    {
        "time": 1689818732000,
        "value": 139.228,
        "_internal_originalTime": 1689818732000
    },
    {
        "time": 1689818734000,
        "value": 139.233,
        "_internal_originalTime": 1689818734000
    },
    {
        "time": 1689818735000,
        "value": 139.234,
        "_internal_originalTime": 1689818735000
    },
    {
        "time": 1689818736000,
        "value": 139.237,
        "_internal_originalTime": 1689818736000
    },
    {
        "time": 1689818737000,
        "value": 139.233,
        "_internal_originalTime": 1689818737000
    },
    {
        "time": 1689818739000,
        "value": 139.23,
        "_internal_originalTime": 1689818739000
    },
    {
        "time": 1689818740000,
        "value": 139.229,
        "_internal_originalTime": 1689818740000
    },
    {
        "time": 1689818741000,
        "value": 139.233,
        "_internal_originalTime": 1689818741000
    },
    {
        "time": 1689818742000,
        "value": 139.232,
        "_internal_originalTime": 1689818742000
    },
    {
        "time": 1689818743000,
        "value": 139.227,
        "_internal_originalTime": 1689818743000
    },
    {
        "time": 1689818745000,
        "value": 139.231,
        "_internal_originalTime": 1689818745000
    },
    {
        "time": 1689818747000,
        "value": 139.233,
        "_internal_originalTime": 1689818747000
    },
    {
        "time": 1689818749000,
        "value": 139.236,
        "_internal_originalTime": 1689818749000
    },
    {
        "time": 1689818750000,
        "value": 139.233,
        "_internal_originalTime": 1689818750000
    },
    {
        "time": 1689818751000,
        "value": 139.23,
        "_internal_originalTime": 1689818751000
    },
    {
        "time": 1689818752000,
        "value": 139.229,
        "_internal_originalTime": 1689818752000
    },
    {
        "time": 1689818754000,
        "value": 139.235,
        "_internal_originalTime": 1689818754000
    },
    {
        "time": 1689818755000,
        "value": 139.242,
        "_internal_originalTime": 1689818755000
    },
    {
        "time": 1689818757000,
        "value": 139.242,
        "_internal_originalTime": 1689818757000
    },
    {
        "time": 1689818758000,
        "value": 139.237,
        "_internal_originalTime": 1689818758000
    },
    {
        "time": 1689818760000,
        "value": 139.244,
        "_internal_originalTime": 1689818760000
    },
    {
        "time": 1689818761000,
        "value": 139.243,
        "_internal_originalTime": 1689818761000
    },
    {
        "time": 1689818762000,
        "value": 139.238,
        "_internal_originalTime": 1689818762000
    },
    {
        "time": 1689818764000,
        "value": 139.244,
        "_internal_originalTime": 1689818764000
    },
    {
        "time": 1689818766000,
        "value": 139.245,
        "_internal_originalTime": 1689818766000
    },
    {
        "time": 1689818768000,
        "value": 139.248,
        "_internal_originalTime": 1689818768000
    },
    {
        "time": 1689818770000,
        "value": 139.248,
        "_internal_originalTime": 1689818770000
    },
    {
        "time": 1689818773000,
        "value": 139.25,
        "_internal_originalTime": 1689818773000
    },
    {
        "time": 1689818774000,
        "value": 139.25,
        "_internal_originalTime": 1689818774000
    },
    {
        "time": 1689818775000,
        "value": 139.25,
        "_internal_originalTime": 1689818775000
    },
    {
        "time": 1689818776000,
        "value": 139.246,
        "_internal_originalTime": 1689818776000
    },
    {
        "time": 1689818778000,
        "value": 139.246,
        "_internal_originalTime": 1689818778000
    },
    {
        "time": 1689818779000,
        "value": 139.251,
        "_internal_originalTime": 1689818779000
    },
    {
        "time": 1689818780000,
        "value": 139.256,
        "_internal_originalTime": 1689818780000
    },
    {
        "time": 1689818781000,
        "value": 139.255,
        "_internal_originalTime": 1689818781000
    },
    {
        "time": 1689818782000,
        "value": 139.256,
        "_internal_originalTime": 1689818782000
    },
    {
        "time": 1689818784000,
        "value": 139.248,
        "_internal_originalTime": 1689818784000
    },
    {
        "time": 1689818785000,
        "value": 139.253,
        "_internal_originalTime": 1689818785000
    },
    {
        "time": 1689818787000,
        "value": 139.252,
        "_internal_originalTime": 1689818787000
    },
    {
        "time": 1689818788000,
        "value": 139.249,
        "_internal_originalTime": 1689818788000
    },
    {
        "time": 1689818789000,
        "value": 139.249,
        "_internal_originalTime": 1689818789000
    },
    {
        "time": 1689818790000,
        "value": 139.248,
        "_internal_originalTime": 1689818790000
    },
    {
        "time": 1689818793000,
        "value": 139.248,
        "_internal_originalTime": 1689818793000
    },
    {
        "time": 1689818795000,
        "value": 139.254,
        "_internal_originalTime": 1689818795000
    },
    {
        "time": 1689818797000,
        "value": 139.25,
        "_internal_originalTime": 1689818797000
    },
    {
        "time": 1689818798000,
        "value": 139.255,
        "_internal_originalTime": 1689818798000
    },
    {
        "time": 1689818799000,
        "value": 139.254,
        "_internal_originalTime": 1689818799000
    },
    {
        "time": 1689818801000,
        "value": 139.261,
        "_internal_originalTime": 1689818801000
    },
    {
        "time": 1689818802000,
        "value": 139.26,
        "_internal_originalTime": 1689818802000
    },
    {
        "time": 1689818803000,
        "value": 139.265,
        "_internal_originalTime": 1689818803000
    },
    {
        "time": 1689818805000,
        "value": 139.26,
        "_internal_originalTime": 1689818805000
    },
    {
        "time": 1689818807000,
        "value": 139.265,
        "_internal_originalTime": 1689818807000
    },
    {
        "time": 1689818808000,
        "value": 139.26,
        "_internal_originalTime": 1689818808000
    },
    {
        "time": 1689818809000,
        "value": 139.262,
        "_internal_originalTime": 1689818809000
    },
    {
        "time": 1689818810000,
        "value": 139.259,
        "_internal_originalTime": 1689818810000
    },
    {
        "time": 1689818812000,
        "value": 139.258,
        "_internal_originalTime": 1689818812000
    },
    {
        "time": 1689818813000,
        "value": 139.256,
        "_internal_originalTime": 1689818813000
    },
    {
        "time": 1689818814000,
        "value": 139.251,
        "_internal_originalTime": 1689818814000
    },
    {
        "time": 1689818815000,
        "value": 139.246,
        "_internal_originalTime": 1689818815000
    },
    {
        "time": 1689818816000,
        "value": 139.245,
        "_internal_originalTime": 1689818816000
    },
    {
        "time": 1689818818000,
        "value": 139.236,
        "_internal_originalTime": 1689818818000
    },
    {
        "time": 1689818821000,
        "value": 139.238,
        "_internal_originalTime": 1689818821000
    },
    {
        "time": 1689818823000,
        "value": 139.23,
        "_internal_originalTime": 1689818823000
    },
    {
        "time": 1689818825000,
        "value": 139.228,
        "_internal_originalTime": 1689818825000
    },
    {
        "time": 1689818826000,
        "value": 139.232,
        "_internal_originalTime": 1689818826000
    },
    {
        "time": 1689818829000,
        "value": 139.231,
        "_internal_originalTime": 1689818829000
    },
    {
        "time": 1689818831000,
        "value": 139.231,
        "_internal_originalTime": 1689818831000
    },
    {
        "time": 1689818834000,
        "value": 139.231,
        "_internal_originalTime": 1689818834000
    },
    {
        "time": 1689818835000,
        "value": 139.228,
        "_internal_originalTime": 1689818835000
    },
    {
        "time": 1689818837000,
        "value": 139.235,
        "_internal_originalTime": 1689818837000
    },
    {
        "time": 1689818838000,
        "value": 139.234,
        "_internal_originalTime": 1689818838000
    },
    {
        "time": 1689818839000,
        "value": 139.228,
        "_internal_originalTime": 1689818839000
    },
    {
        "time": 1689818841000,
        "value": 139.225,
        "_internal_originalTime": 1689818841000
    },
    {
        "time": 1689818843000,
        "value": 139.223,
        "_internal_originalTime": 1689818843000
    },
    {
        "time": 1689818844000,
        "value": 139.222,
        "_internal_originalTime": 1689818844000
    },
    {
        "time": 1689818846000,
        "value": 139.223,
        "_internal_originalTime": 1689818846000
    },
    {
        "time": 1689818847000,
        "value": 139.22,
        "_internal_originalTime": 1689818847000
    },
    {
        "time": 1689818848000,
        "value": 139.217,
        "_internal_originalTime": 1689818848000
    },
    {
        "time": 1689818850000,
        "value": 139.218,
        "_internal_originalTime": 1689818850000
    },
    {
        "time": 1689818851000,
        "value": 139.222,
        "_internal_originalTime": 1689818851000
    },
    {
        "time": 1689818853000,
        "value": 139.221,
        "_internal_originalTime": 1689818853000
    },
    {
        "time": 1689818856000,
        "value": 139.221,
        "_internal_originalTime": 1689818856000
    },
    {
        "time": 1689818857000,
        "value": 139.222,
        "_internal_originalTime": 1689818857000
    },
    {
        "time": 1689818859000,
        "value": 139.225,
        "_internal_originalTime": 1689818859000
    },
    {
        "time": 1689818860000,
        "value": 139.221,
        "_internal_originalTime": 1689818860000
    },
    {
        "time": 1689818861000,
        "value": 139.224,
        "_internal_originalTime": 1689818861000
    },
    {
        "time": 1689818865000,
        "value": 139.223,
        "_internal_originalTime": 1689818865000
    },
    {
        "time": 1689818866000,
        "value": 139.221,
        "_internal_originalTime": 1689818866000
    },
    {
        "time": 1689818867000,
        "value": 139.22,
        "_internal_originalTime": 1689818867000
    },
    {
        "time": 1689818869000,
        "value": 139.223,
        "_internal_originalTime": 1689818869000
    },
    {
        "time": 1689818871000,
        "value": 139.224,
        "_internal_originalTime": 1689818871000
    },
    {
        "time": 1689818873000,
        "value": 139.225,
        "_internal_originalTime": 1689818873000
    },
    {
        "time": 1689818874000,
        "value": 139.224,
        "_internal_originalTime": 1689818874000
    },
    {
        "time": 1689818875000,
        "value": 139.221,
        "_internal_originalTime": 1689818875000
    },
    {
        "time": 1689818877000,
        "value": 139.224,
        "_internal_originalTime": 1689818877000
    },
    {
        "time": 1689818878000,
        "value": 139.218,
        "_internal_originalTime": 1689818878000
    },
    {
        "time": 1689818880000,
        "value": 139.221,
        "_internal_originalTime": 1689818880000
    },
    {
        "time": 1689818881000,
        "value": 139.22,
        "_internal_originalTime": 1689818881000
    },
    {
        "time": 1689818883000,
        "value": 139.227,
        "_internal_originalTime": 1689818883000
    },
    {
        "time": 1689818884000,
        "value": 139.23,
        "_internal_originalTime": 1689818884000
    },
    {
        "time": 1689818885000,
        "value": 139.232,
        "_internal_originalTime": 1689818885000
    },
    {
        "time": 1689818886000,
        "value": 139.233,
        "_internal_originalTime": 1689818886000
    },
    {
        "time": 1689818888000,
        "value": 139.23,
        "_internal_originalTime": 1689818888000
    },
    {
        "time": 1689818889000,
        "value": 139.227,
        "_internal_originalTime": 1689818889000
    },
    {
        "time": 1689818891000,
        "value": 139.227,
        "_internal_originalTime": 1689818891000
    },
    {
        "time": 1689818892000,
        "value": 139.222,
        "_internal_originalTime": 1689818892000
    },
    {
        "time": 1689818894000,
        "value": 139.227,
        "_internal_originalTime": 1689818894000
    },
    {
        "time": 1689818895000,
        "value": 139.232,
        "_internal_originalTime": 1689818895000
    },
    {
        "time": 1689818896000,
        "value": 139.233,
        "_internal_originalTime": 1689818896000
    },
    {
        "time": 1689818897000,
        "value": 139.228,
        "_internal_originalTime": 1689818897000
    },
    {
        "time": 1689818900000,
        "value": 139.233,
        "_internal_originalTime": 1689818900000
    },
    {
        "time": 1689818901000,
        "value": 139.232,
        "_internal_originalTime": 1689818901000
    },
    {
        "time": 1689818908000,
        "value": 139.227,
        "_internal_originalTime": 1689818908000
    },
    {
        "time": 1689818912000,
        "value": 139.229,
        "_internal_originalTime": 1689818912000
    },
    {
        "time": 1689818913000,
        "value": 139.23,
        "_internal_originalTime": 1689818913000
    },
    {
        "time": 1689818915000,
        "value": 139.235,
        "_internal_originalTime": 1689818915000
    },
    {
        "time": 1689818916000,
        "value": 139.236,
        "_internal_originalTime": 1689818916000
    },
    {
        "time": 1689818917000,
        "value": 139.232,
        "_internal_originalTime": 1689818917000
    },
    {
        "time": 1689818918000,
        "value": 139.234,
        "_internal_originalTime": 1689818918000
    },
    {
        "time": 1689818919000,
        "value": 139.232,
        "_internal_originalTime": 1689818919000
    },
    {
        "time": 1689818921000,
        "value": 139.232,
        "_internal_originalTime": 1689818921000
    },
    {
        "time": 1689818922000,
        "value": 139.232,
        "_internal_originalTime": 1689818922000
    },
    {
        "time": 1689818923000,
        "value": 139.235,
        "_internal_originalTime": 1689818923000
    },
    {
        "time": 1689818924000,
        "value": 139.23,
        "_internal_originalTime": 1689818924000
    },
    {
        "time": 1689818925000,
        "value": 139.23,
        "_internal_originalTime": 1689818925000
    },
    {
        "time": 1689818926000,
        "value": 139.23,
        "_internal_originalTime": 1689818926000
    },
    {
        "time": 1689818927000,
        "value": 139.23,
        "_internal_originalTime": 1689818927000
    },
    {
        "time": 1689818929000,
        "value": 139.23,
        "_internal_originalTime": 1689818929000
    },
    {
        "time": 1689818930000,
        "value": 139.232,
        "_internal_originalTime": 1689818930000
    },
    {
        "time": 1689818932000,
        "value": 139.244,
        "_internal_originalTime": 1689818932000
    },
    {
        "time": 1689818934000,
        "value": 139.244,
        "_internal_originalTime": 1689818934000
    },
    {
        "time": 1689818936000,
        "value": 139.245,
        "_internal_originalTime": 1689818936000
    },
    {
        "time": 1689818937000,
        "value": 139.244,
        "_internal_originalTime": 1689818937000
    },
    {
        "time": 1689818939000,
        "value": 139.244,
        "_internal_originalTime": 1689818939000
    },
    {
        "time": 1689818940000,
        "value": 139.244,
        "_internal_originalTime": 1689818940000
    },
    {
        "time": 1689818941000,
        "value": 139.242,
        "_internal_originalTime": 1689818941000
    },
    {
        "time": 1689818942000,
        "value": 139.242,
        "_internal_originalTime": 1689818942000
    },
    {
        "time": 1689818943000,
        "value": 139.247,
        "_internal_originalTime": 1689818943000
    },
    {
        "time": 1689818944000,
        "value": 139.242,
        "_internal_originalTime": 1689818944000
    },
    {
        "time": 1689818946000,
        "value": 139.243,
        "_internal_originalTime": 1689818946000
    },
    {
        "time": 1689818949000,
        "value": 139.245,
        "_internal_originalTime": 1689818949000
    },
    {
        "time": 1689818950000,
        "value": 139.235,
        "_internal_originalTime": 1689818950000
    },
    {
        "time": 1689818951000,
        "value": 139.235,
        "_internal_originalTime": 1689818951000
    },
    {
        "time": 1689818952000,
        "value": 139.235,
        "_internal_originalTime": 1689818952000
    },
    {
        "time": 1689818953000,
        "value": 139.235,
        "_internal_originalTime": 1689818953000
    },
    {
        "time": 1689818955000,
        "value": 139.232,
        "_internal_originalTime": 1689818955000
    },
    {
        "time": 1689818956000,
        "value": 139.232,
        "_internal_originalTime": 1689818956000
    },
    {
        "time": 1689818957000,
        "value": 139.242,
        "_internal_originalTime": 1689818957000
    },
    {
        "time": 1689818959000,
        "value": 139.242,
        "_internal_originalTime": 1689818959000
    },
    {
        "time": 1689818960000,
        "value": 139.242,
        "_internal_originalTime": 1689818960000
    },
    {
        "time": 1689818961000,
        "value": 139.243,
        "_internal_originalTime": 1689818961000
    },
    {
        "time": 1689818963000,
        "value": 139.241,
        "_internal_originalTime": 1689818963000
    },
    {
        "time": 1689818964000,
        "value": 139.245,
        "_internal_originalTime": 1689818964000
    },
    {
        "time": 1689818965000,
        "value": 139.242,
        "_internal_originalTime": 1689818965000
    },
    {
        "time": 1689818966000,
        "value": 139.253,
        "_internal_originalTime": 1689818966000
    },
    {
        "time": 1689818968000,
        "value": 139.252,
        "_internal_originalTime": 1689818968000
    },
    {
        "time": 1689818969000,
        "value": 139.249,
        "_internal_originalTime": 1689818969000
    },
    {
        "time": 1689818970000,
        "value": 139.251,
        "_internal_originalTime": 1689818970000
    },
    {
        "time": 1689818971000,
        "value": 139.252,
        "_internal_originalTime": 1689818971000
    },
    {
        "time": 1689818973000,
        "value": 139.251,
        "_internal_originalTime": 1689818973000
    },
    {
        "time": 1689818975000,
        "value": 139.246,
        "_internal_originalTime": 1689818975000
    },
    {
        "time": 1689818977000,
        "value": 139.247,
        "_internal_originalTime": 1689818977000
    },
    {
        "time": 1689818978000,
        "value": 139.243,
        "_internal_originalTime": 1689818978000
    },
    {
        "time": 1689818979000,
        "value": 139.247,
        "_internal_originalTime": 1689818979000
    },
    {
        "time": 1689818981000,
        "value": 139.245,
        "_internal_originalTime": 1689818981000
    },
    {
        "time": 1689818984000,
        "value": 139.243,
        "_internal_originalTime": 1689818984000
    },
    {
        "time": 1689818986000,
        "value": 139.242,
        "_internal_originalTime": 1689818986000
    },
    {
        "time": 1689818988000,
        "value": 139.247,
        "_internal_originalTime": 1689818988000
    },
    {
        "time": 1689818989000,
        "value": 139.242,
        "_internal_originalTime": 1689818989000
    },
    {
        "time": 1689818990000,
        "value": 139.245,
        "_internal_originalTime": 1689818990000
    },
    {
        "time": 1689818992000,
        "value": 139.242,
        "_internal_originalTime": 1689818992000
    },
    {
        "time": 1689818994000,
        "value": 139.251,
        "_internal_originalTime": 1689818994000
    },
    {
        "time": 1689818995000,
        "value": 139.253,
        "_internal_originalTime": 1689818995000
    },
    {
        "time": 1689818996000,
        "value": 139.249,
        "_internal_originalTime": 1689818996000
    },
    {
        "time": 1689818998000,
        "value": 139.253,
        "_internal_originalTime": 1689818998000
    },
    {
        "time": 1689818999000,
        "value": 139.259,
        "_internal_originalTime": 1689818999000
    },
    {
        "time": 1689819000000,
        "value": 139.258,
        "_internal_originalTime": 1689819000000
    },
    {
        "time": 1689819001000,
        "value": 139.259,
        "_internal_originalTime": 1689819001000
    },
    {
        "time": 1689819002000,
        "value": 139.26,
        "_internal_originalTime": 1689819002000
    },
    {
        "time": 1689819003000,
        "value": 139.265,
        "_internal_originalTime": 1689819003000
    },
    {
        "time": 1689819004000,
        "value": 139.263,
        "_internal_originalTime": 1689819004000
    },
    {
        "time": 1689819006000,
        "value": 139.256,
        "_internal_originalTime": 1689819006000
    },
    {
        "time": 1689819007000,
        "value": 139.256,
        "_internal_originalTime": 1689819007000
    },
    {
        "time": 1689819009000,
        "value": 139.249,
        "_internal_originalTime": 1689819009000
    },
    {
        "time": 1689819010000,
        "value": 139.252,
        "_internal_originalTime": 1689819010000
    },
    {
        "time": 1689819011000,
        "value": 139.253,
        "_internal_originalTime": 1689819011000
    },
    {
        "time": 1689819015000,
        "value": 139.25,
        "_internal_originalTime": 1689819015000
    },
    {
        "time": 1689819016000,
        "value": 139.25,
        "_internal_originalTime": 1689819016000
    },
    {
        "time": 1689819017000,
        "value": 139.25,
        "_internal_originalTime": 1689819017000
    },
    {
        "time": 1689819018000,
        "value": 139.25,
        "_internal_originalTime": 1689819018000
    },
    {
        "time": 1689819019000,
        "value": 139.25,
        "_internal_originalTime": 1689819019000
    },
    {
        "time": 1689819021000,
        "value": 139.252,
        "_internal_originalTime": 1689819021000
    },
    {
        "time": 1689819022000,
        "value": 139.255,
        "_internal_originalTime": 1689819022000
    },
    {
        "time": 1689819023000,
        "value": 139.252,
        "_internal_originalTime": 1689819023000
    },
    {
        "time": 1689819024000,
        "value": 139.255,
        "_internal_originalTime": 1689819024000
    },
    {
        "time": 1689819026000,
        "value": 139.25,
        "_internal_originalTime": 1689819026000
    },
    {
        "time": 1689819027000,
        "value": 139.248,
        "_internal_originalTime": 1689819027000
    },
    {
        "time": 1689819028000,
        "value": 139.251,
        "_internal_originalTime": 1689819028000
    },
    {
        "time": 1689819030000,
        "value": 139.254,
        "_internal_originalTime": 1689819030000
    },
    {
        "time": 1689819036000,
        "value": 139.247,
        "_internal_originalTime": 1689819036000
    },
    {
        "time": 1689819037000,
        "value": 139.251,
        "_internal_originalTime": 1689819037000
    },
    {
        "time": 1689819039000,
        "value": 139.249,
        "_internal_originalTime": 1689819039000
    },
    {
        "time": 1689819041000,
        "value": 139.248,
        "_internal_originalTime": 1689819041000
    },
    {
        "time": 1689819044000,
        "value": 139.25,
        "_internal_originalTime": 1689819044000
    },
    {
        "time": 1689819045000,
        "value": 139.253,
        "_internal_originalTime": 1689819045000
    },
    {
        "time": 1689819046000,
        "value": 139.254,
        "_internal_originalTime": 1689819046000
    },
    {
        "time": 1689819047000,
        "value": 139.253,
        "_internal_originalTime": 1689819047000
    },
    {
        "time": 1689819049000,
        "value": 139.252,
        "_internal_originalTime": 1689819049000
    },
    {
        "time": 1689819050000,
        "value": 139.247,
        "_internal_originalTime": 1689819050000
    },
    {
        "time": 1689819052000,
        "value": 139.251,
        "_internal_originalTime": 1689819052000
    },
    {
        "time": 1689819053000,
        "value": 139.251,
        "_internal_originalTime": 1689819053000
    },
    {
        "time": 1689819055000,
        "value": 139.251,
        "_internal_originalTime": 1689819055000
    },
    {
        "time": 1689819056000,
        "value": 139.246,
        "_internal_originalTime": 1689819056000
    },
    {
        "time": 1689819057000,
        "value": 139.247,
        "_internal_originalTime": 1689819057000
    },
    {
        "time": 1689819058000,
        "value": 139.245,
        "_internal_originalTime": 1689819058000
    },
    {
        "time": 1689819060000,
        "value": 139.249,
        "_internal_originalTime": 1689819060000
    },
    {
        "time": 1689819061000,
        "value": 139.247,
        "_internal_originalTime": 1689819061000
    },
    {
        "time": 1689819062000,
        "value": 139.252,
        "_internal_originalTime": 1689819062000
    },
    {
        "time": 1689819065000,
        "value": 139.252,
        "_internal_originalTime": 1689819065000
    },
    {
        "time": 1689819066000,
        "value": 139.249,
        "_internal_originalTime": 1689819066000
    },
    {
        "time": 1689819067000,
        "value": 139.249,
        "_internal_originalTime": 1689819067000
    },
    {
        "time": 1689819068000,
        "value": 139.249,
        "_internal_originalTime": 1689819068000
    },
    {
        "time": 1689819069000,
        "value": 139.248,
        "_internal_originalTime": 1689819069000
    },
    {
        "time": 1689819070000,
        "value": 139.247,
        "_internal_originalTime": 1689819070000
    },
    {
        "time": 1689819071000,
        "value": 139.249,
        "_internal_originalTime": 1689819071000
    },
    {
        "time": 1689819079000,
        "value": 139.252,
        "_internal_originalTime": 1689819079000
    },
    {
        "time": 1689819080000,
        "value": 139.252,
        "_internal_originalTime": 1689819080000
    },
    {
        "time": 1689819082000,
        "value": 139.246,
        "_internal_originalTime": 1689819082000
    },
    {
        "time": 1689819083000,
        "value": 139.244,
        "_internal_originalTime": 1689819083000
    },
    {
        "time": 1689819085000,
        "value": 139.241,
        "_internal_originalTime": 1689819085000
    },
    {
        "time": 1689819087000,
        "value": 139.24,
        "_internal_originalTime": 1689819087000
    },
    {
        "time": 1689819089000,
        "value": 139.24,
        "_internal_originalTime": 1689819089000
    },
    {
        "time": 1689819092000,
        "value": 139.24,
        "_internal_originalTime": 1689819092000
    },
    {
        "time": 1689819093000,
        "value": 139.243,
        "_internal_originalTime": 1689819093000
    },
    {
        "time": 1689819099000,
        "value": 139.241,
        "_internal_originalTime": 1689819099000
    },
    {
        "time": 1689819100000,
        "value": 139.24,
        "_internal_originalTime": 1689819100000
    },
    {
        "time": 1689819104000,
        "value": 139.24,
        "_internal_originalTime": 1689819104000
    },
    {
        "time": 1689819106000,
        "value": 139.242,
        "_internal_originalTime": 1689819106000
    },
    {
        "time": 1689819108000,
        "value": 139.224,
        "_internal_originalTime": 1689819108000
    },
    {
        "time": 1689819110000,
        "value": 139.222,
        "_internal_originalTime": 1689819110000
    },
    {
        "time": 1689819111000,
        "value": 139.222,
        "_internal_originalTime": 1689819111000
    },
    {
        "time": 1689819112000,
        "value": 139.223,
        "_internal_originalTime": 1689819112000
    },
    {
        "time": 1689819113000,
        "value": 139.221,
        "_internal_originalTime": 1689819113000
    },
    {
        "time": 1689819116000,
        "value": 139.233,
        "_internal_originalTime": 1689819116000
    },
    {
        "time": 1689819118000,
        "value": 139.233,
        "_internal_originalTime": 1689819118000
    },
    {
        "time": 1689819120000,
        "value": 139.232,
        "_internal_originalTime": 1689819120000
    },
    {
        "time": 1689819121000,
        "value": 139.231,
        "_internal_originalTime": 1689819121000
    },
    {
        "time": 1689819122000,
        "value": 139.232,
        "_internal_originalTime": 1689819122000
    },
    {
        "time": 1689819123000,
        "value": 139.234,
        "_internal_originalTime": 1689819123000
    },
    {
        "time": 1689819125000,
        "value": 139.222,
        "_internal_originalTime": 1689819125000
    },
    {
        "time": 1689819127000,
        "value": 139.225,
        "_internal_originalTime": 1689819127000
    },
    {
        "time": 1689819128000,
        "value": 139.224,
        "_internal_originalTime": 1689819128000
    },
    {
        "time": 1689819129000,
        "value": 139.221,
        "_internal_originalTime": 1689819129000
    },
    {
        "time": 1689819130000,
        "value": 139.221,
        "_internal_originalTime": 1689819130000
    },
    {
        "time": 1689819131000,
        "value": 139.223,
        "_internal_originalTime": 1689819131000
    },
    {
        "time": 1689819132000,
        "value": 139.22,
        "_internal_originalTime": 1689819132000
    },
    {
        "time": 1689819134000,
        "value": 139.224,
        "_internal_originalTime": 1689819134000
    },
    {
        "time": 1689819136000,
        "value": 139.222,
        "_internal_originalTime": 1689819136000
    },
    {
        "time": 1689819139000,
        "value": 139.225,
        "_internal_originalTime": 1689819139000
    },
    {
        "time": 1689819141000,
        "value": 139.225,
        "_internal_originalTime": 1689819141000
    },
    {
        "time": 1689819142000,
        "value": 139.222,
        "_internal_originalTime": 1689819142000
    },
    {
        "time": 1689819143000,
        "value": 139.227,
        "_internal_originalTime": 1689819143000
    },
    {
        "time": 1689819144000,
        "value": 139.226,
        "_internal_originalTime": 1689819144000
    },
    {
        "time": 1689819145000,
        "value": 139.223,
        "_internal_originalTime": 1689819145000
    },
    {
        "time": 1689819146000,
        "value": 139.222,
        "_internal_originalTime": 1689819146000
    },
    {
        "time": 1689819148000,
        "value": 139.222,
        "_internal_originalTime": 1689819148000
    },
    {
        "time": 1689819149000,
        "value": 139.226,
        "_internal_originalTime": 1689819149000
    },
    {
        "time": 1689819151000,
        "value": 139.226,
        "_internal_originalTime": 1689819151000
    },
    {
        "time": 1689819153000,
        "value": 139.213,
        "_internal_originalTime": 1689819153000
    },
    {
        "time": 1689819154000,
        "value": 139.213,
        "_internal_originalTime": 1689819154000
    },
    {
        "time": 1689819155000,
        "value": 139.21,
        "_internal_originalTime": 1689819155000
    },
    {
        "time": 1689819156000,
        "value": 139.201,
        "_internal_originalTime": 1689819156000
    },
    {
        "time": 1689819158000,
        "value": 139.206,
        "_internal_originalTime": 1689819158000
    },
    {
        "time": 1689819159000,
        "value": 139.207,
        "_internal_originalTime": 1689819159000
    },
    {
        "time": 1689819160000,
        "value": 139.207,
        "_internal_originalTime": 1689819160000
    },
    {
        "time": 1689819161000,
        "value": 139.204,
        "_internal_originalTime": 1689819161000
    },
    {
        "time": 1689819163000,
        "value": 139.205,
        "_internal_originalTime": 1689819163000
    },
    {
        "time": 1689819164000,
        "value": 139.21,
        "_internal_originalTime": 1689819164000
    },
    {
        "time": 1689819165000,
        "value": 139.208,
        "_internal_originalTime": 1689819165000
    },
    {
        "time": 1689819166000,
        "value": 139.213,
        "_internal_originalTime": 1689819166000
    },
    {
        "time": 1689819167000,
        "value": 139.215,
        "_internal_originalTime": 1689819167000
    },
    {
        "time": 1689819170000,
        "value": 139.209,
        "_internal_originalTime": 1689819170000
    },
    {
        "time": 1689819171000,
        "value": 139.215,
        "_internal_originalTime": 1689819171000
    },
    {
        "time": 1689819172000,
        "value": 139.223,
        "_internal_originalTime": 1689819172000
    },
    {
        "time": 1689819173000,
        "value": 139.225,
        "_internal_originalTime": 1689819173000
    },
    {
        "time": 1689819175000,
        "value": 139.222,
        "_internal_originalTime": 1689819175000
    },
    {
        "time": 1689819178000,
        "value": 139.223,
        "_internal_originalTime": 1689819178000
    },
    {
        "time": 1689819179000,
        "value": 139.224,
        "_internal_originalTime": 1689819179000
    },
    {
        "time": 1689819180000,
        "value": 139.222,
        "_internal_originalTime": 1689819180000
    },
    {
        "time": 1689819181000,
        "value": 139.223,
        "_internal_originalTime": 1689819181000
    },
    {
        "time": 1689819183000,
        "value": 139.223,
        "_internal_originalTime": 1689819183000
    },
    {
        "time": 1689819184000,
        "value": 139.226,
        "_internal_originalTime": 1689819184000
    },
    {
        "time": 1689819185000,
        "value": 139.226,
        "_internal_originalTime": 1689819185000
    },
    {
        "time": 1689819186000,
        "value": 139.223,
        "_internal_originalTime": 1689819186000
    },
    {
        "time": 1689819187000,
        "value": 139.224,
        "_internal_originalTime": 1689819187000
    },
    {
        "time": 1689819188000,
        "value": 139.226,
        "_internal_originalTime": 1689819188000
    },
    {
        "time": 1689819189000,
        "value": 139.225,
        "_internal_originalTime": 1689819189000
    },
    {
        "time": 1689819192000,
        "value": 139.231,
        "_internal_originalTime": 1689819192000
    },
    {
        "time": 1689819193000,
        "value": 139.235,
        "_internal_originalTime": 1689819193000
    },
    {
        "time": 1689819194000,
        "value": 139.232,
        "_internal_originalTime": 1689819194000
    },
    {
        "time": 1689819195000,
        "value": 139.233,
        "_internal_originalTime": 1689819195000
    },
    {
        "time": 1689819197000,
        "value": 139.235,
        "_internal_originalTime": 1689819197000
    },
    {
        "time": 1689819198000,
        "value": 139.235,
        "_internal_originalTime": 1689819198000
    },
    {
        "time": 1689819200000,
        "value": 139.234,
        "_internal_originalTime": 1689819200000
    },
    {
        "time": 1689819202000,
        "value": 139.232,
        "_internal_originalTime": 1689819202000
    },
    {
        "time": 1689819203000,
        "value": 139.227,
        "_internal_originalTime": 1689819203000
    },
    {
        "time": 1689819205000,
        "value": 139.223,
        "_internal_originalTime": 1689819205000
    },
    {
        "time": 1689819207000,
        "value": 139.217,
        "_internal_originalTime": 1689819207000
    },
    {
        "time": 1689819208000,
        "value": 139.216,
        "_internal_originalTime": 1689819208000
    },
    {
        "time": 1689819209000,
        "value": 139.217,
        "_internal_originalTime": 1689819209000
    },
    {
        "time": 1689819210000,
        "value": 139.236,
        "_internal_originalTime": 1689819210000
    },
    {
        "time": 1689819211000,
        "value": 139.231,
        "_internal_originalTime": 1689819211000
    },
    {
        "time": 1689819212000,
        "value": 139.231,
        "_internal_originalTime": 1689819212000
    },
    {
        "time": 1689819214000,
        "value": 139.233,
        "_internal_originalTime": 1689819214000
    },
    {
        "time": 1689819216000,
        "value": 139.229,
        "_internal_originalTime": 1689819216000
    },
    {
        "time": 1689819217000,
        "value": 139.226,
        "_internal_originalTime": 1689819217000
    },
    {
        "time": 1689819219000,
        "value": 139.226,
        "_internal_originalTime": 1689819219000
    },
    {
        "time": 1689819220000,
        "value": 139.226,
        "_internal_originalTime": 1689819220000
    },
    {
        "time": 1689819222000,
        "value": 139.226,
        "_internal_originalTime": 1689819222000
    },
    {
        "time": 1689819223000,
        "value": 139.225,
        "_internal_originalTime": 1689819223000
    },
    {
        "time": 1689819224000,
        "value": 139.227,
        "_internal_originalTime": 1689819224000
    },
    {
        "time": 1689819225000,
        "value": 139.235,
        "_internal_originalTime": 1689819225000
    },
    {
        "time": 1689819226000,
        "value": 139.231,
        "_internal_originalTime": 1689819226000
    },
    {
        "time": 1689819227000,
        "value": 139.234,
        "_internal_originalTime": 1689819227000
    },
    {
        "time": 1689819228000,
        "value": 139.238,
        "_internal_originalTime": 1689819228000
    },
    {
        "time": 1689819229000,
        "value": 139.238,
        "_internal_originalTime": 1689819229000
    },
    {
        "time": 1689819230000,
        "value": 139.241,
        "_internal_originalTime": 1689819230000
    },
    {
        "time": 1689819233000,
        "value": 139.24,
        "_internal_originalTime": 1689819233000
    },
    {
        "time": 1689819234000,
        "value": 139.236,
        "_internal_originalTime": 1689819234000
    },
    {
        "time": 1689819235000,
        "value": 139.236,
        "_internal_originalTime": 1689819235000
    },
    {
        "time": 1689819237000,
        "value": 139.239,
        "_internal_originalTime": 1689819237000
    },
    {
        "time": 1689819239000,
        "value": 139.24,
        "_internal_originalTime": 1689819239000
    },
    {
        "time": 1689819240000,
        "value": 139.24,
        "_internal_originalTime": 1689819240000
    },
    {
        "time": 1689819241000,
        "value": 139.24,
        "_internal_originalTime": 1689819241000
    },
    {
        "time": 1689819242000,
        "value": 139.236,
        "_internal_originalTime": 1689819242000
    },
    {
        "time": 1689819244000,
        "value": 139.242,
        "_internal_originalTime": 1689819244000
    },
    {
        "time": 1689819245000,
        "value": 139.241,
        "_internal_originalTime": 1689819245000
    },
    {
        "time": 1689819246000,
        "value": 139.24,
        "_internal_originalTime": 1689819246000
    },
    {
        "time": 1689819247000,
        "value": 139.241,
        "_internal_originalTime": 1689819247000
    },
    {
        "time": 1689819248000,
        "value": 139.24,
        "_internal_originalTime": 1689819248000
    },
    {
        "time": 1689819249000,
        "value": 139.24,
        "_internal_originalTime": 1689819249000
    },
    {
        "time": 1689819251000,
        "value": 139.244,
        "_internal_originalTime": 1689819251000
    },
    {
        "time": 1689819253000,
        "value": 139.242,
        "_internal_originalTime": 1689819253000
    },
    {
        "time": 1689819256000,
        "value": 139.239,
        "_internal_originalTime": 1689819256000
    },
    {
        "time": 1689819257000,
        "value": 139.238,
        "_internal_originalTime": 1689819257000
    },
    {
        "time": 1689819258000,
        "value": 139.243,
        "_internal_originalTime": 1689819258000
    },
    {
        "time": 1689819260000,
        "value": 139.242,
        "_internal_originalTime": 1689819260000
    },
    {
        "time": 1689819261000,
        "value": 139.241,
        "_internal_originalTime": 1689819261000
    },
    {
        "time": 1689819262000,
        "value": 139.241,
        "_internal_originalTime": 1689819262000
    },
    {
        "time": 1689819263000,
        "value": 139.238,
        "_internal_originalTime": 1689819263000
    },
    {
        "time": 1689819264000,
        "value": 139.238,
        "_internal_originalTime": 1689819264000
    },
    {
        "time": 1689819267000,
        "value": 139.232,
        "_internal_originalTime": 1689819267000
    },
    {
        "time": 1689819269000,
        "value": 139.229,
        "_internal_originalTime": 1689819269000
    },
    {
        "time": 1689819270000,
        "value": 139.23,
        "_internal_originalTime": 1689819270000
    },
    {
        "time": 1689819271000,
        "value": 139.229,
        "_internal_originalTime": 1689819271000
    },
    {
        "time": 1689819273000,
        "value": 139.229,
        "_internal_originalTime": 1689819273000
    },
    {
        "time": 1689819274000,
        "value": 139.228,
        "_internal_originalTime": 1689819274000
    },
    {
        "time": 1689819275000,
        "value": 139.23,
        "_internal_originalTime": 1689819275000
    },
    {
        "time": 1689819276000,
        "value": 139.24,
        "_internal_originalTime": 1689819276000
    },
    {
        "time": 1689819277000,
        "value": 139.232,
        "_internal_originalTime": 1689819277000
    },
    {
        "time": 1689819278000,
        "value": 139.237,
        "_internal_originalTime": 1689819278000
    },
    {
        "time": 1689819280000,
        "value": 139.232,
        "_internal_originalTime": 1689819280000
    },
    {
        "time": 1689819281000,
        "value": 139.232,
        "_internal_originalTime": 1689819281000
    },
    {
        "time": 1689819283000,
        "value": 139.254,
        "_internal_originalTime": 1689819283000
    },
    {
        "time": 1689819285000,
        "value": 139.256,
        "_internal_originalTime": 1689819285000
    },
    {
        "time": 1689819286000,
        "value": 139.259,
        "_internal_originalTime": 1689819286000
    },
    {
        "time": 1689819288000,
        "value": 139.256,
        "_internal_originalTime": 1689819288000
    },
    {
        "time": 1689819290000,
        "value": 139.26,
        "_internal_originalTime": 1689819290000
    },
    {
        "time": 1689819291000,
        "value": 139.258,
        "_internal_originalTime": 1689819291000
    },
    {
        "time": 1689819292000,
        "value": 139.256,
        "_internal_originalTime": 1689819292000
    },
    {
        "time": 1689819293000,
        "value": 139.254,
        "_internal_originalTime": 1689819293000
    },
    {
        "time": 1689819294000,
        "value": 139.25,
        "_internal_originalTime": 1689819294000
    },
    {
        "time": 1689819295000,
        "value": 139.25,
        "_internal_originalTime": 1689819295000
    },
    {
        "time": 1689819297000,
        "value": 139.244,
        "_internal_originalTime": 1689819297000
    },
    {
        "time": 1689819299000,
        "value": 139.245,
        "_internal_originalTime": 1689819299000
    },
    {
        "time": 1689819300000,
        "value": 139.245,
        "_internal_originalTime": 1689819300000
    },
    {
        "time": 1689819301000,
        "value": 139.245,
        "_internal_originalTime": 1689819301000
    },
    {
        "time": 1689819302000,
        "value": 139.242,
        "_internal_originalTime": 1689819302000
    },
    {
        "time": 1689819304000,
        "value": 139.24,
        "_internal_originalTime": 1689819304000
    },
    {
        "time": 1689819305000,
        "value": 139.246,
        "_internal_originalTime": 1689819305000
    },
    {
        "time": 1689819306000,
        "value": 139.236,
        "_internal_originalTime": 1689819306000
    },
    {
        "time": 1689819307000,
        "value": 139.237,
        "_internal_originalTime": 1689819307000
    },
    {
        "time": 1689819308000,
        "value": 139.242,
        "_internal_originalTime": 1689819308000
    },
    {
        "time": 1689819309000,
        "value": 139.24,
        "_internal_originalTime": 1689819309000
    },
    {
        "time": 1689819310000,
        "value": 139.241,
        "_internal_originalTime": 1689819310000
    },
    {
        "time": 1689819311000,
        "value": 139.24,
        "_internal_originalTime": 1689819311000
    },
    {
        "time": 1689819313000,
        "value": 139.243,
        "_internal_originalTime": 1689819313000
    },
    {
        "time": 1689819314000,
        "value": 139.244,
        "_internal_originalTime": 1689819314000
    },
    {
        "time": 1689819319000,
        "value": 139.245,
        "_internal_originalTime": 1689819319000
    },
    {
        "time": 1689819320000,
        "value": 139.245,
        "_internal_originalTime": 1689819320000
    },
    {
        "time": 1689819321000,
        "value": 139.246,
        "_internal_originalTime": 1689819321000
    },
    {
        "time": 1689819322000,
        "value": 139.242,
        "_internal_originalTime": 1689819322000
    },
    {
        "time": 1689819324000,
        "value": 139.245,
        "_internal_originalTime": 1689819324000
    },
    {
        "time": 1689819325000,
        "value": 139.245,
        "_internal_originalTime": 1689819325000
    },
    {
        "time": 1689819326000,
        "value": 139.242,
        "_internal_originalTime": 1689819326000
    },
    {
        "time": 1689819327000,
        "value": 139.242,
        "_internal_originalTime": 1689819327000
    },
    {
        "time": 1689819329000,
        "value": 139.238,
        "_internal_originalTime": 1689819329000
    },
    {
        "time": 1689819330000,
        "value": 139.245,
        "_internal_originalTime": 1689819330000
    },
    {
        "time": 1689819331000,
        "value": 139.244,
        "_internal_originalTime": 1689819331000
    },
    {
        "time": 1689819333000,
        "value": 139.246,
        "_internal_originalTime": 1689819333000
    },
    {
        "time": 1689819335000,
        "value": 139.24,
        "_internal_originalTime": 1689819335000
    },
    {
        "time": 1689819336000,
        "value": 139.244,
        "_internal_originalTime": 1689819336000
    },
    {
        "time": 1689819337000,
        "value": 139.241,
        "_internal_originalTime": 1689819337000
    },
    {
        "time": 1689819339000,
        "value": 139.243,
        "_internal_originalTime": 1689819339000
    },
    {
        "time": 1689819340000,
        "value": 139.247,
        "_internal_originalTime": 1689819340000
    },
    {
        "time": 1689819342000,
        "value": 139.243,
        "_internal_originalTime": 1689819342000
    },
    {
        "time": 1689819343000,
        "value": 139.246,
        "_internal_originalTime": 1689819343000
    },
    {
        "time": 1689819345000,
        "value": 139.249,
        "_internal_originalTime": 1689819345000
    },
    {
        "time": 1689819346000,
        "value": 139.244,
        "_internal_originalTime": 1689819346000
    },
    {
        "time": 1689819348000,
        "value": 139.247,
        "_internal_originalTime": 1689819348000
    },
    {
        "time": 1689819349000,
        "value": 139.247,
        "_internal_originalTime": 1689819349000
    },
    {
        "time": 1689819351000,
        "value": 139.247,
        "_internal_originalTime": 1689819351000
    },
    {
        "time": 1689819352000,
        "value": 139.245,
        "_internal_originalTime": 1689819352000
    },
    {
        "time": 1689819354000,
        "value": 139.247,
        "_internal_originalTime": 1689819354000
    },
    {
        "time": 1689819356000,
        "value": 139.242,
        "_internal_originalTime": 1689819356000
    },
    {
        "time": 1689819357000,
        "value": 139.245,
        "_internal_originalTime": 1689819357000
    },
    {
        "time": 1689819359000,
        "value": 139.242,
        "_internal_originalTime": 1689819359000
    },
    {
        "time": 1689819361000,
        "value": 139.243,
        "_internal_originalTime": 1689819361000
    },
    {
        "time": 1689819362000,
        "value": 139.231,
        "_internal_originalTime": 1689819362000
    },
    {
        "time": 1689819364000,
        "value": 139.23,
        "_internal_originalTime": 1689819364000
    },
    {
        "time": 1689819366000,
        "value": 139.229,
        "_internal_originalTime": 1689819366000
    },
    {
        "time": 1689819367000,
        "value": 139.234,
        "_internal_originalTime": 1689819367000
    },
    {
        "time": 1689819368000,
        "value": 139.235,
        "_internal_originalTime": 1689819368000
    },
    {
        "time": 1689819370000,
        "value": 139.22,
        "_internal_originalTime": 1689819370000
    },
    {
        "time": 1689819371000,
        "value": 139.227,
        "_internal_originalTime": 1689819371000
    },
    {
        "time": 1689819372000,
        "value": 139.22,
        "_internal_originalTime": 1689819372000
    },
    {
        "time": 1689819373000,
        "value": 139.225,
        "_internal_originalTime": 1689819373000
    },
    {
        "time": 1689819374000,
        "value": 139.224,
        "_internal_originalTime": 1689819374000
    },
    {
        "time": 1689819375000,
        "value": 139.224,
        "_internal_originalTime": 1689819375000
    },
    {
        "time": 1689819377000,
        "value": 139.223,
        "_internal_originalTime": 1689819377000
    },
    {
        "time": 1689819378000,
        "value": 139.224,
        "_internal_originalTime": 1689819378000
    },
    {
        "time": 1689819380000,
        "value": 139.225,
        "_internal_originalTime": 1689819380000
    },
    {
        "time": 1689819381000,
        "value": 139.221,
        "_internal_originalTime": 1689819381000
    },
    {
        "time": 1689819382000,
        "value": 139.226,
        "_internal_originalTime": 1689819382000
    },
    {
        "time": 1689819384000,
        "value": 139.223,
        "_internal_originalTime": 1689819384000
    },
    {
        "time": 1689819386000,
        "value": 139.215,
        "_internal_originalTime": 1689819386000
    },
    {
        "time": 1689819387000,
        "value": 139.215,
        "_internal_originalTime": 1689819387000
    },
    {
        "time": 1689819388000,
        "value": 139.215,
        "_internal_originalTime": 1689819388000
    },
    {
        "time": 1689819389000,
        "value": 139.21,
        "_internal_originalTime": 1689819389000
    },
    {
        "time": 1689819391000,
        "value": 139.214,
        "_internal_originalTime": 1689819391000
    },
    {
        "time": 1689819392000,
        "value": 139.21,
        "_internal_originalTime": 1689819392000
    },
    {
        "time": 1689819393000,
        "value": 139.21,
        "_internal_originalTime": 1689819393000
    },
    {
        "time": 1689819395000,
        "value": 139.217,
        "_internal_originalTime": 1689819395000
    },
    {
        "time": 1689819396000,
        "value": 139.211,
        "_internal_originalTime": 1689819396000
    },
    {
        "time": 1689819397000,
        "value": 139.212,
        "_internal_originalTime": 1689819397000
    },
    {
        "time": 1689819398000,
        "value": 139.208,
        "_internal_originalTime": 1689819398000
    },
    {
        "time": 1689819400000,
        "value": 139.213,
        "_internal_originalTime": 1689819400000
    },
    {
        "time": 1689819402000,
        "value": 139.21,
        "_internal_originalTime": 1689819402000
    },
    {
        "time": 1689819403000,
        "value": 139.215,
        "_internal_originalTime": 1689819403000
    },
    {
        "time": 1689819404000,
        "value": 139.215,
        "_internal_originalTime": 1689819404000
    },
    {
        "time": 1689819406000,
        "value": 139.216,
        "_internal_originalTime": 1689819406000
    },
    {
        "time": 1689819408000,
        "value": 139.215,
        "_internal_originalTime": 1689819408000
    },
    {
        "time": 1689819409000,
        "value": 139.215,
        "_internal_originalTime": 1689819409000
    },
    {
        "time": 1689819410000,
        "value": 139.216,
        "_internal_originalTime": 1689819410000
    },
    {
        "time": 1689819412000,
        "value": 139.216,
        "_internal_originalTime": 1689819412000
    },
    {
        "time": 1689819413000,
        "value": 139.218,
        "_internal_originalTime": 1689819413000
    },
    {
        "time": 1689819414000,
        "value": 139.22,
        "_internal_originalTime": 1689819414000
    },
    {
        "time": 1689819415000,
        "value": 139.214,
        "_internal_originalTime": 1689819415000
    },
    {
        "time": 1689819416000,
        "value": 139.213,
        "_internal_originalTime": 1689819416000
    },
    {
        "time": 1689819419000,
        "value": 139.211,
        "_internal_originalTime": 1689819419000
    },
    {
        "time": 1689819420000,
        "value": 139.212,
        "_internal_originalTime": 1689819420000
    },
    {
        "time": 1689819423000,
        "value": 139.215,
        "_internal_originalTime": 1689819423000
    },
    {
        "time": 1689819424000,
        "value": 139.211,
        "_internal_originalTime": 1689819424000
    },
    {
        "time": 1689819426000,
        "value": 139.215,
        "_internal_originalTime": 1689819426000
    },
    {
        "time": 1689819427000,
        "value": 139.215,
        "_internal_originalTime": 1689819427000
    },
    {
        "time": 1689819429000,
        "value": 139.215,
        "_internal_originalTime": 1689819429000
    },
    {
        "time": 1689819431000,
        "value": 139.212,
        "_internal_originalTime": 1689819431000
    },
    {
        "time": 1689819432000,
        "value": 139.211,
        "_internal_originalTime": 1689819432000
    },
    {
        "time": 1689819434000,
        "value": 139.215,
        "_internal_originalTime": 1689819434000
    },
    {
        "time": 1689819436000,
        "value": 139.215,
        "_internal_originalTime": 1689819436000
    },
    {
        "time": 1689819437000,
        "value": 139.215,
        "_internal_originalTime": 1689819437000
    },
    {
        "time": 1689819438000,
        "value": 139.215,
        "_internal_originalTime": 1689819438000
    },
    {
        "time": 1689819441000,
        "value": 139.211,
        "_internal_originalTime": 1689819441000
    },
    {
        "time": 1689819442000,
        "value": 139.212,
        "_internal_originalTime": 1689819442000
    },
    {
        "time": 1689819448000,
        "value": 139.214,
        "_internal_originalTime": 1689819448000
    },
    {
        "time": 1689819449000,
        "value": 139.211,
        "_internal_originalTime": 1689819449000
    },
    {
        "time": 1689819451000,
        "value": 139.215,
        "_internal_originalTime": 1689819451000
    },
    {
        "time": 1689819452000,
        "value": 139.215,
        "_internal_originalTime": 1689819452000
    },
    {
        "time": 1689819457000,
        "value": 139.216,
        "_internal_originalTime": 1689819457000
    },
    {
        "time": 1689819458000,
        "value": 139.22,
        "_internal_originalTime": 1689819458000
    },
    {
        "time": 1689819460000,
        "value": 139.223,
        "_internal_originalTime": 1689819460000
    },
    {
        "time": 1689819462000,
        "value": 139.218,
        "_internal_originalTime": 1689819462000
    },
    {
        "time": 1689819463000,
        "value": 139.221,
        "_internal_originalTime": 1689819463000
    },
    {
        "time": 1689819464000,
        "value": 139.218,
        "_internal_originalTime": 1689819464000
    },
    {
        "time": 1689819466000,
        "value": 139.224,
        "_internal_originalTime": 1689819466000
    },
    {
        "time": 1689819467000,
        "value": 139.225,
        "_internal_originalTime": 1689819467000
    },
    {
        "time": 1689819468000,
        "value": 139.233,
        "_internal_originalTime": 1689819468000
    },
    {
        "time": 1689819469000,
        "value": 139.227,
        "_internal_originalTime": 1689819469000
    },
    {
        "time": 1689819470000,
        "value": 139.227,
        "_internal_originalTime": 1689819470000
    },
    {
        "time": 1689819471000,
        "value": 139.227,
        "_internal_originalTime": 1689819471000
    },
    {
        "time": 1689819473000,
        "value": 139.226,
        "_internal_originalTime": 1689819473000
    },
    {
        "time": 1689819474000,
        "value": 139.224,
        "_internal_originalTime": 1689819474000
    },
    {
        "time": 1689819476000,
        "value": 139.221,
        "_internal_originalTime": 1689819476000
    },
    {
        "time": 1689819477000,
        "value": 139.222,
        "_internal_originalTime": 1689819477000
    },
    {
        "time": 1689819479000,
        "value": 139.215,
        "_internal_originalTime": 1689819479000
    },
    {
        "time": 1689819481000,
        "value": 139.216,
        "_internal_originalTime": 1689819481000
    },
    {
        "time": 1689819482000,
        "value": 139.215,
        "_internal_originalTime": 1689819482000
    },
    {
        "time": 1689819484000,
        "value": 139.215,
        "_internal_originalTime": 1689819484000
    },
    {
        "time": 1689819487000,
        "value": 139.21,
        "_internal_originalTime": 1689819487000
    },
    {
        "time": 1689819488000,
        "value": 139.215,
        "_internal_originalTime": 1689819488000
    },
    {
        "time": 1689819489000,
        "value": 139.213,
        "_internal_originalTime": 1689819489000
    },
    {
        "time": 1689819490000,
        "value": 139.211,
        "_internal_originalTime": 1689819490000
    },
    {
        "time": 1689819492000,
        "value": 139.215,
        "_internal_originalTime": 1689819492000
    },
    {
        "time": 1689819498000,
        "value": 139.212,
        "_internal_originalTime": 1689819498000
    },
    {
        "time": 1689819501000,
        "value": 139.212,
        "_internal_originalTime": 1689819501000
    },
    {
        "time": 1689819502000,
        "value": 139.212,
        "_internal_originalTime": 1689819502000
    },
    {
        "time": 1689819509000,
        "value": 139.21,
        "_internal_originalTime": 1689819509000
    },
    {
        "time": 1689819516000,
        "value": 139.212,
        "_internal_originalTime": 1689819516000
    },
    {
        "time": 1689819517000,
        "value": 139.215,
        "_internal_originalTime": 1689819517000
    },
    {
        "time": 1689819518000,
        "value": 139.211,
        "_internal_originalTime": 1689819518000
    },
    {
        "time": 1689819519000,
        "value": 139.214,
        "_internal_originalTime": 1689819519000
    },
    {
        "time": 1689819520000,
        "value": 139.215,
        "_internal_originalTime": 1689819520000
    },
    {
        "time": 1689819521000,
        "value": 139.212,
        "_internal_originalTime": 1689819521000
    },
    {
        "time": 1689819523000,
        "value": 139.21,
        "_internal_originalTime": 1689819523000
    },
    {
        "time": 1689819524000,
        "value": 139.215,
        "_internal_originalTime": 1689819524000
    },
    {
        "time": 1689819528000,
        "value": 139.214,
        "_internal_originalTime": 1689819528000
    },
    {
        "time": 1689819531000,
        "value": 139.214,
        "_internal_originalTime": 1689819531000
    },
    {
        "time": 1689819533000,
        "value": 139.212,
        "_internal_originalTime": 1689819533000
    },
    {
        "time": 1689819534000,
        "value": 139.212,
        "_internal_originalTime": 1689819534000
    },
    {
        "time": 1689819535000,
        "value": 139.212,
        "_internal_originalTime": 1689819535000
    },
    {
        "time": 1689819538000,
        "value": 139.216,
        "_internal_originalTime": 1689819538000
    },
    {
        "time": 1689819539000,
        "value": 139.21,
        "_internal_originalTime": 1689819539000
    },
    {
        "time": 1689819540000,
        "value": 139.216,
        "_internal_originalTime": 1689819540000
    },
    {
        "time": 1689819541000,
        "value": 139.215,
        "_internal_originalTime": 1689819541000
    },
    {
        "time": 1689819542000,
        "value": 139.22,
        "_internal_originalTime": 1689819542000
    },
    {
        "time": 1689819544000,
        "value": 139.225,
        "_internal_originalTime": 1689819544000
    },
    {
        "time": 1689819545000,
        "value": 139.222,
        "_internal_originalTime": 1689819545000
    },
    {
        "time": 1689819546000,
        "value": 139.228,
        "_internal_originalTime": 1689819546000
    },
    {
        "time": 1689819547000,
        "value": 139.228,
        "_internal_originalTime": 1689819547000
    },
    {
        "time": 1689819549000,
        "value": 139.23,
        "_internal_originalTime": 1689819549000
    },
    {
        "time": 1689819551000,
        "value": 139.229,
        "_internal_originalTime": 1689819551000
    },
    {
        "time": 1689819552000,
        "value": 139.226,
        "_internal_originalTime": 1689819552000
    },
    {
        "time": 1689819555000,
        "value": 139.228,
        "_internal_originalTime": 1689819555000
    },
    {
        "time": 1689819556000,
        "value": 139.228,
        "_internal_originalTime": 1689819556000
    },
    {
        "time": 1689819557000,
        "value": 139.228,
        "_internal_originalTime": 1689819557000
    },
    {
        "time": 1689819559000,
        "value": 139.229,
        "_internal_originalTime": 1689819559000
    },
    {
        "time": 1689819560000,
        "value": 139.231,
        "_internal_originalTime": 1689819560000
    },
    {
        "time": 1689819561000,
        "value": 139.229,
        "_internal_originalTime": 1689819561000
    },
    {
        "time": 1689819567000,
        "value": 139.229,
        "_internal_originalTime": 1689819567000
    },
    {
        "time": 1689819568000,
        "value": 139.23,
        "_internal_originalTime": 1689819568000
    },
    {
        "time": 1689819569000,
        "value": 139.229,
        "_internal_originalTime": 1689819569000
    },
    {
        "time": 1689819570000,
        "value": 139.233,
        "_internal_originalTime": 1689819570000
    },
    {
        "time": 1689819571000,
        "value": 139.233,
        "_internal_originalTime": 1689819571000
    },
    {
        "time": 1689819574000,
        "value": 139.232,
        "_internal_originalTime": 1689819574000
    },
    {
        "time": 1689819575000,
        "value": 139.234,
        "_internal_originalTime": 1689819575000
    },
    {
        "time": 1689819576000,
        "value": 139.231,
        "_internal_originalTime": 1689819576000
    },
    {
        "time": 1689819578000,
        "value": 139.233,
        "_internal_originalTime": 1689819578000
    },
    {
        "time": 1689819580000,
        "value": 139.23,
        "_internal_originalTime": 1689819580000
    },
    {
        "time": 1689819581000,
        "value": 139.231,
        "_internal_originalTime": 1689819581000
    },
    {
        "time": 1689819583000,
        "value": 139.233,
        "_internal_originalTime": 1689819583000
    },
    {
        "time": 1689819585000,
        "value": 139.233,
        "_internal_originalTime": 1689819585000
    },
    {
        "time": 1689819587000,
        "value": 139.228,
        "_internal_originalTime": 1689819587000
    },
    {
        "time": 1689819588000,
        "value": 139.234,
        "_internal_originalTime": 1689819588000
    },
    {
        "time": 1689819589000,
        "value": 139.23,
        "_internal_originalTime": 1689819589000
    },
    {
        "time": 1689819590000,
        "value": 139.227,
        "_internal_originalTime": 1689819590000
    },
    {
        "time": 1689819592000,
        "value": 139.227,
        "_internal_originalTime": 1689819592000
    },
    {
        "time": 1689819594000,
        "value": 139.232,
        "_internal_originalTime": 1689819594000
    },
    {
        "time": 1689819595000,
        "value": 139.233,
        "_internal_originalTime": 1689819595000
    },
    {
        "time": 1689819597000,
        "value": 139.241,
        "_internal_originalTime": 1689819597000
    },
    {
        "time": 1689819598000,
        "value": 139.236,
        "_internal_originalTime": 1689819598000
    },
    {
        "time": 1689819599000,
        "value": 139.24,
        "_internal_originalTime": 1689819599000
    },
    {
        "time": 1689819600000,
        "value": 139.236,
        "_internal_originalTime": 1689819600000
    },
    {
        "time": 1689819601000,
        "value": 139.239,
        "_internal_originalTime": 1689819601000
    },
    {
        "time": 1689819602000,
        "value": 139.236,
        "_internal_originalTime": 1689819602000
    },
    {
        "time": 1689819604000,
        "value": 139.234,
        "_internal_originalTime": 1689819604000
    },
    {
        "time": 1689819606000,
        "value": 139.232,
        "_internal_originalTime": 1689819606000
    },
    {
        "time": 1689819607000,
        "value": 139.234,
        "_internal_originalTime": 1689819607000
    },
    {
        "time": 1689819608000,
        "value": 139.23,
        "_internal_originalTime": 1689819608000
    },
    {
        "time": 1689819609000,
        "value": 139.231,
        "_internal_originalTime": 1689819609000
    },
    {
        "time": 1689819610000,
        "value": 139.23,
        "_internal_originalTime": 1689819610000
    },
    {
        "time": 1689819611000,
        "value": 139.23,
        "_internal_originalTime": 1689819611000
    },
    {
        "time": 1689819612000,
        "value": 139.23,
        "_internal_originalTime": 1689819612000
    },
    {
        "time": 1689819614000,
        "value": 139.224,
        "_internal_originalTime": 1689819614000
    },
    {
        "time": 1689819615000,
        "value": 139.225,
        "_internal_originalTime": 1689819615000
    },
    {
        "time": 1689819616000,
        "value": 139.227,
        "_internal_originalTime": 1689819616000
    },
    {
        "time": 1689819619000,
        "value": 139.227,
        "_internal_originalTime": 1689819619000
    },
    {
        "time": 1689819620000,
        "value": 139.228,
        "_internal_originalTime": 1689819620000
    },
    {
        "time": 1689819621000,
        "value": 139.225,
        "_internal_originalTime": 1689819621000
    },
    {
        "time": 1689819622000,
        "value": 139.224,
        "_internal_originalTime": 1689819622000
    },
    {
        "time": 1689819623000,
        "value": 139.22,
        "_internal_originalTime": 1689819623000
    },
    {
        "time": 1689819626000,
        "value": 139.223,
        "_internal_originalTime": 1689819626000
    },
    {
        "time": 1689819629000,
        "value": 139.223,
        "_internal_originalTime": 1689819629000
    },
    {
        "time": 1689819630000,
        "value": 139.225,
        "_internal_originalTime": 1689819630000
    },
    {
        "time": 1689819633000,
        "value": 139.222,
        "_internal_originalTime": 1689819633000
    },
    {
        "time": 1689819634000,
        "value": 139.222,
        "_internal_originalTime": 1689819634000
    },
    {
        "time": 1689819635000,
        "value": 139.223,
        "_internal_originalTime": 1689819635000
    },
    {
        "time": 1689819636000,
        "value": 139.226,
        "_internal_originalTime": 1689819636000
    },
    {
        "time": 1689819637000,
        "value": 139.223,
        "_internal_originalTime": 1689819637000
    },
    {
        "time": 1689819639000,
        "value": 139.225,
        "_internal_originalTime": 1689819639000
    },
    {
        "time": 1689819641000,
        "value": 139.22,
        "_internal_originalTime": 1689819641000
    },
    {
        "time": 1689819642000,
        "value": 139.221,
        "_internal_originalTime": 1689819642000
    },
    {
        "time": 1689819643000,
        "value": 139.22,
        "_internal_originalTime": 1689819643000
    },
    {
        "time": 1689819645000,
        "value": 139.22,
        "_internal_originalTime": 1689819645000
    },
    {
        "time": 1689819648000,
        "value": 139.224,
        "_internal_originalTime": 1689819648000
    },
    {
        "time": 1689819649000,
        "value": 139.22,
        "_internal_originalTime": 1689819649000
    },
    {
        "time": 1689819652000,
        "value": 139.225,
        "_internal_originalTime": 1689819652000
    },
    {
        "time": 1689819654000,
        "value": 139.224,
        "_internal_originalTime": 1689819654000
    },
    {
        "time": 1689819655000,
        "value": 139.226,
        "_internal_originalTime": 1689819655000
    },
    {
        "time": 1689819657000,
        "value": 139.225,
        "_internal_originalTime": 1689819657000
    },
    {
        "time": 1689819659000,
        "value": 139.218,
        "_internal_originalTime": 1689819659000
    },
    {
        "time": 1689819667000,
        "value": 139.22,
        "_internal_originalTime": 1689819667000
    },
    {
        "time": 1689819669000,
        "value": 139.223,
        "_internal_originalTime": 1689819669000
    },
    {
        "time": 1689819672000,
        "value": 139.224,
        "_internal_originalTime": 1689819672000
    },
    {
        "time": 1689819676000,
        "value": 139.225,
        "_internal_originalTime": 1689819676000
    },
    {
        "time": 1689819678000,
        "value": 139.225,
        "_internal_originalTime": 1689819678000
    },
    {
        "time": 1689819684000,
        "value": 139.224,
        "_internal_originalTime": 1689819684000
    },
    {
        "time": 1689819686000,
        "value": 139.224,
        "_internal_originalTime": 1689819686000
    },
    {
        "time": 1689819688000,
        "value": 139.224,
        "_internal_originalTime": 1689819688000
    },
    {
        "time": 1689819689000,
        "value": 139.219,
        "_internal_originalTime": 1689819689000
    },
    {
        "time": 1689819691000,
        "value": 139.22,
        "_internal_originalTime": 1689819691000
    },
    {
        "time": 1689819692000,
        "value": 139.224,
        "_internal_originalTime": 1689819692000
    },
    {
        "time": 1689819693000,
        "value": 139.224,
        "_internal_originalTime": 1689819693000
    },
    {
        "time": 1689819700000,
        "value": 139.222,
        "_internal_originalTime": 1689819700000
    },
    {
        "time": 1689819703000,
        "value": 139.21,
        "_internal_originalTime": 1689819703000
    },
    {
        "time": 1689819704000,
        "value": 139.21,
        "_internal_originalTime": 1689819704000
    },
    {
        "time": 1689819707000,
        "value": 139.21,
        "_internal_originalTime": 1689819707000
    },
    {
        "time": 1689819708000,
        "value": 139.212,
        "_internal_originalTime": 1689819708000
    },
    {
        "time": 1689819709000,
        "value": 139.212,
        "_internal_originalTime": 1689819709000
    },
    {
        "time": 1689819710000,
        "value": 139.216,
        "_internal_originalTime": 1689819710000
    },
    {
        "time": 1689819715000,
        "value": 139.21,
        "_internal_originalTime": 1689819715000
    },
    {
        "time": 1689819716000,
        "value": 139.21,
        "_internal_originalTime": 1689819716000
    },
    {
        "time": 1689819718000,
        "value": 139.212,
        "_internal_originalTime": 1689819718000
    },
    {
        "time": 1689819720000,
        "value": 139.212,
        "_internal_originalTime": 1689819720000
    },
    {
        "time": 1689819722000,
        "value": 139.216,
        "_internal_originalTime": 1689819722000
    },
    {
        "time": 1689819724000,
        "value": 139.206,
        "_internal_originalTime": 1689819724000
    },
    {
        "time": 1689819725000,
        "value": 139.2,
        "_internal_originalTime": 1689819725000
    },
    {
        "time": 1689819730000,
        "value": 139.203,
        "_internal_originalTime": 1689819730000
    },
    {
        "time": 1689819731000,
        "value": 139.202,
        "_internal_originalTime": 1689819731000
    },
    {
        "time": 1689819733000,
        "value": 139.202,
        "_internal_originalTime": 1689819733000
    },
    {
        "time": 1689819734000,
        "value": 139.201,
        "_internal_originalTime": 1689819734000
    },
    {
        "time": 1689819735000,
        "value": 139.199,
        "_internal_originalTime": 1689819735000
    },
    {
        "time": 1689819736000,
        "value": 139.2,
        "_internal_originalTime": 1689819736000
    },
    {
        "time": 1689819737000,
        "value": 139.205,
        "_internal_originalTime": 1689819737000
    },
    {
        "time": 1689819739000,
        "value": 139.204,
        "_internal_originalTime": 1689819739000
    },
    {
        "time": 1689819740000,
        "value": 139.2,
        "_internal_originalTime": 1689819740000
    },
    {
        "time": 1689819747000,
        "value": 139.202,
        "_internal_originalTime": 1689819747000
    },
    {
        "time": 1689819749000,
        "value": 139.196,
        "_internal_originalTime": 1689819749000
    },
    {
        "time": 1689819750000,
        "value": 139.199,
        "_internal_originalTime": 1689819750000
    },
    {
        "time": 1689819751000,
        "value": 139.2,
        "_internal_originalTime": 1689819751000
    },
    {
        "time": 1689819752000,
        "value": 139.2,
        "_internal_originalTime": 1689819752000
    },
    {
        "time": 1689819754000,
        "value": 139.2,
        "_internal_originalTime": 1689819754000
    },
    {
        "time": 1689819755000,
        "value": 139.196,
        "_internal_originalTime": 1689819755000
    },
    {
        "time": 1689819759000,
        "value": 139.193,
        "_internal_originalTime": 1689819759000
    },
    {
        "time": 1689819760000,
        "value": 139.195,
        "_internal_originalTime": 1689819760000
    },
    {
        "time": 1689819761000,
        "value": 139.195,
        "_internal_originalTime": 1689819761000
    },
    {
        "time": 1689819763000,
        "value": 139.193,
        "_internal_originalTime": 1689819763000
    },
    {
        "time": 1689819764000,
        "value": 139.188,
        "_internal_originalTime": 1689819764000
    },
    {
        "time": 1689819766000,
        "value": 139.195,
        "_internal_originalTime": 1689819766000
    },
    {
        "time": 1689819767000,
        "value": 139.195,
        "_internal_originalTime": 1689819767000
    },
    {
        "time": 1689819768000,
        "value": 139.192,
        "_internal_originalTime": 1689819768000
    },
    {
        "time": 1689819769000,
        "value": 139.193,
        "_internal_originalTime": 1689819769000
    },
    {
        "time": 1689819770000,
        "value": 139.195,
        "_internal_originalTime": 1689819770000
    },
    {
        "time": 1689819773000,
        "value": 139.196,
        "_internal_originalTime": 1689819773000
    },
    {
        "time": 1689819776000,
        "value": 139.198,
        "_internal_originalTime": 1689819776000
    },
    {
        "time": 1689819778000,
        "value": 139.192,
        "_internal_originalTime": 1689819778000
    },
    {
        "time": 1689819779000,
        "value": 139.191,
        "_internal_originalTime": 1689819779000
    },
    {
        "time": 1689819780000,
        "value": 139.193,
        "_internal_originalTime": 1689819780000
    },
    {
        "time": 1689819781000,
        "value": 139.189,
        "_internal_originalTime": 1689819781000
    },
    {
        "time": 1689819782000,
        "value": 139.19,
        "_internal_originalTime": 1689819782000
    },
    {
        "time": 1689819784000,
        "value": 139.189,
        "_internal_originalTime": 1689819784000
    },
    {
        "time": 1689819785000,
        "value": 139.188,
        "_internal_originalTime": 1689819785000
    },
    {
        "time": 1689819786000,
        "value": 139.187,
        "_internal_originalTime": 1689819786000
    },
    {
        "time": 1689819787000,
        "value": 139.185,
        "_internal_originalTime": 1689819787000
    },
    {
        "time": 1689819790000,
        "value": 139.184,
        "_internal_originalTime": 1689819790000
    },
    {
        "time": 1689819791000,
        "value": 139.181,
        "_internal_originalTime": 1689819791000
    },
    {
        "time": 1689819792000,
        "value": 139.185,
        "_internal_originalTime": 1689819792000
    },
    {
        "time": 1689819794000,
        "value": 139.184,
        "_internal_originalTime": 1689819794000
    },
    {
        "time": 1689819795000,
        "value": 139.18,
        "_internal_originalTime": 1689819795000
    },
    {
        "time": 1689819798000,
        "value": 139.179,
        "_internal_originalTime": 1689819798000
    },
    {
        "time": 1689819800000,
        "value": 139.185,
        "_internal_originalTime": 1689819800000
    },
    {
        "time": 1689819801000,
        "value": 139.184,
        "_internal_originalTime": 1689819801000
    },
    {
        "time": 1689819805000,
        "value": 139.178,
        "_internal_originalTime": 1689819805000
    },
    {
        "time": 1689819806000,
        "value": 139.184,
        "_internal_originalTime": 1689819806000
    },
    {
        "time": 1689819807000,
        "value": 139.183,
        "_internal_originalTime": 1689819807000
    },
    {
        "time": 1689819808000,
        "value": 139.18,
        "_internal_originalTime": 1689819808000
    },
    {
        "time": 1689819809000,
        "value": 139.179,
        "_internal_originalTime": 1689819809000
    },
    {
        "time": 1689819810000,
        "value": 139.185,
        "_internal_originalTime": 1689819810000
    },
    {
        "time": 1689819812000,
        "value": 139.183,
        "_internal_originalTime": 1689819812000
    },
    {
        "time": 1689819813000,
        "value": 139.182,
        "_internal_originalTime": 1689819813000
    },
    {
        "time": 1689819814000,
        "value": 139.183,
        "_internal_originalTime": 1689819814000
    },
    {
        "time": 1689819815000,
        "value": 139.187,
        "_internal_originalTime": 1689819815000
    },
    {
        "time": 1689819816000,
        "value": 139.183,
        "_internal_originalTime": 1689819816000
    },
    {
        "time": 1689819817000,
        "value": 139.181,
        "_internal_originalTime": 1689819817000
    },
    {
        "time": 1689819819000,
        "value": 139.179,
        "_internal_originalTime": 1689819819000
    },
    {
        "time": 1689819820000,
        "value": 139.167,
        "_internal_originalTime": 1689819820000
    },
    {
        "time": 1689819822000,
        "value": 139.162,
        "_internal_originalTime": 1689819822000
    },
    {
        "time": 1689819823000,
        "value": 139.165,
        "_internal_originalTime": 1689819823000
    },
    {
        "time": 1689819825000,
        "value": 139.162,
        "_internal_originalTime": 1689819825000
    },
    {
        "time": 1689819826000,
        "value": 139.164,
        "_internal_originalTime": 1689819826000
    },
    {
        "time": 1689819827000,
        "value": 139.164,
        "_internal_originalTime": 1689819827000
    },
    {
        "time": 1689819828000,
        "value": 139.169,
        "_internal_originalTime": 1689819828000
    },
    {
        "time": 1689819829000,
        "value": 139.169,
        "_internal_originalTime": 1689819829000
    },
    {
        "time": 1689819830000,
        "value": 139.165,
        "_internal_originalTime": 1689819830000
    },
    {
        "time": 1689819832000,
        "value": 139.165,
        "_internal_originalTime": 1689819832000
    },
    {
        "time": 1689819833000,
        "value": 139.167,
        "_internal_originalTime": 1689819833000
    },
    {
        "time": 1689819834000,
        "value": 139.167,
        "_internal_originalTime": 1689819834000
    },
    {
        "time": 1689819835000,
        "value": 139.166,
        "_internal_originalTime": 1689819835000
    },
    {
        "time": 1689819836000,
        "value": 139.164,
        "_internal_originalTime": 1689819836000
    },
    {
        "time": 1689819838000,
        "value": 139.166,
        "_internal_originalTime": 1689819838000
    },
    {
        "time": 1689819839000,
        "value": 139.161,
        "_internal_originalTime": 1689819839000
    },
    {
        "time": 1689819840000,
        "value": 139.166,
        "_internal_originalTime": 1689819840000
    },
    {
        "time": 1689819841000,
        "value": 139.166,
        "_internal_originalTime": 1689819841000
    },
    {
        "time": 1689819842000,
        "value": 139.166,
        "_internal_originalTime": 1689819842000
    },
    {
        "time": 1689819846000,
        "value": 139.162,
        "_internal_originalTime": 1689819846000
    },
    {
        "time": 1689819847000,
        "value": 139.166,
        "_internal_originalTime": 1689819847000
    },
    {
        "time": 1689819848000,
        "value": 139.162,
        "_internal_originalTime": 1689819848000
    },
    {
        "time": 1689819850000,
        "value": 139.162,
        "_internal_originalTime": 1689819850000
    },
    {
        "time": 1689819851000,
        "value": 139.167,
        "_internal_originalTime": 1689819851000
    },
    {
        "time": 1689819852000,
        "value": 139.172,
        "_internal_originalTime": 1689819852000
    },
    {
        "time": 1689819853000,
        "value": 139.174,
        "_internal_originalTime": 1689819853000
    },
    {
        "time": 1689819854000,
        "value": 139.17,
        "_internal_originalTime": 1689819854000
    },
    {
        "time": 1689819855000,
        "value": 139.173,
        "_internal_originalTime": 1689819855000
    },
    {
        "time": 1689819856000,
        "value": 139.17,
        "_internal_originalTime": 1689819856000
    },
    {
        "time": 1689819857000,
        "value": 139.17,
        "_internal_originalTime": 1689819857000
    },
    {
        "time": 1689819858000,
        "value": 139.171,
        "_internal_originalTime": 1689819858000
    },
    {
        "time": 1689819860000,
        "value": 139.174,
        "_internal_originalTime": 1689819860000
    },
    {
        "time": 1689819862000,
        "value": 139.169,
        "_internal_originalTime": 1689819862000
    },
    {
        "time": 1689819864000,
        "value": 139.175,
        "_internal_originalTime": 1689819864000
    },
    {
        "time": 1689819866000,
        "value": 139.175,
        "_internal_originalTime": 1689819866000
    },
    {
        "time": 1689819868000,
        "value": 139.18,
        "_internal_originalTime": 1689819868000
    },
    {
        "time": 1689819869000,
        "value": 139.174,
        "_internal_originalTime": 1689819869000
    },
    {
        "time": 1689819870000,
        "value": 139.177,
        "_internal_originalTime": 1689819870000
    },
    {
        "time": 1689819871000,
        "value": 139.175,
        "_internal_originalTime": 1689819871000
    },
    {
        "time": 1689819872000,
        "value": 139.172,
        "_internal_originalTime": 1689819872000
    },
    {
        "time": 1689819873000,
        "value": 139.176,
        "_internal_originalTime": 1689819873000
    },
    {
        "time": 1689819874000,
        "value": 139.176,
        "_internal_originalTime": 1689819874000
    },
    {
        "time": 1689819876000,
        "value": 139.166,
        "_internal_originalTime": 1689819876000
    },
    {
        "time": 1689819877000,
        "value": 139.165,
        "_internal_originalTime": 1689819877000
    },
    {
        "time": 1689819879000,
        "value": 139.165,
        "_internal_originalTime": 1689819879000
    },
    {
        "time": 1689819880000,
        "value": 139.161,
        "_internal_originalTime": 1689819880000
    },
    {
        "time": 1689819881000,
        "value": 139.165,
        "_internal_originalTime": 1689819881000
    },
    {
        "time": 1689819883000,
        "value": 139.162,
        "_internal_originalTime": 1689819883000
    },
    {
        "time": 1689819890000,
        "value": 139.167,
        "_internal_originalTime": 1689819890000
    },
    {
        "time": 1689819891000,
        "value": 139.166,
        "_internal_originalTime": 1689819891000
    },
    {
        "time": 1689819892000,
        "value": 139.164,
        "_internal_originalTime": 1689819892000
    },
    {
        "time": 1689819893000,
        "value": 139.165,
        "_internal_originalTime": 1689819893000
    },
    {
        "time": 1689819895000,
        "value": 139.164,
        "_internal_originalTime": 1689819895000
    },
    {
        "time": 1689819896000,
        "value": 139.162,
        "_internal_originalTime": 1689819896000
    },
    {
        "time": 1689819898000,
        "value": 139.166,
        "_internal_originalTime": 1689819898000
    },
    {
        "time": 1689819899000,
        "value": 139.166,
        "_internal_originalTime": 1689819899000
    },
    {
        "time": 1689819901000,
        "value": 139.165,
        "_internal_originalTime": 1689819901000
    },
    {
        "time": 1689819902000,
        "value": 139.16,
        "_internal_originalTime": 1689819902000
    },
    {
        "time": 1689819903000,
        "value": 139.164,
        "_internal_originalTime": 1689819903000
    },
    {
        "time": 1689819904000,
        "value": 139.16,
        "_internal_originalTime": 1689819904000
    },
    {
        "time": 1689819905000,
        "value": 139.166,
        "_internal_originalTime": 1689819905000
    },
    {
        "time": 1689819907000,
        "value": 139.161,
        "_internal_originalTime": 1689819907000
    },
    {
        "time": 1689819908000,
        "value": 139.16,
        "_internal_originalTime": 1689819908000
    },
    {
        "time": 1689819909000,
        "value": 139.162,
        "_internal_originalTime": 1689819909000
    },
    {
        "time": 1689819910000,
        "value": 139.161,
        "_internal_originalTime": 1689819910000
    },
    {
        "time": 1689819911000,
        "value": 139.158,
        "_internal_originalTime": 1689819911000
    },
    {
        "time": 1689819913000,
        "value": 139.161,
        "_internal_originalTime": 1689819913000
    },
    {
        "time": 1689819914000,
        "value": 139.164,
        "_internal_originalTime": 1689819914000
    },
    {
        "time": 1689819915000,
        "value": 139.164,
        "_internal_originalTime": 1689819915000
    },
    {
        "time": 1689819917000,
        "value": 139.163,
        "_internal_originalTime": 1689819917000
    },
    {
        "time": 1689819918000,
        "value": 139.164,
        "_internal_originalTime": 1689819918000
    },
    {
        "time": 1689819920000,
        "value": 139.166,
        "_internal_originalTime": 1689819920000
    },
    {
        "time": 1689819922000,
        "value": 139.166,
        "_internal_originalTime": 1689819922000
    },
    {
        "time": 1689819923000,
        "value": 139.164,
        "_internal_originalTime": 1689819923000
    },
    {
        "time": 1689819925000,
        "value": 139.164,
        "_internal_originalTime": 1689819925000
    },
    {
        "time": 1689819926000,
        "value": 139.164,
        "_internal_originalTime": 1689819926000
    },
    {
        "time": 1689819927000,
        "value": 139.165,
        "_internal_originalTime": 1689819927000
    },
    {
        "time": 1689819928000,
        "value": 139.165,
        "_internal_originalTime": 1689819928000
    },
    {
        "time": 1689819929000,
        "value": 139.167,
        "_internal_originalTime": 1689819929000
    },
    {
        "time": 1689819931000,
        "value": 139.179,
        "_internal_originalTime": 1689819931000
    },
    {
        "time": 1689819933000,
        "value": 139.177,
        "_internal_originalTime": 1689819933000
    },
    {
        "time": 1689819934000,
        "value": 139.178,
        "_internal_originalTime": 1689819934000
    },
    {
        "time": 1689819935000,
        "value": 139.179,
        "_internal_originalTime": 1689819935000
    },
    {
        "time": 1689819936000,
        "value": 139.178,
        "_internal_originalTime": 1689819936000
    },
    {
        "time": 1689819937000,
        "value": 139.174,
        "_internal_originalTime": 1689819937000
    },
    {
        "time": 1689819939000,
        "value": 139.171,
        "_internal_originalTime": 1689819939000
    },
    {
        "time": 1689819940000,
        "value": 139.175,
        "_internal_originalTime": 1689819940000
    },
    {
        "time": 1689819941000,
        "value": 139.172,
        "_internal_originalTime": 1689819941000
    },
    {
        "time": 1689819942000,
        "value": 139.177,
        "_internal_originalTime": 1689819942000
    },
    {
        "time": 1689819944000,
        "value": 139.176,
        "_internal_originalTime": 1689819944000
    },
    {
        "time": 1689819946000,
        "value": 139.173,
        "_internal_originalTime": 1689819946000
    },
    {
        "time": 1689819948000,
        "value": 139.174,
        "_internal_originalTime": 1689819948000
    },
    {
        "time": 1689819949000,
        "value": 139.176,
        "_internal_originalTime": 1689819949000
    },
    {
        "time": 1689819950000,
        "value": 139.172,
        "_internal_originalTime": 1689819950000
    },
    {
        "time": 1689819952000,
        "value": 139.173,
        "_internal_originalTime": 1689819952000
    },
    {
        "time": 1689819953000,
        "value": 139.166,
        "_internal_originalTime": 1689819953000
    },
    {
        "time": 1689819954000,
        "value": 139.167,
        "_internal_originalTime": 1689819954000
    },
    {
        "time": 1689819955000,
        "value": 139.166,
        "_internal_originalTime": 1689819955000
    },
    {
        "time": 1689819957000,
        "value": 139.163,
        "_internal_originalTime": 1689819957000
    },
    {
        "time": 1689819959000,
        "value": 139.16,
        "_internal_originalTime": 1689819959000
    },
    {
        "time": 1689819960000,
        "value": 139.16,
        "_internal_originalTime": 1689819960000
    },
    {
        "time": 1689819962000,
        "value": 139.163,
        "_internal_originalTime": 1689819962000
    },
    {
        "time": 1689819963000,
        "value": 139.165,
        "_internal_originalTime": 1689819963000
    },
    {
        "time": 1689819965000,
        "value": 139.167,
        "_internal_originalTime": 1689819965000
    },
    {
        "time": 1689819966000,
        "value": 139.166,
        "_internal_originalTime": 1689819966000
    },
    {
        "time": 1689819967000,
        "value": 139.165,
        "_internal_originalTime": 1689819967000
    },
    {
        "time": 1689819969000,
        "value": 139.165,
        "_internal_originalTime": 1689819969000
    },
    {
        "time": 1689819970000,
        "value": 139.166,
        "_internal_originalTime": 1689819970000
    },
    {
        "time": 1689819971000,
        "value": 139.165,
        "_internal_originalTime": 1689819971000
    },
    {
        "time": 1689819973000,
        "value": 139.163,
        "_internal_originalTime": 1689819973000
    },
    {
        "time": 1689819974000,
        "value": 139.165,
        "_internal_originalTime": 1689819974000
    },
    {
        "time": 1689819978000,
        "value": 139.162,
        "_internal_originalTime": 1689819978000
    },
    {
        "time": 1689819979000,
        "value": 139.162,
        "_internal_originalTime": 1689819979000
    },
    {
        "time": 1689819982000,
        "value": 139.163,
        "_internal_originalTime": 1689819982000
    },
    {
        "time": 1689819985000,
        "value": 139.159,
        "_internal_originalTime": 1689819985000
    },
    {
        "time": 1689819986000,
        "value": 139.148,
        "_internal_originalTime": 1689819986000
    },
    {
        "time": 1689819987000,
        "value": 139.146,
        "_internal_originalTime": 1689819987000
    },
    {
        "time": 1689819988000,
        "value": 139.146,
        "_internal_originalTime": 1689819988000
    },
    {
        "time": 1689819990000,
        "value": 139.147,
        "_internal_originalTime": 1689819990000
    },
    {
        "time": 1689819991000,
        "value": 139.151,
        "_internal_originalTime": 1689819991000
    },
    {
        "time": 1689819993000,
        "value": 139.149,
        "_internal_originalTime": 1689819993000
    },
    {
        "time": 1689819994000,
        "value": 139.144,
        "_internal_originalTime": 1689819994000
    },
    {
        "time": 1689819995000,
        "value": 139.147,
        "_internal_originalTime": 1689819995000
    },
    {
        "time": 1689819996000,
        "value": 139.144,
        "_internal_originalTime": 1689819996000
    },
    {
        "time": 1689819998000,
        "value": 139.144,
        "_internal_originalTime": 1689819998000
    },
    {
        "time": 1689819999000,
        "value": 139.144,
        "_internal_originalTime": 1689819999000
    },
    {
        "time": 1689820000000,
        "value": 139.144,
        "_internal_originalTime": 1689820000000
    },
    {
        "time": 1689820003000,
        "value": 139.151,
        "_internal_originalTime": 1689820003000
    },
    {
        "time": 1689820005000,
        "value": 139.153,
        "_internal_originalTime": 1689820005000
    },
    {
        "time": 1689820006000,
        "value": 139.15,
        "_internal_originalTime": 1689820006000
    },
    {
        "time": 1689820009000,
        "value": 139.127,
        "_internal_originalTime": 1689820009000
    },
    {
        "time": 1689820010000,
        "value": 139.129,
        "_internal_originalTime": 1689820010000
    },
    {
        "time": 1689820011000,
        "value": 139.129,
        "_internal_originalTime": 1689820011000
    },
    {
        "time": 1689820013000,
        "value": 139.124,
        "_internal_originalTime": 1689820013000
    },
    {
        "time": 1689820014000,
        "value": 139.127,
        "_internal_originalTime": 1689820014000
    },
    {
        "time": 1689820016000,
        "value": 139.128,
        "_internal_originalTime": 1689820016000
    },
    {
        "time": 1689820017000,
        "value": 139.122,
        "_internal_originalTime": 1689820017000
    },
    {
        "time": 1689820018000,
        "value": 139.128,
        "_internal_originalTime": 1689820018000
    },
    {
        "time": 1689820019000,
        "value": 139.126,
        "_internal_originalTime": 1689820019000
    },
    {
        "time": 1689820021000,
        "value": 139.123,
        "_internal_originalTime": 1689820021000
    },
    {
        "time": 1689820022000,
        "value": 139.128,
        "_internal_originalTime": 1689820022000
    },
    {
        "time": 1689820023000,
        "value": 139.127,
        "_internal_originalTime": 1689820023000
    },
    {
        "time": 1689820024000,
        "value": 139.12,
        "_internal_originalTime": 1689820024000
    },
    {
        "time": 1689820025000,
        "value": 139.123,
        "_internal_originalTime": 1689820025000
    },
    {
        "time": 1689820026000,
        "value": 139.121,
        "_internal_originalTime": 1689820026000
    },
    {
        "time": 1689820027000,
        "value": 139.117,
        "_internal_originalTime": 1689820027000
    },
    {
        "time": 1689820028000,
        "value": 139.115,
        "_internal_originalTime": 1689820028000
    },
    {
        "time": 1689820029000,
        "value": 139.118,
        "_internal_originalTime": 1689820029000
    },
    {
        "time": 1689820030000,
        "value": 139.114,
        "_internal_originalTime": 1689820030000
    },
    {
        "time": 1689820031000,
        "value": 139.116,
        "_internal_originalTime": 1689820031000
    },
    {
        "time": 1689820033000,
        "value": 139.114,
        "_internal_originalTime": 1689820033000
    },
    {
        "time": 1689820034000,
        "value": 139.116,
        "_internal_originalTime": 1689820034000
    },
    {
        "time": 1689820036000,
        "value": 139.119,
        "_internal_originalTime": 1689820036000
    },
    {
        "time": 1689820037000,
        "value": 139.12,
        "_internal_originalTime": 1689820037000
    },
    {
        "time": 1689820038000,
        "value": 139.121,
        "_internal_originalTime": 1689820038000
    },
    {
        "time": 1689820039000,
        "value": 139.12,
        "_internal_originalTime": 1689820039000
    },
    {
        "time": 1689820041000,
        "value": 139.119,
        "_internal_originalTime": 1689820041000
    },
    {
        "time": 1689820042000,
        "value": 139.116,
        "_internal_originalTime": 1689820042000
    },
    {
        "time": 1689820045000,
        "value": 139.116,
        "_internal_originalTime": 1689820045000
    },
    {
        "time": 1689820047000,
        "value": 139.116,
        "_internal_originalTime": 1689820047000
    },
    {
        "time": 1689820048000,
        "value": 139.114,
        "_internal_originalTime": 1689820048000
    },
    {
        "time": 1689820049000,
        "value": 139.124,
        "_internal_originalTime": 1689820049000
    },
    {
        "time": 1689820050000,
        "value": 139.124,
        "_internal_originalTime": 1689820050000
    },
    {
        "time": 1689820051000,
        "value": 139.129,
        "_internal_originalTime": 1689820051000
    },
    {
        "time": 1689820053000,
        "value": 139.129,
        "_internal_originalTime": 1689820053000
    },
    {
        "time": 1689820055000,
        "value": 139.126,
        "_internal_originalTime": 1689820055000
    },
    {
        "time": 1689820057000,
        "value": 139.129,
        "_internal_originalTime": 1689820057000
    },
    {
        "time": 1689820058000,
        "value": 139.129,
        "_internal_originalTime": 1689820058000
    },
    {
        "time": 1689820059000,
        "value": 139.13,
        "_internal_originalTime": 1689820059000
    },
    {
        "time": 1689820060000,
        "value": 139.124,
        "_internal_originalTime": 1689820060000
    },
    {
        "time": 1689820063000,
        "value": 139.124,
        "_internal_originalTime": 1689820063000
    },
    {
        "time": 1689820064000,
        "value": 139.138,
        "_internal_originalTime": 1689820064000
    },
    {
        "time": 1689820065000,
        "value": 139.137,
        "_internal_originalTime": 1689820065000
    },
    {
        "time": 1689820066000,
        "value": 139.135,
        "_internal_originalTime": 1689820066000
    },
    {
        "time": 1689820067000,
        "value": 139.134,
        "_internal_originalTime": 1689820067000
    },
    {
        "time": 1689820069000,
        "value": 139.132,
        "_internal_originalTime": 1689820069000
    },
    {
        "time": 1689820070000,
        "value": 139.13,
        "_internal_originalTime": 1689820070000
    },
    {
        "time": 1689820071000,
        "value": 139.126,
        "_internal_originalTime": 1689820071000
    },
    {
        "time": 1689820072000,
        "value": 139.13,
        "_internal_originalTime": 1689820072000
    },
    {
        "time": 1689820073000,
        "value": 139.13,
        "_internal_originalTime": 1689820073000
    },
    {
        "time": 1689820075000,
        "value": 139.124,
        "_internal_originalTime": 1689820075000
    },
    {
        "time": 1689820076000,
        "value": 139.122,
        "_internal_originalTime": 1689820076000
    },
    {
        "time": 1689820078000,
        "value": 139.12,
        "_internal_originalTime": 1689820078000
    },
    {
        "time": 1689820079000,
        "value": 139.11,
        "_internal_originalTime": 1689820079000
    },
    {
        "time": 1689820080000,
        "value": 139.117,
        "_internal_originalTime": 1689820080000
    },
    {
        "time": 1689820081000,
        "value": 139.119,
        "_internal_originalTime": 1689820081000
    },
    {
        "time": 1689820082000,
        "value": 139.117,
        "_internal_originalTime": 1689820082000
    },
    {
        "time": 1689820083000,
        "value": 139.112,
        "_internal_originalTime": 1689820083000
    },
    {
        "time": 1689820084000,
        "value": 139.118,
        "_internal_originalTime": 1689820084000
    },
    {
        "time": 1689820086000,
        "value": 139.119,
        "_internal_originalTime": 1689820086000
    },
    {
        "time": 1689820087000,
        "value": 139.117,
        "_internal_originalTime": 1689820087000
    },
    {
        "time": 1689820088000,
        "value": 139.116,
        "_internal_originalTime": 1689820088000
    },
    {
        "time": 1689820090000,
        "value": 139.116,
        "_internal_originalTime": 1689820090000
    },
    {
        "time": 1689820092000,
        "value": 139.12,
        "_internal_originalTime": 1689820092000
    },
    {
        "time": 1689820094000,
        "value": 139.117,
        "_internal_originalTime": 1689820094000
    },
    {
        "time": 1689820095000,
        "value": 139.122,
        "_internal_originalTime": 1689820095000
    },
    {
        "time": 1689820096000,
        "value": 139.125,
        "_internal_originalTime": 1689820096000
    },
    {
        "time": 1689820098000,
        "value": 139.124,
        "_internal_originalTime": 1689820098000
    },
    {
        "time": 1689820102000,
        "value": 139.124,
        "_internal_originalTime": 1689820102000
    },
    {
        "time": 1689820103000,
        "value": 139.123,
        "_internal_originalTime": 1689820103000
    },
    {
        "time": 1689820105000,
        "value": 139.129,
        "_internal_originalTime": 1689820105000
    },
    {
        "time": 1689820106000,
        "value": 139.13,
        "_internal_originalTime": 1689820106000
    },
    {
        "time": 1689820109000,
        "value": 139.124,
        "_internal_originalTime": 1689820109000
    },
    {
        "time": 1689820110000,
        "value": 139.127,
        "_internal_originalTime": 1689820110000
    },
    {
        "time": 1689820111000,
        "value": 139.129,
        "_internal_originalTime": 1689820111000
    },
    {
        "time": 1689820112000,
        "value": 139.131,
        "_internal_originalTime": 1689820112000
    },
    {
        "time": 1689820113000,
        "value": 139.13,
        "_internal_originalTime": 1689820113000
    },
    {
        "time": 1689820114000,
        "value": 139.127,
        "_internal_originalTime": 1689820114000
    },
    {
        "time": 1689820115000,
        "value": 139.128,
        "_internal_originalTime": 1689820115000
    },
    {
        "time": 1689820117000,
        "value": 139.129,
        "_internal_originalTime": 1689820117000
    },
    {
        "time": 1689820118000,
        "value": 139.126,
        "_internal_originalTime": 1689820118000
    },
    {
        "time": 1689820119000,
        "value": 139.127,
        "_internal_originalTime": 1689820119000
    },
    {
        "time": 1689820121000,
        "value": 139.132,
        "_internal_originalTime": 1689820121000
    },
    {
        "time": 1689820122000,
        "value": 139.131,
        "_internal_originalTime": 1689820122000
    },
    {
        "time": 1689820123000,
        "value": 139.129,
        "_internal_originalTime": 1689820123000
    },
    {
        "time": 1689820124000,
        "value": 139.131,
        "_internal_originalTime": 1689820124000
    },
    {
        "time": 1689820126000,
        "value": 139.13,
        "_internal_originalTime": 1689820126000
    },
    {
        "time": 1689820128000,
        "value": 139.124,
        "_internal_originalTime": 1689820128000
    },
    {
        "time": 1689820129000,
        "value": 139.128,
        "_internal_originalTime": 1689820129000
    },
    {
        "time": 1689820132000,
        "value": 139.128,
        "_internal_originalTime": 1689820132000
    },
    {
        "time": 1689820137000,
        "value": 139.12,
        "_internal_originalTime": 1689820137000
    },
    {
        "time": 1689820140000,
        "value": 139.116,
        "_internal_originalTime": 1689820140000
    },
    {
        "time": 1689820141000,
        "value": 139.111,
        "_internal_originalTime": 1689820141000
    },
    {
        "time": 1689820143000,
        "value": 139.111,
        "_internal_originalTime": 1689820143000
    },
    {
        "time": 1689820145000,
        "value": 139.115,
        "_internal_originalTime": 1689820145000
    },
    {
        "time": 1689820146000,
        "value": 139.117,
        "_internal_originalTime": 1689820146000
    },
    {
        "time": 1689820150000,
        "value": 139.12,
        "_internal_originalTime": 1689820150000
    },
    {
        "time": 1689820152000,
        "value": 139.126,
        "_internal_originalTime": 1689820152000
    },
    {
        "time": 1689820153000,
        "value": 139.127,
        "_internal_originalTime": 1689820153000
    },
    {
        "time": 1689820156000,
        "value": 139.127,
        "_internal_originalTime": 1689820156000
    },
    {
        "time": 1689820160000,
        "value": 139.127,
        "_internal_originalTime": 1689820160000
    },
    {
        "time": 1689820162000,
        "value": 139.128,
        "_internal_originalTime": 1689820162000
    },
    {
        "time": 1689820163000,
        "value": 139.127,
        "_internal_originalTime": 1689820163000
    },
    {
        "time": 1689820164000,
        "value": 139.128,
        "_internal_originalTime": 1689820164000
    },
    {
        "time": 1689820166000,
        "value": 139.128,
        "_internal_originalTime": 1689820166000
    },
    {
        "time": 1689820167000,
        "value": 139.128,
        "_internal_originalTime": 1689820167000
    },
    {
        "time": 1689820169000,
        "value": 139.124,
        "_internal_originalTime": 1689820169000
    },
    {
        "time": 1689820170000,
        "value": 139.129,
        "_internal_originalTime": 1689820170000
    },
    {
        "time": 1689820171000,
        "value": 139.132,
        "_internal_originalTime": 1689820171000
    },
    {
        "time": 1689820172000,
        "value": 139.129,
        "_internal_originalTime": 1689820172000
    },
    {
        "time": 1689820174000,
        "value": 139.128,
        "_internal_originalTime": 1689820174000
    },
    {
        "time": 1689820175000,
        "value": 139.131,
        "_internal_originalTime": 1689820175000
    },
    {
        "time": 1689820179000,
        "value": 139.129,
        "_internal_originalTime": 1689820179000
    },
    {
        "time": 1689820180000,
        "value": 139.128,
        "_internal_originalTime": 1689820180000
    },
    {
        "time": 1689820182000,
        "value": 139.128,
        "_internal_originalTime": 1689820182000
    },
    {
        "time": 1689820183000,
        "value": 139.129,
        "_internal_originalTime": 1689820183000
    },
    {
        "time": 1689820185000,
        "value": 139.134,
        "_internal_originalTime": 1689820185000
    },
    {
        "time": 1689820186000,
        "value": 139.131,
        "_internal_originalTime": 1689820186000
    },
    {
        "time": 1689820187000,
        "value": 139.133,
        "_internal_originalTime": 1689820187000
    },
    {
        "time": 1689820189000,
        "value": 139.138,
        "_internal_originalTime": 1689820189000
    },
    {
        "time": 1689820190000,
        "value": 139.142,
        "_internal_originalTime": 1689820190000
    },
    {
        "time": 1689820191000,
        "value": 139.148,
        "_internal_originalTime": 1689820191000
    },
    {
        "time": 1689820192000,
        "value": 139.142,
        "_internal_originalTime": 1689820192000
    },
    {
        "time": 1689820194000,
        "value": 139.148,
        "_internal_originalTime": 1689820194000
    },
    {
        "time": 1689820195000,
        "value": 139.15,
        "_internal_originalTime": 1689820195000
    },
    {
        "time": 1689820197000,
        "value": 139.149,
        "_internal_originalTime": 1689820197000
    },
    {
        "time": 1689820200000,
        "value": 139.146,
        "_internal_originalTime": 1689820200000
    },
    {
        "time": 1689820201000,
        "value": 139.149,
        "_internal_originalTime": 1689820201000
    },
    {
        "time": 1689820202000,
        "value": 139.146,
        "_internal_originalTime": 1689820202000
    },
    {
        "time": 1689820203000,
        "value": 139.144,
        "_internal_originalTime": 1689820203000
    },
    {
        "time": 1689820205000,
        "value": 139.147,
        "_internal_originalTime": 1689820205000
    },
    {
        "time": 1689820206000,
        "value": 139.146,
        "_internal_originalTime": 1689820206000
    },
    {
        "time": 1689820208000,
        "value": 139.148,
        "_internal_originalTime": 1689820208000
    },
    {
        "time": 1689820210000,
        "value": 139.149,
        "_internal_originalTime": 1689820210000
    },
    {
        "time": 1689820211000,
        "value": 139.146,
        "_internal_originalTime": 1689820211000
    },
    {
        "time": 1689820214000,
        "value": 139.155,
        "_internal_originalTime": 1689820214000
    },
    {
        "time": 1689820215000,
        "value": 139.154,
        "_internal_originalTime": 1689820215000
    },
    {
        "time": 1689820216000,
        "value": 139.15,
        "_internal_originalTime": 1689820216000
    },
    {
        "time": 1689820218000,
        "value": 139.146,
        "_internal_originalTime": 1689820218000
    },
    {
        "time": 1689820220000,
        "value": 139.142,
        "_internal_originalTime": 1689820220000
    },
    {
        "time": 1689820221000,
        "value": 139.142,
        "_internal_originalTime": 1689820221000
    },
    {
        "time": 1689820222000,
        "value": 139.142,
        "_internal_originalTime": 1689820222000
    },
    {
        "time": 1689820223000,
        "value": 139.142,
        "_internal_originalTime": 1689820223000
    },
    {
        "time": 1689820225000,
        "value": 139.142,
        "_internal_originalTime": 1689820225000
    },
    {
        "time": 1689820226000,
        "value": 139.148,
        "_internal_originalTime": 1689820226000
    },
    {
        "time": 1689820227000,
        "value": 139.144,
        "_internal_originalTime": 1689820227000
    },
    {
        "time": 1689820228000,
        "value": 139.146,
        "_internal_originalTime": 1689820228000
    },
    {
        "time": 1689820229000,
        "value": 139.142,
        "_internal_originalTime": 1689820229000
    },
    {
        "time": 1689820231000,
        "value": 139.147,
        "_internal_originalTime": 1689820231000
    },
    {
        "time": 1689820232000,
        "value": 139.149,
        "_internal_originalTime": 1689820232000
    },
    {
        "time": 1689820233000,
        "value": 139.149,
        "_internal_originalTime": 1689820233000
    },
    {
        "time": 1689820235000,
        "value": 139.149,
        "_internal_originalTime": 1689820235000
    },
    {
        "time": 1689820236000,
        "value": 139.147,
        "_internal_originalTime": 1689820236000
    },
    {
        "time": 1689820237000,
        "value": 139.149,
        "_internal_originalTime": 1689820237000
    },
    {
        "time": 1689820240000,
        "value": 139.148,
        "_internal_originalTime": 1689820240000
    },
    {
        "time": 1689820241000,
        "value": 139.154,
        "_internal_originalTime": 1689820241000
    },
    {
        "time": 1689820242000,
        "value": 139.165,
        "_internal_originalTime": 1689820242000
    },
    {
        "time": 1689820243000,
        "value": 139.167,
        "_internal_originalTime": 1689820243000
    },
    {
        "time": 1689820244000,
        "value": 139.167,
        "_internal_originalTime": 1689820244000
    },
    {
        "time": 1689820245000,
        "value": 139.166,
        "_internal_originalTime": 1689820245000
    },
    {
        "time": 1689820247000,
        "value": 139.162,
        "_internal_originalTime": 1689820247000
    },
    {
        "time": 1689820249000,
        "value": 139.164,
        "_internal_originalTime": 1689820249000
    },
    {
        "time": 1689820250000,
        "value": 139.169,
        "_internal_originalTime": 1689820250000
    },
    {
        "time": 1689820251000,
        "value": 139.166,
        "_internal_originalTime": 1689820251000
    },
    {
        "time": 1689820252000,
        "value": 139.169,
        "_internal_originalTime": 1689820252000
    },
    {
        "time": 1689820254000,
        "value": 139.164,
        "_internal_originalTime": 1689820254000
    },
    {
        "time": 1689820255000,
        "value": 139.164,
        "_internal_originalTime": 1689820255000
    },
    {
        "time": 1689820256000,
        "value": 139.165,
        "_internal_originalTime": 1689820256000
    },
    {
        "time": 1689820258000,
        "value": 139.171,
        "_internal_originalTime": 1689820258000
    },
    {
        "time": 1689820259000,
        "value": 139.162,
        "_internal_originalTime": 1689820259000
    },
    {
        "time": 1689820260000,
        "value": 139.168,
        "_internal_originalTime": 1689820260000
    },
    {
        "time": 1689820261000,
        "value": 139.17,
        "_internal_originalTime": 1689820261000
    },
    {
        "time": 1689820262000,
        "value": 139.169,
        "_internal_originalTime": 1689820262000
    },
    {
        "time": 1689820263000,
        "value": 139.171,
        "_internal_originalTime": 1689820263000
    },
    {
        "time": 1689820267000,
        "value": 139.177,
        "_internal_originalTime": 1689820267000
    },
    {
        "time": 1689820268000,
        "value": 139.174,
        "_internal_originalTime": 1689820268000
    },
    {
        "time": 1689820270000,
        "value": 139.166,
        "_internal_originalTime": 1689820270000
    },
    {
        "time": 1689820271000,
        "value": 139.17,
        "_internal_originalTime": 1689820271000
    },
    {
        "time": 1689820273000,
        "value": 139.166,
        "_internal_originalTime": 1689820273000
    },
    {
        "time": 1689820274000,
        "value": 139.166,
        "_internal_originalTime": 1689820274000
    },
    {
        "time": 1689820275000,
        "value": 139.169,
        "_internal_originalTime": 1689820275000
    },
    {
        "time": 1689820277000,
        "value": 139.17,
        "_internal_originalTime": 1689820277000
    },
    {
        "time": 1689820278000,
        "value": 139.168,
        "_internal_originalTime": 1689820278000
    },
    {
        "time": 1689820280000,
        "value": 139.171,
        "_internal_originalTime": 1689820280000
    },
    {
        "time": 1689820283000,
        "value": 139.171,
        "_internal_originalTime": 1689820283000
    },
    {
        "time": 1689820284000,
        "value": 139.179,
        "_internal_originalTime": 1689820284000
    },
    {
        "time": 1689820285000,
        "value": 139.178,
        "_internal_originalTime": 1689820285000
    },
    {
        "time": 1689820286000,
        "value": 139.185,
        "_internal_originalTime": 1689820286000
    },
    {
        "time": 1689820289000,
        "value": 139.178,
        "_internal_originalTime": 1689820289000
    },
    {
        "time": 1689820290000,
        "value": 139.186,
        "_internal_originalTime": 1689820290000
    },
    {
        "time": 1689820292000,
        "value": 139.185,
        "_internal_originalTime": 1689820292000
    },
    {
        "time": 1689820294000,
        "value": 139.182,
        "_internal_originalTime": 1689820294000
    },
    {
        "time": 1689820295000,
        "value": 139.185,
        "_internal_originalTime": 1689820295000
    },
    {
        "time": 1689820296000,
        "value": 139.18,
        "_internal_originalTime": 1689820296000
    },
    {
        "time": 1689820297000,
        "value": 139.18,
        "_internal_originalTime": 1689820297000
    },
    {
        "time": 1689820299000,
        "value": 139.176,
        "_internal_originalTime": 1689820299000
    },
    {
        "time": 1689820301000,
        "value": 139.174,
        "_internal_originalTime": 1689820301000
    },
    {
        "time": 1689820303000,
        "value": 139.176,
        "_internal_originalTime": 1689820303000
    },
    {
        "time": 1689820304000,
        "value": 139.181,
        "_internal_originalTime": 1689820304000
    },
    {
        "time": 1689820308000,
        "value": 139.18,
        "_internal_originalTime": 1689820308000
    },
    {
        "time": 1689820309000,
        "value": 139.175,
        "_internal_originalTime": 1689820309000
    },
    {
        "time": 1689820311000,
        "value": 139.181,
        "_internal_originalTime": 1689820311000
    },
    {
        "time": 1689820312000,
        "value": 139.176,
        "_internal_originalTime": 1689820312000
    },
    {
        "time": 1689820313000,
        "value": 139.176,
        "_internal_originalTime": 1689820313000
    },
    {
        "time": 1689820314000,
        "value": 139.176,
        "_internal_originalTime": 1689820314000
    },
    {
        "time": 1689820316000,
        "value": 139.176,
        "_internal_originalTime": 1689820316000
    },
    {
        "time": 1689820317000,
        "value": 139.175,
        "_internal_originalTime": 1689820317000
    },
    {
        "time": 1689820318000,
        "value": 139.181,
        "_internal_originalTime": 1689820318000
    },
    {
        "time": 1689820319000,
        "value": 139.179,
        "_internal_originalTime": 1689820319000
    },
    {
        "time": 1689820321000,
        "value": 139.177,
        "_internal_originalTime": 1689820321000
    },
    {
        "time": 1689820322000,
        "value": 139.173,
        "_internal_originalTime": 1689820322000
    },
    {
        "time": 1689820324000,
        "value": 139.166,
        "_internal_originalTime": 1689820324000
    },
    {
        "time": 1689820325000,
        "value": 139.171,
        "_internal_originalTime": 1689820325000
    },
    {
        "time": 1689820327000,
        "value": 139.168,
        "_internal_originalTime": 1689820327000
    },
    {
        "time": 1689820329000,
        "value": 139.164,
        "_internal_originalTime": 1689820329000
    },
    {
        "time": 1689820330000,
        "value": 139.177,
        "_internal_originalTime": 1689820330000
    },
    {
        "time": 1689820332000,
        "value": 139.175,
        "_internal_originalTime": 1689820332000
    },
    {
        "time": 1689820333000,
        "value": 139.176,
        "_internal_originalTime": 1689820333000
    },
    {
        "time": 1689820334000,
        "value": 139.181,
        "_internal_originalTime": 1689820334000
    },
    {
        "time": 1689820337000,
        "value": 139.177,
        "_internal_originalTime": 1689820337000
    },
    {
        "time": 1689820339000,
        "value": 139.176,
        "_internal_originalTime": 1689820339000
    },
    {
        "time": 1689820340000,
        "value": 139.174,
        "_internal_originalTime": 1689820340000
    },
    {
        "time": 1689820343000,
        "value": 139.177,
        "_internal_originalTime": 1689820343000
    },
    {
        "time": 1689820344000,
        "value": 139.176,
        "_internal_originalTime": 1689820344000
    },
    {
        "time": 1689820347000,
        "value": 139.177,
        "_internal_originalTime": 1689820347000
    },
    {
        "time": 1689820348000,
        "value": 139.176,
        "_internal_originalTime": 1689820348000
    },
    {
        "time": 1689820349000,
        "value": 139.174,
        "_internal_originalTime": 1689820349000
    },
    {
        "time": 1689820350000,
        "value": 139.174,
        "_internal_originalTime": 1689820350000
    },
    {
        "time": 1689820351000,
        "value": 139.177,
        "_internal_originalTime": 1689820351000
    },
    {
        "time": 1689820352000,
        "value": 139.181,
        "_internal_originalTime": 1689820352000
    },
    {
        "time": 1689820354000,
        "value": 139.176,
        "_internal_originalTime": 1689820354000
    },
    {
        "time": 1689820355000,
        "value": 139.181,
        "_internal_originalTime": 1689820355000
    },
    {
        "time": 1689820358000,
        "value": 139.187,
        "_internal_originalTime": 1689820358000
    },
    {
        "time": 1689820359000,
        "value": 139.183,
        "_internal_originalTime": 1689820359000
    },
    {
        "time": 1689820361000,
        "value": 139.183,
        "_internal_originalTime": 1689820361000
    },
    {
        "time": 1689820363000,
        "value": 139.187,
        "_internal_originalTime": 1689820363000
    },
    {
        "time": 1689820365000,
        "value": 139.188,
        "_internal_originalTime": 1689820365000
    },
    {
        "time": 1689820366000,
        "value": 139.182,
        "_internal_originalTime": 1689820366000
    },
    {
        "time": 1689820368000,
        "value": 139.188,
        "_internal_originalTime": 1689820368000
    },
    {
        "time": 1689820369000,
        "value": 139.18,
        "_internal_originalTime": 1689820369000
    },
    {
        "time": 1689820370000,
        "value": 139.18,
        "_internal_originalTime": 1689820370000
    },
    {
        "time": 1689820371000,
        "value": 139.173,
        "_internal_originalTime": 1689820371000
    },
    {
        "time": 1689820372000,
        "value": 139.175,
        "_internal_originalTime": 1689820372000
    },
    {
        "time": 1689820374000,
        "value": 139.179,
        "_internal_originalTime": 1689820374000
    },
    {
        "time": 1689820376000,
        "value": 139.18,
        "_internal_originalTime": 1689820376000
    },
    {
        "time": 1689820377000,
        "value": 139.179,
        "_internal_originalTime": 1689820377000
    },
    {
        "time": 1689820379000,
        "value": 139.174,
        "_internal_originalTime": 1689820379000
    },
    {
        "time": 1689820380000,
        "value": 139.17,
        "_internal_originalTime": 1689820380000
    },
    {
        "time": 1689820381000,
        "value": 139.17,
        "_internal_originalTime": 1689820381000
    },
    {
        "time": 1689820382000,
        "value": 139.177,
        "_internal_originalTime": 1689820382000
    },
    {
        "time": 1689820383000,
        "value": 139.171,
        "_internal_originalTime": 1689820383000
    },
    {
        "time": 1689820384000,
        "value": 139.176,
        "_internal_originalTime": 1689820384000
    },
    {
        "time": 1689820385000,
        "value": 139.171,
        "_internal_originalTime": 1689820385000
    },
    {
        "time": 1689820386000,
        "value": 139.171,
        "_internal_originalTime": 1689820386000
    },
    {
        "time": 1689820388000,
        "value": 139.166,
        "_internal_originalTime": 1689820388000
    },
    {
        "time": 1689820389000,
        "value": 139.169,
        "_internal_originalTime": 1689820389000
    },
    {
        "time": 1689820390000,
        "value": 139.17,
        "_internal_originalTime": 1689820390000
    },
    {
        "time": 1689820391000,
        "value": 139.171,
        "_internal_originalTime": 1689820391000
    },
    {
        "time": 1689820393000,
        "value": 139.171,
        "_internal_originalTime": 1689820393000
    },
    {
        "time": 1689820394000,
        "value": 139.164,
        "_internal_originalTime": 1689820394000
    },
    {
        "time": 1689820395000,
        "value": 139.17,
        "_internal_originalTime": 1689820395000
    },
    {
        "time": 1689820396000,
        "value": 139.169,
        "_internal_originalTime": 1689820396000
    },
    {
        "time": 1689820398000,
        "value": 139.169,
        "_internal_originalTime": 1689820398000
    },
    {
        "time": 1689820400000,
        "value": 139.165,
        "_internal_originalTime": 1689820400000
    },
    {
        "time": 1689820401000,
        "value": 139.164,
        "_internal_originalTime": 1689820401000
    },
    {
        "time": 1689820403000,
        "value": 139.165,
        "_internal_originalTime": 1689820403000
    },
    {
        "time": 1689820404000,
        "value": 139.166,
        "_internal_originalTime": 1689820404000
    },
    {
        "time": 1689820407000,
        "value": 139.166,
        "_internal_originalTime": 1689820407000
    },
    {
        "time": 1689820409000,
        "value": 139.166,
        "_internal_originalTime": 1689820409000
    },
    {
        "time": 1689820410000,
        "value": 139.166,
        "_internal_originalTime": 1689820410000
    },
    {
        "time": 1689820412000,
        "value": 139.172,
        "_internal_originalTime": 1689820412000
    },
    {
        "time": 1689820413000,
        "value": 139.175,
        "_internal_originalTime": 1689820413000
    },
    {
        "time": 1689820414000,
        "value": 139.172,
        "_internal_originalTime": 1689820414000
    },
    {
        "time": 1689820416000,
        "value": 139.181,
        "_internal_originalTime": 1689820416000
    },
    {
        "time": 1689820417000,
        "value": 139.185,
        "_internal_originalTime": 1689820417000
    },
    {
        "time": 1689820419000,
        "value": 139.181,
        "_internal_originalTime": 1689820419000
    },
    {
        "time": 1689820421000,
        "value": 139.178,
        "_internal_originalTime": 1689820421000
    },
    {
        "time": 1689820423000,
        "value": 139.173,
        "_internal_originalTime": 1689820423000
    },
    {
        "time": 1689820425000,
        "value": 139.176,
        "_internal_originalTime": 1689820425000
    },
    {
        "time": 1689820426000,
        "value": 139.172,
        "_internal_originalTime": 1689820426000
    },
    {
        "time": 1689820427000,
        "value": 139.175,
        "_internal_originalTime": 1689820427000
    },
    {
        "time": 1689820430000,
        "value": 139.175,
        "_internal_originalTime": 1689820430000
    },
    {
        "time": 1689820432000,
        "value": 139.176,
        "_internal_originalTime": 1689820432000
    },
    {
        "time": 1689820433000,
        "value": 139.175,
        "_internal_originalTime": 1689820433000
    },
    {
        "time": 1689820435000,
        "value": 139.171,
        "_internal_originalTime": 1689820435000
    },
    {
        "time": 1689820437000,
        "value": 139.166,
        "_internal_originalTime": 1689820437000
    },
    {
        "time": 1689820438000,
        "value": 139.166,
        "_internal_originalTime": 1689820438000
    },
    {
        "time": 1689820439000,
        "value": 139.166,
        "_internal_originalTime": 1689820439000
    },
    {
        "time": 1689820440000,
        "value": 139.164,
        "_internal_originalTime": 1689820440000
    },
    {
        "time": 1689820441000,
        "value": 139.168,
        "_internal_originalTime": 1689820441000
    },
    {
        "time": 1689820443000,
        "value": 139.166,
        "_internal_originalTime": 1689820443000
    },
    {
        "time": 1689820444000,
        "value": 139.166,
        "_internal_originalTime": 1689820444000
    },
    {
        "time": 1689820445000,
        "value": 139.169,
        "_internal_originalTime": 1689820445000
    },
    {
        "time": 1689820447000,
        "value": 139.168,
        "_internal_originalTime": 1689820447000
    },
    {
        "time": 1689820450000,
        "value": 139.169,
        "_internal_originalTime": 1689820450000
    },
    {
        "time": 1689820451000,
        "value": 139.169,
        "_internal_originalTime": 1689820451000
    },
    {
        "time": 1689820454000,
        "value": 139.169,
        "_internal_originalTime": 1689820454000
    },
    {
        "time": 1689820455000,
        "value": 139.164,
        "_internal_originalTime": 1689820455000
    },
    {
        "time": 1689820457000,
        "value": 139.169,
        "_internal_originalTime": 1689820457000
    },
    {
        "time": 1689820458000,
        "value": 139.169,
        "_internal_originalTime": 1689820458000
    },
    {
        "time": 1689820459000,
        "value": 139.169,
        "_internal_originalTime": 1689820459000
    },
    {
        "time": 1689820461000,
        "value": 139.169,
        "_internal_originalTime": 1689820461000
    },
    {
        "time": 1689820462000,
        "value": 139.169,
        "_internal_originalTime": 1689820462000
    },
    {
        "time": 1689820463000,
        "value": 139.169,
        "_internal_originalTime": 1689820463000
    },
    {
        "time": 1689820464000,
        "value": 139.164,
        "_internal_originalTime": 1689820464000
    },
    {
        "time": 1689820469000,
        "value": 139.164,
        "_internal_originalTime": 1689820469000
    },
    {
        "time": 1689820470000,
        "value": 139.166,
        "_internal_originalTime": 1689820470000
    },
    {
        "time": 1689820471000,
        "value": 139.166,
        "_internal_originalTime": 1689820471000
    },
    {
        "time": 1689820473000,
        "value": 139.169,
        "_internal_originalTime": 1689820473000
    },
    {
        "time": 1689820474000,
        "value": 139.169,
        "_internal_originalTime": 1689820474000
    },
    {
        "time": 1689820475000,
        "value": 139.168,
        "_internal_originalTime": 1689820475000
    },
    {
        "time": 1689820476000,
        "value": 139.168,
        "_internal_originalTime": 1689820476000
    },
    {
        "time": 1689820479000,
        "value": 139.165,
        "_internal_originalTime": 1689820479000
    },
    {
        "time": 1689820480000,
        "value": 139.169,
        "_internal_originalTime": 1689820480000
    },
    {
        "time": 1689820481000,
        "value": 139.169,
        "_internal_originalTime": 1689820481000
    },
    {
        "time": 1689820485000,
        "value": 139.166,
        "_internal_originalTime": 1689820485000
    },
    {
        "time": 1689820486000,
        "value": 139.162,
        "_internal_originalTime": 1689820486000
    },
    {
        "time": 1689820488000,
        "value": 139.158,
        "_internal_originalTime": 1689820488000
    },
    {
        "time": 1689820490000,
        "value": 139.156,
        "_internal_originalTime": 1689820490000
    },
    {
        "time": 1689820491000,
        "value": 139.158,
        "_internal_originalTime": 1689820491000
    },
    {
        "time": 1689820493000,
        "value": 139.161,
        "_internal_originalTime": 1689820493000
    },
    {
        "time": 1689820494000,
        "value": 139.158,
        "_internal_originalTime": 1689820494000
    },
    {
        "time": 1689820496000,
        "value": 139.162,
        "_internal_originalTime": 1689820496000
    },
    {
        "time": 1689820497000,
        "value": 139.168,
        "_internal_originalTime": 1689820497000
    },
    {
        "time": 1689820498000,
        "value": 139.167,
        "_internal_originalTime": 1689820498000
    },
    {
        "time": 1689820499000,
        "value": 139.159,
        "_internal_originalTime": 1689820499000
    },
    {
        "time": 1689820501000,
        "value": 139.16,
        "_internal_originalTime": 1689820501000
    },
    {
        "time": 1689820503000,
        "value": 139.166,
        "_internal_originalTime": 1689820503000
    },
    {
        "time": 1689820507000,
        "value": 139.167,
        "_internal_originalTime": 1689820507000
    },
    {
        "time": 1689820508000,
        "value": 139.163,
        "_internal_originalTime": 1689820508000
    },
    {
        "time": 1689820511000,
        "value": 139.162,
        "_internal_originalTime": 1689820511000
    },
    {
        "time": 1689820512000,
        "value": 139.163,
        "_internal_originalTime": 1689820512000
    },
    {
        "time": 1689820513000,
        "value": 139.162,
        "_internal_originalTime": 1689820513000
    },
    {
        "time": 1689820515000,
        "value": 139.162,
        "_internal_originalTime": 1689820515000
    },
    {
        "time": 1689820516000,
        "value": 139.16,
        "_internal_originalTime": 1689820516000
    },
    {
        "time": 1689820519000,
        "value": 139.161,
        "_internal_originalTime": 1689820519000
    },
    {
        "time": 1689820520000,
        "value": 139.161,
        "_internal_originalTime": 1689820520000
    },
    {
        "time": 1689820522000,
        "value": 139.166,
        "_internal_originalTime": 1689820522000
    },
    {
        "time": 1689820523000,
        "value": 139.167,
        "_internal_originalTime": 1689820523000
    },
    {
        "time": 1689820524000,
        "value": 139.166,
        "_internal_originalTime": 1689820524000
    },
    {
        "time": 1689820526000,
        "value": 139.167,
        "_internal_originalTime": 1689820526000
    },
    {
        "time": 1689820527000,
        "value": 139.167,
        "_internal_originalTime": 1689820527000
    },
    {
        "time": 1689820529000,
        "value": 139.161,
        "_internal_originalTime": 1689820529000
    },
    {
        "time": 1689820531000,
        "value": 139.163,
        "_internal_originalTime": 1689820531000
    },
    {
        "time": 1689820533000,
        "value": 139.163,
        "_internal_originalTime": 1689820533000
    },
    {
        "time": 1689820534000,
        "value": 139.167,
        "_internal_originalTime": 1689820534000
    },
    {
        "time": 1689820536000,
        "value": 139.159,
        "_internal_originalTime": 1689820536000
    },
    {
        "time": 1689820537000,
        "value": 139.159,
        "_internal_originalTime": 1689820537000
    },
    {
        "time": 1689820538000,
        "value": 139.159,
        "_internal_originalTime": 1689820538000
    },
    {
        "time": 1689820540000,
        "value": 139.158,
        "_internal_originalTime": 1689820540000
    },
    {
        "time": 1689820543000,
        "value": 139.158,
        "_internal_originalTime": 1689820543000
    },
    {
        "time": 1689820544000,
        "value": 139.155,
        "_internal_originalTime": 1689820544000
    },
    {
        "time": 1689820546000,
        "value": 139.155,
        "_internal_originalTime": 1689820546000
    },
    {
        "time": 1689820549000,
        "value": 139.152,
        "_internal_originalTime": 1689820549000
    },
    {
        "time": 1689820550000,
        "value": 139.156,
        "_internal_originalTime": 1689820550000
    },
    {
        "time": 1689820554000,
        "value": 139.157,
        "_internal_originalTime": 1689820554000
    },
    {
        "time": 1689820555000,
        "value": 139.154,
        "_internal_originalTime": 1689820555000
    },
    {
        "time": 1689820557000,
        "value": 139.154,
        "_internal_originalTime": 1689820557000
    },
    {
        "time": 1689820558000,
        "value": 139.16,
        "_internal_originalTime": 1689820558000
    },
    {
        "time": 1689820560000,
        "value": 139.155,
        "_internal_originalTime": 1689820560000
    },
    {
        "time": 1689820561000,
        "value": 139.159,
        "_internal_originalTime": 1689820561000
    },
    {
        "time": 1689820563000,
        "value": 139.159,
        "_internal_originalTime": 1689820563000
    },
    {
        "time": 1689820565000,
        "value": 139.155,
        "_internal_originalTime": 1689820565000
    },
    {
        "time": 1689820566000,
        "value": 139.16,
        "_internal_originalTime": 1689820566000
    },
    {
        "time": 1689820567000,
        "value": 139.159,
        "_internal_originalTime": 1689820567000
    },
    {
        "time": 1689820569000,
        "value": 139.158,
        "_internal_originalTime": 1689820569000
    },
    {
        "time": 1689820571000,
        "value": 139.154,
        "_internal_originalTime": 1689820571000
    },
    {
        "time": 1689820573000,
        "value": 139.157,
        "_internal_originalTime": 1689820573000
    },
    {
        "time": 1689820574000,
        "value": 139.152,
        "_internal_originalTime": 1689820574000
    },
    {
        "time": 1689820576000,
        "value": 139.154,
        "_internal_originalTime": 1689820576000
    },
    {
        "time": 1689820577000,
        "value": 139.158,
        "_internal_originalTime": 1689820577000
    },
    {
        "time": 1689820578000,
        "value": 139.154,
        "_internal_originalTime": 1689820578000
    },
    {
        "time": 1689820580000,
        "value": 139.152,
        "_internal_originalTime": 1689820580000
    },
    {
        "time": 1689820581000,
        "value": 139.165,
        "_internal_originalTime": 1689820581000
    },
    {
        "time": 1689820582000,
        "value": 139.166,
        "_internal_originalTime": 1689820582000
    },
    {
        "time": 1689820584000,
        "value": 139.166,
        "_internal_originalTime": 1689820584000
    },
    {
        "time": 1689820585000,
        "value": 139.166,
        "_internal_originalTime": 1689820585000
    },
    {
        "time": 1689820586000,
        "value": 139.158,
        "_internal_originalTime": 1689820586000
    },
    {
        "time": 1689820588000,
        "value": 139.155,
        "_internal_originalTime": 1689820588000
    },
    {
        "time": 1689820589000,
        "value": 139.153,
        "_internal_originalTime": 1689820589000
    },
    {
        "time": 1689820590000,
        "value": 139.158,
        "_internal_originalTime": 1689820590000
    },
    {
        "time": 1689820593000,
        "value": 139.159,
        "_internal_originalTime": 1689820593000
    },
    {
        "time": 1689820594000,
        "value": 139.155,
        "_internal_originalTime": 1689820594000
    },
    {
        "time": 1689820595000,
        "value": 139.16,
        "_internal_originalTime": 1689820595000
    },
    {
        "time": 1689820596000,
        "value": 139.166,
        "_internal_originalTime": 1689820596000
    },
    {
        "time": 1689820597000,
        "value": 139.166,
        "_internal_originalTime": 1689820597000
    },
    {
        "time": 1689820599000,
        "value": 139.168,
        "_internal_originalTime": 1689820599000
    },
    {
        "time": 1689820602000,
        "value": 139.164,
        "_internal_originalTime": 1689820602000
    },
    {
        "time": 1689820603000,
        "value": 139.17,
        "_internal_originalTime": 1689820603000
    },
    {
        "time": 1689820605000,
        "value": 139.168,
        "_internal_originalTime": 1689820605000
    },
    {
        "time": 1689820608000,
        "value": 139.167,
        "_internal_originalTime": 1689820608000
    },
    {
        "time": 1689820609000,
        "value": 139.167,
        "_internal_originalTime": 1689820609000
    },
    {
        "time": 1689820610000,
        "value": 139.168,
        "_internal_originalTime": 1689820610000
    },
    {
        "time": 1689820613000,
        "value": 139.167,
        "_internal_originalTime": 1689820613000
    },
    {
        "time": 1689820619000,
        "value": 139.167,
        "_internal_originalTime": 1689820619000
    },
    {
        "time": 1689820620000,
        "value": 139.165,
        "_internal_originalTime": 1689820620000
    },
    {
        "time": 1689820621000,
        "value": 139.167,
        "_internal_originalTime": 1689820621000
    },
    {
        "time": 1689820622000,
        "value": 139.167,
        "_internal_originalTime": 1689820622000
    },
    {
        "time": 1689820623000,
        "value": 139.168,
        "_internal_originalTime": 1689820623000
    },
    {
        "time": 1689820624000,
        "value": 139.167,
        "_internal_originalTime": 1689820624000
    },
    {
        "time": 1689820625000,
        "value": 139.164,
        "_internal_originalTime": 1689820625000
    },
    {
        "time": 1689820626000,
        "value": 139.169,
        "_internal_originalTime": 1689820626000
    },
    {
        "time": 1689820627000,
        "value": 139.174,
        "_internal_originalTime": 1689820627000
    },
    {
        "time": 1689820629000,
        "value": 139.18,
        "_internal_originalTime": 1689820629000
    },
    {
        "time": 1689820630000,
        "value": 139.183,
        "_internal_originalTime": 1689820630000
    },
    {
        "time": 1689820631000,
        "value": 139.187,
        "_internal_originalTime": 1689820631000
    },
    {
        "time": 1689820632000,
        "value": 139.185,
        "_internal_originalTime": 1689820632000
    },
    {
        "time": 1689820633000,
        "value": 139.184,
        "_internal_originalTime": 1689820633000
    },
    {
        "time": 1689820634000,
        "value": 139.189,
        "_internal_originalTime": 1689820634000
    },
    {
        "time": 1689820637000,
        "value": 139.194,
        "_internal_originalTime": 1689820637000
    },
    {
        "time": 1689820638000,
        "value": 139.19,
        "_internal_originalTime": 1689820638000
    },
    {
        "time": 1689820639000,
        "value": 139.19,
        "_internal_originalTime": 1689820639000
    },
    {
        "time": 1689820641000,
        "value": 139.191,
        "_internal_originalTime": 1689820641000
    },
    {
        "time": 1689820642000,
        "value": 139.19,
        "_internal_originalTime": 1689820642000
    },
    {
        "time": 1689820644000,
        "value": 139.185,
        "_internal_originalTime": 1689820644000
    },
    {
        "time": 1689820645000,
        "value": 139.184,
        "_internal_originalTime": 1689820645000
    },
    {
        "time": 1689820646000,
        "value": 139.185,
        "_internal_originalTime": 1689820646000
    },
    {
        "time": 1689820648000,
        "value": 139.189,
        "_internal_originalTime": 1689820648000
    },
    {
        "time": 1689820649000,
        "value": 139.182,
        "_internal_originalTime": 1689820649000
    },
    {
        "time": 1689820650000,
        "value": 139.19,
        "_internal_originalTime": 1689820650000
    },
    {
        "time": 1689820651000,
        "value": 139.192,
        "_internal_originalTime": 1689820651000
    },
    {
        "time": 1689820652000,
        "value": 139.189,
        "_internal_originalTime": 1689820652000
    },
    {
        "time": 1689820656000,
        "value": 139.191,
        "_internal_originalTime": 1689820656000
    },
    {
        "time": 1689820659000,
        "value": 139.189,
        "_internal_originalTime": 1689820659000
    },
    {
        "time": 1689820664000,
        "value": 139.19,
        "_internal_originalTime": 1689820664000
    },
    {
        "time": 1689820665000,
        "value": 139.195,
        "_internal_originalTime": 1689820665000
    },
    {
        "time": 1689820666000,
        "value": 139.191,
        "_internal_originalTime": 1689820666000
    },
    {
        "time": 1689820667000,
        "value": 139.191,
        "_internal_originalTime": 1689820667000
    },
    {
        "time": 1689820668000,
        "value": 139.192,
        "_internal_originalTime": 1689820668000
    },
    {
        "time": 1689820669000,
        "value": 139.191,
        "_internal_originalTime": 1689820669000
    },
    {
        "time": 1689820671000,
        "value": 139.191,
        "_internal_originalTime": 1689820671000
    },
    {
        "time": 1689820673000,
        "value": 139.192,
        "_internal_originalTime": 1689820673000
    },
    {
        "time": 1689820674000,
        "value": 139.195,
        "_internal_originalTime": 1689820674000
    },
    {
        "time": 1689820679000,
        "value": 139.188,
        "_internal_originalTime": 1689820679000
    },
    {
        "time": 1689820681000,
        "value": 139.191,
        "_internal_originalTime": 1689820681000
    },
    {
        "time": 1689820682000,
        "value": 139.184,
        "_internal_originalTime": 1689820682000
    },
    {
        "time": 1689820683000,
        "value": 139.182,
        "_internal_originalTime": 1689820683000
    },
    {
        "time": 1689820684000,
        "value": 139.185,
        "_internal_originalTime": 1689820684000
    },
    {
        "time": 1689820685000,
        "value": 139.183,
        "_internal_originalTime": 1689820685000
    },
    {
        "time": 1689820687000,
        "value": 139.185,
        "_internal_originalTime": 1689820687000
    },
    {
        "time": 1689820689000,
        "value": 139.186,
        "_internal_originalTime": 1689820689000
    },
    {
        "time": 1689820690000,
        "value": 139.181,
        "_internal_originalTime": 1689820690000
    },
    {
        "time": 1689820692000,
        "value": 139.189,
        "_internal_originalTime": 1689820692000
    },
    {
        "time": 1689820694000,
        "value": 139.189,
        "_internal_originalTime": 1689820694000
    },
    {
        "time": 1689820696000,
        "value": 139.19,
        "_internal_originalTime": 1689820696000
    },
    {
        "time": 1689820699000,
        "value": 139.196,
        "_internal_originalTime": 1689820699000
    },
    {
        "time": 1689820700000,
        "value": 139.195,
        "_internal_originalTime": 1689820700000
    },
    {
        "time": 1689820701000,
        "value": 139.196,
        "_internal_originalTime": 1689820701000
    },
    {
        "time": 1689820704000,
        "value": 139.197,
        "_internal_originalTime": 1689820704000
    },
    {
        "time": 1689820705000,
        "value": 139.203,
        "_internal_originalTime": 1689820705000
    },
    {
        "time": 1689820706000,
        "value": 139.199,
        "_internal_originalTime": 1689820706000
    },
    {
        "time": 1689820707000,
        "value": 139.202,
        "_internal_originalTime": 1689820707000
    },
    {
        "time": 1689820708000,
        "value": 139.206,
        "_internal_originalTime": 1689820708000
    },
    {
        "time": 1689820710000,
        "value": 139.202,
        "_internal_originalTime": 1689820710000
    },
    {
        "time": 1689820711000,
        "value": 139.202,
        "_internal_originalTime": 1689820711000
    },
    {
        "time": 1689820712000,
        "value": 139.202,
        "_internal_originalTime": 1689820712000
    },
    {
        "time": 1689820713000,
        "value": 139.205,
        "_internal_originalTime": 1689820713000
    },
    {
        "time": 1689820714000,
        "value": 139.205,
        "_internal_originalTime": 1689820714000
    },
    {
        "time": 1689820715000,
        "value": 139.205,
        "_internal_originalTime": 1689820715000
    },
    {
        "time": 1689820717000,
        "value": 139.206,
        "_internal_originalTime": 1689820717000
    },
    {
        "time": 1689820719000,
        "value": 139.204,
        "_internal_originalTime": 1689820719000
    },
    {
        "time": 1689820720000,
        "value": 139.209,
        "_internal_originalTime": 1689820720000
    },
    {
        "time": 1689820721000,
        "value": 139.204,
        "_internal_originalTime": 1689820721000
    },
    {
        "time": 1689820722000,
        "value": 139.205,
        "_internal_originalTime": 1689820722000
    },
    {
        "time": 1689820723000,
        "value": 139.204,
        "_internal_originalTime": 1689820723000
    },
    {
        "time": 1689820725000,
        "value": 139.205,
        "_internal_originalTime": 1689820725000
    },
    {
        "time": 1689820726000,
        "value": 139.207,
        "_internal_originalTime": 1689820726000
    },
    {
        "time": 1689820727000,
        "value": 139.205,
        "_internal_originalTime": 1689820727000
    },
    {
        "time": 1689820729000,
        "value": 139.206,
        "_internal_originalTime": 1689820729000
    },
    {
        "time": 1689820730000,
        "value": 139.202,
        "_internal_originalTime": 1689820730000
    },
    {
        "time": 1689820732000,
        "value": 139.202,
        "_internal_originalTime": 1689820732000
    },
    {
        "time": 1689820734000,
        "value": 139.207,
        "_internal_originalTime": 1689820734000
    },
    {
        "time": 1689820735000,
        "value": 139.202,
        "_internal_originalTime": 1689820735000
    },
    {
        "time": 1689820736000,
        "value": 139.208,
        "_internal_originalTime": 1689820736000
    },
    {
        "time": 1689820737000,
        "value": 139.209,
        "_internal_originalTime": 1689820737000
    },
    {
        "time": 1689820739000,
        "value": 139.203,
        "_internal_originalTime": 1689820739000
    },
    {
        "time": 1689820741000,
        "value": 139.211,
        "_internal_originalTime": 1689820741000
    },
    {
        "time": 1689820742000,
        "value": 139.204,
        "_internal_originalTime": 1689820742000
    },
    {
        "time": 1689820743000,
        "value": 139.208,
        "_internal_originalTime": 1689820743000
    },
    {
        "time": 1689820744000,
        "value": 139.205,
        "_internal_originalTime": 1689820744000
    },
    {
        "time": 1689820746000,
        "value": 139.206,
        "_internal_originalTime": 1689820746000
    },
    {
        "time": 1689820749000,
        "value": 139.204,
        "_internal_originalTime": 1689820749000
    },
    {
        "time": 1689820750000,
        "value": 139.207,
        "_internal_originalTime": 1689820750000
    },
    {
        "time": 1689820753000,
        "value": 139.202,
        "_internal_originalTime": 1689820753000
    },
    {
        "time": 1689820754000,
        "value": 139.206,
        "_internal_originalTime": 1689820754000
    },
    {
        "time": 1689820755000,
        "value": 139.207,
        "_internal_originalTime": 1689820755000
    },
    {
        "time": 1689820756000,
        "value": 139.206,
        "_internal_originalTime": 1689820756000
    },
    {
        "time": 1689820757000,
        "value": 139.206,
        "_internal_originalTime": 1689820757000
    },
    {
        "time": 1689820758000,
        "value": 139.205,
        "_internal_originalTime": 1689820758000
    },
    {
        "time": 1689820759000,
        "value": 139.201,
        "_internal_originalTime": 1689820759000
    },
    {
        "time": 1689820760000,
        "value": 139.201,
        "_internal_originalTime": 1689820760000
    },
    {
        "time": 1689820761000,
        "value": 139.202,
        "_internal_originalTime": 1689820761000
    },
    {
        "time": 1689820763000,
        "value": 139.201,
        "_internal_originalTime": 1689820763000
    },
    {
        "time": 1689820764000,
        "value": 139.206,
        "_internal_originalTime": 1689820764000
    },
    {
        "time": 1689820767000,
        "value": 139.201,
        "_internal_originalTime": 1689820767000
    },
    {
        "time": 1689820768000,
        "value": 139.202,
        "_internal_originalTime": 1689820768000
    },
    {
        "time": 1689820769000,
        "value": 139.201,
        "_internal_originalTime": 1689820769000
    },
    {
        "time": 1689820772000,
        "value": 139.208,
        "_internal_originalTime": 1689820772000
    },
    {
        "time": 1689820773000,
        "value": 139.208,
        "_internal_originalTime": 1689820773000
    },
    {
        "time": 1689820774000,
        "value": 139.214,
        "_internal_originalTime": 1689820774000
    },
    {
        "time": 1689820775000,
        "value": 139.21,
        "_internal_originalTime": 1689820775000
    },
    {
        "time": 1689820777000,
        "value": 139.214,
        "_internal_originalTime": 1689820777000
    },
    {
        "time": 1689820779000,
        "value": 139.213,
        "_internal_originalTime": 1689820779000
    },
    {
        "time": 1689820780000,
        "value": 139.21,
        "_internal_originalTime": 1689820780000
    },
    {
        "time": 1689820784000,
        "value": 139.21,
        "_internal_originalTime": 1689820784000
    },
    {
        "time": 1689820786000,
        "value": 139.214,
        "_internal_originalTime": 1689820786000
    },
    {
        "time": 1689820788000,
        "value": 139.214,
        "_internal_originalTime": 1689820788000
    },
    {
        "time": 1689820789000,
        "value": 139.21,
        "_internal_originalTime": 1689820789000
    },
    {
        "time": 1689820791000,
        "value": 139.214,
        "_internal_originalTime": 1689820791000
    },
    {
        "time": 1689820792000,
        "value": 139.21,
        "_internal_originalTime": 1689820792000
    },
    {
        "time": 1689820793000,
        "value": 139.21,
        "_internal_originalTime": 1689820793000
    },
    {
        "time": 1689820795000,
        "value": 139.21,
        "_internal_originalTime": 1689820795000
    },
    {
        "time": 1689820796000,
        "value": 139.21,
        "_internal_originalTime": 1689820796000
    },
    {
        "time": 1689820797000,
        "value": 139.209,
        "_internal_originalTime": 1689820797000
    },
    {
        "time": 1689820798000,
        "value": 139.214,
        "_internal_originalTime": 1689820798000
    },
    {
        "time": 1689820799000,
        "value": 139.208,
        "_internal_originalTime": 1689820799000
    },
    {
        "time": 1689820801000,
        "value": 139.213,
        "_internal_originalTime": 1689820801000
    },
    {
        "time": 1689820803000,
        "value": 139.213,
        "_internal_originalTime": 1689820803000
    },
    {
        "time": 1689820804000,
        "value": 139.209,
        "_internal_originalTime": 1689820804000
    },
    {
        "time": 1689820805000,
        "value": 139.208,
        "_internal_originalTime": 1689820805000
    },
    {
        "time": 1689820806000,
        "value": 139.213,
        "_internal_originalTime": 1689820806000
    },
    {
        "time": 1689820809000,
        "value": 139.211,
        "_internal_originalTime": 1689820809000
    },
    {
        "time": 1689820813000,
        "value": 139.205,
        "_internal_originalTime": 1689820813000
    },
    {
        "time": 1689820814000,
        "value": 139.201,
        "_internal_originalTime": 1689820814000
    },
    {
        "time": 1689820815000,
        "value": 139.202,
        "_internal_originalTime": 1689820815000
    },
    {
        "time": 1689820817000,
        "value": 139.204,
        "_internal_originalTime": 1689820817000
    },
    {
        "time": 1689820818000,
        "value": 139.205,
        "_internal_originalTime": 1689820818000
    },
    {
        "time": 1689820820000,
        "value": 139.204,
        "_internal_originalTime": 1689820820000
    },
    {
        "time": 1689820821000,
        "value": 139.204,
        "_internal_originalTime": 1689820821000
    },
    {
        "time": 1689820823000,
        "value": 139.21,
        "_internal_originalTime": 1689820823000
    },
    {
        "time": 1689820824000,
        "value": 139.207,
        "_internal_originalTime": 1689820824000
    },
    {
        "time": 1689820826000,
        "value": 139.207,
        "_internal_originalTime": 1689820826000
    },
    {
        "time": 1689820827000,
        "value": 139.218,
        "_internal_originalTime": 1689820827000
    },
    {
        "time": 1689820828000,
        "value": 139.218,
        "_internal_originalTime": 1689820828000
    },
    {
        "time": 1689820829000,
        "value": 139.214,
        "_internal_originalTime": 1689820829000
    },
    {
        "time": 1689820831000,
        "value": 139.216,
        "_internal_originalTime": 1689820831000
    },
    {
        "time": 1689820833000,
        "value": 139.218,
        "_internal_originalTime": 1689820833000
    },
    {
        "time": 1689820834000,
        "value": 139.214,
        "_internal_originalTime": 1689820834000
    },
    {
        "time": 1689820835000,
        "value": 139.214,
        "_internal_originalTime": 1689820835000
    },
    {
        "time": 1689820836000,
        "value": 139.22,
        "_internal_originalTime": 1689820836000
    },
    {
        "time": 1689820837000,
        "value": 139.219,
        "_internal_originalTime": 1689820837000
    },
    {
        "time": 1689820839000,
        "value": 139.216,
        "_internal_originalTime": 1689820839000
    },
    {
        "time": 1689820841000,
        "value": 139.214,
        "_internal_originalTime": 1689820841000
    },
    {
        "time": 1689820843000,
        "value": 139.204,
        "_internal_originalTime": 1689820843000
    },
    {
        "time": 1689820850000,
        "value": 139.207,
        "_internal_originalTime": 1689820850000
    },
    {
        "time": 1689820852000,
        "value": 139.201,
        "_internal_originalTime": 1689820852000
    },
    {
        "time": 1689820853000,
        "value": 139.2,
        "_internal_originalTime": 1689820853000
    },
    {
        "time": 1689820854000,
        "value": 139.204,
        "_internal_originalTime": 1689820854000
    },
    {
        "time": 1689820855000,
        "value": 139.2,
        "_internal_originalTime": 1689820855000
    },
    {
        "time": 1689820857000,
        "value": 139.2,
        "_internal_originalTime": 1689820857000
    },
    {
        "time": 1689820858000,
        "value": 139.2,
        "_internal_originalTime": 1689820858000
    },
    {
        "time": 1689820859000,
        "value": 139.2,
        "_internal_originalTime": 1689820859000
    },
    {
        "time": 1689820860000,
        "value": 139.2,
        "_internal_originalTime": 1689820860000
    },
    {
        "time": 1689820861000,
        "value": 139.201,
        "_internal_originalTime": 1689820861000
    },
    {
        "time": 1689820866000,
        "value": 139.201,
        "_internal_originalTime": 1689820866000
    },
    {
        "time": 1689820867000,
        "value": 139.2,
        "_internal_originalTime": 1689820867000
    },
    {
        "time": 1689820869000,
        "value": 139.203,
        "_internal_originalTime": 1689820869000
    },
    {
        "time": 1689820870000,
        "value": 139.201,
        "_internal_originalTime": 1689820870000
    },
    {
        "time": 1689820871000,
        "value": 139.201,
        "_internal_originalTime": 1689820871000
    },
    {
        "time": 1689820873000,
        "value": 139.204,
        "_internal_originalTime": 1689820873000
    },
    {
        "time": 1689820875000,
        "value": 139.209,
        "_internal_originalTime": 1689820875000
    },
    {
        "time": 1689820876000,
        "value": 139.202,
        "_internal_originalTime": 1689820876000
    },
    {
        "time": 1689820878000,
        "value": 139.201,
        "_internal_originalTime": 1689820878000
    },
    {
        "time": 1689820879000,
        "value": 139.202,
        "_internal_originalTime": 1689820879000
    },
    {
        "time": 1689820880000,
        "value": 139.2,
        "_internal_originalTime": 1689820880000
    },
    {
        "time": 1689820882000,
        "value": 139.202,
        "_internal_originalTime": 1689820882000
    },
    {
        "time": 1689820883000,
        "value": 139.202,
        "_internal_originalTime": 1689820883000
    },
    {
        "time": 1689820886000,
        "value": 139.202,
        "_internal_originalTime": 1689820886000
    },
    {
        "time": 1689820887000,
        "value": 139.204,
        "_internal_originalTime": 1689820887000
    },
    {
        "time": 1689820888000,
        "value": 139.209,
        "_internal_originalTime": 1689820888000
    },
    {
        "time": 1689820890000,
        "value": 139.218,
        "_internal_originalTime": 1689820890000
    },
    {
        "time": 1689820891000,
        "value": 139.218,
        "_internal_originalTime": 1689820891000
    },
    {
        "time": 1689820894000,
        "value": 139.224,
        "_internal_originalTime": 1689820894000
    },
    {
        "time": 1689820895000,
        "value": 139.222,
        "_internal_originalTime": 1689820895000
    },
    {
        "time": 1689820897000,
        "value": 139.22,
        "_internal_originalTime": 1689820897000
    },
    {
        "time": 1689820898000,
        "value": 139.22,
        "_internal_originalTime": 1689820898000
    },
    {
        "time": 1689820899000,
        "value": 139.22,
        "_internal_originalTime": 1689820899000
    },
    {
        "time": 1689820901000,
        "value": 139.22,
        "_internal_originalTime": 1689820901000
    },
    {
        "time": 1689820903000,
        "value": 139.224,
        "_internal_originalTime": 1689820903000
    },
    {
        "time": 1689820905000,
        "value": 139.22,
        "_internal_originalTime": 1689820905000
    },
    {
        "time": 1689820906000,
        "value": 139.22,
        "_internal_originalTime": 1689820906000
    },
    {
        "time": 1689820909000,
        "value": 139.225,
        "_internal_originalTime": 1689820909000
    },
    {
        "time": 1689820911000,
        "value": 139.223,
        "_internal_originalTime": 1689820911000
    },
    {
        "time": 1689820913000,
        "value": 139.222,
        "_internal_originalTime": 1689820913000
    },
    {
        "time": 1689820914000,
        "value": 139.223,
        "_internal_originalTime": 1689820914000
    },
    {
        "time": 1689820916000,
        "value": 139.22,
        "_internal_originalTime": 1689820916000
    },
    {
        "time": 1689820917000,
        "value": 139.224,
        "_internal_originalTime": 1689820917000
    },
    {
        "time": 1689820919000,
        "value": 139.219,
        "_internal_originalTime": 1689820919000
    },
    {
        "time": 1689820920000,
        "value": 139.224,
        "_internal_originalTime": 1689820920000
    },
    {
        "time": 1689820921000,
        "value": 139.226,
        "_internal_originalTime": 1689820921000
    },
    {
        "time": 1689820922000,
        "value": 139.225,
        "_internal_originalTime": 1689820922000
    },
    {
        "time": 1689820925000,
        "value": 139.222,
        "_internal_originalTime": 1689820925000
    },
    {
        "time": 1689820926000,
        "value": 139.225,
        "_internal_originalTime": 1689820926000
    },
    {
        "time": 1689820927000,
        "value": 139.225,
        "_internal_originalTime": 1689820927000
    },
    {
        "time": 1689820928000,
        "value": 139.223,
        "_internal_originalTime": 1689820928000
    },
    {
        "time": 1689820930000,
        "value": 139.222,
        "_internal_originalTime": 1689820930000
    },
    {
        "time": 1689820931000,
        "value": 139.223,
        "_internal_originalTime": 1689820931000
    },
    {
        "time": 1689820934000,
        "value": 139.223,
        "_internal_originalTime": 1689820934000
    },
    {
        "time": 1689820936000,
        "value": 139.225,
        "_internal_originalTime": 1689820936000
    },
    {
        "time": 1689820940000,
        "value": 139.226,
        "_internal_originalTime": 1689820940000
    },
    {
        "time": 1689820942000,
        "value": 139.224,
        "_internal_originalTime": 1689820942000
    },
    {
        "time": 1689820943000,
        "value": 139.218,
        "_internal_originalTime": 1689820943000
    },
    {
        "time": 1689820944000,
        "value": 139.223,
        "_internal_originalTime": 1689820944000
    },
    {
        "time": 1689820945000,
        "value": 139.218,
        "_internal_originalTime": 1689820945000
    },
    {
        "time": 1689820947000,
        "value": 139.219,
        "_internal_originalTime": 1689820947000
    },
    {
        "time": 1689820948000,
        "value": 139.218,
        "_internal_originalTime": 1689820948000
    },
    {
        "time": 1689820949000,
        "value": 139.219,
        "_internal_originalTime": 1689820949000
    },
    {
        "time": 1689820950000,
        "value": 139.225,
        "_internal_originalTime": 1689820950000
    },
    {
        "time": 1689820951000,
        "value": 139.221,
        "_internal_originalTime": 1689820951000
    },
    {
        "time": 1689820952000,
        "value": 139.22,
        "_internal_originalTime": 1689820952000
    },
    {
        "time": 1689820954000,
        "value": 139.226,
        "_internal_originalTime": 1689820954000
    },
    {
        "time": 1689820956000,
        "value": 139.227,
        "_internal_originalTime": 1689820956000
    },
    {
        "time": 1689820957000,
        "value": 139.223,
        "_internal_originalTime": 1689820957000
    },
    {
        "time": 1689820960000,
        "value": 139.223,
        "_internal_originalTime": 1689820960000
    },
    {
        "time": 1689820961000,
        "value": 139.227,
        "_internal_originalTime": 1689820961000
    },
    {
        "time": 1689820964000,
        "value": 139.225,
        "_internal_originalTime": 1689820964000
    },
    {
        "time": 1689820966000,
        "value": 139.226,
        "_internal_originalTime": 1689820966000
    },
    {
        "time": 1689820969000,
        "value": 139.225,
        "_internal_originalTime": 1689820969000
    },
    {
        "time": 1689820970000,
        "value": 139.226,
        "_internal_originalTime": 1689820970000
    },
    {
        "time": 1689820971000,
        "value": 139.226,
        "_internal_originalTime": 1689820971000
    },
    {
        "time": 1689820973000,
        "value": 139.226,
        "_internal_originalTime": 1689820973000
    },
    {
        "time": 1689820974000,
        "value": 139.243,
        "_internal_originalTime": 1689820974000
    },
    {
        "time": 1689820975000,
        "value": 139.24,
        "_internal_originalTime": 1689820975000
    },
    {
        "time": 1689820976000,
        "value": 139.245,
        "_internal_originalTime": 1689820976000
    },
    {
        "time": 1689820977000,
        "value": 139.245,
        "_internal_originalTime": 1689820977000
    },
    {
        "time": 1689820979000,
        "value": 139.24,
        "_internal_originalTime": 1689820979000
    },
    {
        "time": 1689820980000,
        "value": 139.24,
        "_internal_originalTime": 1689820980000
    },
    {
        "time": 1689820981000,
        "value": 139.245,
        "_internal_originalTime": 1689820981000
    },
    {
        "time": 1689820982000,
        "value": 139.245,
        "_internal_originalTime": 1689820982000
    },
    {
        "time": 1689820987000,
        "value": 139.237,
        "_internal_originalTime": 1689820987000
    },
    {
        "time": 1689820989000,
        "value": 139.234,
        "_internal_originalTime": 1689820989000
    },
    {
        "time": 1689820991000,
        "value": 139.23,
        "_internal_originalTime": 1689820991000
    },
    {
        "time": 1689820993000,
        "value": 139.232,
        "_internal_originalTime": 1689820993000
    },
    {
        "time": 1689820994000,
        "value": 139.228,
        "_internal_originalTime": 1689820994000
    },
    {
        "time": 1689820995000,
        "value": 139.224,
        "_internal_originalTime": 1689820995000
    },
    {
        "time": 1689820996000,
        "value": 139.222,
        "_internal_originalTime": 1689820996000
    },
    {
        "time": 1689820997000,
        "value": 139.225,
        "_internal_originalTime": 1689820997000
    },
    {
        "time": 1689821002000,
        "value": 139.221,
        "_internal_originalTime": 1689821002000
    },
    {
        "time": 1689821003000,
        "value": 139.219,
        "_internal_originalTime": 1689821003000
    },
    {
        "time": 1689821004000,
        "value": 139.219,
        "_internal_originalTime": 1689821004000
    },
    {
        "time": 1689821005000,
        "value": 139.217,
        "_internal_originalTime": 1689821005000
    },
    {
        "time": 1689821008000,
        "value": 139.225,
        "_internal_originalTime": 1689821008000
    },
    {
        "time": 1689821009000,
        "value": 139.221,
        "_internal_originalTime": 1689821009000
    },
    {
        "time": 1689821011000,
        "value": 139.226,
        "_internal_originalTime": 1689821011000
    },
    {
        "time": 1689821013000,
        "value": 139.227,
        "_internal_originalTime": 1689821013000
    },
    {
        "time": 1689821014000,
        "value": 139.228,
        "_internal_originalTime": 1689821014000
    },
    {
        "time": 1689821015000,
        "value": 139.227,
        "_internal_originalTime": 1689821015000
    },
    {
        "time": 1689821017000,
        "value": 139.222,
        "_internal_originalTime": 1689821017000
    },
    {
        "time": 1689821019000,
        "value": 139.223,
        "_internal_originalTime": 1689821019000
    },
    {
        "time": 1689821021000,
        "value": 139.22,
        "_internal_originalTime": 1689821021000
    },
    {
        "time": 1689821023000,
        "value": 139.227,
        "_internal_originalTime": 1689821023000
    },
    {
        "time": 1689821028000,
        "value": 139.22,
        "_internal_originalTime": 1689821028000
    },
    {
        "time": 1689821029000,
        "value": 139.224,
        "_internal_originalTime": 1689821029000
    },
    {
        "time": 1689821030000,
        "value": 139.224,
        "_internal_originalTime": 1689821030000
    },
    {
        "time": 1689821032000,
        "value": 139.222,
        "_internal_originalTime": 1689821032000
    },
    {
        "time": 1689821034000,
        "value": 139.222,
        "_internal_originalTime": 1689821034000
    },
    {
        "time": 1689821039000,
        "value": 139.227,
        "_internal_originalTime": 1689821039000
    },
    {
        "time": 1689821040000,
        "value": 139.229,
        "_internal_originalTime": 1689821040000
    },
    {
        "time": 1689821041000,
        "value": 139.232,
        "_internal_originalTime": 1689821041000
    },
    {
        "time": 1689821042000,
        "value": 139.228,
        "_internal_originalTime": 1689821042000
    },
    {
        "time": 1689821043000,
        "value": 139.228,
        "_internal_originalTime": 1689821043000
    },
    {
        "time": 1689821044000,
        "value": 139.231,
        "_internal_originalTime": 1689821044000
    },
    {
        "time": 1689821045000,
        "value": 139.23,
        "_internal_originalTime": 1689821045000
    },
    {
        "time": 1689821049000,
        "value": 139.232,
        "_internal_originalTime": 1689821049000
    },
    {
        "time": 1689821050000,
        "value": 139.228,
        "_internal_originalTime": 1689821050000
    },
    {
        "time": 1689821051000,
        "value": 139.227,
        "_internal_originalTime": 1689821051000
    },
    {
        "time": 1689821053000,
        "value": 139.223,
        "_internal_originalTime": 1689821053000
    },
    {
        "time": 1689821055000,
        "value": 139.222,
        "_internal_originalTime": 1689821055000
    },
    {
        "time": 1689821056000,
        "value": 139.222,
        "_internal_originalTime": 1689821056000
    },
    {
        "time": 1689821058000,
        "value": 139.223,
        "_internal_originalTime": 1689821058000
    },
    {
        "time": 1689821060000,
        "value": 139.227,
        "_internal_originalTime": 1689821060000
    },
    {
        "time": 1689821063000,
        "value": 139.227,
        "_internal_originalTime": 1689821063000
    },
    {
        "time": 1689821064000,
        "value": 139.226,
        "_internal_originalTime": 1689821064000
    },
    {
        "time": 1689821065000,
        "value": 139.226,
        "_internal_originalTime": 1689821065000
    },
    {
        "time": 1689821066000,
        "value": 139.231,
        "_internal_originalTime": 1689821066000
    },
    {
        "time": 1689821068000,
        "value": 139.233,
        "_internal_originalTime": 1689821068000
    },
    {
        "time": 1689821070000,
        "value": 139.227,
        "_internal_originalTime": 1689821070000
    },
    {
        "time": 1689821071000,
        "value": 139.227,
        "_internal_originalTime": 1689821071000
    },
    {
        "time": 1689821072000,
        "value": 139.229,
        "_internal_originalTime": 1689821072000
    },
    {
        "time": 1689821074000,
        "value": 139.229,
        "_internal_originalTime": 1689821074000
    },
    {
        "time": 1689821075000,
        "value": 139.232,
        "_internal_originalTime": 1689821075000
    },
    {
        "time": 1689821077000,
        "value": 139.227,
        "_internal_originalTime": 1689821077000
    },
    {
        "time": 1689821079000,
        "value": 139.227,
        "_internal_originalTime": 1689821079000
    },
    {
        "time": 1689821081000,
        "value": 139.233,
        "_internal_originalTime": 1689821081000
    },
    {
        "time": 1689821082000,
        "value": 139.232,
        "_internal_originalTime": 1689821082000
    },
    {
        "time": 1689821083000,
        "value": 139.237,
        "_internal_originalTime": 1689821083000
    },
    {
        "time": 1689821085000,
        "value": 139.239,
        "_internal_originalTime": 1689821085000
    },
    {
        "time": 1689821086000,
        "value": 139.238,
        "_internal_originalTime": 1689821086000
    },
    {
        "time": 1689821088000,
        "value": 139.234,
        "_internal_originalTime": 1689821088000
    },
    {
        "time": 1689821091000,
        "value": 139.234,
        "_internal_originalTime": 1689821091000
    },
    {
        "time": 1689821096000,
        "value": 139.237,
        "_internal_originalTime": 1689821096000
    },
    {
        "time": 1689821099000,
        "value": 139.232,
        "_internal_originalTime": 1689821099000
    },
    {
        "time": 1689821103000,
        "value": 139.238,
        "_internal_originalTime": 1689821103000
    },
    {
        "time": 1689821104000,
        "value": 139.234,
        "_internal_originalTime": 1689821104000
    },
    {
        "time": 1689821106000,
        "value": 139.236,
        "_internal_originalTime": 1689821106000
    },
    {
        "time": 1689821107000,
        "value": 139.23,
        "_internal_originalTime": 1689821107000
    },
    {
        "time": 1689821108000,
        "value": 139.231,
        "_internal_originalTime": 1689821108000
    },
    {
        "time": 1689821109000,
        "value": 139.233,
        "_internal_originalTime": 1689821109000
    },
    {
        "time": 1689821110000,
        "value": 139.235,
        "_internal_originalTime": 1689821110000
    },
    {
        "time": 1689821114000,
        "value": 139.23,
        "_internal_originalTime": 1689821114000
    },
    {
        "time": 1689821115000,
        "value": 139.235,
        "_internal_originalTime": 1689821115000
    },
    {
        "time": 1689821117000,
        "value": 139.23,
        "_internal_originalTime": 1689821117000
    },
    {
        "time": 1689821118000,
        "value": 139.237,
        "_internal_originalTime": 1689821118000
    },
    {
        "time": 1689821119000,
        "value": 139.23,
        "_internal_originalTime": 1689821119000
    },
    {
        "time": 1689821120000,
        "value": 139.232,
        "_internal_originalTime": 1689821120000
    },
    {
        "time": 1689821121000,
        "value": 139.23,
        "_internal_originalTime": 1689821121000
    },
    {
        "time": 1689821123000,
        "value": 139.232,
        "_internal_originalTime": 1689821123000
    },
    {
        "time": 1689821124000,
        "value": 139.239,
        "_internal_originalTime": 1689821124000
    },
    {
        "time": 1689821126000,
        "value": 139.234,
        "_internal_originalTime": 1689821126000
    },
    {
        "time": 1689821127000,
        "value": 139.237,
        "_internal_originalTime": 1689821127000
    },
    {
        "time": 1689821129000,
        "value": 139.239,
        "_internal_originalTime": 1689821129000
    },
    {
        "time": 1689821130000,
        "value": 139.24,
        "_internal_originalTime": 1689821130000
    },
    {
        "time": 1689821133000,
        "value": 139.234,
        "_internal_originalTime": 1689821133000
    },
    {
        "time": 1689821134000,
        "value": 139.246,
        "_internal_originalTime": 1689821134000
    },
    {
        "time": 1689821135000,
        "value": 139.243,
        "_internal_originalTime": 1689821135000
    },
    {
        "time": 1689821137000,
        "value": 139.247,
        "_internal_originalTime": 1689821137000
    },
    {
        "time": 1689821139000,
        "value": 139.243,
        "_internal_originalTime": 1689821139000
    },
    {
        "time": 1689821141000,
        "value": 139.243,
        "_internal_originalTime": 1689821141000
    },
    {
        "time": 1689821143000,
        "value": 139.242,
        "_internal_originalTime": 1689821143000
    },
    {
        "time": 1689821144000,
        "value": 139.243,
        "_internal_originalTime": 1689821144000
    },
    {
        "time": 1689821145000,
        "value": 139.242,
        "_internal_originalTime": 1689821145000
    },
    {
        "time": 1689821146000,
        "value": 139.242,
        "_internal_originalTime": 1689821146000
    },
    {
        "time": 1689821147000,
        "value": 139.246,
        "_internal_originalTime": 1689821147000
    },
    {
        "time": 1689821150000,
        "value": 139.247,
        "_internal_originalTime": 1689821150000
    },
    {
        "time": 1689821152000,
        "value": 139.243,
        "_internal_originalTime": 1689821152000
    },
    {
        "time": 1689821153000,
        "value": 139.247,
        "_internal_originalTime": 1689821153000
    },
    {
        "time": 1689821155000,
        "value": 139.248,
        "_internal_originalTime": 1689821155000
    },
    {
        "time": 1689821156000,
        "value": 139.246,
        "_internal_originalTime": 1689821156000
    },
    {
        "time": 1689821157000,
        "value": 139.242,
        "_internal_originalTime": 1689821157000
    },
    {
        "time": 1689821161000,
        "value": 139.247,
        "_internal_originalTime": 1689821161000
    },
    {
        "time": 1689821164000,
        "value": 139.243,
        "_internal_originalTime": 1689821164000
    },
    {
        "time": 1689821165000,
        "value": 139.245,
        "_internal_originalTime": 1689821165000
    },
    {
        "time": 1689821167000,
        "value": 139.247,
        "_internal_originalTime": 1689821167000
    },
    {
        "time": 1689821169000,
        "value": 139.246,
        "_internal_originalTime": 1689821169000
    },
    {
        "time": 1689821171000,
        "value": 139.244,
        "_internal_originalTime": 1689821171000
    },
    {
        "time": 1689821173000,
        "value": 139.248,
        "_internal_originalTime": 1689821173000
    },
    {
        "time": 1689821174000,
        "value": 139.236,
        "_internal_originalTime": 1689821174000
    },
    {
        "time": 1689821175000,
        "value": 139.232,
        "_internal_originalTime": 1689821175000
    },
    {
        "time": 1689821176000,
        "value": 139.232,
        "_internal_originalTime": 1689821176000
    },
    {
        "time": 1689821178000,
        "value": 139.236,
        "_internal_originalTime": 1689821178000
    },
    {
        "time": 1689821181000,
        "value": 139.232,
        "_internal_originalTime": 1689821181000
    },
    {
        "time": 1689821182000,
        "value": 139.237,
        "_internal_originalTime": 1689821182000
    },
    {
        "time": 1689821183000,
        "value": 139.235,
        "_internal_originalTime": 1689821183000
    },
    {
        "time": 1689821184000,
        "value": 139.236,
        "_internal_originalTime": 1689821184000
    },
    {
        "time": 1689821185000,
        "value": 139.232,
        "_internal_originalTime": 1689821185000
    },
    {
        "time": 1689821187000,
        "value": 139.238,
        "_internal_originalTime": 1689821187000
    },
    {
        "time": 1689821188000,
        "value": 139.237,
        "_internal_originalTime": 1689821188000
    },
    {
        "time": 1689821189000,
        "value": 139.229,
        "_internal_originalTime": 1689821189000
    },
    {
        "time": 1689821192000,
        "value": 139.232,
        "_internal_originalTime": 1689821192000
    },
    {
        "time": 1689821194000,
        "value": 139.227,
        "_internal_originalTime": 1689821194000
    },
    {
        "time": 1689821195000,
        "value": 139.224,
        "_internal_originalTime": 1689821195000
    },
    {
        "time": 1689821196000,
        "value": 139.229,
        "_internal_originalTime": 1689821196000
    },
    {
        "time": 1689821199000,
        "value": 139.228,
        "_internal_originalTime": 1689821199000
    },
    {
        "time": 1689821200000,
        "value": 139.229,
        "_internal_originalTime": 1689821200000
    },
    {
        "time": 1689821201000,
        "value": 139.224,
        "_internal_originalTime": 1689821201000
    },
    {
        "time": 1689821202000,
        "value": 139.227,
        "_internal_originalTime": 1689821202000
    },
    {
        "time": 1689821203000,
        "value": 139.224,
        "_internal_originalTime": 1689821203000
    },
    {
        "time": 1689821204000,
        "value": 139.226,
        "_internal_originalTime": 1689821204000
    },
    {
        "time": 1689821206000,
        "value": 139.228,
        "_internal_originalTime": 1689821206000
    },
    {
        "time": 1689821207000,
        "value": 139.222,
        "_internal_originalTime": 1689821207000
    },
    {
        "time": 1689821208000,
        "value": 139.227,
        "_internal_originalTime": 1689821208000
    },
    {
        "time": 1689821210000,
        "value": 139.227,
        "_internal_originalTime": 1689821210000
    },
    {
        "time": 1689821211000,
        "value": 139.235,
        "_internal_originalTime": 1689821211000
    },
    {
        "time": 1689821213000,
        "value": 139.232,
        "_internal_originalTime": 1689821213000
    },
    {
        "time": 1689821214000,
        "value": 139.238,
        "_internal_originalTime": 1689821214000
    },
    {
        "time": 1689821215000,
        "value": 139.237,
        "_internal_originalTime": 1689821215000
    },
    {
        "time": 1689821216000,
        "value": 139.232,
        "_internal_originalTime": 1689821216000
    },
    {
        "time": 1689821220000,
        "value": 139.241,
        "_internal_originalTime": 1689821220000
    },
    {
        "time": 1689821221000,
        "value": 139.239,
        "_internal_originalTime": 1689821221000
    },
    {
        "time": 1689821223000,
        "value": 139.232,
        "_internal_originalTime": 1689821223000
    },
    {
        "time": 1689821224000,
        "value": 139.232,
        "_internal_originalTime": 1689821224000
    },
    {
        "time": 1689821225000,
        "value": 139.232,
        "_internal_originalTime": 1689821225000
    },
    {
        "time": 1689821226000,
        "value": 139.235,
        "_internal_originalTime": 1689821226000
    },
    {
        "time": 1689821229000,
        "value": 139.222,
        "_internal_originalTime": 1689821229000
    },
    {
        "time": 1689821230000,
        "value": 139.222,
        "_internal_originalTime": 1689821230000
    },
    {
        "time": 1689821232000,
        "value": 139.221,
        "_internal_originalTime": 1689821232000
    },
    {
        "time": 1689821233000,
        "value": 139.223,
        "_internal_originalTime": 1689821233000
    },
    {
        "time": 1689821235000,
        "value": 139.223,
        "_internal_originalTime": 1689821235000
    },
    {
        "time": 1689821236000,
        "value": 139.225,
        "_internal_originalTime": 1689821236000
    },
    {
        "time": 1689821237000,
        "value": 139.222,
        "_internal_originalTime": 1689821237000
    },
    {
        "time": 1689821239000,
        "value": 139.225,
        "_internal_originalTime": 1689821239000
    },
    {
        "time": 1689821240000,
        "value": 139.229,
        "_internal_originalTime": 1689821240000
    },
    {
        "time": 1689821241000,
        "value": 139.229,
        "_internal_originalTime": 1689821241000
    },
    {
        "time": 1689821244000,
        "value": 139.231,
        "_internal_originalTime": 1689821244000
    },
    {
        "time": 1689821245000,
        "value": 139.23,
        "_internal_originalTime": 1689821245000
    },
    {
        "time": 1689821246000,
        "value": 139.224,
        "_internal_originalTime": 1689821246000
    },
    {
        "time": 1689821247000,
        "value": 139.232,
        "_internal_originalTime": 1689821247000
    },
    {
        "time": 1689821248000,
        "value": 139.229,
        "_internal_originalTime": 1689821248000
    },
    {
        "time": 1689821249000,
        "value": 139.227,
        "_internal_originalTime": 1689821249000
    },
    {
        "time": 1689821250000,
        "value": 139.225,
        "_internal_originalTime": 1689821250000
    },
    {
        "time": 1689821251000,
        "value": 139.224,
        "_internal_originalTime": 1689821251000
    },
    {
        "time": 1689821252000,
        "value": 139.212,
        "_internal_originalTime": 1689821252000
    },
    {
        "time": 1689821254000,
        "value": 139.212,
        "_internal_originalTime": 1689821254000
    },
    {
        "time": 1689821256000,
        "value": 139.212,
        "_internal_originalTime": 1689821256000
    },
    {
        "time": 1689821257000,
        "value": 139.212,
        "_internal_originalTime": 1689821257000
    },
    {
        "time": 1689821259000,
        "value": 139.214,
        "_internal_originalTime": 1689821259000
    },
    {
        "time": 1689821261000,
        "value": 139.215,
        "_internal_originalTime": 1689821261000
    },
    {
        "time": 1689821262000,
        "value": 139.215,
        "_internal_originalTime": 1689821262000
    },
    {
        "time": 1689821263000,
        "value": 139.215,
        "_internal_originalTime": 1689821263000
    },
    {
        "time": 1689821265000,
        "value": 139.21,
        "_internal_originalTime": 1689821265000
    },
    {
        "time": 1689821266000,
        "value": 139.209,
        "_internal_originalTime": 1689821266000
    },
    {
        "time": 1689821267000,
        "value": 139.206,
        "_internal_originalTime": 1689821267000
    },
    {
        "time": 1689821269000,
        "value": 139.204,
        "_internal_originalTime": 1689821269000
    },
    {
        "time": 1689821271000,
        "value": 139.202,
        "_internal_originalTime": 1689821271000
    },
    {
        "time": 1689821273000,
        "value": 139.202,
        "_internal_originalTime": 1689821273000
    },
    {
        "time": 1689821274000,
        "value": 139.204,
        "_internal_originalTime": 1689821274000
    },
    {
        "time": 1689821275000,
        "value": 139.205,
        "_internal_originalTime": 1689821275000
    },
    {
        "time": 1689821276000,
        "value": 139.206,
        "_internal_originalTime": 1689821276000
    },
    {
        "time": 1689821279000,
        "value": 139.206,
        "_internal_originalTime": 1689821279000
    },
    {
        "time": 1689821280000,
        "value": 139.202,
        "_internal_originalTime": 1689821280000
    },
    {
        "time": 1689821281000,
        "value": 139.2,
        "_internal_originalTime": 1689821281000
    },
    {
        "time": 1689821282000,
        "value": 139.202,
        "_internal_originalTime": 1689821282000
    },
    {
        "time": 1689821283000,
        "value": 139.201,
        "_internal_originalTime": 1689821283000
    },
    {
        "time": 1689821284000,
        "value": 139.204,
        "_internal_originalTime": 1689821284000
    },
    {
        "time": 1689821285000,
        "value": 139.201,
        "_internal_originalTime": 1689821285000
    },
    {
        "time": 1689821286000,
        "value": 139.205,
        "_internal_originalTime": 1689821286000
    },
    {
        "time": 1689821287000,
        "value": 139.207,
        "_internal_originalTime": 1689821287000
    },
    {
        "time": 1689821289000,
        "value": 139.205,
        "_internal_originalTime": 1689821289000
    },
    {
        "time": 1689821290000,
        "value": 139.204,
        "_internal_originalTime": 1689821290000
    },
    {
        "time": 1689821291000,
        "value": 139.204,
        "_internal_originalTime": 1689821291000
    },
    {
        "time": 1689821292000,
        "value": 139.207,
        "_internal_originalTime": 1689821292000
    },
    {
        "time": 1689821293000,
        "value": 139.205,
        "_internal_originalTime": 1689821293000
    },
    {
        "time": 1689821294000,
        "value": 139.204,
        "_internal_originalTime": 1689821294000
    },
    {
        "time": 1689821296000,
        "value": 139.209,
        "_internal_originalTime": 1689821296000
    },
    {
        "time": 1689821298000,
        "value": 139.209,
        "_internal_originalTime": 1689821298000
    },
    {
        "time": 1689821299000,
        "value": 139.209,
        "_internal_originalTime": 1689821299000
    },
    {
        "time": 1689821301000,
        "value": 139.207,
        "_internal_originalTime": 1689821301000
    },
    {
        "time": 1689821305000,
        "value": 139.212,
        "_internal_originalTime": 1689821305000
    },
    {
        "time": 1689821306000,
        "value": 139.215,
        "_internal_originalTime": 1689821306000
    },
    {
        "time": 1689821307000,
        "value": 139.212,
        "_internal_originalTime": 1689821307000
    },
    {
        "time": 1689821308000,
        "value": 139.212,
        "_internal_originalTime": 1689821308000
    },
    {
        "time": 1689821309000,
        "value": 139.208,
        "_internal_originalTime": 1689821309000
    },
    {
        "time": 1689821310000,
        "value": 139.215,
        "_internal_originalTime": 1689821310000
    },
    {
        "time": 1689821312000,
        "value": 139.21,
        "_internal_originalTime": 1689821312000
    },
    {
        "time": 1689821313000,
        "value": 139.212,
        "_internal_originalTime": 1689821313000
    },
    {
        "time": 1689821315000,
        "value": 139.217,
        "_internal_originalTime": 1689821315000
    },
    {
        "time": 1689821316000,
        "value": 139.214,
        "_internal_originalTime": 1689821316000
    },
    {
        "time": 1689821317000,
        "value": 139.219,
        "_internal_originalTime": 1689821317000
    },
    {
        "time": 1689821319000,
        "value": 139.22,
        "_internal_originalTime": 1689821319000
    },
    {
        "time": 1689821321000,
        "value": 139.223,
        "_internal_originalTime": 1689821321000
    },
    {
        "time": 1689821322000,
        "value": 139.223,
        "_internal_originalTime": 1689821322000
    },
    {
        "time": 1689821323000,
        "value": 139.218,
        "_internal_originalTime": 1689821323000
    },
    {
        "time": 1689821324000,
        "value": 139.214,
        "_internal_originalTime": 1689821324000
    },
    {
        "time": 1689821326000,
        "value": 139.212,
        "_internal_originalTime": 1689821326000
    },
    {
        "time": 1689821327000,
        "value": 139.211,
        "_internal_originalTime": 1689821327000
    },
    {
        "time": 1689821329000,
        "value": 139.209,
        "_internal_originalTime": 1689821329000
    },
    {
        "time": 1689821330000,
        "value": 139.212,
        "_internal_originalTime": 1689821330000
    },
    {
        "time": 1689821332000,
        "value": 139.209,
        "_internal_originalTime": 1689821332000
    },
    {
        "time": 1689821335000,
        "value": 139.212,
        "_internal_originalTime": 1689821335000
    },
    {
        "time": 1689821336000,
        "value": 139.208,
        "_internal_originalTime": 1689821336000
    },
    {
        "time": 1689821337000,
        "value": 139.206,
        "_internal_originalTime": 1689821337000
    },
    {
        "time": 1689821339000,
        "value": 139.207,
        "_internal_originalTime": 1689821339000
    },
    {
        "time": 1689821341000,
        "value": 139.206,
        "_internal_originalTime": 1689821341000
    },
    {
        "time": 1689821343000,
        "value": 139.206,
        "_internal_originalTime": 1689821343000
    },
    {
        "time": 1689821344000,
        "value": 139.206,
        "_internal_originalTime": 1689821344000
    },
    {
        "time": 1689821348000,
        "value": 139.207,
        "_internal_originalTime": 1689821348000
    },
    {
        "time": 1689821349000,
        "value": 139.21,
        "_internal_originalTime": 1689821349000
    },
    {
        "time": 1689821350000,
        "value": 139.208,
        "_internal_originalTime": 1689821350000
    },
    {
        "time": 1689821352000,
        "value": 139.206,
        "_internal_originalTime": 1689821352000
    },
    {
        "time": 1689821353000,
        "value": 139.206,
        "_internal_originalTime": 1689821353000
    },
    {
        "time": 1689821354000,
        "value": 139.206,
        "_internal_originalTime": 1689821354000
    },
    {
        "time": 1689821356000,
        "value": 139.204,
        "_internal_originalTime": 1689821356000
    },
    {
        "time": 1689821357000,
        "value": 139.202,
        "_internal_originalTime": 1689821357000
    },
    {
        "time": 1689821359000,
        "value": 139.205,
        "_internal_originalTime": 1689821359000
    },
    {
        "time": 1689821360000,
        "value": 139.202,
        "_internal_originalTime": 1689821360000
    },
    {
        "time": 1689821361000,
        "value": 139.205,
        "_internal_originalTime": 1689821361000
    },
    {
        "time": 1689821363000,
        "value": 139.21,
        "_internal_originalTime": 1689821363000
    },
    {
        "time": 1689821365000,
        "value": 139.209,
        "_internal_originalTime": 1689821365000
    },
    {
        "time": 1689821366000,
        "value": 139.21,
        "_internal_originalTime": 1689821366000
    },
    {
        "time": 1689821369000,
        "value": 139.207,
        "_internal_originalTime": 1689821369000
    },
    {
        "time": 1689821370000,
        "value": 139.21,
        "_internal_originalTime": 1689821370000
    },
    {
        "time": 1689821371000,
        "value": 139.213,
        "_internal_originalTime": 1689821371000
    },
    {
        "time": 1689821374000,
        "value": 139.21,
        "_internal_originalTime": 1689821374000
    },
    {
        "time": 1689821375000,
        "value": 139.211,
        "_internal_originalTime": 1689821375000
    },
    {
        "time": 1689821377000,
        "value": 139.202,
        "_internal_originalTime": 1689821377000
    },
    {
        "time": 1689821378000,
        "value": 139.2,
        "_internal_originalTime": 1689821378000
    },
    {
        "time": 1689821379000,
        "value": 139.196,
        "_internal_originalTime": 1689821379000
    },
    {
        "time": 1689821380000,
        "value": 139.193,
        "_internal_originalTime": 1689821380000
    },
    {
        "time": 1689821381000,
        "value": 139.195,
        "_internal_originalTime": 1689821381000
    },
    {
        "time": 1689821383000,
        "value": 139.204,
        "_internal_originalTime": 1689821383000
    },
    {
        "time": 1689821384000,
        "value": 139.2,
        "_internal_originalTime": 1689821384000
    },
    {
        "time": 1689821385000,
        "value": 139.204,
        "_internal_originalTime": 1689821385000
    },
    {
        "time": 1689821386000,
        "value": 139.204,
        "_internal_originalTime": 1689821386000
    },
    {
        "time": 1689821388000,
        "value": 139.204,
        "_internal_originalTime": 1689821388000
    },
    {
        "time": 1689821390000,
        "value": 139.2,
        "_internal_originalTime": 1689821390000
    },
    {
        "time": 1689821391000,
        "value": 139.204,
        "_internal_originalTime": 1689821391000
    },
    {
        "time": 1689821394000,
        "value": 139.199,
        "_internal_originalTime": 1689821394000
    },
    {
        "time": 1689821396000,
        "value": 139.2,
        "_internal_originalTime": 1689821396000
    },
    {
        "time": 1689821397000,
        "value": 139.2,
        "_internal_originalTime": 1689821397000
    },
    {
        "time": 1689821398000,
        "value": 139.2,
        "_internal_originalTime": 1689821398000
    },
    {
        "time": 1689821399000,
        "value": 139.198,
        "_internal_originalTime": 1689821399000
    },
    {
        "time": 1689821400000,
        "value": 139.2,
        "_internal_originalTime": 1689821400000
    },
    {
        "time": 1689821403000,
        "value": 139.2,
        "_internal_originalTime": 1689821403000
    },
    {
        "time": 1689821404000,
        "value": 139.201,
        "_internal_originalTime": 1689821404000
    },
    {
        "time": 1689821405000,
        "value": 139.206,
        "_internal_originalTime": 1689821405000
    },
    {
        "time": 1689821406000,
        "value": 139.202,
        "_internal_originalTime": 1689821406000
    },
    {
        "time": 1689821408000,
        "value": 139.202,
        "_internal_originalTime": 1689821408000
    },
    {
        "time": 1689821409000,
        "value": 139.202,
        "_internal_originalTime": 1689821409000
    },
    {
        "time": 1689821410000,
        "value": 139.202,
        "_internal_originalTime": 1689821410000
    },
    {
        "time": 1689821411000,
        "value": 139.206,
        "_internal_originalTime": 1689821411000
    },
    {
        "time": 1689821417000,
        "value": 139.206,
        "_internal_originalTime": 1689821417000
    },
    {
        "time": 1689821421000,
        "value": 139.204,
        "_internal_originalTime": 1689821421000
    },
    {
        "time": 1689821422000,
        "value": 139.205,
        "_internal_originalTime": 1689821422000
    },
    {
        "time": 1689821424000,
        "value": 139.202,
        "_internal_originalTime": 1689821424000
    },
    {
        "time": 1689821425000,
        "value": 139.203,
        "_internal_originalTime": 1689821425000
    },
    {
        "time": 1689821426000,
        "value": 139.203,
        "_internal_originalTime": 1689821426000
    },
    {
        "time": 1689821428000,
        "value": 139.205,
        "_internal_originalTime": 1689821428000
    },
    {
        "time": 1689821429000,
        "value": 139.202,
        "_internal_originalTime": 1689821429000
    },
    {
        "time": 1689821430000,
        "value": 139.205,
        "_internal_originalTime": 1689821430000
    },
    {
        "time": 1689821432000,
        "value": 139.202,
        "_internal_originalTime": 1689821432000
    },
    {
        "time": 1689821434000,
        "value": 139.201,
        "_internal_originalTime": 1689821434000
    },
    {
        "time": 1689821436000,
        "value": 139.205,
        "_internal_originalTime": 1689821436000
    },
    {
        "time": 1689821437000,
        "value": 139.205,
        "_internal_originalTime": 1689821437000
    },
    {
        "time": 1689821438000,
        "value": 139.205,
        "_internal_originalTime": 1689821438000
    },
    {
        "time": 1689821439000,
        "value": 139.202,
        "_internal_originalTime": 1689821439000
    },
    {
        "time": 1689821446000,
        "value": 139.205,
        "_internal_originalTime": 1689821446000
    },
    {
        "time": 1689821449000,
        "value": 139.2,
        "_internal_originalTime": 1689821449000
    },
    {
        "time": 1689821451000,
        "value": 139.202,
        "_internal_originalTime": 1689821451000
    },
    {
        "time": 1689821454000,
        "value": 139.202,
        "_internal_originalTime": 1689821454000
    },
    {
        "time": 1689821455000,
        "value": 139.202,
        "_internal_originalTime": 1689821455000
    },
    {
        "time": 1689821457000,
        "value": 139.201,
        "_internal_originalTime": 1689821457000
    },
    {
        "time": 1689821458000,
        "value": 139.206,
        "_internal_originalTime": 1689821458000
    },
    {
        "time": 1689821459000,
        "value": 139.201,
        "_internal_originalTime": 1689821459000
    },
    {
        "time": 1689821460000,
        "value": 139.202,
        "_internal_originalTime": 1689821460000
    },
    {
        "time": 1689821466000,
        "value": 139.202,
        "_internal_originalTime": 1689821466000
    },
    {
        "time": 1689821467000,
        "value": 139.202,
        "_internal_originalTime": 1689821467000
    },
    {
        "time": 1689821469000,
        "value": 139.205,
        "_internal_originalTime": 1689821469000
    },
    {
        "time": 1689821475000,
        "value": 139.205,
        "_internal_originalTime": 1689821475000
    },
    {
        "time": 1689821480000,
        "value": 139.2,
        "_internal_originalTime": 1689821480000
    },
    {
        "time": 1689821483000,
        "value": 139.206,
        "_internal_originalTime": 1689821483000
    },
    {
        "time": 1689821487000,
        "value": 139.212,
        "_internal_originalTime": 1689821487000
    },
    {
        "time": 1689821488000,
        "value": 139.214,
        "_internal_originalTime": 1689821488000
    },
    {
        "time": 1689821489000,
        "value": 139.209,
        "_internal_originalTime": 1689821489000
    },
    {
        "time": 1689821490000,
        "value": 139.219,
        "_internal_originalTime": 1689821490000
    },
    {
        "time": 1689821491000,
        "value": 139.22,
        "_internal_originalTime": 1689821491000
    },
    {
        "time": 1689821492000,
        "value": 139.216,
        "_internal_originalTime": 1689821492000
    },
    {
        "time": 1689821494000,
        "value": 139.211,
        "_internal_originalTime": 1689821494000
    },
    {
        "time": 1689821495000,
        "value": 139.215,
        "_internal_originalTime": 1689821495000
    },
    {
        "time": 1689821497000,
        "value": 139.219,
        "_internal_originalTime": 1689821497000
    },
    {
        "time": 1689821498000,
        "value": 139.216,
        "_internal_originalTime": 1689821498000
    },
    {
        "time": 1689821499000,
        "value": 139.217,
        "_internal_originalTime": 1689821499000
    },
    {
        "time": 1689821500000,
        "value": 139.217,
        "_internal_originalTime": 1689821500000
    },
    {
        "time": 1689821502000,
        "value": 139.221,
        "_internal_originalTime": 1689821502000
    },
    {
        "time": 1689821503000,
        "value": 139.226,
        "_internal_originalTime": 1689821503000
    },
    {
        "time": 1689821504000,
        "value": 139.227,
        "_internal_originalTime": 1689821504000
    },
    {
        "time": 1689821505000,
        "value": 139.224,
        "_internal_originalTime": 1689821505000
    },
    {
        "time": 1689821506000,
        "value": 139.224,
        "_internal_originalTime": 1689821506000
    },
    {
        "time": 1689821509000,
        "value": 139.223,
        "_internal_originalTime": 1689821509000
    },
    {
        "time": 1689821512000,
        "value": 139.222,
        "_internal_originalTime": 1689821512000
    },
    {
        "time": 1689821513000,
        "value": 139.222,
        "_internal_originalTime": 1689821513000
    },
    {
        "time": 1689821515000,
        "value": 139.223,
        "_internal_originalTime": 1689821515000
    },
    {
        "time": 1689821516000,
        "value": 139.236,
        "_internal_originalTime": 1689821516000
    },
    {
        "time": 1689821519000,
        "value": 139.236,
        "_internal_originalTime": 1689821519000
    },
    {
        "time": 1689821520000,
        "value": 139.243,
        "_internal_originalTime": 1689821520000
    },
    {
        "time": 1689821521000,
        "value": 139.243,
        "_internal_originalTime": 1689821521000
    },
    {
        "time": 1689821522000,
        "value": 139.24,
        "_internal_originalTime": 1689821522000
    },
    {
        "time": 1689821524000,
        "value": 139.243,
        "_internal_originalTime": 1689821524000
    },
    {
        "time": 1689821525000,
        "value": 139.243,
        "_internal_originalTime": 1689821525000
    },
    {
        "time": 1689821527000,
        "value": 139.24,
        "_internal_originalTime": 1689821527000
    },
    {
        "time": 1689821528000,
        "value": 139.245,
        "_internal_originalTime": 1689821528000
    },
    {
        "time": 1689821529000,
        "value": 139.244,
        "_internal_originalTime": 1689821529000
    },
    {
        "time": 1689821534000,
        "value": 139.244,
        "_internal_originalTime": 1689821534000
    },
    {
        "time": 1689821535000,
        "value": 139.243,
        "_internal_originalTime": 1689821535000
    },
    {
        "time": 1689821536000,
        "value": 139.235,
        "_internal_originalTime": 1689821536000
    },
    {
        "time": 1689821537000,
        "value": 139.235,
        "_internal_originalTime": 1689821537000
    },
    {
        "time": 1689821538000,
        "value": 139.236,
        "_internal_originalTime": 1689821538000
    },
    {
        "time": 1689821539000,
        "value": 139.232,
        "_internal_originalTime": 1689821539000
    },
    {
        "time": 1689821541000,
        "value": 139.23,
        "_internal_originalTime": 1689821541000
    },
    {
        "time": 1689821542000,
        "value": 139.234,
        "_internal_originalTime": 1689821542000
    },
    {
        "time": 1689821543000,
        "value": 139.236,
        "_internal_originalTime": 1689821543000
    },
    {
        "time": 1689821544000,
        "value": 139.236,
        "_internal_originalTime": 1689821544000
    },
    {
        "time": 1689821545000,
        "value": 139.234,
        "_internal_originalTime": 1689821545000
    },
    {
        "time": 1689821547000,
        "value": 139.236,
        "_internal_originalTime": 1689821547000
    },
    {
        "time": 1689821548000,
        "value": 139.237,
        "_internal_originalTime": 1689821548000
    },
    {
        "time": 1689821549000,
        "value": 139.23,
        "_internal_originalTime": 1689821549000
    },
    {
        "time": 1689821550000,
        "value": 139.236,
        "_internal_originalTime": 1689821550000
    },
    {
        "time": 1689821551000,
        "value": 139.232,
        "_internal_originalTime": 1689821551000
    },
    {
        "time": 1689821552000,
        "value": 139.232,
        "_internal_originalTime": 1689821552000
    },
    {
        "time": 1689821554000,
        "value": 139.235,
        "_internal_originalTime": 1689821554000
    },
    {
        "time": 1689821555000,
        "value": 139.235,
        "_internal_originalTime": 1689821555000
    },
    {
        "time": 1689821556000,
        "value": 139.232,
        "_internal_originalTime": 1689821556000
    },
    {
        "time": 1689821558000,
        "value": 139.234,
        "_internal_originalTime": 1689821558000
    },
    {
        "time": 1689821559000,
        "value": 139.234,
        "_internal_originalTime": 1689821559000
    },
    {
        "time": 1689821562000,
        "value": 139.233,
        "_internal_originalTime": 1689821562000
    },
    {
        "time": 1689821563000,
        "value": 139.234,
        "_internal_originalTime": 1689821563000
    },
    {
        "time": 1689821564000,
        "value": 139.236,
        "_internal_originalTime": 1689821564000
    },
    {
        "time": 1689821565000,
        "value": 139.237,
        "_internal_originalTime": 1689821565000
    },
    {
        "time": 1689821566000,
        "value": 139.238,
        "_internal_originalTime": 1689821566000
    },
    {
        "time": 1689821568000,
        "value": 139.236,
        "_internal_originalTime": 1689821568000
    },
    {
        "time": 1689821569000,
        "value": 139.237,
        "_internal_originalTime": 1689821569000
    },
    {
        "time": 1689821572000,
        "value": 139.232,
        "_internal_originalTime": 1689821572000
    },
    {
        "time": 1689821575000,
        "value": 139.232,
        "_internal_originalTime": 1689821575000
    },
    {
        "time": 1689821578000,
        "value": 139.235,
        "_internal_originalTime": 1689821578000
    },
    {
        "time": 1689821579000,
        "value": 139.232,
        "_internal_originalTime": 1689821579000
    },
    {
        "time": 1689821580000,
        "value": 139.23,
        "_internal_originalTime": 1689821580000
    },
    {
        "time": 1689821581000,
        "value": 139.235,
        "_internal_originalTime": 1689821581000
    },
    {
        "time": 1689821583000,
        "value": 139.232,
        "_internal_originalTime": 1689821583000
    },
    {
        "time": 1689821584000,
        "value": 139.237,
        "_internal_originalTime": 1689821584000
    },
    {
        "time": 1689821585000,
        "value": 139.237,
        "_internal_originalTime": 1689821585000
    },
    {
        "time": 1689821586000,
        "value": 139.236,
        "_internal_originalTime": 1689821586000
    },
    {
        "time": 1689821587000,
        "value": 139.232,
        "_internal_originalTime": 1689821587000
    },
    {
        "time": 1689821589000,
        "value": 139.235,
        "_internal_originalTime": 1689821589000
    },
    {
        "time": 1689821592000,
        "value": 139.232,
        "_internal_originalTime": 1689821592000
    },
    {
        "time": 1689821593000,
        "value": 139.234,
        "_internal_originalTime": 1689821593000
    },
    {
        "time": 1689821594000,
        "value": 139.232,
        "_internal_originalTime": 1689821594000
    },
    {
        "time": 1689821595000,
        "value": 139.233,
        "_internal_originalTime": 1689821595000
    },
    {
        "time": 1689821597000,
        "value": 139.232,
        "_internal_originalTime": 1689821597000
    },
    {
        "time": 1689821599000,
        "value": 139.231,
        "_internal_originalTime": 1689821599000
    },
    {
        "time": 1689821608000,
        "value": 139.23,
        "_internal_originalTime": 1689821608000
    },
    {
        "time": 1689821610000,
        "value": 139.233,
        "_internal_originalTime": 1689821610000
    },
    {
        "time": 1689821611000,
        "value": 139.233,
        "_internal_originalTime": 1689821611000
    },
    {
        "time": 1689821612000,
        "value": 139.232,
        "_internal_originalTime": 1689821612000
    },
    {
        "time": 1689821613000,
        "value": 139.235,
        "_internal_originalTime": 1689821613000
    },
    {
        "time": 1689821615000,
        "value": 139.234,
        "_internal_originalTime": 1689821615000
    },
    {
        "time": 1689821617000,
        "value": 139.235,
        "_internal_originalTime": 1689821617000
    },
    {
        "time": 1689821618000,
        "value": 139.238,
        "_internal_originalTime": 1689821618000
    },
    {
        "time": 1689821619000,
        "value": 139.233,
        "_internal_originalTime": 1689821619000
    },
    {
        "time": 1689821621000,
        "value": 139.234,
        "_internal_originalTime": 1689821621000
    },
    {
        "time": 1689821623000,
        "value": 139.248,
        "_internal_originalTime": 1689821623000
    },
    {
        "time": 1689821624000,
        "value": 139.247,
        "_internal_originalTime": 1689821624000
    },
    {
        "time": 1689821626000,
        "value": 139.248,
        "_internal_originalTime": 1689821626000
    },
    {
        "time": 1689821627000,
        "value": 139.248,
        "_internal_originalTime": 1689821627000
    },
    {
        "time": 1689821628000,
        "value": 139.247,
        "_internal_originalTime": 1689821628000
    },
    {
        "time": 1689821630000,
        "value": 139.252,
        "_internal_originalTime": 1689821630000
    },
    {
        "time": 1689821631000,
        "value": 139.25,
        "_internal_originalTime": 1689821631000
    },
    {
        "time": 1689821633000,
        "value": 139.25,
        "_internal_originalTime": 1689821633000
    },
    {
        "time": 1689821634000,
        "value": 139.258,
        "_internal_originalTime": 1689821634000
    },
    {
        "time": 1689821637000,
        "value": 139.259,
        "_internal_originalTime": 1689821637000
    },
    {
        "time": 1689821638000,
        "value": 139.262,
        "_internal_originalTime": 1689821638000
    },
    {
        "time": 1689821640000,
        "value": 139.257,
        "_internal_originalTime": 1689821640000
    },
    {
        "time": 1689821641000,
        "value": 139.266,
        "_internal_originalTime": 1689821641000
    },
    {
        "time": 1689821643000,
        "value": 139.265,
        "_internal_originalTime": 1689821643000
    },
    {
        "time": 1689821644000,
        "value": 139.26,
        "_internal_originalTime": 1689821644000
    },
    {
        "time": 1689821645000,
        "value": 139.265,
        "_internal_originalTime": 1689821645000
    },
    {
        "time": 1689821647000,
        "value": 139.256,
        "_internal_originalTime": 1689821647000
    },
    {
        "time": 1689821649000,
        "value": 139.256,
        "_internal_originalTime": 1689821649000
    },
    {
        "time": 1689821650000,
        "value": 139.255,
        "_internal_originalTime": 1689821650000
    },
    {
        "time": 1689821651000,
        "value": 139.256,
        "_internal_originalTime": 1689821651000
    },
    {
        "time": 1689821653000,
        "value": 139.255,
        "_internal_originalTime": 1689821653000
    },
    {
        "time": 1689821654000,
        "value": 139.255,
        "_internal_originalTime": 1689821654000
    },
    {
        "time": 1689821655000,
        "value": 139.258,
        "_internal_originalTime": 1689821655000
    },
    {
        "time": 1689821656000,
        "value": 139.263,
        "_internal_originalTime": 1689821656000
    },
    {
        "time": 1689821657000,
        "value": 139.264,
        "_internal_originalTime": 1689821657000
    },
    {
        "time": 1689821659000,
        "value": 139.267,
        "_internal_originalTime": 1689821659000
    },
    {
        "time": 1689821662000,
        "value": 139.271,
        "_internal_originalTime": 1689821662000
    },
    {
        "time": 1689821664000,
        "value": 139.28,
        "_internal_originalTime": 1689821664000
    },
    {
        "time": 1689821665000,
        "value": 139.281,
        "_internal_originalTime": 1689821665000
    },
    {
        "time": 1689821666000,
        "value": 139.278,
        "_internal_originalTime": 1689821666000
    },
    {
        "time": 1689821667000,
        "value": 139.274,
        "_internal_originalTime": 1689821667000
    },
    {
        "time": 1689821668000,
        "value": 139.276,
        "_internal_originalTime": 1689821668000
    },
    {
        "time": 1689821670000,
        "value": 139.265,
        "_internal_originalTime": 1689821670000
    },
    {
        "time": 1689821671000,
        "value": 139.272,
        "_internal_originalTime": 1689821671000
    },
    {
        "time": 1689821673000,
        "value": 139.263,
        "_internal_originalTime": 1689821673000
    },
    {
        "time": 1689821674000,
        "value": 139.262,
        "_internal_originalTime": 1689821674000
    },
    {
        "time": 1689821675000,
        "value": 139.258,
        "_internal_originalTime": 1689821675000
    },
    {
        "time": 1689821677000,
        "value": 139.253,
        "_internal_originalTime": 1689821677000
    },
    {
        "time": 1689821678000,
        "value": 139.257,
        "_internal_originalTime": 1689821678000
    },
    {
        "time": 1689821680000,
        "value": 139.26,
        "_internal_originalTime": 1689821680000
    },
    {
        "time": 1689821681000,
        "value": 139.263,
        "_internal_originalTime": 1689821681000
    },
    {
        "time": 1689821682000,
        "value": 139.262,
        "_internal_originalTime": 1689821682000
    },
    {
        "time": 1689821683000,
        "value": 139.26,
        "_internal_originalTime": 1689821683000
    },
    {
        "time": 1689821684000,
        "value": 139.26,
        "_internal_originalTime": 1689821684000
    },
    {
        "time": 1689821685000,
        "value": 139.263,
        "_internal_originalTime": 1689821685000
    },
    {
        "time": 1689821686000,
        "value": 139.266,
        "_internal_originalTime": 1689821686000
    },
    {
        "time": 1689821688000,
        "value": 139.26,
        "_internal_originalTime": 1689821688000
    },
    {
        "time": 1689821689000,
        "value": 139.265,
        "_internal_originalTime": 1689821689000
    },
    {
        "time": 1689821690000,
        "value": 139.261,
        "_internal_originalTime": 1689821690000
    },
    {
        "time": 1689821691000,
        "value": 139.262,
        "_internal_originalTime": 1689821691000
    },
    {
        "time": 1689821694000,
        "value": 139.26,
        "_internal_originalTime": 1689821694000
    },
    {
        "time": 1689821695000,
        "value": 139.265,
        "_internal_originalTime": 1689821695000
    },
    {
        "time": 1689821696000,
        "value": 139.265,
        "_internal_originalTime": 1689821696000
    },
    {
        "time": 1689821698000,
        "value": 139.262,
        "_internal_originalTime": 1689821698000
    },
    {
        "time": 1689821700000,
        "value": 139.267,
        "_internal_originalTime": 1689821700000
    },
    {
        "time": 1689821702000,
        "value": 139.264,
        "_internal_originalTime": 1689821702000
    },
    {
        "time": 1689821704000,
        "value": 139.266,
        "_internal_originalTime": 1689821704000
    },
    {
        "time": 1689821705000,
        "value": 139.26,
        "_internal_originalTime": 1689821705000
    },
    {
        "time": 1689821706000,
        "value": 139.265,
        "_internal_originalTime": 1689821706000
    },
    {
        "time": 1689821707000,
        "value": 139.26,
        "_internal_originalTime": 1689821707000
    },
    {
        "time": 1689821708000,
        "value": 139.25,
        "_internal_originalTime": 1689821708000
    },
    {
        "time": 1689821710000,
        "value": 139.251,
        "_internal_originalTime": 1689821710000
    },
    {
        "time": 1689821711000,
        "value": 139.253,
        "_internal_originalTime": 1689821711000
    },
    {
        "time": 1689821713000,
        "value": 139.25,
        "_internal_originalTime": 1689821713000
    },
    {
        "time": 1689821714000,
        "value": 139.254,
        "_internal_originalTime": 1689821714000
    },
    {
        "time": 1689821716000,
        "value": 139.25,
        "_internal_originalTime": 1689821716000
    },
    {
        "time": 1689821718000,
        "value": 139.25,
        "_internal_originalTime": 1689821718000
    },
    {
        "time": 1689821719000,
        "value": 139.252,
        "_internal_originalTime": 1689821719000
    },
    {
        "time": 1689821721000,
        "value": 139.254,
        "_internal_originalTime": 1689821721000
    },
    {
        "time": 1689821723000,
        "value": 139.254,
        "_internal_originalTime": 1689821723000
    },
    {
        "time": 1689821724000,
        "value": 139.256,
        "_internal_originalTime": 1689821724000
    },
    {
        "time": 1689821727000,
        "value": 139.252,
        "_internal_originalTime": 1689821727000
    },
    {
        "time": 1689821730000,
        "value": 139.248,
        "_internal_originalTime": 1689821730000
    },
    {
        "time": 1689821731000,
        "value": 139.257,
        "_internal_originalTime": 1689821731000
    },
    {
        "time": 1689821733000,
        "value": 139.256,
        "_internal_originalTime": 1689821733000
    },
    {
        "time": 1689821736000,
        "value": 139.255,
        "_internal_originalTime": 1689821736000
    },
    {
        "time": 1689821737000,
        "value": 139.255,
        "_internal_originalTime": 1689821737000
    },
    {
        "time": 1689821739000,
        "value": 139.253,
        "_internal_originalTime": 1689821739000
    },
    {
        "time": 1689821740000,
        "value": 139.252,
        "_internal_originalTime": 1689821740000
    },
    {
        "time": 1689821743000,
        "value": 139.252,
        "_internal_originalTime": 1689821743000
    },
    {
        "time": 1689821744000,
        "value": 139.256,
        "_internal_originalTime": 1689821744000
    },
    {
        "time": 1689821747000,
        "value": 139.253,
        "_internal_originalTime": 1689821747000
    },
    {
        "time": 1689821749000,
        "value": 139.258,
        "_internal_originalTime": 1689821749000
    },
    {
        "time": 1689821750000,
        "value": 139.262,
        "_internal_originalTime": 1689821750000
    },
    {
        "time": 1689821752000,
        "value": 139.258,
        "_internal_originalTime": 1689821752000
    },
    {
        "time": 1689821753000,
        "value": 139.258,
        "_internal_originalTime": 1689821753000
    },
    {
        "time": 1689821754000,
        "value": 139.258,
        "_internal_originalTime": 1689821754000
    },
    {
        "time": 1689821756000,
        "value": 139.258,
        "_internal_originalTime": 1689821756000
    },
    {
        "time": 1689821757000,
        "value": 139.258,
        "_internal_originalTime": 1689821757000
    },
    {
        "time": 1689821760000,
        "value": 139.252,
        "_internal_originalTime": 1689821760000
    },
    {
        "time": 1689821761000,
        "value": 139.254,
        "_internal_originalTime": 1689821761000
    },
    {
        "time": 1689821763000,
        "value": 139.255,
        "_internal_originalTime": 1689821763000
    },
    {
        "time": 1689821764000,
        "value": 139.254,
        "_internal_originalTime": 1689821764000
    },
    {
        "time": 1689821766000,
        "value": 139.25,
        "_internal_originalTime": 1689821766000
    },
    {
        "time": 1689821767000,
        "value": 139.256,
        "_internal_originalTime": 1689821767000
    },
    {
        "time": 1689821768000,
        "value": 139.252,
        "_internal_originalTime": 1689821768000
    },
    {
        "time": 1689821769000,
        "value": 139.253,
        "_internal_originalTime": 1689821769000
    },
    {
        "time": 1689821770000,
        "value": 139.252,
        "_internal_originalTime": 1689821770000
    },
    {
        "time": 1689821771000,
        "value": 139.253,
        "_internal_originalTime": 1689821771000
    },
    {
        "time": 1689821772000,
        "value": 139.252,
        "_internal_originalTime": 1689821772000
    },
    {
        "time": 1689821774000,
        "value": 139.257,
        "_internal_originalTime": 1689821774000
    },
    {
        "time": 1689821775000,
        "value": 139.255,
        "_internal_originalTime": 1689821775000
    },
    {
        "time": 1689821776000,
        "value": 139.252,
        "_internal_originalTime": 1689821776000
    },
    {
        "time": 1689821778000,
        "value": 139.252,
        "_internal_originalTime": 1689821778000
    },
    {
        "time": 1689821782000,
        "value": 139.254,
        "_internal_originalTime": 1689821782000
    },
    {
        "time": 1689821783000,
        "value": 139.254,
        "_internal_originalTime": 1689821783000
    },
    {
        "time": 1689821786000,
        "value": 139.255,
        "_internal_originalTime": 1689821786000
    },
    {
        "time": 1689821787000,
        "value": 139.252,
        "_internal_originalTime": 1689821787000
    },
    {
        "time": 1689821788000,
        "value": 139.255,
        "_internal_originalTime": 1689821788000
    },
    {
        "time": 1689821790000,
        "value": 139.251,
        "_internal_originalTime": 1689821790000
    },
    {
        "time": 1689821791000,
        "value": 139.261,
        "_internal_originalTime": 1689821791000
    },
    {
        "time": 1689821792000,
        "value": 139.257,
        "_internal_originalTime": 1689821792000
    },
    {
        "time": 1689821793000,
        "value": 139.262,
        "_internal_originalTime": 1689821793000
    },
    {
        "time": 1689821794000,
        "value": 139.258,
        "_internal_originalTime": 1689821794000
    },
    {
        "time": 1689821795000,
        "value": 139.257,
        "_internal_originalTime": 1689821795000
    },
    {
        "time": 1689821798000,
        "value": 139.251,
        "_internal_originalTime": 1689821798000
    },
    {
        "time": 1689821799000,
        "value": 139.262,
        "_internal_originalTime": 1689821799000
    },
    {
        "time": 1689821800000,
        "value": 139.262,
        "_internal_originalTime": 1689821800000
    },
    {
        "time": 1689821802000,
        "value": 139.264,
        "_internal_originalTime": 1689821802000
    },
    {
        "time": 1689821803000,
        "value": 139.268,
        "_internal_originalTime": 1689821803000
    },
    {
        "time": 1689821804000,
        "value": 139.264,
        "_internal_originalTime": 1689821804000
    },
    {
        "time": 1689821805000,
        "value": 139.266,
        "_internal_originalTime": 1689821805000
    },
    {
        "time": 1689821806000,
        "value": 139.265,
        "_internal_originalTime": 1689821806000
    },
    {
        "time": 1689821808000,
        "value": 139.26,
        "_internal_originalTime": 1689821808000
    },
    {
        "time": 1689821809000,
        "value": 139.261,
        "_internal_originalTime": 1689821809000
    },
    {
        "time": 1689821810000,
        "value": 139.266,
        "_internal_originalTime": 1689821810000
    },
    {
        "time": 1689821812000,
        "value": 139.265,
        "_internal_originalTime": 1689821812000
    },
    {
        "time": 1689821814000,
        "value": 139.267,
        "_internal_originalTime": 1689821814000
    },
    {
        "time": 1689821815000,
        "value": 139.265,
        "_internal_originalTime": 1689821815000
    },
    {
        "time": 1689821817000,
        "value": 139.264,
        "_internal_originalTime": 1689821817000
    },
    {
        "time": 1689821820000,
        "value": 139.264,
        "_internal_originalTime": 1689821820000
    },
    {
        "time": 1689821821000,
        "value": 139.254,
        "_internal_originalTime": 1689821821000
    },
    {
        "time": 1689821822000,
        "value": 139.262,
        "_internal_originalTime": 1689821822000
    },
    {
        "time": 1689821823000,
        "value": 139.256,
        "_internal_originalTime": 1689821823000
    },
    {
        "time": 1689821824000,
        "value": 139.256,
        "_internal_originalTime": 1689821824000
    },
    {
        "time": 1689821826000,
        "value": 139.255,
        "_internal_originalTime": 1689821826000
    },
    {
        "time": 1689821827000,
        "value": 139.254,
        "_internal_originalTime": 1689821827000
    },
    {
        "time": 1689821829000,
        "value": 139.256,
        "_internal_originalTime": 1689821829000
    },
    {
        "time": 1689821832000,
        "value": 139.25,
        "_internal_originalTime": 1689821832000
    },
    {
        "time": 1689821833000,
        "value": 139.25,
        "_internal_originalTime": 1689821833000
    },
    {
        "time": 1689821834000,
        "value": 139.254,
        "_internal_originalTime": 1689821834000
    },
    {
        "time": 1689821836000,
        "value": 139.253,
        "_internal_originalTime": 1689821836000
    },
    {
        "time": 1689821837000,
        "value": 139.255,
        "_internal_originalTime": 1689821837000
    },
    {
        "time": 1689821839000,
        "value": 139.255,
        "_internal_originalTime": 1689821839000
    },
    {
        "time": 1689821844000,
        "value": 139.25,
        "_internal_originalTime": 1689821844000
    },
    {
        "time": 1689821845000,
        "value": 139.25,
        "_internal_originalTime": 1689821845000
    },
    {
        "time": 1689821846000,
        "value": 139.253,
        "_internal_originalTime": 1689821846000
    },
    {
        "time": 1689821850000,
        "value": 139.247,
        "_internal_originalTime": 1689821850000
    },
    {
        "time": 1689821853000,
        "value": 139.258,
        "_internal_originalTime": 1689821853000
    },
    {
        "time": 1689821854000,
        "value": 139.254,
        "_internal_originalTime": 1689821854000
    },
    {
        "time": 1689821855000,
        "value": 139.254,
        "_internal_originalTime": 1689821855000
    },
    {
        "time": 1689821857000,
        "value": 139.254,
        "_internal_originalTime": 1689821857000
    },
    {
        "time": 1689821858000,
        "value": 139.257,
        "_internal_originalTime": 1689821858000
    },
    {
        "time": 1689821859000,
        "value": 139.254,
        "_internal_originalTime": 1689821859000
    },
    {
        "time": 1689821860000,
        "value": 139.256,
        "_internal_originalTime": 1689821860000
    },
    {
        "time": 1689821861000,
        "value": 139.252,
        "_internal_originalTime": 1689821861000
    },
    {
        "time": 1689821862000,
        "value": 139.253,
        "_internal_originalTime": 1689821862000
    },
    {
        "time": 1689821864000,
        "value": 139.26,
        "_internal_originalTime": 1689821864000
    },
    {
        "time": 1689821865000,
        "value": 139.257,
        "_internal_originalTime": 1689821865000
    },
    {
        "time": 1689821868000,
        "value": 139.256,
        "_internal_originalTime": 1689821868000
    },
    {
        "time": 1689821869000,
        "value": 139.256,
        "_internal_originalTime": 1689821869000
    },
    {
        "time": 1689821870000,
        "value": 139.256,
        "_internal_originalTime": 1689821870000
    },
    {
        "time": 1689821871000,
        "value": 139.256,
        "_internal_originalTime": 1689821871000
    },
    {
        "time": 1689821872000,
        "value": 139.257,
        "_internal_originalTime": 1689821872000
    },
    {
        "time": 1689821874000,
        "value": 139.257,
        "_internal_originalTime": 1689821874000
    },
    {
        "time": 1689821875000,
        "value": 139.256,
        "_internal_originalTime": 1689821875000
    },
    {
        "time": 1689821878000,
        "value": 139.252,
        "_internal_originalTime": 1689821878000
    },
    {
        "time": 1689821879000,
        "value": 139.253,
        "_internal_originalTime": 1689821879000
    },
    {
        "time": 1689821880000,
        "value": 139.253,
        "_internal_originalTime": 1689821880000
    },
    {
        "time": 1689821881000,
        "value": 139.253,
        "_internal_originalTime": 1689821881000
    },
    {
        "time": 1689821883000,
        "value": 139.253,
        "_internal_originalTime": 1689821883000
    },
    {
        "time": 1689821884000,
        "value": 139.253,
        "_internal_originalTime": 1689821884000
    },
    {
        "time": 1689821885000,
        "value": 139.25,
        "_internal_originalTime": 1689821885000
    },
    {
        "time": 1689821886000,
        "value": 139.245,
        "_internal_originalTime": 1689821886000
    },
    {
        "time": 1689821888000,
        "value": 139.243,
        "_internal_originalTime": 1689821888000
    },
    {
        "time": 1689821889000,
        "value": 139.24,
        "_internal_originalTime": 1689821889000
    },
    {
        "time": 1689821892000,
        "value": 139.24,
        "_internal_originalTime": 1689821892000
    },
    {
        "time": 1689821894000,
        "value": 139.24,
        "_internal_originalTime": 1689821894000
    },
    {
        "time": 1689821895000,
        "value": 139.24,
        "_internal_originalTime": 1689821895000
    },
    {
        "time": 1689821901000,
        "value": 139.243,
        "_internal_originalTime": 1689821901000
    },
    {
        "time": 1689821902000,
        "value": 139.244,
        "_internal_originalTime": 1689821902000
    },
    {
        "time": 1689821903000,
        "value": 139.24,
        "_internal_originalTime": 1689821903000
    },
    {
        "time": 1689821905000,
        "value": 139.24,
        "_internal_originalTime": 1689821905000
    },
    {
        "time": 1689821906000,
        "value": 139.243,
        "_internal_originalTime": 1689821906000
    },
    {
        "time": 1689821908000,
        "value": 139.232,
        "_internal_originalTime": 1689821908000
    },
    {
        "time": 1689821909000,
        "value": 139.232,
        "_internal_originalTime": 1689821909000
    },
    {
        "time": 1689821910000,
        "value": 139.241,
        "_internal_originalTime": 1689821910000
    },
    {
        "time": 1689821911000,
        "value": 139.241,
        "_internal_originalTime": 1689821911000
    },
    {
        "time": 1689821912000,
        "value": 139.243,
        "_internal_originalTime": 1689821912000
    },
    {
        "time": 1689821914000,
        "value": 139.234,
        "_internal_originalTime": 1689821914000
    },
    {
        "time": 1689821915000,
        "value": 139.237,
        "_internal_originalTime": 1689821915000
    },
    {
        "time": 1689821916000,
        "value": 139.23,
        "_internal_originalTime": 1689821916000
    },
    {
        "time": 1689821918000,
        "value": 139.236,
        "_internal_originalTime": 1689821918000
    },
    {
        "time": 1689821919000,
        "value": 139.232,
        "_internal_originalTime": 1689821919000
    },
    {
        "time": 1689821920000,
        "value": 139.23,
        "_internal_originalTime": 1689821920000
    },
    {
        "time": 1689821921000,
        "value": 139.229,
        "_internal_originalTime": 1689821921000
    },
    {
        "time": 1689821923000,
        "value": 139.227,
        "_internal_originalTime": 1689821923000
    },
    {
        "time": 1689821925000,
        "value": 139.22,
        "_internal_originalTime": 1689821925000
    },
    {
        "time": 1689821926000,
        "value": 139.226,
        "_internal_originalTime": 1689821926000
    },
    {
        "time": 1689821927000,
        "value": 139.221,
        "_internal_originalTime": 1689821927000
    },
    {
        "time": 1689821928000,
        "value": 139.225,
        "_internal_originalTime": 1689821928000
    },
    {
        "time": 1689821930000,
        "value": 139.223,
        "_internal_originalTime": 1689821930000
    },
    {
        "time": 1689821931000,
        "value": 139.224,
        "_internal_originalTime": 1689821931000
    },
    {
        "time": 1689821935000,
        "value": 139.225,
        "_internal_originalTime": 1689821935000
    },
    {
        "time": 1689821936000,
        "value": 139.22,
        "_internal_originalTime": 1689821936000
    },
    {
        "time": 1689821937000,
        "value": 139.229,
        "_internal_originalTime": 1689821937000
    },
    {
        "time": 1689821938000,
        "value": 139.224,
        "_internal_originalTime": 1689821938000
    },
    {
        "time": 1689821940000,
        "value": 139.221,
        "_internal_originalTime": 1689821940000
    },
    {
        "time": 1689821941000,
        "value": 139.226,
        "_internal_originalTime": 1689821941000
    },
    {
        "time": 1689821943000,
        "value": 139.216,
        "_internal_originalTime": 1689821943000
    },
    {
        "time": 1689821944000,
        "value": 139.216,
        "_internal_originalTime": 1689821944000
    },
    {
        "time": 1689821945000,
        "value": 139.213,
        "_internal_originalTime": 1689821945000
    },
    {
        "time": 1689821947000,
        "value": 139.21,
        "_internal_originalTime": 1689821947000
    },
    {
        "time": 1689821948000,
        "value": 139.215,
        "_internal_originalTime": 1689821948000
    },
    {
        "time": 1689821949000,
        "value": 139.215,
        "_internal_originalTime": 1689821949000
    },
    {
        "time": 1689821950000,
        "value": 139.213,
        "_internal_originalTime": 1689821950000
    },
    {
        "time": 1689821952000,
        "value": 139.211,
        "_internal_originalTime": 1689821952000
    },
    {
        "time": 1689821953000,
        "value": 139.212,
        "_internal_originalTime": 1689821953000
    },
    {
        "time": 1689821954000,
        "value": 139.211,
        "_internal_originalTime": 1689821954000
    },
    {
        "time": 1689821955000,
        "value": 139.211,
        "_internal_originalTime": 1689821955000
    },
    {
        "time": 1689821958000,
        "value": 139.213,
        "_internal_originalTime": 1689821958000
    },
    {
        "time": 1689821959000,
        "value": 139.214,
        "_internal_originalTime": 1689821959000
    },
    {
        "time": 1689821960000,
        "value": 139.216,
        "_internal_originalTime": 1689821960000
    },
    {
        "time": 1689821961000,
        "value": 139.216,
        "_internal_originalTime": 1689821961000
    },
    {
        "time": 1689821962000,
        "value": 139.217,
        "_internal_originalTime": 1689821962000
    },
    {
        "time": 1689821963000,
        "value": 139.217,
        "_internal_originalTime": 1689821963000
    },
    {
        "time": 1689821967000,
        "value": 139.217,
        "_internal_originalTime": 1689821967000
    },
    {
        "time": 1689821968000,
        "value": 139.217,
        "_internal_originalTime": 1689821968000
    },
    {
        "time": 1689821969000,
        "value": 139.214,
        "_internal_originalTime": 1689821969000
    },
    {
        "time": 1689821970000,
        "value": 139.217,
        "_internal_originalTime": 1689821970000
    },
    {
        "time": 1689821971000,
        "value": 139.217,
        "_internal_originalTime": 1689821971000
    },
    {
        "time": 1689821973000,
        "value": 139.217,
        "_internal_originalTime": 1689821973000
    },
    {
        "time": 1689821974000,
        "value": 139.209,
        "_internal_originalTime": 1689821974000
    },
    {
        "time": 1689821976000,
        "value": 139.212,
        "_internal_originalTime": 1689821976000
    },
    {
        "time": 1689821978000,
        "value": 139.211,
        "_internal_originalTime": 1689821978000
    },
    {
        "time": 1689821980000,
        "value": 139.204,
        "_internal_originalTime": 1689821980000
    },
    {
        "time": 1689821982000,
        "value": 139.207,
        "_internal_originalTime": 1689821982000
    },
    {
        "time": 1689821983000,
        "value": 139.205,
        "_internal_originalTime": 1689821983000
    },
    {
        "time": 1689821984000,
        "value": 139.207,
        "_internal_originalTime": 1689821984000
    },
    {
        "time": 1689821985000,
        "value": 139.207,
        "_internal_originalTime": 1689821985000
    },
    {
        "time": 1689821986000,
        "value": 139.206,
        "_internal_originalTime": 1689821986000
    },
    {
        "time": 1689821988000,
        "value": 139.207,
        "_internal_originalTime": 1689821988000
    },
    {
        "time": 1689821989000,
        "value": 139.207,
        "_internal_originalTime": 1689821989000
    },
    {
        "time": 1689821992000,
        "value": 139.21,
        "_internal_originalTime": 1689821992000
    },
    {
        "time": 1689821993000,
        "value": 139.212,
        "_internal_originalTime": 1689821993000
    },
    {
        "time": 1689821995000,
        "value": 139.212,
        "_internal_originalTime": 1689821995000
    },
    {
        "time": 1689821997000,
        "value": 139.211,
        "_internal_originalTime": 1689821997000
    },
    {
        "time": 1689821998000,
        "value": 139.211,
        "_internal_originalTime": 1689821998000
    },
    {
        "time": 1689821999000,
        "value": 139.211,
        "_internal_originalTime": 1689821999000
    },
    {
        "time": 1689822000000,
        "value": 139.216,
        "_internal_originalTime": 1689822000000
    },
    {
        "time": 1689822001000,
        "value": 139.22,
        "_internal_originalTime": 1689822001000
    },
    {
        "time": 1689822002000,
        "value": 139.224,
        "_internal_originalTime": 1689822002000
    },
    {
        "time": 1689822003000,
        "value": 139.224,
        "_internal_originalTime": 1689822003000
    },
    {
        "time": 1689822004000,
        "value": 139.227,
        "_internal_originalTime": 1689822004000
    },
    {
        "time": 1689822005000,
        "value": 139.227,
        "_internal_originalTime": 1689822005000
    },
    {
        "time": 1689822006000,
        "value": 139.223,
        "_internal_originalTime": 1689822006000
    },
    {
        "time": 1689822007000,
        "value": 139.225,
        "_internal_originalTime": 1689822007000
    },
    {
        "time": 1689822008000,
        "value": 139.226,
        "_internal_originalTime": 1689822008000
    },
    {
        "time": 1689822009000,
        "value": 139.22,
        "_internal_originalTime": 1689822009000
    },
    {
        "time": 1689822011000,
        "value": 139.226,
        "_internal_originalTime": 1689822011000
    },
    {
        "time": 1689822012000,
        "value": 139.217,
        "_internal_originalTime": 1689822012000
    },
    {
        "time": 1689822013000,
        "value": 139.21,
        "_internal_originalTime": 1689822013000
    },
    {
        "time": 1689822015000,
        "value": 139.207,
        "_internal_originalTime": 1689822015000
    },
    {
        "time": 1689822016000,
        "value": 139.205,
        "_internal_originalTime": 1689822016000
    },
    {
        "time": 1689822017000,
        "value": 139.205,
        "_internal_originalTime": 1689822017000
    },
    {
        "time": 1689822019000,
        "value": 139.206,
        "_internal_originalTime": 1689822019000
    },
    {
        "time": 1689822020000,
        "value": 139.206,
        "_internal_originalTime": 1689822020000
    },
    {
        "time": 1689822021000,
        "value": 139.204,
        "_internal_originalTime": 1689822021000
    },
    {
        "time": 1689822022000,
        "value": 139.208,
        "_internal_originalTime": 1689822022000
    },
    {
        "time": 1689822024000,
        "value": 139.209,
        "_internal_originalTime": 1689822024000
    },
    {
        "time": 1689822025000,
        "value": 139.212,
        "_internal_originalTime": 1689822025000
    },
    {
        "time": 1689822028000,
        "value": 139.212,
        "_internal_originalTime": 1689822028000
    },
    {
        "time": 1689822030000,
        "value": 139.208,
        "_internal_originalTime": 1689822030000
    },
    {
        "time": 1689822032000,
        "value": 139.218,
        "_internal_originalTime": 1689822032000
    },
    {
        "time": 1689822034000,
        "value": 139.219,
        "_internal_originalTime": 1689822034000
    },
    {
        "time": 1689822035000,
        "value": 139.221,
        "_internal_originalTime": 1689822035000
    },
    {
        "time": 1689822036000,
        "value": 139.225,
        "_internal_originalTime": 1689822036000
    },
    {
        "time": 1689822037000,
        "value": 139.224,
        "_internal_originalTime": 1689822037000
    },
    {
        "time": 1689822039000,
        "value": 139.222,
        "_internal_originalTime": 1689822039000
    },
    {
        "time": 1689822040000,
        "value": 139.221,
        "_internal_originalTime": 1689822040000
    },
    {
        "time": 1689822041000,
        "value": 139.216,
        "_internal_originalTime": 1689822041000
    },
    {
        "time": 1689822042000,
        "value": 139.22,
        "_internal_originalTime": 1689822042000
    },
    {
        "time": 1689822044000,
        "value": 139.21,
        "_internal_originalTime": 1689822044000
    },
    {
        "time": 1689822045000,
        "value": 139.208,
        "_internal_originalTime": 1689822045000
    },
    {
        "time": 1689822046000,
        "value": 139.21,
        "_internal_originalTime": 1689822046000
    },
    {
        "time": 1689822047000,
        "value": 139.204,
        "_internal_originalTime": 1689822047000
    },
    {
        "time": 1689822048000,
        "value": 139.206,
        "_internal_originalTime": 1689822048000
    },
    {
        "time": 1689822049000,
        "value": 139.206,
        "_internal_originalTime": 1689822049000
    },
    {
        "time": 1689822052000,
        "value": 139.202,
        "_internal_originalTime": 1689822052000
    },
    {
        "time": 1689822053000,
        "value": 139.207,
        "_internal_originalTime": 1689822053000
    },
    {
        "time": 1689822055000,
        "value": 139.206,
        "_internal_originalTime": 1689822055000
    },
    {
        "time": 1689822059000,
        "value": 139.209,
        "_internal_originalTime": 1689822059000
    },
    {
        "time": 1689822061000,
        "value": 139.208,
        "_internal_originalTime": 1689822061000
    },
    {
        "time": 1689822062000,
        "value": 139.215,
        "_internal_originalTime": 1689822062000
    },
    {
        "time": 1689822064000,
        "value": 139.216,
        "_internal_originalTime": 1689822064000
    },
    {
        "time": 1689822065000,
        "value": 139.21,
        "_internal_originalTime": 1689822065000
    },
    {
        "time": 1689822066000,
        "value": 139.21,
        "_internal_originalTime": 1689822066000
    },
    {
        "time": 1689822067000,
        "value": 139.215,
        "_internal_originalTime": 1689822067000
    },
    {
        "time": 1689822069000,
        "value": 139.215,
        "_internal_originalTime": 1689822069000
    },
    {
        "time": 1689822070000,
        "value": 139.212,
        "_internal_originalTime": 1689822070000
    },
    {
        "time": 1689822071000,
        "value": 139.208,
        "_internal_originalTime": 1689822071000
    },
    {
        "time": 1689822072000,
        "value": 139.21,
        "_internal_originalTime": 1689822072000
    },
    {
        "time": 1689822073000,
        "value": 139.204,
        "_internal_originalTime": 1689822073000
    },
    {
        "time": 1689822075000,
        "value": 139.207,
        "_internal_originalTime": 1689822075000
    },
    {
        "time": 1689822076000,
        "value": 139.212,
        "_internal_originalTime": 1689822076000
    },
    {
        "time": 1689822077000,
        "value": 139.217,
        "_internal_originalTime": 1689822077000
    },
    {
        "time": 1689822080000,
        "value": 139.223,
        "_internal_originalTime": 1689822080000
    },
    {
        "time": 1689822081000,
        "value": 139.222,
        "_internal_originalTime": 1689822081000
    },
    {
        "time": 1689822082000,
        "value": 139.221,
        "_internal_originalTime": 1689822082000
    },
    {
        "time": 1689822083000,
        "value": 139.22,
        "_internal_originalTime": 1689822083000
    },
    {
        "time": 1689822085000,
        "value": 139.225,
        "_internal_originalTime": 1689822085000
    },
    {
        "time": 1689822086000,
        "value": 139.229,
        "_internal_originalTime": 1689822086000
    },
    {
        "time": 1689822087000,
        "value": 139.226,
        "_internal_originalTime": 1689822087000
    },
    {
        "time": 1689822088000,
        "value": 139.226,
        "_internal_originalTime": 1689822088000
    },
    {
        "time": 1689822089000,
        "value": 139.229,
        "_internal_originalTime": 1689822089000
    },
    {
        "time": 1689822091000,
        "value": 139.245,
        "_internal_originalTime": 1689822091000
    },
    {
        "time": 1689822092000,
        "value": 139.256,
        "_internal_originalTime": 1689822092000
    },
    {
        "time": 1689822094000,
        "value": 139.252,
        "_internal_originalTime": 1689822094000
    },
    {
        "time": 1689822095000,
        "value": 139.256,
        "_internal_originalTime": 1689822095000
    },
    {
        "time": 1689822096000,
        "value": 139.253,
        "_internal_originalTime": 1689822096000
    },
    {
        "time": 1689822098000,
        "value": 139.256,
        "_internal_originalTime": 1689822098000
    },
    {
        "time": 1689822099000,
        "value": 139.253,
        "_internal_originalTime": 1689822099000
    },
    {
        "time": 1689822100000,
        "value": 139.256,
        "_internal_originalTime": 1689822100000
    },
    {
        "time": 1689822102000,
        "value": 139.256,
        "_internal_originalTime": 1689822102000
    },
    {
        "time": 1689822103000,
        "value": 139.253,
        "_internal_originalTime": 1689822103000
    },
    {
        "time": 1689822104000,
        "value": 139.256,
        "_internal_originalTime": 1689822104000
    },
    {
        "time": 1689822105000,
        "value": 139.254,
        "_internal_originalTime": 1689822105000
    },
    {
        "time": 1689822106000,
        "value": 139.256,
        "_internal_originalTime": 1689822106000
    },
    {
        "time": 1689822108000,
        "value": 139.251,
        "_internal_originalTime": 1689822108000
    },
    {
        "time": 1689822110000,
        "value": 139.253,
        "_internal_originalTime": 1689822110000
    },
    {
        "time": 1689822111000,
        "value": 139.254,
        "_internal_originalTime": 1689822111000
    },
    {
        "time": 1689822112000,
        "value": 139.253,
        "_internal_originalTime": 1689822112000
    },
    {
        "time": 1689822113000,
        "value": 139.25,
        "_internal_originalTime": 1689822113000
    },
    {
        "time": 1689822115000,
        "value": 139.253,
        "_internal_originalTime": 1689822115000
    },
    {
        "time": 1689822118000,
        "value": 139.255,
        "_internal_originalTime": 1689822118000
    },
    {
        "time": 1689822119000,
        "value": 139.256,
        "_internal_originalTime": 1689822119000
    },
    {
        "time": 1689822120000,
        "value": 139.25,
        "_internal_originalTime": 1689822120000
    },
    {
        "time": 1689822122000,
        "value": 139.253,
        "_internal_originalTime": 1689822122000
    },
    {
        "time": 1689822123000,
        "value": 139.252,
        "_internal_originalTime": 1689822123000
    },
    {
        "time": 1689822124000,
        "value": 139.253,
        "_internal_originalTime": 1689822124000
    },
    {
        "time": 1689822126000,
        "value": 139.252,
        "_internal_originalTime": 1689822126000
    },
    {
        "time": 1689822127000,
        "value": 139.253,
        "_internal_originalTime": 1689822127000
    },
    {
        "time": 1689822128000,
        "value": 139.255,
        "_internal_originalTime": 1689822128000
    },
    {
        "time": 1689822129000,
        "value": 139.253,
        "_internal_originalTime": 1689822129000
    },
    {
        "time": 1689822134000,
        "value": 139.252,
        "_internal_originalTime": 1689822134000
    },
    {
        "time": 1689822136000,
        "value": 139.25,
        "_internal_originalTime": 1689822136000
    },
    {
        "time": 1689822137000,
        "value": 139.255,
        "_internal_originalTime": 1689822137000
    },
    {
        "time": 1689822139000,
        "value": 139.25,
        "_internal_originalTime": 1689822139000
    },
    {
        "time": 1689822141000,
        "value": 139.259,
        "_internal_originalTime": 1689822141000
    },
    {
        "time": 1689822142000,
        "value": 139.264,
        "_internal_originalTime": 1689822142000
    },
    {
        "time": 1689822143000,
        "value": 139.264,
        "_internal_originalTime": 1689822143000
    },
    {
        "time": 1689822145000,
        "value": 139.26,
        "_internal_originalTime": 1689822145000
    },
    {
        "time": 1689822146000,
        "value": 139.269,
        "_internal_originalTime": 1689822146000
    },
    {
        "time": 1689822147000,
        "value": 139.273,
        "_internal_originalTime": 1689822147000
    },
    {
        "time": 1689822150000,
        "value": 139.269,
        "_internal_originalTime": 1689822150000
    },
    {
        "time": 1689822151000,
        "value": 139.265,
        "_internal_originalTime": 1689822151000
    },
    {
        "time": 1689822153000,
        "value": 139.267,
        "_internal_originalTime": 1689822153000
    },
    {
        "time": 1689822154000,
        "value": 139.264,
        "_internal_originalTime": 1689822154000
    },
    {
        "time": 1689822156000,
        "value": 139.262,
        "_internal_originalTime": 1689822156000
    },
    {
        "time": 1689822157000,
        "value": 139.262,
        "_internal_originalTime": 1689822157000
    },
    {
        "time": 1689822159000,
        "value": 139.263,
        "_internal_originalTime": 1689822159000
    },
    {
        "time": 1689822161000,
        "value": 139.262,
        "_internal_originalTime": 1689822161000
    },
    {
        "time": 1689822163000,
        "value": 139.262,
        "_internal_originalTime": 1689822163000
    },
    {
        "time": 1689822164000,
        "value": 139.264,
        "_internal_originalTime": 1689822164000
    },
    {
        "time": 1689822165000,
        "value": 139.264,
        "_internal_originalTime": 1689822165000
    },
    {
        "time": 1689822167000,
        "value": 139.262,
        "_internal_originalTime": 1689822167000
    },
    {
        "time": 1689822168000,
        "value": 139.26,
        "_internal_originalTime": 1689822168000
    },
    {
        "time": 1689822169000,
        "value": 139.26,
        "_internal_originalTime": 1689822169000
    },
    {
        "time": 1689822170000,
        "value": 139.26,
        "_internal_originalTime": 1689822170000
    },
    {
        "time": 1689822172000,
        "value": 139.26,
        "_internal_originalTime": 1689822172000
    },
    {
        "time": 1689822173000,
        "value": 139.266,
        "_internal_originalTime": 1689822173000
    },
    {
        "time": 1689822175000,
        "value": 139.273,
        "_internal_originalTime": 1689822175000
    },
    {
        "time": 1689822176000,
        "value": 139.27,
        "_internal_originalTime": 1689822176000
    },
    {
        "time": 1689822178000,
        "value": 139.266,
        "_internal_originalTime": 1689822178000
    },
    {
        "time": 1689822179000,
        "value": 139.265,
        "_internal_originalTime": 1689822179000
    },
    {
        "time": 1689822180000,
        "value": 139.262,
        "_internal_originalTime": 1689822180000
    },
    {
        "time": 1689822181000,
        "value": 139.27,
        "_internal_originalTime": 1689822181000
    },
    {
        "time": 1689822182000,
        "value": 139.266,
        "_internal_originalTime": 1689822182000
    },
    {
        "time": 1689822185000,
        "value": 139.272,
        "_internal_originalTime": 1689822185000
    },
    {
        "time": 1689822186000,
        "value": 139.273,
        "_internal_originalTime": 1689822186000
    },
    {
        "time": 1689822187000,
        "value": 139.269,
        "_internal_originalTime": 1689822187000
    },
    {
        "time": 1689822189000,
        "value": 139.273,
        "_internal_originalTime": 1689822189000
    },
    {
        "time": 1689822190000,
        "value": 139.274,
        "_internal_originalTime": 1689822190000
    },
    {
        "time": 1689822191000,
        "value": 139.274,
        "_internal_originalTime": 1689822191000
    },
    {
        "time": 1689822194000,
        "value": 139.273,
        "_internal_originalTime": 1689822194000
    },
    {
        "time": 1689822195000,
        "value": 139.268,
        "_internal_originalTime": 1689822195000
    },
    {
        "time": 1689822196000,
        "value": 139.269,
        "_internal_originalTime": 1689822196000
    },
    {
        "time": 1689822199000,
        "value": 139.268,
        "_internal_originalTime": 1689822199000
    },
    {
        "time": 1689822200000,
        "value": 139.268,
        "_internal_originalTime": 1689822200000
    },
    {
        "time": 1689822201000,
        "value": 139.268,
        "_internal_originalTime": 1689822201000
    },
    {
        "time": 1689822205000,
        "value": 139.268,
        "_internal_originalTime": 1689822205000
    },
    {
        "time": 1689822207000,
        "value": 139.274,
        "_internal_originalTime": 1689822207000
    },
    {
        "time": 1689822210000,
        "value": 139.268,
        "_internal_originalTime": 1689822210000
    },
    {
        "time": 1689822215000,
        "value": 139.262,
        "_internal_originalTime": 1689822215000
    },
    {
        "time": 1689822216000,
        "value": 139.259,
        "_internal_originalTime": 1689822216000
    },
    {
        "time": 1689822217000,
        "value": 139.258,
        "_internal_originalTime": 1689822217000
    },
    {
        "time": 1689822219000,
        "value": 139.255,
        "_internal_originalTime": 1689822219000
    },
    {
        "time": 1689822220000,
        "value": 139.258,
        "_internal_originalTime": 1689822220000
    },
    {
        "time": 1689822221000,
        "value": 139.26,
        "_internal_originalTime": 1689822221000
    },
    {
        "time": 1689822223000,
        "value": 139.26,
        "_internal_originalTime": 1689822223000
    },
    {
        "time": 1689822225000,
        "value": 139.26,
        "_internal_originalTime": 1689822225000
    },
    {
        "time": 1689822226000,
        "value": 139.259,
        "_internal_originalTime": 1689822226000
    },
    {
        "time": 1689822227000,
        "value": 139.262,
        "_internal_originalTime": 1689822227000
    },
    {
        "time": 1689822232000,
        "value": 139.26,
        "_internal_originalTime": 1689822232000
    },
    {
        "time": 1689822233000,
        "value": 139.267,
        "_internal_originalTime": 1689822233000
    },
    {
        "time": 1689822234000,
        "value": 139.264,
        "_internal_originalTime": 1689822234000
    },
    {
        "time": 1689822235000,
        "value": 139.261,
        "_internal_originalTime": 1689822235000
    },
    {
        "time": 1689822236000,
        "value": 139.26,
        "_internal_originalTime": 1689822236000
    },
    {
        "time": 1689822238000,
        "value": 139.265,
        "_internal_originalTime": 1689822238000
    },
    {
        "time": 1689822240000,
        "value": 139.261,
        "_internal_originalTime": 1689822240000
    },
    {
        "time": 1689822241000,
        "value": 139.261,
        "_internal_originalTime": 1689822241000
    },
    {
        "time": 1689822242000,
        "value": 139.265,
        "_internal_originalTime": 1689822242000
    },
    {
        "time": 1689822243000,
        "value": 139.26,
        "_internal_originalTime": 1689822243000
    },
    {
        "time": 1689822244000,
        "value": 139.256,
        "_internal_originalTime": 1689822244000
    },
    {
        "time": 1689822245000,
        "value": 139.256,
        "_internal_originalTime": 1689822245000
    },
    {
        "time": 1689822247000,
        "value": 139.256,
        "_internal_originalTime": 1689822247000
    },
    {
        "time": 1689822248000,
        "value": 139.258,
        "_internal_originalTime": 1689822248000
    },
    {
        "time": 1689822249000,
        "value": 139.256,
        "_internal_originalTime": 1689822249000
    },
    {
        "time": 1689822250000,
        "value": 139.25,
        "_internal_originalTime": 1689822250000
    },
    {
        "time": 1689822252000,
        "value": 139.255,
        "_internal_originalTime": 1689822252000
    },
    {
        "time": 1689822254000,
        "value": 139.26,
        "_internal_originalTime": 1689822254000
    },
    {
        "time": 1689822256000,
        "value": 139.274,
        "_internal_originalTime": 1689822256000
    },
    {
        "time": 1689822257000,
        "value": 139.273,
        "_internal_originalTime": 1689822257000
    },
    {
        "time": 1689822258000,
        "value": 139.262,
        "_internal_originalTime": 1689822258000
    },
    {
        "time": 1689822260000,
        "value": 139.262,
        "_internal_originalTime": 1689822260000
    },
    {
        "time": 1689822261000,
        "value": 139.269,
        "_internal_originalTime": 1689822261000
    },
    {
        "time": 1689822262000,
        "value": 139.268,
        "_internal_originalTime": 1689822262000
    },
    {
        "time": 1689822263000,
        "value": 139.267,
        "_internal_originalTime": 1689822263000
    },
    {
        "time": 1689822264000,
        "value": 139.272,
        "_internal_originalTime": 1689822264000
    },
    {
        "time": 1689822265000,
        "value": 139.272,
        "_internal_originalTime": 1689822265000
    },
    {
        "time": 1689822266000,
        "value": 139.271,
        "_internal_originalTime": 1689822266000
    },
    {
        "time": 1689822268000,
        "value": 139.268,
        "_internal_originalTime": 1689822268000
    },
    {
        "time": 1689822269000,
        "value": 139.27,
        "_internal_originalTime": 1689822269000
    },
    {
        "time": 1689822270000,
        "value": 139.268,
        "_internal_originalTime": 1689822270000
    },
    {
        "time": 1689822271000,
        "value": 139.281,
        "_internal_originalTime": 1689822271000
    },
    {
        "time": 1689822272000,
        "value": 139.278,
        "_internal_originalTime": 1689822272000
    },
    {
        "time": 1689822274000,
        "value": 139.279,
        "_internal_originalTime": 1689822274000
    },
    {
        "time": 1689822278000,
        "value": 139.279,
        "_internal_originalTime": 1689822278000
    },
    {
        "time": 1689822279000,
        "value": 139.278,
        "_internal_originalTime": 1689822279000
    },
    {
        "time": 1689822280000,
        "value": 139.281,
        "_internal_originalTime": 1689822280000
    },
    {
        "time": 1689822281000,
        "value": 139.278,
        "_internal_originalTime": 1689822281000
    },
    {
        "time": 1689822283000,
        "value": 139.28,
        "_internal_originalTime": 1689822283000
    },
    {
        "time": 1689822284000,
        "value": 139.283,
        "_internal_originalTime": 1689822284000
    },
    {
        "time": 1689822288000,
        "value": 139.28,
        "_internal_originalTime": 1689822288000
    },
    {
        "time": 1689822289000,
        "value": 139.28,
        "_internal_originalTime": 1689822289000
    },
    {
        "time": 1689822291000,
        "value": 139.276,
        "_internal_originalTime": 1689822291000
    },
    {
        "time": 1689822292000,
        "value": 139.277,
        "_internal_originalTime": 1689822292000
    },
    {
        "time": 1689822294000,
        "value": 139.276,
        "_internal_originalTime": 1689822294000
    },
    {
        "time": 1689822296000,
        "value": 139.278,
        "_internal_originalTime": 1689822296000
    },
    {
        "time": 1689822297000,
        "value": 139.278,
        "_internal_originalTime": 1689822297000
    },
    {
        "time": 1689822299000,
        "value": 139.279,
        "_internal_originalTime": 1689822299000
    },
    {
        "time": 1689822300000,
        "value": 139.283,
        "_internal_originalTime": 1689822300000
    },
    {
        "time": 1689822301000,
        "value": 139.281,
        "_internal_originalTime": 1689822301000
    },
    {
        "time": 1689822303000,
        "value": 139.278,
        "_internal_originalTime": 1689822303000
    },
    {
        "time": 1689822304000,
        "value": 139.279,
        "_internal_originalTime": 1689822304000
    },
    {
        "time": 1689822305000,
        "value": 139.278,
        "_internal_originalTime": 1689822305000
    },
    {
        "time": 1689822306000,
        "value": 139.279,
        "_internal_originalTime": 1689822306000
    },
    {
        "time": 1689822308000,
        "value": 139.283,
        "_internal_originalTime": 1689822308000
    },
    {
        "time": 1689822309000,
        "value": 139.281,
        "_internal_originalTime": 1689822309000
    },
    {
        "time": 1689822310000,
        "value": 139.279,
        "_internal_originalTime": 1689822310000
    },
    {
        "time": 1689822311000,
        "value": 139.283,
        "_internal_originalTime": 1689822311000
    },
    {
        "time": 1689822313000,
        "value": 139.281,
        "_internal_originalTime": 1689822313000
    },
    {
        "time": 1689822314000,
        "value": 139.285,
        "_internal_originalTime": 1689822314000
    },
    {
        "time": 1689822317000,
        "value": 139.28,
        "_internal_originalTime": 1689822317000
    },
    {
        "time": 1689822318000,
        "value": 139.283,
        "_internal_originalTime": 1689822318000
    },
    {
        "time": 1689822321000,
        "value": 139.28,
        "_internal_originalTime": 1689822321000
    },
    {
        "time": 1689822322000,
        "value": 139.278,
        "_internal_originalTime": 1689822322000
    },
    {
        "time": 1689822323000,
        "value": 139.281,
        "_internal_originalTime": 1689822323000
    },
    {
        "time": 1689822325000,
        "value": 139.278,
        "_internal_originalTime": 1689822325000
    },
    {
        "time": 1689822327000,
        "value": 139.283,
        "_internal_originalTime": 1689822327000
    },
    {
        "time": 1689822330000,
        "value": 139.283,
        "_internal_originalTime": 1689822330000
    },
    {
        "time": 1689822331000,
        "value": 139.283,
        "_internal_originalTime": 1689822331000
    },
    {
        "time": 1689822333000,
        "value": 139.288,
        "_internal_originalTime": 1689822333000
    },
    {
        "time": 1689822334000,
        "value": 139.288,
        "_internal_originalTime": 1689822334000
    },
    {
        "time": 1689822336000,
        "value": 139.287,
        "_internal_originalTime": 1689822336000
    },
    {
        "time": 1689822338000,
        "value": 139.283,
        "_internal_originalTime": 1689822338000
    },
    {
        "time": 1689822340000,
        "value": 139.281,
        "_internal_originalTime": 1689822340000
    },
    {
        "time": 1689822342000,
        "value": 139.28,
        "_internal_originalTime": 1689822342000
    },
    {
        "time": 1689822343000,
        "value": 139.28,
        "_internal_originalTime": 1689822343000
    },
    {
        "time": 1689822344000,
        "value": 139.272,
        "_internal_originalTime": 1689822344000
    },
    {
        "time": 1689822345000,
        "value": 139.274,
        "_internal_originalTime": 1689822345000
    },
    {
        "time": 1689822346000,
        "value": 139.277,
        "_internal_originalTime": 1689822346000
    },
    {
        "time": 1689822347000,
        "value": 139.272,
        "_internal_originalTime": 1689822347000
    },
    {
        "time": 1689822348000,
        "value": 139.271,
        "_internal_originalTime": 1689822348000
    },
    {
        "time": 1689822349000,
        "value": 139.271,
        "_internal_originalTime": 1689822349000
    },
    {
        "time": 1689822350000,
        "value": 139.268,
        "_internal_originalTime": 1689822350000
    },
    {
        "time": 1689822352000,
        "value": 139.268,
        "_internal_originalTime": 1689822352000
    },
    {
        "time": 1689822353000,
        "value": 139.268,
        "_internal_originalTime": 1689822353000
    },
    {
        "time": 1689822355000,
        "value": 139.268,
        "_internal_originalTime": 1689822355000
    },
    {
        "time": 1689822356000,
        "value": 139.268,
        "_internal_originalTime": 1689822356000
    },
    {
        "time": 1689822358000,
        "value": 139.266,
        "_internal_originalTime": 1689822358000
    },
    {
        "time": 1689822359000,
        "value": 139.26,
        "_internal_originalTime": 1689822359000
    },
    {
        "time": 1689822360000,
        "value": 139.26,
        "_internal_originalTime": 1689822360000
    },
    {
        "time": 1689822361000,
        "value": 139.262,
        "_internal_originalTime": 1689822361000
    },
    {
        "time": 1689822362000,
        "value": 139.254,
        "_internal_originalTime": 1689822362000
    },
    {
        "time": 1689822363000,
        "value": 139.255,
        "_internal_originalTime": 1689822363000
    },
    {
        "time": 1689822364000,
        "value": 139.255,
        "_internal_originalTime": 1689822364000
    },
    {
        "time": 1689822366000,
        "value": 139.254,
        "_internal_originalTime": 1689822366000
    },
    {
        "time": 1689822368000,
        "value": 139.25,
        "_internal_originalTime": 1689822368000
    },
    {
        "time": 1689822370000,
        "value": 139.254,
        "_internal_originalTime": 1689822370000
    },
    {
        "time": 1689822371000,
        "value": 139.254,
        "_internal_originalTime": 1689822371000
    },
    {
        "time": 1689822372000,
        "value": 139.256,
        "_internal_originalTime": 1689822372000
    },
    {
        "time": 1689822373000,
        "value": 139.256,
        "_internal_originalTime": 1689822373000
    },
    {
        "time": 1689822374000,
        "value": 139.258,
        "_internal_originalTime": 1689822374000
    },
    {
        "time": 1689822375000,
        "value": 139.254,
        "_internal_originalTime": 1689822375000
    },
    {
        "time": 1689822377000,
        "value": 139.255,
        "_internal_originalTime": 1689822377000
    },
    {
        "time": 1689822378000,
        "value": 139.255,
        "_internal_originalTime": 1689822378000
    },
    {
        "time": 1689822379000,
        "value": 139.252,
        "_internal_originalTime": 1689822379000
    },
    {
        "time": 1689822381000,
        "value": 139.25,
        "_internal_originalTime": 1689822381000
    },
    {
        "time": 1689822382000,
        "value": 139.251,
        "_internal_originalTime": 1689822382000
    },
    {
        "time": 1689822384000,
        "value": 139.254,
        "_internal_originalTime": 1689822384000
    },
    {
        "time": 1689822386000,
        "value": 139.25,
        "_internal_originalTime": 1689822386000
    },
    {
        "time": 1689822387000,
        "value": 139.25,
        "_internal_originalTime": 1689822387000
    },
    {
        "time": 1689822390000,
        "value": 139.251,
        "_internal_originalTime": 1689822390000
    },
    {
        "time": 1689822391000,
        "value": 139.256,
        "_internal_originalTime": 1689822391000
    },
    {
        "time": 1689822393000,
        "value": 139.251,
        "_internal_originalTime": 1689822393000
    },
    {
        "time": 1689822398000,
        "value": 139.253,
        "_internal_originalTime": 1689822398000
    },
    {
        "time": 1689822399000,
        "value": 139.25,
        "_internal_originalTime": 1689822399000
    },
    {
        "time": 1689822400000,
        "value": 139.253,
        "_internal_originalTime": 1689822400000
    },
    {
        "time": 1689822401000,
        "value": 139.255,
        "_internal_originalTime": 1689822401000
    },
    {
        "time": 1689822403000,
        "value": 139.253,
        "_internal_originalTime": 1689822403000
    },
    {
        "time": 1689822404000,
        "value": 139.253,
        "_internal_originalTime": 1689822404000
    },
    {
        "time": 1689822406000,
        "value": 139.253,
        "_internal_originalTime": 1689822406000
    },
    {
        "time": 1689822408000,
        "value": 139.249,
        "_internal_originalTime": 1689822408000
    },
    {
        "time": 1689822409000,
        "value": 139.248,
        "_internal_originalTime": 1689822409000
    },
    {
        "time": 1689822411000,
        "value": 139.246,
        "_internal_originalTime": 1689822411000
    },
    {
        "time": 1689822412000,
        "value": 139.244,
        "_internal_originalTime": 1689822412000
    },
    {
        "time": 1689822415000,
        "value": 139.245,
        "_internal_originalTime": 1689822415000
    },
    {
        "time": 1689822418000,
        "value": 139.24,
        "_internal_originalTime": 1689822418000
    },
    {
        "time": 1689822420000,
        "value": 139.244,
        "_internal_originalTime": 1689822420000
    },
    {
        "time": 1689822421000,
        "value": 139.24,
        "_internal_originalTime": 1689822421000
    },
    {
        "time": 1689822422000,
        "value": 139.234,
        "_internal_originalTime": 1689822422000
    },
    {
        "time": 1689822424000,
        "value": 139.237,
        "_internal_originalTime": 1689822424000
    },
    {
        "time": 1689822425000,
        "value": 139.237,
        "_internal_originalTime": 1689822425000
    },
    {
        "time": 1689822426000,
        "value": 139.236,
        "_internal_originalTime": 1689822426000
    },
    {
        "time": 1689822427000,
        "value": 139.242,
        "_internal_originalTime": 1689822427000
    },
    {
        "time": 1689822428000,
        "value": 139.243,
        "_internal_originalTime": 1689822428000
    },
    {
        "time": 1689822429000,
        "value": 139.242,
        "_internal_originalTime": 1689822429000
    },
    {
        "time": 1689822430000,
        "value": 139.246,
        "_internal_originalTime": 1689822430000
    },
    {
        "time": 1689822433000,
        "value": 139.247,
        "_internal_originalTime": 1689822433000
    },
    {
        "time": 1689822434000,
        "value": 139.242,
        "_internal_originalTime": 1689822434000
    },
    {
        "time": 1689822437000,
        "value": 139.246,
        "_internal_originalTime": 1689822437000
    },
    {
        "time": 1689822440000,
        "value": 139.246,
        "_internal_originalTime": 1689822440000
    },
    {
        "time": 1689822441000,
        "value": 139.232,
        "_internal_originalTime": 1689822441000
    },
    {
        "time": 1689822442000,
        "value": 139.232,
        "_internal_originalTime": 1689822442000
    },
    {
        "time": 1689822444000,
        "value": 139.234,
        "_internal_originalTime": 1689822444000
    },
    {
        "time": 1689822448000,
        "value": 139.232,
        "_internal_originalTime": 1689822448000
    },
    {
        "time": 1689822449000,
        "value": 139.231,
        "_internal_originalTime": 1689822449000
    },
    {
        "time": 1689822450000,
        "value": 139.232,
        "_internal_originalTime": 1689822450000
    },
    {
        "time": 1689822451000,
        "value": 139.232,
        "_internal_originalTime": 1689822451000
    },
    {
        "time": 1689822453000,
        "value": 139.234,
        "_internal_originalTime": 1689822453000
    },
    {
        "time": 1689822454000,
        "value": 139.237,
        "_internal_originalTime": 1689822454000
    },
    {
        "time": 1689822455000,
        "value": 139.238,
        "_internal_originalTime": 1689822455000
    },
    {
        "time": 1689822456000,
        "value": 139.237,
        "_internal_originalTime": 1689822456000
    },
    {
        "time": 1689822458000,
        "value": 139.235,
        "_internal_originalTime": 1689822458000
    },
    {
        "time": 1689822459000,
        "value": 139.234,
        "_internal_originalTime": 1689822459000
    },
    {
        "time": 1689822460000,
        "value": 139.235,
        "_internal_originalTime": 1689822460000
    },
    {
        "time": 1689822461000,
        "value": 139.237,
        "_internal_originalTime": 1689822461000
    },
    {
        "time": 1689822465000,
        "value": 139.233,
        "_internal_originalTime": 1689822465000
    },
    {
        "time": 1689822466000,
        "value": 139.236,
        "_internal_originalTime": 1689822466000
    },
    {
        "time": 1689822467000,
        "value": 139.232,
        "_internal_originalTime": 1689822467000
    },
    {
        "time": 1689822469000,
        "value": 139.238,
        "_internal_originalTime": 1689822469000
    },
    {
        "time": 1689822471000,
        "value": 139.238,
        "_internal_originalTime": 1689822471000
    },
    {
        "time": 1689822474000,
        "value": 139.235,
        "_internal_originalTime": 1689822474000
    },
    {
        "time": 1689822475000,
        "value": 139.235,
        "_internal_originalTime": 1689822475000
    },
    {
        "time": 1689822477000,
        "value": 139.236,
        "_internal_originalTime": 1689822477000
    },
    {
        "time": 1689822478000,
        "value": 139.234,
        "_internal_originalTime": 1689822478000
    },
    {
        "time": 1689822479000,
        "value": 139.244,
        "_internal_originalTime": 1689822479000
    },
    {
        "time": 1689822480000,
        "value": 139.248,
        "_internal_originalTime": 1689822480000
    },
    {
        "time": 1689822481000,
        "value": 139.246,
        "_internal_originalTime": 1689822481000
    },
    {
        "time": 1689822482000,
        "value": 139.252,
        "_internal_originalTime": 1689822482000
    },
    {
        "time": 1689822483000,
        "value": 139.248,
        "_internal_originalTime": 1689822483000
    },
    {
        "time": 1689822485000,
        "value": 139.254,
        "_internal_originalTime": 1689822485000
    },
    {
        "time": 1689822486000,
        "value": 139.252,
        "_internal_originalTime": 1689822486000
    },
    {
        "time": 1689822488000,
        "value": 139.251,
        "_internal_originalTime": 1689822488000
    },
    {
        "time": 1689822489000,
        "value": 139.252,
        "_internal_originalTime": 1689822489000
    },
    {
        "time": 1689822490000,
        "value": 139.255,
        "_internal_originalTime": 1689822490000
    },
    {
        "time": 1689822492000,
        "value": 139.256,
        "_internal_originalTime": 1689822492000
    },
    {
        "time": 1689822493000,
        "value": 139.252,
        "_internal_originalTime": 1689822493000
    },
    {
        "time": 1689822495000,
        "value": 139.254,
        "_internal_originalTime": 1689822495000
    },
    {
        "time": 1689822496000,
        "value": 139.255,
        "_internal_originalTime": 1689822496000
    },
    {
        "time": 1689822497000,
        "value": 139.253,
        "_internal_originalTime": 1689822497000
    },
    {
        "time": 1689822498000,
        "value": 139.25,
        "_internal_originalTime": 1689822498000
    },
    {
        "time": 1689822499000,
        "value": 139.254,
        "_internal_originalTime": 1689822499000
    },
    {
        "time": 1689822501000,
        "value": 139.25,
        "_internal_originalTime": 1689822501000
    },
    {
        "time": 1689822502000,
        "value": 139.256,
        "_internal_originalTime": 1689822502000
    },
    {
        "time": 1689822504000,
        "value": 139.255,
        "_internal_originalTime": 1689822504000
    },
    {
        "time": 1689822506000,
        "value": 139.253,
        "_internal_originalTime": 1689822506000
    },
    {
        "time": 1689822509000,
        "value": 139.254,
        "_internal_originalTime": 1689822509000
    },
    {
        "time": 1689822510000,
        "value": 139.249,
        "_internal_originalTime": 1689822510000
    },
    {
        "time": 1689822511000,
        "value": 139.248,
        "_internal_originalTime": 1689822511000
    },
    {
        "time": 1689822513000,
        "value": 139.245,
        "_internal_originalTime": 1689822513000
    },
    {
        "time": 1689822516000,
        "value": 139.244,
        "_internal_originalTime": 1689822516000
    },
    {
        "time": 1689822517000,
        "value": 139.24,
        "_internal_originalTime": 1689822517000
    },
    {
        "time": 1689822519000,
        "value": 139.241,
        "_internal_originalTime": 1689822519000
    },
    {
        "time": 1689822520000,
        "value": 139.24,
        "_internal_originalTime": 1689822520000
    },
    {
        "time": 1689822521000,
        "value": 139.232,
        "_internal_originalTime": 1689822521000
    },
    {
        "time": 1689822523000,
        "value": 139.243,
        "_internal_originalTime": 1689822523000
    },
    {
        "time": 1689822525000,
        "value": 139.243,
        "_internal_originalTime": 1689822525000
    },
    {
        "time": 1689822526000,
        "value": 139.24,
        "_internal_originalTime": 1689822526000
    },
    {
        "time": 1689822527000,
        "value": 139.244,
        "_internal_originalTime": 1689822527000
    },
    {
        "time": 1689822528000,
        "value": 139.24,
        "_internal_originalTime": 1689822528000
    },
    {
        "time": 1689822529000,
        "value": 139.246,
        "_internal_originalTime": 1689822529000
    },
    {
        "time": 1689822530000,
        "value": 139.242,
        "_internal_originalTime": 1689822530000
    },
    {
        "time": 1689822533000,
        "value": 139.242,
        "_internal_originalTime": 1689822533000
    },
    {
        "time": 1689822534000,
        "value": 139.242,
        "_internal_originalTime": 1689822534000
    },
    {
        "time": 1689822535000,
        "value": 139.245,
        "_internal_originalTime": 1689822535000
    },
    {
        "time": 1689822536000,
        "value": 139.242,
        "_internal_originalTime": 1689822536000
    },
    {
        "time": 1689822539000,
        "value": 139.242,
        "_internal_originalTime": 1689822539000
    },
    {
        "time": 1689822540000,
        "value": 139.242,
        "_internal_originalTime": 1689822540000
    },
    {
        "time": 1689822541000,
        "value": 139.246,
        "_internal_originalTime": 1689822541000
    },
    {
        "time": 1689822542000,
        "value": 139.24,
        "_internal_originalTime": 1689822542000
    },
    {
        "time": 1689822545000,
        "value": 139.246,
        "_internal_originalTime": 1689822545000
    },
    {
        "time": 1689822547000,
        "value": 139.248,
        "_internal_originalTime": 1689822547000
    },
    {
        "time": 1689822549000,
        "value": 139.251,
        "_internal_originalTime": 1689822549000
    },
    {
        "time": 1689822551000,
        "value": 139.252,
        "_internal_originalTime": 1689822551000
    },
    {
        "time": 1689822552000,
        "value": 139.252,
        "_internal_originalTime": 1689822552000
    },
    {
        "time": 1689822554000,
        "value": 139.252,
        "_internal_originalTime": 1689822554000
    },
    {
        "time": 1689822556000,
        "value": 139.254,
        "_internal_originalTime": 1689822556000
    },
    {
        "time": 1689822557000,
        "value": 139.255,
        "_internal_originalTime": 1689822557000
    },
    {
        "time": 1689822559000,
        "value": 139.255,
        "_internal_originalTime": 1689822559000
    },
    {
        "time": 1689822561000,
        "value": 139.255,
        "_internal_originalTime": 1689822561000
    },
    {
        "time": 1689822566000,
        "value": 139.254,
        "_internal_originalTime": 1689822566000
    },
    {
        "time": 1689822570000,
        "value": 139.249,
        "_internal_originalTime": 1689822570000
    },
    {
        "time": 1689822571000,
        "value": 139.252,
        "_internal_originalTime": 1689822571000
    },
    {
        "time": 1689822573000,
        "value": 139.25,
        "_internal_originalTime": 1689822573000
    },
    {
        "time": 1689822576000,
        "value": 139.25,
        "_internal_originalTime": 1689822576000
    },
    {
        "time": 1689822577000,
        "value": 139.25,
        "_internal_originalTime": 1689822577000
    },
    {
        "time": 1689822579000,
        "value": 139.251,
        "_internal_originalTime": 1689822579000
    },
    {
        "time": 1689822580000,
        "value": 139.248,
        "_internal_originalTime": 1689822580000
    },
    {
        "time": 1689822581000,
        "value": 139.248,
        "_internal_originalTime": 1689822581000
    },
    {
        "time": 1689822585000,
        "value": 139.247,
        "_internal_originalTime": 1689822585000
    },
    {
        "time": 1689822586000,
        "value": 139.249,
        "_internal_originalTime": 1689822586000
    },
    {
        "time": 1689822587000,
        "value": 139.249,
        "_internal_originalTime": 1689822587000
    },
    {
        "time": 1689822588000,
        "value": 139.248,
        "_internal_originalTime": 1689822588000
    },
    {
        "time": 1689822590000,
        "value": 139.245,
        "_internal_originalTime": 1689822590000
    },
    {
        "time": 1689822592000,
        "value": 139.242,
        "_internal_originalTime": 1689822592000
    },
    {
        "time": 1689822594000,
        "value": 139.242,
        "_internal_originalTime": 1689822594000
    },
    {
        "time": 1689822595000,
        "value": 139.242,
        "_internal_originalTime": 1689822595000
    },
    {
        "time": 1689822596000,
        "value": 139.247,
        "_internal_originalTime": 1689822596000
    },
    {
        "time": 1689822598000,
        "value": 139.244,
        "_internal_originalTime": 1689822598000
    },
    {
        "time": 1689822599000,
        "value": 139.242,
        "_internal_originalTime": 1689822599000
    },
    {
        "time": 1689822600000,
        "value": 139.242,
        "_internal_originalTime": 1689822600000
    },
    {
        "time": 1689822602000,
        "value": 139.244,
        "_internal_originalTime": 1689822602000
    },
    {
        "time": 1689822604000,
        "value": 139.242,
        "_internal_originalTime": 1689822604000
    },
    {
        "time": 1689822606000,
        "value": 139.244,
        "_internal_originalTime": 1689822606000
    },
    {
        "time": 1689822607000,
        "value": 139.243,
        "_internal_originalTime": 1689822607000
    },
    {
        "time": 1689822609000,
        "value": 139.242,
        "_internal_originalTime": 1689822609000
    },
    {
        "time": 1689822611000,
        "value": 139.238,
        "_internal_originalTime": 1689822611000
    },
    {
        "time": 1689822612000,
        "value": 139.244,
        "_internal_originalTime": 1689822612000
    },
    {
        "time": 1689822613000,
        "value": 139.238,
        "_internal_originalTime": 1689822613000
    },
    {
        "time": 1689822614000,
        "value": 139.242,
        "_internal_originalTime": 1689822614000
    },
    {
        "time": 1689822617000,
        "value": 139.238,
        "_internal_originalTime": 1689822617000
    },
    {
        "time": 1689822618000,
        "value": 139.238,
        "_internal_originalTime": 1689822618000
    },
    {
        "time": 1689822620000,
        "value": 139.237,
        "_internal_originalTime": 1689822620000
    },
    {
        "time": 1689822625000,
        "value": 139.239,
        "_internal_originalTime": 1689822625000
    },
    {
        "time": 1689822626000,
        "value": 139.239,
        "_internal_originalTime": 1689822626000
    },
    {
        "time": 1689822627000,
        "value": 139.237,
        "_internal_originalTime": 1689822627000
    },
    {
        "time": 1689822629000,
        "value": 139.236,
        "_internal_originalTime": 1689822629000
    },
    {
        "time": 1689822630000,
        "value": 139.239,
        "_internal_originalTime": 1689822630000
    },
    {
        "time": 1689822631000,
        "value": 139.238,
        "_internal_originalTime": 1689822631000
    },
    {
        "time": 1689822633000,
        "value": 139.238,
        "_internal_originalTime": 1689822633000
    },
    {
        "time": 1689822636000,
        "value": 139.243,
        "_internal_originalTime": 1689822636000
    },
    {
        "time": 1689822637000,
        "value": 139.247,
        "_internal_originalTime": 1689822637000
    },
    {
        "time": 1689822638000,
        "value": 139.244,
        "_internal_originalTime": 1689822638000
    },
    {
        "time": 1689822639000,
        "value": 139.242,
        "_internal_originalTime": 1689822639000
    },
    {
        "time": 1689822640000,
        "value": 139.249,
        "_internal_originalTime": 1689822640000
    },
    {
        "time": 1689822642000,
        "value": 139.254,
        "_internal_originalTime": 1689822642000
    },
    {
        "time": 1689822643000,
        "value": 139.248,
        "_internal_originalTime": 1689822643000
    },
    {
        "time": 1689822646000,
        "value": 139.249,
        "_internal_originalTime": 1689822646000
    },
    {
        "time": 1689822648000,
        "value": 139.249,
        "_internal_originalTime": 1689822648000
    },
    {
        "time": 1689822649000,
        "value": 139.248,
        "_internal_originalTime": 1689822649000
    },
    {
        "time": 1689822651000,
        "value": 139.253,
        "_internal_originalTime": 1689822651000
    },
    {
        "time": 1689822652000,
        "value": 139.245,
        "_internal_originalTime": 1689822652000
    },
    {
        "time": 1689822653000,
        "value": 139.243,
        "_internal_originalTime": 1689822653000
    },
    {
        "time": 1689822655000,
        "value": 139.243,
        "_internal_originalTime": 1689822655000
    },
    {
        "time": 1689822656000,
        "value": 139.243,
        "_internal_originalTime": 1689822656000
    },
    {
        "time": 1689822660000,
        "value": 139.244,
        "_internal_originalTime": 1689822660000
    },
    {
        "time": 1689822666000,
        "value": 139.243,
        "_internal_originalTime": 1689822666000
    },
    {
        "time": 1689822668000,
        "value": 139.238,
        "_internal_originalTime": 1689822668000
    },
    {
        "time": 1689822669000,
        "value": 139.245,
        "_internal_originalTime": 1689822669000
    },
    {
        "time": 1689822670000,
        "value": 139.241,
        "_internal_originalTime": 1689822670000
    },
    {
        "time": 1689822671000,
        "value": 139.241,
        "_internal_originalTime": 1689822671000
    },
    {
        "time": 1689822673000,
        "value": 139.24,
        "_internal_originalTime": 1689822673000
    },
    {
        "time": 1689822676000,
        "value": 139.242,
        "_internal_originalTime": 1689822676000
    },
    {
        "time": 1689822678000,
        "value": 139.24,
        "_internal_originalTime": 1689822678000
    },
    {
        "time": 1689822679000,
        "value": 139.245,
        "_internal_originalTime": 1689822679000
    },
    {
        "time": 1689822681000,
        "value": 139.246,
        "_internal_originalTime": 1689822681000
    },
    {
        "time": 1689822682000,
        "value": 139.24,
        "_internal_originalTime": 1689822682000
    },
    {
        "time": 1689822683000,
        "value": 139.235,
        "_internal_originalTime": 1689822683000
    },
    {
        "time": 1689822685000,
        "value": 139.232,
        "_internal_originalTime": 1689822685000
    },
    {
        "time": 1689822687000,
        "value": 139.236,
        "_internal_originalTime": 1689822687000
    },
    {
        "time": 1689822690000,
        "value": 139.231,
        "_internal_originalTime": 1689822690000
    },
    {
        "time": 1689822691000,
        "value": 139.232,
        "_internal_originalTime": 1689822691000
    },
    {
        "time": 1689822692000,
        "value": 139.23,
        "_internal_originalTime": 1689822692000
    },
    {
        "time": 1689822694000,
        "value": 139.234,
        "_internal_originalTime": 1689822694000
    },
    {
        "time": 1689822696000,
        "value": 139.227,
        "_internal_originalTime": 1689822696000
    },
    {
        "time": 1689822697000,
        "value": 139.226,
        "_internal_originalTime": 1689822697000
    },
    {
        "time": 1689822699000,
        "value": 139.224,
        "_internal_originalTime": 1689822699000
    },
    {
        "time": 1689822700000,
        "value": 139.222,
        "_internal_originalTime": 1689822700000
    },
    {
        "time": 1689822701000,
        "value": 139.222,
        "_internal_originalTime": 1689822701000
    },
    {
        "time": 1689822703000,
        "value": 139.225,
        "_internal_originalTime": 1689822703000
    },
    {
        "time": 1689822704000,
        "value": 139.234,
        "_internal_originalTime": 1689822704000
    },
    {
        "time": 1689822708000,
        "value": 139.234,
        "_internal_originalTime": 1689822708000
    },
    {
        "time": 1689822709000,
        "value": 139.231,
        "_internal_originalTime": 1689822709000
    },
    {
        "time": 1689822710000,
        "value": 139.243,
        "_internal_originalTime": 1689822710000
    },
    {
        "time": 1689822711000,
        "value": 139.246,
        "_internal_originalTime": 1689822711000
    },
    {
        "time": 1689822712000,
        "value": 139.242,
        "_internal_originalTime": 1689822712000
    },
    {
        "time": 1689822717000,
        "value": 139.242,
        "_internal_originalTime": 1689822717000
    },
    {
        "time": 1689822718000,
        "value": 139.243,
        "_internal_originalTime": 1689822718000
    },
    {
        "time": 1689822720000,
        "value": 139.247,
        "_internal_originalTime": 1689822720000
    },
    {
        "time": 1689822721000,
        "value": 139.247,
        "_internal_originalTime": 1689822721000
    },
    {
        "time": 1689822722000,
        "value": 139.246,
        "_internal_originalTime": 1689822722000
    },
    {
        "time": 1689822723000,
        "value": 139.243,
        "_internal_originalTime": 1689822723000
    },
    {
        "time": 1689822724000,
        "value": 139.24,
        "_internal_originalTime": 1689822724000
    },
    {
        "time": 1689822726000,
        "value": 139.243,
        "_internal_originalTime": 1689822726000
    },
    {
        "time": 1689822727000,
        "value": 139.243,
        "_internal_originalTime": 1689822727000
    },
    {
        "time": 1689822729000,
        "value": 139.238,
        "_internal_originalTime": 1689822729000
    },
    {
        "time": 1689822730000,
        "value": 139.238,
        "_internal_originalTime": 1689822730000
    },
    {
        "time": 1689822731000,
        "value": 139.236,
        "_internal_originalTime": 1689822731000
    },
    {
        "time": 1689822733000,
        "value": 139.236,
        "_internal_originalTime": 1689822733000
    },
    {
        "time": 1689822734000,
        "value": 139.232,
        "_internal_originalTime": 1689822734000
    },
    {
        "time": 1689822736000,
        "value": 139.232,
        "_internal_originalTime": 1689822736000
    },
    {
        "time": 1689822740000,
        "value": 139.231,
        "_internal_originalTime": 1689822740000
    },
    {
        "time": 1689822742000,
        "value": 139.232,
        "_internal_originalTime": 1689822742000
    },
    {
        "time": 1689822743000,
        "value": 139.232,
        "_internal_originalTime": 1689822743000
    },
    {
        "time": 1689822746000,
        "value": 139.235,
        "_internal_originalTime": 1689822746000
    },
    {
        "time": 1689822750000,
        "value": 139.23,
        "_internal_originalTime": 1689822750000
    },
    {
        "time": 1689822751000,
        "value": 139.232,
        "_internal_originalTime": 1689822751000
    },
    {
        "time": 1689822753000,
        "value": 139.232,
        "_internal_originalTime": 1689822753000
    },
    {
        "time": 1689822757000,
        "value": 139.232,
        "_internal_originalTime": 1689822757000
    },
    {
        "time": 1689822758000,
        "value": 139.232,
        "_internal_originalTime": 1689822758000
    },
    {
        "time": 1689822760000,
        "value": 139.232,
        "_internal_originalTime": 1689822760000
    },
    {
        "time": 1689822761000,
        "value": 139.232,
        "_internal_originalTime": 1689822761000
    },
    {
        "time": 1689822762000,
        "value": 139.232,
        "_internal_originalTime": 1689822762000
    },
    {
        "time": 1689822763000,
        "value": 139.232,
        "_internal_originalTime": 1689822763000
    },
    {
        "time": 1689822764000,
        "value": 139.232,
        "_internal_originalTime": 1689822764000
    },
    {
        "time": 1689822765000,
        "value": 139.232,
        "_internal_originalTime": 1689822765000
    },
    {
        "time": 1689822768000,
        "value": 139.232,
        "_internal_originalTime": 1689822768000
    },
    {
        "time": 1689822770000,
        "value": 139.232,
        "_internal_originalTime": 1689822770000
    },
    {
        "time": 1689822772000,
        "value": 139.232,
        "_internal_originalTime": 1689822772000
    },
    {
        "time": 1689822773000,
        "value": 139.232,
        "_internal_originalTime": 1689822773000
    },
    {
        "time": 1689822774000,
        "value": 139.232,
        "_internal_originalTime": 1689822774000
    },
    {
        "time": 1689822777000,
        "value": 139.242,
        "_internal_originalTime": 1689822777000
    },
    {
        "time": 1689822779000,
        "value": 139.238,
        "_internal_originalTime": 1689822779000
    },
    {
        "time": 1689822780000,
        "value": 139.239,
        "_internal_originalTime": 1689822780000
    },
    {
        "time": 1689822782000,
        "value": 139.243,
        "_internal_originalTime": 1689822782000
    },
    {
        "time": 1689822783000,
        "value": 139.242,
        "_internal_originalTime": 1689822783000
    },
    {
        "time": 1689822784000,
        "value": 139.242,
        "_internal_originalTime": 1689822784000
    },
    {
        "time": 1689822785000,
        "value": 139.245,
        "_internal_originalTime": 1689822785000
    },
    {
        "time": 1689822787000,
        "value": 139.244,
        "_internal_originalTime": 1689822787000
    },
    {
        "time": 1689822788000,
        "value": 139.245,
        "_internal_originalTime": 1689822788000
    },
    {
        "time": 1689822789000,
        "value": 139.243,
        "_internal_originalTime": 1689822789000
    },
    {
        "time": 1689822791000,
        "value": 139.242,
        "_internal_originalTime": 1689822791000
    },
    {
        "time": 1689822792000,
        "value": 139.242,
        "_internal_originalTime": 1689822792000
    },
    {
        "time": 1689822793000,
        "value": 139.243,
        "_internal_originalTime": 1689822793000
    },
    {
        "time": 1689822794000,
        "value": 139.242,
        "_internal_originalTime": 1689822794000
    },
    {
        "time": 1689822796000,
        "value": 139.24,
        "_internal_originalTime": 1689822796000
    },
    {
        "time": 1689822797000,
        "value": 139.243,
        "_internal_originalTime": 1689822797000
    },
    {
        "time": 1689822799000,
        "value": 139.25,
        "_internal_originalTime": 1689822799000
    },
    {
        "time": 1689822800000,
        "value": 139.258,
        "_internal_originalTime": 1689822800000
    },
    {
        "time": 1689822801000,
        "value": 139.259,
        "_internal_originalTime": 1689822801000
    },
    {
        "time": 1689822802000,
        "value": 139.265,
        "_internal_originalTime": 1689822802000
    },
    {
        "time": 1689822803000,
        "value": 139.261,
        "_internal_originalTime": 1689822803000
    },
    {
        "time": 1689822804000,
        "value": 139.262,
        "_internal_originalTime": 1689822804000
    },
    {
        "time": 1689822806000,
        "value": 139.264,
        "_internal_originalTime": 1689822806000
    },
    {
        "time": 1689822807000,
        "value": 139.263,
        "_internal_originalTime": 1689822807000
    },
    {
        "time": 1689822808000,
        "value": 139.261,
        "_internal_originalTime": 1689822808000
    },
    {
        "time": 1689822809000,
        "value": 139.26,
        "_internal_originalTime": 1689822809000
    },
    {
        "time": 1689822811000,
        "value": 139.262,
        "_internal_originalTime": 1689822811000
    },
    {
        "time": 1689822812000,
        "value": 139.255,
        "_internal_originalTime": 1689822812000
    },
    {
        "time": 1689822813000,
        "value": 139.254,
        "_internal_originalTime": 1689822813000
    },
    {
        "time": 1689822815000,
        "value": 139.254,
        "_internal_originalTime": 1689822815000
    },
    {
        "time": 1689822817000,
        "value": 139.249,
        "_internal_originalTime": 1689822817000
    },
    {
        "time": 1689822818000,
        "value": 139.255,
        "_internal_originalTime": 1689822818000
    },
    {
        "time": 1689822819000,
        "value": 139.255,
        "_internal_originalTime": 1689822819000
    },
    {
        "time": 1689822820000,
        "value": 139.255,
        "_internal_originalTime": 1689822820000
    },
    {
        "time": 1689822821000,
        "value": 139.255,
        "_internal_originalTime": 1689822821000
    },
    {
        "time": 1689822824000,
        "value": 139.262,
        "_internal_originalTime": 1689822824000
    },
    {
        "time": 1689822826000,
        "value": 139.266,
        "_internal_originalTime": 1689822826000
    },
    {
        "time": 1689822827000,
        "value": 139.268,
        "_internal_originalTime": 1689822827000
    },
    {
        "time": 1689822829000,
        "value": 139.27,
        "_internal_originalTime": 1689822829000
    },
    {
        "time": 1689822831000,
        "value": 139.27,
        "_internal_originalTime": 1689822831000
    },
    {
        "time": 1689822832000,
        "value": 139.272,
        "_internal_originalTime": 1689822832000
    },
    {
        "time": 1689822834000,
        "value": 139.271,
        "_internal_originalTime": 1689822834000
    },
    {
        "time": 1689822835000,
        "value": 139.272,
        "_internal_originalTime": 1689822835000
    },
    {
        "time": 1689822838000,
        "value": 139.271,
        "_internal_originalTime": 1689822838000
    },
    {
        "time": 1689822840000,
        "value": 139.265,
        "_internal_originalTime": 1689822840000
    },
    {
        "time": 1689822841000,
        "value": 139.268,
        "_internal_originalTime": 1689822841000
    },
    {
        "time": 1689822842000,
        "value": 139.264,
        "_internal_originalTime": 1689822842000
    },
    {
        "time": 1689822843000,
        "value": 139.26,
        "_internal_originalTime": 1689822843000
    },
    {
        "time": 1689822844000,
        "value": 139.26,
        "_internal_originalTime": 1689822844000
    },
    {
        "time": 1689822846000,
        "value": 139.26,
        "_internal_originalTime": 1689822846000
    },
    {
        "time": 1689822847000,
        "value": 139.263,
        "_internal_originalTime": 1689822847000
    },
    {
        "time": 1689822848000,
        "value": 139.264,
        "_internal_originalTime": 1689822848000
    },
    {
        "time": 1689822849000,
        "value": 139.261,
        "_internal_originalTime": 1689822849000
    },
    {
        "time": 1689822852000,
        "value": 139.26,
        "_internal_originalTime": 1689822852000
    },
    {
        "time": 1689822857000,
        "value": 139.26,
        "_internal_originalTime": 1689822857000
    },
    {
        "time": 1689822859000,
        "value": 139.26,
        "_internal_originalTime": 1689822859000
    },
    {
        "time": 1689822860000,
        "value": 139.264,
        "_internal_originalTime": 1689822860000
    },
    {
        "time": 1689822862000,
        "value": 139.258,
        "_internal_originalTime": 1689822862000
    },
    {
        "time": 1689822864000,
        "value": 139.256,
        "_internal_originalTime": 1689822864000
    },
    {
        "time": 1689822865000,
        "value": 139.257,
        "_internal_originalTime": 1689822865000
    },
    {
        "time": 1689822867000,
        "value": 139.262,
        "_internal_originalTime": 1689822867000
    },
    {
        "time": 1689822868000,
        "value": 139.258,
        "_internal_originalTime": 1689822868000
    },
    {
        "time": 1689822869000,
        "value": 139.269,
        "_internal_originalTime": 1689822869000
    },
    {
        "time": 1689822870000,
        "value": 139.271,
        "_internal_originalTime": 1689822870000
    },
    {
        "time": 1689822871000,
        "value": 139.272,
        "_internal_originalTime": 1689822871000
    },
    {
        "time": 1689822872000,
        "value": 139.268,
        "_internal_originalTime": 1689822872000
    },
    {
        "time": 1689822874000,
        "value": 139.273,
        "_internal_originalTime": 1689822874000
    },
    {
        "time": 1689822875000,
        "value": 139.27,
        "_internal_originalTime": 1689822875000
    },
    {
        "time": 1689822877000,
        "value": 139.273,
        "_internal_originalTime": 1689822877000
    },
    {
        "time": 1689822878000,
        "value": 139.273,
        "_internal_originalTime": 1689822878000
    },
    {
        "time": 1689822879000,
        "value": 139.273,
        "_internal_originalTime": 1689822879000
    },
    {
        "time": 1689822882000,
        "value": 139.264,
        "_internal_originalTime": 1689822882000
    },
    {
        "time": 1689822883000,
        "value": 139.262,
        "_internal_originalTime": 1689822883000
    },
    {
        "time": 1689822884000,
        "value": 139.259,
        "_internal_originalTime": 1689822884000
    },
    {
        "time": 1689822886000,
        "value": 139.259,
        "_internal_originalTime": 1689822886000
    },
    {
        "time": 1689822887000,
        "value": 139.257,
        "_internal_originalTime": 1689822887000
    },
    {
        "time": 1689822888000,
        "value": 139.254,
        "_internal_originalTime": 1689822888000
    },
    {
        "time": 1689822889000,
        "value": 139.254,
        "_internal_originalTime": 1689822889000
    },
    {
        "time": 1689822890000,
        "value": 139.254,
        "_internal_originalTime": 1689822890000
    },
    {
        "time": 1689822891000,
        "value": 139.253,
        "_internal_originalTime": 1689822891000
    },
    {
        "time": 1689822893000,
        "value": 139.251,
        "_internal_originalTime": 1689822893000
    },
    {
        "time": 1689822894000,
        "value": 139.25,
        "_internal_originalTime": 1689822894000
    },
    {
        "time": 1689822896000,
        "value": 139.262,
        "_internal_originalTime": 1689822896000
    },
    {
        "time": 1689822900000,
        "value": 139.258,
        "_internal_originalTime": 1689822900000
    },
    {
        "time": 1689822901000,
        "value": 139.252,
        "_internal_originalTime": 1689822901000
    },
    {
        "time": 1689822902000,
        "value": 139.256,
        "_internal_originalTime": 1689822902000
    },
    {
        "time": 1689822904000,
        "value": 139.252,
        "_internal_originalTime": 1689822904000
    },
    {
        "time": 1689822906000,
        "value": 139.25,
        "_internal_originalTime": 1689822906000
    },
    {
        "time": 1689822907000,
        "value": 139.247,
        "_internal_originalTime": 1689822907000
    },
    {
        "time": 1689822908000,
        "value": 139.24,
        "_internal_originalTime": 1689822908000
    },
    {
        "time": 1689822909000,
        "value": 139.248,
        "_internal_originalTime": 1689822909000
    },
    {
        "time": 1689822910000,
        "value": 139.252,
        "_internal_originalTime": 1689822910000
    },
    {
        "time": 1689822912000,
        "value": 139.248,
        "_internal_originalTime": 1689822912000
    },
    {
        "time": 1689822913000,
        "value": 139.242,
        "_internal_originalTime": 1689822913000
    },
    {
        "time": 1689822914000,
        "value": 139.246,
        "_internal_originalTime": 1689822914000
    },
    {
        "time": 1689822916000,
        "value": 139.24,
        "_internal_originalTime": 1689822916000
    },
    {
        "time": 1689822918000,
        "value": 139.244,
        "_internal_originalTime": 1689822918000
    },
    {
        "time": 1689822919000,
        "value": 139.246,
        "_internal_originalTime": 1689822919000
    },
    {
        "time": 1689822921000,
        "value": 139.246,
        "_internal_originalTime": 1689822921000
    },
    {
        "time": 1689822922000,
        "value": 139.242,
        "_internal_originalTime": 1689822922000
    },
    {
        "time": 1689822923000,
        "value": 139.247,
        "_internal_originalTime": 1689822923000
    },
    {
        "time": 1689822924000,
        "value": 139.242,
        "_internal_originalTime": 1689822924000
    },
    {
        "time": 1689822927000,
        "value": 139.237,
        "_internal_originalTime": 1689822927000
    },
    {
        "time": 1689822928000,
        "value": 139.235,
        "_internal_originalTime": 1689822928000
    },
    {
        "time": 1689822929000,
        "value": 139.235,
        "_internal_originalTime": 1689822929000
    },
    {
        "time": 1689822930000,
        "value": 139.234,
        "_internal_originalTime": 1689822930000
    },
    {
        "time": 1689822931000,
        "value": 139.227,
        "_internal_originalTime": 1689822931000
    },
    {
        "time": 1689822932000,
        "value": 139.227,
        "_internal_originalTime": 1689822932000
    },
    {
        "time": 1689822935000,
        "value": 139.22,
        "_internal_originalTime": 1689822935000
    },
    {
        "time": 1689822937000,
        "value": 139.224,
        "_internal_originalTime": 1689822937000
    },
    {
        "time": 1689822939000,
        "value": 139.222,
        "_internal_originalTime": 1689822939000
    },
    {
        "time": 1689822940000,
        "value": 139.224,
        "_internal_originalTime": 1689822940000
    },
    {
        "time": 1689822941000,
        "value": 139.226,
        "_internal_originalTime": 1689822941000
    },
    {
        "time": 1689822942000,
        "value": 139.227,
        "_internal_originalTime": 1689822942000
    },
    {
        "time": 1689822943000,
        "value": 139.227,
        "_internal_originalTime": 1689822943000
    },
    {
        "time": 1689822944000,
        "value": 139.227,
        "_internal_originalTime": 1689822944000
    },
    {
        "time": 1689822947000,
        "value": 139.226,
        "_internal_originalTime": 1689822947000
    },
    {
        "time": 1689822948000,
        "value": 139.224,
        "_internal_originalTime": 1689822948000
    },
    {
        "time": 1689822949000,
        "value": 139.223,
        "_internal_originalTime": 1689822949000
    },
    {
        "time": 1689822950000,
        "value": 139.223,
        "_internal_originalTime": 1689822950000
    },
    {
        "time": 1689822952000,
        "value": 139.22,
        "_internal_originalTime": 1689822952000
    },
    {
        "time": 1689822953000,
        "value": 139.218,
        "_internal_originalTime": 1689822953000
    },
    {
        "time": 1689822955000,
        "value": 139.223,
        "_internal_originalTime": 1689822955000
    },
    {
        "time": 1689822957000,
        "value": 139.219,
        "_internal_originalTime": 1689822957000
    },
    {
        "time": 1689822959000,
        "value": 139.225,
        "_internal_originalTime": 1689822959000
    },
    {
        "time": 1689822960000,
        "value": 139.219,
        "_internal_originalTime": 1689822960000
    },
    {
        "time": 1689822966000,
        "value": 139.22,
        "_internal_originalTime": 1689822966000
    },
    {
        "time": 1689822967000,
        "value": 139.22,
        "_internal_originalTime": 1689822967000
    },
    {
        "time": 1689822968000,
        "value": 139.215,
        "_internal_originalTime": 1689822968000
    },
    {
        "time": 1689822970000,
        "value": 139.21,
        "_internal_originalTime": 1689822970000
    },
    {
        "time": 1689822973000,
        "value": 139.213,
        "_internal_originalTime": 1689822973000
    },
    {
        "time": 1689822975000,
        "value": 139.211,
        "_internal_originalTime": 1689822975000
    },
    {
        "time": 1689822978000,
        "value": 139.213,
        "_internal_originalTime": 1689822978000
    },
    {
        "time": 1689822979000,
        "value": 139.214,
        "_internal_originalTime": 1689822979000
    },
    {
        "time": 1689822980000,
        "value": 139.21,
        "_internal_originalTime": 1689822980000
    },
    {
        "time": 1689822982000,
        "value": 139.21,
        "_internal_originalTime": 1689822982000
    },
    {
        "time": 1689822985000,
        "value": 139.216,
        "_internal_originalTime": 1689822985000
    },
    {
        "time": 1689822986000,
        "value": 139.21,
        "_internal_originalTime": 1689822986000
    },
    {
        "time": 1689822988000,
        "value": 139.206,
        "_internal_originalTime": 1689822988000
    },
    {
        "time": 1689822989000,
        "value": 139.201,
        "_internal_originalTime": 1689822989000
    },
    {
        "time": 1689822990000,
        "value": 139.2,
        "_internal_originalTime": 1689822990000
    },
    {
        "time": 1689822991000,
        "value": 139.205,
        "_internal_originalTime": 1689822991000
    },
    {
        "time": 1689822993000,
        "value": 139.206,
        "_internal_originalTime": 1689822993000
    },
    {
        "time": 1689822994000,
        "value": 139.201,
        "_internal_originalTime": 1689822994000
    },
    {
        "time": 1689822997000,
        "value": 139.194,
        "_internal_originalTime": 1689822997000
    },
    {
        "time": 1689822998000,
        "value": 139.194,
        "_internal_originalTime": 1689822998000
    },
    {
        "time": 1689822999000,
        "value": 139.198,
        "_internal_originalTime": 1689822999000
    },
    {
        "time": 1689823000000,
        "value": 139.193,
        "_internal_originalTime": 1689823000000
    },
    {
        "time": 1689823001000,
        "value": 139.192,
        "_internal_originalTime": 1689823001000
    },
    {
        "time": 1689823002000,
        "value": 139.193,
        "_internal_originalTime": 1689823002000
    },
    {
        "time": 1689823004000,
        "value": 139.196,
        "_internal_originalTime": 1689823004000
    },
    {
        "time": 1689823005000,
        "value": 139.196,
        "_internal_originalTime": 1689823005000
    },
    {
        "time": 1689823006000,
        "value": 139.191,
        "_internal_originalTime": 1689823006000
    },
    {
        "time": 1689823008000,
        "value": 139.196,
        "_internal_originalTime": 1689823008000
    },
    {
        "time": 1689823010000,
        "value": 139.192,
        "_internal_originalTime": 1689823010000
    },
    {
        "time": 1689823012000,
        "value": 139.183,
        "_internal_originalTime": 1689823012000
    },
    {
        "time": 1689823013000,
        "value": 139.183,
        "_internal_originalTime": 1689823013000
    },
    {
        "time": 1689823014000,
        "value": 139.183,
        "_internal_originalTime": 1689823014000
    },
    {
        "time": 1689823015000,
        "value": 139.187,
        "_internal_originalTime": 1689823015000
    },
    {
        "time": 1689823017000,
        "value": 139.189,
        "_internal_originalTime": 1689823017000
    },
    {
        "time": 1689823018000,
        "value": 139.184,
        "_internal_originalTime": 1689823018000
    },
    {
        "time": 1689823020000,
        "value": 139.183,
        "_internal_originalTime": 1689823020000
    },
    {
        "time": 1689823021000,
        "value": 139.185,
        "_internal_originalTime": 1689823021000
    },
    {
        "time": 1689823022000,
        "value": 139.184,
        "_internal_originalTime": 1689823022000
    },
    {
        "time": 1689823023000,
        "value": 139.185,
        "_internal_originalTime": 1689823023000
    },
    {
        "time": 1689823024000,
        "value": 139.184,
        "_internal_originalTime": 1689823024000
    },
    {
        "time": 1689823025000,
        "value": 139.185,
        "_internal_originalTime": 1689823025000
    },
    {
        "time": 1689823027000,
        "value": 139.182,
        "_internal_originalTime": 1689823027000
    },
    {
        "time": 1689823028000,
        "value": 139.182,
        "_internal_originalTime": 1689823028000
    },
    {
        "time": 1689823029000,
        "value": 139.174,
        "_internal_originalTime": 1689823029000
    },
    {
        "time": 1689823030000,
        "value": 139.174,
        "_internal_originalTime": 1689823030000
    },
    {
        "time": 1689823031000,
        "value": 139.175,
        "_internal_originalTime": 1689823031000
    },
    {
        "time": 1689823033000,
        "value": 139.178,
        "_internal_originalTime": 1689823033000
    },
    {
        "time": 1689823037000,
        "value": 139.179,
        "_internal_originalTime": 1689823037000
    },
    {
        "time": 1689823039000,
        "value": 139.178,
        "_internal_originalTime": 1689823039000
    },
    {
        "time": 1689823040000,
        "value": 139.177,
        "_internal_originalTime": 1689823040000
    },
    {
        "time": 1689823041000,
        "value": 139.174,
        "_internal_originalTime": 1689823041000
    },
    {
        "time": 1689823042000,
        "value": 139.178,
        "_internal_originalTime": 1689823042000
    },
    {
        "time": 1689823043000,
        "value": 139.174,
        "_internal_originalTime": 1689823043000
    },
    {
        "time": 1689823044000,
        "value": 139.176,
        "_internal_originalTime": 1689823044000
    },
    {
        "time": 1689823046000,
        "value": 139.175,
        "_internal_originalTime": 1689823046000
    },
    {
        "time": 1689823048000,
        "value": 139.174,
        "_internal_originalTime": 1689823048000
    },
    {
        "time": 1689823049000,
        "value": 139.173,
        "_internal_originalTime": 1689823049000
    },
    {
        "time": 1689823051000,
        "value": 139.174,
        "_internal_originalTime": 1689823051000
    },
    {
        "time": 1689823052000,
        "value": 139.174,
        "_internal_originalTime": 1689823052000
    },
    {
        "time": 1689823054000,
        "value": 139.171,
        "_internal_originalTime": 1689823054000
    },
    {
        "time": 1689823055000,
        "value": 139.172,
        "_internal_originalTime": 1689823055000
    },
    {
        "time": 1689823056000,
        "value": 139.173,
        "_internal_originalTime": 1689823056000
    },
    {
        "time": 1689823057000,
        "value": 139.172,
        "_internal_originalTime": 1689823057000
    },
    {
        "time": 1689823059000,
        "value": 139.168,
        "_internal_originalTime": 1689823059000
    },
    {
        "time": 1689823060000,
        "value": 139.177,
        "_internal_originalTime": 1689823060000
    },
    {
        "time": 1689823061000,
        "value": 139.18,
        "_internal_originalTime": 1689823061000
    },
    {
        "time": 1689823063000,
        "value": 139.177,
        "_internal_originalTime": 1689823063000
    },
    {
        "time": 1689823064000,
        "value": 139.175,
        "_internal_originalTime": 1689823064000
    },
    {
        "time": 1689823065000,
        "value": 139.177,
        "_internal_originalTime": 1689823065000
    },
    {
        "time": 1689823066000,
        "value": 139.177,
        "_internal_originalTime": 1689823066000
    },
    {
        "time": 1689823067000,
        "value": 139.177,
        "_internal_originalTime": 1689823067000
    },
    {
        "time": 1689823069000,
        "value": 139.179,
        "_internal_originalTime": 1689823069000
    },
    {
        "time": 1689823070000,
        "value": 139.176,
        "_internal_originalTime": 1689823070000
    },
    {
        "time": 1689823071000,
        "value": 139.184,
        "_internal_originalTime": 1689823071000
    },
    {
        "time": 1689823072000,
        "value": 139.186,
        "_internal_originalTime": 1689823072000
    },
    {
        "time": 1689823073000,
        "value": 139.18,
        "_internal_originalTime": 1689823073000
    },
    {
        "time": 1689823075000,
        "value": 139.188,
        "_internal_originalTime": 1689823075000
    },
    {
        "time": 1689823076000,
        "value": 139.188,
        "_internal_originalTime": 1689823076000
    },
    {
        "time": 1689823078000,
        "value": 139.19,
        "_internal_originalTime": 1689823078000
    },
    {
        "time": 1689823079000,
        "value": 139.189,
        "_internal_originalTime": 1689823079000
    },
    {
        "time": 1689823080000,
        "value": 139.187,
        "_internal_originalTime": 1689823080000
    },
    {
        "time": 1689823081000,
        "value": 139.184,
        "_internal_originalTime": 1689823081000
    },
    {
        "time": 1689823082000,
        "value": 139.187,
        "_internal_originalTime": 1689823082000
    },
    {
        "time": 1689823083000,
        "value": 139.187,
        "_internal_originalTime": 1689823083000
    },
    {
        "time": 1689823085000,
        "value": 139.183,
        "_internal_originalTime": 1689823085000
    },
    {
        "time": 1689823086000,
        "value": 139.176,
        "_internal_originalTime": 1689823086000
    },
    {
        "time": 1689823087000,
        "value": 139.173,
        "_internal_originalTime": 1689823087000
    },
    {
        "time": 1689823089000,
        "value": 139.17,
        "_internal_originalTime": 1689823089000
    },
    {
        "time": 1689823090000,
        "value": 139.168,
        "_internal_originalTime": 1689823090000
    },
    {
        "time": 1689823091000,
        "value": 139.168,
        "_internal_originalTime": 1689823091000
    },
    {
        "time": 1689823093000,
        "value": 139.172,
        "_internal_originalTime": 1689823093000
    },
    {
        "time": 1689823095000,
        "value": 139.178,
        "_internal_originalTime": 1689823095000
    },
    {
        "time": 1689823096000,
        "value": 139.176,
        "_internal_originalTime": 1689823096000
    },
    {
        "time": 1689823097000,
        "value": 139.179,
        "_internal_originalTime": 1689823097000
    },
    {
        "time": 1689823098000,
        "value": 139.18,
        "_internal_originalTime": 1689823098000
    },
    {
        "time": 1689823099000,
        "value": 139.18,
        "_internal_originalTime": 1689823099000
    },
    {
        "time": 1689823101000,
        "value": 139.176,
        "_internal_originalTime": 1689823101000
    },
    {
        "time": 1689823102000,
        "value": 139.176,
        "_internal_originalTime": 1689823102000
    },
    {
        "time": 1689823103000,
        "value": 139.176,
        "_internal_originalTime": 1689823103000
    },
    {
        "time": 1689823104000,
        "value": 139.174,
        "_internal_originalTime": 1689823104000
    },
    {
        "time": 1689823109000,
        "value": 139.176,
        "_internal_originalTime": 1689823109000
    },
    {
        "time": 1689823110000,
        "value": 139.178,
        "_internal_originalTime": 1689823110000
    },
    {
        "time": 1689823111000,
        "value": 139.178,
        "_internal_originalTime": 1689823111000
    },
    {
        "time": 1689823112000,
        "value": 139.178,
        "_internal_originalTime": 1689823112000
    },
    {
        "time": 1689823113000,
        "value": 139.177,
        "_internal_originalTime": 1689823113000
    },
    {
        "time": 1689823114000,
        "value": 139.176,
        "_internal_originalTime": 1689823114000
    },
    {
        "time": 1689823116000,
        "value": 139.176,
        "_internal_originalTime": 1689823116000
    },
    {
        "time": 1689823117000,
        "value": 139.172,
        "_internal_originalTime": 1689823117000
    },
    {
        "time": 1689823119000,
        "value": 139.178,
        "_internal_originalTime": 1689823119000
    },
    {
        "time": 1689823120000,
        "value": 139.175,
        "_internal_originalTime": 1689823120000
    },
    {
        "time": 1689823122000,
        "value": 139.176,
        "_internal_originalTime": 1689823122000
    },
    {
        "time": 1689823123000,
        "value": 139.174,
        "_internal_originalTime": 1689823123000
    },
    {
        "time": 1689823124000,
        "value": 139.174,
        "_internal_originalTime": 1689823124000
    },
    {
        "time": 1689823126000,
        "value": 139.172,
        "_internal_originalTime": 1689823126000
    },
    {
        "time": 1689823127000,
        "value": 139.17,
        "_internal_originalTime": 1689823127000
    },
    {
        "time": 1689823129000,
        "value": 139.169,
        "_internal_originalTime": 1689823129000
    },
    {
        "time": 1689823130000,
        "value": 139.164,
        "_internal_originalTime": 1689823130000
    },
    {
        "time": 1689823132000,
        "value": 139.169,
        "_internal_originalTime": 1689823132000
    },
    {
        "time": 1689823133000,
        "value": 139.169,
        "_internal_originalTime": 1689823133000
    },
    {
        "time": 1689823134000,
        "value": 139.169,
        "_internal_originalTime": 1689823134000
    },
    {
        "time": 1689823137000,
        "value": 139.164,
        "_internal_originalTime": 1689823137000
    },
    {
        "time": 1689823138000,
        "value": 139.161,
        "_internal_originalTime": 1689823138000
    },
    {
        "time": 1689823139000,
        "value": 139.162,
        "_internal_originalTime": 1689823139000
    },
    {
        "time": 1689823140000,
        "value": 139.155,
        "_internal_originalTime": 1689823140000
    },
    {
        "time": 1689823141000,
        "value": 139.157,
        "_internal_originalTime": 1689823141000
    },
    {
        "time": 1689823143000,
        "value": 139.159,
        "_internal_originalTime": 1689823143000
    },
    {
        "time": 1689823144000,
        "value": 139.161,
        "_internal_originalTime": 1689823144000
    },
    {
        "time": 1689823146000,
        "value": 139.162,
        "_internal_originalTime": 1689823146000
    },
    {
        "time": 1689823148000,
        "value": 139.163,
        "_internal_originalTime": 1689823148000
    },
    {
        "time": 1689823149000,
        "value": 139.159,
        "_internal_originalTime": 1689823149000
    },
    {
        "time": 1689823150000,
        "value": 139.159,
        "_internal_originalTime": 1689823150000
    },
    {
        "time": 1689823152000,
        "value": 139.159,
        "_internal_originalTime": 1689823152000
    },
    {
        "time": 1689823153000,
        "value": 139.154,
        "_internal_originalTime": 1689823153000
    },
    {
        "time": 1689823154000,
        "value": 139.156,
        "_internal_originalTime": 1689823154000
    },
    {
        "time": 1689823155000,
        "value": 139.157,
        "_internal_originalTime": 1689823155000
    },
    {
        "time": 1689823156000,
        "value": 139.156,
        "_internal_originalTime": 1689823156000
    },
    {
        "time": 1689823158000,
        "value": 139.158,
        "_internal_originalTime": 1689823158000
    },
    {
        "time": 1689823159000,
        "value": 139.158,
        "_internal_originalTime": 1689823159000
    },
    {
        "time": 1689823160000,
        "value": 139.157,
        "_internal_originalTime": 1689823160000
    },
    {
        "time": 1689823163000,
        "value": 139.16,
        "_internal_originalTime": 1689823163000
    },
    {
        "time": 1689823164000,
        "value": 139.162,
        "_internal_originalTime": 1689823164000
    },
    {
        "time": 1689823165000,
        "value": 139.162,
        "_internal_originalTime": 1689823165000
    },
    {
        "time": 1689823167000,
        "value": 139.164,
        "_internal_originalTime": 1689823167000
    },
    {
        "time": 1689823168000,
        "value": 139.165,
        "_internal_originalTime": 1689823168000
    },
    {
        "time": 1689823169000,
        "value": 139.164,
        "_internal_originalTime": 1689823169000
    },
    {
        "time": 1689823170000,
        "value": 139.157,
        "_internal_originalTime": 1689823170000
    },
    {
        "time": 1689823171000,
        "value": 139.16,
        "_internal_originalTime": 1689823171000
    },
    {
        "time": 1689823172000,
        "value": 139.164,
        "_internal_originalTime": 1689823172000
    },
    {
        "time": 1689823174000,
        "value": 139.163,
        "_internal_originalTime": 1689823174000
    },
    {
        "time": 1689823175000,
        "value": 139.168,
        "_internal_originalTime": 1689823175000
    },
    {
        "time": 1689823176000,
        "value": 139.171,
        "_internal_originalTime": 1689823176000
    },
    {
        "time": 1689823178000,
        "value": 139.17,
        "_internal_originalTime": 1689823178000
    },
    {
        "time": 1689823179000,
        "value": 139.167,
        "_internal_originalTime": 1689823179000
    },
    {
        "time": 1689823180000,
        "value": 139.169,
        "_internal_originalTime": 1689823180000
    },
    {
        "time": 1689823181000,
        "value": 139.171,
        "_internal_originalTime": 1689823181000
    },
    {
        "time": 1689823182000,
        "value": 139.166,
        "_internal_originalTime": 1689823182000
    },
    {
        "time": 1689823184000,
        "value": 139.166,
        "_internal_originalTime": 1689823184000
    },
    {
        "time": 1689823186000,
        "value": 139.171,
        "_internal_originalTime": 1689823186000
    },
    {
        "time": 1689823187000,
        "value": 139.166,
        "_internal_originalTime": 1689823187000
    },
    {
        "time": 1689823189000,
        "value": 139.166,
        "_internal_originalTime": 1689823189000
    },
    {
        "time": 1689823190000,
        "value": 139.167,
        "_internal_originalTime": 1689823190000
    },
    {
        "time": 1689823191000,
        "value": 139.166,
        "_internal_originalTime": 1689823191000
    },
    {
        "time": 1689823192000,
        "value": 139.164,
        "_internal_originalTime": 1689823192000
    },
    {
        "time": 1689823194000,
        "value": 139.162,
        "_internal_originalTime": 1689823194000
    },
    {
        "time": 1689823195000,
        "value": 139.166,
        "_internal_originalTime": 1689823195000
    },
    {
        "time": 1689823197000,
        "value": 139.168,
        "_internal_originalTime": 1689823197000
    },
    {
        "time": 1689823199000,
        "value": 139.166,
        "_internal_originalTime": 1689823199000
    },
    {
        "time": 1689823200000,
        "value": 139.168,
        "_internal_originalTime": 1689823200000
    },
    {
        "time": 1689823201000,
        "value": 139.166,
        "_internal_originalTime": 1689823201000
    },
    {
        "time": 1689823203000,
        "value": 139.164,
        "_internal_originalTime": 1689823203000
    },
    {
        "time": 1689823204000,
        "value": 139.168,
        "_internal_originalTime": 1689823204000
    },
    {
        "time": 1689823206000,
        "value": 139.169,
        "_internal_originalTime": 1689823206000
    },
    {
        "time": 1689823208000,
        "value": 139.168,
        "_internal_originalTime": 1689823208000
    },
    {
        "time": 1689823209000,
        "value": 139.168,
        "_internal_originalTime": 1689823209000
    },
    {
        "time": 1689823210000,
        "value": 139.168,
        "_internal_originalTime": 1689823210000
    },
    {
        "time": 1689823213000,
        "value": 139.168,
        "_internal_originalTime": 1689823213000
    },
    {
        "time": 1689823214000,
        "value": 139.166,
        "_internal_originalTime": 1689823214000
    },
    {
        "time": 1689823218000,
        "value": 139.167,
        "_internal_originalTime": 1689823218000
    },
    {
        "time": 1689823219000,
        "value": 139.166,
        "_internal_originalTime": 1689823219000
    },
    {
        "time": 1689823220000,
        "value": 139.166,
        "_internal_originalTime": 1689823220000
    },
    {
        "time": 1689823222000,
        "value": 139.164,
        "_internal_originalTime": 1689823222000
    },
    {
        "time": 1689823223000,
        "value": 139.164,
        "_internal_originalTime": 1689823223000
    },
    {
        "time": 1689823224000,
        "value": 139.152,
        "_internal_originalTime": 1689823224000
    },
    {
        "time": 1689823225000,
        "value": 139.152,
        "_internal_originalTime": 1689823225000
    },
    {
        "time": 1689823226000,
        "value": 139.157,
        "_internal_originalTime": 1689823226000
    },
    {
        "time": 1689823227000,
        "value": 139.156,
        "_internal_originalTime": 1689823227000
    },
    {
        "time": 1689823228000,
        "value": 139.16,
        "_internal_originalTime": 1689823228000
    },
    {
        "time": 1689823229000,
        "value": 139.154,
        "_internal_originalTime": 1689823229000
    },
    {
        "time": 1689823231000,
        "value": 139.153,
        "_internal_originalTime": 1689823231000
    },
    {
        "time": 1689823236000,
        "value": 139.152,
        "_internal_originalTime": 1689823236000
    },
    {
        "time": 1689823237000,
        "value": 139.153,
        "_internal_originalTime": 1689823237000
    },
    {
        "time": 1689823238000,
        "value": 139.157,
        "_internal_originalTime": 1689823238000
    },
    {
        "time": 1689823240000,
        "value": 139.156,
        "_internal_originalTime": 1689823240000
    },
    {
        "time": 1689823244000,
        "value": 139.156,
        "_internal_originalTime": 1689823244000
    },
    {
        "time": 1689823246000,
        "value": 139.152,
        "_internal_originalTime": 1689823246000
    },
    {
        "time": 1689823247000,
        "value": 139.155,
        "_internal_originalTime": 1689823247000
    },
    {
        "time": 1689823248000,
        "value": 139.148,
        "_internal_originalTime": 1689823248000
    },
    {
        "time": 1689823249000,
        "value": 139.151,
        "_internal_originalTime": 1689823249000
    },
    {
        "time": 1689823255000,
        "value": 139.149,
        "_internal_originalTime": 1689823255000
    },
    {
        "time": 1689823257000,
        "value": 139.148,
        "_internal_originalTime": 1689823257000
    },
    {
        "time": 1689823259000,
        "value": 139.149,
        "_internal_originalTime": 1689823259000
    },
    {
        "time": 1689823261000,
        "value": 139.148,
        "_internal_originalTime": 1689823261000
    },
    {
        "time": 1689823262000,
        "value": 139.15,
        "_internal_originalTime": 1689823262000
    },
    {
        "time": 1689823263000,
        "value": 139.149,
        "_internal_originalTime": 1689823263000
    },
    {
        "time": 1689823264000,
        "value": 139.144,
        "_internal_originalTime": 1689823264000
    },
    {
        "time": 1689823268000,
        "value": 139.153,
        "_internal_originalTime": 1689823268000
    },
    {
        "time": 1689823269000,
        "value": 139.156,
        "_internal_originalTime": 1689823269000
    },
    {
        "time": 1689823270000,
        "value": 139.152,
        "_internal_originalTime": 1689823270000
    },
    {
        "time": 1689823272000,
        "value": 139.156,
        "_internal_originalTime": 1689823272000
    },
    {
        "time": 1689823273000,
        "value": 139.157,
        "_internal_originalTime": 1689823273000
    },
    {
        "time": 1689823275000,
        "value": 139.162,
        "_internal_originalTime": 1689823275000
    },
    {
        "time": 1689823276000,
        "value": 139.17,
        "_internal_originalTime": 1689823276000
    },
    {
        "time": 1689823278000,
        "value": 139.17,
        "_internal_originalTime": 1689823278000
    },
    {
        "time": 1689823279000,
        "value": 139.155,
        "_internal_originalTime": 1689823279000
    },
    {
        "time": 1689823280000,
        "value": 139.157,
        "_internal_originalTime": 1689823280000
    },
    {
        "time": 1689823281000,
        "value": 139.156,
        "_internal_originalTime": 1689823281000
    },
    {
        "time": 1689823288000,
        "value": 139.161,
        "_internal_originalTime": 1689823288000
    },
    {
        "time": 1689823289000,
        "value": 139.159,
        "_internal_originalTime": 1689823289000
    },
    {
        "time": 1689823290000,
        "value": 139.156,
        "_internal_originalTime": 1689823290000
    },
    {
        "time": 1689823292000,
        "value": 139.161,
        "_internal_originalTime": 1689823292000
    },
    {
        "time": 1689823293000,
        "value": 139.158,
        "_internal_originalTime": 1689823293000
    },
    {
        "time": 1689823294000,
        "value": 139.155,
        "_internal_originalTime": 1689823294000
    },
    {
        "time": 1689823295000,
        "value": 139.155,
        "_internal_originalTime": 1689823295000
    },
    {
        "time": 1689823297000,
        "value": 139.156,
        "_internal_originalTime": 1689823297000
    },
    {
        "time": 1689823299000,
        "value": 139.155,
        "_internal_originalTime": 1689823299000
    },
    {
        "time": 1689823300000,
        "value": 139.155,
        "_internal_originalTime": 1689823300000
    },
    {
        "time": 1689823302000,
        "value": 139.156,
        "_internal_originalTime": 1689823302000
    },
    {
        "time": 1689823303000,
        "value": 139.153,
        "_internal_originalTime": 1689823303000
    },
    {
        "time": 1689823305000,
        "value": 139.152,
        "_internal_originalTime": 1689823305000
    },
    {
        "time": 1689823306000,
        "value": 139.156,
        "_internal_originalTime": 1689823306000
    },
    {
        "time": 1689823307000,
        "value": 139.156,
        "_internal_originalTime": 1689823307000
    },
    {
        "time": 1689823309000,
        "value": 139.155,
        "_internal_originalTime": 1689823309000
    },
    {
        "time": 1689823310000,
        "value": 139.156,
        "_internal_originalTime": 1689823310000
    },
    {
        "time": 1689823311000,
        "value": 139.145,
        "_internal_originalTime": 1689823311000
    },
    {
        "time": 1689823312000,
        "value": 139.144,
        "_internal_originalTime": 1689823312000
    },
    {
        "time": 1689823314000,
        "value": 139.146,
        "_internal_originalTime": 1689823314000
    },
    {
        "time": 1689823315000,
        "value": 139.144,
        "_internal_originalTime": 1689823315000
    },
    {
        "time": 1689823318000,
        "value": 139.145,
        "_internal_originalTime": 1689823318000
    },
    {
        "time": 1689823320000,
        "value": 139.144,
        "_internal_originalTime": 1689823320000
    },
    {
        "time": 1689823323000,
        "value": 139.144,
        "_internal_originalTime": 1689823323000
    },
    {
        "time": 1689823324000,
        "value": 139.146,
        "_internal_originalTime": 1689823324000
    },
    {
        "time": 1689823325000,
        "value": 139.147,
        "_internal_originalTime": 1689823325000
    },
    {
        "time": 1689823326000,
        "value": 139.153,
        "_internal_originalTime": 1689823326000
    },
    {
        "time": 1689823327000,
        "value": 139.15,
        "_internal_originalTime": 1689823327000
    },
    {
        "time": 1689823329000,
        "value": 139.147,
        "_internal_originalTime": 1689823329000
    },
    {
        "time": 1689823331000,
        "value": 139.148,
        "_internal_originalTime": 1689823331000
    },
    {
        "time": 1689823332000,
        "value": 139.148,
        "_internal_originalTime": 1689823332000
    },
    {
        "time": 1689823333000,
        "value": 139.146,
        "_internal_originalTime": 1689823333000
    },
    {
        "time": 1689823334000,
        "value": 139.147,
        "_internal_originalTime": 1689823334000
    },
    {
        "time": 1689823335000,
        "value": 139.149,
        "_internal_originalTime": 1689823335000
    },
    {
        "time": 1689823338000,
        "value": 139.144,
        "_internal_originalTime": 1689823338000
    },
    {
        "time": 1689823340000,
        "value": 139.148,
        "_internal_originalTime": 1689823340000
    },
    {
        "time": 1689823342000,
        "value": 139.148,
        "_internal_originalTime": 1689823342000
    },
    {
        "time": 1689823343000,
        "value": 139.131,
        "_internal_originalTime": 1689823343000
    },
    {
        "time": 1689823345000,
        "value": 139.126,
        "_internal_originalTime": 1689823345000
    },
    {
        "time": 1689823346000,
        "value": 139.13,
        "_internal_originalTime": 1689823346000
    },
    {
        "time": 1689823347000,
        "value": 139.124,
        "_internal_originalTime": 1689823347000
    },
    {
        "time": 1689823348000,
        "value": 139.129,
        "_internal_originalTime": 1689823348000
    },
    {
        "time": 1689823350000,
        "value": 139.122,
        "_internal_originalTime": 1689823350000
    },
    {
        "time": 1689823352000,
        "value": 139.12,
        "_internal_originalTime": 1689823352000
    },
    {
        "time": 1689823353000,
        "value": 139.12,
        "_internal_originalTime": 1689823353000
    },
    {
        "time": 1689823354000,
        "value": 139.122,
        "_internal_originalTime": 1689823354000
    },
    {
        "time": 1689823356000,
        "value": 139.127,
        "_internal_originalTime": 1689823356000
    },
    {
        "time": 1689823357000,
        "value": 139.131,
        "_internal_originalTime": 1689823357000
    },
    {
        "time": 1689823359000,
        "value": 139.129,
        "_internal_originalTime": 1689823359000
    },
    {
        "time": 1689823360000,
        "value": 139.126,
        "_internal_originalTime": 1689823360000
    },
    {
        "time": 1689823364000,
        "value": 139.136,
        "_internal_originalTime": 1689823364000
    },
    {
        "time": 1689823365000,
        "value": 139.145,
        "_internal_originalTime": 1689823365000
    },
    {
        "time": 1689823366000,
        "value": 139.145,
        "_internal_originalTime": 1689823366000
    },
    {
        "time": 1689823368000,
        "value": 139.145,
        "_internal_originalTime": 1689823368000
    },
    {
        "time": 1689823369000,
        "value": 139.147,
        "_internal_originalTime": 1689823369000
    },
    {
        "time": 1689823374000,
        "value": 139.146,
        "_internal_originalTime": 1689823374000
    },
    {
        "time": 1689823376000,
        "value": 139.146,
        "_internal_originalTime": 1689823376000
    },
    {
        "time": 1689823377000,
        "value": 139.147,
        "_internal_originalTime": 1689823377000
    },
    {
        "time": 1689823378000,
        "value": 139.142,
        "_internal_originalTime": 1689823378000
    },
    {
        "time": 1689823380000,
        "value": 139.154,
        "_internal_originalTime": 1689823380000
    },
    {
        "time": 1689823383000,
        "value": 139.154,
        "_internal_originalTime": 1689823383000
    },
    {
        "time": 1689823387000,
        "value": 139.148,
        "_internal_originalTime": 1689823387000
    },
    {
        "time": 1689823388000,
        "value": 139.147,
        "_internal_originalTime": 1689823388000
    },
    {
        "time": 1689823390000,
        "value": 139.149,
        "_internal_originalTime": 1689823390000
    },
    {
        "time": 1689823391000,
        "value": 139.148,
        "_internal_originalTime": 1689823391000
    },
    {
        "time": 1689823392000,
        "value": 139.152,
        "_internal_originalTime": 1689823392000
    },
    {
        "time": 1689823403000,
        "value": 139.148,
        "_internal_originalTime": 1689823403000
    },
    {
        "time": 1689823404000,
        "value": 139.154,
        "_internal_originalTime": 1689823404000
    },
    {
        "time": 1689823405000,
        "value": 139.152,
        "_internal_originalTime": 1689823405000
    },
    {
        "time": 1689823408000,
        "value": 139.152,
        "_internal_originalTime": 1689823408000
    },
    {
        "time": 1689823409000,
        "value": 139.148,
        "_internal_originalTime": 1689823409000
    },
    {
        "time": 1689823411000,
        "value": 139.146,
        "_internal_originalTime": 1689823411000
    },
    {
        "time": 1689823412000,
        "value": 139.154,
        "_internal_originalTime": 1689823412000
    },
    {
        "time": 1689823414000,
        "value": 139.152,
        "_internal_originalTime": 1689823414000
    },
    {
        "time": 1689823415000,
        "value": 139.152,
        "_internal_originalTime": 1689823415000
    },
    {
        "time": 1689823416000,
        "value": 139.158,
        "_internal_originalTime": 1689823416000
    },
    {
        "time": 1689823417000,
        "value": 139.159,
        "_internal_originalTime": 1689823417000
    },
    {
        "time": 1689823420000,
        "value": 139.156,
        "_internal_originalTime": 1689823420000
    },
    {
        "time": 1689823421000,
        "value": 139.156,
        "_internal_originalTime": 1689823421000
    },
    {
        "time": 1689823422000,
        "value": 139.157,
        "_internal_originalTime": 1689823422000
    },
    {
        "time": 1689823425000,
        "value": 139.156,
        "_internal_originalTime": 1689823425000
    },
    {
        "time": 1689823426000,
        "value": 139.155,
        "_internal_originalTime": 1689823426000
    },
    {
        "time": 1689823429000,
        "value": 139.155,
        "_internal_originalTime": 1689823429000
    },
    {
        "time": 1689823430000,
        "value": 139.155,
        "_internal_originalTime": 1689823430000
    },
    {
        "time": 1689823431000,
        "value": 139.155,
        "_internal_originalTime": 1689823431000
    },
    {
        "time": 1689823436000,
        "value": 139.152,
        "_internal_originalTime": 1689823436000
    },
    {
        "time": 1689823437000,
        "value": 139.15,
        "_internal_originalTime": 1689823437000
    },
    {
        "time": 1689823439000,
        "value": 139.15,
        "_internal_originalTime": 1689823439000
    },
    {
        "time": 1689823440000,
        "value": 139.149,
        "_internal_originalTime": 1689823440000
    },
    {
        "time": 1689823443000,
        "value": 139.151,
        "_internal_originalTime": 1689823443000
    },
    {
        "time": 1689823446000,
        "value": 139.148,
        "_internal_originalTime": 1689823446000
    },
    {
        "time": 1689823447000,
        "value": 139.148,
        "_internal_originalTime": 1689823447000
    },
    {
        "time": 1689823449000,
        "value": 139.146,
        "_internal_originalTime": 1689823449000
    },
    {
        "time": 1689823450000,
        "value": 139.149,
        "_internal_originalTime": 1689823450000
    },
    {
        "time": 1689823451000,
        "value": 139.147,
        "_internal_originalTime": 1689823451000
    },
    {
        "time": 1689823452000,
        "value": 139.146,
        "_internal_originalTime": 1689823452000
    },
    {
        "time": 1689823453000,
        "value": 139.145,
        "_internal_originalTime": 1689823453000
    },
    {
        "time": 1689823456000,
        "value": 139.146,
        "_internal_originalTime": 1689823456000
    },
    {
        "time": 1689823458000,
        "value": 139.148,
        "_internal_originalTime": 1689823458000
    },
    {
        "time": 1689823459000,
        "value": 139.153,
        "_internal_originalTime": 1689823459000
    },
    {
        "time": 1689823462000,
        "value": 139.152,
        "_internal_originalTime": 1689823462000
    },
    {
        "time": 1689823464000,
        "value": 139.144,
        "_internal_originalTime": 1689823464000
    },
    {
        "time": 1689823466000,
        "value": 139.149,
        "_internal_originalTime": 1689823466000
    },
    {
        "time": 1689823467000,
        "value": 139.148,
        "_internal_originalTime": 1689823467000
    },
    {
        "time": 1689823469000,
        "value": 139.152,
        "_internal_originalTime": 1689823469000
    },
    {
        "time": 1689823470000,
        "value": 139.148,
        "_internal_originalTime": 1689823470000
    },
    {
        "time": 1689823471000,
        "value": 139.153,
        "_internal_originalTime": 1689823471000
    },
    {
        "time": 1689823472000,
        "value": 139.15,
        "_internal_originalTime": 1689823472000
    },
    {
        "time": 1689823473000,
        "value": 139.15,
        "_internal_originalTime": 1689823473000
    },
    {
        "time": 1689823475000,
        "value": 139.151,
        "_internal_originalTime": 1689823475000
    },
    {
        "time": 1689823477000,
        "value": 139.157,
        "_internal_originalTime": 1689823477000
    },
    {
        "time": 1689823478000,
        "value": 139.15,
        "_internal_originalTime": 1689823478000
    },
    {
        "time": 1689823480000,
        "value": 139.153,
        "_internal_originalTime": 1689823480000
    },
    {
        "time": 1689823482000,
        "value": 139.15,
        "_internal_originalTime": 1689823482000
    },
    {
        "time": 1689823484000,
        "value": 139.149,
        "_internal_originalTime": 1689823484000
    },
    {
        "time": 1689823485000,
        "value": 139.153,
        "_internal_originalTime": 1689823485000
    },
    {
        "time": 1689823486000,
        "value": 139.153,
        "_internal_originalTime": 1689823486000
    },
    {
        "time": 1689823489000,
        "value": 139.15,
        "_internal_originalTime": 1689823489000
    },
    {
        "time": 1689823494000,
        "value": 139.15,
        "_internal_originalTime": 1689823494000
    },
    {
        "time": 1689823495000,
        "value": 139.154,
        "_internal_originalTime": 1689823495000
    },
    {
        "time": 1689823496000,
        "value": 139.15,
        "_internal_originalTime": 1689823496000
    },
    {
        "time": 1689823503000,
        "value": 139.153,
        "_internal_originalTime": 1689823503000
    },
    {
        "time": 1689823504000,
        "value": 139.151,
        "_internal_originalTime": 1689823504000
    },
    {
        "time": 1689823506000,
        "value": 139.15,
        "_internal_originalTime": 1689823506000
    },
    {
        "time": 1689823507000,
        "value": 139.156,
        "_internal_originalTime": 1689823507000
    },
    {
        "time": 1689823508000,
        "value": 139.154,
        "_internal_originalTime": 1689823508000
    },
    {
        "time": 1689823509000,
        "value": 139.153,
        "_internal_originalTime": 1689823509000
    },
    {
        "time": 1689823512000,
        "value": 139.153,
        "_internal_originalTime": 1689823512000
    },
    {
        "time": 1689823513000,
        "value": 139.15,
        "_internal_originalTime": 1689823513000
    },
    {
        "time": 1689823514000,
        "value": 139.153,
        "_internal_originalTime": 1689823514000
    },
    {
        "time": 1689823515000,
        "value": 139.152,
        "_internal_originalTime": 1689823515000
    },
    {
        "time": 1689823516000,
        "value": 139.153,
        "_internal_originalTime": 1689823516000
    },
    {
        "time": 1689823518000,
        "value": 139.157,
        "_internal_originalTime": 1689823518000
    },
    {
        "time": 1689823519000,
        "value": 139.162,
        "_internal_originalTime": 1689823519000
    },
    {
        "time": 1689823522000,
        "value": 139.158,
        "_internal_originalTime": 1689823522000
    },
    {
        "time": 1689823525000,
        "value": 139.169,
        "_internal_originalTime": 1689823525000
    },
    {
        "time": 1689823526000,
        "value": 139.167,
        "_internal_originalTime": 1689823526000
    },
    {
        "time": 1689823528000,
        "value": 139.166,
        "_internal_originalTime": 1689823528000
    },
    {
        "time": 1689823530000,
        "value": 139.16,
        "_internal_originalTime": 1689823530000
    },
    {
        "time": 1689823532000,
        "value": 139.168,
        "_internal_originalTime": 1689823532000
    },
    {
        "time": 1689823534000,
        "value": 139.165,
        "_internal_originalTime": 1689823534000
    },
    {
        "time": 1689823535000,
        "value": 139.163,
        "_internal_originalTime": 1689823535000
    },
    {
        "time": 1689823536000,
        "value": 139.169,
        "_internal_originalTime": 1689823536000
    },
    {
        "time": 1689823537000,
        "value": 139.168,
        "_internal_originalTime": 1689823537000
    },
    {
        "time": 1689823539000,
        "value": 139.164,
        "_internal_originalTime": 1689823539000
    },
    {
        "time": 1689823540000,
        "value": 139.162,
        "_internal_originalTime": 1689823540000
    },
    {
        "time": 1689823542000,
        "value": 139.159,
        "_internal_originalTime": 1689823542000
    },
    {
        "time": 1689823543000,
        "value": 139.167,
        "_internal_originalTime": 1689823543000
    },
    {
        "time": 1689823544000,
        "value": 139.16,
        "_internal_originalTime": 1689823544000
    },
    {
        "time": 1689823546000,
        "value": 139.162,
        "_internal_originalTime": 1689823546000
    },
    {
        "time": 1689823548000,
        "value": 139.162,
        "_internal_originalTime": 1689823548000
    },
    {
        "time": 1689823549000,
        "value": 139.162,
        "_internal_originalTime": 1689823549000
    },
    {
        "time": 1689823552000,
        "value": 139.169,
        "_internal_originalTime": 1689823552000
    },
    {
        "time": 1689823553000,
        "value": 139.165,
        "_internal_originalTime": 1689823553000
    },
    {
        "time": 1689823556000,
        "value": 139.162,
        "_internal_originalTime": 1689823556000
    },
    {
        "time": 1689823557000,
        "value": 139.163,
        "_internal_originalTime": 1689823557000
    },
    {
        "time": 1689823558000,
        "value": 139.166,
        "_internal_originalTime": 1689823558000
    },
    {
        "time": 1689823559000,
        "value": 139.163,
        "_internal_originalTime": 1689823559000
    },
    {
        "time": 1689823561000,
        "value": 139.162,
        "_internal_originalTime": 1689823561000
    },
    {
        "time": 1689823562000,
        "value": 139.163,
        "_internal_originalTime": 1689823562000
    },
    {
        "time": 1689823563000,
        "value": 139.166,
        "_internal_originalTime": 1689823563000
    },
    {
        "time": 1689823564000,
        "value": 139.165,
        "_internal_originalTime": 1689823564000
    },
    {
        "time": 1689823565000,
        "value": 139.168,
        "_internal_originalTime": 1689823565000
    },
    {
        "time": 1689823569000,
        "value": 139.165,
        "_internal_originalTime": 1689823569000
    },
    {
        "time": 1689823571000,
        "value": 139.169,
        "_internal_originalTime": 1689823571000
    },
    {
        "time": 1689823573000,
        "value": 139.167,
        "_internal_originalTime": 1689823573000
    },
    {
        "time": 1689823574000,
        "value": 139.168,
        "_internal_originalTime": 1689823574000
    },
    {
        "time": 1689823576000,
        "value": 139.165,
        "_internal_originalTime": 1689823576000
    },
    {
        "time": 1689823578000,
        "value": 139.168,
        "_internal_originalTime": 1689823578000
    },
    {
        "time": 1689823579000,
        "value": 139.164,
        "_internal_originalTime": 1689823579000
    },
    {
        "time": 1689823583000,
        "value": 139.167,
        "_internal_originalTime": 1689823583000
    },
    {
        "time": 1689823585000,
        "value": 139.167,
        "_internal_originalTime": 1689823585000
    },
    {
        "time": 1689823587000,
        "value": 139.168,
        "_internal_originalTime": 1689823587000
    },
    {
        "time": 1689823588000,
        "value": 139.168,
        "_internal_originalTime": 1689823588000
    },
    {
        "time": 1689823589000,
        "value": 139.166,
        "_internal_originalTime": 1689823589000
    },
    {
        "time": 1689823590000,
        "value": 139.162,
        "_internal_originalTime": 1689823590000
    },
    {
        "time": 1689823593000,
        "value": 139.166,
        "_internal_originalTime": 1689823593000
    },
    {
        "time": 1689823597000,
        "value": 139.169,
        "_internal_originalTime": 1689823597000
    },
    {
        "time": 1689823599000,
        "value": 139.166,
        "_internal_originalTime": 1689823599000
    },
    {
        "time": 1689823601000,
        "value": 139.17,
        "_internal_originalTime": 1689823601000
    },
    {
        "time": 1689823603000,
        "value": 139.166,
        "_internal_originalTime": 1689823603000
    },
    {
        "time": 1689823605000,
        "value": 139.165,
        "_internal_originalTime": 1689823605000
    },
    {
        "time": 1689823606000,
        "value": 139.168,
        "_internal_originalTime": 1689823606000
    },
    {
        "time": 1689823607000,
        "value": 139.167,
        "_internal_originalTime": 1689823607000
    },
    {
        "time": 1689823609000,
        "value": 139.169,
        "_internal_originalTime": 1689823609000
    },
    {
        "time": 1689823614000,
        "value": 139.168,
        "_internal_originalTime": 1689823614000
    },
    {
        "time": 1689823615000,
        "value": 139.171,
        "_internal_originalTime": 1689823615000
    },
    {
        "time": 1689823620000,
        "value": 139.172,
        "_internal_originalTime": 1689823620000
    },
    {
        "time": 1689823621000,
        "value": 139.159,
        "_internal_originalTime": 1689823621000
    },
    {
        "time": 1689823622000,
        "value": 139.161,
        "_internal_originalTime": 1689823622000
    },
    {
        "time": 1689823624000,
        "value": 139.158,
        "_internal_originalTime": 1689823624000
    },
    {
        "time": 1689823626000,
        "value": 139.16,
        "_internal_originalTime": 1689823626000
    },
    {
        "time": 1689823629000,
        "value": 139.16,
        "_internal_originalTime": 1689823629000
    },
    {
        "time": 1689823631000,
        "value": 139.16,
        "_internal_originalTime": 1689823631000
    },
    {
        "time": 1689823633000,
        "value": 139.167,
        "_internal_originalTime": 1689823633000
    },
    {
        "time": 1689823635000,
        "value": 139.167,
        "_internal_originalTime": 1689823635000
    },
    {
        "time": 1689823637000,
        "value": 139.162,
        "_internal_originalTime": 1689823637000
    },
    {
        "time": 1689823638000,
        "value": 139.163,
        "_internal_originalTime": 1689823638000
    },
    {
        "time": 1689823639000,
        "value": 139.163,
        "_internal_originalTime": 1689823639000
    },
    {
        "time": 1689823640000,
        "value": 139.163,
        "_internal_originalTime": 1689823640000
    },
    {
        "time": 1689823642000,
        "value": 139.162,
        "_internal_originalTime": 1689823642000
    },
    {
        "time": 1689823643000,
        "value": 139.16,
        "_internal_originalTime": 1689823643000
    },
    {
        "time": 1689823644000,
        "value": 139.159,
        "_internal_originalTime": 1689823644000
    },
    {
        "time": 1689823646000,
        "value": 139.157,
        "_internal_originalTime": 1689823646000
    },
    {
        "time": 1689823647000,
        "value": 139.156,
        "_internal_originalTime": 1689823647000
    },
    {
        "time": 1689823649000,
        "value": 139.159,
        "_internal_originalTime": 1689823649000
    },
    {
        "time": 1689823650000,
        "value": 139.161,
        "_internal_originalTime": 1689823650000
    },
    {
        "time": 1689823652000,
        "value": 139.16,
        "_internal_originalTime": 1689823652000
    },
    {
        "time": 1689823654000,
        "value": 139.159,
        "_internal_originalTime": 1689823654000
    },
    {
        "time": 1689823655000,
        "value": 139.161,
        "_internal_originalTime": 1689823655000
    },
    {
        "time": 1689823657000,
        "value": 139.156,
        "_internal_originalTime": 1689823657000
    },
    {
        "time": 1689823658000,
        "value": 139.16,
        "_internal_originalTime": 1689823658000
    },
    {
        "time": 1689823659000,
        "value": 139.159,
        "_internal_originalTime": 1689823659000
    },
    {
        "time": 1689823660000,
        "value": 139.156,
        "_internal_originalTime": 1689823660000
    },
    {
        "time": 1689823661000,
        "value": 139.16,
        "_internal_originalTime": 1689823661000
    },
    {
        "time": 1689823663000,
        "value": 139.157,
        "_internal_originalTime": 1689823663000
    },
    {
        "time": 1689823664000,
        "value": 139.159,
        "_internal_originalTime": 1689823664000
    },
    {
        "time": 1689823666000,
        "value": 139.154,
        "_internal_originalTime": 1689823666000
    },
    {
        "time": 1689823668000,
        "value": 139.154,
        "_internal_originalTime": 1689823668000
    },
    {
        "time": 1689823670000,
        "value": 139.154,
        "_internal_originalTime": 1689823670000
    },
    {
        "time": 1689823672000,
        "value": 139.16,
        "_internal_originalTime": 1689823672000
    },
    {
        "time": 1689823674000,
        "value": 139.159,
        "_internal_originalTime": 1689823674000
    },
    {
        "time": 1689823675000,
        "value": 139.155,
        "_internal_originalTime": 1689823675000
    },
    {
        "time": 1689823676000,
        "value": 139.158,
        "_internal_originalTime": 1689823676000
    },
    {
        "time": 1689823678000,
        "value": 139.156,
        "_internal_originalTime": 1689823678000
    },
    {
        "time": 1689823679000,
        "value": 139.152,
        "_internal_originalTime": 1689823679000
    },
    {
        "time": 1689823680000,
        "value": 139.151,
        "_internal_originalTime": 1689823680000
    },
    {
        "time": 1689823686000,
        "value": 139.161,
        "_internal_originalTime": 1689823686000
    },
    {
        "time": 1689823687000,
        "value": 139.161,
        "_internal_originalTime": 1689823687000
    },
    {
        "time": 1689823689000,
        "value": 139.157,
        "_internal_originalTime": 1689823689000
    },
    {
        "time": 1689823691000,
        "value": 139.157,
        "_internal_originalTime": 1689823691000
    },
    {
        "time": 1689823698000,
        "value": 139.155,
        "_internal_originalTime": 1689823698000
    },
    {
        "time": 1689823701000,
        "value": 139.154,
        "_internal_originalTime": 1689823701000
    },
    {
        "time": 1689823703000,
        "value": 139.159,
        "_internal_originalTime": 1689823703000
    },
    {
        "time": 1689823704000,
        "value": 139.154,
        "_internal_originalTime": 1689823704000
    },
    {
        "time": 1689823705000,
        "value": 139.154,
        "_internal_originalTime": 1689823705000
    },
    {
        "time": 1689823706000,
        "value": 139.155,
        "_internal_originalTime": 1689823706000
    },
    {
        "time": 1689823708000,
        "value": 139.16,
        "_internal_originalTime": 1689823708000
    },
    {
        "time": 1689823710000,
        "value": 139.154,
        "_internal_originalTime": 1689823710000
    },
    {
        "time": 1689823714000,
        "value": 139.156,
        "_internal_originalTime": 1689823714000
    },
    {
        "time": 1689823715000,
        "value": 139.157,
        "_internal_originalTime": 1689823715000
    },
    {
        "time": 1689823717000,
        "value": 139.157,
        "_internal_originalTime": 1689823717000
    },
    {
        "time": 1689823718000,
        "value": 139.159,
        "_internal_originalTime": 1689823718000
    },
    {
        "time": 1689823720000,
        "value": 139.161,
        "_internal_originalTime": 1689823720000
    },
    {
        "time": 1689823721000,
        "value": 139.157,
        "_internal_originalTime": 1689823721000
    },
    {
        "time": 1689823722000,
        "value": 139.153,
        "_internal_originalTime": 1689823722000
    },
    {
        "time": 1689823724000,
        "value": 139.154,
        "_internal_originalTime": 1689823724000
    },
    {
        "time": 1689823727000,
        "value": 139.157,
        "_internal_originalTime": 1689823727000
    },
    {
        "time": 1689823728000,
        "value": 139.152,
        "_internal_originalTime": 1689823728000
    },
    {
        "time": 1689823731000,
        "value": 139.157,
        "_internal_originalTime": 1689823731000
    },
    {
        "time": 1689823732000,
        "value": 139.156,
        "_internal_originalTime": 1689823732000
    },
    {
        "time": 1689823734000,
        "value": 139.152,
        "_internal_originalTime": 1689823734000
    },
    {
        "time": 1689823735000,
        "value": 139.159,
        "_internal_originalTime": 1689823735000
    },
    {
        "time": 1689823736000,
        "value": 139.153,
        "_internal_originalTime": 1689823736000
    },
    {
        "time": 1689823737000,
        "value": 139.152,
        "_internal_originalTime": 1689823737000
    },
    {
        "time": 1689823739000,
        "value": 139.155,
        "_internal_originalTime": 1689823739000
    },
    {
        "time": 1689823740000,
        "value": 139.151,
        "_internal_originalTime": 1689823740000
    },
    {
        "time": 1689823741000,
        "value": 139.152,
        "_internal_originalTime": 1689823741000
    },
    {
        "time": 1689823743000,
        "value": 139.157,
        "_internal_originalTime": 1689823743000
    },
    {
        "time": 1689823744000,
        "value": 139.152,
        "_internal_originalTime": 1689823744000
    },
    {
        "time": 1689823745000,
        "value": 139.153,
        "_internal_originalTime": 1689823745000
    },
    {
        "time": 1689823746000,
        "value": 139.152,
        "_internal_originalTime": 1689823746000
    },
    {
        "time": 1689823747000,
        "value": 139.153,
        "_internal_originalTime": 1689823747000
    },
    {
        "time": 1689823749000,
        "value": 139.153,
        "_internal_originalTime": 1689823749000
    },
    {
        "time": 1689823750000,
        "value": 139.153,
        "_internal_originalTime": 1689823750000
    },
    {
        "time": 1689823751000,
        "value": 139.157,
        "_internal_originalTime": 1689823751000
    },
    {
        "time": 1689823752000,
        "value": 139.152,
        "_internal_originalTime": 1689823752000
    },
    {
        "time": 1689823754000,
        "value": 139.162,
        "_internal_originalTime": 1689823754000
    },
    {
        "time": 1689823755000,
        "value": 139.157,
        "_internal_originalTime": 1689823755000
    },
    {
        "time": 1689823756000,
        "value": 139.156,
        "_internal_originalTime": 1689823756000
    },
    {
        "time": 1689823757000,
        "value": 139.158,
        "_internal_originalTime": 1689823757000
    },
    {
        "time": 1689823759000,
        "value": 139.166,
        "_internal_originalTime": 1689823759000
    },
    {
        "time": 1689823760000,
        "value": 139.16,
        "_internal_originalTime": 1689823760000
    },
    {
        "time": 1689823762000,
        "value": 139.166,
        "_internal_originalTime": 1689823762000
    },
    {
        "time": 1689823763000,
        "value": 139.168,
        "_internal_originalTime": 1689823763000
    },
    {
        "time": 1689823764000,
        "value": 139.164,
        "_internal_originalTime": 1689823764000
    },
    {
        "time": 1689823765000,
        "value": 139.17,
        "_internal_originalTime": 1689823765000
    },
    {
        "time": 1689823766000,
        "value": 139.165,
        "_internal_originalTime": 1689823766000
    },
    {
        "time": 1689823767000,
        "value": 139.164,
        "_internal_originalTime": 1689823767000
    },
    {
        "time": 1689823770000,
        "value": 139.162,
        "_internal_originalTime": 1689823770000
    },
    {
        "time": 1689823771000,
        "value": 139.165,
        "_internal_originalTime": 1689823771000
    },
    {
        "time": 1689823772000,
        "value": 139.17,
        "_internal_originalTime": 1689823772000
    },
    {
        "time": 1689823774000,
        "value": 139.168,
        "_internal_originalTime": 1689823774000
    },
    {
        "time": 1689823775000,
        "value": 139.166,
        "_internal_originalTime": 1689823775000
    },
    {
        "time": 1689823776000,
        "value": 139.168,
        "_internal_originalTime": 1689823776000
    },
    {
        "time": 1689823778000,
        "value": 139.171,
        "_internal_originalTime": 1689823778000
    },
    {
        "time": 1689823779000,
        "value": 139.166,
        "_internal_originalTime": 1689823779000
    },
    {
        "time": 1689823780000,
        "value": 139.168,
        "_internal_originalTime": 1689823780000
    },
    {
        "time": 1689823782000,
        "value": 139.165,
        "_internal_originalTime": 1689823782000
    },
    {
        "time": 1689823783000,
        "value": 139.177,
        "_internal_originalTime": 1689823783000
    },
    {
        "time": 1689823784000,
        "value": 139.178,
        "_internal_originalTime": 1689823784000
    },
    {
        "time": 1689823785000,
        "value": 139.172,
        "_internal_originalTime": 1689823785000
    },
    {
        "time": 1689823786000,
        "value": 139.181,
        "_internal_originalTime": 1689823786000
    },
    {
        "time": 1689823788000,
        "value": 139.176,
        "_internal_originalTime": 1689823788000
    },
    {
        "time": 1689823790000,
        "value": 139.177,
        "_internal_originalTime": 1689823790000
    },
    {
        "time": 1689823792000,
        "value": 139.176,
        "_internal_originalTime": 1689823792000
    },
    {
        "time": 1689823794000,
        "value": 139.17,
        "_internal_originalTime": 1689823794000
    },
    {
        "time": 1689823795000,
        "value": 139.176,
        "_internal_originalTime": 1689823795000
    },
    {
        "time": 1689823797000,
        "value": 139.178,
        "_internal_originalTime": 1689823797000
    },
    {
        "time": 1689823798000,
        "value": 139.177,
        "_internal_originalTime": 1689823798000
    },
    {
        "time": 1689823799000,
        "value": 139.172,
        "_internal_originalTime": 1689823799000
    },
    {
        "time": 1689823800000,
        "value": 139.168,
        "_internal_originalTime": 1689823800000
    },
    {
        "time": 1689823802000,
        "value": 139.174,
        "_internal_originalTime": 1689823802000
    },
    {
        "time": 1689823804000,
        "value": 139.189,
        "_internal_originalTime": 1689823804000
    },
    {
        "time": 1689823805000,
        "value": 139.19,
        "_internal_originalTime": 1689823805000
    },
    {
        "time": 1689823807000,
        "value": 139.19,
        "_internal_originalTime": 1689823807000
    },
    {
        "time": 1689823808000,
        "value": 139.19,
        "_internal_originalTime": 1689823808000
    },
    {
        "time": 1689823809000,
        "value": 139.186,
        "_internal_originalTime": 1689823809000
    },
    {
        "time": 1689823810000,
        "value": 139.183,
        "_internal_originalTime": 1689823810000
    },
    {
        "time": 1689823812000,
        "value": 139.182,
        "_internal_originalTime": 1689823812000
    },
    {
        "time": 1689823814000,
        "value": 139.189,
        "_internal_originalTime": 1689823814000
    },
    {
        "time": 1689823815000,
        "value": 139.187,
        "_internal_originalTime": 1689823815000
    },
    {
        "time": 1689823816000,
        "value": 139.188,
        "_internal_originalTime": 1689823816000
    },
    {
        "time": 1689823818000,
        "value": 139.204,
        "_internal_originalTime": 1689823818000
    },
    {
        "time": 1689823819000,
        "value": 139.205,
        "_internal_originalTime": 1689823819000
    },
    {
        "time": 1689823821000,
        "value": 139.204,
        "_internal_originalTime": 1689823821000
    },
    {
        "time": 1689823822000,
        "value": 139.206,
        "_internal_originalTime": 1689823822000
    },
    {
        "time": 1689823826000,
        "value": 139.198,
        "_internal_originalTime": 1689823826000
    },
    {
        "time": 1689823827000,
        "value": 139.202,
        "_internal_originalTime": 1689823827000
    },
    {
        "time": 1689823829000,
        "value": 139.196,
        "_internal_originalTime": 1689823829000
    },
    {
        "time": 1689823831000,
        "value": 139.19,
        "_internal_originalTime": 1689823831000
    },
    {
        "time": 1689823832000,
        "value": 139.199,
        "_internal_originalTime": 1689823832000
    },
    {
        "time": 1689823834000,
        "value": 139.199,
        "_internal_originalTime": 1689823834000
    },
    {
        "time": 1689823835000,
        "value": 139.201,
        "_internal_originalTime": 1689823835000
    },
    {
        "time": 1689823836000,
        "value": 139.196,
        "_internal_originalTime": 1689823836000
    },
    {
        "time": 1689823838000,
        "value": 139.196,
        "_internal_originalTime": 1689823838000
    },
    {
        "time": 1689823839000,
        "value": 139.2,
        "_internal_originalTime": 1689823839000
    },
    {
        "time": 1689823840000,
        "value": 139.199,
        "_internal_originalTime": 1689823840000
    },
    {
        "time": 1689823842000,
        "value": 139.193,
        "_internal_originalTime": 1689823842000
    },
    {
        "time": 1689823843000,
        "value": 139.195,
        "_internal_originalTime": 1689823843000
    },
    {
        "time": 1689823844000,
        "value": 139.197,
        "_internal_originalTime": 1689823844000
    },
    {
        "time": 1689823846000,
        "value": 139.192,
        "_internal_originalTime": 1689823846000
    },
    {
        "time": 1689823847000,
        "value": 139.192,
        "_internal_originalTime": 1689823847000
    },
    {
        "time": 1689823848000,
        "value": 139.193,
        "_internal_originalTime": 1689823848000
    },
    {
        "time": 1689823849000,
        "value": 139.192,
        "_internal_originalTime": 1689823849000
    },
    {
        "time": 1689823853000,
        "value": 139.196,
        "_internal_originalTime": 1689823853000
    },
    {
        "time": 1689823855000,
        "value": 139.195,
        "_internal_originalTime": 1689823855000
    },
    {
        "time": 1689823857000,
        "value": 139.192,
        "_internal_originalTime": 1689823857000
    },
    {
        "time": 1689823858000,
        "value": 139.193,
        "_internal_originalTime": 1689823858000
    },
    {
        "time": 1689823859000,
        "value": 139.197,
        "_internal_originalTime": 1689823859000
    },
    {
        "time": 1689823861000,
        "value": 139.195,
        "_internal_originalTime": 1689823861000
    },
    {
        "time": 1689823863000,
        "value": 139.182,
        "_internal_originalTime": 1689823863000
    },
    {
        "time": 1689823864000,
        "value": 139.188,
        "_internal_originalTime": 1689823864000
    },
    {
        "time": 1689823865000,
        "value": 139.188,
        "_internal_originalTime": 1689823865000
    },
    {
        "time": 1689823866000,
        "value": 139.189,
        "_internal_originalTime": 1689823866000
    },
    {
        "time": 1689823867000,
        "value": 139.188,
        "_internal_originalTime": 1689823867000
    },
    {
        "time": 1689823869000,
        "value": 139.186,
        "_internal_originalTime": 1689823869000
    },
    {
        "time": 1689823871000,
        "value": 139.188,
        "_internal_originalTime": 1689823871000
    },
    {
        "time": 1689823875000,
        "value": 139.187,
        "_internal_originalTime": 1689823875000
    },
    {
        "time": 1689823879000,
        "value": 139.18,
        "_internal_originalTime": 1689823879000
    },
    {
        "time": 1689823880000,
        "value": 139.18,
        "_internal_originalTime": 1689823880000
    },
    {
        "time": 1689823881000,
        "value": 139.176,
        "_internal_originalTime": 1689823881000
    },
    {
        "time": 1689823882000,
        "value": 139.177,
        "_internal_originalTime": 1689823882000
    },
    {
        "time": 1689823884000,
        "value": 139.176,
        "_internal_originalTime": 1689823884000
    },
    {
        "time": 1689823885000,
        "value": 139.172,
        "_internal_originalTime": 1689823885000
    },
    {
        "time": 1689823887000,
        "value": 139.172,
        "_internal_originalTime": 1689823887000
    },
    {
        "time": 1689823888000,
        "value": 139.178,
        "_internal_originalTime": 1689823888000
    },
    {
        "time": 1689823889000,
        "value": 139.177,
        "_internal_originalTime": 1689823889000
    },
    {
        "time": 1689823890000,
        "value": 139.17,
        "_internal_originalTime": 1689823890000
    },
    {
        "time": 1689823892000,
        "value": 139.178,
        "_internal_originalTime": 1689823892000
    },
    {
        "time": 1689823894000,
        "value": 139.177,
        "_internal_originalTime": 1689823894000
    },
    {
        "time": 1689823896000,
        "value": 139.176,
        "_internal_originalTime": 1689823896000
    },
    {
        "time": 1689823899000,
        "value": 139.173,
        "_internal_originalTime": 1689823899000
    },
    {
        "time": 1689823900000,
        "value": 139.172,
        "_internal_originalTime": 1689823900000
    },
    {
        "time": 1689823901000,
        "value": 139.173,
        "_internal_originalTime": 1689823901000
    },
    {
        "time": 1689823902000,
        "value": 139.172,
        "_internal_originalTime": 1689823902000
    },
    {
        "time": 1689823905000,
        "value": 139.172,
        "_internal_originalTime": 1689823905000
    },
    {
        "time": 1689823907000,
        "value": 139.177,
        "_internal_originalTime": 1689823907000
    },
    {
        "time": 1689823908000,
        "value": 139.187,
        "_internal_originalTime": 1689823908000
    },
    {
        "time": 1689823909000,
        "value": 139.182,
        "_internal_originalTime": 1689823909000
    },
    {
        "time": 1689823910000,
        "value": 139.182,
        "_internal_originalTime": 1689823910000
    },
    {
        "time": 1689823911000,
        "value": 139.182,
        "_internal_originalTime": 1689823911000
    },
    {
        "time": 1689823913000,
        "value": 139.191,
        "_internal_originalTime": 1689823913000
    },
    {
        "time": 1689823914000,
        "value": 139.185,
        "_internal_originalTime": 1689823914000
    },
    {
        "time": 1689823915000,
        "value": 139.191,
        "_internal_originalTime": 1689823915000
    },
    {
        "time": 1689823917000,
        "value": 139.186,
        "_internal_originalTime": 1689823917000
    },
    {
        "time": 1689823919000,
        "value": 139.19,
        "_internal_originalTime": 1689823919000
    },
    {
        "time": 1689823921000,
        "value": 139.191,
        "_internal_originalTime": 1689823921000
    },
    {
        "time": 1689823922000,
        "value": 139.19,
        "_internal_originalTime": 1689823922000
    },
    {
        "time": 1689823924000,
        "value": 139.184,
        "_internal_originalTime": 1689823924000
    },
    {
        "time": 1689823926000,
        "value": 139.184,
        "_internal_originalTime": 1689823926000
    },
    {
        "time": 1689823928000,
        "value": 139.191,
        "_internal_originalTime": 1689823928000
    },
    {
        "time": 1689823929000,
        "value": 139.188,
        "_internal_originalTime": 1689823929000
    },
    {
        "time": 1689823930000,
        "value": 139.191,
        "_internal_originalTime": 1689823930000
    },
    {
        "time": 1689823932000,
        "value": 139.191,
        "_internal_originalTime": 1689823932000
    },
    {
        "time": 1689823933000,
        "value": 139.192,
        "_internal_originalTime": 1689823933000
    },
    {
        "time": 1689823934000,
        "value": 139.191,
        "_internal_originalTime": 1689823934000
    },
    {
        "time": 1689823936000,
        "value": 139.188,
        "_internal_originalTime": 1689823936000
    },
    {
        "time": 1689823937000,
        "value": 139.192,
        "_internal_originalTime": 1689823937000
    },
    {
        "time": 1689823939000,
        "value": 139.193,
        "_internal_originalTime": 1689823939000
    },
    {
        "time": 1689823941000,
        "value": 139.183,
        "_internal_originalTime": 1689823941000
    },
    {
        "time": 1689823942000,
        "value": 139.182,
        "_internal_originalTime": 1689823942000
    },
    {
        "time": 1689823946000,
        "value": 139.183,
        "_internal_originalTime": 1689823946000
    },
    {
        "time": 1689823947000,
        "value": 139.181,
        "_internal_originalTime": 1689823947000
    },
    {
        "time": 1689823949000,
        "value": 139.181,
        "_internal_originalTime": 1689823949000
    },
    {
        "time": 1689823950000,
        "value": 139.181,
        "_internal_originalTime": 1689823950000
    },
    {
        "time": 1689823953000,
        "value": 139.192,
        "_internal_originalTime": 1689823953000
    },
    {
        "time": 1689823956000,
        "value": 139.185,
        "_internal_originalTime": 1689823956000
    },
    {
        "time": 1689823958000,
        "value": 139.184,
        "_internal_originalTime": 1689823958000
    },
    {
        "time": 1689823959000,
        "value": 139.187,
        "_internal_originalTime": 1689823959000
    },
    {
        "time": 1689823961000,
        "value": 139.186,
        "_internal_originalTime": 1689823961000
    },
    {
        "time": 1689823963000,
        "value": 139.187,
        "_internal_originalTime": 1689823963000
    },
    {
        "time": 1689823965000,
        "value": 139.186,
        "_internal_originalTime": 1689823965000
    },
    {
        "time": 1689823966000,
        "value": 139.188,
        "_internal_originalTime": 1689823966000
    },
    {
        "time": 1689823967000,
        "value": 139.183,
        "_internal_originalTime": 1689823967000
    },
    {
        "time": 1689823968000,
        "value": 139.186,
        "_internal_originalTime": 1689823968000
    },
    {
        "time": 1689823971000,
        "value": 139.182,
        "_internal_originalTime": 1689823971000
    },
    {
        "time": 1689823972000,
        "value": 139.182,
        "_internal_originalTime": 1689823972000
    },
    {
        "time": 1689823974000,
        "value": 139.176,
        "_internal_originalTime": 1689823974000
    },
    {
        "time": 1689823977000,
        "value": 139.181,
        "_internal_originalTime": 1689823977000
    },
    {
        "time": 1689823981000,
        "value": 139.175,
        "_internal_originalTime": 1689823981000
    },
    {
        "time": 1689823984000,
        "value": 139.182,
        "_internal_originalTime": 1689823984000
    },
    {
        "time": 1689823986000,
        "value": 139.182,
        "_internal_originalTime": 1689823986000
    },
    {
        "time": 1689823987000,
        "value": 139.182,
        "_internal_originalTime": 1689823987000
    },
    {
        "time": 1689823989000,
        "value": 139.176,
        "_internal_originalTime": 1689823989000
    },
    {
        "time": 1689823992000,
        "value": 139.181,
        "_internal_originalTime": 1689823992000
    },
    {
        "time": 1689823994000,
        "value": 139.18,
        "_internal_originalTime": 1689823994000
    },
    {
        "time": 1689823995000,
        "value": 139.177,
        "_internal_originalTime": 1689823995000
    },
    {
        "time": 1689823996000,
        "value": 139.181,
        "_internal_originalTime": 1689823996000
    },
    {
        "time": 1689823997000,
        "value": 139.182,
        "_internal_originalTime": 1689823997000
    },
    {
        "time": 1689823999000,
        "value": 139.178,
        "_internal_originalTime": 1689823999000
    },
    {
        "time": 1689824000000,
        "value": 139.189,
        "_internal_originalTime": 1689824000000
    },
    {
        "time": 1689824001000,
        "value": 139.188,
        "_internal_originalTime": 1689824001000
    },
    {
        "time": 1689824002000,
        "value": 139.19,
        "_internal_originalTime": 1689824002000
    },
    {
        "time": 1689824004000,
        "value": 139.186,
        "_internal_originalTime": 1689824004000
    },
    {
        "time": 1689824005000,
        "value": 139.183,
        "_internal_originalTime": 1689824005000
    },
    {
        "time": 1689824007000,
        "value": 139.187,
        "_internal_originalTime": 1689824007000
    },
    {
        "time": 1689824008000,
        "value": 139.187,
        "_internal_originalTime": 1689824008000
    },
    {
        "time": 1689824010000,
        "value": 139.19,
        "_internal_originalTime": 1689824010000
    },
    {
        "time": 1689824011000,
        "value": 139.192,
        "_internal_originalTime": 1689824011000
    },
    {
        "time": 1689824013000,
        "value": 139.191,
        "_internal_originalTime": 1689824013000
    },
    {
        "time": 1689824014000,
        "value": 139.19,
        "_internal_originalTime": 1689824014000
    },
    {
        "time": 1689824015000,
        "value": 139.183,
        "_internal_originalTime": 1689824015000
    },
    {
        "time": 1689824016000,
        "value": 139.182,
        "_internal_originalTime": 1689824016000
    },
    {
        "time": 1689824018000,
        "value": 139.188,
        "_internal_originalTime": 1689824018000
    },
    {
        "time": 1689824019000,
        "value": 139.185,
        "_internal_originalTime": 1689824019000
    },
    {
        "time": 1689824020000,
        "value": 139.187,
        "_internal_originalTime": 1689824020000
    },
    {
        "time": 1689824022000,
        "value": 139.188,
        "_internal_originalTime": 1689824022000
    },
    {
        "time": 1689824023000,
        "value": 139.187,
        "_internal_originalTime": 1689824023000
    },
    {
        "time": 1689824024000,
        "value": 139.189,
        "_internal_originalTime": 1689824024000
    },
    {
        "time": 1689824025000,
        "value": 139.189,
        "_internal_originalTime": 1689824025000
    },
    {
        "time": 1689824026000,
        "value": 139.184,
        "_internal_originalTime": 1689824026000
    },
    {
        "time": 1689824028000,
        "value": 139.187,
        "_internal_originalTime": 1689824028000
    },
    {
        "time": 1689824029000,
        "value": 139.184,
        "_internal_originalTime": 1689824029000
    },
    {
        "time": 1689824030000,
        "value": 139.188,
        "_internal_originalTime": 1689824030000
    },
    {
        "time": 1689824031000,
        "value": 139.187,
        "_internal_originalTime": 1689824031000
    },
    {
        "time": 1689824033000,
        "value": 139.185,
        "_internal_originalTime": 1689824033000
    },
    {
        "time": 1689824035000,
        "value": 139.186,
        "_internal_originalTime": 1689824035000
    },
    {
        "time": 1689824037000,
        "value": 139.183,
        "_internal_originalTime": 1689824037000
    },
    {
        "time": 1689824038000,
        "value": 139.187,
        "_internal_originalTime": 1689824038000
    },
    {
        "time": 1689824039000,
        "value": 139.182,
        "_internal_originalTime": 1689824039000
    },
    {
        "time": 1689824041000,
        "value": 139.178,
        "_internal_originalTime": 1689824041000
    },
    {
        "time": 1689824042000,
        "value": 139.181,
        "_internal_originalTime": 1689824042000
    },
    {
        "time": 1689824043000,
        "value": 139.18,
        "_internal_originalTime": 1689824043000
    },
    {
        "time": 1689824044000,
        "value": 139.183,
        "_internal_originalTime": 1689824044000
    },
    {
        "time": 1689824045000,
        "value": 139.184,
        "_internal_originalTime": 1689824045000
    },
    {
        "time": 1689824047000,
        "value": 139.185,
        "_internal_originalTime": 1689824047000
    },
    {
        "time": 1689824048000,
        "value": 139.181,
        "_internal_originalTime": 1689824048000
    },
    {
        "time": 1689824050000,
        "value": 139.182,
        "_internal_originalTime": 1689824050000
    },
    {
        "time": 1689824051000,
        "value": 139.188,
        "_internal_originalTime": 1689824051000
    },
    {
        "time": 1689824056000,
        "value": 139.184,
        "_internal_originalTime": 1689824056000
    },
    {
        "time": 1689824057000,
        "value": 139.184,
        "_internal_originalTime": 1689824057000
    },
    {
        "time": 1689824058000,
        "value": 139.189,
        "_internal_originalTime": 1689824058000
    },
    {
        "time": 1689824062000,
        "value": 139.187,
        "_internal_originalTime": 1689824062000
    },
    {
        "time": 1689824064000,
        "value": 139.187,
        "_internal_originalTime": 1689824064000
    },
    {
        "time": 1689824065000,
        "value": 139.192,
        "_internal_originalTime": 1689824065000
    },
    {
        "time": 1689824066000,
        "value": 139.19,
        "_internal_originalTime": 1689824066000
    },
    {
        "time": 1689824067000,
        "value": 139.19,
        "_internal_originalTime": 1689824067000
    },
    {
        "time": 1689824069000,
        "value": 139.191,
        "_internal_originalTime": 1689824069000
    },
    {
        "time": 1689824071000,
        "value": 139.191,
        "_internal_originalTime": 1689824071000
    },
    {
        "time": 1689824074000,
        "value": 139.191,
        "_internal_originalTime": 1689824074000
    },
    {
        "time": 1689824076000,
        "value": 139.191,
        "_internal_originalTime": 1689824076000
    },
    {
        "time": 1689824077000,
        "value": 139.192,
        "_internal_originalTime": 1689824077000
    },
    {
        "time": 1689824079000,
        "value": 139.189,
        "_internal_originalTime": 1689824079000
    },
    {
        "time": 1689824085000,
        "value": 139.19,
        "_internal_originalTime": 1689824085000
    },
    {
        "time": 1689824088000,
        "value": 139.191,
        "_internal_originalTime": 1689824088000
    },
    {
        "time": 1689824089000,
        "value": 139.185,
        "_internal_originalTime": 1689824089000
    },
    {
        "time": 1689824091000,
        "value": 139.192,
        "_internal_originalTime": 1689824091000
    },
    {
        "time": 1689824092000,
        "value": 139.186,
        "_internal_originalTime": 1689824092000
    },
    {
        "time": 1689824094000,
        "value": 139.194,
        "_internal_originalTime": 1689824094000
    },
    {
        "time": 1689824095000,
        "value": 139.201,
        "_internal_originalTime": 1689824095000
    },
    {
        "time": 1689824096000,
        "value": 139.195,
        "_internal_originalTime": 1689824096000
    },
    {
        "time": 1689824101000,
        "value": 139.193,
        "_internal_originalTime": 1689824101000
    },
    {
        "time": 1689824102000,
        "value": 139.194,
        "_internal_originalTime": 1689824102000
    },
    {
        "time": 1689824103000,
        "value": 139.195,
        "_internal_originalTime": 1689824103000
    },
    {
        "time": 1689824104000,
        "value": 139.191,
        "_internal_originalTime": 1689824104000
    },
    {
        "time": 1689824105000,
        "value": 139.199,
        "_internal_originalTime": 1689824105000
    },
    {
        "time": 1689824107000,
        "value": 139.193,
        "_internal_originalTime": 1689824107000
    },
    {
        "time": 1689824108000,
        "value": 139.194,
        "_internal_originalTime": 1689824108000
    },
    {
        "time": 1689824109000,
        "value": 139.194,
        "_internal_originalTime": 1689824109000
    },
    {
        "time": 1689824110000,
        "value": 139.199,
        "_internal_originalTime": 1689824110000
    },
    {
        "time": 1689824111000,
        "value": 139.2,
        "_internal_originalTime": 1689824111000
    },
    {
        "time": 1689824113000,
        "value": 139.199,
        "_internal_originalTime": 1689824113000
    },
    {
        "time": 1689824114000,
        "value": 139.198,
        "_internal_originalTime": 1689824114000
    },
    {
        "time": 1689824116000,
        "value": 139.194,
        "_internal_originalTime": 1689824116000
    },
    {
        "time": 1689824117000,
        "value": 139.2,
        "_internal_originalTime": 1689824117000
    },
    {
        "time": 1689824119000,
        "value": 139.197,
        "_internal_originalTime": 1689824119000
    },
    {
        "time": 1689824120000,
        "value": 139.206,
        "_internal_originalTime": 1689824120000
    },
    {
        "time": 1689824121000,
        "value": 139.209,
        "_internal_originalTime": 1689824121000
    },
    {
        "time": 1689824123000,
        "value": 139.208,
        "_internal_originalTime": 1689824123000
    },
    {
        "time": 1689824125000,
        "value": 139.213,
        "_internal_originalTime": 1689824125000
    },
    {
        "time": 1689824126000,
        "value": 139.214,
        "_internal_originalTime": 1689824126000
    },
    {
        "time": 1689824127000,
        "value": 139.215,
        "_internal_originalTime": 1689824127000
    },
    {
        "time": 1689824129000,
        "value": 139.216,
        "_internal_originalTime": 1689824129000
    },
    {
        "time": 1689824131000,
        "value": 139.215,
        "_internal_originalTime": 1689824131000
    },
    {
        "time": 1689824132000,
        "value": 139.21,
        "_internal_originalTime": 1689824132000
    },
    {
        "time": 1689824136000,
        "value": 139.212,
        "_internal_originalTime": 1689824136000
    },
    {
        "time": 1689824137000,
        "value": 139.212,
        "_internal_originalTime": 1689824137000
    },
    {
        "time": 1689824139000,
        "value": 139.213,
        "_internal_originalTime": 1689824139000
    },
    {
        "time": 1689824141000,
        "value": 139.21,
        "_internal_originalTime": 1689824141000
    },
    {
        "time": 1689824143000,
        "value": 139.211,
        "_internal_originalTime": 1689824143000
    },
    {
        "time": 1689824145000,
        "value": 139.211,
        "_internal_originalTime": 1689824145000
    },
    {
        "time": 1689824146000,
        "value": 139.21,
        "_internal_originalTime": 1689824146000
    },
    {
        "time": 1689824147000,
        "value": 139.21,
        "_internal_originalTime": 1689824147000
    },
    {
        "time": 1689824148000,
        "value": 139.21,
        "_internal_originalTime": 1689824148000
    },
    {
        "time": 1689824152000,
        "value": 139.211,
        "_internal_originalTime": 1689824152000
    },
    {
        "time": 1689824153000,
        "value": 139.21,
        "_internal_originalTime": 1689824153000
    },
    {
        "time": 1689824155000,
        "value": 139.216,
        "_internal_originalTime": 1689824155000
    },
    {
        "time": 1689824157000,
        "value": 139.21,
        "_internal_originalTime": 1689824157000
    },
    {
        "time": 1689824158000,
        "value": 139.21,
        "_internal_originalTime": 1689824158000
    },
    {
        "time": 1689824160000,
        "value": 139.215,
        "_internal_originalTime": 1689824160000
    },
    {
        "time": 1689824161000,
        "value": 139.21,
        "_internal_originalTime": 1689824161000
    },
    {
        "time": 1689824162000,
        "value": 139.21,
        "_internal_originalTime": 1689824162000
    },
    {
        "time": 1689824163000,
        "value": 139.21,
        "_internal_originalTime": 1689824163000
    },
    {
        "time": 1689824164000,
        "value": 139.21,
        "_internal_originalTime": 1689824164000
    },
    {
        "time": 1689824165000,
        "value": 139.215,
        "_internal_originalTime": 1689824165000
    },
    {
        "time": 1689824166000,
        "value": 139.216,
        "_internal_originalTime": 1689824166000
    },
    {
        "time": 1689824167000,
        "value": 139.21,
        "_internal_originalTime": 1689824167000
    },
    {
        "time": 1689824169000,
        "value": 139.21,
        "_internal_originalTime": 1689824169000
    },
    {
        "time": 1689824170000,
        "value": 139.216,
        "_internal_originalTime": 1689824170000
    },
    {
        "time": 1689824171000,
        "value": 139.22,
        "_internal_originalTime": 1689824171000
    },
    {
        "time": 1689824172000,
        "value": 139.214,
        "_internal_originalTime": 1689824172000
    },
    {
        "time": 1689824175000,
        "value": 139.219,
        "_internal_originalTime": 1689824175000
    },
    {
        "time": 1689824176000,
        "value": 139.218,
        "_internal_originalTime": 1689824176000
    },
    {
        "time": 1689824178000,
        "value": 139.212,
        "_internal_originalTime": 1689824178000
    },
    {
        "time": 1689824179000,
        "value": 139.22,
        "_internal_originalTime": 1689824179000
    },
    {
        "time": 1689824180000,
        "value": 139.223,
        "_internal_originalTime": 1689824180000
    },
    {
        "time": 1689824182000,
        "value": 139.229,
        "_internal_originalTime": 1689824182000
    },
    {
        "time": 1689824183000,
        "value": 139.229,
        "_internal_originalTime": 1689824183000
    },
    {
        "time": 1689824184000,
        "value": 139.229,
        "_internal_originalTime": 1689824184000
    },
    {
        "time": 1689824185000,
        "value": 139.233,
        "_internal_originalTime": 1689824185000
    },
    {
        "time": 1689824186000,
        "value": 139.229,
        "_internal_originalTime": 1689824186000
    },
    {
        "time": 1689824187000,
        "value": 139.227,
        "_internal_originalTime": 1689824187000
    },
    {
        "time": 1689824188000,
        "value": 139.231,
        "_internal_originalTime": 1689824188000
    },
    {
        "time": 1689824190000,
        "value": 139.224,
        "_internal_originalTime": 1689824190000
    },
    {
        "time": 1689824191000,
        "value": 139.224,
        "_internal_originalTime": 1689824191000
    },
    {
        "time": 1689824192000,
        "value": 139.225,
        "_internal_originalTime": 1689824192000
    },
    {
        "time": 1689824194000,
        "value": 139.224,
        "_internal_originalTime": 1689824194000
    },
    {
        "time": 1689824196000,
        "value": 139.225,
        "_internal_originalTime": 1689824196000
    },
    {
        "time": 1689824197000,
        "value": 139.224,
        "_internal_originalTime": 1689824197000
    },
    {
        "time": 1689824199000,
        "value": 139.227,
        "_internal_originalTime": 1689824199000
    },
    {
        "time": 1689824200000,
        "value": 139.227,
        "_internal_originalTime": 1689824200000
    },
    {
        "time": 1689824202000,
        "value": 139.22,
        "_internal_originalTime": 1689824202000
    },
    {
        "time": 1689824204000,
        "value": 139.225,
        "_internal_originalTime": 1689824204000
    },
    {
        "time": 1689824205000,
        "value": 139.225,
        "_internal_originalTime": 1689824205000
    },
    {
        "time": 1689824206000,
        "value": 139.229,
        "_internal_originalTime": 1689824206000
    },
    {
        "time": 1689824207000,
        "value": 139.228,
        "_internal_originalTime": 1689824207000
    },
    {
        "time": 1689824210000,
        "value": 139.241,
        "_internal_originalTime": 1689824210000
    },
    {
        "time": 1689824211000,
        "value": 139.241,
        "_internal_originalTime": 1689824211000
    },
    {
        "time": 1689824212000,
        "value": 139.24,
        "_internal_originalTime": 1689824212000
    },
    {
        "time": 1689824214000,
        "value": 139.237,
        "_internal_originalTime": 1689824214000
    },
    {
        "time": 1689824216000,
        "value": 139.241,
        "_internal_originalTime": 1689824216000
    },
    {
        "time": 1689824218000,
        "value": 139.24,
        "_internal_originalTime": 1689824218000
    },
    {
        "time": 1689824219000,
        "value": 139.239,
        "_internal_originalTime": 1689824219000
    },
    {
        "time": 1689824220000,
        "value": 139.234,
        "_internal_originalTime": 1689824220000
    },
    {
        "time": 1689824221000,
        "value": 139.237,
        "_internal_originalTime": 1689824221000
    },
    {
        "time": 1689824224000,
        "value": 139.232,
        "_internal_originalTime": 1689824224000
    },
    {
        "time": 1689824225000,
        "value": 139.237,
        "_internal_originalTime": 1689824225000
    },
    {
        "time": 1689824227000,
        "value": 139.232,
        "_internal_originalTime": 1689824227000
    },
    {
        "time": 1689824229000,
        "value": 139.23,
        "_internal_originalTime": 1689824229000
    },
    {
        "time": 1689824230000,
        "value": 139.234,
        "_internal_originalTime": 1689824230000
    },
    {
        "time": 1689824232000,
        "value": 139.233,
        "_internal_originalTime": 1689824232000
    },
    {
        "time": 1689824233000,
        "value": 139.233,
        "_internal_originalTime": 1689824233000
    },
    {
        "time": 1689824235000,
        "value": 139.234,
        "_internal_originalTime": 1689824235000
    },
    {
        "time": 1689824236000,
        "value": 139.231,
        "_internal_originalTime": 1689824236000
    },
    {
        "time": 1689824237000,
        "value": 139.234,
        "_internal_originalTime": 1689824237000
    },
    {
        "time": 1689824238000,
        "value": 139.23,
        "_internal_originalTime": 1689824238000
    },
    {
        "time": 1689824239000,
        "value": 139.235,
        "_internal_originalTime": 1689824239000
    },
    {
        "time": 1689824242000,
        "value": 139.236,
        "_internal_originalTime": 1689824242000
    },
    {
        "time": 1689824244000,
        "value": 139.231,
        "_internal_originalTime": 1689824244000
    },
    {
        "time": 1689824245000,
        "value": 139.23,
        "_internal_originalTime": 1689824245000
    },
    {
        "time": 1689824246000,
        "value": 139.229,
        "_internal_originalTime": 1689824246000
    },
    {
        "time": 1689824249000,
        "value": 139.229,
        "_internal_originalTime": 1689824249000
    },
    {
        "time": 1689824251000,
        "value": 139.223,
        "_internal_originalTime": 1689824251000
    },
    {
        "time": 1689824252000,
        "value": 139.224,
        "_internal_originalTime": 1689824252000
    },
    {
        "time": 1689824253000,
        "value": 139.225,
        "_internal_originalTime": 1689824253000
    },
    {
        "time": 1689824254000,
        "value": 139.229,
        "_internal_originalTime": 1689824254000
    },
    {
        "time": 1689824256000,
        "value": 139.224,
        "_internal_originalTime": 1689824256000
    },
    {
        "time": 1689824257000,
        "value": 139.228,
        "_internal_originalTime": 1689824257000
    },
    {
        "time": 1689824258000,
        "value": 139.224,
        "_internal_originalTime": 1689824258000
    },
    {
        "time": 1689824260000,
        "value": 139.229,
        "_internal_originalTime": 1689824260000
    },
    {
        "time": 1689824261000,
        "value": 139.224,
        "_internal_originalTime": 1689824261000
    },
    {
        "time": 1689824263000,
        "value": 139.224,
        "_internal_originalTime": 1689824263000
    },
    {
        "time": 1689824264000,
        "value": 139.228,
        "_internal_originalTime": 1689824264000
    },
    {
        "time": 1689824265000,
        "value": 139.224,
        "_internal_originalTime": 1689824265000
    },
    {
        "time": 1689824267000,
        "value": 139.228,
        "_internal_originalTime": 1689824267000
    },
    {
        "time": 1689824269000,
        "value": 139.227,
        "_internal_originalTime": 1689824269000
    },
    {
        "time": 1689824270000,
        "value": 139.224,
        "_internal_originalTime": 1689824270000
    },
    {
        "time": 1689824278000,
        "value": 139.224,
        "_internal_originalTime": 1689824278000
    },
    {
        "time": 1689824279000,
        "value": 139.23,
        "_internal_originalTime": 1689824279000
    },
    {
        "time": 1689824281000,
        "value": 139.223,
        "_internal_originalTime": 1689824281000
    },
    {
        "time": 1689824282000,
        "value": 139.238,
        "_internal_originalTime": 1689824282000
    },
    {
        "time": 1689824284000,
        "value": 139.237,
        "_internal_originalTime": 1689824284000
    },
    {
        "time": 1689824285000,
        "value": 139.243,
        "_internal_originalTime": 1689824285000
    },
    {
        "time": 1689824286000,
        "value": 139.239,
        "_internal_originalTime": 1689824286000
    },
    {
        "time": 1689824287000,
        "value": 139.238,
        "_internal_originalTime": 1689824287000
    },
    {
        "time": 1689824288000,
        "value": 139.239,
        "_internal_originalTime": 1689824288000
    },
    {
        "time": 1689824289000,
        "value": 139.238,
        "_internal_originalTime": 1689824289000
    },
    {
        "time": 1689824290000,
        "value": 139.236,
        "_internal_originalTime": 1689824290000
    },
    {
        "time": 1689824291000,
        "value": 139.246,
        "_internal_originalTime": 1689824291000
    },
    {
        "time": 1689824293000,
        "value": 139.247,
        "_internal_originalTime": 1689824293000
    },
    {
        "time": 1689824294000,
        "value": 139.241,
        "_internal_originalTime": 1689824294000
    },
    {
        "time": 1689824297000,
        "value": 139.247,
        "_internal_originalTime": 1689824297000
    },
    {
        "time": 1689824298000,
        "value": 139.249,
        "_internal_originalTime": 1689824298000
    },
    {
        "time": 1689824299000,
        "value": 139.248,
        "_internal_originalTime": 1689824299000
    },
    {
        "time": 1689824302000,
        "value": 139.242,
        "_internal_originalTime": 1689824302000
    },
    {
        "time": 1689824303000,
        "value": 139.246,
        "_internal_originalTime": 1689824303000
    },
    {
        "time": 1689824304000,
        "value": 139.246,
        "_internal_originalTime": 1689824304000
    },
    {
        "time": 1689824307000,
        "value": 139.248,
        "_internal_originalTime": 1689824307000
    },
    {
        "time": 1689824308000,
        "value": 139.248,
        "_internal_originalTime": 1689824308000
    },
    {
        "time": 1689824309000,
        "value": 139.25,
        "_internal_originalTime": 1689824309000
    },
    {
        "time": 1689824310000,
        "value": 139.25,
        "_internal_originalTime": 1689824310000
    },
    {
        "time": 1689824311000,
        "value": 139.241,
        "_internal_originalTime": 1689824311000
    },
    {
        "time": 1689824312000,
        "value": 139.249,
        "_internal_originalTime": 1689824312000
    },
    {
        "time": 1689824314000,
        "value": 139.248,
        "_internal_originalTime": 1689824314000
    },
    {
        "time": 1689824317000,
        "value": 139.249,
        "_internal_originalTime": 1689824317000
    },
    {
        "time": 1689824318000,
        "value": 139.249,
        "_internal_originalTime": 1689824318000
    },
    {
        "time": 1689824319000,
        "value": 139.245,
        "_internal_originalTime": 1689824319000
    },
    {
        "time": 1689824320000,
        "value": 139.242,
        "_internal_originalTime": 1689824320000
    },
    {
        "time": 1689824321000,
        "value": 139.243,
        "_internal_originalTime": 1689824321000
    },
    {
        "time": 1689824323000,
        "value": 139.247,
        "_internal_originalTime": 1689824323000
    },
    {
        "time": 1689824324000,
        "value": 139.242,
        "_internal_originalTime": 1689824324000
    },
    {
        "time": 1689824325000,
        "value": 139.24,
        "_internal_originalTime": 1689824325000
    },
    {
        "time": 1689824326000,
        "value": 139.242,
        "_internal_originalTime": 1689824326000
    },
    {
        "time": 1689824327000,
        "value": 139.24,
        "_internal_originalTime": 1689824327000
    },
    {
        "time": 1689824329000,
        "value": 139.241,
        "_internal_originalTime": 1689824329000
    },
    {
        "time": 1689824331000,
        "value": 139.23,
        "_internal_originalTime": 1689824331000
    },
    {
        "time": 1689824334000,
        "value": 139.237,
        "_internal_originalTime": 1689824334000
    },
    {
        "time": 1689824336000,
        "value": 139.231,
        "_internal_originalTime": 1689824336000
    },
    {
        "time": 1689824337000,
        "value": 139.236,
        "_internal_originalTime": 1689824337000
    },
    {
        "time": 1689824339000,
        "value": 139.233,
        "_internal_originalTime": 1689824339000
    },
    {
        "time": 1689824340000,
        "value": 139.234,
        "_internal_originalTime": 1689824340000
    },
    {
        "time": 1689824341000,
        "value": 139.229,
        "_internal_originalTime": 1689824341000
    },
    {
        "time": 1689824344000,
        "value": 139.234,
        "_internal_originalTime": 1689824344000
    },
    {
        "time": 1689824346000,
        "value": 139.231,
        "_internal_originalTime": 1689824346000
    },
    {
        "time": 1689824347000,
        "value": 139.238,
        "_internal_originalTime": 1689824347000
    },
    {
        "time": 1689824349000,
        "value": 139.236,
        "_internal_originalTime": 1689824349000
    },
    {
        "time": 1689824350000,
        "value": 139.238,
        "_internal_originalTime": 1689824350000
    },
    {
        "time": 1689824351000,
        "value": 139.232,
        "_internal_originalTime": 1689824351000
    },
    {
        "time": 1689824352000,
        "value": 139.23,
        "_internal_originalTime": 1689824352000
    },
    {
        "time": 1689824354000,
        "value": 139.236,
        "_internal_originalTime": 1689824354000
    },
    {
        "time": 1689824356000,
        "value": 139.23,
        "_internal_originalTime": 1689824356000
    },
    {
        "time": 1689824358000,
        "value": 139.232,
        "_internal_originalTime": 1689824358000
    },
    {
        "time": 1689824360000,
        "value": 139.246,
        "_internal_originalTime": 1689824360000
    },
    {
        "time": 1689824361000,
        "value": 139.244,
        "_internal_originalTime": 1689824361000
    },
    {
        "time": 1689824362000,
        "value": 139.246,
        "_internal_originalTime": 1689824362000
    },
    {
        "time": 1689824363000,
        "value": 139.249,
        "_internal_originalTime": 1689824363000
    },
    {
        "time": 1689824365000,
        "value": 139.246,
        "_internal_originalTime": 1689824365000
    },
    {
        "time": 1689824366000,
        "value": 139.24,
        "_internal_originalTime": 1689824366000
    },
    {
        "time": 1689824367000,
        "value": 139.24,
        "_internal_originalTime": 1689824367000
    },
    {
        "time": 1689824370000,
        "value": 139.244,
        "_internal_originalTime": 1689824370000
    },
    {
        "time": 1689824371000,
        "value": 139.238,
        "_internal_originalTime": 1689824371000
    },
    {
        "time": 1689824374000,
        "value": 139.238,
        "_internal_originalTime": 1689824374000
    },
    {
        "time": 1689824376000,
        "value": 139.24,
        "_internal_originalTime": 1689824376000
    },
    {
        "time": 1689824379000,
        "value": 139.244,
        "_internal_originalTime": 1689824379000
    },
    {
        "time": 1689824382000,
        "value": 139.24,
        "_internal_originalTime": 1689824382000
    },
    {
        "time": 1689824383000,
        "value": 139.247,
        "_internal_originalTime": 1689824383000
    },
    {
        "time": 1689824384000,
        "value": 139.246,
        "_internal_originalTime": 1689824384000
    },
    {
        "time": 1689824386000,
        "value": 139.245,
        "_internal_originalTime": 1689824386000
    },
    {
        "time": 1689824387000,
        "value": 139.245,
        "_internal_originalTime": 1689824387000
    },
    {
        "time": 1689824389000,
        "value": 139.247,
        "_internal_originalTime": 1689824389000
    },
    {
        "time": 1689824391000,
        "value": 139.242,
        "_internal_originalTime": 1689824391000
    },
    {
        "time": 1689824394000,
        "value": 139.24,
        "_internal_originalTime": 1689824394000
    },
    {
        "time": 1689824395000,
        "value": 139.245,
        "_internal_originalTime": 1689824395000
    },
    {
        "time": 1689824396000,
        "value": 139.24,
        "_internal_originalTime": 1689824396000
    },
    {
        "time": 1689824397000,
        "value": 139.24,
        "_internal_originalTime": 1689824397000
    },
    {
        "time": 1689824399000,
        "value": 139.24,
        "_internal_originalTime": 1689824399000
    },
    {
        "time": 1689824400000,
        "value": 139.248,
        "_internal_originalTime": 1689824400000
    },
    {
        "time": 1689824401000,
        "value": 139.248,
        "_internal_originalTime": 1689824401000
    },
    {
        "time": 1689824402000,
        "value": 139.242,
        "_internal_originalTime": 1689824402000
    },
    {
        "time": 1689824404000,
        "value": 139.247,
        "_internal_originalTime": 1689824404000
    },
    {
        "time": 1689824406000,
        "value": 139.245,
        "_internal_originalTime": 1689824406000
    },
    {
        "time": 1689824407000,
        "value": 139.24,
        "_internal_originalTime": 1689824407000
    },
    {
        "time": 1689824408000,
        "value": 139.244,
        "_internal_originalTime": 1689824408000
    },
    {
        "time": 1689824410000,
        "value": 139.246,
        "_internal_originalTime": 1689824410000
    },
    {
        "time": 1689824412000,
        "value": 139.247,
        "_internal_originalTime": 1689824412000
    },
    {
        "time": 1689824414000,
        "value": 139.241,
        "_internal_originalTime": 1689824414000
    },
    {
        "time": 1689824415000,
        "value": 139.242,
        "_internal_originalTime": 1689824415000
    },
    {
        "time": 1689824416000,
        "value": 139.247,
        "_internal_originalTime": 1689824416000
    },
    {
        "time": 1689824417000,
        "value": 139.246,
        "_internal_originalTime": 1689824417000
    },
    {
        "time": 1689824419000,
        "value": 139.242,
        "_internal_originalTime": 1689824419000
    },
    {
        "time": 1689824420000,
        "value": 139.247,
        "_internal_originalTime": 1689824420000
    },
    {
        "time": 1689824423000,
        "value": 139.242,
        "_internal_originalTime": 1689824423000
    },
    {
        "time": 1689824431000,
        "value": 139.241,
        "_internal_originalTime": 1689824431000
    },
    {
        "time": 1689824432000,
        "value": 139.242,
        "_internal_originalTime": 1689824432000
    },
    {
        "time": 1689824434000,
        "value": 139.246,
        "_internal_originalTime": 1689824434000
    },
    {
        "time": 1689824438000,
        "value": 139.244,
        "_internal_originalTime": 1689824438000
    },
    {
        "time": 1689824439000,
        "value": 139.242,
        "_internal_originalTime": 1689824439000
    },
    {
        "time": 1689824440000,
        "value": 139.245,
        "_internal_originalTime": 1689824440000
    },
    {
        "time": 1689824446000,
        "value": 139.24,
        "_internal_originalTime": 1689824446000
    },
    {
        "time": 1689824447000,
        "value": 139.243,
        "_internal_originalTime": 1689824447000
    },
    {
        "time": 1689824448000,
        "value": 139.238,
        "_internal_originalTime": 1689824448000
    },
    {
        "time": 1689824449000,
        "value": 139.24,
        "_internal_originalTime": 1689824449000
    },
    {
        "time": 1689824451000,
        "value": 139.245,
        "_internal_originalTime": 1689824451000
    },
    {
        "time": 1689824460000,
        "value": 139.238,
        "_internal_originalTime": 1689824460000
    },
    {
        "time": 1689824461000,
        "value": 139.237,
        "_internal_originalTime": 1689824461000
    },
    {
        "time": 1689824463000,
        "value": 139.234,
        "_internal_originalTime": 1689824463000
    },
    {
        "time": 1689824464000,
        "value": 139.228,
        "_internal_originalTime": 1689824464000
    },
    {
        "time": 1689824466000,
        "value": 139.23,
        "_internal_originalTime": 1689824466000
    },
    {
        "time": 1689824467000,
        "value": 139.233,
        "_internal_originalTime": 1689824467000
    },
    {
        "time": 1689824469000,
        "value": 139.229,
        "_internal_originalTime": 1689824469000
    },
    {
        "time": 1689824471000,
        "value": 139.228,
        "_internal_originalTime": 1689824471000
    },
    {
        "time": 1689824473000,
        "value": 139.234,
        "_internal_originalTime": 1689824473000
    },
    {
        "time": 1689824474000,
        "value": 139.228,
        "_internal_originalTime": 1689824474000
    },
    {
        "time": 1689824476000,
        "value": 139.229,
        "_internal_originalTime": 1689824476000
    },
    {
        "time": 1689824477000,
        "value": 139.227,
        "_internal_originalTime": 1689824477000
    },
    {
        "time": 1689824479000,
        "value": 139.228,
        "_internal_originalTime": 1689824479000
    },
    {
        "time": 1689824481000,
        "value": 139.222,
        "_internal_originalTime": 1689824481000
    },
    {
        "time": 1689824482000,
        "value": 139.224,
        "_internal_originalTime": 1689824482000
    },
    {
        "time": 1689824483000,
        "value": 139.22,
        "_internal_originalTime": 1689824483000
    },
    {
        "time": 1689824484000,
        "value": 139.22,
        "_internal_originalTime": 1689824484000
    },
    {
        "time": 1689824485000,
        "value": 139.221,
        "_internal_originalTime": 1689824485000
    },
    {
        "time": 1689824486000,
        "value": 139.22,
        "_internal_originalTime": 1689824486000
    },
    {
        "time": 1689824489000,
        "value": 139.221,
        "_internal_originalTime": 1689824489000
    },
    {
        "time": 1689824491000,
        "value": 139.22,
        "_internal_originalTime": 1689824491000
    },
    {
        "time": 1689824492000,
        "value": 139.225,
        "_internal_originalTime": 1689824492000
    },
    {
        "time": 1689824495000,
        "value": 139.221,
        "_internal_originalTime": 1689824495000
    },
    {
        "time": 1689824496000,
        "value": 139.22,
        "_internal_originalTime": 1689824496000
    },
    {
        "time": 1689824497000,
        "value": 139.221,
        "_internal_originalTime": 1689824497000
    },
    {
        "time": 1689824499000,
        "value": 139.224,
        "_internal_originalTime": 1689824499000
    },
    {
        "time": 1689824505000,
        "value": 139.223,
        "_internal_originalTime": 1689824505000
    },
    {
        "time": 1689824506000,
        "value": 139.222,
        "_internal_originalTime": 1689824506000
    },
    {
        "time": 1689824507000,
        "value": 139.226,
        "_internal_originalTime": 1689824507000
    },
    {
        "time": 1689824511000,
        "value": 139.227,
        "_internal_originalTime": 1689824511000
    },
    {
        "time": 1689824513000,
        "value": 139.226,
        "_internal_originalTime": 1689824513000
    },
    {
        "time": 1689824515000,
        "value": 139.227,
        "_internal_originalTime": 1689824515000
    },
    {
        "time": 1689824516000,
        "value": 139.22,
        "_internal_originalTime": 1689824516000
    },
    {
        "time": 1689824518000,
        "value": 139.222,
        "_internal_originalTime": 1689824518000
    },
    {
        "time": 1689824519000,
        "value": 139.227,
        "_internal_originalTime": 1689824519000
    },
    {
        "time": 1689824520000,
        "value": 139.227,
        "_internal_originalTime": 1689824520000
    },
    {
        "time": 1689824521000,
        "value": 139.22,
        "_internal_originalTime": 1689824521000
    },
    {
        "time": 1689824523000,
        "value": 139.226,
        "_internal_originalTime": 1689824523000
    },
    {
        "time": 1689824525000,
        "value": 139.226,
        "_internal_originalTime": 1689824525000
    },
    {
        "time": 1689824526000,
        "value": 139.22,
        "_internal_originalTime": 1689824526000
    },
    {
        "time": 1689824527000,
        "value": 139.224,
        "_internal_originalTime": 1689824527000
    },
    {
        "time": 1689824529000,
        "value": 139.223,
        "_internal_originalTime": 1689824529000
    },
    {
        "time": 1689824530000,
        "value": 139.221,
        "_internal_originalTime": 1689824530000
    },
    {
        "time": 1689824531000,
        "value": 139.225,
        "_internal_originalTime": 1689824531000
    },
    {
        "time": 1689824533000,
        "value": 139.224,
        "_internal_originalTime": 1689824533000
    },
    {
        "time": 1689824534000,
        "value": 139.225,
        "_internal_originalTime": 1689824534000
    },
    {
        "time": 1689824535000,
        "value": 139.22,
        "_internal_originalTime": 1689824535000
    },
    {
        "time": 1689824537000,
        "value": 139.221,
        "_internal_originalTime": 1689824537000
    },
    {
        "time": 1689824538000,
        "value": 139.22,
        "_internal_originalTime": 1689824538000
    },
    {
        "time": 1689824539000,
        "value": 139.225,
        "_internal_originalTime": 1689824539000
    },
    {
        "time": 1689824543000,
        "value": 139.22,
        "_internal_originalTime": 1689824543000
    },
    {
        "time": 1689824549000,
        "value": 139.221,
        "_internal_originalTime": 1689824549000
    },
    {
        "time": 1689824551000,
        "value": 139.216,
        "_internal_originalTime": 1689824551000
    },
    {
        "time": 1689824552000,
        "value": 139.215,
        "_internal_originalTime": 1689824552000
    },
    {
        "time": 1689824553000,
        "value": 139.215,
        "_internal_originalTime": 1689824553000
    },
    {
        "time": 1689824554000,
        "value": 139.211,
        "_internal_originalTime": 1689824554000
    },
    {
        "time": 1689824556000,
        "value": 139.21,
        "_internal_originalTime": 1689824556000
    },
    {
        "time": 1689824557000,
        "value": 139.217,
        "_internal_originalTime": 1689824557000
    },
    {
        "time": 1689824560000,
        "value": 139.212,
        "_internal_originalTime": 1689824560000
    },
    {
        "time": 1689824565000,
        "value": 139.211,
        "_internal_originalTime": 1689824565000
    },
    {
        "time": 1689824566000,
        "value": 139.215,
        "_internal_originalTime": 1689824566000
    },
    {
        "time": 1689824567000,
        "value": 139.211,
        "_internal_originalTime": 1689824567000
    },
    {
        "time": 1689824568000,
        "value": 139.21,
        "_internal_originalTime": 1689824568000
    },
    {
        "time": 1689824570000,
        "value": 139.212,
        "_internal_originalTime": 1689824570000
    },
    {
        "time": 1689824571000,
        "value": 139.215,
        "_internal_originalTime": 1689824571000
    },
    {
        "time": 1689824572000,
        "value": 139.217,
        "_internal_originalTime": 1689824572000
    },
    {
        "time": 1689824578000,
        "value": 139.215,
        "_internal_originalTime": 1689824578000
    },
    {
        "time": 1689824579000,
        "value": 139.212,
        "_internal_originalTime": 1689824579000
    },
    {
        "time": 1689824580000,
        "value": 139.212,
        "_internal_originalTime": 1689824580000
    },
    {
        "time": 1689824581000,
        "value": 139.21,
        "_internal_originalTime": 1689824581000
    },
    {
        "time": 1689824582000,
        "value": 139.216,
        "_internal_originalTime": 1689824582000
    },
    {
        "time": 1689824584000,
        "value": 139.211,
        "_internal_originalTime": 1689824584000
    },
    {
        "time": 1689824586000,
        "value": 139.218,
        "_internal_originalTime": 1689824586000
    },
    {
        "time": 1689824587000,
        "value": 139.215,
        "_internal_originalTime": 1689824587000
    },
    {
        "time": 1689824589000,
        "value": 139.211,
        "_internal_originalTime": 1689824589000
    },
    {
        "time": 1689824590000,
        "value": 139.212,
        "_internal_originalTime": 1689824590000
    },
    {
        "time": 1689824595000,
        "value": 139.216,
        "_internal_originalTime": 1689824595000
    },
    {
        "time": 1689824597000,
        "value": 139.215,
        "_internal_originalTime": 1689824597000
    },
    {
        "time": 1689824601000,
        "value": 139.216,
        "_internal_originalTime": 1689824601000
    },
    {
        "time": 1689824603000,
        "value": 139.212,
        "_internal_originalTime": 1689824603000
    },
    {
        "time": 1689824605000,
        "value": 139.216,
        "_internal_originalTime": 1689824605000
    },
    {
        "time": 1689824611000,
        "value": 139.216,
        "_internal_originalTime": 1689824611000
    },
    {
        "time": 1689824613000,
        "value": 139.223,
        "_internal_originalTime": 1689824613000
    },
    {
        "time": 1689824614000,
        "value": 139.223,
        "_internal_originalTime": 1689824614000
    },
    {
        "time": 1689824616000,
        "value": 139.221,
        "_internal_originalTime": 1689824616000
    },
    {
        "time": 1689824617000,
        "value": 139.224,
        "_internal_originalTime": 1689824617000
    },
    {
        "time": 1689824619000,
        "value": 139.223,
        "_internal_originalTime": 1689824619000
    },
    {
        "time": 1689824620000,
        "value": 139.222,
        "_internal_originalTime": 1689824620000
    },
    {
        "time": 1689824623000,
        "value": 139.226,
        "_internal_originalTime": 1689824623000
    },
    {
        "time": 1689824629000,
        "value": 139.22,
        "_internal_originalTime": 1689824629000
    },
    {
        "time": 1689824630000,
        "value": 139.226,
        "_internal_originalTime": 1689824630000
    },
    {
        "time": 1689824632000,
        "value": 139.222,
        "_internal_originalTime": 1689824632000
    },
    {
        "time": 1689824633000,
        "value": 139.222,
        "_internal_originalTime": 1689824633000
    },
    {
        "time": 1689824635000,
        "value": 139.222,
        "_internal_originalTime": 1689824635000
    },
    {
        "time": 1689824636000,
        "value": 139.227,
        "_internal_originalTime": 1689824636000
    },
    {
        "time": 1689824638000,
        "value": 139.226,
        "_internal_originalTime": 1689824638000
    },
    {
        "time": 1689824640000,
        "value": 139.222,
        "_internal_originalTime": 1689824640000
    },
    {
        "time": 1689824641000,
        "value": 139.227,
        "_internal_originalTime": 1689824641000
    },
    {
        "time": 1689824644000,
        "value": 139.225,
        "_internal_originalTime": 1689824644000
    },
    {
        "time": 1689824646000,
        "value": 139.225,
        "_internal_originalTime": 1689824646000
    },
    {
        "time": 1689824647000,
        "value": 139.222,
        "_internal_originalTime": 1689824647000
    },
    {
        "time": 1689824648000,
        "value": 139.222,
        "_internal_originalTime": 1689824648000
    },
    {
        "time": 1689824649000,
        "value": 139.224,
        "_internal_originalTime": 1689824649000
    },
    {
        "time": 1689824651000,
        "value": 139.226,
        "_internal_originalTime": 1689824651000
    },
    {
        "time": 1689824652000,
        "value": 139.222,
        "_internal_originalTime": 1689824652000
    },
    {
        "time": 1689824653000,
        "value": 139.226,
        "_internal_originalTime": 1689824653000
    },
    {
        "time": 1689824655000,
        "value": 139.222,
        "_internal_originalTime": 1689824655000
    },
    {
        "time": 1689824656000,
        "value": 139.222,
        "_internal_originalTime": 1689824656000
    },
    {
        "time": 1689824658000,
        "value": 139.222,
        "_internal_originalTime": 1689824658000
    },
    {
        "time": 1689824659000,
        "value": 139.226,
        "_internal_originalTime": 1689824659000
    },
    {
        "time": 1689824661000,
        "value": 139.224,
        "_internal_originalTime": 1689824661000
    },
    {
        "time": 1689824662000,
        "value": 139.22,
        "_internal_originalTime": 1689824662000
    },
    {
        "time": 1689824669000,
        "value": 139.222,
        "_internal_originalTime": 1689824669000
    },
    {
        "time": 1689824671000,
        "value": 139.225,
        "_internal_originalTime": 1689824671000
    },
    {
        "time": 1689824673000,
        "value": 139.222,
        "_internal_originalTime": 1689824673000
    },
    {
        "time": 1689824674000,
        "value": 139.222,
        "_internal_originalTime": 1689824674000
    },
    {
        "time": 1689824675000,
        "value": 139.226,
        "_internal_originalTime": 1689824675000
    },
    {
        "time": 1689824676000,
        "value": 139.226,
        "_internal_originalTime": 1689824676000
    },
    {
        "time": 1689824679000,
        "value": 139.226,
        "_internal_originalTime": 1689824679000
    },
    {
        "time": 1689824680000,
        "value": 139.226,
        "_internal_originalTime": 1689824680000
    },
    {
        "time": 1689824682000,
        "value": 139.222,
        "_internal_originalTime": 1689824682000
    },
    {
        "time": 1689824683000,
        "value": 139.226,
        "_internal_originalTime": 1689824683000
    },
    {
        "time": 1689824684000,
        "value": 139.222,
        "_internal_originalTime": 1689824684000
    },
    {
        "time": 1689824685000,
        "value": 139.226,
        "_internal_originalTime": 1689824685000
    },
    {
        "time": 1689824686000,
        "value": 139.226,
        "_internal_originalTime": 1689824686000
    },
    {
        "time": 1689824688000,
        "value": 139.226,
        "_internal_originalTime": 1689824688000
    },
    {
        "time": 1689824691000,
        "value": 139.227,
        "_internal_originalTime": 1689824691000
    },
    {
        "time": 1689824694000,
        "value": 139.225,
        "_internal_originalTime": 1689824694000
    },
    {
        "time": 1689824700000,
        "value": 139.216,
        "_internal_originalTime": 1689824700000
    },
    {
        "time": 1689824701000,
        "value": 139.215,
        "_internal_originalTime": 1689824701000
    },
    {
        "time": 1689824703000,
        "value": 139.214,
        "_internal_originalTime": 1689824703000
    },
    {
        "time": 1689824704000,
        "value": 139.219,
        "_internal_originalTime": 1689824704000
    },
    {
        "time": 1689824706000,
        "value": 139.219,
        "_internal_originalTime": 1689824706000
    },
    {
        "time": 1689824707000,
        "value": 139.22,
        "_internal_originalTime": 1689824707000
    },
    {
        "time": 1689824710000,
        "value": 139.215,
        "_internal_originalTime": 1689824710000
    },
    {
        "time": 1689824712000,
        "value": 139.218,
        "_internal_originalTime": 1689824712000
    },
    {
        "time": 1689824713000,
        "value": 139.218,
        "_internal_originalTime": 1689824713000
    },
    {
        "time": 1689824715000,
        "value": 139.219,
        "_internal_originalTime": 1689824715000
    },
    {
        "time": 1689824716000,
        "value": 139.219,
        "_internal_originalTime": 1689824716000
    },
    {
        "time": 1689824719000,
        "value": 139.218,
        "_internal_originalTime": 1689824719000
    },
    {
        "time": 1689824720000,
        "value": 139.214,
        "_internal_originalTime": 1689824720000
    },
    {
        "time": 1689824724000,
        "value": 139.212,
        "_internal_originalTime": 1689824724000
    },
    {
        "time": 1689824727000,
        "value": 139.217,
        "_internal_originalTime": 1689824727000
    },
    {
        "time": 1689824729000,
        "value": 139.218,
        "_internal_originalTime": 1689824729000
    },
    {
        "time": 1689824730000,
        "value": 139.228,
        "_internal_originalTime": 1689824730000
    },
    {
        "time": 1689824731000,
        "value": 139.235,
        "_internal_originalTime": 1689824731000
    },
    {
        "time": 1689824733000,
        "value": 139.23,
        "_internal_originalTime": 1689824733000
    },
    {
        "time": 1689824734000,
        "value": 139.237,
        "_internal_originalTime": 1689824734000
    },
    {
        "time": 1689824736000,
        "value": 139.232,
        "_internal_originalTime": 1689824736000
    },
    {
        "time": 1689824737000,
        "value": 139.238,
        "_internal_originalTime": 1689824737000
    },
    {
        "time": 1689824739000,
        "value": 139.237,
        "_internal_originalTime": 1689824739000
    },
    {
        "time": 1689824741000,
        "value": 139.236,
        "_internal_originalTime": 1689824741000
    },
    {
        "time": 1689824743000,
        "value": 139.235,
        "_internal_originalTime": 1689824743000
    },
    {
        "time": 1689824745000,
        "value": 139.231,
        "_internal_originalTime": 1689824745000
    },
    {
        "time": 1689824751000,
        "value": 139.23,
        "_internal_originalTime": 1689824751000
    },
    {
        "time": 1689824752000,
        "value": 139.23,
        "_internal_originalTime": 1689824752000
    },
    {
        "time": 1689824755000,
        "value": 139.228,
        "_internal_originalTime": 1689824755000
    },
    {
        "time": 1689824757000,
        "value": 139.234,
        "_internal_originalTime": 1689824757000
    },
    {
        "time": 1689824759000,
        "value": 139.23,
        "_internal_originalTime": 1689824759000
    },
    {
        "time": 1689824760000,
        "value": 139.231,
        "_internal_originalTime": 1689824760000
    },
    {
        "time": 1689824762000,
        "value": 139.234,
        "_internal_originalTime": 1689824762000
    },
    {
        "time": 1689824764000,
        "value": 139.238,
        "_internal_originalTime": 1689824764000
    },
    {
        "time": 1689824765000,
        "value": 139.237,
        "_internal_originalTime": 1689824765000
    },
    {
        "time": 1689824766000,
        "value": 139.235,
        "_internal_originalTime": 1689824766000
    },
    {
        "time": 1689824769000,
        "value": 139.234,
        "_internal_originalTime": 1689824769000
    },
    {
        "time": 1689824770000,
        "value": 139.235,
        "_internal_originalTime": 1689824770000
    },
    {
        "time": 1689824773000,
        "value": 139.238,
        "_internal_originalTime": 1689824773000
    },
    {
        "time": 1689824774000,
        "value": 139.245,
        "_internal_originalTime": 1689824774000
    },
    {
        "time": 1689824775000,
        "value": 139.245,
        "_internal_originalTime": 1689824775000
    },
    {
        "time": 1689824777000,
        "value": 139.237,
        "_internal_originalTime": 1689824777000
    },
    {
        "time": 1689824778000,
        "value": 139.237,
        "_internal_originalTime": 1689824778000
    },
    {
        "time": 1689824786000,
        "value": 139.233,
        "_internal_originalTime": 1689824786000
    },
    {
        "time": 1689824791000,
        "value": 139.232,
        "_internal_originalTime": 1689824791000
    },
    {
        "time": 1689824793000,
        "value": 139.238,
        "_internal_originalTime": 1689824793000
    },
    {
        "time": 1689824794000,
        "value": 139.235,
        "_internal_originalTime": 1689824794000
    },
    {
        "time": 1689824795000,
        "value": 139.239,
        "_internal_originalTime": 1689824795000
    },
    {
        "time": 1689824797000,
        "value": 139.238,
        "_internal_originalTime": 1689824797000
    },
    {
        "time": 1689824799000,
        "value": 139.248,
        "_internal_originalTime": 1689824799000
    },
    {
        "time": 1689824800000,
        "value": 139.244,
        "_internal_originalTime": 1689824800000
    },
    {
        "time": 1689824802000,
        "value": 139.242,
        "_internal_originalTime": 1689824802000
    },
    {
        "time": 1689824803000,
        "value": 139.242,
        "_internal_originalTime": 1689824803000
    },
    {
        "time": 1689824805000,
        "value": 139.242,
        "_internal_originalTime": 1689824805000
    },
    {
        "time": 1689824806000,
        "value": 139.246,
        "_internal_originalTime": 1689824806000
    },
    {
        "time": 1689824807000,
        "value": 139.247,
        "_internal_originalTime": 1689824807000
    },
    {
        "time": 1689824808000,
        "value": 139.247,
        "_internal_originalTime": 1689824808000
    },
    {
        "time": 1689824810000,
        "value": 139.246,
        "_internal_originalTime": 1689824810000
    },
    {
        "time": 1689824814000,
        "value": 139.247,
        "_internal_originalTime": 1689824814000
    },
    {
        "time": 1689824816000,
        "value": 139.244,
        "_internal_originalTime": 1689824816000
    },
    {
        "time": 1689824818000,
        "value": 139.244,
        "_internal_originalTime": 1689824818000
    },
    {
        "time": 1689824819000,
        "value": 139.244,
        "_internal_originalTime": 1689824819000
    },
    {
        "time": 1689824821000,
        "value": 139.238,
        "_internal_originalTime": 1689824821000
    },
    {
        "time": 1689824822000,
        "value": 139.244,
        "_internal_originalTime": 1689824822000
    },
    {
        "time": 1689824825000,
        "value": 139.245,
        "_internal_originalTime": 1689824825000
    },
    {
        "time": 1689824827000,
        "value": 139.244,
        "_internal_originalTime": 1689824827000
    },
    {
        "time": 1689824828000,
        "value": 139.245,
        "_internal_originalTime": 1689824828000
    },
    {
        "time": 1689824829000,
        "value": 139.245,
        "_internal_originalTime": 1689824829000
    },
    {
        "time": 1689824831000,
        "value": 139.244,
        "_internal_originalTime": 1689824831000
    },
    {
        "time": 1689824833000,
        "value": 139.248,
        "_internal_originalTime": 1689824833000
    },
    {
        "time": 1689824834000,
        "value": 139.247,
        "_internal_originalTime": 1689824834000
    },
    {
        "time": 1689824835000,
        "value": 139.247,
        "_internal_originalTime": 1689824835000
    },
    {
        "time": 1689824837000,
        "value": 139.248,
        "_internal_originalTime": 1689824837000
    },
    {
        "time": 1689824839000,
        "value": 139.241,
        "_internal_originalTime": 1689824839000
    },
    {
        "time": 1689824843000,
        "value": 139.244,
        "_internal_originalTime": 1689824843000
    },
    {
        "time": 1689824845000,
        "value": 139.24,
        "_internal_originalTime": 1689824845000
    },
    {
        "time": 1689824847000,
        "value": 139.241,
        "_internal_originalTime": 1689824847000
    },
    {
        "time": 1689824848000,
        "value": 139.241,
        "_internal_originalTime": 1689824848000
    },
    {
        "time": 1689824853000,
        "value": 139.243,
        "_internal_originalTime": 1689824853000
    },
    {
        "time": 1689824855000,
        "value": 139.246,
        "_internal_originalTime": 1689824855000
    },
    {
        "time": 1689824856000,
        "value": 139.242,
        "_internal_originalTime": 1689824856000
    },
    {
        "time": 1689824857000,
        "value": 139.247,
        "_internal_originalTime": 1689824857000
    },
    {
        "time": 1689824859000,
        "value": 139.242,
        "_internal_originalTime": 1689824859000
    },
    {
        "time": 1689824862000,
        "value": 139.242,
        "_internal_originalTime": 1689824862000
    },
    {
        "time": 1689824863000,
        "value": 139.248,
        "_internal_originalTime": 1689824863000
    },
    {
        "time": 1689824864000,
        "value": 139.242,
        "_internal_originalTime": 1689824864000
    },
    {
        "time": 1689824866000,
        "value": 139.246,
        "_internal_originalTime": 1689824866000
    },
    {
        "time": 1689824867000,
        "value": 139.246,
        "_internal_originalTime": 1689824867000
    },
    {
        "time": 1689824869000,
        "value": 139.246,
        "_internal_originalTime": 1689824869000
    },
    {
        "time": 1689824871000,
        "value": 139.247,
        "_internal_originalTime": 1689824871000
    },
    {
        "time": 1689824872000,
        "value": 139.244,
        "_internal_originalTime": 1689824872000
    },
    {
        "time": 1689824873000,
        "value": 139.243,
        "_internal_originalTime": 1689824873000
    },
    {
        "time": 1689824875000,
        "value": 139.238,
        "_internal_originalTime": 1689824875000
    },
    {
        "time": 1689824877000,
        "value": 139.236,
        "_internal_originalTime": 1689824877000
    },
    {
        "time": 1689824878000,
        "value": 139.24,
        "_internal_originalTime": 1689824878000
    },
    {
        "time": 1689824880000,
        "value": 139.239,
        "_internal_originalTime": 1689824880000
    },
    {
        "time": 1689824881000,
        "value": 139.234,
        "_internal_originalTime": 1689824881000
    },
    {
        "time": 1689824882000,
        "value": 139.238,
        "_internal_originalTime": 1689824882000
    },
    {
        "time": 1689824884000,
        "value": 139.234,
        "_internal_originalTime": 1689824884000
    },
    {
        "time": 1689824887000,
        "value": 139.237,
        "_internal_originalTime": 1689824887000
    },
    {
        "time": 1689824889000,
        "value": 139.235,
        "_internal_originalTime": 1689824889000
    },
    {
        "time": 1689824894000,
        "value": 139.239,
        "_internal_originalTime": 1689824894000
    },
    {
        "time": 1689824895000,
        "value": 139.238,
        "_internal_originalTime": 1689824895000
    },
    {
        "time": 1689824896000,
        "value": 139.239,
        "_internal_originalTime": 1689824896000
    },
    {
        "time": 1689824899000,
        "value": 139.239,
        "_internal_originalTime": 1689824899000
    },
    {
        "time": 1689824901000,
        "value": 139.234,
        "_internal_originalTime": 1689824901000
    },
    {
        "time": 1689824902000,
        "value": 139.238,
        "_internal_originalTime": 1689824902000
    },
    {
        "time": 1689824904000,
        "value": 139.234,
        "_internal_originalTime": 1689824904000
    },
    {
        "time": 1689824905000,
        "value": 139.234,
        "_internal_originalTime": 1689824905000
    },
    {
        "time": 1689824908000,
        "value": 139.235,
        "_internal_originalTime": 1689824908000
    },
    {
        "time": 1689824909000,
        "value": 139.234,
        "_internal_originalTime": 1689824909000
    },
    {
        "time": 1689824910000,
        "value": 139.232,
        "_internal_originalTime": 1689824910000
    },
    {
        "time": 1689824912000,
        "value": 139.237,
        "_internal_originalTime": 1689824912000
    },
    {
        "time": 1689824915000,
        "value": 139.237,
        "_internal_originalTime": 1689824915000
    },
    {
        "time": 1689824918000,
        "value": 139.237,
        "_internal_originalTime": 1689824918000
    },
    {
        "time": 1689824920000,
        "value": 139.239,
        "_internal_originalTime": 1689824920000
    },
    {
        "time": 1689824922000,
        "value": 139.237,
        "_internal_originalTime": 1689824922000
    },
    {
        "time": 1689824924000,
        "value": 139.235,
        "_internal_originalTime": 1689824924000
    },
    {
        "time": 1689824925000,
        "value": 139.238,
        "_internal_originalTime": 1689824925000
    },
    {
        "time": 1689824928000,
        "value": 139.231,
        "_internal_originalTime": 1689824928000
    },
    {
        "time": 1689824930000,
        "value": 139.228,
        "_internal_originalTime": 1689824930000
    },
    {
        "time": 1689824931000,
        "value": 139.222,
        "_internal_originalTime": 1689824931000
    },
    {
        "time": 1689824933000,
        "value": 139.223,
        "_internal_originalTime": 1689824933000
    },
    {
        "time": 1689824934000,
        "value": 139.223,
        "_internal_originalTime": 1689824934000
    },
    {
        "time": 1689824936000,
        "value": 139.223,
        "_internal_originalTime": 1689824936000
    },
    {
        "time": 1689824938000,
        "value": 139.224,
        "_internal_originalTime": 1689824938000
    },
    {
        "time": 1689824939000,
        "value": 139.22,
        "_internal_originalTime": 1689824939000
    },
    {
        "time": 1689824940000,
        "value": 139.225,
        "_internal_originalTime": 1689824940000
    },
    {
        "time": 1689824942000,
        "value": 139.224,
        "_internal_originalTime": 1689824942000
    },
    {
        "time": 1689824943000,
        "value": 139.223,
        "_internal_originalTime": 1689824943000
    },
    {
        "time": 1689824944000,
        "value": 139.224,
        "_internal_originalTime": 1689824944000
    },
    {
        "time": 1689824945000,
        "value": 139.221,
        "_internal_originalTime": 1689824945000
    },
    {
        "time": 1689824946000,
        "value": 139.22,
        "_internal_originalTime": 1689824946000
    },
    {
        "time": 1689824947000,
        "value": 139.226,
        "_internal_originalTime": 1689824947000
    },
    {
        "time": 1689824950000,
        "value": 139.224,
        "_internal_originalTime": 1689824950000
    },
    {
        "time": 1689824951000,
        "value": 139.229,
        "_internal_originalTime": 1689824951000
    },
    {
        "time": 1689824954000,
        "value": 139.229,
        "_internal_originalTime": 1689824954000
    },
    {
        "time": 1689824955000,
        "value": 139.229,
        "_internal_originalTime": 1689824955000
    },
    {
        "time": 1689824957000,
        "value": 139.229,
        "_internal_originalTime": 1689824957000
    },
    {
        "time": 1689824959000,
        "value": 139.23,
        "_internal_originalTime": 1689824959000
    },
    {
        "time": 1689824961000,
        "value": 139.224,
        "_internal_originalTime": 1689824961000
    },
    {
        "time": 1689824963000,
        "value": 139.224,
        "_internal_originalTime": 1689824963000
    },
    {
        "time": 1689824965000,
        "value": 139.229,
        "_internal_originalTime": 1689824965000
    },
    {
        "time": 1689824966000,
        "value": 139.229,
        "_internal_originalTime": 1689824966000
    },
    {
        "time": 1689824969000,
        "value": 139.23,
        "_internal_originalTime": 1689824969000
    },
    {
        "time": 1689824971000,
        "value": 139.224,
        "_internal_originalTime": 1689824971000
    },
    {
        "time": 1689824973000,
        "value": 139.229,
        "_internal_originalTime": 1689824973000
    },
    {
        "time": 1689824976000,
        "value": 139.245,
        "_internal_originalTime": 1689824976000
    },
    {
        "time": 1689824978000,
        "value": 139.24,
        "_internal_originalTime": 1689824978000
    },
    {
        "time": 1689824980000,
        "value": 139.239,
        "_internal_originalTime": 1689824980000
    },
    {
        "time": 1689824981000,
        "value": 139.237,
        "_internal_originalTime": 1689824981000
    },
    {
        "time": 1689824983000,
        "value": 139.232,
        "_internal_originalTime": 1689824983000
    },
    {
        "time": 1689824984000,
        "value": 139.234,
        "_internal_originalTime": 1689824984000
    },
    {
        "time": 1689824986000,
        "value": 139.23,
        "_internal_originalTime": 1689824986000
    },
    {
        "time": 1689824988000,
        "value": 139.238,
        "_internal_originalTime": 1689824988000
    },
    {
        "time": 1689824991000,
        "value": 139.237,
        "_internal_originalTime": 1689824991000
    },
    {
        "time": 1689824992000,
        "value": 139.237,
        "_internal_originalTime": 1689824992000
    },
    {
        "time": 1689824994000,
        "value": 139.234,
        "_internal_originalTime": 1689824994000
    },
    {
        "time": 1689824997000,
        "value": 139.235,
        "_internal_originalTime": 1689824997000
    },
    {
        "time": 1689824998000,
        "value": 139.231,
        "_internal_originalTime": 1689824998000
    },
    {
        "time": 1689824999000,
        "value": 139.232,
        "_internal_originalTime": 1689824999000
    },
    {
        "time": 1689825000000,
        "value": 139.238,
        "_internal_originalTime": 1689825000000
    },
    {
        "time": 1689825002000,
        "value": 139.237,
        "_internal_originalTime": 1689825002000
    },
    {
        "time": 1689825003000,
        "value": 139.235,
        "_internal_originalTime": 1689825003000
    },
    {
        "time": 1689825004000,
        "value": 139.236,
        "_internal_originalTime": 1689825004000
    },
    {
        "time": 1689825006000,
        "value": 139.232,
        "_internal_originalTime": 1689825006000
    },
    {
        "time": 1689825009000,
        "value": 139.238,
        "_internal_originalTime": 1689825009000
    },
    {
        "time": 1689825011000,
        "value": 139.238,
        "_internal_originalTime": 1689825011000
    },
    {
        "time": 1689825015000,
        "value": 139.244,
        "_internal_originalTime": 1689825015000
    },
    {
        "time": 1689825018000,
        "value": 139.24,
        "_internal_originalTime": 1689825018000
    },
    {
        "time": 1689825019000,
        "value": 139.244,
        "_internal_originalTime": 1689825019000
    },
    {
        "time": 1689825020000,
        "value": 139.246,
        "_internal_originalTime": 1689825020000
    },
    {
        "time": 1689825021000,
        "value": 139.246,
        "_internal_originalTime": 1689825021000
    },
    {
        "time": 1689825023000,
        "value": 139.24,
        "_internal_originalTime": 1689825023000
    },
    {
        "time": 1689825025000,
        "value": 139.237,
        "_internal_originalTime": 1689825025000
    },
    {
        "time": 1689825026000,
        "value": 139.234,
        "_internal_originalTime": 1689825026000
    },
    {
        "time": 1689825029000,
        "value": 139.23,
        "_internal_originalTime": 1689825029000
    },
    {
        "time": 1689825031000,
        "value": 139.236,
        "_internal_originalTime": 1689825031000
    },
    {
        "time": 1689825032000,
        "value": 139.237,
        "_internal_originalTime": 1689825032000
    },
    {
        "time": 1689825035000,
        "value": 139.232,
        "_internal_originalTime": 1689825035000
    },
    {
        "time": 1689825036000,
        "value": 139.232,
        "_internal_originalTime": 1689825036000
    },
    {
        "time": 1689825038000,
        "value": 139.235,
        "_internal_originalTime": 1689825038000
    },
    {
        "time": 1689825040000,
        "value": 139.235,
        "_internal_originalTime": 1689825040000
    },
    {
        "time": 1689825045000,
        "value": 139.231,
        "_internal_originalTime": 1689825045000
    },
    {
        "time": 1689825046000,
        "value": 139.232,
        "_internal_originalTime": 1689825046000
    },
    {
        "time": 1689825048000,
        "value": 139.236,
        "_internal_originalTime": 1689825048000
    },
    {
        "time": 1689825049000,
        "value": 139.235,
        "_internal_originalTime": 1689825049000
    },
    {
        "time": 1689825051000,
        "value": 139.232,
        "_internal_originalTime": 1689825051000
    },
    {
        "time": 1689825052000,
        "value": 139.231,
        "_internal_originalTime": 1689825052000
    },
    {
        "time": 1689825054000,
        "value": 139.236,
        "_internal_originalTime": 1689825054000
    },
    {
        "time": 1689825055000,
        "value": 139.232,
        "_internal_originalTime": 1689825055000
    },
    {
        "time": 1689825057000,
        "value": 139.232,
        "_internal_originalTime": 1689825057000
    },
    {
        "time": 1689825058000,
        "value": 139.235,
        "_internal_originalTime": 1689825058000
    },
    {
        "time": 1689825059000,
        "value": 139.237,
        "_internal_originalTime": 1689825059000
    },
    {
        "time": 1689825061000,
        "value": 139.232,
        "_internal_originalTime": 1689825061000
    },
    {
        "time": 1689825064000,
        "value": 139.232,
        "_internal_originalTime": 1689825064000
    },
    {
        "time": 1689825066000,
        "value": 139.232,
        "_internal_originalTime": 1689825066000
    },
    {
        "time": 1689825067000,
        "value": 139.235,
        "_internal_originalTime": 1689825067000
    },
    {
        "time": 1689825069000,
        "value": 139.234,
        "_internal_originalTime": 1689825069000
    },
    {
        "time": 1689825070000,
        "value": 139.235,
        "_internal_originalTime": 1689825070000
    },
    {
        "time": 1689825074000,
        "value": 139.236,
        "_internal_originalTime": 1689825074000
    },
    {
        "time": 1689825078000,
        "value": 139.238,
        "_internal_originalTime": 1689825078000
    },
    {
        "time": 1689825079000,
        "value": 139.238,
        "_internal_originalTime": 1689825079000
    },
    {
        "time": 1689825081000,
        "value": 139.237,
        "_internal_originalTime": 1689825081000
    },
    {
        "time": 1689825083000,
        "value": 139.236,
        "_internal_originalTime": 1689825083000
    },
    {
        "time": 1689825085000,
        "value": 139.234,
        "_internal_originalTime": 1689825085000
    },
    {
        "time": 1689825086000,
        "value": 139.237,
        "_internal_originalTime": 1689825086000
    },
    {
        "time": 1689825088000,
        "value": 139.22,
        "_internal_originalTime": 1689825088000
    },
    {
        "time": 1689825090000,
        "value": 139.224,
        "_internal_originalTime": 1689825090000
    },
    {
        "time": 1689825091000,
        "value": 139.225,
        "_internal_originalTime": 1689825091000
    },
    {
        "time": 1689825093000,
        "value": 139.228,
        "_internal_originalTime": 1689825093000
    },
    {
        "time": 1689825097000,
        "value": 139.228,
        "_internal_originalTime": 1689825097000
    },
    {
        "time": 1689825098000,
        "value": 139.229,
        "_internal_originalTime": 1689825098000
    },
    {
        "time": 1689825100000,
        "value": 139.228,
        "_internal_originalTime": 1689825100000
    },
    {
        "time": 1689825101000,
        "value": 139.229,
        "_internal_originalTime": 1689825101000
    },
    {
        "time": 1689825103000,
        "value": 139.23,
        "_internal_originalTime": 1689825103000
    },
    {
        "time": 1689825104000,
        "value": 139.23,
        "_internal_originalTime": 1689825104000
    },
    {
        "time": 1689825107000,
        "value": 139.23,
        "_internal_originalTime": 1689825107000
    },
    {
        "time": 1689825108000,
        "value": 139.23,
        "_internal_originalTime": 1689825108000
    },
    {
        "time": 1689825110000,
        "value": 139.229,
        "_internal_originalTime": 1689825110000
    },
    {
        "time": 1689825112000,
        "value": 139.229,
        "_internal_originalTime": 1689825112000
    },
    {
        "time": 1689825116000,
        "value": 139.229,
        "_internal_originalTime": 1689825116000
    },
    {
        "time": 1689825120000,
        "value": 139.225,
        "_internal_originalTime": 1689825120000
    },
    {
        "time": 1689825121000,
        "value": 139.229,
        "_internal_originalTime": 1689825121000
    },
    {
        "time": 1689825123000,
        "value": 139.229,
        "_internal_originalTime": 1689825123000
    },
    {
        "time": 1689825124000,
        "value": 139.225,
        "_internal_originalTime": 1689825124000
    },
    {
        "time": 1689825126000,
        "value": 139.23,
        "_internal_originalTime": 1689825126000
    },
    {
        "time": 1689825128000,
        "value": 139.229,
        "_internal_originalTime": 1689825128000
    },
    {
        "time": 1689825129000,
        "value": 139.225,
        "_internal_originalTime": 1689825129000
    },
    {
        "time": 1689825131000,
        "value": 139.224,
        "_internal_originalTime": 1689825131000
    },
    {
        "time": 1689825134000,
        "value": 139.228,
        "_internal_originalTime": 1689825134000
    },
    {
        "time": 1689825135000,
        "value": 139.227,
        "_internal_originalTime": 1689825135000
    },
    {
        "time": 1689825136000,
        "value": 139.226,
        "_internal_originalTime": 1689825136000
    },
    {
        "time": 1689825137000,
        "value": 139.225,
        "_internal_originalTime": 1689825137000
    },
    {
        "time": 1689825140000,
        "value": 139.226,
        "_internal_originalTime": 1689825140000
    },
    {
        "time": 1689825141000,
        "value": 139.226,
        "_internal_originalTime": 1689825141000
    },
    {
        "time": 1689825144000,
        "value": 139.226,
        "_internal_originalTime": 1689825144000
    },
    {
        "time": 1689825147000,
        "value": 139.225,
        "_internal_originalTime": 1689825147000
    },
    {
        "time": 1689825150000,
        "value": 139.227,
        "_internal_originalTime": 1689825150000
    },
    {
        "time": 1689825151000,
        "value": 139.221,
        "_internal_originalTime": 1689825151000
    },
    {
        "time": 1689825152000,
        "value": 139.226,
        "_internal_originalTime": 1689825152000
    },
    {
        "time": 1689825159000,
        "value": 139.224,
        "_internal_originalTime": 1689825159000
    },
    {
        "time": 1689825162000,
        "value": 139.223,
        "_internal_originalTime": 1689825162000
    },
    {
        "time": 1689825163000,
        "value": 139.228,
        "_internal_originalTime": 1689825163000
    },
    {
        "time": 1689825164000,
        "value": 139.227,
        "_internal_originalTime": 1689825164000
    },
    {
        "time": 1689825166000,
        "value": 139.226,
        "_internal_originalTime": 1689825166000
    },
    {
        "time": 1689825168000,
        "value": 139.214,
        "_internal_originalTime": 1689825168000
    },
    {
        "time": 1689825169000,
        "value": 139.218,
        "_internal_originalTime": 1689825169000
    },
    {
        "time": 1689825171000,
        "value": 139.217,
        "_internal_originalTime": 1689825171000
    },
    {
        "time": 1689825174000,
        "value": 139.216,
        "_internal_originalTime": 1689825174000
    },
    {
        "time": 1689825177000,
        "value": 139.214,
        "_internal_originalTime": 1689825177000
    },
    {
        "time": 1689825178000,
        "value": 139.219,
        "_internal_originalTime": 1689825178000
    },
    {
        "time": 1689825180000,
        "value": 139.213,
        "_internal_originalTime": 1689825180000
    },
    {
        "time": 1689825181000,
        "value": 139.216,
        "_internal_originalTime": 1689825181000
    },
    {
        "time": 1689825189000,
        "value": 139.212,
        "_internal_originalTime": 1689825189000
    },
    {
        "time": 1689825191000,
        "value": 139.216,
        "_internal_originalTime": 1689825191000
    },
    {
        "time": 1689825193000,
        "value": 139.217,
        "_internal_originalTime": 1689825193000
    },
    {
        "time": 1689825195000,
        "value": 139.219,
        "_internal_originalTime": 1689825195000
    },
    {
        "time": 1689825196000,
        "value": 139.216,
        "_internal_originalTime": 1689825196000
    },
    {
        "time": 1689825197000,
        "value": 139.216,
        "_internal_originalTime": 1689825197000
    },
    {
        "time": 1689825200000,
        "value": 139.214,
        "_internal_originalTime": 1689825200000
    },
    {
        "time": 1689825201000,
        "value": 139.218,
        "_internal_originalTime": 1689825201000
    },
    {
        "time": 1689825203000,
        "value": 139.219,
        "_internal_originalTime": 1689825203000
    },
    {
        "time": 1689825206000,
        "value": 139.219,
        "_internal_originalTime": 1689825206000
    },
    {
        "time": 1689825207000,
        "value": 139.219,
        "_internal_originalTime": 1689825207000
    },
    {
        "time": 1689825209000,
        "value": 139.217,
        "_internal_originalTime": 1689825209000
    },
    {
        "time": 1689825210000,
        "value": 139.219,
        "_internal_originalTime": 1689825210000
    },
    {
        "time": 1689825212000,
        "value": 139.218,
        "_internal_originalTime": 1689825212000
    },
    {
        "time": 1689825214000,
        "value": 139.222,
        "_internal_originalTime": 1689825214000
    },
    {
        "time": 1689825217000,
        "value": 139.223,
        "_internal_originalTime": 1689825217000
    },
    {
        "time": 1689825219000,
        "value": 139.219,
        "_internal_originalTime": 1689825219000
    },
    {
        "time": 1689825220000,
        "value": 139.217,
        "_internal_originalTime": 1689825220000
    },
    {
        "time": 1689825222000,
        "value": 139.221,
        "_internal_originalTime": 1689825222000
    },
    {
        "time": 1689825223000,
        "value": 139.22,
        "_internal_originalTime": 1689825223000
    },
    {
        "time": 1689825224000,
        "value": 139.221,
        "_internal_originalTime": 1689825224000
    },
    {
        "time": 1689825231000,
        "value": 139.222,
        "_internal_originalTime": 1689825231000
    },
    {
        "time": 1689825232000,
        "value": 139.221,
        "_internal_originalTime": 1689825232000
    },
    {
        "time": 1689825235000,
        "value": 139.221,
        "_internal_originalTime": 1689825235000
    },
    {
        "time": 1689825239000,
        "value": 139.222,
        "_internal_originalTime": 1689825239000
    },
    {
        "time": 1689825240000,
        "value": 139.224,
        "_internal_originalTime": 1689825240000
    },
    {
        "time": 1689825241000,
        "value": 139.222,
        "_internal_originalTime": 1689825241000
    },
    {
        "time": 1689825243000,
        "value": 139.229,
        "_internal_originalTime": 1689825243000
    },
    {
        "time": 1689825245000,
        "value": 139.237,
        "_internal_originalTime": 1689825245000
    },
    {
        "time": 1689825246000,
        "value": 139.232,
        "_internal_originalTime": 1689825246000
    },
    {
        "time": 1689825248000,
        "value": 139.236,
        "_internal_originalTime": 1689825248000
    },
    {
        "time": 1689825251000,
        "value": 139.235,
        "_internal_originalTime": 1689825251000
    },
    {
        "time": 1689825252000,
        "value": 139.231,
        "_internal_originalTime": 1689825252000
    },
    {
        "time": 1689825254000,
        "value": 139.235,
        "_internal_originalTime": 1689825254000
    },
    {
        "time": 1689825255000,
        "value": 139.23,
        "_internal_originalTime": 1689825255000
    },
    {
        "time": 1689825259000,
        "value": 139.23,
        "_internal_originalTime": 1689825259000
    },
    {
        "time": 1689825260000,
        "value": 139.235,
        "_internal_originalTime": 1689825260000
    },
    {
        "time": 1689825261000,
        "value": 139.235,
        "_internal_originalTime": 1689825261000
    },
    {
        "time": 1689825266000,
        "value": 139.231,
        "_internal_originalTime": 1689825266000
    },
    {
        "time": 1689825267000,
        "value": 139.228,
        "_internal_originalTime": 1689825267000
    },
    {
        "time": 1689825268000,
        "value": 139.23,
        "_internal_originalTime": 1689825268000
    },
    {
        "time": 1689825271000,
        "value": 139.229,
        "_internal_originalTime": 1689825271000
    },
    {
        "time": 1689825275000,
        "value": 139.23,
        "_internal_originalTime": 1689825275000
    },
    {
        "time": 1689825276000,
        "value": 139.23,
        "_internal_originalTime": 1689825276000
    },
    {
        "time": 1689825278000,
        "value": 139.222,
        "_internal_originalTime": 1689825278000
    },
    {
        "time": 1689825279000,
        "value": 139.225,
        "_internal_originalTime": 1689825279000
    },
    {
        "time": 1689825304000,
        "value": 139.22,
        "_internal_originalTime": 1689825304000
    },
    {
        "time": 1689825307000,
        "value": 139.214,
        "_internal_originalTime": 1689825307000
    },
    {
        "time": 1689825309000,
        "value": 139.214,
        "_internal_originalTime": 1689825309000
    },
    {
        "time": 1689825312000,
        "value": 139.223,
        "_internal_originalTime": 1689825312000
    },
    {
        "time": 1689825313000,
        "value": 139.222,
        "_internal_originalTime": 1689825313000
    },
    {
        "time": 1689825319000,
        "value": 139.205,
        "_internal_originalTime": 1689825319000
    },
    {
        "time": 1689825320000,
        "value": 139.205,
        "_internal_originalTime": 1689825320000
    },
    {
        "time": 1689825322000,
        "value": 139.199,
        "_internal_originalTime": 1689825322000
    },
    {
        "time": 1689825323000,
        "value": 139.2,
        "_internal_originalTime": 1689825323000
    },
    {
        "time": 1689825326000,
        "value": 139.2,
        "_internal_originalTime": 1689825326000
    },
    {
        "time": 1689825327000,
        "value": 139.205,
        "_internal_originalTime": 1689825327000
    },
    {
        "time": 1689825328000,
        "value": 139.199,
        "_internal_originalTime": 1689825328000
    },
    {
        "time": 1689825331000,
        "value": 139.198,
        "_internal_originalTime": 1689825331000
    },
    {
        "time": 1689825332000,
        "value": 139.2,
        "_internal_originalTime": 1689825332000
    },
    {
        "time": 1689825334000,
        "value": 139.2,
        "_internal_originalTime": 1689825334000
    },
    {
        "time": 1689825336000,
        "value": 139.201,
        "_internal_originalTime": 1689825336000
    },
    {
        "time": 1689825337000,
        "value": 139.2,
        "_internal_originalTime": 1689825337000
    },
    {
        "time": 1689825339000,
        "value": 139.2,
        "_internal_originalTime": 1689825339000
    },
    {
        "time": 1689825340000,
        "value": 139.2,
        "_internal_originalTime": 1689825340000
    },
    {
        "time": 1689825344000,
        "value": 139.199,
        "_internal_originalTime": 1689825344000
    },
    {
        "time": 1689825347000,
        "value": 139.207,
        "_internal_originalTime": 1689825347000
    },
    {
        "time": 1689825348000,
        "value": 139.206,
        "_internal_originalTime": 1689825348000
    },
    {
        "time": 1689825350000,
        "value": 139.206,
        "_internal_originalTime": 1689825350000
    },
    {
        "time": 1689825353000,
        "value": 139.2,
        "_internal_originalTime": 1689825353000
    },
    {
        "time": 1689825355000,
        "value": 139.201,
        "_internal_originalTime": 1689825355000
    },
    {
        "time": 1689825357000,
        "value": 139.206,
        "_internal_originalTime": 1689825357000
    },
    {
        "time": 1689825360000,
        "value": 139.206,
        "_internal_originalTime": 1689825360000
    },
    {
        "time": 1689825362000,
        "value": 139.206,
        "_internal_originalTime": 1689825362000
    },
    {
        "time": 1689825363000,
        "value": 139.206,
        "_internal_originalTime": 1689825363000
    },
    {
        "time": 1689825364000,
        "value": 139.2,
        "_internal_originalTime": 1689825364000
    },
    {
        "time": 1689825365000,
        "value": 139.2,
        "_internal_originalTime": 1689825365000
    },
    {
        "time": 1689825368000,
        "value": 139.2,
        "_internal_originalTime": 1689825368000
    },
    {
        "time": 1689825369000,
        "value": 139.203,
        "_internal_originalTime": 1689825369000
    },
    {
        "time": 1689825372000,
        "value": 139.198,
        "_internal_originalTime": 1689825372000
    },
    {
        "time": 1689825373000,
        "value": 139.192,
        "_internal_originalTime": 1689825373000
    },
    {
        "time": 1689825375000,
        "value": 139.198,
        "_internal_originalTime": 1689825375000
    },
    {
        "time": 1689825376000,
        "value": 139.197,
        "_internal_originalTime": 1689825376000
    },
    {
        "time": 1689825380000,
        "value": 139.197,
        "_internal_originalTime": 1689825380000
    },
    {
        "time": 1689825382000,
        "value": 139.182,
        "_internal_originalTime": 1689825382000
    },
    {
        "time": 1689825383000,
        "value": 139.188,
        "_internal_originalTime": 1689825383000
    },
    {
        "time": 1689825386000,
        "value": 139.182,
        "_internal_originalTime": 1689825386000
    },
    {
        "time": 1689825387000,
        "value": 139.187,
        "_internal_originalTime": 1689825387000
    },
    {
        "time": 1689825388000,
        "value": 139.186,
        "_internal_originalTime": 1689825388000
    },
    {
        "time": 1689825389000,
        "value": 139.181,
        "_internal_originalTime": 1689825389000
    },
    {
        "time": 1689825391000,
        "value": 139.186,
        "_internal_originalTime": 1689825391000
    },
    {
        "time": 1689825393000,
        "value": 139.186,
        "_internal_originalTime": 1689825393000
    },
    {
        "time": 1689825397000,
        "value": 139.186,
        "_internal_originalTime": 1689825397000
    },
    {
        "time": 1689825399000,
        "value": 139.187,
        "_internal_originalTime": 1689825399000
    },
    {
        "time": 1689825400000,
        "value": 139.186,
        "_internal_originalTime": 1689825400000
    },
    {
        "time": 1689825402000,
        "value": 139.186,
        "_internal_originalTime": 1689825402000
    },
    {
        "time": 1689825404000,
        "value": 139.187,
        "_internal_originalTime": 1689825404000
    },
    {
        "time": 1689825405000,
        "value": 139.187,
        "_internal_originalTime": 1689825405000
    },
    {
        "time": 1689825406000,
        "value": 139.186,
        "_internal_originalTime": 1689825406000
    },
    {
        "time": 1689825407000,
        "value": 139.188,
        "_internal_originalTime": 1689825407000
    },
    {
        "time": 1689825409000,
        "value": 139.187,
        "_internal_originalTime": 1689825409000
    },
    {
        "time": 1689825411000,
        "value": 139.187,
        "_internal_originalTime": 1689825411000
    },
    {
        "time": 1689825413000,
        "value": 139.187,
        "_internal_originalTime": 1689825413000
    },
    {
        "time": 1689825414000,
        "value": 139.187,
        "_internal_originalTime": 1689825414000
    },
    {
        "time": 1689825415000,
        "value": 139.187,
        "_internal_originalTime": 1689825415000
    },
    {
        "time": 1689825417000,
        "value": 139.178,
        "_internal_originalTime": 1689825417000
    },
    {
        "time": 1689825418000,
        "value": 139.178,
        "_internal_originalTime": 1689825418000
    },
    {
        "time": 1689825420000,
        "value": 139.175,
        "_internal_originalTime": 1689825420000
    },
    {
        "time": 1689825421000,
        "value": 139.177,
        "_internal_originalTime": 1689825421000
    },
    {
        "time": 1689825422000,
        "value": 139.176,
        "_internal_originalTime": 1689825422000
    },
    {
        "time": 1689825426000,
        "value": 139.176,
        "_internal_originalTime": 1689825426000
    },
    {
        "time": 1689825428000,
        "value": 139.173,
        "_internal_originalTime": 1689825428000
    },
    {
        "time": 1689825429000,
        "value": 139.173,
        "_internal_originalTime": 1689825429000
    },
    {
        "time": 1689825431000,
        "value": 139.182,
        "_internal_originalTime": 1689825431000
    },
    {
        "time": 1689825433000,
        "value": 139.176,
        "_internal_originalTime": 1689825433000
    },
    {
        "time": 1689825434000,
        "value": 139.18,
        "_internal_originalTime": 1689825434000
    },
    {
        "time": 1689825436000,
        "value": 139.176,
        "_internal_originalTime": 1689825436000
    },
    {
        "time": 1689825437000,
        "value": 139.174,
        "_internal_originalTime": 1689825437000
    },
    {
        "time": 1689825439000,
        "value": 139.178,
        "_internal_originalTime": 1689825439000
    },
    {
        "time": 1689825441000,
        "value": 139.175,
        "_internal_originalTime": 1689825441000
    },
    {
        "time": 1689825442000,
        "value": 139.178,
        "_internal_originalTime": 1689825442000
    },
    {
        "time": 1689825444000,
        "value": 139.18,
        "_internal_originalTime": 1689825444000
    },
    {
        "time": 1689825445000,
        "value": 139.177,
        "_internal_originalTime": 1689825445000
    },
    {
        "time": 1689825448000,
        "value": 139.175,
        "_internal_originalTime": 1689825448000
    },
    {
        "time": 1689825450000,
        "value": 139.179,
        "_internal_originalTime": 1689825450000
    },
    {
        "time": 1689825451000,
        "value": 139.174,
        "_internal_originalTime": 1689825451000
    },
    {
        "time": 1689825456000,
        "value": 139.184,
        "_internal_originalTime": 1689825456000
    },
    {
        "time": 1689825457000,
        "value": 139.183,
        "_internal_originalTime": 1689825457000
    },
    {
        "time": 1689825460000,
        "value": 139.18,
        "_internal_originalTime": 1689825460000
    },
    {
        "time": 1689825461000,
        "value": 139.176,
        "_internal_originalTime": 1689825461000
    },
    {
        "time": 1689825465000,
        "value": 139.176,
        "_internal_originalTime": 1689825465000
    },
    {
        "time": 1689825466000,
        "value": 139.177,
        "_internal_originalTime": 1689825466000
    },
    {
        "time": 1689825468000,
        "value": 139.177,
        "_internal_originalTime": 1689825468000
    },
    {
        "time": 1689825470000,
        "value": 139.175,
        "_internal_originalTime": 1689825470000
    },
    {
        "time": 1689825474000,
        "value": 139.176,
        "_internal_originalTime": 1689825474000
    },
    {
        "time": 1689825475000,
        "value": 139.178,
        "_internal_originalTime": 1689825475000
    },
    {
        "time": 1689825477000,
        "value": 139.183,
        "_internal_originalTime": 1689825477000
    },
    {
        "time": 1689825480000,
        "value": 139.183,
        "_internal_originalTime": 1689825480000
    },
    {
        "time": 1689825481000,
        "value": 139.186,
        "_internal_originalTime": 1689825481000
    },
    {
        "time": 1689825482000,
        "value": 139.183,
        "_internal_originalTime": 1689825482000
    },
    {
        "time": 1689825484000,
        "value": 139.183,
        "_internal_originalTime": 1689825484000
    },
    {
        "time": 1689825485000,
        "value": 139.184,
        "_internal_originalTime": 1689825485000
    },
    {
        "time": 1689825487000,
        "value": 139.188,
        "_internal_originalTime": 1689825487000
    },
    {
        "time": 1689825489000,
        "value": 139.185,
        "_internal_originalTime": 1689825489000
    },
    {
        "time": 1689825492000,
        "value": 139.188,
        "_internal_originalTime": 1689825492000
    },
    {
        "time": 1689825494000,
        "value": 139.182,
        "_internal_originalTime": 1689825494000
    },
    {
        "time": 1689825496000,
        "value": 139.193,
        "_internal_originalTime": 1689825496000
    },
    {
        "time": 1689825498000,
        "value": 139.188,
        "_internal_originalTime": 1689825498000
    },
    {
        "time": 1689825500000,
        "value": 139.187,
        "_internal_originalTime": 1689825500000
    },
    {
        "time": 1689825501000,
        "value": 139.18,
        "_internal_originalTime": 1689825501000
    },
    {
        "time": 1689825503000,
        "value": 139.185,
        "_internal_originalTime": 1689825503000
    },
    {
        "time": 1689825505000,
        "value": 139.186,
        "_internal_originalTime": 1689825505000
    },
    {
        "time": 1689825507000,
        "value": 139.18,
        "_internal_originalTime": 1689825507000
    },
    {
        "time": 1689825509000,
        "value": 139.181,
        "_internal_originalTime": 1689825509000
    },
    {
        "time": 1689825511000,
        "value": 139.18,
        "_internal_originalTime": 1689825511000
    },
    {
        "time": 1689825513000,
        "value": 139.181,
        "_internal_originalTime": 1689825513000
    },
    {
        "time": 1689825515000,
        "value": 139.19,
        "_internal_originalTime": 1689825515000
    },
    {
        "time": 1689825516000,
        "value": 139.188,
        "_internal_originalTime": 1689825516000
    },
    {
        "time": 1689825518000,
        "value": 139.184,
        "_internal_originalTime": 1689825518000
    },
    {
        "time": 1689825520000,
        "value": 139.188,
        "_internal_originalTime": 1689825520000
    },
    {
        "time": 1689825523000,
        "value": 139.182,
        "_internal_originalTime": 1689825523000
    },
    {
        "time": 1689825525000,
        "value": 139.186,
        "_internal_originalTime": 1689825525000
    },
    {
        "time": 1689825526000,
        "value": 139.186,
        "_internal_originalTime": 1689825526000
    },
    {
        "time": 1689825527000,
        "value": 139.18,
        "_internal_originalTime": 1689825527000
    },
    {
        "time": 1689825529000,
        "value": 139.183,
        "_internal_originalTime": 1689825529000
    },
    {
        "time": 1689825530000,
        "value": 139.18,
        "_internal_originalTime": 1689825530000
    },
    {
        "time": 1689825532000,
        "value": 139.181,
        "_internal_originalTime": 1689825532000
    },
    {
        "time": 1689825534000,
        "value": 139.189,
        "_internal_originalTime": 1689825534000
    },
    {
        "time": 1689825537000,
        "value": 139.186,
        "_internal_originalTime": 1689825537000
    },
    {
        "time": 1689825538000,
        "value": 139.185,
        "_internal_originalTime": 1689825538000
    },
    {
        "time": 1689825542000,
        "value": 139.181,
        "_internal_originalTime": 1689825542000
    },
    {
        "time": 1689825544000,
        "value": 139.187,
        "_internal_originalTime": 1689825544000
    },
    {
        "time": 1689825545000,
        "value": 139.185,
        "_internal_originalTime": 1689825545000
    },
    {
        "time": 1689825548000,
        "value": 139.188,
        "_internal_originalTime": 1689825548000
    },
    {
        "time": 1689825549000,
        "value": 139.188,
        "_internal_originalTime": 1689825549000
    },
    {
        "time": 1689825555000,
        "value": 139.187,
        "_internal_originalTime": 1689825555000
    },
    {
        "time": 1689825556000,
        "value": 139.183,
        "_internal_originalTime": 1689825556000
    },
    {
        "time": 1689825559000,
        "value": 139.187,
        "_internal_originalTime": 1689825559000
    },
    {
        "time": 1689825561000,
        "value": 139.182,
        "_internal_originalTime": 1689825561000
    },
    {
        "time": 1689825566000,
        "value": 139.184,
        "_internal_originalTime": 1689825566000
    },
    {
        "time": 1689825568000,
        "value": 139.183,
        "_internal_originalTime": 1689825568000
    },
    {
        "time": 1689825569000,
        "value": 139.185,
        "_internal_originalTime": 1689825569000
    },
    {
        "time": 1689825570000,
        "value": 139.183,
        "_internal_originalTime": 1689825570000
    },
    {
        "time": 1689825572000,
        "value": 139.183,
        "_internal_originalTime": 1689825572000
    },
    {
        "time": 1689825576000,
        "value": 139.184,
        "_internal_originalTime": 1689825576000
    },
    {
        "time": 1689825580000,
        "value": 139.183,
        "_internal_originalTime": 1689825580000
    },
    {
        "time": 1689825583000,
        "value": 139.186,
        "_internal_originalTime": 1689825583000
    },
    {
        "time": 1689825584000,
        "value": 139.187,
        "_internal_originalTime": 1689825584000
    },
    {
        "time": 1689825585000,
        "value": 139.188,
        "_internal_originalTime": 1689825585000
    },
    {
        "time": 1689825588000,
        "value": 139.184,
        "_internal_originalTime": 1689825588000
    },
    {
        "time": 1689825589000,
        "value": 139.187,
        "_internal_originalTime": 1689825589000
    },
    {
        "time": 1689825592000,
        "value": 139.182,
        "_internal_originalTime": 1689825592000
    },
    {
        "time": 1689825596000,
        "value": 139.186,
        "_internal_originalTime": 1689825596000
    },
    {
        "time": 1689825600000,
        "value": 139.183,
        "_internal_originalTime": 1689825600000
    },
    {
        "time": 1689825601000,
        "value": 139.187,
        "_internal_originalTime": 1689825601000
    },
    {
        "time": 1689825602000,
        "value": 139.187,
        "_internal_originalTime": 1689825602000
    },
    {
        "time": 1689825603000,
        "value": 139.186,
        "_internal_originalTime": 1689825603000
    },
    {
        "time": 1689825605000,
        "value": 139.183,
        "_internal_originalTime": 1689825605000
    },
    {
        "time": 1689825607000,
        "value": 139.188,
        "_internal_originalTime": 1689825607000
    },
    {
        "time": 1689825609000,
        "value": 139.185,
        "_internal_originalTime": 1689825609000
    },
    {
        "time": 1689825610000,
        "value": 139.186,
        "_internal_originalTime": 1689825610000
    },
    {
        "time": 1689825611000,
        "value": 139.187,
        "_internal_originalTime": 1689825611000
    },
    {
        "time": 1689825614000,
        "value": 139.182,
        "_internal_originalTime": 1689825614000
    },
    {
        "time": 1689825615000,
        "value": 139.188,
        "_internal_originalTime": 1689825615000
    },
    {
        "time": 1689825617000,
        "value": 139.188,
        "_internal_originalTime": 1689825617000
    },
    {
        "time": 1689825618000,
        "value": 139.188,
        "_internal_originalTime": 1689825618000
    },
    {
        "time": 1689825621000,
        "value": 139.182,
        "_internal_originalTime": 1689825621000
    },
    {
        "time": 1689825623000,
        "value": 139.181,
        "_internal_originalTime": 1689825623000
    },
    {
        "time": 1689825624000,
        "value": 139.182,
        "_internal_originalTime": 1689825624000
    },
    {
        "time": 1689825626000,
        "value": 139.185,
        "_internal_originalTime": 1689825626000
    },
    {
        "time": 1689825628000,
        "value": 139.182,
        "_internal_originalTime": 1689825628000
    },
    {
        "time": 1689825629000,
        "value": 139.188,
        "_internal_originalTime": 1689825629000
    },
    {
        "time": 1689825631000,
        "value": 139.181,
        "_internal_originalTime": 1689825631000
    },
    {
        "time": 1689825634000,
        "value": 139.185,
        "_internal_originalTime": 1689825634000
    },
    {
        "time": 1689825635000,
        "value": 139.186,
        "_internal_originalTime": 1689825635000
    },
    {
        "time": 1689825637000,
        "value": 139.186,
        "_internal_originalTime": 1689825637000
    },
    {
        "time": 1689825639000,
        "value": 139.183,
        "_internal_originalTime": 1689825639000
    },
    {
        "time": 1689825641000,
        "value": 139.184,
        "_internal_originalTime": 1689825641000
    },
    {
        "time": 1689825642000,
        "value": 139.188,
        "_internal_originalTime": 1689825642000
    },
    {
        "time": 1689825644000,
        "value": 139.197,
        "_internal_originalTime": 1689825644000
    },
    {
        "time": 1689825645000,
        "value": 139.192,
        "_internal_originalTime": 1689825645000
    },
    {
        "time": 1689825646000,
        "value": 139.196,
        "_internal_originalTime": 1689825646000
    },
    {
        "time": 1689825649000,
        "value": 139.196,
        "_internal_originalTime": 1689825649000
    },
    {
        "time": 1689825651000,
        "value": 139.194,
        "_internal_originalTime": 1689825651000
    },
    {
        "time": 1689825653000,
        "value": 139.198,
        "_internal_originalTime": 1689825653000
    },
    {
        "time": 1689825654000,
        "value": 139.193,
        "_internal_originalTime": 1689825654000
    },
    {
        "time": 1689825656000,
        "value": 139.198,
        "_internal_originalTime": 1689825656000
    },
    {
        "time": 1689825658000,
        "value": 139.198,
        "_internal_originalTime": 1689825658000
    },
    {
        "time": 1689825660000,
        "value": 139.194,
        "_internal_originalTime": 1689825660000
    },
    {
        "time": 1689825661000,
        "value": 139.191,
        "_internal_originalTime": 1689825661000
    },
    {
        "time": 1689825664000,
        "value": 139.196,
        "_internal_originalTime": 1689825664000
    },
    {
        "time": 1689825666000,
        "value": 139.192,
        "_internal_originalTime": 1689825666000
    },
    {
        "time": 1689825668000,
        "value": 139.193,
        "_internal_originalTime": 1689825668000
    },
    {
        "time": 1689825670000,
        "value": 139.193,
        "_internal_originalTime": 1689825670000
    },
    {
        "time": 1689825673000,
        "value": 139.192,
        "_internal_originalTime": 1689825673000
    },
    {
        "time": 1689825685000,
        "value": 139.196,
        "_internal_originalTime": 1689825685000
    },
    {
        "time": 1689825686000,
        "value": 139.192,
        "_internal_originalTime": 1689825686000
    },
    {
        "time": 1689825688000,
        "value": 139.192,
        "_internal_originalTime": 1689825688000
    },
    {
        "time": 1689825689000,
        "value": 139.192,
        "_internal_originalTime": 1689825689000
    },
    {
        "time": 1689825691000,
        "value": 139.19,
        "_internal_originalTime": 1689825691000
    },
    {
        "time": 1689825694000,
        "value": 139.192,
        "_internal_originalTime": 1689825694000
    },
    {
        "time": 1689825697000,
        "value": 139.192,
        "_internal_originalTime": 1689825697000
    },
    {
        "time": 1689825699000,
        "value": 139.194,
        "_internal_originalTime": 1689825699000
    },
    {
        "time": 1689825705000,
        "value": 139.193,
        "_internal_originalTime": 1689825705000
    },
    {
        "time": 1689825706000,
        "value": 139.192,
        "_internal_originalTime": 1689825706000
    },
    {
        "time": 1689825708000,
        "value": 139.193,
        "_internal_originalTime": 1689825708000
    },
    {
        "time": 1689825710000,
        "value": 139.196,
        "_internal_originalTime": 1689825710000
    },
    {
        "time": 1689825714000,
        "value": 139.196,
        "_internal_originalTime": 1689825714000
    },
    {
        "time": 1689825716000,
        "value": 139.191,
        "_internal_originalTime": 1689825716000
    },
    {
        "time": 1689825718000,
        "value": 139.196,
        "_internal_originalTime": 1689825718000
    },
    {
        "time": 1689825720000,
        "value": 139.187,
        "_internal_originalTime": 1689825720000
    },
    {
        "time": 1689825721000,
        "value": 139.182,
        "_internal_originalTime": 1689825721000
    },
    {
        "time": 1689825722000,
        "value": 139.185,
        "_internal_originalTime": 1689825722000
    },
    {
        "time": 1689825724000,
        "value": 139.184,
        "_internal_originalTime": 1689825724000
    },
    {
        "time": 1689825725000,
        "value": 139.184,
        "_internal_originalTime": 1689825725000
    },
    {
        "time": 1689825727000,
        "value": 139.186,
        "_internal_originalTime": 1689825727000
    },
    {
        "time": 1689825729000,
        "value": 139.182,
        "_internal_originalTime": 1689825729000
    },
    {
        "time": 1689825732000,
        "value": 139.182,
        "_internal_originalTime": 1689825732000
    },
    {
        "time": 1689825733000,
        "value": 139.196,
        "_internal_originalTime": 1689825733000
    },
    {
        "time": 1689825734000,
        "value": 139.193,
        "_internal_originalTime": 1689825734000
    },
    {
        "time": 1689825735000,
        "value": 139.192,
        "_internal_originalTime": 1689825735000
    },
    {
        "time": 1689825738000,
        "value": 139.193,
        "_internal_originalTime": 1689825738000
    },
    {
        "time": 1689825740000,
        "value": 139.193,
        "_internal_originalTime": 1689825740000
    },
    {
        "time": 1689825741000,
        "value": 139.196,
        "_internal_originalTime": 1689825741000
    },
    {
        "time": 1689825744000,
        "value": 139.193,
        "_internal_originalTime": 1689825744000
    },
    {
        "time": 1689825746000,
        "value": 139.195,
        "_internal_originalTime": 1689825746000
    },
    {
        "time": 1689825747000,
        "value": 139.196,
        "_internal_originalTime": 1689825747000
    },
    {
        "time": 1689825749000,
        "value": 139.196,
        "_internal_originalTime": 1689825749000
    },
    {
        "time": 1689825750000,
        "value": 139.193,
        "_internal_originalTime": 1689825750000
    },
    {
        "time": 1689825752000,
        "value": 139.197,
        "_internal_originalTime": 1689825752000
    },
    {
        "time": 1689825753000,
        "value": 139.196,
        "_internal_originalTime": 1689825753000
    },
    {
        "time": 1689825754000,
        "value": 139.196,
        "_internal_originalTime": 1689825754000
    },
    {
        "time": 1689825756000,
        "value": 139.193,
        "_internal_originalTime": 1689825756000
    },
    {
        "time": 1689825759000,
        "value": 139.195,
        "_internal_originalTime": 1689825759000
    },
    {
        "time": 1689825760000,
        "value": 139.192,
        "_internal_originalTime": 1689825760000
    },
    {
        "time": 1689825762000,
        "value": 139.195,
        "_internal_originalTime": 1689825762000
    },
    {
        "time": 1689825763000,
        "value": 139.193,
        "_internal_originalTime": 1689825763000
    },
    {
        "time": 1689825765000,
        "value": 139.193,
        "_internal_originalTime": 1689825765000
    },
    {
        "time": 1689825766000,
        "value": 139.193,
        "_internal_originalTime": 1689825766000
    },
    {
        "time": 1689825768000,
        "value": 139.188,
        "_internal_originalTime": 1689825768000
    },
    {
        "time": 1689825769000,
        "value": 139.187,
        "_internal_originalTime": 1689825769000
    },
    {
        "time": 1689825770000,
        "value": 139.184,
        "_internal_originalTime": 1689825770000
    },
    {
        "time": 1689825774000,
        "value": 139.187,
        "_internal_originalTime": 1689825774000
    },
    {
        "time": 1689825778000,
        "value": 139.182,
        "_internal_originalTime": 1689825778000
    },
    {
        "time": 1689825780000,
        "value": 139.182,
        "_internal_originalTime": 1689825780000
    },
    {
        "time": 1689825782000,
        "value": 139.185,
        "_internal_originalTime": 1689825782000
    },
    {
        "time": 1689825787000,
        "value": 139.186,
        "_internal_originalTime": 1689825787000
    },
    {
        "time": 1689825789000,
        "value": 139.184,
        "_internal_originalTime": 1689825789000
    },
    {
        "time": 1689825792000,
        "value": 139.178,
        "_internal_originalTime": 1689825792000
    },
    {
        "time": 1689825794000,
        "value": 139.177,
        "_internal_originalTime": 1689825794000
    },
    {
        "time": 1689825797000,
        "value": 139.188,
        "_internal_originalTime": 1689825797000
    },
    {
        "time": 1689825799000,
        "value": 139.186,
        "_internal_originalTime": 1689825799000
    },
    {
        "time": 1689825800000,
        "value": 139.184,
        "_internal_originalTime": 1689825800000
    },
    {
        "time": 1689825802000,
        "value": 139.183,
        "_internal_originalTime": 1689825802000
    },
    {
        "time": 1689825804000,
        "value": 139.184,
        "_internal_originalTime": 1689825804000
    },
    {
        "time": 1689825805000,
        "value": 139.182,
        "_internal_originalTime": 1689825805000
    },
    {
        "time": 1689825806000,
        "value": 139.186,
        "_internal_originalTime": 1689825806000
    },
    {
        "time": 1689825808000,
        "value": 139.18,
        "_internal_originalTime": 1689825808000
    },
    {
        "time": 1689825809000,
        "value": 139.181,
        "_internal_originalTime": 1689825809000
    },
    {
        "time": 1689825811000,
        "value": 139.181,
        "_internal_originalTime": 1689825811000
    },
    {
        "time": 1689825819000,
        "value": 139.184,
        "_internal_originalTime": 1689825819000
    },
    {
        "time": 1689825820000,
        "value": 139.187,
        "_internal_originalTime": 1689825820000
    },
    {
        "time": 1689825823000,
        "value": 139.198,
        "_internal_originalTime": 1689825823000
    },
    {
        "time": 1689825826000,
        "value": 139.198,
        "_internal_originalTime": 1689825826000
    },
    {
        "time": 1689825829000,
        "value": 139.195,
        "_internal_originalTime": 1689825829000
    },
    {
        "time": 1689825833000,
        "value": 139.195,
        "_internal_originalTime": 1689825833000
    },
    {
        "time": 1689825838000,
        "value": 139.194,
        "_internal_originalTime": 1689825838000
    },
    {
        "time": 1689825839000,
        "value": 139.194,
        "_internal_originalTime": 1689825839000
    },
    {
        "time": 1689825840000,
        "value": 139.192,
        "_internal_originalTime": 1689825840000
    },
    {
        "time": 1689825842000,
        "value": 139.196,
        "_internal_originalTime": 1689825842000
    },
    {
        "time": 1689825843000,
        "value": 139.196,
        "_internal_originalTime": 1689825843000
    },
    {
        "time": 1689825844000,
        "value": 139.197,
        "_internal_originalTime": 1689825844000
    },
    {
        "time": 1689825845000,
        "value": 139.198,
        "_internal_originalTime": 1689825845000
    },
    {
        "time": 1689825849000,
        "value": 139.195,
        "_internal_originalTime": 1689825849000
    },
    {
        "time": 1689825855000,
        "value": 139.192,
        "_internal_originalTime": 1689825855000
    },
    {
        "time": 1689825857000,
        "value": 139.197,
        "_internal_originalTime": 1689825857000
    },
    {
        "time": 1689825858000,
        "value": 139.197,
        "_internal_originalTime": 1689825858000
    },
    {
        "time": 1689825861000,
        "value": 139.196,
        "_internal_originalTime": 1689825861000
    },
    {
        "time": 1689825863000,
        "value": 139.196,
        "_internal_originalTime": 1689825863000
    },
    {
        "time": 1689825865000,
        "value": 139.192,
        "_internal_originalTime": 1689825865000
    },
    {
        "time": 1689825866000,
        "value": 139.196,
        "_internal_originalTime": 1689825866000
    },
    {
        "time": 1689825867000,
        "value": 139.196,
        "_internal_originalTime": 1689825867000
    },
    {
        "time": 1689825871000,
        "value": 139.192,
        "_internal_originalTime": 1689825871000
    },
    {
        "time": 1689825872000,
        "value": 139.195,
        "_internal_originalTime": 1689825872000
    },
    {
        "time": 1689825876000,
        "value": 139.192,
        "_internal_originalTime": 1689825876000
    },
    {
        "time": 1689825879000,
        "value": 139.193,
        "_internal_originalTime": 1689825879000
    },
    {
        "time": 1689825883000,
        "value": 139.186,
        "_internal_originalTime": 1689825883000
    },
    {
        "time": 1689825884000,
        "value": 139.19,
        "_internal_originalTime": 1689825884000
    },
    {
        "time": 1689825885000,
        "value": 139.186,
        "_internal_originalTime": 1689825885000
    },
    {
        "time": 1689825887000,
        "value": 139.188,
        "_internal_originalTime": 1689825887000
    },
    {
        "time": 1689825888000,
        "value": 139.196,
        "_internal_originalTime": 1689825888000
    },
    {
        "time": 1689825889000,
        "value": 139.197,
        "_internal_originalTime": 1689825889000
    },
    {
        "time": 1689825891000,
        "value": 139.196,
        "_internal_originalTime": 1689825891000
    },
    {
        "time": 1689825895000,
        "value": 139.197,
        "_internal_originalTime": 1689825895000
    },
    {
        "time": 1689825897000,
        "value": 139.196,
        "_internal_originalTime": 1689825897000
    },
    {
        "time": 1689825898000,
        "value": 139.192,
        "_internal_originalTime": 1689825898000
    },
    {
        "time": 1689825899000,
        "value": 139.193,
        "_internal_originalTime": 1689825899000
    },
    {
        "time": 1689825900000,
        "value": 139.195,
        "_internal_originalTime": 1689825900000
    },
    {
        "time": 1689825901000,
        "value": 139.19,
        "_internal_originalTime": 1689825901000
    },
    {
        "time": 1689825902000,
        "value": 139.191,
        "_internal_originalTime": 1689825902000
    },
    {
        "time": 1689825906000,
        "value": 139.197,
        "_internal_originalTime": 1689825906000
    },
    {
        "time": 1689825907000,
        "value": 139.198,
        "_internal_originalTime": 1689825907000
    },
    {
        "time": 1689825908000,
        "value": 139.197,
        "_internal_originalTime": 1689825908000
    },
    {
        "time": 1689825911000,
        "value": 139.196,
        "_internal_originalTime": 1689825911000
    },
    {
        "time": 1689825913000,
        "value": 139.196,
        "_internal_originalTime": 1689825913000
    },
    {
        "time": 1689825914000,
        "value": 139.197,
        "_internal_originalTime": 1689825914000
    },
    {
        "time": 1689825916000,
        "value": 139.196,
        "_internal_originalTime": 1689825916000
    },
    {
        "time": 1689825917000,
        "value": 139.192,
        "_internal_originalTime": 1689825917000
    },
    {
        "time": 1689825918000,
        "value": 139.196,
        "_internal_originalTime": 1689825918000
    },
    {
        "time": 1689825921000,
        "value": 139.197,
        "_internal_originalTime": 1689825921000
    },
    {
        "time": 1689825924000,
        "value": 139.195,
        "_internal_originalTime": 1689825924000
    },
    {
        "time": 1689825926000,
        "value": 139.193,
        "_internal_originalTime": 1689825926000
    },
    {
        "time": 1689825927000,
        "value": 139.194,
        "_internal_originalTime": 1689825927000
    },
    {
        "time": 1689825929000,
        "value": 139.199,
        "_internal_originalTime": 1689825929000
    },
    {
        "time": 1689825931000,
        "value": 139.193,
        "_internal_originalTime": 1689825931000
    },
    {
        "time": 1689825933000,
        "value": 139.193,
        "_internal_originalTime": 1689825933000
    },
    {
        "time": 1689825936000,
        "value": 139.199,
        "_internal_originalTime": 1689825936000
    },
    {
        "time": 1689825937000,
        "value": 139.198,
        "_internal_originalTime": 1689825937000
    },
    {
        "time": 1689825939000,
        "value": 139.196,
        "_internal_originalTime": 1689825939000
    },
    {
        "time": 1689825946000,
        "value": 139.195,
        "_internal_originalTime": 1689825946000
    },
    {
        "time": 1689825947000,
        "value": 139.196,
        "_internal_originalTime": 1689825947000
    },
    {
        "time": 1689825950000,
        "value": 139.196,
        "_internal_originalTime": 1689825950000
    },
    {
        "time": 1689825951000,
        "value": 139.197,
        "_internal_originalTime": 1689825951000
    },
    {
        "time": 1689825953000,
        "value": 139.197,
        "_internal_originalTime": 1689825953000
    },
    {
        "time": 1689825954000,
        "value": 139.197,
        "_internal_originalTime": 1689825954000
    },
    {
        "time": 1689825959000,
        "value": 139.197,
        "_internal_originalTime": 1689825959000
    },
    {
        "time": 1689825960000,
        "value": 139.196,
        "_internal_originalTime": 1689825960000
    },
    {
        "time": 1689825961000,
        "value": 139.197,
        "_internal_originalTime": 1689825961000
    },
    {
        "time": 1689825963000,
        "value": 139.196,
        "_internal_originalTime": 1689825963000
    },
    {
        "time": 1689825964000,
        "value": 139.198,
        "_internal_originalTime": 1689825964000
    },
    {
        "time": 1689825966000,
        "value": 139.198,
        "_internal_originalTime": 1689825966000
    },
    {
        "time": 1689825967000,
        "value": 139.198,
        "_internal_originalTime": 1689825967000
    },
    {
        "time": 1689825971000,
        "value": 139.194,
        "_internal_originalTime": 1689825971000
    },
    {
        "time": 1689825973000,
        "value": 139.198,
        "_internal_originalTime": 1689825973000
    },
    {
        "time": 1689825975000,
        "value": 139.192,
        "_internal_originalTime": 1689825975000
    },
    {
        "time": 1689825977000,
        "value": 139.197,
        "_internal_originalTime": 1689825977000
    },
    {
        "time": 1689825978000,
        "value": 139.194,
        "_internal_originalTime": 1689825978000
    },
    {
        "time": 1689825983000,
        "value": 139.197,
        "_internal_originalTime": 1689825983000
    },
    {
        "time": 1689825986000,
        "value": 139.194,
        "_internal_originalTime": 1689825986000
    },
    {
        "time": 1689825987000,
        "value": 139.194,
        "_internal_originalTime": 1689825987000
    },
    {
        "time": 1689825989000,
        "value": 139.196,
        "_internal_originalTime": 1689825989000
    },
    {
        "time": 1689825990000,
        "value": 139.197,
        "_internal_originalTime": 1689825990000
    },
    {
        "time": 1689825991000,
        "value": 139.191,
        "_internal_originalTime": 1689825991000
    },
    {
        "time": 1689825995000,
        "value": 139.192,
        "_internal_originalTime": 1689825995000
    },
    {
        "time": 1689825997000,
        "value": 139.197,
        "_internal_originalTime": 1689825997000
    },
    {
        "time": 1689826000000,
        "value": 139.192,
        "_internal_originalTime": 1689826000000
    },
    {
        "time": 1689826002000,
        "value": 139.193,
        "_internal_originalTime": 1689826002000
    },
    {
        "time": 1689826003000,
        "value": 139.2,
        "_internal_originalTime": 1689826003000
    },
    {
        "time": 1689826005000,
        "value": 139.202,
        "_internal_originalTime": 1689826005000
    },
    {
        "time": 1689826007000,
        "value": 139.202,
        "_internal_originalTime": 1689826007000
    },
    {
        "time": 1689826009000,
        "value": 139.206,
        "_internal_originalTime": 1689826009000
    },
    {
        "time": 1689826010000,
        "value": 139.206,
        "_internal_originalTime": 1689826010000
    },
    {
        "time": 1689826012000,
        "value": 139.207,
        "_internal_originalTime": 1689826012000
    },
    {
        "time": 1689826013000,
        "value": 139.207,
        "_internal_originalTime": 1689826013000
    },
    {
        "time": 1689826017000,
        "value": 139.206,
        "_internal_originalTime": 1689826017000
    },
    {
        "time": 1689826018000,
        "value": 139.208,
        "_internal_originalTime": 1689826018000
    },
    {
        "time": 1689826021000,
        "value": 139.201,
        "_internal_originalTime": 1689826021000
    },
    {
        "time": 1689826023000,
        "value": 139.207,
        "_internal_originalTime": 1689826023000
    },
    {
        "time": 1689826027000,
        "value": 139.207,
        "_internal_originalTime": 1689826027000
    },
    {
        "time": 1689826028000,
        "value": 139.202,
        "_internal_originalTime": 1689826028000
    },
    {
        "time": 1689826029000,
        "value": 139.205,
        "_internal_originalTime": 1689826029000
    },
    {
        "time": 1689826034000,
        "value": 139.202,
        "_internal_originalTime": 1689826034000
    },
    {
        "time": 1689826040000,
        "value": 139.202,
        "_internal_originalTime": 1689826040000
    },
    {
        "time": 1689826042000,
        "value": 139.206,
        "_internal_originalTime": 1689826042000
    },
    {
        "time": 1689826047000,
        "value": 139.203,
        "_internal_originalTime": 1689826047000
    },
    {
        "time": 1689826048000,
        "value": 139.206,
        "_internal_originalTime": 1689826048000
    },
    {
        "time": 1689826049000,
        "value": 139.205,
        "_internal_originalTime": 1689826049000
    },
    {
        "time": 1689826050000,
        "value": 139.206,
        "_internal_originalTime": 1689826050000
    },
    {
        "time": 1689826051000,
        "value": 139.204,
        "_internal_originalTime": 1689826051000
    },
    {
        "time": 1689826054000,
        "value": 139.206,
        "_internal_originalTime": 1689826054000
    },
    {
        "time": 1689826055000,
        "value": 139.204,
        "_internal_originalTime": 1689826055000
    },
    {
        "time": 1689826056000,
        "value": 139.204,
        "_internal_originalTime": 1689826056000
    },
    {
        "time": 1689826057000,
        "value": 139.203,
        "_internal_originalTime": 1689826057000
    },
    {
        "time": 1689826060000,
        "value": 139.217,
        "_internal_originalTime": 1689826060000
    },
    {
        "time": 1689826061000,
        "value": 139.217,
        "_internal_originalTime": 1689826061000
    },
    {
        "time": 1689826063000,
        "value": 139.228,
        "_internal_originalTime": 1689826063000
    },
    {
        "time": 1689826064000,
        "value": 139.226,
        "_internal_originalTime": 1689826064000
    },
    {
        "time": 1689826065000,
        "value": 139.227,
        "_internal_originalTime": 1689826065000
    },
    {
        "time": 1689826067000,
        "value": 139.225,
        "_internal_originalTime": 1689826067000
    },
    {
        "time": 1689826068000,
        "value": 139.226,
        "_internal_originalTime": 1689826068000
    },
    {
        "time": 1689826070000,
        "value": 139.226,
        "_internal_originalTime": 1689826070000
    },
    {
        "time": 1689826071000,
        "value": 139.222,
        "_internal_originalTime": 1689826071000
    },
    {
        "time": 1689826073000,
        "value": 139.225,
        "_internal_originalTime": 1689826073000
    },
    {
        "time": 1689826076000,
        "value": 139.223,
        "_internal_originalTime": 1689826076000
    },
    {
        "time": 1689826078000,
        "value": 139.221,
        "_internal_originalTime": 1689826078000
    },
    {
        "time": 1689826079000,
        "value": 139.222,
        "_internal_originalTime": 1689826079000
    },
    {
        "time": 1689826080000,
        "value": 139.221,
        "_internal_originalTime": 1689826080000
    },
    {
        "time": 1689826082000,
        "value": 139.22,
        "_internal_originalTime": 1689826082000
    },
    {
        "time": 1689826084000,
        "value": 139.224,
        "_internal_originalTime": 1689826084000
    },
    {
        "time": 1689826086000,
        "value": 139.224,
        "_internal_originalTime": 1689826086000
    },
    {
        "time": 1689826090000,
        "value": 139.224,
        "_internal_originalTime": 1689826090000
    },
    {
        "time": 1689826094000,
        "value": 139.227,
        "_internal_originalTime": 1689826094000
    },
    {
        "time": 1689826096000,
        "value": 139.229,
        "_internal_originalTime": 1689826096000
    },
    {
        "time": 1689826100000,
        "value": 139.229,
        "_internal_originalTime": 1689826100000
    },
    {
        "time": 1689826101000,
        "value": 139.228,
        "_internal_originalTime": 1689826101000
    },
    {
        "time": 1689826103000,
        "value": 139.229,
        "_internal_originalTime": 1689826103000
    },
    {
        "time": 1689826106000,
        "value": 139.227,
        "_internal_originalTime": 1689826106000
    },
    {
        "time": 1689826107000,
        "value": 139.227,
        "_internal_originalTime": 1689826107000
    },
    {
        "time": 1689826110000,
        "value": 139.227,
        "_internal_originalTime": 1689826110000
    },
    {
        "time": 1689826111000,
        "value": 139.22,
        "_internal_originalTime": 1689826111000
    },
    {
        "time": 1689826112000,
        "value": 139.227,
        "_internal_originalTime": 1689826112000
    },
    {
        "time": 1689826119000,
        "value": 139.222,
        "_internal_originalTime": 1689826119000
    },
    {
        "time": 1689826122000,
        "value": 139.227,
        "_internal_originalTime": 1689826122000
    },
    {
        "time": 1689826124000,
        "value": 139.226,
        "_internal_originalTime": 1689826124000
    },
    {
        "time": 1689826127000,
        "value": 139.226,
        "_internal_originalTime": 1689826127000
    },
    {
        "time": 1689826128000,
        "value": 139.225,
        "_internal_originalTime": 1689826128000
    },
    {
        "time": 1689826129000,
        "value": 139.226,
        "_internal_originalTime": 1689826129000
    },
    {
        "time": 1689826134000,
        "value": 139.228,
        "_internal_originalTime": 1689826134000
    },
    {
        "time": 1689826135000,
        "value": 139.229,
        "_internal_originalTime": 1689826135000
    },
    {
        "time": 1689826136000,
        "value": 139.228,
        "_internal_originalTime": 1689826136000
    },
    {
        "time": 1689826138000,
        "value": 139.228,
        "_internal_originalTime": 1689826138000
    },
    {
        "time": 1689826139000,
        "value": 139.228,
        "_internal_originalTime": 1689826139000
    },
    {
        "time": 1689826141000,
        "value": 139.228,
        "_internal_originalTime": 1689826141000
    },
    {
        "time": 1689826142000,
        "value": 139.225,
        "_internal_originalTime": 1689826142000
    },
    {
        "time": 1689826143000,
        "value": 139.225,
        "_internal_originalTime": 1689826143000
    },
    {
        "time": 1689826144000,
        "value": 139.221,
        "_internal_originalTime": 1689826144000
    },
    {
        "time": 1689826146000,
        "value": 139.22,
        "_internal_originalTime": 1689826146000
    },
    {
        "time": 1689826150000,
        "value": 139.22,
        "_internal_originalTime": 1689826150000
    },
    {
        "time": 1689826151000,
        "value": 139.225,
        "_internal_originalTime": 1689826151000
    },
    {
        "time": 1689826157000,
        "value": 139.226,
        "_internal_originalTime": 1689826157000
    },
    {
        "time": 1689826158000,
        "value": 139.221,
        "_internal_originalTime": 1689826158000
    },
    {
        "time": 1689826159000,
        "value": 139.225,
        "_internal_originalTime": 1689826159000
    },
    {
        "time": 1689826161000,
        "value": 139.22,
        "_internal_originalTime": 1689826161000
    },
    {
        "time": 1689826163000,
        "value": 139.225,
        "_internal_originalTime": 1689826163000
    },
    {
        "time": 1689826164000,
        "value": 139.225,
        "_internal_originalTime": 1689826164000
    },
    {
        "time": 1689826166000,
        "value": 139.222,
        "_internal_originalTime": 1689826166000
    },
    {
        "time": 1689826168000,
        "value": 139.221,
        "_internal_originalTime": 1689826168000
    },
    {
        "time": 1689826170000,
        "value": 139.215,
        "_internal_originalTime": 1689826170000
    },
    {
        "time": 1689826171000,
        "value": 139.219,
        "_internal_originalTime": 1689826171000
    },
    {
        "time": 1689826172000,
        "value": 139.218,
        "_internal_originalTime": 1689826172000
    },
    {
        "time": 1689826175000,
        "value": 139.214,
        "_internal_originalTime": 1689826175000
    },
    {
        "time": 1689826178000,
        "value": 139.218,
        "_internal_originalTime": 1689826178000
    },
    {
        "time": 1689826180000,
        "value": 139.225,
        "_internal_originalTime": 1689826180000
    },
    {
        "time": 1689826181000,
        "value": 139.22,
        "_internal_originalTime": 1689826181000
    },
    {
        "time": 1689826183000,
        "value": 139.22,
        "_internal_originalTime": 1689826183000
    },
    {
        "time": 1689826185000,
        "value": 139.225,
        "_internal_originalTime": 1689826185000
    },
    {
        "time": 1689826188000,
        "value": 139.224,
        "_internal_originalTime": 1689826188000
    },
    {
        "time": 1689826189000,
        "value": 139.22,
        "_internal_originalTime": 1689826189000
    },
    {
        "time": 1689826192000,
        "value": 139.22,
        "_internal_originalTime": 1689826192000
    },
    {
        "time": 1689826193000,
        "value": 139.225,
        "_internal_originalTime": 1689826193000
    },
    {
        "time": 1689826195000,
        "value": 139.224,
        "_internal_originalTime": 1689826195000
    },
    {
        "time": 1689826200000,
        "value": 139.223,
        "_internal_originalTime": 1689826200000
    },
    {
        "time": 1689826201000,
        "value": 139.219,
        "_internal_originalTime": 1689826201000
    },
    {
        "time": 1689826202000,
        "value": 139.224,
        "_internal_originalTime": 1689826202000
    },
    {
        "time": 1689826204000,
        "value": 139.218,
        "_internal_originalTime": 1689826204000
    },
    {
        "time": 1689826205000,
        "value": 139.224,
        "_internal_originalTime": 1689826205000
    },
    {
        "time": 1689826207000,
        "value": 139.214,
        "_internal_originalTime": 1689826207000
    },
    {
        "time": 1689826209000,
        "value": 139.215,
        "_internal_originalTime": 1689826209000
    },
    {
        "time": 1689826211000,
        "value": 139.216,
        "_internal_originalTime": 1689826211000
    },
    {
        "time": 1689826213000,
        "value": 139.218,
        "_internal_originalTime": 1689826213000
    },
    {
        "time": 1689826214000,
        "value": 139.22,
        "_internal_originalTime": 1689826214000
    },
    {
        "time": 1689826216000,
        "value": 139.217,
        "_internal_originalTime": 1689826216000
    },
    {
        "time": 1689826218000,
        "value": 139.218,
        "_internal_originalTime": 1689826218000
    },
    {
        "time": 1689826219000,
        "value": 139.22,
        "_internal_originalTime": 1689826219000
    },
    {
        "time": 1689826222000,
        "value": 139.218,
        "_internal_originalTime": 1689826222000
    },
    {
        "time": 1689826223000,
        "value": 139.223,
        "_internal_originalTime": 1689826223000
    },
    {
        "time": 1689826225000,
        "value": 139.223,
        "_internal_originalTime": 1689826225000
    },
    {
        "time": 1689826226000,
        "value": 139.22,
        "_internal_originalTime": 1689826226000
    },
    {
        "time": 1689826227000,
        "value": 139.22,
        "_internal_originalTime": 1689826227000
    },
    {
        "time": 1689826230000,
        "value": 139.224,
        "_internal_originalTime": 1689826230000
    },
    {
        "time": 1689826231000,
        "value": 139.217,
        "_internal_originalTime": 1689826231000
    },
    {
        "time": 1689826233000,
        "value": 139.222,
        "_internal_originalTime": 1689826233000
    },
    {
        "time": 1689826236000,
        "value": 139.219,
        "_internal_originalTime": 1689826236000
    },
    {
        "time": 1689826240000,
        "value": 139.218,
        "_internal_originalTime": 1689826240000
    },
    {
        "time": 1689826241000,
        "value": 139.217,
        "_internal_originalTime": 1689826241000
    },
    {
        "time": 1689826243000,
        "value": 139.219,
        "_internal_originalTime": 1689826243000
    },
    {
        "time": 1689826244000,
        "value": 139.218,
        "_internal_originalTime": 1689826244000
    },
    {
        "time": 1689826245000,
        "value": 139.218,
        "_internal_originalTime": 1689826245000
    },
    {
        "time": 1689826247000,
        "value": 139.219,
        "_internal_originalTime": 1689826247000
    },
    {
        "time": 1689826249000,
        "value": 139.22,
        "_internal_originalTime": 1689826249000
    },
    {
        "time": 1689826253000,
        "value": 139.219,
        "_internal_originalTime": 1689826253000
    },
    {
        "time": 1689826255000,
        "value": 139.223,
        "_internal_originalTime": 1689826255000
    },
    {
        "time": 1689826258000,
        "value": 139.218,
        "_internal_originalTime": 1689826258000
    },
    {
        "time": 1689826259000,
        "value": 139.221,
        "_internal_originalTime": 1689826259000
    },
    {
        "time": 1689826262000,
        "value": 139.219,
        "_internal_originalTime": 1689826262000
    },
    {
        "time": 1689826264000,
        "value": 139.219,
        "_internal_originalTime": 1689826264000
    },
    {
        "time": 1689826266000,
        "value": 139.219,
        "_internal_originalTime": 1689826266000
    },
    {
        "time": 1689826268000,
        "value": 139.22,
        "_internal_originalTime": 1689826268000
    },
    {
        "time": 1689826270000,
        "value": 139.218,
        "_internal_originalTime": 1689826270000
    },
    {
        "time": 1689826272000,
        "value": 139.219,
        "_internal_originalTime": 1689826272000
    },
    {
        "time": 1689826273000,
        "value": 139.219,
        "_internal_originalTime": 1689826273000
    },
    {
        "time": 1689826274000,
        "value": 139.219,
        "_internal_originalTime": 1689826274000
    },
    {
        "time": 1689826287000,
        "value": 139.219,
        "_internal_originalTime": 1689826287000
    },
    {
        "time": 1689826288000,
        "value": 139.219,
        "_internal_originalTime": 1689826288000
    },
    {
        "time": 1689826289000,
        "value": 139.22,
        "_internal_originalTime": 1689826289000
    },
    {
        "time": 1689826291000,
        "value": 139.217,
        "_internal_originalTime": 1689826291000
    },
    {
        "time": 1689826292000,
        "value": 139.22,
        "_internal_originalTime": 1689826292000
    },
    {
        "time": 1689826294000,
        "value": 139.219,
        "_internal_originalTime": 1689826294000
    },
    {
        "time": 1689826296000,
        "value": 139.22,
        "_internal_originalTime": 1689826296000
    },
    {
        "time": 1689826299000,
        "value": 139.224,
        "_internal_originalTime": 1689826299000
    },
    {
        "time": 1689826301000,
        "value": 139.22,
        "_internal_originalTime": 1689826301000
    },
    {
        "time": 1689826303000,
        "value": 139.233,
        "_internal_originalTime": 1689826303000
    },
    {
        "time": 1689826305000,
        "value": 139.228,
        "_internal_originalTime": 1689826305000
    },
    {
        "time": 1689826306000,
        "value": 139.229,
        "_internal_originalTime": 1689826306000
    },
    {
        "time": 1689826308000,
        "value": 139.23,
        "_internal_originalTime": 1689826308000
    },
    {
        "time": 1689826309000,
        "value": 139.229,
        "_internal_originalTime": 1689826309000
    },
    {
        "time": 1689826310000,
        "value": 139.23,
        "_internal_originalTime": 1689826310000
    },
    {
        "time": 1689826312000,
        "value": 139.229,
        "_internal_originalTime": 1689826312000
    },
    {
        "time": 1689826321000,
        "value": 139.227,
        "_internal_originalTime": 1689826321000
    },
    {
        "time": 1689826324000,
        "value": 139.232,
        "_internal_originalTime": 1689826324000
    },
    {
        "time": 1689826326000,
        "value": 139.228,
        "_internal_originalTime": 1689826326000
    },
    {
        "time": 1689826327000,
        "value": 139.228,
        "_internal_originalTime": 1689826327000
    },
    {
        "time": 1689826329000,
        "value": 139.223,
        "_internal_originalTime": 1689826329000
    },
    {
        "time": 1689826330000,
        "value": 139.223,
        "_internal_originalTime": 1689826330000
    },
    {
        "time": 1689826332000,
        "value": 139.223,
        "_internal_originalTime": 1689826332000
    },
    {
        "time": 1689826336000,
        "value": 139.222,
        "_internal_originalTime": 1689826336000
    },
    {
        "time": 1689826338000,
        "value": 139.226,
        "_internal_originalTime": 1689826338000
    },
    {
        "time": 1689826341000,
        "value": 139.222,
        "_internal_originalTime": 1689826341000
    },
    {
        "time": 1689826342000,
        "value": 139.223,
        "_internal_originalTime": 1689826342000
    },
    {
        "time": 1689826345000,
        "value": 139.233,
        "_internal_originalTime": 1689826345000
    },
    {
        "time": 1689826349000,
        "value": 139.23,
        "_internal_originalTime": 1689826349000
    },
    {
        "time": 1689826353000,
        "value": 139.23,
        "_internal_originalTime": 1689826353000
    },
    {
        "time": 1689826355000,
        "value": 139.23,
        "_internal_originalTime": 1689826355000
    },
    {
        "time": 1689826359000,
        "value": 139.231,
        "_internal_originalTime": 1689826359000
    },
    {
        "time": 1689826361000,
        "value": 139.233,
        "_internal_originalTime": 1689826361000
    },
    {
        "time": 1689826363000,
        "value": 139.23,
        "_internal_originalTime": 1689826363000
    },
    {
        "time": 1689826366000,
        "value": 139.228,
        "_internal_originalTime": 1689826366000
    },
    {
        "time": 1689826367000,
        "value": 139.228,
        "_internal_originalTime": 1689826367000
    },
    {
        "time": 1689826368000,
        "value": 139.228,
        "_internal_originalTime": 1689826368000
    },
    {
        "time": 1689826370000,
        "value": 139.23,
        "_internal_originalTime": 1689826370000
    },
    {
        "time": 1689826375000,
        "value": 139.23,
        "_internal_originalTime": 1689826375000
    },
    {
        "time": 1689826381000,
        "value": 139.227,
        "_internal_originalTime": 1689826381000
    },
    {
        "time": 1689826383000,
        "value": 139.233,
        "_internal_originalTime": 1689826383000
    },
    {
        "time": 1689826384000,
        "value": 139.228,
        "_internal_originalTime": 1689826384000
    },
    {
        "time": 1689826385000,
        "value": 139.229,
        "_internal_originalTime": 1689826385000
    },
    {
        "time": 1689826386000,
        "value": 139.23,
        "_internal_originalTime": 1689826386000
    },
    {
        "time": 1689826389000,
        "value": 139.23,
        "_internal_originalTime": 1689826389000
    },
    {
        "time": 1689826391000,
        "value": 139.23,
        "_internal_originalTime": 1689826391000
    },
    {
        "time": 1689826393000,
        "value": 139.229,
        "_internal_originalTime": 1689826393000
    },
    {
        "time": 1689826394000,
        "value": 139.23,
        "_internal_originalTime": 1689826394000
    },
    {
        "time": 1689826398000,
        "value": 139.228,
        "_internal_originalTime": 1689826398000
    },
    {
        "time": 1689826400000,
        "value": 139.228,
        "_internal_originalTime": 1689826400000
    },
    {
        "time": 1689826401000,
        "value": 139.234,
        "_internal_originalTime": 1689826401000
    },
    {
        "time": 1689826404000,
        "value": 139.23,
        "_internal_originalTime": 1689826404000
    },
    {
        "time": 1689826406000,
        "value": 139.228,
        "_internal_originalTime": 1689826406000
    },
    {
        "time": 1689826411000,
        "value": 139.23,
        "_internal_originalTime": 1689826411000
    },
    {
        "time": 1689826413000,
        "value": 139.229,
        "_internal_originalTime": 1689826413000
    },
    {
        "time": 1689826415000,
        "value": 139.23,
        "_internal_originalTime": 1689826415000
    },
    {
        "time": 1689826416000,
        "value": 139.229,
        "_internal_originalTime": 1689826416000
    },
    {
        "time": 1689826419000,
        "value": 139.23,
        "_internal_originalTime": 1689826419000
    },
    {
        "time": 1689826421000,
        "value": 139.23,
        "_internal_originalTime": 1689826421000
    },
    {
        "time": 1689826422000,
        "value": 139.228,
        "_internal_originalTime": 1689826422000
    },
    {
        "time": 1689826423000,
        "value": 139.233,
        "_internal_originalTime": 1689826423000
    },
    {
        "time": 1689826426000,
        "value": 139.23,
        "_internal_originalTime": 1689826426000
    },
    {
        "time": 1689826429000,
        "value": 139.228,
        "_internal_originalTime": 1689826429000
    },
    {
        "time": 1689826432000,
        "value": 139.228,
        "_internal_originalTime": 1689826432000
    },
    {
        "time": 1689826435000,
        "value": 139.228,
        "_internal_originalTime": 1689826435000
    },
    {
        "time": 1689826438000,
        "value": 139.23,
        "_internal_originalTime": 1689826438000
    },
    {
        "time": 1689826440000,
        "value": 139.23,
        "_internal_originalTime": 1689826440000
    },
    {
        "time": 1689826442000,
        "value": 139.23,
        "_internal_originalTime": 1689826442000
    },
    {
        "time": 1689826443000,
        "value": 139.23,
        "_internal_originalTime": 1689826443000
    },
    {
        "time": 1689826444000,
        "value": 139.235,
        "_internal_originalTime": 1689826444000
    },
    {
        "time": 1689826446000,
        "value": 139.234,
        "_internal_originalTime": 1689826446000
    },
    {
        "time": 1689826449000,
        "value": 139.231,
        "_internal_originalTime": 1689826449000
    },
    {
        "time": 1689826450000,
        "value": 139.233,
        "_internal_originalTime": 1689826450000
    },
    {
        "time": 1689826452000,
        "value": 139.228,
        "_internal_originalTime": 1689826452000
    },
    {
        "time": 1689826455000,
        "value": 139.229,
        "_internal_originalTime": 1689826455000
    },
    {
        "time": 1689826457000,
        "value": 139.235,
        "_internal_originalTime": 1689826457000
    },
    {
        "time": 1689826460000,
        "value": 139.245,
        "_internal_originalTime": 1689826460000
    },
    {
        "time": 1689826461000,
        "value": 139.245,
        "_internal_originalTime": 1689826461000
    },
    {
        "time": 1689826462000,
        "value": 139.237,
        "_internal_originalTime": 1689826462000
    },
    {
        "time": 1689826463000,
        "value": 139.243,
        "_internal_originalTime": 1689826463000
    },
    {
        "time": 1689826468000,
        "value": 139.238,
        "_internal_originalTime": 1689826468000
    },
    {
        "time": 1689826471000,
        "value": 139.236,
        "_internal_originalTime": 1689826471000
    },
    {
        "time": 1689826473000,
        "value": 139.233,
        "_internal_originalTime": 1689826473000
    },
    {
        "time": 1689826474000,
        "value": 139.226,
        "_internal_originalTime": 1689826474000
    },
    {
        "time": 1689826476000,
        "value": 139.223,
        "_internal_originalTime": 1689826476000
    },
    {
        "time": 1689826478000,
        "value": 139.222,
        "_internal_originalTime": 1689826478000
    },
    {
        "time": 1689826480000,
        "value": 139.223,
        "_internal_originalTime": 1689826480000
    },
    {
        "time": 1689826482000,
        "value": 139.222,
        "_internal_originalTime": 1689826482000
    },
    {
        "time": 1689826484000,
        "value": 139.222,
        "_internal_originalTime": 1689826484000
    },
    {
        "time": 1689826486000,
        "value": 139.222,
        "_internal_originalTime": 1689826486000
    },
    {
        "time": 1689826487000,
        "value": 139.223,
        "_internal_originalTime": 1689826487000
    },
    {
        "time": 1689826490000,
        "value": 139.223,
        "_internal_originalTime": 1689826490000
    },
    {
        "time": 1689826491000,
        "value": 139.222,
        "_internal_originalTime": 1689826491000
    },
    {
        "time": 1689826495000,
        "value": 139.222,
        "_internal_originalTime": 1689826495000
    },
    {
        "time": 1689826498000,
        "value": 139.227,
        "_internal_originalTime": 1689826498000
    },
    {
        "time": 1689826501000,
        "value": 139.222,
        "_internal_originalTime": 1689826501000
    },
    {
        "time": 1689826506000,
        "value": 139.225,
        "_internal_originalTime": 1689826506000
    },
    {
        "time": 1689826508000,
        "value": 139.233,
        "_internal_originalTime": 1689826508000
    },
    {
        "time": 1689826509000,
        "value": 139.229,
        "_internal_originalTime": 1689826509000
    },
    {
        "time": 1689826512000,
        "value": 139.224,
        "_internal_originalTime": 1689826512000
    },
    {
        "time": 1689826513000,
        "value": 139.229,
        "_internal_originalTime": 1689826513000
    },
    {
        "time": 1689826514000,
        "value": 139.228,
        "_internal_originalTime": 1689826514000
    },
    {
        "time": 1689826516000,
        "value": 139.228,
        "_internal_originalTime": 1689826516000
    },
    {
        "time": 1689826519000,
        "value": 139.228,
        "_internal_originalTime": 1689826519000
    },
    {
        "time": 1689826521000,
        "value": 139.228,
        "_internal_originalTime": 1689826521000
    },
    {
        "time": 1689826523000,
        "value": 139.228,
        "_internal_originalTime": 1689826523000
    },
    {
        "time": 1689826528000,
        "value": 139.229,
        "_internal_originalTime": 1689826528000
    },
    {
        "time": 1689826531000,
        "value": 139.226,
        "_internal_originalTime": 1689826531000
    },
    {
        "time": 1689826533000,
        "value": 139.227,
        "_internal_originalTime": 1689826533000
    },
    {
        "time": 1689826539000,
        "value": 139.225,
        "_internal_originalTime": 1689826539000
    },
    {
        "time": 1689826543000,
        "value": 139.222,
        "_internal_originalTime": 1689826543000
    },
    {
        "time": 1689826545000,
        "value": 139.222,
        "_internal_originalTime": 1689826545000
    },
    {
        "time": 1689826547000,
        "value": 139.221,
        "_internal_originalTime": 1689826547000
    },
    {
        "time": 1689826548000,
        "value": 139.223,
        "_internal_originalTime": 1689826548000
    },
    {
        "time": 1689826551000,
        "value": 139.22,
        "_internal_originalTime": 1689826551000
    },
    {
        "time": 1689826553000,
        "value": 139.224,
        "_internal_originalTime": 1689826553000
    },
    {
        "time": 1689826556000,
        "value": 139.221,
        "_internal_originalTime": 1689826556000
    },
    {
        "time": 1689826559000,
        "value": 139.22,
        "_internal_originalTime": 1689826559000
    },
    {
        "time": 1689826560000,
        "value": 139.226,
        "_internal_originalTime": 1689826560000
    },
    {
        "time": 1689826562000,
        "value": 139.222,
        "_internal_originalTime": 1689826562000
    },
    {
        "time": 1689826565000,
        "value": 139.225,
        "_internal_originalTime": 1689826565000
    },
    {
        "time": 1689826566000,
        "value": 139.226,
        "_internal_originalTime": 1689826566000
    },
    {
        "time": 1689826568000,
        "value": 139.226,
        "_internal_originalTime": 1689826568000
    },
    {
        "time": 1689826569000,
        "value": 139.226,
        "_internal_originalTime": 1689826569000
    },
    {
        "time": 1689826571000,
        "value": 139.222,
        "_internal_originalTime": 1689826571000
    },
    {
        "time": 1689826578000,
        "value": 139.223,
        "_internal_originalTime": 1689826578000
    },
    {
        "time": 1689826580000,
        "value": 139.226,
        "_internal_originalTime": 1689826580000
    },
    {
        "time": 1689826583000,
        "value": 139.226,
        "_internal_originalTime": 1689826583000
    },
    {
        "time": 1689826584000,
        "value": 139.221,
        "_internal_originalTime": 1689826584000
    },
    {
        "time": 1689826587000,
        "value": 139.222,
        "_internal_originalTime": 1689826587000
    },
    {
        "time": 1689826589000,
        "value": 139.224,
        "_internal_originalTime": 1689826589000
    },
    {
        "time": 1689826591000,
        "value": 139.22,
        "_internal_originalTime": 1689826591000
    },
    {
        "time": 1689826594000,
        "value": 139.224,
        "_internal_originalTime": 1689826594000
    },
    {
        "time": 1689826596000,
        "value": 139.221,
        "_internal_originalTime": 1689826596000
    },
    {
        "time": 1689826597000,
        "value": 139.224,
        "_internal_originalTime": 1689826597000
    },
    {
        "time": 1689826600000,
        "value": 139.223,
        "_internal_originalTime": 1689826600000
    },
    {
        "time": 1689826601000,
        "value": 139.226,
        "_internal_originalTime": 1689826601000
    },
    {
        "time": 1689826602000,
        "value": 139.223,
        "_internal_originalTime": 1689826602000
    },
    {
        "time": 1689826603000,
        "value": 139.222,
        "_internal_originalTime": 1689826603000
    },
    {
        "time": 1689826605000,
        "value": 139.226,
        "_internal_originalTime": 1689826605000
    },
    {
        "time": 1689826607000,
        "value": 139.226,
        "_internal_originalTime": 1689826607000
    },
    {
        "time": 1689826610000,
        "value": 139.222,
        "_internal_originalTime": 1689826610000
    },
    {
        "time": 1689826611000,
        "value": 139.222,
        "_internal_originalTime": 1689826611000
    },
    {
        "time": 1689826613000,
        "value": 139.233,
        "_internal_originalTime": 1689826613000
    },
    {
        "time": 1689826614000,
        "value": 139.23,
        "_internal_originalTime": 1689826614000
    },
    {
        "time": 1689826617000,
        "value": 139.245,
        "_internal_originalTime": 1689826617000
    },
    {
        "time": 1689826618000,
        "value": 139.245,
        "_internal_originalTime": 1689826618000
    },
    {
        "time": 1689826619000,
        "value": 139.247,
        "_internal_originalTime": 1689826619000
    },
    {
        "time": 1689826622000,
        "value": 139.244,
        "_internal_originalTime": 1689826622000
    },
    {
        "time": 1689826624000,
        "value": 139.246,
        "_internal_originalTime": 1689826624000
    },
    {
        "time": 1689826626000,
        "value": 139.254,
        "_internal_originalTime": 1689826626000
    },
    {
        "time": 1689826627000,
        "value": 139.255,
        "_internal_originalTime": 1689826627000
    },
    {
        "time": 1689826629000,
        "value": 139.252,
        "_internal_originalTime": 1689826629000
    },
    {
        "time": 1689826631000,
        "value": 139.252,
        "_internal_originalTime": 1689826631000
    },
    {
        "time": 1689826632000,
        "value": 139.254,
        "_internal_originalTime": 1689826632000
    },
    {
        "time": 1689826633000,
        "value": 139.254,
        "_internal_originalTime": 1689826633000
    },
    {
        "time": 1689826637000,
        "value": 139.255,
        "_internal_originalTime": 1689826637000
    },
    {
        "time": 1689826638000,
        "value": 139.248,
        "_internal_originalTime": 1689826638000
    },
    {
        "time": 1689826640000,
        "value": 139.249,
        "_internal_originalTime": 1689826640000
    },
    {
        "time": 1689826641000,
        "value": 139.252,
        "_internal_originalTime": 1689826641000
    },
    {
        "time": 1689826643000,
        "value": 139.244,
        "_internal_originalTime": 1689826643000
    },
    {
        "time": 1689826648000,
        "value": 139.245,
        "_internal_originalTime": 1689826648000
    },
    {
        "time": 1689826649000,
        "value": 139.245,
        "_internal_originalTime": 1689826649000
    },
    {
        "time": 1689826650000,
        "value": 139.245,
        "_internal_originalTime": 1689826650000
    },
    {
        "time": 1689826652000,
        "value": 139.24,
        "_internal_originalTime": 1689826652000
    },
    {
        "time": 1689826654000,
        "value": 139.249,
        "_internal_originalTime": 1689826654000
    },
    {
        "time": 1689826657000,
        "value": 139.246,
        "_internal_originalTime": 1689826657000
    },
    {
        "time": 1689826659000,
        "value": 139.246,
        "_internal_originalTime": 1689826659000
    },
    {
        "time": 1689826660000,
        "value": 139.252,
        "_internal_originalTime": 1689826660000
    },
    {
        "time": 1689826662000,
        "value": 139.252,
        "_internal_originalTime": 1689826662000
    },
    {
        "time": 1689826664000,
        "value": 139.247,
        "_internal_originalTime": 1689826664000
    },
    {
        "time": 1689826665000,
        "value": 139.246,
        "_internal_originalTime": 1689826665000
    },
    {
        "time": 1689826667000,
        "value": 139.249,
        "_internal_originalTime": 1689826667000
    },
    {
        "time": 1689826668000,
        "value": 139.252,
        "_internal_originalTime": 1689826668000
    },
    {
        "time": 1689826670000,
        "value": 139.249,
        "_internal_originalTime": 1689826670000
    },
    {
        "time": 1689826672000,
        "value": 139.248,
        "_internal_originalTime": 1689826672000
    },
    {
        "time": 1689826673000,
        "value": 139.253,
        "_internal_originalTime": 1689826673000
    },
    {
        "time": 1689826676000,
        "value": 139.264,
        "_internal_originalTime": 1689826676000
    },
    {
        "time": 1689826677000,
        "value": 139.264,
        "_internal_originalTime": 1689826677000
    },
    {
        "time": 1689826679000,
        "value": 139.264,
        "_internal_originalTime": 1689826679000
    },
    {
        "time": 1689826680000,
        "value": 139.266,
        "_internal_originalTime": 1689826680000
    },
    {
        "time": 1689826682000,
        "value": 139.263,
        "_internal_originalTime": 1689826682000
    },
    {
        "time": 1689826683000,
        "value": 139.262,
        "_internal_originalTime": 1689826683000
    },
    {
        "time": 1689826686000,
        "value": 139.26,
        "_internal_originalTime": 1689826686000
    },
    {
        "time": 1689826690000,
        "value": 139.263,
        "_internal_originalTime": 1689826690000
    },
    {
        "time": 1689826691000,
        "value": 139.26,
        "_internal_originalTime": 1689826691000
    },
    {
        "time": 1689826696000,
        "value": 139.26,
        "_internal_originalTime": 1689826696000
    },
    {
        "time": 1689826697000,
        "value": 139.26,
        "_internal_originalTime": 1689826697000
    },
    {
        "time": 1689826698000,
        "value": 139.261,
        "_internal_originalTime": 1689826698000
    },
    {
        "time": 1689826701000,
        "value": 139.281,
        "_internal_originalTime": 1689826701000
    },
    {
        "time": 1689826702000,
        "value": 139.278,
        "_internal_originalTime": 1689826702000
    },
    {
        "time": 1689826704000,
        "value": 139.281,
        "_internal_originalTime": 1689826704000
    },
    {
        "time": 1689826706000,
        "value": 139.277,
        "_internal_originalTime": 1689826706000
    },
    {
        "time": 1689826708000,
        "value": 139.276,
        "_internal_originalTime": 1689826708000
    },
    {
        "time": 1689826709000,
        "value": 139.278,
        "_internal_originalTime": 1689826709000
    },
    {
        "time": 1689826712000,
        "value": 139.273,
        "_internal_originalTime": 1689826712000
    },
    {
        "time": 1689826713000,
        "value": 139.272,
        "_internal_originalTime": 1689826713000
    },
    {
        "time": 1689826714000,
        "value": 139.272,
        "_internal_originalTime": 1689826714000
    },
    {
        "time": 1689826716000,
        "value": 139.27,
        "_internal_originalTime": 1689826716000
    },
    {
        "time": 1689826717000,
        "value": 139.273,
        "_internal_originalTime": 1689826717000
    },
    {
        "time": 1689826719000,
        "value": 139.271,
        "_internal_originalTime": 1689826719000
    },
    {
        "time": 1689826722000,
        "value": 139.271,
        "_internal_originalTime": 1689826722000
    },
    {
        "time": 1689826724000,
        "value": 139.27,
        "_internal_originalTime": 1689826724000
    },
    {
        "time": 1689826725000,
        "value": 139.268,
        "_internal_originalTime": 1689826725000
    },
    {
        "time": 1689826730000,
        "value": 139.268,
        "_internal_originalTime": 1689826730000
    },
    {
        "time": 1689826732000,
        "value": 139.268,
        "_internal_originalTime": 1689826732000
    },
    {
        "time": 1689826734000,
        "value": 139.268,
        "_internal_originalTime": 1689826734000
    },
    {
        "time": 1689826736000,
        "value": 139.279,
        "_internal_originalTime": 1689826736000
    },
    {
        "time": 1689826737000,
        "value": 139.277,
        "_internal_originalTime": 1689826737000
    },
    {
        "time": 1689826739000,
        "value": 139.276,
        "_internal_originalTime": 1689826739000
    },
    {
        "time": 1689826742000,
        "value": 139.281,
        "_internal_originalTime": 1689826742000
    },
    {
        "time": 1689826743000,
        "value": 139.277,
        "_internal_originalTime": 1689826743000
    },
    {
        "time": 1689826744000,
        "value": 139.278,
        "_internal_originalTime": 1689826744000
    },
    {
        "time": 1689826746000,
        "value": 139.278,
        "_internal_originalTime": 1689826746000
    },
    {
        "time": 1689826747000,
        "value": 139.281,
        "_internal_originalTime": 1689826747000
    },
    {
        "time": 1689826749000,
        "value": 139.281,
        "_internal_originalTime": 1689826749000
    },
    {
        "time": 1689826755000,
        "value": 139.281,
        "_internal_originalTime": 1689826755000
    },
    {
        "time": 1689826756000,
        "value": 139.281,
        "_internal_originalTime": 1689826756000
    },
    {
        "time": 1689826761000,
        "value": 139.279,
        "_internal_originalTime": 1689826761000
    },
    {
        "time": 1689826762000,
        "value": 139.281,
        "_internal_originalTime": 1689826762000
    },
    {
        "time": 1689826763000,
        "value": 139.277,
        "_internal_originalTime": 1689826763000
    },
    {
        "time": 1689826765000,
        "value": 139.279,
        "_internal_originalTime": 1689826765000
    },
    {
        "time": 1689826766000,
        "value": 139.277,
        "_internal_originalTime": 1689826766000
    },
    {
        "time": 1689826767000,
        "value": 139.278,
        "_internal_originalTime": 1689826767000
    },
    {
        "time": 1689826769000,
        "value": 139.277,
        "_internal_originalTime": 1689826769000
    },
    {
        "time": 1689826771000,
        "value": 139.282,
        "_internal_originalTime": 1689826771000
    },
    {
        "time": 1689826772000,
        "value": 139.276,
        "_internal_originalTime": 1689826772000
    },
    {
        "time": 1689826773000,
        "value": 139.277,
        "_internal_originalTime": 1689826773000
    },
    {
        "time": 1689826775000,
        "value": 139.289,
        "_internal_originalTime": 1689826775000
    },
    {
        "time": 1689826777000,
        "value": 139.292,
        "_internal_originalTime": 1689826777000
    },
    {
        "time": 1689826778000,
        "value": 139.292,
        "_internal_originalTime": 1689826778000
    },
    {
        "time": 1689826780000,
        "value": 139.295,
        "_internal_originalTime": 1689826780000
    },
    {
        "time": 1689826782000,
        "value": 139.295,
        "_internal_originalTime": 1689826782000
    },
    {
        "time": 1689826786000,
        "value": 139.292,
        "_internal_originalTime": 1689826786000
    },
    {
        "time": 1689826787000,
        "value": 139.292,
        "_internal_originalTime": 1689826787000
    },
    {
        "time": 1689826789000,
        "value": 139.294,
        "_internal_originalTime": 1689826789000
    },
    {
        "time": 1689826790000,
        "value": 139.291,
        "_internal_originalTime": 1689826790000
    },
    {
        "time": 1689826792000,
        "value": 139.291,
        "_internal_originalTime": 1689826792000
    },
    {
        "time": 1689826794000,
        "value": 139.294,
        "_internal_originalTime": 1689826794000
    },
    {
        "time": 1689826799000,
        "value": 139.295,
        "_internal_originalTime": 1689826799000
    },
    {
        "time": 1689826800000,
        "value": 139.294,
        "_internal_originalTime": 1689826800000
    },
    {
        "time": 1689826801000,
        "value": 139.294,
        "_internal_originalTime": 1689826801000
    },
    {
        "time": 1689826802000,
        "value": 139.289,
        "_internal_originalTime": 1689826802000
    },
    {
        "time": 1689826804000,
        "value": 139.27,
        "_internal_originalTime": 1689826804000
    },
    {
        "time": 1689826805000,
        "value": 139.276,
        "_internal_originalTime": 1689826805000
    },
    {
        "time": 1689826807000,
        "value": 139.283,
        "_internal_originalTime": 1689826807000
    },
    {
        "time": 1689826808000,
        "value": 139.282,
        "_internal_originalTime": 1689826808000
    },
    {
        "time": 1689826810000,
        "value": 139.283,
        "_internal_originalTime": 1689826810000
    },
    {
        "time": 1689826813000,
        "value": 139.286,
        "_internal_originalTime": 1689826813000
    },
    {
        "time": 1689826815000,
        "value": 139.282,
        "_internal_originalTime": 1689826815000
    },
    {
        "time": 1689826820000,
        "value": 139.284,
        "_internal_originalTime": 1689826820000
    },
    {
        "time": 1689826821000,
        "value": 139.287,
        "_internal_originalTime": 1689826821000
    },
    {
        "time": 1689826823000,
        "value": 139.282,
        "_internal_originalTime": 1689826823000
    },
    {
        "time": 1689826824000,
        "value": 139.283,
        "_internal_originalTime": 1689826824000
    },
    {
        "time": 1689826827000,
        "value": 139.291,
        "_internal_originalTime": 1689826827000
    },
    {
        "time": 1689826828000,
        "value": 139.287,
        "_internal_originalTime": 1689826828000
    },
    {
        "time": 1689826829000,
        "value": 139.288,
        "_internal_originalTime": 1689826829000
    },
    {
        "time": 1689826831000,
        "value": 139.293,
        "_internal_originalTime": 1689826831000
    },
    {
        "time": 1689826832000,
        "value": 139.288,
        "_internal_originalTime": 1689826832000
    },
    {
        "time": 1689826834000,
        "value": 139.293,
        "_internal_originalTime": 1689826834000
    },
    {
        "time": 1689826836000,
        "value": 139.292,
        "_internal_originalTime": 1689826836000
    },
    {
        "time": 1689826837000,
        "value": 139.292,
        "_internal_originalTime": 1689826837000
    },
    {
        "time": 1689826839000,
        "value": 139.291,
        "_internal_originalTime": 1689826839000
    },
    {
        "time": 1689826841000,
        "value": 139.291,
        "_internal_originalTime": 1689826841000
    },
    {
        "time": 1689826843000,
        "value": 139.288,
        "_internal_originalTime": 1689826843000
    },
    {
        "time": 1689826844000,
        "value": 139.288,
        "_internal_originalTime": 1689826844000
    },
    {
        "time": 1689826845000,
        "value": 139.288,
        "_internal_originalTime": 1689826845000
    },
    {
        "time": 1689826848000,
        "value": 139.289,
        "_internal_originalTime": 1689826848000
    },
    {
        "time": 1689826850000,
        "value": 139.283,
        "_internal_originalTime": 1689826850000
    },
    {
        "time": 1689826853000,
        "value": 139.286,
        "_internal_originalTime": 1689826853000
    },
    {
        "time": 1689826855000,
        "value": 139.281,
        "_internal_originalTime": 1689826855000
    },
    {
        "time": 1689826856000,
        "value": 139.282,
        "_internal_originalTime": 1689826856000
    },
    {
        "time": 1689826857000,
        "value": 139.279,
        "_internal_originalTime": 1689826857000
    },
    {
        "time": 1689826862000,
        "value": 139.275,
        "_internal_originalTime": 1689826862000
    },
    {
        "time": 1689826864000,
        "value": 139.285,
        "_internal_originalTime": 1689826864000
    },
    {
        "time": 1689826866000,
        "value": 139.285,
        "_internal_originalTime": 1689826866000
    },
    {
        "time": 1689826868000,
        "value": 139.28,
        "_internal_originalTime": 1689826868000
    },
    {
        "time": 1689826870000,
        "value": 139.283,
        "_internal_originalTime": 1689826870000
    },
    {
        "time": 1689826872000,
        "value": 139.286,
        "_internal_originalTime": 1689826872000
    },
    {
        "time": 1689826874000,
        "value": 139.289,
        "_internal_originalTime": 1689826874000
    },
    {
        "time": 1689826876000,
        "value": 139.287,
        "_internal_originalTime": 1689826876000
    },
    {
        "time": 1689826878000,
        "value": 139.286,
        "_internal_originalTime": 1689826878000
    },
    {
        "time": 1689826879000,
        "value": 139.29,
        "_internal_originalTime": 1689826879000
    },
    {
        "time": 1689826882000,
        "value": 139.285,
        "_internal_originalTime": 1689826882000
    },
    {
        "time": 1689826885000,
        "value": 139.28,
        "_internal_originalTime": 1689826885000
    },
    {
        "time": 1689826886000,
        "value": 139.282,
        "_internal_originalTime": 1689826886000
    },
    {
        "time": 1689826887000,
        "value": 139.281,
        "_internal_originalTime": 1689826887000
    },
    {
        "time": 1689826888000,
        "value": 139.277,
        "_internal_originalTime": 1689826888000
    },
    {
        "time": 1689826892000,
        "value": 139.276,
        "_internal_originalTime": 1689826892000
    },
    {
        "time": 1689826894000,
        "value": 139.277,
        "_internal_originalTime": 1689826894000
    },
    {
        "time": 1689826896000,
        "value": 139.274,
        "_internal_originalTime": 1689826896000
    },
    {
        "time": 1689826899000,
        "value": 139.275,
        "_internal_originalTime": 1689826899000
    },
    {
        "time": 1689826901000,
        "value": 139.273,
        "_internal_originalTime": 1689826901000
    },
    {
        "time": 1689826902000,
        "value": 139.273,
        "_internal_originalTime": 1689826902000
    },
    {
        "time": 1689826904000,
        "value": 139.268,
        "_internal_originalTime": 1689826904000
    },
    {
        "time": 1689826906000,
        "value": 139.275,
        "_internal_originalTime": 1689826906000
    },
    {
        "time": 1689826907000,
        "value": 139.276,
        "_internal_originalTime": 1689826907000
    },
    {
        "time": 1689826910000,
        "value": 139.277,
        "_internal_originalTime": 1689826910000
    },
    {
        "time": 1689826911000,
        "value": 139.281,
        "_internal_originalTime": 1689826911000
    },
    {
        "time": 1689826913000,
        "value": 139.278,
        "_internal_originalTime": 1689826913000
    },
    {
        "time": 1689826914000,
        "value": 139.278,
        "_internal_originalTime": 1689826914000
    },
    {
        "time": 1689826916000,
        "value": 139.281,
        "_internal_originalTime": 1689826916000
    },
    {
        "time": 1689826919000,
        "value": 139.279,
        "_internal_originalTime": 1689826919000
    },
    {
        "time": 1689826920000,
        "value": 139.277,
        "_internal_originalTime": 1689826920000
    },
    {
        "time": 1689826922000,
        "value": 139.28,
        "_internal_originalTime": 1689826922000
    },
    {
        "time": 1689826923000,
        "value": 139.274,
        "_internal_originalTime": 1689826923000
    },
    {
        "time": 1689826924000,
        "value": 139.279,
        "_internal_originalTime": 1689826924000
    },
    {
        "time": 1689826926000,
        "value": 139.279,
        "_internal_originalTime": 1689826926000
    },
    {
        "time": 1689826928000,
        "value": 139.28,
        "_internal_originalTime": 1689826928000
    },
    {
        "time": 1689826929000,
        "value": 139.275,
        "_internal_originalTime": 1689826929000
    },
    {
        "time": 1689826931000,
        "value": 139.268,
        "_internal_originalTime": 1689826931000
    },
    {
        "time": 1689826933000,
        "value": 139.277,
        "_internal_originalTime": 1689826933000
    },
    {
        "time": 1689826935000,
        "value": 139.272,
        "_internal_originalTime": 1689826935000
    },
    {
        "time": 1689826936000,
        "value": 139.276,
        "_internal_originalTime": 1689826936000
    },
    {
        "time": 1689826947000,
        "value": 139.272,
        "_internal_originalTime": 1689826947000
    },
    {
        "time": 1689826950000,
        "value": 139.275,
        "_internal_originalTime": 1689826950000
    },
    {
        "time": 1689826952000,
        "value": 139.27,
        "_internal_originalTime": 1689826952000
    },
    {
        "time": 1689826953000,
        "value": 139.276,
        "_internal_originalTime": 1689826953000
    },
    {
        "time": 1689826957000,
        "value": 139.282,
        "_internal_originalTime": 1689826957000
    },
    {
        "time": 1689826958000,
        "value": 139.283,
        "_internal_originalTime": 1689826958000
    },
    {
        "time": 1689826959000,
        "value": 139.281,
        "_internal_originalTime": 1689826959000
    },
    {
        "time": 1689826961000,
        "value": 139.278,
        "_internal_originalTime": 1689826961000
    },
    {
        "time": 1689826963000,
        "value": 139.285,
        "_internal_originalTime": 1689826963000
    },
    {
        "time": 1689826965000,
        "value": 139.288,
        "_internal_originalTime": 1689826965000
    },
    {
        "time": 1689826966000,
        "value": 139.285,
        "_internal_originalTime": 1689826966000
    },
    {
        "time": 1689826967000,
        "value": 139.278,
        "_internal_originalTime": 1689826967000
    },
    {
        "time": 1689826968000,
        "value": 139.279,
        "_internal_originalTime": 1689826968000
    },
    {
        "time": 1689826970000,
        "value": 139.278,
        "_internal_originalTime": 1689826970000
    },
    {
        "time": 1689826972000,
        "value": 139.278,
        "_internal_originalTime": 1689826972000
    },
    {
        "time": 1689826974000,
        "value": 139.283,
        "_internal_originalTime": 1689826974000
    },
    {
        "time": 1689826976000,
        "value": 139.284,
        "_internal_originalTime": 1689826976000
    },
    {
        "time": 1689826978000,
        "value": 139.283,
        "_internal_originalTime": 1689826978000
    },
    {
        "time": 1689826979000,
        "value": 139.278,
        "_internal_originalTime": 1689826979000
    },
    {
        "time": 1689826982000,
        "value": 139.278,
        "_internal_originalTime": 1689826982000
    },
    {
        "time": 1689826983000,
        "value": 139.284,
        "_internal_originalTime": 1689826983000
    },
    {
        "time": 1689826985000,
        "value": 139.291,
        "_internal_originalTime": 1689826985000
    },
    {
        "time": 1689826987000,
        "value": 139.291,
        "_internal_originalTime": 1689826987000
    },
    {
        "time": 1689826989000,
        "value": 139.29,
        "_internal_originalTime": 1689826989000
    },
    {
        "time": 1689826990000,
        "value": 139.29,
        "_internal_originalTime": 1689826990000
    },
    {
        "time": 1689826994000,
        "value": 139.291,
        "_internal_originalTime": 1689826994000
    },
    {
        "time": 1689826995000,
        "value": 139.294,
        "_internal_originalTime": 1689826995000
    },
    {
        "time": 1689826996000,
        "value": 139.293,
        "_internal_originalTime": 1689826996000
    },
    {
        "time": 1689826997000,
        "value": 139.298,
        "_internal_originalTime": 1689826997000
    },
    {
        "time": 1689827000000,
        "value": 139.302,
        "_internal_originalTime": 1689827000000
    },
    {
        "time": 1689827001000,
        "value": 139.298,
        "_internal_originalTime": 1689827001000
    },
    {
        "time": 1689827002000,
        "value": 139.298,
        "_internal_originalTime": 1689827002000
    },
    {
        "time": 1689827003000,
        "value": 139.299,
        "_internal_originalTime": 1689827003000
    },
    {
        "time": 1689827006000,
        "value": 139.302,
        "_internal_originalTime": 1689827006000
    },
    {
        "time": 1689827007000,
        "value": 139.299,
        "_internal_originalTime": 1689827007000
    },
    {
        "time": 1689827008000,
        "value": 139.298,
        "_internal_originalTime": 1689827008000
    },
    {
        "time": 1689827010000,
        "value": 139.299,
        "_internal_originalTime": 1689827010000
    },
    {
        "time": 1689827012000,
        "value": 139.294,
        "_internal_originalTime": 1689827012000
    },
    {
        "time": 1689827013000,
        "value": 139.293,
        "_internal_originalTime": 1689827013000
    },
    {
        "time": 1689827014000,
        "value": 139.294,
        "_internal_originalTime": 1689827014000
    },
    {
        "time": 1689827017000,
        "value": 139.294,
        "_internal_originalTime": 1689827017000
    },
    {
        "time": 1689827018000,
        "value": 139.29,
        "_internal_originalTime": 1689827018000
    },
    {
        "time": 1689827020000,
        "value": 139.289,
        "_internal_originalTime": 1689827020000
    },
    {
        "time": 1689827022000,
        "value": 139.29,
        "_internal_originalTime": 1689827022000
    },
    {
        "time": 1689827023000,
        "value": 139.29,
        "_internal_originalTime": 1689827023000
    },
    {
        "time": 1689827026000,
        "value": 139.29,
        "_internal_originalTime": 1689827026000
    },
    {
        "time": 1689827027000,
        "value": 139.293,
        "_internal_originalTime": 1689827027000
    },
    {
        "time": 1689827029000,
        "value": 139.295,
        "_internal_originalTime": 1689827029000
    },
    {
        "time": 1689827031000,
        "value": 139.291,
        "_internal_originalTime": 1689827031000
    },
    {
        "time": 1689827032000,
        "value": 139.296,
        "_internal_originalTime": 1689827032000
    },
    {
        "time": 1689827034000,
        "value": 139.296,
        "_internal_originalTime": 1689827034000
    },
    {
        "time": 1689827037000,
        "value": 139.289,
        "_internal_originalTime": 1689827037000
    },
    {
        "time": 1689827038000,
        "value": 139.287,
        "_internal_originalTime": 1689827038000
    },
    {
        "time": 1689827040000,
        "value": 139.285,
        "_internal_originalTime": 1689827040000
    },
    {
        "time": 1689827041000,
        "value": 139.29,
        "_internal_originalTime": 1689827041000
    },
    {
        "time": 1689827042000,
        "value": 139.293,
        "_internal_originalTime": 1689827042000
    },
    {
        "time": 1689827043000,
        "value": 139.292,
        "_internal_originalTime": 1689827043000
    },
    {
        "time": 1689827045000,
        "value": 139.285,
        "_internal_originalTime": 1689827045000
    },
    {
        "time": 1689827046000,
        "value": 139.28,
        "_internal_originalTime": 1689827046000
    },
    {
        "time": 1689827048000,
        "value": 139.28,
        "_internal_originalTime": 1689827048000
    },
    {
        "time": 1689827049000,
        "value": 139.28,
        "_internal_originalTime": 1689827049000
    },
    {
        "time": 1689827053000,
        "value": 139.281,
        "_internal_originalTime": 1689827053000
    },
    {
        "time": 1689827055000,
        "value": 139.284,
        "_internal_originalTime": 1689827055000
    },
    {
        "time": 1689827056000,
        "value": 139.28,
        "_internal_originalTime": 1689827056000
    },
    {
        "time": 1689827058000,
        "value": 139.285,
        "_internal_originalTime": 1689827058000
    },
    {
        "time": 1689827061000,
        "value": 139.28,
        "_internal_originalTime": 1689827061000
    },
    {
        "time": 1689827064000,
        "value": 139.287,
        "_internal_originalTime": 1689827064000
    },
    {
        "time": 1689827066000,
        "value": 139.281,
        "_internal_originalTime": 1689827066000
    },
    {
        "time": 1689827067000,
        "value": 139.282,
        "_internal_originalTime": 1689827067000
    },
    {
        "time": 1689827069000,
        "value": 139.284,
        "_internal_originalTime": 1689827069000
    },
    {
        "time": 1689827070000,
        "value": 139.282,
        "_internal_originalTime": 1689827070000
    },
    {
        "time": 1689827071000,
        "value": 139.282,
        "_internal_originalTime": 1689827071000
    },
    {
        "time": 1689827072000,
        "value": 139.282,
        "_internal_originalTime": 1689827072000
    },
    {
        "time": 1689827074000,
        "value": 139.281,
        "_internal_originalTime": 1689827074000
    },
    {
        "time": 1689827076000,
        "value": 139.283,
        "_internal_originalTime": 1689827076000
    },
    {
        "time": 1689827078000,
        "value": 139.292,
        "_internal_originalTime": 1689827078000
    },
    {
        "time": 1689827079000,
        "value": 139.29,
        "_internal_originalTime": 1689827079000
    },
    {
        "time": 1689827081000,
        "value": 139.288,
        "_internal_originalTime": 1689827081000
    },
    {
        "time": 1689827086000,
        "value": 139.289,
        "_internal_originalTime": 1689827086000
    },
    {
        "time": 1689827087000,
        "value": 139.288,
        "_internal_originalTime": 1689827087000
    },
    {
        "time": 1689827089000,
        "value": 139.295,
        "_internal_originalTime": 1689827089000
    },
    {
        "time": 1689827092000,
        "value": 139.289,
        "_internal_originalTime": 1689827092000
    },
    {
        "time": 1689827094000,
        "value": 139.286,
        "_internal_originalTime": 1689827094000
    },
    {
        "time": 1689827097000,
        "value": 139.288,
        "_internal_originalTime": 1689827097000
    },
    {
        "time": 1689827098000,
        "value": 139.296,
        "_internal_originalTime": 1689827098000
    },
    {
        "time": 1689827099000,
        "value": 139.295,
        "_internal_originalTime": 1689827099000
    },
    {
        "time": 1689827100000,
        "value": 139.295,
        "_internal_originalTime": 1689827100000
    },
    {
        "time": 1689827102000,
        "value": 139.289,
        "_internal_originalTime": 1689827102000
    },
    {
        "time": 1689827103000,
        "value": 139.292,
        "_internal_originalTime": 1689827103000
    },
    {
        "time": 1689827105000,
        "value": 139.292,
        "_internal_originalTime": 1689827105000
    },
    {
        "time": 1689827108000,
        "value": 139.292,
        "_internal_originalTime": 1689827108000
    },
    {
        "time": 1689827111000,
        "value": 139.293,
        "_internal_originalTime": 1689827111000
    },
    {
        "time": 1689827113000,
        "value": 139.29,
        "_internal_originalTime": 1689827113000
    },
    {
        "time": 1689827114000,
        "value": 139.29,
        "_internal_originalTime": 1689827114000
    },
    {
        "time": 1689827115000,
        "value": 139.293,
        "_internal_originalTime": 1689827115000
    },
    {
        "time": 1689827120000,
        "value": 139.29,
        "_internal_originalTime": 1689827120000
    },
    {
        "time": 1689827121000,
        "value": 139.294,
        "_internal_originalTime": 1689827121000
    },
    {
        "time": 1689827126000,
        "value": 139.293,
        "_internal_originalTime": 1689827126000
    },
    {
        "time": 1689827127000,
        "value": 139.294,
        "_internal_originalTime": 1689827127000
    },
    {
        "time": 1689827130000,
        "value": 139.293,
        "_internal_originalTime": 1689827130000
    },
    {
        "time": 1689827132000,
        "value": 139.298,
        "_internal_originalTime": 1689827132000
    },
    {
        "time": 1689827133000,
        "value": 139.304,
        "_internal_originalTime": 1689827133000
    },
    {
        "time": 1689827135000,
        "value": 139.303,
        "_internal_originalTime": 1689827135000
    },
    {
        "time": 1689827136000,
        "value": 139.304,
        "_internal_originalTime": 1689827136000
    },
    {
        "time": 1689827139000,
        "value": 139.305,
        "_internal_originalTime": 1689827139000
    },
    {
        "time": 1689827140000,
        "value": 139.308,
        "_internal_originalTime": 1689827140000
    },
    {
        "time": 1689827142000,
        "value": 139.303,
        "_internal_originalTime": 1689827142000
    },
    {
        "time": 1689827144000,
        "value": 139.305,
        "_internal_originalTime": 1689827144000
    },
    {
        "time": 1689827145000,
        "value": 139.301,
        "_internal_originalTime": 1689827145000
    },
    {
        "time": 1689827146000,
        "value": 139.302,
        "_internal_originalTime": 1689827146000
    },
    {
        "time": 1689827147000,
        "value": 139.303,
        "_internal_originalTime": 1689827147000
    },
    {
        "time": 1689827148000,
        "value": 139.302,
        "_internal_originalTime": 1689827148000
    },
    {
        "time": 1689827153000,
        "value": 139.295,
        "_internal_originalTime": 1689827153000
    },
    {
        "time": 1689827154000,
        "value": 139.299,
        "_internal_originalTime": 1689827154000
    },
    {
        "time": 1689827156000,
        "value": 139.296,
        "_internal_originalTime": 1689827156000
    },
    {
        "time": 1689827158000,
        "value": 139.293,
        "_internal_originalTime": 1689827158000
    },
    {
        "time": 1689827159000,
        "value": 139.294,
        "_internal_originalTime": 1689827159000
    },
    {
        "time": 1689827162000,
        "value": 139.289,
        "_internal_originalTime": 1689827162000
    },
    {
        "time": 1689827164000,
        "value": 139.292,
        "_internal_originalTime": 1689827164000
    },
    {
        "time": 1689827165000,
        "value": 139.292,
        "_internal_originalTime": 1689827165000
    },
    {
        "time": 1689827166000,
        "value": 139.292,
        "_internal_originalTime": 1689827166000
    },
    {
        "time": 1689827168000,
        "value": 139.291,
        "_internal_originalTime": 1689827168000
    },
    {
        "time": 1689827171000,
        "value": 139.289,
        "_internal_originalTime": 1689827171000
    },
    {
        "time": 1689827174000,
        "value": 139.293,
        "_internal_originalTime": 1689827174000
    },
    {
        "time": 1689827175000,
        "value": 139.292,
        "_internal_originalTime": 1689827175000
    },
    {
        "time": 1689827177000,
        "value": 139.288,
        "_internal_originalTime": 1689827177000
    },
    {
        "time": 1689827178000,
        "value": 139.291,
        "_internal_originalTime": 1689827178000
    },
    {
        "time": 1689827180000,
        "value": 139.289,
        "_internal_originalTime": 1689827180000
    },
    {
        "time": 1689827181000,
        "value": 139.29,
        "_internal_originalTime": 1689827181000
    },
    {
        "time": 1689827183000,
        "value": 139.288,
        "_internal_originalTime": 1689827183000
    },
    {
        "time": 1689827185000,
        "value": 139.291,
        "_internal_originalTime": 1689827185000
    },
    {
        "time": 1689827188000,
        "value": 139.284,
        "_internal_originalTime": 1689827188000
    },
    {
        "time": 1689827189000,
        "value": 139.278,
        "_internal_originalTime": 1689827189000
    },
    {
        "time": 1689827191000,
        "value": 139.278,
        "_internal_originalTime": 1689827191000
    },
    {
        "time": 1689827192000,
        "value": 139.277,
        "_internal_originalTime": 1689827192000
    },
    {
        "time": 1689827194000,
        "value": 139.285,
        "_internal_originalTime": 1689827194000
    },
    {
        "time": 1689827196000,
        "value": 139.28,
        "_internal_originalTime": 1689827196000
    },
    {
        "time": 1689827198000,
        "value": 139.287,
        "_internal_originalTime": 1689827198000
    },
    {
        "time": 1689827201000,
        "value": 139.285,
        "_internal_originalTime": 1689827201000
    },
    {
        "time": 1689827203000,
        "value": 139.285,
        "_internal_originalTime": 1689827203000
    },
    {
        "time": 1689827204000,
        "value": 139.282,
        "_internal_originalTime": 1689827204000
    },
    {
        "time": 1689827208000,
        "value": 139.288,
        "_internal_originalTime": 1689827208000
    },
    {
        "time": 1689827209000,
        "value": 139.283,
        "_internal_originalTime": 1689827209000
    },
    {
        "time": 1689827211000,
        "value": 139.285,
        "_internal_originalTime": 1689827211000
    },
    {
        "time": 1689827214000,
        "value": 139.285,
        "_internal_originalTime": 1689827214000
    },
    {
        "time": 1689827215000,
        "value": 139.28,
        "_internal_originalTime": 1689827215000
    },
    {
        "time": 1689827217000,
        "value": 139.287,
        "_internal_originalTime": 1689827217000
    },
    {
        "time": 1689827219000,
        "value": 139.286,
        "_internal_originalTime": 1689827219000
    },
    {
        "time": 1689827220000,
        "value": 139.282,
        "_internal_originalTime": 1689827220000
    },
    {
        "time": 1689827221000,
        "value": 139.282,
        "_internal_originalTime": 1689827221000
    },
    {
        "time": 1689827223000,
        "value": 139.28,
        "_internal_originalTime": 1689827223000
    },
    {
        "time": 1689827225000,
        "value": 139.285,
        "_internal_originalTime": 1689827225000
    },
    {
        "time": 1689827226000,
        "value": 139.284,
        "_internal_originalTime": 1689827226000
    },
    {
        "time": 1689827228000,
        "value": 139.284,
        "_internal_originalTime": 1689827228000
    },
    {
        "time": 1689827229000,
        "value": 139.278,
        "_internal_originalTime": 1689827229000
    },
    {
        "time": 1689827231000,
        "value": 139.279,
        "_internal_originalTime": 1689827231000
    },
    {
        "time": 1689827235000,
        "value": 139.277,
        "_internal_originalTime": 1689827235000
    },
    {
        "time": 1689827238000,
        "value": 139.282,
        "_internal_originalTime": 1689827238000
    },
    {
        "time": 1689827240000,
        "value": 139.282,
        "_internal_originalTime": 1689827240000
    },
    {
        "time": 1689827241000,
        "value": 139.282,
        "_internal_originalTime": 1689827241000
    },
    {
        "time": 1689827243000,
        "value": 139.282,
        "_internal_originalTime": 1689827243000
    },
    {
        "time": 1689827244000,
        "value": 139.285,
        "_internal_originalTime": 1689827244000
    },
    {
        "time": 1689827245000,
        "value": 139.285,
        "_internal_originalTime": 1689827245000
    },
    {
        "time": 1689827249000,
        "value": 139.283,
        "_internal_originalTime": 1689827249000
    },
    {
        "time": 1689827251000,
        "value": 139.28,
        "_internal_originalTime": 1689827251000
    },
    {
        "time": 1689827252000,
        "value": 139.283,
        "_internal_originalTime": 1689827252000
    },
    {
        "time": 1689827253000,
        "value": 139.282,
        "_internal_originalTime": 1689827253000
    },
    {
        "time": 1689827258000,
        "value": 139.28,
        "_internal_originalTime": 1689827258000
    },
    {
        "time": 1689827259000,
        "value": 139.28,
        "_internal_originalTime": 1689827259000
    },
    {
        "time": 1689827261000,
        "value": 139.282,
        "_internal_originalTime": 1689827261000
    },
    {
        "time": 1689827263000,
        "value": 139.278,
        "_internal_originalTime": 1689827263000
    },
    {
        "time": 1689827264000,
        "value": 139.279,
        "_internal_originalTime": 1689827264000
    },
    {
        "time": 1689827265000,
        "value": 139.278,
        "_internal_originalTime": 1689827265000
    },
    {
        "time": 1689827267000,
        "value": 139.279,
        "_internal_originalTime": 1689827267000
    },
    {
        "time": 1689827268000,
        "value": 139.279,
        "_internal_originalTime": 1689827268000
    },
    {
        "time": 1689827271000,
        "value": 139.282,
        "_internal_originalTime": 1689827271000
    },
    {
        "time": 1689827272000,
        "value": 139.279,
        "_internal_originalTime": 1689827272000
    },
    {
        "time": 1689827274000,
        "value": 139.284,
        "_internal_originalTime": 1689827274000
    },
    {
        "time": 1689827276000,
        "value": 139.282,
        "_internal_originalTime": 1689827276000
    },
    {
        "time": 1689827277000,
        "value": 139.281,
        "_internal_originalTime": 1689827277000
    },
    {
        "time": 1689827279000,
        "value": 139.285,
        "_internal_originalTime": 1689827279000
    },
    {
        "time": 1689827280000,
        "value": 139.28,
        "_internal_originalTime": 1689827280000
    },
    {
        "time": 1689827281000,
        "value": 139.281,
        "_internal_originalTime": 1689827281000
    },
    {
        "time": 1689827282000,
        "value": 139.279,
        "_internal_originalTime": 1689827282000
    },
    {
        "time": 1689827285000,
        "value": 139.283,
        "_internal_originalTime": 1689827285000
    },
    {
        "time": 1689827287000,
        "value": 139.282,
        "_internal_originalTime": 1689827287000
    },
    {
        "time": 1689827288000,
        "value": 139.283,
        "_internal_originalTime": 1689827288000
    },
    {
        "time": 1689827290000,
        "value": 139.281,
        "_internal_originalTime": 1689827290000
    },
    {
        "time": 1689827292000,
        "value": 139.28,
        "_internal_originalTime": 1689827292000
    },
    {
        "time": 1689827293000,
        "value": 139.284,
        "_internal_originalTime": 1689827293000
    },
    {
        "time": 1689827298000,
        "value": 139.286,
        "_internal_originalTime": 1689827298000
    },
    {
        "time": 1689827310000,
        "value": 139.285,
        "_internal_originalTime": 1689827310000
    },
    {
        "time": 1689827312000,
        "value": 139.28,
        "_internal_originalTime": 1689827312000
    },
    {
        "time": 1689827314000,
        "value": 139.287,
        "_internal_originalTime": 1689827314000
    },
    {
        "time": 1689827317000,
        "value": 139.282,
        "_internal_originalTime": 1689827317000
    },
    {
        "time": 1689827319000,
        "value": 139.286,
        "_internal_originalTime": 1689827319000
    },
    {
        "time": 1689827321000,
        "value": 139.285,
        "_internal_originalTime": 1689827321000
    },
    {
        "time": 1689827325000,
        "value": 139.284,
        "_internal_originalTime": 1689827325000
    },
    {
        "time": 1689827327000,
        "value": 139.28,
        "_internal_originalTime": 1689827327000
    },
    {
        "time": 1689827328000,
        "value": 139.281,
        "_internal_originalTime": 1689827328000
    },
    {
        "time": 1689827330000,
        "value": 139.28,
        "_internal_originalTime": 1689827330000
    },
    {
        "time": 1689827332000,
        "value": 139.281,
        "_internal_originalTime": 1689827332000
    },
    {
        "time": 1689827334000,
        "value": 139.285,
        "_internal_originalTime": 1689827334000
    },
    {
        "time": 1689827336000,
        "value": 139.285,
        "_internal_originalTime": 1689827336000
    },
    {
        "time": 1689827337000,
        "value": 139.28,
        "_internal_originalTime": 1689827337000
    },
    {
        "time": 1689827339000,
        "value": 139.283,
        "_internal_originalTime": 1689827339000
    },
    {
        "time": 1689827340000,
        "value": 139.28,
        "_internal_originalTime": 1689827340000
    },
    {
        "time": 1689827341000,
        "value": 139.283,
        "_internal_originalTime": 1689827341000
    },
    {
        "time": 1689827342000,
        "value": 139.283,
        "_internal_originalTime": 1689827342000
    },
    {
        "time": 1689827343000,
        "value": 139.283,
        "_internal_originalTime": 1689827343000
    },
    {
        "time": 1689827344000,
        "value": 139.283,
        "_internal_originalTime": 1689827344000
    },
    {
        "time": 1689827346000,
        "value": 139.283,
        "_internal_originalTime": 1689827346000
    },
    {
        "time": 1689827348000,
        "value": 139.283,
        "_internal_originalTime": 1689827348000
    },
    {
        "time": 1689827351000,
        "value": 139.28,
        "_internal_originalTime": 1689827351000
    },
    {
        "time": 1689827353000,
        "value": 139.278,
        "_internal_originalTime": 1689827353000
    },
    {
        "time": 1689827354000,
        "value": 139.277,
        "_internal_originalTime": 1689827354000
    },
    {
        "time": 1689827357000,
        "value": 139.279,
        "_internal_originalTime": 1689827357000
    },
    {
        "time": 1689827358000,
        "value": 139.278,
        "_internal_originalTime": 1689827358000
    },
    {
        "time": 1689827359000,
        "value": 139.278,
        "_internal_originalTime": 1689827359000
    },
    {
        "time": 1689827362000,
        "value": 139.283,
        "_internal_originalTime": 1689827362000
    },
    {
        "time": 1689827364000,
        "value": 139.284,
        "_internal_originalTime": 1689827364000
    },
    {
        "time": 1689827365000,
        "value": 139.282,
        "_internal_originalTime": 1689827365000
    },
    {
        "time": 1689827366000,
        "value": 139.286,
        "_internal_originalTime": 1689827366000
    },
    {
        "time": 1689827370000,
        "value": 139.281,
        "_internal_originalTime": 1689827370000
    },
    {
        "time": 1689827372000,
        "value": 139.279,
        "_internal_originalTime": 1689827372000
    },
    {
        "time": 1689827375000,
        "value": 139.28,
        "_internal_originalTime": 1689827375000
    },
    {
        "time": 1689827376000,
        "value": 139.284,
        "_internal_originalTime": 1689827376000
    },
    {
        "time": 1689827378000,
        "value": 139.283,
        "_internal_originalTime": 1689827378000
    },
    {
        "time": 1689827381000,
        "value": 139.281,
        "_internal_originalTime": 1689827381000
    },
    {
        "time": 1689827383000,
        "value": 139.284,
        "_internal_originalTime": 1689827383000
    },
    {
        "time": 1689827385000,
        "value": 139.285,
        "_internal_originalTime": 1689827385000
    },
    {
        "time": 1689827386000,
        "value": 139.284,
        "_internal_originalTime": 1689827386000
    },
    {
        "time": 1689827387000,
        "value": 139.283,
        "_internal_originalTime": 1689827387000
    },
    {
        "time": 1689827389000,
        "value": 139.282,
        "_internal_originalTime": 1689827389000
    },
    {
        "time": 1689827390000,
        "value": 139.275,
        "_internal_originalTime": 1689827390000
    },
    {
        "time": 1689827392000,
        "value": 139.275,
        "_internal_originalTime": 1689827392000
    },
    {
        "time": 1689827393000,
        "value": 139.274,
        "_internal_originalTime": 1689827393000
    },
    {
        "time": 1689827398000,
        "value": 139.27,
        "_internal_originalTime": 1689827398000
    },
    {
        "time": 1689827400000,
        "value": 139.27,
        "_internal_originalTime": 1689827400000
    },
    {
        "time": 1689827401000,
        "value": 139.27,
        "_internal_originalTime": 1689827401000
    },
    {
        "time": 1689827402000,
        "value": 139.271,
        "_internal_originalTime": 1689827402000
    },
    {
        "time": 1689827404000,
        "value": 139.276,
        "_internal_originalTime": 1689827404000
    },
    {
        "time": 1689827405000,
        "value": 139.28,
        "_internal_originalTime": 1689827405000
    },
    {
        "time": 1689827407000,
        "value": 139.282,
        "_internal_originalTime": 1689827407000
    },
    {
        "time": 1689827408000,
        "value": 139.278,
        "_internal_originalTime": 1689827408000
    },
    {
        "time": 1689827410000,
        "value": 139.279,
        "_internal_originalTime": 1689827410000
    },
    {
        "time": 1689827411000,
        "value": 139.281,
        "_internal_originalTime": 1689827411000
    },
    {
        "time": 1689827414000,
        "value": 139.281,
        "_internal_originalTime": 1689827414000
    },
    {
        "time": 1689827419000,
        "value": 139.287,
        "_internal_originalTime": 1689827419000
    },
    {
        "time": 1689827420000,
        "value": 139.291,
        "_internal_originalTime": 1689827420000
    },
    {
        "time": 1689827421000,
        "value": 139.286,
        "_internal_originalTime": 1689827421000
    },
    {
        "time": 1689827423000,
        "value": 139.287,
        "_internal_originalTime": 1689827423000
    },
    {
        "time": 1689827424000,
        "value": 139.286,
        "_internal_originalTime": 1689827424000
    },
    {
        "time": 1689827425000,
        "value": 139.291,
        "_internal_originalTime": 1689827425000
    },
    {
        "time": 1689827430000,
        "value": 139.291,
        "_internal_originalTime": 1689827430000
    },
    {
        "time": 1689827431000,
        "value": 139.286,
        "_internal_originalTime": 1689827431000
    },
    {
        "time": 1689827433000,
        "value": 139.287,
        "_internal_originalTime": 1689827433000
    },
    {
        "time": 1689827434000,
        "value": 139.283,
        "_internal_originalTime": 1689827434000
    },
    {
        "time": 1689827436000,
        "value": 139.282,
        "_internal_originalTime": 1689827436000
    },
    {
        "time": 1689827437000,
        "value": 139.278,
        "_internal_originalTime": 1689827437000
    },
    {
        "time": 1689827439000,
        "value": 139.278,
        "_internal_originalTime": 1689827439000
    },
    {
        "time": 1689827441000,
        "value": 139.279,
        "_internal_originalTime": 1689827441000
    },
    {
        "time": 1689827443000,
        "value": 139.28,
        "_internal_originalTime": 1689827443000
    },
    {
        "time": 1689827446000,
        "value": 139.283,
        "_internal_originalTime": 1689827446000
    },
    {
        "time": 1689827448000,
        "value": 139.285,
        "_internal_originalTime": 1689827448000
    },
    {
        "time": 1689827450000,
        "value": 139.282,
        "_internal_originalTime": 1689827450000
    },
    {
        "time": 1689827452000,
        "value": 139.28,
        "_internal_originalTime": 1689827452000
    },
    {
        "time": 1689827454000,
        "value": 139.284,
        "_internal_originalTime": 1689827454000
    },
    {
        "time": 1689827456000,
        "value": 139.282,
        "_internal_originalTime": 1689827456000
    },
    {
        "time": 1689827457000,
        "value": 139.285,
        "_internal_originalTime": 1689827457000
    },
    {
        "time": 1689827458000,
        "value": 139.286,
        "_internal_originalTime": 1689827458000
    },
    {
        "time": 1689827460000,
        "value": 139.281,
        "_internal_originalTime": 1689827460000
    },
    {
        "time": 1689827461000,
        "value": 139.287,
        "_internal_originalTime": 1689827461000
    },
    {
        "time": 1689827462000,
        "value": 139.28,
        "_internal_originalTime": 1689827462000
    },
    {
        "time": 1689827463000,
        "value": 139.286,
        "_internal_originalTime": 1689827463000
    },
    {
        "time": 1689827464000,
        "value": 139.282,
        "_internal_originalTime": 1689827464000
    },
    {
        "time": 1689827466000,
        "value": 139.282,
        "_internal_originalTime": 1689827466000
    },
    {
        "time": 1689827467000,
        "value": 139.286,
        "_internal_originalTime": 1689827467000
    },
    {
        "time": 1689827470000,
        "value": 139.282,
        "_internal_originalTime": 1689827470000
    },
    {
        "time": 1689827471000,
        "value": 139.281,
        "_internal_originalTime": 1689827471000
    },
    {
        "time": 1689827473000,
        "value": 139.281,
        "_internal_originalTime": 1689827473000
    },
    {
        "time": 1689827475000,
        "value": 139.279,
        "_internal_originalTime": 1689827475000
    },
    {
        "time": 1689827476000,
        "value": 139.281,
        "_internal_originalTime": 1689827476000
    },
    {
        "time": 1689827478000,
        "value": 139.282,
        "_internal_originalTime": 1689827478000
    },
    {
        "time": 1689827480000,
        "value": 139.279,
        "_internal_originalTime": 1689827480000
    },
    {
        "time": 1689827482000,
        "value": 139.28,
        "_internal_originalTime": 1689827482000
    },
    {
        "time": 1689827483000,
        "value": 139.279,
        "_internal_originalTime": 1689827483000
    },
    {
        "time": 1689827485000,
        "value": 139.28,
        "_internal_originalTime": 1689827485000
    },
    {
        "time": 1689827489000,
        "value": 139.28,
        "_internal_originalTime": 1689827489000
    },
    {
        "time": 1689827490000,
        "value": 139.28,
        "_internal_originalTime": 1689827490000
    },
    {
        "time": 1689827492000,
        "value": 139.277,
        "_internal_originalTime": 1689827492000
    },
    {
        "time": 1689827496000,
        "value": 139.282,
        "_internal_originalTime": 1689827496000
    },
    {
        "time": 1689827497000,
        "value": 139.281,
        "_internal_originalTime": 1689827497000
    },
    {
        "time": 1689827499000,
        "value": 139.281,
        "_internal_originalTime": 1689827499000
    },
    {
        "time": 1689827501000,
        "value": 139.282,
        "_internal_originalTime": 1689827501000
    },
    {
        "time": 1689827504000,
        "value": 139.28,
        "_internal_originalTime": 1689827504000
    },
    {
        "time": 1689827505000,
        "value": 139.283,
        "_internal_originalTime": 1689827505000
    },
    {
        "time": 1689827506000,
        "value": 139.282,
        "_internal_originalTime": 1689827506000
    },
    {
        "time": 1689827508000,
        "value": 139.284,
        "_internal_originalTime": 1689827508000
    },
    {
        "time": 1689827509000,
        "value": 139.28,
        "_internal_originalTime": 1689827509000
    },
    {
        "time": 1689827511000,
        "value": 139.282,
        "_internal_originalTime": 1689827511000
    },
    {
        "time": 1689827512000,
        "value": 139.28,
        "_internal_originalTime": 1689827512000
    },
    {
        "time": 1689827514000,
        "value": 139.28,
        "_internal_originalTime": 1689827514000
    },
    {
        "time": 1689827516000,
        "value": 139.279,
        "_internal_originalTime": 1689827516000
    },
    {
        "time": 1689827517000,
        "value": 139.28,
        "_internal_originalTime": 1689827517000
    },
    {
        "time": 1689827518000,
        "value": 139.278,
        "_internal_originalTime": 1689827518000
    },
    {
        "time": 1689827521000,
        "value": 139.281,
        "_internal_originalTime": 1689827521000
    },
    {
        "time": 1689827522000,
        "value": 139.278,
        "_internal_originalTime": 1689827522000
    },
    {
        "time": 1689827524000,
        "value": 139.281,
        "_internal_originalTime": 1689827524000
    },
    {
        "time": 1689827525000,
        "value": 139.277,
        "_internal_originalTime": 1689827525000
    },
    {
        "time": 1689827527000,
        "value": 139.281,
        "_internal_originalTime": 1689827527000
    },
    {
        "time": 1689827528000,
        "value": 139.278,
        "_internal_originalTime": 1689827528000
    },
    {
        "time": 1689827531000,
        "value": 139.28,
        "_internal_originalTime": 1689827531000
    },
    {
        "time": 1689827532000,
        "value": 139.281,
        "_internal_originalTime": 1689827532000
    },
    {
        "time": 1689827533000,
        "value": 139.28,
        "_internal_originalTime": 1689827533000
    },
    {
        "time": 1689827534000,
        "value": 139.277,
        "_internal_originalTime": 1689827534000
    },
    {
        "time": 1689827536000,
        "value": 139.281,
        "_internal_originalTime": 1689827536000
    },
    {
        "time": 1689827537000,
        "value": 139.281,
        "_internal_originalTime": 1689827537000
    },
    {
        "time": 1689827540000,
        "value": 139.281,
        "_internal_originalTime": 1689827540000
    },
    {
        "time": 1689827543000,
        "value": 139.281,
        "_internal_originalTime": 1689827543000
    },
    {
        "time": 1689827544000,
        "value": 139.284,
        "_internal_originalTime": 1689827544000
    },
    {
        "time": 1689827547000,
        "value": 139.278,
        "_internal_originalTime": 1689827547000
    },
    {
        "time": 1689827549000,
        "value": 139.285,
        "_internal_originalTime": 1689827549000
    },
    {
        "time": 1689827550000,
        "value": 139.284,
        "_internal_originalTime": 1689827550000
    },
    {
        "time": 1689827553000,
        "value": 139.284,
        "_internal_originalTime": 1689827553000
    },
    {
        "time": 1689827556000,
        "value": 139.286,
        "_internal_originalTime": 1689827556000
    },
    {
        "time": 1689827558000,
        "value": 139.286,
        "_internal_originalTime": 1689827558000
    },
    {
        "time": 1689827560000,
        "value": 139.287,
        "_internal_originalTime": 1689827560000
    },
    {
        "time": 1689827562000,
        "value": 139.286,
        "_internal_originalTime": 1689827562000
    },
    {
        "time": 1689827564000,
        "value": 139.286,
        "_internal_originalTime": 1689827564000
    },
    {
        "time": 1689827566000,
        "value": 139.291,
        "_internal_originalTime": 1689827566000
    },
    {
        "time": 1689827568000,
        "value": 139.286,
        "_internal_originalTime": 1689827568000
    },
    {
        "time": 1689827569000,
        "value": 139.287,
        "_internal_originalTime": 1689827569000
    },
    {
        "time": 1689827570000,
        "value": 139.291,
        "_internal_originalTime": 1689827570000
    },
    {
        "time": 1689827572000,
        "value": 139.287,
        "_internal_originalTime": 1689827572000
    },
    {
        "time": 1689827574000,
        "value": 139.291,
        "_internal_originalTime": 1689827574000
    },
    {
        "time": 1689827576000,
        "value": 139.283,
        "_internal_originalTime": 1689827576000
    },
    {
        "time": 1689827579000,
        "value": 139.283,
        "_internal_originalTime": 1689827579000
    },
    {
        "time": 1689827580000,
        "value": 139.28,
        "_internal_originalTime": 1689827580000
    },
    {
        "time": 1689827582000,
        "value": 139.276,
        "_internal_originalTime": 1689827582000
    },
    {
        "time": 1689827583000,
        "value": 139.279,
        "_internal_originalTime": 1689827583000
    },
    {
        "time": 1689827585000,
        "value": 139.282,
        "_internal_originalTime": 1689827585000
    },
    {
        "time": 1689827587000,
        "value": 139.284,
        "_internal_originalTime": 1689827587000
    },
    {
        "time": 1689827588000,
        "value": 139.286,
        "_internal_originalTime": 1689827588000
    },
    {
        "time": 1689827590000,
        "value": 139.282,
        "_internal_originalTime": 1689827590000
    },
    {
        "time": 1689827592000,
        "value": 139.28,
        "_internal_originalTime": 1689827592000
    },
    {
        "time": 1689827593000,
        "value": 139.284,
        "_internal_originalTime": 1689827593000
    },
    {
        "time": 1689827595000,
        "value": 139.286,
        "_internal_originalTime": 1689827595000
    },
    {
        "time": 1689827596000,
        "value": 139.285,
        "_internal_originalTime": 1689827596000
    },
    {
        "time": 1689827597000,
        "value": 139.279,
        "_internal_originalTime": 1689827597000
    },
    {
        "time": 1689827600000,
        "value": 139.282,
        "_internal_originalTime": 1689827600000
    },
    {
        "time": 1689827602000,
        "value": 139.278,
        "_internal_originalTime": 1689827602000
    },
    {
        "time": 1689827603000,
        "value": 139.279,
        "_internal_originalTime": 1689827603000
    },
    {
        "time": 1689827605000,
        "value": 139.278,
        "_internal_originalTime": 1689827605000
    },
    {
        "time": 1689827606000,
        "value": 139.282,
        "_internal_originalTime": 1689827606000
    },
    {
        "time": 1689827608000,
        "value": 139.282,
        "_internal_originalTime": 1689827608000
    },
    {
        "time": 1689827610000,
        "value": 139.282,
        "_internal_originalTime": 1689827610000
    },
    {
        "time": 1689827612000,
        "value": 139.285,
        "_internal_originalTime": 1689827612000
    },
    {
        "time": 1689827614000,
        "value": 139.285,
        "_internal_originalTime": 1689827614000
    },
    {
        "time": 1689827615000,
        "value": 139.285,
        "_internal_originalTime": 1689827615000
    },
    {
        "time": 1689827618000,
        "value": 139.283,
        "_internal_originalTime": 1689827618000
    },
    {
        "time": 1689827620000,
        "value": 139.282,
        "_internal_originalTime": 1689827620000
    },
    {
        "time": 1689827622000,
        "value": 139.283,
        "_internal_originalTime": 1689827622000
    },
    {
        "time": 1689827623000,
        "value": 139.283,
        "_internal_originalTime": 1689827623000
    },
    {
        "time": 1689827627000,
        "value": 139.283,
        "_internal_originalTime": 1689827627000
    },
    {
        "time": 1689827629000,
        "value": 139.283,
        "_internal_originalTime": 1689827629000
    },
    {
        "time": 1689827633000,
        "value": 139.283,
        "_internal_originalTime": 1689827633000
    },
    {
        "time": 1689827635000,
        "value": 139.283,
        "_internal_originalTime": 1689827635000
    },
    {
        "time": 1689827637000,
        "value": 139.278,
        "_internal_originalTime": 1689827637000
    },
    {
        "time": 1689827642000,
        "value": 139.282,
        "_internal_originalTime": 1689827642000
    },
    {
        "time": 1689827643000,
        "value": 139.28,
        "_internal_originalTime": 1689827643000
    },
    {
        "time": 1689827644000,
        "value": 139.279,
        "_internal_originalTime": 1689827644000
    },
    {
        "time": 1689827646000,
        "value": 139.282,
        "_internal_originalTime": 1689827646000
    },
    {
        "time": 1689827648000,
        "value": 139.282,
        "_internal_originalTime": 1689827648000
    },
    {
        "time": 1689827650000,
        "value": 139.282,
        "_internal_originalTime": 1689827650000
    },
    {
        "time": 1689827651000,
        "value": 139.279,
        "_internal_originalTime": 1689827651000
    },
    {
        "time": 1689827654000,
        "value": 139.279,
        "_internal_originalTime": 1689827654000
    },
    {
        "time": 1689827655000,
        "value": 139.278,
        "_internal_originalTime": 1689827655000
    },
    {
        "time": 1689827662000,
        "value": 139.277,
        "_internal_originalTime": 1689827662000
    },
    {
        "time": 1689827663000,
        "value": 139.282,
        "_internal_originalTime": 1689827663000
    },
    {
        "time": 1689827671000,
        "value": 139.283,
        "_internal_originalTime": 1689827671000
    },
    {
        "time": 1689827673000,
        "value": 139.286,
        "_internal_originalTime": 1689827673000
    },
    {
        "time": 1689827675000,
        "value": 139.286,
        "_internal_originalTime": 1689827675000
    },
    {
        "time": 1689827677000,
        "value": 139.282,
        "_internal_originalTime": 1689827677000
    },
    {
        "time": 1689827681000,
        "value": 139.285,
        "_internal_originalTime": 1689827681000
    },
    {
        "time": 1689827682000,
        "value": 139.284,
        "_internal_originalTime": 1689827682000
    },
    {
        "time": 1689827684000,
        "value": 139.28,
        "_internal_originalTime": 1689827684000
    },
    {
        "time": 1689827685000,
        "value": 139.284,
        "_internal_originalTime": 1689827685000
    },
    {
        "time": 1689827686000,
        "value": 139.284,
        "_internal_originalTime": 1689827686000
    },
    {
        "time": 1689827688000,
        "value": 139.282,
        "_internal_originalTime": 1689827688000
    },
    {
        "time": 1689827691000,
        "value": 139.287,
        "_internal_originalTime": 1689827691000
    },
    {
        "time": 1689827692000,
        "value": 139.283,
        "_internal_originalTime": 1689827692000
    },
    {
        "time": 1689827693000,
        "value": 139.282,
        "_internal_originalTime": 1689827693000
    },
    {
        "time": 1689827702000,
        "value": 139.281,
        "_internal_originalTime": 1689827702000
    },
    {
        "time": 1689827711000,
        "value": 139.286,
        "_internal_originalTime": 1689827711000
    },
    {
        "time": 1689827714000,
        "value": 139.281,
        "_internal_originalTime": 1689827714000
    },
    {
        "time": 1689827715000,
        "value": 139.281,
        "_internal_originalTime": 1689827715000
    },
    {
        "time": 1689827721000,
        "value": 139.282,
        "_internal_originalTime": 1689827721000
    },
    {
        "time": 1689827723000,
        "value": 139.28,
        "_internal_originalTime": 1689827723000
    },
    {
        "time": 1689827724000,
        "value": 139.281,
        "_internal_originalTime": 1689827724000
    },
    {
        "time": 1689827726000,
        "value": 139.281,
        "_internal_originalTime": 1689827726000
    },
    {
        "time": 1689827727000,
        "value": 139.28,
        "_internal_originalTime": 1689827727000
    },
    {
        "time": 1689827732000,
        "value": 139.28,
        "_internal_originalTime": 1689827732000
    },
    {
        "time": 1689827737000,
        "value": 139.281,
        "_internal_originalTime": 1689827737000
    },
    {
        "time": 1689827738000,
        "value": 139.283,
        "_internal_originalTime": 1689827738000
    },
    {
        "time": 1689827741000,
        "value": 139.284,
        "_internal_originalTime": 1689827741000
    },
    {
        "time": 1689827744000,
        "value": 139.285,
        "_internal_originalTime": 1689827744000
    },
    {
        "time": 1689827745000,
        "value": 139.291,
        "_internal_originalTime": 1689827745000
    },
    {
        "time": 1689827749000,
        "value": 139.286,
        "_internal_originalTime": 1689827749000
    },
    {
        "time": 1689827755000,
        "value": 139.285,
        "_internal_originalTime": 1689827755000
    },
    {
        "time": 1689827761000,
        "value": 139.289,
        "_internal_originalTime": 1689827761000
    },
    {
        "time": 1689827762000,
        "value": 139.284,
        "_internal_originalTime": 1689827762000
    },
    {
        "time": 1689827765000,
        "value": 139.291,
        "_internal_originalTime": 1689827765000
    },
    {
        "time": 1689827769000,
        "value": 139.286,
        "_internal_originalTime": 1689827769000
    },
    {
        "time": 1689827771000,
        "value": 139.302,
        "_internal_originalTime": 1689827771000
    },
    {
        "time": 1689827773000,
        "value": 139.302,
        "_internal_originalTime": 1689827773000
    },
    {
        "time": 1689827774000,
        "value": 139.305,
        "_internal_originalTime": 1689827774000
    },
    {
        "time": 1689827777000,
        "value": 139.305,
        "_internal_originalTime": 1689827777000
    },
    {
        "time": 1689827779000,
        "value": 139.302,
        "_internal_originalTime": 1689827779000
    },
    {
        "time": 1689827780000,
        "value": 139.301,
        "_internal_originalTime": 1689827780000
    },
    {
        "time": 1689827782000,
        "value": 139.299,
        "_internal_originalTime": 1689827782000
    },
    {
        "time": 1689827784000,
        "value": 139.3,
        "_internal_originalTime": 1689827784000
    },
    {
        "time": 1689827785000,
        "value": 139.303,
        "_internal_originalTime": 1689827785000
    },
    {
        "time": 1689827786000,
        "value": 139.301,
        "_internal_originalTime": 1689827786000
    },
    {
        "time": 1689827788000,
        "value": 139.301,
        "_internal_originalTime": 1689827788000
    },
    {
        "time": 1689827789000,
        "value": 139.302,
        "_internal_originalTime": 1689827789000
    },
    {
        "time": 1689827790000,
        "value": 139.299,
        "_internal_originalTime": 1689827790000
    },
    {
        "time": 1689827792000,
        "value": 139.298,
        "_internal_originalTime": 1689827792000
    },
    {
        "time": 1689827794000,
        "value": 139.299,
        "_internal_originalTime": 1689827794000
    },
    {
        "time": 1689827796000,
        "value": 139.301,
        "_internal_originalTime": 1689827796000
    },
    {
        "time": 1689827798000,
        "value": 139.303,
        "_internal_originalTime": 1689827798000
    },
    {
        "time": 1689827799000,
        "value": 139.299,
        "_internal_originalTime": 1689827799000
    },
    {
        "time": 1689827801000,
        "value": 139.3,
        "_internal_originalTime": 1689827801000
    },
    {
        "time": 1689827803000,
        "value": 139.301,
        "_internal_originalTime": 1689827803000
    },
    {
        "time": 1689827806000,
        "value": 139.299,
        "_internal_originalTime": 1689827806000
    },
    {
        "time": 1689827807000,
        "value": 139.298,
        "_internal_originalTime": 1689827807000
    },
    {
        "time": 1689827810000,
        "value": 139.301,
        "_internal_originalTime": 1689827810000
    },
    {
        "time": 1689827812000,
        "value": 139.292,
        "_internal_originalTime": 1689827812000
    },
    {
        "time": 1689827813000,
        "value": 139.291,
        "_internal_originalTime": 1689827813000
    },
    {
        "time": 1689827817000,
        "value": 139.295,
        "_internal_originalTime": 1689827817000
    },
    {
        "time": 1689827819000,
        "value": 139.297,
        "_internal_originalTime": 1689827819000
    },
    {
        "time": 1689827821000,
        "value": 139.296,
        "_internal_originalTime": 1689827821000
    },
    {
        "time": 1689827822000,
        "value": 139.292,
        "_internal_originalTime": 1689827822000
    },
    {
        "time": 1689827823000,
        "value": 139.297,
        "_internal_originalTime": 1689827823000
    },
    {
        "time": 1689827825000,
        "value": 139.294,
        "_internal_originalTime": 1689827825000
    },
    {
        "time": 1689827826000,
        "value": 139.295,
        "_internal_originalTime": 1689827826000
    },
    {
        "time": 1689827828000,
        "value": 139.298,
        "_internal_originalTime": 1689827828000
    },
    {
        "time": 1689827829000,
        "value": 139.299,
        "_internal_originalTime": 1689827829000
    },
    {
        "time": 1689827832000,
        "value": 139.3,
        "_internal_originalTime": 1689827832000
    },
    {
        "time": 1689827833000,
        "value": 139.295,
        "_internal_originalTime": 1689827833000
    },
    {
        "time": 1689827834000,
        "value": 139.295,
        "_internal_originalTime": 1689827834000
    },
    {
        "time": 1689827837000,
        "value": 139.295,
        "_internal_originalTime": 1689827837000
    },
    {
        "time": 1689827838000,
        "value": 139.29,
        "_internal_originalTime": 1689827838000
    },
    {
        "time": 1689827851000,
        "value": 139.291,
        "_internal_originalTime": 1689827851000
    },
    {
        "time": 1689827854000,
        "value": 139.297,
        "_internal_originalTime": 1689827854000
    },
    {
        "time": 1689827855000,
        "value": 139.292,
        "_internal_originalTime": 1689827855000
    },
    {
        "time": 1689827856000,
        "value": 139.296,
        "_internal_originalTime": 1689827856000
    },
    {
        "time": 1689827861000,
        "value": 139.294,
        "_internal_originalTime": 1689827861000
    },
    {
        "time": 1689827865000,
        "value": 139.297,
        "_internal_originalTime": 1689827865000
    },
    {
        "time": 1689827867000,
        "value": 139.29,
        "_internal_originalTime": 1689827867000
    },
    {
        "time": 1689827869000,
        "value": 139.29,
        "_internal_originalTime": 1689827869000
    },
    {
        "time": 1689827871000,
        "value": 139.294,
        "_internal_originalTime": 1689827871000
    },
    {
        "time": 1689827872000,
        "value": 139.295,
        "_internal_originalTime": 1689827872000
    },
    {
        "time": 1689827882000,
        "value": 139.294,
        "_internal_originalTime": 1689827882000
    },
    {
        "time": 1689827883000,
        "value": 139.291,
        "_internal_originalTime": 1689827883000
    },
    {
        "time": 1689827884000,
        "value": 139.289,
        "_internal_originalTime": 1689827884000
    },
    {
        "time": 1689827887000,
        "value": 139.281,
        "_internal_originalTime": 1689827887000
    },
    {
        "time": 1689827888000,
        "value": 139.28,
        "_internal_originalTime": 1689827888000
    },
    {
        "time": 1689827890000,
        "value": 139.282,
        "_internal_originalTime": 1689827890000
    },
    {
        "time": 1689827891000,
        "value": 139.283,
        "_internal_originalTime": 1689827891000
    },
    {
        "time": 1689827895000,
        "value": 139.283,
        "_internal_originalTime": 1689827895000
    },
    {
        "time": 1689827896000,
        "value": 139.281,
        "_internal_originalTime": 1689827896000
    },
    {
        "time": 1689827897000,
        "value": 139.284,
        "_internal_originalTime": 1689827897000
    },
    {
        "time": 1689827900000,
        "value": 139.282,
        "_internal_originalTime": 1689827900000
    },
    {
        "time": 1689827904000,
        "value": 139.278,
        "_internal_originalTime": 1689827904000
    },
    {
        "time": 1689827906000,
        "value": 139.279,
        "_internal_originalTime": 1689827906000
    },
    {
        "time": 1689827908000,
        "value": 139.283,
        "_internal_originalTime": 1689827908000
    },
    {
        "time": 1689827910000,
        "value": 139.282,
        "_internal_originalTime": 1689827910000
    },
    {
        "time": 1689827911000,
        "value": 139.282,
        "_internal_originalTime": 1689827911000
    },
    {
        "time": 1689827913000,
        "value": 139.278,
        "_internal_originalTime": 1689827913000
    },
    {
        "time": 1689827916000,
        "value": 139.278,
        "_internal_originalTime": 1689827916000
    },
    {
        "time": 1689827918000,
        "value": 139.282,
        "_internal_originalTime": 1689827918000
    },
    {
        "time": 1689827919000,
        "value": 139.281,
        "_internal_originalTime": 1689827919000
    },
    {
        "time": 1689827921000,
        "value": 139.281,
        "_internal_originalTime": 1689827921000
    },
    {
        "time": 1689827924000,
        "value": 139.281,
        "_internal_originalTime": 1689827924000
    },
    {
        "time": 1689827926000,
        "value": 139.28,
        "_internal_originalTime": 1689827926000
    },
    {
        "time": 1689827927000,
        "value": 139.278,
        "_internal_originalTime": 1689827927000
    },
    {
        "time": 1689827929000,
        "value": 139.272,
        "_internal_originalTime": 1689827929000
    },
    {
        "time": 1689827931000,
        "value": 139.271,
        "_internal_originalTime": 1689827931000
    },
    {
        "time": 1689827934000,
        "value": 139.277,
        "_internal_originalTime": 1689827934000
    },
    {
        "time": 1689827935000,
        "value": 139.276,
        "_internal_originalTime": 1689827935000
    },
    {
        "time": 1689827938000,
        "value": 139.277,
        "_internal_originalTime": 1689827938000
    },
    {
        "time": 1689827941000,
        "value": 139.277,
        "_internal_originalTime": 1689827941000
    },
    {
        "time": 1689827943000,
        "value": 139.276,
        "_internal_originalTime": 1689827943000
    },
    {
        "time": 1689827945000,
        "value": 139.277,
        "_internal_originalTime": 1689827945000
    },
    {
        "time": 1689827946000,
        "value": 139.271,
        "_internal_originalTime": 1689827946000
    },
    {
        "time": 1689827947000,
        "value": 139.277,
        "_internal_originalTime": 1689827947000
    },
    {
        "time": 1689827950000,
        "value": 139.276,
        "_internal_originalTime": 1689827950000
    },
    {
        "time": 1689827951000,
        "value": 139.279,
        "_internal_originalTime": 1689827951000
    },
    {
        "time": 1689827953000,
        "value": 139.279,
        "_internal_originalTime": 1689827953000
    },
    {
        "time": 1689827954000,
        "value": 139.282,
        "_internal_originalTime": 1689827954000
    },
    {
        "time": 1689827956000,
        "value": 139.279,
        "_internal_originalTime": 1689827956000
    },
    {
        "time": 1689827957000,
        "value": 139.279,
        "_internal_originalTime": 1689827957000
    },
    {
        "time": 1689827961000,
        "value": 139.277,
        "_internal_originalTime": 1689827961000
    },
    {
        "time": 1689827963000,
        "value": 139.281,
        "_internal_originalTime": 1689827963000
    },
    {
        "time": 1689827965000,
        "value": 139.282,
        "_internal_originalTime": 1689827965000
    },
    {
        "time": 1689827967000,
        "value": 139.278,
        "_internal_originalTime": 1689827967000
    },
    {
        "time": 1689827968000,
        "value": 139.283,
        "_internal_originalTime": 1689827968000
    },
    {
        "time": 1689827969000,
        "value": 139.279,
        "_internal_originalTime": 1689827969000
    },
    {
        "time": 1689827970000,
        "value": 139.278,
        "_internal_originalTime": 1689827970000
    },
    {
        "time": 1689827971000,
        "value": 139.279,
        "_internal_originalTime": 1689827971000
    },
    {
        "time": 1689827972000,
        "value": 139.284,
        "_internal_originalTime": 1689827972000
    },
    {
        "time": 1689827973000,
        "value": 139.279,
        "_internal_originalTime": 1689827973000
    },
    {
        "time": 1689827974000,
        "value": 139.283,
        "_internal_originalTime": 1689827974000
    },
    {
        "time": 1689827978000,
        "value": 139.283,
        "_internal_originalTime": 1689827978000
    },
    {
        "time": 1689827979000,
        "value": 139.278,
        "_internal_originalTime": 1689827979000
    },
    {
        "time": 1689827981000,
        "value": 139.28,
        "_internal_originalTime": 1689827981000
    },
    {
        "time": 1689827987000,
        "value": 139.284,
        "_internal_originalTime": 1689827987000
    },
    {
        "time": 1689827988000,
        "value": 139.281,
        "_internal_originalTime": 1689827988000
    },
    {
        "time": 1689827990000,
        "value": 139.282,
        "_internal_originalTime": 1689827990000
    },
    {
        "time": 1689827992000,
        "value": 139.288,
        "_internal_originalTime": 1689827992000
    },
    {
        "time": 1689827994000,
        "value": 139.291,
        "_internal_originalTime": 1689827994000
    },
    {
        "time": 1689827996000,
        "value": 139.287,
        "_internal_originalTime": 1689827996000
    },
    {
        "time": 1689827998000,
        "value": 139.294,
        "_internal_originalTime": 1689827998000
    },
    {
        "time": 1689828000000,
        "value": 139.295,
        "_internal_originalTime": 1689828000000
    },
    {
        "time": 1689828001000,
        "value": 139.294,
        "_internal_originalTime": 1689828001000
    },
    {
        "time": 1689828004000,
        "value": 139.292,
        "_internal_originalTime": 1689828004000
    },
    {
        "time": 1689828005000,
        "value": 139.295,
        "_internal_originalTime": 1689828005000
    },
    {
        "time": 1689828007000,
        "value": 139.29,
        "_internal_originalTime": 1689828007000
    },
    {
        "time": 1689828009000,
        "value": 139.29,
        "_internal_originalTime": 1689828009000
    },
    {
        "time": 1689828010000,
        "value": 139.291,
        "_internal_originalTime": 1689828010000
    },
    {
        "time": 1689828012000,
        "value": 139.295,
        "_internal_originalTime": 1689828012000
    },
    {
        "time": 1689828013000,
        "value": 139.294,
        "_internal_originalTime": 1689828013000
    },
    {
        "time": 1689828014000,
        "value": 139.295,
        "_internal_originalTime": 1689828014000
    },
    {
        "time": 1689828016000,
        "value": 139.292,
        "_internal_originalTime": 1689828016000
    },
    {
        "time": 1689828017000,
        "value": 139.294,
        "_internal_originalTime": 1689828017000
    },
    {
        "time": 1689828019000,
        "value": 139.299,
        "_internal_originalTime": 1689828019000
    },
    {
        "time": 1689828020000,
        "value": 139.298,
        "_internal_originalTime": 1689828020000
    },
    {
        "time": 1689828022000,
        "value": 139.294,
        "_internal_originalTime": 1689828022000
    },
    {
        "time": 1689828024000,
        "value": 139.291,
        "_internal_originalTime": 1689828024000
    },
    {
        "time": 1689828026000,
        "value": 139.294,
        "_internal_originalTime": 1689828026000
    },
    {
        "time": 1689828027000,
        "value": 139.292,
        "_internal_originalTime": 1689828027000
    },
    {
        "time": 1689828030000,
        "value": 139.293,
        "_internal_originalTime": 1689828030000
    },
    {
        "time": 1689828032000,
        "value": 139.287,
        "_internal_originalTime": 1689828032000
    },
    {
        "time": 1689828034000,
        "value": 139.288,
        "_internal_originalTime": 1689828034000
    },
    {
        "time": 1689828036000,
        "value": 139.295,
        "_internal_originalTime": 1689828036000
    },
    {
        "time": 1689828038000,
        "value": 139.29,
        "_internal_originalTime": 1689828038000
    },
    {
        "time": 1689828041000,
        "value": 139.294,
        "_internal_originalTime": 1689828041000
    },
    {
        "time": 1689828042000,
        "value": 139.298,
        "_internal_originalTime": 1689828042000
    },
    {
        "time": 1689828044000,
        "value": 139.29,
        "_internal_originalTime": 1689828044000
    },
    {
        "time": 1689828047000,
        "value": 139.295,
        "_internal_originalTime": 1689828047000
    },
    {
        "time": 1689828049000,
        "value": 139.295,
        "_internal_originalTime": 1689828049000
    },
    {
        "time": 1689828051000,
        "value": 139.29,
        "_internal_originalTime": 1689828051000
    },
    {
        "time": 1689828052000,
        "value": 139.298,
        "_internal_originalTime": 1689828052000
    },
    {
        "time": 1689828054000,
        "value": 139.3,
        "_internal_originalTime": 1689828054000
    },
    {
        "time": 1689828055000,
        "value": 139.294,
        "_internal_originalTime": 1689828055000
    },
    {
        "time": 1689828056000,
        "value": 139.294,
        "_internal_originalTime": 1689828056000
    },
    {
        "time": 1689828057000,
        "value": 139.294,
        "_internal_originalTime": 1689828057000
    },
    {
        "time": 1689828059000,
        "value": 139.294,
        "_internal_originalTime": 1689828059000
    },
    {
        "time": 1689828063000,
        "value": 139.297,
        "_internal_originalTime": 1689828063000
    },
    {
        "time": 1689828066000,
        "value": 139.295,
        "_internal_originalTime": 1689828066000
    },
    {
        "time": 1689828069000,
        "value": 139.298,
        "_internal_originalTime": 1689828069000
    },
    {
        "time": 1689828070000,
        "value": 139.294,
        "_internal_originalTime": 1689828070000
    },
    {
        "time": 1689828072000,
        "value": 139.296,
        "_internal_originalTime": 1689828072000
    },
    {
        "time": 1689828074000,
        "value": 139.308,
        "_internal_originalTime": 1689828074000
    },
    {
        "time": 1689828075000,
        "value": 139.307,
        "_internal_originalTime": 1689828075000
    },
    {
        "time": 1689828078000,
        "value": 139.307,
        "_internal_originalTime": 1689828078000
    },
    {
        "time": 1689828079000,
        "value": 139.302,
        "_internal_originalTime": 1689828079000
    },
    {
        "time": 1689828081000,
        "value": 139.305,
        "_internal_originalTime": 1689828081000
    },
    {
        "time": 1689828082000,
        "value": 139.307,
        "_internal_originalTime": 1689828082000
    },
    {
        "time": 1689828085000,
        "value": 139.307,
        "_internal_originalTime": 1689828085000
    },
    {
        "time": 1689828087000,
        "value": 139.309,
        "_internal_originalTime": 1689828087000
    },
    {
        "time": 1689828091000,
        "value": 139.309,
        "_internal_originalTime": 1689828091000
    },
    {
        "time": 1689828093000,
        "value": 139.309,
        "_internal_originalTime": 1689828093000
    },
    {
        "time": 1689828094000,
        "value": 139.309,
        "_internal_originalTime": 1689828094000
    },
    {
        "time": 1689828095000,
        "value": 139.308,
        "_internal_originalTime": 1689828095000
    },
    {
        "time": 1689828100000,
        "value": 139.308,
        "_internal_originalTime": 1689828100000
    },
    {
        "time": 1689828101000,
        "value": 139.309,
        "_internal_originalTime": 1689828101000
    },
    {
        "time": 1689828103000,
        "value": 139.305,
        "_internal_originalTime": 1689828103000
    },
    {
        "time": 1689828106000,
        "value": 139.304,
        "_internal_originalTime": 1689828106000
    },
    {
        "time": 1689828109000,
        "value": 139.308,
        "_internal_originalTime": 1689828109000
    },
    {
        "time": 1689828114000,
        "value": 139.305,
        "_internal_originalTime": 1689828114000
    },
    {
        "time": 1689828115000,
        "value": 139.314,
        "_internal_originalTime": 1689828115000
    },
    {
        "time": 1689828117000,
        "value": 139.308,
        "_internal_originalTime": 1689828117000
    },
    {
        "time": 1689828118000,
        "value": 139.321,
        "_internal_originalTime": 1689828118000
    },
    {
        "time": 1689828119000,
        "value": 139.318,
        "_internal_originalTime": 1689828119000
    },
    {
        "time": 1689828121000,
        "value": 139.315,
        "_internal_originalTime": 1689828121000
    },
    {
        "time": 1689828122000,
        "value": 139.316,
        "_internal_originalTime": 1689828122000
    },
    {
        "time": 1689828124000,
        "value": 139.313,
        "_internal_originalTime": 1689828124000
    },
    {
        "time": 1689828126000,
        "value": 139.318,
        "_internal_originalTime": 1689828126000
    },
    {
        "time": 1689828127000,
        "value": 139.314,
        "_internal_originalTime": 1689828127000
    },
    {
        "time": 1689828129000,
        "value": 139.313,
        "_internal_originalTime": 1689828129000
    },
    {
        "time": 1689828131000,
        "value": 139.311,
        "_internal_originalTime": 1689828131000
    },
    {
        "time": 1689828133000,
        "value": 139.31,
        "_internal_originalTime": 1689828133000
    },
    {
        "time": 1689828134000,
        "value": 139.316,
        "_internal_originalTime": 1689828134000
    },
    {
        "time": 1689828136000,
        "value": 139.316,
        "_internal_originalTime": 1689828136000
    },
    {
        "time": 1689828137000,
        "value": 139.31,
        "_internal_originalTime": 1689828137000
    },
    {
        "time": 1689828139000,
        "value": 139.312,
        "_internal_originalTime": 1689828139000
    },
    {
        "time": 1689828141000,
        "value": 139.315,
        "_internal_originalTime": 1689828141000
    },
    {
        "time": 1689828142000,
        "value": 139.315,
        "_internal_originalTime": 1689828142000
    },
    {
        "time": 1689828144000,
        "value": 139.316,
        "_internal_originalTime": 1689828144000
    },
    {
        "time": 1689828147000,
        "value": 139.315,
        "_internal_originalTime": 1689828147000
    },
    {
        "time": 1689828150000,
        "value": 139.306,
        "_internal_originalTime": 1689828150000
    },
    {
        "time": 1689828151000,
        "value": 139.305,
        "_internal_originalTime": 1689828151000
    },
    {
        "time": 1689828152000,
        "value": 139.298,
        "_internal_originalTime": 1689828152000
    },
    {
        "time": 1689828155000,
        "value": 139.304,
        "_internal_originalTime": 1689828155000
    },
    {
        "time": 1689828156000,
        "value": 139.303,
        "_internal_originalTime": 1689828156000
    },
    {
        "time": 1689828157000,
        "value": 139.298,
        "_internal_originalTime": 1689828157000
    },
    {
        "time": 1689828161000,
        "value": 139.303,
        "_internal_originalTime": 1689828161000
    },
    {
        "time": 1689828163000,
        "value": 139.308,
        "_internal_originalTime": 1689828163000
    },
    {
        "time": 1689828165000,
        "value": 139.303,
        "_internal_originalTime": 1689828165000
    },
    {
        "time": 1689828166000,
        "value": 139.31,
        "_internal_originalTime": 1689828166000
    },
    {
        "time": 1689828168000,
        "value": 139.314,
        "_internal_originalTime": 1689828168000
    },
    {
        "time": 1689828171000,
        "value": 139.314,
        "_internal_originalTime": 1689828171000
    },
    {
        "time": 1689828174000,
        "value": 139.311,
        "_internal_originalTime": 1689828174000
    },
    {
        "time": 1689828175000,
        "value": 139.311,
        "_internal_originalTime": 1689828175000
    },
    {
        "time": 1689828177000,
        "value": 139.312,
        "_internal_originalTime": 1689828177000
    },
    {
        "time": 1689828178000,
        "value": 139.304,
        "_internal_originalTime": 1689828178000
    },
    {
        "time": 1689828180000,
        "value": 139.303,
        "_internal_originalTime": 1689828180000
    },
    {
        "time": 1689828182000,
        "value": 139.298,
        "_internal_originalTime": 1689828182000
    },
    {
        "time": 1689828183000,
        "value": 139.293,
        "_internal_originalTime": 1689828183000
    },
    {
        "time": 1689828184000,
        "value": 139.292,
        "_internal_originalTime": 1689828184000
    },
    {
        "time": 1689828187000,
        "value": 139.298,
        "_internal_originalTime": 1689828187000
    },
    {
        "time": 1689828190000,
        "value": 139.296,
        "_internal_originalTime": 1689828190000
    },
    {
        "time": 1689828192000,
        "value": 139.295,
        "_internal_originalTime": 1689828192000
    },
    {
        "time": 1689828193000,
        "value": 139.295,
        "_internal_originalTime": 1689828193000
    },
    {
        "time": 1689828194000,
        "value": 139.29,
        "_internal_originalTime": 1689828194000
    },
    {
        "time": 1689828195000,
        "value": 139.29,
        "_internal_originalTime": 1689828195000
    },
    {
        "time": 1689828197000,
        "value": 139.29,
        "_internal_originalTime": 1689828197000
    },
    {
        "time": 1689828201000,
        "value": 139.296,
        "_internal_originalTime": 1689828201000
    },
    {
        "time": 1689828205000,
        "value": 139.298,
        "_internal_originalTime": 1689828205000
    },
    {
        "time": 1689828207000,
        "value": 139.298,
        "_internal_originalTime": 1689828207000
    },
    {
        "time": 1689828209000,
        "value": 139.302,
        "_internal_originalTime": 1689828209000
    },
    {
        "time": 1689828211000,
        "value": 139.304,
        "_internal_originalTime": 1689828211000
    },
    {
        "time": 1689828212000,
        "value": 139.298,
        "_internal_originalTime": 1689828212000
    },
    {
        "time": 1689828215000,
        "value": 139.305,
        "_internal_originalTime": 1689828215000
    },
    {
        "time": 1689828217000,
        "value": 139.306,
        "_internal_originalTime": 1689828217000
    },
    {
        "time": 1689828221000,
        "value": 139.301,
        "_internal_originalTime": 1689828221000
    },
    {
        "time": 1689828222000,
        "value": 139.307,
        "_internal_originalTime": 1689828222000
    },
    {
        "time": 1689828224000,
        "value": 139.304,
        "_internal_originalTime": 1689828224000
    },
    {
        "time": 1689828229000,
        "value": 139.301,
        "_internal_originalTime": 1689828229000
    },
    {
        "time": 1689828230000,
        "value": 139.3,
        "_internal_originalTime": 1689828230000
    },
    {
        "time": 1689828234000,
        "value": 139.317,
        "_internal_originalTime": 1689828234000
    },
    {
        "time": 1689828235000,
        "value": 139.313,
        "_internal_originalTime": 1689828235000
    },
    {
        "time": 1689828236000,
        "value": 139.314,
        "_internal_originalTime": 1689828236000
    },
    {
        "time": 1689828238000,
        "value": 139.319,
        "_internal_originalTime": 1689828238000
    },
    {
        "time": 1689828241000,
        "value": 139.314,
        "_internal_originalTime": 1689828241000
    },
    {
        "time": 1689828242000,
        "value": 139.322,
        "_internal_originalTime": 1689828242000
    },
    {
        "time": 1689828243000,
        "value": 139.318,
        "_internal_originalTime": 1689828243000
    },
    {
        "time": 1689828247000,
        "value": 139.318,
        "_internal_originalTime": 1689828247000
    },
    {
        "time": 1689828250000,
        "value": 139.317,
        "_internal_originalTime": 1689828250000
    },
    {
        "time": 1689828251000,
        "value": 139.314,
        "_internal_originalTime": 1689828251000
    },
    {
        "time": 1689828252000,
        "value": 139.319,
        "_internal_originalTime": 1689828252000
    },
    {
        "time": 1689828256000,
        "value": 139.314,
        "_internal_originalTime": 1689828256000
    },
    {
        "time": 1689828258000,
        "value": 139.31,
        "_internal_originalTime": 1689828258000
    },
    {
        "time": 1689828259000,
        "value": 139.316,
        "_internal_originalTime": 1689828259000
    },
    {
        "time": 1689828261000,
        "value": 139.308,
        "_internal_originalTime": 1689828261000
    },
    {
        "time": 1689828262000,
        "value": 139.313,
        "_internal_originalTime": 1689828262000
    },
    {
        "time": 1689828263000,
        "value": 139.314,
        "_internal_originalTime": 1689828263000
    },
    {
        "time": 1689828269000,
        "value": 139.309,
        "_internal_originalTime": 1689828269000
    },
    {
        "time": 1689828271000,
        "value": 139.314,
        "_internal_originalTime": 1689828271000
    },
    {
        "time": 1689828272000,
        "value": 139.31,
        "_internal_originalTime": 1689828272000
    },
    {
        "time": 1689828273000,
        "value": 139.318,
        "_internal_originalTime": 1689828273000
    },
    {
        "time": 1689828281000,
        "value": 139.315,
        "_internal_originalTime": 1689828281000
    },
    {
        "time": 1689828284000,
        "value": 139.313,
        "_internal_originalTime": 1689828284000
    },
    {
        "time": 1689828285000,
        "value": 139.313,
        "_internal_originalTime": 1689828285000
    },
    {
        "time": 1689828288000,
        "value": 139.313,
        "_internal_originalTime": 1689828288000
    },
    {
        "time": 1689828292000,
        "value": 139.307,
        "_internal_originalTime": 1689828292000
    },
    {
        "time": 1689828293000,
        "value": 139.314,
        "_internal_originalTime": 1689828293000
    },
    {
        "time": 1689828297000,
        "value": 139.31,
        "_internal_originalTime": 1689828297000
    },
    {
        "time": 1689828300000,
        "value": 139.304,
        "_internal_originalTime": 1689828300000
    },
    {
        "time": 1689828301000,
        "value": 139.307,
        "_internal_originalTime": 1689828301000
    },
    {
        "time": 1689828302000,
        "value": 139.303,
        "_internal_originalTime": 1689828302000
    },
    {
        "time": 1689828304000,
        "value": 139.305,
        "_internal_originalTime": 1689828304000
    },
    {
        "time": 1689828305000,
        "value": 139.303,
        "_internal_originalTime": 1689828305000
    },
    {
        "time": 1689828306000,
        "value": 139.302,
        "_internal_originalTime": 1689828306000
    },
    {
        "time": 1689828309000,
        "value": 139.309,
        "_internal_originalTime": 1689828309000
    },
    {
        "time": 1689828310000,
        "value": 139.314,
        "_internal_originalTime": 1689828310000
    },
    {
        "time": 1689828311000,
        "value": 139.311,
        "_internal_originalTime": 1689828311000
    },
    {
        "time": 1689828315000,
        "value": 139.308,
        "_internal_originalTime": 1689828315000
    },
    {
        "time": 1689828317000,
        "value": 139.302,
        "_internal_originalTime": 1689828317000
    },
    {
        "time": 1689828319000,
        "value": 139.298,
        "_internal_originalTime": 1689828319000
    },
    {
        "time": 1689828321000,
        "value": 139.306,
        "_internal_originalTime": 1689828321000
    },
    {
        "time": 1689828322000,
        "value": 139.299,
        "_internal_originalTime": 1689828322000
    },
    {
        "time": 1689828323000,
        "value": 139.298,
        "_internal_originalTime": 1689828323000
    },
    {
        "time": 1689828326000,
        "value": 139.306,
        "_internal_originalTime": 1689828326000
    },
    {
        "time": 1689828327000,
        "value": 139.3,
        "_internal_originalTime": 1689828327000
    },
    {
        "time": 1689828329000,
        "value": 139.3,
        "_internal_originalTime": 1689828329000
    },
    {
        "time": 1689828330000,
        "value": 139.308,
        "_internal_originalTime": 1689828330000
    },
    {
        "time": 1689828332000,
        "value": 139.301,
        "_internal_originalTime": 1689828332000
    },
    {
        "time": 1689828334000,
        "value": 139.306,
        "_internal_originalTime": 1689828334000
    },
    {
        "time": 1689828336000,
        "value": 139.306,
        "_internal_originalTime": 1689828336000
    },
    {
        "time": 1689828338000,
        "value": 139.308,
        "_internal_originalTime": 1689828338000
    },
    {
        "time": 1689828341000,
        "value": 139.304,
        "_internal_originalTime": 1689828341000
    },
    {
        "time": 1689828344000,
        "value": 139.302,
        "_internal_originalTime": 1689828344000
    },
    {
        "time": 1689828347000,
        "value": 139.307,
        "_internal_originalTime": 1689828347000
    },
    {
        "time": 1689828350000,
        "value": 139.308,
        "_internal_originalTime": 1689828350000
    },
    {
        "time": 1689828351000,
        "value": 139.303,
        "_internal_originalTime": 1689828351000
    },
    {
        "time": 1689828353000,
        "value": 139.3,
        "_internal_originalTime": 1689828353000
    },
    {
        "time": 1689828355000,
        "value": 139.307,
        "_internal_originalTime": 1689828355000
    },
    {
        "time": 1689828358000,
        "value": 139.302,
        "_internal_originalTime": 1689828358000
    },
    {
        "time": 1689828363000,
        "value": 139.302,
        "_internal_originalTime": 1689828363000
    },
    {
        "time": 1689828365000,
        "value": 139.313,
        "_internal_originalTime": 1689828365000
    },
    {
        "time": 1689828367000,
        "value": 139.315,
        "_internal_originalTime": 1689828367000
    },
    {
        "time": 1689828368000,
        "value": 139.313,
        "_internal_originalTime": 1689828368000
    },
    {
        "time": 1689828371000,
        "value": 139.308,
        "_internal_originalTime": 1689828371000
    },
    {
        "time": 1689828372000,
        "value": 139.314,
        "_internal_originalTime": 1689828372000
    },
    {
        "time": 1689828373000,
        "value": 139.312,
        "_internal_originalTime": 1689828373000
    },
    {
        "time": 1689828377000,
        "value": 139.315,
        "_internal_originalTime": 1689828377000
    },
    {
        "time": 1689828381000,
        "value": 139.315,
        "_internal_originalTime": 1689828381000
    },
    {
        "time": 1689828382000,
        "value": 139.314,
        "_internal_originalTime": 1689828382000
    },
    {
        "time": 1689828386000,
        "value": 139.309,
        "_internal_originalTime": 1689828386000
    },
    {
        "time": 1689828387000,
        "value": 139.314,
        "_internal_originalTime": 1689828387000
    },
    {
        "time": 1689828390000,
        "value": 139.315,
        "_internal_originalTime": 1689828390000
    },
    {
        "time": 1689828392000,
        "value": 139.305,
        "_internal_originalTime": 1689828392000
    },
    {
        "time": 1689828397000,
        "value": 139.313,
        "_internal_originalTime": 1689828397000
    },
    {
        "time": 1689828398000,
        "value": 139.308,
        "_internal_originalTime": 1689828398000
    },
    {
        "time": 1689828401000,
        "value": 139.31,
        "_internal_originalTime": 1689828401000
    },
    {
        "time": 1689828403000,
        "value": 139.315,
        "_internal_originalTime": 1689828403000
    },
    {
        "time": 1689828404000,
        "value": 139.309,
        "_internal_originalTime": 1689828404000
    },
    {
        "time": 1689828405000,
        "value": 139.313,
        "_internal_originalTime": 1689828405000
    },
    {
        "time": 1689828411000,
        "value": 139.308,
        "_internal_originalTime": 1689828411000
    },
    {
        "time": 1689828412000,
        "value": 139.308,
        "_internal_originalTime": 1689828412000
    },
    {
        "time": 1689828414000,
        "value": 139.317,
        "_internal_originalTime": 1689828414000
    },
    {
        "time": 1689828416000,
        "value": 139.31,
        "_internal_originalTime": 1689828416000
    },
    {
        "time": 1689828419000,
        "value": 139.314,
        "_internal_originalTime": 1689828419000
    },
    {
        "time": 1689828422000,
        "value": 139.311,
        "_internal_originalTime": 1689828422000
    },
    {
        "time": 1689828423000,
        "value": 139.32,
        "_internal_originalTime": 1689828423000
    },
    {
        "time": 1689828430000,
        "value": 139.32,
        "_internal_originalTime": 1689828430000
    },
    {
        "time": 1689828431000,
        "value": 139.317,
        "_internal_originalTime": 1689828431000
    },
    {
        "time": 1689828433000,
        "value": 139.321,
        "_internal_originalTime": 1689828433000
    },
    {
        "time": 1689828434000,
        "value": 139.32,
        "_internal_originalTime": 1689828434000
    },
    {
        "time": 1689828438000,
        "value": 139.321,
        "_internal_originalTime": 1689828438000
    },
    {
        "time": 1689828440000,
        "value": 139.32,
        "_internal_originalTime": 1689828440000
    },
    {
        "time": 1689828443000,
        "value": 139.321,
        "_internal_originalTime": 1689828443000
    },
    {
        "time": 1689828445000,
        "value": 139.321,
        "_internal_originalTime": 1689828445000
    },
    {
        "time": 1689828448000,
        "value": 139.322,
        "_internal_originalTime": 1689828448000
    },
    {
        "time": 1689828449000,
        "value": 139.324,
        "_internal_originalTime": 1689828449000
    },
    {
        "time": 1689828451000,
        "value": 139.323,
        "_internal_originalTime": 1689828451000
    },
    {
        "time": 1689828452000,
        "value": 139.319,
        "_internal_originalTime": 1689828452000
    },
    {
        "time": 1689828455000,
        "value": 139.324,
        "_internal_originalTime": 1689828455000
    },
    {
        "time": 1689828461000,
        "value": 139.323,
        "_internal_originalTime": 1689828461000
    },
    {
        "time": 1689828462000,
        "value": 139.323,
        "_internal_originalTime": 1689828462000
    },
    {
        "time": 1689828465000,
        "value": 139.324,
        "_internal_originalTime": 1689828465000
    },
    {
        "time": 1689828469000,
        "value": 139.323,
        "_internal_originalTime": 1689828469000
    },
    {
        "time": 1689828472000,
        "value": 139.324,
        "_internal_originalTime": 1689828472000
    },
    {
        "time": 1689828476000,
        "value": 139.323,
        "_internal_originalTime": 1689828476000
    },
    {
        "time": 1689828479000,
        "value": 139.317,
        "_internal_originalTime": 1689828479000
    },
    {
        "time": 1689828481000,
        "value": 139.318,
        "_internal_originalTime": 1689828481000
    },
    {
        "time": 1689828482000,
        "value": 139.317,
        "_internal_originalTime": 1689828482000
    },
    {
        "time": 1689828484000,
        "value": 139.326,
        "_internal_originalTime": 1689828484000
    },
    {
        "time": 1689828485000,
        "value": 139.318,
        "_internal_originalTime": 1689828485000
    },
    {
        "time": 1689828487000,
        "value": 139.325,
        "_internal_originalTime": 1689828487000
    },
    {
        "time": 1689828489000,
        "value": 139.318,
        "_internal_originalTime": 1689828489000
    },
    {
        "time": 1689828491000,
        "value": 139.319,
        "_internal_originalTime": 1689828491000
    },
    {
        "time": 1689828492000,
        "value": 139.318,
        "_internal_originalTime": 1689828492000
    },
    {
        "time": 1689828495000,
        "value": 139.321,
        "_internal_originalTime": 1689828495000
    },
    {
        "time": 1689828496000,
        "value": 139.326,
        "_internal_originalTime": 1689828496000
    },
    {
        "time": 1689828498000,
        "value": 139.324,
        "_internal_originalTime": 1689828498000
    },
    {
        "time": 1689828499000,
        "value": 139.319,
        "_internal_originalTime": 1689828499000
    },
    {
        "time": 1689828500000,
        "value": 139.318,
        "_internal_originalTime": 1689828500000
    },
    {
        "time": 1689828501000,
        "value": 139.319,
        "_internal_originalTime": 1689828501000
    },
    {
        "time": 1689828504000,
        "value": 139.323,
        "_internal_originalTime": 1689828504000
    },
    {
        "time": 1689828505000,
        "value": 139.324,
        "_internal_originalTime": 1689828505000
    },
    {
        "time": 1689828507000,
        "value": 139.321,
        "_internal_originalTime": 1689828507000
    },
    {
        "time": 1689828510000,
        "value": 139.32,
        "_internal_originalTime": 1689828510000
    },
    {
        "time": 1689828511000,
        "value": 139.325,
        "_internal_originalTime": 1689828511000
    },
    {
        "time": 1689828513000,
        "value": 139.319,
        "_internal_originalTime": 1689828513000
    },
    {
        "time": 1689828515000,
        "value": 139.322,
        "_internal_originalTime": 1689828515000
    },
    {
        "time": 1689828516000,
        "value": 139.316,
        "_internal_originalTime": 1689828516000
    },
    {
        "time": 1689828518000,
        "value": 139.315,
        "_internal_originalTime": 1689828518000
    },
    {
        "time": 1689828521000,
        "value": 139.311,
        "_internal_originalTime": 1689828521000
    },
    {
        "time": 1689828524000,
        "value": 139.31,
        "_internal_originalTime": 1689828524000
    },
    {
        "time": 1689828525000,
        "value": 139.311,
        "_internal_originalTime": 1689828525000
    },
    {
        "time": 1689828526000,
        "value": 139.309,
        "_internal_originalTime": 1689828526000
    },
    {
        "time": 1689828532000,
        "value": 139.312,
        "_internal_originalTime": 1689828532000
    },
    {
        "time": 1689828533000,
        "value": 139.313,
        "_internal_originalTime": 1689828533000
    },
    {
        "time": 1689828534000,
        "value": 139.313,
        "_internal_originalTime": 1689828534000
    },
    {
        "time": 1689828537000,
        "value": 139.311,
        "_internal_originalTime": 1689828537000
    },
    {
        "time": 1689828539000,
        "value": 139.307,
        "_internal_originalTime": 1689828539000
    },
    {
        "time": 1689828541000,
        "value": 139.313,
        "_internal_originalTime": 1689828541000
    },
    {
        "time": 1689828542000,
        "value": 139.307,
        "_internal_originalTime": 1689828542000
    },
    {
        "time": 1689828544000,
        "value": 139.312,
        "_internal_originalTime": 1689828544000
    },
    {
        "time": 1689828545000,
        "value": 139.313,
        "_internal_originalTime": 1689828545000
    },
    {
        "time": 1689828546000,
        "value": 139.312,
        "_internal_originalTime": 1689828546000
    },
    {
        "time": 1689828549000,
        "value": 139.304,
        "_internal_originalTime": 1689828549000
    },
    {
        "time": 1689828551000,
        "value": 139.3,
        "_internal_originalTime": 1689828551000
    },
    {
        "time": 1689828552000,
        "value": 139.306,
        "_internal_originalTime": 1689828552000
    },
    {
        "time": 1689828553000,
        "value": 139.304,
        "_internal_originalTime": 1689828553000
    },
    {
        "time": 1689828555000,
        "value": 139.3,
        "_internal_originalTime": 1689828555000
    },
    {
        "time": 1689828557000,
        "value": 139.3,
        "_internal_originalTime": 1689828557000
    },
    {
        "time": 1689828558000,
        "value": 139.299,
        "_internal_originalTime": 1689828558000
    },
    {
        "time": 1689828564000,
        "value": 139.304,
        "_internal_originalTime": 1689828564000
    },
    {
        "time": 1689828568000,
        "value": 139.3,
        "_internal_originalTime": 1689828568000
    },
    {
        "time": 1689828573000,
        "value": 139.298,
        "_internal_originalTime": 1689828573000
    },
    {
        "time": 1689828575000,
        "value": 139.31,
        "_internal_originalTime": 1689828575000
    },
    {
        "time": 1689828577000,
        "value": 139.309,
        "_internal_originalTime": 1689828577000
    },
    {
        "time": 1689828579000,
        "value": 139.306,
        "_internal_originalTime": 1689828579000
    },
    {
        "time": 1689828581000,
        "value": 139.311,
        "_internal_originalTime": 1689828581000
    },
    {
        "time": 1689828583000,
        "value": 139.313,
        "_internal_originalTime": 1689828583000
    },
    {
        "time": 1689828584000,
        "value": 139.316,
        "_internal_originalTime": 1689828584000
    },
    {
        "time": 1689828585000,
        "value": 139.318,
        "_internal_originalTime": 1689828585000
    },
    {
        "time": 1689828588000,
        "value": 139.319,
        "_internal_originalTime": 1689828588000
    },
    {
        "time": 1689828593000,
        "value": 139.318,
        "_internal_originalTime": 1689828593000
    },
    {
        "time": 1689828596000,
        "value": 139.314,
        "_internal_originalTime": 1689828596000
    },
    {
        "time": 1689828598000,
        "value": 139.313,
        "_internal_originalTime": 1689828598000
    },
    {
        "time": 1689828599000,
        "value": 139.321,
        "_internal_originalTime": 1689828599000
    },
    {
        "time": 1689828600000,
        "value": 139.319,
        "_internal_originalTime": 1689828600000
    },
    {
        "time": 1689828604000,
        "value": 139.312,
        "_internal_originalTime": 1689828604000
    },
    {
        "time": 1689828606000,
        "value": 139.322,
        "_internal_originalTime": 1689828606000
    },
    {
        "time": 1689828609000,
        "value": 139.325,
        "_internal_originalTime": 1689828609000
    },
    {
        "time": 1689828611000,
        "value": 139.32,
        "_internal_originalTime": 1689828611000
    },
    {
        "time": 1689828612000,
        "value": 139.321,
        "_internal_originalTime": 1689828612000
    },
    {
        "time": 1689828614000,
        "value": 139.32,
        "_internal_originalTime": 1689828614000
    },
    {
        "time": 1689828615000,
        "value": 139.327,
        "_internal_originalTime": 1689828615000
    },
    {
        "time": 1689828617000,
        "value": 139.332,
        "_internal_originalTime": 1689828617000
    },
    {
        "time": 1689828619000,
        "value": 139.326,
        "_internal_originalTime": 1689828619000
    },
    {
        "time": 1689828621000,
        "value": 139.325,
        "_internal_originalTime": 1689828621000
    },
    {
        "time": 1689828622000,
        "value": 139.321,
        "_internal_originalTime": 1689828622000
    },
    {
        "time": 1689828624000,
        "value": 139.32,
        "_internal_originalTime": 1689828624000
    },
    {
        "time": 1689828626000,
        "value": 139.323,
        "_internal_originalTime": 1689828626000
    },
    {
        "time": 1689828628000,
        "value": 139.319,
        "_internal_originalTime": 1689828628000
    },
    {
        "time": 1689828630000,
        "value": 139.319,
        "_internal_originalTime": 1689828630000
    },
    {
        "time": 1689828631000,
        "value": 139.323,
        "_internal_originalTime": 1689828631000
    },
    {
        "time": 1689828632000,
        "value": 139.321,
        "_internal_originalTime": 1689828632000
    },
    {
        "time": 1689828634000,
        "value": 139.335,
        "_internal_originalTime": 1689828634000
    },
    {
        "time": 1689828635000,
        "value": 139.327,
        "_internal_originalTime": 1689828635000
    },
    {
        "time": 1689828637000,
        "value": 139.32,
        "_internal_originalTime": 1689828637000
    },
    {
        "time": 1689828638000,
        "value": 139.329,
        "_internal_originalTime": 1689828638000
    },
    {
        "time": 1689828640000,
        "value": 139.336,
        "_internal_originalTime": 1689828640000
    },
    {
        "time": 1689828641000,
        "value": 139.336,
        "_internal_originalTime": 1689828641000
    },
    {
        "time": 1689828644000,
        "value": 139.33,
        "_internal_originalTime": 1689828644000
    },
    {
        "time": 1689828645000,
        "value": 139.33,
        "_internal_originalTime": 1689828645000
    },
    {
        "time": 1689828647000,
        "value": 139.325,
        "_internal_originalTime": 1689828647000
    },
    {
        "time": 1689828650000,
        "value": 139.33,
        "_internal_originalTime": 1689828650000
    },
    {
        "time": 1689828651000,
        "value": 139.329,
        "_internal_originalTime": 1689828651000
    },
    {
        "time": 1689828653000,
        "value": 139.328,
        "_internal_originalTime": 1689828653000
    },
    {
        "time": 1689828654000,
        "value": 139.329,
        "_internal_originalTime": 1689828654000
    },
    {
        "time": 1689828656000,
        "value": 139.33,
        "_internal_originalTime": 1689828656000
    },
    {
        "time": 1689828658000,
        "value": 139.334,
        "_internal_originalTime": 1689828658000
    },
    {
        "time": 1689828659000,
        "value": 139.334,
        "_internal_originalTime": 1689828659000
    },
    {
        "time": 1689828661000,
        "value": 139.331,
        "_internal_originalTime": 1689828661000
    },
    {
        "time": 1689828663000,
        "value": 139.327,
        "_internal_originalTime": 1689828663000
    },
    {
        "time": 1689828664000,
        "value": 139.33,
        "_internal_originalTime": 1689828664000
    },
    {
        "time": 1689828666000,
        "value": 139.333,
        "_internal_originalTime": 1689828666000
    },
    {
        "time": 1689828668000,
        "value": 139.332,
        "_internal_originalTime": 1689828668000
    },
    {
        "time": 1689828669000,
        "value": 139.326,
        "_internal_originalTime": 1689828669000
    },
    {
        "time": 1689828671000,
        "value": 139.327,
        "_internal_originalTime": 1689828671000
    },
    {
        "time": 1689828673000,
        "value": 139.335,
        "_internal_originalTime": 1689828673000
    },
    {
        "time": 1689828674000,
        "value": 139.342,
        "_internal_originalTime": 1689828674000
    },
    {
        "time": 1689828676000,
        "value": 139.34,
        "_internal_originalTime": 1689828676000
    },
    {
        "time": 1689828677000,
        "value": 139.338,
        "_internal_originalTime": 1689828677000
    },
    {
        "time": 1689828680000,
        "value": 139.337,
        "_internal_originalTime": 1689828680000
    },
    {
        "time": 1689828681000,
        "value": 139.336,
        "_internal_originalTime": 1689828681000
    },
    {
        "time": 1689828683000,
        "value": 139.34,
        "_internal_originalTime": 1689828683000
    },
    {
        "time": 1689828685000,
        "value": 139.33,
        "_internal_originalTime": 1689828685000
    },
    {
        "time": 1689828687000,
        "value": 139.331,
        "_internal_originalTime": 1689828687000
    },
    {
        "time": 1689828688000,
        "value": 139.329,
        "_internal_originalTime": 1689828688000
    },
    {
        "time": 1689828690000,
        "value": 139.331,
        "_internal_originalTime": 1689828690000
    },
    {
        "time": 1689828692000,
        "value": 139.326,
        "_internal_originalTime": 1689828692000
    },
    {
        "time": 1689828694000,
        "value": 139.328,
        "_internal_originalTime": 1689828694000
    },
    {
        "time": 1689828696000,
        "value": 139.329,
        "_internal_originalTime": 1689828696000
    },
    {
        "time": 1689828697000,
        "value": 139.331,
        "_internal_originalTime": 1689828697000
    },
    {
        "time": 1689828698000,
        "value": 139.335,
        "_internal_originalTime": 1689828698000
    },
    {
        "time": 1689828700000,
        "value": 139.335,
        "_internal_originalTime": 1689828700000
    },
    {
        "time": 1689828701000,
        "value": 139.329,
        "_internal_originalTime": 1689828701000
    },
    {
        "time": 1689828704000,
        "value": 139.334,
        "_internal_originalTime": 1689828704000
    },
    {
        "time": 1689828705000,
        "value": 139.334,
        "_internal_originalTime": 1689828705000
    },
    {
        "time": 1689828707000,
        "value": 139.333,
        "_internal_originalTime": 1689828707000
    },
    {
        "time": 1689828708000,
        "value": 139.338,
        "_internal_originalTime": 1689828708000
    },
    {
        "time": 1689828710000,
        "value": 139.337,
        "_internal_originalTime": 1689828710000
    },
    {
        "time": 1689828713000,
        "value": 139.338,
        "_internal_originalTime": 1689828713000
    },
    {
        "time": 1689828714000,
        "value": 139.336,
        "_internal_originalTime": 1689828714000
    },
    {
        "time": 1689828717000,
        "value": 139.334,
        "_internal_originalTime": 1689828717000
    },
    {
        "time": 1689828721000,
        "value": 139.333,
        "_internal_originalTime": 1689828721000
    },
    {
        "time": 1689828722000,
        "value": 139.327,
        "_internal_originalTime": 1689828722000
    },
    {
        "time": 1689828726000,
        "value": 139.331,
        "_internal_originalTime": 1689828726000
    },
    {
        "time": 1689828728000,
        "value": 139.328,
        "_internal_originalTime": 1689828728000
    },
    {
        "time": 1689828732000,
        "value": 139.331,
        "_internal_originalTime": 1689828732000
    },
    {
        "time": 1689828733000,
        "value": 139.334,
        "_internal_originalTime": 1689828733000
    },
    {
        "time": 1689828734000,
        "value": 139.333,
        "_internal_originalTime": 1689828734000
    },
    {
        "time": 1689828738000,
        "value": 139.334,
        "_internal_originalTime": 1689828738000
    },
    {
        "time": 1689828742000,
        "value": 139.331,
        "_internal_originalTime": 1689828742000
    },
    {
        "time": 1689828744000,
        "value": 139.331,
        "_internal_originalTime": 1689828744000
    },
    {
        "time": 1689828745000,
        "value": 139.33,
        "_internal_originalTime": 1689828745000
    },
    {
        "time": 1689828748000,
        "value": 139.332,
        "_internal_originalTime": 1689828748000
    },
    {
        "time": 1689828749000,
        "value": 139.335,
        "_internal_originalTime": 1689828749000
    },
    {
        "time": 1689828750000,
        "value": 139.331,
        "_internal_originalTime": 1689828750000
    },
    {
        "time": 1689828752000,
        "value": 139.332,
        "_internal_originalTime": 1689828752000
    },
    {
        "time": 1689828753000,
        "value": 139.336,
        "_internal_originalTime": 1689828753000
    },
    {
        "time": 1689828755000,
        "value": 139.338,
        "_internal_originalTime": 1689828755000
    },
    {
        "time": 1689828756000,
        "value": 139.334,
        "_internal_originalTime": 1689828756000
    },
    {
        "time": 1689828757000,
        "value": 139.338,
        "_internal_originalTime": 1689828757000
    },
    {
        "time": 1689828759000,
        "value": 139.332,
        "_internal_originalTime": 1689828759000
    },
    {
        "time": 1689828761000,
        "value": 139.333,
        "_internal_originalTime": 1689828761000
    },
    {
        "time": 1689828764000,
        "value": 139.337,
        "_internal_originalTime": 1689828764000
    },
    {
        "time": 1689828765000,
        "value": 139.338,
        "_internal_originalTime": 1689828765000
    },
    {
        "time": 1689828766000,
        "value": 139.338,
        "_internal_originalTime": 1689828766000
    },
    {
        "time": 1689828770000,
        "value": 139.336,
        "_internal_originalTime": 1689828770000
    },
    {
        "time": 1689828773000,
        "value": 139.337,
        "_internal_originalTime": 1689828773000
    },
    {
        "time": 1689828774000,
        "value": 139.332,
        "_internal_originalTime": 1689828774000
    },
    {
        "time": 1689828776000,
        "value": 139.335,
        "_internal_originalTime": 1689828776000
    },
    {
        "time": 1689828779000,
        "value": 139.335,
        "_internal_originalTime": 1689828779000
    },
    {
        "time": 1689828781000,
        "value": 139.333,
        "_internal_originalTime": 1689828781000
    },
    {
        "time": 1689828782000,
        "value": 139.339,
        "_internal_originalTime": 1689828782000
    },
    {
        "time": 1689828784000,
        "value": 139.34,
        "_internal_originalTime": 1689828784000
    },
    {
        "time": 1689828786000,
        "value": 139.336,
        "_internal_originalTime": 1689828786000
    },
    {
        "time": 1689828787000,
        "value": 139.34,
        "_internal_originalTime": 1689828787000
    },
    {
        "time": 1689828788000,
        "value": 139.341,
        "_internal_originalTime": 1689828788000
    },
    {
        "time": 1689828791000,
        "value": 139.343,
        "_internal_originalTime": 1689828791000
    },
    {
        "time": 1689828792000,
        "value": 139.342,
        "_internal_originalTime": 1689828792000
    },
    {
        "time": 1689828794000,
        "value": 139.355,
        "_internal_originalTime": 1689828794000
    },
    {
        "time": 1689828795000,
        "value": 139.35,
        "_internal_originalTime": 1689828795000
    },
    {
        "time": 1689828797000,
        "value": 139.354,
        "_internal_originalTime": 1689828797000
    },
    {
        "time": 1689828799000,
        "value": 139.348,
        "_internal_originalTime": 1689828799000
    },
    {
        "time": 1689828801000,
        "value": 139.354,
        "_internal_originalTime": 1689828801000
    },
    {
        "time": 1689828802000,
        "value": 139.342,
        "_internal_originalTime": 1689828802000
    },
    {
        "time": 1689828804000,
        "value": 139.342,
        "_internal_originalTime": 1689828804000
    },
    {
        "time": 1689828806000,
        "value": 139.35,
        "_internal_originalTime": 1689828806000
    },
    {
        "time": 1689828808000,
        "value": 139.349,
        "_internal_originalTime": 1689828808000
    },
    {
        "time": 1689828810000,
        "value": 139.346,
        "_internal_originalTime": 1689828810000
    },
    {
        "time": 1689828812000,
        "value": 139.342,
        "_internal_originalTime": 1689828812000
    },
    {
        "time": 1689828813000,
        "value": 139.34,
        "_internal_originalTime": 1689828813000
    },
    {
        "time": 1689828814000,
        "value": 139.342,
        "_internal_originalTime": 1689828814000
    },
    {
        "time": 1689828816000,
        "value": 139.346,
        "_internal_originalTime": 1689828816000
    },
    {
        "time": 1689828817000,
        "value": 139.347,
        "_internal_originalTime": 1689828817000
    },
    {
        "time": 1689828820000,
        "value": 139.354,
        "_internal_originalTime": 1689828820000
    },
    {
        "time": 1689828822000,
        "value": 139.349,
        "_internal_originalTime": 1689828822000
    },
    {
        "time": 1689828823000,
        "value": 139.349,
        "_internal_originalTime": 1689828823000
    },
    {
        "time": 1689828824000,
        "value": 139.353,
        "_internal_originalTime": 1689828824000
    },
    {
        "time": 1689828826000,
        "value": 139.349,
        "_internal_originalTime": 1689828826000
    },
    {
        "time": 1689828831000,
        "value": 139.353,
        "_internal_originalTime": 1689828831000
    },
    {
        "time": 1689828836000,
        "value": 139.352,
        "_internal_originalTime": 1689828836000
    },
    {
        "time": 1689828838000,
        "value": 139.349,
        "_internal_originalTime": 1689828838000
    },
    {
        "time": 1689828840000,
        "value": 139.349,
        "_internal_originalTime": 1689828840000
    },
    {
        "time": 1689828843000,
        "value": 139.353,
        "_internal_originalTime": 1689828843000
    },
    {
        "time": 1689828844000,
        "value": 139.365,
        "_internal_originalTime": 1689828844000
    },
    {
        "time": 1689828846000,
        "value": 139.358,
        "_internal_originalTime": 1689828846000
    },
    {
        "time": 1689828847000,
        "value": 139.358,
        "_internal_originalTime": 1689828847000
    },
    {
        "time": 1689828849000,
        "value": 139.359,
        "_internal_originalTime": 1689828849000
    },
    {
        "time": 1689828851000,
        "value": 139.358,
        "_internal_originalTime": 1689828851000
    },
    {
        "time": 1689828853000,
        "value": 139.364,
        "_internal_originalTime": 1689828853000
    },
    {
        "time": 1689828854000,
        "value": 139.362,
        "_internal_originalTime": 1689828854000
    },
    {
        "time": 1689828855000,
        "value": 139.362,
        "_internal_originalTime": 1689828855000
    },
    {
        "time": 1689828856000,
        "value": 139.361,
        "_internal_originalTime": 1689828856000
    },
    {
        "time": 1689828859000,
        "value": 139.36,
        "_internal_originalTime": 1689828859000
    },
    {
        "time": 1689828860000,
        "value": 139.368,
        "_internal_originalTime": 1689828860000
    },
    {
        "time": 1689828861000,
        "value": 139.367,
        "_internal_originalTime": 1689828861000
    },
    {
        "time": 1689828864000,
        "value": 139.368,
        "_internal_originalTime": 1689828864000
    },
    {
        "time": 1689828865000,
        "value": 139.371,
        "_internal_originalTime": 1689828865000
    },
    {
        "time": 1689828867000,
        "value": 139.375,
        "_internal_originalTime": 1689828867000
    },
    {
        "time": 1689828868000,
        "value": 139.374,
        "_internal_originalTime": 1689828868000
    },
    {
        "time": 1689828870000,
        "value": 139.376,
        "_internal_originalTime": 1689828870000
    },
    {
        "time": 1689828871000,
        "value": 139.373,
        "_internal_originalTime": 1689828871000
    },
    {
        "time": 1689828873000,
        "value": 139.37,
        "_internal_originalTime": 1689828873000
    },
    {
        "time": 1689828875000,
        "value": 139.371,
        "_internal_originalTime": 1689828875000
    },
    {
        "time": 1689828876000,
        "value": 139.37,
        "_internal_originalTime": 1689828876000
    },
    {
        "time": 1689828881000,
        "value": 139.371,
        "_internal_originalTime": 1689828881000
    },
    {
        "time": 1689828885000,
        "value": 139.371,
        "_internal_originalTime": 1689828885000
    },
    {
        "time": 1689828886000,
        "value": 139.37,
        "_internal_originalTime": 1689828886000
    },
    {
        "time": 1689828889000,
        "value": 139.371,
        "_internal_originalTime": 1689828889000
    },
    {
        "time": 1689828891000,
        "value": 139.369,
        "_internal_originalTime": 1689828891000
    },
    {
        "time": 1689828894000,
        "value": 139.368,
        "_internal_originalTime": 1689828894000
    },
    {
        "time": 1689828895000,
        "value": 139.368,
        "_internal_originalTime": 1689828895000
    },
    {
        "time": 1689828896000,
        "value": 139.373,
        "_internal_originalTime": 1689828896000
    },
    {
        "time": 1689828903000,
        "value": 139.367,
        "_internal_originalTime": 1689828903000
    },
    {
        "time": 1689828904000,
        "value": 139.36,
        "_internal_originalTime": 1689828904000
    },
    {
        "time": 1689828905000,
        "value": 139.359,
        "_internal_originalTime": 1689828905000
    },
    {
        "time": 1689828907000,
        "value": 139.356,
        "_internal_originalTime": 1689828907000
    },
    {
        "time": 1689828909000,
        "value": 139.359,
        "_internal_originalTime": 1689828909000
    },
    {
        "time": 1689828910000,
        "value": 139.36,
        "_internal_originalTime": 1689828910000
    },
    {
        "time": 1689828912000,
        "value": 139.361,
        "_internal_originalTime": 1689828912000
    },
    {
        "time": 1689828913000,
        "value": 139.356,
        "_internal_originalTime": 1689828913000
    },
    {
        "time": 1689828915000,
        "value": 139.36,
        "_internal_originalTime": 1689828915000
    },
    {
        "time": 1689828917000,
        "value": 139.363,
        "_internal_originalTime": 1689828917000
    },
    {
        "time": 1689828918000,
        "value": 139.362,
        "_internal_originalTime": 1689828918000
    },
    {
        "time": 1689828921000,
        "value": 139.363,
        "_internal_originalTime": 1689828921000
    },
    {
        "time": 1689828923000,
        "value": 139.359,
        "_internal_originalTime": 1689828923000
    },
    {
        "time": 1689828925000,
        "value": 139.364,
        "_internal_originalTime": 1689828925000
    },
    {
        "time": 1689828926000,
        "value": 139.363,
        "_internal_originalTime": 1689828926000
    },
    {
        "time": 1689828927000,
        "value": 139.363,
        "_internal_originalTime": 1689828927000
    },
    {
        "time": 1689828930000,
        "value": 139.356,
        "_internal_originalTime": 1689828930000
    },
    {
        "time": 1689828931000,
        "value": 139.358,
        "_internal_originalTime": 1689828931000
    },
    {
        "time": 1689828932000,
        "value": 139.354,
        "_internal_originalTime": 1689828932000
    },
    {
        "time": 1689828934000,
        "value": 139.362,
        "_internal_originalTime": 1689828934000
    },
    {
        "time": 1689828941000,
        "value": 139.361,
        "_internal_originalTime": 1689828941000
    },
    {
        "time": 1689828943000,
        "value": 139.363,
        "_internal_originalTime": 1689828943000
    },
    {
        "time": 1689828946000,
        "value": 139.358,
        "_internal_originalTime": 1689828946000
    },
    {
        "time": 1689828949000,
        "value": 139.358,
        "_internal_originalTime": 1689828949000
    },
    {
        "time": 1689828951000,
        "value": 139.359,
        "_internal_originalTime": 1689828951000
    },
    {
        "time": 1689828952000,
        "value": 139.37,
        "_internal_originalTime": 1689828952000
    },
    {
        "time": 1689828953000,
        "value": 139.373,
        "_internal_originalTime": 1689828953000
    },
    {
        "time": 1689828956000,
        "value": 139.371,
        "_internal_originalTime": 1689828956000
    },
    {
        "time": 1689828957000,
        "value": 139.368,
        "_internal_originalTime": 1689828957000
    },
    {
        "time": 1689828960000,
        "value": 139.368,
        "_internal_originalTime": 1689828960000
    },
    {
        "time": 1689828961000,
        "value": 139.369,
        "_internal_originalTime": 1689828961000
    },
    {
        "time": 1689828963000,
        "value": 139.369,
        "_internal_originalTime": 1689828963000
    },
    {
        "time": 1689828966000,
        "value": 139.372,
        "_internal_originalTime": 1689828966000
    },
    {
        "time": 1689828968000,
        "value": 139.373,
        "_internal_originalTime": 1689828968000
    },
    {
        "time": 1689828970000,
        "value": 139.371,
        "_internal_originalTime": 1689828970000
    },
    {
        "time": 1689828971000,
        "value": 139.369,
        "_internal_originalTime": 1689828971000
    },
    {
        "time": 1689828975000,
        "value": 139.368,
        "_internal_originalTime": 1689828975000
    },
    {
        "time": 1689828977000,
        "value": 139.373,
        "_internal_originalTime": 1689828977000
    },
    {
        "time": 1689828981000,
        "value": 139.371,
        "_internal_originalTime": 1689828981000
    },
    {
        "time": 1689828982000,
        "value": 139.37,
        "_internal_originalTime": 1689828982000
    },
    {
        "time": 1689828983000,
        "value": 139.37,
        "_internal_originalTime": 1689828983000
    },
    {
        "time": 1689828986000,
        "value": 139.367,
        "_internal_originalTime": 1689828986000
    },
    {
        "time": 1689828987000,
        "value": 139.373,
        "_internal_originalTime": 1689828987000
    },
    {
        "time": 1689828988000,
        "value": 139.372,
        "_internal_originalTime": 1689828988000
    },
    {
        "time": 1689828990000,
        "value": 139.368,
        "_internal_originalTime": 1689828990000
    },
    {
        "time": 1689828992000,
        "value": 139.371,
        "_internal_originalTime": 1689828992000
    },
    {
        "time": 1689828993000,
        "value": 139.37,
        "_internal_originalTime": 1689828993000
    },
    {
        "time": 1689828995000,
        "value": 139.369,
        "_internal_originalTime": 1689828995000
    },
    {
        "time": 1689828997000,
        "value": 139.372,
        "_internal_originalTime": 1689828997000
    },
    {
        "time": 1689829001000,
        "value": 139.368,
        "_internal_originalTime": 1689829001000
    },
    {
        "time": 1689829002000,
        "value": 139.368,
        "_internal_originalTime": 1689829002000
    },
    {
        "time": 1689829004000,
        "value": 139.369,
        "_internal_originalTime": 1689829004000
    },
    {
        "time": 1689829006000,
        "value": 139.373,
        "_internal_originalTime": 1689829006000
    },
    {
        "time": 1689829011000,
        "value": 139.38,
        "_internal_originalTime": 1689829011000
    },
    {
        "time": 1689829012000,
        "value": 139.376,
        "_internal_originalTime": 1689829012000
    },
    {
        "time": 1689829015000,
        "value": 139.376,
        "_internal_originalTime": 1689829015000
    },
    {
        "time": 1689829019000,
        "value": 139.377,
        "_internal_originalTime": 1689829019000
    },
    {
        "time": 1689829020000,
        "value": 139.378,
        "_internal_originalTime": 1689829020000
    },
    {
        "time": 1689829023000,
        "value": 139.376,
        "_internal_originalTime": 1689829023000
    },
    {
        "time": 1689829025000,
        "value": 139.379,
        "_internal_originalTime": 1689829025000
    },
    {
        "time": 1689829027000,
        "value": 139.379,
        "_internal_originalTime": 1689829027000
    },
    {
        "time": 1689829029000,
        "value": 139.378,
        "_internal_originalTime": 1689829029000
    },
    {
        "time": 1689829032000,
        "value": 139.371,
        "_internal_originalTime": 1689829032000
    },
    {
        "time": 1689829033000,
        "value": 139.367,
        "_internal_originalTime": 1689829033000
    },
    {
        "time": 1689829035000,
        "value": 139.367,
        "_internal_originalTime": 1689829035000
    },
    {
        "time": 1689829036000,
        "value": 139.368,
        "_internal_originalTime": 1689829036000
    },
    {
        "time": 1689829037000,
        "value": 139.364,
        "_internal_originalTime": 1689829037000
    },
    {
        "time": 1689829038000,
        "value": 139.366,
        "_internal_originalTime": 1689829038000
    },
    {
        "time": 1689829041000,
        "value": 139.364,
        "_internal_originalTime": 1689829041000
    },
    {
        "time": 1689829044000,
        "value": 139.364,
        "_internal_originalTime": 1689829044000
    },
    {
        "time": 1689829046000,
        "value": 139.36,
        "_internal_originalTime": 1689829046000
    },
    {
        "time": 1689829049000,
        "value": 139.364,
        "_internal_originalTime": 1689829049000
    },
    {
        "time": 1689829051000,
        "value": 139.363,
        "_internal_originalTime": 1689829051000
    },
    {
        "time": 1689829053000,
        "value": 139.357,
        "_internal_originalTime": 1689829053000
    },
    {
        "time": 1689829056000,
        "value": 139.371,
        "_internal_originalTime": 1689829056000
    },
    {
        "time": 1689829057000,
        "value": 139.373,
        "_internal_originalTime": 1689829057000
    },
    {
        "time": 1689829058000,
        "value": 139.366,
        "_internal_originalTime": 1689829058000
    },
    {
        "time": 1689829060000,
        "value": 139.368,
        "_internal_originalTime": 1689829060000
    },
    {
        "time": 1689829061000,
        "value": 139.364,
        "_internal_originalTime": 1689829061000
    },
    {
        "time": 1689829064000,
        "value": 139.368,
        "_internal_originalTime": 1689829064000
    },
    {
        "time": 1689829067000,
        "value": 139.366,
        "_internal_originalTime": 1689829067000
    },
    {
        "time": 1689829068000,
        "value": 139.37,
        "_internal_originalTime": 1689829068000
    },
    {
        "time": 1689829070000,
        "value": 139.368,
        "_internal_originalTime": 1689829070000
    },
    {
        "time": 1689829071000,
        "value": 139.367,
        "_internal_originalTime": 1689829071000
    },
    {
        "time": 1689829073000,
        "value": 139.372,
        "_internal_originalTime": 1689829073000
    },
    {
        "time": 1689829074000,
        "value": 139.368,
        "_internal_originalTime": 1689829074000
    },
    {
        "time": 1689829076000,
        "value": 139.374,
        "_internal_originalTime": 1689829076000
    },
    {
        "time": 1689829078000,
        "value": 139.372,
        "_internal_originalTime": 1689829078000
    },
    {
        "time": 1689829079000,
        "value": 139.369,
        "_internal_originalTime": 1689829079000
    },
    {
        "time": 1689829081000,
        "value": 139.368,
        "_internal_originalTime": 1689829081000
    },
    {
        "time": 1689829083000,
        "value": 139.374,
        "_internal_originalTime": 1689829083000
    },
    {
        "time": 1689829084000,
        "value": 139.372,
        "_internal_originalTime": 1689829084000
    },
    {
        "time": 1689829087000,
        "value": 139.369,
        "_internal_originalTime": 1689829087000
    },
    {
        "time": 1689829089000,
        "value": 139.371,
        "_internal_originalTime": 1689829089000
    },
    {
        "time": 1689829090000,
        "value": 139.374,
        "_internal_originalTime": 1689829090000
    },
    {
        "time": 1689829092000,
        "value": 139.371,
        "_internal_originalTime": 1689829092000
    },
    {
        "time": 1689829094000,
        "value": 139.369,
        "_internal_originalTime": 1689829094000
    },
    {
        "time": 1689829096000,
        "value": 139.367,
        "_internal_originalTime": 1689829096000
    },
    {
        "time": 1689829098000,
        "value": 139.369,
        "_internal_originalTime": 1689829098000
    },
    {
        "time": 1689829100000,
        "value": 139.371,
        "_internal_originalTime": 1689829100000
    },
    {
        "time": 1689829101000,
        "value": 139.371,
        "_internal_originalTime": 1689829101000
    },
    {
        "time": 1689829103000,
        "value": 139.368,
        "_internal_originalTime": 1689829103000
    },
    {
        "time": 1689829104000,
        "value": 139.368,
        "_internal_originalTime": 1689829104000
    },
    {
        "time": 1689829107000,
        "value": 139.367,
        "_internal_originalTime": 1689829107000
    },
    {
        "time": 1689829108000,
        "value": 139.371,
        "_internal_originalTime": 1689829108000
    },
    {
        "time": 1689829111000,
        "value": 139.368,
        "_internal_originalTime": 1689829111000
    },
    {
        "time": 1689829113000,
        "value": 139.371,
        "_internal_originalTime": 1689829113000
    },
    {
        "time": 1689829114000,
        "value": 139.367,
        "_internal_originalTime": 1689829114000
    },
    {
        "time": 1689829115000,
        "value": 139.367,
        "_internal_originalTime": 1689829115000
    },
    {
        "time": 1689829118000,
        "value": 139.365,
        "_internal_originalTime": 1689829118000
    },
    {
        "time": 1689829119000,
        "value": 139.367,
        "_internal_originalTime": 1689829119000
    },
    {
        "time": 1689829121000,
        "value": 139.366,
        "_internal_originalTime": 1689829121000
    },
    {
        "time": 1689829123000,
        "value": 139.373,
        "_internal_originalTime": 1689829123000
    },
    {
        "time": 1689829124000,
        "value": 139.372,
        "_internal_originalTime": 1689829124000
    },
    {
        "time": 1689829125000,
        "value": 139.373,
        "_internal_originalTime": 1689829125000
    },
    {
        "time": 1689829127000,
        "value": 139.377,
        "_internal_originalTime": 1689829127000
    },
    {
        "time": 1689829129000,
        "value": 139.376,
        "_internal_originalTime": 1689829129000
    },
    {
        "time": 1689829130000,
        "value": 139.371,
        "_internal_originalTime": 1689829130000
    },
    {
        "time": 1689829132000,
        "value": 139.372,
        "_internal_originalTime": 1689829132000
    },
    {
        "time": 1689829133000,
        "value": 139.378,
        "_internal_originalTime": 1689829133000
    },
    {
        "time": 1689829134000,
        "value": 139.373,
        "_internal_originalTime": 1689829134000
    },
    {
        "time": 1689829136000,
        "value": 139.376,
        "_internal_originalTime": 1689829136000
    },
    {
        "time": 1689829139000,
        "value": 139.378,
        "_internal_originalTime": 1689829139000
    },
    {
        "time": 1689829141000,
        "value": 139.379,
        "_internal_originalTime": 1689829141000
    },
    {
        "time": 1689829143000,
        "value": 139.378,
        "_internal_originalTime": 1689829143000
    },
    {
        "time": 1689829144000,
        "value": 139.379,
        "_internal_originalTime": 1689829144000
    },
    {
        "time": 1689829148000,
        "value": 139.374,
        "_internal_originalTime": 1689829148000
    },
    {
        "time": 1689829150000,
        "value": 139.38,
        "_internal_originalTime": 1689829150000
    },
    {
        "time": 1689829152000,
        "value": 139.378,
        "_internal_originalTime": 1689829152000
    },
    {
        "time": 1689829153000,
        "value": 139.371,
        "_internal_originalTime": 1689829153000
    },
    {
        "time": 1689829155000,
        "value": 139.373,
        "_internal_originalTime": 1689829155000
    },
    {
        "time": 1689829156000,
        "value": 139.369,
        "_internal_originalTime": 1689829156000
    },
    {
        "time": 1689829157000,
        "value": 139.376,
        "_internal_originalTime": 1689829157000
    },
    {
        "time": 1689829159000,
        "value": 139.375,
        "_internal_originalTime": 1689829159000
    },
    {
        "time": 1689829167000,
        "value": 139.37,
        "_internal_originalTime": 1689829167000
    },
    {
        "time": 1689829169000,
        "value": 139.373,
        "_internal_originalTime": 1689829169000
    },
    {
        "time": 1689829170000,
        "value": 139.369,
        "_internal_originalTime": 1689829170000
    },
    {
        "time": 1689829173000,
        "value": 139.367,
        "_internal_originalTime": 1689829173000
    },
    {
        "time": 1689829176000,
        "value": 139.373,
        "_internal_originalTime": 1689829176000
    },
    {
        "time": 1689829177000,
        "value": 139.367,
        "_internal_originalTime": 1689829177000
    },
    {
        "time": 1689829178000,
        "value": 139.369,
        "_internal_originalTime": 1689829178000
    },
    {
        "time": 1689829181000,
        "value": 139.371,
        "_internal_originalTime": 1689829181000
    },
    {
        "time": 1689829182000,
        "value": 139.374,
        "_internal_originalTime": 1689829182000
    },
    {
        "time": 1689829184000,
        "value": 139.368,
        "_internal_originalTime": 1689829184000
    },
    {
        "time": 1689829186000,
        "value": 139.368,
        "_internal_originalTime": 1689829186000
    },
    {
        "time": 1689829187000,
        "value": 139.369,
        "_internal_originalTime": 1689829187000
    },
    {
        "time": 1689829190000,
        "value": 139.366,
        "_internal_originalTime": 1689829190000
    },
    {
        "time": 1689829191000,
        "value": 139.369,
        "_internal_originalTime": 1689829191000
    },
    {
        "time": 1689829193000,
        "value": 139.369,
        "_internal_originalTime": 1689829193000
    },
    {
        "time": 1689829195000,
        "value": 139.368,
        "_internal_originalTime": 1689829195000
    },
    {
        "time": 1689829197000,
        "value": 139.372,
        "_internal_originalTime": 1689829197000
    },
    {
        "time": 1689829199000,
        "value": 139.375,
        "_internal_originalTime": 1689829199000
    },
    {
        "time": 1689829201000,
        "value": 139.374,
        "_internal_originalTime": 1689829201000
    },
    {
        "time": 1689829202000,
        "value": 139.373,
        "_internal_originalTime": 1689829202000
    },
    {
        "time": 1689829204000,
        "value": 139.371,
        "_internal_originalTime": 1689829204000
    },
    {
        "time": 1689829206000,
        "value": 139.373,
        "_internal_originalTime": 1689829206000
    },
    {
        "time": 1689829207000,
        "value": 139.379,
        "_internal_originalTime": 1689829207000
    },
    {
        "time": 1689829208000,
        "value": 139.379,
        "_internal_originalTime": 1689829208000
    },
    {
        "time": 1689829210000,
        "value": 139.376,
        "_internal_originalTime": 1689829210000
    },
    {
        "time": 1689829212000,
        "value": 139.384,
        "_internal_originalTime": 1689829212000
    },
    {
        "time": 1689829213000,
        "value": 139.385,
        "_internal_originalTime": 1689829213000
    },
    {
        "time": 1689829216000,
        "value": 139.387,
        "_internal_originalTime": 1689829216000
    },
    {
        "time": 1689829218000,
        "value": 139.384,
        "_internal_originalTime": 1689829218000
    },
    {
        "time": 1689829220000,
        "value": 139.379,
        "_internal_originalTime": 1689829220000
    },
    {
        "time": 1689829221000,
        "value": 139.383,
        "_internal_originalTime": 1689829221000
    },
    {
        "time": 1689829223000,
        "value": 139.382,
        "_internal_originalTime": 1689829223000
    },
    {
        "time": 1689829225000,
        "value": 139.378,
        "_internal_originalTime": 1689829225000
    },
    {
        "time": 1689829228000,
        "value": 139.376,
        "_internal_originalTime": 1689829228000
    },
    {
        "time": 1689829229000,
        "value": 139.366,
        "_internal_originalTime": 1689829229000
    },
    {
        "time": 1689829230000,
        "value": 139.368,
        "_internal_originalTime": 1689829230000
    },
    {
        "time": 1689829232000,
        "value": 139.364,
        "_internal_originalTime": 1689829232000
    },
    {
        "time": 1689829234000,
        "value": 139.354,
        "_internal_originalTime": 1689829234000
    },
    {
        "time": 1689829235000,
        "value": 139.359,
        "_internal_originalTime": 1689829235000
    },
    {
        "time": 1689829236000,
        "value": 139.359,
        "_internal_originalTime": 1689829236000
    },
    {
        "time": 1689829238000,
        "value": 139.363,
        "_internal_originalTime": 1689829238000
    },
    {
        "time": 1689829240000,
        "value": 139.365,
        "_internal_originalTime": 1689829240000
    },
    {
        "time": 1689829241000,
        "value": 139.364,
        "_internal_originalTime": 1689829241000
    },
    {
        "time": 1689829242000,
        "value": 139.365,
        "_internal_originalTime": 1689829242000
    },
    {
        "time": 1689829245000,
        "value": 139.364,
        "_internal_originalTime": 1689829245000
    },
    {
        "time": 1689829246000,
        "value": 139.369,
        "_internal_originalTime": 1689829246000
    },
    {
        "time": 1689829248000,
        "value": 139.364,
        "_internal_originalTime": 1689829248000
    },
    {
        "time": 1689829250000,
        "value": 139.361,
        "_internal_originalTime": 1689829250000
    },
    {
        "time": 1689829251000,
        "value": 139.364,
        "_internal_originalTime": 1689829251000
    },
    {
        "time": 1689829252000,
        "value": 139.366,
        "_internal_originalTime": 1689829252000
    },
    {
        "time": 1689829256000,
        "value": 139.365,
        "_internal_originalTime": 1689829256000
    },
    {
        "time": 1689829257000,
        "value": 139.368,
        "_internal_originalTime": 1689829257000
    },
    {
        "time": 1689829258000,
        "value": 139.369,
        "_internal_originalTime": 1689829258000
    },
    {
        "time": 1689829259000,
        "value": 139.368,
        "_internal_originalTime": 1689829259000
    },
    {
        "time": 1689829261000,
        "value": 139.365,
        "_internal_originalTime": 1689829261000
    },
    {
        "time": 1689829262000,
        "value": 139.366,
        "_internal_originalTime": 1689829262000
    },
    {
        "time": 1689829264000,
        "value": 139.366,
        "_internal_originalTime": 1689829264000
    },
    {
        "time": 1689829266000,
        "value": 139.36,
        "_internal_originalTime": 1689829266000
    },
    {
        "time": 1689829267000,
        "value": 139.361,
        "_internal_originalTime": 1689829267000
    },
    {
        "time": 1689829273000,
        "value": 139.358,
        "_internal_originalTime": 1689829273000
    },
    {
        "time": 1689829274000,
        "value": 139.364,
        "_internal_originalTime": 1689829274000
    },
    {
        "time": 1689829275000,
        "value": 139.359,
        "_internal_originalTime": 1689829275000
    },
    {
        "time": 1689829277000,
        "value": 139.359,
        "_internal_originalTime": 1689829277000
    },
    {
        "time": 1689829278000,
        "value": 139.358,
        "_internal_originalTime": 1689829278000
    },
    {
        "time": 1689829280000,
        "value": 139.363,
        "_internal_originalTime": 1689829280000
    },
    {
        "time": 1689829281000,
        "value": 139.362,
        "_internal_originalTime": 1689829281000
    },
    {
        "time": 1689829316000,
        "value": 139.37,
        "_internal_originalTime": 1689829316000
    },
    {
        "time": 1689829317000,
        "value": 139.371,
        "_internal_originalTime": 1689829317000
    },
    {
        "time": 1689829319000,
        "value": 139.366,
        "_internal_originalTime": 1689829319000
    },
    {
        "time": 1689829321000,
        "value": 139.367,
        "_internal_originalTime": 1689829321000
    },
    {
        "time": 1689829323000,
        "value": 139.366,
        "_internal_originalTime": 1689829323000
    },
    {
        "time": 1689829325000,
        "value": 139.366,
        "_internal_originalTime": 1689829325000
    },
    {
        "time": 1689829328000,
        "value": 139.369,
        "_internal_originalTime": 1689829328000
    },
    {
        "time": 1689829330000,
        "value": 139.36,
        "_internal_originalTime": 1689829330000
    },
    {
        "time": 1689829331000,
        "value": 139.356,
        "_internal_originalTime": 1689829331000
    },
    {
        "time": 1689829332000,
        "value": 139.36,
        "_internal_originalTime": 1689829332000
    },
    {
        "time": 1689829333000,
        "value": 139.356,
        "_internal_originalTime": 1689829333000
    },
    {
        "time": 1689829335000,
        "value": 139.36,
        "_internal_originalTime": 1689829335000
    },
    {
        "time": 1689829340000,
        "value": 139.356,
        "_internal_originalTime": 1689829340000
    },
    {
        "time": 1689829342000,
        "value": 139.356,
        "_internal_originalTime": 1689829342000
    },
    {
        "time": 1689829344000,
        "value": 139.354,
        "_internal_originalTime": 1689829344000
    },
    {
        "time": 1689829346000,
        "value": 139.36,
        "_internal_originalTime": 1689829346000
    },
    {
        "time": 1689829347000,
        "value": 139.356,
        "_internal_originalTime": 1689829347000
    },
    {
        "time": 1689829349000,
        "value": 139.361,
        "_internal_originalTime": 1689829349000
    },
    {
        "time": 1689829350000,
        "value": 139.36,
        "_internal_originalTime": 1689829350000
    },
    {
        "time": 1689829351000,
        "value": 139.361,
        "_internal_originalTime": 1689829351000
    },
    {
        "time": 1689829352000,
        "value": 139.36,
        "_internal_originalTime": 1689829352000
    },
    {
        "time": 1689829353000,
        "value": 139.36,
        "_internal_originalTime": 1689829353000
    },
    {
        "time": 1689829354000,
        "value": 139.356,
        "_internal_originalTime": 1689829354000
    },
    {
        "time": 1689829356000,
        "value": 139.36,
        "_internal_originalTime": 1689829356000
    },
    {
        "time": 1689829358000,
        "value": 139.361,
        "_internal_originalTime": 1689829358000
    },
    {
        "time": 1689829360000,
        "value": 139.362,
        "_internal_originalTime": 1689829360000
    },
    {
        "time": 1689829361000,
        "value": 139.358,
        "_internal_originalTime": 1689829361000
    },
    {
        "time": 1689829363000,
        "value": 139.356,
        "_internal_originalTime": 1689829363000
    },
    {
        "time": 1689829364000,
        "value": 139.36,
        "_internal_originalTime": 1689829364000
    },
    {
        "time": 1689829365000,
        "value": 139.356,
        "_internal_originalTime": 1689829365000
    },
    {
        "time": 1689829367000,
        "value": 139.356,
        "_internal_originalTime": 1689829367000
    },
    {
        "time": 1689829370000,
        "value": 139.36,
        "_internal_originalTime": 1689829370000
    },
    {
        "time": 1689829372000,
        "value": 139.356,
        "_internal_originalTime": 1689829372000
    },
    {
        "time": 1689829374000,
        "value": 139.355,
        "_internal_originalTime": 1689829374000
    },
    {
        "time": 1689829376000,
        "value": 139.356,
        "_internal_originalTime": 1689829376000
    },
    {
        "time": 1689829379000,
        "value": 139.357,
        "_internal_originalTime": 1689829379000
    },
    {
        "time": 1689829380000,
        "value": 139.369,
        "_internal_originalTime": 1689829380000
    },
    {
        "time": 1689829381000,
        "value": 139.364,
        "_internal_originalTime": 1689829381000
    },
    {
        "time": 1689829383000,
        "value": 139.361,
        "_internal_originalTime": 1689829383000
    },
    {
        "time": 1689829384000,
        "value": 139.362,
        "_internal_originalTime": 1689829384000
    },
    {
        "time": 1689829386000,
        "value": 139.358,
        "_internal_originalTime": 1689829386000
    },
    {
        "time": 1689829390000,
        "value": 139.363,
        "_internal_originalTime": 1689829390000
    },
    {
        "time": 1689829391000,
        "value": 139.361,
        "_internal_originalTime": 1689829391000
    },
    {
        "time": 1689829393000,
        "value": 139.358,
        "_internal_originalTime": 1689829393000
    },
    {
        "time": 1689829394000,
        "value": 139.359,
        "_internal_originalTime": 1689829394000
    },
    {
        "time": 1689829396000,
        "value": 139.354,
        "_internal_originalTime": 1689829396000
    },
    {
        "time": 1689829399000,
        "value": 139.349,
        "_internal_originalTime": 1689829399000
    },
    {
        "time": 1689829401000,
        "value": 139.35,
        "_internal_originalTime": 1689829401000
    },
    {
        "time": 1689829402000,
        "value": 139.344,
        "_internal_originalTime": 1689829402000
    },
    {
        "time": 1689829404000,
        "value": 139.344,
        "_internal_originalTime": 1689829404000
    },
    {
        "time": 1689829406000,
        "value": 139.339,
        "_internal_originalTime": 1689829406000
    },
    {
        "time": 1689829410000,
        "value": 139.343,
        "_internal_originalTime": 1689829410000
    },
    {
        "time": 1689829412000,
        "value": 139.338,
        "_internal_originalTime": 1689829412000
    },
    {
        "time": 1689829414000,
        "value": 139.339,
        "_internal_originalTime": 1689829414000
    },
    {
        "time": 1689829415000,
        "value": 139.347,
        "_internal_originalTime": 1689829415000
    },
    {
        "time": 1689829417000,
        "value": 139.352,
        "_internal_originalTime": 1689829417000
    },
    {
        "time": 1689829418000,
        "value": 139.351,
        "_internal_originalTime": 1689829418000
    },
    {
        "time": 1689829420000,
        "value": 139.355,
        "_internal_originalTime": 1689829420000
    },
    {
        "time": 1689829421000,
        "value": 139.356,
        "_internal_originalTime": 1689829421000
    },
    {
        "time": 1689829422000,
        "value": 139.355,
        "_internal_originalTime": 1689829422000
    },
    {
        "time": 1689829427000,
        "value": 139.356,
        "_internal_originalTime": 1689829427000
    },
    {
        "time": 1689829428000,
        "value": 139.354,
        "_internal_originalTime": 1689829428000
    },
    {
        "time": 1689829430000,
        "value": 139.351,
        "_internal_originalTime": 1689829430000
    },
    {
        "time": 1689829432000,
        "value": 139.348,
        "_internal_originalTime": 1689829432000
    },
    {
        "time": 1689829433000,
        "value": 139.351,
        "_internal_originalTime": 1689829433000
    },
    {
        "time": 1689829434000,
        "value": 139.351,
        "_internal_originalTime": 1689829434000
    },
    {
        "time": 1689829436000,
        "value": 139.346,
        "_internal_originalTime": 1689829436000
    },
    {
        "time": 1689829438000,
        "value": 139.35,
        "_internal_originalTime": 1689829438000
    },
    {
        "time": 1689829439000,
        "value": 139.349,
        "_internal_originalTime": 1689829439000
    },
    {
        "time": 1689829440000,
        "value": 139.349,
        "_internal_originalTime": 1689829440000
    },
    {
        "time": 1689829441000,
        "value": 139.349,
        "_internal_originalTime": 1689829441000
    },
    {
        "time": 1689829442000,
        "value": 139.346,
        "_internal_originalTime": 1689829442000
    },
    {
        "time": 1689829443000,
        "value": 139.343,
        "_internal_originalTime": 1689829443000
    },
    {
        "time": 1689829446000,
        "value": 139.349,
        "_internal_originalTime": 1689829446000
    },
    {
        "time": 1689829447000,
        "value": 139.351,
        "_internal_originalTime": 1689829447000
    },
    {
        "time": 1689829449000,
        "value": 139.351,
        "_internal_originalTime": 1689829449000
    },
    {
        "time": 1689829452000,
        "value": 139.352,
        "_internal_originalTime": 1689829452000
    },
    {
        "time": 1689829453000,
        "value": 139.354,
        "_internal_originalTime": 1689829453000
    },
    {
        "time": 1689829454000,
        "value": 139.353,
        "_internal_originalTime": 1689829454000
    },
    {
        "time": 1689829456000,
        "value": 139.352,
        "_internal_originalTime": 1689829456000
    },
    {
        "time": 1689829458000,
        "value": 139.35,
        "_internal_originalTime": 1689829458000
    },
    {
        "time": 1689829460000,
        "value": 139.345,
        "_internal_originalTime": 1689829460000
    },
    {
        "time": 1689829463000,
        "value": 139.345,
        "_internal_originalTime": 1689829463000
    },
    {
        "time": 1689829465000,
        "value": 139.35,
        "_internal_originalTime": 1689829465000
    },
    {
        "time": 1689829467000,
        "value": 139.349,
        "_internal_originalTime": 1689829467000
    },
    {
        "time": 1689829469000,
        "value": 139.349,
        "_internal_originalTime": 1689829469000
    },
    {
        "time": 1689829470000,
        "value": 139.344,
        "_internal_originalTime": 1689829470000
    },
    {
        "time": 1689829471000,
        "value": 139.351,
        "_internal_originalTime": 1689829471000
    },
    {
        "time": 1689829473000,
        "value": 139.344,
        "_internal_originalTime": 1689829473000
    },
    {
        "time": 1689829474000,
        "value": 139.349,
        "_internal_originalTime": 1689829474000
    },
    {
        "time": 1689829476000,
        "value": 139.35,
        "_internal_originalTime": 1689829476000
    },
    {
        "time": 1689829478000,
        "value": 139.344,
        "_internal_originalTime": 1689829478000
    },
    {
        "time": 1689829479000,
        "value": 139.349,
        "_internal_originalTime": 1689829479000
    },
    {
        "time": 1689829481000,
        "value": 139.348,
        "_internal_originalTime": 1689829481000
    },
    {
        "time": 1689829482000,
        "value": 139.353,
        "_internal_originalTime": 1689829482000
    },
    {
        "time": 1689829484000,
        "value": 139.347,
        "_internal_originalTime": 1689829484000
    },
    {
        "time": 1689829486000,
        "value": 139.352,
        "_internal_originalTime": 1689829486000
    },
    {
        "time": 1689829487000,
        "value": 139.347,
        "_internal_originalTime": 1689829487000
    },
    {
        "time": 1689829489000,
        "value": 139.35,
        "_internal_originalTime": 1689829489000
    },
    {
        "time": 1689829491000,
        "value": 139.351,
        "_internal_originalTime": 1689829491000
    },
    {
        "time": 1689829492000,
        "value": 139.351,
        "_internal_originalTime": 1689829492000
    },
    {
        "time": 1689829494000,
        "value": 139.347,
        "_internal_originalTime": 1689829494000
    },
    {
        "time": 1689829495000,
        "value": 139.353,
        "_internal_originalTime": 1689829495000
    },
    {
        "time": 1689829497000,
        "value": 139.348,
        "_internal_originalTime": 1689829497000
    },
    {
        "time": 1689829499000,
        "value": 139.347,
        "_internal_originalTime": 1689829499000
    },
    {
        "time": 1689829500000,
        "value": 139.353,
        "_internal_originalTime": 1689829500000
    },
    {
        "time": 1689829501000,
        "value": 139.347,
        "_internal_originalTime": 1689829501000
    },
    {
        "time": 1689829504000,
        "value": 139.35,
        "_internal_originalTime": 1689829504000
    },
    {
        "time": 1689829505000,
        "value": 139.346,
        "_internal_originalTime": 1689829505000
    },
    {
        "time": 1689829507000,
        "value": 139.35,
        "_internal_originalTime": 1689829507000
    },
    {
        "time": 1689829509000,
        "value": 139.35,
        "_internal_originalTime": 1689829509000
    },
    {
        "time": 1689829510000,
        "value": 139.351,
        "_internal_originalTime": 1689829510000
    },
    {
        "time": 1689829512000,
        "value": 139.353,
        "_internal_originalTime": 1689829512000
    },
    {
        "time": 1689829514000,
        "value": 139.351,
        "_internal_originalTime": 1689829514000
    },
    {
        "time": 1689829515000,
        "value": 139.351,
        "_internal_originalTime": 1689829515000
    },
    {
        "time": 1689829516000,
        "value": 139.353,
        "_internal_originalTime": 1689829516000
    },
    {
        "time": 1689829518000,
        "value": 139.35,
        "_internal_originalTime": 1689829518000
    },
    {
        "time": 1689829519000,
        "value": 139.351,
        "_internal_originalTime": 1689829519000
    },
    {
        "time": 1689829521000,
        "value": 139.351,
        "_internal_originalTime": 1689829521000
    },
    {
        "time": 1689829527000,
        "value": 139.351,
        "_internal_originalTime": 1689829527000
    },
    {
        "time": 1689829528000,
        "value": 139.346,
        "_internal_originalTime": 1689829528000
    },
    {
        "time": 1689829529000,
        "value": 139.352,
        "_internal_originalTime": 1689829529000
    },
    {
        "time": 1689829531000,
        "value": 139.351,
        "_internal_originalTime": 1689829531000
    },
    {
        "time": 1689829533000,
        "value": 139.354,
        "_internal_originalTime": 1689829533000
    },
    {
        "time": 1689829535000,
        "value": 139.362,
        "_internal_originalTime": 1689829535000
    },
    {
        "time": 1689829536000,
        "value": 139.364,
        "_internal_originalTime": 1689829536000
    },
    {
        "time": 1689829538000,
        "value": 139.358,
        "_internal_originalTime": 1689829538000
    },
    {
        "time": 1689829541000,
        "value": 139.361,
        "_internal_originalTime": 1689829541000
    },
    {
        "time": 1689829543000,
        "value": 139.359,
        "_internal_originalTime": 1689829543000
    },
    {
        "time": 1689829544000,
        "value": 139.362,
        "_internal_originalTime": 1689829544000
    },
    {
        "time": 1689829545000,
        "value": 139.362,
        "_internal_originalTime": 1689829545000
    },
    {
        "time": 1689829546000,
        "value": 139.363,
        "_internal_originalTime": 1689829546000
    },
    {
        "time": 1689829549000,
        "value": 139.363,
        "_internal_originalTime": 1689829549000
    },
    {
        "time": 1689829551000,
        "value": 139.363,
        "_internal_originalTime": 1689829551000
    },
    {
        "time": 1689829552000,
        "value": 139.36,
        "_internal_originalTime": 1689829552000
    },
    {
        "time": 1689829553000,
        "value": 139.36,
        "_internal_originalTime": 1689829553000
    },
    {
        "time": 1689829555000,
        "value": 139.363,
        "_internal_originalTime": 1689829555000
    },
    {
        "time": 1689829556000,
        "value": 139.359,
        "_internal_originalTime": 1689829556000
    },
    {
        "time": 1689829558000,
        "value": 139.365,
        "_internal_originalTime": 1689829558000
    },
    {
        "time": 1689829559000,
        "value": 139.364,
        "_internal_originalTime": 1689829559000
    },
    {
        "time": 1689829560000,
        "value": 139.363,
        "_internal_originalTime": 1689829560000
    },
    {
        "time": 1689829561000,
        "value": 139.364,
        "_internal_originalTime": 1689829561000
    },
    {
        "time": 1689829563000,
        "value": 139.363,
        "_internal_originalTime": 1689829563000
    },
    {
        "time": 1689829565000,
        "value": 139.358,
        "_internal_originalTime": 1689829565000
    },
    {
        "time": 1689829567000,
        "value": 139.363,
        "_internal_originalTime": 1689829567000
    },
    {
        "time": 1689829568000,
        "value": 139.364,
        "_internal_originalTime": 1689829568000
    },
    {
        "time": 1689829570000,
        "value": 139.365,
        "_internal_originalTime": 1689829570000
    },
    {
        "time": 1689829572000,
        "value": 139.359,
        "_internal_originalTime": 1689829572000
    },
    {
        "time": 1689829573000,
        "value": 139.358,
        "_internal_originalTime": 1689829573000
    },
    {
        "time": 1689829574000,
        "value": 139.363,
        "_internal_originalTime": 1689829574000
    },
    {
        "time": 1689829578000,
        "value": 139.359,
        "_internal_originalTime": 1689829578000
    },
    {
        "time": 1689829579000,
        "value": 139.363,
        "_internal_originalTime": 1689829579000
    },
    {
        "time": 1689829581000,
        "value": 139.359,
        "_internal_originalTime": 1689829581000
    },
    {
        "time": 1689829582000,
        "value": 139.363,
        "_internal_originalTime": 1689829582000
    },
    {
        "time": 1689829584000,
        "value": 139.362,
        "_internal_originalTime": 1689829584000
    },
    {
        "time": 1689829585000,
        "value": 139.358,
        "_internal_originalTime": 1689829585000
    },
    {
        "time": 1689829588000,
        "value": 139.362,
        "_internal_originalTime": 1689829588000
    },
    {
        "time": 1689829590000,
        "value": 139.363,
        "_internal_originalTime": 1689829590000
    },
    {
        "time": 1689829592000,
        "value": 139.358,
        "_internal_originalTime": 1689829592000
    },
    {
        "time": 1689829594000,
        "value": 139.362,
        "_internal_originalTime": 1689829594000
    },
    {
        "time": 1689829597000,
        "value": 139.358,
        "_internal_originalTime": 1689829597000
    },
    {
        "time": 1689829602000,
        "value": 139.363,
        "_internal_originalTime": 1689829602000
    },
    {
        "time": 1689829604000,
        "value": 139.357,
        "_internal_originalTime": 1689829604000
    },
    {
        "time": 1689829609000,
        "value": 139.359,
        "_internal_originalTime": 1689829609000
    },
    {
        "time": 1689829610000,
        "value": 139.363,
        "_internal_originalTime": 1689829610000
    },
    {
        "time": 1689829612000,
        "value": 139.363,
        "_internal_originalTime": 1689829612000
    },
    {
        "time": 1689829614000,
        "value": 139.362,
        "_internal_originalTime": 1689829614000
    },
    {
        "time": 1689829615000,
        "value": 139.359,
        "_internal_originalTime": 1689829615000
    },
    {
        "time": 1689829617000,
        "value": 139.362,
        "_internal_originalTime": 1689829617000
    },
    {
        "time": 1689829618000,
        "value": 139.359,
        "_internal_originalTime": 1689829618000
    },
    {
        "time": 1689829619000,
        "value": 139.362,
        "_internal_originalTime": 1689829619000
    },
    {
        "time": 1689829621000,
        "value": 139.363,
        "_internal_originalTime": 1689829621000
    },
    {
        "time": 1689829624000,
        "value": 139.362,
        "_internal_originalTime": 1689829624000
    },
    {
        "time": 1689829627000,
        "value": 139.363,
        "_internal_originalTime": 1689829627000
    },
    {
        "time": 1689829628000,
        "value": 139.359,
        "_internal_originalTime": 1689829628000
    },
    {
        "time": 1689829630000,
        "value": 139.363,
        "_internal_originalTime": 1689829630000
    },
    {
        "time": 1689829631000,
        "value": 139.363,
        "_internal_originalTime": 1689829631000
    },
    {
        "time": 1689829632000,
        "value": 139.363,
        "_internal_originalTime": 1689829632000
    },
    {
        "time": 1689829637000,
        "value": 139.364,
        "_internal_originalTime": 1689829637000
    },
    {
        "time": 1689829641000,
        "value": 139.365,
        "_internal_originalTime": 1689829641000
    },
    {
        "time": 1689829642000,
        "value": 139.363,
        "_internal_originalTime": 1689829642000
    },
    {
        "time": 1689829644000,
        "value": 139.365,
        "_internal_originalTime": 1689829644000
    },
    {
        "time": 1689829646000,
        "value": 139.364,
        "_internal_originalTime": 1689829646000
    },
    {
        "time": 1689829648000,
        "value": 139.358,
        "_internal_originalTime": 1689829648000
    },
    {
        "time": 1689829651000,
        "value": 139.364,
        "_internal_originalTime": 1689829651000
    },
    {
        "time": 1689829653000,
        "value": 139.357,
        "_internal_originalTime": 1689829653000
    },
    {
        "time": 1689829658000,
        "value": 139.362,
        "_internal_originalTime": 1689829658000
    },
    {
        "time": 1689829659000,
        "value": 139.363,
        "_internal_originalTime": 1689829659000
    },
    {
        "time": 1689829661000,
        "value": 139.36,
        "_internal_originalTime": 1689829661000
    },
    {
        "time": 1689829663000,
        "value": 139.364,
        "_internal_originalTime": 1689829663000
    },
    {
        "time": 1689829664000,
        "value": 139.364,
        "_internal_originalTime": 1689829664000
    },
    {
        "time": 1689829665000,
        "value": 139.362,
        "_internal_originalTime": 1689829665000
    },
    {
        "time": 1689829670000,
        "value": 139.362,
        "_internal_originalTime": 1689829670000
    },
    {
        "time": 1689829672000,
        "value": 139.362,
        "_internal_originalTime": 1689829672000
    },
    {
        "time": 1689829673000,
        "value": 139.348,
        "_internal_originalTime": 1689829673000
    },
    {
        "time": 1689829675000,
        "value": 139.352,
        "_internal_originalTime": 1689829675000
    },
    {
        "time": 1689829676000,
        "value": 139.353,
        "_internal_originalTime": 1689829676000
    },
    {
        "time": 1689829678000,
        "value": 139.353,
        "_internal_originalTime": 1689829678000
    },
    {
        "time": 1689829679000,
        "value": 139.353,
        "_internal_originalTime": 1689829679000
    },
    {
        "time": 1689829681000,
        "value": 139.352,
        "_internal_originalTime": 1689829681000
    },
    {
        "time": 1689829683000,
        "value": 139.351,
        "_internal_originalTime": 1689829683000
    },
    {
        "time": 1689829684000,
        "value": 139.346,
        "_internal_originalTime": 1689829684000
    },
    {
        "time": 1689829686000,
        "value": 139.346,
        "_internal_originalTime": 1689829686000
    },
    {
        "time": 1689829688000,
        "value": 139.344,
        "_internal_originalTime": 1689829688000
    },
    {
        "time": 1689829689000,
        "value": 139.338,
        "_internal_originalTime": 1689829689000
    },
    {
        "time": 1689829691000,
        "value": 139.344,
        "_internal_originalTime": 1689829691000
    },
    {
        "time": 1689829693000,
        "value": 139.343,
        "_internal_originalTime": 1689829693000
    },
    {
        "time": 1689829694000,
        "value": 139.344,
        "_internal_originalTime": 1689829694000
    },
    {
        "time": 1689829698000,
        "value": 139.345,
        "_internal_originalTime": 1689829698000
    },
    {
        "time": 1689829699000,
        "value": 139.344,
        "_internal_originalTime": 1689829699000
    },
    {
        "time": 1689829701000,
        "value": 139.339,
        "_internal_originalTime": 1689829701000
    },
    {
        "time": 1689829702000,
        "value": 139.344,
        "_internal_originalTime": 1689829702000
    },
    {
        "time": 1689829705000,
        "value": 139.345,
        "_internal_originalTime": 1689829705000
    },
    {
        "time": 1689829707000,
        "value": 139.352,
        "_internal_originalTime": 1689829707000
    },
    {
        "time": 1689829709000,
        "value": 139.346,
        "_internal_originalTime": 1689829709000
    },
    {
        "time": 1689829710000,
        "value": 139.35,
        "_internal_originalTime": 1689829710000
    },
    {
        "time": 1689829711000,
        "value": 139.346,
        "_internal_originalTime": 1689829711000
    },
    {
        "time": 1689829713000,
        "value": 139.35,
        "_internal_originalTime": 1689829713000
    },
    {
        "time": 1689829719000,
        "value": 139.352,
        "_internal_originalTime": 1689829719000
    },
    {
        "time": 1689829721000,
        "value": 139.353,
        "_internal_originalTime": 1689829721000
    },
    {
        "time": 1689829723000,
        "value": 139.356,
        "_internal_originalTime": 1689829723000
    },
    {
        "time": 1689829725000,
        "value": 139.36,
        "_internal_originalTime": 1689829725000
    },
    {
        "time": 1689829727000,
        "value": 139.349,
        "_internal_originalTime": 1689829727000
    },
    {
        "time": 1689829729000,
        "value": 139.35,
        "_internal_originalTime": 1689829729000
    },
    {
        "time": 1689829730000,
        "value": 139.351,
        "_internal_originalTime": 1689829730000
    },
    {
        "time": 1689829732000,
        "value": 139.346,
        "_internal_originalTime": 1689829732000
    },
    {
        "time": 1689829733000,
        "value": 139.348,
        "_internal_originalTime": 1689829733000
    },
    {
        "time": 1689829735000,
        "value": 139.348,
        "_internal_originalTime": 1689829735000
    },
    {
        "time": 1689829737000,
        "value": 139.352,
        "_internal_originalTime": 1689829737000
    },
    {
        "time": 1689829738000,
        "value": 139.351,
        "_internal_originalTime": 1689829738000
    },
    {
        "time": 1689829739000,
        "value": 139.35,
        "_internal_originalTime": 1689829739000
    },
    {
        "time": 1689829740000,
        "value": 139.35,
        "_internal_originalTime": 1689829740000
    },
    {
        "time": 1689829741000,
        "value": 139.35,
        "_internal_originalTime": 1689829741000
    },
    {
        "time": 1689829742000,
        "value": 139.346,
        "_internal_originalTime": 1689829742000
    },
    {
        "time": 1689829743000,
        "value": 139.346,
        "_internal_originalTime": 1689829743000
    },
    {
        "time": 1689829744000,
        "value": 139.349,
        "_internal_originalTime": 1689829744000
    },
    {
        "time": 1689829745000,
        "value": 139.351,
        "_internal_originalTime": 1689829745000
    },
    {
        "time": 1689829747000,
        "value": 139.347,
        "_internal_originalTime": 1689829747000
    },
    {
        "time": 1689829749000,
        "value": 139.348,
        "_internal_originalTime": 1689829749000
    },
    {
        "time": 1689829750000,
        "value": 139.344,
        "_internal_originalTime": 1689829750000
    },
    {
        "time": 1689829752000,
        "value": 139.351,
        "_internal_originalTime": 1689829752000
    },
    {
        "time": 1689829753000,
        "value": 139.35,
        "_internal_originalTime": 1689829753000
    },
    {
        "time": 1689829755000,
        "value": 139.351,
        "_internal_originalTime": 1689829755000
    },
    {
        "time": 1689829758000,
        "value": 139.35,
        "_internal_originalTime": 1689829758000
    },
    {
        "time": 1689829761000,
        "value": 139.35,
        "_internal_originalTime": 1689829761000
    },
    {
        "time": 1689829763000,
        "value": 139.35,
        "_internal_originalTime": 1689829763000
    },
    {
        "time": 1689829765000,
        "value": 139.349,
        "_internal_originalTime": 1689829765000
    },
    {
        "time": 1689829768000,
        "value": 139.354,
        "_internal_originalTime": 1689829768000
    },
    {
        "time": 1689829770000,
        "value": 139.353,
        "_internal_originalTime": 1689829770000
    },
    {
        "time": 1689829771000,
        "value": 139.346,
        "_internal_originalTime": 1689829771000
    },
    {
        "time": 1689829772000,
        "value": 139.346,
        "_internal_originalTime": 1689829772000
    },
    {
        "time": 1689829774000,
        "value": 139.347,
        "_internal_originalTime": 1689829774000
    },
    {
        "time": 1689829775000,
        "value": 139.353,
        "_internal_originalTime": 1689829775000
    },
    {
        "time": 1689829777000,
        "value": 139.352,
        "_internal_originalTime": 1689829777000
    },
    {
        "time": 1689829781000,
        "value": 139.348,
        "_internal_originalTime": 1689829781000
    },
    {
        "time": 1689829782000,
        "value": 139.354,
        "_internal_originalTime": 1689829782000
    },
    {
        "time": 1689829784000,
        "value": 139.355,
        "_internal_originalTime": 1689829784000
    },
    {
        "time": 1689829785000,
        "value": 139.363,
        "_internal_originalTime": 1689829785000
    },
    {
        "time": 1689829787000,
        "value": 139.364,
        "_internal_originalTime": 1689829787000
    },
    {
        "time": 1689829788000,
        "value": 139.363,
        "_internal_originalTime": 1689829788000
    },
    {
        "time": 1689829791000,
        "value": 139.359,
        "_internal_originalTime": 1689829791000
    },
    {
        "time": 1689829792000,
        "value": 139.36,
        "_internal_originalTime": 1689829792000
    },
    {
        "time": 1689829793000,
        "value": 139.359,
        "_internal_originalTime": 1689829793000
    },
    {
        "time": 1689829795000,
        "value": 139.364,
        "_internal_originalTime": 1689829795000
    },
    {
        "time": 1689829797000,
        "value": 139.363,
        "_internal_originalTime": 1689829797000
    },
    {
        "time": 1689829799000,
        "value": 139.358,
        "_internal_originalTime": 1689829799000
    },
    {
        "time": 1689829800000,
        "value": 139.362,
        "_internal_originalTime": 1689829800000
    },
    {
        "time": 1689829803000,
        "value": 139.357,
        "_internal_originalTime": 1689829803000
    },
    {
        "time": 1689829805000,
        "value": 139.358,
        "_internal_originalTime": 1689829805000
    },
    {
        "time": 1689829807000,
        "value": 139.353,
        "_internal_originalTime": 1689829807000
    },
    {
        "time": 1689829809000,
        "value": 139.36,
        "_internal_originalTime": 1689829809000
    },
    {
        "time": 1689829810000,
        "value": 139.359,
        "_internal_originalTime": 1689829810000
    },
    {
        "time": 1689829812000,
        "value": 139.359,
        "_internal_originalTime": 1689829812000
    },
    {
        "time": 1689829814000,
        "value": 139.35,
        "_internal_originalTime": 1689829814000
    },
    {
        "time": 1689829820000,
        "value": 139.348,
        "_internal_originalTime": 1689829820000
    },
    {
        "time": 1689829821000,
        "value": 139.344,
        "_internal_originalTime": 1689829821000
    },
    {
        "time": 1689829823000,
        "value": 139.341,
        "_internal_originalTime": 1689829823000
    },
    {
        "time": 1689829824000,
        "value": 139.343,
        "_internal_originalTime": 1689829824000
    },
    {
        "time": 1689829826000,
        "value": 139.343,
        "_internal_originalTime": 1689829826000
    },
    {
        "time": 1689829828000,
        "value": 139.343,
        "_internal_originalTime": 1689829828000
    },
    {
        "time": 1689829830000,
        "value": 139.342,
        "_internal_originalTime": 1689829830000
    },
    {
        "time": 1689829831000,
        "value": 139.343,
        "_internal_originalTime": 1689829831000
    },
    {
        "time": 1689829832000,
        "value": 139.337,
        "_internal_originalTime": 1689829832000
    },
    {
        "time": 1689829834000,
        "value": 139.343,
        "_internal_originalTime": 1689829834000
    },
    {
        "time": 1689829835000,
        "value": 139.336,
        "_internal_originalTime": 1689829835000
    },
    {
        "time": 1689829837000,
        "value": 139.337,
        "_internal_originalTime": 1689829837000
    },
    {
        "time": 1689829838000,
        "value": 139.336,
        "_internal_originalTime": 1689829838000
    },
    {
        "time": 1689829841000,
        "value": 139.333,
        "_internal_originalTime": 1689829841000
    },
    {
        "time": 1689829842000,
        "value": 139.326,
        "_internal_originalTime": 1689829842000
    },
    {
        "time": 1689829844000,
        "value": 139.331,
        "_internal_originalTime": 1689829844000
    },
    {
        "time": 1689829845000,
        "value": 139.331,
        "_internal_originalTime": 1689829845000
    },
    {
        "time": 1689829846000,
        "value": 139.331,
        "_internal_originalTime": 1689829846000
    },
    {
        "time": 1689829852000,
        "value": 139.327,
        "_internal_originalTime": 1689829852000
    },
    {
        "time": 1689829854000,
        "value": 139.327,
        "_internal_originalTime": 1689829854000
    },
    {
        "time": 1689829855000,
        "value": 139.331,
        "_internal_originalTime": 1689829855000
    },
    {
        "time": 1689829858000,
        "value": 139.326,
        "_internal_originalTime": 1689829858000
    },
    {
        "time": 1689829859000,
        "value": 139.331,
        "_internal_originalTime": 1689829859000
    },
    {
        "time": 1689829861000,
        "value": 139.326,
        "_internal_originalTime": 1689829861000
    },
    {
        "time": 1689829862000,
        "value": 139.326,
        "_internal_originalTime": 1689829862000
    },
    {
        "time": 1689829864000,
        "value": 139.333,
        "_internal_originalTime": 1689829864000
    },
    {
        "time": 1689829865000,
        "value": 139.326,
        "_internal_originalTime": 1689829865000
    },
    {
        "time": 1689829867000,
        "value": 139.327,
        "_internal_originalTime": 1689829867000
    },
    {
        "time": 1689829869000,
        "value": 139.327,
        "_internal_originalTime": 1689829869000
    },
    {
        "time": 1689829871000,
        "value": 139.326,
        "_internal_originalTime": 1689829871000
    },
    {
        "time": 1689829872000,
        "value": 139.331,
        "_internal_originalTime": 1689829872000
    },
    {
        "time": 1689829875000,
        "value": 139.333,
        "_internal_originalTime": 1689829875000
    },
    {
        "time": 1689829878000,
        "value": 139.334,
        "_internal_originalTime": 1689829878000
    },
    {
        "time": 1689829879000,
        "value": 139.33,
        "_internal_originalTime": 1689829879000
    },
    {
        "time": 1689829881000,
        "value": 139.335,
        "_internal_originalTime": 1689829881000
    },
    {
        "time": 1689829882000,
        "value": 139.33,
        "_internal_originalTime": 1689829882000
    },
    {
        "time": 1689829887000,
        "value": 139.334,
        "_internal_originalTime": 1689829887000
    },
    {
        "time": 1689829888000,
        "value": 139.33,
        "_internal_originalTime": 1689829888000
    },
    {
        "time": 1689829889000,
        "value": 139.336,
        "_internal_originalTime": 1689829889000
    },
    {
        "time": 1689829891000,
        "value": 139.335,
        "_internal_originalTime": 1689829891000
    },
    {
        "time": 1689829892000,
        "value": 139.335,
        "_internal_originalTime": 1689829892000
    },
    {
        "time": 1689829893000,
        "value": 139.334,
        "_internal_originalTime": 1689829893000
    },
    {
        "time": 1689829895000,
        "value": 139.335,
        "_internal_originalTime": 1689829895000
    },
    {
        "time": 1689829897000,
        "value": 139.334,
        "_internal_originalTime": 1689829897000
    },
    {
        "time": 1689829899000,
        "value": 139.331,
        "_internal_originalTime": 1689829899000
    },
    {
        "time": 1689829901000,
        "value": 139.331,
        "_internal_originalTime": 1689829901000
    },
    {
        "time": 1689829904000,
        "value": 139.33,
        "_internal_originalTime": 1689829904000
    },
    {
        "time": 1689829905000,
        "value": 139.331,
        "_internal_originalTime": 1689829905000
    },
    {
        "time": 1689829906000,
        "value": 139.335,
        "_internal_originalTime": 1689829906000
    },
    {
        "time": 1689829909000,
        "value": 139.334,
        "_internal_originalTime": 1689829909000
    },
    {
        "time": 1689829910000,
        "value": 139.33,
        "_internal_originalTime": 1689829910000
    },
    {
        "time": 1689829911000,
        "value": 139.331,
        "_internal_originalTime": 1689829911000
    },
    {
        "time": 1689829913000,
        "value": 139.334,
        "_internal_originalTime": 1689829913000
    },
    {
        "time": 1689829915000,
        "value": 139.334,
        "_internal_originalTime": 1689829915000
    },
    {
        "time": 1689829917000,
        "value": 139.334,
        "_internal_originalTime": 1689829917000
    },
    {
        "time": 1689829918000,
        "value": 139.33,
        "_internal_originalTime": 1689829918000
    },
    {
        "time": 1689829919000,
        "value": 139.334,
        "_internal_originalTime": 1689829919000
    },
    {
        "time": 1689829920000,
        "value": 139.334,
        "_internal_originalTime": 1689829920000
    },
    {
        "time": 1689829922000,
        "value": 139.33,
        "_internal_originalTime": 1689829922000
    },
    {
        "time": 1689829923000,
        "value": 139.331,
        "_internal_originalTime": 1689829923000
    },
    {
        "time": 1689829925000,
        "value": 139.328,
        "_internal_originalTime": 1689829925000
    },
    {
        "time": 1689829927000,
        "value": 139.33,
        "_internal_originalTime": 1689829927000
    },
    {
        "time": 1689829929000,
        "value": 139.331,
        "_internal_originalTime": 1689829929000
    },
    {
        "time": 1689829931000,
        "value": 139.335,
        "_internal_originalTime": 1689829931000
    },
    {
        "time": 1689829935000,
        "value": 139.333,
        "_internal_originalTime": 1689829935000
    },
    {
        "time": 1689829937000,
        "value": 139.339,
        "_internal_originalTime": 1689829937000
    },
    {
        "time": 1689829939000,
        "value": 139.339,
        "_internal_originalTime": 1689829939000
    },
    {
        "time": 1689829940000,
        "value": 139.345,
        "_internal_originalTime": 1689829940000
    },
    {
        "time": 1689829942000,
        "value": 139.341,
        "_internal_originalTime": 1689829942000
    },
    {
        "time": 1689829943000,
        "value": 139.34,
        "_internal_originalTime": 1689829943000
    },
    {
        "time": 1689829945000,
        "value": 139.34,
        "_internal_originalTime": 1689829945000
    },
    {
        "time": 1689829947000,
        "value": 139.34,
        "_internal_originalTime": 1689829947000
    },
    {
        "time": 1689829949000,
        "value": 139.346,
        "_internal_originalTime": 1689829949000
    },
    {
        "time": 1689829952000,
        "value": 139.349,
        "_internal_originalTime": 1689829952000
    },
    {
        "time": 1689829953000,
        "value": 139.349,
        "_internal_originalTime": 1689829953000
    },
    {
        "time": 1689829955000,
        "value": 139.343,
        "_internal_originalTime": 1689829955000
    },
    {
        "time": 1689829956000,
        "value": 139.344,
        "_internal_originalTime": 1689829956000
    },
    {
        "time": 1689829958000,
        "value": 139.348,
        "_internal_originalTime": 1689829958000
    },
    {
        "time": 1689829960000,
        "value": 139.346,
        "_internal_originalTime": 1689829960000
    },
    {
        "time": 1689829962000,
        "value": 139.35,
        "_internal_originalTime": 1689829962000
    },
    {
        "time": 1689829965000,
        "value": 139.346,
        "_internal_originalTime": 1689829965000
    },
    {
        "time": 1689829966000,
        "value": 139.35,
        "_internal_originalTime": 1689829966000
    },
    {
        "time": 1689829968000,
        "value": 139.348,
        "_internal_originalTime": 1689829968000
    },
    {
        "time": 1689829969000,
        "value": 139.349,
        "_internal_originalTime": 1689829969000
    },
    {
        "time": 1689829972000,
        "value": 139.351,
        "_internal_originalTime": 1689829972000
    },
    {
        "time": 1689829973000,
        "value": 139.344,
        "_internal_originalTime": 1689829973000
    },
    {
        "time": 1689829975000,
        "value": 139.35,
        "_internal_originalTime": 1689829975000
    },
    {
        "time": 1689829976000,
        "value": 139.346,
        "_internal_originalTime": 1689829976000
    },
    {
        "time": 1689829977000,
        "value": 139.351,
        "_internal_originalTime": 1689829977000
    },
    {
        "time": 1689829980000,
        "value": 139.348,
        "_internal_originalTime": 1689829980000
    },
    {
        "time": 1689829981000,
        "value": 139.352,
        "_internal_originalTime": 1689829981000
    },
    {
        "time": 1689829983000,
        "value": 139.354,
        "_internal_originalTime": 1689829983000
    },
    {
        "time": 1689829985000,
        "value": 139.354,
        "_internal_originalTime": 1689829985000
    },
    {
        "time": 1689829987000,
        "value": 139.349,
        "_internal_originalTime": 1689829987000
    },
    {
        "time": 1689829991000,
        "value": 139.354,
        "_internal_originalTime": 1689829991000
    },
    {
        "time": 1689829994000,
        "value": 139.352,
        "_internal_originalTime": 1689829994000
    },
    {
        "time": 1689829995000,
        "value": 139.353,
        "_internal_originalTime": 1689829995000
    },
    {
        "time": 1689829997000,
        "value": 139.352,
        "_internal_originalTime": 1689829997000
    },
    {
        "time": 1689829998000,
        "value": 139.35,
        "_internal_originalTime": 1689829998000
    },
    {
        "time": 1689829999000,
        "value": 139.355,
        "_internal_originalTime": 1689829999000
    },
    {
        "time": 1689830000000,
        "value": 139.354,
        "_internal_originalTime": 1689830000000
    },
    {
        "time": 1689830002000,
        "value": 139.353,
        "_internal_originalTime": 1689830002000
    },
    {
        "time": 1689830005000,
        "value": 139.351,
        "_internal_originalTime": 1689830005000
    },
    {
        "time": 1689830007000,
        "value": 139.351,
        "_internal_originalTime": 1689830007000
    },
    {
        "time": 1689830009000,
        "value": 139.355,
        "_internal_originalTime": 1689830009000
    },
    {
        "time": 1689830010000,
        "value": 139.355,
        "_internal_originalTime": 1689830010000
    },
    {
        "time": 1689830013000,
        "value": 139.348,
        "_internal_originalTime": 1689830013000
    },
    {
        "time": 1689830014000,
        "value": 139.354,
        "_internal_originalTime": 1689830014000
    },
    {
        "time": 1689830019000,
        "value": 139.354,
        "_internal_originalTime": 1689830019000
    },
    {
        "time": 1689830021000,
        "value": 139.352,
        "_internal_originalTime": 1689830021000
    },
    {
        "time": 1689830023000,
        "value": 139.363,
        "_internal_originalTime": 1689830023000
    },
    {
        "time": 1689830025000,
        "value": 139.365,
        "_internal_originalTime": 1689830025000
    },
    {
        "time": 1689830026000,
        "value": 139.363,
        "_internal_originalTime": 1689830026000
    },
    {
        "time": 1689830028000,
        "value": 139.362,
        "_internal_originalTime": 1689830028000
    },
    {
        "time": 1689830036000,
        "value": 139.359,
        "_internal_originalTime": 1689830036000
    },
    {
        "time": 1689830038000,
        "value": 139.361,
        "_internal_originalTime": 1689830038000
    },
    {
        "time": 1689830039000,
        "value": 139.363,
        "_internal_originalTime": 1689830039000
    },
    {
        "time": 1689830040000,
        "value": 139.363,
        "_internal_originalTime": 1689830040000
    },
    {
        "time": 1689830041000,
        "value": 139.362,
        "_internal_originalTime": 1689830041000
    },
    {
        "time": 1689830042000,
        "value": 139.362,
        "_internal_originalTime": 1689830042000
    },
    {
        "time": 1689830044000,
        "value": 139.353,
        "_internal_originalTime": 1689830044000
    },
    {
        "time": 1689830046000,
        "value": 139.359,
        "_internal_originalTime": 1689830046000
    },
    {
        "time": 1689830047000,
        "value": 139.356,
        "_internal_originalTime": 1689830047000
    },
    {
        "time": 1689830049000,
        "value": 139.356,
        "_internal_originalTime": 1689830049000
    },
    {
        "time": 1689830051000,
        "value": 139.362,
        "_internal_originalTime": 1689830051000
    },
    {
        "time": 1689830053000,
        "value": 139.363,
        "_internal_originalTime": 1689830053000
    },
    {
        "time": 1689830055000,
        "value": 139.363,
        "_internal_originalTime": 1689830055000
    },
    {
        "time": 1689830056000,
        "value": 139.362,
        "_internal_originalTime": 1689830056000
    },
    {
        "time": 1689830058000,
        "value": 139.362,
        "_internal_originalTime": 1689830058000
    },
    {
        "time": 1689830063000,
        "value": 139.359,
        "_internal_originalTime": 1689830063000
    },
    {
        "time": 1689830065000,
        "value": 139.362,
        "_internal_originalTime": 1689830065000
    },
    {
        "time": 1689830066000,
        "value": 139.364,
        "_internal_originalTime": 1689830066000
    },
    {
        "time": 1689830068000,
        "value": 139.366,
        "_internal_originalTime": 1689830068000
    },
    {
        "time": 1689830070000,
        "value": 139.363,
        "_internal_originalTime": 1689830070000
    },
    {
        "time": 1689830071000,
        "value": 139.36,
        "_internal_originalTime": 1689830071000
    },
    {
        "time": 1689830072000,
        "value": 139.362,
        "_internal_originalTime": 1689830072000
    },
    {
        "time": 1689830074000,
        "value": 139.364,
        "_internal_originalTime": 1689830074000
    },
    {
        "time": 1689830075000,
        "value": 139.363,
        "_internal_originalTime": 1689830075000
    },
    {
        "time": 1689830076000,
        "value": 139.363,
        "_internal_originalTime": 1689830076000
    },
    {
        "time": 1689830079000,
        "value": 139.362,
        "_internal_originalTime": 1689830079000
    },
    {
        "time": 1689830081000,
        "value": 139.36,
        "_internal_originalTime": 1689830081000
    },
    {
        "time": 1689830084000,
        "value": 139.36,
        "_internal_originalTime": 1689830084000
    },
    {
        "time": 1689830085000,
        "value": 139.361,
        "_internal_originalTime": 1689830085000
    },
    {
        "time": 1689830087000,
        "value": 139.361,
        "_internal_originalTime": 1689830087000
    },
    {
        "time": 1689830088000,
        "value": 139.357,
        "_internal_originalTime": 1689830088000
    },
    {
        "time": 1689830090000,
        "value": 139.355,
        "_internal_originalTime": 1689830090000
    },
    {
        "time": 1689830093000,
        "value": 139.352,
        "_internal_originalTime": 1689830093000
    },
    {
        "time": 1689830098000,
        "value": 139.352,
        "_internal_originalTime": 1689830098000
    },
    {
        "time": 1689830102000,
        "value": 139.351,
        "_internal_originalTime": 1689830102000
    },
    {
        "time": 1689830105000,
        "value": 139.349,
        "_internal_originalTime": 1689830105000
    },
    {
        "time": 1689830106000,
        "value": 139.349,
        "_internal_originalTime": 1689830106000
    },
    {
        "time": 1689830107000,
        "value": 139.351,
        "_internal_originalTime": 1689830107000
    },
    {
        "time": 1689830108000,
        "value": 139.349,
        "_internal_originalTime": 1689830108000
    },
    {
        "time": 1689830109000,
        "value": 139.351,
        "_internal_originalTime": 1689830109000
    },
    {
        "time": 1689830110000,
        "value": 139.352,
        "_internal_originalTime": 1689830110000
    },
    {
        "time": 1689830111000,
        "value": 139.351,
        "_internal_originalTime": 1689830111000
    },
    {
        "time": 1689830112000,
        "value": 139.346,
        "_internal_originalTime": 1689830112000
    },
    {
        "time": 1689830118000,
        "value": 139.349,
        "_internal_originalTime": 1689830118000
    },
    {
        "time": 1689830119000,
        "value": 139.35,
        "_internal_originalTime": 1689830119000
    },
    {
        "time": 1689830120000,
        "value": 139.35,
        "_internal_originalTime": 1689830120000
    },
    {
        "time": 1689830121000,
        "value": 139.347,
        "_internal_originalTime": 1689830121000
    },
    {
        "time": 1689830122000,
        "value": 139.345,
        "_internal_originalTime": 1689830122000
    },
    {
        "time": 1689830123000,
        "value": 139.342,
        "_internal_originalTime": 1689830123000
    },
    {
        "time": 1689830124000,
        "value": 139.342,
        "_internal_originalTime": 1689830124000
    },
    {
        "time": 1689830125000,
        "value": 139.34,
        "_internal_originalTime": 1689830125000
    },
    {
        "time": 1689830126000,
        "value": 139.342,
        "_internal_originalTime": 1689830126000
    },
    {
        "time": 1689830128000,
        "value": 139.342,
        "_internal_originalTime": 1689830128000
    },
    {
        "time": 1689830131000,
        "value": 139.348,
        "_internal_originalTime": 1689830131000
    },
    {
        "time": 1689830132000,
        "value": 139.349,
        "_internal_originalTime": 1689830132000
    },
    {
        "time": 1689830133000,
        "value": 139.352,
        "_internal_originalTime": 1689830133000
    },
    {
        "time": 1689830141000,
        "value": 139.349,
        "_internal_originalTime": 1689830141000
    },
    {
        "time": 1689830144000,
        "value": 139.351,
        "_internal_originalTime": 1689830144000
    },
    {
        "time": 1689830145000,
        "value": 139.349,
        "_internal_originalTime": 1689830145000
    },
    {
        "time": 1689830147000,
        "value": 139.35,
        "_internal_originalTime": 1689830147000
    },
    {
        "time": 1689830148000,
        "value": 139.352,
        "_internal_originalTime": 1689830148000
    },
    {
        "time": 1689830152000,
        "value": 139.349,
        "_internal_originalTime": 1689830152000
    },
    {
        "time": 1689830154000,
        "value": 139.349,
        "_internal_originalTime": 1689830154000
    },
    {
        "time": 1689830156000,
        "value": 139.352,
        "_internal_originalTime": 1689830156000
    },
    {
        "time": 1689830160000,
        "value": 139.348,
        "_internal_originalTime": 1689830160000
    },
    {
        "time": 1689830161000,
        "value": 139.353,
        "_internal_originalTime": 1689830161000
    },
    {
        "time": 1689830162000,
        "value": 139.356,
        "_internal_originalTime": 1689830162000
    },
    {
        "time": 1689830163000,
        "value": 139.349,
        "_internal_originalTime": 1689830163000
    },
    {
        "time": 1689830165000,
        "value": 139.355,
        "_internal_originalTime": 1689830165000
    },
    {
        "time": 1689830167000,
        "value": 139.362,
        "_internal_originalTime": 1689830167000
    },
    {
        "time": 1689830168000,
        "value": 139.358,
        "_internal_originalTime": 1689830168000
    },
    {
        "time": 1689830170000,
        "value": 139.36,
        "_internal_originalTime": 1689830170000
    },
    {
        "time": 1689830171000,
        "value": 139.36,
        "_internal_originalTime": 1689830171000
    },
    {
        "time": 1689830172000,
        "value": 139.36,
        "_internal_originalTime": 1689830172000
    },
    {
        "time": 1689830174000,
        "value": 139.358,
        "_internal_originalTime": 1689830174000
    },
    {
        "time": 1689830175000,
        "value": 139.36,
        "_internal_originalTime": 1689830175000
    },
    {
        "time": 1689830176000,
        "value": 139.36,
        "_internal_originalTime": 1689830176000
    },
    {
        "time": 1689830177000,
        "value": 139.364,
        "_internal_originalTime": 1689830177000
    },
    {
        "time": 1689830178000,
        "value": 139.36,
        "_internal_originalTime": 1689830178000
    },
    {
        "time": 1689830181000,
        "value": 139.361,
        "_internal_originalTime": 1689830181000
    },
    {
        "time": 1689830184000,
        "value": 139.359,
        "_internal_originalTime": 1689830184000
    },
    {
        "time": 1689830186000,
        "value": 139.363,
        "_internal_originalTime": 1689830186000
    },
    {
        "time": 1689830187000,
        "value": 139.362,
        "_internal_originalTime": 1689830187000
    },
    {
        "time": 1689830188000,
        "value": 139.363,
        "_internal_originalTime": 1689830188000
    },
    {
        "time": 1689830193000,
        "value": 139.357,
        "_internal_originalTime": 1689830193000
    },
    {
        "time": 1689830195000,
        "value": 139.359,
        "_internal_originalTime": 1689830195000
    },
    {
        "time": 1689830263000,
        "value": 139.341,
        "_internal_originalTime": 1689830263000
    },
    {
        "time": 1689830265000,
        "value": 139.348,
        "_internal_originalTime": 1689830265000
    },
    {
        "time": 1689830266000,
        "value": 139.345,
        "_internal_originalTime": 1689830266000
    },
    {
        "time": 1689830267000,
        "value": 139.34,
        "_internal_originalTime": 1689830267000
    },
    {
        "time": 1689830268000,
        "value": 139.341,
        "_internal_originalTime": 1689830268000
    },
    {
        "time": 1689830269000,
        "value": 139.34,
        "_internal_originalTime": 1689830269000
    },
    {
        "time": 1689830273000,
        "value": 139.343,
        "_internal_originalTime": 1689830273000
    },
    {
        "time": 1689830276000,
        "value": 139.341,
        "_internal_originalTime": 1689830276000
    },
    {
        "time": 1689830277000,
        "value": 139.34,
        "_internal_originalTime": 1689830277000
    },
    {
        "time": 1689830279000,
        "value": 139.341,
        "_internal_originalTime": 1689830279000
    },
    {
        "time": 1689830281000,
        "value": 139.341,
        "_internal_originalTime": 1689830281000
    },
    {
        "time": 1689830282000,
        "value": 139.342,
        "_internal_originalTime": 1689830282000
    },
    {
        "time": 1689830284000,
        "value": 139.345,
        "_internal_originalTime": 1689830284000
    },
    {
        "time": 1689830286000,
        "value": 139.342,
        "_internal_originalTime": 1689830286000
    },
    {
        "time": 1689830287000,
        "value": 139.338,
        "_internal_originalTime": 1689830287000
    },
    {
        "time": 1689830288000,
        "value": 139.338,
        "_internal_originalTime": 1689830288000
    },
    {
        "time": 1689830289000,
        "value": 139.343,
        "_internal_originalTime": 1689830289000
    },
    {
        "time": 1689830291000,
        "value": 139.339,
        "_internal_originalTime": 1689830291000
    },
    {
        "time": 1689830293000,
        "value": 139.338,
        "_internal_originalTime": 1689830293000
    },
    {
        "time": 1689830295000,
        "value": 139.342,
        "_internal_originalTime": 1689830295000
    },
    {
        "time": 1689830297000,
        "value": 139.342,
        "_internal_originalTime": 1689830297000
    },
    {
        "time": 1689830298000,
        "value": 139.332,
        "_internal_originalTime": 1689830298000
    },
    {
        "time": 1689830299000,
        "value": 139.335,
        "_internal_originalTime": 1689830299000
    },
    {
        "time": 1689830302000,
        "value": 139.33,
        "_internal_originalTime": 1689830302000
    },
    {
        "time": 1689830303000,
        "value": 139.331,
        "_internal_originalTime": 1689830303000
    },
    {
        "time": 1689830305000,
        "value": 139.333,
        "_internal_originalTime": 1689830305000
    },
    {
        "time": 1689830306000,
        "value": 139.328,
        "_internal_originalTime": 1689830306000
    },
    {
        "time": 1689830307000,
        "value": 139.331,
        "_internal_originalTime": 1689830307000
    },
    {
        "time": 1689830308000,
        "value": 139.335,
        "_internal_originalTime": 1689830308000
    },
    {
        "time": 1689830310000,
        "value": 139.333,
        "_internal_originalTime": 1689830310000
    },
    {
        "time": 1689830313000,
        "value": 139.329,
        "_internal_originalTime": 1689830313000
    },
    {
        "time": 1689830315000,
        "value": 139.335,
        "_internal_originalTime": 1689830315000
    },
    {
        "time": 1689830319000,
        "value": 139.333,
        "_internal_originalTime": 1689830319000
    },
    {
        "time": 1689830320000,
        "value": 139.335,
        "_internal_originalTime": 1689830320000
    },
    {
        "time": 1689830321000,
        "value": 139.334,
        "_internal_originalTime": 1689830321000
    },
    {
        "time": 1689830322000,
        "value": 139.335,
        "_internal_originalTime": 1689830322000
    },
    {
        "time": 1689830324000,
        "value": 139.333,
        "_internal_originalTime": 1689830324000
    },
    {
        "time": 1689830325000,
        "value": 139.335,
        "_internal_originalTime": 1689830325000
    },
    {
        "time": 1689830329000,
        "value": 139.336,
        "_internal_originalTime": 1689830329000
    },
    {
        "time": 1689830330000,
        "value": 139.338,
        "_internal_originalTime": 1689830330000
    },
    {
        "time": 1689830331000,
        "value": 139.336,
        "_internal_originalTime": 1689830331000
    },
    {
        "time": 1689830332000,
        "value": 139.339,
        "_internal_originalTime": 1689830332000
    },
    {
        "time": 1689830333000,
        "value": 139.335,
        "_internal_originalTime": 1689830333000
    },
    {
        "time": 1689830335000,
        "value": 139.34,
        "_internal_originalTime": 1689830335000
    },
    {
        "time": 1689830336000,
        "value": 139.339,
        "_internal_originalTime": 1689830336000
    },
    {
        "time": 1689830337000,
        "value": 139.342,
        "_internal_originalTime": 1689830337000
    },
    {
        "time": 1689830339000,
        "value": 139.338,
        "_internal_originalTime": 1689830339000
    },
    {
        "time": 1689830340000,
        "value": 139.339,
        "_internal_originalTime": 1689830340000
    },
    {
        "time": 1689830343000,
        "value": 139.343,
        "_internal_originalTime": 1689830343000
    },
    {
        "time": 1689830345000,
        "value": 139.343,
        "_internal_originalTime": 1689830345000
    },
    {
        "time": 1689830349000,
        "value": 139.339,
        "_internal_originalTime": 1689830349000
    },
    {
        "time": 1689830350000,
        "value": 139.34,
        "_internal_originalTime": 1689830350000
    },
    {
        "time": 1689830352000,
        "value": 139.34,
        "_internal_originalTime": 1689830352000
    },
    {
        "time": 1689830355000,
        "value": 139.339,
        "_internal_originalTime": 1689830355000
    },
    {
        "time": 1689830357000,
        "value": 139.343,
        "_internal_originalTime": 1689830357000
    },
    {
        "time": 1689830358000,
        "value": 139.336,
        "_internal_originalTime": 1689830358000
    },
    {
        "time": 1689830359000,
        "value": 139.338,
        "_internal_originalTime": 1689830359000
    },
    {
        "time": 1689830361000,
        "value": 139.341,
        "_internal_originalTime": 1689830361000
    },
    {
        "time": 1689830362000,
        "value": 139.337,
        "_internal_originalTime": 1689830362000
    },
    {
        "time": 1689830364000,
        "value": 139.341,
        "_internal_originalTime": 1689830364000
    },
    {
        "time": 1689830366000,
        "value": 139.341,
        "_internal_originalTime": 1689830366000
    },
    {
        "time": 1689830371000,
        "value": 139.338,
        "_internal_originalTime": 1689830371000
    },
    {
        "time": 1689830372000,
        "value": 139.337,
        "_internal_originalTime": 1689830372000
    },
    {
        "time": 1689830374000,
        "value": 139.333,
        "_internal_originalTime": 1689830374000
    },
    {
        "time": 1689830375000,
        "value": 139.332,
        "_internal_originalTime": 1689830375000
    },
    {
        "time": 1689830379000,
        "value": 139.334,
        "_internal_originalTime": 1689830379000
    },
    {
        "time": 1689830381000,
        "value": 139.334,
        "_internal_originalTime": 1689830381000
    },
    {
        "time": 1689830383000,
        "value": 139.335,
        "_internal_originalTime": 1689830383000
    },
    {
        "time": 1689830385000,
        "value": 139.337,
        "_internal_originalTime": 1689830385000
    },
    {
        "time": 1689830386000,
        "value": 139.332,
        "_internal_originalTime": 1689830386000
    },
    {
        "time": 1689830387000,
        "value": 139.335,
        "_internal_originalTime": 1689830387000
    },
    {
        "time": 1689830388000,
        "value": 139.335,
        "_internal_originalTime": 1689830388000
    },
    {
        "time": 1689830389000,
        "value": 139.334,
        "_internal_originalTime": 1689830389000
    },
    {
        "time": 1689830390000,
        "value": 139.335,
        "_internal_originalTime": 1689830390000
    },
    {
        "time": 1689830391000,
        "value": 139.33,
        "_internal_originalTime": 1689830391000
    },
    {
        "time": 1689830393000,
        "value": 139.329,
        "_internal_originalTime": 1689830393000
    },
    {
        "time": 1689830396000,
        "value": 139.33,
        "_internal_originalTime": 1689830396000
    },
    {
        "time": 1689830397000,
        "value": 139.335,
        "_internal_originalTime": 1689830397000
    },
    {
        "time": 1689830398000,
        "value": 139.335,
        "_internal_originalTime": 1689830398000
    },
    {
        "time": 1689830401000,
        "value": 139.335,
        "_internal_originalTime": 1689830401000
    },
    {
        "time": 1689830402000,
        "value": 139.329,
        "_internal_originalTime": 1689830402000
    },
    {
        "time": 1689830403000,
        "value": 139.329,
        "_internal_originalTime": 1689830403000
    },
    {
        "time": 1689830404000,
        "value": 139.339,
        "_internal_originalTime": 1689830404000
    },
    {
        "time": 1689830405000,
        "value": 139.337,
        "_internal_originalTime": 1689830405000
    },
    {
        "time": 1689830407000,
        "value": 139.342,
        "_internal_originalTime": 1689830407000
    },
    {
        "time": 1689830408000,
        "value": 139.342,
        "_internal_originalTime": 1689830408000
    },
    {
        "time": 1689830411000,
        "value": 139.342,
        "_internal_originalTime": 1689830411000
    },
    {
        "time": 1689830412000,
        "value": 139.338,
        "_internal_originalTime": 1689830412000
    },
    {
        "time": 1689830421000,
        "value": 139.341,
        "_internal_originalTime": 1689830421000
    },
    {
        "time": 1689830422000,
        "value": 139.343,
        "_internal_originalTime": 1689830422000
    },
    {
        "time": 1689830423000,
        "value": 139.34,
        "_internal_originalTime": 1689830423000
    },
    {
        "time": 1689830426000,
        "value": 139.339,
        "_internal_originalTime": 1689830426000
    },
    {
        "time": 1689830427000,
        "value": 139.336,
        "_internal_originalTime": 1689830427000
    },
    {
        "time": 1689830428000,
        "value": 139.336,
        "_internal_originalTime": 1689830428000
    },
    {
        "time": 1689830430000,
        "value": 139.336,
        "_internal_originalTime": 1689830430000
    },
    {
        "time": 1689830431000,
        "value": 139.34,
        "_internal_originalTime": 1689830431000
    },
    {
        "time": 1689830433000,
        "value": 139.336,
        "_internal_originalTime": 1689830433000
    },
    {
        "time": 1689830434000,
        "value": 139.336,
        "_internal_originalTime": 1689830434000
    },
    {
        "time": 1689830435000,
        "value": 139.341,
        "_internal_originalTime": 1689830435000
    },
    {
        "time": 1689830440000,
        "value": 139.341,
        "_internal_originalTime": 1689830440000
    },
    {
        "time": 1689830442000,
        "value": 139.341,
        "_internal_originalTime": 1689830442000
    },
    {
        "time": 1689830446000,
        "value": 139.34,
        "_internal_originalTime": 1689830446000
    },
    {
        "time": 1689830447000,
        "value": 139.336,
        "_internal_originalTime": 1689830447000
    },
    {
        "time": 1689830449000,
        "value": 139.334,
        "_internal_originalTime": 1689830449000
    },
    {
        "time": 1689830450000,
        "value": 139.336,
        "_internal_originalTime": 1689830450000
    },
    {
        "time": 1689830451000,
        "value": 139.338,
        "_internal_originalTime": 1689830451000
    },
    {
        "time": 1689830452000,
        "value": 139.334,
        "_internal_originalTime": 1689830452000
    },
    {
        "time": 1689830454000,
        "value": 139.335,
        "_internal_originalTime": 1689830454000
    },
    {
        "time": 1689830455000,
        "value": 139.334,
        "_internal_originalTime": 1689830455000
    },
    {
        "time": 1689830457000,
        "value": 139.33,
        "_internal_originalTime": 1689830457000
    },
    {
        "time": 1689830458000,
        "value": 139.328,
        "_internal_originalTime": 1689830458000
    },
    {
        "time": 1689830460000,
        "value": 139.33,
        "_internal_originalTime": 1689830460000
    },
    {
        "time": 1689830462000,
        "value": 139.333,
        "_internal_originalTime": 1689830462000
    },
    {
        "time": 1689830464000,
        "value": 139.331,
        "_internal_originalTime": 1689830464000
    },
    {
        "time": 1689830465000,
        "value": 139.333,
        "_internal_originalTime": 1689830465000
    },
    {
        "time": 1689830467000,
        "value": 139.333,
        "_internal_originalTime": 1689830467000
    },
    {
        "time": 1689830468000,
        "value": 139.33,
        "_internal_originalTime": 1689830468000
    },
    {
        "time": 1689830470000,
        "value": 139.324,
        "_internal_originalTime": 1689830470000
    },
    {
        "time": 1689830471000,
        "value": 139.322,
        "_internal_originalTime": 1689830471000
    },
    {
        "time": 1689830472000,
        "value": 139.321,
        "_internal_originalTime": 1689830472000
    },
    {
        "time": 1689830474000,
        "value": 139.32,
        "_internal_originalTime": 1689830474000
    },
    {
        "time": 1689830478000,
        "value": 139.321,
        "_internal_originalTime": 1689830478000
    },
    {
        "time": 1689830484000,
        "value": 139.32,
        "_internal_originalTime": 1689830484000
    },
    {
        "time": 1689830489000,
        "value": 139.321,
        "_internal_originalTime": 1689830489000
    },
    {
        "time": 1689830490000,
        "value": 139.321,
        "_internal_originalTime": 1689830490000
    },
    {
        "time": 1689830491000,
        "value": 139.323,
        "_internal_originalTime": 1689830491000
    },
    {
        "time": 1689830492000,
        "value": 139.317,
        "_internal_originalTime": 1689830492000
    },
    {
        "time": 1689830493000,
        "value": 139.313,
        "_internal_originalTime": 1689830493000
    },
    {
        "time": 1689830495000,
        "value": 139.313,
        "_internal_originalTime": 1689830495000
    },
    {
        "time": 1689830496000,
        "value": 139.308,
        "_internal_originalTime": 1689830496000
    },
    {
        "time": 1689830497000,
        "value": 139.307,
        "_internal_originalTime": 1689830497000
    },
    {
        "time": 1689830499000,
        "value": 139.309,
        "_internal_originalTime": 1689830499000
    },
    {
        "time": 1689830500000,
        "value": 139.308,
        "_internal_originalTime": 1689830500000
    },
    {
        "time": 1689830502000,
        "value": 139.316,
        "_internal_originalTime": 1689830502000
    },
    {
        "time": 1689830503000,
        "value": 139.31,
        "_internal_originalTime": 1689830503000
    },
    {
        "time": 1689830504000,
        "value": 139.316,
        "_internal_originalTime": 1689830504000
    },
    {
        "time": 1689830508000,
        "value": 139.311,
        "_internal_originalTime": 1689830508000
    },
    {
        "time": 1689830510000,
        "value": 139.312,
        "_internal_originalTime": 1689830510000
    },
    {
        "time": 1689830511000,
        "value": 139.318,
        "_internal_originalTime": 1689830511000
    },
    {
        "time": 1689830512000,
        "value": 139.324,
        "_internal_originalTime": 1689830512000
    },
    {
        "time": 1689830514000,
        "value": 139.32,
        "_internal_originalTime": 1689830514000
    },
    {
        "time": 1689830515000,
        "value": 139.325,
        "_internal_originalTime": 1689830515000
    },
    {
        "time": 1689830517000,
        "value": 139.322,
        "_internal_originalTime": 1689830517000
    },
    {
        "time": 1689830519000,
        "value": 139.33,
        "_internal_originalTime": 1689830519000
    },
    {
        "time": 1689830521000,
        "value": 139.322,
        "_internal_originalTime": 1689830521000
    },
    {
        "time": 1689830523000,
        "value": 139.327,
        "_internal_originalTime": 1689830523000
    },
    {
        "time": 1689830524000,
        "value": 139.322,
        "_internal_originalTime": 1689830524000
    },
    {
        "time": 1689830525000,
        "value": 139.322,
        "_internal_originalTime": 1689830525000
    },
    {
        "time": 1689830527000,
        "value": 139.32,
        "_internal_originalTime": 1689830527000
    },
    {
        "time": 1689830529000,
        "value": 139.32,
        "_internal_originalTime": 1689830529000
    },
    {
        "time": 1689830530000,
        "value": 139.321,
        "_internal_originalTime": 1689830530000
    },
    {
        "time": 1689830531000,
        "value": 139.323,
        "_internal_originalTime": 1689830531000
    },
    {
        "time": 1689830532000,
        "value": 139.328,
        "_internal_originalTime": 1689830532000
    },
    {
        "time": 1689830535000,
        "value": 139.328,
        "_internal_originalTime": 1689830535000
    },
    {
        "time": 1689830536000,
        "value": 139.327,
        "_internal_originalTime": 1689830536000
    },
    {
        "time": 1689830539000,
        "value": 139.326,
        "_internal_originalTime": 1689830539000
    },
    {
        "time": 1689830547000,
        "value": 139.327,
        "_internal_originalTime": 1689830547000
    },
    {
        "time": 1689830550000,
        "value": 139.326,
        "_internal_originalTime": 1689830550000
    },
    {
        "time": 1689830551000,
        "value": 139.326,
        "_internal_originalTime": 1689830551000
    },
    {
        "time": 1689830553000,
        "value": 139.324,
        "_internal_originalTime": 1689830553000
    },
    {
        "time": 1689830556000,
        "value": 139.326,
        "_internal_originalTime": 1689830556000
    },
    {
        "time": 1689830561000,
        "value": 139.328,
        "_internal_originalTime": 1689830561000
    },
    {
        "time": 1689830564000,
        "value": 139.326,
        "_internal_originalTime": 1689830564000
    },
    {
        "time": 1689830565000,
        "value": 139.326,
        "_internal_originalTime": 1689830565000
    },
    {
        "time": 1689830567000,
        "value": 139.326,
        "_internal_originalTime": 1689830567000
    },
    {
        "time": 1689830568000,
        "value": 139.332,
        "_internal_originalTime": 1689830568000
    },
    {
        "time": 1689830570000,
        "value": 139.332,
        "_internal_originalTime": 1689830570000
    },
    {
        "time": 1689830571000,
        "value": 139.326,
        "_internal_originalTime": 1689830571000
    },
    {
        "time": 1689830573000,
        "value": 139.331,
        "_internal_originalTime": 1689830573000
    },
    {
        "time": 1689830574000,
        "value": 139.326,
        "_internal_originalTime": 1689830574000
    },
    {
        "time": 1689830576000,
        "value": 139.327,
        "_internal_originalTime": 1689830576000
    },
    {
        "time": 1689830577000,
        "value": 139.322,
        "_internal_originalTime": 1689830577000
    },
    {
        "time": 1689830580000,
        "value": 139.324,
        "_internal_originalTime": 1689830580000
    },
    {
        "time": 1689830581000,
        "value": 139.325,
        "_internal_originalTime": 1689830581000
    },
    {
        "time": 1689830583000,
        "value": 139.325,
        "_internal_originalTime": 1689830583000
    },
    {
        "time": 1689830584000,
        "value": 139.328,
        "_internal_originalTime": 1689830584000
    },
    {
        "time": 1689830586000,
        "value": 139.322,
        "_internal_originalTime": 1689830586000
    },
    {
        "time": 1689830587000,
        "value": 139.326,
        "_internal_originalTime": 1689830587000
    },
    {
        "time": 1689830589000,
        "value": 139.325,
        "_internal_originalTime": 1689830589000
    },
    {
        "time": 1689830590000,
        "value": 139.322,
        "_internal_originalTime": 1689830590000
    },
    {
        "time": 1689830591000,
        "value": 139.323,
        "_internal_originalTime": 1689830591000
    },
    {
        "time": 1689830596000,
        "value": 139.326,
        "_internal_originalTime": 1689830596000
    },
    {
        "time": 1689830598000,
        "value": 139.32,
        "_internal_originalTime": 1689830598000
    },
    {
        "time": 1689830599000,
        "value": 139.32,
        "_internal_originalTime": 1689830599000
    },
    {
        "time": 1689830602000,
        "value": 139.322,
        "_internal_originalTime": 1689830602000
    },
    {
        "time": 1689830607000,
        "value": 139.327,
        "_internal_originalTime": 1689830607000
    },
    {
        "time": 1689830608000,
        "value": 139.327,
        "_internal_originalTime": 1689830608000
    },
    {
        "time": 1689830609000,
        "value": 139.327,
        "_internal_originalTime": 1689830609000
    },
    {
        "time": 1689830611000,
        "value": 139.326,
        "_internal_originalTime": 1689830611000
    },
    {
        "time": 1689830614000,
        "value": 139.327,
        "_internal_originalTime": 1689830614000
    },
    {
        "time": 1689830617000,
        "value": 139.325,
        "_internal_originalTime": 1689830617000
    },
    {
        "time": 1689830619000,
        "value": 139.326,
        "_internal_originalTime": 1689830619000
    },
    {
        "time": 1689830621000,
        "value": 139.327,
        "_internal_originalTime": 1689830621000
    },
    {
        "time": 1689830623000,
        "value": 139.331,
        "_internal_originalTime": 1689830623000
    },
    {
        "time": 1689830624000,
        "value": 139.332,
        "_internal_originalTime": 1689830624000
    },
    {
        "time": 1689830627000,
        "value": 139.33,
        "_internal_originalTime": 1689830627000
    },
    {
        "time": 1689830628000,
        "value": 139.33,
        "_internal_originalTime": 1689830628000
    },
    {
        "time": 1689830632000,
        "value": 139.328,
        "_internal_originalTime": 1689830632000
    },
    {
        "time": 1689830636000,
        "value": 139.332,
        "_internal_originalTime": 1689830636000
    },
    {
        "time": 1689830637000,
        "value": 139.328,
        "_internal_originalTime": 1689830637000
    },
    {
        "time": 1689830639000,
        "value": 139.327,
        "_internal_originalTime": 1689830639000
    },
    {
        "time": 1689830641000,
        "value": 139.32,
        "_internal_originalTime": 1689830641000
    },
    {
        "time": 1689830642000,
        "value": 139.321,
        "_internal_originalTime": 1689830642000
    },
    {
        "time": 1689830644000,
        "value": 139.326,
        "_internal_originalTime": 1689830644000
    },
    {
        "time": 1689830646000,
        "value": 139.319,
        "_internal_originalTime": 1689830646000
    },
    {
        "time": 1689830648000,
        "value": 139.321,
        "_internal_originalTime": 1689830648000
    },
    {
        "time": 1689830649000,
        "value": 139.326,
        "_internal_originalTime": 1689830649000
    },
    {
        "time": 1689830650000,
        "value": 139.32,
        "_internal_originalTime": 1689830650000
    },
    {
        "time": 1689830651000,
        "value": 139.326,
        "_internal_originalTime": 1689830651000
    },
    {
        "time": 1689830654000,
        "value": 139.325,
        "_internal_originalTime": 1689830654000
    },
    {
        "time": 1689830656000,
        "value": 139.326,
        "_internal_originalTime": 1689830656000
    },
    {
        "time": 1689830657000,
        "value": 139.326,
        "_internal_originalTime": 1689830657000
    },
    {
        "time": 1689830658000,
        "value": 139.324,
        "_internal_originalTime": 1689830658000
    },
    {
        "time": 1689830660000,
        "value": 139.319,
        "_internal_originalTime": 1689830660000
    },
    {
        "time": 1689830662000,
        "value": 139.325,
        "_internal_originalTime": 1689830662000
    },
    {
        "time": 1689830664000,
        "value": 139.324,
        "_internal_originalTime": 1689830664000
    },
    {
        "time": 1689830667000,
        "value": 139.324,
        "_internal_originalTime": 1689830667000
    },
    {
        "time": 1689830668000,
        "value": 139.325,
        "_internal_originalTime": 1689830668000
    },
    {
        "time": 1689830669000,
        "value": 139.325,
        "_internal_originalTime": 1689830669000
    },
    {
        "time": 1689830670000,
        "value": 139.324,
        "_internal_originalTime": 1689830670000
    },
    {
        "time": 1689830672000,
        "value": 139.323,
        "_internal_originalTime": 1689830672000
    },
    {
        "time": 1689830674000,
        "value": 139.319,
        "_internal_originalTime": 1689830674000
    },
    {
        "time": 1689830675000,
        "value": 139.324,
        "_internal_originalTime": 1689830675000
    },
    {
        "time": 1689830677000,
        "value": 139.319,
        "_internal_originalTime": 1689830677000
    },
    {
        "time": 1689830679000,
        "value": 139.322,
        "_internal_originalTime": 1689830679000
    },
    {
        "time": 1689830680000,
        "value": 139.316,
        "_internal_originalTime": 1689830680000
    },
    {
        "time": 1689830681000,
        "value": 139.319,
        "_internal_originalTime": 1689830681000
    },
    {
        "time": 1689830683000,
        "value": 139.316,
        "_internal_originalTime": 1689830683000
    },
    {
        "time": 1689830684000,
        "value": 139.318,
        "_internal_originalTime": 1689830684000
    },
    {
        "time": 1689830685000,
        "value": 139.322,
        "_internal_originalTime": 1689830685000
    },
    {
        "time": 1689830686000,
        "value": 139.316,
        "_internal_originalTime": 1689830686000
    },
    {
        "time": 1689830687000,
        "value": 139.317,
        "_internal_originalTime": 1689830687000
    },
    {
        "time": 1689830689000,
        "value": 139.322,
        "_internal_originalTime": 1689830689000
    },
    {
        "time": 1689830690000,
        "value": 139.322,
        "_internal_originalTime": 1689830690000
    },
    {
        "time": 1689830692000,
        "value": 139.322,
        "_internal_originalTime": 1689830692000
    },
    {
        "time": 1689830696000,
        "value": 139.32,
        "_internal_originalTime": 1689830696000
    },
    {
        "time": 1689830697000,
        "value": 139.321,
        "_internal_originalTime": 1689830697000
    },
    {
        "time": 1689830701000,
        "value": 139.311,
        "_internal_originalTime": 1689830701000
    },
    {
        "time": 1689830703000,
        "value": 139.316,
        "_internal_originalTime": 1689830703000
    },
    {
        "time": 1689830705000,
        "value": 139.312,
        "_internal_originalTime": 1689830705000
    },
    {
        "time": 1689830707000,
        "value": 139.311,
        "_internal_originalTime": 1689830707000
    },
    {
        "time": 1689830708000,
        "value": 139.307,
        "_internal_originalTime": 1689830708000
    },
    {
        "time": 1689830710000,
        "value": 139.308,
        "_internal_originalTime": 1689830710000
    },
    {
        "time": 1689830711000,
        "value": 139.312,
        "_internal_originalTime": 1689830711000
    },
    {
        "time": 1689830712000,
        "value": 139.307,
        "_internal_originalTime": 1689830712000
    },
    {
        "time": 1689830713000,
        "value": 139.302,
        "_internal_originalTime": 1689830713000
    },
    {
        "time": 1689830714000,
        "value": 139.304,
        "_internal_originalTime": 1689830714000
    },
    {
        "time": 1689830715000,
        "value": 139.311,
        "_internal_originalTime": 1689830715000
    },
    {
        "time": 1689830716000,
        "value": 139.308,
        "_internal_originalTime": 1689830716000
    },
    {
        "time": 1689830719000,
        "value": 139.306,
        "_internal_originalTime": 1689830719000
    },
    {
        "time": 1689830720000,
        "value": 139.309,
        "_internal_originalTime": 1689830720000
    },
    {
        "time": 1689830721000,
        "value": 139.304,
        "_internal_originalTime": 1689830721000
    },
    {
        "time": 1689830722000,
        "value": 139.309,
        "_internal_originalTime": 1689830722000
    },
    {
        "time": 1689830724000,
        "value": 139.308,
        "_internal_originalTime": 1689830724000
    },
    {
        "time": 1689830725000,
        "value": 139.308,
        "_internal_originalTime": 1689830725000
    },
    {
        "time": 1689830727000,
        "value": 139.313,
        "_internal_originalTime": 1689830727000
    },
    {
        "time": 1689830728000,
        "value": 139.313,
        "_internal_originalTime": 1689830728000
    },
    {
        "time": 1689830730000,
        "value": 139.313,
        "_internal_originalTime": 1689830730000
    },
    {
        "time": 1689830731000,
        "value": 139.31,
        "_internal_originalTime": 1689830731000
    },
    {
        "time": 1689830732000,
        "value": 139.315,
        "_internal_originalTime": 1689830732000
    },
    {
        "time": 1689830733000,
        "value": 139.309,
        "_internal_originalTime": 1689830733000
    },
    {
        "time": 1689830735000,
        "value": 139.31,
        "_internal_originalTime": 1689830735000
    },
    {
        "time": 1689830736000,
        "value": 139.31,
        "_internal_originalTime": 1689830736000
    },
    {
        "time": 1689830737000,
        "value": 139.315,
        "_internal_originalTime": 1689830737000
    },
    {
        "time": 1689830739000,
        "value": 139.31,
        "_internal_originalTime": 1689830739000
    },
    {
        "time": 1689830740000,
        "value": 139.314,
        "_internal_originalTime": 1689830740000
    },
    {
        "time": 1689830741000,
        "value": 139.31,
        "_internal_originalTime": 1689830741000
    },
    {
        "time": 1689830746000,
        "value": 139.311,
        "_internal_originalTime": 1689830746000
    },
    {
        "time": 1689830748000,
        "value": 139.315,
        "_internal_originalTime": 1689830748000
    },
    {
        "time": 1689830753000,
        "value": 139.31,
        "_internal_originalTime": 1689830753000
    },
    {
        "time": 1689830754000,
        "value": 139.31,
        "_internal_originalTime": 1689830754000
    },
    {
        "time": 1689830757000,
        "value": 139.316,
        "_internal_originalTime": 1689830757000
    },
    {
        "time": 1689830758000,
        "value": 139.32,
        "_internal_originalTime": 1689830758000
    },
    {
        "time": 1689830759000,
        "value": 139.321,
        "_internal_originalTime": 1689830759000
    },
    {
        "time": 1689830760000,
        "value": 139.316,
        "_internal_originalTime": 1689830760000
    },
    {
        "time": 1689830761000,
        "value": 139.317,
        "_internal_originalTime": 1689830761000
    },
    {
        "time": 1689830762000,
        "value": 139.312,
        "_internal_originalTime": 1689830762000
    },
    {
        "time": 1689830763000,
        "value": 139.318,
        "_internal_originalTime": 1689830763000
    },
    {
        "time": 1689830765000,
        "value": 139.313,
        "_internal_originalTime": 1689830765000
    },
    {
        "time": 1689830767000,
        "value": 139.312,
        "_internal_originalTime": 1689830767000
    },
    {
        "time": 1689830768000,
        "value": 139.316,
        "_internal_originalTime": 1689830768000
    },
    {
        "time": 1689830770000,
        "value": 139.313,
        "_internal_originalTime": 1689830770000
    },
    {
        "time": 1689830771000,
        "value": 139.318,
        "_internal_originalTime": 1689830771000
    },
    {
        "time": 1689830772000,
        "value": 139.318,
        "_internal_originalTime": 1689830772000
    },
    {
        "time": 1689830773000,
        "value": 139.313,
        "_internal_originalTime": 1689830773000
    },
    {
        "time": 1689830774000,
        "value": 139.313,
        "_internal_originalTime": 1689830774000
    },
    {
        "time": 1689830776000,
        "value": 139.315,
        "_internal_originalTime": 1689830776000
    },
    {
        "time": 1689830777000,
        "value": 139.313,
        "_internal_originalTime": 1689830777000
    },
    {
        "time": 1689830778000,
        "value": 139.319,
        "_internal_originalTime": 1689830778000
    },
    {
        "time": 1689830779000,
        "value": 139.325,
        "_internal_originalTime": 1689830779000
    },
    {
        "time": 1689830781000,
        "value": 139.318,
        "_internal_originalTime": 1689830781000
    },
    {
        "time": 1689830782000,
        "value": 139.32,
        "_internal_originalTime": 1689830782000
    },
    {
        "time": 1689830783000,
        "value": 139.32,
        "_internal_originalTime": 1689830783000
    },
    {
        "time": 1689830784000,
        "value": 139.318,
        "_internal_originalTime": 1689830784000
    },
    {
        "time": 1689830789000,
        "value": 139.318,
        "_internal_originalTime": 1689830789000
    },
    {
        "time": 1689830794000,
        "value": 139.319,
        "_internal_originalTime": 1689830794000
    },
    {
        "time": 1689830795000,
        "value": 139.32,
        "_internal_originalTime": 1689830795000
    },
    {
        "time": 1689830797000,
        "value": 139.327,
        "_internal_originalTime": 1689830797000
    },
    {
        "time": 1689830799000,
        "value": 139.325,
        "_internal_originalTime": 1689830799000
    },
    {
        "time": 1689830800000,
        "value": 139.326,
        "_internal_originalTime": 1689830800000
    },
    {
        "time": 1689830801000,
        "value": 139.324,
        "_internal_originalTime": 1689830801000
    },
    {
        "time": 1689830802000,
        "value": 139.322,
        "_internal_originalTime": 1689830802000
    },
    {
        "time": 1689830803000,
        "value": 139.322,
        "_internal_originalTime": 1689830803000
    },
    {
        "time": 1689830805000,
        "value": 139.323,
        "_internal_originalTime": 1689830805000
    },
    {
        "time": 1689830806000,
        "value": 139.323,
        "_internal_originalTime": 1689830806000
    },
    {
        "time": 1689830809000,
        "value": 139.326,
        "_internal_originalTime": 1689830809000
    },
    {
        "time": 1689830811000,
        "value": 139.325,
        "_internal_originalTime": 1689830811000
    },
    {
        "time": 1689830815000,
        "value": 139.32,
        "_internal_originalTime": 1689830815000
    },
    {
        "time": 1689830819000,
        "value": 139.319,
        "_internal_originalTime": 1689830819000
    },
    {
        "time": 1689830820000,
        "value": 139.323,
        "_internal_originalTime": 1689830820000
    },
    {
        "time": 1689830821000,
        "value": 139.318,
        "_internal_originalTime": 1689830821000
    },
    {
        "time": 1689830822000,
        "value": 139.323,
        "_internal_originalTime": 1689830822000
    },
    {
        "time": 1689830825000,
        "value": 139.322,
        "_internal_originalTime": 1689830825000
    },
    {
        "time": 1689830826000,
        "value": 139.323,
        "_internal_originalTime": 1689830826000
    },
    {
        "time": 1689830827000,
        "value": 139.323,
        "_internal_originalTime": 1689830827000
    },
    {
        "time": 1689830831000,
        "value": 139.318,
        "_internal_originalTime": 1689830831000
    },
    {
        "time": 1689830832000,
        "value": 139.322,
        "_internal_originalTime": 1689830832000
    },
    {
        "time": 1689830833000,
        "value": 139.323,
        "_internal_originalTime": 1689830833000
    },
    {
        "time": 1689830834000,
        "value": 139.323,
        "_internal_originalTime": 1689830834000
    },
    {
        "time": 1689830835000,
        "value": 139.323,
        "_internal_originalTime": 1689830835000
    },
    {
        "time": 1689830837000,
        "value": 139.323,
        "_internal_originalTime": 1689830837000
    },
    {
        "time": 1689830838000,
        "value": 139.323,
        "_internal_originalTime": 1689830838000
    },
    {
        "time": 1689830840000,
        "value": 139.323,
        "_internal_originalTime": 1689830840000
    },
    {
        "time": 1689830844000,
        "value": 139.317,
        "_internal_originalTime": 1689830844000
    },
    {
        "time": 1689830847000,
        "value": 139.315,
        "_internal_originalTime": 1689830847000
    },
    {
        "time": 1689830849000,
        "value": 139.322,
        "_internal_originalTime": 1689830849000
    },
    {
        "time": 1689830850000,
        "value": 139.334,
        "_internal_originalTime": 1689830850000
    },
    {
        "time": 1689830851000,
        "value": 139.333,
        "_internal_originalTime": 1689830851000
    },
    {
        "time": 1689830852000,
        "value": 139.328,
        "_internal_originalTime": 1689830852000
    },
    {
        "time": 1689830854000,
        "value": 139.335,
        "_internal_originalTime": 1689830854000
    },
    {
        "time": 1689830855000,
        "value": 139.33,
        "_internal_originalTime": 1689830855000
    },
    {
        "time": 1689830856000,
        "value": 139.329,
        "_internal_originalTime": 1689830856000
    },
    {
        "time": 1689830859000,
        "value": 139.327,
        "_internal_originalTime": 1689830859000
    },
    {
        "time": 1689830860000,
        "value": 139.322,
        "_internal_originalTime": 1689830860000
    },
    {
        "time": 1689830861000,
        "value": 139.32,
        "_internal_originalTime": 1689830861000
    },
    {
        "time": 1689830862000,
        "value": 139.323,
        "_internal_originalTime": 1689830862000
    },
    {
        "time": 1689830864000,
        "value": 139.319,
        "_internal_originalTime": 1689830864000
    },
    {
        "time": 1689830865000,
        "value": 139.326,
        "_internal_originalTime": 1689830865000
    },
    {
        "time": 1689830866000,
        "value": 139.325,
        "_internal_originalTime": 1689830866000
    },
    {
        "time": 1689830867000,
        "value": 139.325,
        "_internal_originalTime": 1689830867000
    },
    {
        "time": 1689830869000,
        "value": 139.324,
        "_internal_originalTime": 1689830869000
    },
    {
        "time": 1689830872000,
        "value": 139.322,
        "_internal_originalTime": 1689830872000
    },
    {
        "time": 1689830873000,
        "value": 139.322,
        "_internal_originalTime": 1689830873000
    },
    {
        "time": 1689830875000,
        "value": 139.324,
        "_internal_originalTime": 1689830875000
    },
    {
        "time": 1689830877000,
        "value": 139.32,
        "_internal_originalTime": 1689830877000
    },
    {
        "time": 1689830879000,
        "value": 139.326,
        "_internal_originalTime": 1689830879000
    },
    {
        "time": 1689830880000,
        "value": 139.322,
        "_internal_originalTime": 1689830880000
    },
    {
        "time": 1689830881000,
        "value": 139.325,
        "_internal_originalTime": 1689830881000
    },
    {
        "time": 1689830882000,
        "value": 139.326,
        "_internal_originalTime": 1689830882000
    },
    {
        "time": 1689830884000,
        "value": 139.328,
        "_internal_originalTime": 1689830884000
    },
    {
        "time": 1689830885000,
        "value": 139.324,
        "_internal_originalTime": 1689830885000
    },
    {
        "time": 1689830887000,
        "value": 139.322,
        "_internal_originalTime": 1689830887000
    },
    {
        "time": 1689830889000,
        "value": 139.326,
        "_internal_originalTime": 1689830889000
    },
    {
        "time": 1689830890000,
        "value": 139.331,
        "_internal_originalTime": 1689830890000
    },
    {
        "time": 1689830891000,
        "value": 139.324,
        "_internal_originalTime": 1689830891000
    },
    {
        "time": 1689830893000,
        "value": 139.325,
        "_internal_originalTime": 1689830893000
    },
    {
        "time": 1689830894000,
        "value": 139.331,
        "_internal_originalTime": 1689830894000
    },
    {
        "time": 1689830899000,
        "value": 139.332,
        "_internal_originalTime": 1689830899000
    },
    {
        "time": 1689830900000,
        "value": 139.326,
        "_internal_originalTime": 1689830900000
    },
    {
        "time": 1689830903000,
        "value": 139.329,
        "_internal_originalTime": 1689830903000
    },
    {
        "time": 1689830904000,
        "value": 139.331,
        "_internal_originalTime": 1689830904000
    },
    {
        "time": 1689830906000,
        "value": 139.326,
        "_internal_originalTime": 1689830906000
    },
    {
        "time": 1689830913000,
        "value": 139.322,
        "_internal_originalTime": 1689830913000
    },
    {
        "time": 1689830914000,
        "value": 139.318,
        "_internal_originalTime": 1689830914000
    },
    {
        "time": 1689830915000,
        "value": 139.318,
        "_internal_originalTime": 1689830915000
    },
    {
        "time": 1689830916000,
        "value": 139.318,
        "_internal_originalTime": 1689830916000
    },
    {
        "time": 1689830917000,
        "value": 139.323,
        "_internal_originalTime": 1689830917000
    },
    {
        "time": 1689830919000,
        "value": 139.318,
        "_internal_originalTime": 1689830919000
    },
    {
        "time": 1689830921000,
        "value": 139.319,
        "_internal_originalTime": 1689830921000
    },
    {
        "time": 1689830929000,
        "value": 139.326,
        "_internal_originalTime": 1689830929000
    },
    {
        "time": 1689830930000,
        "value": 139.331,
        "_internal_originalTime": 1689830930000
    },
    {
        "time": 1689830937000,
        "value": 139.333,
        "_internal_originalTime": 1689830937000
    },
    {
        "time": 1689830938000,
        "value": 139.334,
        "_internal_originalTime": 1689830938000
    },
    {
        "time": 1689830941000,
        "value": 139.335,
        "_internal_originalTime": 1689830941000
    },
    {
        "time": 1689830942000,
        "value": 139.334,
        "_internal_originalTime": 1689830942000
    },
    {
        "time": 1689830943000,
        "value": 139.327,
        "_internal_originalTime": 1689830943000
    },
    {
        "time": 1689830945000,
        "value": 139.331,
        "_internal_originalTime": 1689830945000
    },
    {
        "time": 1689830947000,
        "value": 139.336,
        "_internal_originalTime": 1689830947000
    },
    {
        "time": 1689830948000,
        "value": 139.332,
        "_internal_originalTime": 1689830948000
    },
    {
        "time": 1689830949000,
        "value": 139.332,
        "_internal_originalTime": 1689830949000
    },
    {
        "time": 1689830951000,
        "value": 139.331,
        "_internal_originalTime": 1689830951000
    },
    {
        "time": 1689830956000,
        "value": 139.336,
        "_internal_originalTime": 1689830956000
    },
    {
        "time": 1689830957000,
        "value": 139.336,
        "_internal_originalTime": 1689830957000
    },
    {
        "time": 1689830958000,
        "value": 139.33,
        "_internal_originalTime": 1689830958000
    },
    {
        "time": 1689830959000,
        "value": 139.335,
        "_internal_originalTime": 1689830959000
    },
    {
        "time": 1689830961000,
        "value": 139.339,
        "_internal_originalTime": 1689830961000
    },
    {
        "time": 1689830963000,
        "value": 139.339,
        "_internal_originalTime": 1689830963000
    },
    {
        "time": 1689830964000,
        "value": 139.339,
        "_internal_originalTime": 1689830964000
    },
    {
        "time": 1689830965000,
        "value": 139.343,
        "_internal_originalTime": 1689830965000
    },
    {
        "time": 1689830971000,
        "value": 139.339,
        "_internal_originalTime": 1689830971000
    },
    {
        "time": 1689830972000,
        "value": 139.343,
        "_internal_originalTime": 1689830972000
    },
    {
        "time": 1689830974000,
        "value": 139.345,
        "_internal_originalTime": 1689830974000
    },
    {
        "time": 1689830975000,
        "value": 139.342,
        "_internal_originalTime": 1689830975000
    },
    {
        "time": 1689830976000,
        "value": 139.341,
        "_internal_originalTime": 1689830976000
    },
    {
        "time": 1689830977000,
        "value": 139.341,
        "_internal_originalTime": 1689830977000
    },
    {
        "time": 1689830979000,
        "value": 139.342,
        "_internal_originalTime": 1689830979000
    },
    {
        "time": 1689830981000,
        "value": 139.342,
        "_internal_originalTime": 1689830981000
    },
    {
        "time": 1689830983000,
        "value": 139.34,
        "_internal_originalTime": 1689830983000
    },
    {
        "time": 1689830984000,
        "value": 139.345,
        "_internal_originalTime": 1689830984000
    },
    {
        "time": 1689830985000,
        "value": 139.339,
        "_internal_originalTime": 1689830985000
    },
    {
        "time": 1689830986000,
        "value": 139.344,
        "_internal_originalTime": 1689830986000
    },
    {
        "time": 1689830987000,
        "value": 139.347,
        "_internal_originalTime": 1689830987000
    },
    {
        "time": 1689830989000,
        "value": 139.342,
        "_internal_originalTime": 1689830989000
    },
    {
        "time": 1689830990000,
        "value": 139.34,
        "_internal_originalTime": 1689830990000
    },
    {
        "time": 1689830991000,
        "value": 139.342,
        "_internal_originalTime": 1689830991000
    },
    {
        "time": 1689830992000,
        "value": 139.347,
        "_internal_originalTime": 1689830992000
    },
    {
        "time": 1689830994000,
        "value": 139.34,
        "_internal_originalTime": 1689830994000
    },
    {
        "time": 1689830995000,
        "value": 139.346,
        "_internal_originalTime": 1689830995000
    },
    {
        "time": 1689830996000,
        "value": 139.345,
        "_internal_originalTime": 1689830996000
    },
    {
        "time": 1689830997000,
        "value": 139.347,
        "_internal_originalTime": 1689830997000
    },
    {
        "time": 1689830999000,
        "value": 139.346,
        "_internal_originalTime": 1689830999000
    },
    {
        "time": 1689831000000,
        "value": 139.349,
        "_internal_originalTime": 1689831000000
    },
    {
        "time": 1689831001000,
        "value": 139.346,
        "_internal_originalTime": 1689831001000
    },
    {
        "time": 1689831002000,
        "value": 139.349,
        "_internal_originalTime": 1689831002000
    },
    {
        "time": 1689831003000,
        "value": 139.344,
        "_internal_originalTime": 1689831003000
    },
    {
        "time": 1689831005000,
        "value": 139.346,
        "_internal_originalTime": 1689831005000
    },
    {
        "time": 1689831006000,
        "value": 139.35,
        "_internal_originalTime": 1689831006000
    },
    {
        "time": 1689831007000,
        "value": 139.348,
        "_internal_originalTime": 1689831007000
    },
    {
        "time": 1689831008000,
        "value": 139.349,
        "_internal_originalTime": 1689831008000
    },
    {
        "time": 1689831009000,
        "value": 139.349,
        "_internal_originalTime": 1689831009000
    },
    {
        "time": 1689831011000,
        "value": 139.348,
        "_internal_originalTime": 1689831011000
    },
    {
        "time": 1689831012000,
        "value": 139.353,
        "_internal_originalTime": 1689831012000
    },
    {
        "time": 1689831015000,
        "value": 139.354,
        "_internal_originalTime": 1689831015000
    },
    {
        "time": 1689831016000,
        "value": 139.353,
        "_internal_originalTime": 1689831016000
    },
    {
        "time": 1689831018000,
        "value": 139.348,
        "_internal_originalTime": 1689831018000
    },
    {
        "time": 1689831019000,
        "value": 139.348,
        "_internal_originalTime": 1689831019000
    },
    {
        "time": 1689831020000,
        "value": 139.35,
        "_internal_originalTime": 1689831020000
    },
    {
        "time": 1689831021000,
        "value": 139.354,
        "_internal_originalTime": 1689831021000
    },
    {
        "time": 1689831022000,
        "value": 139.356,
        "_internal_originalTime": 1689831022000
    },
    {
        "time": 1689831024000,
        "value": 139.351,
        "_internal_originalTime": 1689831024000
    },
    {
        "time": 1689831025000,
        "value": 139.353,
        "_internal_originalTime": 1689831025000
    },
    {
        "time": 1689831027000,
        "value": 139.356,
        "_internal_originalTime": 1689831027000
    },
    {
        "time": 1689831030000,
        "value": 139.355,
        "_internal_originalTime": 1689831030000
    },
    {
        "time": 1689831031000,
        "value": 139.351,
        "_internal_originalTime": 1689831031000
    },
    {
        "time": 1689831032000,
        "value": 139.354,
        "_internal_originalTime": 1689831032000
    },
    {
        "time": 1689831033000,
        "value": 139.347,
        "_internal_originalTime": 1689831033000
    },
    {
        "time": 1689831034000,
        "value": 139.354,
        "_internal_originalTime": 1689831034000
    },
    {
        "time": 1689831035000,
        "value": 139.354,
        "_internal_originalTime": 1689831035000
    },
    {
        "time": 1689831036000,
        "value": 139.353,
        "_internal_originalTime": 1689831036000
    },
    {
        "time": 1689831039000,
        "value": 139.353,
        "_internal_originalTime": 1689831039000
    },
    {
        "time": 1689831040000,
        "value": 139.348,
        "_internal_originalTime": 1689831040000
    },
    {
        "time": 1689831041000,
        "value": 139.351,
        "_internal_originalTime": 1689831041000
    },
    {
        "time": 1689831043000,
        "value": 139.348,
        "_internal_originalTime": 1689831043000
    },
    {
        "time": 1689831044000,
        "value": 139.356,
        "_internal_originalTime": 1689831044000
    },
    {
        "time": 1689831045000,
        "value": 139.352,
        "_internal_originalTime": 1689831045000
    },
    {
        "time": 1689831047000,
        "value": 139.352,
        "_internal_originalTime": 1689831047000
    },
    {
        "time": 1689831050000,
        "value": 139.354,
        "_internal_originalTime": 1689831050000
    },
    {
        "time": 1689831051000,
        "value": 139.352,
        "_internal_originalTime": 1689831051000
    },
    {
        "time": 1689831052000,
        "value": 139.355,
        "_internal_originalTime": 1689831052000
    },
    {
        "time": 1689831054000,
        "value": 139.359,
        "_internal_originalTime": 1689831054000
    },
    {
        "time": 1689831055000,
        "value": 139.356,
        "_internal_originalTime": 1689831055000
    },
    {
        "time": 1689831056000,
        "value": 139.359,
        "_internal_originalTime": 1689831056000
    },
    {
        "time": 1689831057000,
        "value": 139.359,
        "_internal_originalTime": 1689831057000
    },
    {
        "time": 1689831059000,
        "value": 139.36,
        "_internal_originalTime": 1689831059000
    },
    {
        "time": 1689831060000,
        "value": 139.361,
        "_internal_originalTime": 1689831060000
    },
    {
        "time": 1689831061000,
        "value": 139.358,
        "_internal_originalTime": 1689831061000
    },
    {
        "time": 1689831062000,
        "value": 139.358,
        "_internal_originalTime": 1689831062000
    },
    {
        "time": 1689831063000,
        "value": 139.357,
        "_internal_originalTime": 1689831063000
    },
    {
        "time": 1689831065000,
        "value": 139.358,
        "_internal_originalTime": 1689831065000
    },
    {
        "time": 1689831066000,
        "value": 139.359,
        "_internal_originalTime": 1689831066000
    },
    {
        "time": 1689831070000,
        "value": 139.358,
        "_internal_originalTime": 1689831070000
    },
    {
        "time": 1689831071000,
        "value": 139.364,
        "_internal_originalTime": 1689831071000
    },
    {
        "time": 1689831072000,
        "value": 139.363,
        "_internal_originalTime": 1689831072000
    },
    {
        "time": 1689831074000,
        "value": 139.364,
        "_internal_originalTime": 1689831074000
    },
    {
        "time": 1689831075000,
        "value": 139.363,
        "_internal_originalTime": 1689831075000
    },
    {
        "time": 1689831077000,
        "value": 139.359,
        "_internal_originalTime": 1689831077000
    },
    {
        "time": 1689831079000,
        "value": 139.372,
        "_internal_originalTime": 1689831079000
    },
    {
        "time": 1689831081000,
        "value": 139.369,
        "_internal_originalTime": 1689831081000
    },
    {
        "time": 1689831082000,
        "value": 139.373,
        "_internal_originalTime": 1689831082000
    },
    {
        "time": 1689831083000,
        "value": 139.37,
        "_internal_originalTime": 1689831083000
    },
    {
        "time": 1689831085000,
        "value": 139.377,
        "_internal_originalTime": 1689831085000
    },
    {
        "time": 1689831086000,
        "value": 139.372,
        "_internal_originalTime": 1689831086000
    },
    {
        "time": 1689831087000,
        "value": 139.373,
        "_internal_originalTime": 1689831087000
    },
    {
        "time": 1689831088000,
        "value": 139.382,
        "_internal_originalTime": 1689831088000
    },
    {
        "time": 1689831089000,
        "value": 139.38,
        "_internal_originalTime": 1689831089000
    },
    {
        "time": 1689831090000,
        "value": 139.38,
        "_internal_originalTime": 1689831090000
    },
    {
        "time": 1689831091000,
        "value": 139.377,
        "_internal_originalTime": 1689831091000
    },
    {
        "time": 1689831093000,
        "value": 139.374,
        "_internal_originalTime": 1689831093000
    },
    {
        "time": 1689831094000,
        "value": 139.372,
        "_internal_originalTime": 1689831094000
    },
    {
        "time": 1689831096000,
        "value": 139.371,
        "_internal_originalTime": 1689831096000
    },
    {
        "time": 1689831097000,
        "value": 139.371,
        "_internal_originalTime": 1689831097000
    },
    {
        "time": 1689831099000,
        "value": 139.372,
        "_internal_originalTime": 1689831099000
    },
    {
        "time": 1689831101000,
        "value": 139.375,
        "_internal_originalTime": 1689831101000
    },
    {
        "time": 1689831103000,
        "value": 139.37,
        "_internal_originalTime": 1689831103000
    },
    {
        "time": 1689831104000,
        "value": 139.371,
        "_internal_originalTime": 1689831104000
    },
    {
        "time": 1689831105000,
        "value": 139.373,
        "_internal_originalTime": 1689831105000
    },
    {
        "time": 1689831106000,
        "value": 139.368,
        "_internal_originalTime": 1689831106000
    },
    {
        "time": 1689831108000,
        "value": 139.37,
        "_internal_originalTime": 1689831108000
    },
    {
        "time": 1689831110000,
        "value": 139.377,
        "_internal_originalTime": 1689831110000
    },
    {
        "time": 1689831111000,
        "value": 139.374,
        "_internal_originalTime": 1689831111000
    },
    {
        "time": 1689831112000,
        "value": 139.375,
        "_internal_originalTime": 1689831112000
    },
    {
        "time": 1689831114000,
        "value": 139.382,
        "_internal_originalTime": 1689831114000
    },
    {
        "time": 1689831115000,
        "value": 139.383,
        "_internal_originalTime": 1689831115000
    },
    {
        "time": 1689831116000,
        "value": 139.382,
        "_internal_originalTime": 1689831116000
    },
    {
        "time": 1689831117000,
        "value": 139.383,
        "_internal_originalTime": 1689831117000
    },
    {
        "time": 1689831118000,
        "value": 139.382,
        "_internal_originalTime": 1689831118000
    },
    {
        "time": 1689831119000,
        "value": 139.382,
        "_internal_originalTime": 1689831119000
    },
    {
        "time": 1689831121000,
        "value": 139.388,
        "_internal_originalTime": 1689831121000
    },
    {
        "time": 1689831123000,
        "value": 139.387,
        "_internal_originalTime": 1689831123000
    },
    {
        "time": 1689831126000,
        "value": 139.383,
        "_internal_originalTime": 1689831126000
    },
    {
        "time": 1689831127000,
        "value": 139.385,
        "_internal_originalTime": 1689831127000
    },
    {
        "time": 1689831131000,
        "value": 139.389,
        "_internal_originalTime": 1689831131000
    },
    {
        "time": 1689831135000,
        "value": 139.384,
        "_internal_originalTime": 1689831135000
    },
    {
        "time": 1689831136000,
        "value": 139.39,
        "_internal_originalTime": 1689831136000
    },
    {
        "time": 1689831137000,
        "value": 139.386,
        "_internal_originalTime": 1689831137000
    },
    {
        "time": 1689831138000,
        "value": 139.39,
        "_internal_originalTime": 1689831138000
    },
    {
        "time": 1689831140000,
        "value": 139.391,
        "_internal_originalTime": 1689831140000
    },
    {
        "time": 1689831141000,
        "value": 139.388,
        "_internal_originalTime": 1689831141000
    },
    {
        "time": 1689831142000,
        "value": 139.386,
        "_internal_originalTime": 1689831142000
    },
    {
        "time": 1689831143000,
        "value": 139.382,
        "_internal_originalTime": 1689831143000
    },
    {
        "time": 1689831144000,
        "value": 139.383,
        "_internal_originalTime": 1689831144000
    },
    {
        "time": 1689831145000,
        "value": 139.385,
        "_internal_originalTime": 1689831145000
    },
    {
        "time": 1689831148000,
        "value": 139.385,
        "_internal_originalTime": 1689831148000
    },
    {
        "time": 1689831149000,
        "value": 139.386,
        "_internal_originalTime": 1689831149000
    },
    {
        "time": 1689831153000,
        "value": 139.385,
        "_internal_originalTime": 1689831153000
    },
    {
        "time": 1689831154000,
        "value": 139.388,
        "_internal_originalTime": 1689831154000
    },
    {
        "time": 1689831157000,
        "value": 139.39,
        "_internal_originalTime": 1689831157000
    },
    {
        "time": 1689831159000,
        "value": 139.388,
        "_internal_originalTime": 1689831159000
    },
    {
        "time": 1689831161000,
        "value": 139.386,
        "_internal_originalTime": 1689831161000
    },
    {
        "time": 1689831163000,
        "value": 139.391,
        "_internal_originalTime": 1689831163000
    },
    {
        "time": 1689831164000,
        "value": 139.391,
        "_internal_originalTime": 1689831164000
    },
    {
        "time": 1689831165000,
        "value": 139.388,
        "_internal_originalTime": 1689831165000
    },
    {
        "time": 1689831166000,
        "value": 139.388,
        "_internal_originalTime": 1689831166000
    },
    {
        "time": 1689831167000,
        "value": 139.391,
        "_internal_originalTime": 1689831167000
    },
    {
        "time": 1689831169000,
        "value": 139.39,
        "_internal_originalTime": 1689831169000
    },
    {
        "time": 1689831171000,
        "value": 139.385,
        "_internal_originalTime": 1689831171000
    },
    {
        "time": 1689831172000,
        "value": 139.389,
        "_internal_originalTime": 1689831172000
    },
    {
        "time": 1689831173000,
        "value": 139.389,
        "_internal_originalTime": 1689831173000
    },
    {
        "time": 1689831178000,
        "value": 139.391,
        "_internal_originalTime": 1689831178000
    },
    {
        "time": 1689831180000,
        "value": 139.388,
        "_internal_originalTime": 1689831180000
    },
    {
        "time": 1689831182000,
        "value": 139.39,
        "_internal_originalTime": 1689831182000
    },
    {
        "time": 1689831183000,
        "value": 139.382,
        "_internal_originalTime": 1689831183000
    },
    {
        "time": 1689831184000,
        "value": 139.384,
        "_internal_originalTime": 1689831184000
    },
    {
        "time": 1689831186000,
        "value": 139.387,
        "_internal_originalTime": 1689831186000
    },
    {
        "time": 1689831188000,
        "value": 139.382,
        "_internal_originalTime": 1689831188000
    },
    {
        "time": 1689831190000,
        "value": 139.38,
        "_internal_originalTime": 1689831190000
    },
    {
        "time": 1689831192000,
        "value": 139.384,
        "_internal_originalTime": 1689831192000
    },
    {
        "time": 1689831193000,
        "value": 139.388,
        "_internal_originalTime": 1689831193000
    },
    {
        "time": 1689831194000,
        "value": 139.388,
        "_internal_originalTime": 1689831194000
    },
    {
        "time": 1689831195000,
        "value": 139.39,
        "_internal_originalTime": 1689831195000
    },
    {
        "time": 1689831196000,
        "value": 139.387,
        "_internal_originalTime": 1689831196000
    },
    {
        "time": 1689831197000,
        "value": 139.39,
        "_internal_originalTime": 1689831197000
    },
    {
        "time": 1689831205000,
        "value": 139.389,
        "_internal_originalTime": 1689831205000
    },
    {
        "time": 1689831206000,
        "value": 139.387,
        "_internal_originalTime": 1689831206000
    },
    {
        "time": 1689831208000,
        "value": 139.387,
        "_internal_originalTime": 1689831208000
    },
    {
        "time": 1689831210000,
        "value": 139.385,
        "_internal_originalTime": 1689831210000
    },
    {
        "time": 1689831212000,
        "value": 139.385,
        "_internal_originalTime": 1689831212000
    },
    {
        "time": 1689831213000,
        "value": 139.39,
        "_internal_originalTime": 1689831213000
    },
    {
        "time": 1689831215000,
        "value": 139.387,
        "_internal_originalTime": 1689831215000
    },
    {
        "time": 1689831216000,
        "value": 139.389,
        "_internal_originalTime": 1689831216000
    },
    {
        "time": 1689831217000,
        "value": 139.384,
        "_internal_originalTime": 1689831217000
    },
    {
        "time": 1689831218000,
        "value": 139.386,
        "_internal_originalTime": 1689831218000
    },
    {
        "time": 1689831220000,
        "value": 139.39,
        "_internal_originalTime": 1689831220000
    },
    {
        "time": 1689831221000,
        "value": 139.387,
        "_internal_originalTime": 1689831221000
    },
    {
        "time": 1689831222000,
        "value": 139.385,
        "_internal_originalTime": 1689831222000
    },
    {
        "time": 1689831223000,
        "value": 139.387,
        "_internal_originalTime": 1689831223000
    },
    {
        "time": 1689831226000,
        "value": 139.388,
        "_internal_originalTime": 1689831226000
    },
    {
        "time": 1689831228000,
        "value": 139.388,
        "_internal_originalTime": 1689831228000
    },
    {
        "time": 1689831229000,
        "value": 139.386,
        "_internal_originalTime": 1689831229000
    },
    {
        "time": 1689831230000,
        "value": 139.387,
        "_internal_originalTime": 1689831230000
    },
    {
        "time": 1689831232000,
        "value": 139.386,
        "_internal_originalTime": 1689831232000
    },
    {
        "time": 1689831238000,
        "value": 139.391,
        "_internal_originalTime": 1689831238000
    },
    {
        "time": 1689831240000,
        "value": 139.391,
        "_internal_originalTime": 1689831240000
    },
    {
        "time": 1689831241000,
        "value": 139.387,
        "_internal_originalTime": 1689831241000
    },
    {
        "time": 1689831243000,
        "value": 139.39,
        "_internal_originalTime": 1689831243000
    },
    {
        "time": 1689831244000,
        "value": 139.387,
        "_internal_originalTime": 1689831244000
    },
    {
        "time": 1689831247000,
        "value": 139.387,
        "_internal_originalTime": 1689831247000
    },
    {
        "time": 1689831249000,
        "value": 139.387,
        "_internal_originalTime": 1689831249000
    },
    {
        "time": 1689831250000,
        "value": 139.388,
        "_internal_originalTime": 1689831250000
    },
    {
        "time": 1689831251000,
        "value": 139.387,
        "_internal_originalTime": 1689831251000
    },
    {
        "time": 1689831253000,
        "value": 139.389,
        "_internal_originalTime": 1689831253000
    },
    {
        "time": 1689831254000,
        "value": 139.388,
        "_internal_originalTime": 1689831254000
    },
    {
        "time": 1689831256000,
        "value": 139.385,
        "_internal_originalTime": 1689831256000
    },
    {
        "time": 1689831257000,
        "value": 139.385,
        "_internal_originalTime": 1689831257000
    },
    {
        "time": 1689831258000,
        "value": 139.384,
        "_internal_originalTime": 1689831258000
    },
    {
        "time": 1689831262000,
        "value": 139.384,
        "_internal_originalTime": 1689831262000
    },
    {
        "time": 1689831265000,
        "value": 139.385,
        "_internal_originalTime": 1689831265000
    },
    {
        "time": 1689831267000,
        "value": 139.384,
        "_internal_originalTime": 1689831267000
    },
    {
        "time": 1689831268000,
        "value": 139.378,
        "_internal_originalTime": 1689831268000
    },
    {
        "time": 1689831269000,
        "value": 139.382,
        "_internal_originalTime": 1689831269000
    },
    {
        "time": 1689831270000,
        "value": 139.379,
        "_internal_originalTime": 1689831270000
    },
    {
        "time": 1689831272000,
        "value": 139.382,
        "_internal_originalTime": 1689831272000
    },
    {
        "time": 1689831274000,
        "value": 139.382,
        "_internal_originalTime": 1689831274000
    },
    {
        "time": 1689831275000,
        "value": 139.376,
        "_internal_originalTime": 1689831275000
    },
    {
        "time": 1689831276000,
        "value": 139.379,
        "_internal_originalTime": 1689831276000
    },
    {
        "time": 1689831277000,
        "value": 139.38,
        "_internal_originalTime": 1689831277000
    },
    {
        "time": 1689831279000,
        "value": 139.376,
        "_internal_originalTime": 1689831279000
    },
    {
        "time": 1689831281000,
        "value": 139.374,
        "_internal_originalTime": 1689831281000
    },
    {
        "time": 1689831282000,
        "value": 139.38,
        "_internal_originalTime": 1689831282000
    },
    {
        "time": 1689831285000,
        "value": 139.381,
        "_internal_originalTime": 1689831285000
    },
    {
        "time": 1689831288000,
        "value": 139.376,
        "_internal_originalTime": 1689831288000
    },
    {
        "time": 1689831289000,
        "value": 139.379,
        "_internal_originalTime": 1689831289000
    },
    {
        "time": 1689831293000,
        "value": 139.378,
        "_internal_originalTime": 1689831293000
    },
    {
        "time": 1689831295000,
        "value": 139.376,
        "_internal_originalTime": 1689831295000
    },
    {
        "time": 1689831296000,
        "value": 139.376,
        "_internal_originalTime": 1689831296000
    },
    {
        "time": 1689831299000,
        "value": 139.384,
        "_internal_originalTime": 1689831299000
    },
    {
        "time": 1689831300000,
        "value": 139.387,
        "_internal_originalTime": 1689831300000
    },
    {
        "time": 1689831301000,
        "value": 139.387,
        "_internal_originalTime": 1689831301000
    },
    {
        "time": 1689831303000,
        "value": 139.384,
        "_internal_originalTime": 1689831303000
    },
    {
        "time": 1689831305000,
        "value": 139.384,
        "_internal_originalTime": 1689831305000
    },
    {
        "time": 1689831306000,
        "value": 139.388,
        "_internal_originalTime": 1689831306000
    },
    {
        "time": 1689831308000,
        "value": 139.397,
        "_internal_originalTime": 1689831308000
    },
    {
        "time": 1689831309000,
        "value": 139.4,
        "_internal_originalTime": 1689831309000
    },
    {
        "time": 1689831310000,
        "value": 139.409,
        "_internal_originalTime": 1689831310000
    },
    {
        "time": 1689831311000,
        "value": 139.42,
        "_internal_originalTime": 1689831311000
    },
    {
        "time": 1689831312000,
        "value": 139.418,
        "_internal_originalTime": 1689831312000
    },
    {
        "time": 1689831313000,
        "value": 139.426,
        "_internal_originalTime": 1689831313000
    },
    {
        "time": 1689831315000,
        "value": 139.422,
        "_internal_originalTime": 1689831315000
    },
    {
        "time": 1689831316000,
        "value": 139.421,
        "_internal_originalTime": 1689831316000
    },
    {
        "time": 1689831318000,
        "value": 139.423,
        "_internal_originalTime": 1689831318000
    },
    {
        "time": 1689831319000,
        "value": 139.419,
        "_internal_originalTime": 1689831319000
    },
    {
        "time": 1689831321000,
        "value": 139.411,
        "_internal_originalTime": 1689831321000
    },
    {
        "time": 1689831330000,
        "value": 139.4,
        "_internal_originalTime": 1689831330000
    },
    {
        "time": 1689831331000,
        "value": 139.404,
        "_internal_originalTime": 1689831331000
    },
    {
        "time": 1689831332000,
        "value": 139.4,
        "_internal_originalTime": 1689831332000
    },
    {
        "time": 1689831333000,
        "value": 139.409,
        "_internal_originalTime": 1689831333000
    },
    {
        "time": 1689831334000,
        "value": 139.408,
        "_internal_originalTime": 1689831334000
    },
    {
        "time": 1689831336000,
        "value": 139.409,
        "_internal_originalTime": 1689831336000
    },
    {
        "time": 1689831337000,
        "value": 139.41,
        "_internal_originalTime": 1689831337000
    },
    {
        "time": 1689831341000,
        "value": 139.406,
        "_internal_originalTime": 1689831341000
    },
    {
        "time": 1689831342000,
        "value": 139.41,
        "_internal_originalTime": 1689831342000
    },
    {
        "time": 1689831344000,
        "value": 139.411,
        "_internal_originalTime": 1689831344000
    },
    {
        "time": 1689831345000,
        "value": 139.415,
        "_internal_originalTime": 1689831345000
    },
    {
        "time": 1689831346000,
        "value": 139.412,
        "_internal_originalTime": 1689831346000
    },
    {
        "time": 1689831347000,
        "value": 139.417,
        "_internal_originalTime": 1689831347000
    },
    {
        "time": 1689831348000,
        "value": 139.418,
        "_internal_originalTime": 1689831348000
    },
    {
        "time": 1689831349000,
        "value": 139.415,
        "_internal_originalTime": 1689831349000
    },
    {
        "time": 1689831350000,
        "value": 139.42,
        "_internal_originalTime": 1689831350000
    },
    {
        "time": 1689831353000,
        "value": 139.42,
        "_internal_originalTime": 1689831353000
    },
    {
        "time": 1689831357000,
        "value": 139.417,
        "_internal_originalTime": 1689831357000
    },
    {
        "time": 1689831359000,
        "value": 139.417,
        "_internal_originalTime": 1689831359000
    },
    {
        "time": 1689831360000,
        "value": 139.414,
        "_internal_originalTime": 1689831360000
    },
    {
        "time": 1689831361000,
        "value": 139.416,
        "_internal_originalTime": 1689831361000
    },
    {
        "time": 1689831362000,
        "value": 139.419,
        "_internal_originalTime": 1689831362000
    },
    {
        "time": 1689831363000,
        "value": 139.42,
        "_internal_originalTime": 1689831363000
    },
    {
        "time": 1689831367000,
        "value": 139.425,
        "_internal_originalTime": 1689831367000
    },
    {
        "time": 1689831369000,
        "value": 139.425,
        "_internal_originalTime": 1689831369000
    },
    {
        "time": 1689831370000,
        "value": 139.427,
        "_internal_originalTime": 1689831370000
    },
    {
        "time": 1689831371000,
        "value": 139.424,
        "_internal_originalTime": 1689831371000
    },
    {
        "time": 1689831372000,
        "value": 139.42,
        "_internal_originalTime": 1689831372000
    },
    {
        "time": 1689831374000,
        "value": 139.427,
        "_internal_originalTime": 1689831374000
    },
    {
        "time": 1689831381000,
        "value": 139.422,
        "_internal_originalTime": 1689831381000
    },
    {
        "time": 1689831382000,
        "value": 139.423,
        "_internal_originalTime": 1689831382000
    },
    {
        "time": 1689831383000,
        "value": 139.427,
        "_internal_originalTime": 1689831383000
    },
    {
        "time": 1689831386000,
        "value": 139.427,
        "_internal_originalTime": 1689831386000
    },
    {
        "time": 1689831388000,
        "value": 139.42,
        "_internal_originalTime": 1689831388000
    },
    {
        "time": 1689831389000,
        "value": 139.424,
        "_internal_originalTime": 1689831389000
    },
    {
        "time": 1689831391000,
        "value": 139.424,
        "_internal_originalTime": 1689831391000
    },
    {
        "time": 1689831392000,
        "value": 139.425,
        "_internal_originalTime": 1689831392000
    },
    {
        "time": 1689831394000,
        "value": 139.423,
        "_internal_originalTime": 1689831394000
    },
    {
        "time": 1689831396000,
        "value": 139.422,
        "_internal_originalTime": 1689831396000
    },
    {
        "time": 1689831398000,
        "value": 139.422,
        "_internal_originalTime": 1689831398000
    },
    {
        "time": 1689831400000,
        "value": 139.421,
        "_internal_originalTime": 1689831400000
    },
    {
        "time": 1689831401000,
        "value": 139.422,
        "_internal_originalTime": 1689831401000
    },
    {
        "time": 1689831402000,
        "value": 139.421,
        "_internal_originalTime": 1689831402000
    },
    {
        "time": 1689831403000,
        "value": 139.425,
        "_internal_originalTime": 1689831403000
    },
    {
        "time": 1689831404000,
        "value": 139.417,
        "_internal_originalTime": 1689831404000
    },
    {
        "time": 1689831406000,
        "value": 139.42,
        "_internal_originalTime": 1689831406000
    },
    {
        "time": 1689831407000,
        "value": 139.416,
        "_internal_originalTime": 1689831407000
    },
    {
        "time": 1689831408000,
        "value": 139.426,
        "_internal_originalTime": 1689831408000
    },
    {
        "time": 1689831410000,
        "value": 139.426,
        "_internal_originalTime": 1689831410000
    },
    {
        "time": 1689831411000,
        "value": 139.426,
        "_internal_originalTime": 1689831411000
    },
    {
        "time": 1689831413000,
        "value": 139.426,
        "_internal_originalTime": 1689831413000
    },
    {
        "time": 1689831414000,
        "value": 139.42,
        "_internal_originalTime": 1689831414000
    },
    {
        "time": 1689831415000,
        "value": 139.426,
        "_internal_originalTime": 1689831415000
    },
    {
        "time": 1689831416000,
        "value": 139.426,
        "_internal_originalTime": 1689831416000
    },
    {
        "time": 1689831418000,
        "value": 139.426,
        "_internal_originalTime": 1689831418000
    },
    {
        "time": 1689831419000,
        "value": 139.426,
        "_internal_originalTime": 1689831419000
    },
    {
        "time": 1689831421000,
        "value": 139.424,
        "_internal_originalTime": 1689831421000
    },
    {
        "time": 1689831422000,
        "value": 139.422,
        "_internal_originalTime": 1689831422000
    },
    {
        "time": 1689831424000,
        "value": 139.426,
        "_internal_originalTime": 1689831424000
    },
    {
        "time": 1689831426000,
        "value": 139.426,
        "_internal_originalTime": 1689831426000
    },
    {
        "time": 1689831427000,
        "value": 139.426,
        "_internal_originalTime": 1689831427000
    },
    {
        "time": 1689831430000,
        "value": 139.427,
        "_internal_originalTime": 1689831430000
    },
    {
        "time": 1689831432000,
        "value": 139.424,
        "_internal_originalTime": 1689831432000
    },
    {
        "time": 1689831435000,
        "value": 139.426,
        "_internal_originalTime": 1689831435000
    },
    {
        "time": 1689831437000,
        "value": 139.426,
        "_internal_originalTime": 1689831437000
    },
    {
        "time": 1689831440000,
        "value": 139.422,
        "_internal_originalTime": 1689831440000
    },
    {
        "time": 1689831441000,
        "value": 139.422,
        "_internal_originalTime": 1689831441000
    },
    {
        "time": 1689831442000,
        "value": 139.423,
        "_internal_originalTime": 1689831442000
    },
    {
        "time": 1689831443000,
        "value": 139.422,
        "_internal_originalTime": 1689831443000
    },
    {
        "time": 1689831447000,
        "value": 139.426,
        "_internal_originalTime": 1689831447000
    },
    {
        "time": 1689831448000,
        "value": 139.426,
        "_internal_originalTime": 1689831448000
    },
    {
        "time": 1689831450000,
        "value": 139.426,
        "_internal_originalTime": 1689831450000
    },
    {
        "time": 1689831453000,
        "value": 139.42,
        "_internal_originalTime": 1689831453000
    },
    {
        "time": 1689831459000,
        "value": 139.422,
        "_internal_originalTime": 1689831459000
    },
    {
        "time": 1689831461000,
        "value": 139.423,
        "_internal_originalTime": 1689831461000
    },
    {
        "time": 1689831462000,
        "value": 139.427,
        "_internal_originalTime": 1689831462000
    },
    {
        "time": 1689831463000,
        "value": 139.427,
        "_internal_originalTime": 1689831463000
    },
    {
        "time": 1689831464000,
        "value": 139.427,
        "_internal_originalTime": 1689831464000
    },
    {
        "time": 1689831466000,
        "value": 139.422,
        "_internal_originalTime": 1689831466000
    },
    {
        "time": 1689831467000,
        "value": 139.427,
        "_internal_originalTime": 1689831467000
    },
    {
        "time": 1689831469000,
        "value": 139.423,
        "_internal_originalTime": 1689831469000
    },
    {
        "time": 1689831470000,
        "value": 139.427,
        "_internal_originalTime": 1689831470000
    },
    {
        "time": 1689831471000,
        "value": 139.427,
        "_internal_originalTime": 1689831471000
    },
    {
        "time": 1689831472000,
        "value": 139.426,
        "_internal_originalTime": 1689831472000
    },
    {
        "time": 1689831474000,
        "value": 139.426,
        "_internal_originalTime": 1689831474000
    },
    {
        "time": 1689831478000,
        "value": 139.426,
        "_internal_originalTime": 1689831478000
    },
    {
        "time": 1689831480000,
        "value": 139.416,
        "_internal_originalTime": 1689831480000
    },
    {
        "time": 1689831481000,
        "value": 139.418,
        "_internal_originalTime": 1689831481000
    },
    {
        "time": 1689831482000,
        "value": 139.419,
        "_internal_originalTime": 1689831482000
    },
    {
        "time": 1689831483000,
        "value": 139.42,
        "_internal_originalTime": 1689831483000
    },
    {
        "time": 1689831484000,
        "value": 139.42,
        "_internal_originalTime": 1689831484000
    },
    {
        "time": 1689831485000,
        "value": 139.42,
        "_internal_originalTime": 1689831485000
    },
    {
        "time": 1689831487000,
        "value": 139.418,
        "_internal_originalTime": 1689831487000
    },
    {
        "time": 1689831488000,
        "value": 139.42,
        "_internal_originalTime": 1689831488000
    },
    {
        "time": 1689831489000,
        "value": 139.421,
        "_internal_originalTime": 1689831489000
    },
    {
        "time": 1689831490000,
        "value": 139.416,
        "_internal_originalTime": 1689831490000
    },
    {
        "time": 1689831492000,
        "value": 139.42,
        "_internal_originalTime": 1689831492000
    },
    {
        "time": 1689831493000,
        "value": 139.416,
        "_internal_originalTime": 1689831493000
    },
    {
        "time": 1689831495000,
        "value": 139.419,
        "_internal_originalTime": 1689831495000
    },
    {
        "time": 1689831497000,
        "value": 139.419,
        "_internal_originalTime": 1689831497000
    },
    {
        "time": 1689831498000,
        "value": 139.419,
        "_internal_originalTime": 1689831498000
    },
    {
        "time": 1689831500000,
        "value": 139.42,
        "_internal_originalTime": 1689831500000
    },
    {
        "time": 1689831501000,
        "value": 139.416,
        "_internal_originalTime": 1689831501000
    },
    {
        "time": 1689831504000,
        "value": 139.416,
        "_internal_originalTime": 1689831504000
    },
    {
        "time": 1689831505000,
        "value": 139.421,
        "_internal_originalTime": 1689831505000
    },
    {
        "time": 1689831508000,
        "value": 139.42,
        "_internal_originalTime": 1689831508000
    },
    {
        "time": 1689831511000,
        "value": 139.416,
        "_internal_originalTime": 1689831511000
    },
    {
        "time": 1689831512000,
        "value": 139.419,
        "_internal_originalTime": 1689831512000
    },
    {
        "time": 1689831514000,
        "value": 139.411,
        "_internal_originalTime": 1689831514000
    },
    {
        "time": 1689831516000,
        "value": 139.415,
        "_internal_originalTime": 1689831516000
    },
    {
        "time": 1689831517000,
        "value": 139.414,
        "_internal_originalTime": 1689831517000
    },
    {
        "time": 1689831518000,
        "value": 139.417,
        "_internal_originalTime": 1689831518000
    },
    {
        "time": 1689831520000,
        "value": 139.417,
        "_internal_originalTime": 1689831520000
    },
    {
        "time": 1689831521000,
        "value": 139.413,
        "_internal_originalTime": 1689831521000
    },
    {
        "time": 1689831524000,
        "value": 139.417,
        "_internal_originalTime": 1689831524000
    },
    {
        "time": 1689831526000,
        "value": 139.416,
        "_internal_originalTime": 1689831526000
    },
    {
        "time": 1689831527000,
        "value": 139.407,
        "_internal_originalTime": 1689831527000
    },
    {
        "time": 1689831528000,
        "value": 139.408,
        "_internal_originalTime": 1689831528000
    },
    {
        "time": 1689831530000,
        "value": 139.411,
        "_internal_originalTime": 1689831530000
    },
    {
        "time": 1689831531000,
        "value": 139.412,
        "_internal_originalTime": 1689831531000
    },
    {
        "time": 1689831532000,
        "value": 139.412,
        "_internal_originalTime": 1689831532000
    },
    {
        "time": 1689831534000,
        "value": 139.414,
        "_internal_originalTime": 1689831534000
    },
    {
        "time": 1689831536000,
        "value": 139.414,
        "_internal_originalTime": 1689831536000
    },
    {
        "time": 1689831537000,
        "value": 139.414,
        "_internal_originalTime": 1689831537000
    },
    {
        "time": 1689831538000,
        "value": 139.418,
        "_internal_originalTime": 1689831538000
    },
    {
        "time": 1689831539000,
        "value": 139.415,
        "_internal_originalTime": 1689831539000
    },
    {
        "time": 1689831540000,
        "value": 139.418,
        "_internal_originalTime": 1689831540000
    },
    {
        "time": 1689831541000,
        "value": 139.414,
        "_internal_originalTime": 1689831541000
    },
    {
        "time": 1689831542000,
        "value": 139.418,
        "_internal_originalTime": 1689831542000
    },
    {
        "time": 1689831544000,
        "value": 139.418,
        "_internal_originalTime": 1689831544000
    },
    {
        "time": 1689831546000,
        "value": 139.418,
        "_internal_originalTime": 1689831546000
    },
    {
        "time": 1689831547000,
        "value": 139.42,
        "_internal_originalTime": 1689831547000
    },
    {
        "time": 1689831549000,
        "value": 139.415,
        "_internal_originalTime": 1689831549000
    },
    {
        "time": 1689831551000,
        "value": 139.414,
        "_internal_originalTime": 1689831551000
    },
    {
        "time": 1689831552000,
        "value": 139.418,
        "_internal_originalTime": 1689831552000
    },
    {
        "time": 1689831553000,
        "value": 139.418,
        "_internal_originalTime": 1689831553000
    },
    {
        "time": 1689831554000,
        "value": 139.418,
        "_internal_originalTime": 1689831554000
    },
    {
        "time": 1689831555000,
        "value": 139.413,
        "_internal_originalTime": 1689831555000
    },
    {
        "time": 1689831556000,
        "value": 139.414,
        "_internal_originalTime": 1689831556000
    },
    {
        "time": 1689831559000,
        "value": 139.412,
        "_internal_originalTime": 1689831559000
    },
    {
        "time": 1689831561000,
        "value": 139.414,
        "_internal_originalTime": 1689831561000
    },
    {
        "time": 1689831564000,
        "value": 139.418,
        "_internal_originalTime": 1689831564000
    },
    {
        "time": 1689831565000,
        "value": 139.415,
        "_internal_originalTime": 1689831565000
    },
    {
        "time": 1689831566000,
        "value": 139.418,
        "_internal_originalTime": 1689831566000
    },
    {
        "time": 1689831567000,
        "value": 139.418,
        "_internal_originalTime": 1689831567000
    },
    {
        "time": 1689831568000,
        "value": 139.418,
        "_internal_originalTime": 1689831568000
    },
    {
        "time": 1689831570000,
        "value": 139.416,
        "_internal_originalTime": 1689831570000
    },
    {
        "time": 1689831571000,
        "value": 139.416,
        "_internal_originalTime": 1689831571000
    },
    {
        "time": 1689831572000,
        "value": 139.416,
        "_internal_originalTime": 1689831572000
    },
    {
        "time": 1689831574000,
        "value": 139.428,
        "_internal_originalTime": 1689831574000
    },
    {
        "time": 1689831576000,
        "value": 139.429,
        "_internal_originalTime": 1689831576000
    },
    {
        "time": 1689831577000,
        "value": 139.429,
        "_internal_originalTime": 1689831577000
    },
    {
        "time": 1689831578000,
        "value": 139.43,
        "_internal_originalTime": 1689831578000
    },
    {
        "time": 1689831579000,
        "value": 139.436,
        "_internal_originalTime": 1689831579000
    },
    {
        "time": 1689831580000,
        "value": 139.436,
        "_internal_originalTime": 1689831580000
    },
    {
        "time": 1689831583000,
        "value": 139.437,
        "_internal_originalTime": 1689831583000
    },
    {
        "time": 1689831585000,
        "value": 139.446,
        "_internal_originalTime": 1689831585000
    },
    {
        "time": 1689831586000,
        "value": 139.456,
        "_internal_originalTime": 1689831586000
    },
    {
        "time": 1689831587000,
        "value": 139.466,
        "_internal_originalTime": 1689831587000
    },
    {
        "time": 1689831588000,
        "value": 139.466,
        "_internal_originalTime": 1689831588000
    },
    {
        "time": 1689831589000,
        "value": 139.466,
        "_internal_originalTime": 1689831589000
    },
    {
        "time": 1689831591000,
        "value": 139.465,
        "_internal_originalTime": 1689831591000
    },
    {
        "time": 1689831592000,
        "value": 139.463,
        "_internal_originalTime": 1689831592000
    },
    {
        "time": 1689831593000,
        "value": 139.465,
        "_internal_originalTime": 1689831593000
    },
    {
        "time": 1689831594000,
        "value": 139.464,
        "_internal_originalTime": 1689831594000
    },
    {
        "time": 1689831596000,
        "value": 139.463,
        "_internal_originalTime": 1689831596000
    },
    {
        "time": 1689831598000,
        "value": 139.469,
        "_internal_originalTime": 1689831598000
    },
    {
        "time": 1689831600000,
        "value": 139.465,
        "_internal_originalTime": 1689831600000
    },
    {
        "time": 1689831602000,
        "value": 139.466,
        "_internal_originalTime": 1689831602000
    },
    {
        "time": 1689831603000,
        "value": 139.467,
        "_internal_originalTime": 1689831603000
    },
    {
        "time": 1689831604000,
        "value": 139.468,
        "_internal_originalTime": 1689831604000
    },
    {
        "time": 1689831605000,
        "value": 139.467,
        "_internal_originalTime": 1689831605000
    },
    {
        "time": 1689831607000,
        "value": 139.457,
        "_internal_originalTime": 1689831607000
    },
    {
        "time": 1689831609000,
        "value": 139.458,
        "_internal_originalTime": 1689831609000
    },
    {
        "time": 1689831611000,
        "value": 139.465,
        "_internal_originalTime": 1689831611000
    },
    {
        "time": 1689831612000,
        "value": 139.46,
        "_internal_originalTime": 1689831612000
    },
    {
        "time": 1689831613000,
        "value": 139.46,
        "_internal_originalTime": 1689831613000
    },
    {
        "time": 1689831617000,
        "value": 139.459,
        "_internal_originalTime": 1689831617000
    },
    {
        "time": 1689831618000,
        "value": 139.458,
        "_internal_originalTime": 1689831618000
    },
    {
        "time": 1689831619000,
        "value": 139.455,
        "_internal_originalTime": 1689831619000
    },
    {
        "time": 1689831621000,
        "value": 139.457,
        "_internal_originalTime": 1689831621000
    },
    {
        "time": 1689831622000,
        "value": 139.451,
        "_internal_originalTime": 1689831622000
    },
    {
        "time": 1689831625000,
        "value": 139.458,
        "_internal_originalTime": 1689831625000
    },
    {
        "time": 1689831626000,
        "value": 139.445,
        "_internal_originalTime": 1689831626000
    },
    {
        "time": 1689831627000,
        "value": 139.448,
        "_internal_originalTime": 1689831627000
    },
    {
        "time": 1689831628000,
        "value": 139.452,
        "_internal_originalTime": 1689831628000
    },
    {
        "time": 1689831630000,
        "value": 139.453,
        "_internal_originalTime": 1689831630000
    },
    {
        "time": 1689831632000,
        "value": 139.458,
        "_internal_originalTime": 1689831632000
    },
    {
        "time": 1689831634000,
        "value": 139.453,
        "_internal_originalTime": 1689831634000
    },
    {
        "time": 1689831636000,
        "value": 139.454,
        "_internal_originalTime": 1689831636000
    },
    {
        "time": 1689831637000,
        "value": 139.456,
        "_internal_originalTime": 1689831637000
    },
    {
        "time": 1689831638000,
        "value": 139.456,
        "_internal_originalTime": 1689831638000
    },
    {
        "time": 1689831639000,
        "value": 139.457,
        "_internal_originalTime": 1689831639000
    },
    {
        "time": 1689831641000,
        "value": 139.46,
        "_internal_originalTime": 1689831641000
    },
    {
        "time": 1689831642000,
        "value": 139.457,
        "_internal_originalTime": 1689831642000
    },
    {
        "time": 1689831643000,
        "value": 139.456,
        "_internal_originalTime": 1689831643000
    },
    {
        "time": 1689831644000,
        "value": 139.457,
        "_internal_originalTime": 1689831644000
    },
    {
        "time": 1689831645000,
        "value": 139.461,
        "_internal_originalTime": 1689831645000
    },
    {
        "time": 1689831648000,
        "value": 139.461,
        "_internal_originalTime": 1689831648000
    },
    {
        "time": 1689831649000,
        "value": 139.461,
        "_internal_originalTime": 1689831649000
    },
    {
        "time": 1689831650000,
        "value": 139.461,
        "_internal_originalTime": 1689831650000
    },
    {
        "time": 1689831651000,
        "value": 139.457,
        "_internal_originalTime": 1689831651000
    },
    {
        "time": 1689831652000,
        "value": 139.456,
        "_internal_originalTime": 1689831652000
    },
    {
        "time": 1689831655000,
        "value": 139.456,
        "_internal_originalTime": 1689831655000
    },
    {
        "time": 1689831657000,
        "value": 139.463,
        "_internal_originalTime": 1689831657000
    },
    {
        "time": 1689831658000,
        "value": 139.461,
        "_internal_originalTime": 1689831658000
    },
    {
        "time": 1689831660000,
        "value": 139.465,
        "_internal_originalTime": 1689831660000
    },
    {
        "time": 1689831661000,
        "value": 139.465,
        "_internal_originalTime": 1689831661000
    },
    {
        "time": 1689831662000,
        "value": 139.474,
        "_internal_originalTime": 1689831662000
    },
    {
        "time": 1689831664000,
        "value": 139.467,
        "_internal_originalTime": 1689831664000
    },
    {
        "time": 1689831665000,
        "value": 139.47,
        "_internal_originalTime": 1689831665000
    },
    {
        "time": 1689831667000,
        "value": 139.474,
        "_internal_originalTime": 1689831667000
    },
    {
        "time": 1689831670000,
        "value": 139.474,
        "_internal_originalTime": 1689831670000
    },
    {
        "time": 1689831671000,
        "value": 139.474,
        "_internal_originalTime": 1689831671000
    },
    {
        "time": 1689831673000,
        "value": 139.474,
        "_internal_originalTime": 1689831673000
    },
    {
        "time": 1689831674000,
        "value": 139.474,
        "_internal_originalTime": 1689831674000
    },
    {
        "time": 1689831676000,
        "value": 139.473,
        "_internal_originalTime": 1689831676000
    },
    {
        "time": 1689831677000,
        "value": 139.473,
        "_internal_originalTime": 1689831677000
    },
    {
        "time": 1689831679000,
        "value": 139.468,
        "_internal_originalTime": 1689831679000
    },
    {
        "time": 1689831681000,
        "value": 139.473,
        "_internal_originalTime": 1689831681000
    },
    {
        "time": 1689831684000,
        "value": 139.473,
        "_internal_originalTime": 1689831684000
    },
    {
        "time": 1689831685000,
        "value": 139.468,
        "_internal_originalTime": 1689831685000
    },
    {
        "time": 1689831686000,
        "value": 139.474,
        "_internal_originalTime": 1689831686000
    },
    {
        "time": 1689831689000,
        "value": 139.474,
        "_internal_originalTime": 1689831689000
    },
    {
        "time": 1689831690000,
        "value": 139.474,
        "_internal_originalTime": 1689831690000
    },
    {
        "time": 1689831692000,
        "value": 139.475,
        "_internal_originalTime": 1689831692000
    },
    {
        "time": 1689831693000,
        "value": 139.474,
        "_internal_originalTime": 1689831693000
    },
    {
        "time": 1689831694000,
        "value": 139.474,
        "_internal_originalTime": 1689831694000
    },
    {
        "time": 1689831695000,
        "value": 139.475,
        "_internal_originalTime": 1689831695000
    },
    {
        "time": 1689831699000,
        "value": 139.47,
        "_internal_originalTime": 1689831699000
    },
    {
        "time": 1689831700000,
        "value": 139.475,
        "_internal_originalTime": 1689831700000
    },
    {
        "time": 1689831701000,
        "value": 139.473,
        "_internal_originalTime": 1689831701000
    },
    {
        "time": 1689831702000,
        "value": 139.47,
        "_internal_originalTime": 1689831702000
    },
    {
        "time": 1689831706000,
        "value": 139.475,
        "_internal_originalTime": 1689831706000
    },
    {
        "time": 1689831707000,
        "value": 139.47,
        "_internal_originalTime": 1689831707000
    },
    {
        "time": 1689831709000,
        "value": 139.475,
        "_internal_originalTime": 1689831709000
    },
    {
        "time": 1689831711000,
        "value": 139.47,
        "_internal_originalTime": 1689831711000
    },
    {
        "time": 1689831712000,
        "value": 139.468,
        "_internal_originalTime": 1689831712000
    },
    {
        "time": 1689831713000,
        "value": 139.465,
        "_internal_originalTime": 1689831713000
    },
    {
        "time": 1689831714000,
        "value": 139.461,
        "_internal_originalTime": 1689831714000
    },
    {
        "time": 1689831715000,
        "value": 139.464,
        "_internal_originalTime": 1689831715000
    },
    {
        "time": 1689831717000,
        "value": 139.465,
        "_internal_originalTime": 1689831717000
    },
    {
        "time": 1689831718000,
        "value": 139.466,
        "_internal_originalTime": 1689831718000
    },
    {
        "time": 1689831720000,
        "value": 139.467,
        "_internal_originalTime": 1689831720000
    },
    {
        "time": 1689831721000,
        "value": 139.468,
        "_internal_originalTime": 1689831721000
    },
    {
        "time": 1689831722000,
        "value": 139.467,
        "_internal_originalTime": 1689831722000
    },
    {
        "time": 1689831724000,
        "value": 139.465,
        "_internal_originalTime": 1689831724000
    },
    {
        "time": 1689831725000,
        "value": 139.469,
        "_internal_originalTime": 1689831725000
    },
    {
        "time": 1689831727000,
        "value": 139.473,
        "_internal_originalTime": 1689831727000
    },
    {
        "time": 1689831728000,
        "value": 139.473,
        "_internal_originalTime": 1689831728000
    },
    {
        "time": 1689831729000,
        "value": 139.47,
        "_internal_originalTime": 1689831729000
    },
    {
        "time": 1689831730000,
        "value": 139.473,
        "_internal_originalTime": 1689831730000
    },
    {
        "time": 1689831731000,
        "value": 139.473,
        "_internal_originalTime": 1689831731000
    },
    {
        "time": 1689831732000,
        "value": 139.473,
        "_internal_originalTime": 1689831732000
    },
    {
        "time": 1689831734000,
        "value": 139.468,
        "_internal_originalTime": 1689831734000
    },
    {
        "time": 1689831735000,
        "value": 139.473,
        "_internal_originalTime": 1689831735000
    },
    {
        "time": 1689831736000,
        "value": 139.469,
        "_internal_originalTime": 1689831736000
    },
    {
        "time": 1689831737000,
        "value": 139.473,
        "_internal_originalTime": 1689831737000
    },
    {
        "time": 1689831747000,
        "value": 139.469,
        "_internal_originalTime": 1689831747000
    },
    {
        "time": 1689831749000,
        "value": 139.469,
        "_internal_originalTime": 1689831749000
    },
    {
        "time": 1689831750000,
        "value": 139.471,
        "_internal_originalTime": 1689831750000
    },
    {
        "time": 1689831751000,
        "value": 139.473,
        "_internal_originalTime": 1689831751000
    },
    {
        "time": 1689831752000,
        "value": 139.468,
        "_internal_originalTime": 1689831752000
    },
    {
        "time": 1689831754000,
        "value": 139.466,
        "_internal_originalTime": 1689831754000
    },
    {
        "time": 1689831756000,
        "value": 139.469,
        "_internal_originalTime": 1689831756000
    },
    {
        "time": 1689831761000,
        "value": 139.469,
        "_internal_originalTime": 1689831761000
    },
    {
        "time": 1689831763000,
        "value": 139.473,
        "_internal_originalTime": 1689831763000
    },
    {
        "time": 1689831764000,
        "value": 139.469,
        "_internal_originalTime": 1689831764000
    },
    {
        "time": 1689831765000,
        "value": 139.474,
        "_internal_originalTime": 1689831765000
    },
    {
        "time": 1689831767000,
        "value": 139.473,
        "_internal_originalTime": 1689831767000
    },
    {
        "time": 1689831769000,
        "value": 139.463,
        "_internal_originalTime": 1689831769000
    },
    {
        "time": 1689831770000,
        "value": 139.465,
        "_internal_originalTime": 1689831770000
    },
    {
        "time": 1689831772000,
        "value": 139.462,
        "_internal_originalTime": 1689831772000
    },
    {
        "time": 1689831773000,
        "value": 139.465,
        "_internal_originalTime": 1689831773000
    },
    {
        "time": 1689831774000,
        "value": 139.467,
        "_internal_originalTime": 1689831774000
    },
    {
        "time": 1689831776000,
        "value": 139.466,
        "_internal_originalTime": 1689831776000
    },
    {
        "time": 1689831778000,
        "value": 139.461,
        "_internal_originalTime": 1689831778000
    },
    {
        "time": 1689831780000,
        "value": 139.456,
        "_internal_originalTime": 1689831780000
    },
    {
        "time": 1689831781000,
        "value": 139.461,
        "_internal_originalTime": 1689831781000
    },
    {
        "time": 1689831782000,
        "value": 139.456,
        "_internal_originalTime": 1689831782000
    },
    {
        "time": 1689831784000,
        "value": 139.457,
        "_internal_originalTime": 1689831784000
    },
    {
        "time": 1689831785000,
        "value": 139.457,
        "_internal_originalTime": 1689831785000
    },
    {
        "time": 1689831789000,
        "value": 139.457,
        "_internal_originalTime": 1689831789000
    },
    {
        "time": 1689831791000,
        "value": 139.455,
        "_internal_originalTime": 1689831791000
    },
    {
        "time": 1689831794000,
        "value": 139.45,
        "_internal_originalTime": 1689831794000
    },
    {
        "time": 1689831796000,
        "value": 139.437,
        "_internal_originalTime": 1689831796000
    },
    {
        "time": 1689831798000,
        "value": 139.442,
        "_internal_originalTime": 1689831798000
    },
    {
        "time": 1689831799000,
        "value": 139.437,
        "_internal_originalTime": 1689831799000
    },
    {
        "time": 1689831800000,
        "value": 139.437,
        "_internal_originalTime": 1689831800000
    },
    {
        "time": 1689831802000,
        "value": 139.441,
        "_internal_originalTime": 1689831802000
    },
    {
        "time": 1689831803000,
        "value": 139.445,
        "_internal_originalTime": 1689831803000
    },
    {
        "time": 1689831804000,
        "value": 139.447,
        "_internal_originalTime": 1689831804000
    },
    {
        "time": 1689831805000,
        "value": 139.443,
        "_internal_originalTime": 1689831805000
    },
    {
        "time": 1689831807000,
        "value": 139.442,
        "_internal_originalTime": 1689831807000
    },
    {
        "time": 1689831808000,
        "value": 139.442,
        "_internal_originalTime": 1689831808000
    },
    {
        "time": 1689831809000,
        "value": 139.443,
        "_internal_originalTime": 1689831809000
    },
    {
        "time": 1689831811000,
        "value": 139.448,
        "_internal_originalTime": 1689831811000
    },
    {
        "time": 1689831812000,
        "value": 139.442,
        "_internal_originalTime": 1689831812000
    },
    {
        "time": 1689831814000,
        "value": 139.443,
        "_internal_originalTime": 1689831814000
    },
    {
        "time": 1689831816000,
        "value": 139.448,
        "_internal_originalTime": 1689831816000
    },
    {
        "time": 1689831817000,
        "value": 139.44,
        "_internal_originalTime": 1689831817000
    },
    {
        "time": 1689831818000,
        "value": 139.435,
        "_internal_originalTime": 1689831818000
    },
    {
        "time": 1689831819000,
        "value": 139.434,
        "_internal_originalTime": 1689831819000
    },
    {
        "time": 1689831821000,
        "value": 139.436,
        "_internal_originalTime": 1689831821000
    },
    {
        "time": 1689831822000,
        "value": 139.434,
        "_internal_originalTime": 1689831822000
    },
    {
        "time": 1689831823000,
        "value": 139.441,
        "_internal_originalTime": 1689831823000
    },
    {
        "time": 1689831825000,
        "value": 139.448,
        "_internal_originalTime": 1689831825000
    },
    {
        "time": 1689831826000,
        "value": 139.448,
        "_internal_originalTime": 1689831826000
    },
    {
        "time": 1689831828000,
        "value": 139.448,
        "_internal_originalTime": 1689831828000
    },
    {
        "time": 1689831830000,
        "value": 139.442,
        "_internal_originalTime": 1689831830000
    },
    {
        "time": 1689831831000,
        "value": 139.448,
        "_internal_originalTime": 1689831831000
    },
    {
        "time": 1689831833000,
        "value": 139.448,
        "_internal_originalTime": 1689831833000
    },
    {
        "time": 1689831834000,
        "value": 139.448,
        "_internal_originalTime": 1689831834000
    },
    {
        "time": 1689831835000,
        "value": 139.442,
        "_internal_originalTime": 1689831835000
    },
    {
        "time": 1689831838000,
        "value": 139.442,
        "_internal_originalTime": 1689831838000
    },
    {
        "time": 1689831840000,
        "value": 139.443,
        "_internal_originalTime": 1689831840000
    },
    {
        "time": 1689831841000,
        "value": 139.448,
        "_internal_originalTime": 1689831841000
    },
    {
        "time": 1689831844000,
        "value": 139.441,
        "_internal_originalTime": 1689831844000
    },
    {
        "time": 1689831845000,
        "value": 139.448,
        "_internal_originalTime": 1689831845000
    },
    {
        "time": 1689831846000,
        "value": 139.452,
        "_internal_originalTime": 1689831846000
    },
    {
        "time": 1689831849000,
        "value": 139.452,
        "_internal_originalTime": 1689831849000
    },
    {
        "time": 1689831851000,
        "value": 139.449,
        "_internal_originalTime": 1689831851000
    },
    {
        "time": 1689831852000,
        "value": 139.452,
        "_internal_originalTime": 1689831852000
    },
    {
        "time": 1689831853000,
        "value": 139.452,
        "_internal_originalTime": 1689831853000
    },
    {
        "time": 1689831855000,
        "value": 139.45,
        "_internal_originalTime": 1689831855000
    },
    {
        "time": 1689831857000,
        "value": 139.447,
        "_internal_originalTime": 1689831857000
    },
    {
        "time": 1689831865000,
        "value": 139.442,
        "_internal_originalTime": 1689831865000
    },
    {
        "time": 1689831866000,
        "value": 139.442,
        "_internal_originalTime": 1689831866000
    },
    {
        "time": 1689831867000,
        "value": 139.442,
        "_internal_originalTime": 1689831867000
    },
    {
        "time": 1689831869000,
        "value": 139.442,
        "_internal_originalTime": 1689831869000
    },
    {
        "time": 1689831871000,
        "value": 139.442,
        "_internal_originalTime": 1689831871000
    },
    {
        "time": 1689831872000,
        "value": 139.436,
        "_internal_originalTime": 1689831872000
    },
    {
        "time": 1689831874000,
        "value": 139.433,
        "_internal_originalTime": 1689831874000
    },
    {
        "time": 1689831877000,
        "value": 139.433,
        "_internal_originalTime": 1689831877000
    },
    {
        "time": 1689831878000,
        "value": 139.434,
        "_internal_originalTime": 1689831878000
    },
    {
        "time": 1689831879000,
        "value": 139.434,
        "_internal_originalTime": 1689831879000
    },
    {
        "time": 1689831880000,
        "value": 139.438,
        "_internal_originalTime": 1689831880000
    },
    {
        "time": 1689831882000,
        "value": 139.438,
        "_internal_originalTime": 1689831882000
    },
    {
        "time": 1689831883000,
        "value": 139.433,
        "_internal_originalTime": 1689831883000
    },
    {
        "time": 1689831887000,
        "value": 139.437,
        "_internal_originalTime": 1689831887000
    },
    {
        "time": 1689831889000,
        "value": 139.438,
        "_internal_originalTime": 1689831889000
    },
    {
        "time": 1689831890000,
        "value": 139.438,
        "_internal_originalTime": 1689831890000
    },
    {
        "time": 1689831891000,
        "value": 139.438,
        "_internal_originalTime": 1689831891000
    },
    {
        "time": 1689831893000,
        "value": 139.438,
        "_internal_originalTime": 1689831893000
    },
    {
        "time": 1689831895000,
        "value": 139.438,
        "_internal_originalTime": 1689831895000
    },
    {
        "time": 1689831897000,
        "value": 139.437,
        "_internal_originalTime": 1689831897000
    },
    {
        "time": 1689831898000,
        "value": 139.438,
        "_internal_originalTime": 1689831898000
    },
    {
        "time": 1689831900000,
        "value": 139.438,
        "_internal_originalTime": 1689831900000
    },
    {
        "time": 1689831901000,
        "value": 139.43,
        "_internal_originalTime": 1689831901000
    },
    {
        "time": 1689831902000,
        "value": 139.436,
        "_internal_originalTime": 1689831902000
    },
    {
        "time": 1689831904000,
        "value": 139.435,
        "_internal_originalTime": 1689831904000
    },
    {
        "time": 1689831906000,
        "value": 139.426,
        "_internal_originalTime": 1689831906000
    },
    {
        "time": 1689831907000,
        "value": 139.427,
        "_internal_originalTime": 1689831907000
    },
    {
        "time": 1689831909000,
        "value": 139.419,
        "_internal_originalTime": 1689831909000
    },
    {
        "time": 1689831910000,
        "value": 139.414,
        "_internal_originalTime": 1689831910000
    },
    {
        "time": 1689831911000,
        "value": 139.412,
        "_internal_originalTime": 1689831911000
    },
    {
        "time": 1689831913000,
        "value": 139.411,
        "_internal_originalTime": 1689831913000
    },
    {
        "time": 1689831914000,
        "value": 139.411,
        "_internal_originalTime": 1689831914000
    },
    {
        "time": 1689831915000,
        "value": 139.413,
        "_internal_originalTime": 1689831915000
    },
    {
        "time": 1689831916000,
        "value": 139.418,
        "_internal_originalTime": 1689831916000
    },
    {
        "time": 1689831917000,
        "value": 139.407,
        "_internal_originalTime": 1689831917000
    },
    {
        "time": 1689831920000,
        "value": 139.401,
        "_internal_originalTime": 1689831920000
    },
    {
        "time": 1689831921000,
        "value": 139.408,
        "_internal_originalTime": 1689831921000
    },
    {
        "time": 1689831922000,
        "value": 139.403,
        "_internal_originalTime": 1689831922000
    },
    {
        "time": 1689831926000,
        "value": 139.408,
        "_internal_originalTime": 1689831926000
    },
    {
        "time": 1689831927000,
        "value": 139.405,
        "_internal_originalTime": 1689831927000
    },
    {
        "time": 1689831928000,
        "value": 139.408,
        "_internal_originalTime": 1689831928000
    },
    {
        "time": 1689831929000,
        "value": 139.409,
        "_internal_originalTime": 1689831929000
    },
    {
        "time": 1689831930000,
        "value": 139.409,
        "_internal_originalTime": 1689831930000
    },
    {
        "time": 1689831931000,
        "value": 139.409,
        "_internal_originalTime": 1689831931000
    },
    {
        "time": 1689831933000,
        "value": 139.409,
        "_internal_originalTime": 1689831933000
    },
    {
        "time": 1689831934000,
        "value": 139.404,
        "_internal_originalTime": 1689831934000
    },
    {
        "time": 1689831936000,
        "value": 139.408,
        "_internal_originalTime": 1689831936000
    },
    {
        "time": 1689831937000,
        "value": 139.408,
        "_internal_originalTime": 1689831937000
    },
    {
        "time": 1689831938000,
        "value": 139.408,
        "_internal_originalTime": 1689831938000
    },
    {
        "time": 1689831940000,
        "value": 139.405,
        "_internal_originalTime": 1689831940000
    },
    {
        "time": 1689831941000,
        "value": 139.408,
        "_internal_originalTime": 1689831941000
    },
    {
        "time": 1689831942000,
        "value": 139.404,
        "_internal_originalTime": 1689831942000
    },
    {
        "time": 1689831944000,
        "value": 139.392,
        "_internal_originalTime": 1689831944000
    },
    {
        "time": 1689831945000,
        "value": 139.398,
        "_internal_originalTime": 1689831945000
    },
    {
        "time": 1689831946000,
        "value": 139.394,
        "_internal_originalTime": 1689831946000
    },
    {
        "time": 1689831947000,
        "value": 139.397,
        "_internal_originalTime": 1689831947000
    },
    {
        "time": 1689831948000,
        "value": 139.395,
        "_internal_originalTime": 1689831948000
    },
    {
        "time": 1689831949000,
        "value": 139.397,
        "_internal_originalTime": 1689831949000
    },
    {
        "time": 1689831950000,
        "value": 139.398,
        "_internal_originalTime": 1689831950000
    },
    {
        "time": 1689831952000,
        "value": 139.398,
        "_internal_originalTime": 1689831952000
    },
    {
        "time": 1689831954000,
        "value": 139.398,
        "_internal_originalTime": 1689831954000
    },
    {
        "time": 1689831956000,
        "value": 139.394,
        "_internal_originalTime": 1689831956000
    },
    {
        "time": 1689831957000,
        "value": 139.398,
        "_internal_originalTime": 1689831957000
    },
    {
        "time": 1689831958000,
        "value": 139.395,
        "_internal_originalTime": 1689831958000
    },
    {
        "time": 1689831959000,
        "value": 139.398,
        "_internal_originalTime": 1689831959000
    },
    {
        "time": 1689831960000,
        "value": 139.4,
        "_internal_originalTime": 1689831960000
    },
    {
        "time": 1689831961000,
        "value": 139.394,
        "_internal_originalTime": 1689831961000
    },
    {
        "time": 1689831962000,
        "value": 139.4,
        "_internal_originalTime": 1689831962000
    },
    {
        "time": 1689831964000,
        "value": 139.401,
        "_internal_originalTime": 1689831964000
    },
    {
        "time": 1689831965000,
        "value": 139.402,
        "_internal_originalTime": 1689831965000
    },
    {
        "time": 1689831966000,
        "value": 139.403,
        "_internal_originalTime": 1689831966000
    },
    {
        "time": 1689831967000,
        "value": 139.407,
        "_internal_originalTime": 1689831967000
    },
    {
        "time": 1689831968000,
        "value": 139.405,
        "_internal_originalTime": 1689831968000
    },
    {
        "time": 1689831969000,
        "value": 139.404,
        "_internal_originalTime": 1689831969000
    },
    {
        "time": 1689831971000,
        "value": 139.406,
        "_internal_originalTime": 1689831971000
    },
    {
        "time": 1689831972000,
        "value": 139.396,
        "_internal_originalTime": 1689831972000
    },
    {
        "time": 1689831974000,
        "value": 139.397,
        "_internal_originalTime": 1689831974000
    },
    {
        "time": 1689831975000,
        "value": 139.397,
        "_internal_originalTime": 1689831975000
    },
    {
        "time": 1689831977000,
        "value": 139.394,
        "_internal_originalTime": 1689831977000
    },
    {
        "time": 1689831979000,
        "value": 139.394,
        "_internal_originalTime": 1689831979000
    },
    {
        "time": 1689831980000,
        "value": 139.399,
        "_internal_originalTime": 1689831980000
    },
    {
        "time": 1689831981000,
        "value": 139.402,
        "_internal_originalTime": 1689831981000
    },
    {
        "time": 1689831982000,
        "value": 139.403,
        "_internal_originalTime": 1689831982000
    },
    {
        "time": 1689831983000,
        "value": 139.405,
        "_internal_originalTime": 1689831983000
    },
    {
        "time": 1689831985000,
        "value": 139.407,
        "_internal_originalTime": 1689831985000
    },
    {
        "time": 1689831986000,
        "value": 139.407,
        "_internal_originalTime": 1689831986000
    },
    {
        "time": 1689831987000,
        "value": 139.41,
        "_internal_originalTime": 1689831987000
    },
    {
        "time": 1689831989000,
        "value": 139.406,
        "_internal_originalTime": 1689831989000
    },
    {
        "time": 1689831990000,
        "value": 139.409,
        "_internal_originalTime": 1689831990000
    },
    {
        "time": 1689831991000,
        "value": 139.403,
        "_internal_originalTime": 1689831991000
    },
    {
        "time": 1689831992000,
        "value": 139.409,
        "_internal_originalTime": 1689831992000
    },
    {
        "time": 1689831994000,
        "value": 139.402,
        "_internal_originalTime": 1689831994000
    },
    {
        "time": 1689831995000,
        "value": 139.402,
        "_internal_originalTime": 1689831995000
    },
    {
        "time": 1689831996000,
        "value": 139.402,
        "_internal_originalTime": 1689831996000
    },
    {
        "time": 1689831998000,
        "value": 139.407,
        "_internal_originalTime": 1689831998000
    },
    {
        "time": 1689832000000,
        "value": 139.403,
        "_internal_originalTime": 1689832000000
    },
    {
        "time": 1689832001000,
        "value": 139.405,
        "_internal_originalTime": 1689832001000
    },
    {
        "time": 1689832002000,
        "value": 139.402,
        "_internal_originalTime": 1689832002000
    },
    {
        "time": 1689832005000,
        "value": 139.407,
        "_internal_originalTime": 1689832005000
    },
    {
        "time": 1689832007000,
        "value": 139.407,
        "_internal_originalTime": 1689832007000
    },
    {
        "time": 1689832008000,
        "value": 139.407,
        "_internal_originalTime": 1689832008000
    },
    {
        "time": 1689832009000,
        "value": 139.403,
        "_internal_originalTime": 1689832009000
    },
    {
        "time": 1689832010000,
        "value": 139.407,
        "_internal_originalTime": 1689832010000
    },
    {
        "time": 1689832011000,
        "value": 139.403,
        "_internal_originalTime": 1689832011000
    },
    {
        "time": 1689832012000,
        "value": 139.407,
        "_internal_originalTime": 1689832012000
    },
    {
        "time": 1689832014000,
        "value": 139.402,
        "_internal_originalTime": 1689832014000
    },
    {
        "time": 1689832015000,
        "value": 139.407,
        "_internal_originalTime": 1689832015000
    },
    {
        "time": 1689832017000,
        "value": 139.407,
        "_internal_originalTime": 1689832017000
    },
    {
        "time": 1689832018000,
        "value": 139.403,
        "_internal_originalTime": 1689832018000
    },
    {
        "time": 1689832019000,
        "value": 139.397,
        "_internal_originalTime": 1689832019000
    },
    {
        "time": 1689832020000,
        "value": 139.397,
        "_internal_originalTime": 1689832020000
    },
    {
        "time": 1689832021000,
        "value": 139.392,
        "_internal_originalTime": 1689832021000
    },
    {
        "time": 1689832022000,
        "value": 139.39,
        "_internal_originalTime": 1689832022000
    },
    {
        "time": 1689832024000,
        "value": 139.393,
        "_internal_originalTime": 1689832024000
    },
    {
        "time": 1689832026000,
        "value": 139.396,
        "_internal_originalTime": 1689832026000
    },
    {
        "time": 1689832027000,
        "value": 139.396,
        "_internal_originalTime": 1689832027000
    },
    {
        "time": 1689832028000,
        "value": 139.392,
        "_internal_originalTime": 1689832028000
    },
    {
        "time": 1689832029000,
        "value": 139.396,
        "_internal_originalTime": 1689832029000
    },
    {
        "time": 1689832030000,
        "value": 139.397,
        "_internal_originalTime": 1689832030000
    },
    {
        "time": 1689832032000,
        "value": 139.393,
        "_internal_originalTime": 1689832032000
    },
    {
        "time": 1689832033000,
        "value": 139.397,
        "_internal_originalTime": 1689832033000
    },
    {
        "time": 1689832036000,
        "value": 139.393,
        "_internal_originalTime": 1689832036000
    },
    {
        "time": 1689832037000,
        "value": 139.399,
        "_internal_originalTime": 1689832037000
    },
    {
        "time": 1689832038000,
        "value": 139.398,
        "_internal_originalTime": 1689832038000
    },
    {
        "time": 1689832039000,
        "value": 139.397,
        "_internal_originalTime": 1689832039000
    },
    {
        "time": 1689832040000,
        "value": 139.399,
        "_internal_originalTime": 1689832040000
    },
    {
        "time": 1689832042000,
        "value": 139.398,
        "_internal_originalTime": 1689832042000
    },
    {
        "time": 1689832043000,
        "value": 139.394,
        "_internal_originalTime": 1689832043000
    },
    {
        "time": 1689832047000,
        "value": 139.399,
        "_internal_originalTime": 1689832047000
    },
    {
        "time": 1689832048000,
        "value": 139.398,
        "_internal_originalTime": 1689832048000
    },
    {
        "time": 1689832049000,
        "value": 139.398,
        "_internal_originalTime": 1689832049000
    },
    {
        "time": 1689832050000,
        "value": 139.394,
        "_internal_originalTime": 1689832050000
    },
    {
        "time": 1689832054000,
        "value": 139.392,
        "_internal_originalTime": 1689832054000
    },
    {
        "time": 1689832056000,
        "value": 139.397,
        "_internal_originalTime": 1689832056000
    },
    {
        "time": 1689832059000,
        "value": 139.392,
        "_internal_originalTime": 1689832059000
    },
    {
        "time": 1689832061000,
        "value": 139.394,
        "_internal_originalTime": 1689832061000
    },
    {
        "time": 1689832062000,
        "value": 139.397,
        "_internal_originalTime": 1689832062000
    },
    {
        "time": 1689832064000,
        "value": 139.397,
        "_internal_originalTime": 1689832064000
    },
    {
        "time": 1689832066000,
        "value": 139.394,
        "_internal_originalTime": 1689832066000
    },
    {
        "time": 1689832068000,
        "value": 139.394,
        "_internal_originalTime": 1689832068000
    },
    {
        "time": 1689832069000,
        "value": 139.397,
        "_internal_originalTime": 1689832069000
    },
    {
        "time": 1689832071000,
        "value": 139.397,
        "_internal_originalTime": 1689832071000
    },
    {
        "time": 1689832074000,
        "value": 139.397,
        "_internal_originalTime": 1689832074000
    },
    {
        "time": 1689832075000,
        "value": 139.399,
        "_internal_originalTime": 1689832075000
    },
    {
        "time": 1689832076000,
        "value": 139.397,
        "_internal_originalTime": 1689832076000
    },
    {
        "time": 1689832078000,
        "value": 139.394,
        "_internal_originalTime": 1689832078000
    },
    {
        "time": 1689832080000,
        "value": 139.397,
        "_internal_originalTime": 1689832080000
    },
    {
        "time": 1689832082000,
        "value": 139.392,
        "_internal_originalTime": 1689832082000
    },
    {
        "time": 1689832084000,
        "value": 139.397,
        "_internal_originalTime": 1689832084000
    },
    {
        "time": 1689832085000,
        "value": 139.397,
        "_internal_originalTime": 1689832085000
    },
    {
        "time": 1689832088000,
        "value": 139.394,
        "_internal_originalTime": 1689832088000
    },
    {
        "time": 1689832089000,
        "value": 139.397,
        "_internal_originalTime": 1689832089000
    },
    {
        "time": 1689832091000,
        "value": 139.394,
        "_internal_originalTime": 1689832091000
    },
    {
        "time": 1689832092000,
        "value": 139.394,
        "_internal_originalTime": 1689832092000
    },
    {
        "time": 1689832093000,
        "value": 139.397,
        "_internal_originalTime": 1689832093000
    },
    {
        "time": 1689832096000,
        "value": 139.397,
        "_internal_originalTime": 1689832096000
    },
    {
        "time": 1689832097000,
        "value": 139.397,
        "_internal_originalTime": 1689832097000
    },
    {
        "time": 1689832098000,
        "value": 139.397,
        "_internal_originalTime": 1689832098000
    },
    {
        "time": 1689832100000,
        "value": 139.398,
        "_internal_originalTime": 1689832100000
    },
    {
        "time": 1689832101000,
        "value": 139.4,
        "_internal_originalTime": 1689832101000
    },
    {
        "time": 1689832103000,
        "value": 139.4,
        "_internal_originalTime": 1689832103000
    },
    {
        "time": 1689832105000,
        "value": 139.397,
        "_internal_originalTime": 1689832105000
    },
    {
        "time": 1689832108000,
        "value": 139.397,
        "_internal_originalTime": 1689832108000
    },
    {
        "time": 1689832109000,
        "value": 139.398,
        "_internal_originalTime": 1689832109000
    },
    {
        "time": 1689832111000,
        "value": 139.398,
        "_internal_originalTime": 1689832111000
    },
    {
        "time": 1689832114000,
        "value": 139.391,
        "_internal_originalTime": 1689832114000
    },
    {
        "time": 1689832115000,
        "value": 139.397,
        "_internal_originalTime": 1689832115000
    },
    {
        "time": 1689832116000,
        "value": 139.395,
        "_internal_originalTime": 1689832116000
    },
    {
        "time": 1689832117000,
        "value": 139.397,
        "_internal_originalTime": 1689832117000
    },
    {
        "time": 1689832119000,
        "value": 139.397,
        "_internal_originalTime": 1689832119000
    },
    {
        "time": 1689832120000,
        "value": 139.397,
        "_internal_originalTime": 1689832120000
    },
    {
        "time": 1689832121000,
        "value": 139.398,
        "_internal_originalTime": 1689832121000
    },
    {
        "time": 1689832122000,
        "value": 139.398,
        "_internal_originalTime": 1689832122000
    },
    {
        "time": 1689832124000,
        "value": 139.398,
        "_internal_originalTime": 1689832124000
    },
    {
        "time": 1689832125000,
        "value": 139.398,
        "_internal_originalTime": 1689832125000
    },
    {
        "time": 1689832127000,
        "value": 139.398,
        "_internal_originalTime": 1689832127000
    },
    {
        "time": 1689832128000,
        "value": 139.398,
        "_internal_originalTime": 1689832128000
    },
    {
        "time": 1689832129000,
        "value": 139.398,
        "_internal_originalTime": 1689832129000
    },
    {
        "time": 1689832130000,
        "value": 139.398,
        "_internal_originalTime": 1689832130000
    },
    {
        "time": 1689832132000,
        "value": 139.393,
        "_internal_originalTime": 1689832132000
    },
    {
        "time": 1689832133000,
        "value": 139.392,
        "_internal_originalTime": 1689832133000
    },
    {
        "time": 1689832134000,
        "value": 139.394,
        "_internal_originalTime": 1689832134000
    },
    {
        "time": 1689832135000,
        "value": 139.394,
        "_internal_originalTime": 1689832135000
    },
    {
        "time": 1689832137000,
        "value": 139.398,
        "_internal_originalTime": 1689832137000
    },
    {
        "time": 1689832138000,
        "value": 139.397,
        "_internal_originalTime": 1689832138000
    },
    {
        "time": 1689832140000,
        "value": 139.392,
        "_internal_originalTime": 1689832140000
    },
    {
        "time": 1689832143000,
        "value": 139.387,
        "_internal_originalTime": 1689832143000
    },
    {
        "time": 1689832144000,
        "value": 139.387,
        "_internal_originalTime": 1689832144000
    },
    {
        "time": 1689832145000,
        "value": 139.394,
        "_internal_originalTime": 1689832145000
    },
    {
        "time": 1689832148000,
        "value": 139.397,
        "_internal_originalTime": 1689832148000
    },
    {
        "time": 1689832150000,
        "value": 139.398,
        "_internal_originalTime": 1689832150000
    },
    {
        "time": 1689832151000,
        "value": 139.394,
        "_internal_originalTime": 1689832151000
    },
    {
        "time": 1689832152000,
        "value": 139.398,
        "_internal_originalTime": 1689832152000
    },
    {
        "time": 1689832153000,
        "value": 139.393,
        "_internal_originalTime": 1689832153000
    },
    {
        "time": 1689832155000,
        "value": 139.398,
        "_internal_originalTime": 1689832155000
    },
    {
        "time": 1689832156000,
        "value": 139.392,
        "_internal_originalTime": 1689832156000
    },
    {
        "time": 1689832157000,
        "value": 139.386,
        "_internal_originalTime": 1689832157000
    },
    {
        "time": 1689832159000,
        "value": 139.391,
        "_internal_originalTime": 1689832159000
    },
    {
        "time": 1689832161000,
        "value": 139.39,
        "_internal_originalTime": 1689832161000
    },
    {
        "time": 1689832163000,
        "value": 139.389,
        "_internal_originalTime": 1689832163000
    },
    {
        "time": 1689832164000,
        "value": 139.384,
        "_internal_originalTime": 1689832164000
    },
    {
        "time": 1689832167000,
        "value": 139.387,
        "_internal_originalTime": 1689832167000
    },
    {
        "time": 1689832168000,
        "value": 139.385,
        "_internal_originalTime": 1689832168000
    },
    {
        "time": 1689832172000,
        "value": 139.387,
        "_internal_originalTime": 1689832172000
    },
    {
        "time": 1689832173000,
        "value": 139.391,
        "_internal_originalTime": 1689832173000
    },
    {
        "time": 1689832174000,
        "value": 139.384,
        "_internal_originalTime": 1689832174000
    },
    {
        "time": 1689832175000,
        "value": 139.387,
        "_internal_originalTime": 1689832175000
    },
    {
        "time": 1689832176000,
        "value": 139.389,
        "_internal_originalTime": 1689832176000
    },
    {
        "time": 1689832177000,
        "value": 139.389,
        "_internal_originalTime": 1689832177000
    },
    {
        "time": 1689832179000,
        "value": 139.389,
        "_internal_originalTime": 1689832179000
    },
    {
        "time": 1689832180000,
        "value": 139.389,
        "_internal_originalTime": 1689832180000
    },
    {
        "time": 1689832181000,
        "value": 139.386,
        "_internal_originalTime": 1689832181000
    },
    {
        "time": 1689832184000,
        "value": 139.384,
        "_internal_originalTime": 1689832184000
    },
    {
        "time": 1689832185000,
        "value": 139.388,
        "_internal_originalTime": 1689832185000
    },
    {
        "time": 1689832187000,
        "value": 139.389,
        "_internal_originalTime": 1689832187000
    },
    {
        "time": 1689832189000,
        "value": 139.385,
        "_internal_originalTime": 1689832189000
    },
    {
        "time": 1689832190000,
        "value": 139.386,
        "_internal_originalTime": 1689832190000
    },
    {
        "time": 1689832192000,
        "value": 139.385,
        "_internal_originalTime": 1689832192000
    },
    {
        "time": 1689832193000,
        "value": 139.385,
        "_internal_originalTime": 1689832193000
    },
    {
        "time": 1689832194000,
        "value": 139.389,
        "_internal_originalTime": 1689832194000
    },
    {
        "time": 1689832197000,
        "value": 139.387,
        "_internal_originalTime": 1689832197000
    },
    {
        "time": 1689832198000,
        "value": 139.391,
        "_internal_originalTime": 1689832198000
    },
    {
        "time": 1689832199000,
        "value": 139.39,
        "_internal_originalTime": 1689832199000
    },
    {
        "time": 1689832200000,
        "value": 139.388,
        "_internal_originalTime": 1689832200000
    },
    {
        "time": 1689832201000,
        "value": 139.388,
        "_internal_originalTime": 1689832201000
    },
    {
        "time": 1689832202000,
        "value": 139.389,
        "_internal_originalTime": 1689832202000
    },
    {
        "time": 1689832203000,
        "value": 139.389,
        "_internal_originalTime": 1689832203000
    },
    {
        "time": 1689832204000,
        "value": 139.389,
        "_internal_originalTime": 1689832204000
    },
    {
        "time": 1689832207000,
        "value": 139.387,
        "_internal_originalTime": 1689832207000
    },
    {
        "time": 1689832208000,
        "value": 139.389,
        "_internal_originalTime": 1689832208000
    },
    {
        "time": 1689832209000,
        "value": 139.389,
        "_internal_originalTime": 1689832209000
    },
    {
        "time": 1689832211000,
        "value": 139.387,
        "_internal_originalTime": 1689832211000
    },
    {
        "time": 1689832212000,
        "value": 139.384,
        "_internal_originalTime": 1689832212000
    },
    {
        "time": 1689832213000,
        "value": 139.389,
        "_internal_originalTime": 1689832213000
    },
    {
        "time": 1689832214000,
        "value": 139.389,
        "_internal_originalTime": 1689832214000
    },
    {
        "time": 1689832215000,
        "value": 139.385,
        "_internal_originalTime": 1689832215000
    },
    {
        "time": 1689832216000,
        "value": 139.389,
        "_internal_originalTime": 1689832216000
    },
    {
        "time": 1689832218000,
        "value": 139.389,
        "_internal_originalTime": 1689832218000
    },
    {
        "time": 1689832219000,
        "value": 139.384,
        "_internal_originalTime": 1689832219000
    },
    {
        "time": 1689832220000,
        "value": 139.389,
        "_internal_originalTime": 1689832220000
    },
    {
        "time": 1689832222000,
        "value": 139.389,
        "_internal_originalTime": 1689832222000
    },
    {
        "time": 1689832223000,
        "value": 139.388,
        "_internal_originalTime": 1689832223000
    },
    {
        "time": 1689832225000,
        "value": 139.385,
        "_internal_originalTime": 1689832225000
    },
    {
        "time": 1689832226000,
        "value": 139.388,
        "_internal_originalTime": 1689832226000
    },
    {
        "time": 1689832227000,
        "value": 139.388,
        "_internal_originalTime": 1689832227000
    },
    {
        "time": 1689832229000,
        "value": 139.384,
        "_internal_originalTime": 1689832229000
    },
    {
        "time": 1689832230000,
        "value": 139.388,
        "_internal_originalTime": 1689832230000
    },
    {
        "time": 1689832231000,
        "value": 139.388,
        "_internal_originalTime": 1689832231000
    },
    {
        "time": 1689832232000,
        "value": 139.388,
        "_internal_originalTime": 1689832232000
    },
    {
        "time": 1689832234000,
        "value": 139.385,
        "_internal_originalTime": 1689832234000
    },
    {
        "time": 1689832235000,
        "value": 139.384,
        "_internal_originalTime": 1689832235000
    },
    {
        "time": 1689832237000,
        "value": 139.388,
        "_internal_originalTime": 1689832237000
    },
    {
        "time": 1689832238000,
        "value": 139.388,
        "_internal_originalTime": 1689832238000
    },
    {
        "time": 1689832239000,
        "value": 139.388,
        "_internal_originalTime": 1689832239000
    },
    {
        "time": 1689832241000,
        "value": 139.387,
        "_internal_originalTime": 1689832241000
    },
    {
        "time": 1689832242000,
        "value": 139.377,
        "_internal_originalTime": 1689832242000
    },
    {
        "time": 1689832243000,
        "value": 139.378,
        "_internal_originalTime": 1689832243000
    },
    {
        "time": 1689832244000,
        "value": 139.375,
        "_internal_originalTime": 1689832244000
    },
    {
        "time": 1689832245000,
        "value": 139.376,
        "_internal_originalTime": 1689832245000
    },
    {
        "time": 1689832246000,
        "value": 139.377,
        "_internal_originalTime": 1689832246000
    },
    {
        "time": 1689832247000,
        "value": 139.376,
        "_internal_originalTime": 1689832247000
    },
    {
        "time": 1689832249000,
        "value": 139.374,
        "_internal_originalTime": 1689832249000
    },
    {
        "time": 1689832250000,
        "value": 139.379,
        "_internal_originalTime": 1689832250000
    },
    {
        "time": 1689832251000,
        "value": 139.377,
        "_internal_originalTime": 1689832251000
    },
    {
        "time": 1689832252000,
        "value": 139.378,
        "_internal_originalTime": 1689832252000
    },
    {
        "time": 1689832254000,
        "value": 139.377,
        "_internal_originalTime": 1689832254000
    },
    {
        "time": 1689832255000,
        "value": 139.377,
        "_internal_originalTime": 1689832255000
    },
    {
        "time": 1689832258000,
        "value": 139.378,
        "_internal_originalTime": 1689832258000
    },
    {
        "time": 1689832259000,
        "value": 139.377,
        "_internal_originalTime": 1689832259000
    },
    {
        "time": 1689832261000,
        "value": 139.372,
        "_internal_originalTime": 1689832261000
    },
    {
        "time": 1689832262000,
        "value": 139.37,
        "_internal_originalTime": 1689832262000
    },
    {
        "time": 1689832263000,
        "value": 139.368,
        "_internal_originalTime": 1689832263000
    },
    {
        "time": 1689832264000,
        "value": 139.361,
        "_internal_originalTime": 1689832264000
    },
    {
        "time": 1689832265000,
        "value": 139.37,
        "_internal_originalTime": 1689832265000
    },
    {
        "time": 1689832267000,
        "value": 139.368,
        "_internal_originalTime": 1689832267000
    },
    {
        "time": 1689832269000,
        "value": 139.363,
        "_internal_originalTime": 1689832269000
    },
    {
        "time": 1689832270000,
        "value": 139.367,
        "_internal_originalTime": 1689832270000
    },
    {
        "time": 1689832271000,
        "value": 139.365,
        "_internal_originalTime": 1689832271000
    },
    {
        "time": 1689832273000,
        "value": 139.368,
        "_internal_originalTime": 1689832273000
    },
    {
        "time": 1689832274000,
        "value": 139.368,
        "_internal_originalTime": 1689832274000
    },
    {
        "time": 1689832277000,
        "value": 139.364,
        "_internal_originalTime": 1689832277000
    },
    {
        "time": 1689832278000,
        "value": 139.368,
        "_internal_originalTime": 1689832278000
    },
    {
        "time": 1689832279000,
        "value": 139.369,
        "_internal_originalTime": 1689832279000
    },
    {
        "time": 1689832281000,
        "value": 139.369,
        "_internal_originalTime": 1689832281000
    },
    {
        "time": 1689832282000,
        "value": 139.369,
        "_internal_originalTime": 1689832282000
    },
    {
        "time": 1689832283000,
        "value": 139.369,
        "_internal_originalTime": 1689832283000
    },
    {
        "time": 1689832287000,
        "value": 139.362,
        "_internal_originalTime": 1689832287000
    },
    {
        "time": 1689832288000,
        "value": 139.366,
        "_internal_originalTime": 1689832288000
    },
    {
        "time": 1689832290000,
        "value": 139.363,
        "_internal_originalTime": 1689832290000
    },
    {
        "time": 1689832291000,
        "value": 139.362,
        "_internal_originalTime": 1689832291000
    },
    {
        "time": 1689832292000,
        "value": 139.361,
        "_internal_originalTime": 1689832292000
    },
    {
        "time": 1689832294000,
        "value": 139.363,
        "_internal_originalTime": 1689832294000
    },
    {
        "time": 1689832297000,
        "value": 139.361,
        "_internal_originalTime": 1689832297000
    },
    {
        "time": 1689832301000,
        "value": 139.359,
        "_internal_originalTime": 1689832301000
    },
    {
        "time": 1689832303000,
        "value": 139.363,
        "_internal_originalTime": 1689832303000
    },
    {
        "time": 1689832305000,
        "value": 139.364,
        "_internal_originalTime": 1689832305000
    },
    {
        "time": 1689832306000,
        "value": 139.364,
        "_internal_originalTime": 1689832306000
    },
    {
        "time": 1689832307000,
        "value": 139.364,
        "_internal_originalTime": 1689832307000
    },
    {
        "time": 1689832308000,
        "value": 139.363,
        "_internal_originalTime": 1689832308000
    },
    {
        "time": 1689832312000,
        "value": 139.362,
        "_internal_originalTime": 1689832312000
    },
    {
        "time": 1689832313000,
        "value": 139.369,
        "_internal_originalTime": 1689832313000
    },
    {
        "time": 1689832317000,
        "value": 139.364,
        "_internal_originalTime": 1689832317000
    },
    {
        "time": 1689832318000,
        "value": 139.368,
        "_internal_originalTime": 1689832318000
    },
    {
        "time": 1689832320000,
        "value": 139.364,
        "_internal_originalTime": 1689832320000
    },
    {
        "time": 1689832321000,
        "value": 139.372,
        "_internal_originalTime": 1689832321000
    },
    {
        "time": 1689832323000,
        "value": 139.364,
        "_internal_originalTime": 1689832323000
    },
    {
        "time": 1689832324000,
        "value": 139.364,
        "_internal_originalTime": 1689832324000
    },
    {
        "time": 1689832325000,
        "value": 139.366,
        "_internal_originalTime": 1689832325000
    },
    {
        "time": 1689832327000,
        "value": 139.366,
        "_internal_originalTime": 1689832327000
    },
    {
        "time": 1689832328000,
        "value": 139.366,
        "_internal_originalTime": 1689832328000
    },
    {
        "time": 1689832329000,
        "value": 139.371,
        "_internal_originalTime": 1689832329000
    },
    {
        "time": 1689832330000,
        "value": 139.366,
        "_internal_originalTime": 1689832330000
    },
    {
        "time": 1689832332000,
        "value": 139.381,
        "_internal_originalTime": 1689832332000
    },
    {
        "time": 1689832333000,
        "value": 139.38,
        "_internal_originalTime": 1689832333000
    },
    {
        "time": 1689832336000,
        "value": 139.374,
        "_internal_originalTime": 1689832336000
    },
    {
        "time": 1689832338000,
        "value": 139.376,
        "_internal_originalTime": 1689832338000
    },
    {
        "time": 1689832339000,
        "value": 139.381,
        "_internal_originalTime": 1689832339000
    },
    {
        "time": 1689832340000,
        "value": 139.376,
        "_internal_originalTime": 1689832340000
    },
    {
        "time": 1689832342000,
        "value": 139.382,
        "_internal_originalTime": 1689832342000
    },
    {
        "time": 1689832343000,
        "value": 139.368,
        "_internal_originalTime": 1689832343000
    },
    {
        "time": 1689832345000,
        "value": 139.367,
        "_internal_originalTime": 1689832345000
    },
    {
        "time": 1689832346000,
        "value": 139.367,
        "_internal_originalTime": 1689832346000
    },
    {
        "time": 1689832349000,
        "value": 139.362,
        "_internal_originalTime": 1689832349000
    },
    {
        "time": 1689832350000,
        "value": 139.364,
        "_internal_originalTime": 1689832350000
    },
    {
        "time": 1689832351000,
        "value": 139.368,
        "_internal_originalTime": 1689832351000
    },
    {
        "time": 1689832352000,
        "value": 139.364,
        "_internal_originalTime": 1689832352000
    },
    {
        "time": 1689832354000,
        "value": 139.361,
        "_internal_originalTime": 1689832354000
    },
    {
        "time": 1689832355000,
        "value": 139.368,
        "_internal_originalTime": 1689832355000
    },
    {
        "time": 1689832356000,
        "value": 139.37,
        "_internal_originalTime": 1689832356000
    },
    {
        "time": 1689832357000,
        "value": 139.364,
        "_internal_originalTime": 1689832357000
    },
    {
        "time": 1689832358000,
        "value": 139.362,
        "_internal_originalTime": 1689832358000
    },
    {
        "time": 1689832359000,
        "value": 139.368,
        "_internal_originalTime": 1689832359000
    },
    {
        "time": 1689832361000,
        "value": 139.364,
        "_internal_originalTime": 1689832361000
    },
    {
        "time": 1689832363000,
        "value": 139.368,
        "_internal_originalTime": 1689832363000
    },
    {
        "time": 1689832364000,
        "value": 139.369,
        "_internal_originalTime": 1689832364000
    },
    {
        "time": 1689832365000,
        "value": 139.37,
        "_internal_originalTime": 1689832365000
    },
    {
        "time": 1689832367000,
        "value": 139.364,
        "_internal_originalTime": 1689832367000
    },
    {
        "time": 1689832368000,
        "value": 139.368,
        "_internal_originalTime": 1689832368000
    },
    {
        "time": 1689832369000,
        "value": 139.368,
        "_internal_originalTime": 1689832369000
    },
    {
        "time": 1689832370000,
        "value": 139.369,
        "_internal_originalTime": 1689832370000
    },
    {
        "time": 1689832372000,
        "value": 139.368,
        "_internal_originalTime": 1689832372000
    },
    {
        "time": 1689832375000,
        "value": 139.364,
        "_internal_originalTime": 1689832375000
    },
    {
        "time": 1689832378000,
        "value": 139.366,
        "_internal_originalTime": 1689832378000
    },
    {
        "time": 1689832379000,
        "value": 139.357,
        "_internal_originalTime": 1689832379000
    },
    {
        "time": 1689832380000,
        "value": 139.354,
        "_internal_originalTime": 1689832380000
    },
    {
        "time": 1689832382000,
        "value": 139.36,
        "_internal_originalTime": 1689832382000
    },
    {
        "time": 1689832383000,
        "value": 139.359,
        "_internal_originalTime": 1689832383000
    },
    {
        "time": 1689832384000,
        "value": 139.355,
        "_internal_originalTime": 1689832384000
    },
    {
        "time": 1689832385000,
        "value": 139.359,
        "_internal_originalTime": 1689832385000
    },
    {
        "time": 1689832387000,
        "value": 139.36,
        "_internal_originalTime": 1689832387000
    },
    {
        "time": 1689832388000,
        "value": 139.357,
        "_internal_originalTime": 1689832388000
    },
    {
        "time": 1689832389000,
        "value": 139.359,
        "_internal_originalTime": 1689832389000
    },
    {
        "time": 1689832390000,
        "value": 139.354,
        "_internal_originalTime": 1689832390000
    },
    {
        "time": 1689832391000,
        "value": 139.359,
        "_internal_originalTime": 1689832391000
    },
    {
        "time": 1689832393000,
        "value": 139.363,
        "_internal_originalTime": 1689832393000
    },
    {
        "time": 1689832394000,
        "value": 139.357,
        "_internal_originalTime": 1689832394000
    },
    {
        "time": 1689832395000,
        "value": 139.352,
        "_internal_originalTime": 1689832395000
    },
    {
        "time": 1689832396000,
        "value": 139.357,
        "_internal_originalTime": 1689832396000
    },
    {
        "time": 1689832397000,
        "value": 139.358,
        "_internal_originalTime": 1689832397000
    },
    {
        "time": 1689832399000,
        "value": 139.354,
        "_internal_originalTime": 1689832399000
    },
    {
        "time": 1689832400000,
        "value": 139.358,
        "_internal_originalTime": 1689832400000
    },
    {
        "time": 1689832401000,
        "value": 139.347,
        "_internal_originalTime": 1689832401000
    },
    {
        "time": 1689832402000,
        "value": 139.351,
        "_internal_originalTime": 1689832402000
    },
    {
        "time": 1689832404000,
        "value": 139.34,
        "_internal_originalTime": 1689832404000
    },
    {
        "time": 1689832406000,
        "value": 139.342,
        "_internal_originalTime": 1689832406000
    },
    {
        "time": 1689832407000,
        "value": 139.337,
        "_internal_originalTime": 1689832407000
    },
    {
        "time": 1689832408000,
        "value": 139.337,
        "_internal_originalTime": 1689832408000
    },
    {
        "time": 1689832409000,
        "value": 139.34,
        "_internal_originalTime": 1689832409000
    },
    {
        "time": 1689832410000,
        "value": 139.341,
        "_internal_originalTime": 1689832410000
    },
    {
        "time": 1689832411000,
        "value": 139.339,
        "_internal_originalTime": 1689832411000
    },
    {
        "time": 1689832413000,
        "value": 139.343,
        "_internal_originalTime": 1689832413000
    },
    {
        "time": 1689832414000,
        "value": 139.346,
        "_internal_originalTime": 1689832414000
    },
    {
        "time": 1689832415000,
        "value": 139.355,
        "_internal_originalTime": 1689832415000
    },
    {
        "time": 1689832416000,
        "value": 139.358,
        "_internal_originalTime": 1689832416000
    },
    {
        "time": 1689832419000,
        "value": 139.358,
        "_internal_originalTime": 1689832419000
    },
    {
        "time": 1689832420000,
        "value": 139.358,
        "_internal_originalTime": 1689832420000
    },
    {
        "time": 1689832421000,
        "value": 139.355,
        "_internal_originalTime": 1689832421000
    },
    {
        "time": 1689832423000,
        "value": 139.358,
        "_internal_originalTime": 1689832423000
    },
    {
        "time": 1689832425000,
        "value": 139.354,
        "_internal_originalTime": 1689832425000
    },
    {
        "time": 1689832426000,
        "value": 139.359,
        "_internal_originalTime": 1689832426000
    },
    {
        "time": 1689832427000,
        "value": 139.368,
        "_internal_originalTime": 1689832427000
    },
    {
        "time": 1689832430000,
        "value": 139.364,
        "_internal_originalTime": 1689832430000
    },
    {
        "time": 1689832431000,
        "value": 139.366,
        "_internal_originalTime": 1689832431000
    },
    {
        "time": 1689832432000,
        "value": 139.376,
        "_internal_originalTime": 1689832432000
    },
    {
        "time": 1689832433000,
        "value": 139.369,
        "_internal_originalTime": 1689832433000
    },
    {
        "time": 1689832434000,
        "value": 139.369,
        "_internal_originalTime": 1689832434000
    },
    {
        "time": 1689832435000,
        "value": 139.367,
        "_internal_originalTime": 1689832435000
    },
    {
        "time": 1689832436000,
        "value": 139.373,
        "_internal_originalTime": 1689832436000
    },
    {
        "time": 1689832438000,
        "value": 139.376,
        "_internal_originalTime": 1689832438000
    },
    {
        "time": 1689832439000,
        "value": 139.375,
        "_internal_originalTime": 1689832439000
    },
    {
        "time": 1689832440000,
        "value": 139.374,
        "_internal_originalTime": 1689832440000
    },
    {
        "time": 1689832441000,
        "value": 139.377,
        "_internal_originalTime": 1689832441000
    },
    {
        "time": 1689832442000,
        "value": 139.377,
        "_internal_originalTime": 1689832442000
    },
    {
        "time": 1689832443000,
        "value": 139.377,
        "_internal_originalTime": 1689832443000
    },
    {
        "time": 1689832444000,
        "value": 139.371,
        "_internal_originalTime": 1689832444000
    },
    {
        "time": 1689832446000,
        "value": 139.375,
        "_internal_originalTime": 1689832446000
    },
    {
        "time": 1689832447000,
        "value": 139.378,
        "_internal_originalTime": 1689832447000
    },
    {
        "time": 1689832450000,
        "value": 139.387,
        "_internal_originalTime": 1689832450000
    },
    {
        "time": 1689832451000,
        "value": 139.389,
        "_internal_originalTime": 1689832451000
    },
    {
        "time": 1689832452000,
        "value": 139.388,
        "_internal_originalTime": 1689832452000
    },
    {
        "time": 1689832454000,
        "value": 139.388,
        "_internal_originalTime": 1689832454000
    },
    {
        "time": 1689832456000,
        "value": 139.394,
        "_internal_originalTime": 1689832456000
    },
    {
        "time": 1689832458000,
        "value": 139.397,
        "_internal_originalTime": 1689832458000
    },
    {
        "time": 1689832463000,
        "value": 139.395,
        "_internal_originalTime": 1689832463000
    },
    {
        "time": 1689832464000,
        "value": 139.412,
        "_internal_originalTime": 1689832464000
    },
    {
        "time": 1689832465000,
        "value": 139.417,
        "_internal_originalTime": 1689832465000
    },
    {
        "time": 1689832466000,
        "value": 139.41,
        "_internal_originalTime": 1689832466000
    },
    {
        "time": 1689832469000,
        "value": 139.407,
        "_internal_originalTime": 1689832469000
    },
    {
        "time": 1689832470000,
        "value": 139.411,
        "_internal_originalTime": 1689832470000
    },
    {
        "time": 1689832471000,
        "value": 139.404,
        "_internal_originalTime": 1689832471000
    },
    {
        "time": 1689832472000,
        "value": 139.414,
        "_internal_originalTime": 1689832472000
    },
    {
        "time": 1689832473000,
        "value": 139.416,
        "_internal_originalTime": 1689832473000
    },
    {
        "time": 1689832474000,
        "value": 139.411,
        "_internal_originalTime": 1689832474000
    },
    {
        "time": 1689832475000,
        "value": 139.413,
        "_internal_originalTime": 1689832475000
    },
    {
        "time": 1689832476000,
        "value": 139.418,
        "_internal_originalTime": 1689832476000
    },
    {
        "time": 1689832477000,
        "value": 139.417,
        "_internal_originalTime": 1689832477000
    },
    {
        "time": 1689832479000,
        "value": 139.421,
        "_internal_originalTime": 1689832479000
    },
    {
        "time": 1689832480000,
        "value": 139.427,
        "_internal_originalTime": 1689832480000
    },
    {
        "time": 1689832481000,
        "value": 139.426,
        "_internal_originalTime": 1689832481000
    },
    {
        "time": 1689832482000,
        "value": 139.422,
        "_internal_originalTime": 1689832482000
    },
    {
        "time": 1689832485000,
        "value": 139.426,
        "_internal_originalTime": 1689832485000
    },
    {
        "time": 1689832486000,
        "value": 139.426,
        "_internal_originalTime": 1689832486000
    },
    {
        "time": 1689832488000,
        "value": 139.423,
        "_internal_originalTime": 1689832488000
    },
    {
        "time": 1689832489000,
        "value": 139.416,
        "_internal_originalTime": 1689832489000
    },
    {
        "time": 1689832490000,
        "value": 139.42,
        "_internal_originalTime": 1689832490000
    },
    {
        "time": 1689832492000,
        "value": 139.417,
        "_internal_originalTime": 1689832492000
    },
    {
        "time": 1689832495000,
        "value": 139.418,
        "_internal_originalTime": 1689832495000
    },
    {
        "time": 1689832496000,
        "value": 139.416,
        "_internal_originalTime": 1689832496000
    },
    {
        "time": 1689832497000,
        "value": 139.412,
        "_internal_originalTime": 1689832497000
    },
    {
        "time": 1689832498000,
        "value": 139.408,
        "_internal_originalTime": 1689832498000
    },
    {
        "time": 1689832500000,
        "value": 139.407,
        "_internal_originalTime": 1689832500000
    },
    {
        "time": 1689832501000,
        "value": 139.393,
        "_internal_originalTime": 1689832501000
    },
    {
        "time": 1689832502000,
        "value": 139.394,
        "_internal_originalTime": 1689832502000
    },
    {
        "time": 1689832504000,
        "value": 139.392,
        "_internal_originalTime": 1689832504000
    },
    {
        "time": 1689832506000,
        "value": 139.393,
        "_internal_originalTime": 1689832506000
    },
    {
        "time": 1689832507000,
        "value": 139.402,
        "_internal_originalTime": 1689832507000
    },
    {
        "time": 1689832508000,
        "value": 139.4,
        "_internal_originalTime": 1689832508000
    },
    {
        "time": 1689832509000,
        "value": 139.407,
        "_internal_originalTime": 1689832509000
    },
    {
        "time": 1689832510000,
        "value": 139.4,
        "_internal_originalTime": 1689832510000
    },
    {
        "time": 1689832512000,
        "value": 139.41,
        "_internal_originalTime": 1689832512000
    },
    {
        "time": 1689832515000,
        "value": 139.411,
        "_internal_originalTime": 1689832515000
    },
    {
        "time": 1689832516000,
        "value": 139.409,
        "_internal_originalTime": 1689832516000
    },
    {
        "time": 1689832518000,
        "value": 139.402,
        "_internal_originalTime": 1689832518000
    },
    {
        "time": 1689832521000,
        "value": 139.407,
        "_internal_originalTime": 1689832521000
    },
    {
        "time": 1689832522000,
        "value": 139.407,
        "_internal_originalTime": 1689832522000
    },
    {
        "time": 1689832523000,
        "value": 139.402,
        "_internal_originalTime": 1689832523000
    },
    {
        "time": 1689832524000,
        "value": 139.407,
        "_internal_originalTime": 1689832524000
    },
    {
        "time": 1689832526000,
        "value": 139.407,
        "_internal_originalTime": 1689832526000
    },
    {
        "time": 1689832528000,
        "value": 139.407,
        "_internal_originalTime": 1689832528000
    },
    {
        "time": 1689832530000,
        "value": 139.402,
        "_internal_originalTime": 1689832530000
    },
    {
        "time": 1689832531000,
        "value": 139.403,
        "_internal_originalTime": 1689832531000
    },
    {
        "time": 1689832533000,
        "value": 139.395,
        "_internal_originalTime": 1689832533000
    },
    {
        "time": 1689832534000,
        "value": 139.398,
        "_internal_originalTime": 1689832534000
    },
    {
        "time": 1689832535000,
        "value": 139.392,
        "_internal_originalTime": 1689832535000
    },
    {
        "time": 1689832537000,
        "value": 139.397,
        "_internal_originalTime": 1689832537000
    },
    {
        "time": 1689832538000,
        "value": 139.397,
        "_internal_originalTime": 1689832538000
    },
    {
        "time": 1689832539000,
        "value": 139.393,
        "_internal_originalTime": 1689832539000
    },
    {
        "time": 1689832541000,
        "value": 139.393,
        "_internal_originalTime": 1689832541000
    },
    {
        "time": 1689832543000,
        "value": 139.397,
        "_internal_originalTime": 1689832543000
    },
    {
        "time": 1689832544000,
        "value": 139.398,
        "_internal_originalTime": 1689832544000
    },
    {
        "time": 1689832545000,
        "value": 139.397,
        "_internal_originalTime": 1689832545000
    },
    {
        "time": 1689832546000,
        "value": 139.397,
        "_internal_originalTime": 1689832546000
    },
    {
        "time": 1689832547000,
        "value": 139.393,
        "_internal_originalTime": 1689832547000
    },
    {
        "time": 1689832549000,
        "value": 139.392,
        "_internal_originalTime": 1689832549000
    },
    {
        "time": 1689832551000,
        "value": 139.397,
        "_internal_originalTime": 1689832551000
    },
    {
        "time": 1689832552000,
        "value": 139.402,
        "_internal_originalTime": 1689832552000
    },
    {
        "time": 1689832553000,
        "value": 139.392,
        "_internal_originalTime": 1689832553000
    },
    {
        "time": 1689832554000,
        "value": 139.4,
        "_internal_originalTime": 1689832554000
    },
    {
        "time": 1689832556000,
        "value": 139.394,
        "_internal_originalTime": 1689832556000
    },
    {
        "time": 1689832557000,
        "value": 139.397,
        "_internal_originalTime": 1689832557000
    },
    {
        "time": 1689832558000,
        "value": 139.397,
        "_internal_originalTime": 1689832558000
    },
    {
        "time": 1689832562000,
        "value": 139.397,
        "_internal_originalTime": 1689832562000
    },
    {
        "time": 1689832564000,
        "value": 139.383,
        "_internal_originalTime": 1689832564000
    },
    {
        "time": 1689832565000,
        "value": 139.384,
        "_internal_originalTime": 1689832565000
    },
    {
        "time": 1689832566000,
        "value": 139.363,
        "_internal_originalTime": 1689832566000
    },
    {
        "time": 1689832568000,
        "value": 139.363,
        "_internal_originalTime": 1689832568000
    },
    {
        "time": 1689832569000,
        "value": 139.346,
        "_internal_originalTime": 1689832569000
    },
    {
        "time": 1689832570000,
        "value": 139.336,
        "_internal_originalTime": 1689832570000
    },
    {
        "time": 1689832571000,
        "value": 139.326,
        "_internal_originalTime": 1689832571000
    },
    {
        "time": 1689832573000,
        "value": 139.306,
        "_internal_originalTime": 1689832573000
    },
    {
        "time": 1689832574000,
        "value": 139.297,
        "_internal_originalTime": 1689832574000
    },
    {
        "time": 1689832575000,
        "value": 139.289,
        "_internal_originalTime": 1689832575000
    },
    {
        "time": 1689832576000,
        "value": 139.276,
        "_internal_originalTime": 1689832576000
    },
    {
        "time": 1689832577000,
        "value": 139.286,
        "_internal_originalTime": 1689832577000
    },
    {
        "time": 1689832579000,
        "value": 139.258,
        "_internal_originalTime": 1689832579000
    },
    {
        "time": 1689832580000,
        "value": 139.264,
        "_internal_originalTime": 1689832580000
    },
    {
        "time": 1689832581000,
        "value": 139.256,
        "_internal_originalTime": 1689832581000
    },
    {
        "time": 1689832582000,
        "value": 139.26,
        "_internal_originalTime": 1689832582000
    },
    {
        "time": 1689832583000,
        "value": 139.264,
        "_internal_originalTime": 1689832583000
    },
    {
        "time": 1689832584000,
        "value": 139.258,
        "_internal_originalTime": 1689832584000
    },
    {
        "time": 1689832586000,
        "value": 139.265,
        "_internal_originalTime": 1689832586000
    },
    {
        "time": 1689832587000,
        "value": 139.265,
        "_internal_originalTime": 1689832587000
    },
    {
        "time": 1689832588000,
        "value": 139.269,
        "_internal_originalTime": 1689832588000
    },
    {
        "time": 1689832589000,
        "value": 139.281,
        "_internal_originalTime": 1689832589000
    },
    {
        "time": 1689832590000,
        "value": 139.288,
        "_internal_originalTime": 1689832590000
    },
    {
        "time": 1689832591000,
        "value": 139.281,
        "_internal_originalTime": 1689832591000
    },
    {
        "time": 1689832593000,
        "value": 139.282,
        "_internal_originalTime": 1689832593000
    },
    {
        "time": 1689832594000,
        "value": 139.281,
        "_internal_originalTime": 1689832594000
    },
    {
        "time": 1689832595000,
        "value": 139.302,
        "_internal_originalTime": 1689832595000
    },
    {
        "time": 1689832597000,
        "value": 139.312,
        "_internal_originalTime": 1689832597000
    },
    {
        "time": 1689832598000,
        "value": 139.319,
        "_internal_originalTime": 1689832598000
    },
    {
        "time": 1689832600000,
        "value": 139.316,
        "_internal_originalTime": 1689832600000
    },
    {
        "time": 1689832602000,
        "value": 139.318,
        "_internal_originalTime": 1689832602000
    },
    {
        "time": 1689832603000,
        "value": 139.319,
        "_internal_originalTime": 1689832603000
    },
    {
        "time": 1689832606000,
        "value": 139.324,
        "_internal_originalTime": 1689832606000
    },
    {
        "time": 1689832607000,
        "value": 139.322,
        "_internal_originalTime": 1689832607000
    },
    {
        "time": 1689832608000,
        "value": 139.322,
        "_internal_originalTime": 1689832608000
    },
    {
        "time": 1689832609000,
        "value": 139.32,
        "_internal_originalTime": 1689832609000
    },
    {
        "time": 1689832611000,
        "value": 139.318,
        "_internal_originalTime": 1689832611000
    },
    {
        "time": 1689832612000,
        "value": 139.326,
        "_internal_originalTime": 1689832612000
    },
    {
        "time": 1689832614000,
        "value": 139.339,
        "_internal_originalTime": 1689832614000
    },
    {
        "time": 1689832615000,
        "value": 139.34,
        "_internal_originalTime": 1689832615000
    },
    {
        "time": 1689832616000,
        "value": 139.342,
        "_internal_originalTime": 1689832616000
    },
    {
        "time": 1689832618000,
        "value": 139.354,
        "_internal_originalTime": 1689832618000
    },
    {
        "time": 1689832619000,
        "value": 139.358,
        "_internal_originalTime": 1689832619000
    },
    {
        "time": 1689832620000,
        "value": 139.352,
        "_internal_originalTime": 1689832620000
    },
    {
        "time": 1689832621000,
        "value": 139.373,
        "_internal_originalTime": 1689832621000
    },
    {
        "time": 1689832622000,
        "value": 139.374,
        "_internal_originalTime": 1689832622000
    },
    {
        "time": 1689832624000,
        "value": 139.368,
        "_internal_originalTime": 1689832624000
    },
    {
        "time": 1689832625000,
        "value": 139.369,
        "_internal_originalTime": 1689832625000
    },
    {
        "time": 1689832627000,
        "value": 139.368,
        "_internal_originalTime": 1689832627000
    },
    {
        "time": 1689832628000,
        "value": 139.364,
        "_internal_originalTime": 1689832628000
    },
    {
        "time": 1689832629000,
        "value": 139.364,
        "_internal_originalTime": 1689832629000
    },
    {
        "time": 1689832630000,
        "value": 139.365,
        "_internal_originalTime": 1689832630000
    },
    {
        "time": 1689832632000,
        "value": 139.365,
        "_internal_originalTime": 1689832632000
    },
    {
        "time": 1689832633000,
        "value": 139.364,
        "_internal_originalTime": 1689832633000
    },
    {
        "time": 1689832634000,
        "value": 139.341,
        "_internal_originalTime": 1689832634000
    },
    {
        "time": 1689832636000,
        "value": 139.336,
        "_internal_originalTime": 1689832636000
    },
    {
        "time": 1689832637000,
        "value": 139.342,
        "_internal_originalTime": 1689832637000
    },
    {
        "time": 1689832639000,
        "value": 139.342,
        "_internal_originalTime": 1689832639000
    },
    {
        "time": 1689832640000,
        "value": 139.336,
        "_internal_originalTime": 1689832640000
    },
    {
        "time": 1689832641000,
        "value": 139.34,
        "_internal_originalTime": 1689832641000
    },
    {
        "time": 1689832642000,
        "value": 139.343,
        "_internal_originalTime": 1689832642000
    },
    {
        "time": 1689832643000,
        "value": 139.336,
        "_internal_originalTime": 1689832643000
    },
    {
        "time": 1689832644000,
        "value": 139.339,
        "_internal_originalTime": 1689832644000
    },
    {
        "time": 1689832645000,
        "value": 139.336,
        "_internal_originalTime": 1689832645000
    },
    {
        "time": 1689832647000,
        "value": 139.336,
        "_internal_originalTime": 1689832647000
    },
    {
        "time": 1689832648000,
        "value": 139.34,
        "_internal_originalTime": 1689832648000
    },
    {
        "time": 1689832649000,
        "value": 139.342,
        "_internal_originalTime": 1689832649000
    },
    {
        "time": 1689832651000,
        "value": 139.334,
        "_internal_originalTime": 1689832651000
    },
    {
        "time": 1689832652000,
        "value": 139.334,
        "_internal_originalTime": 1689832652000
    },
    {
        "time": 1689832653000,
        "value": 139.328,
        "_internal_originalTime": 1689832653000
    },
    {
        "time": 1689832654000,
        "value": 139.319,
        "_internal_originalTime": 1689832654000
    },
    {
        "time": 1689832655000,
        "value": 139.32,
        "_internal_originalTime": 1689832655000
    },
    {
        "time": 1689832657000,
        "value": 139.324,
        "_internal_originalTime": 1689832657000
    },
    {
        "time": 1689832658000,
        "value": 139.32,
        "_internal_originalTime": 1689832658000
    },
    {
        "time": 1689832659000,
        "value": 139.322,
        "_internal_originalTime": 1689832659000
    },
    {
        "time": 1689832662000,
        "value": 139.315,
        "_internal_originalTime": 1689832662000
    },
    {
        "time": 1689832664000,
        "value": 139.317,
        "_internal_originalTime": 1689832664000
    },
    {
        "time": 1689832665000,
        "value": 139.32,
        "_internal_originalTime": 1689832665000
    },
    {
        "time": 1689832666000,
        "value": 139.321,
        "_internal_originalTime": 1689832666000
    },
    {
        "time": 1689832667000,
        "value": 139.324,
        "_internal_originalTime": 1689832667000
    },
    {
        "time": 1689832669000,
        "value": 139.318,
        "_internal_originalTime": 1689832669000
    },
    {
        "time": 1689832671000,
        "value": 139.331,
        "_internal_originalTime": 1689832671000
    },
    {
        "time": 1689832672000,
        "value": 139.32,
        "_internal_originalTime": 1689832672000
    },
    {
        "time": 1689832673000,
        "value": 139.326,
        "_internal_originalTime": 1689832673000
    },
    {
        "time": 1689832675000,
        "value": 139.32,
        "_internal_originalTime": 1689832675000
    },
    {
        "time": 1689832676000,
        "value": 139.326,
        "_internal_originalTime": 1689832676000
    },
    {
        "time": 1689832679000,
        "value": 139.321,
        "_internal_originalTime": 1689832679000
    },
    {
        "time": 1689832681000,
        "value": 139.32,
        "_internal_originalTime": 1689832681000
    },
    {
        "time": 1689832682000,
        "value": 139.326,
        "_internal_originalTime": 1689832682000
    },
    {
        "time": 1689832683000,
        "value": 139.326,
        "_internal_originalTime": 1689832683000
    },
    {
        "time": 1689832684000,
        "value": 139.331,
        "_internal_originalTime": 1689832684000
    },
    {
        "time": 1689832686000,
        "value": 139.33,
        "_internal_originalTime": 1689832686000
    },
    {
        "time": 1689832688000,
        "value": 139.334,
        "_internal_originalTime": 1689832688000
    },
    {
        "time": 1689832689000,
        "value": 139.343,
        "_internal_originalTime": 1689832689000
    },
    {
        "time": 1689832690000,
        "value": 139.334,
        "_internal_originalTime": 1689832690000
    },
    {
        "time": 1689832692000,
        "value": 139.339,
        "_internal_originalTime": 1689832692000
    },
    {
        "time": 1689832693000,
        "value": 139.337,
        "_internal_originalTime": 1689832693000
    },
    {
        "time": 1689832694000,
        "value": 139.336,
        "_internal_originalTime": 1689832694000
    },
    {
        "time": 1689832695000,
        "value": 139.342,
        "_internal_originalTime": 1689832695000
    },
    {
        "time": 1689832696000,
        "value": 139.339,
        "_internal_originalTime": 1689832696000
    },
    {
        "time": 1689832697000,
        "value": 139.338,
        "_internal_originalTime": 1689832697000
    },
    {
        "time": 1689832699000,
        "value": 139.34,
        "_internal_originalTime": 1689832699000
    },
    {
        "time": 1689832700000,
        "value": 139.34,
        "_internal_originalTime": 1689832700000
    },
    {
        "time": 1689832701000,
        "value": 139.341,
        "_internal_originalTime": 1689832701000
    },
    {
        "time": 1689832702000,
        "value": 139.336,
        "_internal_originalTime": 1689832702000
    },
    {
        "time": 1689832703000,
        "value": 139.336,
        "_internal_originalTime": 1689832703000
    },
    {
        "time": 1689832705000,
        "value": 139.336,
        "_internal_originalTime": 1689832705000
    },
    {
        "time": 1689832706000,
        "value": 139.342,
        "_internal_originalTime": 1689832706000
    },
    {
        "time": 1689832707000,
        "value": 139.335,
        "_internal_originalTime": 1689832707000
    },
    {
        "time": 1689832708000,
        "value": 139.334,
        "_internal_originalTime": 1689832708000
    },
    {
        "time": 1689832710000,
        "value": 139.343,
        "_internal_originalTime": 1689832710000
    },
    {
        "time": 1689832712000,
        "value": 139.349,
        "_internal_originalTime": 1689832712000
    },
    {
        "time": 1689832713000,
        "value": 139.348,
        "_internal_originalTime": 1689832713000
    },
    {
        "time": 1689832714000,
        "value": 139.348,
        "_internal_originalTime": 1689832714000
    },
    {
        "time": 1689832715000,
        "value": 139.348,
        "_internal_originalTime": 1689832715000
    },
    {
        "time": 1689832718000,
        "value": 139.352,
        "_internal_originalTime": 1689832718000
    },
    {
        "time": 1689832719000,
        "value": 139.352,
        "_internal_originalTime": 1689832719000
    },
    {
        "time": 1689832720000,
        "value": 139.359,
        "_internal_originalTime": 1689832720000
    },
    {
        "time": 1689832721000,
        "value": 139.355,
        "_internal_originalTime": 1689832721000
    },
    {
        "time": 1689832723000,
        "value": 139.358,
        "_internal_originalTime": 1689832723000
    },
    {
        "time": 1689832724000,
        "value": 139.357,
        "_internal_originalTime": 1689832724000
    },
    {
        "time": 1689832725000,
        "value": 139.359,
        "_internal_originalTime": 1689832725000
    },
    {
        "time": 1689832726000,
        "value": 139.358,
        "_internal_originalTime": 1689832726000
    },
    {
        "time": 1689832728000,
        "value": 139.358,
        "_internal_originalTime": 1689832728000
    },
    {
        "time": 1689832729000,
        "value": 139.346,
        "_internal_originalTime": 1689832729000
    },
    {
        "time": 1689832731000,
        "value": 139.349,
        "_internal_originalTime": 1689832731000
    },
    {
        "time": 1689832732000,
        "value": 139.347,
        "_internal_originalTime": 1689832732000
    },
    {
        "time": 1689832733000,
        "value": 139.345,
        "_internal_originalTime": 1689832733000
    },
    {
        "time": 1689832734000,
        "value": 139.346,
        "_internal_originalTime": 1689832734000
    },
    {
        "time": 1689832736000,
        "value": 139.344,
        "_internal_originalTime": 1689832736000
    },
    {
        "time": 1689832737000,
        "value": 139.344,
        "_internal_originalTime": 1689832737000
    },
    {
        "time": 1689832739000,
        "value": 139.351,
        "_internal_originalTime": 1689832739000
    },
    {
        "time": 1689832740000,
        "value": 139.357,
        "_internal_originalTime": 1689832740000
    },
    {
        "time": 1689832741000,
        "value": 139.357,
        "_internal_originalTime": 1689832741000
    },
    {
        "time": 1689832743000,
        "value": 139.356,
        "_internal_originalTime": 1689832743000
    },
    {
        "time": 1689832744000,
        "value": 139.357,
        "_internal_originalTime": 1689832744000
    },
    {
        "time": 1689832745000,
        "value": 139.359,
        "_internal_originalTime": 1689832745000
    },
    {
        "time": 1689832747000,
        "value": 139.361,
        "_internal_originalTime": 1689832747000
    },
    {
        "time": 1689832748000,
        "value": 139.358,
        "_internal_originalTime": 1689832748000
    },
    {
        "time": 1689832750000,
        "value": 139.357,
        "_internal_originalTime": 1689832750000
    },
    {
        "time": 1689832751000,
        "value": 139.358,
        "_internal_originalTime": 1689832751000
    },
    {
        "time": 1689832752000,
        "value": 139.362,
        "_internal_originalTime": 1689832752000
    },
    {
        "time": 1689832754000,
        "value": 139.358,
        "_internal_originalTime": 1689832754000
    },
    {
        "time": 1689832755000,
        "value": 139.357,
        "_internal_originalTime": 1689832755000
    },
    {
        "time": 1689832756000,
        "value": 139.358,
        "_internal_originalTime": 1689832756000
    },
    {
        "time": 1689832758000,
        "value": 139.354,
        "_internal_originalTime": 1689832758000
    },
    {
        "time": 1689832759000,
        "value": 139.354,
        "_internal_originalTime": 1689832759000
    },
    {
        "time": 1689832760000,
        "value": 139.361,
        "_internal_originalTime": 1689832760000
    },
    {
        "time": 1689832762000,
        "value": 139.357,
        "_internal_originalTime": 1689832762000
    },
    {
        "time": 1689832763000,
        "value": 139.354,
        "_internal_originalTime": 1689832763000
    },
    {
        "time": 1689832765000,
        "value": 139.348,
        "_internal_originalTime": 1689832765000
    },
    {
        "time": 1689832766000,
        "value": 139.348,
        "_internal_originalTime": 1689832766000
    },
    {
        "time": 1689832768000,
        "value": 139.349,
        "_internal_originalTime": 1689832768000
    },
    {
        "time": 1689832769000,
        "value": 139.357,
        "_internal_originalTime": 1689832769000
    },
    {
        "time": 1689832770000,
        "value": 139.357,
        "_internal_originalTime": 1689832770000
    },
    {
        "time": 1689832772000,
        "value": 139.354,
        "_internal_originalTime": 1689832772000
    },
    {
        "time": 1689832774000,
        "value": 139.358,
        "_internal_originalTime": 1689832774000
    },
    {
        "time": 1689832776000,
        "value": 139.358,
        "_internal_originalTime": 1689832776000
    },
    {
        "time": 1689832777000,
        "value": 139.358,
        "_internal_originalTime": 1689832777000
    },
    {
        "time": 1689832778000,
        "value": 139.358,
        "_internal_originalTime": 1689832778000
    },
    {
        "time": 1689832780000,
        "value": 139.353,
        "_internal_originalTime": 1689832780000
    },
    {
        "time": 1689832781000,
        "value": 139.357,
        "_internal_originalTime": 1689832781000
    },
    {
        "time": 1689832782000,
        "value": 139.358,
        "_internal_originalTime": 1689832782000
    },
    {
        "time": 1689832783000,
        "value": 139.358,
        "_internal_originalTime": 1689832783000
    },
    {
        "time": 1689832784000,
        "value": 139.358,
        "_internal_originalTime": 1689832784000
    },
    {
        "time": 1689832785000,
        "value": 139.357,
        "_internal_originalTime": 1689832785000
    },
    {
        "time": 1689832786000,
        "value": 139.36,
        "_internal_originalTime": 1689832786000
    },
    {
        "time": 1689832788000,
        "value": 139.355,
        "_internal_originalTime": 1689832788000
    },
    {
        "time": 1689832790000,
        "value": 139.358,
        "_internal_originalTime": 1689832790000
    },
    {
        "time": 1689832791000,
        "value": 139.358,
        "_internal_originalTime": 1689832791000
    },
    {
        "time": 1689832793000,
        "value": 139.355,
        "_internal_originalTime": 1689832793000
    },
    {
        "time": 1689832794000,
        "value": 139.343,
        "_internal_originalTime": 1689832794000
    },
    {
        "time": 1689832795000,
        "value": 139.338,
        "_internal_originalTime": 1689832795000
    },
    {
        "time": 1689832796000,
        "value": 139.344,
        "_internal_originalTime": 1689832796000
    },
    {
        "time": 1689832797000,
        "value": 139.346,
        "_internal_originalTime": 1689832797000
    },
    {
        "time": 1689832799000,
        "value": 139.347,
        "_internal_originalTime": 1689832799000
    },
    {
        "time": 1689832800000,
        "value": 139.343,
        "_internal_originalTime": 1689832800000
    },
    {
        "time": 1689832801000,
        "value": 139.314,
        "_internal_originalTime": 1689832801000
    },
    {
        "time": 1689832803000,
        "value": 139.324,
        "_internal_originalTime": 1689832803000
    },
    {
        "time": 1689832804000,
        "value": 139.314,
        "_internal_originalTime": 1689832804000
    },
    {
        "time": 1689832805000,
        "value": 139.315,
        "_internal_originalTime": 1689832805000
    },
    {
        "time": 1689832807000,
        "value": 139.315,
        "_internal_originalTime": 1689832807000
    },
    {
        "time": 1689832808000,
        "value": 139.313,
        "_internal_originalTime": 1689832808000
    },
    {
        "time": 1689832809000,
        "value": 139.315,
        "_internal_originalTime": 1689832809000
    },
    {
        "time": 1689832810000,
        "value": 139.318,
        "_internal_originalTime": 1689832810000
    },
    {
        "time": 1689832811000,
        "value": 139.309,
        "_internal_originalTime": 1689832811000
    },
    {
        "time": 1689832812000,
        "value": 139.308,
        "_internal_originalTime": 1689832812000
    },
    {
        "time": 1689832814000,
        "value": 139.312,
        "_internal_originalTime": 1689832814000
    },
    {
        "time": 1689832815000,
        "value": 139.313,
        "_internal_originalTime": 1689832815000
    },
    {
        "time": 1689832816000,
        "value": 139.313,
        "_internal_originalTime": 1689832816000
    },
    {
        "time": 1689832817000,
        "value": 139.315,
        "_internal_originalTime": 1689832817000
    },
    {
        "time": 1689832818000,
        "value": 139.316,
        "_internal_originalTime": 1689832818000
    },
    {
        "time": 1689832820000,
        "value": 139.321,
        "_internal_originalTime": 1689832820000
    },
    {
        "time": 1689832821000,
        "value": 139.32,
        "_internal_originalTime": 1689832821000
    },
    {
        "time": 1689832822000,
        "value": 139.319,
        "_internal_originalTime": 1689832822000
    },
    {
        "time": 1689832823000,
        "value": 139.319,
        "_internal_originalTime": 1689832823000
    },
    {
        "time": 1689832824000,
        "value": 139.319,
        "_internal_originalTime": 1689832824000
    },
    {
        "time": 1689832826000,
        "value": 139.308,
        "_internal_originalTime": 1689832826000
    },
    {
        "time": 1689832827000,
        "value": 139.302,
        "_internal_originalTime": 1689832827000
    },
    {
        "time": 1689832829000,
        "value": 139.296,
        "_internal_originalTime": 1689832829000
    },
    {
        "time": 1689832830000,
        "value": 139.293,
        "_internal_originalTime": 1689832830000
    },
    {
        "time": 1689832831000,
        "value": 139.302,
        "_internal_originalTime": 1689832831000
    },
    {
        "time": 1689832832000,
        "value": 139.308,
        "_internal_originalTime": 1689832832000
    },
    {
        "time": 1689832835000,
        "value": 139.308,
        "_internal_originalTime": 1689832835000
    },
    {
        "time": 1689832836000,
        "value": 139.299,
        "_internal_originalTime": 1689832836000
    },
    {
        "time": 1689832837000,
        "value": 139.308,
        "_internal_originalTime": 1689832837000
    },
    {
        "time": 1689832839000,
        "value": 139.308,
        "_internal_originalTime": 1689832839000
    },
    {
        "time": 1689832840000,
        "value": 139.303,
        "_internal_originalTime": 1689832840000
    },
    {
        "time": 1689832841000,
        "value": 139.308,
        "_internal_originalTime": 1689832841000
    },
    {
        "time": 1689832843000,
        "value": 139.312,
        "_internal_originalTime": 1689832843000
    },
    {
        "time": 1689832844000,
        "value": 139.294,
        "_internal_originalTime": 1689832844000
    },
    {
        "time": 1689832846000,
        "value": 139.292,
        "_internal_originalTime": 1689832846000
    },
    {
        "time": 1689832847000,
        "value": 139.318,
        "_internal_originalTime": 1689832847000
    },
    {
        "time": 1689832848000,
        "value": 139.335,
        "_internal_originalTime": 1689832848000
    },
    {
        "time": 1689832849000,
        "value": 139.344,
        "_internal_originalTime": 1689832849000
    },
    {
        "time": 1689832850000,
        "value": 139.344,
        "_internal_originalTime": 1689832850000
    },
    {
        "time": 1689832852000,
        "value": 139.345,
        "_internal_originalTime": 1689832852000
    },
    {
        "time": 1689832854000,
        "value": 139.347,
        "_internal_originalTime": 1689832854000
    },
    {
        "time": 1689832855000,
        "value": 139.337,
        "_internal_originalTime": 1689832855000
    },
    {
        "time": 1689832856000,
        "value": 139.341,
        "_internal_originalTime": 1689832856000
    },
    {
        "time": 1689832858000,
        "value": 139.34,
        "_internal_originalTime": 1689832858000
    },
    {
        "time": 1689832859000,
        "value": 139.336,
        "_internal_originalTime": 1689832859000
    },
    {
        "time": 1689832861000,
        "value": 139.346,
        "_internal_originalTime": 1689832861000
    },
    {
        "time": 1689832862000,
        "value": 139.331,
        "_internal_originalTime": 1689832862000
    },
    {
        "time": 1689832864000,
        "value": 139.338,
        "_internal_originalTime": 1689832864000
    },
    {
        "time": 1689832866000,
        "value": 139.344,
        "_internal_originalTime": 1689832866000
    },
    {
        "time": 1689832867000,
        "value": 139.328,
        "_internal_originalTime": 1689832867000
    },
    {
        "time": 1689832868000,
        "value": 139.34,
        "_internal_originalTime": 1689832868000
    },
    {
        "time": 1689832869000,
        "value": 139.333,
        "_internal_originalTime": 1689832869000
    },
    {
        "time": 1689832871000,
        "value": 139.344,
        "_internal_originalTime": 1689832871000
    },
    {
        "time": 1689832872000,
        "value": 139.338,
        "_internal_originalTime": 1689832872000
    },
    {
        "time": 1689832873000,
        "value": 139.333,
        "_internal_originalTime": 1689832873000
    },
    {
        "time": 1689832875000,
        "value": 139.332,
        "_internal_originalTime": 1689832875000
    },
    {
        "time": 1689832876000,
        "value": 139.338,
        "_internal_originalTime": 1689832876000
    },
    {
        "time": 1689832878000,
        "value": 139.334,
        "_internal_originalTime": 1689832878000
    },
    {
        "time": 1689832879000,
        "value": 139.34,
        "_internal_originalTime": 1689832879000
    },
    {
        "time": 1689832880000,
        "value": 139.34,
        "_internal_originalTime": 1689832880000
    },
    {
        "time": 1689832882000,
        "value": 139.341,
        "_internal_originalTime": 1689832882000
    },
    {
        "time": 1689832883000,
        "value": 139.347,
        "_internal_originalTime": 1689832883000
    },
    {
        "time": 1689832884000,
        "value": 139.34,
        "_internal_originalTime": 1689832884000
    },
    {
        "time": 1689832885000,
        "value": 139.36,
        "_internal_originalTime": 1689832885000
    },
    {
        "time": 1689832886000,
        "value": 139.355,
        "_internal_originalTime": 1689832886000
    },
    {
        "time": 1689832887000,
        "value": 139.361,
        "_internal_originalTime": 1689832887000
    },
    {
        "time": 1689832889000,
        "value": 139.361,
        "_internal_originalTime": 1689832889000
    },
    {
        "time": 1689832890000,
        "value": 139.361,
        "_internal_originalTime": 1689832890000
    },
    {
        "time": 1689832891000,
        "value": 139.362,
        "_internal_originalTime": 1689832891000
    },
    {
        "time": 1689832892000,
        "value": 139.363,
        "_internal_originalTime": 1689832892000
    },
    {
        "time": 1689832894000,
        "value": 139.363,
        "_internal_originalTime": 1689832894000
    },
    {
        "time": 1689832896000,
        "value": 139.368,
        "_internal_originalTime": 1689832896000
    },
    {
        "time": 1689832897000,
        "value": 139.373,
        "_internal_originalTime": 1689832897000
    },
    {
        "time": 1689832898000,
        "value": 139.375,
        "_internal_originalTime": 1689832898000
    },
    {
        "time": 1689832901000,
        "value": 139.375,
        "_internal_originalTime": 1689832901000
    },
    {
        "time": 1689832902000,
        "value": 139.381,
        "_internal_originalTime": 1689832902000
    },
    {
        "time": 1689832903000,
        "value": 139.375,
        "_internal_originalTime": 1689832903000
    },
    {
        "time": 1689832905000,
        "value": 139.379,
        "_internal_originalTime": 1689832905000
    },
    {
        "time": 1689832906000,
        "value": 139.379,
        "_internal_originalTime": 1689832906000
    },
    {
        "time": 1689832908000,
        "value": 139.386,
        "_internal_originalTime": 1689832908000
    },
    {
        "time": 1689832909000,
        "value": 139.38,
        "_internal_originalTime": 1689832909000
    },
    {
        "time": 1689832910000,
        "value": 139.381,
        "_internal_originalTime": 1689832910000
    },
    {
        "time": 1689832912000,
        "value": 139.381,
        "_internal_originalTime": 1689832912000
    },
    {
        "time": 1689832913000,
        "value": 139.383,
        "_internal_originalTime": 1689832913000
    },
    {
        "time": 1689832915000,
        "value": 139.383,
        "_internal_originalTime": 1689832915000
    },
    {
        "time": 1689832916000,
        "value": 139.38,
        "_internal_originalTime": 1689832916000
    },
    {
        "time": 1689832919000,
        "value": 139.387,
        "_internal_originalTime": 1689832919000
    },
    {
        "time": 1689832921000,
        "value": 139.386,
        "_internal_originalTime": 1689832921000
    },
    {
        "time": 1689832922000,
        "value": 139.386,
        "_internal_originalTime": 1689832922000
    },
    {
        "time": 1689832923000,
        "value": 139.376,
        "_internal_originalTime": 1689832923000
    },
    {
        "time": 1689832924000,
        "value": 139.373,
        "_internal_originalTime": 1689832924000
    },
    {
        "time": 1689832925000,
        "value": 139.383,
        "_internal_originalTime": 1689832925000
    },
    {
        "time": 1689832926000,
        "value": 139.382,
        "_internal_originalTime": 1689832926000
    },
    {
        "time": 1689832927000,
        "value": 139.385,
        "_internal_originalTime": 1689832927000
    },
    {
        "time": 1689832929000,
        "value": 139.39,
        "_internal_originalTime": 1689832929000
    },
    {
        "time": 1689832930000,
        "value": 139.385,
        "_internal_originalTime": 1689832930000
    },
    {
        "time": 1689832931000,
        "value": 139.383,
        "_internal_originalTime": 1689832931000
    },
    {
        "time": 1689832932000,
        "value": 139.378,
        "_internal_originalTime": 1689832932000
    },
    {
        "time": 1689832934000,
        "value": 139.376,
        "_internal_originalTime": 1689832934000
    },
    {
        "time": 1689832935000,
        "value": 139.372,
        "_internal_originalTime": 1689832935000
    },
    {
        "time": 1689832936000,
        "value": 139.372,
        "_internal_originalTime": 1689832936000
    },
    {
        "time": 1689832938000,
        "value": 139.382,
        "_internal_originalTime": 1689832938000
    },
    {
        "time": 1689832939000,
        "value": 139.378,
        "_internal_originalTime": 1689832939000
    },
    {
        "time": 1689832941000,
        "value": 139.374,
        "_internal_originalTime": 1689832941000
    },
    {
        "time": 1689832942000,
        "value": 139.381,
        "_internal_originalTime": 1689832942000
    },
    {
        "time": 1689832943000,
        "value": 139.381,
        "_internal_originalTime": 1689832943000
    },
    {
        "time": 1689832944000,
        "value": 139.386,
        "_internal_originalTime": 1689832944000
    },
    {
        "time": 1689832945000,
        "value": 139.38,
        "_internal_originalTime": 1689832945000
    },
    {
        "time": 1689832946000,
        "value": 139.386,
        "_internal_originalTime": 1689832946000
    },
    {
        "time": 1689832948000,
        "value": 139.371,
        "_internal_originalTime": 1689832948000
    },
    {
        "time": 1689832949000,
        "value": 139.371,
        "_internal_originalTime": 1689832949000
    },
    {
        "time": 1689832950000,
        "value": 139.376,
        "_internal_originalTime": 1689832950000
    },
    {
        "time": 1689832951000,
        "value": 139.37,
        "_internal_originalTime": 1689832951000
    },
    {
        "time": 1689832952000,
        "value": 139.371,
        "_internal_originalTime": 1689832952000
    },
    {
        "time": 1689832954000,
        "value": 139.352,
        "_internal_originalTime": 1689832954000
    },
    {
        "time": 1689832956000,
        "value": 139.353,
        "_internal_originalTime": 1689832956000
    },
    {
        "time": 1689832959000,
        "value": 139.358,
        "_internal_originalTime": 1689832959000
    },
    {
        "time": 1689832960000,
        "value": 139.368,
        "_internal_originalTime": 1689832960000
    },
    {
        "time": 1689832961000,
        "value": 139.361,
        "_internal_originalTime": 1689832961000
    },
    {
        "time": 1689832962000,
        "value": 139.375,
        "_internal_originalTime": 1689832962000
    },
    {
        "time": 1689832963000,
        "value": 139.378,
        "_internal_originalTime": 1689832963000
    },
    {
        "time": 1689832964000,
        "value": 139.376,
        "_internal_originalTime": 1689832964000
    },
    {
        "time": 1689832965000,
        "value": 139.376,
        "_internal_originalTime": 1689832965000
    },
    {
        "time": 1689832966000,
        "value": 139.37,
        "_internal_originalTime": 1689832966000
    },
    {
        "time": 1689832968000,
        "value": 139.376,
        "_internal_originalTime": 1689832968000
    },
    {
        "time": 1689832969000,
        "value": 139.372,
        "_internal_originalTime": 1689832969000
    },
    {
        "time": 1689832970000,
        "value": 139.378,
        "_internal_originalTime": 1689832970000
    },
    {
        "time": 1689832971000,
        "value": 139.374,
        "_internal_originalTime": 1689832971000
    },
    {
        "time": 1689832973000,
        "value": 139.378,
        "_internal_originalTime": 1689832973000
    },
    {
        "time": 1689832974000,
        "value": 139.377,
        "_internal_originalTime": 1689832974000
    },
    {
        "time": 1689832975000,
        "value": 139.377,
        "_internal_originalTime": 1689832975000
    },
    {
        "time": 1689832977000,
        "value": 139.38,
        "_internal_originalTime": 1689832977000
    },
    {
        "time": 1689832979000,
        "value": 139.368,
        "_internal_originalTime": 1689832979000
    },
    {
        "time": 1689832980000,
        "value": 139.367,
        "_internal_originalTime": 1689832980000
    },
    {
        "time": 1689832981000,
        "value": 139.368,
        "_internal_originalTime": 1689832981000
    },
    {
        "time": 1689832982000,
        "value": 139.367,
        "_internal_originalTime": 1689832982000
    },
    {
        "time": 1689832983000,
        "value": 139.368,
        "_internal_originalTime": 1689832983000
    },
    {
        "time": 1689832984000,
        "value": 139.363,
        "_internal_originalTime": 1689832984000
    },
    {
        "time": 1689832985000,
        "value": 139.375,
        "_internal_originalTime": 1689832985000
    },
    {
        "time": 1689832986000,
        "value": 139.38,
        "_internal_originalTime": 1689832986000
    },
    {
        "time": 1689832987000,
        "value": 139.38,
        "_internal_originalTime": 1689832987000
    },
    {
        "time": 1689832988000,
        "value": 139.377,
        "_internal_originalTime": 1689832988000
    },
    {
        "time": 1689832990000,
        "value": 139.382,
        "_internal_originalTime": 1689832990000
    },
    {
        "time": 1689832991000,
        "value": 139.374,
        "_internal_originalTime": 1689832991000
    },
    {
        "time": 1689832992000,
        "value": 139.376,
        "_internal_originalTime": 1689832992000
    },
    {
        "time": 1689832994000,
        "value": 139.376,
        "_internal_originalTime": 1689832994000
    },
    {
        "time": 1689832995000,
        "value": 139.377,
        "_internal_originalTime": 1689832995000
    },
    {
        "time": 1689832997000,
        "value": 139.381,
        "_internal_originalTime": 1689832997000
    },
    {
        "time": 1689832998000,
        "value": 139.379,
        "_internal_originalTime": 1689832998000
    },
    {
        "time": 1689832999000,
        "value": 139.383,
        "_internal_originalTime": 1689832999000
    },
    {
        "time": 1689833001000,
        "value": 139.386,
        "_internal_originalTime": 1689833001000
    },
    {
        "time": 1689833002000,
        "value": 139.38,
        "_internal_originalTime": 1689833002000
    },
    {
        "time": 1689833003000,
        "value": 139.379,
        "_internal_originalTime": 1689833003000
    },
    {
        "time": 1689833004000,
        "value": 139.375,
        "_internal_originalTime": 1689833004000
    },
    {
        "time": 1689833006000,
        "value": 139.375,
        "_internal_originalTime": 1689833006000
    },
    {
        "time": 1689833007000,
        "value": 139.379,
        "_internal_originalTime": 1689833007000
    },
    {
        "time": 1689833008000,
        "value": 139.381,
        "_internal_originalTime": 1689833008000
    },
    {
        "time": 1689833009000,
        "value": 139.386,
        "_internal_originalTime": 1689833009000
    },
    {
        "time": 1689833011000,
        "value": 139.382,
        "_internal_originalTime": 1689833011000
    },
    {
        "time": 1689833013000,
        "value": 139.383,
        "_internal_originalTime": 1689833013000
    },
    {
        "time": 1689833014000,
        "value": 139.381,
        "_internal_originalTime": 1689833014000
    },
    {
        "time": 1689833015000,
        "value": 139.386,
        "_internal_originalTime": 1689833015000
    },
    {
        "time": 1689833017000,
        "value": 139.386,
        "_internal_originalTime": 1689833017000
    },
    {
        "time": 1689833019000,
        "value": 139.382,
        "_internal_originalTime": 1689833019000
    },
    {
        "time": 1689833021000,
        "value": 139.383,
        "_internal_originalTime": 1689833021000
    },
    {
        "time": 1689833022000,
        "value": 139.384,
        "_internal_originalTime": 1689833022000
    },
    {
        "time": 1689833024000,
        "value": 139.384,
        "_internal_originalTime": 1689833024000
    },
    {
        "time": 1689833026000,
        "value": 139.387,
        "_internal_originalTime": 1689833026000
    },
    {
        "time": 1689833028000,
        "value": 139.384,
        "_internal_originalTime": 1689833028000
    },
    {
        "time": 1689833029000,
        "value": 139.385,
        "_internal_originalTime": 1689833029000
    },
    {
        "time": 1689833032000,
        "value": 139.389,
        "_internal_originalTime": 1689833032000
    },
    {
        "time": 1689833033000,
        "value": 139.384,
        "_internal_originalTime": 1689833033000
    },
    {
        "time": 1689833035000,
        "value": 139.386,
        "_internal_originalTime": 1689833035000
    },
    {
        "time": 1689833036000,
        "value": 139.386,
        "_internal_originalTime": 1689833036000
    },
    {
        "time": 1689833037000,
        "value": 139.381,
        "_internal_originalTime": 1689833037000
    },
    {
        "time": 1689833039000,
        "value": 139.382,
        "_internal_originalTime": 1689833039000
    },
    {
        "time": 1689833040000,
        "value": 139.386,
        "_internal_originalTime": 1689833040000
    },
    {
        "time": 1689833041000,
        "value": 139.38,
        "_internal_originalTime": 1689833041000
    },
    {
        "time": 1689833043000,
        "value": 139.386,
        "_internal_originalTime": 1689833043000
    },
    {
        "time": 1689833044000,
        "value": 139.382,
        "_internal_originalTime": 1689833044000
    },
    {
        "time": 1689833047000,
        "value": 139.393,
        "_internal_originalTime": 1689833047000
    },
    {
        "time": 1689833048000,
        "value": 139.392,
        "_internal_originalTime": 1689833048000
    },
    {
        "time": 1689833049000,
        "value": 139.392,
        "_internal_originalTime": 1689833049000
    },
    {
        "time": 1689833050000,
        "value": 139.396,
        "_internal_originalTime": 1689833050000
    },
    {
        "time": 1689833052000,
        "value": 139.39,
        "_internal_originalTime": 1689833052000
    },
    {
        "time": 1689833053000,
        "value": 139.391,
        "_internal_originalTime": 1689833053000
    },
    {
        "time": 1689833054000,
        "value": 139.392,
        "_internal_originalTime": 1689833054000
    },
    {
        "time": 1689833055000,
        "value": 139.392,
        "_internal_originalTime": 1689833055000
    },
    {
        "time": 1689833057000,
        "value": 139.404,
        "_internal_originalTime": 1689833057000
    },
    {
        "time": 1689833058000,
        "value": 139.4,
        "_internal_originalTime": 1689833058000
    },
    {
        "time": 1689833059000,
        "value": 139.402,
        "_internal_originalTime": 1689833059000
    },
    {
        "time": 1689833061000,
        "value": 139.399,
        "_internal_originalTime": 1689833061000
    },
    {
        "time": 1689833062000,
        "value": 139.397,
        "_internal_originalTime": 1689833062000
    },
    {
        "time": 1689833065000,
        "value": 139.405,
        "_internal_originalTime": 1689833065000
    },
    {
        "time": 1689833066000,
        "value": 139.404,
        "_internal_originalTime": 1689833066000
    },
    {
        "time": 1689833067000,
        "value": 139.413,
        "_internal_originalTime": 1689833067000
    },
    {
        "time": 1689833068000,
        "value": 139.421,
        "_internal_originalTime": 1689833068000
    },
    {
        "time": 1689833069000,
        "value": 139.416,
        "_internal_originalTime": 1689833069000
    },
    {
        "time": 1689833071000,
        "value": 139.419,
        "_internal_originalTime": 1689833071000
    },
    {
        "time": 1689833072000,
        "value": 139.417,
        "_internal_originalTime": 1689833072000
    },
    {
        "time": 1689833073000,
        "value": 139.41,
        "_internal_originalTime": 1689833073000
    },
    {
        "time": 1689833074000,
        "value": 139.416,
        "_internal_originalTime": 1689833074000
    },
    {
        "time": 1689833075000,
        "value": 139.415,
        "_internal_originalTime": 1689833075000
    },
    {
        "time": 1689833077000,
        "value": 139.415,
        "_internal_originalTime": 1689833077000
    },
    {
        "time": 1689833079000,
        "value": 139.405,
        "_internal_originalTime": 1689833079000
    },
    {
        "time": 1689833080000,
        "value": 139.402,
        "_internal_originalTime": 1689833080000
    },
    {
        "time": 1689833081000,
        "value": 139.408,
        "_internal_originalTime": 1689833081000
    },
    {
        "time": 1689833082000,
        "value": 139.407,
        "_internal_originalTime": 1689833082000
    },
    {
        "time": 1689833084000,
        "value": 139.407,
        "_internal_originalTime": 1689833084000
    },
    {
        "time": 1689833085000,
        "value": 139.408,
        "_internal_originalTime": 1689833085000
    },
    {
        "time": 1689833086000,
        "value": 139.41,
        "_internal_originalTime": 1689833086000
    },
    {
        "time": 1689833088000,
        "value": 139.408,
        "_internal_originalTime": 1689833088000
    },
    {
        "time": 1689833089000,
        "value": 139.411,
        "_internal_originalTime": 1689833089000
    },
    {
        "time": 1689833090000,
        "value": 139.419,
        "_internal_originalTime": 1689833090000
    },
    {
        "time": 1689833091000,
        "value": 139.419,
        "_internal_originalTime": 1689833091000
    },
    {
        "time": 1689833092000,
        "value": 139.421,
        "_internal_originalTime": 1689833092000
    },
    {
        "time": 1689833094000,
        "value": 139.423,
        "_internal_originalTime": 1689833094000
    },
    {
        "time": 1689833095000,
        "value": 139.418,
        "_internal_originalTime": 1689833095000
    },
    {
        "time": 1689833097000,
        "value": 139.425,
        "_internal_originalTime": 1689833097000
    },
    {
        "time": 1689833098000,
        "value": 139.424,
        "_internal_originalTime": 1689833098000
    },
    {
        "time": 1689833100000,
        "value": 139.427,
        "_internal_originalTime": 1689833100000
    },
    {
        "time": 1689833101000,
        "value": 139.43,
        "_internal_originalTime": 1689833101000
    },
    {
        "time": 1689833103000,
        "value": 139.431,
        "_internal_originalTime": 1689833103000
    },
    {
        "time": 1689833104000,
        "value": 139.43,
        "_internal_originalTime": 1689833104000
    },
    {
        "time": 1689833105000,
        "value": 139.425,
        "_internal_originalTime": 1689833105000
    },
    {
        "time": 1689833107000,
        "value": 139.43,
        "_internal_originalTime": 1689833107000
    },
    {
        "time": 1689833108000,
        "value": 139.424,
        "_internal_originalTime": 1689833108000
    },
    {
        "time": 1689833110000,
        "value": 139.425,
        "_internal_originalTime": 1689833110000
    },
    {
        "time": 1689833111000,
        "value": 139.427,
        "_internal_originalTime": 1689833111000
    },
    {
        "time": 1689833112000,
        "value": 139.425,
        "_internal_originalTime": 1689833112000
    },
    {
        "time": 1689833113000,
        "value": 139.429,
        "_internal_originalTime": 1689833113000
    },
    {
        "time": 1689833114000,
        "value": 139.429,
        "_internal_originalTime": 1689833114000
    },
    {
        "time": 1689833116000,
        "value": 139.438,
        "_internal_originalTime": 1689833116000
    },
    {
        "time": 1689833117000,
        "value": 139.43,
        "_internal_originalTime": 1689833117000
    },
    {
        "time": 1689833118000,
        "value": 139.437,
        "_internal_originalTime": 1689833118000
    },
    {
        "time": 1689833120000,
        "value": 139.446,
        "_internal_originalTime": 1689833120000
    },
    {
        "time": 1689833122000,
        "value": 139.438,
        "_internal_originalTime": 1689833122000
    },
    {
        "time": 1689833123000,
        "value": 139.443,
        "_internal_originalTime": 1689833123000
    },
    {
        "time": 1689833125000,
        "value": 139.447,
        "_internal_originalTime": 1689833125000
    },
    {
        "time": 1689833126000,
        "value": 139.45,
        "_internal_originalTime": 1689833126000
    },
    {
        "time": 1689833127000,
        "value": 139.452,
        "_internal_originalTime": 1689833127000
    },
    {
        "time": 1689833128000,
        "value": 139.455,
        "_internal_originalTime": 1689833128000
    },
    {
        "time": 1689833129000,
        "value": 139.449,
        "_internal_originalTime": 1689833129000
    },
    {
        "time": 1689833130000,
        "value": 139.449,
        "_internal_originalTime": 1689833130000
    },
    {
        "time": 1689833133000,
        "value": 139.449,
        "_internal_originalTime": 1689833133000
    },
    {
        "time": 1689833134000,
        "value": 139.448,
        "_internal_originalTime": 1689833134000
    },
    {
        "time": 1689833138000,
        "value": 139.444,
        "_internal_originalTime": 1689833138000
    },
    {
        "time": 1689833139000,
        "value": 139.466,
        "_internal_originalTime": 1689833139000
    },
    {
        "time": 1689833140000,
        "value": 139.46,
        "_internal_originalTime": 1689833140000
    },
    {
        "time": 1689833142000,
        "value": 139.461,
        "_internal_originalTime": 1689833142000
    },
    {
        "time": 1689833145000,
        "value": 139.462,
        "_internal_originalTime": 1689833145000
    },
    {
        "time": 1689833146000,
        "value": 139.463,
        "_internal_originalTime": 1689833146000
    },
    {
        "time": 1689833147000,
        "value": 139.463,
        "_internal_originalTime": 1689833147000
    },
    {
        "time": 1689833149000,
        "value": 139.46,
        "_internal_originalTime": 1689833149000
    },
    {
        "time": 1689833151000,
        "value": 139.457,
        "_internal_originalTime": 1689833151000
    },
    {
        "time": 1689833152000,
        "value": 139.47,
        "_internal_originalTime": 1689833152000
    },
    {
        "time": 1689833154000,
        "value": 139.49,
        "_internal_originalTime": 1689833154000
    },
    {
        "time": 1689833155000,
        "value": 139.496,
        "_internal_originalTime": 1689833155000
    },
    {
        "time": 1689833156000,
        "value": 139.493,
        "_internal_originalTime": 1689833156000
    },
    {
        "time": 1689833157000,
        "value": 139.495,
        "_internal_originalTime": 1689833157000
    },
    {
        "time": 1689833158000,
        "value": 139.49,
        "_internal_originalTime": 1689833158000
    },
    {
        "time": 1689833160000,
        "value": 139.49,
        "_internal_originalTime": 1689833160000
    },
    {
        "time": 1689833161000,
        "value": 139.494,
        "_internal_originalTime": 1689833161000
    },
    {
        "time": 1689833163000,
        "value": 139.491,
        "_internal_originalTime": 1689833163000
    },
    {
        "time": 1689833164000,
        "value": 139.495,
        "_internal_originalTime": 1689833164000
    },
    {
        "time": 1689833165000,
        "value": 139.495,
        "_internal_originalTime": 1689833165000
    },
    {
        "time": 1689833167000,
        "value": 139.494,
        "_internal_originalTime": 1689833167000
    },
    {
        "time": 1689833169000,
        "value": 139.488,
        "_internal_originalTime": 1689833169000
    },
    {
        "time": 1689833170000,
        "value": 139.487,
        "_internal_originalTime": 1689833170000
    },
    {
        "time": 1689833171000,
        "value": 139.489,
        "_internal_originalTime": 1689833171000
    },
    {
        "time": 1689833172000,
        "value": 139.495,
        "_internal_originalTime": 1689833172000
    },
    {
        "time": 1689833174000,
        "value": 139.493,
        "_internal_originalTime": 1689833174000
    },
    {
        "time": 1689833176000,
        "value": 139.492,
        "_internal_originalTime": 1689833176000
    },
    {
        "time": 1689833178000,
        "value": 139.49,
        "_internal_originalTime": 1689833178000
    },
    {
        "time": 1689833179000,
        "value": 139.493,
        "_internal_originalTime": 1689833179000
    },
    {
        "time": 1689833180000,
        "value": 139.498,
        "_internal_originalTime": 1689833180000
    },
    {
        "time": 1689833181000,
        "value": 139.494,
        "_internal_originalTime": 1689833181000
    },
    {
        "time": 1689833182000,
        "value": 139.498,
        "_internal_originalTime": 1689833182000
    },
    {
        "time": 1689833185000,
        "value": 139.506,
        "_internal_originalTime": 1689833185000
    },
    {
        "time": 1689833186000,
        "value": 139.502,
        "_internal_originalTime": 1689833186000
    },
    {
        "time": 1689833188000,
        "value": 139.502,
        "_internal_originalTime": 1689833188000
    },
    {
        "time": 1689833189000,
        "value": 139.515,
        "_internal_originalTime": 1689833189000
    },
    {
        "time": 1689833190000,
        "value": 139.513,
        "_internal_originalTime": 1689833190000
    },
    {
        "time": 1689833191000,
        "value": 139.513,
        "_internal_originalTime": 1689833191000
    },
    {
        "time": 1689833192000,
        "value": 139.515,
        "_internal_originalTime": 1689833192000
    },
    {
        "time": 1689833194000,
        "value": 139.509,
        "_internal_originalTime": 1689833194000
    },
    {
        "time": 1689833196000,
        "value": 139.515,
        "_internal_originalTime": 1689833196000
    },
    {
        "time": 1689833199000,
        "value": 139.512,
        "_internal_originalTime": 1689833199000
    },
    {
        "time": 1689833200000,
        "value": 139.516,
        "_internal_originalTime": 1689833200000
    },
    {
        "time": 1689833201000,
        "value": 139.515,
        "_internal_originalTime": 1689833201000
    },
    {
        "time": 1689833203000,
        "value": 139.515,
        "_internal_originalTime": 1689833203000
    },
    {
        "time": 1689833204000,
        "value": 139.515,
        "_internal_originalTime": 1689833204000
    },
    {
        "time": 1689833205000,
        "value": 139.515,
        "_internal_originalTime": 1689833205000
    },
    {
        "time": 1689833206000,
        "value": 139.515,
        "_internal_originalTime": 1689833206000
    },
    {
        "time": 1689833207000,
        "value": 139.509,
        "_internal_originalTime": 1689833207000
    },
    {
        "time": 1689833209000,
        "value": 139.508,
        "_internal_originalTime": 1689833209000
    },
    {
        "time": 1689833211000,
        "value": 139.508,
        "_internal_originalTime": 1689833211000
    },
    {
        "time": 1689833212000,
        "value": 139.512,
        "_internal_originalTime": 1689833212000
    },
    {
        "time": 1689833213000,
        "value": 139.504,
        "_internal_originalTime": 1689833213000
    },
    {
        "time": 1689833214000,
        "value": 139.509,
        "_internal_originalTime": 1689833214000
    },
    {
        "time": 1689833215000,
        "value": 139.514,
        "_internal_originalTime": 1689833215000
    },
    {
        "time": 1689833216000,
        "value": 139.513,
        "_internal_originalTime": 1689833216000
    },
    {
        "time": 1689833217000,
        "value": 139.509,
        "_internal_originalTime": 1689833217000
    },
    {
        "time": 1689833219000,
        "value": 139.512,
        "_internal_originalTime": 1689833219000
    },
    {
        "time": 1689833220000,
        "value": 139.517,
        "_internal_originalTime": 1689833220000
    },
    {
        "time": 1689833221000,
        "value": 139.521,
        "_internal_originalTime": 1689833221000
    },
    {
        "time": 1689833222000,
        "value": 139.522,
        "_internal_originalTime": 1689833222000
    },
    {
        "time": 1689833223000,
        "value": 139.534,
        "_internal_originalTime": 1689833223000
    },
    {
        "time": 1689833225000,
        "value": 139.538,
        "_internal_originalTime": 1689833225000
    },
    {
        "time": 1689833226000,
        "value": 139.534,
        "_internal_originalTime": 1689833226000
    },
    {
        "time": 1689833229000,
        "value": 139.545,
        "_internal_originalTime": 1689833229000
    },
    {
        "time": 1689833230000,
        "value": 139.544,
        "_internal_originalTime": 1689833230000
    },
    {
        "time": 1689833231000,
        "value": 139.539,
        "_internal_originalTime": 1689833231000
    },
    {
        "time": 1689833232000,
        "value": 139.533,
        "_internal_originalTime": 1689833232000
    },
    {
        "time": 1689833233000,
        "value": 139.533,
        "_internal_originalTime": 1689833233000
    },
    {
        "time": 1689833235000,
        "value": 139.536,
        "_internal_originalTime": 1689833235000
    },
    {
        "time": 1689833236000,
        "value": 139.532,
        "_internal_originalTime": 1689833236000
    },
    {
        "time": 1689833238000,
        "value": 139.536,
        "_internal_originalTime": 1689833238000
    },
    {
        "time": 1689833239000,
        "value": 139.541,
        "_internal_originalTime": 1689833239000
    },
    {
        "time": 1689833241000,
        "value": 139.541,
        "_internal_originalTime": 1689833241000
    },
    {
        "time": 1689833242000,
        "value": 139.541,
        "_internal_originalTime": 1689833242000
    },
    {
        "time": 1689833243000,
        "value": 139.536,
        "_internal_originalTime": 1689833243000
    },
    {
        "time": 1689833245000,
        "value": 139.54,
        "_internal_originalTime": 1689833245000
    },
    {
        "time": 1689833247000,
        "value": 139.538,
        "_internal_originalTime": 1689833247000
    },
    {
        "time": 1689833249000,
        "value": 139.544,
        "_internal_originalTime": 1689833249000
    },
    {
        "time": 1689833251000,
        "value": 139.544,
        "_internal_originalTime": 1689833251000
    },
    {
        "time": 1689833253000,
        "value": 139.529,
        "_internal_originalTime": 1689833253000
    },
    {
        "time": 1689833254000,
        "value": 139.529,
        "_internal_originalTime": 1689833254000
    },
    {
        "time": 1689833255000,
        "value": 139.531,
        "_internal_originalTime": 1689833255000
    },
    {
        "time": 1689833257000,
        "value": 139.532,
        "_internal_originalTime": 1689833257000
    },
    {
        "time": 1689833258000,
        "value": 139.533,
        "_internal_originalTime": 1689833258000
    },
    {
        "time": 1689833259000,
        "value": 139.531,
        "_internal_originalTime": 1689833259000
    },
    {
        "time": 1689833261000,
        "value": 139.531,
        "_internal_originalTime": 1689833261000
    },
    {
        "time": 1689833265000,
        "value": 139.53,
        "_internal_originalTime": 1689833265000
    },
    {
        "time": 1689833266000,
        "value": 139.528,
        "_internal_originalTime": 1689833266000
    },
    {
        "time": 1689833267000,
        "value": 139.529,
        "_internal_originalTime": 1689833267000
    },
    {
        "time": 1689833271000,
        "value": 139.531,
        "_internal_originalTime": 1689833271000
    },
    {
        "time": 1689833272000,
        "value": 139.531,
        "_internal_originalTime": 1689833272000
    },
    {
        "time": 1689833273000,
        "value": 139.53,
        "_internal_originalTime": 1689833273000
    },
    {
        "time": 1689833275000,
        "value": 139.532,
        "_internal_originalTime": 1689833275000
    },
    {
        "time": 1689833277000,
        "value": 139.518,
        "_internal_originalTime": 1689833277000
    },
    {
        "time": 1689833279000,
        "value": 139.524,
        "_internal_originalTime": 1689833279000
    },
    {
        "time": 1689833280000,
        "value": 139.52,
        "_internal_originalTime": 1689833280000
    },
    {
        "time": 1689833282000,
        "value": 139.516,
        "_internal_originalTime": 1689833282000
    },
    {
        "time": 1689833283000,
        "value": 139.518,
        "_internal_originalTime": 1689833283000
    },
    {
        "time": 1689833285000,
        "value": 139.521,
        "_internal_originalTime": 1689833285000
    },
    {
        "time": 1689833286000,
        "value": 139.52,
        "_internal_originalTime": 1689833286000
    },
    {
        "time": 1689833287000,
        "value": 139.514,
        "_internal_originalTime": 1689833287000
    },
    {
        "time": 1689833288000,
        "value": 139.515,
        "_internal_originalTime": 1689833288000
    },
    {
        "time": 1689833290000,
        "value": 139.519,
        "_internal_originalTime": 1689833290000
    },
    {
        "time": 1689833291000,
        "value": 139.52,
        "_internal_originalTime": 1689833291000
    },
    {
        "time": 1689833292000,
        "value": 139.51,
        "_internal_originalTime": 1689833292000
    },
    {
        "time": 1689833294000,
        "value": 139.514,
        "_internal_originalTime": 1689833294000
    },
    {
        "time": 1689833295000,
        "value": 139.512,
        "_internal_originalTime": 1689833295000
    },
    {
        "time": 1689833297000,
        "value": 139.512,
        "_internal_originalTime": 1689833297000
    },
    {
        "time": 1689833298000,
        "value": 139.514,
        "_internal_originalTime": 1689833298000
    },
    {
        "time": 1689833299000,
        "value": 139.514,
        "_internal_originalTime": 1689833299000
    },
    {
        "time": 1689833301000,
        "value": 139.512,
        "_internal_originalTime": 1689833301000
    },
    {
        "time": 1689833302000,
        "value": 139.514,
        "_internal_originalTime": 1689833302000
    },
    {
        "time": 1689833303000,
        "value": 139.51,
        "_internal_originalTime": 1689833303000
    },
    {
        "time": 1689833304000,
        "value": 139.515,
        "_internal_originalTime": 1689833304000
    },
    {
        "time": 1689833305000,
        "value": 139.514,
        "_internal_originalTime": 1689833305000
    },
    {
        "time": 1689833307000,
        "value": 139.524,
        "_internal_originalTime": 1689833307000
    },
    {
        "time": 1689833308000,
        "value": 139.521,
        "_internal_originalTime": 1689833308000
    },
    {
        "time": 1689833310000,
        "value": 139.524,
        "_internal_originalTime": 1689833310000
    },
    {
        "time": 1689833312000,
        "value": 139.533,
        "_internal_originalTime": 1689833312000
    },
    {
        "time": 1689833314000,
        "value": 139.516,
        "_internal_originalTime": 1689833314000
    },
    {
        "time": 1689833316000,
        "value": 139.518,
        "_internal_originalTime": 1689833316000
    },
    {
        "time": 1689833318000,
        "value": 139.525,
        "_internal_originalTime": 1689833318000
    },
    {
        "time": 1689833320000,
        "value": 139.521,
        "_internal_originalTime": 1689833320000
    },
    {
        "time": 1689833321000,
        "value": 139.519,
        "_internal_originalTime": 1689833321000
    },
    {
        "time": 1689833323000,
        "value": 139.521,
        "_internal_originalTime": 1689833323000
    },
    {
        "time": 1689833324000,
        "value": 139.548,
        "_internal_originalTime": 1689833324000
    },
    {
        "time": 1689833325000,
        "value": 139.544,
        "_internal_originalTime": 1689833325000
    },
    {
        "time": 1689833326000,
        "value": 139.54,
        "_internal_originalTime": 1689833326000
    },
    {
        "time": 1689833328000,
        "value": 139.541,
        "_internal_originalTime": 1689833328000
    },
    {
        "time": 1689833329000,
        "value": 139.542,
        "_internal_originalTime": 1689833329000
    },
    {
        "time": 1689833330000,
        "value": 139.533,
        "_internal_originalTime": 1689833330000
    },
    {
        "time": 1689833331000,
        "value": 139.531,
        "_internal_originalTime": 1689833331000
    },
    {
        "time": 1689833332000,
        "value": 139.527,
        "_internal_originalTime": 1689833332000
    },
    {
        "time": 1689833334000,
        "value": 139.528,
        "_internal_originalTime": 1689833334000
    },
    {
        "time": 1689833335000,
        "value": 139.53,
        "_internal_originalTime": 1689833335000
    },
    {
        "time": 1689833337000,
        "value": 139.523,
        "_internal_originalTime": 1689833337000
    },
    {
        "time": 1689833338000,
        "value": 139.526,
        "_internal_originalTime": 1689833338000
    },
    {
        "time": 1689833339000,
        "value": 139.524,
        "_internal_originalTime": 1689833339000
    },
    {
        "time": 1689833340000,
        "value": 139.526,
        "_internal_originalTime": 1689833340000
    },
    {
        "time": 1689833341000,
        "value": 139.526,
        "_internal_originalTime": 1689833341000
    },
    {
        "time": 1689833342000,
        "value": 139.525,
        "_internal_originalTime": 1689833342000
    },
    {
        "time": 1689833343000,
        "value": 139.522,
        "_internal_originalTime": 1689833343000
    },
    {
        "time": 1689833344000,
        "value": 139.519,
        "_internal_originalTime": 1689833344000
    },
    {
        "time": 1689833345000,
        "value": 139.522,
        "_internal_originalTime": 1689833345000
    },
    {
        "time": 1689833347000,
        "value": 139.525,
        "_internal_originalTime": 1689833347000
    },
    {
        "time": 1689833349000,
        "value": 139.525,
        "_internal_originalTime": 1689833349000
    },
    {
        "time": 1689833350000,
        "value": 139.526,
        "_internal_originalTime": 1689833350000
    },
    {
        "time": 1689833351000,
        "value": 139.526,
        "_internal_originalTime": 1689833351000
    },
    {
        "time": 1689833352000,
        "value": 139.524,
        "_internal_originalTime": 1689833352000
    },
    {
        "time": 1689833353000,
        "value": 139.526,
        "_internal_originalTime": 1689833353000
    },
    {
        "time": 1689833355000,
        "value": 139.525,
        "_internal_originalTime": 1689833355000
    },
    {
        "time": 1689833356000,
        "value": 139.526,
        "_internal_originalTime": 1689833356000
    },
    {
        "time": 1689833357000,
        "value": 139.52,
        "_internal_originalTime": 1689833357000
    },
    {
        "time": 1689833359000,
        "value": 139.52,
        "_internal_originalTime": 1689833359000
    },
    {
        "time": 1689833361000,
        "value": 139.525,
        "_internal_originalTime": 1689833361000
    },
    {
        "time": 1689833362000,
        "value": 139.524,
        "_internal_originalTime": 1689833362000
    },
    {
        "time": 1689833363000,
        "value": 139.524,
        "_internal_originalTime": 1689833363000
    },
    {
        "time": 1689833364000,
        "value": 139.517,
        "_internal_originalTime": 1689833364000
    },
    {
        "time": 1689833366000,
        "value": 139.518,
        "_internal_originalTime": 1689833366000
    },
    {
        "time": 1689833367000,
        "value": 139.508,
        "_internal_originalTime": 1689833367000
    },
    {
        "time": 1689833368000,
        "value": 139.513,
        "_internal_originalTime": 1689833368000
    },
    {
        "time": 1689833371000,
        "value": 139.51,
        "_internal_originalTime": 1689833371000
    },
    {
        "time": 1689833372000,
        "value": 139.514,
        "_internal_originalTime": 1689833372000
    },
    {
        "time": 1689833373000,
        "value": 139.514,
        "_internal_originalTime": 1689833373000
    },
    {
        "time": 1689833374000,
        "value": 139.512,
        "_internal_originalTime": 1689833374000
    },
    {
        "time": 1689833376000,
        "value": 139.51,
        "_internal_originalTime": 1689833376000
    },
    {
        "time": 1689833378000,
        "value": 139.509,
        "_internal_originalTime": 1689833378000
    },
    {
        "time": 1689833380000,
        "value": 139.5,
        "_internal_originalTime": 1689833380000
    },
    {
        "time": 1689833382000,
        "value": 139.508,
        "_internal_originalTime": 1689833382000
    },
    {
        "time": 1689833383000,
        "value": 139.513,
        "_internal_originalTime": 1689833383000
    },
    {
        "time": 1689833384000,
        "value": 139.512,
        "_internal_originalTime": 1689833384000
    },
    {
        "time": 1689833385000,
        "value": 139.508,
        "_internal_originalTime": 1689833385000
    },
    {
        "time": 1689833386000,
        "value": 139.515,
        "_internal_originalTime": 1689833386000
    },
    {
        "time": 1689833387000,
        "value": 139.512,
        "_internal_originalTime": 1689833387000
    },
    {
        "time": 1689833390000,
        "value": 139.518,
        "_internal_originalTime": 1689833390000
    },
    {
        "time": 1689833392000,
        "value": 139.51,
        "_internal_originalTime": 1689833392000
    },
    {
        "time": 1689833393000,
        "value": 139.508,
        "_internal_originalTime": 1689833393000
    },
    {
        "time": 1689833394000,
        "value": 139.509,
        "_internal_originalTime": 1689833394000
    },
    {
        "time": 1689833396000,
        "value": 139.512,
        "_internal_originalTime": 1689833396000
    },
    {
        "time": 1689833398000,
        "value": 139.509,
        "_internal_originalTime": 1689833398000
    },
    {
        "time": 1689833400000,
        "value": 139.509,
        "_internal_originalTime": 1689833400000
    },
    {
        "time": 1689833401000,
        "value": 139.512,
        "_internal_originalTime": 1689833401000
    },
    {
        "time": 1689833402000,
        "value": 139.512,
        "_internal_originalTime": 1689833402000
    },
    {
        "time": 1689833404000,
        "value": 139.515,
        "_internal_originalTime": 1689833404000
    },
    {
        "time": 1689833405000,
        "value": 139.514,
        "_internal_originalTime": 1689833405000
    },
    {
        "time": 1689833406000,
        "value": 139.518,
        "_internal_originalTime": 1689833406000
    },
    {
        "time": 1689833407000,
        "value": 139.512,
        "_internal_originalTime": 1689833407000
    },
    {
        "time": 1689833408000,
        "value": 139.514,
        "_internal_originalTime": 1689833408000
    },
    {
        "time": 1689833413000,
        "value": 139.509,
        "_internal_originalTime": 1689833413000
    },
    {
        "time": 1689833414000,
        "value": 139.499,
        "_internal_originalTime": 1689833414000
    },
    {
        "time": 1689833416000,
        "value": 139.493,
        "_internal_originalTime": 1689833416000
    },
    {
        "time": 1689833417000,
        "value": 139.493,
        "_internal_originalTime": 1689833417000
    },
    {
        "time": 1689833418000,
        "value": 139.498,
        "_internal_originalTime": 1689833418000
    },
    {
        "time": 1689833419000,
        "value": 139.495,
        "_internal_originalTime": 1689833419000
    },
    {
        "time": 1689833420000,
        "value": 139.494,
        "_internal_originalTime": 1689833420000
    },
    {
        "time": 1689833422000,
        "value": 139.497,
        "_internal_originalTime": 1689833422000
    },
    {
        "time": 1689833424000,
        "value": 139.492,
        "_internal_originalTime": 1689833424000
    },
    {
        "time": 1689833426000,
        "value": 139.497,
        "_internal_originalTime": 1689833426000
    },
    {
        "time": 1689833428000,
        "value": 139.495,
        "_internal_originalTime": 1689833428000
    },
    {
        "time": 1689833429000,
        "value": 139.489,
        "_internal_originalTime": 1689833429000
    },
    {
        "time": 1689833431000,
        "value": 139.493,
        "_internal_originalTime": 1689833431000
    },
    {
        "time": 1689833432000,
        "value": 139.488,
        "_internal_originalTime": 1689833432000
    },
    {
        "time": 1689833433000,
        "value": 139.486,
        "_internal_originalTime": 1689833433000
    },
    {
        "time": 1689833434000,
        "value": 139.478,
        "_internal_originalTime": 1689833434000
    },
    {
        "time": 1689833435000,
        "value": 139.483,
        "_internal_originalTime": 1689833435000
    },
    {
        "time": 1689833436000,
        "value": 139.477,
        "_internal_originalTime": 1689833436000
    },
    {
        "time": 1689833438000,
        "value": 139.483,
        "_internal_originalTime": 1689833438000
    },
    {
        "time": 1689833441000,
        "value": 139.482,
        "_internal_originalTime": 1689833441000
    },
    {
        "time": 1689833442000,
        "value": 139.48,
        "_internal_originalTime": 1689833442000
    },
    {
        "time": 1689833443000,
        "value": 139.48,
        "_internal_originalTime": 1689833443000
    },
    {
        "time": 1689833444000,
        "value": 139.484,
        "_internal_originalTime": 1689833444000
    },
    {
        "time": 1689833446000,
        "value": 139.484,
        "_internal_originalTime": 1689833446000
    },
    {
        "time": 1689833448000,
        "value": 139.486,
        "_internal_originalTime": 1689833448000
    },
    {
        "time": 1689833449000,
        "value": 139.485,
        "_internal_originalTime": 1689833449000
    },
    {
        "time": 1689833451000,
        "value": 139.48,
        "_internal_originalTime": 1689833451000
    },
    {
        "time": 1689833452000,
        "value": 139.481,
        "_internal_originalTime": 1689833452000
    },
    {
        "time": 1689833454000,
        "value": 139.48,
        "_internal_originalTime": 1689833454000
    },
    {
        "time": 1689833456000,
        "value": 139.483,
        "_internal_originalTime": 1689833456000
    },
    {
        "time": 1689833457000,
        "value": 139.484,
        "_internal_originalTime": 1689833457000
    },
    {
        "time": 1689833459000,
        "value": 139.484,
        "_internal_originalTime": 1689833459000
    },
    {
        "time": 1689833460000,
        "value": 139.484,
        "_internal_originalTime": 1689833460000
    },
    {
        "time": 1689833462000,
        "value": 139.487,
        "_internal_originalTime": 1689833462000
    },
    {
        "time": 1689833464000,
        "value": 139.5,
        "_internal_originalTime": 1689833464000
    },
    {
        "time": 1689833466000,
        "value": 139.502,
        "_internal_originalTime": 1689833466000
    },
    {
        "time": 1689833467000,
        "value": 139.507,
        "_internal_originalTime": 1689833467000
    },
    {
        "time": 1689833468000,
        "value": 139.504,
        "_internal_originalTime": 1689833468000
    },
    {
        "time": 1689833469000,
        "value": 139.499,
        "_internal_originalTime": 1689833469000
    },
    {
        "time": 1689833471000,
        "value": 139.5,
        "_internal_originalTime": 1689833471000
    },
    {
        "time": 1689833473000,
        "value": 139.504,
        "_internal_originalTime": 1689833473000
    },
    {
        "time": 1689833475000,
        "value": 139.502,
        "_internal_originalTime": 1689833475000
    },
    {
        "time": 1689833477000,
        "value": 139.512,
        "_internal_originalTime": 1689833477000
    },
    {
        "time": 1689833478000,
        "value": 139.5,
        "_internal_originalTime": 1689833478000
    },
    {
        "time": 1689833479000,
        "value": 139.504,
        "_internal_originalTime": 1689833479000
    },
    {
        "time": 1689833480000,
        "value": 139.502,
        "_internal_originalTime": 1689833480000
    },
    {
        "time": 1689833481000,
        "value": 139.502,
        "_internal_originalTime": 1689833481000
    },
    {
        "time": 1689833482000,
        "value": 139.502,
        "_internal_originalTime": 1689833482000
    },
    {
        "time": 1689833484000,
        "value": 139.502,
        "_internal_originalTime": 1689833484000
    },
    {
        "time": 1689833485000,
        "value": 139.503,
        "_internal_originalTime": 1689833485000
    },
    {
        "time": 1689833486000,
        "value": 139.504,
        "_internal_originalTime": 1689833486000
    },
    {
        "time": 1689833488000,
        "value": 139.51,
        "_internal_originalTime": 1689833488000
    },
    {
        "time": 1689833489000,
        "value": 139.511,
        "_internal_originalTime": 1689833489000
    },
    {
        "time": 1689833490000,
        "value": 139.507,
        "_internal_originalTime": 1689833490000
    },
    {
        "time": 1689833491000,
        "value": 139.509,
        "_internal_originalTime": 1689833491000
    },
    {
        "time": 1689833492000,
        "value": 139.509,
        "_internal_originalTime": 1689833492000
    },
    {
        "time": 1689833495000,
        "value": 139.519,
        "_internal_originalTime": 1689833495000
    },
    {
        "time": 1689833496000,
        "value": 139.52,
        "_internal_originalTime": 1689833496000
    },
    {
        "time": 1689833497000,
        "value": 139.517,
        "_internal_originalTime": 1689833497000
    },
    {
        "time": 1689833499000,
        "value": 139.514,
        "_internal_originalTime": 1689833499000
    },
    {
        "time": 1689833500000,
        "value": 139.515,
        "_internal_originalTime": 1689833500000
    },
    {
        "time": 1689833501000,
        "value": 139.509,
        "_internal_originalTime": 1689833501000
    },
    {
        "time": 1689833502000,
        "value": 139.507,
        "_internal_originalTime": 1689833502000
    },
    {
        "time": 1689833504000,
        "value": 139.515,
        "_internal_originalTime": 1689833504000
    },
    {
        "time": 1689833505000,
        "value": 139.511,
        "_internal_originalTime": 1689833505000
    },
    {
        "time": 1689833507000,
        "value": 139.517,
        "_internal_originalTime": 1689833507000
    },
    {
        "time": 1689833508000,
        "value": 139.521,
        "_internal_originalTime": 1689833508000
    },
    {
        "time": 1689833509000,
        "value": 139.517,
        "_internal_originalTime": 1689833509000
    },
    {
        "time": 1689833511000,
        "value": 139.52,
        "_internal_originalTime": 1689833511000
    },
    {
        "time": 1689833512000,
        "value": 139.519,
        "_internal_originalTime": 1689833512000
    },
    {
        "time": 1689833513000,
        "value": 139.52,
        "_internal_originalTime": 1689833513000
    },
    {
        "time": 1689833514000,
        "value": 139.52,
        "_internal_originalTime": 1689833514000
    },
    {
        "time": 1689833516000,
        "value": 139.518,
        "_internal_originalTime": 1689833516000
    },
    {
        "time": 1689833518000,
        "value": 139.537,
        "_internal_originalTime": 1689833518000
    },
    {
        "time": 1689833520000,
        "value": 139.532,
        "_internal_originalTime": 1689833520000
    },
    {
        "time": 1689833521000,
        "value": 139.532,
        "_internal_originalTime": 1689833521000
    },
    {
        "time": 1689833523000,
        "value": 139.531,
        "_internal_originalTime": 1689833523000
    },
    {
        "time": 1689833524000,
        "value": 139.531,
        "_internal_originalTime": 1689833524000
    },
    {
        "time": 1689833525000,
        "value": 139.531,
        "_internal_originalTime": 1689833525000
    },
    {
        "time": 1689833526000,
        "value": 139.529,
        "_internal_originalTime": 1689833526000
    },
    {
        "time": 1689833527000,
        "value": 139.528,
        "_internal_originalTime": 1689833527000
    },
    {
        "time": 1689833528000,
        "value": 139.539,
        "_internal_originalTime": 1689833528000
    },
    {
        "time": 1689833530000,
        "value": 139.54,
        "_internal_originalTime": 1689833530000
    },
    {
        "time": 1689833531000,
        "value": 139.534,
        "_internal_originalTime": 1689833531000
    },
    {
        "time": 1689833533000,
        "value": 139.541,
        "_internal_originalTime": 1689833533000
    },
    {
        "time": 1689833535000,
        "value": 139.537,
        "_internal_originalTime": 1689833535000
    },
    {
        "time": 1689833536000,
        "value": 139.536,
        "_internal_originalTime": 1689833536000
    },
    {
        "time": 1689833537000,
        "value": 139.518,
        "_internal_originalTime": 1689833537000
    },
    {
        "time": 1689833538000,
        "value": 139.521,
        "_internal_originalTime": 1689833538000
    },
    {
        "time": 1689833539000,
        "value": 139.523,
        "_internal_originalTime": 1689833539000
    },
    {
        "time": 1689833541000,
        "value": 139.517,
        "_internal_originalTime": 1689833541000
    },
    {
        "time": 1689833542000,
        "value": 139.511,
        "_internal_originalTime": 1689833542000
    },
    {
        "time": 1689833544000,
        "value": 139.511,
        "_internal_originalTime": 1689833544000
    },
    {
        "time": 1689833545000,
        "value": 139.51,
        "_internal_originalTime": 1689833545000
    },
    {
        "time": 1689833547000,
        "value": 139.511,
        "_internal_originalTime": 1689833547000
    },
    {
        "time": 1689833548000,
        "value": 139.507,
        "_internal_originalTime": 1689833548000
    },
    {
        "time": 1689833549000,
        "value": 139.506,
        "_internal_originalTime": 1689833549000
    },
    {
        "time": 1689833550000,
        "value": 139.512,
        "_internal_originalTime": 1689833550000
    },
    {
        "time": 1689833552000,
        "value": 139.508,
        "_internal_originalTime": 1689833552000
    },
    {
        "time": 1689833554000,
        "value": 139.508,
        "_internal_originalTime": 1689833554000
    },
    {
        "time": 1689833555000,
        "value": 139.515,
        "_internal_originalTime": 1689833555000
    },
    {
        "time": 1689833557000,
        "value": 139.508,
        "_internal_originalTime": 1689833557000
    },
    {
        "time": 1689833558000,
        "value": 139.516,
        "_internal_originalTime": 1689833558000
    },
    {
        "time": 1689833561000,
        "value": 139.522,
        "_internal_originalTime": 1689833561000
    },
    {
        "time": 1689833562000,
        "value": 139.525,
        "_internal_originalTime": 1689833562000
    },
    {
        "time": 1689833563000,
        "value": 139.523,
        "_internal_originalTime": 1689833563000
    },
    {
        "time": 1689833564000,
        "value": 139.519,
        "_internal_originalTime": 1689833564000
    },
    {
        "time": 1689833565000,
        "value": 139.523,
        "_internal_originalTime": 1689833565000
    },
    {
        "time": 1689833566000,
        "value": 139.525,
        "_internal_originalTime": 1689833566000
    },
    {
        "time": 1689833568000,
        "value": 139.522,
        "_internal_originalTime": 1689833568000
    },
    {
        "time": 1689833569000,
        "value": 139.519,
        "_internal_originalTime": 1689833569000
    },
    {
        "time": 1689833571000,
        "value": 139.518,
        "_internal_originalTime": 1689833571000
    },
    {
        "time": 1689833572000,
        "value": 139.519,
        "_internal_originalTime": 1689833572000
    },
    {
        "time": 1689833573000,
        "value": 139.518,
        "_internal_originalTime": 1689833573000
    },
    {
        "time": 1689833574000,
        "value": 139.521,
        "_internal_originalTime": 1689833574000
    },
    {
        "time": 1689833576000,
        "value": 139.516,
        "_internal_originalTime": 1689833576000
    },
    {
        "time": 1689833578000,
        "value": 139.518,
        "_internal_originalTime": 1689833578000
    },
    {
        "time": 1689833580000,
        "value": 139.519,
        "_internal_originalTime": 1689833580000
    },
    {
        "time": 1689833581000,
        "value": 139.524,
        "_internal_originalTime": 1689833581000
    },
    {
        "time": 1689833582000,
        "value": 139.523,
        "_internal_originalTime": 1689833582000
    },
    {
        "time": 1689833583000,
        "value": 139.522,
        "_internal_originalTime": 1689833583000
    },
    {
        "time": 1689833585000,
        "value": 139.517,
        "_internal_originalTime": 1689833585000
    },
    {
        "time": 1689833589000,
        "value": 139.516,
        "_internal_originalTime": 1689833589000
    },
    {
        "time": 1689833590000,
        "value": 139.516,
        "_internal_originalTime": 1689833590000
    },
    {
        "time": 1689833591000,
        "value": 139.504,
        "_internal_originalTime": 1689833591000
    },
    {
        "time": 1689833593000,
        "value": 139.513,
        "_internal_originalTime": 1689833593000
    },
    {
        "time": 1689833594000,
        "value": 139.504,
        "_internal_originalTime": 1689833594000
    },
    {
        "time": 1689833595000,
        "value": 139.513,
        "_internal_originalTime": 1689833595000
    },
    {
        "time": 1689833596000,
        "value": 139.513,
        "_internal_originalTime": 1689833596000
    },
    {
        "time": 1689833598000,
        "value": 139.515,
        "_internal_originalTime": 1689833598000
    },
    {
        "time": 1689833600000,
        "value": 139.51,
        "_internal_originalTime": 1689833600000
    },
    {
        "time": 1689833601000,
        "value": 139.512,
        "_internal_originalTime": 1689833601000
    },
    {
        "time": 1689833602000,
        "value": 139.51,
        "_internal_originalTime": 1689833602000
    },
    {
        "time": 1689833604000,
        "value": 139.507,
        "_internal_originalTime": 1689833604000
    },
    {
        "time": 1689833607000,
        "value": 139.506,
        "_internal_originalTime": 1689833607000
    },
    {
        "time": 1689833608000,
        "value": 139.506,
        "_internal_originalTime": 1689833608000
    },
    {
        "time": 1689833609000,
        "value": 139.505,
        "_internal_originalTime": 1689833609000
    },
    {
        "time": 1689833610000,
        "value": 139.503,
        "_internal_originalTime": 1689833610000
    },
    {
        "time": 1689833611000,
        "value": 139.504,
        "_internal_originalTime": 1689833611000
    },
    {
        "time": 1689833612000,
        "value": 139.502,
        "_internal_originalTime": 1689833612000
    },
    {
        "time": 1689833613000,
        "value": 139.503,
        "_internal_originalTime": 1689833613000
    },
    {
        "time": 1689833614000,
        "value": 139.495,
        "_internal_originalTime": 1689833614000
    },
    {
        "time": 1689833616000,
        "value": 139.499,
        "_internal_originalTime": 1689833616000
    },
    {
        "time": 1689833618000,
        "value": 139.494,
        "_internal_originalTime": 1689833618000
    },
    {
        "time": 1689833619000,
        "value": 139.497,
        "_internal_originalTime": 1689833619000
    },
    {
        "time": 1689833620000,
        "value": 139.492,
        "_internal_originalTime": 1689833620000
    },
    {
        "time": 1689833622000,
        "value": 139.483,
        "_internal_originalTime": 1689833622000
    },
    {
        "time": 1689833623000,
        "value": 139.484,
        "_internal_originalTime": 1689833623000
    },
    {
        "time": 1689833624000,
        "value": 139.479,
        "_internal_originalTime": 1689833624000
    },
    {
        "time": 1689833625000,
        "value": 139.478,
        "_internal_originalTime": 1689833625000
    },
    {
        "time": 1689833627000,
        "value": 139.479,
        "_internal_originalTime": 1689833627000
    },
    {
        "time": 1689833628000,
        "value": 139.482,
        "_internal_originalTime": 1689833628000
    },
    {
        "time": 1689833629000,
        "value": 139.486,
        "_internal_originalTime": 1689833629000
    },
    {
        "time": 1689833630000,
        "value": 139.481,
        "_internal_originalTime": 1689833630000
    },
    {
        "time": 1689833632000,
        "value": 139.485,
        "_internal_originalTime": 1689833632000
    },
    {
        "time": 1689833633000,
        "value": 139.476,
        "_internal_originalTime": 1689833633000
    },
    {
        "time": 1689833634000,
        "value": 139.472,
        "_internal_originalTime": 1689833634000
    },
    {
        "time": 1689833635000,
        "value": 139.473,
        "_internal_originalTime": 1689833635000
    },
    {
        "time": 1689833637000,
        "value": 139.477,
        "_internal_originalTime": 1689833637000
    },
    {
        "time": 1689833638000,
        "value": 139.473,
        "_internal_originalTime": 1689833638000
    },
    {
        "time": 1689833640000,
        "value": 139.475,
        "_internal_originalTime": 1689833640000
    },
    {
        "time": 1689833641000,
        "value": 139.473,
        "_internal_originalTime": 1689833641000
    },
    {
        "time": 1689833643000,
        "value": 139.473,
        "_internal_originalTime": 1689833643000
    },
    {
        "time": 1689833645000,
        "value": 139.473,
        "_internal_originalTime": 1689833645000
    },
    {
        "time": 1689833646000,
        "value": 139.467,
        "_internal_originalTime": 1689833646000
    },
    {
        "time": 1689833647000,
        "value": 139.469,
        "_internal_originalTime": 1689833647000
    },
    {
        "time": 1689833648000,
        "value": 139.469,
        "_internal_originalTime": 1689833648000
    },
    {
        "time": 1689833649000,
        "value": 139.474,
        "_internal_originalTime": 1689833649000
    },
    {
        "time": 1689833651000,
        "value": 139.474,
        "_internal_originalTime": 1689833651000
    },
    {
        "time": 1689833652000,
        "value": 139.473,
        "_internal_originalTime": 1689833652000
    },
    {
        "time": 1689833653000,
        "value": 139.468,
        "_internal_originalTime": 1689833653000
    },
    {
        "time": 1689833654000,
        "value": 139.475,
        "_internal_originalTime": 1689833654000
    },
    {
        "time": 1689833655000,
        "value": 139.47,
        "_internal_originalTime": 1689833655000
    },
    {
        "time": 1689833658000,
        "value": 139.476,
        "_internal_originalTime": 1689833658000
    },
    {
        "time": 1689833659000,
        "value": 139.476,
        "_internal_originalTime": 1689833659000
    },
    {
        "time": 1689833660000,
        "value": 139.471,
        "_internal_originalTime": 1689833660000
    },
    {
        "time": 1689833661000,
        "value": 139.476,
        "_internal_originalTime": 1689833661000
    },
    {
        "time": 1689833662000,
        "value": 139.483,
        "_internal_originalTime": 1689833662000
    },
    {
        "time": 1689833664000,
        "value": 139.48,
        "_internal_originalTime": 1689833664000
    },
    {
        "time": 1689833666000,
        "value": 139.487,
        "_internal_originalTime": 1689833666000
    },
    {
        "time": 1689833667000,
        "value": 139.486,
        "_internal_originalTime": 1689833667000
    },
    {
        "time": 1689833668000,
        "value": 139.479,
        "_internal_originalTime": 1689833668000
    },
    {
        "time": 1689833669000,
        "value": 139.478,
        "_internal_originalTime": 1689833669000
    },
    {
        "time": 1689833670000,
        "value": 139.477,
        "_internal_originalTime": 1689833670000
    },
    {
        "time": 1689833672000,
        "value": 139.474,
        "_internal_originalTime": 1689833672000
    },
    {
        "time": 1689833673000,
        "value": 139.464,
        "_internal_originalTime": 1689833673000
    },
    {
        "time": 1689833674000,
        "value": 139.46,
        "_internal_originalTime": 1689833674000
    },
    {
        "time": 1689833679000,
        "value": 139.463,
        "_internal_originalTime": 1689833679000
    },
    {
        "time": 1689833681000,
        "value": 139.463,
        "_internal_originalTime": 1689833681000
    },
    {
        "time": 1689833683000,
        "value": 139.464,
        "_internal_originalTime": 1689833683000
    },
    {
        "time": 1689833686000,
        "value": 139.464,
        "_internal_originalTime": 1689833686000
    },
    {
        "time": 1689833687000,
        "value": 139.468,
        "_internal_originalTime": 1689833687000
    },
    {
        "time": 1689833688000,
        "value": 139.467,
        "_internal_originalTime": 1689833688000
    },
    {
        "time": 1689833689000,
        "value": 139.462,
        "_internal_originalTime": 1689833689000
    },
    {
        "time": 1689833690000,
        "value": 139.463,
        "_internal_originalTime": 1689833690000
    },
    {
        "time": 1689833691000,
        "value": 139.467,
        "_internal_originalTime": 1689833691000
    },
    {
        "time": 1689833696000,
        "value": 139.462,
        "_internal_originalTime": 1689833696000
    },
    {
        "time": 1689833697000,
        "value": 139.467,
        "_internal_originalTime": 1689833697000
    },
    {
        "time": 1689833699000,
        "value": 139.462,
        "_internal_originalTime": 1689833699000
    },
    {
        "time": 1689833700000,
        "value": 139.473,
        "_internal_originalTime": 1689833700000
    },
    {
        "time": 1689833701000,
        "value": 139.476,
        "_internal_originalTime": 1689833701000
    },
    {
        "time": 1689833702000,
        "value": 139.473,
        "_internal_originalTime": 1689833702000
    },
    {
        "time": 1689833703000,
        "value": 139.471,
        "_internal_originalTime": 1689833703000
    },
    {
        "time": 1689833704000,
        "value": 139.476,
        "_internal_originalTime": 1689833704000
    },
    {
        "time": 1689833705000,
        "value": 139.483,
        "_internal_originalTime": 1689833705000
    },
    {
        "time": 1689833707000,
        "value": 139.479,
        "_internal_originalTime": 1689833707000
    },
    {
        "time": 1689833708000,
        "value": 139.483,
        "_internal_originalTime": 1689833708000
    },
    {
        "time": 1689833709000,
        "value": 139.486,
        "_internal_originalTime": 1689833709000
    },
    {
        "time": 1689833710000,
        "value": 139.486,
        "_internal_originalTime": 1689833710000
    },
    {
        "time": 1689833711000,
        "value": 139.489,
        "_internal_originalTime": 1689833711000
    },
    {
        "time": 1689833712000,
        "value": 139.489,
        "_internal_originalTime": 1689833712000
    },
    {
        "time": 1689833713000,
        "value": 139.484,
        "_internal_originalTime": 1689833713000
    },
    {
        "time": 1689833715000,
        "value": 139.487,
        "_internal_originalTime": 1689833715000
    },
    {
        "time": 1689833716000,
        "value": 139.492,
        "_internal_originalTime": 1689833716000
    },
    {
        "time": 1689833718000,
        "value": 139.488,
        "_internal_originalTime": 1689833718000
    },
    {
        "time": 1689833720000,
        "value": 139.492,
        "_internal_originalTime": 1689833720000
    },
    {
        "time": 1689833721000,
        "value": 139.487,
        "_internal_originalTime": 1689833721000
    },
    {
        "time": 1689833722000,
        "value": 139.482,
        "_internal_originalTime": 1689833722000
    },
    {
        "time": 1689833723000,
        "value": 139.494,
        "_internal_originalTime": 1689833723000
    },
    {
        "time": 1689833726000,
        "value": 139.488,
        "_internal_originalTime": 1689833726000
    },
    {
        "time": 1689833727000,
        "value": 139.497,
        "_internal_originalTime": 1689833727000
    },
    {
        "time": 1689833728000,
        "value": 139.503,
        "_internal_originalTime": 1689833728000
    },
    {
        "time": 1689833730000,
        "value": 139.506,
        "_internal_originalTime": 1689833730000
    },
    {
        "time": 1689833731000,
        "value": 139.512,
        "_internal_originalTime": 1689833731000
    },
    {
        "time": 1689833732000,
        "value": 139.509,
        "_internal_originalTime": 1689833732000
    },
    {
        "time": 1689833734000,
        "value": 139.515,
        "_internal_originalTime": 1689833734000
    },
    {
        "time": 1689833735000,
        "value": 139.511,
        "_internal_originalTime": 1689833735000
    },
    {
        "time": 1689833736000,
        "value": 139.51,
        "_internal_originalTime": 1689833736000
    },
    {
        "time": 1689833737000,
        "value": 139.51,
        "_internal_originalTime": 1689833737000
    },
    {
        "time": 1689833738000,
        "value": 139.509,
        "_internal_originalTime": 1689833738000
    },
    {
        "time": 1689833741000,
        "value": 139.514,
        "_internal_originalTime": 1689833741000
    },
    {
        "time": 1689833743000,
        "value": 139.514,
        "_internal_originalTime": 1689833743000
    },
    {
        "time": 1689833745000,
        "value": 139.515,
        "_internal_originalTime": 1689833745000
    },
    {
        "time": 1689833746000,
        "value": 139.514,
        "_internal_originalTime": 1689833746000
    },
    {
        "time": 1689833748000,
        "value": 139.511,
        "_internal_originalTime": 1689833748000
    },
    {
        "time": 1689833750000,
        "value": 139.51,
        "_internal_originalTime": 1689833750000
    },
    {
        "time": 1689833751000,
        "value": 139.51,
        "_internal_originalTime": 1689833751000
    },
    {
        "time": 1689833753000,
        "value": 139.515,
        "_internal_originalTime": 1689833753000
    },
    {
        "time": 1689833755000,
        "value": 139.515,
        "_internal_originalTime": 1689833755000
    },
    {
        "time": 1689833757000,
        "value": 139.515,
        "_internal_originalTime": 1689833757000
    },
    {
        "time": 1689833758000,
        "value": 139.522,
        "_internal_originalTime": 1689833758000
    },
    {
        "time": 1689833760000,
        "value": 139.524,
        "_internal_originalTime": 1689833760000
    },
    {
        "time": 1689833761000,
        "value": 139.518,
        "_internal_originalTime": 1689833761000
    },
    {
        "time": 1689833763000,
        "value": 139.519,
        "_internal_originalTime": 1689833763000
    },
    {
        "time": 1689833764000,
        "value": 139.521,
        "_internal_originalTime": 1689833764000
    },
    {
        "time": 1689833766000,
        "value": 139.528,
        "_internal_originalTime": 1689833766000
    },
    {
        "time": 1689833767000,
        "value": 139.524,
        "_internal_originalTime": 1689833767000
    },
    {
        "time": 1689833768000,
        "value": 139.523,
        "_internal_originalTime": 1689833768000
    },
    {
        "time": 1689833771000,
        "value": 139.52,
        "_internal_originalTime": 1689833771000
    },
    {
        "time": 1689833772000,
        "value": 139.523,
        "_internal_originalTime": 1689833772000
    },
    {
        "time": 1689833773000,
        "value": 139.524,
        "_internal_originalTime": 1689833773000
    },
    {
        "time": 1689833774000,
        "value": 139.519,
        "_internal_originalTime": 1689833774000
    },
    {
        "time": 1689833776000,
        "value": 139.519,
        "_internal_originalTime": 1689833776000
    },
    {
        "time": 1689833777000,
        "value": 139.518,
        "_internal_originalTime": 1689833777000
    },
    {
        "time": 1689833778000,
        "value": 139.524,
        "_internal_originalTime": 1689833778000
    },
    {
        "time": 1689833779000,
        "value": 139.512,
        "_internal_originalTime": 1689833779000
    },
    {
        "time": 1689833780000,
        "value": 139.513,
        "_internal_originalTime": 1689833780000
    },
    {
        "time": 1689833782000,
        "value": 139.521,
        "_internal_originalTime": 1689833782000
    },
    {
        "time": 1689833784000,
        "value": 139.518,
        "_internal_originalTime": 1689833784000
    },
    {
        "time": 1689833785000,
        "value": 139.519,
        "_internal_originalTime": 1689833785000
    },
    {
        "time": 1689833788000,
        "value": 139.522,
        "_internal_originalTime": 1689833788000
    },
    {
        "time": 1689833790000,
        "value": 139.519,
        "_internal_originalTime": 1689833790000
    },
    {
        "time": 1689833791000,
        "value": 139.523,
        "_internal_originalTime": 1689833791000
    },
    {
        "time": 1689833792000,
        "value": 139.519,
        "_internal_originalTime": 1689833792000
    },
    {
        "time": 1689833794000,
        "value": 139.521,
        "_internal_originalTime": 1689833794000
    },
    {
        "time": 1689833795000,
        "value": 139.521,
        "_internal_originalTime": 1689833795000
    },
    {
        "time": 1689833796000,
        "value": 139.522,
        "_internal_originalTime": 1689833796000
    },
    {
        "time": 1689833797000,
        "value": 139.522,
        "_internal_originalTime": 1689833797000
    },
    {
        "time": 1689833798000,
        "value": 139.519,
        "_internal_originalTime": 1689833798000
    },
    {
        "time": 1689833801000,
        "value": 139.519,
        "_internal_originalTime": 1689833801000
    },
    {
        "time": 1689833802000,
        "value": 139.511,
        "_internal_originalTime": 1689833802000
    },
    {
        "time": 1689833803000,
        "value": 139.508,
        "_internal_originalTime": 1689833803000
    },
    {
        "time": 1689833804000,
        "value": 139.508,
        "_internal_originalTime": 1689833804000
    },
    {
        "time": 1689833805000,
        "value": 139.507,
        "_internal_originalTime": 1689833805000
    },
    {
        "time": 1689833807000,
        "value": 139.51,
        "_internal_originalTime": 1689833807000
    },
    {
        "time": 1689833809000,
        "value": 139.512,
        "_internal_originalTime": 1689833809000
    },
    {
        "time": 1689833810000,
        "value": 139.512,
        "_internal_originalTime": 1689833810000
    },
    {
        "time": 1689833811000,
        "value": 139.517,
        "_internal_originalTime": 1689833811000
    },
    {
        "time": 1689833814000,
        "value": 139.518,
        "_internal_originalTime": 1689833814000
    },
    {
        "time": 1689833815000,
        "value": 139.525,
        "_internal_originalTime": 1689833815000
    },
    {
        "time": 1689833816000,
        "value": 139.531,
        "_internal_originalTime": 1689833816000
    },
    {
        "time": 1689833817000,
        "value": 139.528,
        "_internal_originalTime": 1689833817000
    },
    {
        "time": 1689833819000,
        "value": 139.532,
        "_internal_originalTime": 1689833819000
    },
    {
        "time": 1689833821000,
        "value": 139.527,
        "_internal_originalTime": 1689833821000
    },
    {
        "time": 1689833822000,
        "value": 139.526,
        "_internal_originalTime": 1689833822000
    },
    {
        "time": 1689833823000,
        "value": 139.527,
        "_internal_originalTime": 1689833823000
    },
    {
        "time": 1689833824000,
        "value": 139.527,
        "_internal_originalTime": 1689833824000
    },
    {
        "time": 1689833825000,
        "value": 139.52,
        "_internal_originalTime": 1689833825000
    },
    {
        "time": 1689833826000,
        "value": 139.527,
        "_internal_originalTime": 1689833826000
    },
    {
        "time": 1689833827000,
        "value": 139.527,
        "_internal_originalTime": 1689833827000
    },
    {
        "time": 1689833830000,
        "value": 139.524,
        "_internal_originalTime": 1689833830000
    },
    {
        "time": 1689833831000,
        "value": 139.519,
        "_internal_originalTime": 1689833831000
    },
    {
        "time": 1689833832000,
        "value": 139.524,
        "_internal_originalTime": 1689833832000
    },
    {
        "time": 1689833833000,
        "value": 139.524,
        "_internal_originalTime": 1689833833000
    },
    {
        "time": 1689833834000,
        "value": 139.524,
        "_internal_originalTime": 1689833834000
    },
    {
        "time": 1689833835000,
        "value": 139.519,
        "_internal_originalTime": 1689833835000
    },
    {
        "time": 1689833837000,
        "value": 139.518,
        "_internal_originalTime": 1689833837000
    },
    {
        "time": 1689833838000,
        "value": 139.524,
        "_internal_originalTime": 1689833838000
    },
    {
        "time": 1689833839000,
        "value": 139.523,
        "_internal_originalTime": 1689833839000
    },
    {
        "time": 1689833840000,
        "value": 139.524,
        "_internal_originalTime": 1689833840000
    },
    {
        "time": 1689833842000,
        "value": 139.521,
        "_internal_originalTime": 1689833842000
    },
    {
        "time": 1689833844000,
        "value": 139.525,
        "_internal_originalTime": 1689833844000
    },
    {
        "time": 1689833847000,
        "value": 139.524,
        "_internal_originalTime": 1689833847000
    },
    {
        "time": 1689833848000,
        "value": 139.521,
        "_internal_originalTime": 1689833848000
    },
    {
        "time": 1689833850000,
        "value": 139.524,
        "_internal_originalTime": 1689833850000
    },
    {
        "time": 1689833852000,
        "value": 139.525,
        "_internal_originalTime": 1689833852000
    },
    {
        "time": 1689833854000,
        "value": 139.529,
        "_internal_originalTime": 1689833854000
    },
    {
        "time": 1689833855000,
        "value": 139.521,
        "_internal_originalTime": 1689833855000
    },
    {
        "time": 1689833856000,
        "value": 139.522,
        "_internal_originalTime": 1689833856000
    },
    {
        "time": 1689833857000,
        "value": 139.523,
        "_internal_originalTime": 1689833857000
    },
    {
        "time": 1689833858000,
        "value": 139.522,
        "_internal_originalTime": 1689833858000
    },
    {
        "time": 1689833861000,
        "value": 139.524,
        "_internal_originalTime": 1689833861000
    },
    {
        "time": 1689833864000,
        "value": 139.523,
        "_internal_originalTime": 1689833864000
    },
    {
        "time": 1689833865000,
        "value": 139.522,
        "_internal_originalTime": 1689833865000
    },
    {
        "time": 1689833868000,
        "value": 139.522,
        "_internal_originalTime": 1689833868000
    },
    {
        "time": 1689833869000,
        "value": 139.523,
        "_internal_originalTime": 1689833869000
    },
    {
        "time": 1689833870000,
        "value": 139.522,
        "_internal_originalTime": 1689833870000
    },
    {
        "time": 1689833871000,
        "value": 139.533,
        "_internal_originalTime": 1689833871000
    },
    {
        "time": 1689833872000,
        "value": 139.532,
        "_internal_originalTime": 1689833872000
    },
    {
        "time": 1689833873000,
        "value": 139.533,
        "_internal_originalTime": 1689833873000
    },
    {
        "time": 1689833875000,
        "value": 139.536,
        "_internal_originalTime": 1689833875000
    },
    {
        "time": 1689833876000,
        "value": 139.536,
        "_internal_originalTime": 1689833876000
    },
    {
        "time": 1689833878000,
        "value": 139.536,
        "_internal_originalTime": 1689833878000
    },
    {
        "time": 1689833879000,
        "value": 139.533,
        "_internal_originalTime": 1689833879000
    },
    {
        "time": 1689833880000,
        "value": 139.527,
        "_internal_originalTime": 1689833880000
    },
    {
        "time": 1689833881000,
        "value": 139.52,
        "_internal_originalTime": 1689833881000
    },
    {
        "time": 1689833882000,
        "value": 139.527,
        "_internal_originalTime": 1689833882000
    },
    {
        "time": 1689833885000,
        "value": 139.525,
        "_internal_originalTime": 1689833885000
    },
    {
        "time": 1689833886000,
        "value": 139.527,
        "_internal_originalTime": 1689833886000
    },
    {
        "time": 1689833888000,
        "value": 139.519,
        "_internal_originalTime": 1689833888000
    },
    {
        "time": 1689833889000,
        "value": 139.518,
        "_internal_originalTime": 1689833889000
    },
    {
        "time": 1689833890000,
        "value": 139.518,
        "_internal_originalTime": 1689833890000
    },
    {
        "time": 1689833892000,
        "value": 139.521,
        "_internal_originalTime": 1689833892000
    },
    {
        "time": 1689833893000,
        "value": 139.525,
        "_internal_originalTime": 1689833893000
    },
    {
        "time": 1689833894000,
        "value": 139.528,
        "_internal_originalTime": 1689833894000
    },
    {
        "time": 1689833900000,
        "value": 139.527,
        "_internal_originalTime": 1689833900000
    },
    {
        "time": 1689833902000,
        "value": 139.532,
        "_internal_originalTime": 1689833902000
    },
    {
        "time": 1689833903000,
        "value": 139.534,
        "_internal_originalTime": 1689833903000
    },
    {
        "time": 1689833904000,
        "value": 139.535,
        "_internal_originalTime": 1689833904000
    },
    {
        "time": 1689833905000,
        "value": 139.534,
        "_internal_originalTime": 1689833905000
    },
    {
        "time": 1689833906000,
        "value": 139.535,
        "_internal_originalTime": 1689833906000
    },
    {
        "time": 1689833907000,
        "value": 139.534,
        "_internal_originalTime": 1689833907000
    },
    {
        "time": 1689833908000,
        "value": 139.534,
        "_internal_originalTime": 1689833908000
    },
    {
        "time": 1689833911000,
        "value": 139.541,
        "_internal_originalTime": 1689833911000
    },
    {
        "time": 1689833912000,
        "value": 139.539,
        "_internal_originalTime": 1689833912000
    },
    {
        "time": 1689833913000,
        "value": 139.544,
        "_internal_originalTime": 1689833913000
    },
    {
        "time": 1689833914000,
        "value": 139.55,
        "_internal_originalTime": 1689833914000
    },
    {
        "time": 1689833915000,
        "value": 139.549,
        "_internal_originalTime": 1689833915000
    },
    {
        "time": 1689833916000,
        "value": 139.549,
        "_internal_originalTime": 1689833916000
    },
    {
        "time": 1689833919000,
        "value": 139.55,
        "_internal_originalTime": 1689833919000
    },
    {
        "time": 1689833920000,
        "value": 139.552,
        "_internal_originalTime": 1689833920000
    },
    {
        "time": 1689833921000,
        "value": 139.553,
        "_internal_originalTime": 1689833921000
    },
    {
        "time": 1689833923000,
        "value": 139.551,
        "_internal_originalTime": 1689833923000
    },
    {
        "time": 1689833924000,
        "value": 139.548,
        "_internal_originalTime": 1689833924000
    },
    {
        "time": 1689833925000,
        "value": 139.549,
        "_internal_originalTime": 1689833925000
    },
    {
        "time": 1689833926000,
        "value": 139.552,
        "_internal_originalTime": 1689833926000
    },
    {
        "time": 1689833927000,
        "value": 139.547,
        "_internal_originalTime": 1689833927000
    },
    {
        "time": 1689833929000,
        "value": 139.547,
        "_internal_originalTime": 1689833929000
    },
    {
        "time": 1689833930000,
        "value": 139.547,
        "_internal_originalTime": 1689833930000
    },
    {
        "time": 1689833931000,
        "value": 139.551,
        "_internal_originalTime": 1689833931000
    },
    {
        "time": 1689833932000,
        "value": 139.55,
        "_internal_originalTime": 1689833932000
    },
    {
        "time": 1689833935000,
        "value": 139.536,
        "_internal_originalTime": 1689833935000
    },
    {
        "time": 1689833936000,
        "value": 139.541,
        "_internal_originalTime": 1689833936000
    },
    {
        "time": 1689833938000,
        "value": 139.53,
        "_internal_originalTime": 1689833938000
    },
    {
        "time": 1689833939000,
        "value": 139.536,
        "_internal_originalTime": 1689833939000
    },
    {
        "time": 1689833940000,
        "value": 139.533,
        "_internal_originalTime": 1689833940000
    },
    {
        "time": 1689833941000,
        "value": 139.536,
        "_internal_originalTime": 1689833941000
    },
    {
        "time": 1689833942000,
        "value": 139.536,
        "_internal_originalTime": 1689833942000
    },
    {
        "time": 1689833943000,
        "value": 139.542,
        "_internal_originalTime": 1689833943000
    },
    {
        "time": 1689833944000,
        "value": 139.542,
        "_internal_originalTime": 1689833944000
    },
    {
        "time": 1689833945000,
        "value": 139.536,
        "_internal_originalTime": 1689833945000
    },
    {
        "time": 1689833947000,
        "value": 139.538,
        "_internal_originalTime": 1689833947000
    },
    {
        "time": 1689833948000,
        "value": 139.536,
        "_internal_originalTime": 1689833948000
    },
    {
        "time": 1689833949000,
        "value": 139.541,
        "_internal_originalTime": 1689833949000
    },
    {
        "time": 1689833950000,
        "value": 139.542,
        "_internal_originalTime": 1689833950000
    },
    {
        "time": 1689833951000,
        "value": 139.542,
        "_internal_originalTime": 1689833951000
    },
    {
        "time": 1689833953000,
        "value": 139.536,
        "_internal_originalTime": 1689833953000
    },
    {
        "time": 1689833955000,
        "value": 139.538,
        "_internal_originalTime": 1689833955000
    },
    {
        "time": 1689833956000,
        "value": 139.547,
        "_internal_originalTime": 1689833956000
    },
    {
        "time": 1689833957000,
        "value": 139.55,
        "_internal_originalTime": 1689833957000
    },
    {
        "time": 1689833958000,
        "value": 139.55,
        "_internal_originalTime": 1689833958000
    },
    {
        "time": 1689833960000,
        "value": 139.552,
        "_internal_originalTime": 1689833960000
    },
    {
        "time": 1689833962000,
        "value": 139.55,
        "_internal_originalTime": 1689833962000
    },
    {
        "time": 1689833963000,
        "value": 139.547,
        "_internal_originalTime": 1689833963000
    },
    {
        "time": 1689833964000,
        "value": 139.551,
        "_internal_originalTime": 1689833964000
    },
    {
        "time": 1689833966000,
        "value": 139.548,
        "_internal_originalTime": 1689833966000
    },
    {
        "time": 1689833967000,
        "value": 139.547,
        "_internal_originalTime": 1689833967000
    },
    {
        "time": 1689833968000,
        "value": 139.551,
        "_internal_originalTime": 1689833968000
    },
    {
        "time": 1689833969000,
        "value": 139.551,
        "_internal_originalTime": 1689833969000
    },
    {
        "time": 1689833970000,
        "value": 139.545,
        "_internal_originalTime": 1689833970000
    },
    {
        "time": 1689833971000,
        "value": 139.54,
        "_internal_originalTime": 1689833971000
    },
    {
        "time": 1689833972000,
        "value": 139.54,
        "_internal_originalTime": 1689833972000
    },
    {
        "time": 1689833974000,
        "value": 139.536,
        "_internal_originalTime": 1689833974000
    },
    {
        "time": 1689833975000,
        "value": 139.529,
        "_internal_originalTime": 1689833975000
    },
    {
        "time": 1689833977000,
        "value": 139.53,
        "_internal_originalTime": 1689833977000
    },
    {
        "time": 1689833978000,
        "value": 139.536,
        "_internal_originalTime": 1689833978000
    },
    {
        "time": 1689833980000,
        "value": 139.532,
        "_internal_originalTime": 1689833980000
    },
    {
        "time": 1689833981000,
        "value": 139.536,
        "_internal_originalTime": 1689833981000
    },
    {
        "time": 1689833982000,
        "value": 139.532,
        "_internal_originalTime": 1689833982000
    },
    {
        "time": 1689833983000,
        "value": 139.533,
        "_internal_originalTime": 1689833983000
    },
    {
        "time": 1689833986000,
        "value": 139.532,
        "_internal_originalTime": 1689833986000
    },
    {
        "time": 1689833987000,
        "value": 139.533,
        "_internal_originalTime": 1689833987000
    },
    {
        "time": 1689833990000,
        "value": 139.537,
        "_internal_originalTime": 1689833990000
    },
    {
        "time": 1689833992000,
        "value": 139.536,
        "_internal_originalTime": 1689833992000
    },
    {
        "time": 1689833994000,
        "value": 139.537,
        "_internal_originalTime": 1689833994000
    },
    {
        "time": 1689833995000,
        "value": 139.535,
        "_internal_originalTime": 1689833995000
    },
    {
        "time": 1689833996000,
        "value": 139.536,
        "_internal_originalTime": 1689833996000
    },
    {
        "time": 1689833997000,
        "value": 139.552,
        "_internal_originalTime": 1689833997000
    },
    {
        "time": 1689833998000,
        "value": 139.548,
        "_internal_originalTime": 1689833998000
    },
    {
        "time": 1689834000000,
        "value": 139.552,
        "_internal_originalTime": 1689834000000
    },
    {
        "time": 1689834001000,
        "value": 139.549,
        "_internal_originalTime": 1689834001000
    },
    {
        "time": 1689834004000,
        "value": 139.565,
        "_internal_originalTime": 1689834004000
    },
    {
        "time": 1689834005000,
        "value": 139.569,
        "_internal_originalTime": 1689834005000
    },
    {
        "time": 1689834006000,
        "value": 139.57,
        "_internal_originalTime": 1689834006000
    },
    {
        "time": 1689834007000,
        "value": 139.581,
        "_internal_originalTime": 1689834007000
    },
    {
        "time": 1689834010000,
        "value": 139.574,
        "_internal_originalTime": 1689834010000
    },
    {
        "time": 1689834011000,
        "value": 139.576,
        "_internal_originalTime": 1689834011000
    },
    {
        "time": 1689834012000,
        "value": 139.572,
        "_internal_originalTime": 1689834012000
    },
    {
        "time": 1689834014000,
        "value": 139.58,
        "_internal_originalTime": 1689834014000
    },
    {
        "time": 1689834015000,
        "value": 139.581,
        "_internal_originalTime": 1689834015000
    },
    {
        "time": 1689834016000,
        "value": 139.581,
        "_internal_originalTime": 1689834016000
    },
    {
        "time": 1689834018000,
        "value": 139.58,
        "_internal_originalTime": 1689834018000
    },
    {
        "time": 1689834019000,
        "value": 139.577,
        "_internal_originalTime": 1689834019000
    },
    {
        "time": 1689834020000,
        "value": 139.58,
        "_internal_originalTime": 1689834020000
    },
    {
        "time": 1689834021000,
        "value": 139.584,
        "_internal_originalTime": 1689834021000
    },
    {
        "time": 1689834022000,
        "value": 139.58,
        "_internal_originalTime": 1689834022000
    },
    {
        "time": 1689834024000,
        "value": 139.576,
        "_internal_originalTime": 1689834024000
    },
    {
        "time": 1689834027000,
        "value": 139.581,
        "_internal_originalTime": 1689834027000
    },
    {
        "time": 1689834028000,
        "value": 139.577,
        "_internal_originalTime": 1689834028000
    },
    {
        "time": 1689834029000,
        "value": 139.576,
        "_internal_originalTime": 1689834029000
    },
    {
        "time": 1689834030000,
        "value": 139.576,
        "_internal_originalTime": 1689834030000
    },
    {
        "time": 1689834031000,
        "value": 139.58,
        "_internal_originalTime": 1689834031000
    },
    {
        "time": 1689834033000,
        "value": 139.573,
        "_internal_originalTime": 1689834033000
    },
    {
        "time": 1689834034000,
        "value": 139.572,
        "_internal_originalTime": 1689834034000
    },
    {
        "time": 1689834035000,
        "value": 139.567,
        "_internal_originalTime": 1689834035000
    },
    {
        "time": 1689834036000,
        "value": 139.567,
        "_internal_originalTime": 1689834036000
    },
    {
        "time": 1689834041000,
        "value": 139.574,
        "_internal_originalTime": 1689834041000
    },
    {
        "time": 1689834042000,
        "value": 139.572,
        "_internal_originalTime": 1689834042000
    },
    {
        "time": 1689834043000,
        "value": 139.574,
        "_internal_originalTime": 1689834043000
    },
    {
        "time": 1689834044000,
        "value": 139.574,
        "_internal_originalTime": 1689834044000
    },
    {
        "time": 1689834046000,
        "value": 139.573,
        "_internal_originalTime": 1689834046000
    },
    {
        "time": 1689834047000,
        "value": 139.576,
        "_internal_originalTime": 1689834047000
    },
    {
        "time": 1689834049000,
        "value": 139.571,
        "_internal_originalTime": 1689834049000
    },
    {
        "time": 1689834051000,
        "value": 139.574,
        "_internal_originalTime": 1689834051000
    },
    {
        "time": 1689834052000,
        "value": 139.554,
        "_internal_originalTime": 1689834052000
    },
    {
        "time": 1689834053000,
        "value": 139.557,
        "_internal_originalTime": 1689834053000
    },
    {
        "time": 1689834054000,
        "value": 139.563,
        "_internal_originalTime": 1689834054000
    },
    {
        "time": 1689834055000,
        "value": 139.549,
        "_internal_originalTime": 1689834055000
    },
    {
        "time": 1689834057000,
        "value": 139.555,
        "_internal_originalTime": 1689834057000
    },
    {
        "time": 1689834058000,
        "value": 139.555,
        "_internal_originalTime": 1689834058000
    },
    {
        "time": 1689834059000,
        "value": 139.56,
        "_internal_originalTime": 1689834059000
    },
    {
        "time": 1689834060000,
        "value": 139.55,
        "_internal_originalTime": 1689834060000
    },
    {
        "time": 1689834061000,
        "value": 139.549,
        "_internal_originalTime": 1689834061000
    },
    {
        "time": 1689834062000,
        "value": 139.541,
        "_internal_originalTime": 1689834062000
    },
    {
        "time": 1689834063000,
        "value": 139.527,
        "_internal_originalTime": 1689834063000
    },
    {
        "time": 1689834064000,
        "value": 139.531,
        "_internal_originalTime": 1689834064000
    },
    {
        "time": 1689834065000,
        "value": 139.533,
        "_internal_originalTime": 1689834065000
    },
    {
        "time": 1689834066000,
        "value": 139.541,
        "_internal_originalTime": 1689834066000
    },
    {
        "time": 1689834067000,
        "value": 139.533,
        "_internal_originalTime": 1689834067000
    },
    {
        "time": 1689834069000,
        "value": 139.534,
        "_internal_originalTime": 1689834069000
    },
    {
        "time": 1689834070000,
        "value": 139.534,
        "_internal_originalTime": 1689834070000
    },
    {
        "time": 1689834071000,
        "value": 139.534,
        "_internal_originalTime": 1689834071000
    },
    {
        "time": 1689834072000,
        "value": 139.528,
        "_internal_originalTime": 1689834072000
    },
    {
        "time": 1689834073000,
        "value": 139.528,
        "_internal_originalTime": 1689834073000
    },
    {
        "time": 1689834074000,
        "value": 139.533,
        "_internal_originalTime": 1689834074000
    },
    {
        "time": 1689834075000,
        "value": 139.53,
        "_internal_originalTime": 1689834075000
    },
    {
        "time": 1689834076000,
        "value": 139.531,
        "_internal_originalTime": 1689834076000
    },
    {
        "time": 1689834077000,
        "value": 139.531,
        "_internal_originalTime": 1689834077000
    },
    {
        "time": 1689834079000,
        "value": 139.527,
        "_internal_originalTime": 1689834079000
    },
    {
        "time": 1689834081000,
        "value": 139.528,
        "_internal_originalTime": 1689834081000
    },
    {
        "time": 1689834082000,
        "value": 139.527,
        "_internal_originalTime": 1689834082000
    },
    {
        "time": 1689834083000,
        "value": 139.531,
        "_internal_originalTime": 1689834083000
    },
    {
        "time": 1689834084000,
        "value": 139.528,
        "_internal_originalTime": 1689834084000
    },
    {
        "time": 1689834085000,
        "value": 139.527,
        "_internal_originalTime": 1689834085000
    },
    {
        "time": 1689834086000,
        "value": 139.532,
        "_internal_originalTime": 1689834086000
    },
    {
        "time": 1689834087000,
        "value": 139.532,
        "_internal_originalTime": 1689834087000
    },
    {
        "time": 1689834089000,
        "value": 139.527,
        "_internal_originalTime": 1689834089000
    },
    {
        "time": 1689834091000,
        "value": 139.526,
        "_internal_originalTime": 1689834091000
    },
    {
        "time": 1689834093000,
        "value": 139.537,
        "_internal_originalTime": 1689834093000
    },
    {
        "time": 1689834094000,
        "value": 139.531,
        "_internal_originalTime": 1689834094000
    },
    {
        "time": 1689834095000,
        "value": 139.531,
        "_internal_originalTime": 1689834095000
    },
    {
        "time": 1689834097000,
        "value": 139.532,
        "_internal_originalTime": 1689834097000
    },
    {
        "time": 1689834098000,
        "value": 139.532,
        "_internal_originalTime": 1689834098000
    },
    {
        "time": 1689834100000,
        "value": 139.532,
        "_internal_originalTime": 1689834100000
    },
    {
        "time": 1689834101000,
        "value": 139.532,
        "_internal_originalTime": 1689834101000
    },
    {
        "time": 1689834102000,
        "value": 139.531,
        "_internal_originalTime": 1689834102000
    },
    {
        "time": 1689834103000,
        "value": 139.541,
        "_internal_originalTime": 1689834103000
    },
    {
        "time": 1689834105000,
        "value": 139.532,
        "_internal_originalTime": 1689834105000
    },
    {
        "time": 1689834106000,
        "value": 139.526,
        "_internal_originalTime": 1689834106000
    },
    {
        "time": 1689834107000,
        "value": 139.532,
        "_internal_originalTime": 1689834107000
    },
    {
        "time": 1689834109000,
        "value": 139.522,
        "_internal_originalTime": 1689834109000
    },
    {
        "time": 1689834111000,
        "value": 139.525,
        "_internal_originalTime": 1689834111000
    },
    {
        "time": 1689834112000,
        "value": 139.522,
        "_internal_originalTime": 1689834112000
    },
    {
        "time": 1689834113000,
        "value": 139.528,
        "_internal_originalTime": 1689834113000
    },
    {
        "time": 1689834114000,
        "value": 139.529,
        "_internal_originalTime": 1689834114000
    },
    {
        "time": 1689834115000,
        "value": 139.523,
        "_internal_originalTime": 1689834115000
    },
    {
        "time": 1689834118000,
        "value": 139.525,
        "_internal_originalTime": 1689834118000
    },
    {
        "time": 1689834120000,
        "value": 139.535,
        "_internal_originalTime": 1689834120000
    },
    {
        "time": 1689834121000,
        "value": 139.534,
        "_internal_originalTime": 1689834121000
    },
    {
        "time": 1689834122000,
        "value": 139.533,
        "_internal_originalTime": 1689834122000
    },
    {
        "time": 1689834123000,
        "value": 139.533,
        "_internal_originalTime": 1689834123000
    },
    {
        "time": 1689834126000,
        "value": 139.536,
        "_internal_originalTime": 1689834126000
    },
    {
        "time": 1689834128000,
        "value": 139.526,
        "_internal_originalTime": 1689834128000
    },
    {
        "time": 1689834129000,
        "value": 139.533,
        "_internal_originalTime": 1689834129000
    },
    {
        "time": 1689834130000,
        "value": 139.532,
        "_internal_originalTime": 1689834130000
    },
    {
        "time": 1689834131000,
        "value": 139.532,
        "_internal_originalTime": 1689834131000
    },
    {
        "time": 1689834132000,
        "value": 139.533,
        "_internal_originalTime": 1689834132000
    },
    {
        "time": 1689834133000,
        "value": 139.53,
        "_internal_originalTime": 1689834133000
    },
    {
        "time": 1689834134000,
        "value": 139.533,
        "_internal_originalTime": 1689834134000
    },
    {
        "time": 1689834136000,
        "value": 139.533,
        "_internal_originalTime": 1689834136000
    },
    {
        "time": 1689834137000,
        "value": 139.533,
        "_internal_originalTime": 1689834137000
    },
    {
        "time": 1689834138000,
        "value": 139.532,
        "_internal_originalTime": 1689834138000
    },
    {
        "time": 1689834139000,
        "value": 139.531,
        "_internal_originalTime": 1689834139000
    },
    {
        "time": 1689834142000,
        "value": 139.53,
        "_internal_originalTime": 1689834142000
    },
    {
        "time": 1689834143000,
        "value": 139.535,
        "_internal_originalTime": 1689834143000
    },
    {
        "time": 1689834144000,
        "value": 139.534,
        "_internal_originalTime": 1689834144000
    },
    {
        "time": 1689834145000,
        "value": 139.529,
        "_internal_originalTime": 1689834145000
    },
    {
        "time": 1689834146000,
        "value": 139.533,
        "_internal_originalTime": 1689834146000
    },
    {
        "time": 1689834147000,
        "value": 139.529,
        "_internal_originalTime": 1689834147000
    },
    {
        "time": 1689834148000,
        "value": 139.529,
        "_internal_originalTime": 1689834148000
    },
    {
        "time": 1689834149000,
        "value": 139.53,
        "_internal_originalTime": 1689834149000
    },
    {
        "time": 1689834150000,
        "value": 139.533,
        "_internal_originalTime": 1689834150000
    },
    {
        "time": 1689834151000,
        "value": 139.543,
        "_internal_originalTime": 1689834151000
    },
    {
        "time": 1689834153000,
        "value": 139.538,
        "_internal_originalTime": 1689834153000
    },
    {
        "time": 1689834154000,
        "value": 139.543,
        "_internal_originalTime": 1689834154000
    },
    {
        "time": 1689834155000,
        "value": 139.54,
        "_internal_originalTime": 1689834155000
    },
    {
        "time": 1689834157000,
        "value": 139.552,
        "_internal_originalTime": 1689834157000
    },
    {
        "time": 1689834160000,
        "value": 139.553,
        "_internal_originalTime": 1689834160000
    },
    {
        "time": 1689834162000,
        "value": 139.552,
        "_internal_originalTime": 1689834162000
    },
    {
        "time": 1689834164000,
        "value": 139.553,
        "_internal_originalTime": 1689834164000
    },
    {
        "time": 1689834165000,
        "value": 139.551,
        "_internal_originalTime": 1689834165000
    },
    {
        "time": 1689834167000,
        "value": 139.551,
        "_internal_originalTime": 1689834167000
    },
    {
        "time": 1689834168000,
        "value": 139.536,
        "_internal_originalTime": 1689834168000
    },
    {
        "time": 1689834169000,
        "value": 139.537,
        "_internal_originalTime": 1689834169000
    },
    {
        "time": 1689834170000,
        "value": 139.543,
        "_internal_originalTime": 1689834170000
    },
    {
        "time": 1689834172000,
        "value": 139.54,
        "_internal_originalTime": 1689834172000
    },
    {
        "time": 1689834173000,
        "value": 139.53,
        "_internal_originalTime": 1689834173000
    },
    {
        "time": 1689834174000,
        "value": 139.536,
        "_internal_originalTime": 1689834174000
    },
    {
        "time": 1689834175000,
        "value": 139.537,
        "_internal_originalTime": 1689834175000
    },
    {
        "time": 1689834176000,
        "value": 139.542,
        "_internal_originalTime": 1689834176000
    },
    {
        "time": 1689834177000,
        "value": 139.542,
        "_internal_originalTime": 1689834177000
    },
    {
        "time": 1689834179000,
        "value": 139.536,
        "_internal_originalTime": 1689834179000
    },
    {
        "time": 1689834180000,
        "value": 139.52,
        "_internal_originalTime": 1689834180000
    },
    {
        "time": 1689834184000,
        "value": 139.535,
        "_internal_originalTime": 1689834184000
    },
    {
        "time": 1689834186000,
        "value": 139.537,
        "_internal_originalTime": 1689834186000
    },
    {
        "time": 1689834188000,
        "value": 139.532,
        "_internal_originalTime": 1689834188000
    },
    {
        "time": 1689834190000,
        "value": 139.537,
        "_internal_originalTime": 1689834190000
    },
    {
        "time": 1689834191000,
        "value": 139.532,
        "_internal_originalTime": 1689834191000
    },
    {
        "time": 1689834193000,
        "value": 139.538,
        "_internal_originalTime": 1689834193000
    },
    {
        "time": 1689834195000,
        "value": 139.533,
        "_internal_originalTime": 1689834195000
    },
    {
        "time": 1689834196000,
        "value": 139.534,
        "_internal_originalTime": 1689834196000
    },
    {
        "time": 1689834197000,
        "value": 139.532,
        "_internal_originalTime": 1689834197000
    },
    {
        "time": 1689834198000,
        "value": 139.529,
        "_internal_originalTime": 1689834198000
    },
    {
        "time": 1689834199000,
        "value": 139.53,
        "_internal_originalTime": 1689834199000
    },
    {
        "time": 1689834201000,
        "value": 139.529,
        "_internal_originalTime": 1689834201000
    },
    {
        "time": 1689834202000,
        "value": 139.534,
        "_internal_originalTime": 1689834202000
    },
    {
        "time": 1689834204000,
        "value": 139.534,
        "_internal_originalTime": 1689834204000
    },
    {
        "time": 1689834205000,
        "value": 139.516,
        "_internal_originalTime": 1689834205000
    },
    {
        "time": 1689834206000,
        "value": 139.515,
        "_internal_originalTime": 1689834206000
    },
    {
        "time": 1689834209000,
        "value": 139.516,
        "_internal_originalTime": 1689834209000
    },
    {
        "time": 1689834210000,
        "value": 139.526,
        "_internal_originalTime": 1689834210000
    },
    {
        "time": 1689834211000,
        "value": 139.527,
        "_internal_originalTime": 1689834211000
    },
    {
        "time": 1689834213000,
        "value": 139.528,
        "_internal_originalTime": 1689834213000
    },
    {
        "time": 1689834214000,
        "value": 139.529,
        "_internal_originalTime": 1689834214000
    },
    {
        "time": 1689834215000,
        "value": 139.518,
        "_internal_originalTime": 1689834215000
    },
    {
        "time": 1689834217000,
        "value": 139.526,
        "_internal_originalTime": 1689834217000
    },
    {
        "time": 1689834218000,
        "value": 139.522,
        "_internal_originalTime": 1689834218000
    },
    {
        "time": 1689834220000,
        "value": 139.527,
        "_internal_originalTime": 1689834220000
    },
    {
        "time": 1689834221000,
        "value": 139.525,
        "_internal_originalTime": 1689834221000
    },
    {
        "time": 1689834222000,
        "value": 139.523,
        "_internal_originalTime": 1689834222000
    },
    {
        "time": 1689834225000,
        "value": 139.524,
        "_internal_originalTime": 1689834225000
    },
    {
        "time": 1689834226000,
        "value": 139.532,
        "_internal_originalTime": 1689834226000
    },
    {
        "time": 1689834227000,
        "value": 139.528,
        "_internal_originalTime": 1689834227000
    },
    {
        "time": 1689834228000,
        "value": 139.529,
        "_internal_originalTime": 1689834228000
    },
    {
        "time": 1689834229000,
        "value": 139.533,
        "_internal_originalTime": 1689834229000
    },
    {
        "time": 1689834230000,
        "value": 139.534,
        "_internal_originalTime": 1689834230000
    },
    {
        "time": 1689834231000,
        "value": 139.529,
        "_internal_originalTime": 1689834231000
    },
    {
        "time": 1689834234000,
        "value": 139.53,
        "_internal_originalTime": 1689834234000
    },
    {
        "time": 1689834236000,
        "value": 139.528,
        "_internal_originalTime": 1689834236000
    },
    {
        "time": 1689834238000,
        "value": 139.516,
        "_internal_originalTime": 1689834238000
    },
    {
        "time": 1689834239000,
        "value": 139.524,
        "_internal_originalTime": 1689834239000
    },
    {
        "time": 1689834241000,
        "value": 139.517,
        "_internal_originalTime": 1689834241000
    },
    {
        "time": 1689834242000,
        "value": 139.519,
        "_internal_originalTime": 1689834242000
    },
    {
        "time": 1689834244000,
        "value": 139.522,
        "_internal_originalTime": 1689834244000
    },
    {
        "time": 1689834245000,
        "value": 139.515,
        "_internal_originalTime": 1689834245000
    },
    {
        "time": 1689834246000,
        "value": 139.523,
        "_internal_originalTime": 1689834246000
    },
    {
        "time": 1689834247000,
        "value": 139.525,
        "_internal_originalTime": 1689834247000
    },
    {
        "time": 1689834250000,
        "value": 139.523,
        "_internal_originalTime": 1689834250000
    },
    {
        "time": 1689834251000,
        "value": 139.516,
        "_internal_originalTime": 1689834251000
    },
    {
        "time": 1689834253000,
        "value": 139.523,
        "_internal_originalTime": 1689834253000
    },
    {
        "time": 1689834254000,
        "value": 139.519,
        "_internal_originalTime": 1689834254000
    },
    {
        "time": 1689834255000,
        "value": 139.526,
        "_internal_originalTime": 1689834255000
    },
    {
        "time": 1689834256000,
        "value": 139.531,
        "_internal_originalTime": 1689834256000
    },
    {
        "time": 1689834257000,
        "value": 139.524,
        "_internal_originalTime": 1689834257000
    },
    {
        "time": 1689834258000,
        "value": 139.528,
        "_internal_originalTime": 1689834258000
    },
    {
        "time": 1689834259000,
        "value": 139.533,
        "_internal_originalTime": 1689834259000
    },
    {
        "time": 1689834260000,
        "value": 139.532,
        "_internal_originalTime": 1689834260000
    },
    {
        "time": 1689834261000,
        "value": 139.528,
        "_internal_originalTime": 1689834261000
    },
    {
        "time": 1689834262000,
        "value": 139.532,
        "_internal_originalTime": 1689834262000
    },
    {
        "time": 1689834264000,
        "value": 139.522,
        "_internal_originalTime": 1689834264000
    },
    {
        "time": 1689834265000,
        "value": 139.521,
        "_internal_originalTime": 1689834265000
    },
    {
        "time": 1689834266000,
        "value": 139.525,
        "_internal_originalTime": 1689834266000
    },
    {
        "time": 1689834267000,
        "value": 139.527,
        "_internal_originalTime": 1689834267000
    },
    {
        "time": 1689834268000,
        "value": 139.526,
        "_internal_originalTime": 1689834268000
    },
    {
        "time": 1689834271000,
        "value": 139.526,
        "_internal_originalTime": 1689834271000
    },
    {
        "time": 1689834272000,
        "value": 139.528,
        "_internal_originalTime": 1689834272000
    },
    {
        "time": 1689834274000,
        "value": 139.534,
        "_internal_originalTime": 1689834274000
    },
    {
        "time": 1689834275000,
        "value": 139.527,
        "_internal_originalTime": 1689834275000
    },
    {
        "time": 1689834276000,
        "value": 139.527,
        "_internal_originalTime": 1689834276000
    },
    {
        "time": 1689834277000,
        "value": 139.532,
        "_internal_originalTime": 1689834277000
    },
    {
        "time": 1689834280000,
        "value": 139.526,
        "_internal_originalTime": 1689834280000
    },
    {
        "time": 1689834281000,
        "value": 139.524,
        "_internal_originalTime": 1689834281000
    },
    {
        "time": 1689834286000,
        "value": 139.516,
        "_internal_originalTime": 1689834286000
    },
    {
        "time": 1689834287000,
        "value": 139.521,
        "_internal_originalTime": 1689834287000
    },
    {
        "time": 1689834289000,
        "value": 139.515,
        "_internal_originalTime": 1689834289000
    },
    {
        "time": 1689834291000,
        "value": 139.519,
        "_internal_originalTime": 1689834291000
    },
    {
        "time": 1689834292000,
        "value": 139.519,
        "_internal_originalTime": 1689834292000
    },
    {
        "time": 1689834293000,
        "value": 139.514,
        "_internal_originalTime": 1689834293000
    },
    {
        "time": 1689834294000,
        "value": 139.523,
        "_internal_originalTime": 1689834294000
    },
    {
        "time": 1689834295000,
        "value": 139.524,
        "_internal_originalTime": 1689834295000
    },
    {
        "time": 1689834297000,
        "value": 139.525,
        "_internal_originalTime": 1689834297000
    },
    {
        "time": 1689834300000,
        "value": 139.519,
        "_internal_originalTime": 1689834300000
    },
    {
        "time": 1689834301000,
        "value": 139.514,
        "_internal_originalTime": 1689834301000
    },
    {
        "time": 1689834303000,
        "value": 139.514,
        "_internal_originalTime": 1689834303000
    },
    {
        "time": 1689834304000,
        "value": 139.517,
        "_internal_originalTime": 1689834304000
    },
    {
        "time": 1689834305000,
        "value": 139.521,
        "_internal_originalTime": 1689834305000
    },
    {
        "time": 1689834307000,
        "value": 139.512,
        "_internal_originalTime": 1689834307000
    },
    {
        "time": 1689834308000,
        "value": 139.512,
        "_internal_originalTime": 1689834308000
    },
    {
        "time": 1689834309000,
        "value": 139.519,
        "_internal_originalTime": 1689834309000
    },
    {
        "time": 1689834310000,
        "value": 139.513,
        "_internal_originalTime": 1689834310000
    },
    {
        "time": 1689834312000,
        "value": 139.521,
        "_internal_originalTime": 1689834312000
    },
    {
        "time": 1689834313000,
        "value": 139.519,
        "_internal_originalTime": 1689834313000
    },
    {
        "time": 1689834315000,
        "value": 139.514,
        "_internal_originalTime": 1689834315000
    },
    {
        "time": 1689834316000,
        "value": 139.511,
        "_internal_originalTime": 1689834316000
    },
    {
        "time": 1689834317000,
        "value": 139.504,
        "_internal_originalTime": 1689834317000
    },
    {
        "time": 1689834319000,
        "value": 139.511,
        "_internal_originalTime": 1689834319000
    },
    {
        "time": 1689834322000,
        "value": 139.511,
        "_internal_originalTime": 1689834322000
    },
    {
        "time": 1689834323000,
        "value": 139.511,
        "_internal_originalTime": 1689834323000
    },
    {
        "time": 1689834325000,
        "value": 139.508,
        "_internal_originalTime": 1689834325000
    },
    {
        "time": 1689834326000,
        "value": 139.506,
        "_internal_originalTime": 1689834326000
    },
    {
        "time": 1689834328000,
        "value": 139.512,
        "_internal_originalTime": 1689834328000
    },
    {
        "time": 1689834330000,
        "value": 139.515,
        "_internal_originalTime": 1689834330000
    },
    {
        "time": 1689834331000,
        "value": 139.515,
        "_internal_originalTime": 1689834331000
    },
    {
        "time": 1689834332000,
        "value": 139.511,
        "_internal_originalTime": 1689834332000
    },
    {
        "time": 1689834334000,
        "value": 139.512,
        "_internal_originalTime": 1689834334000
    },
    {
        "time": 1689834335000,
        "value": 139.508,
        "_internal_originalTime": 1689834335000
    },
    {
        "time": 1689834337000,
        "value": 139.512,
        "_internal_originalTime": 1689834337000
    },
    {
        "time": 1689834340000,
        "value": 139.517,
        "_internal_originalTime": 1689834340000
    },
    {
        "time": 1689834341000,
        "value": 139.512,
        "_internal_originalTime": 1689834341000
    },
    {
        "time": 1689834342000,
        "value": 139.517,
        "_internal_originalTime": 1689834342000
    },
    {
        "time": 1689834343000,
        "value": 139.517,
        "_internal_originalTime": 1689834343000
    },
    {
        "time": 1689834344000,
        "value": 139.511,
        "_internal_originalTime": 1689834344000
    },
    {
        "time": 1689834346000,
        "value": 139.512,
        "_internal_originalTime": 1689834346000
    },
    {
        "time": 1689834348000,
        "value": 139.511,
        "_internal_originalTime": 1689834348000
    },
    {
        "time": 1689834349000,
        "value": 139.516,
        "_internal_originalTime": 1689834349000
    },
    {
        "time": 1689834350000,
        "value": 139.517,
        "_internal_originalTime": 1689834350000
    },
    {
        "time": 1689834352000,
        "value": 139.517,
        "_internal_originalTime": 1689834352000
    },
    {
        "time": 1689834353000,
        "value": 139.517,
        "_internal_originalTime": 1689834353000
    },
    {
        "time": 1689834354000,
        "value": 139.517,
        "_internal_originalTime": 1689834354000
    },
    {
        "time": 1689834355000,
        "value": 139.518,
        "_internal_originalTime": 1689834355000
    },
    {
        "time": 1689834357000,
        "value": 139.517,
        "_internal_originalTime": 1689834357000
    },
    {
        "time": 1689834358000,
        "value": 139.518,
        "_internal_originalTime": 1689834358000
    },
    {
        "time": 1689834359000,
        "value": 139.512,
        "_internal_originalTime": 1689834359000
    },
    {
        "time": 1689834360000,
        "value": 139.512,
        "_internal_originalTime": 1689834360000
    },
    {
        "time": 1689834361000,
        "value": 139.517,
        "_internal_originalTime": 1689834361000
    },
    {
        "time": 1689834366000,
        "value": 139.523,
        "_internal_originalTime": 1689834366000
    },
    {
        "time": 1689834367000,
        "value": 139.527,
        "_internal_originalTime": 1689834367000
    },
    {
        "time": 1689834368000,
        "value": 139.531,
        "_internal_originalTime": 1689834368000
    },
    {
        "time": 1689834369000,
        "value": 139.526,
        "_internal_originalTime": 1689834369000
    },
    {
        "time": 1689834371000,
        "value": 139.53,
        "_internal_originalTime": 1689834371000
    },
    {
        "time": 1689834372000,
        "value": 139.531,
        "_internal_originalTime": 1689834372000
    },
    {
        "time": 1689834373000,
        "value": 139.536,
        "_internal_originalTime": 1689834373000
    },
    {
        "time": 1689834375000,
        "value": 139.536,
        "_internal_originalTime": 1689834375000
    },
    {
        "time": 1689834376000,
        "value": 139.557,
        "_internal_originalTime": 1689834376000
    },
    {
        "time": 1689834378000,
        "value": 139.556,
        "_internal_originalTime": 1689834378000
    },
    {
        "time": 1689834379000,
        "value": 139.561,
        "_internal_originalTime": 1689834379000
    },
    {
        "time": 1689834380000,
        "value": 139.556,
        "_internal_originalTime": 1689834380000
    },
    {
        "time": 1689834381000,
        "value": 139.55,
        "_internal_originalTime": 1689834381000
    },
    {
        "time": 1689834383000,
        "value": 139.55,
        "_internal_originalTime": 1689834383000
    },
    {
        "time": 1689834384000,
        "value": 139.55,
        "_internal_originalTime": 1689834384000
    },
    {
        "time": 1689834386000,
        "value": 139.55,
        "_internal_originalTime": 1689834386000
    },
    {
        "time": 1689834387000,
        "value": 139.55,
        "_internal_originalTime": 1689834387000
    },
    {
        "time": 1689834389000,
        "value": 139.55,
        "_internal_originalTime": 1689834389000
    },
    {
        "time": 1689834390000,
        "value": 139.545,
        "_internal_originalTime": 1689834390000
    },
    {
        "time": 1689834391000,
        "value": 139.547,
        "_internal_originalTime": 1689834391000
    },
    {
        "time": 1689834392000,
        "value": 139.542,
        "_internal_originalTime": 1689834392000
    },
    {
        "time": 1689834394000,
        "value": 139.547,
        "_internal_originalTime": 1689834394000
    },
    {
        "time": 1689834395000,
        "value": 139.541,
        "_internal_originalTime": 1689834395000
    },
    {
        "time": 1689834396000,
        "value": 139.547,
        "_internal_originalTime": 1689834396000
    },
    {
        "time": 1689834399000,
        "value": 139.548,
        "_internal_originalTime": 1689834399000
    },
    {
        "time": 1689834400000,
        "value": 139.542,
        "_internal_originalTime": 1689834400000
    },
    {
        "time": 1689834401000,
        "value": 139.543,
        "_internal_originalTime": 1689834401000
    },
    {
        "time": 1689834402000,
        "value": 139.54,
        "_internal_originalTime": 1689834402000
    },
    {
        "time": 1689834403000,
        "value": 139.545,
        "_internal_originalTime": 1689834403000
    },
    {
        "time": 1689834404000,
        "value": 139.542,
        "_internal_originalTime": 1689834404000
    },
    {
        "time": 1689834405000,
        "value": 139.544,
        "_internal_originalTime": 1689834405000
    },
    {
        "time": 1689834406000,
        "value": 139.543,
        "_internal_originalTime": 1689834406000
    },
    {
        "time": 1689834407000,
        "value": 139.539,
        "_internal_originalTime": 1689834407000
    },
    {
        "time": 1689834409000,
        "value": 139.54,
        "_internal_originalTime": 1689834409000
    },
    {
        "time": 1689834411000,
        "value": 139.542,
        "_internal_originalTime": 1689834411000
    },
    {
        "time": 1689834412000,
        "value": 139.541,
        "_internal_originalTime": 1689834412000
    },
    {
        "time": 1689834413000,
        "value": 139.541,
        "_internal_originalTime": 1689834413000
    },
    {
        "time": 1689834415000,
        "value": 139.536,
        "_internal_originalTime": 1689834415000
    },
    {
        "time": 1689834416000,
        "value": 139.536,
        "_internal_originalTime": 1689834416000
    },
    {
        "time": 1689834419000,
        "value": 139.541,
        "_internal_originalTime": 1689834419000
    },
    {
        "time": 1689834420000,
        "value": 139.52,
        "_internal_originalTime": 1689834420000
    },
    {
        "time": 1689834421000,
        "value": 139.538,
        "_internal_originalTime": 1689834421000
    },
    {
        "time": 1689834422000,
        "value": 139.541,
        "_internal_originalTime": 1689834422000
    },
    {
        "time": 1689834425000,
        "value": 139.549,
        "_internal_originalTime": 1689834425000
    },
    {
        "time": 1689834427000,
        "value": 139.545,
        "_internal_originalTime": 1689834427000
    },
    {
        "time": 1689834428000,
        "value": 139.552,
        "_internal_originalTime": 1689834428000
    },
    {
        "time": 1689834429000,
        "value": 139.551,
        "_internal_originalTime": 1689834429000
    },
    {
        "time": 1689834430000,
        "value": 139.553,
        "_internal_originalTime": 1689834430000
    },
    {
        "time": 1689834431000,
        "value": 139.551,
        "_internal_originalTime": 1689834431000
    },
    {
        "time": 1689834432000,
        "value": 139.55,
        "_internal_originalTime": 1689834432000
    },
    {
        "time": 1689834434000,
        "value": 139.555,
        "_internal_originalTime": 1689834434000
    },
    {
        "time": 1689834436000,
        "value": 139.55,
        "_internal_originalTime": 1689834436000
    },
    {
        "time": 1689834437000,
        "value": 139.554,
        "_internal_originalTime": 1689834437000
    },
    {
        "time": 1689834438000,
        "value": 139.553,
        "_internal_originalTime": 1689834438000
    },
    {
        "time": 1689834439000,
        "value": 139.553,
        "_internal_originalTime": 1689834439000
    },
    {
        "time": 1689834440000,
        "value": 139.556,
        "_internal_originalTime": 1689834440000
    },
    {
        "time": 1689834443000,
        "value": 139.548,
        "_internal_originalTime": 1689834443000
    },
    {
        "time": 1689834447000,
        "value": 139.56,
        "_internal_originalTime": 1689834447000
    },
    {
        "time": 1689834448000,
        "value": 139.553,
        "_internal_originalTime": 1689834448000
    },
    {
        "time": 1689834449000,
        "value": 139.548,
        "_internal_originalTime": 1689834449000
    },
    {
        "time": 1689834450000,
        "value": 139.553,
        "_internal_originalTime": 1689834450000
    },
    {
        "time": 1689834451000,
        "value": 139.553,
        "_internal_originalTime": 1689834451000
    },
    {
        "time": 1689834452000,
        "value": 139.552,
        "_internal_originalTime": 1689834452000
    },
    {
        "time": 1689834454000,
        "value": 139.553,
        "_internal_originalTime": 1689834454000
    },
    {
        "time": 1689834456000,
        "value": 139.551,
        "_internal_originalTime": 1689834456000
    },
    {
        "time": 1689834457000,
        "value": 139.551,
        "_internal_originalTime": 1689834457000
    },
    {
        "time": 1689834458000,
        "value": 139.543,
        "_internal_originalTime": 1689834458000
    },
    {
        "time": 1689834459000,
        "value": 139.544,
        "_internal_originalTime": 1689834459000
    },
    {
        "time": 1689834461000,
        "value": 139.546,
        "_internal_originalTime": 1689834461000
    },
    {
        "time": 1689834462000,
        "value": 139.55,
        "_internal_originalTime": 1689834462000
    },
    {
        "time": 1689834466000,
        "value": 139.537,
        "_internal_originalTime": 1689834466000
    },
    {
        "time": 1689834468000,
        "value": 139.541,
        "_internal_originalTime": 1689834468000
    },
    {
        "time": 1689834470000,
        "value": 139.537,
        "_internal_originalTime": 1689834470000
    },
    {
        "time": 1689834471000,
        "value": 139.533,
        "_internal_originalTime": 1689834471000
    },
    {
        "time": 1689834473000,
        "value": 139.531,
        "_internal_originalTime": 1689834473000
    },
    {
        "time": 1689834474000,
        "value": 139.533,
        "_internal_originalTime": 1689834474000
    },
    {
        "time": 1689834475000,
        "value": 139.532,
        "_internal_originalTime": 1689834475000
    },
    {
        "time": 1689834476000,
        "value": 139.53,
        "_internal_originalTime": 1689834476000
    },
    {
        "time": 1689834478000,
        "value": 139.534,
        "_internal_originalTime": 1689834478000
    },
    {
        "time": 1689834481000,
        "value": 139.526,
        "_internal_originalTime": 1689834481000
    },
    {
        "time": 1689834482000,
        "value": 139.526,
        "_internal_originalTime": 1689834482000
    },
    {
        "time": 1689834484000,
        "value": 139.534,
        "_internal_originalTime": 1689834484000
    },
    {
        "time": 1689834485000,
        "value": 139.533,
        "_internal_originalTime": 1689834485000
    },
    {
        "time": 1689834486000,
        "value": 139.535,
        "_internal_originalTime": 1689834486000
    },
    {
        "time": 1689834487000,
        "value": 139.528,
        "_internal_originalTime": 1689834487000
    },
    {
        "time": 1689834488000,
        "value": 139.533,
        "_internal_originalTime": 1689834488000
    },
    {
        "time": 1689834491000,
        "value": 139.526,
        "_internal_originalTime": 1689834491000
    },
    {
        "time": 1689834493000,
        "value": 139.515,
        "_internal_originalTime": 1689834493000
    },
    {
        "time": 1689834494000,
        "value": 139.511,
        "_internal_originalTime": 1689834494000
    },
    {
        "time": 1689834495000,
        "value": 139.518,
        "_internal_originalTime": 1689834495000
    },
    {
        "time": 1689834496000,
        "value": 139.512,
        "_internal_originalTime": 1689834496000
    },
    {
        "time": 1689834498000,
        "value": 139.517,
        "_internal_originalTime": 1689834498000
    },
    {
        "time": 1689834500000,
        "value": 139.512,
        "_internal_originalTime": 1689834500000
    },
    {
        "time": 1689834501000,
        "value": 139.512,
        "_internal_originalTime": 1689834501000
    },
    {
        "time": 1689834502000,
        "value": 139.515,
        "_internal_originalTime": 1689834502000
    },
    {
        "time": 1689834503000,
        "value": 139.516,
        "_internal_originalTime": 1689834503000
    },
    {
        "time": 1689834505000,
        "value": 139.519,
        "_internal_originalTime": 1689834505000
    },
    {
        "time": 1689834506000,
        "value": 139.522,
        "_internal_originalTime": 1689834506000
    },
    {
        "time": 1689834507000,
        "value": 139.523,
        "_internal_originalTime": 1689834507000
    },
    {
        "time": 1689834509000,
        "value": 139.523,
        "_internal_originalTime": 1689834509000
    },
    {
        "time": 1689834510000,
        "value": 139.524,
        "_internal_originalTime": 1689834510000
    },
    {
        "time": 1689834511000,
        "value": 139.52,
        "_internal_originalTime": 1689834511000
    },
    {
        "time": 1689834512000,
        "value": 139.522,
        "_internal_originalTime": 1689834512000
    },
    {
        "time": 1689834515000,
        "value": 139.517,
        "_internal_originalTime": 1689834515000
    },
    {
        "time": 1689834516000,
        "value": 139.522,
        "_internal_originalTime": 1689834516000
    },
    {
        "time": 1689834517000,
        "value": 139.518,
        "_internal_originalTime": 1689834517000
    },
    {
        "time": 1689834518000,
        "value": 139.515,
        "_internal_originalTime": 1689834518000
    },
    {
        "time": 1689834520000,
        "value": 139.517,
        "_internal_originalTime": 1689834520000
    },
    {
        "time": 1689834521000,
        "value": 139.518,
        "_internal_originalTime": 1689834521000
    },
    {
        "time": 1689834522000,
        "value": 139.518,
        "_internal_originalTime": 1689834522000
    },
    {
        "time": 1689834523000,
        "value": 139.51,
        "_internal_originalTime": 1689834523000
    },
    {
        "time": 1689834524000,
        "value": 139.51,
        "_internal_originalTime": 1689834524000
    },
    {
        "time": 1689834525000,
        "value": 139.52,
        "_internal_originalTime": 1689834525000
    },
    {
        "time": 1689834526000,
        "value": 139.521,
        "_internal_originalTime": 1689834526000
    },
    {
        "time": 1689834529000,
        "value": 139.518,
        "_internal_originalTime": 1689834529000
    },
    {
        "time": 1689834530000,
        "value": 139.523,
        "_internal_originalTime": 1689834530000
    },
    {
        "time": 1689834531000,
        "value": 139.516,
        "_internal_originalTime": 1689834531000
    },
    {
        "time": 1689834533000,
        "value": 139.517,
        "_internal_originalTime": 1689834533000
    },
    {
        "time": 1689834534000,
        "value": 139.522,
        "_internal_originalTime": 1689834534000
    },
    {
        "time": 1689834536000,
        "value": 139.523,
        "_internal_originalTime": 1689834536000
    },
    {
        "time": 1689834537000,
        "value": 139.523,
        "_internal_originalTime": 1689834537000
    },
    {
        "time": 1689834538000,
        "value": 139.522,
        "_internal_originalTime": 1689834538000
    },
    {
        "time": 1689834539000,
        "value": 139.516,
        "_internal_originalTime": 1689834539000
    },
    {
        "time": 1689834541000,
        "value": 139.5,
        "_internal_originalTime": 1689834541000
    },
    {
        "time": 1689834543000,
        "value": 139.523,
        "_internal_originalTime": 1689834543000
    },
    {
        "time": 1689834544000,
        "value": 139.522,
        "_internal_originalTime": 1689834544000
    },
    {
        "time": 1689834545000,
        "value": 139.515,
        "_internal_originalTime": 1689834545000
    },
    {
        "time": 1689834548000,
        "value": 139.522,
        "_internal_originalTime": 1689834548000
    },
    {
        "time": 1689834550000,
        "value": 139.52,
        "_internal_originalTime": 1689834550000
    },
    {
        "time": 1689834551000,
        "value": 139.512,
        "_internal_originalTime": 1689834551000
    },
    {
        "time": 1689834552000,
        "value": 139.523,
        "_internal_originalTime": 1689834552000
    },
    {
        "time": 1689834553000,
        "value": 139.523,
        "_internal_originalTime": 1689834553000
    },
    {
        "time": 1689834554000,
        "value": 139.523,
        "_internal_originalTime": 1689834554000
    },
    {
        "time": 1689834555000,
        "value": 139.522,
        "_internal_originalTime": 1689834555000
    },
    {
        "time": 1689834557000,
        "value": 139.537,
        "_internal_originalTime": 1689834557000
    },
    {
        "time": 1689834558000,
        "value": 139.532,
        "_internal_originalTime": 1689834558000
    },
    {
        "time": 1689834559000,
        "value": 139.537,
        "_internal_originalTime": 1689834559000
    },
    {
        "time": 1689834560000,
        "value": 139.54,
        "_internal_originalTime": 1689834560000
    },
    {
        "time": 1689834561000,
        "value": 139.552,
        "_internal_originalTime": 1689834561000
    },
    {
        "time": 1689834562000,
        "value": 139.548,
        "_internal_originalTime": 1689834562000
    },
    {
        "time": 1689834563000,
        "value": 139.55,
        "_internal_originalTime": 1689834563000
    },
    {
        "time": 1689834566000,
        "value": 139.559,
        "_internal_originalTime": 1689834566000
    },
    {
        "time": 1689834567000,
        "value": 139.554,
        "_internal_originalTime": 1689834567000
    },
    {
        "time": 1689834569000,
        "value": 139.558,
        "_internal_originalTime": 1689834569000
    },
    {
        "time": 1689834570000,
        "value": 139.56,
        "_internal_originalTime": 1689834570000
    },
    {
        "time": 1689834571000,
        "value": 139.564,
        "_internal_originalTime": 1689834571000
    },
    {
        "time": 1689834572000,
        "value": 139.562,
        "_internal_originalTime": 1689834572000
    },
    {
        "time": 1689834573000,
        "value": 139.555,
        "_internal_originalTime": 1689834573000
    },
    {
        "time": 1689834574000,
        "value": 139.554,
        "_internal_originalTime": 1689834574000
    },
    {
        "time": 1689834575000,
        "value": 139.562,
        "_internal_originalTime": 1689834575000
    },
    {
        "time": 1689834577000,
        "value": 139.563,
        "_internal_originalTime": 1689834577000
    },
    {
        "time": 1689834578000,
        "value": 139.564,
        "_internal_originalTime": 1689834578000
    },
    {
        "time": 1689834580000,
        "value": 139.568,
        "_internal_originalTime": 1689834580000
    },
    {
        "time": 1689834582000,
        "value": 139.572,
        "_internal_originalTime": 1689834582000
    },
    {
        "time": 1689834583000,
        "value": 139.57,
        "_internal_originalTime": 1689834583000
    },
    {
        "time": 1689834584000,
        "value": 139.57,
        "_internal_originalTime": 1689834584000
    },
    {
        "time": 1689834585000,
        "value": 139.565,
        "_internal_originalTime": 1689834585000
    },
    {
        "time": 1689834587000,
        "value": 139.57,
        "_internal_originalTime": 1689834587000
    },
    {
        "time": 1689834588000,
        "value": 139.574,
        "_internal_originalTime": 1689834588000
    },
    {
        "time": 1689834590000,
        "value": 139.575,
        "_internal_originalTime": 1689834590000
    },
    {
        "time": 1689834591000,
        "value": 139.58,
        "_internal_originalTime": 1689834591000
    },
    {
        "time": 1689834592000,
        "value": 139.582,
        "_internal_originalTime": 1689834592000
    },
    {
        "time": 1689834593000,
        "value": 139.578,
        "_internal_originalTime": 1689834593000
    },
    {
        "time": 1689834595000,
        "value": 139.581,
        "_internal_originalTime": 1689834595000
    },
    {
        "time": 1689834596000,
        "value": 139.579,
        "_internal_originalTime": 1689834596000
    },
    {
        "time": 1689834598000,
        "value": 139.582,
        "_internal_originalTime": 1689834598000
    },
    {
        "time": 1689834600000,
        "value": 139.58,
        "_internal_originalTime": 1689834600000
    },
    {
        "time": 1689834601000,
        "value": 139.585,
        "_internal_originalTime": 1689834601000
    },
    {
        "time": 1689834602000,
        "value": 139.585,
        "_internal_originalTime": 1689834602000
    },
    {
        "time": 1689834604000,
        "value": 139.582,
        "_internal_originalTime": 1689834604000
    },
    {
        "time": 1689834605000,
        "value": 139.584,
        "_internal_originalTime": 1689834605000
    },
    {
        "time": 1689834607000,
        "value": 139.583,
        "_internal_originalTime": 1689834607000
    },
    {
        "time": 1689834608000,
        "value": 139.578,
        "_internal_originalTime": 1689834608000
    },
    {
        "time": 1689834609000,
        "value": 139.58,
        "_internal_originalTime": 1689834609000
    },
    {
        "time": 1689834611000,
        "value": 139.577,
        "_internal_originalTime": 1689834611000
    },
    {
        "time": 1689834613000,
        "value": 139.58,
        "_internal_originalTime": 1689834613000
    },
    {
        "time": 1689834614000,
        "value": 139.58,
        "_internal_originalTime": 1689834614000
    },
    {
        "time": 1689834616000,
        "value": 139.58,
        "_internal_originalTime": 1689834616000
    },
    {
        "time": 1689834617000,
        "value": 139.582,
        "_internal_originalTime": 1689834617000
    },
    {
        "time": 1689834618000,
        "value": 139.58,
        "_internal_originalTime": 1689834618000
    },
    {
        "time": 1689834621000,
        "value": 139.581,
        "_internal_originalTime": 1689834621000
    },
    {
        "time": 1689834622000,
        "value": 139.582,
        "_internal_originalTime": 1689834622000
    },
    {
        "time": 1689834624000,
        "value": 139.58,
        "_internal_originalTime": 1689834624000
    },
    {
        "time": 1689834625000,
        "value": 139.58,
        "_internal_originalTime": 1689834625000
    },
    {
        "time": 1689834627000,
        "value": 139.576,
        "_internal_originalTime": 1689834627000
    },
    {
        "time": 1689834628000,
        "value": 139.58,
        "_internal_originalTime": 1689834628000
    },
    {
        "time": 1689834630000,
        "value": 139.58,
        "_internal_originalTime": 1689834630000
    },
    {
        "time": 1689834631000,
        "value": 139.577,
        "_internal_originalTime": 1689834631000
    },
    {
        "time": 1689834633000,
        "value": 139.574,
        "_internal_originalTime": 1689834633000
    },
    {
        "time": 1689834635000,
        "value": 139.575,
        "_internal_originalTime": 1689834635000
    },
    {
        "time": 1689834636000,
        "value": 139.592,
        "_internal_originalTime": 1689834636000
    },
    {
        "time": 1689834637000,
        "value": 139.592,
        "_internal_originalTime": 1689834637000
    },
    {
        "time": 1689834638000,
        "value": 139.58,
        "_internal_originalTime": 1689834638000
    },
    {
        "time": 1689834639000,
        "value": 139.578,
        "_internal_originalTime": 1689834639000
    },
    {
        "time": 1689834640000,
        "value": 139.572,
        "_internal_originalTime": 1689834640000
    },
    {
        "time": 1689834642000,
        "value": 139.572,
        "_internal_originalTime": 1689834642000
    },
    {
        "time": 1689834643000,
        "value": 139.568,
        "_internal_originalTime": 1689834643000
    },
    {
        "time": 1689834644000,
        "value": 139.561,
        "_internal_originalTime": 1689834644000
    },
    {
        "time": 1689834645000,
        "value": 139.554,
        "_internal_originalTime": 1689834645000
    },
    {
        "time": 1689834646000,
        "value": 139.559,
        "_internal_originalTime": 1689834646000
    },
    {
        "time": 1689834647000,
        "value": 139.558,
        "_internal_originalTime": 1689834647000
    },
    {
        "time": 1689834648000,
        "value": 139.571,
        "_internal_originalTime": 1689834648000
    },
    {
        "time": 1689834649000,
        "value": 139.572,
        "_internal_originalTime": 1689834649000
    },
    {
        "time": 1689834650000,
        "value": 139.567,
        "_internal_originalTime": 1689834650000
    },
    {
        "time": 1689834652000,
        "value": 139.566,
        "_internal_originalTime": 1689834652000
    },
    {
        "time": 1689834653000,
        "value": 139.571,
        "_internal_originalTime": 1689834653000
    },
    {
        "time": 1689834654000,
        "value": 139.579,
        "_internal_originalTime": 1689834654000
    },
    {
        "time": 1689834655000,
        "value": 139.572,
        "_internal_originalTime": 1689834655000
    },
    {
        "time": 1689834656000,
        "value": 139.575,
        "_internal_originalTime": 1689834656000
    },
    {
        "time": 1689834657000,
        "value": 139.58,
        "_internal_originalTime": 1689834657000
    },
    {
        "time": 1689834658000,
        "value": 139.575,
        "_internal_originalTime": 1689834658000
    },
    {
        "time": 1689834659000,
        "value": 139.574,
        "_internal_originalTime": 1689834659000
    },
    {
        "time": 1689834660000,
        "value": 139.572,
        "_internal_originalTime": 1689834660000
    },
    {
        "time": 1689834661000,
        "value": 139.567,
        "_internal_originalTime": 1689834661000
    },
    {
        "time": 1689834662000,
        "value": 139.572,
        "_internal_originalTime": 1689834662000
    },
    {
        "time": 1689834663000,
        "value": 139.577,
        "_internal_originalTime": 1689834663000
    },
    {
        "time": 1689834664000,
        "value": 139.58,
        "_internal_originalTime": 1689834664000
    },
    {
        "time": 1689834666000,
        "value": 139.574,
        "_internal_originalTime": 1689834666000
    },
    {
        "time": 1689834667000,
        "value": 139.581,
        "_internal_originalTime": 1689834667000
    },
    {
        "time": 1689834668000,
        "value": 139.577,
        "_internal_originalTime": 1689834668000
    },
    {
        "time": 1689834669000,
        "value": 139.566,
        "_internal_originalTime": 1689834669000
    },
    {
        "time": 1689834670000,
        "value": 139.568,
        "_internal_originalTime": 1689834670000
    },
    {
        "time": 1689834671000,
        "value": 139.566,
        "_internal_originalTime": 1689834671000
    },
    {
        "time": 1689834672000,
        "value": 139.565,
        "_internal_originalTime": 1689834672000
    },
    {
        "time": 1689834674000,
        "value": 139.564,
        "_internal_originalTime": 1689834674000
    },
    {
        "time": 1689834677000,
        "value": 139.564,
        "_internal_originalTime": 1689834677000
    },
    {
        "time": 1689834678000,
        "value": 139.564,
        "_internal_originalTime": 1689834678000
    },
    {
        "time": 1689834679000,
        "value": 139.569,
        "_internal_originalTime": 1689834679000
    },
    {
        "time": 1689834680000,
        "value": 139.571,
        "_internal_originalTime": 1689834680000
    },
    {
        "time": 1689834682000,
        "value": 139.565,
        "_internal_originalTime": 1689834682000
    },
    {
        "time": 1689834684000,
        "value": 139.57,
        "_internal_originalTime": 1689834684000
    },
    {
        "time": 1689834685000,
        "value": 139.565,
        "_internal_originalTime": 1689834685000
    },
    {
        "time": 1689834686000,
        "value": 139.571,
        "_internal_originalTime": 1689834686000
    },
    {
        "time": 1689834688000,
        "value": 139.568,
        "_internal_originalTime": 1689834688000
    },
    {
        "time": 1689834689000,
        "value": 139.565,
        "_internal_originalTime": 1689834689000
    },
    {
        "time": 1689834690000,
        "value": 139.564,
        "_internal_originalTime": 1689834690000
    },
    {
        "time": 1689834691000,
        "value": 139.565,
        "_internal_originalTime": 1689834691000
    },
    {
        "time": 1689834693000,
        "value": 139.567,
        "_internal_originalTime": 1689834693000
    },
    {
        "time": 1689834694000,
        "value": 139.572,
        "_internal_originalTime": 1689834694000
    },
    {
        "time": 1689834695000,
        "value": 139.565,
        "_internal_originalTime": 1689834695000
    },
    {
        "time": 1689834697000,
        "value": 139.573,
        "_internal_originalTime": 1689834697000
    },
    {
        "time": 1689834699000,
        "value": 139.565,
        "_internal_originalTime": 1689834699000
    },
    {
        "time": 1689834701000,
        "value": 139.568,
        "_internal_originalTime": 1689834701000
    },
    {
        "time": 1689834702000,
        "value": 139.567,
        "_internal_originalTime": 1689834702000
    },
    {
        "time": 1689834703000,
        "value": 139.568,
        "_internal_originalTime": 1689834703000
    },
    {
        "time": 1689834704000,
        "value": 139.567,
        "_internal_originalTime": 1689834704000
    },
    {
        "time": 1689834705000,
        "value": 139.569,
        "_internal_originalTime": 1689834705000
    },
    {
        "time": 1689834707000,
        "value": 139.57,
        "_internal_originalTime": 1689834707000
    },
    {
        "time": 1689834709000,
        "value": 139.571,
        "_internal_originalTime": 1689834709000
    },
    {
        "time": 1689834710000,
        "value": 139.567,
        "_internal_originalTime": 1689834710000
    },
    {
        "time": 1689834712000,
        "value": 139.58,
        "_internal_originalTime": 1689834712000
    },
    {
        "time": 1689834714000,
        "value": 139.58,
        "_internal_originalTime": 1689834714000
    },
    {
        "time": 1689834716000,
        "value": 139.579,
        "_internal_originalTime": 1689834716000
    },
    {
        "time": 1689834718000,
        "value": 139.583,
        "_internal_originalTime": 1689834718000
    },
    {
        "time": 1689834719000,
        "value": 139.582,
        "_internal_originalTime": 1689834719000
    },
    {
        "time": 1689834721000,
        "value": 139.582,
        "_internal_originalTime": 1689834721000
    },
    {
        "time": 1689834722000,
        "value": 139.577,
        "_internal_originalTime": 1689834722000
    },
    {
        "time": 1689834723000,
        "value": 139.582,
        "_internal_originalTime": 1689834723000
    },
    {
        "time": 1689834724000,
        "value": 139.582,
        "_internal_originalTime": 1689834724000
    },
    {
        "time": 1689834726000,
        "value": 139.578,
        "_internal_originalTime": 1689834726000
    },
    {
        "time": 1689834727000,
        "value": 139.577,
        "_internal_originalTime": 1689834727000
    },
    {
        "time": 1689834728000,
        "value": 139.583,
        "_internal_originalTime": 1689834728000
    },
    {
        "time": 1689834730000,
        "value": 139.588,
        "_internal_originalTime": 1689834730000
    },
    {
        "time": 1689834731000,
        "value": 139.592,
        "_internal_originalTime": 1689834731000
    },
    {
        "time": 1689834733000,
        "value": 139.587,
        "_internal_originalTime": 1689834733000
    },
    {
        "time": 1689834734000,
        "value": 139.587,
        "_internal_originalTime": 1689834734000
    },
    {
        "time": 1689834735000,
        "value": 139.588,
        "_internal_originalTime": 1689834735000
    },
    {
        "time": 1689834736000,
        "value": 139.587,
        "_internal_originalTime": 1689834736000
    },
    {
        "time": 1689834737000,
        "value": 139.591,
        "_internal_originalTime": 1689834737000
    },
    {
        "time": 1689834738000,
        "value": 139.588,
        "_internal_originalTime": 1689834738000
    },
    {
        "time": 1689834743000,
        "value": 139.591,
        "_internal_originalTime": 1689834743000
    },
    {
        "time": 1689834744000,
        "value": 139.587,
        "_internal_originalTime": 1689834744000
    },
    {
        "time": 1689834745000,
        "value": 139.593,
        "_internal_originalTime": 1689834745000
    },
    {
        "time": 1689834747000,
        "value": 139.59,
        "_internal_originalTime": 1689834747000
    },
    {
        "time": 1689834749000,
        "value": 139.595,
        "_internal_originalTime": 1689834749000
    },
    {
        "time": 1689834751000,
        "value": 139.598,
        "_internal_originalTime": 1689834751000
    },
    {
        "time": 1689834752000,
        "value": 139.592,
        "_internal_originalTime": 1689834752000
    },
    {
        "time": 1689834753000,
        "value": 139.588,
        "_internal_originalTime": 1689834753000
    },
    {
        "time": 1689834756000,
        "value": 139.589,
        "_internal_originalTime": 1689834756000
    },
    {
        "time": 1689834757000,
        "value": 139.59,
        "_internal_originalTime": 1689834757000
    },
    {
        "time": 1689834758000,
        "value": 139.591,
        "_internal_originalTime": 1689834758000
    },
    {
        "time": 1689834760000,
        "value": 139.591,
        "_internal_originalTime": 1689834760000
    },
    {
        "time": 1689834762000,
        "value": 139.593,
        "_internal_originalTime": 1689834762000
    },
    {
        "time": 1689834763000,
        "value": 139.589,
        "_internal_originalTime": 1689834763000
    },
    {
        "time": 1689834764000,
        "value": 139.594,
        "_internal_originalTime": 1689834764000
    },
    {
        "time": 1689834765000,
        "value": 139.588,
        "_internal_originalTime": 1689834765000
    },
    {
        "time": 1689834766000,
        "value": 139.594,
        "_internal_originalTime": 1689834766000
    },
    {
        "time": 1689834767000,
        "value": 139.593,
        "_internal_originalTime": 1689834767000
    },
    {
        "time": 1689834769000,
        "value": 139.592,
        "_internal_originalTime": 1689834769000
    },
    {
        "time": 1689834770000,
        "value": 139.587,
        "_internal_originalTime": 1689834770000
    },
    {
        "time": 1689834771000,
        "value": 139.578,
        "_internal_originalTime": 1689834771000
    },
    {
        "time": 1689834776000,
        "value": 139.579,
        "_internal_originalTime": 1689834776000
    },
    {
        "time": 1689834777000,
        "value": 139.579,
        "_internal_originalTime": 1689834777000
    },
    {
        "time": 1689834778000,
        "value": 139.577,
        "_internal_originalTime": 1689834778000
    },
    {
        "time": 1689834779000,
        "value": 139.576,
        "_internal_originalTime": 1689834779000
    },
    {
        "time": 1689834780000,
        "value": 139.577,
        "_internal_originalTime": 1689834780000
    },
    {
        "time": 1689834781000,
        "value": 139.58,
        "_internal_originalTime": 1689834781000
    },
    {
        "time": 1689834782000,
        "value": 139.578,
        "_internal_originalTime": 1689834782000
    },
    {
        "time": 1689834784000,
        "value": 139.58,
        "_internal_originalTime": 1689834784000
    },
    {
        "time": 1689834786000,
        "value": 139.574,
        "_internal_originalTime": 1689834786000
    },
    {
        "time": 1689834787000,
        "value": 139.576,
        "_internal_originalTime": 1689834787000
    },
    {
        "time": 1689834788000,
        "value": 139.576,
        "_internal_originalTime": 1689834788000
    },
    {
        "time": 1689834790000,
        "value": 139.58,
        "_internal_originalTime": 1689834790000
    },
    {
        "time": 1689834791000,
        "value": 139.578,
        "_internal_originalTime": 1689834791000
    },
    {
        "time": 1689834792000,
        "value": 139.576,
        "_internal_originalTime": 1689834792000
    },
    {
        "time": 1689834793000,
        "value": 139.574,
        "_internal_originalTime": 1689834793000
    },
    {
        "time": 1689834794000,
        "value": 139.572,
        "_internal_originalTime": 1689834794000
    },
    {
        "time": 1689834795000,
        "value": 139.573,
        "_internal_originalTime": 1689834795000
    },
    {
        "time": 1689834849000,
        "value": 139.576,
        "_internal_originalTime": 1689834849000
    },
    {
        "time": 1689834851000,
        "value": 139.572,
        "_internal_originalTime": 1689834851000
    },
    {
        "time": 1689834852000,
        "value": 139.581,
        "_internal_originalTime": 1689834852000
    },
    {
        "time": 1689834853000,
        "value": 139.581,
        "_internal_originalTime": 1689834853000
    },
    {
        "time": 1689834854000,
        "value": 139.581,
        "_internal_originalTime": 1689834854000
    },
    {
        "time": 1689834858000,
        "value": 139.581,
        "_internal_originalTime": 1689834858000
    },
    {
        "time": 1689834859000,
        "value": 139.572,
        "_internal_originalTime": 1689834859000
    },
    {
        "time": 1689834860000,
        "value": 139.576,
        "_internal_originalTime": 1689834860000
    },
    {
        "time": 1689834861000,
        "value": 139.576,
        "_internal_originalTime": 1689834861000
    },
    {
        "time": 1689834862000,
        "value": 139.574,
        "_internal_originalTime": 1689834862000
    },
    {
        "time": 1689834863000,
        "value": 139.575,
        "_internal_originalTime": 1689834863000
    },
    {
        "time": 1689834864000,
        "value": 139.574,
        "_internal_originalTime": 1689834864000
    },
    {
        "time": 1689834867000,
        "value": 139.576,
        "_internal_originalTime": 1689834867000
    },
    {
        "time": 1689834869000,
        "value": 139.58,
        "_internal_originalTime": 1689834869000
    },
    {
        "time": 1689834871000,
        "value": 139.581,
        "_internal_originalTime": 1689834871000
    },
    {
        "time": 1689834873000,
        "value": 139.58,
        "_internal_originalTime": 1689834873000
    },
    {
        "time": 1689834874000,
        "value": 139.576,
        "_internal_originalTime": 1689834874000
    },
    {
        "time": 1689834875000,
        "value": 139.574,
        "_internal_originalTime": 1689834875000
    },
    {
        "time": 1689834877000,
        "value": 139.58,
        "_internal_originalTime": 1689834877000
    },
    {
        "time": 1689834878000,
        "value": 139.579,
        "_internal_originalTime": 1689834878000
    },
    {
        "time": 1689834881000,
        "value": 139.577,
        "_internal_originalTime": 1689834881000
    },
    {
        "time": 1689834882000,
        "value": 139.575,
        "_internal_originalTime": 1689834882000
    },
    {
        "time": 1689834883000,
        "value": 139.577,
        "_internal_originalTime": 1689834883000
    },
    {
        "time": 1689834885000,
        "value": 139.576,
        "_internal_originalTime": 1689834885000
    },
    {
        "time": 1689834886000,
        "value": 139.577,
        "_internal_originalTime": 1689834886000
    },
    {
        "time": 1689834888000,
        "value": 139.58,
        "_internal_originalTime": 1689834888000
    },
    {
        "time": 1689834889000,
        "value": 139.578,
        "_internal_originalTime": 1689834889000
    },
    {
        "time": 1689834890000,
        "value": 139.581,
        "_internal_originalTime": 1689834890000
    },
    {
        "time": 1689834891000,
        "value": 139.581,
        "_internal_originalTime": 1689834891000
    },
    {
        "time": 1689834894000,
        "value": 139.577,
        "_internal_originalTime": 1689834894000
    },
    {
        "time": 1689834895000,
        "value": 139.576,
        "_internal_originalTime": 1689834895000
    },
    {
        "time": 1689834897000,
        "value": 139.576,
        "_internal_originalTime": 1689834897000
    },
    {
        "time": 1689834899000,
        "value": 139.576,
        "_internal_originalTime": 1689834899000
    },
    {
        "time": 1689834900000,
        "value": 139.573,
        "_internal_originalTime": 1689834900000
    },
    {
        "time": 1689834901000,
        "value": 139.578,
        "_internal_originalTime": 1689834901000
    },
    {
        "time": 1689834903000,
        "value": 139.581,
        "_internal_originalTime": 1689834903000
    },
    {
        "time": 1689834905000,
        "value": 139.575,
        "_internal_originalTime": 1689834905000
    },
    {
        "time": 1689834906000,
        "value": 139.581,
        "_internal_originalTime": 1689834906000
    },
    {
        "time": 1689834907000,
        "value": 139.581,
        "_internal_originalTime": 1689834907000
    },
    {
        "time": 1689834908000,
        "value": 139.577,
        "_internal_originalTime": 1689834908000
    },
    {
        "time": 1689834909000,
        "value": 139.58,
        "_internal_originalTime": 1689834909000
    },
    {
        "time": 1689834911000,
        "value": 139.581,
        "_internal_originalTime": 1689834911000
    },
    {
        "time": 1689834912000,
        "value": 139.581,
        "_internal_originalTime": 1689834912000
    },
    {
        "time": 1689834914000,
        "value": 139.576,
        "_internal_originalTime": 1689834914000
    },
    {
        "time": 1689834915000,
        "value": 139.576,
        "_internal_originalTime": 1689834915000
    },
    {
        "time": 1689834916000,
        "value": 139.577,
        "_internal_originalTime": 1689834916000
    },
    {
        "time": 1689834917000,
        "value": 139.576,
        "_internal_originalTime": 1689834917000
    },
    {
        "time": 1689834918000,
        "value": 139.576,
        "_internal_originalTime": 1689834918000
    },
    {
        "time": 1689834922000,
        "value": 139.574,
        "_internal_originalTime": 1689834922000
    },
    {
        "time": 1689834923000,
        "value": 139.572,
        "_internal_originalTime": 1689834923000
    },
    {
        "time": 1689834924000,
        "value": 139.572,
        "_internal_originalTime": 1689834924000
    },
    {
        "time": 1689834925000,
        "value": 139.571,
        "_internal_originalTime": 1689834925000
    },
    {
        "time": 1689834926000,
        "value": 139.568,
        "_internal_originalTime": 1689834926000
    },
    {
        "time": 1689834927000,
        "value": 139.571,
        "_internal_originalTime": 1689834927000
    },
    {
        "time": 1689834929000,
        "value": 139.569,
        "_internal_originalTime": 1689834929000
    },
    {
        "time": 1689834930000,
        "value": 139.56,
        "_internal_originalTime": 1689834930000
    },
    {
        "time": 1689834931000,
        "value": 139.554,
        "_internal_originalTime": 1689834931000
    },
    {
        "time": 1689834933000,
        "value": 139.556,
        "_internal_originalTime": 1689834933000
    },
    {
        "time": 1689834934000,
        "value": 139.556,
        "_internal_originalTime": 1689834934000
    },
    {
        "time": 1689834935000,
        "value": 139.552,
        "_internal_originalTime": 1689834935000
    },
    {
        "time": 1689834937000,
        "value": 139.552,
        "_internal_originalTime": 1689834937000
    },
    {
        "time": 1689834938000,
        "value": 139.548,
        "_internal_originalTime": 1689834938000
    },
    {
        "time": 1689834940000,
        "value": 139.548,
        "_internal_originalTime": 1689834940000
    },
    {
        "time": 1689834941000,
        "value": 139.551,
        "_internal_originalTime": 1689834941000
    },
    {
        "time": 1689834942000,
        "value": 139.548,
        "_internal_originalTime": 1689834942000
    },
    {
        "time": 1689834943000,
        "value": 139.548,
        "_internal_originalTime": 1689834943000
    },
    {
        "time": 1689834944000,
        "value": 139.547,
        "_internal_originalTime": 1689834944000
    },
    {
        "time": 1689834945000,
        "value": 139.546,
        "_internal_originalTime": 1689834945000
    },
    {
        "time": 1689834947000,
        "value": 139.547,
        "_internal_originalTime": 1689834947000
    },
    {
        "time": 1689834948000,
        "value": 139.547,
        "_internal_originalTime": 1689834948000
    },
    {
        "time": 1689834949000,
        "value": 139.547,
        "_internal_originalTime": 1689834949000
    },
    {
        "time": 1689834950000,
        "value": 139.538,
        "_internal_originalTime": 1689834950000
    },
    {
        "time": 1689834951000,
        "value": 139.544,
        "_internal_originalTime": 1689834951000
    },
    {
        "time": 1689834952000,
        "value": 139.545,
        "_internal_originalTime": 1689834952000
    },
    {
        "time": 1689834955000,
        "value": 139.538,
        "_internal_originalTime": 1689834955000
    },
    {
        "time": 1689834958000,
        "value": 139.545,
        "_internal_originalTime": 1689834958000
    },
    {
        "time": 1689834959000,
        "value": 139.54,
        "_internal_originalTime": 1689834959000
    },
    {
        "time": 1689834961000,
        "value": 139.54,
        "_internal_originalTime": 1689834961000
    },
    {
        "time": 1689834962000,
        "value": 139.542,
        "_internal_originalTime": 1689834962000
    },
    {
        "time": 1689834963000,
        "value": 139.542,
        "_internal_originalTime": 1689834963000
    },
    {
        "time": 1689834964000,
        "value": 139.542,
        "_internal_originalTime": 1689834964000
    },
    {
        "time": 1689834965000,
        "value": 139.535,
        "_internal_originalTime": 1689834965000
    },
    {
        "time": 1689834966000,
        "value": 139.537,
        "_internal_originalTime": 1689834966000
    },
    {
        "time": 1689834967000,
        "value": 139.531,
        "_internal_originalTime": 1689834967000
    },
    {
        "time": 1689834968000,
        "value": 139.53,
        "_internal_originalTime": 1689834968000
    },
    {
        "time": 1689834970000,
        "value": 139.527,
        "_internal_originalTime": 1689834970000
    },
    {
        "time": 1689834971000,
        "value": 139.529,
        "_internal_originalTime": 1689834971000
    },
    {
        "time": 1689834972000,
        "value": 139.522,
        "_internal_originalTime": 1689834972000
    },
    {
        "time": 1689834973000,
        "value": 139.532,
        "_internal_originalTime": 1689834973000
    },
    {
        "time": 1689834974000,
        "value": 139.528,
        "_internal_originalTime": 1689834974000
    },
    {
        "time": 1689834975000,
        "value": 139.531,
        "_internal_originalTime": 1689834975000
    },
    {
        "time": 1689834976000,
        "value": 139.527,
        "_internal_originalTime": 1689834976000
    },
    {
        "time": 1689834978000,
        "value": 139.536,
        "_internal_originalTime": 1689834978000
    },
    {
        "time": 1689834979000,
        "value": 139.528,
        "_internal_originalTime": 1689834979000
    },
    {
        "time": 1689834981000,
        "value": 139.541,
        "_internal_originalTime": 1689834981000
    },
    {
        "time": 1689834982000,
        "value": 139.534,
        "_internal_originalTime": 1689834982000
    },
    {
        "time": 1689834983000,
        "value": 139.535,
        "_internal_originalTime": 1689834983000
    },
    {
        "time": 1689834984000,
        "value": 139.531,
        "_internal_originalTime": 1689834984000
    },
    {
        "time": 1689834985000,
        "value": 139.532,
        "_internal_originalTime": 1689834985000
    },
    {
        "time": 1689834986000,
        "value": 139.529,
        "_internal_originalTime": 1689834986000
    },
    {
        "time": 1689834987000,
        "value": 139.532,
        "_internal_originalTime": 1689834987000
    },
    {
        "time": 1689834988000,
        "value": 139.524,
        "_internal_originalTime": 1689834988000
    },
    {
        "time": 1689834990000,
        "value": 139.531,
        "_internal_originalTime": 1689834990000
    },
    {
        "time": 1689834991000,
        "value": 139.531,
        "_internal_originalTime": 1689834991000
    },
    {
        "time": 1689834993000,
        "value": 139.528,
        "_internal_originalTime": 1689834993000
    },
    {
        "time": 1689834994000,
        "value": 139.526,
        "_internal_originalTime": 1689834994000
    },
    {
        "time": 1689834995000,
        "value": 139.526,
        "_internal_originalTime": 1689834995000
    },
    {
        "time": 1689834996000,
        "value": 139.527,
        "_internal_originalTime": 1689834996000
    },
    {
        "time": 1689834997000,
        "value": 139.528,
        "_internal_originalTime": 1689834997000
    },
    {
        "time": 1689834999000,
        "value": 139.524,
        "_internal_originalTime": 1689834999000
    },
    {
        "time": 1689835000000,
        "value": 139.518,
        "_internal_originalTime": 1689835000000
    },
    {
        "time": 1689835001000,
        "value": 139.523,
        "_internal_originalTime": 1689835001000
    },
    {
        "time": 1689835003000,
        "value": 139.522,
        "_internal_originalTime": 1689835003000
    },
    {
        "time": 1689835006000,
        "value": 139.524,
        "_internal_originalTime": 1689835006000
    },
    {
        "time": 1689835008000,
        "value": 139.533,
        "_internal_originalTime": 1689835008000
    },
    {
        "time": 1689835009000,
        "value": 139.536,
        "_internal_originalTime": 1689835009000
    },
    {
        "time": 1689835010000,
        "value": 139.536,
        "_internal_originalTime": 1689835010000
    },
    {
        "time": 1689835011000,
        "value": 139.538,
        "_internal_originalTime": 1689835011000
    },
    {
        "time": 1689835013000,
        "value": 139.553,
        "_internal_originalTime": 1689835013000
    },
    {
        "time": 1689835014000,
        "value": 139.553,
        "_internal_originalTime": 1689835014000
    },
    {
        "time": 1689835015000,
        "value": 139.554,
        "_internal_originalTime": 1689835015000
    },
    {
        "time": 1689835016000,
        "value": 139.548,
        "_internal_originalTime": 1689835016000
    },
    {
        "time": 1689835017000,
        "value": 139.552,
        "_internal_originalTime": 1689835017000
    },
    {
        "time": 1689835019000,
        "value": 139.55,
        "_internal_originalTime": 1689835019000
    },
    {
        "time": 1689835020000,
        "value": 139.55,
        "_internal_originalTime": 1689835020000
    },
    {
        "time": 1689835021000,
        "value": 139.556,
        "_internal_originalTime": 1689835021000
    },
    {
        "time": 1689835022000,
        "value": 139.557,
        "_internal_originalTime": 1689835022000
    },
    {
        "time": 1689835024000,
        "value": 139.554,
        "_internal_originalTime": 1689835024000
    },
    {
        "time": 1689835025000,
        "value": 139.555,
        "_internal_originalTime": 1689835025000
    },
    {
        "time": 1689835027000,
        "value": 139.548,
        "_internal_originalTime": 1689835027000
    },
    {
        "time": 1689835028000,
        "value": 139.554,
        "_internal_originalTime": 1689835028000
    },
    {
        "time": 1689835029000,
        "value": 139.552,
        "_internal_originalTime": 1689835029000
    },
    {
        "time": 1689835030000,
        "value": 139.552,
        "_internal_originalTime": 1689835030000
    },
    {
        "time": 1689835031000,
        "value": 139.554,
        "_internal_originalTime": 1689835031000
    },
    {
        "time": 1689835033000,
        "value": 139.56,
        "_internal_originalTime": 1689835033000
    },
    {
        "time": 1689835034000,
        "value": 139.565,
        "_internal_originalTime": 1689835034000
    },
    {
        "time": 1689835035000,
        "value": 139.565,
        "_internal_originalTime": 1689835035000
    },
    {
        "time": 1689835036000,
        "value": 139.563,
        "_internal_originalTime": 1689835036000
    },
    {
        "time": 1689835038000,
        "value": 139.564,
        "_internal_originalTime": 1689835038000
    },
    {
        "time": 1689835039000,
        "value": 139.565,
        "_internal_originalTime": 1689835039000
    },
    {
        "time": 1689835040000,
        "value": 139.566,
        "_internal_originalTime": 1689835040000
    },
    {
        "time": 1689835042000,
        "value": 139.566,
        "_internal_originalTime": 1689835042000
    },
    {
        "time": 1689835044000,
        "value": 139.57,
        "_internal_originalTime": 1689835044000
    },
    {
        "time": 1689835046000,
        "value": 139.565,
        "_internal_originalTime": 1689835046000
    },
    {
        "time": 1689835047000,
        "value": 139.566,
        "_internal_originalTime": 1689835047000
    },
    {
        "time": 1689835050000,
        "value": 139.569,
        "_internal_originalTime": 1689835050000
    },
    {
        "time": 1689835051000,
        "value": 139.57,
        "_internal_originalTime": 1689835051000
    },
    {
        "time": 1689835052000,
        "value": 139.57,
        "_internal_originalTime": 1689835052000
    },
    {
        "time": 1689835053000,
        "value": 139.564,
        "_internal_originalTime": 1689835053000
    },
    {
        "time": 1689835054000,
        "value": 139.565,
        "_internal_originalTime": 1689835054000
    },
    {
        "time": 1689835055000,
        "value": 139.563,
        "_internal_originalTime": 1689835055000
    },
    {
        "time": 1689835057000,
        "value": 139.57,
        "_internal_originalTime": 1689835057000
    },
    {
        "time": 1689835058000,
        "value": 139.566,
        "_internal_originalTime": 1689835058000
    },
    {
        "time": 1689835060000,
        "value": 139.572,
        "_internal_originalTime": 1689835060000
    },
    {
        "time": 1689835062000,
        "value": 139.571,
        "_internal_originalTime": 1689835062000
    },
    {
        "time": 1689835063000,
        "value": 139.566,
        "_internal_originalTime": 1689835063000
    },
    {
        "time": 1689835065000,
        "value": 139.568,
        "_internal_originalTime": 1689835065000
    },
    {
        "time": 1689835066000,
        "value": 139.567,
        "_internal_originalTime": 1689835066000
    },
    {
        "time": 1689835067000,
        "value": 139.571,
        "_internal_originalTime": 1689835067000
    },
    {
        "time": 1689835068000,
        "value": 139.568,
        "_internal_originalTime": 1689835068000
    },
    {
        "time": 1689835069000,
        "value": 139.564,
        "_internal_originalTime": 1689835069000
    },
    {
        "time": 1689835071000,
        "value": 139.564,
        "_internal_originalTime": 1689835071000
    },
    {
        "time": 1689835072000,
        "value": 139.565,
        "_internal_originalTime": 1689835072000
    },
    {
        "time": 1689835074000,
        "value": 139.567,
        "_internal_originalTime": 1689835074000
    },
    {
        "time": 1689835075000,
        "value": 139.57,
        "_internal_originalTime": 1689835075000
    },
    {
        "time": 1689835076000,
        "value": 139.566,
        "_internal_originalTime": 1689835076000
    },
    {
        "time": 1689835077000,
        "value": 139.563,
        "_internal_originalTime": 1689835077000
    },
    {
        "time": 1689835079000,
        "value": 139.566,
        "_internal_originalTime": 1689835079000
    },
    {
        "time": 1689835080000,
        "value": 139.562,
        "_internal_originalTime": 1689835080000
    },
    {
        "time": 1689835081000,
        "value": 139.55,
        "_internal_originalTime": 1689835081000
    },
    {
        "time": 1689835082000,
        "value": 139.565,
        "_internal_originalTime": 1689835082000
    },
    {
        "time": 1689835083000,
        "value": 139.564,
        "_internal_originalTime": 1689835083000
    },
    {
        "time": 1689835085000,
        "value": 139.573,
        "_internal_originalTime": 1689835085000
    },
    {
        "time": 1689835086000,
        "value": 139.572,
        "_internal_originalTime": 1689835086000
    },
    {
        "time": 1689835087000,
        "value": 139.571,
        "_internal_originalTime": 1689835087000
    },
    {
        "time": 1689835088000,
        "value": 139.57,
        "_internal_originalTime": 1689835088000
    },
    {
        "time": 1689835089000,
        "value": 139.566,
        "_internal_originalTime": 1689835089000
    },
    {
        "time": 1689835091000,
        "value": 139.564,
        "_internal_originalTime": 1689835091000
    },
    {
        "time": 1689835092000,
        "value": 139.57,
        "_internal_originalTime": 1689835092000
    },
    {
        "time": 1689835094000,
        "value": 139.569,
        "_internal_originalTime": 1689835094000
    },
    {
        "time": 1689835095000,
        "value": 139.567,
        "_internal_originalTime": 1689835095000
    },
    {
        "time": 1689835096000,
        "value": 139.569,
        "_internal_originalTime": 1689835096000
    },
    {
        "time": 1689835097000,
        "value": 139.574,
        "_internal_originalTime": 1689835097000
    },
    {
        "time": 1689835098000,
        "value": 139.576,
        "_internal_originalTime": 1689835098000
    },
    {
        "time": 1689835100000,
        "value": 139.58,
        "_internal_originalTime": 1689835100000
    },
    {
        "time": 1689835104000,
        "value": 139.575,
        "_internal_originalTime": 1689835104000
    },
    {
        "time": 1689835106000,
        "value": 139.58,
        "_internal_originalTime": 1689835106000
    },
    {
        "time": 1689835108000,
        "value": 139.575,
        "_internal_originalTime": 1689835108000
    },
    {
        "time": 1689835110000,
        "value": 139.579,
        "_internal_originalTime": 1689835110000
    },
    {
        "time": 1689835111000,
        "value": 139.585,
        "_internal_originalTime": 1689835111000
    },
    {
        "time": 1689835112000,
        "value": 139.581,
        "_internal_originalTime": 1689835112000
    },
    {
        "time": 1689835114000,
        "value": 139.58,
        "_internal_originalTime": 1689835114000
    },
    {
        "time": 1689835115000,
        "value": 139.576,
        "_internal_originalTime": 1689835115000
    },
    {
        "time": 1689835116000,
        "value": 139.577,
        "_internal_originalTime": 1689835116000
    },
    {
        "time": 1689835117000,
        "value": 139.575,
        "_internal_originalTime": 1689835117000
    },
    {
        "time": 1689835119000,
        "value": 139.57,
        "_internal_originalTime": 1689835119000
    },
    {
        "time": 1689835120000,
        "value": 139.575,
        "_internal_originalTime": 1689835120000
    },
    {
        "time": 1689835121000,
        "value": 139.576,
        "_internal_originalTime": 1689835121000
    },
    {
        "time": 1689835122000,
        "value": 139.576,
        "_internal_originalTime": 1689835122000
    },
    {
        "time": 1689835124000,
        "value": 139.57,
        "_internal_originalTime": 1689835124000
    },
    {
        "time": 1689835125000,
        "value": 139.572,
        "_internal_originalTime": 1689835125000
    },
    {
        "time": 1689835126000,
        "value": 139.573,
        "_internal_originalTime": 1689835126000
    },
    {
        "time": 1689835127000,
        "value": 139.568,
        "_internal_originalTime": 1689835127000
    },
    {
        "time": 1689835128000,
        "value": 139.57,
        "_internal_originalTime": 1689835128000
    },
    {
        "time": 1689835129000,
        "value": 139.57,
        "_internal_originalTime": 1689835129000
    },
    {
        "time": 1689835130000,
        "value": 139.57,
        "_internal_originalTime": 1689835130000
    },
    {
        "time": 1689835131000,
        "value": 139.565,
        "_internal_originalTime": 1689835131000
    },
    {
        "time": 1689835133000,
        "value": 139.575,
        "_internal_originalTime": 1689835133000
    },
    {
        "time": 1689835134000,
        "value": 139.576,
        "_internal_originalTime": 1689835134000
    },
    {
        "time": 1689835135000,
        "value": 139.57,
        "_internal_originalTime": 1689835135000
    },
    {
        "time": 1689835136000,
        "value": 139.57,
        "_internal_originalTime": 1689835136000
    },
    {
        "time": 1689835138000,
        "value": 139.572,
        "_internal_originalTime": 1689835138000
    },
    {
        "time": 1689835139000,
        "value": 139.559,
        "_internal_originalTime": 1689835139000
    },
    {
        "time": 1689835140000,
        "value": 139.564,
        "_internal_originalTime": 1689835140000
    },
    {
        "time": 1689835141000,
        "value": 139.567,
        "_internal_originalTime": 1689835141000
    },
    {
        "time": 1689835142000,
        "value": 139.56,
        "_internal_originalTime": 1689835142000
    },
    {
        "time": 1689835143000,
        "value": 139.565,
        "_internal_originalTime": 1689835143000
    },
    {
        "time": 1689835144000,
        "value": 139.566,
        "_internal_originalTime": 1689835144000
    },
    {
        "time": 1689835145000,
        "value": 139.567,
        "_internal_originalTime": 1689835145000
    },
    {
        "time": 1689835147000,
        "value": 139.56,
        "_internal_originalTime": 1689835147000
    },
    {
        "time": 1689835149000,
        "value": 139.57,
        "_internal_originalTime": 1689835149000
    },
    {
        "time": 1689835150000,
        "value": 139.57,
        "_internal_originalTime": 1689835150000
    },
    {
        "time": 1689835151000,
        "value": 139.566,
        "_internal_originalTime": 1689835151000
    },
    {
        "time": 1689835153000,
        "value": 139.564,
        "_internal_originalTime": 1689835153000
    },
    {
        "time": 1689835154000,
        "value": 139.563,
        "_internal_originalTime": 1689835154000
    },
    {
        "time": 1689835156000,
        "value": 139.563,
        "_internal_originalTime": 1689835156000
    },
    {
        "time": 1689835158000,
        "value": 139.564,
        "_internal_originalTime": 1689835158000
    },
    {
        "time": 1689835159000,
        "value": 139.571,
        "_internal_originalTime": 1689835159000
    },
    {
        "time": 1689835160000,
        "value": 139.564,
        "_internal_originalTime": 1689835160000
    },
    {
        "time": 1689835162000,
        "value": 139.571,
        "_internal_originalTime": 1689835162000
    },
    {
        "time": 1689835163000,
        "value": 139.565,
        "_internal_originalTime": 1689835163000
    },
    {
        "time": 1689835164000,
        "value": 139.564,
        "_internal_originalTime": 1689835164000
    },
    {
        "time": 1689835166000,
        "value": 139.564,
        "_internal_originalTime": 1689835166000
    },
    {
        "time": 1689835167000,
        "value": 139.568,
        "_internal_originalTime": 1689835167000
    },
    {
        "time": 1689835168000,
        "value": 139.567,
        "_internal_originalTime": 1689835168000
    },
    {
        "time": 1689835169000,
        "value": 139.566,
        "_internal_originalTime": 1689835169000
    },
    {
        "time": 1689835171000,
        "value": 139.569,
        "_internal_originalTime": 1689835171000
    },
    {
        "time": 1689835172000,
        "value": 139.568,
        "_internal_originalTime": 1689835172000
    },
    {
        "time": 1689835174000,
        "value": 139.565,
        "_internal_originalTime": 1689835174000
    },
    {
        "time": 1689835175000,
        "value": 139.562,
        "_internal_originalTime": 1689835175000
    },
    {
        "time": 1689835177000,
        "value": 139.57,
        "_internal_originalTime": 1689835177000
    },
    {
        "time": 1689835180000,
        "value": 139.564,
        "_internal_originalTime": 1689835180000
    },
    {
        "time": 1689835182000,
        "value": 139.567,
        "_internal_originalTime": 1689835182000
    },
    {
        "time": 1689835183000,
        "value": 139.57,
        "_internal_originalTime": 1689835183000
    },
    {
        "time": 1689835184000,
        "value": 139.57,
        "_internal_originalTime": 1689835184000
    },
    {
        "time": 1689835185000,
        "value": 139.564,
        "_internal_originalTime": 1689835185000
    },
    {
        "time": 1689835186000,
        "value": 139.562,
        "_internal_originalTime": 1689835186000
    },
    {
        "time": 1689835188000,
        "value": 139.569,
        "_internal_originalTime": 1689835188000
    },
    {
        "time": 1689835191000,
        "value": 139.57,
        "_internal_originalTime": 1689835191000
    },
    {
        "time": 1689835193000,
        "value": 139.562,
        "_internal_originalTime": 1689835193000
    },
    {
        "time": 1689835194000,
        "value": 139.564,
        "_internal_originalTime": 1689835194000
    },
    {
        "time": 1689835195000,
        "value": 139.57,
        "_internal_originalTime": 1689835195000
    },
    {
        "time": 1689835197000,
        "value": 139.565,
        "_internal_originalTime": 1689835197000
    },
    {
        "time": 1689835198000,
        "value": 139.564,
        "_internal_originalTime": 1689835198000
    },
    {
        "time": 1689835199000,
        "value": 139.565,
        "_internal_originalTime": 1689835199000
    },
    {
        "time": 1689835200000,
        "value": 139.562,
        "_internal_originalTime": 1689835200000
    },
    {
        "time": 1689835201000,
        "value": 139.562,
        "_internal_originalTime": 1689835201000
    },
    {
        "time": 1689835205000,
        "value": 139.562,
        "_internal_originalTime": 1689835205000
    },
    {
        "time": 1689835207000,
        "value": 139.563,
        "_internal_originalTime": 1689835207000
    },
    {
        "time": 1689835209000,
        "value": 139.56,
        "_internal_originalTime": 1689835209000
    },
    {
        "time": 1689835210000,
        "value": 139.562,
        "_internal_originalTime": 1689835210000
    },
    {
        "time": 1689835211000,
        "value": 139.562,
        "_internal_originalTime": 1689835211000
    },
    {
        "time": 1689835214000,
        "value": 139.559,
        "_internal_originalTime": 1689835214000
    },
    {
        "time": 1689835216000,
        "value": 139.555,
        "_internal_originalTime": 1689835216000
    },
    {
        "time": 1689835217000,
        "value": 139.55,
        "_internal_originalTime": 1689835217000
    },
    {
        "time": 1689835219000,
        "value": 139.556,
        "_internal_originalTime": 1689835219000
    },
    {
        "time": 1689835220000,
        "value": 139.556,
        "_internal_originalTime": 1689835220000
    },
    {
        "time": 1689835222000,
        "value": 139.555,
        "_internal_originalTime": 1689835222000
    },
    {
        "time": 1689835223000,
        "value": 139.555,
        "_internal_originalTime": 1689835223000
    },
    {
        "time": 1689835225000,
        "value": 139.551,
        "_internal_originalTime": 1689835225000
    },
    {
        "time": 1689835226000,
        "value": 139.547,
        "_internal_originalTime": 1689835226000
    },
    {
        "time": 1689835228000,
        "value": 139.553,
        "_internal_originalTime": 1689835228000
    },
    {
        "time": 1689835229000,
        "value": 139.548,
        "_internal_originalTime": 1689835229000
    },
    {
        "time": 1689835230000,
        "value": 139.547,
        "_internal_originalTime": 1689835230000
    },
    {
        "time": 1689835231000,
        "value": 139.548,
        "_internal_originalTime": 1689835231000
    },
    {
        "time": 1689835233000,
        "value": 139.546,
        "_internal_originalTime": 1689835233000
    },
    {
        "time": 1689835234000,
        "value": 139.547,
        "_internal_originalTime": 1689835234000
    },
    {
        "time": 1689835235000,
        "value": 139.552,
        "_internal_originalTime": 1689835235000
    },
    {
        "time": 1689835236000,
        "value": 139.546,
        "_internal_originalTime": 1689835236000
    },
    {
        "time": 1689835237000,
        "value": 139.552,
        "_internal_originalTime": 1689835237000
    },
    {
        "time": 1689835239000,
        "value": 139.548,
        "_internal_originalTime": 1689835239000
    },
    {
        "time": 1689835241000,
        "value": 139.556,
        "_internal_originalTime": 1689835241000
    },
    {
        "time": 1689835243000,
        "value": 139.559,
        "_internal_originalTime": 1689835243000
    },
    {
        "time": 1689835244000,
        "value": 139.563,
        "_internal_originalTime": 1689835244000
    },
    {
        "time": 1689835246000,
        "value": 139.559,
        "_internal_originalTime": 1689835246000
    },
    {
        "time": 1689835247000,
        "value": 139.552,
        "_internal_originalTime": 1689835247000
    },
    {
        "time": 1689835249000,
        "value": 139.543,
        "_internal_originalTime": 1689835249000
    },
    {
        "time": 1689835251000,
        "value": 139.553,
        "_internal_originalTime": 1689835251000
    },
    {
        "time": 1689835252000,
        "value": 139.551,
        "_internal_originalTime": 1689835252000
    },
    {
        "time": 1689835253000,
        "value": 139.555,
        "_internal_originalTime": 1689835253000
    },
    {
        "time": 1689835255000,
        "value": 139.551,
        "_internal_originalTime": 1689835255000
    },
    {
        "time": 1689835257000,
        "value": 139.553,
        "_internal_originalTime": 1689835257000
    },
    {
        "time": 1689835258000,
        "value": 139.551,
        "_internal_originalTime": 1689835258000
    },
    {
        "time": 1689835259000,
        "value": 139.552,
        "_internal_originalTime": 1689835259000
    },
    {
        "time": 1689835261000,
        "value": 139.552,
        "_internal_originalTime": 1689835261000
    },
    {
        "time": 1689835262000,
        "value": 139.559,
        "_internal_originalTime": 1689835262000
    },
    {
        "time": 1689835263000,
        "value": 139.554,
        "_internal_originalTime": 1689835263000
    },
    {
        "time": 1689835264000,
        "value": 139.548,
        "_internal_originalTime": 1689835264000
    },
    {
        "time": 1689835265000,
        "value": 139.549,
        "_internal_originalTime": 1689835265000
    },
    {
        "time": 1689835267000,
        "value": 139.551,
        "_internal_originalTime": 1689835267000
    },
    {
        "time": 1689835268000,
        "value": 139.549,
        "_internal_originalTime": 1689835268000
    },
    {
        "time": 1689835269000,
        "value": 139.552,
        "_internal_originalTime": 1689835269000
    },
    {
        "time": 1689835270000,
        "value": 139.559,
        "_internal_originalTime": 1689835270000
    },
    {
        "time": 1689835271000,
        "value": 139.557,
        "_internal_originalTime": 1689835271000
    },
    {
        "time": 1689835272000,
        "value": 139.559,
        "_internal_originalTime": 1689835272000
    },
    {
        "time": 1689835273000,
        "value": 139.559,
        "_internal_originalTime": 1689835273000
    },
    {
        "time": 1689835276000,
        "value": 139.561,
        "_internal_originalTime": 1689835276000
    },
    {
        "time": 1689835278000,
        "value": 139.56,
        "_internal_originalTime": 1689835278000
    },
    {
        "time": 1689835279000,
        "value": 139.554,
        "_internal_originalTime": 1689835279000
    },
    {
        "time": 1689835280000,
        "value": 139.556,
        "_internal_originalTime": 1689835280000
    },
    {
        "time": 1689835281000,
        "value": 139.572,
        "_internal_originalTime": 1689835281000
    },
    {
        "time": 1689835282000,
        "value": 139.57,
        "_internal_originalTime": 1689835282000
    },
    {
        "time": 1689835284000,
        "value": 139.57,
        "_internal_originalTime": 1689835284000
    },
    {
        "time": 1689835286000,
        "value": 139.579,
        "_internal_originalTime": 1689835286000
    },
    {
        "time": 1689835288000,
        "value": 139.577,
        "_internal_originalTime": 1689835288000
    },
    {
        "time": 1689835290000,
        "value": 139.572,
        "_internal_originalTime": 1689835290000
    },
    {
        "time": 1689835291000,
        "value": 139.579,
        "_internal_originalTime": 1689835291000
    },
    {
        "time": 1689835292000,
        "value": 139.578,
        "_internal_originalTime": 1689835292000
    },
    {
        "time": 1689835294000,
        "value": 139.578,
        "_internal_originalTime": 1689835294000
    },
    {
        "time": 1689835295000,
        "value": 139.573,
        "_internal_originalTime": 1689835295000
    },
    {
        "time": 1689835296000,
        "value": 139.579,
        "_internal_originalTime": 1689835296000
    },
    {
        "time": 1689835297000,
        "value": 139.578,
        "_internal_originalTime": 1689835297000
    },
    {
        "time": 1689835299000,
        "value": 139.579,
        "_internal_originalTime": 1689835299000
    },
    {
        "time": 1689835300000,
        "value": 139.578,
        "_internal_originalTime": 1689835300000
    },
    {
        "time": 1689835301000,
        "value": 139.585,
        "_internal_originalTime": 1689835301000
    },
    {
        "time": 1689835303000,
        "value": 139.59,
        "_internal_originalTime": 1689835303000
    },
    {
        "time": 1689835304000,
        "value": 139.59,
        "_internal_originalTime": 1689835304000
    },
    {
        "time": 1689835306000,
        "value": 139.583,
        "_internal_originalTime": 1689835306000
    },
    {
        "time": 1689835307000,
        "value": 139.588,
        "_internal_originalTime": 1689835307000
    },
    {
        "time": 1689835308000,
        "value": 139.587,
        "_internal_originalTime": 1689835308000
    },
    {
        "time": 1689835310000,
        "value": 139.591,
        "_internal_originalTime": 1689835310000
    },
    {
        "time": 1689835311000,
        "value": 139.588,
        "_internal_originalTime": 1689835311000
    },
    {
        "time": 1689835312000,
        "value": 139.582,
        "_internal_originalTime": 1689835312000
    },
    {
        "time": 1689835313000,
        "value": 139.583,
        "_internal_originalTime": 1689835313000
    },
    {
        "time": 1689835314000,
        "value": 139.583,
        "_internal_originalTime": 1689835314000
    },
    {
        "time": 1689835316000,
        "value": 139.577,
        "_internal_originalTime": 1689835316000
    },
    {
        "time": 1689835317000,
        "value": 139.583,
        "_internal_originalTime": 1689835317000
    },
    {
        "time": 1689835319000,
        "value": 139.585,
        "_internal_originalTime": 1689835319000
    },
    {
        "time": 1689835320000,
        "value": 139.585,
        "_internal_originalTime": 1689835320000
    },
    {
        "time": 1689835321000,
        "value": 139.56,
        "_internal_originalTime": 1689835321000
    },
    {
        "time": 1689835322000,
        "value": 139.584,
        "_internal_originalTime": 1689835322000
    },
    {
        "time": 1689835324000,
        "value": 139.584,
        "_internal_originalTime": 1689835324000
    },
    {
        "time": 1689835326000,
        "value": 139.576,
        "_internal_originalTime": 1689835326000
    },
    {
        "time": 1689835327000,
        "value": 139.584,
        "_internal_originalTime": 1689835327000
    },
    {
        "time": 1689835329000,
        "value": 139.584,
        "_internal_originalTime": 1689835329000
    },
    {
        "time": 1689835330000,
        "value": 139.587,
        "_internal_originalTime": 1689835330000
    },
    {
        "time": 1689835331000,
        "value": 139.586,
        "_internal_originalTime": 1689835331000
    },
    {
        "time": 1689835332000,
        "value": 139.58,
        "_internal_originalTime": 1689835332000
    },
    {
        "time": 1689835334000,
        "value": 139.579,
        "_internal_originalTime": 1689835334000
    },
    {
        "time": 1689835335000,
        "value": 139.582,
        "_internal_originalTime": 1689835335000
    },
    {
        "time": 1689835337000,
        "value": 139.576,
        "_internal_originalTime": 1689835337000
    },
    {
        "time": 1689835338000,
        "value": 139.582,
        "_internal_originalTime": 1689835338000
    },
    {
        "time": 1689835339000,
        "value": 139.577,
        "_internal_originalTime": 1689835339000
    },
    {
        "time": 1689835340000,
        "value": 139.583,
        "_internal_originalTime": 1689835340000
    },
    {
        "time": 1689835341000,
        "value": 139.577,
        "_internal_originalTime": 1689835341000
    },
    {
        "time": 1689835342000,
        "value": 139.576,
        "_internal_originalTime": 1689835342000
    },
    {
        "time": 1689835344000,
        "value": 139.577,
        "_internal_originalTime": 1689835344000
    },
    {
        "time": 1689835345000,
        "value": 139.589,
        "_internal_originalTime": 1689835345000
    },
    {
        "time": 1689835346000,
        "value": 139.59,
        "_internal_originalTime": 1689835346000
    },
    {
        "time": 1689835347000,
        "value": 139.59,
        "_internal_originalTime": 1689835347000
    },
    {
        "time": 1689835348000,
        "value": 139.591,
        "_internal_originalTime": 1689835348000
    },
    {
        "time": 1689835349000,
        "value": 139.586,
        "_internal_originalTime": 1689835349000
    },
    {
        "time": 1689835351000,
        "value": 139.587,
        "_internal_originalTime": 1689835351000
    },
    {
        "time": 1689835352000,
        "value": 139.588,
        "_internal_originalTime": 1689835352000
    },
    {
        "time": 1689835353000,
        "value": 139.592,
        "_internal_originalTime": 1689835353000
    },
    {
        "time": 1689835355000,
        "value": 139.586,
        "_internal_originalTime": 1689835355000
    },
    {
        "time": 1689835357000,
        "value": 139.588,
        "_internal_originalTime": 1689835357000
    },
    {
        "time": 1689835358000,
        "value": 139.597,
        "_internal_originalTime": 1689835358000
    },
    {
        "time": 1689835359000,
        "value": 139.592,
        "_internal_originalTime": 1689835359000
    },
    {
        "time": 1689835361000,
        "value": 139.594,
        "_internal_originalTime": 1689835361000
    },
    {
        "time": 1689835364000,
        "value": 139.596,
        "_internal_originalTime": 1689835364000
    },
    {
        "time": 1689835366000,
        "value": 139.593,
        "_internal_originalTime": 1689835366000
    },
    {
        "time": 1689835367000,
        "value": 139.592,
        "_internal_originalTime": 1689835367000
    },
    {
        "time": 1689835368000,
        "value": 139.592,
        "_internal_originalTime": 1689835368000
    },
    {
        "time": 1689835369000,
        "value": 139.592,
        "_internal_originalTime": 1689835369000
    },
    {
        "time": 1689835370000,
        "value": 139.592,
        "_internal_originalTime": 1689835370000
    },
    {
        "time": 1689835372000,
        "value": 139.592,
        "_internal_originalTime": 1689835372000
    },
    {
        "time": 1689835375000,
        "value": 139.592,
        "_internal_originalTime": 1689835375000
    },
    {
        "time": 1689835377000,
        "value": 139.597,
        "_internal_originalTime": 1689835377000
    },
    {
        "time": 1689835378000,
        "value": 139.593,
        "_internal_originalTime": 1689835378000
    },
    {
        "time": 1689835381000,
        "value": 139.57,
        "_internal_originalTime": 1689835381000
    },
    {
        "time": 1689835383000,
        "value": 139.592,
        "_internal_originalTime": 1689835383000
    },
    {
        "time": 1689835385000,
        "value": 139.609,
        "_internal_originalTime": 1689835385000
    },
    {
        "time": 1689835386000,
        "value": 139.61,
        "_internal_originalTime": 1689835386000
    },
    {
        "time": 1689835387000,
        "value": 139.608,
        "_internal_originalTime": 1689835387000
    },
    {
        "time": 1689835388000,
        "value": 139.594,
        "_internal_originalTime": 1689835388000
    },
    {
        "time": 1689835389000,
        "value": 139.608,
        "_internal_originalTime": 1689835389000
    },
    {
        "time": 1689835391000,
        "value": 139.604,
        "_internal_originalTime": 1689835391000
    },
    {
        "time": 1689835392000,
        "value": 139.602,
        "_internal_originalTime": 1689835392000
    },
    {
        "time": 1689835393000,
        "value": 139.597,
        "_internal_originalTime": 1689835393000
    },
    {
        "time": 1689835394000,
        "value": 139.596,
        "_internal_originalTime": 1689835394000
    },
    {
        "time": 1689835395000,
        "value": 139.597,
        "_internal_originalTime": 1689835395000
    },
    {
        "time": 1689835396000,
        "value": 139.596,
        "_internal_originalTime": 1689835396000
    },
    {
        "time": 1689835398000,
        "value": 139.591,
        "_internal_originalTime": 1689835398000
    },
    {
        "time": 1689835399000,
        "value": 139.598,
        "_internal_originalTime": 1689835399000
    },
    {
        "time": 1689835401000,
        "value": 139.601,
        "_internal_originalTime": 1689835401000
    },
    {
        "time": 1689835402000,
        "value": 139.599,
        "_internal_originalTime": 1689835402000
    },
    {
        "time": 1689835403000,
        "value": 139.6,
        "_internal_originalTime": 1689835403000
    },
    {
        "time": 1689835404000,
        "value": 139.597,
        "_internal_originalTime": 1689835404000
    },
    {
        "time": 1689835405000,
        "value": 139.596,
        "_internal_originalTime": 1689835405000
    },
    {
        "time": 1689835407000,
        "value": 139.61,
        "_internal_originalTime": 1689835407000
    },
    {
        "time": 1689835408000,
        "value": 139.606,
        "_internal_originalTime": 1689835408000
    },
    {
        "time": 1689835410000,
        "value": 139.61,
        "_internal_originalTime": 1689835410000
    },
    {
        "time": 1689835411000,
        "value": 139.608,
        "_internal_originalTime": 1689835411000
    },
    {
        "time": 1689835413000,
        "value": 139.61,
        "_internal_originalTime": 1689835413000
    },
    {
        "time": 1689835414000,
        "value": 139.61,
        "_internal_originalTime": 1689835414000
    },
    {
        "time": 1689835415000,
        "value": 139.615,
        "_internal_originalTime": 1689835415000
    },
    {
        "time": 1689835417000,
        "value": 139.611,
        "_internal_originalTime": 1689835417000
    },
    {
        "time": 1689835419000,
        "value": 139.609,
        "_internal_originalTime": 1689835419000
    },
    {
        "time": 1689835420000,
        "value": 139.619,
        "_internal_originalTime": 1689835420000
    },
    {
        "time": 1689835421000,
        "value": 139.615,
        "_internal_originalTime": 1689835421000
    },
    {
        "time": 1689835423000,
        "value": 139.62,
        "_internal_originalTime": 1689835423000
    },
    {
        "time": 1689835425000,
        "value": 139.616,
        "_internal_originalTime": 1689835425000
    },
    {
        "time": 1689835427000,
        "value": 139.623,
        "_internal_originalTime": 1689835427000
    },
    {
        "time": 1689835428000,
        "value": 139.63,
        "_internal_originalTime": 1689835428000
    },
    {
        "time": 1689835429000,
        "value": 139.631,
        "_internal_originalTime": 1689835429000
    },
    {
        "time": 1689835430000,
        "value": 139.632,
        "_internal_originalTime": 1689835430000
    },
    {
        "time": 1689835431000,
        "value": 139.631,
        "_internal_originalTime": 1689835431000
    },
    {
        "time": 1689835432000,
        "value": 139.624,
        "_internal_originalTime": 1689835432000
    },
    {
        "time": 1689835434000,
        "value": 139.625,
        "_internal_originalTime": 1689835434000
    },
    {
        "time": 1689835435000,
        "value": 139.624,
        "_internal_originalTime": 1689835435000
    },
    {
        "time": 1689835436000,
        "value": 139.632,
        "_internal_originalTime": 1689835436000
    },
    {
        "time": 1689835437000,
        "value": 139.626,
        "_internal_originalTime": 1689835437000
    },
    {
        "time": 1689835438000,
        "value": 139.628,
        "_internal_originalTime": 1689835438000
    },
    {
        "time": 1689835440000,
        "value": 139.631,
        "_internal_originalTime": 1689835440000
    },
    {
        "time": 1689835441000,
        "value": 139.625,
        "_internal_originalTime": 1689835441000
    },
    {
        "time": 1689835443000,
        "value": 139.627,
        "_internal_originalTime": 1689835443000
    },
    {
        "time": 1689835445000,
        "value": 139.616,
        "_internal_originalTime": 1689835445000
    },
    {
        "time": 1689835447000,
        "value": 139.627,
        "_internal_originalTime": 1689835447000
    },
    {
        "time": 1689835448000,
        "value": 139.627,
        "_internal_originalTime": 1689835448000
    },
    {
        "time": 1689835450000,
        "value": 139.622,
        "_internal_originalTime": 1689835450000
    },
    {
        "time": 1689835452000,
        "value": 139.624,
        "_internal_originalTime": 1689835452000
    },
    {
        "time": 1689835453000,
        "value": 139.628,
        "_internal_originalTime": 1689835453000
    },
    {
        "time": 1689835455000,
        "value": 139.624,
        "_internal_originalTime": 1689835455000
    },
    {
        "time": 1689835456000,
        "value": 139.629,
        "_internal_originalTime": 1689835456000
    },
    {
        "time": 1689835457000,
        "value": 139.629,
        "_internal_originalTime": 1689835457000
    },
    {
        "time": 1689835459000,
        "value": 139.629,
        "_internal_originalTime": 1689835459000
    },
    {
        "time": 1689835461000,
        "value": 139.625,
        "_internal_originalTime": 1689835461000
    },
    {
        "time": 1689835462000,
        "value": 139.624,
        "_internal_originalTime": 1689835462000
    },
    {
        "time": 1689835463000,
        "value": 139.63,
        "_internal_originalTime": 1689835463000
    },
    {
        "time": 1689835465000,
        "value": 139.628,
        "_internal_originalTime": 1689835465000
    },
    {
        "time": 1689835467000,
        "value": 139.623,
        "_internal_originalTime": 1689835467000
    },
    {
        "time": 1689835469000,
        "value": 139.62,
        "_internal_originalTime": 1689835469000
    },
    {
        "time": 1689835471000,
        "value": 139.622,
        "_internal_originalTime": 1689835471000
    },
    {
        "time": 1689835472000,
        "value": 139.621,
        "_internal_originalTime": 1689835472000
    },
    {
        "time": 1689835474000,
        "value": 139.615,
        "_internal_originalTime": 1689835474000
    },
    {
        "time": 1689835476000,
        "value": 139.618,
        "_internal_originalTime": 1689835476000
    },
    {
        "time": 1689835477000,
        "value": 139.617,
        "_internal_originalTime": 1689835477000
    },
    {
        "time": 1689835478000,
        "value": 139.612,
        "_internal_originalTime": 1689835478000
    },
    {
        "time": 1689835480000,
        "value": 139.613,
        "_internal_originalTime": 1689835480000
    },
    {
        "time": 1689835481000,
        "value": 139.615,
        "_internal_originalTime": 1689835481000
    },
    {
        "time": 1689835482000,
        "value": 139.618,
        "_internal_originalTime": 1689835482000
    },
    {
        "time": 1689835483000,
        "value": 139.612,
        "_internal_originalTime": 1689835483000
    },
    {
        "time": 1689835484000,
        "value": 139.618,
        "_internal_originalTime": 1689835484000
    },
    {
        "time": 1689835485000,
        "value": 139.612,
        "_internal_originalTime": 1689835485000
    },
    {
        "time": 1689835486000,
        "value": 139.614,
        "_internal_originalTime": 1689835486000
    },
    {
        "time": 1689835488000,
        "value": 139.614,
        "_internal_originalTime": 1689835488000
    },
    {
        "time": 1689835490000,
        "value": 139.616,
        "_internal_originalTime": 1689835490000
    },
    {
        "time": 1689835491000,
        "value": 139.629,
        "_internal_originalTime": 1689835491000
    },
    {
        "time": 1689835492000,
        "value": 139.626,
        "_internal_originalTime": 1689835492000
    },
    {
        "time": 1689835494000,
        "value": 139.624,
        "_internal_originalTime": 1689835494000
    },
    {
        "time": 1689835495000,
        "value": 139.624,
        "_internal_originalTime": 1689835495000
    },
    {
        "time": 1689835496000,
        "value": 139.628,
        "_internal_originalTime": 1689835496000
    },
    {
        "time": 1689835498000,
        "value": 139.624,
        "_internal_originalTime": 1689835498000
    },
    {
        "time": 1689835499000,
        "value": 139.628,
        "_internal_originalTime": 1689835499000
    },
    {
        "time": 1689835500000,
        "value": 139.629,
        "_internal_originalTime": 1689835500000
    },
    {
        "time": 1689835501000,
        "value": 139.628,
        "_internal_originalTime": 1689835501000
    },
    {
        "time": 1689835503000,
        "value": 139.621,
        "_internal_originalTime": 1689835503000
    },
    {
        "time": 1689835504000,
        "value": 139.621,
        "_internal_originalTime": 1689835504000
    },
    {
        "time": 1689835505000,
        "value": 139.611,
        "_internal_originalTime": 1689835505000
    },
    {
        "time": 1689835506000,
        "value": 139.62,
        "_internal_originalTime": 1689835506000
    },
    {
        "time": 1689835507000,
        "value": 139.616,
        "_internal_originalTime": 1689835507000
    },
    {
        "time": 1689835509000,
        "value": 139.623,
        "_internal_originalTime": 1689835509000
    },
    {
        "time": 1689835510000,
        "value": 139.62,
        "_internal_originalTime": 1689835510000
    },
    {
        "time": 1689835511000,
        "value": 139.617,
        "_internal_originalTime": 1689835511000
    },
    {
        "time": 1689835512000,
        "value": 139.612,
        "_internal_originalTime": 1689835512000
    },
    {
        "time": 1689835513000,
        "value": 139.612,
        "_internal_originalTime": 1689835513000
    },
    {
        "time": 1689835514000,
        "value": 139.607,
        "_internal_originalTime": 1689835514000
    },
    {
        "time": 1689835515000,
        "value": 139.612,
        "_internal_originalTime": 1689835515000
    },
    {
        "time": 1689835516000,
        "value": 139.601,
        "_internal_originalTime": 1689835516000
    },
    {
        "time": 1689835518000,
        "value": 139.601,
        "_internal_originalTime": 1689835518000
    },
    {
        "time": 1689835519000,
        "value": 139.606,
        "_internal_originalTime": 1689835519000
    },
    {
        "time": 1689835521000,
        "value": 139.605,
        "_internal_originalTime": 1689835521000
    },
    {
        "time": 1689835522000,
        "value": 139.599,
        "_internal_originalTime": 1689835522000
    },
    {
        "time": 1689835523000,
        "value": 139.599,
        "_internal_originalTime": 1689835523000
    },
    {
        "time": 1689835524000,
        "value": 139.588,
        "_internal_originalTime": 1689835524000
    },
    {
        "time": 1689835525000,
        "value": 139.589,
        "_internal_originalTime": 1689835525000
    },
    {
        "time": 1689835527000,
        "value": 139.595,
        "_internal_originalTime": 1689835527000
    },
    {
        "time": 1689835528000,
        "value": 139.593,
        "_internal_originalTime": 1689835528000
    },
    {
        "time": 1689835529000,
        "value": 139.594,
        "_internal_originalTime": 1689835529000
    },
    {
        "time": 1689835530000,
        "value": 139.59,
        "_internal_originalTime": 1689835530000
    },
    {
        "time": 1689835531000,
        "value": 139.59,
        "_internal_originalTime": 1689835531000
    },
    {
        "time": 1689835532000,
        "value": 139.594,
        "_internal_originalTime": 1689835532000
    },
    {
        "time": 1689835534000,
        "value": 139.597,
        "_internal_originalTime": 1689835534000
    },
    {
        "time": 1689835536000,
        "value": 139.593,
        "_internal_originalTime": 1689835536000
    },
    {
        "time": 1689835537000,
        "value": 139.599,
        "_internal_originalTime": 1689835537000
    },
    {
        "time": 1689835538000,
        "value": 139.596,
        "_internal_originalTime": 1689835538000
    },
    {
        "time": 1689835540000,
        "value": 139.604,
        "_internal_originalTime": 1689835540000
    },
    {
        "time": 1689835541000,
        "value": 139.613,
        "_internal_originalTime": 1689835541000
    },
    {
        "time": 1689835543000,
        "value": 139.618,
        "_internal_originalTime": 1689835543000
    },
    {
        "time": 1689835544000,
        "value": 139.613,
        "_internal_originalTime": 1689835544000
    },
    {
        "time": 1689835545000,
        "value": 139.614,
        "_internal_originalTime": 1689835545000
    },
    {
        "time": 1689835546000,
        "value": 139.61,
        "_internal_originalTime": 1689835546000
    },
    {
        "time": 1689835547000,
        "value": 139.615,
        "_internal_originalTime": 1689835547000
    },
    {
        "time": 1689835549000,
        "value": 139.613,
        "_internal_originalTime": 1689835549000
    },
    {
        "time": 1689835551000,
        "value": 139.622,
        "_internal_originalTime": 1689835551000
    },
    {
        "time": 1689835552000,
        "value": 139.618,
        "_internal_originalTime": 1689835552000
    },
    {
        "time": 1689835555000,
        "value": 139.607,
        "_internal_originalTime": 1689835555000
    },
    {
        "time": 1689835556000,
        "value": 139.612,
        "_internal_originalTime": 1689835556000
    },
    {
        "time": 1689835557000,
        "value": 139.612,
        "_internal_originalTime": 1689835557000
    },
    {
        "time": 1689835559000,
        "value": 139.607,
        "_internal_originalTime": 1689835559000
    },
    {
        "time": 1689835560000,
        "value": 139.612,
        "_internal_originalTime": 1689835560000
    },
    {
        "time": 1689835561000,
        "value": 139.607,
        "_internal_originalTime": 1689835561000
    },
    {
        "time": 1689835562000,
        "value": 139.613,
        "_internal_originalTime": 1689835562000
    },
    {
        "time": 1689835564000,
        "value": 139.606,
        "_internal_originalTime": 1689835564000
    },
    {
        "time": 1689835566000,
        "value": 139.596,
        "_internal_originalTime": 1689835566000
    },
    {
        "time": 1689835567000,
        "value": 139.596,
        "_internal_originalTime": 1689835567000
    },
    {
        "time": 1689835568000,
        "value": 139.601,
        "_internal_originalTime": 1689835568000
    },
    {
        "time": 1689835569000,
        "value": 139.596,
        "_internal_originalTime": 1689835569000
    },
    {
        "time": 1689835570000,
        "value": 139.606,
        "_internal_originalTime": 1689835570000
    },
    {
        "time": 1689835571000,
        "value": 139.606,
        "_internal_originalTime": 1689835571000
    },
    {
        "time": 1689835572000,
        "value": 139.606,
        "_internal_originalTime": 1689835572000
    },
    {
        "time": 1689835573000,
        "value": 139.6,
        "_internal_originalTime": 1689835573000
    },
    {
        "time": 1689835574000,
        "value": 139.6,
        "_internal_originalTime": 1689835574000
    },
    {
        "time": 1689835576000,
        "value": 139.602,
        "_internal_originalTime": 1689835576000
    },
    {
        "time": 1689835577000,
        "value": 139.608,
        "_internal_originalTime": 1689835577000
    },
    {
        "time": 1689835578000,
        "value": 139.596,
        "_internal_originalTime": 1689835578000
    },
    {
        "time": 1689835580000,
        "value": 139.602,
        "_internal_originalTime": 1689835580000
    },
    {
        "time": 1689835582000,
        "value": 139.597,
        "_internal_originalTime": 1689835582000
    },
    {
        "time": 1689835583000,
        "value": 139.612,
        "_internal_originalTime": 1689835583000
    },
    {
        "time": 1689835584000,
        "value": 139.609,
        "_internal_originalTime": 1689835584000
    },
    {
        "time": 1689835585000,
        "value": 139.609,
        "_internal_originalTime": 1689835585000
    },
    {
        "time": 1689835586000,
        "value": 139.609,
        "_internal_originalTime": 1689835586000
    },
    {
        "time": 1689835587000,
        "value": 139.609,
        "_internal_originalTime": 1689835587000
    },
    {
        "time": 1689835588000,
        "value": 139.609,
        "_internal_originalTime": 1689835588000
    },
    {
        "time": 1689835589000,
        "value": 139.609,
        "_internal_originalTime": 1689835589000
    },
    {
        "time": 1689835590000,
        "value": 139.604,
        "_internal_originalTime": 1689835590000
    },
    {
        "time": 1689835592000,
        "value": 139.604,
        "_internal_originalTime": 1689835592000
    },
    {
        "time": 1689835595000,
        "value": 139.609,
        "_internal_originalTime": 1689835595000
    },
    {
        "time": 1689835597000,
        "value": 139.609,
        "_internal_originalTime": 1689835597000
    },
    {
        "time": 1689835598000,
        "value": 139.604,
        "_internal_originalTime": 1689835598000
    },
    {
        "time": 1689835599000,
        "value": 139.604,
        "_internal_originalTime": 1689835599000
    },
    {
        "time": 1689835600000,
        "value": 139.613,
        "_internal_originalTime": 1689835600000
    },
    {
        "time": 1689835602000,
        "value": 139.607,
        "_internal_originalTime": 1689835602000
    },
    {
        "time": 1689835603000,
        "value": 139.606,
        "_internal_originalTime": 1689835603000
    },
    {
        "time": 1689835604000,
        "value": 139.606,
        "_internal_originalTime": 1689835604000
    },
    {
        "time": 1689835605000,
        "value": 139.606,
        "_internal_originalTime": 1689835605000
    },
    {
        "time": 1689835606000,
        "value": 139.606,
        "_internal_originalTime": 1689835606000
    },
    {
        "time": 1689835608000,
        "value": 139.606,
        "_internal_originalTime": 1689835608000
    },
    {
        "time": 1689835609000,
        "value": 139.61,
        "_internal_originalTime": 1689835609000
    },
    {
        "time": 1689835610000,
        "value": 139.611,
        "_internal_originalTime": 1689835610000
    },
    {
        "time": 1689835611000,
        "value": 139.611,
        "_internal_originalTime": 1689835611000
    },
    {
        "time": 1689835612000,
        "value": 139.611,
        "_internal_originalTime": 1689835612000
    },
    {
        "time": 1689835613000,
        "value": 139.61,
        "_internal_originalTime": 1689835613000
    },
    {
        "time": 1689835615000,
        "value": 139.605,
        "_internal_originalTime": 1689835615000
    },
    {
        "time": 1689835616000,
        "value": 139.604,
        "_internal_originalTime": 1689835616000
    },
    {
        "time": 1689835617000,
        "value": 139.606,
        "_internal_originalTime": 1689835617000
    },
    {
        "time": 1689835618000,
        "value": 139.61,
        "_internal_originalTime": 1689835618000
    },
    {
        "time": 1689835619000,
        "value": 139.605,
        "_internal_originalTime": 1689835619000
    },
    {
        "time": 1689835620000,
        "value": 139.604,
        "_internal_originalTime": 1689835620000
    },
    {
        "time": 1689835621000,
        "value": 139.6,
        "_internal_originalTime": 1689835621000
    },
    {
        "time": 1689835622000,
        "value": 139.604,
        "_internal_originalTime": 1689835622000
    },
    {
        "time": 1689835623000,
        "value": 139.606,
        "_internal_originalTime": 1689835623000
    },
    {
        "time": 1689835624000,
        "value": 139.606,
        "_internal_originalTime": 1689835624000
    },
    {
        "time": 1689835627000,
        "value": 139.611,
        "_internal_originalTime": 1689835627000
    },
    {
        "time": 1689835628000,
        "value": 139.606,
        "_internal_originalTime": 1689835628000
    },
    {
        "time": 1689835630000,
        "value": 139.612,
        "_internal_originalTime": 1689835630000
    },
    {
        "time": 1689835631000,
        "value": 139.606,
        "_internal_originalTime": 1689835631000
    },
    {
        "time": 1689835633000,
        "value": 139.606,
        "_internal_originalTime": 1689835633000
    },
    {
        "time": 1689835634000,
        "value": 139.606,
        "_internal_originalTime": 1689835634000
    },
    {
        "time": 1689835636000,
        "value": 139.606,
        "_internal_originalTime": 1689835636000
    },
    {
        "time": 1689835638000,
        "value": 139.613,
        "_internal_originalTime": 1689835638000
    },
    {
        "time": 1689835639000,
        "value": 139.613,
        "_internal_originalTime": 1689835639000
    },
    {
        "time": 1689835640000,
        "value": 139.612,
        "_internal_originalTime": 1689835640000
    },
    {
        "time": 1689835642000,
        "value": 139.611,
        "_internal_originalTime": 1689835642000
    },
    {
        "time": 1689835643000,
        "value": 139.605,
        "_internal_originalTime": 1689835643000
    },
    {
        "time": 1689835644000,
        "value": 139.604,
        "_internal_originalTime": 1689835644000
    },
    {
        "time": 1689835646000,
        "value": 139.611,
        "_internal_originalTime": 1689835646000
    },
    {
        "time": 1689835648000,
        "value": 139.606,
        "_internal_originalTime": 1689835648000
    },
    {
        "time": 1689835649000,
        "value": 139.604,
        "_internal_originalTime": 1689835649000
    },
    {
        "time": 1689835651000,
        "value": 139.604,
        "_internal_originalTime": 1689835651000
    },
    {
        "time": 1689835652000,
        "value": 139.604,
        "_internal_originalTime": 1689835652000
    },
    {
        "time": 1689835654000,
        "value": 139.611,
        "_internal_originalTime": 1689835654000
    },
    {
        "time": 1689835655000,
        "value": 139.61,
        "_internal_originalTime": 1689835655000
    },
    {
        "time": 1689835656000,
        "value": 139.596,
        "_internal_originalTime": 1689835656000
    },
    {
        "time": 1689835657000,
        "value": 139.588,
        "_internal_originalTime": 1689835657000
    },
    {
        "time": 1689835658000,
        "value": 139.586,
        "_internal_originalTime": 1689835658000
    },
    {
        "time": 1689835660000,
        "value": 139.589,
        "_internal_originalTime": 1689835660000
    },
    {
        "time": 1689835661000,
        "value": 139.594,
        "_internal_originalTime": 1689835661000
    },
    {
        "time": 1689835663000,
        "value": 139.576,
        "_internal_originalTime": 1689835663000
    },
    {
        "time": 1689835664000,
        "value": 139.576,
        "_internal_originalTime": 1689835664000
    },
    {
        "time": 1689835665000,
        "value": 139.576,
        "_internal_originalTime": 1689835665000
    },
    {
        "time": 1689835666000,
        "value": 139.568,
        "_internal_originalTime": 1689835666000
    },
    {
        "time": 1689835667000,
        "value": 139.556,
        "_internal_originalTime": 1689835667000
    },
    {
        "time": 1689835669000,
        "value": 139.562,
        "_internal_originalTime": 1689835669000
    },
    {
        "time": 1689835670000,
        "value": 139.554,
        "_internal_originalTime": 1689835670000
    },
    {
        "time": 1689835672000,
        "value": 139.557,
        "_internal_originalTime": 1689835672000
    },
    {
        "time": 1689835674000,
        "value": 139.562,
        "_internal_originalTime": 1689835674000
    },
    {
        "time": 1689835675000,
        "value": 139.556,
        "_internal_originalTime": 1689835675000
    },
    {
        "time": 1689835676000,
        "value": 139.563,
        "_internal_originalTime": 1689835676000
    },
    {
        "time": 1689835678000,
        "value": 139.554,
        "_internal_originalTime": 1689835678000
    },
    {
        "time": 1689835679000,
        "value": 139.56,
        "_internal_originalTime": 1689835679000
    },
    {
        "time": 1689835680000,
        "value": 139.54,
        "_internal_originalTime": 1689835680000
    },
    {
        "time": 1689835681000,
        "value": 139.552,
        "_internal_originalTime": 1689835681000
    },
    {
        "time": 1689835682000,
        "value": 139.555,
        "_internal_originalTime": 1689835682000
    },
    {
        "time": 1689835683000,
        "value": 139.553,
        "_internal_originalTime": 1689835683000
    },
    {
        "time": 1689835686000,
        "value": 139.554,
        "_internal_originalTime": 1689835686000
    },
    {
        "time": 1689835687000,
        "value": 139.554,
        "_internal_originalTime": 1689835687000
    },
    {
        "time": 1689835689000,
        "value": 139.55,
        "_internal_originalTime": 1689835689000
    },
    {
        "time": 1689835690000,
        "value": 139.559,
        "_internal_originalTime": 1689835690000
    },
    {
        "time": 1689835691000,
        "value": 139.562,
        "_internal_originalTime": 1689835691000
    },
    {
        "time": 1689835693000,
        "value": 139.563,
        "_internal_originalTime": 1689835693000
    },
    {
        "time": 1689835694000,
        "value": 139.561,
        "_internal_originalTime": 1689835694000
    },
    {
        "time": 1689835695000,
        "value": 139.554,
        "_internal_originalTime": 1689835695000
    },
    {
        "time": 1689835696000,
        "value": 139.561,
        "_internal_originalTime": 1689835696000
    },
    {
        "time": 1689835699000,
        "value": 139.562,
        "_internal_originalTime": 1689835699000
    },
    {
        "time": 1689835700000,
        "value": 139.562,
        "_internal_originalTime": 1689835700000
    },
    {
        "time": 1689835702000,
        "value": 139.561,
        "_internal_originalTime": 1689835702000
    },
    {
        "time": 1689835703000,
        "value": 139.562,
        "_internal_originalTime": 1689835703000
    },
    {
        "time": 1689835704000,
        "value": 139.562,
        "_internal_originalTime": 1689835704000
    },
    {
        "time": 1689835705000,
        "value": 139.562,
        "_internal_originalTime": 1689835705000
    },
    {
        "time": 1689835706000,
        "value": 139.562,
        "_internal_originalTime": 1689835706000
    },
    {
        "time": 1689835708000,
        "value": 139.559,
        "_internal_originalTime": 1689835708000
    },
    {
        "time": 1689835709000,
        "value": 139.556,
        "_internal_originalTime": 1689835709000
    },
    {
        "time": 1689835711000,
        "value": 139.556,
        "_internal_originalTime": 1689835711000
    },
    {
        "time": 1689835713000,
        "value": 139.563,
        "_internal_originalTime": 1689835713000
    },
    {
        "time": 1689835714000,
        "value": 139.562,
        "_internal_originalTime": 1689835714000
    },
    {
        "time": 1689835715000,
        "value": 139.562,
        "_internal_originalTime": 1689835715000
    },
    {
        "time": 1689835717000,
        "value": 139.563,
        "_internal_originalTime": 1689835717000
    },
    {
        "time": 1689835719000,
        "value": 139.562,
        "_internal_originalTime": 1689835719000
    },
    {
        "time": 1689835720000,
        "value": 139.558,
        "_internal_originalTime": 1689835720000
    },
    {
        "time": 1689835722000,
        "value": 139.559,
        "_internal_originalTime": 1689835722000
    },
    {
        "time": 1689835723000,
        "value": 139.555,
        "_internal_originalTime": 1689835723000
    },
    {
        "time": 1689835724000,
        "value": 139.56,
        "_internal_originalTime": 1689835724000
    },
    {
        "time": 1689835725000,
        "value": 139.56,
        "_internal_originalTime": 1689835725000
    },
    {
        "time": 1689835726000,
        "value": 139.56,
        "_internal_originalTime": 1689835726000
    },
    {
        "time": 1689835728000,
        "value": 139.556,
        "_internal_originalTime": 1689835728000
    },
    {
        "time": 1689835729000,
        "value": 139.559,
        "_internal_originalTime": 1689835729000
    },
    {
        "time": 1689835730000,
        "value": 139.559,
        "_internal_originalTime": 1689835730000
    },
    {
        "time": 1689835731000,
        "value": 139.56,
        "_internal_originalTime": 1689835731000
    },
    {
        "time": 1689835732000,
        "value": 139.555,
        "_internal_originalTime": 1689835732000
    },
    {
        "time": 1689835734000,
        "value": 139.562,
        "_internal_originalTime": 1689835734000
    },
    {
        "time": 1689835735000,
        "value": 139.556,
        "_internal_originalTime": 1689835735000
    },
    {
        "time": 1689835736000,
        "value": 139.562,
        "_internal_originalTime": 1689835736000
    },
    {
        "time": 1689835737000,
        "value": 139.564,
        "_internal_originalTime": 1689835737000
    },
    {
        "time": 1689835738000,
        "value": 139.56,
        "_internal_originalTime": 1689835738000
    },
    {
        "time": 1689835740000,
        "value": 139.559,
        "_internal_originalTime": 1689835740000
    },
    {
        "time": 1689835741000,
        "value": 139.558,
        "_internal_originalTime": 1689835741000
    },
    {
        "time": 1689835742000,
        "value": 139.56,
        "_internal_originalTime": 1689835742000
    },
    {
        "time": 1689835744000,
        "value": 139.556,
        "_internal_originalTime": 1689835744000
    },
    {
        "time": 1689835745000,
        "value": 139.556,
        "_internal_originalTime": 1689835745000
    },
    {
        "time": 1689835747000,
        "value": 139.561,
        "_internal_originalTime": 1689835747000
    },
    {
        "time": 1689835748000,
        "value": 139.561,
        "_internal_originalTime": 1689835748000
    },
    {
        "time": 1689835750000,
        "value": 139.561,
        "_internal_originalTime": 1689835750000
    },
    {
        "time": 1689835751000,
        "value": 139.562,
        "_internal_originalTime": 1689835751000
    },
    {
        "time": 1689835753000,
        "value": 139.563,
        "_internal_originalTime": 1689835753000
    },
    {
        "time": 1689835755000,
        "value": 139.56,
        "_internal_originalTime": 1689835755000
    },
    {
        "time": 1689835760000,
        "value": 139.561,
        "_internal_originalTime": 1689835760000
    },
    {
        "time": 1689835761000,
        "value": 139.554,
        "_internal_originalTime": 1689835761000
    },
    {
        "time": 1689835763000,
        "value": 139.553,
        "_internal_originalTime": 1689835763000
    },
    {
        "time": 1689835765000,
        "value": 139.548,
        "_internal_originalTime": 1689835765000
    },
    {
        "time": 1689835766000,
        "value": 139.556,
        "_internal_originalTime": 1689835766000
    },
    {
        "time": 1689835767000,
        "value": 139.551,
        "_internal_originalTime": 1689835767000
    },
    {
        "time": 1689835768000,
        "value": 139.549,
        "_internal_originalTime": 1689835768000
    },
    {
        "time": 1689835769000,
        "value": 139.55,
        "_internal_originalTime": 1689835769000
    },
    {
        "time": 1689835770000,
        "value": 139.554,
        "_internal_originalTime": 1689835770000
    },
    {
        "time": 1689835771000,
        "value": 139.554,
        "_internal_originalTime": 1689835771000
    },
    {
        "time": 1689835772000,
        "value": 139.548,
        "_internal_originalTime": 1689835772000
    },
    {
        "time": 1689835774000,
        "value": 139.538,
        "_internal_originalTime": 1689835774000
    },
    {
        "time": 1689835776000,
        "value": 139.539,
        "_internal_originalTime": 1689835776000
    },
    {
        "time": 1689835777000,
        "value": 139.543,
        "_internal_originalTime": 1689835777000
    },
    {
        "time": 1689835779000,
        "value": 139.539,
        "_internal_originalTime": 1689835779000
    },
    {
        "time": 1689835780000,
        "value": 139.541,
        "_internal_originalTime": 1689835780000
    },
    {
        "time": 1689835781000,
        "value": 139.542,
        "_internal_originalTime": 1689835781000
    },
    {
        "time": 1689835782000,
        "value": 139.545,
        "_internal_originalTime": 1689835782000
    },
    {
        "time": 1689835783000,
        "value": 139.545,
        "_internal_originalTime": 1689835783000
    },
    {
        "time": 1689835784000,
        "value": 139.541,
        "_internal_originalTime": 1689835784000
    },
    {
        "time": 1689835786000,
        "value": 139.543,
        "_internal_originalTime": 1689835786000
    },
    {
        "time": 1689835787000,
        "value": 139.538,
        "_internal_originalTime": 1689835787000
    },
    {
        "time": 1689835789000,
        "value": 139.536,
        "_internal_originalTime": 1689835789000
    },
    {
        "time": 1689835790000,
        "value": 139.542,
        "_internal_originalTime": 1689835790000
    },
    {
        "time": 1689835792000,
        "value": 139.54,
        "_internal_originalTime": 1689835792000
    },
    {
        "time": 1689835793000,
        "value": 139.535,
        "_internal_originalTime": 1689835793000
    },
    {
        "time": 1689835794000,
        "value": 139.537,
        "_internal_originalTime": 1689835794000
    },
    {
        "time": 1689835795000,
        "value": 139.54,
        "_internal_originalTime": 1689835795000
    },
    {
        "time": 1689835796000,
        "value": 139.54,
        "_internal_originalTime": 1689835796000
    },
    {
        "time": 1689835797000,
        "value": 139.535,
        "_internal_originalTime": 1689835797000
    },
    {
        "time": 1689835799000,
        "value": 139.536,
        "_internal_originalTime": 1689835799000
    },
    {
        "time": 1689835800000,
        "value": 139.52,
        "_internal_originalTime": 1689835800000
    },
    {
        "time": 1689835803000,
        "value": 139.534,
        "_internal_originalTime": 1689835803000
    },
    {
        "time": 1689835804000,
        "value": 139.543,
        "_internal_originalTime": 1689835804000
    },
    {
        "time": 1689835805000,
        "value": 139.535,
        "_internal_originalTime": 1689835805000
    },
    {
        "time": 1689835806000,
        "value": 139.542,
        "_internal_originalTime": 1689835806000
    },
    {
        "time": 1689835807000,
        "value": 139.543,
        "_internal_originalTime": 1689835807000
    },
    {
        "time": 1689835809000,
        "value": 139.544,
        "_internal_originalTime": 1689835809000
    },
    {
        "time": 1689835810000,
        "value": 139.544,
        "_internal_originalTime": 1689835810000
    },
    {
        "time": 1689835811000,
        "value": 139.539,
        "_internal_originalTime": 1689835811000
    },
    {
        "time": 1689835812000,
        "value": 139.534,
        "_internal_originalTime": 1689835812000
    },
    {
        "time": 1689835813000,
        "value": 139.531,
        "_internal_originalTime": 1689835813000
    },
    {
        "time": 1689835814000,
        "value": 139.53,
        "_internal_originalTime": 1689835814000
    },
    {
        "time": 1689835815000,
        "value": 139.532,
        "_internal_originalTime": 1689835815000
    },
    {
        "time": 1689835816000,
        "value": 139.528,
        "_internal_originalTime": 1689835816000
    },
    {
        "time": 1689835817000,
        "value": 139.524,
        "_internal_originalTime": 1689835817000
    },
    {
        "time": 1689835818000,
        "value": 139.524,
        "_internal_originalTime": 1689835818000
    },
    {
        "time": 1689835819000,
        "value": 139.526,
        "_internal_originalTime": 1689835819000
    },
    {
        "time": 1689835820000,
        "value": 139.524,
        "_internal_originalTime": 1689835820000
    },
    {
        "time": 1689835823000,
        "value": 139.522,
        "_internal_originalTime": 1689835823000
    },
    {
        "time": 1689835824000,
        "value": 139.516,
        "_internal_originalTime": 1689835824000
    },
    {
        "time": 1689835825000,
        "value": 139.522,
        "_internal_originalTime": 1689835825000
    },
    {
        "time": 1689835826000,
        "value": 139.523,
        "_internal_originalTime": 1689835826000
    },
    {
        "time": 1689835827000,
        "value": 139.521,
        "_internal_originalTime": 1689835827000
    },
    {
        "time": 1689835828000,
        "value": 139.53,
        "_internal_originalTime": 1689835828000
    },
    {
        "time": 1689835829000,
        "value": 139.532,
        "_internal_originalTime": 1689835829000
    },
    {
        "time": 1689835830000,
        "value": 139.534,
        "_internal_originalTime": 1689835830000
    },
    {
        "time": 1689835832000,
        "value": 139.528,
        "_internal_originalTime": 1689835832000
    },
    {
        "time": 1689835833000,
        "value": 139.537,
        "_internal_originalTime": 1689835833000
    },
    {
        "time": 1689835834000,
        "value": 139.537,
        "_internal_originalTime": 1689835834000
    },
    {
        "time": 1689835837000,
        "value": 139.528,
        "_internal_originalTime": 1689835837000
    },
    {
        "time": 1689835839000,
        "value": 139.529,
        "_internal_originalTime": 1689835839000
    },
    {
        "time": 1689835840000,
        "value": 139.526,
        "_internal_originalTime": 1689835840000
    },
    {
        "time": 1689835841000,
        "value": 139.528,
        "_internal_originalTime": 1689835841000
    },
    {
        "time": 1689835843000,
        "value": 139.526,
        "_internal_originalTime": 1689835843000
    },
    {
        "time": 1689835844000,
        "value": 139.531,
        "_internal_originalTime": 1689835844000
    },
    {
        "time": 1689835845000,
        "value": 139.533,
        "_internal_originalTime": 1689835845000
    },
    {
        "time": 1689835846000,
        "value": 139.527,
        "_internal_originalTime": 1689835846000
    },
    {
        "time": 1689835848000,
        "value": 139.533,
        "_internal_originalTime": 1689835848000
    },
    {
        "time": 1689835850000,
        "value": 139.528,
        "_internal_originalTime": 1689835850000
    },
    {
        "time": 1689835851000,
        "value": 139.527,
        "_internal_originalTime": 1689835851000
    },
    {
        "time": 1689835852000,
        "value": 139.533,
        "_internal_originalTime": 1689835852000
    },
    {
        "time": 1689835857000,
        "value": 139.534,
        "_internal_originalTime": 1689835857000
    },
    {
        "time": 1689835860000,
        "value": 139.531,
        "_internal_originalTime": 1689835860000
    },
    {
        "time": 1689835861000,
        "value": 139.529,
        "_internal_originalTime": 1689835861000
    },
    {
        "time": 1689835862000,
        "value": 139.532,
        "_internal_originalTime": 1689835862000
    },
    {
        "time": 1689835863000,
        "value": 139.527,
        "_internal_originalTime": 1689835863000
    },
    {
        "time": 1689835864000,
        "value": 139.527,
        "_internal_originalTime": 1689835864000
    },
    {
        "time": 1689835866000,
        "value": 139.53,
        "_internal_originalTime": 1689835866000
    },
    {
        "time": 1689835867000,
        "value": 139.531,
        "_internal_originalTime": 1689835867000
    },
    {
        "time": 1689835869000,
        "value": 139.524,
        "_internal_originalTime": 1689835869000
    },
    {
        "time": 1689835870000,
        "value": 139.531,
        "_internal_originalTime": 1689835870000
    },
    {
        "time": 1689835871000,
        "value": 139.524,
        "_internal_originalTime": 1689835871000
    },
    {
        "time": 1689835873000,
        "value": 139.525,
        "_internal_originalTime": 1689835873000
    },
    {
        "time": 1689835874000,
        "value": 139.524,
        "_internal_originalTime": 1689835874000
    },
    {
        "time": 1689835876000,
        "value": 139.527,
        "_internal_originalTime": 1689835876000
    },
    {
        "time": 1689835877000,
        "value": 139.525,
        "_internal_originalTime": 1689835877000
    },
    {
        "time": 1689835878000,
        "value": 139.528,
        "_internal_originalTime": 1689835878000
    },
    {
        "time": 1689835879000,
        "value": 139.528,
        "_internal_originalTime": 1689835879000
    },
    {
        "time": 1689835880000,
        "value": 139.535,
        "_internal_originalTime": 1689835880000
    },
    {
        "time": 1689835883000,
        "value": 139.53,
        "_internal_originalTime": 1689835883000
    },
    {
        "time": 1689835885000,
        "value": 139.529,
        "_internal_originalTime": 1689835885000
    },
    {
        "time": 1689835886000,
        "value": 139.528,
        "_internal_originalTime": 1689835886000
    },
    {
        "time": 1689835887000,
        "value": 139.528,
        "_internal_originalTime": 1689835887000
    },
    {
        "time": 1689835888000,
        "value": 139.535,
        "_internal_originalTime": 1689835888000
    },
    {
        "time": 1689835890000,
        "value": 139.532,
        "_internal_originalTime": 1689835890000
    },
    {
        "time": 1689835891000,
        "value": 139.535,
        "_internal_originalTime": 1689835891000
    },
    {
        "time": 1689835892000,
        "value": 139.536,
        "_internal_originalTime": 1689835892000
    },
    {
        "time": 1689835895000,
        "value": 139.543,
        "_internal_originalTime": 1689835895000
    },
    {
        "time": 1689835897000,
        "value": 139.539,
        "_internal_originalTime": 1689835897000
    },
    {
        "time": 1689835898000,
        "value": 139.543,
        "_internal_originalTime": 1689835898000
    },
    {
        "time": 1689835900000,
        "value": 139.539,
        "_internal_originalTime": 1689835900000
    },
    {
        "time": 1689835901000,
        "value": 139.543,
        "_internal_originalTime": 1689835901000
    },
    {
        "time": 1689835903000,
        "value": 139.54,
        "_internal_originalTime": 1689835903000
    },
    {
        "time": 1689835905000,
        "value": 139.54,
        "_internal_originalTime": 1689835905000
    },
    {
        "time": 1689835906000,
        "value": 139.541,
        "_internal_originalTime": 1689835906000
    },
    {
        "time": 1689835907000,
        "value": 139.546,
        "_internal_originalTime": 1689835907000
    },
    {
        "time": 1689835910000,
        "value": 139.546,
        "_internal_originalTime": 1689835910000
    },
    {
        "time": 1689835912000,
        "value": 139.545,
        "_internal_originalTime": 1689835912000
    },
    {
        "time": 1689835914000,
        "value": 139.538,
        "_internal_originalTime": 1689835914000
    },
    {
        "time": 1689835915000,
        "value": 139.539,
        "_internal_originalTime": 1689835915000
    },
    {
        "time": 1689835917000,
        "value": 139.54,
        "_internal_originalTime": 1689835917000
    },
    {
        "time": 1689835918000,
        "value": 139.543,
        "_internal_originalTime": 1689835918000
    },
    {
        "time": 1689835919000,
        "value": 139.544,
        "_internal_originalTime": 1689835919000
    },
    {
        "time": 1689835920000,
        "value": 139.542,
        "_internal_originalTime": 1689835920000
    },
    {
        "time": 1689835921000,
        "value": 139.54,
        "_internal_originalTime": 1689835921000
    },
    {
        "time": 1689835922000,
        "value": 139.538,
        "_internal_originalTime": 1689835922000
    },
    {
        "time": 1689835923000,
        "value": 139.544,
        "_internal_originalTime": 1689835923000
    },
    {
        "time": 1689835927000,
        "value": 139.538,
        "_internal_originalTime": 1689835927000
    },
    {
        "time": 1689835928000,
        "value": 139.542,
        "_internal_originalTime": 1689835928000
    },
    {
        "time": 1689835931000,
        "value": 139.539,
        "_internal_originalTime": 1689835931000
    },
    {
        "time": 1689835932000,
        "value": 139.54,
        "_internal_originalTime": 1689835932000
    },
    {
        "time": 1689835933000,
        "value": 139.54,
        "_internal_originalTime": 1689835933000
    },
    {
        "time": 1689835934000,
        "value": 139.544,
        "_internal_originalTime": 1689835934000
    },
    {
        "time": 1689835937000,
        "value": 139.54,
        "_internal_originalTime": 1689835937000
    },
    {
        "time": 1689835945000,
        "value": 139.541,
        "_internal_originalTime": 1689835945000
    },
    {
        "time": 1689835946000,
        "value": 139.547,
        "_internal_originalTime": 1689835946000
    },
    {
        "time": 1689835947000,
        "value": 139.546,
        "_internal_originalTime": 1689835947000
    },
    {
        "time": 1689835951000,
        "value": 139.546,
        "_internal_originalTime": 1689835951000
    },
    {
        "time": 1689835953000,
        "value": 139.542,
        "_internal_originalTime": 1689835953000
    },
    {
        "time": 1689835955000,
        "value": 139.546,
        "_internal_originalTime": 1689835955000
    },
    {
        "time": 1689835956000,
        "value": 139.546,
        "_internal_originalTime": 1689835956000
    },
    {
        "time": 1689835957000,
        "value": 139.542,
        "_internal_originalTime": 1689835957000
    },
    {
        "time": 1689835961000,
        "value": 139.544,
        "_internal_originalTime": 1689835961000
    },
    {
        "time": 1689835962000,
        "value": 139.55,
        "_internal_originalTime": 1689835962000
    },
    {
        "time": 1689835969000,
        "value": 139.548,
        "_internal_originalTime": 1689835969000
    },
    {
        "time": 1689835970000,
        "value": 139.55,
        "_internal_originalTime": 1689835970000
    },
    {
        "time": 1689835971000,
        "value": 139.548,
        "_internal_originalTime": 1689835971000
    },
    {
        "time": 1689835974000,
        "value": 139.547,
        "_internal_originalTime": 1689835974000
    },
    {
        "time": 1689835975000,
        "value": 139.548,
        "_internal_originalTime": 1689835975000
    },
    {
        "time": 1689835977000,
        "value": 139.551,
        "_internal_originalTime": 1689835977000
    },
    {
        "time": 1689835978000,
        "value": 139.553,
        "_internal_originalTime": 1689835978000
    },
    {
        "time": 1689835980000,
        "value": 139.553,
        "_internal_originalTime": 1689835980000
    },
    {
        "time": 1689835981000,
        "value": 139.53,
        "_internal_originalTime": 1689835981000
    },
    {
        "time": 1689835982000,
        "value": 139.555,
        "_internal_originalTime": 1689835982000
    },
    {
        "time": 1689835983000,
        "value": 139.553,
        "_internal_originalTime": 1689835983000
    },
    {
        "time": 1689835984000,
        "value": 139.55,
        "_internal_originalTime": 1689835984000
    },
    {
        "time": 1689835985000,
        "value": 139.548,
        "_internal_originalTime": 1689835985000
    },
    {
        "time": 1689835987000,
        "value": 139.552,
        "_internal_originalTime": 1689835987000
    },
    {
        "time": 1689835989000,
        "value": 139.553,
        "_internal_originalTime": 1689835989000
    },
    {
        "time": 1689835991000,
        "value": 139.556,
        "_internal_originalTime": 1689835991000
    },
    {
        "time": 1689835992000,
        "value": 139.553,
        "_internal_originalTime": 1689835992000
    },
    {
        "time": 1689835993000,
        "value": 139.553,
        "_internal_originalTime": 1689835993000
    },
    {
        "time": 1689835994000,
        "value": 139.553,
        "_internal_originalTime": 1689835994000
    },
    {
        "time": 1689835995000,
        "value": 139.55,
        "_internal_originalTime": 1689835995000
    },
    {
        "time": 1689835997000,
        "value": 139.55,
        "_internal_originalTime": 1689835997000
    },
    {
        "time": 1689835998000,
        "value": 139.556,
        "_internal_originalTime": 1689835998000
    },
    {
        "time": 1689836000000,
        "value": 139.551,
        "_internal_originalTime": 1689836000000
    },
    {
        "time": 1689836001000,
        "value": 139.548,
        "_internal_originalTime": 1689836001000
    },
    {
        "time": 1689836002000,
        "value": 139.556,
        "_internal_originalTime": 1689836002000
    },
    {
        "time": 1689836003000,
        "value": 139.551,
        "_internal_originalTime": 1689836003000
    },
    {
        "time": 1689836005000,
        "value": 139.555,
        "_internal_originalTime": 1689836005000
    },
    {
        "time": 1689836006000,
        "value": 139.552,
        "_internal_originalTime": 1689836006000
    },
    {
        "time": 1689836007000,
        "value": 139.553,
        "_internal_originalTime": 1689836007000
    },
    {
        "time": 1689836009000,
        "value": 139.551,
        "_internal_originalTime": 1689836009000
    },
    {
        "time": 1689836010000,
        "value": 139.553,
        "_internal_originalTime": 1689836010000
    },
    {
        "time": 1689836011000,
        "value": 139.554,
        "_internal_originalTime": 1689836011000
    },
    {
        "time": 1689836012000,
        "value": 139.55,
        "_internal_originalTime": 1689836012000
    },
    {
        "time": 1689836015000,
        "value": 139.549,
        "_internal_originalTime": 1689836015000
    },
    {
        "time": 1689836017000,
        "value": 139.554,
        "_internal_originalTime": 1689836017000
    },
    {
        "time": 1689836018000,
        "value": 139.55,
        "_internal_originalTime": 1689836018000
    },
    {
        "time": 1689836019000,
        "value": 139.551,
        "_internal_originalTime": 1689836019000
    },
    {
        "time": 1689836020000,
        "value": 139.56,
        "_internal_originalTime": 1689836020000
    },
    {
        "time": 1689836022000,
        "value": 139.557,
        "_internal_originalTime": 1689836022000
    },
    {
        "time": 1689836023000,
        "value": 139.557,
        "_internal_originalTime": 1689836023000
    },
    {
        "time": 1689836024000,
        "value": 139.557,
        "_internal_originalTime": 1689836024000
    },
    {
        "time": 1689836025000,
        "value": 139.557,
        "_internal_originalTime": 1689836025000
    },
    {
        "time": 1689836028000,
        "value": 139.558,
        "_internal_originalTime": 1689836028000
    },
    {
        "time": 1689836029000,
        "value": 139.559,
        "_internal_originalTime": 1689836029000
    },
    {
        "time": 1689836030000,
        "value": 139.557,
        "_internal_originalTime": 1689836030000
    },
    {
        "time": 1689836031000,
        "value": 139.555,
        "_internal_originalTime": 1689836031000
    },
    {
        "time": 1689836034000,
        "value": 139.553,
        "_internal_originalTime": 1689836034000
    },
    {
        "time": 1689836035000,
        "value": 139.546,
        "_internal_originalTime": 1689836035000
    },
    {
        "time": 1689836036000,
        "value": 139.547,
        "_internal_originalTime": 1689836036000
    },
    {
        "time": 1689836037000,
        "value": 139.551,
        "_internal_originalTime": 1689836037000
    },
    {
        "time": 1689836038000,
        "value": 139.546,
        "_internal_originalTime": 1689836038000
    },
    {
        "time": 1689836039000,
        "value": 139.551,
        "_internal_originalTime": 1689836039000
    },
    {
        "time": 1689836041000,
        "value": 139.552,
        "_internal_originalTime": 1689836041000
    },
    {
        "time": 1689836042000,
        "value": 139.555,
        "_internal_originalTime": 1689836042000
    },
    {
        "time": 1689836043000,
        "value": 139.548,
        "_internal_originalTime": 1689836043000
    },
    {
        "time": 1689836045000,
        "value": 139.554,
        "_internal_originalTime": 1689836045000
    },
    {
        "time": 1689836046000,
        "value": 139.547,
        "_internal_originalTime": 1689836046000
    },
    {
        "time": 1689836048000,
        "value": 139.543,
        "_internal_originalTime": 1689836048000
    },
    {
        "time": 1689836049000,
        "value": 139.537,
        "_internal_originalTime": 1689836049000
    },
    {
        "time": 1689836050000,
        "value": 139.552,
        "_internal_originalTime": 1689836050000
    },
    {
        "time": 1689836051000,
        "value": 139.548,
        "_internal_originalTime": 1689836051000
    },
    {
        "time": 1689836052000,
        "value": 139.544,
        "_internal_originalTime": 1689836052000
    },
    {
        "time": 1689836054000,
        "value": 139.546,
        "_internal_originalTime": 1689836054000
    },
    {
        "time": 1689836056000,
        "value": 139.557,
        "_internal_originalTime": 1689836056000
    },
    {
        "time": 1689836057000,
        "value": 139.555,
        "_internal_originalTime": 1689836057000
    },
    {
        "time": 1689836061000,
        "value": 139.55,
        "_internal_originalTime": 1689836061000
    },
    {
        "time": 1689836062000,
        "value": 139.551,
        "_internal_originalTime": 1689836062000
    },
    {
        "time": 1689836064000,
        "value": 139.551,
        "_internal_originalTime": 1689836064000
    },
    {
        "time": 1689836066000,
        "value": 139.555,
        "_internal_originalTime": 1689836066000
    },
    {
        "time": 1689836067000,
        "value": 139.548,
        "_internal_originalTime": 1689836067000
    },
    {
        "time": 1689836068000,
        "value": 139.554,
        "_internal_originalTime": 1689836068000
    },
    {
        "time": 1689836070000,
        "value": 139.553,
        "_internal_originalTime": 1689836070000
    },
    {
        "time": 1689836071000,
        "value": 139.548,
        "_internal_originalTime": 1689836071000
    },
    {
        "time": 1689836073000,
        "value": 139.556,
        "_internal_originalTime": 1689836073000
    },
    {
        "time": 1689836074000,
        "value": 139.554,
        "_internal_originalTime": 1689836074000
    },
    {
        "time": 1689836075000,
        "value": 139.554,
        "_internal_originalTime": 1689836075000
    },
    {
        "time": 1689836076000,
        "value": 139.55,
        "_internal_originalTime": 1689836076000
    },
    {
        "time": 1689836077000,
        "value": 139.554,
        "_internal_originalTime": 1689836077000
    },
    {
        "time": 1689836079000,
        "value": 139.549,
        "_internal_originalTime": 1689836079000
    },
    {
        "time": 1689836080000,
        "value": 139.552,
        "_internal_originalTime": 1689836080000
    },
    {
        "time": 1689836081000,
        "value": 139.552,
        "_internal_originalTime": 1689836081000
    },
    {
        "time": 1689836082000,
        "value": 139.552,
        "_internal_originalTime": 1689836082000
    },
    {
        "time": 1689836083000,
        "value": 139.552,
        "_internal_originalTime": 1689836083000
    },
    {
        "time": 1689836085000,
        "value": 139.548,
        "_internal_originalTime": 1689836085000
    },
    {
        "time": 1689836086000,
        "value": 139.552,
        "_internal_originalTime": 1689836086000
    },
    {
        "time": 1689836088000,
        "value": 139.549,
        "_internal_originalTime": 1689836088000
    },
    {
        "time": 1689836089000,
        "value": 139.549,
        "_internal_originalTime": 1689836089000
    },
    {
        "time": 1689836091000,
        "value": 139.551,
        "_internal_originalTime": 1689836091000
    },
    {
        "time": 1689836092000,
        "value": 139.546,
        "_internal_originalTime": 1689836092000
    },
    {
        "time": 1689836093000,
        "value": 139.543,
        "_internal_originalTime": 1689836093000
    },
    {
        "time": 1689836095000,
        "value": 139.532,
        "_internal_originalTime": 1689836095000
    },
    {
        "time": 1689836097000,
        "value": 139.541,
        "_internal_originalTime": 1689836097000
    },
    {
        "time": 1689836098000,
        "value": 139.538,
        "_internal_originalTime": 1689836098000
    },
    {
        "time": 1689836099000,
        "value": 139.54,
        "_internal_originalTime": 1689836099000
    },
    {
        "time": 1689836101000,
        "value": 139.539,
        "_internal_originalTime": 1689836101000
    },
    {
        "time": 1689836102000,
        "value": 139.54,
        "_internal_originalTime": 1689836102000
    },
    {
        "time": 1689836103000,
        "value": 139.54,
        "_internal_originalTime": 1689836103000
    },
    {
        "time": 1689836104000,
        "value": 139.54,
        "_internal_originalTime": 1689836104000
    },
    {
        "time": 1689836106000,
        "value": 139.534,
        "_internal_originalTime": 1689836106000
    },
    {
        "time": 1689836107000,
        "value": 139.53,
        "_internal_originalTime": 1689836107000
    },
    {
        "time": 1689836109000,
        "value": 139.534,
        "_internal_originalTime": 1689836109000
    },
    {
        "time": 1689836111000,
        "value": 139.533,
        "_internal_originalTime": 1689836111000
    },
    {
        "time": 1689836112000,
        "value": 139.53,
        "_internal_originalTime": 1689836112000
    },
    {
        "time": 1689836113000,
        "value": 139.53,
        "_internal_originalTime": 1689836113000
    },
    {
        "time": 1689836114000,
        "value": 139.526,
        "_internal_originalTime": 1689836114000
    },
    {
        "time": 1689836115000,
        "value": 139.53,
        "_internal_originalTime": 1689836115000
    },
    {
        "time": 1689836116000,
        "value": 139.524,
        "_internal_originalTime": 1689836116000
    },
    {
        "time": 1689836117000,
        "value": 139.525,
        "_internal_originalTime": 1689836117000
    },
    {
        "time": 1689836118000,
        "value": 139.53,
        "_internal_originalTime": 1689836118000
    },
    {
        "time": 1689836119000,
        "value": 139.524,
        "_internal_originalTime": 1689836119000
    },
    {
        "time": 1689836120000,
        "value": 139.53,
        "_internal_originalTime": 1689836120000
    },
    {
        "time": 1689836123000,
        "value": 139.533,
        "_internal_originalTime": 1689836123000
    },
    {
        "time": 1689836124000,
        "value": 139.534,
        "_internal_originalTime": 1689836124000
    },
    {
        "time": 1689836126000,
        "value": 139.528,
        "_internal_originalTime": 1689836126000
    },
    {
        "time": 1689836127000,
        "value": 139.527,
        "_internal_originalTime": 1689836127000
    },
    {
        "time": 1689836128000,
        "value": 139.527,
        "_internal_originalTime": 1689836128000
    },
    {
        "time": 1689836129000,
        "value": 139.527,
        "_internal_originalTime": 1689836129000
    },
    {
        "time": 1689836131000,
        "value": 139.528,
        "_internal_originalTime": 1689836131000
    },
    {
        "time": 1689836132000,
        "value": 139.532,
        "_internal_originalTime": 1689836132000
    },
    {
        "time": 1689836133000,
        "value": 139.528,
        "_internal_originalTime": 1689836133000
    },
    {
        "time": 1689836136000,
        "value": 139.526,
        "_internal_originalTime": 1689836136000
    },
    {
        "time": 1689836137000,
        "value": 139.533,
        "_internal_originalTime": 1689836137000
    },
    {
        "time": 1689836142000,
        "value": 139.528,
        "_internal_originalTime": 1689836142000
    },
    {
        "time": 1689836143000,
        "value": 139.527,
        "_internal_originalTime": 1689836143000
    },
    {
        "time": 1689836144000,
        "value": 139.531,
        "_internal_originalTime": 1689836144000
    },
    {
        "time": 1689836145000,
        "value": 139.531,
        "_internal_originalTime": 1689836145000
    },
    {
        "time": 1689836147000,
        "value": 139.529,
        "_internal_originalTime": 1689836147000
    },
    {
        "time": 1689836149000,
        "value": 139.531,
        "_internal_originalTime": 1689836149000
    },
    {
        "time": 1689836152000,
        "value": 139.519,
        "_internal_originalTime": 1689836152000
    },
    {
        "time": 1689836153000,
        "value": 139.524,
        "_internal_originalTime": 1689836153000
    },
    {
        "time": 1689836155000,
        "value": 139.521,
        "_internal_originalTime": 1689836155000
    },
    {
        "time": 1689836156000,
        "value": 139.521,
        "_internal_originalTime": 1689836156000
    },
    {
        "time": 1689836157000,
        "value": 139.519,
        "_internal_originalTime": 1689836157000
    },
    {
        "time": 1689836159000,
        "value": 139.514,
        "_internal_originalTime": 1689836159000
    },
    {
        "time": 1689836160000,
        "value": 139.509,
        "_internal_originalTime": 1689836160000
    },
    {
        "time": 1689836161000,
        "value": 139.508,
        "_internal_originalTime": 1689836161000
    },
    {
        "time": 1689836163000,
        "value": 139.5,
        "_internal_originalTime": 1689836163000
    },
    {
        "time": 1689836164000,
        "value": 139.497,
        "_internal_originalTime": 1689836164000
    },
    {
        "time": 1689836166000,
        "value": 139.485,
        "_internal_originalTime": 1689836166000
    },
    {
        "time": 1689836167000,
        "value": 139.491,
        "_internal_originalTime": 1689836167000
    },
    {
        "time": 1689836168000,
        "value": 139.488,
        "_internal_originalTime": 1689836168000
    },
    {
        "time": 1689836171000,
        "value": 139.49,
        "_internal_originalTime": 1689836171000
    },
    {
        "time": 1689836172000,
        "value": 139.494,
        "_internal_originalTime": 1689836172000
    },
    {
        "time": 1689836173000,
        "value": 139.495,
        "_internal_originalTime": 1689836173000
    },
    {
        "time": 1689836174000,
        "value": 139.499,
        "_internal_originalTime": 1689836174000
    },
    {
        "time": 1689836175000,
        "value": 139.503,
        "_internal_originalTime": 1689836175000
    },
    {
        "time": 1689836176000,
        "value": 139.503,
        "_internal_originalTime": 1689836176000
    },
    {
        "time": 1689836177000,
        "value": 139.499,
        "_internal_originalTime": 1689836177000
    },
    {
        "time": 1689836178000,
        "value": 139.497,
        "_internal_originalTime": 1689836178000
    },
    {
        "time": 1689836179000,
        "value": 139.502,
        "_internal_originalTime": 1689836179000
    },
    {
        "time": 1689836180000,
        "value": 139.502,
        "_internal_originalTime": 1689836180000
    },
    {
        "time": 1689836182000,
        "value": 139.503,
        "_internal_originalTime": 1689836182000
    },
    {
        "time": 1689836183000,
        "value": 139.503,
        "_internal_originalTime": 1689836183000
    },
    {
        "time": 1689836185000,
        "value": 139.502,
        "_internal_originalTime": 1689836185000
    },
    {
        "time": 1689836187000,
        "value": 139.502,
        "_internal_originalTime": 1689836187000
    },
    {
        "time": 1689836188000,
        "value": 139.502,
        "_internal_originalTime": 1689836188000
    },
    {
        "time": 1689836190000,
        "value": 139.502,
        "_internal_originalTime": 1689836190000
    },
    {
        "time": 1689836193000,
        "value": 139.502,
        "_internal_originalTime": 1689836193000
    },
    {
        "time": 1689836194000,
        "value": 139.498,
        "_internal_originalTime": 1689836194000
    },
    {
        "time": 1689836195000,
        "value": 139.498,
        "_internal_originalTime": 1689836195000
    },
    {
        "time": 1689836197000,
        "value": 139.502,
        "_internal_originalTime": 1689836197000
    },
    {
        "time": 1689836198000,
        "value": 139.502,
        "_internal_originalTime": 1689836198000
    },
    {
        "time": 1689836199000,
        "value": 139.497,
        "_internal_originalTime": 1689836199000
    },
    {
        "time": 1689836200000,
        "value": 139.497,
        "_internal_originalTime": 1689836200000
    },
    {
        "time": 1689836201000,
        "value": 139.504,
        "_internal_originalTime": 1689836201000
    },
    {
        "time": 1689836204000,
        "value": 139.504,
        "_internal_originalTime": 1689836204000
    },
    {
        "time": 1689836205000,
        "value": 139.504,
        "_internal_originalTime": 1689836205000
    },
    {
        "time": 1689836207000,
        "value": 139.513,
        "_internal_originalTime": 1689836207000
    },
    {
        "time": 1689836208000,
        "value": 139.513,
        "_internal_originalTime": 1689836208000
    },
    {
        "time": 1689836210000,
        "value": 139.504,
        "_internal_originalTime": 1689836210000
    },
    {
        "time": 1689836211000,
        "value": 139.512,
        "_internal_originalTime": 1689836211000
    },
    {
        "time": 1689836212000,
        "value": 139.513,
        "_internal_originalTime": 1689836212000
    },
    {
        "time": 1689836213000,
        "value": 139.513,
        "_internal_originalTime": 1689836213000
    },
    {
        "time": 1689836215000,
        "value": 139.509,
        "_internal_originalTime": 1689836215000
    },
    {
        "time": 1689836216000,
        "value": 139.512,
        "_internal_originalTime": 1689836216000
    },
    {
        "time": 1689836218000,
        "value": 139.511,
        "_internal_originalTime": 1689836218000
    },
    {
        "time": 1689836219000,
        "value": 139.51,
        "_internal_originalTime": 1689836219000
    },
    {
        "time": 1689836220000,
        "value": 139.515,
        "_internal_originalTime": 1689836220000
    },
    {
        "time": 1689836221000,
        "value": 139.514,
        "_internal_originalTime": 1689836221000
    },
    {
        "time": 1689836222000,
        "value": 139.51,
        "_internal_originalTime": 1689836222000
    },
    {
        "time": 1689836223000,
        "value": 139.51,
        "_internal_originalTime": 1689836223000
    },
    {
        "time": 1689836224000,
        "value": 139.51,
        "_internal_originalTime": 1689836224000
    },
    {
        "time": 1689836226000,
        "value": 139.515,
        "_internal_originalTime": 1689836226000
    },
    {
        "time": 1689836227000,
        "value": 139.52,
        "_internal_originalTime": 1689836227000
    },
    {
        "time": 1689836229000,
        "value": 139.521,
        "_internal_originalTime": 1689836229000
    },
    {
        "time": 1689836230000,
        "value": 139.516,
        "_internal_originalTime": 1689836230000
    },
    {
        "time": 1689836231000,
        "value": 139.52,
        "_internal_originalTime": 1689836231000
    },
    {
        "time": 1689836233000,
        "value": 139.52,
        "_internal_originalTime": 1689836233000
    },
    {
        "time": 1689836235000,
        "value": 139.515,
        "_internal_originalTime": 1689836235000
    },
    {
        "time": 1689836236000,
        "value": 139.515,
        "_internal_originalTime": 1689836236000
    },
    {
        "time": 1689836237000,
        "value": 139.517,
        "_internal_originalTime": 1689836237000
    },
    {
        "time": 1689836238000,
        "value": 139.52,
        "_internal_originalTime": 1689836238000
    },
    {
        "time": 1689836239000,
        "value": 139.521,
        "_internal_originalTime": 1689836239000
    },
    {
        "time": 1689836240000,
        "value": 139.521,
        "_internal_originalTime": 1689836240000
    },
    {
        "time": 1689836242000,
        "value": 139.517,
        "_internal_originalTime": 1689836242000
    },
    {
        "time": 1689836243000,
        "value": 139.521,
        "_internal_originalTime": 1689836243000
    },
    {
        "time": 1689836244000,
        "value": 139.522,
        "_internal_originalTime": 1689836244000
    },
    {
        "time": 1689836246000,
        "value": 139.522,
        "_internal_originalTime": 1689836246000
    },
    {
        "time": 1689836247000,
        "value": 139.521,
        "_internal_originalTime": 1689836247000
    },
    {
        "time": 1689836249000,
        "value": 139.517,
        "_internal_originalTime": 1689836249000
    },
    {
        "time": 1689836250000,
        "value": 139.513,
        "_internal_originalTime": 1689836250000
    },
    {
        "time": 1689836251000,
        "value": 139.505,
        "_internal_originalTime": 1689836251000
    },
    {
        "time": 1689836252000,
        "value": 139.506,
        "_internal_originalTime": 1689836252000
    },
    {
        "time": 1689836253000,
        "value": 139.505,
        "_internal_originalTime": 1689836253000
    },
    {
        "time": 1689836254000,
        "value": 139.508,
        "_internal_originalTime": 1689836254000
    },
    {
        "time": 1689836256000,
        "value": 139.506,
        "_internal_originalTime": 1689836256000
    },
    {
        "time": 1689836257000,
        "value": 139.511,
        "_internal_originalTime": 1689836257000
    },
    {
        "time": 1689836258000,
        "value": 139.509,
        "_internal_originalTime": 1689836258000
    },
    {
        "time": 1689836260000,
        "value": 139.51,
        "_internal_originalTime": 1689836260000
    },
    {
        "time": 1689836261000,
        "value": 139.51,
        "_internal_originalTime": 1689836261000
    },
    {
        "time": 1689836263000,
        "value": 139.506,
        "_internal_originalTime": 1689836263000
    },
    {
        "time": 1689836264000,
        "value": 139.502,
        "_internal_originalTime": 1689836264000
    },
    {
        "time": 1689836265000,
        "value": 139.503,
        "_internal_originalTime": 1689836265000
    },
    {
        "time": 1689836267000,
        "value": 139.505,
        "_internal_originalTime": 1689836267000
    },
    {
        "time": 1689836268000,
        "value": 139.508,
        "_internal_originalTime": 1689836268000
    },
    {
        "time": 1689836269000,
        "value": 139.504,
        "_internal_originalTime": 1689836269000
    },
    {
        "time": 1689836270000,
        "value": 139.508,
        "_internal_originalTime": 1689836270000
    },
    {
        "time": 1689836271000,
        "value": 139.5,
        "_internal_originalTime": 1689836271000
    },
    {
        "time": 1689836272000,
        "value": 139.502,
        "_internal_originalTime": 1689836272000
    },
    {
        "time": 1689836274000,
        "value": 139.508,
        "_internal_originalTime": 1689836274000
    },
    {
        "time": 1689836276000,
        "value": 139.502,
        "_internal_originalTime": 1689836276000
    },
    {
        "time": 1689836277000,
        "value": 139.502,
        "_internal_originalTime": 1689836277000
    },
    {
        "time": 1689836278000,
        "value": 139.501,
        "_internal_originalTime": 1689836278000
    },
    {
        "time": 1689836280000,
        "value": 139.508,
        "_internal_originalTime": 1689836280000
    },
    {
        "time": 1689836281000,
        "value": 139.508,
        "_internal_originalTime": 1689836281000
    },
    {
        "time": 1689836284000,
        "value": 139.512,
        "_internal_originalTime": 1689836284000
    },
    {
        "time": 1689836285000,
        "value": 139.513,
        "_internal_originalTime": 1689836285000
    },
    {
        "time": 1689836286000,
        "value": 139.506,
        "_internal_originalTime": 1689836286000
    },
    {
        "time": 1689836288000,
        "value": 139.507,
        "_internal_originalTime": 1689836288000
    },
    {
        "time": 1689836289000,
        "value": 139.508,
        "_internal_originalTime": 1689836289000
    },
    {
        "time": 1689836290000,
        "value": 139.507,
        "_internal_originalTime": 1689836290000
    },
    {
        "time": 1689836291000,
        "value": 139.508,
        "_internal_originalTime": 1689836291000
    },
    {
        "time": 1689836292000,
        "value": 139.514,
        "_internal_originalTime": 1689836292000
    },
    {
        "time": 1689836293000,
        "value": 139.508,
        "_internal_originalTime": 1689836293000
    },
    {
        "time": 1689836294000,
        "value": 139.508,
        "_internal_originalTime": 1689836294000
    },
    {
        "time": 1689836296000,
        "value": 139.511,
        "_internal_originalTime": 1689836296000
    },
    {
        "time": 1689836297000,
        "value": 139.52,
        "_internal_originalTime": 1689836297000
    },
    {
        "time": 1689836298000,
        "value": 139.522,
        "_internal_originalTime": 1689836298000
    },
    {
        "time": 1689836299000,
        "value": 139.521,
        "_internal_originalTime": 1689836299000
    },
    {
        "time": 1689836300000,
        "value": 139.521,
        "_internal_originalTime": 1689836300000
    },
    {
        "time": 1689836302000,
        "value": 139.523,
        "_internal_originalTime": 1689836302000
    },
    {
        "time": 1689836303000,
        "value": 139.521,
        "_internal_originalTime": 1689836303000
    },
    {
        "time": 1689836304000,
        "value": 139.521,
        "_internal_originalTime": 1689836304000
    },
    {
        "time": 1689836305000,
        "value": 139.524,
        "_internal_originalTime": 1689836305000
    },
    {
        "time": 1689836307000,
        "value": 139.524,
        "_internal_originalTime": 1689836307000
    },
    {
        "time": 1689836308000,
        "value": 139.524,
        "_internal_originalTime": 1689836308000
    },
    {
        "time": 1689836309000,
        "value": 139.518,
        "_internal_originalTime": 1689836309000
    },
    {
        "time": 1689836310000,
        "value": 139.518,
        "_internal_originalTime": 1689836310000
    },
    {
        "time": 1689836311000,
        "value": 139.522,
        "_internal_originalTime": 1689836311000
    },
    {
        "time": 1689836312000,
        "value": 139.523,
        "_internal_originalTime": 1689836312000
    },
    {
        "time": 1689836317000,
        "value": 139.518,
        "_internal_originalTime": 1689836317000
    },
    {
        "time": 1689836318000,
        "value": 139.524,
        "_internal_originalTime": 1689836318000
    },
    {
        "time": 1689836320000,
        "value": 139.532,
        "_internal_originalTime": 1689836320000
    },
    {
        "time": 1689836321000,
        "value": 139.53,
        "_internal_originalTime": 1689836321000
    },
    {
        "time": 1689836322000,
        "value": 139.531,
        "_internal_originalTime": 1689836322000
    },
    {
        "time": 1689836323000,
        "value": 139.528,
        "_internal_originalTime": 1689836323000
    },
    {
        "time": 1689836327000,
        "value": 139.526,
        "_internal_originalTime": 1689836327000
    },
    {
        "time": 1689836329000,
        "value": 139.528,
        "_internal_originalTime": 1689836329000
    },
    {
        "time": 1689836330000,
        "value": 139.529,
        "_internal_originalTime": 1689836330000
    },
    {
        "time": 1689836331000,
        "value": 139.524,
        "_internal_originalTime": 1689836331000
    },
    {
        "time": 1689836333000,
        "value": 139.525,
        "_internal_originalTime": 1689836333000
    },
    {
        "time": 1689836334000,
        "value": 139.528,
        "_internal_originalTime": 1689836334000
    },
    {
        "time": 1689836335000,
        "value": 139.529,
        "_internal_originalTime": 1689836335000
    },
    {
        "time": 1689836337000,
        "value": 139.525,
        "_internal_originalTime": 1689836337000
    },
    {
        "time": 1689836338000,
        "value": 139.524,
        "_internal_originalTime": 1689836338000
    },
    {
        "time": 1689836339000,
        "value": 139.527,
        "_internal_originalTime": 1689836339000
    },
    {
        "time": 1689836340000,
        "value": 139.525,
        "_internal_originalTime": 1689836340000
    },
    {
        "time": 1689836341000,
        "value": 139.5,
        "_internal_originalTime": 1689836341000
    },
    {
        "time": 1689836343000,
        "value": 139.523,
        "_internal_originalTime": 1689836343000
    },
    {
        "time": 1689836344000,
        "value": 139.527,
        "_internal_originalTime": 1689836344000
    },
    {
        "time": 1689836346000,
        "value": 139.527,
        "_internal_originalTime": 1689836346000
    },
    {
        "time": 1689836347000,
        "value": 139.527,
        "_internal_originalTime": 1689836347000
    },
    {
        "time": 1689836348000,
        "value": 139.528,
        "_internal_originalTime": 1689836348000
    },
    {
        "time": 1689836349000,
        "value": 139.524,
        "_internal_originalTime": 1689836349000
    },
    {
        "time": 1689836350000,
        "value": 139.524,
        "_internal_originalTime": 1689836350000
    },
    {
        "time": 1689836351000,
        "value": 139.529,
        "_internal_originalTime": 1689836351000
    },
    {
        "time": 1689836353000,
        "value": 139.524,
        "_internal_originalTime": 1689836353000
    },
    {
        "time": 1689836354000,
        "value": 139.523,
        "_internal_originalTime": 1689836354000
    },
    {
        "time": 1689836355000,
        "value": 139.528,
        "_internal_originalTime": 1689836355000
    },
    {
        "time": 1689836357000,
        "value": 139.524,
        "_internal_originalTime": 1689836357000
    },
    {
        "time": 1689836359000,
        "value": 139.529,
        "_internal_originalTime": 1689836359000
    },
    {
        "time": 1689836360000,
        "value": 139.522,
        "_internal_originalTime": 1689836360000
    },
    {
        "time": 1689836361000,
        "value": 139.523,
        "_internal_originalTime": 1689836361000
    },
    {
        "time": 1689836362000,
        "value": 139.528,
        "_internal_originalTime": 1689836362000
    },
    {
        "time": 1689836368000,
        "value": 139.529,
        "_internal_originalTime": 1689836368000
    },
    {
        "time": 1689836369000,
        "value": 139.53,
        "_internal_originalTime": 1689836369000
    },
    {
        "time": 1689836371000,
        "value": 139.53,
        "_internal_originalTime": 1689836371000
    },
    {
        "time": 1689836372000,
        "value": 139.53,
        "_internal_originalTime": 1689836372000
    },
    {
        "time": 1689836373000,
        "value": 139.525,
        "_internal_originalTime": 1689836373000
    },
    {
        "time": 1689836374000,
        "value": 139.529,
        "_internal_originalTime": 1689836374000
    },
    {
        "time": 1689836376000,
        "value": 139.523,
        "_internal_originalTime": 1689836376000
    },
    {
        "time": 1689836377000,
        "value": 139.525,
        "_internal_originalTime": 1689836377000
    },
    {
        "time": 1689836378000,
        "value": 139.525,
        "_internal_originalTime": 1689836378000
    },
    {
        "time": 1689836380000,
        "value": 139.518,
        "_internal_originalTime": 1689836380000
    },
    {
        "time": 1689836381000,
        "value": 139.52,
        "_internal_originalTime": 1689836381000
    },
    {
        "time": 1689836382000,
        "value": 139.521,
        "_internal_originalTime": 1689836382000
    },
    {
        "time": 1689836383000,
        "value": 139.524,
        "_internal_originalTime": 1689836383000
    },
    {
        "time": 1689836384000,
        "value": 139.525,
        "_internal_originalTime": 1689836384000
    },
    {
        "time": 1689836385000,
        "value": 139.521,
        "_internal_originalTime": 1689836385000
    },
    {
        "time": 1689836386000,
        "value": 139.525,
        "_internal_originalTime": 1689836386000
    },
    {
        "time": 1689836388000,
        "value": 139.52,
        "_internal_originalTime": 1689836388000
    },
    {
        "time": 1689836389000,
        "value": 139.524,
        "_internal_originalTime": 1689836389000
    },
    {
        "time": 1689836390000,
        "value": 139.525,
        "_internal_originalTime": 1689836390000
    },
    {
        "time": 1689836391000,
        "value": 139.525,
        "_internal_originalTime": 1689836391000
    },
    {
        "time": 1689836392000,
        "value": 139.522,
        "_internal_originalTime": 1689836392000
    },
    {
        "time": 1689836394000,
        "value": 139.521,
        "_internal_originalTime": 1689836394000
    },
    {
        "time": 1689836395000,
        "value": 139.524,
        "_internal_originalTime": 1689836395000
    },
    {
        "time": 1689836396000,
        "value": 139.527,
        "_internal_originalTime": 1689836396000
    },
    {
        "time": 1689836397000,
        "value": 139.522,
        "_internal_originalTime": 1689836397000
    },
    {
        "time": 1689836398000,
        "value": 139.523,
        "_internal_originalTime": 1689836398000
    },
    {
        "time": 1689836399000,
        "value": 139.522,
        "_internal_originalTime": 1689836399000
    },
    {
        "time": 1689836400000,
        "value": 139.526,
        "_internal_originalTime": 1689836400000
    },
    {
        "time": 1689836402000,
        "value": 139.521,
        "_internal_originalTime": 1689836402000
    },
    {
        "time": 1689836403000,
        "value": 139.534,
        "_internal_originalTime": 1689836403000
    },
    {
        "time": 1689836404000,
        "value": 139.535,
        "_internal_originalTime": 1689836404000
    },
    {
        "time": 1689836405000,
        "value": 139.535,
        "_internal_originalTime": 1689836405000
    },
    {
        "time": 1689836406000,
        "value": 139.528,
        "_internal_originalTime": 1689836406000
    },
    {
        "time": 1689836407000,
        "value": 139.53,
        "_internal_originalTime": 1689836407000
    },
    {
        "time": 1689836408000,
        "value": 139.527,
        "_internal_originalTime": 1689836408000
    },
    {
        "time": 1689836409000,
        "value": 139.51,
        "_internal_originalTime": 1689836409000
    },
    {
        "time": 1689836410000,
        "value": 139.509,
        "_internal_originalTime": 1689836410000
    },
    {
        "time": 1689836411000,
        "value": 139.521,
        "_internal_originalTime": 1689836411000
    },
    {
        "time": 1689836413000,
        "value": 139.516,
        "_internal_originalTime": 1689836413000
    },
    {
        "time": 1689836414000,
        "value": 139.521,
        "_internal_originalTime": 1689836414000
    },
    {
        "time": 1689836415000,
        "value": 139.522,
        "_internal_originalTime": 1689836415000
    },
    {
        "time": 1689836416000,
        "value": 139.522,
        "_internal_originalTime": 1689836416000
    },
    {
        "time": 1689836418000,
        "value": 139.519,
        "_internal_originalTime": 1689836418000
    },
    {
        "time": 1689836419000,
        "value": 139.52,
        "_internal_originalTime": 1689836419000
    },
    {
        "time": 1689836421000,
        "value": 139.517,
        "_internal_originalTime": 1689836421000
    },
    {
        "time": 1689836422000,
        "value": 139.518,
        "_internal_originalTime": 1689836422000
    },
    {
        "time": 1689836423000,
        "value": 139.52,
        "_internal_originalTime": 1689836423000
    },
    {
        "time": 1689836424000,
        "value": 139.514,
        "_internal_originalTime": 1689836424000
    },
    {
        "time": 1689836426000,
        "value": 139.516,
        "_internal_originalTime": 1689836426000
    },
    {
        "time": 1689836428000,
        "value": 139.512,
        "_internal_originalTime": 1689836428000
    },
    {
        "time": 1689836430000,
        "value": 139.502,
        "_internal_originalTime": 1689836430000
    },
    {
        "time": 1689836431000,
        "value": 139.503,
        "_internal_originalTime": 1689836431000
    },
    {
        "time": 1689836432000,
        "value": 139.497,
        "_internal_originalTime": 1689836432000
    },
    {
        "time": 1689836434000,
        "value": 139.492,
        "_internal_originalTime": 1689836434000
    },
    {
        "time": 1689836435000,
        "value": 139.481,
        "_internal_originalTime": 1689836435000
    },
    {
        "time": 1689836437000,
        "value": 139.482,
        "_internal_originalTime": 1689836437000
    },
    {
        "time": 1689836438000,
        "value": 139.472,
        "_internal_originalTime": 1689836438000
    },
    {
        "time": 1689836440000,
        "value": 139.473,
        "_internal_originalTime": 1689836440000
    },
    {
        "time": 1689836441000,
        "value": 139.471,
        "_internal_originalTime": 1689836441000
    },
    {
        "time": 1689836442000,
        "value": 139.474,
        "_internal_originalTime": 1689836442000
    },
    {
        "time": 1689836443000,
        "value": 139.476,
        "_internal_originalTime": 1689836443000
    },
    {
        "time": 1689836446000,
        "value": 139.472,
        "_internal_originalTime": 1689836446000
    },
    {
        "time": 1689836447000,
        "value": 139.477,
        "_internal_originalTime": 1689836447000
    },
    {
        "time": 1689836449000,
        "value": 139.484,
        "_internal_originalTime": 1689836449000
    },
    {
        "time": 1689836450000,
        "value": 139.488,
        "_internal_originalTime": 1689836450000
    },
    {
        "time": 1689836451000,
        "value": 139.486,
        "_internal_originalTime": 1689836451000
    },
    {
        "time": 1689836452000,
        "value": 139.49,
        "_internal_originalTime": 1689836452000
    },
    {
        "time": 1689836453000,
        "value": 139.49,
        "_internal_originalTime": 1689836453000
    },
    {
        "time": 1689836454000,
        "value": 139.487,
        "_internal_originalTime": 1689836454000
    },
    {
        "time": 1689836455000,
        "value": 139.49,
        "_internal_originalTime": 1689836455000
    },
    {
        "time": 1689836456000,
        "value": 139.493,
        "_internal_originalTime": 1689836456000
    },
    {
        "time": 1689836459000,
        "value": 139.489,
        "_internal_originalTime": 1689836459000
    },
    {
        "time": 1689836460000,
        "value": 139.489,
        "_internal_originalTime": 1689836460000
    },
    {
        "time": 1689836461000,
        "value": 139.478,
        "_internal_originalTime": 1689836461000
    },
    {
        "time": 1689836463000,
        "value": 139.481,
        "_internal_originalTime": 1689836463000
    },
    {
        "time": 1689836464000,
        "value": 139.486,
        "_internal_originalTime": 1689836464000
    },
    {
        "time": 1689836466000,
        "value": 139.48,
        "_internal_originalTime": 1689836466000
    },
    {
        "time": 1689836467000,
        "value": 139.482,
        "_internal_originalTime": 1689836467000
    },
    {
        "time": 1689836468000,
        "value": 139.478,
        "_internal_originalTime": 1689836468000
    },
    {
        "time": 1689836469000,
        "value": 139.476,
        "_internal_originalTime": 1689836469000
    },
    {
        "time": 1689836471000,
        "value": 139.476,
        "_internal_originalTime": 1689836471000
    },
    {
        "time": 1689836473000,
        "value": 139.475,
        "_internal_originalTime": 1689836473000
    },
    {
        "time": 1689836474000,
        "value": 139.467,
        "_internal_originalTime": 1689836474000
    },
    {
        "time": 1689836475000,
        "value": 139.473,
        "_internal_originalTime": 1689836475000
    },
    {
        "time": 1689836476000,
        "value": 139.473,
        "_internal_originalTime": 1689836476000
    },
    {
        "time": 1689836477000,
        "value": 139.465,
        "_internal_originalTime": 1689836477000
    },
    {
        "time": 1689836478000,
        "value": 139.464,
        "_internal_originalTime": 1689836478000
    },
    {
        "time": 1689836479000,
        "value": 139.464,
        "_internal_originalTime": 1689836479000
    },
    {
        "time": 1689836481000,
        "value": 139.465,
        "_internal_originalTime": 1689836481000
    },
    {
        "time": 1689836482000,
        "value": 139.458,
        "_internal_originalTime": 1689836482000
    },
    {
        "time": 1689836483000,
        "value": 139.463,
        "_internal_originalTime": 1689836483000
    },
    {
        "time": 1689836485000,
        "value": 139.46,
        "_internal_originalTime": 1689836485000
    },
    {
        "time": 1689836486000,
        "value": 139.462,
        "_internal_originalTime": 1689836486000
    },
    {
        "time": 1689836487000,
        "value": 139.46,
        "_internal_originalTime": 1689836487000
    },
    {
        "time": 1689836488000,
        "value": 139.452,
        "_internal_originalTime": 1689836488000
    },
    {
        "time": 1689836489000,
        "value": 139.443,
        "_internal_originalTime": 1689836489000
    },
    {
        "time": 1689836490000,
        "value": 139.442,
        "_internal_originalTime": 1689836490000
    },
    {
        "time": 1689836491000,
        "value": 139.437,
        "_internal_originalTime": 1689836491000
    },
    {
        "time": 1689836492000,
        "value": 139.438,
        "_internal_originalTime": 1689836492000
    },
    {
        "time": 1689836493000,
        "value": 139.442,
        "_internal_originalTime": 1689836493000
    },
    {
        "time": 1689836494000,
        "value": 139.437,
        "_internal_originalTime": 1689836494000
    },
    {
        "time": 1689836495000,
        "value": 139.442,
        "_internal_originalTime": 1689836495000
    },
    {
        "time": 1689836496000,
        "value": 139.442,
        "_internal_originalTime": 1689836496000
    },
    {
        "time": 1689836498000,
        "value": 139.444,
        "_internal_originalTime": 1689836498000
    },
    {
        "time": 1689836500000,
        "value": 139.426,
        "_internal_originalTime": 1689836500000
    },
    {
        "time": 1689836501000,
        "value": 139.424,
        "_internal_originalTime": 1689836501000
    },
    {
        "time": 1689836502000,
        "value": 139.428,
        "_internal_originalTime": 1689836502000
    },
    {
        "time": 1689836503000,
        "value": 139.435,
        "_internal_originalTime": 1689836503000
    },
    {
        "time": 1689836504000,
        "value": 139.439,
        "_internal_originalTime": 1689836504000
    },
    {
        "time": 1689836505000,
        "value": 139.443,
        "_internal_originalTime": 1689836505000
    },
    {
        "time": 1689836506000,
        "value": 139.437,
        "_internal_originalTime": 1689836506000
    },
    {
        "time": 1689836508000,
        "value": 139.437,
        "_internal_originalTime": 1689836508000
    },
    {
        "time": 1689836509000,
        "value": 139.435,
        "_internal_originalTime": 1689836509000
    },
    {
        "time": 1689836510000,
        "value": 139.443,
        "_internal_originalTime": 1689836510000
    },
    {
        "time": 1689836511000,
        "value": 139.439,
        "_internal_originalTime": 1689836511000
    },
    {
        "time": 1689836512000,
        "value": 139.435,
        "_internal_originalTime": 1689836512000
    },
    {
        "time": 1689836513000,
        "value": 139.439,
        "_internal_originalTime": 1689836513000
    },
    {
        "time": 1689836514000,
        "value": 139.443,
        "_internal_originalTime": 1689836514000
    },
    {
        "time": 1689836516000,
        "value": 139.443,
        "_internal_originalTime": 1689836516000
    },
    {
        "time": 1689836517000,
        "value": 139.442,
        "_internal_originalTime": 1689836517000
    },
    {
        "time": 1689836518000,
        "value": 139.444,
        "_internal_originalTime": 1689836518000
    },
    {
        "time": 1689836519000,
        "value": 139.448,
        "_internal_originalTime": 1689836519000
    },
    {
        "time": 1689836520000,
        "value": 139.438,
        "_internal_originalTime": 1689836520000
    },
    {
        "time": 1689836521000,
        "value": 139.443,
        "_internal_originalTime": 1689836521000
    },
    {
        "time": 1689836522000,
        "value": 139.443,
        "_internal_originalTime": 1689836522000
    },
    {
        "time": 1689836524000,
        "value": 139.445,
        "_internal_originalTime": 1689836524000
    },
    {
        "time": 1689836526000,
        "value": 139.445,
        "_internal_originalTime": 1689836526000
    },
    {
        "time": 1689836527000,
        "value": 139.439,
        "_internal_originalTime": 1689836527000
    },
    {
        "time": 1689836528000,
        "value": 139.443,
        "_internal_originalTime": 1689836528000
    },
    {
        "time": 1689836530000,
        "value": 139.443,
        "_internal_originalTime": 1689836530000
    },
    {
        "time": 1689836531000,
        "value": 139.439,
        "_internal_originalTime": 1689836531000
    },
    {
        "time": 1689836532000,
        "value": 139.442,
        "_internal_originalTime": 1689836532000
    },
    {
        "time": 1689836533000,
        "value": 139.445,
        "_internal_originalTime": 1689836533000
    },
    {
        "time": 1689836534000,
        "value": 139.44,
        "_internal_originalTime": 1689836534000
    },
    {
        "time": 1689836535000,
        "value": 139.438,
        "_internal_originalTime": 1689836535000
    },
    {
        "time": 1689836536000,
        "value": 139.439,
        "_internal_originalTime": 1689836536000
    },
    {
        "time": 1689836537000,
        "value": 139.445,
        "_internal_originalTime": 1689836537000
    },
    {
        "time": 1689836538000,
        "value": 139.44,
        "_internal_originalTime": 1689836538000
    },
    {
        "time": 1689836539000,
        "value": 139.44,
        "_internal_originalTime": 1689836539000
    },
    {
        "time": 1689836541000,
        "value": 139.446,
        "_internal_originalTime": 1689836541000
    },
    {
        "time": 1689836542000,
        "value": 139.442,
        "_internal_originalTime": 1689836542000
    },
    {
        "time": 1689836543000,
        "value": 139.442,
        "_internal_originalTime": 1689836543000
    },
    {
        "time": 1689836544000,
        "value": 139.446,
        "_internal_originalTime": 1689836544000
    },
    {
        "time": 1689836545000,
        "value": 139.442,
        "_internal_originalTime": 1689836545000
    },
    {
        "time": 1689836546000,
        "value": 139.442,
        "_internal_originalTime": 1689836546000
    },
    {
        "time": 1689836548000,
        "value": 139.439,
        "_internal_originalTime": 1689836548000
    },
    {
        "time": 1689836549000,
        "value": 139.432,
        "_internal_originalTime": 1689836549000
    },
    {
        "time": 1689836550000,
        "value": 139.442,
        "_internal_originalTime": 1689836550000
    },
    {
        "time": 1689836552000,
        "value": 139.438,
        "_internal_originalTime": 1689836552000
    },
    {
        "time": 1689836553000,
        "value": 139.443,
        "_internal_originalTime": 1689836553000
    },
    {
        "time": 1689836554000,
        "value": 139.443,
        "_internal_originalTime": 1689836554000
    },
    {
        "time": 1689836555000,
        "value": 139.438,
        "_internal_originalTime": 1689836555000
    },
    {
        "time": 1689836556000,
        "value": 139.443,
        "_internal_originalTime": 1689836556000
    },
    {
        "time": 1689836557000,
        "value": 139.438,
        "_internal_originalTime": 1689836557000
    },
    {
        "time": 1689836558000,
        "value": 139.439,
        "_internal_originalTime": 1689836558000
    },
    {
        "time": 1689836559000,
        "value": 139.438,
        "_internal_originalTime": 1689836559000
    },
    {
        "time": 1689836561000,
        "value": 139.44,
        "_internal_originalTime": 1689836561000
    },
    {
        "time": 1689836562000,
        "value": 139.442,
        "_internal_originalTime": 1689836562000
    },
    {
        "time": 1689836563000,
        "value": 139.443,
        "_internal_originalTime": 1689836563000
    },
    {
        "time": 1689836564000,
        "value": 139.443,
        "_internal_originalTime": 1689836564000
    },
    {
        "time": 1689836565000,
        "value": 139.441,
        "_internal_originalTime": 1689836565000
    },
    {
        "time": 1689836566000,
        "value": 139.445,
        "_internal_originalTime": 1689836566000
    },
    {
        "time": 1689836567000,
        "value": 139.443,
        "_internal_originalTime": 1689836567000
    },
    {
        "time": 1689836568000,
        "value": 139.443,
        "_internal_originalTime": 1689836568000
    },
    {
        "time": 1689836569000,
        "value": 139.438,
        "_internal_originalTime": 1689836569000
    },
    {
        "time": 1689836570000,
        "value": 139.434,
        "_internal_originalTime": 1689836570000
    },
    {
        "time": 1689836571000,
        "value": 139.436,
        "_internal_originalTime": 1689836571000
    },
    {
        "time": 1689836572000,
        "value": 139.44,
        "_internal_originalTime": 1689836572000
    },
    {
        "time": 1689836574000,
        "value": 139.438,
        "_internal_originalTime": 1689836574000
    },
    {
        "time": 1689836576000,
        "value": 139.434,
        "_internal_originalTime": 1689836576000
    },
    {
        "time": 1689836577000,
        "value": 139.434,
        "_internal_originalTime": 1689836577000
    },
    {
        "time": 1689836578000,
        "value": 139.436,
        "_internal_originalTime": 1689836578000
    },
    {
        "time": 1689836579000,
        "value": 139.444,
        "_internal_originalTime": 1689836579000
    },
    {
        "time": 1689836580000,
        "value": 139.438,
        "_internal_originalTime": 1689836580000
    },
    {
        "time": 1689836582000,
        "value": 139.441,
        "_internal_originalTime": 1689836582000
    },
    {
        "time": 1689836583000,
        "value": 139.445,
        "_internal_originalTime": 1689836583000
    },
    {
        "time": 1689836584000,
        "value": 139.442,
        "_internal_originalTime": 1689836584000
    },
    {
        "time": 1689836586000,
        "value": 139.44,
        "_internal_originalTime": 1689836586000
    },
    {
        "time": 1689836587000,
        "value": 139.447,
        "_internal_originalTime": 1689836587000
    },
    {
        "time": 1689836590000,
        "value": 139.446,
        "_internal_originalTime": 1689836590000
    },
    {
        "time": 1689836591000,
        "value": 139.445,
        "_internal_originalTime": 1689836591000
    },
    {
        "time": 1689836593000,
        "value": 139.44,
        "_internal_originalTime": 1689836593000
    },
    {
        "time": 1689836594000,
        "value": 139.438,
        "_internal_originalTime": 1689836594000
    },
    {
        "time": 1689836595000,
        "value": 139.444,
        "_internal_originalTime": 1689836595000
    },
    {
        "time": 1689836596000,
        "value": 139.44,
        "_internal_originalTime": 1689836596000
    },
    {
        "time": 1689836597000,
        "value": 139.446,
        "_internal_originalTime": 1689836597000
    },
    {
        "time": 1689836599000,
        "value": 139.443,
        "_internal_originalTime": 1689836599000
    },
    {
        "time": 1689836600000,
        "value": 139.443,
        "_internal_originalTime": 1689836600000
    },
    {
        "time": 1689836602000,
        "value": 139.438,
        "_internal_originalTime": 1689836602000
    },
    {
        "time": 1689836603000,
        "value": 139.439,
        "_internal_originalTime": 1689836603000
    },
    {
        "time": 1689836604000,
        "value": 139.432,
        "_internal_originalTime": 1689836604000
    },
    {
        "time": 1689836605000,
        "value": 139.432,
        "_internal_originalTime": 1689836605000
    },
    {
        "time": 1689836606000,
        "value": 139.426,
        "_internal_originalTime": 1689836606000
    },
    {
        "time": 1689836607000,
        "value": 139.432,
        "_internal_originalTime": 1689836607000
    },
    {
        "time": 1689836609000,
        "value": 139.428,
        "_internal_originalTime": 1689836609000
    },
    {
        "time": 1689836610000,
        "value": 139.427,
        "_internal_originalTime": 1689836610000
    },
    {
        "time": 1689836611000,
        "value": 139.434,
        "_internal_originalTime": 1689836611000
    },
    {
        "time": 1689836612000,
        "value": 139.427,
        "_internal_originalTime": 1689836612000
    },
    {
        "time": 1689836614000,
        "value": 139.429,
        "_internal_originalTime": 1689836614000
    },
    {
        "time": 1689836615000,
        "value": 139.426,
        "_internal_originalTime": 1689836615000
    },
    {
        "time": 1689836616000,
        "value": 139.432,
        "_internal_originalTime": 1689836616000
    },
    {
        "time": 1689836617000,
        "value": 139.418,
        "_internal_originalTime": 1689836617000
    },
    {
        "time": 1689836618000,
        "value": 139.424,
        "_internal_originalTime": 1689836618000
    },
    {
        "time": 1689836619000,
        "value": 139.426,
        "_internal_originalTime": 1689836619000
    },
    {
        "time": 1689836620000,
        "value": 139.421,
        "_internal_originalTime": 1689836620000
    },
    {
        "time": 1689836621000,
        "value": 139.425,
        "_internal_originalTime": 1689836621000
    },
    {
        "time": 1689836622000,
        "value": 139.425,
        "_internal_originalTime": 1689836622000
    },
    {
        "time": 1689836623000,
        "value": 139.425,
        "_internal_originalTime": 1689836623000
    },
    {
        "time": 1689836624000,
        "value": 139.418,
        "_internal_originalTime": 1689836624000
    },
    {
        "time": 1689836626000,
        "value": 139.426,
        "_internal_originalTime": 1689836626000
    },
    {
        "time": 1689836628000,
        "value": 139.426,
        "_internal_originalTime": 1689836628000
    },
    {
        "time": 1689836629000,
        "value": 139.423,
        "_internal_originalTime": 1689836629000
    },
    {
        "time": 1689836630000,
        "value": 139.42,
        "_internal_originalTime": 1689836630000
    },
    {
        "time": 1689836631000,
        "value": 139.42,
        "_internal_originalTime": 1689836631000
    },
    {
        "time": 1689836632000,
        "value": 139.42,
        "_internal_originalTime": 1689836632000
    },
    {
        "time": 1689836633000,
        "value": 139.423,
        "_internal_originalTime": 1689836633000
    },
    {
        "time": 1689836634000,
        "value": 139.423,
        "_internal_originalTime": 1689836634000
    },
    {
        "time": 1689836635000,
        "value": 139.424,
        "_internal_originalTime": 1689836635000
    },
    {
        "time": 1689836636000,
        "value": 139.419,
        "_internal_originalTime": 1689836636000
    },
    {
        "time": 1689836639000,
        "value": 139.419,
        "_internal_originalTime": 1689836639000
    },
    {
        "time": 1689836640000,
        "value": 139.4,
        "_internal_originalTime": 1689836640000
    },
    {
        "time": 1689836641000,
        "value": 139.417,
        "_internal_originalTime": 1689836641000
    },
    {
        "time": 1689836642000,
        "value": 139.415,
        "_internal_originalTime": 1689836642000
    },
    {
        "time": 1689836645000,
        "value": 139.416,
        "_internal_originalTime": 1689836645000
    },
    {
        "time": 1689836646000,
        "value": 139.41,
        "_internal_originalTime": 1689836646000
    },
    {
        "time": 1689836647000,
        "value": 139.417,
        "_internal_originalTime": 1689836647000
    },
    {
        "time": 1689836648000,
        "value": 139.41,
        "_internal_originalTime": 1689836648000
    },
    {
        "time": 1689836649000,
        "value": 139.412,
        "_internal_originalTime": 1689836649000
    },
    {
        "time": 1689836650000,
        "value": 139.417,
        "_internal_originalTime": 1689836650000
    },
    {
        "time": 1689836651000,
        "value": 139.413,
        "_internal_originalTime": 1689836651000
    },
    {
        "time": 1689836652000,
        "value": 139.418,
        "_internal_originalTime": 1689836652000
    },
    {
        "time": 1689836653000,
        "value": 139.421,
        "_internal_originalTime": 1689836653000
    },
    {
        "time": 1689836654000,
        "value": 139.419,
        "_internal_originalTime": 1689836654000
    },
    {
        "time": 1689836655000,
        "value": 139.422,
        "_internal_originalTime": 1689836655000
    },
    {
        "time": 1689836656000,
        "value": 139.417,
        "_internal_originalTime": 1689836656000
    },
    {
        "time": 1689836657000,
        "value": 139.417,
        "_internal_originalTime": 1689836657000
    },
    {
        "time": 1689836658000,
        "value": 139.417,
        "_internal_originalTime": 1689836658000
    },
    {
        "time": 1689836659000,
        "value": 139.412,
        "_internal_originalTime": 1689836659000
    },
    {
        "time": 1689836660000,
        "value": 139.408,
        "_internal_originalTime": 1689836660000
    },
    {
        "time": 1689836662000,
        "value": 139.414,
        "_internal_originalTime": 1689836662000
    },
    {
        "time": 1689836664000,
        "value": 139.407,
        "_internal_originalTime": 1689836664000
    },
    {
        "time": 1689836665000,
        "value": 139.405,
        "_internal_originalTime": 1689836665000
    },
    {
        "time": 1689836666000,
        "value": 139.405,
        "_internal_originalTime": 1689836666000
    },
    {
        "time": 1689836667000,
        "value": 139.407,
        "_internal_originalTime": 1689836667000
    },
    {
        "time": 1689836669000,
        "value": 139.407,
        "_internal_originalTime": 1689836669000
    },
    {
        "time": 1689836670000,
        "value": 139.401,
        "_internal_originalTime": 1689836670000
    },
    {
        "time": 1689836671000,
        "value": 139.414,
        "_internal_originalTime": 1689836671000
    },
    {
        "time": 1689836672000,
        "value": 139.417,
        "_internal_originalTime": 1689836672000
    },
    {
        "time": 1689836674000,
        "value": 139.422,
        "_internal_originalTime": 1689836674000
    },
    {
        "time": 1689836676000,
        "value": 139.422,
        "_internal_originalTime": 1689836676000
    },
    {
        "time": 1689836677000,
        "value": 139.419,
        "_internal_originalTime": 1689836677000
    },
    {
        "time": 1689836678000,
        "value": 139.419,
        "_internal_originalTime": 1689836678000
    },
    {
        "time": 1689836679000,
        "value": 139.421,
        "_internal_originalTime": 1689836679000
    },
    {
        "time": 1689836680000,
        "value": 139.416,
        "_internal_originalTime": 1689836680000
    },
    {
        "time": 1689836681000,
        "value": 139.42,
        "_internal_originalTime": 1689836681000
    },
    {
        "time": 1689836682000,
        "value": 139.418,
        "_internal_originalTime": 1689836682000
    },
    {
        "time": 1689836683000,
        "value": 139.42,
        "_internal_originalTime": 1689836683000
    },
    {
        "time": 1689836684000,
        "value": 139.416,
        "_internal_originalTime": 1689836684000
    },
    {
        "time": 1689836685000,
        "value": 139.42,
        "_internal_originalTime": 1689836685000
    },
    {
        "time": 1689836686000,
        "value": 139.417,
        "_internal_originalTime": 1689836686000
    },
    {
        "time": 1689836690000,
        "value": 139.414,
        "_internal_originalTime": 1689836690000
    },
    {
        "time": 1689836691000,
        "value": 139.42,
        "_internal_originalTime": 1689836691000
    },
    {
        "time": 1689836692000,
        "value": 139.416,
        "_internal_originalTime": 1689836692000
    },
    {
        "time": 1689836693000,
        "value": 139.416,
        "_internal_originalTime": 1689836693000
    },
    {
        "time": 1689836695000,
        "value": 139.423,
        "_internal_originalTime": 1689836695000
    },
    {
        "time": 1689836696000,
        "value": 139.412,
        "_internal_originalTime": 1689836696000
    },
    {
        "time": 1689836697000,
        "value": 139.414,
        "_internal_originalTime": 1689836697000
    },
    {
        "time": 1689836698000,
        "value": 139.413,
        "_internal_originalTime": 1689836698000
    },
    {
        "time": 1689836700000,
        "value": 139.417,
        "_internal_originalTime": 1689836700000
    },
    {
        "time": 1689836701000,
        "value": 139.39,
        "_internal_originalTime": 1689836701000
    },
    {
        "time": 1689836702000,
        "value": 139.411,
        "_internal_originalTime": 1689836702000
    },
    {
        "time": 1689836703000,
        "value": 139.416,
        "_internal_originalTime": 1689836703000
    },
    {
        "time": 1689836704000,
        "value": 139.412,
        "_internal_originalTime": 1689836704000
    },
    {
        "time": 1689836705000,
        "value": 139.416,
        "_internal_originalTime": 1689836705000
    },
    {
        "time": 1689836706000,
        "value": 139.418,
        "_internal_originalTime": 1689836706000
    },
    {
        "time": 1689836707000,
        "value": 139.42,
        "_internal_originalTime": 1689836707000
    },
    {
        "time": 1689836708000,
        "value": 139.42,
        "_internal_originalTime": 1689836708000
    },
    {
        "time": 1689836710000,
        "value": 139.417,
        "_internal_originalTime": 1689836710000
    },
    {
        "time": 1689836711000,
        "value": 139.415,
        "_internal_originalTime": 1689836711000
    },
    {
        "time": 1689836712000,
        "value": 139.42,
        "_internal_originalTime": 1689836712000
    },
    {
        "time": 1689836714000,
        "value": 139.416,
        "_internal_originalTime": 1689836714000
    },
    {
        "time": 1689836715000,
        "value": 139.422,
        "_internal_originalTime": 1689836715000
    },
    {
        "time": 1689836716000,
        "value": 139.416,
        "_internal_originalTime": 1689836716000
    },
    {
        "time": 1689836717000,
        "value": 139.417,
        "_internal_originalTime": 1689836717000
    },
    {
        "time": 1689836718000,
        "value": 139.421,
        "_internal_originalTime": 1689836718000
    },
    {
        "time": 1689836719000,
        "value": 139.427,
        "_internal_originalTime": 1689836719000
    },
    {
        "time": 1689836720000,
        "value": 139.426,
        "_internal_originalTime": 1689836720000
    },
    {
        "time": 1689836721000,
        "value": 139.429,
        "_internal_originalTime": 1689836721000
    },
    {
        "time": 1689836722000,
        "value": 139.428,
        "_internal_originalTime": 1689836722000
    },
    {
        "time": 1689836724000,
        "value": 139.437,
        "_internal_originalTime": 1689836724000
    },
    {
        "time": 1689836725000,
        "value": 139.443,
        "_internal_originalTime": 1689836725000
    },
    {
        "time": 1689836727000,
        "value": 139.445,
        "_internal_originalTime": 1689836727000
    },
    {
        "time": 1689836728000,
        "value": 139.442,
        "_internal_originalTime": 1689836728000
    },
    {
        "time": 1689836729000,
        "value": 139.45,
        "_internal_originalTime": 1689836729000
    },
    {
        "time": 1689836730000,
        "value": 139.455,
        "_internal_originalTime": 1689836730000
    },
    {
        "time": 1689836731000,
        "value": 139.448,
        "_internal_originalTime": 1689836731000
    },
    {
        "time": 1689836733000,
        "value": 139.452,
        "_internal_originalTime": 1689836733000
    },
    {
        "time": 1689836734000,
        "value": 139.453,
        "_internal_originalTime": 1689836734000
    },
    {
        "time": 1689836735000,
        "value": 139.449,
        "_internal_originalTime": 1689836735000
    },
    {
        "time": 1689836738000,
        "value": 139.453,
        "_internal_originalTime": 1689836738000
    },
    {
        "time": 1689836740000,
        "value": 139.457,
        "_internal_originalTime": 1689836740000
    },
    {
        "time": 1689836741000,
        "value": 139.452,
        "_internal_originalTime": 1689836741000
    },
    {
        "time": 1689836743000,
        "value": 139.453,
        "_internal_originalTime": 1689836743000
    },
    {
        "time": 1689836744000,
        "value": 139.453,
        "_internal_originalTime": 1689836744000
    },
    {
        "time": 1689836745000,
        "value": 139.453,
        "_internal_originalTime": 1689836745000
    },
    {
        "time": 1689836746000,
        "value": 139.452,
        "_internal_originalTime": 1689836746000
    },
    {
        "time": 1689836747000,
        "value": 139.453,
        "_internal_originalTime": 1689836747000
    },
    {
        "time": 1689836748000,
        "value": 139.448,
        "_internal_originalTime": 1689836748000
    },
    {
        "time": 1689836749000,
        "value": 139.439,
        "_internal_originalTime": 1689836749000
    },
    {
        "time": 1689836750000,
        "value": 139.437,
        "_internal_originalTime": 1689836750000
    },
    {
        "time": 1689836752000,
        "value": 139.438,
        "_internal_originalTime": 1689836752000
    },
    {
        "time": 1689836754000,
        "value": 139.434,
        "_internal_originalTime": 1689836754000
    },
    {
        "time": 1689836755000,
        "value": 139.438,
        "_internal_originalTime": 1689836755000
    },
    {
        "time": 1689836756000,
        "value": 139.445,
        "_internal_originalTime": 1689836756000
    },
    {
        "time": 1689836757000,
        "value": 139.447,
        "_internal_originalTime": 1689836757000
    },
    {
        "time": 1689836758000,
        "value": 139.441,
        "_internal_originalTime": 1689836758000
    },
    {
        "time": 1689836760000,
        "value": 139.445,
        "_internal_originalTime": 1689836760000
    },
    {
        "time": 1689836762000,
        "value": 139.446,
        "_internal_originalTime": 1689836762000
    },
    {
        "time": 1689836764000,
        "value": 139.443,
        "_internal_originalTime": 1689836764000
    },
    {
        "time": 1689836766000,
        "value": 139.441,
        "_internal_originalTime": 1689836766000
    },
    {
        "time": 1689836767000,
        "value": 139.442,
        "_internal_originalTime": 1689836767000
    },
    {
        "time": 1689836768000,
        "value": 139.447,
        "_internal_originalTime": 1689836768000
    },
    {
        "time": 1689836769000,
        "value": 139.447,
        "_internal_originalTime": 1689836769000
    },
    {
        "time": 1689836770000,
        "value": 139.447,
        "_internal_originalTime": 1689836770000
    },
    {
        "time": 1689836771000,
        "value": 139.439,
        "_internal_originalTime": 1689836771000
    },
    {
        "time": 1689836772000,
        "value": 139.442,
        "_internal_originalTime": 1689836772000
    },
    {
        "time": 1689836774000,
        "value": 139.446,
        "_internal_originalTime": 1689836774000
    },
    {
        "time": 1689836775000,
        "value": 139.445,
        "_internal_originalTime": 1689836775000
    },
    {
        "time": 1689836776000,
        "value": 139.446,
        "_internal_originalTime": 1689836776000
    },
    {
        "time": 1689836777000,
        "value": 139.439,
        "_internal_originalTime": 1689836777000
    },
    {
        "time": 1689836779000,
        "value": 139.428,
        "_internal_originalTime": 1689836779000
    },
    {
        "time": 1689836781000,
        "value": 139.436,
        "_internal_originalTime": 1689836781000
    },
    {
        "time": 1689836782000,
        "value": 139.437,
        "_internal_originalTime": 1689836782000
    },
    {
        "time": 1689836784000,
        "value": 139.439,
        "_internal_originalTime": 1689836784000
    },
    {
        "time": 1689836785000,
        "value": 139.438,
        "_internal_originalTime": 1689836785000
    },
    {
        "time": 1689836786000,
        "value": 139.43,
        "_internal_originalTime": 1689836786000
    },
    {
        "time": 1689836787000,
        "value": 139.432,
        "_internal_originalTime": 1689836787000
    },
    {
        "time": 1689836790000,
        "value": 139.434,
        "_internal_originalTime": 1689836790000
    },
    {
        "time": 1689836791000,
        "value": 139.434,
        "_internal_originalTime": 1689836791000
    },
    {
        "time": 1689836793000,
        "value": 139.438,
        "_internal_originalTime": 1689836793000
    },
    {
        "time": 1689836794000,
        "value": 139.437,
        "_internal_originalTime": 1689836794000
    },
    {
        "time": 1689836796000,
        "value": 139.438,
        "_internal_originalTime": 1689836796000
    },
    {
        "time": 1689836797000,
        "value": 139.432,
        "_internal_originalTime": 1689836797000
    },
    {
        "time": 1689836798000,
        "value": 139.432,
        "_internal_originalTime": 1689836798000
    },
    {
        "time": 1689836800000,
        "value": 139.438,
        "_internal_originalTime": 1689836800000
    },
    {
        "time": 1689836801000,
        "value": 139.441,
        "_internal_originalTime": 1689836801000
    },
    {
        "time": 1689836803000,
        "value": 139.444,
        "_internal_originalTime": 1689836803000
    },
    {
        "time": 1689836804000,
        "value": 139.445,
        "_internal_originalTime": 1689836804000
    },
    {
        "time": 1689836805000,
        "value": 139.448,
        "_internal_originalTime": 1689836805000
    },
    {
        "time": 1689836807000,
        "value": 139.44,
        "_internal_originalTime": 1689836807000
    },
    {
        "time": 1689836810000,
        "value": 139.438,
        "_internal_originalTime": 1689836810000
    },
    {
        "time": 1689836811000,
        "value": 139.435,
        "_internal_originalTime": 1689836811000
    },
    {
        "time": 1689836812000,
        "value": 139.437,
        "_internal_originalTime": 1689836812000
    },
    {
        "time": 1689836813000,
        "value": 139.43,
        "_internal_originalTime": 1689836813000
    },
    {
        "time": 1689836814000,
        "value": 139.426,
        "_internal_originalTime": 1689836814000
    },
    {
        "time": 1689836815000,
        "value": 139.427,
        "_internal_originalTime": 1689836815000
    },
    {
        "time": 1689836816000,
        "value": 139.42,
        "_internal_originalTime": 1689836816000
    },
    {
        "time": 1689836819000,
        "value": 139.421,
        "_internal_originalTime": 1689836819000
    },
    {
        "time": 1689836820000,
        "value": 139.418,
        "_internal_originalTime": 1689836820000
    },
    {
        "time": 1689836821000,
        "value": 139.4,
        "_internal_originalTime": 1689836821000
    },
    {
        "time": 1689836822000,
        "value": 139.407,
        "_internal_originalTime": 1689836822000
    },
    {
        "time": 1689836823000,
        "value": 139.413,
        "_internal_originalTime": 1689836823000
    },
    {
        "time": 1689836824000,
        "value": 139.41,
        "_internal_originalTime": 1689836824000
    },
    {
        "time": 1689836825000,
        "value": 139.413,
        "_internal_originalTime": 1689836825000
    },
    {
        "time": 1689836826000,
        "value": 139.41,
        "_internal_originalTime": 1689836826000
    },
    {
        "time": 1689836827000,
        "value": 139.41,
        "_internal_originalTime": 1689836827000
    },
    {
        "time": 1689836829000,
        "value": 139.413,
        "_internal_originalTime": 1689836829000
    },
    {
        "time": 1689836830000,
        "value": 139.413,
        "_internal_originalTime": 1689836830000
    },
    {
        "time": 1689836831000,
        "value": 139.409,
        "_internal_originalTime": 1689836831000
    },
    {
        "time": 1689836833000,
        "value": 139.411,
        "_internal_originalTime": 1689836833000
    },
    {
        "time": 1689836834000,
        "value": 139.412,
        "_internal_originalTime": 1689836834000
    },
    {
        "time": 1689836835000,
        "value": 139.412,
        "_internal_originalTime": 1689836835000
    },
    {
        "time": 1689836837000,
        "value": 139.414,
        "_internal_originalTime": 1689836837000
    },
    {
        "time": 1689836839000,
        "value": 139.416,
        "_internal_originalTime": 1689836839000
    },
    {
        "time": 1689836841000,
        "value": 139.41,
        "_internal_originalTime": 1689836841000
    },
    {
        "time": 1689836842000,
        "value": 139.413,
        "_internal_originalTime": 1689836842000
    },
    {
        "time": 1689836846000,
        "value": 139.416,
        "_internal_originalTime": 1689836846000
    },
    {
        "time": 1689836847000,
        "value": 139.411,
        "_internal_originalTime": 1689836847000
    },
    {
        "time": 1689836848000,
        "value": 139.41,
        "_internal_originalTime": 1689836848000
    },
    {
        "time": 1689836849000,
        "value": 139.412,
        "_internal_originalTime": 1689836849000
    },
    {
        "time": 1689836850000,
        "value": 139.408,
        "_internal_originalTime": 1689836850000
    },
    {
        "time": 1689836851000,
        "value": 139.413,
        "_internal_originalTime": 1689836851000
    },
    {
        "time": 1689836852000,
        "value": 139.409,
        "_internal_originalTime": 1689836852000
    },
    {
        "time": 1689836853000,
        "value": 139.408,
        "_internal_originalTime": 1689836853000
    },
    {
        "time": 1689836854000,
        "value": 139.409,
        "_internal_originalTime": 1689836854000
    },
    {
        "time": 1689836855000,
        "value": 139.409,
        "_internal_originalTime": 1689836855000
    },
    {
        "time": 1689836856000,
        "value": 139.408,
        "_internal_originalTime": 1689836856000
    },
    {
        "time": 1689836861000,
        "value": 139.405,
        "_internal_originalTime": 1689836861000
    },
    {
        "time": 1689836862000,
        "value": 139.4,
        "_internal_originalTime": 1689836862000
    },
    {
        "time": 1689836863000,
        "value": 139.401,
        "_internal_originalTime": 1689836863000
    },
    {
        "time": 1689836864000,
        "value": 139.401,
        "_internal_originalTime": 1689836864000
    },
    {
        "time": 1689836865000,
        "value": 139.394,
        "_internal_originalTime": 1689836865000
    },
    {
        "time": 1689836866000,
        "value": 139.396,
        "_internal_originalTime": 1689836866000
    },
    {
        "time": 1689836867000,
        "value": 139.392,
        "_internal_originalTime": 1689836867000
    },
    {
        "time": 1689836869000,
        "value": 139.394,
        "_internal_originalTime": 1689836869000
    },
    {
        "time": 1689836870000,
        "value": 139.403,
        "_internal_originalTime": 1689836870000
    },
    {
        "time": 1689836871000,
        "value": 139.382,
        "_internal_originalTime": 1689836871000
    },
    {
        "time": 1689836872000,
        "value": 139.383,
        "_internal_originalTime": 1689836872000
    },
    {
        "time": 1689836873000,
        "value": 139.385,
        "_internal_originalTime": 1689836873000
    },
    {
        "time": 1689836874000,
        "value": 139.378,
        "_internal_originalTime": 1689836874000
    },
    {
        "time": 1689836875000,
        "value": 139.385,
        "_internal_originalTime": 1689836875000
    },
    {
        "time": 1689836877000,
        "value": 139.38,
        "_internal_originalTime": 1689836877000
    },
    {
        "time": 1689836879000,
        "value": 139.384,
        "_internal_originalTime": 1689836879000
    },
    {
        "time": 1689836880000,
        "value": 139.385,
        "_internal_originalTime": 1689836880000
    },
    {
        "time": 1689836881000,
        "value": 139.383,
        "_internal_originalTime": 1689836881000
    },
    {
        "time": 1689836882000,
        "value": 139.385,
        "_internal_originalTime": 1689836882000
    },
    {
        "time": 1689836883000,
        "value": 139.384,
        "_internal_originalTime": 1689836883000
    },
    {
        "time": 1689836884000,
        "value": 139.389,
        "_internal_originalTime": 1689836884000
    },
    {
        "time": 1689836886000,
        "value": 139.39,
        "_internal_originalTime": 1689836886000
    },
    {
        "time": 1689836887000,
        "value": 139.396,
        "_internal_originalTime": 1689836887000
    },
    {
        "time": 1689836888000,
        "value": 139.395,
        "_internal_originalTime": 1689836888000
    },
    {
        "time": 1689836889000,
        "value": 139.398,
        "_internal_originalTime": 1689836889000
    },
    {
        "time": 1689836890000,
        "value": 139.403,
        "_internal_originalTime": 1689836890000
    },
    {
        "time": 1689836891000,
        "value": 139.401,
        "_internal_originalTime": 1689836891000
    },
    {
        "time": 1689836894000,
        "value": 139.399,
        "_internal_originalTime": 1689836894000
    },
    {
        "time": 1689836895000,
        "value": 139.394,
        "_internal_originalTime": 1689836895000
    },
    {
        "time": 1689836896000,
        "value": 139.397,
        "_internal_originalTime": 1689836896000
    },
    {
        "time": 1689836898000,
        "value": 139.397,
        "_internal_originalTime": 1689836898000
    },
    {
        "time": 1689836900000,
        "value": 139.387,
        "_internal_originalTime": 1689836900000
    },
    {
        "time": 1689836901000,
        "value": 139.382,
        "_internal_originalTime": 1689836901000
    },
    {
        "time": 1689836902000,
        "value": 139.383,
        "_internal_originalTime": 1689836902000
    },
    {
        "time": 1689836903000,
        "value": 139.38,
        "_internal_originalTime": 1689836903000
    },
    {
        "time": 1689836904000,
        "value": 139.383,
        "_internal_originalTime": 1689836904000
    },
    {
        "time": 1689836906000,
        "value": 139.386,
        "_internal_originalTime": 1689836906000
    },
    {
        "time": 1689836907000,
        "value": 139.389,
        "_internal_originalTime": 1689836907000
    },
    {
        "time": 1689836908000,
        "value": 139.389,
        "_internal_originalTime": 1689836908000
    },
    {
        "time": 1689836909000,
        "value": 139.39,
        "_internal_originalTime": 1689836909000
    },
    {
        "time": 1689836910000,
        "value": 139.387,
        "_internal_originalTime": 1689836910000
    },
    {
        "time": 1689836911000,
        "value": 139.392,
        "_internal_originalTime": 1689836911000
    },
    {
        "time": 1689836912000,
        "value": 139.392,
        "_internal_originalTime": 1689836912000
    },
    {
        "time": 1689836913000,
        "value": 139.397,
        "_internal_originalTime": 1689836913000
    },
    {
        "time": 1689836914000,
        "value": 139.397,
        "_internal_originalTime": 1689836914000
    },
    {
        "time": 1689836915000,
        "value": 139.401,
        "_internal_originalTime": 1689836915000
    },
    {
        "time": 1689836917000,
        "value": 139.391,
        "_internal_originalTime": 1689836917000
    },
    {
        "time": 1689836919000,
        "value": 139.396,
        "_internal_originalTime": 1689836919000
    },
    {
        "time": 1689836921000,
        "value": 139.391,
        "_internal_originalTime": 1689836921000
    },
    {
        "time": 1689836922000,
        "value": 139.383,
        "_internal_originalTime": 1689836922000
    },
    {
        "time": 1689836923000,
        "value": 139.388,
        "_internal_originalTime": 1689836923000
    },
    {
        "time": 1689836924000,
        "value": 139.382,
        "_internal_originalTime": 1689836924000
    },
    {
        "time": 1689836925000,
        "value": 139.38,
        "_internal_originalTime": 1689836925000
    },
    {
        "time": 1689836926000,
        "value": 139.38,
        "_internal_originalTime": 1689836926000
    },
    {
        "time": 1689836927000,
        "value": 139.386,
        "_internal_originalTime": 1689836927000
    },
    {
        "time": 1689836928000,
        "value": 139.387,
        "_internal_originalTime": 1689836928000
    },
    {
        "time": 1689836929000,
        "value": 139.387,
        "_internal_originalTime": 1689836929000
    },
    {
        "time": 1689836930000,
        "value": 139.386,
        "_internal_originalTime": 1689836930000
    },
    {
        "time": 1689836931000,
        "value": 139.386,
        "_internal_originalTime": 1689836931000
    },
    {
        "time": 1689836934000,
        "value": 139.388,
        "_internal_originalTime": 1689836934000
    },
    {
        "time": 1689836935000,
        "value": 139.39,
        "_internal_originalTime": 1689836935000
    },
    {
        "time": 1689836937000,
        "value": 139.396,
        "_internal_originalTime": 1689836937000
    },
    {
        "time": 1689836939000,
        "value": 139.391,
        "_internal_originalTime": 1689836939000
    },
    {
        "time": 1689836940000,
        "value": 139.39,
        "_internal_originalTime": 1689836940000
    },
    {
        "time": 1689836942000,
        "value": 139.387,
        "_internal_originalTime": 1689836942000
    },
    {
        "time": 1689836944000,
        "value": 139.38,
        "_internal_originalTime": 1689836944000
    },
    {
        "time": 1689836946000,
        "value": 139.38,
        "_internal_originalTime": 1689836946000
    },
    {
        "time": 1689836947000,
        "value": 139.386,
        "_internal_originalTime": 1689836947000
    },
    {
        "time": 1689836948000,
        "value": 139.378,
        "_internal_originalTime": 1689836948000
    },
    {
        "time": 1689836949000,
        "value": 139.374,
        "_internal_originalTime": 1689836949000
    },
    {
        "time": 1689836950000,
        "value": 139.378,
        "_internal_originalTime": 1689836950000
    },
    {
        "time": 1689836951000,
        "value": 139.379,
        "_internal_originalTime": 1689836951000
    },
    {
        "time": 1689836954000,
        "value": 139.375,
        "_internal_originalTime": 1689836954000
    },
    {
        "time": 1689836956000,
        "value": 139.376,
        "_internal_originalTime": 1689836956000
    },
    {
        "time": 1689836958000,
        "value": 139.372,
        "_internal_originalTime": 1689836958000
    },
    {
        "time": 1689836959000,
        "value": 139.375,
        "_internal_originalTime": 1689836959000
    },
    {
        "time": 1689836960000,
        "value": 139.373,
        "_internal_originalTime": 1689836960000
    },
    {
        "time": 1689836961000,
        "value": 139.372,
        "_internal_originalTime": 1689836961000
    },
    {
        "time": 1689836963000,
        "value": 139.368,
        "_internal_originalTime": 1689836963000
    },
    {
        "time": 1689836964000,
        "value": 139.372,
        "_internal_originalTime": 1689836964000
    },
    {
        "time": 1689836966000,
        "value": 139.368,
        "_internal_originalTime": 1689836966000
    },
    {
        "time": 1689836967000,
        "value": 139.37,
        "_internal_originalTime": 1689836967000
    },
    {
        "time": 1689836968000,
        "value": 139.388,
        "_internal_originalTime": 1689836968000
    },
    {
        "time": 1689836969000,
        "value": 139.387,
        "_internal_originalTime": 1689836969000
    },
    {
        "time": 1689836970000,
        "value": 139.38,
        "_internal_originalTime": 1689836970000
    },
    {
        "time": 1689836971000,
        "value": 139.386,
        "_internal_originalTime": 1689836971000
    },
    {
        "time": 1689836972000,
        "value": 139.388,
        "_internal_originalTime": 1689836972000
    },
    {
        "time": 1689836973000,
        "value": 139.388,
        "_internal_originalTime": 1689836973000
    },
    {
        "time": 1689836975000,
        "value": 139.39,
        "_internal_originalTime": 1689836975000
    },
    {
        "time": 1689836976000,
        "value": 139.392,
        "_internal_originalTime": 1689836976000
    },
    {
        "time": 1689836978000,
        "value": 139.397,
        "_internal_originalTime": 1689836978000
    },
    {
        "time": 1689836979000,
        "value": 139.392,
        "_internal_originalTime": 1689836979000
    },
    {
        "time": 1689836980000,
        "value": 139.398,
        "_internal_originalTime": 1689836980000
    },
    {
        "time": 1689836981000,
        "value": 139.397,
        "_internal_originalTime": 1689836981000
    },
    {
        "time": 1689836983000,
        "value": 139.407,
        "_internal_originalTime": 1689836983000
    },
    {
        "time": 1689836984000,
        "value": 139.407,
        "_internal_originalTime": 1689836984000
    },
    {
        "time": 1689836986000,
        "value": 139.405,
        "_internal_originalTime": 1689836986000
    },
    {
        "time": 1689836987000,
        "value": 139.407,
        "_internal_originalTime": 1689836987000
    },
    {
        "time": 1689836989000,
        "value": 139.41,
        "_internal_originalTime": 1689836989000
    },
    {
        "time": 1689836991000,
        "value": 139.408,
        "_internal_originalTime": 1689836991000
    },
    {
        "time": 1689836993000,
        "value": 139.403,
        "_internal_originalTime": 1689836993000
    },
    {
        "time": 1689836994000,
        "value": 139.404,
        "_internal_originalTime": 1689836994000
    },
    {
        "time": 1689836995000,
        "value": 139.408,
        "_internal_originalTime": 1689836995000
    },
    {
        "time": 1689836996000,
        "value": 139.409,
        "_internal_originalTime": 1689836996000
    },
    {
        "time": 1689836998000,
        "value": 139.416,
        "_internal_originalTime": 1689836998000
    },
    {
        "time": 1689836999000,
        "value": 139.412,
        "_internal_originalTime": 1689836999000
    },
    {
        "time": 1689837001000,
        "value": 139.413,
        "_internal_originalTime": 1689837001000
    },
    {
        "time": 1689837002000,
        "value": 139.408,
        "_internal_originalTime": 1689837002000
    },
    {
        "time": 1689837003000,
        "value": 139.416,
        "_internal_originalTime": 1689837003000
    },
    {
        "time": 1689837004000,
        "value": 139.414,
        "_internal_originalTime": 1689837004000
    },
    {
        "time": 1689837005000,
        "value": 139.412,
        "_internal_originalTime": 1689837005000
    },
    {
        "time": 1689837006000,
        "value": 139.414,
        "_internal_originalTime": 1689837006000
    },
    {
        "time": 1689837008000,
        "value": 139.42,
        "_internal_originalTime": 1689837008000
    },
    {
        "time": 1689837009000,
        "value": 139.418,
        "_internal_originalTime": 1689837009000
    },
    {
        "time": 1689837010000,
        "value": 139.421,
        "_internal_originalTime": 1689837010000
    },
    {
        "time": 1689837011000,
        "value": 139.421,
        "_internal_originalTime": 1689837011000
    },
    {
        "time": 1689837012000,
        "value": 139.42,
        "_internal_originalTime": 1689837012000
    },
    {
        "time": 1689837013000,
        "value": 139.426,
        "_internal_originalTime": 1689837013000
    },
    {
        "time": 1689837014000,
        "value": 139.42,
        "_internal_originalTime": 1689837014000
    },
    {
        "time": 1689837015000,
        "value": 139.425,
        "_internal_originalTime": 1689837015000
    },
    {
        "time": 1689837016000,
        "value": 139.434,
        "_internal_originalTime": 1689837016000
    },
    {
        "time": 1689837017000,
        "value": 139.434,
        "_internal_originalTime": 1689837017000
    },
    {
        "time": 1689837018000,
        "value": 139.435,
        "_internal_originalTime": 1689837018000
    },
    {
        "time": 1689837019000,
        "value": 139.437,
        "_internal_originalTime": 1689837019000
    },
    {
        "time": 1689837020000,
        "value": 139.432,
        "_internal_originalTime": 1689837020000
    },
    {
        "time": 1689837021000,
        "value": 139.434,
        "_internal_originalTime": 1689837021000
    },
    {
        "time": 1689837022000,
        "value": 139.434,
        "_internal_originalTime": 1689837022000
    },
    {
        "time": 1689837024000,
        "value": 139.445,
        "_internal_originalTime": 1689837024000
    },
    {
        "time": 1689837025000,
        "value": 139.442,
        "_internal_originalTime": 1689837025000
    },
    {
        "time": 1689837026000,
        "value": 139.443,
        "_internal_originalTime": 1689837026000
    },
    {
        "time": 1689837027000,
        "value": 139.444,
        "_internal_originalTime": 1689837027000
    },
    {
        "time": 1689837029000,
        "value": 139.432,
        "_internal_originalTime": 1689837029000
    },
    {
        "time": 1689837031000,
        "value": 139.434,
        "_internal_originalTime": 1689837031000
    },
    {
        "time": 1689837033000,
        "value": 139.427,
        "_internal_originalTime": 1689837033000
    },
    {
        "time": 1689837034000,
        "value": 139.426,
        "_internal_originalTime": 1689837034000
    },
    {
        "time": 1689837035000,
        "value": 139.42,
        "_internal_originalTime": 1689837035000
    },
    {
        "time": 1689837036000,
        "value": 139.433,
        "_internal_originalTime": 1689837036000
    },
    {
        "time": 1689837037000,
        "value": 139.435,
        "_internal_originalTime": 1689837037000
    },
    {
        "time": 1689837038000,
        "value": 139.435,
        "_internal_originalTime": 1689837038000
    },
    {
        "time": 1689837040000,
        "value": 139.432,
        "_internal_originalTime": 1689837040000
    },
    {
        "time": 1689837041000,
        "value": 139.424,
        "_internal_originalTime": 1689837041000
    },
    {
        "time": 1689837042000,
        "value": 139.428,
        "_internal_originalTime": 1689837042000
    },
    {
        "time": 1689837043000,
        "value": 139.425,
        "_internal_originalTime": 1689837043000
    },
    {
        "time": 1689837044000,
        "value": 139.426,
        "_internal_originalTime": 1689837044000
    },
    {
        "time": 1689837045000,
        "value": 139.42,
        "_internal_originalTime": 1689837045000
    },
    {
        "time": 1689837046000,
        "value": 139.424,
        "_internal_originalTime": 1689837046000
    },
    {
        "time": 1689837047000,
        "value": 139.426,
        "_internal_originalTime": 1689837047000
    },
    {
        "time": 1689837048000,
        "value": 139.422,
        "_internal_originalTime": 1689837048000
    },
    {
        "time": 1689837049000,
        "value": 139.425,
        "_internal_originalTime": 1689837049000
    },
    {
        "time": 1689837050000,
        "value": 139.425,
        "_internal_originalTime": 1689837050000
    },
    {
        "time": 1689837051000,
        "value": 139.423,
        "_internal_originalTime": 1689837051000
    },
    {
        "time": 1689837052000,
        "value": 139.42,
        "_internal_originalTime": 1689837052000
    },
    {
        "time": 1689837054000,
        "value": 139.419,
        "_internal_originalTime": 1689837054000
    },
    {
        "time": 1689837055000,
        "value": 139.415,
        "_internal_originalTime": 1689837055000
    },
    {
        "time": 1689837056000,
        "value": 139.421,
        "_internal_originalTime": 1689837056000
    },
    {
        "time": 1689837057000,
        "value": 139.414,
        "_internal_originalTime": 1689837057000
    },
    {
        "time": 1689837059000,
        "value": 139.42,
        "_internal_originalTime": 1689837059000
    },
    {
        "time": 1689837060000,
        "value": 139.418,
        "_internal_originalTime": 1689837060000
    },
    {
        "time": 1689837061000,
        "value": 139.4,
        "_internal_originalTime": 1689837061000
    },
    {
        "time": 1689837062000,
        "value": 139.418,
        "_internal_originalTime": 1689837062000
    },
    {
        "time": 1689837064000,
        "value": 139.423,
        "_internal_originalTime": 1689837064000
    },
    {
        "time": 1689837065000,
        "value": 139.432,
        "_internal_originalTime": 1689837065000
    },
    {
        "time": 1689837067000,
        "value": 139.433,
        "_internal_originalTime": 1689837067000
    },
    {
        "time": 1689837068000,
        "value": 139.426,
        "_internal_originalTime": 1689837068000
    },
    {
        "time": 1689837069000,
        "value": 139.427,
        "_internal_originalTime": 1689837069000
    },
    {
        "time": 1689837070000,
        "value": 139.427,
        "_internal_originalTime": 1689837070000
    },
    {
        "time": 1689837071000,
        "value": 139.426,
        "_internal_originalTime": 1689837071000
    },
    {
        "time": 1689837072000,
        "value": 139.426,
        "_internal_originalTime": 1689837072000
    },
    {
        "time": 1689837074000,
        "value": 139.426,
        "_internal_originalTime": 1689837074000
    },
    {
        "time": 1689837075000,
        "value": 139.423,
        "_internal_originalTime": 1689837075000
    },
    {
        "time": 1689837076000,
        "value": 139.422,
        "_internal_originalTime": 1689837076000
    },
    {
        "time": 1689837078000,
        "value": 139.428,
        "_internal_originalTime": 1689837078000
    },
    {
        "time": 1689837079000,
        "value": 139.427,
        "_internal_originalTime": 1689837079000
    },
    {
        "time": 1689837081000,
        "value": 139.426,
        "_internal_originalTime": 1689837081000
    },
    {
        "time": 1689837082000,
        "value": 139.423,
        "_internal_originalTime": 1689837082000
    },
    {
        "time": 1689837083000,
        "value": 139.422,
        "_internal_originalTime": 1689837083000
    },
    {
        "time": 1689837086000,
        "value": 139.422,
        "_internal_originalTime": 1689837086000
    },
    {
        "time": 1689837088000,
        "value": 139.426,
        "_internal_originalTime": 1689837088000
    },
    {
        "time": 1689837090000,
        "value": 139.425,
        "_internal_originalTime": 1689837090000
    },
    {
        "time": 1689837092000,
        "value": 139.417,
        "_internal_originalTime": 1689837092000
    },
    {
        "time": 1689837093000,
        "value": 139.416,
        "_internal_originalTime": 1689837093000
    },
    {
        "time": 1689837094000,
        "value": 139.421,
        "_internal_originalTime": 1689837094000
    },
    {
        "time": 1689837095000,
        "value": 139.42,
        "_internal_originalTime": 1689837095000
    },
    {
        "time": 1689837097000,
        "value": 139.424,
        "_internal_originalTime": 1689837097000
    },
    {
        "time": 1689837099000,
        "value": 139.418,
        "_internal_originalTime": 1689837099000
    },
    {
        "time": 1689837101000,
        "value": 139.42,
        "_internal_originalTime": 1689837101000
    },
    {
        "time": 1689837103000,
        "value": 139.418,
        "_internal_originalTime": 1689837103000
    },
    {
        "time": 1689837105000,
        "value": 139.414,
        "_internal_originalTime": 1689837105000
    },
    {
        "time": 1689837106000,
        "value": 139.417,
        "_internal_originalTime": 1689837106000
    },
    {
        "time": 1689837107000,
        "value": 139.416,
        "_internal_originalTime": 1689837107000
    },
    {
        "time": 1689837108000,
        "value": 139.402,
        "_internal_originalTime": 1689837108000
    },
    {
        "time": 1689837109000,
        "value": 139.402,
        "_internal_originalTime": 1689837109000
    },
    {
        "time": 1689837110000,
        "value": 139.405,
        "_internal_originalTime": 1689837110000
    },
    {
        "time": 1689837111000,
        "value": 139.405,
        "_internal_originalTime": 1689837111000
    },
    {
        "time": 1689837112000,
        "value": 139.399,
        "_internal_originalTime": 1689837112000
    },
    {
        "time": 1689837113000,
        "value": 139.398,
        "_internal_originalTime": 1689837113000
    },
    {
        "time": 1689837114000,
        "value": 139.406,
        "_internal_originalTime": 1689837114000
    },
    {
        "time": 1689837115000,
        "value": 139.406,
        "_internal_originalTime": 1689837115000
    },
    {
        "time": 1689837116000,
        "value": 139.402,
        "_internal_originalTime": 1689837116000
    },
    {
        "time": 1689837118000,
        "value": 139.401,
        "_internal_originalTime": 1689837118000
    },
    {
        "time": 1689837119000,
        "value": 139.398,
        "_internal_originalTime": 1689837119000
    },
    {
        "time": 1689837120000,
        "value": 139.411,
        "_internal_originalTime": 1689837120000
    },
    {
        "time": 1689837121000,
        "value": 139.411,
        "_internal_originalTime": 1689837121000
    },
    {
        "time": 1689837122000,
        "value": 139.415,
        "_internal_originalTime": 1689837122000
    },
    {
        "time": 1689837125000,
        "value": 139.41,
        "_internal_originalTime": 1689837125000
    },
    {
        "time": 1689837126000,
        "value": 139.41,
        "_internal_originalTime": 1689837126000
    },
    {
        "time": 1689837128000,
        "value": 139.412,
        "_internal_originalTime": 1689837128000
    },
    {
        "time": 1689837129000,
        "value": 139.408,
        "_internal_originalTime": 1689837129000
    },
    {
        "time": 1689837130000,
        "value": 139.409,
        "_internal_originalTime": 1689837130000
    },
    {
        "time": 1689837131000,
        "value": 139.411,
        "_internal_originalTime": 1689837131000
    },
    {
        "time": 1689837133000,
        "value": 139.41,
        "_internal_originalTime": 1689837133000
    },
    {
        "time": 1689837134000,
        "value": 139.408,
        "_internal_originalTime": 1689837134000
    },
    {
        "time": 1689837136000,
        "value": 139.411,
        "_internal_originalTime": 1689837136000
    },
    {
        "time": 1689837137000,
        "value": 139.413,
        "_internal_originalTime": 1689837137000
    },
    {
        "time": 1689837138000,
        "value": 139.427,
        "_internal_originalTime": 1689837138000
    },
    {
        "time": 1689837139000,
        "value": 139.43,
        "_internal_originalTime": 1689837139000
    },
    {
        "time": 1689837141000,
        "value": 139.427,
        "_internal_originalTime": 1689837141000
    },
    {
        "time": 1689837143000,
        "value": 139.425,
        "_internal_originalTime": 1689837143000
    },
    {
        "time": 1689837145000,
        "value": 139.425,
        "_internal_originalTime": 1689837145000
    },
    {
        "time": 1689837146000,
        "value": 139.425,
        "_internal_originalTime": 1689837146000
    },
    {
        "time": 1689837147000,
        "value": 139.422,
        "_internal_originalTime": 1689837147000
    },
    {
        "time": 1689837148000,
        "value": 139.425,
        "_internal_originalTime": 1689837148000
    },
    {
        "time": 1689837150000,
        "value": 139.425,
        "_internal_originalTime": 1689837150000
    },
    {
        "time": 1689837151000,
        "value": 139.416,
        "_internal_originalTime": 1689837151000
    },
    {
        "time": 1689837153000,
        "value": 139.414,
        "_internal_originalTime": 1689837153000
    },
    {
        "time": 1689837155000,
        "value": 139.419,
        "_internal_originalTime": 1689837155000
    },
    {
        "time": 1689837157000,
        "value": 139.417,
        "_internal_originalTime": 1689837157000
    },
    {
        "time": 1689837158000,
        "value": 139.412,
        "_internal_originalTime": 1689837158000
    },
    {
        "time": 1689837159000,
        "value": 139.412,
        "_internal_originalTime": 1689837159000
    },
    {
        "time": 1689837160000,
        "value": 139.411,
        "_internal_originalTime": 1689837160000
    },
    {
        "time": 1689837162000,
        "value": 139.407,
        "_internal_originalTime": 1689837162000
    },
    {
        "time": 1689837163000,
        "value": 139.408,
        "_internal_originalTime": 1689837163000
    },
    {
        "time": 1689837165000,
        "value": 139.408,
        "_internal_originalTime": 1689837165000
    },
    {
        "time": 1689837166000,
        "value": 139.41,
        "_internal_originalTime": 1689837166000
    },
    {
        "time": 1689837168000,
        "value": 139.41,
        "_internal_originalTime": 1689837168000
    },
    {
        "time": 1689837169000,
        "value": 139.412,
        "_internal_originalTime": 1689837169000
    },
    {
        "time": 1689837170000,
        "value": 139.412,
        "_internal_originalTime": 1689837170000
    },
    {
        "time": 1689837171000,
        "value": 139.416,
        "_internal_originalTime": 1689837171000
    },
    {
        "time": 1689837173000,
        "value": 139.412,
        "_internal_originalTime": 1689837173000
    },
    {
        "time": 1689837175000,
        "value": 139.413,
        "_internal_originalTime": 1689837175000
    },
    {
        "time": 1689837176000,
        "value": 139.412,
        "_internal_originalTime": 1689837176000
    },
    {
        "time": 1689837177000,
        "value": 139.403,
        "_internal_originalTime": 1689837177000
    },
    {
        "time": 1689837178000,
        "value": 139.402,
        "_internal_originalTime": 1689837178000
    },
    {
        "time": 1689837180000,
        "value": 139.404,
        "_internal_originalTime": 1689837180000
    },
    {
        "time": 1689837182000,
        "value": 139.404,
        "_internal_originalTime": 1689837182000
    },
    {
        "time": 1689837183000,
        "value": 139.402,
        "_internal_originalTime": 1689837183000
    },
    {
        "time": 1689837184000,
        "value": 139.407,
        "_internal_originalTime": 1689837184000
    },
    {
        "time": 1689837186000,
        "value": 139.403,
        "_internal_originalTime": 1689837186000
    },
    {
        "time": 1689837187000,
        "value": 139.403,
        "_internal_originalTime": 1689837187000
    },
    {
        "time": 1689837189000,
        "value": 139.403,
        "_internal_originalTime": 1689837189000
    },
    {
        "time": 1689837190000,
        "value": 139.406,
        "_internal_originalTime": 1689837190000
    },
    {
        "time": 1689837191000,
        "value": 139.403,
        "_internal_originalTime": 1689837191000
    },
    {
        "time": 1689837192000,
        "value": 139.402,
        "_internal_originalTime": 1689837192000
    },
    {
        "time": 1689837194000,
        "value": 139.403,
        "_internal_originalTime": 1689837194000
    },
    {
        "time": 1689837196000,
        "value": 139.406,
        "_internal_originalTime": 1689837196000
    },
    {
        "time": 1689837197000,
        "value": 139.407,
        "_internal_originalTime": 1689837197000
    },
    {
        "time": 1689837199000,
        "value": 139.406,
        "_internal_originalTime": 1689837199000
    },
    {
        "time": 1689837200000,
        "value": 139.402,
        "_internal_originalTime": 1689837200000
    },
    {
        "time": 1689837201000,
        "value": 139.408,
        "_internal_originalTime": 1689837201000
    },
    {
        "time": 1689837202000,
        "value": 139.404,
        "_internal_originalTime": 1689837202000
    },
    {
        "time": 1689837204000,
        "value": 139.404,
        "_internal_originalTime": 1689837204000
    },
    {
        "time": 1689837206000,
        "value": 139.404,
        "_internal_originalTime": 1689837206000
    },
    {
        "time": 1689837207000,
        "value": 139.404,
        "_internal_originalTime": 1689837207000
    },
    {
        "time": 1689837208000,
        "value": 139.404,
        "_internal_originalTime": 1689837208000
    },
    {
        "time": 1689837211000,
        "value": 139.405,
        "_internal_originalTime": 1689837211000
    },
    {
        "time": 1689837212000,
        "value": 139.402,
        "_internal_originalTime": 1689837212000
    },
    {
        "time": 1689837213000,
        "value": 139.401,
        "_internal_originalTime": 1689837213000
    },
    {
        "time": 1689837215000,
        "value": 139.401,
        "_internal_originalTime": 1689837215000
    },
    {
        "time": 1689837216000,
        "value": 139.396,
        "_internal_originalTime": 1689837216000
    },
    {
        "time": 1689837217000,
        "value": 139.401,
        "_internal_originalTime": 1689837217000
    },
    {
        "time": 1689837219000,
        "value": 139.397,
        "_internal_originalTime": 1689837219000
    },
    {
        "time": 1689837220000,
        "value": 139.398,
        "_internal_originalTime": 1689837220000
    },
    {
        "time": 1689837222000,
        "value": 139.394,
        "_internal_originalTime": 1689837222000
    },
    {
        "time": 1689837223000,
        "value": 139.388,
        "_internal_originalTime": 1689837223000
    },
    {
        "time": 1689837224000,
        "value": 139.386,
        "_internal_originalTime": 1689837224000
    },
    {
        "time": 1689837225000,
        "value": 139.385,
        "_internal_originalTime": 1689837225000
    },
    {
        "time": 1689837226000,
        "value": 139.387,
        "_internal_originalTime": 1689837226000
    },
    {
        "time": 1689837228000,
        "value": 139.387,
        "_internal_originalTime": 1689837228000
    },
    {
        "time": 1689837229000,
        "value": 139.382,
        "_internal_originalTime": 1689837229000
    },
    {
        "time": 1689837230000,
        "value": 139.386,
        "_internal_originalTime": 1689837230000
    },
    {
        "time": 1689837231000,
        "value": 139.391,
        "_internal_originalTime": 1689837231000
    },
    {
        "time": 1689837233000,
        "value": 139.386,
        "_internal_originalTime": 1689837233000
    },
    {
        "time": 1689837234000,
        "value": 139.394,
        "_internal_originalTime": 1689837234000
    },
    {
        "time": 1689837236000,
        "value": 139.391,
        "_internal_originalTime": 1689837236000
    },
    {
        "time": 1689837237000,
        "value": 139.386,
        "_internal_originalTime": 1689837237000
    },
    {
        "time": 1689837238000,
        "value": 139.386,
        "_internal_originalTime": 1689837238000
    },
    {
        "time": 1689837240000,
        "value": 139.396,
        "_internal_originalTime": 1689837240000
    },
    {
        "time": 1689837241000,
        "value": 139.398,
        "_internal_originalTime": 1689837241000
    },
    {
        "time": 1689837242000,
        "value": 139.401,
        "_internal_originalTime": 1689837242000
    },
    {
        "time": 1689837243000,
        "value": 139.398,
        "_internal_originalTime": 1689837243000
    },
    {
        "time": 1689837244000,
        "value": 139.407,
        "_internal_originalTime": 1689837244000
    },
    {
        "time": 1689837246000,
        "value": 139.408,
        "_internal_originalTime": 1689837246000
    },
    {
        "time": 1689837247000,
        "value": 139.402,
        "_internal_originalTime": 1689837247000
    },
    {
        "time": 1689837248000,
        "value": 139.408,
        "_internal_originalTime": 1689837248000
    },
    {
        "time": 1689837250000,
        "value": 139.407,
        "_internal_originalTime": 1689837250000
    },
    {
        "time": 1689837251000,
        "value": 139.404,
        "_internal_originalTime": 1689837251000
    },
    {
        "time": 1689837252000,
        "value": 139.405,
        "_internal_originalTime": 1689837252000
    },
    {
        "time": 1689837253000,
        "value": 139.404,
        "_internal_originalTime": 1689837253000
    },
    {
        "time": 1689837254000,
        "value": 139.398,
        "_internal_originalTime": 1689837254000
    },
    {
        "time": 1689837255000,
        "value": 139.395,
        "_internal_originalTime": 1689837255000
    },
    {
        "time": 1689837256000,
        "value": 139.395,
        "_internal_originalTime": 1689837256000
    },
    {
        "time": 1689837258000,
        "value": 139.389,
        "_internal_originalTime": 1689837258000
    },
    {
        "time": 1689837260000,
        "value": 139.397,
        "_internal_originalTime": 1689837260000
    },
    {
        "time": 1689837261000,
        "value": 139.397,
        "_internal_originalTime": 1689837261000
    },
    {
        "time": 1689837262000,
        "value": 139.391,
        "_internal_originalTime": 1689837262000
    },
    {
        "time": 1689837264000,
        "value": 139.388,
        "_internal_originalTime": 1689837264000
    },
    {
        "time": 1689837265000,
        "value": 139.391,
        "_internal_originalTime": 1689837265000
    },
    {
        "time": 1689837266000,
        "value": 139.395,
        "_internal_originalTime": 1689837266000
    },
    {
        "time": 1689837267000,
        "value": 139.395,
        "_internal_originalTime": 1689837267000
    },
    {
        "time": 1689837268000,
        "value": 139.39,
        "_internal_originalTime": 1689837268000
    },
    {
        "time": 1689837269000,
        "value": 139.392,
        "_internal_originalTime": 1689837269000
    },
    {
        "time": 1689837270000,
        "value": 139.386,
        "_internal_originalTime": 1689837270000
    },
    {
        "time": 1689837271000,
        "value": 139.387,
        "_internal_originalTime": 1689837271000
    },
    {
        "time": 1689837272000,
        "value": 139.382,
        "_internal_originalTime": 1689837272000
    },
    {
        "time": 1689837273000,
        "value": 139.384,
        "_internal_originalTime": 1689837273000
    },
    {
        "time": 1689837274000,
        "value": 139.39,
        "_internal_originalTime": 1689837274000
    },
    {
        "time": 1689837276000,
        "value": 139.386,
        "_internal_originalTime": 1689837276000
    },
    {
        "time": 1689837277000,
        "value": 139.386,
        "_internal_originalTime": 1689837277000
    },
    {
        "time": 1689837278000,
        "value": 139.386,
        "_internal_originalTime": 1689837278000
    },
    {
        "time": 1689837280000,
        "value": 139.386,
        "_internal_originalTime": 1689837280000
    },
    {
        "time": 1689837281000,
        "value": 139.387,
        "_internal_originalTime": 1689837281000
    },
    {
        "time": 1689837282000,
        "value": 139.387,
        "_internal_originalTime": 1689837282000
    },
    {
        "time": 1689837284000,
        "value": 139.389,
        "_internal_originalTime": 1689837284000
    },
    {
        "time": 1689837286000,
        "value": 139.393,
        "_internal_originalTime": 1689837286000
    },
    {
        "time": 1689837287000,
        "value": 139.389,
        "_internal_originalTime": 1689837287000
    },
    {
        "time": 1689837288000,
        "value": 139.394,
        "_internal_originalTime": 1689837288000
    },
    {
        "time": 1689837290000,
        "value": 139.395,
        "_internal_originalTime": 1689837290000
    },
    {
        "time": 1689837291000,
        "value": 139.398,
        "_internal_originalTime": 1689837291000
    },
    {
        "time": 1689837292000,
        "value": 139.404,
        "_internal_originalTime": 1689837292000
    },
    {
        "time": 1689837294000,
        "value": 139.402,
        "_internal_originalTime": 1689837294000
    },
    {
        "time": 1689837295000,
        "value": 139.406,
        "_internal_originalTime": 1689837295000
    },
    {
        "time": 1689837296000,
        "value": 139.406,
        "_internal_originalTime": 1689837296000
    },
    {
        "time": 1689837298000,
        "value": 139.401,
        "_internal_originalTime": 1689837298000
    },
    {
        "time": 1689837299000,
        "value": 139.401,
        "_internal_originalTime": 1689837299000
    },
    {
        "time": 1689837301000,
        "value": 139.38,
        "_internal_originalTime": 1689837301000
    },
    {
        "time": 1689837302000,
        "value": 139.398,
        "_internal_originalTime": 1689837302000
    },
    {
        "time": 1689837303000,
        "value": 139.392,
        "_internal_originalTime": 1689837303000
    },
    {
        "time": 1689837305000,
        "value": 139.398,
        "_internal_originalTime": 1689837305000
    },
    {
        "time": 1689837306000,
        "value": 139.395,
        "_internal_originalTime": 1689837306000
    },
    {
        "time": 1689837307000,
        "value": 139.397,
        "_internal_originalTime": 1689837307000
    },
    {
        "time": 1689837308000,
        "value": 139.395,
        "_internal_originalTime": 1689837308000
    },
    {
        "time": 1689837309000,
        "value": 139.402,
        "_internal_originalTime": 1689837309000
    },
    {
        "time": 1689837311000,
        "value": 139.4,
        "_internal_originalTime": 1689837311000
    },
    {
        "time": 1689837312000,
        "value": 139.395,
        "_internal_originalTime": 1689837312000
    },
    {
        "time": 1689837313000,
        "value": 139.393,
        "_internal_originalTime": 1689837313000
    },
    {
        "time": 1689837315000,
        "value": 139.391,
        "_internal_originalTime": 1689837315000
    },
    {
        "time": 1689837316000,
        "value": 139.39,
        "_internal_originalTime": 1689837316000
    },
    {
        "time": 1689837317000,
        "value": 139.392,
        "_internal_originalTime": 1689837317000
    },
    {
        "time": 1689837318000,
        "value": 139.392,
        "_internal_originalTime": 1689837318000
    },
    {
        "time": 1689837319000,
        "value": 139.393,
        "_internal_originalTime": 1689837319000
    },
    {
        "time": 1689837320000,
        "value": 139.398,
        "_internal_originalTime": 1689837320000
    },
    {
        "time": 1689837322000,
        "value": 139.399,
        "_internal_originalTime": 1689837322000
    },
    {
        "time": 1689837323000,
        "value": 139.392,
        "_internal_originalTime": 1689837323000
    },
    {
        "time": 1689837325000,
        "value": 139.395,
        "_internal_originalTime": 1689837325000
    },
    {
        "time": 1689837326000,
        "value": 139.393,
        "_internal_originalTime": 1689837326000
    },
    {
        "time": 1689837327000,
        "value": 139.394,
        "_internal_originalTime": 1689837327000
    },
    {
        "time": 1689837328000,
        "value": 139.394,
        "_internal_originalTime": 1689837328000
    },
    {
        "time": 1689837329000,
        "value": 139.396,
        "_internal_originalTime": 1689837329000
    },
    {
        "time": 1689837330000,
        "value": 139.394,
        "_internal_originalTime": 1689837330000
    },
    {
        "time": 1689837331000,
        "value": 139.396,
        "_internal_originalTime": 1689837331000
    },
    {
        "time": 1689837332000,
        "value": 139.396,
        "_internal_originalTime": 1689837332000
    },
    {
        "time": 1689837333000,
        "value": 139.398,
        "_internal_originalTime": 1689837333000
    },
    {
        "time": 1689837334000,
        "value": 139.402,
        "_internal_originalTime": 1689837334000
    },
    {
        "time": 1689837335000,
        "value": 139.409,
        "_internal_originalTime": 1689837335000
    },
    {
        "time": 1689837336000,
        "value": 139.42,
        "_internal_originalTime": 1689837336000
    },
    {
        "time": 1689837337000,
        "value": 139.417,
        "_internal_originalTime": 1689837337000
    },
    {
        "time": 1689837338000,
        "value": 139.418,
        "_internal_originalTime": 1689837338000
    },
    {
        "time": 1689837340000,
        "value": 139.414,
        "_internal_originalTime": 1689837340000
    },
    {
        "time": 1689837342000,
        "value": 139.418,
        "_internal_originalTime": 1689837342000
    },
    {
        "time": 1689837343000,
        "value": 139.418,
        "_internal_originalTime": 1689837343000
    },
    {
        "time": 1689837344000,
        "value": 139.424,
        "_internal_originalTime": 1689837344000
    },
    {
        "time": 1689837345000,
        "value": 139.434,
        "_internal_originalTime": 1689837345000
    },
    {
        "time": 1689837346000,
        "value": 139.435,
        "_internal_originalTime": 1689837346000
    },
    {
        "time": 1689837348000,
        "value": 139.437,
        "_internal_originalTime": 1689837348000
    },
    {
        "time": 1689837349000,
        "value": 139.438,
        "_internal_originalTime": 1689837349000
    },
    {
        "time": 1689837350000,
        "value": 139.436,
        "_internal_originalTime": 1689837350000
    },
    {
        "time": 1689837351000,
        "value": 139.441,
        "_internal_originalTime": 1689837351000
    },
    {
        "time": 1689837352000,
        "value": 139.439,
        "_internal_originalTime": 1689837352000
    },
    {
        "time": 1689837353000,
        "value": 139.437,
        "_internal_originalTime": 1689837353000
    },
    {
        "time": 1689837354000,
        "value": 139.44,
        "_internal_originalTime": 1689837354000
    },
    {
        "time": 1689837355000,
        "value": 139.438,
        "_internal_originalTime": 1689837355000
    },
    {
        "time": 1689837356000,
        "value": 139.441,
        "_internal_originalTime": 1689837356000
    },
    {
        "time": 1689837357000,
        "value": 139.44,
        "_internal_originalTime": 1689837357000
    },
    {
        "time": 1689837358000,
        "value": 139.438,
        "_internal_originalTime": 1689837358000
    },
    {
        "time": 1689837359000,
        "value": 139.438,
        "_internal_originalTime": 1689837359000
    },
    {
        "time": 1689837360000,
        "value": 139.434,
        "_internal_originalTime": 1689837360000
    },
    {
        "time": 1689837361000,
        "value": 139.434,
        "_internal_originalTime": 1689837361000
    },
    {
        "time": 1689837362000,
        "value": 139.44,
        "_internal_originalTime": 1689837362000
    },
    {
        "time": 1689837363000,
        "value": 139.439,
        "_internal_originalTime": 1689837363000
    },
    {
        "time": 1689837364000,
        "value": 139.446,
        "_internal_originalTime": 1689837364000
    },
    {
        "time": 1689837365000,
        "value": 139.446,
        "_internal_originalTime": 1689837365000
    },
    {
        "time": 1689837366000,
        "value": 139.444,
        "_internal_originalTime": 1689837366000
    },
    {
        "time": 1689837367000,
        "value": 139.453,
        "_internal_originalTime": 1689837367000
    },
    {
        "time": 1689837368000,
        "value": 139.457,
        "_internal_originalTime": 1689837368000
    },
    {
        "time": 1689837369000,
        "value": 139.456,
        "_internal_originalTime": 1689837369000
    },
    {
        "time": 1689837370000,
        "value": 139.459,
        "_internal_originalTime": 1689837370000
    },
    {
        "time": 1689837371000,
        "value": 139.458,
        "_internal_originalTime": 1689837371000
    },
    {
        "time": 1689837372000,
        "value": 139.458,
        "_internal_originalTime": 1689837372000
    },
    {
        "time": 1689837373000,
        "value": 139.456,
        "_internal_originalTime": 1689837373000
    },
    {
        "time": 1689837374000,
        "value": 139.46,
        "_internal_originalTime": 1689837374000
    },
    {
        "time": 1689837375000,
        "value": 139.459,
        "_internal_originalTime": 1689837375000
    },
    {
        "time": 1689837376000,
        "value": 139.456,
        "_internal_originalTime": 1689837376000
    },
    {
        "time": 1689837377000,
        "value": 139.458,
        "_internal_originalTime": 1689837377000
    },
    {
        "time": 1689837378000,
        "value": 139.458,
        "_internal_originalTime": 1689837378000
    },
    {
        "time": 1689837379000,
        "value": 139.459,
        "_internal_originalTime": 1689837379000
    },
    {
        "time": 1689837380000,
        "value": 139.468,
        "_internal_originalTime": 1689837380000
    },
    {
        "time": 1689837381000,
        "value": 139.464,
        "_internal_originalTime": 1689837381000
    },
    {
        "time": 1689837382000,
        "value": 139.479,
        "_internal_originalTime": 1689837382000
    },
    {
        "time": 1689837383000,
        "value": 139.488,
        "_internal_originalTime": 1689837383000
    },
    {
        "time": 1689837384000,
        "value": 139.482,
        "_internal_originalTime": 1689837384000
    },
    {
        "time": 1689837385000,
        "value": 139.486,
        "_internal_originalTime": 1689837385000
    },
    {
        "time": 1689837386000,
        "value": 139.473,
        "_internal_originalTime": 1689837386000
    },
    {
        "time": 1689837387000,
        "value": 139.474,
        "_internal_originalTime": 1689837387000
    },
    {
        "time": 1689837388000,
        "value": 139.468,
        "_internal_originalTime": 1689837388000
    },
    {
        "time": 1689837389000,
        "value": 139.477,
        "_internal_originalTime": 1689837389000
    },
    {
        "time": 1689837390000,
        "value": 139.473,
        "_internal_originalTime": 1689837390000
    },
    {
        "time": 1689837391000,
        "value": 139.483,
        "_internal_originalTime": 1689837391000
    },
    {
        "time": 1689837392000,
        "value": 139.49,
        "_internal_originalTime": 1689837392000
    },
    {
        "time": 1689837393000,
        "value": 139.498,
        "_internal_originalTime": 1689837393000
    },
    {
        "time": 1689837394000,
        "value": 139.499,
        "_internal_originalTime": 1689837394000
    },
    {
        "time": 1689837395000,
        "value": 139.499,
        "_internal_originalTime": 1689837395000
    },
    {
        "time": 1689837396000,
        "value": 139.495,
        "_internal_originalTime": 1689837396000
    },
    {
        "time": 1689837397000,
        "value": 139.496,
        "_internal_originalTime": 1689837397000
    },
    {
        "time": 1689837398000,
        "value": 139.493,
        "_internal_originalTime": 1689837398000
    },
    {
        "time": 1689837399000,
        "value": 139.489,
        "_internal_originalTime": 1689837399000
    },
    {
        "time": 1689837400000,
        "value": 139.49,
        "_internal_originalTime": 1689837400000
    },
    {
        "time": 1689837401000,
        "value": 139.502,
        "_internal_originalTime": 1689837401000
    },
    {
        "time": 1689837402000,
        "value": 139.507,
        "_internal_originalTime": 1689837402000
    },
    {
        "time": 1689837403000,
        "value": 139.51,
        "_internal_originalTime": 1689837403000
    },
    {
        "time": 1689837405000,
        "value": 139.514,
        "_internal_originalTime": 1689837405000
    },
    {
        "time": 1689837406000,
        "value": 139.512,
        "_internal_originalTime": 1689837406000
    },
    {
        "time": 1689837407000,
        "value": 139.526,
        "_internal_originalTime": 1689837407000
    },
    {
        "time": 1689837408000,
        "value": 139.531,
        "_internal_originalTime": 1689837408000
    },
    {
        "time": 1689837409000,
        "value": 139.531,
        "_internal_originalTime": 1689837409000
    },
    {
        "time": 1689837410000,
        "value": 139.539,
        "_internal_originalTime": 1689837410000
    },
    {
        "time": 1689837411000,
        "value": 139.541,
        "_internal_originalTime": 1689837411000
    },
    {
        "time": 1689837412000,
        "value": 139.551,
        "_internal_originalTime": 1689837412000
    },
    {
        "time": 1689837413000,
        "value": 139.545,
        "_internal_originalTime": 1689837413000
    },
    {
        "time": 1689837414000,
        "value": 139.546,
        "_internal_originalTime": 1689837414000
    },
    {
        "time": 1689837415000,
        "value": 139.547,
        "_internal_originalTime": 1689837415000
    },
    {
        "time": 1689837416000,
        "value": 139.545,
        "_internal_originalTime": 1689837416000
    },
    {
        "time": 1689837417000,
        "value": 139.547,
        "_internal_originalTime": 1689837417000
    },
    {
        "time": 1689837418000,
        "value": 139.552,
        "_internal_originalTime": 1689837418000
    },
    {
        "time": 1689837420000,
        "value": 139.555,
        "_internal_originalTime": 1689837420000
    },
    {
        "time": 1689837421000,
        "value": 139.562,
        "_internal_originalTime": 1689837421000
    },
    {
        "time": 1689837422000,
        "value": 139.559,
        "_internal_originalTime": 1689837422000
    },
    {
        "time": 1689837423000,
        "value": 139.556,
        "_internal_originalTime": 1689837423000
    },
    {
        "time": 1689837425000,
        "value": 139.553,
        "_internal_originalTime": 1689837425000
    },
    {
        "time": 1689837426000,
        "value": 139.546,
        "_internal_originalTime": 1689837426000
    },
    {
        "time": 1689837428000,
        "value": 139.551,
        "_internal_originalTime": 1689837428000
    },
    {
        "time": 1689837429000,
        "value": 139.549,
        "_internal_originalTime": 1689837429000
    },
    {
        "time": 1689837430000,
        "value": 139.55,
        "_internal_originalTime": 1689837430000
    },
    {
        "time": 1689837431000,
        "value": 139.548,
        "_internal_originalTime": 1689837431000
    },
    {
        "time": 1689837433000,
        "value": 139.546,
        "_internal_originalTime": 1689837433000
    },
    {
        "time": 1689837434000,
        "value": 139.542,
        "_internal_originalTime": 1689837434000
    },
    {
        "time": 1689837435000,
        "value": 139.543,
        "_internal_originalTime": 1689837435000
    },
    {
        "time": 1689837436000,
        "value": 139.536,
        "_internal_originalTime": 1689837436000
    },
    {
        "time": 1689837437000,
        "value": 139.542,
        "_internal_originalTime": 1689837437000
    },
    {
        "time": 1689837438000,
        "value": 139.546,
        "_internal_originalTime": 1689837438000
    },
    {
        "time": 1689837439000,
        "value": 139.546,
        "_internal_originalTime": 1689837439000
    },
    {
        "time": 1689837440000,
        "value": 139.55,
        "_internal_originalTime": 1689837440000
    },
    {
        "time": 1689837441000,
        "value": 139.551,
        "_internal_originalTime": 1689837441000
    },
    {
        "time": 1689837442000,
        "value": 139.562,
        "_internal_originalTime": 1689837442000
    },
    {
        "time": 1689837443000,
        "value": 139.561,
        "_internal_originalTime": 1689837443000
    },
    {
        "time": 1689837444000,
        "value": 139.562,
        "_internal_originalTime": 1689837444000
    },
    {
        "time": 1689837445000,
        "value": 139.556,
        "_internal_originalTime": 1689837445000
    },
    {
        "time": 1689837446000,
        "value": 139.563,
        "_internal_originalTime": 1689837446000
    },
    {
        "time": 1689837447000,
        "value": 139.559,
        "_internal_originalTime": 1689837447000
    },
    {
        "time": 1689837448000,
        "value": 139.554,
        "_internal_originalTime": 1689837448000
    },
    {
        "time": 1689837449000,
        "value": 139.553,
        "_internal_originalTime": 1689837449000
    },
    {
        "time": 1689837451000,
        "value": 139.547,
        "_internal_originalTime": 1689837451000
    },
    {
        "time": 1689837452000,
        "value": 139.544,
        "_internal_originalTime": 1689837452000
    },
    {
        "time": 1689837453000,
        "value": 139.546,
        "_internal_originalTime": 1689837453000
    },
    {
        "time": 1689837454000,
        "value": 139.544,
        "_internal_originalTime": 1689837454000
    },
    {
        "time": 1689837455000,
        "value": 139.543,
        "_internal_originalTime": 1689837455000
    },
    {
        "time": 1689837456000,
        "value": 139.538,
        "_internal_originalTime": 1689837456000
    },
    {
        "time": 1689837458000,
        "value": 139.549,
        "_internal_originalTime": 1689837458000
    },
    {
        "time": 1689837459000,
        "value": 139.552,
        "_internal_originalTime": 1689837459000
    },
    {
        "time": 1689837460000,
        "value": 139.549,
        "_internal_originalTime": 1689837460000
    },
    {
        "time": 1689837461000,
        "value": 139.546,
        "_internal_originalTime": 1689837461000
    },
    {
        "time": 1689837462000,
        "value": 139.539,
        "_internal_originalTime": 1689837462000
    },
    {
        "time": 1689837463000,
        "value": 139.542,
        "_internal_originalTime": 1689837463000
    },
    {
        "time": 1689837465000,
        "value": 139.557,
        "_internal_originalTime": 1689837465000
    },
    {
        "time": 1689837466000,
        "value": 139.559,
        "_internal_originalTime": 1689837466000
    },
    {
        "time": 1689837467000,
        "value": 139.561,
        "_internal_originalTime": 1689837467000
    },
    {
        "time": 1689837469000,
        "value": 139.56,
        "_internal_originalTime": 1689837469000
    },
    {
        "time": 1689837470000,
        "value": 139.561,
        "_internal_originalTime": 1689837470000
    },
    {
        "time": 1689837471000,
        "value": 139.556,
        "_internal_originalTime": 1689837471000
    },
    {
        "time": 1689837472000,
        "value": 139.553,
        "_internal_originalTime": 1689837472000
    },
    {
        "time": 1689837473000,
        "value": 139.55,
        "_internal_originalTime": 1689837473000
    },
    {
        "time": 1689837474000,
        "value": 139.551,
        "_internal_originalTime": 1689837474000
    },
    {
        "time": 1689837475000,
        "value": 139.55,
        "_internal_originalTime": 1689837475000
    },
    {
        "time": 1689837476000,
        "value": 139.544,
        "_internal_originalTime": 1689837476000
    },
    {
        "time": 1689837478000,
        "value": 139.548,
        "_internal_originalTime": 1689837478000
    },
    {
        "time": 1689837479000,
        "value": 139.555,
        "_internal_originalTime": 1689837479000
    },
    {
        "time": 1689837481000,
        "value": 139.555,
        "_internal_originalTime": 1689837481000
    },
    {
        "time": 1689837482000,
        "value": 139.554,
        "_internal_originalTime": 1689837482000
    },
    {
        "time": 1689837483000,
        "value": 139.546,
        "_internal_originalTime": 1689837483000
    },
    {
        "time": 1689837484000,
        "value": 139.55,
        "_internal_originalTime": 1689837484000
    },
    {
        "time": 1689837485000,
        "value": 139.548,
        "_internal_originalTime": 1689837485000
    },
    {
        "time": 1689837486000,
        "value": 139.549,
        "_internal_originalTime": 1689837486000
    },
    {
        "time": 1689837487000,
        "value": 139.536,
        "_internal_originalTime": 1689837487000
    },
    {
        "time": 1689837488000,
        "value": 139.542,
        "_internal_originalTime": 1689837488000
    },
    {
        "time": 1689837489000,
        "value": 139.54,
        "_internal_originalTime": 1689837489000
    },
    {
        "time": 1689837490000,
        "value": 139.54,
        "_internal_originalTime": 1689837490000
    },
    {
        "time": 1689837491000,
        "value": 139.536,
        "_internal_originalTime": 1689837491000
    },
    {
        "time": 1689837493000,
        "value": 139.541,
        "_internal_originalTime": 1689837493000
    },
    {
        "time": 1689837494000,
        "value": 139.539,
        "_internal_originalTime": 1689837494000
    },
    {
        "time": 1689837495000,
        "value": 139.537,
        "_internal_originalTime": 1689837495000
    },
    {
        "time": 1689837496000,
        "value": 139.533,
        "_internal_originalTime": 1689837496000
    },
    {
        "time": 1689837497000,
        "value": 139.536,
        "_internal_originalTime": 1689837497000
    },
    {
        "time": 1689837498000,
        "value": 139.533,
        "_internal_originalTime": 1689837498000
    },
    {
        "time": 1689837499000,
        "value": 139.526,
        "_internal_originalTime": 1689837499000
    },
    {
        "time": 1689837501000,
        "value": 139.527,
        "_internal_originalTime": 1689837501000
    },
    {
        "time": 1689837502000,
        "value": 139.531,
        "_internal_originalTime": 1689837502000
    },
    {
        "time": 1689837503000,
        "value": 139.526,
        "_internal_originalTime": 1689837503000
    },
    {
        "time": 1689837504000,
        "value": 139.528,
        "_internal_originalTime": 1689837504000
    },
    {
        "time": 1689837505000,
        "value": 139.524,
        "_internal_originalTime": 1689837505000
    },
    {
        "time": 1689837507000,
        "value": 139.524,
        "_internal_originalTime": 1689837507000
    },
    {
        "time": 1689837508000,
        "value": 139.52,
        "_internal_originalTime": 1689837508000
    },
    {
        "time": 1689837510000,
        "value": 139.523,
        "_internal_originalTime": 1689837510000
    },
    {
        "time": 1689837511000,
        "value": 139.53,
        "_internal_originalTime": 1689837511000
    },
    {
        "time": 1689837512000,
        "value": 139.53,
        "_internal_originalTime": 1689837512000
    },
    {
        "time": 1689837514000,
        "value": 139.53,
        "_internal_originalTime": 1689837514000
    },
    {
        "time": 1689837515000,
        "value": 139.524,
        "_internal_originalTime": 1689837515000
    },
    {
        "time": 1689837517000,
        "value": 139.535,
        "_internal_originalTime": 1689837517000
    },
    {
        "time": 1689837518000,
        "value": 139.541,
        "_internal_originalTime": 1689837518000
    },
    {
        "time": 1689837519000,
        "value": 139.541,
        "_internal_originalTime": 1689837519000
    },
    {
        "time": 1689837520000,
        "value": 139.536,
        "_internal_originalTime": 1689837520000
    },
    {
        "time": 1689837523000,
        "value": 139.536,
        "_internal_originalTime": 1689837523000
    },
    {
        "time": 1689837525000,
        "value": 139.54,
        "_internal_originalTime": 1689837525000
    },
    {
        "time": 1689837527000,
        "value": 139.538,
        "_internal_originalTime": 1689837527000
    },
    {
        "time": 1689837530000,
        "value": 139.535,
        "_internal_originalTime": 1689837530000
    },
    {
        "time": 1689837531000,
        "value": 139.536,
        "_internal_originalTime": 1689837531000
    },
    {
        "time": 1689837532000,
        "value": 139.535,
        "_internal_originalTime": 1689837532000
    },
    {
        "time": 1689837533000,
        "value": 139.533,
        "_internal_originalTime": 1689837533000
    },
    {
        "time": 1689837534000,
        "value": 139.533,
        "_internal_originalTime": 1689837534000
    },
    {
        "time": 1689837535000,
        "value": 139.535,
        "_internal_originalTime": 1689837535000
    },
    {
        "time": 1689837537000,
        "value": 139.525,
        "_internal_originalTime": 1689837537000
    },
    {
        "time": 1689837538000,
        "value": 139.529,
        "_internal_originalTime": 1689837538000
    },
    {
        "time": 1689837540000,
        "value": 139.522,
        "_internal_originalTime": 1689837540000
    },
    {
        "time": 1689837541000,
        "value": 139.527,
        "_internal_originalTime": 1689837541000
    },
    {
        "time": 1689837542000,
        "value": 139.527,
        "_internal_originalTime": 1689837542000
    },
    {
        "time": 1689837543000,
        "value": 139.522,
        "_internal_originalTime": 1689837543000
    },
    {
        "time": 1689837544000,
        "value": 139.522,
        "_internal_originalTime": 1689837544000
    },
    {
        "time": 1689837545000,
        "value": 139.523,
        "_internal_originalTime": 1689837545000
    },
    {
        "time": 1689837546000,
        "value": 139.518,
        "_internal_originalTime": 1689837546000
    },
    {
        "time": 1689837547000,
        "value": 139.524,
        "_internal_originalTime": 1689837547000
    },
    {
        "time": 1689837548000,
        "value": 139.514,
        "_internal_originalTime": 1689837548000
    },
    {
        "time": 1689837549000,
        "value": 139.513,
        "_internal_originalTime": 1689837549000
    },
    {
        "time": 1689837551000,
        "value": 139.513,
        "_internal_originalTime": 1689837551000
    },
    {
        "time": 1689837552000,
        "value": 139.513,
        "_internal_originalTime": 1689837552000
    },
    {
        "time": 1689837553000,
        "value": 139.515,
        "_internal_originalTime": 1689837553000
    },
    {
        "time": 1689837555000,
        "value": 139.519,
        "_internal_originalTime": 1689837555000
    },
    {
        "time": 1689837556000,
        "value": 139.521,
        "_internal_originalTime": 1689837556000
    },
    {
        "time": 1689837557000,
        "value": 139.519,
        "_internal_originalTime": 1689837557000
    },
    {
        "time": 1689837558000,
        "value": 139.508,
        "_internal_originalTime": 1689837558000
    },
    {
        "time": 1689837559000,
        "value": 139.509,
        "_internal_originalTime": 1689837559000
    },
    {
        "time": 1689837560000,
        "value": 139.498,
        "_internal_originalTime": 1689837560000
    },
    {
        "time": 1689837562000,
        "value": 139.498,
        "_internal_originalTime": 1689837562000
    },
    {
        "time": 1689837563000,
        "value": 139.499,
        "_internal_originalTime": 1689837563000
    },
    {
        "time": 1689837564000,
        "value": 139.502,
        "_internal_originalTime": 1689837564000
    },
    {
        "time": 1689837565000,
        "value": 139.503,
        "_internal_originalTime": 1689837565000
    },
    {
        "time": 1689837566000,
        "value": 139.51,
        "_internal_originalTime": 1689837566000
    },
    {
        "time": 1689837568000,
        "value": 139.509,
        "_internal_originalTime": 1689837568000
    },
    {
        "time": 1689837569000,
        "value": 139.511,
        "_internal_originalTime": 1689837569000
    },
    {
        "time": 1689837570000,
        "value": 139.508,
        "_internal_originalTime": 1689837570000
    },
    {
        "time": 1689837571000,
        "value": 139.512,
        "_internal_originalTime": 1689837571000
    },
    {
        "time": 1689837572000,
        "value": 139.507,
        "_internal_originalTime": 1689837572000
    },
    {
        "time": 1689837573000,
        "value": 139.506,
        "_internal_originalTime": 1689837573000
    },
    {
        "time": 1689837574000,
        "value": 139.506,
        "_internal_originalTime": 1689837574000
    },
    {
        "time": 1689837576000,
        "value": 139.512,
        "_internal_originalTime": 1689837576000
    },
    {
        "time": 1689837578000,
        "value": 139.508,
        "_internal_originalTime": 1689837578000
    },
    {
        "time": 1689837579000,
        "value": 139.5,
        "_internal_originalTime": 1689837579000
    },
    {
        "time": 1689837581000,
        "value": 139.502,
        "_internal_originalTime": 1689837581000
    },
    {
        "time": 1689837583000,
        "value": 139.5,
        "_internal_originalTime": 1689837583000
    },
    {
        "time": 1689837584000,
        "value": 139.501,
        "_internal_originalTime": 1689837584000
    },
    {
        "time": 1689837586000,
        "value": 139.498,
        "_internal_originalTime": 1689837586000
    },
    {
        "time": 1689837587000,
        "value": 139.497,
        "_internal_originalTime": 1689837587000
    },
    {
        "time": 1689837589000,
        "value": 139.5,
        "_internal_originalTime": 1689837589000
    },
    {
        "time": 1689837590000,
        "value": 139.501,
        "_internal_originalTime": 1689837590000
    },
    {
        "time": 1689837592000,
        "value": 139.497,
        "_internal_originalTime": 1689837592000
    },
    {
        "time": 1689837593000,
        "value": 139.496,
        "_internal_originalTime": 1689837593000
    },
    {
        "time": 1689837594000,
        "value": 139.496,
        "_internal_originalTime": 1689837594000
    },
    {
        "time": 1689837595000,
        "value": 139.501,
        "_internal_originalTime": 1689837595000
    },
    {
        "time": 1689837596000,
        "value": 139.501,
        "_internal_originalTime": 1689837596000
    },
    {
        "time": 1689837598000,
        "value": 139.501,
        "_internal_originalTime": 1689837598000
    },
    {
        "time": 1689837599000,
        "value": 139.489,
        "_internal_originalTime": 1689837599000
    },
    {
        "time": 1689837600000,
        "value": 139.494,
        "_internal_originalTime": 1689837600000
    },
    {
        "time": 1689837602000,
        "value": 139.506,
        "_internal_originalTime": 1689837602000
    },
    {
        "time": 1689837603000,
        "value": 139.504,
        "_internal_originalTime": 1689837603000
    },
    {
        "time": 1689837605000,
        "value": 139.511,
        "_internal_originalTime": 1689837605000
    },
    {
        "time": 1689837606000,
        "value": 139.516,
        "_internal_originalTime": 1689837606000
    },
    {
        "time": 1689837607000,
        "value": 139.511,
        "_internal_originalTime": 1689837607000
    },
    {
        "time": 1689837608000,
        "value": 139.51,
        "_internal_originalTime": 1689837608000
    },
    {
        "time": 1689837610000,
        "value": 139.508,
        "_internal_originalTime": 1689837610000
    },
    {
        "time": 1689837611000,
        "value": 139.509,
        "_internal_originalTime": 1689837611000
    },
    {
        "time": 1689837612000,
        "value": 139.511,
        "_internal_originalTime": 1689837612000
    },
    {
        "time": 1689837613000,
        "value": 139.511,
        "_internal_originalTime": 1689837613000
    },
    {
        "time": 1689837614000,
        "value": 139.512,
        "_internal_originalTime": 1689837614000
    },
    {
        "time": 1689837615000,
        "value": 139.507,
        "_internal_originalTime": 1689837615000
    },
    {
        "time": 1689837617000,
        "value": 139.504,
        "_internal_originalTime": 1689837617000
    },
    {
        "time": 1689837618000,
        "value": 139.501,
        "_internal_originalTime": 1689837618000
    },
    {
        "time": 1689837619000,
        "value": 139.502,
        "_internal_originalTime": 1689837619000
    },
    {
        "time": 1689837620000,
        "value": 139.498,
        "_internal_originalTime": 1689837620000
    },
    {
        "time": 1689837622000,
        "value": 139.499,
        "_internal_originalTime": 1689837622000
    },
    {
        "time": 1689837623000,
        "value": 139.508,
        "_internal_originalTime": 1689837623000
    },
    {
        "time": 1689837625000,
        "value": 139.504,
        "_internal_originalTime": 1689837625000
    },
    {
        "time": 1689837626000,
        "value": 139.504,
        "_internal_originalTime": 1689837626000
    },
    {
        "time": 1689837627000,
        "value": 139.509,
        "_internal_originalTime": 1689837627000
    },
    {
        "time": 1689837628000,
        "value": 139.503,
        "_internal_originalTime": 1689837628000
    },
    {
        "time": 1689837629000,
        "value": 139.497,
        "_internal_originalTime": 1689837629000
    },
    {
        "time": 1689837630000,
        "value": 139.498,
        "_internal_originalTime": 1689837630000
    },
    {
        "time": 1689837631000,
        "value": 139.502,
        "_internal_originalTime": 1689837631000
    },
    {
        "time": 1689837632000,
        "value": 139.498,
        "_internal_originalTime": 1689837632000
    },
    {
        "time": 1689837633000,
        "value": 139.499,
        "_internal_originalTime": 1689837633000
    },
    {
        "time": 1689837634000,
        "value": 139.502,
        "_internal_originalTime": 1689837634000
    },
    {
        "time": 1689837635000,
        "value": 139.499,
        "_internal_originalTime": 1689837635000
    },
    {
        "time": 1689837636000,
        "value": 139.498,
        "_internal_originalTime": 1689837636000
    },
    {
        "time": 1689837637000,
        "value": 139.491,
        "_internal_originalTime": 1689837637000
    },
    {
        "time": 1689837638000,
        "value": 139.487,
        "_internal_originalTime": 1689837638000
    },
    {
        "time": 1689837639000,
        "value": 139.492,
        "_internal_originalTime": 1689837639000
    },
    {
        "time": 1689837640000,
        "value": 139.488,
        "_internal_originalTime": 1689837640000
    },
    {
        "time": 1689837641000,
        "value": 139.487,
        "_internal_originalTime": 1689837641000
    },
    {
        "time": 1689837642000,
        "value": 139.492,
        "_internal_originalTime": 1689837642000
    },
    {
        "time": 1689837643000,
        "value": 139.481,
        "_internal_originalTime": 1689837643000
    },
    {
        "time": 1689837645000,
        "value": 139.478,
        "_internal_originalTime": 1689837645000
    },
    {
        "time": 1689837646000,
        "value": 139.478,
        "_internal_originalTime": 1689837646000
    },
    {
        "time": 1689837647000,
        "value": 139.472,
        "_internal_originalTime": 1689837647000
    },
    {
        "time": 1689837649000,
        "value": 139.47,
        "_internal_originalTime": 1689837649000
    },
    {
        "time": 1689837650000,
        "value": 139.475,
        "_internal_originalTime": 1689837650000
    },
    {
        "time": 1689837651000,
        "value": 139.468,
        "_internal_originalTime": 1689837651000
    },
    {
        "time": 1689837652000,
        "value": 139.47,
        "_internal_originalTime": 1689837652000
    },
    {
        "time": 1689837653000,
        "value": 139.466,
        "_internal_originalTime": 1689837653000
    },
    {
        "time": 1689837654000,
        "value": 139.465,
        "_internal_originalTime": 1689837654000
    },
    {
        "time": 1689837655000,
        "value": 139.465,
        "_internal_originalTime": 1689837655000
    },
    {
        "time": 1689837656000,
        "value": 139.465,
        "_internal_originalTime": 1689837656000
    },
    {
        "time": 1689837657000,
        "value": 139.458,
        "_internal_originalTime": 1689837657000
    },
    {
        "time": 1689837658000,
        "value": 139.461,
        "_internal_originalTime": 1689837658000
    },
    {
        "time": 1689837660000,
        "value": 139.462,
        "_internal_originalTime": 1689837660000
    },
    {
        "time": 1689837661000,
        "value": 139.463,
        "_internal_originalTime": 1689837661000
    },
    {
        "time": 1689837662000,
        "value": 139.455,
        "_internal_originalTime": 1689837662000
    },
    {
        "time": 1689837663000,
        "value": 139.455,
        "_internal_originalTime": 1689837663000
    },
    {
        "time": 1689837664000,
        "value": 139.45,
        "_internal_originalTime": 1689837664000
    },
    {
        "time": 1689837666000,
        "value": 139.452,
        "_internal_originalTime": 1689837666000
    },
    {
        "time": 1689837667000,
        "value": 139.46,
        "_internal_originalTime": 1689837667000
    },
    {
        "time": 1689837668000,
        "value": 139.453,
        "_internal_originalTime": 1689837668000
    },
    {
        "time": 1689837669000,
        "value": 139.449,
        "_internal_originalTime": 1689837669000
    },
    {
        "time": 1689837670000,
        "value": 139.443,
        "_internal_originalTime": 1689837670000
    },
    {
        "time": 1689837671000,
        "value": 139.443,
        "_internal_originalTime": 1689837671000
    },
    {
        "time": 1689837672000,
        "value": 139.452,
        "_internal_originalTime": 1689837672000
    },
    {
        "time": 1689837674000,
        "value": 139.449,
        "_internal_originalTime": 1689837674000
    },
    {
        "time": 1689837675000,
        "value": 139.453,
        "_internal_originalTime": 1689837675000
    },
    {
        "time": 1689837676000,
        "value": 139.45,
        "_internal_originalTime": 1689837676000
    },
    {
        "time": 1689837677000,
        "value": 139.45,
        "_internal_originalTime": 1689837677000
    },
    {
        "time": 1689837678000,
        "value": 139.451,
        "_internal_originalTime": 1689837678000
    },
    {
        "time": 1689837679000,
        "value": 139.452,
        "_internal_originalTime": 1689837679000
    },
    {
        "time": 1689837681000,
        "value": 139.452,
        "_internal_originalTime": 1689837681000
    },
    {
        "time": 1689837682000,
        "value": 139.457,
        "_internal_originalTime": 1689837682000
    },
    {
        "time": 1689837683000,
        "value": 139.462,
        "_internal_originalTime": 1689837683000
    },
    {
        "time": 1689837684000,
        "value": 139.466,
        "_internal_originalTime": 1689837684000
    },
    {
        "time": 1689837686000,
        "value": 139.46,
        "_internal_originalTime": 1689837686000
    },
    {
        "time": 1689837687000,
        "value": 139.464,
        "_internal_originalTime": 1689837687000
    },
    {
        "time": 1689837689000,
        "value": 139.462,
        "_internal_originalTime": 1689837689000
    },
    {
        "time": 1689837690000,
        "value": 139.46,
        "_internal_originalTime": 1689837690000
    },
    {
        "time": 1689837692000,
        "value": 139.464,
        "_internal_originalTime": 1689837692000
    },
    {
        "time": 1689837694000,
        "value": 139.466,
        "_internal_originalTime": 1689837694000
    },
    {
        "time": 1689837695000,
        "value": 139.466,
        "_internal_originalTime": 1689837695000
    },
    {
        "time": 1689837696000,
        "value": 139.465,
        "_internal_originalTime": 1689837696000
    },
    {
        "time": 1689837697000,
        "value": 139.457,
        "_internal_originalTime": 1689837697000
    },
    {
        "time": 1689837698000,
        "value": 139.452,
        "_internal_originalTime": 1689837698000
    },
    {
        "time": 1689837699000,
        "value": 139.444,
        "_internal_originalTime": 1689837699000
    },
    {
        "time": 1689837700000,
        "value": 139.442,
        "_internal_originalTime": 1689837700000
    },
    {
        "time": 1689837701000,
        "value": 139.443,
        "_internal_originalTime": 1689837701000
    },
    {
        "time": 1689837703000,
        "value": 139.448,
        "_internal_originalTime": 1689837703000
    },
    {
        "time": 1689837704000,
        "value": 139.446,
        "_internal_originalTime": 1689837704000
    },
    {
        "time": 1689837706000,
        "value": 139.448,
        "_internal_originalTime": 1689837706000
    },
    {
        "time": 1689837707000,
        "value": 139.447,
        "_internal_originalTime": 1689837707000
    },
    {
        "time": 1689837708000,
        "value": 139.44,
        "_internal_originalTime": 1689837708000
    },
    {
        "time": 1689837709000,
        "value": 139.442,
        "_internal_originalTime": 1689837709000
    },
    {
        "time": 1689837711000,
        "value": 139.447,
        "_internal_originalTime": 1689837711000
    },
    {
        "time": 1689837713000,
        "value": 139.445,
        "_internal_originalTime": 1689837713000
    },
    {
        "time": 1689837715000,
        "value": 139.449,
        "_internal_originalTime": 1689837715000
    },
    {
        "time": 1689837716000,
        "value": 139.453,
        "_internal_originalTime": 1689837716000
    },
    {
        "time": 1689837717000,
        "value": 139.452,
        "_internal_originalTime": 1689837717000
    },
    {
        "time": 1689837718000,
        "value": 139.455,
        "_internal_originalTime": 1689837718000
    },
    {
        "time": 1689837720000,
        "value": 139.455,
        "_internal_originalTime": 1689837720000
    },
    {
        "time": 1689837721000,
        "value": 139.45,
        "_internal_originalTime": 1689837721000
    },
    {
        "time": 1689837722000,
        "value": 139.449,
        "_internal_originalTime": 1689837722000
    },
    {
        "time": 1689837723000,
        "value": 139.447,
        "_internal_originalTime": 1689837723000
    },
    {
        "time": 1689837724000,
        "value": 139.449,
        "_internal_originalTime": 1689837724000
    },
    {
        "time": 1689837725000,
        "value": 139.443,
        "_internal_originalTime": 1689837725000
    },
    {
        "time": 1689837727000,
        "value": 139.434,
        "_internal_originalTime": 1689837727000
    },
    {
        "time": 1689837728000,
        "value": 139.436,
        "_internal_originalTime": 1689837728000
    },
    {
        "time": 1689837729000,
        "value": 139.437,
        "_internal_originalTime": 1689837729000
    },
    {
        "time": 1689837730000,
        "value": 139.436,
        "_internal_originalTime": 1689837730000
    },
    {
        "time": 1689837731000,
        "value": 139.433,
        "_internal_originalTime": 1689837731000
    },
    {
        "time": 1689837732000,
        "value": 139.437,
        "_internal_originalTime": 1689837732000
    },
    {
        "time": 1689837734000,
        "value": 139.451,
        "_internal_originalTime": 1689837734000
    },
    {
        "time": 1689837735000,
        "value": 139.445,
        "_internal_originalTime": 1689837735000
    },
    {
        "time": 1689837736000,
        "value": 139.447,
        "_internal_originalTime": 1689837736000
    },
    {
        "time": 1689837738000,
        "value": 139.448,
        "_internal_originalTime": 1689837738000
    },
    {
        "time": 1689837739000,
        "value": 139.447,
        "_internal_originalTime": 1689837739000
    },
    {
        "time": 1689837740000,
        "value": 139.447,
        "_internal_originalTime": 1689837740000
    },
    {
        "time": 1689837741000,
        "value": 139.447,
        "_internal_originalTime": 1689837741000
    },
    {
        "time": 1689837742000,
        "value": 139.451,
        "_internal_originalTime": 1689837742000
    },
    {
        "time": 1689837743000,
        "value": 139.455,
        "_internal_originalTime": 1689837743000
    },
    {
        "time": 1689837744000,
        "value": 139.456,
        "_internal_originalTime": 1689837744000
    },
    {
        "time": 1689837745000,
        "value": 139.455,
        "_internal_originalTime": 1689837745000
    },
    {
        "time": 1689837747000,
        "value": 139.455,
        "_internal_originalTime": 1689837747000
    },
    {
        "time": 1689837748000,
        "value": 139.454,
        "_internal_originalTime": 1689837748000
    },
    {
        "time": 1689837749000,
        "value": 139.454,
        "_internal_originalTime": 1689837749000
    },
    {
        "time": 1689837750000,
        "value": 139.458,
        "_internal_originalTime": 1689837750000
    },
    {
        "time": 1689837751000,
        "value": 139.46,
        "_internal_originalTime": 1689837751000
    },
    {
        "time": 1689837752000,
        "value": 139.452,
        "_internal_originalTime": 1689837752000
    },
    {
        "time": 1689837753000,
        "value": 139.452,
        "_internal_originalTime": 1689837753000
    },
    {
        "time": 1689837754000,
        "value": 139.453,
        "_internal_originalTime": 1689837754000
    },
    {
        "time": 1689837755000,
        "value": 139.456,
        "_internal_originalTime": 1689837755000
    },
    {
        "time": 1689837757000,
        "value": 139.46,
        "_internal_originalTime": 1689837757000
    },
    {
        "time": 1689837758000,
        "value": 139.456,
        "_internal_originalTime": 1689837758000
    },
    {
        "time": 1689837759000,
        "value": 139.459,
        "_internal_originalTime": 1689837759000
    },
    {
        "time": 1689837762000,
        "value": 139.462,
        "_internal_originalTime": 1689837762000
    },
    {
        "time": 1689837763000,
        "value": 139.476,
        "_internal_originalTime": 1689837763000
    },
    {
        "time": 1689837765000,
        "value": 139.476,
        "_internal_originalTime": 1689837765000
    },
    {
        "time": 1689837767000,
        "value": 139.475,
        "_internal_originalTime": 1689837767000
    },
    {
        "time": 1689837768000,
        "value": 139.472,
        "_internal_originalTime": 1689837768000
    },
    {
        "time": 1689837770000,
        "value": 139.476,
        "_internal_originalTime": 1689837770000
    },
    {
        "time": 1689837772000,
        "value": 139.468,
        "_internal_originalTime": 1689837772000
    },
    {
        "time": 1689837774000,
        "value": 139.476,
        "_internal_originalTime": 1689837774000
    },
    {
        "time": 1689837775000,
        "value": 139.473,
        "_internal_originalTime": 1689837775000
    },
    {
        "time": 1689837776000,
        "value": 139.462,
        "_internal_originalTime": 1689837776000
    },
    {
        "time": 1689837777000,
        "value": 139.464,
        "_internal_originalTime": 1689837777000
    },
    {
        "time": 1689837778000,
        "value": 139.46,
        "_internal_originalTime": 1689837778000
    },
    {
        "time": 1689837779000,
        "value": 139.462,
        "_internal_originalTime": 1689837779000
    },
    {
        "time": 1689837781000,
        "value": 139.47,
        "_internal_originalTime": 1689837781000
    },
    {
        "time": 1689837782000,
        "value": 139.468,
        "_internal_originalTime": 1689837782000
    },
    {
        "time": 1689837783000,
        "value": 139.47,
        "_internal_originalTime": 1689837783000
    },
    {
        "time": 1689837784000,
        "value": 139.478,
        "_internal_originalTime": 1689837784000
    },
    {
        "time": 1689837785000,
        "value": 139.47,
        "_internal_originalTime": 1689837785000
    },
    {
        "time": 1689837787000,
        "value": 139.477,
        "_internal_originalTime": 1689837787000
    },
    {
        "time": 1689837788000,
        "value": 139.465,
        "_internal_originalTime": 1689837788000
    },
    {
        "time": 1689837789000,
        "value": 139.463,
        "_internal_originalTime": 1689837789000
    },
    {
        "time": 1689837791000,
        "value": 139.468,
        "_internal_originalTime": 1689837791000
    },
    {
        "time": 1689837792000,
        "value": 139.467,
        "_internal_originalTime": 1689837792000
    },
    {
        "time": 1689837793000,
        "value": 139.466,
        "_internal_originalTime": 1689837793000
    },
    {
        "time": 1689837794000,
        "value": 139.458,
        "_internal_originalTime": 1689837794000
    },
    {
        "time": 1689837795000,
        "value": 139.463,
        "_internal_originalTime": 1689837795000
    },
    {
        "time": 1689837796000,
        "value": 139.452,
        "_internal_originalTime": 1689837796000
    },
    {
        "time": 1689837797000,
        "value": 139.45,
        "_internal_originalTime": 1689837797000
    },
    {
        "time": 1689837798000,
        "value": 139.451,
        "_internal_originalTime": 1689837798000
    },
    {
        "time": 1689837799000,
        "value": 139.452,
        "_internal_originalTime": 1689837799000
    },
    {
        "time": 1689837800000,
        "value": 139.449,
        "_internal_originalTime": 1689837800000
    },
    {
        "time": 1689837801000,
        "value": 139.451,
        "_internal_originalTime": 1689837801000
    },
    {
        "time": 1689837802000,
        "value": 139.455,
        "_internal_originalTime": 1689837802000
    },
    {
        "time": 1689837804000,
        "value": 139.452,
        "_internal_originalTime": 1689837804000
    },
    {
        "time": 1689837805000,
        "value": 139.456,
        "_internal_originalTime": 1689837805000
    },
    {
        "time": 1689837807000,
        "value": 139.455,
        "_internal_originalTime": 1689837807000
    },
    {
        "time": 1689837808000,
        "value": 139.451,
        "_internal_originalTime": 1689837808000
    },
    {
        "time": 1689837810000,
        "value": 139.456,
        "_internal_originalTime": 1689837810000
    },
    {
        "time": 1689837811000,
        "value": 139.446,
        "_internal_originalTime": 1689837811000
    },
    {
        "time": 1689837812000,
        "value": 139.446,
        "_internal_originalTime": 1689837812000
    },
    {
        "time": 1689837814000,
        "value": 139.448,
        "_internal_originalTime": 1689837814000
    },
    {
        "time": 1689837816000,
        "value": 139.447,
        "_internal_originalTime": 1689837816000
    },
    {
        "time": 1689837818000,
        "value": 139.445,
        "_internal_originalTime": 1689837818000
    },
    {
        "time": 1689837820000,
        "value": 139.44,
        "_internal_originalTime": 1689837820000
    },
    {
        "time": 1689837821000,
        "value": 139.438,
        "_internal_originalTime": 1689837821000
    },
    {
        "time": 1689837822000,
        "value": 139.438,
        "_internal_originalTime": 1689837822000
    },
    {
        "time": 1689837823000,
        "value": 139.443,
        "_internal_originalTime": 1689837823000
    },
    {
        "time": 1689837825000,
        "value": 139.436,
        "_internal_originalTime": 1689837825000
    },
    {
        "time": 1689837826000,
        "value": 139.438,
        "_internal_originalTime": 1689837826000
    },
    {
        "time": 1689837827000,
        "value": 139.438,
        "_internal_originalTime": 1689837827000
    },
    {
        "time": 1689837829000,
        "value": 139.44,
        "_internal_originalTime": 1689837829000
    },
    {
        "time": 1689837831000,
        "value": 139.446,
        "_internal_originalTime": 1689837831000
    },
    {
        "time": 1689837833000,
        "value": 139.457,
        "_internal_originalTime": 1689837833000
    },
    {
        "time": 1689837834000,
        "value": 139.452,
        "_internal_originalTime": 1689837834000
    },
    {
        "time": 1689837836000,
        "value": 139.46,
        "_internal_originalTime": 1689837836000
    },
    {
        "time": 1689837837000,
        "value": 139.466,
        "_internal_originalTime": 1689837837000
    },
    {
        "time": 1689837838000,
        "value": 139.462,
        "_internal_originalTime": 1689837838000
    },
    {
        "time": 1689837839000,
        "value": 139.462,
        "_internal_originalTime": 1689837839000
    },
    {
        "time": 1689837840000,
        "value": 139.468,
        "_internal_originalTime": 1689837840000
    },
    {
        "time": 1689837841000,
        "value": 139.464,
        "_internal_originalTime": 1689837841000
    },
    {
        "time": 1689837842000,
        "value": 139.467,
        "_internal_originalTime": 1689837842000
    },
    {
        "time": 1689837843000,
        "value": 139.466,
        "_internal_originalTime": 1689837843000
    },
    {
        "time": 1689837844000,
        "value": 139.466,
        "_internal_originalTime": 1689837844000
    },
    {
        "time": 1689837845000,
        "value": 139.469,
        "_internal_originalTime": 1689837845000
    },
    {
        "time": 1689837847000,
        "value": 139.476,
        "_internal_originalTime": 1689837847000
    },
    {
        "time": 1689837848000,
        "value": 139.475,
        "_internal_originalTime": 1689837848000
    },
    {
        "time": 1689837850000,
        "value": 139.471,
        "_internal_originalTime": 1689837850000
    },
    {
        "time": 1689837851000,
        "value": 139.476,
        "_internal_originalTime": 1689837851000
    },
    {
        "time": 1689837853000,
        "value": 139.472,
        "_internal_originalTime": 1689837853000
    },
    {
        "time": 1689837855000,
        "value": 139.479,
        "_internal_originalTime": 1689837855000
    },
    {
        "time": 1689837856000,
        "value": 139.478,
        "_internal_originalTime": 1689837856000
    },
    {
        "time": 1689837858000,
        "value": 139.482,
        "_internal_originalTime": 1689837858000
    },
    {
        "time": 1689837859000,
        "value": 139.478,
        "_internal_originalTime": 1689837859000
    },
    {
        "time": 1689837860000,
        "value": 139.481,
        "_internal_originalTime": 1689837860000
    },
    {
        "time": 1689837861000,
        "value": 139.482,
        "_internal_originalTime": 1689837861000
    },
    {
        "time": 1689837862000,
        "value": 139.483,
        "_internal_originalTime": 1689837862000
    },
    {
        "time": 1689837863000,
        "value": 139.486,
        "_internal_originalTime": 1689837863000
    },
    {
        "time": 1689837866000,
        "value": 139.491,
        "_internal_originalTime": 1689837866000
    },
    {
        "time": 1689837867000,
        "value": 139.492,
        "_internal_originalTime": 1689837867000
    },
    {
        "time": 1689837869000,
        "value": 139.489,
        "_internal_originalTime": 1689837869000
    },
    {
        "time": 1689837870000,
        "value": 139.489,
        "_internal_originalTime": 1689837870000
    },
    {
        "time": 1689837871000,
        "value": 139.495,
        "_internal_originalTime": 1689837871000
    },
    {
        "time": 1689837872000,
        "value": 139.494,
        "_internal_originalTime": 1689837872000
    },
    {
        "time": 1689837873000,
        "value": 139.49,
        "_internal_originalTime": 1689837873000
    },
    {
        "time": 1689837874000,
        "value": 139.493,
        "_internal_originalTime": 1689837874000
    },
    {
        "time": 1689837875000,
        "value": 139.492,
        "_internal_originalTime": 1689837875000
    },
    {
        "time": 1689837876000,
        "value": 139.494,
        "_internal_originalTime": 1689837876000
    },
    {
        "time": 1689837877000,
        "value": 139.492,
        "_internal_originalTime": 1689837877000
    },
    {
        "time": 1689837878000,
        "value": 139.486,
        "_internal_originalTime": 1689837878000
    },
    {
        "time": 1689837879000,
        "value": 139.485,
        "_internal_originalTime": 1689837879000
    },
    {
        "time": 1689837881000,
        "value": 139.483,
        "_internal_originalTime": 1689837881000
    },
    {
        "time": 1689837882000,
        "value": 139.482,
        "_internal_originalTime": 1689837882000
    },
    {
        "time": 1689837883000,
        "value": 139.481,
        "_internal_originalTime": 1689837883000
    },
    {
        "time": 1689837885000,
        "value": 139.482,
        "_internal_originalTime": 1689837885000
    },
    {
        "time": 1689837886000,
        "value": 139.482,
        "_internal_originalTime": 1689837886000
    },
    {
        "time": 1689837888000,
        "value": 139.484,
        "_internal_originalTime": 1689837888000
    },
    {
        "time": 1689837889000,
        "value": 139.476,
        "_internal_originalTime": 1689837889000
    },
    {
        "time": 1689837890000,
        "value": 139.478,
        "_internal_originalTime": 1689837890000
    },
    {
        "time": 1689837932000,
        "value": 139.532,
        "_internal_originalTime": 1689837932000
    },
    {
        "time": 1689837934000,
        "value": 139.531,
        "_internal_originalTime": 1689837934000
    },
    {
        "time": 1689837935000,
        "value": 139.518,
        "_internal_originalTime": 1689837935000
    },
    {
        "time": 1689837936000,
        "value": 139.515,
        "_internal_originalTime": 1689837936000
    },
    {
        "time": 1689837937000,
        "value": 139.522,
        "_internal_originalTime": 1689837937000
    },
    {
        "time": 1689837938000,
        "value": 139.511,
        "_internal_originalTime": 1689837938000
    },
    {
        "time": 1689837939000,
        "value": 139.51,
        "_internal_originalTime": 1689837939000
    },
    {
        "time": 1689837940000,
        "value": 139.516,
        "_internal_originalTime": 1689837940000
    },
    {
        "time": 1689837941000,
        "value": 139.517,
        "_internal_originalTime": 1689837941000
    },
    {
        "time": 1689837943000,
        "value": 139.512,
        "_internal_originalTime": 1689837943000
    },
    {
        "time": 1689837944000,
        "value": 139.522,
        "_internal_originalTime": 1689837944000
    },
    {
        "time": 1689837945000,
        "value": 139.52,
        "_internal_originalTime": 1689837945000
    },
    {
        "time": 1689837946000,
        "value": 139.523,
        "_internal_originalTime": 1689837946000
    },
    {
        "time": 1689837948000,
        "value": 139.528,
        "_internal_originalTime": 1689837948000
    },
    {
        "time": 1689837949000,
        "value": 139.528,
        "_internal_originalTime": 1689837949000
    },
    {
        "time": 1689837950000,
        "value": 139.528,
        "_internal_originalTime": 1689837950000
    },
    {
        "time": 1689837951000,
        "value": 139.529,
        "_internal_originalTime": 1689837951000
    },
    {
        "time": 1689837952000,
        "value": 139.532,
        "_internal_originalTime": 1689837952000
    },
    {
        "time": 1689837954000,
        "value": 139.524,
        "_internal_originalTime": 1689837954000
    },
    {
        "time": 1689837955000,
        "value": 139.52,
        "_internal_originalTime": 1689837955000
    },
    {
        "time": 1689837956000,
        "value": 139.526,
        "_internal_originalTime": 1689837956000
    },
    {
        "time": 1689837958000,
        "value": 139.523,
        "_internal_originalTime": 1689837958000
    },
    {
        "time": 1689837959000,
        "value": 139.524,
        "_internal_originalTime": 1689837959000
    },
    {
        "time": 1689837960000,
        "value": 139.518,
        "_internal_originalTime": 1689837960000
    },
    {
        "time": 1689837961000,
        "value": 139.527,
        "_internal_originalTime": 1689837961000
    },
    {
        "time": 1689837962000,
        "value": 139.528,
        "_internal_originalTime": 1689837962000
    },
    {
        "time": 1689837963000,
        "value": 139.537,
        "_internal_originalTime": 1689837963000
    },
    {
        "time": 1689837965000,
        "value": 139.531,
        "_internal_originalTime": 1689837965000
    },
    {
        "time": 1689837966000,
        "value": 139.526,
        "_internal_originalTime": 1689837966000
    },
    {
        "time": 1689837967000,
        "value": 139.516,
        "_internal_originalTime": 1689837967000
    },
    {
        "time": 1689837968000,
        "value": 139.516,
        "_internal_originalTime": 1689837968000
    },
    {
        "time": 1689837970000,
        "value": 139.522,
        "_internal_originalTime": 1689837970000
    },
    {
        "time": 1689837972000,
        "value": 139.52,
        "_internal_originalTime": 1689837972000
    },
    {
        "time": 1689837973000,
        "value": 139.517,
        "_internal_originalTime": 1689837973000
    },
    {
        "time": 1689837974000,
        "value": 139.508,
        "_internal_originalTime": 1689837974000
    },
    {
        "time": 1689837975000,
        "value": 139.512,
        "_internal_originalTime": 1689837975000
    },
    {
        "time": 1689837976000,
        "value": 139.508,
        "_internal_originalTime": 1689837976000
    },
    {
        "time": 1689837977000,
        "value": 139.511,
        "_internal_originalTime": 1689837977000
    },
    {
        "time": 1689837978000,
        "value": 139.511,
        "_internal_originalTime": 1689837978000
    },
    {
        "time": 1689837980000,
        "value": 139.501,
        "_internal_originalTime": 1689837980000
    },
    {
        "time": 1689837981000,
        "value": 139.501,
        "_internal_originalTime": 1689837981000
    },
    {
        "time": 1689837982000,
        "value": 139.5,
        "_internal_originalTime": 1689837982000
    },
    {
        "time": 1689837983000,
        "value": 139.503,
        "_internal_originalTime": 1689837983000
    },
    {
        "time": 1689837984000,
        "value": 139.503,
        "_internal_originalTime": 1689837984000
    },
    {
        "time": 1689837985000,
        "value": 139.498,
        "_internal_originalTime": 1689837985000
    },
    {
        "time": 1689837986000,
        "value": 139.503,
        "_internal_originalTime": 1689837986000
    },
    {
        "time": 1689837988000,
        "value": 139.498,
        "_internal_originalTime": 1689837988000
    },
    {
        "time": 1689837990000,
        "value": 139.51,
        "_internal_originalTime": 1689837990000
    },
    {
        "time": 1689837992000,
        "value": 139.51,
        "_internal_originalTime": 1689837992000
    },
    {
        "time": 1689837993000,
        "value": 139.511,
        "_internal_originalTime": 1689837993000
    },
    {
        "time": 1689837995000,
        "value": 139.506,
        "_internal_originalTime": 1689837995000
    },
    {
        "time": 1689837996000,
        "value": 139.504,
        "_internal_originalTime": 1689837996000
    },
    {
        "time": 1689837997000,
        "value": 139.495,
        "_internal_originalTime": 1689837997000
    },
    {
        "time": 1689837999000,
        "value": 139.492,
        "_internal_originalTime": 1689837999000
    },
    {
        "time": 1689838000000,
        "value": 139.491,
        "_internal_originalTime": 1689838000000
    },
    {
        "time": 1689838001000,
        "value": 139.489,
        "_internal_originalTime": 1689838001000
    },
    {
        "time": 1689838002000,
        "value": 139.489,
        "_internal_originalTime": 1689838002000
    },
    {
        "time": 1689838003000,
        "value": 139.488,
        "_internal_originalTime": 1689838003000
    },
    {
        "time": 1689838005000,
        "value": 139.488,
        "_internal_originalTime": 1689838005000
    },
    {
        "time": 1689838006000,
        "value": 139.496,
        "_internal_originalTime": 1689838006000
    },
    {
        "time": 1689838007000,
        "value": 139.496,
        "_internal_originalTime": 1689838007000
    },
    {
        "time": 1689838008000,
        "value": 139.492,
        "_internal_originalTime": 1689838008000
    },
    {
        "time": 1689838009000,
        "value": 139.496,
        "_internal_originalTime": 1689838009000
    },
    {
        "time": 1689838010000,
        "value": 139.486,
        "_internal_originalTime": 1689838010000
    },
    {
        "time": 1689838012000,
        "value": 139.487,
        "_internal_originalTime": 1689838012000
    },
    {
        "time": 1689838014000,
        "value": 139.48,
        "_internal_originalTime": 1689838014000
    },
    {
        "time": 1689838015000,
        "value": 139.484,
        "_internal_originalTime": 1689838015000
    },
    {
        "time": 1689838016000,
        "value": 139.484,
        "_internal_originalTime": 1689838016000
    },
    {
        "time": 1689838019000,
        "value": 139.483,
        "_internal_originalTime": 1689838019000
    },
    {
        "time": 1689838021000,
        "value": 139.483,
        "_internal_originalTime": 1689838021000
    },
    {
        "time": 1689838022000,
        "value": 139.477,
        "_internal_originalTime": 1689838022000
    },
    {
        "time": 1689838025000,
        "value": 139.474,
        "_internal_originalTime": 1689838025000
    },
    {
        "time": 1689838026000,
        "value": 139.474,
        "_internal_originalTime": 1689838026000
    },
    {
        "time": 1689838027000,
        "value": 139.474,
        "_internal_originalTime": 1689838027000
    },
    {
        "time": 1689838028000,
        "value": 139.458,
        "_internal_originalTime": 1689838028000
    },
    {
        "time": 1689838030000,
        "value": 139.454,
        "_internal_originalTime": 1689838030000
    },
    {
        "time": 1689838031000,
        "value": 139.451,
        "_internal_originalTime": 1689838031000
    },
    {
        "time": 1689838032000,
        "value": 139.456,
        "_internal_originalTime": 1689838032000
    },
    {
        "time": 1689838033000,
        "value": 139.456,
        "_internal_originalTime": 1689838033000
    },
    {
        "time": 1689838035000,
        "value": 139.453,
        "_internal_originalTime": 1689838035000
    },
    {
        "time": 1689838036000,
        "value": 139.449,
        "_internal_originalTime": 1689838036000
    },
    {
        "time": 1689838038000,
        "value": 139.447,
        "_internal_originalTime": 1689838038000
    },
    {
        "time": 1689838039000,
        "value": 139.445,
        "_internal_originalTime": 1689838039000
    },
    {
        "time": 1689838040000,
        "value": 139.443,
        "_internal_originalTime": 1689838040000
    },
    {
        "time": 1689838041000,
        "value": 139.443,
        "_internal_originalTime": 1689838041000
    },
    {
        "time": 1689838042000,
        "value": 139.446,
        "_internal_originalTime": 1689838042000
    },
    {
        "time": 1689838043000,
        "value": 139.445,
        "_internal_originalTime": 1689838043000
    },
    {
        "time": 1689838044000,
        "value": 139.444,
        "_internal_originalTime": 1689838044000
    },
    {
        "time": 1689838045000,
        "value": 139.444,
        "_internal_originalTime": 1689838045000
    },
    {
        "time": 1689838047000,
        "value": 139.442,
        "_internal_originalTime": 1689838047000
    },
    {
        "time": 1689838048000,
        "value": 139.438,
        "_internal_originalTime": 1689838048000
    },
    {
        "time": 1689838049000,
        "value": 139.443,
        "_internal_originalTime": 1689838049000
    },
    {
        "time": 1689838050000,
        "value": 139.442,
        "_internal_originalTime": 1689838050000
    },
    {
        "time": 1689838051000,
        "value": 139.442,
        "_internal_originalTime": 1689838051000
    },
    {
        "time": 1689838052000,
        "value": 139.436,
        "_internal_originalTime": 1689838052000
    },
    {
        "time": 1689838053000,
        "value": 139.435,
        "_internal_originalTime": 1689838053000
    },
    {
        "time": 1689838055000,
        "value": 139.431,
        "_internal_originalTime": 1689838055000
    },
    {
        "time": 1689838056000,
        "value": 139.434,
        "_internal_originalTime": 1689838056000
    },
    {
        "time": 1689838057000,
        "value": 139.43,
        "_internal_originalTime": 1689838057000
    },
    {
        "time": 1689838058000,
        "value": 139.433,
        "_internal_originalTime": 1689838058000
    },
    {
        "time": 1689838059000,
        "value": 139.43,
        "_internal_originalTime": 1689838059000
    },
    {
        "time": 1689838060000,
        "value": 139.428,
        "_internal_originalTime": 1689838060000
    },
    {
        "time": 1689838061000,
        "value": 139.425,
        "_internal_originalTime": 1689838061000
    },
    {
        "time": 1689838062000,
        "value": 139.42,
        "_internal_originalTime": 1689838062000
    },
    {
        "time": 1689838063000,
        "value": 139.425,
        "_internal_originalTime": 1689838063000
    },
    {
        "time": 1689838065000,
        "value": 139.422,
        "_internal_originalTime": 1689838065000
    },
    {
        "time": 1689838066000,
        "value": 139.418,
        "_internal_originalTime": 1689838066000
    },
    {
        "time": 1689838067000,
        "value": 139.42,
        "_internal_originalTime": 1689838067000
    },
    {
        "time": 1689838068000,
        "value": 139.423,
        "_internal_originalTime": 1689838068000
    },
    {
        "time": 1689838070000,
        "value": 139.423,
        "_internal_originalTime": 1689838070000
    },
    {
        "time": 1689838071000,
        "value": 139.417,
        "_internal_originalTime": 1689838071000
    },
    {
        "time": 1689838072000,
        "value": 139.416,
        "_internal_originalTime": 1689838072000
    },
    {
        "time": 1689838073000,
        "value": 139.415,
        "_internal_originalTime": 1689838073000
    },
    {
        "time": 1689838075000,
        "value": 139.415,
        "_internal_originalTime": 1689838075000
    },
    {
        "time": 1689838076000,
        "value": 139.407,
        "_internal_originalTime": 1689838076000
    },
    {
        "time": 1689838077000,
        "value": 139.414,
        "_internal_originalTime": 1689838077000
    },
    {
        "time": 1689838078000,
        "value": 139.42,
        "_internal_originalTime": 1689838078000
    },
    {
        "time": 1689838080000,
        "value": 139.418,
        "_internal_originalTime": 1689838080000
    },
    {
        "time": 1689838082000,
        "value": 139.408,
        "_internal_originalTime": 1689838082000
    },
    {
        "time": 1689838083000,
        "value": 139.401,
        "_internal_originalTime": 1689838083000
    },
    {
        "time": 1689838084000,
        "value": 139.404,
        "_internal_originalTime": 1689838084000
    },
    {
        "time": 1689838085000,
        "value": 139.392,
        "_internal_originalTime": 1689838085000
    },
    {
        "time": 1689838086000,
        "value": 139.392,
        "_internal_originalTime": 1689838086000
    },
    {
        "time": 1689838087000,
        "value": 139.388,
        "_internal_originalTime": 1689838087000
    },
    {
        "time": 1689838088000,
        "value": 139.389,
        "_internal_originalTime": 1689838088000
    },
    {
        "time": 1689838089000,
        "value": 139.368,
        "_internal_originalTime": 1689838089000
    },
    {
        "time": 1689838090000,
        "value": 139.378,
        "_internal_originalTime": 1689838090000
    },
    {
        "time": 1689838091000,
        "value": 139.366,
        "_internal_originalTime": 1689838091000
    },
    {
        "time": 1689838092000,
        "value": 139.377,
        "_internal_originalTime": 1689838092000
    },
    {
        "time": 1689838094000,
        "value": 139.386,
        "_internal_originalTime": 1689838094000
    },
    {
        "time": 1689838095000,
        "value": 139.375,
        "_internal_originalTime": 1689838095000
    },
    {
        "time": 1689838096000,
        "value": 139.376,
        "_internal_originalTime": 1689838096000
    },
    {
        "time": 1689838098000,
        "value": 139.376,
        "_internal_originalTime": 1689838098000
    },
    {
        "time": 1689838099000,
        "value": 139.374,
        "_internal_originalTime": 1689838099000
    },
    {
        "time": 1689838100000,
        "value": 139.374,
        "_internal_originalTime": 1689838100000
    },
    {
        "time": 1689838101000,
        "value": 139.361,
        "_internal_originalTime": 1689838101000
    },
    {
        "time": 1689838102000,
        "value": 139.373,
        "_internal_originalTime": 1689838102000
    },
    {
        "time": 1689838103000,
        "value": 139.356,
        "_internal_originalTime": 1689838103000
    },
    {
        "time": 1689838104000,
        "value": 139.36,
        "_internal_originalTime": 1689838104000
    },
    {
        "time": 1689838105000,
        "value": 139.352,
        "_internal_originalTime": 1689838105000
    },
    {
        "time": 1689838106000,
        "value": 139.357,
        "_internal_originalTime": 1689838106000
    },
    {
        "time": 1689838107000,
        "value": 139.357,
        "_internal_originalTime": 1689838107000
    },
    {
        "time": 1689838108000,
        "value": 139.356,
        "_internal_originalTime": 1689838108000
    },
    {
        "time": 1689838110000,
        "value": 139.358,
        "_internal_originalTime": 1689838110000
    },
    {
        "time": 1689838111000,
        "value": 139.354,
        "_internal_originalTime": 1689838111000
    },
    {
        "time": 1689838112000,
        "value": 139.356,
        "_internal_originalTime": 1689838112000
    },
    {
        "time": 1689838114000,
        "value": 139.361,
        "_internal_originalTime": 1689838114000
    },
    {
        "time": 1689838115000,
        "value": 139.356,
        "_internal_originalTime": 1689838115000
    },
    {
        "time": 1689838116000,
        "value": 139.364,
        "_internal_originalTime": 1689838116000
    },
    {
        "time": 1689838117000,
        "value": 139.37,
        "_internal_originalTime": 1689838117000
    },
    {
        "time": 1689838118000,
        "value": 139.374,
        "_internal_originalTime": 1689838118000
    },
    {
        "time": 1689838119000,
        "value": 139.376,
        "_internal_originalTime": 1689838119000
    },
    {
        "time": 1689838120000,
        "value": 139.381,
        "_internal_originalTime": 1689838120000
    },
    {
        "time": 1689838122000,
        "value": 139.379,
        "_internal_originalTime": 1689838122000
    },
    {
        "time": 1689838123000,
        "value": 139.391,
        "_internal_originalTime": 1689838123000
    },
    {
        "time": 1689838124000,
        "value": 139.391,
        "_internal_originalTime": 1689838124000
    },
    {
        "time": 1689838125000,
        "value": 139.396,
        "_internal_originalTime": 1689838125000
    },
    {
        "time": 1689838126000,
        "value": 139.394,
        "_internal_originalTime": 1689838126000
    },
    {
        "time": 1689838128000,
        "value": 139.401,
        "_internal_originalTime": 1689838128000
    },
    {
        "time": 1689838129000,
        "value": 139.398,
        "_internal_originalTime": 1689838129000
    },
    {
        "time": 1689838130000,
        "value": 139.394,
        "_internal_originalTime": 1689838130000
    },
    {
        "time": 1689838132000,
        "value": 139.404,
        "_internal_originalTime": 1689838132000
    },
    {
        "time": 1689838133000,
        "value": 139.394,
        "_internal_originalTime": 1689838133000
    },
    {
        "time": 1689838135000,
        "value": 139.395,
        "_internal_originalTime": 1689838135000
    },
    {
        "time": 1689838136000,
        "value": 139.403,
        "_internal_originalTime": 1689838136000
    },
    {
        "time": 1689838137000,
        "value": 139.403,
        "_internal_originalTime": 1689838137000
    },
    {
        "time": 1689838138000,
        "value": 139.403,
        "_internal_originalTime": 1689838138000
    },
    {
        "time": 1689838139000,
        "value": 139.407,
        "_internal_originalTime": 1689838139000
    },
    {
        "time": 1689838140000,
        "value": 139.407,
        "_internal_originalTime": 1689838140000
    },
    {
        "time": 1689838141000,
        "value": 139.38,
        "_internal_originalTime": 1689838141000
    },
    {
        "time": 1689838142000,
        "value": 139.412,
        "_internal_originalTime": 1689838142000
    },
    {
        "time": 1689838143000,
        "value": 139.414,
        "_internal_originalTime": 1689838143000
    },
    {
        "time": 1689838144000,
        "value": 139.415,
        "_internal_originalTime": 1689838144000
    },
    {
        "time": 1689838145000,
        "value": 139.412,
        "_internal_originalTime": 1689838145000
    },
    {
        "time": 1689838147000,
        "value": 139.426,
        "_internal_originalTime": 1689838147000
    },
    {
        "time": 1689838148000,
        "value": 139.424,
        "_internal_originalTime": 1689838148000
    },
    {
        "time": 1689838149000,
        "value": 139.427,
        "_internal_originalTime": 1689838149000
    },
    {
        "time": 1689838150000,
        "value": 139.411,
        "_internal_originalTime": 1689838150000
    },
    {
        "time": 1689838151000,
        "value": 139.42,
        "_internal_originalTime": 1689838151000
    },
    {
        "time": 1689838152000,
        "value": 139.414,
        "_internal_originalTime": 1689838152000
    },
    {
        "time": 1689838153000,
        "value": 139.42,
        "_internal_originalTime": 1689838153000
    },
    {
        "time": 1689838155000,
        "value": 139.42,
        "_internal_originalTime": 1689838155000
    },
    {
        "time": 1689838156000,
        "value": 139.423,
        "_internal_originalTime": 1689838156000
    },
    {
        "time": 1689838157000,
        "value": 139.427,
        "_internal_originalTime": 1689838157000
    },
    {
        "time": 1689838158000,
        "value": 139.439,
        "_internal_originalTime": 1689838158000
    },
    {
        "time": 1689838159000,
        "value": 139.438,
        "_internal_originalTime": 1689838159000
    },
    {
        "time": 1689838160000,
        "value": 139.44,
        "_internal_originalTime": 1689838160000
    },
    {
        "time": 1689838161000,
        "value": 139.435,
        "_internal_originalTime": 1689838161000
    },
    {
        "time": 1689838162000,
        "value": 139.433,
        "_internal_originalTime": 1689838162000
    },
    {
        "time": 1689838164000,
        "value": 139.428,
        "_internal_originalTime": 1689838164000
    },
    {
        "time": 1689838165000,
        "value": 139.427,
        "_internal_originalTime": 1689838165000
    },
    {
        "time": 1689838166000,
        "value": 139.416,
        "_internal_originalTime": 1689838166000
    },
    {
        "time": 1689838168000,
        "value": 139.42,
        "_internal_originalTime": 1689838168000
    },
    {
        "time": 1689838169000,
        "value": 139.419,
        "_internal_originalTime": 1689838169000
    },
    {
        "time": 1689838171000,
        "value": 139.416,
        "_internal_originalTime": 1689838171000
    },
    {
        "time": 1689838172000,
        "value": 139.417,
        "_internal_originalTime": 1689838172000
    },
    {
        "time": 1689838173000,
        "value": 139.407,
        "_internal_originalTime": 1689838173000
    },
    {
        "time": 1689838174000,
        "value": 139.396,
        "_internal_originalTime": 1689838174000
    },
    {
        "time": 1689838175000,
        "value": 139.407,
        "_internal_originalTime": 1689838175000
    },
    {
        "time": 1689838176000,
        "value": 139.421,
        "_internal_originalTime": 1689838176000
    },
    {
        "time": 1689838177000,
        "value": 139.425,
        "_internal_originalTime": 1689838177000
    },
    {
        "time": 1689838178000,
        "value": 139.426,
        "_internal_originalTime": 1689838178000
    },
    {
        "time": 1689838179000,
        "value": 139.424,
        "_internal_originalTime": 1689838179000
    },
    {
        "time": 1689838180000,
        "value": 139.424,
        "_internal_originalTime": 1689838180000
    },
    {
        "time": 1689838181000,
        "value": 139.414,
        "_internal_originalTime": 1689838181000
    },
    {
        "time": 1689838182000,
        "value": 139.417,
        "_internal_originalTime": 1689838182000
    },
    {
        "time": 1689838183000,
        "value": 139.411,
        "_internal_originalTime": 1689838183000
    },
    {
        "time": 1689838185000,
        "value": 139.416,
        "_internal_originalTime": 1689838185000
    },
    {
        "time": 1689838186000,
        "value": 139.418,
        "_internal_originalTime": 1689838186000
    },
    {
        "time": 1689838187000,
        "value": 139.415,
        "_internal_originalTime": 1689838187000
    },
    {
        "time": 1689838188000,
        "value": 139.414,
        "_internal_originalTime": 1689838188000
    },
    {
        "time": 1689838191000,
        "value": 139.414,
        "_internal_originalTime": 1689838191000
    },
    {
        "time": 1689838192000,
        "value": 139.423,
        "_internal_originalTime": 1689838192000
    },
    {
        "time": 1689838193000,
        "value": 139.433,
        "_internal_originalTime": 1689838193000
    },
    {
        "time": 1689838194000,
        "value": 139.434,
        "_internal_originalTime": 1689838194000
    },
    {
        "time": 1689838195000,
        "value": 139.431,
        "_internal_originalTime": 1689838195000
    },
    {
        "time": 1689838197000,
        "value": 139.428,
        "_internal_originalTime": 1689838197000
    },
    {
        "time": 1689838198000,
        "value": 139.425,
        "_internal_originalTime": 1689838198000
    },
    {
        "time": 1689838200000,
        "value": 139.424,
        "_internal_originalTime": 1689838200000
    },
    {
        "time": 1689838201000,
        "value": 139.426,
        "_internal_originalTime": 1689838201000
    },
    {
        "time": 1689838202000,
        "value": 139.419,
        "_internal_originalTime": 1689838202000
    },
    {
        "time": 1689838203000,
        "value": 139.418,
        "_internal_originalTime": 1689838203000
    },
    {
        "time": 1689838204000,
        "value": 139.424,
        "_internal_originalTime": 1689838204000
    },
    {
        "time": 1689838205000,
        "value": 139.426,
        "_internal_originalTime": 1689838205000
    },
    {
        "time": 1689838206000,
        "value": 139.424,
        "_internal_originalTime": 1689838206000
    },
    {
        "time": 1689838207000,
        "value": 139.422,
        "_internal_originalTime": 1689838207000
    },
    {
        "time": 1689838208000,
        "value": 139.416,
        "_internal_originalTime": 1689838208000
    },
    {
        "time": 1689838209000,
        "value": 139.406,
        "_internal_originalTime": 1689838209000
    },
    {
        "time": 1689838210000,
        "value": 139.405,
        "_internal_originalTime": 1689838210000
    },
    {
        "time": 1689838211000,
        "value": 139.402,
        "_internal_originalTime": 1689838211000
    },
    {
        "time": 1689838212000,
        "value": 139.405,
        "_internal_originalTime": 1689838212000
    },
    {
        "time": 1689838214000,
        "value": 139.406,
        "_internal_originalTime": 1689838214000
    },
    {
        "time": 1689838215000,
        "value": 139.41,
        "_internal_originalTime": 1689838215000
    },
    {
        "time": 1689838216000,
        "value": 139.407,
        "_internal_originalTime": 1689838216000
    },
    {
        "time": 1689838217000,
        "value": 139.41,
        "_internal_originalTime": 1689838217000
    },
    {
        "time": 1689838219000,
        "value": 139.41,
        "_internal_originalTime": 1689838219000
    },
    {
        "time": 1689838220000,
        "value": 139.413,
        "_internal_originalTime": 1689838220000
    },
    {
        "time": 1689838221000,
        "value": 139.408,
        "_internal_originalTime": 1689838221000
    },
    {
        "time": 1689838222000,
        "value": 139.402,
        "_internal_originalTime": 1689838222000
    },
    {
        "time": 1689838223000,
        "value": 139.41,
        "_internal_originalTime": 1689838223000
    },
    {
        "time": 1689838224000,
        "value": 139.405,
        "_internal_originalTime": 1689838224000
    },
    {
        "time": 1689838226000,
        "value": 139.416,
        "_internal_originalTime": 1689838226000
    },
    {
        "time": 1689838227000,
        "value": 139.415,
        "_internal_originalTime": 1689838227000
    },
    {
        "time": 1689838228000,
        "value": 139.416,
        "_internal_originalTime": 1689838228000
    },
    {
        "time": 1689838230000,
        "value": 139.423,
        "_internal_originalTime": 1689838230000
    },
    {
        "time": 1689838231000,
        "value": 139.416,
        "_internal_originalTime": 1689838231000
    },
    {
        "time": 1689838232000,
        "value": 139.408,
        "_internal_originalTime": 1689838232000
    },
    {
        "time": 1689838233000,
        "value": 139.41,
        "_internal_originalTime": 1689838233000
    },
    {
        "time": 1689838234000,
        "value": 139.422,
        "_internal_originalTime": 1689838234000
    },
    {
        "time": 1689838236000,
        "value": 139.426,
        "_internal_originalTime": 1689838236000
    },
    {
        "time": 1689838237000,
        "value": 139.422,
        "_internal_originalTime": 1689838237000
    },
    {
        "time": 1689838239000,
        "value": 139.418,
        "_internal_originalTime": 1689838239000
    },
    {
        "time": 1689838240000,
        "value": 139.428,
        "_internal_originalTime": 1689838240000
    },
    {
        "time": 1689838241000,
        "value": 139.433,
        "_internal_originalTime": 1689838241000
    },
    {
        "time": 1689838242000,
        "value": 139.428,
        "_internal_originalTime": 1689838242000
    },
    {
        "time": 1689838243000,
        "value": 139.432,
        "_internal_originalTime": 1689838243000
    },
    {
        "time": 1689838244000,
        "value": 139.428,
        "_internal_originalTime": 1689838244000
    },
    {
        "time": 1689838245000,
        "value": 139.432,
        "_internal_originalTime": 1689838245000
    },
    {
        "time": 1689838246000,
        "value": 139.422,
        "_internal_originalTime": 1689838246000
    },
    {
        "time": 1689838247000,
        "value": 139.421,
        "_internal_originalTime": 1689838247000
    },
    {
        "time": 1689838248000,
        "value": 139.423,
        "_internal_originalTime": 1689838248000
    },
    {
        "time": 1689838249000,
        "value": 139.421,
        "_internal_originalTime": 1689838249000
    },
    {
        "time": 1689838250000,
        "value": 139.42,
        "_internal_originalTime": 1689838250000
    },
    {
        "time": 1689838251000,
        "value": 139.423,
        "_internal_originalTime": 1689838251000
    },
    {
        "time": 1689838252000,
        "value": 139.424,
        "_internal_originalTime": 1689838252000
    },
    {
        "time": 1689838253000,
        "value": 139.422,
        "_internal_originalTime": 1689838253000
    },
    {
        "time": 1689838254000,
        "value": 139.423,
        "_internal_originalTime": 1689838254000
    },
    {
        "time": 1689838257000,
        "value": 139.433,
        "_internal_originalTime": 1689838257000
    },
    {
        "time": 1689838258000,
        "value": 139.436,
        "_internal_originalTime": 1689838258000
    },
    {
        "time": 1689838261000,
        "value": 139.434,
        "_internal_originalTime": 1689838261000
    },
    {
        "time": 1689838262000,
        "value": 139.434,
        "_internal_originalTime": 1689838262000
    },
    {
        "time": 1689838263000,
        "value": 139.436,
        "_internal_originalTime": 1689838263000
    },
    {
        "time": 1689838264000,
        "value": 139.438,
        "_internal_originalTime": 1689838264000
    },
    {
        "time": 1689838265000,
        "value": 139.438,
        "_internal_originalTime": 1689838265000
    },
    {
        "time": 1689838269000,
        "value": 139.43,
        "_internal_originalTime": 1689838269000
    },
    {
        "time": 1689838270000,
        "value": 139.433,
        "_internal_originalTime": 1689838270000
    },
    {
        "time": 1689838272000,
        "value": 139.434,
        "_internal_originalTime": 1689838272000
    },
    {
        "time": 1689838274000,
        "value": 139.434,
        "_internal_originalTime": 1689838274000
    },
    {
        "time": 1689838275000,
        "value": 139.43,
        "_internal_originalTime": 1689838275000
    },
    {
        "time": 1689838276000,
        "value": 139.426,
        "_internal_originalTime": 1689838276000
    },
    {
        "time": 1689838277000,
        "value": 139.427,
        "_internal_originalTime": 1689838277000
    },
    {
        "time": 1689838278000,
        "value": 139.429,
        "_internal_originalTime": 1689838278000
    },
    {
        "time": 1689838279000,
        "value": 139.426,
        "_internal_originalTime": 1689838279000
    },
    {
        "time": 1689838280000,
        "value": 139.426,
        "_internal_originalTime": 1689838280000
    },
    {
        "time": 1689838281000,
        "value": 139.432,
        "_internal_originalTime": 1689838281000
    },
    {
        "time": 1689838282000,
        "value": 139.423,
        "_internal_originalTime": 1689838282000
    },
    {
        "time": 1689838284000,
        "value": 139.426,
        "_internal_originalTime": 1689838284000
    },
    {
        "time": 1689838286000,
        "value": 139.425,
        "_internal_originalTime": 1689838286000
    },
    {
        "time": 1689838287000,
        "value": 139.425,
        "_internal_originalTime": 1689838287000
    },
    {
        "time": 1689838288000,
        "value": 139.421,
        "_internal_originalTime": 1689838288000
    },
    {
        "time": 1689838289000,
        "value": 139.42,
        "_internal_originalTime": 1689838289000
    },
    {
        "time": 1689838290000,
        "value": 139.424,
        "_internal_originalTime": 1689838290000
    },
    {
        "time": 1689838291000,
        "value": 139.425,
        "_internal_originalTime": 1689838291000
    },
    {
        "time": 1689838292000,
        "value": 139.424,
        "_internal_originalTime": 1689838292000
    },
    {
        "time": 1689838293000,
        "value": 139.412,
        "_internal_originalTime": 1689838293000
    },
    {
        "time": 1689838294000,
        "value": 139.41,
        "_internal_originalTime": 1689838294000
    },
    {
        "time": 1689838295000,
        "value": 139.41,
        "_internal_originalTime": 1689838295000
    },
    {
        "time": 1689838296000,
        "value": 139.409,
        "_internal_originalTime": 1689838296000
    },
    {
        "time": 1689838298000,
        "value": 139.41,
        "_internal_originalTime": 1689838298000
    },
    {
        "time": 1689838299000,
        "value": 139.412,
        "_internal_originalTime": 1689838299000
    },
    {
        "time": 1689838300000,
        "value": 139.408,
        "_internal_originalTime": 1689838300000
    },
    {
        "time": 1689838301000,
        "value": 139.408,
        "_internal_originalTime": 1689838301000
    },
    {
        "time": 1689838302000,
        "value": 139.408,
        "_internal_originalTime": 1689838302000
    },
    {
        "time": 1689838303000,
        "value": 139.406,
        "_internal_originalTime": 1689838303000
    },
    {
        "time": 1689838304000,
        "value": 139.407,
        "_internal_originalTime": 1689838304000
    },
    {
        "time": 1689838305000,
        "value": 139.408,
        "_internal_originalTime": 1689838305000
    },
    {
        "time": 1689838306000,
        "value": 139.408,
        "_internal_originalTime": 1689838306000
    },
    {
        "time": 1689838308000,
        "value": 139.402,
        "_internal_originalTime": 1689838308000
    },
    {
        "time": 1689838309000,
        "value": 139.403,
        "_internal_originalTime": 1689838309000
    },
    {
        "time": 1689838310000,
        "value": 139.402,
        "_internal_originalTime": 1689838310000
    },
    {
        "time": 1689838311000,
        "value": 139.402,
        "_internal_originalTime": 1689838311000
    },
    {
        "time": 1689838313000,
        "value": 139.403,
        "_internal_originalTime": 1689838313000
    },
    {
        "time": 1689838314000,
        "value": 139.403,
        "_internal_originalTime": 1689838314000
    },
    {
        "time": 1689838315000,
        "value": 139.396,
        "_internal_originalTime": 1689838315000
    },
    {
        "time": 1689838316000,
        "value": 139.396,
        "_internal_originalTime": 1689838316000
    },
    {
        "time": 1689838318000,
        "value": 139.394,
        "_internal_originalTime": 1689838318000
    },
    {
        "time": 1689838319000,
        "value": 139.395,
        "_internal_originalTime": 1689838319000
    },
    {
        "time": 1689838321000,
        "value": 139.38,
        "_internal_originalTime": 1689838321000
    },
    {
        "time": 1689838322000,
        "value": 139.398,
        "_internal_originalTime": 1689838322000
    },
    {
        "time": 1689838323000,
        "value": 139.397,
        "_internal_originalTime": 1689838323000
    },
    {
        "time": 1689838326000,
        "value": 139.394,
        "_internal_originalTime": 1689838326000
    },
    {
        "time": 1689838327000,
        "value": 139.387,
        "_internal_originalTime": 1689838327000
    },
    {
        "time": 1689838328000,
        "value": 139.39,
        "_internal_originalTime": 1689838328000
    },
    {
        "time": 1689838329000,
        "value": 139.392,
        "_internal_originalTime": 1689838329000
    },
    {
        "time": 1689838330000,
        "value": 139.396,
        "_internal_originalTime": 1689838330000
    },
    {
        "time": 1689838331000,
        "value": 139.392,
        "_internal_originalTime": 1689838331000
    },
    {
        "time": 1689838332000,
        "value": 139.396,
        "_internal_originalTime": 1689838332000
    },
    {
        "time": 1689838333000,
        "value": 139.386,
        "_internal_originalTime": 1689838333000
    },
    {
        "time": 1689838334000,
        "value": 139.387,
        "_internal_originalTime": 1689838334000
    },
    {
        "time": 1689838335000,
        "value": 139.389,
        "_internal_originalTime": 1689838335000
    },
    {
        "time": 1689838336000,
        "value": 139.388,
        "_internal_originalTime": 1689838336000
    },
    {
        "time": 1689838338000,
        "value": 139.387,
        "_internal_originalTime": 1689838338000
    },
    {
        "time": 1689838339000,
        "value": 139.388,
        "_internal_originalTime": 1689838339000
    },
    {
        "time": 1689838340000,
        "value": 139.39,
        "_internal_originalTime": 1689838340000
    },
    {
        "time": 1689838342000,
        "value": 139.394,
        "_internal_originalTime": 1689838342000
    },
    {
        "time": 1689838343000,
        "value": 139.396,
        "_internal_originalTime": 1689838343000
    },
    {
        "time": 1689838344000,
        "value": 139.395,
        "_internal_originalTime": 1689838344000
    },
    {
        "time": 1689838346000,
        "value": 139.396,
        "_internal_originalTime": 1689838346000
    },
    {
        "time": 1689838347000,
        "value": 139.388,
        "_internal_originalTime": 1689838347000
    },
    {
        "time": 1689838348000,
        "value": 139.396,
        "_internal_originalTime": 1689838348000
    },
    {
        "time": 1689838349000,
        "value": 139.392,
        "_internal_originalTime": 1689838349000
    },
    {
        "time": 1689838350000,
        "value": 139.385,
        "_internal_originalTime": 1689838350000
    },
    {
        "time": 1689838351000,
        "value": 139.384,
        "_internal_originalTime": 1689838351000
    },
    {
        "time": 1689838352000,
        "value": 139.385,
        "_internal_originalTime": 1689838352000
    },
    {
        "time": 1689838354000,
        "value": 139.39,
        "_internal_originalTime": 1689838354000
    },
    {
        "time": 1689838355000,
        "value": 139.386,
        "_internal_originalTime": 1689838355000
    },
    {
        "time": 1689838356000,
        "value": 139.384,
        "_internal_originalTime": 1689838356000
    },
    {
        "time": 1689838357000,
        "value": 139.389,
        "_internal_originalTime": 1689838357000
    },
    {
        "time": 1689838359000,
        "value": 139.388,
        "_internal_originalTime": 1689838359000
    },
    {
        "time": 1689838360000,
        "value": 139.383,
        "_internal_originalTime": 1689838360000
    },
    {
        "time": 1689838361000,
        "value": 139.388,
        "_internal_originalTime": 1689838361000
    },
    {
        "time": 1689838362000,
        "value": 139.392,
        "_internal_originalTime": 1689838362000
    },
    {
        "time": 1689838364000,
        "value": 139.392,
        "_internal_originalTime": 1689838364000
    },
    {
        "time": 1689838365000,
        "value": 139.387,
        "_internal_originalTime": 1689838365000
    },
    {
        "time": 1689838366000,
        "value": 139.387,
        "_internal_originalTime": 1689838366000
    },
    {
        "time": 1689838368000,
        "value": 139.388,
        "_internal_originalTime": 1689838368000
    },
    {
        "time": 1689838369000,
        "value": 139.393,
        "_internal_originalTime": 1689838369000
    },
    {
        "time": 1689838370000,
        "value": 139.397,
        "_internal_originalTime": 1689838370000
    },
    {
        "time": 1689838371000,
        "value": 139.397,
        "_internal_originalTime": 1689838371000
    },
    {
        "time": 1689838372000,
        "value": 139.396,
        "_internal_originalTime": 1689838372000
    },
    {
        "time": 1689838373000,
        "value": 139.397,
        "_internal_originalTime": 1689838373000
    },
    {
        "time": 1689838374000,
        "value": 139.4,
        "_internal_originalTime": 1689838374000
    },
    {
        "time": 1689838375000,
        "value": 139.401,
        "_internal_originalTime": 1689838375000
    },
    {
        "time": 1689838376000,
        "value": 139.406,
        "_internal_originalTime": 1689838376000
    },
    {
        "time": 1689838378000,
        "value": 139.402,
        "_internal_originalTime": 1689838378000
    },
    {
        "time": 1689838379000,
        "value": 139.403,
        "_internal_originalTime": 1689838379000
    },
    {
        "time": 1689838380000,
        "value": 139.409,
        "_internal_originalTime": 1689838380000
    },
    {
        "time": 1689838381000,
        "value": 139.402,
        "_internal_originalTime": 1689838381000
    },
    {
        "time": 1689838382000,
        "value": 139.403,
        "_internal_originalTime": 1689838382000
    },
    {
        "time": 1689838383000,
        "value": 139.393,
        "_internal_originalTime": 1689838383000
    },
    {
        "time": 1689838384000,
        "value": 139.397,
        "_internal_originalTime": 1689838384000
    },
    {
        "time": 1689838385000,
        "value": 139.397,
        "_internal_originalTime": 1689838385000
    },
    {
        "time": 1689838386000,
        "value": 139.393,
        "_internal_originalTime": 1689838386000
    },
    {
        "time": 1689838387000,
        "value": 139.392,
        "_internal_originalTime": 1689838387000
    },
    {
        "time": 1689838389000,
        "value": 139.396,
        "_internal_originalTime": 1689838389000
    },
    {
        "time": 1689838390000,
        "value": 139.396,
        "_internal_originalTime": 1689838390000
    },
    {
        "time": 1689838391000,
        "value": 139.396,
        "_internal_originalTime": 1689838391000
    },
    {
        "time": 1689838392000,
        "value": 139.401,
        "_internal_originalTime": 1689838392000
    },
    {
        "time": 1689838393000,
        "value": 139.405,
        "_internal_originalTime": 1689838393000
    },
    {
        "time": 1689838394000,
        "value": 139.403,
        "_internal_originalTime": 1689838394000
    },
    {
        "time": 1689838395000,
        "value": 139.4,
        "_internal_originalTime": 1689838395000
    },
    {
        "time": 1689838396000,
        "value": 139.401,
        "_internal_originalTime": 1689838396000
    },
    {
        "time": 1689838398000,
        "value": 139.399,
        "_internal_originalTime": 1689838398000
    },
    {
        "time": 1689838399000,
        "value": 139.405,
        "_internal_originalTime": 1689838399000
    },
    {
        "time": 1689838400000,
        "value": 139.402,
        "_internal_originalTime": 1689838400000
    },
    {
        "time": 1689838401000,
        "value": 139.407,
        "_internal_originalTime": 1689838401000
    },
    {
        "time": 1689838404000,
        "value": 139.401,
        "_internal_originalTime": 1689838404000
    },
    {
        "time": 1689838405000,
        "value": 139.397,
        "_internal_originalTime": 1689838405000
    },
    {
        "time": 1689838407000,
        "value": 139.401,
        "_internal_originalTime": 1689838407000
    },
    {
        "time": 1689838408000,
        "value": 139.396,
        "_internal_originalTime": 1689838408000
    },
    {
        "time": 1689838409000,
        "value": 139.388,
        "_internal_originalTime": 1689838409000
    },
    {
        "time": 1689838410000,
        "value": 139.393,
        "_internal_originalTime": 1689838410000
    },
    {
        "time": 1689838411000,
        "value": 139.389,
        "_internal_originalTime": 1689838411000
    },
    {
        "time": 1689838413000,
        "value": 139.396,
        "_internal_originalTime": 1689838413000
    },
    {
        "time": 1689838415000,
        "value": 139.394,
        "_internal_originalTime": 1689838415000
    },
    {
        "time": 1689838416000,
        "value": 139.39,
        "_internal_originalTime": 1689838416000
    },
    {
        "time": 1689838417000,
        "value": 139.388,
        "_internal_originalTime": 1689838417000
    },
    {
        "time": 1689838418000,
        "value": 139.396,
        "_internal_originalTime": 1689838418000
    },
    {
        "time": 1689838419000,
        "value": 139.402,
        "_internal_originalTime": 1689838419000
    },
    {
        "time": 1689838420000,
        "value": 139.402,
        "_internal_originalTime": 1689838420000
    },
    {
        "time": 1689838421000,
        "value": 139.404,
        "_internal_originalTime": 1689838421000
    },
    {
        "time": 1689838423000,
        "value": 139.404,
        "_internal_originalTime": 1689838423000
    },
    {
        "time": 1689838425000,
        "value": 139.403,
        "_internal_originalTime": 1689838425000
    },
    {
        "time": 1689838426000,
        "value": 139.392,
        "_internal_originalTime": 1689838426000
    },
    {
        "time": 1689838427000,
        "value": 139.396,
        "_internal_originalTime": 1689838427000
    },
    {
        "time": 1689838429000,
        "value": 139.395,
        "_internal_originalTime": 1689838429000
    },
    {
        "time": 1689838430000,
        "value": 139.392,
        "_internal_originalTime": 1689838430000
    },
    {
        "time": 1689838431000,
        "value": 139.394,
        "_internal_originalTime": 1689838431000
    },
    {
        "time": 1689838432000,
        "value": 139.389,
        "_internal_originalTime": 1689838432000
    },
    {
        "time": 1689838433000,
        "value": 139.396,
        "_internal_originalTime": 1689838433000
    },
    {
        "time": 1689838434000,
        "value": 139.396,
        "_internal_originalTime": 1689838434000
    },
    {
        "time": 1689838435000,
        "value": 139.396,
        "_internal_originalTime": 1689838435000
    },
    {
        "time": 1689838436000,
        "value": 139.396,
        "_internal_originalTime": 1689838436000
    },
    {
        "time": 1689838439000,
        "value": 139.395,
        "_internal_originalTime": 1689838439000
    },
    {
        "time": 1689838440000,
        "value": 139.395,
        "_internal_originalTime": 1689838440000
    },
    {
        "time": 1689838441000,
        "value": 139.392,
        "_internal_originalTime": 1689838441000
    },
    {
        "time": 1689838442000,
        "value": 139.393,
        "_internal_originalTime": 1689838442000
    },
    {
        "time": 1689838443000,
        "value": 139.395,
        "_internal_originalTime": 1689838443000
    },
    {
        "time": 1689838444000,
        "value": 139.397,
        "_internal_originalTime": 1689838444000
    },
    {
        "time": 1689838446000,
        "value": 139.391,
        "_internal_originalTime": 1689838446000
    },
    {
        "time": 1689838447000,
        "value": 139.392,
        "_internal_originalTime": 1689838447000
    },
    {
        "time": 1689838448000,
        "value": 139.396,
        "_internal_originalTime": 1689838448000
    },
    {
        "time": 1689838449000,
        "value": 139.39,
        "_internal_originalTime": 1689838449000
    },
    {
        "time": 1689838451000,
        "value": 139.391,
        "_internal_originalTime": 1689838451000
    },
    {
        "time": 1689838452000,
        "value": 139.387,
        "_internal_originalTime": 1689838452000
    },
    {
        "time": 1689838453000,
        "value": 139.392,
        "_internal_originalTime": 1689838453000
    },
    {
        "time": 1689838455000,
        "value": 139.381,
        "_internal_originalTime": 1689838455000
    },
    {
        "time": 1689838456000,
        "value": 139.385,
        "_internal_originalTime": 1689838456000
    },
    {
        "time": 1689838457000,
        "value": 139.377,
        "_internal_originalTime": 1689838457000
    },
    {
        "time": 1689838459000,
        "value": 139.384,
        "_internal_originalTime": 1689838459000
    },
    {
        "time": 1689838460000,
        "value": 139.386,
        "_internal_originalTime": 1689838460000
    },
    {
        "time": 1689838461000,
        "value": 139.386,
        "_internal_originalTime": 1689838461000
    },
    {
        "time": 1689838462000,
        "value": 139.38,
        "_internal_originalTime": 1689838462000
    },
    {
        "time": 1689838463000,
        "value": 139.386,
        "_internal_originalTime": 1689838463000
    },
    {
        "time": 1689838464000,
        "value": 139.385,
        "_internal_originalTime": 1689838464000
    },
    {
        "time": 1689838465000,
        "value": 139.381,
        "_internal_originalTime": 1689838465000
    },
    {
        "time": 1689838466000,
        "value": 139.382,
        "_internal_originalTime": 1689838466000
    },
    {
        "time": 1689838467000,
        "value": 139.379,
        "_internal_originalTime": 1689838467000
    },
    {
        "time": 1689838468000,
        "value": 139.38,
        "_internal_originalTime": 1689838468000
    },
    {
        "time": 1689838470000,
        "value": 139.383,
        "_internal_originalTime": 1689838470000
    },
    {
        "time": 1689838471000,
        "value": 139.384,
        "_internal_originalTime": 1689838471000
    },
    {
        "time": 1689838472000,
        "value": 139.376,
        "_internal_originalTime": 1689838472000
    },
    {
        "time": 1689838473000,
        "value": 139.379,
        "_internal_originalTime": 1689838473000
    },
    {
        "time": 1689838475000,
        "value": 139.376,
        "_internal_originalTime": 1689838475000
    },
    {
        "time": 1689838476000,
        "value": 139.374,
        "_internal_originalTime": 1689838476000
    },
    {
        "time": 1689838477000,
        "value": 139.377,
        "_internal_originalTime": 1689838477000
    },
    {
        "time": 1689838478000,
        "value": 139.373,
        "_internal_originalTime": 1689838478000
    },
    {
        "time": 1689838479000,
        "value": 139.367,
        "_internal_originalTime": 1689838479000
    },
    {
        "time": 1689838480000,
        "value": 139.363,
        "_internal_originalTime": 1689838480000
    },
    {
        "time": 1689838481000,
        "value": 139.368,
        "_internal_originalTime": 1689838481000
    },
    {
        "time": 1689838483000,
        "value": 139.374,
        "_internal_originalTime": 1689838483000
    },
    {
        "time": 1689838484000,
        "value": 139.38,
        "_internal_originalTime": 1689838484000
    },
    {
        "time": 1689838485000,
        "value": 139.38,
        "_internal_originalTime": 1689838485000
    },
    {
        "time": 1689838487000,
        "value": 139.379,
        "_internal_originalTime": 1689838487000
    },
    {
        "time": 1689838488000,
        "value": 139.39,
        "_internal_originalTime": 1689838488000
    },
    {
        "time": 1689838489000,
        "value": 139.383,
        "_internal_originalTime": 1689838489000
    },
    {
        "time": 1689838490000,
        "value": 139.387,
        "_internal_originalTime": 1689838490000
    },
    {
        "time": 1689838491000,
        "value": 139.389,
        "_internal_originalTime": 1689838491000
    },
    {
        "time": 1689838492000,
        "value": 139.39,
        "_internal_originalTime": 1689838492000
    },
    {
        "time": 1689838494000,
        "value": 139.387,
        "_internal_originalTime": 1689838494000
    },
    {
        "time": 1689838495000,
        "value": 139.39,
        "_internal_originalTime": 1689838495000
    },
    {
        "time": 1689838497000,
        "value": 139.384,
        "_internal_originalTime": 1689838497000
    },
    {
        "time": 1689838499000,
        "value": 139.387,
        "_internal_originalTime": 1689838499000
    },
    {
        "time": 1689838500000,
        "value": 139.385,
        "_internal_originalTime": 1689838500000
    },
    {
        "time": 1689838501000,
        "value": 139.376,
        "_internal_originalTime": 1689838501000
    },
    {
        "time": 1689838502000,
        "value": 139.38,
        "_internal_originalTime": 1689838502000
    },
    {
        "time": 1689838503000,
        "value": 139.377,
        "_internal_originalTime": 1689838503000
    },
    {
        "time": 1689838504000,
        "value": 139.378,
        "_internal_originalTime": 1689838504000
    },
    {
        "time": 1689838506000,
        "value": 139.372,
        "_internal_originalTime": 1689838506000
    },
    {
        "time": 1689838507000,
        "value": 139.372,
        "_internal_originalTime": 1689838507000
    },
    {
        "time": 1689838509000,
        "value": 139.377,
        "_internal_originalTime": 1689838509000
    },
    {
        "time": 1689838511000,
        "value": 139.372,
        "_internal_originalTime": 1689838511000
    },
    {
        "time": 1689838512000,
        "value": 139.372,
        "_internal_originalTime": 1689838512000
    },
    {
        "time": 1689838513000,
        "value": 139.373,
        "_internal_originalTime": 1689838513000
    },
    {
        "time": 1689838515000,
        "value": 139.376,
        "_internal_originalTime": 1689838515000
    },
    {
        "time": 1689838516000,
        "value": 139.369,
        "_internal_originalTime": 1689838516000
    },
    {
        "time": 1689838517000,
        "value": 139.373,
        "_internal_originalTime": 1689838517000
    },
    {
        "time": 1689838518000,
        "value": 139.379,
        "_internal_originalTime": 1689838518000
    },
    {
        "time": 1689838519000,
        "value": 139.372,
        "_internal_originalTime": 1689838519000
    },
    {
        "time": 1689838520000,
        "value": 139.378,
        "_internal_originalTime": 1689838520000
    },
    {
        "time": 1689838521000,
        "value": 139.383,
        "_internal_originalTime": 1689838521000
    },
    {
        "time": 1689838522000,
        "value": 139.383,
        "_internal_originalTime": 1689838522000
    },
    {
        "time": 1689838524000,
        "value": 139.392,
        "_internal_originalTime": 1689838524000
    },
    {
        "time": 1689838525000,
        "value": 139.397,
        "_internal_originalTime": 1689838525000
    },
    {
        "time": 1689838526000,
        "value": 139.393,
        "_internal_originalTime": 1689838526000
    },
    {
        "time": 1689838527000,
        "value": 139.397,
        "_internal_originalTime": 1689838527000
    },
    {
        "time": 1689838528000,
        "value": 139.395,
        "_internal_originalTime": 1689838528000
    },
    {
        "time": 1689838530000,
        "value": 139.394,
        "_internal_originalTime": 1689838530000
    },
    {
        "time": 1689838532000,
        "value": 139.397,
        "_internal_originalTime": 1689838532000
    },
    {
        "time": 1689838534000,
        "value": 139.39,
        "_internal_originalTime": 1689838534000
    },
    {
        "time": 1689838535000,
        "value": 139.395,
        "_internal_originalTime": 1689838535000
    },
    {
        "time": 1689838536000,
        "value": 139.39,
        "_internal_originalTime": 1689838536000
    },
    {
        "time": 1689838537000,
        "value": 139.393,
        "_internal_originalTime": 1689838537000
    },
    {
        "time": 1689838538000,
        "value": 139.39,
        "_internal_originalTime": 1689838538000
    },
    {
        "time": 1689838539000,
        "value": 139.395,
        "_internal_originalTime": 1689838539000
    },
    {
        "time": 1689838540000,
        "value": 139.396,
        "_internal_originalTime": 1689838540000
    },
    {
        "time": 1689838541000,
        "value": 139.394,
        "_internal_originalTime": 1689838541000
    },
    {
        "time": 1689838543000,
        "value": 139.393,
        "_internal_originalTime": 1689838543000
    },
    {
        "time": 1689838544000,
        "value": 139.392,
        "_internal_originalTime": 1689838544000
    },
    {
        "time": 1689838545000,
        "value": 139.393,
        "_internal_originalTime": 1689838545000
    },
    {
        "time": 1689838546000,
        "value": 139.39,
        "_internal_originalTime": 1689838546000
    },
    {
        "time": 1689838547000,
        "value": 139.391,
        "_internal_originalTime": 1689838547000
    },
    {
        "time": 1689838548000,
        "value": 139.391,
        "_internal_originalTime": 1689838548000
    },
    {
        "time": 1689838549000,
        "value": 139.389,
        "_internal_originalTime": 1689838549000
    },
    {
        "time": 1689838550000,
        "value": 139.39,
        "_internal_originalTime": 1689838550000
    },
    {
        "time": 1689838551000,
        "value": 139.389,
        "_internal_originalTime": 1689838551000
    },
    {
        "time": 1689838553000,
        "value": 139.388,
        "_internal_originalTime": 1689838553000
    },
    {
        "time": 1689838554000,
        "value": 139.372,
        "_internal_originalTime": 1689838554000
    },
    {
        "time": 1689838556000,
        "value": 139.374,
        "_internal_originalTime": 1689838556000
    },
    {
        "time": 1689838557000,
        "value": 139.374,
        "_internal_originalTime": 1689838557000
    },
    {
        "time": 1689838559000,
        "value": 139.37,
        "_internal_originalTime": 1689838559000
    },
    {
        "time": 1689838560000,
        "value": 139.37,
        "_internal_originalTime": 1689838560000
    },
    {
        "time": 1689838562000,
        "value": 139.374,
        "_internal_originalTime": 1689838562000
    },
    {
        "time": 1689838563000,
        "value": 139.374,
        "_internal_originalTime": 1689838563000
    },
    {
        "time": 1689838564000,
        "value": 139.371,
        "_internal_originalTime": 1689838564000
    },
    {
        "time": 1689838565000,
        "value": 139.376,
        "_internal_originalTime": 1689838565000
    },
    {
        "time": 1689838566000,
        "value": 139.375,
        "_internal_originalTime": 1689838566000
    },
    {
        "time": 1689838567000,
        "value": 139.375,
        "_internal_originalTime": 1689838567000
    },
    {
        "time": 1689838568000,
        "value": 139.371,
        "_internal_originalTime": 1689838568000
    },
    {
        "time": 1689838569000,
        "value": 139.37,
        "_internal_originalTime": 1689838569000
    },
    {
        "time": 1689838570000,
        "value": 139.373,
        "_internal_originalTime": 1689838570000
    },
    {
        "time": 1689838572000,
        "value": 139.369,
        "_internal_originalTime": 1689838572000
    },
    {
        "time": 1689838573000,
        "value": 139.372,
        "_internal_originalTime": 1689838573000
    },
    {
        "time": 1689838575000,
        "value": 139.37,
        "_internal_originalTime": 1689838575000
    },
    {
        "time": 1689838576000,
        "value": 139.373,
        "_internal_originalTime": 1689838576000
    },
    {
        "time": 1689838577000,
        "value": 139.375,
        "_internal_originalTime": 1689838577000
    },
    {
        "time": 1689838578000,
        "value": 139.368,
        "_internal_originalTime": 1689838578000
    },
    {
        "time": 1689838581000,
        "value": 139.375,
        "_internal_originalTime": 1689838581000
    },
    {
        "time": 1689838583000,
        "value": 139.387,
        "_internal_originalTime": 1689838583000
    },
    {
        "time": 1689838584000,
        "value": 139.393,
        "_internal_originalTime": 1689838584000
    },
    {
        "time": 1689838586000,
        "value": 139.398,
        "_internal_originalTime": 1689838586000
    },
    {
        "time": 1689838587000,
        "value": 139.397,
        "_internal_originalTime": 1689838587000
    },
    {
        "time": 1689838588000,
        "value": 139.397,
        "_internal_originalTime": 1689838588000
    },
    {
        "time": 1689838590000,
        "value": 139.398,
        "_internal_originalTime": 1689838590000
    },
    {
        "time": 1689838591000,
        "value": 139.398,
        "_internal_originalTime": 1689838591000
    },
    {
        "time": 1689838592000,
        "value": 139.403,
        "_internal_originalTime": 1689838592000
    },
    {
        "time": 1689838593000,
        "value": 139.398,
        "_internal_originalTime": 1689838593000
    },
    {
        "time": 1689838595000,
        "value": 139.394,
        "_internal_originalTime": 1689838595000
    },
    {
        "time": 1689838596000,
        "value": 139.386,
        "_internal_originalTime": 1689838596000
    },
    {
        "time": 1689838597000,
        "value": 139.387,
        "_internal_originalTime": 1689838597000
    },
    {
        "time": 1689838598000,
        "value": 139.386,
        "_internal_originalTime": 1689838598000
    },
    {
        "time": 1689838599000,
        "value": 139.386,
        "_internal_originalTime": 1689838599000
    },
    {
        "time": 1689838600000,
        "value": 139.387,
        "_internal_originalTime": 1689838600000
    },
    {
        "time": 1689838602000,
        "value": 139.387,
        "_internal_originalTime": 1689838602000
    },
    {
        "time": 1689838603000,
        "value": 139.383,
        "_internal_originalTime": 1689838603000
    },
    {
        "time": 1689838604000,
        "value": 139.381,
        "_internal_originalTime": 1689838604000
    },
    {
        "time": 1689838605000,
        "value": 139.391,
        "_internal_originalTime": 1689838605000
    },
    {
        "time": 1689838606000,
        "value": 139.388,
        "_internal_originalTime": 1689838606000
    },
    {
        "time": 1689838607000,
        "value": 139.392,
        "_internal_originalTime": 1689838607000
    },
    {
        "time": 1689838608000,
        "value": 139.391,
        "_internal_originalTime": 1689838608000
    },
    {
        "time": 1689838610000,
        "value": 139.391,
        "_internal_originalTime": 1689838610000
    },
    {
        "time": 1689838611000,
        "value": 139.388,
        "_internal_originalTime": 1689838611000
    },
    {
        "time": 1689838612000,
        "value": 139.384,
        "_internal_originalTime": 1689838612000
    },
    {
        "time": 1689838613000,
        "value": 139.387,
        "_internal_originalTime": 1689838613000
    },
    {
        "time": 1689838615000,
        "value": 139.386,
        "_internal_originalTime": 1689838615000
    },
    {
        "time": 1689838617000,
        "value": 139.387,
        "_internal_originalTime": 1689838617000
    },
    {
        "time": 1689838618000,
        "value": 139.394,
        "_internal_originalTime": 1689838618000
    },
    {
        "time": 1689838620000,
        "value": 139.394,
        "_internal_originalTime": 1689838620000
    },
    {
        "time": 1689838622000,
        "value": 139.396,
        "_internal_originalTime": 1689838622000
    },
    {
        "time": 1689838623000,
        "value": 139.412,
        "_internal_originalTime": 1689838623000
    },
    {
        "time": 1689838625000,
        "value": 139.413,
        "_internal_originalTime": 1689838625000
    },
    {
        "time": 1689838626000,
        "value": 139.417,
        "_internal_originalTime": 1689838626000
    },
    {
        "time": 1689838627000,
        "value": 139.418,
        "_internal_originalTime": 1689838627000
    },
    {
        "time": 1689838628000,
        "value": 139.42,
        "_internal_originalTime": 1689838628000
    },
    {
        "time": 1689838629000,
        "value": 139.423,
        "_internal_originalTime": 1689838629000
    },
    {
        "time": 1689838630000,
        "value": 139.423,
        "_internal_originalTime": 1689838630000
    },
    {
        "time": 1689838631000,
        "value": 139.428,
        "_internal_originalTime": 1689838631000
    },
    {
        "time": 1689838633000,
        "value": 139.43,
        "_internal_originalTime": 1689838633000
    },
    {
        "time": 1689838634000,
        "value": 139.426,
        "_internal_originalTime": 1689838634000
    },
    {
        "time": 1689838635000,
        "value": 139.423,
        "_internal_originalTime": 1689838635000
    },
    {
        "time": 1689838637000,
        "value": 139.428,
        "_internal_originalTime": 1689838637000
    },
    {
        "time": 1689838638000,
        "value": 139.432,
        "_internal_originalTime": 1689838638000
    },
    {
        "time": 1689838639000,
        "value": 139.433,
        "_internal_originalTime": 1689838639000
    },
    {
        "time": 1689838640000,
        "value": 139.433,
        "_internal_originalTime": 1689838640000
    },
    {
        "time": 1689838641000,
        "value": 139.435,
        "_internal_originalTime": 1689838641000
    },
    {
        "time": 1689838643000,
        "value": 139.432,
        "_internal_originalTime": 1689838643000
    },
    {
        "time": 1689838644000,
        "value": 139.437,
        "_internal_originalTime": 1689838644000
    },
    {
        "time": 1689838645000,
        "value": 139.432,
        "_internal_originalTime": 1689838645000
    },
    {
        "time": 1689838647000,
        "value": 139.442,
        "_internal_originalTime": 1689838647000
    },
    {
        "time": 1689838648000,
        "value": 139.442,
        "_internal_originalTime": 1689838648000
    },
    {
        "time": 1689838650000,
        "value": 139.445,
        "_internal_originalTime": 1689838650000
    },
    {
        "time": 1689838651000,
        "value": 139.443,
        "_internal_originalTime": 1689838651000
    },
    {
        "time": 1689838652000,
        "value": 139.443,
        "_internal_originalTime": 1689838652000
    },
    {
        "time": 1689838653000,
        "value": 139.447,
        "_internal_originalTime": 1689838653000
    },
    {
        "time": 1689838654000,
        "value": 139.445,
        "_internal_originalTime": 1689838654000
    },
    {
        "time": 1689838655000,
        "value": 139.445,
        "_internal_originalTime": 1689838655000
    },
    {
        "time": 1689838657000,
        "value": 139.445,
        "_internal_originalTime": 1689838657000
    },
    {
        "time": 1689838658000,
        "value": 139.448,
        "_internal_originalTime": 1689838658000
    },
    {
        "time": 1689838659000,
        "value": 139.454,
        "_internal_originalTime": 1689838659000
    },
    {
        "time": 1689838660000,
        "value": 139.458,
        "_internal_originalTime": 1689838660000
    },
    {
        "time": 1689838661000,
        "value": 139.461,
        "_internal_originalTime": 1689838661000
    },
    {
        "time": 1689838665000,
        "value": 139.463,
        "_internal_originalTime": 1689838665000
    },
    {
        "time": 1689838666000,
        "value": 139.454,
        "_internal_originalTime": 1689838666000
    },
    {
        "time": 1689838668000,
        "value": 139.459,
        "_internal_originalTime": 1689838668000
    },
    {
        "time": 1689838669000,
        "value": 139.462,
        "_internal_originalTime": 1689838669000
    },
    {
        "time": 1689838670000,
        "value": 139.467,
        "_internal_originalTime": 1689838670000
    },
    {
        "time": 1689838671000,
        "value": 139.469,
        "_internal_originalTime": 1689838671000
    },
    {
        "time": 1689838672000,
        "value": 139.468,
        "_internal_originalTime": 1689838672000
    },
    {
        "time": 1689838674000,
        "value": 139.47,
        "_internal_originalTime": 1689838674000
    },
    {
        "time": 1689838675000,
        "value": 139.471,
        "_internal_originalTime": 1689838675000
    },
    {
        "time": 1689838676000,
        "value": 139.476,
        "_internal_originalTime": 1689838676000
    },
    {
        "time": 1689838677000,
        "value": 139.485,
        "_internal_originalTime": 1689838677000
    },
    {
        "time": 1689838679000,
        "value": 139.492,
        "_internal_originalTime": 1689838679000
    },
    {
        "time": 1689838680000,
        "value": 139.496,
        "_internal_originalTime": 1689838680000
    },
    {
        "time": 1689838681000,
        "value": 139.487,
        "_internal_originalTime": 1689838681000
    },
    {
        "time": 1689838682000,
        "value": 139.484,
        "_internal_originalTime": 1689838682000
    },
    {
        "time": 1689838683000,
        "value": 139.48,
        "_internal_originalTime": 1689838683000
    },
    {
        "time": 1689838684000,
        "value": 139.483,
        "_internal_originalTime": 1689838684000
    },
    {
        "time": 1689838685000,
        "value": 139.486,
        "_internal_originalTime": 1689838685000
    },
    {
        "time": 1689838686000,
        "value": 139.483,
        "_internal_originalTime": 1689838686000
    },
    {
        "time": 1689838688000,
        "value": 139.487,
        "_internal_originalTime": 1689838688000
    },
    {
        "time": 1689838689000,
        "value": 139.485,
        "_internal_originalTime": 1689838689000
    },
    {
        "time": 1689838690000,
        "value": 139.488,
        "_internal_originalTime": 1689838690000
    },
    {
        "time": 1689838691000,
        "value": 139.488,
        "_internal_originalTime": 1689838691000
    },
    {
        "time": 1689838692000,
        "value": 139.486,
        "_internal_originalTime": 1689838692000
    },
    {
        "time": 1689838693000,
        "value": 139.494,
        "_internal_originalTime": 1689838693000
    },
    {
        "time": 1689838695000,
        "value": 139.495,
        "_internal_originalTime": 1689838695000
    },
    {
        "time": 1689838697000,
        "value": 139.495,
        "_internal_originalTime": 1689838697000
    },
    {
        "time": 1689838698000,
        "value": 139.499,
        "_internal_originalTime": 1689838698000
    },
    {
        "time": 1689838699000,
        "value": 139.501,
        "_internal_originalTime": 1689838699000
    },
    {
        "time": 1689838701000,
        "value": 139.5,
        "_internal_originalTime": 1689838701000
    },
    {
        "time": 1689838702000,
        "value": 139.504,
        "_internal_originalTime": 1689838702000
    },
    {
        "time": 1689838703000,
        "value": 139.504,
        "_internal_originalTime": 1689838703000
    },
    {
        "time": 1689838704000,
        "value": 139.512,
        "_internal_originalTime": 1689838704000
    },
    {
        "time": 1689838706000,
        "value": 139.515,
        "_internal_originalTime": 1689838706000
    },
    {
        "time": 1689838707000,
        "value": 139.507,
        "_internal_originalTime": 1689838707000
    },
    {
        "time": 1689838708000,
        "value": 139.509,
        "_internal_originalTime": 1689838708000
    },
    {
        "time": 1689838710000,
        "value": 139.503,
        "_internal_originalTime": 1689838710000
    },
    {
        "time": 1689838711000,
        "value": 139.503,
        "_internal_originalTime": 1689838711000
    },
    {
        "time": 1689838712000,
        "value": 139.504,
        "_internal_originalTime": 1689838712000
    },
    {
        "time": 1689838714000,
        "value": 139.5,
        "_internal_originalTime": 1689838714000
    },
    {
        "time": 1689838715000,
        "value": 139.5,
        "_internal_originalTime": 1689838715000
    },
    {
        "time": 1689838716000,
        "value": 139.501,
        "_internal_originalTime": 1689838716000
    },
    {
        "time": 1689838717000,
        "value": 139.497,
        "_internal_originalTime": 1689838717000
    },
    {
        "time": 1689838718000,
        "value": 139.496,
        "_internal_originalTime": 1689838718000
    },
    {
        "time": 1689838720000,
        "value": 139.5,
        "_internal_originalTime": 1689838720000
    },
    {
        "time": 1689838721000,
        "value": 139.501,
        "_internal_originalTime": 1689838721000
    },
    {
        "time": 1689838723000,
        "value": 139.506,
        "_internal_originalTime": 1689838723000
    },
    {
        "time": 1689838725000,
        "value": 139.506,
        "_internal_originalTime": 1689838725000
    },
    {
        "time": 1689838727000,
        "value": 139.506,
        "_internal_originalTime": 1689838727000
    },
    {
        "time": 1689838728000,
        "value": 139.501,
        "_internal_originalTime": 1689838728000
    },
    {
        "time": 1689838729000,
        "value": 139.501,
        "_internal_originalTime": 1689838729000
    },
    {
        "time": 1689838730000,
        "value": 139.497,
        "_internal_originalTime": 1689838730000
    },
    {
        "time": 1689838731000,
        "value": 139.496,
        "_internal_originalTime": 1689838731000
    },
    {
        "time": 1689838733000,
        "value": 139.494,
        "_internal_originalTime": 1689838733000
    },
    {
        "time": 1689838735000,
        "value": 139.496,
        "_internal_originalTime": 1689838735000
    },
    {
        "time": 1689838736000,
        "value": 139.498,
        "_internal_originalTime": 1689838736000
    },
    {
        "time": 1689838737000,
        "value": 139.503,
        "_internal_originalTime": 1689838737000
    },
    {
        "time": 1689838738000,
        "value": 139.499,
        "_internal_originalTime": 1689838738000
    },
    {
        "time": 1689838739000,
        "value": 139.503,
        "_internal_originalTime": 1689838739000
    },
    {
        "time": 1689838740000,
        "value": 139.498,
        "_internal_originalTime": 1689838740000
    },
    {
        "time": 1689838741000,
        "value": 139.504,
        "_internal_originalTime": 1689838741000
    },
    {
        "time": 1689838742000,
        "value": 139.504,
        "_internal_originalTime": 1689838742000
    },
    {
        "time": 1689838743000,
        "value": 139.505,
        "_internal_originalTime": 1689838743000
    },
    {
        "time": 1689838744000,
        "value": 139.512,
        "_internal_originalTime": 1689838744000
    },
    {
        "time": 1689838745000,
        "value": 139.515,
        "_internal_originalTime": 1689838745000
    },
    {
        "time": 1689838746000,
        "value": 139.512,
        "_internal_originalTime": 1689838746000
    },
    {
        "time": 1689838747000,
        "value": 139.515,
        "_internal_originalTime": 1689838747000
    },
    {
        "time": 1689838748000,
        "value": 139.522,
        "_internal_originalTime": 1689838748000
    },
    {
        "time": 1689838749000,
        "value": 139.529,
        "_internal_originalTime": 1689838749000
    },
    {
        "time": 1689838750000,
        "value": 139.523,
        "_internal_originalTime": 1689838750000
    },
    {
        "time": 1689838752000,
        "value": 139.522,
        "_internal_originalTime": 1689838752000
    },
    {
        "time": 1689838753000,
        "value": 139.522,
        "_internal_originalTime": 1689838753000
    },
    {
        "time": 1689838754000,
        "value": 139.521,
        "_internal_originalTime": 1689838754000
    },
    {
        "time": 1689838755000,
        "value": 139.518,
        "_internal_originalTime": 1689838755000
    },
    {
        "time": 1689838757000,
        "value": 139.523,
        "_internal_originalTime": 1689838757000
    },
    {
        "time": 1689838758000,
        "value": 139.524,
        "_internal_originalTime": 1689838758000
    },
    {
        "time": 1689838759000,
        "value": 139.523,
        "_internal_originalTime": 1689838759000
    },
    {
        "time": 1689838760000,
        "value": 139.523,
        "_internal_originalTime": 1689838760000
    },
    {
        "time": 1689838761000,
        "value": 139.518,
        "_internal_originalTime": 1689838761000
    },
    {
        "time": 1689838762000,
        "value": 139.518,
        "_internal_originalTime": 1689838762000
    },
    {
        "time": 1689838763000,
        "value": 139.524,
        "_internal_originalTime": 1689838763000
    },
    {
        "time": 1689838764000,
        "value": 139.519,
        "_internal_originalTime": 1689838764000
    },
    {
        "time": 1689838766000,
        "value": 139.519,
        "_internal_originalTime": 1689838766000
    },
    {
        "time": 1689838767000,
        "value": 139.521,
        "_internal_originalTime": 1689838767000
    },
    {
        "time": 1689838768000,
        "value": 139.522,
        "_internal_originalTime": 1689838768000
    },
    {
        "time": 1689838770000,
        "value": 139.52,
        "_internal_originalTime": 1689838770000
    },
    {
        "time": 1689838771000,
        "value": 139.523,
        "_internal_originalTime": 1689838771000
    },
    {
        "time": 1689838772000,
        "value": 139.53,
        "_internal_originalTime": 1689838772000
    },
    {
        "time": 1689838773000,
        "value": 139.527,
        "_internal_originalTime": 1689838773000
    },
    {
        "time": 1689838774000,
        "value": 139.532,
        "_internal_originalTime": 1689838774000
    },
    {
        "time": 1689838775000,
        "value": 139.527,
        "_internal_originalTime": 1689838775000
    },
    {
        "time": 1689838776000,
        "value": 139.532,
        "_internal_originalTime": 1689838776000
    },
    {
        "time": 1689838777000,
        "value": 139.533,
        "_internal_originalTime": 1689838777000
    },
    {
        "time": 1689838779000,
        "value": 139.533,
        "_internal_originalTime": 1689838779000
    },
    {
        "time": 1689838780000,
        "value": 139.532,
        "_internal_originalTime": 1689838780000
    },
    {
        "time": 1689838781000,
        "value": 139.531,
        "_internal_originalTime": 1689838781000
    },
    {
        "time": 1689838782000,
        "value": 139.528,
        "_internal_originalTime": 1689838782000
    },
    {
        "time": 1689838783000,
        "value": 139.534,
        "_internal_originalTime": 1689838783000
    },
    {
        "time": 1689838785000,
        "value": 139.538,
        "_internal_originalTime": 1689838785000
    },
    {
        "time": 1689838786000,
        "value": 139.542,
        "_internal_originalTime": 1689838786000
    },
    {
        "time": 1689838787000,
        "value": 139.543,
        "_internal_originalTime": 1689838787000
    },
    {
        "time": 1689838788000,
        "value": 139.539,
        "_internal_originalTime": 1689838788000
    },
    {
        "time": 1689838790000,
        "value": 139.542,
        "_internal_originalTime": 1689838790000
    },
    {
        "time": 1689838792000,
        "value": 139.539,
        "_internal_originalTime": 1689838792000
    },
    {
        "time": 1689838793000,
        "value": 139.542,
        "_internal_originalTime": 1689838793000
    },
    {
        "time": 1689838794000,
        "value": 139.542,
        "_internal_originalTime": 1689838794000
    },
    {
        "time": 1689838795000,
        "value": 139.542,
        "_internal_originalTime": 1689838795000
    },
    {
        "time": 1689838796000,
        "value": 139.541,
        "_internal_originalTime": 1689838796000
    },
    {
        "time": 1689838798000,
        "value": 139.538,
        "_internal_originalTime": 1689838798000
    },
    {
        "time": 1689838799000,
        "value": 139.541,
        "_internal_originalTime": 1689838799000
    },
    {
        "time": 1689838800000,
        "value": 139.539,
        "_internal_originalTime": 1689838800000
    },
    {
        "time": 1689838802000,
        "value": 139.54,
        "_internal_originalTime": 1689838802000
    },
    {
        "time": 1689838803000,
        "value": 139.536,
        "_internal_originalTime": 1689838803000
    },
    {
        "time": 1689838804000,
        "value": 139.545,
        "_internal_originalTime": 1689838804000
    },
    {
        "time": 1689838805000,
        "value": 139.547,
        "_internal_originalTime": 1689838805000
    },
    {
        "time": 1689838806000,
        "value": 139.549,
        "_internal_originalTime": 1689838806000
    },
    {
        "time": 1689838807000,
        "value": 139.549,
        "_internal_originalTime": 1689838807000
    },
    {
        "time": 1689838808000,
        "value": 139.543,
        "_internal_originalTime": 1689838808000
    },
    {
        "time": 1689838809000,
        "value": 139.548,
        "_internal_originalTime": 1689838809000
    },
    {
        "time": 1689838811000,
        "value": 139.547,
        "_internal_originalTime": 1689838811000
    },
    {
        "time": 1689838812000,
        "value": 139.55,
        "_internal_originalTime": 1689838812000
    },
    {
        "time": 1689838813000,
        "value": 139.549,
        "_internal_originalTime": 1689838813000
    },
    {
        "time": 1689838815000,
        "value": 139.552,
        "_internal_originalTime": 1689838815000
    },
    {
        "time": 1689838816000,
        "value": 139.559,
        "_internal_originalTime": 1689838816000
    },
    {
        "time": 1689838817000,
        "value": 139.559,
        "_internal_originalTime": 1689838817000
    },
    {
        "time": 1689838819000,
        "value": 139.558,
        "_internal_originalTime": 1689838819000
    },
    {
        "time": 1689838820000,
        "value": 139.559,
        "_internal_originalTime": 1689838820000
    },
    {
        "time": 1689838821000,
        "value": 139.559,
        "_internal_originalTime": 1689838821000
    },
    {
        "time": 1689838823000,
        "value": 139.553,
        "_internal_originalTime": 1689838823000
    },
    {
        "time": 1689838824000,
        "value": 139.55,
        "_internal_originalTime": 1689838824000
    },
    {
        "time": 1689838825000,
        "value": 139.548,
        "_internal_originalTime": 1689838825000
    },
    {
        "time": 1689838826000,
        "value": 139.55,
        "_internal_originalTime": 1689838826000
    },
    {
        "time": 1689838827000,
        "value": 139.551,
        "_internal_originalTime": 1689838827000
    },
    {
        "time": 1689838828000,
        "value": 139.55,
        "_internal_originalTime": 1689838828000
    },
    {
        "time": 1689838831000,
        "value": 139.548,
        "_internal_originalTime": 1689838831000
    },
    {
        "time": 1689838832000,
        "value": 139.552,
        "_internal_originalTime": 1689838832000
    },
    {
        "time": 1689838833000,
        "value": 139.546,
        "_internal_originalTime": 1689838833000
    },
    {
        "time": 1689838834000,
        "value": 139.551,
        "_internal_originalTime": 1689838834000
    },
    {
        "time": 1689838835000,
        "value": 139.558,
        "_internal_originalTime": 1689838835000
    },
    {
        "time": 1689838836000,
        "value": 139.561,
        "_internal_originalTime": 1689838836000
    },
    {
        "time": 1689838837000,
        "value": 139.56,
        "_internal_originalTime": 1689838837000
    },
    {
        "time": 1689838839000,
        "value": 139.554,
        "_internal_originalTime": 1689838839000
    },
    {
        "time": 1689838840000,
        "value": 139.56,
        "_internal_originalTime": 1689838840000
    },
    {
        "time": 1689838841000,
        "value": 139.559,
        "_internal_originalTime": 1689838841000
    },
    {
        "time": 1689838843000,
        "value": 139.56,
        "_internal_originalTime": 1689838843000
    },
    {
        "time": 1689838844000,
        "value": 139.561,
        "_internal_originalTime": 1689838844000
    },
    {
        "time": 1689838845000,
        "value": 139.56,
        "_internal_originalTime": 1689838845000
    },
    {
        "time": 1689838846000,
        "value": 139.56,
        "_internal_originalTime": 1689838846000
    },
    {
        "time": 1689838847000,
        "value": 139.555,
        "_internal_originalTime": 1689838847000
    },
    {
        "time": 1689838849000,
        "value": 139.546,
        "_internal_originalTime": 1689838849000
    },
    {
        "time": 1689838850000,
        "value": 139.549,
        "_internal_originalTime": 1689838850000
    },
    {
        "time": 1689838851000,
        "value": 139.544,
        "_internal_originalTime": 1689838851000
    },
    {
        "time": 1689838853000,
        "value": 139.545,
        "_internal_originalTime": 1689838853000
    },
    {
        "time": 1689838855000,
        "value": 139.544,
        "_internal_originalTime": 1689838855000
    },
    {
        "time": 1689838856000,
        "value": 139.544,
        "_internal_originalTime": 1689838856000
    },
    {
        "time": 1689838858000,
        "value": 139.544,
        "_internal_originalTime": 1689838858000
    },
    {
        "time": 1689838859000,
        "value": 139.548,
        "_internal_originalTime": 1689838859000
    },
    {
        "time": 1689838860000,
        "value": 139.545,
        "_internal_originalTime": 1689838860000
    },
    {
        "time": 1689838861000,
        "value": 139.542,
        "_internal_originalTime": 1689838861000
    },
    {
        "time": 1689838863000,
        "value": 139.54,
        "_internal_originalTime": 1689838863000
    },
    {
        "time": 1689838864000,
        "value": 139.539,
        "_internal_originalTime": 1689838864000
    },
    {
        "time": 1689838865000,
        "value": 139.534,
        "_internal_originalTime": 1689838865000
    },
    {
        "time": 1689838867000,
        "value": 139.533,
        "_internal_originalTime": 1689838867000
    },
    {
        "time": 1689838868000,
        "value": 139.534,
        "_internal_originalTime": 1689838868000
    },
    {
        "time": 1689838869000,
        "value": 139.528,
        "_internal_originalTime": 1689838869000
    },
    {
        "time": 1689838870000,
        "value": 139.529,
        "_internal_originalTime": 1689838870000
    },
    {
        "time": 1689838872000,
        "value": 139.521,
        "_internal_originalTime": 1689838872000
    },
    {
        "time": 1689838873000,
        "value": 139.528,
        "_internal_originalTime": 1689838873000
    },
    {
        "time": 1689838874000,
        "value": 139.532,
        "_internal_originalTime": 1689838874000
    },
    {
        "time": 1689838875000,
        "value": 139.539,
        "_internal_originalTime": 1689838875000
    },
    {
        "time": 1689838876000,
        "value": 139.546,
        "_internal_originalTime": 1689838876000
    },
    {
        "time": 1689838877000,
        "value": 139.539,
        "_internal_originalTime": 1689838877000
    },
    {
        "time": 1689838878000,
        "value": 139.54,
        "_internal_originalTime": 1689838878000
    },
    {
        "time": 1689838879000,
        "value": 139.538,
        "_internal_originalTime": 1689838879000
    },
    {
        "time": 1689838880000,
        "value": 139.533,
        "_internal_originalTime": 1689838880000
    },
    {
        "time": 1689838881000,
        "value": 139.538,
        "_internal_originalTime": 1689838881000
    },
    {
        "time": 1689838883000,
        "value": 139.544,
        "_internal_originalTime": 1689838883000
    },
    {
        "time": 1689838884000,
        "value": 139.54,
        "_internal_originalTime": 1689838884000
    },
    {
        "time": 1689838885000,
        "value": 139.543,
        "_internal_originalTime": 1689838885000
    },
    {
        "time": 1689838886000,
        "value": 139.538,
        "_internal_originalTime": 1689838886000
    },
    {
        "time": 1689838887000,
        "value": 139.54,
        "_internal_originalTime": 1689838887000
    },
    {
        "time": 1689838888000,
        "value": 139.546,
        "_internal_originalTime": 1689838888000
    },
    {
        "time": 1689838889000,
        "value": 139.552,
        "_internal_originalTime": 1689838889000
    },
    {
        "time": 1689838891000,
        "value": 139.554,
        "_internal_originalTime": 1689838891000
    },
    {
        "time": 1689838892000,
        "value": 139.547,
        "_internal_originalTime": 1689838892000
    },
    {
        "time": 1689838893000,
        "value": 139.547,
        "_internal_originalTime": 1689838893000
    },
    {
        "time": 1689838894000,
        "value": 139.548,
        "_internal_originalTime": 1689838894000
    },
    {
        "time": 1689838895000,
        "value": 139.547,
        "_internal_originalTime": 1689838895000
    },
    {
        "time": 1689838897000,
        "value": 139.54,
        "_internal_originalTime": 1689838897000
    },
    {
        "time": 1689838898000,
        "value": 139.538,
        "_internal_originalTime": 1689838898000
    },
    {
        "time": 1689838899000,
        "value": 139.534,
        "_internal_originalTime": 1689838899000
    },
    {
        "time": 1689838900000,
        "value": 139.542,
        "_internal_originalTime": 1689838900000
    },
    {
        "time": 1689838901000,
        "value": 139.549,
        "_internal_originalTime": 1689838901000
    },
    {
        "time": 1689838902000,
        "value": 139.548,
        "_internal_originalTime": 1689838902000
    },
    {
        "time": 1689838903000,
        "value": 139.544,
        "_internal_originalTime": 1689838903000
    },
    {
        "time": 1689838904000,
        "value": 139.548,
        "_internal_originalTime": 1689838904000
    },
    {
        "time": 1689838906000,
        "value": 139.545,
        "_internal_originalTime": 1689838906000
    },
    {
        "time": 1689838908000,
        "value": 139.546,
        "_internal_originalTime": 1689838908000
    },
    {
        "time": 1689838909000,
        "value": 139.548,
        "_internal_originalTime": 1689838909000
    },
    {
        "time": 1689838910000,
        "value": 139.549,
        "_internal_originalTime": 1689838910000
    },
    {
        "time": 1689838912000,
        "value": 139.551,
        "_internal_originalTime": 1689838912000
    },
    {
        "time": 1689838913000,
        "value": 139.551,
        "_internal_originalTime": 1689838913000
    },
    {
        "time": 1689838914000,
        "value": 139.551,
        "_internal_originalTime": 1689838914000
    },
    {
        "time": 1689838915000,
        "value": 139.555,
        "_internal_originalTime": 1689838915000
    },
    {
        "time": 1689838916000,
        "value": 139.552,
        "_internal_originalTime": 1689838916000
    },
    {
        "time": 1689838917000,
        "value": 139.551,
        "_internal_originalTime": 1689838917000
    },
    {
        "time": 1689838918000,
        "value": 139.546,
        "_internal_originalTime": 1689838918000
    },
    {
        "time": 1689838920000,
        "value": 139.553,
        "_internal_originalTime": 1689838920000
    },
    {
        "time": 1689838921000,
        "value": 139.555,
        "_internal_originalTime": 1689838921000
    },
    {
        "time": 1689838922000,
        "value": 139.56,
        "_internal_originalTime": 1689838922000
    },
    {
        "time": 1689838923000,
        "value": 139.559,
        "_internal_originalTime": 1689838923000
    },
    {
        "time": 1689838925000,
        "value": 139.565,
        "_internal_originalTime": 1689838925000
    },
    {
        "time": 1689838926000,
        "value": 139.559,
        "_internal_originalTime": 1689838926000
    },
    {
        "time": 1689838927000,
        "value": 139.552,
        "_internal_originalTime": 1689838927000
    },
    {
        "time": 1689838928000,
        "value": 139.558,
        "_internal_originalTime": 1689838928000
    },
    {
        "time": 1689838929000,
        "value": 139.544,
        "_internal_originalTime": 1689838929000
    },
    {
        "time": 1689838931000,
        "value": 139.543,
        "_internal_originalTime": 1689838931000
    },
    {
        "time": 1689838932000,
        "value": 139.551,
        "_internal_originalTime": 1689838932000
    },
    {
        "time": 1689838933000,
        "value": 139.545,
        "_internal_originalTime": 1689838933000
    },
    {
        "time": 1689838934000,
        "value": 139.539,
        "_internal_originalTime": 1689838934000
    },
    {
        "time": 1689838935000,
        "value": 139.539,
        "_internal_originalTime": 1689838935000
    },
    {
        "time": 1689838936000,
        "value": 139.539,
        "_internal_originalTime": 1689838936000
    },
    {
        "time": 1689838937000,
        "value": 139.537,
        "_internal_originalTime": 1689838937000
    },
    {
        "time": 1689838938000,
        "value": 139.541,
        "_internal_originalTime": 1689838938000
    },
    {
        "time": 1689838939000,
        "value": 139.538,
        "_internal_originalTime": 1689838939000
    },
    {
        "time": 1689838940000,
        "value": 139.534,
        "_internal_originalTime": 1689838940000
    },
    {
        "time": 1689838941000,
        "value": 139.539,
        "_internal_originalTime": 1689838941000
    },
    {
        "time": 1689838942000,
        "value": 139.541,
        "_internal_originalTime": 1689838942000
    },
    {
        "time": 1689838944000,
        "value": 139.54,
        "_internal_originalTime": 1689838944000
    },
    {
        "time": 1689838945000,
        "value": 139.547,
        "_internal_originalTime": 1689838945000
    },
    {
        "time": 1689838946000,
        "value": 139.552,
        "_internal_originalTime": 1689838946000
    },
    {
        "time": 1689838948000,
        "value": 139.557,
        "_internal_originalTime": 1689838948000
    },
    {
        "time": 1689838949000,
        "value": 139.557,
        "_internal_originalTime": 1689838949000
    },
    {
        "time": 1689838950000,
        "value": 139.559,
        "_internal_originalTime": 1689838950000
    },
    {
        "time": 1689838951000,
        "value": 139.557,
        "_internal_originalTime": 1689838951000
    },
    {
        "time": 1689838952000,
        "value": 139.555,
        "_internal_originalTime": 1689838952000
    },
    {
        "time": 1689838953000,
        "value": 139.557,
        "_internal_originalTime": 1689838953000
    },
    {
        "time": 1689838954000,
        "value": 139.554,
        "_internal_originalTime": 1689838954000
    },
    {
        "time": 1689838955000,
        "value": 139.554,
        "_internal_originalTime": 1689838955000
    },
    {
        "time": 1689838957000,
        "value": 139.566,
        "_internal_originalTime": 1689838957000
    },
    {
        "time": 1689838958000,
        "value": 139.572,
        "_internal_originalTime": 1689838958000
    },
    {
        "time": 1689838959000,
        "value": 139.567,
        "_internal_originalTime": 1689838959000
    },
    {
        "time": 1689838960000,
        "value": 139.571,
        "_internal_originalTime": 1689838960000
    },
    {
        "time": 1689838961000,
        "value": 139.574,
        "_internal_originalTime": 1689838961000
    },
    {
        "time": 1689838963000,
        "value": 139.583,
        "_internal_originalTime": 1689838963000
    },
    {
        "time": 1689838964000,
        "value": 139.588,
        "_internal_originalTime": 1689838964000
    },
    {
        "time": 1689838965000,
        "value": 139.585,
        "_internal_originalTime": 1689838965000
    },
    {
        "time": 1689838966000,
        "value": 139.588,
        "_internal_originalTime": 1689838966000
    },
    {
        "time": 1689838967000,
        "value": 139.586,
        "_internal_originalTime": 1689838967000
    },
    {
        "time": 1689838968000,
        "value": 139.596,
        "_internal_originalTime": 1689838968000
    },
    {
        "time": 1689838969000,
        "value": 139.594,
        "_internal_originalTime": 1689838969000
    },
    {
        "time": 1689838971000,
        "value": 139.598,
        "_internal_originalTime": 1689838971000
    },
    {
        "time": 1689838972000,
        "value": 139.603,
        "_internal_originalTime": 1689838972000
    },
    {
        "time": 1689838974000,
        "value": 139.603,
        "_internal_originalTime": 1689838974000
    },
    {
        "time": 1689838975000,
        "value": 139.592,
        "_internal_originalTime": 1689838975000
    },
    {
        "time": 1689838976000,
        "value": 139.589,
        "_internal_originalTime": 1689838976000
    },
    {
        "time": 1689838977000,
        "value": 139.596,
        "_internal_originalTime": 1689838977000
    },
    {
        "time": 1689838978000,
        "value": 139.597,
        "_internal_originalTime": 1689838978000
    },
    {
        "time": 1689838979000,
        "value": 139.592,
        "_internal_originalTime": 1689838979000
    },
    {
        "time": 1689838980000,
        "value": 139.592,
        "_internal_originalTime": 1689838980000
    },
    {
        "time": 1689838981000,
        "value": 139.587,
        "_internal_originalTime": 1689838981000
    },
    {
        "time": 1689838982000,
        "value": 139.592,
        "_internal_originalTime": 1689838982000
    },
    {
        "time": 1689838983000,
        "value": 139.587,
        "_internal_originalTime": 1689838983000
    },
    {
        "time": 1689838984000,
        "value": 139.588,
        "_internal_originalTime": 1689838984000
    },
    {
        "time": 1689838985000,
        "value": 139.586,
        "_internal_originalTime": 1689838985000
    },
    {
        "time": 1689838986000,
        "value": 139.587,
        "_internal_originalTime": 1689838986000
    },
    {
        "time": 1689838987000,
        "value": 139.586,
        "_internal_originalTime": 1689838987000
    },
    {
        "time": 1689838988000,
        "value": 139.586,
        "_internal_originalTime": 1689838988000
    },
    {
        "time": 1689838989000,
        "value": 139.586,
        "_internal_originalTime": 1689838989000
    },
    {
        "time": 1689838990000,
        "value": 139.586,
        "_internal_originalTime": 1689838990000
    },
    {
        "time": 1689838991000,
        "value": 139.583,
        "_internal_originalTime": 1689838991000
    },
    {
        "time": 1689838992000,
        "value": 139.585,
        "_internal_originalTime": 1689838992000
    },
    {
        "time": 1689838993000,
        "value": 139.586,
        "_internal_originalTime": 1689838993000
    },
    {
        "time": 1689838994000,
        "value": 139.587,
        "_internal_originalTime": 1689838994000
    },
    {
        "time": 1689838995000,
        "value": 139.583,
        "_internal_originalTime": 1689838995000
    },
    {
        "time": 1689838996000,
        "value": 139.586,
        "_internal_originalTime": 1689838996000
    },
    {
        "time": 1689838997000,
        "value": 139.586,
        "_internal_originalTime": 1689838997000
    },
    {
        "time": 1689838998000,
        "value": 139.583,
        "_internal_originalTime": 1689838998000
    },
    {
        "time": 1689839000000,
        "value": 139.59,
        "_internal_originalTime": 1689839000000
    },
    {
        "time": 1689839001000,
        "value": 139.59,
        "_internal_originalTime": 1689839001000
    },
    {
        "time": 1689839002000,
        "value": 139.595,
        "_internal_originalTime": 1689839002000
    },
    {
        "time": 1689839003000,
        "value": 139.595,
        "_internal_originalTime": 1689839003000
    },
    {
        "time": 1689839004000,
        "value": 139.596,
        "_internal_originalTime": 1689839004000
    },
    {
        "time": 1689839005000,
        "value": 139.59,
        "_internal_originalTime": 1689839005000
    },
    {
        "time": 1689839006000,
        "value": 139.596,
        "_internal_originalTime": 1689839006000
    },
    {
        "time": 1689839007000,
        "value": 139.596,
        "_internal_originalTime": 1689839007000
    },
    {
        "time": 1689839010000,
        "value": 139.594,
        "_internal_originalTime": 1689839010000
    },
    {
        "time": 1689839012000,
        "value": 139.594,
        "_internal_originalTime": 1689839012000
    },
    {
        "time": 1689839013000,
        "value": 139.593,
        "_internal_originalTime": 1689839013000
    },
    {
        "time": 1689839014000,
        "value": 139.598,
        "_internal_originalTime": 1689839014000
    },
    {
        "time": 1689839015000,
        "value": 139.598,
        "_internal_originalTime": 1689839015000
    },
    {
        "time": 1689839016000,
        "value": 139.597,
        "_internal_originalTime": 1689839016000
    },
    {
        "time": 1689839017000,
        "value": 139.599,
        "_internal_originalTime": 1689839017000
    },
    {
        "time": 1689839019000,
        "value": 139.594,
        "_internal_originalTime": 1689839019000
    },
    {
        "time": 1689839020000,
        "value": 139.594,
        "_internal_originalTime": 1689839020000
    },
    {
        "time": 1689839021000,
        "value": 139.596,
        "_internal_originalTime": 1689839021000
    },
    {
        "time": 1689839022000,
        "value": 139.59,
        "_internal_originalTime": 1689839022000
    },
    {
        "time": 1689839024000,
        "value": 139.598,
        "_internal_originalTime": 1689839024000
    },
    {
        "time": 1689839025000,
        "value": 139.598,
        "_internal_originalTime": 1689839025000
    },
    {
        "time": 1689839026000,
        "value": 139.597,
        "_internal_originalTime": 1689839026000
    },
    {
        "time": 1689839027000,
        "value": 139.592,
        "_internal_originalTime": 1689839027000
    },
    {
        "time": 1689839028000,
        "value": 139.599,
        "_internal_originalTime": 1689839028000
    },
    {
        "time": 1689839029000,
        "value": 139.598,
        "_internal_originalTime": 1689839029000
    },
    {
        "time": 1689839031000,
        "value": 139.597,
        "_internal_originalTime": 1689839031000
    },
    {
        "time": 1689839033000,
        "value": 139.596,
        "_internal_originalTime": 1689839033000
    },
    {
        "time": 1689839034000,
        "value": 139.596,
        "_internal_originalTime": 1689839034000
    },
    {
        "time": 1689839035000,
        "value": 139.582,
        "_internal_originalTime": 1689839035000
    },
    {
        "time": 1689839036000,
        "value": 139.582,
        "_internal_originalTime": 1689839036000
    },
    {
        "time": 1689839037000,
        "value": 139.582,
        "_internal_originalTime": 1689839037000
    },
    {
        "time": 1689839038000,
        "value": 139.58,
        "_internal_originalTime": 1689839038000
    },
    {
        "time": 1689839039000,
        "value": 139.587,
        "_internal_originalTime": 1689839039000
    },
    {
        "time": 1689839040000,
        "value": 139.586,
        "_internal_originalTime": 1689839040000
    },
    {
        "time": 1689839041000,
        "value": 139.587,
        "_internal_originalTime": 1689839041000
    },
    {
        "time": 1689839042000,
        "value": 139.582,
        "_internal_originalTime": 1689839042000
    },
    {
        "time": 1689839043000,
        "value": 139.577,
        "_internal_originalTime": 1689839043000
    },
    {
        "time": 1689839044000,
        "value": 139.578,
        "_internal_originalTime": 1689839044000
    },
    {
        "time": 1689839046000,
        "value": 139.572,
        "_internal_originalTime": 1689839046000
    },
    {
        "time": 1689839047000,
        "value": 139.569,
        "_internal_originalTime": 1689839047000
    },
    {
        "time": 1689839048000,
        "value": 139.575,
        "_internal_originalTime": 1689839048000
    },
    {
        "time": 1689839049000,
        "value": 139.577,
        "_internal_originalTime": 1689839049000
    },
    {
        "time": 1689839050000,
        "value": 139.577,
        "_internal_originalTime": 1689839050000
    },
    {
        "time": 1689839051000,
        "value": 139.57,
        "_internal_originalTime": 1689839051000
    },
    {
        "time": 1689839052000,
        "value": 139.577,
        "_internal_originalTime": 1689839052000
    },
    {
        "time": 1689839054000,
        "value": 139.567,
        "_internal_originalTime": 1689839054000
    },
    {
        "time": 1689839055000,
        "value": 139.57,
        "_internal_originalTime": 1689839055000
    },
    {
        "time": 1689839056000,
        "value": 139.572,
        "_internal_originalTime": 1689839056000
    },
    {
        "time": 1689839057000,
        "value": 139.571,
        "_internal_originalTime": 1689839057000
    },
    {
        "time": 1689839058000,
        "value": 139.572,
        "_internal_originalTime": 1689839058000
    },
    {
        "time": 1689839059000,
        "value": 139.564,
        "_internal_originalTime": 1689839059000
    },
    {
        "time": 1689839060000,
        "value": 139.567,
        "_internal_originalTime": 1689839060000
    },
    {
        "time": 1689839061000,
        "value": 139.568,
        "_internal_originalTime": 1689839061000
    },
    {
        "time": 1689839062000,
        "value": 139.557,
        "_internal_originalTime": 1689839062000
    },
    {
        "time": 1689839063000,
        "value": 139.557,
        "_internal_originalTime": 1689839063000
    },
    {
        "time": 1689839065000,
        "value": 139.555,
        "_internal_originalTime": 1689839065000
    },
    {
        "time": 1689839066000,
        "value": 139.555,
        "_internal_originalTime": 1689839066000
    },
    {
        "time": 1689839067000,
        "value": 139.558,
        "_internal_originalTime": 1689839067000
    },
    {
        "time": 1689839068000,
        "value": 139.562,
        "_internal_originalTime": 1689839068000
    },
    {
        "time": 1689839069000,
        "value": 139.563,
        "_internal_originalTime": 1689839069000
    },
    {
        "time": 1689839070000,
        "value": 139.564,
        "_internal_originalTime": 1689839070000
    },
    {
        "time": 1689839071000,
        "value": 139.566,
        "_internal_originalTime": 1689839071000
    },
    {
        "time": 1689839072000,
        "value": 139.569,
        "_internal_originalTime": 1689839072000
    },
    {
        "time": 1689839073000,
        "value": 139.577,
        "_internal_originalTime": 1689839073000
    },
    {
        "time": 1689839074000,
        "value": 139.577,
        "_internal_originalTime": 1689839074000
    },
    {
        "time": 1689839075000,
        "value": 139.572,
        "_internal_originalTime": 1689839075000
    },
    {
        "time": 1689839077000,
        "value": 139.573,
        "_internal_originalTime": 1689839077000
    },
    {
        "time": 1689839078000,
        "value": 139.577,
        "_internal_originalTime": 1689839078000
    },
    {
        "time": 1689839079000,
        "value": 139.58,
        "_internal_originalTime": 1689839079000
    },
    {
        "time": 1689839080000,
        "value": 139.579,
        "_internal_originalTime": 1689839080000
    },
    {
        "time": 1689839081000,
        "value": 139.587,
        "_internal_originalTime": 1689839081000
    },
    {
        "time": 1689839082000,
        "value": 139.587,
        "_internal_originalTime": 1689839082000
    },
    {
        "time": 1689839083000,
        "value": 139.587,
        "_internal_originalTime": 1689839083000
    },
    {
        "time": 1689839084000,
        "value": 139.587,
        "_internal_originalTime": 1689839084000
    },
    {
        "time": 1689839085000,
        "value": 139.587,
        "_internal_originalTime": 1689839085000
    },
    {
        "time": 1689839086000,
        "value": 139.583,
        "_internal_originalTime": 1689839086000
    },
    {
        "time": 1689839088000,
        "value": 139.591,
        "_internal_originalTime": 1689839088000
    },
    {
        "time": 1689839089000,
        "value": 139.591,
        "_internal_originalTime": 1689839089000
    },
    {
        "time": 1689839090000,
        "value": 139.591,
        "_internal_originalTime": 1689839090000
    },
    {
        "time": 1689839091000,
        "value": 139.596,
        "_internal_originalTime": 1689839091000
    },
    {
        "time": 1689839092000,
        "value": 139.591,
        "_internal_originalTime": 1689839092000
    },
    {
        "time": 1689839093000,
        "value": 139.596,
        "_internal_originalTime": 1689839093000
    },
    {
        "time": 1689839094000,
        "value": 139.593,
        "_internal_originalTime": 1689839094000
    },
    {
        "time": 1689839095000,
        "value": 139.591,
        "_internal_originalTime": 1689839095000
    },
    {
        "time": 1689839097000,
        "value": 139.596,
        "_internal_originalTime": 1689839097000
    },
    {
        "time": 1689839098000,
        "value": 139.596,
        "_internal_originalTime": 1689839098000
    },
    {
        "time": 1689839099000,
        "value": 139.597,
        "_internal_originalTime": 1689839099000
    },
    {
        "time": 1689839100000,
        "value": 139.59,
        "_internal_originalTime": 1689839100000
    },
    {
        "time": 1689839101000,
        "value": 139.59,
        "_internal_originalTime": 1689839101000
    },
    {
        "time": 1689839102000,
        "value": 139.597,
        "_internal_originalTime": 1689839102000
    },
    {
        "time": 1689839104000,
        "value": 139.592,
        "_internal_originalTime": 1689839104000
    },
    {
        "time": 1689839105000,
        "value": 139.592,
        "_internal_originalTime": 1689839105000
    },
    {
        "time": 1689839107000,
        "value": 139.588,
        "_internal_originalTime": 1689839107000
    },
    {
        "time": 1689839108000,
        "value": 139.588,
        "_internal_originalTime": 1689839108000
    },
    {
        "time": 1689839110000,
        "value": 139.587,
        "_internal_originalTime": 1689839110000
    },
    {
        "time": 1689839111000,
        "value": 139.577,
        "_internal_originalTime": 1689839111000
    },
    {
        "time": 1689839112000,
        "value": 139.572,
        "_internal_originalTime": 1689839112000
    },
    {
        "time": 1689839113000,
        "value": 139.577,
        "_internal_originalTime": 1689839113000
    },
    {
        "time": 1689839114000,
        "value": 139.577,
        "_internal_originalTime": 1689839114000
    },
    {
        "time": 1689839115000,
        "value": 139.575,
        "_internal_originalTime": 1689839115000
    },
    {
        "time": 1689839116000,
        "value": 139.573,
        "_internal_originalTime": 1689839116000
    },
    {
        "time": 1689839117000,
        "value": 139.572,
        "_internal_originalTime": 1689839117000
    },
    {
        "time": 1689839118000,
        "value": 139.574,
        "_internal_originalTime": 1689839118000
    },
    {
        "time": 1689839120000,
        "value": 139.577,
        "_internal_originalTime": 1689839120000
    },
    {
        "time": 1689839121000,
        "value": 139.575,
        "_internal_originalTime": 1689839121000
    },
    {
        "time": 1689839122000,
        "value": 139.573,
        "_internal_originalTime": 1689839122000
    },
    {
        "time": 1689839123000,
        "value": 139.57,
        "_internal_originalTime": 1689839123000
    },
    {
        "time": 1689839124000,
        "value": 139.577,
        "_internal_originalTime": 1689839124000
    },
    {
        "time": 1689839125000,
        "value": 139.57,
        "_internal_originalTime": 1689839125000
    },
    {
        "time": 1689839126000,
        "value": 139.586,
        "_internal_originalTime": 1689839126000
    },
    {
        "time": 1689839127000,
        "value": 139.582,
        "_internal_originalTime": 1689839127000
    },
    {
        "time": 1689839128000,
        "value": 139.582,
        "_internal_originalTime": 1689839128000
    },
    {
        "time": 1689839129000,
        "value": 139.583,
        "_internal_originalTime": 1689839129000
    },
    {
        "time": 1689839131000,
        "value": 139.587,
        "_internal_originalTime": 1689839131000
    },
    {
        "time": 1689839132000,
        "value": 139.58,
        "_internal_originalTime": 1689839132000
    },
    {
        "time": 1689839133000,
        "value": 139.587,
        "_internal_originalTime": 1689839133000
    },
    {
        "time": 1689839134000,
        "value": 139.587,
        "_internal_originalTime": 1689839134000
    },
    {
        "time": 1689839136000,
        "value": 139.58,
        "_internal_originalTime": 1689839136000
    },
    {
        "time": 1689839137000,
        "value": 139.581,
        "_internal_originalTime": 1689839137000
    },
    {
        "time": 1689839138000,
        "value": 139.583,
        "_internal_originalTime": 1689839138000
    },
    {
        "time": 1689839140000,
        "value": 139.579,
        "_internal_originalTime": 1689839140000
    },
    {
        "time": 1689839141000,
        "value": 139.577,
        "_internal_originalTime": 1689839141000
    },
    {
        "time": 1689839143000,
        "value": 139.579,
        "_internal_originalTime": 1689839143000
    },
    {
        "time": 1689839145000,
        "value": 139.581,
        "_internal_originalTime": 1689839145000
    },
    {
        "time": 1689839146000,
        "value": 139.582,
        "_internal_originalTime": 1689839146000
    },
    {
        "time": 1689839147000,
        "value": 139.578,
        "_internal_originalTime": 1689839147000
    },
    {
        "time": 1689839148000,
        "value": 139.577,
        "_internal_originalTime": 1689839148000
    },
    {
        "time": 1689839150000,
        "value": 139.578,
        "_internal_originalTime": 1689839150000
    },
    {
        "time": 1689839151000,
        "value": 139.572,
        "_internal_originalTime": 1689839151000
    },
    {
        "time": 1689839152000,
        "value": 139.579,
        "_internal_originalTime": 1689839152000
    },
    {
        "time": 1689839153000,
        "value": 139.575,
        "_internal_originalTime": 1689839153000
    },
    {
        "time": 1689839155000,
        "value": 139.564,
        "_internal_originalTime": 1689839155000
    },
    {
        "time": 1689839156000,
        "value": 139.568,
        "_internal_originalTime": 1689839156000
    },
    {
        "time": 1689839157000,
        "value": 139.568,
        "_internal_originalTime": 1689839157000
    },
    {
        "time": 1689839158000,
        "value": 139.563,
        "_internal_originalTime": 1689839158000
    },
    {
        "time": 1689839160000,
        "value": 139.561,
        "_internal_originalTime": 1689839160000
    },
    {
        "time": 1689839161000,
        "value": 139.554,
        "_internal_originalTime": 1689839161000
    },
    {
        "time": 1689839163000,
        "value": 139.562,
        "_internal_originalTime": 1689839163000
    },
    {
        "time": 1689839164000,
        "value": 139.56,
        "_internal_originalTime": 1689839164000
    },
    {
        "time": 1689839165000,
        "value": 139.561,
        "_internal_originalTime": 1689839165000
    },
    {
        "time": 1689839166000,
        "value": 139.564,
        "_internal_originalTime": 1689839166000
    },
    {
        "time": 1689839167000,
        "value": 139.561,
        "_internal_originalTime": 1689839167000
    },
    {
        "time": 1689839168000,
        "value": 139.562,
        "_internal_originalTime": 1689839168000
    },
    {
        "time": 1689839169000,
        "value": 139.56,
        "_internal_originalTime": 1689839169000
    },
    {
        "time": 1689839171000,
        "value": 139.559,
        "_internal_originalTime": 1689839171000
    },
    {
        "time": 1689839172000,
        "value": 139.557,
        "_internal_originalTime": 1689839172000
    },
    {
        "time": 1689839173000,
        "value": 139.565,
        "_internal_originalTime": 1689839173000
    },
    {
        "time": 1689839175000,
        "value": 139.562,
        "_internal_originalTime": 1689839175000
    },
    {
        "time": 1689839176000,
        "value": 139.571,
        "_internal_originalTime": 1689839176000
    },
    {
        "time": 1689839177000,
        "value": 139.561,
        "_internal_originalTime": 1689839177000
    },
    {
        "time": 1689839178000,
        "value": 139.558,
        "_internal_originalTime": 1689839178000
    },
    {
        "time": 1689839179000,
        "value": 139.557,
        "_internal_originalTime": 1689839179000
    },
    {
        "time": 1689839181000,
        "value": 139.565,
        "_internal_originalTime": 1689839181000
    },
    {
        "time": 1689839183000,
        "value": 139.564,
        "_internal_originalTime": 1689839183000
    },
    {
        "time": 1689839184000,
        "value": 139.564,
        "_internal_originalTime": 1689839184000
    },
    {
        "time": 1689839186000,
        "value": 139.561,
        "_internal_originalTime": 1689839186000
    },
    {
        "time": 1689839187000,
        "value": 139.562,
        "_internal_originalTime": 1689839187000
    },
    {
        "time": 1689839190000,
        "value": 139.562,
        "_internal_originalTime": 1689839190000
    },
    {
        "time": 1689839191000,
        "value": 139.566,
        "_internal_originalTime": 1689839191000
    },
    {
        "time": 1689839192000,
        "value": 139.566,
        "_internal_originalTime": 1689839192000
    },
    {
        "time": 1689839193000,
        "value": 139.569,
        "_internal_originalTime": 1689839193000
    },
    {
        "time": 1689839194000,
        "value": 139.568,
        "_internal_originalTime": 1689839194000
    },
    {
        "time": 1689839196000,
        "value": 139.564,
        "_internal_originalTime": 1689839196000
    },
    {
        "time": 1689839197000,
        "value": 139.564,
        "_internal_originalTime": 1689839197000
    },
    {
        "time": 1689839198000,
        "value": 139.568,
        "_internal_originalTime": 1689839198000
    },
    {
        "time": 1689839199000,
        "value": 139.569,
        "_internal_originalTime": 1689839199000
    },
    {
        "time": 1689839200000,
        "value": 139.57,
        "_internal_originalTime": 1689839200000
    },
    {
        "time": 1689839202000,
        "value": 139.567,
        "_internal_originalTime": 1689839202000
    },
    {
        "time": 1689839203000,
        "value": 139.568,
        "_internal_originalTime": 1689839203000
    },
    {
        "time": 1689839205000,
        "value": 139.564,
        "_internal_originalTime": 1689839205000
    },
    {
        "time": 1689839206000,
        "value": 139.566,
        "_internal_originalTime": 1689839206000
    },
    {
        "time": 1689839207000,
        "value": 139.564,
        "_internal_originalTime": 1689839207000
    },
    {
        "time": 1689839208000,
        "value": 139.565,
        "_internal_originalTime": 1689839208000
    },
    {
        "time": 1689839209000,
        "value": 139.564,
        "_internal_originalTime": 1689839209000
    },
    {
        "time": 1689839210000,
        "value": 139.556,
        "_internal_originalTime": 1689839210000
    },
    {
        "time": 1689839211000,
        "value": 139.557,
        "_internal_originalTime": 1689839211000
    },
    {
        "time": 1689839212000,
        "value": 139.558,
        "_internal_originalTime": 1689839212000
    },
    {
        "time": 1689839214000,
        "value": 139.552,
        "_internal_originalTime": 1689839214000
    },
    {
        "time": 1689839215000,
        "value": 139.554,
        "_internal_originalTime": 1689839215000
    },
    {
        "time": 1689839216000,
        "value": 139.549,
        "_internal_originalTime": 1689839216000
    },
    {
        "time": 1689839219000,
        "value": 139.558,
        "_internal_originalTime": 1689839219000
    },
    {
        "time": 1689839221000,
        "value": 139.559,
        "_internal_originalTime": 1689839221000
    },
    {
        "time": 1689839222000,
        "value": 139.559,
        "_internal_originalTime": 1689839222000
    },
    {
        "time": 1689839224000,
        "value": 139.557,
        "_internal_originalTime": 1689839224000
    },
    {
        "time": 1689839225000,
        "value": 139.552,
        "_internal_originalTime": 1689839225000
    },
    {
        "time": 1689839226000,
        "value": 139.552,
        "_internal_originalTime": 1689839226000
    },
    {
        "time": 1689839227000,
        "value": 139.559,
        "_internal_originalTime": 1689839227000
    },
    {
        "time": 1689839228000,
        "value": 139.562,
        "_internal_originalTime": 1689839228000
    },
    {
        "time": 1689839230000,
        "value": 139.558,
        "_internal_originalTime": 1689839230000
    },
    {
        "time": 1689839231000,
        "value": 139.56,
        "_internal_originalTime": 1689839231000
    },
    {
        "time": 1689839232000,
        "value": 139.563,
        "_internal_originalTime": 1689839232000
    },
    {
        "time": 1689839235000,
        "value": 139.562,
        "_internal_originalTime": 1689839235000
    },
    {
        "time": 1689839237000,
        "value": 139.556,
        "_internal_originalTime": 1689839237000
    },
    {
        "time": 1689839238000,
        "value": 139.559,
        "_internal_originalTime": 1689839238000
    },
    {
        "time": 1689839240000,
        "value": 139.559,
        "_internal_originalTime": 1689839240000
    },
    {
        "time": 1689839242000,
        "value": 139.56,
        "_internal_originalTime": 1689839242000
    },
    {
        "time": 1689839244000,
        "value": 139.559,
        "_internal_originalTime": 1689839244000
    },
    {
        "time": 1689839245000,
        "value": 139.55,
        "_internal_originalTime": 1689839245000
    },
    {
        "time": 1689839246000,
        "value": 139.556,
        "_internal_originalTime": 1689839246000
    },
    {
        "time": 1689839247000,
        "value": 139.559,
        "_internal_originalTime": 1689839247000
    },
    {
        "time": 1689839249000,
        "value": 139.566,
        "_internal_originalTime": 1689839249000
    },
    {
        "time": 1689839250000,
        "value": 139.563,
        "_internal_originalTime": 1689839250000
    },
    {
        "time": 1689839251000,
        "value": 139.563,
        "_internal_originalTime": 1689839251000
    },
    {
        "time": 1689839253000,
        "value": 139.57,
        "_internal_originalTime": 1689839253000
    },
    {
        "time": 1689839254000,
        "value": 139.568,
        "_internal_originalTime": 1689839254000
    },
    {
        "time": 1689839255000,
        "value": 139.567,
        "_internal_originalTime": 1689839255000
    },
    {
        "time": 1689839256000,
        "value": 139.572,
        "_internal_originalTime": 1689839256000
    },
    {
        "time": 1689839257000,
        "value": 139.568,
        "_internal_originalTime": 1689839257000
    },
    {
        "time": 1689839259000,
        "value": 139.566,
        "_internal_originalTime": 1689839259000
    },
    {
        "time": 1689839260000,
        "value": 139.567,
        "_internal_originalTime": 1689839260000
    },
    {
        "time": 1689839261000,
        "value": 139.572,
        "_internal_originalTime": 1689839261000
    },
    {
        "time": 1689839263000,
        "value": 139.568,
        "_internal_originalTime": 1689839263000
    },
    {
        "time": 1689839264000,
        "value": 139.57,
        "_internal_originalTime": 1689839264000
    },
    {
        "time": 1689839265000,
        "value": 139.57,
        "_internal_originalTime": 1689839265000
    },
    {
        "time": 1689839267000,
        "value": 139.573,
        "_internal_originalTime": 1689839267000
    },
    {
        "time": 1689839268000,
        "value": 139.577,
        "_internal_originalTime": 1689839268000
    },
    {
        "time": 1689839269000,
        "value": 139.577,
        "_internal_originalTime": 1689839269000
    },
    {
        "time": 1689839272000,
        "value": 139.573,
        "_internal_originalTime": 1689839272000
    },
    {
        "time": 1689839274000,
        "value": 139.573,
        "_internal_originalTime": 1689839274000
    },
    {
        "time": 1689839275000,
        "value": 139.587,
        "_internal_originalTime": 1689839275000
    },
    {
        "time": 1689839276000,
        "value": 139.585,
        "_internal_originalTime": 1689839276000
    },
    {
        "time": 1689839277000,
        "value": 139.591,
        "_internal_originalTime": 1689839277000
    },
    {
        "time": 1689839279000,
        "value": 139.596,
        "_internal_originalTime": 1689839279000
    },
    {
        "time": 1689839280000,
        "value": 139.591,
        "_internal_originalTime": 1689839280000
    },
    {
        "time": 1689839281000,
        "value": 139.597,
        "_internal_originalTime": 1689839281000
    },
    {
        "time": 1689839282000,
        "value": 139.59,
        "_internal_originalTime": 1689839282000
    },
    {
        "time": 1689839283000,
        "value": 139.592,
        "_internal_originalTime": 1689839283000
    },
    {
        "time": 1689839284000,
        "value": 139.595,
        "_internal_originalTime": 1689839284000
    },
    {
        "time": 1689839285000,
        "value": 139.605,
        "_internal_originalTime": 1689839285000
    },
    {
        "time": 1689839287000,
        "value": 139.606,
        "_internal_originalTime": 1689839287000
    },
    {
        "time": 1689839289000,
        "value": 139.62,
        "_internal_originalTime": 1689839289000
    },
    {
        "time": 1689839290000,
        "value": 139.62,
        "_internal_originalTime": 1689839290000
    },
    {
        "time": 1689839291000,
        "value": 139.628,
        "_internal_originalTime": 1689839291000
    },
    {
        "time": 1689839292000,
        "value": 139.63,
        "_internal_originalTime": 1689839292000
    },
    {
        "time": 1689839293000,
        "value": 139.63,
        "_internal_originalTime": 1689839293000
    },
    {
        "time": 1689839294000,
        "value": 139.634,
        "_internal_originalTime": 1689839294000
    },
    {
        "time": 1689839296000,
        "value": 139.633,
        "_internal_originalTime": 1689839296000
    },
    {
        "time": 1689839297000,
        "value": 139.63,
        "_internal_originalTime": 1689839297000
    },
    {
        "time": 1689839298000,
        "value": 139.646,
        "_internal_originalTime": 1689839298000
    },
    {
        "time": 1689839300000,
        "value": 139.636,
        "_internal_originalTime": 1689839300000
    },
    {
        "time": 1689839301000,
        "value": 139.641,
        "_internal_originalTime": 1689839301000
    },
    {
        "time": 1689839302000,
        "value": 139.644,
        "_internal_originalTime": 1689839302000
    },
    {
        "time": 1689839303000,
        "value": 139.636,
        "_internal_originalTime": 1689839303000
    },
    {
        "time": 1689839304000,
        "value": 139.627,
        "_internal_originalTime": 1689839304000
    },
    {
        "time": 1689839305000,
        "value": 139.622,
        "_internal_originalTime": 1689839305000
    },
    {
        "time": 1689839306000,
        "value": 139.622,
        "_internal_originalTime": 1689839306000
    },
    {
        "time": 1689839307000,
        "value": 139.621,
        "_internal_originalTime": 1689839307000
    },
    {
        "time": 1689839308000,
        "value": 139.626,
        "_internal_originalTime": 1689839308000
    },
    {
        "time": 1689839310000,
        "value": 139.61,
        "_internal_originalTime": 1689839310000
    },
    {
        "time": 1689839315000,
        "value": 139.615,
        "_internal_originalTime": 1689839315000
    },
    {
        "time": 1689839316000,
        "value": 139.616,
        "_internal_originalTime": 1689839316000
    },
    {
        "time": 1689839317000,
        "value": 139.617,
        "_internal_originalTime": 1689839317000
    },
    {
        "time": 1689839319000,
        "value": 139.618,
        "_internal_originalTime": 1689839319000
    },
    {
        "time": 1689839320000,
        "value": 139.614,
        "_internal_originalTime": 1689839320000
    },
    {
        "time": 1689839321000,
        "value": 139.619,
        "_internal_originalTime": 1689839321000
    },
    {
        "time": 1689839322000,
        "value": 139.618,
        "_internal_originalTime": 1689839322000
    },
    {
        "time": 1689839324000,
        "value": 139.615,
        "_internal_originalTime": 1689839324000
    },
    {
        "time": 1689839325000,
        "value": 139.618,
        "_internal_originalTime": 1689839325000
    },
    {
        "time": 1689839326000,
        "value": 139.602,
        "_internal_originalTime": 1689839326000
    },
    {
        "time": 1689839328000,
        "value": 139.608,
        "_internal_originalTime": 1689839328000
    },
    {
        "time": 1689839329000,
        "value": 139.609,
        "_internal_originalTime": 1689839329000
    },
    {
        "time": 1689839331000,
        "value": 139.612,
        "_internal_originalTime": 1689839331000
    },
    {
        "time": 1689839332000,
        "value": 139.612,
        "_internal_originalTime": 1689839332000
    },
    {
        "time": 1689839333000,
        "value": 139.619,
        "_internal_originalTime": 1689839333000
    },
    {
        "time": 1689839334000,
        "value": 139.614,
        "_internal_originalTime": 1689839334000
    },
    {
        "time": 1689839335000,
        "value": 139.616,
        "_internal_originalTime": 1689839335000
    },
    {
        "time": 1689839336000,
        "value": 139.615,
        "_internal_originalTime": 1689839336000
    },
    {
        "time": 1689839337000,
        "value": 139.616,
        "_internal_originalTime": 1689839337000
    },
    {
        "time": 1689839338000,
        "value": 139.612,
        "_internal_originalTime": 1689839338000
    },
    {
        "time": 1689839340000,
        "value": 139.617,
        "_internal_originalTime": 1689839340000
    },
    {
        "time": 1689839341000,
        "value": 139.59,
        "_internal_originalTime": 1689839341000
    },
    {
        "time": 1689839342000,
        "value": 139.604,
        "_internal_originalTime": 1689839342000
    },
    {
        "time": 1689839343000,
        "value": 139.608,
        "_internal_originalTime": 1689839343000
    },
    {
        "time": 1689839344000,
        "value": 139.61,
        "_internal_originalTime": 1689839344000
    },
    {
        "time": 1689839345000,
        "value": 139.611,
        "_internal_originalTime": 1689839345000
    },
    {
        "time": 1689839346000,
        "value": 139.608,
        "_internal_originalTime": 1689839346000
    },
    {
        "time": 1689839348000,
        "value": 139.613,
        "_internal_originalTime": 1689839348000
    },
    {
        "time": 1689839349000,
        "value": 139.604,
        "_internal_originalTime": 1689839349000
    },
    {
        "time": 1689839350000,
        "value": 139.602,
        "_internal_originalTime": 1689839350000
    },
    {
        "time": 1689839351000,
        "value": 139.606,
        "_internal_originalTime": 1689839351000
    },
    {
        "time": 1689839352000,
        "value": 139.605,
        "_internal_originalTime": 1689839352000
    },
    {
        "time": 1689839354000,
        "value": 139.603,
        "_internal_originalTime": 1689839354000
    },
    {
        "time": 1689839355000,
        "value": 139.61,
        "_internal_originalTime": 1689839355000
    },
    {
        "time": 1689839356000,
        "value": 139.615,
        "_internal_originalTime": 1689839356000
    },
    {
        "time": 1689839357000,
        "value": 139.616,
        "_internal_originalTime": 1689839357000
    },
    {
        "time": 1689839358000,
        "value": 139.619,
        "_internal_originalTime": 1689839358000
    },
    {
        "time": 1689839359000,
        "value": 139.623,
        "_internal_originalTime": 1689839359000
    },
    {
        "time": 1689839360000,
        "value": 139.618,
        "_internal_originalTime": 1689839360000
    },
    {
        "time": 1689839361000,
        "value": 139.624,
        "_internal_originalTime": 1689839361000
    },
    {
        "time": 1689839362000,
        "value": 139.616,
        "_internal_originalTime": 1689839362000
    },
    {
        "time": 1689839364000,
        "value": 139.612,
        "_internal_originalTime": 1689839364000
    },
    {
        "time": 1689839365000,
        "value": 139.616,
        "_internal_originalTime": 1689839365000
    },
    {
        "time": 1689839366000,
        "value": 139.612,
        "_internal_originalTime": 1689839366000
    },
    {
        "time": 1689839368000,
        "value": 139.612,
        "_internal_originalTime": 1689839368000
    },
    {
        "time": 1689839369000,
        "value": 139.617,
        "_internal_originalTime": 1689839369000
    },
    {
        "time": 1689839370000,
        "value": 139.612,
        "_internal_originalTime": 1689839370000
    },
    {
        "time": 1689839371000,
        "value": 139.618,
        "_internal_originalTime": 1689839371000
    },
    {
        "time": 1689839372000,
        "value": 139.624,
        "_internal_originalTime": 1689839372000
    },
    {
        "time": 1689839373000,
        "value": 139.624,
        "_internal_originalTime": 1689839373000
    },
    {
        "time": 1689839374000,
        "value": 139.624,
        "_internal_originalTime": 1689839374000
    },
    {
        "time": 1689839377000,
        "value": 139.614,
        "_internal_originalTime": 1689839377000
    },
    {
        "time": 1689839378000,
        "value": 139.615,
        "_internal_originalTime": 1689839378000
    },
    {
        "time": 1689839379000,
        "value": 139.602,
        "_internal_originalTime": 1689839379000
    },
    {
        "time": 1689839380000,
        "value": 139.622,
        "_internal_originalTime": 1689839380000
    },
    {
        "time": 1689839381000,
        "value": 139.622,
        "_internal_originalTime": 1689839381000
    },
    {
        "time": 1689839383000,
        "value": 139.619,
        "_internal_originalTime": 1689839383000
    },
    {
        "time": 1689839384000,
        "value": 139.627,
        "_internal_originalTime": 1689839384000
    },
    {
        "time": 1689839385000,
        "value": 139.631,
        "_internal_originalTime": 1689839385000
    },
    {
        "time": 1689839386000,
        "value": 139.637,
        "_internal_originalTime": 1689839386000
    },
    {
        "time": 1689839387000,
        "value": 139.63,
        "_internal_originalTime": 1689839387000
    },
    {
        "time": 1689839389000,
        "value": 139.636,
        "_internal_originalTime": 1689839389000
    },
    {
        "time": 1689839390000,
        "value": 139.638,
        "_internal_originalTime": 1689839390000
    },
    {
        "time": 1689839391000,
        "value": 139.63,
        "_internal_originalTime": 1689839391000
    },
    {
        "time": 1689839392000,
        "value": 139.628,
        "_internal_originalTime": 1689839392000
    },
    {
        "time": 1689839393000,
        "value": 139.627,
        "_internal_originalTime": 1689839393000
    },
    {
        "time": 1689839395000,
        "value": 139.627,
        "_internal_originalTime": 1689839395000
    },
    {
        "time": 1689839397000,
        "value": 139.626,
        "_internal_originalTime": 1689839397000
    },
    {
        "time": 1689839398000,
        "value": 139.619,
        "_internal_originalTime": 1689839398000
    },
    {
        "time": 1689839399000,
        "value": 139.627,
        "_internal_originalTime": 1689839399000
    },
    {
        "time": 1689839401000,
        "value": 139.629,
        "_internal_originalTime": 1689839401000
    },
    {
        "time": 1689839402000,
        "value": 139.63,
        "_internal_originalTime": 1689839402000
    },
    {
        "time": 1689839403000,
        "value": 139.635,
        "_internal_originalTime": 1689839403000
    },
    {
        "time": 1689839404000,
        "value": 139.629,
        "_internal_originalTime": 1689839404000
    },
    {
        "time": 1689839405000,
        "value": 139.622,
        "_internal_originalTime": 1689839405000
    },
    {
        "time": 1689839406000,
        "value": 139.624,
        "_internal_originalTime": 1689839406000
    },
    {
        "time": 1689839407000,
        "value": 139.622,
        "_internal_originalTime": 1689839407000
    },
    {
        "time": 1689839408000,
        "value": 139.625,
        "_internal_originalTime": 1689839408000
    },
    {
        "time": 1689839409000,
        "value": 139.627,
        "_internal_originalTime": 1689839409000
    },
    {
        "time": 1689839411000,
        "value": 139.629,
        "_internal_originalTime": 1689839411000
    },
    {
        "time": 1689839412000,
        "value": 139.633,
        "_internal_originalTime": 1689839412000
    },
    {
        "time": 1689839413000,
        "value": 139.629,
        "_internal_originalTime": 1689839413000
    },
    {
        "time": 1689839415000,
        "value": 139.638,
        "_internal_originalTime": 1689839415000
    },
    {
        "time": 1689839417000,
        "value": 139.63,
        "_internal_originalTime": 1689839417000
    },
    {
        "time": 1689839418000,
        "value": 139.63,
        "_internal_originalTime": 1689839418000
    },
    {
        "time": 1689839419000,
        "value": 139.636,
        "_internal_originalTime": 1689839419000
    },
    {
        "time": 1689839420000,
        "value": 139.638,
        "_internal_originalTime": 1689839420000
    },
    {
        "time": 1689839421000,
        "value": 139.64,
        "_internal_originalTime": 1689839421000
    },
    {
        "time": 1689839423000,
        "value": 139.658,
        "_internal_originalTime": 1689839423000
    },
    {
        "time": 1689839424000,
        "value": 139.648,
        "_internal_originalTime": 1689839424000
    },
    {
        "time": 1689839425000,
        "value": 139.658,
        "_internal_originalTime": 1689839425000
    },
    {
        "time": 1689839426000,
        "value": 139.66,
        "_internal_originalTime": 1689839426000
    },
    {
        "time": 1689839427000,
        "value": 139.652,
        "_internal_originalTime": 1689839427000
    },
    {
        "time": 1689839428000,
        "value": 139.657,
        "_internal_originalTime": 1689839428000
    },
    {
        "time": 1689839430000,
        "value": 139.658,
        "_internal_originalTime": 1689839430000
    },
    {
        "time": 1689839432000,
        "value": 139.665,
        "_internal_originalTime": 1689839432000
    },
    {
        "time": 1689839433000,
        "value": 139.659,
        "_internal_originalTime": 1689839433000
    },
    {
        "time": 1689839434000,
        "value": 139.657,
        "_internal_originalTime": 1689839434000
    },
    {
        "time": 1689839435000,
        "value": 139.652,
        "_internal_originalTime": 1689839435000
    },
    {
        "time": 1689839436000,
        "value": 139.657,
        "_internal_originalTime": 1689839436000
    },
    {
        "time": 1689839437000,
        "value": 139.652,
        "_internal_originalTime": 1689839437000
    },
    {
        "time": 1689839439000,
        "value": 139.658,
        "_internal_originalTime": 1689839439000
    },
    {
        "time": 1689839441000,
        "value": 139.652,
        "_internal_originalTime": 1689839441000
    },
    {
        "time": 1689839442000,
        "value": 139.661,
        "_internal_originalTime": 1689839442000
    },
    {
        "time": 1689839443000,
        "value": 139.662,
        "_internal_originalTime": 1689839443000
    },
    {
        "time": 1689839445000,
        "value": 139.661,
        "_internal_originalTime": 1689839445000
    },
    {
        "time": 1689839446000,
        "value": 139.652,
        "_internal_originalTime": 1689839446000
    },
    {
        "time": 1689839447000,
        "value": 139.656,
        "_internal_originalTime": 1689839447000
    },
    {
        "time": 1689839448000,
        "value": 139.655,
        "_internal_originalTime": 1689839448000
    },
    {
        "time": 1689839449000,
        "value": 139.655,
        "_internal_originalTime": 1689839449000
    },
    {
        "time": 1689839450000,
        "value": 139.65,
        "_internal_originalTime": 1689839450000
    },
    {
        "time": 1689839451000,
        "value": 139.657,
        "_internal_originalTime": 1689839451000
    },
    {
        "time": 1689839453000,
        "value": 139.658,
        "_internal_originalTime": 1689839453000
    },
    {
        "time": 1689839454000,
        "value": 139.654,
        "_internal_originalTime": 1689839454000
    },
    {
        "time": 1689839456000,
        "value": 139.653,
        "_internal_originalTime": 1689839456000
    },
    {
        "time": 1689839457000,
        "value": 139.651,
        "_internal_originalTime": 1689839457000
    },
    {
        "time": 1689839459000,
        "value": 139.663,
        "_internal_originalTime": 1689839459000
    },
    {
        "time": 1689839460000,
        "value": 139.667,
        "_internal_originalTime": 1689839460000
    },
    {
        "time": 1689839461000,
        "value": 139.64,
        "_internal_originalTime": 1689839461000
    },
    {
        "time": 1689839462000,
        "value": 139.658,
        "_internal_originalTime": 1689839462000
    },
    {
        "time": 1689839463000,
        "value": 139.663,
        "_internal_originalTime": 1689839463000
    },
    {
        "time": 1689839464000,
        "value": 139.656,
        "_internal_originalTime": 1689839464000
    },
    {
        "time": 1689839465000,
        "value": 139.65,
        "_internal_originalTime": 1689839465000
    },
    {
        "time": 1689839466000,
        "value": 139.659,
        "_internal_originalTime": 1689839466000
    },
    {
        "time": 1689839467000,
        "value": 139.661,
        "_internal_originalTime": 1689839467000
    },
    {
        "time": 1689839468000,
        "value": 139.663,
        "_internal_originalTime": 1689839468000
    },
    {
        "time": 1689839470000,
        "value": 139.664,
        "_internal_originalTime": 1689839470000
    },
    {
        "time": 1689839471000,
        "value": 139.66,
        "_internal_originalTime": 1689839471000
    },
    {
        "time": 1689839472000,
        "value": 139.67,
        "_internal_originalTime": 1689839472000
    },
    {
        "time": 1689839473000,
        "value": 139.674,
        "_internal_originalTime": 1689839473000
    },
    {
        "time": 1689839475000,
        "value": 139.665,
        "_internal_originalTime": 1689839475000
    },
    {
        "time": 1689839476000,
        "value": 139.659,
        "_internal_originalTime": 1689839476000
    },
    {
        "time": 1689839478000,
        "value": 139.663,
        "_internal_originalTime": 1689839478000
    },
    {
        "time": 1689839479000,
        "value": 139.65,
        "_internal_originalTime": 1689839479000
    },
    {
        "time": 1689839481000,
        "value": 139.663,
        "_internal_originalTime": 1689839481000
    },
    {
        "time": 1689839482000,
        "value": 139.665,
        "_internal_originalTime": 1689839482000
    },
    {
        "time": 1689839483000,
        "value": 139.665,
        "_internal_originalTime": 1689839483000
    },
    {
        "time": 1689839484000,
        "value": 139.665,
        "_internal_originalTime": 1689839484000
    },
    {
        "time": 1689839486000,
        "value": 139.66,
        "_internal_originalTime": 1689839486000
    },
    {
        "time": 1689839487000,
        "value": 139.663,
        "_internal_originalTime": 1689839487000
    },
    {
        "time": 1689839488000,
        "value": 139.657,
        "_internal_originalTime": 1689839488000
    },
    {
        "time": 1689839489000,
        "value": 139.658,
        "_internal_originalTime": 1689839489000
    },
    {
        "time": 1689839491000,
        "value": 139.661,
        "_internal_originalTime": 1689839491000
    },
    {
        "time": 1689839492000,
        "value": 139.663,
        "_internal_originalTime": 1689839492000
    },
    {
        "time": 1689839493000,
        "value": 139.663,
        "_internal_originalTime": 1689839493000
    },
    {
        "time": 1689839494000,
        "value": 139.656,
        "_internal_originalTime": 1689839494000
    },
    {
        "time": 1689839495000,
        "value": 139.66,
        "_internal_originalTime": 1689839495000
    },
    {
        "time": 1689839497000,
        "value": 139.659,
        "_internal_originalTime": 1689839497000
    },
    {
        "time": 1689839498000,
        "value": 139.653,
        "_internal_originalTime": 1689839498000
    },
    {
        "time": 1689839499000,
        "value": 139.658,
        "_internal_originalTime": 1689839499000
    },
    {
        "time": 1689839500000,
        "value": 139.659,
        "_internal_originalTime": 1689839500000
    },
    {
        "time": 1689839501000,
        "value": 139.658,
        "_internal_originalTime": 1689839501000
    },
    {
        "time": 1689839502000,
        "value": 139.659,
        "_internal_originalTime": 1689839502000
    },
    {
        "time": 1689839503000,
        "value": 139.658,
        "_internal_originalTime": 1689839503000
    },
    {
        "time": 1689839504000,
        "value": 139.658,
        "_internal_originalTime": 1689839504000
    },
    {
        "time": 1689839506000,
        "value": 139.659,
        "_internal_originalTime": 1689839506000
    },
    {
        "time": 1689839508000,
        "value": 139.656,
        "_internal_originalTime": 1689839508000
    },
    {
        "time": 1689839509000,
        "value": 139.653,
        "_internal_originalTime": 1689839509000
    },
    {
        "time": 1689839510000,
        "value": 139.658,
        "_internal_originalTime": 1689839510000
    },
    {
        "time": 1689839511000,
        "value": 139.659,
        "_internal_originalTime": 1689839511000
    },
    {
        "time": 1689839512000,
        "value": 139.663,
        "_internal_originalTime": 1689839512000
    },
    {
        "time": 1689839513000,
        "value": 139.665,
        "_internal_originalTime": 1689839513000
    },
    {
        "time": 1689839514000,
        "value": 139.661,
        "_internal_originalTime": 1689839514000
    },
    {
        "time": 1689839516000,
        "value": 139.667,
        "_internal_originalTime": 1689839516000
    },
    {
        "time": 1689839518000,
        "value": 139.673,
        "_internal_originalTime": 1689839518000
    },
    {
        "time": 1689839519000,
        "value": 139.669,
        "_internal_originalTime": 1689839519000
    },
    {
        "time": 1689839520000,
        "value": 139.668,
        "_internal_originalTime": 1689839520000
    },
    {
        "time": 1689839521000,
        "value": 139.65,
        "_internal_originalTime": 1689839521000
    },
    {
        "time": 1689839522000,
        "value": 139.686,
        "_internal_originalTime": 1689839522000
    },
    {
        "time": 1689839523000,
        "value": 139.678,
        "_internal_originalTime": 1689839523000
    },
    {
        "time": 1689839524000,
        "value": 139.683,
        "_internal_originalTime": 1689839524000
    },
    {
        "time": 1689839525000,
        "value": 139.677,
        "_internal_originalTime": 1689839525000
    },
    {
        "time": 1689839526000,
        "value": 139.679,
        "_internal_originalTime": 1689839526000
    },
    {
        "time": 1689839527000,
        "value": 139.685,
        "_internal_originalTime": 1689839527000
    },
    {
        "time": 1689839529000,
        "value": 139.684,
        "_internal_originalTime": 1689839529000
    },
    {
        "time": 1689839530000,
        "value": 139.677,
        "_internal_originalTime": 1689839530000
    },
    {
        "time": 1689839531000,
        "value": 139.675,
        "_internal_originalTime": 1689839531000
    },
    {
        "time": 1689839532000,
        "value": 139.684,
        "_internal_originalTime": 1689839532000
    },
    {
        "time": 1689839533000,
        "value": 139.679,
        "_internal_originalTime": 1689839533000
    },
    {
        "time": 1689839535000,
        "value": 139.677,
        "_internal_originalTime": 1689839535000
    },
    {
        "time": 1689839536000,
        "value": 139.673,
        "_internal_originalTime": 1689839536000
    },
    {
        "time": 1689839537000,
        "value": 139.667,
        "_internal_originalTime": 1689839537000
    },
    {
        "time": 1689839538000,
        "value": 139.663,
        "_internal_originalTime": 1689839538000
    },
    {
        "time": 1689839540000,
        "value": 139.666,
        "_internal_originalTime": 1689839540000
    },
    {
        "time": 1689839541000,
        "value": 139.66,
        "_internal_originalTime": 1689839541000
    },
    {
        "time": 1689839542000,
        "value": 139.661,
        "_internal_originalTime": 1689839542000
    },
    {
        "time": 1689839543000,
        "value": 139.66,
        "_internal_originalTime": 1689839543000
    },
    {
        "time": 1689839544000,
        "value": 139.656,
        "_internal_originalTime": 1689839544000
    },
    {
        "time": 1689839545000,
        "value": 139.652,
        "_internal_originalTime": 1689839545000
    },
    {
        "time": 1689839546000,
        "value": 139.657,
        "_internal_originalTime": 1689839546000
    },
    {
        "time": 1689839547000,
        "value": 139.65,
        "_internal_originalTime": 1689839547000
    },
    {
        "time": 1689839549000,
        "value": 139.653,
        "_internal_originalTime": 1689839549000
    },
    {
        "time": 1689839550000,
        "value": 139.657,
        "_internal_originalTime": 1689839550000
    },
    {
        "time": 1689839552000,
        "value": 139.656,
        "_internal_originalTime": 1689839552000
    },
    {
        "time": 1689839553000,
        "value": 139.653,
        "_internal_originalTime": 1689839553000
    },
    {
        "time": 1689839555000,
        "value": 139.656,
        "_internal_originalTime": 1689839555000
    },
    {
        "time": 1689839557000,
        "value": 139.656,
        "_internal_originalTime": 1689839557000
    },
    {
        "time": 1689839558000,
        "value": 139.657,
        "_internal_originalTime": 1689839558000
    },
    {
        "time": 1689839559000,
        "value": 139.657,
        "_internal_originalTime": 1689839559000
    },
    {
        "time": 1689839560000,
        "value": 139.656,
        "_internal_originalTime": 1689839560000
    },
    {
        "time": 1689839561000,
        "value": 139.655,
        "_internal_originalTime": 1689839561000
    },
    {
        "time": 1689839563000,
        "value": 139.654,
        "_internal_originalTime": 1689839563000
    },
    {
        "time": 1689839564000,
        "value": 139.649,
        "_internal_originalTime": 1689839564000
    },
    {
        "time": 1689839565000,
        "value": 139.654,
        "_internal_originalTime": 1689839565000
    },
    {
        "time": 1689839566000,
        "value": 139.658,
        "_internal_originalTime": 1689839566000
    },
    {
        "time": 1689839567000,
        "value": 139.655,
        "_internal_originalTime": 1689839567000
    },
    {
        "time": 1689839569000,
        "value": 139.655,
        "_internal_originalTime": 1689839569000
    },
    {
        "time": 1689839571000,
        "value": 139.653,
        "_internal_originalTime": 1689839571000
    },
    {
        "time": 1689839572000,
        "value": 139.65,
        "_internal_originalTime": 1689839572000
    },
    {
        "time": 1689839573000,
        "value": 139.65,
        "_internal_originalTime": 1689839573000
    },
    {
        "time": 1689839574000,
        "value": 139.657,
        "_internal_originalTime": 1689839574000
    },
    {
        "time": 1689839576000,
        "value": 139.65,
        "_internal_originalTime": 1689839576000
    },
    {
        "time": 1689839577000,
        "value": 139.657,
        "_internal_originalTime": 1689839577000
    },
    {
        "time": 1689839578000,
        "value": 139.657,
        "_internal_originalTime": 1689839578000
    },
    {
        "time": 1689839580000,
        "value": 139.639,
        "_internal_originalTime": 1689839580000
    },
    {
        "time": 1689839581000,
        "value": 139.647,
        "_internal_originalTime": 1689839581000
    },
    {
        "time": 1689839582000,
        "value": 139.646,
        "_internal_originalTime": 1689839582000
    },
    {
        "time": 1689839583000,
        "value": 139.636,
        "_internal_originalTime": 1689839583000
    },
    {
        "time": 1689839584000,
        "value": 139.636,
        "_internal_originalTime": 1689839584000
    },
    {
        "time": 1689839585000,
        "value": 139.634,
        "_internal_originalTime": 1689839585000
    },
    {
        "time": 1689839587000,
        "value": 139.631,
        "_internal_originalTime": 1689839587000
    },
    {
        "time": 1689839588000,
        "value": 139.637,
        "_internal_originalTime": 1689839588000
    },
    {
        "time": 1689839589000,
        "value": 139.637,
        "_internal_originalTime": 1689839589000
    },
    {
        "time": 1689839590000,
        "value": 139.636,
        "_internal_originalTime": 1689839590000
    },
    {
        "time": 1689839591000,
        "value": 139.636,
        "_internal_originalTime": 1689839591000
    },
    {
        "time": 1689839592000,
        "value": 139.636,
        "_internal_originalTime": 1689839592000
    },
    {
        "time": 1689839593000,
        "value": 139.637,
        "_internal_originalTime": 1689839593000
    },
    {
        "time": 1689839595000,
        "value": 139.636,
        "_internal_originalTime": 1689839595000
    },
    {
        "time": 1689839596000,
        "value": 139.637,
        "_internal_originalTime": 1689839596000
    },
    {
        "time": 1689839597000,
        "value": 139.643,
        "_internal_originalTime": 1689839597000
    },
    {
        "time": 1689839599000,
        "value": 139.643,
        "_internal_originalTime": 1689839599000
    },
    {
        "time": 1689839601000,
        "value": 139.636,
        "_internal_originalTime": 1689839601000
    },
    {
        "time": 1689839602000,
        "value": 139.637,
        "_internal_originalTime": 1689839602000
    },
    {
        "time": 1689839603000,
        "value": 139.634,
        "_internal_originalTime": 1689839603000
    },
    {
        "time": 1689839605000,
        "value": 139.636,
        "_internal_originalTime": 1689839605000
    },
    {
        "time": 1689839606000,
        "value": 139.637,
        "_internal_originalTime": 1689839606000
    },
    {
        "time": 1689839607000,
        "value": 139.637,
        "_internal_originalTime": 1689839607000
    },
    {
        "time": 1689839610000,
        "value": 139.636,
        "_internal_originalTime": 1689839610000
    },
    {
        "time": 1689839612000,
        "value": 139.635,
        "_internal_originalTime": 1689839612000
    },
    {
        "time": 1689839613000,
        "value": 139.634,
        "_internal_originalTime": 1689839613000
    },
    {
        "time": 1689839614000,
        "value": 139.625,
        "_internal_originalTime": 1689839614000
    },
    {
        "time": 1689839615000,
        "value": 139.63,
        "_internal_originalTime": 1689839615000
    },
    {
        "time": 1689839617000,
        "value": 139.627,
        "_internal_originalTime": 1689839617000
    },
    {
        "time": 1689839619000,
        "value": 139.624,
        "_internal_originalTime": 1689839619000
    },
    {
        "time": 1689839620000,
        "value": 139.619,
        "_internal_originalTime": 1689839620000
    },
    {
        "time": 1689839621000,
        "value": 139.612,
        "_internal_originalTime": 1689839621000
    },
    {
        "time": 1689839622000,
        "value": 139.616,
        "_internal_originalTime": 1689839622000
    },
    {
        "time": 1689839623000,
        "value": 139.617,
        "_internal_originalTime": 1689839623000
    },
    {
        "time": 1689839625000,
        "value": 139.619,
        "_internal_originalTime": 1689839625000
    },
    {
        "time": 1689839627000,
        "value": 139.616,
        "_internal_originalTime": 1689839627000
    },
    {
        "time": 1689839629000,
        "value": 139.609,
        "_internal_originalTime": 1689839629000
    },
    {
        "time": 1689839631000,
        "value": 139.615,
        "_internal_originalTime": 1689839631000
    },
    {
        "time": 1689839632000,
        "value": 139.606,
        "_internal_originalTime": 1689839632000
    },
    {
        "time": 1689839633000,
        "value": 139.607,
        "_internal_originalTime": 1689839633000
    },
    {
        "time": 1689839635000,
        "value": 139.612,
        "_internal_originalTime": 1689839635000
    },
    {
        "time": 1689839636000,
        "value": 139.612,
        "_internal_originalTime": 1689839636000
    },
    {
        "time": 1689839637000,
        "value": 139.609,
        "_internal_originalTime": 1689839637000
    },
    {
        "time": 1689839639000,
        "value": 139.61,
        "_internal_originalTime": 1689839639000
    },
    {
        "time": 1689839640000,
        "value": 139.615,
        "_internal_originalTime": 1689839640000
    },
    {
        "time": 1689839642000,
        "value": 139.614,
        "_internal_originalTime": 1689839642000
    },
    {
        "time": 1689839643000,
        "value": 139.621,
        "_internal_originalTime": 1689839643000
    },
    {
        "time": 1689839646000,
        "value": 139.621,
        "_internal_originalTime": 1689839646000
    },
    {
        "time": 1689839647000,
        "value": 139.619,
        "_internal_originalTime": 1689839647000
    },
    {
        "time": 1689839649000,
        "value": 139.62,
        "_internal_originalTime": 1689839649000
    },
    {
        "time": 1689839650000,
        "value": 139.617,
        "_internal_originalTime": 1689839650000
    },
    {
        "time": 1689839651000,
        "value": 139.619,
        "_internal_originalTime": 1689839651000
    },
    {
        "time": 1689839652000,
        "value": 139.621,
        "_internal_originalTime": 1689839652000
    },
    {
        "time": 1689839654000,
        "value": 139.612,
        "_internal_originalTime": 1689839654000
    },
    {
        "time": 1689839655000,
        "value": 139.604,
        "_internal_originalTime": 1689839655000
    },
    {
        "time": 1689839656000,
        "value": 139.609,
        "_internal_originalTime": 1689839656000
    },
    {
        "time": 1689839658000,
        "value": 139.605,
        "_internal_originalTime": 1689839658000
    },
    {
        "time": 1689839659000,
        "value": 139.61,
        "_internal_originalTime": 1689839659000
    },
    {
        "time": 1689839661000,
        "value": 139.602,
        "_internal_originalTime": 1689839661000
    },
    {
        "time": 1689839663000,
        "value": 139.602,
        "_internal_originalTime": 1689839663000
    },
    {
        "time": 1689839664000,
        "value": 139.609,
        "_internal_originalTime": 1689839664000
    },
    {
        "time": 1689839665000,
        "value": 139.608,
        "_internal_originalTime": 1689839665000
    },
    {
        "time": 1689839666000,
        "value": 139.608,
        "_internal_originalTime": 1689839666000
    },
    {
        "time": 1689839667000,
        "value": 139.606,
        "_internal_originalTime": 1689839667000
    },
    {
        "time": 1689839668000,
        "value": 139.61,
        "_internal_originalTime": 1689839668000
    },
    {
        "time": 1689839670000,
        "value": 139.606,
        "_internal_originalTime": 1689839670000
    },
    {
        "time": 1689839671000,
        "value": 139.603,
        "_internal_originalTime": 1689839671000
    },
    {
        "time": 1689839673000,
        "value": 139.6,
        "_internal_originalTime": 1689839673000
    },
    {
        "time": 1689839674000,
        "value": 139.61,
        "_internal_originalTime": 1689839674000
    },
    {
        "time": 1689839675000,
        "value": 139.612,
        "_internal_originalTime": 1689839675000
    },
    {
        "time": 1689839677000,
        "value": 139.606,
        "_internal_originalTime": 1689839677000
    },
    {
        "time": 1689839678000,
        "value": 139.606,
        "_internal_originalTime": 1689839678000
    },
    {
        "time": 1689839679000,
        "value": 139.604,
        "_internal_originalTime": 1689839679000
    },
    {
        "time": 1689839681000,
        "value": 139.607,
        "_internal_originalTime": 1689839681000
    },
    {
        "time": 1689839682000,
        "value": 139.611,
        "_internal_originalTime": 1689839682000
    },
    {
        "time": 1689839683000,
        "value": 139.618,
        "_internal_originalTime": 1689839683000
    },
    {
        "time": 1689839685000,
        "value": 139.611,
        "_internal_originalTime": 1689839685000
    },
    {
        "time": 1689839686000,
        "value": 139.606,
        "_internal_originalTime": 1689839686000
    },
    {
        "time": 1689839688000,
        "value": 139.611,
        "_internal_originalTime": 1689839688000
    },
    {
        "time": 1689839690000,
        "value": 139.612,
        "_internal_originalTime": 1689839690000
    },
    {
        "time": 1689839691000,
        "value": 139.605,
        "_internal_originalTime": 1689839691000
    },
    {
        "time": 1689839692000,
        "value": 139.611,
        "_internal_originalTime": 1689839692000
    },
    {
        "time": 1689839693000,
        "value": 139.604,
        "_internal_originalTime": 1689839693000
    },
    {
        "time": 1689839695000,
        "value": 139.602,
        "_internal_originalTime": 1689839695000
    },
    {
        "time": 1689839697000,
        "value": 139.598,
        "_internal_originalTime": 1689839697000
    },
    {
        "time": 1689839698000,
        "value": 139.602,
        "_internal_originalTime": 1689839698000
    },
    {
        "time": 1689839699000,
        "value": 139.601,
        "_internal_originalTime": 1689839699000
    },
    {
        "time": 1689839701000,
        "value": 139.595,
        "_internal_originalTime": 1689839701000
    },
    {
        "time": 1689839702000,
        "value": 139.595,
        "_internal_originalTime": 1689839702000
    },
    {
        "time": 1689839703000,
        "value": 139.595,
        "_internal_originalTime": 1689839703000
    },
    {
        "time": 1689839704000,
        "value": 139.598,
        "_internal_originalTime": 1689839704000
    },
    {
        "time": 1689839705000,
        "value": 139.598,
        "_internal_originalTime": 1689839705000
    },
    {
        "time": 1689839706000,
        "value": 139.598,
        "_internal_originalTime": 1689839706000
    },
    {
        "time": 1689839707000,
        "value": 139.601,
        "_internal_originalTime": 1689839707000
    },
    {
        "time": 1689839708000,
        "value": 139.601,
        "_internal_originalTime": 1689839708000
    },
    {
        "time": 1689839710000,
        "value": 139.598,
        "_internal_originalTime": 1689839710000
    },
    {
        "time": 1689839711000,
        "value": 139.592,
        "_internal_originalTime": 1689839711000
    },
    {
        "time": 1689839713000,
        "value": 139.598,
        "_internal_originalTime": 1689839713000
    },
    {
        "time": 1689839715000,
        "value": 139.597,
        "_internal_originalTime": 1689839715000
    },
    {
        "time": 1689839716000,
        "value": 139.599,
        "_internal_originalTime": 1689839716000
    },
    {
        "time": 1689839717000,
        "value": 139.601,
        "_internal_originalTime": 1689839717000
    },
    {
        "time": 1689839718000,
        "value": 139.602,
        "_internal_originalTime": 1689839718000
    },
    {
        "time": 1689839720000,
        "value": 139.602,
        "_internal_originalTime": 1689839720000
    },
    {
        "time": 1689839722000,
        "value": 139.617,
        "_internal_originalTime": 1689839722000
    },
    {
        "time": 1689839724000,
        "value": 139.62,
        "_internal_originalTime": 1689839724000
    },
    {
        "time": 1689839726000,
        "value": 139.616,
        "_internal_originalTime": 1689839726000
    },
    {
        "time": 1689839728000,
        "value": 139.618,
        "_internal_originalTime": 1689839728000
    },
    {
        "time": 1689839730000,
        "value": 139.615,
        "_internal_originalTime": 1689839730000
    },
    {
        "time": 1689839732000,
        "value": 139.62,
        "_internal_originalTime": 1689839732000
    },
    {
        "time": 1689839733000,
        "value": 139.622,
        "_internal_originalTime": 1689839733000
    },
    {
        "time": 1689839734000,
        "value": 139.595,
        "_internal_originalTime": 1689839734000
    },
    {
        "time": 1689839736000,
        "value": 139.582,
        "_internal_originalTime": 1689839736000
    },
    {
        "time": 1689839737000,
        "value": 139.582,
        "_internal_originalTime": 1689839737000
    },
    {
        "time": 1689839738000,
        "value": 139.587,
        "_internal_originalTime": 1689839738000
    },
    {
        "time": 1689839740000,
        "value": 139.588,
        "_internal_originalTime": 1689839740000
    },
    {
        "time": 1689839741000,
        "value": 139.591,
        "_internal_originalTime": 1689839741000
    },
    {
        "time": 1689839742000,
        "value": 139.592,
        "_internal_originalTime": 1689839742000
    },
    {
        "time": 1689839743000,
        "value": 139.591,
        "_internal_originalTime": 1689839743000
    },
    {
        "time": 1689839744000,
        "value": 139.588,
        "_internal_originalTime": 1689839744000
    },
    {
        "time": 1689839746000,
        "value": 139.586,
        "_internal_originalTime": 1689839746000
    },
    {
        "time": 1689839747000,
        "value": 139.586,
        "_internal_originalTime": 1689839747000
    },
    {
        "time": 1689839748000,
        "value": 139.591,
        "_internal_originalTime": 1689839748000
    },
    {
        "time": 1689839749000,
        "value": 139.593,
        "_internal_originalTime": 1689839749000
    },
    {
        "time": 1689839750000,
        "value": 139.59,
        "_internal_originalTime": 1689839750000
    },
    {
        "time": 1689839752000,
        "value": 139.591,
        "_internal_originalTime": 1689839752000
    },
    {
        "time": 1689839754000,
        "value": 139.589,
        "_internal_originalTime": 1689839754000
    },
    {
        "time": 1689839755000,
        "value": 139.591,
        "_internal_originalTime": 1689839755000
    },
    {
        "time": 1689839757000,
        "value": 139.588,
        "_internal_originalTime": 1689839757000
    },
    {
        "time": 1689839759000,
        "value": 139.58,
        "_internal_originalTime": 1689839759000
    },
    {
        "time": 1689839761000,
        "value": 139.56,
        "_internal_originalTime": 1689839761000
    },
    {
        "time": 1689839762000,
        "value": 139.583,
        "_internal_originalTime": 1689839762000
    },
    {
        "time": 1689839763000,
        "value": 139.581,
        "_internal_originalTime": 1689839763000
    },
    {
        "time": 1689839764000,
        "value": 139.58,
        "_internal_originalTime": 1689839764000
    },
    {
        "time": 1689839766000,
        "value": 139.576,
        "_internal_originalTime": 1689839766000
    },
    {
        "time": 1689839767000,
        "value": 139.574,
        "_internal_originalTime": 1689839767000
    },
    {
        "time": 1689839768000,
        "value": 139.576,
        "_internal_originalTime": 1689839768000
    },
    {
        "time": 1689839770000,
        "value": 139.578,
        "_internal_originalTime": 1689839770000
    },
    {
        "time": 1689839771000,
        "value": 139.574,
        "_internal_originalTime": 1689839771000
    },
    {
        "time": 1689839772000,
        "value": 139.578,
        "_internal_originalTime": 1689839772000
    },
    {
        "time": 1689839774000,
        "value": 139.58,
        "_internal_originalTime": 1689839774000
    },
    {
        "time": 1689839775000,
        "value": 139.582,
        "_internal_originalTime": 1689839775000
    },
    {
        "time": 1689839776000,
        "value": 139.581,
        "_internal_originalTime": 1689839776000
    },
    {
        "time": 1689839777000,
        "value": 139.578,
        "_internal_originalTime": 1689839777000
    },
    {
        "time": 1689839778000,
        "value": 139.583,
        "_internal_originalTime": 1689839778000
    },
    {
        "time": 1689839779000,
        "value": 139.574,
        "_internal_originalTime": 1689839779000
    },
    {
        "time": 1689839781000,
        "value": 139.58,
        "_internal_originalTime": 1689839781000
    },
    {
        "time": 1689839783000,
        "value": 139.574,
        "_internal_originalTime": 1689839783000
    },
    {
        "time": 1689839785000,
        "value": 139.576,
        "_internal_originalTime": 1689839785000
    },
    {
        "time": 1689839786000,
        "value": 139.58,
        "_internal_originalTime": 1689839786000
    },
    {
        "time": 1689839787000,
        "value": 139.576,
        "_internal_originalTime": 1689839787000
    },
    {
        "time": 1689839789000,
        "value": 139.571,
        "_internal_originalTime": 1689839789000
    },
    {
        "time": 1689839790000,
        "value": 139.573,
        "_internal_originalTime": 1689839790000
    },
    {
        "time": 1689839791000,
        "value": 139.577,
        "_internal_originalTime": 1689839791000
    },
    {
        "time": 1689839792000,
        "value": 139.578,
        "_internal_originalTime": 1689839792000
    },
    {
        "time": 1689839794000,
        "value": 139.575,
        "_internal_originalTime": 1689839794000
    },
    {
        "time": 1689839795000,
        "value": 139.581,
        "_internal_originalTime": 1689839795000
    },
    {
        "time": 1689839796000,
        "value": 139.584,
        "_internal_originalTime": 1689839796000
    },
    {
        "time": 1689839797000,
        "value": 139.578,
        "_internal_originalTime": 1689839797000
    },
    {
        "time": 1689839798000,
        "value": 139.582,
        "_internal_originalTime": 1689839798000
    },
    {
        "time": 1689839800000,
        "value": 139.579,
        "_internal_originalTime": 1689839800000
    },
    {
        "time": 1689839801000,
        "value": 139.573,
        "_internal_originalTime": 1689839801000
    },
    {
        "time": 1689839802000,
        "value": 139.581,
        "_internal_originalTime": 1689839802000
    },
    {
        "time": 1689839805000,
        "value": 139.576,
        "_internal_originalTime": 1689839805000
    },
    {
        "time": 1689839806000,
        "value": 139.581,
        "_internal_originalTime": 1689839806000
    },
    {
        "time": 1689839807000,
        "value": 139.582,
        "_internal_originalTime": 1689839807000
    },
    {
        "time": 1689839808000,
        "value": 139.573,
        "_internal_originalTime": 1689839808000
    },
    {
        "time": 1689839809000,
        "value": 139.581,
        "_internal_originalTime": 1689839809000
    },
    {
        "time": 1689839811000,
        "value": 139.578,
        "_internal_originalTime": 1689839811000
    },
    {
        "time": 1689839812000,
        "value": 139.577,
        "_internal_originalTime": 1689839812000
    },
    {
        "time": 1689839813000,
        "value": 139.577,
        "_internal_originalTime": 1689839813000
    },
    {
        "time": 1689839816000,
        "value": 139.576,
        "_internal_originalTime": 1689839816000
    },
    {
        "time": 1689839817000,
        "value": 139.572,
        "_internal_originalTime": 1689839817000
    },
    {
        "time": 1689839820000,
        "value": 139.55,
        "_internal_originalTime": 1689839820000
    },
    {
        "time": 1689839822000,
        "value": 139.575,
        "_internal_originalTime": 1689839822000
    },
    {
        "time": 1689839823000,
        "value": 139.578,
        "_internal_originalTime": 1689839823000
    },
    {
        "time": 1689839824000,
        "value": 139.577,
        "_internal_originalTime": 1689839824000
    },
    {
        "time": 1689839825000,
        "value": 139.577,
        "_internal_originalTime": 1689839825000
    },
    {
        "time": 1689839827000,
        "value": 139.573,
        "_internal_originalTime": 1689839827000
    },
    {
        "time": 1689839828000,
        "value": 139.577,
        "_internal_originalTime": 1689839828000
    },
    {
        "time": 1689839829000,
        "value": 139.577,
        "_internal_originalTime": 1689839829000
    },
    {
        "time": 1689839831000,
        "value": 139.582,
        "_internal_originalTime": 1689839831000
    },
    {
        "time": 1689839832000,
        "value": 139.583,
        "_internal_originalTime": 1689839832000
    },
    {
        "time": 1689839834000,
        "value": 139.58,
        "_internal_originalTime": 1689839834000
    },
    {
        "time": 1689839836000,
        "value": 139.584,
        "_internal_originalTime": 1689839836000
    },
    {
        "time": 1689839837000,
        "value": 139.585,
        "_internal_originalTime": 1689839837000
    },
    {
        "time": 1689839840000,
        "value": 139.586,
        "_internal_originalTime": 1689839840000
    },
    {
        "time": 1689839841000,
        "value": 139.585,
        "_internal_originalTime": 1689839841000
    },
    {
        "time": 1689839842000,
        "value": 139.582,
        "_internal_originalTime": 1689839842000
    },
    {
        "time": 1689839843000,
        "value": 139.581,
        "_internal_originalTime": 1689839843000
    },
    {
        "time": 1689839845000,
        "value": 139.585,
        "_internal_originalTime": 1689839845000
    },
    {
        "time": 1689839848000,
        "value": 139.585,
        "_internal_originalTime": 1689839848000
    },
    {
        "time": 1689839849000,
        "value": 139.583,
        "_internal_originalTime": 1689839849000
    },
    {
        "time": 1689839850000,
        "value": 139.587,
        "_internal_originalTime": 1689839850000
    },
    {
        "time": 1689839851000,
        "value": 139.591,
        "_internal_originalTime": 1689839851000
    },
    {
        "time": 1689839852000,
        "value": 139.593,
        "_internal_originalTime": 1689839852000
    },
    {
        "time": 1689839853000,
        "value": 139.59,
        "_internal_originalTime": 1689839853000
    },
    {
        "time": 1689839854000,
        "value": 139.59,
        "_internal_originalTime": 1689839854000
    },
    {
        "time": 1689839856000,
        "value": 139.592,
        "_internal_originalTime": 1689839856000
    },
    {
        "time": 1689839857000,
        "value": 139.589,
        "_internal_originalTime": 1689839857000
    },
    {
        "time": 1689839858000,
        "value": 139.593,
        "_internal_originalTime": 1689839858000
    },
    {
        "time": 1689839860000,
        "value": 139.601,
        "_internal_originalTime": 1689839860000
    },
    {
        "time": 1689839861000,
        "value": 139.604,
        "_internal_originalTime": 1689839861000
    },
    {
        "time": 1689839862000,
        "value": 139.608,
        "_internal_originalTime": 1689839862000
    },
    {
        "time": 1689839863000,
        "value": 139.609,
        "_internal_originalTime": 1689839863000
    },
    {
        "time": 1689839864000,
        "value": 139.61,
        "_internal_originalTime": 1689839864000
    },
    {
        "time": 1689839866000,
        "value": 139.607,
        "_internal_originalTime": 1689839866000
    },
    {
        "time": 1689839867000,
        "value": 139.608,
        "_internal_originalTime": 1689839867000
    },
    {
        "time": 1689839868000,
        "value": 139.611,
        "_internal_originalTime": 1689839868000
    },
    {
        "time": 1689839869000,
        "value": 139.602,
        "_internal_originalTime": 1689839869000
    },
    {
        "time": 1689839871000,
        "value": 139.607,
        "_internal_originalTime": 1689839871000
    },
    {
        "time": 1689839872000,
        "value": 139.608,
        "_internal_originalTime": 1689839872000
    },
    {
        "time": 1689839874000,
        "value": 139.605,
        "_internal_originalTime": 1689839874000
    },
    {
        "time": 1689839875000,
        "value": 139.601,
        "_internal_originalTime": 1689839875000
    },
    {
        "time": 1689839877000,
        "value": 139.604,
        "_internal_originalTime": 1689839877000
    },
    {
        "time": 1689839879000,
        "value": 139.607,
        "_internal_originalTime": 1689839879000
    },
    {
        "time": 1689839880000,
        "value": 139.58,
        "_internal_originalTime": 1689839880000
    },
    {
        "time": 1689839882000,
        "value": 139.606,
        "_internal_originalTime": 1689839882000
    },
    {
        "time": 1689839883000,
        "value": 139.599,
        "_internal_originalTime": 1689839883000
    },
    {
        "time": 1689839885000,
        "value": 139.604,
        "_internal_originalTime": 1689839885000
    },
    {
        "time": 1689839886000,
        "value": 139.601,
        "_internal_originalTime": 1689839886000
    },
    {
        "time": 1689839887000,
        "value": 139.602,
        "_internal_originalTime": 1689839887000
    },
    {
        "time": 1689839888000,
        "value": 139.607,
        "_internal_originalTime": 1689839888000
    },
    {
        "time": 1689839889000,
        "value": 139.608,
        "_internal_originalTime": 1689839889000
    },
    {
        "time": 1689839892000,
        "value": 139.607,
        "_internal_originalTime": 1689839892000
    },
    {
        "time": 1689839896000,
        "value": 139.609,
        "_internal_originalTime": 1689839896000
    },
    {
        "time": 1689839897000,
        "value": 139.61,
        "_internal_originalTime": 1689839897000
    },
    {
        "time": 1689839898000,
        "value": 139.61,
        "_internal_originalTime": 1689839898000
    },
    {
        "time": 1689839899000,
        "value": 139.616,
        "_internal_originalTime": 1689839899000
    },
    {
        "time": 1689839901000,
        "value": 139.61,
        "_internal_originalTime": 1689839901000
    },
    {
        "time": 1689839902000,
        "value": 139.615,
        "_internal_originalTime": 1689839902000
    },
    {
        "time": 1689839905000,
        "value": 139.614,
        "_internal_originalTime": 1689839905000
    },
    {
        "time": 1689839906000,
        "value": 139.614,
        "_internal_originalTime": 1689839906000
    },
    {
        "time": 1689839907000,
        "value": 139.621,
        "_internal_originalTime": 1689839907000
    },
    {
        "time": 1689839908000,
        "value": 139.626,
        "_internal_originalTime": 1689839908000
    },
    {
        "time": 1689839910000,
        "value": 139.623,
        "_internal_originalTime": 1689839910000
    },
    {
        "time": 1689839912000,
        "value": 139.626,
        "_internal_originalTime": 1689839912000
    },
    {
        "time": 1689839913000,
        "value": 139.625,
        "_internal_originalTime": 1689839913000
    },
    {
        "time": 1689839914000,
        "value": 139.627,
        "_internal_originalTime": 1689839914000
    },
    {
        "time": 1689839916000,
        "value": 139.623,
        "_internal_originalTime": 1689839916000
    },
    {
        "time": 1689839917000,
        "value": 139.624,
        "_internal_originalTime": 1689839917000
    },
    {
        "time": 1689839918000,
        "value": 139.627,
        "_internal_originalTime": 1689839918000
    },
    {
        "time": 1689839919000,
        "value": 139.624,
        "_internal_originalTime": 1689839919000
    },
    {
        "time": 1689839921000,
        "value": 139.62,
        "_internal_originalTime": 1689839921000
    },
    {
        "time": 1689839922000,
        "value": 139.619,
        "_internal_originalTime": 1689839922000
    },
    {
        "time": 1689839924000,
        "value": 139.62,
        "_internal_originalTime": 1689839924000
    },
    {
        "time": 1689839925000,
        "value": 139.624,
        "_internal_originalTime": 1689839925000
    },
    {
        "time": 1689839926000,
        "value": 139.625,
        "_internal_originalTime": 1689839926000
    },
    {
        "time": 1689839928000,
        "value": 139.624,
        "_internal_originalTime": 1689839928000
    },
    {
        "time": 1689839929000,
        "value": 139.625,
        "_internal_originalTime": 1689839929000
    },
    {
        "time": 1689839930000,
        "value": 139.625,
        "_internal_originalTime": 1689839930000
    },
    {
        "time": 1689839931000,
        "value": 139.623,
        "_internal_originalTime": 1689839931000
    },
    {
        "time": 1689839932000,
        "value": 139.622,
        "_internal_originalTime": 1689839932000
    },
    {
        "time": 1689839933000,
        "value": 139.623,
        "_internal_originalTime": 1689839933000
    },
    {
        "time": 1689839935000,
        "value": 139.624,
        "_internal_originalTime": 1689839935000
    },
    {
        "time": 1689839936000,
        "value": 139.624,
        "_internal_originalTime": 1689839936000
    },
    {
        "time": 1689839937000,
        "value": 139.626,
        "_internal_originalTime": 1689839937000
    },
    {
        "time": 1689839939000,
        "value": 139.624,
        "_internal_originalTime": 1689839939000
    },
    {
        "time": 1689839940000,
        "value": 139.6,
        "_internal_originalTime": 1689839940000
    },
    {
        "time": 1689839941000,
        "value": 139.624,
        "_internal_originalTime": 1689839941000
    },
    {
        "time": 1689839942000,
        "value": 139.625,
        "_internal_originalTime": 1689839942000
    },
    {
        "time": 1689839943000,
        "value": 139.623,
        "_internal_originalTime": 1689839943000
    },
    {
        "time": 1689839944000,
        "value": 139.623,
        "_internal_originalTime": 1689839944000
    },
    {
        "time": 1689839945000,
        "value": 139.637,
        "_internal_originalTime": 1689839945000
    },
    {
        "time": 1689839946000,
        "value": 139.643,
        "_internal_originalTime": 1689839946000
    },
    {
        "time": 1689839947000,
        "value": 139.641,
        "_internal_originalTime": 1689839947000
    },
    {
        "time": 1689839949000,
        "value": 139.644,
        "_internal_originalTime": 1689839949000
    },
    {
        "time": 1689839950000,
        "value": 139.643,
        "_internal_originalTime": 1689839950000
    },
    {
        "time": 1689839952000,
        "value": 139.647,
        "_internal_originalTime": 1689839952000
    },
    {
        "time": 1689839953000,
        "value": 139.648,
        "_internal_originalTime": 1689839953000
    },
    {
        "time": 1689839954000,
        "value": 139.648,
        "_internal_originalTime": 1689839954000
    },
    {
        "time": 1689839955000,
        "value": 139.644,
        "_internal_originalTime": 1689839955000
    },
    {
        "time": 1689839956000,
        "value": 139.647,
        "_internal_originalTime": 1689839956000
    },
    {
        "time": 1689839958000,
        "value": 139.645,
        "_internal_originalTime": 1689839958000
    },
    {
        "time": 1689839959000,
        "value": 139.636,
        "_internal_originalTime": 1689839959000
    },
    {
        "time": 1689839960000,
        "value": 139.637,
        "_internal_originalTime": 1689839960000
    },
    {
        "time": 1689839961000,
        "value": 139.635,
        "_internal_originalTime": 1689839961000
    },
    {
        "time": 1689839962000,
        "value": 139.636,
        "_internal_originalTime": 1689839962000
    },
    {
        "time": 1689839963000,
        "value": 139.632,
        "_internal_originalTime": 1689839963000
    },
    {
        "time": 1689839964000,
        "value": 139.641,
        "_internal_originalTime": 1689839964000
    },
    {
        "time": 1689839966000,
        "value": 139.636,
        "_internal_originalTime": 1689839966000
    },
    {
        "time": 1689839968000,
        "value": 139.645,
        "_internal_originalTime": 1689839968000
    },
    {
        "time": 1689839969000,
        "value": 139.643,
        "_internal_originalTime": 1689839969000
    },
    {
        "time": 1689839970000,
        "value": 139.641,
        "_internal_originalTime": 1689839970000
    },
    {
        "time": 1689839971000,
        "value": 139.638,
        "_internal_originalTime": 1689839971000
    },
    {
        "time": 1689839972000,
        "value": 139.638,
        "_internal_originalTime": 1689839972000
    },
    {
        "time": 1689839973000,
        "value": 139.641,
        "_internal_originalTime": 1689839973000
    },
    {
        "time": 1689839975000,
        "value": 139.641,
        "_internal_originalTime": 1689839975000
    },
    {
        "time": 1689839976000,
        "value": 139.643,
        "_internal_originalTime": 1689839976000
    },
    {
        "time": 1689839977000,
        "value": 139.636,
        "_internal_originalTime": 1689839977000
    },
    {
        "time": 1689839978000,
        "value": 139.643,
        "_internal_originalTime": 1689839978000
    },
    {
        "time": 1689839979000,
        "value": 139.644,
        "_internal_originalTime": 1689839979000
    },
    {
        "time": 1689839981000,
        "value": 139.64,
        "_internal_originalTime": 1689839981000
    },
    {
        "time": 1689839983000,
        "value": 139.638,
        "_internal_originalTime": 1689839983000
    },
    {
        "time": 1689839984000,
        "value": 139.641,
        "_internal_originalTime": 1689839984000
    },
    {
        "time": 1689839988000,
        "value": 139.642,
        "_internal_originalTime": 1689839988000
    },
    {
        "time": 1689839989000,
        "value": 139.643,
        "_internal_originalTime": 1689839989000
    },
    {
        "time": 1689839991000,
        "value": 139.642,
        "_internal_originalTime": 1689839991000
    },
    {
        "time": 1689839992000,
        "value": 139.642,
        "_internal_originalTime": 1689839992000
    },
    {
        "time": 1689839994000,
        "value": 139.628,
        "_internal_originalTime": 1689839994000
    },
    {
        "time": 1689839996000,
        "value": 139.625,
        "_internal_originalTime": 1689839996000
    },
    {
        "time": 1689839997000,
        "value": 139.627,
        "_internal_originalTime": 1689839997000
    },
    {
        "time": 1689839998000,
        "value": 139.625,
        "_internal_originalTime": 1689839998000
    },
    {
        "time": 1689839999000,
        "value": 139.622,
        "_internal_originalTime": 1689839999000
    },
    {
        "time": 1689840000000,
        "value": 139.62,
        "_internal_originalTime": 1689840000000
    },
    {
        "time": 1689840001000,
        "value": 139.627,
        "_internal_originalTime": 1689840001000
    },
    {
        "time": 1689840002000,
        "value": 139.624,
        "_internal_originalTime": 1689840002000
    },
    {
        "time": 1689840003000,
        "value": 139.626,
        "_internal_originalTime": 1689840003000
    },
    {
        "time": 1689840004000,
        "value": 139.614,
        "_internal_originalTime": 1689840004000
    },
    {
        "time": 1689840005000,
        "value": 139.621,
        "_internal_originalTime": 1689840005000
    },
    {
        "time": 1689840006000,
        "value": 139.618,
        "_internal_originalTime": 1689840006000
    },
    {
        "time": 1689840007000,
        "value": 139.621,
        "_internal_originalTime": 1689840007000
    },
    {
        "time": 1689840008000,
        "value": 139.611,
        "_internal_originalTime": 1689840008000
    },
    {
        "time": 1689840009000,
        "value": 139.611,
        "_internal_originalTime": 1689840009000
    },
    {
        "time": 1689840010000,
        "value": 139.611,
        "_internal_originalTime": 1689840010000
    },
    {
        "time": 1689840011000,
        "value": 139.605,
        "_internal_originalTime": 1689840011000
    },
    {
        "time": 1689840013000,
        "value": 139.611,
        "_internal_originalTime": 1689840013000
    },
    {
        "time": 1689840015000,
        "value": 139.598,
        "_internal_originalTime": 1689840015000
    },
    {
        "time": 1689840016000,
        "value": 139.601,
        "_internal_originalTime": 1689840016000
    },
    {
        "time": 1689840018000,
        "value": 139.596,
        "_internal_originalTime": 1689840018000
    },
    {
        "time": 1689840019000,
        "value": 139.596,
        "_internal_originalTime": 1689840019000
    },
    {
        "time": 1689840020000,
        "value": 139.587,
        "_internal_originalTime": 1689840020000
    },
    {
        "time": 1689840021000,
        "value": 139.582,
        "_internal_originalTime": 1689840021000
    },
    {
        "time": 1689840023000,
        "value": 139.594,
        "_internal_originalTime": 1689840023000
    },
    {
        "time": 1689840025000,
        "value": 139.572,
        "_internal_originalTime": 1689840025000
    },
    {
        "time": 1689840026000,
        "value": 139.579,
        "_internal_originalTime": 1689840026000
    },
    {
        "time": 1689840028000,
        "value": 139.582,
        "_internal_originalTime": 1689840028000
    },
    {
        "time": 1689840029000,
        "value": 139.582,
        "_internal_originalTime": 1689840029000
    },
    {
        "time": 1689840030000,
        "value": 139.578,
        "_internal_originalTime": 1689840030000
    },
    {
        "time": 1689840031000,
        "value": 139.594,
        "_internal_originalTime": 1689840031000
    },
    {
        "time": 1689840032000,
        "value": 139.601,
        "_internal_originalTime": 1689840032000
    },
    {
        "time": 1689840034000,
        "value": 139.6,
        "_internal_originalTime": 1689840034000
    },
    {
        "time": 1689840035000,
        "value": 139.595,
        "_internal_originalTime": 1689840035000
    },
    {
        "time": 1689840036000,
        "value": 139.596,
        "_internal_originalTime": 1689840036000
    },
    {
        "time": 1689840038000,
        "value": 139.599,
        "_internal_originalTime": 1689840038000
    },
    {
        "time": 1689840039000,
        "value": 139.596,
        "_internal_originalTime": 1689840039000
    },
    {
        "time": 1689840041000,
        "value": 139.606,
        "_internal_originalTime": 1689840041000
    },
    {
        "time": 1689840042000,
        "value": 139.606,
        "_internal_originalTime": 1689840042000
    },
    {
        "time": 1689840044000,
        "value": 139.6,
        "_internal_originalTime": 1689840044000
    },
    {
        "time": 1689840045000,
        "value": 139.603,
        "_internal_originalTime": 1689840045000
    },
    {
        "time": 1689840047000,
        "value": 139.605,
        "_internal_originalTime": 1689840047000
    },
    {
        "time": 1689840048000,
        "value": 139.607,
        "_internal_originalTime": 1689840048000
    },
    {
        "time": 1689840049000,
        "value": 139.606,
        "_internal_originalTime": 1689840049000
    },
    {
        "time": 1689840050000,
        "value": 139.601,
        "_internal_originalTime": 1689840050000
    },
    {
        "time": 1689840052000,
        "value": 139.602,
        "_internal_originalTime": 1689840052000
    },
    {
        "time": 1689840053000,
        "value": 139.607,
        "_internal_originalTime": 1689840053000
    },
    {
        "time": 1689840054000,
        "value": 139.608,
        "_internal_originalTime": 1689840054000
    },
    {
        "time": 1689840055000,
        "value": 139.608,
        "_internal_originalTime": 1689840055000
    },
    {
        "time": 1689840057000,
        "value": 139.609,
        "_internal_originalTime": 1689840057000
    },
    {
        "time": 1689840058000,
        "value": 139.615,
        "_internal_originalTime": 1689840058000
    },
    {
        "time": 1689840059000,
        "value": 139.616,
        "_internal_originalTime": 1689840059000
    },
    {
        "time": 1689840060000,
        "value": 139.617,
        "_internal_originalTime": 1689840060000
    },
    {
        "time": 1689840061000,
        "value": 139.618,
        "_internal_originalTime": 1689840061000
    },
    {
        "time": 1689840063000,
        "value": 139.618,
        "_internal_originalTime": 1689840063000
    },
    {
        "time": 1689840064000,
        "value": 139.614,
        "_internal_originalTime": 1689840064000
    },
    {
        "time": 1689840065000,
        "value": 139.618,
        "_internal_originalTime": 1689840065000
    },
    {
        "time": 1689840066000,
        "value": 139.618,
        "_internal_originalTime": 1689840066000
    },
    {
        "time": 1689840067000,
        "value": 139.614,
        "_internal_originalTime": 1689840067000
    },
    {
        "time": 1689840068000,
        "value": 139.617,
        "_internal_originalTime": 1689840068000
    },
    {
        "time": 1689840070000,
        "value": 139.621,
        "_internal_originalTime": 1689840070000
    },
    {
        "time": 1689840071000,
        "value": 139.631,
        "_internal_originalTime": 1689840071000
    },
    {
        "time": 1689840072000,
        "value": 139.632,
        "_internal_originalTime": 1689840072000
    },
    {
        "time": 1689840073000,
        "value": 139.635,
        "_internal_originalTime": 1689840073000
    },
    {
        "time": 1689840075000,
        "value": 139.628,
        "_internal_originalTime": 1689840075000
    },
    {
        "time": 1689840076000,
        "value": 139.637,
        "_internal_originalTime": 1689840076000
    },
    {
        "time": 1689840077000,
        "value": 139.644,
        "_internal_originalTime": 1689840077000
    },
    {
        "time": 1689840078000,
        "value": 139.641,
        "_internal_originalTime": 1689840078000
    },
    {
        "time": 1689840079000,
        "value": 139.638,
        "_internal_originalTime": 1689840079000
    },
    {
        "time": 1689840081000,
        "value": 139.64,
        "_internal_originalTime": 1689840081000
    },
    {
        "time": 1689840083000,
        "value": 139.645,
        "_internal_originalTime": 1689840083000
    },
    {
        "time": 1689840084000,
        "value": 139.637,
        "_internal_originalTime": 1689840084000
    },
    {
        "time": 1689840086000,
        "value": 139.636,
        "_internal_originalTime": 1689840086000
    },
    {
        "time": 1689840087000,
        "value": 139.635,
        "_internal_originalTime": 1689840087000
    },
    {
        "time": 1689840088000,
        "value": 139.638,
        "_internal_originalTime": 1689840088000
    },
    {
        "time": 1689840090000,
        "value": 139.641,
        "_internal_originalTime": 1689840090000
    },
    {
        "time": 1689840092000,
        "value": 139.637,
        "_internal_originalTime": 1689840092000
    },
    {
        "time": 1689840093000,
        "value": 139.645,
        "_internal_originalTime": 1689840093000
    },
    {
        "time": 1689840094000,
        "value": 139.639,
        "_internal_originalTime": 1689840094000
    },
    {
        "time": 1689840095000,
        "value": 139.643,
        "_internal_originalTime": 1689840095000
    },
    {
        "time": 1689840097000,
        "value": 139.643,
        "_internal_originalTime": 1689840097000
    },
    {
        "time": 1689840098000,
        "value": 139.642,
        "_internal_originalTime": 1689840098000
    },
    {
        "time": 1689840099000,
        "value": 139.649,
        "_internal_originalTime": 1689840099000
    },
    {
        "time": 1689840101000,
        "value": 139.648,
        "_internal_originalTime": 1689840101000
    },
    {
        "time": 1689840102000,
        "value": 139.659,
        "_internal_originalTime": 1689840102000
    },
    {
        "time": 1689840103000,
        "value": 139.661,
        "_internal_originalTime": 1689840103000
    },
    {
        "time": 1689840104000,
        "value": 139.658,
        "_internal_originalTime": 1689840104000
    },
    {
        "time": 1689840106000,
        "value": 139.663,
        "_internal_originalTime": 1689840106000
    },
    {
        "time": 1689840108000,
        "value": 139.661,
        "_internal_originalTime": 1689840108000
    },
    {
        "time": 1689840109000,
        "value": 139.658,
        "_internal_originalTime": 1689840109000
    },
    {
        "time": 1689840110000,
        "value": 139.658,
        "_internal_originalTime": 1689840110000
    },
    {
        "time": 1689840111000,
        "value": 139.661,
        "_internal_originalTime": 1689840111000
    },
    {
        "time": 1689840112000,
        "value": 139.657,
        "_internal_originalTime": 1689840112000
    },
    {
        "time": 1689840113000,
        "value": 139.656,
        "_internal_originalTime": 1689840113000
    },
    {
        "time": 1689840115000,
        "value": 139.657,
        "_internal_originalTime": 1689840115000
    },
    {
        "time": 1689840116000,
        "value": 139.654,
        "_internal_originalTime": 1689840116000
    },
    {
        "time": 1689840117000,
        "value": 139.654,
        "_internal_originalTime": 1689840117000
    },
    {
        "time": 1689840118000,
        "value": 139.657,
        "_internal_originalTime": 1689840118000
    },
    {
        "time": 1689840119000,
        "value": 139.652,
        "_internal_originalTime": 1689840119000
    },
    {
        "time": 1689840121000,
        "value": 139.66,
        "_internal_originalTime": 1689840121000
    },
    {
        "time": 1689840122000,
        "value": 139.658,
        "_internal_originalTime": 1689840122000
    },
    {
        "time": 1689840123000,
        "value": 139.654,
        "_internal_originalTime": 1689840123000
    },
    {
        "time": 1689840124000,
        "value": 139.656,
        "_internal_originalTime": 1689840124000
    },
    {
        "time": 1689840125000,
        "value": 139.649,
        "_internal_originalTime": 1689840125000
    },
    {
        "time": 1689840127000,
        "value": 139.641,
        "_internal_originalTime": 1689840127000
    },
    {
        "time": 1689840128000,
        "value": 139.639,
        "_internal_originalTime": 1689840128000
    },
    {
        "time": 1689840129000,
        "value": 139.632,
        "_internal_originalTime": 1689840129000
    },
    {
        "time": 1689840130000,
        "value": 139.637,
        "_internal_originalTime": 1689840130000
    },
    {
        "time": 1689840132000,
        "value": 139.637,
        "_internal_originalTime": 1689840132000
    },
    {
        "time": 1689840133000,
        "value": 139.638,
        "_internal_originalTime": 1689840133000
    },
    {
        "time": 1689840134000,
        "value": 139.634,
        "_internal_originalTime": 1689840134000
    },
    {
        "time": 1689840135000,
        "value": 139.635,
        "_internal_originalTime": 1689840135000
    },
    {
        "time": 1689840136000,
        "value": 139.639,
        "_internal_originalTime": 1689840136000
    },
    {
        "time": 1689840137000,
        "value": 139.646,
        "_internal_originalTime": 1689840137000
    },
    {
        "time": 1689840138000,
        "value": 139.647,
        "_internal_originalTime": 1689840138000
    },
    {
        "time": 1689840139000,
        "value": 139.638,
        "_internal_originalTime": 1689840139000
    },
    {
        "time": 1689840140000,
        "value": 139.641,
        "_internal_originalTime": 1689840140000
    },
    {
        "time": 1689840142000,
        "value": 139.643,
        "_internal_originalTime": 1689840142000
    },
    {
        "time": 1689840144000,
        "value": 139.644,
        "_internal_originalTime": 1689840144000
    },
    {
        "time": 1689840145000,
        "value": 139.644,
        "_internal_originalTime": 1689840145000
    },
    {
        "time": 1689840146000,
        "value": 139.642,
        "_internal_originalTime": 1689840146000
    },
    {
        "time": 1689840147000,
        "value": 139.643,
        "_internal_originalTime": 1689840147000
    },
    {
        "time": 1689840148000,
        "value": 139.648,
        "_internal_originalTime": 1689840148000
    },
    {
        "time": 1689840150000,
        "value": 139.651,
        "_internal_originalTime": 1689840150000
    },
    {
        "time": 1689840151000,
        "value": 139.653,
        "_internal_originalTime": 1689840151000
    },
    {
        "time": 1689840152000,
        "value": 139.654,
        "_internal_originalTime": 1689840152000
    },
    {
        "time": 1689840153000,
        "value": 139.649,
        "_internal_originalTime": 1689840153000
    },
    {
        "time": 1689840154000,
        "value": 139.654,
        "_internal_originalTime": 1689840154000
    },
    {
        "time": 1689840156000,
        "value": 139.654,
        "_internal_originalTime": 1689840156000
    },
    {
        "time": 1689840157000,
        "value": 139.651,
        "_internal_originalTime": 1689840157000
    },
    {
        "time": 1689840158000,
        "value": 139.652,
        "_internal_originalTime": 1689840158000
    },
    {
        "time": 1689840160000,
        "value": 139.648,
        "_internal_originalTime": 1689840160000
    },
    {
        "time": 1689840161000,
        "value": 139.656,
        "_internal_originalTime": 1689840161000
    },
    {
        "time": 1689840162000,
        "value": 139.65,
        "_internal_originalTime": 1689840162000
    },
    {
        "time": 1689840163000,
        "value": 139.657,
        "_internal_originalTime": 1689840163000
    },
    {
        "time": 1689840165000,
        "value": 139.652,
        "_internal_originalTime": 1689840165000
    },
    {
        "time": 1689840166000,
        "value": 139.653,
        "_internal_originalTime": 1689840166000
    },
    {
        "time": 1689840167000,
        "value": 139.652,
        "_internal_originalTime": 1689840167000
    },
    {
        "time": 1689840169000,
        "value": 139.653,
        "_internal_originalTime": 1689840169000
    },
    {
        "time": 1689840170000,
        "value": 139.652,
        "_internal_originalTime": 1689840170000
    },
    {
        "time": 1689840171000,
        "value": 139.663,
        "_internal_originalTime": 1689840171000
    },
    {
        "time": 1689840172000,
        "value": 139.652,
        "_internal_originalTime": 1689840172000
    },
    {
        "time": 1689840173000,
        "value": 139.656,
        "_internal_originalTime": 1689840173000
    },
    {
        "time": 1689840175000,
        "value": 139.654,
        "_internal_originalTime": 1689840175000
    },
    {
        "time": 1689840176000,
        "value": 139.649,
        "_internal_originalTime": 1689840176000
    },
    {
        "time": 1689840177000,
        "value": 139.653,
        "_internal_originalTime": 1689840177000
    },
    {
        "time": 1689840179000,
        "value": 139.648,
        "_internal_originalTime": 1689840179000
    },
    {
        "time": 1689840180000,
        "value": 139.648,
        "_internal_originalTime": 1689840180000
    },
    {
        "time": 1689840181000,
        "value": 139.652,
        "_internal_originalTime": 1689840181000
    },
    {
        "time": 1689840182000,
        "value": 139.648,
        "_internal_originalTime": 1689840182000
    },
    {
        "time": 1689840184000,
        "value": 139.648,
        "_internal_originalTime": 1689840184000
    },
    {
        "time": 1689840186000,
        "value": 139.652,
        "_internal_originalTime": 1689840186000
    },
    {
        "time": 1689840188000,
        "value": 139.652,
        "_internal_originalTime": 1689840188000
    },
    {
        "time": 1689840190000,
        "value": 139.65,
        "_internal_originalTime": 1689840190000
    },
    {
        "time": 1689840191000,
        "value": 139.655,
        "_internal_originalTime": 1689840191000
    },
    {
        "time": 1689840193000,
        "value": 139.66,
        "_internal_originalTime": 1689840193000
    },
    {
        "time": 1689840195000,
        "value": 139.663,
        "_internal_originalTime": 1689840195000
    },
    {
        "time": 1689840197000,
        "value": 139.662,
        "_internal_originalTime": 1689840197000
    },
    {
        "time": 1689840198000,
        "value": 139.655,
        "_internal_originalTime": 1689840198000
    },
    {
        "time": 1689840200000,
        "value": 139.652,
        "_internal_originalTime": 1689840200000
    },
    {
        "time": 1689840201000,
        "value": 139.651,
        "_internal_originalTime": 1689840201000
    },
    {
        "time": 1689840202000,
        "value": 139.655,
        "_internal_originalTime": 1689840202000
    },
    {
        "time": 1689840203000,
        "value": 139.65,
        "_internal_originalTime": 1689840203000
    },
    {
        "time": 1689840205000,
        "value": 139.653,
        "_internal_originalTime": 1689840205000
    },
    {
        "time": 1689840207000,
        "value": 139.652,
        "_internal_originalTime": 1689840207000
    },
    {
        "time": 1689840210000,
        "value": 139.653,
        "_internal_originalTime": 1689840210000
    },
    {
        "time": 1689840212000,
        "value": 139.648,
        "_internal_originalTime": 1689840212000
    },
    {
        "time": 1689840213000,
        "value": 139.652,
        "_internal_originalTime": 1689840213000
    },
    {
        "time": 1689840214000,
        "value": 139.647,
        "_internal_originalTime": 1689840214000
    },
    {
        "time": 1689840216000,
        "value": 139.646,
        "_internal_originalTime": 1689840216000
    },
    {
        "time": 1689840218000,
        "value": 139.641,
        "_internal_originalTime": 1689840218000
    },
    {
        "time": 1689840219000,
        "value": 139.646,
        "_internal_originalTime": 1689840219000
    },
    {
        "time": 1689840221000,
        "value": 139.641,
        "_internal_originalTime": 1689840221000
    },
    {
        "time": 1689840222000,
        "value": 139.635,
        "_internal_originalTime": 1689840222000
    },
    {
        "time": 1689840223000,
        "value": 139.638,
        "_internal_originalTime": 1689840223000
    },
    {
        "time": 1689840226000,
        "value": 139.629,
        "_internal_originalTime": 1689840226000
    },
    {
        "time": 1689840228000,
        "value": 139.63,
        "_internal_originalTime": 1689840228000
    },
    {
        "time": 1689840229000,
        "value": 139.628,
        "_internal_originalTime": 1689840229000
    },
    {
        "time": 1689840230000,
        "value": 139.629,
        "_internal_originalTime": 1689840230000
    },
    {
        "time": 1689840231000,
        "value": 139.633,
        "_internal_originalTime": 1689840231000
    },
    {
        "time": 1689840232000,
        "value": 139.627,
        "_internal_originalTime": 1689840232000
    },
    {
        "time": 1689840233000,
        "value": 139.632,
        "_internal_originalTime": 1689840233000
    },
    {
        "time": 1689840234000,
        "value": 139.629,
        "_internal_originalTime": 1689840234000
    },
    {
        "time": 1689840235000,
        "value": 139.626,
        "_internal_originalTime": 1689840235000
    },
    {
        "time": 1689840237000,
        "value": 139.622,
        "_internal_originalTime": 1689840237000
    },
    {
        "time": 1689840239000,
        "value": 139.626,
        "_internal_originalTime": 1689840239000
    },
    {
        "time": 1689840240000,
        "value": 139.626,
        "_internal_originalTime": 1689840240000
    },
    {
        "time": 1689840241000,
        "value": 139.626,
        "_internal_originalTime": 1689840241000
    },
    {
        "time": 1689840243000,
        "value": 139.622,
        "_internal_originalTime": 1689840243000
    },
    {
        "time": 1689840244000,
        "value": 139.621,
        "_internal_originalTime": 1689840244000
    },
    {
        "time": 1689840246000,
        "value": 139.621,
        "_internal_originalTime": 1689840246000
    },
    {
        "time": 1689840247000,
        "value": 139.616,
        "_internal_originalTime": 1689840247000
    },
    {
        "time": 1689840249000,
        "value": 139.618,
        "_internal_originalTime": 1689840249000
    },
    {
        "time": 1689840251000,
        "value": 139.618,
        "_internal_originalTime": 1689840251000
    },
    {
        "time": 1689840252000,
        "value": 139.623,
        "_internal_originalTime": 1689840252000
    },
    {
        "time": 1689840253000,
        "value": 139.624,
        "_internal_originalTime": 1689840253000
    },
    {
        "time": 1689840254000,
        "value": 139.625,
        "_internal_originalTime": 1689840254000
    },
    {
        "time": 1689840255000,
        "value": 139.626,
        "_internal_originalTime": 1689840255000
    },
    {
        "time": 1689840257000,
        "value": 139.622,
        "_internal_originalTime": 1689840257000
    },
    {
        "time": 1689840258000,
        "value": 139.626,
        "_internal_originalTime": 1689840258000
    },
    {
        "time": 1689840259000,
        "value": 139.622,
        "_internal_originalTime": 1689840259000
    },
    {
        "time": 1689840261000,
        "value": 139.621,
        "_internal_originalTime": 1689840261000
    },
    {
        "time": 1689840262000,
        "value": 139.62,
        "_internal_originalTime": 1689840262000
    },
    {
        "time": 1689840263000,
        "value": 139.616,
        "_internal_originalTime": 1689840263000
    },
    {
        "time": 1689840265000,
        "value": 139.616,
        "_internal_originalTime": 1689840265000
    },
    {
        "time": 1689840267000,
        "value": 139.616,
        "_internal_originalTime": 1689840267000
    },
    {
        "time": 1689840268000,
        "value": 139.616,
        "_internal_originalTime": 1689840268000
    },
    {
        "time": 1689840269000,
        "value": 139.616,
        "_internal_originalTime": 1689840269000
    },
    {
        "time": 1689840271000,
        "value": 139.618,
        "_internal_originalTime": 1689840271000
    },
    {
        "time": 1689840272000,
        "value": 139.622,
        "_internal_originalTime": 1689840272000
    },
    {
        "time": 1689840273000,
        "value": 139.612,
        "_internal_originalTime": 1689840273000
    },
    {
        "time": 1689840274000,
        "value": 139.612,
        "_internal_originalTime": 1689840274000
    },
    {
        "time": 1689840275000,
        "value": 139.612,
        "_internal_originalTime": 1689840275000
    },
    {
        "time": 1689840276000,
        "value": 139.612,
        "_internal_originalTime": 1689840276000
    },
    {
        "time": 1689840277000,
        "value": 139.61,
        "_internal_originalTime": 1689840277000
    },
    {
        "time": 1689840278000,
        "value": 139.613,
        "_internal_originalTime": 1689840278000
    },
    {
        "time": 1689840279000,
        "value": 139.617,
        "_internal_originalTime": 1689840279000
    },
    {
        "time": 1689840280000,
        "value": 139.617,
        "_internal_originalTime": 1689840280000
    },
    {
        "time": 1689840281000,
        "value": 139.617,
        "_internal_originalTime": 1689840281000
    },
    {
        "time": 1689840283000,
        "value": 139.619,
        "_internal_originalTime": 1689840283000
    },
    {
        "time": 1689840284000,
        "value": 139.616,
        "_internal_originalTime": 1689840284000
    },
    {
        "time": 1689840285000,
        "value": 139.618,
        "_internal_originalTime": 1689840285000
    },
    {
        "time": 1689840286000,
        "value": 139.617,
        "_internal_originalTime": 1689840286000
    },
    {
        "time": 1689840288000,
        "value": 139.617,
        "_internal_originalTime": 1689840288000
    },
    {
        "time": 1689840289000,
        "value": 139.611,
        "_internal_originalTime": 1689840289000
    },
    {
        "time": 1689840290000,
        "value": 139.605,
        "_internal_originalTime": 1689840290000
    },
    {
        "time": 1689840291000,
        "value": 139.601,
        "_internal_originalTime": 1689840291000
    },
    {
        "time": 1689840292000,
        "value": 139.603,
        "_internal_originalTime": 1689840292000
    },
    {
        "time": 1689840294000,
        "value": 139.599,
        "_internal_originalTime": 1689840294000
    },
    {
        "time": 1689840295000,
        "value": 139.604,
        "_internal_originalTime": 1689840295000
    },
    {
        "time": 1689840297000,
        "value": 139.604,
        "_internal_originalTime": 1689840297000
    },
    {
        "time": 1689840299000,
        "value": 139.605,
        "_internal_originalTime": 1689840299000
    },
    {
        "time": 1689840301000,
        "value": 139.601,
        "_internal_originalTime": 1689840301000
    },
    {
        "time": 1689840302000,
        "value": 139.598,
        "_internal_originalTime": 1689840302000
    },
    {
        "time": 1689840304000,
        "value": 139.601,
        "_internal_originalTime": 1689840304000
    },
    {
        "time": 1689840305000,
        "value": 139.599,
        "_internal_originalTime": 1689840305000
    },
    {
        "time": 1689840306000,
        "value": 139.599,
        "_internal_originalTime": 1689840306000
    },
    {
        "time": 1689840307000,
        "value": 139.597,
        "_internal_originalTime": 1689840307000
    },
    {
        "time": 1689840308000,
        "value": 139.596,
        "_internal_originalTime": 1689840308000
    },
    {
        "time": 1689840309000,
        "value": 139.597,
        "_internal_originalTime": 1689840309000
    },
    {
        "time": 1689840311000,
        "value": 139.595,
        "_internal_originalTime": 1689840311000
    },
    {
        "time": 1689840312000,
        "value": 139.593,
        "_internal_originalTime": 1689840312000
    },
    {
        "time": 1689840313000,
        "value": 139.599,
        "_internal_originalTime": 1689840313000
    },
    {
        "time": 1689840315000,
        "value": 139.597,
        "_internal_originalTime": 1689840315000
    },
    {
        "time": 1689840317000,
        "value": 139.592,
        "_internal_originalTime": 1689840317000
    },
    {
        "time": 1689840319000,
        "value": 139.593,
        "_internal_originalTime": 1689840319000
    },
    {
        "time": 1689840320000,
        "value": 139.591,
        "_internal_originalTime": 1689840320000
    },
    {
        "time": 1689840321000,
        "value": 139.591,
        "_internal_originalTime": 1689840321000
    },
    {
        "time": 1689840322000,
        "value": 139.593,
        "_internal_originalTime": 1689840322000
    },
    {
        "time": 1689840323000,
        "value": 139.588,
        "_internal_originalTime": 1689840323000
    },
    {
        "time": 1689840324000,
        "value": 139.581,
        "_internal_originalTime": 1689840324000
    },
    {
        "time": 1689840325000,
        "value": 139.574,
        "_internal_originalTime": 1689840325000
    },
    {
        "time": 1689840326000,
        "value": 139.578,
        "_internal_originalTime": 1689840326000
    },
    {
        "time": 1689840328000,
        "value": 139.578,
        "_internal_originalTime": 1689840328000
    },
    {
        "time": 1689840329000,
        "value": 139.571,
        "_internal_originalTime": 1689840329000
    },
    {
        "time": 1689840330000,
        "value": 139.578,
        "_internal_originalTime": 1689840330000
    },
    {
        "time": 1689840331000,
        "value": 139.571,
        "_internal_originalTime": 1689840331000
    },
    {
        "time": 1689840333000,
        "value": 139.576,
        "_internal_originalTime": 1689840333000
    },
    {
        "time": 1689840334000,
        "value": 139.581,
        "_internal_originalTime": 1689840334000
    },
    {
        "time": 1689840335000,
        "value": 139.58,
        "_internal_originalTime": 1689840335000
    },
    {
        "time": 1689840336000,
        "value": 139.576,
        "_internal_originalTime": 1689840336000
    },
    {
        "time": 1689840338000,
        "value": 139.576,
        "_internal_originalTime": 1689840338000
    },
    {
        "time": 1689840339000,
        "value": 139.576,
        "_internal_originalTime": 1689840339000
    },
    {
        "time": 1689840340000,
        "value": 139.581,
        "_internal_originalTime": 1689840340000
    },
    {
        "time": 1689840341000,
        "value": 139.581,
        "_internal_originalTime": 1689840341000
    },
    {
        "time": 1689840342000,
        "value": 139.581,
        "_internal_originalTime": 1689840342000
    },
    {
        "time": 1689840343000,
        "value": 139.576,
        "_internal_originalTime": 1689840343000
    },
    {
        "time": 1689840344000,
        "value": 139.582,
        "_internal_originalTime": 1689840344000
    },
    {
        "time": 1689840346000,
        "value": 139.583,
        "_internal_originalTime": 1689840346000
    },
    {
        "time": 1689840348000,
        "value": 139.576,
        "_internal_originalTime": 1689840348000
    },
    {
        "time": 1689840349000,
        "value": 139.583,
        "_internal_originalTime": 1689840349000
    },
    {
        "time": 1689840350000,
        "value": 139.578,
        "_internal_originalTime": 1689840350000
    },
    {
        "time": 1689840351000,
        "value": 139.573,
        "_internal_originalTime": 1689840351000
    },
    {
        "time": 1689840356000,
        "value": 139.58,
        "_internal_originalTime": 1689840356000
    },
    {
        "time": 1689840357000,
        "value": 139.575,
        "_internal_originalTime": 1689840357000
    },
    {
        "time": 1689840359000,
        "value": 139.58,
        "_internal_originalTime": 1689840359000
    },
    {
        "time": 1689840360000,
        "value": 139.574,
        "_internal_originalTime": 1689840360000
    },
    {
        "time": 1689840361000,
        "value": 139.581,
        "_internal_originalTime": 1689840361000
    },
    {
        "time": 1689840362000,
        "value": 139.577,
        "_internal_originalTime": 1689840362000
    },
    {
        "time": 1689840363000,
        "value": 139.575,
        "_internal_originalTime": 1689840363000
    },
    {
        "time": 1689840364000,
        "value": 139.578,
        "_internal_originalTime": 1689840364000
    },
    {
        "time": 1689840365000,
        "value": 139.56,
        "_internal_originalTime": 1689840365000
    },
    {
        "time": 1689840366000,
        "value": 139.561,
        "_internal_originalTime": 1689840366000
    },
    {
        "time": 1689840368000,
        "value": 139.56,
        "_internal_originalTime": 1689840368000
    },
    {
        "time": 1689840369000,
        "value": 139.568,
        "_internal_originalTime": 1689840369000
    },
    {
        "time": 1689840370000,
        "value": 139.561,
        "_internal_originalTime": 1689840370000
    },
    {
        "time": 1689840372000,
        "value": 139.564,
        "_internal_originalTime": 1689840372000
    },
    {
        "time": 1689840373000,
        "value": 139.57,
        "_internal_originalTime": 1689840373000
    },
    {
        "time": 1689840374000,
        "value": 139.564,
        "_internal_originalTime": 1689840374000
    },
    {
        "time": 1689840375000,
        "value": 139.571,
        "_internal_originalTime": 1689840375000
    },
    {
        "time": 1689840376000,
        "value": 139.58,
        "_internal_originalTime": 1689840376000
    },
    {
        "time": 1689840378000,
        "value": 139.58,
        "_internal_originalTime": 1689840378000
    },
    {
        "time": 1689840381000,
        "value": 139.582,
        "_internal_originalTime": 1689840381000
    },
    {
        "time": 1689840383000,
        "value": 139.586,
        "_internal_originalTime": 1689840383000
    },
    {
        "time": 1689840384000,
        "value": 139.586,
        "_internal_originalTime": 1689840384000
    },
    {
        "time": 1689840385000,
        "value": 139.593,
        "_internal_originalTime": 1689840385000
    },
    {
        "time": 1689840387000,
        "value": 139.588,
        "_internal_originalTime": 1689840387000
    },
    {
        "time": 1689840388000,
        "value": 139.586,
        "_internal_originalTime": 1689840388000
    },
    {
        "time": 1689840389000,
        "value": 139.589,
        "_internal_originalTime": 1689840389000
    },
    {
        "time": 1689840391000,
        "value": 139.59,
        "_internal_originalTime": 1689840391000
    },
    {
        "time": 1689840392000,
        "value": 139.589,
        "_internal_originalTime": 1689840392000
    },
    {
        "time": 1689840394000,
        "value": 139.587,
        "_internal_originalTime": 1689840394000
    },
    {
        "time": 1689840395000,
        "value": 139.592,
        "_internal_originalTime": 1689840395000
    },
    {
        "time": 1689840396000,
        "value": 139.587,
        "_internal_originalTime": 1689840396000
    },
    {
        "time": 1689840397000,
        "value": 139.589,
        "_internal_originalTime": 1689840397000
    },
    {
        "time": 1689840399000,
        "value": 139.586,
        "_internal_originalTime": 1689840399000
    },
    {
        "time": 1689840401000,
        "value": 139.587,
        "_internal_originalTime": 1689840401000
    },
    {
        "time": 1689840402000,
        "value": 139.589,
        "_internal_originalTime": 1689840402000
    },
    {
        "time": 1689840405000,
        "value": 139.589,
        "_internal_originalTime": 1689840405000
    },
    {
        "time": 1689840406000,
        "value": 139.584,
        "_internal_originalTime": 1689840406000
    },
    {
        "time": 1689840410000,
        "value": 139.587,
        "_internal_originalTime": 1689840410000
    },
    {
        "time": 1689840411000,
        "value": 139.586,
        "_internal_originalTime": 1689840411000
    },
    {
        "time": 1689840412000,
        "value": 139.578,
        "_internal_originalTime": 1689840412000
    },
    {
        "time": 1689840413000,
        "value": 139.578,
        "_internal_originalTime": 1689840413000
    },
    {
        "time": 1689840414000,
        "value": 139.578,
        "_internal_originalTime": 1689840414000
    },
    {
        "time": 1689840416000,
        "value": 139.586,
        "_internal_originalTime": 1689840416000
    },
    {
        "time": 1689840418000,
        "value": 139.58,
        "_internal_originalTime": 1689840418000
    },
    {
        "time": 1689840420000,
        "value": 139.586,
        "_internal_originalTime": 1689840420000
    },
    {
        "time": 1689840421000,
        "value": 139.584,
        "_internal_originalTime": 1689840421000
    },
    {
        "time": 1689840422000,
        "value": 139.584,
        "_internal_originalTime": 1689840422000
    },
    {
        "time": 1689840423000,
        "value": 139.582,
        "_internal_originalTime": 1689840423000
    },
    {
        "time": 1689840424000,
        "value": 139.576,
        "_internal_originalTime": 1689840424000
    },
    {
        "time": 1689840425000,
        "value": 139.58,
        "_internal_originalTime": 1689840425000
    },
    {
        "time": 1689840426000,
        "value": 139.576,
        "_internal_originalTime": 1689840426000
    },
    {
        "time": 1689840427000,
        "value": 139.572,
        "_internal_originalTime": 1689840427000
    },
    {
        "time": 1689840428000,
        "value": 139.579,
        "_internal_originalTime": 1689840428000
    },
    {
        "time": 1689840430000,
        "value": 139.571,
        "_internal_originalTime": 1689840430000
    },
    {
        "time": 1689840431000,
        "value": 139.574,
        "_internal_originalTime": 1689840431000
    },
    {
        "time": 1689840432000,
        "value": 139.578,
        "_internal_originalTime": 1689840432000
    },
    {
        "time": 1689840433000,
        "value": 139.578,
        "_internal_originalTime": 1689840433000
    },
    {
        "time": 1689840435000,
        "value": 139.578,
        "_internal_originalTime": 1689840435000
    },
    {
        "time": 1689840437000,
        "value": 139.574,
        "_internal_originalTime": 1689840437000
    },
    {
        "time": 1689840438000,
        "value": 139.579,
        "_internal_originalTime": 1689840438000
    },
    {
        "time": 1689840439000,
        "value": 139.572,
        "_internal_originalTime": 1689840439000
    },
    {
        "time": 1689840441000,
        "value": 139.578,
        "_internal_originalTime": 1689840441000
    },
    {
        "time": 1689840442000,
        "value": 139.57,
        "_internal_originalTime": 1689840442000
    },
    {
        "time": 1689840443000,
        "value": 139.566,
        "_internal_originalTime": 1689840443000
    },
    {
        "time": 1689840445000,
        "value": 139.568,
        "_internal_originalTime": 1689840445000
    },
    {
        "time": 1689840446000,
        "value": 139.577,
        "_internal_originalTime": 1689840446000
    },
    {
        "time": 1689840447000,
        "value": 139.57,
        "_internal_originalTime": 1689840447000
    },
    {
        "time": 1689840448000,
        "value": 139.577,
        "_internal_originalTime": 1689840448000
    },
    {
        "time": 1689840449000,
        "value": 139.579,
        "_internal_originalTime": 1689840449000
    },
    {
        "time": 1689840451000,
        "value": 139.573,
        "_internal_originalTime": 1689840451000
    },
    {
        "time": 1689840452000,
        "value": 139.575,
        "_internal_originalTime": 1689840452000
    },
    {
        "time": 1689840453000,
        "value": 139.576,
        "_internal_originalTime": 1689840453000
    },
    {
        "time": 1689840454000,
        "value": 139.578,
        "_internal_originalTime": 1689840454000
    },
    {
        "time": 1689840455000,
        "value": 139.584,
        "_internal_originalTime": 1689840455000
    },
    {
        "time": 1689840457000,
        "value": 139.587,
        "_internal_originalTime": 1689840457000
    },
    {
        "time": 1689840458000,
        "value": 139.589,
        "_internal_originalTime": 1689840458000
    },
    {
        "time": 1689840459000,
        "value": 139.584,
        "_internal_originalTime": 1689840459000
    },
    {
        "time": 1689840460000,
        "value": 139.581,
        "_internal_originalTime": 1689840460000
    },
    {
        "time": 1689840462000,
        "value": 139.588,
        "_internal_originalTime": 1689840462000
    },
    {
        "time": 1689840464000,
        "value": 139.585,
        "_internal_originalTime": 1689840464000
    },
    {
        "time": 1689840465000,
        "value": 139.582,
        "_internal_originalTime": 1689840465000
    },
    {
        "time": 1689840466000,
        "value": 139.588,
        "_internal_originalTime": 1689840466000
    },
    {
        "time": 1689840468000,
        "value": 139.59,
        "_internal_originalTime": 1689840468000
    },
    {
        "time": 1689840469000,
        "value": 139.59,
        "_internal_originalTime": 1689840469000
    },
    {
        "time": 1689840470000,
        "value": 139.59,
        "_internal_originalTime": 1689840470000
    },
    {
        "time": 1689840471000,
        "value": 139.59,
        "_internal_originalTime": 1689840471000
    },
    {
        "time": 1689840472000,
        "value": 139.588,
        "_internal_originalTime": 1689840472000
    },
    {
        "time": 1689840474000,
        "value": 139.582,
        "_internal_originalTime": 1689840474000
    },
    {
        "time": 1689840475000,
        "value": 139.584,
        "_internal_originalTime": 1689840475000
    },
    {
        "time": 1689840476000,
        "value": 139.584,
        "_internal_originalTime": 1689840476000
    },
    {
        "time": 1689840477000,
        "value": 139.588,
        "_internal_originalTime": 1689840477000
    },
    {
        "time": 1689840479000,
        "value": 139.591,
        "_internal_originalTime": 1689840479000
    },
    {
        "time": 1689840480000,
        "value": 139.587,
        "_internal_originalTime": 1689840480000
    },
    {
        "time": 1689840482000,
        "value": 139.594,
        "_internal_originalTime": 1689840482000
    },
    {
        "time": 1689840484000,
        "value": 139.588,
        "_internal_originalTime": 1689840484000
    },
    {
        "time": 1689840485000,
        "value": 139.58,
        "_internal_originalTime": 1689840485000
    },
    {
        "time": 1689840486000,
        "value": 139.574,
        "_internal_originalTime": 1689840486000
    },
    {
        "time": 1689840488000,
        "value": 139.579,
        "_internal_originalTime": 1689840488000
    },
    {
        "time": 1689840489000,
        "value": 139.573,
        "_internal_originalTime": 1689840489000
    },
    {
        "time": 1689840490000,
        "value": 139.572,
        "_internal_originalTime": 1689840490000
    },
    {
        "time": 1689840491000,
        "value": 139.581,
        "_internal_originalTime": 1689840491000
    },
    {
        "time": 1689840492000,
        "value": 139.58,
        "_internal_originalTime": 1689840492000
    },
    {
        "time": 1689840494000,
        "value": 139.583,
        "_internal_originalTime": 1689840494000
    },
    {
        "time": 1689840495000,
        "value": 139.581,
        "_internal_originalTime": 1689840495000
    },
    {
        "time": 1689840496000,
        "value": 139.572,
        "_internal_originalTime": 1689840496000
    },
    {
        "time": 1689840497000,
        "value": 139.58,
        "_internal_originalTime": 1689840497000
    },
    {
        "time": 1689840498000,
        "value": 139.575,
        "_internal_originalTime": 1689840498000
    },
    {
        "time": 1689840499000,
        "value": 139.577,
        "_internal_originalTime": 1689840499000
    },
    {
        "time": 1689840501000,
        "value": 139.582,
        "_internal_originalTime": 1689840501000
    },
    {
        "time": 1689840503000,
        "value": 139.58,
        "_internal_originalTime": 1689840503000
    },
    {
        "time": 1689840504000,
        "value": 139.574,
        "_internal_originalTime": 1689840504000
    },
    {
        "time": 1689840505000,
        "value": 139.574,
        "_internal_originalTime": 1689840505000
    },
    {
        "time": 1689840506000,
        "value": 139.574,
        "_internal_originalTime": 1689840506000
    },
    {
        "time": 1689840508000,
        "value": 139.578,
        "_internal_originalTime": 1689840508000
    },
    {
        "time": 1689840510000,
        "value": 139.568,
        "_internal_originalTime": 1689840510000
    },
    {
        "time": 1689840512000,
        "value": 139.568,
        "_internal_originalTime": 1689840512000
    },
    {
        "time": 1689840513000,
        "value": 139.568,
        "_internal_originalTime": 1689840513000
    },
    {
        "time": 1689840515000,
        "value": 139.56,
        "_internal_originalTime": 1689840515000
    },
    {
        "time": 1689840516000,
        "value": 139.564,
        "_internal_originalTime": 1689840516000
    },
    {
        "time": 1689840517000,
        "value": 139.564,
        "_internal_originalTime": 1689840517000
    },
    {
        "time": 1689840518000,
        "value": 139.567,
        "_internal_originalTime": 1689840518000
    },
    {
        "time": 1689840520000,
        "value": 139.562,
        "_internal_originalTime": 1689840520000
    },
    {
        "time": 1689840521000,
        "value": 139.562,
        "_internal_originalTime": 1689840521000
    },
    {
        "time": 1689840523000,
        "value": 139.559,
        "_internal_originalTime": 1689840523000
    },
    {
        "time": 1689840525000,
        "value": 139.569,
        "_internal_originalTime": 1689840525000
    },
    {
        "time": 1689840526000,
        "value": 139.568,
        "_internal_originalTime": 1689840526000
    },
    {
        "time": 1689840527000,
        "value": 139.563,
        "_internal_originalTime": 1689840527000
    },
    {
        "time": 1689840528000,
        "value": 139.568,
        "_internal_originalTime": 1689840528000
    },
    {
        "time": 1689840530000,
        "value": 139.562,
        "_internal_originalTime": 1689840530000
    },
    {
        "time": 1689840531000,
        "value": 139.564,
        "_internal_originalTime": 1689840531000
    },
    {
        "time": 1689840532000,
        "value": 139.569,
        "_internal_originalTime": 1689840532000
    },
    {
        "time": 1689840533000,
        "value": 139.565,
        "_internal_originalTime": 1689840533000
    },
    {
        "time": 1689840534000,
        "value": 139.564,
        "_internal_originalTime": 1689840534000
    },
    {
        "time": 1689840536000,
        "value": 139.568,
        "_internal_originalTime": 1689840536000
    },
    {
        "time": 1689840537000,
        "value": 139.556,
        "_internal_originalTime": 1689840537000
    },
    {
        "time": 1689840538000,
        "value": 139.562,
        "_internal_originalTime": 1689840538000
    },
    {
        "time": 1689840540000,
        "value": 139.566,
        "_internal_originalTime": 1689840540000
    },
    {
        "time": 1689840541000,
        "value": 139.569,
        "_internal_originalTime": 1689840541000
    },
    {
        "time": 1689840542000,
        "value": 139.571,
        "_internal_originalTime": 1689840542000
    },
    {
        "time": 1689840544000,
        "value": 139.567,
        "_internal_originalTime": 1689840544000
    },
    {
        "time": 1689840545000,
        "value": 139.568,
        "_internal_originalTime": 1689840545000
    },
    {
        "time": 1689840546000,
        "value": 139.569,
        "_internal_originalTime": 1689840546000
    },
    {
        "time": 1689840547000,
        "value": 139.567,
        "_internal_originalTime": 1689840547000
    },
    {
        "time": 1689840548000,
        "value": 139.568,
        "_internal_originalTime": 1689840548000
    },
    {
        "time": 1689840550000,
        "value": 139.575,
        "_internal_originalTime": 1689840550000
    },
    {
        "time": 1689840551000,
        "value": 139.579,
        "_internal_originalTime": 1689840551000
    },
    {
        "time": 1689840552000,
        "value": 139.579,
        "_internal_originalTime": 1689840552000
    },
    {
        "time": 1689840554000,
        "value": 139.57,
        "_internal_originalTime": 1689840554000
    },
    {
        "time": 1689840555000,
        "value": 139.568,
        "_internal_originalTime": 1689840555000
    },
    {
        "time": 1689840556000,
        "value": 139.571,
        "_internal_originalTime": 1689840556000
    },
    {
        "time": 1689840557000,
        "value": 139.567,
        "_internal_originalTime": 1689840557000
    },
    {
        "time": 1689840559000,
        "value": 139.57,
        "_internal_originalTime": 1689840559000
    },
    {
        "time": 1689840560000,
        "value": 139.566,
        "_internal_originalTime": 1689840560000
    },
    {
        "time": 1689840561000,
        "value": 139.567,
        "_internal_originalTime": 1689840561000
    },
    {
        "time": 1689840562000,
        "value": 139.568,
        "_internal_originalTime": 1689840562000
    },
    {
        "time": 1689840564000,
        "value": 139.568,
        "_internal_originalTime": 1689840564000
    },
    {
        "time": 1689840565000,
        "value": 139.571,
        "_internal_originalTime": 1689840565000
    },
    {
        "time": 1689840566000,
        "value": 139.576,
        "_internal_originalTime": 1689840566000
    },
    {
        "time": 1689840567000,
        "value": 139.578,
        "_internal_originalTime": 1689840567000
    },
    {
        "time": 1689840568000,
        "value": 139.573,
        "_internal_originalTime": 1689840568000
    },
    {
        "time": 1689840569000,
        "value": 139.573,
        "_internal_originalTime": 1689840569000
    },
    {
        "time": 1689840570000,
        "value": 139.573,
        "_internal_originalTime": 1689840570000
    },
    {
        "time": 1689840571000,
        "value": 139.574,
        "_internal_originalTime": 1689840571000
    },
    {
        "time": 1689840573000,
        "value": 139.569,
        "_internal_originalTime": 1689840573000
    },
    {
        "time": 1689840574000,
        "value": 139.567,
        "_internal_originalTime": 1689840574000
    },
    {
        "time": 1689840575000,
        "value": 139.566,
        "_internal_originalTime": 1689840575000
    },
    {
        "time": 1689840576000,
        "value": 139.566,
        "_internal_originalTime": 1689840576000
    },
    {
        "time": 1689840577000,
        "value": 139.564,
        "_internal_originalTime": 1689840577000
    },
    {
        "time": 1689840578000,
        "value": 139.567,
        "_internal_originalTime": 1689840578000
    },
    {
        "time": 1689840580000,
        "value": 139.567,
        "_internal_originalTime": 1689840580000
    },
    {
        "time": 1689840582000,
        "value": 139.566,
        "_internal_originalTime": 1689840582000
    },
    {
        "time": 1689840583000,
        "value": 139.563,
        "_internal_originalTime": 1689840583000
    },
    {
        "time": 1689840586000,
        "value": 139.556,
        "_internal_originalTime": 1689840586000
    },
    {
        "time": 1689840587000,
        "value": 139.561,
        "_internal_originalTime": 1689840587000
    },
    {
        "time": 1689840588000,
        "value": 139.561,
        "_internal_originalTime": 1689840588000
    },
    {
        "time": 1689840589000,
        "value": 139.565,
        "_internal_originalTime": 1689840589000
    },
    {
        "time": 1689840590000,
        "value": 139.564,
        "_internal_originalTime": 1689840590000
    },
    {
        "time": 1689840592000,
        "value": 139.562,
        "_internal_originalTime": 1689840592000
    },
    {
        "time": 1689840593000,
        "value": 139.562,
        "_internal_originalTime": 1689840593000
    },
    {
        "time": 1689840594000,
        "value": 139.564,
        "_internal_originalTime": 1689840594000
    },
    {
        "time": 1689840595000,
        "value": 139.568,
        "_internal_originalTime": 1689840595000
    },
    {
        "time": 1689840596000,
        "value": 139.568,
        "_internal_originalTime": 1689840596000
    },
    {
        "time": 1689840599000,
        "value": 139.568,
        "_internal_originalTime": 1689840599000
    },
    {
        "time": 1689840600000,
        "value": 139.567,
        "_internal_originalTime": 1689840600000
    },
    {
        "time": 1689840601000,
        "value": 139.567,
        "_internal_originalTime": 1689840601000
    },
    {
        "time": 1689840603000,
        "value": 139.565,
        "_internal_originalTime": 1689840603000
    },
    {
        "time": 1689840604000,
        "value": 139.57,
        "_internal_originalTime": 1689840604000
    },
    {
        "time": 1689840605000,
        "value": 139.571,
        "_internal_originalTime": 1689840605000
    },
    {
        "time": 1689840606000,
        "value": 139.567,
        "_internal_originalTime": 1689840606000
    },
    {
        "time": 1689840607000,
        "value": 139.56,
        "_internal_originalTime": 1689840607000
    },
    {
        "time": 1689840608000,
        "value": 139.562,
        "_internal_originalTime": 1689840608000
    },
    {
        "time": 1689840610000,
        "value": 139.563,
        "_internal_originalTime": 1689840610000
    },
    {
        "time": 1689840611000,
        "value": 139.552,
        "_internal_originalTime": 1689840611000
    },
    {
        "time": 1689840612000,
        "value": 139.557,
        "_internal_originalTime": 1689840612000
    },
    {
        "time": 1689840613000,
        "value": 139.553,
        "_internal_originalTime": 1689840613000
    },
    {
        "time": 1689840614000,
        "value": 139.553,
        "_internal_originalTime": 1689840614000
    },
    {
        "time": 1689840616000,
        "value": 139.559,
        "_internal_originalTime": 1689840616000
    },
    {
        "time": 1689840617000,
        "value": 139.556,
        "_internal_originalTime": 1689840617000
    },
    {
        "time": 1689840618000,
        "value": 139.552,
        "_internal_originalTime": 1689840618000
    },
    {
        "time": 1689840620000,
        "value": 139.55,
        "_internal_originalTime": 1689840620000
    },
    {
        "time": 1689840621000,
        "value": 139.552,
        "_internal_originalTime": 1689840621000
    },
    {
        "time": 1689840622000,
        "value": 139.556,
        "_internal_originalTime": 1689840622000
    },
    {
        "time": 1689840624000,
        "value": 139.564,
        "_internal_originalTime": 1689840624000
    },
    {
        "time": 1689840625000,
        "value": 139.565,
        "_internal_originalTime": 1689840625000
    },
    {
        "time": 1689840626000,
        "value": 139.57,
        "_internal_originalTime": 1689840626000
    },
    {
        "time": 1689840627000,
        "value": 139.565,
        "_internal_originalTime": 1689840627000
    },
    {
        "time": 1689840628000,
        "value": 139.569,
        "_internal_originalTime": 1689840628000
    },
    {
        "time": 1689840629000,
        "value": 139.569,
        "_internal_originalTime": 1689840629000
    },
    {
        "time": 1689840631000,
        "value": 139.57,
        "_internal_originalTime": 1689840631000
    },
    {
        "time": 1689840632000,
        "value": 139.568,
        "_internal_originalTime": 1689840632000
    },
    {
        "time": 1689840633000,
        "value": 139.571,
        "_internal_originalTime": 1689840633000
    },
    {
        "time": 1689840634000,
        "value": 139.574,
        "_internal_originalTime": 1689840634000
    },
    {
        "time": 1689840636000,
        "value": 139.572,
        "_internal_originalTime": 1689840636000
    },
    {
        "time": 1689840637000,
        "value": 139.571,
        "_internal_originalTime": 1689840637000
    },
    {
        "time": 1689840638000,
        "value": 139.564,
        "_internal_originalTime": 1689840638000
    },
    {
        "time": 1689840639000,
        "value": 139.566,
        "_internal_originalTime": 1689840639000
    },
    {
        "time": 1689840641000,
        "value": 139.568,
        "_internal_originalTime": 1689840641000
    },
    {
        "time": 1689840642000,
        "value": 139.567,
        "_internal_originalTime": 1689840642000
    },
    {
        "time": 1689840643000,
        "value": 139.566,
        "_internal_originalTime": 1689840643000
    },
    {
        "time": 1689840644000,
        "value": 139.571,
        "_internal_originalTime": 1689840644000
    },
    {
        "time": 1689840646000,
        "value": 139.569,
        "_internal_originalTime": 1689840646000
    },
    {
        "time": 1689840649000,
        "value": 139.566,
        "_internal_originalTime": 1689840649000
    },
    {
        "time": 1689840651000,
        "value": 139.57,
        "_internal_originalTime": 1689840651000
    },
    {
        "time": 1689840652000,
        "value": 139.566,
        "_internal_originalTime": 1689840652000
    },
    {
        "time": 1689840653000,
        "value": 139.561,
        "_internal_originalTime": 1689840653000
    },
    {
        "time": 1689840654000,
        "value": 139.554,
        "_internal_originalTime": 1689840654000
    },
    {
        "time": 1689840655000,
        "value": 139.558,
        "_internal_originalTime": 1689840655000
    },
    {
        "time": 1689840657000,
        "value": 139.554,
        "_internal_originalTime": 1689840657000
    },
    {
        "time": 1689840658000,
        "value": 139.556,
        "_internal_originalTime": 1689840658000
    },
    {
        "time": 1689840660000,
        "value": 139.556,
        "_internal_originalTime": 1689840660000
    },
    {
        "time": 1689840661000,
        "value": 139.56,
        "_internal_originalTime": 1689840661000
    },
    {
        "time": 1689840662000,
        "value": 139.561,
        "_internal_originalTime": 1689840662000
    },
    {
        "time": 1689840665000,
        "value": 139.557,
        "_internal_originalTime": 1689840665000
    },
    {
        "time": 1689840666000,
        "value": 139.56,
        "_internal_originalTime": 1689840666000
    },
    {
        "time": 1689840668000,
        "value": 139.555,
        "_internal_originalTime": 1689840668000
    },
    {
        "time": 1689840669000,
        "value": 139.559,
        "_internal_originalTime": 1689840669000
    },
    {
        "time": 1689840670000,
        "value": 139.56,
        "_internal_originalTime": 1689840670000
    },
    {
        "time": 1689840671000,
        "value": 139.558,
        "_internal_originalTime": 1689840671000
    },
    {
        "time": 1689840673000,
        "value": 139.554,
        "_internal_originalTime": 1689840673000
    },
    {
        "time": 1689840674000,
        "value": 139.554,
        "_internal_originalTime": 1689840674000
    },
    {
        "time": 1689840675000,
        "value": 139.559,
        "_internal_originalTime": 1689840675000
    },
    {
        "time": 1689840677000,
        "value": 139.56,
        "_internal_originalTime": 1689840677000
    },
    {
        "time": 1689840678000,
        "value": 139.557,
        "_internal_originalTime": 1689840678000
    },
    {
        "time": 1689840683000,
        "value": 139.561,
        "_internal_originalTime": 1689840683000
    },
    {
        "time": 1689840685000,
        "value": 139.556,
        "_internal_originalTime": 1689840685000
    },
    {
        "time": 1689840687000,
        "value": 139.56,
        "_internal_originalTime": 1689840687000
    },
    {
        "time": 1689840688000,
        "value": 139.57,
        "_internal_originalTime": 1689840688000
    },
    {
        "time": 1689840690000,
        "value": 139.568,
        "_internal_originalTime": 1689840690000
    },
    {
        "time": 1689840691000,
        "value": 139.572,
        "_internal_originalTime": 1689840691000
    },
    {
        "time": 1689840692000,
        "value": 139.579,
        "_internal_originalTime": 1689840692000
    },
    {
        "time": 1689840694000,
        "value": 139.579,
        "_internal_originalTime": 1689840694000
    },
    {
        "time": 1689840696000,
        "value": 139.59,
        "_internal_originalTime": 1689840696000
    },
    {
        "time": 1689840697000,
        "value": 139.584,
        "_internal_originalTime": 1689840697000
    },
    {
        "time": 1689840698000,
        "value": 139.591,
        "_internal_originalTime": 1689840698000
    },
    {
        "time": 1689840700000,
        "value": 139.595,
        "_internal_originalTime": 1689840700000
    },
    {
        "time": 1689840701000,
        "value": 139.588,
        "_internal_originalTime": 1689840701000
    },
    {
        "time": 1689840702000,
        "value": 139.592,
        "_internal_originalTime": 1689840702000
    },
    {
        "time": 1689840704000,
        "value": 139.598,
        "_internal_originalTime": 1689840704000
    },
    {
        "time": 1689840705000,
        "value": 139.593,
        "_internal_originalTime": 1689840705000
    },
    {
        "time": 1689840707000,
        "value": 139.594,
        "_internal_originalTime": 1689840707000
    },
    {
        "time": 1689840708000,
        "value": 139.588,
        "_internal_originalTime": 1689840708000
    },
    {
        "time": 1689840710000,
        "value": 139.595,
        "_internal_originalTime": 1689840710000
    },
    {
        "time": 1689840711000,
        "value": 139.59,
        "_internal_originalTime": 1689840711000
    },
    {
        "time": 1689840713000,
        "value": 139.595,
        "_internal_originalTime": 1689840713000
    },
    {
        "time": 1689840715000,
        "value": 139.588,
        "_internal_originalTime": 1689840715000
    },
    {
        "time": 1689840716000,
        "value": 139.583,
        "_internal_originalTime": 1689840716000
    },
    {
        "time": 1689840717000,
        "value": 139.588,
        "_internal_originalTime": 1689840717000
    },
    {
        "time": 1689840718000,
        "value": 139.58,
        "_internal_originalTime": 1689840718000
    },
    {
        "time": 1689840720000,
        "value": 139.577,
        "_internal_originalTime": 1689840720000
    },
    {
        "time": 1689840721000,
        "value": 139.573,
        "_internal_originalTime": 1689840721000
    },
    {
        "time": 1689840722000,
        "value": 139.572,
        "_internal_originalTime": 1689840722000
    },
    {
        "time": 1689840724000,
        "value": 139.566,
        "_internal_originalTime": 1689840724000
    },
    {
        "time": 1689840725000,
        "value": 139.568,
        "_internal_originalTime": 1689840725000
    },
    {
        "time": 1689840726000,
        "value": 139.571,
        "_internal_originalTime": 1689840726000
    },
    {
        "time": 1689840728000,
        "value": 139.559,
        "_internal_originalTime": 1689840728000
    },
    {
        "time": 1689840729000,
        "value": 139.563,
        "_internal_originalTime": 1689840729000
    },
    {
        "time": 1689840731000,
        "value": 139.558,
        "_internal_originalTime": 1689840731000
    },
    {
        "time": 1689840732000,
        "value": 139.555,
        "_internal_originalTime": 1689840732000
    },
    {
        "time": 1689840733000,
        "value": 139.552,
        "_internal_originalTime": 1689840733000
    },
    {
        "time": 1689840735000,
        "value": 139.556,
        "_internal_originalTime": 1689840735000
    },
    {
        "time": 1689840736000,
        "value": 139.552,
        "_internal_originalTime": 1689840736000
    },
    {
        "time": 1689840737000,
        "value": 139.553,
        "_internal_originalTime": 1689840737000
    },
    {
        "time": 1689840739000,
        "value": 139.558,
        "_internal_originalTime": 1689840739000
    },
    {
        "time": 1689840741000,
        "value": 139.557,
        "_internal_originalTime": 1689840741000
    },
    {
        "time": 1689840743000,
        "value": 139.558,
        "_internal_originalTime": 1689840743000
    },
    {
        "time": 1689840744000,
        "value": 139.557,
        "_internal_originalTime": 1689840744000
    },
    {
        "time": 1689840745000,
        "value": 139.56,
        "_internal_originalTime": 1689840745000
    },
    {
        "time": 1689840746000,
        "value": 139.559,
        "_internal_originalTime": 1689840746000
    },
    {
        "time": 1689840747000,
        "value": 139.559,
        "_internal_originalTime": 1689840747000
    },
    {
        "time": 1689840750000,
        "value": 139.556,
        "_internal_originalTime": 1689840750000
    },
    {
        "time": 1689840753000,
        "value": 139.556,
        "_internal_originalTime": 1689840753000
    },
    {
        "time": 1689840754000,
        "value": 139.553,
        "_internal_originalTime": 1689840754000
    },
    {
        "time": 1689840755000,
        "value": 139.556,
        "_internal_originalTime": 1689840755000
    },
    {
        "time": 1689840757000,
        "value": 139.556,
        "_internal_originalTime": 1689840757000
    },
    {
        "time": 1689840758000,
        "value": 139.555,
        "_internal_originalTime": 1689840758000
    },
    {
        "time": 1689840759000,
        "value": 139.552,
        "_internal_originalTime": 1689840759000
    },
    {
        "time": 1689840760000,
        "value": 139.551,
        "_internal_originalTime": 1689840760000
    },
    {
        "time": 1689840762000,
        "value": 139.559,
        "_internal_originalTime": 1689840762000
    },
    {
        "time": 1689840763000,
        "value": 139.561,
        "_internal_originalTime": 1689840763000
    },
    {
        "time": 1689840764000,
        "value": 139.559,
        "_internal_originalTime": 1689840764000
    },
    {
        "time": 1689840766000,
        "value": 139.559,
        "_internal_originalTime": 1689840766000
    },
    {
        "time": 1689840769000,
        "value": 139.558,
        "_internal_originalTime": 1689840769000
    },
    {
        "time": 1689840770000,
        "value": 139.548,
        "_internal_originalTime": 1689840770000
    },
    {
        "time": 1689840772000,
        "value": 139.545,
        "_internal_originalTime": 1689840772000
    },
    {
        "time": 1689840773000,
        "value": 139.54,
        "_internal_originalTime": 1689840773000
    },
    {
        "time": 1689840774000,
        "value": 139.545,
        "_internal_originalTime": 1689840774000
    },
    {
        "time": 1689840776000,
        "value": 139.541,
        "_internal_originalTime": 1689840776000
    },
    {
        "time": 1689840777000,
        "value": 139.54,
        "_internal_originalTime": 1689840777000
    },
    {
        "time": 1689840779000,
        "value": 139.538,
        "_internal_originalTime": 1689840779000
    },
    {
        "time": 1689840780000,
        "value": 139.528,
        "_internal_originalTime": 1689840780000
    },
    {
        "time": 1689840781000,
        "value": 139.522,
        "_internal_originalTime": 1689840781000
    },
    {
        "time": 1689840782000,
        "value": 139.517,
        "_internal_originalTime": 1689840782000
    },
    {
        "time": 1689840783000,
        "value": 139.517,
        "_internal_originalTime": 1689840783000
    },
    {
        "time": 1689840784000,
        "value": 139.517,
        "_internal_originalTime": 1689840784000
    },
    {
        "time": 1689840785000,
        "value": 139.517,
        "_internal_originalTime": 1689840785000
    },
    {
        "time": 1689840786000,
        "value": 139.514,
        "_internal_originalTime": 1689840786000
    },
    {
        "time": 1689840788000,
        "value": 139.525,
        "_internal_originalTime": 1689840788000
    },
    {
        "time": 1689840789000,
        "value": 139.52,
        "_internal_originalTime": 1689840789000
    },
    {
        "time": 1689840790000,
        "value": 139.523,
        "_internal_originalTime": 1689840790000
    },
    {
        "time": 1689840791000,
        "value": 139.522,
        "_internal_originalTime": 1689840791000
    },
    {
        "time": 1689840792000,
        "value": 139.526,
        "_internal_originalTime": 1689840792000
    },
    {
        "time": 1689840794000,
        "value": 139.525,
        "_internal_originalTime": 1689840794000
    },
    {
        "time": 1689840795000,
        "value": 139.527,
        "_internal_originalTime": 1689840795000
    },
    {
        "time": 1689840797000,
        "value": 139.522,
        "_internal_originalTime": 1689840797000
    },
    {
        "time": 1689840799000,
        "value": 139.53,
        "_internal_originalTime": 1689840799000
    },
    {
        "time": 1689840800000,
        "value": 139.533,
        "_internal_originalTime": 1689840800000
    },
    {
        "time": 1689840802000,
        "value": 139.53,
        "_internal_originalTime": 1689840802000
    },
    {
        "time": 1689840803000,
        "value": 139.53,
        "_internal_originalTime": 1689840803000
    },
    {
        "time": 1689840804000,
        "value": 139.538,
        "_internal_originalTime": 1689840804000
    },
    {
        "time": 1689840805000,
        "value": 139.538,
        "_internal_originalTime": 1689840805000
    },
    {
        "time": 1689840806000,
        "value": 139.538,
        "_internal_originalTime": 1689840806000
    },
    {
        "time": 1689840808000,
        "value": 139.539,
        "_internal_originalTime": 1689840808000
    },
    {
        "time": 1689840809000,
        "value": 139.541,
        "_internal_originalTime": 1689840809000
    },
    {
        "time": 1689840810000,
        "value": 139.541,
        "_internal_originalTime": 1689840810000
    },
    {
        "time": 1689840813000,
        "value": 139.542,
        "_internal_originalTime": 1689840813000
    },
    {
        "time": 1689840815000,
        "value": 139.543,
        "_internal_originalTime": 1689840815000
    },
    {
        "time": 1689840816000,
        "value": 139.545,
        "_internal_originalTime": 1689840816000
    },
    {
        "time": 1689840818000,
        "value": 139.544,
        "_internal_originalTime": 1689840818000
    },
    {
        "time": 1689840819000,
        "value": 139.544,
        "_internal_originalTime": 1689840819000
    },
    {
        "time": 1689840822000,
        "value": 139.548,
        "_internal_originalTime": 1689840822000
    },
    {
        "time": 1689840823000,
        "value": 139.538,
        "_internal_originalTime": 1689840823000
    },
    {
        "time": 1689840824000,
        "value": 139.536,
        "_internal_originalTime": 1689840824000
    },
    {
        "time": 1689840825000,
        "value": 139.537,
        "_internal_originalTime": 1689840825000
    },
    {
        "time": 1689840826000,
        "value": 139.538,
        "_internal_originalTime": 1689840826000
    },
    {
        "time": 1689840827000,
        "value": 139.533,
        "_internal_originalTime": 1689840827000
    },
    {
        "time": 1689840828000,
        "value": 139.531,
        "_internal_originalTime": 1689840828000
    },
    {
        "time": 1689840830000,
        "value": 139.529,
        "_internal_originalTime": 1689840830000
    },
    {
        "time": 1689840831000,
        "value": 139.526,
        "_internal_originalTime": 1689840831000
    },
    {
        "time": 1689840832000,
        "value": 139.527,
        "_internal_originalTime": 1689840832000
    },
    {
        "time": 1689840833000,
        "value": 139.52,
        "_internal_originalTime": 1689840833000
    },
    {
        "time": 1689840835000,
        "value": 139.522,
        "_internal_originalTime": 1689840835000
    },
    {
        "time": 1689840836000,
        "value": 139.523,
        "_internal_originalTime": 1689840836000
    },
    {
        "time": 1689840838000,
        "value": 139.523,
        "_internal_originalTime": 1689840838000
    },
    {
        "time": 1689840839000,
        "value": 139.522,
        "_internal_originalTime": 1689840839000
    },
    {
        "time": 1689840840000,
        "value": 139.524,
        "_internal_originalTime": 1689840840000
    },
    {
        "time": 1689840841000,
        "value": 139.521,
        "_internal_originalTime": 1689840841000
    },
    {
        "time": 1689840842000,
        "value": 139.523,
        "_internal_originalTime": 1689840842000
    },
    {
        "time": 1689840843000,
        "value": 139.519,
        "_internal_originalTime": 1689840843000
    },
    {
        "time": 1689840845000,
        "value": 139.519,
        "_internal_originalTime": 1689840845000
    },
    {
        "time": 1689840847000,
        "value": 139.518,
        "_internal_originalTime": 1689840847000
    },
    {
        "time": 1689840848000,
        "value": 139.519,
        "_internal_originalTime": 1689840848000
    },
    {
        "time": 1689840849000,
        "value": 139.521,
        "_internal_originalTime": 1689840849000
    },
    {
        "time": 1689840851000,
        "value": 139.516,
        "_internal_originalTime": 1689840851000
    },
    {
        "time": 1689840853000,
        "value": 139.521,
        "_internal_originalTime": 1689840853000
    },
    {
        "time": 1689840854000,
        "value": 139.522,
        "_internal_originalTime": 1689840854000
    },
    {
        "time": 1689840855000,
        "value": 139.518,
        "_internal_originalTime": 1689840855000
    },
    {
        "time": 1689840856000,
        "value": 139.52,
        "_internal_originalTime": 1689840856000
    },
    {
        "time": 1689840858000,
        "value": 139.516,
        "_internal_originalTime": 1689840858000
    },
    {
        "time": 1689840860000,
        "value": 139.522,
        "_internal_originalTime": 1689840860000
    },
    {
        "time": 1689840861000,
        "value": 139.516,
        "_internal_originalTime": 1689840861000
    },
    {
        "time": 1689840862000,
        "value": 139.52,
        "_internal_originalTime": 1689840862000
    },
    {
        "time": 1689840863000,
        "value": 139.518,
        "_internal_originalTime": 1689840863000
    },
    {
        "time": 1689840864000,
        "value": 139.52,
        "_internal_originalTime": 1689840864000
    },
    {
        "time": 1689840866000,
        "value": 139.519,
        "_internal_originalTime": 1689840866000
    },
    {
        "time": 1689840867000,
        "value": 139.508,
        "_internal_originalTime": 1689840867000
    },
    {
        "time": 1689840868000,
        "value": 139.512,
        "_internal_originalTime": 1689840868000
    },
    {
        "time": 1689840870000,
        "value": 139.529,
        "_internal_originalTime": 1689840870000
    },
    {
        "time": 1689840871000,
        "value": 139.535,
        "_internal_originalTime": 1689840871000
    },
    {
        "time": 1689840872000,
        "value": 139.526,
        "_internal_originalTime": 1689840872000
    },
    {
        "time": 1689840873000,
        "value": 139.531,
        "_internal_originalTime": 1689840873000
    },
    {
        "time": 1689840874000,
        "value": 139.53,
        "_internal_originalTime": 1689840874000
    },
    {
        "time": 1689840876000,
        "value": 139.528,
        "_internal_originalTime": 1689840876000
    },
    {
        "time": 1689840878000,
        "value": 139.527,
        "_internal_originalTime": 1689840878000
    },
    {
        "time": 1689840879000,
        "value": 139.53,
        "_internal_originalTime": 1689840879000
    },
    {
        "time": 1689840881000,
        "value": 139.528,
        "_internal_originalTime": 1689840881000
    },
    {
        "time": 1689840882000,
        "value": 139.52,
        "_internal_originalTime": 1689840882000
    },
    {
        "time": 1689840883000,
        "value": 139.521,
        "_internal_originalTime": 1689840883000
    },
    {
        "time": 1689840884000,
        "value": 139.53,
        "_internal_originalTime": 1689840884000
    },
    {
        "time": 1689840886000,
        "value": 139.528,
        "_internal_originalTime": 1689840886000
    },
    {
        "time": 1689840887000,
        "value": 139.528,
        "_internal_originalTime": 1689840887000
    },
    {
        "time": 1689840888000,
        "value": 139.528,
        "_internal_originalTime": 1689840888000
    },
    {
        "time": 1689840889000,
        "value": 139.541,
        "_internal_originalTime": 1689840889000
    },
    {
        "time": 1689840890000,
        "value": 139.538,
        "_internal_originalTime": 1689840890000
    },
    {
        "time": 1689840892000,
        "value": 139.537,
        "_internal_originalTime": 1689840892000
    },
    {
        "time": 1689840893000,
        "value": 139.527,
        "_internal_originalTime": 1689840893000
    },
    {
        "time": 1689840894000,
        "value": 139.532,
        "_internal_originalTime": 1689840894000
    },
    {
        "time": 1689840895000,
        "value": 139.534,
        "_internal_originalTime": 1689840895000
    },
    {
        "time": 1689840896000,
        "value": 139.535,
        "_internal_originalTime": 1689840896000
    },
    {
        "time": 1689840897000,
        "value": 139.519,
        "_internal_originalTime": 1689840897000
    },
    {
        "time": 1689840898000,
        "value": 139.518,
        "_internal_originalTime": 1689840898000
    },
    {
        "time": 1689840900000,
        "value": 139.518,
        "_internal_originalTime": 1689840900000
    },
    {
        "time": 1689840901000,
        "value": 139.521,
        "_internal_originalTime": 1689840901000
    },
    {
        "time": 1689840902000,
        "value": 139.517,
        "_internal_originalTime": 1689840902000
    },
    {
        "time": 1689840904000,
        "value": 139.521,
        "_internal_originalTime": 1689840904000
    },
    {
        "time": 1689840905000,
        "value": 139.515,
        "_internal_originalTime": 1689840905000
    },
    {
        "time": 1689840907000,
        "value": 139.516,
        "_internal_originalTime": 1689840907000
    },
    {
        "time": 1689840908000,
        "value": 139.522,
        "_internal_originalTime": 1689840908000
    },
    {
        "time": 1689840909000,
        "value": 139.519,
        "_internal_originalTime": 1689840909000
    },
    {
        "time": 1689840910000,
        "value": 139.522,
        "_internal_originalTime": 1689840910000
    },
    {
        "time": 1689840911000,
        "value": 139.52,
        "_internal_originalTime": 1689840911000
    },
    {
        "time": 1689840912000,
        "value": 139.518,
        "_internal_originalTime": 1689840912000
    },
    {
        "time": 1689840914000,
        "value": 139.522,
        "_internal_originalTime": 1689840914000
    },
    {
        "time": 1689840915000,
        "value": 139.521,
        "_internal_originalTime": 1689840915000
    },
    {
        "time": 1689840916000,
        "value": 139.519,
        "_internal_originalTime": 1689840916000
    },
    {
        "time": 1689840917000,
        "value": 139.522,
        "_internal_originalTime": 1689840917000
    },
    {
        "time": 1689840918000,
        "value": 139.519,
        "_internal_originalTime": 1689840918000
    },
    {
        "time": 1689840920000,
        "value": 139.519,
        "_internal_originalTime": 1689840920000
    },
    {
        "time": 1689840922000,
        "value": 139.518,
        "_internal_originalTime": 1689840922000
    },
    {
        "time": 1689840923000,
        "value": 139.519,
        "_internal_originalTime": 1689840923000
    },
    {
        "time": 1689840924000,
        "value": 139.516,
        "_internal_originalTime": 1689840924000
    },
    {
        "time": 1689840926000,
        "value": 139.522,
        "_internal_originalTime": 1689840926000
    },
    {
        "time": 1689840927000,
        "value": 139.519,
        "_internal_originalTime": 1689840927000
    },
    {
        "time": 1689840928000,
        "value": 139.518,
        "_internal_originalTime": 1689840928000
    },
    {
        "time": 1689840929000,
        "value": 139.516,
        "_internal_originalTime": 1689840929000
    },
    {
        "time": 1689840930000,
        "value": 139.519,
        "_internal_originalTime": 1689840930000
    },
    {
        "time": 1689840931000,
        "value": 139.522,
        "_internal_originalTime": 1689840931000
    },
    {
        "time": 1689840932000,
        "value": 139.522,
        "_internal_originalTime": 1689840932000
    },
    {
        "time": 1689840938000,
        "value": 139.516,
        "_internal_originalTime": 1689840938000
    },
    {
        "time": 1689840940000,
        "value": 139.524,
        "_internal_originalTime": 1689840940000
    },
    {
        "time": 1689840941000,
        "value": 139.522,
        "_internal_originalTime": 1689840941000
    },
    {
        "time": 1689840942000,
        "value": 139.515,
        "_internal_originalTime": 1689840942000
    },
    {
        "time": 1689840944000,
        "value": 139.505,
        "_internal_originalTime": 1689840944000
    },
    {
        "time": 1689840945000,
        "value": 139.506,
        "_internal_originalTime": 1689840945000
    },
    {
        "time": 1689840946000,
        "value": 139.499,
        "_internal_originalTime": 1689840946000
    },
    {
        "time": 1689840947000,
        "value": 139.502,
        "_internal_originalTime": 1689840947000
    },
    {
        "time": 1689840948000,
        "value": 139.502,
        "_internal_originalTime": 1689840948000
    },
    {
        "time": 1689840950000,
        "value": 139.503,
        "_internal_originalTime": 1689840950000
    },
    {
        "time": 1689840951000,
        "value": 139.498,
        "_internal_originalTime": 1689840951000
    },
    {
        "time": 1689840953000,
        "value": 139.501,
        "_internal_originalTime": 1689840953000
    },
    {
        "time": 1689840954000,
        "value": 139.501,
        "_internal_originalTime": 1689840954000
    },
    {
        "time": 1689840956000,
        "value": 139.511,
        "_internal_originalTime": 1689840956000
    },
    {
        "time": 1689840957000,
        "value": 139.518,
        "_internal_originalTime": 1689840957000
    },
    {
        "time": 1689840958000,
        "value": 139.527,
        "_internal_originalTime": 1689840958000
    },
    {
        "time": 1689840960000,
        "value": 139.532,
        "_internal_originalTime": 1689840960000
    },
    {
        "time": 1689840961000,
        "value": 139.531,
        "_internal_originalTime": 1689840961000
    },
    {
        "time": 1689840962000,
        "value": 139.526,
        "_internal_originalTime": 1689840962000
    },
    {
        "time": 1689840964000,
        "value": 139.532,
        "_internal_originalTime": 1689840964000
    },
    {
        "time": 1689840965000,
        "value": 139.544,
        "_internal_originalTime": 1689840965000
    },
    {
        "time": 1689840966000,
        "value": 139.541,
        "_internal_originalTime": 1689840966000
    },
    {
        "time": 1689840967000,
        "value": 139.54,
        "_internal_originalTime": 1689840967000
    },
    {
        "time": 1689840968000,
        "value": 139.548,
        "_internal_originalTime": 1689840968000
    },
    {
        "time": 1689840970000,
        "value": 139.541,
        "_internal_originalTime": 1689840970000
    },
    {
        "time": 1689840971000,
        "value": 139.542,
        "_internal_originalTime": 1689840971000
    },
    {
        "time": 1689840972000,
        "value": 139.539,
        "_internal_originalTime": 1689840972000
    },
    {
        "time": 1689840974000,
        "value": 139.546,
        "_internal_originalTime": 1689840974000
    },
    {
        "time": 1689840975000,
        "value": 139.545,
        "_internal_originalTime": 1689840975000
    },
    {
        "time": 1689840976000,
        "value": 139.545,
        "_internal_originalTime": 1689840976000
    },
    {
        "time": 1689840977000,
        "value": 139.542,
        "_internal_originalTime": 1689840977000
    },
    {
        "time": 1689840979000,
        "value": 139.547,
        "_internal_originalTime": 1689840979000
    },
    {
        "time": 1689840981000,
        "value": 139.546,
        "_internal_originalTime": 1689840981000
    },
    {
        "time": 1689840982000,
        "value": 139.541,
        "_internal_originalTime": 1689840982000
    },
    {
        "time": 1689840984000,
        "value": 139.54,
        "_internal_originalTime": 1689840984000
    },
    {
        "time": 1689840985000,
        "value": 139.538,
        "_internal_originalTime": 1689840985000
    },
    {
        "time": 1689840987000,
        "value": 139.536,
        "_internal_originalTime": 1689840987000
    },
    {
        "time": 1689840988000,
        "value": 139.538,
        "_internal_originalTime": 1689840988000
    },
    {
        "time": 1689840989000,
        "value": 139.54,
        "_internal_originalTime": 1689840989000
    },
    {
        "time": 1689840991000,
        "value": 139.534,
        "_internal_originalTime": 1689840991000
    },
    {
        "time": 1689840992000,
        "value": 139.536,
        "_internal_originalTime": 1689840992000
    },
    {
        "time": 1689840993000,
        "value": 139.54,
        "_internal_originalTime": 1689840993000
    },
    {
        "time": 1689840994000,
        "value": 139.536,
        "_internal_originalTime": 1689840994000
    },
    {
        "time": 1689840995000,
        "value": 139.536,
        "_internal_originalTime": 1689840995000
    },
    {
        "time": 1689840997000,
        "value": 139.53,
        "_internal_originalTime": 1689840997000
    },
    {
        "time": 1689840998000,
        "value": 139.53,
        "_internal_originalTime": 1689840998000
    },
    {
        "time": 1689840999000,
        "value": 139.532,
        "_internal_originalTime": 1689840999000
    },
    {
        "time": 1689841001000,
        "value": 139.532,
        "_internal_originalTime": 1689841001000
    },
    {
        "time": 1689841002000,
        "value": 139.535,
        "_internal_originalTime": 1689841002000
    },
    {
        "time": 1689841003000,
        "value": 139.521,
        "_internal_originalTime": 1689841003000
    },
    {
        "time": 1689841004000,
        "value": 139.518,
        "_internal_originalTime": 1689841004000
    },
    {
        "time": 1689841006000,
        "value": 139.519,
        "_internal_originalTime": 1689841006000
    },
    {
        "time": 1689841007000,
        "value": 139.53,
        "_internal_originalTime": 1689841007000
    },
    {
        "time": 1689841008000,
        "value": 139.537,
        "_internal_originalTime": 1689841008000
    },
    {
        "time": 1689841010000,
        "value": 139.541,
        "_internal_originalTime": 1689841010000
    },
    {
        "time": 1689841011000,
        "value": 139.54,
        "_internal_originalTime": 1689841011000
    },
    {
        "time": 1689841012000,
        "value": 139.539,
        "_internal_originalTime": 1689841012000
    },
    {
        "time": 1689841013000,
        "value": 139.526,
        "_internal_originalTime": 1689841013000
    },
    {
        "time": 1689841015000,
        "value": 139.525,
        "_internal_originalTime": 1689841015000
    },
    {
        "time": 1689841016000,
        "value": 139.526,
        "_internal_originalTime": 1689841016000
    },
    {
        "time": 1689841018000,
        "value": 139.529,
        "_internal_originalTime": 1689841018000
    },
    {
        "time": 1689841019000,
        "value": 139.52,
        "_internal_originalTime": 1689841019000
    },
    {
        "time": 1689841020000,
        "value": 139.523,
        "_internal_originalTime": 1689841020000
    },
    {
        "time": 1689841022000,
        "value": 139.527,
        "_internal_originalTime": 1689841022000
    },
    {
        "time": 1689841023000,
        "value": 139.531,
        "_internal_originalTime": 1689841023000
    },
    {
        "time": 1689841024000,
        "value": 139.532,
        "_internal_originalTime": 1689841024000
    },
    {
        "time": 1689841025000,
        "value": 139.535,
        "_internal_originalTime": 1689841025000
    },
    {
        "time": 1689841027000,
        "value": 139.532,
        "_internal_originalTime": 1689841027000
    },
    {
        "time": 1689841028000,
        "value": 139.532,
        "_internal_originalTime": 1689841028000
    },
    {
        "time": 1689841029000,
        "value": 139.532,
        "_internal_originalTime": 1689841029000
    },
    {
        "time": 1689841031000,
        "value": 139.536,
        "_internal_originalTime": 1689841031000
    },
    {
        "time": 1689841032000,
        "value": 139.536,
        "_internal_originalTime": 1689841032000
    },
    {
        "time": 1689841035000,
        "value": 139.541,
        "_internal_originalTime": 1689841035000
    },
    {
        "time": 1689841036000,
        "value": 139.536,
        "_internal_originalTime": 1689841036000
    },
    {
        "time": 1689841038000,
        "value": 139.536,
        "_internal_originalTime": 1689841038000
    },
    {
        "time": 1689841039000,
        "value": 139.548,
        "_internal_originalTime": 1689841039000
    },
    {
        "time": 1689841040000,
        "value": 139.549,
        "_internal_originalTime": 1689841040000
    },
    {
        "time": 1689841042000,
        "value": 139.544,
        "_internal_originalTime": 1689841042000
    },
    {
        "time": 1689841043000,
        "value": 139.548,
        "_internal_originalTime": 1689841043000
    },
    {
        "time": 1689841044000,
        "value": 139.548,
        "_internal_originalTime": 1689841044000
    },
    {
        "time": 1689841046000,
        "value": 139.544,
        "_internal_originalTime": 1689841046000
    },
    {
        "time": 1689841047000,
        "value": 139.545,
        "_internal_originalTime": 1689841047000
    },
    {
        "time": 1689841050000,
        "value": 139.536,
        "_internal_originalTime": 1689841050000
    },
    {
        "time": 1689841051000,
        "value": 139.536,
        "_internal_originalTime": 1689841051000
    },
    {
        "time": 1689841053000,
        "value": 139.544,
        "_internal_originalTime": 1689841053000
    },
    {
        "time": 1689841054000,
        "value": 139.541,
        "_internal_originalTime": 1689841054000
    },
    {
        "time": 1689841055000,
        "value": 139.538,
        "_internal_originalTime": 1689841055000
    },
    {
        "time": 1689841058000,
        "value": 139.535,
        "_internal_originalTime": 1689841058000
    },
    {
        "time": 1689841059000,
        "value": 139.541,
        "_internal_originalTime": 1689841059000
    },
    {
        "time": 1689841060000,
        "value": 139.54,
        "_internal_originalTime": 1689841060000
    },
    {
        "time": 1689841061000,
        "value": 139.541,
        "_internal_originalTime": 1689841061000
    },
    {
        "time": 1689841063000,
        "value": 139.538,
        "_internal_originalTime": 1689841063000
    },
    {
        "time": 1689841064000,
        "value": 139.54,
        "_internal_originalTime": 1689841064000
    },
    {
        "time": 1689841066000,
        "value": 139.541,
        "_internal_originalTime": 1689841066000
    },
    {
        "time": 1689841067000,
        "value": 139.538,
        "_internal_originalTime": 1689841067000
    },
    {
        "time": 1689841069000,
        "value": 139.542,
        "_internal_originalTime": 1689841069000
    },
    {
        "time": 1689841070000,
        "value": 139.546,
        "_internal_originalTime": 1689841070000
    },
    {
        "time": 1689841071000,
        "value": 139.548,
        "_internal_originalTime": 1689841071000
    },
    {
        "time": 1689841072000,
        "value": 139.547,
        "_internal_originalTime": 1689841072000
    },
    {
        "time": 1689841073000,
        "value": 139.548,
        "_internal_originalTime": 1689841073000
    },
    {
        "time": 1689841074000,
        "value": 139.546,
        "_internal_originalTime": 1689841074000
    },
    {
        "time": 1689841075000,
        "value": 139.549,
        "_internal_originalTime": 1689841075000
    },
    {
        "time": 1689841076000,
        "value": 139.55,
        "_internal_originalTime": 1689841076000
    },
    {
        "time": 1689841078000,
        "value": 139.548,
        "_internal_originalTime": 1689841078000
    },
    {
        "time": 1689841080000,
        "value": 139.55,
        "_internal_originalTime": 1689841080000
    },
    {
        "time": 1689841081000,
        "value": 139.556,
        "_internal_originalTime": 1689841081000
    },
    {
        "time": 1689841082000,
        "value": 139.553,
        "_internal_originalTime": 1689841082000
    },
    {
        "time": 1689841083000,
        "value": 139.557,
        "_internal_originalTime": 1689841083000
    },
    {
        "time": 1689841084000,
        "value": 139.555,
        "_internal_originalTime": 1689841084000
    },
    {
        "time": 1689841086000,
        "value": 139.55,
        "_internal_originalTime": 1689841086000
    },
    {
        "time": 1689841087000,
        "value": 139.55,
        "_internal_originalTime": 1689841087000
    },
    {
        "time": 1689841088000,
        "value": 139.542,
        "_internal_originalTime": 1689841088000
    },
    {
        "time": 1689841089000,
        "value": 139.546,
        "_internal_originalTime": 1689841089000
    },
    {
        "time": 1689841090000,
        "value": 139.547,
        "_internal_originalTime": 1689841090000
    },
    {
        "time": 1689841091000,
        "value": 139.55,
        "_internal_originalTime": 1689841091000
    },
    {
        "time": 1689841093000,
        "value": 139.546,
        "_internal_originalTime": 1689841093000
    },
    {
        "time": 1689841095000,
        "value": 139.552,
        "_internal_originalTime": 1689841095000
    },
    {
        "time": 1689841096000,
        "value": 139.556,
        "_internal_originalTime": 1689841096000
    },
    {
        "time": 1689841097000,
        "value": 139.557,
        "_internal_originalTime": 1689841097000
    },
    {
        "time": 1689841098000,
        "value": 139.556,
        "_internal_originalTime": 1689841098000
    },
    {
        "time": 1689841102000,
        "value": 139.56,
        "_internal_originalTime": 1689841102000
    },
    {
        "time": 1689841103000,
        "value": 139.561,
        "_internal_originalTime": 1689841103000
    },
    {
        "time": 1689841105000,
        "value": 139.572,
        "_internal_originalTime": 1689841105000
    },
    {
        "time": 1689841106000,
        "value": 139.571,
        "_internal_originalTime": 1689841106000
    },
    {
        "time": 1689841107000,
        "value": 139.57,
        "_internal_originalTime": 1689841107000
    },
    {
        "time": 1689841108000,
        "value": 139.568,
        "_internal_originalTime": 1689841108000
    },
    {
        "time": 1689841110000,
        "value": 139.574,
        "_internal_originalTime": 1689841110000
    },
    {
        "time": 1689841111000,
        "value": 139.574,
        "_internal_originalTime": 1689841111000
    },
    {
        "time": 1689841112000,
        "value": 139.572,
        "_internal_originalTime": 1689841112000
    },
    {
        "time": 1689841114000,
        "value": 139.568,
        "_internal_originalTime": 1689841114000
    },
    {
        "time": 1689841115000,
        "value": 139.566,
        "_internal_originalTime": 1689841115000
    },
    {
        "time": 1689841117000,
        "value": 139.571,
        "_internal_originalTime": 1689841117000
    },
    {
        "time": 1689841118000,
        "value": 139.557,
        "_internal_originalTime": 1689841118000
    },
    {
        "time": 1689841119000,
        "value": 139.56,
        "_internal_originalTime": 1689841119000
    },
    {
        "time": 1689841120000,
        "value": 139.555,
        "_internal_originalTime": 1689841120000
    },
    {
        "time": 1689841122000,
        "value": 139.554,
        "_internal_originalTime": 1689841122000
    },
    {
        "time": 1689841123000,
        "value": 139.546,
        "_internal_originalTime": 1689841123000
    },
    {
        "time": 1689841124000,
        "value": 139.545,
        "_internal_originalTime": 1689841124000
    },
    {
        "time": 1689841125000,
        "value": 139.544,
        "_internal_originalTime": 1689841125000
    },
    {
        "time": 1689841127000,
        "value": 139.544,
        "_internal_originalTime": 1689841127000
    },
    {
        "time": 1689841128000,
        "value": 139.544,
        "_internal_originalTime": 1689841128000
    },
    {
        "time": 1689841131000,
        "value": 139.549,
        "_internal_originalTime": 1689841131000
    },
    {
        "time": 1689841132000,
        "value": 139.542,
        "_internal_originalTime": 1689841132000
    },
    {
        "time": 1689841133000,
        "value": 139.55,
        "_internal_originalTime": 1689841133000
    },
    {
        "time": 1689841134000,
        "value": 139.548,
        "_internal_originalTime": 1689841134000
    },
    {
        "time": 1689841135000,
        "value": 139.542,
        "_internal_originalTime": 1689841135000
    },
    {
        "time": 1689841138000,
        "value": 139.545,
        "_internal_originalTime": 1689841138000
    },
    {
        "time": 1689841139000,
        "value": 139.551,
        "_internal_originalTime": 1689841139000
    },
    {
        "time": 1689841141000,
        "value": 139.549,
        "_internal_originalTime": 1689841141000
    },
    {
        "time": 1689841142000,
        "value": 139.555,
        "_internal_originalTime": 1689841142000
    },
    {
        "time": 1689841144000,
        "value": 139.554,
        "_internal_originalTime": 1689841144000
    },
    {
        "time": 1689841145000,
        "value": 139.557,
        "_internal_originalTime": 1689841145000
    },
    {
        "time": 1689841146000,
        "value": 139.562,
        "_internal_originalTime": 1689841146000
    },
    {
        "time": 1689841147000,
        "value": 139.558,
        "_internal_originalTime": 1689841147000
    },
    {
        "time": 1689841148000,
        "value": 139.56,
        "_internal_originalTime": 1689841148000
    },
    {
        "time": 1689841150000,
        "value": 139.555,
        "_internal_originalTime": 1689841150000
    },
    {
        "time": 1689841151000,
        "value": 139.56,
        "_internal_originalTime": 1689841151000
    },
    {
        "time": 1689841153000,
        "value": 139.558,
        "_internal_originalTime": 1689841153000
    },
    {
        "time": 1689841154000,
        "value": 139.559,
        "_internal_originalTime": 1689841154000
    },
    {
        "time": 1689841155000,
        "value": 139.559,
        "_internal_originalTime": 1689841155000
    },
    {
        "time": 1689841156000,
        "value": 139.558,
        "_internal_originalTime": 1689841156000
    },
    {
        "time": 1689841157000,
        "value": 139.555,
        "_internal_originalTime": 1689841157000
    },
    {
        "time": 1689841158000,
        "value": 139.559,
        "_internal_originalTime": 1689841158000
    },
    {
        "time": 1689841159000,
        "value": 139.558,
        "_internal_originalTime": 1689841159000
    },
    {
        "time": 1689841161000,
        "value": 139.547,
        "_internal_originalTime": 1689841161000
    },
    {
        "time": 1689841162000,
        "value": 139.548,
        "_internal_originalTime": 1689841162000
    },
    {
        "time": 1689841164000,
        "value": 139.549,
        "_internal_originalTime": 1689841164000
    },
    {
        "time": 1689841166000,
        "value": 139.548,
        "_internal_originalTime": 1689841166000
    },
    {
        "time": 1689841167000,
        "value": 139.545,
        "_internal_originalTime": 1689841167000
    },
    {
        "time": 1689841169000,
        "value": 139.544,
        "_internal_originalTime": 1689841169000
    },
    {
        "time": 1689841170000,
        "value": 139.545,
        "_internal_originalTime": 1689841170000
    },
    {
        "time": 1689841171000,
        "value": 139.544,
        "_internal_originalTime": 1689841171000
    },
    {
        "time": 1689841172000,
        "value": 139.548,
        "_internal_originalTime": 1689841172000
    },
    {
        "time": 1689841173000,
        "value": 139.55,
        "_internal_originalTime": 1689841173000
    },
    {
        "time": 1689841174000,
        "value": 139.546,
        "_internal_originalTime": 1689841174000
    },
    {
        "time": 1689841176000,
        "value": 139.547,
        "_internal_originalTime": 1689841176000
    },
    {
        "time": 1689841177000,
        "value": 139.554,
        "_internal_originalTime": 1689841177000
    },
    {
        "time": 1689841178000,
        "value": 139.546,
        "_internal_originalTime": 1689841178000
    },
    {
        "time": 1689841179000,
        "value": 139.548,
        "_internal_originalTime": 1689841179000
    },
    {
        "time": 1689841181000,
        "value": 139.555,
        "_internal_originalTime": 1689841181000
    },
    {
        "time": 1689841182000,
        "value": 139.555,
        "_internal_originalTime": 1689841182000
    },
    {
        "time": 1689841183000,
        "value": 139.559,
        "_internal_originalTime": 1689841183000
    },
    {
        "time": 1689841184000,
        "value": 139.559,
        "_internal_originalTime": 1689841184000
    },
    {
        "time": 1689841186000,
        "value": 139.554,
        "_internal_originalTime": 1689841186000
    },
    {
        "time": 1689841187000,
        "value": 139.555,
        "_internal_originalTime": 1689841187000
    },
    {
        "time": 1689841188000,
        "value": 139.558,
        "_internal_originalTime": 1689841188000
    },
    {
        "time": 1689841190000,
        "value": 139.56,
        "_internal_originalTime": 1689841190000
    },
    {
        "time": 1689841191000,
        "value": 139.557,
        "_internal_originalTime": 1689841191000
    },
    {
        "time": 1689841192000,
        "value": 139.559,
        "_internal_originalTime": 1689841192000
    },
    {
        "time": 1689841193000,
        "value": 139.556,
        "_internal_originalTime": 1689841193000
    },
    {
        "time": 1689841194000,
        "value": 139.556,
        "_internal_originalTime": 1689841194000
    },
    {
        "time": 1689841195000,
        "value": 139.554,
        "_internal_originalTime": 1689841195000
    },
    {
        "time": 1689841197000,
        "value": 139.556,
        "_internal_originalTime": 1689841197000
    },
    {
        "time": 1689841199000,
        "value": 139.559,
        "_internal_originalTime": 1689841199000
    },
    {
        "time": 1689841200000,
        "value": 139.554,
        "_internal_originalTime": 1689841200000
    },
    {
        "time": 1689841201000,
        "value": 139.553,
        "_internal_originalTime": 1689841201000
    },
    {
        "time": 1689841203000,
        "value": 139.552,
        "_internal_originalTime": 1689841203000
    },
    {
        "time": 1689841206000,
        "value": 139.556,
        "_internal_originalTime": 1689841206000
    },
    {
        "time": 1689841207000,
        "value": 139.552,
        "_internal_originalTime": 1689841207000
    },
    {
        "time": 1689841209000,
        "value": 139.556,
        "_internal_originalTime": 1689841209000
    },
    {
        "time": 1689841210000,
        "value": 139.552,
        "_internal_originalTime": 1689841210000
    },
    {
        "time": 1689841211000,
        "value": 139.551,
        "_internal_originalTime": 1689841211000
    },
    {
        "time": 1689841212000,
        "value": 139.551,
        "_internal_originalTime": 1689841212000
    },
    {
        "time": 1689841213000,
        "value": 139.549,
        "_internal_originalTime": 1689841213000
    },
    {
        "time": 1689841215000,
        "value": 139.561,
        "_internal_originalTime": 1689841215000
    },
    {
        "time": 1689841216000,
        "value": 139.562,
        "_internal_originalTime": 1689841216000
    },
    {
        "time": 1689841218000,
        "value": 139.564,
        "_internal_originalTime": 1689841218000
    },
    {
        "time": 1689841220000,
        "value": 139.564,
        "_internal_originalTime": 1689841220000
    },
    {
        "time": 1689841221000,
        "value": 139.568,
        "_internal_originalTime": 1689841221000
    },
    {
        "time": 1689841223000,
        "value": 139.571,
        "_internal_originalTime": 1689841223000
    },
    {
        "time": 1689841226000,
        "value": 139.568,
        "_internal_originalTime": 1689841226000
    },
    {
        "time": 1689841227000,
        "value": 139.569,
        "_internal_originalTime": 1689841227000
    },
    {
        "time": 1689841230000,
        "value": 139.567,
        "_internal_originalTime": 1689841230000
    },
    {
        "time": 1689841231000,
        "value": 139.569,
        "_internal_originalTime": 1689841231000
    },
    {
        "time": 1689841232000,
        "value": 139.566,
        "_internal_originalTime": 1689841232000
    },
    {
        "time": 1689841233000,
        "value": 139.571,
        "_internal_originalTime": 1689841233000
    },
    {
        "time": 1689841235000,
        "value": 139.578,
        "_internal_originalTime": 1689841235000
    },
    {
        "time": 1689841236000,
        "value": 139.583,
        "_internal_originalTime": 1689841236000
    },
    {
        "time": 1689841237000,
        "value": 139.588,
        "_internal_originalTime": 1689841237000
    },
    {
        "time": 1689841238000,
        "value": 139.599,
        "_internal_originalTime": 1689841238000
    },
    {
        "time": 1689841239000,
        "value": 139.595,
        "_internal_originalTime": 1689841239000
    },
    {
        "time": 1689841241000,
        "value": 139.598,
        "_internal_originalTime": 1689841241000
    },
    {
        "time": 1689841242000,
        "value": 139.593,
        "_internal_originalTime": 1689841242000
    },
    {
        "time": 1689841243000,
        "value": 139.599,
        "_internal_originalTime": 1689841243000
    },
    {
        "time": 1689841244000,
        "value": 139.598,
        "_internal_originalTime": 1689841244000
    },
    {
        "time": 1689841245000,
        "value": 139.599,
        "_internal_originalTime": 1689841245000
    },
    {
        "time": 1689841246000,
        "value": 139.599,
        "_internal_originalTime": 1689841246000
    },
    {
        "time": 1689841247000,
        "value": 139.594,
        "_internal_originalTime": 1689841247000
    },
    {
        "time": 1689841248000,
        "value": 139.594,
        "_internal_originalTime": 1689841248000
    },
    {
        "time": 1689841249000,
        "value": 139.6,
        "_internal_originalTime": 1689841249000
    },
    {
        "time": 1689841250000,
        "value": 139.598,
        "_internal_originalTime": 1689841250000
    },
    {
        "time": 1689841251000,
        "value": 139.596,
        "_internal_originalTime": 1689841251000
    },
    {
        "time": 1689841252000,
        "value": 139.597,
        "_internal_originalTime": 1689841252000
    },
    {
        "time": 1689841254000,
        "value": 139.586,
        "_internal_originalTime": 1689841254000
    },
    {
        "time": 1689841255000,
        "value": 139.587,
        "_internal_originalTime": 1689841255000
    },
    {
        "time": 1689841256000,
        "value": 139.588,
        "_internal_originalTime": 1689841256000
    },
    {
        "time": 1689841257000,
        "value": 139.589,
        "_internal_originalTime": 1689841257000
    },
    {
        "time": 1689841258000,
        "value": 139.587,
        "_internal_originalTime": 1689841258000
    },
    {
        "time": 1689841259000,
        "value": 139.581,
        "_internal_originalTime": 1689841259000
    },
    {
        "time": 1689841260000,
        "value": 139.58,
        "_internal_originalTime": 1689841260000
    },
    {
        "time": 1689841261000,
        "value": 139.584,
        "_internal_originalTime": 1689841261000
    },
    {
        "time": 1689841262000,
        "value": 139.585,
        "_internal_originalTime": 1689841262000
    },
    {
        "time": 1689841264000,
        "value": 139.578,
        "_internal_originalTime": 1689841264000
    },
    {
        "time": 1689841265000,
        "value": 139.568,
        "_internal_originalTime": 1689841265000
    },
    {
        "time": 1689841266000,
        "value": 139.568,
        "_internal_originalTime": 1689841266000
    },
    {
        "time": 1689841268000,
        "value": 139.56,
        "_internal_originalTime": 1689841268000
    },
    {
        "time": 1689841269000,
        "value": 139.563,
        "_internal_originalTime": 1689841269000
    },
    {
        "time": 1689841270000,
        "value": 139.563,
        "_internal_originalTime": 1689841270000
    },
    {
        "time": 1689841271000,
        "value": 139.567,
        "_internal_originalTime": 1689841271000
    },
    {
        "time": 1689841273000,
        "value": 139.572,
        "_internal_originalTime": 1689841273000
    },
    {
        "time": 1689841275000,
        "value": 139.579,
        "_internal_originalTime": 1689841275000
    },
    {
        "time": 1689841276000,
        "value": 139.582,
        "_internal_originalTime": 1689841276000
    },
    {
        "time": 1689841277000,
        "value": 139.581,
        "_internal_originalTime": 1689841277000
    },
    {
        "time": 1689841279000,
        "value": 139.59,
        "_internal_originalTime": 1689841279000
    },
    {
        "time": 1689841280000,
        "value": 139.59,
        "_internal_originalTime": 1689841280000
    },
    {
        "time": 1689841281000,
        "value": 139.586,
        "_internal_originalTime": 1689841281000
    },
    {
        "time": 1689841283000,
        "value": 139.592,
        "_internal_originalTime": 1689841283000
    },
    {
        "time": 1689841284000,
        "value": 139.591,
        "_internal_originalTime": 1689841284000
    },
    {
        "time": 1689841286000,
        "value": 139.578,
        "_internal_originalTime": 1689841286000
    },
    {
        "time": 1689841287000,
        "value": 139.573,
        "_internal_originalTime": 1689841287000
    },
    {
        "time": 1689841288000,
        "value": 139.575,
        "_internal_originalTime": 1689841288000
    },
    {
        "time": 1689841289000,
        "value": 139.576,
        "_internal_originalTime": 1689841289000
    },
    {
        "time": 1689841290000,
        "value": 139.575,
        "_internal_originalTime": 1689841290000
    },
    {
        "time": 1689841292000,
        "value": 139.572,
        "_internal_originalTime": 1689841292000
    },
    {
        "time": 1689841294000,
        "value": 139.574,
        "_internal_originalTime": 1689841294000
    },
    {
        "time": 1689841296000,
        "value": 139.576,
        "_internal_originalTime": 1689841296000
    },
    {
        "time": 1689841297000,
        "value": 139.577,
        "_internal_originalTime": 1689841297000
    },
    {
        "time": 1689841298000,
        "value": 139.57,
        "_internal_originalTime": 1689841298000
    },
    {
        "time": 1689841299000,
        "value": 139.575,
        "_internal_originalTime": 1689841299000
    },
    {
        "time": 1689841301000,
        "value": 139.573,
        "_internal_originalTime": 1689841301000
    },
    {
        "time": 1689841302000,
        "value": 139.574,
        "_internal_originalTime": 1689841302000
    },
    {
        "time": 1689841303000,
        "value": 139.554,
        "_internal_originalTime": 1689841303000
    },
    {
        "time": 1689841304000,
        "value": 139.557,
        "_internal_originalTime": 1689841304000
    },
    {
        "time": 1689841305000,
        "value": 139.547,
        "_internal_originalTime": 1689841305000
    },
    {
        "time": 1689841306000,
        "value": 139.551,
        "_internal_originalTime": 1689841306000
    },
    {
        "time": 1689841307000,
        "value": 139.549,
        "_internal_originalTime": 1689841307000
    },
    {
        "time": 1689841308000,
        "value": 139.552,
        "_internal_originalTime": 1689841308000
    },
    {
        "time": 1689841309000,
        "value": 139.55,
        "_internal_originalTime": 1689841309000
    },
    {
        "time": 1689841311000,
        "value": 139.547,
        "_internal_originalTime": 1689841311000
    },
    {
        "time": 1689841312000,
        "value": 139.551,
        "_internal_originalTime": 1689841312000
    },
    {
        "time": 1689841313000,
        "value": 139.551,
        "_internal_originalTime": 1689841313000
    },
    {
        "time": 1689841315000,
        "value": 139.55,
        "_internal_originalTime": 1689841315000
    },
    {
        "time": 1689841316000,
        "value": 139.534,
        "_internal_originalTime": 1689841316000
    },
    {
        "time": 1689841317000,
        "value": 139.541,
        "_internal_originalTime": 1689841317000
    },
    {
        "time": 1689841319000,
        "value": 139.54,
        "_internal_originalTime": 1689841319000
    },
    {
        "time": 1689841320000,
        "value": 139.536,
        "_internal_originalTime": 1689841320000
    },
    {
        "time": 1689841322000,
        "value": 139.539,
        "_internal_originalTime": 1689841322000
    },
    {
        "time": 1689841324000,
        "value": 139.537,
        "_internal_originalTime": 1689841324000
    },
    {
        "time": 1689841326000,
        "value": 139.54,
        "_internal_originalTime": 1689841326000
    },
    {
        "time": 1689841327000,
        "value": 139.517,
        "_internal_originalTime": 1689841327000
    },
    {
        "time": 1689841328000,
        "value": 139.525,
        "_internal_originalTime": 1689841328000
    },
    {
        "time": 1689841329000,
        "value": 139.52,
        "_internal_originalTime": 1689841329000
    },
    {
        "time": 1689841331000,
        "value": 139.53,
        "_internal_originalTime": 1689841331000
    },
    {
        "time": 1689841332000,
        "value": 139.535,
        "_internal_originalTime": 1689841332000
    },
    {
        "time": 1689841335000,
        "value": 139.533,
        "_internal_originalTime": 1689841335000
    },
    {
        "time": 1689841336000,
        "value": 139.535,
        "_internal_originalTime": 1689841336000
    },
    {
        "time": 1689841337000,
        "value": 139.536,
        "_internal_originalTime": 1689841337000
    },
    {
        "time": 1689841338000,
        "value": 139.536,
        "_internal_originalTime": 1689841338000
    },
    {
        "time": 1689841339000,
        "value": 139.537,
        "_internal_originalTime": 1689841339000
    },
    {
        "time": 1689841341000,
        "value": 139.535,
        "_internal_originalTime": 1689841341000
    },
    {
        "time": 1689841343000,
        "value": 139.524,
        "_internal_originalTime": 1689841343000
    },
    {
        "time": 1689841345000,
        "value": 139.528,
        "_internal_originalTime": 1689841345000
    },
    {
        "time": 1689841346000,
        "value": 139.53,
        "_internal_originalTime": 1689841346000
    },
    {
        "time": 1689841348000,
        "value": 139.51,
        "_internal_originalTime": 1689841348000
    },
    {
        "time": 1689841350000,
        "value": 139.52,
        "_internal_originalTime": 1689841350000
    },
    {
        "time": 1689841351000,
        "value": 139.516,
        "_internal_originalTime": 1689841351000
    },
    {
        "time": 1689841352000,
        "value": 139.521,
        "_internal_originalTime": 1689841352000
    },
    {
        "time": 1689841354000,
        "value": 139.525,
        "_internal_originalTime": 1689841354000
    },
    {
        "time": 1689841355000,
        "value": 139.52,
        "_internal_originalTime": 1689841355000
    },
    {
        "time": 1689841356000,
        "value": 139.526,
        "_internal_originalTime": 1689841356000
    },
    {
        "time": 1689841357000,
        "value": 139.523,
        "_internal_originalTime": 1689841357000
    },
    {
        "time": 1689841358000,
        "value": 139.526,
        "_internal_originalTime": 1689841358000
    },
    {
        "time": 1689841359000,
        "value": 139.529,
        "_internal_originalTime": 1689841359000
    },
    {
        "time": 1689841361000,
        "value": 139.527,
        "_internal_originalTime": 1689841361000
    },
    {
        "time": 1689841362000,
        "value": 139.531,
        "_internal_originalTime": 1689841362000
    },
    {
        "time": 1689841364000,
        "value": 139.524,
        "_internal_originalTime": 1689841364000
    },
    {
        "time": 1689841365000,
        "value": 139.521,
        "_internal_originalTime": 1689841365000
    },
    {
        "time": 1689841366000,
        "value": 139.518,
        "_internal_originalTime": 1689841366000
    },
    {
        "time": 1689841367000,
        "value": 139.529,
        "_internal_originalTime": 1689841367000
    },
    {
        "time": 1689841369000,
        "value": 139.528,
        "_internal_originalTime": 1689841369000
    },
    {
        "time": 1689841370000,
        "value": 139.525,
        "_internal_originalTime": 1689841370000
    },
    {
        "time": 1689841371000,
        "value": 139.52,
        "_internal_originalTime": 1689841371000
    },
    {
        "time": 1689841373000,
        "value": 139.52,
        "_internal_originalTime": 1689841373000
    },
    {
        "time": 1689841374000,
        "value": 139.518,
        "_internal_originalTime": 1689841374000
    },
    {
        "time": 1689841375000,
        "value": 139.52,
        "_internal_originalTime": 1689841375000
    },
    {
        "time": 1689841376000,
        "value": 139.516,
        "_internal_originalTime": 1689841376000
    },
    {
        "time": 1689841377000,
        "value": 139.519,
        "_internal_originalTime": 1689841377000
    },
    {
        "time": 1689841378000,
        "value": 139.519,
        "_internal_originalTime": 1689841378000
    },
    {
        "time": 1689841380000,
        "value": 139.519,
        "_internal_originalTime": 1689841380000
    },
    {
        "time": 1689841381000,
        "value": 139.525,
        "_internal_originalTime": 1689841381000
    },
    {
        "time": 1689841382000,
        "value": 139.522,
        "_internal_originalTime": 1689841382000
    },
    {
        "time": 1689841383000,
        "value": 139.524,
        "_internal_originalTime": 1689841383000
    },
    {
        "time": 1689841384000,
        "value": 139.524,
        "_internal_originalTime": 1689841384000
    },
    {
        "time": 1689841386000,
        "value": 139.53,
        "_internal_originalTime": 1689841386000
    },
    {
        "time": 1689841387000,
        "value": 139.521,
        "_internal_originalTime": 1689841387000
    },
    {
        "time": 1689841388000,
        "value": 139.514,
        "_internal_originalTime": 1689841388000
    },
    {
        "time": 1689841389000,
        "value": 139.516,
        "_internal_originalTime": 1689841389000
    },
    {
        "time": 1689841390000,
        "value": 139.519,
        "_internal_originalTime": 1689841390000
    },
    {
        "time": 1689841391000,
        "value": 139.515,
        "_internal_originalTime": 1689841391000
    },
    {
        "time": 1689841393000,
        "value": 139.52,
        "_internal_originalTime": 1689841393000
    },
    {
        "time": 1689841395000,
        "value": 139.52,
        "_internal_originalTime": 1689841395000
    },
    {
        "time": 1689841397000,
        "value": 139.519,
        "_internal_originalTime": 1689841397000
    },
    {
        "time": 1689841398000,
        "value": 139.518,
        "_internal_originalTime": 1689841398000
    },
    {
        "time": 1689841399000,
        "value": 139.514,
        "_internal_originalTime": 1689841399000
    },
    {
        "time": 1689841401000,
        "value": 139.522,
        "_internal_originalTime": 1689841401000
    },
    {
        "time": 1689841402000,
        "value": 139.522,
        "_internal_originalTime": 1689841402000
    },
    {
        "time": 1689841403000,
        "value": 139.516,
        "_internal_originalTime": 1689841403000
    },
    {
        "time": 1689841407000,
        "value": 139.524,
        "_internal_originalTime": 1689841407000
    },
    {
        "time": 1689841408000,
        "value": 139.526,
        "_internal_originalTime": 1689841408000
    },
    {
        "time": 1689841410000,
        "value": 139.525,
        "_internal_originalTime": 1689841410000
    },
    {
        "time": 1689841411000,
        "value": 139.523,
        "_internal_originalTime": 1689841411000
    },
    {
        "time": 1689841412000,
        "value": 139.52,
        "_internal_originalTime": 1689841412000
    },
    {
        "time": 1689841414000,
        "value": 139.522,
        "_internal_originalTime": 1689841414000
    },
    {
        "time": 1689841415000,
        "value": 139.522,
        "_internal_originalTime": 1689841415000
    },
    {
        "time": 1689841416000,
        "value": 139.52,
        "_internal_originalTime": 1689841416000
    },
    {
        "time": 1689841417000,
        "value": 139.525,
        "_internal_originalTime": 1689841417000
    },
    {
        "time": 1689841418000,
        "value": 139.526,
        "_internal_originalTime": 1689841418000
    },
    {
        "time": 1689841419000,
        "value": 139.522,
        "_internal_originalTime": 1689841419000
    },
    {
        "time": 1689841420000,
        "value": 139.522,
        "_internal_originalTime": 1689841420000
    },
    {
        "time": 1689841421000,
        "value": 139.52,
        "_internal_originalTime": 1689841421000
    },
    {
        "time": 1689841422000,
        "value": 139.526,
        "_internal_originalTime": 1689841422000
    },
    {
        "time": 1689841424000,
        "value": 139.516,
        "_internal_originalTime": 1689841424000
    },
    {
        "time": 1689841426000,
        "value": 139.522,
        "_internal_originalTime": 1689841426000
    },
    {
        "time": 1689841427000,
        "value": 139.517,
        "_internal_originalTime": 1689841427000
    },
    {
        "time": 1689841428000,
        "value": 139.515,
        "_internal_originalTime": 1689841428000
    },
    {
        "time": 1689841429000,
        "value": 139.515,
        "_internal_originalTime": 1689841429000
    },
    {
        "time": 1689841430000,
        "value": 139.516,
        "_internal_originalTime": 1689841430000
    },
    {
        "time": 1689841431000,
        "value": 139.516,
        "_internal_originalTime": 1689841431000
    },
    {
        "time": 1689841433000,
        "value": 139.52,
        "_internal_originalTime": 1689841433000
    },
    {
        "time": 1689841434000,
        "value": 139.52,
        "_internal_originalTime": 1689841434000
    },
    {
        "time": 1689841435000,
        "value": 139.516,
        "_internal_originalTime": 1689841435000
    },
    {
        "time": 1689841437000,
        "value": 139.515,
        "_internal_originalTime": 1689841437000
    },
    {
        "time": 1689841438000,
        "value": 139.516,
        "_internal_originalTime": 1689841438000
    },
    {
        "time": 1689841439000,
        "value": 139.515,
        "_internal_originalTime": 1689841439000
    },
    {
        "time": 1689841440000,
        "value": 139.52,
        "_internal_originalTime": 1689841440000
    },
    {
        "time": 1689841441000,
        "value": 139.518,
        "_internal_originalTime": 1689841441000
    },
    {
        "time": 1689841442000,
        "value": 139.518,
        "_internal_originalTime": 1689841442000
    },
    {
        "time": 1689841444000,
        "value": 139.518,
        "_internal_originalTime": 1689841444000
    },
    {
        "time": 1689841445000,
        "value": 139.516,
        "_internal_originalTime": 1689841445000
    },
    {
        "time": 1689841446000,
        "value": 139.515,
        "_internal_originalTime": 1689841446000
    },
    {
        "time": 1689841447000,
        "value": 139.516,
        "_internal_originalTime": 1689841447000
    },
    {
        "time": 1689841449000,
        "value": 139.516,
        "_internal_originalTime": 1689841449000
    },
    {
        "time": 1689841450000,
        "value": 139.514,
        "_internal_originalTime": 1689841450000
    },
    {
        "time": 1689841452000,
        "value": 139.52,
        "_internal_originalTime": 1689841452000
    },
    {
        "time": 1689841453000,
        "value": 139.515,
        "_internal_originalTime": 1689841453000
    },
    {
        "time": 1689841455000,
        "value": 139.521,
        "_internal_originalTime": 1689841455000
    },
    {
        "time": 1689841456000,
        "value": 139.521,
        "_internal_originalTime": 1689841456000
    },
    {
        "time": 1689841457000,
        "value": 139.518,
        "_internal_originalTime": 1689841457000
    },
    {
        "time": 1689841458000,
        "value": 139.507,
        "_internal_originalTime": 1689841458000
    },
    {
        "time": 1689841459000,
        "value": 139.503,
        "_internal_originalTime": 1689841459000
    },
    {
        "time": 1689841460000,
        "value": 139.5,
        "_internal_originalTime": 1689841460000
    },
    {
        "time": 1689841461000,
        "value": 139.503,
        "_internal_originalTime": 1689841461000
    },
    {
        "time": 1689841462000,
        "value": 139.506,
        "_internal_originalTime": 1689841462000
    },
    {
        "time": 1689841464000,
        "value": 139.506,
        "_internal_originalTime": 1689841464000
    },
    {
        "time": 1689841465000,
        "value": 139.51,
        "_internal_originalTime": 1689841465000
    },
    {
        "time": 1689841467000,
        "value": 139.523,
        "_internal_originalTime": 1689841467000
    },
    {
        "time": 1689841468000,
        "value": 139.525,
        "_internal_originalTime": 1689841468000
    },
    {
        "time": 1689841469000,
        "value": 139.531,
        "_internal_originalTime": 1689841469000
    },
    {
        "time": 1689841470000,
        "value": 139.532,
        "_internal_originalTime": 1689841470000
    },
    {
        "time": 1689841471000,
        "value": 139.536,
        "_internal_originalTime": 1689841471000
    },
    {
        "time": 1689841473000,
        "value": 139.544,
        "_internal_originalTime": 1689841473000
    },
    {
        "time": 1689841474000,
        "value": 139.541,
        "_internal_originalTime": 1689841474000
    },
    {
        "time": 1689841475000,
        "value": 139.536,
        "_internal_originalTime": 1689841475000
    },
    {
        "time": 1689841478000,
        "value": 139.534,
        "_internal_originalTime": 1689841478000
    },
    {
        "time": 1689841481000,
        "value": 139.537,
        "_internal_originalTime": 1689841481000
    },
    {
        "time": 1689841482000,
        "value": 139.534,
        "_internal_originalTime": 1689841482000
    },
    {
        "time": 1689841483000,
        "value": 139.532,
        "_internal_originalTime": 1689841483000
    },
    {
        "time": 1689841484000,
        "value": 139.536,
        "_internal_originalTime": 1689841484000
    },
    {
        "time": 1689841485000,
        "value": 139.53,
        "_internal_originalTime": 1689841485000
    },
    {
        "time": 1689841486000,
        "value": 139.531,
        "_internal_originalTime": 1689841486000
    },
    {
        "time": 1689841488000,
        "value": 139.53,
        "_internal_originalTime": 1689841488000
    },
    {
        "time": 1689841489000,
        "value": 139.531,
        "_internal_originalTime": 1689841489000
    },
    {
        "time": 1689841491000,
        "value": 139.529,
        "_internal_originalTime": 1689841491000
    },
    {
        "time": 1689841493000,
        "value": 139.534,
        "_internal_originalTime": 1689841493000
    },
    {
        "time": 1689841494000,
        "value": 139.53,
        "_internal_originalTime": 1689841494000
    },
    {
        "time": 1689841495000,
        "value": 139.53,
        "_internal_originalTime": 1689841495000
    },
    {
        "time": 1689841498000,
        "value": 139.536,
        "_internal_originalTime": 1689841498000
    },
    {
        "time": 1689841500000,
        "value": 139.542,
        "_internal_originalTime": 1689841500000
    },
    {
        "time": 1689841501000,
        "value": 139.534,
        "_internal_originalTime": 1689841501000
    },
    {
        "time": 1689841502000,
        "value": 139.532,
        "_internal_originalTime": 1689841502000
    },
    {
        "time": 1689841503000,
        "value": 139.536,
        "_internal_originalTime": 1689841503000
    },
    {
        "time": 1689841505000,
        "value": 139.532,
        "_internal_originalTime": 1689841505000
    },
    {
        "time": 1689841506000,
        "value": 139.528,
        "_internal_originalTime": 1689841506000
    },
    {
        "time": 1689841508000,
        "value": 139.528,
        "_internal_originalTime": 1689841508000
    },
    {
        "time": 1689841511000,
        "value": 139.523,
        "_internal_originalTime": 1689841511000
    },
    {
        "time": 1689841512000,
        "value": 139.52,
        "_internal_originalTime": 1689841512000
    },
    {
        "time": 1689841513000,
        "value": 139.522,
        "_internal_originalTime": 1689841513000
    },
    {
        "time": 1689841515000,
        "value": 139.517,
        "_internal_originalTime": 1689841515000
    },
    {
        "time": 1689841516000,
        "value": 139.516,
        "_internal_originalTime": 1689841516000
    },
    {
        "time": 1689841518000,
        "value": 139.517,
        "_internal_originalTime": 1689841518000
    },
    {
        "time": 1689841519000,
        "value": 139.515,
        "_internal_originalTime": 1689841519000
    },
    {
        "time": 1689841521000,
        "value": 139.511,
        "_internal_originalTime": 1689841521000
    },
    {
        "time": 1689841522000,
        "value": 139.514,
        "_internal_originalTime": 1689841522000
    },
    {
        "time": 1689841523000,
        "value": 139.51,
        "_internal_originalTime": 1689841523000
    },
    {
        "time": 1689841525000,
        "value": 139.508,
        "_internal_originalTime": 1689841525000
    },
    {
        "time": 1689841526000,
        "value": 139.511,
        "_internal_originalTime": 1689841526000
    },
    {
        "time": 1689841527000,
        "value": 139.499,
        "_internal_originalTime": 1689841527000
    },
    {
        "time": 1689841529000,
        "value": 139.497,
        "_internal_originalTime": 1689841529000
    },
    {
        "time": 1689841530000,
        "value": 139.502,
        "_internal_originalTime": 1689841530000
    },
    {
        "time": 1689841531000,
        "value": 139.496,
        "_internal_originalTime": 1689841531000
    },
    {
        "time": 1689841532000,
        "value": 139.498,
        "_internal_originalTime": 1689841532000
    },
    {
        "time": 1689841533000,
        "value": 139.502,
        "_internal_originalTime": 1689841533000
    },
    {
        "time": 1689841535000,
        "value": 139.504,
        "_internal_originalTime": 1689841535000
    },
    {
        "time": 1689841536000,
        "value": 139.497,
        "_internal_originalTime": 1689841536000
    },
    {
        "time": 1689841537000,
        "value": 139.498,
        "_internal_originalTime": 1689841537000
    },
    {
        "time": 1689841539000,
        "value": 139.499,
        "_internal_originalTime": 1689841539000
    },
    {
        "time": 1689841540000,
        "value": 139.502,
        "_internal_originalTime": 1689841540000
    },
    {
        "time": 1689841541000,
        "value": 139.496,
        "_internal_originalTime": 1689841541000
    },
    {
        "time": 1689841542000,
        "value": 139.496,
        "_internal_originalTime": 1689841542000
    },
    {
        "time": 1689841543000,
        "value": 139.497,
        "_internal_originalTime": 1689841543000
    },
    {
        "time": 1689841545000,
        "value": 139.501,
        "_internal_originalTime": 1689841545000
    },
    {
        "time": 1689841546000,
        "value": 139.501,
        "_internal_originalTime": 1689841546000
    },
    {
        "time": 1689841547000,
        "value": 139.497,
        "_internal_originalTime": 1689841547000
    },
    {
        "time": 1689841548000,
        "value": 139.498,
        "_internal_originalTime": 1689841548000
    },
    {
        "time": 1689841549000,
        "value": 139.503,
        "_internal_originalTime": 1689841549000
    },
    {
        "time": 1689841551000,
        "value": 139.496,
        "_internal_originalTime": 1689841551000
    },
    {
        "time": 1689841552000,
        "value": 139.496,
        "_internal_originalTime": 1689841552000
    },
    {
        "time": 1689841553000,
        "value": 139.486,
        "_internal_originalTime": 1689841553000
    },
    {
        "time": 1689841554000,
        "value": 139.487,
        "_internal_originalTime": 1689841554000
    },
    {
        "time": 1689841556000,
        "value": 139.488,
        "_internal_originalTime": 1689841556000
    },
    {
        "time": 1689841557000,
        "value": 139.49,
        "_internal_originalTime": 1689841557000
    },
    {
        "time": 1689841559000,
        "value": 139.488,
        "_internal_originalTime": 1689841559000
    },
    {
        "time": 1689841561000,
        "value": 139.492,
        "_internal_originalTime": 1689841561000
    },
    {
        "time": 1689841563000,
        "value": 139.488,
        "_internal_originalTime": 1689841563000
    },
    {
        "time": 1689841564000,
        "value": 139.498,
        "_internal_originalTime": 1689841564000
    },
    {
        "time": 1689841565000,
        "value": 139.494,
        "_internal_originalTime": 1689841565000
    },
    {
        "time": 1689841566000,
        "value": 139.496,
        "_internal_originalTime": 1689841566000
    },
    {
        "time": 1689841567000,
        "value": 139.492,
        "_internal_originalTime": 1689841567000
    },
    {
        "time": 1689841569000,
        "value": 139.496,
        "_internal_originalTime": 1689841569000
    },
    {
        "time": 1689841570000,
        "value": 139.493,
        "_internal_originalTime": 1689841570000
    },
    {
        "time": 1689841572000,
        "value": 139.487,
        "_internal_originalTime": 1689841572000
    },
    {
        "time": 1689841574000,
        "value": 139.496,
        "_internal_originalTime": 1689841574000
    },
    {
        "time": 1689841575000,
        "value": 139.488,
        "_internal_originalTime": 1689841575000
    },
    {
        "time": 1689841576000,
        "value": 139.492,
        "_internal_originalTime": 1689841576000
    },
    {
        "time": 1689841577000,
        "value": 139.487,
        "_internal_originalTime": 1689841577000
    },
    {
        "time": 1689841579000,
        "value": 139.492,
        "_internal_originalTime": 1689841579000
    },
    {
        "time": 1689841580000,
        "value": 139.494,
        "_internal_originalTime": 1689841580000
    },
    {
        "time": 1689841581000,
        "value": 139.492,
        "_internal_originalTime": 1689841581000
    },
    {
        "time": 1689841583000,
        "value": 139.492,
        "_internal_originalTime": 1689841583000
    },
    {
        "time": 1689841584000,
        "value": 139.493,
        "_internal_originalTime": 1689841584000
    },
    {
        "time": 1689841586000,
        "value": 139.492,
        "_internal_originalTime": 1689841586000
    },
    {
        "time": 1689841587000,
        "value": 139.494,
        "_internal_originalTime": 1689841587000
    },
    {
        "time": 1689841589000,
        "value": 139.488,
        "_internal_originalTime": 1689841589000
    },
    {
        "time": 1689841590000,
        "value": 139.487,
        "_internal_originalTime": 1689841590000
    },
    {
        "time": 1689841592000,
        "value": 139.487,
        "_internal_originalTime": 1689841592000
    },
    {
        "time": 1689841594000,
        "value": 139.492,
        "_internal_originalTime": 1689841594000
    },
    {
        "time": 1689841595000,
        "value": 139.49,
        "_internal_originalTime": 1689841595000
    },
    {
        "time": 1689841596000,
        "value": 139.5,
        "_internal_originalTime": 1689841596000
    },
    {
        "time": 1689841598000,
        "value": 139.499,
        "_internal_originalTime": 1689841598000
    },
    {
        "time": 1689841599000,
        "value": 139.505,
        "_internal_originalTime": 1689841599000
    },
    {
        "time": 1689841601000,
        "value": 139.508,
        "_internal_originalTime": 1689841601000
    },
    {
        "time": 1689841603000,
        "value": 139.513,
        "_internal_originalTime": 1689841603000
    },
    {
        "time": 1689841605000,
        "value": 139.514,
        "_internal_originalTime": 1689841605000
    },
    {
        "time": 1689841607000,
        "value": 139.506,
        "_internal_originalTime": 1689841607000
    },
    {
        "time": 1689841608000,
        "value": 139.51,
        "_internal_originalTime": 1689841608000
    },
    {
        "time": 1689841609000,
        "value": 139.507,
        "_internal_originalTime": 1689841609000
    },
    {
        "time": 1689841610000,
        "value": 139.509,
        "_internal_originalTime": 1689841610000
    },
    {
        "time": 1689841611000,
        "value": 139.517,
        "_internal_originalTime": 1689841611000
    },
    {
        "time": 1689841612000,
        "value": 139.521,
        "_internal_originalTime": 1689841612000
    },
    {
        "time": 1689841614000,
        "value": 139.522,
        "_internal_originalTime": 1689841614000
    },
    {
        "time": 1689841615000,
        "value": 139.518,
        "_internal_originalTime": 1689841615000
    },
    {
        "time": 1689841616000,
        "value": 139.528,
        "_internal_originalTime": 1689841616000
    },
    {
        "time": 1689841617000,
        "value": 139.524,
        "_internal_originalTime": 1689841617000
    },
    {
        "time": 1689841618000,
        "value": 139.524,
        "_internal_originalTime": 1689841618000
    },
    {
        "time": 1689841620000,
        "value": 139.528,
        "_internal_originalTime": 1689841620000
    },
    {
        "time": 1689841621000,
        "value": 139.5,
        "_internal_originalTime": 1689841621000
    },
    {
        "time": 1689841622000,
        "value": 139.516,
        "_internal_originalTime": 1689841622000
    },
    {
        "time": 1689841623000,
        "value": 139.52,
        "_internal_originalTime": 1689841623000
    },
    {
        "time": 1689841624000,
        "value": 139.516,
        "_internal_originalTime": 1689841624000
    },
    {
        "time": 1689841626000,
        "value": 139.521,
        "_internal_originalTime": 1689841626000
    },
    {
        "time": 1689841627000,
        "value": 139.524,
        "_internal_originalTime": 1689841627000
    },
    {
        "time": 1689841628000,
        "value": 139.533,
        "_internal_originalTime": 1689841628000
    },
    {
        "time": 1689841630000,
        "value": 139.528,
        "_internal_originalTime": 1689841630000
    },
    {
        "time": 1689841631000,
        "value": 139.526,
        "_internal_originalTime": 1689841631000
    },
    {
        "time": 1689841632000,
        "value": 139.528,
        "_internal_originalTime": 1689841632000
    },
    {
        "time": 1689841633000,
        "value": 139.534,
        "_internal_originalTime": 1689841633000
    },
    {
        "time": 1689841635000,
        "value": 139.534,
        "_internal_originalTime": 1689841635000
    },
    {
        "time": 1689841636000,
        "value": 139.534,
        "_internal_originalTime": 1689841636000
    },
    {
        "time": 1689841637000,
        "value": 139.534,
        "_internal_originalTime": 1689841637000
    },
    {
        "time": 1689841639000,
        "value": 139.534,
        "_internal_originalTime": 1689841639000
    },
    {
        "time": 1689841641000,
        "value": 139.539,
        "_internal_originalTime": 1689841641000
    },
    {
        "time": 1689841643000,
        "value": 139.527,
        "_internal_originalTime": 1689841643000
    },
    {
        "time": 1689841644000,
        "value": 139.527,
        "_internal_originalTime": 1689841644000
    },
    {
        "time": 1689841646000,
        "value": 139.528,
        "_internal_originalTime": 1689841646000
    },
    {
        "time": 1689841647000,
        "value": 139.523,
        "_internal_originalTime": 1689841647000
    },
    {
        "time": 1689841648000,
        "value": 139.528,
        "_internal_originalTime": 1689841648000
    },
    {
        "time": 1689841649000,
        "value": 139.522,
        "_internal_originalTime": 1689841649000
    },
    {
        "time": 1689841650000,
        "value": 139.528,
        "_internal_originalTime": 1689841650000
    },
    {
        "time": 1689841652000,
        "value": 139.529,
        "_internal_originalTime": 1689841652000
    },
    {
        "time": 1689841653000,
        "value": 139.524,
        "_internal_originalTime": 1689841653000
    },
    {
        "time": 1689841654000,
        "value": 139.522,
        "_internal_originalTime": 1689841654000
    },
    {
        "time": 1689841655000,
        "value": 139.522,
        "_internal_originalTime": 1689841655000
    },
    {
        "time": 1689841656000,
        "value": 139.532,
        "_internal_originalTime": 1689841656000
    },
    {
        "time": 1689841657000,
        "value": 139.532,
        "_internal_originalTime": 1689841657000
    },
    {
        "time": 1689841659000,
        "value": 139.53,
        "_internal_originalTime": 1689841659000
    },
    {
        "time": 1689841660000,
        "value": 139.53,
        "_internal_originalTime": 1689841660000
    },
    {
        "time": 1689841661000,
        "value": 139.524,
        "_internal_originalTime": 1689841661000
    },
    {
        "time": 1689841663000,
        "value": 139.523,
        "_internal_originalTime": 1689841663000
    },
    {
        "time": 1689841665000,
        "value": 139.522,
        "_internal_originalTime": 1689841665000
    },
    {
        "time": 1689841666000,
        "value": 139.522,
        "_internal_originalTime": 1689841666000
    },
    {
        "time": 1689841667000,
        "value": 139.529,
        "_internal_originalTime": 1689841667000
    },
    {
        "time": 1689841668000,
        "value": 139.529,
        "_internal_originalTime": 1689841668000
    },
    {
        "time": 1689841669000,
        "value": 139.528,
        "_internal_originalTime": 1689841669000
    },
    {
        "time": 1689841671000,
        "value": 139.532,
        "_internal_originalTime": 1689841671000
    },
    {
        "time": 1689841672000,
        "value": 139.528,
        "_internal_originalTime": 1689841672000
    },
    {
        "time": 1689841674000,
        "value": 139.531,
        "_internal_originalTime": 1689841674000
    },
    {
        "time": 1689841675000,
        "value": 139.527,
        "_internal_originalTime": 1689841675000
    },
    {
        "time": 1689841676000,
        "value": 139.527,
        "_internal_originalTime": 1689841676000
    },
    {
        "time": 1689841678000,
        "value": 139.523,
        "_internal_originalTime": 1689841678000
    },
    {
        "time": 1689841679000,
        "value": 139.53,
        "_internal_originalTime": 1689841679000
    },
    {
        "time": 1689841680000,
        "value": 139.531,
        "_internal_originalTime": 1689841680000
    },
    {
        "time": 1689841681000,
        "value": 139.51,
        "_internal_originalTime": 1689841681000
    },
    {
        "time": 1689841683000,
        "value": 139.528,
        "_internal_originalTime": 1689841683000
    },
    {
        "time": 1689841684000,
        "value": 139.524,
        "_internal_originalTime": 1689841684000
    },
    {
        "time": 1689841685000,
        "value": 139.526,
        "_internal_originalTime": 1689841685000
    },
    {
        "time": 1689841686000,
        "value": 139.529,
        "_internal_originalTime": 1689841686000
    },
    {
        "time": 1689841688000,
        "value": 139.528,
        "_internal_originalTime": 1689841688000
    },
    {
        "time": 1689841689000,
        "value": 139.524,
        "_internal_originalTime": 1689841689000
    },
    {
        "time": 1689841690000,
        "value": 139.525,
        "_internal_originalTime": 1689841690000
    },
    {
        "time": 1689841691000,
        "value": 139.528,
        "_internal_originalTime": 1689841691000
    },
    {
        "time": 1689841692000,
        "value": 139.535,
        "_internal_originalTime": 1689841692000
    },
    {
        "time": 1689841694000,
        "value": 139.528,
        "_internal_originalTime": 1689841694000
    },
    {
        "time": 1689841695000,
        "value": 139.531,
        "_internal_originalTime": 1689841695000
    },
    {
        "time": 1689841696000,
        "value": 139.529,
        "_internal_originalTime": 1689841696000
    },
    {
        "time": 1689841697000,
        "value": 139.53,
        "_internal_originalTime": 1689841697000
    },
    {
        "time": 1689841698000,
        "value": 139.531,
        "_internal_originalTime": 1689841698000
    },
    {
        "time": 1689841699000,
        "value": 139.528,
        "_internal_originalTime": 1689841699000
    },
    {
        "time": 1689841701000,
        "value": 139.528,
        "_internal_originalTime": 1689841701000
    },
    {
        "time": 1689841702000,
        "value": 139.531,
        "_internal_originalTime": 1689841702000
    },
    {
        "time": 1689841705000,
        "value": 139.528,
        "_internal_originalTime": 1689841705000
    },
    {
        "time": 1689841706000,
        "value": 139.531,
        "_internal_originalTime": 1689841706000
    },
    {
        "time": 1689841708000,
        "value": 139.527,
        "_internal_originalTime": 1689841708000
    },
    {
        "time": 1689841710000,
        "value": 139.53,
        "_internal_originalTime": 1689841710000
    },
    {
        "time": 1689841711000,
        "value": 139.528,
        "_internal_originalTime": 1689841711000
    },
    {
        "time": 1689841712000,
        "value": 139.524,
        "_internal_originalTime": 1689841712000
    },
    {
        "time": 1689841714000,
        "value": 139.524,
        "_internal_originalTime": 1689841714000
    },
    {
        "time": 1689841715000,
        "value": 139.525,
        "_internal_originalTime": 1689841715000
    },
    {
        "time": 1689841716000,
        "value": 139.526,
        "_internal_originalTime": 1689841716000
    },
    {
        "time": 1689841718000,
        "value": 139.525,
        "_internal_originalTime": 1689841718000
    },
    {
        "time": 1689841719000,
        "value": 139.523,
        "_internal_originalTime": 1689841719000
    },
    {
        "time": 1689841721000,
        "value": 139.527,
        "_internal_originalTime": 1689841721000
    },
    {
        "time": 1689841722000,
        "value": 139.528,
        "_internal_originalTime": 1689841722000
    },
    {
        "time": 1689841723000,
        "value": 139.531,
        "_internal_originalTime": 1689841723000
    },
    {
        "time": 1689841724000,
        "value": 139.532,
        "_internal_originalTime": 1689841724000
    },
    {
        "time": 1689841725000,
        "value": 139.536,
        "_internal_originalTime": 1689841725000
    },
    {
        "time": 1689841727000,
        "value": 139.536,
        "_internal_originalTime": 1689841727000
    },
    {
        "time": 1689841728000,
        "value": 139.541,
        "_internal_originalTime": 1689841728000
    },
    {
        "time": 1689841730000,
        "value": 139.542,
        "_internal_originalTime": 1689841730000
    },
    {
        "time": 1689841731000,
        "value": 139.54,
        "_internal_originalTime": 1689841731000
    },
    {
        "time": 1689841732000,
        "value": 139.542,
        "_internal_originalTime": 1689841732000
    },
    {
        "time": 1689841736000,
        "value": 139.543,
        "_internal_originalTime": 1689841736000
    },
    {
        "time": 1689841737000,
        "value": 139.536,
        "_internal_originalTime": 1689841737000
    },
    {
        "time": 1689841738000,
        "value": 139.536,
        "_internal_originalTime": 1689841738000
    },
    {
        "time": 1689841740000,
        "value": 139.538,
        "_internal_originalTime": 1689841740000
    },
    {
        "time": 1689841741000,
        "value": 139.535,
        "_internal_originalTime": 1689841741000
    },
    {
        "time": 1689841742000,
        "value": 139.531,
        "_internal_originalTime": 1689841742000
    },
    {
        "time": 1689841743000,
        "value": 139.526,
        "_internal_originalTime": 1689841743000
    },
    {
        "time": 1689841744000,
        "value": 139.528,
        "_internal_originalTime": 1689841744000
    },
    {
        "time": 1689841745000,
        "value": 139.523,
        "_internal_originalTime": 1689841745000
    },
    {
        "time": 1689841747000,
        "value": 139.528,
        "_internal_originalTime": 1689841747000
    },
    {
        "time": 1689841748000,
        "value": 139.524,
        "_internal_originalTime": 1689841748000
    },
    {
        "time": 1689841749000,
        "value": 139.522,
        "_internal_originalTime": 1689841749000
    },
    {
        "time": 1689841751000,
        "value": 139.524,
        "_internal_originalTime": 1689841751000
    },
    {
        "time": 1689841752000,
        "value": 139.526,
        "_internal_originalTime": 1689841752000
    },
    {
        "time": 1689841753000,
        "value": 139.53,
        "_internal_originalTime": 1689841753000
    },
    {
        "time": 1689841754000,
        "value": 139.526,
        "_internal_originalTime": 1689841754000
    },
    {
        "time": 1689841756000,
        "value": 139.531,
        "_internal_originalTime": 1689841756000
    },
    {
        "time": 1689841757000,
        "value": 139.526,
        "_internal_originalTime": 1689841757000
    },
    {
        "time": 1689841760000,
        "value": 139.531,
        "_internal_originalTime": 1689841760000
    },
    {
        "time": 1689841761000,
        "value": 139.528,
        "_internal_originalTime": 1689841761000
    },
    {
        "time": 1689841763000,
        "value": 139.53,
        "_internal_originalTime": 1689841763000
    },
    {
        "time": 1689841764000,
        "value": 139.54,
        "_internal_originalTime": 1689841764000
    },
    {
        "time": 1689841766000,
        "value": 139.531,
        "_internal_originalTime": 1689841766000
    },
    {
        "time": 1689841767000,
        "value": 139.537,
        "_internal_originalTime": 1689841767000
    },
    {
        "time": 1689841769000,
        "value": 139.537,
        "_internal_originalTime": 1689841769000
    },
    {
        "time": 1689841771000,
        "value": 139.541,
        "_internal_originalTime": 1689841771000
    },
    {
        "time": 1689841773000,
        "value": 139.534,
        "_internal_originalTime": 1689841773000
    },
    {
        "time": 1689841774000,
        "value": 139.536,
        "_internal_originalTime": 1689841774000
    },
    {
        "time": 1689841776000,
        "value": 139.541,
        "_internal_originalTime": 1689841776000
    },
    {
        "time": 1689841777000,
        "value": 139.539,
        "_internal_originalTime": 1689841777000
    },
    {
        "time": 1689841778000,
        "value": 139.534,
        "_internal_originalTime": 1689841778000
    },
    {
        "time": 1689841780000,
        "value": 139.537,
        "_internal_originalTime": 1689841780000
    },
    {
        "time": 1689841781000,
        "value": 139.54,
        "_internal_originalTime": 1689841781000
    },
    {
        "time": 1689841783000,
        "value": 139.539,
        "_internal_originalTime": 1689841783000
    },
    {
        "time": 1689841784000,
        "value": 139.536,
        "_internal_originalTime": 1689841784000
    },
    {
        "time": 1689841785000,
        "value": 139.529,
        "_internal_originalTime": 1689841785000
    },
    {
        "time": 1689841786000,
        "value": 139.534,
        "_internal_originalTime": 1689841786000
    },
    {
        "time": 1689841788000,
        "value": 139.536,
        "_internal_originalTime": 1689841788000
    },
    {
        "time": 1689841789000,
        "value": 139.53,
        "_internal_originalTime": 1689841789000
    },
    {
        "time": 1689841790000,
        "value": 139.535,
        "_internal_originalTime": 1689841790000
    },
    {
        "time": 1689841791000,
        "value": 139.535,
        "_internal_originalTime": 1689841791000
    },
    {
        "time": 1689841792000,
        "value": 139.526,
        "_internal_originalTime": 1689841792000
    },
    {
        "time": 1689841793000,
        "value": 139.534,
        "_internal_originalTime": 1689841793000
    },
    {
        "time": 1689841795000,
        "value": 139.54,
        "_internal_originalTime": 1689841795000
    },
    {
        "time": 1689841796000,
        "value": 139.54,
        "_internal_originalTime": 1689841796000
    },
    {
        "time": 1689841798000,
        "value": 139.541,
        "_internal_originalTime": 1689841798000
    },
    {
        "time": 1689841799000,
        "value": 139.538,
        "_internal_originalTime": 1689841799000
    },
    {
        "time": 1689841800000,
        "value": 139.542,
        "_internal_originalTime": 1689841800000
    },
    {
        "time": 1689841801000,
        "value": 139.54,
        "_internal_originalTime": 1689841801000
    },
    {
        "time": 1689841802000,
        "value": 139.536,
        "_internal_originalTime": 1689841802000
    },
    {
        "time": 1689841803000,
        "value": 139.536,
        "_internal_originalTime": 1689841803000
    },
    {
        "time": 1689841804000,
        "value": 139.54,
        "_internal_originalTime": 1689841804000
    },
    {
        "time": 1689841806000,
        "value": 139.541,
        "_internal_originalTime": 1689841806000
    },
    {
        "time": 1689841807000,
        "value": 139.54,
        "_internal_originalTime": 1689841807000
    },
    {
        "time": 1689841808000,
        "value": 139.534,
        "_internal_originalTime": 1689841808000
    },
    {
        "time": 1689841809000,
        "value": 139.539,
        "_internal_originalTime": 1689841809000
    },
    {
        "time": 1689841811000,
        "value": 139.533,
        "_internal_originalTime": 1689841811000
    },
    {
        "time": 1689841812000,
        "value": 139.534,
        "_internal_originalTime": 1689841812000
    },
    {
        "time": 1689841813000,
        "value": 139.532,
        "_internal_originalTime": 1689841813000
    },
    {
        "time": 1689841814000,
        "value": 139.531,
        "_internal_originalTime": 1689841814000
    },
    {
        "time": 1689841815000,
        "value": 139.533,
        "_internal_originalTime": 1689841815000
    },
    {
        "time": 1689841817000,
        "value": 139.54,
        "_internal_originalTime": 1689841817000
    },
    {
        "time": 1689841818000,
        "value": 139.536,
        "_internal_originalTime": 1689841818000
    },
    {
        "time": 1689841819000,
        "value": 139.536,
        "_internal_originalTime": 1689841819000
    },
    {
        "time": 1689841820000,
        "value": 139.539,
        "_internal_originalTime": 1689841820000
    },
    {
        "time": 1689841822000,
        "value": 139.535,
        "_internal_originalTime": 1689841822000
    },
    {
        "time": 1689841823000,
        "value": 139.539,
        "_internal_originalTime": 1689841823000
    },
    {
        "time": 1689841824000,
        "value": 139.53,
        "_internal_originalTime": 1689841824000
    },
    {
        "time": 1689841826000,
        "value": 139.528,
        "_internal_originalTime": 1689841826000
    },
    {
        "time": 1689841827000,
        "value": 139.532,
        "_internal_originalTime": 1689841827000
    },
    {
        "time": 1689841828000,
        "value": 139.536,
        "_internal_originalTime": 1689841828000
    },
    {
        "time": 1689841829000,
        "value": 139.538,
        "_internal_originalTime": 1689841829000
    },
    {
        "time": 1689841834000,
        "value": 139.541,
        "_internal_originalTime": 1689841834000
    },
    {
        "time": 1689841835000,
        "value": 139.537,
        "_internal_originalTime": 1689841835000
    },
    {
        "time": 1689841836000,
        "value": 139.542,
        "_internal_originalTime": 1689841836000
    },
    {
        "time": 1689841838000,
        "value": 139.544,
        "_internal_originalTime": 1689841838000
    },
    {
        "time": 1689841839000,
        "value": 139.54,
        "_internal_originalTime": 1689841839000
    },
    {
        "time": 1689841840000,
        "value": 139.538,
        "_internal_originalTime": 1689841840000
    },
    {
        "time": 1689841842000,
        "value": 139.538,
        "_internal_originalTime": 1689841842000
    },
    {
        "time": 1689841843000,
        "value": 139.524,
        "_internal_originalTime": 1689841843000
    },
    {
        "time": 1689841844000,
        "value": 139.521,
        "_internal_originalTime": 1689841844000
    },
    {
        "time": 1689841846000,
        "value": 139.524,
        "_internal_originalTime": 1689841846000
    },
    {
        "time": 1689841847000,
        "value": 139.525,
        "_internal_originalTime": 1689841847000
    },
    {
        "time": 1689841849000,
        "value": 139.521,
        "_internal_originalTime": 1689841849000
    },
    {
        "time": 1689841850000,
        "value": 139.514,
        "_internal_originalTime": 1689841850000
    },
    {
        "time": 1689841851000,
        "value": 139.523,
        "_internal_originalTime": 1689841851000
    },
    {
        "time": 1689841852000,
        "value": 139.516,
        "_internal_originalTime": 1689841852000
    },
    {
        "time": 1689841854000,
        "value": 139.517,
        "_internal_originalTime": 1689841854000
    },
    {
        "time": 1689841855000,
        "value": 139.521,
        "_internal_originalTime": 1689841855000
    },
    {
        "time": 1689841857000,
        "value": 139.521,
        "_internal_originalTime": 1689841857000
    },
    {
        "time": 1689841858000,
        "value": 139.514,
        "_internal_originalTime": 1689841858000
    },
    {
        "time": 1689841860000,
        "value": 139.5,
        "_internal_originalTime": 1689841860000
    },
    {
        "time": 1689841861000,
        "value": 139.521,
        "_internal_originalTime": 1689841861000
    },
    {
        "time": 1689841862000,
        "value": 139.508,
        "_internal_originalTime": 1689841862000
    },
    {
        "time": 1689841863000,
        "value": 139.515,
        "_internal_originalTime": 1689841863000
    },
    {
        "time": 1689841864000,
        "value": 139.51,
        "_internal_originalTime": 1689841864000
    },
    {
        "time": 1689841865000,
        "value": 139.51,
        "_internal_originalTime": 1689841865000
    },
    {
        "time": 1689841867000,
        "value": 139.51,
        "_internal_originalTime": 1689841867000
    },
    {
        "time": 1689841868000,
        "value": 139.506,
        "_internal_originalTime": 1689841868000
    },
    {
        "time": 1689841869000,
        "value": 139.508,
        "_internal_originalTime": 1689841869000
    },
    {
        "time": 1689841871000,
        "value": 139.5,
        "_internal_originalTime": 1689841871000
    },
    {
        "time": 1689841872000,
        "value": 139.506,
        "_internal_originalTime": 1689841872000
    },
    {
        "time": 1689841873000,
        "value": 139.507,
        "_internal_originalTime": 1689841873000
    },
    {
        "time": 1689841875000,
        "value": 139.506,
        "_internal_originalTime": 1689841875000
    },
    {
        "time": 1689841876000,
        "value": 139.502,
        "_internal_originalTime": 1689841876000
    },
    {
        "time": 1689841877000,
        "value": 139.506,
        "_internal_originalTime": 1689841877000
    },
    {
        "time": 1689841878000,
        "value": 139.491,
        "_internal_originalTime": 1689841878000
    },
    {
        "time": 1689841879000,
        "value": 139.5,
        "_internal_originalTime": 1689841879000
    },
    {
        "time": 1689841880000,
        "value": 139.495,
        "_internal_originalTime": 1689841880000
    },
    {
        "time": 1689841882000,
        "value": 139.497,
        "_internal_originalTime": 1689841882000
    },
    {
        "time": 1689841883000,
        "value": 139.502,
        "_internal_originalTime": 1689841883000
    },
    {
        "time": 1689841885000,
        "value": 139.489,
        "_internal_originalTime": 1689841885000
    },
    {
        "time": 1689841886000,
        "value": 139.476,
        "_internal_originalTime": 1689841886000
    },
    {
        "time": 1689841887000,
        "value": 139.478,
        "_internal_originalTime": 1689841887000
    },
    {
        "time": 1689841888000,
        "value": 139.466,
        "_internal_originalTime": 1689841888000
    },
    {
        "time": 1689841889000,
        "value": 139.456,
        "_internal_originalTime": 1689841889000
    },
    {
        "time": 1689841890000,
        "value": 139.457,
        "_internal_originalTime": 1689841890000
    },
    {
        "time": 1689841891000,
        "value": 139.462,
        "_internal_originalTime": 1689841891000
    },
    {
        "time": 1689841893000,
        "value": 139.463,
        "_internal_originalTime": 1689841893000
    },
    {
        "time": 1689841894000,
        "value": 139.458,
        "_internal_originalTime": 1689841894000
    },
    {
        "time": 1689841895000,
        "value": 139.459,
        "_internal_originalTime": 1689841895000
    },
    {
        "time": 1689841896000,
        "value": 139.452,
        "_internal_originalTime": 1689841896000
    },
    {
        "time": 1689841897000,
        "value": 139.456,
        "_internal_originalTime": 1689841897000
    },
    {
        "time": 1689841898000,
        "value": 139.463,
        "_internal_originalTime": 1689841898000
    },
    {
        "time": 1689841899000,
        "value": 139.462,
        "_internal_originalTime": 1689841899000
    },
    {
        "time": 1689841900000,
        "value": 139.458,
        "_internal_originalTime": 1689841900000
    },
    {
        "time": 1689841901000,
        "value": 139.457,
        "_internal_originalTime": 1689841901000
    },
    {
        "time": 1689841902000,
        "value": 139.457,
        "_internal_originalTime": 1689841902000
    },
    {
        "time": 1689841903000,
        "value": 139.457,
        "_internal_originalTime": 1689841903000
    },
    {
        "time": 1689841905000,
        "value": 139.457,
        "_internal_originalTime": 1689841905000
    },
    {
        "time": 1689841906000,
        "value": 139.471,
        "_internal_originalTime": 1689841906000
    },
    {
        "time": 1689841907000,
        "value": 139.467,
        "_internal_originalTime": 1689841907000
    },
    {
        "time": 1689841908000,
        "value": 139.474,
        "_internal_originalTime": 1689841908000
    },
    {
        "time": 1689841910000,
        "value": 139.476,
        "_internal_originalTime": 1689841910000
    },
    {
        "time": 1689841911000,
        "value": 139.476,
        "_internal_originalTime": 1689841911000
    },
    {
        "time": 1689841913000,
        "value": 139.476,
        "_internal_originalTime": 1689841913000
    },
    {
        "time": 1689841914000,
        "value": 139.467,
        "_internal_originalTime": 1689841914000
    },
    {
        "time": 1689841915000,
        "value": 139.472,
        "_internal_originalTime": 1689841915000
    },
    {
        "time": 1689841916000,
        "value": 139.467,
        "_internal_originalTime": 1689841916000
    },
    {
        "time": 1689841917000,
        "value": 139.466,
        "_internal_originalTime": 1689841917000
    },
    {
        "time": 1689841918000,
        "value": 139.466,
        "_internal_originalTime": 1689841918000
    },
    {
        "time": 1689841919000,
        "value": 139.466,
        "_internal_originalTime": 1689841919000
    },
    {
        "time": 1689841921000,
        "value": 139.463,
        "_internal_originalTime": 1689841921000
    },
    {
        "time": 1689841922000,
        "value": 139.468,
        "_internal_originalTime": 1689841922000
    },
    {
        "time": 1689841923000,
        "value": 139.47,
        "_internal_originalTime": 1689841923000
    },
    {
        "time": 1689841924000,
        "value": 139.473,
        "_internal_originalTime": 1689841924000
    },
    {
        "time": 1689841926000,
        "value": 139.472,
        "_internal_originalTime": 1689841926000
    },
    {
        "time": 1689841927000,
        "value": 139.47,
        "_internal_originalTime": 1689841927000
    },
    {
        "time": 1689841928000,
        "value": 139.475,
        "_internal_originalTime": 1689841928000
    },
    {
        "time": 1689841929000,
        "value": 139.471,
        "_internal_originalTime": 1689841929000
    },
    {
        "time": 1689841931000,
        "value": 139.472,
        "_internal_originalTime": 1689841931000
    },
    {
        "time": 1689841932000,
        "value": 139.468,
        "_internal_originalTime": 1689841932000
    },
    {
        "time": 1689841933000,
        "value": 139.469,
        "_internal_originalTime": 1689841933000
    },
    {
        "time": 1689841935000,
        "value": 139.468,
        "_internal_originalTime": 1689841935000
    },
    {
        "time": 1689841936000,
        "value": 139.473,
        "_internal_originalTime": 1689841936000
    },
    {
        "time": 1689841937000,
        "value": 139.473,
        "_internal_originalTime": 1689841937000
    },
    {
        "time": 1689841938000,
        "value": 139.476,
        "_internal_originalTime": 1689841938000
    },
    {
        "time": 1689841939000,
        "value": 139.473,
        "_internal_originalTime": 1689841939000
    },
    {
        "time": 1689841940000,
        "value": 139.474,
        "_internal_originalTime": 1689841940000
    },
    {
        "time": 1689841941000,
        "value": 139.475,
        "_internal_originalTime": 1689841941000
    },
    {
        "time": 1689841943000,
        "value": 139.48,
        "_internal_originalTime": 1689841943000
    },
    {
        "time": 1689841945000,
        "value": 139.476,
        "_internal_originalTime": 1689841945000
    },
    {
        "time": 1689841946000,
        "value": 139.482,
        "_internal_originalTime": 1689841946000
    },
    {
        "time": 1689841948000,
        "value": 139.481,
        "_internal_originalTime": 1689841948000
    },
    {
        "time": 1689841950000,
        "value": 139.481,
        "_internal_originalTime": 1689841950000
    },
    {
        "time": 1689841951000,
        "value": 139.48,
        "_internal_originalTime": 1689841951000
    },
    {
        "time": 1689841952000,
        "value": 139.48,
        "_internal_originalTime": 1689841952000
    },
    {
        "time": 1689841954000,
        "value": 139.479,
        "_internal_originalTime": 1689841954000
    },
    {
        "time": 1689841955000,
        "value": 139.476,
        "_internal_originalTime": 1689841955000
    },
    {
        "time": 1689841956000,
        "value": 139.48,
        "_internal_originalTime": 1689841956000
    },
    {
        "time": 1689841957000,
        "value": 139.48,
        "_internal_originalTime": 1689841957000
    },
    {
        "time": 1689841958000,
        "value": 139.475,
        "_internal_originalTime": 1689841958000
    },
    {
        "time": 1689841960000,
        "value": 139.466,
        "_internal_originalTime": 1689841960000
    },
    {
        "time": 1689841961000,
        "value": 139.458,
        "_internal_originalTime": 1689841961000
    },
    {
        "time": 1689841962000,
        "value": 139.448,
        "_internal_originalTime": 1689841962000
    },
    {
        "time": 1689841963000,
        "value": 139.45,
        "_internal_originalTime": 1689841963000
    },
    {
        "time": 1689841965000,
        "value": 139.451,
        "_internal_originalTime": 1689841965000
    },
    {
        "time": 1689841966000,
        "value": 139.448,
        "_internal_originalTime": 1689841966000
    },
    {
        "time": 1689841968000,
        "value": 139.454,
        "_internal_originalTime": 1689841968000
    },
    {
        "time": 1689841969000,
        "value": 139.459,
        "_internal_originalTime": 1689841969000
    },
    {
        "time": 1689841971000,
        "value": 139.461,
        "_internal_originalTime": 1689841971000
    },
    {
        "time": 1689841972000,
        "value": 139.454,
        "_internal_originalTime": 1689841972000
    },
    {
        "time": 1689841974000,
        "value": 139.456,
        "_internal_originalTime": 1689841974000
    },
    {
        "time": 1689841975000,
        "value": 139.454,
        "_internal_originalTime": 1689841975000
    },
    {
        "time": 1689841976000,
        "value": 139.452,
        "_internal_originalTime": 1689841976000
    },
    {
        "time": 1689841977000,
        "value": 139.453,
        "_internal_originalTime": 1689841977000
    },
    {
        "time": 1689841979000,
        "value": 139.457,
        "_internal_originalTime": 1689841979000
    },
    {
        "time": 1689841980000,
        "value": 139.453,
        "_internal_originalTime": 1689841980000
    },
    {
        "time": 1689841981000,
        "value": 139.453,
        "_internal_originalTime": 1689841981000
    },
    {
        "time": 1689841983000,
        "value": 139.45,
        "_internal_originalTime": 1689841983000
    },
    {
        "time": 1689841985000,
        "value": 139.459,
        "_internal_originalTime": 1689841985000
    },
    {
        "time": 1689841986000,
        "value": 139.462,
        "_internal_originalTime": 1689841986000
    },
    {
        "time": 1689841987000,
        "value": 139.455,
        "_internal_originalTime": 1689841987000
    },
    {
        "time": 1689841988000,
        "value": 139.448,
        "_internal_originalTime": 1689841988000
    },
    {
        "time": 1689841989000,
        "value": 139.454,
        "_internal_originalTime": 1689841989000
    },
    {
        "time": 1689841991000,
        "value": 139.452,
        "_internal_originalTime": 1689841991000
    },
    {
        "time": 1689841992000,
        "value": 139.445,
        "_internal_originalTime": 1689841992000
    },
    {
        "time": 1689841994000,
        "value": 139.441,
        "_internal_originalTime": 1689841994000
    },
    {
        "time": 1689841995000,
        "value": 139.429,
        "_internal_originalTime": 1689841995000
    },
    {
        "time": 1689841997000,
        "value": 139.442,
        "_internal_originalTime": 1689841997000
    },
    {
        "time": 1689841999000,
        "value": 139.458,
        "_internal_originalTime": 1689841999000
    },
    {
        "time": 1689842000000,
        "value": 139.468,
        "_internal_originalTime": 1689842000000
    },
    {
        "time": 1689842001000,
        "value": 139.471,
        "_internal_originalTime": 1689842001000
    },
    {
        "time": 1689842002000,
        "value": 139.468,
        "_internal_originalTime": 1689842002000
    },
    {
        "time": 1689842004000,
        "value": 139.466,
        "_internal_originalTime": 1689842004000
    },
    {
        "time": 1689842006000,
        "value": 139.467,
        "_internal_originalTime": 1689842006000
    },
    {
        "time": 1689842007000,
        "value": 139.463,
        "_internal_originalTime": 1689842007000
    },
    {
        "time": 1689842008000,
        "value": 139.462,
        "_internal_originalTime": 1689842008000
    },
    {
        "time": 1689842010000,
        "value": 139.462,
        "_internal_originalTime": 1689842010000
    },
    {
        "time": 1689842011000,
        "value": 139.467,
        "_internal_originalTime": 1689842011000
    },
    {
        "time": 1689842012000,
        "value": 139.466,
        "_internal_originalTime": 1689842012000
    },
    {
        "time": 1689842013000,
        "value": 139.467,
        "_internal_originalTime": 1689842013000
    },
    {
        "time": 1689842014000,
        "value": 139.468,
        "_internal_originalTime": 1689842014000
    },
    {
        "time": 1689842016000,
        "value": 139.458,
        "_internal_originalTime": 1689842016000
    },
    {
        "time": 1689842017000,
        "value": 139.459,
        "_internal_originalTime": 1689842017000
    },
    {
        "time": 1689842019000,
        "value": 139.458,
        "_internal_originalTime": 1689842019000
    },
    {
        "time": 1689842020000,
        "value": 139.45,
        "_internal_originalTime": 1689842020000
    },
    {
        "time": 1689842021000,
        "value": 139.457,
        "_internal_originalTime": 1689842021000
    },
    {
        "time": 1689842022000,
        "value": 139.459,
        "_internal_originalTime": 1689842022000
    },
    {
        "time": 1689842023000,
        "value": 139.448,
        "_internal_originalTime": 1689842023000
    },
    {
        "time": 1689842025000,
        "value": 139.461,
        "_internal_originalTime": 1689842025000
    },
    {
        "time": 1689842026000,
        "value": 139.461,
        "_internal_originalTime": 1689842026000
    },
    {
        "time": 1689842027000,
        "value": 139.461,
        "_internal_originalTime": 1689842027000
    },
    {
        "time": 1689842028000,
        "value": 139.462,
        "_internal_originalTime": 1689842028000
    },
    {
        "time": 1689842030000,
        "value": 139.458,
        "_internal_originalTime": 1689842030000
    },
    {
        "time": 1689842031000,
        "value": 139.46,
        "_internal_originalTime": 1689842031000
    },
    {
        "time": 1689842032000,
        "value": 139.465,
        "_internal_originalTime": 1689842032000
    },
    {
        "time": 1689842033000,
        "value": 139.462,
        "_internal_originalTime": 1689842033000
    },
    {
        "time": 1689842034000,
        "value": 139.466,
        "_internal_originalTime": 1689842034000
    },
    {
        "time": 1689842035000,
        "value": 139.457,
        "_internal_originalTime": 1689842035000
    },
    {
        "time": 1689842037000,
        "value": 139.452,
        "_internal_originalTime": 1689842037000
    },
    {
        "time": 1689842038000,
        "value": 139.45,
        "_internal_originalTime": 1689842038000
    },
    {
        "time": 1689842039000,
        "value": 139.44,
        "_internal_originalTime": 1689842039000
    },
    {
        "time": 1689842040000,
        "value": 139.447,
        "_internal_originalTime": 1689842040000
    },
    {
        "time": 1689842041000,
        "value": 139.447,
        "_internal_originalTime": 1689842041000
    },
    {
        "time": 1689842042000,
        "value": 139.454,
        "_internal_originalTime": 1689842042000
    },
    {
        "time": 1689842044000,
        "value": 139.448,
        "_internal_originalTime": 1689842044000
    },
    {
        "time": 1689842046000,
        "value": 139.449,
        "_internal_originalTime": 1689842046000
    },
    {
        "time": 1689842047000,
        "value": 139.448,
        "_internal_originalTime": 1689842047000
    },
    {
        "time": 1689842048000,
        "value": 139.452,
        "_internal_originalTime": 1689842048000
    },
    {
        "time": 1689842050000,
        "value": 139.454,
        "_internal_originalTime": 1689842050000
    },
    {
        "time": 1689842051000,
        "value": 139.451,
        "_internal_originalTime": 1689842051000
    },
    {
        "time": 1689842052000,
        "value": 139.455,
        "_internal_originalTime": 1689842052000
    },
    {
        "time": 1689842053000,
        "value": 139.454,
        "_internal_originalTime": 1689842053000
    },
    {
        "time": 1689842054000,
        "value": 139.448,
        "_internal_originalTime": 1689842054000
    },
    {
        "time": 1689842055000,
        "value": 139.453,
        "_internal_originalTime": 1689842055000
    },
    {
        "time": 1689842057000,
        "value": 139.449,
        "_internal_originalTime": 1689842057000
    },
    {
        "time": 1689842059000,
        "value": 139.452,
        "_internal_originalTime": 1689842059000
    },
    {
        "time": 1689842061000,
        "value": 139.456,
        "_internal_originalTime": 1689842061000
    },
    {
        "time": 1689842062000,
        "value": 139.464,
        "_internal_originalTime": 1689842062000
    },
    {
        "time": 1689842063000,
        "value": 139.458,
        "_internal_originalTime": 1689842063000
    },
    {
        "time": 1689842064000,
        "value": 139.462,
        "_internal_originalTime": 1689842064000
    },
    {
        "time": 1689842065000,
        "value": 139.462,
        "_internal_originalTime": 1689842065000
    },
    {
        "time": 1689842066000,
        "value": 139.464,
        "_internal_originalTime": 1689842066000
    },
    {
        "time": 1689842068000,
        "value": 139.464,
        "_internal_originalTime": 1689842068000
    },
    {
        "time": 1689842069000,
        "value": 139.46,
        "_internal_originalTime": 1689842069000
    },
    {
        "time": 1689842071000,
        "value": 139.464,
        "_internal_originalTime": 1689842071000
    },
    {
        "time": 1689842073000,
        "value": 139.448,
        "_internal_originalTime": 1689842073000
    },
    {
        "time": 1689842074000,
        "value": 139.453,
        "_internal_originalTime": 1689842074000
    },
    {
        "time": 1689842075000,
        "value": 139.453,
        "_internal_originalTime": 1689842075000
    },
    {
        "time": 1689842076000,
        "value": 139.458,
        "_internal_originalTime": 1689842076000
    },
    {
        "time": 1689842077000,
        "value": 139.455,
        "_internal_originalTime": 1689842077000
    },
    {
        "time": 1689842078000,
        "value": 139.451,
        "_internal_originalTime": 1689842078000
    },
    {
        "time": 1689842080000,
        "value": 139.452,
        "_internal_originalTime": 1689842080000
    },
    {
        "time": 1689842081000,
        "value": 139.456,
        "_internal_originalTime": 1689842081000
    },
    {
        "time": 1689842083000,
        "value": 139.453,
        "_internal_originalTime": 1689842083000
    },
    {
        "time": 1689842085000,
        "value": 139.454,
        "_internal_originalTime": 1689842085000
    },
    {
        "time": 1689842086000,
        "value": 139.455,
        "_internal_originalTime": 1689842086000
    },
    {
        "time": 1689842087000,
        "value": 139.455,
        "_internal_originalTime": 1689842087000
    },
    {
        "time": 1689842089000,
        "value": 139.455,
        "_internal_originalTime": 1689842089000
    },
    {
        "time": 1689842090000,
        "value": 139.456,
        "_internal_originalTime": 1689842090000
    },
    {
        "time": 1689842091000,
        "value": 139.456,
        "_internal_originalTime": 1689842091000
    },
    {
        "time": 1689842092000,
        "value": 139.457,
        "_internal_originalTime": 1689842092000
    },
    {
        "time": 1689842094000,
        "value": 139.456,
        "_internal_originalTime": 1689842094000
    },
    {
        "time": 1689842096000,
        "value": 139.452,
        "_internal_originalTime": 1689842096000
    },
    {
        "time": 1689842098000,
        "value": 139.454,
        "_internal_originalTime": 1689842098000
    },
    {
        "time": 1689842099000,
        "value": 139.452,
        "_internal_originalTime": 1689842099000
    },
    {
        "time": 1689842100000,
        "value": 139.43,
        "_internal_originalTime": 1689842100000
    },
    {
        "time": 1689842101000,
        "value": 139.45,
        "_internal_originalTime": 1689842101000
    },
    {
        "time": 1689842102000,
        "value": 139.454,
        "_internal_originalTime": 1689842102000
    },
    {
        "time": 1689842104000,
        "value": 139.456,
        "_internal_originalTime": 1689842104000
    },
    {
        "time": 1689842105000,
        "value": 139.459,
        "_internal_originalTime": 1689842105000
    },
    {
        "time": 1689842106000,
        "value": 139.456,
        "_internal_originalTime": 1689842106000
    },
    {
        "time": 1689842107000,
        "value": 139.454,
        "_internal_originalTime": 1689842107000
    },
    {
        "time": 1689842108000,
        "value": 139.454,
        "_internal_originalTime": 1689842108000
    },
    {
        "time": 1689842109000,
        "value": 139.455,
        "_internal_originalTime": 1689842109000
    },
    {
        "time": 1689842111000,
        "value": 139.454,
        "_internal_originalTime": 1689842111000
    },
    {
        "time": 1689842112000,
        "value": 139.45,
        "_internal_originalTime": 1689842112000
    },
    {
        "time": 1689842113000,
        "value": 139.455,
        "_internal_originalTime": 1689842113000
    },
    {
        "time": 1689842114000,
        "value": 139.455,
        "_internal_originalTime": 1689842114000
    },
    {
        "time": 1689842115000,
        "value": 139.454,
        "_internal_originalTime": 1689842115000
    },
    {
        "time": 1689842116000,
        "value": 139.46,
        "_internal_originalTime": 1689842116000
    },
    {
        "time": 1689842117000,
        "value": 139.457,
        "_internal_originalTime": 1689842117000
    },
    {
        "time": 1689842118000,
        "value": 139.455,
        "_internal_originalTime": 1689842118000
    },
    {
        "time": 1689842119000,
        "value": 139.447,
        "_internal_originalTime": 1689842119000
    },
    {
        "time": 1689842120000,
        "value": 139.452,
        "_internal_originalTime": 1689842120000
    },
    {
        "time": 1689842121000,
        "value": 139.451,
        "_internal_originalTime": 1689842121000
    },
    {
        "time": 1689842123000,
        "value": 139.452,
        "_internal_originalTime": 1689842123000
    },
    {
        "time": 1689842124000,
        "value": 139.453,
        "_internal_originalTime": 1689842124000
    },
    {
        "time": 1689842125000,
        "value": 139.447,
        "_internal_originalTime": 1689842125000
    },
    {
        "time": 1689842126000,
        "value": 139.455,
        "_internal_originalTime": 1689842126000
    },
    {
        "time": 1689842128000,
        "value": 139.455,
        "_internal_originalTime": 1689842128000
    },
    {
        "time": 1689842129000,
        "value": 139.455,
        "_internal_originalTime": 1689842129000
    },
    {
        "time": 1689842131000,
        "value": 139.455,
        "_internal_originalTime": 1689842131000
    },
    {
        "time": 1689842132000,
        "value": 139.453,
        "_internal_originalTime": 1689842132000
    },
    {
        "time": 1689842133000,
        "value": 139.453,
        "_internal_originalTime": 1689842133000
    },
    {
        "time": 1689842134000,
        "value": 139.453,
        "_internal_originalTime": 1689842134000
    },
    {
        "time": 1689842135000,
        "value": 139.454,
        "_internal_originalTime": 1689842135000
    },
    {
        "time": 1689842137000,
        "value": 139.454,
        "_internal_originalTime": 1689842137000
    },
    {
        "time": 1689842139000,
        "value": 139.455,
        "_internal_originalTime": 1689842139000
    },
    {
        "time": 1689842140000,
        "value": 139.449,
        "_internal_originalTime": 1689842140000
    },
    {
        "time": 1689842141000,
        "value": 139.445,
        "_internal_originalTime": 1689842141000
    },
    {
        "time": 1689842142000,
        "value": 139.447,
        "_internal_originalTime": 1689842142000
    },
    {
        "time": 1689842143000,
        "value": 139.442,
        "_internal_originalTime": 1689842143000
    },
    {
        "time": 1689842144000,
        "value": 139.44,
        "_internal_originalTime": 1689842144000
    },
    {
        "time": 1689842145000,
        "value": 139.444,
        "_internal_originalTime": 1689842145000
    },
    {
        "time": 1689842146000,
        "value": 139.444,
        "_internal_originalTime": 1689842146000
    },
    {
        "time": 1689842147000,
        "value": 139.438,
        "_internal_originalTime": 1689842147000
    },
    {
        "time": 1689842149000,
        "value": 139.444,
        "_internal_originalTime": 1689842149000
    },
    {
        "time": 1689842150000,
        "value": 139.446,
        "_internal_originalTime": 1689842150000
    },
    {
        "time": 1689842151000,
        "value": 139.444,
        "_internal_originalTime": 1689842151000
    },
    {
        "time": 1689842152000,
        "value": 139.446,
        "_internal_originalTime": 1689842152000
    },
    {
        "time": 1689842154000,
        "value": 139.443,
        "_internal_originalTime": 1689842154000
    },
    {
        "time": 1689842155000,
        "value": 139.448,
        "_internal_originalTime": 1689842155000
    },
    {
        "time": 1689842158000,
        "value": 139.424,
        "_internal_originalTime": 1689842158000
    },
    {
        "time": 1689842159000,
        "value": 139.42,
        "_internal_originalTime": 1689842159000
    },
    {
        "time": 1689842160000,
        "value": 139.418,
        "_internal_originalTime": 1689842160000
    },
    {
        "time": 1689842162000,
        "value": 139.423,
        "_internal_originalTime": 1689842162000
    },
    {
        "time": 1689842164000,
        "value": 139.432,
        "_internal_originalTime": 1689842164000
    },
    {
        "time": 1689842165000,
        "value": 139.436,
        "_internal_originalTime": 1689842165000
    },
    {
        "time": 1689842166000,
        "value": 139.432,
        "_internal_originalTime": 1689842166000
    },
    {
        "time": 1689842167000,
        "value": 139.427,
        "_internal_originalTime": 1689842167000
    },
    {
        "time": 1689842169000,
        "value": 139.419,
        "_internal_originalTime": 1689842169000
    },
    {
        "time": 1689842170000,
        "value": 139.423,
        "_internal_originalTime": 1689842170000
    },
    {
        "time": 1689842171000,
        "value": 139.429,
        "_internal_originalTime": 1689842171000
    },
    {
        "time": 1689842172000,
        "value": 139.425,
        "_internal_originalTime": 1689842172000
    },
    {
        "time": 1689842174000,
        "value": 139.426,
        "_internal_originalTime": 1689842174000
    },
    {
        "time": 1689842175000,
        "value": 139.432,
        "_internal_originalTime": 1689842175000
    },
    {
        "time": 1689842176000,
        "value": 139.431,
        "_internal_originalTime": 1689842176000
    },
    {
        "time": 1689842177000,
        "value": 139.43,
        "_internal_originalTime": 1689842177000
    },
    {
        "time": 1689842179000,
        "value": 139.433,
        "_internal_originalTime": 1689842179000
    },
    {
        "time": 1689842180000,
        "value": 139.433,
        "_internal_originalTime": 1689842180000
    },
    {
        "time": 1689842182000,
        "value": 139.433,
        "_internal_originalTime": 1689842182000
    },
    {
        "time": 1689842183000,
        "value": 139.435,
        "_internal_originalTime": 1689842183000
    },
    {
        "time": 1689842184000,
        "value": 139.435,
        "_internal_originalTime": 1689842184000
    },
    {
        "time": 1689842185000,
        "value": 139.432,
        "_internal_originalTime": 1689842185000
    },
    {
        "time": 1689842186000,
        "value": 139.432,
        "_internal_originalTime": 1689842186000
    },
    {
        "time": 1689842188000,
        "value": 139.431,
        "_internal_originalTime": 1689842188000
    },
    {
        "time": 1689842189000,
        "value": 139.434,
        "_internal_originalTime": 1689842189000
    },
    {
        "time": 1689842190000,
        "value": 139.43,
        "_internal_originalTime": 1689842190000
    },
    {
        "time": 1689842191000,
        "value": 139.434,
        "_internal_originalTime": 1689842191000
    },
    {
        "time": 1689842193000,
        "value": 139.426,
        "_internal_originalTime": 1689842193000
    },
    {
        "time": 1689842194000,
        "value": 139.427,
        "_internal_originalTime": 1689842194000
    },
    {
        "time": 1689842198000,
        "value": 139.424,
        "_internal_originalTime": 1689842198000
    },
    {
        "time": 1689842199000,
        "value": 139.425,
        "_internal_originalTime": 1689842199000
    },
    {
        "time": 1689842201000,
        "value": 139.429,
        "_internal_originalTime": 1689842201000
    },
    {
        "time": 1689842202000,
        "value": 139.433,
        "_internal_originalTime": 1689842202000
    },
    {
        "time": 1689842203000,
        "value": 139.433,
        "_internal_originalTime": 1689842203000
    },
    {
        "time": 1689842204000,
        "value": 139.429,
        "_internal_originalTime": 1689842204000
    },
    {
        "time": 1689842205000,
        "value": 139.423,
        "_internal_originalTime": 1689842205000
    },
    {
        "time": 1689842207000,
        "value": 139.423,
        "_internal_originalTime": 1689842207000
    },
    {
        "time": 1689842208000,
        "value": 139.423,
        "_internal_originalTime": 1689842208000
    },
    {
        "time": 1689842210000,
        "value": 139.425,
        "_internal_originalTime": 1689842210000
    },
    {
        "time": 1689842211000,
        "value": 139.423,
        "_internal_originalTime": 1689842211000
    },
    {
        "time": 1689842213000,
        "value": 139.421,
        "_internal_originalTime": 1689842213000
    },
    {
        "time": 1689842216000,
        "value": 139.419,
        "_internal_originalTime": 1689842216000
    },
    {
        "time": 1689842219000,
        "value": 139.427,
        "_internal_originalTime": 1689842219000
    },
    {
        "time": 1689842220000,
        "value": 139.425,
        "_internal_originalTime": 1689842220000
    },
    {
        "time": 1689842221000,
        "value": 139.42,
        "_internal_originalTime": 1689842221000
    },
    {
        "time": 1689842222000,
        "value": 139.421,
        "_internal_originalTime": 1689842222000
    },
    {
        "time": 1689842223000,
        "value": 139.422,
        "_internal_originalTime": 1689842223000
    },
    {
        "time": 1689842224000,
        "value": 139.417,
        "_internal_originalTime": 1689842224000
    },
    {
        "time": 1689842225000,
        "value": 139.414,
        "_internal_originalTime": 1689842225000
    },
    {
        "time": 1689842227000,
        "value": 139.42,
        "_internal_originalTime": 1689842227000
    },
    {
        "time": 1689842228000,
        "value": 139.41,
        "_internal_originalTime": 1689842228000
    },
    {
        "time": 1689842230000,
        "value": 139.413,
        "_internal_originalTime": 1689842230000
    },
    {
        "time": 1689842231000,
        "value": 139.422,
        "_internal_originalTime": 1689842231000
    },
    {
        "time": 1689842232000,
        "value": 139.422,
        "_internal_originalTime": 1689842232000
    },
    {
        "time": 1689842233000,
        "value": 139.418,
        "_internal_originalTime": 1689842233000
    },
    {
        "time": 1689842234000,
        "value": 139.425,
        "_internal_originalTime": 1689842234000
    },
    {
        "time": 1689842235000,
        "value": 139.422,
        "_internal_originalTime": 1689842235000
    },
    {
        "time": 1689842236000,
        "value": 139.42,
        "_internal_originalTime": 1689842236000
    },
    {
        "time": 1689842237000,
        "value": 139.422,
        "_internal_originalTime": 1689842237000
    },
    {
        "time": 1689842239000,
        "value": 139.42,
        "_internal_originalTime": 1689842239000
    },
    {
        "time": 1689842240000,
        "value": 139.421,
        "_internal_originalTime": 1689842240000
    },
    {
        "time": 1689842241000,
        "value": 139.427,
        "_internal_originalTime": 1689842241000
    },
    {
        "time": 1689842243000,
        "value": 139.427,
        "_internal_originalTime": 1689842243000
    },
    {
        "time": 1689842245000,
        "value": 139.423,
        "_internal_originalTime": 1689842245000
    },
    {
        "time": 1689842247000,
        "value": 139.425,
        "_internal_originalTime": 1689842247000
    },
    {
        "time": 1689842248000,
        "value": 139.422,
        "_internal_originalTime": 1689842248000
    },
    {
        "time": 1689842249000,
        "value": 139.42,
        "_internal_originalTime": 1689842249000
    },
    {
        "time": 1689842250000,
        "value": 139.431,
        "_internal_originalTime": 1689842250000
    },
    {
        "time": 1689842251000,
        "value": 139.432,
        "_internal_originalTime": 1689842251000
    },
    {
        "time": 1689842253000,
        "value": 139.429,
        "_internal_originalTime": 1689842253000
    },
    {
        "time": 1689842255000,
        "value": 139.431,
        "_internal_originalTime": 1689842255000
    },
    {
        "time": 1689842257000,
        "value": 139.433,
        "_internal_originalTime": 1689842257000
    },
    {
        "time": 1689842258000,
        "value": 139.435,
        "_internal_originalTime": 1689842258000
    },
    {
        "time": 1689842260000,
        "value": 139.43,
        "_internal_originalTime": 1689842260000
    },
    {
        "time": 1689842261000,
        "value": 139.426,
        "_internal_originalTime": 1689842261000
    },
    {
        "time": 1689842262000,
        "value": 139.431,
        "_internal_originalTime": 1689842262000
    },
    {
        "time": 1689842263000,
        "value": 139.435,
        "_internal_originalTime": 1689842263000
    },
    {
        "time": 1689842265000,
        "value": 139.433,
        "_internal_originalTime": 1689842265000
    },
    {
        "time": 1689842266000,
        "value": 139.434,
        "_internal_originalTime": 1689842266000
    },
    {
        "time": 1689842267000,
        "value": 139.43,
        "_internal_originalTime": 1689842267000
    },
    {
        "time": 1689842268000,
        "value": 139.435,
        "_internal_originalTime": 1689842268000
    },
    {
        "time": 1689842270000,
        "value": 139.435,
        "_internal_originalTime": 1689842270000
    },
    {
        "time": 1689842271000,
        "value": 139.434,
        "_internal_originalTime": 1689842271000
    },
    {
        "time": 1689842272000,
        "value": 139.432,
        "_internal_originalTime": 1689842272000
    },
    {
        "time": 1689842273000,
        "value": 139.428,
        "_internal_originalTime": 1689842273000
    },
    {
        "time": 1689842275000,
        "value": 139.431,
        "_internal_originalTime": 1689842275000
    },
    {
        "time": 1689842276000,
        "value": 139.432,
        "_internal_originalTime": 1689842276000
    },
    {
        "time": 1689842277000,
        "value": 139.432,
        "_internal_originalTime": 1689842277000
    },
    {
        "time": 1689842278000,
        "value": 139.428,
        "_internal_originalTime": 1689842278000
    },
    {
        "time": 1689842280000,
        "value": 139.434,
        "_internal_originalTime": 1689842280000
    },
    {
        "time": 1689842281000,
        "value": 139.41,
        "_internal_originalTime": 1689842281000
    },
    {
        "time": 1689842282000,
        "value": 139.42,
        "_internal_originalTime": 1689842282000
    },
    {
        "time": 1689842283000,
        "value": 139.415,
        "_internal_originalTime": 1689842283000
    },
    {
        "time": 1689842284000,
        "value": 139.416,
        "_internal_originalTime": 1689842284000
    },
    {
        "time": 1689842286000,
        "value": 139.41,
        "_internal_originalTime": 1689842286000
    },
    {
        "time": 1689842287000,
        "value": 139.417,
        "_internal_originalTime": 1689842287000
    },
    {
        "time": 1689842289000,
        "value": 139.417,
        "_internal_originalTime": 1689842289000
    },
    {
        "time": 1689842291000,
        "value": 139.413,
        "_internal_originalTime": 1689842291000
    },
    {
        "time": 1689842292000,
        "value": 139.421,
        "_internal_originalTime": 1689842292000
    },
    {
        "time": 1689842293000,
        "value": 139.417,
        "_internal_originalTime": 1689842293000
    },
    {
        "time": 1689842295000,
        "value": 139.412,
        "_internal_originalTime": 1689842295000
    },
    {
        "time": 1689842296000,
        "value": 139.413,
        "_internal_originalTime": 1689842296000
    },
    {
        "time": 1689842297000,
        "value": 139.416,
        "_internal_originalTime": 1689842297000
    },
    {
        "time": 1689842298000,
        "value": 139.416,
        "_internal_originalTime": 1689842298000
    },
    {
        "time": 1689842299000,
        "value": 139.41,
        "_internal_originalTime": 1689842299000
    },
    {
        "time": 1689842300000,
        "value": 139.41,
        "_internal_originalTime": 1689842300000
    },
    {
        "time": 1689842302000,
        "value": 139.413,
        "_internal_originalTime": 1689842302000
    },
    {
        "time": 1689842303000,
        "value": 139.414,
        "_internal_originalTime": 1689842303000
    },
    {
        "time": 1689842304000,
        "value": 139.413,
        "_internal_originalTime": 1689842304000
    },
    {
        "time": 1689842306000,
        "value": 139.412,
        "_internal_originalTime": 1689842306000
    },
    {
        "time": 1689842307000,
        "value": 139.413,
        "_internal_originalTime": 1689842307000
    },
    {
        "time": 1689842309000,
        "value": 139.414,
        "_internal_originalTime": 1689842309000
    },
    {
        "time": 1689842311000,
        "value": 139.415,
        "_internal_originalTime": 1689842311000
    },
    {
        "time": 1689842313000,
        "value": 139.418,
        "_internal_originalTime": 1689842313000
    },
    {
        "time": 1689842315000,
        "value": 139.414,
        "_internal_originalTime": 1689842315000
    },
    {
        "time": 1689842316000,
        "value": 139.414,
        "_internal_originalTime": 1689842316000
    },
    {
        "time": 1689842317000,
        "value": 139.415,
        "_internal_originalTime": 1689842317000
    },
    {
        "time": 1689842318000,
        "value": 139.409,
        "_internal_originalTime": 1689842318000
    },
    {
        "time": 1689842320000,
        "value": 139.416,
        "_internal_originalTime": 1689842320000
    },
    {
        "time": 1689842321000,
        "value": 139.412,
        "_internal_originalTime": 1689842321000
    },
    {
        "time": 1689842323000,
        "value": 139.408,
        "_internal_originalTime": 1689842323000
    },
    {
        "time": 1689842325000,
        "value": 139.41,
        "_internal_originalTime": 1689842325000
    },
    {
        "time": 1689842326000,
        "value": 139.408,
        "_internal_originalTime": 1689842326000
    },
    {
        "time": 1689842328000,
        "value": 139.414,
        "_internal_originalTime": 1689842328000
    },
    {
        "time": 1689842329000,
        "value": 139.414,
        "_internal_originalTime": 1689842329000
    },
    {
        "time": 1689842330000,
        "value": 139.411,
        "_internal_originalTime": 1689842330000
    },
    {
        "time": 1689842333000,
        "value": 139.414,
        "_internal_originalTime": 1689842333000
    },
    {
        "time": 1689842334000,
        "value": 139.419,
        "_internal_originalTime": 1689842334000
    },
    {
        "time": 1689842335000,
        "value": 139.417,
        "_internal_originalTime": 1689842335000
    },
    {
        "time": 1689842336000,
        "value": 139.422,
        "_internal_originalTime": 1689842336000
    },
    {
        "time": 1689842338000,
        "value": 139.425,
        "_internal_originalTime": 1689842338000
    },
    {
        "time": 1689842340000,
        "value": 139.425,
        "_internal_originalTime": 1689842340000
    },
    {
        "time": 1689842341000,
        "value": 139.422,
        "_internal_originalTime": 1689842341000
    },
    {
        "time": 1689842343000,
        "value": 139.419,
        "_internal_originalTime": 1689842343000
    },
    {
        "time": 1689842344000,
        "value": 139.417,
        "_internal_originalTime": 1689842344000
    },
    {
        "time": 1689842345000,
        "value": 139.418,
        "_internal_originalTime": 1689842345000
    },
    {
        "time": 1689842347000,
        "value": 139.42,
        "_internal_originalTime": 1689842347000
    },
    {
        "time": 1689842348000,
        "value": 139.423,
        "_internal_originalTime": 1689842348000
    },
    {
        "time": 1689842349000,
        "value": 139.422,
        "_internal_originalTime": 1689842349000
    },
    {
        "time": 1689842350000,
        "value": 139.428,
        "_internal_originalTime": 1689842350000
    },
    {
        "time": 1689842351000,
        "value": 139.425,
        "_internal_originalTime": 1689842351000
    },
    {
        "time": 1689842353000,
        "value": 139.425,
        "_internal_originalTime": 1689842353000
    },
    {
        "time": 1689842354000,
        "value": 139.428,
        "_internal_originalTime": 1689842354000
    },
    {
        "time": 1689842355000,
        "value": 139.428,
        "_internal_originalTime": 1689842355000
    },
    {
        "time": 1689842356000,
        "value": 139.427,
        "_internal_originalTime": 1689842356000
    },
    {
        "time": 1689842358000,
        "value": 139.427,
        "_internal_originalTime": 1689842358000
    },
    {
        "time": 1689842360000,
        "value": 139.432,
        "_internal_originalTime": 1689842360000
    },
    {
        "time": 1689842361000,
        "value": 139.435,
        "_internal_originalTime": 1689842361000
    },
    {
        "time": 1689842362000,
        "value": 139.435,
        "_internal_originalTime": 1689842362000
    },
    {
        "time": 1689842365000,
        "value": 139.441,
        "_internal_originalTime": 1689842365000
    },
    {
        "time": 1689842366000,
        "value": 139.437,
        "_internal_originalTime": 1689842366000
    },
    {
        "time": 1689842367000,
        "value": 139.44,
        "_internal_originalTime": 1689842367000
    },
    {
        "time": 1689842368000,
        "value": 139.441,
        "_internal_originalTime": 1689842368000
    },
    {
        "time": 1689842369000,
        "value": 139.434,
        "_internal_originalTime": 1689842369000
    },
    {
        "time": 1689842370000,
        "value": 139.437,
        "_internal_originalTime": 1689842370000
    },
    {
        "time": 1689842371000,
        "value": 139.438,
        "_internal_originalTime": 1689842371000
    },
    {
        "time": 1689842372000,
        "value": 139.441,
        "_internal_originalTime": 1689842372000
    },
    {
        "time": 1689842378000,
        "value": 139.438,
        "_internal_originalTime": 1689842378000
    },
    {
        "time": 1689842379000,
        "value": 139.441,
        "_internal_originalTime": 1689842379000
    },
    {
        "time": 1689842381000,
        "value": 139.441,
        "_internal_originalTime": 1689842381000
    },
    {
        "time": 1689842385000,
        "value": 139.434,
        "_internal_originalTime": 1689842385000
    },
    {
        "time": 1689842387000,
        "value": 139.432,
        "_internal_originalTime": 1689842387000
    },
    {
        "time": 1689842388000,
        "value": 139.433,
        "_internal_originalTime": 1689842388000
    },
    {
        "time": 1689842389000,
        "value": 139.434,
        "_internal_originalTime": 1689842389000
    },
    {
        "time": 1689842390000,
        "value": 139.433,
        "_internal_originalTime": 1689842390000
    },
    {
        "time": 1689842392000,
        "value": 139.433,
        "_internal_originalTime": 1689842392000
    },
    {
        "time": 1689842393000,
        "value": 139.437,
        "_internal_originalTime": 1689842393000
    },
    {
        "time": 1689842394000,
        "value": 139.441,
        "_internal_originalTime": 1689842394000
    },
    {
        "time": 1689842395000,
        "value": 139.439,
        "_internal_originalTime": 1689842395000
    },
    {
        "time": 1689842396000,
        "value": 139.436,
        "_internal_originalTime": 1689842396000
    },
    {
        "time": 1689842398000,
        "value": 139.437,
        "_internal_originalTime": 1689842398000
    },
    {
        "time": 1689842399000,
        "value": 139.442,
        "_internal_originalTime": 1689842399000
    },
    {
        "time": 1689842400000,
        "value": 139.437,
        "_internal_originalTime": 1689842400000
    },
    {
        "time": 1689842402000,
        "value": 139.434,
        "_internal_originalTime": 1689842402000
    },
    {
        "time": 1689842403000,
        "value": 139.432,
        "_internal_originalTime": 1689842403000
    },
    {
        "time": 1689842404000,
        "value": 139.436,
        "_internal_originalTime": 1689842404000
    },
    {
        "time": 1689842405000,
        "value": 139.436,
        "_internal_originalTime": 1689842405000
    },
    {
        "time": 1689842406000,
        "value": 139.434,
        "_internal_originalTime": 1689842406000
    },
    {
        "time": 1689842408000,
        "value": 139.437,
        "_internal_originalTime": 1689842408000
    },
    {
        "time": 1689842409000,
        "value": 139.44,
        "_internal_originalTime": 1689842409000
    },
    {
        "time": 1689842411000,
        "value": 139.436,
        "_internal_originalTime": 1689842411000
    },
    {
        "time": 1689842413000,
        "value": 139.441,
        "_internal_originalTime": 1689842413000
    },
    {
        "time": 1689842415000,
        "value": 139.442,
        "_internal_originalTime": 1689842415000
    },
    {
        "time": 1689842419000,
        "value": 139.442,
        "_internal_originalTime": 1689842419000
    },
    {
        "time": 1689842420000,
        "value": 139.446,
        "_internal_originalTime": 1689842420000
    },
    {
        "time": 1689842422000,
        "value": 139.442,
        "_internal_originalTime": 1689842422000
    },
    {
        "time": 1689842423000,
        "value": 139.443,
        "_internal_originalTime": 1689842423000
    },
    {
        "time": 1689842424000,
        "value": 139.442,
        "_internal_originalTime": 1689842424000
    },
    {
        "time": 1689842426000,
        "value": 139.441,
        "_internal_originalTime": 1689842426000
    },
    {
        "time": 1689842427000,
        "value": 139.436,
        "_internal_originalTime": 1689842427000
    },
    {
        "time": 1689842428000,
        "value": 139.438,
        "_internal_originalTime": 1689842428000
    },
    {
        "time": 1689842430000,
        "value": 139.436,
        "_internal_originalTime": 1689842430000
    },
    {
        "time": 1689842431000,
        "value": 139.43,
        "_internal_originalTime": 1689842431000
    },
    {
        "time": 1689842432000,
        "value": 139.426,
        "_internal_originalTime": 1689842432000
    },
    {
        "time": 1689842433000,
        "value": 139.425,
        "_internal_originalTime": 1689842433000
    },
    {
        "time": 1689842435000,
        "value": 139.42,
        "_internal_originalTime": 1689842435000
    },
    {
        "time": 1689842436000,
        "value": 139.423,
        "_internal_originalTime": 1689842436000
    },
    {
        "time": 1689842437000,
        "value": 139.418,
        "_internal_originalTime": 1689842437000
    },
    {
        "time": 1689842439000,
        "value": 139.416,
        "_internal_originalTime": 1689842439000
    },
    {
        "time": 1689842440000,
        "value": 139.414,
        "_internal_originalTime": 1689842440000
    },
    {
        "time": 1689842441000,
        "value": 139.416,
        "_internal_originalTime": 1689842441000
    },
    {
        "time": 1689842442000,
        "value": 139.416,
        "_internal_originalTime": 1689842442000
    },
    {
        "time": 1689842444000,
        "value": 139.411,
        "_internal_originalTime": 1689842444000
    },
    {
        "time": 1689842445000,
        "value": 139.412,
        "_internal_originalTime": 1689842445000
    },
    {
        "time": 1689842446000,
        "value": 139.415,
        "_internal_originalTime": 1689842446000
    },
    {
        "time": 1689842447000,
        "value": 139.411,
        "_internal_originalTime": 1689842447000
    },
    {
        "time": 1689842449000,
        "value": 139.408,
        "_internal_originalTime": 1689842449000
    },
    {
        "time": 1689842450000,
        "value": 139.407,
        "_internal_originalTime": 1689842450000
    },
    {
        "time": 1689842451000,
        "value": 139.407,
        "_internal_originalTime": 1689842451000
    },
    {
        "time": 1689842452000,
        "value": 139.407,
        "_internal_originalTime": 1689842452000
    },
    {
        "time": 1689842454000,
        "value": 139.398,
        "_internal_originalTime": 1689842454000
    },
    {
        "time": 1689842455000,
        "value": 139.402,
        "_internal_originalTime": 1689842455000
    },
    {
        "time": 1689842456000,
        "value": 139.403,
        "_internal_originalTime": 1689842456000
    },
    {
        "time": 1689842457000,
        "value": 139.399,
        "_internal_originalTime": 1689842457000
    },
    {
        "time": 1689842459000,
        "value": 139.398,
        "_internal_originalTime": 1689842459000
    },
    {
        "time": 1689842460000,
        "value": 139.403,
        "_internal_originalTime": 1689842460000
    },
    {
        "time": 1689842462000,
        "value": 139.396,
        "_internal_originalTime": 1689842462000
    },
    {
        "time": 1689842463000,
        "value": 139.395,
        "_internal_originalTime": 1689842463000
    },
    {
        "time": 1689842464000,
        "value": 139.391,
        "_internal_originalTime": 1689842464000
    },
    {
        "time": 1689842465000,
        "value": 139.406,
        "_internal_originalTime": 1689842465000
    },
    {
        "time": 1689842467000,
        "value": 139.406,
        "_internal_originalTime": 1689842467000
    },
    {
        "time": 1689842471000,
        "value": 139.406,
        "_internal_originalTime": 1689842471000
    },
    {
        "time": 1689842473000,
        "value": 139.408,
        "_internal_originalTime": 1689842473000
    },
    {
        "time": 1689842474000,
        "value": 139.41,
        "_internal_originalTime": 1689842474000
    },
    {
        "time": 1689842475000,
        "value": 139.409,
        "_internal_originalTime": 1689842475000
    },
    {
        "time": 1689842477000,
        "value": 139.408,
        "_internal_originalTime": 1689842477000
    },
    {
        "time": 1689842479000,
        "value": 139.408,
        "_internal_originalTime": 1689842479000
    },
    {
        "time": 1689842480000,
        "value": 139.404,
        "_internal_originalTime": 1689842480000
    },
    {
        "time": 1689842481000,
        "value": 139.407,
        "_internal_originalTime": 1689842481000
    },
    {
        "time": 1689842482000,
        "value": 139.403,
        "_internal_originalTime": 1689842482000
    },
    {
        "time": 1689842483000,
        "value": 139.402,
        "_internal_originalTime": 1689842483000
    },
    {
        "time": 1689842484000,
        "value": 139.403,
        "_internal_originalTime": 1689842484000
    },
    {
        "time": 1689842485000,
        "value": 139.402,
        "_internal_originalTime": 1689842485000
    },
    {
        "time": 1689842487000,
        "value": 139.41,
        "_internal_originalTime": 1689842487000
    },
    {
        "time": 1689842488000,
        "value": 139.408,
        "_internal_originalTime": 1689842488000
    },
    {
        "time": 1689842490000,
        "value": 139.41,
        "_internal_originalTime": 1689842490000
    },
    {
        "time": 1689842491000,
        "value": 139.41,
        "_internal_originalTime": 1689842491000
    },
    {
        "time": 1689842492000,
        "value": 139.409,
        "_internal_originalTime": 1689842492000
    },
    {
        "time": 1689842493000,
        "value": 139.41,
        "_internal_originalTime": 1689842493000
    },
    {
        "time": 1689842495000,
        "value": 139.413,
        "_internal_originalTime": 1689842495000
    },
    {
        "time": 1689842496000,
        "value": 139.404,
        "_internal_originalTime": 1689842496000
    },
    {
        "time": 1689842497000,
        "value": 139.411,
        "_internal_originalTime": 1689842497000
    },
    {
        "time": 1689842498000,
        "value": 139.41,
        "_internal_originalTime": 1689842498000
    },
    {
        "time": 1689842499000,
        "value": 139.409,
        "_internal_originalTime": 1689842499000
    },
    {
        "time": 1689842501000,
        "value": 139.418,
        "_internal_originalTime": 1689842501000
    },
    {
        "time": 1689842502000,
        "value": 139.42,
        "_internal_originalTime": 1689842502000
    },
    {
        "time": 1689842503000,
        "value": 139.426,
        "_internal_originalTime": 1689842503000
    },
    {
        "time": 1689842505000,
        "value": 139.42,
        "_internal_originalTime": 1689842505000
    },
    {
        "time": 1689842506000,
        "value": 139.422,
        "_internal_originalTime": 1689842506000
    },
    {
        "time": 1689842507000,
        "value": 139.422,
        "_internal_originalTime": 1689842507000
    },
    {
        "time": 1689842509000,
        "value": 139.421,
        "_internal_originalTime": 1689842509000
    },
    {
        "time": 1689842510000,
        "value": 139.423,
        "_internal_originalTime": 1689842510000
    },
    {
        "time": 1689842512000,
        "value": 139.422,
        "_internal_originalTime": 1689842512000
    },
    {
        "time": 1689842513000,
        "value": 139.423,
        "_internal_originalTime": 1689842513000
    },
    {
        "time": 1689842516000,
        "value": 139.423,
        "_internal_originalTime": 1689842516000
    },
    {
        "time": 1689842517000,
        "value": 139.425,
        "_internal_originalTime": 1689842517000
    },
    {
        "time": 1689842518000,
        "value": 139.423,
        "_internal_originalTime": 1689842518000
    },
    {
        "time": 1689842519000,
        "value": 139.426,
        "_internal_originalTime": 1689842519000
    },
    {
        "time": 1689842521000,
        "value": 139.4,
        "_internal_originalTime": 1689842521000
    },
    {
        "time": 1689842522000,
        "value": 139.426,
        "_internal_originalTime": 1689842522000
    },
    {
        "time": 1689842523000,
        "value": 139.423,
        "_internal_originalTime": 1689842523000
    },
    {
        "time": 1689842524000,
        "value": 139.422,
        "_internal_originalTime": 1689842524000
    },
    {
        "time": 1689842526000,
        "value": 139.427,
        "_internal_originalTime": 1689842526000
    },
    {
        "time": 1689842527000,
        "value": 139.424,
        "_internal_originalTime": 1689842527000
    },
    {
        "time": 1689842529000,
        "value": 139.42,
        "_internal_originalTime": 1689842529000
    },
    {
        "time": 1689842531000,
        "value": 139.419,
        "_internal_originalTime": 1689842531000
    },
    {
        "time": 1689842532000,
        "value": 139.414,
        "_internal_originalTime": 1689842532000
    },
    {
        "time": 1689842533000,
        "value": 139.414,
        "_internal_originalTime": 1689842533000
    },
    {
        "time": 1689842534000,
        "value": 139.417,
        "_internal_originalTime": 1689842534000
    },
    {
        "time": 1689842536000,
        "value": 139.416,
        "_internal_originalTime": 1689842536000
    },
    {
        "time": 1689842537000,
        "value": 139.42,
        "_internal_originalTime": 1689842537000
    },
    {
        "time": 1689842539000,
        "value": 139.417,
        "_internal_originalTime": 1689842539000
    },
    {
        "time": 1689842540000,
        "value": 139.417,
        "_internal_originalTime": 1689842540000
    },
    {
        "time": 1689842541000,
        "value": 139.417,
        "_internal_originalTime": 1689842541000
    },
    {
        "time": 1689842542000,
        "value": 139.414,
        "_internal_originalTime": 1689842542000
    },
    {
        "time": 1689842543000,
        "value": 139.415,
        "_internal_originalTime": 1689842543000
    },
    {
        "time": 1689842545000,
        "value": 139.414,
        "_internal_originalTime": 1689842545000
    },
    {
        "time": 1689842547000,
        "value": 139.414,
        "_internal_originalTime": 1689842547000
    },
    {
        "time": 1689842548000,
        "value": 139.418,
        "_internal_originalTime": 1689842548000
    },
    {
        "time": 1689842550000,
        "value": 139.416,
        "_internal_originalTime": 1689842550000
    },
    {
        "time": 1689842551000,
        "value": 139.409,
        "_internal_originalTime": 1689842551000
    },
    {
        "time": 1689842552000,
        "value": 139.408,
        "_internal_originalTime": 1689842552000
    },
    {
        "time": 1689842553000,
        "value": 139.413,
        "_internal_originalTime": 1689842553000
    },
    {
        "time": 1689842555000,
        "value": 139.41,
        "_internal_originalTime": 1689842555000
    },
    {
        "time": 1689842556000,
        "value": 139.41,
        "_internal_originalTime": 1689842556000
    },
    {
        "time": 1689842557000,
        "value": 139.41,
        "_internal_originalTime": 1689842557000
    },
    {
        "time": 1689842558000,
        "value": 139.407,
        "_internal_originalTime": 1689842558000
    },
    {
        "time": 1689842560000,
        "value": 139.419,
        "_internal_originalTime": 1689842560000
    },
    {
        "time": 1689842561000,
        "value": 139.421,
        "_internal_originalTime": 1689842561000
    },
    {
        "time": 1689842562000,
        "value": 139.421,
        "_internal_originalTime": 1689842562000
    },
    {
        "time": 1689842563000,
        "value": 139.42,
        "_internal_originalTime": 1689842563000
    },
    {
        "time": 1689842565000,
        "value": 139.416,
        "_internal_originalTime": 1689842565000
    },
    {
        "time": 1689842567000,
        "value": 139.417,
        "_internal_originalTime": 1689842567000
    },
    {
        "time": 1689842568000,
        "value": 139.413,
        "_internal_originalTime": 1689842568000
    },
    {
        "time": 1689842570000,
        "value": 139.411,
        "_internal_originalTime": 1689842570000
    },
    {
        "time": 1689842572000,
        "value": 139.415,
        "_internal_originalTime": 1689842572000
    },
    {
        "time": 1689842573000,
        "value": 139.414,
        "_internal_originalTime": 1689842573000
    },
    {
        "time": 1689842574000,
        "value": 139.417,
        "_internal_originalTime": 1689842574000
    },
    {
        "time": 1689842576000,
        "value": 139.42,
        "_internal_originalTime": 1689842576000
    },
    {
        "time": 1689842578000,
        "value": 139.421,
        "_internal_originalTime": 1689842578000
    },
    {
        "time": 1689842580000,
        "value": 139.421,
        "_internal_originalTime": 1689842580000
    },
    {
        "time": 1689842582000,
        "value": 139.421,
        "_internal_originalTime": 1689842582000
    },
    {
        "time": 1689842583000,
        "value": 139.424,
        "_internal_originalTime": 1689842583000
    },
    {
        "time": 1689842584000,
        "value": 139.421,
        "_internal_originalTime": 1689842584000
    },
    {
        "time": 1689842585000,
        "value": 139.424,
        "_internal_originalTime": 1689842585000
    },
    {
        "time": 1689842587000,
        "value": 139.423,
        "_internal_originalTime": 1689842587000
    },
    {
        "time": 1689842588000,
        "value": 139.417,
        "_internal_originalTime": 1689842588000
    },
    {
        "time": 1689842589000,
        "value": 139.421,
        "_internal_originalTime": 1689842589000
    },
    {
        "time": 1689842591000,
        "value": 139.416,
        "_internal_originalTime": 1689842591000
    },
    {
        "time": 1689842592000,
        "value": 139.415,
        "_internal_originalTime": 1689842592000
    },
    {
        "time": 1689842594000,
        "value": 139.414,
        "_internal_originalTime": 1689842594000
    },
    {
        "time": 1689842595000,
        "value": 139.418,
        "_internal_originalTime": 1689842595000
    },
    {
        "time": 1689842596000,
        "value": 139.426,
        "_internal_originalTime": 1689842596000
    },
    {
        "time": 1689842597000,
        "value": 139.421,
        "_internal_originalTime": 1689842597000
    },
    {
        "time": 1689842598000,
        "value": 139.427,
        "_internal_originalTime": 1689842598000
    },
    {
        "time": 1689842599000,
        "value": 139.425,
        "_internal_originalTime": 1689842599000
    },
    {
        "time": 1689842600000,
        "value": 139.424,
        "_internal_originalTime": 1689842600000
    },
    {
        "time": 1689842602000,
        "value": 139.423,
        "_internal_originalTime": 1689842602000
    },
    {
        "time": 1689842603000,
        "value": 139.427,
        "_internal_originalTime": 1689842603000
    },
    {
        "time": 1689842604000,
        "value": 139.426,
        "_internal_originalTime": 1689842604000
    },
    {
        "time": 1689842605000,
        "value": 139.427,
        "_internal_originalTime": 1689842605000
    },
    {
        "time": 1689842609000,
        "value": 139.427,
        "_internal_originalTime": 1689842609000
    },
    {
        "time": 1689842610000,
        "value": 139.426,
        "_internal_originalTime": 1689842610000
    },
    {
        "time": 1689842611000,
        "value": 139.423,
        "_internal_originalTime": 1689842611000
    },
    {
        "time": 1689842612000,
        "value": 139.424,
        "_internal_originalTime": 1689842612000
    },
    {
        "time": 1689842616000,
        "value": 139.418,
        "_internal_originalTime": 1689842616000
    },
    {
        "time": 1689842617000,
        "value": 139.425,
        "_internal_originalTime": 1689842617000
    },
    {
        "time": 1689842618000,
        "value": 139.42,
        "_internal_originalTime": 1689842618000
    },
    {
        "time": 1689842620000,
        "value": 139.425,
        "_internal_originalTime": 1689842620000
    },
    {
        "time": 1689842621000,
        "value": 139.422,
        "_internal_originalTime": 1689842621000
    },
    {
        "time": 1689842622000,
        "value": 139.425,
        "_internal_originalTime": 1689842622000
    },
    {
        "time": 1689842623000,
        "value": 139.421,
        "_internal_originalTime": 1689842623000
    },
    {
        "time": 1689842625000,
        "value": 139.425,
        "_internal_originalTime": 1689842625000
    },
    {
        "time": 1689842626000,
        "value": 139.425,
        "_internal_originalTime": 1689842626000
    },
    {
        "time": 1689842628000,
        "value": 139.421,
        "_internal_originalTime": 1689842628000
    },
    {
        "time": 1689842629000,
        "value": 139.423,
        "_internal_originalTime": 1689842629000
    },
    {
        "time": 1689842632000,
        "value": 139.423,
        "_internal_originalTime": 1689842632000
    },
    {
        "time": 1689842634000,
        "value": 139.423,
        "_internal_originalTime": 1689842634000
    },
    {
        "time": 1689842635000,
        "value": 139.423,
        "_internal_originalTime": 1689842635000
    },
    {
        "time": 1689842637000,
        "value": 139.423,
        "_internal_originalTime": 1689842637000
    },
    {
        "time": 1689842638000,
        "value": 139.424,
        "_internal_originalTime": 1689842638000
    },
    {
        "time": 1689842639000,
        "value": 139.424,
        "_internal_originalTime": 1689842639000
    },
    {
        "time": 1689842640000,
        "value": 139.426,
        "_internal_originalTime": 1689842640000
    },
    {
        "time": 1689842642000,
        "value": 139.423,
        "_internal_originalTime": 1689842642000
    },
    {
        "time": 1689842643000,
        "value": 139.422,
        "_internal_originalTime": 1689842643000
    },
    {
        "time": 1689842645000,
        "value": 139.425,
        "_internal_originalTime": 1689842645000
    },
    {
        "time": 1689842646000,
        "value": 139.425,
        "_internal_originalTime": 1689842646000
    },
    {
        "time": 1689842647000,
        "value": 139.42,
        "_internal_originalTime": 1689842647000
    },
    {
        "time": 1689842648000,
        "value": 139.419,
        "_internal_originalTime": 1689842648000
    },
    {
        "time": 1689842649000,
        "value": 139.422,
        "_internal_originalTime": 1689842649000
    },
    {
        "time": 1689842650000,
        "value": 139.42,
        "_internal_originalTime": 1689842650000
    },
    {
        "time": 1689842651000,
        "value": 139.426,
        "_internal_originalTime": 1689842651000
    },
    {
        "time": 1689842653000,
        "value": 139.425,
        "_internal_originalTime": 1689842653000
    },
    {
        "time": 1689842654000,
        "value": 139.422,
        "_internal_originalTime": 1689842654000
    },
    {
        "time": 1689842657000,
        "value": 139.43,
        "_internal_originalTime": 1689842657000
    },
    {
        "time": 1689842658000,
        "value": 139.422,
        "_internal_originalTime": 1689842658000
    },
    {
        "time": 1689842659000,
        "value": 139.426,
        "_internal_originalTime": 1689842659000
    },
    {
        "time": 1689842660000,
        "value": 139.423,
        "_internal_originalTime": 1689842660000
    },
    {
        "time": 1689842663000,
        "value": 139.42,
        "_internal_originalTime": 1689842663000
    },
    {
        "time": 1689842664000,
        "value": 139.42,
        "_internal_originalTime": 1689842664000
    },
    {
        "time": 1689842665000,
        "value": 139.426,
        "_internal_originalTime": 1689842665000
    },
    {
        "time": 1689842666000,
        "value": 139.426,
        "_internal_originalTime": 1689842666000
    },
    {
        "time": 1689842668000,
        "value": 139.42,
        "_internal_originalTime": 1689842668000
    },
    {
        "time": 1689842670000,
        "value": 139.417,
        "_internal_originalTime": 1689842670000
    },
    {
        "time": 1689842672000,
        "value": 139.415,
        "_internal_originalTime": 1689842672000
    },
    {
        "time": 1689842674000,
        "value": 139.414,
        "_internal_originalTime": 1689842674000
    },
    {
        "time": 1689842676000,
        "value": 139.415,
        "_internal_originalTime": 1689842676000
    },
    {
        "time": 1689842678000,
        "value": 139.417,
        "_internal_originalTime": 1689842678000
    },
    {
        "time": 1689842679000,
        "value": 139.423,
        "_internal_originalTime": 1689842679000
    },
    {
        "time": 1689842680000,
        "value": 139.42,
        "_internal_originalTime": 1689842680000
    },
    {
        "time": 1689842681000,
        "value": 139.423,
        "_internal_originalTime": 1689842681000
    },
    {
        "time": 1689842682000,
        "value": 139.424,
        "_internal_originalTime": 1689842682000
    },
    {
        "time": 1689842684000,
        "value": 139.423,
        "_internal_originalTime": 1689842684000
    },
    {
        "time": 1689842685000,
        "value": 139.425,
        "_internal_originalTime": 1689842685000
    },
    {
        "time": 1689842686000,
        "value": 139.421,
        "_internal_originalTime": 1689842686000
    },
    {
        "time": 1689842687000,
        "value": 139.424,
        "_internal_originalTime": 1689842687000
    },
    {
        "time": 1689842688000,
        "value": 139.424,
        "_internal_originalTime": 1689842688000
    },
    {
        "time": 1689842689000,
        "value": 139.424,
        "_internal_originalTime": 1689842689000
    },
    {
        "time": 1689842694000,
        "value": 139.42,
        "_internal_originalTime": 1689842694000
    },
    {
        "time": 1689842695000,
        "value": 139.412,
        "_internal_originalTime": 1689842695000
    },
    {
        "time": 1689842696000,
        "value": 139.415,
        "_internal_originalTime": 1689842696000
    },
    {
        "time": 1689842697000,
        "value": 139.414,
        "_internal_originalTime": 1689842697000
    },
    {
        "time": 1689842698000,
        "value": 139.414,
        "_internal_originalTime": 1689842698000
    },
    {
        "time": 1689842700000,
        "value": 139.412,
        "_internal_originalTime": 1689842700000
    },
    {
        "time": 1689842701000,
        "value": 139.412,
        "_internal_originalTime": 1689842701000
    },
    {
        "time": 1689842702000,
        "value": 139.412,
        "_internal_originalTime": 1689842702000
    },
    {
        "time": 1689842703000,
        "value": 139.415,
        "_internal_originalTime": 1689842703000
    },
    {
        "time": 1689842704000,
        "value": 139.41,
        "_internal_originalTime": 1689842704000
    },
    {
        "time": 1689842705000,
        "value": 139.408,
        "_internal_originalTime": 1689842705000
    },
    {
        "time": 1689842706000,
        "value": 139.408,
        "_internal_originalTime": 1689842706000
    },
    {
        "time": 1689842707000,
        "value": 139.404,
        "_internal_originalTime": 1689842707000
    },
    {
        "time": 1689842709000,
        "value": 139.412,
        "_internal_originalTime": 1689842709000
    },
    {
        "time": 1689842710000,
        "value": 139.412,
        "_internal_originalTime": 1689842710000
    },
    {
        "time": 1689842711000,
        "value": 139.405,
        "_internal_originalTime": 1689842711000
    },
    {
        "time": 1689842712000,
        "value": 139.401,
        "_internal_originalTime": 1689842712000
    },
    {
        "time": 1689842714000,
        "value": 139.403,
        "_internal_originalTime": 1689842714000
    },
    {
        "time": 1689842715000,
        "value": 139.404,
        "_internal_originalTime": 1689842715000
    },
    {
        "time": 1689842717000,
        "value": 139.403,
        "_internal_originalTime": 1689842717000
    },
    {
        "time": 1689842718000,
        "value": 139.403,
        "_internal_originalTime": 1689842718000
    },
    {
        "time": 1689842719000,
        "value": 139.398,
        "_internal_originalTime": 1689842719000
    },
    {
        "time": 1689842721000,
        "value": 139.398,
        "_internal_originalTime": 1689842721000
    },
    {
        "time": 1689842722000,
        "value": 139.39,
        "_internal_originalTime": 1689842722000
    },
    {
        "time": 1689842723000,
        "value": 139.384,
        "_internal_originalTime": 1689842723000
    },
    {
        "time": 1689842724000,
        "value": 139.386,
        "_internal_originalTime": 1689842724000
    },
    {
        "time": 1689842726000,
        "value": 139.384,
        "_internal_originalTime": 1689842726000
    },
    {
        "time": 1689842727000,
        "value": 139.39,
        "_internal_originalTime": 1689842727000
    },
    {
        "time": 1689842728000,
        "value": 139.39,
        "_internal_originalTime": 1689842728000
    },
    {
        "time": 1689842730000,
        "value": 139.389,
        "_internal_originalTime": 1689842730000
    },
    {
        "time": 1689842732000,
        "value": 139.387,
        "_internal_originalTime": 1689842732000
    },
    {
        "time": 1689842733000,
        "value": 139.387,
        "_internal_originalTime": 1689842733000
    },
    {
        "time": 1689842734000,
        "value": 139.388,
        "_internal_originalTime": 1689842734000
    },
    {
        "time": 1689842736000,
        "value": 139.395,
        "_internal_originalTime": 1689842736000
    },
    {
        "time": 1689842737000,
        "value": 139.39,
        "_internal_originalTime": 1689842737000
    },
    {
        "time": 1689842739000,
        "value": 139.391,
        "_internal_originalTime": 1689842739000
    },
    {
        "time": 1689842741000,
        "value": 139.392,
        "_internal_originalTime": 1689842741000
    },
    {
        "time": 1689842743000,
        "value": 139.393,
        "_internal_originalTime": 1689842743000
    },
    {
        "time": 1689842744000,
        "value": 139.396,
        "_internal_originalTime": 1689842744000
    },
    {
        "time": 1689842745000,
        "value": 139.396,
        "_internal_originalTime": 1689842745000
    },
    {
        "time": 1689842746000,
        "value": 139.397,
        "_internal_originalTime": 1689842746000
    },
    {
        "time": 1689842748000,
        "value": 139.395,
        "_internal_originalTime": 1689842748000
    },
    {
        "time": 1689842749000,
        "value": 139.396,
        "_internal_originalTime": 1689842749000
    },
    {
        "time": 1689842750000,
        "value": 139.396,
        "_internal_originalTime": 1689842750000
    },
    {
        "time": 1689842751000,
        "value": 139.396,
        "_internal_originalTime": 1689842751000
    },
    {
        "time": 1689842753000,
        "value": 139.396,
        "_internal_originalTime": 1689842753000
    },
    {
        "time": 1689842754000,
        "value": 139.392,
        "_internal_originalTime": 1689842754000
    },
    {
        "time": 1689842756000,
        "value": 139.393,
        "_internal_originalTime": 1689842756000
    },
    {
        "time": 1689842757000,
        "value": 139.392,
        "_internal_originalTime": 1689842757000
    },
    {
        "time": 1689842759000,
        "value": 139.397,
        "_internal_originalTime": 1689842759000
    },
    {
        "time": 1689842760000,
        "value": 139.392,
        "_internal_originalTime": 1689842760000
    },
    {
        "time": 1689842761000,
        "value": 139.399,
        "_internal_originalTime": 1689842761000
    },
    {
        "time": 1689842762000,
        "value": 139.397,
        "_internal_originalTime": 1689842762000
    },
    {
        "time": 1689842763000,
        "value": 139.396,
        "_internal_originalTime": 1689842763000
    },
    {
        "time": 1689842764000,
        "value": 139.391,
        "_internal_originalTime": 1689842764000
    },
    {
        "time": 1689842766000,
        "value": 139.388,
        "_internal_originalTime": 1689842766000
    },
    {
        "time": 1689842767000,
        "value": 139.389,
        "_internal_originalTime": 1689842767000
    },
    {
        "time": 1689842768000,
        "value": 139.39,
        "_internal_originalTime": 1689842768000
    },
    {
        "time": 1689842770000,
        "value": 139.392,
        "_internal_originalTime": 1689842770000
    },
    {
        "time": 1689842772000,
        "value": 139.384,
        "_internal_originalTime": 1689842772000
    },
    {
        "time": 1689842773000,
        "value": 139.383,
        "_internal_originalTime": 1689842773000
    },
    {
        "time": 1689842778000,
        "value": 139.38,
        "_internal_originalTime": 1689842778000
    },
    {
        "time": 1689842779000,
        "value": 139.376,
        "_internal_originalTime": 1689842779000
    },
    {
        "time": 1689842781000,
        "value": 139.366,
        "_internal_originalTime": 1689842781000
    },
    {
        "time": 1689842782000,
        "value": 139.36,
        "_internal_originalTime": 1689842782000
    },
    {
        "time": 1689842783000,
        "value": 139.358,
        "_internal_originalTime": 1689842783000
    },
    {
        "time": 1689842784000,
        "value": 139.36,
        "_internal_originalTime": 1689842784000
    },
    {
        "time": 1689842786000,
        "value": 139.367,
        "_internal_originalTime": 1689842786000
    },
    {
        "time": 1689842787000,
        "value": 139.361,
        "_internal_originalTime": 1689842787000
    },
    {
        "time": 1689842788000,
        "value": 139.362,
        "_internal_originalTime": 1689842788000
    },
    {
        "time": 1689842790000,
        "value": 139.367,
        "_internal_originalTime": 1689842790000
    },
    {
        "time": 1689842791000,
        "value": 139.368,
        "_internal_originalTime": 1689842791000
    },
    {
        "time": 1689842792000,
        "value": 139.368,
        "_internal_originalTime": 1689842792000
    },
    {
        "time": 1689842794000,
        "value": 139.368,
        "_internal_originalTime": 1689842794000
    },
    {
        "time": 1689842795000,
        "value": 139.374,
        "_internal_originalTime": 1689842795000
    },
    {
        "time": 1689842796000,
        "value": 139.374,
        "_internal_originalTime": 1689842796000
    },
    {
        "time": 1689842798000,
        "value": 139.377,
        "_internal_originalTime": 1689842798000
    },
    {
        "time": 1689842799000,
        "value": 139.366,
        "_internal_originalTime": 1689842799000
    },
    {
        "time": 1689842800000,
        "value": 139.373,
        "_internal_originalTime": 1689842800000
    },
    {
        "time": 1689842801000,
        "value": 139.364,
        "_internal_originalTime": 1689842801000
    },
    {
        "time": 1689842802000,
        "value": 139.374,
        "_internal_originalTime": 1689842802000
    },
    {
        "time": 1689842805000,
        "value": 139.375,
        "_internal_originalTime": 1689842805000
    },
    {
        "time": 1689842806000,
        "value": 139.375,
        "_internal_originalTime": 1689842806000
    },
    {
        "time": 1689842807000,
        "value": 139.373,
        "_internal_originalTime": 1689842807000
    },
    {
        "time": 1689842808000,
        "value": 139.378,
        "_internal_originalTime": 1689842808000
    },
    {
        "time": 1689842809000,
        "value": 139.378,
        "_internal_originalTime": 1689842809000
    },
    {
        "time": 1689842811000,
        "value": 139.379,
        "_internal_originalTime": 1689842811000
    },
    {
        "time": 1689842812000,
        "value": 139.38,
        "_internal_originalTime": 1689842812000
    },
    {
        "time": 1689842813000,
        "value": 139.384,
        "_internal_originalTime": 1689842813000
    },
    {
        "time": 1689842814000,
        "value": 139.399,
        "_internal_originalTime": 1689842814000
    },
    {
        "time": 1689842815000,
        "value": 139.393,
        "_internal_originalTime": 1689842815000
    },
    {
        "time": 1689842816000,
        "value": 139.392,
        "_internal_originalTime": 1689842816000
    },
    {
        "time": 1689842818000,
        "value": 139.403,
        "_internal_originalTime": 1689842818000
    },
    {
        "time": 1689842820000,
        "value": 139.398,
        "_internal_originalTime": 1689842820000
    },
    {
        "time": 1689842821000,
        "value": 139.4,
        "_internal_originalTime": 1689842821000
    },
    {
        "time": 1689842822000,
        "value": 139.407,
        "_internal_originalTime": 1689842822000
    },
    {
        "time": 1689842823000,
        "value": 139.41,
        "_internal_originalTime": 1689842823000
    },
    {
        "time": 1689842825000,
        "value": 139.412,
        "_internal_originalTime": 1689842825000
    },
    {
        "time": 1689842827000,
        "value": 139.413,
        "_internal_originalTime": 1689842827000
    },
    {
        "time": 1689842828000,
        "value": 139.413,
        "_internal_originalTime": 1689842828000
    },
    {
        "time": 1689842829000,
        "value": 139.411,
        "_internal_originalTime": 1689842829000
    },
    {
        "time": 1689842831000,
        "value": 139.419,
        "_internal_originalTime": 1689842831000
    },
    {
        "time": 1689842832000,
        "value": 139.419,
        "_internal_originalTime": 1689842832000
    },
    {
        "time": 1689842833000,
        "value": 139.419,
        "_internal_originalTime": 1689842833000
    },
    {
        "time": 1689842835000,
        "value": 139.424,
        "_internal_originalTime": 1689842835000
    },
    {
        "time": 1689842836000,
        "value": 139.421,
        "_internal_originalTime": 1689842836000
    },
    {
        "time": 1689842838000,
        "value": 139.428,
        "_internal_originalTime": 1689842838000
    },
    {
        "time": 1689842840000,
        "value": 139.431,
        "_internal_originalTime": 1689842840000
    },
    {
        "time": 1689842841000,
        "value": 139.435,
        "_internal_originalTime": 1689842841000
    },
    {
        "time": 1689842842000,
        "value": 139.433,
        "_internal_originalTime": 1689842842000
    },
    {
        "time": 1689842843000,
        "value": 139.432,
        "_internal_originalTime": 1689842843000
    },
    {
        "time": 1689842844000,
        "value": 139.438,
        "_internal_originalTime": 1689842844000
    },
    {
        "time": 1689842847000,
        "value": 139.432,
        "_internal_originalTime": 1689842847000
    },
    {
        "time": 1689842848000,
        "value": 139.434,
        "_internal_originalTime": 1689842848000
    },
    {
        "time": 1689842850000,
        "value": 139.423,
        "_internal_originalTime": 1689842850000
    },
    {
        "time": 1689842852000,
        "value": 139.402,
        "_internal_originalTime": 1689842852000
    },
    {
        "time": 1689842853000,
        "value": 139.407,
        "_internal_originalTime": 1689842853000
    },
    {
        "time": 1689842854000,
        "value": 139.406,
        "_internal_originalTime": 1689842854000
    },
    {
        "time": 1689842855000,
        "value": 139.402,
        "_internal_originalTime": 1689842855000
    },
    {
        "time": 1689842856000,
        "value": 139.408,
        "_internal_originalTime": 1689842856000
    },
    {
        "time": 1689842858000,
        "value": 139.404,
        "_internal_originalTime": 1689842858000
    },
    {
        "time": 1689842860000,
        "value": 139.407,
        "_internal_originalTime": 1689842860000
    },
    {
        "time": 1689842861000,
        "value": 139.406,
        "_internal_originalTime": 1689842861000
    },
    {
        "time": 1689842862000,
        "value": 139.41,
        "_internal_originalTime": 1689842862000
    },
    {
        "time": 1689842864000,
        "value": 139.414,
        "_internal_originalTime": 1689842864000
    },
    {
        "time": 1689842865000,
        "value": 139.414,
        "_internal_originalTime": 1689842865000
    },
    {
        "time": 1689842866000,
        "value": 139.41,
        "_internal_originalTime": 1689842866000
    },
    {
        "time": 1689842868000,
        "value": 139.408,
        "_internal_originalTime": 1689842868000
    },
    {
        "time": 1689842869000,
        "value": 139.409,
        "_internal_originalTime": 1689842869000
    },
    {
        "time": 1689842870000,
        "value": 139.406,
        "_internal_originalTime": 1689842870000
    },
    {
        "time": 1689842871000,
        "value": 139.407,
        "_internal_originalTime": 1689842871000
    },
    {
        "time": 1689842873000,
        "value": 139.407,
        "_internal_originalTime": 1689842873000
    },
    {
        "time": 1689842875000,
        "value": 139.402,
        "_internal_originalTime": 1689842875000
    },
    {
        "time": 1689842876000,
        "value": 139.408,
        "_internal_originalTime": 1689842876000
    },
    {
        "time": 1689842877000,
        "value": 139.408,
        "_internal_originalTime": 1689842877000
    },
    {
        "time": 1689842880000,
        "value": 139.408,
        "_internal_originalTime": 1689842880000
    },
    {
        "time": 1689842881000,
        "value": 139.413,
        "_internal_originalTime": 1689842881000
    },
    {
        "time": 1689842882000,
        "value": 139.422,
        "_internal_originalTime": 1689842882000
    },
    {
        "time": 1689842883000,
        "value": 139.423,
        "_internal_originalTime": 1689842883000
    },
    {
        "time": 1689842884000,
        "value": 139.427,
        "_internal_originalTime": 1689842884000
    },
    {
        "time": 1689842885000,
        "value": 139.42,
        "_internal_originalTime": 1689842885000
    },
    {
        "time": 1689842887000,
        "value": 139.422,
        "_internal_originalTime": 1689842887000
    },
    {
        "time": 1689842888000,
        "value": 139.416,
        "_internal_originalTime": 1689842888000
    },
    {
        "time": 1689842889000,
        "value": 139.41,
        "_internal_originalTime": 1689842889000
    },
    {
        "time": 1689842891000,
        "value": 139.406,
        "_internal_originalTime": 1689842891000
    },
    {
        "time": 1689842892000,
        "value": 139.406,
        "_internal_originalTime": 1689842892000
    },
    {
        "time": 1689842893000,
        "value": 139.406,
        "_internal_originalTime": 1689842893000
    },
    {
        "time": 1689842894000,
        "value": 139.407,
        "_internal_originalTime": 1689842894000
    },
    {
        "time": 1689842896000,
        "value": 139.407,
        "_internal_originalTime": 1689842896000
    },
    {
        "time": 1689842897000,
        "value": 139.412,
        "_internal_originalTime": 1689842897000
    },
    {
        "time": 1689842898000,
        "value": 139.415,
        "_internal_originalTime": 1689842898000
    },
    {
        "time": 1689842899000,
        "value": 139.416,
        "_internal_originalTime": 1689842899000
    },
    {
        "time": 1689842901000,
        "value": 139.416,
        "_internal_originalTime": 1689842901000
    },
    {
        "time": 1689842902000,
        "value": 139.417,
        "_internal_originalTime": 1689842902000
    },
    {
        "time": 1689842904000,
        "value": 139.415,
        "_internal_originalTime": 1689842904000
    },
    {
        "time": 1689842905000,
        "value": 139.412,
        "_internal_originalTime": 1689842905000
    },
    {
        "time": 1689842906000,
        "value": 139.416,
        "_internal_originalTime": 1689842906000
    },
    {
        "time": 1689842907000,
        "value": 139.418,
        "_internal_originalTime": 1689842907000
    },
    {
        "time": 1689842908000,
        "value": 139.416,
        "_internal_originalTime": 1689842908000
    },
    {
        "time": 1689842909000,
        "value": 139.418,
        "_internal_originalTime": 1689842909000
    },
    {
        "time": 1689842911000,
        "value": 139.415,
        "_internal_originalTime": 1689842911000
    },
    {
        "time": 1689842912000,
        "value": 139.414,
        "_internal_originalTime": 1689842912000
    },
    {
        "time": 1689842913000,
        "value": 139.419,
        "_internal_originalTime": 1689842913000
    },
    {
        "time": 1689842914000,
        "value": 139.414,
        "_internal_originalTime": 1689842914000
    },
    {
        "time": 1689842915000,
        "value": 139.418,
        "_internal_originalTime": 1689842915000
    },
    {
        "time": 1689842917000,
        "value": 139.416,
        "_internal_originalTime": 1689842917000
    },
    {
        "time": 1689842918000,
        "value": 139.411,
        "_internal_originalTime": 1689842918000
    },
    {
        "time": 1689842920000,
        "value": 139.415,
        "_internal_originalTime": 1689842920000
    },
    {
        "time": 1689842921000,
        "value": 139.423,
        "_internal_originalTime": 1689842921000
    },
    {
        "time": 1689842922000,
        "value": 139.419,
        "_internal_originalTime": 1689842922000
    },
    {
        "time": 1689842924000,
        "value": 139.423,
        "_internal_originalTime": 1689842924000
    },
    {
        "time": 1689842925000,
        "value": 139.418,
        "_internal_originalTime": 1689842925000
    },
    {
        "time": 1689842926000,
        "value": 139.425,
        "_internal_originalTime": 1689842926000
    },
    {
        "time": 1689842927000,
        "value": 139.423,
        "_internal_originalTime": 1689842927000
    },
    {
        "time": 1689842928000,
        "value": 139.425,
        "_internal_originalTime": 1689842928000
    },
    {
        "time": 1689842930000,
        "value": 139.425,
        "_internal_originalTime": 1689842930000
    },
    {
        "time": 1689842932000,
        "value": 139.424,
        "_internal_originalTime": 1689842932000
    },
    {
        "time": 1689842933000,
        "value": 139.425,
        "_internal_originalTime": 1689842933000
    },
    {
        "time": 1689842934000,
        "value": 139.426,
        "_internal_originalTime": 1689842934000
    },
    {
        "time": 1689842935000,
        "value": 139.425,
        "_internal_originalTime": 1689842935000
    },
    {
        "time": 1689842937000,
        "value": 139.425,
        "_internal_originalTime": 1689842937000
    },
    {
        "time": 1689842938000,
        "value": 139.429,
        "_internal_originalTime": 1689842938000
    },
    {
        "time": 1689842940000,
        "value": 139.431,
        "_internal_originalTime": 1689842940000
    },
    {
        "time": 1689842941000,
        "value": 139.434,
        "_internal_originalTime": 1689842941000
    },
    {
        "time": 1689842942000,
        "value": 139.432,
        "_internal_originalTime": 1689842942000
    },
    {
        "time": 1689842943000,
        "value": 139.432,
        "_internal_originalTime": 1689842943000
    },
    {
        "time": 1689842944000,
        "value": 139.429,
        "_internal_originalTime": 1689842944000
    },
    {
        "time": 1689842945000,
        "value": 139.429,
        "_internal_originalTime": 1689842945000
    },
    {
        "time": 1689842946000,
        "value": 139.429,
        "_internal_originalTime": 1689842946000
    },
    {
        "time": 1689842947000,
        "value": 139.431,
        "_internal_originalTime": 1689842947000
    },
    {
        "time": 1689842948000,
        "value": 139.434,
        "_internal_originalTime": 1689842948000
    },
    {
        "time": 1689842950000,
        "value": 139.431,
        "_internal_originalTime": 1689842950000
    },
    {
        "time": 1689842951000,
        "value": 139.428,
        "_internal_originalTime": 1689842951000
    },
    {
        "time": 1689842953000,
        "value": 139.431,
        "_internal_originalTime": 1689842953000
    },
    {
        "time": 1689842955000,
        "value": 139.416,
        "_internal_originalTime": 1689842955000
    },
    {
        "time": 1689842956000,
        "value": 139.414,
        "_internal_originalTime": 1689842956000
    },
    {
        "time": 1689842957000,
        "value": 139.409,
        "_internal_originalTime": 1689842957000
    },
    {
        "time": 1689842958000,
        "value": 139.415,
        "_internal_originalTime": 1689842958000
    },
    {
        "time": 1689842960000,
        "value": 139.422,
        "_internal_originalTime": 1689842960000
    },
    {
        "time": 1689842961000,
        "value": 139.412,
        "_internal_originalTime": 1689842961000
    },
    {
        "time": 1689842963000,
        "value": 139.412,
        "_internal_originalTime": 1689842963000
    },
    {
        "time": 1689842965000,
        "value": 139.407,
        "_internal_originalTime": 1689842965000
    },
    {
        "time": 1689842966000,
        "value": 139.407,
        "_internal_originalTime": 1689842966000
    },
    {
        "time": 1689842967000,
        "value": 139.407,
        "_internal_originalTime": 1689842967000
    },
    {
        "time": 1689842968000,
        "value": 139.408,
        "_internal_originalTime": 1689842968000
    },
    {
        "time": 1689842969000,
        "value": 139.408,
        "_internal_originalTime": 1689842969000
    },
    {
        "time": 1689842970000,
        "value": 139.414,
        "_internal_originalTime": 1689842970000
    },
    {
        "time": 1689842972000,
        "value": 139.411,
        "_internal_originalTime": 1689842972000
    },
    {
        "time": 1689842974000,
        "value": 139.41,
        "_internal_originalTime": 1689842974000
    },
    {
        "time": 1689842975000,
        "value": 139.416,
        "_internal_originalTime": 1689842975000
    },
    {
        "time": 1689842976000,
        "value": 139.417,
        "_internal_originalTime": 1689842976000
    },
    {
        "time": 1689842977000,
        "value": 139.416,
        "_internal_originalTime": 1689842977000
    },
    {
        "time": 1689842979000,
        "value": 139.413,
        "_internal_originalTime": 1689842979000
    },
    {
        "time": 1689842980000,
        "value": 139.415,
        "_internal_originalTime": 1689842980000
    },
    {
        "time": 1689842981000,
        "value": 139.413,
        "_internal_originalTime": 1689842981000
    },
    {
        "time": 1689842982000,
        "value": 139.416,
        "_internal_originalTime": 1689842982000
    },
    {
        "time": 1689842984000,
        "value": 139.416,
        "_internal_originalTime": 1689842984000
    },
    {
        "time": 1689842985000,
        "value": 139.416,
        "_internal_originalTime": 1689842985000
    },
    {
        "time": 1689842986000,
        "value": 139.413,
        "_internal_originalTime": 1689842986000
    },
    {
        "time": 1689842990000,
        "value": 139.416,
        "_internal_originalTime": 1689842990000
    },
    {
        "time": 1689842992000,
        "value": 139.416,
        "_internal_originalTime": 1689842992000
    },
    {
        "time": 1689842993000,
        "value": 139.416,
        "_internal_originalTime": 1689842993000
    },
    {
        "time": 1689842995000,
        "value": 139.418,
        "_internal_originalTime": 1689842995000
    },
    {
        "time": 1689842996000,
        "value": 139.423,
        "_internal_originalTime": 1689842996000
    },
    {
        "time": 1689842997000,
        "value": 139.421,
        "_internal_originalTime": 1689842997000
    },
    {
        "time": 1689842998000,
        "value": 139.418,
        "_internal_originalTime": 1689842998000
    },
    {
        "time": 1689842999000,
        "value": 139.423,
        "_internal_originalTime": 1689842999000
    },
    {
        "time": 1689843001000,
        "value": 139.42,
        "_internal_originalTime": 1689843001000
    },
    {
        "time": 1689843002000,
        "value": 139.425,
        "_internal_originalTime": 1689843002000
    },
    {
        "time": 1689843003000,
        "value": 139.429,
        "_internal_originalTime": 1689843003000
    },
    {
        "time": 1689843005000,
        "value": 139.423,
        "_internal_originalTime": 1689843005000
    },
    {
        "time": 1689843006000,
        "value": 139.425,
        "_internal_originalTime": 1689843006000
    },
    {
        "time": 1689843008000,
        "value": 139.419,
        "_internal_originalTime": 1689843008000
    },
    {
        "time": 1689843009000,
        "value": 139.422,
        "_internal_originalTime": 1689843009000
    },
    {
        "time": 1689843010000,
        "value": 139.423,
        "_internal_originalTime": 1689843010000
    },
    {
        "time": 1689843012000,
        "value": 139.416,
        "_internal_originalTime": 1689843012000
    },
    {
        "time": 1689843013000,
        "value": 139.416,
        "_internal_originalTime": 1689843013000
    },
    {
        "time": 1689843014000,
        "value": 139.418,
        "_internal_originalTime": 1689843014000
    },
    {
        "time": 1689843015000,
        "value": 139.419,
        "_internal_originalTime": 1689843015000
    },
    {
        "time": 1689843017000,
        "value": 139.423,
        "_internal_originalTime": 1689843017000
    },
    {
        "time": 1689843018000,
        "value": 139.423,
        "_internal_originalTime": 1689843018000
    },
    {
        "time": 1689843019000,
        "value": 139.419,
        "_internal_originalTime": 1689843019000
    },
    {
        "time": 1689843021000,
        "value": 139.424,
        "_internal_originalTime": 1689843021000
    },
    {
        "time": 1689843022000,
        "value": 139.424,
        "_internal_originalTime": 1689843022000
    },
    {
        "time": 1689843024000,
        "value": 139.428,
        "_internal_originalTime": 1689843024000
    },
    {
        "time": 1689843025000,
        "value": 139.429,
        "_internal_originalTime": 1689843025000
    },
    {
        "time": 1689843026000,
        "value": 139.434,
        "_internal_originalTime": 1689843026000
    },
    {
        "time": 1689843027000,
        "value": 139.446,
        "_internal_originalTime": 1689843027000
    },
    {
        "time": 1689843028000,
        "value": 139.442,
        "_internal_originalTime": 1689843028000
    },
    {
        "time": 1689843030000,
        "value": 139.449,
        "_internal_originalTime": 1689843030000
    },
    {
        "time": 1689843031000,
        "value": 139.451,
        "_internal_originalTime": 1689843031000
    },
    {
        "time": 1689843033000,
        "value": 139.452,
        "_internal_originalTime": 1689843033000
    },
    {
        "time": 1689843034000,
        "value": 139.451,
        "_internal_originalTime": 1689843034000
    },
    {
        "time": 1689843035000,
        "value": 139.448,
        "_internal_originalTime": 1689843035000
    },
    {
        "time": 1689843036000,
        "value": 139.453,
        "_internal_originalTime": 1689843036000
    },
    {
        "time": 1689843037000,
        "value": 139.454,
        "_internal_originalTime": 1689843037000
    },
    {
        "time": 1689843039000,
        "value": 139.452,
        "_internal_originalTime": 1689843039000
    },
    {
        "time": 1689843040000,
        "value": 139.452,
        "_internal_originalTime": 1689843040000
    },
    {
        "time": 1689843041000,
        "value": 139.452,
        "_internal_originalTime": 1689843041000
    },
    {
        "time": 1689843042000,
        "value": 139.448,
        "_internal_originalTime": 1689843042000
    },
    {
        "time": 1689843043000,
        "value": 139.452,
        "_internal_originalTime": 1689843043000
    },
    {
        "time": 1689843045000,
        "value": 139.448,
        "_internal_originalTime": 1689843045000
    },
    {
        "time": 1689843046000,
        "value": 139.446,
        "_internal_originalTime": 1689843046000
    },
    {
        "time": 1689843047000,
        "value": 139.442,
        "_internal_originalTime": 1689843047000
    },
    {
        "time": 1689843048000,
        "value": 139.446,
        "_internal_originalTime": 1689843048000
    },
    {
        "time": 1689843049000,
        "value": 139.445,
        "_internal_originalTime": 1689843049000
    },
    {
        "time": 1689843050000,
        "value": 139.445,
        "_internal_originalTime": 1689843050000
    },
    {
        "time": 1689843051000,
        "value": 139.444,
        "_internal_originalTime": 1689843051000
    },
    {
        "time": 1689843053000,
        "value": 139.442,
        "_internal_originalTime": 1689843053000
    },
    {
        "time": 1689843054000,
        "value": 139.445,
        "_internal_originalTime": 1689843054000
    },
    {
        "time": 1689843055000,
        "value": 139.446,
        "_internal_originalTime": 1689843055000
    },
    {
        "time": 1689843056000,
        "value": 139.444,
        "_internal_originalTime": 1689843056000
    },
    {
        "time": 1689843057000,
        "value": 139.445,
        "_internal_originalTime": 1689843057000
    },
    {
        "time": 1689843059000,
        "value": 139.448,
        "_internal_originalTime": 1689843059000
    },
    {
        "time": 1689843060000,
        "value": 139.447,
        "_internal_originalTime": 1689843060000
    },
    {
        "time": 1689843061000,
        "value": 139.446,
        "_internal_originalTime": 1689843061000
    },
    {
        "time": 1689843062000,
        "value": 139.445,
        "_internal_originalTime": 1689843062000
    },
    {
        "time": 1689843065000,
        "value": 139.445,
        "_internal_originalTime": 1689843065000
    },
    {
        "time": 1689843066000,
        "value": 139.44,
        "_internal_originalTime": 1689843066000
    },
    {
        "time": 1689843068000,
        "value": 139.445,
        "_internal_originalTime": 1689843068000
    },
    {
        "time": 1689843069000,
        "value": 139.445,
        "_internal_originalTime": 1689843069000
    },
    {
        "time": 1689843071000,
        "value": 139.443,
        "_internal_originalTime": 1689843071000
    },
    {
        "time": 1689843072000,
        "value": 139.445,
        "_internal_originalTime": 1689843072000
    },
    {
        "time": 1689843075000,
        "value": 139.445,
        "_internal_originalTime": 1689843075000
    },
    {
        "time": 1689843076000,
        "value": 139.44,
        "_internal_originalTime": 1689843076000
    },
    {
        "time": 1689843077000,
        "value": 139.444,
        "_internal_originalTime": 1689843077000
    },
    {
        "time": 1689843078000,
        "value": 139.44,
        "_internal_originalTime": 1689843078000
    },
    {
        "time": 1689843079000,
        "value": 139.444,
        "_internal_originalTime": 1689843079000
    },
    {
        "time": 1689843081000,
        "value": 139.452,
        "_internal_originalTime": 1689843081000
    },
    {
        "time": 1689843082000,
        "value": 139.449,
        "_internal_originalTime": 1689843082000
    },
    {
        "time": 1689843083000,
        "value": 139.445,
        "_internal_originalTime": 1689843083000
    },
    {
        "time": 1689843084000,
        "value": 139.444,
        "_internal_originalTime": 1689843084000
    },
    {
        "time": 1689843086000,
        "value": 139.454,
        "_internal_originalTime": 1689843086000
    },
    {
        "time": 1689843088000,
        "value": 139.463,
        "_internal_originalTime": 1689843088000
    },
    {
        "time": 1689843089000,
        "value": 139.462,
        "_internal_originalTime": 1689843089000
    },
    {
        "time": 1689843090000,
        "value": 139.464,
        "_internal_originalTime": 1689843090000
    },
    {
        "time": 1689843092000,
        "value": 139.466,
        "_internal_originalTime": 1689843092000
    },
    {
        "time": 1689843093000,
        "value": 139.466,
        "_internal_originalTime": 1689843093000
    },
    {
        "time": 1689843094000,
        "value": 139.466,
        "_internal_originalTime": 1689843094000
    },
    {
        "time": 1689843096000,
        "value": 139.47,
        "_internal_originalTime": 1689843096000
    },
    {
        "time": 1689843097000,
        "value": 139.473,
        "_internal_originalTime": 1689843097000
    },
    {
        "time": 1689843098000,
        "value": 139.479,
        "_internal_originalTime": 1689843098000
    },
    {
        "time": 1689843099000,
        "value": 139.47,
        "_internal_originalTime": 1689843099000
    },
    {
        "time": 1689843100000,
        "value": 139.468,
        "_internal_originalTime": 1689843100000
    },
    {
        "time": 1689843101000,
        "value": 139.469,
        "_internal_originalTime": 1689843101000
    },
    {
        "time": 1689843103000,
        "value": 139.472,
        "_internal_originalTime": 1689843103000
    },
    {
        "time": 1689843104000,
        "value": 139.465,
        "_internal_originalTime": 1689843104000
    },
    {
        "time": 1689843105000,
        "value": 139.46,
        "_internal_originalTime": 1689843105000
    },
    {
        "time": 1689843106000,
        "value": 139.461,
        "_internal_originalTime": 1689843106000
    },
    {
        "time": 1689843107000,
        "value": 139.462,
        "_internal_originalTime": 1689843107000
    },
    {
        "time": 1689843108000,
        "value": 139.462,
        "_internal_originalTime": 1689843108000
    },
    {
        "time": 1689843109000,
        "value": 139.461,
        "_internal_originalTime": 1689843109000
    },
    {
        "time": 1689843110000,
        "value": 139.462,
        "_internal_originalTime": 1689843110000
    },
    {
        "time": 1689843112000,
        "value": 139.462,
        "_internal_originalTime": 1689843112000
    },
    {
        "time": 1689843113000,
        "value": 139.466,
        "_internal_originalTime": 1689843113000
    },
    {
        "time": 1689843115000,
        "value": 139.463,
        "_internal_originalTime": 1689843115000
    },
    {
        "time": 1689843116000,
        "value": 139.464,
        "_internal_originalTime": 1689843116000
    },
    {
        "time": 1689843117000,
        "value": 139.461,
        "_internal_originalTime": 1689843117000
    },
    {
        "time": 1689843119000,
        "value": 139.463,
        "_internal_originalTime": 1689843119000
    },
    {
        "time": 1689843120000,
        "value": 139.44,
        "_internal_originalTime": 1689843120000
    },
    {
        "time": 1689843121000,
        "value": 139.461,
        "_internal_originalTime": 1689843121000
    },
    {
        "time": 1689843123000,
        "value": 139.462,
        "_internal_originalTime": 1689843123000
    },
    {
        "time": 1689843126000,
        "value": 139.467,
        "_internal_originalTime": 1689843126000
    },
    {
        "time": 1689843127000,
        "value": 139.462,
        "_internal_originalTime": 1689843127000
    },
    {
        "time": 1689843128000,
        "value": 139.46,
        "_internal_originalTime": 1689843128000
    },
    {
        "time": 1689843129000,
        "value": 139.461,
        "_internal_originalTime": 1689843129000
    },
    {
        "time": 1689843130000,
        "value": 139.462,
        "_internal_originalTime": 1689843130000
    },
    {
        "time": 1689843131000,
        "value": 139.466,
        "_internal_originalTime": 1689843131000
    },
    {
        "time": 1689843132000,
        "value": 139.465,
        "_internal_originalTime": 1689843132000
    },
    {
        "time": 1689843133000,
        "value": 139.466,
        "_internal_originalTime": 1689843133000
    },
    {
        "time": 1689843135000,
        "value": 139.467,
        "_internal_originalTime": 1689843135000
    },
    {
        "time": 1689843136000,
        "value": 139.47,
        "_internal_originalTime": 1689843136000
    },
    {
        "time": 1689843139000,
        "value": 139.472,
        "_internal_originalTime": 1689843139000
    },
    {
        "time": 1689843140000,
        "value": 139.475,
        "_internal_originalTime": 1689843140000
    },
    {
        "time": 1689843143000,
        "value": 139.471,
        "_internal_originalTime": 1689843143000
    },
    {
        "time": 1689843144000,
        "value": 139.476,
        "_internal_originalTime": 1689843144000
    },
    {
        "time": 1689843145000,
        "value": 139.476,
        "_internal_originalTime": 1689843145000
    },
    {
        "time": 1689843146000,
        "value": 139.471,
        "_internal_originalTime": 1689843146000
    },
    {
        "time": 1689843147000,
        "value": 139.474,
        "_internal_originalTime": 1689843147000
    },
    {
        "time": 1689843148000,
        "value": 139.476,
        "_internal_originalTime": 1689843148000
    },
    {
        "time": 1689843150000,
        "value": 139.474,
        "_internal_originalTime": 1689843150000
    },
    {
        "time": 1689843151000,
        "value": 139.476,
        "_internal_originalTime": 1689843151000
    },
    {
        "time": 1689843152000,
        "value": 139.473,
        "_internal_originalTime": 1689843152000
    },
    {
        "time": 1689843154000,
        "value": 139.476,
        "_internal_originalTime": 1689843154000
    },
    {
        "time": 1689843156000,
        "value": 139.476,
        "_internal_originalTime": 1689843156000
    },
    {
        "time": 1689843157000,
        "value": 139.476,
        "_internal_originalTime": 1689843157000
    },
    {
        "time": 1689843159000,
        "value": 139.48,
        "_internal_originalTime": 1689843159000
    },
    {
        "time": 1689843160000,
        "value": 139.483,
        "_internal_originalTime": 1689843160000
    },
    {
        "time": 1689843161000,
        "value": 139.48,
        "_internal_originalTime": 1689843161000
    },
    {
        "time": 1689843163000,
        "value": 139.472,
        "_internal_originalTime": 1689843163000
    },
    {
        "time": 1689843164000,
        "value": 139.468,
        "_internal_originalTime": 1689843164000
    },
    {
        "time": 1689843166000,
        "value": 139.471,
        "_internal_originalTime": 1689843166000
    },
    {
        "time": 1689843167000,
        "value": 139.471,
        "_internal_originalTime": 1689843167000
    },
    {
        "time": 1689843168000,
        "value": 139.475,
        "_internal_originalTime": 1689843168000
    },
    {
        "time": 1689843169000,
        "value": 139.472,
        "_internal_originalTime": 1689843169000
    },
    {
        "time": 1689843171000,
        "value": 139.47,
        "_internal_originalTime": 1689843171000
    },
    {
        "time": 1689843172000,
        "value": 139.468,
        "_internal_originalTime": 1689843172000
    },
    {
        "time": 1689843174000,
        "value": 139.467,
        "_internal_originalTime": 1689843174000
    },
    {
        "time": 1689843175000,
        "value": 139.458,
        "_internal_originalTime": 1689843175000
    },
    {
        "time": 1689843176000,
        "value": 139.463,
        "_internal_originalTime": 1689843176000
    },
    {
        "time": 1689843177000,
        "value": 139.466,
        "_internal_originalTime": 1689843177000
    },
    {
        "time": 1689843179000,
        "value": 139.466,
        "_internal_originalTime": 1689843179000
    },
    {
        "time": 1689843181000,
        "value": 139.463,
        "_internal_originalTime": 1689843181000
    },
    {
        "time": 1689843182000,
        "value": 139.467,
        "_internal_originalTime": 1689843182000
    },
    {
        "time": 1689843183000,
        "value": 139.467,
        "_internal_originalTime": 1689843183000
    },
    {
        "time": 1689843185000,
        "value": 139.467,
        "_internal_originalTime": 1689843185000
    },
    {
        "time": 1689843187000,
        "value": 139.468,
        "_internal_originalTime": 1689843187000
    },
    {
        "time": 1689843189000,
        "value": 139.469,
        "_internal_originalTime": 1689843189000
    },
    {
        "time": 1689843191000,
        "value": 139.466,
        "_internal_originalTime": 1689843191000
    },
    {
        "time": 1689843193000,
        "value": 139.467,
        "_internal_originalTime": 1689843193000
    },
    {
        "time": 1689843194000,
        "value": 139.465,
        "_internal_originalTime": 1689843194000
    },
    {
        "time": 1689843196000,
        "value": 139.467,
        "_internal_originalTime": 1689843196000
    },
    {
        "time": 1689843197000,
        "value": 139.466,
        "_internal_originalTime": 1689843197000
    },
    {
        "time": 1689843199000,
        "value": 139.468,
        "_internal_originalTime": 1689843199000
    },
    {
        "time": 1689843200000,
        "value": 139.469,
        "_internal_originalTime": 1689843200000
    },
    {
        "time": 1689843201000,
        "value": 139.464,
        "_internal_originalTime": 1689843201000
    },
    {
        "time": 1689843202000,
        "value": 139.467,
        "_internal_originalTime": 1689843202000
    },
    {
        "time": 1689843204000,
        "value": 139.467,
        "_internal_originalTime": 1689843204000
    },
    {
        "time": 1689843206000,
        "value": 139.472,
        "_internal_originalTime": 1689843206000
    },
    {
        "time": 1689843207000,
        "value": 139.475,
        "_internal_originalTime": 1689843207000
    },
    {
        "time": 1689843208000,
        "value": 139.473,
        "_internal_originalTime": 1689843208000
    },
    {
        "time": 1689843209000,
        "value": 139.472,
        "_internal_originalTime": 1689843209000
    },
    {
        "time": 1689843210000,
        "value": 139.475,
        "_internal_originalTime": 1689843210000
    },
    {
        "time": 1689843211000,
        "value": 139.478,
        "_internal_originalTime": 1689843211000
    },
    {
        "time": 1689843212000,
        "value": 139.477,
        "_internal_originalTime": 1689843212000
    },
    {
        "time": 1689843214000,
        "value": 139.475,
        "_internal_originalTime": 1689843214000
    },
    {
        "time": 1689843215000,
        "value": 139.474,
        "_internal_originalTime": 1689843215000
    },
    {
        "time": 1689843216000,
        "value": 139.474,
        "_internal_originalTime": 1689843216000
    },
    {
        "time": 1689843218000,
        "value": 139.474,
        "_internal_originalTime": 1689843218000
    },
    {
        "time": 1689843219000,
        "value": 139.479,
        "_internal_originalTime": 1689843219000
    },
    {
        "time": 1689843220000,
        "value": 139.474,
        "_internal_originalTime": 1689843220000
    },
    {
        "time": 1689843222000,
        "value": 139.478,
        "_internal_originalTime": 1689843222000
    },
    {
        "time": 1689843223000,
        "value": 139.476,
        "_internal_originalTime": 1689843223000
    },
    {
        "time": 1689843224000,
        "value": 139.472,
        "_internal_originalTime": 1689843224000
    },
    {
        "time": 1689843226000,
        "value": 139.475,
        "_internal_originalTime": 1689843226000
    },
    {
        "time": 1689843227000,
        "value": 139.472,
        "_internal_originalTime": 1689843227000
    },
    {
        "time": 1689843228000,
        "value": 139.475,
        "_internal_originalTime": 1689843228000
    },
    {
        "time": 1689843229000,
        "value": 139.474,
        "_internal_originalTime": 1689843229000
    },
    {
        "time": 1689843230000,
        "value": 139.477,
        "_internal_originalTime": 1689843230000
    },
    {
        "time": 1689843231000,
        "value": 139.477,
        "_internal_originalTime": 1689843231000
    },
    {
        "time": 1689843232000,
        "value": 139.475,
        "_internal_originalTime": 1689843232000
    },
    {
        "time": 1689843234000,
        "value": 139.478,
        "_internal_originalTime": 1689843234000
    },
    {
        "time": 1689843236000,
        "value": 139.472,
        "_internal_originalTime": 1689843236000
    },
    {
        "time": 1689843239000,
        "value": 139.478,
        "_internal_originalTime": 1689843239000
    },
    {
        "time": 1689843240000,
        "value": 139.476,
        "_internal_originalTime": 1689843240000
    },
    {
        "time": 1689843241000,
        "value": 139.474,
        "_internal_originalTime": 1689843241000
    },
    {
        "time": 1689843242000,
        "value": 139.47,
        "_internal_originalTime": 1689843242000
    },
    {
        "time": 1689843244000,
        "value": 139.468,
        "_internal_originalTime": 1689843244000
    },
    {
        "time": 1689843246000,
        "value": 139.473,
        "_internal_originalTime": 1689843246000
    },
    {
        "time": 1689843248000,
        "value": 139.476,
        "_internal_originalTime": 1689843248000
    },
    {
        "time": 1689843249000,
        "value": 139.473,
        "_internal_originalTime": 1689843249000
    },
    {
        "time": 1689843250000,
        "value": 139.474,
        "_internal_originalTime": 1689843250000
    },
    {
        "time": 1689843251000,
        "value": 139.47,
        "_internal_originalTime": 1689843251000
    },
    {
        "time": 1689843252000,
        "value": 139.47,
        "_internal_originalTime": 1689843252000
    },
    {
        "time": 1689843253000,
        "value": 139.469,
        "_internal_originalTime": 1689843253000
    },
    {
        "time": 1689843256000,
        "value": 139.472,
        "_internal_originalTime": 1689843256000
    },
    {
        "time": 1689843257000,
        "value": 139.472,
        "_internal_originalTime": 1689843257000
    },
    {
        "time": 1689843259000,
        "value": 139.468,
        "_internal_originalTime": 1689843259000
    },
    {
        "time": 1689843260000,
        "value": 139.469,
        "_internal_originalTime": 1689843260000
    },
    {
        "time": 1689843261000,
        "value": 139.473,
        "_internal_originalTime": 1689843261000
    },
    {
        "time": 1689843262000,
        "value": 139.462,
        "_internal_originalTime": 1689843262000
    },
    {
        "time": 1689843263000,
        "value": 139.468,
        "_internal_originalTime": 1689843263000
    },
    {
        "time": 1689843265000,
        "value": 139.468,
        "_internal_originalTime": 1689843265000
    },
    {
        "time": 1689843266000,
        "value": 139.468,
        "_internal_originalTime": 1689843266000
    },
    {
        "time": 1689843267000,
        "value": 139.468,
        "_internal_originalTime": 1689843267000
    },
    {
        "time": 1689843269000,
        "value": 139.467,
        "_internal_originalTime": 1689843269000
    },
    {
        "time": 1689843270000,
        "value": 139.465,
        "_internal_originalTime": 1689843270000
    },
    {
        "time": 1689843272000,
        "value": 139.468,
        "_internal_originalTime": 1689843272000
    },
    {
        "time": 1689843273000,
        "value": 139.467,
        "_internal_originalTime": 1689843273000
    },
    {
        "time": 1689843275000,
        "value": 139.464,
        "_internal_originalTime": 1689843275000
    },
    {
        "time": 1689843277000,
        "value": 139.467,
        "_internal_originalTime": 1689843277000
    },
    {
        "time": 1689843279000,
        "value": 139.467,
        "_internal_originalTime": 1689843279000
    },
    {
        "time": 1689843280000,
        "value": 139.471,
        "_internal_originalTime": 1689843280000
    },
    {
        "time": 1689843281000,
        "value": 139.472,
        "_internal_originalTime": 1689843281000
    },
    {
        "time": 1689843282000,
        "value": 139.469,
        "_internal_originalTime": 1689843282000
    },
    {
        "time": 1689843284000,
        "value": 139.471,
        "_internal_originalTime": 1689843284000
    },
    {
        "time": 1689843285000,
        "value": 139.47,
        "_internal_originalTime": 1689843285000
    },
    {
        "time": 1689843286000,
        "value": 139.47,
        "_internal_originalTime": 1689843286000
    },
    {
        "time": 1689843287000,
        "value": 139.474,
        "_internal_originalTime": 1689843287000
    },
    {
        "time": 1689843288000,
        "value": 139.472,
        "_internal_originalTime": 1689843288000
    },
    {
        "time": 1689843289000,
        "value": 139.477,
        "_internal_originalTime": 1689843289000
    },
    {
        "time": 1689843291000,
        "value": 139.476,
        "_internal_originalTime": 1689843291000
    },
    {
        "time": 1689843292000,
        "value": 139.476,
        "_internal_originalTime": 1689843292000
    },
    {
        "time": 1689843293000,
        "value": 139.472,
        "_internal_originalTime": 1689843293000
    },
    {
        "time": 1689843295000,
        "value": 139.475,
        "_internal_originalTime": 1689843295000
    },
    {
        "time": 1689843296000,
        "value": 139.468,
        "_internal_originalTime": 1689843296000
    },
    {
        "time": 1689843297000,
        "value": 139.473,
        "_internal_originalTime": 1689843297000
    },
    {
        "time": 1689843299000,
        "value": 139.474,
        "_internal_originalTime": 1689843299000
    },
    {
        "time": 1689843301000,
        "value": 139.476,
        "_internal_originalTime": 1689843301000
    },
    {
        "time": 1689843303000,
        "value": 139.482,
        "_internal_originalTime": 1689843303000
    },
    {
        "time": 1689843304000,
        "value": 139.479,
        "_internal_originalTime": 1689843304000
    },
    {
        "time": 1689843306000,
        "value": 139.481,
        "_internal_originalTime": 1689843306000
    },
    {
        "time": 1689843307000,
        "value": 139.478,
        "_internal_originalTime": 1689843307000
    },
    {
        "time": 1689843308000,
        "value": 139.484,
        "_internal_originalTime": 1689843308000
    },
    {
        "time": 1689843309000,
        "value": 139.485,
        "_internal_originalTime": 1689843309000
    },
    {
        "time": 1689843311000,
        "value": 139.481,
        "_internal_originalTime": 1689843311000
    },
    {
        "time": 1689843312000,
        "value": 139.478,
        "_internal_originalTime": 1689843312000
    },
    {
        "time": 1689843314000,
        "value": 139.479,
        "_internal_originalTime": 1689843314000
    },
    {
        "time": 1689843315000,
        "value": 139.483,
        "_internal_originalTime": 1689843315000
    },
    {
        "time": 1689843316000,
        "value": 139.485,
        "_internal_originalTime": 1689843316000
    },
    {
        "time": 1689843317000,
        "value": 139.484,
        "_internal_originalTime": 1689843317000
    },
    {
        "time": 1689843319000,
        "value": 139.479,
        "_internal_originalTime": 1689843319000
    },
    {
        "time": 1689843326000,
        "value": 139.478,
        "_internal_originalTime": 1689843326000
    },
    {
        "time": 1689843327000,
        "value": 139.479,
        "_internal_originalTime": 1689843327000
    },
    {
        "time": 1689843328000,
        "value": 139.475,
        "_internal_originalTime": 1689843328000
    },
    {
        "time": 1689843330000,
        "value": 139.476,
        "_internal_originalTime": 1689843330000
    },
    {
        "time": 1689843331000,
        "value": 139.472,
        "_internal_originalTime": 1689843331000
    },
    {
        "time": 1689843332000,
        "value": 139.476,
        "_internal_originalTime": 1689843332000
    },
    {
        "time": 1689843333000,
        "value": 139.472,
        "_internal_originalTime": 1689843333000
    },
    {
        "time": 1689843334000,
        "value": 139.475,
        "_internal_originalTime": 1689843334000
    },
    {
        "time": 1689843335000,
        "value": 139.483,
        "_internal_originalTime": 1689843335000
    },
    {
        "time": 1689843336000,
        "value": 139.479,
        "_internal_originalTime": 1689843336000
    },
    {
        "time": 1689843337000,
        "value": 139.486,
        "_internal_originalTime": 1689843337000
    },
    {
        "time": 1689843338000,
        "value": 139.484,
        "_internal_originalTime": 1689843338000
    },
    {
        "time": 1689843339000,
        "value": 139.484,
        "_internal_originalTime": 1689843339000
    },
    {
        "time": 1689843341000,
        "value": 139.483,
        "_internal_originalTime": 1689843341000
    },
    {
        "time": 1689843342000,
        "value": 139.49,
        "_internal_originalTime": 1689843342000
    },
    {
        "time": 1689843343000,
        "value": 139.491,
        "_internal_originalTime": 1689843343000
    },
    {
        "time": 1689843344000,
        "value": 139.493,
        "_internal_originalTime": 1689843344000
    },
    {
        "time": 1689843345000,
        "value": 139.496,
        "_internal_originalTime": 1689843345000
    },
    {
        "time": 1689843346000,
        "value": 139.496,
        "_internal_originalTime": 1689843346000
    },
    {
        "time": 1689843348000,
        "value": 139.496,
        "_internal_originalTime": 1689843348000
    },
    {
        "time": 1689843350000,
        "value": 139.498,
        "_internal_originalTime": 1689843350000
    },
    {
        "time": 1689843351000,
        "value": 139.498,
        "_internal_originalTime": 1689843351000
    },
    {
        "time": 1689843352000,
        "value": 139.5,
        "_internal_originalTime": 1689843352000
    },
    {
        "time": 1689843354000,
        "value": 139.502,
        "_internal_originalTime": 1689843354000
    },
    {
        "time": 1689843355000,
        "value": 139.504,
        "_internal_originalTime": 1689843355000
    },
    {
        "time": 1689843356000,
        "value": 139.499,
        "_internal_originalTime": 1689843356000
    },
    {
        "time": 1689843358000,
        "value": 139.497,
        "_internal_originalTime": 1689843358000
    },
    {
        "time": 1689843360000,
        "value": 139.488,
        "_internal_originalTime": 1689843360000
    },
    {
        "time": 1689843361000,
        "value": 139.494,
        "_internal_originalTime": 1689843361000
    },
    {
        "time": 1689843362000,
        "value": 139.494,
        "_internal_originalTime": 1689843362000
    },
    {
        "time": 1689843364000,
        "value": 139.492,
        "_internal_originalTime": 1689843364000
    },
    {
        "time": 1689843365000,
        "value": 139.495,
        "_internal_originalTime": 1689843365000
    },
    {
        "time": 1689843366000,
        "value": 139.495,
        "_internal_originalTime": 1689843366000
    },
    {
        "time": 1689843367000,
        "value": 139.494,
        "_internal_originalTime": 1689843367000
    },
    {
        "time": 1689843369000,
        "value": 139.486,
        "_internal_originalTime": 1689843369000
    },
    {
        "time": 1689843370000,
        "value": 139.49,
        "_internal_originalTime": 1689843370000
    },
    {
        "time": 1689843371000,
        "value": 139.494,
        "_internal_originalTime": 1689843371000
    },
    {
        "time": 1689843372000,
        "value": 139.487,
        "_internal_originalTime": 1689843372000
    },
    {
        "time": 1689843376000,
        "value": 139.486,
        "_internal_originalTime": 1689843376000
    },
    {
        "time": 1689843377000,
        "value": 139.487,
        "_internal_originalTime": 1689843377000
    },
    {
        "time": 1689843378000,
        "value": 139.486,
        "_internal_originalTime": 1689843378000
    },
    {
        "time": 1689843379000,
        "value": 139.487,
        "_internal_originalTime": 1689843379000
    },
    {
        "time": 1689843380000,
        "value": 139.489,
        "_internal_originalTime": 1689843380000
    },
    {
        "time": 1689843381000,
        "value": 139.488,
        "_internal_originalTime": 1689843381000
    },
    {
        "time": 1689843382000,
        "value": 139.482,
        "_internal_originalTime": 1689843382000
    },
    {
        "time": 1689843384000,
        "value": 139.487,
        "_internal_originalTime": 1689843384000
    },
    {
        "time": 1689843386000,
        "value": 139.486,
        "_internal_originalTime": 1689843386000
    },
    {
        "time": 1689843387000,
        "value": 139.478,
        "_internal_originalTime": 1689843387000
    },
    {
        "time": 1689843388000,
        "value": 139.473,
        "_internal_originalTime": 1689843388000
    },
    {
        "time": 1689843390000,
        "value": 139.478,
        "_internal_originalTime": 1689843390000
    },
    {
        "time": 1689843391000,
        "value": 139.477,
        "_internal_originalTime": 1689843391000
    },
    {
        "time": 1689843392000,
        "value": 139.473,
        "_internal_originalTime": 1689843392000
    },
    {
        "time": 1689843393000,
        "value": 139.479,
        "_internal_originalTime": 1689843393000
    },
    {
        "time": 1689843394000,
        "value": 139.473,
        "_internal_originalTime": 1689843394000
    },
    {
        "time": 1689843395000,
        "value": 139.473,
        "_internal_originalTime": 1689843395000
    },
    {
        "time": 1689843397000,
        "value": 139.477,
        "_internal_originalTime": 1689843397000
    },
    {
        "time": 1689843398000,
        "value": 139.476,
        "_internal_originalTime": 1689843398000
    },
    {
        "time": 1689843399000,
        "value": 139.466,
        "_internal_originalTime": 1689843399000
    },
    {
        "time": 1689843401000,
        "value": 139.471,
        "_internal_originalTime": 1689843401000
    },
    {
        "time": 1689843403000,
        "value": 139.468,
        "_internal_originalTime": 1689843403000
    },
    {
        "time": 1689843404000,
        "value": 139.469,
        "_internal_originalTime": 1689843404000
    },
    {
        "time": 1689843405000,
        "value": 139.473,
        "_internal_originalTime": 1689843405000
    },
    {
        "time": 1689843406000,
        "value": 139.472,
        "_internal_originalTime": 1689843406000
    },
    {
        "time": 1689843407000,
        "value": 139.467,
        "_internal_originalTime": 1689843407000
    },
    {
        "time": 1689843409000,
        "value": 139.462,
        "_internal_originalTime": 1689843409000
    },
    {
        "time": 1689843410000,
        "value": 139.466,
        "_internal_originalTime": 1689843410000
    },
    {
        "time": 1689843412000,
        "value": 139.468,
        "_internal_originalTime": 1689843412000
    },
    {
        "time": 1689843413000,
        "value": 139.468,
        "_internal_originalTime": 1689843413000
    },
    {
        "time": 1689843414000,
        "value": 139.467,
        "_internal_originalTime": 1689843414000
    },
    {
        "time": 1689843415000,
        "value": 139.47,
        "_internal_originalTime": 1689843415000
    },
    {
        "time": 1689843416000,
        "value": 139.468,
        "_internal_originalTime": 1689843416000
    },
    {
        "time": 1689843417000,
        "value": 139.476,
        "_internal_originalTime": 1689843417000
    },
    {
        "time": 1689843418000,
        "value": 139.472,
        "_internal_originalTime": 1689843418000
    },
    {
        "time": 1689843420000,
        "value": 139.477,
        "_internal_originalTime": 1689843420000
    },
    {
        "time": 1689843421000,
        "value": 139.477,
        "_internal_originalTime": 1689843421000
    },
    {
        "time": 1689843422000,
        "value": 139.468,
        "_internal_originalTime": 1689843422000
    },
    {
        "time": 1689843424000,
        "value": 139.473,
        "_internal_originalTime": 1689843424000
    },
    {
        "time": 1689843425000,
        "value": 139.473,
        "_internal_originalTime": 1689843425000
    },
    {
        "time": 1689843426000,
        "value": 139.477,
        "_internal_originalTime": 1689843426000
    },
    {
        "time": 1689843427000,
        "value": 139.476,
        "_internal_originalTime": 1689843427000
    },
    {
        "time": 1689843429000,
        "value": 139.471,
        "_internal_originalTime": 1689843429000
    },
    {
        "time": 1689843431000,
        "value": 139.471,
        "_internal_originalTime": 1689843431000
    },
    {
        "time": 1689843433000,
        "value": 139.477,
        "_internal_originalTime": 1689843433000
    },
    {
        "time": 1689843435000,
        "value": 139.48,
        "_internal_originalTime": 1689843435000
    },
    {
        "time": 1689843436000,
        "value": 139.474,
        "_internal_originalTime": 1689843436000
    },
    {
        "time": 1689843437000,
        "value": 139.479,
        "_internal_originalTime": 1689843437000
    },
    {
        "time": 1689843439000,
        "value": 139.473,
        "_internal_originalTime": 1689843439000
    },
    {
        "time": 1689843440000,
        "value": 139.472,
        "_internal_originalTime": 1689843440000
    },
    {
        "time": 1689843441000,
        "value": 139.472,
        "_internal_originalTime": 1689843441000
    },
    {
        "time": 1689843442000,
        "value": 139.476,
        "_internal_originalTime": 1689843442000
    },
    {
        "time": 1689843444000,
        "value": 139.476,
        "_internal_originalTime": 1689843444000
    },
    {
        "time": 1689843447000,
        "value": 139.481,
        "_internal_originalTime": 1689843447000
    },
    {
        "time": 1689843449000,
        "value": 139.477,
        "_internal_originalTime": 1689843449000
    },
    {
        "time": 1689843450000,
        "value": 139.476,
        "_internal_originalTime": 1689843450000
    },
    {
        "time": 1689843452000,
        "value": 139.476,
        "_internal_originalTime": 1689843452000
    },
    {
        "time": 1689843453000,
        "value": 139.486,
        "_internal_originalTime": 1689843453000
    },
    {
        "time": 1689843455000,
        "value": 139.487,
        "_internal_originalTime": 1689843455000
    },
    {
        "time": 1689843456000,
        "value": 139.483,
        "_internal_originalTime": 1689843456000
    },
    {
        "time": 1689843457000,
        "value": 139.483,
        "_internal_originalTime": 1689843457000
    },
    {
        "time": 1689843458000,
        "value": 139.478,
        "_internal_originalTime": 1689843458000
    },
    {
        "time": 1689843459000,
        "value": 139.483,
        "_internal_originalTime": 1689843459000
    },
    {
        "time": 1689843460000,
        "value": 139.478,
        "_internal_originalTime": 1689843460000
    },
    {
        "time": 1689843462000,
        "value": 139.484,
        "_internal_originalTime": 1689843462000
    },
    {
        "time": 1689843463000,
        "value": 139.478,
        "_internal_originalTime": 1689843463000
    },
    {
        "time": 1689843464000,
        "value": 139.472,
        "_internal_originalTime": 1689843464000
    },
    {
        "time": 1689843466000,
        "value": 139.473,
        "_internal_originalTime": 1689843466000
    },
    {
        "time": 1689843467000,
        "value": 139.475,
        "_internal_originalTime": 1689843467000
    },
    {
        "time": 1689843469000,
        "value": 139.481,
        "_internal_originalTime": 1689843469000
    },
    {
        "time": 1689843470000,
        "value": 139.471,
        "_internal_originalTime": 1689843470000
    },
    {
        "time": 1689843472000,
        "value": 139.479,
        "_internal_originalTime": 1689843472000
    },
    {
        "time": 1689843473000,
        "value": 139.474,
        "_internal_originalTime": 1689843473000
    },
    {
        "time": 1689843474000,
        "value": 139.476,
        "_internal_originalTime": 1689843474000
    },
    {
        "time": 1689843475000,
        "value": 139.476,
        "_internal_originalTime": 1689843475000
    },
    {
        "time": 1689843476000,
        "value": 139.476,
        "_internal_originalTime": 1689843476000
    },
    {
        "time": 1689843478000,
        "value": 139.476,
        "_internal_originalTime": 1689843478000
    },
    {
        "time": 1689843479000,
        "value": 139.476,
        "_internal_originalTime": 1689843479000
    },
    {
        "time": 1689843480000,
        "value": 139.486,
        "_internal_originalTime": 1689843480000
    },
    {
        "time": 1689843481000,
        "value": 139.481,
        "_internal_originalTime": 1689843481000
    },
    {
        "time": 1689843482000,
        "value": 139.486,
        "_internal_originalTime": 1689843482000
    },
    {
        "time": 1689843484000,
        "value": 139.496,
        "_internal_originalTime": 1689843484000
    },
    {
        "time": 1689843485000,
        "value": 139.492,
        "_internal_originalTime": 1689843485000
    },
    {
        "time": 1689843486000,
        "value": 139.494,
        "_internal_originalTime": 1689843486000
    },
    {
        "time": 1689843487000,
        "value": 139.49,
        "_internal_originalTime": 1689843487000
    },
    {
        "time": 1689843489000,
        "value": 139.488,
        "_internal_originalTime": 1689843489000
    },
    {
        "time": 1689843490000,
        "value": 139.494,
        "_internal_originalTime": 1689843490000
    },
    {
        "time": 1689843491000,
        "value": 139.492,
        "_internal_originalTime": 1689843491000
    },
    {
        "time": 1689843493000,
        "value": 139.494,
        "_internal_originalTime": 1689843493000
    },
    {
        "time": 1689843495000,
        "value": 139.49,
        "_internal_originalTime": 1689843495000
    },
    {
        "time": 1689843496000,
        "value": 139.494,
        "_internal_originalTime": 1689843496000
    },
    {
        "time": 1689843498000,
        "value": 139.486,
        "_internal_originalTime": 1689843498000
    },
    {
        "time": 1689843499000,
        "value": 139.491,
        "_internal_originalTime": 1689843499000
    },
    {
        "time": 1689843500000,
        "value": 139.491,
        "_internal_originalTime": 1689843500000
    },
    {
        "time": 1689843502000,
        "value": 139.491,
        "_internal_originalTime": 1689843502000
    },
    {
        "time": 1689843504000,
        "value": 139.491,
        "_internal_originalTime": 1689843504000
    },
    {
        "time": 1689843507000,
        "value": 139.49,
        "_internal_originalTime": 1689843507000
    },
    {
        "time": 1689843511000,
        "value": 139.485,
        "_internal_originalTime": 1689843511000
    },
    {
        "time": 1689843512000,
        "value": 139.486,
        "_internal_originalTime": 1689843512000
    },
    {
        "time": 1689843517000,
        "value": 139.49,
        "_internal_originalTime": 1689843517000
    },
    {
        "time": 1689843518000,
        "value": 139.486,
        "_internal_originalTime": 1689843518000
    },
    {
        "time": 1689843519000,
        "value": 139.486,
        "_internal_originalTime": 1689843519000
    },
    {
        "time": 1689843521000,
        "value": 139.486,
        "_internal_originalTime": 1689843521000
    },
    {
        "time": 1689843523000,
        "value": 139.489,
        "_internal_originalTime": 1689843523000
    },
    {
        "time": 1689843525000,
        "value": 139.491,
        "_internal_originalTime": 1689843525000
    },
    {
        "time": 1689843526000,
        "value": 139.49,
        "_internal_originalTime": 1689843526000
    },
    {
        "time": 1689843527000,
        "value": 139.49,
        "_internal_originalTime": 1689843527000
    },
    {
        "time": 1689843528000,
        "value": 139.49,
        "_internal_originalTime": 1689843528000
    },
    {
        "time": 1689843529000,
        "value": 139.488,
        "_internal_originalTime": 1689843529000
    },
    {
        "time": 1689843531000,
        "value": 139.493,
        "_internal_originalTime": 1689843531000
    },
    {
        "time": 1689843532000,
        "value": 139.486,
        "_internal_originalTime": 1689843532000
    },
    {
        "time": 1689843533000,
        "value": 139.484,
        "_internal_originalTime": 1689843533000
    },
    {
        "time": 1689843535000,
        "value": 139.492,
        "_internal_originalTime": 1689843535000
    },
    {
        "time": 1689843536000,
        "value": 139.491,
        "_internal_originalTime": 1689843536000
    },
    {
        "time": 1689843537000,
        "value": 139.484,
        "_internal_originalTime": 1689843537000
    },
    {
        "time": 1689843539000,
        "value": 139.482,
        "_internal_originalTime": 1689843539000
    },
    {
        "time": 1689843540000,
        "value": 139.483,
        "_internal_originalTime": 1689843540000
    },
    {
        "time": 1689843541000,
        "value": 139.476,
        "_internal_originalTime": 1689843541000
    },
    {
        "time": 1689843543000,
        "value": 139.475,
        "_internal_originalTime": 1689843543000
    },
    {
        "time": 1689843544000,
        "value": 139.472,
        "_internal_originalTime": 1689843544000
    },
    {
        "time": 1689843545000,
        "value": 139.475,
        "_internal_originalTime": 1689843545000
    },
    {
        "time": 1689843546000,
        "value": 139.475,
        "_internal_originalTime": 1689843546000
    },
    {
        "time": 1689843547000,
        "value": 139.474,
        "_internal_originalTime": 1689843547000
    },
    {
        "time": 1689843548000,
        "value": 139.468,
        "_internal_originalTime": 1689843548000
    },
    {
        "time": 1689843549000,
        "value": 139.474,
        "_internal_originalTime": 1689843549000
    },
    {
        "time": 1689843550000,
        "value": 139.474,
        "_internal_originalTime": 1689843550000
    },
    {
        "time": 1689843551000,
        "value": 139.472,
        "_internal_originalTime": 1689843551000
    },
    {
        "time": 1689843553000,
        "value": 139.474,
        "_internal_originalTime": 1689843553000
    },
    {
        "time": 1689843555000,
        "value": 139.473,
        "_internal_originalTime": 1689843555000
    },
    {
        "time": 1689843556000,
        "value": 139.476,
        "_internal_originalTime": 1689843556000
    },
    {
        "time": 1689843557000,
        "value": 139.478,
        "_internal_originalTime": 1689843557000
    },
    {
        "time": 1689843558000,
        "value": 139.478,
        "_internal_originalTime": 1689843558000
    },
    {
        "time": 1689843560000,
        "value": 139.468,
        "_internal_originalTime": 1689843560000
    },
    {
        "time": 1689843562000,
        "value": 139.469,
        "_internal_originalTime": 1689843562000
    },
    {
        "time": 1689843563000,
        "value": 139.464,
        "_internal_originalTime": 1689843563000
    },
    {
        "time": 1689843565000,
        "value": 139.461,
        "_internal_originalTime": 1689843565000
    },
    {
        "time": 1689843566000,
        "value": 139.456,
        "_internal_originalTime": 1689843566000
    },
    {
        "time": 1689843567000,
        "value": 139.45,
        "_internal_originalTime": 1689843567000
    },
    {
        "time": 1689843569000,
        "value": 139.455,
        "_internal_originalTime": 1689843569000
    },
    {
        "time": 1689843570000,
        "value": 139.449,
        "_internal_originalTime": 1689843570000
    },
    {
        "time": 1689843572000,
        "value": 139.454,
        "_internal_originalTime": 1689843572000
    },
    {
        "time": 1689843573000,
        "value": 139.449,
        "_internal_originalTime": 1689843573000
    },
    {
        "time": 1689843574000,
        "value": 139.448,
        "_internal_originalTime": 1689843574000
    },
    {
        "time": 1689843575000,
        "value": 139.448,
        "_internal_originalTime": 1689843575000
    },
    {
        "time": 1689843577000,
        "value": 139.452,
        "_internal_originalTime": 1689843577000
    },
    {
        "time": 1689843578000,
        "value": 139.453,
        "_internal_originalTime": 1689843578000
    },
    {
        "time": 1689843579000,
        "value": 139.451,
        "_internal_originalTime": 1689843579000
    },
    {
        "time": 1689843580000,
        "value": 139.452,
        "_internal_originalTime": 1689843580000
    },
    {
        "time": 1689843581000,
        "value": 139.452,
        "_internal_originalTime": 1689843581000
    },
    {
        "time": 1689843582000,
        "value": 139.446,
        "_internal_originalTime": 1689843582000
    },
    {
        "time": 1689843584000,
        "value": 139.451,
        "_internal_originalTime": 1689843584000
    },
    {
        "time": 1689843585000,
        "value": 139.446,
        "_internal_originalTime": 1689843585000
    },
    {
        "time": 1689843586000,
        "value": 139.452,
        "_internal_originalTime": 1689843586000
    },
    {
        "time": 1689843588000,
        "value": 139.451,
        "_internal_originalTime": 1689843588000
    },
    {
        "time": 1689843589000,
        "value": 139.451,
        "_internal_originalTime": 1689843589000
    },
    {
        "time": 1689843590000,
        "value": 139.454,
        "_internal_originalTime": 1689843590000
    },
    {
        "time": 1689843591000,
        "value": 139.456,
        "_internal_originalTime": 1689843591000
    },
    {
        "time": 1689843592000,
        "value": 139.456,
        "_internal_originalTime": 1689843592000
    },
    {
        "time": 1689843593000,
        "value": 139.451,
        "_internal_originalTime": 1689843593000
    },
    {
        "time": 1689843594000,
        "value": 139.457,
        "_internal_originalTime": 1689843594000
    },
    {
        "time": 1689843596000,
        "value": 139.449,
        "_internal_originalTime": 1689843596000
    },
    {
        "time": 1689843597000,
        "value": 139.454,
        "_internal_originalTime": 1689843597000
    },
    {
        "time": 1689843598000,
        "value": 139.456,
        "_internal_originalTime": 1689843598000
    },
    {
        "time": 1689843599000,
        "value": 139.452,
        "_internal_originalTime": 1689843599000
    },
    {
        "time": 1689843600000,
        "value": 139.448,
        "_internal_originalTime": 1689843600000
    },
    {
        "time": 1689843601000,
        "value": 139.43,
        "_internal_originalTime": 1689843601000
    },
    {
        "time": 1689843602000,
        "value": 139.457,
        "_internal_originalTime": 1689843602000
    },
    {
        "time": 1689843603000,
        "value": 139.454,
        "_internal_originalTime": 1689843603000
    },
    {
        "time": 1689843604000,
        "value": 139.452,
        "_internal_originalTime": 1689843604000
    },
    {
        "time": 1689843605000,
        "value": 139.442,
        "_internal_originalTime": 1689843605000
    },
    {
        "time": 1689843607000,
        "value": 139.452,
        "_internal_originalTime": 1689843607000
    },
    {
        "time": 1689843608000,
        "value": 139.448,
        "_internal_originalTime": 1689843608000
    },
    {
        "time": 1689843609000,
        "value": 139.448,
        "_internal_originalTime": 1689843609000
    },
    {
        "time": 1689843644000,
        "value": 139.455,
        "_internal_originalTime": 1689843644000
    },
    {
        "time": 1689843645000,
        "value": 139.456,
        "_internal_originalTime": 1689843645000
    },
    {
        "time": 1689843647000,
        "value": 139.457,
        "_internal_originalTime": 1689843647000
    },
    {
        "time": 1689843648000,
        "value": 139.456,
        "_internal_originalTime": 1689843648000
    },
    {
        "time": 1689843651000,
        "value": 139.454,
        "_internal_originalTime": 1689843651000
    },
    {
        "time": 1689843652000,
        "value": 139.447,
        "_internal_originalTime": 1689843652000
    },
    {
        "time": 1689843653000,
        "value": 139.455,
        "_internal_originalTime": 1689843653000
    },
    {
        "time": 1689843655000,
        "value": 139.458,
        "_internal_originalTime": 1689843655000
    },
    {
        "time": 1689843657000,
        "value": 139.454,
        "_internal_originalTime": 1689843657000
    },
    {
        "time": 1689843659000,
        "value": 139.454,
        "_internal_originalTime": 1689843659000
    },
    {
        "time": 1689843660000,
        "value": 139.448,
        "_internal_originalTime": 1689843660000
    },
    {
        "time": 1689843661000,
        "value": 139.454,
        "_internal_originalTime": 1689843661000
    },
    {
        "time": 1689843662000,
        "value": 139.458,
        "_internal_originalTime": 1689843662000
    },
    {
        "time": 1689843664000,
        "value": 139.465,
        "_internal_originalTime": 1689843664000
    },
    {
        "time": 1689843665000,
        "value": 139.46,
        "_internal_originalTime": 1689843665000
    },
    {
        "time": 1689843666000,
        "value": 139.458,
        "_internal_originalTime": 1689843666000
    },
    {
        "time": 1689843668000,
        "value": 139.458,
        "_internal_originalTime": 1689843668000
    },
    {
        "time": 1689843670000,
        "value": 139.463,
        "_internal_originalTime": 1689843670000
    },
    {
        "time": 1689843671000,
        "value": 139.463,
        "_internal_originalTime": 1689843671000
    },
    {
        "time": 1689843673000,
        "value": 139.461,
        "_internal_originalTime": 1689843673000
    },
    {
        "time": 1689843675000,
        "value": 139.465,
        "_internal_originalTime": 1689843675000
    },
    {
        "time": 1689843676000,
        "value": 139.473,
        "_internal_originalTime": 1689843676000
    },
    {
        "time": 1689843677000,
        "value": 139.473,
        "_internal_originalTime": 1689843677000
    },
    {
        "time": 1689843678000,
        "value": 139.472,
        "_internal_originalTime": 1689843678000
    },
    {
        "time": 1689843679000,
        "value": 139.476,
        "_internal_originalTime": 1689843679000
    },
    {
        "time": 1689843680000,
        "value": 139.476,
        "_internal_originalTime": 1689843680000
    },
    {
        "time": 1689843681000,
        "value": 139.475,
        "_internal_originalTime": 1689843681000
    },
    {
        "time": 1689843683000,
        "value": 139.47,
        "_internal_originalTime": 1689843683000
    },
    {
        "time": 1689843684000,
        "value": 139.474,
        "_internal_originalTime": 1689843684000
    },
    {
        "time": 1689843685000,
        "value": 139.471,
        "_internal_originalTime": 1689843685000
    },
    {
        "time": 1689843686000,
        "value": 139.474,
        "_internal_originalTime": 1689843686000
    },
    {
        "time": 1689843687000,
        "value": 139.474,
        "_internal_originalTime": 1689843687000
    },
    {
        "time": 1689843690000,
        "value": 139.477,
        "_internal_originalTime": 1689843690000
    },
    {
        "time": 1689843691000,
        "value": 139.474,
        "_internal_originalTime": 1689843691000
    },
    {
        "time": 1689843693000,
        "value": 139.477,
        "_internal_originalTime": 1689843693000
    },
    {
        "time": 1689843695000,
        "value": 139.48,
        "_internal_originalTime": 1689843695000
    },
    {
        "time": 1689843696000,
        "value": 139.479,
        "_internal_originalTime": 1689843696000
    },
    {
        "time": 1689843697000,
        "value": 139.486,
        "_internal_originalTime": 1689843697000
    },
    {
        "time": 1689843699000,
        "value": 139.483,
        "_internal_originalTime": 1689843699000
    },
    {
        "time": 1689843700000,
        "value": 139.491,
        "_internal_originalTime": 1689843700000
    },
    {
        "time": 1689843701000,
        "value": 139.489,
        "_internal_originalTime": 1689843701000
    },
    {
        "time": 1689843702000,
        "value": 139.489,
        "_internal_originalTime": 1689843702000
    },
    {
        "time": 1689843703000,
        "value": 139.49,
        "_internal_originalTime": 1689843703000
    },
    {
        "time": 1689843705000,
        "value": 139.488,
        "_internal_originalTime": 1689843705000
    },
    {
        "time": 1689843706000,
        "value": 139.489,
        "_internal_originalTime": 1689843706000
    },
    {
        "time": 1689843707000,
        "value": 139.492,
        "_internal_originalTime": 1689843707000
    },
    {
        "time": 1689843708000,
        "value": 139.49,
        "_internal_originalTime": 1689843708000
    },
    {
        "time": 1689843711000,
        "value": 139.482,
        "_internal_originalTime": 1689843711000
    },
    {
        "time": 1689843712000,
        "value": 139.489,
        "_internal_originalTime": 1689843712000
    },
    {
        "time": 1689843715000,
        "value": 139.483,
        "_internal_originalTime": 1689843715000
    },
    {
        "time": 1689843716000,
        "value": 139.48,
        "_internal_originalTime": 1689843716000
    },
    {
        "time": 1689843717000,
        "value": 139.479,
        "_internal_originalTime": 1689843717000
    },
    {
        "time": 1689843719000,
        "value": 139.484,
        "_internal_originalTime": 1689843719000
    },
    {
        "time": 1689843720000,
        "value": 139.478,
        "_internal_originalTime": 1689843720000
    },
    {
        "time": 1689843721000,
        "value": 139.483,
        "_internal_originalTime": 1689843721000
    },
    {
        "time": 1689843723000,
        "value": 139.48,
        "_internal_originalTime": 1689843723000
    },
    {
        "time": 1689843724000,
        "value": 139.481,
        "_internal_originalTime": 1689843724000
    },
    {
        "time": 1689843725000,
        "value": 139.479,
        "_internal_originalTime": 1689843725000
    },
    {
        "time": 1689843727000,
        "value": 139.487,
        "_internal_originalTime": 1689843727000
    },
    {
        "time": 1689843729000,
        "value": 139.488,
        "_internal_originalTime": 1689843729000
    },
    {
        "time": 1689843730000,
        "value": 139.492,
        "_internal_originalTime": 1689843730000
    },
    {
        "time": 1689843731000,
        "value": 139.486,
        "_internal_originalTime": 1689843731000
    },
    {
        "time": 1689843732000,
        "value": 139.491,
        "_internal_originalTime": 1689843732000
    },
    {
        "time": 1689843734000,
        "value": 139.49,
        "_internal_originalTime": 1689843734000
    },
    {
        "time": 1689843735000,
        "value": 139.486,
        "_internal_originalTime": 1689843735000
    },
    {
        "time": 1689843736000,
        "value": 139.492,
        "_internal_originalTime": 1689843736000
    },
    {
        "time": 1689843737000,
        "value": 139.487,
        "_internal_originalTime": 1689843737000
    },
    {
        "time": 1689843738000,
        "value": 139.491,
        "_internal_originalTime": 1689843738000
    },
    {
        "time": 1689843740000,
        "value": 139.492,
        "_internal_originalTime": 1689843740000
    },
    {
        "time": 1689843741000,
        "value": 139.487,
        "_internal_originalTime": 1689843741000
    },
    {
        "time": 1689843743000,
        "value": 139.492,
        "_internal_originalTime": 1689843743000
    },
    {
        "time": 1689843745000,
        "value": 139.488,
        "_internal_originalTime": 1689843745000
    },
    {
        "time": 1689843747000,
        "value": 139.487,
        "_internal_originalTime": 1689843747000
    },
    {
        "time": 1689843748000,
        "value": 139.488,
        "_internal_originalTime": 1689843748000
    },
    {
        "time": 1689843750000,
        "value": 139.488,
        "_internal_originalTime": 1689843750000
    },
    {
        "time": 1689843751000,
        "value": 139.502,
        "_internal_originalTime": 1689843751000
    },
    {
        "time": 1689843752000,
        "value": 139.507,
        "_internal_originalTime": 1689843752000
    },
    {
        "time": 1689843753000,
        "value": 139.516,
        "_internal_originalTime": 1689843753000
    },
    {
        "time": 1689843754000,
        "value": 139.51,
        "_internal_originalTime": 1689843754000
    },
    {
        "time": 1689843755000,
        "value": 139.514,
        "_internal_originalTime": 1689843755000
    },
    {
        "time": 1689843757000,
        "value": 139.515,
        "_internal_originalTime": 1689843757000
    },
    {
        "time": 1689843758000,
        "value": 139.515,
        "_internal_originalTime": 1689843758000
    },
    {
        "time": 1689843759000,
        "value": 139.518,
        "_internal_originalTime": 1689843759000
    },
    {
        "time": 1689843761000,
        "value": 139.512,
        "_internal_originalTime": 1689843761000
    },
    {
        "time": 1689843763000,
        "value": 139.508,
        "_internal_originalTime": 1689843763000
    },
    {
        "time": 1689843765000,
        "value": 139.508,
        "_internal_originalTime": 1689843765000
    },
    {
        "time": 1689843766000,
        "value": 139.508,
        "_internal_originalTime": 1689843766000
    },
    {
        "time": 1689843768000,
        "value": 139.516,
        "_internal_originalTime": 1689843768000
    },
    {
        "time": 1689843769000,
        "value": 139.512,
        "_internal_originalTime": 1689843769000
    },
    {
        "time": 1689843770000,
        "value": 139.51,
        "_internal_originalTime": 1689843770000
    },
    {
        "time": 1689843771000,
        "value": 139.511,
        "_internal_originalTime": 1689843771000
    },
    {
        "time": 1689843773000,
        "value": 139.51,
        "_internal_originalTime": 1689843773000
    },
    {
        "time": 1689843774000,
        "value": 139.501,
        "_internal_originalTime": 1689843774000
    },
    {
        "time": 1689843775000,
        "value": 139.501,
        "_internal_originalTime": 1689843775000
    },
    {
        "time": 1689843776000,
        "value": 139.5,
        "_internal_originalTime": 1689843776000
    },
    {
        "time": 1689843777000,
        "value": 139.492,
        "_internal_originalTime": 1689843777000
    },
    {
        "time": 1689843779000,
        "value": 139.499,
        "_internal_originalTime": 1689843779000
    },
    {
        "time": 1689843780000,
        "value": 139.492,
        "_internal_originalTime": 1689843780000
    },
    {
        "time": 1689843781000,
        "value": 139.484,
        "_internal_originalTime": 1689843781000
    },
    {
        "time": 1689843783000,
        "value": 139.487,
        "_internal_originalTime": 1689843783000
    },
    {
        "time": 1689843784000,
        "value": 139.482,
        "_internal_originalTime": 1689843784000
    },
    {
        "time": 1689843785000,
        "value": 139.474,
        "_internal_originalTime": 1689843785000
    },
    {
        "time": 1689843786000,
        "value": 139.48,
        "_internal_originalTime": 1689843786000
    },
    {
        "time": 1689843787000,
        "value": 139.479,
        "_internal_originalTime": 1689843787000
    },
    {
        "time": 1689843789000,
        "value": 139.474,
        "_internal_originalTime": 1689843789000
    },
    {
        "time": 1689843791000,
        "value": 139.473,
        "_internal_originalTime": 1689843791000
    },
    {
        "time": 1689843792000,
        "value": 139.478,
        "_internal_originalTime": 1689843792000
    },
    {
        "time": 1689843795000,
        "value": 139.473,
        "_internal_originalTime": 1689843795000
    },
    {
        "time": 1689843796000,
        "value": 139.468,
        "_internal_originalTime": 1689843796000
    },
    {
        "time": 1689843797000,
        "value": 139.477,
        "_internal_originalTime": 1689843797000
    },
    {
        "time": 1689843799000,
        "value": 139.474,
        "_internal_originalTime": 1689843799000
    },
    {
        "time": 1689843801000,
        "value": 139.473,
        "_internal_originalTime": 1689843801000
    },
    {
        "time": 1689843802000,
        "value": 139.472,
        "_internal_originalTime": 1689843802000
    },
    {
        "time": 1689843803000,
        "value": 139.468,
        "_internal_originalTime": 1689843803000
    },
    {
        "time": 1689843804000,
        "value": 139.475,
        "_internal_originalTime": 1689843804000
    },
    {
        "time": 1689843805000,
        "value": 139.47,
        "_internal_originalTime": 1689843805000
    },
    {
        "time": 1689843807000,
        "value": 139.473,
        "_internal_originalTime": 1689843807000
    },
    {
        "time": 1689843810000,
        "value": 139.472,
        "_internal_originalTime": 1689843810000
    },
    {
        "time": 1689843811000,
        "value": 139.472,
        "_internal_originalTime": 1689843811000
    },
    {
        "time": 1689843813000,
        "value": 139.473,
        "_internal_originalTime": 1689843813000
    },
    {
        "time": 1689843814000,
        "value": 139.469,
        "_internal_originalTime": 1689843814000
    },
    {
        "time": 1689843815000,
        "value": 139.473,
        "_internal_originalTime": 1689843815000
    },
    {
        "time": 1689843817000,
        "value": 139.467,
        "_internal_originalTime": 1689843817000
    },
    {
        "time": 1689843818000,
        "value": 139.472,
        "_internal_originalTime": 1689843818000
    },
    {
        "time": 1689843819000,
        "value": 139.467,
        "_internal_originalTime": 1689843819000
    },
    {
        "time": 1689843820000,
        "value": 139.464,
        "_internal_originalTime": 1689843820000
    },
    {
        "time": 1689843822000,
        "value": 139.469,
        "_internal_originalTime": 1689843822000
    },
    {
        "time": 1689843823000,
        "value": 139.473,
        "_internal_originalTime": 1689843823000
    },
    {
        "time": 1689843825000,
        "value": 139.474,
        "_internal_originalTime": 1689843825000
    },
    {
        "time": 1689843826000,
        "value": 139.472,
        "_internal_originalTime": 1689843826000
    },
    {
        "time": 1689843828000,
        "value": 139.472,
        "_internal_originalTime": 1689843828000
    },
    {
        "time": 1689843829000,
        "value": 139.473,
        "_internal_originalTime": 1689843829000
    },
    {
        "time": 1689843832000,
        "value": 139.475,
        "_internal_originalTime": 1689843832000
    },
    {
        "time": 1689843833000,
        "value": 139.473,
        "_internal_originalTime": 1689843833000
    },
    {
        "time": 1689843834000,
        "value": 139.472,
        "_internal_originalTime": 1689843834000
    },
    {
        "time": 1689843836000,
        "value": 139.475,
        "_internal_originalTime": 1689843836000
    },
    {
        "time": 1689843838000,
        "value": 139.473,
        "_internal_originalTime": 1689843838000
    },
    {
        "time": 1689843840000,
        "value": 139.473,
        "_internal_originalTime": 1689843840000
    },
    {
        "time": 1689843841000,
        "value": 139.465,
        "_internal_originalTime": 1689843841000
    },
    {
        "time": 1689843844000,
        "value": 139.469,
        "_internal_originalTime": 1689843844000
    },
    {
        "time": 1689843845000,
        "value": 139.477,
        "_internal_originalTime": 1689843845000
    },
    {
        "time": 1689843847000,
        "value": 139.472,
        "_internal_originalTime": 1689843847000
    },
    {
        "time": 1689843849000,
        "value": 139.477,
        "_internal_originalTime": 1689843849000
    },
    {
        "time": 1689843851000,
        "value": 139.472,
        "_internal_originalTime": 1689843851000
    },
    {
        "time": 1689843852000,
        "value": 139.482,
        "_internal_originalTime": 1689843852000
    },
    {
        "time": 1689843854000,
        "value": 139.477,
        "_internal_originalTime": 1689843854000
    },
    {
        "time": 1689843855000,
        "value": 139.475,
        "_internal_originalTime": 1689843855000
    },
    {
        "time": 1689843856000,
        "value": 139.476,
        "_internal_originalTime": 1689843856000
    },
    {
        "time": 1689843859000,
        "value": 139.475,
        "_internal_originalTime": 1689843859000
    },
    {
        "time": 1689843860000,
        "value": 139.474,
        "_internal_originalTime": 1689843860000
    },
    {
        "time": 1689843861000,
        "value": 139.468,
        "_internal_originalTime": 1689843861000
    },
    {
        "time": 1689843862000,
        "value": 139.476,
        "_internal_originalTime": 1689843862000
    },
    {
        "time": 1689843863000,
        "value": 139.47,
        "_internal_originalTime": 1689843863000
    },
    {
        "time": 1689843864000,
        "value": 139.473,
        "_internal_originalTime": 1689843864000
    },
    {
        "time": 1689843866000,
        "value": 139.473,
        "_internal_originalTime": 1689843866000
    },
    {
        "time": 1689843867000,
        "value": 139.472,
        "_internal_originalTime": 1689843867000
    },
    {
        "time": 1689843868000,
        "value": 139.473,
        "_internal_originalTime": 1689843868000
    },
    {
        "time": 1689843869000,
        "value": 139.473,
        "_internal_originalTime": 1689843869000
    },
    {
        "time": 1689843871000,
        "value": 139.473,
        "_internal_originalTime": 1689843871000
    },
    {
        "time": 1689843872000,
        "value": 139.473,
        "_internal_originalTime": 1689843872000
    },
    {
        "time": 1689843873000,
        "value": 139.472,
        "_internal_originalTime": 1689843873000
    },
    {
        "time": 1689843874000,
        "value": 139.474,
        "_internal_originalTime": 1689843874000
    },
    {
        "time": 1689843876000,
        "value": 139.476,
        "_internal_originalTime": 1689843876000
    },
    {
        "time": 1689843877000,
        "value": 139.475,
        "_internal_originalTime": 1689843877000
    },
    {
        "time": 1689843878000,
        "value": 139.481,
        "_internal_originalTime": 1689843878000
    },
    {
        "time": 1689843880000,
        "value": 139.481,
        "_internal_originalTime": 1689843880000
    },
    {
        "time": 1689843881000,
        "value": 139.484,
        "_internal_originalTime": 1689843881000
    },
    {
        "time": 1689843882000,
        "value": 139.476,
        "_internal_originalTime": 1689843882000
    },
    {
        "time": 1689843883000,
        "value": 139.482,
        "_internal_originalTime": 1689843883000
    },
    {
        "time": 1689843885000,
        "value": 139.478,
        "_internal_originalTime": 1689843885000
    },
    {
        "time": 1689843886000,
        "value": 139.48,
        "_internal_originalTime": 1689843886000
    },
    {
        "time": 1689843888000,
        "value": 139.485,
        "_internal_originalTime": 1689843888000
    },
    {
        "time": 1689843889000,
        "value": 139.484,
        "_internal_originalTime": 1689843889000
    },
    {
        "time": 1689843891000,
        "value": 139.486,
        "_internal_originalTime": 1689843891000
    },
    {
        "time": 1689843892000,
        "value": 139.485,
        "_internal_originalTime": 1689843892000
    },
    {
        "time": 1689843894000,
        "value": 139.481,
        "_internal_originalTime": 1689843894000
    },
    {
        "time": 1689843895000,
        "value": 139.476,
        "_internal_originalTime": 1689843895000
    },
    {
        "time": 1689843897000,
        "value": 139.484,
        "_internal_originalTime": 1689843897000
    },
    {
        "time": 1689843898000,
        "value": 139.481,
        "_internal_originalTime": 1689843898000
    },
    {
        "time": 1689843899000,
        "value": 139.485,
        "_internal_originalTime": 1689843899000
    },
    {
        "time": 1689843900000,
        "value": 139.487,
        "_internal_originalTime": 1689843900000
    },
    {
        "time": 1689843901000,
        "value": 139.46,
        "_internal_originalTime": 1689843901000
    },
    {
        "time": 1689843902000,
        "value": 139.478,
        "_internal_originalTime": 1689843902000
    },
    {
        "time": 1689843903000,
        "value": 139.473,
        "_internal_originalTime": 1689843903000
    },
    {
        "time": 1689843904000,
        "value": 139.476,
        "_internal_originalTime": 1689843904000
    },
    {
        "time": 1689843905000,
        "value": 139.475,
        "_internal_originalTime": 1689843905000
    },
    {
        "time": 1689843908000,
        "value": 139.471,
        "_internal_originalTime": 1689843908000
    },
    {
        "time": 1689843909000,
        "value": 139.468,
        "_internal_originalTime": 1689843909000
    },
    {
        "time": 1689843910000,
        "value": 139.474,
        "_internal_originalTime": 1689843910000
    },
    {
        "time": 1689843911000,
        "value": 139.473,
        "_internal_originalTime": 1689843911000
    },
    {
        "time": 1689843913000,
        "value": 139.469,
        "_internal_originalTime": 1689843913000
    },
    {
        "time": 1689843914000,
        "value": 139.47,
        "_internal_originalTime": 1689843914000
    },
    {
        "time": 1689843915000,
        "value": 139.47,
        "_internal_originalTime": 1689843915000
    },
    {
        "time": 1689843916000,
        "value": 139.472,
        "_internal_originalTime": 1689843916000
    },
    {
        "time": 1689843917000,
        "value": 139.472,
        "_internal_originalTime": 1689843917000
    },
    {
        "time": 1689843918000,
        "value": 139.477,
        "_internal_originalTime": 1689843918000
    },
    {
        "time": 1689843920000,
        "value": 139.476,
        "_internal_originalTime": 1689843920000
    },
    {
        "time": 1689843922000,
        "value": 139.469,
        "_internal_originalTime": 1689843922000
    },
    {
        "time": 1689843923000,
        "value": 139.474,
        "_internal_originalTime": 1689843923000
    },
    {
        "time": 1689843925000,
        "value": 139.469,
        "_internal_originalTime": 1689843925000
    },
    {
        "time": 1689843926000,
        "value": 139.464,
        "_internal_originalTime": 1689843926000
    },
    {
        "time": 1689843928000,
        "value": 139.476,
        "_internal_originalTime": 1689843928000
    },
    {
        "time": 1689843929000,
        "value": 139.483,
        "_internal_originalTime": 1689843929000
    },
    {
        "time": 1689843931000,
        "value": 139.481,
        "_internal_originalTime": 1689843931000
    },
    {
        "time": 1689843932000,
        "value": 139.482,
        "_internal_originalTime": 1689843932000
    },
    {
        "time": 1689843933000,
        "value": 139.487,
        "_internal_originalTime": 1689843933000
    },
    {
        "time": 1689843934000,
        "value": 139.483,
        "_internal_originalTime": 1689843934000
    },
    {
        "time": 1689843936000,
        "value": 139.481,
        "_internal_originalTime": 1689843936000
    },
    {
        "time": 1689843938000,
        "value": 139.483,
        "_internal_originalTime": 1689843938000
    },
    {
        "time": 1689843939000,
        "value": 139.478,
        "_internal_originalTime": 1689843939000
    },
    {
        "time": 1689843941000,
        "value": 139.483,
        "_internal_originalTime": 1689843941000
    },
    {
        "time": 1689843945000,
        "value": 139.485,
        "_internal_originalTime": 1689843945000
    },
    {
        "time": 1689843946000,
        "value": 139.48,
        "_internal_originalTime": 1689843946000
    },
    {
        "time": 1689843948000,
        "value": 139.482,
        "_internal_originalTime": 1689843948000
    },
    {
        "time": 1689843949000,
        "value": 139.483,
        "_internal_originalTime": 1689843949000
    },
    {
        "time": 1689843950000,
        "value": 139.486,
        "_internal_originalTime": 1689843950000
    },
    {
        "time": 1689843951000,
        "value": 139.49,
        "_internal_originalTime": 1689843951000
    },
    {
        "time": 1689843952000,
        "value": 139.49,
        "_internal_originalTime": 1689843952000
    },
    {
        "time": 1689843954000,
        "value": 139.487,
        "_internal_originalTime": 1689843954000
    },
    {
        "time": 1689843957000,
        "value": 139.482,
        "_internal_originalTime": 1689843957000
    },
    {
        "time": 1689843958000,
        "value": 139.478,
        "_internal_originalTime": 1689843958000
    },
    {
        "time": 1689843960000,
        "value": 139.479,
        "_internal_originalTime": 1689843960000
    },
    {
        "time": 1689843962000,
        "value": 139.479,
        "_internal_originalTime": 1689843962000
    },
    {
        "time": 1689843963000,
        "value": 139.482,
        "_internal_originalTime": 1689843963000
    },
    {
        "time": 1689843964000,
        "value": 139.48,
        "_internal_originalTime": 1689843964000
    },
    {
        "time": 1689843965000,
        "value": 139.477,
        "_internal_originalTime": 1689843965000
    },
    {
        "time": 1689843966000,
        "value": 139.479,
        "_internal_originalTime": 1689843966000
    },
    {
        "time": 1689843967000,
        "value": 139.478,
        "_internal_originalTime": 1689843967000
    },
    {
        "time": 1689843969000,
        "value": 139.472,
        "_internal_originalTime": 1689843969000
    },
    {
        "time": 1689843970000,
        "value": 139.476,
        "_internal_originalTime": 1689843970000
    },
    {
        "time": 1689843972000,
        "value": 139.477,
        "_internal_originalTime": 1689843972000
    },
    {
        "time": 1689843974000,
        "value": 139.472,
        "_internal_originalTime": 1689843974000
    },
    {
        "time": 1689843975000,
        "value": 139.468,
        "_internal_originalTime": 1689843975000
    },
    {
        "time": 1689843976000,
        "value": 139.464,
        "_internal_originalTime": 1689843976000
    },
    {
        "time": 1689843977000,
        "value": 139.461,
        "_internal_originalTime": 1689843977000
    },
    {
        "time": 1689843979000,
        "value": 139.46,
        "_internal_originalTime": 1689843979000
    },
    {
        "time": 1689843980000,
        "value": 139.463,
        "_internal_originalTime": 1689843980000
    },
    {
        "time": 1689843981000,
        "value": 139.465,
        "_internal_originalTime": 1689843981000
    },
    {
        "time": 1689843982000,
        "value": 139.464,
        "_internal_originalTime": 1689843982000
    },
    {
        "time": 1689843983000,
        "value": 139.468,
        "_internal_originalTime": 1689843983000
    },
    {
        "time": 1689843985000,
        "value": 139.47,
        "_internal_originalTime": 1689843985000
    },
    {
        "time": 1689843987000,
        "value": 139.479,
        "_internal_originalTime": 1689843987000
    },
    {
        "time": 1689843988000,
        "value": 139.479,
        "_internal_originalTime": 1689843988000
    },
    {
        "time": 1689843989000,
        "value": 139.476,
        "_internal_originalTime": 1689843989000
    },
    {
        "time": 1689843991000,
        "value": 139.476,
        "_internal_originalTime": 1689843991000
    },
    {
        "time": 1689843992000,
        "value": 139.474,
        "_internal_originalTime": 1689843992000
    },
    {
        "time": 1689843993000,
        "value": 139.472,
        "_internal_originalTime": 1689843993000
    },
    {
        "time": 1689843994000,
        "value": 139.473,
        "_internal_originalTime": 1689843994000
    },
    {
        "time": 1689843996000,
        "value": 139.469,
        "_internal_originalTime": 1689843996000
    },
    {
        "time": 1689843998000,
        "value": 139.475,
        "_internal_originalTime": 1689843998000
    },
    {
        "time": 1689844000000,
        "value": 139.472,
        "_internal_originalTime": 1689844000000
    },
    {
        "time": 1689844001000,
        "value": 139.486,
        "_internal_originalTime": 1689844001000
    },
    {
        "time": 1689844002000,
        "value": 139.486,
        "_internal_originalTime": 1689844002000
    },
    {
        "time": 1689844003000,
        "value": 139.486,
        "_internal_originalTime": 1689844003000
    },
    {
        "time": 1689844005000,
        "value": 139.481,
        "_internal_originalTime": 1689844005000
    },
    {
        "time": 1689844006000,
        "value": 139.482,
        "_internal_originalTime": 1689844006000
    },
    {
        "time": 1689844007000,
        "value": 139.486,
        "_internal_originalTime": 1689844007000
    },
    {
        "time": 1689844009000,
        "value": 139.492,
        "_internal_originalTime": 1689844009000
    },
    {
        "time": 1689844011000,
        "value": 139.488,
        "_internal_originalTime": 1689844011000
    },
    {
        "time": 1689844012000,
        "value": 139.491,
        "_internal_originalTime": 1689844012000
    },
    {
        "time": 1689844013000,
        "value": 139.488,
        "_internal_originalTime": 1689844013000
    },
    {
        "time": 1689844014000,
        "value": 139.491,
        "_internal_originalTime": 1689844014000
    },
    {
        "time": 1689844015000,
        "value": 139.492,
        "_internal_originalTime": 1689844015000
    },
    {
        "time": 1689844016000,
        "value": 139.488,
        "_internal_originalTime": 1689844016000
    },
    {
        "time": 1689844017000,
        "value": 139.492,
        "_internal_originalTime": 1689844017000
    },
    {
        "time": 1689844018000,
        "value": 139.492,
        "_internal_originalTime": 1689844018000
    },
    {
        "time": 1689844020000,
        "value": 139.486,
        "_internal_originalTime": 1689844020000
    },
    {
        "time": 1689844021000,
        "value": 139.491,
        "_internal_originalTime": 1689844021000
    },
    {
        "time": 1689844022000,
        "value": 139.488,
        "_internal_originalTime": 1689844022000
    },
    {
        "time": 1689844023000,
        "value": 139.491,
        "_internal_originalTime": 1689844023000
    },
    {
        "time": 1689844024000,
        "value": 139.495,
        "_internal_originalTime": 1689844024000
    },
    {
        "time": 1689844026000,
        "value": 139.48,
        "_internal_originalTime": 1689844026000
    },
    {
        "time": 1689844027000,
        "value": 139.489,
        "_internal_originalTime": 1689844027000
    },
    {
        "time": 1689844029000,
        "value": 139.488,
        "_internal_originalTime": 1689844029000
    },
    {
        "time": 1689844030000,
        "value": 139.482,
        "_internal_originalTime": 1689844030000
    },
    {
        "time": 1689844031000,
        "value": 139.478,
        "_internal_originalTime": 1689844031000
    },
    {
        "time": 1689844033000,
        "value": 139.48,
        "_internal_originalTime": 1689844033000
    },
    {
        "time": 1689844034000,
        "value": 139.484,
        "_internal_originalTime": 1689844034000
    },
    {
        "time": 1689844035000,
        "value": 139.483,
        "_internal_originalTime": 1689844035000
    },
    {
        "time": 1689844036000,
        "value": 139.483,
        "_internal_originalTime": 1689844036000
    },
    {
        "time": 1689844037000,
        "value": 139.484,
        "_internal_originalTime": 1689844037000
    },
    {
        "time": 1689844038000,
        "value": 139.484,
        "_internal_originalTime": 1689844038000
    },
    {
        "time": 1689844040000,
        "value": 139.484,
        "_internal_originalTime": 1689844040000
    },
    {
        "time": 1689844041000,
        "value": 139.484,
        "_internal_originalTime": 1689844041000
    },
    {
        "time": 1689844042000,
        "value": 139.483,
        "_internal_originalTime": 1689844042000
    },
    {
        "time": 1689844043000,
        "value": 139.484,
        "_internal_originalTime": 1689844043000
    },
    {
        "time": 1689844045000,
        "value": 139.483,
        "_internal_originalTime": 1689844045000
    },
    {
        "time": 1689844046000,
        "value": 139.484,
        "_internal_originalTime": 1689844046000
    },
    {
        "time": 1689844049000,
        "value": 139.484,
        "_internal_originalTime": 1689844049000
    },
    {
        "time": 1689844050000,
        "value": 139.482,
        "_internal_originalTime": 1689844050000
    },
    {
        "time": 1689844051000,
        "value": 139.483,
        "_internal_originalTime": 1689844051000
    },
    {
        "time": 1689844052000,
        "value": 139.481,
        "_internal_originalTime": 1689844052000
    },
    {
        "time": 1689844053000,
        "value": 139.483,
        "_internal_originalTime": 1689844053000
    },
    {
        "time": 1689844054000,
        "value": 139.484,
        "_internal_originalTime": 1689844054000
    },
    {
        "time": 1689844056000,
        "value": 139.478,
        "_internal_originalTime": 1689844056000
    },
    {
        "time": 1689844057000,
        "value": 139.484,
        "_internal_originalTime": 1689844057000
    },
    {
        "time": 1689844059000,
        "value": 139.478,
        "_internal_originalTime": 1689844059000
    },
    {
        "time": 1689844061000,
        "value": 139.482,
        "_internal_originalTime": 1689844061000
    },
    {
        "time": 1689844062000,
        "value": 139.483,
        "_internal_originalTime": 1689844062000
    },
    {
        "time": 1689844063000,
        "value": 139.485,
        "_internal_originalTime": 1689844063000
    },
    {
        "time": 1689844064000,
        "value": 139.481,
        "_internal_originalTime": 1689844064000
    },
    {
        "time": 1689844066000,
        "value": 139.486,
        "_internal_originalTime": 1689844066000
    },
    {
        "time": 1689844067000,
        "value": 139.488,
        "_internal_originalTime": 1689844067000
    },
    {
        "time": 1689844068000,
        "value": 139.485,
        "_internal_originalTime": 1689844068000
    },
    {
        "time": 1689844069000,
        "value": 139.489,
        "_internal_originalTime": 1689844069000
    },
    {
        "time": 1689844070000,
        "value": 139.491,
        "_internal_originalTime": 1689844070000
    },
    {
        "time": 1689844071000,
        "value": 139.486,
        "_internal_originalTime": 1689844071000
    },
    {
        "time": 1689844072000,
        "value": 139.491,
        "_internal_originalTime": 1689844072000
    },
    {
        "time": 1689844074000,
        "value": 139.492,
        "_internal_originalTime": 1689844074000
    },
    {
        "time": 1689844076000,
        "value": 139.493,
        "_internal_originalTime": 1689844076000
    },
    {
        "time": 1689844077000,
        "value": 139.496,
        "_internal_originalTime": 1689844077000
    },
    {
        "time": 1689844079000,
        "value": 139.493,
        "_internal_originalTime": 1689844079000
    },
    {
        "time": 1689844080000,
        "value": 139.492,
        "_internal_originalTime": 1689844080000
    },
    {
        "time": 1689844082000,
        "value": 139.494,
        "_internal_originalTime": 1689844082000
    },
    {
        "time": 1689844083000,
        "value": 139.49,
        "_internal_originalTime": 1689844083000
    },
    {
        "time": 1689844084000,
        "value": 139.492,
        "_internal_originalTime": 1689844084000
    },
    {
        "time": 1689844086000,
        "value": 139.492,
        "_internal_originalTime": 1689844086000
    },
    {
        "time": 1689844087000,
        "value": 139.494,
        "_internal_originalTime": 1689844087000
    },
    {
        "time": 1689844088000,
        "value": 139.491,
        "_internal_originalTime": 1689844088000
    },
    {
        "time": 1689844090000,
        "value": 139.493,
        "_internal_originalTime": 1689844090000
    },
    {
        "time": 1689844091000,
        "value": 139.492,
        "_internal_originalTime": 1689844091000
    },
    {
        "time": 1689844092000,
        "value": 139.493,
        "_internal_originalTime": 1689844092000
    },
    {
        "time": 1689844093000,
        "value": 139.49,
        "_internal_originalTime": 1689844093000
    },
    {
        "time": 1689844095000,
        "value": 139.49,
        "_internal_originalTime": 1689844095000
    },
    {
        "time": 1689844096000,
        "value": 139.491,
        "_internal_originalTime": 1689844096000
    },
    {
        "time": 1689844097000,
        "value": 139.49,
        "_internal_originalTime": 1689844097000
    },
    {
        "time": 1689844098000,
        "value": 139.493,
        "_internal_originalTime": 1689844098000
    },
    {
        "time": 1689844100000,
        "value": 139.496,
        "_internal_originalTime": 1689844100000
    },
    {
        "time": 1689844101000,
        "value": 139.494,
        "_internal_originalTime": 1689844101000
    },
    {
        "time": 1689844102000,
        "value": 139.494,
        "_internal_originalTime": 1689844102000
    },
    {
        "time": 1689844104000,
        "value": 139.489,
        "_internal_originalTime": 1689844104000
    },
    {
        "time": 1689844106000,
        "value": 139.494,
        "_internal_originalTime": 1689844106000
    },
    {
        "time": 1689844108000,
        "value": 139.49,
        "_internal_originalTime": 1689844108000
    },
    {
        "time": 1689844110000,
        "value": 139.494,
        "_internal_originalTime": 1689844110000
    },
    {
        "time": 1689844111000,
        "value": 139.495,
        "_internal_originalTime": 1689844111000
    },
    {
        "time": 1689844112000,
        "value": 139.49,
        "_internal_originalTime": 1689844112000
    },
    {
        "time": 1689844113000,
        "value": 139.494,
        "_internal_originalTime": 1689844113000
    },
    {
        "time": 1689844114000,
        "value": 139.495,
        "_internal_originalTime": 1689844114000
    },
    {
        "time": 1689844115000,
        "value": 139.491,
        "_internal_originalTime": 1689844115000
    },
    {
        "time": 1689844117000,
        "value": 139.49,
        "_internal_originalTime": 1689844117000
    },
    {
        "time": 1689844118000,
        "value": 139.49,
        "_internal_originalTime": 1689844118000
    },
    {
        "time": 1689844119000,
        "value": 139.493,
        "_internal_originalTime": 1689844119000
    },
    {
        "time": 1689844121000,
        "value": 139.496,
        "_internal_originalTime": 1689844121000
    },
    {
        "time": 1689844122000,
        "value": 139.496,
        "_internal_originalTime": 1689844122000
    },
    {
        "time": 1689844124000,
        "value": 139.493,
        "_internal_originalTime": 1689844124000
    },
    {
        "time": 1689844126000,
        "value": 139.491,
        "_internal_originalTime": 1689844126000
    },
    {
        "time": 1689844127000,
        "value": 139.494,
        "_internal_originalTime": 1689844127000
    },
    {
        "time": 1689844128000,
        "value": 139.492,
        "_internal_originalTime": 1689844128000
    },
    {
        "time": 1689844129000,
        "value": 139.493,
        "_internal_originalTime": 1689844129000
    },
    {
        "time": 1689844131000,
        "value": 139.491,
        "_internal_originalTime": 1689844131000
    },
    {
        "time": 1689844133000,
        "value": 139.488,
        "_internal_originalTime": 1689844133000
    },
    {
        "time": 1689844134000,
        "value": 139.491,
        "_internal_originalTime": 1689844134000
    },
    {
        "time": 1689844136000,
        "value": 139.489,
        "_internal_originalTime": 1689844136000
    },
    {
        "time": 1689844137000,
        "value": 139.492,
        "_internal_originalTime": 1689844137000
    },
    {
        "time": 1689844139000,
        "value": 139.492,
        "_internal_originalTime": 1689844139000
    },
    {
        "time": 1689844140000,
        "value": 139.47,
        "_internal_originalTime": 1689844140000
    },
    {
        "time": 1689844141000,
        "value": 139.488,
        "_internal_originalTime": 1689844141000
    },
    {
        "time": 1689844142000,
        "value": 139.494,
        "_internal_originalTime": 1689844142000
    },
    {
        "time": 1689844144000,
        "value": 139.492,
        "_internal_originalTime": 1689844144000
    },
    {
        "time": 1689844146000,
        "value": 139.488,
        "_internal_originalTime": 1689844146000
    },
    {
        "time": 1689844147000,
        "value": 139.493,
        "_internal_originalTime": 1689844147000
    },
    {
        "time": 1689844149000,
        "value": 139.486,
        "_internal_originalTime": 1689844149000
    },
    {
        "time": 1689844150000,
        "value": 139.489,
        "_internal_originalTime": 1689844150000
    },
    {
        "time": 1689844151000,
        "value": 139.491,
        "_internal_originalTime": 1689844151000
    },
    {
        "time": 1689844153000,
        "value": 139.49,
        "_internal_originalTime": 1689844153000
    },
    {
        "time": 1689844155000,
        "value": 139.496,
        "_internal_originalTime": 1689844155000
    },
    {
        "time": 1689844156000,
        "value": 139.508,
        "_internal_originalTime": 1689844156000
    },
    {
        "time": 1689844158000,
        "value": 139.511,
        "_internal_originalTime": 1689844158000
    },
    {
        "time": 1689844159000,
        "value": 139.513,
        "_internal_originalTime": 1689844159000
    },
    {
        "time": 1689844161000,
        "value": 139.509,
        "_internal_originalTime": 1689844161000
    },
    {
        "time": 1689844162000,
        "value": 139.506,
        "_internal_originalTime": 1689844162000
    },
    {
        "time": 1689844163000,
        "value": 139.506,
        "_internal_originalTime": 1689844163000
    },
    {
        "time": 1689844165000,
        "value": 139.505,
        "_internal_originalTime": 1689844165000
    },
    {
        "time": 1689844166000,
        "value": 139.505,
        "_internal_originalTime": 1689844166000
    },
    {
        "time": 1689844167000,
        "value": 139.509,
        "_internal_originalTime": 1689844167000
    },
    {
        "time": 1689844169000,
        "value": 139.508,
        "_internal_originalTime": 1689844169000
    },
    {
        "time": 1689844170000,
        "value": 139.51,
        "_internal_originalTime": 1689844170000
    },
    {
        "time": 1689844171000,
        "value": 139.51,
        "_internal_originalTime": 1689844171000
    },
    {
        "time": 1689844172000,
        "value": 139.51,
        "_internal_originalTime": 1689844172000
    },
    {
        "time": 1689844177000,
        "value": 139.51,
        "_internal_originalTime": 1689844177000
    },
    {
        "time": 1689844178000,
        "value": 139.51,
        "_internal_originalTime": 1689844178000
    },
    {
        "time": 1689844180000,
        "value": 139.512,
        "_internal_originalTime": 1689844180000
    },
    {
        "time": 1689844181000,
        "value": 139.512,
        "_internal_originalTime": 1689844181000
    },
    {
        "time": 1689844182000,
        "value": 139.51,
        "_internal_originalTime": 1689844182000
    },
    {
        "time": 1689844184000,
        "value": 139.51,
        "_internal_originalTime": 1689844184000
    },
    {
        "time": 1689844185000,
        "value": 139.507,
        "_internal_originalTime": 1689844185000
    },
    {
        "time": 1689844187000,
        "value": 139.507,
        "_internal_originalTime": 1689844187000
    },
    {
        "time": 1689844188000,
        "value": 139.507,
        "_internal_originalTime": 1689844188000
    },
    {
        "time": 1689844190000,
        "value": 139.51,
        "_internal_originalTime": 1689844190000
    },
    {
        "time": 1689844191000,
        "value": 139.512,
        "_internal_originalTime": 1689844191000
    },
    {
        "time": 1689844193000,
        "value": 139.512,
        "_internal_originalTime": 1689844193000
    },
    {
        "time": 1689844194000,
        "value": 139.509,
        "_internal_originalTime": 1689844194000
    },
    {
        "time": 1689844196000,
        "value": 139.511,
        "_internal_originalTime": 1689844196000
    },
    {
        "time": 1689844199000,
        "value": 139.509,
        "_internal_originalTime": 1689844199000
    },
    {
        "time": 1689844200000,
        "value": 139.512,
        "_internal_originalTime": 1689844200000
    },
    {
        "time": 1689844202000,
        "value": 139.515,
        "_internal_originalTime": 1689844202000
    },
    {
        "time": 1689844203000,
        "value": 139.518,
        "_internal_originalTime": 1689844203000
    },
    {
        "time": 1689844205000,
        "value": 139.516,
        "_internal_originalTime": 1689844205000
    },
    {
        "time": 1689844206000,
        "value": 139.514,
        "_internal_originalTime": 1689844206000
    },
    {
        "time": 1689844207000,
        "value": 139.514,
        "_internal_originalTime": 1689844207000
    },
    {
        "time": 1689844209000,
        "value": 139.511,
        "_internal_originalTime": 1689844209000
    },
    {
        "time": 1689844211000,
        "value": 139.52,
        "_internal_originalTime": 1689844211000
    },
    {
        "time": 1689844212000,
        "value": 139.519,
        "_internal_originalTime": 1689844212000
    },
    {
        "time": 1689844213000,
        "value": 139.521,
        "_internal_originalTime": 1689844213000
    },
    {
        "time": 1689844215000,
        "value": 139.523,
        "_internal_originalTime": 1689844215000
    },
    {
        "time": 1689844216000,
        "value": 139.523,
        "_internal_originalTime": 1689844216000
    },
    {
        "time": 1689844218000,
        "value": 139.521,
        "_internal_originalTime": 1689844218000
    },
    {
        "time": 1689844219000,
        "value": 139.518,
        "_internal_originalTime": 1689844219000
    },
    {
        "time": 1689844220000,
        "value": 139.519,
        "_internal_originalTime": 1689844220000
    },
    {
        "time": 1689844222000,
        "value": 139.52,
        "_internal_originalTime": 1689844222000
    },
    {
        "time": 1689844223000,
        "value": 139.518,
        "_internal_originalTime": 1689844223000
    },
    {
        "time": 1689844226000,
        "value": 139.519,
        "_internal_originalTime": 1689844226000
    },
    {
        "time": 1689844228000,
        "value": 139.52,
        "_internal_originalTime": 1689844228000
    },
    {
        "time": 1689844230000,
        "value": 139.52,
        "_internal_originalTime": 1689844230000
    },
    {
        "time": 1689844231000,
        "value": 139.523,
        "_internal_originalTime": 1689844231000
    },
    {
        "time": 1689844233000,
        "value": 139.522,
        "_internal_originalTime": 1689844233000
    },
    {
        "time": 1689844235000,
        "value": 139.518,
        "_internal_originalTime": 1689844235000
    },
    {
        "time": 1689844236000,
        "value": 139.518,
        "_internal_originalTime": 1689844236000
    },
    {
        "time": 1689844238000,
        "value": 139.521,
        "_internal_originalTime": 1689844238000
    },
    {
        "time": 1689844239000,
        "value": 139.533,
        "_internal_originalTime": 1689844239000
    },
    {
        "time": 1689844240000,
        "value": 139.538,
        "_internal_originalTime": 1689844240000
    },
    {
        "time": 1689844242000,
        "value": 139.538,
        "_internal_originalTime": 1689844242000
    },
    {
        "time": 1689844245000,
        "value": 139.539,
        "_internal_originalTime": 1689844245000
    },
    {
        "time": 1689844246000,
        "value": 139.536,
        "_internal_originalTime": 1689844246000
    },
    {
        "time": 1689844247000,
        "value": 139.536,
        "_internal_originalTime": 1689844247000
    },
    {
        "time": 1689844248000,
        "value": 139.536,
        "_internal_originalTime": 1689844248000
    },
    {
        "time": 1689844249000,
        "value": 139.541,
        "_internal_originalTime": 1689844249000
    },
    {
        "time": 1689844250000,
        "value": 139.541,
        "_internal_originalTime": 1689844250000
    },
    {
        "time": 1689844252000,
        "value": 139.544,
        "_internal_originalTime": 1689844252000
    },
    {
        "time": 1689844253000,
        "value": 139.544,
        "_internal_originalTime": 1689844253000
    },
    {
        "time": 1689844254000,
        "value": 139.54,
        "_internal_originalTime": 1689844254000
    },
    {
        "time": 1689844255000,
        "value": 139.539,
        "_internal_originalTime": 1689844255000
    },
    {
        "time": 1689844256000,
        "value": 139.543,
        "_internal_originalTime": 1689844256000
    },
    {
        "time": 1689844257000,
        "value": 139.543,
        "_internal_originalTime": 1689844257000
    },
    {
        "time": 1689844259000,
        "value": 139.543,
        "_internal_originalTime": 1689844259000
    },
    {
        "time": 1689844260000,
        "value": 139.544,
        "_internal_originalTime": 1689844260000
    },
    {
        "time": 1689844262000,
        "value": 139.535,
        "_internal_originalTime": 1689844262000
    },
    {
        "time": 1689844263000,
        "value": 139.541,
        "_internal_originalTime": 1689844263000
    },
    {
        "time": 1689844264000,
        "value": 139.538,
        "_internal_originalTime": 1689844264000
    },
    {
        "time": 1689844265000,
        "value": 139.539,
        "_internal_originalTime": 1689844265000
    },
    {
        "time": 1689844267000,
        "value": 139.536,
        "_internal_originalTime": 1689844267000
    },
    {
        "time": 1689844268000,
        "value": 139.54,
        "_internal_originalTime": 1689844268000
    },
    {
        "time": 1689844270000,
        "value": 139.539,
        "_internal_originalTime": 1689844270000
    },
    {
        "time": 1689844271000,
        "value": 139.54,
        "_internal_originalTime": 1689844271000
    },
    {
        "time": 1689844272000,
        "value": 139.534,
        "_internal_originalTime": 1689844272000
    },
    {
        "time": 1689844273000,
        "value": 139.54,
        "_internal_originalTime": 1689844273000
    },
    {
        "time": 1689844274000,
        "value": 139.54,
        "_internal_originalTime": 1689844274000
    },
    {
        "time": 1689844276000,
        "value": 139.539,
        "_internal_originalTime": 1689844276000
    },
    {
        "time": 1689844278000,
        "value": 139.531,
        "_internal_originalTime": 1689844278000
    },
    {
        "time": 1689844279000,
        "value": 139.525,
        "_internal_originalTime": 1689844279000
    },
    {
        "time": 1689844281000,
        "value": 139.526,
        "_internal_originalTime": 1689844281000
    },
    {
        "time": 1689844282000,
        "value": 139.526,
        "_internal_originalTime": 1689844282000
    },
    {
        "time": 1689844285000,
        "value": 139.525,
        "_internal_originalTime": 1689844285000
    },
    {
        "time": 1689844287000,
        "value": 139.521,
        "_internal_originalTime": 1689844287000
    },
    {
        "time": 1689844289000,
        "value": 139.524,
        "_internal_originalTime": 1689844289000
    },
    {
        "time": 1689844290000,
        "value": 139.521,
        "_internal_originalTime": 1689844290000
    },
    {
        "time": 1689844291000,
        "value": 139.522,
        "_internal_originalTime": 1689844291000
    },
    {
        "time": 1689844292000,
        "value": 139.521,
        "_internal_originalTime": 1689844292000
    },
    {
        "time": 1689844293000,
        "value": 139.526,
        "_internal_originalTime": 1689844293000
    },
    {
        "time": 1689844295000,
        "value": 139.527,
        "_internal_originalTime": 1689844295000
    },
    {
        "time": 1689844296000,
        "value": 139.523,
        "_internal_originalTime": 1689844296000
    },
    {
        "time": 1689844297000,
        "value": 139.522,
        "_internal_originalTime": 1689844297000
    },
    {
        "time": 1689844300000,
        "value": 139.526,
        "_internal_originalTime": 1689844300000
    },
    {
        "time": 1689844301000,
        "value": 139.523,
        "_internal_originalTime": 1689844301000
    },
    {
        "time": 1689844303000,
        "value": 139.526,
        "_internal_originalTime": 1689844303000
    },
    {
        "time": 1689844305000,
        "value": 139.525,
        "_internal_originalTime": 1689844305000
    },
    {
        "time": 1689844306000,
        "value": 139.523,
        "_internal_originalTime": 1689844306000
    },
    {
        "time": 1689844309000,
        "value": 139.52,
        "_internal_originalTime": 1689844309000
    },
    {
        "time": 1689844310000,
        "value": 139.525,
        "_internal_originalTime": 1689844310000
    },
    {
        "time": 1689844311000,
        "value": 139.531,
        "_internal_originalTime": 1689844311000
    },
    {
        "time": 1689844312000,
        "value": 139.529,
        "_internal_originalTime": 1689844312000
    },
    {
        "time": 1689844313000,
        "value": 139.532,
        "_internal_originalTime": 1689844313000
    },
    {
        "time": 1689844314000,
        "value": 139.527,
        "_internal_originalTime": 1689844314000
    },
    {
        "time": 1689844316000,
        "value": 139.53,
        "_internal_originalTime": 1689844316000
    },
    {
        "time": 1689844317000,
        "value": 139.529,
        "_internal_originalTime": 1689844317000
    },
    {
        "time": 1689844318000,
        "value": 139.528,
        "_internal_originalTime": 1689844318000
    },
    {
        "time": 1689844319000,
        "value": 139.526,
        "_internal_originalTime": 1689844319000
    },
    {
        "time": 1689844320000,
        "value": 139.527,
        "_internal_originalTime": 1689844320000
    },
    {
        "time": 1689844321000,
        "value": 139.524,
        "_internal_originalTime": 1689844321000
    },
    {
        "time": 1689844323000,
        "value": 139.531,
        "_internal_originalTime": 1689844323000
    },
    {
        "time": 1689844324000,
        "value": 139.532,
        "_internal_originalTime": 1689844324000
    },
    {
        "time": 1689844325000,
        "value": 139.538,
        "_internal_originalTime": 1689844325000
    },
    {
        "time": 1689844326000,
        "value": 139.536,
        "_internal_originalTime": 1689844326000
    },
    {
        "time": 1689844328000,
        "value": 139.534,
        "_internal_originalTime": 1689844328000
    },
    {
        "time": 1689844329000,
        "value": 139.525,
        "_internal_originalTime": 1689844329000
    },
    {
        "time": 1689844330000,
        "value": 139.525,
        "_internal_originalTime": 1689844330000
    },
    {
        "time": 1689844332000,
        "value": 139.528,
        "_internal_originalTime": 1689844332000
    },
    {
        "time": 1689844333000,
        "value": 139.522,
        "_internal_originalTime": 1689844333000
    },
    {
        "time": 1689844335000,
        "value": 139.528,
        "_internal_originalTime": 1689844335000
    },
    {
        "time": 1689844337000,
        "value": 139.522,
        "_internal_originalTime": 1689844337000
    },
    {
        "time": 1689844339000,
        "value": 139.531,
        "_internal_originalTime": 1689844339000
    },
    {
        "time": 1689844340000,
        "value": 139.529,
        "_internal_originalTime": 1689844340000
    },
    {
        "time": 1689844341000,
        "value": 139.531,
        "_internal_originalTime": 1689844341000
    },
    {
        "time": 1689844342000,
        "value": 139.529,
        "_internal_originalTime": 1689844342000
    },
    {
        "time": 1689844344000,
        "value": 139.53,
        "_internal_originalTime": 1689844344000
    },
    {
        "time": 1689844347000,
        "value": 139.533,
        "_internal_originalTime": 1689844347000
    },
    {
        "time": 1689844348000,
        "value": 139.528,
        "_internal_originalTime": 1689844348000
    },
    {
        "time": 1689844350000,
        "value": 139.53,
        "_internal_originalTime": 1689844350000
    },
    {
        "time": 1689844351000,
        "value": 139.527,
        "_internal_originalTime": 1689844351000
    },
    {
        "time": 1689844352000,
        "value": 139.532,
        "_internal_originalTime": 1689844352000
    },
    {
        "time": 1689844354000,
        "value": 139.531,
        "_internal_originalTime": 1689844354000
    },
    {
        "time": 1689844355000,
        "value": 139.524,
        "_internal_originalTime": 1689844355000
    },
    {
        "time": 1689844357000,
        "value": 139.525,
        "_internal_originalTime": 1689844357000
    },
    {
        "time": 1689844358000,
        "value": 139.522,
        "_internal_originalTime": 1689844358000
    },
    {
        "time": 1689844360000,
        "value": 139.527,
        "_internal_originalTime": 1689844360000
    },
    {
        "time": 1689844361000,
        "value": 139.526,
        "_internal_originalTime": 1689844361000
    },
    {
        "time": 1689844362000,
        "value": 139.526,
        "_internal_originalTime": 1689844362000
    },
    {
        "time": 1689844364000,
        "value": 139.524,
        "_internal_originalTime": 1689844364000
    },
    {
        "time": 1689844365000,
        "value": 139.529,
        "_internal_originalTime": 1689844365000
    },
    {
        "time": 1689844366000,
        "value": 139.53,
        "_internal_originalTime": 1689844366000
    },
    {
        "time": 1689844368000,
        "value": 139.53,
        "_internal_originalTime": 1689844368000
    },
    {
        "time": 1689844369000,
        "value": 139.531,
        "_internal_originalTime": 1689844369000
    },
    {
        "time": 1689844370000,
        "value": 139.535,
        "_internal_originalTime": 1689844370000
    },
    {
        "time": 1689844371000,
        "value": 139.533,
        "_internal_originalTime": 1689844371000
    },
    {
        "time": 1689844373000,
        "value": 139.532,
        "_internal_originalTime": 1689844373000
    },
    {
        "time": 1689844375000,
        "value": 139.534,
        "_internal_originalTime": 1689844375000
    },
    {
        "time": 1689844377000,
        "value": 139.533,
        "_internal_originalTime": 1689844377000
    },
    {
        "time": 1689844378000,
        "value": 139.527,
        "_internal_originalTime": 1689844378000
    },
    {
        "time": 1689844379000,
        "value": 139.528,
        "_internal_originalTime": 1689844379000
    },
    {
        "time": 1689844380000,
        "value": 139.528,
        "_internal_originalTime": 1689844380000
    },
    {
        "time": 1689844381000,
        "value": 139.529,
        "_internal_originalTime": 1689844381000
    },
    {
        "time": 1689844382000,
        "value": 139.528,
        "_internal_originalTime": 1689844382000
    },
    {
        "time": 1689844385000,
        "value": 139.527,
        "_internal_originalTime": 1689844385000
    },
    {
        "time": 1689844386000,
        "value": 139.529,
        "_internal_originalTime": 1689844386000
    },
    {
        "time": 1689844387000,
        "value": 139.527,
        "_internal_originalTime": 1689844387000
    },
    {
        "time": 1689844389000,
        "value": 139.534,
        "_internal_originalTime": 1689844389000
    },
    {
        "time": 1689844390000,
        "value": 139.532,
        "_internal_originalTime": 1689844390000
    },
    {
        "time": 1689844391000,
        "value": 139.528,
        "_internal_originalTime": 1689844391000
    },
    {
        "time": 1689844392000,
        "value": 139.531,
        "_internal_originalTime": 1689844392000
    },
    {
        "time": 1689844395000,
        "value": 139.524,
        "_internal_originalTime": 1689844395000
    },
    {
        "time": 1689844396000,
        "value": 139.529,
        "_internal_originalTime": 1689844396000
    },
    {
        "time": 1689844397000,
        "value": 139.529,
        "_internal_originalTime": 1689844397000
    },
    {
        "time": 1689844399000,
        "value": 139.529,
        "_internal_originalTime": 1689844399000
    },
    {
        "time": 1689844400000,
        "value": 139.531,
        "_internal_originalTime": 1689844400000
    },
    {
        "time": 1689844401000,
        "value": 139.525,
        "_internal_originalTime": 1689844401000
    },
    {
        "time": 1689844402000,
        "value": 139.52,
        "_internal_originalTime": 1689844402000
    },
    {
        "time": 1689844407000,
        "value": 139.522,
        "_internal_originalTime": 1689844407000
    },
    {
        "time": 1689844408000,
        "value": 139.518,
        "_internal_originalTime": 1689844408000
    },
    {
        "time": 1689844409000,
        "value": 139.524,
        "_internal_originalTime": 1689844409000
    },
    {
        "time": 1689844411000,
        "value": 139.519,
        "_internal_originalTime": 1689844411000
    },
    {
        "time": 1689844413000,
        "value": 139.524,
        "_internal_originalTime": 1689844413000
    },
    {
        "time": 1689844414000,
        "value": 139.518,
        "_internal_originalTime": 1689844414000
    },
    {
        "time": 1689844415000,
        "value": 139.519,
        "_internal_originalTime": 1689844415000
    },
    {
        "time": 1689844416000,
        "value": 139.516,
        "_internal_originalTime": 1689844416000
    },
    {
        "time": 1689844417000,
        "value": 139.524,
        "_internal_originalTime": 1689844417000
    },
    {
        "time": 1689844418000,
        "value": 139.518,
        "_internal_originalTime": 1689844418000
    },
    {
        "time": 1689844419000,
        "value": 139.523,
        "_internal_originalTime": 1689844419000
    },
    {
        "time": 1689844421000,
        "value": 139.52,
        "_internal_originalTime": 1689844421000
    },
    {
        "time": 1689844422000,
        "value": 139.523,
        "_internal_originalTime": 1689844422000
    },
    {
        "time": 1689844423000,
        "value": 139.523,
        "_internal_originalTime": 1689844423000
    },
    {
        "time": 1689844425000,
        "value": 139.519,
        "_internal_originalTime": 1689844425000
    },
    {
        "time": 1689844426000,
        "value": 139.523,
        "_internal_originalTime": 1689844426000
    },
    {
        "time": 1689844427000,
        "value": 139.516,
        "_internal_originalTime": 1689844427000
    },
    {
        "time": 1689844429000,
        "value": 139.52,
        "_internal_originalTime": 1689844429000
    },
    {
        "time": 1689844430000,
        "value": 139.522,
        "_internal_originalTime": 1689844430000
    },
    {
        "time": 1689844431000,
        "value": 139.523,
        "_internal_originalTime": 1689844431000
    },
    {
        "time": 1689844432000,
        "value": 139.52,
        "_internal_originalTime": 1689844432000
    },
    {
        "time": 1689844434000,
        "value": 139.521,
        "_internal_originalTime": 1689844434000
    },
    {
        "time": 1689844436000,
        "value": 139.518,
        "_internal_originalTime": 1689844436000
    },
    {
        "time": 1689844437000,
        "value": 139.525,
        "_internal_originalTime": 1689844437000
    },
    {
        "time": 1689844438000,
        "value": 139.518,
        "_internal_originalTime": 1689844438000
    },
    {
        "time": 1689844439000,
        "value": 139.519,
        "_internal_originalTime": 1689844439000
    },
    {
        "time": 1689844440000,
        "value": 139.517,
        "_internal_originalTime": 1689844440000
    },
    {
        "time": 1689844442000,
        "value": 139.519,
        "_internal_originalTime": 1689844442000
    },
    {
        "time": 1689844443000,
        "value": 139.517,
        "_internal_originalTime": 1689844443000
    },
    {
        "time": 1689844445000,
        "value": 139.518,
        "_internal_originalTime": 1689844445000
    },
    {
        "time": 1689844447000,
        "value": 139.524,
        "_internal_originalTime": 1689844447000
    },
    {
        "time": 1689844448000,
        "value": 139.516,
        "_internal_originalTime": 1689844448000
    },
    {
        "time": 1689844449000,
        "value": 139.512,
        "_internal_originalTime": 1689844449000
    },
    {
        "time": 1689844451000,
        "value": 139.516,
        "_internal_originalTime": 1689844451000
    },
    {
        "time": 1689844453000,
        "value": 139.512,
        "_internal_originalTime": 1689844453000
    },
    {
        "time": 1689844454000,
        "value": 139.512,
        "_internal_originalTime": 1689844454000
    },
    {
        "time": 1689844455000,
        "value": 139.51,
        "_internal_originalTime": 1689844455000
    },
    {
        "time": 1689844456000,
        "value": 139.505,
        "_internal_originalTime": 1689844456000
    },
    {
        "time": 1689844457000,
        "value": 139.5,
        "_internal_originalTime": 1689844457000
    },
    {
        "time": 1689844458000,
        "value": 139.503,
        "_internal_originalTime": 1689844458000
    },
    {
        "time": 1689844460000,
        "value": 139.509,
        "_internal_originalTime": 1689844460000
    },
    {
        "time": 1689844461000,
        "value": 139.509,
        "_internal_originalTime": 1689844461000
    },
    {
        "time": 1689844462000,
        "value": 139.516,
        "_internal_originalTime": 1689844462000
    },
    {
        "time": 1689844464000,
        "value": 139.51,
        "_internal_originalTime": 1689844464000
    },
    {
        "time": 1689844465000,
        "value": 139.511,
        "_internal_originalTime": 1689844465000
    },
    {
        "time": 1689844467000,
        "value": 139.507,
        "_internal_originalTime": 1689844467000
    },
    {
        "time": 1689844468000,
        "value": 139.516,
        "_internal_originalTime": 1689844468000
    },
    {
        "time": 1689844469000,
        "value": 139.515,
        "_internal_originalTime": 1689844469000
    },
    {
        "time": 1689844470000,
        "value": 139.507,
        "_internal_originalTime": 1689844470000
    },
    {
        "time": 1689844472000,
        "value": 139.508,
        "_internal_originalTime": 1689844472000
    },
    {
        "time": 1689844473000,
        "value": 139.508,
        "_internal_originalTime": 1689844473000
    },
    {
        "time": 1689844475000,
        "value": 139.509,
        "_internal_originalTime": 1689844475000
    },
    {
        "time": 1689844476000,
        "value": 139.515,
        "_internal_originalTime": 1689844476000
    },
    {
        "time": 1689844477000,
        "value": 139.513,
        "_internal_originalTime": 1689844477000
    },
    {
        "time": 1689844478000,
        "value": 139.511,
        "_internal_originalTime": 1689844478000
    },
    {
        "time": 1689844480000,
        "value": 139.502,
        "_internal_originalTime": 1689844480000
    },
    {
        "time": 1689844482000,
        "value": 139.508,
        "_internal_originalTime": 1689844482000
    },
    {
        "time": 1689844483000,
        "value": 139.502,
        "_internal_originalTime": 1689844483000
    },
    {
        "time": 1689844485000,
        "value": 139.503,
        "_internal_originalTime": 1689844485000
    },
    {
        "time": 1689844486000,
        "value": 139.497,
        "_internal_originalTime": 1689844486000
    },
    {
        "time": 1689844487000,
        "value": 139.498,
        "_internal_originalTime": 1689844487000
    },
    {
        "time": 1689844488000,
        "value": 139.499,
        "_internal_originalTime": 1689844488000
    },
    {
        "time": 1689844489000,
        "value": 139.498,
        "_internal_originalTime": 1689844489000
    },
    {
        "time": 1689844491000,
        "value": 139.5,
        "_internal_originalTime": 1689844491000
    },
    {
        "time": 1689844492000,
        "value": 139.502,
        "_internal_originalTime": 1689844492000
    },
    {
        "time": 1689844493000,
        "value": 139.502,
        "_internal_originalTime": 1689844493000
    },
    {
        "time": 1689844495000,
        "value": 139.5,
        "_internal_originalTime": 1689844495000
    },
    {
        "time": 1689844497000,
        "value": 139.498,
        "_internal_originalTime": 1689844497000
    },
    {
        "time": 1689844499000,
        "value": 139.498,
        "_internal_originalTime": 1689844499000
    },
    {
        "time": 1689844500000,
        "value": 139.501,
        "_internal_originalTime": 1689844500000
    },
    {
        "time": 1689844501000,
        "value": 139.48,
        "_internal_originalTime": 1689844501000
    },
    {
        "time": 1689844502000,
        "value": 139.498,
        "_internal_originalTime": 1689844502000
    },
    {
        "time": 1689844504000,
        "value": 139.5,
        "_internal_originalTime": 1689844504000
    },
    {
        "time": 1689844505000,
        "value": 139.501,
        "_internal_originalTime": 1689844505000
    },
    {
        "time": 1689844506000,
        "value": 139.5,
        "_internal_originalTime": 1689844506000
    },
    {
        "time": 1689844508000,
        "value": 139.499,
        "_internal_originalTime": 1689844508000
    },
    {
        "time": 1689844509000,
        "value": 139.495,
        "_internal_originalTime": 1689844509000
    },
    {
        "time": 1689844510000,
        "value": 139.502,
        "_internal_originalTime": 1689844510000
    },
    {
        "time": 1689844511000,
        "value": 139.502,
        "_internal_originalTime": 1689844511000
    },
    {
        "time": 1689844513000,
        "value": 139.502,
        "_internal_originalTime": 1689844513000
    },
    {
        "time": 1689844514000,
        "value": 139.503,
        "_internal_originalTime": 1689844514000
    },
    {
        "time": 1689844516000,
        "value": 139.504,
        "_internal_originalTime": 1689844516000
    },
    {
        "time": 1689844518000,
        "value": 139.51,
        "_internal_originalTime": 1689844518000
    },
    {
        "time": 1689844519000,
        "value": 139.509,
        "_internal_originalTime": 1689844519000
    },
    {
        "time": 1689844520000,
        "value": 139.509,
        "_internal_originalTime": 1689844520000
    },
    {
        "time": 1689844521000,
        "value": 139.504,
        "_internal_originalTime": 1689844521000
    },
    {
        "time": 1689844523000,
        "value": 139.507,
        "_internal_originalTime": 1689844523000
    },
    {
        "time": 1689844524000,
        "value": 139.507,
        "_internal_originalTime": 1689844524000
    },
    {
        "time": 1689844526000,
        "value": 139.506,
        "_internal_originalTime": 1689844526000
    },
    {
        "time": 1689844527000,
        "value": 139.507,
        "_internal_originalTime": 1689844527000
    },
    {
        "time": 1689844529000,
        "value": 139.512,
        "_internal_originalTime": 1689844529000
    },
    {
        "time": 1689844530000,
        "value": 139.512,
        "_internal_originalTime": 1689844530000
    },
    {
        "time": 1689844534000,
        "value": 139.51,
        "_internal_originalTime": 1689844534000
    },
    {
        "time": 1689844535000,
        "value": 139.51,
        "_internal_originalTime": 1689844535000
    },
    {
        "time": 1689844537000,
        "value": 139.506,
        "_internal_originalTime": 1689844537000
    },
    {
        "time": 1689844538000,
        "value": 139.502,
        "_internal_originalTime": 1689844538000
    },
    {
        "time": 1689844539000,
        "value": 139.503,
        "_internal_originalTime": 1689844539000
    },
    {
        "time": 1689844541000,
        "value": 139.505,
        "_internal_originalTime": 1689844541000
    },
    {
        "time": 1689844542000,
        "value": 139.508,
        "_internal_originalTime": 1689844542000
    },
    {
        "time": 1689844544000,
        "value": 139.503,
        "_internal_originalTime": 1689844544000
    },
    {
        "time": 1689844547000,
        "value": 139.505,
        "_internal_originalTime": 1689844547000
    },
    {
        "time": 1689844548000,
        "value": 139.506,
        "_internal_originalTime": 1689844548000
    },
    {
        "time": 1689844549000,
        "value": 139.506,
        "_internal_originalTime": 1689844549000
    },
    {
        "time": 1689844551000,
        "value": 139.507,
        "_internal_originalTime": 1689844551000
    },
    {
        "time": 1689844552000,
        "value": 139.506,
        "_internal_originalTime": 1689844552000
    },
    {
        "time": 1689844553000,
        "value": 139.503,
        "_internal_originalTime": 1689844553000
    },
    {
        "time": 1689844555000,
        "value": 139.506,
        "_internal_originalTime": 1689844555000
    },
    {
        "time": 1689844559000,
        "value": 139.506,
        "_internal_originalTime": 1689844559000
    },
    {
        "time": 1689844562000,
        "value": 139.503,
        "_internal_originalTime": 1689844562000
    },
    {
        "time": 1689844563000,
        "value": 139.502,
        "_internal_originalTime": 1689844563000
    },
    {
        "time": 1689844564000,
        "value": 139.503,
        "_internal_originalTime": 1689844564000
    },
    {
        "time": 1689844565000,
        "value": 139.502,
        "_internal_originalTime": 1689844565000
    },
    {
        "time": 1689844566000,
        "value": 139.506,
        "_internal_originalTime": 1689844566000
    },
    {
        "time": 1689844569000,
        "value": 139.504,
        "_internal_originalTime": 1689844569000
    },
    {
        "time": 1689844571000,
        "value": 139.511,
        "_internal_originalTime": 1689844571000
    },
    {
        "time": 1689844572000,
        "value": 139.513,
        "_internal_originalTime": 1689844572000
    },
    {
        "time": 1689844573000,
        "value": 139.514,
        "_internal_originalTime": 1689844573000
    },
    {
        "time": 1689844574000,
        "value": 139.508,
        "_internal_originalTime": 1689844574000
    },
    {
        "time": 1689844575000,
        "value": 139.51,
        "_internal_originalTime": 1689844575000
    },
    {
        "time": 1689844576000,
        "value": 139.516,
        "_internal_originalTime": 1689844576000
    },
    {
        "time": 1689844577000,
        "value": 139.51,
        "_internal_originalTime": 1689844577000
    },
    {
        "time": 1689844578000,
        "value": 139.506,
        "_internal_originalTime": 1689844578000
    },
    {
        "time": 1689844580000,
        "value": 139.509,
        "_internal_originalTime": 1689844580000
    },
    {
        "time": 1689844581000,
        "value": 139.504,
        "_internal_originalTime": 1689844581000
    },
    {
        "time": 1689844582000,
        "value": 139.502,
        "_internal_originalTime": 1689844582000
    },
    {
        "time": 1689844583000,
        "value": 139.503,
        "_internal_originalTime": 1689844583000
    },
    {
        "time": 1689844584000,
        "value": 139.502,
        "_internal_originalTime": 1689844584000
    },
    {
        "time": 1689844589000,
        "value": 139.502,
        "_internal_originalTime": 1689844589000
    },
    {
        "time": 1689844591000,
        "value": 139.503,
        "_internal_originalTime": 1689844591000
    },
    {
        "time": 1689844593000,
        "value": 139.505,
        "_internal_originalTime": 1689844593000
    },
    {
        "time": 1689844595000,
        "value": 139.503,
        "_internal_originalTime": 1689844595000
    },
    {
        "time": 1689844597000,
        "value": 139.506,
        "_internal_originalTime": 1689844597000
    },
    {
        "time": 1689844598000,
        "value": 139.51,
        "_internal_originalTime": 1689844598000
    },
    {
        "time": 1689844599000,
        "value": 139.505,
        "_internal_originalTime": 1689844599000
    },
    {
        "time": 1689844601000,
        "value": 139.51,
        "_internal_originalTime": 1689844601000
    },
    {
        "time": 1689844602000,
        "value": 139.51,
        "_internal_originalTime": 1689844602000
    },
    {
        "time": 1689844603000,
        "value": 139.512,
        "_internal_originalTime": 1689844603000
    },
    {
        "time": 1689844604000,
        "value": 139.511,
        "_internal_originalTime": 1689844604000
    },
    {
        "time": 1689844605000,
        "value": 139.515,
        "_internal_originalTime": 1689844605000
    },
    {
        "time": 1689844606000,
        "value": 139.508,
        "_internal_originalTime": 1689844606000
    },
    {
        "time": 1689844608000,
        "value": 139.513,
        "_internal_originalTime": 1689844608000
    },
    {
        "time": 1689844609000,
        "value": 139.511,
        "_internal_originalTime": 1689844609000
    },
    {
        "time": 1689844610000,
        "value": 139.511,
        "_internal_originalTime": 1689844610000
    },
    {
        "time": 1689844611000,
        "value": 139.511,
        "_internal_originalTime": 1689844611000
    },
    {
        "time": 1689844612000,
        "value": 139.508,
        "_internal_originalTime": 1689844612000
    },
    {
        "time": 1689844613000,
        "value": 139.514,
        "_internal_originalTime": 1689844613000
    },
    {
        "time": 1689844614000,
        "value": 139.513,
        "_internal_originalTime": 1689844614000
    },
    {
        "time": 1689844616000,
        "value": 139.516,
        "_internal_originalTime": 1689844616000
    },
    {
        "time": 1689844618000,
        "value": 139.512,
        "_internal_originalTime": 1689844618000
    },
    {
        "time": 1689844620000,
        "value": 139.512,
        "_internal_originalTime": 1689844620000
    },
    {
        "time": 1689844621000,
        "value": 139.506,
        "_internal_originalTime": 1689844621000
    },
    {
        "time": 1689844622000,
        "value": 139.5,
        "_internal_originalTime": 1689844622000
    },
    {
        "time": 1689844623000,
        "value": 139.501,
        "_internal_originalTime": 1689844623000
    },
    {
        "time": 1689844624000,
        "value": 139.501,
        "_internal_originalTime": 1689844624000
    },
    {
        "time": 1689844626000,
        "value": 139.498,
        "_internal_originalTime": 1689844626000
    },
    {
        "time": 1689844627000,
        "value": 139.498,
        "_internal_originalTime": 1689844627000
    },
    {
        "time": 1689844628000,
        "value": 139.497,
        "_internal_originalTime": 1689844628000
    },
    {
        "time": 1689844630000,
        "value": 139.5,
        "_internal_originalTime": 1689844630000
    },
    {
        "time": 1689844631000,
        "value": 139.5,
        "_internal_originalTime": 1689844631000
    },
    {
        "time": 1689844633000,
        "value": 139.497,
        "_internal_originalTime": 1689844633000
    },
    {
        "time": 1689844634000,
        "value": 139.496,
        "_internal_originalTime": 1689844634000
    },
    {
        "time": 1689844636000,
        "value": 139.497,
        "_internal_originalTime": 1689844636000
    },
    {
        "time": 1689844637000,
        "value": 139.501,
        "_internal_originalTime": 1689844637000
    },
    {
        "time": 1689844639000,
        "value": 139.502,
        "_internal_originalTime": 1689844639000
    },
    {
        "time": 1689844641000,
        "value": 139.501,
        "_internal_originalTime": 1689844641000
    },
    {
        "time": 1689844642000,
        "value": 139.498,
        "_internal_originalTime": 1689844642000
    },
    {
        "time": 1689844643000,
        "value": 139.502,
        "_internal_originalTime": 1689844643000
    },
    {
        "time": 1689844644000,
        "value": 139.502,
        "_internal_originalTime": 1689844644000
    },
    {
        "time": 1689844646000,
        "value": 139.502,
        "_internal_originalTime": 1689844646000
    },
    {
        "time": 1689844648000,
        "value": 139.502,
        "_internal_originalTime": 1689844648000
    },
    {
        "time": 1689844649000,
        "value": 139.503,
        "_internal_originalTime": 1689844649000
    },
    {
        "time": 1689844650000,
        "value": 139.501,
        "_internal_originalTime": 1689844650000
    },
    {
        "time": 1689844652000,
        "value": 139.502,
        "_internal_originalTime": 1689844652000
    },
    {
        "time": 1689844656000,
        "value": 139.498,
        "_internal_originalTime": 1689844656000
    },
    {
        "time": 1689844657000,
        "value": 139.502,
        "_internal_originalTime": 1689844657000
    },
    {
        "time": 1689844659000,
        "value": 139.505,
        "_internal_originalTime": 1689844659000
    },
    {
        "time": 1689844661000,
        "value": 139.503,
        "_internal_originalTime": 1689844661000
    },
    {
        "time": 1689844663000,
        "value": 139.502,
        "_internal_originalTime": 1689844663000
    },
    {
        "time": 1689844667000,
        "value": 139.506,
        "_internal_originalTime": 1689844667000
    },
    {
        "time": 1689844668000,
        "value": 139.501,
        "_internal_originalTime": 1689844668000
    },
    {
        "time": 1689844671000,
        "value": 139.498,
        "_internal_originalTime": 1689844671000
    },
    {
        "time": 1689844672000,
        "value": 139.498,
        "_internal_originalTime": 1689844672000
    },
    {
        "time": 1689844676000,
        "value": 139.497,
        "_internal_originalTime": 1689844676000
    },
    {
        "time": 1689844679000,
        "value": 139.498,
        "_internal_originalTime": 1689844679000
    },
    {
        "time": 1689844681000,
        "value": 139.5,
        "_internal_originalTime": 1689844681000
    },
    {
        "time": 1689844682000,
        "value": 139.497,
        "_internal_originalTime": 1689844682000
    },
    {
        "time": 1689844684000,
        "value": 139.501,
        "_internal_originalTime": 1689844684000
    },
    {
        "time": 1689844686000,
        "value": 139.49,
        "_internal_originalTime": 1689844686000
    },
    {
        "time": 1689844687000,
        "value": 139.487,
        "_internal_originalTime": 1689844687000
    },
    {
        "time": 1689844689000,
        "value": 139.485,
        "_internal_originalTime": 1689844689000
    },
    {
        "time": 1689844690000,
        "value": 139.488,
        "_internal_originalTime": 1689844690000
    },
    {
        "time": 1689844692000,
        "value": 139.488,
        "_internal_originalTime": 1689844692000
    },
    {
        "time": 1689844693000,
        "value": 139.488,
        "_internal_originalTime": 1689844693000
    },
    {
        "time": 1689844694000,
        "value": 139.487,
        "_internal_originalTime": 1689844694000
    },
    {
        "time": 1689844695000,
        "value": 139.491,
        "_internal_originalTime": 1689844695000
    },
    {
        "time": 1689844697000,
        "value": 139.486,
        "_internal_originalTime": 1689844697000
    },
    {
        "time": 1689844698000,
        "value": 139.481,
        "_internal_originalTime": 1689844698000
    },
    {
        "time": 1689844700000,
        "value": 139.478,
        "_internal_originalTime": 1689844700000
    },
    {
        "time": 1689844701000,
        "value": 139.477,
        "_internal_originalTime": 1689844701000
    },
    {
        "time": 1689844703000,
        "value": 139.466,
        "_internal_originalTime": 1689844703000
    },
    {
        "time": 1689844707000,
        "value": 139.46,
        "_internal_originalTime": 1689844707000
    },
    {
        "time": 1689844709000,
        "value": 139.467,
        "_internal_originalTime": 1689844709000
    },
    {
        "time": 1689844712000,
        "value": 139.469,
        "_internal_originalTime": 1689844712000
    },
    {
        "time": 1689844714000,
        "value": 139.461,
        "_internal_originalTime": 1689844714000
    },
    {
        "time": 1689844715000,
        "value": 139.46,
        "_internal_originalTime": 1689844715000
    },
    {
        "time": 1689844718000,
        "value": 139.458,
        "_internal_originalTime": 1689844718000
    },
    {
        "time": 1689844719000,
        "value": 139.451,
        "_internal_originalTime": 1689844719000
    },
    {
        "time": 1689844721000,
        "value": 139.454,
        "_internal_originalTime": 1689844721000
    },
    {
        "time": 1689844722000,
        "value": 139.463,
        "_internal_originalTime": 1689844722000
    },
    {
        "time": 1689844723000,
        "value": 139.462,
        "_internal_originalTime": 1689844723000
    },
    {
        "time": 1689844725000,
        "value": 139.458,
        "_internal_originalTime": 1689844725000
    },
    {
        "time": 1689844726000,
        "value": 139.467,
        "_internal_originalTime": 1689844726000
    },
    {
        "time": 1689844727000,
        "value": 139.46,
        "_internal_originalTime": 1689844727000
    },
    {
        "time": 1689844728000,
        "value": 139.46,
        "_internal_originalTime": 1689844728000
    },
    {
        "time": 1689844730000,
        "value": 139.465,
        "_internal_originalTime": 1689844730000
    },
    {
        "time": 1689844731000,
        "value": 139.458,
        "_internal_originalTime": 1689844731000
    },
    {
        "time": 1689844733000,
        "value": 139.465,
        "_internal_originalTime": 1689844733000
    },
    {
        "time": 1689844734000,
        "value": 139.469,
        "_internal_originalTime": 1689844734000
    },
    {
        "time": 1689844735000,
        "value": 139.462,
        "_internal_originalTime": 1689844735000
    },
    {
        "time": 1689844736000,
        "value": 139.461,
        "_internal_originalTime": 1689844736000
    },
    {
        "time": 1689844737000,
        "value": 139.46,
        "_internal_originalTime": 1689844737000
    },
    {
        "time": 1689844738000,
        "value": 139.461,
        "_internal_originalTime": 1689844738000
    },
    {
        "time": 1689844740000,
        "value": 139.462,
        "_internal_originalTime": 1689844740000
    },
    {
        "time": 1689844741000,
        "value": 139.462,
        "_internal_originalTime": 1689844741000
    },
    {
        "time": 1689844743000,
        "value": 139.462,
        "_internal_originalTime": 1689844743000
    },
    {
        "time": 1689844744000,
        "value": 139.462,
        "_internal_originalTime": 1689844744000
    },
    {
        "time": 1689844745000,
        "value": 139.45,
        "_internal_originalTime": 1689844745000
    },
    {
        "time": 1689844746000,
        "value": 139.445,
        "_internal_originalTime": 1689844746000
    },
    {
        "time": 1689844747000,
        "value": 139.444,
        "_internal_originalTime": 1689844747000
    },
    {
        "time": 1689844748000,
        "value": 139.44,
        "_internal_originalTime": 1689844748000
    },
    {
        "time": 1689844750000,
        "value": 139.443,
        "_internal_originalTime": 1689844750000
    },
    {
        "time": 1689844751000,
        "value": 139.438,
        "_internal_originalTime": 1689844751000
    },
    {
        "time": 1689844753000,
        "value": 139.443,
        "_internal_originalTime": 1689844753000
    },
    {
        "time": 1689844755000,
        "value": 139.446,
        "_internal_originalTime": 1689844755000
    },
    {
        "time": 1689844756000,
        "value": 139.442,
        "_internal_originalTime": 1689844756000
    },
    {
        "time": 1689844758000,
        "value": 139.44,
        "_internal_originalTime": 1689844758000
    },
    {
        "time": 1689844759000,
        "value": 139.44,
        "_internal_originalTime": 1689844759000
    },
    {
        "time": 1689844761000,
        "value": 139.446,
        "_internal_originalTime": 1689844761000
    },
    {
        "time": 1689844762000,
        "value": 139.446,
        "_internal_originalTime": 1689844762000
    },
    {
        "time": 1689844764000,
        "value": 139.445,
        "_internal_originalTime": 1689844764000
    },
    {
        "time": 1689844765000,
        "value": 139.443,
        "_internal_originalTime": 1689844765000
    },
    {
        "time": 1689844767000,
        "value": 139.44,
        "_internal_originalTime": 1689844767000
    },
    {
        "time": 1689844768000,
        "value": 139.447,
        "_internal_originalTime": 1689844768000
    },
    {
        "time": 1689844770000,
        "value": 139.44,
        "_internal_originalTime": 1689844770000
    },
    {
        "time": 1689844771000,
        "value": 139.442,
        "_internal_originalTime": 1689844771000
    },
    {
        "time": 1689844772000,
        "value": 139.442,
        "_internal_originalTime": 1689844772000
    },
    {
        "time": 1689844774000,
        "value": 139.442,
        "_internal_originalTime": 1689844774000
    },
    {
        "time": 1689844775000,
        "value": 139.436,
        "_internal_originalTime": 1689844775000
    },
    {
        "time": 1689844777000,
        "value": 139.443,
        "_internal_originalTime": 1689844777000
    },
    {
        "time": 1689844778000,
        "value": 139.436,
        "_internal_originalTime": 1689844778000
    },
    {
        "time": 1689844779000,
        "value": 139.436,
        "_internal_originalTime": 1689844779000
    },
    {
        "time": 1689844781000,
        "value": 139.442,
        "_internal_originalTime": 1689844781000
    },
    {
        "time": 1689844783000,
        "value": 139.437,
        "_internal_originalTime": 1689844783000
    },
    {
        "time": 1689844785000,
        "value": 139.436,
        "_internal_originalTime": 1689844785000
    },
    {
        "time": 1689844786000,
        "value": 139.444,
        "_internal_originalTime": 1689844786000
    },
    {
        "time": 1689844787000,
        "value": 139.444,
        "_internal_originalTime": 1689844787000
    },
    {
        "time": 1689844788000,
        "value": 139.44,
        "_internal_originalTime": 1689844788000
    },
    {
        "time": 1689844789000,
        "value": 139.447,
        "_internal_originalTime": 1689844789000
    },
    {
        "time": 1689844790000,
        "value": 139.44,
        "_internal_originalTime": 1689844790000
    },
    {
        "time": 1689844792000,
        "value": 139.446,
        "_internal_originalTime": 1689844792000
    },
    {
        "time": 1689844793000,
        "value": 139.441,
        "_internal_originalTime": 1689844793000
    },
    {
        "time": 1689844794000,
        "value": 139.439,
        "_internal_originalTime": 1689844794000
    },
    {
        "time": 1689844795000,
        "value": 139.444,
        "_internal_originalTime": 1689844795000
    },
    {
        "time": 1689844797000,
        "value": 139.439,
        "_internal_originalTime": 1689844797000
    },
    {
        "time": 1689844798000,
        "value": 139.44,
        "_internal_originalTime": 1689844798000
    },
    {
        "time": 1689844799000,
        "value": 139.441,
        "_internal_originalTime": 1689844799000
    },
    {
        "time": 1689844800000,
        "value": 139.437,
        "_internal_originalTime": 1689844800000
    },
    {
        "time": 1689844801000,
        "value": 139.43,
        "_internal_originalTime": 1689844801000
    },
    {
        "time": 1689844802000,
        "value": 139.436,
        "_internal_originalTime": 1689844802000
    },
    {
        "time": 1689844804000,
        "value": 139.431,
        "_internal_originalTime": 1689844804000
    },
    {
        "time": 1689844805000,
        "value": 139.428,
        "_internal_originalTime": 1689844805000
    },
    {
        "time": 1689844806000,
        "value": 139.433,
        "_internal_originalTime": 1689844806000
    },
    {
        "time": 1689844807000,
        "value": 139.433,
        "_internal_originalTime": 1689844807000
    },
    {
        "time": 1689844809000,
        "value": 139.43,
        "_internal_originalTime": 1689844809000
    },
    {
        "time": 1689844810000,
        "value": 139.427,
        "_internal_originalTime": 1689844810000
    },
    {
        "time": 1689844811000,
        "value": 139.428,
        "_internal_originalTime": 1689844811000
    },
    {
        "time": 1689844812000,
        "value": 139.427,
        "_internal_originalTime": 1689844812000
    },
    {
        "time": 1689844814000,
        "value": 139.435,
        "_internal_originalTime": 1689844814000
    },
    {
        "time": 1689844815000,
        "value": 139.431,
        "_internal_originalTime": 1689844815000
    },
    {
        "time": 1689844816000,
        "value": 139.437,
        "_internal_originalTime": 1689844816000
    },
    {
        "time": 1689844817000,
        "value": 139.432,
        "_internal_originalTime": 1689844817000
    },
    {
        "time": 1689844818000,
        "value": 139.439,
        "_internal_originalTime": 1689844818000
    },
    {
        "time": 1689844819000,
        "value": 139.446,
        "_internal_originalTime": 1689844819000
    },
    {
        "time": 1689844820000,
        "value": 139.431,
        "_internal_originalTime": 1689844820000
    },
    {
        "time": 1689844821000,
        "value": 139.43,
        "_internal_originalTime": 1689844821000
    },
    {
        "time": 1689844822000,
        "value": 139.431,
        "_internal_originalTime": 1689844822000
    },
    {
        "time": 1689844824000,
        "value": 139.428,
        "_internal_originalTime": 1689844824000
    },
    {
        "time": 1689844826000,
        "value": 139.433,
        "_internal_originalTime": 1689844826000
    },
    {
        "time": 1689844827000,
        "value": 139.432,
        "_internal_originalTime": 1689844827000
    },
    {
        "time": 1689844829000,
        "value": 139.437,
        "_internal_originalTime": 1689844829000
    },
    {
        "time": 1689844830000,
        "value": 139.437,
        "_internal_originalTime": 1689844830000
    },
    {
        "time": 1689844831000,
        "value": 139.436,
        "_internal_originalTime": 1689844831000
    },
    {
        "time": 1689844833000,
        "value": 139.431,
        "_internal_originalTime": 1689844833000
    },
    {
        "time": 1689844834000,
        "value": 139.429,
        "_internal_originalTime": 1689844834000
    },
    {
        "time": 1689844835000,
        "value": 139.43,
        "_internal_originalTime": 1689844835000
    },
    {
        "time": 1689844836000,
        "value": 139.431,
        "_internal_originalTime": 1689844836000
    },
    {
        "time": 1689844837000,
        "value": 139.431,
        "_internal_originalTime": 1689844837000
    },
    {
        "time": 1689844838000,
        "value": 139.428,
        "_internal_originalTime": 1689844838000
    },
    {
        "time": 1689844840000,
        "value": 139.429,
        "_internal_originalTime": 1689844840000
    },
    {
        "time": 1689844841000,
        "value": 139.436,
        "_internal_originalTime": 1689844841000
    },
    {
        "time": 1689844843000,
        "value": 139.434,
        "_internal_originalTime": 1689844843000
    },
    {
        "time": 1689844844000,
        "value": 139.44,
        "_internal_originalTime": 1689844844000
    },
    {
        "time": 1689844845000,
        "value": 139.441,
        "_internal_originalTime": 1689844845000
    },
    {
        "time": 1689844847000,
        "value": 139.443,
        "_internal_originalTime": 1689844847000
    },
    {
        "time": 1689844848000,
        "value": 139.444,
        "_internal_originalTime": 1689844848000
    },
    {
        "time": 1689844849000,
        "value": 139.447,
        "_internal_originalTime": 1689844849000
    },
    {
        "time": 1689844850000,
        "value": 139.451,
        "_internal_originalTime": 1689844850000
    },
    {
        "time": 1689844851000,
        "value": 139.452,
        "_internal_originalTime": 1689844851000
    },
    {
        "time": 1689844853000,
        "value": 139.454,
        "_internal_originalTime": 1689844853000
    },
    {
        "time": 1689844854000,
        "value": 139.455,
        "_internal_originalTime": 1689844854000
    },
    {
        "time": 1689844855000,
        "value": 139.456,
        "_internal_originalTime": 1689844855000
    },
    {
        "time": 1689844856000,
        "value": 139.455,
        "_internal_originalTime": 1689844856000
    },
    {
        "time": 1689844858000,
        "value": 139.454,
        "_internal_originalTime": 1689844858000
    },
    {
        "time": 1689844859000,
        "value": 139.453,
        "_internal_originalTime": 1689844859000
    },
    {
        "time": 1689844860000,
        "value": 139.43,
        "_internal_originalTime": 1689844860000
    },
    {
        "time": 1689844861000,
        "value": 139.461,
        "_internal_originalTime": 1689844861000
    },
    {
        "time": 1689844862000,
        "value": 139.461,
        "_internal_originalTime": 1689844862000
    },
    {
        "time": 1689844863000,
        "value": 139.461,
        "_internal_originalTime": 1689844863000
    },
    {
        "time": 1689844866000,
        "value": 139.461,
        "_internal_originalTime": 1689844866000
    },
    {
        "time": 1689844867000,
        "value": 139.462,
        "_internal_originalTime": 1689844867000
    },
    {
        "time": 1689844869000,
        "value": 139.46,
        "_internal_originalTime": 1689844869000
    },
    {
        "time": 1689844870000,
        "value": 139.461,
        "_internal_originalTime": 1689844870000
    },
    {
        "time": 1689844871000,
        "value": 139.462,
        "_internal_originalTime": 1689844871000
    },
    {
        "time": 1689844873000,
        "value": 139.463,
        "_internal_originalTime": 1689844873000
    },
    {
        "time": 1689844874000,
        "value": 139.462,
        "_internal_originalTime": 1689844874000
    },
    {
        "time": 1689844875000,
        "value": 139.46,
        "_internal_originalTime": 1689844875000
    },
    {
        "time": 1689844876000,
        "value": 139.463,
        "_internal_originalTime": 1689844876000
    },
    {
        "time": 1689844878000,
        "value": 139.462,
        "_internal_originalTime": 1689844878000
    },
    {
        "time": 1689844879000,
        "value": 139.464,
        "_internal_originalTime": 1689844879000
    },
    {
        "time": 1689844880000,
        "value": 139.465,
        "_internal_originalTime": 1689844880000
    },
    {
        "time": 1689844881000,
        "value": 139.461,
        "_internal_originalTime": 1689844881000
    },
    {
        "time": 1689844882000,
        "value": 139.463,
        "_internal_originalTime": 1689844882000
    },
    {
        "time": 1689844883000,
        "value": 139.461,
        "_internal_originalTime": 1689844883000
    },
    {
        "time": 1689844885000,
        "value": 139.462,
        "_internal_originalTime": 1689844885000
    },
    {
        "time": 1689844886000,
        "value": 139.465,
        "_internal_originalTime": 1689844886000
    },
    {
        "time": 1689844888000,
        "value": 139.462,
        "_internal_originalTime": 1689844888000
    },
    {
        "time": 1689844890000,
        "value": 139.461,
        "_internal_originalTime": 1689844890000
    },
    {
        "time": 1689844891000,
        "value": 139.461,
        "_internal_originalTime": 1689844891000
    },
    {
        "time": 1689844892000,
        "value": 139.46,
        "_internal_originalTime": 1689844892000
    },
    {
        "time": 1689844894000,
        "value": 139.463,
        "_internal_originalTime": 1689844894000
    },
    {
        "time": 1689844895000,
        "value": 139.464,
        "_internal_originalTime": 1689844895000
    },
    {
        "time": 1689844896000,
        "value": 139.464,
        "_internal_originalTime": 1689844896000
    },
    {
        "time": 1689844898000,
        "value": 139.461,
        "_internal_originalTime": 1689844898000
    },
    {
        "time": 1689844899000,
        "value": 139.459,
        "_internal_originalTime": 1689844899000
    },
    {
        "time": 1689844900000,
        "value": 139.464,
        "_internal_originalTime": 1689844900000
    },
    {
        "time": 1689844901000,
        "value": 139.466,
        "_internal_originalTime": 1689844901000
    },
    {
        "time": 1689844903000,
        "value": 139.463,
        "_internal_originalTime": 1689844903000
    },
    {
        "time": 1689844904000,
        "value": 139.465,
        "_internal_originalTime": 1689844904000
    },
    {
        "time": 1689844905000,
        "value": 139.465,
        "_internal_originalTime": 1689844905000
    },
    {
        "time": 1689844906000,
        "value": 139.465,
        "_internal_originalTime": 1689844906000
    },
    {
        "time": 1689844908000,
        "value": 139.464,
        "_internal_originalTime": 1689844908000
    },
    {
        "time": 1689844909000,
        "value": 139.464,
        "_internal_originalTime": 1689844909000
    },
    {
        "time": 1689844910000,
        "value": 139.462,
        "_internal_originalTime": 1689844910000
    },
    {
        "time": 1689844911000,
        "value": 139.464,
        "_internal_originalTime": 1689844911000
    },
    {
        "time": 1689844912000,
        "value": 139.466,
        "_internal_originalTime": 1689844912000
    },
    {
        "time": 1689844915000,
        "value": 139.468,
        "_internal_originalTime": 1689844915000
    },
    {
        "time": 1689844916000,
        "value": 139.462,
        "_internal_originalTime": 1689844916000
    },
    {
        "time": 1689844917000,
        "value": 139.463,
        "_internal_originalTime": 1689844917000
    },
    {
        "time": 1689844920000,
        "value": 139.466,
        "_internal_originalTime": 1689844920000
    },
    {
        "time": 1689844921000,
        "value": 139.462,
        "_internal_originalTime": 1689844921000
    },
    {
        "time": 1689844923000,
        "value": 139.449,
        "_internal_originalTime": 1689844923000
    },
    {
        "time": 1689844924000,
        "value": 139.455,
        "_internal_originalTime": 1689844924000
    },
    {
        "time": 1689844925000,
        "value": 139.452,
        "_internal_originalTime": 1689844925000
    },
    {
        "time": 1689844926000,
        "value": 139.461,
        "_internal_originalTime": 1689844926000
    },
    {
        "time": 1689844928000,
        "value": 139.463,
        "_internal_originalTime": 1689844928000
    },
    {
        "time": 1689844929000,
        "value": 139.46,
        "_internal_originalTime": 1689844929000
    },
    {
        "time": 1689844931000,
        "value": 139.462,
        "_internal_originalTime": 1689844931000
    },
    {
        "time": 1689844932000,
        "value": 139.463,
        "_internal_originalTime": 1689844932000
    },
    {
        "time": 1689844933000,
        "value": 139.457,
        "_internal_originalTime": 1689844933000
    },
    {
        "time": 1689844934000,
        "value": 139.45,
        "_internal_originalTime": 1689844934000
    },
    {
        "time": 1689844938000,
        "value": 139.452,
        "_internal_originalTime": 1689844938000
    },
    {
        "time": 1689844939000,
        "value": 139.448,
        "_internal_originalTime": 1689844939000
    },
    {
        "time": 1689844940000,
        "value": 139.442,
        "_internal_originalTime": 1689844940000
    },
    {
        "time": 1689844942000,
        "value": 139.441,
        "_internal_originalTime": 1689844942000
    },
    {
        "time": 1689844943000,
        "value": 139.442,
        "_internal_originalTime": 1689844943000
    },
    {
        "time": 1689844944000,
        "value": 139.447,
        "_internal_originalTime": 1689844944000
    },
    {
        "time": 1689844947000,
        "value": 139.447,
        "_internal_originalTime": 1689844947000
    },
    {
        "time": 1689844948000,
        "value": 139.447,
        "_internal_originalTime": 1689844948000
    },
    {
        "time": 1689844949000,
        "value": 139.448,
        "_internal_originalTime": 1689844949000
    },
    {
        "time": 1689844950000,
        "value": 139.443,
        "_internal_originalTime": 1689844950000
    },
    {
        "time": 1689844952000,
        "value": 139.45,
        "_internal_originalTime": 1689844952000
    },
    {
        "time": 1689844953000,
        "value": 139.444,
        "_internal_originalTime": 1689844953000
    },
    {
        "time": 1689844954000,
        "value": 139.443,
        "_internal_originalTime": 1689844954000
    },
    {
        "time": 1689844955000,
        "value": 139.449,
        "_internal_originalTime": 1689844955000
    },
    {
        "time": 1689844956000,
        "value": 139.446,
        "_internal_originalTime": 1689844956000
    },
    {
        "time": 1689844958000,
        "value": 139.44,
        "_internal_originalTime": 1689844958000
    },
    {
        "time": 1689844960000,
        "value": 139.445,
        "_internal_originalTime": 1689844960000
    },
    {
        "time": 1689844961000,
        "value": 139.447,
        "_internal_originalTime": 1689844961000
    },
    {
        "time": 1689844962000,
        "value": 139.449,
        "_internal_originalTime": 1689844962000
    },
    {
        "time": 1689844963000,
        "value": 139.45,
        "_internal_originalTime": 1689844963000
    },
    {
        "time": 1689844966000,
        "value": 139.451,
        "_internal_originalTime": 1689844966000
    },
    {
        "time": 1689844968000,
        "value": 139.455,
        "_internal_originalTime": 1689844968000
    },
    {
        "time": 1689844970000,
        "value": 139.453,
        "_internal_originalTime": 1689844970000
    },
    {
        "time": 1689844971000,
        "value": 139.452,
        "_internal_originalTime": 1689844971000
    },
    {
        "time": 1689844972000,
        "value": 139.455,
        "_internal_originalTime": 1689844972000
    },
    {
        "time": 1689844974000,
        "value": 139.46,
        "_internal_originalTime": 1689844974000
    },
    {
        "time": 1689844975000,
        "value": 139.458,
        "_internal_originalTime": 1689844975000
    },
    {
        "time": 1689844976000,
        "value": 139.46,
        "_internal_originalTime": 1689844976000
    },
    {
        "time": 1689844978000,
        "value": 139.457,
        "_internal_originalTime": 1689844978000
    },
    {
        "time": 1689844979000,
        "value": 139.462,
        "_internal_originalTime": 1689844979000
    },
    {
        "time": 1689844980000,
        "value": 139.467,
        "_internal_originalTime": 1689844980000
    },
    {
        "time": 1689844981000,
        "value": 139.468,
        "_internal_originalTime": 1689844981000
    },
    {
        "time": 1689844982000,
        "value": 139.468,
        "_internal_originalTime": 1689844982000
    },
    {
        "time": 1689844983000,
        "value": 139.47,
        "_internal_originalTime": 1689844983000
    },
    {
        "time": 1689844984000,
        "value": 139.47,
        "_internal_originalTime": 1689844984000
    },
    {
        "time": 1689844985000,
        "value": 139.469,
        "_internal_originalTime": 1689844985000
    },
    {
        "time": 1689844986000,
        "value": 139.468,
        "_internal_originalTime": 1689844986000
    },
    {
        "time": 1689844988000,
        "value": 139.468,
        "_internal_originalTime": 1689844988000
    },
    {
        "time": 1689844989000,
        "value": 139.465,
        "_internal_originalTime": 1689844989000
    },
    {
        "time": 1689844991000,
        "value": 139.472,
        "_internal_originalTime": 1689844991000
    },
    {
        "time": 1689844992000,
        "value": 139.468,
        "_internal_originalTime": 1689844992000
    },
    {
        "time": 1689844993000,
        "value": 139.471,
        "_internal_originalTime": 1689844993000
    },
    {
        "time": 1689844994000,
        "value": 139.468,
        "_internal_originalTime": 1689844994000
    },
    {
        "time": 1689844996000,
        "value": 139.463,
        "_internal_originalTime": 1689844996000
    },
    {
        "time": 1689844997000,
        "value": 139.463,
        "_internal_originalTime": 1689844997000
    },
    {
        "time": 1689845003000,
        "value": 139.469,
        "_internal_originalTime": 1689845003000
    },
    {
        "time": 1689845004000,
        "value": 139.473,
        "_internal_originalTime": 1689845004000
    },
    {
        "time": 1689845006000,
        "value": 139.475,
        "_internal_originalTime": 1689845006000
    },
    {
        "time": 1689845007000,
        "value": 139.475,
        "_internal_originalTime": 1689845007000
    },
    {
        "time": 1689845013000,
        "value": 139.47,
        "_internal_originalTime": 1689845013000
    },
    {
        "time": 1689845014000,
        "value": 139.476,
        "_internal_originalTime": 1689845014000
    },
    {
        "time": 1689845015000,
        "value": 139.471,
        "_internal_originalTime": 1689845015000
    },
    {
        "time": 1689845016000,
        "value": 139.472,
        "_internal_originalTime": 1689845016000
    },
    {
        "time": 1689845019000,
        "value": 139.472,
        "_internal_originalTime": 1689845019000
    },
    {
        "time": 1689845021000,
        "value": 139.477,
        "_internal_originalTime": 1689845021000
    },
    {
        "time": 1689845023000,
        "value": 139.474,
        "_internal_originalTime": 1689845023000
    },
    {
        "time": 1689845024000,
        "value": 139.474,
        "_internal_originalTime": 1689845024000
    },
    {
        "time": 1689845026000,
        "value": 139.481,
        "_internal_originalTime": 1689845026000
    },
    {
        "time": 1689845027000,
        "value": 139.482,
        "_internal_originalTime": 1689845027000
    },
    {
        "time": 1689845028000,
        "value": 139.482,
        "_internal_originalTime": 1689845028000
    },
    {
        "time": 1689845030000,
        "value": 139.481,
        "_internal_originalTime": 1689845030000
    },
    {
        "time": 1689845031000,
        "value": 139.476,
        "_internal_originalTime": 1689845031000
    },
    {
        "time": 1689845033000,
        "value": 139.476,
        "_internal_originalTime": 1689845033000
    },
    {
        "time": 1689845035000,
        "value": 139.476,
        "_internal_originalTime": 1689845035000
    },
    {
        "time": 1689845037000,
        "value": 139.478,
        "_internal_originalTime": 1689845037000
    },
    {
        "time": 1689845038000,
        "value": 139.483,
        "_internal_originalTime": 1689845038000
    },
    {
        "time": 1689845039000,
        "value": 139.482,
        "_internal_originalTime": 1689845039000
    },
    {
        "time": 1689845040000,
        "value": 139.478,
        "_internal_originalTime": 1689845040000
    },
    {
        "time": 1689845041000,
        "value": 139.478,
        "_internal_originalTime": 1689845041000
    },
    {
        "time": 1689845042000,
        "value": 139.482,
        "_internal_originalTime": 1689845042000
    },
    {
        "time": 1689845043000,
        "value": 139.478,
        "_internal_originalTime": 1689845043000
    },
    {
        "time": 1689845045000,
        "value": 139.478,
        "_internal_originalTime": 1689845045000
    },
    {
        "time": 1689845046000,
        "value": 139.478,
        "_internal_originalTime": 1689845046000
    },
    {
        "time": 1689845048000,
        "value": 139.474,
        "_internal_originalTime": 1689845048000
    },
    {
        "time": 1689845050000,
        "value": 139.462,
        "_internal_originalTime": 1689845050000
    },
    {
        "time": 1689845051000,
        "value": 139.462,
        "_internal_originalTime": 1689845051000
    },
    {
        "time": 1689845052000,
        "value": 139.463,
        "_internal_originalTime": 1689845052000
    },
    {
        "time": 1689845054000,
        "value": 139.47,
        "_internal_originalTime": 1689845054000
    },
    {
        "time": 1689845055000,
        "value": 139.472,
        "_internal_originalTime": 1689845055000
    },
    {
        "time": 1689845056000,
        "value": 139.464,
        "_internal_originalTime": 1689845056000
    },
    {
        "time": 1689845057000,
        "value": 139.468,
        "_internal_originalTime": 1689845057000
    },
    {
        "time": 1689845058000,
        "value": 139.467,
        "_internal_originalTime": 1689845058000
    },
    {
        "time": 1689845059000,
        "value": 139.467,
        "_internal_originalTime": 1689845059000
    },
    {
        "time": 1689845063000,
        "value": 139.467,
        "_internal_originalTime": 1689845063000
    },
    {
        "time": 1689845065000,
        "value": 139.457,
        "_internal_originalTime": 1689845065000
    },
    {
        "time": 1689845066000,
        "value": 139.457,
        "_internal_originalTime": 1689845066000
    },
    {
        "time": 1689845071000,
        "value": 139.464,
        "_internal_originalTime": 1689845071000
    },
    {
        "time": 1689845072000,
        "value": 139.466,
        "_internal_originalTime": 1689845072000
    },
    {
        "time": 1689845076000,
        "value": 139.462,
        "_internal_originalTime": 1689845076000
    },
    {
        "time": 1689845077000,
        "value": 139.461,
        "_internal_originalTime": 1689845077000
    },
    {
        "time": 1689845078000,
        "value": 139.461,
        "_internal_originalTime": 1689845078000
    },
    {
        "time": 1689845080000,
        "value": 139.462,
        "_internal_originalTime": 1689845080000
    },
    {
        "time": 1689845081000,
        "value": 139.463,
        "_internal_originalTime": 1689845081000
    },
    {
        "time": 1689845084000,
        "value": 139.463,
        "_internal_originalTime": 1689845084000
    },
    {
        "time": 1689845085000,
        "value": 139.467,
        "_internal_originalTime": 1689845085000
    },
    {
        "time": 1689845086000,
        "value": 139.462,
        "_internal_originalTime": 1689845086000
    },
    {
        "time": 1689845087000,
        "value": 139.464,
        "_internal_originalTime": 1689845087000
    },
    {
        "time": 1689845088000,
        "value": 139.468,
        "_internal_originalTime": 1689845088000
    },
    {
        "time": 1689845090000,
        "value": 139.467,
        "_internal_originalTime": 1689845090000
    },
    {
        "time": 1689845091000,
        "value": 139.467,
        "_internal_originalTime": 1689845091000
    },
    {
        "time": 1689845092000,
        "value": 139.471,
        "_internal_originalTime": 1689845092000
    },
    {
        "time": 1689845094000,
        "value": 139.469,
        "_internal_originalTime": 1689845094000
    },
    {
        "time": 1689845095000,
        "value": 139.47,
        "_internal_originalTime": 1689845095000
    },
    {
        "time": 1689845096000,
        "value": 139.47,
        "_internal_originalTime": 1689845096000
    },
    {
        "time": 1689845098000,
        "value": 139.468,
        "_internal_originalTime": 1689845098000
    },
    {
        "time": 1689845100000,
        "value": 139.479,
        "_internal_originalTime": 1689845100000
    },
    {
        "time": 1689845101000,
        "value": 139.479,
        "_internal_originalTime": 1689845101000
    },
    {
        "time": 1689845102000,
        "value": 139.472,
        "_internal_originalTime": 1689845102000
    },
    {
        "time": 1689845104000,
        "value": 139.472,
        "_internal_originalTime": 1689845104000
    },
    {
        "time": 1689845105000,
        "value": 139.476,
        "_internal_originalTime": 1689845105000
    },
    {
        "time": 1689845106000,
        "value": 139.472,
        "_internal_originalTime": 1689845106000
    },
    {
        "time": 1689845108000,
        "value": 139.467,
        "_internal_originalTime": 1689845108000
    },
    {
        "time": 1689845109000,
        "value": 139.475,
        "_internal_originalTime": 1689845109000
    },
    {
        "time": 1689845111000,
        "value": 139.472,
        "_internal_originalTime": 1689845111000
    },
    {
        "time": 1689845112000,
        "value": 139.478,
        "_internal_originalTime": 1689845112000
    },
    {
        "time": 1689845113000,
        "value": 139.477,
        "_internal_originalTime": 1689845113000
    },
    {
        "time": 1689845115000,
        "value": 139.473,
        "_internal_originalTime": 1689845115000
    },
    {
        "time": 1689845118000,
        "value": 139.474,
        "_internal_originalTime": 1689845118000
    },
    {
        "time": 1689845119000,
        "value": 139.477,
        "_internal_originalTime": 1689845119000
    },
    {
        "time": 1689845120000,
        "value": 139.476,
        "_internal_originalTime": 1689845120000
    },
    {
        "time": 1689845122000,
        "value": 139.467,
        "_internal_originalTime": 1689845122000
    },
    {
        "time": 1689845123000,
        "value": 139.476,
        "_internal_originalTime": 1689845123000
    },
    {
        "time": 1689845125000,
        "value": 139.477,
        "_internal_originalTime": 1689845125000
    },
    {
        "time": 1689845126000,
        "value": 139.47,
        "_internal_originalTime": 1689845126000
    },
    {
        "time": 1689845127000,
        "value": 139.474,
        "_internal_originalTime": 1689845127000
    },
    {
        "time": 1689845129000,
        "value": 139.474,
        "_internal_originalTime": 1689845129000
    },
    {
        "time": 1689845130000,
        "value": 139.473,
        "_internal_originalTime": 1689845130000
    },
    {
        "time": 1689845131000,
        "value": 139.472,
        "_internal_originalTime": 1689845131000
    },
    {
        "time": 1689845134000,
        "value": 139.475,
        "_internal_originalTime": 1689845134000
    },
    {
        "time": 1689845135000,
        "value": 139.472,
        "_internal_originalTime": 1689845135000
    },
    {
        "time": 1689845137000,
        "value": 139.476,
        "_internal_originalTime": 1689845137000
    },
    {
        "time": 1689845139000,
        "value": 139.47,
        "_internal_originalTime": 1689845139000
    },
    {
        "time": 1689845141000,
        "value": 139.474,
        "_internal_originalTime": 1689845141000
    },
    {
        "time": 1689845143000,
        "value": 139.478,
        "_internal_originalTime": 1689845143000
    },
    {
        "time": 1689845145000,
        "value": 139.478,
        "_internal_originalTime": 1689845145000
    },
    {
        "time": 1689845147000,
        "value": 139.476,
        "_internal_originalTime": 1689845147000
    },
    {
        "time": 1689845148000,
        "value": 139.474,
        "_internal_originalTime": 1689845148000
    },
    {
        "time": 1689845150000,
        "value": 139.461,
        "_internal_originalTime": 1689845150000
    },
    {
        "time": 1689845151000,
        "value": 139.461,
        "_internal_originalTime": 1689845151000
    },
    {
        "time": 1689845153000,
        "value": 139.453,
        "_internal_originalTime": 1689845153000
    },
    {
        "time": 1689845154000,
        "value": 139.447,
        "_internal_originalTime": 1689845154000
    },
    {
        "time": 1689845155000,
        "value": 139.456,
        "_internal_originalTime": 1689845155000
    },
    {
        "time": 1689845157000,
        "value": 139.458,
        "_internal_originalTime": 1689845157000
    },
    {
        "time": 1689845158000,
        "value": 139.458,
        "_internal_originalTime": 1689845158000
    },
    {
        "time": 1689845159000,
        "value": 139.457,
        "_internal_originalTime": 1689845159000
    },
    {
        "time": 1689845161000,
        "value": 139.456,
        "_internal_originalTime": 1689845161000
    },
    {
        "time": 1689845162000,
        "value": 139.453,
        "_internal_originalTime": 1689845162000
    },
    {
        "time": 1689845164000,
        "value": 139.452,
        "_internal_originalTime": 1689845164000
    },
    {
        "time": 1689845165000,
        "value": 139.452,
        "_internal_originalTime": 1689845165000
    },
    {
        "time": 1689845166000,
        "value": 139.452,
        "_internal_originalTime": 1689845166000
    },
    {
        "time": 1689845167000,
        "value": 139.453,
        "_internal_originalTime": 1689845167000
    },
    {
        "time": 1689845168000,
        "value": 139.456,
        "_internal_originalTime": 1689845168000
    },
    {
        "time": 1689845169000,
        "value": 139.457,
        "_internal_originalTime": 1689845169000
    },
    {
        "time": 1689845170000,
        "value": 139.451,
        "_internal_originalTime": 1689845170000
    },
    {
        "time": 1689845171000,
        "value": 139.453,
        "_internal_originalTime": 1689845171000
    },
    {
        "time": 1689845173000,
        "value": 139.451,
        "_internal_originalTime": 1689845173000
    },
    {
        "time": 1689845175000,
        "value": 139.449,
        "_internal_originalTime": 1689845175000
    },
    {
        "time": 1689845176000,
        "value": 139.452,
        "_internal_originalTime": 1689845176000
    },
    {
        "time": 1689845178000,
        "value": 139.448,
        "_internal_originalTime": 1689845178000
    },
    {
        "time": 1689845179000,
        "value": 139.442,
        "_internal_originalTime": 1689845179000
    },
    {
        "time": 1689845180000,
        "value": 139.446,
        "_internal_originalTime": 1689845180000
    },
    {
        "time": 1689845181000,
        "value": 139.442,
        "_internal_originalTime": 1689845181000
    },
    {
        "time": 1689845184000,
        "value": 139.444,
        "_internal_originalTime": 1689845184000
    },
    {
        "time": 1689845185000,
        "value": 139.446,
        "_internal_originalTime": 1689845185000
    },
    {
        "time": 1689845188000,
        "value": 139.439,
        "_internal_originalTime": 1689845188000
    },
    {
        "time": 1689845189000,
        "value": 139.442,
        "_internal_originalTime": 1689845189000
    },
    {
        "time": 1689845191000,
        "value": 139.436,
        "_internal_originalTime": 1689845191000
    },
    {
        "time": 1689845192000,
        "value": 139.437,
        "_internal_originalTime": 1689845192000
    },
    {
        "time": 1689845193000,
        "value": 139.434,
        "_internal_originalTime": 1689845193000
    },
    {
        "time": 1689845194000,
        "value": 139.434,
        "_internal_originalTime": 1689845194000
    },
    {
        "time": 1689845195000,
        "value": 139.439,
        "_internal_originalTime": 1689845195000
    },
    {
        "time": 1689845196000,
        "value": 139.437,
        "_internal_originalTime": 1689845196000
    },
    {
        "time": 1689845198000,
        "value": 139.431,
        "_internal_originalTime": 1689845198000
    },
    {
        "time": 1689845199000,
        "value": 139.432,
        "_internal_originalTime": 1689845199000
    },
    {
        "time": 1689845201000,
        "value": 139.433,
        "_internal_originalTime": 1689845201000
    },
    {
        "time": 1689845202000,
        "value": 139.432,
        "_internal_originalTime": 1689845202000
    },
    {
        "time": 1689845206000,
        "value": 139.437,
        "_internal_originalTime": 1689845206000
    },
    {
        "time": 1689845209000,
        "value": 139.434,
        "_internal_originalTime": 1689845209000
    },
    {
        "time": 1689845212000,
        "value": 139.434,
        "_internal_originalTime": 1689845212000
    },
    {
        "time": 1689845214000,
        "value": 139.43,
        "_internal_originalTime": 1689845214000
    },
    {
        "time": 1689845215000,
        "value": 139.432,
        "_internal_originalTime": 1689845215000
    },
    {
        "time": 1689845217000,
        "value": 139.432,
        "_internal_originalTime": 1689845217000
    },
    {
        "time": 1689845218000,
        "value": 139.436,
        "_internal_originalTime": 1689845218000
    },
    {
        "time": 1689845220000,
        "value": 139.41,
        "_internal_originalTime": 1689845220000
    },
    {
        "time": 1689845221000,
        "value": 139.432,
        "_internal_originalTime": 1689845221000
    },
    {
        "time": 1689845222000,
        "value": 139.434,
        "_internal_originalTime": 1689845222000
    },
    {
        "time": 1689845224000,
        "value": 139.434,
        "_internal_originalTime": 1689845224000
    },
    {
        "time": 1689845225000,
        "value": 139.434,
        "_internal_originalTime": 1689845225000
    },
    {
        "time": 1689845227000,
        "value": 139.429,
        "_internal_originalTime": 1689845227000
    },
    {
        "time": 1689845228000,
        "value": 139.422,
        "_internal_originalTime": 1689845228000
    },
    {
        "time": 1689845229000,
        "value": 139.422,
        "_internal_originalTime": 1689845229000
    },
    {
        "time": 1689845231000,
        "value": 139.426,
        "_internal_originalTime": 1689845231000
    },
    {
        "time": 1689845232000,
        "value": 139.42,
        "_internal_originalTime": 1689845232000
    },
    {
        "time": 1689845233000,
        "value": 139.425,
        "_internal_originalTime": 1689845233000
    },
    {
        "time": 1689845234000,
        "value": 139.422,
        "_internal_originalTime": 1689845234000
    },
    {
        "time": 1689845236000,
        "value": 139.424,
        "_internal_originalTime": 1689845236000
    },
    {
        "time": 1689845238000,
        "value": 139.423,
        "_internal_originalTime": 1689845238000
    },
    {
        "time": 1689845240000,
        "value": 139.42,
        "_internal_originalTime": 1689845240000
    },
    {
        "time": 1689845241000,
        "value": 139.424,
        "_internal_originalTime": 1689845241000
    },
    {
        "time": 1689845245000,
        "value": 139.421,
        "_internal_originalTime": 1689845245000
    },
    {
        "time": 1689845246000,
        "value": 139.421,
        "_internal_originalTime": 1689845246000
    },
    {
        "time": 1689845247000,
        "value": 139.426,
        "_internal_originalTime": 1689845247000
    },
    {
        "time": 1689845248000,
        "value": 139.42,
        "_internal_originalTime": 1689845248000
    },
    {
        "time": 1689845250000,
        "value": 139.426,
        "_internal_originalTime": 1689845250000
    },
    {
        "time": 1689845251000,
        "value": 139.419,
        "_internal_originalTime": 1689845251000
    },
    {
        "time": 1689845252000,
        "value": 139.418,
        "_internal_originalTime": 1689845252000
    },
    {
        "time": 1689845253000,
        "value": 139.425,
        "_internal_originalTime": 1689845253000
    },
    {
        "time": 1689845254000,
        "value": 139.425,
        "_internal_originalTime": 1689845254000
    },
    {
        "time": 1689845256000,
        "value": 139.42,
        "_internal_originalTime": 1689845256000
    },
    {
        "time": 1689845257000,
        "value": 139.418,
        "_internal_originalTime": 1689845257000
    },
    {
        "time": 1689845258000,
        "value": 139.423,
        "_internal_originalTime": 1689845258000
    },
    {
        "time": 1689845259000,
        "value": 139.416,
        "_internal_originalTime": 1689845259000
    },
    {
        "time": 1689845261000,
        "value": 139.419,
        "_internal_originalTime": 1689845261000
    },
    {
        "time": 1689845262000,
        "value": 139.426,
        "_internal_originalTime": 1689845262000
    },
    {
        "time": 1689845264000,
        "value": 139.423,
        "_internal_originalTime": 1689845264000
    },
    {
        "time": 1689845265000,
        "value": 139.42,
        "_internal_originalTime": 1689845265000
    },
    {
        "time": 1689845266000,
        "value": 139.423,
        "_internal_originalTime": 1689845266000
    },
    {
        "time": 1689845267000,
        "value": 139.419,
        "_internal_originalTime": 1689845267000
    },
    {
        "time": 1689845268000,
        "value": 139.425,
        "_internal_originalTime": 1689845268000
    },
    {
        "time": 1689845270000,
        "value": 139.423,
        "_internal_originalTime": 1689845270000
    },
    {
        "time": 1689845271000,
        "value": 139.425,
        "_internal_originalTime": 1689845271000
    },
    {
        "time": 1689845273000,
        "value": 139.418,
        "_internal_originalTime": 1689845273000
    },
    {
        "time": 1689845274000,
        "value": 139.423,
        "_internal_originalTime": 1689845274000
    },
    {
        "time": 1689845276000,
        "value": 139.422,
        "_internal_originalTime": 1689845276000
    },
    {
        "time": 1689845278000,
        "value": 139.412,
        "_internal_originalTime": 1689845278000
    },
    {
        "time": 1689845279000,
        "value": 139.417,
        "_internal_originalTime": 1689845279000
    },
    {
        "time": 1689845281000,
        "value": 139.412,
        "_internal_originalTime": 1689845281000
    },
    {
        "time": 1689845282000,
        "value": 139.417,
        "_internal_originalTime": 1689845282000
    },
    {
        "time": 1689845283000,
        "value": 139.41,
        "_internal_originalTime": 1689845283000
    },
    {
        "time": 1689845285000,
        "value": 139.422,
        "_internal_originalTime": 1689845285000
    },
    {
        "time": 1689845287000,
        "value": 139.425,
        "_internal_originalTime": 1689845287000
    },
    {
        "time": 1689845288000,
        "value": 139.425,
        "_internal_originalTime": 1689845288000
    },
    {
        "time": 1689845289000,
        "value": 139.419,
        "_internal_originalTime": 1689845289000
    },
    {
        "time": 1689845291000,
        "value": 139.421,
        "_internal_originalTime": 1689845291000
    },
    {
        "time": 1689845292000,
        "value": 139.426,
        "_internal_originalTime": 1689845292000
    },
    {
        "time": 1689845293000,
        "value": 139.424,
        "_internal_originalTime": 1689845293000
    },
    {
        "time": 1689845295000,
        "value": 139.426,
        "_internal_originalTime": 1689845295000
    },
    {
        "time": 1689845297000,
        "value": 139.431,
        "_internal_originalTime": 1689845297000
    },
    {
        "time": 1689845298000,
        "value": 139.431,
        "_internal_originalTime": 1689845298000
    },
    {
        "time": 1689845300000,
        "value": 139.43,
        "_internal_originalTime": 1689845300000
    },
    {
        "time": 1689845301000,
        "value": 139.427,
        "_internal_originalTime": 1689845301000
    },
    {
        "time": 1689845302000,
        "value": 139.428,
        "_internal_originalTime": 1689845302000
    },
    {
        "time": 1689845303000,
        "value": 139.427,
        "_internal_originalTime": 1689845303000
    },
    {
        "time": 1689845305000,
        "value": 139.424,
        "_internal_originalTime": 1689845305000
    },
    {
        "time": 1689845307000,
        "value": 139.422,
        "_internal_originalTime": 1689845307000
    },
    {
        "time": 1689845308000,
        "value": 139.427,
        "_internal_originalTime": 1689845308000
    },
    {
        "time": 1689845310000,
        "value": 139.424,
        "_internal_originalTime": 1689845310000
    },
    {
        "time": 1689845311000,
        "value": 139.425,
        "_internal_originalTime": 1689845311000
    },
    {
        "time": 1689845312000,
        "value": 139.424,
        "_internal_originalTime": 1689845312000
    },
    {
        "time": 1689845314000,
        "value": 139.423,
        "_internal_originalTime": 1689845314000
    },
    {
        "time": 1689845315000,
        "value": 139.425,
        "_internal_originalTime": 1689845315000
    },
    {
        "time": 1689845316000,
        "value": 139.417,
        "_internal_originalTime": 1689845316000
    },
    {
        "time": 1689845318000,
        "value": 139.418,
        "_internal_originalTime": 1689845318000
    },
    {
        "time": 1689845319000,
        "value": 139.414,
        "_internal_originalTime": 1689845319000
    },
    {
        "time": 1689845320000,
        "value": 139.417,
        "_internal_originalTime": 1689845320000
    },
    {
        "time": 1689845321000,
        "value": 139.412,
        "_internal_originalTime": 1689845321000
    },
    {
        "time": 1689845323000,
        "value": 139.416,
        "_internal_originalTime": 1689845323000
    },
    {
        "time": 1689845324000,
        "value": 139.409,
        "_internal_originalTime": 1689845324000
    },
    {
        "time": 1689845326000,
        "value": 139.416,
        "_internal_originalTime": 1689845326000
    },
    {
        "time": 1689845328000,
        "value": 139.409,
        "_internal_originalTime": 1689845328000
    },
    {
        "time": 1689845329000,
        "value": 139.413,
        "_internal_originalTime": 1689845329000
    },
    {
        "time": 1689845330000,
        "value": 139.404,
        "_internal_originalTime": 1689845330000
    },
    {
        "time": 1689845332000,
        "value": 139.408,
        "_internal_originalTime": 1689845332000
    },
    {
        "time": 1689845334000,
        "value": 139.407,
        "_internal_originalTime": 1689845334000
    },
    {
        "time": 1689845336000,
        "value": 139.402,
        "_internal_originalTime": 1689845336000
    },
    {
        "time": 1689845337000,
        "value": 139.403,
        "_internal_originalTime": 1689845337000
    },
    {
        "time": 1689845338000,
        "value": 139.403,
        "_internal_originalTime": 1689845338000
    },
    {
        "time": 1689845340000,
        "value": 139.403,
        "_internal_originalTime": 1689845340000
    },
    {
        "time": 1689845341000,
        "value": 139.399,
        "_internal_originalTime": 1689845341000
    },
    {
        "time": 1689845342000,
        "value": 139.399,
        "_internal_originalTime": 1689845342000
    },
    {
        "time": 1689845344000,
        "value": 139.403,
        "_internal_originalTime": 1689845344000
    },
    {
        "time": 1689845345000,
        "value": 139.406,
        "_internal_originalTime": 1689845345000
    },
    {
        "time": 1689845346000,
        "value": 139.414,
        "_internal_originalTime": 1689845346000
    },
    {
        "time": 1689845347000,
        "value": 139.426,
        "_internal_originalTime": 1689845347000
    },
    {
        "time": 1689845349000,
        "value": 139.426,
        "_internal_originalTime": 1689845349000
    },
    {
        "time": 1689845351000,
        "value": 139.426,
        "_internal_originalTime": 1689845351000
    },
    {
        "time": 1689845353000,
        "value": 139.422,
        "_internal_originalTime": 1689845353000
    },
    {
        "time": 1689845354000,
        "value": 139.421,
        "_internal_originalTime": 1689845354000
    },
    {
        "time": 1689845355000,
        "value": 139.42,
        "_internal_originalTime": 1689845355000
    },
    {
        "time": 1689845356000,
        "value": 139.425,
        "_internal_originalTime": 1689845356000
    },
    {
        "time": 1689845357000,
        "value": 139.42,
        "_internal_originalTime": 1689845357000
    },
    {
        "time": 1689845358000,
        "value": 139.422,
        "_internal_originalTime": 1689845358000
    },
    {
        "time": 1689845360000,
        "value": 139.423,
        "_internal_originalTime": 1689845360000
    },
    {
        "time": 1689845361000,
        "value": 139.422,
        "_internal_originalTime": 1689845361000
    },
    {
        "time": 1689845362000,
        "value": 139.422,
        "_internal_originalTime": 1689845362000
    },
    {
        "time": 1689845363000,
        "value": 139.422,
        "_internal_originalTime": 1689845363000
    },
    {
        "time": 1689845365000,
        "value": 139.422,
        "_internal_originalTime": 1689845365000
    },
    {
        "time": 1689845367000,
        "value": 139.427,
        "_internal_originalTime": 1689845367000
    },
    {
        "time": 1689845369000,
        "value": 139.422,
        "_internal_originalTime": 1689845369000
    },
    {
        "time": 1689845370000,
        "value": 139.426,
        "_internal_originalTime": 1689845370000
    },
    {
        "time": 1689845371000,
        "value": 139.424,
        "_internal_originalTime": 1689845371000
    },
    {
        "time": 1689845373000,
        "value": 139.426,
        "_internal_originalTime": 1689845373000
    },
    {
        "time": 1689845374000,
        "value": 139.428,
        "_internal_originalTime": 1689845374000
    },
    {
        "time": 1689845375000,
        "value": 139.426,
        "_internal_originalTime": 1689845375000
    },
    {
        "time": 1689845377000,
        "value": 139.422,
        "_internal_originalTime": 1689845377000
    },
    {
        "time": 1689845378000,
        "value": 139.422,
        "_internal_originalTime": 1689845378000
    },
    {
        "time": 1689845379000,
        "value": 139.419,
        "_internal_originalTime": 1689845379000
    },
    {
        "time": 1689845381000,
        "value": 139.426,
        "_internal_originalTime": 1689845381000
    },
    {
        "time": 1689845382000,
        "value": 139.424,
        "_internal_originalTime": 1689845382000
    },
    {
        "time": 1689845383000,
        "value": 139.422,
        "_internal_originalTime": 1689845383000
    },
    {
        "time": 1689845384000,
        "value": 139.422,
        "_internal_originalTime": 1689845384000
    },
    {
        "time": 1689845385000,
        "value": 139.424,
        "_internal_originalTime": 1689845385000
    },
    {
        "time": 1689845386000,
        "value": 139.423,
        "_internal_originalTime": 1689845386000
    },
    {
        "time": 1689845387000,
        "value": 139.426,
        "_internal_originalTime": 1689845387000
    },
    {
        "time": 1689845388000,
        "value": 139.427,
        "_internal_originalTime": 1689845388000
    },
    {
        "time": 1689845389000,
        "value": 139.423,
        "_internal_originalTime": 1689845389000
    },
    {
        "time": 1689845390000,
        "value": 139.427,
        "_internal_originalTime": 1689845390000
    },
    {
        "time": 1689845392000,
        "value": 139.426,
        "_internal_originalTime": 1689845392000
    },
    {
        "time": 1689845394000,
        "value": 139.424,
        "_internal_originalTime": 1689845394000
    },
    {
        "time": 1689845395000,
        "value": 139.424,
        "_internal_originalTime": 1689845395000
    },
    {
        "time": 1689845400000,
        "value": 139.423,
        "_internal_originalTime": 1689845400000
    },
    {
        "time": 1689845401000,
        "value": 139.427,
        "_internal_originalTime": 1689845401000
    },
    {
        "time": 1689845403000,
        "value": 139.425,
        "_internal_originalTime": 1689845403000
    },
    {
        "time": 1689845404000,
        "value": 139.427,
        "_internal_originalTime": 1689845404000
    },
    {
        "time": 1689845405000,
        "value": 139.425,
        "_internal_originalTime": 1689845405000
    },
    {
        "time": 1689845406000,
        "value": 139.426,
        "_internal_originalTime": 1689845406000
    },
    {
        "time": 1689845407000,
        "value": 139.424,
        "_internal_originalTime": 1689845407000
    },
    {
        "time": 1689845409000,
        "value": 139.412,
        "_internal_originalTime": 1689845409000
    },
    {
        "time": 1689845410000,
        "value": 139.414,
        "_internal_originalTime": 1689845410000
    },
    {
        "time": 1689845411000,
        "value": 139.414,
        "_internal_originalTime": 1689845411000
    },
    {
        "time": 1689845413000,
        "value": 139.412,
        "_internal_originalTime": 1689845413000
    },
    {
        "time": 1689845414000,
        "value": 139.412,
        "_internal_originalTime": 1689845414000
    },
    {
        "time": 1689845415000,
        "value": 139.412,
        "_internal_originalTime": 1689845415000
    },
    {
        "time": 1689845416000,
        "value": 139.412,
        "_internal_originalTime": 1689845416000
    },
    {
        "time": 1689845417000,
        "value": 139.417,
        "_internal_originalTime": 1689845417000
    },
    {
        "time": 1689845419000,
        "value": 139.423,
        "_internal_originalTime": 1689845419000
    },
    {
        "time": 1689845422000,
        "value": 139.421,
        "_internal_originalTime": 1689845422000
    },
    {
        "time": 1689845423000,
        "value": 139.427,
        "_internal_originalTime": 1689845423000
    },
    {
        "time": 1689845424000,
        "value": 139.427,
        "_internal_originalTime": 1689845424000
    },
    {
        "time": 1689845428000,
        "value": 139.423,
        "_internal_originalTime": 1689845428000
    },
    {
        "time": 1689845429000,
        "value": 139.427,
        "_internal_originalTime": 1689845429000
    },
    {
        "time": 1689845430000,
        "value": 139.422,
        "_internal_originalTime": 1689845430000
    },
    {
        "time": 1689845432000,
        "value": 139.424,
        "_internal_originalTime": 1689845432000
    },
    {
        "time": 1689845433000,
        "value": 139.418,
        "_internal_originalTime": 1689845433000
    },
    {
        "time": 1689845434000,
        "value": 139.416,
        "_internal_originalTime": 1689845434000
    },
    {
        "time": 1689845436000,
        "value": 139.419,
        "_internal_originalTime": 1689845436000
    },
    {
        "time": 1689845438000,
        "value": 139.418,
        "_internal_originalTime": 1689845438000
    },
    {
        "time": 1689845440000,
        "value": 139.418,
        "_internal_originalTime": 1689845440000
    },
    {
        "time": 1689845441000,
        "value": 139.417,
        "_internal_originalTime": 1689845441000
    },
    {
        "time": 1689845442000,
        "value": 139.42,
        "_internal_originalTime": 1689845442000
    },
    {
        "time": 1689845444000,
        "value": 139.42,
        "_internal_originalTime": 1689845444000
    },
    {
        "time": 1689845446000,
        "value": 139.412,
        "_internal_originalTime": 1689845446000
    },
    {
        "time": 1689845447000,
        "value": 139.418,
        "_internal_originalTime": 1689845447000
    },
    {
        "time": 1689845449000,
        "value": 139.413,
        "_internal_originalTime": 1689845449000
    },
    {
        "time": 1689845451000,
        "value": 139.419,
        "_internal_originalTime": 1689845451000
    },
    {
        "time": 1689845453000,
        "value": 139.418,
        "_internal_originalTime": 1689845453000
    },
    {
        "time": 1689845454000,
        "value": 139.416,
        "_internal_originalTime": 1689845454000
    },
    {
        "time": 1689845455000,
        "value": 139.415,
        "_internal_originalTime": 1689845455000
    },
    {
        "time": 1689845456000,
        "value": 139.416,
        "_internal_originalTime": 1689845456000
    },
    {
        "time": 1689845459000,
        "value": 139.396,
        "_internal_originalTime": 1689845459000
    },
    {
        "time": 1689845460000,
        "value": 139.401,
        "_internal_originalTime": 1689845460000
    },
    {
        "time": 1689845461000,
        "value": 139.399,
        "_internal_originalTime": 1689845461000
    },
    {
        "time": 1689845462000,
        "value": 139.402,
        "_internal_originalTime": 1689845462000
    },
    {
        "time": 1689845463000,
        "value": 139.4,
        "_internal_originalTime": 1689845463000
    },
    {
        "time": 1689845465000,
        "value": 139.4,
        "_internal_originalTime": 1689845465000
    },
    {
        "time": 1689845466000,
        "value": 139.4,
        "_internal_originalTime": 1689845466000
    },
    {
        "time": 1689845467000,
        "value": 139.396,
        "_internal_originalTime": 1689845467000
    },
    {
        "time": 1689845468000,
        "value": 139.395,
        "_internal_originalTime": 1689845468000
    },
    {
        "time": 1689845470000,
        "value": 139.395,
        "_internal_originalTime": 1689845470000
    },
    {
        "time": 1689845471000,
        "value": 139.399,
        "_internal_originalTime": 1689845471000
    },
    {
        "time": 1689845472000,
        "value": 139.399,
        "_internal_originalTime": 1689845472000
    },
    {
        "time": 1689845474000,
        "value": 139.399,
        "_internal_originalTime": 1689845474000
    },
    {
        "time": 1689845475000,
        "value": 139.397,
        "_internal_originalTime": 1689845475000
    },
    {
        "time": 1689845476000,
        "value": 139.401,
        "_internal_originalTime": 1689845476000
    },
    {
        "time": 1689845478000,
        "value": 139.39,
        "_internal_originalTime": 1689845478000
    },
    {
        "time": 1689845479000,
        "value": 139.374,
        "_internal_originalTime": 1689845479000
    },
    {
        "time": 1689845480000,
        "value": 139.376,
        "_internal_originalTime": 1689845480000
    },
    {
        "time": 1689845482000,
        "value": 139.382,
        "_internal_originalTime": 1689845482000
    },
    {
        "time": 1689845483000,
        "value": 139.376,
        "_internal_originalTime": 1689845483000
    },
    {
        "time": 1689845484000,
        "value": 139.381,
        "_internal_originalTime": 1689845484000
    },
    {
        "time": 1689845485000,
        "value": 139.375,
        "_internal_originalTime": 1689845485000
    },
    {
        "time": 1689845487000,
        "value": 139.374,
        "_internal_originalTime": 1689845487000
    },
    {
        "time": 1689845488000,
        "value": 139.383,
        "_internal_originalTime": 1689845488000
    },
    {
        "time": 1689845489000,
        "value": 139.383,
        "_internal_originalTime": 1689845489000
    },
    {
        "time": 1689845492000,
        "value": 139.383,
        "_internal_originalTime": 1689845492000
    },
    {
        "time": 1689845493000,
        "value": 139.379,
        "_internal_originalTime": 1689845493000
    },
    {
        "time": 1689845494000,
        "value": 139.379,
        "_internal_originalTime": 1689845494000
    },
    {
        "time": 1689845497000,
        "value": 139.367,
        "_internal_originalTime": 1689845497000
    },
    {
        "time": 1689845498000,
        "value": 139.367,
        "_internal_originalTime": 1689845498000
    },
    {
        "time": 1689845499000,
        "value": 139.365,
        "_internal_originalTime": 1689845499000
    },
    {
        "time": 1689845501000,
        "value": 139.358,
        "_internal_originalTime": 1689845501000
    },
    {
        "time": 1689845503000,
        "value": 139.368,
        "_internal_originalTime": 1689845503000
    },
    {
        "time": 1689845504000,
        "value": 139.362,
        "_internal_originalTime": 1689845504000
    },
    {
        "time": 1689845506000,
        "value": 139.369,
        "_internal_originalTime": 1689845506000
    },
    {
        "time": 1689845507000,
        "value": 139.363,
        "_internal_originalTime": 1689845507000
    },
    {
        "time": 1689845508000,
        "value": 139.364,
        "_internal_originalTime": 1689845508000
    },
    {
        "time": 1689845510000,
        "value": 139.364,
        "_internal_originalTime": 1689845510000
    },
    {
        "time": 1689845511000,
        "value": 139.364,
        "_internal_originalTime": 1689845511000
    },
    {
        "time": 1689845512000,
        "value": 139.364,
        "_internal_originalTime": 1689845512000
    },
    {
        "time": 1689845514000,
        "value": 139.364,
        "_internal_originalTime": 1689845514000
    },
    {
        "time": 1689845515000,
        "value": 139.364,
        "_internal_originalTime": 1689845515000
    },
    {
        "time": 1689845516000,
        "value": 139.37,
        "_internal_originalTime": 1689845516000
    },
    {
        "time": 1689845518000,
        "value": 139.366,
        "_internal_originalTime": 1689845518000
    },
    {
        "time": 1689845519000,
        "value": 139.365,
        "_internal_originalTime": 1689845519000
    },
    {
        "time": 1689845520000,
        "value": 139.366,
        "_internal_originalTime": 1689845520000
    },
    {
        "time": 1689845521000,
        "value": 139.364,
        "_internal_originalTime": 1689845521000
    },
    {
        "time": 1689845522000,
        "value": 139.361,
        "_internal_originalTime": 1689845522000
    },
    {
        "time": 1689845523000,
        "value": 139.36,
        "_internal_originalTime": 1689845523000
    },
    {
        "time": 1689845524000,
        "value": 139.358,
        "_internal_originalTime": 1689845524000
    },
    {
        "time": 1689845525000,
        "value": 139.363,
        "_internal_originalTime": 1689845525000
    },
    {
        "time": 1689845526000,
        "value": 139.36,
        "_internal_originalTime": 1689845526000
    },
    {
        "time": 1689845528000,
        "value": 139.359,
        "_internal_originalTime": 1689845528000
    },
    {
        "time": 1689845529000,
        "value": 139.356,
        "_internal_originalTime": 1689845529000
    },
    {
        "time": 1689845530000,
        "value": 139.354,
        "_internal_originalTime": 1689845530000
    },
    {
        "time": 1689845531000,
        "value": 139.36,
        "_internal_originalTime": 1689845531000
    },
    {
        "time": 1689845533000,
        "value": 139.362,
        "_internal_originalTime": 1689845533000
    },
    {
        "time": 1689845534000,
        "value": 139.362,
        "_internal_originalTime": 1689845534000
    },
    {
        "time": 1689845536000,
        "value": 139.362,
        "_internal_originalTime": 1689845536000
    },
    {
        "time": 1689845538000,
        "value": 139.363,
        "_internal_originalTime": 1689845538000
    },
    {
        "time": 1689845539000,
        "value": 139.367,
        "_internal_originalTime": 1689845539000
    },
    {
        "time": 1689845540000,
        "value": 139.367,
        "_internal_originalTime": 1689845540000
    },
    {
        "time": 1689845541000,
        "value": 139.36,
        "_internal_originalTime": 1689845541000
    },
    {
        "time": 1689845542000,
        "value": 139.366,
        "_internal_originalTime": 1689845542000
    },
    {
        "time": 1689845543000,
        "value": 139.367,
        "_internal_originalTime": 1689845543000
    },
    {
        "time": 1689845544000,
        "value": 139.368,
        "_internal_originalTime": 1689845544000
    },
    {
        "time": 1689845546000,
        "value": 139.365,
        "_internal_originalTime": 1689845546000
    },
    {
        "time": 1689845547000,
        "value": 139.367,
        "_internal_originalTime": 1689845547000
    },
    {
        "time": 1689845548000,
        "value": 139.367,
        "_internal_originalTime": 1689845548000
    },
    {
        "time": 1689845549000,
        "value": 139.367,
        "_internal_originalTime": 1689845549000
    },
    {
        "time": 1689845550000,
        "value": 139.369,
        "_internal_originalTime": 1689845550000
    },
    {
        "time": 1689845551000,
        "value": 139.369,
        "_internal_originalTime": 1689845551000
    },
    {
        "time": 1689845552000,
        "value": 139.364,
        "_internal_originalTime": 1689845552000
    },
    {
        "time": 1689845554000,
        "value": 139.368,
        "_internal_originalTime": 1689845554000
    },
    {
        "time": 1689845555000,
        "value": 139.364,
        "_internal_originalTime": 1689845555000
    },
    {
        "time": 1689845556000,
        "value": 139.369,
        "_internal_originalTime": 1689845556000
    },
    {
        "time": 1689845558000,
        "value": 139.364,
        "_internal_originalTime": 1689845558000
    },
    {
        "time": 1689845559000,
        "value": 139.369,
        "_internal_originalTime": 1689845559000
    },
    {
        "time": 1689845561000,
        "value": 139.368,
        "_internal_originalTime": 1689845561000
    },
    {
        "time": 1689845563000,
        "value": 139.375,
        "_internal_originalTime": 1689845563000
    },
    {
        "time": 1689845566000,
        "value": 139.373,
        "_internal_originalTime": 1689845566000
    },
    {
        "time": 1689845568000,
        "value": 139.378,
        "_internal_originalTime": 1689845568000
    },
    {
        "time": 1689845571000,
        "value": 139.372,
        "_internal_originalTime": 1689845571000
    },
    {
        "time": 1689845572000,
        "value": 139.372,
        "_internal_originalTime": 1689845572000
    },
    {
        "time": 1689845574000,
        "value": 139.368,
        "_internal_originalTime": 1689845574000
    },
    {
        "time": 1689845575000,
        "value": 139.368,
        "_internal_originalTime": 1689845575000
    },
    {
        "time": 1689845577000,
        "value": 139.372,
        "_internal_originalTime": 1689845577000
    },
    {
        "time": 1689845578000,
        "value": 139.377,
        "_internal_originalTime": 1689845578000
    },
    {
        "time": 1689845579000,
        "value": 139.38,
        "_internal_originalTime": 1689845579000
    },
    {
        "time": 1689845580000,
        "value": 139.385,
        "_internal_originalTime": 1689845580000
    },
    {
        "time": 1689845581000,
        "value": 139.35,
        "_internal_originalTime": 1689845581000
    },
    {
        "time": 1689845582000,
        "value": 139.384,
        "_internal_originalTime": 1689845582000
    },
    {
        "time": 1689845583000,
        "value": 139.387,
        "_internal_originalTime": 1689845583000
    },
    {
        "time": 1689845585000,
        "value": 139.382,
        "_internal_originalTime": 1689845585000
    },
    {
        "time": 1689845586000,
        "value": 139.387,
        "_internal_originalTime": 1689845586000
    },
    {
        "time": 1689845587000,
        "value": 139.388,
        "_internal_originalTime": 1689845587000
    },
    {
        "time": 1689845588000,
        "value": 139.387,
        "_internal_originalTime": 1689845588000
    },
    {
        "time": 1689845590000,
        "value": 139.382,
        "_internal_originalTime": 1689845590000
    },
    {
        "time": 1689845591000,
        "value": 139.388,
        "_internal_originalTime": 1689845591000
    },
    {
        "time": 1689845593000,
        "value": 139.385,
        "_internal_originalTime": 1689845593000
    },
    {
        "time": 1689845594000,
        "value": 139.385,
        "_internal_originalTime": 1689845594000
    },
    {
        "time": 1689845595000,
        "value": 139.391,
        "_internal_originalTime": 1689845595000
    },
    {
        "time": 1689845597000,
        "value": 139.391,
        "_internal_originalTime": 1689845597000
    },
    {
        "time": 1689845598000,
        "value": 139.387,
        "_internal_originalTime": 1689845598000
    },
    {
        "time": 1689845600000,
        "value": 139.388,
        "_internal_originalTime": 1689845600000
    },
    {
        "time": 1689845602000,
        "value": 139.382,
        "_internal_originalTime": 1689845602000
    },
    {
        "time": 1689845604000,
        "value": 139.387,
        "_internal_originalTime": 1689845604000
    },
    {
        "time": 1689845606000,
        "value": 139.388,
        "_internal_originalTime": 1689845606000
    },
    {
        "time": 1689845607000,
        "value": 139.392,
        "_internal_originalTime": 1689845607000
    },
    {
        "time": 1689845608000,
        "value": 139.392,
        "_internal_originalTime": 1689845608000
    },
    {
        "time": 1689845609000,
        "value": 139.403,
        "_internal_originalTime": 1689845609000
    },
    {
        "time": 1689845610000,
        "value": 139.399,
        "_internal_originalTime": 1689845610000
    },
    {
        "time": 1689845611000,
        "value": 139.402,
        "_internal_originalTime": 1689845611000
    },
    {
        "time": 1689845612000,
        "value": 139.405,
        "_internal_originalTime": 1689845612000
    },
    {
        "time": 1689845613000,
        "value": 139.398,
        "_internal_originalTime": 1689845613000
    },
    {
        "time": 1689845614000,
        "value": 139.395,
        "_internal_originalTime": 1689845614000
    },
    {
        "time": 1689845616000,
        "value": 139.395,
        "_internal_originalTime": 1689845616000
    },
    {
        "time": 1689845617000,
        "value": 139.395,
        "_internal_originalTime": 1689845617000
    },
    {
        "time": 1689845618000,
        "value": 139.392,
        "_internal_originalTime": 1689845618000
    },
    {
        "time": 1689845620000,
        "value": 139.395,
        "_internal_originalTime": 1689845620000
    },
    {
        "time": 1689845621000,
        "value": 139.402,
        "_internal_originalTime": 1689845621000
    },
    {
        "time": 1689845622000,
        "value": 139.402,
        "_internal_originalTime": 1689845622000
    },
    {
        "time": 1689845623000,
        "value": 139.402,
        "_internal_originalTime": 1689845623000
    },
    {
        "time": 1689845624000,
        "value": 139.402,
        "_internal_originalTime": 1689845624000
    },
    {
        "time": 1689845625000,
        "value": 139.403,
        "_internal_originalTime": 1689845625000
    },
    {
        "time": 1689845627000,
        "value": 139.392,
        "_internal_originalTime": 1689845627000
    },
    {
        "time": 1689845628000,
        "value": 139.401,
        "_internal_originalTime": 1689845628000
    },
    {
        "time": 1689845629000,
        "value": 139.4,
        "_internal_originalTime": 1689845629000
    },
    {
        "time": 1689845631000,
        "value": 139.397,
        "_internal_originalTime": 1689845631000
    },
    {
        "time": 1689845632000,
        "value": 139.393,
        "_internal_originalTime": 1689845632000
    },
    {
        "time": 1689845633000,
        "value": 139.387,
        "_internal_originalTime": 1689845633000
    },
    {
        "time": 1689845634000,
        "value": 139.383,
        "_internal_originalTime": 1689845634000
    },
    {
        "time": 1689845635000,
        "value": 139.387,
        "_internal_originalTime": 1689845635000
    },
    {
        "time": 1689845638000,
        "value": 139.389,
        "_internal_originalTime": 1689845638000
    },
    {
        "time": 1689845641000,
        "value": 139.37,
        "_internal_originalTime": 1689845641000
    },
    {
        "time": 1689845642000,
        "value": 139.385,
        "_internal_originalTime": 1689845642000
    },
    {
        "time": 1689845643000,
        "value": 139.389,
        "_internal_originalTime": 1689845643000
    },
    {
        "time": 1689845644000,
        "value": 139.389,
        "_internal_originalTime": 1689845644000
    },
    {
        "time": 1689845645000,
        "value": 139.389,
        "_internal_originalTime": 1689845645000
    },
    {
        "time": 1689845646000,
        "value": 139.384,
        "_internal_originalTime": 1689845646000
    },
    {
        "time": 1689845650000,
        "value": 139.383,
        "_internal_originalTime": 1689845650000
    },
    {
        "time": 1689845651000,
        "value": 139.388,
        "_internal_originalTime": 1689845651000
    },
    {
        "time": 1689845653000,
        "value": 139.384,
        "_internal_originalTime": 1689845653000
    },
    {
        "time": 1689845657000,
        "value": 139.386,
        "_internal_originalTime": 1689845657000
    },
    {
        "time": 1689845658000,
        "value": 139.385,
        "_internal_originalTime": 1689845658000
    },
    {
        "time": 1689845659000,
        "value": 139.386,
        "_internal_originalTime": 1689845659000
    },
    {
        "time": 1689845660000,
        "value": 139.383,
        "_internal_originalTime": 1689845660000
    },
    {
        "time": 1689845662000,
        "value": 139.386,
        "_internal_originalTime": 1689845662000
    },
    {
        "time": 1689845663000,
        "value": 139.382,
        "_internal_originalTime": 1689845663000
    },
    {
        "time": 1689845665000,
        "value": 139.366,
        "_internal_originalTime": 1689845665000
    },
    {
        "time": 1689845667000,
        "value": 139.371,
        "_internal_originalTime": 1689845667000
    },
    {
        "time": 1689845668000,
        "value": 139.372,
        "_internal_originalTime": 1689845668000
    },
    {
        "time": 1689845670000,
        "value": 139.373,
        "_internal_originalTime": 1689845670000
    },
    {
        "time": 1689845671000,
        "value": 139.37,
        "_internal_originalTime": 1689845671000
    },
    {
        "time": 1689845672000,
        "value": 139.375,
        "_internal_originalTime": 1689845672000
    },
    {
        "time": 1689845673000,
        "value": 139.376,
        "_internal_originalTime": 1689845673000
    },
    {
        "time": 1689845674000,
        "value": 139.375,
        "_internal_originalTime": 1689845674000
    },
    {
        "time": 1689845675000,
        "value": 139.377,
        "_internal_originalTime": 1689845675000
    },
    {
        "time": 1689845677000,
        "value": 139.365,
        "_internal_originalTime": 1689845677000
    },
    {
        "time": 1689845678000,
        "value": 139.367,
        "_internal_originalTime": 1689845678000
    },
    {
        "time": 1689845679000,
        "value": 139.369,
        "_internal_originalTime": 1689845679000
    },
    {
        "time": 1689845680000,
        "value": 139.366,
        "_internal_originalTime": 1689845680000
    },
    {
        "time": 1689845682000,
        "value": 139.362,
        "_internal_originalTime": 1689845682000
    },
    {
        "time": 1689845683000,
        "value": 139.367,
        "_internal_originalTime": 1689845683000
    },
    {
        "time": 1689845684000,
        "value": 139.367,
        "_internal_originalTime": 1689845684000
    },
    {
        "time": 1689845685000,
        "value": 139.362,
        "_internal_originalTime": 1689845685000
    },
    {
        "time": 1689845686000,
        "value": 139.364,
        "_internal_originalTime": 1689845686000
    },
    {
        "time": 1689845688000,
        "value": 139.362,
        "_internal_originalTime": 1689845688000
    },
    {
        "time": 1689845690000,
        "value": 139.363,
        "_internal_originalTime": 1689845690000
    },
    {
        "time": 1689845692000,
        "value": 139.368,
        "_internal_originalTime": 1689845692000
    },
    {
        "time": 1689845694000,
        "value": 139.368,
        "_internal_originalTime": 1689845694000
    },
    {
        "time": 1689845695000,
        "value": 139.378,
        "_internal_originalTime": 1689845695000
    },
    {
        "time": 1689845696000,
        "value": 139.377,
        "_internal_originalTime": 1689845696000
    },
    {
        "time": 1689845699000,
        "value": 139.378,
        "_internal_originalTime": 1689845699000
    },
    {
        "time": 1689845700000,
        "value": 139.376,
        "_internal_originalTime": 1689845700000
    },
    {
        "time": 1689845701000,
        "value": 139.377,
        "_internal_originalTime": 1689845701000
    },
    {
        "time": 1689845702000,
        "value": 139.378,
        "_internal_originalTime": 1689845702000
    },
    {
        "time": 1689845703000,
        "value": 139.378,
        "_internal_originalTime": 1689845703000
    },
    {
        "time": 1689845705000,
        "value": 139.372,
        "_internal_originalTime": 1689845705000
    },
    {
        "time": 1689845706000,
        "value": 139.377,
        "_internal_originalTime": 1689845706000
    },
    {
        "time": 1689845708000,
        "value": 139.377,
        "_internal_originalTime": 1689845708000
    },
    {
        "time": 1689845710000,
        "value": 139.373,
        "_internal_originalTime": 1689845710000
    },
    {
        "time": 1689845711000,
        "value": 139.373,
        "_internal_originalTime": 1689845711000
    },
    {
        "time": 1689845712000,
        "value": 139.372,
        "_internal_originalTime": 1689845712000
    },
    {
        "time": 1689845714000,
        "value": 139.376,
        "_internal_originalTime": 1689845714000
    },
    {
        "time": 1689845715000,
        "value": 139.375,
        "_internal_originalTime": 1689845715000
    },
    {
        "time": 1689845717000,
        "value": 139.375,
        "_internal_originalTime": 1689845717000
    },
    {
        "time": 1689845718000,
        "value": 139.372,
        "_internal_originalTime": 1689845718000
    },
    {
        "time": 1689845719000,
        "value": 139.377,
        "_internal_originalTime": 1689845719000
    },
    {
        "time": 1689845721000,
        "value": 139.372,
        "_internal_originalTime": 1689845721000
    },
    {
        "time": 1689845722000,
        "value": 139.375,
        "_internal_originalTime": 1689845722000
    },
    {
        "time": 1689845724000,
        "value": 139.371,
        "_internal_originalTime": 1689845724000
    },
    {
        "time": 1689845725000,
        "value": 139.377,
        "_internal_originalTime": 1689845725000
    },
    {
        "time": 1689845726000,
        "value": 139.376,
        "_internal_originalTime": 1689845726000
    },
    {
        "time": 1689845727000,
        "value": 139.381,
        "_internal_originalTime": 1689845727000
    },
    {
        "time": 1689845730000,
        "value": 139.381,
        "_internal_originalTime": 1689845730000
    },
    {
        "time": 1689845732000,
        "value": 139.378,
        "_internal_originalTime": 1689845732000
    },
    {
        "time": 1689845733000,
        "value": 139.373,
        "_internal_originalTime": 1689845733000
    },
    {
        "time": 1689845734000,
        "value": 139.375,
        "_internal_originalTime": 1689845734000
    },
    {
        "time": 1689845736000,
        "value": 139.373,
        "_internal_originalTime": 1689845736000
    },
    {
        "time": 1689845737000,
        "value": 139.376,
        "_internal_originalTime": 1689845737000
    },
    {
        "time": 1689845739000,
        "value": 139.38,
        "_internal_originalTime": 1689845739000
    },
    {
        "time": 1689845740000,
        "value": 139.385,
        "_internal_originalTime": 1689845740000
    },
    {
        "time": 1689845741000,
        "value": 139.388,
        "_internal_originalTime": 1689845741000
    },
    {
        "time": 1689845743000,
        "value": 139.389,
        "_internal_originalTime": 1689845743000
    },
    {
        "time": 1689845744000,
        "value": 139.39,
        "_internal_originalTime": 1689845744000
    },
    {
        "time": 1689845745000,
        "value": 139.39,
        "_internal_originalTime": 1689845745000
    },
    {
        "time": 1689845747000,
        "value": 139.391,
        "_internal_originalTime": 1689845747000
    },
    {
        "time": 1689845749000,
        "value": 139.397,
        "_internal_originalTime": 1689845749000
    },
    {
        "time": 1689845750000,
        "value": 139.394,
        "_internal_originalTime": 1689845750000
    },
    {
        "time": 1689845754000,
        "value": 139.392,
        "_internal_originalTime": 1689845754000
    },
    {
        "time": 1689845755000,
        "value": 139.393,
        "_internal_originalTime": 1689845755000
    },
    {
        "time": 1689845757000,
        "value": 139.394,
        "_internal_originalTime": 1689845757000
    },
    {
        "time": 1689845758000,
        "value": 139.403,
        "_internal_originalTime": 1689845758000
    },
    {
        "time": 1689845759000,
        "value": 139.401,
        "_internal_originalTime": 1689845759000
    },
    {
        "time": 1689845761000,
        "value": 139.37,
        "_internal_originalTime": 1689845761000
    },
    {
        "time": 1689845763000,
        "value": 139.398,
        "_internal_originalTime": 1689845763000
    },
    {
        "time": 1689845764000,
        "value": 139.398,
        "_internal_originalTime": 1689845764000
    },
    {
        "time": 1689845766000,
        "value": 139.392,
        "_internal_originalTime": 1689845766000
    },
    {
        "time": 1689845767000,
        "value": 139.394,
        "_internal_originalTime": 1689845767000
    },
    {
        "time": 1689845769000,
        "value": 139.384,
        "_internal_originalTime": 1689845769000
    },
    {
        "time": 1689845770000,
        "value": 139.379,
        "_internal_originalTime": 1689845770000
    },
    {
        "time": 1689845771000,
        "value": 139.382,
        "_internal_originalTime": 1689845771000
    },
    {
        "time": 1689845774000,
        "value": 139.378,
        "_internal_originalTime": 1689845774000
    },
    {
        "time": 1689845775000,
        "value": 139.376,
        "_internal_originalTime": 1689845775000
    },
    {
        "time": 1689845776000,
        "value": 139.373,
        "_internal_originalTime": 1689845776000
    },
    {
        "time": 1689845777000,
        "value": 139.377,
        "_internal_originalTime": 1689845777000
    },
    {
        "time": 1689845779000,
        "value": 139.379,
        "_internal_originalTime": 1689845779000
    },
    {
        "time": 1689845780000,
        "value": 139.382,
        "_internal_originalTime": 1689845780000
    },
    {
        "time": 1689845781000,
        "value": 139.374,
        "_internal_originalTime": 1689845781000
    },
    {
        "time": 1689845782000,
        "value": 139.37,
        "_internal_originalTime": 1689845782000
    },
    {
        "time": 1689845784000,
        "value": 139.375,
        "_internal_originalTime": 1689845784000
    },
    {
        "time": 1689845785000,
        "value": 139.385,
        "_internal_originalTime": 1689845785000
    },
    {
        "time": 1689845786000,
        "value": 139.385,
        "_internal_originalTime": 1689845786000
    },
    {
        "time": 1689845787000,
        "value": 139.385,
        "_internal_originalTime": 1689845787000
    },
    {
        "time": 1689845790000,
        "value": 139.388,
        "_internal_originalTime": 1689845790000
    },
    {
        "time": 1689845791000,
        "value": 139.388,
        "_internal_originalTime": 1689845791000
    },
    {
        "time": 1689845792000,
        "value": 139.386,
        "_internal_originalTime": 1689845792000
    },
    {
        "time": 1689845793000,
        "value": 139.384,
        "_internal_originalTime": 1689845793000
    },
    {
        "time": 1689845794000,
        "value": 139.389,
        "_internal_originalTime": 1689845794000
    },
    {
        "time": 1689845795000,
        "value": 139.387,
        "_internal_originalTime": 1689845795000
    },
    {
        "time": 1689845796000,
        "value": 139.388,
        "_internal_originalTime": 1689845796000
    },
    {
        "time": 1689845798000,
        "value": 139.389,
        "_internal_originalTime": 1689845798000
    },
    {
        "time": 1689845799000,
        "value": 139.387,
        "_internal_originalTime": 1689845799000
    },
    {
        "time": 1689845801000,
        "value": 139.39,
        "_internal_originalTime": 1689845801000
    },
    {
        "time": 1689845802000,
        "value": 139.389,
        "_internal_originalTime": 1689845802000
    },
    {
        "time": 1689845803000,
        "value": 139.39,
        "_internal_originalTime": 1689845803000
    },
    {
        "time": 1689845805000,
        "value": 139.389,
        "_internal_originalTime": 1689845805000
    },
    {
        "time": 1689845806000,
        "value": 139.387,
        "_internal_originalTime": 1689845806000
    },
    {
        "time": 1689845807000,
        "value": 139.388,
        "_internal_originalTime": 1689845807000
    },
    {
        "time": 1689845809000,
        "value": 139.391,
        "_internal_originalTime": 1689845809000
    },
    {
        "time": 1689845811000,
        "value": 139.388,
        "_internal_originalTime": 1689845811000
    },
    {
        "time": 1689845814000,
        "value": 139.397,
        "_internal_originalTime": 1689845814000
    },
    {
        "time": 1689845816000,
        "value": 139.396,
        "_internal_originalTime": 1689845816000
    },
    {
        "time": 1689845817000,
        "value": 139.399,
        "_internal_originalTime": 1689845817000
    },
    {
        "time": 1689845818000,
        "value": 139.397,
        "_internal_originalTime": 1689845818000
    },
    {
        "time": 1689845820000,
        "value": 139.397,
        "_internal_originalTime": 1689845820000
    },
    {
        "time": 1689845821000,
        "value": 139.396,
        "_internal_originalTime": 1689845821000
    },
    {
        "time": 1689845822000,
        "value": 139.393,
        "_internal_originalTime": 1689845822000
    },
    {
        "time": 1689845824000,
        "value": 139.397,
        "_internal_originalTime": 1689845824000
    },
    {
        "time": 1689845825000,
        "value": 139.398,
        "_internal_originalTime": 1689845825000
    },
    {
        "time": 1689845826000,
        "value": 139.394,
        "_internal_originalTime": 1689845826000
    },
    {
        "time": 1689845827000,
        "value": 139.395,
        "_internal_originalTime": 1689845827000
    },
    {
        "time": 1689845828000,
        "value": 139.394,
        "_internal_originalTime": 1689845828000
    },
    {
        "time": 1689845830000,
        "value": 139.397,
        "_internal_originalTime": 1689845830000
    },
    {
        "time": 1689845831000,
        "value": 139.394,
        "_internal_originalTime": 1689845831000
    },
    {
        "time": 1689845832000,
        "value": 139.398,
        "_internal_originalTime": 1689845832000
    },
    {
        "time": 1689845834000,
        "value": 139.396,
        "_internal_originalTime": 1689845834000
    },
    {
        "time": 1689845835000,
        "value": 139.385,
        "_internal_originalTime": 1689845835000
    },
    {
        "time": 1689845837000,
        "value": 139.383,
        "_internal_originalTime": 1689845837000
    },
    {
        "time": 1689845838000,
        "value": 139.383,
        "_internal_originalTime": 1689845838000
    },
    {
        "time": 1689845840000,
        "value": 139.385,
        "_internal_originalTime": 1689845840000
    },
    {
        "time": 1689845841000,
        "value": 139.387,
        "_internal_originalTime": 1689845841000
    },
    {
        "time": 1689845842000,
        "value": 139.384,
        "_internal_originalTime": 1689845842000
    },
    {
        "time": 1689845844000,
        "value": 139.386,
        "_internal_originalTime": 1689845844000
    },
    {
        "time": 1689845846000,
        "value": 139.386,
        "_internal_originalTime": 1689845846000
    },
    {
        "time": 1689845847000,
        "value": 139.384,
        "_internal_originalTime": 1689845847000
    },
    {
        "time": 1689845848000,
        "value": 139.386,
        "_internal_originalTime": 1689845848000
    },
    {
        "time": 1689845850000,
        "value": 139.39,
        "_internal_originalTime": 1689845850000
    },
    {
        "time": 1689845853000,
        "value": 139.388,
        "_internal_originalTime": 1689845853000
    },
    {
        "time": 1689845854000,
        "value": 139.392,
        "_internal_originalTime": 1689845854000
    },
    {
        "time": 1689845855000,
        "value": 139.394,
        "_internal_originalTime": 1689845855000
    },
    {
        "time": 1689845857000,
        "value": 139.392,
        "_internal_originalTime": 1689845857000
    },
    {
        "time": 1689845861000,
        "value": 139.395,
        "_internal_originalTime": 1689845861000
    },
    {
        "time": 1689845862000,
        "value": 139.396,
        "_internal_originalTime": 1689845862000
    },
    {
        "time": 1689845863000,
        "value": 139.396,
        "_internal_originalTime": 1689845863000
    },
    {
        "time": 1689845865000,
        "value": 139.396,
        "_internal_originalTime": 1689845865000
    },
    {
        "time": 1689845866000,
        "value": 139.396,
        "_internal_originalTime": 1689845866000
    },
    {
        "time": 1689845871000,
        "value": 139.396,
        "_internal_originalTime": 1689845871000
    },
    {
        "time": 1689845872000,
        "value": 139.398,
        "_internal_originalTime": 1689845872000
    },
    {
        "time": 1689845873000,
        "value": 139.398,
        "_internal_originalTime": 1689845873000
    },
    {
        "time": 1689845875000,
        "value": 139.397,
        "_internal_originalTime": 1689845875000
    },
    {
        "time": 1689845876000,
        "value": 139.396,
        "_internal_originalTime": 1689845876000
    },
    {
        "time": 1689845878000,
        "value": 139.391,
        "_internal_originalTime": 1689845878000
    },
    {
        "time": 1689845879000,
        "value": 139.391,
        "_internal_originalTime": 1689845879000
    },
    {
        "time": 1689845880000,
        "value": 139.395,
        "_internal_originalTime": 1689845880000
    },
    {
        "time": 1689845882000,
        "value": 139.392,
        "_internal_originalTime": 1689845882000
    },
    {
        "time": 1689845884000,
        "value": 139.394,
        "_internal_originalTime": 1689845884000
    },
    {
        "time": 1689845885000,
        "value": 139.394,
        "_internal_originalTime": 1689845885000
    },
    {
        "time": 1689845886000,
        "value": 139.395,
        "_internal_originalTime": 1689845886000
    },
    {
        "time": 1689845887000,
        "value": 139.396,
        "_internal_originalTime": 1689845887000
    },
    {
        "time": 1689845888000,
        "value": 139.395,
        "_internal_originalTime": 1689845888000
    },
    {
        "time": 1689845890000,
        "value": 139.39,
        "_internal_originalTime": 1689845890000
    },
    {
        "time": 1689845892000,
        "value": 139.394,
        "_internal_originalTime": 1689845892000
    },
    {
        "time": 1689845895000,
        "value": 139.394,
        "_internal_originalTime": 1689845895000
    },
    {
        "time": 1689845896000,
        "value": 139.392,
        "_internal_originalTime": 1689845896000
    },
    {
        "time": 1689845897000,
        "value": 139.394,
        "_internal_originalTime": 1689845897000
    },
    {
        "time": 1689845899000,
        "value": 139.384,
        "_internal_originalTime": 1689845899000
    },
    {
        "time": 1689845900000,
        "value": 139.385,
        "_internal_originalTime": 1689845900000
    },
    {
        "time": 1689845902000,
        "value": 139.383,
        "_internal_originalTime": 1689845902000
    },
    {
        "time": 1689845904000,
        "value": 139.382,
        "_internal_originalTime": 1689845904000
    },
    {
        "time": 1689845905000,
        "value": 139.383,
        "_internal_originalTime": 1689845905000
    },
    {
        "time": 1689845906000,
        "value": 139.382,
        "_internal_originalTime": 1689845906000
    },
    {
        "time": 1689845909000,
        "value": 139.387,
        "_internal_originalTime": 1689845909000
    },
    {
        "time": 1689845911000,
        "value": 139.385,
        "_internal_originalTime": 1689845911000
    },
    {
        "time": 1689845912000,
        "value": 139.386,
        "_internal_originalTime": 1689845912000
    },
    {
        "time": 1689845913000,
        "value": 139.385,
        "_internal_originalTime": 1689845913000
    },
    {
        "time": 1689845914000,
        "value": 139.383,
        "_internal_originalTime": 1689845914000
    },
    {
        "time": 1689845917000,
        "value": 139.379,
        "_internal_originalTime": 1689845917000
    },
    {
        "time": 1689845918000,
        "value": 139.369,
        "_internal_originalTime": 1689845918000
    },
    {
        "time": 1689845919000,
        "value": 139.369,
        "_internal_originalTime": 1689845919000
    },
    {
        "time": 1689845921000,
        "value": 139.372,
        "_internal_originalTime": 1689845921000
    },
    {
        "time": 1689845922000,
        "value": 139.385,
        "_internal_originalTime": 1689845922000
    },
    {
        "time": 1689845923000,
        "value": 139.38,
        "_internal_originalTime": 1689845923000
    },
    {
        "time": 1689845925000,
        "value": 139.377,
        "_internal_originalTime": 1689845925000
    },
    {
        "time": 1689845927000,
        "value": 139.382,
        "_internal_originalTime": 1689845927000
    },
    {
        "time": 1689845928000,
        "value": 139.374,
        "_internal_originalTime": 1689845928000
    },
    {
        "time": 1689845929000,
        "value": 139.377,
        "_internal_originalTime": 1689845929000
    },
    {
        "time": 1689845930000,
        "value": 139.381,
        "_internal_originalTime": 1689845930000
    },
    {
        "time": 1689845932000,
        "value": 139.381,
        "_internal_originalTime": 1689845932000
    },
    {
        "time": 1689845934000,
        "value": 139.376,
        "_internal_originalTime": 1689845934000
    },
    {
        "time": 1689845935000,
        "value": 139.379,
        "_internal_originalTime": 1689845935000
    },
    {
        "time": 1689845937000,
        "value": 139.377,
        "_internal_originalTime": 1689845937000
    },
    {
        "time": 1689845938000,
        "value": 139.387,
        "_internal_originalTime": 1689845938000
    },
    {
        "time": 1689845939000,
        "value": 139.385,
        "_internal_originalTime": 1689845939000
    },
    {
        "time": 1689845940000,
        "value": 139.389,
        "_internal_originalTime": 1689845940000
    },
    {
        "time": 1689845941000,
        "value": 139.384,
        "_internal_originalTime": 1689845941000
    },
    {
        "time": 1689845942000,
        "value": 139.39,
        "_internal_originalTime": 1689845942000
    },
    {
        "time": 1689845943000,
        "value": 139.388,
        "_internal_originalTime": 1689845943000
    },
    {
        "time": 1689845945000,
        "value": 139.389,
        "_internal_originalTime": 1689845945000
    },
    {
        "time": 1689845947000,
        "value": 139.389,
        "_internal_originalTime": 1689845947000
    },
    {
        "time": 1689845949000,
        "value": 139.389,
        "_internal_originalTime": 1689845949000
    },
    {
        "time": 1689845951000,
        "value": 139.384,
        "_internal_originalTime": 1689845951000
    },
    {
        "time": 1689845952000,
        "value": 139.387,
        "_internal_originalTime": 1689845952000
    },
    {
        "time": 1689845953000,
        "value": 139.384,
        "_internal_originalTime": 1689845953000
    },
    {
        "time": 1689845954000,
        "value": 139.387,
        "_internal_originalTime": 1689845954000
    },
    {
        "time": 1689845956000,
        "value": 139.386,
        "_internal_originalTime": 1689845956000
    },
    {
        "time": 1689845958000,
        "value": 139.384,
        "_internal_originalTime": 1689845958000
    },
    {
        "time": 1689845959000,
        "value": 139.389,
        "_internal_originalTime": 1689845959000
    },
    {
        "time": 1689845960000,
        "value": 139.384,
        "_internal_originalTime": 1689845960000
    },
    {
        "time": 1689845962000,
        "value": 139.382,
        "_internal_originalTime": 1689845962000
    },
    {
        "time": 1689845963000,
        "value": 139.38,
        "_internal_originalTime": 1689845963000
    },
    {
        "time": 1689845964000,
        "value": 139.38,
        "_internal_originalTime": 1689845964000
    },
    {
        "time": 1689845965000,
        "value": 139.385,
        "_internal_originalTime": 1689845965000
    },
    {
        "time": 1689845967000,
        "value": 139.387,
        "_internal_originalTime": 1689845967000
    },
    {
        "time": 1689845968000,
        "value": 139.386,
        "_internal_originalTime": 1689845968000
    },
    {
        "time": 1689845970000,
        "value": 139.384,
        "_internal_originalTime": 1689845970000
    },
    {
        "time": 1689845971000,
        "value": 139.386,
        "_internal_originalTime": 1689845971000
    },
    {
        "time": 1689845975000,
        "value": 139.387,
        "_internal_originalTime": 1689845975000
    },
    {
        "time": 1689845976000,
        "value": 139.386,
        "_internal_originalTime": 1689845976000
    },
    {
        "time": 1689845978000,
        "value": 139.384,
        "_internal_originalTime": 1689845978000
    },
    {
        "time": 1689845979000,
        "value": 139.385,
        "_internal_originalTime": 1689845979000
    },
    {
        "time": 1689845981000,
        "value": 139.388,
        "_internal_originalTime": 1689845981000
    },
    {
        "time": 1689845983000,
        "value": 139.4,
        "_internal_originalTime": 1689845983000
    },
    {
        "time": 1689845984000,
        "value": 139.397,
        "_internal_originalTime": 1689845984000
    },
    {
        "time": 1689845985000,
        "value": 139.395,
        "_internal_originalTime": 1689845985000
    },
    {
        "time": 1689845986000,
        "value": 139.399,
        "_internal_originalTime": 1689845986000
    },
    {
        "time": 1689845988000,
        "value": 139.398,
        "_internal_originalTime": 1689845988000
    },
    {
        "time": 1689845989000,
        "value": 139.397,
        "_internal_originalTime": 1689845989000
    },
    {
        "time": 1689845990000,
        "value": 139.394,
        "_internal_originalTime": 1689845990000
    },
    {
        "time": 1689845991000,
        "value": 139.397,
        "_internal_originalTime": 1689845991000
    },
    {
        "time": 1689845993000,
        "value": 139.396,
        "_internal_originalTime": 1689845993000
    },
    {
        "time": 1689845994000,
        "value": 139.399,
        "_internal_originalTime": 1689845994000
    },
    {
        "time": 1689845995000,
        "value": 139.397,
        "_internal_originalTime": 1689845995000
    },
    {
        "time": 1689845996000,
        "value": 139.394,
        "_internal_originalTime": 1689845996000
    },
    {
        "time": 1689845998000,
        "value": 139.398,
        "_internal_originalTime": 1689845998000
    },
    {
        "time": 1689845999000,
        "value": 139.394,
        "_internal_originalTime": 1689845999000
    },
    {
        "time": 1689846000000,
        "value": 139.395,
        "_internal_originalTime": 1689846000000
    },
    {
        "time": 1689846002000,
        "value": 139.399,
        "_internal_originalTime": 1689846002000
    },
    {
        "time": 1689846003000,
        "value": 139.399,
        "_internal_originalTime": 1689846003000
    },
    {
        "time": 1689846004000,
        "value": 139.4,
        "_internal_originalTime": 1689846004000
    },
    {
        "time": 1689846005000,
        "value": 139.399,
        "_internal_originalTime": 1689846005000
    },
    {
        "time": 1689846007000,
        "value": 139.396,
        "_internal_originalTime": 1689846007000
    },
    {
        "time": 1689846008000,
        "value": 139.398,
        "_internal_originalTime": 1689846008000
    },
    {
        "time": 1689846010000,
        "value": 139.391,
        "_internal_originalTime": 1689846010000
    },
    {
        "time": 1689846011000,
        "value": 139.4,
        "_internal_originalTime": 1689846011000
    },
    {
        "time": 1689846012000,
        "value": 139.395,
        "_internal_originalTime": 1689846012000
    },
    {
        "time": 1689846014000,
        "value": 139.398,
        "_internal_originalTime": 1689846014000
    },
    {
        "time": 1689846015000,
        "value": 139.398,
        "_internal_originalTime": 1689846015000
    },
    {
        "time": 1689846017000,
        "value": 139.398,
        "_internal_originalTime": 1689846017000
    },
    {
        "time": 1689846018000,
        "value": 139.399,
        "_internal_originalTime": 1689846018000
    },
    {
        "time": 1689846019000,
        "value": 139.395,
        "_internal_originalTime": 1689846019000
    },
    {
        "time": 1689846020000,
        "value": 139.396,
        "_internal_originalTime": 1689846020000
    },
    {
        "time": 1689846021000,
        "value": 139.394,
        "_internal_originalTime": 1689846021000
    },
    {
        "time": 1689846023000,
        "value": 139.408,
        "_internal_originalTime": 1689846023000
    },
    {
        "time": 1689846024000,
        "value": 139.4,
        "_internal_originalTime": 1689846024000
    },
    {
        "time": 1689846026000,
        "value": 139.399,
        "_internal_originalTime": 1689846026000
    },
    {
        "time": 1689846028000,
        "value": 139.398,
        "_internal_originalTime": 1689846028000
    },
    {
        "time": 1689846029000,
        "value": 139.397,
        "_internal_originalTime": 1689846029000
    },
    {
        "time": 1689846030000,
        "value": 139.397,
        "_internal_originalTime": 1689846030000
    },
    {
        "time": 1689846031000,
        "value": 139.397,
        "_internal_originalTime": 1689846031000
    },
    {
        "time": 1689846033000,
        "value": 139.387,
        "_internal_originalTime": 1689846033000
    },
    {
        "time": 1689846034000,
        "value": 139.396,
        "_internal_originalTime": 1689846034000
    },
    {
        "time": 1689846035000,
        "value": 139.407,
        "_internal_originalTime": 1689846035000
    },
    {
        "time": 1689846036000,
        "value": 139.397,
        "_internal_originalTime": 1689846036000
    },
    {
        "time": 1689846037000,
        "value": 139.397,
        "_internal_originalTime": 1689846037000
    },
    {
        "time": 1689846039000,
        "value": 139.4,
        "_internal_originalTime": 1689846039000
    },
    {
        "time": 1689846041000,
        "value": 139.401,
        "_internal_originalTime": 1689846041000
    },
    {
        "time": 1689846042000,
        "value": 139.403,
        "_internal_originalTime": 1689846042000
    },
    {
        "time": 1689846043000,
        "value": 139.408,
        "_internal_originalTime": 1689846043000
    },
    {
        "time": 1689846045000,
        "value": 139.426,
        "_internal_originalTime": 1689846045000
    },
    {
        "time": 1689846046000,
        "value": 139.428,
        "_internal_originalTime": 1689846046000
    },
    {
        "time": 1689846047000,
        "value": 139.43,
        "_internal_originalTime": 1689846047000
    },
    {
        "time": 1689846049000,
        "value": 139.432,
        "_internal_originalTime": 1689846049000
    },
    {
        "time": 1689846050000,
        "value": 139.439,
        "_internal_originalTime": 1689846050000
    },
    {
        "time": 1689846051000,
        "value": 139.438,
        "_internal_originalTime": 1689846051000
    },
    {
        "time": 1689846053000,
        "value": 139.437,
        "_internal_originalTime": 1689846053000
    },
    {
        "time": 1689846054000,
        "value": 139.436,
        "_internal_originalTime": 1689846054000
    },
    {
        "time": 1689846055000,
        "value": 139.437,
        "_internal_originalTime": 1689846055000
    },
    {
        "time": 1689846056000,
        "value": 139.434,
        "_internal_originalTime": 1689846056000
    },
    {
        "time": 1689846058000,
        "value": 139.438,
        "_internal_originalTime": 1689846058000
    },
    {
        "time": 1689846060000,
        "value": 139.438,
        "_internal_originalTime": 1689846060000
    },
    {
        "time": 1689846061000,
        "value": 139.41,
        "_internal_originalTime": 1689846061000
    },
    {
        "time": 1689846063000,
        "value": 139.428,
        "_internal_originalTime": 1689846063000
    },
    {
        "time": 1689846064000,
        "value": 139.433,
        "_internal_originalTime": 1689846064000
    },
    {
        "time": 1689846065000,
        "value": 139.431,
        "_internal_originalTime": 1689846065000
    },
    {
        "time": 1689846067000,
        "value": 139.43,
        "_internal_originalTime": 1689846067000
    },
    {
        "time": 1689846068000,
        "value": 139.429,
        "_internal_originalTime": 1689846068000
    },
    {
        "time": 1689846069000,
        "value": 139.429,
        "_internal_originalTime": 1689846069000
    },
    {
        "time": 1689846071000,
        "value": 139.429,
        "_internal_originalTime": 1689846071000
    },
    {
        "time": 1689846073000,
        "value": 139.428,
        "_internal_originalTime": 1689846073000
    },
    {
        "time": 1689846074000,
        "value": 139.427,
        "_internal_originalTime": 1689846074000
    },
    {
        "time": 1689846077000,
        "value": 139.424,
        "_internal_originalTime": 1689846077000
    },
    {
        "time": 1689846078000,
        "value": 139.425,
        "_internal_originalTime": 1689846078000
    },
    {
        "time": 1689846080000,
        "value": 139.427,
        "_internal_originalTime": 1689846080000
    },
    {
        "time": 1689846081000,
        "value": 139.427,
        "_internal_originalTime": 1689846081000
    },
    {
        "time": 1689846082000,
        "value": 139.428,
        "_internal_originalTime": 1689846082000
    },
    {
        "time": 1689846084000,
        "value": 139.425,
        "_internal_originalTime": 1689846084000
    },
    {
        "time": 1689846086000,
        "value": 139.428,
        "_internal_originalTime": 1689846086000
    },
    {
        "time": 1689846087000,
        "value": 139.428,
        "_internal_originalTime": 1689846087000
    },
    {
        "time": 1689846088000,
        "value": 139.425,
        "_internal_originalTime": 1689846088000
    },
    {
        "time": 1689846090000,
        "value": 139.428,
        "_internal_originalTime": 1689846090000
    },
    {
        "time": 1689846091000,
        "value": 139.427,
        "_internal_originalTime": 1689846091000
    },
    {
        "time": 1689846092000,
        "value": 139.425,
        "_internal_originalTime": 1689846092000
    },
    {
        "time": 1689846093000,
        "value": 139.431,
        "_internal_originalTime": 1689846093000
    },
    {
        "time": 1689846096000,
        "value": 139.428,
        "_internal_originalTime": 1689846096000
    },
    {
        "time": 1689846097000,
        "value": 139.428,
        "_internal_originalTime": 1689846097000
    },
    {
        "time": 1689846098000,
        "value": 139.428,
        "_internal_originalTime": 1689846098000
    },
    {
        "time": 1689846101000,
        "value": 139.434,
        "_internal_originalTime": 1689846101000
    },
    {
        "time": 1689846102000,
        "value": 139.429,
        "_internal_originalTime": 1689846102000
    },
    {
        "time": 1689846103000,
        "value": 139.432,
        "_internal_originalTime": 1689846103000
    },
    {
        "time": 1689846105000,
        "value": 139.424,
        "_internal_originalTime": 1689846105000
    },
    {
        "time": 1689846106000,
        "value": 139.418,
        "_internal_originalTime": 1689846106000
    },
    {
        "time": 1689846107000,
        "value": 139.418,
        "_internal_originalTime": 1689846107000
    },
    {
        "time": 1689846108000,
        "value": 139.418,
        "_internal_originalTime": 1689846108000
    },
    {
        "time": 1689846110000,
        "value": 139.433,
        "_internal_originalTime": 1689846110000
    },
    {
        "time": 1689846111000,
        "value": 139.436,
        "_internal_originalTime": 1689846111000
    },
    {
        "time": 1689846112000,
        "value": 139.435,
        "_internal_originalTime": 1689846112000
    },
    {
        "time": 1689846113000,
        "value": 139.436,
        "_internal_originalTime": 1689846113000
    },
    {
        "time": 1689846115000,
        "value": 139.435,
        "_internal_originalTime": 1689846115000
    },
    {
        "time": 1689846116000,
        "value": 139.435,
        "_internal_originalTime": 1689846116000
    },
    {
        "time": 1689846117000,
        "value": 139.435,
        "_internal_originalTime": 1689846117000
    },
    {
        "time": 1689846119000,
        "value": 139.426,
        "_internal_originalTime": 1689846119000
    },
    {
        "time": 1689846120000,
        "value": 139.426,
        "_internal_originalTime": 1689846120000
    },
    {
        "time": 1689846122000,
        "value": 139.431,
        "_internal_originalTime": 1689846122000
    },
    {
        "time": 1689846123000,
        "value": 139.436,
        "_internal_originalTime": 1689846123000
    },
    {
        "time": 1689846124000,
        "value": 139.443,
        "_internal_originalTime": 1689846124000
    },
    {
        "time": 1689846125000,
        "value": 139.442,
        "_internal_originalTime": 1689846125000
    },
    {
        "time": 1689846126000,
        "value": 139.441,
        "_internal_originalTime": 1689846126000
    },
    {
        "time": 1689846127000,
        "value": 139.439,
        "_internal_originalTime": 1689846127000
    },
    {
        "time": 1689846129000,
        "value": 139.442,
        "_internal_originalTime": 1689846129000
    },
    {
        "time": 1689846130000,
        "value": 139.442,
        "_internal_originalTime": 1689846130000
    },
    {
        "time": 1689846132000,
        "value": 139.443,
        "_internal_originalTime": 1689846132000
    },
    {
        "time": 1689846133000,
        "value": 139.444,
        "_internal_originalTime": 1689846133000
    },
    {
        "time": 1689846135000,
        "value": 139.445,
        "_internal_originalTime": 1689846135000
    },
    {
        "time": 1689846136000,
        "value": 139.443,
        "_internal_originalTime": 1689846136000
    },
    {
        "time": 1689846137000,
        "value": 139.44,
        "_internal_originalTime": 1689846137000
    },
    {
        "time": 1689846138000,
        "value": 139.443,
        "_internal_originalTime": 1689846138000
    },
    {
        "time": 1689846140000,
        "value": 139.438,
        "_internal_originalTime": 1689846140000
    },
    {
        "time": 1689846141000,
        "value": 139.432,
        "_internal_originalTime": 1689846141000
    },
    {
        "time": 1689846142000,
        "value": 139.438,
        "_internal_originalTime": 1689846142000
    },
    {
        "time": 1689846143000,
        "value": 139.442,
        "_internal_originalTime": 1689846143000
    },
    {
        "time": 1689846144000,
        "value": 139.44,
        "_internal_originalTime": 1689846144000
    },
    {
        "time": 1689846145000,
        "value": 139.436,
        "_internal_originalTime": 1689846145000
    },
    {
        "time": 1689846146000,
        "value": 139.435,
        "_internal_originalTime": 1689846146000
    },
    {
        "time": 1689846148000,
        "value": 139.436,
        "_internal_originalTime": 1689846148000
    },
    {
        "time": 1689846150000,
        "value": 139.436,
        "_internal_originalTime": 1689846150000
    },
    {
        "time": 1689846151000,
        "value": 139.442,
        "_internal_originalTime": 1689846151000
    },
    {
        "time": 1689846153000,
        "value": 139.438,
        "_internal_originalTime": 1689846153000
    },
    {
        "time": 1689846154000,
        "value": 139.437,
        "_internal_originalTime": 1689846154000
    },
    {
        "time": 1689846156000,
        "value": 139.43,
        "_internal_originalTime": 1689846156000
    },
    {
        "time": 1689846157000,
        "value": 139.433,
        "_internal_originalTime": 1689846157000
    },
    {
        "time": 1689846158000,
        "value": 139.433,
        "_internal_originalTime": 1689846158000
    },
    {
        "time": 1689846160000,
        "value": 139.43,
        "_internal_originalTime": 1689846160000
    },
    {
        "time": 1689846161000,
        "value": 139.435,
        "_internal_originalTime": 1689846161000
    },
    {
        "time": 1689846162000,
        "value": 139.438,
        "_internal_originalTime": 1689846162000
    },
    {
        "time": 1689846163000,
        "value": 139.434,
        "_internal_originalTime": 1689846163000
    },
    {
        "time": 1689846165000,
        "value": 139.431,
        "_internal_originalTime": 1689846165000
    },
    {
        "time": 1689846166000,
        "value": 139.434,
        "_internal_originalTime": 1689846166000
    },
    {
        "time": 1689846167000,
        "value": 139.433,
        "_internal_originalTime": 1689846167000
    },
    {
        "time": 1689846168000,
        "value": 139.432,
        "_internal_originalTime": 1689846168000
    },
    {
        "time": 1689846169000,
        "value": 139.434,
        "_internal_originalTime": 1689846169000
    },
    {
        "time": 1689846171000,
        "value": 139.437,
        "_internal_originalTime": 1689846171000
    },
    {
        "time": 1689846172000,
        "value": 139.437,
        "_internal_originalTime": 1689846172000
    },
    {
        "time": 1689846173000,
        "value": 139.435,
        "_internal_originalTime": 1689846173000
    },
    {
        "time": 1689846175000,
        "value": 139.429,
        "_internal_originalTime": 1689846175000
    },
    {
        "time": 1689846176000,
        "value": 139.426,
        "_internal_originalTime": 1689846176000
    },
    {
        "time": 1689846177000,
        "value": 139.43,
        "_internal_originalTime": 1689846177000
    },
    {
        "time": 1689846179000,
        "value": 139.429,
        "_internal_originalTime": 1689846179000
    },
    {
        "time": 1689846180000,
        "value": 139.429,
        "_internal_originalTime": 1689846180000
    },
    {
        "time": 1689846182000,
        "value": 139.428,
        "_internal_originalTime": 1689846182000
    },
    {
        "time": 1689846183000,
        "value": 139.424,
        "_internal_originalTime": 1689846183000
    },
    {
        "time": 1689846184000,
        "value": 139.422,
        "_internal_originalTime": 1689846184000
    },
    {
        "time": 1689846186000,
        "value": 139.419,
        "_internal_originalTime": 1689846186000
    },
    {
        "time": 1689846187000,
        "value": 139.414,
        "_internal_originalTime": 1689846187000
    },
    {
        "time": 1689846188000,
        "value": 139.419,
        "_internal_originalTime": 1689846188000
    },
    {
        "time": 1689846190000,
        "value": 139.418,
        "_internal_originalTime": 1689846190000
    },
    {
        "time": 1689846191000,
        "value": 139.417,
        "_internal_originalTime": 1689846191000
    },
    {
        "time": 1689846192000,
        "value": 139.414,
        "_internal_originalTime": 1689846192000
    },
    {
        "time": 1689846193000,
        "value": 139.411,
        "_internal_originalTime": 1689846193000
    },
    {
        "time": 1689846195000,
        "value": 139.412,
        "_internal_originalTime": 1689846195000
    },
    {
        "time": 1689846196000,
        "value": 139.412,
        "_internal_originalTime": 1689846196000
    },
    {
        "time": 1689846197000,
        "value": 139.411,
        "_internal_originalTime": 1689846197000
    },
    {
        "time": 1689846200000,
        "value": 139.411,
        "_internal_originalTime": 1689846200000
    },
    {
        "time": 1689846201000,
        "value": 139.406,
        "_internal_originalTime": 1689846201000
    },
    {
        "time": 1689846203000,
        "value": 139.402,
        "_internal_originalTime": 1689846203000
    },
    {
        "time": 1689846204000,
        "value": 139.402,
        "_internal_originalTime": 1689846204000
    },
    {
        "time": 1689846205000,
        "value": 139.402,
        "_internal_originalTime": 1689846205000
    },
    {
        "time": 1689846206000,
        "value": 139.402,
        "_internal_originalTime": 1689846206000
    },
    {
        "time": 1689846207000,
        "value": 139.402,
        "_internal_originalTime": 1689846207000
    },
    {
        "time": 1689846208000,
        "value": 139.399,
        "_internal_originalTime": 1689846208000
    },
    {
        "time": 1689846210000,
        "value": 139.399,
        "_internal_originalTime": 1689846210000
    },
    {
        "time": 1689846211000,
        "value": 139.401,
        "_internal_originalTime": 1689846211000
    },
    {
        "time": 1689846212000,
        "value": 139.405,
        "_internal_originalTime": 1689846212000
    },
    {
        "time": 1689846213000,
        "value": 139.4,
        "_internal_originalTime": 1689846213000
    },
    {
        "time": 1689846214000,
        "value": 139.402,
        "_internal_originalTime": 1689846214000
    },
    {
        "time": 1689846216000,
        "value": 139.406,
        "_internal_originalTime": 1689846216000
    },
    {
        "time": 1689846217000,
        "value": 139.404,
        "_internal_originalTime": 1689846217000
    },
    {
        "time": 1689846220000,
        "value": 139.402,
        "_internal_originalTime": 1689846220000
    },
    {
        "time": 1689846221000,
        "value": 139.402,
        "_internal_originalTime": 1689846221000
    },
    {
        "time": 1689846223000,
        "value": 139.402,
        "_internal_originalTime": 1689846223000
    },
    {
        "time": 1689846225000,
        "value": 139.403,
        "_internal_originalTime": 1689846225000
    },
    {
        "time": 1689846227000,
        "value": 139.403,
        "_internal_originalTime": 1689846227000
    },
    {
        "time": 1689846229000,
        "value": 139.402,
        "_internal_originalTime": 1689846229000
    },
    {
        "time": 1689846231000,
        "value": 139.404,
        "_internal_originalTime": 1689846231000
    },
    {
        "time": 1689846232000,
        "value": 139.406,
        "_internal_originalTime": 1689846232000
    },
    {
        "time": 1689846233000,
        "value": 139.402,
        "_internal_originalTime": 1689846233000
    },
    {
        "time": 1689846235000,
        "value": 139.4,
        "_internal_originalTime": 1689846235000
    },
    {
        "time": 1689846236000,
        "value": 139.403,
        "_internal_originalTime": 1689846236000
    },
    {
        "time": 1689846239000,
        "value": 139.406,
        "_internal_originalTime": 1689846239000
    },
    {
        "time": 1689846240000,
        "value": 139.405,
        "_internal_originalTime": 1689846240000
    },
    {
        "time": 1689846241000,
        "value": 139.405,
        "_internal_originalTime": 1689846241000
    },
    {
        "time": 1689846242000,
        "value": 139.4,
        "_internal_originalTime": 1689846242000
    },
    {
        "time": 1689846243000,
        "value": 139.4,
        "_internal_originalTime": 1689846243000
    },
    {
        "time": 1689846245000,
        "value": 139.406,
        "_internal_originalTime": 1689846245000
    },
    {
        "time": 1689846246000,
        "value": 139.402,
        "_internal_originalTime": 1689846246000
    },
    {
        "time": 1689846248000,
        "value": 139.402,
        "_internal_originalTime": 1689846248000
    },
    {
        "time": 1689846249000,
        "value": 139.406,
        "_internal_originalTime": 1689846249000
    },
    {
        "time": 1689846250000,
        "value": 139.402,
        "_internal_originalTime": 1689846250000
    },
    {
        "time": 1689846252000,
        "value": 139.404,
        "_internal_originalTime": 1689846252000
    },
    {
        "time": 1689846253000,
        "value": 139.402,
        "_internal_originalTime": 1689846253000
    },
    {
        "time": 1689846254000,
        "value": 139.403,
        "_internal_originalTime": 1689846254000
    },
    {
        "time": 1689846256000,
        "value": 139.406,
        "_internal_originalTime": 1689846256000
    },
    {
        "time": 1689846257000,
        "value": 139.407,
        "_internal_originalTime": 1689846257000
    },
    {
        "time": 1689846258000,
        "value": 139.412,
        "_internal_originalTime": 1689846258000
    },
    {
        "time": 1689846259000,
        "value": 139.412,
        "_internal_originalTime": 1689846259000
    },
    {
        "time": 1689846260000,
        "value": 139.414,
        "_internal_originalTime": 1689846260000
    },
    {
        "time": 1689846261000,
        "value": 139.415,
        "_internal_originalTime": 1689846261000
    },
    {
        "time": 1689846263000,
        "value": 139.418,
        "_internal_originalTime": 1689846263000
    },
    {
        "time": 1689846264000,
        "value": 139.414,
        "_internal_originalTime": 1689846264000
    },
    {
        "time": 1689846265000,
        "value": 139.419,
        "_internal_originalTime": 1689846265000
    },
    {
        "time": 1689846267000,
        "value": 139.414,
        "_internal_originalTime": 1689846267000
    },
    {
        "time": 1689846268000,
        "value": 139.415,
        "_internal_originalTime": 1689846268000
    },
    {
        "time": 1689846271000,
        "value": 139.419,
        "_internal_originalTime": 1689846271000
    },
    {
        "time": 1689846273000,
        "value": 139.419,
        "_internal_originalTime": 1689846273000
    },
    {
        "time": 1689846275000,
        "value": 139.414,
        "_internal_originalTime": 1689846275000
    },
    {
        "time": 1689846277000,
        "value": 139.415,
        "_internal_originalTime": 1689846277000
    },
    {
        "time": 1689846279000,
        "value": 139.414,
        "_internal_originalTime": 1689846279000
    },
    {
        "time": 1689846280000,
        "value": 139.413,
        "_internal_originalTime": 1689846280000
    },
    {
        "time": 1689846281000,
        "value": 139.416,
        "_internal_originalTime": 1689846281000
    },
    {
        "time": 1689846284000,
        "value": 139.415,
        "_internal_originalTime": 1689846284000
    },
    {
        "time": 1689846285000,
        "value": 139.419,
        "_internal_originalTime": 1689846285000
    },
    {
        "time": 1689846286000,
        "value": 139.415,
        "_internal_originalTime": 1689846286000
    },
    {
        "time": 1689846287000,
        "value": 139.421,
        "_internal_originalTime": 1689846287000
    },
    {
        "time": 1689846288000,
        "value": 139.42,
        "_internal_originalTime": 1689846288000
    },
    {
        "time": 1689846290000,
        "value": 139.428,
        "_internal_originalTime": 1689846290000
    },
    {
        "time": 1689846291000,
        "value": 139.428,
        "_internal_originalTime": 1689846291000
    },
    {
        "time": 1689846292000,
        "value": 139.423,
        "_internal_originalTime": 1689846292000
    },
    {
        "time": 1689846293000,
        "value": 139.423,
        "_internal_originalTime": 1689846293000
    },
    {
        "time": 1689846295000,
        "value": 139.424,
        "_internal_originalTime": 1689846295000
    },
    {
        "time": 1689846296000,
        "value": 139.423,
        "_internal_originalTime": 1689846296000
    },
    {
        "time": 1689846297000,
        "value": 139.426,
        "_internal_originalTime": 1689846297000
    },
    {
        "time": 1689846300000,
        "value": 139.427,
        "_internal_originalTime": 1689846300000
    },
    {
        "time": 1689846301000,
        "value": 139.4,
        "_internal_originalTime": 1689846301000
    },
    {
        "time": 1689846303000,
        "value": 139.422,
        "_internal_originalTime": 1689846303000
    },
    {
        "time": 1689846306000,
        "value": 139.427,
        "_internal_originalTime": 1689846306000
    },
    {
        "time": 1689846308000,
        "value": 139.429,
        "_internal_originalTime": 1689846308000
    },
    {
        "time": 1689846309000,
        "value": 139.438,
        "_internal_originalTime": 1689846309000
    },
    {
        "time": 1689846310000,
        "value": 139.439,
        "_internal_originalTime": 1689846310000
    },
    {
        "time": 1689846311000,
        "value": 139.438,
        "_internal_originalTime": 1689846311000
    },
    {
        "time": 1689846313000,
        "value": 139.441,
        "_internal_originalTime": 1689846313000
    },
    {
        "time": 1689846314000,
        "value": 139.446,
        "_internal_originalTime": 1689846314000
    },
    {
        "time": 1689846315000,
        "value": 139.443,
        "_internal_originalTime": 1689846315000
    },
    {
        "time": 1689846318000,
        "value": 139.438,
        "_internal_originalTime": 1689846318000
    },
    {
        "time": 1689846319000,
        "value": 139.445,
        "_internal_originalTime": 1689846319000
    },
    {
        "time": 1689846320000,
        "value": 139.441,
        "_internal_originalTime": 1689846320000
    },
    {
        "time": 1689846321000,
        "value": 139.44,
        "_internal_originalTime": 1689846321000
    },
    {
        "time": 1689846323000,
        "value": 139.441,
        "_internal_originalTime": 1689846323000
    },
    {
        "time": 1689846324000,
        "value": 139.44,
        "_internal_originalTime": 1689846324000
    },
    {
        "time": 1689846325000,
        "value": 139.441,
        "_internal_originalTime": 1689846325000
    },
    {
        "time": 1689846348000,
        "value": 139.446,
        "_internal_originalTime": 1689846348000
    },
    {
        "time": 1689846349000,
        "value": 139.447,
        "_internal_originalTime": 1689846349000
    },
    {
        "time": 1689846350000,
        "value": 139.443,
        "_internal_originalTime": 1689846350000
    },
    {
        "time": 1689846352000,
        "value": 139.442,
        "_internal_originalTime": 1689846352000
    },
    {
        "time": 1689846353000,
        "value": 139.446,
        "_internal_originalTime": 1689846353000
    },
    {
        "time": 1689846354000,
        "value": 139.442,
        "_internal_originalTime": 1689846354000
    },
    {
        "time": 1689846356000,
        "value": 139.443,
        "_internal_originalTime": 1689846356000
    },
    {
        "time": 1689846357000,
        "value": 139.447,
        "_internal_originalTime": 1689846357000
    },
    {
        "time": 1689846359000,
        "value": 139.442,
        "_internal_originalTime": 1689846359000
    },
    {
        "time": 1689846360000,
        "value": 139.44,
        "_internal_originalTime": 1689846360000
    },
    {
        "time": 1689846361000,
        "value": 139.44,
        "_internal_originalTime": 1689846361000
    },
    {
        "time": 1689846363000,
        "value": 139.44,
        "_internal_originalTime": 1689846363000
    },
    {
        "time": 1689846365000,
        "value": 139.447,
        "_internal_originalTime": 1689846365000
    },
    {
        "time": 1689846366000,
        "value": 139.446,
        "_internal_originalTime": 1689846366000
    },
    {
        "time": 1689846367000,
        "value": 139.445,
        "_internal_originalTime": 1689846367000
    },
    {
        "time": 1689846368000,
        "value": 139.446,
        "_internal_originalTime": 1689846368000
    },
    {
        "time": 1689846370000,
        "value": 139.44,
        "_internal_originalTime": 1689846370000
    },
    {
        "time": 1689846371000,
        "value": 139.437,
        "_internal_originalTime": 1689846371000
    },
    {
        "time": 1689846372000,
        "value": 139.435,
        "_internal_originalTime": 1689846372000
    },
    {
        "time": 1689846373000,
        "value": 139.434,
        "_internal_originalTime": 1689846373000
    },
    {
        "time": 1689846375000,
        "value": 139.435,
        "_internal_originalTime": 1689846375000
    },
    {
        "time": 1689846376000,
        "value": 139.435,
        "_internal_originalTime": 1689846376000
    },
    {
        "time": 1689846378000,
        "value": 139.441,
        "_internal_originalTime": 1689846378000
    },
    {
        "time": 1689846379000,
        "value": 139.439,
        "_internal_originalTime": 1689846379000
    },
    {
        "time": 1689846380000,
        "value": 139.442,
        "_internal_originalTime": 1689846380000
    },
    {
        "time": 1689846383000,
        "value": 139.447,
        "_internal_originalTime": 1689846383000
    },
    {
        "time": 1689846384000,
        "value": 139.446,
        "_internal_originalTime": 1689846384000
    },
    {
        "time": 1689846385000,
        "value": 139.44,
        "_internal_originalTime": 1689846385000
    },
    {
        "time": 1689846386000,
        "value": 139.444,
        "_internal_originalTime": 1689846386000
    },
    {
        "time": 1689846388000,
        "value": 139.446,
        "_internal_originalTime": 1689846388000
    },
    {
        "time": 1689846389000,
        "value": 139.445,
        "_internal_originalTime": 1689846389000
    },
    {
        "time": 1689846390000,
        "value": 139.446,
        "_internal_originalTime": 1689846390000
    },
    {
        "time": 1689846391000,
        "value": 139.442,
        "_internal_originalTime": 1689846391000
    },
    {
        "time": 1689846392000,
        "value": 139.447,
        "_internal_originalTime": 1689846392000
    },
    {
        "time": 1689846395000,
        "value": 139.44,
        "_internal_originalTime": 1689846395000
    },
    {
        "time": 1689846396000,
        "value": 139.444,
        "_internal_originalTime": 1689846396000
    },
    {
        "time": 1689846397000,
        "value": 139.442,
        "_internal_originalTime": 1689846397000
    },
    {
        "time": 1689846399000,
        "value": 139.447,
        "_internal_originalTime": 1689846399000
    },
    {
        "time": 1689846400000,
        "value": 139.445,
        "_internal_originalTime": 1689846400000
    },
    {
        "time": 1689846401000,
        "value": 139.44,
        "_internal_originalTime": 1689846401000
    },
    {
        "time": 1689846403000,
        "value": 139.442,
        "_internal_originalTime": 1689846403000
    },
    {
        "time": 1689846404000,
        "value": 139.443,
        "_internal_originalTime": 1689846404000
    },
    {
        "time": 1689846405000,
        "value": 139.442,
        "_internal_originalTime": 1689846405000
    },
    {
        "time": 1689846406000,
        "value": 139.448,
        "_internal_originalTime": 1689846406000
    },
    {
        "time": 1689846407000,
        "value": 139.448,
        "_internal_originalTime": 1689846407000
    },
    {
        "time": 1689846408000,
        "value": 139.442,
        "_internal_originalTime": 1689846408000
    },
    {
        "time": 1689846409000,
        "value": 139.44,
        "_internal_originalTime": 1689846409000
    },
    {
        "time": 1689846411000,
        "value": 139.444,
        "_internal_originalTime": 1689846411000
    },
    {
        "time": 1689846412000,
        "value": 139.446,
        "_internal_originalTime": 1689846412000
    },
    {
        "time": 1689846414000,
        "value": 139.448,
        "_internal_originalTime": 1689846414000
    },
    {
        "time": 1689846415000,
        "value": 139.448,
        "_internal_originalTime": 1689846415000
    },
    {
        "time": 1689846419000,
        "value": 139.442,
        "_internal_originalTime": 1689846419000
    },
    {
        "time": 1689846420000,
        "value": 139.446,
        "_internal_originalTime": 1689846420000
    },
    {
        "time": 1689846421000,
        "value": 139.448,
        "_internal_originalTime": 1689846421000
    },
    {
        "time": 1689846422000,
        "value": 139.448,
        "_internal_originalTime": 1689846422000
    },
    {
        "time": 1689846423000,
        "value": 139.444,
        "_internal_originalTime": 1689846423000
    },
    {
        "time": 1689846424000,
        "value": 139.442,
        "_internal_originalTime": 1689846424000
    },
    {
        "time": 1689846426000,
        "value": 139.441,
        "_internal_originalTime": 1689846426000
    },
    {
        "time": 1689846427000,
        "value": 139.437,
        "_internal_originalTime": 1689846427000
    },
    {
        "time": 1689846428000,
        "value": 139.436,
        "_internal_originalTime": 1689846428000
    },
    {
        "time": 1689846429000,
        "value": 139.437,
        "_internal_originalTime": 1689846429000
    },
    {
        "time": 1689846431000,
        "value": 139.433,
        "_internal_originalTime": 1689846431000
    },
    {
        "time": 1689846432000,
        "value": 139.433,
        "_internal_originalTime": 1689846432000
    },
    {
        "time": 1689846434000,
        "value": 139.438,
        "_internal_originalTime": 1689846434000
    },
    {
        "time": 1689846436000,
        "value": 139.435,
        "_internal_originalTime": 1689846436000
    },
    {
        "time": 1689846437000,
        "value": 139.434,
        "_internal_originalTime": 1689846437000
    },
    {
        "time": 1689846439000,
        "value": 139.434,
        "_internal_originalTime": 1689846439000
    },
    {
        "time": 1689846442000,
        "value": 139.436,
        "_internal_originalTime": 1689846442000
    },
    {
        "time": 1689846443000,
        "value": 139.432,
        "_internal_originalTime": 1689846443000
    },
    {
        "time": 1689846445000,
        "value": 139.436,
        "_internal_originalTime": 1689846445000
    },
    {
        "time": 1689846446000,
        "value": 139.437,
        "_internal_originalTime": 1689846446000
    },
    {
        "time": 1689846448000,
        "value": 139.434,
        "_internal_originalTime": 1689846448000
    },
    {
        "time": 1689846450000,
        "value": 139.432,
        "_internal_originalTime": 1689846450000
    },
    {
        "time": 1689846452000,
        "value": 139.435,
        "_internal_originalTime": 1689846452000
    },
    {
        "time": 1689846454000,
        "value": 139.438,
        "_internal_originalTime": 1689846454000
    },
    {
        "time": 1689846455000,
        "value": 139.432,
        "_internal_originalTime": 1689846455000
    },
    {
        "time": 1689846459000,
        "value": 139.437,
        "_internal_originalTime": 1689846459000
    },
    {
        "time": 1689846461000,
        "value": 139.442,
        "_internal_originalTime": 1689846461000
    },
    {
        "time": 1689846462000,
        "value": 139.442,
        "_internal_originalTime": 1689846462000
    },
    {
        "time": 1689846463000,
        "value": 139.446,
        "_internal_originalTime": 1689846463000
    },
    {
        "time": 1689846464000,
        "value": 139.446,
        "_internal_originalTime": 1689846464000
    },
    {
        "time": 1689846465000,
        "value": 139.442,
        "_internal_originalTime": 1689846465000
    },
    {
        "time": 1689846466000,
        "value": 139.448,
        "_internal_originalTime": 1689846466000
    },
    {
        "time": 1689846467000,
        "value": 139.456,
        "_internal_originalTime": 1689846467000
    },
    {
        "time": 1689846468000,
        "value": 139.45,
        "_internal_originalTime": 1689846468000
    },
    {
        "time": 1689846470000,
        "value": 139.45,
        "_internal_originalTime": 1689846470000
    },
    {
        "time": 1689846471000,
        "value": 139.456,
        "_internal_originalTime": 1689846471000
    },
    {
        "time": 1689846472000,
        "value": 139.454,
        "_internal_originalTime": 1689846472000
    },
    {
        "time": 1689846474000,
        "value": 139.448,
        "_internal_originalTime": 1689846474000
    },
    {
        "time": 1689846475000,
        "value": 139.449,
        "_internal_originalTime": 1689846475000
    },
    {
        "time": 1689846477000,
        "value": 139.448,
        "_internal_originalTime": 1689846477000
    },
    {
        "time": 1689846478000,
        "value": 139.442,
        "_internal_originalTime": 1689846478000
    },
    {
        "time": 1689846480000,
        "value": 139.444,
        "_internal_originalTime": 1689846480000
    },
    {
        "time": 1689846481000,
        "value": 139.449,
        "_internal_originalTime": 1689846481000
    },
    {
        "time": 1689846484000,
        "value": 139.449,
        "_internal_originalTime": 1689846484000
    },
    {
        "time": 1689846485000,
        "value": 139.45,
        "_internal_originalTime": 1689846485000
    },
    {
        "time": 1689846486000,
        "value": 139.45,
        "_internal_originalTime": 1689846486000
    },
    {
        "time": 1689846487000,
        "value": 139.454,
        "_internal_originalTime": 1689846487000
    },
    {
        "time": 1689846488000,
        "value": 139.458,
        "_internal_originalTime": 1689846488000
    },
    {
        "time": 1689846490000,
        "value": 139.451,
        "_internal_originalTime": 1689846490000
    },
    {
        "time": 1689846491000,
        "value": 139.465,
        "_internal_originalTime": 1689846491000
    },
    {
        "time": 1689846492000,
        "value": 139.458,
        "_internal_originalTime": 1689846492000
    },
    {
        "time": 1689846493000,
        "value": 139.461,
        "_internal_originalTime": 1689846493000
    },
    {
        "time": 1689846495000,
        "value": 139.467,
        "_internal_originalTime": 1689846495000
    },
    {
        "time": 1689846496000,
        "value": 139.465,
        "_internal_originalTime": 1689846496000
    },
    {
        "time": 1689846498000,
        "value": 139.469,
        "_internal_originalTime": 1689846498000
    },
    {
        "time": 1689846499000,
        "value": 139.464,
        "_internal_originalTime": 1689846499000
    },
    {
        "time": 1689846500000,
        "value": 139.462,
        "_internal_originalTime": 1689846500000
    },
    {
        "time": 1689846501000,
        "value": 139.467,
        "_internal_originalTime": 1689846501000
    },
    {
        "time": 1689846503000,
        "value": 139.466,
        "_internal_originalTime": 1689846503000
    },
    {
        "time": 1689846504000,
        "value": 139.467,
        "_internal_originalTime": 1689846504000
    },
    {
        "time": 1689846505000,
        "value": 139.462,
        "_internal_originalTime": 1689846505000
    },
    {
        "time": 1689846506000,
        "value": 139.468,
        "_internal_originalTime": 1689846506000
    },
    {
        "time": 1689846508000,
        "value": 139.462,
        "_internal_originalTime": 1689846508000
    },
    {
        "time": 1689846511000,
        "value": 139.468,
        "_internal_originalTime": 1689846511000
    },
    {
        "time": 1689846512000,
        "value": 139.462,
        "_internal_originalTime": 1689846512000
    },
    {
        "time": 1689846513000,
        "value": 139.466,
        "_internal_originalTime": 1689846513000
    },
    {
        "time": 1689846514000,
        "value": 139.464,
        "_internal_originalTime": 1689846514000
    },
    {
        "time": 1689846515000,
        "value": 139.462,
        "_internal_originalTime": 1689846515000
    },
    {
        "time": 1689846517000,
        "value": 139.467,
        "_internal_originalTime": 1689846517000
    },
    {
        "time": 1689846518000,
        "value": 139.452,
        "_internal_originalTime": 1689846518000
    },
    {
        "time": 1689846519000,
        "value": 139.458,
        "_internal_originalTime": 1689846519000
    },
    {
        "time": 1689846521000,
        "value": 139.454,
        "_internal_originalTime": 1689846521000
    },
    {
        "time": 1689846522000,
        "value": 139.454,
        "_internal_originalTime": 1689846522000
    },
    {
        "time": 1689846523000,
        "value": 139.459,
        "_internal_originalTime": 1689846523000
    },
    {
        "time": 1689846524000,
        "value": 139.455,
        "_internal_originalTime": 1689846524000
    },
    {
        "time": 1689846525000,
        "value": 139.457,
        "_internal_originalTime": 1689846525000
    },
    {
        "time": 1689846526000,
        "value": 139.457,
        "_internal_originalTime": 1689846526000
    },
    {
        "time": 1689846528000,
        "value": 139.455,
        "_internal_originalTime": 1689846528000
    },
    {
        "time": 1689846530000,
        "value": 139.456,
        "_internal_originalTime": 1689846530000
    },
    {
        "time": 1689846532000,
        "value": 139.452,
        "_internal_originalTime": 1689846532000
    },
    {
        "time": 1689846534000,
        "value": 139.454,
        "_internal_originalTime": 1689846534000
    },
    {
        "time": 1689846535000,
        "value": 139.452,
        "_internal_originalTime": 1689846535000
    },
    {
        "time": 1689846536000,
        "value": 139.458,
        "_internal_originalTime": 1689846536000
    },
    {
        "time": 1689846539000,
        "value": 139.458,
        "_internal_originalTime": 1689846539000
    },
    {
        "time": 1689846541000,
        "value": 139.43,
        "_internal_originalTime": 1689846541000
    },
    {
        "time": 1689846542000,
        "value": 139.457,
        "_internal_originalTime": 1689846542000
    },
    {
        "time": 1689846543000,
        "value": 139.457,
        "_internal_originalTime": 1689846543000
    },
    {
        "time": 1689846545000,
        "value": 139.455,
        "_internal_originalTime": 1689846545000
    },
    {
        "time": 1689846546000,
        "value": 139.459,
        "_internal_originalTime": 1689846546000
    },
    {
        "time": 1689846547000,
        "value": 139.456,
        "_internal_originalTime": 1689846547000
    },
    {
        "time": 1689846548000,
        "value": 139.456,
        "_internal_originalTime": 1689846548000
    },
    {
        "time": 1689846550000,
        "value": 139.461,
        "_internal_originalTime": 1689846550000
    },
    {
        "time": 1689846551000,
        "value": 139.459,
        "_internal_originalTime": 1689846551000
    },
    {
        "time": 1689846552000,
        "value": 139.459,
        "_internal_originalTime": 1689846552000
    },
    {
        "time": 1689846554000,
        "value": 139.456,
        "_internal_originalTime": 1689846554000
    },
    {
        "time": 1689846555000,
        "value": 139.457,
        "_internal_originalTime": 1689846555000
    },
    {
        "time": 1689846556000,
        "value": 139.455,
        "_internal_originalTime": 1689846556000
    },
    {
        "time": 1689846560000,
        "value": 139.444,
        "_internal_originalTime": 1689846560000
    },
    {
        "time": 1689846561000,
        "value": 139.444,
        "_internal_originalTime": 1689846561000
    },
    {
        "time": 1689846563000,
        "value": 139.445,
        "_internal_originalTime": 1689846563000
    },
    {
        "time": 1689846565000,
        "value": 139.448,
        "_internal_originalTime": 1689846565000
    },
    {
        "time": 1689846567000,
        "value": 139.442,
        "_internal_originalTime": 1689846567000
    },
    {
        "time": 1689846568000,
        "value": 139.447,
        "_internal_originalTime": 1689846568000
    },
    {
        "time": 1689846570000,
        "value": 139.447,
        "_internal_originalTime": 1689846570000
    },
    {
        "time": 1689846571000,
        "value": 139.442,
        "_internal_originalTime": 1689846571000
    },
    {
        "time": 1689846573000,
        "value": 139.442,
        "_internal_originalTime": 1689846573000
    },
    {
        "time": 1689846575000,
        "value": 139.443,
        "_internal_originalTime": 1689846575000
    },
    {
        "time": 1689846576000,
        "value": 139.442,
        "_internal_originalTime": 1689846576000
    },
    {
        "time": 1689846578000,
        "value": 139.442,
        "_internal_originalTime": 1689846578000
    },
    {
        "time": 1689846579000,
        "value": 139.444,
        "_internal_originalTime": 1689846579000
    },
    {
        "time": 1689846580000,
        "value": 139.448,
        "_internal_originalTime": 1689846580000
    },
    {
        "time": 1689846581000,
        "value": 139.444,
        "_internal_originalTime": 1689846581000
    },
    {
        "time": 1689846582000,
        "value": 139.447,
        "_internal_originalTime": 1689846582000
    },
    {
        "time": 1689846584000,
        "value": 139.447,
        "_internal_originalTime": 1689846584000
    },
    {
        "time": 1689846585000,
        "value": 139.442,
        "_internal_originalTime": 1689846585000
    },
    {
        "time": 1689846586000,
        "value": 139.446,
        "_internal_originalTime": 1689846586000
    },
    {
        "time": 1689846587000,
        "value": 139.443,
        "_internal_originalTime": 1689846587000
    },
    {
        "time": 1689846589000,
        "value": 139.444,
        "_internal_originalTime": 1689846589000
    },
    {
        "time": 1689846591000,
        "value": 139.447,
        "_internal_originalTime": 1689846591000
    },
    {
        "time": 1689846593000,
        "value": 139.449,
        "_internal_originalTime": 1689846593000
    },
    {
        "time": 1689846594000,
        "value": 139.446,
        "_internal_originalTime": 1689846594000
    },
    {
        "time": 1689846595000,
        "value": 139.445,
        "_internal_originalTime": 1689846595000
    },
    {
        "time": 1689846597000,
        "value": 139.443,
        "_internal_originalTime": 1689846597000
    },
    {
        "time": 1689846599000,
        "value": 139.445,
        "_internal_originalTime": 1689846599000
    },
    {
        "time": 1689846600000,
        "value": 139.446,
        "_internal_originalTime": 1689846600000
    },
    {
        "time": 1689846601000,
        "value": 139.444,
        "_internal_originalTime": 1689846601000
    },
    {
        "time": 1689846602000,
        "value": 139.445,
        "_internal_originalTime": 1689846602000
    },
    {
        "time": 1689846603000,
        "value": 139.442,
        "_internal_originalTime": 1689846603000
    },
    {
        "time": 1689846604000,
        "value": 139.446,
        "_internal_originalTime": 1689846604000
    },
    {
        "time": 1689846606000,
        "value": 139.445,
        "_internal_originalTime": 1689846606000
    },
    {
        "time": 1689846607000,
        "value": 139.447,
        "_internal_originalTime": 1689846607000
    },
    {
        "time": 1689846609000,
        "value": 139.445,
        "_internal_originalTime": 1689846609000
    },
    {
        "time": 1689846611000,
        "value": 139.447,
        "_internal_originalTime": 1689846611000
    },
    {
        "time": 1689846612000,
        "value": 139.447,
        "_internal_originalTime": 1689846612000
    },
    {
        "time": 1689846614000,
        "value": 139.447,
        "_internal_originalTime": 1689846614000
    },
    {
        "time": 1689846615000,
        "value": 139.445,
        "_internal_originalTime": 1689846615000
    },
    {
        "time": 1689846616000,
        "value": 139.443,
        "_internal_originalTime": 1689846616000
    },
    {
        "time": 1689846617000,
        "value": 139.446,
        "_internal_originalTime": 1689846617000
    },
    {
        "time": 1689846618000,
        "value": 139.443,
        "_internal_originalTime": 1689846618000
    },
    {
        "time": 1689846619000,
        "value": 139.444,
        "_internal_originalTime": 1689846619000
    },
    {
        "time": 1689846620000,
        "value": 139.442,
        "_internal_originalTime": 1689846620000
    },
    {
        "time": 1689846623000,
        "value": 139.435,
        "_internal_originalTime": 1689846623000
    },
    {
        "time": 1689846624000,
        "value": 139.434,
        "_internal_originalTime": 1689846624000
    },
    {
        "time": 1689846625000,
        "value": 139.435,
        "_internal_originalTime": 1689846625000
    },
    {
        "time": 1689846627000,
        "value": 139.434,
        "_internal_originalTime": 1689846627000
    },
    {
        "time": 1689846628000,
        "value": 139.428,
        "_internal_originalTime": 1689846628000
    },
    {
        "time": 1689846629000,
        "value": 139.425,
        "_internal_originalTime": 1689846629000
    },
    {
        "time": 1689846630000,
        "value": 139.424,
        "_internal_originalTime": 1689846630000
    },
    {
        "time": 1689846631000,
        "value": 139.424,
        "_internal_originalTime": 1689846631000
    },
    {
        "time": 1689846632000,
        "value": 139.421,
        "_internal_originalTime": 1689846632000
    },
    {
        "time": 1689846634000,
        "value": 139.426,
        "_internal_originalTime": 1689846634000
    },
    {
        "time": 1689846635000,
        "value": 139.425,
        "_internal_originalTime": 1689846635000
    },
    {
        "time": 1689846636000,
        "value": 139.426,
        "_internal_originalTime": 1689846636000
    },
    {
        "time": 1689846639000,
        "value": 139.425,
        "_internal_originalTime": 1689846639000
    },
    {
        "time": 1689846640000,
        "value": 139.422,
        "_internal_originalTime": 1689846640000
    },
    {
        "time": 1689846641000,
        "value": 139.423,
        "_internal_originalTime": 1689846641000
    },
    {
        "time": 1689846642000,
        "value": 139.422,
        "_internal_originalTime": 1689846642000
    },
    {
        "time": 1689846644000,
        "value": 139.422,
        "_internal_originalTime": 1689846644000
    },
    {
        "time": 1689846645000,
        "value": 139.427,
        "_internal_originalTime": 1689846645000
    },
    {
        "time": 1689846648000,
        "value": 139.428,
        "_internal_originalTime": 1689846648000
    },
    {
        "time": 1689846650000,
        "value": 139.429,
        "_internal_originalTime": 1689846650000
    },
    {
        "time": 1689846651000,
        "value": 139.432,
        "_internal_originalTime": 1689846651000
    },
    {
        "time": 1689846652000,
        "value": 139.438,
        "_internal_originalTime": 1689846652000
    },
    {
        "time": 1689846653000,
        "value": 139.437,
        "_internal_originalTime": 1689846653000
    },
    {
        "time": 1689846654000,
        "value": 139.436,
        "_internal_originalTime": 1689846654000
    },
    {
        "time": 1689846656000,
        "value": 139.434,
        "_internal_originalTime": 1689846656000
    },
    {
        "time": 1689846657000,
        "value": 139.437,
        "_internal_originalTime": 1689846657000
    },
    {
        "time": 1689846658000,
        "value": 139.432,
        "_internal_originalTime": 1689846658000
    },
    {
        "time": 1689846660000,
        "value": 139.437,
        "_internal_originalTime": 1689846660000
    },
    {
        "time": 1689846661000,
        "value": 139.434,
        "_internal_originalTime": 1689846661000
    },
    {
        "time": 1689846663000,
        "value": 139.436,
        "_internal_originalTime": 1689846663000
    },
    {
        "time": 1689846665000,
        "value": 139.436,
        "_internal_originalTime": 1689846665000
    },
    {
        "time": 1689846667000,
        "value": 139.437,
        "_internal_originalTime": 1689846667000
    },
    {
        "time": 1689846668000,
        "value": 139.437,
        "_internal_originalTime": 1689846668000
    },
    {
        "time": 1689846670000,
        "value": 139.433,
        "_internal_originalTime": 1689846670000
    },
    {
        "time": 1689846672000,
        "value": 139.436,
        "_internal_originalTime": 1689846672000
    },
    {
        "time": 1689846673000,
        "value": 139.447,
        "_internal_originalTime": 1689846673000
    },
    {
        "time": 1689846674000,
        "value": 139.45,
        "_internal_originalTime": 1689846674000
    },
    {
        "time": 1689846677000,
        "value": 139.445,
        "_internal_originalTime": 1689846677000
    },
    {
        "time": 1689846678000,
        "value": 139.444,
        "_internal_originalTime": 1689846678000
    },
    {
        "time": 1689846680000,
        "value": 139.445,
        "_internal_originalTime": 1689846680000
    },
    {
        "time": 1689846681000,
        "value": 139.444,
        "_internal_originalTime": 1689846681000
    },
    {
        "time": 1689846682000,
        "value": 139.445,
        "_internal_originalTime": 1689846682000
    },
    {
        "time": 1689846684000,
        "value": 139.444,
        "_internal_originalTime": 1689846684000
    },
    {
        "time": 1689846685000,
        "value": 139.443,
        "_internal_originalTime": 1689846685000
    },
    {
        "time": 1689846686000,
        "value": 139.444,
        "_internal_originalTime": 1689846686000
    },
    {
        "time": 1689846689000,
        "value": 139.445,
        "_internal_originalTime": 1689846689000
    },
    {
        "time": 1689846690000,
        "value": 139.446,
        "_internal_originalTime": 1689846690000
    },
    {
        "time": 1689846692000,
        "value": 139.445,
        "_internal_originalTime": 1689846692000
    },
    {
        "time": 1689846693000,
        "value": 139.442,
        "_internal_originalTime": 1689846693000
    },
    {
        "time": 1689846694000,
        "value": 139.447,
        "_internal_originalTime": 1689846694000
    },
    {
        "time": 1689846695000,
        "value": 139.444,
        "_internal_originalTime": 1689846695000
    },
    {
        "time": 1689846696000,
        "value": 139.442,
        "_internal_originalTime": 1689846696000
    },
    {
        "time": 1689846698000,
        "value": 139.446,
        "_internal_originalTime": 1689846698000
    },
    {
        "time": 1689846699000,
        "value": 139.446,
        "_internal_originalTime": 1689846699000
    },
    {
        "time": 1689846701000,
        "value": 139.445,
        "_internal_originalTime": 1689846701000
    },
    {
        "time": 1689846702000,
        "value": 139.446,
        "_internal_originalTime": 1689846702000
    },
    {
        "time": 1689846703000,
        "value": 139.442,
        "_internal_originalTime": 1689846703000
    },
    {
        "time": 1689846705000,
        "value": 139.446,
        "_internal_originalTime": 1689846705000
    },
    {
        "time": 1689846706000,
        "value": 139.442,
        "_internal_originalTime": 1689846706000
    },
    {
        "time": 1689846707000,
        "value": 139.449,
        "_internal_originalTime": 1689846707000
    },
    {
        "time": 1689846708000,
        "value": 139.455,
        "_internal_originalTime": 1689846708000
    },
    {
        "time": 1689846709000,
        "value": 139.456,
        "_internal_originalTime": 1689846709000
    },
    {
        "time": 1689846711000,
        "value": 139.455,
        "_internal_originalTime": 1689846711000
    },
    {
        "time": 1689846714000,
        "value": 139.455,
        "_internal_originalTime": 1689846714000
    },
    {
        "time": 1689846715000,
        "value": 139.454,
        "_internal_originalTime": 1689846715000
    },
    {
        "time": 1689846717000,
        "value": 139.454,
        "_internal_originalTime": 1689846717000
    },
    {
        "time": 1689846718000,
        "value": 139.455,
        "_internal_originalTime": 1689846718000
    },
    {
        "time": 1689846720000,
        "value": 139.453,
        "_internal_originalTime": 1689846720000
    },
    {
        "time": 1689846721000,
        "value": 139.457,
        "_internal_originalTime": 1689846721000
    },
    {
        "time": 1689846722000,
        "value": 139.456,
        "_internal_originalTime": 1689846722000
    },
    {
        "time": 1689846725000,
        "value": 139.45,
        "_internal_originalTime": 1689846725000
    },
    {
        "time": 1689846726000,
        "value": 139.456,
        "_internal_originalTime": 1689846726000
    },
    {
        "time": 1689846727000,
        "value": 139.453,
        "_internal_originalTime": 1689846727000
    },
    {
        "time": 1689846729000,
        "value": 139.457,
        "_internal_originalTime": 1689846729000
    },
    {
        "time": 1689846730000,
        "value": 139.451,
        "_internal_originalTime": 1689846730000
    },
    {
        "time": 1689846731000,
        "value": 139.452,
        "_internal_originalTime": 1689846731000
    },
    {
        "time": 1689846733000,
        "value": 139.454,
        "_internal_originalTime": 1689846733000
    },
    {
        "time": 1689846735000,
        "value": 139.457,
        "_internal_originalTime": 1689846735000
    },
    {
        "time": 1689846736000,
        "value": 139.457,
        "_internal_originalTime": 1689846736000
    },
    {
        "time": 1689846738000,
        "value": 139.456,
        "_internal_originalTime": 1689846738000
    },
    {
        "time": 1689846739000,
        "value": 139.456,
        "_internal_originalTime": 1689846739000
    },
    {
        "time": 1689846740000,
        "value": 139.457,
        "_internal_originalTime": 1689846740000
    },
    {
        "time": 1689846741000,
        "value": 139.456,
        "_internal_originalTime": 1689846741000
    },
    {
        "time": 1689846744000,
        "value": 139.452,
        "_internal_originalTime": 1689846744000
    },
    {
        "time": 1689846745000,
        "value": 139.455,
        "_internal_originalTime": 1689846745000
    },
    {
        "time": 1689846746000,
        "value": 139.453,
        "_internal_originalTime": 1689846746000
    },
    {
        "time": 1689846747000,
        "value": 139.452,
        "_internal_originalTime": 1689846747000
    },
    {
        "time": 1689846748000,
        "value": 139.453,
        "_internal_originalTime": 1689846748000
    },
    {
        "time": 1689846749000,
        "value": 139.452,
        "_internal_originalTime": 1689846749000
    },
    {
        "time": 1689846750000,
        "value": 139.453,
        "_internal_originalTime": 1689846750000
    },
    {
        "time": 1689846751000,
        "value": 139.452,
        "_internal_originalTime": 1689846751000
    },
    {
        "time": 1689846753000,
        "value": 139.452,
        "_internal_originalTime": 1689846753000
    },
    {
        "time": 1689846755000,
        "value": 139.456,
        "_internal_originalTime": 1689846755000
    },
    {
        "time": 1689846756000,
        "value": 139.457,
        "_internal_originalTime": 1689846756000
    },
    {
        "time": 1689846757000,
        "value": 139.457,
        "_internal_originalTime": 1689846757000
    },
    {
        "time": 1689846759000,
        "value": 139.457,
        "_internal_originalTime": 1689846759000
    },
    {
        "time": 1689846760000,
        "value": 139.453,
        "_internal_originalTime": 1689846760000
    },
    {
        "time": 1689846762000,
        "value": 139.453,
        "_internal_originalTime": 1689846762000
    },
    {
        "time": 1689846763000,
        "value": 139.468,
        "_internal_originalTime": 1689846763000
    },
    {
        "time": 1689846764000,
        "value": 139.466,
        "_internal_originalTime": 1689846764000
    },
    {
        "time": 1689846765000,
        "value": 139.463,
        "_internal_originalTime": 1689846765000
    },
    {
        "time": 1689846766000,
        "value": 139.462,
        "_internal_originalTime": 1689846766000
    },
    {
        "time": 1689846768000,
        "value": 139.454,
        "_internal_originalTime": 1689846768000
    },
    {
        "time": 1689846769000,
        "value": 139.458,
        "_internal_originalTime": 1689846769000
    },
    {
        "time": 1689846771000,
        "value": 139.456,
        "_internal_originalTime": 1689846771000
    },
    {
        "time": 1689846773000,
        "value": 139.452,
        "_internal_originalTime": 1689846773000
    },
    {
        "time": 1689846774000,
        "value": 139.452,
        "_internal_originalTime": 1689846774000
    },
    {
        "time": 1689846775000,
        "value": 139.442,
        "_internal_originalTime": 1689846775000
    },
    {
        "time": 1689846776000,
        "value": 139.448,
        "_internal_originalTime": 1689846776000
    },
    {
        "time": 1689846778000,
        "value": 139.447,
        "_internal_originalTime": 1689846778000
    },
    {
        "time": 1689846779000,
        "value": 139.448,
        "_internal_originalTime": 1689846779000
    },
    {
        "time": 1689846780000,
        "value": 139.447,
        "_internal_originalTime": 1689846780000
    },
    {
        "time": 1689846781000,
        "value": 139.42,
        "_internal_originalTime": 1689846781000
    },
    {
        "time": 1689846782000,
        "value": 139.451,
        "_internal_originalTime": 1689846782000
    },
    {
        "time": 1689846784000,
        "value": 139.445,
        "_internal_originalTime": 1689846784000
    },
    {
        "time": 1689846786000,
        "value": 139.443,
        "_internal_originalTime": 1689846786000
    },
    {
        "time": 1689846788000,
        "value": 139.443,
        "_internal_originalTime": 1689846788000
    },
    {
        "time": 1689846791000,
        "value": 139.447,
        "_internal_originalTime": 1689846791000
    },
    {
        "time": 1689846793000,
        "value": 139.442,
        "_internal_originalTime": 1689846793000
    },
    {
        "time": 1689846794000,
        "value": 139.449,
        "_internal_originalTime": 1689846794000
    },
    {
        "time": 1689846795000,
        "value": 139.448,
        "_internal_originalTime": 1689846795000
    },
    {
        "time": 1689846797000,
        "value": 139.451,
        "_internal_originalTime": 1689846797000
    },
    {
        "time": 1689846798000,
        "value": 139.455,
        "_internal_originalTime": 1689846798000
    },
    {
        "time": 1689846799000,
        "value": 139.455,
        "_internal_originalTime": 1689846799000
    },
    {
        "time": 1689846800000,
        "value": 139.452,
        "_internal_originalTime": 1689846800000
    },
    {
        "time": 1689846801000,
        "value": 139.453,
        "_internal_originalTime": 1689846801000
    },
    {
        "time": 1689846803000,
        "value": 139.458,
        "_internal_originalTime": 1689846803000
    },
    {
        "time": 1689846804000,
        "value": 139.458,
        "_internal_originalTime": 1689846804000
    },
    {
        "time": 1689846805000,
        "value": 139.457,
        "_internal_originalTime": 1689846805000
    },
    {
        "time": 1689846806000,
        "value": 139.457,
        "_internal_originalTime": 1689846806000
    },
    {
        "time": 1689846807000,
        "value": 139.452,
        "_internal_originalTime": 1689846807000
    },
    {
        "time": 1689846810000,
        "value": 139.453,
        "_internal_originalTime": 1689846810000
    },
    {
        "time": 1689846811000,
        "value": 139.455,
        "_internal_originalTime": 1689846811000
    },
    {
        "time": 1689846812000,
        "value": 139.458,
        "_internal_originalTime": 1689846812000
    },
    {
        "time": 1689846814000,
        "value": 139.452,
        "_internal_originalTime": 1689846814000
    },
    {
        "time": 1689846815000,
        "value": 139.453,
        "_internal_originalTime": 1689846815000
    },
    {
        "time": 1689846817000,
        "value": 139.452,
        "_internal_originalTime": 1689846817000
    },
    {
        "time": 1689846818000,
        "value": 139.451,
        "_internal_originalTime": 1689846818000
    },
    {
        "time": 1689846819000,
        "value": 139.455,
        "_internal_originalTime": 1689846819000
    },
    {
        "time": 1689846820000,
        "value": 139.45,
        "_internal_originalTime": 1689846820000
    },
    {
        "time": 1689846821000,
        "value": 139.453,
        "_internal_originalTime": 1689846821000
    },
    {
        "time": 1689846823000,
        "value": 139.454,
        "_internal_originalTime": 1689846823000
    },
    {
        "time": 1689846824000,
        "value": 139.455,
        "_internal_originalTime": 1689846824000
    },
    {
        "time": 1689846825000,
        "value": 139.458,
        "_internal_originalTime": 1689846825000
    },
    {
        "time": 1689846827000,
        "value": 139.457,
        "_internal_originalTime": 1689846827000
    },
    {
        "time": 1689846828000,
        "value": 139.457,
        "_internal_originalTime": 1689846828000
    },
    {
        "time": 1689846829000,
        "value": 139.455,
        "_internal_originalTime": 1689846829000
    },
    {
        "time": 1689846830000,
        "value": 139.454,
        "_internal_originalTime": 1689846830000
    },
    {
        "time": 1689846832000,
        "value": 139.459,
        "_internal_originalTime": 1689846832000
    },
    {
        "time": 1689846833000,
        "value": 139.465,
        "_internal_originalTime": 1689846833000
    },
    {
        "time": 1689846834000,
        "value": 139.466,
        "_internal_originalTime": 1689846834000
    },
    {
        "time": 1689846835000,
        "value": 139.467,
        "_internal_originalTime": 1689846835000
    },
    {
        "time": 1689846836000,
        "value": 139.465,
        "_internal_originalTime": 1689846836000
    },
    {
        "time": 1689846837000,
        "value": 139.467,
        "_internal_originalTime": 1689846837000
    },
    {
        "time": 1689846838000,
        "value": 139.463,
        "_internal_originalTime": 1689846838000
    },
    {
        "time": 1689846840000,
        "value": 139.464,
        "_internal_originalTime": 1689846840000
    },
    {
        "time": 1689846841000,
        "value": 139.466,
        "_internal_originalTime": 1689846841000
    },
    {
        "time": 1689846842000,
        "value": 139.466,
        "_internal_originalTime": 1689846842000
    },
    {
        "time": 1689846844000,
        "value": 139.466,
        "_internal_originalTime": 1689846844000
    },
    {
        "time": 1689846846000,
        "value": 139.473,
        "_internal_originalTime": 1689846846000
    },
    {
        "time": 1689846847000,
        "value": 139.471,
        "_internal_originalTime": 1689846847000
    },
    {
        "time": 1689846848000,
        "value": 139.472,
        "_internal_originalTime": 1689846848000
    },
    {
        "time": 1689846850000,
        "value": 139.466,
        "_internal_originalTime": 1689846850000
    },
    {
        "time": 1689846851000,
        "value": 139.469,
        "_internal_originalTime": 1689846851000
    },
    {
        "time": 1689846854000,
        "value": 139.471,
        "_internal_originalTime": 1689846854000
    },
    {
        "time": 1689846855000,
        "value": 139.465,
        "_internal_originalTime": 1689846855000
    },
    {
        "time": 1689846856000,
        "value": 139.467,
        "_internal_originalTime": 1689846856000
    },
    {
        "time": 1689846857000,
        "value": 139.473,
        "_internal_originalTime": 1689846857000
    },
    {
        "time": 1689846858000,
        "value": 139.47,
        "_internal_originalTime": 1689846858000
    },
    {
        "time": 1689846859000,
        "value": 139.47,
        "_internal_originalTime": 1689846859000
    },
    {
        "time": 1689846860000,
        "value": 139.469,
        "_internal_originalTime": 1689846860000
    },
    {
        "time": 1689846862000,
        "value": 139.474,
        "_internal_originalTime": 1689846862000
    },
    {
        "time": 1689846863000,
        "value": 139.473,
        "_internal_originalTime": 1689846863000
    },
    {
        "time": 1689846865000,
        "value": 139.472,
        "_internal_originalTime": 1689846865000
    },
    {
        "time": 1689846866000,
        "value": 139.481,
        "_internal_originalTime": 1689846866000
    },
    {
        "time": 1689846868000,
        "value": 139.478,
        "_internal_originalTime": 1689846868000
    },
    {
        "time": 1689846870000,
        "value": 139.48,
        "_internal_originalTime": 1689846870000
    },
    {
        "time": 1689846872000,
        "value": 139.48,
        "_internal_originalTime": 1689846872000
    },
    {
        "time": 1689846875000,
        "value": 139.482,
        "_internal_originalTime": 1689846875000
    },
    {
        "time": 1689846876000,
        "value": 139.482,
        "_internal_originalTime": 1689846876000
    },
    {
        "time": 1689846878000,
        "value": 139.473,
        "_internal_originalTime": 1689846878000
    },
    {
        "time": 1689846879000,
        "value": 139.469,
        "_internal_originalTime": 1689846879000
    },
    {
        "time": 1689846880000,
        "value": 139.468,
        "_internal_originalTime": 1689846880000
    },
    {
        "time": 1689846881000,
        "value": 139.476,
        "_internal_originalTime": 1689846881000
    },
    {
        "time": 1689846883000,
        "value": 139.475,
        "_internal_originalTime": 1689846883000
    },
    {
        "time": 1689846884000,
        "value": 139.47,
        "_internal_originalTime": 1689846884000
    },
    {
        "time": 1689846886000,
        "value": 139.476,
        "_internal_originalTime": 1689846886000
    },
    {
        "time": 1689846890000,
        "value": 139.484,
        "_internal_originalTime": 1689846890000
    },
    {
        "time": 1689846891000,
        "value": 139.484,
        "_internal_originalTime": 1689846891000
    },
    {
        "time": 1689846892000,
        "value": 139.485,
        "_internal_originalTime": 1689846892000
    },
    {
        "time": 1689846893000,
        "value": 139.481,
        "_internal_originalTime": 1689846893000
    },
    {
        "time": 1689846895000,
        "value": 139.487,
        "_internal_originalTime": 1689846895000
    },
    {
        "time": 1689846897000,
        "value": 139.486,
        "_internal_originalTime": 1689846897000
    },
    {
        "time": 1689846898000,
        "value": 139.485,
        "_internal_originalTime": 1689846898000
    },
    {
        "time": 1689846899000,
        "value": 139.486,
        "_internal_originalTime": 1689846899000
    },
    {
        "time": 1689846901000,
        "value": 139.493,
        "_internal_originalTime": 1689846901000
    },
    {
        "time": 1689846902000,
        "value": 139.494,
        "_internal_originalTime": 1689846902000
    },
    {
        "time": 1689846903000,
        "value": 139.49,
        "_internal_originalTime": 1689846903000
    },
    {
        "time": 1689846904000,
        "value": 139.489,
        "_internal_originalTime": 1689846904000
    },
    {
        "time": 1689846905000,
        "value": 139.49,
        "_internal_originalTime": 1689846905000
    },
    {
        "time": 1689846906000,
        "value": 139.492,
        "_internal_originalTime": 1689846906000
    },
    {
        "time": 1689846907000,
        "value": 139.489,
        "_internal_originalTime": 1689846907000
    },
    {
        "time": 1689846909000,
        "value": 139.49,
        "_internal_originalTime": 1689846909000
    },
    {
        "time": 1689846910000,
        "value": 139.489,
        "_internal_originalTime": 1689846910000
    },
    {
        "time": 1689846911000,
        "value": 139.493,
        "_internal_originalTime": 1689846911000
    },
    {
        "time": 1689846912000,
        "value": 139.492,
        "_internal_originalTime": 1689846912000
    },
    {
        "time": 1689846914000,
        "value": 139.493,
        "_internal_originalTime": 1689846914000
    },
    {
        "time": 1689846917000,
        "value": 139.493,
        "_internal_originalTime": 1689846917000
    },
    {
        "time": 1689846918000,
        "value": 139.492,
        "_internal_originalTime": 1689846918000
    },
    {
        "time": 1689846919000,
        "value": 139.492,
        "_internal_originalTime": 1689846919000
    },
    {
        "time": 1689846920000,
        "value": 139.488,
        "_internal_originalTime": 1689846920000
    },
    {
        "time": 1689846921000,
        "value": 139.492,
        "_internal_originalTime": 1689846921000
    },
    {
        "time": 1689846922000,
        "value": 139.489,
        "_internal_originalTime": 1689846922000
    },
    {
        "time": 1689846924000,
        "value": 139.489,
        "_internal_originalTime": 1689846924000
    },
    {
        "time": 1689846925000,
        "value": 139.492,
        "_internal_originalTime": 1689846925000
    },
    {
        "time": 1689846927000,
        "value": 139.491,
        "_internal_originalTime": 1689846927000
    },
    {
        "time": 1689846928000,
        "value": 139.489,
        "_internal_originalTime": 1689846928000
    },
    {
        "time": 1689846930000,
        "value": 139.485,
        "_internal_originalTime": 1689846930000
    },
    {
        "time": 1689846931000,
        "value": 139.491,
        "_internal_originalTime": 1689846931000
    },
    {
        "time": 1689846933000,
        "value": 139.492,
        "_internal_originalTime": 1689846933000
    },
    {
        "time": 1689846935000,
        "value": 139.492,
        "_internal_originalTime": 1689846935000
    },
    {
        "time": 1689846937000,
        "value": 139.492,
        "_internal_originalTime": 1689846937000
    },
    {
        "time": 1689846938000,
        "value": 139.491,
        "_internal_originalTime": 1689846938000
    },
    {
        "time": 1689846939000,
        "value": 139.492,
        "_internal_originalTime": 1689846939000
    },
    {
        "time": 1689846941000,
        "value": 139.49,
        "_internal_originalTime": 1689846941000
    },
    {
        "time": 1689846943000,
        "value": 139.488,
        "_internal_originalTime": 1689846943000
    },
    {
        "time": 1689846944000,
        "value": 139.48,
        "_internal_originalTime": 1689846944000
    },
    {
        "time": 1689846945000,
        "value": 139.487,
        "_internal_originalTime": 1689846945000
    },
    {
        "time": 1689846947000,
        "value": 139.483,
        "_internal_originalTime": 1689846947000
    },
    {
        "time": 1689846948000,
        "value": 139.484,
        "_internal_originalTime": 1689846948000
    },
    {
        "time": 1689846949000,
        "value": 139.485,
        "_internal_originalTime": 1689846949000
    },
    {
        "time": 1689846950000,
        "value": 139.484,
        "_internal_originalTime": 1689846950000
    },
    {
        "time": 1689846951000,
        "value": 139.484,
        "_internal_originalTime": 1689846951000
    },
    {
        "time": 1689846955000,
        "value": 139.483,
        "_internal_originalTime": 1689846955000
    },
    {
        "time": 1689846956000,
        "value": 139.484,
        "_internal_originalTime": 1689846956000
    },
    {
        "time": 1689846957000,
        "value": 139.482,
        "_internal_originalTime": 1689846957000
    },
    {
        "time": 1689846958000,
        "value": 139.482,
        "_internal_originalTime": 1689846958000
    },
    {
        "time": 1689846959000,
        "value": 139.48,
        "_internal_originalTime": 1689846959000
    },
    {
        "time": 1689846960000,
        "value": 139.483,
        "_internal_originalTime": 1689846960000
    },
    {
        "time": 1689846961000,
        "value": 139.48,
        "_internal_originalTime": 1689846961000
    },
    {
        "time": 1689846963000,
        "value": 139.483,
        "_internal_originalTime": 1689846963000
    },
    {
        "time": 1689846965000,
        "value": 139.482,
        "_internal_originalTime": 1689846965000
    },
    {
        "time": 1689846966000,
        "value": 139.486,
        "_internal_originalTime": 1689846966000
    },
    {
        "time": 1689846967000,
        "value": 139.485,
        "_internal_originalTime": 1689846967000
    },
    {
        "time": 1689846969000,
        "value": 139.481,
        "_internal_originalTime": 1689846969000
    },
    {
        "time": 1689846970000,
        "value": 139.477,
        "_internal_originalTime": 1689846970000
    },
    {
        "time": 1689846972000,
        "value": 139.482,
        "_internal_originalTime": 1689846972000
    },
    {
        "time": 1689846974000,
        "value": 139.482,
        "_internal_originalTime": 1689846974000
    },
    {
        "time": 1689846976000,
        "value": 139.482,
        "_internal_originalTime": 1689846976000
    },
    {
        "time": 1689846977000,
        "value": 139.48,
        "_internal_originalTime": 1689846977000
    },
    {
        "time": 1689846978000,
        "value": 139.482,
        "_internal_originalTime": 1689846978000
    },
    {
        "time": 1689846980000,
        "value": 139.481,
        "_internal_originalTime": 1689846980000
    },
    {
        "time": 1689846982000,
        "value": 139.48,
        "_internal_originalTime": 1689846982000
    },
    {
        "time": 1689846983000,
        "value": 139.488,
        "_internal_originalTime": 1689846983000
    },
    {
        "time": 1689846984000,
        "value": 139.491,
        "_internal_originalTime": 1689846984000
    },
    {
        "time": 1689846987000,
        "value": 139.489,
        "_internal_originalTime": 1689846987000
    },
    {
        "time": 1689846988000,
        "value": 139.49,
        "_internal_originalTime": 1689846988000
    },
    {
        "time": 1689846990000,
        "value": 139.49,
        "_internal_originalTime": 1689846990000
    },
    {
        "time": 1689846991000,
        "value": 139.49,
        "_internal_originalTime": 1689846991000
    },
    {
        "time": 1689846993000,
        "value": 139.487,
        "_internal_originalTime": 1689846993000
    },
    {
        "time": 1689846995000,
        "value": 139.488,
        "_internal_originalTime": 1689846995000
    },
    {
        "time": 1689846996000,
        "value": 139.486,
        "_internal_originalTime": 1689846996000
    },
    {
        "time": 1689846998000,
        "value": 139.493,
        "_internal_originalTime": 1689846998000
    },
    {
        "time": 1689846999000,
        "value": 139.493,
        "_internal_originalTime": 1689846999000
    },
    {
        "time": 1689847000000,
        "value": 139.499,
        "_internal_originalTime": 1689847000000
    },
    {
        "time": 1689847001000,
        "value": 139.493,
        "_internal_originalTime": 1689847001000
    },
    {
        "time": 1689847002000,
        "value": 139.496,
        "_internal_originalTime": 1689847002000
    },
    {
        "time": 1689847003000,
        "value": 139.495,
        "_internal_originalTime": 1689847003000
    },
    {
        "time": 1689847004000,
        "value": 139.491,
        "_internal_originalTime": 1689847004000
    },
    {
        "time": 1689847005000,
        "value": 139.497,
        "_internal_originalTime": 1689847005000
    },
    {
        "time": 1689847006000,
        "value": 139.492,
        "_internal_originalTime": 1689847006000
    },
    {
        "time": 1689847007000,
        "value": 139.496,
        "_internal_originalTime": 1689847007000
    },
    {
        "time": 1689847008000,
        "value": 139.492,
        "_internal_originalTime": 1689847008000
    },
    {
        "time": 1689847010000,
        "value": 139.493,
        "_internal_originalTime": 1689847010000
    },
    {
        "time": 1689847011000,
        "value": 139.496,
        "_internal_originalTime": 1689847011000
    },
    {
        "time": 1689847012000,
        "value": 139.49,
        "_internal_originalTime": 1689847012000
    },
    {
        "time": 1689847014000,
        "value": 139.492,
        "_internal_originalTime": 1689847014000
    },
    {
        "time": 1689847015000,
        "value": 139.495,
        "_internal_originalTime": 1689847015000
    },
    {
        "time": 1689847016000,
        "value": 139.494,
        "_internal_originalTime": 1689847016000
    },
    {
        "time": 1689847017000,
        "value": 139.495,
        "_internal_originalTime": 1689847017000
    },
    {
        "time": 1689847020000,
        "value": 139.49,
        "_internal_originalTime": 1689847020000
    },
    {
        "time": 1689847021000,
        "value": 139.495,
        "_internal_originalTime": 1689847021000
    },
    {
        "time": 1689847022000,
        "value": 139.491,
        "_internal_originalTime": 1689847022000
    },
    {
        "time": 1689847023000,
        "value": 139.494,
        "_internal_originalTime": 1689847023000
    },
    {
        "time": 1689847024000,
        "value": 139.498,
        "_internal_originalTime": 1689847024000
    },
    {
        "time": 1689847027000,
        "value": 139.498,
        "_internal_originalTime": 1689847027000
    },
    {
        "time": 1689847028000,
        "value": 139.499,
        "_internal_originalTime": 1689847028000
    },
    {
        "time": 1689847031000,
        "value": 139.496,
        "_internal_originalTime": 1689847031000
    },
    {
        "time": 1689847032000,
        "value": 139.499,
        "_internal_originalTime": 1689847032000
    },
    {
        "time": 1689847033000,
        "value": 139.498,
        "_internal_originalTime": 1689847033000
    },
    {
        "time": 1689847038000,
        "value": 139.484,
        "_internal_originalTime": 1689847038000
    },
    {
        "time": 1689847039000,
        "value": 139.485,
        "_internal_originalTime": 1689847039000
    },
    {
        "time": 1689847040000,
        "value": 139.486,
        "_internal_originalTime": 1689847040000
    },
    {
        "time": 1689847041000,
        "value": 139.488,
        "_internal_originalTime": 1689847041000
    },
    {
        "time": 1689847043000,
        "value": 139.488,
        "_internal_originalTime": 1689847043000
    },
    {
        "time": 1689847044000,
        "value": 139.494,
        "_internal_originalTime": 1689847044000
    },
    {
        "time": 1689847045000,
        "value": 139.495,
        "_internal_originalTime": 1689847045000
    },
    {
        "time": 1689847046000,
        "value": 139.495,
        "_internal_originalTime": 1689847046000
    },
    {
        "time": 1689847048000,
        "value": 139.491,
        "_internal_originalTime": 1689847048000
    },
    {
        "time": 1689847050000,
        "value": 139.503,
        "_internal_originalTime": 1689847050000
    },
    {
        "time": 1689847051000,
        "value": 139.5,
        "_internal_originalTime": 1689847051000
    },
    {
        "time": 1689847053000,
        "value": 139.501,
        "_internal_originalTime": 1689847053000
    },
    {
        "time": 1689847055000,
        "value": 139.505,
        "_internal_originalTime": 1689847055000
    },
    {
        "time": 1689847056000,
        "value": 139.505,
        "_internal_originalTime": 1689847056000
    },
    {
        "time": 1689847057000,
        "value": 139.504,
        "_internal_originalTime": 1689847057000
    },
    {
        "time": 1689847059000,
        "value": 139.501,
        "_internal_originalTime": 1689847059000
    },
    {
        "time": 1689847060000,
        "value": 139.497,
        "_internal_originalTime": 1689847060000
    },
    {
        "time": 1689847061000,
        "value": 139.498,
        "_internal_originalTime": 1689847061000
    },
    {
        "time": 1689847063000,
        "value": 139.507,
        "_internal_originalTime": 1689847063000
    },
    {
        "time": 1689847064000,
        "value": 139.511,
        "_internal_originalTime": 1689847064000
    },
    {
        "time": 1689847067000,
        "value": 139.511,
        "_internal_originalTime": 1689847067000
    },
    {
        "time": 1689847068000,
        "value": 139.506,
        "_internal_originalTime": 1689847068000
    },
    {
        "time": 1689847069000,
        "value": 139.506,
        "_internal_originalTime": 1689847069000
    },
    {
        "time": 1689847071000,
        "value": 139.51,
        "_internal_originalTime": 1689847071000
    },
    {
        "time": 1689847072000,
        "value": 139.508,
        "_internal_originalTime": 1689847072000
    },
    {
        "time": 1689847074000,
        "value": 139.512,
        "_internal_originalTime": 1689847074000
    },
    {
        "time": 1689847075000,
        "value": 139.511,
        "_internal_originalTime": 1689847075000
    },
    {
        "time": 1689847077000,
        "value": 139.509,
        "_internal_originalTime": 1689847077000
    },
    {
        "time": 1689847079000,
        "value": 139.507,
        "_internal_originalTime": 1689847079000
    },
    {
        "time": 1689847081000,
        "value": 139.509,
        "_internal_originalTime": 1689847081000
    },
    {
        "time": 1689847082000,
        "value": 139.512,
        "_internal_originalTime": 1689847082000
    },
    {
        "time": 1689847083000,
        "value": 139.513,
        "_internal_originalTime": 1689847083000
    },
    {
        "time": 1689847085000,
        "value": 139.515,
        "_internal_originalTime": 1689847085000
    },
    {
        "time": 1689847086000,
        "value": 139.513,
        "_internal_originalTime": 1689847086000
    },
    {
        "time": 1689847087000,
        "value": 139.514,
        "_internal_originalTime": 1689847087000
    },
    {
        "time": 1689847089000,
        "value": 139.516,
        "_internal_originalTime": 1689847089000
    },
    {
        "time": 1689847091000,
        "value": 139.514,
        "_internal_originalTime": 1689847091000
    },
    {
        "time": 1689847092000,
        "value": 139.516,
        "_internal_originalTime": 1689847092000
    },
    {
        "time": 1689847093000,
        "value": 139.517,
        "_internal_originalTime": 1689847093000
    },
    {
        "time": 1689847094000,
        "value": 139.517,
        "_internal_originalTime": 1689847094000
    },
    {
        "time": 1689847097000,
        "value": 139.515,
        "_internal_originalTime": 1689847097000
    },
    {
        "time": 1689847098000,
        "value": 139.516,
        "_internal_originalTime": 1689847098000
    },
    {
        "time": 1689847100000,
        "value": 139.517,
        "_internal_originalTime": 1689847100000
    },
    {
        "time": 1689847101000,
        "value": 139.516,
        "_internal_originalTime": 1689847101000
    },
    {
        "time": 1689847102000,
        "value": 139.517,
        "_internal_originalTime": 1689847102000
    },
    {
        "time": 1689847104000,
        "value": 139.516,
        "_internal_originalTime": 1689847104000
    },
    {
        "time": 1689847105000,
        "value": 139.525,
        "_internal_originalTime": 1689847105000
    },
    {
        "time": 1689847106000,
        "value": 139.527,
        "_internal_originalTime": 1689847106000
    },
    {
        "time": 1689847108000,
        "value": 139.522,
        "_internal_originalTime": 1689847108000
    },
    {
        "time": 1689847110000,
        "value": 139.522,
        "_internal_originalTime": 1689847110000
    },
    {
        "time": 1689847112000,
        "value": 139.526,
        "_internal_originalTime": 1689847112000
    },
    {
        "time": 1689847113000,
        "value": 139.525,
        "_internal_originalTime": 1689847113000
    },
    {
        "time": 1689847114000,
        "value": 139.521,
        "_internal_originalTime": 1689847114000
    },
    {
        "time": 1689847115000,
        "value": 139.521,
        "_internal_originalTime": 1689847115000
    },
    {
        "time": 1689847116000,
        "value": 139.521,
        "_internal_originalTime": 1689847116000
    },
    {
        "time": 1689847118000,
        "value": 139.524,
        "_internal_originalTime": 1689847118000
    },
    {
        "time": 1689847119000,
        "value": 139.522,
        "_internal_originalTime": 1689847119000
    },
    {
        "time": 1689847121000,
        "value": 139.526,
        "_internal_originalTime": 1689847121000
    },
    {
        "time": 1689847123000,
        "value": 139.528,
        "_internal_originalTime": 1689847123000
    },
    {
        "time": 1689847124000,
        "value": 139.523,
        "_internal_originalTime": 1689847124000
    },
    {
        "time": 1689847126000,
        "value": 139.523,
        "_internal_originalTime": 1689847126000
    },
    {
        "time": 1689847127000,
        "value": 139.525,
        "_internal_originalTime": 1689847127000
    },
    {
        "time": 1689847128000,
        "value": 139.529,
        "_internal_originalTime": 1689847128000
    },
    {
        "time": 1689847129000,
        "value": 139.528,
        "_internal_originalTime": 1689847129000
    },
    {
        "time": 1689847131000,
        "value": 139.529,
        "_internal_originalTime": 1689847131000
    },
    {
        "time": 1689847132000,
        "value": 139.525,
        "_internal_originalTime": 1689847132000
    },
    {
        "time": 1689847133000,
        "value": 139.532,
        "_internal_originalTime": 1689847133000
    },
    {
        "time": 1689847134000,
        "value": 139.53,
        "_internal_originalTime": 1689847134000
    },
    {
        "time": 1689847136000,
        "value": 139.53,
        "_internal_originalTime": 1689847136000
    },
    {
        "time": 1689847137000,
        "value": 139.528,
        "_internal_originalTime": 1689847137000
    },
    {
        "time": 1689847139000,
        "value": 139.531,
        "_internal_originalTime": 1689847139000
    },
    {
        "time": 1689847140000,
        "value": 139.531,
        "_internal_originalTime": 1689847140000
    },
    {
        "time": 1689847141000,
        "value": 139.527,
        "_internal_originalTime": 1689847141000
    },
    {
        "time": 1689847142000,
        "value": 139.53,
        "_internal_originalTime": 1689847142000
    },
    {
        "time": 1689847144000,
        "value": 139.526,
        "_internal_originalTime": 1689847144000
    },
    {
        "time": 1689847146000,
        "value": 139.531,
        "_internal_originalTime": 1689847146000
    },
    {
        "time": 1689847147000,
        "value": 139.528,
        "_internal_originalTime": 1689847147000
    },
    {
        "time": 1689847148000,
        "value": 139.533,
        "_internal_originalTime": 1689847148000
    },
    {
        "time": 1689847149000,
        "value": 139.533,
        "_internal_originalTime": 1689847149000
    },
    {
        "time": 1689847151000,
        "value": 139.529,
        "_internal_originalTime": 1689847151000
    },
    {
        "time": 1689847152000,
        "value": 139.528,
        "_internal_originalTime": 1689847152000
    },
    {
        "time": 1689847155000,
        "value": 139.529,
        "_internal_originalTime": 1689847155000
    },
    {
        "time": 1689847156000,
        "value": 139.53,
        "_internal_originalTime": 1689847156000
    },
    {
        "time": 1689847157000,
        "value": 139.528,
        "_internal_originalTime": 1689847157000
    },
    {
        "time": 1689847159000,
        "value": 139.529,
        "_internal_originalTime": 1689847159000
    },
    {
        "time": 1689847160000,
        "value": 139.528,
        "_internal_originalTime": 1689847160000
    },
    {
        "time": 1689847161000,
        "value": 139.527,
        "_internal_originalTime": 1689847161000
    },
    {
        "time": 1689847163000,
        "value": 139.53,
        "_internal_originalTime": 1689847163000
    },
    {
        "time": 1689847164000,
        "value": 139.53,
        "_internal_originalTime": 1689847164000
    },
    {
        "time": 1689847165000,
        "value": 139.527,
        "_internal_originalTime": 1689847165000
    },
    {
        "time": 1689847167000,
        "value": 139.528,
        "_internal_originalTime": 1689847167000
    },
    {
        "time": 1689847168000,
        "value": 139.53,
        "_internal_originalTime": 1689847168000
    },
    {
        "time": 1689847169000,
        "value": 139.528,
        "_internal_originalTime": 1689847169000
    },
    {
        "time": 1689847170000,
        "value": 139.526,
        "_internal_originalTime": 1689847170000
    },
    {
        "time": 1689847171000,
        "value": 139.527,
        "_internal_originalTime": 1689847171000
    },
    {
        "time": 1689847172000,
        "value": 139.531,
        "_internal_originalTime": 1689847172000
    },
    {
        "time": 1689847174000,
        "value": 139.533,
        "_internal_originalTime": 1689847174000
    },
    {
        "time": 1689847175000,
        "value": 139.533,
        "_internal_originalTime": 1689847175000
    },
    {
        "time": 1689847177000,
        "value": 139.533,
        "_internal_originalTime": 1689847177000
    },
    {
        "time": 1689847178000,
        "value": 139.532,
        "_internal_originalTime": 1689847178000
    },
    {
        "time": 1689847180000,
        "value": 139.528,
        "_internal_originalTime": 1689847180000
    },
    {
        "time": 1689847181000,
        "value": 139.525,
        "_internal_originalTime": 1689847181000
    },
    {
        "time": 1689847182000,
        "value": 139.531,
        "_internal_originalTime": 1689847182000
    },
    {
        "time": 1689847183000,
        "value": 139.527,
        "_internal_originalTime": 1689847183000
    },
    {
        "time": 1689847185000,
        "value": 139.528,
        "_internal_originalTime": 1689847185000
    },
    {
        "time": 1689847186000,
        "value": 139.528,
        "_internal_originalTime": 1689847186000
    },
    {
        "time": 1689847189000,
        "value": 139.524,
        "_internal_originalTime": 1689847189000
    },
    {
        "time": 1689847190000,
        "value": 139.528,
        "_internal_originalTime": 1689847190000
    },
    {
        "time": 1689847192000,
        "value": 139.527,
        "_internal_originalTime": 1689847192000
    },
    {
        "time": 1689847194000,
        "value": 139.526,
        "_internal_originalTime": 1689847194000
    },
    {
        "time": 1689847195000,
        "value": 139.527,
        "_internal_originalTime": 1689847195000
    },
    {
        "time": 1689847196000,
        "value": 139.53,
        "_internal_originalTime": 1689847196000
    },
    {
        "time": 1689847197000,
        "value": 139.524,
        "_internal_originalTime": 1689847197000
    },
    {
        "time": 1689847198000,
        "value": 139.529,
        "_internal_originalTime": 1689847198000
    },
    {
        "time": 1689847200000,
        "value": 139.5,
        "_internal_originalTime": 1689847200000
    },
    {
        "time": 1689847201000,
        "value": 139.52,
        "_internal_originalTime": 1689847201000
    },
    {
        "time": 1689847202000,
        "value": 139.519,
        "_internal_originalTime": 1689847202000
    },
    {
        "time": 1689847204000,
        "value": 139.514,
        "_internal_originalTime": 1689847204000
    },
    {
        "time": 1689847206000,
        "value": 139.512,
        "_internal_originalTime": 1689847206000
    },
    {
        "time": 1689847207000,
        "value": 139.51,
        "_internal_originalTime": 1689847207000
    },
    {
        "time": 1689847208000,
        "value": 139.511,
        "_internal_originalTime": 1689847208000
    },
    {
        "time": 1689847209000,
        "value": 139.512,
        "_internal_originalTime": 1689847209000
    },
    {
        "time": 1689847211000,
        "value": 139.515,
        "_internal_originalTime": 1689847211000
    },
    {
        "time": 1689847212000,
        "value": 139.51,
        "_internal_originalTime": 1689847212000
    },
    {
        "time": 1689847214000,
        "value": 139.512,
        "_internal_originalTime": 1689847214000
    },
    {
        "time": 1689847215000,
        "value": 139.51,
        "_internal_originalTime": 1689847215000
    },
    {
        "time": 1689847216000,
        "value": 139.513,
        "_internal_originalTime": 1689847216000
    },
    {
        "time": 1689847218000,
        "value": 139.508,
        "_internal_originalTime": 1689847218000
    },
    {
        "time": 1689847220000,
        "value": 139.519,
        "_internal_originalTime": 1689847220000
    },
    {
        "time": 1689847222000,
        "value": 139.514,
        "_internal_originalTime": 1689847222000
    },
    {
        "time": 1689847223000,
        "value": 139.51,
        "_internal_originalTime": 1689847223000
    },
    {
        "time": 1689847224000,
        "value": 139.51,
        "_internal_originalTime": 1689847224000
    },
    {
        "time": 1689847226000,
        "value": 139.515,
        "_internal_originalTime": 1689847226000
    },
    {
        "time": 1689847227000,
        "value": 139.515,
        "_internal_originalTime": 1689847227000
    },
    {
        "time": 1689847229000,
        "value": 139.517,
        "_internal_originalTime": 1689847229000
    },
    {
        "time": 1689847230000,
        "value": 139.514,
        "_internal_originalTime": 1689847230000
    },
    {
        "time": 1689847231000,
        "value": 139.521,
        "_internal_originalTime": 1689847231000
    },
    {
        "time": 1689847232000,
        "value": 139.526,
        "_internal_originalTime": 1689847232000
    },
    {
        "time": 1689847234000,
        "value": 139.526,
        "_internal_originalTime": 1689847234000
    },
    {
        "time": 1689847236000,
        "value": 139.526,
        "_internal_originalTime": 1689847236000
    },
    {
        "time": 1689847237000,
        "value": 139.527,
        "_internal_originalTime": 1689847237000
    },
    {
        "time": 1689847238000,
        "value": 139.526,
        "_internal_originalTime": 1689847238000
    },
    {
        "time": 1689847240000,
        "value": 139.522,
        "_internal_originalTime": 1689847240000
    },
    {
        "time": 1689847242000,
        "value": 139.529,
        "_internal_originalTime": 1689847242000
    },
    {
        "time": 1689847243000,
        "value": 139.529,
        "_internal_originalTime": 1689847243000
    },
    {
        "time": 1689847245000,
        "value": 139.525,
        "_internal_originalTime": 1689847245000
    },
    {
        "time": 1689847246000,
        "value": 139.53,
        "_internal_originalTime": 1689847246000
    },
    {
        "time": 1689847247000,
        "value": 139.528,
        "_internal_originalTime": 1689847247000
    },
    {
        "time": 1689847249000,
        "value": 139.528,
        "_internal_originalTime": 1689847249000
    },
    {
        "time": 1689847250000,
        "value": 139.53,
        "_internal_originalTime": 1689847250000
    },
    {
        "time": 1689847252000,
        "value": 139.53,
        "_internal_originalTime": 1689847252000
    },
    {
        "time": 1689847254000,
        "value": 139.529,
        "_internal_originalTime": 1689847254000
    },
    {
        "time": 1689847256000,
        "value": 139.531,
        "_internal_originalTime": 1689847256000
    },
    {
        "time": 1689847258000,
        "value": 139.529,
        "_internal_originalTime": 1689847258000
    },
    {
        "time": 1689847259000,
        "value": 139.53,
        "_internal_originalTime": 1689847259000
    },
    {
        "time": 1689847260000,
        "value": 139.526,
        "_internal_originalTime": 1689847260000
    },
    {
        "time": 1689847261000,
        "value": 139.53,
        "_internal_originalTime": 1689847261000
    },
    {
        "time": 1689847265000,
        "value": 139.53,
        "_internal_originalTime": 1689847265000
    },
    {
        "time": 1689847267000,
        "value": 139.527,
        "_internal_originalTime": 1689847267000
    },
    {
        "time": 1689847268000,
        "value": 139.528,
        "_internal_originalTime": 1689847268000
    },
    {
        "time": 1689847271000,
        "value": 139.538,
        "_internal_originalTime": 1689847271000
    },
    {
        "time": 1689847272000,
        "value": 139.54,
        "_internal_originalTime": 1689847272000
    },
    {
        "time": 1689847274000,
        "value": 139.536,
        "_internal_originalTime": 1689847274000
    },
    {
        "time": 1689847275000,
        "value": 139.54,
        "_internal_originalTime": 1689847275000
    },
    {
        "time": 1689847276000,
        "value": 139.541,
        "_internal_originalTime": 1689847276000
    },
    {
        "time": 1689847277000,
        "value": 139.541,
        "_internal_originalTime": 1689847277000
    },
    {
        "time": 1689847278000,
        "value": 139.539,
        "_internal_originalTime": 1689847278000
    },
    {
        "time": 1689847280000,
        "value": 139.534,
        "_internal_originalTime": 1689847280000
    },
    {
        "time": 1689847282000,
        "value": 139.536,
        "_internal_originalTime": 1689847282000
    },
    {
        "time": 1689847284000,
        "value": 139.54,
        "_internal_originalTime": 1689847284000
    },
    {
        "time": 1689847286000,
        "value": 139.539,
        "_internal_originalTime": 1689847286000
    },
    {
        "time": 1689847287000,
        "value": 139.535,
        "_internal_originalTime": 1689847287000
    },
    {
        "time": 1689847288000,
        "value": 139.54,
        "_internal_originalTime": 1689847288000
    },
    {
        "time": 1689847289000,
        "value": 139.54,
        "_internal_originalTime": 1689847289000
    },
    {
        "time": 1689847290000,
        "value": 139.534,
        "_internal_originalTime": 1689847290000
    },
    {
        "time": 1689847292000,
        "value": 139.54,
        "_internal_originalTime": 1689847292000
    },
    {
        "time": 1689847294000,
        "value": 139.536,
        "_internal_originalTime": 1689847294000
    },
    {
        "time": 1689847296000,
        "value": 139.534,
        "_internal_originalTime": 1689847296000
    },
    {
        "time": 1689847297000,
        "value": 139.535,
        "_internal_originalTime": 1689847297000
    },
    {
        "time": 1689847298000,
        "value": 139.54,
        "_internal_originalTime": 1689847298000
    },
    {
        "time": 1689847300000,
        "value": 139.539,
        "_internal_originalTime": 1689847300000
    },
    {
        "time": 1689847301000,
        "value": 139.534,
        "_internal_originalTime": 1689847301000
    },
    {
        "time": 1689847304000,
        "value": 139.54,
        "_internal_originalTime": 1689847304000
    },
    {
        "time": 1689847305000,
        "value": 139.54,
        "_internal_originalTime": 1689847305000
    },
    {
        "time": 1689847306000,
        "value": 139.536,
        "_internal_originalTime": 1689847306000
    },
    {
        "time": 1689847307000,
        "value": 139.536,
        "_internal_originalTime": 1689847307000
    },
    {
        "time": 1689847309000,
        "value": 139.537,
        "_internal_originalTime": 1689847309000
    },
    {
        "time": 1689847310000,
        "value": 139.534,
        "_internal_originalTime": 1689847310000
    },
    {
        "time": 1689847313000,
        "value": 139.539,
        "_internal_originalTime": 1689847313000
    },
    {
        "time": 1689847315000,
        "value": 139.539,
        "_internal_originalTime": 1689847315000
    },
    {
        "time": 1689847316000,
        "value": 139.539,
        "_internal_originalTime": 1689847316000
    },
    {
        "time": 1689847318000,
        "value": 139.539,
        "_internal_originalTime": 1689847318000
    },
    {
        "time": 1689847319000,
        "value": 139.539,
        "_internal_originalTime": 1689847319000
    },
    {
        "time": 1689847320000,
        "value": 139.536,
        "_internal_originalTime": 1689847320000
    },
    {
        "time": 1689847321000,
        "value": 139.54,
        "_internal_originalTime": 1689847321000
    },
    {
        "time": 1689847322000,
        "value": 139.542,
        "_internal_originalTime": 1689847322000
    },
    {
        "time": 1689847323000,
        "value": 139.535,
        "_internal_originalTime": 1689847323000
    },
    {
        "time": 1689847325000,
        "value": 139.541,
        "_internal_originalTime": 1689847325000
    },
    {
        "time": 1689847327000,
        "value": 139.536,
        "_internal_originalTime": 1689847327000
    },
    {
        "time": 1689847328000,
        "value": 139.546,
        "_internal_originalTime": 1689847328000
    },
    {
        "time": 1689847329000,
        "value": 139.55,
        "_internal_originalTime": 1689847329000
    },
    {
        "time": 1689847331000,
        "value": 139.547,
        "_internal_originalTime": 1689847331000
    },
    {
        "time": 1689847332000,
        "value": 139.545,
        "_internal_originalTime": 1689847332000
    },
    {
        "time": 1689847333000,
        "value": 139.541,
        "_internal_originalTime": 1689847333000
    },
    {
        "time": 1689847335000,
        "value": 139.54,
        "_internal_originalTime": 1689847335000
    },
    {
        "time": 1689847336000,
        "value": 139.542,
        "_internal_originalTime": 1689847336000
    },
    {
        "time": 1689847338000,
        "value": 139.543,
        "_internal_originalTime": 1689847338000
    },
    {
        "time": 1689847340000,
        "value": 139.543,
        "_internal_originalTime": 1689847340000
    },
    {
        "time": 1689847342000,
        "value": 139.542,
        "_internal_originalTime": 1689847342000
    },
    {
        "time": 1689847343000,
        "value": 139.541,
        "_internal_originalTime": 1689847343000
    },
    {
        "time": 1689847344000,
        "value": 139.524,
        "_internal_originalTime": 1689847344000
    },
    {
        "time": 1689847346000,
        "value": 139.529,
        "_internal_originalTime": 1689847346000
    },
    {
        "time": 1689847347000,
        "value": 139.529,
        "_internal_originalTime": 1689847347000
    },
    {
        "time": 1689847348000,
        "value": 139.53,
        "_internal_originalTime": 1689847348000
    },
    {
        "time": 1689847350000,
        "value": 139.528,
        "_internal_originalTime": 1689847350000
    },
    {
        "time": 1689847351000,
        "value": 139.528,
        "_internal_originalTime": 1689847351000
    },
    {
        "time": 1689847352000,
        "value": 139.527,
        "_internal_originalTime": 1689847352000
    },
    {
        "time": 1689847354000,
        "value": 139.535,
        "_internal_originalTime": 1689847354000
    },
    {
        "time": 1689847355000,
        "value": 139.537,
        "_internal_originalTime": 1689847355000
    },
    {
        "time": 1689847357000,
        "value": 139.539,
        "_internal_originalTime": 1689847357000
    },
    {
        "time": 1689847358000,
        "value": 139.536,
        "_internal_originalTime": 1689847358000
    },
    {
        "time": 1689847361000,
        "value": 139.538,
        "_internal_originalTime": 1689847361000
    },
    {
        "time": 1689847362000,
        "value": 139.54,
        "_internal_originalTime": 1689847362000
    },
    {
        "time": 1689847364000,
        "value": 139.54,
        "_internal_originalTime": 1689847364000
    },
    {
        "time": 1689847365000,
        "value": 139.532,
        "_internal_originalTime": 1689847365000
    },
    {
        "time": 1689847366000,
        "value": 139.529,
        "_internal_originalTime": 1689847366000
    },
    {
        "time": 1689847368000,
        "value": 139.526,
        "_internal_originalTime": 1689847368000
    },
    {
        "time": 1689847369000,
        "value": 139.53,
        "_internal_originalTime": 1689847369000
    },
    {
        "time": 1689847370000,
        "value": 139.533,
        "_internal_originalTime": 1689847370000
    },
    {
        "time": 1689847371000,
        "value": 139.532,
        "_internal_originalTime": 1689847371000
    },
    {
        "time": 1689847373000,
        "value": 139.529,
        "_internal_originalTime": 1689847373000
    },
    {
        "time": 1689847375000,
        "value": 139.528,
        "_internal_originalTime": 1689847375000
    },
    {
        "time": 1689847376000,
        "value": 139.53,
        "_internal_originalTime": 1689847376000
    },
    {
        "time": 1689847377000,
        "value": 139.531,
        "_internal_originalTime": 1689847377000
    },
    {
        "time": 1689847379000,
        "value": 139.528,
        "_internal_originalTime": 1689847379000
    },
    {
        "time": 1689847380000,
        "value": 139.53,
        "_internal_originalTime": 1689847380000
    },
    {
        "time": 1689847381000,
        "value": 139.51,
        "_internal_originalTime": 1689847381000
    },
    {
        "time": 1689847382000,
        "value": 139.534,
        "_internal_originalTime": 1689847382000
    },
    {
        "time": 1689847383000,
        "value": 139.53,
        "_internal_originalTime": 1689847383000
    },
    {
        "time": 1689847384000,
        "value": 139.53,
        "_internal_originalTime": 1689847384000
    },
    {
        "time": 1689847385000,
        "value": 139.538,
        "_internal_originalTime": 1689847385000
    },
    {
        "time": 1689847386000,
        "value": 139.534,
        "_internal_originalTime": 1689847386000
    },
    {
        "time": 1689847388000,
        "value": 139.532,
        "_internal_originalTime": 1689847388000
    },
    {
        "time": 1689847391000,
        "value": 139.536,
        "_internal_originalTime": 1689847391000
    },
    {
        "time": 1689847392000,
        "value": 139.532,
        "_internal_originalTime": 1689847392000
    },
    {
        "time": 1689847393000,
        "value": 139.532,
        "_internal_originalTime": 1689847393000
    },
    {
        "time": 1689847394000,
        "value": 139.527,
        "_internal_originalTime": 1689847394000
    },
    {
        "time": 1689847396000,
        "value": 139.526,
        "_internal_originalTime": 1689847396000
    },
    {
        "time": 1689847397000,
        "value": 139.528,
        "_internal_originalTime": 1689847397000
    },
    {
        "time": 1689847398000,
        "value": 139.53,
        "_internal_originalTime": 1689847398000
    },
    {
        "time": 1689847400000,
        "value": 139.529,
        "_internal_originalTime": 1689847400000
    },
    {
        "time": 1689847402000,
        "value": 139.531,
        "_internal_originalTime": 1689847402000
    },
    {
        "time": 1689847403000,
        "value": 139.536,
        "_internal_originalTime": 1689847403000
    },
    {
        "time": 1689847404000,
        "value": 139.543,
        "_internal_originalTime": 1689847404000
    },
    {
        "time": 1689847405000,
        "value": 139.536,
        "_internal_originalTime": 1689847405000
    },
    {
        "time": 1689847406000,
        "value": 139.543,
        "_internal_originalTime": 1689847406000
    },
    {
        "time": 1689847408000,
        "value": 139.543,
        "_internal_originalTime": 1689847408000
    },
    {
        "time": 1689847409000,
        "value": 139.538,
        "_internal_originalTime": 1689847409000
    },
    {
        "time": 1689847410000,
        "value": 139.541,
        "_internal_originalTime": 1689847410000
    },
    {
        "time": 1689847411000,
        "value": 139.536,
        "_internal_originalTime": 1689847411000
    },
    {
        "time": 1689847412000,
        "value": 139.541,
        "_internal_originalTime": 1689847412000
    },
    {
        "time": 1689847413000,
        "value": 139.538,
        "_internal_originalTime": 1689847413000
    },
    {
        "time": 1689847414000,
        "value": 139.543,
        "_internal_originalTime": 1689847414000
    },
    {
        "time": 1689847416000,
        "value": 139.543,
        "_internal_originalTime": 1689847416000
    },
    {
        "time": 1689847417000,
        "value": 139.54,
        "_internal_originalTime": 1689847417000
    },
    {
        "time": 1689847421000,
        "value": 139.539,
        "_internal_originalTime": 1689847421000
    },
    {
        "time": 1689847423000,
        "value": 139.542,
        "_internal_originalTime": 1689847423000
    },
    {
        "time": 1689847424000,
        "value": 139.536,
        "_internal_originalTime": 1689847424000
    },
    {
        "time": 1689847429000,
        "value": 139.543,
        "_internal_originalTime": 1689847429000
    },
    {
        "time": 1689847431000,
        "value": 139.536,
        "_internal_originalTime": 1689847431000
    },
    {
        "time": 1689847433000,
        "value": 139.538,
        "_internal_originalTime": 1689847433000
    },
    {
        "time": 1689847434000,
        "value": 139.539,
        "_internal_originalTime": 1689847434000
    },
    {
        "time": 1689847435000,
        "value": 139.538,
        "_internal_originalTime": 1689847435000
    },
    {
        "time": 1689847436000,
        "value": 139.537,
        "_internal_originalTime": 1689847436000
    },
    {
        "time": 1689847440000,
        "value": 139.541,
        "_internal_originalTime": 1689847440000
    },
    {
        "time": 1689847443000,
        "value": 139.542,
        "_internal_originalTime": 1689847443000
    },
    {
        "time": 1689847447000,
        "value": 139.541,
        "_internal_originalTime": 1689847447000
    },
    {
        "time": 1689847448000,
        "value": 139.539,
        "_internal_originalTime": 1689847448000
    },
    {
        "time": 1689847451000,
        "value": 139.535,
        "_internal_originalTime": 1689847451000
    },
    {
        "time": 1689847452000,
        "value": 139.536,
        "_internal_originalTime": 1689847452000
    },
    {
        "time": 1689847457000,
        "value": 139.532,
        "_internal_originalTime": 1689847457000
    },
    {
        "time": 1689847458000,
        "value": 139.533,
        "_internal_originalTime": 1689847458000
    },
    {
        "time": 1689847459000,
        "value": 139.534,
        "_internal_originalTime": 1689847459000
    },
    {
        "time": 1689847460000,
        "value": 139.535,
        "_internal_originalTime": 1689847460000
    },
    {
        "time": 1689847463000,
        "value": 139.535,
        "_internal_originalTime": 1689847463000
    },
    {
        "time": 1689847464000,
        "value": 139.524,
        "_internal_originalTime": 1689847464000
    },
    {
        "time": 1689847465000,
        "value": 139.53,
        "_internal_originalTime": 1689847465000
    },
    {
        "time": 1689847468000,
        "value": 139.53,
        "_internal_originalTime": 1689847468000
    },
    {
        "time": 1689847470000,
        "value": 139.527,
        "_internal_originalTime": 1689847470000
    },
    {
        "time": 1689847471000,
        "value": 139.535,
        "_internal_originalTime": 1689847471000
    },
    {
        "time": 1689847472000,
        "value": 139.529,
        "_internal_originalTime": 1689847472000
    },
    {
        "time": 1689847474000,
        "value": 139.533,
        "_internal_originalTime": 1689847474000
    },
    {
        "time": 1689847475000,
        "value": 139.533,
        "_internal_originalTime": 1689847475000
    },
    {
        "time": 1689847477000,
        "value": 139.528,
        "_internal_originalTime": 1689847477000
    },
    {
        "time": 1689847478000,
        "value": 139.522,
        "_internal_originalTime": 1689847478000
    },
    {
        "time": 1689847479000,
        "value": 139.517,
        "_internal_originalTime": 1689847479000
    },
    {
        "time": 1689847481000,
        "value": 139.517,
        "_internal_originalTime": 1689847481000
    },
    {
        "time": 1689847482000,
        "value": 139.517,
        "_internal_originalTime": 1689847482000
    },
    {
        "time": 1689847483000,
        "value": 139.521,
        "_internal_originalTime": 1689847483000
    },
    {
        "time": 1689847485000,
        "value": 139.521,
        "_internal_originalTime": 1689847485000
    },
    {
        "time": 1689847488000,
        "value": 139.517,
        "_internal_originalTime": 1689847488000
    },
    {
        "time": 1689847489000,
        "value": 139.517,
        "_internal_originalTime": 1689847489000
    },
    {
        "time": 1689847490000,
        "value": 139.521,
        "_internal_originalTime": 1689847490000
    },
    {
        "time": 1689847491000,
        "value": 139.521,
        "_internal_originalTime": 1689847491000
    },
    {
        "time": 1689847494000,
        "value": 139.521,
        "_internal_originalTime": 1689847494000
    },
    {
        "time": 1689847496000,
        "value": 139.516,
        "_internal_originalTime": 1689847496000
    },
    {
        "time": 1689847497000,
        "value": 139.517,
        "_internal_originalTime": 1689847497000
    },
    {
        "time": 1689847499000,
        "value": 139.516,
        "_internal_originalTime": 1689847499000
    },
    {
        "time": 1689847500000,
        "value": 139.52,
        "_internal_originalTime": 1689847500000
    },
    {
        "time": 1689847502000,
        "value": 139.52,
        "_internal_originalTime": 1689847502000
    },
    {
        "time": 1689847504000,
        "value": 139.525,
        "_internal_originalTime": 1689847504000
    },
    {
        "time": 1689847505000,
        "value": 139.523,
        "_internal_originalTime": 1689847505000
    },
    {
        "time": 1689847506000,
        "value": 139.519,
        "_internal_originalTime": 1689847506000
    },
    {
        "time": 1689847509000,
        "value": 139.523,
        "_internal_originalTime": 1689847509000
    },
    {
        "time": 1689847511000,
        "value": 139.522,
        "_internal_originalTime": 1689847511000
    },
    {
        "time": 1689847512000,
        "value": 139.518,
        "_internal_originalTime": 1689847512000
    },
    {
        "time": 1689847513000,
        "value": 139.523,
        "_internal_originalTime": 1689847513000
    },
    {
        "time": 1689847514000,
        "value": 139.522,
        "_internal_originalTime": 1689847514000
    },
    {
        "time": 1689847519000,
        "value": 139.516,
        "_internal_originalTime": 1689847519000
    },
    {
        "time": 1689847521000,
        "value": 139.522,
        "_internal_originalTime": 1689847521000
    },
    {
        "time": 1689847526000,
        "value": 139.531,
        "_internal_originalTime": 1689847526000
    },
    {
        "time": 1689847527000,
        "value": 139.528,
        "_internal_originalTime": 1689847527000
    },
    {
        "time": 1689847528000,
        "value": 139.53,
        "_internal_originalTime": 1689847528000
    },
    {
        "time": 1689847529000,
        "value": 139.527,
        "_internal_originalTime": 1689847529000
    },
    {
        "time": 1689847531000,
        "value": 139.531,
        "_internal_originalTime": 1689847531000
    },
    {
        "time": 1689847535000,
        "value": 139.53,
        "_internal_originalTime": 1689847535000
    },
    {
        "time": 1689847537000,
        "value": 139.527,
        "_internal_originalTime": 1689847537000
    },
    {
        "time": 1689847540000,
        "value": 139.527,
        "_internal_originalTime": 1689847540000
    },
    {
        "time": 1689847541000,
        "value": 139.527,
        "_internal_originalTime": 1689847541000
    },
    {
        "time": 1689847543000,
        "value": 139.528,
        "_internal_originalTime": 1689847543000
    },
    {
        "time": 1689847544000,
        "value": 139.524,
        "_internal_originalTime": 1689847544000
    },
    {
        "time": 1689847546000,
        "value": 139.527,
        "_internal_originalTime": 1689847546000
    },
    {
        "time": 1689847547000,
        "value": 139.523,
        "_internal_originalTime": 1689847547000
    },
    {
        "time": 1689847548000,
        "value": 139.524,
        "_internal_originalTime": 1689847548000
    },
    {
        "time": 1689847552000,
        "value": 139.527,
        "_internal_originalTime": 1689847552000
    },
    {
        "time": 1689847553000,
        "value": 139.532,
        "_internal_originalTime": 1689847553000
    },
    {
        "time": 1689847555000,
        "value": 139.528,
        "_internal_originalTime": 1689847555000
    },
    {
        "time": 1689847556000,
        "value": 139.528,
        "_internal_originalTime": 1689847556000
    },
    {
        "time": 1689847559000,
        "value": 139.529,
        "_internal_originalTime": 1689847559000
    },
    {
        "time": 1689847560000,
        "value": 139.52,
        "_internal_originalTime": 1689847560000
    },
    {
        "time": 1689847561000,
        "value": 139.521,
        "_internal_originalTime": 1689847561000
    },
    {
        "time": 1689847562000,
        "value": 139.52,
        "_internal_originalTime": 1689847562000
    },
    {
        "time": 1689847563000,
        "value": 139.52,
        "_internal_originalTime": 1689847563000
    },
    {
        "time": 1689847564000,
        "value": 139.527,
        "_internal_originalTime": 1689847564000
    },
    {
        "time": 1689847566000,
        "value": 139.52,
        "_internal_originalTime": 1689847566000
    },
    {
        "time": 1689847569000,
        "value": 139.527,
        "_internal_originalTime": 1689847569000
    },
    {
        "time": 1689847570000,
        "value": 139.522,
        "_internal_originalTime": 1689847570000
    },
    {
        "time": 1689847571000,
        "value": 139.524,
        "_internal_originalTime": 1689847571000
    },
    {
        "time": 1689847572000,
        "value": 139.525,
        "_internal_originalTime": 1689847572000
    },
    {
        "time": 1689847576000,
        "value": 139.524,
        "_internal_originalTime": 1689847576000
    },
    {
        "time": 1689847578000,
        "value": 139.531,
        "_internal_originalTime": 1689847578000
    },
    {
        "time": 1689847579000,
        "value": 139.527,
        "_internal_originalTime": 1689847579000
    },
    {
        "time": 1689847581000,
        "value": 139.525,
        "_internal_originalTime": 1689847581000
    },
    {
        "time": 1689847584000,
        "value": 139.53,
        "_internal_originalTime": 1689847584000
    },
    {
        "time": 1689847586000,
        "value": 139.531,
        "_internal_originalTime": 1689847586000
    },
    {
        "time": 1689847587000,
        "value": 139.53,
        "_internal_originalTime": 1689847587000
    },
    {
        "time": 1689847590000,
        "value": 139.524,
        "_internal_originalTime": 1689847590000
    },
    {
        "time": 1689847591000,
        "value": 139.525,
        "_internal_originalTime": 1689847591000
    },
    {
        "time": 1689847592000,
        "value": 139.528,
        "_internal_originalTime": 1689847592000
    },
    {
        "time": 1689847596000,
        "value": 139.526,
        "_internal_originalTime": 1689847596000
    },
    {
        "time": 1689847597000,
        "value": 139.527,
        "_internal_originalTime": 1689847597000
    },
    {
        "time": 1689847599000,
        "value": 139.522,
        "_internal_originalTime": 1689847599000
    },
    {
        "time": 1689847600000,
        "value": 139.517,
        "_internal_originalTime": 1689847600000
    },
    {
        "time": 1689847601000,
        "value": 139.522,
        "_internal_originalTime": 1689847601000
    },
    {
        "time": 1689847604000,
        "value": 139.521,
        "_internal_originalTime": 1689847604000
    },
    {
        "time": 1689847605000,
        "value": 139.522,
        "_internal_originalTime": 1689847605000
    },
    {
        "time": 1689847614000,
        "value": 139.517,
        "_internal_originalTime": 1689847614000
    },
    {
        "time": 1689847615000,
        "value": 139.511,
        "_internal_originalTime": 1689847615000
    },
    {
        "time": 1689847616000,
        "value": 139.508,
        "_internal_originalTime": 1689847616000
    },
    {
        "time": 1689847617000,
        "value": 139.51,
        "_internal_originalTime": 1689847617000
    },
    {
        "time": 1689847618000,
        "value": 139.511,
        "_internal_originalTime": 1689847618000
    },
    {
        "time": 1689847620000,
        "value": 139.504,
        "_internal_originalTime": 1689847620000
    },
    {
        "time": 1689847622000,
        "value": 139.511,
        "_internal_originalTime": 1689847622000
    },
    {
        "time": 1689847623000,
        "value": 139.507,
        "_internal_originalTime": 1689847623000
    },
    {
        "time": 1689847624000,
        "value": 139.511,
        "_internal_originalTime": 1689847624000
    },
    {
        "time": 1689847625000,
        "value": 139.51,
        "_internal_originalTime": 1689847625000
    },
    {
        "time": 1689847627000,
        "value": 139.508,
        "_internal_originalTime": 1689847627000
    },
    {
        "time": 1689847628000,
        "value": 139.512,
        "_internal_originalTime": 1689847628000
    },
    {
        "time": 1689847630000,
        "value": 139.506,
        "_internal_originalTime": 1689847630000
    },
    {
        "time": 1689847631000,
        "value": 139.509,
        "_internal_originalTime": 1689847631000
    },
    {
        "time": 1689847633000,
        "value": 139.513,
        "_internal_originalTime": 1689847633000
    },
    {
        "time": 1689847635000,
        "value": 139.511,
        "_internal_originalTime": 1689847635000
    },
    {
        "time": 1689847636000,
        "value": 139.514,
        "_internal_originalTime": 1689847636000
    },
    {
        "time": 1689847638000,
        "value": 139.513,
        "_internal_originalTime": 1689847638000
    },
    {
        "time": 1689847639000,
        "value": 139.508,
        "_internal_originalTime": 1689847639000
    },
    {
        "time": 1689847640000,
        "value": 139.512,
        "_internal_originalTime": 1689847640000
    },
    {
        "time": 1689847642000,
        "value": 139.508,
        "_internal_originalTime": 1689847642000
    },
    {
        "time": 1689847643000,
        "value": 139.509,
        "_internal_originalTime": 1689847643000
    },
    {
        "time": 1689847644000,
        "value": 139.508,
        "_internal_originalTime": 1689847644000
    },
    {
        "time": 1689847647000,
        "value": 139.509,
        "_internal_originalTime": 1689847647000
    },
    {
        "time": 1689847650000,
        "value": 139.507,
        "_internal_originalTime": 1689847650000
    },
    {
        "time": 1689847652000,
        "value": 139.512,
        "_internal_originalTime": 1689847652000
    },
    {
        "time": 1689847653000,
        "value": 139.509,
        "_internal_originalTime": 1689847653000
    },
    {
        "time": 1689847655000,
        "value": 139.509,
        "_internal_originalTime": 1689847655000
    },
    {
        "time": 1689847656000,
        "value": 139.51,
        "_internal_originalTime": 1689847656000
    },
    {
        "time": 1689847657000,
        "value": 139.513,
        "_internal_originalTime": 1689847657000
    },
    {
        "time": 1689847659000,
        "value": 139.509,
        "_internal_originalTime": 1689847659000
    },
    {
        "time": 1689847660000,
        "value": 139.504,
        "_internal_originalTime": 1689847660000
    },
    {
        "time": 1689847662000,
        "value": 139.507,
        "_internal_originalTime": 1689847662000
    },
    {
        "time": 1689847664000,
        "value": 139.509,
        "_internal_originalTime": 1689847664000
    },
    {
        "time": 1689847666000,
        "value": 139.512,
        "_internal_originalTime": 1689847666000
    },
    {
        "time": 1689847667000,
        "value": 139.509,
        "_internal_originalTime": 1689847667000
    },
    {
        "time": 1689847668000,
        "value": 139.508,
        "_internal_originalTime": 1689847668000
    },
    {
        "time": 1689847670000,
        "value": 139.511,
        "_internal_originalTime": 1689847670000
    },
    {
        "time": 1689847672000,
        "value": 139.507,
        "_internal_originalTime": 1689847672000
    },
    {
        "time": 1689847673000,
        "value": 139.512,
        "_internal_originalTime": 1689847673000
    },
    {
        "time": 1689847675000,
        "value": 139.513,
        "_internal_originalTime": 1689847675000
    },
    {
        "time": 1689847676000,
        "value": 139.509,
        "_internal_originalTime": 1689847676000
    },
    {
        "time": 1689847678000,
        "value": 139.514,
        "_internal_originalTime": 1689847678000
    },
    {
        "time": 1689847679000,
        "value": 139.511,
        "_internal_originalTime": 1689847679000
    },
    {
        "time": 1689847680000,
        "value": 139.51,
        "_internal_originalTime": 1689847680000
    },
    {
        "time": 1689847681000,
        "value": 139.516,
        "_internal_originalTime": 1689847681000
    },
    {
        "time": 1689847682000,
        "value": 139.515,
        "_internal_originalTime": 1689847682000
    },
    {
        "time": 1689847683000,
        "value": 139.515,
        "_internal_originalTime": 1689847683000
    },
    {
        "time": 1689847685000,
        "value": 139.51,
        "_internal_originalTime": 1689847685000
    },
    {
        "time": 1689847687000,
        "value": 139.51,
        "_internal_originalTime": 1689847687000
    },
    {
        "time": 1689847688000,
        "value": 139.51,
        "_internal_originalTime": 1689847688000
    },
    {
        "time": 1689847689000,
        "value": 139.51,
        "_internal_originalTime": 1689847689000
    },
    {
        "time": 1689847691000,
        "value": 139.512,
        "_internal_originalTime": 1689847691000
    },
    {
        "time": 1689847693000,
        "value": 139.515,
        "_internal_originalTime": 1689847693000
    },
    {
        "time": 1689847694000,
        "value": 139.515,
        "_internal_originalTime": 1689847694000
    },
    {
        "time": 1689847696000,
        "value": 139.53,
        "_internal_originalTime": 1689847696000
    },
    {
        "time": 1689847697000,
        "value": 139.529,
        "_internal_originalTime": 1689847697000
    },
    {
        "time": 1689847698000,
        "value": 139.529,
        "_internal_originalTime": 1689847698000
    },
    {
        "time": 1689847700000,
        "value": 139.527,
        "_internal_originalTime": 1689847700000
    },
    {
        "time": 1689847701000,
        "value": 139.529,
        "_internal_originalTime": 1689847701000
    },
    {
        "time": 1689847702000,
        "value": 139.525,
        "_internal_originalTime": 1689847702000
    },
    {
        "time": 1689847704000,
        "value": 139.524,
        "_internal_originalTime": 1689847704000
    },
    {
        "time": 1689847705000,
        "value": 139.523,
        "_internal_originalTime": 1689847705000
    },
    {
        "time": 1689847706000,
        "value": 139.519,
        "_internal_originalTime": 1689847706000
    },
    {
        "time": 1689847708000,
        "value": 139.518,
        "_internal_originalTime": 1689847708000
    },
    {
        "time": 1689847710000,
        "value": 139.523,
        "_internal_originalTime": 1689847710000
    },
    {
        "time": 1689847719000,
        "value": 139.523,
        "_internal_originalTime": 1689847719000
    },
    {
        "time": 1689847721000,
        "value": 139.523,
        "_internal_originalTime": 1689847721000
    },
    {
        "time": 1689847723000,
        "value": 139.523,
        "_internal_originalTime": 1689847723000
    },
    {
        "time": 1689847725000,
        "value": 139.523,
        "_internal_originalTime": 1689847725000
    },
    {
        "time": 1689847726000,
        "value": 139.526,
        "_internal_originalTime": 1689847726000
    },
    {
        "time": 1689847727000,
        "value": 139.523,
        "_internal_originalTime": 1689847727000
    },
    {
        "time": 1689847728000,
        "value": 139.526,
        "_internal_originalTime": 1689847728000
    },
    {
        "time": 1689847730000,
        "value": 139.527,
        "_internal_originalTime": 1689847730000
    },
    {
        "time": 1689847731000,
        "value": 139.524,
        "_internal_originalTime": 1689847731000
    },
    {
        "time": 1689847732000,
        "value": 139.52,
        "_internal_originalTime": 1689847732000
    },
    {
        "time": 1689847734000,
        "value": 139.521,
        "_internal_originalTime": 1689847734000
    },
    {
        "time": 1689847736000,
        "value": 139.526,
        "_internal_originalTime": 1689847736000
    },
    {
        "time": 1689847737000,
        "value": 139.527,
        "_internal_originalTime": 1689847737000
    },
    {
        "time": 1689847738000,
        "value": 139.523,
        "_internal_originalTime": 1689847738000
    },
    {
        "time": 1689847740000,
        "value": 139.51,
        "_internal_originalTime": 1689847740000
    },
    {
        "time": 1689847741000,
        "value": 139.521,
        "_internal_originalTime": 1689847741000
    },
    {
        "time": 1689847743000,
        "value": 139.519,
        "_internal_originalTime": 1689847743000
    },
    {
        "time": 1689847744000,
        "value": 139.525,
        "_internal_originalTime": 1689847744000
    },
    {
        "time": 1689847745000,
        "value": 139.526,
        "_internal_originalTime": 1689847745000
    },
    {
        "time": 1689847746000,
        "value": 139.528,
        "_internal_originalTime": 1689847746000
    },
    {
        "time": 1689847747000,
        "value": 139.528,
        "_internal_originalTime": 1689847747000
    },
    {
        "time": 1689847749000,
        "value": 139.524,
        "_internal_originalTime": 1689847749000
    },
    {
        "time": 1689847750000,
        "value": 139.523,
        "_internal_originalTime": 1689847750000
    },
    {
        "time": 1689847752000,
        "value": 139.524,
        "_internal_originalTime": 1689847752000
    },
    {
        "time": 1689847753000,
        "value": 139.534,
        "_internal_originalTime": 1689847753000
    },
    {
        "time": 1689847754000,
        "value": 139.534,
        "_internal_originalTime": 1689847754000
    },
    {
        "time": 1689847756000,
        "value": 139.535,
        "_internal_originalTime": 1689847756000
    },
    {
        "time": 1689847757000,
        "value": 139.535,
        "_internal_originalTime": 1689847757000
    },
    {
        "time": 1689847758000,
        "value": 139.534,
        "_internal_originalTime": 1689847758000
    },
    {
        "time": 1689847763000,
        "value": 139.537,
        "_internal_originalTime": 1689847763000
    },
    {
        "time": 1689847764000,
        "value": 139.538,
        "_internal_originalTime": 1689847764000
    },
    {
        "time": 1689847766000,
        "value": 139.537,
        "_internal_originalTime": 1689847766000
    },
    {
        "time": 1689847767000,
        "value": 139.538,
        "_internal_originalTime": 1689847767000
    },
    {
        "time": 1689847768000,
        "value": 139.53,
        "_internal_originalTime": 1689847768000
    },
    {
        "time": 1689847769000,
        "value": 139.536,
        "_internal_originalTime": 1689847769000
    },
    {
        "time": 1689847770000,
        "value": 139.536,
        "_internal_originalTime": 1689847770000
    },
    {
        "time": 1689847772000,
        "value": 139.536,
        "_internal_originalTime": 1689847772000
    },
    {
        "time": 1689847773000,
        "value": 139.532,
        "_internal_originalTime": 1689847773000
    },
    {
        "time": 1689847775000,
        "value": 139.528,
        "_internal_originalTime": 1689847775000
    },
    {
        "time": 1689847777000,
        "value": 139.534,
        "_internal_originalTime": 1689847777000
    },
    {
        "time": 1689847779000,
        "value": 139.528,
        "_internal_originalTime": 1689847779000
    },
    {
        "time": 1689847781000,
        "value": 139.53,
        "_internal_originalTime": 1689847781000
    },
    {
        "time": 1689847782000,
        "value": 139.53,
        "_internal_originalTime": 1689847782000
    },
    {
        "time": 1689847783000,
        "value": 139.533,
        "_internal_originalTime": 1689847783000
    },
    {
        "time": 1689847784000,
        "value": 139.53,
        "_internal_originalTime": 1689847784000
    },
    {
        "time": 1689847785000,
        "value": 139.53,
        "_internal_originalTime": 1689847785000
    },
    {
        "time": 1689847788000,
        "value": 139.541,
        "_internal_originalTime": 1689847788000
    },
    {
        "time": 1689847789000,
        "value": 139.533,
        "_internal_originalTime": 1689847789000
    },
    {
        "time": 1689847790000,
        "value": 139.538,
        "_internal_originalTime": 1689847790000
    },
    {
        "time": 1689847791000,
        "value": 139.536,
        "_internal_originalTime": 1689847791000
    },
    {
        "time": 1689847793000,
        "value": 139.532,
        "_internal_originalTime": 1689847793000
    },
    {
        "time": 1689847795000,
        "value": 139.528,
        "_internal_originalTime": 1689847795000
    },
    {
        "time": 1689847796000,
        "value": 139.526,
        "_internal_originalTime": 1689847796000
    },
    {
        "time": 1689847797000,
        "value": 139.518,
        "_internal_originalTime": 1689847797000
    },
    {
        "time": 1689847800000,
        "value": 139.517,
        "_internal_originalTime": 1689847800000
    },
    {
        "time": 1689847801000,
        "value": 139.521,
        "_internal_originalTime": 1689847801000
    },
    {
        "time": 1689847802000,
        "value": 139.51,
        "_internal_originalTime": 1689847802000
    },
    {
        "time": 1689847803000,
        "value": 139.505,
        "_internal_originalTime": 1689847803000
    },
    {
        "time": 1689847806000,
        "value": 139.498,
        "_internal_originalTime": 1689847806000
    },
    {
        "time": 1689847807000,
        "value": 139.504,
        "_internal_originalTime": 1689847807000
    },
    {
        "time": 1689847808000,
        "value": 139.501,
        "_internal_originalTime": 1689847808000
    },
    {
        "time": 1689847810000,
        "value": 139.504,
        "_internal_originalTime": 1689847810000
    },
    {
        "time": 1689847811000,
        "value": 139.499,
        "_internal_originalTime": 1689847811000
    },
    {
        "time": 1689847812000,
        "value": 139.502,
        "_internal_originalTime": 1689847812000
    },
    {
        "time": 1689847815000,
        "value": 139.502,
        "_internal_originalTime": 1689847815000
    },
    {
        "time": 1689847816000,
        "value": 139.487,
        "_internal_originalTime": 1689847816000
    },
    {
        "time": 1689847817000,
        "value": 139.486,
        "_internal_originalTime": 1689847817000
    },
    {
        "time": 1689847818000,
        "value": 139.492,
        "_internal_originalTime": 1689847818000
    },
    {
        "time": 1689847819000,
        "value": 139.495,
        "_internal_originalTime": 1689847819000
    },
    {
        "time": 1689847820000,
        "value": 139.494,
        "_internal_originalTime": 1689847820000
    },
    {
        "time": 1689847821000,
        "value": 139.488,
        "_internal_originalTime": 1689847821000
    },
    {
        "time": 1689847823000,
        "value": 139.49,
        "_internal_originalTime": 1689847823000
    },
    {
        "time": 1689847824000,
        "value": 139.489,
        "_internal_originalTime": 1689847824000
    },
    {
        "time": 1689847825000,
        "value": 139.493,
        "_internal_originalTime": 1689847825000
    },
    {
        "time": 1689847827000,
        "value": 139.487,
        "_internal_originalTime": 1689847827000
    },
    {
        "time": 1689847829000,
        "value": 139.492,
        "_internal_originalTime": 1689847829000
    },
    {
        "time": 1689847831000,
        "value": 139.497,
        "_internal_originalTime": 1689847831000
    },
    {
        "time": 1689847832000,
        "value": 139.492,
        "_internal_originalTime": 1689847832000
    },
    {
        "time": 1689847833000,
        "value": 139.492,
        "_internal_originalTime": 1689847833000
    },
    {
        "time": 1689847835000,
        "value": 139.495,
        "_internal_originalTime": 1689847835000
    },
    {
        "time": 1689847836000,
        "value": 139.487,
        "_internal_originalTime": 1689847836000
    },
    {
        "time": 1689847837000,
        "value": 139.492,
        "_internal_originalTime": 1689847837000
    },
    {
        "time": 1689847838000,
        "value": 139.492,
        "_internal_originalTime": 1689847838000
    },
    {
        "time": 1689847840000,
        "value": 139.488,
        "_internal_originalTime": 1689847840000
    },
    {
        "time": 1689847841000,
        "value": 139.492,
        "_internal_originalTime": 1689847841000
    },
    {
        "time": 1689847843000,
        "value": 139.489,
        "_internal_originalTime": 1689847843000
    },
    {
        "time": 1689847844000,
        "value": 139.489,
        "_internal_originalTime": 1689847844000
    },
    {
        "time": 1689847845000,
        "value": 139.487,
        "_internal_originalTime": 1689847845000
    },
    {
        "time": 1689847847000,
        "value": 139.491,
        "_internal_originalTime": 1689847847000
    },
    {
        "time": 1689847848000,
        "value": 139.488,
        "_internal_originalTime": 1689847848000
    },
    {
        "time": 1689847850000,
        "value": 139.491,
        "_internal_originalTime": 1689847850000
    },
    {
        "time": 1689847851000,
        "value": 139.486,
        "_internal_originalTime": 1689847851000
    },
    {
        "time": 1689847852000,
        "value": 139.487,
        "_internal_originalTime": 1689847852000
    },
    {
        "time": 1689847854000,
        "value": 139.487,
        "_internal_originalTime": 1689847854000
    },
    {
        "time": 1689847855000,
        "value": 139.488,
        "_internal_originalTime": 1689847855000
    },
    {
        "time": 1689847857000,
        "value": 139.49,
        "_internal_originalTime": 1689847857000
    },
    {
        "time": 1689847858000,
        "value": 139.491,
        "_internal_originalTime": 1689847858000
    },
    {
        "time": 1689847860000,
        "value": 139.486,
        "_internal_originalTime": 1689847860000
    },
    {
        "time": 1689847861000,
        "value": 139.487,
        "_internal_originalTime": 1689847861000
    },
    {
        "time": 1689847862000,
        "value": 139.491,
        "_internal_originalTime": 1689847862000
    },
    {
        "time": 1689847864000,
        "value": 139.487,
        "_internal_originalTime": 1689847864000
    },
    {
        "time": 1689847866000,
        "value": 139.486,
        "_internal_originalTime": 1689847866000
    },
    {
        "time": 1689847867000,
        "value": 139.489,
        "_internal_originalTime": 1689847867000
    },
    {
        "time": 1689847869000,
        "value": 139.489,
        "_internal_originalTime": 1689847869000
    },
    {
        "time": 1689847870000,
        "value": 139.491,
        "_internal_originalTime": 1689847870000
    },
    {
        "time": 1689847871000,
        "value": 139.49,
        "_internal_originalTime": 1689847871000
    },
    {
        "time": 1689847873000,
        "value": 139.493,
        "_internal_originalTime": 1689847873000
    },
    {
        "time": 1689847875000,
        "value": 139.496,
        "_internal_originalTime": 1689847875000
    },
    {
        "time": 1689847876000,
        "value": 139.491,
        "_internal_originalTime": 1689847876000
    },
    {
        "time": 1689847877000,
        "value": 139.496,
        "_internal_originalTime": 1689847877000
    },
    {
        "time": 1689847879000,
        "value": 139.496,
        "_internal_originalTime": 1689847879000
    },
    {
        "time": 1689847880000,
        "value": 139.498,
        "_internal_originalTime": 1689847880000
    },
    {
        "time": 1689847881000,
        "value": 139.506,
        "_internal_originalTime": 1689847881000
    },
    {
        "time": 1689847882000,
        "value": 139.5,
        "_internal_originalTime": 1689847882000
    },
    {
        "time": 1689847883000,
        "value": 139.5,
        "_internal_originalTime": 1689847883000
    },
    {
        "time": 1689847885000,
        "value": 139.504,
        "_internal_originalTime": 1689847885000
    },
    {
        "time": 1689847891000,
        "value": 139.505,
        "_internal_originalTime": 1689847891000
    },
    {
        "time": 1689847893000,
        "value": 139.504,
        "_internal_originalTime": 1689847893000
    },
    {
        "time": 1689847894000,
        "value": 139.499,
        "_internal_originalTime": 1689847894000
    },
    {
        "time": 1689847896000,
        "value": 139.505,
        "_internal_originalTime": 1689847896000
    },
    {
        "time": 1689847897000,
        "value": 139.507,
        "_internal_originalTime": 1689847897000
    },
    {
        "time": 1689847898000,
        "value": 139.499,
        "_internal_originalTime": 1689847898000
    },
    {
        "time": 1689847900000,
        "value": 139.497,
        "_internal_originalTime": 1689847900000
    },
    {
        "time": 1689847901000,
        "value": 139.504,
        "_internal_originalTime": 1689847901000
    },
    {
        "time": 1689847903000,
        "value": 139.504,
        "_internal_originalTime": 1689847903000
    },
    {
        "time": 1689847904000,
        "value": 139.504,
        "_internal_originalTime": 1689847904000
    },
    {
        "time": 1689847905000,
        "value": 139.499,
        "_internal_originalTime": 1689847905000
    },
    {
        "time": 1689847906000,
        "value": 139.498,
        "_internal_originalTime": 1689847906000
    },
    {
        "time": 1689847908000,
        "value": 139.506,
        "_internal_originalTime": 1689847908000
    },
    {
        "time": 1689847910000,
        "value": 139.506,
        "_internal_originalTime": 1689847910000
    },
    {
        "time": 1689847914000,
        "value": 139.498,
        "_internal_originalTime": 1689847914000
    },
    {
        "time": 1689847915000,
        "value": 139.505,
        "_internal_originalTime": 1689847915000
    },
    {
        "time": 1689847917000,
        "value": 139.499,
        "_internal_originalTime": 1689847917000
    },
    {
        "time": 1689847918000,
        "value": 139.49,
        "_internal_originalTime": 1689847918000
    },
    {
        "time": 1689847919000,
        "value": 139.488,
        "_internal_originalTime": 1689847919000
    },
    {
        "time": 1689847922000,
        "value": 139.499,
        "_internal_originalTime": 1689847922000
    },
    {
        "time": 1689847923000,
        "value": 139.5,
        "_internal_originalTime": 1689847923000
    },
    {
        "time": 1689847924000,
        "value": 139.495,
        "_internal_originalTime": 1689847924000
    },
    {
        "time": 1689847926000,
        "value": 139.489,
        "_internal_originalTime": 1689847926000
    },
    {
        "time": 1689847927000,
        "value": 139.492,
        "_internal_originalTime": 1689847927000
    },
    {
        "time": 1689847928000,
        "value": 139.489,
        "_internal_originalTime": 1689847928000
    },
    {
        "time": 1689847930000,
        "value": 139.489,
        "_internal_originalTime": 1689847930000
    },
    {
        "time": 1689847931000,
        "value": 139.49,
        "_internal_originalTime": 1689847931000
    },
    {
        "time": 1689847937000,
        "value": 139.493,
        "_internal_originalTime": 1689847937000
    },
    {
        "time": 1689847938000,
        "value": 139.495,
        "_internal_originalTime": 1689847938000
    },
    {
        "time": 1689847940000,
        "value": 139.495,
        "_internal_originalTime": 1689847940000
    },
    {
        "time": 1689847941000,
        "value": 139.489,
        "_internal_originalTime": 1689847941000
    },
    {
        "time": 1689847942000,
        "value": 139.495,
        "_internal_originalTime": 1689847942000
    },
    {
        "time": 1689847945000,
        "value": 139.494,
        "_internal_originalTime": 1689847945000
    },
    {
        "time": 1689847949000,
        "value": 139.503,
        "_internal_originalTime": 1689847949000
    },
    {
        "time": 1689847951000,
        "value": 139.497,
        "_internal_originalTime": 1689847951000
    },
    {
        "time": 1689847952000,
        "value": 139.493,
        "_internal_originalTime": 1689847952000
    },
    {
        "time": 1689847953000,
        "value": 139.499,
        "_internal_originalTime": 1689847953000
    },
    {
        "time": 1689847954000,
        "value": 139.499,
        "_internal_originalTime": 1689847954000
    },
    {
        "time": 1689847956000,
        "value": 139.497,
        "_internal_originalTime": 1689847956000
    },
    {
        "time": 1689847957000,
        "value": 139.495,
        "_internal_originalTime": 1689847957000
    },
    {
        "time": 1689847960000,
        "value": 139.495,
        "_internal_originalTime": 1689847960000
    },
    {
        "time": 1689847961000,
        "value": 139.49,
        "_internal_originalTime": 1689847961000
    },
    {
        "time": 1689847963000,
        "value": 139.494,
        "_internal_originalTime": 1689847963000
    },
    {
        "time": 1689847965000,
        "value": 139.5,
        "_internal_originalTime": 1689847965000
    },
    {
        "time": 1689847966000,
        "value": 139.494,
        "_internal_originalTime": 1689847966000
    },
    {
        "time": 1689847969000,
        "value": 139.497,
        "_internal_originalTime": 1689847969000
    },
    {
        "time": 1689847975000,
        "value": 139.497,
        "_internal_originalTime": 1689847975000
    },
    {
        "time": 1689847979000,
        "value": 139.496,
        "_internal_originalTime": 1689847979000
    },
    {
        "time": 1689847980000,
        "value": 139.497,
        "_internal_originalTime": 1689847980000
    },
    {
        "time": 1689847984000,
        "value": 139.495,
        "_internal_originalTime": 1689847984000
    },
    {
        "time": 1689847985000,
        "value": 139.497,
        "_internal_originalTime": 1689847985000
    },
    {
        "time": 1689847986000,
        "value": 139.492,
        "_internal_originalTime": 1689847986000
    },
    {
        "time": 1689847987000,
        "value": 139.496,
        "_internal_originalTime": 1689847987000
    },
    {
        "time": 1689847989000,
        "value": 139.488,
        "_internal_originalTime": 1689847989000
    },
    {
        "time": 1689847991000,
        "value": 139.48,
        "_internal_originalTime": 1689847991000
    },
    {
        "time": 1689847992000,
        "value": 139.481,
        "_internal_originalTime": 1689847992000
    },
    {
        "time": 1689847993000,
        "value": 139.487,
        "_internal_originalTime": 1689847993000
    },
    {
        "time": 1689847995000,
        "value": 139.492,
        "_internal_originalTime": 1689847995000
    },
    {
        "time": 1689847997000,
        "value": 139.489,
        "_internal_originalTime": 1689847997000
    },
    {
        "time": 1689847998000,
        "value": 139.487,
        "_internal_originalTime": 1689847998000
    },
    {
        "time": 1689847999000,
        "value": 139.488,
        "_internal_originalTime": 1689847999000
    },
    {
        "time": 1689848001000,
        "value": 139.487,
        "_internal_originalTime": 1689848001000
    },
    {
        "time": 1689848002000,
        "value": 139.486,
        "_internal_originalTime": 1689848002000
    },
    {
        "time": 1689848003000,
        "value": 139.485,
        "_internal_originalTime": 1689848003000
    },
    {
        "time": 1689848006000,
        "value": 139.481,
        "_internal_originalTime": 1689848006000
    },
    {
        "time": 1689848007000,
        "value": 139.485,
        "_internal_originalTime": 1689848007000
    },
    {
        "time": 1689848009000,
        "value": 139.486,
        "_internal_originalTime": 1689848009000
    },
    {
        "time": 1689848010000,
        "value": 139.485,
        "_internal_originalTime": 1689848010000
    },
    {
        "time": 1689848012000,
        "value": 139.48,
        "_internal_originalTime": 1689848012000
    },
    {
        "time": 1689848014000,
        "value": 139.48,
        "_internal_originalTime": 1689848014000
    },
    {
        "time": 1689848015000,
        "value": 139.481,
        "_internal_originalTime": 1689848015000
    },
    {
        "time": 1689848017000,
        "value": 139.48,
        "_internal_originalTime": 1689848017000
    },
    {
        "time": 1689848018000,
        "value": 139.483,
        "_internal_originalTime": 1689848018000
    },
    {
        "time": 1689848019000,
        "value": 139.483,
        "_internal_originalTime": 1689848019000
    },
    {
        "time": 1689848021000,
        "value": 139.478,
        "_internal_originalTime": 1689848021000
    },
    {
        "time": 1689848022000,
        "value": 139.484,
        "_internal_originalTime": 1689848022000
    },
    {
        "time": 1689848023000,
        "value": 139.48,
        "_internal_originalTime": 1689848023000
    },
    {
        "time": 1689848025000,
        "value": 139.482,
        "_internal_originalTime": 1689848025000
    },
    {
        "time": 1689848026000,
        "value": 139.481,
        "_internal_originalTime": 1689848026000
    },
    {
        "time": 1689848029000,
        "value": 139.483,
        "_internal_originalTime": 1689848029000
    },
    {
        "time": 1689848031000,
        "value": 139.477,
        "_internal_originalTime": 1689848031000
    },
    {
        "time": 1689848032000,
        "value": 139.475,
        "_internal_originalTime": 1689848032000
    },
    {
        "time": 1689848034000,
        "value": 139.473,
        "_internal_originalTime": 1689848034000
    },
    {
        "time": 1689848038000,
        "value": 139.479,
        "_internal_originalTime": 1689848038000
    },
    {
        "time": 1689848040000,
        "value": 139.478,
        "_internal_originalTime": 1689848040000
    },
    {
        "time": 1689848042000,
        "value": 139.479,
        "_internal_originalTime": 1689848042000
    },
    {
        "time": 1689848043000,
        "value": 139.479,
        "_internal_originalTime": 1689848043000
    },
    {
        "time": 1689848044000,
        "value": 139.479,
        "_internal_originalTime": 1689848044000
    },
    {
        "time": 1689848045000,
        "value": 139.479,
        "_internal_originalTime": 1689848045000
    },
    {
        "time": 1689848050000,
        "value": 139.493,
        "_internal_originalTime": 1689848050000
    },
    {
        "time": 1689848051000,
        "value": 139.49,
        "_internal_originalTime": 1689848051000
    },
    {
        "time": 1689848052000,
        "value": 139.494,
        "_internal_originalTime": 1689848052000
    },
    {
        "time": 1689848054000,
        "value": 139.492,
        "_internal_originalTime": 1689848054000
    },
    {
        "time": 1689848055000,
        "value": 139.49,
        "_internal_originalTime": 1689848055000
    },
    {
        "time": 1689848056000,
        "value": 139.491,
        "_internal_originalTime": 1689848056000
    },
    {
        "time": 1689848060000,
        "value": 139.482,
        "_internal_originalTime": 1689848060000
    },
    {
        "time": 1689848061000,
        "value": 139.483,
        "_internal_originalTime": 1689848061000
    },
    {
        "time": 1689848063000,
        "value": 139.485,
        "_internal_originalTime": 1689848063000
    },
    {
        "time": 1689848064000,
        "value": 139.487,
        "_internal_originalTime": 1689848064000
    },
    {
        "time": 1689848066000,
        "value": 139.487,
        "_internal_originalTime": 1689848066000
    },
    {
        "time": 1689848067000,
        "value": 139.484,
        "_internal_originalTime": 1689848067000
    },
    {
        "time": 1689848068000,
        "value": 139.487,
        "_internal_originalTime": 1689848068000
    },
    {
        "time": 1689848070000,
        "value": 139.487,
        "_internal_originalTime": 1689848070000
    },
    {
        "time": 1689848071000,
        "value": 139.483,
        "_internal_originalTime": 1689848071000
    },
    {
        "time": 1689848072000,
        "value": 139.486,
        "_internal_originalTime": 1689848072000
    },
    {
        "time": 1689848073000,
        "value": 139.483,
        "_internal_originalTime": 1689848073000
    },
    {
        "time": 1689848075000,
        "value": 139.484,
        "_internal_originalTime": 1689848075000
    },
    {
        "time": 1689848076000,
        "value": 139.48,
        "_internal_originalTime": 1689848076000
    },
    {
        "time": 1689848077000,
        "value": 139.48,
        "_internal_originalTime": 1689848077000
    },
    {
        "time": 1689848079000,
        "value": 139.478,
        "_internal_originalTime": 1689848079000
    },
    {
        "time": 1689848080000,
        "value": 139.479,
        "_internal_originalTime": 1689848080000
    },
    {
        "time": 1689848081000,
        "value": 139.48,
        "_internal_originalTime": 1689848081000
    },
    {
        "time": 1689848082000,
        "value": 139.484,
        "_internal_originalTime": 1689848082000
    },
    {
        "time": 1689848084000,
        "value": 139.484,
        "_internal_originalTime": 1689848084000
    },
    {
        "time": 1689848086000,
        "value": 139.484,
        "_internal_originalTime": 1689848086000
    },
    {
        "time": 1689848088000,
        "value": 139.482,
        "_internal_originalTime": 1689848088000
    },
    {
        "time": 1689848089000,
        "value": 139.478,
        "_internal_originalTime": 1689848089000
    },
    {
        "time": 1689848090000,
        "value": 139.484,
        "_internal_originalTime": 1689848090000
    },
    {
        "time": 1689848092000,
        "value": 139.483,
        "_internal_originalTime": 1689848092000
    },
    {
        "time": 1689848093000,
        "value": 139.485,
        "_internal_originalTime": 1689848093000
    },
    {
        "time": 1689848094000,
        "value": 139.482,
        "_internal_originalTime": 1689848094000
    },
    {
        "time": 1689848096000,
        "value": 139.485,
        "_internal_originalTime": 1689848096000
    },
    {
        "time": 1689848098000,
        "value": 139.481,
        "_internal_originalTime": 1689848098000
    },
    {
        "time": 1689848100000,
        "value": 139.483,
        "_internal_originalTime": 1689848100000
    },
    {
        "time": 1689848102000,
        "value": 139.481,
        "_internal_originalTime": 1689848102000
    },
    {
        "time": 1689848104000,
        "value": 139.479,
        "_internal_originalTime": 1689848104000
    },
    {
        "time": 1689848106000,
        "value": 139.482,
        "_internal_originalTime": 1689848106000
    },
    {
        "time": 1689848107000,
        "value": 139.481,
        "_internal_originalTime": 1689848107000
    },
    {
        "time": 1689848108000,
        "value": 139.479,
        "_internal_originalTime": 1689848108000
    },
    {
        "time": 1689848110000,
        "value": 139.479,
        "_internal_originalTime": 1689848110000
    },
    {
        "time": 1689848111000,
        "value": 139.483,
        "_internal_originalTime": 1689848111000
    },
    {
        "time": 1689848112000,
        "value": 139.483,
        "_internal_originalTime": 1689848112000
    },
    {
        "time": 1689848114000,
        "value": 139.484,
        "_internal_originalTime": 1689848114000
    },
    {
        "time": 1689848117000,
        "value": 139.482,
        "_internal_originalTime": 1689848117000
    },
    {
        "time": 1689848120000,
        "value": 139.476,
        "_internal_originalTime": 1689848120000
    },
    {
        "time": 1689848122000,
        "value": 139.483,
        "_internal_originalTime": 1689848122000
    },
    {
        "time": 1689848123000,
        "value": 139.484,
        "_internal_originalTime": 1689848123000
    },
    {
        "time": 1689848126000,
        "value": 139.48,
        "_internal_originalTime": 1689848126000
    },
    {
        "time": 1689848127000,
        "value": 139.484,
        "_internal_originalTime": 1689848127000
    },
    {
        "time": 1689848128000,
        "value": 139.483,
        "_internal_originalTime": 1689848128000
    },
    {
        "time": 1689848129000,
        "value": 139.483,
        "_internal_originalTime": 1689848129000
    },
    {
        "time": 1689848130000,
        "value": 139.484,
        "_internal_originalTime": 1689848130000
    },
    {
        "time": 1689848132000,
        "value": 139.484,
        "_internal_originalTime": 1689848132000
    },
    {
        "time": 1689848133000,
        "value": 139.48,
        "_internal_originalTime": 1689848133000
    },
    {
        "time": 1689848134000,
        "value": 139.479,
        "_internal_originalTime": 1689848134000
    },
    {
        "time": 1689848136000,
        "value": 139.479,
        "_internal_originalTime": 1689848136000
    },
    {
        "time": 1689848137000,
        "value": 139.484,
        "_internal_originalTime": 1689848137000
    },
    {
        "time": 1689848139000,
        "value": 139.483,
        "_internal_originalTime": 1689848139000
    },
    {
        "time": 1689848141000,
        "value": 139.474,
        "_internal_originalTime": 1689848141000
    },
    {
        "time": 1689848142000,
        "value": 139.477,
        "_internal_originalTime": 1689848142000
    },
    {
        "time": 1689848143000,
        "value": 139.472,
        "_internal_originalTime": 1689848143000
    },
    {
        "time": 1689848146000,
        "value": 139.47,
        "_internal_originalTime": 1689848146000
    },
    {
        "time": 1689848148000,
        "value": 139.47,
        "_internal_originalTime": 1689848148000
    },
    {
        "time": 1689848149000,
        "value": 139.474,
        "_internal_originalTime": 1689848149000
    },
    {
        "time": 1689848150000,
        "value": 139.472,
        "_internal_originalTime": 1689848150000
    },
    {
        "time": 1689848157000,
        "value": 139.48,
        "_internal_originalTime": 1689848157000
    },
    {
        "time": 1689848159000,
        "value": 139.481,
        "_internal_originalTime": 1689848159000
    },
    {
        "time": 1689848160000,
        "value": 139.477,
        "_internal_originalTime": 1689848160000
    },
    {
        "time": 1689848161000,
        "value": 139.477,
        "_internal_originalTime": 1689848161000
    },
    {
        "time": 1689848163000,
        "value": 139.476,
        "_internal_originalTime": 1689848163000
    },
    {
        "time": 1689848165000,
        "value": 139.472,
        "_internal_originalTime": 1689848165000
    },
    {
        "time": 1689848166000,
        "value": 139.475,
        "_internal_originalTime": 1689848166000
    },
    {
        "time": 1689848168000,
        "value": 139.475,
        "_internal_originalTime": 1689848168000
    },
    {
        "time": 1689848169000,
        "value": 139.473,
        "_internal_originalTime": 1689848169000
    },
    {
        "time": 1689848171000,
        "value": 139.468,
        "_internal_originalTime": 1689848171000
    },
    {
        "time": 1689848172000,
        "value": 139.475,
        "_internal_originalTime": 1689848172000
    },
    {
        "time": 1689848173000,
        "value": 139.47,
        "_internal_originalTime": 1689848173000
    },
    {
        "time": 1689848175000,
        "value": 139.475,
        "_internal_originalTime": 1689848175000
    },
    {
        "time": 1689848176000,
        "value": 139.475,
        "_internal_originalTime": 1689848176000
    },
    {
        "time": 1689848179000,
        "value": 139.475,
        "_internal_originalTime": 1689848179000
    },
    {
        "time": 1689848181000,
        "value": 139.475,
        "_internal_originalTime": 1689848181000
    },
    {
        "time": 1689848182000,
        "value": 139.472,
        "_internal_originalTime": 1689848182000
    },
    {
        "time": 1689848183000,
        "value": 139.47,
        "_internal_originalTime": 1689848183000
    },
    {
        "time": 1689848185000,
        "value": 139.464,
        "_internal_originalTime": 1689848185000
    },
    {
        "time": 1689848186000,
        "value": 139.467,
        "_internal_originalTime": 1689848186000
    },
    {
        "time": 1689848188000,
        "value": 139.467,
        "_internal_originalTime": 1689848188000
    },
    {
        "time": 1689848190000,
        "value": 139.464,
        "_internal_originalTime": 1689848190000
    },
    {
        "time": 1689848191000,
        "value": 139.459,
        "_internal_originalTime": 1689848191000
    },
    {
        "time": 1689848193000,
        "value": 139.463,
        "_internal_originalTime": 1689848193000
    },
    {
        "time": 1689848194000,
        "value": 139.456,
        "_internal_originalTime": 1689848194000
    },
    {
        "time": 1689848196000,
        "value": 139.452,
        "_internal_originalTime": 1689848196000
    },
    {
        "time": 1689848197000,
        "value": 139.454,
        "_internal_originalTime": 1689848197000
    },
    {
        "time": 1689848198000,
        "value": 139.448,
        "_internal_originalTime": 1689848198000
    },
    {
        "time": 1689848200000,
        "value": 139.447,
        "_internal_originalTime": 1689848200000
    },
    {
        "time": 1689848201000,
        "value": 139.44,
        "_internal_originalTime": 1689848201000
    },
    {
        "time": 1689848203000,
        "value": 139.448,
        "_internal_originalTime": 1689848203000
    },
    {
        "time": 1689848205000,
        "value": 139.44,
        "_internal_originalTime": 1689848205000
    },
    {
        "time": 1689848206000,
        "value": 139.438,
        "_internal_originalTime": 1689848206000
    },
    {
        "time": 1689848208000,
        "value": 139.433,
        "_internal_originalTime": 1689848208000
    },
    {
        "time": 1689848212000,
        "value": 139.444,
        "_internal_originalTime": 1689848212000
    },
    {
        "time": 1689848213000,
        "value": 139.44,
        "_internal_originalTime": 1689848213000
    },
    {
        "time": 1689848215000,
        "value": 139.441,
        "_internal_originalTime": 1689848215000
    },
    {
        "time": 1689848216000,
        "value": 139.437,
        "_internal_originalTime": 1689848216000
    },
    {
        "time": 1689848217000,
        "value": 139.436,
        "_internal_originalTime": 1689848217000
    },
    {
        "time": 1689848219000,
        "value": 139.437,
        "_internal_originalTime": 1689848219000
    },
    {
        "time": 1689848220000,
        "value": 139.438,
        "_internal_originalTime": 1689848220000
    },
    {
        "time": 1689848221000,
        "value": 139.438,
        "_internal_originalTime": 1689848221000
    },
    {
        "time": 1689848222000,
        "value": 139.438,
        "_internal_originalTime": 1689848222000
    },
    {
        "time": 1689848224000,
        "value": 139.43,
        "_internal_originalTime": 1689848224000
    },
    {
        "time": 1689848225000,
        "value": 139.429,
        "_internal_originalTime": 1689848225000
    },
    {
        "time": 1689848227000,
        "value": 139.436,
        "_internal_originalTime": 1689848227000
    },
    {
        "time": 1689848228000,
        "value": 139.432,
        "_internal_originalTime": 1689848228000
    },
    {
        "time": 1689848229000,
        "value": 139.433,
        "_internal_originalTime": 1689848229000
    },
    {
        "time": 1689848231000,
        "value": 139.431,
        "_internal_originalTime": 1689848231000
    },
    {
        "time": 1689848233000,
        "value": 139.43,
        "_internal_originalTime": 1689848233000
    },
    {
        "time": 1689848235000,
        "value": 139.436,
        "_internal_originalTime": 1689848235000
    },
    {
        "time": 1689848236000,
        "value": 139.436,
        "_internal_originalTime": 1689848236000
    },
    {
        "time": 1689848237000,
        "value": 139.436,
        "_internal_originalTime": 1689848237000
    },
    {
        "time": 1689848240000,
        "value": 139.436,
        "_internal_originalTime": 1689848240000
    },
    {
        "time": 1689848242000,
        "value": 139.436,
        "_internal_originalTime": 1689848242000
    },
    {
        "time": 1689848243000,
        "value": 139.434,
        "_internal_originalTime": 1689848243000
    },
    {
        "time": 1689848245000,
        "value": 139.434,
        "_internal_originalTime": 1689848245000
    },
    {
        "time": 1689848246000,
        "value": 139.433,
        "_internal_originalTime": 1689848246000
    },
    {
        "time": 1689848250000,
        "value": 139.431,
        "_internal_originalTime": 1689848250000
    },
    {
        "time": 1689848251000,
        "value": 139.436,
        "_internal_originalTime": 1689848251000
    },
    {
        "time": 1689848252000,
        "value": 139.436,
        "_internal_originalTime": 1689848252000
    },
    {
        "time": 1689848253000,
        "value": 139.436,
        "_internal_originalTime": 1689848253000
    },
    {
        "time": 1689848255000,
        "value": 139.438,
        "_internal_originalTime": 1689848255000
    },
    {
        "time": 1689848258000,
        "value": 139.433,
        "_internal_originalTime": 1689848258000
    },
    {
        "time": 1689848259000,
        "value": 139.432,
        "_internal_originalTime": 1689848259000
    },
    {
        "time": 1689848261000,
        "value": 139.43,
        "_internal_originalTime": 1689848261000
    },
    {
        "time": 1689848264000,
        "value": 139.433,
        "_internal_originalTime": 1689848264000
    },
    {
        "time": 1689848265000,
        "value": 139.436,
        "_internal_originalTime": 1689848265000
    },
    {
        "time": 1689848266000,
        "value": 139.43,
        "_internal_originalTime": 1689848266000
    },
    {
        "time": 1689848268000,
        "value": 139.422,
        "_internal_originalTime": 1689848268000
    },
    {
        "time": 1689848269000,
        "value": 139.424,
        "_internal_originalTime": 1689848269000
    },
    {
        "time": 1689848270000,
        "value": 139.427,
        "_internal_originalTime": 1689848270000
    },
    {
        "time": 1689848271000,
        "value": 139.428,
        "_internal_originalTime": 1689848271000
    },
    {
        "time": 1689848273000,
        "value": 139.427,
        "_internal_originalTime": 1689848273000
    },
    {
        "time": 1689848274000,
        "value": 139.425,
        "_internal_originalTime": 1689848274000
    },
    {
        "time": 1689848276000,
        "value": 139.427,
        "_internal_originalTime": 1689848276000
    },
    {
        "time": 1689848279000,
        "value": 139.427,
        "_internal_originalTime": 1689848279000
    },
    {
        "time": 1689848281000,
        "value": 139.425,
        "_internal_originalTime": 1689848281000
    },
    {
        "time": 1689848282000,
        "value": 139.423,
        "_internal_originalTime": 1689848282000
    },
    {
        "time": 1689848284000,
        "value": 139.427,
        "_internal_originalTime": 1689848284000
    },
    {
        "time": 1689848285000,
        "value": 139.427,
        "_internal_originalTime": 1689848285000
    },
    {
        "time": 1689848286000,
        "value": 139.425,
        "_internal_originalTime": 1689848286000
    },
    {
        "time": 1689848287000,
        "value": 139.427,
        "_internal_originalTime": 1689848287000
    },
    {
        "time": 1689848290000,
        "value": 139.428,
        "_internal_originalTime": 1689848290000
    },
    {
        "time": 1689848291000,
        "value": 139.425,
        "_internal_originalTime": 1689848291000
    },
    {
        "time": 1689848293000,
        "value": 139.422,
        "_internal_originalTime": 1689848293000
    },
    {
        "time": 1689848294000,
        "value": 139.425,
        "_internal_originalTime": 1689848294000
    },
    {
        "time": 1689848295000,
        "value": 139.428,
        "_internal_originalTime": 1689848295000
    },
    {
        "time": 1689848296000,
        "value": 139.433,
        "_internal_originalTime": 1689848296000
    },
    {
        "time": 1689848297000,
        "value": 139.433,
        "_internal_originalTime": 1689848297000
    },
    {
        "time": 1689848298000,
        "value": 139.433,
        "_internal_originalTime": 1689848298000
    },
    {
        "time": 1689848301000,
        "value": 139.432,
        "_internal_originalTime": 1689848301000
    },
    {
        "time": 1689848303000,
        "value": 139.427,
        "_internal_originalTime": 1689848303000
    },
    {
        "time": 1689848304000,
        "value": 139.431,
        "_internal_originalTime": 1689848304000
    },
    {
        "time": 1689848306000,
        "value": 139.428,
        "_internal_originalTime": 1689848306000
    },
    {
        "time": 1689848308000,
        "value": 139.425,
        "_internal_originalTime": 1689848308000
    },
    {
        "time": 1689848309000,
        "value": 139.424,
        "_internal_originalTime": 1689848309000
    },
    {
        "time": 1689848311000,
        "value": 139.425,
        "_internal_originalTime": 1689848311000
    },
    {
        "time": 1689848312000,
        "value": 139.428,
        "_internal_originalTime": 1689848312000
    },
    {
        "time": 1689848315000,
        "value": 139.425,
        "_internal_originalTime": 1689848315000
    },
    {
        "time": 1689848320000,
        "value": 139.423,
        "_internal_originalTime": 1689848320000
    },
    {
        "time": 1689848321000,
        "value": 139.428,
        "_internal_originalTime": 1689848321000
    },
    {
        "time": 1689848324000,
        "value": 139.428,
        "_internal_originalTime": 1689848324000
    },
    {
        "time": 1689848326000,
        "value": 139.431,
        "_internal_originalTime": 1689848326000
    },
    {
        "time": 1689848327000,
        "value": 139.432,
        "_internal_originalTime": 1689848327000
    },
    {
        "time": 1689848328000,
        "value": 139.427,
        "_internal_originalTime": 1689848328000
    },
    {
        "time": 1689848330000,
        "value": 139.427,
        "_internal_originalTime": 1689848330000
    },
    {
        "time": 1689848331000,
        "value": 139.429,
        "_internal_originalTime": 1689848331000
    },
    {
        "time": 1689848333000,
        "value": 139.43,
        "_internal_originalTime": 1689848333000
    },
    {
        "time": 1689848336000,
        "value": 139.427,
        "_internal_originalTime": 1689848336000
    },
    {
        "time": 1689848337000,
        "value": 139.432,
        "_internal_originalTime": 1689848337000
    },
    {
        "time": 1689848340000,
        "value": 139.431,
        "_internal_originalTime": 1689848340000
    },
    {
        "time": 1689848343000,
        "value": 139.438,
        "_internal_originalTime": 1689848343000
    },
    {
        "time": 1689848345000,
        "value": 139.438,
        "_internal_originalTime": 1689848345000
    },
    {
        "time": 1689848347000,
        "value": 139.437,
        "_internal_originalTime": 1689848347000
    },
    {
        "time": 1689848348000,
        "value": 139.437,
        "_internal_originalTime": 1689848348000
    },
    {
        "time": 1689848350000,
        "value": 139.433,
        "_internal_originalTime": 1689848350000
    },
    {
        "time": 1689848352000,
        "value": 139.437,
        "_internal_originalTime": 1689848352000
    },
    {
        "time": 1689848353000,
        "value": 139.432,
        "_internal_originalTime": 1689848353000
    },
    {
        "time": 1689848354000,
        "value": 139.443,
        "_internal_originalTime": 1689848354000
    },
    {
        "time": 1689848356000,
        "value": 139.445,
        "_internal_originalTime": 1689848356000
    },
    {
        "time": 1689848357000,
        "value": 139.447,
        "_internal_originalTime": 1689848357000
    },
    {
        "time": 1689848358000,
        "value": 139.444,
        "_internal_originalTime": 1689848358000
    },
    {
        "time": 1689848360000,
        "value": 139.444,
        "_internal_originalTime": 1689848360000
    },
    {
        "time": 1689848361000,
        "value": 139.447,
        "_internal_originalTime": 1689848361000
    },
    {
        "time": 1689848363000,
        "value": 139.447,
        "_internal_originalTime": 1689848363000
    },
    {
        "time": 1689848364000,
        "value": 139.445,
        "_internal_originalTime": 1689848364000
    },
    {
        "time": 1689848367000,
        "value": 139.441,
        "_internal_originalTime": 1689848367000
    },
    {
        "time": 1689848368000,
        "value": 139.439,
        "_internal_originalTime": 1689848368000
    },
    {
        "time": 1689848369000,
        "value": 139.438,
        "_internal_originalTime": 1689848369000
    },
    {
        "time": 1689848370000,
        "value": 139.441,
        "_internal_originalTime": 1689848370000
    },
    {
        "time": 1689848372000,
        "value": 139.437,
        "_internal_originalTime": 1689848372000
    },
    {
        "time": 1689848373000,
        "value": 139.438,
        "_internal_originalTime": 1689848373000
    },
    {
        "time": 1689848374000,
        "value": 139.442,
        "_internal_originalTime": 1689848374000
    },
    {
        "time": 1689848377000,
        "value": 139.442,
        "_internal_originalTime": 1689848377000
    },
    {
        "time": 1689848378000,
        "value": 139.446,
        "_internal_originalTime": 1689848378000
    },
    {
        "time": 1689848379000,
        "value": 139.448,
        "_internal_originalTime": 1689848379000
    },
    {
        "time": 1689848381000,
        "value": 139.44,
        "_internal_originalTime": 1689848381000
    },
    {
        "time": 1689848383000,
        "value": 139.438,
        "_internal_originalTime": 1689848383000
    },
    {
        "time": 1689848385000,
        "value": 139.438,
        "_internal_originalTime": 1689848385000
    },
    {
        "time": 1689848386000,
        "value": 139.442,
        "_internal_originalTime": 1689848386000
    },
    {
        "time": 1689848388000,
        "value": 139.444,
        "_internal_originalTime": 1689848388000
    },
    {
        "time": 1689848390000,
        "value": 139.445,
        "_internal_originalTime": 1689848390000
    },
    {
        "time": 1689848391000,
        "value": 139.444,
        "_internal_originalTime": 1689848391000
    },
    {
        "time": 1689848393000,
        "value": 139.444,
        "_internal_originalTime": 1689848393000
    },
    {
        "time": 1689848394000,
        "value": 139.445,
        "_internal_originalTime": 1689848394000
    },
    {
        "time": 1689848396000,
        "value": 139.445,
        "_internal_originalTime": 1689848396000
    },
    {
        "time": 1689848398000,
        "value": 139.445,
        "_internal_originalTime": 1689848398000
    },
    {
        "time": 1689848400000,
        "value": 139.444,
        "_internal_originalTime": 1689848400000
    },
    {
        "time": 1689848402000,
        "value": 139.444,
        "_internal_originalTime": 1689848402000
    },
    {
        "time": 1689848405000,
        "value": 139.444,
        "_internal_originalTime": 1689848405000
    },
    {
        "time": 1689848406000,
        "value": 139.44,
        "_internal_originalTime": 1689848406000
    },
    {
        "time": 1689848407000,
        "value": 139.44,
        "_internal_originalTime": 1689848407000
    },
    {
        "time": 1689848409000,
        "value": 139.44,
        "_internal_originalTime": 1689848409000
    },
    {
        "time": 1689848410000,
        "value": 139.444,
        "_internal_originalTime": 1689848410000
    },
    {
        "time": 1689848412000,
        "value": 139.434,
        "_internal_originalTime": 1689848412000
    },
    {
        "time": 1689848413000,
        "value": 139.43,
        "_internal_originalTime": 1689848413000
    },
    {
        "time": 1689848416000,
        "value": 139.435,
        "_internal_originalTime": 1689848416000
    },
    {
        "time": 1689848418000,
        "value": 139.436,
        "_internal_originalTime": 1689848418000
    },
    {
        "time": 1689848419000,
        "value": 139.432,
        "_internal_originalTime": 1689848419000
    },
    {
        "time": 1689848422000,
        "value": 139.434,
        "_internal_originalTime": 1689848422000
    },
    {
        "time": 1689848423000,
        "value": 139.436,
        "_internal_originalTime": 1689848423000
    },
    {
        "time": 1689848425000,
        "value": 139.438,
        "_internal_originalTime": 1689848425000
    },
    {
        "time": 1689848427000,
        "value": 139.44,
        "_internal_originalTime": 1689848427000
    },
    {
        "time": 1689848428000,
        "value": 139.443,
        "_internal_originalTime": 1689848428000
    },
    {
        "time": 1689848429000,
        "value": 139.442,
        "_internal_originalTime": 1689848429000
    },
    {
        "time": 1689848431000,
        "value": 139.438,
        "_internal_originalTime": 1689848431000
    },
    {
        "time": 1689848433000,
        "value": 139.442,
        "_internal_originalTime": 1689848433000
    },
    {
        "time": 1689848434000,
        "value": 139.446,
        "_internal_originalTime": 1689848434000
    },
    {
        "time": 1689848436000,
        "value": 139.445,
        "_internal_originalTime": 1689848436000
    },
    {
        "time": 1689848437000,
        "value": 139.445,
        "_internal_originalTime": 1689848437000
    },
    {
        "time": 1689848439000,
        "value": 139.44,
        "_internal_originalTime": 1689848439000
    },
    {
        "time": 1689848440000,
        "value": 139.447,
        "_internal_originalTime": 1689848440000
    },
    {
        "time": 1689848441000,
        "value": 139.447,
        "_internal_originalTime": 1689848441000
    },
    {
        "time": 1689848443000,
        "value": 139.44,
        "_internal_originalTime": 1689848443000
    },
    {
        "time": 1689848445000,
        "value": 139.448,
        "_internal_originalTime": 1689848445000
    },
    {
        "time": 1689848446000,
        "value": 139.448,
        "_internal_originalTime": 1689848446000
    },
    {
        "time": 1689848447000,
        "value": 139.449,
        "_internal_originalTime": 1689848447000
    },
    {
        "time": 1689848448000,
        "value": 139.448,
        "_internal_originalTime": 1689848448000
    },
    {
        "time": 1689848450000,
        "value": 139.449,
        "_internal_originalTime": 1689848450000
    },
    {
        "time": 1689848451000,
        "value": 139.444,
        "_internal_originalTime": 1689848451000
    },
    {
        "time": 1689848454000,
        "value": 139.448,
        "_internal_originalTime": 1689848454000
    },
    {
        "time": 1689848455000,
        "value": 139.458,
        "_internal_originalTime": 1689848455000
    },
    {
        "time": 1689848456000,
        "value": 139.457,
        "_internal_originalTime": 1689848456000
    },
    {
        "time": 1689848458000,
        "value": 139.459,
        "_internal_originalTime": 1689848458000
    },
    {
        "time": 1689848460000,
        "value": 139.456,
        "_internal_originalTime": 1689848460000
    },
    {
        "time": 1689848461000,
        "value": 139.43,
        "_internal_originalTime": 1689848461000
    },
    {
        "time": 1689848464000,
        "value": 139.449,
        "_internal_originalTime": 1689848464000
    },
    {
        "time": 1689848466000,
        "value": 139.45,
        "_internal_originalTime": 1689848466000
    },
    {
        "time": 1689848468000,
        "value": 139.45,
        "_internal_originalTime": 1689848468000
    },
    {
        "time": 1689848471000,
        "value": 139.449,
        "_internal_originalTime": 1689848471000
    },
    {
        "time": 1689848472000,
        "value": 139.453,
        "_internal_originalTime": 1689848472000
    },
    {
        "time": 1689848474000,
        "value": 139.454,
        "_internal_originalTime": 1689848474000
    },
    {
        "time": 1689848475000,
        "value": 139.447,
        "_internal_originalTime": 1689848475000
    },
    {
        "time": 1689848476000,
        "value": 139.451,
        "_internal_originalTime": 1689848476000
    },
    {
        "time": 1689848477000,
        "value": 139.453,
        "_internal_originalTime": 1689848477000
    },
    {
        "time": 1689848479000,
        "value": 139.452,
        "_internal_originalTime": 1689848479000
    },
    {
        "time": 1689848480000,
        "value": 139.448,
        "_internal_originalTime": 1689848480000
    },
    {
        "time": 1689848481000,
        "value": 139.443,
        "_internal_originalTime": 1689848481000
    },
    {
        "time": 1689848483000,
        "value": 139.447,
        "_internal_originalTime": 1689848483000
    },
    {
        "time": 1689848484000,
        "value": 139.441,
        "_internal_originalTime": 1689848484000
    },
    {
        "time": 1689848485000,
        "value": 139.447,
        "_internal_originalTime": 1689848485000
    },
    {
        "time": 1689848486000,
        "value": 139.442,
        "_internal_originalTime": 1689848486000
    },
    {
        "time": 1689848488000,
        "value": 139.443,
        "_internal_originalTime": 1689848488000
    },
    {
        "time": 1689848490000,
        "value": 139.443,
        "_internal_originalTime": 1689848490000
    },
    {
        "time": 1689848492000,
        "value": 139.442,
        "_internal_originalTime": 1689848492000
    },
    {
        "time": 1689848493000,
        "value": 139.446,
        "_internal_originalTime": 1689848493000
    },
    {
        "time": 1689848495000,
        "value": 139.445,
        "_internal_originalTime": 1689848495000
    },
    {
        "time": 1689848496000,
        "value": 139.451,
        "_internal_originalTime": 1689848496000
    },
    {
        "time": 1689848497000,
        "value": 139.451,
        "_internal_originalTime": 1689848497000
    },
    {
        "time": 1689848501000,
        "value": 139.454,
        "_internal_originalTime": 1689848501000
    },
    {
        "time": 1689848503000,
        "value": 139.447,
        "_internal_originalTime": 1689848503000
    },
    {
        "time": 1689848504000,
        "value": 139.449,
        "_internal_originalTime": 1689848504000
    },
    {
        "time": 1689848505000,
        "value": 139.448,
        "_internal_originalTime": 1689848505000
    },
    {
        "time": 1689848507000,
        "value": 139.448,
        "_internal_originalTime": 1689848507000
    },
    {
        "time": 1689848509000,
        "value": 139.448,
        "_internal_originalTime": 1689848509000
    },
    {
        "time": 1689848510000,
        "value": 139.456,
        "_internal_originalTime": 1689848510000
    },
    {
        "time": 1689848512000,
        "value": 139.45,
        "_internal_originalTime": 1689848512000
    },
    {
        "time": 1689848513000,
        "value": 139.452,
        "_internal_originalTime": 1689848513000
    },
    {
        "time": 1689848515000,
        "value": 139.452,
        "_internal_originalTime": 1689848515000
    },
    {
        "time": 1689848517000,
        "value": 139.452,
        "_internal_originalTime": 1689848517000
    },
    {
        "time": 1689848518000,
        "value": 139.453,
        "_internal_originalTime": 1689848518000
    },
    {
        "time": 1689848519000,
        "value": 139.452,
        "_internal_originalTime": 1689848519000
    },
    {
        "time": 1689848520000,
        "value": 139.453,
        "_internal_originalTime": 1689848520000
    },
    {
        "time": 1689848521000,
        "value": 139.454,
        "_internal_originalTime": 1689848521000
    },
    {
        "time": 1689848523000,
        "value": 139.453,
        "_internal_originalTime": 1689848523000
    },
    {
        "time": 1689848524000,
        "value": 139.45,
        "_internal_originalTime": 1689848524000
    },
    {
        "time": 1689848525000,
        "value": 139.458,
        "_internal_originalTime": 1689848525000
    },
    {
        "time": 1689848526000,
        "value": 139.457,
        "_internal_originalTime": 1689848526000
    },
    {
        "time": 1689848527000,
        "value": 139.452,
        "_internal_originalTime": 1689848527000
    },
    {
        "time": 1689848529000,
        "value": 139.452,
        "_internal_originalTime": 1689848529000
    },
    {
        "time": 1689848531000,
        "value": 139.452,
        "_internal_originalTime": 1689848531000
    },
    {
        "time": 1689848533000,
        "value": 139.459,
        "_internal_originalTime": 1689848533000
    },
    {
        "time": 1689848536000,
        "value": 139.456,
        "_internal_originalTime": 1689848536000
    },
    {
        "time": 1689848537000,
        "value": 139.453,
        "_internal_originalTime": 1689848537000
    },
    {
        "time": 1689848539000,
        "value": 139.455,
        "_internal_originalTime": 1689848539000
    },
    {
        "time": 1689848540000,
        "value": 139.454,
        "_internal_originalTime": 1689848540000
    },
    {
        "time": 1689848541000,
        "value": 139.452,
        "_internal_originalTime": 1689848541000
    },
    {
        "time": 1689848543000,
        "value": 139.457,
        "_internal_originalTime": 1689848543000
    },
    {
        "time": 1689848547000,
        "value": 139.452,
        "_internal_originalTime": 1689848547000
    },
    {
        "time": 1689848548000,
        "value": 139.458,
        "_internal_originalTime": 1689848548000
    },
    {
        "time": 1689848549000,
        "value": 139.457,
        "_internal_originalTime": 1689848549000
    },
    {
        "time": 1689848551000,
        "value": 139.453,
        "_internal_originalTime": 1689848551000
    },
    {
        "time": 1689848552000,
        "value": 139.455,
        "_internal_originalTime": 1689848552000
    },
    {
        "time": 1689848555000,
        "value": 139.442,
        "_internal_originalTime": 1689848555000
    },
    {
        "time": 1689848558000,
        "value": 139.441,
        "_internal_originalTime": 1689848558000
    },
    {
        "time": 1689848559000,
        "value": 139.428,
        "_internal_originalTime": 1689848559000
    },
    {
        "time": 1689848561000,
        "value": 139.429,
        "_internal_originalTime": 1689848561000
    },
    {
        "time": 1689848562000,
        "value": 139.428,
        "_internal_originalTime": 1689848562000
    },
    {
        "time": 1689848563000,
        "value": 139.422,
        "_internal_originalTime": 1689848563000
    },
    {
        "time": 1689848564000,
        "value": 139.421,
        "_internal_originalTime": 1689848564000
    },
    {
        "time": 1689848565000,
        "value": 139.427,
        "_internal_originalTime": 1689848565000
    },
    {
        "time": 1689848567000,
        "value": 139.426,
        "_internal_originalTime": 1689848567000
    },
    {
        "time": 1689848568000,
        "value": 139.427,
        "_internal_originalTime": 1689848568000
    },
    {
        "time": 1689848571000,
        "value": 139.422,
        "_internal_originalTime": 1689848571000
    },
    {
        "time": 1689848573000,
        "value": 139.428,
        "_internal_originalTime": 1689848573000
    },
    {
        "time": 1689848576000,
        "value": 139.422,
        "_internal_originalTime": 1689848576000
    },
    {
        "time": 1689848577000,
        "value": 139.423,
        "_internal_originalTime": 1689848577000
    },
    {
        "time": 1689848579000,
        "value": 139.422,
        "_internal_originalTime": 1689848579000
    },
    {
        "time": 1689848580000,
        "value": 139.423,
        "_internal_originalTime": 1689848580000
    },
    {
        "time": 1689848581000,
        "value": 139.43,
        "_internal_originalTime": 1689848581000
    },
    {
        "time": 1689848583000,
        "value": 139.436,
        "_internal_originalTime": 1689848583000
    },
    {
        "time": 1689848584000,
        "value": 139.432,
        "_internal_originalTime": 1689848584000
    },
    {
        "time": 1689848585000,
        "value": 139.433,
        "_internal_originalTime": 1689848585000
    },
    {
        "time": 1689848587000,
        "value": 139.437,
        "_internal_originalTime": 1689848587000
    },
    {
        "time": 1689848589000,
        "value": 139.438,
        "_internal_originalTime": 1689848589000
    },
    {
        "time": 1689848590000,
        "value": 139.432,
        "_internal_originalTime": 1689848590000
    },
    {
        "time": 1689848592000,
        "value": 139.438,
        "_internal_originalTime": 1689848592000
    },
    {
        "time": 1689848593000,
        "value": 139.435,
        "_internal_originalTime": 1689848593000
    },
    {
        "time": 1689848594000,
        "value": 139.44,
        "_internal_originalTime": 1689848594000
    },
    {
        "time": 1689848595000,
        "value": 139.433,
        "_internal_originalTime": 1689848595000
    },
    {
        "time": 1689848597000,
        "value": 139.441,
        "_internal_originalTime": 1689848597000
    },
    {
        "time": 1689848598000,
        "value": 139.432,
        "_internal_originalTime": 1689848598000
    },
    {
        "time": 1689848599000,
        "value": 139.434,
        "_internal_originalTime": 1689848599000
    },
    {
        "time": 1689848601000,
        "value": 139.434,
        "_internal_originalTime": 1689848601000
    },
    {
        "time": 1689848602000,
        "value": 139.432,
        "_internal_originalTime": 1689848602000
    },
    {
        "time": 1689848604000,
        "value": 139.434,
        "_internal_originalTime": 1689848604000
    },
    {
        "time": 1689848605000,
        "value": 139.427,
        "_internal_originalTime": 1689848605000
    },
    {
        "time": 1689848607000,
        "value": 139.429,
        "_internal_originalTime": 1689848607000
    },
    {
        "time": 1689848609000,
        "value": 139.434,
        "_internal_originalTime": 1689848609000
    },
    {
        "time": 1689848614000,
        "value": 139.428,
        "_internal_originalTime": 1689848614000
    },
    {
        "time": 1689848615000,
        "value": 139.422,
        "_internal_originalTime": 1689848615000
    },
    {
        "time": 1689848617000,
        "value": 139.42,
        "_internal_originalTime": 1689848617000
    },
    {
        "time": 1689848618000,
        "value": 139.421,
        "_internal_originalTime": 1689848618000
    },
    {
        "time": 1689848619000,
        "value": 139.42,
        "_internal_originalTime": 1689848619000
    },
    {
        "time": 1689848621000,
        "value": 139.422,
        "_internal_originalTime": 1689848621000
    },
    {
        "time": 1689848623000,
        "value": 139.422,
        "_internal_originalTime": 1689848623000
    },
    {
        "time": 1689848625000,
        "value": 139.426,
        "_internal_originalTime": 1689848625000
    },
    {
        "time": 1689848626000,
        "value": 139.421,
        "_internal_originalTime": 1689848626000
    },
    {
        "time": 1689848627000,
        "value": 139.428,
        "_internal_originalTime": 1689848627000
    },
    {
        "time": 1689848631000,
        "value": 139.421,
        "_internal_originalTime": 1689848631000
    },
    {
        "time": 1689848635000,
        "value": 139.424,
        "_internal_originalTime": 1689848635000
    },
    {
        "time": 1689848636000,
        "value": 139.428,
        "_internal_originalTime": 1689848636000
    },
    {
        "time": 1689848637000,
        "value": 139.429,
        "_internal_originalTime": 1689848637000
    },
    {
        "time": 1689848640000,
        "value": 139.428,
        "_internal_originalTime": 1689848640000
    },
    {
        "time": 1689848641000,
        "value": 139.428,
        "_internal_originalTime": 1689848641000
    },
    {
        "time": 1689848642000,
        "value": 139.424,
        "_internal_originalTime": 1689848642000
    },
    {
        "time": 1689848644000,
        "value": 139.422,
        "_internal_originalTime": 1689848644000
    },
    {
        "time": 1689848646000,
        "value": 139.424,
        "_internal_originalTime": 1689848646000
    },
    {
        "time": 1689848647000,
        "value": 139.422,
        "_internal_originalTime": 1689848647000
    },
    {
        "time": 1689848651000,
        "value": 139.426,
        "_internal_originalTime": 1689848651000
    },
    {
        "time": 1689848652000,
        "value": 139.428,
        "_internal_originalTime": 1689848652000
    },
    {
        "time": 1689848653000,
        "value": 139.423,
        "_internal_originalTime": 1689848653000
    },
    {
        "time": 1689848654000,
        "value": 139.412,
        "_internal_originalTime": 1689848654000
    },
    {
        "time": 1689848655000,
        "value": 139.414,
        "_internal_originalTime": 1689848655000
    },
    {
        "time": 1689848656000,
        "value": 139.408,
        "_internal_originalTime": 1689848656000
    },
    {
        "time": 1689848658000,
        "value": 139.407,
        "_internal_originalTime": 1689848658000
    },
    {
        "time": 1689848659000,
        "value": 139.409,
        "_internal_originalTime": 1689848659000
    },
    {
        "time": 1689848661000,
        "value": 139.409,
        "_internal_originalTime": 1689848661000
    },
    {
        "time": 1689848662000,
        "value": 139.407,
        "_internal_originalTime": 1689848662000
    },
    {
        "time": 1689848663000,
        "value": 139.409,
        "_internal_originalTime": 1689848663000
    },
    {
        "time": 1689848664000,
        "value": 139.402,
        "_internal_originalTime": 1689848664000
    },
    {
        "time": 1689848666000,
        "value": 139.409,
        "_internal_originalTime": 1689848666000
    },
    {
        "time": 1689848668000,
        "value": 139.415,
        "_internal_originalTime": 1689848668000
    },
    {
        "time": 1689848669000,
        "value": 139.417,
        "_internal_originalTime": 1689848669000
    },
    {
        "time": 1689848670000,
        "value": 139.417,
        "_internal_originalTime": 1689848670000
    },
    {
        "time": 1689848671000,
        "value": 139.417,
        "_internal_originalTime": 1689848671000
    },
    {
        "time": 1689848673000,
        "value": 139.417,
        "_internal_originalTime": 1689848673000
    },
    {
        "time": 1689848674000,
        "value": 139.417,
        "_internal_originalTime": 1689848674000
    },
    {
        "time": 1689848678000,
        "value": 139.417,
        "_internal_originalTime": 1689848678000
    },
    {
        "time": 1689848682000,
        "value": 139.416,
        "_internal_originalTime": 1689848682000
    },
    {
        "time": 1689848683000,
        "value": 139.415,
        "_internal_originalTime": 1689848683000
    },
    {
        "time": 1689848685000,
        "value": 139.415,
        "_internal_originalTime": 1689848685000
    },
    {
        "time": 1689848686000,
        "value": 139.415,
        "_internal_originalTime": 1689848686000
    },
    {
        "time": 1689848687000,
        "value": 139.414,
        "_internal_originalTime": 1689848687000
    },
    {
        "time": 1689848689000,
        "value": 139.42,
        "_internal_originalTime": 1689848689000
    },
    {
        "time": 1689848691000,
        "value": 139.424,
        "_internal_originalTime": 1689848691000
    },
    {
        "time": 1689848692000,
        "value": 139.418,
        "_internal_originalTime": 1689848692000
    },
    {
        "time": 1689848693000,
        "value": 139.419,
        "_internal_originalTime": 1689848693000
    },
    {
        "time": 1689848695000,
        "value": 139.424,
        "_internal_originalTime": 1689848695000
    },
    {
        "time": 1689848697000,
        "value": 139.424,
        "_internal_originalTime": 1689848697000
    },
    {
        "time": 1689848698000,
        "value": 139.423,
        "_internal_originalTime": 1689848698000
    },
    {
        "time": 1689848700000,
        "value": 139.419,
        "_internal_originalTime": 1689848700000
    },
    {
        "time": 1689848701000,
        "value": 139.421,
        "_internal_originalTime": 1689848701000
    },
    {
        "time": 1689848703000,
        "value": 139.411,
        "_internal_originalTime": 1689848703000
    },
    {
        "time": 1689848704000,
        "value": 139.415,
        "_internal_originalTime": 1689848704000
    },
    {
        "time": 1689848706000,
        "value": 139.412,
        "_internal_originalTime": 1689848706000
    },
    {
        "time": 1689848708000,
        "value": 139.412,
        "_internal_originalTime": 1689848708000
    },
    {
        "time": 1689848709000,
        "value": 139.416,
        "_internal_originalTime": 1689848709000
    },
    {
        "time": 1689848710000,
        "value": 139.421,
        "_internal_originalTime": 1689848710000
    },
    {
        "time": 1689848712000,
        "value": 139.418,
        "_internal_originalTime": 1689848712000
    },
    {
        "time": 1689848713000,
        "value": 139.41,
        "_internal_originalTime": 1689848713000
    },
    {
        "time": 1689848716000,
        "value": 139.407,
        "_internal_originalTime": 1689848716000
    },
    {
        "time": 1689848718000,
        "value": 139.412,
        "_internal_originalTime": 1689848718000
    },
    {
        "time": 1689848719000,
        "value": 139.408,
        "_internal_originalTime": 1689848719000
    },
    {
        "time": 1689848720000,
        "value": 139.41,
        "_internal_originalTime": 1689848720000
    },
    {
        "time": 1689848722000,
        "value": 139.408,
        "_internal_originalTime": 1689848722000
    },
    {
        "time": 1689848723000,
        "value": 139.407,
        "_internal_originalTime": 1689848723000
    },
    {
        "time": 1689848725000,
        "value": 139.4,
        "_internal_originalTime": 1689848725000
    },
    {
        "time": 1689848726000,
        "value": 139.405,
        "_internal_originalTime": 1689848726000
    },
    {
        "time": 1689848728000,
        "value": 139.405,
        "_internal_originalTime": 1689848728000
    },
    {
        "time": 1689848730000,
        "value": 139.404,
        "_internal_originalTime": 1689848730000
    },
    {
        "time": 1689848731000,
        "value": 139.4,
        "_internal_originalTime": 1689848731000
    },
    {
        "time": 1689848733000,
        "value": 139.402,
        "_internal_originalTime": 1689848733000
    },
    {
        "time": 1689848734000,
        "value": 139.406,
        "_internal_originalTime": 1689848734000
    },
    {
        "time": 1689848735000,
        "value": 139.402,
        "_internal_originalTime": 1689848735000
    },
    {
        "time": 1689848737000,
        "value": 139.404,
        "_internal_originalTime": 1689848737000
    },
    {
        "time": 1689848739000,
        "value": 139.407,
        "_internal_originalTime": 1689848739000
    },
    {
        "time": 1689848740000,
        "value": 139.408,
        "_internal_originalTime": 1689848740000
    },
    {
        "time": 1689848742000,
        "value": 139.405,
        "_internal_originalTime": 1689848742000
    },
    {
        "time": 1689848743000,
        "value": 139.404,
        "_internal_originalTime": 1689848743000
    },
    {
        "time": 1689848745000,
        "value": 139.398,
        "_internal_originalTime": 1689848745000
    },
    {
        "time": 1689848746000,
        "value": 139.398,
        "_internal_originalTime": 1689848746000
    },
    {
        "time": 1689848751000,
        "value": 139.398,
        "_internal_originalTime": 1689848751000
    },
    {
        "time": 1689848754000,
        "value": 139.4,
        "_internal_originalTime": 1689848754000
    },
    {
        "time": 1689848755000,
        "value": 139.394,
        "_internal_originalTime": 1689848755000
    },
    {
        "time": 1689848757000,
        "value": 139.394,
        "_internal_originalTime": 1689848757000
    },
    {
        "time": 1689848759000,
        "value": 139.4,
        "_internal_originalTime": 1689848759000
    },
    {
        "time": 1689848760000,
        "value": 139.394,
        "_internal_originalTime": 1689848760000
    },
    {
        "time": 1689848761000,
        "value": 139.399,
        "_internal_originalTime": 1689848761000
    },
    {
        "time": 1689848762000,
        "value": 139.396,
        "_internal_originalTime": 1689848762000
    },
    {
        "time": 1689848764000,
        "value": 139.384,
        "_internal_originalTime": 1689848764000
    },
    {
        "time": 1689848765000,
        "value": 139.373,
        "_internal_originalTime": 1689848765000
    },
    {
        "time": 1689848766000,
        "value": 139.379,
        "_internal_originalTime": 1689848766000
    },
    {
        "time": 1689848767000,
        "value": 139.374,
        "_internal_originalTime": 1689848767000
    },
    {
        "time": 1689848769000,
        "value": 139.381,
        "_internal_originalTime": 1689848769000
    },
    {
        "time": 1689848771000,
        "value": 139.37,
        "_internal_originalTime": 1689848771000
    },
    {
        "time": 1689848772000,
        "value": 139.377,
        "_internal_originalTime": 1689848772000
    },
    {
        "time": 1689848775000,
        "value": 139.376,
        "_internal_originalTime": 1689848775000
    },
    {
        "time": 1689848777000,
        "value": 139.366,
        "_internal_originalTime": 1689848777000
    },
    {
        "time": 1689848779000,
        "value": 139.357,
        "_internal_originalTime": 1689848779000
    },
    {
        "time": 1689848780000,
        "value": 139.367,
        "_internal_originalTime": 1689848780000
    },
    {
        "time": 1689848781000,
        "value": 139.371,
        "_internal_originalTime": 1689848781000
    },
    {
        "time": 1689848782000,
        "value": 139.376,
        "_internal_originalTime": 1689848782000
    },
    {
        "time": 1689848785000,
        "value": 139.377,
        "_internal_originalTime": 1689848785000
    },
    {
        "time": 1689848786000,
        "value": 139.378,
        "_internal_originalTime": 1689848786000
    },
    {
        "time": 1689848788000,
        "value": 139.381,
        "_internal_originalTime": 1689848788000
    },
    {
        "time": 1689848789000,
        "value": 139.387,
        "_internal_originalTime": 1689848789000
    },
    {
        "time": 1689848790000,
        "value": 139.386,
        "_internal_originalTime": 1689848790000
    },
    {
        "time": 1689848792000,
        "value": 139.382,
        "_internal_originalTime": 1689848792000
    },
    {
        "time": 1689848793000,
        "value": 139.38,
        "_internal_originalTime": 1689848793000
    },
    {
        "time": 1689848796000,
        "value": 139.377,
        "_internal_originalTime": 1689848796000
    },
    {
        "time": 1689848798000,
        "value": 139.381,
        "_internal_originalTime": 1689848798000
    },
    {
        "time": 1689848799000,
        "value": 139.382,
        "_internal_originalTime": 1689848799000
    },
    {
        "time": 1689848801000,
        "value": 139.378,
        "_internal_originalTime": 1689848801000
    },
    {
        "time": 1689848804000,
        "value": 139.377,
        "_internal_originalTime": 1689848804000
    },
    {
        "time": 1689848807000,
        "value": 139.375,
        "_internal_originalTime": 1689848807000
    },
    {
        "time": 1689848809000,
        "value": 139.375,
        "_internal_originalTime": 1689848809000
    },
    {
        "time": 1689848811000,
        "value": 139.373,
        "_internal_originalTime": 1689848811000
    },
    {
        "time": 1689848812000,
        "value": 139.373,
        "_internal_originalTime": 1689848812000
    },
    {
        "time": 1689848813000,
        "value": 139.37,
        "_internal_originalTime": 1689848813000
    },
    {
        "time": 1689848815000,
        "value": 139.37,
        "_internal_originalTime": 1689848815000
    },
    {
        "time": 1689848817000,
        "value": 139.37,
        "_internal_originalTime": 1689848817000
    },
    {
        "time": 1689848818000,
        "value": 139.375,
        "_internal_originalTime": 1689848818000
    },
    {
        "time": 1689848819000,
        "value": 139.374,
        "_internal_originalTime": 1689848819000
    },
    {
        "time": 1689848822000,
        "value": 139.371,
        "_internal_originalTime": 1689848822000
    },
    {
        "time": 1689848823000,
        "value": 139.372,
        "_internal_originalTime": 1689848823000
    },
    {
        "time": 1689848826000,
        "value": 139.373,
        "_internal_originalTime": 1689848826000
    },
    {
        "time": 1689848827000,
        "value": 139.374,
        "_internal_originalTime": 1689848827000
    },
    {
        "time": 1689848829000,
        "value": 139.374,
        "_internal_originalTime": 1689848829000
    },
    {
        "time": 1689848830000,
        "value": 139.374,
        "_internal_originalTime": 1689848830000
    },
    {
        "time": 1689848831000,
        "value": 139.369,
        "_internal_originalTime": 1689848831000
    },
    {
        "time": 1689848838000,
        "value": 139.371,
        "_internal_originalTime": 1689848838000
    },
    {
        "time": 1689848839000,
        "value": 139.37,
        "_internal_originalTime": 1689848839000
    },
    {
        "time": 1689848840000,
        "value": 139.374,
        "_internal_originalTime": 1689848840000
    },
    {
        "time": 1689848841000,
        "value": 139.374,
        "_internal_originalTime": 1689848841000
    },
    {
        "time": 1689848843000,
        "value": 139.374,
        "_internal_originalTime": 1689848843000
    },
    {
        "time": 1689848845000,
        "value": 139.365,
        "_internal_originalTime": 1689848845000
    },
    {
        "time": 1689848846000,
        "value": 139.361,
        "_internal_originalTime": 1689848846000
    },
    {
        "time": 1689848849000,
        "value": 139.362,
        "_internal_originalTime": 1689848849000
    },
    {
        "time": 1689848850000,
        "value": 139.348,
        "_internal_originalTime": 1689848850000
    },
    {
        "time": 1689848852000,
        "value": 139.346,
        "_internal_originalTime": 1689848852000
    },
    {
        "time": 1689848853000,
        "value": 139.351,
        "_internal_originalTime": 1689848853000
    },
    {
        "time": 1689848855000,
        "value": 139.351,
        "_internal_originalTime": 1689848855000
    },
    {
        "time": 1689848856000,
        "value": 139.35,
        "_internal_originalTime": 1689848856000
    },
    {
        "time": 1689848857000,
        "value": 139.355,
        "_internal_originalTime": 1689848857000
    },
    {
        "time": 1689848859000,
        "value": 139.355,
        "_internal_originalTime": 1689848859000
    },
    {
        "time": 1689848861000,
        "value": 139.346,
        "_internal_originalTime": 1689848861000
    },
    {
        "time": 1689848862000,
        "value": 139.348,
        "_internal_originalTime": 1689848862000
    },
    {
        "time": 1689848863000,
        "value": 139.349,
        "_internal_originalTime": 1689848863000
    },
    {
        "time": 1689848865000,
        "value": 139.354,
        "_internal_originalTime": 1689848865000
    },
    {
        "time": 1689848868000,
        "value": 139.354,
        "_internal_originalTime": 1689848868000
    },
    {
        "time": 1689848869000,
        "value": 139.356,
        "_internal_originalTime": 1689848869000
    },
    {
        "time": 1689848870000,
        "value": 139.355,
        "_internal_originalTime": 1689848870000
    },
    {
        "time": 1689848873000,
        "value": 139.357,
        "_internal_originalTime": 1689848873000
    },
    {
        "time": 1689848874000,
        "value": 139.356,
        "_internal_originalTime": 1689848874000
    },
    {
        "time": 1689848876000,
        "value": 139.351,
        "_internal_originalTime": 1689848876000
    },
    {
        "time": 1689848877000,
        "value": 139.351,
        "_internal_originalTime": 1689848877000
    },
    {
        "time": 1689848879000,
        "value": 139.35,
        "_internal_originalTime": 1689848879000
    },
    {
        "time": 1689848881000,
        "value": 139.33,
        "_internal_originalTime": 1689848881000
    },
    {
        "time": 1689848886000,
        "value": 139.358,
        "_internal_originalTime": 1689848886000
    },
    {
        "time": 1689848887000,
        "value": 139.355,
        "_internal_originalTime": 1689848887000
    },
    {
        "time": 1689848889000,
        "value": 139.36,
        "_internal_originalTime": 1689848889000
    },
    {
        "time": 1689848890000,
        "value": 139.358,
        "_internal_originalTime": 1689848890000
    },
    {
        "time": 1689848892000,
        "value": 139.361,
        "_internal_originalTime": 1689848892000
    },
    {
        "time": 1689848893000,
        "value": 139.368,
        "_internal_originalTime": 1689848893000
    },
    {
        "time": 1689848895000,
        "value": 139.372,
        "_internal_originalTime": 1689848895000
    },
    {
        "time": 1689848897000,
        "value": 139.372,
        "_internal_originalTime": 1689848897000
    },
    {
        "time": 1689848898000,
        "value": 139.376,
        "_internal_originalTime": 1689848898000
    },
    {
        "time": 1689848899000,
        "value": 139.38,
        "_internal_originalTime": 1689848899000
    },
    {
        "time": 1689848900000,
        "value": 139.38,
        "_internal_originalTime": 1689848900000
    },
    {
        "time": 1689848902000,
        "value": 139.366,
        "_internal_originalTime": 1689848902000
    },
    {
        "time": 1689848903000,
        "value": 139.37,
        "_internal_originalTime": 1689848903000
    },
    {
        "time": 1689848904000,
        "value": 139.367,
        "_internal_originalTime": 1689848904000
    },
    {
        "time": 1689848906000,
        "value": 139.364,
        "_internal_originalTime": 1689848906000
    },
    {
        "time": 1689848907000,
        "value": 139.367,
        "_internal_originalTime": 1689848907000
    },
    {
        "time": 1689848909000,
        "value": 139.367,
        "_internal_originalTime": 1689848909000
    },
    {
        "time": 1689848911000,
        "value": 139.368,
        "_internal_originalTime": 1689848911000
    },
    {
        "time": 1689848913000,
        "value": 139.368,
        "_internal_originalTime": 1689848913000
    },
    {
        "time": 1689848914000,
        "value": 139.371,
        "_internal_originalTime": 1689848914000
    },
    {
        "time": 1689848915000,
        "value": 139.371,
        "_internal_originalTime": 1689848915000
    },
    {
        "time": 1689848917000,
        "value": 139.366,
        "_internal_originalTime": 1689848917000
    },
    {
        "time": 1689848918000,
        "value": 139.367,
        "_internal_originalTime": 1689848918000
    },
    {
        "time": 1689848921000,
        "value": 139.369,
        "_internal_originalTime": 1689848921000
    },
    {
        "time": 1689848925000,
        "value": 139.369,
        "_internal_originalTime": 1689848925000
    },
    {
        "time": 1689848926000,
        "value": 139.369,
        "_internal_originalTime": 1689848926000
    },
    {
        "time": 1689848928000,
        "value": 139.36,
        "_internal_originalTime": 1689848928000
    },
    {
        "time": 1689848930000,
        "value": 139.354,
        "_internal_originalTime": 1689848930000
    },
    {
        "time": 1689848931000,
        "value": 139.354,
        "_internal_originalTime": 1689848931000
    },
    {
        "time": 1689848933000,
        "value": 139.358,
        "_internal_originalTime": 1689848933000
    },
    {
        "time": 1689848934000,
        "value": 139.362,
        "_internal_originalTime": 1689848934000
    },
    {
        "time": 1689848936000,
        "value": 139.357,
        "_internal_originalTime": 1689848936000
    },
    {
        "time": 1689848937000,
        "value": 139.363,
        "_internal_originalTime": 1689848937000
    },
    {
        "time": 1689848939000,
        "value": 139.362,
        "_internal_originalTime": 1689848939000
    },
    {
        "time": 1689848941000,
        "value": 139.358,
        "_internal_originalTime": 1689848941000
    },
    {
        "time": 1689848943000,
        "value": 139.354,
        "_internal_originalTime": 1689848943000
    },
    {
        "time": 1689848945000,
        "value": 139.355,
        "_internal_originalTime": 1689848945000
    },
    {
        "time": 1689848947000,
        "value": 139.349,
        "_internal_originalTime": 1689848947000
    },
    {
        "time": 1689848948000,
        "value": 139.348,
        "_internal_originalTime": 1689848948000
    },
    {
        "time": 1689848950000,
        "value": 139.35,
        "_internal_originalTime": 1689848950000
    },
    {
        "time": 1689848952000,
        "value": 139.347,
        "_internal_originalTime": 1689848952000
    },
    {
        "time": 1689848953000,
        "value": 139.344,
        "_internal_originalTime": 1689848953000
    },
    {
        "time": 1689848954000,
        "value": 139.348,
        "_internal_originalTime": 1689848954000
    },
    {
        "time": 1689848955000,
        "value": 139.349,
        "_internal_originalTime": 1689848955000
    },
    {
        "time": 1689848956000,
        "value": 139.349,
        "_internal_originalTime": 1689848956000
    },
    {
        "time": 1689848958000,
        "value": 139.342,
        "_internal_originalTime": 1689848958000
    },
    {
        "time": 1689848959000,
        "value": 139.346,
        "_internal_originalTime": 1689848959000
    },
    {
        "time": 1689848961000,
        "value": 139.345,
        "_internal_originalTime": 1689848961000
    },
    {
        "time": 1689848962000,
        "value": 139.354,
        "_internal_originalTime": 1689848962000
    },
    {
        "time": 1689848964000,
        "value": 139.357,
        "_internal_originalTime": 1689848964000
    },
    {
        "time": 1689848965000,
        "value": 139.362,
        "_internal_originalTime": 1689848965000
    },
    {
        "time": 1689848966000,
        "value": 139.362,
        "_internal_originalTime": 1689848966000
    },
    {
        "time": 1689848969000,
        "value": 139.364,
        "_internal_originalTime": 1689848969000
    },
    {
        "time": 1689848970000,
        "value": 139.367,
        "_internal_originalTime": 1689848970000
    },
    {
        "time": 1689848971000,
        "value": 139.371,
        "_internal_originalTime": 1689848971000
    },
    {
        "time": 1689848972000,
        "value": 139.37,
        "_internal_originalTime": 1689848972000
    },
    {
        "time": 1689848973000,
        "value": 139.364,
        "_internal_originalTime": 1689848973000
    },
    {
        "time": 1689848974000,
        "value": 139.376,
        "_internal_originalTime": 1689848974000
    },
    {
        "time": 1689848976000,
        "value": 139.376,
        "_internal_originalTime": 1689848976000
    },
    {
        "time": 1689848979000,
        "value": 139.374,
        "_internal_originalTime": 1689848979000
    },
    {
        "time": 1689848980000,
        "value": 139.379,
        "_internal_originalTime": 1689848980000
    },
    {
        "time": 1689848981000,
        "value": 139.375,
        "_internal_originalTime": 1689848981000
    },
    {
        "time": 1689848983000,
        "value": 139.375,
        "_internal_originalTime": 1689848983000
    },
    {
        "time": 1689848984000,
        "value": 139.381,
        "_internal_originalTime": 1689848984000
    },
    {
        "time": 1689848985000,
        "value": 139.378,
        "_internal_originalTime": 1689848985000
    },
    {
        "time": 1689848987000,
        "value": 139.376,
        "_internal_originalTime": 1689848987000
    },
    {
        "time": 1689848988000,
        "value": 139.374,
        "_internal_originalTime": 1689848988000
    },
    {
        "time": 1689848989000,
        "value": 139.372,
        "_internal_originalTime": 1689848989000
    },
    {
        "time": 1689848990000,
        "value": 139.372,
        "_internal_originalTime": 1689848990000
    },
    {
        "time": 1689848994000,
        "value": 139.375,
        "_internal_originalTime": 1689848994000
    },
    {
        "time": 1689848996000,
        "value": 139.374,
        "_internal_originalTime": 1689848996000
    },
    {
        "time": 1689848997000,
        "value": 139.371,
        "_internal_originalTime": 1689848997000
    },
    {
        "time": 1689848999000,
        "value": 139.37,
        "_internal_originalTime": 1689848999000
    },
    {
        "time": 1689849001000,
        "value": 139.35,
        "_internal_originalTime": 1689849001000
    },
    {
        "time": 1689849002000,
        "value": 139.372,
        "_internal_originalTime": 1689849002000
    },
    {
        "time": 1689849003000,
        "value": 139.372,
        "_internal_originalTime": 1689849003000
    },
    {
        "time": 1689849004000,
        "value": 139.37,
        "_internal_originalTime": 1689849004000
    },
    {
        "time": 1689849005000,
        "value": 139.37,
        "_internal_originalTime": 1689849005000
    },
    {
        "time": 1689849008000,
        "value": 139.379,
        "_internal_originalTime": 1689849008000
    },
    {
        "time": 1689849009000,
        "value": 139.373,
        "_internal_originalTime": 1689849009000
    },
    {
        "time": 1689849011000,
        "value": 139.378,
        "_internal_originalTime": 1689849011000
    },
    {
        "time": 1689849012000,
        "value": 139.382,
        "_internal_originalTime": 1689849012000
    },
    {
        "time": 1689849013000,
        "value": 139.381,
        "_internal_originalTime": 1689849013000
    },
    {
        "time": 1689849015000,
        "value": 139.381,
        "_internal_originalTime": 1689849015000
    },
    {
        "time": 1689849016000,
        "value": 139.382,
        "_internal_originalTime": 1689849016000
    },
    {
        "time": 1689849018000,
        "value": 139.382,
        "_internal_originalTime": 1689849018000
    },
    {
        "time": 1689849019000,
        "value": 139.391,
        "_internal_originalTime": 1689849019000
    },
    {
        "time": 1689849020000,
        "value": 139.389,
        "_internal_originalTime": 1689849020000
    },
    {
        "time": 1689849022000,
        "value": 139.387,
        "_internal_originalTime": 1689849022000
    },
    {
        "time": 1689849023000,
        "value": 139.388,
        "_internal_originalTime": 1689849023000
    },
    {
        "time": 1689849025000,
        "value": 139.387,
        "_internal_originalTime": 1689849025000
    },
    {
        "time": 1689849026000,
        "value": 139.39,
        "_internal_originalTime": 1689849026000
    },
    {
        "time": 1689849028000,
        "value": 139.391,
        "_internal_originalTime": 1689849028000
    },
    {
        "time": 1689849030000,
        "value": 139.39,
        "_internal_originalTime": 1689849030000
    },
    {
        "time": 1689849033000,
        "value": 139.382,
        "_internal_originalTime": 1689849033000
    },
    {
        "time": 1689849034000,
        "value": 139.381,
        "_internal_originalTime": 1689849034000
    },
    {
        "time": 1689849036000,
        "value": 139.382,
        "_internal_originalTime": 1689849036000
    },
    {
        "time": 1689849037000,
        "value": 139.382,
        "_internal_originalTime": 1689849037000
    },
    {
        "time": 1689849038000,
        "value": 139.381,
        "_internal_originalTime": 1689849038000
    },
    {
        "time": 1689849039000,
        "value": 139.382,
        "_internal_originalTime": 1689849039000
    },
    {
        "time": 1689849042000,
        "value": 139.382,
        "_internal_originalTime": 1689849042000
    },
    {
        "time": 1689849043000,
        "value": 139.378,
        "_internal_originalTime": 1689849043000
    },
    {
        "time": 1689849044000,
        "value": 139.38,
        "_internal_originalTime": 1689849044000
    },
    {
        "time": 1689849045000,
        "value": 139.379,
        "_internal_originalTime": 1689849045000
    },
    {
        "time": 1689849046000,
        "value": 139.381,
        "_internal_originalTime": 1689849046000
    },
    {
        "time": 1689849048000,
        "value": 139.384,
        "_internal_originalTime": 1689849048000
    },
    {
        "time": 1689849051000,
        "value": 139.386,
        "_internal_originalTime": 1689849051000
    },
    {
        "time": 1689849052000,
        "value": 139.387,
        "_internal_originalTime": 1689849052000
    },
    {
        "time": 1689849054000,
        "value": 139.387,
        "_internal_originalTime": 1689849054000
    },
    {
        "time": 1689849055000,
        "value": 139.381,
        "_internal_originalTime": 1689849055000
    },
    {
        "time": 1689849056000,
        "value": 139.381,
        "_internal_originalTime": 1689849056000
    },
    {
        "time": 1689849059000,
        "value": 139.381,
        "_internal_originalTime": 1689849059000
    },
    {
        "time": 1689849060000,
        "value": 139.36,
        "_internal_originalTime": 1689849060000
    },
    {
        "time": 1689849061000,
        "value": 139.377,
        "_internal_originalTime": 1689849061000
    },
    {
        "time": 1689849064000,
        "value": 139.377,
        "_internal_originalTime": 1689849064000
    },
    {
        "time": 1689849066000,
        "value": 139.376,
        "_internal_originalTime": 1689849066000
    },
    {
        "time": 1689849068000,
        "value": 139.381,
        "_internal_originalTime": 1689849068000
    },
    {
        "time": 1689849070000,
        "value": 139.378,
        "_internal_originalTime": 1689849070000
    },
    {
        "time": 1689849071000,
        "value": 139.372,
        "_internal_originalTime": 1689849071000
    },
    {
        "time": 1689849072000,
        "value": 139.38,
        "_internal_originalTime": 1689849072000
    },
    {
        "time": 1689849075000,
        "value": 139.378,
        "_internal_originalTime": 1689849075000
    },
    {
        "time": 1689849077000,
        "value": 139.378,
        "_internal_originalTime": 1689849077000
    },
    {
        "time": 1689849078000,
        "value": 139.378,
        "_internal_originalTime": 1689849078000
    },
    {
        "time": 1689849079000,
        "value": 139.38,
        "_internal_originalTime": 1689849079000
    },
    {
        "time": 1689849081000,
        "value": 139.378,
        "_internal_originalTime": 1689849081000
    },
    {
        "time": 1689849083000,
        "value": 139.38,
        "_internal_originalTime": 1689849083000
    },
    {
        "time": 1689849085000,
        "value": 139.38,
        "_internal_originalTime": 1689849085000
    },
    {
        "time": 1689849086000,
        "value": 139.376,
        "_internal_originalTime": 1689849086000
    },
    {
        "time": 1689849087000,
        "value": 139.376,
        "_internal_originalTime": 1689849087000
    },
    {
        "time": 1689849089000,
        "value": 139.379,
        "_internal_originalTime": 1689849089000
    },
    {
        "time": 1689849090000,
        "value": 139.378,
        "_internal_originalTime": 1689849090000
    },
    {
        "time": 1689849091000,
        "value": 139.379,
        "_internal_originalTime": 1689849091000
    },
    {
        "time": 1689849094000,
        "value": 139.379,
        "_internal_originalTime": 1689849094000
    },
    {
        "time": 1689849096000,
        "value": 139.38,
        "_internal_originalTime": 1689849096000
    },
    {
        "time": 1689849098000,
        "value": 139.376,
        "_internal_originalTime": 1689849098000
    },
    {
        "time": 1689849100000,
        "value": 139.369,
        "_internal_originalTime": 1689849100000
    },
    {
        "time": 1689849101000,
        "value": 139.365,
        "_internal_originalTime": 1689849101000
    },
    {
        "time": 1689849103000,
        "value": 139.367,
        "_internal_originalTime": 1689849103000
    },
    {
        "time": 1689849105000,
        "value": 139.37,
        "_internal_originalTime": 1689849105000
    },
    {
        "time": 1689849106000,
        "value": 139.371,
        "_internal_originalTime": 1689849106000
    },
    {
        "time": 1689849107000,
        "value": 139.368,
        "_internal_originalTime": 1689849107000
    },
    {
        "time": 1689849109000,
        "value": 139.369,
        "_internal_originalTime": 1689849109000
    },
    {
        "time": 1689849111000,
        "value": 139.367,
        "_internal_originalTime": 1689849111000
    },
    {
        "time": 1689849112000,
        "value": 139.368,
        "_internal_originalTime": 1689849112000
    },
    {
        "time": 1689849113000,
        "value": 139.371,
        "_internal_originalTime": 1689849113000
    },
    {
        "time": 1689849115000,
        "value": 139.371,
        "_internal_originalTime": 1689849115000
    },
    {
        "time": 1689849117000,
        "value": 139.367,
        "_internal_originalTime": 1689849117000
    },
    {
        "time": 1689849119000,
        "value": 139.372,
        "_internal_originalTime": 1689849119000
    },
    {
        "time": 1689849120000,
        "value": 139.368,
        "_internal_originalTime": 1689849120000
    },
    {
        "time": 1689849121000,
        "value": 139.371,
        "_internal_originalTime": 1689849121000
    },
    {
        "time": 1689849124000,
        "value": 139.368,
        "_internal_originalTime": 1689849124000
    },
    {
        "time": 1689849125000,
        "value": 139.37,
        "_internal_originalTime": 1689849125000
    },
    {
        "time": 1689849126000,
        "value": 139.371,
        "_internal_originalTime": 1689849126000
    },
    {
        "time": 1689849128000,
        "value": 139.367,
        "_internal_originalTime": 1689849128000
    },
    {
        "time": 1689849129000,
        "value": 139.366,
        "_internal_originalTime": 1689849129000
    },
    {
        "time": 1689849131000,
        "value": 139.368,
        "_internal_originalTime": 1689849131000
    },
    {
        "time": 1689849132000,
        "value": 139.368,
        "_internal_originalTime": 1689849132000
    },
    {
        "time": 1689849136000,
        "value": 139.367,
        "_internal_originalTime": 1689849136000
    },
    {
        "time": 1689849138000,
        "value": 139.37,
        "_internal_originalTime": 1689849138000
    },
    {
        "time": 1689849139000,
        "value": 139.371,
        "_internal_originalTime": 1689849139000
    },
    {
        "time": 1689849140000,
        "value": 139.371,
        "_internal_originalTime": 1689849140000
    },
    {
        "time": 1689849142000,
        "value": 139.371,
        "_internal_originalTime": 1689849142000
    },
    {
        "time": 1689849144000,
        "value": 139.367,
        "_internal_originalTime": 1689849144000
    },
    {
        "time": 1689849145000,
        "value": 139.367,
        "_internal_originalTime": 1689849145000
    },
    {
        "time": 1689849147000,
        "value": 139.367,
        "_internal_originalTime": 1689849147000
    },
    {
        "time": 1689849148000,
        "value": 139.367,
        "_internal_originalTime": 1689849148000
    },
    {
        "time": 1689849149000,
        "value": 139.368,
        "_internal_originalTime": 1689849149000
    },
    {
        "time": 1689849150000,
        "value": 139.366,
        "_internal_originalTime": 1689849150000
    },
    {
        "time": 1689849152000,
        "value": 139.361,
        "_internal_originalTime": 1689849152000
    },
    {
        "time": 1689849153000,
        "value": 139.357,
        "_internal_originalTime": 1689849153000
    },
    {
        "time": 1689849154000,
        "value": 139.362,
        "_internal_originalTime": 1689849154000
    },
    {
        "time": 1689849156000,
        "value": 139.357,
        "_internal_originalTime": 1689849156000
    },
    {
        "time": 1689849158000,
        "value": 139.349,
        "_internal_originalTime": 1689849158000
    },
    {
        "time": 1689849160000,
        "value": 139.347,
        "_internal_originalTime": 1689849160000
    },
    {
        "time": 1689849162000,
        "value": 139.351,
        "_internal_originalTime": 1689849162000
    },
    {
        "time": 1689849163000,
        "value": 139.347,
        "_internal_originalTime": 1689849163000
    },
    {
        "time": 1689849165000,
        "value": 139.348,
        "_internal_originalTime": 1689849165000
    },
    {
        "time": 1689849168000,
        "value": 139.348,
        "_internal_originalTime": 1689849168000
    },
    {
        "time": 1689849169000,
        "value": 139.347,
        "_internal_originalTime": 1689849169000
    },
    {
        "time": 1689849170000,
        "value": 139.347,
        "_internal_originalTime": 1689849170000
    },
    {
        "time": 1689849171000,
        "value": 139.345,
        "_internal_originalTime": 1689849171000
    },
    {
        "time": 1689849173000,
        "value": 139.35,
        "_internal_originalTime": 1689849173000
    },
    {
        "time": 1689849175000,
        "value": 139.345,
        "_internal_originalTime": 1689849175000
    },
    {
        "time": 1689849177000,
        "value": 139.348,
        "_internal_originalTime": 1689849177000
    },
    {
        "time": 1689849178000,
        "value": 139.347,
        "_internal_originalTime": 1689849178000
    },
    {
        "time": 1689849179000,
        "value": 139.347,
        "_internal_originalTime": 1689849179000
    },
    {
        "time": 1689849180000,
        "value": 139.345,
        "_internal_originalTime": 1689849180000
    },
    {
        "time": 1689849181000,
        "value": 139.349,
        "_internal_originalTime": 1689849181000
    },
    {
        "time": 1689849182000,
        "value": 139.345,
        "_internal_originalTime": 1689849182000
    },
    {
        "time": 1689849184000,
        "value": 139.344,
        "_internal_originalTime": 1689849184000
    },
    {
        "time": 1689849185000,
        "value": 139.347,
        "_internal_originalTime": 1689849185000
    },
    {
        "time": 1689849186000,
        "value": 139.35,
        "_internal_originalTime": 1689849186000
    },
    {
        "time": 1689849188000,
        "value": 139.346,
        "_internal_originalTime": 1689849188000
    },
    {
        "time": 1689849189000,
        "value": 139.348,
        "_internal_originalTime": 1689849189000
    },
    {
        "time": 1689849191000,
        "value": 139.349,
        "_internal_originalTime": 1689849191000
    },
    {
        "time": 1689849192000,
        "value": 139.343,
        "_internal_originalTime": 1689849192000
    },
    {
        "time": 1689849193000,
        "value": 139.347,
        "_internal_originalTime": 1689849193000
    },
    {
        "time": 1689849194000,
        "value": 139.348,
        "_internal_originalTime": 1689849194000
    },
    {
        "time": 1689849195000,
        "value": 139.347,
        "_internal_originalTime": 1689849195000
    },
    {
        "time": 1689849197000,
        "value": 139.347,
        "_internal_originalTime": 1689849197000
    },
    {
        "time": 1689849198000,
        "value": 139.346,
        "_internal_originalTime": 1689849198000
    },
    {
        "time": 1689849199000,
        "value": 139.352,
        "_internal_originalTime": 1689849199000
    },
    {
        "time": 1689849200000,
        "value": 139.349,
        "_internal_originalTime": 1689849200000
    },
    {
        "time": 1689849203000,
        "value": 139.352,
        "_internal_originalTime": 1689849203000
    },
    {
        "time": 1689849204000,
        "value": 139.344,
        "_internal_originalTime": 1689849204000
    },
    {
        "time": 1689849206000,
        "value": 139.348,
        "_internal_originalTime": 1689849206000
    },
    {
        "time": 1689849207000,
        "value": 139.351,
        "_internal_originalTime": 1689849207000
    },
    {
        "time": 1689849210000,
        "value": 139.35,
        "_internal_originalTime": 1689849210000
    },
    {
        "time": 1689849211000,
        "value": 139.347,
        "_internal_originalTime": 1689849211000
    },
    {
        "time": 1689849212000,
        "value": 139.346,
        "_internal_originalTime": 1689849212000
    },
    {
        "time": 1689849214000,
        "value": 139.351,
        "_internal_originalTime": 1689849214000
    },
    {
        "time": 1689849215000,
        "value": 139.352,
        "_internal_originalTime": 1689849215000
    },
    {
        "time": 1689849216000,
        "value": 139.349,
        "_internal_originalTime": 1689849216000
    },
    {
        "time": 1689849218000,
        "value": 139.352,
        "_internal_originalTime": 1689849218000
    },
    {
        "time": 1689849221000,
        "value": 139.349,
        "_internal_originalTime": 1689849221000
    },
    {
        "time": 1689849222000,
        "value": 139.343,
        "_internal_originalTime": 1689849222000
    },
    {
        "time": 1689849224000,
        "value": 139.347,
        "_internal_originalTime": 1689849224000
    },
    {
        "time": 1689849225000,
        "value": 139.343,
        "_internal_originalTime": 1689849225000
    },
    {
        "time": 1689849227000,
        "value": 139.343,
        "_internal_originalTime": 1689849227000
    },
    {
        "time": 1689849229000,
        "value": 139.345,
        "_internal_originalTime": 1689849229000
    },
    {
        "time": 1689849230000,
        "value": 139.346,
        "_internal_originalTime": 1689849230000
    },
    {
        "time": 1689849232000,
        "value": 139.348,
        "_internal_originalTime": 1689849232000
    },
    {
        "time": 1689849235000,
        "value": 139.351,
        "_internal_originalTime": 1689849235000
    },
    {
        "time": 1689849236000,
        "value": 139.352,
        "_internal_originalTime": 1689849236000
    },
    {
        "time": 1689849238000,
        "value": 139.346,
        "_internal_originalTime": 1689849238000
    },
    {
        "time": 1689849239000,
        "value": 139.35,
        "_internal_originalTime": 1689849239000
    },
    {
        "time": 1689849240000,
        "value": 139.349,
        "_internal_originalTime": 1689849240000
    },
    {
        "time": 1689849241000,
        "value": 139.346,
        "_internal_originalTime": 1689849241000
    },
    {
        "time": 1689849242000,
        "value": 139.343,
        "_internal_originalTime": 1689849242000
    },
    {
        "time": 1689849243000,
        "value": 139.347,
        "_internal_originalTime": 1689849243000
    },
    {
        "time": 1689849244000,
        "value": 139.346,
        "_internal_originalTime": 1689849244000
    },
    {
        "time": 1689849245000,
        "value": 139.352,
        "_internal_originalTime": 1689849245000
    },
    {
        "time": 1689849247000,
        "value": 139.349,
        "_internal_originalTime": 1689849247000
    },
    {
        "time": 1689849248000,
        "value": 139.351,
        "_internal_originalTime": 1689849248000
    },
    {
        "time": 1689849250000,
        "value": 139.351,
        "_internal_originalTime": 1689849250000
    },
    {
        "time": 1689849251000,
        "value": 139.35,
        "_internal_originalTime": 1689849251000
    },
    {
        "time": 1689849253000,
        "value": 139.351,
        "_internal_originalTime": 1689849253000
    },
    {
        "time": 1689849255000,
        "value": 139.35,
        "_internal_originalTime": 1689849255000
    },
    {
        "time": 1689849256000,
        "value": 139.353,
        "_internal_originalTime": 1689849256000
    },
    {
        "time": 1689849257000,
        "value": 139.361,
        "_internal_originalTime": 1689849257000
    },
    {
        "time": 1689849258000,
        "value": 139.364,
        "_internal_originalTime": 1689849258000
    },
    {
        "time": 1689849261000,
        "value": 139.363,
        "_internal_originalTime": 1689849261000
    },
    {
        "time": 1689849262000,
        "value": 139.358,
        "_internal_originalTime": 1689849262000
    },
    {
        "time": 1689849263000,
        "value": 139.359,
        "_internal_originalTime": 1689849263000
    },
    {
        "time": 1689849265000,
        "value": 139.361,
        "_internal_originalTime": 1689849265000
    },
    {
        "time": 1689849266000,
        "value": 139.363,
        "_internal_originalTime": 1689849266000
    },
    {
        "time": 1689849268000,
        "value": 139.367,
        "_internal_originalTime": 1689849268000
    },
    {
        "time": 1689849270000,
        "value": 139.366,
        "_internal_originalTime": 1689849270000
    },
    {
        "time": 1689849271000,
        "value": 139.37,
        "_internal_originalTime": 1689849271000
    },
    {
        "time": 1689849272000,
        "value": 139.367,
        "_internal_originalTime": 1689849272000
    },
    {
        "time": 1689849274000,
        "value": 139.366,
        "_internal_originalTime": 1689849274000
    },
    {
        "time": 1689849275000,
        "value": 139.362,
        "_internal_originalTime": 1689849275000
    },
    {
        "time": 1689849277000,
        "value": 139.365,
        "_internal_originalTime": 1689849277000
    },
    {
        "time": 1689849278000,
        "value": 139.362,
        "_internal_originalTime": 1689849278000
    },
    {
        "time": 1689849280000,
        "value": 139.361,
        "_internal_originalTime": 1689849280000
    },
    {
        "time": 1689849281000,
        "value": 139.36,
        "_internal_originalTime": 1689849281000
    },
    {
        "time": 1689849283000,
        "value": 139.36,
        "_internal_originalTime": 1689849283000
    },
    {
        "time": 1689849286000,
        "value": 139.362,
        "_internal_originalTime": 1689849286000
    },
    {
        "time": 1689849288000,
        "value": 139.365,
        "_internal_originalTime": 1689849288000
    },
    {
        "time": 1689849289000,
        "value": 139.371,
        "_internal_originalTime": 1689849289000
    },
    {
        "time": 1689849290000,
        "value": 139.372,
        "_internal_originalTime": 1689849290000
    },
    {
        "time": 1689849292000,
        "value": 139.369,
        "_internal_originalTime": 1689849292000
    },
    {
        "time": 1689849294000,
        "value": 139.371,
        "_internal_originalTime": 1689849294000
    },
    {
        "time": 1689849295000,
        "value": 139.372,
        "_internal_originalTime": 1689849295000
    },
    {
        "time": 1689849297000,
        "value": 139.375,
        "_internal_originalTime": 1689849297000
    },
    {
        "time": 1689849299000,
        "value": 139.375,
        "_internal_originalTime": 1689849299000
    },
    {
        "time": 1689849300000,
        "value": 139.374,
        "_internal_originalTime": 1689849300000
    },
    {
        "time": 1689849301000,
        "value": 139.374,
        "_internal_originalTime": 1689849301000
    },
    {
        "time": 1689849302000,
        "value": 139.372,
        "_internal_originalTime": 1689849302000
    },
    {
        "time": 1689849304000,
        "value": 139.37,
        "_internal_originalTime": 1689849304000
    },
    {
        "time": 1689849305000,
        "value": 139.371,
        "_internal_originalTime": 1689849305000
    },
    {
        "time": 1689849306000,
        "value": 139.37,
        "_internal_originalTime": 1689849306000
    },
    {
        "time": 1689849308000,
        "value": 139.371,
        "_internal_originalTime": 1689849308000
    },
    {
        "time": 1689849309000,
        "value": 139.372,
        "_internal_originalTime": 1689849309000
    },
    {
        "time": 1689849311000,
        "value": 139.37,
        "_internal_originalTime": 1689849311000
    },
    {
        "time": 1689849312000,
        "value": 139.371,
        "_internal_originalTime": 1689849312000
    },
    {
        "time": 1689849313000,
        "value": 139.37,
        "_internal_originalTime": 1689849313000
    },
    {
        "time": 1689849315000,
        "value": 139.365,
        "_internal_originalTime": 1689849315000
    },
    {
        "time": 1689849316000,
        "value": 139.362,
        "_internal_originalTime": 1689849316000
    },
    {
        "time": 1689849317000,
        "value": 139.363,
        "_internal_originalTime": 1689849317000
    }
]