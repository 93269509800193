import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import TimelineItem from "examples/Timeline/TimelineItem";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";

import axios from "axios";
import { useState, useEffect } from "react";

const colors = {
  matic: "info",
  usdt: "success",
  bnb: "warning",
};

function Balances() {
  const [systemBalance, setSystemBalance] = useState();
  useEffect(() => {
    const load = async () => {
      const _systemBalance = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/balances/total",
        {
          withCredentials: true,
        }
      );

      setSystemBalance(_systemBalance.data);
    };
    load();
  }, []);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium">
            System Balances
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          {systemBalance &&
            systemBalance.map((b, i) => (
              <TimelineItem
                key={b._id}
                color={colors[b._id] || "primary"}
                icon="notifications"
                title={b.sum.toFixed(2) + " " + b._id.toUpperCase()}
                dateTime=""
                lastItem={i === systemBalance.length - 1}
              />
            ))}
        </MDBox>
      </Card>
    </>
  );
}

export default Balances;
