// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import PieChart from "examples/Charts/PieChart";
import BetsTable from "layouts/bets/BetsTable";

// Dashboard components
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { shortened } from "helpers";
import { useLocation, useParams } from "react-router-dom";

function Dashboard() {
  const location = useLocation();
  const { partnerName } = useParams();
  const [partner, setPartner] = useState();
  const [days, setDays] = useState(7);
  const [statistics, setStatistics] = useState();
  const [profit, setProfit] = useState();
  const [dateFrom, setDateFrom] = useState(
    new Date(moment.utc().subtract({ days: 7 }))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [betStats, setBetStats] = useState();
  const [topBalanceUsers, setTopBalanceUsers] = useState();

  const [betsData, setBetsData] = useState();

  useEffect(() => {
    if (!partner) return;
    const load = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URI +
          "/api/v1/dashboard/partners/" +
          partner +
          "/bets",
        {
          withCredentials: true,
        }
      );
      setBetsData(result.data);
    };
    load();
  }, [partner]);

  useEffect(() => {
    if (partnerName) {
      setPartner(partnerName);
      return;
    }
    let tmp = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1,
      location.pathname.length
    );
    setPartner(tmp);
  }, [partnerName]);

  useEffect(() => {
    const load = async () => {
      if (!partner) return;
      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/partners/" + partner,
        {
          withCredentials: true,
          params: {
            days,
            dateFrom,
            dateTo,
          },
        }
      );
      setStatistics(result.data);

      const allBets = result.data.betsSince.map((b) => ({
        ...b,
        date: b.startTimestamp.toString().substring(0, 10),
      }));

      const allBetStats = allBets.reduce(
        (acc, cur) => {
          //if (!acc || !acc.dailyBetStats) acc = { dailyBetStats: {} };
          if (!acc.dailyBetStats[cur.date])
            acc.dailyBetStats[cur.date] = {
              _id: cur.date,
              totalBet: 0,
              count: 0,
            };
          acc.dailyBetStats[cur.date].count++;
          acc.dailyBetStats[cur.date].totalBet += cur.amountUSD;

          if (!acc.targetTokens[cur.targetToken])
            acc.targetTokens[cur.targetToken] = {
              _id: cur.targetToken,
              count: 0,
            };

          acc.targetTokens[cur.targetToken].count++;

          return acc;
        },
        { dailyBetStats: {}, targetTokens: {} }
      );

      const _betStats = Object.values(allBetStats.dailyBetStats).sort(
        (a, b) => b._id - a._id
      );

      const labels = Array(7)
        .fill()
        .map((i, idx) => {
          return moment().subtract(idx, "d").format("YYYY-MM-DD");
        })
        .reverse();

      const dailyBetCount = [0, 0, 0, 0, 0, 0, 0];
      const dailyBetSum = [0, 0, 0, 0, 0, 0, 0];

      for (let i = 0; i < 7; i++) {
        const stat = _betStats.find((s) => s._id == labels[i]);
        if (!stat) continue;
        dailyBetCount[i] = stat.count;
        dailyBetSum[i] = stat.totalBet;
      }

      setBetStats({
        labels,
        count: dailyBetCount,
        sum: dailyBetSum,
        targetTokens: Object.values(allBetStats.targetTokens),
      });
    };

    load();
  }, [days, partner, dateFrom, dateTo]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {statistics && (
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Users"}
                        count={statistics.usersCount}
                        percentage={{
                          color: "success",
                          amount: "+" + statistics.usersSinceDate,
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Active Users"}
                        count={statistics.activeUsersSinceDate}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Bets"}
                        count={statistics.betsCount}
                        percentage={{
                          color: "success",
                          amount: "+" + statistics.betsSince.length,
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item md={3}>
                    <DatePicker
                      selected={dateFrom}
                      onChange={(date) => setDateFrom(date)}
                    />
                    <DatePicker
                      selected={dateTo}
                      onChange={(date) => setDateTo(date)}
                    />
                    {
                      <MDBox mb={1.5} mt={4}>
                        <ComplexStatisticsCard
                          icon="leaderboard"
                          title={"HouseProfit"}
                          count={statistics.houseProfit.toFixed(2) + " $"}
                          percentage={{
                            color: "success",
                            amount: dateFrom + " - " + dateTo,
                            label: "",
                          }}
                        />
                      </MDBox>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <MDBox mt={4.5}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    {topBalanceUsers && (
                      <MDBox mb={3}>
                        <HorizontalBarChart
                          icon={{ color: "info", component: "leaderboard" }}
                          title="Top Balances"
                          description="Top user balances / converted to USD"
                          chart={{
                            labels: topBalanceUsers.map((t) =>
                              shortened(t.address)
                            ),
                            tooltipText: topBalanceUsers.map((t) => t.address),
                            datasets: [
                              {
                                label: "USD ",
                                color: "dark",
                                data: topBalanceUsers.map((t) => t.usd),
                              },
                            ],
                          }}
                        />
                      </MDBox>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MDBox mb={3}>
                      <HorizontalBarChart
                        icon={{ color: "info", component: "leaderboard" }}
                        title="Top Earners"
                        description="All time USD house profits"
                        chart={{
                          labels: statistics.topEarners.map((t) =>
                            shortened(t.address)
                          ),
                          tooltipText: statistics.topEarners.map(
                            (t) => t.address
                          ),
                          datasets: [
                            {
                              label: "USD",
                              color: "dark",
                              data: statistics.topEarners.map((t) =>
                                Math.abs(t.houseProfit)
                              ),
                            },
                          ],
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox mb={3}>
                      <HorizontalBarChart
                        icon={{ color: "info", component: "leaderboard" }}
                        title="Top WinRates"
                        description="All time % among users with profit"
                        chart={{
                          labels: statistics.topWinRates.map((t) =>
                            shortened(t.address)
                          ),
                          tooltipText: statistics.topWinRates.map(
                            (t) => t.address
                          ),
                          datasets: [
                            {
                              label: "%",
                              color: "dark",
                              data: statistics.topWinRates.map((t) =>
                                parseInt(t.winRate * 100)
                              ),
                            },
                          ],
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>

                <Grid item md={6}>
                  {betStats && (
                    <>
                      <Grid item xs={12} mt={8}>
                        <MDBox mb={3}>
                          <ReportsLineChart
                            color="success"
                            title="Daily Total Bets"
                            chart={{
                              labels: betStats.labels,
                              datasets: [
                                {
                                  label: "total Bets in USD",
                                  data: betStats.sum,
                                },
                              ],
                            }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} mt={8}>
                        <MDBox mb={3}>
                          <ReportsLineChart
                            color="info"
                            title="Daily Bet Count"
                            chart={{
                              labels: betStats.labels,
                              datasets: [
                                {
                                  label: "num Bets",
                                  data: betStats.count,
                                },
                              ],
                            }}
                          />
                        </MDBox>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <MDBox mb={3}>
                            <PieChart
                              icon={{
                                color: "info",
                                component: "leaderboard",
                              }}
                              title="Token Stats"
                              description={days + " days"}
                              chart={{
                                labels: betStats.targetTokens.map((s) => s._id),
                                datasets: {
                                  label: "Projects",
                                  backgroundColors: [
                                    "info",
                                    "primary",
                                    "dark",
                                    "secondary",
                                    "primary",
                                  ],
                                  data: betStats.targetTokens.map(
                                    (s) => s.count
                                  ),
                                },
                              }}
                            />
                          </MDBox>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </MDBox>
            {betsData && <BetsTable data={betsData} />}
          </Stack>
        </Stack>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
