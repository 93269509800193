// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import { createChart, ColorType } from "lightweight-charts";
import { Line } from "react-chartjs-2";
import pricedata from "./pricedata";
import priceChangeData from "./priceChangeData"

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
// Dashboard components
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

function Dashboard() {
  const [round, setRound] = useState(24382);
  const [roundData, setRoundData] = useState([]);
  const [prices, setPrices] = useState([]);

  const chartContainerRef = useRef();

  useEffect(() => {
    // const handleResize = () => {
    //   chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    // };
    let chart;

    try {
      chart = createChart(chartContainerRef.current, {
        layout: {
          background: { type: ColorType.Solid, color: "white" },
          textColor: "black",
        },
        width: 1200,
        height: 600,
        timeScale: {
          timeVisible: true,
          secondsVisible: true,
        },
      });
      chart
        .timeScale()
        .fitContent();

      const newSeries = chart.addAreaSeries({
        lineColor: "#2962FF",
        topColor: "#2962FF",
        bottomColor: "rgba(41, 98, 255, 0.28)",
      });

      const cache = {};

      // let pr = pricedata
      //   .map((r) => {
      //     if (!r.time) {
      //       console.log(r);
      //     }
      //     if (!cache[r.time]) {
      //       cache[r.time] = true;
      //       return { time: r.time / 1000, value: r.value };
      //     }
      //   })
      //   .filter((p) => p);
      //console.log("prices", pr);

      newSeries.setData(priceChangeData);
    } catch (err) {
      console.log(err);
    }

    //window.addEventListener("resize", handleResize);

    return () => {
      // window.removeEventListener("resize", handleResize);

      chart.remove();
    };
  }, []);

  // const loadChart = async () => {
  //   //   const _round = await axios.get(
  //   //     process.env.REACT_APP_API_URI + "/api/v1/dashboard/bets/" + round,
  //   //     {
  //   //       withCredentials: true,
  //   //     }
  //   //   );
  //   //   console.log("roundData",_round.data);
  //   //   setRoundData(_round.data);

  //   const result = await axios.post(
  //     process.env.REACT_APP_API_URI + "/api/v1/dashboard/priceChart",
  //     {
  //       withCredentials: true,
  //       token: "USD/JPY",
  //       dateFrom: new Date("2023-07-18T00:00:00.624Z").getTime() - 0,
  //       dateTo: new Date().getTime() + 0,
  //     }
  //   );
  //   console.log(result.data);
  //   const cache = {};
  //   let pr = result.data.map((r) => {
  //     if (!cache[new Date(r.timestamp).getTime()])
  //       return { time: new Date(r.timestamp).getTime(), value: r.price };
  //     cache[new Date(r.timestamp).getTime()] = true;
  //   });
  //   pr = [...new Set(pr)];
  //   console.log("prices", pr);
  //   setPrices(pr);
  // };

  // useEffect(() => {
  //   loadChart();
  // }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <input
        type="text"
        value={round}
        onChange={(e) => setRound(e.target.value)}
      />
      <button onClick={loadChart}>load chart</button>
      {roundData && (
        <>
          <p>{roundData.position === 0 ? "UP" : "DOWN"}</p>
          <p>{roundData.sender}</p>
        </>
      )} */}

      <div ref={chartContainerRef} />
      {/* {prices && (
        <div ref={chartContainerRef} />
        // <Line
        //   options={options}
        //   data={{
        //     labels: prices.map((p) => p.timestamp),
        //     datasets: [
        //       {
        //         label: "BTC/USDT",
        //         data: prices.map((p) => p.price),
        //         borderColor: "rgb(255, 99, 132)",
        //         backgroundColor: "rgba(255, 99, 132, 0.5)",
        //       },
        //     ],
        //   }}
        // />
      )} */}
    </DashboardLayout>
  );
}

export default Dashboard;
