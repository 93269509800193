// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CircularProgress from "@mui/material/CircularProgress";
import "./table.css";

// Data
import wallets from "./wallets";
import { useEffect, useState } from "react";
import axios from "axios";
import BigNumber from "bignumber.js";

const ethers = require("ethers");
const balanceCheckerABI = require("../../constants/balanceCheckerABI.json");
const { JsonRpcProvider } = require("@ethersproject/providers");

const rpcPOLYGON =
  "https://polygon-mainnet.g.alchemy.com/v2/ndVuZ1WBu4AxFZM9G1Qol0syj6blC1wV"; //"https://polygon-rpc.com/";
const providerPOLYGON = new JsonRpcProvider(rpcPOLYGON);
const rpcBSC = "https://bsc-dataseed1.defibit.io";
const providerBSC = new JsonRpcProvider(rpcBSC);

const contracts = {
  bsc: new ethers.Contract(
    "0x5f8a1dCc0105a171cbec1c939EEB0Ed97BB4535d",
    balanceCheckerABI,
    providerBSC
  ),
};

function ProfitWallets() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);

      const result = await axios.get(
        "https://api.prdt.finance/api/v1/dashboard/profitWallets",
        {
          withCredentials: true,
        }
      );

      const balances = await contracts.bsc.check(wallets);

      const users = [];

      for (let i = 0; i < balances[0].length; i++) {
        const proUserBNB = result.data.find(
          (u) => u.sender == balances[0][i] && u.token == "bnb"
        );
        const proUserMatic = result.data.find(
          (u) => u.sender == balances[0][i] && u.token == "matic"
        );

        users.push({
          address: balances[0][i],
          balance: new BigNumber(balances[1][i].toString())
            .dividedBy(10 ** 18)
            .toFixed(4),
          depositBNB: proUserBNB ? proUserBNB.profitWalletAmount.toFixed(2) : 0,
          amountBNB: proUserBNB ? proUserBNB.amount.toFixed(2) : 0,
          depositMatic: proUserMatic
            ? proUserMatic.profitWalletAmount.toFixed(2)
            : 0,
          amountMatic: proUserMatic ? proUserMatic.amount.toFixed(2) : 0,
        });
      }

      setIsLoading(false);
      setData(users);
    };
    load();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <CircularProgress color="success" />}
      {data && (
        <table className="userstable">
          <thead>
            <tr>
              <th>User</th>
              <th>BlockChain Balance</th>
              <th>BNB TotalDeposit</th>
              <th>BNB Balance</th>
              <th>Matic TotalDeposit</th>
              <th>Matic Balance</th>
            </tr>
          </thead>
          <tbody>
            {data.map((u) => (
              <tr key={u.address}>
                <td>{u.address}</td>
                <td>{u.balance}</td>
                <td>{u.depositBNB}</td>
                <td>{u.amountBNB}</td>
                <td>{u.depositMatic}</td>
                <td>{u.amountMatic}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </DashboardLayout>
  );
}

export default ProfitWallets;
