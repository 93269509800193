import React, { useEffect, useState } from "react";
import Web3 from "web3";
import axios from "axios";
import classicNewABI from "../../../constants/classicNewABI";

const web3Bsc = new Web3("https://bsc-dataseed3.defibit.io/");
const web3Matic = new Web3("https://polygon-rpc.com/");

const contracts = {
  Bsc: new web3Bsc.eth.Contract(
    classicNewABI,
    "0x00199E444155f6a06d74CF36315419d39b874f5c"
  ),
  Polygon: new web3Matic.eth.Contract(
    classicNewABI,
    "0x9f9564BE7b566dfE4B091a83a591752102aF3F33"
  ),
};

export const TopWinnersTable = ({ title, winners = [] }) => {
  const [isFlagged, setIsFlagged] = useState([]);
  const [nicknames, setNicknames] = useState({});

  const loadNicknames = async () => {
    console.log("loading nicknames");
    const result = await axios.get(
      `${process.env.REACT_APP_API_URI}/api/v1/dashboard/nicknames`,
      {
        withCredentials: true,
      }
    );
    let nicks = {};
    for (let i = 0; i < result.data.length; i++) {
      let nickname = result.data[i].nickname;
      if (nickname?.length === 0) nickname = null;
      let label = result.data[i].label;
      if (label?.length === 0) label = null;
      nicks[result.data[i].address.toLowerCase()] = { nickname, label };
    }
    console.log("nicks", nicks);
    setNicknames(nicks);
  };

  useEffect(() => {
    loadNicknames();
  }, []);

  useEffect(() => {
    const load = async () => {
      console.log("loading winners flags", title, winners.length);
      if (!title) return;
      const contract = contracts[title];

      const calls = [];
      for (let i = 0; i < winners.length; i++) {
        calls.push(contract.methods.flaggedUsers(winners[i].sender).call());
      }

      const result = await Promise.all(calls);
      setIsFlagged(result);
    };

    load();
  }, [title, winners]);

  return (
    <>
      {" "}
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full">
                <thead className="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      {title}
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Flagged?
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Count Bet
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Count Won
                    </th>{" "}
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Win Rate %
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      Total Bet
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                    >
                      House Profit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {winners.map((user, i) => (
                    <tr
                      key={user.sender}
                      className={
                        i % 2 == 1 ? "bg-white" : "bg-gray-100" + " border-b"
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <a href={"/classic/user/" + user.sender}>
                          {nicknames[user.sender]?.nickname
                            ? nicknames[user.sender].nickname
                            : user.sender}
                        </a>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {isFlagged[i] ? "flagged" : ""}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {user.countBet}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {user.countWon}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {parseInt(100 * (user.countWon / user.countBet))}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {user.totalBet}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {user.houseProfit}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
