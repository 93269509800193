// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import PieChart from "examples/Charts/PieChart";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { useEffect, useState } from "react";
import axios from "axios";
import ContractBalances from "./components/ContractBalances";
import SystemBalances from "./components/SystemBalances";
import OperatorBalances from "./components/OperatorBalances";
import DashboardGraphs from "./components/DashboardGraphs";
import moment from "moment";

function Dashboard() {
  const [anomalies, setAnomalies] = useState([]);
  const [days, setDays] = useState(1);
  const [statistics, setStatistics] = useState();
  const [profit, setProfit] = useState();
  const [dateFrom, setDateFrom] = useState(
    new Date(moment.utc().subtract({ days: 7 }))
  );
  const [dateTo, setDateTo] = useState(new Date());
  const [betStats, setBetStats] = useState();
  const [topBalanceUsers, setTopBalanceUsers] = useState();

  const [pausedWarningStatus, setPausedWarningStatus] = useState(false);

  useEffect(() => {
    // Function to fetch status from the API
    const fetchStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/v1/dashboard/status`
        );

        console.log("/status", response.data);
        setPausedWarningStatus(response.data.filter((s) => s.value).length > 0);
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchStatus();
    const timer = setInterval(() => fetchStatus(), 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const load = async () => {
      const resultT = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/prediction/bets",
        {
          withCredentials: true,
          params: {
            sortBy: "amountUSD",
            sortDirection: 1,
          },
        }
      );

      console.log("../prediction/bets", resultT.data);

      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard",
        {
          withCredentials: true,
          params: {
            days,
          },
        }
      );
      console.log("/", result.data);
      setStatistics(result.data);

      console.log(result.data.dailyNetworkBetStats);
      console.log(result.data.dailyBetStats);

      const _betStats = result.data.dailyBetStats;
      const _networkBetStats = result.data.dailyNetworkBetStats;

      const dailyBetCount = [0, 0, 0, 0, 0, 0, 0];
      const dailyBetSum = [0, 0, 0, 0, 0, 0, 0];
      const dailyBetProfit = [0, 0, 0, 0, 0, 0, 0];
      const labels = [7];

      for (let i = 0; i < 7; i++) {
        const idx = 6 - i;
        labels[idx] = _betStats[i]._id;
        dailyBetCount[idx] = _betStats[i].count;
        dailyBetSum[idx] = _betStats[i].totalBet;
        const bonusData = result.data.dailyBonuses.find(
          (b) => b._id === _betStats[i]._id
        );
        const bonus = bonusData ? bonusData.sum : 0;
        dailyBetProfit[idx] =
          _betStats[i].totalBet - _betStats[i].amountWon - bonus;
      }

      const networkData = {
        eth: {
          dailyBetCount: [0, 0, 0, 0, 0, 0, 0],
          dailyBetSum: [0, 0, 0, 0, 0, 0, 0],
          dailyBetProfit: [0, 0, 0, 0, 0, 0, 0],
        },
        bsc: {
          dailyBetCount: [0, 0, 0, 0, 0, 0, 0],
          dailyBetSum: [0, 0, 0, 0, 0, 0, 0],
          dailyBetProfit: [0, 0, 0, 0, 0, 0, 0],
        },
        polygon: {
          dailyBetCount: [0, 0, 0, 0, 0, 0, 0],
          dailyBetSum: [0, 0, 0, 0, 0, 0, 0],
          dailyBetProfit: [0, 0, 0, 0, 0, 0, 0],
        },
        arb: {
          dailyBetCount: [0, 0, 0, 0, 0, 0, 0],
          dailyBetSum: [0, 0, 0, 0, 0, 0, 0],
          dailyBetProfit: [0, 0, 0, 0, 0, 0, 0],
        },
        prdt: {
          dailyBetCount: [0, 0, 0, 0, 0, 0, 0],
          dailyBetSum: [0, 0, 0, 0, 0, 0, 0],
          dailyBetProfit: [0, 0, 0, 0, 0, 0, 0],
        },
      };

      for (let i = 0; i < _networkBetStats.length; i++) {
        const date = _networkBetStats[i]._id.date;
        const dateIndex = labels.indexOf(date);
        const network = _networkBetStats[i]._id.network;
        networkData[network].dailyBetCount[dateIndex] =
          _networkBetStats[i].count;
        networkData[network].dailyBetSum[dateIndex] =
          _networkBetStats[i].totalBet;

        networkData[network].dailyBetProfit[dateIndex] =
          _networkBetStats[i].totalBet - _networkBetStats[i].amountWon;
      }

      setBetStats({
        labels,
        networkData,
        count: dailyBetCount,
        sum: dailyBetSum,
        profit: dailyBetProfit,
      });

      const topBalanceUsersData = result.data.allBalances;

      const tokenPrices = {
        usdt: 1,
        bnb: 311,
        matic: 1.05,
      };
      let topBalances = topBalanceUsersData.map((b) => ({
        ...b,
        usd: b.amount * tokenPrices[b.token],
      }));

      topBalances = topBalances.reduce((acc, cur) => {
        if (!acc[cur.sender]) acc[cur.sender] = 0;
        acc[cur.sender] += parseFloat(cur.usd);
        return acc;
      }, {});
      topBalances = Object.keys(topBalances)
        .map((key) => ({
          address: key,
          usd: topBalances[key],
        }))
        .sort((a, b) => parseFloat(b.usd) - parseFloat(a.usd))
        .slice(0, 5)
        .map((b) => ({ ...b, usd: parseInt(b.usd) }));

      setTopBalanceUsers(topBalances);
    };

    load();
  }, [days]);

  useEffect(() => {
    const load = async () => {
      const result = await axios.post(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/profits",
        {
          withCredentials: true,
          dateFrom,
          dateTo,
        }
      );
      console.log("/profits", result.data);
      setProfit(parseInt(result.data));
    };

    load();
  }, [dateFrom, dateTo]);

  // useEffect(() => {
  //   const load = async () => {
  //     const result = await axios.get(
  //       process.env.REACT_APP_API_URI + "/api/v1/dashboard/anomalies",
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     setAnomalies(result.data);
  //   };

  //   load();
  // }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!pausedWarningStatus && (
        <div className="w-3 h-3 bg-green-500 rounded-full"></div>
      )}
      {pausedWarningStatus && (
        <div className="w-3 h-3 bg-red-500 rounded-full"></div>
      )}
      {statistics && (
        <Stack direction="row" spacing={2}>
          <Grid item xs={12} md={2}>
            <Grid item xs={12} mt={1}>
              <ContractBalances />
            </Grid>
            <Grid item xs={12} mt={1}>
              <SystemBalances />
            </Grid>
            <Grid item xs={12} mt={1}>
              <OperatorBalances />
            </Grid>
          </Grid>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Users"}
                        count={statistics.usersCount}
                        percentage={{
                          color: "success",
                          amount: "+" + statistics.usersSinceDate,
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Active Users"}
                        count={statistics.activeUsersSinceDate}
                        percentage={{
                          color: "success",
                          amount: "",
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Bets"}
                        count={statistics.betsCount}
                        percentage={{
                          color: "success",
                          amount: "+" + statistics.betsSinceDate,
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Deposit Count"}
                        count={statistics.depositsCount}
                        percentage={{
                          color: "success",
                          amount: "+" + statistics.depositsSinceDate,
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Withdraw Count"}
                        count={statistics.withdrawsCount}
                        percentage={{
                          color: "success",
                          amount: "+" + statistics.withdrawsSinceDate,
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Deposit USD"}
                        count={statistics.depositsTotal.toFixed()}
                        percentage={{
                          color: "success",
                          amount:
                            "+" + statistics.depositsTotalSinceDate.toFixed(),
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        icon="leaderboard"
                        title={"Total Withdraw USD"}
                        count={statistics.withdrawsTotal.toFixed()}
                        percentage={{
                          color: "success",
                          amount:
                            "+" + statistics.withdrawsTotalSinceDate.toFixed(),
                          label: "last " + days + " day",
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4}>
                <DatePicker
                  selected={dateFrom}
                  onChange={(date) => setDateFrom(date)}
                />
                <DatePicker
                  selected={dateTo}
                  onChange={(date) => setDateTo(date)}
                />
                {profit && (
                  <MDBox mb={1.5} mt={4}>
                    <ComplexStatisticsCard
                      icon="leaderboard"
                      title={"HouseProfit"}
                      count={profit + " $"}
                      percentage={{
                        color: "success",
                        amount: dateFrom + " - " + dateTo,
                        label: "",
                      }}
                    />
                  </MDBox>
                )}
              </Grid>
            </Grid>
            {/* <MDBox mt={4.5}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Balance Anomalies
                        </MDTypography>
                        <Divider />
                        <MDTypography>
                          {anomalies.map((a, i) => (
                            <p key={i}>
                              {a.sender} , {a.network}, {a.token}, {a.check}
                            </p>
                          ))}
                        </MDTypography>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox> */}
            <DashboardGraphs
              topBalanceUsers={topBalanceUsers}
              statistics={statistics}
              betStats={betStats}
            />
          </Stack>
        </Stack>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
