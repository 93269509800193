// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import Grid from "@mui/material/Grid";
// Material Dashboard 2 React example components
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import PieChart from "examples/Charts/PieChart";

import { shortened } from "helpers";

const DashboardGraphs = ({ topBalanceUsers, statistics, betStats }) => {
  return (
    <MDBox mt={4.5}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid item xs={12}>
            {topBalanceUsers && (
              <MDBox mb={3}>
                <HorizontalBarChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Top Balances"
                  description="Top user balances / converted to USD"
                  chart={{
                    labels: topBalanceUsers.map((t) => shortened(t.address)),
                    tooltipText: topBalanceUsers.map((t) => t.address),
                    datasets: [
                      {
                        label: "USD ",
                        color: "dark",
                        data: topBalanceUsers.map((t) => t.usd),
                      },
                    ],
                  }}
                />
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <MDBox mb={3}>
              <HorizontalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Top Earners"
                description="All time USD house profits"
                chart={{
                  labels: statistics.topEarners.map((t) =>
                    shortened(t.address)
                  ),
                  tooltipText: statistics.topEarners.map((t) => t.address),
                  datasets: [
                    {
                      label: "USD",
                      color: "dark",
                      data: statistics.topEarners.map((t) =>
                        Math.abs(t.houseProfit)
                      ),
                    },
                  ],
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox mb={3}>
              <HorizontalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Top WinRates"
                description="All time % among users with profit"
                chart={{
                  labels: statistics.topWinRates.map((t) =>
                    shortened(t.address)
                  ),
                  tooltipText: statistics.topWinRates.map((t) => t.address),
                  datasets: [
                    {
                      label: "%",
                      color: "dark",
                      data: statistics.topWinRates.map((t) =>
                        parseInt(t.winRate * 100)
                      ),
                    },
                  ],
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        <Grid item md={6}>
          {betStats && (
            <>
              <Grid item xs={12}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="primary"
                    title="Daily House Profit"
                    chart={{
                      labels: betStats.labels,
                      datasets: [
                        {
                          label: "houseProfit in USD",
                          data: betStats.profit,
                          borderWidth: 4,
                          pointBackgroundColor: "rgba(255, 255, 255, .8)",
                          borderColor: "rgba(255, 255, 255, .8)",
                        },
                        {
                          label: "ETH houseProfit in USD",
                          data: betStats.networkData.eth.dailyBetProfit,
                        },
                        {
                          label: "POLY houseProfit in USD",
                          data: betStats.networkData.polygon.dailyBetProfit,
                        },
                        {
                          label: "BSC houseProfit in USD",
                          data: betStats.networkData.bsc.dailyBetProfit,
                        },
                        {
                          label: "ARB houseProfit in USD",
                          data: betStats.networkData.arb.dailyBetProfit,
                        },
                      ],
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} mt={8}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="Daily Total Bets"
                    chart={{
                      labels: betStats.labels,
                      datasets: [
                        {
                          label: "total Bets in USD",
                          data: betStats.sum,
                          borderWidth: 4,
                          pointBackgroundColor: "rgba(255, 255, 255, .8)",
                          borderColor: "rgba(255, 255, 255, .8)",
                        },
                        {
                          label: "ETH totalBets in USD",
                          data: betStats.networkData.eth.dailyBetSum,
                        },
                        {
                          label: "POLY totalBets in USD",
                          data: betStats.networkData.polygon.dailyBetSum,
                        },
                        {
                          label: "BSC totalBets in USD",
                          data: betStats.networkData.bsc.dailyBetSum,
                        },
                        {
                          label: "ARB totalBets in USD",
                          data: betStats.networkData.arb.dailyBetSum,
                        },
                      ],
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} mt={8}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="info"
                    title="Daily Bet Count"
                    chart={{
                      labels: betStats.labels,
                      datasets: [
                        {
                          label: "num Bets",
                          data: betStats.count,
                          borderWidth: 4,
                          pointBackgroundColor: "rgba(255, 255, 255, .8)",
                          borderColor: "rgba(255, 255, 255, .8)",
                        },
                        {
                          label: "ETH betCount",
                          data: betStats.networkData.eth.dailyBetCount,
                        },
                        {
                          label: "POLY betCount",
                          data: betStats.networkData.polygon.dailyBetCount,
                        },
                        {
                          label: "BSC betCount",
                          data: betStats.networkData.bsc.dailyBetCount,
                        },
                      ],
                    }}
                  />
                </MDBox>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MDBox mb={3}>
                    <PieChart
                      icon={{
                        color: "info",
                        component: "leaderboard",
                      }}
                      title="Token Stats"
                      description="All Time"
                      chart={{
                        labels: statistics.targetTokens.map((s) => s._id),
                        datasets: [
                          {
                            label: "Projects",
                            backgroundColors: [
                              "info",
                              "primary",
                              "dark",
                              "secondary",
                              "primary",
                            ],
                            data: statistics.targetTokens.map((s) => s.count),
                          },
                        ],
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDBox mb={3}>
                    <PieChart
                      icon={{
                        color: "info",
                        component: "leaderboard",
                      }}
                      title="Token Stats"
                      description="Last Week"
                      chart={{
                        labels: statistics.targetTokensLastWeek.map(
                          (s) => s._id
                        ),
                        datasets: [
                          {
                            label: "Projects",
                            legend: true,
                            backgroundColors: [
                              "info",
                              "primary",
                              "dark",
                              "secondary",
                              "primary",
                            ],
                            data: statistics.targetTokensLastWeek.map(
                              (s) => s.count
                            ),
                          },
                        ],
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default DashboardGraphs;
