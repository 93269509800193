const TooltipItem = (props) => {
  return (
    <div class="group flex relative">
      {props.children}
      <span
        class="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 
-translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto z-50"
      >
        {props.tooltip}
      </span>
    </div>
  );
};

export default TooltipItem;
