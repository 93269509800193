import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const SystemStatus = () => {
  const [status, setStatus] = useState([{ title: "allPaused", value: false }]);

  useEffect(() => {
    // Function to fetch status from the API
    const fetchStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/v1/dashboard/status`
        );
        setStatus(response.data);
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };

    fetchStatus();
  }, []);

  const handleToggle = (_status) => {
    const newStatus = [...status];
    newStatus.filter((s) => _status.title === s.title)[0].value =
      !_status.value;
    setStatus(newStatus);
  };

  const handleSave = async (_status) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/v1/dashboard/status`,
        { title: _status.title, value: _status.value },
        {
          headers: { cookie: global.authTokens },
        }
      );
      alert("Status saved successfully!");
    } catch (error) {
      console.error("Error saving status:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="p-4 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4">
        <h2 className="font-bold text-xl text-center mb-4">System Status</h2>

        {status.map((s) => {
          return (
            <div key={s.title} className="flex items-center justify-between">
              <input
                type="checkbox"
                className="toggle toggle-accent"
                checked={s.value}
                onChange={() => handleToggle(s)}
              />
              <span>{s.title}</span>
              <button onClick={() => handleSave(s)} className="btn btn-primary">
                Save
              </button>
            </div>
          );
        })}
      </div>
    </DashboardLayout>
  );
};

export default SystemStatus;
