// @mui material components
import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import { shortened } from "helpers";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import classicABI from "../../constants/classicABI";
import classicNewABI from "../../constants/classicNewABI";
import { TopWinnersTable } from "./components/TopWinnersTable";

const web3Bsc = new Web3("https://bsc-dataseed3.defibit.io/");
const web3Matic = new Web3("https://polygon-rpc.com/");

const fromWei = (amt) => {
  const num = new BigNumber(amt.toString()).dividedBy(10 ** 18);
  return parseFloat(num.toFixed(2));
};

const predictionsData = [
  {
    name: "BSC New",
    address: "0x00199E444155f6a06d74CF36315419d39b874f5c",
    interval: 300,
    network: 0,
    web3: web3Bsc,
    abi: classicNewABI,
    multiple: true,
    id: "0",
  },
  {
    name: "Poly New",
    address: "0x9f9564BE7b566dfE4B091a83a591752102aF3F33",
    interval: 300,
    network: 1,
    web3: web3Matic,
    abi: classicNewABI,
    multiple: true,
    id: "0",
  },
];

const predictionContracts = [];
for (let i = 0; i < predictionsData.length; i++) {
  const web3ToUse = predictionsData[i].web3;

  predictionContracts.push(
    new web3ToUse.eth.Contract(
      predictionsData[i].abi,
      predictionsData[i].address
    )
  );
}

const getNativeBalance = async (web3, address) => {
  const balanceWei = await web3.eth.getBalance(address);
  const balance = BigNumber(balanceWei.toString())
    .dividedBy(10 ** 18)
    .toFixed(2);
  return balance;
};

function Dashboard() {
  const [bscWinners, setBscWinners] = useState([]);
  const [polygonWinners, setPolygonWinners] = useState([]);
  const [hideBelow, setHideBelow] = useState(10);

  const loadWinners = async (network) => {
    const graph =
      network === "polygon"
        ? "https://api.studio.thegraph.com/query/76875/polynew/version/latest"
        : "https://api.studio.thegraph.com/query/76875/bnbnew/version/latest";

    const query = `query { 
      userTotals(first: 60, orderBy: houseProfit, where: {day: 0}) {
        id
        sender
        countBet
        countWon
        totalBet
        houseProfit
      }
    }`;

    let results = await fetch(graph, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        query,
      }),
    });

    const result = await results.json();
    const userTotals = result.data.userTotals;
    const users = [];
    userTotals
      .filter((u) => !u.id.includes("_0x"))
      .forEach((u) => {
        let data = { ...u };
        data.totalBet = fromWei(data.totalBet);
        data.houseProfit = fromWei(data.houseProfit);
        users.push(data);
      });

    return users;
  };

  useEffect(() => {
    const loadData = async () => {
      const winnersPolygon = await loadWinners("polygon");
      const winnersBsc = await loadWinners("bsc");
      setBscWinners(winnersBsc);
      setPolygonWinners(winnersPolygon);
    };
    loadData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <MDInput
          mt={5}
          fullWidth
          label="Hide Users with Bet Count Less Than:"
          value={hideBelow}
          variant="standard"
          onChange={(e) => setHideBelow(e.target.value)}
        />
        <TopWinnersTable
          title="Polygon"
          winners={polygonWinners.filter((u) => u.countBet >= hideBelow)}
        />
        <TopWinnersTable
          title="Bsc"
          winners={bscWinners.filter((u) => u.countBet >= hideBelow)}
        />
      </Grid>
    </DashboardLayout>
  );
}

export default Dashboard;
