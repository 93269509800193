import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";

import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";

const fromWei = (amt) => {
  const num = new BigNumber(amt.toString()).dividedBy(10 ** 18);
  return parseFloat(num.toFixed(2));
};

const idToName = {
  all: "all",
  "0x1": "eth",
  "0x2": "matic",
  "0x0": "btc",
};

function getDayNo(date) {
  const timestamp = new Date(date).getTime();
  const dayNo = Math.floor((timestamp - 1641168000000) / 86400000);
  return dayNo;
}

function dayToDate(day) {
  const timestamp = day * 86400000 + 1641168000000;
  return moment(timestamp).format("ddd, MMMM Do YYYY");
}

function currentDayNo() {
  return Math.floor((Date.now() - 1641168000000) / 86400000);
}

function ClassicStatistics() {
  const [network, setNetwork] = useState("polygon");
  const [id, setId] = useState("all");

  const [dateFrom, setDateFrom] = useState(
    new Date(moment.utc().subtract({ days: 7 }))
  );
  const [dateTo, setDateTo] = useState(new Date());

  const [days, setDays] = useState(7);
  const [dailyBetStats, setDailyBetStats] = useState([]);
  const [betStats, setBetStats] = useState();
  const [marketStats, setMarketStats] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const graph =
        network === "polygon"
          ? "https://api.studio.thegraph.com/query/76875/polynew/version/latest"
          : "https://api.studio.thegraph.com/query/76875/bnbnew/version/latest";

      const dayNo = currentDayNo();
      console.log("dayNo", dayNo);
      const dayFrom = dayNo - days + 1;

      const query = `query ($dayFrom: Int!) { 
        dailyBetStats(first: 1000, where:{day_gte:$dayFrom}) {
            id
            day
            countBet
            countWon
            totalBet
            totalWon
          }
    }`;

      let results = await fetch(graph, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          query,
          variables: { dayFrom },
        }),
      });

      const result = await results.json();
      const _dailyBetStats = result.data.dailyBetStats;
      console.log("dailyBetStats", _dailyBetStats);
      //setDailyBetStats(_dailyBetStats);

      const dailyBetCounts = {};
      const dailyBetSums = {};
      const dailyBetProfit = [0, 0, 0, 0, 0, 0, 0];
      const labels = [7];

      const ids = ["0x0", "0x1", "0x2", "0x3", "0x14"];

      for (let i = 0; i < 7; i++) {
        const day = dayNo - i;
        for (let j = 0; j < ids.length; j++) {
          const stat = _dailyBetStats.find(
            (b) => b.id == ids[j] + "_" + day + ".0"
          );

          if (!dailyBetCounts[ids[j]])
            dailyBetCounts[ids[j]] = [0, 0, 0, 0, 0, 0, 0];
          if (!dailyBetSums[ids[j]])
            dailyBetSums[ids[j]] = [0, 0, 0, 0, 0, 0, 0];
          if (!dailyBetCounts["all"])
            dailyBetCounts["all"] = [0, 0, 0, 0, 0, 0, 0];
          if (!dailyBetSums["all"]) dailyBetSums["all"] = [0, 0, 0, 0, 0, 0, 0];

          dailyBetCounts[ids[j]][6 - i] = stat ? stat.countBet : 0;
          dailyBetCounts["all"][6 - i] += dailyBetCounts[ids[j]][6 - i];
          dailyBetSums[ids[j]][6 - i] = stat ? fromWei(stat.totalBet) : 0;
          dailyBetSums["all"][6 - i] += dailyBetSums[ids[j]][6 - i];
        }

        labels[6 - i] = day;
      }

      console.log("dailyBetCounts", dailyBetCounts);

      setBetStats({
        labels,
        count: dailyBetCounts,
        sum: dailyBetSums,
        profit: dailyBetProfit,
      });
    };
    loadData();
  }, [network]);

  useEffect(() => {
    const loadData = async () => {
      const graph =
        network === "polygon"
          ? "https://api.studio.thegraph.com/query/76875/polynew/version/latest"
          : "https://api.studio.thegraph.com/query/76875/bnbnew/version/latest";

      const queryAllTime = `query ($id: String)  { 
            marketTotals(first: 1000, where: {predictionId:$id, day: 0}) {
                id
                day
                countBet
                countWon
                countBear
                countBull
                totalWon
                totalBet
                totalSamePrice
                totalWonClaimed
                totalClaimed
                totalEarnedUSD
              }
        }`;

      let reqAllTime = await fetch(graph, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          query: queryAllTime,
          variables: { id },
        }),
      });

      const resultAllTime = await reqAllTime.json();
      console.log("resultAllTime", resultAllTime);
      const marketTotalAllTime = resultAllTime.data.marketTotals[0];
      marketTotalAllTime.totalWon = fromWei(marketTotalAllTime.totalWon);
      marketTotalAllTime.totalBet = fromWei(marketTotalAllTime.totalBet);
      marketTotalAllTime.totalSamePrice = fromWei(
        marketTotalAllTime.totalSamePrice
      );
      marketTotalAllTime.totalWonClaimed = fromWei(
        marketTotalAllTime.totalWonClaimed
      );
      marketTotalAllTime.totalClaimed = fromWei(
        marketTotalAllTime.totalClaimed
      );

      const dayFromNo = getDayNo(dateFrom);
      const dayToNo = getDayNo(dateTo);

      const query = `query ($dayFromNo: Int!, $dayToNo: Int!, $id: String)  { 
        marketTotals(first: 1000, where: {day_gte:$dayFromNo, day_lte:$dayToNo, predictionId:$id}) {
            id
            day
            countBet
            countWon
            countBear
            countBull
            totalWon
            totalBet
            totalSamePrice
            totalWonClaimed
            totalClaimed
            totalEarnedUSD
          }
    }`;

      let results = await fetch(graph, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          query,
          variables: { dayFromNo, dayToNo, id },
        }),
      });

      const result = await results.json();
      const marketTotals = result.data.marketTotals;
      console.log("marketTotals", marketTotals);

      const daysTotal = {
        day: "total",
        countBet: 0,
        countWon: 0,
        countBear: 0,
        countBull: 0,
        totalWon: 0,
        totalBet: 0,
        totalSamePrice: 0,
        totalWonClaimed: 0,
        totalClaimed: 0,
        totalEarnedUSD: 0,
      };
      marketTotals.forEach((d) => {
        daysTotal.countBet += d.countBet;
        daysTotal.countWon += d.countWon;
        daysTotal.countBear += d.countBear;
        daysTotal.countBull += d.countBull;

        d.totalWon = fromWei(d.totalWon);
        d.totalBet = fromWei(d.totalBet);
        d.totalWonClaimed = fromWei(d.totalWonClaimed);
        d.totalSamePrice = fromWei(d.totalSamePrice);
        d.totalClaimed = fromWei(d.totalClaimed);

        daysTotal.totalWon += d.totalWon;
        daysTotal.totalBet += d.totalBet;
        daysTotal.totalWonClaimed += d.totalWonClaimed;
        daysTotal.totalClaimed += d.totalClaimed;
        daysTotal.totalSamePrice += d.totalSamePrice;
      });

      marketTotals.push(daysTotal);
      marketTotals.push(marketTotalAllTime);
      setMarketStats(marketTotals.reverse());
    };
    loadData();
  }, [network, id, dateFrom, dateTo]);

  return (
    <>
      <Grid container spacing={3}>
        <MDBox mb={10}>
          <InputLabel id="network-select">Network</InputLabel>
          <Select
            labelId="network-select"
            value={network}
            label="Network"
            onChange={(e) => setNetwork(e.target.value)}
          >
            <MenuItem value={"polygon"}>Polygon</MenuItem>
            <MenuItem value={"bsc"}>BSC</MenuItem>
          </Select>
          <InputLabel id="prediction-select">Prediction</InputLabel>
          <Select
            labelId="prediction-select"
            value={id}
            label="Prediction"
            onChange={(e) => setId(e.target.value)}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="0x0">BTC</MenuItem>
            <MenuItem value="0x1">ETH</MenuItem>
            <MenuItem value="0x2">MATIC</MenuItem>
            <MenuItem value="0x3">BNB</MenuItem>
            <MenuItem value="0x14">JPY</MenuItem>
          </Select>
        </MDBox>
      </Grid>
      <Stack direction="column" spacing={2}>
        <Grid container spacing={3}>
          {betStats && (
            <>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title={network + " " + idToName[id] + " Bet Count"}
                  chart={{
                    labels: betStats.labels,
                    datasets: [
                      {
                        label: "Count",
                        data: betStats.count[id],
                        borderWidth: 4,
                        pointBackgroundColor: "rgba(255, 255, 255, .8)",
                        borderColor: "rgba(255, 255, 255, .8)",
                      },
                    ],
                  }}
                />
              </MDBox>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="info"
                  title={network + " " + idToName[id] + " Bet Amounts"}
                  chart={{
                    labels: betStats.labels,
                    datasets: [
                      {
                        label: "Bet Amount in Matic",
                        data: betStats.sum[id],
                        borderWidth: 4,
                        pointBackgroundColor: "rgba(255, 255, 255, .8)",
                        borderColor: "rgba(255, 255, 255, .8)",
                      },
                    ],
                  }}
                />
              </MDBox>
              <br />
            </>
          )}
          <DatePicker
            selected={dateFrom}
            onChange={(date) => setDateFrom(date)}
          />
          <DatePicker selected={dateTo} onChange={(date) => setDateTo(date)} />
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
              <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="bg-white border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          {network + " " + idToName[id]}
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Bet
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Won
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Bull
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Count Bear
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total Bet
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total Won
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total SamePrice
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          House Profit
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          Total Claimed
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {marketStats.map((m, i) => (
                        <tr
                          key={m.day}
                          className={
                            i % 2 == 1
                              ? "bg-white"
                              : "bg-gray-100" + " border-b"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {m.day === 0
                              ? "allTime"
                              : m.day === "total"
                              ? "total (selected)"
                              : dayToDate(m.day)}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countBet}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countWon}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countBull}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.countBear}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalBet}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalWon}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalSamePrice}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {(
                              m.totalBet -
                              m.totalWon -
                              m.totalSamePrice / 2
                            ).toFixed(2)}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                            {m.totalClaimed}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Stack>
    </>
  );
}

export default ClassicStatistics;
