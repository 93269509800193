// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CircularProgress from "@mui/material/CircularProgress";

// Data
import { useEffect, useState } from "react";
import axios from "axios";
import BetsTable from "./BetsTable";

function Tables() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(true);

  useEffect(() => {
    const load = async () => {
      console.log("canUpdate", canUpdate);
      if (!canUpdate) return;
      setIsLoading(true);
      const result = await axios.get(
        process.env.REACT_APP_API_URI + "/api/v1/dashboard/bets/withSource",
        {
          withCredentials: true,
        }
      );

      setIsLoading(false);
      console.log(result.data);
      setData(result.data);
    };
    load();
    const timer = setInterval(() => load(), 10000);
    return () => {
      clearInterval(timer);
    };
  }, [canUpdate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <CircularProgress color="success" />}
      {data && (
        <BetsTable
          data={data}
          isLoading={isLoading}
          showSource={true}
          setCanUpdate={setCanUpdate}
        />
      )}
    </DashboardLayout>
  );
}

export default Tables;
