// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import axios from "axios";

const getMinutesDiff = (timestamp) => {
  const seconds = Math.abs(Math.floor(Date.now()) - timestamp);
  const minutes = Math.floor(seconds / 60000);
  return minutes;
};

const getSecondsDiff = (timestamp) => {
  const sec = Math.abs(Math.floor(Date.now()) - timestamp);
  const seconds = Math.floor(sec / 1000);
  return seconds;
};

const getMinutesDiffText = (timestamp) => {
  let diff = getMinutesDiff(timestamp);
  let timeDetail = "min";
  if (diff === 0) {
    diff = getSecondsDiff(timestamp);
    timeDetail = "sec";
  }
  if (timestamp > Math.floor(Date.now()))
    return "in " + diff + " " + timeDetail;
  return diff + " " + timeDetail + " ago";
};

const shortened = (address) => {
  return address.substring(0, 4) + "..." + address.slice(-4);
};

const Item = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {title}
    </MDTypography>
    {description && (
      <MDTypography variant="caption">{description}</MDTypography>
    )}
  </MDBox>
);

const Badge = ({ title, color }) => {
  <MDBox ml={-1}>
    <MDBadge badgeContent={title} color={color} variant="gradient" size="sm" />
  </MDBox>;
};

function AutoPlaysTable({ data }) {
  const [bets, setBets] = useState();
  useEffect(() => {
    const rows = data.map((b) => {
      return {
        currentEpoch: <Item title={b.currentEpoch} />,
        user: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography component="a" href={"/user/" + b.sender} color="text">
              {shortened(b.sender)}
            </MDTypography>
            <MDTypography>{b.user.nickname}</MDTypography>
          </MDBox>
        ),
        numBets: (
          <Item
            title={
              "Bets: " +
              b.numBets +
              " / " +
              (b.targetNumBets === 0 ? "∞" : b.targetNumBets)
            }
            description={b.interval + " Seconds"}
          />
        ),
        stoploss: (
          <Item
            title={"Stop Profit: " + b.stopProfit}
            description={"Stop Loss:  " + b.stopLoss}
          />
        ),
        onwinloss: (
          <Item
            title={
              "W: " +
              (b.resetOnWin ? "reset" : "+" + b.increaseOnWin + "%") +
              " / " +
              (b.switchPositionOnWin ? "same" : "switch")
            }
            description={
              "L:  " +
              (b.resetOnLoss ? "reset" : "+" + b.increaseOnLoss + "%") +
              " / " +
              (b.switchPositionOnLoss ? "same" : "switch")
            }
          />
        ),
        token: (
          <Item
            title={b.targetToken}
            description={b.totalBet + " " + b.bettingToken}
          />
        ),
        profit: (
          <Item
            title={b.totalProfit + " " + b.bettingToken}
            description={
              "Win Rate: " + ((b.numWon / b.numBets) * 100).toFixed() + "%"
            }
          />
        ),
        result: (
          <MDBox lineHeight={1} textAlign="center">
            <MDTypography>
              <MDBadge
                badgeContent={
                  b.completed
                    ? "completed"
                    : b.canceled
                    ? "canceled"
                    : "ongoing"
                }
                color={
                  b.completed ? "success" : b.canceled ? "warning" : "info"
                }
                variant="gradient"
                size="sm"
              />
            </MDTypography>
            <MDTypography variant="caption">{b.newBetFailReason}</MDTypography>
          </MDBox>
        ),
        time: (
          <Item
            title={
              "LastEvent: " +
              getMinutesDiffText(new Date(b.updatedAt).getTime())
            }
            description={
              "Start: " + getMinutesDiffText(new Date(b.createdAt).getTime())
            }
          />
        ),
      };
    });

    const columns = [
      {
        Header: "user",
        accessor: "user",
        align: "left",
      },
      { Header: "num Bets", accessor: "numBets", align: "left" },
      {
        Header: "On Win/Loss",
        accessor: "onwinloss",
        align: "left",
      },
      { Header: "token", accessor: "token", align: "left" },
      { Header: "SL/SP", accessor: "stoploss", align: "left" },
      { Header: "profit", accessor: "profit", align: "left" },
      { Header: "result", accessor: "result", align: "center" },
      { Header: "time", accessor: "time", align: "left" },
    ];

    const items = {
      columns,
      rows,
    };

    setBets(items);
  }, []);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Latest Bets
              </MDTypography>
            </MDBox>
            {bets && (
              <MDBox pt={3}>
                <DataTable
                  table={bets}
                  isSorted={true}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            )}
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default AutoPlaysTable;
